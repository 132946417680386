import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ParamArray, ComponentParamArray, ComponentUtils } from "../Core/utils/ComponentUtils";
import { HTMLAttributes, KnockoutComponentAttributes } from "@abstraqt-dev/jsxknockout";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "nav-tab" : {
                width?: string;
                height?: string;
            } & HTMLAttributes<HTMLElement> & KnockoutComponentAttributes;
        }
    }
}

export interface INavTabPage {
    componentName: string;
    title: string;
    params?: string;
    badgeCounter?: ko.Observable<number> | ko.Computed<number>;
}

export interface INavTabParams {
    Pages: ParamArray<INavTabPage>;
}

export class NavTab {
    Pages: ComponentParamArray<INavTabPage>;
    SelectedPage : ko.Observable<INavTabPage> = ko.observable();

    constructor(params : INavTabParams) {
        this.Pages = ComponentUtils.parseParameterArray(params.Pages, []);

        this.SelectedPage(this.Pages().length > 0 ? this.Pages()[0] : null);
    }

    public SelectPage(page : INavTabPage) {
        this.SelectedPage(page);
    }
}

ko.components.register("nav-tab", {
    viewModel: {
        createViewModel: (params: INavTabParams, componentInfo: ko.components.ComponentInfo) => {
            let vm = new NavTab(params);

            let element = (componentInfo.element as HTMLElement);
            let classes = element.className || "";
            element.className = "";

            let widthAttribute = element.attributes.getNamedItem("width");
            let heightAttribute = element.attributes.getNamedItem("height");
            let style = "";

            if(widthAttribute) {
                let width = widthAttribute?.value;
                element.attributes.removeNamedItem("width");

                if(width)
                    style += "width: " + width + "; ";
            }

            if(heightAttribute) {
                let height = heightAttribute?.value;
                element.attributes.removeNamedItem("height");

                if(height)
                    style += "height: " + height + "; ";
            }

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={"tabbable-custom " + classes} style={style + "margin-bottom: 0"}>
                    <ul class="nav nav-tabs" data-bind="foreach: Pages">
                        <li data-bind="css: { active: $component.SelectedPage() == $data }">
                            <a href="#" data-bind="attr: { 'aria-expanded': $component.SelectedPage() == $data }, click: $component.SelectPage.bind($component, $data)">
                                <ko-text data-bind="title"></ko-text>
                                <ko-if data-bind="$data.badgeCounter && $data.badgeCounter() > 0">
                                    <span class="badge badge-danger margin-left-5" data-bind="text: $data.badgeCounter"></span>
                                </ko-if>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <ko-if data-bind="SelectedPage">
                        <div class="tab-pane active flex-container flex-vertical flex-full-height" data-bind="component : { name: SelectedPage().componentName, params: SelectedPage().params }">
                            
                        </div>
                        </ko-if>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});