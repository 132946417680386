import * as ko from "knockout";
/**
 * Created by d.bucchi on 12/06/2017.
 */

interface ISelfSlideObject{
    panDirections: any;
    BindingToLeft: any;
    BindingToRight: any;
    startX: number;
    startY: number;
    startLeftPosition: number;
    startTime: Date;
    leftMenuOpened: boolean;
    rightMenuOpened: boolean;
    onXScrollEnabled: boolean;
}
export class SlideObjectBindingHandler {

    public init(element: HTMLElement, valueAccessor: () => any, allBindings: () => any) {
        var self : ISelfSlideObject = {
            panDirections: ko.utils.unwrapObservable(valueAccessor()),
            BindingToLeft: allBindings()["slideObjectFunctionToLeft"],
            BindingToRight: allBindings()["slideObjectFunctionToRight"],
            startX: 0,
            startY: 0,
            startLeftPosition: 0,
            startTime: null,
            leftMenuOpened: false,
            rightMenuOpened: false,
            onXScrollEnabled: true
        };

        self.panDirections.registerObserver(() => {
            SlideObjectBindingHandler.moveToCenter(element, self);
        });

        $(element).on("touchstart",
            (event) => {
                if (self.onXScrollEnabled) {
                    var ev = event.originalEvent as TouchEvent;
                    self.startX = ev.changedTouches[0].clientX;
                    self.startY = ev.changedTouches[0].clientY;
                    self.startLeftPosition = SlideObjectBindingHandler.getLeftPosition(element);
                    self.startTime = new Date();
                }
            });
        $(element).on("touchmove",
            (event) => {
                if (self.onXScrollEnabled) {
                    var ev = event.originalEvent as TouchEvent;
                    var dx = SlideObjectBindingHandler.deltaX(self.startX, ev.changedTouches[0].clientX);
                    var dy = SlideObjectBindingHandler.deltaY(self.startY, ev.changedTouches[0].clientY);
                    if (SlideObjectBindingHandler.shouldScrollOnX(dx) &&
                        !SlideObjectBindingHandler.shouldScrollOnY(dy) &&
                        !SlideObjectBindingHandler
                            .stopMovement(self.startX,
                                ev.changedTouches[0].clientX,
                            self.leftMenuOpened,
                            self.rightMenuOpened,
                            element)) {
                        event.preventDefault();
                        if (SlideObjectBindingHandler.toRight(self.startX, ev.changedTouches[0].clientX) &&
                            (!self.rightMenuOpened && self.panDirections.SlideLeftEnabled())) {
                            $(element).css({
                                "transform": "translate(" +
                                    (self.startLeftPosition + (ev.changedTouches[0].clientX - self.startX)) +
                                    "px, 0px)"
                            });
                        }
                        if (SlideObjectBindingHandler.toLeft(self.startX, ev.changedTouches[0].clientX) &&
                            (!self.leftMenuOpened && self.panDirections.SlideRightEnabled())) {
                            $(element).css({
                                "transform": "translate(" +
                                    (self.startLeftPosition + (ev.changedTouches[0].clientX - self.startX)) +
                                    "px, 0px)"
                            });
                        }
                    }
                }
            });
        $(element).on("touchend",
            (event) => {
                if (self.onXScrollEnabled) {
                    var ev = event.originalEvent as TouchEvent;
                    self.onXScrollEnabled = false;
                    var dy = SlideObjectBindingHandler.deltaY(self.startY, ev.changedTouches[0].clientY);
                    if (SlideObjectBindingHandler.toRight(self.startX, ev.changedTouches[0].clientX)) {
                        if (!self.leftMenuOpened && !self.rightMenuOpened && self.panDirections.SlideLeftEnabled()) {
                            if (SlideObjectBindingHandler.shouldCompleteToRight(SlideObjectBindingHandler.getLeftPosition(element)) || !SlideObjectBindingHandler.shouldScrollOnY(dy)) {
                                var transition = ($(window).width());
                                SlideObjectBindingHandler.translate(element, transition);
                                self.leftMenuOpened = true;
                                self.onXScrollEnabled = true;
                                self.BindingToLeft();
                                setTimeout(() => { SlideObjectBindingHandler.moveToCenter(element, self) }, 500);
                            } else {
                                SlideObjectBindingHandler.moveToCenter(element, self);
                                self.onXScrollEnabled = true;
                            }
                        } else {
                            if (self.rightMenuOpened) {
                                if (SlideObjectBindingHandler.shouldCompleteToRight(SlideObjectBindingHandler.getRightPosition(element)) || !SlideObjectBindingHandler.shouldScrollOnY(dy)) {
                                    SlideObjectBindingHandler.moveToCenter(element, self);
                                    self.rightMenuOpened = false;
                                    self.onXScrollEnabled = true;
                                } else {
                                    var transition = ($(window).width());
                                    SlideObjectBindingHandler.translate(element, -transition);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        }
                    }
                    if (SlideObjectBindingHandler.toLeft(self.startX, ev.changedTouches[0].clientX)) {
                        if (!self.rightMenuOpened && !self.leftMenuOpened && self.panDirections.SlideRightEnabled()) {
                            if (SlideObjectBindingHandler.shouldCompleteToLeft(SlideObjectBindingHandler.getLeftPosition(element)) || !SlideObjectBindingHandler.shouldScrollOnY(dy)) {
                                var transition = ($(window).width());
                                SlideObjectBindingHandler.translate(element, -transition);
                                self.rightMenuOpened = true;
                                self.onXScrollEnabled = true;
                                self.BindingToRight();
                                setTimeout(() => { SlideObjectBindingHandler.moveToCenter(element, self) }, 500);
                            } else {
                                SlideObjectBindingHandler.moveToCenter(element, self);
                                self.onXScrollEnabled = true;
                            }
                        } else {
                            if (self.leftMenuOpened) {
                                if (!SlideObjectBindingHandler.shouldScrollOnY(dy) ||
                                    !SlideObjectBindingHandler.shouldCompleteToLeft(SlideObjectBindingHandler.getLeftPosition(element))) {
                                    SlideObjectBindingHandler.moveToCenter(element, self);
                                    self.leftMenuOpened = false;
                                    self.onXScrollEnabled = true;
                                } else {
                                    var transition = ($(window).width());
                                    SlideObjectBindingHandler.translate(element, transition);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        }
                    }
                    self.onXScrollEnabled = true;
                }
            });
    }

    private static translate(element: HTMLElement, x: number) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(" + x + "px, 0px)");
        setTimeout(() => {
            $(element).css("transition", "");
        }, 500);
    }

    private static moveToCenter(element: HTMLElement, self: any) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(0px,0px)");
        setTimeout(() => {
                $(element).css("transition", "");
                $(element).parent().find(".leftmenu").addClass("hidden");
                self.leftMenuOpened = false;
                self.rightMenuOpened = false;
            },
            500);
    }

    private static getLeftPosition(element: HTMLElement): number {
        if (!isNaN(parseInt($(element).css("transform").split(",")[4]))) {
            return parseInt($(element).css("transform").split(",")[4]);
        }
        return parseInt($(element).css("left"));
    }

    private static getRightPosition(element: HTMLElement): number {
        return -SlideObjectBindingHandler.getLeftPosition(element);
    }

    private static toRight(prevX: number, nextX: number): boolean {
        return nextX > prevX;
    }

    private static toLeft(prevX: number, nextX: number): boolean {
        return nextX < prevX;
    }

    private static deltaX(startXTouch: number, endXTouch: number): number {
        return Math.abs(endXTouch - startXTouch);
    }

    private static deltaY(startYTouch: number, endYTouch: number): number {
        return Math.abs(endYTouch - startYTouch);
    }

    private static shouldScrollOnX(dx: number): boolean {
        return dx > 10;
    }

    private static shouldScrollOnY(dy: number): boolean {
        return dy > 15;
    }

    private static stopMovement(prevX: number, nextX: number, leftMenuOpened: boolean, rightMenuOpened: boolean, element: HTMLElement): boolean {
        if (SlideObjectBindingHandler.toRight(prevX, nextX) && (leftMenuOpened || SlideObjectBindingHandler.getLeftPosition(element) >= $(window).width() - 50)) {
            return true;
        } else {
            if (SlideObjectBindingHandler.toLeft(prevX, nextX) && (rightMenuOpened || SlideObjectBindingHandler.getRightPosition(element) >= $(window).width() - 50)) {
                return true;
            } else {
                return false;
            }
        }
    }

    private static shouldCompleteToLeft(position: number): boolean {
        return position < -($(window).width() / 4);
    }

    private static shouldCompleteToRight(position: number): boolean {
        return position > ($(window).width() / 4);
    }
}

ko.bindingHandlers["slideObject"] = new SlideObjectBindingHandler();