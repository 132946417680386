import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderStateSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderState } from "../ProlifeSdk/interfaces/job-order/IJobOrderState";

export class JobOrderStateDisplayValue {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var result = ko.computed(() => {
            var options = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var jobOrderStateSettingsManager : IJobOrderStateSettingsManager = <IJobOrderStateSettingsManager> settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
            var jobOrderStates : IJobOrderState[] = jobOrderStateSettingsManager.getJobOrderStates();

            var founded : IJobOrderState = null;
            jobOrderStates.forEach((state : IJobOrderState) => {
                if (state.IdJobOrderStateId == ko.utils.unwrapObservable(options.stateId)){
                    founded = state;
                    return;
                }
            });
            if (founded == null)
                return options.DefaultDescription || "";
            return founded.Description;
        });

        ko.applyBindingsToNode(element, { text: result }, undefined);
    }
}

ko.bindingHandlers["JobOrderStateDisplayValue"] = new JobOrderStateDisplayValue();
ko.virtualElements.allowedBindings["JobOrderStateDisplayValue"] = true;