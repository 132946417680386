import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class HomePage extends ProLifeReport implements IReport, IReportViewModel
{
    templateName : string = "";
    templateUrl : string = "";
    detailsTemplateName : string = "reports-home";
    detailsTemplateUrl : string = "reports/templates";
    Name : string = ProlifeSdk.TextResources.Reports.Report;
    viewModel : any;

    constructor()
    {
        super(-1, -1);
        this.viewModel = this;
    }

    Open()
    {
        //Not implemented
    }

    getViewModel() : IReportViewModel
    {
        return this.viewModel;
    }

    initialize()
    {
        //Nothing to do
    }
}