import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IDocumentCommonData, IGetDocumentsRequest } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class DocumentsDataSource implements IDataSource {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    private currentView: IDataSourceView;
    private jobOrderIds: number[] = [];
    private customerIds: number[] = [];
    private entityTypes: string[] = [];

    private filterByJobOrder = true;

    constructor() {}

    getTitle(currentModel: IDataSourceModel<number, IDocumentCommonData>): string {
        return ProlifeSdk.TextResources.Warehouse.CustomerOrders;
    }

    isGroupedData(currentModel: IDataSourceModel<number, IDocumentCommonData>, textFilter: string): boolean {
        return false;
    }

    areEqual(
        a: IDataSourceModel<number, IDocumentCommonData>,
        b: IDataSourceModel<number, IDocumentCommonData>
    ): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    async getData(
        currentModel: IDataSourceModel<number, IDocumentCommonData>,
        textFilter: string,
        skip = 0,
        count = 100000
    ): Promise<IDataSourceModel<number, IDocumentCommonData>[]> {
        const request: IGetDocumentsRequest = {
            skip: skip,
            count: count,
            textFilter: textFilter,
            jobOrderIds: this.jobOrderIds,
            customerIds: this.customerIds,
            filterByJobOrder: this.filterByJobOrder,
            entityTypes: this.entityTypes,
        };

        const orders: IDocumentCommonData[] = await this.documentsService.GetDocuments(request);
        return orders.map(this.createDocumentCommonDataDataSourceModel, this);
    }

    async getById(
        currentModel: IDataSourceModel<number, IDocumentCommonData>,
        ids: number[]
    ): Promise<IDataSourceModel<number, IDocumentCommonData>[]> {
        const orders: IDocumentCommonData[] = await this.documentsService.GetDocumentsByIds(ids, this.entityTypes);
        return orders.map(this.createDocumentCommonDataDataSourceModel, this);
    }

    setView(view: IDataSourceView): void {
        this.currentView = view;
    }

    setFilterByJobOrder(value: boolean): void {
        this.filterByJobOrder = value;
    }

    setJobOrderIds(...ids: number[]): void {
        this.jobOrderIds = ids;
    }

    setCustomerIds(...ids: number[]): void {
        this.customerIds = ids;
    }

    setEntityTypes(entityTypes: string[]): void {
        this.entityTypes = entityTypes || [];
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }

    private createDocumentCommonDataDataSourceModel(
        document: IDocumentCommonData
    ): IDataSourceModel<number, IDocumentCommonData> {
        return {
            id: document.Id,
            isGroup: false,
            isLeaf: true,
            title: String.format(
                ProlifeSdk.TextResources.Invoices.DocumentNameForDataSource,
                document.DocumentLabel,
                document.DocumentNumber,
                moment(document.DocumentDate).format("L"),
                document.ProtocolName
            ),
            subTitle: document.CustomerName,
            model: document,
        };
    }
}
