import * as ko from "knockout";
export class Tooltip {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        /*let options = ko.utils.unwrapObservable(valueAccessor());
        $(element).tooltip(options);

        let allBindings = allBindingsAccessor();
        if(allBindings.attr && allBindings.attr.title) {
            let title = allBindings.attr.title;
            if(ko.isObservable(title)) {
                title.subscribe((value) => {
                    $(element).attr('data-original-title', value);
                });
            }
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).tooltip('destroy');            
        });*/
    }

    /*update(element : any, valueAccessor: () => any) {

        $(element).on()
        let options = ko.utils.unwrapObservable(valueAccessor());
        $(element).tooltip('destroy');
        $(element).tooltip(options);
    }*/
}

ko.bindingHandlers['tooltip'] = new Tooltip();
