import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ListView } from "../../../../ProlifeSdk/prolifesdk/controls/lists/ListView";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IWorkflowCategory } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IException } from "../../../../Core/interfaces/IException";
import { IWorkflowCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IListView, IListViewProvider, IListViewItem } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IListView";
import { Deferred } from "../../../../Core/Deferred";

export class WorkflowCategoriesSettingsViewModel
{
    title : string;
    public categoriesList : IListView;

    constructor(private settingManager : IWorkflowCategoriesSettingsManager)
    {
        this.title = settingManager.getLabel();
        this.categoriesList = new ListView(new WorkflowCategoriesListProvider(settingManager), { enableCrud : true, height: '640px' });
    }
}

export class WorkflowCategoriesManagerDialog implements IDialog
{
    @LazyImportSettingManager(ProlifeSdk.WorkflowCategoriesType)
    private settingManager : IWorkflowCategoriesSettingsManager;

    title : string;
    public list : IListView;
    templateName: string = "workflow-categories-manager-dialog";
    templateUrl: string = "todolist/templates/settings";
    modal : { close: (result?: any) => void; };

    constructor()
    {
        this.title = this.settingManager.getLabel();
        this.list = new ListView(new WorkflowCategoriesListProvider(this.settingManager), { enableCrud : true, height: '580px' });
    }

    public close(): void
    {
        this.modal.close();
    }

    public action() { }
}

export class WorkflowCategoriesListProvider implements IListViewProvider
{
    constructor(private settingManager : IWorkflowCategoriesSettingsManager) {}

    public GetItems() : Promise<IListViewItem[]>
    {
        var d = new Deferred<IListViewItem[]>();
        this.settingManager.GetCategories()
            .then((categories : IWorkflowCategory[]) => {
                d.resolve(categories.map((c : IWorkflowCategory) => {
                    return new WorkflowCategory(this.settingManager, c);
                }));
            })
            .catch(() => { d.reject([])});
        return d.promise();
    }

    public CreateNewItem() : Promise<IListViewItem>
    {
        let def = new Deferred();
        let newCategory : IWorkflowCategory = {
            Id: null,
            Name : ProlifeSdk.TextResources.Todolist.NewCategory,
            Locked : false,
            Deleted : false,
            Icon: 'fa fa-fork',
            Background: '#4b8df8',
            Foreground: '#FFFFFF',
            TypeCode: "",
            IsDefault: false,
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            HideFromSuggestions: false
        };
        
        this.settingManager.CreateOrUpdate(newCategory).then((c: IWorkflowCategory) => {
            def.resolve(new WorkflowCategory(this.settingManager, c));
        }).catch(() => {
            def.reject();
        });
        
        return def.promise();
    }
}

export class WorkflowCategory implements IListViewItem
{
    public templateName : string = "workflow-categories-list-item";
    public templateUrl : string = "todolist/templates/settings";

    public CanBeDeleted : ko.Observable<boolean> = ko.observable(false);
    public Name : ko.Observable<string> = ko.observable("");
    public Icon : ko.Observable<string> = ko.observable();
    public Background : ko.Observable<string> = ko.observable();
    public Foreground : ko.Observable<string> = ko.observable();
    public TypeCode : ko.Observable<string> = ko.observable();
    public ShowAlertOnUnestimatedTasks: ko.Observable<boolean> = ko.observable();
    public WorkflowMustBePlanned: ko.Observable<boolean> = ko.observable();
    public WorkflowMustBeRelatedToCustomerOrders: ko.Observable<boolean> = ko.observable();
    public ActivitiesMustBeReviewed: ko.Observable<boolean> = ko.observable();
    public HideFromSuggestions : ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private settingManager : IWorkflowCategoriesSettingsManager, private category : IWorkflowCategory)
    {
        this.CanBeDeleted(true/*!category.Locked*/);
        this.Name(category.Name);
        this.Icon(category.Icon);
        this.Background(category.Background);
        this.Foreground(category.Foreground);
        this.TypeCode(category.TypeCode);
        this.ShowAlertOnUnestimatedTasks(category.ShowAlertOnUnestimatedTasks);
        this.WorkflowMustBePlanned(category.WorkflowMustBePlanned);
        this.WorkflowMustBeRelatedToCustomerOrders(category.WorkflowMustBeRelatedToCustomerOrders);
        this.ActivitiesMustBeReviewed(category.ActivitiesMustBeReviewed);
        this.HideFromSuggestions(category.HideFromSuggestions);

        this.Name.subscribe(this.Update.bind(this));
        this.Icon.subscribe(this.Update.bind(this));
        this.Background.subscribe(this.Update.bind(this));
        this.Foreground.subscribe(this.Update.bind(this));
        this.TypeCode.subscribe(this.Update.bind(this));
        this.ShowAlertOnUnestimatedTasks.subscribe(this.Update.bind(this));
        this.WorkflowMustBePlanned.subscribe(this.Update.bind(this));
        this.WorkflowMustBeRelatedToCustomerOrders.subscribe(this.Update.bind(this));
        this.ActivitiesMustBeReviewed.subscribe(this.Update.bind(this));
        this.HideFromSuggestions.subscribe(this.Update.bind(this));
    }

    public Delete() : Promise<void>
    {
        let def = new Deferred<void>();

        this.internalDelete()
            .then(() => def.resolve())
            .catch(() => def.reject());

        return def.promise();
    }

    public Update()
    {
        this.category.Name = this.Name();
        this.category.Icon = this.Icon();
        this.category.Background = this.Background();
        this.category.Foreground = this.Foreground();
        this.category.TypeCode = this.TypeCode();
        this.category.ShowAlertOnUnestimatedTasks = this.ShowAlertOnUnestimatedTasks();
        this.category.WorkflowMustBePlanned = this.WorkflowMustBePlanned();
        this.category.WorkflowMustBeRelatedToCustomerOrders = this.WorkflowMustBeRelatedToCustomerOrders();
        this.category.ActivitiesMustBeReviewed = this.ActivitiesMustBeReviewed();
        this.category.HideFromSuggestions = this.HideFromSuggestions();
        
        this.settingManager.CreateOrUpdate(this.category);
    }

    private async internalDelete(): Promise<void> {
        let confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.Todolist.SureToDeleteCategory, ProlifeSdk.TextResources.Todolist.DoNotDeleteCategory, ProlifeSdk.TextResources.Todolist.DeleteCategory);

        if(!confirm)
            return;
        
        try {
            await this.settingManager.Delete(this.category.Id);
        } catch(e) {
            let ex: IException = e as IException;

            this.infoToastService.Error(ex.ExceptionMessage);
        }
    }
}
