import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IResourcesGroupsSettingsManager, IResourcesGroup } from "../../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IHumanResourcesControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/users/IHumanResourcesControlsEntityProvider";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    pageReloadHelp: {
        position: "absolute",
        left: "60%",
        marginLeft: "-25%",
        textAlign: "center",
        top: "0px",
        width: "500px",
        color: "#a94442"
    }
});
const { classes } = styleSheet.attach();

type ResourcesGroupsEditingViewModelProps = {
    groupsManager : IResourcesGroupsSettingsManager
}

export function ResourcesGroupsEditingViewModel(props: ResourcesGroupsEditingViewModelProps) {
    const C = require("./ResourcesGroupsEditingViewModel")._ResourcesGroupsEditingViewModel as typeof _ResourcesGroupsEditingViewModel;
    return <C {...props} />;
}

export class _ResourcesGroupsEditingViewModel
{
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    public title : string;
    public elements : ko.ObservableArray<ResourcesGroupViewModel> = ko.observableArray();
    public FilteredElements : ko.Computed<ResourcesGroupViewModel[]>;
    public SelectedGroup : ko.Observable<ResourcesGroupViewModel> = ko.observable(null);
    public TextFilter : ko.Observable<string> = ko.observable("").extend({ throttle : 500 });

    constructor(private props : ResourcesGroupsEditingViewModelProps)
    {
        this.title = this.props.groupsManager.getLabel();

        const groups : IResourcesGroup[] = this.props.groupsManager.GetGroups().filter((r : IResourcesGroup) => !r.Deleted);

        groups.forEach(this.CreateViewModelFor.bind(this));

        this.FilteredElements = ko.computed(() => {
            return this.elements().filter((g : ResourcesGroupViewModel) => {
                return g.Name().toUpperCase().trim().indexOf(this.TextFilter().toUpperCase().trim()) > -1;
            });
        });
    }

    private CreateViewModelFor(group : IResourcesGroup)
    {
        const groupVm = new ResourcesGroupViewModel(this, group, this.props.groupsManager);
        this.elements.push(groupVm);
    }

    public CreateNew()
    {
        const group : IResourcesGroup = {
            Name : this.GetNewName(),
            Description : "",
            WorkedHoursApprovalRole: "",
            Deleted : false,
            BuiltIn : false,
            ResourcesIds : [],
            HoursApprovalResources: [],
            HoursApprovalState: 0
        };
        
        this.props.groupsManager.CreateOrUpdate(group).then((g : IResourcesGroup) => { this.CreateViewModelFor(g); });
    }

    private GetNewName()
    {
        const allNames = this.elements().map((g : ResourcesGroupViewModel) => { return g.Name(); });
        return this.sdkService.Utilities.Lists.GetNewProgressiveName(ProlifeSdk.TextResources.Users.NewGroup, allNames);
    }

    render() {
        const groupEditor = this;
        const group: ResourcesGroupViewModel = null;
        const selectedGroup: ResourcesGroupViewModel = null;

        return ComponentUtils.bindTo((
            <>
                <h3 className="page-title">
                    <span data-bind={{ text: groupEditor.title }}></span>
                    <small className={"alert alert-danger " + classes.pageReloadHelp}>{TextResources.Users.PageReloadHelp}</small>
                </h3>

                <div className="row">
                    <div className="col-md-5">
                        <div className="portlet box red">
                            <div className="portlet-title">
                                <div className="caption search">
                                    <div className="input-icon input-inline input-icon-small input-transparent">
                                        <i className="fa fa-search"></i>
                                        <input placeholder={TextResources.Users.SearchGroupsPlaceholder} type="text" className="form-control input-sm" data-bind={{ value: groupEditor.TextFilter, valueUpdate :  'afterkeydown' }} />
                                    </div>
                                </div>
                                <div className="actions">
                                    <a href="#" className="btn default btn-sm" data-bind={{ click: groupEditor.CreateNew.bind(groupEditor) }}>
                                        <i className="fa fa-plus"></i>&nbsp;{TextResources.Users.New}
                                    </a>
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div data-bind={{ slimScroll: '700px' }}>
                                            <div className="list-group" data-bind={{ foreach: { data: groupEditor.FilteredElements, as: 'group' } }}>
                                                <a href="#" className="list-group-item" data-bind={{ click: group.ShowDetails.bind(group), css : { 'active' : group.IsSelected } }}>
                                                    <h4 data-bind={{ text: group.Name }}></h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7" data-bind={{ with: groupEditor.SelectedGroup, as: 'selectedGroup' }}>
                        <div className="portlet box red">
                            <div className="portlet-title">
                                <div className="caption">
                                    <span data-bind={{ text: selectedGroup.Name }}></span>
                                </div>
                                <div className="actions">
                                    <a href="#" className="btn default btn-sm" data-bind={{ click: selectedGroup.SaveChanges.bind(selectedGroup) }}>
                                        <i className="fa fa-floppy-o"></i>&nbsp;{TextResources.Users.Save}
                                    </a>
                                    <a href="#" className="btn default btn-sm" data-bind={{ click: selectedGroup.Delete.bind(selectedGroup) }}>
                                        <i className="fa fa-trash-o"></i>&nbsp;{TextResources.Users.Delete}
                                    </a>
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-horizontal">
                                            <div className="form-body">
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">{TextResources.Users.GroupName}</label>
                                                    <div className="col-md-9">
                                                        <input type="text" className="form-control" placeholder={TextResources.Users.GroupNamePlaceholder} data-bind={{ selectOnFocus: {}, value: selectedGroup.Name }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">{TextResources.Users.GroupDescription}</label>
                                                    <div className="col-md-9">
                                                        <input type="text" className="form-control" placeholder={TextResources.Users.GroupDescriptionPlaceholder} data-bind={{ selectOnFocus: {}, value: selectedGroup.Description }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">{TextResources.Users.GroupMembers}</label>
                                                    <div className="col-md-9">
                                                        <input className="form-control default" type="hidden" placeholder={TextResources.Users.GroupMembersPlaceholder} data-bind={{ value: selectedGroup.ResourcesStringArray, select2: { query : selectedGroup.resourcesEntityProvider.findEntities.bind(selectedGroup.resourcesEntityProvider), initSelection: selectedGroup.resourcesEntityProvider.findMultipleEntities.bind(selectedGroup.resourcesEntityProvider), allowClear: true, multiple: true, minimumInputLength: 1 } }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ), this, "groupEditor");
    }
}

export class ResourcesGroupViewModel
{
    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService : IEntityProviderService;

    public IsSelected : ko.Observable<boolean> = ko.observable(false);
    public Name : ko.Observable<string> = ko.observable("");
    public Description : ko.Observable<string> = ko.observable("");

    public resourcesEntityProvider : IHumanResourcesControlsEntityProvider;
    public ResourcesStringArray : ko.Observable<string> = ko.observable("");

    constructor(private container : _ResourcesGroupsEditingViewModel, private group : IResourcesGroup, private groupsManager : IResourcesGroupsSettingsManager)
    {
        this.resourcesEntityProvider = this.entityProviderService.getEntityProvider(ProlifeSdk.HumanResources).getControlsProvider() as IHumanResourcesControlsEntityProvider;
        
        this.Update(group);
    }

    Update(group : IResourcesGroup)
    {
        this.group = group;
        this.Name(group.Name || "");
        this.Description(group.Description || "");

        let resourcesString  = "";
        group.ResourcesIds.forEach((r : number) => { resourcesString += r + "|"; });
        if (resourcesString.length > 0)
            resourcesString = resourcesString.substr(0, resourcesString.length-1);
        this.ResourcesStringArray(resourcesString);
    }

    ShowDetails()
    {
        if(this.container.SelectedGroup())
            this.container.SelectedGroup().IsSelected(false);

        this.container.SelectedGroup(this);
        this.IsSelected(true);
    }

    SaveChanges()
    {
        if(this.Name().trim().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Users.InsertGroupName);
            return;
        }

        this.group.Name = this.Name();
        this.group.Description = this.Description();
        this.group.ResourcesIds = this.ResourcesStringArray().split("|")
            .filter((s : string) => { return s && s.trim().length > 0; })
            .map((s : string) => parseInt(s));

        this.groupsManager.CreateOrUpdate(this.group).then((g : IResourcesGroup) => {
            this.Update(g);
            this.toastService.Success(ProlifeSdk.TextResources.Users.GroupSavedSuccessfully);
        });
    }

    Delete()
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.Users.SureToDeleteGroup, ProlifeSdk.TextResources.Users.DoNotDeleteGroup, ProlifeSdk.TextResources.Users.DeleteGroup, (confirm : boolean) => {
            if(!confirm)
                return;

            this.groupsManager.Remove(this.group.Id).then(() => {
                this.container.elements.remove(this);
                this.container.SelectedGroup(null);
                this.toastService.Success(ProlifeSdk.TextResources.Users.GroupDeletedSuccessfully);
            });
        });
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ResourcesGroupsEditingViewModel);
}