import * as ko from "knockout";
import { INavigationMenuComponentSearchActionItem, INavigationMenuComponentSearchAction, INavigationMenuComponent } from "./INavigationMenuComponent";

export class NavigationMenuComponentSearchActionItem implements INavigationMenuComponentSearchActionItem {
    public isSeparator: false;
    public isGroup: false;

    text: ko.Computed<string> = ko.computed(() => "");
    active: ko.Computed<boolean> = ko.computed(() => false);
    canExecute: ko.Computed<boolean> = ko.computed(() => true);
    activeClass: ko.Observable<string> = ko.observable("");
    defaultClass: ko.Observable<string> = ko.observable("");
    icon?: ko.Computed<string>;
    
    public Selected: ko.Observable<boolean> = ko.observable();
    public Visible: ko.Computed<boolean>;

    constructor(public actionInfo: INavigationMenuComponentSearchAction, private navigator: INavigationMenuComponent) {
        this.Selected(actionInfo.isDefault || false);

        this.Visible = ko.computed(() => {
            if (actionInfo.visible === undefined || actionInfo.visible === null)
                return true;
            return actionInfo.visible();
        });
    }

    public click(): void {
        this.navigator.selectSearchOption(this);
        this.actionInfo.action(this.actionInfo, this.navigator);
    }
}