import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 13:31
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { SampleTypeEditingViewModel } from "./ui/SampleTypeEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISampleTypeSettingManager } from "../../../../interfaces/ISampleTypeSettingManager";
import { ISampleType } from "../../../../../ProlifeSdk/interfaces/survey/ISample";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";

export class SampleTypeSettingManager implements ISampleTypeSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private sampleTypes: ISampleType[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }

    getSampleTypes(): ISampleType[] {
        return this.sampleTypes;
    }

    getSampleTypeById(id: number): ISampleType {
        var types: ISampleType[] = this.sampleTypes.filter((t: ISampleType) => { return t.Id == id });
        return types[0];
    }

    load(): Promise<ISampleType[]> {
        return this.ajaxService.Post<ISampleType[]>("Survey-api/SampleApi", "GetSampleTypes", {})
            .then((types: ISampleType[]) => { this.sampleTypes = types; return types; });
    }

    getName(): string {
        return ProlifeSdk.SampleType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.SampleTypes;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "sample-type",
            templateUrl: "survey/templates/sample/settings",
            viewModel: new SampleTypeEditingViewModel(this.serviceLocator, this)
        };
    }

    addOrUpdate(type: ISampleType): Promise<ISampleType> {
        return this.ajaxService.Post<ISampleType>("Survey-api/SampleApi", "AddOrUpdateSampleType", {
            methodData: {
                Type: type
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/SampleApi", "RemoveSampleType", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: ISampleType): void {
        var found: boolean = false;

        this.sampleTypes.forEach((s: ISampleType) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Name = state.Name;
            s.Icon = state.Icon;
            s.Background = state.Background;
            s.Foreground = state.Foreground;

            found = true;
        });

        if (!found)
            this.sampleTypes.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.sampleTypes.length; i++) {
            if (this.sampleTypes[i].Id == id) {
                this.sampleTypes.splice(i, 1);
                break;
            }
        }
    }
}