import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IvaModesEditingViewModel } from "./ui/IvaModesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IIvaModes, IIvaMode, IIvaModesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class IvaModesSettingsManager implements IIvaModes {
    private ajaxService : IAjaxService;
    private ivaModes : IIvaMode[] = [];
    private observers : IIvaModesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer : IIvaModesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IIvaModesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IIvaMode[]> {
        var def = new Deferred<IIvaMode[]>();

        this.ajaxService.Get("Invoices-api", "IvaMode", {})
            .then((ivaModes: IIvaMode[]) => {
                this.ivaModes = ivaModes;
                def.resolve(ivaModes);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.IvaModes;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.VATCodes;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "ivamode",
            templateUrl: "invoices/templates/settings",
            viewModel: new IvaModesEditingViewModel(this)
        };
    }

    getIvaModes(getDeleted : boolean = false) : IIvaMode[] {
        return this.ivaModes.filter((m : IIvaMode) => { return getDeleted || m.Eliminato == 0; });
    }

    getIvaModeByCode(ivaCode : string) : IIvaMode {
        return this.ivaModes.filter((ivaMode : IIvaMode) => ivaMode.CodiceIVA == ivaCode)[0];
    }

    getDefaultIva() : IIvaMode {
        return this.ivaModes.filter((ivaMode : IIvaMode) => ivaMode.IvaDefault == 1)[0];
    }

    createOrUpdate(ivaMode : IIvaMode) : Promise<IIvaMode> {
        var def = new Deferred<IIvaMode>();

        if(ivaMode.IdTipoIVA > 0) {
            this.ajaxService.Put("Invoices-api", "IvaMode", { methodData: ivaMode})
                .then((ivaMode: IIvaMode) => {
                    this.onIvaModeChanged(ivaMode);
                    def.resolve(ivaMode);
                })
                .catch(() => def.reject());
        } else {
            this.ajaxService.Post("Invoices-api", "IvaMode", { methodData: ivaMode})
            .then((ivaMode: IIvaMode) => {
                this.onIvaModeAdded(ivaMode);
                def.resolve(ivaMode);
            })
            .catch(() => def.reject());
        }

        return def.promise();
    }

    remove(ivaModeId : number) : Promise<void> {
        var def = new Deferred<void>();

        this.ajaxService.Delete("Invoices-api", "IvaMode/" + ivaModeId, {})
            .then(() => {
                this.onIvaModeDeleted(ivaModeId);
                def.resolve();
            })
            .catch(() => def.reject());

        return def.promise();
    }

    private onIvaModeChanged(ivaMode : IIvaMode) {
        this.load();
        this.observers.forEach((obs) => obs.onIvaModeChanged(ivaMode));
    }

    private onIvaModeAdded(ivaMode : IIvaMode) {
        this.load();
        this.observers.forEach((obs) => obs.onIvaModeAdded(ivaMode));
    }

    private onIvaModeDeleted(ivaModeId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onIvaModeDeleted(ivaModeId));
    }
}