import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type IconInputProps = {
    label?: string;
    icon: ko.Observable<string>;
    simple?: boolean;
}

export function IconInput(props: IconInputProps) {
    const C = require("./IconInput")._IconInput as typeof _IconInput;
    return <C {...props} />;
}

export class _IconInput {
    static defaultProps: Partial<IconInputProps> = {
    }

    constructor(private props : IconInputProps) {
        
    }
    
    render() {
        const iconInput = this;
        const classNames = ComponentUtils.classNames("form-group");

        const wrapFormGroup = (input: React.ReactElement) => {
            if (this.props.simple)
                return input;

            return  <div className={classNames}>
                        {this.props.label && <><label className="control-label">{this.props.label}</label><br/></>}
                        {input}
                    </div>
        }
        
        return ComponentUtils.bindTo(wrapFormGroup(<button className="btn btn-default" data-bind={{ iconPicker: iconInput.props.icon }} role="iconpicker" />), this, 'iconInput');
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(IconInput);
}