import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as moment from "moment-timezone";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface ITimeZoneDataSourceModel extends IDataSourceModel {
    offset: number;
}

export interface ITimeZoneDataSource extends IDataSource {

}

export class TimeZoneDataSource implements ITimeZoneDataSource {
    setView(view: IDataSourceView): void {
        
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }  

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Desktop.TimeZone;
    }
    
    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDataSourceModel[]> {
        let timeZones = moment.tz.names();
        textFilter = (textFilter || "").toLowerCase();
        
        return timeZones
            .filter(tz => tz.toLowerCase().indexOf(textFilter) >= 0)
            .map<ITimeZoneDataSourceModel>(tz => {
                let zone = moment.tz.zone(tz);
                let startOfYear = moment().startOf('year');
                let offset = zone.utcOffset(startOfYear.valueOf());
                let hoursOffset = Math.floor(offset / 60);
                let minutesOffset = Math.floor(offset % 60);
                return {
                    id: tz,
                    isGroup: false,
                    isLeaf: true,
                    title: `(GMT${hoursOffset >= 0 ? '+' + hoursOffset : hoursOffset}${minutesOffset > 0 ? ':' + minutesOffset : ''}) ${tz}`,
                    offset: offset
                };
            }).sort((a, b) => {
                let result = a.offset - b.offset;
                if(result != 0)
                    return result;
                if(a.title < b.title) return -1;
                if(a.title > b.title) return 1;
                return 0;
            });
    }

    async getById(currentModel: IDataSourceModel, ids: number[] | string[]): Promise<IDataSourceModel[]> {
        
        return (<string[]>ids).map<IDataSourceModel>(tz => {
            let zone = moment.tz.zone(tz);
            let startOfYear = moment().startOf('year');
            let offset = zone.utcOffset(startOfYear.valueOf());
            let hoursOffset = Math.floor(offset / 60);
            let minutesOffset = Math.floor(offset % 60);
            return {
                id: tz,
                isGroup: false,
                isLeaf: true,
                title: `(GMT${hoursOffset >= 0 ? '+' + hoursOffset : hoursOffset}${minutesOffset > 0 ? ':' + minutesOffset : ''}) ${tz}`,
                offset: offset
            };
        });
    }

    areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    public getSupportedDropMimeTypes() : string[] {
        return [];
    }
}