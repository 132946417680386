import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 18/02/13
 * Time: 17.24
 * To change this template use File | Settings | File Templates.
 */

import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IConveyance, IConveyances } from "../../../../ProlifeSdk/interfaces/invoice/settings/IConveyances";

export interface IConveyanceProvider {
    getData() : IConveyance;
    update(conveyance : IConveyance) : void;
}

export class ConveyancesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IConveyanceProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private conveyancesManager : IConveyances) {
        this.title = conveyancesManager.getLabel();

        var conveyances = conveyancesManager.getConveyances();
        conveyances.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(conveyance : IConveyance) {
        this.elements.push(new ConveyanceViewModel(this, conveyance));
    }

    public addNewSetting() {
        var conveyanceViewModel = new ConveyanceViewModel(this, {
            Descrizione: this.newSettingName()
        });
        this.elements.push(conveyanceViewModel);
        this.createOrUpdateEntry(conveyanceViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IConveyanceProvider) {
        var appearance = element.getData();
        this.conveyancesManager.createOrUpdate(appearance)
            .then((updatedAppearance) => element.update(updatedAppearance));
    }

    public deleteEntry(element : IConveyanceProvider) {
        this.elements.remove(element);
        var appearance = element.getData();
        this.conveyancesManager.remove(appearance.Id);
    }
}

class ConveyanceViewModel implements IConveyanceProvider {
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : ConveyancesEditingViewModel, private conveyance : IConveyance) {
        this.name(conveyance.Descrizione);
        this.name.subscribe(this.onNameChanged.bind(this));
    }

    private onNameChanged(newValue : string) {
        if(!newValue) {
            this.container.deleteEntry(this);
        } else {
            this.container.createOrUpdateEntry(this);
        }
    }

    getData() : IConveyance {
        var conveyance : IConveyance = <IConveyance> $.extend({}, this.conveyance);
        conveyance.Descrizione = this.name();
        return conveyance;
    }

    update(conveyance : IConveyance) : void {
        this.conveyance = conveyance;
        this.name(this.conveyance.Descrizione);
    }
}