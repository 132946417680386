import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type LoadingProps = {
    show: ko.MaybeObservable<boolean>;
};

export function Loading(props: LoadingProps) {
    const C = require("./Loading")._Loading as typeof _Loading;
    return <C {...props} />;
}

export class _Loading {
    static defaultProps: Partial<LoadingProps> = {};

    constructor(private props: LoadingProps) {}

    render() {
        const l = this;
        return ComponentUtils.bindTo(
            <i class="fa fa-circle-o-notch fa-spin" data-bind={{ visible: l.props.show }}></i>,
            this,
            "l"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(Loading);
}
