import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { MultipleEntitiesProvider } from "../ProlifeSdk/prolifesdk/MultipleEntitiesProvider";
import { WorkSheetRow } from "../WorkedHours/workedhours/ui/WorkSheetRow";
import * as Core from "../Core/Core";
import { IWorkedHoursService } from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkableElement } from "../ProlifeSdk/interfaces/worked-hours/IWorkableElement";

export class WorkActivitiesValue
{
    init(element: any, valueAccessor: () => any): void
    {
        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        var todoListService : ITodoListService = <ITodoListService> Core.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        var instance = {};

        var row : WorkSheetRow = ko.utils.unwrapObservable(valueAccessor().row);

        var iconsProvider = valueAccessor()["iconsProvider"];
        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var billableField = valueAccessor()["billableField"];
        var estimateField = valueAccessor()["estimateField"];
        var descrField = valueAccessor()["descrField"];
        var containerIdField = valueAccessor()["containerIdField"];
        var containerTypeField = valueAccessor()["containerTypeField"];
        var getClosedField = valueAccessor()["getClosedField"];
        var workedHoursField = valueAccessor()["workedHoursField"];

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : WorkActivitiesValue.findWorkActivitiesContainers.bind(null, instance, workableElementsService, getClosedField, containerIdField, containerTypeField),
            display: (json) => {
                var item = JSON.parse(json);
                return item.FullName;
            },
            limit: 1000,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var iconClass : string = row.GetActivityTypeIcon(item.TypeCode) || "fa-clipboard"; //iconsProvider ? iconsProvider(item.TypeCode) || "fa-clipboard" : "fa-clipboard";
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\"><span class='fa " + iconClass +  "'></span>&nbsp;" + item.FullName + " - (<small>" + item.TaskStatus + "</small>) <br/><small style=\"color: grey\">" + item.WorkflowTitle + " - (" + item.WorkflowStatus + ")</span></div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            row.WorkActivityType(item.TypeCode); //typeField(item.TypeCode);
            row.WorkActivityId(item.Id); //keyField(item.Id);
            row.WorkActivityDescr(item.FullName); //if(descrField) descrField(item.FullName);
            row.WorkActivityWorkflow(item.WorkflowTitle);

            if(item.TypeCode == ProlifeSdk.JobOrderTaskEntityTypeCode) {
                row.WorkedHours(item.WorkedHours || 0); //if(workedHoursField) workedHoursField(item.WorkedHours || 0);

                //if(row.IsInitializedFromPreferred() || row.Id == undefined || row.Id == null || row.Id <= 0) {
                    todoListService.GetTaskById(item.Id)
                        .then((t:ITodoListTask) => {
                            row.BillableInit(t.ReportingType == 1);
                            row.EstimatedWorkInit(t.Duration || 0);
                            row.IsTaskClosed(t.TaskBoardStatus >= 2);

                            var workRows = t.EstimatedBudgetRows.filter(r => r.Type == ProlifeSdk.EstimatedWorkEntityTypeCode);
                            if(workRows.length > 0) {
                                var matches = workRows.filter((r) => r.EntityKeyId == (<any>row).resource.FkDefaultCharacter);
                                var roleId: number;
                                if (matches.length > 0) {
                                    roleId = matches[0].EntityKeyId;
                                    row.RoleId(roleId);
                                    return;
                                }

                                matches = workRows.filter((w) => row.Roles().filter((r) => w.EntityKeyId == r.IdUserCharacter).length > 0);
                                if (matches.length > 0) {
                                    roleId = matches[0].EntityKeyId;
                                    row.RoleId(roleId);
                                    return;
                                }
                            }

                            row.RoleId((<any>row).resource.FkDefaultCharacter);
                        });
                //}
                /*if(billableField || estimateField) {
                    todoListService.GetTaskById(item.Id)
                        .then((t : ITodoListTask) => {
                            if(billableField)
                                billableField(t.ReportingType == 1);
                            if(estimateField)
                                estimateField(t.Duration || 0);
                        });
                }*/
            }

            row.WorkActivitiesContainerId(item.ContainerId); //containerIdField(item.ContainerId);
            row.WorkActivitiesContainerType(item.ContainerType); //containerTypeField(item.ContainerType);
            $(element).data("typeField", item.TypeCode);
            $(element).data("keyField", item.Id);
            return item.FullName;
        });

        var lastContainerId = row.WorkActivitiesContainerId(); //containerIdField();
        var lastContainerType = row.WorkActivitiesContainerType(); //containerTypeField();

        var interceptor = ko.computed(() => {
            var containerId = row.WorkActivitiesContainerId(); //containerIdField();
            var containerType = row.WorkActivitiesContainerType(); //containerTypeField();

            if(containerId == lastContainerId && containerType == lastContainerType)
                return;

                (<any>$(element)).typeahead("val", "");
            lastContainerId = containerId;
            lastContainerType = containerType;
        });

        //Gestione tasto freccia giù per aprire il menu senza digitare
        /*$(element).keyup((e) => {
            if(e.keyCode == 40)
                if($(element).val() == null) {
                    $(element).val("");
                }
                $(element).typeahead("open");
        });*/
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var row : WorkSheetRow = ko.utils.unwrapObservable(valueAccessor().row);

        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var billableField = valueAccessor()["billableField"];
        var estimateField = valueAccessor()["estimateField"];
        var containerIdField = valueAccessor()["containerIdField"];
        var containerTypeField = valueAccessor()["containerTypeField"];
        var descrField = valueAccessor()["descrField"];
        var previousType = $(element).data("typeField");
        var previousId = $(element).data("keyField");
        var workedHoursField = valueAccessor()["workedHoursField"];

        if(row.WorkActivityType() == previousType && row.WorkActivityId() == previousId) //if(typeField() == previousType && keyField() == previousId)
            return;

        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        var todoListService : ITodoListService = <ITodoListService> Core.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        if(!row.WorkActivityType() || !row.WorkActivityId()) //if(!typeField() || !keyField())
        {
            (<any>$(element)).typeahead("val", "");
            row.WorkActivityDescr(""); //if(descrField) descrField("");
            row.WorkActivityWorkflow("");
            return;
        }

        workableElementsService.GetWorkableElement(row.WorkActivityId(), row.WorkActivityType()) //(keyField(), typeField())
            .then((result : any) => {
                var item = result ? WorkActivitiesValue.transformItem(result) : null;
                var text =  item ? item.FullName : "";
                var containerId =  item ? item.ContainerId : null;
                var containerType =  item ? item.ContainerType : null;
                var activityWorkflow = item ? item.WorkflowTitle : "";

                (<any>$(element)).typeahead("val", text);
                row.WorkActivityDescr(text); //if(descrField) descrField(text);
                row.WorkActivityWorkflow(activityWorkflow);

                if(item && item.TypeCode == ProlifeSdk.JobOrderTaskEntityTypeCode) {
                    row.WorkedHours(item.WorkedHours || 0); //if(workedHoursField) workedHoursField(item.WorkedHours || 0);

                    if(row.IsInitializedFromPreferred() || row.Id == undefined || row.Id == null || row.Id <= 0) {
                        todoListService.GetTaskById(item.Id)
                            .then((t:ITodoListTask) => {
                                row.BillableInit(t.ReportingType == 1);
                                row.EstimatedWorkInit(t.Duration || 0);
                                row.IsTaskClosed(t.TaskBoardStatus >= 2);

                                var workRows = t.EstimatedBudgetRows.filter(r => r.Type == ProlifeSdk.EstimatedWorkEntityTypeCode);
                                if(workRows.length > 0) {
                                    var matches = workRows.filter((r) => r.EntityKeyId == (<any>row).resource.FkDefaultCharacter);
                                    var roleId: number;
                                    if (matches.length > 0) {
                                        roleId = matches[0].EntityKeyId;
                                        row.RoleId(roleId);
                                        return;
                                    }

                                    matches = workRows.filter((w) => row.Roles().filter((r) => w.EntityKeyId == r.IdUserCharacter).length > 0);
                                    if (matches.length > 0) {
                                        roleId = matches[0].EntityKeyId;
                                        row.RoleId(roleId);
                                        return;
                                    }
                                }

                                row.RoleId((<any>row).resource.FkDefaultCharacter);
                            });
                    }

                    /*if(billableField || estimateField) {
                        todoListService.GetTaskById(item.Id)
                            .then((t : ITodoListTask) => {
                                if(billableField)
                                    billableField(t.ReportingType == 1);
                                if(estimateField)
                                    estimateField(t.Duration || 0);
                            });
                    }*/
                }

                row.WorkActivitiesContainerType(containerType);
                row.WorkActivitiesContainerId(containerId);
                /*if(containerIdField)
                {
                    containerTypeField(containerType)
                    containerIdField(containerId);
                }*/
            });
    }

    private static findWorkActivitiesContainers(_this : any, findService : IWorkedHoursService, getClosedField : ko.Observable<boolean>,
                 containerIdField : any, containerTypeField : any,
                 query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any,
                 numberOfElementsLoaded? : number)
    {
        if(_this.lastTimeout)
            clearTimeout(_this.lastTimeout);

        var getClosed :boolean = getClosedField ? getClosedField() : true;

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            findService.GetWorkableElements(false, false, true, true, getClosed, query, numberOfElementsLoaded || 0, 50, -1, containerIdField(), containerTypeField())
                .then((results : any[]) => {
                    return asyncProcess(WorkActivitiesValue.transformItems(results));
                });
        }, 500);
    }

    private static transformItems(items : IWorkableElement[]) : any[] {
        return items.map(WorkActivitiesValue.transformItem);
    }

    private static getTaskStatusDescription(taskStatus: number) : string {
        switch(taskStatus) {
            case -1:
                return ProlifeSdk.TextResources.Todolist.Backlog;
            case 0:
                return ProlifeSdk.TextResources.Todolist.ToDo;
            case 1:
                return ProlifeSdk.TextResources.Todolist.InProgress;
            case 2:
                return ProlifeSdk.TextResources.Todolist.CompletedSingular;
            case 3:
                return ProlifeSdk.TextResources.Todolist.VerifiedSingular;
            case 4:
                return ProlifeSdk.TextResources.Todolist.Suspended;
            case 5:
                return ProlifeSdk.TextResources.Todolist.DeletedColumn
            default:
                return "";
        }
    }

    public static transformItem(item : IWorkableElement) : any {
        return {
            Id: item.KeyId,
            TypeCode: item.ElementType,
            FullName: item.Name,
            ProjectId : item.ProjectId,
            ContainerId : item.ContainerId,
            ContainerType : item.ContainerType,
            WorkedHours: item.WorkedHours,
            WorkflowId: item.WorkflowId,
            WorkflowTitle: item.WorkflowTitle,
            WorkflowStatus: item.WorkflowStatus,
            TaskStatus: WorkActivitiesValue.getTaskStatusDescription(item.TaskStatus),
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["workActivitiesValue"] = new WorkActivitiesValue();
