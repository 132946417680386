import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowForList } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class TodoListWorkflowsControlsEntityProvider implements IControlsEntityProvider
{
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService : ITodoListService;
    
    private lastTimeout : ReturnType<typeof setTimeout>;
    private JobOrderId : number = -1;

    constructor() {}

    public SetJobOrder(jobOrderId : number)
    {
        this.JobOrderId = jobOrderId || -1;
    }

    public findEntities(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.todoListService.GetWorkflowsForList(this.JobOrderId, query.term)
                .then((data) => {
                    query.callback({
                        results: data.map((g : ITodoListWorkflowForList) =>
                        {
                            return {
                                id: g.Id,
                                text: g.Title
                            };
                        })
                    });
                });
        }, 500);
    }

    /***
     * Estrae l'id già impostato sull'elemento e lo ritorna in formato lista
     * @param element
     * @param callback
     */
    public findEntity(element, callback)
    {
        var id = parseInt(<string>$(element).val());
        if(!isNaN(id) && id > 0) {
            this.todoListService.GetWorkflowForList(id)
                .then((g : ITodoListWorkflowForList) => callback({
                    id: g.Id,
                    text: g.Title
                }));
        }
    }
}
