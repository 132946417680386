import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";
import {
    IMailTemplatesSettingsManager,
    MailTemplate,
} from "../ProlifeSdk/interfaces/desktop/settings/IMailTemplatesSettingsManager";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export type MailTemplateDataSourceModel = IDataSourceModel<number, MailTemplate>;

export class MailTemplatesDataSource extends BaseDataSource<MailTemplateDataSourceModel, number, MailTemplate> {
    @LazyImportSettingManager(nameof<IMailTemplatesSettingsManager>())
    private mailTemplatesSettingsManager: IMailTemplatesSettingsManager;

    getTitle(currentModel: MailTemplateDataSourceModel): string {
        return "";
    }

    async getData(
        currentModel: MailTemplateDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<MailTemplateDataSourceModel[]> {
        return this.mailTemplatesSettingsManager
            .get()
            .filter(this.applyTextFilter.bind(this, textFilter))
            .slice(skip, skip + count)
            .map(this.createModel, this);
    }

    private applyTextFilter(textFilter: string, template: MailTemplate) {
        return TextFiltersUtilities.contains(template.name, textFilter);
    }

    private createModel(template: MailTemplate): MailTemplateDataSourceModel {
        return {
            id: template.id,
            title: template.name,
            isGroup: false,
            isLeaf: true,
            model: template,
        };
    }

    async getById(currentModel: MailTemplateDataSourceModel, ids: number[]): Promise<MailTemplateDataSourceModel[]> {
        return this.mailTemplatesSettingsManager
            .get()
            .filter(this.applyIdsFilter.bind(this, ids))
            .map(this.createModel, this);
    }

    private applyIdsFilter(ids: number[], template: MailTemplate) {
        return ids.indexOf(template.id) !== -1;
    }
}
