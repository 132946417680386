import * as Core from "../Core";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { IAuthorizationService, IAuthorizationServiceConfiguration } from "../interfaces/IAuthorizationService";
import { IAjaxService } from "../interfaces/IAjaxService";
import { IAuthenticationService } from "../interfaces/IAuthenticationService";
import { IService } from "../interfaces/IService";
import { IAuthenticationServiceObserver } from "../interfaces/IAuthenticationServiceObserver";
import { IUserForClient } from "../interfaces/IPrincipal";
import { IAuthorizationServiceObserver } from "../interfaces/IAuthorizationServiceObserver";

class AuthorizationService implements IAuthorizationService, IAuthenticationServiceObserver {
    private _currentUser : IUserForClient;
    private _ajaxService : IAjaxService;
    private _authenticationService : IAuthenticationService;
    private _rights : { [feature: string]: boolean };
    private _observers: IAuthorizationServiceObserver[];

    constructor(serviceLocator: IServiceLocator, private configuration : IAuthorizationServiceConfiguration = Core.configuration.authorizationConfig) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IAuthorizationService>(), this);

        this._currentUser = null;
        this._rights = {};
        this._observers = [];
        this._authenticationService = <IAuthenticationService> serviceLocator.findService(ServiceTypes.Authentication);
        this._ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);

        //this._authenticationService.addObserver(this);
    }

    addObserver(observer : IAuthorizationServiceObserver) : void {
        this._observers.push(observer);
    }

    removeObserver(observer : IAuthorizationServiceObserver) : void {
        const index = this._observers.indexOf(observer);
        if(index == -1) return;
        this._observers = this._observers.splice(index, 1);
    }

    isOfType(serviceType: string) : boolean {
        return serviceType == this.getServiceType();
    }

    getServiceType() : string {
        return ServiceTypes.Authorization;
    }

    isAuthorized(feature: string) : boolean
    {
        return this._rights[feature];
    }

    public refreshAuthorization()
    {
        this.loadAuthorizations();
    }

    private loadAuthorizations()
    {
        this._ajaxService.Post(this.configuration.serviceName,
            this.configuration.loadRightsMethodName, {})
            .then(this.authorizationLoaded.bind(this));
    }

    private authorizationLoaded(data)
    {
        this._rights = {};

        for(let i = 0; i < data.length; i++)
        {
            this._rights[data[i]] = true;
        }

        for(let i = 0; i < this._observers.length; i++) {
            this._observers[i].authorizationLoaded(this._rights);
        }
    }

    private clearAuthorizations()
    {
        this._rights = {};
    }

    userLoggedIn(user: IUserForClient) : void {
        this._currentUser = user;
        this.loadAuthorizations();
    }

    userLoggedOut() : void {
        this._currentUser = null;
        this.clearAuthorizations();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loginFailed() : void {}
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new AuthorizationService(serviceLocator);
}