import * as React from "@abstraqt-dev/jsxknockout";
import { INavigationMenuComponentTemplatesProvider, INavigationMenuComponentModel, INavigationMenuComponentItem } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSource } from "../../../../DataSources/IDataSource";
import { DateDataSource, IDayNavigationMenuComponentModel } from "./DateDataSource";
import { WorkedHoursJobOrderDateDataSource, IDayNavigationMenuComponentModel as IJobOrderDayNavigationMenuComponentModel } from "../../../../DataSources/WorkedHoursJobOrderDateDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Right } from "../../../../Core/Authorizations";

export class DatesMenuTemplateProvider implements INavigationMenuComponentTemplatesProvider {
    @Right("WorkedHours_CanApproveHours")
    private WorkedHours_CanApproveHours: boolean;

    public hasTemplateFor(dataSource: IDataSource, model: INavigationMenuComponentModel): boolean {
        if (dataSource instanceof DateDataSource) {
            let dayModel = model as IDayNavigationMenuComponentModel;
            return !!dayModel.isDay;
        }
        
        if (dataSource instanceof WorkedHoursJobOrderDateDataSource) {
            let dayModel = model as IJobOrderDayNavigationMenuComponentModel;
            return !!dayModel.isDay;
        }

        return false;
    }
    
    public templatesProvider(dataSource: IDataSource, model: INavigationMenuComponentModel): Node {
        if (dataSource instanceof DateDataSource)
            return this.datesMenuTemplate();

        if (dataSource instanceof WorkedHoursJobOrderDateDataSource)
            return this.jobOrderDatesMenuTemplate();

        return <></>;
    }

    private datesMenuTemplate(): Node {
        let $data: IDayNavigationMenuComponentModel;
        let $index: () => number;

        return  <div data-bind={{ style: { 'border-left' : $data.isToday ? '4px solid rgb(217, 168, 19)' : '' } }} style={{ margin: "-10px", padding: "10px" }} className="day-menu-component">
                    <div>
                        <span data-bind={{ style : { color : $data.isToday ? '#d9a813' : ($data.isHoliday ? '#db6f57' : ''), 'font-size' : $data.isToday ? '18px' : 'inherit' } }}>
                            <span><strong data-bind={{ text : $data.dayNumber }}></strong></span>&nbsp;
                            <span data-bind={{ text: $data.title }}></span>
                            <span data-bind={{ visible: $data.isLocked, attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {} }} style={{ color: "orange" }} title={TextResources.WorkedHours.WorkedHoursLockedTitle}>
                                <i className="fa fa-lock"></i>
                            </span>
                        </span>
                        <span className="pull-right" style={{ marginLeft: "10px" }} title={TextResources.WorkedHours.WorkedHoursHoursStatusTitle} data-bind={{ timeTextFromNumber: $data.numericTotalHours, style: { color: $data.hasOverImbalance ? '#2b8b3a' : ($data.hasDownImbalance ? '#d9a813' : ($data.hasExtraordinaryHours ? '#d93713' : 'inherit')) } }}></span>
                
                        {this.approvalIconsTemplate()}
                    </div>
                    <div data-bind={{ html: $data.detail }}></div>
                    <div data-bind={{ visible: !$data.detail }}>
                        &nbsp;
                    </div>
                    <ko-bind data-bind={{ if: $data.hasOverImbalance }}>
                        <div className="pull-right fa fa-toggle-up" title={TextResources.WorkedHours.WorkedHoursHoursStatusTitle} style={{ position: "absolute", right: "10px", bottom: "8px", color: "#2b8b3a" }}></div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: $data.hasDownImbalance }}>
                        <div className="pull-right fa fa-toggle-down" title={TextResources.WorkedHours.WorkedHoursHoursStatusTitle} style={{ position: "absolute", right: "10px", bottom: "8px", color: "#d9a813" }}></div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: $data.hasExtraordinaryHours }}>
                        <div className="pull-right fa fa-clock-o" title={TextResources.WorkedHours.WorkedHoursHoursStatusTitle} style={{ position: "absolute", right: "35px", bottom: "8px", color: "#d93713" }}></div>
                    </ko-bind>
                </div>;
    }
    
    private jobOrderDatesMenuTemplate(): Node {
        let $data: IJobOrderDayNavigationMenuComponentModel;
        let $index: () => number;

        return  <div data-bind={{ style: { 'border-left' : $data.isToday ? '4px solid rgb(217, 168, 19)' : '' } }} style={{ margin: "-10px", padding: "10px" }} className="day-menu-component">
                    <div>
                        <span data-bind={{ style : { color : $data.isToday ? '#d9a813' : ($data.isHoliday ? '#db6f57' : ''), 'font-size' : $data.isToday ? '18px' : 'inherit' } }}>
                            <span><strong data-bind={{ text : $data.dayNumber }}></strong></span>&nbsp;
                            <span data-bind={{ text : $data.title }}></span>
                            <span data-bind={{ visible: $data.isLocked, attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {} }} style={{ color: "orange" }} title={TextResources.WorkedHours.WorkedHoursLockedTitle}>
                                <i className="fa fa-lock"></i>
                            </span>
                        </span>
                        <span className="pull-right" style={{ marginLeft: "10px" }} title={TextResources.WorkedHours.WorkedHoursHoursStatusTitle} data-bind={{ timeTextFromNumber: $data.numericTotalHours, style: { color: $data.hasExtraordinaryHours ? '#d93713' : 'inherit' } }}></span>
                
                        {this.approvalIconsTemplate()}
                    </div>
                    &nbsp;
                </div>;
    }

    private approvalIconsTemplate() {
        let $data: IDayNavigationMenuComponentModel;
        let $index: () => number;
        let $parent: INavigationMenuComponentItem;

        if (this.WorkedHours_CanApproveHours)
            return  <span className="pull-right approval-icons">
                        <span style={{ color: "lime" }} title={TextResources.WorkedHours.WorkedHoursApprovedHoursTitle} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.allApproved || $data.partiallyApproved) ? 'lime' : (!$parent.Selected() ? '#4e4e4e' : '#859caf') } }}>
                            <i className="glyphicon glyphicon-ok"></i>
                        </span>

                        <span style={{ color: "red" }} title={TextResources.WorkedHours.WorkedHoursRefusedHoursTitle} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.someRejected ? 'red' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) } }}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </span>

                        <span style={{ color: "orange" }} title={TextResources.WorkedHours.WorkedHoursToBeApprovedHoursTitle} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.partiallyApproved || (!$data.partiallyApproved && !$data.someRejected && !$data.allApproved && $data.numericTotalHours > 0) ? 'orange' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) } }}>
                            <i className="glyphicon glyphicon-exclamation-sign"></i>
                        </span>

                        <span style={{ color: "orange" }} title={TextResources.WorkedHours.WorkedHoursFlexibilityIsPresent} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.hasFlexibility  ? 'orange' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) } }}>
                            <i className="glyphicon glyphicon-retweet"></i>
                        </span>
                    </span>;

        return  <span className="pull-right approval-icons">
                    <span style={{ color: "lime" }} title={TextResources.WorkedHours.WorkedHoursApprovedHoursTitle} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.allApproved || $data.partiallyApproved) ? 'lime' : (!$parent.Selected() ? '#4e4e4e' : '#859caf') }/* , visible: ($data.allApproved || $data.partiallyApproved) */ }}>
                        <i className="glyphicon glyphicon-ok"></i>
                    </span>

                    <span style={{ color: "red" }} title={TextResources.WorkedHours.WorkedHoursRefusedHoursTitle} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.someRejected ? 'red' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) }/* , visible: $data.someRejected */ }}>
                        <i className="glyphicon glyphicon-remove"></i>
                    </span>

                    <span style={{ color: "orange" }} title={TextResources.WorkedHours.WorkedHoursFlexibilityIsPresent} data-bind={{ attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: ($data.hasFlexibility  ? 'orange' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) } }}>
                            <i className="glyphicon glyphicon-retweet"></i>
                        </span>
                </span>;
    }
}