import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { classNames, ComponentUtils, PropsWithChildren, reloadNow } from "../../Core/utils/ComponentUtils";
import { RouteParams, _Route } from "./Route";
import { Template } from "../../bindings";
import { useService } from "../../Core/DependencyInjection";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import * as Route from "route-parser";
import * as _ from "lodash";

type RoutesProps = PropsWithChildren<{
    className?: string;
}>;

export function Routes(props: RoutesProps) {
    const C = require("./Routes")._Routes as typeof _Routes;
    return <C {...props} />;
}

export class _Routes {
    static defaultProps: Partial<RoutesProps> = {
    }

    public routes: { path: Route, element: (params: RouteParams) => React.ReactNode; }[] = [];
    ShownRoute : ko.Observable<() => React.ReactNode> = ko.observable();

    private shownRouteModel : { path: Route, element: (params: RouteParams) => React.ReactNode; };
    private currentParams: false | { [x: string]: string };
    private opaService: IOPAService;
    onLocationChange: (url: string) => void;

    constructor(private props : RoutesProps) {
        ComponentUtils.Children.forEach(props.children, c => {
            const route = ComponentUtils.getComponent<_Route>(c);
            this.routes.push({
                path: new Route(route.props.path + (!route.props.exact ? "(*any)" : "")),
                element: route.props.element
            });
        });

        this.opaService = useService(nameof<IOPAService>());
        this.onLocationChange = this.handleLocationChange.bind(this);
    }

    componentDidMount() {
        this.opaService.OnChange(this.onLocationChange);
        if(!this.ShownRoute()) {
            const reversed = this.routes[0].path.reverse({});
            if(!reversed) return;

            this.navigateTo(reversed);
        }
    }

    componentWillUnmount() {
        this.opaService.OffChange(this.onLocationChange);
    }

    public navigateTo(url: string) {
        this.opaService.NavigateTo("/#" + url.replace("/#", "").replace("#", ""));
    }

    private handleLocationChange(url: string) {
        for(const route of this.routes) {
            const params = route.path.match(url.replace("/#", ""));
            if(params) {
                if(params.any)
                    params.any = undefined; //ignoriamo il campo any

                if(this.shownRouteModel === route && _.isEqual(params, this.currentParams)) return;

                this.ShownRoute(() => route.element({
                    ...params,
                    navigator: this
                }));
                this.shownRouteModel = route;
                this.currentParams = params;
                return;
            }
        }

        this.ShownRoute(undefined);
        this.shownRouteModel = undefined;
        this.currentParams = {};
    }
    
    render() {
        return  <div className={classNames("flex-1", this.props.className)}>
                    <Template component={this.ShownRoute} onError={() => <div>Si è verificato un errore durante il rendering della route</div>} />
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(Routes);
}