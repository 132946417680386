import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IPurchaseTypeSettingManager } from "../SettingManagers/PurchaseTypeSettingManager";
import { IDataSourceModel } from "./IDataSource";
import { ITodoListService, IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IUserCharactersSettingsManager } from "../ProlifeSdk/interfaces/users/IUserCharacter";

export interface IWorkflowSnapshotTask {
    SnapshotId: number;
    TaskId: number;
    Title: string;
    Description: string;
    EstimatedBudgetRows: IEstimatedBudgetForTasksSnapshots[];
}

export interface IEstimatedBudgetForTasksSnapshots {
    SnapshotId: number;
    EstimatedBudgetId: number;
    EstimatedBudgetForTaskId: number;
    TaskId: number;
    Description: string;
    UnitPrice: number;
    Amount: number;
    Price: number;
    Type: string;
    EntityKeyId: number;
    Multiplier: number;
    Markup: number;
    UnitCost: number;
    Cost: number;
    Discount: number;
    Formula: string;
    NetUnitPrice: number;
    DiscountValues: string;
    IsCompound: boolean;
    FKState: number;
    Notes: string;
    Order: number;

    EntityName: string;
}

export class WorkflowSnapshotTasksDataSource extends BaseDataSource<IDataSourceModel> {
    private snapshotId: number = undefined;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;

    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesSettingManager: IPurchaseTypeSettingManager;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager: IUserCharactersSettingsManager;

    public setSnapshotId(snapshotId: number): void {
        this.snapshotId = snapshotId;
    }

    public getTitle(currentModel: IDataSourceModel): string {
        return TextResources.Todolist.WorkflowSnapshotContentDataSource;
    }

    public async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, IWorkflowSnapshotTask>[]> {
        let snapshotEstimatedBudgetRowsWithTasksData = await this.todolistService.GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData(this.snapshotId, skip, count, textFilter);
        return this.createModels(snapshotEstimatedBudgetRowsWithTasksData);
    }
    
    public async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel<number, IWorkflowSnapshotTask>[]> {
        let snapshotEstimatedBudgetRowsWithTasksData = await this.todolistService.GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksDataByTasksIds(this.snapshotId, ids);
        return this.createModels(snapshotEstimatedBudgetRowsWithTasksData);
    }


    private createModels(snapshotEstimatedBudgetRowsWithTasksData: IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]): IDataSourceModel<number, IWorkflowSnapshotTask>[] {
        let tasks = this.createTasks(snapshotEstimatedBudgetRowsWithTasksData);
        return tasks.map(this.createModel, this);
    }

    private createModel(task: IWorkflowSnapshotTask): IDataSourceModel<number, IWorkflowSnapshotTask> {
        return {
            id: task.TaskId,
            isGroup: false,
            isLeaf: true,
            title: task.Title,
            model: task
        };
    }

    private createTasks(snapshotEstimatedBudgetRowsWithTasksData: IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]): IWorkflowSnapshotTask[] {
        let tasks: IWorkflowSnapshotTask[] = [];

        let lastTask: IWorkflowSnapshotTask = null;

        for (let row of snapshotEstimatedBudgetRowsWithTasksData) {
            if (!lastTask || lastTask.TaskId != row.TaskId) {
                lastTask = {
                    SnapshotId: row.SnapshotId,
                    TaskId: row.TaskId,
                    Title: row.Title,
                    Description: row.TaskDescription,
                    EstimatedBudgetRows: [],
                };
                
                tasks.push(lastTask);
            }

            if (!row.Id)
                continue;

            let ebtRow = {
                SnapshotId: row.SnapshotId,
                EstimatedBudgetId: row.Id,
                TaskId: row.TaskId,
                Description: row.Description,
                UnitPrice: row.UnitPrice,
                Amount: row.Amount,
                Price: row.Price,
                Type: row.Type,
                EntityKeyId: row.EntityKeyId,
                EntityName: row.Description,
                Multiplier: row.Multiplier,
                Markup: row.Markup,
                UnitCost: row.UnitCost,
                Cost: row.Cost,
                Discount: row.Discount,
                Formula: row.Formula,
                DiscountValues: row.DiscountValues,
                EstimatedBudgetForTaskId: row.EstimatedBudgetForTaskId,
                FKState: row.FKState,
                IsCompound: row.IsCompound,
                NetUnitPrice: row.NetUnitPrice,
                Notes: row.Notes,
                Order: row.Order
            };

            if (row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode) {
                let role = this.rolesManager.getUserCharacterById(row.EntityKeyId);
                ebtRow.EntityName = role?.Description;
            } else if (row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
                let purchaseType = this.purchaseTypesSettingManager.getById(row.EntityKeyId);
                ebtRow.EntityName = purchaseType?.Title;
            }

            lastTask.EstimatedBudgetRows.push(ebtRow);
        }

        return tasks;
    }
}