import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/03/2018
 * Time: 15:26
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IEventOnDatesIntervalDetails } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";

export class PlannedEventsAlertDialog implements IDialog {
    public templateName: string = "planned-events-alert-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Agenda.PlannedEventsDialogTitle;

    public Message: ko.Observable<string> = ko.observable();
    public EventsList: ko.ObservableArray<IEventOnDatesIntervalDetails> = ko.observableArray([]);

    public ShowDeleteButton: boolean = false;

    modal: { close: (results?: any) => void; };

    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, events: IEventOnDatesIntervalDetails[], message: string, showDeleteButton: boolean = false) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.Message(message);
        this.EventsList(events);

        this.ShowDeleteButton = showDeleteButton;
    }

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        this.modal.close(true);
    }

    public Show(): Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(this, "large", null, "agenda/templates/dialogs", "planned-events-alert-dialog");
    }
}