import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IDocumentBuilderDocumentRelatedWorkflows } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IActivitiesForDocuments, IActivitiesSourceForDocuments } from "../../../ProlifeSdk/interfaces/todolist/IActivitiesForDocuments";
import { IEstimateBudgetRow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDiscountsService, IDiscountsManager } from "../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { IArticle } from "../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class ActivitiesWarehouseRow implements IActivitiesForDocuments
{
    ArticleId : ko.Observable<number> = ko.observable();

    Description : ko.Observable<string> = ko.observable();
    AmountFormula : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    Discounts : ko.Observable<string> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    UOM: ko.Observable<string> = ko.observable();

    CatalogId : ko.Observable<number> = ko.observable();

    Sources : IActivitiesSourceForDocuments[] = [];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[] = [];

    constructor(private discountsService : IDiscountsService, discountsManager : IDiscountsManager, public article : IArticle, private estimateBudgetRow: IEstimateBudgetRow, amount : number, extraPrice : number = 0) {
        this.ArticleId(article.ArticleId);
        this.Description(article.Code + " " + article.Description);
        this.CatalogId(article.CatalogId);
        this.UnitPrice(article.CustomerPrice);
        this.UOM(article.UOM);
        this.Discounts(discountsManager ? discountsManager.getFormattedDiscountsForArticle(article) : undefined);

        this.Amount(amount);
        this.updateNetUnitPrice(extraPrice);
        this.generateSources();
    }

    public updateNetUnitPrice(extraPrice : number)
    {
        var discount = 1.0;
        if(this.Discounts()) {
            discount = this.discountsService.calculateDiscount(this.Discounts());
        }
        this.NetUnitPrice((this.UnitPrice() * discount) + (extraPrice / this.Amount()));
        this.UnitPrice(this.NetUnitPrice() / discount);
        this.Total(this.Amount() * this.NetUnitPrice());
    }

    private generateSources()
    {
        this.Sources = [{
            Description: this.Description(),
            Amount: this.Amount(),
            UnitPrice: this.UnitPrice(),
            Discounts: this.Discounts(),
            NetUnitPrice: this.NetUnitPrice(),
            UOM: this.UOM(),
            Total: this.Total(),

            EntityKeyId: this.getEntityId(),
            EntityType: ProlifeSdk.WarehouseArticleEntityTypeCode,
            CatalogId: this.CatalogId(),

            EstimateBudgetRowId: this.estimateBudgetRow.Id,
            TaskId: this.estimateBudgetRow.TaskId
        }];
    }

    private getEntityId() {
        return this.ArticleId();
    }

    public references(row : IEstimateBudgetRow) : boolean {
        return false; //ProlifeSdk.WarehouseArticleEntytyTypeCode == row.Type && this.row.Id == row.Id;
    }
}