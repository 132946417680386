import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 27/02/2018
 * Time: 09:25
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { FestivityEditingDialog } from "./dialogs/FestivityEditingDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFestivityUIProvider, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IFullCalendar } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";

export class FestivitiesUIProvider implements IFestivityUIProvider {
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
    }

    GetFestivityEditingDialog(festivityId: number = null, agendaId: number = null, calendar: IFullCalendar = null): Promise<IFestivity> {
        var dialog = new FestivityEditingDialog(this.serviceLocator, festivityId, agendaId, calendar);
        return dialog.ShowDialog();
    }
}