import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IReminderService, IRemindObjReferenceUi, IEntityRefKeyWithPath } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IEntityProviderService, IEntityDescriptor } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { Deferred } from "../../../../Core/Deferred";

export class RelateToObjManager implements IDialog{
    private entitiesService : IEntityProviderService;
    private toastService : IInfoToastService;
    private dialogsService : IDialogsService;
    private remindService : IReminderService;

    public templateName: string = "remind-relate-obj-dialog";
    public templateUrl: string = "reminder/templates";
    public title: string = ProlifeSdk.TextResources.Reminder.RemindRelateObjDialogTitle;
    modal: { close: (result?: any) => void; };

    public entityType : IDropDownList;
    public referenceUi : ko.Observable<any> = ko.observable();

    private initializing : boolean = false;

    constructor(private serviceLocator : IServiceLocator) {
        this.remindService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.entityType = this.entitiesService.getEntitiesDropDown((e : IEntityDescriptor) => { return e.CanBeRelatedToRemind; });

        this.entityType.selectedId.subscribe(() => { this.initialize(this.entityType.selectedId(), null)});
    }

    public initialize(entityType : string, id : number) : Promise<void> {
        var def = new Deferred<void>();

        if(this.initializing)
            return def.resolve().promise();

        this.initializing = true;
        this.entityType.selectedId(entityType);

        var ui : IRemindObjReferenceUi = this.remindService.getObjReferenceUiFor(entityType);

        if(ui)
            ui.initialize({ EntityKeyType : entityType, EntityKeyId : id }).then(() => { def.resolve(); });

        this.referenceUi(ui);
        this.initializing = false;
        return def.promise();
    }

    public getSelectedRef() : Promise<IEntityRefKeyWithPath> {
        var def = new Deferred<IEntityRefKeyWithPath>();

        if(!this.referenceUi())
            return def.resolve(null).promise();

        this.referenceUi().getSelectedEntityPathDescription().then((pathDescription : string) => {
            var ref : IEntityRefKey = this.referenceUi().getSelectedEntityKey();
            return def.resolve({
                EntityKeyType : ref.EntityKeyType,
                EntityKeyId : ref.EntityKeyId,
                PathDescription : pathDescription
            });
        });

        return def.promise();
    }

    public close(){
        this.modal.close(false);
    }

    public action(){
        this.modal.close(true);
    }
}