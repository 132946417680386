import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IControlsMultipleEntitiesProvider, IItemIdWithType } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IEntitySearchResult } from "../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { ILogFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class MultipleEntitiesProviderForControls implements IControlsMultipleEntitiesProvider
{
    private lastTimeout : ReturnType<typeof setTimeout>;
    private entitiesProviderService : IEntityProviderService;

    constructor(serviceLocator : IServiceLocator, private entitiesCodes : string[], private supportFreeText : boolean, private showType : boolean,
                private defaultTextLabel : string = ProlifeSdk.TextResources.Desktop.TextAndDate)
    {
        this.entitiesProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
    }

    public findEntities(query: any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            var searchFilter : string = query.term;
            var callback : any = query.callback;

            var data = { results: [] };

            var providersDef = new Deferred();
            this.entitiesProviderService.search(searchFilter, this.entitiesCodes).then((results : IEntitySearchResult[]) => {

                var allTagsFilters :ILogFilter[] = [];

                results.forEach((result : IEntitySearchResult) => {

                    if(result.entities.length == 0)
                        return;

                    var menuGroup = {
                        text : result.provider.getEntityTypeName(),
                        children : []
                    }

                    result.entities.forEach(e => {
                        menuGroup.children.push(this.FormatProviderItem(e, result.provider));
                    });

                    data.results.push(menuGroup);

                });

                //Voce Tutto (ricerca il contenuto in tutti i campi)
                if(this.supportFreeText)
                {
                    var getForAllFilter : ILogFilter = {
                        IsSearchForAllFilter : true,
                        HintSearch : searchFilter
                        //Tags : allTagsFilters
                    }

                    data.results.splice(0, 0, {
                        text : this.defaultTextLabel,
                        children : [{
                            id : JSON.stringify(getForAllFilter),
                            text : searchFilter
                        }]
                    });
                }

                providersDef.resolve();
            }).catch(() => providersDef.reject());

            Promise.all([providersDef.promise()]).then(() => {
                callback(data);
            });
        }, 500);
    }

    public findEntity(element, callback)
    {
        var values = <string> $(element).val();
        if(values !== "")
        {
            var result : any[] = [];
            var items : string[] = values.split("|");
            var defs : Promise<any>[] = [];
            var providersWithItems : any[] = [];
            items.forEach((stringItem : string) =>
            {
                var type = stringItem.split("_")[0];
                var id : number = parseInt(stringItem.split("_")[1]);    //Attenzione, considero solo ID interi!

                var entityProvider : IEntityProvider = this.entitiesProviderService.getEntityProvider(type);
                defs.push(entityProvider.getEntity(id).then((item) => {
                    providersWithItems.push({ Item : item, Provider : entityProvider });
                }));
            });

            Promise.all(defs).then(() => {
                providersWithItems.forEach((p) => {
                    result.push(this.FormatProviderItem(p.Item, p.Provider));
                });
                callback(result);
            });
        }
    }

    public formatItemsForControlValueField(itemsWithTypes : IItemIdWithType[]) : string
    {
        return itemsWithTypes.map((i : IItemIdWithType) => {
            return i.Type + "_" + i.Id.toString();
        }).join("|");
    }

    public extractItemsAndTypesFromStringValue(stringValue : string) : IItemIdWithType[]
    {
        return stringValue.split("|")
            .filter((s : string) => { return s && s.trim().length > 0; })
            .map((s : string) => {
                var type : string = s.split("_")[0];
                var id : number = parseInt(s.split("_")[1]);
                return <IItemIdWithType>{
                    Type : type,
                    Id : id
                }
            });
    }

    private FormatProviderItem(item : any, provider : IEntityProvider)
    {
        return {
            id : provider.getType() + "_" + provider.getPkValue(item),
            text : (this.showType ? provider.getEntityTypeName() + ":   " : "") + provider.getDisplayName(item)
        }
    }
}
