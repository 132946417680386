import * as ko from "knockout";
export class RadioButton implements ko.BindingHandler<ko.Observable<boolean>> {
    init(element: HTMLInputElement, valueAccessor: () => ko.Observable<boolean>, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) : void | { controlsDescendantBindings: boolean; } {
        let value = valueAccessor();
        let radioName = $(element).attr("name");
        let preventChanges = false;

        $(element).on("change", () => {
            if(preventChanges)
                return;

            preventChanges = true;

            value(element.checked);

            if(element.checked) {
                let allRadios = $("input[type=radio][name=" + radioName + "]");
                allRadios.each((index, e) => {
                    if(e === element)
                        return;

                    $(e).trigger("change");
                });
            }

            preventChanges = false;
        });
    }

    update(element: HTMLInputElement, valueAccessor: () => ko.Observable<boolean>, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) : void | { controlsDescendantBindings: boolean; } {
        let value = ko.unwrap(valueAccessor());
        if(value) {
            element.checked = true;
        }
    }
}

ko.bindingHandlers["radio"] = new RadioButton();