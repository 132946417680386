import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 24/01/2019
 * Time: 19:04
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IRecipientCodeViewModel } from "../../interfaces/IRecipientCodeViewModel";
import { IRecipientCode } from "../../../ProlifeSdk/interfaces/customer/IRecipientCode";
import { ICustomerViewModel } from "../../interfaces/ICustomerViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator, IValidation } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

export class RecipientCodeViewModel implements IRecipientCodeViewModel {
    public Id: number;
    public RecipientCode: ko.Observable<string> = ko.observable();
    public PEC: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public FocusOnPECField: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    private validator : IValidator<RecipientCodeViewModel>;

    private isLoading: boolean = false;

    constructor(private recipientCode: IRecipientCode, private customerViewModel: ICustomerViewModel) {
        this.isLoading = true;

        this.Id = this.recipientCode.Id;
        this.RecipientCode(this.recipientCode.RecipientCode);
        this.PEC(this.recipientCode.PEC);
        this.Description(this.recipientCode.Description);

        this.RecipientCode.subscribe((value: string) => {
            if (this.isLoading)
                return;

            this.OnCodeChanges();
        });

        this.isLoading = false;

        this.validator = this.validationService.createValidator<RecipientCodeViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(r => r.RecipientCode(), TextResources.Customers.EmptyRecipientCodeNotAllowed)
            .stringLengthBetween(r => r.RecipientCode(), 6, 6, () => String.format(ProlifeSdk.TextResources.Customers.RecipientCodeLengthError, this.RecipientCode(), 6), () => this.customerViewModel.PublicAdministration() && this.customerViewModel.ElectronicInvoicing())
            .stringLengthBetween(r => r.RecipientCode(), 7, 7, String.format(ProlifeSdk.TextResources.Customers.RecipientCodeLengthError, this.RecipientCode(), 7), () => !this.customerViewModel.PublicAdministration() && this.customerViewModel.ElectronicInvoicing());
    }

    public GetData(): IRecipientCode {
        var obj: IRecipientCode = <IRecipientCode> $.extend(true, {}, this.recipientCode);

        obj.RecipientCode = this.RecipientCode();
        obj.PEC = this.PEC();
        obj.Description = this.Description();

        return obj;
    }

    public Delete(): void {
        this.customerViewModel.DeleteRecipientCode(this);
    }

    public GetValidation(): IValidator<RecipientCodeViewModel> {
        return this.validator;
    }

    private OnCodeChanges(): void {
        this.customerViewModel.OnRecipientCodeChanges(this);
    }
}