import * as Core from "../Core/Core";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";

export interface IValidationService {
    createValidator<T>(): IValidator<T>;
}

export interface IValidation {
    valid: boolean;
    message?: string;
}

export interface IValidator<T> {
    isNull<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T>;
    isUndefined<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T>;
    isNullOrUndefined<V>(
        selector: (value: T) => V,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isNotNull<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T>;
    isNotUndefined<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T>;
    isNotNullOrUndefined<V>(
        selector: (value: T) => V,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isTrue<V>(
        selector: (value: T) => V,
        errorMessage?: string | (() => string),
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isFalse<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T>;
    isNotNullOrUndefinedOrEmpty(
        selector: (value: T) => string,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isNotNullOrUndefinedOrWhiteSpace(
        selector: (value: T) => string,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isNotNullUndefinedOrZero(
        selector: (value: T) => number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    isNotNullUndefinedOrLessOrEqualZero(
        selector: (value: T) => number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    stringMaxLength(
        selector: (value: T) => string,
        maxLength: number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    stringMinLength(
        selector: (value: T) => string,
        minLength: number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;
    stringLengthBetween(
        selector: (value: T) => string,
        minLength: number,
        maxLength: number,
        errorMessage?: string | (() => string),
        onlyIf?: (value: T) => boolean
    ): IValidator<T>;

    validate(value: T): IValidation[];
    validateAndShowInfoToast(value: T): boolean;
}

export interface IValidationRule<T> {
    validate(value: T): IValidation;
}

export class Validator<T> implements IValidator<T> {
    public rules: IValidationRule<T>[] = [];

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public error(message: string): IValidation {
        return {
            valid: false,
            message: message,
        };
    }

    public ok(): IValidation {
        return {
            valid: true,
        };
    }

    isNull<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val !== null) {
                    return this.error(errorMessage ?? "Il valore deve essere null: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isUndefined<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val !== undefined) {
                    return this.error(errorMessage ?? "Il valore deve essere undefined: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isNullOrUndefined<V>(
        selector: (value: T) => V,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val !== null && val !== undefined) {
                    return this.error(errorMessage ?? "Il valore deve essere null o undefined: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNull<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null) {
                    return this.error(errorMessage ?? "Il valore non deve essere null: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotUndefined<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === undefined) {
                    return this.error(errorMessage ?? "Il valore non deve essere undefined: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNullOrUndefined<V>(
        selector: (value: T) => V,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null || val === undefined) {
                    return this.error(
                        errorMessage ?? "Il valore non deve essere null o undefined: " + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    isTrue<V>(
        selector: (value: T) => V,
        errorMessage?: string | (() => string),
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (!val) {
                    return this.error(
                        errorMessage
                            ? typeof errorMessage === "function"
                                ? errorMessage()
                                : errorMessage
                            : "Il valore deve essere true: " + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    isFalse<V>(selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val) {
                    return this.error(errorMessage ?? "Il valore deve essere false: " + selector.toString());
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNullOrUndefinedOrEmpty(
        selector: (value: T) => string,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null || val === undefined || val === "") {
                    return this.error(
                        errorMessage ?? "La stringa non deve essere null, undefined o vuota: " + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNullOrUndefinedOrWhiteSpace(
        selector: (value: T) => string,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null || val === undefined || val.trim() === "") {
                    return this.error(
                        errorMessage ??
                            "La stringa non deve essere null, undefined o contenente solo spazi: " + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNullUndefinedOrZero(
        selector: (value: T) => number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null || val === undefined || val === 0) {
                    return this.error(
                        errorMessage ?? "La stringa non deve essere null, undefined o zero: " + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    isNotNullUndefinedOrLessOrEqualZero(
        selector: (value: T) => number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if (val === null || val === undefined || val <= 0) {
                    return this.error(
                        errorMessage ??
                            "La stringa non deve essere null, undefined oppure minore o uguale di zero: " +
                                selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    stringMaxLength(
        selector: (value: T) => string,
        maxLength: number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if ((val || "").length > maxLength) {
                    return this.error(
                        errorMessage ??
                            `La stringa non deve essere più lunga di ${maxLength} caratteri: ` + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    stringMinLength(
        selector: (value: T) => string,
        minLength: number,
        errorMessage?: string,
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value);
                if ((val || "").length < minLength) {
                    return this.error(
                        errorMessage ??
                            `La stringa deve essere lunga almeno ${minLength} caratteri: ` + selector.toString()
                    );
                }
                return this.ok();
            },
        });
        return this;
    }

    stringLengthBetween(
        selector: (value: T) => string,
        minLength: number,
        maxLength: number,
        errorMessage?: string | (() => string),
        onlyIf?: (value: T) => boolean
    ): IValidator<T> {
        this.rules.push({
            validate: (value: T) => {
                if (onlyIf && !onlyIf(value)) return this.ok();

                const val = selector(value) || "";
                if (val.length < minLength || val.length > maxLength) {
                    const msg =
                        typeof errorMessage === "function"
                            ? errorMessage()
                            : typeof errorMessage === "string"
                            ? errorMessage
                            : `La lunghezza della stringa deve essere compresa tra ${minLength} e ${maxLength} caratteri: ` +
                              selector.toString();
                    return this.error(msg);
                }
                return this.ok();
            },
        });
        return this;
    }

    validate(value: T): IValidation[] {
        const result: IValidation[] = [];
        for (const rule of this.rules) {
            const res = rule.validate(value);
            if (!res.valid) result.push(res);
        }
        return result;
    }

    validateAndShowInfoToast(value: T): boolean {
        const result = this.validate(value);
        if (result.length > 0) {
            this.infoToastService.Error(result.map((r) => r.message).join("<br/>"));
            return false;
        }
        return true;
    }
}

@Service(nameof<IValidationService>())
export class ValidationService implements IValidationService {
    public createValidator<T>(): IValidator<T> {
        return new Validator<T>();
    }
}

export default function () {
    return Core.serviceLocator.findService(nameof<IValidationService>());
}
