import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { JobOrderResponsiblesApplicationMode } from "../../enums/JobOrderResponsiblesApplicationMode";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type JobOrderResponsiblesApplicationModesProps = {
}

export class JobOrderResponsiblesApplicationModes extends DialogComponentBase {
    static defaultProps: Partial<JobOrderResponsiblesApplicationModesProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : JobOrderResponsiblesApplicationModesProps) {
        super({});

        this.title(TextResources.JobOrder.JobOrder);
    }
    
    show(): Promise<JobOrderResponsiblesApplicationMode> {
        return this.dialogsService.ShowModal(this);
    }

    close() {
        this.modal.close(JobOrderResponsiblesApplicationMode.NotApply);
    }

    merge() {
        this.modal.close(JobOrderResponsiblesApplicationMode.Merge);
    }

    action() {
        this.modal.close(JobOrderResponsiblesApplicationMode.Apply);
    }
    
    renderBody() {
        return  <p style={{ fontSize: "14px" }}>
                    {TextResources.JobOrder.JobOrderResponsiblesApplicationModesMessage}
                    <ul style={{ marginTop: "10px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <span className="bold">{TextResources.JobOrder.NotApplyResponsibles}</span>: {TextResources.JobOrder.NotApplyResponsiblesDescr}
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <span className="bold">{TextResources.JobOrder.MergeResponsibles}</span>: {TextResources.JobOrder.MergeResponsiblesDescr}
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <span className="bold">{TextResources.JobOrder.ApplyResponsibles}</span>: {TextResources.JobOrder.ApplyResponsiblesDescr}
                        </li>
                    </ul>
                </p>;
    }

    renderFooter() {
        return  <>
                    <a href="#" class="btn btn-default" data-bind="click: close">{TextResources.JobOrder.NotApplyResponsibles}</a>
                    <a href="#" class="btn btn-success" data-bind="click: merge">{TextResources.JobOrder.MergeResponsibles}</a>
                    <a href="#" class="btn btn-primary" data-bind="click: action">{TextResources.JobOrder.ApplyResponsibles}</a>
                </>;
    }
}