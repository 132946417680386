import * as numeral from "numeral";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IArticle, IArticlesService } from "../ProlifeSdk/interfaces/warehouse/IArticlesService";

export interface IArticleDataSourceModel extends IDataSourceModel<number, IArticle> {
    
}

export class ArticlesDataSource implements IDataSource {
    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService! : IArticlesService;

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.Articles;
    }    
    
    isGroupedData(currentModel: IArticleDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IArticleDataSourceModel, b: IArticleDataSourceModel): boolean {
        return (!a && !b) || (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IArticleDataSourceModel, textFilter: string, skip: number, count: number): Promise<IArticleDataSourceModel[]> {
        let articles = await this.articlesService.getArticles(textFilter, null, skip, count);
        return articles.map(this.createModelFor, this);
    }

    private createModelFor(article : IArticle) : IArticleDataSourceModel {
        return {
            id: article.ArticleId,
            title: article.Code,
            isLeaf: true,
            isGroup: false,
            subTitle: article.Description + '&nbsp;(' + numeral(article.CustomerPrice).format('0,0.00 $') + ')',
            model: article
        }
    }
    
    async getById(currentModel: IArticleDataSourceModel, ids: number[]): Promise<IArticleDataSourceModel[]> {
        let articles = await this.articlesService.GetArticlesByIds(ids);
        return articles.map(this.createModelFor, this);
    }
    
    setView(view: IDataSourceView): void {
        
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}