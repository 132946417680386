import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { IPreferredSuppliersService, IPreferredSupplierSolution } from "../Warehouse/PreferredSuppliersService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IPreferredSuppliersSolutionDataSourceModel extends IDataSourceModel<number, IPreferredSupplierSolution> {

}

export interface IPreferredSuppliersSolutionDataSource extends IDataSource {

}

export class PreferredSuppliersSolutionDataSource implements IPreferredSuppliersSolutionDataSource {
    @LazyImport(nameof<IPreferredSuppliersService>())
    private preferredSuppliersService! : IPreferredSuppliersService;

    private view: IDataSourceView;
    private refreshRequested: boolean;

    getTitle(currentModel: IPreferredSuppliersSolutionDataSourceModel): string {
        return "Anteprima Fornitori Abituali";
    }    
    
    isGroupedData(currentModel: IPreferredSuppliersSolutionDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IPreferredSuppliersSolutionDataSourceModel, b: IPreferredSuppliersSolutionDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    getData(currentModel: IPreferredSuppliersSolutionDataSourceModel, textFilter: string, skip: number, count: number): Promise<IPreferredSuppliersSolutionDataSourceModel[]> {
        return this.preferredSuppliersService.ResolvePreferredSuppliers(textFilter, skip, count)
            .then((articles : IPreferredSupplierSolution[]) => {
                return articles.map(this.createModelFor, this);
            });
    }
    
    async getById(currentModel: IPreferredSuppliersSolutionDataSourceModel, ids: (string | number)[]): Promise<IPreferredSuppliersSolutionDataSourceModel[]> {
        return [];
    }

    private createModelFor(solution : IPreferredSupplierSolution) : IPreferredSuppliersSolutionDataSourceModel {
        return {
            id: solution.ArticleId,
            title: solution.Code,
            subTitle: solution.Description,
            isLeaf: true,
            isGroup: false,
            model: solution
        };
    }

    refresh() {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}