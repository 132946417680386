import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IReferenceDetailsViewModelFactory,
    IEntityRefInfo,
    IReferenceDetailsViewModel,
    ISimpleEntitiesViewModel,
} from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IEstimateBudgetRow } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDocumentReferencesMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IReferenceForMap } from "../../../../bindings";
import { Deferred } from "../../../../Core/Deferred";

export class EstimatedPurchaseRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory {
    public UrlProvider: (docId: number, regId: number) => string;

    constructor() {
        this.UrlProvider = (docId, regId) => "#";
    }

    GetEntityTypeDescription(): string {
        return ProlifeSdk.TextResources.Todolist.EstimatedPurchases;
    }

    CreateViewModelsFor(
        references: IRefDocumentRow[],
        details: IEntityRefInfo[],
        customerId: number,
        isReferencer: boolean
    ): IReferenceDetailsViewModel[] {
        var viewModels: IReferenceDetailsViewModel[] = [];
        references.forEach((r: IRefDocumentRow) => {
            var matches = details.filter((d: IEntityRefInfo) => {
                return d.EntityKeyId == r.SourceEntityKeyId;
            });
            viewModels.push(new EstimatedPurchaseRefDetailsViewModel(customerId, r, matches[0], isReferencer));
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(
        referencedLeafsInfo: ILeafReferenceInfo[],
        warehouseId: number,
        customerId: number
    ): IReferenceDetailsViewModel[] {
        var viewModels: IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r: ILeafReferenceInfo) => {
            viewModels.push(new LeafEstimatedPurchaseViewModel(warehouseId, customerId, r));
        });
        return viewModels;
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(
        referenceMap: IDocumentReferencesMap
    ): ISimpleEntitiesViewModel {
        return new EstimatedPurchasesViewModel(referenceMap);
    }
}

class EstimatedPurchasesViewModel implements ISimpleEntitiesViewModel {
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    templateName: string = "documents-map-viewer-estimated-purchases";
    templateUrl: string = "todolist/templates/documents/referencemap";
    EntityType: string = ProlifeSdk.EstimatedPurchaseEntityTypeCode;

    public EstimatedPurchases: ko.ObservableArray<EstimatedPurchaseForReferenceMap> = ko.observableArray([]);

    constructor(private referenceMap: IDocumentReferencesMap) {}

    LoadEntities(references: IReferenceForMap[]): Promise<void> {
        var entitiesIds: number[] = references
            .filter((r: IReferenceForMap) => {
                return r.SourceEntityType == this.EntityType;
            })
            .map((r: IReferenceForMap) => {
                return r.SourceId;
            });

        var def = new Deferred<void>();
        this.todoListService
            .GetEstimatedPurchasesByIds(entitiesIds)
            .then((estimatedPurchase: IEstimateBudgetRow[]) => {
                this.EstimatedPurchases(
                    estimatedPurchase.map((a: any) => {
                        return new EstimatedPurchaseForReferenceMap(a, this);
                    })
                );
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    SelectRelatedRows(entityId: number, entityType: string) {
        this.EstimatedPurchases().forEach((a: EstimatedPurchaseForReferenceMap) => {
            a.IsSelected(a.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }

    public OnSelectionChanged(selected: EstimatedPurchaseForReferenceMap) {
        this.EstimatedPurchases().forEach((a: EstimatedPurchaseForReferenceMap) => {
            a.IsSelected(a == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.estimatedPurchase.Id, this.EntityType);
    }
}

class EstimatedPurchaseForReferenceMap extends NodeSubElement {
    public IsSelected: ko.Observable<boolean> = ko.observable(false);

    constructor(public estimatedPurchase: IEstimateBudgetRow, private container: EstimatedPurchasesViewModel) {
        super(container, estimatedPurchase.Id, container.EntityType);
    }
}

class EstimatedPurchaseRefDetailsViewModel implements IReferenceDetailsViewModel {
    templateName: string;
    templateUrl: string;

    constructor(
        private customerId: number,
        private reference: IRefDocumentRow,
        private details: IEntityRefInfo,
        isReferencer: boolean
    ) {
        this.templateName = "estimated-purchase";
        this.templateUrl = "todolist/templates/documents/referencemap/referencedetails";
    }

    ShowDetails() {}
}

class LeafEstimatedPurchaseViewModel implements IReferenceDetailsViewModel {
    templateName: string;
    templateUrl: string;

    constructor(private warehouseId: number, private customerId: number, private reference: ILeafReferenceInfo) {
        this.templateName = "leaf-estimated-purchase";
        this.templateUrl = "todolist/templates/documents/referencemap/referencedetails";
    }

    ShowDetails() {}
}
