import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/02/13
 * Time: 11.38
 * To change this template use File | Settings | File Templates.
 */

export class GetFocus {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                return underlyingObservable();
            },
            write: function(newValue) {
                underlyingObservable(newValue);
            }
        });

        ko.applyBindingsToNode(element, { hasfocus: interceptor }, undefined);
    }
}

ko.bindingHandlers["getFocus"] = ko.bindingHandlers["getfocus"] = new GetFocus();

ko.bindingHandlers["focusOnce"] = {
    init: function(elem, valueAccessor, allBindingsAccessor)
    {
        $(elem).focus();

        if(allBindingsAccessor().selectOnFocus) {
            setTimeout(function() {
                $(elem).select();
            }, 0);
        }
    }
};

ko.bindingHandlers["focusOnceDelayed"] = {
    init: function(elem, valueAccessor, allBindingsAccessor)
    {
        var delay = valueAccessor();

        setTimeout(function()
        {
            $(elem).select();
        }, delay || 0);
    }
};