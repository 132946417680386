import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IUsersService, IUserForList } from "../Users/UsersService";
import { IDataSourceModel } from "./IDataSource";

export interface IUsersDataSourceModel extends IDataSourceModel<number, IUserForList> {

}

export class UsersDataSource extends BaseDataSource<IUsersDataSourceModel> {
    @LazyImport(nameof<IUsersService>())
    private usersService : IUsersService;

    getTitle(currentModel: IUsersDataSourceModel): string {
        return TextResources.Users.Users;
    }
    
    async getData(currentModel: IUsersDataSourceModel, textFilter: string, skip: number, count: number): Promise<IUsersDataSourceModel[]> {
        let allUsers = await this.usersService.GetUsersForList(textFilter, skip, count);
        return allUsers.map(this.createModelFor, this);
    }

    async getById(currentModel: IUsersDataSourceModel, ids: number[]): Promise<IUsersDataSourceModel[]> {
        let allUser = await this.usersService.GetUsersForListByIds(ids);
        return allUser.map(this.createModelFor, this);
    }

    private createModelFor(user : IUserForList) : IUsersDataSourceModel {
        return {
            id: user.IdUser,
            title: user.Nome + ' ' + user.Cognome,
            isLeaf: true,
            isGroup: false,
            model: user
        }
    }
}