import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/02/13
 * Time: 10.14
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { ICustomersMetadataTypes } from "../interfaces/ICustomersMetadataTypes";
import { IMetadataType } from "../../ProlifeSdk/interfaces/customer/IMetadataType";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../ProlifeSdk/interfaces/IView";

export class CustomersMetadataTypes implements ICustomersMetadataTypes {
    private ajaxService : IAjaxService;
    private metadataTypes : IMetadataType[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        const settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Customers.AddressBook);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<IMetadataType[]> {
        return this.ajaxService.Get<IMetadataType[]>("Customers-api/MetadataTypes", "", {})
            .then(this.loadMetadataTypes.bind(this));
    }

    private loadMetadataTypes(metadataTypes : IMetadataType[]) {
        this.metadataTypes = metadataTypes;
    }

    getName(): string {
        return ProlifeSdk.CustomersMetadataTypesSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Customers.AddressBookMetadata;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return null;
    }

    public getMetadataTypes() : IMetadataType[]{
        return this.metadataTypes;
    }
}