import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/07/2018
 * Time: 11:00
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { WizardStep } from "../../../../../ProlifeSdk/prolifesdk/WizardStep";
import { VatRegisterStatus } from "../../../settings/ui/protocols/VatRegisterStatus";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { ICurrenciesSettingsManager } from "../../../settings/CurrenciesSettingsManager";
import { Invoice } from "./Invoice";
import { IDocumentsService } from "../../../../DocumentsService";
import {
    IMonthlyInvoicingDataSource,
    ICustomerForMonthlyInvoicing,
    IJobOrderForMonthlyInvoicing,
} from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IEnititiesToImportForMonthlyInvoicingWrapper } from "../../../../interfaces/IEntitiesToImportForMonthlyInvoicingWrapper";
import {
    IEntityToImportInfoForMonthlyInvoicing,
    ILabelsMap,
    ILabelInfo,
} from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentCurrencyViewModel } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IInvoiceForMonthlyInvoicingWizard } from "../../../../interfaces/IInvoiceForMonthlyInvoicingWizard";
import { IInvoiceForLabelsList } from "../../../../interfaces/IInvoiceForLabelsList";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IVatRegister, IVatRegisters } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { INationsSettingsManager } from "../../../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { IExpireModes } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { Deferred } from "../../../../../Core/Deferred";

export interface IInvoicesCurrenciesMap {
    [key: string]: string[];
}

export class ImportMonthlyInvoicingDataWizardInvoicingBaseStep
    extends WizardStep
    implements IEnititiesToImportForMonthlyInvoicingWrapper
{
    public EntitiesList: ko.ObservableArray<IEntityToImportInfoForMonthlyInvoicing> = ko.observableArray([]);
    public SelectedEntities: ko.ObservableArray<IEntityToImportInfoForMonthlyInvoicing> = ko.observableArray([]);
    public PartialInvoicing: ko.Observable<boolean> = ko.observable(false);
    public IncludePdfAsAttachmentOnElectronicInvoicingExport: ko.Observable<boolean> = ko.observable(true);
    public CanDoPartialInvoicing: ko.Observable<boolean> = ko.observable(false);

    public ClickedEntityRow: ko.Observable<any> = ko.observable();

    public InvoicesToProduce: ko.Computed<IInvoiceForLabelsList[]>;
    public SelectedInvoices: ko.Computed<IInvoiceForLabelsList[]>;

    public AllCollapsed: ko.Computed<boolean>;
    public AllSelected: ko.Computed<boolean>;
    public HasEntitiesWithoutLabel: ko.Computed<boolean>;
    public AllEntitiesWithoutLabelSelected: ko.Computed<boolean>;

    public LabelsMap: ILabelsMap = {};

    public initialiPartialInvoicingSetting: boolean;

    private currenciesInterceptor: ko.Computed<void>;

    constructor(
        templateName: string,
        templateUrl: string,
        Name: string,
        protected dataSource: IMonthlyInvoicingDataSource,
        initializationInfo: IWizardInitializationInfo,
        viewModel?: any
    ) {
        super(templateName, templateUrl, Name, initializationInfo);

        this.AllSelected = ko.computed({
            read: () => {
                return this.SelectedEntities().length == this.EntitiesList().length && this.EntitiesList().length != 0;
            },
            write: (selected: boolean) => {
                this.EntitiesList().forEach((invoice: IEntityToImportInfoForMonthlyInvoicing) =>
                    invoice.Selected(selected)
                );
            },
        });

        this.AllCollapsed = ko.computed({
            read: () => {
                return (
                    this.EntitiesList().filter((i: IEntityToImportInfoForMonthlyInvoicing) => !i.Collapsed()).length ==
                    0
                );
            },
            write: (collapse: boolean) => {
                this.EntitiesList().forEach((i: IEntityToImportInfoForMonthlyInvoicing) => i.Collapsed(collapse));
            },
        });

        this.AllEntitiesWithoutLabelSelected = ko.computed({
            read: () => {
                const entities: IEntityToImportInfoForMonthlyInvoicing[] = this.EntitiesList();
                let selectedEntitiesWithoutlabel = 0;
                let entitiesWithoutLabel = 0;
                for (let i = 0; i < entities.length; i++) {
                    if (!entities[i].LabelForInvoiceGrouping()) {
                        entitiesWithoutLabel++;
                        if (entities[i].Selected()) selectedEntitiesWithoutlabel++;
                    }
                }
                return entitiesWithoutLabel == selectedEntitiesWithoutlabel && entitiesWithoutLabel != 0;
            },
            write: (select: boolean) => {
                this.EntitiesList().forEach((e: IEntityToImportInfoForMonthlyInvoicing) => {
                    if (!e.LabelForInvoiceGrouping()) e.Selected(select);
                });
            },
        });

        this.HasEntitiesWithoutLabel = ko.computed(() => {
            const entities: IEntityToImportInfoForMonthlyInvoicing[] = this.EntitiesList();

            for (let i = 0; i < entities.length; i++) {
                if (!entities[i].LabelForInvoiceGrouping()) return true;
            }

            return false;
        });

        this.InvoicesToProduce = ko.computed<IInvoiceForLabelsList[]>(() => {
            const invoices: IInvoiceForLabelsList[] = [];
            const entities: IEntityToImportInfoForMonthlyInvoicing[] = this.EntitiesList();

            for (let i = 0; i < entities.length; i++) {
                const entity = entities[i];

                const label: string = (
                    !entity.LabelForInvoiceGrouping() ? "" : entity.LabelForInvoiceGrouping()
                ).toUpperCase();
                let invoiceForLabel = invoices.firstOrDefault(
                    (l: IInvoiceForLabelsList) => (!l.label ? "" : l.label.toUpperCase()) == label
                );

                const jobOrder = this.dataSource.JobOrdersCache.get(entity.JobOrderId());
                const customer = this.dataSource.CustomersCache.get(entity.CustomerId());

                if (!invoiceForLabel) {
                    const invoiceForLabelData: IInvoiceForLabelsListData = {
                        label: label,
                        total: entities
                            .filter(
                                (d) =>
                                    (!d.LabelForInvoiceGrouping() ? "" : d.LabelForInvoiceGrouping().toUpperCase()) ==
                                    label
                            )
                            .reduce(
                                (sum: number, d: IEntityToImportInfoForMonthlyInvoicing) =>
                                    (isNaN(sum) ? 0 : sum) + d.TotalInDefaultCurrency(),
                                0
                            ),
                        rowsNumber: entities.filter(
                            (d) =>
                                (!d.LabelForInvoiceGrouping() ? "" : d.LabelForInvoiceGrouping().toUpperCase()) == label
                        ).length,
                        customerModel: jobOrder,
                        jobOrderModel: customer,
                        paymentType: entity.PaymentType(),
                        paymentExpire: entity.ExpiryType(),
                        partialInvoicingEnebled: this.initialiPartialInvoicingSetting,
                    };

                    invoiceForLabel = new InvoiceForLabelsList(invoiceForLabelData, this);

                    invoices.push(invoiceForLabel);
                }
            }

            return invoices;
        });

        this.SelectedInvoices = ko.computed({
            read: () => {
                const selectedInvoices: IInvoiceForLabelsList[] = [];
                const invoicesToProduce: IInvoiceForLabelsList[] = this.InvoicesToProduce();

                for (let i = 0; i < invoicesToProduce.length; i++) {
                    if (invoicesToProduce[i].Selected()) selectedInvoices.push(invoicesToProduce[i]);
                }

                return selectedInvoices;
            },
            write: (invoices: IInvoiceForLabelsList[]) => {
                if (!invoices || invoices.length == 0) {
                    this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
                        i.Selected(false);
                    });
                    return;
                }

                invoices.forEach((invoiceToSelect: IInvoiceForLabelsList) => {
                    this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
                        i.Selected(
                            (!i.label ? "" : i.label.toUpperCase()) ==
                                (!invoiceToSelect.label ? "" : invoiceToSelect.label.toUpperCase())
                        );
                    });
                });
            },
        });

        this.currenciesInterceptor = ko.computed(() => {
            const entities: IEntityToImportInfoForMonthlyInvoicing[] = this.EntitiesList();
            const invoices: IInvoiceForLabelsList[] = this.InvoicesToProduce();

            const invoicesCurrenciesMap: IInvoicesCurrenciesMap = {};

            for (const entity of entities) {
                const label = entity.LabelForInvoiceGrouping();
                const entityCurrency = entity.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
                if (!entityCurrency) return;

                const group = invoices.firstOrDefault((g) => g.label === label);
                if (!group) return;

                if (!invoicesCurrenciesMap[label]) invoicesCurrenciesMap[label] = [];

                const currencyCode = entityCurrency.Currency().CodeISO4217alpha3;
                group.AddCurrency(currencyCode);
                invoicesCurrenciesMap[label].push(currencyCode);
            }

            invoices.forEach((i: InvoiceForLabelsList) => {
                const invoiceCurrencies = i.DocumentCurrencies.peek();

                for (const currency of invoiceCurrencies) {
                    const code = currency.Currency().CodeISO4217alpha3;
                    if (!invoicesCurrenciesMap[i.label].firstOrDefault((cc) => cc === code)) i.RemoveCurrency(code);
                }

                if (
                    invoiceCurrencies.length > 0 &&
                    !invoiceCurrencies.firstOrDefault((c) => c.IsDocumentCurrency.peek())
                )
                    invoiceCurrencies[0].IsDocumentCurrency(true);
            });
        });
    }

    public RefreshEntitiesSelection(invoice: IInvoiceForLabelsList): void {
        this.EntitiesList().forEach((e: IEntityToImportInfoForMonthlyInvoicing) => {
            if (
                !!invoice &&
                invoice.label.toUpperCase() ==
                    (!e.LabelForInvoiceGrouping() ? "" : e.LabelForInvoiceGrouping().toUpperCase())
            )
                e.Selected(invoice.Selected());
        });
    }

    public GetWizard(): IMonthlyInvoicingDataSource {
        return this.dataSource;
    }

    public BeforeShow() {
        this.SelectedEntities([]);
        this.LabelsMap = {};
        this.initialiPartialInvoicingSetting = this.PartialInvoicing();
    }

    public RegenerateLabels(): void {
        // implementare nelle derivate
    }

    public GetLabelsListForTypeahead(
        listLength: number,
        query: string,
        process: (items: any[]) => any,
        asyncProcess: (items: any[]) => any
    ) {
        const elements: any[] = [];

        if (query) query = query.toLocaleLowerCase();

        this.EntitiesList().forEach((d: IEntityToImportInfoForMonthlyInvoicing) => {
            if (
                elements.filter((e) => d.HasLabel(!e.label ? "" : e.label)).length == 0 &&
                this.TypeaheadSearch(d.LabelForInvoiceGrouping(), query) &&
                (!this.ClickedEntityRow() ||
                    (this.ClickedEntityRow().Name != d.Name &&
                        this.ClickedEntityRow().VerifyDocumentCompatibility(d.GetLabelInfo())))
            )
                elements.push({ label: d.LabelForInvoiceGrouping() });
        });

        if (elements.length > listLength) elements.splice(listLength, elements.length - listLength);
        return process(this.transformElements(elements));
    }

    public SetClickedEntity(entity: IEntityToImportInfoForMonthlyInvoicing): void {
        this.ClickedEntityRow(entity);
    }

    public UpdateLabelInfo(label: string, labelInfo: ILabelInfo): void {
        if (this.LabelsMap[label]) this.LabelsMap[label] = labelInfo;
    }

    private TypeaheadSearch(label: string, query: string): boolean {
        return (!label && !query) || (!!label && this.hasMatchingWords(label, query));
    }

    private hasMatchingWords(label: string, query: string): boolean {
        let matches = 0;
        query = query.trim();
        label.split(" ").forEach((w: string) => {
            query.split(" ").forEach((q: string) => {
                (!w ? "" : w.toUpperCase()).indexOf(q) >= 0 ? matches++ : matches;
            });
        });
        return matches > 0;
    }

    private transformElements(elements: any[]): any[] {
        return elements.map(this.transform);
    }

    private transform(elem: any): any {
        return {
            label: elem.label,

            toString: function () {
                return JSON.stringify(this);
            },
            toLowerCase: function () {
                return this.label.toLowerCase();
            },
            indexOf: function () {
                // eslint-disable-next-line prefer-rest-params
                return String.prototype.indexOf.apply(this.label, arguments);
            },
            replace: function () {
                // eslint-disable-next-line prefer-rest-params
                return String.prototype.replace.apply(this.label, arguments);
            },
        };
    }
}

export interface IInvoiceForLabelsListData {
    label: string;
    total: number;
    rowsNumber: number;
    customerModel: ICustomerForMonthlyInvoicing;
    jobOrderModel: IJobOrderForMonthlyInvoicing;
    paymentType: string;
    paymentExpire: string;
    partialInvoicingEnebled: boolean;
}

class InvoiceForLabelsList implements IInvoiceForLabelsList {
    public label: string;
    public total: number;
    public rowsNumber: number;
    public customerModel: ICustomerForMonthlyInvoicing;
    public jobOrderModel: IJobOrderForMonthlyInvoicing;
    public partialInvoicingEnebled: boolean;
    public payment: ko.Observable<string> = ko.observable();
    public customer: string;
    public jobOrder: string;

    public VatRegistersForFinalInvoicing: ko.ObservableArray<IVatRegister> = ko.observableArray([]);
    public VatRegister: ko.Observable<number> = ko.observable();
    public Selected: ko.Observable<boolean> = ko.observable(false);

    public DocumentCurrencies: ko.ObservableArray<IDocumentCurrencyViewModel> = ko.observableArray([]);

    public LabelElements: ko.Observable<string> = ko.observable("");
    public LabelElementsNumber: ko.Observable<number> = ko.observable();

    public State: ko.Computed<number>;
    public HasInconsistentData: ko.Computed<boolean>;

    private paymentType: string;
    private expireType: string;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegistersManager: IVatRegisters;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    private nationsManager: INationsSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expiriesManager: IExpireModes;

    constructor(data: IInvoiceForLabelsListData, private storage: IEnititiesToImportForMonthlyInvoicingWrapper) {
        this.label = data.label;
        this.total = data.total;
        this.rowsNumber = data.rowsNumber;
        this.customerModel = data.customerModel;
        this.jobOrderModel = data.jobOrderModel;
        this.partialInvoicingEnebled = data.partialInvoicingEnebled;
        this.payment(
            (!data.paymentType ? "N/A" : data.paymentType) + " - " + (!data.paymentExpire ? "N/A" : data.paymentExpire)
        );
        this.customer = this.customerModel?.Name;
        this.jobOrder = this.jobOrderModel?.Name;

        this.paymentType = data.paymentType;
        this.expireType = data.paymentExpire;

        this.LabelElements(!this.label ? ProlifeSdk.TextResources.Invoices.MissingLabel : this.label);
        this.LabelElementsNumber(data.rowsNumber);

        this.VatRegistersForFinalInvoicing(
            this.vatRegistersManager
                .getVatRegisters()
                .filter(
                    (r: IVatRegister) =>
                        (r.TipiFatture.charAt(0) == "1" || r.TipiFatture.charAt(2) == "1") &&
                        r.TipoDocumento == ProlifeSdk.InvoiceDocumentType &&
                        r.ForPartialInvoices == false &&
                        r.Stato == VatRegisterStatus.Enabled
                )
        );

        if (this.VatRegistersForFinalInvoicing().length > 0)
            this.VatRegister(this.VatRegistersForFinalInvoicing()[0].IdRegistroIVA);

        this.State = ko.computed(() => {
            const selectedRowsWithLabel: number = this.storage
                .SelectedEntities()
                .filter((e: IEntityToImportInfoForMonthlyInvoicing) => e.HasLabel(this.label)).length;
            const rowsWithLabel: number = this.storage
                .EntitiesList()
                .filter((e: IEntityToImportInfoForMonthlyInvoicing) => e.HasLabel(this.label)).length;

            return selectedRowsWithLabel == 0
                ? ProlifeSdk.MonthlyInvoicingLabelsSelectionUncheckedState
                : rowsWithLabel == selectedRowsWithLabel
                ? ProlifeSdk.MonthlyInvoicingLabelsSelectionCheckedState
                : ProlifeSdk.MonthlyInvoicingLabelsSelectionMixedState;
        });

        this.HasInconsistentData = ko.computed(() => {
            let result = false;
            let labelInfo: ILabelInfo = null;
            const rowsWithLabel: IEntityToImportInfoForMonthlyInvoicing[] = this.storage
                .EntitiesList()
                .filter((e: IEntityToImportInfoForMonthlyInvoicing) => e.HasLabel(this.label));

            for (let i = 0; i < rowsWithLabel.length; i++) {
                if (i == 0) {
                    labelInfo = rowsWithLabel[i].GetLabelInfo();
                    continue;
                }

                result = !rowsWithLabel[i].VerifyDocumentCompatibility(labelInfo);

                if (result) {
                    this.payment(ProlifeSdk.TextResources.Invoices.InconsistentPaymentData);
                    break;
                }
            }

            if (!result && labelInfo)
                this.payment(
                    (!labelInfo.PaymentMode ? "N/A" : labelInfo.PaymentMode) +
                        " - " +
                        (!labelInfo.ExpiryMode ? "N/A" : labelInfo.ExpiryMode)
                );

            return result;
        });
    }

    public async AsInvoiceForMonthlyInvoicingWizard(
        partialInvoices: IEntityToImportInfoForMonthlyInvoicing[]
    ): Promise<IInvoiceForMonthlyInvoicingWizard> {
        const invoice = new Invoice(this.label, false, partialInvoices, this.storage.GetWizard());
        await invoice.Initialize();
        invoice.storage(null);

        const vatRegister = this.vatRegistersManager.getVatRegisterById(this.VatRegister());

        invoice.VatRegisterForFinalInvoicing(this.VatRegister());
        invoice.VatRegisterNameForFinalInvoicing(vatRegister?.NomeRegistroIVA);
        invoice.VatRegisterForPartialInvoicing(null);
        invoice.VatRegisterNameForPartialInvoicing(null);
        invoice.ExpiryType(this.expireType);
        invoice.ExpiryTypeId(this.expiriesManager.getExpireModeIdByName(invoice.ExpiryType()));
        invoice.PaymentTypeManagerUi.setPaymentInfoFromDescription(this.paymentType);
        invoice.LabelForInvoiceGrouping(this.label);

        invoice.DocumentCurrencies(this.DocumentCurrencies().map((c) => c));

        return invoice;
    }

    public AddCurrency(currencyCode: string): void {
        if (this.DocumentCurrencies.peek().firstOrDefault((c) => c.Currency.peek().CodeISO4217alpha3 === currencyCode))
            return;

        const currency = this.currenciesSettingsManager.getCurrencyByCode(currencyCode);
        const documentCurrency = this.documentsService.DocumentCurrenciesFactory.create(
            null,
            ProlifeSdk.InvoiceEntityTypeCode,
            currency.Id
        );

        documentCurrency.ExchangeValue(null);
        documentCurrency.ExchangeValueForVat(null);

        this.DocumentCurrencies.push(documentCurrency);

        if (!this.DocumentCurrencies.peek().firstOrDefault((c) => c.IsDocumentCurrency.peek())) {
            const nation = this.nationsManager.getNationByAplpha2Code(this.customerModel?.Country);

            if (nation && currency.CountriesIds.indexOf(nation.Id) >= 0) documentCurrency.IsDocumentCurrency(true);

            const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();

            if (defaultCurrency.CodeISO4217alpha3 === currencyCode) {
                documentCurrency.ExchangeValue(1);
                documentCurrency.ExchangeValueForVat(1);
            }
        }
    }

    public RemoveCurrency(currencyCode: string, restoreDocumentCurrency = false): void {
        let index = -1;
        const currencies = this.DocumentCurrencies();

        for (let i = 0; i < currencies.length; i++) {
            if (currencies[i].Currency().CodeISO4217alpha3 === currencyCode) {
                index = i;
                break;
            }
        }

        if (index >= 0) {
            currencies.splice(index, 1);
            this.DocumentCurrencies(currencies);
        }

        if (!currencies.firstOrDefault((c) => c.IsDocumentCurrency() && restoreDocumentCurrency)) {
            const nation = this.nationsManager.getNationByAplpha2Code(this.customerModel?.Country);
            const customerCurrency = currencies.firstOrDefault(
                (c) => c.Currency().CountriesIds.indexOf(nation?.Id) >= 0
            );

            if (customerCurrency) {
                customerCurrency.IsDocumentCurrency(true);
            } else {
                const firstCurrency = currencies.firstOrDefault();
                firstCurrency ? firstCurrency.IsDocumentCurrency(true) : firstCurrency;
            }
        }
    }

    public SwitchSelection(): void {
        this.Selected(!this.Selected());
        this.storage.RefreshEntitiesSelection(this);
    }

    public DeleteFromInvoicingList(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Invoices.DeleteInvoiceFromMonthlyInvoicingList,
            ProlifeSdk.TextResources.Invoices.DeleteInvoiceFromMonthlyInvoicingListCancel,
            ProlifeSdk.TextResources.Invoices.DeleteInvoiceFromMonthlyInvoicingListConfirm,
            (confirm: boolean) => {
                if (confirm) this.deleteRowsFromInvoicingList();

                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private deleteRowsFromInvoicingList(): void {
        const entities: IEntityToImportInfoForMonthlyInvoicing[] = this.storage.EntitiesList();

        for (let i = 0; i < entities.length; i++) {
            if (
                entities[i].LabelForInvoiceGrouping() == this.label ||
                (!entities[i].LabelForInvoiceGrouping() && !this.label)
            ) {
                entities.splice(i, 1);
                i--;
            }
        }

        this.storage.EntitiesList(entities);
    }
}
