import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/02/2018
 * Time: 09:18
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { FolderEditingDialog } from "./dialogs/FolderEditingDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFoldersUIProvider, IFolder } from "../../../ProlifeSdk/interfaces/agenda/IFolderService";

export class FoldersUIProvider implements IFoldersUIProvider {
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
    }

    public GetForldersEditingDialog(folderId: number = null): Promise<IFolder> {
        var editingDialog = new FolderEditingDialog(this.serviceLocator, folderId);
        return this.dialogsService.ShowModal<IFolder>(editingDialog, null, null, "agenda/templates/dialogs", "folder-dialog");
    }
}