import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/12/2018
 * Time: 14:13
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IProvincesSettingsManager, IProvince } from "../../../ProlifeSdk/interfaces/settings/IProvincesSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class ProvincesSettingsManager implements IProvincesSettingsManager {
    private provinces: IProvince[] = [];
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }
    
    load(): Promise<IProvince[]> {
        return this.ajaxService.Get<IProvince[]>("Settings-api", "Provinces", {})
            .then((provincesList: IProvince[]) => {
                this.provinces = provincesList || [];
                return provincesList;
            });
    }

    getName(): string {
        return ProlifeSdk.ProvincesSettings;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Settings.ProvincesSettings;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return undefined;
    }

    getProvincesList(nationId: number): IProvince[] {
        return this.provinces.filter((p) => p.NationId == nationId);
    }

    getProvinceByCode(code: string): IProvince {
        let matches = this.provinces.filter((p) => p.Code == code);
        return matches[0] || null;
    }

    getProvinceById(id: number): IProvince {
        let matches = this.provinces.filter((p) => p.Id == id);
        return matches[0] || null;
    }

    findProvince(searchQuery: string): IProvince {
        let text = (searchQuery || "").trim();
        return this.provinces.firstOrDefault(p => p.Code === text || p.Name === text);
    }
}