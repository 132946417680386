import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { Column } from "./CustomColumn";
import { ComponentUtils } from "../../Core/utils/ComponentUtils";
import { LazyImport } from "../../Core/DependencyInjection";
import { IDialogsService, PopoverPosition } from "../../Core/interfaces/IDialogsService";
import { TableFooterAggregationMode } from "./TableFooterAggregationMode";
import { DropdownList } from "../DropdownList";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { List, ListItem } from "../ListComponent";
import { CheckBox } from "../Checkbox";
import { ArrayDataSource, IArrayDataSourceModel } from "../../DataSources/ArrayDataSource";
import { TabNav, TabNavPage } from "../TabNavComponent";
import { TableConfigurationsManager } from "./TableConfigurationsManager";
import {
    IApplicationConfiguration,
    IApplicationsConfigurationsService,
} from "../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { If } from "../IfIfNotWith";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TableColumnsConfiguration } from "./TableComponent";
import { NumberInput } from "../NumberInput";
import { ColorInput } from "../ColorInput";

const { classes } = jss
    .createStyleSheet({
        columnSelectionPopover: {
            minWidth: "1200px",
            height: "80%",

            "& .popover-header:": {
                height: "35px",
            },

            "& .popover-content": {
                height: "calc(100% - 35px)",

                "& .tabbable-custom": {
                    "& .nav.nav-tabs": {
                        position: "relative",
                    },
                    "& .tab-content": {
                        position: "relative",
                        top: "-5px",
                        flexGrow: 1,
                    },
                },

                "& .column-selection-tab": {
                    width: "1200px",

                    "& .list-notification-container .list-notification.list-notification-fit .list-notification-item": {
                        padding: 0,
                    },

                    "& .column-selection-header": {
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        alignItems: "center",

                        "& label": {
                            fontSize: "13px",
                            fontWeight: "bold",
                            margin: "0px",
                        },

                        "& > div": {
                            marginBottom: "3px",
                        },

                        "& .col-column": {
                            width: "305px",
                        },

                        "& .col-aggregator": {
                            width: "200px",
                        },

                        "& .col-width": {
                            width: "130px",
                        },
                    },
                    "& .column-selection-list": {
                        listStyle: "none",
                        padding: 0,
                        minWidth: "200px",

                        "& label": {
                            fontSize: "13px",
                        },

                        "& > li": {
                            paddingTop: "3px",
                        },
                    },
                    "& .column-selection-footer": {
                        textAlign: "right",
                    },
                    "& input[type=checkbox]": {
                        marginRight: "5px",
                    },
                },

                "& .list-item": {
                    alignItems: "center",

                    "& label": {
                        margin: "0px",
                    },

                    "& .col-column": {
                        width: "305px",
                    },

                    "& .col-aggregator": {
                        width: "200px",
                    },

                    "& .col-width": {
                        width: "130px",
                    },

                    "& .form-group": {
                        margin: 0,
                    },
                },
            },
        },
    })
    .attach();

type ColumnSelectionPopoverProps<T> = {
    columns: Column<T>[];
    sortableColumns?: boolean;
    enableAggregators?: boolean;
    tableId?: number;
    currentColumnsConfiguration?: IApplicationConfiguration;
    onConfigurationApplied?: (configuration: IApplicationConfiguration) => void;
};

type ColumnInfo<T> = {
    column: Column<T>;
    columnId: number;
    visible: ko.Observable<boolean>;
    order: number;
    hasFooterAggregator: boolean;
    footerAggregatorMode: ko.Observable<TableFooterAggregationMode>;
    columnWidth: ko.Observable<number>;
    columnHeight: ko.Observable<number>;
    useCustomTextColor: ko.Observable<boolean>;
    columnTextColor: ko.Observable<string>;
    useCustomBackgroundColor: ko.Observable<boolean>;
    columnBackgroundColor: ko.Observable<string>;
    breakText: ko.Observable<boolean>;
};

type TableFooterAggregation = { id: TableFooterAggregationMode; label: string };

export class ColumnSelectionPopover<T> extends DialogComponentBase {
    static defaultProps: Partial<ColumnSelectionPopoverProps<any>> = {
        sortableColumns: false,
        enableAggregators: false,
    };

    private Columns: ko.ObservableArray<ColumnInfo<T>> = ko.observableArray([]);
    private footerAggregatorTypes: TableFooterAggregation[] = [];

    private ColumnsDataSource: ColumnsInfoDataSource<T> = new ColumnsInfoDataSource();
    private columnsConfigurations: IApplicationConfiguration[] = [];
    private get hasColumnsConfigurationsSaved(): boolean {
        return this.columnsConfigurations.length > 0;
    }

    selectAll: ko.Computed<boolean>;
    selectAllBreakText: ko.Computed<boolean>;
    private DataAreLoaded: ko.Observable<boolean> = ko.observable(false);
    private ConfigurationIsChangedInterceptor: ko.Computed<void>;
    private configurationIsChanged = false;

    private subscriptions: ko.Subscription[] = [];

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService: IApplicationsConfigurationsService;

    constructor(private props: ColumnSelectionPopoverProps<T>) {
        super({ popover: true });

        this.title(
            this.props.currentColumnsConfiguration
                ? String.format(
                      TextResources.ProlifeSdk.TableColumnsManagementWithConfiguration,
                      this.props.currentColumnsConfiguration.Title
                  )
                : TextResources.ProlifeSdk.TableColumnsManagement
        );

        this.loadConfigurations();

        this.Columns(
            props.columns
                .filter((c) => c.hasTitle)
                .map((c) => ({
                    column: c,
                    columnId: c.id,
                    visible: ko.observable(c.visible()),
                    order: c.order(),
                    hasFooterAggregator: c.hasAggregator,
                    footerAggregatorMode: ko.observable(c.aggregationMode()),
                    columnWidth: ko.observable(c.width()),
                    columnHeight: ko.observable(c.height()),
                    useCustomTextColor: ko.observable(c.useCustomTextColor() ?? false),
                    columnTextColor: ko.observable(c.textColor()),
                    useCustomBackgroundColor: ko.observable(c.useCustomBackgroundColor() ?? false),
                    columnBackgroundColor: ko.observable(c.backgroundColor()),
                    breakText: ko.observable(c.breakText()),
                }))
        );

        this.Columns().forEach((c) => {
            this.subscriptions.push(c.useCustomTextColor.subscribe((v) => !v && c.columnTextColor("#000000")));
            this.subscriptions.push(
                c.useCustomBackgroundColor.subscribe((v) => !v && c.columnBackgroundColor("#FFFFFF"))
            );
        });

        this.ColumnsDataSource.setDefaultMimeType("column-info");
        this.ColumnsDataSource.setSupportedDropMimeTypes("column-info");
        this.ColumnsDataSource.setData(...this.createDataSourceModels());

        this.footerAggregatorTypes.push({ id: TableFooterAggregationMode.Sum, label: TextResources.ProlifeSdk.Sum });
        this.footerAggregatorTypes.push({ id: TableFooterAggregationMode.Avg, label: TextResources.ProlifeSdk.Avg });
        this.footerAggregatorTypes.push({ id: TableFooterAggregationMode.Max, label: TextResources.ProlifeSdk.Max });
        this.footerAggregatorTypes.push({ id: TableFooterAggregationMode.Min, label: TextResources.ProlifeSdk.Min });
        this.footerAggregatorTypes.push({
            id: TableFooterAggregationMode.Count,
            label: TextResources.ProlifeSdk.Count,
        });
        this.footerAggregatorTypes.push({
            id: TableFooterAggregationMode.DistinctCount,
            label: TextResources.ProlifeSdk.DistinctCount,
        });

        this.selectAll = ko.computed({
            read: () => {
                const visibleColumnsCount = this.Columns().filter((c) => c.visible()).length;
                return visibleColumnsCount == 0 ? false : visibleColumnsCount == this.Columns().length ? true : null;
            },
            write: (value: boolean) => {
                this.Columns().forEach((c) => c.visible(value));
            },
        });

        this.selectAllBreakText = ko.computed({
            read: () => {
                const breakTextColumnsCount = this.Columns().filter((c) => c.breakText()).length;
                return breakTextColumnsCount == 0
                    ? false
                    : breakTextColumnsCount == this.Columns().length
                    ? true
                    : null;
            },
            write: (value: boolean) => {
                this.Columns().forEach((c) => c.breakText(value));
            },
        });

        this.ConfigurationIsChangedInterceptor = ko.computed(() => {
            this.Columns().forEach((c) => {
                c.visible();
                c.footerAggregatorMode();
                c.breakText();
                c.columnWidth();
                c.columnHeight();
                c.useCustomTextColor();
                c.columnTextColor();
                c.useCustomBackgroundColor();
                c.columnBackgroundColor();
            });

            this.configurationIsChanged = true;
        });

        this.configurationIsChanged = false;
    }

    close() {
        this.subscriptions.forEach((s) => s.dispose());
        super.close();
    }

    action() {
        if (this.configurationIsChanged) this.props.onConfigurationApplied && this.props.onConfigurationApplied(null);

        this.subscriptions.forEach((s) => s.dispose());

        this.applyConfigurationAndClose();
    }

    private applyConfigurationAndClose() {
        const columns = this.Columns();
        columns.forEach((c) => {
            c.column.aggregationMode(c.footerAggregatorMode());
            c.column.order(c.order);
            c.column.breakText(c.breakText());
            c.column.width(c.columnWidth());
            c.column.height(c.columnHeight());
            c.column.useCustomTextColor(c.useCustomTextColor());
            c.column.textColor(c.columnTextColor() ?? "#000000");
            c.column.useCustomBackgroundColor(c.useCustomBackgroundColor());
            c.column.backgroundColor(c.columnBackgroundColor() ?? "#FFFFFF");
        });

        this.modal.close(columns.filter((c) => c.visible()).map((c) => c.column));
    }

    renderHeader() {
        return (
            <>
                <button
                    className="btn btn-danger btn-circle btn-xs popover-close"
                    style={{ position: "absolute", top: "6px", right: "5px", margin: 0 }}
                    data-bind="click: close"
                >
                    &times;&nbsp;{TextResources.ProlifeSdk.Close}
                </button>
                <button
                    className="btn btn-primary btn-xs btn-circle"
                    type="button"
                    style={{ position: "absolute", top: "6px", right: "70px" }}
                    data-bind="click: $data.action.bind($data)"
                >
                    <i className="fa fa-check"></i>&nbsp;{TextResources.ProlifeSdk.Apply}
                </button>
                <h3 className="popover-title" data-bind="text: title"></h3>
            </>
        );
    }

    renderBody() {
        const tabNavClasses = ComponentUtils.classNames(
            "flex-container flex-vertical flex-full-height",
            classes.columnSelectionPopover
        );
        return ComponentUtils.bindTo(
            <If condition={this.DataAreLoaded}>
                {() => (
                    <TabNav className={tabNavClasses}>
                        <TabNavPage title={TextResources.ProlifeSdk.TableColumnsConfigurationTab}>
                            {() => this.renderColumnsTab()}
                        </TabNavPage>
                        <TabNavPage
                            title={TextResources.ProlifeSdk.TableColumnsConfigurationsManagerTab}
                            default={this.hasColumnsConfigurationsSaved}
                        >
                            {() => this.renderConfigurationsManagerTab()}
                        </TabNavPage>
                    </TabNav>
                )}
            </If>,
            this
        );
    }

    showOn(element: HTMLElement, position: PopoverPosition) {
        return this.dialogsService.ShowPopoverComponent<Column<T>[]>(
            element,
            this,
            position,
            null,
            classes.columnSelectionPopover
        );
    }

    private async loadConfigurations(): Promise<void> {
        try {
            this.columnsConfigurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(
                ProlifeSdk.ProLifeTableConfigurationType,
                this.userInfo.getIdUser(),
                0,
                this.props.tableId
            );
            this.DataAreLoaded(true);
        } catch (e) {
            console.error(e);
        }
    }

    private createDataSourceModels() {
        return this.Columns().map((ci) => ({
            id: ci.order,
            title: null,
            isGroup: false,
            isLeaf: true,
            dragEnabled: true,
            model: ci,
        }));
    }

    private refreshList(): void {
        const columns = this.Columns();
        columns.sort((a, b) => a.order - b.order);
        this.Columns(columns);
        this.ColumnsDataSource.setData(...this.createDataSourceModels());
    }

    private onApplyConfiguration(configuration: IApplicationConfiguration): void {
        const config = JSON.parse(configuration.Configuration) as TableColumnsConfiguration;
        const columnsWithoutConfig = [];

        let maxOrderValue = -1;

        for (const col of this.Columns()) {
            const colConfig = config.columnsConfigurations[col.columnId];
            if (!colConfig) {
                console.warn(
                    String.format("Configurazione colonna [{0}: {1}] non trovata!", col.columnId, col.column.title)
                );
                columnsWithoutConfig.push(col);
                continue;
            }

            if (maxOrderValue < colConfig.order) maxOrderValue = colConfig.order;

            col.order = colConfig.order;
            col.footerAggregatorMode(colConfig.aggregationMode);
            col.visible(colConfig.visible);
            col.breakText(colConfig.breakText);
            col.columnWidth(colConfig.width);
            col.columnHeight(colConfig.height);
            col.columnTextColor(
                !colConfig.useCustomTextColor || !colConfig.textColor ? "#000000" : colConfig.textColor
            );
            col.useCustomTextColor(colConfig.useCustomTextColor ?? false);
            col.columnBackgroundColor(
                !colConfig.useCustomBackgroundColor || !colConfig.backgroundColor
                    ? "#FFFFFF"
                    : colConfig.backgroundColor
            );
            col.useCustomBackgroundColor(colConfig.useCustomBackgroundColor ?? false);
        }

        for (const col of columnsWithoutConfig) col.order = ++maxOrderValue;

        this.props.onConfigurationApplied && this.props.onConfigurationApplied(configuration);
        this.refreshList();
        this.applyConfigurationAndClose();
    }

    private renderConfigurationsManagerTab(): React.ReactNode<T> {
        return (
            <TableConfigurationsManager
                configurations={this.columnsConfigurations}
                configurationType={ProlifeSdk.ProLifeTableConfigurationType}
                moduleId={0} // moduleId non è necessario, è impostato ad un valore fisso per compatibilità con la stored
                objectId={this.props.tableId}
                onApplyConfiguration={this.onApplyConfiguration.bind(this)}
                actualConfigurationProvider={this.getActualColumnsConfiguration.bind(this)}
            />
        );
    }

    private getActualColumnsConfiguration() {
        const columnsConfiguration: TableColumnsConfiguration = {
            tableId: this.props.tableId,
            columnsConfigurations: {},
        };

        for (const col of this.Columns()) {
            columnsConfiguration.columnsConfigurations[col.columnId] = {
                columnId: col.columnId,
                visible: col.visible(),
                order: col.order,
                aggregationMode: col.hasFooterAggregator ? col.footerAggregatorMode() : null,
                breakText: col.breakText(),
                width: col.columnWidth(),
                height: col.columnHeight(),
                useCustomTextColor: col.useCustomTextColor(),
                textColor: col.columnTextColor(),
                useCustomBackgroundColor: col.useCustomBackgroundColor(),
                backgroundColor: col.columnBackgroundColor(),
            };
        }

        return columnsConfiguration;
    }

    private onColumnsOrderChange(
        dataTransfer: DataTransfer,
        model: IArrayDataSourceModel<ColumnInfo<T>>,
        before: boolean
    ) {
        this.configurationIsChanged = true;
    }

    private renderColumnsTab() {
        const listOptionalAttributes: any = {};
        listOptionalAttributes.sortable = this.props.sortableColumns;

        const listItemRenderer = (item: ListItem<number, ColumnInfo<T>>) => (
            <div className="flex-container list-item">
                <div className="col-column">
                    <CheckBox checked={item.Model.visible} label={item.Model.column.title} simple />
                </div>
                {this.props.enableAggregators && (
                    <div className="col-aggregator">
                        {item.Model.hasFooterAggregator && (
                            <DropdownList
                                value={item.Model.footerAggregatorMode}
                                values={this.footerAggregatorTypes}
                                valueGetter={(i) => i.id.toString()}
                                idGetter={(i) => i.id}
                                textGetter={(i) => i.label}
                                simple
                                className="input-sm"
                                noSelectionPlaceholder={TextResources.ProlifeSdk.Select2Placeholder}
                            />
                        )}
                    </div>
                )}
                <div className="col-width">
                    <NumberInput
                        value={item.Model.columnWidth}
                        format={"0,0"}
                        simple
                        className="input-sm"
                        nullable
                        placeholder={TextResources.ProlifeSdk.TableColumnWidthPlaceholder}
                    />
                </div>
                <div className="col-width">
                    <NumberInput
                        value={item.Model.columnHeight}
                        format={"0,0"}
                        simple
                        className="input-sm"
                        nullable
                        placeholder={TextResources.ProlifeSdk.TableColumnHeightPlaceholder}
                    />
                </div>
                <div className="col-width flex-container justify-end">
                    <CheckBox checked={item.Model.useCustomTextColor} label={""} simple />
                    <ColorInput
                        value={item.Model.columnTextColor}
                        size="xs"
                        allowAlpha
                        readonly={ko.computed(() => !item.Model.useCustomTextColor())}
                    />
                </div>
                <div className="col-width flex-container justify-end">
                    <CheckBox checked={item.Model.useCustomBackgroundColor} label={""} simple />
                    <ColorInput
                        value={item.Model.columnBackgroundColor}
                        size="xs"
                        allowAlpha
                        readonly={ko.computed(() => !item.Model.useCustomBackgroundColor())}
                    />
                </div>
                <div className="flex-1 text-right">
                    <CheckBox checked={item.Model.breakText} label={""} simple />
                </div>
            </div>
        );

        return (
            <div className="column-selection-tab flex-container flex-full-height flex-vertical">
                <div className="column-selection-header flex-container">
                    {this.props.sortableColumns && <div style={{ flex: "0 0 2.5em", margin: 0 }}>&nbsp;</div>}
                    <div className="flex-container col-column">
                        <CheckBox checked={this.selectAll} label={TextResources.ProlifeSdk.Column} />
                    </div>
                    {this.props.enableAggregators && (
                        <div className="col-aggregator">{TextResources.ProlifeSdk.Aggregator}</div>
                    )}
                    <div className="col-width text-right">{TextResources.ProlifeSdk.TableColumnWidth}</div>
                    <div className="col-width text-right">{TextResources.ProlifeSdk.TableColumnHeight}</div>
                    <div className="col-width text-right">{TextResources.ProlifeSdk.TableColumnTextColor}</div>
                    <div className="col-width text-right">{TextResources.ProlifeSdk.TableColumnBackgroundColor}</div>
                    <div className="flex-container flex-1 flex-fill text-right">
                        <CheckBox
                            checked={this.selectAllBreakText}
                            label={TextResources.ProlifeSdk.BreakText}
                            reverse
                        />
                    </div>
                </div>
                <List
                    dataSource={this.ColumnsDataSource}
                    containerHeight="flex"
                    itemRenderer={listItemRenderer}
                    listAdditionalClasses="column-selection-list"
                    {...listOptionalAttributes}
                    systemScrollable
                    scrollable={false}
                    onDrop={this.onColumnsOrderChange.bind(this)}
                />
                <div className="column-selection-footer"></div>
            </div>
        );
    }
}

class ColumnsInfoDataSource<T> extends ArrayDataSource<ColumnInfo<T>> {
    onItemBeginMove(model: IArrayDataSourceModel<ColumnInfo<T>>, dataTransfer: DataTransfer) {
        const dragged = {
            id: model.id,
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData(this.getDefaultMimeType(), JSON.stringify(dragged));
        dataTransfer.dropEffect = "move";
    }

    async onItemMoved(
        dataTransfer: DataTransfer,
        neighbourItem: IArrayDataSourceModel<ColumnInfo<T>>,
        before: boolean
    ): Promise<void> {
        const mimeType = this.getDefaultMimeType();

        if (dataTransfer.types.indexOf(this.getDefaultMimeType()) >= 0) {
            const droppedItem = JSON.parse(dataTransfer.getData(mimeType));
            const data = this.getAllData();

            const modelIndex = neighbourItem.model.order;
            const oldIndex = data.firstOrDefault((i) => this.areEqual(i, droppedItem))?.model?.order;

            const aheadMovement = oldIndex < modelIndex;
            const newIndex = !aheadMovement
                ? before
                    ? modelIndex
                    : modelIndex + 1
                : before
                ? modelIndex - 1
                : modelIndex;

            for (const model of data) {
                if (this.areEqual(model, droppedItem)) {
                    model.model.order = newIndex;
                    continue;
                }

                if (!aheadMovement && model.model.order >= newIndex && model.model.order < oldIndex)
                    model.model.order++;

                if (aheadMovement && model.model.order > oldIndex && model.model.order <= newIndex) model.model.order--;
            }

            data.sort((a, b) => a.model.order - b.model.order);
            this.setData(...data);
            this.refresh();
        }
    }
}
