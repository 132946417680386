import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, classNames, reloadNow } from "../Core/utils/ComponentUtils";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { NumericText } from "./NumericText";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    "allocation-badge": {
        cursor: "pointer",
    },
});
const { classes } = styleSheet.attach();

type AllocationBadgeProps = {
    workflowId: number;
    jobOrderId: number;
    allocationPercentage: number;
    hasNotEstimatedElementsAllocated: boolean;
    popoverPosition?: "left" | "right" | "top" | "bottom";
};

export function AllocationBadge(props: AllocationBadgeProps) {
    const C = require("./AllocationBadge")._AllocationBadge as typeof _AllocationBadge;
    return <C {...props} />;
}

export class _AllocationBadge {
    static defaultProps: Partial<AllocationBadgeProps> = {
        popoverPosition: "left",
    };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props: AllocationBadgeProps) {}

    private action(sender: unknown, event: Event) {
        return this.dialogsService.ShowPopoverComponent(
            event.currentTarget as HTMLElement,
            {
                componentName: "workflow-allocation-teams",
                title: TextResources.Todolist.WorkflowAllocationStatusTitle,
                params: "JobOrderId: JobOrderId, WorkflowId: WorkflowId",
                model: {
                    JobOrderId: this.props.jobOrderId,
                    WorkflowId: this.props.workflowId,
                },
            },
            this.props.popoverPosition
        );
    }

    render() {
        const allocationBadge = this;

        return (
            <>
                {((this.props.allocationPercentage || 0) > 0 || this.props.hasNotEstimatedElementsAllocated) &&
                    ComponentUtils.bindTo(
                        <span
                            className={classNames(
                                classes["allocation-badge"],
                                "badge",
                                WorkflowsUtils.getAllocationBadgeClass(this.props.hasNotEstimatedElementsAllocated)
                            )}
                            data-bind={{
                                asyncClick: allocationBadge.action.bind(allocationBadge),
                            }}
                        >
                            <NumericText value={this.props.allocationPercentage / 100} format="0[.]0[0]%" />
                        </span>,
                        this,
                        "allocationBadge"
                    )}
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AllocationBadge);
}
