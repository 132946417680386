import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { CategorySpeedFilter } from "./speedFilterViewModels/CategorySpeedFilter";
import { CreatedBySystemFilter } from "./speedFilterViewModels/CreatedBySystemFilter";
import { GroupedEntitiesProvider } from "../../ProlifeSdk/prolifesdk/providers/GroupedEntitiesProvider";
import * as moment from "moment";
import { AdvancedSearchFilter } from "./speedFilterViewModels/AdvancedSearchFilter";
import { FilterDialog } from "../../Desktop/utilities/FilterDialog";
import { IContextEventsObserver } from "../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { ISearchResultsProvider } from "../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { ILogFilter, ILogTagFilter } from "../../ProlifeSdk/interfaces/ILogFilter";

export class FilterViewModel implements ISearchResultsProvider
{
	public priorityFilter : ko.Observable<boolean> = ko.observable();
	public qualityFilter : ko.Observable<boolean> = ko.observable();
	public categoryFilter: CategorySpeedFilter;
    public createdBySystemFilter : CreatedBySystemFilter;
	public advancedFilter: AdvancedSearchFilter;

	public searchResultsProvider : GroupedEntitiesProvider;
	public searchFilters : ko.Observable<string> = ko.observable(""); //Solo stringhe
    private dialogsService : IDialogsService;

	constructor(private serviceLocator : IServiceLocator,private contextEventsObserver: IContextEventsObserver)
    {
        this.createdBySystemFilter = new CreatedBySystemFilter(this.contextEventsObserver.RefreshEvents.bind(this.contextEventsObserver));
		this.categoryFilter = new CategorySpeedFilter(serviceLocator, this.contextEventsObserver.RefreshEvents.bind(this.contextEventsObserver));
		this.advancedFilter = new AdvancedSearchFilter();
		this.priorityFilter(false);
		this.qualityFilter(false);
		this.searchResultsProvider = new GroupedEntitiesProvider(this);
		this.searchFilters.subscribe(() => this.contextEventsObserver.RefreshEvents());
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
	}

    public showAdvancedFilter()
    {
        this.dialogsService.ShowModal<void>(new FilterDialog(this.advancedFilter, "events-filter", "blog/templates/jobOrderNotes/filters",
            ProlifeSdk.TextResources.Blog.FilterEvents),
                null, null, "blog/templates/jobOrderNotes/filters", "filter-dialog")
            .then(() => {
                this.contextEventsObserver.RefreshEvents()
            });
    }

    public showOtherFilter() {
        var self = this;
        this.dialogsService.ShowModal<void>(new FilterDialog(this.categoryFilter, "other-filter", "blog/templates/jobOrderNotes/filters",
                ProlifeSdk.TextResources.Blog.OtherFilter),
            null, null, "blog/templates/jobOrderNotes/filters", "other-filter-dialog")
            .then(() => {
                this.contextEventsObserver.RefreshEvents()
            });
    }

	public SearchHighPriority()
    {
		this.priorityFilter(!this.priorityFilter());
		this.contextEventsObserver.RefreshEvents();
	}

	public SearchEventInQuality()
    {
		this.qualityFilter(!this.qualityFilter());
		this.contextEventsObserver.RefreshEvents();
	}

	public pushFilters(filters : ILogFilter[])
    {
		this.pushPriorityFilter(filters);
		this.pushCategoriesFilter(filters);
		this.pushQualityFilter(filters);
		this.pushAdvancedFilter(filters);
        this.pushCreatedBySystemFilter(filters);
		var allFilters = JSON.parse("[" + (this.searchFilters() || "").replace(/\|/g,",") + "]");
		allFilters.forEach((filter : ILogFilter) => {filters.push(filter);});
	}

	private pushPriorityFilter(filters : ILogFilter[]) : void
    {
		if (this.priorityFilter())
		{
			var Tags : ILogTagFilter[] = [];

			Tags.push( {
				TagName : ProlifeSdk.Tag_Priority,
				Values : [ProlifeSdk.TextResources.Blog.HiPriority],
				ValueTypeId : ProlifeSdk.TagType_String});

			filters.push({
				Tags : Tags
			})
		}
	}

    private pushCreatedBySystemFilter(filters : ILogFilter[]) : void
    {
        filters.push({
            ExcludeCreatedBySystem : this.createdBySystemFilter.getFilter()
        });
    }

	private pushCategoriesFilter(filters : ILogFilter[]) : void
    {
		if (this.categoryFilter.getFilter().length == 0)
			return;

		filters.push(<ILogFilter> {
			Tags : [],
			EventTypeIds : this.categoryFilter.getFilter()
		});
	}

	private pushQualityFilter(filters : ILogFilter[]) : void
    {
		if (this.qualityFilter())
		{
			var Tags : ILogTagFilter[] = [];
			Tags.push( {
				TagName : ProlifeSdk.Tag_TracingQuality,
				Values : ["True"],
				ValueTypeId : ProlifeSdk.TagType_Bool});

			filters.push({
				Tags : Tags
			});
		}

	}

	private pushAdvancedFilter(filters : ILogFilter[]) : void
    {
		var Tags : ILogTagFilter[] = [];

		if (this.advancedFilter.filterActive())
		{
			if (this.advancedFilter.stato() != "-1"){
				Tags.push( {
					TagName : ProlifeSdk.Tag_State,
					Values : [this.advancedFilter.stato()],
					ValueTypeId : ProlifeSdk.TagType_String});
			}

			filters.push({
				From : this.advancedFilter.inizio() ? moment(this.advancedFilter.inizio()).startOf('day').toDate() : null,
				To: this.advancedFilter.fine() ? moment(this.advancedFilter.fine()).endOf('day').toDate() : null,
				Tags : Tags
			});
		}
	}

	public findSearchMatches(query : any) {}
	public findSearchMatch(element, callback) {}
}