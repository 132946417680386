import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { CustomerOrderProtocolType } from "./warehouse/documents/customer-order/CustomerOrderProtocolType";
import { CustomerOrdersProvider } from "./warehouse/documents/customer-order/CustomerOrdersProvider";

import { SupplierOrderProtocolType } from "./warehouse/documents/supplier-order/SupplierOrderProtocolType";
import { SupplierOrdersProvider } from "./warehouse/documents/supplier-order/SupplierOrdersProvider";

import { WarehouseLoadProtocolType } from "./warehouse/documents/warehouse-load/WarehouseLoadProtocolType";
import { WarehouseLoadsProvider } from "./warehouse/documents/warehouse-load/WarehouseLoadsProvider";

import { CustomerOrderRefDetailsViewModelFactory } from "./warehouse/providers/refs-details-factories/CustomerOrderRefDetailsViewModelFactory";
import { SupplierOrderRefDetailsViewModelFactory } from "./warehouse/providers/refs-details-factories/SupplierOrderRefDetailsViewModelFactory";

import { CustomerOrderEvent } from "./warehouse/ui/events/CustomerOrderEvent";
import { SupplierOrderEvent } from "./warehouse/ui/events/SupplierOrderEvent";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog//BlogEventBase";
import { WarehouseLoadReasonsForShipmentSettingsManager } from "./warehouse/settings/WarehouseLoadReasonsForShipmentSettingsManager";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IJobOrderService } from "../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ICustomerOrder, ICustomerOrderForWizard, ICustomerOrderForJobOrderList, ICustomerOrderRow, IFullCustomerOrder } from "../ProlifeSdk/interfaces/warehouse/ICustomerOrder";
import { ISupplierOrderRowStatus, ISupplierOrderForWizard, ISupplierOrder, ISupplierOrderRow, IFullSupplierOrder } from "../ProlifeSdk/interfaces/warehouse/ISupplierOrder";
import { IOrdersService, IOrdersServiceObserver, ICustomerOrderForList, ISupplierOrderForList, IWarehouseLoadReasonForShipment, IReasonForShipmentUsage, IGetCustomerOrdersForListRequest, IGetSupplierOrdersForListRequest, ISupplierOrderDocumentWithDetailsAndRows, IInsertOrUpdateSupplierOrderRequest, ISupplierOrderDocument } from "../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { AjaxOptions, IAjaxService } from "../Core/interfaces/IAjaxService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IService } from "../Core/interfaces/IService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { IVatRegisters, IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

interface IBlogViewModels
{
    [type : string] : new (serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver) => BlogEventBase;
}

export class OrdersService implements IOrdersService, ISettingsServiceObserver, IEventUiProvider
{
    private ajaxService : IAjaxService;
    private authorizationService : IAuthorizationService;
    private entitiesService : IEntityProviderService;
    private settingsService : ISettingsService;
    private sdkService : IProLifeSdkService;
    private documentsService : IDocumentsService;
    private hasRegisteredDocumentProviders  = false;
    private observers : IOrdersServiceObserver[] = [];
    private eventsViewModels : IBlogViewModels = {};

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IOrdersService>(), this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.authorizationService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);
        this.settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.documentsService = <IDocumentsService> this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        this.entitiesService = <IEntityProviderService> this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.sdkService = <IProLifeSdkService> this.serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.settingsService.addObserver(this);

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode : ProlifeSdk.CustomerOrderEntityTypeCode,
            EntityName : ProlifeSdk.TextResources.Warehouse.CustomerOrder,
            PluralEntityName : ProlifeSdk.TextResources.Warehouse.CustomerOrders
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode : ProlifeSdk.SupplierOrderEntityTypeCode,
            EntityName : ProlifeSdk.TextResources.Warehouse.SupplierOrder,
            PluralEntityName : ProlifeSdk.TextResources.Warehouse.SupplierOrders
        });

        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.CustomerOrderEntityTypeCode, new CustomerOrderRefDetailsViewModelFactory());
        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.SupplierOrderEntityTypeCode, new SupplierOrderRefDetailsViewModelFactory());

        this.eventsViewModels[ProlifeSdk.WarehouseEventType_CustomerOrder] = CustomerOrderEvent;
        this.eventsViewModels[ProlifeSdk.WarehouseEventType_SupplierOrder] = SupplierOrderEvent;

        new WarehouseLoadReasonsForShipmentSettingsManager();
    }

    public getUIForEvent(item : ILogEvent, contextEventsObserver : IContextEventsObserver) : IView
    {
        let type = this.eventsViewModels[item.EventType] || BlogEventBase;
        let event : BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView> event;
    }

    addObserver(observer : IOrdersServiceObserver) : void
    {
        this.observers.push(observer);
    }

    removeObserver(observer : IOrdersServiceObserver) : void
    {
        let index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    getServiceType() : string
    {
        return ProlifeSdk.OrdersServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    onSettingsLoaded(): void
    {
        if(this.hasRegisteredDocumentProviders)
            return;

        this.RegisterProtocols();
    }

    onSettingsUpdated(updateType : string) : void
    {
        if(updateType != ProlifeSdk.SettingsUpdateType_DocumentsProtocols)
            return;

        //Rimuovo i registri di questo tipo precedentemente registrati
        this.documentsService.getRegisteredDocumentProviders().slice(0).forEach((p : IDocumentsProvider) => {
            if(p instanceof CustomerOrdersProvider ||
                p instanceof SupplierOrdersProvider ||
                p instanceof WarehouseLoadsProvider)
                this.documentsService.unregisterDocumentsProvider(p);
        });

        let registersManager : IVatRegisters = <IVatRegisters>this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        registersManager.unregisterCustomProtocolType(cpt =>
            cpt instanceof CustomerOrderProtocolType || 
            cpt instanceof SupplierOrderProtocolType ||
            cpt instanceof WarehouseLoadProtocolType
        );

        //Aggiungo i nuovi registri
        this.RegisterProtocols();
    }

    private RegisterProtocols()
    {
        const authorizationService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);
        const vatRegisters = <IVatRegisters> this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);

        const registersManager : IVatRegisters = <IVatRegisters>this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        if(authorizationService.isAuthorized("Warehouse_CustomerOrder") || authorizationService.isAuthorized("Warehouse_ViewCustomerOrder"))
            registersManager.registerCustomProtocolType(new CustomerOrderProtocolType());
        if(authorizationService.isAuthorized("Warehouse_SupplierOrder") || authorizationService.isAuthorized("Warehouse_ViewSupplierOrder"))
            registersManager.registerCustomProtocolType(new SupplierOrderProtocolType());
        if(authorizationService.isAuthorized("Warehouse_Load") || authorizationService.isAuthorized("Warehouse_ViewLoad"))
            registersManager.registerCustomProtocolType(new WarehouseLoadProtocolType());
        
        vatRegisters.getVatRegisters().forEach((protocol : IVatRegister) => {
            if(protocol.Stato != ProlifeSdk.HiddenProtocolState && protocol.TipoDocumento == ProlifeSdk.CustomerOrderTypeId && (authorizationService.isAuthorized("Warehouse_CustomerOrder") || authorizationService.isAuthorized("Warehouse_ViewCustomerOrder")))
                this.documentsService.registerDocumentsProvider(new CustomerOrdersProvider(protocol));
            else if(protocol.Stato != ProlifeSdk.HiddenProtocolState && protocol.TipoDocumento == ProlifeSdk.SupplierOrderTypeId && (authorizationService.isAuthorized("Warehouse_SupplierOrder") || authorizationService.isAuthorized("Warehouse_ViewSupplierOrder")))
                this.documentsService.registerDocumentsProvider(new SupplierOrdersProvider(protocol));
            else if(protocol.Stato != ProlifeSdk.HiddenProtocolState && protocol.TipoDocumento == ProlifeSdk.WarehouseLoadTypeId && (authorizationService.isAuthorized("Warehouse_Load") || authorizationService.isAuthorized("Warehouse_ViewLoad")))
                this.documentsService.registerDocumentsProvider(new WarehouseLoadsProvider(protocol)); 
        });

        this.hasRegisteredDocumentProviders = true;
    }

    searchCustomerOrders(customerName : string, startDate : Date,  endDate : Date, locked?: boolean, unlocked?: boolean, customerId? : number, jobOrderId? : number, withoutJobOrder? : boolean) : Promise<ICustomerOrder[]> {
        let parameters : AjaxOptions = {};
        parameters.methodData = {
            customerName : customerName,
            customerId : customerId,
            from : this.sdkService.Utilities.Dates.AddGmtTime(startDate),
            to : this.sdkService.Utilities.Dates.AddGmtTime(endDate),
            locked: locked,
            unlocked: unlocked,
            jobOrderId: jobOrderId,
            withoutJobOrder: withoutJobOrder
        };

        return this.ajaxService.Post("Warehouse-api", "CustomerOrders/Search", parameters);
    }

    getSupplierOrderStatus(supplierOrderId : number) : Promise<ISupplierOrderRowStatus[]>
    {
        return this.ajaxService.Get("Warehouse-api", "SupplierOrderStatus/" + supplierOrderId, {});
    }

    GetJobOrderCustomerOrdersForList(jobOrderId : number) : Promise<ICustomerOrderForList[]>
    {
        return this.ajaxService.Get("Warehouse-api", "CustomerOrders/GetJobOrderCustomerOrdersForList?jobOrderId=" + jobOrderId, { background: true });
    }


    GetCustomerOrdersForWizard(textFilter : string,
                    getWithoutJobOrder : boolean, jobOrderId : number,
                    getForAllCustomers : boolean, customerId : number,
                    from : Date, to : Date,
                    skip : number, count : number) : Promise<ICustomerOrderForWizard[]>
    {
        return this.ajaxService.Post("Documents-api", "CustomerOrders/GetCustomerOrdersForWizard", {
            methodData: {
                TextFilter : textFilter,
                JobOrderId : jobOrderId,
                GetWithoutJobOrder : getWithoutJobOrder,
                CustomerId : customerId,
                GetForAllCustomers : getForAllCustomers,
                From : this.sdkService.Utilities.Dates.AddGmtTime(from),
                To : this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip : skip,
                Count : count
            }
        });
    }

    GetSupplierOrdersForWizard(textFilter : string,
                               getWithoutJobOrder : boolean, jobOrderId : number,
                               getForAllCustomers : boolean, customerId : number,
                               from : Date, to : Date,
                               skip : number, count : number) : Promise<ISupplierOrderForWizard[]>
    {
        return this.ajaxService.Post("Documents-api", "SupplierOrders/GetSupplierOrdersForWizard", {
            methodData: {
                TextFilter : textFilter,
                JobOrderId : jobOrderId,
                GetWithoutJobOrder : getWithoutJobOrder,
                CustomerId : customerId,
                GetForAllCustomers : getForAllCustomers,
                From : this.sdkService.Utilities.Dates.AddGmtTime(from),
                To : this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip : skip,
                Count : count
            }
        });
    }

    searchSupplierOrders(customerName : string, startDate : Date,  endDate : Date, onlyAccepted? : boolean, locked?: boolean, unlocked?: boolean, customerId? : number, jobOrderId? : number, withoutJobOrder? : boolean) : Promise<ISupplierOrderForList[]>
    {
        let parameters : AjaxOptions = {};
        parameters.methodData = {
            customerName : customerName,
            customerId : customerId,
            from : this.sdkService.Utilities.Dates.AddGmtTime(startDate),
            to : this.sdkService.Utilities.Dates.AddGmtTime(endDate),
            locked: locked,
            unlocked: unlocked,
            jobOrderId: jobOrderId,
            withoutJobOrder: withoutJobOrder,
            OnlyAccepted : onlyAccepted
        };

        return this.ajaxService.Post("Warehouse-api", "SupplierOrders/Search", parameters);
    }

    public getCustomerOrdersByProtocol(protocolId : number, filter? : string) : Promise<ICustomerOrder[]>
    {
        if(!protocolId) {
            return Promise.reject<ICustomerOrder[]>(undefined);
        }

        let filterQuery = "&filter=" + (filter || "");

        return this.ajaxService.Get("Warehouse-api", "CustomerOrders/ByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    getCustomerOrdersPartByProtocol(protocolId : number, skip : number, count : number, filter? : string) : Promise<ICustomerOrder[]>
    {
        if(!protocolId)
            return Promise.reject<ICustomerOrder[]>(undefined);

        let filterQuery = "&skip=" + skip + "&count=" + count + "&filter=" + (filter || "");

        return this.ajaxService.Get("Documents-api", "CustomerOrders/PartByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    GetCustomerOrdersForJobOrderList(jobOrderId : number) : Promise<ICustomerOrderForJobOrderList[]>
    {
        return this.ajaxService.Get("Documents-api", "CustomerOrders/GetCustomerOrdersForJobOrderList?jobOrderId=" + jobOrderId, { background: true });
    }

    public getCustomerOrderById(documentId : number) : Promise<ICustomerOrder>
    {
        if(!documentId) {
            return Promise.reject<ICustomerOrder>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "CustomerOrders/" + documentId, {});
    }

    public getCustomerOrderRowsById(documentId : number) : Promise<ICustomerOrderRow[]> {
        if(!documentId) {
            return Promise.reject<ICustomerOrderRow[]>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "CustomerOrdersRows/GetCustomerOrderRows", {
            methodData: {
                DocumentId: documentId
            }
        });

        //return this.ajaxService.Get("Warehouse-api", "CustomerOrdersRows/" + documentId, {});
    }

    public getCustomerOrdersByIds(ids: number[]): Promise<ICustomerOrderForList[]> {
        return this.ajaxService.Post("Warehouse-api/CustomerOrders", "GetCustomerOrdersByIds", {
            methodData: {
                Ids: ids
            }
        });
    }

    public getJobOrderCustomerOrders(jobOrderId : number) : Promise<ICustomerOrder[]>
    {
        if(!jobOrderId)
            return Promise.reject<ICustomerOrder[]>(undefined);

        return this.ajaxService.Get("Warehouse-api", "CustomerOrders/ByJobOrderId?jobOrderId=" + jobOrderId, { background: true });
    }

    public getJobOrderSupplierOrders(jobOrderId : number) : Promise<ISupplierOrder[]>
    {
        if(!jobOrderId)
            return Promise.reject<ISupplierOrder[]>(undefined);

        return this.ajaxService.Get("Warehouse-api", "SupplierOrders/ByJobOrderId?jobOrderId=" + jobOrderId, { background: true });
    }

    public deleteCustomerOrder(documentId : number) : Promise<void>
    {
        return this.ajaxService.Delete<void>("Warehouse-api", "CustomerOrders/" + documentId, {})
            .then(() => {
                this.observers.forEach((observer : IOrdersServiceObserver) => observer.onCustomerOrderDeleted(documentId));
            });
    }

    public createOrUpdate(document : IFullCustomerOrder) : Promise<IFullCustomerOrder>
    {
        let callback = this.onCustomerOrderAdded.bind(this);

        if(document.CustomerOrder.Id > 0)
            callback = this.onCustomerOrderChanged.bind(this);

        return this.ajaxService.Post<IFullCustomerOrder>("Warehouse-api", "CustomerOrders", {
            methodData: document
        })/*.then((customerOrder: IFullCustomerOrder) => {
            if (customerOrder.TrustAuthorization.Saved)
                callback(customerOrder.CustomerOrder);
        });*/
    }

    getSupplierOrdersByProtocol(protocolId : number, filter? : string) : Promise<ISupplierOrder[]> {
        if(!protocolId) {
            return Promise.reject<ISupplierOrder[]>(undefined);
        }

        let filterQuery = "&filter=" + (filter || "");

        return this.ajaxService.Get("Warehouse-api", "SupplierOrders/ByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    getSupplierOrdersPartByProtocol(protocolId : number, skip : number, count : number, filter? : string) : Promise<ISupplierOrder[]>
    {
        if(!protocolId)
            return Promise.reject<ISupplierOrder[]>(undefined);

        let filterQuery = "&skip=" + skip + "&count=" + count + "&filter=" + (filter || "");

        return this.ajaxService.Get("Documents-api", "SupplierOrders/PartByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    getSupplierOrderById(documentId : number) : Promise<ISupplierOrder> {
        if(!documentId) {
            return Promise.reject<ISupplierOrder>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "SupplierOrders/" + documentId, {});
    }

    getSupplierOrderRowsById(documentId : number) : Promise<ISupplierOrderRow[]> {
        if(!documentId) {
            return Promise.reject<ISupplierOrderRow[]>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "SupplierOrdersRows/GetSupplierOrderRows", {
            methodData: {
                DocumentId: documentId
            }
        });

        //return this.ajaxService.Get("Warehouse-api", "SupplierOrdersRows/" + documentId, {});
    }

    deleteSupplierOrder(documentId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Warehouse-api", "SupplierOrders/" + documentId, {})
            .then(() => {
                this.observers.forEach((observer : IOrdersServiceObserver) => observer.onSupplierOrderDeleted(documentId));
            });
    }

    createOrUpdateSupplierOrder(document : IFullSupplierOrder) : Promise<ISupplierOrder> {
        let callback = (data : ISupplierOrder) => {
            if(data)
                data.Numero = data.Numero ? data.Numero : "";
            this.observers.forEach(o => o.onSupplierOrderAdded(data));
            return data;
        };

        if(document.SupplierOrder.Id > 0)
            callback = (data : ISupplierOrder) => {
                this.observers.forEach(o => o.onSupplierOrderChanged(data));
                return data;
            }

        return this.ajaxService.Post<ISupplierOrder>("Warehouse-api", "SupplierOrders", {
            methodData: document
        }).then(callback);
    }

    setFreezeStatusBySupplierOrdersIds(freeze : boolean, ids : number[]) : Promise<void> {
        if(!ids || ids.length == 0) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService.Post<void>("Warehouse-api", "SupplierOrders/SetFreezeStatus" , {
            methodData : { freezeStatus : freeze, ids: ids }
        }).then(() => {
                ids.forEach((id : number) => this.observers
                    .forEach((observer : IOrdersServiceObserver) => observer.onSupplierOrderLockStateChanged(id, freeze)));
            });
    }

    onCustomerOrderAdded(document : ICustomerOrder) : void
    {
        if(document)
            document.Numero = document.Numero ? document.Numero : "";
        this.observers.forEach((observer : IOrdersServiceObserver) => observer.onCustomerOrderAdded(document));
    }

    onCustomerOrderChanged(document : ICustomerOrder) : void
    {
        this.observers.forEach((observer : IOrdersServiceObserver) => observer.onCustomerOrderChanged(document));
    }

    setFreezeStatusByCustomerOrdersIds(freeze : boolean, ids : number[]) : Promise<void>
    {
        if(!ids || ids.length == 0) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService.Post<void>("Warehouse-api", "CustomerOrders/SetFreezeStatus" , {
            methodData : { freezeStatus : freeze, ids: ids }
        }).then(() => {
                ids.forEach((id : number) => this.observers
                    .forEach((observer : IOrdersServiceObserver) => observer.onCustomerOrderLockStateChanged(id, freeze)));
            });
    }

    /*getCustomerOrderDetailUrl(documentId : number, registerId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, 'Cor' + registerId, documentId);
    }

    getCustomerOrderNewDocumentUrl(registerId : number) : string
    {
        return '/#/' + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentURL, 'Cor' + registerId);
    }

    getCustomerOrderForJobOrderNewDocumentUrl(registerId : number, jobOrderId : number) : string
    {
        return '/#/' + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentForJobOrderURL, 'Cor' + registerId, jobOrderId);
    }

    getSupplierOrderDetailUrl(documentId : number, registerId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, 'Sor' + registerId, documentId);
    }

    getSupplierOrderNewDocumentUrl(registerId : number) : string
    {
        return '/#/' + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentURL, 'Sor' + registerId);
    }

    getSupplierOrderForJobOrderNewDocumentUrl(registerId : number, jobOrderId : number) : string
    {
        return '/#/' + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentForJobOrderURL, 'Sor' + registerId, jobOrderId);
    }*/

    GetWarehouseLoadReasonsForShipment(showDeleted: boolean | null): Promise<IWarehouseLoadReasonForShipment[]> {
        return this.ajaxService.Post<IWarehouseLoadReasonForShipment[]>("Warehouse-api/WarehouseLoadReasonsForShipment", "GetWarehouseLoadReasonsForShipment", {
            background: true,
            methodData: {
        		showDeleted: showDeleted,
        	}
        });
    }

    GetWarehouseLoadsReasonsForShipmentUsage(): Promise<IReasonForShipmentUsage[]> {
        return this.ajaxService.Post<IReasonForShipmentUsage[]>("Warehouse-api/WarehouseLoadReasonsForShipment", "GetWarehouseLoadsReasonsForShipmentUsage", {
            background: true,
            methodData: {
        	}
        });
    }

    GetCustomerOrdersForList(request: IGetCustomerOrdersForListRequest): Promise<ICustomerOrderForList[]> {
        return this.ajaxService.Post<ICustomerOrderForList[]>("Documents-api/CustomerOrders", "GetCustomerOrdersForList", {
            background: true,
            methodData: request
        });
    }

    GetSupplierOrdersForList(request: IGetSupplierOrdersForListRequest): Promise<ISupplierOrderForList[]> {
        return this.ajaxService.Post<ISupplierOrderForList[]>("Documents-api/SupplierOrders", "GetSupplierOrdersForList", {
            background: true,
            methodData: request
        });
    }

    DeleteWarehouseLoadReasonsForShipment(reasonsIds: number[] | null, skipValidityCheck: boolean | null): Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/WarehouseLoadReasonsForShipment", "DeleteWarehouseLoadReasonsForShipment", {
            background: true,
            methodData: {
        		reasonsIds: reasonsIds,
        		skipValidityCheck: skipValidityCheck,
        	}
        });
    }

    InsertOrUpdateWarehouseLoadReasonForShipment(reasons: IWarehouseLoadReasonForShipment[] | null, skipValidityCheck: boolean | null): Promise<IWarehouseLoadReasonForShipment[]> {
        return this.ajaxService.Post<IWarehouseLoadReasonForShipment[]>("Warehouse-api/WarehouseLoadReasonsForShipment", "InsertOrUpdateWarehouseLoadReasonForShipment", {
            background: true,
            methodData: {
        		reasons: reasons,
        		skipValidityCheck: skipValidityCheck,
        	}
        });
    }

    GetSupplierOrderById(documentId: number | null): Promise<ISupplierOrderDocumentWithDetailsAndRows> {
        let result = this.ajaxService.Post<ISupplierOrderDocumentWithDetailsAndRows>("Warehouse-api/SupplierOrders", "GetSupplierOrderById", {
            background: true,
            methodData: {
        		documentId: documentId,
        	}
        });



        return result;
    }

    InsertOrUpdateSupplierOrder(request: IInsertOrUpdateSupplierOrderRequest): Promise<ISupplierOrderDocument> {
        let result = this.ajaxService.Post<ISupplierOrderDocument>("Warehouse-api/SupplierOrders", "InsertOrUpdateSupplierOrder", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new OrdersService(serviceLocator);
}
