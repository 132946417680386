import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");
import { ICartsService, ICartAndActivityEndDates } from "../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

export class CartWorkEndDateEditorDialog implements IDialog {
    public templateName: string = "cart-work-end-date-dialog";
    public templateUrl: string = "allocations/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Allocations.CartWorkEndDateDialogTitle;
    
    public modal: { close: (result?: any) => void; };
    
    public ActualCartDate: ko.Observable<Date> = ko.observable();
    public WorkEndDate: ko.Observable<Date> = ko.observable();
    public PromptMessage: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;

    constructor(private cartAndActivityEndDates: ICartAndActivityEndDates) {
        this.ActualCartDate(this.cartAndActivityEndDates.CartEndDate);
        this.WorkEndDate(this.cartAndActivityEndDates.ElementEndDate);

        this.PromptMessage(!this.ActualCartDate() ?
            String.format(ProlifeSdk.TextResources.Allocations.CartWithoutWorkEndDatePrompt, cartAndActivityEndDates.CartName) :
            String.format(ProlifeSdk.TextResources.Allocations.CartWithWorkEndDatePrompt, cartAndActivityEndDates.CartName, moment(cartAndActivityEndDates.CartEndDate).format("L")));
    }

    show(): Promise<Date> {
        return this.dialogsService.ShowModal(this, null, null, this.templateUrl, this.templateName);
    }
    
    close(): void {
        this.modal.close(this.cartAndActivityEndDates.CartEndDate);
    }
    
    action(): void {
        this.cartsService.updateCartWorkEndDate(this.cartAndActivityEndDates.CartId, this.WorkEndDate())
            .then(() => {
                this.modal.close(this.WorkEndDate());
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.UpdateCartWorkDateError);
            });
    }

}