import * as ko from "knockout";
interface ITypeaheadConfigOptions {
    source: (query: string, callback: (data: any[]) => void) => void;
    display: (data: any) => string;
    suggestion: (data: any) => string;
    onSelectionChange?: (data: any) => void;
}

export class TypeaheadBinding implements ko.BindingHandler<ITypeaheadConfigOptions> {
    init(
        element: HTMLElement,
        valueAccessor: () => ITypeaheadConfigOptions,
        allBindingsAccessor: ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void | { controlsDescendantBindings: boolean } {
        const options = ko.unwrap<ITypeaheadConfigOptions>(valueAccessor());

        (<any>$(element))
            .typeahead(
                {
                    items: 50,
                    minLength: 0,
                    menu: '<ul class="typeahead dropdown-menu"></ul>',
                    item: '<li><a href="#"></a></li>',
                },
                {
                    source: (query: string, syncData: (data: any[]) => void, asyncData: (data: any[]) => void) => {
                        options.source(query, asyncData);
                    },
                    display: (json) => {
                        const item = typeof json === "string" ? JSON.parseWithDate(!json ? null : json) : json;
                        return options.display(item);
                    },
                    limit: 1000,
                    templates: {
                        suggestion: function (json) {
                            const item = typeof json === "string" ? JSON.parseWithDate(!json ? null : json) : json;
                            return options.suggestion(item);
                        },
                    },
                }
            )
            .on("typeahead:selected", (event: JQueryEventObject, json: string) => {
                if (options.onSelectionChange) {
                    const item = typeof json === "string" ? JSON.parseWithDate(!json ? null : json) : json;
                    options.onSelectionChange(item);
                }
            });
    }
}

ko.bindingHandlers["typeahead"] = new TypeaheadBinding();
