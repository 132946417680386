import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export interface IArticleAliasesImportResult {
    Errors : string[];
}

export class ArticleAliasesImportDialog implements IDialog {
    templateName: string = "";
    templateUrl: string = "";
    title: string = "Importazione Alias Articoli ";
    modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    public selectedFiles : ko.ObservableArray<File> = ko.observableArray();

    constructor(private customerId : number) {

    }

    showModal() : Promise<void> {
        return this.dialogsService.ShowModal(this, undefined, {
            saveText: "Importa"
        });
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if(this.selectedFiles().length == 0)
        {
            this.close();
            return;
        }

        this.uploadFile()
            .then((r : IArticleAliasesImportResult) => {
                new ArticleAliasesImportResultDialog(r).showModal()
                    .then(() => this.modal.close(true))
                    .catch(() => this.modal.close(true));
            }).catch(() => {
                this.close();
            });
    }
    
    renderTemplateNodes(): Node[] {
        return [
            <div class="form-horizontal">
                <div class="input-group fileinput-new" data-bind="fileInput : {}">
                    <div class="form-control uneditable-input" data-trigger="fileinput">
                        <i class="fa fa-file fileinput-exists"></i>&nbsp;
                        <span class="fileinput-filename"></span>
                    </div>
                    <span class="input-group-addon btn default btn-file">
                        <span class="fileinput-new">Seleziona file</span>
                        <span class="fileinput-exists">Cambia file</span>
                        <input type="file" data-bind="file: selectedFiles" multiple />
                    </span>
                    <a href="#" class="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">Rimuovi file</a>
                </div>

            </div>
        ];
    }

    public uploadFile() : Promise<IArticleAliasesImportResult>
    {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Todolist.ImportInProgress);
        var files = this.selectedFiles();
        var data = {};
        for(var i = 0; i < files.length; i++)
        {
            data['activities' + i] = files[i];
        }

        return this.ajaxService.Upload<IArticleAliasesImportResult>("Warehouse-api", "ArticleAliasesImport/" + this.customerId, data)
            .finally(() => { this.desktopService.UnblockPageUI(); });
    }
}

export class ArticleAliasesImportResultDialog implements IDialog {
    templateName: string = "";
    templateUrl: string = "";
    title: string = "Risultati importazione Alias Articoli ";
    modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(public result : IArticleAliasesImportResult) {

    }

    showModal() : Promise<void> {
        return this.dialogsService.ShowModal(this, undefined, { noPrompt: true });
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.modal.close();
    }
    
    renderTemplateNodes(): Node[] {
        return [
            <>
                <ko-if data-bind="result.Errors.length > 0">
                    <div class="alert alert-danger">ATTENZIONE: Sono stati riscontrati degli errori in fase di importazione.</div>

                    <table class="table table-advance">
                        <thead>
                            <tr>
                                <th>Errore</th>
                            </tr>
                        </thead>
                        <tbody data-bind="foreach : result.Errors">
                            <tr>
                                <td data-bind="text : $data"></td>
                            </tr>
                        </tbody>
                    </table>
                </ko-if>
                <ko-if data-bind="result.Errors.length == 0">
                    <div class="alert alert-success">Importazione alias articoli completata con successo.</div>
                </ko-if>
            </>
        ];
    }
}