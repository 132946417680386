/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 13/03/13
 * Time: 12.55
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";

export class OnOffSwitch {
    init(
        element: HTMLElement,
        valueAccessor: () => any,
        allBindingsAccessor: () => ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const switchSize: string = allBindingsAccessor()["switchSize"];
        const onLabel = allBindingsAccessor()["onLabel"];
        const offLabel = allBindingsAccessor()["offLabel"];
        const onColor = allBindingsAccessor()["onColor"];
        const offColor = allBindingsAccessor()["offColor"];

        (<any>$(element)).bootstrapSwitch({
            size: switchSize || null,
            onText: onLabel || "ON",
            offText: offLabel || "OFF",
            onColor: onColor || "primary",
            offColor: offColor || "default",
        });

        $(element).on("switchChange.bootstrapSwitch", function (e, value) {
            valueAccessor()(value);
        });

        const disableBinding = allBindingsAccessor()["disable"];
        if (disableBinding && (ko.isObservable(disableBinding) || ko.isComputed(disableBinding))) {
            const subscription = disableBinding.subscribe((v) => {
                (<any>$(element)).bootstrapSwitch("disabled", v);
            });

            (<any>$(element)).bootstrapSwitch("disabled", disableBinding());

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscription.dispose();
            });
        }

        const enableBinding = allBindingsAccessor()["enable"];
        if (enableBinding && (ko.isObservable(enableBinding) || ko.isComputed(enableBinding))) {
            const subscription = enableBinding.subscribe((v) => {
                (<any>$(element)).bootstrapSwitch("disabled", !v);
            });

            (<any>$(element)).bootstrapSwitch("disabled", !enableBinding());

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscription.dispose();
            });
        }
    }

    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const onLabel = allBindingsAccessor()["onLabel"];
        if (onLabel) $(element).find(".switch-left").html(ko.utils.unwrapObservable(onLabel));

        const offLabel = allBindingsAccessor()["offLabel"];
        if (offLabel) $(element).find(".switch-right").html(ko.utils.unwrapObservable(offLabel));

        let value = ko.utils.unwrapObservable(valueAccessor());

        if (value == null || value == undefined) value = false;

        (<any>$(element)).bootstrapSwitch("state", value, true);
    }
}

ko.bindingHandlers["onOffSwitch"] = new OnOffSwitch();
