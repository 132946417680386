import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 01/12/2017
 * Time: 12:05
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { ProLifeService } from "./ProLifeService";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { IService } from "../../Core/interfaces/IService";
import {
    IApplicationsConfigurationsService,
    IAddApplicationConfigurationRequest,
    IUpdateApplicationConfigurationRequest,
    IApplicationConfiguration,
} from "../interfaces/prolife-sdk/IApplicationsConfigurationsService";

class ApplicationsConfigurationsService extends ProLifeService implements IApplicationsConfigurationsService {
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.ApplicationsConfigurationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IApplicationsConfigurationsService>(), this);

        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType(): string {
        return ProlifeSdk.ApplicationsConfigurationsServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return this.getServiceType() == serviceType;
    }

    async AddOrUpdateConfiguration(configuration: IApplicationConfiguration): Promise<IApplicationConfiguration> {
        if (!configuration.ConfigurationId || configuration.ConfigurationId <= 0) {
            const configId = await this.AddApplicationConfiguration(configuration);
            configuration.ConfigurationId = configId;
        } else {
            await this.UpdateApplicationConfiguration(configuration);
        }

        return configuration;
    }

    AddApplicationConfiguration(request: IAddApplicationConfigurationRequest): Promise<number> {
        let result = this.ajaxService.Post<number>("Desktop-api/Configurations", "AddApplicationConfiguration", {
            background: true,
            methodData: request,
        });

        return result;
    }

    UpdateApplicationConfiguration(request: IUpdateApplicationConfigurationRequest): Promise<void> {
        let result = this.ajaxService.Post<void>("Desktop-api/Configurations", "UpdateApplicationConfiguration", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetApplicationConfiguration(
        ConfigurationType: number | null,
        UserId: number | null,
        ModuleId: number | null,
        ObjectId: number | null
    ): Promise<IApplicationConfiguration[]> {
        let result = this.ajaxService.Post<IApplicationConfiguration[]>(
            "Desktop-api/Configurations",
            "GetApplicationConfiguration",
            {
                background: true,
                methodData: {
                    ConfigurationType: ConfigurationType,
                    UserId: UserId,
                    ModuleId: ModuleId,
                    ObjectId: ObjectId,
                },
            }
        );

        return result;
    }

    GetApplicationConfigurationById(configurationId: number | null): Promise<IApplicationConfiguration[]> {
        let result = this.ajaxService.Post<IApplicationConfiguration[]>(
            "Desktop-api/Configurations",
            "GetApplicationConfigurationById",
            {
                background: true,
                methodData: {
                    configurationId: configurationId,
                },
            }
        );

        return result;
    }

    DeleteApplicationConfiguration(configurationId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Desktop-api/Configurations", "DeleteApplicationConfiguration", {
            background: true,
            methodData: {
                configurationId: configurationId,
            },
        });

        return result;
    }

    GetUserPreferredApplicationConfiguration(
        userId: number | null,
        objectId: number | null
    ): Promise<IApplicationConfiguration> {
        let result = this.ajaxService.Post<IApplicationConfiguration>(
            "Desktop-api/Configurations",
            "GetUserPreferredApplicationConfiguration",
            {
                background: true,
                methodData: {
                    userId: userId,
                    objectId: objectId,
                },
            }
        );

        return result;
    }

    SaveUserPreferredConfiguration(
        userId: number | null,
        configurationId: number | null,
        objectId: number | null
    ): Promise<void> {
        let result = this.ajaxService.Post<void>("Desktop-api/Configurations", "SaveUserPreferredConfiguration", {
            background: true,
            methodData: {
                userId: userId,
                configurationId: configurationId,
                objectId: objectId,
            },
        });

        return result;
    }

    RemoveUserPreferredConfiguration(userId: number | null, objectId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Desktop-api/Configurations", "RemoveUserPreferredConfiguration", {
            background: true,
            methodData: {
                userId: userId,
                objectId: objectId,
            },
        });

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ApplicationsConfigurationsService(serviceLocator);
}
