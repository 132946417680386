import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IProjectsService } from "../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IWorkItemHint, IWorkItemBase } from "../../../ProlifeSdk/interfaces/projects/IWorkItem";

export class WorkItemsControlsEntityProvider implements IControlsEntityProvider
{
    private projectsService : IProjectsService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    private projectId : number = null;

    constructor(private serviceLocator : IServiceLocator){
        this.projectsService = <IProjectsService> serviceLocator.findService(ProlifeSdk.ProjectsServiceType);
    }

    public SetProjectFilter(projectId : number)
    {
        this.projectId = projectId;
    }

    public findEntities(query : any) {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.projectsService.workItemsHintSearch(query.term, this.projectId)
                .then((data) => {
                    query.callback({
                        results: data
                            .map((wi : IWorkItemHint) => {
                            return {
                                id: wi.Id,
                                text: wi.Name
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback) {
        var id = parseInt(<string>$(element).val());
        if(!isNaN(id) && id > 0) {
            this.projectsService.getWorkItem(id)
                .then((wi : IWorkItemBase) => callback({
                    id: wi.id,
                    text: wi.name
                }));
        }
    }
}