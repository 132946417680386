import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { classNames, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import {
    ITodoListService,
    WorkflowRelatedDocumentNew,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DateTimeText } from "../../../../Components/DateTimeText";
import { NumericText } from "../../../../Components/NumericText";
import { DocumentClosingStatusIndicator } from "../../../../Components/DocumentClosingStatusIndicator";
import { DocumentType, documentTypeTranslate } from "../../../../Components/DocumentType";

const styleSheet = jss.createStyleSheet({
    relatedDocuments: {
        width: "1200px",
        paddingBottom: "10px",

        "& .margin-left-5": {
            marginLeft: "5px",
        },
    },
});
const { classes } = styleSheet.attach();

type RelatedDocumentsPopOverProps = {
    workflowId: number;
};

type WorkflowRelatedDocumentNewEx = WorkflowRelatedDocumentNew & {
    assignedPrice: number;
};

export class RelatedDocumentsPopOver extends DialogComponentBase {
    public templateName: string;
    public templateUrl: string;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    private customerOrders: ko.ObservableArray<WorkflowRelatedDocumentNewEx> = ko.observableArray([]);
    private otherDocuments: ko.ObservableArray<WorkflowRelatedDocumentNewEx> = ko.observableArray([]);
    private isLoading = ko.observable(true);

    constructor(private props: RelatedDocumentsPopOverProps) {
        super({ popover: true });
        this.title(TextResources.Todolist.WorkflowRelatedDocumentsPopOverTitle);
        this.load();
    }

    private async load(): Promise<void> {
        const documents = await this.todoListService.GetWorkflowRelatedDocumentsNew(this.props.workflowId);
        const docsWithAssignedPrice = documents.map((d) => ({
            ...d,
            assignedPrice: (d.workflowAmount / (!d.rowAmount ? 1 : d.rowAmount)) * d.rowPrice,
        }));

        this.customerOrders(docsWithAssignedPrice.filter((d) => d.documentType === "COR"));
        this.otherDocuments(docsWithAssignedPrice.filter((d) => d.documentType !== "COR"));

        this.isLoading(false);
    }

    public action(): void {
        this.modal.close();
    }

    public async show(element: HTMLElement): Promise<void> {
        if (!this.authorizationsService.isAuthorized("TaskBoard_ViewWorkflowRelatedDocuments")) return;
        return this.dialogsService.ShowPopoverComponent(element, this, "left");
    }

    private renderDocumentsTable(title: string, documents: ko.ObservableArray<WorkflowRelatedDocumentNewEx>) {
        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<WorkflowRelatedDocumentNewEx>();

        return (
            <div className={classNames("row", classes.relatedDocuments)}>
                <div className="col-md-12">
                    <Table
                        id={ProlifeSdk.WorkflowRelatedDocumentsTable}
                        compact
                        bordered
                        striped
                        systemScrollable
                        fixedLayout
                        sortableColumns
                        showColumnSelector
                        columnSelectorPosition="right"
                        enableAggregators
                        showLoadingIndicator={this.isLoading}
                        title={title}
                        dataSource={Table.defaultDataSource(documents, (rd) => ({
                            id: rd.id,
                            title: rd.documentNumber,
                        }))}
                    >
                        <Column
                            id={1}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsDocTypeTitle}
                            sorter={sortString((d) => documentTypeTranslate(d.documentType as DocumentType))}
                            aggregateOn={(item) => item.Data.model.documentType}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <DocumentType documentType={item.Data.model.documentType as DocumentType} />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={2}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsProtocolTitle}
                            sorter={sortString((d) => d.documentProtocolName)}
                            aggregateOn={(item) => item.Data.model.documentProtocolName}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <span>{item.Data.model.documentProtocolName}</span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={3}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsDocNumberTitle}
                            sorter={sortString((d) => d.documentNumber)}
                            aggregateOn={(item) => item.Data.model.documentNumber}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <DocumentClosingStatusIndicator
                                        documentId={item.Data.model.documentId}
                                        documentType={item.Data.model.documentType}
                                        protocolId={item.Data.model.documentProtocolId}
                                        documentClosingState={item.Data.model.documentClosingState}
                                        documentState={item.Data.model.documentState}
                                        documentCauseLogicType={item.Data.model.documentCauseLogicType}
                                        className="btn-xs margin-left-5"
                                        content={<span>{item.Data.model.documentNumber}</span>}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={4}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsDocDateTitle}
                            sorter={sortDate((d) => d.documentDate)}
                            aggregateOn={(item) => item.Data.model.documentDate}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <DateTimeText dateTime={item.Data.model.documentDate} format="L" />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={5}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsDocCustomerTitle}
                            sorter={sortString((d) => d.customerName)}
                            aggregateOn={(item) => item.Data.model.customerName}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <span>{item.Data.model.customerName}</span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={6}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsDocRowNumberTitle}
                            sorter={sortNumber((d) => d.rowNumber)}
                            aggregateOn={(item) => item.Data.model.rowNumber}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <NumericText value={item.Data.model.rowNumber} format="0,0" />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={7}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsAssignedAmountTitle}
                            sorter={sortNumber((d) => d.workflowAmount)}
                            aggregateOn={(item) => item.Data.model.workflowAmount}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <span>
                                        <NumericText value={item.Data.model.workflowAmount} format="0,0[.][0000]" />/
                                        <NumericText value={item.Data.model.rowAmount} format="0,0[.][0000]" />
                                        &nbsp;
                                        <span>{item.Data.model.rowUoM}</span>
                                    </span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            id={8}
                            title={TextResources.Todolist.WorkflowRelatedDocumentsAssignedPriceTitle}
                            sorter={sortNumber((d) => d.assignedPrice * d.rowAmount)}
                            aggregateOn={(item) => item.Data.model.assignedPrice * item.Data.model.rowAmount}
                        >
                            <ColumnBody>
                                {(item: ITableItem<WorkflowRelatedDocumentNewEx>) => (
                                    <span>
                                        <NumericText
                                            value={item.Data.model.assignedPrice * item.Data.model.rowAmount}
                                            format="0,0[.][0000] $"
                                        />
                                        /
                                        <NumericText
                                            value={item.Data.model.rowPrice * item.Data.model.rowAmount}
                                            format="0,0[.][0000] $"
                                        />
                                    </span>
                                )}
                            </ColumnBody>
                        </Column>
                    </Table>
                </div>
            </div>
        );
    }

    renderBody() {
        return ComponentUtils.bindTo(
            <>
                {this.renderDocumentsTable(TextResources.Todolist.CustomerOrdersTitle, this.customerOrders)}
                {this.renderDocumentsTable(TextResources.Todolist.OtherDocumentsTitle, this.otherDocuments)}
            </>,
            this
        );
    }
}
