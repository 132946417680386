import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 26/02/13
 * Time: 16.22
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class CustomBoolProvider  implements IEntityProvider<boolean, boolean>
{

    constructor() {

    }

    getType() : string
    {
        return ProlifeSdk.TagType_Bool;
    }

    getPkValue(item : boolean) : boolean
    {
        return item;
    }

    getDisplayName(item : boolean) : string
    {
        return item ? ProlifeSdk.TextResources.Blog.True : ProlifeSdk.TextResources.Blog.False;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Blog.TrueFalse;
    }

    getDisplayNameForKeyValue(pkValue : boolean) : Promise<string>
    {
        return Promise.resolve(pkValue ? "True" : "False");
    }

    hintSearch(stringFilter : string) : Promise<any[]>
    {
        return Promise.resolve<any[]>([]);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        //Da implementare se necessario
        return null;
    }

    getEntity(pkValue : any) : Promise<any>
    {
        //Da implementare !!!
        return Promise.resolve<any>(undefined);
    }
}