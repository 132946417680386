import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2016
 * Time: 10:55
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { StatesWithCounterNavigationProvider } from "./StatesWithCounterNavigationProvider";
import { IJobOrderService } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../../../../../Core/interfaces/IAuthenticationService";
import { IJobOrderStateWithCounter } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import {
    INavigationFilters,
    INavigationMultipleFilters,
} from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class LogicalStatesWithCounterNavigationProvider extends NavigationProvider {
    public Icon: string = "fa fa-folder";
    public Background: string = "";
    public Foreground: string = "";

    constructor(private serviceLocator: IServiceLocator, public logicalState: number, title: string) {
        super();
        this.Name = title;
        this.templateName = "job-orders-folder";
        this.templateUrl = "jobOrder/templates/navigation";

        const authorizationsService: IAuthorizationService = <IAuthorizationService>(
            serviceLocator.findService(ServiceTypes.Authorization)
        );
        const authenticationService: IAuthenticationService = <IAuthenticationService>(
            serviceLocator.findService(ServiceTypes.Authentication)
        );
        const jobOrdersService: IJobOrderService = <IJobOrderService>(
            serviceLocator.findService(ProlifeSdk.JobOrderServiceType)
        );

        const userId = (<any>authenticationService.getUser()).IdUser;
        const canViewAllJobOrders = authorizationsService.isAuthorized("JobOrders_ViewAllJobOrders");

        jobOrdersService
            .getJobOrderStatesWithCounters(userId, canViewAllJobOrders)
            .then((states: IJobOrderStateWithCounter[]) => {
                const mappedStates = states
                    .filter((s: IJobOrderStateWithCounter) => {
                        return this.logicalState == -1 || this.logicalState == s.LogicalState;
                    })
                    .map((s: IJobOrderStateWithCounter) => {
                        let state = new StatesWithCounterNavigationProvider(serviceLocator, s);
                        state.setCurrentNavigator(this.navigator());
                        return state;
                    });
                this.Items(mappedStates);
            });
    }

    public SetFilterInfoOnModel(f: INavigationFilters) {
        f.LogicalState = this.logicalState;
    }

    public SetMultipleFilterInfoOnModel(f: INavigationMultipleFilters) {
        f.LogicalState = this.logicalState;
    }

    public OnJobOrderDropped(event, ui) {}

    public ValidateJobOrderDrop(status, el) {
        return false;
    }
}
