import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 14:12
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { IQuestionnaireState, IQuestionnaireLogicalState } from "../../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IQuestionnaireStateSettingManager } from "../../../../../interfaces/IQuestionnaireStateSettingManager";

export interface IQuestionnaireStateProvider {
    getData() : IQuestionnaireState;
    update(questionnaireState : IQuestionnaireState) : void;
    withError: ko.Observable<boolean>;
}

export class QuestionnaireStateEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IQuestionnaireStateProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);

    public LogicalStates: ko.ObservableArray<IQuestionnaireLogicalState> = ko.observableArray([]);
    public NewState: ko.Observable<string> = ko.observable();

    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private questionnaireStateManager: IQuestionnaireStateSettingManager) {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.title = questionnaireStateManager.getLabel();

        this.LogicalStates(this.questionnaireStateManager.getQuestionnaireLogicalState());

        this.reloadElements();
    }

    private reloadElements() {
        this.elements([]);
        this.questionnaireStateManager.load();
        var questionnaireStates = this.questionnaireStateManager.getQuestionnaireStates();
        questionnaireStates.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(questionnaireState: IQuestionnaireState) {
        this.elements.push(new QuestionnaireStateViewModel(this, questionnaireState, this.questionnaireStateManager.getQuestionnaireLogicalState()));
    }

    public addNewSetting() {
        if (!this.NewState())
            return;
        var questionnaireStateViewModel = new QuestionnaireStateViewModel(this, {
            Id: null,
            Description: this.NewState(),
            LogicalStateId: this.questionnaireStateManager.getQuestionnaireStates()[0].Id,
            Locked: this.questionnaireStateManager.getQuestionnaireStates()[0].Locked
        }, this.questionnaireStateManager.getQuestionnaireLogicalState());
        this.elements.push(questionnaireStateViewModel);
        this.createOrUpdateEntry(questionnaireStateViewModel);

        this.NewState("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element: IQuestionnaireStateProvider) {
        var questionnaireState = element.getData();
        this.questionnaireStateManager.addOrUpdate(questionnaireState)
            .then((updatedQuestionnaireState) => element.update(updatedQuestionnaireState));
    }

    public deleteEntry(element: IQuestionnaireStateProvider) {
        var questionnaireState = element.getData();
        this.questionnaireStateManager.remove(questionnaireState.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.toastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            });
    }
}


class QuestionnaireStateViewModel implements IQuestionnaireStateProvider {
    Id: ko.Observable<number> = ko.observable();
    Label : ko.Observable<string> = ko.observable();
    LogicalState : ko.Observable<number> = ko.observable();
    Locked: ko.Observable<boolean> = ko.observable();
    SelectedLogicalState : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    public withError: ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    constructor(private container : QuestionnaireStateEditingViewModel, private questionnaireState : IQuestionnaireState, public questionnaireLogicalState : IQuestionnaireLogicalState[]) {
        this.Id(questionnaireState.Id);
        this.Label(questionnaireState.Description);
        this.LogicalState(questionnaireState.LogicalStateId);
        this.Locked(questionnaireState.Locked);

        this.setInitialState();

        this.Label.subscribe(this.onNameChanged.bind(this));
        this.LogicalState.subscribe(this.onDataChanged.bind(this));
    }

    private setInitialState(): void {
        this.SelectedLogicalState(this.questionnaireLogicalState.filter((s: IQuestionnaireLogicalState) => {
            return s.Id == this.LogicalState();
        })[0].Description);

    }

    public SetLogicalState(state: IQuestionnaireLogicalState): void {
        this.LogicalState(state.Id);
        this.SelectedLogicalState(state.Description);
    }

    private onNameChanged(newValue : string) {
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    getData() : IQuestionnaireState {
        var questionnaireState: IQuestionnaireState = <IQuestionnaireState> $.extend({}, this.questionnaireState);
        questionnaireState.Id = this.Id();
        questionnaireState.Description = this.Label();
        questionnaireState.LogicalStateId = this.LogicalState();
        return questionnaireState;
    }

    private onDataChanged(newValue : string) {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    update(questionnaireState : IQuestionnaireState) : void {
        this.updating = true;
        this.questionnaireState = questionnaireState;
        this.Id(questionnaireState.Id);
        this.Label(questionnaireState.Description);
        this.LogicalState(questionnaireState.LogicalStateId);
        this.Locked(questionnaireState.Locked);

        this.updating = false;
    }
}