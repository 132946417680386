import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { DateTimeInput } from "../DateTimeInput";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type DateRangeColumnFilterProps = {
    initialDateFrom: Date;
    initialDateTo: Date;
    onDateRangeChanged: (start: Date, end: Date) => void;

    datePickerParent?: string;
}

export function DateRangeColumnFilter(props: DateRangeColumnFilterProps) {
    const C = require("./DateRangeColumnFilter")._DateRangeColumnFilter as typeof _DateRangeColumnFilter;
    return <C {...props} />;
}

export class _DateRangeColumnFilter extends DialogComponentBase {
    static defaultProps: Partial<DateRangeColumnFilterProps> = {
    }

    DateFrom : ko.Observable<Date> = ko.observable();
    DateTo : ko.Observable<Date> = ko.observable();

    constructor(private props : DateRangeColumnFilterProps) {
        super({ popover: true });

        this.DateFrom(props.initialDateFrom);
        this.DateTo(props.initialDateTo);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        const onDateRangeChanged = () => {
            this.props.onDateRangeChanged(this.DateFrom(), this.DateTo());
        };

        return <div>
            <div class="form-group">
                <label className="control-label">Dal</label>
                <DateTimeInput value={this.DateFrom} allowClear simple placeholder="Data inizio" onChange={onDateRangeChanged} parent={this.props.datePickerParent} />
            </div>
            <div class="form-group">
                <label className="control-label">Al</label>
                <DateTimeInput value={this.DateTo} allowClear simple placeholder="Data fine" onChange={onDateRangeChanged} parent={this.props.datePickerParent} />
            </div>
        </div>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DateRangeColumnFilter);
}