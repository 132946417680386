import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";

export class ProtocolsApplication implements IApplication
{
    public templateName : string;
    public templateUrl : string;
    public templateData : any;
    public tileColor : string = 'tile-orange';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);
    
    private applicationsService : IApplicationsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Protocols";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Desktop.Records;
    }

    getIcon(): string
    {
        return "icon-layers";
    }

    registerRoutes() : void
    {
    }

    getApplicationRoute() : string
    {
        return "http://old.intranet.ab:81/#/Protocollo";
    }

    onGoingDown() : void
    {
        if(this.templateData)
            this.templateData.dispose();
    }

    private start()
    {
    }
}