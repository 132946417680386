import * as ko from "knockout";
export class BootstrapAffix {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var options = valueAccessor() || {};
        (<any>$(element)).affix(options);
    }
}

ko.bindingHandlers["bootstrapAffix"] = new BootstrapAffix();
