import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 04/04/2017
 * Time: 13:02
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SplashPageColumn, JobOrderForSplash } from "./SplashPageColumnsView";
import { IJobOrderService, IJobOrderForSplashWithBefore } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IEntitiesListNotificationsManagerAdapter } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IEntitiesListNotificationsManagerAdapter";
import { IJobOrderForSplash } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";

export class SplashPageColumnsNotificationManagerAdapter implements IEntitiesListNotificationsManagerAdapter {
    public ShowNotificationsMessages : boolean = true;
    public EntityCode : string;
    private jobOrdersService : IJobOrderService;

    constructor(private serviceLocator : IServiceLocator,
                private getFiltersMethod,
                private columns : ko.ObservableArray<SplashPageColumn>,
                private createViewModelMethod : (j : IJobOrderForSplash) => JobOrderForSplash)
    {
        this.EntityCode = ProlifeSdk.JobOrderEntityTypeCode
        this.jobOrdersService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public GetJsonDataFromItem(item : any) : any
    {
        return item.jobOrder;
    }

    public GetNextItem(itemId : number) : Promise<void>
    {
        //return this.jobOrdersService.GetNextItem(itemId, this.getFiltersMethod());
        return Promise.resolve<void>(null);
    }

    public GetItemWithBefore(itemId : number) : Promise<IJobOrderForSplashWithBefore>
    {
        return this.jobOrdersService.GetItemWithBefore(itemId, this.getFiltersMethod());
    }

    public ExtractElementFromListById(itemId : number) : any
    {
        var jobOrder = null;
        this.columns().forEach((c : SplashPageColumn) => {
            var jobOrderById = c.GetJobOrderById(itemId);
            if(jobOrderById)
                jobOrder = jobOrderById;
        });
        return jobOrder;
    }

    public GetItemId(item : any) : number
    {
        return item ? item.jobOrder.JobOrderId : -1;
    }

    public RemoveFromList(itemId : number)
    {
        this.columns().forEach((c : SplashPageColumn) => {
            c.RemoveJobOrderById(itemId);
        });
    }

    public CreateViewModelFor(jsonData : IJobOrderForSplash) : Promise<JobOrderForSplash>
    {
        var def = new Deferred<JobOrderForSplash>();
        return def.resolve(this.createViewModelMethod(jsonData)).promise();
    }

    public GetItemIdByJsonData(jsonData : IJobOrderForSplash) : number
    {
        return jsonData ? jsonData.JobOrderId : null;
    }

    public InsertAt(item : any, insertFromMe : boolean, index : number = -1, insert : boolean = false)
    {
        var destinationState = item.State().IdJobOrderStateId;
        var matchedColumn = this.columns().filter((j : SplashPageColumn) => j.status == destinationState);
        if(matchedColumn.length > 0) {
            if(!matchedColumn[0].GetJobOrderById(item.jobOrder.JobOrderId)) {
                matchedColumn[0].JobOrders.push(item);
                matchedColumn[0].JobOrders.sort((a : JobOrderForSplash, b : JobOrderForSplash) => b.jobOrder.JobOrderId - a.jobOrder.JobOrderId);
            }
        }
    }

    public GetListItemIndex(item : any) : number
    {
        return 0;
    }

    public ExtractLastElementFromList() : any
    {
        return null;//this.jobOrdersList().length > 0 ? this.jobOrdersList()[this.jobOrdersList().length-1] : null;
    }
}