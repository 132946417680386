import * as ko from "knockout";
export class DropDownShowBindingHandler{

    init(element: any,
         valueAccessor: () => any,
         allBindingsAccessor: () => any,
         viewModel: any, bindingContext: ko.BindingContext): any
    {
        var showDropDown = valueAccessor();
        $(element).on('click.dropdown.data-api', () => {
            showDropDown(false);
        });
        $('html').on('click.dropdown.data-api', () => {
            showDropDown(false);
        });
    }

    update(element: any,
           valueAccessor: () => any,
           allBindingsAccessor: () => any,
           viewModel: any,
           bindingContext: ko.BindingContext): void{

        var value : boolean = ko.utils.unwrapObservable(valueAccessor());
        if (value)
        {
            (<any>$(element)).dropdown('toggle');
        }

    }

}

ko.bindingHandlers["DropDownShow"] = new DropDownShowBindingHandler();
