import * as ko from "knockout";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { TaskBoardColumn, TaskForTaskBoard } from "./TaskBoardColumn";
import { TodoListTasksProvider } from "../tasks-list/providers/TodoListTasksProvider";
import { TodoListTask } from "../tasks-list/providers/TodoListTask";
import { WorkflowCategoryControlsEntityProvider } from "../../entity-providers/WorkflowCategoryControlsEntityProvider";
import { WorkflowEditDialog } from "../workflows/WorkflowEditDialog";
import { ShowTaskboardSearchField } from "../../../../Bindings/ShowTaskboardSearchField";
import { ChangeStateDialog } from "../tasks-list/dialogs/ChangeStateDialog";
import { ChangePlanDialog } from "../tasks-list/dialogs/ChangePlanDialog";
import { AdvancedFilterDialog } from "./dialogs/AdvancedFilterDialog";
import { PageSwitcher } from "../../../../ProlifeSdk/prolifesdk/PageSwitcher";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { WorkflowsDataSource } from "../../../../DataSources/WorkflowsDataSource";
import { Delay } from "../../../../Decorators/Delay";
import { IWorkflowOutcome } from "../../../WorkflowOutcomesService";
import { IWorkflowOutcomeSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IHumanResourcesService, IHumanResource } from "../../../../Users/HumanResourcesService";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
    INavigationMenuComponentActionsGroup,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import {
    ITodoListService,
    IJobOrderForTaskBoard,
    IWorkflowForTaskBoard,
    ITaskForTaskBoard,
    ITaskDialogOptions,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask, ITodoListTask } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { ITaskBoardAdvancedFilterDialogResult } from "../../../interfaces/ITaskBoardAdvancedFilterDialog";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationHost } from "../../../../Desktop/interfaces/IApplicationHost";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IPageSwitcherObserver } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IPageSwitcherObserver";
import { IPageConfigurationMap } from "../../../../ProlifeSdk/interfaces/IPageConfigurationMap";
import { AlertsLegendUI } from "../../../../Components/AlertsLegend";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ProLifeClipboard } from "../../../../Core/utils/ProLifeClipboard";

export interface ITaskBoard {
    selectedTasks: ko.ObservableArray<TaskForTaskBoard>;
    ResourceId: ko.Observable<number>;

    SelectTask(t: TaskForTaskBoard, evt: MouseEvent | null);
    StatePlusOne(status: number);
    OpenDialogForChangeState();
    OpenDialogForChangePlan();
    EditSelectedTask();
    DeleteTask();
}

export class TaskBoard
    implements IChangesNotificationsServiceObserver, IPageSwitcherObserver, IDataSourceListener, ITaskBoard
{
    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    public templateName = "task-board";
    public templateUrl = "todolist/templates/task-board";

    public todoListService: ITodoListService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private changesNotificationsService: IChangesNotificationsService;
    private humanResourcesService: IHumanResourcesService;
    private desktopService: IDesktopService;

    public ViewWorker: ko.Observable<boolean> = ko.observable(true);
    public ViewResponsible: ko.Observable<boolean> = ko.observable(true);
    public ViewAll: ko.Observable<boolean> = ko.observable(false);
    public ShowClosed: ko.Observable<boolean> = ko.observable(false);
    public HideNoWork: ko.Observable<boolean> = ko.observable(false);

    public selectedJobOrder: ko.Observable<IJobOrderForTaskBoard> = ko.observable();
    public selectedWorkflow: ko.Observable<IWorkflowForTaskBoard> = ko.observable();
    public selectedTasks: ko.ObservableArray<TaskForTaskBoard> = ko.observableArray([]);
    public selectedTask: ko.Computed<TaskForTaskBoard>;
    public SetActivity: ko.Observable<any> = ko.observable();

    public Columns: TaskBoardColumn[] = [];

    /* Per versione mobile */
    public taskboardSearchFieldEnabler: ShowTaskboardSearchField;
    public taskboardPageSwitcherHandler: PageSwitcher;
    public pageSwitcherMap: IPageConfigurationMap = {};
    private applicationHostService: IApplicationHost;
    private afterDeleteCallback: (taskId: number) => void;
    /* ------------------- */

    private tasksProvider: TodoListTasksProvider;
    private currentResource: IHumanResource;

    public Category: ko.Observable<number> = ko.observable();
    public CategoriesProvider: WorkflowCategoryControlsEntityProvider;

    public ResourceId: ko.Observable<number> = ko.observable();
    public SearchFilter: ko.Observable<string> = ko
        .observable()
        .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

    public AdvancedFilter: ko.Observable<ITaskBoardAdvancedFilterDialogResult> = ko.observable();
    public EnableListsFilteringOnSerachField: ko.Observable<boolean> = ko.observable(false);

    private outcomesSettings: IWorkflowOutcomeSettingsManager;

    public JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource(true, true);
    public JobOrdersMenu: ko.Observable<INavigationMenuComponent> = ko.observable();

    public WorkflowsDataSource: WorkflowsDataSource = new WorkflowsDataSource();
    public WorkflowsMenu: ko.Observable<INavigationMenuComponent> = ko.observable();

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService!: IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator, public UserId: ko.Observable<number>) {
        this.WorkflowsDataSource.enableNotifications(true);

        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersMenu.subscribe((menu: INavigationMenuComponent) => {
            const canViewAll = this.authorizationService.isAuthorized("TaskBoard_CanViewAll");

            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            };

            menu.registerAction(showLegendAction);

            const action: INavigationMenuComponentActionsGroup = {
                isGroup: true,
                isSeparator: false,
                icon: () => "fa fa-angle-down",
                actions: [
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "glyphicon glyphicon-exclamation-sign",
                        text: "Visualizza Tutto",
                        visible: () => canViewAll,
                        canExecute: () => canViewAll,
                        active: () => this.ViewAll(),
                        action: () => {
                            this.ViewAll(!this.ViewAll());
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "glyphicon glyphicon-user",
                        text: "Lavoro",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => this.ViewWorker(),
                        action: () => {
                            this.ViewWorker(!this.ViewWorker());
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "glyphicon glyphicon-tasks",
                        text: "Project Management",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => this.ViewResponsible(),
                        action: () => {
                            this.ViewResponsible(!this.ViewResponsible());
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-eye",
                        text: "Progetti chiusi",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => this.ShowClosed(),
                        action: () => {
                            this.ShowClosed(!this.ShowClosed());
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-eye-slash",
                        text: "Nascondi senza lavoro",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => this.HideNoWork(),
                        action: () => {
                            this.HideNoWork(!this.HideNoWork());
                        },
                    },
                ],
            };

            menu.registerAction(action);
        });

        this.WorkflowsMenu.subscribe((menu) => {
            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            };

            menu.registerAction(showLegendAction);
        });

        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.humanResourcesService = <IHumanResourcesService>(
            serviceLocator.findService(ProlifeSdk.HumanResourcesServiceType)
        );
        this.infoToastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.changesNotificationsService = <IChangesNotificationsService>(
            serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType)
        );
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
        this.desktopService = <IDesktopService>this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        const settingsManager = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.outcomesSettings = <IWorkflowOutcomeSettingsManager>(
            settingsManager.findSettingsManager(ProlifeSdk.WorkflowOutcomes)
        );

        this.tasksProvider = new TodoListTasksProvider();
        this.CategoriesProvider = new WorkflowCategoryControlsEntityProvider();

        const columnSize = 100.0 / 7.0;

        if (this.desktopService.isMobileBrowser()) {
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    -1,
                    ProlifeSdk.TextResources.Todolist.Backlog,
                    columnSize,
                    columnSize * 0,
                    "fa fa-inbox label-default-mobile lbl",
                    "#c6c6c6"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    0,
                    ProlifeSdk.TextResources.Todolist.ToDo,
                    columnSize,
                    columnSize * 1,
                    "fa fa-tachometer label-primary-mobile lbl",
                    "#428bca"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    1,
                    ProlifeSdk.TextResources.Todolist.InProgress,
                    columnSize,
                    columnSize * 2,
                    "fa fa-bolt label-warning-mobile lbl",
                    "#ecbc29"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    2,
                    ProlifeSdk.TextResources.Todolist.Completed,
                    columnSize,
                    columnSize * 3,
                    "fa fa-flag-checkered label-success-mobile lbl",
                    "#45b6af"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    3,
                    ProlifeSdk.TextResources.Todolist.Verified,
                    columnSize,
                    columnSize * 4,
                    "fa fa-check label-success-mobile lbl",
                    "#45b6af"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    4,
                    ProlifeSdk.TextResources.Todolist.Suspended,
                    columnSize,
                    columnSize * 5,
                    "fa fa-clock-o label-danger-mobile lbl",
                    "#89c4f4"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    5,
                    ProlifeSdk.TextResources.Todolist.DeletedColumn,
                    columnSize,
                    columnSize * 6,
                    "fa fa-trash-o label-danger-mobile lbl",
                    "#f3565d"
                )
            );
        } else {
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    -1,
                    ProlifeSdk.TextResources.Todolist.Backlog,
                    columnSize,
                    columnSize * 0,
                    "fa fa-inbox",
                    "#c6c6c6"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    0,
                    ProlifeSdk.TextResources.Todolist.ToDo,
                    columnSize,
                    columnSize * 1,
                    "fa fa-tachometer",
                    "#428bca"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    1,
                    ProlifeSdk.TextResources.Todolist.InProgress,
                    columnSize,
                    columnSize * 2,
                    "fa fa-bolt",
                    "#ecbc29"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    2,
                    ProlifeSdk.TextResources.Todolist.Completed,
                    columnSize,
                    columnSize * 3,
                    "fa fa-flag-checkered",
                    "#45b6af"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    3,
                    ProlifeSdk.TextResources.Todolist.Verified,
                    columnSize,
                    columnSize * 4,
                    "fa fa-check",
                    "#45b6af"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    4,
                    ProlifeSdk.TextResources.Todolist.Suspended,
                    columnSize,
                    columnSize * 5,
                    "fa fa-clock-o",
                    "#89c4f4"
                )
            );
            this.Columns.push(
                new TaskBoardColumn(
                    this,
                    5,
                    ProlifeSdk.TextResources.Todolist.DeletedColumn,
                    columnSize,
                    columnSize * 6,
                    "fa fa-trash-o",
                    "#f3565d"
                )
            );
        }

        this.recalculateColumnSizes();

        let updating = false;

        this.ViewWorker.subscribe(() => {
            if (updating) return;

            updating = true;

            if (!this.ViewWorker() && !this.ViewResponsible()) {
                this.ViewWorker(true);
                updating = false;
                return;
            }

            updating = false;

            this.LoadJobOrders();
        });
        this.ViewResponsible.subscribe(() => {
            if (updating) return;

            updating = true;

            if (!this.ViewWorker() && !this.ViewResponsible()) {
                this.ViewResponsible(true);
                updating = false;
                return;
            }

            updating = false;

            this.LoadJobOrders();
        });

        this.ViewAll.subscribe(() => {
            this.LoadJobOrders();
        });

        this.ShowClosed.subscribe(() => {
            this.LoadJobOrders();
        });

        this.HideNoWork.subscribe(() => {
            this.LoadJobOrders();
        });

        this.Category.subscribe(this.LoadJobOrders.bind(this));

        this.ResourceId.subscribe(() => {
            if (updating) return;

            updating = true;

            this.humanResourcesService
                .GetHumanResource(this.ResourceId())
                .then((r: IHumanResource) => {
                    this.UserId(r.Resource.FkUser);
                    this.currentResource = r;
                    this.LoadJobOrders();
                })
                .finally(() => {
                    updating = false;
                });
        });

        this.UserId.subscribe(() => {
            this.JobOrdersDataSource.setUserId(this.UserId());

            if (!this.UserId()) return;

            if (!updating) {
                updating = true;

                this.humanResourcesService
                    .GetHumanResources()
                    .then((resources: IHumanResource[]) => {
                        const matches = resources.filter((r: IHumanResource) => r.Resource.FkUser == this.UserId());
                        this.ResourceId(matches.length == 0 ? null : matches[0].Resource.Id);
                        this.currentResource = matches.length == 0 ? null : matches[0];
                        this.LoadJobOrders();
                    })
                    .finally(() => {
                        updating = false;
                    });
            } else {
                this.LoadJobOrders();
            }
        });

        this.UserId.valueHasMutated();

        this.EnableListsFilteringOnSerachField.subscribe((value: boolean) => {
            this.LoadJobOrders();
        });

        this.SearchFilter.subscribe((filter: string) => {
            if (this.EnableListsFilteringOnSerachField()) this.LoadJobOrders();
        });

        this.AdvancedFilter.subscribe(() => {
            this.LoadJobOrders();
        });

        this.selectedTask = ko.computed(() => {
            const tasks = this.selectedTasks();
            return tasks.length == 0 ? null : tasks[0];
        });

        /* Per versione mobile */
        this.taskboardSearchFieldEnabler = new ShowTaskboardSearchField();
        this.taskboardPageSwitcherHandler = new PageSwitcher();
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Projects,
            true,
            ProlifeSdk.TextResources.Todolist.ProjectsPageTitle,
            true,
            "next",
            false,
            false,
            true
        );
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Plans,
            false,
            ProlifeSdk.TextResources.Todolist.PlansPageTitle,
            true,
            "next",
            false
        );
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Taskboard,
            false,
            ProlifeSdk.TextResources.Todolist.TaskboardPageTitle
        );
        this.Columns.forEach((c: TaskBoardColumn, i: number) => {
            const INDEX = "TaskboardColumn" + i;
            this.taskboardPageSwitcherHandler.addPage(INDEX, false, c.title, true, "next", false);
        });
        this.taskboardPageSwitcherHandler.registerObserver(this);
        this.desktopService.SystemHeader.setContextMenu({
            templateName: "task-board-filters",
            templateUrl: "Todolist/Templates/Task-Board",
            viewModel: this,
        });
        this.pageSwitcherMap[ProlifeSdk.Page_Projects] = {
            onGoingBack: this.deselectJobOrder.bind(this),
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };
        this.pageSwitcherMap[ProlifeSdk.Page_Plans] = {
            onGoingBack: this.deselectWorkflow.bind(this),
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };
        this.pageSwitcherMap[ProlifeSdk.Page_Taskboard] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };
        this.applicationHostService = <IApplicationHost>(
            serviceLocator.findService(ProlifeSdk.ApplicationHostServiceType)
        );
        this.applicationHostService.SetSideMenuEnabled(true, true);
        /* ------------------- */
    }

    public ShowTaskBoardAdvancedFiltersDialog(): void {
        const dialog = new AdvancedFilterDialog(this.serviceLocator, this.AdvancedFilter());
        dialog.show().then((advFilter: ITaskBoardAdvancedFilterDialogResult) => {
            this.AdvancedFilter(advFilter);
        });
    }

    private findTaskColumn(taskId: number, isTask: boolean) {
        for (const c of this.Columns) {
            if (!c.ContainsTask(taskId, isTask)) continue;
            return c;
        }

        return null;
    }

    private findColumnByStatus(statusId: number) {
        return this.Columns.find((c) => c.status === statusId);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        const isTask = changesInfo.EntityCode !== ProlifeSdk.WorkflowEntityTypeCode;
        const elementId = changesInfo.EntityKeyId;

        //TODO: Gestire lo ShownInWorkflowId

        if (changesInfo.Action === 0 && isTask) {
            //Task created
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (changesInfo.Object.workflowId !== selectedWorkflow) return true;

            const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [elementId]);
            if (!task) return false; //Task not found

            const column = this.findColumnByStatus(task.TaskBoardStatus);
            column.AddTask(task);
        }

        if (changesInfo.Action === 1 && isTask) {
            //Task modified
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const existingColumn = this.findTaskColumn(elementId, isTask);
            if (!existingColumn) return false; //Task not found
            existingColumn.RemoveTask(elementId, isTask);

            const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [elementId]);
            if (!task) return false; //Task not found

            const column = this.findColumnByStatus(task.TaskBoardStatus);
            column.AddTask(task);
        }

        if (changesInfo.Action === 2 && isTask) {
            //Task deleted
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (changesInfo.Object?.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            column.RemoveTask(elementId, isTask);
        }

        if (changesInfo.Action === 8 && isTask) {
            //Status Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            const task = column.RemoveTask(elementId, isTask);
            if (!task) return false; //Task not found

            task.task.TaskBoardStatus = changesInfo.Changes.NewStatus;
            const newColumn = this.findColumnByStatus(changesInfo.Changes.NewStatus);
            newColumn.AddTask(task.task);
        }

        if (changesInfo.Action === 9 && isTask) {
            //Workflow Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            const taskId = changesInfo.Object?.taskId;

            if (changesInfo.Object.workflowId === selectedWorkflow) {
                const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [taskId]);
                if (!task) return false; //Task not found

                const column = this.findColumnByStatus(task.TaskBoardStatus);
                column.AddTask(task);
            }

            if (changesInfo.Changes.OldStatus.previousWorkflowId === selectedWorkflow) {
                const column = this.findTaskColumn(taskId, isTask);
                if (!column) return false; //Task not found

                column.RemoveTask(taskId, isTask);
            }
        }

        if (changesInfo.Action === 10 && isTask) {
            //Task Position Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            const task = column.RemoveTask(elementId, isTask);
            if (!task) return false; //Task not found

            task.task.PositionIndex = changesInfo.Object.newPosition;
            column.AddTask(task.task);
        }

        let action: string = ProlifeSdk.TextResources.Todolist.Modified;
        action = changesInfo.Action == 0 ? ProlifeSdk.TextResources.Todolist.Inserted : action;
        action = changesInfo.Action == 2 ? ProlifeSdk.TextResources.Todolist.Deleted : action;

        if (!sendByMe) {
            const title = changesInfo.Object.title;
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Todolist.TaskChangeMessage,
                    title,
                    action,
                    changesInfo.UserFullName
                )
            );
        }

        return false;
    }

    public dispose() {
        this.WorkflowsDataSource.enableNotifications(false);
        this.changesNotificationsService.RemoveObserver(this);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<number>): void {
        if (sender === this.JobOrdersDataSource && model) {
            this.selectedJobOrder(model.model);
            this.selectedWorkflow(null);
            this.selectedTasks([]);

            this.WorkflowsMenu().select();

            this.LoadWorkflows();
            this.LoadTasks();

            this.taskboardPageSwitcherHandler.goToPage(ProlifeSdk.Page_Plans);
        } else if (sender === this.WorkflowsDataSource) {
            this.selectedWorkflow(model.model);
            this.selectedTasks([]);

            this.LoadTasks();

            this.taskboardPageSwitcherHandler.goToPage(ProlifeSdk.Page_Taskboard);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.JobOrdersDataSource) {
            this.selectedJobOrder(null);
            this.selectedWorkflow(null);
            this.selectedTasks([]);

            this.LoadWorkflows();
            this.LoadTasks();
        }
    }

    canDropTo(sender: INavigationMenuComponent, dropDestination: IDataSourceModel<number>): boolean {
        if (sender === this.WorkflowsMenu()) {
            return dropDestination && dropDestination.isLeaf;
        }

        return false;
    }

    dropTo(sender: INavigationMenuComponent, dropDestination: IDataSourceModel<number>, droppedData: string): void {
        const task: IDraggedTask = JSON.parse(droppedData);

        if (task.CompanyGuid != this.userInfo.getCurrentCompanyGuid()) return;

        if (!this.selectedWorkflow() || !this.selectedJobOrder()) return;

        if (this.selectedWorkflow() && task.WorkflowId == dropDestination.id) return;

        if (this.selectedJobOrder() && task.JobOrderId != this.selectedJobOrder().Id) return;

        this.todoListService.MoveTaskToWorkflow(task.TaskId, dropDestination.id);
    }

    public CreateNewTask() {
        const jobOrderId = this.selectedJobOrder().Id;
        const workflowId = this.selectedWorkflow().Id;
        const options: ITaskDialogOptions = {
            tasksProvider: this.tasksProvider,
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            showClosedJobOrders: false,
        };

        return this.todoListService.ShowCreateNewTaskDialog(jobOrderId, workflowId, options);
    }

    public CreateNewWorkflow() {
        const notInitializedStates = this.outcomesSettings.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: this.selectedJobOrder().Id,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const dialog: WorkflowEditDialog = new WorkflowEditDialog({ workflow: workflow });
        dialog.ShowDialog();
    }

    public CreateNewWorkflowFromTemplate() {
        this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder().Id);
    }

    public CreateNewWorkflowFromWorkflow() {
        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder().Id);
    }

    public StatePlusOne(status: number) {
        if (!this.selectedTask()) return;

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
            this.Columns.forEach((t: TaskBoardColumn) => {
                if (((status + 2) % 6) - 1 === t.status) {
                    t.SetSelectedState(t);
                    t.MoveToState(task.Id, task.IsTask());
                }
            });
        });
    }

    public EditSelectedWorkflow() {
        const workflow = this.selectedWorkflow();
        this.todoListService.GetWorkflow(workflow.Id).then((w: ITodoListWorkflow) => {
            const dialog: WorkflowEditDialog = new WorkflowEditDialog({ workflow: w });
            dialog.ShowDialog();
        });
    }

    public async EditSelectedTask(): Promise<void> {
        if (!this.selectedTask()) return;

        const taskId = this.selectedTask().task.Id;
        const options: ITaskDialogOptions = {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            userId: this.UserId(),
            tasksProvider: this.tasksProvider,
            showClosedJobOrders: false,
            workedHoursData: { resourceId: this.ResourceId(), date: null },
        };

        await this.todoListService.ShowEditTaskDialog(taskId, options);
    }

    public async NewTaskFromTask(): Promise<boolean> {
        return this.todoListService.ShowCopyTaskDialog(this.selectedWorkflow().Id, this.selectedJobOrder().Id, {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            tasksProvider: this.tasksProvider,
        });
    }

    public async NewTaskFromTemplate(): Promise<boolean> {
        return this.todoListService.ShowCreateTaskFromTemplateDialog(
            this.selectedWorkflow().Id,
            this.selectedJobOrder().Id,
            {
                initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
                tasksProvider: this.tasksProvider,
            }
        );
    }

    public OpenDialogForChangeState() {
        if (!this.selectedTask()) return;

        const dialog: ChangeStateDialog = new ChangeStateDialog(this.serviceLocator, this.tasksProvider, this);

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
            dialog.SetActivity(task);
            dialog.ShowModal();
        });
    }

    public OpenDialogForChangePlan() {
        if (!this.selectedTask()) return;

        const dialog: ChangePlanDialog = new ChangePlanDialog(this.serviceLocator, this.tasksProvider, this);

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
            dialog.SetActivity(task);
            dialog.ShowModal();
        });
    }

    public DeleteTask() {
        if (!this.selectedTask()) return;

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Todolist.SureToDeleteTask,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
            ProlifeSdk.TextResources.Todolist.DeleteTask,
            (confirm: boolean) => {
                if (!confirm) return;
                this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
                    const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
                    //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
                    this.SetActivity(task);
                    this.SetActivity().Delete();
                });
            }
        );
    }

    @Delay()
    private LoadTasks() {
        this.Columns.forEach((c: TaskBoardColumn) => {
            c.LoadTasks();
        });
    }

    @Delay()
    private LoadWorkflows() {
        const selectedJobOrderId = this.selectedJobOrder() ? this.selectedJobOrder().Id : null;
        this.WorkflowsDataSource.setJobOrders(selectedJobOrderId);
        this.WorkflowsDataSource.setViewFilters(this.ViewWorker(), this.ViewResponsible(), this.ViewAll());
        this.WorkflowsDataSource.setWorkFilters(!this.HideNoWork());
        this.WorkflowsDataSource.setFilterContents(this.EnableListsFilteringOnSerachField(), this.SearchFilter());
        this.WorkflowsDataSource.setCategoryFilter(this.Category());
        this.WorkflowsDataSource.setUserId(this.UserId());
        const advancedFilters = this.AdvancedFilter();
        this.WorkflowsDataSource.setDateFilters(
            advancedFilters ? advancedFilters.StartDate : null,
            advancedFilters ? advancedFilters.EndDate : null,
            advancedFilters ? advancedFilters.DestinationStatus : null
        );

        this.WorkflowsDataSource.refresh();
    }

    private LoadJobOrders() {
        this.selectedJobOrder(null);
        this.selectedWorkflow(null);
        this.selectedTasks([]);

        this.Columns.forEach((c) => c.Clear());

        this.JobOrdersDataSource.setViewFilters(this.ViewWorker(), this.ViewResponsible(), this.ViewAll());
        this.JobOrdersDataSource.setWorkFilters(!this.HideNoWork());
        this.JobOrdersDataSource.setFilterContents(this.EnableListsFilteringOnSerachField(), this.SearchFilter());
        this.JobOrdersDataSource.setUserId(this.UserId());
        const advancedFilters = this.AdvancedFilter();
        this.JobOrdersDataSource.setDateFilters(
            advancedFilters ? advancedFilters.StartDate : null,
            advancedFilters ? advancedFilters.EndDate : null,
            advancedFilters ? advancedFilters.DestinationStatus : null
        );

        this.JobOrdersDataSource.setAdvancedFilters({
            workflowType: this.Category(),
            jobOrderState: undefined,
            workflowState: undefined,
        });
        this.JobOrdersDataSource.setShowClosed(this.ShowClosed());
        this.JobOrdersDataSource.refresh(false);

        this.LoadWorkflows();
    }

    public recalculateColumnSizes() {
        const collapsedColumns = this.Columns.filter((c) => c.isCollapsed());
        const expandedColumns = this.Columns.filter((c) => !c.isCollapsed());

        const collapsedColumnWidth = 10;
        const expandedColumnMeanWidth =
            (100 - 1 - collapsedColumns.length * collapsedColumnWidth) / expandedColumns.length;
        let left = 0;

        this.Columns.forEach((c) => {
            if (!c.isCollapsed()) {
                c.columnWidth(expandedColumnMeanWidth);
                c.columnOffset(left);

                left += expandedColumnMeanWidth;
            } else {
                c.columnWidth(collapsedColumnWidth);
                c.columnOffset(left);

                left += collapsedColumnWidth;
            }
        });
    }

    onGoingBack(newPageName: string) {
        if (typeof this.pageSwitcherMap[newPageName] != "undefined") this.pageSwitcherMap[newPageName].onGoingBack();
    }

    onPageChanged(newPageName: string, oldPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined)
            this.applicationHostService.SetSideMenuEnabled(
                this.pageSwitcherMap[newPageName].LeftMenuEnabled,
                this.pageSwitcherMap[newPageName].RightMenuEnabled
            );
    }

    deselectJobOrder() {
        this.JobOrdersDataSource.select();
    }

    deselectWorkflow() {
        this.WorkflowsDataSource.select();
    }

    GoBack() {
        this.taskboardPageSwitcherHandler.goBack();
    }

    public SelectTask(t: TaskForTaskBoard, evt: MouseEvent | null) {
        if (this.selectedTask() && (!evt || (!evt.ctrlKey && !evt.metaKey))) {
            for (const task of this.selectedTasks()) {
                task.IsSelected(false);
            }
            this.selectedTasks([]);
        }

        this.selectedTasks.push(t);
        t.IsSelected(true);
    }

    public onCopy() {
        if (!this.selectedTasks().length) return;

        let taskTitles = "";
        for (const task of this.selectedTasks()) {
            taskTitles += task.task.Title + "\n";
        }
        taskTitles = taskTitles.trim();

        ProLifeClipboard.copy(taskTitles);

        if (this.selectedTasks().length > 1)
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskTitlesCopiedToClipboard);
        else this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskTitleCopiedToClipboard);
    }

    onBeginDrag(task: ITaskForTaskBoard, dataTransfer: DataTransfer) {
        let allTasks = this.selectedTasks().map((t) => t.task);
        if (allTasks.length == 0)
            //Se non ho una selezione, uso il task draggato
            allTasks.push(task);
        else if (allTasks.indexOf(task) == -1)
            //Se il task draggato non è nella selezione, uso il task draggato
            allTasks = [task];

        let taskTitles = "";
        for (const task of allTasks) {
            taskTitles += task.Title + "\n";
        }
        taskTitles = taskTitles.trim();

        const draggedTasks = allTasks.map(this.createDraggedTask, this);

        dataTransfer.setData("text/plain", taskTitles);
        dataTransfer.setData("application/prolife-task", JSON.stringify(draggedTasks[0]));
        dataTransfer.setData("application/prolife-tasks", JSON.stringify(draggedTasks));
    }

    private createDraggedTask(task: ITaskForTaskBoard): IDraggedTask {
        return {
            IsTask: task.IsTask,
            TaskId: task.Id,
            TaskBoardStatus: task.TaskBoardStatus,
            WorkflowId: task.WorkflowId,
            JobOrderId: task.JobOrderId,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };
    }
}
