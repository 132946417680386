import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import "./SplashPageOrderDetailsComponent";
import "./SplashPageWorkflowsDetailsComponent";
import "./SplashPageAllocationDetailsComponent";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LayoutContent } from "../../../../Components/Layouts";
import { JobOrderDetails } from "../job-order-detail/JobOrderDetails";

export interface ISplashPageDetailsParams {
    JobOrderId: number;
    Title: string;

    className?: string;
}

export class SplashPageDetailsDialog extends DialogComponentBase {
    JobOrderId: number;
    
    constructor(private props : ISplashPageDetailsParams) {
        super({ className: props.className ?? 'fullscreen', noPrompt: true });
        this.title(this.props.Title);
        this.JobOrderId = this.props.JobOrderId;
    }

    public renderBody() {
        return ComponentUtils.bindTo((
        <LayoutContent>
            <JobOrderDetails jobOrderId={this.JobOrderId}/>
        </LayoutContent>), this, "splashPageDetails");
    }
}