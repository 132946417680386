import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IMetelImportResultReportError, IMetelImportResultReport } from "../../../../ProlifeSdk/interfaces/warehouse/IMetelImportResultReport";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { Layout } from "../../../../Components/Layouts";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IException } from "../../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";

export class MetelImportReport
{
    HeaderErrors : IMetelImportResultReportError[] = [];
    ArticlesErrors : IMetelImportResultReportError[] = [];
    DbErrors : IMetelImportResultReportError[] = [];
    NumberOfArticles : number;
    HasErrors : boolean;
    CatalogUpdated : boolean;

    constructor(report : IMetelImportResultReport)
    {
        this.HasErrors = report.Errors && report.Errors.length > 0;
        this.HeaderErrors = report && report.Errors ? report.Errors.filter((e : IMetelImportResultReportError) => { return e.Level == 0; }) : [];
        this.ArticlesErrors = report && report.Errors ? report.Errors.filter((e : IMetelImportResultReportError) => { return e.Level == 1; }) : [];
        this.DbErrors = report && report.Errors ? report.Errors.filter((e : IMetelImportResultReportError) => { return e.Level == 2; }) : [];
        this.NumberOfArticles = report.NumberOfArticles;
        this.CatalogUpdated = report.CatalogUpdated;
    }
}

export class MetelImport extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor() {
        super({ noPrompt: true });
        this.title(ProlifeSdk.TextResources.Warehouse.CatalogsImport);
    }

    public showDialog()
    {
        this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return <MetelImporter />;
    }
}

type MetelImporterProps = {

};

export function MetelImporter(props: MetelImporterProps) {
    const C = require("./MetelImporter")._MetelImporter as typeof _MetelImporter;
    return <C {...props} />;
}

type ImportStatus = {
    fileName: string;
    status: ko.Observable<string>;
    statusIcon: ko.Observable<string>;
    result: ko.Observable<MetelImportReport>;
    completed: ko.Observable<boolean>;
    error: ko.Observable<boolean>;
    expanded: ko.Observable<boolean>;
}

export class _MetelImporter
{
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    selectedFiles : ko.ObservableArray<File> = ko.observableArray();
    files: ko.ObservableArray<ImportStatus> = ko.observableArray();
    loading : ko.Observable<boolean> = ko.observable(false);

    private importCode : string;
    private eventsToDeregister: number[] = [];

    constructor(private props: MetelImporterProps)
    {
        
    }

    componentDidMount() {
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportStart", (data: { ImportCode: string; FileNames: string[]; })=> {
            if(data.ImportCode === this.importCode) {
                this.files(data.FileNames.map(f => ({
                    fileName: f,
                    status: ko.observable(ProlifeSdk.TextResources.Warehouse.MetelImportWaiting),
                    statusIcon: ko.observable("fa-clock-o"),
                    result: ko.observable(),
                    completed: ko.observable(false),
                    error: ko.observable(false),
                    expanded: ko.observable(false)
                })));
                this.files.push({
                    fileName: "Sincronizzazione e Verifica dati",
                    status: ko.observable(ProlifeSdk.TextResources.Warehouse.MetelImportWaiting),
                    statusIcon: ko.observable("fa-clock-o"),
                    result: ko.observable(),
                    completed: ko.observable(false),
                    error: ko.observable(false),
                    expanded: ko.observable(false)
                })

                this.loading(false);
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportUploadError", (data: { ImportCode: string; Error: IException; })=> {
            if(data.ImportCode === this.importCode) {
                this.infoToastService.Error(data.Error.ExceptionMessage);
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportFileStart", (data: { ImportCode: string; FileName: string; })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === data.FileName);
                if(!file) {
                    console.warn(`File non trovato "${data.FileName}"`);
                    return;
                }
                file.status(ProlifeSdk.TextResources.Warehouse.MetelImportInProgress);
                file.statusIcon("fa-circle-o-notch fa-spin");
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportFileEnd", (data: { ImportCode: string; FileName: string; Result: IMetelImportResultReport })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === data.FileName);
                file.result(new MetelImportReport(data.Result));

                if(data.Result.Errors && data.Result.Errors.length > 0) {
                    file.status(ProlifeSdk.TextResources.Warehouse.MetelImportError);
                    file.statusIcon("fa-times");
                    file.completed(true);
                    file.error(true);
                } else {
                    file.status(ProlifeSdk.TextResources.Warehouse.MetelImportDone + ` (${data.Result.NumberOfArticles})`);
                    file.statusIcon("fa-check");
                    file.completed(true);
                    file.error(false);
                }
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportSyncStart", (data: { ImportCode: string; FileName: string; })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === "Sincronizzazione e Verifica dati");
                if(!file) {
                    console.warn(`File non trovato "${data.FileName}"`);
                    return;
                }
                file.status(ProlifeSdk.TextResources.Warehouse.MetelImportInProgress);
                file.statusIcon("fa-circle-o-notch fa-spin");
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("MetelImportSyncEnd", (data: { ImportCode: string; })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === "Sincronizzazione e Verifica dati");
                file.result(new MetelImportReport({ CatalogUpdated: false, Errors: [], NumberOfArticles: 0 }));
                file.status(ProlifeSdk.TextResources.Warehouse.MetelImportDone);
                file.statusIcon("fa-check");
                file.completed(true);
                file.error(false);
            }
        }));
    }

    componentWillUnmount() {
        this.eventsToDeregister.forEach(i => this.changesNotificationsService.UnregisterEventHandler(undefined, i));
    }

    public action(): void
    {
        /*if(this.selectedFiles().length == 0)
        {
            this.close();
            return;
        }

        this.uploadFile()
            .then((r : IMetelImportResultReport) => {
                var dialog = new MetelImportReportDialog(r);
                this.close();
                setTimeout(() => {
                    this.dialogsService.ShowModal<void>(dialog, "metel-import-result-report", null, dialog.templateUrl, dialog.templateName);
                }, 1000);
            });*/
    }

    public async uploadFile() : Promise<void>
    {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Warehouse.ImportInProgress);
        this.loading(true);
        
        const files = this.selectedFiles();
        const data = {};
        for(let i = 0; i < files.length; i++)
        {
            data['metel' + i] = files[i];
        }

        try
        {
            const result = await this.ajaxService.Upload<{ ImportCode: string }>("Warehouse-api", "Metel", data);
            this.importCode = result.ImportCode;
        }
        catch
        {
            this.loading(false);
        }
        finally
        {
            this.desktopService.UnblockPageUI();
        }
    }

    render() {
        const $data = this;
        let file: IDataSourceModel<string, ImportStatus>;
        let result: MetelImportReport;
        let error : IMetelImportResultReportError;
        const dataSource = Table.defaultDataSource(this.files, (d) => ({ id: d.fileName, title: d.fileName, model: d }));

        return  ComponentUtils.bindTo(
            <div>
                <Layout.Grid columns={["1fr"]} rows={["auto", "400px"]} rowGap={"15px"}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <div className="input-group fileinput-new" data-bind={{ fileInput : {} }}>
                            <div className="form-control uneditable-input" data-trigger="fileinput">
                                <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                <span className="fileinput-filename"></span>
                            </div>
                            <span className="input-group-addon btn default btn-file">
                                <span className="fileinput-new">Seleziona file</span>
                                <span className="fileinput-exists">Cambia file</span>
                                <input type="file" data-bind={{ file: $data.selectedFiles }} multiple />
                            </span>
                            <a href="#" className="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">Rimuovi file</a>
                        </div>
                        <button className="btn btn-primary" data-bind={{ click: $data.uploadFile }} style={{ marginLeft: 'auto' }}>Importa</button>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Table dataSource={dataSource} rowAs="file" systemScrollable showLoadingIndicator={this.loading}>
                            <Column title="File">
                                <span data-bind={{ text: file.model.fileName }}></span>
                            </Column>
                            <Column title="Stato" style={{ width: '200px'}} className="text-right">
                                <span data-bind={{ css: { 'text-success': file.model.completed, 'text-danger': file.model.error }}}>
                                    <span data-bind={{ text: file.model.status }}></span>&nbsp;
                                    <i className="fa" data-bind={{ css: file.model.statusIcon }} />&nbsp;
                                </span>
                                <a href="#" data-bind={{ click: () => file.model.expanded(!file.model.expanded()) }}>
                                    <i className="fa" data-bind={{ css: { 'fa-angle-down': !file.model.expanded(), 'fa-angle-up': file.model.expanded }}} />
                                </a>
                            </Column>
                            <SecondaryRow if={() => "file.model.expanded"}>
                                {(item: ITableItem<ImportStatus>) => 
                                    <div data-bind={{ with: file.model.result, as: 'result' }}>
                                        <ko-bind data-bind={{ if: !result.HasErrors && result.CatalogUpdated }}>
                                            <div class="alert alert-warning">ATTENZIONE: Il listino importato era già presente con il numero di riferimento specificato. Non è stata creata una nuova versione del listino ma è stato eseguito l'aggiornamento della versione presente.</div>
                                        </ko-bind>
                                        <ko-bind data-bind={{ if: result.HasErrors }}>
                                            <div class="alert alert-danger">ATTENZIONE: Il listino non è stato importato. Correggere gli errori riscontrati e riprovare.</div>
                                        </ko-bind>

                                        <div style={{ marginBottom : '30px', marginLeft : '10px' }}>
                                            <span>Articoli presenti nel listino</span>&nbsp;
                                            <strong data-bind={{ numberText : result.NumberOfArticles }}></strong>
                                        </div>

                                        <ko-bind data-bind={{ if: result.HeaderErrors.length > 0 }}>
                                            <table class="table table-advance">
                                                <thead>
                                                    <tr>
                                                        <th>Errori di formattazione testata</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-bind={{ foreach : { data: result.HeaderErrors, as: 'error' } }}>
                                                    <tr>
                                                        <td data-bind={{ text : error.Message }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ko-bind>
                                        
                                        <ko-bind data-bind={{ if: result.ArticlesErrors.length > 0 }}>
                                            <table class="table table-advance">
                                                <thead>
                                                    <tr>
                                                        <th>Errori di formattazione articoli</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-bind={{ foreach : { data: result.ArticlesErrors, as: 'error' } }}>
                                                    <tr>
                                                        <td data-bind={{ text : error.Message }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ko-bind>
                                        
                                        
                                        
                                        <ko-bind data-bind={{ if: result.DbErrors.length > 0 }}>
                                            <table class="table table-advance">
                                                <thead>
                                                    <tr>
                                                        <th>Errori di importazione dati</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-bind={{ foreach : { data: result.DbErrors, as: 'error' } }}>
                                                    <tr>
                                                        <td data-bind={{ text : error.Message }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ko-bind>
                                    </div>
                                }
                            </SecondaryRow>
                        </Table>
                    </Layout.Grid.Cell>
                </Layout.Grid>
            </div>
        , this);
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(MetelImporter);
}