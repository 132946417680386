import jsPDF from "jspdf";
import { ReportComponent, ReportPage } from "../Components";
import { ExportBackground } from "./ReportBackgroundExporter";
import { ExportChildren } from "./ReportChildrenExporter";
import { ExporterFor, ReportExporter } from "./ReportExporterProvider";

@ExporterFor(nameof<ReportPage>())
export class ReportPageExporter implements ReportExporter {
    async export(doc: jsPDF, component: ReportPage) {
        doc.addPage();
        await ExportBackground(doc, component, 0, 0, 21, 29.7);
        await ExportChildren(doc, component, 0, 0);
    }
}