import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IEntitiesListNotificationsManagerObserver,
    IObjectChangesInfo,
} from "../../../interfaces/desktop/IChangesNotificationsService";
import { IEntitiesListNotificationsManagerAdapter, IGenericItemWithBefore } from "../../../interfaces/prolife-sdk/IEntitiesListNotificationsManagerAdapter";
import { Deferred } from "../../../../Core/Deferred";

export class EntitiesListNotificationsManager implements IChangesNotificationsServiceObserver {
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    private observers: IEntitiesListNotificationsManagerObserver[] = [];

    constructor(private adapter: IEntitiesListNotificationsManagerAdapter) {
        this.changesNotificationsService.ObserveNotificationsFor(adapter.EntityCode, this);
    }

    public AddObserver(o: IEntitiesListNotificationsManagerObserver) {
        if (this.observers.indexOf(o) == -1) this.observers.push(o);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (changesInfo.Action == 2) this.WhenItemHasBeenDeleted(changesInfo, sendByMe);
        else if (changesInfo.Action == 0) this.WhenItemHasBeenCreated(changesInfo, sendByMe);
        else if (changesInfo.Action == 1) this.WhenItemHasBeenUpdated(changesInfo, sendByMe);

        return false;
    }

    public Dispose() {
        //E' importante invocarla altrimenti l'elemento rimane sempre in ascolto di notifiche
        this.changesNotificationsService.RemoveObserver(this);
    }

    /***
     * Gestione delle notifiche push in seguito alla modifica di elementi
     * @param changesInfo
     * @constructor
     */
    private WhenItemHasBeenUpdated(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        var modifiedId: number = changesInfo.EntityKeyId;
        var isTask = !changesInfo.Object ? true : changesInfo.Object.IsTask;

        this.adapter.GetItemWithBefore(modifiedId, isTask).then((j: IGenericItemWithBefore) => {
            var currentElement = this.adapter.ExtractElementFromListById(modifiedId);
            var elementMatchWithFilters: boolean = j.Item != null;

            //Se l'elemento non era in lista e non c'è neanche a seguito delle modifiche allora non devo far nulla
            if (!currentElement && !elementMatchWithFilters) return;

            var oldItem: any = this.adapter.ExtractElementFromListById(modifiedId);

            //Rimuovo l'elemento aggiornato se attualmente presente in lista
            if (currentElement) this.adapter.RemoveFromList(modifiedId);

            //Se l'elemento modificato è uscito dal range di visualizzazione devo inserire il prossimo elemento disponibile
            if (!elementMatchWithFilters) {
                var lastElement: any = this.adapter.ExtractLastElementFromList();
                if (lastElement)
                    this.adapter.GetNextItem(this.adapter.GetItemId(lastElement)).then((next: any) => {
                        this.PushElementIntoList(next, this.adapter.GetJsonDataFromItem(lastElement), sendByMe);
                    });
                return;
            }

            this.PushElementIntoList(j.Item, j.Before, sendByMe).then(() => {
                //Notifico la modifica agli observer
                var newItem: any = this.adapter.ExtractElementFromListById(modifiedId);
                this.observers.forEach((o: IEntitiesListNotificationsManagerObserver) => {
                    o.OnItemUpdated(oldItem, newItem);
                });
            });
        });
    }

    private PushElementIntoList(jsonItemToPush: any, jsonBeforeItem: any, insertFromMe: boolean, insert: boolean = false): Promise<void> {
        var def = new Deferred<void>();

        if (!jsonItemToPush) return def.resolve().promise();

        var lastElement = this.adapter.ExtractLastElementFromList();
        this.adapter.CreateViewModelFor(jsonItemToPush).then((itemToPush) => {
            //Inserimento in coda vuota
            if (!lastElement) {
                this.adapter.InsertAt(itemToPush, insertFromMe, -1, insert);
                def.resolve();
                return;
            }

            //Inserimento successivo ad un elemento già presente in lista
            if (jsonBeforeItem) {
                var beforeId: number = this.adapter.GetItemIdByJsonData(jsonBeforeItem);
                var before = this.adapter.ExtractElementFromListById(beforeId);
                var beforeIndex: number = this.adapter.GetListItemIndex(before);
                this.adapter.InsertAt(itemToPush, insertFromMe, before != lastElement ? beforeIndex + 1 : -1, insert);
                def.resolve();
                return;
            }

            //Inserimento in testa...
            this.adapter.InsertAt(itemToPush, insertFromMe, 0, insert);
            def.resolve();
        });

        return def.promise();
    }

    /***
     * Gestione delle notifiche push in seguito alla creazione di elementi
     * @param changesInfo
     * @constructor
     */
    private WhenItemHasBeenCreated(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        var createdId: number = changesInfo.EntityKeyId;
        var isTask = !changesInfo.Object ? true : changesInfo.Object.IsTask;

        this.adapter.GetItemWithBefore(createdId, isTask).then((d: IGenericItemWithBefore) => {
            this.PushElementIntoList(d.Item, d.Before, sendByMe, true).then(() => {
                //Notifico l'inserimento agli observer
                var item: any = this.adapter.ExtractElementFromListById(createdId);
                this.observers.forEach((o: IEntitiesListNotificationsManagerObserver) => {
                    o.OnItemCreated(item);
                });
            });
        });
    }

    /***
     * Gestione delle notifiche push in seguito alla cancellazione di elementi
     * @param changesInfo
     * @constructor
     */
    private WhenItemHasBeenDeleted(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        var deletedId = changesInfo.EntityKeyId;
        var deletedItem = this.adapter.ExtractElementFromListById(deletedId);

        if (!deletedItem) return;

        this.adapter.RemoveFromList(deletedId);

        //Notifico la cancellazione agli observer
        this.observers.forEach((o: IEntitiesListNotificationsManagerObserver) => {
            o.OnItemDeleted(deletedItem);
        });

        var lastItem = this.adapter.ExtractLastElementFromList();

        if (!lastItem) return;

        var lastItemId: number = this.adapter.GetItemId(lastItem);

        this.adapter.GetNextItem(lastItemId).then((i: any) => {
            if (!i) return;

            this.adapter.CreateViewModelFor(i).then((item) => {
                this.adapter.InsertAt(item, sendByMe);
            });
        });
    }
}
