import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Document } from "./Document";

export function DocumentShippingInformation() {
    let doc: Document;

    return  <ko-if data-bind="CanHaveShippingInformation">
                <div class="invoice-shipping-informations bootstrap" data-as={{doc}}>
                    <table class="table table-condensed table-advance">
                        <thead>
                            <tr>
                                <th>{TextResources.Invoices.DocumentShippingInfoPackages}</th>
                                <th style="width: 31%">{TextResources.Invoices.DocumentShippingInfoCause}</th>
                                <th style="width: 31%">{TextResources.Invoices.DocumentShippingInfoAspect}</th>
                                <th style="width: 31%">{TextResources.Invoices.DocumentShippingInfoTransport}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ko-bind data-bind={{ if: doc.ReadOnly }}>
                                        <span class="input-block-level" data-bind={{ text: doc.Packages }}></span>
                                        <input type="checkbox" data-bind={{ visible: doc.CanCopy, checked: doc.ShippingInfoSelected }} />
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                                        <input autoComplete="off" class="input-block-level" type="text" data-bind="value: Packages, numbersOnly: true" />
                                    </ko-bind>
                                    
                                </td>
                                <td>
                                    <select2 
                                        readOnly={() => "ReadOnly"}
                                        class="cause-selector select2-container-for-documents" 
                                        value={() => "FKCause"}
                                        dataSource={() => "DDTCausesDataSource"}
                                        allowClear={true}
                                        simple={true}
                                        noFormControl={true}
                                        placeholder={TextResources.Invoices.DocumentShippingInfoCausePlaceholder}
                                        listener={() => "$data"}></select2>
                                </td>
                                <td>
                                    <typeahead
                                        value={() => "Aspect"}
                                        dataSource={() => "AspectsDataSource"}
                                        readOnly={() => "ReadOnly"}
                                        simple={true}
                                        placeholder={TextResources.Invoices.DocumentShippingInfoAspectPlaceholder}></typeahead>
                                </td>
                                <td>
                                <typeahead
                                        value={() => "Transport"}
                                        dataSource={() => "TransportsDataSource"}
                                        readOnly={() => "ReadOnly"}
                                        simple={true}
                                        placeholder={TextResources.Invoices.DocumentShippingInfoTransportPlaceholder}></typeahead>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="invoice-shipping-informations bootstrap" data-as={{doc}}>
                    <table class="table table-condensed table-advance">
                        <thead>
                            <tr>
                                <th style="width : 33%">{TextResources.Invoices.DocumentShippingInfoWeight}</th>
                                <th style="width : 33%">{TextResources.Invoices.DocumentShippingInfoCarriage}</th>
                                <th>{TextResources.Invoices.DocumentShippingInfoTransportStart}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ko-bind data-bind={{ if: doc.ReadOnly }}>
                                        <span style="width : 100%" class="input-block-level" data-bind={{ numberText: doc.Weight }}></span>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                                        <input autoComplete="off" style="width : 100%" class="input-block-level" type="text" data-bind="numberValue: Weight, selectOnFocus : {}, numbersOnly: true" />
                                    </ko-bind>
                                </td>
                                <td>
                                    <select2 
                                        readOnly={() => "ReadOnly"}
                                        class="cause-selector select2-container-for-documents" 
                                        value={() => "FKCarriage"}
                                        dataSource={() => "CarriagesDataSource"}
                                        allowClear={true}
                                        simple={true}
                                        noFormControl={true}
                                        placeholder={TextResources.Invoices.DocumentShippingInfoCarriagePlaceholder}
                                        listener={() => "$data"}></select2>
                                    </td>
                                <td>
                                    <ko-bind data-bind={{ if: doc.ReadOnly }}>
                                        <span style="width : 100%" class="input-block-level" data-bind={{ timeText: doc.TransportStart }}></span>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                                        <input autoComplete="off" style="width : 100%" class="input-block-level" type="text" placeholder={TextResources.Invoices.DocumentShippingInfoTransportStartPlaceholder} data-bind="timePickerEx: TransportStart, selectOnFocus : {}" />
                                    </ko-bind>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="invoice-signs">
                    <div class="row">
                        <div class="label">{TextResources.Invoices.DocumentShippingInfoTransporterSignature}</div>
                        <div class="label">{TextResources.Invoices.DocumentShippingInfoRecipientSignature}</div>
                    </div>
                    <div class="row">
                        <div class="label">___________________________</div>
                        <div class="label">___________________________</div>
                    </div>
                </div>
            </ko-if>;
}