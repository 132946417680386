import * as ko from "knockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ExpenseLogicalStatus } from "../enums/ExpenseLogicalStatus";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IExpenseStatus, IExpenseService } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface IExpenseStatusesSettingsManager extends ISettingsManager {
    getStatuses(textFilter?: string, includeDeleted?: boolean): IExpenseStatus[];
    getStatusById(id: number): IExpenseStatus;
    insertOrUpdateStatuses(statuses: IExpenseStatus[]): Promise<IExpenseStatus[]>;
}

export class ExpenseStatusesSettingsManager implements IExpenseStatusesSettingsManager {
    private statuses: IExpenseStatus[] = [];

    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    
    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Expenses.ExpensesSettingsGroup);
    }
    
    public load(): Promise<IExpenseStatus[]> {
        return this.expenseService.GetExpenseStatuses(null, true, 0, 100000000)
            .then((statuses: IExpenseStatus[]) => {
                this.statuses = statuses;
                return statuses;
            });
    }
    
    public getName(): string {
        return nameof<IExpenseStatusesSettingsManager>();
    }
    
    public getLabel(): string {
        return TextResources.Expenses.ExpenseStatusesSettingsManager;
    }
    
    public hasEditingUI(): boolean {
        return true;
    }
    
    public getEditingUI(): IView {
        return {
            templateName: null,
            templateUrl: null,
            viewModel: null,
            component: "expense-statuses-editor"
        };
    }

    public getStatuses(textFilter: string = null, includeDeleted: boolean = false): IExpenseStatus[] {
        return this.statuses.filter(s => (s.Deleted === false || includeDeleted) && (!textFilter || this.matchTextFilter(s)) && this.hasRights(s));
    }
    
    public getStatusById(id: number): IExpenseStatus {
        return this.statuses.firstOrDefault(s => s.Id === id);
    }
    
    public async insertOrUpdateStatuses(statuses: IExpenseStatus[]): Promise<IExpenseStatus[]> {
        let savedStatuses = await this.expenseService.InsertOrUpdateExpenseStatuses(statuses);
        
        for (let status of savedStatuses) {
            let oldStatus = this.statuses.firstOrDefault(s => s.Id === status.Id);

            if (!oldStatus)
                this.statuses.push(status);
            else
                this.updateStatus(oldStatus, status);
        }
        return savedStatuses;
    }
    
    private updateStatus(oldStatus: IExpenseStatus, newStatus: IExpenseStatus) {
        oldStatus.Label = newStatus.Label;
        oldStatus.LogicalStatus = newStatus.LogicalStatus;
        oldStatus.Deleted = newStatus.Deleted;
    }

    private matchTextFilter(s: IExpenseStatus): boolean {
        let statusLabel = (s.Label || "");
        let words = statusLabel.split(" ");

        for (let word of words) {
            if (statusLabel.indexOf(word) < 0)
                return false;
        }

        return true;
    }

    private hasRights(status: IExpenseStatus): boolean {
        if (status.LogicalStatus === ExpenseLogicalStatus.Draft)
            return true;

        if (status.LogicalStatus === ExpenseLogicalStatus.Approved)
            return this.authorizationsService.isAuthorized("Expense_CanApproveExpenses");
        
        return this.authorizationsService.isAuthorized("Expense_CanRefundExpenses");
    }
}