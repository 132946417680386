import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IDDTAppearance, IDDTAppearances } from "../ProlifeSdk/interfaces/invoice/settings/IDDTAppearances";

export interface IAspectsDataSourceModel extends IDataSourceModel<number, IDDTAppearance> {

}

export class AspectsDataSource extends BaseDataSource<IAspectsDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.DDTAppearance)
    private ddtAppearances : IDDTAppearances;

    getTitle(currentModel: IAspectsDataSourceModel): string {
        return TextResources.Invoices.DeliveryLookAndFeel;
    }

    async getData(currentModel: IAspectsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IAspectsDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let appearances = this.ddtAppearances.getDDTAppearances();
        
        return appearances
            .filter(d => splitFilters.filter(w => d.Descrizione.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: IAspectsDataSourceModel, ids: (string | number)[]): Promise<IAspectsDataSourceModel[]> {
        return this.ddtAppearances.getDDTAppearances()
            .filter(p => ids.indexOf(p.Id) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IDDTAppearance): IAspectsDataSourceModel {
        return {
            id: model.Id,
            title: model.Descrizione,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}