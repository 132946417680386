import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IUsersService, IUserForList } from "../Users/UsersService";
import { IDataSourceModel } from "./IDataSource";
import { IGDPRService, ITreatment } from "../GDPR/GDPRService";

export interface ITreatmentModel extends ITreatment {
    StateLabel: string;
}

export interface ITreatmentsDataSourceModel extends IDataSourceModel<number, ITreatmentModel> {

}

export class TreatmentsDataSource extends BaseDataSource<ITreatmentsDataSourceModel> {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    private JobOrdersIds: number[] = [];
    private ResourcesIds: number[] = [];
    private JobOrderIdToExclude: number = null;

    getTitle(currentModel: ITreatmentsDataSourceModel): string {
        return "GDPR Data Treatments";
    }

    setJobOrderId(id){
        this.JobOrdersIds = [ id ];
    }

    setResourcesId(id){
        this.ResourcesIds = [ id ];
    }

    setJobOrderIdToExclude(id){
        this.JobOrderIdToExclude = id;
    }
    
    async getData(currentModel: ITreatmentsDataSourceModel, textFilter: string, skip: number , count: number): Promise<ITreatmentsDataSourceModel[]> {
        let allTreatments = await this.GDPRService.GetTreatments({textFilter: textFilter, skip: skip, count: count, jobOrdersIds: this.JobOrdersIds, resourcesIds: this.ResourcesIds, jobOrderIdToExclude: this.JobOrderIdToExclude });
        return allTreatments.map(this.createModelFor, this);
    }

    async getById(currentModel: ITreatmentsDataSourceModel, ids: number[]): Promise<ITreatmentsDataSourceModel[]> {
        let allTreatments = await this.GDPRService.GetTreatmentsByIds(ids);
        return allTreatments.map(this.createModelFor, this);
    }

    private createModelFor(treatment : ITreatment) : ITreatmentsDataSourceModel {
        return {
            id: treatment.Id,
            title: treatment.Title,
            isLeaf: true,
            isGroup: false,
            model: {...treatment, StateLabel: treatment.IsOpen ? TextResources.GDPR.Open : TextResources.GDPR.Closed}
        }
    }
}