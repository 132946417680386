import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 31/07/2017
 * Time: 09:48
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { PeopleViewModel } from "../PeopleViewModel";
import { PeopleMetadataViewModel } from "../PeopleMetadataViewModel";
import { EditPeopleDialog } from "./EditPeopleDialog";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IEditPeopleResult, IPeopleMetadata, IPeopleSample, IPeopleService, IPeople, IPeopleCallForHistory } from "../../../../../ProlifeSdk/interfaces/survey/IPeopleService";

export class PeopleDetailsDialog implements IDialog {
    public templateName: string = "";
    public templateUrl: string = "";
    public title: string = ProlifeSdk.TextResources.Survey.PeopleDetailsDialogTitle;
    public modal: { close: (result?: any) => void; };

    public PeopleDataView: ko.Observable<any> = ko.observable();
    public HistoryView: ko.Observable<any> = ko.observable();

    public ViewModel: ko.Observable<any> = ko.observable();

    public ShowPeopleData: ko.Observable<boolean> = ko.observable(false);
    public ShowHistory: ko.Observable<boolean> = ko.observable(false);

    public CanEdit: ko.Computed<boolean>;

    private dialogsService: IDialogsService;

    private editResult: IEditPeopleResult = null;

    constructor(private serviceLocator: IServiceLocator, private peopleId: number) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.PeopleDataView(new PeopleDataViewModel(this.serviceLocator, this.peopleId));
        this.HistoryView(new HistoryViewModel(this.serviceLocator, this.peopleId));

        this.SetPeopleView();

        this.CanEdit = ko.computed(() => {
            return true;
        });
    }

    public SetPeopleView(): void {
        this.ShowPeopleData(true);
        this.ShowHistory(false);
        this.ViewModel(this.PeopleDataView());
    }

    public SetHistoryView(): void {
        this.ShowPeopleData(false);
        this.ShowHistory(true);
        this.ViewModel(this.HistoryView());
    }

    public Edit(): void {
        var editPeopleDialog = new EditPeopleDialog(this.serviceLocator, this.peopleId);
        this.dialogsService.ShowModal<IEditPeopleResult>(editPeopleDialog, "fullscreen", null, "survey/templates/people/dialogs", "edit-people-dialog")
            .then((result: IEditPeopleResult) => {
                if (!result)
                    return;
                this.editResult = <IEditPeopleResult> { PeopleData: result.PeopleData, PeopleMetadata: result.PeopleMetadata };
                this.PeopleDataView().PeopleData(new PeopleViewModel(result.PeopleData));
                if (!result.PeopleMetadata) {
                    this.PeopleDataView().PeopleMetadata([]);
                    return;
                }
                this.PeopleDataView().PeopleMetadata(result.PeopleMetadata.map((metadata: IPeopleMetadata) => {
                    return new PeopleMetadataViewModel(metadata);
                }));
            });
    }

    action(): void {
        this.modal.close(this.editResult);
    }

    close(): void {
        this.modal.close(null);
    }
}

export class PeopleDataViewModel {
    public templateName: string = "people-view";
    public templateUrl: string = "survey/templates/people/dialogs";

    public PeopleData: ko.Observable<PeopleViewModel> = ko.observable();
    public PeopleMetadata: ko.ObservableArray<PeopleMetadataViewModel> = ko.observableArray([]);

    public Samples: ko.ObservableArray<IPeopleSample> = ko.observableArray([]);

    public EmptyList: ko.Computed<boolean>;

    private peopleService: IPeopleService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private peopleId: number) {
        this.peopleService = <IPeopleService> this.serviceLocator.findService(ProlifeSdk.PeopleServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.peopleService.getPeopleDetails(this.peopleId)
            .then((people: IPeople) => {
                this.PeopleData(new PeopleViewModel(people));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleDetailsError);
            });

        this.peopleService.getPeopleSamples(this.peopleId)
            .then((samples: IPeopleSample[]) => {
                this.Samples(samples);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleSamplesError);
            });

        this.peopleService.getPeopleMetadata(peopleId)
            .then((metadataList: IPeopleMetadata[]) => {
                this.PeopleMetadata(metadataList.map((metadata: IPeopleMetadata) => {
                    return new PeopleMetadataViewModel(metadata);
                }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleMetadataError);
            });

        this.EmptyList = ko.computed(() => {
            return this.PeopleMetadata().length == 0;
        });
    }
}

export class HistoryViewModel {
    public templateName: string = "people-history";
    public templateUrl: string = "survey/templates/people/dialogs";

    public History: ko.ObservableArray<IPeopleCallForHistory> = ko.observableArray([]);

    public EmptyList: ko.Computed<boolean>;

    private peopleService: IPeopleService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private peopleId: number) {
        this.peopleService = <IPeopleService>this.serviceLocator.findService(ProlifeSdk.PeopleServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.peopleService.getPeopleHistory(this.peopleId)
            .then((history: IPeopleCallForHistory[]) => {
                this.History(history);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleHistoryError);
            });

        this.EmptyList = ko.computed(() => {
            return this.History().length == 0;
        });
    }
}