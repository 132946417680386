import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow, useEffect } from "../Core/utils/ComponentUtils";
import jss from "jss"
import TsxForEach from "./ForEach";
import { If } from "./IfIfNotWith";

const styleSheet = jss.createStyleSheet({
    treeView: {
        display: 'block',

        "&.jstree-default .jstree-clicked": {
            backgroundColor: '#428bca',
            color: 'white'
        }
    }
});
const { classes } = styleSheet.attach();

type TreeViewProps = {
    className?: string;
    roots: ko.Subscribable<TreeNodeProps[]>;

    customNodeTemplate?: (node: TreeNode) => React.ReactElement;
}

type TreeNodeItemProps = {
    node: TreeNode;
    siblings: ko.ObservableArray<TreeNode>;

    customNodeTemplate?: (node: TreeNode) => React.ReactElement;
    onExpandedChange?: (node: TreeNode, expanded: boolean) => void;
}

export type TreeNodeProps = {
    onSelect: (node: TreeNode) => void;
    onChildrenNeeded?: () => Promise<TreeNodeProps[]>;
    selected?: boolean | ko.Computed<boolean> | ko.Observable<boolean> | (() => boolean);
    expanded?: boolean;
    children?: TreeNodeProps[];
    icon?: string;
    background?: string;
    foreground?: string;
    subTitle?: string;
    name: string | ko.Observable<string> | (() => string);
    dependencies?: ko.Subscribable<any>[];
}

export interface ITreeNode {
    HasChildren: ko.Computed<boolean>;
    IsLast: ko.Computed<boolean>;
    Selected: ko.Computed<boolean>;
    Expanded: ko.Observable<boolean>;
    
    Name: ko.Computed<string>;
    Icon : ko.Observable<string>;
    Background : ko.Observable<string>;
    Foreground : ko.Observable<string>;
    SubTitle : ko.Observable<string>;

    expand();
    select();
    deselectAll();
}

class TreeNode implements ITreeNode {
    constructor(private options: TreeNodeProps, siblings: ko.ObservableArray<TreeNode>) {
        this.HasChildren = ko.computed(() => this.Children().length > 0);
        this.IsLast = ko.computed(() => siblings()[siblings().length - 1] === this);

        options = Object.assign({
            selected: false,
            expanded: false,
            children: [],
            dependencies: []
        }, options);

        const selected = options.selected;
        this.Selected = ComponentUtils.getComputed(options.selected);
        this.Name = ComponentUtils.getComputed(options.name);
        this.Expanded(options.expanded);
        this.Children(options.children.map(c => new TreeNode(c, this.Children)));
        this.Icon(options.icon);
        this.Background(options.background);
        this.Foreground(options.foreground);
        this.SubTitle(options.subTitle);
        
        useEffect(async () => {
            this.HasLoadedChildren(!options.onChildrenNeeded);
            if(this.Expanded() && this.options.onChildrenNeeded) {
                await this.loadChildren();
            }
        }, options.dependencies, true);

        this.HasLoadedChildren(!options.onChildrenNeeded);
    }

    private async loadChildren() {
        this.HasLoadedChildren(true);
        const children = await this.options.onChildrenNeeded();
        this.Children(children.map(c => new TreeNode(c, this.Children)));
    }

    deselectAll() : void {
        for(const child of this.Children())
            child.deselectAll();
    }

    public async expand() {
        if(!this.Expanded() && !this.HasLoadedChildren() && this.options.onChildrenNeeded) {
            await this.loadChildren();
        }
        this.Expanded(!this.Expanded())
    }

    public select() {
        this.options.onSelect(this);
    }

    IsLast : ko.Computed<boolean>;
    Selected: ko.Computed<boolean>;
    Name : ko.Computed<string>;
    Expanded: ko.Observable<boolean> = ko.observable();
    Children : ko.ObservableArray<TreeNode> = ko.observableArray();
    HasChildren : ko.Computed<boolean>;
    HasLoadedChildren : ko.Observable<boolean> = ko.observable(false);
    Icon : ko.Observable<string> = ko.observable();
    Background : ko.Observable<string> = ko.observable();
    Foreground : ko.Observable<string> = ko.observable();
    SubTitle : ko.Observable<string> = ko.observable();
}

export function TreeView(props: TreeViewProps) {
    const C = require("./TreeView")._TreeView as typeof _TreeView;
    return <C {...props} />;
}

class TreeNodeItem extends React.Component<TreeNodeItemProps> {
    static defaultProps: Partial<TreeNodeItemProps> = {
    }

    private expandedSubscription: ko.Subscription;

    componentDidMount() {
        this.expandedSubscription = this.props.node.Expanded.subscribe((value) => { this.props.onExpandedChange && this.props.onExpandedChange(this.props.node, value); });
    }

    componentWillUnmount() {
        this.expandedSubscription.dispose();
        this.expandedSubscription = null;
    }

    render() {
        const node = this.props.node;
        
        return  ComponentUtils.bindTo(
                <li role="treeitem" className="jstree-node" data-bind={{ css: { 'jstree-open': node.Expanded() && node.HasChildren(), 'jstree-leaf': !node.HasChildren() && node.HasLoadedChildren(), 'jstree-closed': !node.Expanded() && node.HasChildren() || !node.HasLoadedChildren(), 'jstree-last': node.IsLast }}}>
                    {this.props.customNodeTemplate && this.props.customNodeTemplate(this.props.node)}
                    {!this.props.customNodeTemplate && this.defaultNodeRender(this.props.node)}
                    
                    <If condition={() => node.HasChildren() && node.Expanded()}>
                        {() =>  <ul className="jstree-container-ul">
                                    <TsxForEach data={node.Children} as="node">
                                        {(n) => <TreeNodeItem node={n} siblings={node.Children} customNodeTemplate={this.props.customNodeTemplate} />}
                                    </TsxForEach>
                                </ul>}
                    </If>
                </li>
        , node, "node") as React.ReactElement;
    }
    
    private defaultNodeRender(node: TreeNode) {
        return  <>
                    <i className="jstree-icon jstree-ocl" data-bind={{ click: node.expand.bind(node) }}></i>
                    <a className="jstree-anchor" href="#" data-bind={{ click: node.select.bind(node), css : { 'jstree-clicked': node.Selected }, scrollIntoViewOnlyIfTrue: node.Selected }}>
                        <i className="jstree-icon jstree-themeicon jstree-themeicon-custom" data-bind={{ class: node.Icon, style: { 'background-color': node.Background, 'color': node.Foreground } }}></i>
                        <If condition={node.SubTitle}>
                            {() => <><small className="text-muted" data-bind={{ text: node.SubTitle }}></small>&nbsp;-&nbsp;</>}
                        </If>
                        <ko-bind data-bind={{ text: node.Name }}></ko-bind>
                    </a>
                </>;
    }
}

type NodesExpandedStatus = { [node: string]: boolean };

export class _TreeView extends React.Component<TreeViewProps> {
    Children : ko.ObservableArray<TreeNode> = ko.observableArray();

    static defaultProps: Partial<TreeViewProps> = {
    }

    private nodesExpandedStatus: NodesExpandedStatus = {};

    constructor(props : TreeViewProps) {
        super(props);

        useEffect(() => {
            const nodes = props.roots().map(c => {
                let node = new TreeNode(c, this.Children);
                let savedExpandedStatus = this.nodesExpandedStatus[c.name];
                if (savedExpandedStatus !== null && savedExpandedStatus !== undefined)
                    node.Expanded(savedExpandedStatus);

                return node;
            });
            this.Children(nodes);
        }, [props.roots])
    }
    
    render() {
        const classNames = ComponentUtils.classNames("jstree jstree-1 jstree-default", classes.treeView, this.props.className);

        return  <div className={classNames} role="tree">
                    <ul className="jstree-container-ul">
                        <TsxForEach data={this.Children} as="node">
                            {(node) => <TreeNodeItem node={node} siblings={this.Children} onExpandedChange={this.onNodeExpandedChange.bind(this)} customNodeTemplate={this.props.customNodeTemplate} />}
                        </TsxForEach>
                    </ul>
                </div>
    }

    private onNodeExpandedChange(node: TreeNode, expanded: boolean): void {
        this.nodesExpandedStatus[node.Name()] = expanded;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(TreeView);
}