import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { DocumentLayout } from "./DocumentLayout";

export class StandardLayout extends DocumentLayout
{
    constructor()
    {
        super();
        this.LayoutId = 1;
        this.Description = ProlifeSdk.TextResources.ProlifeSdk.StandardDetailDescription;
        this.Title = ProlifeSdk.TextResources.ProlifeSdk.StandardDetailTitle;
        this.ShortTitle = ProlifeSdk.TextResources.ProlifeSdk.StandardDetailShortTitle;
    }
}