import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { WorkedHoursMonthlyReport } from "./WorkedHoursMonthlyReport";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IBusinessMovement } from "../../../../ProlifeSdk/interfaces/worked-hours/IBusinessMovement";

export class BusinessManagementsTransactionsMenu
{
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;

    public isExpanded : ko.Observable<boolean> = ko.observable(false);
    public isVisible : ko.Observable<boolean> = ko.observable(true);
    public Resources : ko.ObservableArray<BusinessManagementsTransactionsMenuResource> = ko.observableArray([]);
    
    constructor(private yearObservable : ko.Observable<number>, private monthObservable : ko.Observable<number>, private parent : WorkedHoursMonthlyReport)
    {
        
    }

    DeleteSelection()
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.WorkedHours.SureToDeleteBusinessManagegemntVariations, ProlifeSdk.TextResources.WorkedHours.DoNotDelete, ProlifeSdk.TextResources.WorkedHours.Delete, (confirm : boolean) => {
            if(!confirm)
                return;

            var movementsIds = [];
            this.Resources().forEach((r : BusinessManagementsTransactionsMenuResource) => {
                r.Transactions().forEach((t : BusinessManagementsTransactionsMenuTransaction) => {
                    if(t.IsSelected())
                        movementsIds.push(t.transaction.HoursId);
                });
            });

            this.workedHoursService.DeleteBusinessMovements(movementsIds).then(() => {
                this.parent.Refresh();
            });
        });
    }

    public switchExpandStatus() : void
    {
        this.isExpanded(!this.isExpanded());
    }

    public refresh()
    {
        this.Resources([]);

        if(this.yearObservable() == null || this.monthObservable() == null)
            return;

        this.workedHoursService.GetMonthlyBusinessMovements(this.yearObservable(), this.monthObservable()+1).then((movements : IBusinessMovement[]) => {
            var resourcesWithMovements = [];
            movements.forEach((m :IBusinessMovement) => {
                var exists : boolean = resourcesWithMovements.filter((r : any) => { return r.Id == m.ResourceId; }).length > 0;
                if(!exists)
                    resourcesWithMovements.push({ Id : m.ResourceId, Name : m.ResourceName });
            });


            var resourcesArray = [];
            resourcesWithMovements.forEach((hr : any) => {
                var resourceMatches = movements.filter((r : IBusinessMovement) => {
                    return r.ResourceId == hr.Id;
                });

                if(resourceMatches.length == 0)
                    return;

                resourcesArray.push(new BusinessManagementsTransactionsMenuResource(hr, resourceMatches));
            });
            this.Resources(resourcesArray);
        });
    }
}

export class BusinessManagementsTransactionsMenuResource
{
    Name : string;
    Transactions : ko.ObservableArray<BusinessManagementsTransactionsMenuTransaction> = ko.observableArray([]);

    constructor(resource : any, transactions : IBusinessMovement[])
    {
        this.Name = resource.Name;

        this.Transactions(transactions.map((transaction : IBusinessMovement) => {
            return new BusinessManagementsTransactionsMenuTransaction(transaction)
        }));
    }
}

export class BusinessManagementsTransactionsMenuTransaction
{
    IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public transaction : IBusinessMovement)
    {
    }

    SwitchSelection()
    {
        this.IsSelected(!this.IsSelected());
    }

}
