import * as ko from "knockout";
import { DocumentsGroup } from "./DocumentsGroup";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";

export class CustomerDocuments extends DocumentsGroup
{
    customer;

    constructor(customer : ICustomer)
    {
        super();
        this.customer = customer;
    }
}