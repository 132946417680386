import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IWarehouseInspection, IWarehouseInspectionsService, IGetWarehouseInspectionsRequest } from "../Warehouse/WarehouseInspectionsService";
import { LazyImport } from "../Core/DependencyInjection";
import { WarehouseInspectionStatus } from "../Warehouse/warehouse/ui/WarehouseInspections/Enums/WarehouseInspectionStatus";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface IWarehouseInspectionsDataSourceModel extends IDataSourceModel<number, WarehouseInspectionForList> {
    statusLabel: string;
    inspectionProgresTitle: string;
}

export class WarehouseInspectionsDataSource extends BaseDataSource<IWarehouseInspectionsDataSourceModel> {
    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;

    private statusFilter: WarehouseInspectionStatus[] = [];
    private getTakenOver: boolean = false;
    private getNotTakenOver: boolean = false;

    private statusLabelsDisctionary: { [status: number]: string } = {};
    private closedInspectionsStartDate: Date;
    private closedInspectionsEndDate: Date;

    constructor() {
        super();

        this.statusLabelsDisctionary[WarehouseInspectionStatus.Draft] = TextResources.Warehouse.WarehouseInspectionDarftStatus;
        this.statusLabelsDisctionary[WarehouseInspectionStatus.Workable] = TextResources.Warehouse.WarehouseInspectionWorkableStatus;
        this.statusLabelsDisctionary[WarehouseInspectionStatus.Closed] = TextResources.Warehouse.WarehouseInspectionClosedStatus;
    }

    public getTitle(currentModel: IWarehouseInspectionsDataSourceModel): string {
        return "";
    }
    
    public async getData(currentModel: IWarehouseInspectionsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IWarehouseInspectionsDataSourceModel[]> {
        let request: IGetWarehouseInspectionsRequest = {
            textFilter: textFilter,
            statusFilter: this.statusFilter,
            getTakenOver: this.getTakenOver,
            getNotTakenOver: this.getNotTakenOver,
            closedInspectionsFrom: this.closedInspectionsStartDate,
            closedInspectionsTo: this.closedInspectionsEndDate,
            skip: skip,
            count: count
        };
        let inspections = await this.warehouseInspectionsService.GetWarehouseInspections(request);
        return inspections.map(this.createInspectionDataSourceModel, this);
    }
    
    public async getById(currentModel: IWarehouseInspectionsDataSourceModel, ids: number[]): Promise<IWarehouseInspectionsDataSourceModel[]> {
        let inspections = await this.warehouseInspectionsService.GetWarehouseInspectionsByIds(ids);
        return inspections.map(this.createInspectionDataSourceModel, this);
    }

    public setStatusFilter(status: WarehouseInspectionStatus[]): void {
        this.statusFilter = status;
    }

    public setGetWorked(onlyTakenOver: boolean): void {
        this.getTakenOver = onlyTakenOver;
    }

    public setGetNotWorked(onlyNotTakenOver: boolean): void {
        this.getNotTakenOver = onlyNotTakenOver;
    }

    public setClosedInspectionsEndDate(endDate: Date): void {
        this.closedInspectionsEndDate = endDate;
    }
    
    public setClosedInspectionsStartDate(startDate: Date): void {
        this.closedInspectionsStartDate = startDate;
    }

    private createInspectionDataSourceModel(inspection: IWarehouseInspection): IWarehouseInspectionsDataSourceModel {
        return {
            id: inspection.Id,
            title: inspection.Title,
            isGroup: false,
            isLeaf: true,
            statusLabel: this.statusLabelsDisctionary[inspection.Status],
            inspectionProgresTitle: this.getInspectionProgressTitle(inspection),
            model: new WarehouseInspectionForList(inspection)
        }
    }
    
    private getInspectionProgressTitle(inspection: IWarehouseInspection): string {
        if (!inspection.WorkedBy)
            return TextResources.Warehouse.InspectionWaitingProgress;
            
        if (!!inspection.WorkedBy && !inspection.LastDocumentsGenerationError && !inspection.DocumentsGenerated)
            return String.format(TextResources.Warehouse.InspectionWorkingProgress, inspection.TotalNumberOfArticles - inspection.NumberOfArticles, inspection.TotalNumberOfArticles);
            
        if (!!inspection.WorkedBy && !!inspection.LastDocumentsGenerationError && !inspection.DocumentsGenerated)
            return TextResources.Warehouse.InspectionDocumentsGenerationErrorProgress;
            
        if (!!inspection.WorkedBy && inspection.DocumentsGenerated)
            return TextResources.Warehouse.InspectionDoneProgress;

        return "";
    }
}

export class WarehouseInspectionForList {
    get Id(): number {
        return this.inspection.Id;
    }

    get Title(): string {
        return this.inspection.Title;
    }
    
    get Status(): WarehouseInspectionStatus {
        return this.inspection.Status;
    }

    get NumberOfArticles(): number {
        return this.inspection.NumberOfArticles;
    }
    
    get TotalNumberOfArticles(): number {
        return this.inspection.TotalNumberOfArticles;
    }

    get SourceWarehouses(): string {
        return this.inspection.SourceWarehouses;
    }
    
    get NumberOfSourceWarehouses(): number {
        return this.inspection.NumberOfSourceWarehouses;
    }

    get DestinationWarehouses(): string {
        return this.inspection.DestinationWarehouses;
    }
    
    get NumberOfDestinationWarehouses(): number {
        return this.inspection.NumberOfDestinationWarehouses;
    }

    get CreationDate(): Date {
        return this.inspection.CreationDate;
    }

    get CreationUserId(): number {
        return this.inspection.CreationUserId;
    }

    get CreationUser(): string {
        return this.inspection.CreationUser;
    }

    get LastModificationDate(): Date {
        return this.inspection.LastModificationDate;
    }

    get LastModificationUserId(): number {
        return this.inspection.LastModificationUserId;
    }

    get LastModificationUser(): string {
        return this.inspection.LastModificationUser;
    }

    get WorkedBy(): number {
        return this.inspection.WorkedBy;
    }

    get WorkedByName(): string {
        return this.inspection.WorkedByName;
    }

    get WorkStartDate(): Date {
        return this.inspection.WorkStartDate;
    }

    get DocumentsGenerated(): boolean {
        return this.inspection.DocumentsGenerated;
    }

    get LastDocumentsGenerationError(): string {
        return this.inspection.LastDocumentsGenerationError;
    }

    get Order(): number {
        return this._order;
    }

    set Order(value: number) {
        this._order = value;
    }

    private _order: number;

    constructor(private inspection: IWarehouseInspection) {
        this._order = this.inspection.Order;
    }

    public isWorked(): boolean {
        return this.Status !== WarehouseInspectionStatus.Draft
            && !!this.WorkedBy;
    }
}