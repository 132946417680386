import { INewMailRecipient } from "../../../ProlifeSdk/interfaces/customer/INewCustomer";
import { ProtocolMailRecipient } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class ProtocolMailRecipientMapper {
    public static mapToProtocolMailRecipient(recipients: INewMailRecipient[]): ProtocolMailRecipient[] {
        return recipients.map((r) => ({
            protocolId: r.protocolId,
            valueType: r.valueType,
            value: r.value,
            valueDataType: r.valueDataType,
        }));
    }

    public static mapToOrganizationalUnitMailRecipient(
        recipients: ProtocolMailRecipient[],
        organizationalUnitId: number
    ): INewMailRecipient[] {
        return recipients.map((r) => ({
            organizationalUnitId: organizationalUnitId,
            protocolId: r.protocolId,
            valueType: r.valueType,
            value: r.value,
            valueDataType: r.valueDataType,
        }));
    }
}
