import * as ko from "knockout";
import "./invoices/settings/ui/CurrenciesEditor";
import { Export, LazyImport } from "../Core/DependencyInjection";
import { CurrenciesSettingsManager } from "./invoices/settings/CurrenciesSettingsManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { ICurrenciesService, ICurrencyWithCountriesIds } from "./interfaces/ICurrenciesService";
import { IService } from "../Core/interfaces/IService";

@Export(nameof<ICurrenciesService>())
export class CurrenciesService implements ICurrenciesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    
    constructor () { }

    public InitializeService() : void {
        new CurrenciesSettingsManager();
    }

    GetCurrenciesByIds(ids : number[] | null) : Promise<ICurrencyWithCountriesIds[]> {
        return this.ajaxService.Post<ICurrencyWithCountriesIds[]>("Documents-api/Currencies", "GetCurrenciesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });
    }

    GetCurrencies(textFilter : string | null, getDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ICurrencyWithCountriesIds[]> {
        return this.ajaxService.Post<ICurrencyWithCountriesIds[]>("Documents-api/Currencies", "GetCurrencies", {
            background: true,
            methodData: {
                textFilter: textFilter,
                getDeleted: getDeleted,
                skip: skip,
                count: count,
            }
        });
    }

    CreateOrUpdateCurrencies(currencies: ICurrencyWithCountriesIds[] | null): Promise<ICurrencyWithCountriesIds[]> {
        return this.ajaxService.Post<ICurrencyWithCountriesIds[]>("Documents-api/Currencies", "CreateOrUpdateCurrencies", {
            background: true,
            methodData: {
        		currencies: currencies,
        	}
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new CurrenciesService();
}
