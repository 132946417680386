import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ResourcesGroupsControlsEntityProvider } from "./ResourcesGroupsControlsEntityProvider";
import { IHumanResourcesService } from "../../HumanResourcesService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IGroupForHint, IResourcesGroup } from "../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class ResourcesGroupEntityProvider implements IEntityProvider<number, IGroupForHint|IResourcesGroup>
{
    @LazyImport(nameof<IHumanResourcesService>())
    private resourcesService : IHumanResourcesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService : IEntityProviderService;

    constructor()
    {
        this.entityProviderService.registerEntityProvider(this, this.getType());
    }

    getType() : string
    {
        return ProlifeSdk.ResourcesGroups;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Users.ResourcesGroup;
    }

    getPkValue(item : IGroupForHint) : number
    {
        return item.Id;
    }

    getDisplayName(item : IGroupForHint) : string
    {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.resourcesService.GetResourcesGroup(pkValue).then(
            (g : IResourcesGroup) => {
                def.resolve(g.Name);
            })
            .catch(() => { def.reject(); });
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IGroupForHint[]>
    {
        return this.ajaxService.Get("Users-api/ResourcesGroups", "HintSearch?filter=" + encodeURI(stringFilter), {
            background: true
        });
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new ResourcesGroupsControlsEntityProvider();
    }

    getEntity(pkValue : any) : Promise<IResourcesGroup>
    {
        return this.resourcesService.GetResourcesGroup(parseInt(pkValue));
    }
}