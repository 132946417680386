import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeTextResources";
import { CheckBox } from "../../../../Components/Checkbox";
import { MailRecipientTypeSelector } from "../../../../Components/MailRecipientTypeSelector";
import { MailTemplateSelector } from "../../../../Components/MailTemplateSelector";

const styleSheet = jss.createStyleSheet({
    editor: {
        "& .left-bar": {
            width: "12%",
        },
        "& .main": {
            width: "88%",
        },
    },
});
const { classes } = styleSheet.attach();

type DocumentMailEditorProps = {
    automaticMailSend: ko.Observable<boolean>;
    includeDocumentAttachmentsInMailSend: ko.Observable<boolean>;
    mailTemplateId: ko.Observable<number>;
    mailTo: ko.ObservableArray<string | number>;
    mailCc: ko.ObservableArray<string | number>;
    mailBcc: ko.ObservableArray<string | number>;

    allowFixedAddresses: ko.Observable<boolean>;
    readonly: ko.Observable<boolean> | ko.Computed<boolean>;

    className?: string;
};

export class DocumentMailEditorUI {
    constructor(private props: DocumentMailEditorProps) {}

    render() {
        return <DocumentMailEditor {...this.props} />;
    }
}

export function DocumentMailEditor(props: DocumentMailEditorProps) {
    const C = require("./DocumentMailEditor")._DocumentMailEditor as typeof _DocumentMailEditor;
    return <C {...props} />;
}

export class _DocumentMailEditor {
    static defaultProps: Partial<DocumentMailEditorProps> = {
        className: "",
    };

    constructor(private props: DocumentMailEditorProps) {}

    render() {
        const dme = this;

        return ComponentUtils.bindTo(
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="form-section">{TextResources.ProlifeSdk.MailSendSettings}</h3>
                    </div>
                </div>
                <div className="row"></div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label col-md-3" style={{ width: "24.49%" }}>
                                {TextResources.ProlifeSdk.MailTemplate}
                            </label>
                            <div className="col-md-9">
                                <MailTemplateSelector
                                    mailTemplateId={this.props.mailTemplateId}
                                    readonly={this.props.readonly}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label col-md-3">
                                {TextResources.ProlifeSdk.SendDocumentMail}
                            </label>
                            <div className="col-md-9">
                                <CheckBox
                                    checked={this.props.automaticMailSend}
                                    switch
                                    simple
                                    readOnly={this.props.readonly}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label col-md-3">
                                {TextResources.ProlifeSdk.IncludeDocumentAttachmentsInMailSend}
                            </label>
                            <div className="col-md-9">
                                <CheckBox
                                    checked={this.props.includeDocumentAttachmentsInMailSend}
                                    switch
                                    simple
                                    readOnly={this.props.readonly}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label col-md-3" style={{ width: "12%" }}>
                                {TextResources.ProlifeSdk.MailTo}
                            </label>
                            <div className="col-md-9" style={{ width: "87.49%" }}>
                                <MailRecipientTypeSelector
                                    recipientTypes={this.props.mailTo}
                                    readonly={this.props.readonly}
                                    multiple
                                    allowFixedAddresses={this.props.allowFixedAddresses}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label col-md-3">{TextResources.ProlifeSdk.MailCc}</label>
                            <div className="col-md-9">
                                <MailRecipientTypeSelector
                                    recipientTypes={this.props.mailCc}
                                    readonly={this.props.readonly}
                                    multiple
                                    allowFixedAddresses={this.props.allowFixedAddresses}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label col-md-3">{TextResources.ProlifeSdk.MailBcc}</label>
                            <div className="col-md-9">
                                <MailRecipientTypeSelector
                                    recipientTypes={this.props.mailBcc}
                                    readonly={this.props.readonly}
                                    multiple
                                    allowFixedAddresses={this.props.allowFixedAddresses}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>,
            this,
            "dme"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentMailEditor);
}
