import * as ko from "knockout";
import "../Core/utils/KnockoutExternalTemplateEngine";
import "../Core/utils/KoBindingProvider";
import "../Core/utils/StringUtilites";
import jss from "jss";
import preset from "jss-preset-default";

jss.setup(preset());

import * as moment from "moment";
import "moment/locale/it";
import * as numeral from "numeral";
import "numeral/locales";
import * as Core from "../Core/Core";
import { CoreConfig } from "../Core/Config";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import "../Invoices/invoices/validators/mustBePivaValidator";
import "../Invoices/invoices/validators/mustBeAbiValidator";
import "../Invoices/invoices/validators/mustBeIbanValidator";
import "../Invoices/invoices/validators/mustBeCfiscOrPiva";
import "../Core/utils/KoAsyncComponentTemplateLoader";
import "../Components/Select2Component";
import "../Components/TimeZonePickerComponent";
import "../Components/TimePickerComponent";
import "../Components/DurationComponent";
import "../Components/TypeaheadComponent";
import "../Components/TreeComponent";
import "../Components/ListComponent";
import "../Components/ExcelExporterComponent/ExcelExporterComponent";
import "../Components/AllocationMenuDetailsComponent";
import "../Components/CheckboxComponent";
import "../Components/WorkflowRelatedDocumentsEditorComponent";
import "../Components/PortletComponent";
import "../Components/WorkflowRelatedDocumentsComponent/RelatedDocumentsPerTypeEditorComponent";
import "../Components/NavTabComponent";
import "../Components/TextInputComponent";
import "../Components/NumericInputComponent";
import "../Components/MoneyInputComponent";
import "../Components/PercentageInputComponent";
import "../Components/TimelineComponent";
import "../Components/CurrencyInputComponent";
import "../Components/CurrencyInputPopoverComponent";
import "../Components/TableComponent/TableComponent";
import "../../node_modules/enjoyhint/enjoyhint.css";
import "../../node_modules/enjoyhint/jquery.enjoyhint.js";
import "./css/FlexBox.scss";
import loginManager from "./login/LoginManager";

declare let loadingSettings: string;
declare let dialogClose: string;
declare let dialogSave: string;
declare let defaultLoading: string;
declare let currentCulture: string;
declare let simpleCurrentCulture: string;
declare let Version: string;

moment.locale(currentCulture);
numeral.locale(simpleCurrentCulture);

Date.prototype.toJSON = function () {
    return moment(this).format();
};

infuser.defaults.useLoadingTemplate = true;
infuser.defaults.loadingTemplate = {
    content:
        '<div style="margin: 0 auto;" class="loading-message loading-message-boxed"><img src="/Content/metronic/assets/global/img/loading-spinner-grey.gif" align=""><span>&nbsp;&nbsp;' +
        defaultLoading +
        "</span></div>",
    transitionIn: function (target, content) {
        const tgt = $(target);
        tgt.hide();
        tgt.html(content);
        tgt.fadeIn();
    },
    transitionOut: function (target) {
        $(target).html("");
    },
};
infuser.defaults.templateUrl = "";
infuser.defaults.templateSuffix = "?bust=" + Version;

const config = new CoreConfig();
config.ajaxConfig.defaultLoading = defaultLoading;
config.dialogsConfig.defaultClose = dialogClose;
config.dialogsConfig.defaultSave = dialogSave;

import type { IServiceFactory } from "../Core/interfaces/IConfig";
import type { IDialogsService } from "../Core/interfaces/IDialogsService";
import type { IService } from "../Core/interfaces/IService";
import type { IOPAService } from "../Core/interfaces/IOPAService";
import type { IModulesService } from "../ProlifeSdk/interfaces/desktop/IModulesService";
import type { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import type { ILocalizationService } from "../Core/interfaces/ILocalizationService";
import type { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IAuthenticationService } from "../Core/interfaces/IAuthenticationService";

(async function InitializeCore() {
    console.log("Core initializing");
    await Core.Initialize(config);
    console.log("Core initialized");

    const ProlifeSdk = require("../ProlifeSdk/ProlifeSdk");
    require("../ProlifeSdk/interfaces/KnockoutExtensions");
    require("../ProlifeSdk/prolifesdk/utils/NumberExtensions");
    require("../ProlifeSdk/prolifesdk/utils/ArrayUtils");
    const { ModulesService } = require("./ModulesService");
    require("../bindings");

    await loginManager.handleLoginIfNeeded();

    const services = require("../services");
    const { Main } = require("./main");

    console.log("Loading services");
    const dialogsService = <IDialogsService>Core.serviceLocator.findService(ServiceTypes.Dialogs);
    dialogsService.LockUI(loadingSettings);

    console.log("Initializing OPA");
    const opaService = <IOPAService>Core.serviceLocator.findService(ServiceTypes.OPA);
    opaService.Initialize(function () {
        this.notFound = () => {};
        this._checkFormSubmission = function () {}; // Disabilita la gestione dei form di Sammy
    });

    //Carico le info sui moduli abilitati
    const modulesService: IModulesService = new ModulesService(Core.serviceLocator);
    const loadedServices: IService[] = [];

    console.log("Refreshing modules");
    await modulesService.Refresh();

    for (const serviceName in services) {
        console.log("Loading service " + serviceName);
        const service: IServiceFactory = services[serviceName];
        const serviceOrServicesArray = service(Core.serviceLocator);
        if (Array.isArray(serviceOrServicesArray)) loadedServices.push(...serviceOrServicesArray);
        else loadedServices.push(serviceOrServicesArray);
    }

    console.log("Loading translations");
    const localizationService = Core.serviceLocator.findService<ILocalizationService>(nameof<ILocalizationService>());
    const sdkService = Core.serviceLocator.findService<IProLifeSdkService>(nameof<IProLifeSdkService>());
    const labels = await localizationService.getClientLabelsTranslations();
    sdkService.LoadTextTranslations(labels);

    const settingsLoadedObserver: ISettingsServiceObserver = {
        onSettingsLoaded: () => {
            dialogsService.UnlockUI();

            ko.applyBindings(new Main(Core.serviceLocator));
        },
        onSettingsUpdated: (updateType: string) => {},
    };

    console.log("Loading settings");
    const settingsService = <ISettingsService>Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
    settingsService.addObserver(settingsLoadedObserver);

    loadedServices.forEach((s) => {
        console.log("Initializing service " + s);
        if (s.InitializeService) s.InitializeService();
    });
})();
