import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/07/2017
 * Time: 16:47
 * To change this template use File | Settings | File Templates.
 */

import { PeopleForList } from "./People";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IPeopleService, IPeopleForList, IEditPeopleResult } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";

export class PeopleForListViewModel extends PeopleForList implements ILockableItem {
    public ItemKey : number;
    public IsItemLocked : ko.Observable<boolean> = ko.observable(false);

    public Identifier: ko.Computed<string>;
    public CompleteAddress: ko.Computed<string>;

    public ShowCf: ko.Computed<boolean>;
    public ShowPIVA: ko.Computed<boolean>;
    
    public Deleted: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IPeopleService>())
    private peoplesService: IPeopleService;

    constructor(people: IPeopleForList) {
        super(people);

        this.ItemKey = people.Id;
        this.Deleted(people.DeletedFromSample);

        this.ShowPIVA = ko.computed(() => {
            return !!this.PIVA();
        });

        this.ShowCf = ko.computed(() => {
            return !!this.FiscalCode();
        });

        this.Identifier = ko.computed(() => {
            return !this.BusinessName() ? this.Name() + ' ' + this.Surname() : this.BusinessName();
        });

        this.CompleteAddress = ko.computed(() => {
            return this.Address() + ' - ' + this.CAP() + ' ' + this.City() + ' (' + this.Province() + ') - ' + this.Country();
        });
    }

    public ShowPeopleDetails(): void {
        this.peoplesService.showPeopleDialog(this.Id)
            .then((result: IEditPeopleResult) => {
                if (!result)
                    return;
                this.updateData(result);
            });
    }

    private updateData(data: IEditPeopleResult) {
        this.BusinessName(data.PeopleData.BusinessName);
        this.Name(data.PeopleData.Name);
        this.Surname(data.PeopleData.Surname);
        this.Address(data.PeopleData.Address);
        this.CAP(data.PeopleData.CAP);
        this.City(data.PeopleData.City);
        this.Province(data.PeopleData.Province);
        this.Country(data.PeopleData.Country);
        this.FiscalCode(data.PeopleData.FiscalCode);
        this.PIVA(data.PeopleData.PIVA);
    }
}