import { IDataSourceModel } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { ITicketOrigin, ITicketsService } from "../Tickets/TicketsService";
import { ITicketOriginsSettingsManager } from "../Tickets/settings/TicketOriginsSettingsManager";

export interface ITicketOriginsDataSourceModel extends IDataSourceModel<number, ITicketOrigin> {

}

export class TicketOriginsDataSource extends BaseDataSource<ITicketOriginsDataSourceModel> {
    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    @LazyImportSettingManager(nameof<ITicketOriginsSettingsManager>())
    private TicketOriginsSettingsManager: ITicketOriginsSettingsManager;

    private showDeleted: boolean;

    setShowDeleted(showDeleted: boolean){
        this.showDeleted = showDeleted;
    }

    getTitle(currentModel: ITicketOriginsDataSourceModel): string {
        return "Ticket Origins";
    }

    async getData(currentModel: ITicketOriginsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITicketOriginsDataSourceModel[]> {
        let types = await this.TicketOriginsSettingsManager.getTicketOrigins(textFilter, this.showDeleted, skip, count);
        return types.map(this.createModelFor, this);
    }

    async getById(currentModel: ITicketOriginsDataSourceModel, ids: number[]): Promise<ITicketOriginsDataSourceModel[]> {
        let types = await this.TicketOriginsSettingsManager.getTicketOriginsByIds(ids);
        return types.map(this.createModelFor, this);
    }

    private createModelFor(type: ITicketOrigin): ITicketOriginsDataSourceModel {
        return {
            id: type.Id,
            title: type.Title,
            isLeaf: true,
            isGroup: false,
            model: type
        };
    }
}