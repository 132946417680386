import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { ContactList } from "./SearchService/ContactList";
import { CallBlogEvent } from "../EventViewModels/CallBlogEvent";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";
import { ITag } from "../../../ProlifeSdk/interfaces/ITag";

export class PhoneCallInput extends BlogEventBaseInput {

	public templateName : string  = "call-details";
	public title : string = ProlifeSdk.TextResources.Blog.PhoneCallTitle;
    durata: ko.Observable<number> = ko.observable(0);
	partecipanti: ContactList;

	constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver,
                event : CallBlogEvent)
    {
		super(serviceLocator, contextEventsObserver);
        this.iconName = "f-icon-phone-1";
		this.partecipanti = new ContactList(ProlifeSdk.TagPhone_Participant);

        this.setValues(event);
	}

    public setValues(item : CallBlogEvent) : void
    {
        super.setValues(item);
        this.durata(item.Duration());
		this.partecipanti.clear();
		this.partecipanti.addElements(item.Participants());
    }

    getBlogEvent(): ILogEvent {

        var localBlogEvent : ILogEvent = super.getBlogEvent();

        localBlogEvent.Tags.push( {TagName : ProlifeSdk.TagPhone_Duration,
            Value : this.durata(),
            TagTypeId : ProlifeSdk.TagType_Int});

		this.partecipanti.getElement().forEach((tag : ITag) => {
            localBlogEvent.Tags.push(tag);
		});
        localBlogEvent.EventType = "Blog.PhoneCall";
        return localBlogEvent;

    }

	public getValidationMessage() : string[] {
		var message : string[] = super.getValidationMessage();

		if (this.subject() == null || this.subject().trim() == "")
			message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

		return message;
	}
}
