import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { WorkflowsUtils } from "../../../../Todolist/Todolist/ui/utils/WorkflowsUtils";
import {
    IJobOrderWorkflowsDetails,
    IJobOrderService,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { WorkflowStatus } from "./WorkflowStatusEnum";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { INavigationMenuComponentBadge } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { WorkflowEditorButton } from "../WorkflowEditorButton";
import { WorkflowTasksEditorButton } from "../WorkflowTasksEditorButton";

interface IJobOrderWorkflowsDetailsDataSourceModel extends IDataSourceModel<number, IJobOrderWorkflowsDetails> {
    badge?: INavigationMenuComponentBadge;
}

export interface ISplashPageWorkflowsDetailsParams {
    jobOrderId: number;
    workflowStatus?: WorkflowStatus;
    customerOrderId?: number;
    scrollable?: boolean;
    loadTrigger?: ko.Subscribable<boolean>;
}

export class SplashPageWorkflowsDetails {
    JobOrderId: number;
    WorkflowStatus: WorkflowStatus;
    CustomerOrderId: number;
    Scrollable: boolean;

    Workflows: ko.ObservableArray<IJobOrderWorkflowsDetails> = ko.observableArray([]);
    Loading: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private loadTriggerSubscription: ko.Subscription;

    constructor(private props: ISplashPageWorkflowsDetailsParams) {
        this.JobOrderId = this.props.jobOrderId ?? -1;
        this.WorkflowStatus = this.props.workflowStatus;
        this.CustomerOrderId = this.props.customerOrderId;
        this.Scrollable = this.props.scrollable ?? true;

        if (this.props.loadTrigger)
            this.loadTriggerSubscription = this.props.loadTrigger.subscribe((value: boolean) => {
                if (!value) {
                    this.Workflows([]);
                    return;
                }

                this.load();
            });
    }

    componentDidMount() {
        console.log("Workflows ComponentDidMount");

        if (!this.props.loadTrigger) this.load();
    }

    componentWillUnmount() {
        if (this.loadTriggerSubscription) this.loadTriggerSubscription.dispose();
    }

    render() {
        let workflow: IJobOrderWorkflowsDetailsDataSourceModel;

        const { sortString, sortNumber } = ComponentUtils.useSorter<IJobOrderWorkflowsDetails>();

        return ComponentUtils.bindTo(
            <Table
                compact={true}
                bordered={true}
                scrollable={this.Scrollable}
                showLoadingIndicator={this.Loading}
                dataSource={{ array: this.Workflows, factory: this.createWorkflowModel.bind(this) }}
                rowAs="workflow"
                fixedLayout
            >
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsWorkflow}
                    sorter={sortString((w) => w.Title)}
                    style={{ width: "30%" }}
                >
                    <ColumnHeader>{() => <span>{TextResources.JobOrder.WorkflowsDetailsWorkflow}</span>}</ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<IJobOrderWorkflowsDetails>) => (
                            <div className="flex-container" style={{ alignItems: "center" }}>
                                <div>
                                    <WorkflowEditorButton workflowId={item.Data.model.WorkflowId} extraSmall />
                                </div>
                                <div>
                                    <WorkflowTasksEditorButton
                                        jobOrderId={item.Data.model.JobOrderId}
                                        workflowId={item.Data.model.WorkflowId}
                                        extraSmall
                                    />
                                </div>
                                <i
                                    class={classes.workflowCategoryIcon}
                                    data-bind={{
                                        css: workflow.model.Icon,
                                        style: {
                                            "background-color": workflow.model.Background,
                                            color: workflow.model.Foreground,
                                        },
                                    }}
                                ></i>
                                <span
                                    className="flex-1 text-ellipsis"
                                    data-bind={{ text: workflow.model.Title, attr: { title: workflow.model.Title } }}
                                ></span>
                                <i
                                    class={classes.workflowOutcomeIcon}
                                    data-bind={{
                                        css: workflow.model.OutcomeIcon,
                                        style: {
                                            "background-color": workflow.model.OutcomeBackground,
                                            color: workflow.model.OutcomeForeground,
                                        },
                                    }}
                                ></i>
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsStatus}
                    className="text-center"
                    sorter={sortString((w) => w.Status)}
                >
                    <span data-bind={{ text: workflow.model.Status }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsWorked}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsWorkedTitle}
                    className="text-center"
                    sorter={sortNumber((w) => w.WorkedHours)}
                >
                    <span data-bind={{ numberText: workflow.model.WorkedHours }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsResidual}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsResidualTitle}
                    className="text-center"
                    sorter={sortNumber((w) => w.RemainingWork)}
                >
                    <span data-bind={{ numberText: workflow.model.RemainingWork }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsSpeed}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsSpeedTitle}
                    className="text-center"
                    sorter={sortNumber((w) => w.Speed)}
                >
                    <span data-bind={{ numberText: workflow.model.Speed }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsReestimatedResidual}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsReestimatedResidualTitle}
                    className="text-center"
                    sorter={sortNumber((w) => w.ReestimatedRemainingWork)}
                >
                    <span data-bind={{ numberText: workflow.model.ReestimatedRemainingWork }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsProgress}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsProgressTitle}
                    className="text-center"
                    sorter={sortNumber((w) => w.ClosedTasks / (w.TotalTasks > 0 ? w.TotalTasks : 1))}
                >
                    <span>
                        <span data-bind={{ text: workflow.model.ClosedTasks }}></span>/
                        <span data-bind={{ text: workflow.model.TotalTasks }}></span>
                    </span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsBudgetFromOrders}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsBudgetFromOrdersTitle}
                    style={{ width: "165px" }}
                    className="text-right"
                    sorter={sortNumber((w) => w.OrderValue)}
                >
                    <span data-bind={{ moneyText: workflow.model.OrderValue }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsBudgetFromBudgetRequests}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsBudgetFromBudgetRequestsTitle}
                    style={{ width: "165px" }}
                    className="text-right"
                    sorter={sortNumber((w) => w.TotalBudget)}
                >
                    <span>
                        <span data-bind={{ numberText: workflow.model.InternalBudget }}></span>&nbsp;/&nbsp;
                        <span data-bind={{ moneyText: workflow.model.TotalBudget }}></span>
                    </span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsEstimatedWorkflowCost}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsEstimatedWorkflowCostTitle}
                    className="text-right"
                    sorter={sortNumber((w) => w.TotalEstimatedCost)}
                >
                    <span data-bind={{ moneyText: workflow.model.TotalEstimatedCost }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.WorkflowsDetailsPlanning}
                    tooltip={TextResources.JobOrder.WorkflowsDetailsPlanningTitle}
                    className="text-right"
                    sorter={sortNumber((w) => w.AllocationPercentage)}
                >
                    <span
                        class="badge"
                        data-bind={{
                            text: workflow.badge.text,
                            css: workflow.badge.cssClass,
                            asyncClick: workflow.badge.action,
                        }}
                    ></span>
                </Column>
            </Table>,
            this
        );
    }

    private async load(): Promise<void> {
        this.Loading(true);

        const details = await this.jobOrderService.GetOrdersWorkflowsDetails(
            this.JobOrderId,
            this.WorkflowStatus,
            this.CustomerOrderId
        );
        this.Workflows(details);

        this.Loading(false);
    }

    private createWorkflowModel(workflow: IJobOrderWorkflowsDetails): IJobOrderWorkflowsDetailsDataSourceModel {
        return {
            id: workflow.WorkflowId,
            title: workflow.Title,
            isGroup: false,
            isLeaf: true,
            model: workflow,
            badge: {
                text: numeral(workflow.AllocationPercentage / 100).format("0[.]0[0]%"),
                cssClass: WorkflowsUtils.getAllocationBadgeClass(workflow.HasNotEstimatedElementsAllocated),
                action: WorkflowsUtils.getAllocationBadgeAction(
                    workflow.WorkflowId,
                    this.JobOrderId,
                    this.dialogsService
                ),
            },
        };
    }
}

const styles = {
    workflowCategoryIcon: {
        width: "20px",
        height: "20px",
        display: "inline-block",
        lineHeight: "20px",
        textAlign: "center",
        marginRight: "5px",
    },
    workflowOutcomeIcon: {
        width: "20px",
        height: "20px",
        display: "inline-block",
        lineHeight: "20px",
        textAlign: "center",
        marginLeft: "5px",
        float: "right",
    },
};

const { classes } = jss.createStyleSheet(styles).attach();
