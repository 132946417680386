import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 13/02/2018
 * Time: 10:17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IFoldersProvider, IFolderService, IFolderEnvelope } from "../../../../ProlifeSdk/interfaces/agenda/IFolderService";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

export class FoldersProvider implements IFoldersProvider {
    private infoToastService: IInfoToastService;
    private foldersService: IFolderService;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(private serviceLocator: IServiceLocator) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.foldersService = <IFolderService> this.serviceLocator.findService(ProlifeSdk.FolderServiceCode);
    }

    GetFolders(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.foldersService.GetFoldersEnvelopesLimitedList(query.term)
                .then((folders: IFolderEnvelope[]) => {
                    query.callback({
                        results: folders.map((f: IFolderEnvelope) => { return { id: f.Id, text: f.Name } })
                    });
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFoldersError);
                });
        }, 500);
    }

    GetFolder(element: HTMLElement, callback: (result :{ id: number; text: string; }) => void) {
        var folderId: number = parseInt(<string>$(element).val());
        if (folderId != null && !isNaN(folderId)) {
            this.foldersService.GetFolderEnvelope(folderId)
                .then((folder: IFolderEnvelope) => {
                    callback({ id: folder.Id, text: folder.Name });
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFoldersError);
                });
        }
    }
}