import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { Tag } from "../../../ProlifeSdk/prolifesdk/blog/Tag";
import { PhoneCallInput } from "../EventsDetails/PhoneCallInput";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../Core/Deferred";

export class CallBlogEvent extends BlogEvent implements IView
{
    public viewModel : any;

    /**** CALL **/
    public Duration : ko.Observable<number> = ko.observable();
    public Participants : ko.ObservableArray<Tag> = ko.observableArray();

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconClass = "fa-phone";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.CallTitle;
        this.templateName = "callEvent";
        this.Clear();
    }

    public GetMailSubject() : string
    {
        return String.format(ProlifeSdk.TextResources.Blog.CallSubject, moment(this.ReferenceDate()).format("L"));
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            body += String.format(ProlifeSdk.TextResources.Blog.CallTime, numeral(this.Duration()).format("0.00"));
            body += this.CreateMailBodyPartForList(this.Participants(), ProlifeSdk.TextResources.Blog.Participants);
            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    Clear()
    {
        super.Clear();
        this.Duration(0);
        this.Participants([]);
    }

    getEditViewModel() : IEventBaseInput
    {
        return new PhoneCallInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        // Call
        this.Participants([]);
        this.Duration(0);

        this.Tags().forEach((item) => {
            if(item.TagName() == ProlifeSdk.TagPhone_Participant){
                this.Participants.push(item);
            }
            if(item.TagName() == ProlifeSdk.TagPhone_Duration){
                this.Duration(parseInt(item.Value()));
            }

        });
    }
}