import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ISettingsManager } from "../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { LazyImport } from "../../Core/DependencyInjection";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { Right } from "../../Core/Authorizations";
import { ITicketClosingType, ITicketsService } from "../TicketsService";
import { TicketClosingTypesSettingsComponent } from "./ui/TicketClosingTypesSettingsComponent";

export interface ITicketClosingTypesSettingsManager extends ISettingsManager {
    getTicketClosingTypes(textFilter: string, showDeleted?: boolean, skip?: number, count?: number);
    getTicketClosingTypesByIds(ids: number[]);
    insertOrUpdateTicketClosingTypes(types: ITicketClosingType[]);
}

export class TicketClosingTypesSettingsManager implements ITicketClosingTypesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    private ticketClosingTypes: ITicketClosingType[] = [];

    //@Right("Tickets_CanWriteTicketClosingTypes")
    private canWriteTicketClosingTypes: boolean = true;

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Tickets.Ticket);
    }

    public getTicketClosingTypes(textFilter: string, showDeleted?: boolean, skip: number = 0, count: number = 1000) {
        return this.ticketClosingTypes.filter(x => this.matchTextFilter(x, textFilter) && (showDeleted || x.Deleted === false)).slice(skip, skip + count);
    }

    public getTicketClosingTypesByIds(ids: number[]) {
        return this.ticketClosingTypes.filter(x => ids.any(i => i == x.Id));
    }

    public async insertOrUpdateTicketClosingTypes(items: ITicketClosingType[]){
        await this.ticketsService.InsertOrUpdateClosingTypes(items);
        await this.load();
    }

    async load() {
        this.ticketClosingTypes = await this.ticketsService.GetClosingTypes(null, true, 0, null);
    }

    getName() {
        return nameof<ITicketClosingTypesSettingsManager>();
    }

    getLabel() {
        return TextResources.Tickets.ClosingTypes;
    }

    hasEditingUI() {
        return this.canWriteTicketClosingTypes;
    }

    getEditingUI() {
        return new TicketClosingTypesSettingsComponent({TicketClosingTypesSettingsManager: this});
    }

    private matchTextFilter(item: ITicketClosingType, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (item.Name.toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}