import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";

export class DiscountValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        let explicitValue = ko.utils.unwrapObservable(allBindingsAccessor().discountExplicit);
        const showNegativeValues: boolean = allBindingsAccessor().showNegativeValues;

        if(explicitValue == null || explicitValue == undefined)
            explicitValue = ProlifeSdk.TextResources.Desktop.ExplicitPrice;

        const interceptor = ko.computed<string>({
            read: function() {
                if(typeof underlyingObservable() == "undefined" || underlyingObservable() === null) return explicitValue;

                let discounts = (underlyingObservable() || "0%")
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/\./g, ",");

                if (!showNegativeValues)
                    discounts = discounts.replace(/-/g, " ");

                return discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0.[########]%"))
                    .join(" ");
            },
            write: function(newValue) {
                if(newValue == explicitValue) {
                    underlyingObservable(undefined);
                    underlyingObservable.valueHasMutated();
                    return;
                }

                if(!newValue)
                    newValue = "0%";

                let discounts = newValue
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/\./g, ",");

                if (!showNegativeValues)
                    discounts = discounts.replace(/-/g, " ");

                const valueToWrite = discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0.[########]%"))
                    .join(" ");

                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

export class DiscountText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let underlyingObservable = valueAccessor();

        if(!ko.isObservable(underlyingObservable))
            underlyingObservable = ko.observable(underlyingObservable);

        const showNegativeValues: boolean = allBindingsAccessor().showNegativeValues;

        let explicitValue = ko.utils.unwrapObservable(allBindingsAccessor().discountExplicit);
        if(explicitValue == null || explicitValue == undefined)
            explicitValue = ProlifeSdk.TextResources.Desktop.ExplicitPrice;

        const interceptor = ko.computed({
            read: function() {
                if(typeof underlyingObservable() == "undefined") return explicitValue;

                let discounts = (underlyingObservable() || "0%")
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/\./g, ",");

                if (!showNegativeValues)
                    discounts = discounts.replace(/-/g, " ");

                return discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0.[########]%"))
                    .join(" ");
            },
            write: function(newValue) {}
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["discountValue"] = new DiscountValue();
ko.bindingHandlers["discountText"] = new DiscountText();