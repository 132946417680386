import * as ko from "knockout";
import { Article } from "../Articles/Article";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IStockService, IStockArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";

export class StockArticle extends Article
{
    @LazyImport(ProlifeSdk.StockServiceType)
    private stockService : IStockService;
    
    StockAmount : ko.Observable<number>;
    TotalStockAmount: ko.Observable<number>;
    StockModified: ko.Observable<boolean> = ko.observable(false);
    MinimumAmount : ko.Observable<number>;
    Orders : number;
    Reservations : number;
    TotalReservations: number;
    StockStatus : ko.Computed<number>;
    Free: ko.Computed<number>;
    Cost: ko.Observable<number> = ko.observable();

    constructor(private stockArticle? : IStockArticle)
    {
        super(stockArticle);
        
        this.Orders = stockArticle ? stockArticle.Orders : 0;
        this.Reservations = stockArticle ? stockArticle.Reservations : 0;
        this.TotalReservations = stockArticle ? stockArticle.TotalReservations : 0;
        this.Free = ko.computed(() => this.StockAmount() - this.Reservations);
        this.Cost(stockArticle?.Cost ?? 0);

        this.StockStatus = ko.computed(() => {
            return this.stockService.CalculateArticleStatus(this.StockAmount(), this.Reservations, this.Orders, this.MinimumAmount());
        });
    }

    dispose()
    {
    }

    public load(stockArticle? : IStockArticle)
    {
        super.load(stockArticle);

        if(!this.StockAmount){
            this.StockAmount = ko.observable();
            this.TotalStockAmount = ko.observable();
            this.MinimumAmount = ko.observable();
        }

        if(!stockArticle) return;

        this.StockAmount(stockArticle.StockAmount);
        this.TotalStockAmount(stockArticle.TotalStockAmount);
        this.MinimumAmount(stockArticle.MinimumAmount);
    }

    public getData() : IStockArticle
    {
        var stockArticle = <IStockArticle> super.getData();

        stockArticle.StockAmount = this.StockAmount();
        stockArticle.MinimumAmount = this.MinimumAmount();

        return stockArticle;
    }

    public isChanged() : boolean
    {
        return !(<any>Object).identical(this.stockArticle, this.getData());
    }
}