import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 09/05/2018
 * Time: 12:25
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { ICustomerTrust } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";

export class TrustManagerErrorsDialog implements IDialog {
    public templateName: string = "trusts-validation-errors";
    public templateUrl: string = "customers/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Customers.TrustsValidationErrorsTitle;

    public Errors: ko.ObservableArray<ICustomerTrust> = ko.observableArray([]);
    public HasIntersectionsErrors: ko.Observable<boolean> = ko.observable();

    modal: { close: (results?: any) => void; };

    constructor(private errors: ICustomerTrust[]) {
        this.Errors(this.errors);
        this.HasIntersectionsErrors(this.errors.length > 0);
    }

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        this.modal.close(false);
    }
}