import * as React from "@abstraqt-dev/jsxknockout"
import { ISettingsManager } from "../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../ProlifeSdk/interfaces/IView";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { GDPREventTypesSettingsComponent } from "./ui/GDPREventTypesSettingsComponent";
import { IGDPREventType, IGDPRService, IGetEventTypesRequest, ITYP_EventType } from "../GDPRService";
import { Right } from "../../Core/Authorizations";

export interface IGDPRLogicEventType {
    Id: number;
    Name: string;
}

export const GDPRLogicEventTypes: IGDPRLogicEventType[] = [{
    Id: 1,
    Name: TextResources.GDPR.LogicEventTypeOpen
},
{
    Id: 2,
    Name: TextResources.GDPR.LogicEventTypeClose
},
{
    Id: 3,
    Name: TextResources.GDPR.LogicEventTypeRemoveResources
},
{
    Id: 4,
    Name: TextResources.GDPR.LogicEventTypeDescriptive
}];

export interface IGDPREventTypesSettingsManager extends ISettingsManager {
    getEventTypes(textFilter: string, showDeleted?: boolean, skip?: number, count?: number): IGDPREventType[];
    getEventTypesByIds(ids: number[]): IGDPREventType[];
    insertOrUpdateEventTypes(eventTypes: ITYP_EventType[]);
    checkIfSystemEvent(id: number): boolean;
}

export class GDPREventTypesSettingsManager implements IGDPREventTypesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    private eventTypes: IGDPREventType[] = [];

    @Right("GDPR_CanWriteEventTypes")
    private canWriteEventTypes: boolean;
    
    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.GDPR.DataManagement);
    }

    public getEventTypes(textFilter: string = null, showDeleted: boolean = false, skip: number = 0, count: number = 1000): IGDPREventType[] {
        return this.eventTypes.filter(x => this.matchTextFilter(x, textFilter) && (showDeleted || x.Deleted === false) && x.IsSystemType === false).slice(skip, skip + count);
    }

    public getEventTypesByIds(ids: number[]): IGDPREventType[] {
        return this.eventTypes.filter(x => ids.any(i => i == x.Id));
    }

    public async insertOrUpdateEventTypes(eventTypes: ITYP_EventType[]) {
        await this.GDPRService.InsertOrUpdateEventTypes(eventTypes);
        await this.load();
    }

    public checkIfSystemEvent(id: number): boolean {
        let eventType = this.eventTypes.find(x => x.Id == id);
        return !!eventType && eventType.IsSystemType;
    }

    async load(): Promise<any> {
        this.eventTypes = await this.GDPRService.GetEventTypes({
            textFilter: null,
            logicEventTypeId: null,
            showDeleted: true,
            skip: 0,
            count: null
        });
    }
    getName(): string {
        return nameof<IGDPREventTypesSettingsManager>();
    }
    getLabel(): string {
        return TextResources.GDPR.EventTypes
    }
    hasEditingUI(): boolean {
        return this.canWriteEventTypes;
    }
    getEditingUI() {
        return new GDPREventTypesSettingsComponent({ EventTypesSettingsManager: this });
    }

    private matchTextFilter(item: IGDPREventType, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (item.Description.toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}