import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 22/10/13
 * Time: 11.31
 * To change this template use File | Settings | File Templates.
 */

import { WorkedHoursMonthlyReport } from "./monthly-report/WorkedHoursMonthlyReport";
import { ActivitiesByCharactersMonthlyReport } from "./monthly-activities-by-characters-report/ActivitiesByCharactersMonthlyReport";
import { WorkedHoursAnomaliesReport } from "./worked-hours-anomalies/WorkedHoursAnomaliesReport";
import { WorkedHoursByJobOrderTypeReport } from "./worked-hours-by-job-order-type/WorkedHoursByJobOrderTypeReport";
import { ExtractHoursReport } from "./ExtractHoursReport";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class WorkedHoursReportsProvider implements IReportsProvider
{
    @LazyImport(nameof<IAuthorizationService>())
    authorizationService : IAuthorizationService;

    Id : number = 3;
    Name : string = ProlifeSdk.TextResources.WorkedHours.Work;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";
    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    
    private navigator : IReportsNavigator;
    
    constructor()
    {
        this.initialize();
    }

    initialize()
    {
        this.Reports([]);

        if(this.authorizationService.isAuthorized("WorkedHours_ManageMonthlyReport"))
            this.Reports.push(new WorkedHoursMonthlyReport(this.Id));
        if(this.authorizationService.isAuthorized("WorkedHours_CustomerWorkedHoursReport"))
            this.Reports.push(new ActivitiesByCharactersMonthlyReport(this.Id));
        if(this.authorizationService.isAuthorized("WorkedHours_WorkedHoursAnomaliesReport"))
            this.Reports.push(new WorkedHoursAnomaliesReport(this.Id));
        if(this.authorizationService.isAuthorized("WorkedHours_HoursByJobOrderReport"))
            this.Reports.push(new WorkedHoursByJobOrderTypeReport(this.Id));
        if(this.authorizationService.isAuthorized("WorkedHours_WorkedHoursExportReport"))
            this.Reports.push(new ExtractHoursReport(this.Id));

        this.setCurrentNavigator(this.navigator);
    }

    getReportById(reportId : number) : IReport
    {
        var report : IReport = null;

        this.Reports().forEach((r : IReport) => {
            report = r.Id == reportId ? r : report;
        })

        return report;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
        this.Reports().forEach((provider) => provider.setNavigator(navigator));
    }

    Open()
    {
        this.navigator.navigateTo(this);
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}