import * as ko from "knockout";
import { ReportComponentWithChildren } from "./ReportComponentWithChildren";
import { ReportComponentWithDataSourceId } from "./ReportComponentWithDataSourceId";
import { ReportPage } from "./ReportPage";
import { ReportSection } from "./ReportSection";

export type MergeParams<T extends any[], P> = T extends [] ? [P] : [T[0] & P, ...Tail<T>];
export type Tail<T extends any[]> = T extends [any, ...infer U] ? U : never;
export type ReportComponentConstructor<T extends ReportComponent = any, A extends any[] = any[]> = new (...props: A) => T;
export type MergedReportComponentConstructor<TBase extends ReportComponentConstructor, TProps, TParams> = ReportComponentConstructor<InstanceType<TBase> & TProps, MergeParams<ConstructorParameters<TBase>, TParams>>

export type ReportComponentProps = { id: number, name: string, type: string };

export class ReportComponent {
    public features: string[] = ["Component"];

    get type() : string { throw "Not Implemented"; }

    id: number;
    name: ko.Observable<string> = ko.observable();
    parent: ko.Computed<ReportComponent & ReportComponentWithChildren>;

    internalParent : ko.Observable<ReportComponent & ReportComponentWithChildren> = ko.observable();

    constructor({id, name}: ReportComponentProps) {
        this.id = id;
        this.name(name);

        this.parent = ko.computed({
            read: () => this.internalParent(),
            write: (value) => {
                throw "Cannot change parent using this property!"
            }
        });
    }

    getSection() : ReportSection {
        let parent = this.parent();

        while(parent) {
            if(parent.type === nameof<ReportSection>())
                return parent as ReportSection;
            parent = parent.parent();
        }

        return this.type === nameof<ReportSection>() ? this as unknown as ReportSection : null;
    }

    getParentDataSourceId() : number {
        let parent = this.parent();

        while(parent) {
            if(parent.hasFeature("DataSourceId") && (parent as unknown as ReportComponentWithDataSourceId).dataSourceId())
                return (parent as unknown as ReportComponentWithDataSourceId).dataSourceId();
            parent = parent.parent();
        }

        return null;
    }

    getChildrenNames() {
        return [this.name()];
    }

    isChildOf(parent: ReportComponent) : boolean {
        let actualParent = this.internalParent();
        while(actualParent) {
            if(actualParent === parent)
                return true;
            actualParent = actualParent.parent();
        }
        return false;
    }

    hasFeature(featureName: string) : boolean {
        return this.features.any(f => f === featureName);
    }

    getData() {
        return {
            id: this.id,
            name: this.name(),
            type: this.type
        }
    }

    remove() {
        const actualIndex = this.internalParent().children.indexOf(this);
        this.internalParent().children().splice(actualIndex, 1);
        this.internalParent(undefined);
        return actualIndex;
    }

    static findNextName(allNames: string[], prefix: string) {
        for(let i = 1; ; i++) {
            const tempName = prefix + " " + i;
            if(allNames.indexOf(tempName) === -1)
                return tempName;
        }
    }
}