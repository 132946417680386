import * as React from "@abstraqt-dev/jsxknockout"
import { IFullTreatmentJobOrders, IFullTreatmentEventsResources, IGDPRService, IFullTreatmentData, IInsertOrUpdateTreatmentRequest, IFullTreatmentResources } from "../GDPRService";
import ko = require("knockout");
import jss from "jss";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../ProlifeSdk/ValidationService";
import  * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IDataSourceModel, IDataSourceListener, IDataSource } from "../../DataSources/IDataSource";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { Table, ITableItem } from "../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../Components/TableComponent/SecondaryRow";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IIdGeneratorService } from "../../ProlifeSdk/IdGeneratorService";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../DataSources/JobOrdersDataSource";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { GDPREventTypesDataSource } from "../../DataSources/GDPREventTypesDataSource";
import { Right } from "../../Core/Authorizations";
import { RichTextInput } from "../../Components/RichTextInput";
import { TabNav } from "../../Components/TabNavComponent";
import { IGDPREventTypesSettingsManager } from "../settings/GDPREventTypesSettingsManager";
import { TextInput } from "../../Components/TextInput";
import { Select2 } from "../../Components/Select2Component";
import { TreatmentTypesDataSource } from "../../DataSources/TreatmentTypesDataSource";
import { IHumanResource } from "../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ActiveResourcesByDate } from "./ComputedResourcesDataSource";
import { Layout } from "../../Components/Layouts";
import { IfNot } from "../../Components/IfIfNotWith";
import { TreatmentEvent, TreatmentEventResource } from "./ViewModels/TreatmentEvent";
import { DateTimeInput } from "../../Components/DateTimeInput";

const { classes } = jss.createStyleSheet({
    gdprTable : {
        "& .actions-col": {
            width: "60px"
        },
        // "& .noMargin input[type=text]": {
        //     margin: "0 !important"
        // }
    }
}).attach();

export class TreatmentResource implements IFullTreatmentResources {
    FkResource: number;
    FullName: string;
    ResourceType: string;
    IsActive: boolean;
    events: ko.ObservableArray<TreatmentEvent>;
    IsDetailHidden: ko.Observable<boolean> = ko.observable(true);

    constructor(item: IFullTreatmentResources, events: TreatmentEvent[], eventsResources: IFullTreatmentEventsResources[]){
        this.FkResource = item.FkResource;
        this.FullName = item.FullName;
        this.ResourceType = item.ResourceType;
        this.IsActive = item.IsActive;
        const relevantEventsIds = eventsResources.filter(er => er.FkResource == item.FkResource).map(er => er.FkEvent);
        this.events = ko.observableArray(events.filter((e) => relevantEventsIds.any(i => i == e.Id)).orderByDesc(e => e.Date()));
    }

    toggleDetail(){
        this.IsDetailHidden(!this.IsDetailHidden());
    }
}

export interface IGDPRTreatmentComponentProps {
    treatmentId?: number;
    fromJobOrder?: IFullTreatmentJobOrders;
    ref?: any;
}

export class GDPRTreatmentComponent implements IDataSourceListener  {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService: IGDPRService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesSettingsManager: IHumanResourcesSettingsManager;

    @LazyImportSettingManager(nameof<IGDPREventTypesSettingsManager>())
    private GDPREventTypesSettingsManager: IGDPREventTypesSettingsManager;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService: IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    validator: IValidator<GDPRTreatmentComponent>;

    @Right("GDPR_CanInsertOrUpdateTreatments")
    private canInsertOrUpdateTreatments: boolean;

    @Right("GDPR_CanWriteOpenCloseTreatmentEvents")
    private canWriteOpenCloseTreatmentEvents: boolean;

    @Right("GDPR_CanWriteDescriptiveTreatmentEvents")
    private canWriteDescriptiveTreatmentEvents: boolean;

    @Right("GDPR_CanWriteRemoveResourcesTreatmentEvents")
    private canWriteRemoveResourcesTreatmentEvents: boolean;

    @Right("GDPR_CanLockEvents")
    private canLockEvents: boolean;

    public JobOrdersDataSource: JobOrdersDataSource;

    public ActiveResourcesByDate: ko.Computed<ActiveResourcesByDate[]>;
    
    private treatmentId: number;
    public item: IFullTreatmentData;
    public InitialTitle: ko.Observable<string> = ko.observable(TextResources.GDPR.AddNewTreatment);
    public Title: ko.Observable<string> = ko.observable("");
    public Description: ko.Observable<string> = ko.observable("");
    public DescriptionTrigger: ko.Observable<any> = ko.observable();

    public FkTreatmentType: ko.Observable<number> = ko.observable(1);
    public TreatmentTypeName: ko.Observable<string> = ko.observable();
    public State: ko.Observable<string> = ko.observable(TextResources.GDPR.Closed);
    public TreatmentTypesDataSource: TreatmentTypesDataSource = new TreatmentTypesDataSource();

    public JobOrders: ko.ObservableArray<IFullTreatmentJobOrders> = ko.observableArray([]);
    public SelectedJobOrderId: ko.Observable<number> = ko.observable();
    public SelectedJobOrderName: ko.Observable<string> = ko.observable();

    public Events: ko.ObservableArray<TreatmentEvent> = ko.observableArray([]);

    public Resources: ko.ObservableArray<TreatmentResource> = ko.observableArray([]);

    public GDPREventTypesDataSource: GDPREventTypesDataSource = new GDPREventTypesDataSource();

    constructor(private props: IGDPRTreatmentComponentProps){
        
        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        const allowedLogicTypesIds = [];
        if (this.canWriteDescriptiveTreatmentEvents)
            allowedLogicTypesIds.push(4);
        
        if (this.canWriteOpenCloseTreatmentEvents)
            allowedLogicTypesIds.push(...[1, 2]);

        if (this.canWriteRemoveResourcesTreatmentEvents)
            allowedLogicTypesIds.push(3);
        
        this.GDPREventTypesDataSource.setAllowedLogicTypesIds(allowedLogicTypesIds);

        this.ActiveResourcesByDate = ko.computed(() => {
            const activeResources: IHumanResource[] = [];
            const activeResourcesByDate: ActiveResourcesByDate[] = [];
            for (const e of this.Events().orderBy(x => x.Date())) {
                const date = e.Date();
                for (const r of e.Resources()){
                    const existCheck = activeResources.any(ar => ar.Resource.Id == r.FkResource());
                    if (this.GDPREventTypesSettingsManager.getEventTypesByIds([e.FkEventType()]).firstOrDefault().FkLogicEventType == 3){
                        if (existCheck) {
                            const index = activeResources.findIndex(ar => ar.Resource.Id == r.FkResource());
                            activeResources.splice(index, 1);
                        }
                    } else {
                        if (!existCheck){
                            activeResources.push(this.resourcesSettingsManager.getHumanResourceById(r.FkResource()));
                        }
                    }
                }
                activeResourcesByDate.push({Date: date, Id: e.Id, Resources: [...activeResources]});
            }
            return activeResourcesByDate;
        });

        this.validator = this.validationService.createValidator<GDPRTreatmentComponent>()
            .isNotNullOrUndefinedOrWhiteSpace((d) => d.Title(), TextResources.GDPR.ErrorNoTitle)
            .isNotNullUndefinedOrZero((d) => d.FkTreatmentType(), TextResources.GDPR.ErrorNoTreatmentType);
    }

    async componentDidMount(){
        if (this.props.treatmentId){
            this.treatmentId = this.props.treatmentId;
            this.loadData();
        } else {
            this.treatmentId = this.idGeneratorService.getNextId();
            this.Title(TextResources.GDPR.AddNewTreatment);
            if (this.props.fromJobOrder){
                this.JobOrders.push(this.props.fromJobOrder);
            }
        }
        this.TreatmentTypeName((await this.TreatmentTypesDataSource.getById(null, [this.FkTreatmentType()])).firstOrDefault().model.Name);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.JobOrdersDataSource && !!model) {
            const jobOrderModel = model as IJobOrderDataSourceModel;
            this.SelectedJobOrderName(jobOrderModel.model.Name);
            this.addJobOrder();
            setTimeout(() => {
                this.JobOrdersDataSource.select(...[]);
                this.SelectedJobOrderId(undefined);
                this.SelectedJobOrderName(undefined);
            }, 250);
        }
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }

    async loadData(){
        this.item = await this.GDPRService.GetFullTreatmentData(this.treatmentId);
        this.Description(this.item.Treatment.Description);
        this.Title(this.item.Treatment.Title);
        this.InitialTitle(this.item.Treatment.Title);
        setTimeout(() => this.DescriptionTrigger.valueHasMutated(), 500);
        this.FkTreatmentType(this.item.Treatment.FkTreatmentType);
        this.JobOrders(this.item.JobOrders);
        this.Events(this.item.Events.map((e) => new TreatmentEvent(e, this.item.EventsResources, this.ActiveResourcesByDate)));
        this.Resources(this.item.Resources.map(r => new TreatmentResource(r, this.Events(), this.item.EventsResources)));
        this.State(this.item.Treatment.IsOpen ? TextResources.GDPR.Open : TextResources.GDPR.Closed);
    }

    sortEvents(left: TreatmentEvent, right: TreatmentEvent): number {
        const leftCode = left.Date().toJSON() + ("000000000000" + left.Id).slice(-12);
        const rightCode = right.Date().toJSON() + ("000000000000" + right.Id).slice(-12);
        if (leftCode == rightCode)
            return 0;
        else if (leftCode < rightCode)
            return -1;
        else
            return 1;
    }

    createEventModel(event: TreatmentEvent){
        return {
            id: event.Id,
            title: event.Description(),
            isLeaf: true,
            isGroup: false,
            model: event
        }
    }

    addJobOrder(){
        if (!this.SelectedJobOrderName() || !this.SelectedJobOrderId()){
            this.infoToastService.Error(TextResources.GDPR.SelectAJobOrder);
            return;
        }

        if (this.JobOrders().any(jo => jo.FkJobOrder == this.SelectedJobOrderId())) {
            this.infoToastService.Error(TextResources.GDPR.JobOrderAlreadyInList);
            return;
        }

        this.JobOrders.push({ FkJobOrder: this.SelectedJobOrderId(), Name: this.SelectedJobOrderName() });
    }

    addTreatmentEvent(){
        const newEvent= new TreatmentEvent({
            Id: this.idGeneratorService.getNextId(),
            FkTreatment: this.treatmentId,
            FkEventType: this.GDPREventTypesSettingsManager.getEventTypes(null).firstOrDefault(et => et.FkLogicEventType == 4).Id,
            Title: "",
            Description: "",
            IsLocked: false,
            Date: new Date(),
            Location: "",
            FkCreationUser: null,
            FkLastEditUser: null,
            LastEditDate: null,
            CreationDate: null
        }, null, this.ActiveResourcesByDate);
        newEvent.toggleEdit();
        this.Events.push(newEvent);
    }

    deleteTreatmentEvent(item: TreatmentEvent){
        this.dialogsService.Confirm(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem, (result) => {
            if (result)
                this.Events.remove(item);
        });
    }

    createEventResourceModel(item: TreatmentEventResource) {
        return {
            id: item.FkResource(),
            title: "",
            isLeaf: true,
            isGroup: false,
            model: item
        }
    }

    createJobOrderModel(jobOrder : IFullTreatmentJobOrders) {
        return {
            id: jobOrder.FkJobOrder,
            title: jobOrder.Name,
            isLeaf: true,
            isGroup: false,
            model: jobOrder
        }
    }

    createTreatmentResourceModel(resource: TreatmentResource) {
        return {
            id: resource.FkResource,
            title: resource.FullName,
            isLeaf: true,
            isGroup: false,
            model: resource
        }
    }

    deleteTreatmentJobOrder(item: IFullTreatmentJobOrders){
        this.dialogsService.Confirm(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem, (result) => {
            if (result)
                this.JobOrders.remove(item);
        });
    }

    async saveAll(): Promise<boolean> {
        let isValid = true;

        if (this.validator.validateAndShowInfoToast(this)) {
            for (const event of this.Events()) {
                if (!event.validator.validateAndShowInfoToast(event) || event.Resources().any((r) => !r.validator.validateAndShowInfoToast(r))) {
                    isValid = false;
                    break;
                }
            }
        } else {
            isValid = false;
        }

        this.Events().orderBy(e => e.Date());
        let isOpen = false;
        const eventTypes = this.GDPRService.EventTypesSettingsManager.getEventTypes(null, true);

        for (const ev of this.Events().filter(e => !e.IsSystemEvent)) {
            const logicEventType = eventTypes.find(x => x.Id === ev.FkEventType()).FkLogicEventType;
            if (logicEventType == 1) {
                if (isOpen) {
                    isValid = false;
                    ev.HasError(true);
                    this.infoToastService.Error(TextResources.GDPR.ErrorDateOpenClose);
                    break;
                } else {
                    isOpen = true;
                }
            } else if (logicEventType == 2) {
                if (isOpen) {
                    isOpen = false;
                } else {
                    isValid = false;
                    ev.HasError(true);
                    this.infoToastService.Error(TextResources.GDPR.ErrorDateOpenClose);
                    break;
                }
            }
        }
        
        const activeResources = [];
        let notified = false;
        for (const ev of this.Events().filter(e => !e.IsSystemEvent)) {
            const logicEventType = eventTypes.find(x => x.Id === ev.FkEventType()).FkLogicEventType;
            let errorFound = false;
            for (const r of ev.Resources()){
                const existCheck = activeResources.any(ar => ar == r.FkResource());
                if (logicEventType == 3){
                    if (existCheck) {
                        const index = activeResources.findIndex(ar => ar == r.FkResource());
                        activeResources.splice(index, 1);
                    } else {
                        isValid = false;
                        errorFound = true;
                        r.HasError(true);
                        break;
                    }
                } else {
                    if (!existCheck){
                        activeResources.push(r.FkResource());
                    }
                }
                if (errorFound && !notified){
                    this.infoToastService.Error(TextResources.GDPR.ErrorDateAddRemoveResources);
                    notified = true;
                }
                ev.HasError(ev.HasError() || errorFound);
            }
        }

        if (isValid) {
            const requestData: IInsertOrUpdateTreatmentRequest = {
                treatment: [{
                    Id: this.treatmentId,
                    Title: this.Title(),
                    Description: this.Description(),
                    FkTreatmentType: this.FkTreatmentType()
                }],
                jobOrdersIds: this.JobOrders().map(jo => jo.FkJobOrder),
                events: this.Events().map(e => ({
                    Id: e.Id,
                    Title: e.Title(),
                    IsLocked: e.IsLocked(),
                    Description: e.Description(),
                    Date: e.Date(),
                    Location: e.Location(),
                    FkEventType: e.FkEventType()
                })),
                eventsResources: this.Events().selectMultiple(e => e.Resources()).map(x => ({
                    FkEvent: x.FkEvent,
                    FkResource: x.FkResource(),
                    ResourceType: x.ResourceType
                }))
            };
            await this.GDPRService.InsertOrUpdateTreatment(requestData);
        }

        return isValid;
    }

    render() {
        let treatmentJobOrders: IDataSourceModel<number, IFullTreatmentJobOrders>;
        let treatmentEvents: IDataSourceModel<number, TreatmentEvent>;
        let treatmentResources: IDataSourceModel<number, TreatmentResource>;
        //let treatmentEventResources: IDataSourceModel<number, TreatmentEventResource>;
        let eventResource: TreatmentEventResource;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        let table: Table<TreatmentEvent>;

        return ComponentUtils.bindTo(
            <Layout.Grid columns={["25%", "75%"]} rows={["auto"]} style={{height: "100%"}}>
                <Layout.Grid.Cell column={1} row={1} className="flex-container flex-vertical flex-1" style={{marginRight: "5px"}}>
                    <Layout.Grid columns={["auto"]} rows={["50%", "50%"]} style={{height: "100%"}}>
                        <Layout.Grid.Cell column={1} row={1} className="flex-container flex-vertical flex-1">
                            <h2 style={{marginBottom: "10px"}}>{TextResources.GDPR.RelatedJobOrders}</h2>
                            <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments}}>
                                <div className="form-group">
                                <Select2 simple={true} allowClear={true}
                                    value={vm.SelectedJobOrderId} 
                                    dataSource={vm.JobOrdersDataSource} 
                                    placeholder={TextResources.GDPR.AddAJobOrder} 
                                    listener={vm}/>
                                </div>
                            </ko-bind>
                            <Table style={{paddingTop:"15px"}} rowAs="treatmentJobOrders" compact={true} fixedLayout={true} scrollable={true} dataSource={{ array: this.JobOrders, factory: this.createJobOrderModel }} className={ComponentUtils.classNames("fixed-height", classes.gdprTable)}>
                                <Column title={TextResources.GDPR.Name}>
                                    <div style="width: 100%" className="text-ellipsis" data-bind={{text: treatmentJobOrders.title, attr: {title: treatmentJobOrders.title}}}></div>
                                </Column>
                                <Column title={TextResources.GDPR.Actions} headerCssClasses="actions-col text-right" cssClasses="actions-col text-right">
                                    <ko-bind data-bind={{if: (vm.canInsertOrUpdateTreatments && !(vm.props.fromJobOrder && vm.props.fromJobOrder.FkJobOrder == treatmentJobOrders.id))}}>
                                        <button className="btn btn-danger btn-xs" title={TextResources.GDPR.Delete} data-bind={{click: vm.deleteTreatmentJobOrder.bind(vm, treatmentJobOrders.model)}}>
                                            <i className="fa fa-trash-o"></i> 
                                        </button>
                                    </ko-bind>
                                </Column>
                            </Table>
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell column={1} row={2} className="flex-container flex-vertical flex-1">
                            <h2>{TextResources.GDPR.RelatedResources}</h2>
                            <Table rowAs="treatmentResources" compact={true} fixedLayout={true} scrollable={true} dataSource={{array: this.Resources, factory: this.createTreatmentResourceModel }} className={ComponentUtils.classNames("fixed-height", classes.gdprTable)}>
                                <Column style={{ width: '30px' }}>
                                    <Column.WithIdentifier data-bind={{style: {"background-color": treatmentResources.model.IsActive ? "limegreen" : "darkgray"}}}>
                                    <button className="btn btn-transparent btn-xs" title={TextResources.GDPR.ShowHideDetail} data-bind={{ click: treatmentResources.model.toggleDetail.bind(treatmentResources.model) }}>
                                        <i className="fa" data-bind={{ css: { 'fa-caret-right': treatmentResources.model.IsDetailHidden(), 'fa-caret-down': !treatmentResources.model.IsDetailHidden() }}}></i>
                                    </button>
                                    </Column.WithIdentifier>
                                </Column>
                                <Column title={TextResources.GDPR.Name}>
                                        <span data-bind={{text: treatmentResources.model.FullName}}></span>
                                </Column>
                                <Column title={TextResources.GDPR.Active} headerCssClasses="actions-col text-right" cssClasses="actions-col text-right">
                                    <ko-bind data-bind={{if: treatmentResources.model.IsActive}}>
                                        <i className="fa fa-check" style={{color: "limegreen"}}></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ifnot: treatmentResources.model.IsActive}}>
                                        <i className="fa fa-times" style={{color: "darkgray"}}></i>
                                    </ko-bind>
                                </Column>
                                <SecondaryRow if={() => "!treatmentResources.model.IsDetailHidden()"}>
                                    {(item: ITableItem<TreatmentResource>) => 
                                    <>
                                    <td></td>
                                    <td data-bind={{attr: {colspan: (table.VisibleColumnsCount() - 1)}}} style={{padding: "0px"}}>
                                        <Table rowAs="treatmentEvents" compact={true} fixedLayout={true} scrollable={true} dataSource={{array: item.Data.model.events, factory: this.createEventModel}} className={ComponentUtils.classNames("fixed-height", classes.gdprTable)}>
                                            <Column title={TextResources.GDPR.Date} cssClasses="noMargin" data-bind={{dateTimeText: treatmentEvents.model.OriginalDate}}/>
                                            <Column headerCssClasses="actions-col text-right" cssClasses="actions-col text-right">
                                                <ko-bind data-bind={{if: treatmentEvents.model.FkOriginalLogicEventType == 3}}>
                                                    <i className="fa fa-minus" style={{color: "red"}}></i>
                                                </ko-bind>
                                                <ko-bind data-bind={{ifnot: treatmentEvents.model.FkOriginalLogicEventType == 3}}>
                                                    <i className="fa fa-plus" style={{color: "limegreen"}}></i>
                                                </ko-bind>
                                            </Column>
                                        </Table>
                                    </td>
                                    </>}
                                </SecondaryRow>
                            </Table>
                        </Layout.Grid.Cell>
                    </Layout.Grid>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell className="flex-container flex-vertical flex-1" row={1} column={2}>
                    <div className="row" style={{marginBottom: "0px"}}>
                        <div className="col-md-5">
                            <h2 className="text-ellipsis" style="width: 100%" data-bind={{text: vm.InitialTitle}}></h2>
                        </div>
                        <div className="col-md-4">
                            <h2 data-bind={{text: vm.TreatmentTypeName}}></h2>
                        </div>
                        <div className="col-md-3">
                            <h2>{TextResources.GDPR.State}: <span data-bind={{text: vm.State}}></span></h2>
                        </div>
                    </div>
                    <TabNav className="flex-container flex-1 flex-vertical">
                        <TabNav.Page title={TextResources.GDPR.TreatmentInfo}>
                            {() => (
                                <>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <label className="control-label">{TextResources.GDPR.Title}</label>
                                            <TextInput selectOnFocus simple maxLength={255} value={vm.Title} placeholder={TextResources.GDPR.TitlePlaceholder} readonly={!vm.canInsertOrUpdateTreatments}/>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="control-label">{TextResources.GDPR.TreatmentType}</label>
                                            <Select2 simple={true}
                                            value={vm.FkTreatmentType} 
                                            dataSource={vm.TreatmentTypesDataSource}
                                            readonly={!vm.canInsertOrUpdateTreatments}/>
                                        </div>
                                    </div>
                                    <label>{TextResources.GDPR.Description}</label>
                                    <RichTextInput value={vm.Description} trigger={vm.DescriptionTrigger} readonly={!vm.canInsertOrUpdateTreatments} placeholder={TextResources.GDPR.DescriptionPlaceholder} resizable simple/>
                                </>
                            )}
                        </TabNav.Page>
                        <TabNav.Page title={TextResources.GDPR.Events} default>
                            {() => (
                                <Table rowAs="treatmentEvents" compact={true} editable={true} fixedLayout={true} defaultSorter={this.sortEvents} scrollable={true} dataSource={{ array: this.Events, factory: this.createEventModel }} className={ComponentUtils.classNames("fixed-height", classes.gdprTable)}>
                                <Column style={{ width: '30px' }}>
                                    <Column.WithIdentifier data-bind={{style: {"background-color": treatmentEvents.model.HasError() ? "red" : ""}}}>
                                    <button className="btn btn-transparent btn-xs" title={TextResources.GDPR.ShowHideDetail} data-bind={{ click: treatmentEvents.model.toggleDetail.bind(treatmentEvents.model) }}>
                                        <i className="fa" data-bind={{ css: { 'fa-caret-right': treatmentEvents.model.IsDetailHidden(), 'fa-caret-down': !treatmentEvents.model.IsDetailHidden() }}}></i>
                                    </button>
                                    </Column.WithIdentifier>
                                </Column>
                                <Column title={TextResources.GDPR.Date} sorter={this.sortEvents} cssClasses="noMargin" style={{width: "170px"}}>
                                    <ColumnBody>
                                    {(item: ITableItem<TreatmentEvent>) => (
                                        <DateTimeInput noIcon simple allowClear readonly={() => !item.Data.model.IsEdit()} value={item.Data.model.Date} parent={"body"}/>
                                    )}
                                    </ColumnBody>
                                </Column>
                                <Column title={TextResources.GDPR.Title} cssClasses="noMargin">
                                    <ColumnBody>
                                    {(item: ITableItem<TreatmentEvent>) => (
                                        <TextInput simple selectOnFocus readonly={() => !item.Data.model.IsEdit()} value={item.Data.model.Title} placeholder={TextResources.GDPR.TitlePlaceholder} maxLength={255}/>
                                    )}
                                    </ColumnBody>
                                </Column>
                                <Column title={TextResources.GDPR.EventType} cssClasses="noMargin">
                                    <select2 value={() => "treatmentEvents.model.FkEventType"} 
                                    dataSource={() => "vm.GDPREventTypesDataSource"}
                                    placeholder={TextResources.GDPR.EventTypePlaceholder}
                                    readOnly={() => "!treatmentEvents.model.IsEdit()"}/>
                                </Column>
                                {/* <Column title={TextResources.GDPR.Location} cssClasses="noMargin">
                                    <text-input readOnly={() => "!treatmentEvents.model.IsEdit()"} value={() => "treatmentEvents.model.Location"} placeholder={TextResources.GDPR.LocationPlaceholder}/>
                                </Column> */}
                                <Column headerCssClasses="text-right" cssClasses="text-right" style={{width: "90px"}}>
                                    <ColumnHeader>
                                        <ko-bind data-bind={{if: vm.canWriteDescriptiveTreatmentEvents}}>
                                        <button className="btn btn-primary btn-xs" title={TextResources.GDPR.Add} data-bind={{click: vm.addTreatmentEvent.bind(vm)}}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        </ko-bind>
                                    </ColumnHeader>
                                    <ColumnBody>
                                        <ko-bind data-bind={{ifnot: treatmentEvents.model.IsSystemEvent}}>
                                            <ko-bind data-bind={{if: vm.canLockEvents}}>
                                                <button className="btn btn-xs" title={TextResources.GDPR.LockUnlockEvent} data-bind={{click: treatmentEvents.model.toggleLock.bind(treatmentEvents.model), css: {'btn-danger': !treatmentEvents.model.IsLocked(), 'btn-success': treatmentEvents.model.IsLocked()}}}>
                                                    <i className="fa" data-bind={{css: {'fa-lock': !treatmentEvents.model.IsLocked(), 'fa-unlock': treatmentEvents.model.IsLocked()}}}></i>
                                                </button>
                                            </ko-bind>
                                            <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments && treatmentEvents.model.CanWrite() && !treatmentEvents.model.IsLocked()}}>
                                                <button className="btn btn-primary btn-xs" title={TextResources.GDPR.EnableEdit} data-bind={{hidden: treatmentEvents.model.IsEdit, click: treatmentEvents.model.toggleEdit.bind(treatmentEvents.model)}}>
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                <button className="btn btn-danger btn-xs" title={TextResources.GDPR.Delete} data-bind={{click: vm.deleteTreatmentEvent.bind(vm, treatmentEvents.model)}}>
                                                    <i className="fa fa-trash-o"></i> 
                                                </button>
                                            </ko-bind>
                                            <ko-bind data-bind={{if: treatmentEvents.model.IsLocked() && !vm.canLockEvents}}>
                                                <i className="fa fa-lock" title={TextResources.GDPR.EventIsLocked} style={{color: "darkred"}}></i>
                                            </ko-bind>
                                            <ko-bind data-bind={{if: treatmentEvents.model.ShowOtherPeopleEventAlert}}>
                                                <i className="fa fa-user" title={TextResources.GDPR.OtherPeopleEvent} style={{color: "darkred"}}></i>
                                            </ko-bind>
                                        </ko-bind>
                                        <ko-bind data-bind={{if: treatmentEvents.model.IsSystemEvent}}>
                                            <i className="fa fa-database" title={TextResources.GDPR.SystemEvent} style={{color: "darkred"}}></i>
                                        </ko-bind>
                                    </ColumnBody>
                                </Column>
                                <SecondaryRow if={() => "!treatmentEvents.model.IsDetailHidden()"}>
                                    {(item: ITableItem<TreatmentEvent>) => 
                                    <>
                                    <td><Column.WithIdentifier data-bind={{style: {"background-color": treatmentEvents.model.HasError() ? "red" : ""}}}/></td>
                                    <td className="form" data-bind={{hidden: treatmentEvents.model.IsDetailHidden, attr: {colspan: (table.VisibleColumnsCount() - 1)}}} style={{backgroundColor: "white"}}>
                                        <div className="form-group" style={{marginTop: "5px"}}>
                                            <label>{TextResources.GDPR.Description}</label>
                                            <label className="pull-right" data-bind={{ifnot: treatmentEvents.model.Id < 0}}>
                                                <small>{TextResources.GDPR.Created}: </small>
                                                <small style={{fontSize: "1.1em"}} data-bind={{dateTimeText: treatmentEvents.model.CreationDate}}></small>
                                                <small> {TextResources.GDPR.From}: </small>
                                                <small style={{fontSize: "1.1em"}} data-bind={{text: treatmentEvents.model.CreationUserFullName}}></small>
                                            </label>
                                            <div className="form-control" style="height: auto">
                                                <textarea 
                                                    title={TextResources.GDPR.Description} 
                                                    data-bind={{
                                                        readonly: treatmentEvents.model.IsDisabled, 
                                                        htmlEditor: treatmentEvents.model.Description, 
                                                        htmlEditorTrigger: treatmentEvents.model.DescriptionTrigger, 
                                                        htmlEditorOptions: { 
                                                            inlineMode : true, 
                                                            language: 'it', 
                                                            resize_enabled: false, 
                                                            scayt_sLang: 'it_IT', 
                                                            height: 100 
                                                        }
                                                    }} 
                                                    placeholder={TextResources.GDPR.DescriptionPlaceholder}/>
                                            </div>
                                        </div>
                                        <IfNot condition={() => item.Data.model.IsSystemEvent}>
                                            {() => <>
                                            <label>{TextResources.GDPR.RelatedResources}</label>
                                            <label className="pull-right" data-bind={{ifnot: treatmentEvents.model.Id < 0}}>
                                                <small>{TextResources.GDPR.LastEdited}: </small>
                                                <small style={{fontSize: "1.1em"}} data-bind={{dateTimeText: treatmentEvents.model.LastEditDate}}></small>
                                                <small> {TextResources.GDPR.From}: </small>
                                                <small style={{fontSize: "1.1em"}} data-bind={{text: treatmentEvents.model.LastEditUserFullName}}></small>
                                            </label>
                                            <div style="margin-bottom: 5px; width: 40%" data-bind={{hidden: !treatmentEvents.model.IsEdit() || (!treatmentEvents.model.CanAddResources() && !treatmentEvents.model.CanAddRemoveYourself()) }}>
                                                <div class="input-group" style="table-layout: fixed; width: 100%">
                                                <Select2 value={item.Data.model.SelectedResourceId} 
                                                    dataSource={item.Data.model.ResourcesDataSource} 
                                                    simple={true} allowClear={true}
                                                    placeholder={TextResources.GDPR.AddAResource}
                                                    listener={item.Data.model}/>
                                                {/* <span style="width: 37px" class="input-group-btn">
                                                    <button className="btn btn-primary" data-bind={{click: treatmentEvents.model.addResource.bind(treatmentEvents.model)}}>
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </span> */}
                                                </div>
                                            </div>

                                            <div className="form-control col-md-12" style={{marginBottom: "5px"}} data-bind={{foreach: { data: treatmentEvents.model.Resources, as: "eventResource"}}}>
                                                <span className="label" data-bind={{css: {"label-primary": !eventResource.HasError(), "label-danger": eventResource.HasError()}}} style="margin-bottom: 5px; margin-right: 5px">
                                                    <span className="btn btn-xs" data-bind={{hidden: !treatmentEvents.model.IsEdit() || !eventResource.CanBeRemoved(), click: treatmentEvents.model.deleteResource.bind(treatmentEvents.model, eventResource)}}>&times;</span>
                                                    <span data-bind={{text: eventResource.ResourceFullName}}></span>
                                                </span>
                                            </div>
                                            </>}
                                        </IfNot>
                                    </td></>}
                                </SecondaryRow>
                            </Table>
                            )}
                        </TabNav.Page>
                    </TabNav>
                </Layout.Grid.Cell>
            </Layout.Grid>, this, "vm");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(GDPRTreatmentComponent);
}