import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ArticleRequirement } from "./ArticleRequirements";
import { IDataSourceModel } from "../../../../../../DataSources/IDataSource";
import { Table, ITableItem } from "../../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { RequirementSourceInfo } from "../../../../../../Provisioning/Provisioning/ui/panels/RequirementSourceInfo";
import { ArticleInfo } from "../../../../../../Provisioning/Provisioning/ui/panels/ArticleInfo";
import { ComponentUtils } from "../../../../../../Core/utils/ComponentUtils";
import moment = require("moment");
import { Delay } from "../../../../../../Decorators/Delay";
import { TableFilter } from "../../../../../../Components/TableComponent/TableFilter";
import { CheckBox } from "../../../../../../Components/Checkbox";

const { classes } = jss.createStyleSheet({
    articleRequirements: {
        "& .checkbox-col": {
            width: "30px"
        },

        "& .amount-col": {
            width: "100px"
        },

        "& .action-col": {
            width: "40px"
        }
    }
}).attach();

export interface IArticleRequirementsInfoProps {
    articlesRequirement: ko.ObservableArray<ArticleRequirement>;
    factory: (articleStock: ArticleRequirement) => IDataSourceModel<number, ArticleRequirement>;

    showDocumentColumn?: boolean;
}

export class ArticleRequirementsInfo {
    private ArticlesRequirements : ko.ObservableArray<ArticleRequirement> = ko.observableArray([]);
    private FilteredArticlesRequirements : ko.ObservableArray<ArticleRequirement> = ko.observableArray([]);
    
    public SelectAllSourceArticles : ko.Computed<boolean>;

    private selectedArticles: number[] = [];
    private selectedDocuments: number[] = [];

    constructor(public props: IArticleRequirementsInfoProps) {
        this.ArticlesRequirements = this.props.articlesRequirement;
        this.FilteredArticlesRequirements(this.ArticlesRequirements());

        this.SelectAllSourceArticles = ko.computed({
            read: () => {
                let articles = this.FilteredArticlesRequirements();
                let selectedArticles = articles.filter(a => a.Selected());

                if (selectedArticles.length === 0)
                    return false;

                if (selectedArticles.length !== articles.length && selectedArticles.length > 0)
                    return null;

                return true;
            },
            write: (value) => {
                let articles = this.FilteredArticlesRequirements();
                for (let article of articles)
                    article.Selected(value);
            }
        });
    }

    render() {
        let articleRequirement: IDataSourceModel<number, ArticleRequirement>;
        
        let { sortString, sortNumber } = ComponentUtils.useSorter<ArticleRequirement>();
        let { getKey, getLabel } = ComponentUtils.useGetter<ArticleRequirement, number>();

        return (
            <Table compact={true} fixedLayout={true} dataSource={{ array: this.FilteredArticlesRequirements, factory: this.props.factory }} rowAs="articleRequirement" className={classes.articleRequirements}>
                <Column className="checkbox-col">
                    <ColumnHeader>
                        {() => <CheckBox checked={this.SelectAllSourceArticles} label="" />}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<ArticleRequirement>) => <CheckBox checked={item.Data.model.Selected} label="" />}
                    </ColumnBody>
                </Column>
                <Column sorter={sortString(m => m.ArticleCode)}>
                    <ColumnHeader>
                        {() =>  <>
                                    <span>{TextResources.Warehouse.Article}</span>
                                    <TableFilter filterSource={this.ArticlesRequirements} itemKeyGetter={getKey((i) => i.ArticleId)} itemLabelGetter={getLabel((i) => i.ArticleCode + " - " + i.Article)} onSelectionChange={this.onArticlessSelectionChanges.bind(this)}></TableFilter>
                                </>
                        }
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<ArticleRequirement>) => (<ArticleInfo code={item.Data.model.ArticleCode} description={item.Data.model.Article}></ArticleInfo>)}
                    </ColumnBody>
                </Column>
                <Column title={this.props.showDocumentColumn ? TextResources.Warehouse.RequestedAmount : TextResources.Warehouse.StockAmount} className="amount-col" sorter={sortNumber(m => m.RequiredAmount)}>
                    <span data-bind={{ numberText: articleRequirement.model.RequiredAmount, format: '0,0' }}></span>
                </Column>
                {!!this.props.showDocumentColumn && (
                    <Column sorter={sortString(m => String.format(TextResources.Provisioning.RequirementSourceDescriptionTopRow, ko.unwrap(m.DocumentRegisterName), ko.unwrap(m.DocumentNumber), moment(ko.unwrap(m.DocumentDate)).format("L")))}>
                        <ColumnHeader>
                            {() =>  <>
                                        <span>{TextResources.Warehouse.Document}</span>
                                        <TableFilter filterSource={this.ArticlesRequirements} itemKeyGetter={getKey((i) => i.DocumentId)} itemLabelGetter={getLabel((i) => String.format(TextResources.Provisioning.RequirementSourceDescriptionTopRow, ko.unwrap(i.DocumentRegisterName), ko.unwrap(i.DocumentNumber), moment(ko.unwrap(i.DocumentDate)).format("L")))} onSelectionChange={this.onDocumentsSelectionChanges.bind(this)}></TableFilter>
                                    </>
                            }
                        </ColumnHeader>
                        <ColumnBody>
                            {(item : ITableItem<ArticleRequirement>) => (
                                <RequirementSourceInfo 
                                    docId={item.Data.model.DocumentId}
                                    docNumber={item.Data.model.DocumentNumber} 
                                    docDate={item.Data.model.DocumentDate}
                                    rowNumber={null}
                                    protocolName={item.Data.model.DocumentRegisterName}
                                    showBottomRow={false}
                                    tooltip={true} />
                            )}
                        </ColumnBody>
                    </Column>
                )}
            </Table>
        );
    }

    private onArticlessSelectionChanges(articles: number[]): void {
        this.selectedArticles = articles;
        this.applyFilters();
    }
    
    private onDocumentsSelectionChanges(documents: number[]): void {
        this.selectedDocuments = documents;
        this.applyFilters();
    }

    @Delay(100)
    private applyFilters(): void {
        let allItems = this.ArticlesRequirements();
        let filteredItems = [];

        for (let item of allItems) {
            if (this.selectedArticles.indexOf(item.ArticleId) >= 0 && (!this.props.showDocumentColumn || this.selectedDocuments.indexOf(item.DocumentId) >= 0))
                filteredItems.push(item);
        }

        this.FilteredArticlesRequirements(filteredItems);
    }
}