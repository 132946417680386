import * as ko from "knockout";
import * as numeral from "numeral";
export class PercentagesValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                if(typeof underlyingObservable() == "undefined") return "";

                var discounts = (underlyingObservable() || "0%")
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/-/g, " ");

                return discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0[.]0[0]%"))
                    .join(" ");
            },
            write: function(newValue: string) {
                if(newValue == "") {
                    underlyingObservable(undefined);
                    underlyingObservable.valueHasMutated();
                    return;
                }

                if(!newValue)
                    newValue = "0%";


                var discounts = newValue
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/-/g, " ")
                    .replace(/\./g, ",");

                var valueToWrite = discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0[.]0[0]%"))
                    .join(" ");

                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

export class PercentagesText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                if(typeof underlyingObservable() == "undefined") return "";

                var discounts = (underlyingObservable() || "0%")
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/-/g, " ");

                return discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0[.]0[0]%"))
                    .join(" ");
            },
            write: function(newValue: string) {
                if(newValue == "") {
                    underlyingObservable(undefined);
                    underlyingObservable.valueHasMutated();
                    return;
                }

                if(!newValue)
                    newValue = "0%";

                var discounts = newValue
                    .replace(/%/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/-/g, " ");

                var valueToWrite = discounts.split(" ")
                    .filter((discountString : string) => discountString.length > 0)
                    .map((discountString : string) => numeral(numeral(discountString + "%").value()).format("0[.]0[0]%"))
                    .join(" ");

                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["percentagesValue"] = new PercentagesValue();
ko.bindingHandlers["percentagesText"] = new PercentagesText();
ko.virtualElements.allowedBindings["percentagesText"] = true;