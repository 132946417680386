import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer, IOrganizationalUnit, IUoAddress } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class RecipientValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();

        var customersService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var instance = {};

        let preventChange = false;

        if(otherBindings.value && ko.isObservable(otherBindings.value)) {
            otherBindings.value.subscribe((newValue) => {
                if(preventChange) return;

                (<any>$(element)).typeahead('val', newValue);
            });
        }

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            display : 'FullName',
            source : RecipientValue.findCustomers.bind(null, instance, customersService),
            limit: 50,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div>" + (item.FullName ? "<b>" + item.FullName + "</b>" : "");
                    element += (item.PIVA && item.PIVA.trim().length > 0) ? "<small>&nbsp;(" + item.PIVA + ")</small>" : "";
                    element += (item.Address && item.Address.trim().length > 0) || (item.UoName && item.UoName.trim().length > 0) ? "<br/>" : "";
                    var secondLine = item.Uo && item.Uo.Description && item.Uo.Description.trim().length > 0 ? "<small>" + item.Uo.Description + "</small>" : "";
                    secondLine += (item.Address && item.Address.trim().length > 0 ? "<small>" + (secondLine.length > 0 ? " - " : "") + item.Address + "</small>" : "");
                    element += secondLine;
                    return element + "</div>";
                }
            }

        }).on("typeahead:selected", (event, json) => {
            preventChange = true;

            const { Customer, Uo, UoAddress, FullName }: { Customer: ICustomer, Uo: IOrganizationalUnit, UoAddress: IUoAddress, FullName: string } = JSON.parse(json);
            valueAccessor()(Customer?.IdCliente);
            otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(FullName);

            var setter = otherBindings["recipientValueSetter"];

            if(setter)
                setter(Customer?.IdCliente, Uo?.Id, UoAddress?.Id);

            setTimeout(() => preventChange = false, 500);

            return FullName;
        })
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var otherBindings = allBindingsAccessor();

        if(!newValue) {
            !otherBindings.value && $(element).val("");
            return;
        }
    }

    private static findCustomers(_this : any, customersService : ICustomersService, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) {
        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            customersService.getAllCustomers(query, true)
                .then((customers : ICustomer[]) => {
                    return asyncProcess(RecipientValue.transformCustomersOld(customers));
                });
        }, 500);
    }

    private static transformCustomers(customers : ICustomer[]) : any[]
    {
        var recipients = [];
        for(let c of customers) {
            if(c.OrganizationalUnits.length === 0)
                recipients.push(RecipientValue.transformCustomer(c, null, null));

            for(let uo of c.OrganizationalUnits) {
                if(!uo.Addresses || uo.Addresses.length == 0) {
                    recipients.push(RecipientValue.transformCustomer(c, uo, null));
                    continue;
                }

                for(let a of uo.Addresses) {
                    recipients.push(RecipientValue.transformCustomer(c, uo, a));
                };
            };
        };

        return recipients;
    }

    private static transformCustomersOld(customers : ICustomer[]) : any[]
    {
        var recipients = [];
        for(let c of customers) {
            recipients.push(RecipientValue.transformCustomer(c, null, null));

            for(let uo of c.OrganizationalUnits) {
                for(let a of uo.Addresses) {
                    recipients.push(RecipientValue.transformCustomer(c, uo, a));
                };
            };
        };

        return recipients;
    }

    private static transformCustomer(customer : ICustomer, uo : IOrganizationalUnit, address : IUoAddress) : any {
        return {
            id: customer.IdCliente,
            FullName: customer.FormattedContactName || "",
            PIVA: customer.PIVA || "",
            Address : uo && address ? address.Address : customer.Indirizzo,
            Customer : customer,
            Uo : uo,
            UoAddress : address,
            Provincia: customer.Provincia || "",
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["recipientValue"] = new RecipientValue();
