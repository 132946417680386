import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/02/2018
 * Time: 16:16
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IEventCanceledMotivationsProvider, IEventCanceledMotivation } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEventCanceledMotivationsSettingsManager } from "../../../../ProlifeSdk/interfaces/agenda/IEventCanceledMotivationsSettingsManager";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

export class EventCanceledMotivationsProvider implements IEventCanceledMotivationsProvider {
    private motivations: IEventCanceledMotivation[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var eventCanceledMotivationsSettingsManager: IEventCanceledMotivationsSettingsManager = <IEventCanceledMotivationsSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventCanceledMotivationsSettingsManager);

        this.motivations = eventCanceledMotivationsSettingsManager.getMotivations();
    }

    GetMotivationsForWaitingList(query: ISelect2Query) {
        query.callback({
            results: this.motivations.filter((m: IEventCanceledMotivation) => { return m.Label.toLowerCase().indexOf(query.term.toLocaleLowerCase()) > -1 && m.ForWaitingList; }).map((m: IEventCanceledMotivation) => { return { id: m.Id, text: m.Label} })
        });
    }

    GetMotivationForWaitingList(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {
        var motivationId: number = parseInt(<string>$(element).val());
        if (!motivationId || isNaN(motivationId))
            return;
        var motivations: IEventCanceledMotivation[] = this.motivations.filter((m: IEventCanceledMotivation) => { return m.Id == motivationId && m.ForWaitingList; });
        if (motivations.length > 0)
            callback({ id: motivations[0].Id, text: motivations[0].Label });
    }

    GetMotivationsForAborting(query: ISelect2Query) {
        query.callback({
            results: this.motivations.filter((m: IEventCanceledMotivation) => { return m.Label.toLowerCase().indexOf(query.term.toLocaleLowerCase()) > -1 && m.ForAborting; }).map((m: IEventCanceledMotivation) => { return { id: m.Id, text: m.Label} })
        });
    }

    GetMotivationForAborting(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {
        var motivationId: number = parseInt(<string>$(element).val());
        if (!motivationId || isNaN(motivationId))
            return;
        var motivations: IEventCanceledMotivation[] = this.motivations.filter((m: IEventCanceledMotivation) => { return m.Id == motivationId && m.ForAborting; });
        if (motivations.length > 0)
            callback({ id: motivations[0].Id, text: motivations[0].Label });
    }

    GetMotivationById(id: number): IEventCanceledMotivation {
        var motivations = this.motivations.filter((m: IEventCanceledMotivation) => { return m.Id == id; });
        if (motivations.length == 0)
            return null;
        return motivations[0];
    }
}