import * as ko from "knockout";
import { SelectableMovementArticle } from "./SelectableMovementArticle";
import { IStockArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";

export class SelectableDetailedMovementArticle extends SelectableMovementArticle {
    public Available: ko.Observable<number> = ko.observable(0);
    public Reserved: ko.Observable<number> = ko.observable(0);
    public UnderStock: ko.Observable<number> = ko.observable(0);

    constructor(
        stockArticle: IStockArticle,
        entityType: string,
        manageTransaction: boolean,
        transactionId = 0,
        forSupplier = false
    ) {
        super(stockArticle, entityType, manageTransaction, transactionId, forSupplier);

        this.Available(stockArticle.StockAmount);
        this.Reserved(stockArticle.Reservations);
        this.UnderStock(stockArticle.MinimumAmount);
    }
}
