import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 24/09/13
 * Time: 15.19
 * To change this template use File | Settings | File Templates.
 */
//import { fabric } from "fabric";

export class FabricBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) {
        var canvas : fabric.Canvas = new fabric.Canvas(element, { });

        var options = allBindingsAccessor();
        FabricBinding.setCanvasSize(canvas, options);

        if(options.autoResize) {
            $(window).on('resize', () => FabricBinding.setCanvasSize(canvas, options));
        }

        if(options.fabricAutoEdit)
        {
            canvas.isDrawingMode = true;
            canvas.forEachObject((o) => o.hasControls = o.hasBorders = o.selectable = false);
            canvas.selection = false;
            canvas.renderAll();
        }

        setTimeout(() => {
            canvas.calcOffset();
        }, 1000);

        valueAccessor()(canvas);
    }

    static setCanvasSize(canvas : fabric.Canvas, options : any) {
        if(options.fabricSize)
        {
            var desiredWidth = parseInt(options.fabricSize.width);
            var desiredHeight = parseInt(options.fabricSize.height);

            if(typeof(options.fabricSize.width) == 'string')
            {
                var desiredWidthString : string = options.fabricSize.width;
                if(desiredWidthString.indexOf("vw") != -1)
                {
                    var desiredWidthInViewportWidth = desiredWidth;
                    desiredWidth = $(window).width() * (desiredWidthInViewportWidth / 100.0);
                }
            }

            if(typeof(options.fabricSize.height) == 'string')
            {
                var desiredHeightString : string = options.fabricSize.height;
                if(desiredHeightString.indexOf("vh") != -1)
                {
                    var desiredHeightInViewportWidth = desiredHeight;
                    desiredHeight = $(window).height() * (desiredHeightInViewportWidth / 100.0);
                }
            }

            canvas.setDimensions({width: desiredWidth, height: desiredHeight});
        }
    }
}

ko.bindingHandlers["fabric"] = new FabricBinding();