import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/12/2018
 * Time: 11:02
 * To change this template use File | Settings | File Templates.
 */

/// <reference path="../../../interfaces/IDocumentForElectronicInvoicing.ts" />
/// <reference path="../../../interfaces/IElectronicInvoicingDataSource.ts" />

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { DocumentDataSource } from "../../../../ProlifeSdk/prolifesdk/DocumentDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { CustomerNationalities } from "../../enums/CustomerNationalities";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { UngroupedCustomersDataSource } from "../../../../DataSources/UngroupedCustomersDataSource";
import { IInvoicesService, IDocumentForElectronicInvoicing, IGetDocumentsForElectronicInvoicingRequest } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IElectronicInvoicingDataSource } from "../../../interfaces/IElectronicInvoicingDataSource";
import { IJobOrdersProvider } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrdersProvider";
import { IVatRegister, IVatRegisters } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IDocumentForElectronicInvoicingViewModel } from "../../../interfaces/IDocumentForElectronicInvoicing";

interface IOption {
    Id: number,
    Description: string;
}

export class ElectronicInvoicingDataSource extends DocumentDataSource implements IElectronicInvoicingDataSource, IJobOrdersProvider {
    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();
    public DocumentNumberFrom: ko.Observable<number> = ko.observable();
    public DocumentNumberTo: ko.Observable<number> = ko.observable();
    public CustomerId: ko.Observable<number> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public ProtocolId: ko.Observable<number> = ko.observable();
    public ShowLockedDocs: ko.Observable<boolean> = ko.observable(false);
    public CustomerNationalityFilter: ko.Observable<CustomerNationalities> = ko.observable(CustomerNationalities.Italian);

    public CustomerNationalityOptions: ko.ObservableArray<IOption> = ko.observableArray([]);
    public ProtocolsList: ko.ObservableArray<IVatRegister> = ko.observableArray([]);

    public Documents: ko.ObservableArray<IDocumentForElectronicInvoicingViewModel> = ko.observableArray([]);
    public SelectedDocuments: ko.ObservableArray<IDocumentForElectronicInvoicingViewModel> = ko.observableArray([]);
    public FilterMode: ko.Observable<string> = ko.observable("0");
    public AllSelected: ko.Computed<boolean>;

    public CustomersDataSource: UngroupedCustomersDataSource;
    public JobOrdersDataSource: JobOrdersDataSource;

    private CustomerFilterForJobOrder: ko.Computed<number>;

    private loading: boolean = false;

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private protocolsManager: IVatRegisters;

    constructor() {
        super('electronic-invoicing-data-source', 'invoices/templates/wizard/data-sources', ProlifeSdk.TextResources.Invoices.ElectronicInvoicingDataSource);
        
        this.CustomersDataSource = new UngroupedCustomersDataSource();
        this.JobOrdersDataSource = new JobOrdersDataSource();
        
        this.CustomerFilterForJobOrder = ko.computed(() => {
            //return !this.Customer() ? null : this.Customer();
            return null;
        });

        this.AllSelected = ko.computed({
            read: () => {
                return this.Documents().filter((d) => d.Selected()).length == this.Documents().length;
            },
            write: (select: boolean) => {
                this.Documents().forEach((d) => {
                    d.Selected(select);
                });
            }
        });

        this.internalInitialize();
    }

    public Initialize()
    {
        this.reset();
        this.installSubscribers();
        this.LoadDocuments();
    }

    public LoadDocuments(): Promise<IDocumentForElectronicInvoicing[]> {
        if (this.loading)
            return Promise.resolve<IDocumentForElectronicInvoicing[]>([]);

        this.loading = true;

        let request: IGetDocumentsForElectronicInvoicingRequest = {
            DateFrom: this.DateFrom(),
            DateTo: this.DateTo(),
            ProtocolId: this.ProtocolId(),
            NumberFrom: this.DocumentNumberFrom(),
            NumberTo: this.DocumentNumberTo(),
            CustomerId: this.CustomerId(),
            JobOrderId: this.JobOrderId(),
            ShowLockedDocuments: this.ShowLockedDocs(),
            FilterBy: parseInt(this.FilterMode()),
            CustomerNationality: this.CustomerNationalityFilter()
        };

        return this.invoicesService.GetDocumentsForElectronicInvoicing(request)
            .then((documents: IDocumentForElectronicInvoicing[]) => {
                this.Documents(documents.map((d) => this.createDocumentForElectronicInvoicingViewModel(d)));
                return documents;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public ImportSelectedDocuments(): void {
        var duplicated: number = 0;

        var selectedDocuments: IDocumentForElectronicInvoicingViewModel[] = this.Documents().filter((d: IDocumentForElectronicInvoicingViewModel) => {
            if (this.IsAlreadySelected(d)) {
                duplicated++;
                return false;
            }

            return d.Selected();
        });

        if (selectedDocuments.length > 0)
            this.SelectedDocuments(this.SelectedDocuments().concat(selectedDocuments));

        if (duplicated > 0)
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ElectronicInvoicingDuplicatedOnSelection);
    }

    public SetFilterMode(mode: string): void {
        this.FilterMode(mode);
    }

    public EmptySelectedDocumentsList(): void {
        this.SelectedDocuments([]);
    }

    public RemoveDocumentFromSelectionList(document: IDocumentForElectronicInvoicingViewModel): void {
        var index: number = -1;

        var selection: IDocumentForElectronicInvoicingViewModel[] = this.SelectedDocuments();
        for (var i = 0; i < selection.length; i++) {
            if (document.IsSameDocument(selection[i].DocumentId)) {
                index = i;
                break;
            }
        }

        selection.splice(i, 1);

        if (index > -1)
            this.SelectedDocuments(selection);
    }

    public IsAlreadySelected(document: IDocumentForElectronicInvoicingViewModel): boolean {
        var matches: IDocumentForElectronicInvoicingViewModel[] = this.SelectedDocuments().filter((d) => d.IsSameDocument(document.DocumentId));
        return matches.length > 0;
    }

    public Validate(): boolean {
        if (this.SelectedDocuments().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ElectronicInvoicingDocumentsSelectionEmpty);
            return false;
        }

        return true;
    }

    public GetData(): IDocumentForElectronicInvoicingViewModel[] {
        return this.SelectedDocuments();
    }

    public findJobOrders(query: any) {}

    public findJobOrder(element, callback) {}

    private internalInitialize(): void {
        var protocols: IVatRegister[] = this.protocolsManager.getVatRegisters().filter((r: IVatRegister) => { return this.filterVatRegister(r); });
        this.ProtocolsList(protocols);

        this.SetNationalitiesFilterOnCustomerDataSource(this.CustomerNationalityFilter());

        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);
        
        this.CustomerNationalityOptions.push({ Id: CustomerNationalities.Italian, Description: TextResources.Invoices.ItalianCustomerNationality });
        this.CustomerNationalityOptions.push({ Id: CustomerNationalities.Foreign, Description: TextResources.Invoices.ForeignCustomerNationality });
    }

    private filterVatRegister(register: IVatRegister): boolean {
        return !register.ForPartialInvoices && register.TipoDocumento == ProlifeSdk.InvoiceDocumentType;
    }

    private createDocumentForElectronicInvoicingViewModel(d: IDocumentForElectronicInvoicing): IDocumentForElectronicInvoicingViewModel {
        return new DocumentForElectronicInvoicing(d, this);
    }

    private reset(): void {
        this.Documents([]);
        this.SelectedDocuments([]);
        this.DateFrom(moment().add("days", -14).toDate());
        this.DateTo(moment().toDate());
        this.DocumentNumberFrom(null);
        this.DocumentNumberTo(null);
        this.CustomerId(null);
        this.JobOrderId(null);
    }

    private installSubscribers(): void {
        this.DateFrom.subscribe(this.LoadDocuments.bind(this));
        this.DateTo.subscribe(this.LoadDocuments.bind(this));
        this.ProtocolId.subscribe(this.LoadDocuments.bind(this));
        this.DocumentNumberFrom.subscribe(this.LoadDocuments.bind(this));
        this.DocumentNumberTo.subscribe(this.LoadDocuments.bind(this));
        this.CustomerId.subscribe(this.LoadDocuments.bind(this));
        this.JobOrderId.subscribe(this.LoadDocuments.bind(this));
        this.ShowLockedDocs.subscribe(this.LoadDocuments.bind(this));
        this.FilterMode.subscribe(this.LoadDocuments.bind(this));
        this.CustomerNationalityFilter.subscribe((value: CustomerNationalities) => {
            this.SetNationalitiesFilterOnCustomerDataSource(value);
            this.LoadDocuments();
        });

        this.DateFrom.subscribe((date: Date) => {
            if (moment(date) > moment(this.DateTo()))
                this.DateTo(date);
        });

        this.DateTo.subscribe((date: Date) => {
            if (moment(date) < moment(this.DateFrom()))
                this.DateFrom(date);
        });
    }
    
    private SetNationalitiesFilterOnCustomerDataSource(value: CustomerNationalities) {
        this.CustomersDataSource.setGetItalianCustomers(value === CustomerNationalities.Italian);
        this.CustomersDataSource.setGetForeignCustomers(value === CustomerNationalities.Foreign);
    }
}

class DocumentForElectronicInvoicing implements IDocumentForElectronicInvoicingViewModel {
    public DocumentId: number;
    public EntityType: string;
    public DocumentType: string;
    public DocumentDate: Date;
    public DocumentNumber: string;
    public ProtocolName: string;
    public Total: number;
    public CustomerName: string;
    public JobOrderName: string;
    public Locked: boolean;

    public Selected: ko.Observable<boolean> = ko.observable();

    constructor(private document: IDocumentForElectronicInvoicing, private dataSource: ElectronicInvoicingDataSource) {
        this.DocumentId = this.document.DocumentId;
        this.EntityType = this.document.EntityType;
        this.DocumentType = this.document.IsCreditNote ? ProlifeSdk.TextResources.Invoices.CreditNote : (this.document.IsAccompanyingInvoice ? ProlifeSdk.TextResources.Invoices.InvoiceMore : ProlifeSdk.TextResources.Invoices.Invoice);
        this.DocumentDate = this.document.DocumentDate;
        this.DocumentNumber = this.document.DocumentNumber;
        this.ProtocolName = this.document.ProtocolName;
        this.Total = this.document.Total;
        this.CustomerName = this.document.CustomerName;
        this.JobOrderName = this.document.JobOrderName;
        this.Locked = this.document.Locked;

        this.Selected(true);
    }

    public RemoveFromList(): void {
        this.dataSource.RemoveDocumentFromSelectionList(this);
    }

    public IsSameDocument(documentId: number): boolean {
        return this.DocumentId == documentId;
    }
}