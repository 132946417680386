import jss from "jss";
import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { ITicketClosingType } from "../../TicketsService";
import { ITicketClosingTypesSettingsManager } from "../TicketClosingTypesSettingsManager";

const { classes } = jss.createStyleSheet({
    ticketsTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class TicketClosingType {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    public validator: IValidator<TicketClosingType>;

    Id: number;
    Name: ko.Observable<string> = ko.observable();
    Deleted: ko.Observable<boolean> = ko.observable();

    constructor(ticketClosingType: ITicketClosingType){
        this.Id = ticketClosingType.Id;
        this.Name(ticketClosingType.Name);
        this.Deleted(ticketClosingType.Deleted);

        this.validator = this.validationService.createValidator<TicketClosingType>()
        .isNotNullOrUndefinedOrWhiteSpace((d) => d.Name(), TextResources.Tickets.ErrorNoDescription)
    }
}

export interface ITicketClosingTypesSettingsComponentProps {
    TicketClosingTypesSettingsManager: ITicketClosingTypesSettingsManager;
}

export class TicketClosingTypesSettingsComponent {
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    TicketClosingTypes: ko.ObservableArray<TicketClosingType> = ko.observableArray();

    constructor(private props: ITicketClosingTypesSettingsComponentProps){
        this.loadData();
    }

    loadData(){
        this.TicketClosingTypes(this.props.TicketClosingTypesSettingsManager.getTicketClosingTypes(null, false).map((x) => new TicketClosingType(x)));
    }

    addNewTicketClosingType(){
        this.TicketClosingTypes.push(new TicketClosingType({
            Id: this.idGeneratorService.getNextId(),
            Deleted: false,
            Name: null
        }));
    }

    async deleteTicketClosingType(item: TicketClosingType){
        let result = await this.dialogsService.ConfirmAsync(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem);
        if (result) {
            if (item.Id < 0)
                this.TicketClosingTypes.remove(item);
            else
                item.Deleted(true);
        }
    }

    restoreTicketClosingType(item: TicketClosingType){
        item.Deleted(false);
    }

    async saveAll(){
        let isValid = true;
        for (let eventType of this.TicketClosingTypes()){
            if (!eventType.validator.validateAndShowInfoToast(eventType)){
                isValid = false;
                break;
            }
        }

        if (this.TicketClosingTypes().length < 1){
            this.infoToastService.Error(TextResources.Tickets.ErrorZeroClosingTypes);
            isValid = false;
        }

        if (isValid){
            try {
                await this.props.TicketClosingTypesSettingsManager.insertOrUpdateTicketClosingTypes(this.TicketClosingTypes().map(x => ({ 
                    Id: x.Id,
                    Name: x.Name(),
                    Deleted : x.Deleted()
                })));
            
                this.infoToastService.Success(TextResources.Tickets.AddExistingSuccess);
                this.loadData();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    createTicketClosingTypeModel(item : TicketClosingType) {
        return {
            id: item.Id,
            title: item.Name(),
            isLeaf: true,
            isGroup: false,
            model: item
        }
    }

    render() {
        let vm = this;
        let ticketClosingTypes: IDataSourceModel<number, TicketClosingType>;

        return ComponentUtils.bindTo(
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-10">
                        <h1>{TextResources.Tickets.ClosingTypes}</h1>
                    </div>
                    <div className="col-md-2 text-right" style="margin-top: 20px">
                        <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}}>
                            <i className="fa fa-save"></i> {TextResources.Tickets.Save}
                        </button>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <Table rowAs="ticketClosingTypes" dataSource={{array: vm.TicketClosingTypes, factory: vm.createTicketClosingTypeModel }} compact={true} editable={true} scrollable={true} className={classes.ticketsTable}>
                        <Column title={TextResources.Tickets.Notes}>
                            {/* <span data-bind={{if: ticketClosingTypes.model.IsInUse}}>
                                <i className="fa fa-exclamation-triangle" title={TextResources.Tickets.TicketInUseAlert}></i>
                            </span> */}
                            <span data-bind={{if: ticketClosingTypes.model.Deleted}}>
                                <i className="fa fa-trash-o" title={TextResources.Tickets.ClosingTypeIsDeletedAlert}></i>
                            </span>
                        </Column>
                        <Column title={TextResources.Tickets.Title} cssClasses="noMargin">
                            <text-input value={() => "ticketClosingTypes.model.Name"} readOnly={() => "ticketClosingTypes.model.Deleted()"} placeholder={TextResources.Tickets.TitlePlaceholder}/>
                        </Column>
                        <Column title={TextResources.Tickets.Actions}>
                            <ColumnHeader>
                                <button className="btn btn-primary btn-xs" data-bind={{click: vm.addNewTicketClosingType.bind(vm)}}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <div data-bind={{ifnot: ticketClosingTypes.model.Deleted}}>
                                    <button className="btn btn-danger btn-xs" data-bind={{click: vm.deleteTicketClosingType.bind(vm, ticketClosingTypes.model)}}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                                <div data-bind={{if: ticketClosingTypes.model.Deleted}}>
                                    <button className="btn btn-success btn-xs" data-bind={{click: vm.restoreTicketClosingType.bind(vm, ticketClosingTypes.model)}}>
                                        <i className="fa fa-recycle"></i>
                                    </button>
                                </div>
                            </ColumnBody>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>, this, "vm");
    }
}   