import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import {
    ComponentUtils,
    Param,
    ParamArray,
    ComponentParam,
    ComponentParamArray,
} from "../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../ProlifeTextResources";
import jss from "jss";
import { IDocumentCurrencyViewModel } from "../../interfaces/invoice/IDocumentsService";
import { IDialogsService, IPopoverComponentInfo, PopoverPosition } from "../../../Core/interfaces/IDialogsService";
import "./DocumentCurrenciesManager";

const { classes } = jss
    .createStyleSheet({
        "currencies-popover-alert": {
            position: "absolute",
            top: "-10px",
            color: "red",
            background: "white",
            "font-size": "20px !important",
            "border-radius": "10px !important",
        },
    })
    .attach();

let attributes = {
    DocumentCurrencies: "document-currencies",
    ShowEditButtonForExchangeValues: "show-edit-button-for-exchange-values",
    Listener: "listener",
    ReadOnly: "readonly",
    ButtonCssClasses: "button-css-classes",
    ButtonText: "button-text",
    Viewport: "viewport",
    Placement: "placement",
    PopOverCssClasses: "popover-css-classes",
    Extended: "extended",
    ShowAlertOnInvalidValues: "show-alert-on-invalid-values",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "document-currencies-popover": {
                params?: {
                    DocumentCurrencies: IDocumentCurrencyViewModel[];
                    ShowEditButtonForExchangeValues: boolean;
                    Listener: IDocumentCurrenciesManagerListener;
                    ReadOnly: boolean;
                    ButtonCssClasses: string;
                    ButtonText: string;
                    Viewport: string;
                    PopOverCssClasses: string;
                    Extended: boolean;
                    Placement: PopoverPosition;
                    ShowAlertOnInvalidValues: boolean;
                };

                "document-currencies"?: IDocumentCurrencyViewModel[] | (() => string);
                readonly?: boolean | (() => string);
                "show-edit-button-for-exchange-values"?: boolean | (() => string);
                listener?: IDocumentCurrenciesManagerListener | (() => string);
                "button-css-classes"?: string | (() => string);
                "button-text"?: string | (() => string);
                viewport?: string | (() => string);
                "popover-css-classes"?: string | (() => string);
                extended?: boolean | (() => string);
                placement?: PopoverPosition | (() => string);
                "show-alert-on-invalid-values"?: boolean | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IDocumentCurrenciesManagerListener {
    onDocumentCurrenciesEditEnd(): void;
}

export interface IDocumentCurrenciesPopOverParams {
    DocumentCurrencies: ParamArray<IDocumentCurrencyViewModel>;
    ShowEditButtonForExchangeValues: Param<boolean>;
    Listener: Param<IDocumentCurrenciesManagerListener>;
    ReadOnly: Param<boolean>;
    ButtonCssClasses: Param<string>;
    ButtonText: Param<string>;
    Viewport: Param<string>;
    Placement: Param<PopoverPosition>;
    PopOverCssClasses: Param<string>;
    Extended: Param<boolean>;
    ShowAlertOnInvalidValues: Param<boolean>;
}

export class DocumentCurrenciesPopOver {
    public ShowEditButtonForExchangeValues: ComponentParam<boolean>;
    public Listener: ComponentParam<IDocumentCurrenciesManagerListener>;
    public ReadOnly: ComponentParam<boolean>;
    public ButtonCssClasses: ComponentParam<string>;
    public ButtonText: ComponentParam<string>;
    public Viewport: ComponentParam<string>;
    public Placement: ComponentParam<PopoverPosition>;
    public PopOverCssClasses: ComponentParam<string>;
    public DocumentCurrencies: ComponentParamArray<IDocumentCurrencyViewModel>;
    public Extended: ComponentParam<boolean>;
    public ShowAlertOnInvalidValues: ComponentParam<boolean>;

    public DocumentCurrencyCode: ko.Computed<string>;

    public HasInvalidValues: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(params: IDocumentCurrenciesPopOverParams) {
        this.ShowEditButtonForExchangeValues = ComponentUtils.parseParameter(
            params.ShowEditButtonForExchangeValues,
            true
        );
        this.Listener = ComponentUtils.parseParameter(params.Listener, undefined);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.ButtonCssClasses = ComponentUtils.parseParameter(params.ButtonCssClasses, "");
        this.ButtonText = ComponentUtils.parseParameter(params.ButtonText, "");
        this.Viewport = ComponentUtils.parseParameter(params.Viewport, undefined);
        this.Placement = ComponentUtils.parseParameter(params.Placement, "bottom");
        this.PopOverCssClasses = ComponentUtils.parseParameter(params.PopOverCssClasses, "");
        this.DocumentCurrencies = ComponentUtils.parseParameterArray(params.DocumentCurrencies, []);
        this.Extended = ComponentUtils.parseParameter(params.Extended, false);
        this.ShowAlertOnInvalidValues = ComponentUtils.parseParameter(params.ShowAlertOnInvalidValues, false);

        this.DocumentCurrencyCode = ko.computed(() => {
            let documentCurrency = this.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
            return documentCurrency ? documentCurrency.Currency().CodeISO4217alpha3 : "";
        });

        this.HasInvalidValues = ko.computed(() => {
            for (let currency of this.DocumentCurrencies()) {
                if (
                    !currency.CurrencyId() ||
                    !currency.ExchangeValue() ||
                    (currency.IsDocumentCurrency() && !currency.ExchangeValueForVat())
                )
                    return true;
            }

            return false;
        });
    }

    public ShowDocumentCurrenciesManager(item: any, event: Event): Promise<void> {
        let listener: IDocumentCurrenciesManagerListener = this.Listener();

        let componentInfo: IPopoverComponentInfo = {
            componentName: "document-currencies-manager",
            title: TextResources.Invoices.CurrenciesLabel,
            model: {
                DocumentCurrencies: this.DocumentCurrencies,
                ReadOnly: this.ReadOnly,
                ShowEditButtonForExchangeValues: this.ShowEditButtonForExchangeValues,
                CurrenciesManager: ko.observable(),

                close: function () {
                    let editor = this.CurrenciesManager();

                    if (!editor) return;

                    if (editor.validate()) {
                        if (listener) listener.onDocumentCurrenciesEditEnd();
                        this.modal.close(null);
                    }
                },
            },
            params: "DocumentCurrencies: DocumentCurrencies, ReadOnly: ReadOnly, ShowEditButtonForExchangeValues: ShowEditButtonForExchangeValues, InjectTo: CurrenciesManager",
        };

        return this.dialogsService.ShowModalComponent(componentInfo, "medium");
    }
}

ko.components.register("document-currencies-popover", {
    viewModel: {
        createViewModel: (params: IDocumentCurrenciesPopOverParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new DocumentCurrenciesPopOver(params);

            let template = [
                <button type="button" data-bind="asyncClick: ShowDocumentCurrenciesManager, css: ButtonCssClasses">
                    <i
                        class="fa fa-money"
                        data-bind="style: { 'margin-right': (ButtonText() !== '' ? '10px' : '0') }"
                    ></i>
                    <ko-if data-bind="ButtonText() !== ''">
                        <span data-bind="text: ButtonText"></span>
                    </ko-if>
                    <div
                        class={"fa fa-exclamation-circle " + classes["currencies-popover-alert"]}
                        data-bind="visible: ShowAlertOnInvalidValues() && HasInvalidValues()"
                        title={TextResources.Invoices.InvalidCurrenciesAlert}
                    ></div>
                </button>,
            ];

            if (vm.Extended()) {
                template = [
                    <div class="form-group">
                        <div class="input-group">
                            <span class="form-control" data-bind="text: DocumentCurrencyCode"></span>
                            <span class="input-group-btn">
                                <button
                                    type="button"
                                    data-bind="asyncClick: ShowDocumentCurrenciesManager, css: ButtonCssClasses"
                                >
                                    <i
                                        class="fa fa-money"
                                        data-bind="style: { 'margin-right': (ButtonText() !== '' ? '10px' : '0') }"
                                    ></i>
                                    <ko-if data-bind="ButtonText() !== ''">
                                        <span data-bind="text: ButtonText"></span>
                                    </ko-if>
                                    <div
                                        class={"fa fa-exclamation-circle " + classes["currencies-popover-alert"]}
                                        data-bind="visible: ShowAlertOnInvalidValues() && HasInvalidValues()"
                                        title={TextResources.Invoices.InvalidCurrenciesAlert}
                                    ></div>
                                </button>
                            </span>
                        </div>
                    </div>,
                ];
            }

            ko.virtualElements.setDomNodeChildren(componentInfo.element, template);

            return vm;
        },
    },
    template: [],
});
