import * as ko from "knockout";
declare global {
    interface KnockoutDataBindsAttributes {
        asyncClick?: ((item?: unknown, event?: Event) => unknown) | ((item?: unknown, event?: Event) => Promise<unknown>);
    }
}

export class AsyncClick {
    init(element: Node, valueAccessor: () => (() => unknown | Promise<unknown>), allBindingsAccessor: () => unknown, viewModel: unknown): void {
        const callback : (() => unknown | Promise<unknown>) = valueAccessor();

        $(element).on("click", (e) => {
            if ($(element).prop("disabled")) {
                e.preventDefault();
                e.stopPropagation();
                
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            try
            {
                const result = callback.apply(viewModel, [viewModel, e.originalEvent]);
                if(result instanceof Promise) {
                    $(element).prop("disabled", true);

                    let restoreOriginalClass = false;
                    let originalIconClass: string;
                    const innerIcon = $(element).find("i");
                    if(innerIcon.length > 0) {
                        originalIconClass = innerIcon.attr("class");
                        innerIcon.attr("class", "fa fa-spinner fa-spin");
                        restoreOriginalClass = true;
                    }

                    result.then(() => {
                        $(element).prop("disabled", false);
                        if(restoreOriginalClass) {
                            innerIcon.attr("class", originalIconClass);
                            restoreOriginalClass = false;
                        }
                    }).catch((ex) => {
                        $(element).prop("disabled", false);
                        if(restoreOriginalClass) {
                            innerIcon.attr("class", originalIconClass);
                            restoreOriginalClass = false;
                        }

                        console.error(ex);
                    });
                }
            }
            catch(ex)
            {
                console.error(ex);
            }
        });
    }
}

ko.bindingHandlers["asyncClick"] = new AsyncClick();