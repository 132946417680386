import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { jobOrderPannel } from "./ui/JobOrderPannel";
import { LazyImport } from "../../Core/DependencyInjection";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IContextAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { Deferred } from "../../Core/Deferred";

export class JobOrderApplication implements IApplication {
	public templateName  = "job-order-pannel";
	public templateUrl  = 'jobOrder/templates';
	public templateData : any;
    public tileColor  = 'tile-green';

    private mainPanel : jobOrderPannel;

    @LazyImport(nameof<IApplicationsService>())
	private applicationsService : IApplicationsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;
    
    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);
    public contextActions: ko.ObservableArray<IContextAction> = ko.observableArray([]);

	constructor(private serviceLocator : IServiceLocator) {

		this.applicationsService =  serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
		this.applicationsService.registerApplication(this);
	}

    getApplicationCode() {
        return "JobOrders";
    }

	getName(): string {
		return ProlifeSdk.TextResources.JobOrder.Orders;
	}

	getIcon(): string {
        return "icon-speedometer";
	}

	registerRoutes() : void {
		this.opaService.Get("#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL, (context) => this.openSplashPage());
		this.opaService.Get("#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/:id", (context) => this.openCommessa(context));
	}

	getApplicationRoute() : string
    {
		return "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL;
	}

	onGoingDown() : void
    {
        if(this.mainPanel)
            this.mainPanel.dispose();

        this.mainPanel = null;
	}

    openCommessa(context : any) : void
    {
        const commessaId = parseInt(context.params["id"]);
        this.initializePanel().then(() => {
            if(commessaId > -1)
                this.mainPanel.openJobOrder(commessaId, false);
            else
                this.mainPanel.newCommessa();
        });
    }

    private initializePanel() : Promise<void>
    {
        const def = new Deferred<void>();

        if(this.mainPanel)
        {
            //this.applicationsService.startApplication(this);
            def.resolve();
            return def.promise();
        }

        this.mainPanel = new jobOrderPannel(this.serviceLocator);
            this.mainPanel.initialize().then(() => {
                this.templateData = this.mainPanel;
                this.applicationsService.startApplication(this);
                def.resolve();

                this.contextActions([{
                    Name: ko.observable(ProlifeSdk.TextResources.JobOrder.NewOrder),
                    CanRun: ko.observable(true),
                    Icon: ko.observable("fa-plus"),
                    Run: this.mainPanel.SplashPage.newCommessa.bind(this.mainPanel.SplashPage)
                }]);
            });

        return def.promise();
    }

	private openSplashPage()
    {
        this.initializePanel().then(() => {
            this.desktopService.SystemHeader.setSidebarVisibility(true);
            this.mainPanel.OpenSplashPage();
        });
	}
}
