import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "./ImportDocumentDataWizard";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

export class ImportDocumentDataWizardReportStep extends DocumentDataWizardStep
{
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;

    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;

    public JobOrderName : ko.Observable<string> = ko.observable("");
    public CustomerName : ko.Observable<string> = ko.observable("");

    public Total : ko.Computed<number>;
    
    constructor()
    {
        super();
        this.Title(TextResources.Invoices.Overview);
        this.IsVisible(false);
        this.IsSelected(true);

        this.Total = ko.computed(() => {
            return this.Rows().sum(r => r.Row.TotalPriceInDocumentCurrency());
        });
    }

    async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);

        await this.loadJobOrderName();
        await this.loadCustomerName();
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.Rows(previousStepRows);
    }

    OnNext(): IDocumentDataWizardRow[] {
        return this.Rows();
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }
    
    private async loadJobOrderName()
    {
        if(!this.initializationInfo || !this.initializationInfo.JobOrderId)
            return;

        try
        {
            const jobOrder = await this.jobOrderService.get(this.initializationInfo.JobOrderId);
            this.JobOrderName(jobOrder.Name);
        }
        catch(e)
        {
            this.JobOrderName(TextResources.ProlifeSdk.JobOrderNotFound);
        }
    }

    private async loadCustomerName()
    {
        if(!this.initializationInfo || !this.initializationInfo.CustomerId)
            return;

        try
        {
            const customer = await this.customersService.getCustomerById(this.initializationInfo.CustomerId);
            this.CustomerName(customer.FormattedContactName);
        }
        catch(e)
        {
            this.CustomerName(TextResources.ProlifeSdk.CustomerNotFound);
        }
    }

    ShowHtmlPreviewForPrint()
    {
        const domElements = $(".wizard-report-table");

        if(domElements.length == 0)
            return;

        const container = $("<p></p>");
        container.append($(domElements[0]).clone());
        const htmlCode : string = container.html();

        localStorage.setItem("HtmlPrintViewer_BodyContent", htmlCode);
        window.open("./HtmlPrintViewer.html", "_blank");
    }

    render() {
        let step : ImportDocumentDataWizardReportStep;
        let reportRow : IDocumentDataWizardRow;
        let row : ProcessedRow;

        return ComponentUtils.bindTo(
            <div style="padding : 20px">
                <div class="row">
                    <a class="btn btn-primary pull-right margin-left-20" href="#" data-bind={{ click : step.ShowHtmlPreviewForPrint }}>
                        <i class="fa fa-print"></i>
                        {TextResources.Invoices.DocumentWizardPrintableVersion}
                    </a>

                    <pre>
                        {TextResources.Invoices.DocumentWizardReportInformation}
                    </pre>
                    <br />
                    <br />

                </div>

                <div class="row wizard-report-table">
                    <div class="col-md-12">

                        <div class="row margin-bottom-20">
                            <h4 class="col-md-4">
                                <strong>{TextResources.Invoices.DocumentWizardCurrentDate}:</strong>&nbsp;<span data-bind={{ dateText: new Date() }}></span>
                            </h4>
                            <ko-bind data-bind={{ if : step.CustomerName() && step.CustomerName().trim().length > 0 }}>
                                <h4 class="col-md-4">
                                    <strong>{TextResources.Invoices.DocumentWizardCustomer}:</strong>&nbsp;<span data-bind={{ text : step.CustomerName }}></span>
                                </h4>
                            </ko-bind>
                            <ko-bind data-bind={{ if : step.JobOrderName() && step.JobOrderName().trim().length > 0 }}>
                                <h4 class="col-md-4">
                                    <strong>{TextResources.Invoices.DocumentWizardJobOrder}:</strong>&nbsp;<span data-bind={{ text : step.JobOrderName }}></span>
                                </h4>
                            </ko-bind>
                        </div>


                        <table class="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                    <th style="text-align : center">{TextResources.Invoices.DocumentWizardVAT}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardNetPrice}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if : step.Rows().length == 0 }}>
                                <tr>
                                    <td colSpan={5} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.Rows, as: 'reportRow' }}}>
                                    <ko-bind data-bind={{ with : reportRow.Row, as: 'row' }}>
                                    <tr>
                                        <td data-bind={{ text: row.Description }}></td>
                                        <td style="text-align : right" data-bind={{ numberText: row.Amount }}></td>
                                        <td style="text-align : right" data-bind={{ moneyText: row.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                        <td style="text-align : center" data-bind={{ text: row.VatCode }}></td>
                                        <td style="text-align : right" data-bind={{ moneyText: row.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                    </tr>
                                    </ko-bind>
                                </ko-bind>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4}>
                                        <strong>{TextResources.Invoices.DocumentWizardTotalPrice}</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong data-bind={{ moneyText : step.Total }}></strong>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>

            </div>
        , this, "step");
    }
}