import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/02/2018
 * Time: 15:20
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { EventCanceledMotivationsEditingViewModel } from "./ui/EventCanceledMotivationsEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IEventCanceledMotivationsSettingsManager } from "../../../../ProlifeSdk/interfaces/agenda/IEventCanceledMotivationsSettingsManager";
import { IEventCanceledMotivation } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class EventCanceledMotivationsSettingsManager implements IEventCanceledMotivationsSettingsManager {
    private motivations: IEventCanceledMotivation[] = [];

    private ajaxService: IAjaxService;
    private modulesService: IModulesService;

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, TextResources.Agenda.AgendaSettingsGroup);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<IEventCanceledMotivation[]> {
        return this.ajaxService.Get<IEventCanceledMotivation[]>("Agenda-api", "EventCanceledMotivations", {})
            .then((motivations: IEventCanceledMotivation[]) => {
                if (!motivations)
                    return motivations;
                this.motivations = motivations;
                return motivations;
            });
    }

    getName(): string {
        return ProlifeSdk.EventCanceledMotivationsSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Agenda.EventCanceledMotivationSettings;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.AgendaApplication);
    }

    getEditingUI(): IView {
        return {
            templateName: "event-canceled-motivations-editor",
            templateUrl: "agenda/templates/settings",
            viewModel: new EventCanceledMotivationsEditingViewModel(this.serviceLocator, this)
        };
    }

    getMotivations(): IEventCanceledMotivation[] {
        return this.motivations;
    }

    addOrUpdateMotivation(motivation: IEventCanceledMotivation): Promise<IEventCanceledMotivation> {
        var def = new Deferred<IEventCanceledMotivation>();
        this.ajaxService.Post("Agenda-api", "EventCanceledMotivations", { methodData: motivation })
            .then((responseObject: IEventCanceledMotivation) => {
                if (motivation.Id <= 0) {
                    this.motivations.push(responseObject);
                    def.resolve(responseObject);
                    return;
                }

                this.replaceMotivation(responseObject);
                def.resolve(responseObject);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    removeMotivation(id: number): Promise<IEventCanceledMotivation[]> {
        var def= new Deferred<IEventCanceledMotivation[]>();
        this.ajaxService.Delete("Agenda-api", "EventCanceledMotivations?id=" + id, {})
            .then((motivations: IEventCanceledMotivation[]) => {
                this.motivations = motivations;
                def.resolve(motivations);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    moveMotivation(id: number, newPosition: number): Promise<IEventCanceledMotivation[]> {
        var def = new Deferred<IEventCanceledMotivation[]>();
        this.ajaxService.Post("Agenda-api", "EventCanceledMotivations/MoveMotivation", { methodData: { MotivationId: id, NewPosition: newPosition } })
            .then((orderedMotivations: IEventCanceledMotivation[]) => {
                this.motivations = orderedMotivations;
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    private replaceMotivation(newMotivation: IEventCanceledMotivation): void {
        for (var i = 0; i < this.motivations.length; i++) {
            if (newMotivation.Id == this.motivations[i].Id) {
                this.motivations.splice(i, 1, newMotivation);
                break;
            }
        }
    }
}