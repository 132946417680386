import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { RevenuesDashboard } from "./RevenuesDashboard";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import {
    IJobOrderService,
    IEstimatedRevenuesForReportingHoursAndPurchases,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEstimatesService, IEstimateForList } from "../../../../ProlifeSdk/interfaces/invoice/IEstimatesService";
import { IOrdersService, ICustomerOrderForList } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IEstimatedRevenue } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IWorkflowRevenue } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowRevenue";
import { IVatRegisters } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { DocumentClosingStatusIndicatorUI } from "../../../../Components/DocumentClosingStatusIndicator";
import { ReactElement } from "@abstraqt-dev/jsxknockout";

type EstimatedRevenueRow = IEstimatedRevenue & { Icon?: DocumentClosingStatusIndicatorUI };

export class EstimatedRevenues {
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    public Rows: ko.ObservableArray<IEstimatedRevenue> = ko.observableArray([]);
    public WorkflowRows: ko.ObservableArray<IWorkflowRevenue> = ko.observableArray([]);
    public Total: ko.Computed<number>;
    public WorkflowTotal: ko.Computed<number>;

    public BillableWorkEstimatedRevenues: ko.Observable<number> = ko.observable();

    public EstimatedRevenuesForReportingHoursAndPurchases: ko.Observable<IEstimatedRevenuesForReportingHoursAndPurchases> =
        ko.observable();

    private jobOrderId: number;
    private selectedWorkflow: number;

    public ShowWorkflowDetails: ko.Observable<boolean> = ko.observable(false);

    constructor(private revenuesDashboard: RevenuesDashboard) {
        this.Total = ko.computed(() => {
            let total: number = this.BillableWorkEstimatedRevenues();
            this.Rows().forEach((r: IEstimatedRevenue) => {
                total += r.Revenues;
            });

            if (this.EstimatedRevenuesForReportingHoursAndPurchases())
                total += this.EstimatedRevenuesForReportingHoursAndPurchases().SellPrice;

            return total;
        });

        this.WorkflowTotal = ko.computed(() => {
            const economicsData = this.revenuesDashboard.provider.EconomicsData();
            if (!economicsData) return 0;

            return (
                economicsData.ArticlesAssignedEstimatedRevenues +
                economicsData.PurchasesAssignedEstimatedRevenues +
                economicsData.HoursAssignedEstimatedRevenues +
                economicsData.OthersEstimatedAssignedRevenues
            );
        });
    }

    public async Initialize(jobOrderId: number, workflowId: number): Promise<void> {
        this.jobOrderId = jobOrderId;
        this.selectedWorkflow = workflowId;

        const rev = await this.jobOrderService.GetJobOrderEstimatedRevenues(jobOrderId, workflowId);

        this.Rows(rev.EstimatedRevenues.map(this.createEstimatedRevenueRow, this));
        this.BillableWorkEstimatedRevenues(rev.BillableWorkEstimatedRevenues ?? 0);
        this.ShowWorkflowDetails(!!this.selectedWorkflow);

        /*if(!this.selectedWorkflow) {
            this.WorkflowRows([]);
        } else {
            let rows = await this.todoListService.GetWorkflowsRevenuesForWorkflow(workflowId)
            this.WorkflowRows(rows.filter(r => r.IsEstimatedRevenue));
        }*/

        const revenues = await this.jobOrderService.GetEstimatedRevenuesForReportingHoursAndPurchases(
            this.jobOrderId,
            this.selectedWorkflow
        );
        if (!revenues) return;
        this.EstimatedRevenuesForReportingHoursAndPurchases(revenues);
    }

    private createEstimatedRevenueRow(er: IEstimatedRevenue): IEstimatedRevenue {
        const extEr = er as EstimatedRevenueRow;

        if (er.FKRegister) {
            extEr.Icon = new DocumentClosingStatusIndicatorUI({
                documentId: er.FkDocument,
                documentType: er.Type,
                protocolId: er.FKRegister,
                documentState: er.FKEstimateState,
                documentClosingState: er.ClosureStatus,
                documentCauseLogicType: er.CauseLogicType,

                className: "btn-xs",
                content: er.Number as unknown as ReactElement,
            });
        }

        return extEr;
    }

    public Edit() {
        const vm: EstimatedRevenuesEditor = new EstimatedRevenuesEditor(this.jobOrderId, this.Rows());
        this.dialogService
            .ShowModal<boolean>(vm, "fullscreen", {}, vm.templateUrl, vm.templateName)
            .then((result: boolean) => {
                if (!result) return;
                this.Initialize(this.jobOrderId, this.selectedWorkflow);
                this.revenuesDashboard.refreshGraphics();
            });
    }
}

export class EstimatedRevenuesEditor implements IDialog {
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IEstimatesService>())
    private estimatesService: IEstimatesService;
    @LazyImport(nameof<IOrdersService>())
    private ordersService: IOrdersService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisterSettings: IVatRegisters;

    public templateName = "estimated-revenues-dialog";
    public templateUrl = "joborder/templates/joborderdetail";
    public title: string = ProlifeSdk.TextResources.JobOrder.ExpectedRevenues;
    public modal: { close: (result?: any) => void };

    public Estimates: ko.ObservableArray<EstimatedRevenue> = ko.observableArray([]);
    public CustomerOrders: ko.ObservableArray<EstimatedRevenue> = ko.observableArray([]);
    public OtherRows: ko.ObservableArray<EstimatedRevenue> = ko.observableArray([]);

    constructor(private jobOrderId: number, currentRows: IEstimatedRevenue[]) {
        //Caricamento dei documenti...
        this.estimatesService.GetJobOrderEstimatesForList(jobOrderId).then((estimates: IEstimateForList[]) => {
            estimates.sort((a, b) => <any>moment(b.Date) - <any>moment(a.Date));

            const estimatesRows: EstimatedRevenue[] = estimates.map((e: IEstimateForList) => {
                const matches: IEstimatedRevenue[] = currentRows.filter((r: IEstimatedRevenue) => {
                    return r.FkDocument == e.Id;
                });
                const model: IEstimatedRevenue =
                    matches.length > 0
                        ? matches[0]
                        : {
                              Type: ProlifeSdk.EstimateEntityTypeCode,
                              SubType: null,
                              Title: "",
                              Amount: 1,
                              UnitPrice: e.Total,
                              FkDocument: e.Id,
                              Revenues: e.Total,
                              ReferenceDate: e.Date,
                          };

                //var url = this.estimatesService.getDocumentDetailUrl(e.Id, e.FKRegistro)

                const vatRegister = this.vatRegisterSettings.getVatRegisterById(e.FKRegistro);
                model.Title = String.format(
                    ProlifeSdk.TextResources.JobOrder.QuoteName,
                    vatRegister.NomeRegistroIVA,
                    e.Number,
                    moment(e.Date).format("L")
                );

                const isSelected: boolean = matches.length > 0;
                return new EstimatedRevenue(model, isSelected, e.Id, ProlifeSdk.EstimateEntityTypeCode);
            });
            this.Estimates(estimatesRows);
        });

        this.ordersService.GetJobOrderCustomerOrdersForList(jobOrderId).then((orders: ICustomerOrderForList[]) => {
            orders.sort((a, b) => <any>moment(b.Date) - <any>moment(a.Date));

            const ordersRows: EstimatedRevenue[] = orders.map((co: ICustomerOrderForList) => {
                const matches: IEstimatedRevenue[] = currentRows.filter((r: IEstimatedRevenue) => {
                    return r.FkDocument == co.Id;
                });
                const model: IEstimatedRevenue =
                    matches.length > 0
                        ? matches[0]
                        : {
                              Type: ProlifeSdk.CustomerOrderEntityTypeCode,
                              SubType: null,
                              Title: "",
                              FkDocument: co.Id,
                              Amount: 1,
                              UnitPrice: co.Total,
                              Revenues: co.Total,
                              ReferenceDate: co.Date,
                          };

                //var url = this.documentsService.GetOpenDocumentURL(ProlifeSdk.CustomerOrderEntityTypeCode, co.Id);//this.ordersService.getCustomerOrderDetailUrl(co.Id, co.FKRegistro);

                const vatRegister = this.vatRegisterSettings.getVatRegisterById(co.FKRegistro);
                model.Title = String.format(
                    ProlifeSdk.TextResources.JobOrder.CustomerOrderName,
                    vatRegister.NomeRegistroIVA,
                    co.Number,
                    moment(co.Date).format("L")
                );

                const isSelected: boolean =
                    currentRows.filter((r: IEstimatedRevenue) => {
                        return r.FkDocument == co.Id;
                    }).length > 0;
                return new EstimatedRevenue(model, isSelected, co.Id, ProlifeSdk.CustomerOrderEntityTypeCode);
            });
            this.CustomerOrders(ordersRows);
        });

        this.OtherRows(
            currentRows
                .filter((r: IEstimatedRevenue) => {
                    return r.Type == "OTH";
                })
                .map((r: IEstimatedRevenue) => {
                    return new EstimatedRevenue(r, true, -1, r.Type);
                })
        );
    }

    private GetData() {
        const estimatedRevenues: IEstimatedRevenue[] = this.Estimates()
            .filter((e: EstimatedRevenue) => {
                return e.IsSelected();
            })
            .map((e: EstimatedRevenue) => {
                return e.GetData();
            });

        this.CustomerOrders()
            .filter((e: EstimatedRevenue) => {
                return e.IsSelected();
            })
            .map((e: EstimatedRevenue) => {
                return e.GetData();
            })
            .forEach((e: IEstimatedRevenue) => {
                estimatedRevenues.push(e);
            });

        this.OtherRows()
            .map((e: EstimatedRevenue) => {
                return e.GetData();
            })
            .forEach((e: IEstimatedRevenue) => {
                estimatedRevenues.push(e);
            });

        return estimatedRevenues;
    }

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        this.jobOrderService.UpdateJobOrderEstimatedRevenues(this.jobOrderId, this.GetData()).then(() => {
            this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.JobOrderEstimatedRevenuesSaved);
            this.modal.close(true);
        });
    }

    public RemoveRow(row: EstimatedRevenue) {
        this.OtherRows.remove(row);
    }

    public AddNewRow() {
        this.OtherRows.push(
            new EstimatedRevenue(
                {
                    Type: "OTH",
                    SubType: "OTH",
                    Title: "",
                    Revenues: 0,
                    Amount: 1,
                    UnitPrice: 0,
                    ReferenceDate: new Date(),
                },
                true,
                -1,
                "OTH"
            )
        );
    }

    public RectifyTaskReportingState() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.JobOrder.ConfirmRectifyTaskReportingState,
            ProlifeSdk.TextResources.JobOrder.DoNotConfirmRectifyTaskReportingState,
            ProlifeSdk.TextResources.JobOrder.DoConfirmRectifyTaskReportingState,
            (result: boolean) => {
                if (!result) return;

                this.jobOrderService.RectifyTaskReportingState(this.jobOrderId).then(() => {
                    this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.RectifyTaskReportingStateSucceded);
                });
            }
        );
    }
}

export class EstimatedRevenue {
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    public IsSelected: ko.Observable<boolean> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public UnitPrice: ko.Observable<number> = ko.observable();
    public Revenues: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public SubType: ko.Observable<string> = ko.observable();
    public ReferenceDate: ko.Observable<Date> = ko.observable();

    public IsDocument: ko.Observable<boolean> = ko.observable(false);

    public SubTypes: any[] = [
        { Id: "OTH", Description: ProlifeSdk.TextResources.JobOrder.Other },
        { Id: ProlifeSdk.EstimatedWorkEntityTypeCode, Description: ProlifeSdk.TextResources.JobOrder.WorkEntity },
        { Id: ProlifeSdk.EstimatedPurchaseEntityTypeCode, Description: ProlifeSdk.TextResources.JobOrder.Purchases },
    ];

    constructor(
        private estimatedRevenue: IEstimatedRevenue,
        initialSelection: boolean,
        private id: number,
        private type: string
    ) {
        this.Revenues(estimatedRevenue.Revenues);
        this.UnitPrice(estimatedRevenue.UnitPrice);
        this.Amount(estimatedRevenue.Amount);
        this.Title(estimatedRevenue.Title);
        this.SubType(estimatedRevenue.SubType);
        this.ReferenceDate(estimatedRevenue.ReferenceDate);

        this.IsDocument(
            [ProlifeSdk.EstimateEntityTypeCode, ProlifeSdk.CustomerOrderEntityTypeCode].indexOf(type) !== -1
        );

        this.IsSelected(initialSelection);

        this.Amount.subscribe(this.CalculateRevenues.bind(this));
        this.UnitPrice.subscribe(this.CalculateRevenues.bind(this));

        if (this.modulesService.IsModuleEnabled(ProlifeSdk.WarehouseApplicationCode))
            this.SubTypes.push({ Id: "WAR", Description: ProlifeSdk.TextResources.JobOrder.WarehouseArticles });
    }

    public OpenDocument() {
        return this.documentsService.OpenDocumentOverlay(this.type, this.id);
    }

    private CalculateRevenues() {
        this.Revenues((this.UnitPrice() || 0) * (this.Amount() || 0));
    }

    public GetData() {
        const data: IEstimatedRevenue = this.estimatedRevenue;
        data.Revenues = this.Revenues();
        data.Title = this.Title();
        data.SubType = this.SubType();
        data.UnitPrice = this.UnitPrice();
        data.Amount = this.Amount();
        data.ReferenceDate = this.ReferenceDate();
        return data;
    }
}
