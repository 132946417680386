import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { HumanResourcesSettingsManager } from "./Users/Settings/HumanResourcesSettingsManager";
import { ResourcesGroupsSettingsManager } from "./Users/Settings/ResourcesGroupsSettingsManager";
import { HumanResourceEntityProvider } from "./Users/EntityProviders/HumanResourceEntityProvider";
import { ResourcesGroupEntityProvider } from "./Users/EntityProviders/ResourcesGroupEntityProvider";
import { ResourceRoleRefDetailsViewModelFactory } from "./Users/documents/providers/ResourceRoleRefDetailsViewModelFactory";
import { LazyImport } from "../Core/DependencyInjection";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IResourcesGroup } from "../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

export interface IGetUserCharacterMeanCostResponse {
    MeanCost?: number;
}

export interface IGetUserCharacterMeanCostRequest {
    UserCharacterId?: number;
    referenceDate?: Date;
}

export interface IHumanResourceOrdersWithSplitInfo extends IHumanResourceOrders {
    SplitFrom?: number;
}

export interface IHumanResourceWithSplitInfo extends IHumanResource {
    Orders : IHumanResourceOrdersWithSplitInfo[];
}

export interface IGetHumanResourcesForListRequest {
    textFilter?: string;
    showDisabled?: boolean;
    showDeleted?: boolean;
    resourceType?: number;
    skip?: number;
    count?: number;
}

export interface IHumanResourceForList {
    Id : number;
    Name?: string;
    Surname?: string;
    FkUser?: number;
    FkDefaultCharacter : number;
    Deleted : boolean;
    Disabled : boolean;
    ResourceType : number;
    ShowPreferred : boolean;
    SerialNumber?: string;
}

export interface IGetHumanResourcesRequest {
    textFilter?: string;
    includeDeleted?: boolean;
    includeDisabled?: boolean;
    includeMaterialResources?: boolean;
    withServiceOrdersAtDate?: Date;
    skip?: number;
    count?: number;
}

export interface IHumanResourceResource {
    Id : number;
    Name?: string;
    Surname?: string;
    FkUser?: number;
    FkDefaultCharacter : number;
    Deleted : boolean;
    Disabled : boolean;
    ResourceType : number;
    ShowPreferred : boolean;
    SerialNumber?: string;
}

export interface IHumanResourceOrders {
    Id : number;
    FromDate : Date;
    ToDate?: Date;
    FkResource : number;
    FkOperationalUnit : number;
    Title?: string;
    Notes?: string;
    HoursMonday : number;
    HoursTuesday : number;
    HoursWednesday : number;
    HoursThursday : number;
    HoursFriday : number;
    HoursSaturday : number;
    HoursSunday : number;
    EstimatedHoursToWork : number;
    MaxFlexiblePositive? : number;
    MaxFlexibleNegative? : number;
}

export interface IHumanResourceOrdersWorkingHours {
    Id : number;
    ServiceOrderId : number;
    DayOfWeek : number;
    Start : string;
    End : string;
    Duration : number;
}

export interface IHumanResourceOrdersRoles {
    FkServiceOrder : number;
    FkCharacter : number;
    IncludeInMeanCostCalculations : boolean;
    IsDefault : boolean;
}

export interface IHumanResourceOrdersSalaries {
    FkServiceOrder : number;
    FkWorkTimeCategoryId : number;
    HourSalary : number;
    IsDefault : boolean;
    MinAnnualHoursAmount?: number;
    MaxAnnualHoursAmount?: number;
    OffsetMaxAnnualHoursAmount?: number;
    MinAnnualHoursAmountBehaviour?: number;
    MaxAnnualHoursAmountBehaviour?: number;
}

export interface IHumanResource {
    Resource : IHumanResourceResource;
    Orders : IHumanResourceOrders[];
    OrdersWorkingHours : IHumanResourceOrdersWorkingHours[];
    OrdersRoles : IHumanResourceOrdersRoles[];
    OrdersSalaries : IHumanResourceOrdersSalaries[];
}

export interface IHumanResourcesService extends IService {
    getFullName(resource : IHumanResource | IHumanResourceForList, showId?: boolean) : string;
    GetResourcesGroups(filter?: string) : Promise<IResourcesGroup[]>;
    GetResourcesGroup(id : number) : Promise<IResourcesGroup>;
    GetUserCharacterMeanCost(request : IGetUserCharacterMeanCostRequest) : Promise<number>;

    InsertOrUpdateHumanResource(resource : IHumanResourceWithSplitInfo) : Promise<IHumanResource>;
    DeleteHumanResource(id : number) : Promise<void>;
    RestoreHumanResource(id : number) : Promise<void>;
    GetHumanResourcesForList(request : IGetHumanResourcesForListRequest) : Promise<IHumanResourceForList[]>;
    GetHumanResourcesForListByIds(ids : number[] | null) : Promise<IHumanResourceForList[]>;
    GetHumanResources(filter?: string, includeDeleted?: boolean, includeDisabled?: boolean, includeMaterialResources?: boolean, withServiceOrderAtDate?: Date, skip?: number, count?: number) : Promise<IHumanResource[]>;
    GetHumanResourceByUserId(id : number | null) : Promise<IHumanResource>;
    GetHumanResource(id : number | null) : Promise<IHumanResource>;
}

export class HumanResourcesService implements IHumanResourcesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService : IEntityProviderService;

    constructor (serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IHumanResourcesService>(), this);
    }

    InitializeService() {
        new HumanResourcesSettingsManager();
        new HumanResourceEntityProvider();
        new ResourcesGroupsSettingsManager();
        new ResourcesGroupEntityProvider();

        this.entitiesService.RegisterEntity(<IEntityDescriptor> {
            EntityCode: ProlifeSdk.EstimatedWorkEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Users.ProfessionalFigure,
            PluralEntityName: ProlifeSdk.TextResources.Users.ProfessionalFigures
        });

        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.EstimatedWorkEntityTypeCode,
            new ResourceRoleRefDetailsViewModelFactory());
    }

    getServiceType() : string {
        return ProlifeSdk.HumanResourcesServiceType;
    }

    isOfType(serviceType : string) : boolean {
        return serviceType == this.getServiceType();
    }

    GetResourcesGroups(filter?: string) : Promise<IResourcesGroup[]> {
        return this.ajaxService.Post("Users-api/ResourcesGroups", "DetailedSearch", {
            methodData: {
                HintSearch: filter
            }
        });
    }

    GetResourcesGroup(id : number) : Promise<IResourcesGroup> {
        return this.ajaxService.Get("Users", "ResourcesGroups/" + id, {});
    }

    private isHumanResource(humanResource : IHumanResource | IHumanResourceForList) : humanResource is IHumanResource {
        return humanResource && humanResource.hasOwnProperty("Resource") && !!(humanResource as IHumanResource).Resource;
    }

    private isHumanResourceForList(humanResource : IHumanResource | IHumanResourceForList) : humanResource is IHumanResourceForList {
        return humanResource && humanResource.hasOwnProperty("Id");
    }

    getFullName(humanResource : IHumanResource | IHumanResourceForList, showId : boolean = false) : string {
        let fullName = "";
        let resource : { Id : number; Name?: string; Surname?: string; };

        if (this.isHumanResource(humanResource))
        {
            resource = humanResource.Resource;
        } else if (this.isHumanResourceForList(humanResource))
        {
            resource = humanResource;
        } else
            return ProlifeSdk.TextResources.Users.UnavailableResource;

        if (resource.Id <= 0)
        {
            fullName = ProlifeSdk.TextResources.Users.NewResource;
        } else
        {
            fullName = (resource.Name ?? "");
            fullName = fullName == "" ? "" : fullName + " ";
            fullName = fullName + (resource.Surname ?? "");
            fullName = fullName || ProlifeSdk.TextResources.Users.UnnamedResource;
        }

        if (showId)
        {
            fullName = fullName + " (id: " + resource.Id + ")";
        }

        return fullName;
    }

    GetUserCharacterMeanCost(request : IGetUserCharacterMeanCostRequest) : Promise<number> {
        return this.ajaxService.Post<number>("Users-api/UserCharacters", "GetUserCharacterMeanCost", {
            methodData: request
        });
    }

    GetHumanResourcesForList(request : IGetHumanResourcesForListRequest) : Promise<IHumanResourceForList[]> {
        let result = this.ajaxService.Post<IHumanResourceForList[]>("Users-api/HumanResources", "GetHumanResourcesForList", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetHumanResourcesForListByIds(ids : number[] | null) : Promise<IHumanResourceForList[]> {
        let result = this.ajaxService.Post<IHumanResourceForList[]>("Users-api/HumanResources", "GetHumanResourcesForListByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    InsertOrUpdateHumanResource(resource : IHumanResourceWithSplitInfo) : Promise<IHumanResource> {
        let result = this.ajaxService.Post<IHumanResource>("Users-api/HumanResources", "CreateOrUpdateHumanResource", {
            background: true,
            methodData: resource
        });
        return result;
    }

    DeleteHumanResource(id : number) : Promise<void> {
        let result = this.ajaxService.Post<void>("Users-api/HumanResources", "DeleteHumanResource", {
            background: true,
            methodData: {
                id: id
            }
        });
        return result;
    }

    RestoreHumanResource(id : number) : Promise<void> {
        let result = this.ajaxService.Post<void>("Users-api/HumanResources", "RestoreHumanResource", {
            background: true,
            methodData: {
                id: id
            }
        });
        return result;
    }

    GetHumanResources(filter?: string, includeDeleted?: boolean, includeDisabled?: boolean, includeMaterialResources?: boolean, withServiceOrderAtDate?: Date, skip?: number, count?: number) : Promise<IHumanResource[]> {
        let result = this.ajaxService.Post<IHumanResource[]>("Users-api/HumanResources", "GetHumanResources", {
            background: true,
            methodData: {
                HintSearch: filter,
                IncludeDeleted: includeDeleted,
                IncludeDisabled: includeDisabled,
                IncludeMaterialResources: includeMaterialResources,
                WithServiceOrderAtDate: withServiceOrderAtDate,
                Skip: skip,
                Count: count
            }
        });



        return result;
    }

    GetHumanResourceByUserId(id : number | null) : Promise<IHumanResource> {
        let result = this.ajaxService.Post<IHumanResource>("Users-api/HumanResources", "GetHumanResourceByUserId", {
            background: true,
            methodData: {
                id: id,
            }
        });



        return result;
    }

    GetHumanResource(id: number | null): Promise<IHumanResource> {
        let result = this.ajaxService.Post<IHumanResource>("Users-api/HumanResources", "GetHumanResource", {
            background: true,
            methodData: {
        		id: id,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new HumanResourcesService(serviceLocator);
}
