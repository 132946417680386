import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { Layout } from "../../../../Components/Layouts";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { NavigationMenu } from "../../../../Components/NavigationMenu";
import { IMailsMenuDataSourceModel, MailsMenuDataSource } from "../../../../DataSources/MailsMenuDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
    INavigationMenuComponentActionsGroup,
    INavigationMenuComponentModel,
    INavigationMenuComponentTemplatesProvider,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IEventBaseData, IExtendedBlogMailData, IMailEventsGeneratorData, IMailsService } from "../../../MailsService";
import { ITreeNode, TreeNodeProps, TreeView } from "../../../../Components/TreeView";
import { JobOrder } from "./JobOrder";
import { RichTextInput } from "../../../../Components/RichTextInput";
import { TextInput } from "../../../../Components/TextInput";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import {
    EventClassificationInput,
    _EventClassificationInput,
} from "../../../../ProlifeSdk/prolifesdk/blog/EventClassificationInput";
import { AttachmentsManager } from "../../../../Components/AttachmentsManager";
import { Attachment } from "../../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { AlertsLegendUI } from "../../../../Components/AlertsLegend";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import {
    IJobOrderForTaskBoard,
    ITaskForTaskBoard,
    ITodoListService,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IJobOrderForTaskBoardDataSourceModel,
    ITaskForTaskBoardDataSourceModel,
    IWorkflowForTaskBoardDataSourceModel,
    JobOrdersWorkflowsAndTasksDataSource,
} from "../../../../DataSources/JobOrdersWorkflowsAndTasksDataSource";
import { WorkflowsMenuSearchModes } from "../../../../Todolist/Todolist/enums/WorkflowsMenuSearchModes";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { SelectMultiple } from "../../../../Components/SelectMultiple";
import { CustomTagsDataSource } from "../../../../DataSources/CustomTagsDataSource";
import { ITag } from "../../../../ProlifeSdk/interfaces/ITag";
import { DetectChanges, DetectClassChanges } from "../../../../Core/ChangeDetection";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ConfirmedTasksStatus, WorkflowStatusAfterSaveDialog } from "../../EventsDetails/WorkflowStatusAfterSaveDialog";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

const styleSheet = jss.createStyleSheet({
    mailEventsGeneratorDialog: {
        "& .modal-body": {
            padding: 0,
        },
    },
    mailEventsGenerator: {
        "& .navigation-menu-wrapper": {
            position: "relative",

            "& .page-sidebar": {
                position: "absolute !important",
                inset: 0,
                margin: "0 !important",
                width: "auto",

                "& .page-quick-sidebar-wrapper": {
                    position: "absolute",
                    inset: 0,
                },

                "& .mail": {
                    "& .first-column": {
                        maxWidth: "calc(100% - 70px)",

                        "& .mail-sender": {
                            color: "white",
                            fontSize: "15px",
                        },

                        "& .mail-subject": {
                            color: "white",
                            fontSize: "13px",
                        },

                        "& .mail-content-preview": {
                            fontSize: "11px",
                        },
                    },

                    "& .second-column": {
                        maxWidth: "70px",
                        minWidth: "70px",

                        "& .mail-datetime": {
                            fontSize: "11px",
                        },

                        "& .mail-actions": {
                            paddingTop: "5px",
                        },
                    },
                },
            },
        },

        "& .selected-activities": {
            backgroundColor: "#eee",

            "& h4": {
                paddingLeft: "5px",
            },

            "& .jstree": {
                "&.jstree-default": {
                    "& .jstree-node, .jstree-icon": {
                        backgroundImage: "none",
                    },
                },

                "& > .jstree-container-ul": {
                    "& > .jstree-node": {
                        "&:not(:first-child)": {
                            marginTop: "10px",
                        },

                        "& > .custom-treeview-node": {
                            "& .node-name": {
                                fontWeight: 700,
                                fontSize: "14px",
                            },
                        },
                    },
                },
            },

            "& .custom-treeview-node": {
                position: "relative",
                padding: "3px 0px 3px 20px",

                "& .switch": {
                    position: "absolute",
                    top: "7px",
                    left: "0px",
                },

                "& .sub-title": {
                    display: "block",
                    lineHeight: 1,
                },
            },
        },

        "& .form": {
            "& .btn": {
                "&.disabled": {
                    cursor: "not-allowed",
                },
            },

            "& .row": {
                marginLeft: 0,
                marginRight: 0,
            },

            "& .priority": {
                "& .btn": {
                    "&.blue, &:hover": {
                        backgroundColor: "#3276b1 !important",
                        borderColor: "#2a6496 !important",
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type MailEventsGeneratorProps = {
    forwardRef: (eventsGenerator: _MailEventsGenerator) => void;
};

export class MailEventsGeneratorDialog extends DialogComponentBase {
    static defaultProps: Partial<MailEventsGeneratorProps> = {};

    private eventsGenerator: _MailEventsGenerator;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IMailsService>())
    private mailsService: IMailsService;

    constructor() {
        super({ className: "fullscreen " + classes.mailEventsGeneratorDialog });
        this.title(TextResources.Blog.MailEventsGeneratorTitle);
    }

    async uploadMails(files: File[]): Promise<void> {
        const currentUserId = this.userInfo.getIdUser();

        for (const file of files) {
            const data = {
                mail: file,
                user: currentUserId,
            };

            const uploadDef = this.mailsService.MailUploadOnTemporaryStorage(data);
            this.desktopService.AddUploadNotification("E-Mail", uploadDef);

            try {
                await uploadDef;
                this.eventsGenerator.refreshMails();
            } catch (e) {
                console.log(e);
            }
        }
    }

    action() {
        this.eventsGenerator.generateEvents();
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return <MailEventsGenerator forwardRef={(eventsGenerator) => (this.eventsGenerator = eventsGenerator)} />;
    }
}

export function MailEventsGenerator(props: MailEventsGeneratorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./MailEventsGenerator")._MailEventsGenerator as typeof _MailEventsGenerator;
    return <C {...props} />;
}

@DetectClassChanges
export class _MailEventsGenerator implements INavigationMenuComponentTemplatesProvider, IDataSourceListener {
    public isChanged: ko.Observable<number> = ko.observable(0);

    private jobOrdersNavigationMenuDataSource: JobOrdersWorkflowsAndTasksDataSource =
        new JobOrdersWorkflowsAndTasksDataSource();
    private mailsNavigationMenuDataSource: MailsMenuDataSource = new MailsMenuDataSource();

    private contactsDataSource: CustomTagsDataSource = new CustomTagsDataSource();

    private jobOrdersNavigationMenu: INavigationMenuComponent;
    private mailsNavigationMenu: INavigationMenuComponent;
    private eventClassificationInput: _EventClassificationInput;

    private SelectedActivitiesTreeViewRoots: ko.Computed<TreeNodeProps[]>;
    private SelectedJobOrders: ko.ObservableArray<JobOrder> = ko.observableArray([]);
    private SeletcedMails: ko.ObservableArray<IExtendedBlogMailData> = ko.observableArray([]);
    private MultipleMails: ko.Observable<boolean> = ko.observable(false);
    private LockMailFields: ko.Observable<boolean> = ko.observable(true);

    @DetectChanges
    private BlogEventSubject: ko.Observable<string> = ko.observable();

    @DetectChanges
    private MailDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    private MailSubject: ko.Observable<string> = ko.observable();
    @DetectChanges
    private MailBody: ko.Observable<string> = ko.observable("");
    @DetectChanges
    private MailSenders: ko.ObservableArray<string> = ko.observableArray([]);
    @DetectChanges
    private MailRecipients: ko.ObservableArray<string> = ko.observableArray([]);
    @DetectChanges
    private MailCcRecipients: ko.ObservableArray<string> = ko.observableArray([]);
    @DetectChanges
    private MailBccRecipients: ko.ObservableArray<string> = ko.observableArray([]);
    @DetectChanges
    private MailAttachments: ko.ObservableArray<Attachment> = ko.observableArray([]);

    private MailAttachmentsBasePath: ko.Observable<string> = ko.observable();

    private MailBodyTrigger: ko.Observable<boolean> = ko.observable(true);

    private ShowingSecondaryActionsOnMenu = ko.observable(false);
    private IsShowingWorkflowsOnMenu = ko.observable(false);
    private IsShowingTasksOnMenu = ko.observable(false);

    private MailDatePlaceholder: ko.Computed<string>;
    private MailBodyPlaceholder: ko.Computed<string>;
    private MailSubjectPlaceholder: ko.Computed<string>;
    private MailSendersPlaceholder: ko.Computed<string>;
    private MailRecipientsPlaceholder: ko.Computed<string>;
    private MailCcRecipientsPlaceholder: ko.Computed<string>;
    private MailBccRecipientsPlaceholder: ko.Computed<string>;
    private MailAttachmentsText: ko.Computed<string>;

    private selectedJobOrder: number;
    private selectedWorkflow: number;

    private validator: IValidator<_MailEventsGenerator>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IMailsService>())
    private mailsService: IMailsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private props: MailEventsGeneratorProps) {
        this.SelectedActivitiesTreeViewRoots = ko.computed(() => {
            const selectedJobOrders = this.SelectedJobOrders();
            return selectedJobOrders.map(this.createTreeViewRootProps, this);
        });

        this.jobOrdersNavigationMenuDataSource.initializeAdvancedFilters();
        this.contactsDataSource.setFreeTextEnabled(true);
        this.contactsDataSource.setEnabledProviders(
            ProlifeSdk.CustomerEntityType,
            ProlifeSdk.HumanResources,
            ProlifeSdk.TagType_String
        );

        this.MailDatePlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailDatePlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailBodyPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailBodyPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailSubjectPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailSubjectPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailSendersPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailSendersPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailRecipientsPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailRecipientsPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailCcRecipientsPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailCcRecipientsPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailBccRecipientsPlaceholder = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.Blog.MailBccRecipientsPlaceholder : TextResources.Blog.MultipleMails;
        });

        this.MailAttachmentsText = ko.computed(() => {
            const multipleMails = this.MultipleMails();
            return !multipleMails ? TextResources.ProlifeSdk.EmptyAttachmentsList : TextResources.Blog.MultipleMails;
        });

        this.configureValidation();

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    private configureValidation() {
        this.validator = this.validationService.createValidator<_MailEventsGenerator>();

        this.validator
            .isNotNullOrUndefinedOrWhiteSpace((d) => d.BlogEventSubject(), TextResources.Blog.MissingBlogEventSubject)
            .isNotNullOrUndefined(
                (d) => d.MailDate(),
                TextResources.Blog.MissingBlogEventMail,
                (d) => (d.SeletcedMails() ?? []).length === 1
            )
            .isFalse((d) => (d.SeletcedMails() ?? []).length === 0, TextResources.Blog.MissingSelectedMails)
            .isFalse((d) => (d.SelectedJobOrders() ?? []).length === 0, TextResources.Blog.MissingSelectedJobOrders);
    }

    public async generateEvents(): Promise<void> {
        if (!this.isValid()) return;

        const data = this.getData();
        const selectedActivities = data.SelectedActivities.selectMultiple((j) => j.Activities).map((t) => t);
        let tasksStatus: ConfirmedTasksStatus = null;

        if (selectedActivities.length > 0) {
            const dialog = new WorkflowStatusAfterSaveDialog({ tasksIds: selectedActivities });
            tasksStatus = await dialog.show();

            if (!tasksStatus.Confirm) return;
        }

        try {
            await this.mailsService.GenerateMailBlogEvents(data);
            if (tasksStatus) await this.todoListService.UpdateTasksStatus(tasksStatus.TasksStatus);

            this.clear();
        } catch (e) {
            console.log(e);
        }
    }

    private isValid() {
        const validationResult = this.validator.validate(this);
        const error = validationResult.firstOrDefault((vr) => !vr.valid);

        if (error) {
            this.infoToastService.Error(error.message);
            return false;
        }

        return true;
    }

    public refreshMails(): void {
        this.mailsNavigationMenuDataSource.refresh();
    }

    private clear() {
        this.BlogEventSubject(null);

        this.clearMailData();

        this.mailsNavigationMenu.select();
        this.mailsNavigationMenu.reset();

        this.jobOrdersNavigationMenu.select();
        this.jobOrdersNavigationMenu.reset();

        this.resetIsChangedStatus();
    }

    private getData(): IMailEventsGeneratorData {
        const selectedMails = this.SeletcedMails();
        const selectedActivities = this.SelectedJobOrders().map((sj) => sj.getData());

        const eventBaseData: IEventBaseData = {
            Subject: this.BlogEventSubject(),
            Date: this.MailDate(),
            Tags: this.eventClassificationInput.getTags(),
        };

        return {
            SelectedMails: selectedMails,
            SelectedActivities: selectedActivities,
            MailSubject: this.MailSubject(),
            MailBody: this.MailBody(),
            MailDate: this.MailDate(),
            MailSenders: this.MailSenders().map((s) => this.createMailContactTag(s, ProlifeSdk.TagEmail_From)),
            MailTo: this.MailRecipients().map((s) => this.createMailContactTag(s, ProlifeSdk.TagEmail_To)),
            MailCc: this.MailCcRecipients().map((s) => this.createMailContactTag(s, ProlifeSdk.TagEmail_Cc)),
            MailBcc: this.MailBccRecipients().map((s) => this.createMailContactTag(s, ProlifeSdk.TagEmail_Ccn)),
            MailAttachments: this.MailAttachments().map((a) => this.createMailAttachmentTag(a)),
            EventBaseData: eventBaseData,
        };
    }

    private createMailAttachmentTag(attachment: Attachment): ITag {
        return {
            TagName: ProlifeSdk.Tag_File,
            TagTypeId: ProlifeSdk.TagType_DocumentReference,
            Value: attachment.id,
            DisplayName: attachment.fileName,
        };
    }

    private createMailContactTag(serializedTag: string, tagName: string): ITag {
        const tagPart = serializedTag.split("#")[0];
        const tag = JSON.parse(tagPart) as ITag;
        tag.TagName = tagName;
        return tag;
    }

    private createTreeViewRootProps(jobOrder: JobOrder): TreeNodeProps {
        return {
            selected: false,
            expanded: true,
            children: [],
            name: jobOrder.Name,
            onSelect: (node) => {},
            onChildrenNeeded: async () => {
                return jobOrder.SelectedActivities().map(this.createTreeViewActivityProps, this);
            },
            dependencies: [jobOrder.SelectedActivities],
        };
    }

    private createTreeViewActivityProps(activity: ITaskForTaskBoard): TreeNodeProps {
        return {
            selected: false,
            expanded: true,
            children: [],
            name: activity.Title,
            subTitle: activity.WorkflowTitle,
            onSelect: (node) => {},
        };
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.jobOrdersNavigationMenuDataSource)
            this.handleActivityOrJobOrderSelection(
                model as ITaskForTaskBoardDataSourceModel | IJobOrderForTaskBoardDataSourceModel
            );
        else this.handleMailSelection(model.model);
    }

    private handleActivityOrJobOrderSelection(
        model: ITaskForTaskBoardDataSourceModel | IJobOrderForTaskBoardDataSourceModel
    ) {
        const jobOrder = model as IJobOrderForTaskBoardDataSourceModel;

        if (jobOrder.isJobOrder) this.handleJobOrderSelection(jobOrder.model);
        else this.handleActivitySelection(model.model as ITaskForTaskBoard);
    }

    private handleJobOrderSelection(jobOrder: IJobOrderForTaskBoard) {
        const jobOrderAlreadySelected = !!this.findJobOrder(jobOrder.Id);
        if (!jobOrderAlreadySelected) this.addNewJobOrder(jobOrder.Id, jobOrder.Name);
    }

    private handleActivitySelection(task: ITaskForTaskBoard) {
        let jobOrder = this.findJobOrder(task.JobOrderId);
        if (!jobOrder) jobOrder = this.addNewJobOrder(task.JobOrderId, task.JobOrderName);
        jobOrder.addActivity(task);
        this.eventClassificationInput.inQuality(true);
    }

    private addNewJobOrder(id: number, name: string): JobOrder {
        const jobOrder = new JobOrder(id, name);
        this.SelectedJobOrders.push(jobOrder);
        return jobOrder;
    }

    private findJobOrder(jobOrderId: number): JobOrder {
        const selectedJobOrders = this.SelectedJobOrders();
        return selectedJobOrders.firstOrDefault((j) => j.Id === jobOrderId);
    }

    private handleMailSelection(model: IExtendedBlogMailData) {
        this.SeletcedMails.push(model);
        const numberOfMails = this.SeletcedMails().length;
        this.MultipleMails(numberOfMails > 1);

        if (numberOfMails === 1) this.populateMailData(model);
        else this.clearMailData();
    }

    private populateMailData(mail: IExtendedBlogMailData) {
        this.MailDate(mail.MailDate);
        this.MailSubject(mail.Subject);
        this.MailBody(mail.Body);
        this.MailSenders(mail.Senders.map(this.createMailAddressId, this));
        this.MailRecipients(mail.To.map(this.createMailAddressId, this));
        this.MailCcRecipients(mail.Cc.map(this.createMailAddressId, this));
        this.MailBccRecipients(mail.Bcc.map(this.createMailAddressId, this));
        this.MailAttachments(mail.Attachments.map((a) => new Attachment().loadFromFile(a)));
        this.MailAttachmentsBasePath(mail.TemporaryStoragePath);

        this.MailBodyTrigger.valueHasMutated();

        this.resetIsChangedStatus();
    }

    private createMailAddressId(address: string): string {
        const tag = this.createTagForAddress(address);
        return JSON.stringify(tag) + "#" + address;
    }

    private createTagForAddress(address: string): ITag {
        const tag = {
            TagName: "",
            TagTypeId: ProlifeSdk.TagType_String,
            Value: address,
        };

        return tag;
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.jobOrdersNavigationMenuDataSource)
            this.handleActivityOrJobOrderDeselection(
                model as IJobOrderForTaskBoardDataSourceModel | ITaskForTaskBoardDataSourceModel
            );
        else this.handleMailDeselection(model.model);
    }

    private handleActivityOrJobOrderDeselection(
        model: IJobOrderForTaskBoardDataSourceModel | ITaskForTaskBoardDataSourceModel
    ) {
        const jobOrder = model as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrder.isJobOrder) this.handleJobOrderDeselection(model.model as IJobOrderForTaskBoard);
        else this.handleActivityDeselection(model.model as ITaskForTaskBoard);
    }

    private handleJobOrderDeselection(jobOrder: IJobOrderForTaskBoard) {
        for (const selectedJobOrder of this.SelectedJobOrders()) {
            if (selectedJobOrder.Id === jobOrder.Id) {
                this.SelectedJobOrders.remove(selectedJobOrder);
                break;
            }
        }
    }

    private handleActivityDeselection(task: ITaskForTaskBoard) {
        const jobOrder = this.findJobOrder(task.JobOrderId);
        jobOrder.removeActivity(task);
        if (jobOrder.SelectedActivities().length === 0) this.SelectedJobOrders.remove(jobOrder);
    }

    private handleMailDeselection(model: IExtendedBlogMailData) {
        const mail = this.findMail(model);
        if (!mail) return;
        this.SeletcedMails.remove(mail);
        const numberOfMails = this.SeletcedMails().length;
        this.MultipleMails(numberOfMails > 1);

        if (numberOfMails === 1) this.populateMailData(this.SeletcedMails().firstOrDefault());

        if (numberOfMails < 1) this.clearMailData();
    }

    private clearMailData() {
        this.MailDate(null);
        this.MailSubject(null);
        this.MailBody("");
        this.MailSenders([]);
        this.MailRecipients([]);
        this.MailCcRecipients([]);
        this.MailBccRecipients([]);
        this.MailAttachments([]);

        this.MailBodyTrigger.valueHasMutated();

        this.resetIsChangedStatus();
    }

    private resetIsChangedStatus() {
        this.isChanged(0);
    }

    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        if (sender === this.jobOrdersNavigationMenuDataSource || this.LockMailFields()) return true;

        if (this.isChanged() === 0) return true;

        return this.dialogsService.ConfirmAsync(
            TextResources.Blog.AbortChangesAndContinue,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );
    }

    public onNavigate(sender: IDataSource, ...history: INavigationMenuComponentModel[]): void {
        if (sender !== this.jobOrdersNavigationMenuDataSource) return;

        if (history.length === 0) {
            this.IsShowingWorkflowsOnMenu(false);
            this.IsShowingTasksOnMenu(false);

            this.selectedJobOrder = null;
            this.selectedWorkflow = null;

            return;
        }

        const model = history[history.length - 1];

        const jobOrderModel = model as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrderModel && jobOrderModel.isJobOrder) {
            this.IsShowingWorkflowsOnMenu(true);
            this.IsShowingTasksOnMenu(false);

            this.selectedJobOrder = jobOrderModel.id;

            return;
        }

        const workflowModel = model as IWorkflowForTaskBoardDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            this.IsShowingWorkflowsOnMenu(false);
            this.IsShowingTasksOnMenu(true);

            this.selectedWorkflow = workflowModel.id;
        }
    }

    private findMail(model: IExtendedBlogMailData): IExtendedBlogMailData {
        const selectedMails = this.SeletcedMails();
        return selectedMails.firstOrDefault((m) => m.Id === model.Id);
    }

    componentDidMount() {
        this.configureJobOrdersDataSource();
        this.configureMailsMenu();
        this.configureJobOrdersMenu();
    }

    componentWillUnmount() {
        this.dispose();
    }

    dispose() {}

    private configureJobOrdersDataSource() {
        this.jobOrdersNavigationMenuDataSource.setViewFilters(true, true, true);
        this.jobOrdersNavigationMenuDataSource.setJobOrdersShowClosed(false);
        this.jobOrdersNavigationMenuDataSource.setTasksShowClosed(true);
        this.jobOrdersNavigationMenuDataSource.setWorkFilters(true);
        this.jobOrdersNavigationMenuDataSource.setUserId(this.userInfo.getIdUser());
    }

    private configureMailsMenu() {
        this.mailsNavigationMenu.setMultipleSelection({
            multipleSelection: true,
            selectLeafsOnly: true,
            keepSelection: true,
        });
        this.configureMailsMenuActions();
    }

    private configureMailsMenuActions() {
        const lockAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-file-o",
            title: TextResources.Blog.UnlockMailFields,
            defaultClass: "btn-transparent",
            visible: () => !this.LockMailFields(),
            canExecute: () => !this.MultipleMails(),
            action: () => {
                this.lockUnlockFields();
            },
        };

        this.mailsNavigationMenu.registerAction(lockAction);

        const unlockAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-files-o",
            defaultClass: "btn-danger",
            title: TextResources.Blog.LockMailFields,
            visible: () => this.LockMailFields(),
            canExecute: () => !this.MultipleMails(),
            action: () => {
                this.lockUnlockFields();
            },
        };

        this.mailsNavigationMenu.registerAction(unlockAction);
    }

    private configureJobOrdersMenu() {
        this.jobOrdersNavigationMenu.setMultipleSelection({
            multipleSelection: true,
            selectLeafsOnly: false,
            keepSelection: true,
        });
        this.jobOrdersNavigationMenu.enableSelectAllChildren();

        this.configureMenuLegendAction();
        this.configureMenuAdvancedFilterAction();
        this.configureMenuWorkflowActions();
        this.configureMenuTasksActions();
        this.configureMenuSearchActions();
    }

    private configureMenuAdvancedFilterAction() {
        const action: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-filter",
            text: "",
            activeClass: "red",
            defaultClass: "btn-transparent",
            active: () => this.jobOrdersNavigationMenuDataSource.AdvancedFilterIsActive(),
            canExecute: () => true,
            action: () => {
                this.jobOrdersNavigationMenuDataSource.editAdvancedFilters();
            },
        };

        this.jobOrdersNavigationMenu.registerAction(action);
    }

    private configureMenuTasksActions() {
        const tasksActions: INavigationMenuComponentActionsGroup = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            visible: () => this.IsShowingTasksOnMenu(),
            actions: [],
        };

        if (this.authorizationsService.isAuthorized("TaskBoard_InsertTask")) {
            const insertTaskAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-plus",
                text: TextResources.Todolist.NewTask,
                visible: () => this.IsShowingTasksOnMenu(),
                canExecute: () => true,
                action: () => {
                    this.createTask();
                },
            };

            tasksActions.actions.push(insertTaskAction);
        }

        if (tasksActions.actions.length > 0) this.jobOrdersNavigationMenu.registerAction(tasksActions);

        if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
            const showSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                text: null,
                visible: () =>
                    this.IsShowingTasksOnMenu() && !this.ShowingSecondaryActionsOnMenu() && this.canEditTasks(),
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(true);
                    this.jobOrdersNavigationMenu.showSecondaryAction(true);
                },
            };

            const hideSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                text: null,
                visible: () =>
                    this.IsShowingTasksOnMenu() && this.ShowingSecondaryActionsOnMenu() && this.canEditTasks(),
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(false);
                    this.jobOrdersNavigationMenu.showSecondaryAction(false);
                },
            };

            this.jobOrdersNavigationMenu.registerAction(showSecondaryAction);
            this.jobOrdersNavigationMenu.registerAction(hideSecondaryAction);
        }
    }

    private configureMenuSearchActions() {
        this.jobOrdersNavigationMenu.registerSearchAction({
            text: TextResources.ProlifeSdk.SearchAll,
            action: () =>
                this.jobOrdersNavigationMenuDataSource.setWorkflowsSearchMode(
                    WorkflowsMenuSearchModes.SearchActivitiesInWorkflows
                ),
            visible: () => this.IsShowingWorkflowsOnMenu(),
            icon: "",
            isGroup: false,
            isSeparator: false,
        });

        this.jobOrdersNavigationMenu.registerSearchAction({
            text: TextResources.ProlifeSdk.SearchOnlyInList,
            action: () =>
                this.jobOrdersNavigationMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchWorkflows),
            visible: () => this.IsShowingWorkflowsOnMenu(),
            icon: "",
            isGroup: false,
            isSeparator: false,
            isDefault: true,
        });

        this.jobOrdersNavigationMenuDataSource.setWorkflowsSearchMode(
            WorkflowsMenuSearchModes.SearchActivitiesInWorkflows
        );
    }

    private configureMenuLegendAction() {
        const showLegendAction: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-info-circle",
            text: "",
            title: TextResources.ProlifeSdk.AlertsLegendTitle,
            activeClass: "",
            defaultClass: "btn-transparent",
            active: () => false,
            canExecute: () => true,
            action: () => {
                const component = new AlertsLegendUI();
                component.show();
            },
        };

        this.jobOrdersNavigationMenu.registerAction(showLegendAction);
    }

    private configureMenuWorkflowActions() {
        const action = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            visible: () => this.IsShowingWorkflowsOnMenu(),
            actions: [],
        };

        if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-plus",
                text: TextResources.Todolist.NewWorkflow,
                visible: () => true,
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                action: () => {
                    this.createNewWorkflow();
                },
            });

        if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-copy",
                text: TextResources.Todolist.NewWorkflowFromWorkflow,
                visible: () => true,
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                action: () => {
                    this.createNewWorkflowFromWorkflow();
                },
            });

        if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-magic",
                text: TextResources.Todolist.CreateFromModel,
                visible: () => true,
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                action: () => {
                    this.createNewWorkflowFromTemplate();
                },
            });

        if (action.actions.length > 0) this.jobOrdersNavigationMenu.registerAction(action);

        if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
            const showSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                visible: () =>
                    this.IsShowingWorkflowsOnMenu() && !this.ShowingSecondaryActionsOnMenu() && this.canEditWorkflows(),
                text: null,
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(true);
                    this.jobOrdersNavigationMenu.showSecondaryAction(true);
                },
            };

            const hideSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                visible: () =>
                    this.IsShowingWorkflowsOnMenu() && this.ShowingSecondaryActionsOnMenu() && this.canEditWorkflows(),
                text: null,
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(false);
                    this.jobOrdersNavigationMenu.showSecondaryAction(false);
                },
            };

            this.jobOrdersNavigationMenu.registerAction(showSecondaryAction);
            this.jobOrdersNavigationMenu.registerAction(hideSecondaryAction);
        }
    }

    private canEditTasks(): boolean {
        return this.IsShowingTasksOnMenu() && this.authorizationsService.isAuthorized("TaskBoard_EditTask");
    }

    private canEditWorkflows(): boolean {
        return this.IsShowingWorkflowsOnMenu() && this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow");
    }

    private async createNewWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowDialog(this.selectedJobOrder);
        this.jobOrdersNavigationMenuDataSource.refresh();
    }

    private async createNewWorkflowFromWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder);
        this.jobOrdersNavigationMenuDataSource.refresh();
    }

    private async createNewWorkflowFromTemplate(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder);
        this.jobOrdersNavigationMenuDataSource.refresh();
    }

    private async createTask(): Promise<void> {
        await this.todoListService.ShowCreateNewTaskDialog(this.selectedJobOrder, this.selectedWorkflow, {
            initialViewAll: true,
        });
        this.jobOrdersNavigationMenuDataSource.refresh();
    }

    private async deleteMail(mail: IExtendedBlogMailData): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Blog.DeleteMailMsg,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        await this.mailsService.RemoveMailsFromTemporaryStorage([mail.Id]);
        this.mailsNavigationMenu.refresh(true);
        this.handleMailDeselection(mail);
    }

    private async lockUnlockFields(): Promise<void> {
        if (!this.LockMailFields()) {
            if (this.isChanged() > 0) {
                const confirm = await this.dialogsService.ConfirmAsync(
                    TextResources.Blog.AbortChangesAndContinue,
                    TextResources.ProlifeSdk.Abort,
                    TextResources.ProlifeSdk.Confirm
                );
                if (!confirm) return;
            }

            this.LockMailFields(true);
            this.mailsNavigationMenu.setMultipleSelection({
                multipleSelection: true,
                selectLeafsOnly: true,
                keepSelection: true,
            });
        } else {
            this.LockMailFields(false);
            this.mailsNavigationMenu.setMultipleSelection({
                multipleSelection: false,
                selectLeafsOnly: true,
                keepSelection: true,
            });
        }

        const currentSelectedMail = this.SeletcedMails().firstOrDefault();
        if (currentSelectedMail) this.mailsNavigationMenuDataSource.selectByIds(currentSelectedMail.Id);

        this.resetIsChangedStatus();
    }

    hasTemplateFor(dataSource: IDataSource, model: INavigationMenuComponentModel): boolean {
        return dataSource === this.mailsNavigationMenuDataSource;
    }

    templatesProvider(dataSource: IDataSource, model: IMailsMenuDataSourceModel) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const generator = this;
        const $data: IMailsMenuDataSourceModel = null;
        if (dataSource === this.mailsNavigationMenuDataSource) {
            const mail = model.model;
            return (
                <div className="flex-container mail">
                    <div className="flex-fill first-column">
                        <div className="mail-sender text-ellipsis">{mail.Senders.join("; ")}</div>
                        <div className="mail-subject text-ellipsis">{mail.Subject}</div>
                        <div className="mail-content-preview text-ellipsis">{model.safeMailBody.substr(0, 50)}</div>
                    </div>
                    <div className="second-column">
                        <div className="mail-datetime text-right">{moment(mail.MailDate).format("L LT")}</div>
                        <div className="mail-actions text-right">
                            <button
                                className="btn btn-xs btn-danger"
                                data-bind={{
                                    asyncClick: generator.deleteMail.bind(generator, $data.model),
                                    clickBubble: false,
                                }}>
                                <i className="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;
    }

    private customTreeViewNodeTempate(node: ITreeNode) {
        return (
            <div className="custom-treeview-node" data-bind={{ click: node.select.bind(node) }}>
                <i
                    className="fa fa-angle-down switch"
                    data-bind={{
                        click: node.expand.bind(node),
                        css: { "fa-angle-right": !node.Expanded(), "fa-angle-down": node.Expanded },
                        visible: node.HasChildren,
                    }}></i>
                <span className="node-name" data-bind={{ text: node.Name }}></span>
                <small className="text-muted sub-title" data-bind={{ text: node.SubTitle }}></small>
            </div>
        );
    }

    render() {
        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["100%"]}
                columns={["270px", "auto", "270px", "270px"]}
                style={{ height: "100%" }}
                className={classes.mailEventsGenerator}>
                <Layout.Grid.Cell column={1} row={1} className="navigation-menu-wrapper">
                    <NavigationMenu
                        dataSource={this.mailsNavigationMenuDataSource}
                        templatesProvider={this}
                        forwardRef={(menu) => (this.mailsNavigationMenu = menu)}
                        listener={this}
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={2} row={1}>
                    <Layout.ScrollContainer systemScrollable>
                        <div className="form">
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-9">
                                        <TextInput
                                            value={this.BlogEventSubject}
                                            label={TextResources.Blog.BlogEventSubject}
                                            placeholder={TextResources.Blog.BlogEventTitlePlaceholder}
                                            selectOnFocus
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <DateTimeInput
                                            value={this.MailDate}
                                            label={TextResources.Blog.MailDateLabel}
                                            placeholder={this.MailDatePlaceholder}
                                            parent=".modal-body"
                                            readonly={this.LockMailFields}
                                            allowClear
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <RichTextInput
                                                value={this.MailBody}
                                                trigger={this.MailBodyTrigger}
                                                placeholder={this.MailBodyPlaceholder}
                                                readonly={this.LockMailFields}
                                                height={150}
                                                resizable
                                                simple
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <TextInput
                                                value={this.MailSubject}
                                                label={TextResources.Blog.MailSubjectLabel}
                                                placeholder={this.MailSubjectPlaceholder}
                                                readonly={this.LockMailFields}
                                                selectOnFocus
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectMultiple.WithLabel
                                                dataSource={this.contactsDataSource}
                                                value={this.MailSenders}
                                                label={TextResources.Blog.MailSenders}
                                                placeholder={this.MailSendersPlaceholder}
                                                readOnly={this.LockMailFields}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectMultiple.WithLabel
                                                dataSource={this.contactsDataSource}
                                                value={this.MailRecipients}
                                                label={TextResources.Blog.MailRecipients}
                                                placeholder={this.MailRecipientsPlaceholder}
                                                readOnly={this.LockMailFields}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectMultiple.WithLabel
                                                dataSource={this.contactsDataSource}
                                                value={this.MailCcRecipients}
                                                label={TextResources.Blog.MailCcRecipients}
                                                placeholder={this.MailCcRecipientsPlaceholder}
                                                readOnly={this.LockMailFields}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectMultiple.WithLabel
                                                dataSource={this.contactsDataSource}
                                                value={this.MailBccRecipients}
                                                label={TextResources.Blog.MailBccRecipients}
                                                placeholder={this.MailBccRecipientsPlaceholder}
                                                readOnly={this.LockMailFields}
                                                allowClear
                                            />
                                        </div>
                                    </div>
                                </div>
                                <EventClassificationInput
                                    forwardRef={(component) => (this.eventClassificationInput = component)}
                                />
                                <AttachmentsManager
                                    attachments={this.MailAttachments}
                                    readOnly={this.LockMailFields}
                                    disableFileRepositoryModal
                                    basePath={this.MailAttachmentsBasePath}
                                    emptyListText={this.MailAttachmentsText}
                                />
                            </div>
                        </div>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={3} row={1} className="flex-vertical selected-activities">
                    <h4>{TextResources.Blog.MailEventsGeneratorSelectedAvtivitiesTitle}</h4>
                    <Layout.ScrollContainer systemScrollable>
                        <TreeView
                            roots={this.SelectedActivitiesTreeViewRoots}
                            customNodeTemplate={this.customTreeViewNodeTempate}
                            className="flex-fill"
                        />
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={4} row={1} className="navigation-menu-wrapper">
                    <NavigationMenu
                        dataSource={this.jobOrdersNavigationMenuDataSource}
                        forwardRef={(menu) => (this.jobOrdersNavigationMenu = menu)}
                        listener={this}
                    />
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "generator"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MailEventsGenerator);
}
