import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as EnjoyHint from "enjoyhint";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { ITaskEditorConfiguration } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IApplicationsConfigurationsService, IApplicationConfiguration } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class ActivityDetailsDialogBase {
    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationsService: IAuthorizationService;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    protected userInfoService: IUserInfo;
    
    @LazyImport(ProlifeSdk.ApplicationsConfigurationsServiceCode)
    protected applicationsConfigurationsService: IApplicationsConfigurationsService;

    @LazyImport(ProlifeSdk.ModulesServiceType)
    protected modulesService: IModulesService;

    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService : IInfoToastService;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService! : IDesktopService;

    //TaskSections
    public EstimatedCostsOpened: ko.Observable<boolean> = ko.observable(false);
    public EstimatedCostsScroller: ko.Observable<boolean> = ko.observable(false);

    public DocumentsOpened: ko.Observable<boolean> = ko.observable(false);
    public DocumentsScroller: ko.Observable<boolean> = ko.observable(false);

    public GeneralDataScroller: ko.Observable<boolean> = ko.observable(false);
    public GeneralDataOpened: ko.Observable<boolean> = ko.observable(false);
    
    public TaskStateScroller: ko.Observable<boolean> = ko.observable(false);
    public TaskStateOpened: ko.Observable<boolean> = ko.observable(false);

    public ResourcesScroller: ko.Observable<boolean> = ko.observable(false);
    public ResourcesOpened: ko.Observable<boolean> = ko.observable(false);
    
    public WorkedHoursDefaultsScroller: ko.Observable<boolean> = ko.observable(false);
    public WorkedHoursDefaultsOpened: ko.Observable<boolean> = ko.observable(false);

    public AdvancedSettingsScroller: ko.Observable<boolean> = ko.observable(false);
    public AdvancedSettingsOpened: ko.Observable<boolean> = ko.observable(false);

    public ShowActivitiesList: ko.Observable<boolean> = ko.observable(true);
    public ViewAll: ko.Observable<boolean> = ko.observable(false);
    public ShowClosedJobOrders: ko.Observable<boolean> = ko.observable(false);

    protected IsTemplate: ko.Observable<boolean> = ko.observable(false);

    private tutorial: EnjoyHint;

    protected editorConfigurationDefaults: ITaskEditorConfiguration = {
        GeneralDataOpened: true,
        ResourcesOpened: false,
        WorkedHoursDefaultsOpened: false,
        AdvancedSettingsOpened: false,
        EstimatedCostsOpened: true,
        DocumentsOpened: true,
        TaskStateOpened: true,
        ShowActivitiesList: true
    }

    protected savedEditorConfiguration: IApplicationConfiguration;

    constructor() {
        this.loadSectionsSettings();
    }

    public reloadDefaultSettings(): void {
        this.GeneralDataOpened(this.editorConfigurationDefaults.GeneralDataOpened);
        this.ResourcesOpened(this.editorConfigurationDefaults.ResourcesOpened);
        this.WorkedHoursDefaultsOpened(this.editorConfigurationDefaults.WorkedHoursDefaultsOpened);
        this.AdvancedSettingsOpened(this.editorConfigurationDefaults.AdvancedSettingsOpened);
        this.EstimatedCostsOpened(this.editorConfigurationDefaults.EstimatedCostsOpened);
        this.DocumentsOpened(this.editorConfigurationDefaults.DocumentsOpened);
        this.TaskStateOpened(this.editorConfigurationDefaults.TaskStateOpened);
        this.ShowActivitiesList(this.editorConfigurationDefaults.ShowActivitiesList);
    }

    public async saveSectionsConfig(): Promise<void> {
        const actualConfig: ITaskEditorConfiguration = {
            GeneralDataOpened: this.GeneralDataOpened(),
            ResourcesOpened: this.ResourcesOpened(),
            WorkedHoursDefaultsOpened: this.WorkedHoursDefaultsOpened(),
            AdvancedSettingsOpened: this.AdvancedSettingsOpened(),
            EstimatedCostsOpened: this.EstimatedCostsOpened(),
            DocumentsOpened: this.DocumentsOpened(),
            TaskStateOpened: this.TaskStateOpened(),
            ShowActivitiesList: this.ShowActivitiesList()
        };

        try {
            let moduleId = 0;

            if (!this.savedEditorConfiguration)
                moduleId = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);

            const appConfig: IApplicationConfiguration = $.extend(true, {}, this.savedEditorConfiguration || <IApplicationConfiguration> {
                ModuleId: moduleId,
                UserID: this.userInfoService.getIdUser(),
                ConfigurationType: ProlifeSdk.ActivityDetailsDialogConfigType,
                Configuration: ""
            });

            appConfig.Configuration = JSON.stringify(actualConfig);

            this.savedEditorConfiguration = await this.applicationsConfigurationsService.AddOrUpdateConfiguration(appConfig);
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskEditorConfigurationSaved);
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.TaskEditorConfigurationSavingError);
        }
    }

    public scrollToEstimatedCosts(): void {
        this.EstimatedCostsOpened(true);
        this.EstimatedCostsScroller(!this.EstimatedCostsScroller());
    }

    public switchEstimatedCostsOpenedState(): void {
        this.EstimatedCostsOpened(!this.EstimatedCostsOpened());
    }

    public scrollToDocuments(): void {
        this.DocumentsOpened(true);
        this.DocumentsScroller(!this.DocumentsScroller());
    }

    public switchDocumentsOpenedState(): void {
        this.DocumentsOpened(!this.DocumentsOpened());
    }

    public scrollToResources(): void {
        this.ResourcesOpened(true);
        this.ResourcesScroller(!this.ResourcesScroller());
    }
    
    public scrollToWorkedHoursDefaults(): void {
        this.WorkedHoursDefaultsOpened(true);
        this.WorkedHoursDefaultsScroller(!this.WorkedHoursDefaultsScroller());
    }

    public switchResourceOpenedState(): void {
        this.ResourcesOpened(!this.ResourcesOpened());
    }
    
    public switchWorkedHoursDefaultsOpenedState(): void {
        this.WorkedHoursDefaultsOpened(!this.WorkedHoursDefaultsOpened());
    }

    public scrollToGeneralData(): void {
        this.GeneralDataOpened(true);
        this.GeneralDataScroller(!this.GeneralDataScroller());
    }

    public scrollToAdvancedSettings(): void {
        this.AdvancedSettingsOpened(true);
        this.AdvancedSettingsScroller(!this.AdvancedSettingsScroller());
    }

    public switchAdvancedSettingsOpenedState(): void {
        this.AdvancedSettingsOpened(!this.AdvancedSettingsOpened());
    }

    public scrollToTaskState(): void {
        this.TaskStateOpened(true);
        this.TaskStateScroller(!this.TaskStateScroller());
    }

    public switchTaskStateOpenedState(): void {
        this.TaskStateOpened(!this.TaskStateOpened());
    }

    public launchTutorial(): void {
        this.tutorial = new EnjoyHint({
            onSkip: () => {
                localStorage.setItem('TodoListTaskTutorial1', 'true');
                this.tutorial = null;
            },
            onEnd: () => {
                localStorage.setItem('TodoListTaskTutorial1', 'true');
                this.tutorial = null;
            }
        });

        const steps = [
            {
                selector: '.task-sections',
                description: 'Benvenuto sulla nuova form di editing dei task.<br/>Questa guida ti mostrerà come usare la nuova struttura della maschera di editing.<br/>I campi del task sono stati suddivisi in sezioni, visibili in questa colonna.<br/>Cliccando su uno dei pulsanti la pagina fa uno scroll fino alla sezione scelta e la apre se questa è chiusa.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: '.task-sections > div > ul > li:nth-child(2) > button.btn.btn-default.btn-xs.btn-status.btn-primary.active',
                description: 'Questi pulsanti permettono di aprire/chiudere una sezione e, allo stesso tempo, indicano se la sezione è aperta o chiusa.<br/>Il pulsante blu indica che la sezione è aperta.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: !this.IsTemplate() ? 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog > div > div.tab-pane.active > div.task-details.os-host.os-theme-thin-dark.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden.os-host-overflow.os-host-overflow-y.os-host-transition > div.os-padding > div > div > div:nth-child(1) > div:nth-child(2)' : 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog.template > div.task-details.os-host.os-theme-thin-dark.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden.os-host-overflow.os-host-overflow-y.os-host-transition > div.os-padding > div > div > div:nth-child(1) > div:nth-child(2)',
                description: 'Le sezioni sono rappresentate da questi blocchi<br/>ed hanno lo stesso titolo riportato sul relativo pulsante.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.modal.fullscreen.in > div > div > div.modal-body > .task-editor-dialog .task-general-data',
                description: 'Le sezione \'Dati generali\' non può essere chiusa e non ha un titolo.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-5 > div > button:nth-child(1)',
                description: 'Questo pulsante permette di aprire/chiudere la colonna sottostante<br/>che contiene l\'elenco dei task del piano visibili all\'utente.<br/>Quando la colonna è chiusa sono visibili solamente gli avvisi relativi al task selezionato.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            }
        ];

        if (!this.IsTemplate()) {
            steps.push(
                {
                    selector: 'body > div.modal.fullscreen.in > div > div > div.modal-footer > a.btn.btn-success.pull-left',
                    description: 'Questo pulsante permette di aprire la maschera di inserimento ore con il task pre-selezionato.',
                    showNext: true,
                    showSkip: true,
                    nextButton: { text: "Avanti" },
                    skipButton: { text: "Salta" }
                }
            );
        }

        steps.push({
            selector: !this.IsTemplate() ? 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog > div > div.tab-pane.active > div.navbar.task-sections > div > ul > li.save-button.text-right > button' : 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog.template > div.navbar.task-sections > div > ul > li.save-button.text-right > button',
            description: 'Questo pulsante permette di salvare lo stato di apertura/chiusura delle sezioni e della colonna dei task.<br/>Questa impostazione viene caricata ogni volta che si apre la maschera di editing,<br/>permettendo quindi personalizzarla e chiudere le sezioni meno utilizzate.',
            showNext: true,
            showSkip: true,
            nextButton: { text: "Avanti" },
            skipButton: { text: "Salta" }
        });

        steps.push({
            selector: !this.IsTemplate() ? 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog > div > div.tab-pane.active > div.navbar.task-sections > div > ul > li.defaults-button.text-right > button' : 'body > div.modal.fullscreen.in > div > div > div.modal-body > div.col-md-7.task-editor-dialog.template > div.navbar.task-sections > div > ul > li.defaults-button.text-right > button',
            description: 'Questo pulsante permette di ricaricare le impostazioni salvate.',
            showNext: true,
            showSkip: true,
            nextButton: { text: "Avanti" },
            skipButton: { text: "Salta" }
        });

        steps.push({
            selector: 'body > div.modal.fullscreen.in > div > div > div.modal-footer > a.btn.btn-info.pull-left',
            description: 'Hai completato il tutorial. Puoi avviarlo nuovamente ogni volta che vuoi con questo pulsante.',
            showNext: false,
            showSkip: true,
            nextButton: { text: "Avanti" },
            skipButton: { text: "Fine" }
        });

        this.tutorial.set(steps);
        this.tutorial.run();
    }

    protected async loadSectionsSettings(): Promise<void> {
        try {
            const moduleId: number = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);
            const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.ActivityDetailsDialogConfigType, this.userInfoService.getIdUser(), moduleId, null);
            this.savedEditorConfiguration = configurations && configurations.length > 0 ? configurations[0] : null;

            let sectionsConfig: ITaskEditorConfiguration = null;

            if (this.savedEditorConfiguration && this.savedEditorConfiguration.Configuration)
                sectionsConfig = JSON.parse(this.savedEditorConfiguration.Configuration) as ITaskEditorConfiguration;

            this.GeneralDataOpened(!sectionsConfig ? this.editorConfigurationDefaults.GeneralDataOpened : sectionsConfig.GeneralDataOpened);
            this.ResourcesOpened(!sectionsConfig ? this.editorConfigurationDefaults.ResourcesOpened : sectionsConfig.ResourcesOpened);
            this.WorkedHoursDefaultsOpened(!sectionsConfig ? this.editorConfigurationDefaults.WorkedHoursDefaultsOpened : sectionsConfig.WorkedHoursDefaultsOpened);
            this.AdvancedSettingsOpened(!sectionsConfig ? this.editorConfigurationDefaults.AdvancedSettingsOpened : sectionsConfig.AdvancedSettingsOpened);
            this.EstimatedCostsOpened(!sectionsConfig ? this.editorConfigurationDefaults.EstimatedCostsOpened : sectionsConfig.EstimatedCostsOpened);
            this.DocumentsOpened(!sectionsConfig ? this.editorConfigurationDefaults.DocumentsOpened : sectionsConfig.DocumentsOpened);
            this.TaskStateOpened(!sectionsConfig ? this.editorConfigurationDefaults.TaskStateOpened : sectionsConfig.TaskStateOpened);
            this.ShowActivitiesList(!sectionsConfig ? this.editorConfigurationDefaults.ShowActivitiesList : sectionsConfig.ShowActivitiesList);

        } catch(e) {
            // TODO errore
        }
    }

    protected launchTutorialIfNeverLaunched(): void {
        const tutorialReaded = localStorage.getItem("TodoListTaskTutorial1");

        if (!tutorialReaded && !this.desktopService.isMobileBrowser())
            this.launchTutorial();
    }    
}