import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2017
 * Time: 11:10
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { TeamViewModel } from "./TeamViewModel";
import { TeamAllocationViewModel } from "./TeamAllocationViewModel";
import { ResourceOperationalUnitViewModel } from "./ResourceOperationalUnitViewModel";
import * as moment from "moment";
import { Moment } from "moment";
import { IHumanResourcesService, IHumanResource, IHumanResourceOrders } from "../../../Users/HumanResourcesService";
import {
    IResourceAllocationRange,
    IFullTeamAllocation,
    ITeam,
    IAllocationsService,
    ITeamAllocation,
} from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IOperationalUnit } from "../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IEventHandler } from "../../../ProlifeSdk/interfaces/IEventHandler";
import { ICartsManager } from "../../interfaces/ICartsManager";
import { Deferred } from "../../../Core/Deferred";

export interface ITeamsManagerOperationalUnit {
    Id: number;
    StartDate: ko.Computed<any /*Moment*/>; //Ritorna un Moment ma non va bene....
    EndDate: ko.Computed<any /*Moment*/>;
    ServiceOrders: ko.ObservableArray<IHumanResourceOrders>;
    Teams: ko.ObservableArray<ITeamForResourceViewModel>;
    GetTeam(teamId: number): ITeamForResourceViewModel;
}

export interface ITeamsManagerResource {
    OperationalUnits: ko.ObservableArray<ITeamsManagerOperationalUnit>;
    Id: ko.Observable<number>;
    AddOperationalUnit(ou: ITeamsManagerOperationalUnit);
    GetOperationalUnit(ouId: number): ITeamsManagerOperationalUnit;
    GetOperationalUnits(): ITeamsManagerOperationalUnit[];
    RemoveOperationalUnit(ouId: number);
}

export interface ITeamForResourceViewModel {
    Id: number;
    Name: ko.Observable<string>;
    StartDate: ko.Computed<any /*Moment*/>;
    EndDate: ko.Computed<any /*Moment*/>;
    AllocationRanges: ko.ObservableArray<ITeamAllocationRangesForTeamViewModel>;

    LoadRanges(ranges: IResourceAllocationRangeViewModel[], teamAllocationId: number);
}

export interface IResourceAllocationRangeViewModel {
    StartDate: ko.Observable<Date>;
    EndDate: ko.Observable<Date>;
    Amount: ko.Observable<number>;
    Deleted: ko.Observable<boolean>;
    HasChanged: ko.Observable<boolean>;
    Selected: ko.Observable<boolean>;

    HoursMonday: ko.Observable<number>;
    HoursTuesday: ko.Observable<number>;
    HoursWednesday: ko.Observable<number>;
    HoursThursday: ko.Observable<number>;
    HoursFriday: ko.Observable<number>;
    HoursSaturday: ko.Observable<number>;
    HoursSunday: ko.Observable<number>;

    IsEditing: ko.Observable<boolean>;

    AllocationMode: ko.Observable<number>;
    AllocationModeForEditing: ko.Observable<number>;
    PercentageAllocationEnabled: ko.Computed<boolean>;
    HoursAllocationEnabled: ko.Computed<boolean>;

    getData(allocationId: number): IResourceAllocationRange;
    Clone(
        operationalUnit: IResourceOperationalUnitViewModel,
        teamsManagerProvider: ITeamsManagerProvider,
        assignNewId?: boolean
    ): IResourceAllocationRangeViewModel;
}

export interface IResourceOperationalUnitViewModel {
    Id: number;
    ShowFinishedAllocations: ko.Observable<boolean>;
    AllocationRanges: ko.ObservableArray<IResourceAllocationRangeViewModel>;
    ServiceOrders: ko.ObservableArray<IHumanResourceOrders>;
    OperationalUnit: IOperationalUnit;
    HasChanged: ko.Computed<boolean>;
    SelectedRange: ko.Computed<IResourceAllocationRangeViewModel>;
    UpdateAllocationsRanges(teamsManager: ITeamsManagerProvider);
    Load(): Promise<IResourceAllocationRange[]>;
    getData(allocationId: number): IResourceAllocationRange[];
    Clone(teamsManagerProvider: ITeamsManagerProvider): IResourceOperationalUnitViewModel;
    VerifyServiceOrders(startDate: Date, endDate: Date): boolean;
}

export interface ITeamAllocationViewModel {
    Id: number;
    ResourceId: ko.Observable<number>;
    ResourceName: ko.Observable<string>;
    ResourceType: ko.Observable<string>;
    TotalAllocatedHours: ko.Computed<number>;
    Deleted: ko.Observable<boolean>;

    OperationalUnits: ko.ObservableArray<IResourceOperationalUnitViewModel>;
    SelectedOperationalUnit: ko.Observable<IResourceOperationalUnitViewModel>;

    AbsoluteStartDate: ko.Computed<any /*Moment*/>;
    AbsoluteEndDate: ko.Computed<any /*Moment*/>;
    HasChanged: ko.Computed<boolean>;
    AllocationLoaded: ko.Computed<boolean>;

    FilteredOperationalUnits: ko.Computed<IResourceOperationalUnitViewModel[]>;
    ShowOverOperationalUnits: ko.Observable<boolean>;

    HasAllocatedFutureHours: ko.Computed<boolean>;

    getData(teamId: number): IFullTeamAllocation;
    GetOperationalUnit(operationalUnitId: number): IResourceOperationalUnitViewModel;
    GetOperationalUnits(): IResourceOperationalUnitViewModel[];
}

export interface ITeamManagerViewModel {
    Id: number;
    ResourceId: ko.Observable<number>;
    ResourceName: ko.Observable<string>;
    ResourceType: ko.Observable<string>;
    TotalAllocatedHours: ko.Computed<number>;
    Deleted: ko.Observable<boolean>;

    OperationalUnits: ko.ObservableArray<IResourceOperationalUnitViewModel>;
    SelectedOperationalUnit: ko.Observable<IResourceOperationalUnitViewModel>;

    AbsoluteStartDate: ko.Computed<any /*Moment*/>;
    AbsoluteEndDate: ko.Computed<any /*Moment*/>;
    HasChanged: ko.Computed<boolean>;
    AllocationLoaded: ko.Computed<boolean>;

    FilteredOperationalUnits: ko.Computed<IResourceOperationalUnitViewModel[]>;
    ShowOverOperationalUnits: ko.Observable<boolean>;

    HasAllocatedFutureHours: ko.Computed<boolean>;

    getData(teamId: number): IFullTeamAllocation;
    GetOperationalUnit(operationalUnitId: number): IResourceOperationalUnitViewModel;
    GetOperationalUnits(): IResourceOperationalUnitViewModel[];
}

export interface ITeamAllocationRangesForTeamViewModel {
    Id: number;
    TeamResourceAllocationId: number;
    StartDate: ko.Observable<Date>;
    EndDate: ko.Observable<Date>;
    Amount: ko.Observable<number>;
    Monday: ko.Observable<number>;
    Tuesday: ko.Observable<number>;
    Wednesday: ko.Observable<number>;
    Thursday: ko.Observable<number>;
    Friday: ko.Observable<number>;
    Saturday: ko.Observable<number>;
    Sunday: ko.Observable<number>;
    OperationalUnitId: number;
    AllocationType: ko.Observable<number>;
    IsCompatibleWith(other: ITeamAllocationRangesForTeamViewModel): boolean;
}

export interface ITeamViewModel {
    lockPositionElaboration: boolean;

    Allocations: ko.ObservableArray<ITeamAllocationViewModel>;
    HasChanged();
    SaveChanges(): Promise<ITeam>;
    Id: ko.Observable<number>;
    Name: ko.Observable<string>;
    Hide: ko.Observable<boolean>;
    Position: ko.Observable<number>;
    StartDate: ko.Computed<any /*Moment*/>;
    EndDate: ko.Computed<any /*Moment*/>;
    IsSelected: ko.Observable<boolean>;

    GetAllocationByResource(resourceId: number): ITeamAllocationViewModel;
    Clone(teamsManagerProvider: ITeamsManagerProvider): ITeamViewModel;
    GetOperationalUnitsWithAllocations(resourceId: number): IResourceOperationalUnitViewModel[];
    GetResourceOperationalUnit(resourceId: number, ouId: number): IResourceOperationalUnitViewModel;
}

export interface ITeamsManagerProvider {
    OnTeamsLoaded: IEventHandler;
    OnTeamCreated: IEventHandler;
    OnTeamDeleted: IEventHandler;
    OnTeamSelected: IEventHandler;
    OnBeforeTeamsManagerChanged: IEventHandler;
    OnAfterTeamsManagerChanged: IEventHandler;

    GetResource(resourceId: number): ITeamsManagerResource;
    GetResources(): ITeamsManagerResource[];

    GetTeam(teamId: number): ITeamViewModel;
    GetTeams(filterHiddenTeams?: boolean): ITeamViewModel[];
    SearchTeams(query: string): ITeamViewModel[];

    RemoveTeamOnResource(resourceId: number, teamId: number);
    OptimizeAllocations(resourceId: number, operationalUnitId: number);
    SynchronizeResourceAllocations(resourceId: number, teamId: number);
    SynchronizeResourceRanges(resourceId: number, teamId: number, operationalUnitId: number);
    GetTeamFromResourceAllocationId(teamResourceAllocationId: number): ITeamViewModel;

    selectTeam(team: any);
    deleteTeam(team: any);

    Clone(): ITeamsManagerProvider;
    CloneToProvider(newProvider: ITeamsManagerProvider);

    StealTeamMangerFrom(teamsManagerProvider: ITeamsManagerProvider);

    SetTeamsManger(teamsManager: ITeamsManager);
    Load();
    SaveChanges();
    addNewTeam(): ITeamViewModel;

    HasTeamsManager(): boolean;
}

export interface ITeamsManager {
    OnTeamsLoaded: IEventHandler;
    OnTeamCreated: IEventHandler;
    OnTeamDeleted: IEventHandler;
    OnTeamSelected: IEventHandler;

    Teams: ko.ObservableArray<ITeamViewModel>;
    Resources: ko.ObservableArray<ITeamsManagerResource>;

    search(query: string): any[];
    addNewTeam(): any;
    //Clone() : ITeamsManager;
    GetResource(resourceId: number): ITeamsManagerResource;
    GetTeam(teamId: number): ITeamViewModel;
    UpdateRangesOnResource(
        allocation: IFullTeamAllocation,
        opUnit: IOperationalUnit,
        ranges: IResourceAllocationRangeViewModel[]
    ): void;
    OptimizeAllocations(resourceId: number, operationalUnitId: number);
    SynchronizeResourceAllocations(resourceId: number, teamId: number);
    SynchronizeResourceRanges(resourceId: number, teamId: number, operationalUnitId: number);
    RemoveTeamOnResource(resourceId: number, teamId: number);
    GetTeamFromResourceAllocationId(teamResourceAllocationId: number): ITeamViewModel;

    SetProvider(teamsManagerProvider: ITeamsManagerProvider);
    Load();

    selectTeam(team: any);
    deleteTeam(team: any);

    CreateNew(): ITeamsManager;
    populateResourcesViewModels();
    SaveChanges(): Promise<void>;
}
export class TeamsManager implements ITeamsManager {
    private allocationsService: IAllocationsService;
    private humanResourcesService: IHumanResourcesService;
    private userInfo: IUserInfo;
    private resources: IHumanResource[];

    public OnTeamsLoaded: IEventHandler = new EventHandler();
    public OnTeamCreated: IEventHandler = new EventHandler();
    public OnTeamSelected: IEventHandler = new EventHandler();
    public OnTeamDeleted: IEventHandler = new EventHandler();

    public OnRangeAddedOnTeam: IEventHandler = new EventHandler();
    public OnRangeDeletedOnTeam: IEventHandler = new EventHandler();

    public ValuesHaveChanged: ko.Computed<boolean>;

    public Teams: ko.ObservableArray<ITeamViewModel> = ko.observableArray();
    public Resources: ko.ObservableArray<ITeamsManagerResource> = ko.observableArray();
    private resourcesCache: { [id: number]: ResourceViewModel } = <any>{};
    private teamsManagerProvider: ITeamsManagerProvider;

    private VisibleTeams: ko.Computed<ITeamViewModel[]>;

    private loading = false;

    constructor(private serviceLocator: IServiceLocator, private cartsManager: ICartsManager) {
        this.allocationsService = <IAllocationsService>serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);
        this.userInfo = <IUserInfo>serviceLocator.findService(ProlifeSdk.UserInfoServiceType);

        this.OnRangeAddedOnTeam.Add(this.AddRangeOnResourceViewModel.bind(this));
        this.OnRangeDeletedOnTeam.Add(this.DeleteRangeOnResourceViewModel.bind(this));
        this.OnTeamDeleted.Add(this.RemoveTeamFromResources.bind(this));

        this.humanResourcesService = <IHumanResourcesService>(
            serviceLocator.findService(ProlifeSdk.HumanResourcesServiceType)
        );

        this.ValuesHaveChanged = ko.computed(() => {
            const teams = this.Teams() || [];
            let atLeastOneTeamChanged = false;
            for (let i = 0; i < teams.length; i++) {
                atLeastOneTeamChanged = atLeastOneTeamChanged || !!teams[i].HasChanged();
            }
            return atLeastOneTeamChanged;
        });

        this.VisibleTeams = ko.computed(() => {
            const teams = this.Teams() || [];
            return teams.filter((t) => !t.Hide());
        });
    }

    public SetProvider(teamsManagerProvider: ITeamsManagerProvider) {
        this.teamsManagerProvider = teamsManagerProvider;
    }

    public Load() {
        if (!this.teamsManagerProvider)
            throw new Error("Impostare un provider prima di chiamare la Load sul TeamsManager");

        this.loading = true;

        const defTeams = this.allocationsService.getTeams("");
        const defResources = this.humanResourcesService.GetHumanResources(undefined, false, false);

        Promise.all([defTeams, defResources]).then(([teams, resources]) => {
            this.InternalLoadTeams(teams).then(() => {
                this.resources = resources;
                this.InternalLoadResources();
                this.OnTeamsLoaded.Call();

                this.loading = false;
            });
        });
    }

    private InternalLoadTeams(teams: ITeam[]): Promise<any[]> {
        this.Teams(teams.map(this.createTeamViewModel.bind(this)));

        const deferreds: Promise<any>[] = [];
        this.Teams().forEach((t: TeamViewModel) => deferreds.push(t.Load()));

        return Promise.all(deferreds);
    }

    private InternalLoadResources() {
        this.resources.forEach((r: IHumanResource) => {
            const vm = new ResourceViewModel(r);
            this.resourcesCache[r.Resource.Id] = vm;
            this.Resources.push(vm);
        });

        this.populateResourcesViewModels();
    }

    private createTeamViewModel(team: ITeam): TeamViewModel {
        return new TeamViewModel(this.serviceLocator, team, this.teamsManagerProvider);
    }

    public search(query: string): ITeamViewModel[] {
        return this.Teams().filter(
            (t: ITeamViewModel) =>
                t
                    .Name()
                    .toLowerCase()
                    .indexOf((query || "").toLowerCase()) != -1
        );
    }

    public addNewTeam(): TeamViewModel {
        const teamViewModel = new TeamViewModel(
            this.serviceLocator,
            {
                Id: this.allocationsService.GenerateNextId(),
                Name: "",
                Hide: false,
                Position: this.VisibleTeams().length + 1,
                CreatedBy: this.userInfo.getIdUser(),
                CreationDate: new Date(),
                LastModifiedBy: this.userInfo.getIdUser(),
                LastModifiedDate: new Date(),
                CanBeFiltered: false,
            },
            this.teamsManagerProvider
        );
        this.Teams.push(teamViewModel);
        this.OnTeamCreated.Call(teamViewModel);

        return teamViewModel;
    }

    public selectTeam(team: TeamViewModel) {
        this.Teams().forEach((t: TeamViewModel) => t.IsSelected(false));
        team.IsSelected(true);

        this.OnTeamSelected.Call(team);
    }

    public SaveChanges(): Promise<void> {
        const def = new Deferred<void>();
        const defs: Promise<any>[] = [];

        const changedTeams = this.Teams().filter((t) => t.HasChanged());
        changedTeams.forEach((t) => defs.push(t.SaveChanges()));

        Promise.all(defs)
            .then(() => {
                changedTeams.forEach((t: TeamViewModel) => this.cartsManager.UpdateTeam(t.Id()));
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    public deleteTeam(team: TeamViewModel): Promise<void> {
        if (!team.Id()) {
            this.Teams.remove(team);
            this.OnTeamDeleted.Call(team);
            return Promise.resolve();
        }
        return this.allocationsService.deleteTeam(team.Id()).then(() => {
            this.Teams.remove(team);
            this.OnTeamDeleted.Call(team);
        });
    }

    public CreateNew(): ITeamsManager {
        const teamsManager = new TeamsManager(this.serviceLocator, this.cartsManager);
        teamsManager.resources = this.resources;
        teamsManager.InternalLoadResources();
        return teamsManager;
    }

    /*public Clone(): ITeamsManager {
        var clonedTeamsManager: TeamsManager = new TeamsManager(this.serviceLocator, this.cartsManager);
        clonedTeamsManager.Teams(this.Teams().map((t: TeamViewModel) => { return t.Clone(clonedTeamsManager); }));
        clonedTeamsManager.InternalLoadResources(this.resources);
        return clonedTeamsManager;
    }*/

    public GetResource(resourceId: number): ITeamsManagerResource {
        if (!resourceId) return null;
        return this.resourcesCache[resourceId];
    }

    public GetTeam(teamId: number): ITeamViewModel {
        if (!teamId) return null;
        const teams = this.Teams().filter((t: ITeamViewModel) => t.Id() == teamId);
        if (teams.length == 0) return null;
        return teams[0];
    }

    public populateResourcesViewModels(): void {
        this.Teams().forEach((t: TeamViewModel) => {
            t.Allocations().forEach((a: TeamAllocationViewModel) => {
                const resource: ResourceViewModel = this.resourcesCache[a.ResourceId()];
                if (resource) {
                    this.createOperationalUnits(a, t, resource);
                }
            });
        });
    }

    private createOperationalUnits(
        alloc: ITeamAllocationViewModel,
        team: ITeamViewModel,
        resource: ITeamsManagerResource
    ) {
        const allocation: IFullTeamAllocation = alloc.getData(team.Id());
        const allocOU: IResourceOperationalUnitViewModel[] = alloc.GetOperationalUnits();
        allocOU.forEach((rou: ResourceOperationalUnitViewModel) => {
            const resourceOU = resource.GetOperationalUnits();
            const matchedOUs = resourceOU.filter((ou: OperationalUnitForResourceViewModel) =>
                ou.isForOperationalUnit(rou.OperationalUnit)
            );
            let viewModel = null;

            if (matchedOUs.length == 0) {
                viewModel = new OperationalUnitForResourceViewModel(rou.OperationalUnit, rou.ServiceOrders());
                resource.AddOperationalUnit(viewModel);
            } else {
                viewModel = matchedOUs[0];
            }

            this.createAllocationRanges(team, viewModel, rou, allocation.Id);
        });
    }

    private createAllocationRanges(
        team: ITeamViewModel,
        ou: OperationalUnitForResourceViewModel,
        rou: ResourceOperationalUnitViewModel,
        teamAllocationId
    ): void {
        let teamForResource: TeamForResourceViewModel = ou
            .Teams()
            .filter((t: TeamForResourceViewModel) => t.isForTeam(team.Id()))
            .pop();

        if (!teamForResource) {
            teamForResource = new TeamForResourceViewModel(team);
            ou.Teams.push(teamForResource);
        }

        teamForResource.LoadRanges(rou.AllocationRanges(), teamAllocationId);
    }

    public UpdateRangesOnResource(
        allocation: IFullTeamAllocation,
        opUnit: IOperationalUnit,
        ranges: IResourceAllocationRangeViewModel[]
    ): void {
        const resource: ITeamsManagerResource = this.Resources()
            .filter((r: ITeamsManagerResource) => {
                return r.Id() == allocation.ResourceId;
            })
            .pop();
        if (!resource) return;
        const ou: ITeamsManagerOperationalUnit = resource
            .OperationalUnits()
            .filter((o: ITeamsManagerOperationalUnit) => {
                return o.Id == opUnit.Id;
            })
            .pop();
        if (!ou) return;
        const team: ITeamForResourceViewModel = ou
            .Teams()
            .filter((t: ITeamForResourceViewModel) => {
                return t.Id == allocation.TeamId;
            })
            .pop();
        if (!team) return;
        team.LoadRanges(ranges, allocation.Id);
    }

    private AddRangeOnResourceViewModel(allocation: ITeamAllocation): void {
        const resource: ResourceViewModel = this.resourcesCache[allocation.ResourceId];
        const team: ITeamViewModel = this.Teams()
            .filter((t: ITeamViewModel) => {
                return t.Id() == allocation.TeamId;
            })
            .pop();
        if (!team) return;
        const teamAllocation: ITeamAllocationViewModel = team
            .Allocations()
            .filter((a: ITeamAllocationViewModel) => {
                return a.ResourceId() == allocation.ResourceId;
            })
            .pop();
        if (!teamAllocation) return;
        this.createOperationalUnits(teamAllocation, team, resource);
    }

    private DeleteRangeOnResourceViewModel(allocation: ITeamAllocation, range: IResourceAllocationRange): void {
        const resource: ResourceViewModel = this.resourcesCache[allocation.ResourceId];
        const ou: ITeamsManagerOperationalUnit = resource
            .OperationalUnits()
            .filter((o: ITeamsManagerOperationalUnit) => {
                return o.Id == range.OperationalUnitId;
            })
            .pop();
        if (!ou) return;
        const team: ITeamForResourceViewModel = ou
            .Teams()
            .filter((t: ITeamForResourceViewModel) => {
                return t.Id == allocation.TeamId;
            })
            .pop();
        if (!team) return;
        const allocationRange: ITeamAllocationRangesForTeamViewModel = team
            .AllocationRanges()
            .filter((r: ITeamAllocationRangesForTeamViewModel) => {
                return r.Id == range.Id;
            })
            .pop();
        if (!allocationRange) return;
        team.AllocationRanges.remove(allocationRange);
        if (team.AllocationRanges().length == 0) ou.Teams.remove(team);
        if (ou.Teams().length == 0) resource.OperationalUnits.remove(ou);
    }

    public RemoveTeamOnResource(resourceId: number, teamId: number): void {
        const resource: ResourceViewModel = this.resourcesCache[resourceId];
        const ous: ITeamsManagerOperationalUnit[] = resource.OperationalUnits();
        for (let index = 0; index < ous.length; index++) {
            const team: ITeamForResourceViewModel = ous[index].Teams().filter((t: ITeamForResourceViewModel) => {
                return t.Id == teamId;
            })[0];
            if (team) ous[index].Teams.remove(team);
            if (ous[index].Teams().length == 0) {
                ous.splice(index, 1);
                index--;
            }
        }
        resource.OperationalUnits(ous);
    }

    private RemoveTeamFromResources(teamViewModel: TeamViewModel): void {
        const teamId: number = teamViewModel.Id();
        this.Resources().forEach((r: ResourceViewModel) => {
            const ous: ITeamsManagerOperationalUnit[] = r.OperationalUnits();
            for (let index = 0; index < ous.length; index++) {
                const team: ITeamForResourceViewModel = ous[index]
                    .Teams()
                    .filter((t: ITeamForResourceViewModel) => {
                        return t.Id == teamId;
                    })
                    .pop();
                if (team) ous[index].Teams.remove(team);
                if (ous[index].Teams().length == 0) {
                    ous.splice(index, 1);
                    index--;
                }
            }
            r.OperationalUnits(ous);
        });
    }

    public OptimizeAllocations(resourceId: number, operationalUnitId: number) {
        const resource = this.GetResource(resourceId);
        const ou = resource.GetOperationalUnit(operationalUnitId);

        ou.Teams().forEach((t: TeamForResourceViewModel) => {
            const team = this.GetTeam(t.Id);
            const allocations = team.GetAllocationByResource(resourceId);
            const allocationsOU = allocations.GetOperationalUnit(operationalUnitId);

            const rangesResources: ITeamAllocationRangesForTeamViewModel[] = t.AllocationRanges();
            const rangesTeams: IResourceAllocationRangeViewModel[] = allocationsOU.AllocationRanges();

            const newRangesResources = [];
            const newRangesTeams = [];

            for (let i = 0; i < rangesResources.length; i++) {
                if (i == rangesResources.length - 1 || !rangesResources[i].IsCompatibleWith(rangesResources[i + 1])) {
                    newRangesResources.push(rangesResources[i]);
                    newRangesTeams.push(rangesTeams[i]);
                } else {
                    rangesResources[i].EndDate(rangesResources[i + 1].EndDate());
                    rangesTeams[i].EndDate(rangesTeams[i + 1].EndDate());
                    rangesResources.splice(i + 1, 1);
                    rangesTeams.splice(i + 1, 1);
                    i--;
                }
            }

            t.AllocationRanges(newRangesResources);
            allocationsOU.AllocationRanges(newRangesTeams);
        });
    }

    private synchronizeResourceOperationalUnits(resourceId: number) {
        const resource: ITeamsManagerResource = this.GetResource(resourceId); //Risorsa -> Resource First

        const resourceOUs: ITeamsManagerOperationalUnit[] = resource.GetOperationalUnits();
        const OUsToRemoveIds = [];
        let foundCounter = 0;

        resourceOUs.forEach((ou: ITeamsManagerOperationalUnit) => {
            foundCounter = 0;
            this.Teams().forEach((team: ITeamViewModel) => {
                const teamOUs: IResourceOperationalUnitViewModel[] = team
                    .GetOperationalUnitsWithAllocations(resourceId)
                    .filter((teamOU: IResourceOperationalUnitViewModel) => {
                        return ou.Id == teamOU.OperationalUnit.Id;
                    });
                if (teamOUs.length > 0) foundCounter++;
            });
            if (foundCounter == 0) OUsToRemoveIds.push(ou.Id);
        });

        OUsToRemoveIds.forEach((id: number) => {
            resource.RemoveOperationalUnit(id);
        });

        this.Teams().forEach((team: ITeamViewModel) => {
            const OUs = team.GetOperationalUnitsWithAllocations(resourceId);
            const OUsToAdd = OUs.filter((ou: IResourceOperationalUnitViewModel) => {
                return !resource.GetOperationalUnit(ou.OperationalUnit.Id);
            });

            /*var OUsToRemove = resource.GetOperationalUnits().filter((ou : ITeamsManagerOperationalUnit) => {
                return !team.GetResourceOperationalUnit(resourceId, ou.Id);
            });

            OUsToRemove.forEach((ou : ITeamsManagerOperationalUnit) => {
                resource.RemoveOperationalUnit(ou.Id);
            });*/

            OUsToAdd.forEach((ou: IResourceOperationalUnitViewModel) => {
                resource.AddOperationalUnit(
                    new OperationalUnitForResourceViewModel(ou.OperationalUnit, ou.ServiceOrders())
                );
            });
        });
    }

    private synchronizeResourceTeams(resourceId: number) {
        const resource = this.GetResource(resourceId);
        this.Teams().forEach((team: ITeamViewModel) => {
            const alloc = team.GetAllocationByResource(resourceId);
            if (!alloc) return;

            alloc.GetOperationalUnits().forEach((ou: IResourceOperationalUnitViewModel) => {
                const resourceOU = resource.GetOperationalUnit(ou.OperationalUnit.Id);
                if (!resourceOU) return;

                const resourceTeam = resourceOU.GetTeam(team.Id());

                if (ou.AllocationRanges().length == 0) {
                    //Rimuovere il team dalla UO se presente
                    if (resourceTeam) {
                        resourceOU.Teams.remove(resourceTeam);
                    }
                    return;
                }

                if (!resourceTeam) {
                    resourceOU.Teams.push(new TeamForResourceViewModel(team));
                }
            });
        });
    }

    private synchronizeResourceRanges(resourceId: number) {
        const resource = this.GetResource(resourceId);
        resource.GetOperationalUnits().forEach((ou: ITeamsManagerOperationalUnit) => {
            ou.Teams().forEach((teamForResource: ITeamForResourceViewModel) => {
                const team = this.GetTeam(teamForResource.Id);
                const alloc = team.GetAllocationByResource(resourceId);
                if (!alloc) return;

                const teamOU = alloc.GetOperationalUnit(ou.Id);
                if (!teamOU) return;

                teamForResource.LoadRanges(teamOU.AllocationRanges(), ou.Id);
            });
        });
    }

    private cleanupResource(resourceId: number) {
        const resource = this.GetResource(resourceId);
        const OUsToRemove = [];

        resource.GetOperationalUnits().forEach((ou: ITeamsManagerOperationalUnit) => {
            const teamsToRemove = [];

            ou.Teams().forEach((team: ITeamForResourceViewModel) => {
                if (team.AllocationRanges().length == 0) teamsToRemove.push(team);
            });

            teamsToRemove.forEach((team: ITeamForResourceViewModel) => {
                ou.Teams.remove(team);
            });

            if (ou.Teams().length == 0) OUsToRemove.push(ou);
        });

        OUsToRemove.forEach((ou: ITeamsManagerOperationalUnit) => {
            resource.RemoveOperationalUnit(ou.Id);
        });
    }

    SynchronizeResourceAllocations(resourceId: number, teamId: number) {
        // NB: il parametro teamId non viene utilizato, quindi può essere rimosso
        // 1) Sincronizzare le UO
        // 2) Sincronizzare i Team nelle UO
        // 3) Sincronizzare i Range nei Team
        // 4) Cleanup dei Team e OU vuote

        // --- Sincronizzare le UO ---
        this.synchronizeResourceOperationalUnits(resourceId);

        // --- Sincronizzare i Team nelle UO ---
        this.synchronizeResourceTeams(resourceId);

        // --- Sincronizzare i Range nei Teams ---
        this.synchronizeResourceRanges(resourceId);

        // --- Rimuovo tutti i Team che non hanno allocazioni e tutte le OU senza team ---
        this.cleanupResource(resourceId);
    }

    SynchronizeResourceRanges(resourceId: number, teamId: number, operationalUnitId: number) {
        this.SynchronizeResourceAllocations(resourceId, teamId);
        /*var resource: ITeamsManagerResource = this.GetResource(resourceId);
        var team: ITeamViewModel = this.GetTeam(teamId);
        if (!resource || !team)
            return;
        var resourceOperationalUnit: ITeamsManagerOperationalUnit = resource.GetOperationalUnit(operationalUnitId);
        var teamAllocation: ITeamAllocationViewModel = team.GetAllocationByResource(resourceId);
        if (!resourceOperationalUnit || !teamAllocation)
            return;

        var resourceTeams: TeamForResourceViewModel[] = resourceOperationalUnit.Teams().filter((t: TeamForResourceViewModel) => { return t.Id == teamId; });
        if (resourceTeams.length == 0)
            return;
        var resourceTeam: TeamForResourceViewModel = resourceTeams[0];
        var teamOperationalUnit: IResourceOperationalUnitViewModel = teamAllocation.GetOperationalUnit(operationalUnitId);
        if (!teamOperationalUnit)
            return;

        resourceTeam.LoadRanges(teamOperationalUnit.AllocationRanges(), teamOperationalUnit.Id);*/
    }

    GetTeamFromResourceAllocationId(teamResourceAllocationId: number): ITeamViewModel {
        const foundTeams = this.Teams().filter((t: TeamViewModel) => {
            let foundAllocations = t.Allocations().filter((a: TeamAllocationViewModel) => {
                return a.Id == teamResourceAllocationId;
            });
            return foundAllocations.length > 0;
        });
        if (foundTeams.length == 0) return null;
        return foundTeams[0];
    }
}

export class ResourceViewModel implements ITeamsManagerResource {
    public Id: ko.Observable<number> = ko.observable();
    public Name: ko.Observable<string> = ko.observable();
    public ServiceOrders: ko.ObservableArray<IHumanResourceOrders> = ko.observableArray([]);
    public StartDate: ko.Computed<Moment>;
    public EndDate: ko.Computed<Moment>;

    public OperationalUnits: ko.ObservableArray<ITeamsManagerOperationalUnit> = ko.observableArray([]);

    constructor(resource: IHumanResource) {
        this.Id(resource.Resource.Id);
        this.Name(resource.Resource.Name + " " + resource.Resource.Surname);
        this.ServiceOrders(resource.Orders);

        this.StartDate = ko.computed(() => {
            let minDate = moment("2100-01-01");
            this.OperationalUnits().forEach((r: ITeamsManagerOperationalUnit) => {
                minDate = moment(r.StartDate()) < minDate ? moment(r.StartDate()) : minDate;
            });
            return minDate;
        });

        this.EndDate = ko.computed(() => {
            let maxDate = moment("1900-01-01");
            this.OperationalUnits().forEach((r: ITeamsManagerOperationalUnit) => {
                maxDate = moment(r.EndDate()) > maxDate ? moment(r.EndDate()) : maxDate;
            });
            return maxDate;
        });
    }

    AddOperationalUnit(ou: OperationalUnitForResourceViewModel) {
        this.OperationalUnits.push(ou);
    }

    public GetOperationalUnit(ouId: number): ITeamsManagerOperationalUnit {
        const ous = this.OperationalUnits().filter((o: ITeamsManagerOperationalUnit) => {
            return o.Id == ouId;
        });
        if (ous.length == 0) return null;
        return ous[0];
    }

    public GetOperationalUnits(): ITeamsManagerOperationalUnit[] {
        return this.OperationalUnits();
    }

    RemoveOperationalUnit(ouId: number) {
        const ouToRemove = this.OperationalUnits().filter((ou: ITeamsManagerOperationalUnit) => ou.Id == ouId);
        ouToRemove.forEach((ou: ITeamsManagerOperationalUnit) => this.OperationalUnits.remove(ou));
    }
}

export class OperationalUnitForResourceViewModel implements ITeamsManagerOperationalUnit {
    public Id: number;
    public Name: ko.Observable<string> = ko.observable();
    public StartDate: ko.Computed<Moment>;
    public EndDate: ko.Computed<Moment>;
    public ServiceOrders: ko.ObservableArray<IHumanResourceOrders> = ko.observableArray([]);
    public Teams: ko.ObservableArray<TeamForResourceViewModel> = ko.observableArray();

    constructor(private ou: IOperationalUnit, serviceOrders: IHumanResourceOrders[]) {
        this.Id = ou.Id;
        this.Name(ou.Name);

        this.ServiceOrders(serviceOrders);

        this.StartDate = ko.computed(() => {
            let minDate = moment("2100-01-01");
            this.Teams().forEach((r: TeamForResourceViewModel) => {
                minDate = moment(r.StartDate()) < minDate ? moment(r.StartDate()) : minDate;
            });
            return minDate;
        });

        this.EndDate = ko.computed(() => {
            let maxDate = moment("1900-01-01");
            this.Teams().forEach((r: TeamForResourceViewModel) => {
                maxDate = moment(r.EndDate()) > maxDate ? moment(r.EndDate()) : maxDate;
            });
            return maxDate;
        });
    }

    public isForOperationalUnit(ou: IOperationalUnit) {
        return this.ou == ou;
    }

    GetTeam(teamId: number): ITeamForResourceViewModel {
        const teams = this.Teams().filter((t: ITeamForResourceViewModel) => t.Id == teamId);
        if (teams.length == 0) return null;
        return teams[0];
    }
}

export class TeamForResourceViewModel implements ITeamForResourceViewModel {
    public Id: number;
    public Name: ko.Observable<string> = ko.observable();
    public StartDate: ko.Computed<Moment>;
    public EndDate: ko.Computed<Moment>;

    public AllocationRanges: ko.ObservableArray<ITeamAllocationRangesForTeamViewModel> = ko.observableArray([]);

    constructor(team: ITeamViewModel) {
        this.Id = team.Id();
        this.Name(team.Name());

        team.Name.subscribe((n) => this.Name(n));

        this.StartDate = ko.computed(() => {
            let minDate = moment("2100-01-01");
            this.AllocationRanges().forEach((r: ITeamAllocationRangesForTeamViewModel) => {
                minDate = moment(r.StartDate()) < minDate ? moment(r.StartDate()) : minDate;
            });
            return minDate;
        });

        this.EndDate = ko.computed(() => {
            let maxDate = moment("1900-01-01");
            this.AllocationRanges().forEach((r: ITeamAllocationRangesForTeamViewModel) => {
                maxDate = moment(r.EndDate()) > maxDate ? moment(r.EndDate()) : maxDate;
            });
            return maxDate;
        });

        //this.LoadRanges(alloc.AllocationRanges(), teamAllocationId);
    }

    public isForTeam(teamId: number) {
        return this.Id == teamId;
    }

    public LoadRanges(ranges: IResourceAllocationRangeViewModel[], teamAllocationId: number): void {
        this.AllocationRanges(
            ranges.map((range: IResourceAllocationRangeViewModel) => {
                return this.createAllocationRangeViewModel(range.getData(teamAllocationId));
            })
        );
    }

    private createAllocationRangeViewModel(range: IResourceAllocationRange): ITeamAllocationRangesForTeamViewModel {
        return new AllocationRangesForTeamViewModel(range);
    }
}

export class AllocationRangesForTeamViewModel implements ITeamAllocationRangesForTeamViewModel {
    public Id: number;
    public TeamResourceAllocationId: number;
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public Monday: ko.Observable<number> = ko.observable();
    public Tuesday: ko.Observable<number> = ko.observable();
    public Wednesday: ko.Observable<number> = ko.observable();
    public Thursday: ko.Observable<number> = ko.observable();
    public Friday: ko.Observable<number> = ko.observable();
    public Saturday: ko.Observable<number> = ko.observable();
    public Sunday: ko.Observable<number> = ko.observable();
    public OperationalUnitId: number;
    public AllocationType: ko.Observable<number> = ko.observable();

    constructor(private range: IResourceAllocationRange) {
        this.Id = range.Id;
        this.TeamResourceAllocationId = range.TeamResourceAllocationId;
        this.StartDate(range.StartDate);
        this.EndDate(range.EndDate);
        this.Amount(range.Amount);
        this.Monday(range.Monday);
        this.Tuesday(range.Tuesday);
        this.Wednesday(range.Wednesday);
        this.Thursday(range.Thursday);
        this.Friday(range.Friday);
        this.Saturday(range.Saturday);
        this.Sunday(range.Sunday);
        this.OperationalUnitId = range.OperationalUnitId;
        this.AllocationType(range.AllocationType);
    }

    public Contains(date: any): boolean {
        return moment(this.StartDate()) <= moment(date) && moment(this.EndDate()) >= moment(date);
    }

    public MustTrimRange(date: any): boolean {
        return moment(this.StartDate()) < moment(date) && moment(this.EndDate()) >= moment(date);
    }

    public IsCompatibleWith(other: ITeamAllocationRangesForTeamViewModel): boolean {
        if (moment(other.StartDate()).diff(moment(this.EndDate()), "days") != 1) return false;
        if (this.AllocationType() != other.AllocationType()) return this.IsHoursCompatibleWith(other);
        if (this.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage)
            return this.IsPercentageCompatibleWith(other);
        return this.IsHoursCompatibleWith(other);
    }

    private IsPercentageCompatibleWith(other: ITeamAllocationRangesForTeamViewModel): boolean {
        return this.Amount() == other.Amount();
    }

    private IsHoursCompatibleWith(other: ITeamAllocationRangesForTeamViewModel): boolean {
        let areCompatible = true;
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        daysOfWeek.forEach((day: string) => {
            areCompatible = areCompatible && (<any>this)[day]() == (<any>other)[day]();
        });
        return areCompatible;
    }

    public Clone(): ITeamAllocationRangesForTeamViewModel {
        return new AllocationRangesForTeamViewModel(this.range);
    }
}
