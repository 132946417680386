import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderTypesSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../ProlifeSdk/interfaces/job-order/IJobOrderType";

export class JobOrderTypeDisplayValue
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var result = ko.computed(() => {
            var options = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var jobOrderTypeSettingsManager : IJobOrderTypesSettingsManager = <IJobOrderTypesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.JobOrderType);
            var jobOrderTypes : IJobOrderType[] = jobOrderTypeSettingsManager.getJobOrderTypes();

            var founded : IJobOrderType = null;
            jobOrderTypes.forEach((type : IJobOrderType) => {
                if (type.Id == ko.utils.unwrapObservable(options.typeId)){
                    founded = type;
                    return;
                }
            });
            if (founded == null)
                return options.DefaultDescription || "";
            return founded.Description;
        });

        ko.applyBindingsToNode(element, { text: result }, undefined);
    }
}

ko.bindingHandlers["JobOrderTypeDisplayValue"] = new JobOrderTypeDisplayValue();
ko.virtualElements.allowedBindings["JobOrderTypeDisplayValue"] = true;
