import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IDialogComponent } from "../interfaces/IDialogsService";
import { ComponentUtils } from "./ComponentUtils";

export interface IDialogComponentParams {
    fade?: boolean;
    className?: string;
    closeLabel?: string;
    actionLabel?: string;
    noPrompt?: boolean;
    bodyClassName?: string;
    popover?: boolean;
}

export abstract class DialogComponentBase implements IDialogComponent {
    type: "DialogComponent" = "DialogComponent";
    title: ko.Observable<string> = ko.observable();
    modal: {
        close: (result?: any) => void;
    }

    constructor(protected params: IDialogComponentParams) {
        
    }

    private renderDefaultHeader() {
        return  <div class="modal-header">
                    {this.renderHeader()}
                </div>;
    }

    private renderDefaultBody() {
        const className = ComponentUtils.classNames("modal-body", this.params.bodyClassName);
        return  <div className={className}>
                    {this.renderBody()}
                </div>
    }

    private renderDefaultFooter() {
        return  <div class="modal-footer">
                    {this.renderFooter()}
                </div>
    }

    renderHeader() {
        if (this.params.popover) {
            return  <>
                        <button className="btn btn-danger btn-circle btn-xs popover-close" style={{ position: "absolute", right: "5px", top: "6px", margin: 0 }} data-bind="click: close">&times;&nbsp;{TextResources.ProlifeSdk.Close}</button>
                        <h3 className="popover-title" data-bind="text: title"></h3>
                    </>
        }

        return  <>
                    <button type="button" class="close" data-bind="click : close"></button>
                    <h4 class="modal-title" data-bind="text: title"></h4>
                </>
    }

    abstract renderBody();

    renderFooter() {
        return  <>
                    <a href="#" class="btn btn-default" data-bind="click: close">{this.params.closeLabel ?? TextResources.ProlifeSdk.Close}</a>
                    {!this.params.noPrompt && <a href="#" class="btn btn-primary" data-bind="click: action">{this.params.actionLabel ?? TextResources.ProlifeSdk.Save}</a>}
                </>;
    }

    public close() {
        this.modal.close();
    }

    get classes() { 
        return ComponentUtils.classNames("modal", this.params.className, {
            "fade": this.params.fade
        });
    }

    render() {
        let classes = ComponentUtils.classNames("modal", this.params.className, {
            "fade": this.params.fade
        });

        if (this.params.popover)
            return this.renderBody();

        return (/*<div class={classes} data-keyboard="false">*/
                    <div class="modal-dialog">
                        <div class="modal-content">
                            {this.renderDefaultHeader()}
                            {this.renderDefaultBody()}
                            {this.renderDefaultFooter()}
                        </div>
                    </div>
                /*</div>*/);
    }
}