import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { reloadNow, ComponentUtils, useEffect } from "../Core/utils/ComponentUtils";
import { IFileRepositoryService } from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { LazyImport } from "../Core/DependencyInjection";
import { IFileOrFolder } from "../ProlifeSdk/interfaces/files/IFileOrFolder";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type FilePickerInputProps = {
    label?: string;
    simple?: boolean;
    noFileName?: boolean;
    withPreview?: boolean;
    previewWidth?: number;
    previewHeight?: number;
    value: ko.Observable<IFileOrFolder>;
};

export function FilePickerInput(props: FilePickerInputProps) {
    const C = require("./FilePickerInput")._FilePickerInput as typeof _FilePickerInput;
    return <C {...props} />;
}

export class _FilePickerInput extends React.Component<FilePickerInputProps> {
    static defaultProps: Partial<FilePickerInputProps> = {
        noFileName: false,
        withPreview: false,
        simple: false,
        previewWidth: 200,
        previewHeight: 150,
    };

    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;

    readonly noPreview = "/Content/img/preview_200x150.png";
    previewPath: ko.Observable<string> = ko.observable(this.noPreview);

    constructor(props: FilePickerInputProps) {
        super(props);
        if (!props.value) props.value = ko.observable();

        useEffect(() => {
            const file = this.props.value();
            if (!file || !file.HasPreview) this.previewPath(this.noPreview);
            else this.previewPath(this.fileRepositoryService.getPreviewUrl(file));
        }, [this.props.value]);
    }

    private async pickFile() {
        const selection = await this.fileRepositoryService.openAsDialog("/");
        if (!selection || selection.length === 0) return;

        const [file] = selection;
        this.props.value(file);
    }

    private clear() {
        this.props.value(undefined);
    }

    render() {
        const fpi = this;

        const wrapWithLabel = (input: React.ReactElement) => {
            if (this.props.simple) return input;

            return (
                <div className="form-group">
                    <label className="control-label">{this.props.label}</label>
                    {input}
                </div>
            );
        };

        return ComponentUtils.bindTo(
            wrapWithLabel(
                <>
                    {this.props.withPreview && (
                        <div
                            className="fileinput-new thumbnail"
                            style={{
                                width: this.props.previewWidth,
                                height: this.props.previewHeight,
                                marginBottom: "5px",
                            }}>
                            <img data-bind={{ attr: { src: fpi.previewPath } }} style={{ height: "100%" }} alt="" />
                        </div>
                    )}
                    <div
                        className="btn-group btn-group-circle"
                        data-bind={{
                            css: {
                                "btn-group btn-group-circle flex-container no-gutters":
                                    !!fpi.props.value() && fpi.props.noFileName,
                                "input-group": !fpi.props.value() || !fpi.props.noFileName,
                            },
                        }}>
                        {!this.props.noFileName && (
                            <div
                                className="form-control uneditable-input span3"
                                data-bind={{ if: !!fpi.props.value() }}>
                                <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                <span
                                    className="fileinput-filename"
                                    data-bind={{ text: fpi.props.value()?.Name }}></span>
                            </div>
                        )}
                        <span
                            className="input-group-addon btn default btn-file btn-circle flex-1"
                            data-bind={{ asyncClick: fpi.pickFile.bind(fpi) }}>
                            <span className="fileinput-new" data-bind={{ hidden: !!fpi.props.value() }}>
                                Seleziona...
                            </span>
                            <span className="fileinput-exists" data-bind={{ visible: !!fpi.props.value() }}>
                                Cambia...
                            </span>
                        </span>
                        <a
                            href="#"
                            className="input-group-addon btn red fileinput-exists flex-1"
                            data-bind={{ visible: !!fpi.props.value(), click: fpi.clear.bind(fpi) }}>
                            Rimuovi
                        </a>
                    </div>
                </>
            ),
            this,
            "fpi"
        ) as React.ReactElement;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(FilePickerInput);
}
