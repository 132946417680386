import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ArticleRequirement, ArticleRequirements } from "./ArticleRequirements";
import { Table, ITableItem } from "../../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { SecondaryRow } from "../../../../../../Components/TableComponent/SecondaryRow";
import { ArticleRequirementsInfo } from "./ArticleRequirementsInfo";
import { IDataSourceModel } from "../../../../../../DataSources/IDataSource";
import { With } from "../../../../../../Components/IfIfNotWith";
import { ComponentUtils } from "../../../../../../Core/utils/ComponentUtils";
import { TableFilter } from "../../../../../../Components/TableComponent/TableFilter";
import { Delay } from "../../../../../../Decorators/Delay";
import { DateTimeInput } from "../../../../../../Components/DateTimeInput";
import { NumberInput } from "../../../../../../Components/NumberInput";

const { classes } = jss.createStyleSheet({
    sourceArticles: {
        "& .checkbox-col": {
            width: "30px"
        },

        "& .warehouse-col": {
            width: "30%"
        },

        "& .selected-articles-col": {
            width: "13%"
        },

        "& .last-inspection-date-col": {
            width: "125px"
        },

        "& .action-col": {
            width: "40px"
        },

        "& .dropdown-menu": {
            "& .help-block": {
                whiteSpace: "normal",
                fontSize: "13px",
                fontWeight: "normal",
                fontFamily: '"Open Sans", sans-serif'
            }
        }
    }
}).attach();

export type SourceArticlesTableFilters = {
    MaxShownArticlesNumberPerWarehouse?: number;
    LastInspectionDateFilter?: Date;
    SelectedCustomers?: number[];
    SelectedJobOrders?: number[];
    SelectedWarehouses?: number[];
}

export interface IArticlesTableProps {
    articles: ko.ObservableArray<ArticleRequirements>;

    defaultDateFilter?: Date;
    defaultMaxShownArticlesFilter?: number;

    showLastInspectionDateColumn?: boolean;
    showDocumentColumn?: boolean;
    sourceFromStocks?: boolean;

    onFiltersChanges?: (filters: SourceArticlesTableFilters) => Promise<void>;
}

export class SourceArticlesTable {
    static defaultProps: Partial<IArticlesTableProps> = {
        defaultMaxShownArticlesFilter: 100
    };

    public SelectAllSourceArticles: ko.Computed<boolean>;
    public Articles: ko.ObservableArray<ArticleRequirements> = ko.observableArray([]);
    public FilteredArticles: ko.ObservableArray<ArticleRequirements> = ko.observableArray([]);

    public MaxShownArticlesNumberPerWarehouse: ko.Observable<number> = ko.observable(100);
    public LastInspectionDateFilter: ko.Observable<Date> = ko.observable();

    private selectedWarehouses: number[] = [];
    private selectedCustomers: number[] = [];
    private selectedJobOrders: number[] = [];

    private subscriptions: ko.Subscription[] = [];

    constructor(public props: IArticlesTableProps) {
        this.LastInspectionDateFilter(this.props.defaultDateFilter);
        this.MaxShownArticlesNumberPerWarehouse(this.props.defaultMaxShownArticlesFilter);

        this.Articles = this.props.articles;
        this.FilteredArticles(this.Articles());

        this.SelectAllSourceArticles = ko.computed({
            read: () => {
                let articles = this.FilteredArticles();
                let selectedArticles = articles.filter(a => a.Selected());

                if (selectedArticles.length === 0)
                    return false;

                if (selectedArticles.length !== articles.length && selectedArticles.length > 0)
                    return null;

                return true;
            },
            write: (value) => {
                let articles = this.FilteredArticles();
                for (let article of articles)
                    article.Selected(value);
            }
        });

        let dateSub = this.LastInspectionDateFilter.subscribe(() => {
            this.applyFilters(true);
        });

        let maxSub = this.MaxShownArticlesNumberPerWarehouse.subscribe(() => {
            this.applyFilters(true);
        });

        this.subscriptions.push(dateSub);
        this.subscriptions.push(maxSub);
    }

    public componentWillUnmount(): void {
        for (let sub of this.subscriptions)
            sub.dispose();
    }

    public render() {
        let articlesTableComponent = this;
        let article: IDataSourceModel<number, ArticleRequirements>;
        
        let { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<ArticleRequirements>();
        let { getKey, getLabel } = ComponentUtils.useGetter<ArticleRequirements, number>();

        return (
            <With data={this} as="articlesTableComponent">
                {() => (
                    <Table compact={true} scrollable={true} striped={true} fixedLayout={true} dataSource={{ array: this.FilteredArticles, factory: this.sourceArticlesFactory.bind(this) }} rowAs="article" className={classes.sourceArticles}>
                        <Column className="checkbox-col">
                            <ColumnHeader>
                                <input type="checkbox" data-bind={{ checkbox: articlesTableComponent.SelectAllSourceArticles }}></input>
                            </ColumnHeader>
                            <ColumnBody>
                                <input type="checkbox" data-bind={{ checkbox: article.model.Selected }}></input>
                            </ColumnBody>
                        </Column>
                        <Column className="warehouse-col" sorter={sortString(m => m.Warehouse)}>
                            <ColumnHeader>
                                {() =>  <>
                                            <span>{TextResources.Warehouse.Warehouse}</span>
                                            <TableFilter filterSource={this.Articles} itemLabelGetter={getLabel((i) => i.Warehouse)} itemKeyGetter={getKey((i) => i.WarehouseId)} onSelectionChange={this.onWarehousesSelectionChanges.bind(this)} />
                                        </>
                                }
                            </ColumnHeader>
                            <div className="text-ellipsis" data-bind={{ attr: { title: article.model.Warehouse } }}>
                                <span data-bind={{ text: article.model.Warehouse }}></span>
                            </div>
                        </Column>
                        <Column className="selected-articles-col" sorter={sortNumber(m => m.SelectedRequirementsInfoNumber())}>
                            <ColumnHeader>
                                {() =>  <>
                                            <span>
                                                {this.props.sourceFromStocks ? TextResources.Warehouse.SelectedArticles : TextResources.Warehouse.SelectedRequirements}
                                                {this.props.sourceFromStocks && (
                                                    <TableFilter customFilterStateHandler={() => this.MaxShownArticlesNumberPerWarehouse() !== null && this.MaxShownArticlesNumberPerWarehouse() !== undefined}>
                                                        {() => (
                                                            <NumberInput
                                                                value={this.MaxShownArticlesNumberPerWarehouse}
                                                                format="0,0"
                                                                label={TextResources.Warehouse.MaxShownArticlesNumberPerWarehouseLabel}
                                                                placeholder={TextResources.Warehouse.MaxShownArticlesNumberPerWarehousePlaceholder}
                                                                helpText={TextResources.Warehouse.MaxShownArticlesNumberPerWarehouseHelpText}
                                                                selectOnFocus
                                                                nullable
                                                            />
                                                        )}
                                                    </TableFilter>
                                                )}
                                            </span>
                                        </>
                                }
                            </ColumnHeader>
                            <span>
                                <span data-bind={{ numberText: article.model.SelectedRequirementsInfoNumber, format: '0,0' }}></span>/<span data-bind={{ numberText: article.model.RequirementsInfo().length, format: '0,0' }}></span>
                            </span>
                        </Column>
                        <Column title={TextResources.Warehouse.Customer} sorter={sortString(m => m.Customer)}>
                            <ColumnHeader>
                                {() =>  <>
                                            <span>{TextResources.Warehouse.Customer}</span>
                                            <TableFilter filterSource={this.Articles} itemLabelGetter={getLabel((i) => i.Customer)} itemKeyGetter={getKey((i) => i.CustomerId)} onSelectionChange={this.onCustomersSelectionChanges.bind(this)} />
                                        </>
                                }
                            </ColumnHeader>
                            <div className="text-ellipsis" data-bind={{ attr: { title: (!article.model.Customer ? '' : article.model.Customer) } }}>
                                <span data-bind={{ text: (!article.model.Customer ? 'N/A' : article.model.Customer) }}></span>
                            </div>
                        </Column>
                        <Column title={TextResources.Warehouse.JobOrder} sorter={sortString(m => m.JobOrder)}>
                            <ColumnHeader>
                                {() =>  <>
                                            <span>{TextResources.Warehouse.JobOrder}</span>
                                            <TableFilter filterSource={this.Articles} itemLabelGetter={getLabel((i) => i.JobOrder)} itemKeyGetter={getKey((i) => i.JobOrderId)} onSelectionChange={this.onJobOrdersSelectionChanges.bind(this)}></TableFilter>
                                        </>
                                }
                            </ColumnHeader>
                            <div className="text-ellipsis" data-bind={{ attr: { title: (!article.model.JobOrder ? '' : article.model.JobOrder) } }}>
                                <span data-bind={{ text: (!article.model.JobOrder ? 'N/A' : article.model.JobOrder) }}></span>
                            </div>
                        </Column>
                        {!!this.props.showLastInspectionDateColumn && (
                            <Column className="last-inspection-date-col" sorter={sortDate(m => m.LastInspectionDate)}>
                                <ColumnHeader>
                                    {() =>  <>
                                                <span>{TextResources.Warehouse.LastInspectionDate}</span>
                                                <TableFilter customFilterStateHandler={() => !!this.LastInspectionDateFilter() }>
                                                    {() => (
                                                        <DateTimeInput
                                                            value={this.LastInspectionDateFilter}
                                                            placeholder={TextResources.Warehouse.LastInspectionDateFilterPlaceholder}
                                                            label={TextResources.Warehouse.LastInspectionDateFilterLabel}
                                                            allowClear
                                                            dateonly
                                                            helpText={TextResources.Warehouse.LastInspectionDateFilterHelpText}
                                                        />
                                                    )}
                                                </TableFilter>
                                            </>
                                    }
                                </ColumnHeader>
                                <span data-bind={{ dateTimeText: article.model.LastInspectionDate, dateTextNoValue: 'N/A' }}></span>
                            </Column>
                        )}
                        <Column className="action-col text-center">
                            <button type="button" className="btn btn-xs" data-bind={{ toggle: article.model.ShowRequirementsInfo }}>
                                <i className="fa" data-bind={{ css: { 'fa-chevron-down': !article.model.ShowRequirementsInfo(), 'fa-chevron-up': article.model.ShowRequirementsInfo } }}></i>
                            </button>
                        </Column>
                        <SecondaryRow visible={() => "article.model.ShowRequirementsInfo"}>
                            {(item: ITableItem<ArticleRequirements>) => (<td colSpan={this.props.showLastInspectionDateColumn ? 7 : 6}><ArticleRequirementsInfo articlesRequirement={item.Data.model.RequirementsInfo} factory={this.requirementsFactory.bind(this)} showDocumentColumn={!!this.props.showDocumentColumn}></ArticleRequirementsInfo></td>)}
                        </SecondaryRow>
                    </Table>
                )}
            </With>
        );
    }

    private onWarehousesSelectionChanges(warehouses: number[]): void {
        this.selectedWarehouses = warehouses;
        this.applyFilters();
    }
    
    private onCustomersSelectionChanges(customers: number[]): void {
        this.selectedCustomers = customers;
        this.applyFilters();
    }
    
    private onJobOrdersSelectionChanges(jobOrders: number[]): void {
        this.selectedJobOrders = jobOrders;
        this.applyFilters();
    }

    @Delay(100)
    private async applyFilters(reload: boolean = false): Promise<void> {
        // Questi due filtri vengono applicati lato server
        let dateFilter = this.LastInspectionDateFilter();
        let maxShownArticles = this.MaxShownArticlesNumberPerWarehouse();

        if (this.props.onFiltersChanges && reload)
            await this.props.onFiltersChanges({
                LastInspectionDateFilter: dateFilter,
                MaxShownArticlesNumberPerWarehouse: maxShownArticles,
                SelectedCustomers: this.selectedCustomers.slice(),
                SelectedJobOrders: this.selectedJobOrders.slice(),
                SelectedWarehouses: this.selectedWarehouses.slice()
            });

        let allItems = this.Articles();
        let filteredItems = [];

        for (let item of allItems) {
            if (this.selectedWarehouses.indexOf(item.WarehouseId) >= 0 
                && this.selectedCustomers.indexOf(item.CustomerId) >= 0 
                && this.selectedJobOrders.indexOf(item.JobOrderId) >= 0)
                filteredItems.push(item);
        }

        this.FilteredArticles(filteredItems);
    }

    private sourceArticlesFactory(article: ArticleRequirements): IDataSourceModel<number, ArticleRequirements> {
        return {
            id: article.WarehouseId,
            title: article.Warehouse,
            isGroup: false,
            isLeaf: true,
            model: article
        };
    }

    private requirementsFactory(article: ArticleRequirement): IDataSourceModel<number, ArticleRequirement> {
        return {
            id: article.ArticleId,
            title: article.ArticleCode + " - " + article.Article,
            isGroup: false,
            isLeaf: true,
            model: article
        };
    }
}