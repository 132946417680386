import * as ko from "knockout";
import { IResourceWork } from "../../../ProlifeSdk/interfaces/job-order/IResourceWork";

export class HumanWorkPeopleCosts
{
    public Description : string;
    public Salary : ko.Observable<number> = ko.observable(0);
    public hours : ko.Observable<number> = ko.observable(0);
    public overtimeHours : ko.Observable<number> = ko.observable(0);
    public allHours : ko.Observable<number> = ko.observable(0);
    public amount : ko.Observable<number> = ko.observable(0);

    constructor(resourceWork : IResourceWork)
    {
        this.Description = resourceWork.ResourceName;
        this.hours(resourceWork.WorkedHours-resourceWork.OvertimeHours);
        this.overtimeHours(resourceWork.OvertimeHours);
        this.allHours(resourceWork.WorkedHours);
        this.amount(resourceWork.WorkedHoursCost);
    }
}
