import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, PropsWithChildren } from "../Core/utils/ComponentUtils";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";
import { Bind } from "./Bind";
import jss from "jss";

const { classes } = jss
    .createStyleSheet({
        layoutScrollContainer: {
            scrollbarWidth: "thin",
            scrollbarColor: "#333 transparent",

            "&::-webkit-scrollbar": {
                width: "10px",
                height: "10px",
            },

            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#333",
                borderRadius: "20px",
                border: "4px solid transparent",
                backgroundClip: "content-box",
            },
        },
    })
    .attach();

// eslint-disable-next-line @typescript-eslint/ban-types
export function SideContent(props: PropsWithChildren<{}>) {
    return <LayoutColumn size={4}>{props.children}</LayoutColumn>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function Content(props: PropsWithChildren<{}>) {
    return <LayoutColumn size={8}>{props.children}</LayoutColumn>;
}

export function LayoutColumn(props: PropsWithChildren<{ size?: number; className?: string }>) {
    const extraClasses = {};
    extraClasses["col-md-" + props.size] = props.size > 0;
    const classes = ComponentUtils.classNames("flex-container", "flex-vertical", extraClasses, props.className);

    return <div className={classes}>{props.children}</div>;
}

export function VerticalLayout(props: PropsWithChildren<{ className?: string; noGutters?: boolean }>) {
    const classes = ComponentUtils.classNames("flex-container", "flex-full-height", props.className, {
        "no-gutters": props.noGutters,
    });

    return <div className={classes}>{props.children}</div>;
}

export function LayoutHeader(
    props: PropsWithChildren<{ className?: string; noOverflow?: boolean; style?: React.CSSProperties }>
) {
    const headerClasses = ComponentUtils.classNames("flex-container", props.className);
    const styles: React.CSSProperties = props.style || {};

    if (props.noOverflow) {
        styles.overflow = "hidden";
    }

    return (
        <div className={headerClasses} style={styles}>
            {props.children}
        </div>
    );
}

export function LayoutContent(props: PropsWithChildren<{ className?: string; noOverflow?: boolean }>) {
    const contentClasses = ComponentUtils.classNames(
        "flex-container",
        "flex-vertical",
        "flex-full-height",
        props.className
    );
    const styles: React.CSSProperties = {};

    if (props.noOverflow) {
        styles.overflow = "hidden";
    }

    return (
        <div className={contentClasses} style={styles}>
            {props.children}
        </div>
    );
}

export function LayoutWithHeader(
    props: PropsWithChildren<{ className?: string; noOverflow?: boolean; style?: CSSProperties }>
) {
    const classes = ComponentUtils.classNames("flex-container", "flex-full-height", "flex-vertical", props.className);

    const styles: React.CSSProperties = props.style ?? {};

    if (props.noOverflow) {
        styles.overflow = "hidden";
    }

    return (
        <div className={classes} style={styles}>
            {props.children}
        </div>
    );
}

export function HorizontalLayout(props: PropsWithChildren<{ className?: string }>) {
    const classes = ComponentUtils.classNames("flex-container", "flex-full-height", "flex-vertical", props.className);

    return <div className={classes}>{props.children}</div>;
}

export function LayoutRow(
    props: PropsWithChildren<{
        className?: string;
        size?: number;
        separator?: "top" | "bottom";
        noOverflow?: boolean;
        autoSize?: boolean;
    }>
) {
    const flexProp = "flex-" + (props.size ?? 1);
    const flexObj = {};
    flexObj[flexProp] = !props.autoSize;

    const classes = ComponentUtils.classNames("flex-container", "flex-vertical", flexObj, props.className);
    const separatorMapping = { top: "borderTop", bottom: "borderBottom" };
    const styles: React.CSSProperties = {};
    if (props.separator) {
        styles[separatorMapping[props.separator]] = "1px solid #ddd";
    }

    if (props.noOverflow) {
        styles.overflow = "hidden";
    }

    return (
        <div className={classes} style={styles}>
            {props.children}
        </div>
    );
}

LayoutWithHeader.Header = LayoutHeader;
LayoutWithHeader.Content = LayoutContent;

VerticalLayout.Column = LayoutColumn;
HorizontalLayout.Row = LayoutRow;

type GridProps = PropsWithChildren<{
    className?: string;
    columns: (string | number)[];
    rows: (string | number)[];
    autoSizeMode?: "min" | "fit" | "max";
    style?: CSSProperties;
    noOverflow?: boolean;
    bindings?: Record<string, unknown>;
    columnGap?: number | string;
    rowGap?: number | string;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
function _Grid(props: GridProps) {
    const templateColumns = Array.isArray(props.columns) ? props.columns.join(" ") : props.columns;
    const templateRows = Array.isArray(props.rows) ? props.rows.join(" ") : props.rows;
    const autosizeMode =
        props.autoSizeMode === "min"
            ? "min-content"
            : props.autoSizeMode === "fit"
            ? "minmax(1, auto)"
            : props.autoSizeMode === "max"
            ? "max-content"
            : "min-content";
    const className = ComponentUtils.classNames("flex-1", props.className);
    const style = {
        display: "grid",
        gridTemplateColumns: templateColumns,
        gridTemplateRows: templateRows,
        gridAutoRows: autosizeMode,
        overflow: props.noOverflow ? "hidden" : "unset",
        columnGap: props.columnGap,
        rowGap: props.rowGap,
        ...props.style,
    };

    return (
        <Bind bind={props.bindings}>
            <div className={className} style={style}>
                {props.children}
            </div>
        </Bind>
    );
}

_Grid.Cell = function _Cell(
    props: PropsWithChildren<{
        column: string | number;
        row: string | number;
        style?: CSSProperties;
        className?: string;
        noOverflow?: boolean;
    }>
) {
    if (props.noOverflow === null || props.noOverflow === undefined) props.noOverflow = true;

    const style = {
        gridColumn: props.column,
        gridRow: props.row,
        overflow: props.noOverflow ? "hidden" : "unset",
        ...props.style,
    };

    const className = ComponentUtils.classNames("flex-container", "flex-1", props.className);

    return (
        <div className={className} style={style}>
            {props.children}
        </div>
    );
};

function _ScrollContainer(
    props: PropsWithChildren<{
        style?: CSSProperties;
        scrollable?: boolean;
        onClick?: (e: MouseEvent & { currentTarget: EventTarget & HTMLDivElement }) => void;
        className?: string;
        verticalOnly?: boolean;
        systemScrollable?: boolean;
    }>
) {
    const { style: otherStyle, scrollable, children, className, verticalOnly, systemScrollable, ...other } = props;
    const style = { ...otherStyle, overflow: systemScrollable ? "auto" : "hidden" };
    const classNames = ComponentUtils.classNames(
        "flex-container",
        "flex-1",
        "flex-vertical",
        classes.layoutScrollContainer,
        className
    );

    let bindings = "";
    if (scrollable) {
        bindings = "slimScroll: 'flex'";
        if (verticalOnly) bindings += ", slimScrollVerticalOnly: true";
    }

    return (
        <div className={classNames} style={style} data-bind={bindings} {...other}>
            {children}
        </div>
    );
}
export namespace Layout {
    export const WithHeader = LayoutWithHeader;
    export const Horizontal = VerticalLayout;
    export const Vertical = HorizontalLayout;

    export const Grid = _Grid;
    export const ScrollContainer = _ScrollContainer;
}
