import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/12/2018
 * Time: 12:06
 * To change this template use File | Settings | File Templates.
 */

import { ImportDataWizardDataSourceStep } from "../../../../../ProlifeSdk/prolifesdk/ImportDataWizardDataSourceStep";
import { IDocumentDataSource } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";

export class ElectronicInvoicingDataSourceStep extends ImportDataWizardDataSourceStep {
    constructor(dataSource: IDocumentDataSource) {
        super(dataSource, <IWizardInitializationInfo> {}, []);
    }

    public BeforeShow(): void {
        this.dataSource.Initialize(<IWizardInitializationInfo> {}, []);
    }

    public BeforeNext(): boolean
    {
        return this.dataSource.Validate();
    }
}