import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/09/2017
 * Time: 15:14
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ReschedulesDialog } from "../../wizard/dialogs/ReschedulesDialog";
import { FinishInterviewDialog } from "../../wizard/dialogs/FinishInterviewDialog";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInterruptedInterviewInfo } from "../../../../../ProlifeSdk/interfaces/survey-wizard/IInterruptedInterviewInfo";
import { ICallInQueueData } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";

export class CloseInterruptedInterviewDialog implements IDialog {
    public templateName: string = "close-interrupted-interview-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs";
    public title: string = ProlifeSdk.TextResources.SurveyWizard.CloseInterruptedInterviewDialogTitle;
    public modal: { close: (result?: any) => void; };

    public Message: ko.Observable<string> = ko.observable();
    
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    constructor(private interview: IInterruptedInterviewInfo, private campaignId: number) {
        this.Message(ProlifeSdk.TextResources.SurveyWizard.ResumeInterruptedInterviewMessage);
        let contact: string = !interview.BusinessName ? interview.Name + " " + interview.Surname : interview.BusinessName;
        this.Message(this.Message().replace("{contact}", contact).replace("{phone-number}", interview.PhoneNumber));

    }

    reschedules(): void {
        let fakeCall: ICallInQueueData = {
            Id: this.interview.Id,
            CampaignId: this.campaignId,
            QuestionnaireId: null,
            PeopleId: this.interview.PeopleId,
            AttemptNumber: this.interview.AttemptNumber,
            PreferentialOperatorId: null,
            RecallOnlyByPreferentialOperator: null,
            Priority: null,
            StateId: null,
            OperatorId: null,
            DateTimeToCall: null,
            LastJobOrderId: this.interview.LastJobOrderId
        };
        let reschedulesInterviewDialog = new ReschedulesDialog(fakeCall, this.interview.LastJobOrderId);
        this.dialogService.ShowModal<boolean>(reschedulesInterviewDialog)
            .then((result: boolean) => {
                if (!result)
                    return;
                this.modal.close();
            });
    }

    close(): void {
        var fakeCall: ICallInQueueData = {
            Id: this.interview.Id,
            CampaignId: this.campaignId,
            QuestionnaireId: null,
            PeopleId: this.interview.PeopleId,
            AttemptNumber: this.interview.AttemptNumber,
            PreferentialOperatorId: null,
            RecallOnlyByPreferentialOperator: null,
            Priority: null,
            StateId: null,
            OperatorId: null,
            DateTimeToCall: null,
            LastJobOrderId: this.interview.LastJobOrderId
        };
        var finishInterviewDialog = new FinishInterviewDialog(fakeCall, this.interview.LastJobOrderId);
        this.dialogService.ShowModal<boolean>(finishInterviewDialog)
            .then((result: boolean) => {
                if (!result)
                    return;
                this.modal.close();
            });
    }

    action(): void {
        location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/" + this.campaignId + "/" + ProlifeSdk.TextResources.SurveyWizard.WizardUrl + "/" + this.interview.Id + "/" + ProlifeSdk.TextResources.SurveyWizard.ResumedWizardUrl;
        this.modal.close();
    }
}