import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { classNames, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ICartsService } from "../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { ICart, ICartContentDetails } from "../../../../ProlifeSdk/interfaces/allocations/ICart";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../../../Components/Layouts";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { TextInput } from "../../../../Components/TextInput";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";

const styleSheet = jss.createStyleSheet({
    cartContent: {
        "& .element-column": {
            width: "calc(100% - 72px)",

            "& .flex-container": {
                padding: "5px 10px",

                "& .icon": {
                    fontSize: "12pt",
                },

                "& .title": {
                    fontSize: "12pt",
                },

                "& .sub-title": {
                    color: "grey",
                },
            },
        },

        "& .actions-column": {
            width: "90px",

            verticalAlign: "initial !important",
            padding: "5px 10px !important",
        },
    },
});
const { classes } = styleSheet.attach();

type CartContentDialogProps = {
    cartId: number;
};

export class CartContentDialog extends DialogComponentBase {
    static defaultProps: Partial<CartContentDialogProps> = {};

    @LazyImport(nameof<ICartsService>())
    private cartsService: ICartsService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    private Cart: ICart;
    private CartContent: ICartContentDetails[];
    private CartContentFiltered: ko.ObservableArray<ICartContentDetails> = ko.observableArray([]);

    private SearchField: ko.Observable<string> = ko.observable("");
    private SearchFieldSubscription: ko.Subscription;

    constructor(private props: CartContentDialogProps) {
        super({ noPrompt: true, className: "medium" });

        this.title("");
    }

    componentDidMount() {
        this.SearchFieldSubscription = this.SearchField.subscribe((value) => this.applyFilter(value));
        this.load();
    }

    componentWillUnmount() {
        this.SearchFieldSubscription.dispose();
        this.SearchFieldSubscription = null;
    }

    private async load(): Promise<void> {
        try {
            const cartWithContent = await this.cartsService.getCartWithContent(this.props.cartId);
            this.Cart = cartWithContent.Cart;
            this.CartContent = cartWithContent.Content;

            this.title(cartWithContent.Cart.Title);

            this.applyFilter("");
        } catch (e) {
            console.log(e);
        }
    }

    private applyFilter(filter: string): void {
        const filteredItems = [];
        const safeFilter = (filter || "").trim();

        for (const element of this.CartContent) {
            if (
                !safeFilter ||
                TextFiltersUtilities.contains(element.Title, safeFilter) ||
                TextFiltersUtilities.contains(element.WorkflowTitle, safeFilter) ||
                TextFiltersUtilities.contains(element.JobOrderTitle, safeFilter)
            )
                filteredItems.push(element);
        }

        this.CartContentFiltered(filteredItems);
    }

    action() {
        this.modal.close();
    }

    private goToJobOrder(jobOrderId: number): void {
        const url = this.jobOrderService.getJobOrderUrl(jobOrderId);
        window.open(url, "_blank");
    }

    private async showTaskEditor(jobOrderId: number, workflowId: number, taskId?: number): Promise<void> {
        console.log(workflowId, taskId);
        if (!taskId)
            await this.todoListService.ShowCreateNewTaskDialog(jobOrderId, workflowId, { initialViewAll: true });
        else await this.todoListService.ShowEditTaskDialog(taskId, { initialViewAll: true });
    }

    renderBody() {
        const cartContent = this;

        return ComponentUtils.bindTo(
            <Layout.Vertical className={classes.cartContent}>
                <Table
                    dataSource={Table.defaultDataSource(this.CartContentFiltered, (item) => ({
                        id: item.Id,
                        title: item.Title,
                    }))}
                    compact
                    systemScrollable
                    bordered
                    fixedLayout
                    rowAs="content"
                    useHoverEffect>
                    <Column title={TextResources.Allocations.CartElementColumnTitle} className="element-column">
                        <ColumnHeader>
                            {() => (
                                <>
                                    {TextResources.Allocations.CartElementColumnTitle}&nbsp;
                                    <TableFilter
                                        customFilterStateHandler={() => !!this.SearchField()}
                                        popoverContainer=".modal-body"
                                        popoverViewport=".modal-body">
                                        {() => (
                                            <TextInput
                                                value={this.SearchField}
                                                placeholder={TextResources.Allocations.CartContentFilterPlaceholder}
                                            />
                                        )}
                                    </TableFilter>
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(item: ITableItem<ICartContentDetails>) => (
                                <div className="flex-container">
                                    <div className="icon">
                                        <i
                                            className={
                                                "fa " +
                                                classNames({
                                                    "fa-sitemap": !item.Data.model.IsTask,
                                                    "fa-pencil-square-o": item.Data.model.IsTask,
                                                })
                                            }></i>
                                    </div>
                                    <div className="flex-fill text-ellipsis">
                                        <div className="title" title={item.Data.model.Title}>
                                            {item.Data.model.Title}
                                        </div>
                                        {item.Data.model.IsTask && (
                                            <div className="sub-title" title={item.Data.model.WorkflowTitle}>
                                                {item.Data.model.WorkflowTitle}
                                            </div>
                                        )}
                                        <div className="sub-title" title={item.Data.model.JobOrderTitle}>
                                            {item.Data.model.JobOrderTitle}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Allocations.ActionsColumnTitle} className="actions-column text-right">
                        <ColumnHeader>{() => <></>}</ColumnHeader>
                        <ColumnBody>
                            {(item: ITableItem<ICartContentDetails>) => (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-xs"
                                        title={TextResources.Allocations.GoToJobOrder}
                                        onClick={() => this.goToJobOrder(item.Data.model.JobOrderId)}>
                                        <i className="fa fa-share"></i>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-xs"
                                        title={TextResources.Allocations.EditCartElement}
                                        onClick={() =>
                                            this.showTaskEditor(
                                                item.Data.model.JobOrderId,
                                                item.Data.model.WorkflowId,
                                                item.Data.model.IsTask ? item.Data.model.ElementId : null
                                            )
                                        }>
                                        <i className="fa fa-edit"></i>
                                    </button>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                </Table>
            </Layout.Vertical>,
            this,
            "cartContent"
        );
    }
}
