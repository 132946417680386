import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { WorkflowEditDialogBase } from "./WorkflowEditDialogBase";
import { TemplateEditMaskDialog } from "../templates-designer/TemplateEditMask";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DetectChanges, DetectClassChanges } from "../../../../Core/ChangeDetection";
import { IWorkflowOutcome } from "../../../WorkflowOutcomesService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { TemplatesDataSource } from "../../../../DataSources/TemplatesDataSource";
import { Right } from "../../../../Core/Authorizations";
import {
    IWorkflowForTaskBoard,
    IWorkflowRelatedDocument,
    IWorkflowSectionsDefaultSettings,
    WorkflowRelatedEntities,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { ITodoListTemplate } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import {
    IWorkflowEditor,
    IWorkflowEditorListener,
    IWorkflowEditorResult,
} from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import {
    IPlannedResource,
    IAllocationsService,
    IWorkflowAllocationInfo,
} from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import {
    IWorkflowState,
    IWorkflowStatesSettingsManager,
} from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { WorkflowLink } from "./WorkflowLink";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { If, IfNot, With } from "../../../../Components/IfIfNotWith";
import { TabNav } from "../../../../Components/TabNavComponent";
import { CheckBox } from "../../../../Components/Checkbox";
import { Select } from "../../../../Components/Select";
import { ResourcesAndGroupsManager } from "../../../../Agenda/Agenda/ui/ResourcesAndGroupsManager";
import { Layout } from "../../../../Components/Layouts";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnFooter } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import {
    IWorkflowRelatedDocumentsEditorComponent,
    WorkflowRelatedDocumentsEditorComponent,
} from "../../../../Components/WorkflowRelatedDocumentsEditorComponent";
import { WorkflowSnapshotsEditor } from "./WorkflowSnapshotsEditor";
import { IWorkflowsDataSourceModel, WorkflowsDataSource } from "../../../../DataSources/WorkflowsDataSource";
import { WorkedHoursDefaultsEditor } from "../utils/WorkedHoursDefaultsEditor";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IWorkflowOutcomeSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { WorkflowCategoriesManagerDialog } from "../../settings/ui/WorkflowCategoriesSettingsViewModel";
import { ResponseBase } from "../../../../Core/response/ResponseBase";
import { RelatedEntitiesDetails } from "./RelatedEntitiesDetails";

const styleSheet = jss.createStyleSheet({
    workflowEditor: {
        "& .portlet": {
            marginBottom: "20px !important",
        },

        "& .workflowDetails": {
            "& .workflow-editor": {
                padding: "10px 15px",
                borderLeft: "5px solid #90d8e7",
                background: "aliceblue",
                position: "relative",
            },
        },

        "& .related-documents": {
            "& .header": {
                padding: "10px",
            },

            "& .documents-groups": {
                backgroundColor: "aliceblue",
                padding: "10px",

                "& .related-documents-actions": {
                    paddingTop: "5px",
                },

                "& .number": {
                    textAlign: "right",
                },
            },
        },

        "& .pushbutton-panel": {
            width: "185px",
            marginBottom: "0px",
            borderLeft: "1px solid #ddd",

            "& .navbar-collapse": {
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "100% !important",
            },

            "& .help": {
                position: "absolute",
                top: "-10px",
                right: "10px",
            },

            "& .sections-title": {
                padding: "0px 10px",
                marginTop: "5px",
                borderBottom: "1px solid #ddd",
            },

            "& li": {
                width: "100%",
                padding: "10px 10px",

                "& .btn.btn-transparent": {
                    backgroundColor: "transparent",
                },

                "& .btn-scroller": {
                    width: "calc(100% - 32px)",
                    textAlign: "left",
                    "-ms-text-overflow": "ellipsis",
                    "-o-text-overflow": "ellipsis",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                    "& i": {
                        color: "#555",
                        marginLeft: "-6px",
                    },
                },

                "& .btn-status": {
                    height: "34px",
                },

                "& .btn-general-data": {
                    backgroundColor: "#e5f1fb",

                    "&:hover": {
                        backgroundColor: "#67b5ca",
                    },
                },

                "& .btn-task-state": {
                    backgroundColor: "#9ae8fd",

                    "&:hover": {
                        backgroundColor: "#67b5ca",
                    },
                },

                "& .btn-resources": {
                    backgroundColor: "#ff9191",

                    "&:hover": {
                        backgroundColor: "#c56565",
                    },
                },

                "& .btn-advanced": {
                    backgroundColor: "#ffda7b",

                    "&:hover": {
                        backgroundColor: "#b5974b",
                    },
                },

                "& .btn-documents": {
                    backgroundColor: "#fffe7e",

                    "&:hover": {
                        backgroundColor: "#d6d529",
                    },
                },

                "& .btn-estimated-costs": {
                    backgroundColor: "#8bff8c",

                    "&:hover": {
                        backgroundColor: "#65d066",
                    },
                },

                "&.actions-separator": {
                    marginTop: "0px",
                    borderBottom: "1px solid #ddd",
                },

                "&.save-button, &.defaults-button": {
                    "& button": {
                        width: "100%",

                        "&:hover": {
                            color: "white",
                            backgroundColor: "#428bca",
                            borderColor: "#357ebd",
                        },
                    },

                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },

            "& a": {
                display: "inline-block",
                padding: "0px",
                backgroundColor: "transparent",
                color: "black",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type WorkflowEditDialogProps = {
    workflow: ITodoListWorkflow;
    showClosedJobOrdersOnSelector?: boolean;
    copyFromWorkflowMode?: boolean;
    importFromTemplateMode?: boolean;
    showSnapshotsEditorTab?: boolean;
    jobOrderId?: number;
};

type WorkflowEditorProps = WorkflowEditDialogProps & { forwardRef?: (editor: _WorkflowEditor) => void };

export class WorkflowEditDialog extends DialogComponentBase {
    static defaultProps: Partial<WorkflowEditDialogProps> = {
        showClosedJobOrdersOnSelector: false,
        copyFromWorkflowMode: false,
        importFromTemplateMode: false,
        showSnapshotsEditorTab: false,
    };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @Right("Todolist_SetWorkflowOutcome")
    private canSetWorkflowOutcome: boolean;

    private editor: _WorkflowEditor;

    constructor(private props: WorkflowEditDialogProps) {
        super({ className: "fullscreen" });

        if (this.props.copyFromWorkflowMode) this.title(TextResources.Todolist.NewWorkflowFromWorkflow);
        else if (this.props.importFromTemplateMode)
            this.title(ProlifeSdk.TextResources.Todolist.NewWorkflowFromTemplate);
        else
            this.title(
                this.props.workflow && this.props.workflow.Id
                    ? ProlifeSdk.TextResources.Todolist.EditWorkflow
                    : ProlifeSdk.TextResources.Todolist.CreateNewWorkflow
            );
    }

    ShowDialog(): Promise<IWorkflowEditorResult> {
        return this.dialogsService.ShowModal<IWorkflowEditorResult>(this);
    }

    close() {
        this.editor
            .closeEditor()
            .then((confirm: boolean) => {
                if (!confirm) return;

                this.modal.close(null);
            })
            .catch((e) => console.error(e));
    }

    action() {
        this.editor
            .action()
            .then((result) => {
                if (!result) return;

                this.modal.close(result);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    renderBody() {
        return <WorkflowEditor {...this.props} forwardRef={(editor) => (this.editor = editor)} />;
    }

    renderFooter() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workflowEditorDialog = this;

        return ComponentUtils.bindTo(
            <>
                {!this.props.copyFromWorkflowMode && !this.props.importFromTemplateMode && (
                    <>
                        {this.canSetWorkflowOutcome && (
                            <>
                                <If condition={this.editor.EditingOutcome}>
                                    {() => (
                                        <a
                                            href="#"
                                            className="btn btn-success pull-left"
                                            data-bind={{
                                                click: workflowEditorDialog.editor.EditOutcome.bind(
                                                    workflowEditorDialog.editor
                                                ),
                                            }}
                                        >
                                            Torna indietro
                                        </a>
                                    )}
                                </If>
                                <IfNot condition={this.editor.EditingOutcome}>
                                    {() => (
                                        <a
                                            href="#"
                                            className="btn btn-warning pull-left"
                                            data-bind={{
                                                click: workflowEditorDialog.editor.EditOutcome.bind(
                                                    workflowEditorDialog.editor
                                                ),
                                            }}
                                        >
                                            Modifica Esito
                                        </a>
                                    )}
                                </IfNot>
                            </>
                        )}

                        <a
                            href="#"
                            className="btn btn-success pull-left"
                            data-bind={{
                                click: workflowEditorDialog.editor.CreateTemplate.bind(workflowEditorDialog.editor),
                                visible: workflowEditorDialog.editor.CanCreateTemplate,
                            }}
                        >
                            Crea Template
                        </a>
                        <If condition={this.editor.CanPrintProductionDocument}>
                            {() => (
                                <a
                                    href="#"
                                    className="btn btn-success pull-left"
                                    data-bind={{
                                        click: workflowEditorDialog.editor.showProductionActivitiesPrintDialog.bind(
                                            workflowEditorDialog.editor
                                        ),
                                    }}
                                >
                                    Genera documento di produzione
                                </a>
                            )}
                        </If>
                    </>
                )}
                <a
                    href="#"
                    className="btn btn-primary"
                    data-bind={{ click: workflowEditorDialog.action.bind(workflowEditorDialog) }}
                >
                    {this.props.copyFromWorkflowMode
                        ? "Crea"
                        : this.props.importFromTemplateMode
                        ? "Crea da modello"
                        : "Salva"}
                </a>

                {!this.props.copyFromWorkflowMode && !this.props.importFromTemplateMode && (
                    <If condition={() => this.props.workflow?.Id && this.editor.CanDeleteWorkflows()}>
                        {() => (
                            <a
                                href="#"
                                className="btn btn-danger"
                                data-bind={{
                                    click: async () => {
                                        const result = await workflowEditorDialog.editor.DeleteWorkflow();
                                        if (result) workflowEditorDialog.modal.close(result);
                                    },
                                }}
                            >
                                Elimina
                            </a>
                        )}
                    </If>
                )}

                <a
                    href="#"
                    className="btn btn-default"
                    data-bind={{ click: workflowEditorDialog.close.bind(workflowEditorDialog) }}
                >
                    Chiudi
                </a>
            </>,
            this,
            "workflowEditorDialog"
        ) as React.ReactElement;
    }
}

export function WorkflowEditor(props: WorkflowEditorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./WorkflowEditDialog")._WorkflowEditor as typeof _WorkflowEditor;
    return <C {...props} />;
}

@DetectClassChanges
export class _WorkflowEditor extends WorkflowEditDialogBase implements IWorkflowEditor {
    @LazyImport(nameof<IJobOrderService>())
    protected jobOrderService!: IJobOrderService;
    @LazyImport(nameof<IProLifeSdkService>())
    protected sdkService: IProLifeSdkService;
    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationService: IAuthorizationService;
    @LazyImport(nameof<IUserInfo>())
    protected userInfoService: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    protected workflowsStates: IWorkflowStatesSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    protected outcomesSettings: IWorkflowOutcomeSettingsManager;

    @DetectChanges
    public OutcomeId: ko.Observable<number> = ko.observable();
    public Outcomes: ko.ObservableArray<IWorkflowOutcome> = ko.observableArray([]);
    @DetectChanges
    public Code: ko.Observable<string> = ko.observable();
    @DetectChanges
    public JobOrderId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public StartDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public EndDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public Status: ko.Observable<number> = ko.observable(0);

    public CanEditCode: ko.Observable<boolean> = ko.observable();
    public CanViewAll: ko.Observable<boolean> = ko.observable(false);
    public EditCode: ko.Observable<boolean> = ko.observable();
    public CodePreview: ko.Observable<string> = ko.observable();
    public CanEditTasksMultiplier: ko.Observable<boolean> = ko.observable(false);
    public CanPrintProductionDocument: ko.Observable<boolean> = ko.observable(false);
    public LockShowAlertOnUnestimatedTasks: ko.Computed<boolean>;
    public LockWorkflowMustBePlanned: ko.Computed<boolean>;
    public LockActivitiesMustBeReviewed: ko.Computed<boolean>;
    public LockWorkflowMustBeRelatedToCustomerOrders: ko.Computed<boolean>;
    public TitleHasFocus: ko.Observable<boolean> = ko.observable(false);
    public CanEditSnapshots: ko.Computed<boolean>;
    public JobOrdersDataSource: JobOrdersDataSource;
    public WorkflowsDataSource: WorkflowsDataSource = new WorkflowsDataSource();
    public RelatedDocumentsEditor: ko.Observable<IWorkflowRelatedDocumentsEditorComponent> = ko.observable();
    public States: IWorkflowState[] = [];

    //---- Property per gestione interfaccia
    public ShowAllocationsInfo: ko.Observable<boolean> = ko.observable(false);

    public GeneralDataScroller: ko.Observable<boolean> = ko.observable(false);
    public WorkflowTaskHelpersScroller: ko.Observable<boolean> = ko.observable(false);
    public ResourcesScroller: ko.Observable<boolean> = ko.observable(false);
    public AdvancedSettingsScroller: ko.Observable<boolean> = ko.observable(false);

    public WorkflowTaskHelpersOpened: ko.Observable<boolean> = ko.observable(false);
    public ResourcesOpened: ko.Observable<boolean> = ko.observable(false);
    public AdvancedSettingsOpened: ko.Observable<boolean> = ko.observable(false);

    public ShowRelatedDocuments: ko.Observable<boolean> = ko.observable(false);
    public ShowSnapshotsEditor: ko.Observable<boolean> = ko.observable(false);
    public ShowPrerequisites: ko.Observable<boolean> = ko.observable(false);
    public ShowWorkflowData: ko.Observable<boolean> = ko.observable(true);

    public ShowSourceTemplateField: ko.Observable<boolean> = ko.observable(true);
    //----

    protected Id = 0;

    protected updatingBudgetTotals = false;
    protected nextFakeId = -1;
    protected listeners: IWorkflowEditorListener[] = [];

    public PlannedResources: ko.ObservableArray<IPlannedResource> = ko.observableArray([]);
    public AllocationInfo: ko.ObservableArray<IWorkflowAllocationInfo> = ko.observableArray([]);
    public EstimatedHours: ko.Observable<number> = ko.observable();
    public AllocatedHours: ko.Observable<number> = ko.observable();
    public UnallocatedHours: ko.Observable<number> = ko.observable();
    public IsCompletelyAllocated: ko.Computed<boolean>;
    public IsPartiallyAllocated: ko.Computed<boolean>;
    public IsUnestimated: ko.Computed<boolean>;
    public HasAllocatedHours: ko.Computed<boolean>;
    public HasAllocatedElements: ko.Computed<boolean>;
    public AllocationPercentage: ko.Computed<number>;
    public HasAllocationInfo: ko.Computed<boolean>;
    public HasNotEstimatedElements: ko.Observable<boolean> = ko.observable();
    public HasNotEstimatedElementsAllocated: ko.Observable<boolean> = ko.observable();

    public CanDeallocateFromCart: ko.Observable<boolean> = ko.observable();

    public CanEditOutcome: ko.Observable<boolean> = ko.observable(false);
    public EditingOutcome: ko.Observable<boolean> = ko.observable();
    public CanCreateTemplate: ko.Observable<boolean> = ko.observable(true);
    public CanDeleteWorkflows: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public ShowInWorkflowId: ko.Observable<number> = ko.observable();

    @DetectChanges
    public Links: ko.ObservableArray<WorkflowLink> = ko.observableArray();
    public NewWorkflowLink: WorkflowLink;

    @DetectChanges
    public SourceTemplateId: ko.Observable<number> = ko.observable();
    public TemplatesDataSource: TemplatesDataSource = new TemplatesDataSource();

    @Right("TaskBoard_CanEditSourceTemplate")
    private canEditSourceTemplate: boolean;
    @Right("!Allocations")
    private allocationsModuleEnabled: boolean;
    @Right("Allocations_Start")
    private canStartAllocation: boolean;
    @Right("Todolist_TasksMultipliersMassiveEdit")
    private canEditTasksMultipliersInMassiveMode: boolean;
    @Right("TaskBoard_EnableWorkflowSnapshotManagement")
    private canManageWorkflowSnapshot: boolean;
    @Right("TaskBoard_ViewHoursReportingSection")
    private canViewHourReportingSection: boolean;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    private workflow: ITodoListWorkflow;

    public LockJobOrderField: ko.Computed<boolean>;
    public LockSourceTemplateField: ko.Computed<boolean>;

    /* --- COPIA DA PIANO --- */
    public SourceJobOrderId: ko.Observable<number> = ko.observable();
    public SourceWorkflowId: ko.Observable<number> = ko.observable();

    public SourceWorkflowsDataSource: WorkflowsDataSource = new WorkflowsDataSource();

    public IsSourceWorkflowSelected: ko.Computed<boolean>;
    /* --- --- --- --- --- --- */

    /* --- CREAZIONE DA TEMPLATE --- */
    public TemplateId: ko.Observable<number> = ko.observable();
    public IsTemplateSelected: ko.Computed<boolean>;
    /* --- --- --- --- --- --- */

    constructor(private props: WorkflowEditorProps) {
        super();
        this.workflow = this.props.workflow;

        this.JobOrdersDataSource = new JobOrdersDataSource(true, false);
        this.JobOrdersDataSource.setViewFilters(true, true, this.CanViewAll());
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setUserId(this.userInfoService.getIdUser());
        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(false);
        this.JobOrdersDataSource.setShowClosed(this.props.showClosedJobOrdersOnSelector);

        this.WorkflowsDataSource.setUserId(this.userInfoService.getIdUser());
        this.WorkflowsDataSource.setViewFilters(true, true, this.CanViewAll());
        this.WorkflowsDataSource.setWorkFilters(true);

        this.JobOrderId.subscribe((id: number) => {
            this.WorkflowsDataSource.setJobOrders(id);
        });

        this.CanViewAll.subscribe((viewAll: boolean) => {
            this.JobOrdersDataSource.setViewFilters(true, true, viewAll);
            this.WorkflowsDataSource.setViewFilters(true, true, viewAll);
        });

        //Se lo stato in cui si trova il piano è stato eliminato logicamente devo forzarlo comunque nell'elenco
        this.States = this.workflowsStates.getStates(true).filter((s: IWorkflowState) => {
            return !s.Deleted || (this.workflow && s.Id == this.workflow.Status);
        });
        this.Outcomes(this.outcomesSettings.getOutcomes());

        this.CanEditCode(this.authorizationService.isAuthorized("TaskBoard_EditWorkflowCode"));
        this.CanEditTasksMultiplier(this.authorizationService.isAuthorized("Todolist_TasksMultipliersMassiveEdit"));
        this.CanEditOutcome(this.authorizationService.isAuthorized("Todolist_SetWorkflowOutcome"));
        this.CanCreateTemplate(this.authorizationService.isAuthorized("Todolist_Start"));
        this.CanDeleteWorkflows(this.authorizationService.isAuthorized("TaskBoard_DeleteWorkflow"));

        this.CanEditSnapshots = ko.computed(() => {
            return !this.IsNew();
        });

        if (this.canStartAllocation && this.workflow && this.workflow.Id > 0) {
            this.allocationsService.isWorkflowDirectlyAllocated(this.workflow.Id).then((result: boolean) => {
                this.CanDeallocateFromCart(result);
            });
        }

        if (this.workflow) {
            this.NewWorkflowLink = new WorkflowLink(this.workflow.Id, {
                Id: 0,
                WorkflowId: this.workflow.Id,
                Type: "",
                ReferenceId: 0,
            });
        }

        this.IsUnestimated = ko.computed(() => {
            return this.EstimatedHours() == 0;
        });

        this.HasAllocatedHours = ko.computed(() => {
            return this.AllocatedHours() > 0;
        });

        this.HasAllocatedElements = ko.computed(() => {
            return this.HasAllocatedHours() || this.HasNotEstimatedElementsAllocated();
        });

        this.IsCompletelyAllocated = ko.computed(() => {
            return (
                this.EstimatedHours() == this.AllocatedHours() &&
                !this.IsUnestimated() &&
                !this.HasNotEstimatedElementsAllocated()
            );
        });

        this.IsPartiallyAllocated = ko.computed(() => {
            return (
                this.EstimatedHours() - this.AllocatedHours() > 0 &&
                this.HasAllocatedHours() &&
                !this.HasNotEstimatedElementsAllocated()
            );
        });

        this.AllocationPercentage = ko.computed(() => {
            if (this.EstimatedHours() == 0) return null;
            return (this.AllocatedHours() / this.EstimatedHours()) * 100;
        });

        this.HasAllocationInfo = ko.computed(() => {
            return this.AllocationInfo().length > 0;
        });

        this.LockJobOrderField = ko.computed(() => {
            return !this.IsNew();
        });

        this.LockSourceTemplateField = ko.computed(() => {
            return !this.canEditSourceTemplate;
        });

        this.IsTemplateSelected = ko.computed(() => {
            return !!this.TemplateId();
        });

        if (this.workflow) this.LoadFromModel(this.workflow);

        if (!(this.workflow && this.workflow.Id))
            setTimeout(() => {
                this.SetFocusOnTitle();
            }, 100);

        if (this.props.copyFromWorkflowMode) this.setupForCopyFromWorkflow();

        if (this.props.jobOrderId) this.JobOrderId(this.props.jobOrderId);

        if (this.props.importFromTemplateMode) {
            this.ShowSourceTemplateField(false);
            this.TemplateId.subscribe(this.LoadFromTemplate.bind(this));
        }

        this.loadSectionsSettings();

        this.props.forwardRef && this.props.forwardRef(this);
    }

    public HasChanges(): boolean {
        return (
            super.HasChanges() ||
            (this.RelatedDocumentsEditor() && this.RelatedDocumentsEditor().hasChanges()) ||
            this.Links().filter((l) => l.isChanged() > 0).length > 0
        );
    }

    private setupForCopyFromWorkflow() {
        this.SourceWorkflowsDataSource.setUserId(this.userInfoService.getIdUser());
        this.SourceWorkflowsDataSource.setViewFilters(true, true, this.CanViewAll());
        this.SourceWorkflowsDataSource.setWorkFilters(true);
        this.SourceWorkflowsDataSource.setJobOrders(this.SourceJobOrderId());

        this.CanViewAll.subscribe((viewAll: boolean) => {
            this.SourceWorkflowsDataSource.setViewFilters(true, true, viewAll);
        });

        this.SourceJobOrderId.subscribe((jobOrderId: number) => {
            this.SourceWorkflowsDataSource.setJobOrders(jobOrderId);
            this.SourceWorkflowId(null);
        });

        this.IsSourceWorkflowSelected = ko.computed(() => {
            return this.SourceJobOrderId() > 0 && this.SourceWorkflowId() > 0;
        });
    }

    private async LoadFromTemplate(): Promise<void> {
        const id: number = this.TemplateId();

        if (isNaN(id) || !id) return;

        const template: ITodoListTemplate = await this.todoListService.GetTemplate(id);

        setTimeout(() => {
            template.Id = undefined;
            super.LoadFromModel(template);

            this.SourceTemplateId(template.Id);
            this.DefaultRoleId(template.DefaultRoleId);
        }, 500);
    }

    public onSourceWorkflowSelected(model: IWorkflowsDataSourceModel): void {
        const workflow = Object.assign({}, model?.model ?? {}) as IWorkflowForTaskBoard;
        const workflowTitleParts = (workflow.Title || "").split("-");
        const workflowTitle =
            workflowTitleParts.length > 1 ? (workflowTitleParts[1] || "").trim() : (workflow.Title || "").trim();

        workflow.Title = workflowTitle;

        this.loadFromTaskboardModel(workflow);
    }

    private loadFromTaskboardModel(workflow: IWorkflowForTaskBoard) {
        this.loading = true;

        this.Title(workflow.Title);
        this.Category(workflow.Category?.toString());
        this.InQuality(workflow.InQuality);
        this.Description(workflow.Description);
        this.ReportingType(workflow.ReportingType ?? 0);
        this.HideFromSuggestions(workflow.HideFromSuggestions);
        this.ActivitiesProgressAmountMode(
            workflow.ActivitiesProgressAmountMode == null ? undefined : workflow.ActivitiesProgressAmountMode
        );
        this.EnableActivitiesProgressModeSetting(
            workflow.ActivitiesProgressAmountMode != null && workflow.ActivitiesProgressAmountMode != undefined
        );
        this.Multiplier(workflow.Multiplier);
        this.MultiplierUoM(workflow.MultiplierUoM);
        this.ShowAlertOnUnestimatedTasks(workflow.ShowAlertOnUnestimatedTasks);
        this.WorkflowMustBePlanned(workflow.WorkflowMustBePlanned);
        this.ActivitiesMustBeReviewed(workflow.ActivitiesMustBeReviewed);
        this.WorkflowMustBeRelatedToCustomerOrders(workflow.WorkflowMustBeRelatedToCustomerOrders);
        this.SourceTemplateId(workflow.SourceTemplateId);
        this.DefaultRoleId(workflow.DefaultRoleId);

        this.WorkedHoursDefaultPlace(workflow.DefaultWorkPlace);
        this.WorkedHoursDefaultTravelDistance(workflow.DefaultTravelDistance);
        this.DDC(workflow.DefaultCallRight);
        this.WorkedHoursDefaultsStrictMode(workflow.WorkedHoursDefaultsStrictMode);
        this.HideAdministrativeDataOnWorkedHours(workflow.HideAdministrativeFieldsOnWorkedHours);

        this.WorkedHoursDefaultRoles(this.createDefaultRoles(workflow.DefaultRoles));
        this.WorkedHoursDefaultWorkTimeCategories(
            this.createDefaultWorkTimeCategories(workflow.DefaultWorkTimeCategories)
        );

        setTimeout(() => {
            // HACK per far funzionare correttamente quei cavolo di onOffSwitch, (ma non stanno funzionando comunque queste m***e)
            this.OverrideCategoryShowAlertOnUnestimatedTasksSetting(
                workflow.OverrideCategoryShowAlertOnUnestimatedTasksSetting
            );
            this.OverrideCategoryActivitiesMustBeReviewedSetting(
                workflow.OverrideCategoryActivitiesMustBeReviewedSetting
            );
            this.OverrideCategoryWorkflowMustBePlannedSetting(workflow.OverrideCategoryWorkflowMustBePlannedSetting);
            this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting(
                workflow.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting
            );

            this.loading = false;

            this.isChanged(0);
        }, 200);
    }

    public SetShowClosedJobOrders(value: boolean): void {
        this.JobOrdersDataSource.setShowClosed(value);
    }

    public CreateTemplate() {
        const template: ITodoListTemplate = {
            Title: this.Title(),
            Description: this.Description(),
            Color: "rgb(0, 0, 0)",
            InQuality: this.InQuality(),
            Category: parseInt(this.Category()),
            ReportingType: this.ReportingType(),
            HideFromSuggestions: this.HideFromSuggestions(),
            Resources: [],
            DefaultRoleId: this.DefaultRoleId(),
            ActivitiesProgressAmountMode: this.ActivitiesProgressAmountMode(),
            Multiplier: this.Multiplier(),
            MultiplierUoM: this.MultiplierUoM(),
            ShowAlertOnUnestimatedTasks: this.ShowAlertOnUnestimatedTasks(),
            WorkflowMustBePlanned: this.WorkflowMustBePlanned(),
            ActivitiesMustBeReviewed: this.ActivitiesMustBeReviewed(),
            WorkflowMustBeRelatedToCustomerOrders: this.WorkflowMustBeRelatedToCustomerOrders(),
            OverrideCategoryShowAlertOnUnestimatedTasksSetting:
                this.OverrideCategoryShowAlertOnUnestimatedTasksSetting(),
            OverrideCategoryWorkflowMustBePlannedSetting: this.OverrideCategoryWorkflowMustBePlannedSetting(),
            OverrideCategoryActivitiesMustBeReviewedSetting: this.OverrideCategoryActivitiesMustBeReviewedSetting(),
            OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting:
                this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting(),
            OverrideCategoryHideFromSuggestionsSetting: this.OverrideCategoryHideFromSuggestionsSetting(),
            DefaultWorkPlace: this.WorkedHoursDefaultPlace(),
            DefaultTravelDistance: this.WorkedHoursDefaultTravelDistance(),
            DefaultCallRight: this.DDC(),
            WorkedHoursDefaultRoles: this.WorkedHoursDefaultRoles().map((r) => ({
                RoleId: r.RoleId(),
                Order: r.Order(),
            })),
            WorkedHoursDefaultWorkTimeCategories: this.WorkedHoursDefaultWorkTimeCategories().map((r) => ({
                WorkTimeCategoryId: r.WorkTimeCategoryId(),
                Order: r.Order(),
            })),
            WorkedHoursDefaultsStrictMode: this.WorkedHoursDefaultsStrictMode(),
            HideAdministrativeFieldsOnWorkedHours: this.HideAdministrativeDataOnWorkedHours(),
        };

        const dialog = new TemplateEditMaskDialog({
            template: template,
            parent: null,
            originWorkflowId: this.workflow.Id,
            edit: true,
        });
        dialog.show();
    }

    public async AddNewLink(): Promise<void> {
        if (!this.NewWorkflowLink.IsValid()) {
            await this.dialogsService.AlertAsync(
                ProlifeSdk.TextResources.Todolist.LinkIsNotValid,
                ProlifeSdk.TextResources.Todolist.LinkIsNotValidTitle
            );
            return;
        }

        const newLink = this.NewWorkflowLink.Clone();
        this.NewWorkflowLink.Reset();
        this.Links.push(newLink);
    }

    public RemoveLink(link: WorkflowLink) {
        this.Links.remove(link);
    }

    public LoadFromModel(workflow: ITodoListWorkflow) {
        const states = this.workflowsStates.getStates(true).filter((s: IWorkflowState) => {
            return !s.Deleted || (workflow && s.Id == workflow.Status);
        });
        const status = !workflow.Status ? (states.length === 0 ? null : states[0].Id) : workflow.Status;

        this.Id = workflow.Id || 0;

        super.LoadFromModel(workflow);
        this.Code(workflow.Code);
        this.JobOrderId(workflow.JobOrderId);
        this.StartDate(workflow.StartDate);
        this.EndDate(workflow.EndDate);
        this.Status(status);
        this.OutcomeId(workflow.OutcomeId);
        this.ShowInWorkflowId(workflow.ShownInWorkflowId);
        this.SourceTemplateId(workflow.SourceTemplateId);

        this.Links(workflow.Links.map((l) => new WorkflowLink(workflow.Id, l)));

        this.CanPrintProductionDocument(!this.IsNew());
        this.ShowAllocationsInfo(false);

        this.IsNew(!workflow.Id || workflow.Id <= 0);

        this.isChanged(0);

        if (this.workflow.Id > 0) {
            this.allocationsService
                .getPlannedResourcesForWorkflow(this.workflow.Id)
                .then((resources: IPlannedResource[]) => {
                    this.PlannedResources(resources);
                    this.ShowAllocationsInfo(resources.length > 0);
                });

            this.allocationsService
                .getWorkflowAllocationInfo(this.workflow.Id)
                .then((allocationInfo: IWorkflowAllocationInfo[]) => {
                    if (!allocationInfo) {
                        this.AllocationInfo.push({
                            TeamName: null,
                            CartName: null,
                            AllocationStartDate: null,
                            RealAllocationEndDate: null,
                            TheoreticalAllocationEndDate: null,
                            EstimatedHours: null,
                            AllocatedHours: null,
                            UnallocatedHours: null,
                            HasNotEstimatedElements: null,
                            HasNotEstimatedElementsAllocated: null,
                        });

                        return;
                    }

                    this.EstimatedHours(allocationInfo[0].EstimatedHours);
                    this.AllocatedHours(allocationInfo[0].AllocatedHours);
                    this.UnallocatedHours(allocationInfo[0].UnallocatedHours);
                    this.HasNotEstimatedElements(allocationInfo[0].HasNotEstimatedElements);
                    this.HasNotEstimatedElementsAllocated(allocationInfo[0].HasNotEstimatedElementsAllocated);

                    this.AllocationInfo(allocationInfo);
                });
        }
    }

    async DeleteWorkflow(): Promise<IWorkflowEditorResult> {
        // 18/02/2021 commentata perché l'API di eliminazione del piano fa già un controllo sulla presenza di task con ore lavorate, a prescindere dalla fatturabilità delle ore
        // let result: boolean = await this.todoListService.HasBillableHours(this.workflow.Id, null, false)
        // let message: string = !result ? ProlifeSdk.TextResources.Todolist.ConfirmDeleteWorkflow : ProlifeSdk.TextResources.Todolist.ConfirmDeleteWorkflowWithBillableHours;

        const message: string = ProlifeSdk.TextResources.Todolist.ConfirmDeleteWorkflow;

        const confirm = await this.dialogService.ConfirmAsync(
            message,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteWorkflow,
            ProlifeSdk.TextResources.Todolist.DeleteWorkflow
        );
        if (!confirm) return null;

        try {
            const result = await this.internalDeleteWorkflow();

            if (result.succeeded) return { Action: "REMOVE", Workflow: this.workflow };

            return await this.handleDeleteWorkflowException(result);
        } catch (e) {
            const error = e as ResponseBase;
            if (error && error.errors) return await this.handleDeleteWorkflowException(error);
            else this.infoToastService.Error(TextResources.Todolist.DeleteWorkflowUnexpectedError);
        }

        return null;
    }

    private async handleDeleteWorkflowException(result) {
        if (result.errors) {
            const error = result.errors.firstOrDefault((e) => e.code === "WorkflowHasRelatedEntities");
            if (error) {
                try {
                    const secondResult = await this.handleRelatedEntitiesError(
                        error.metadata as WorkflowRelatedEntities
                    );

                    return secondResult;
                } catch (e) {
                    e && this.infoToastService.Error(TextResources.Todolist.DeleteWorkflowUnexpectedError);
                }
            } else {
                this.infoToastService.Error(TextResources.Todolist.DeleteWorkflowUnexpectedError);
            }
        } else {
            this.infoToastService.Error(TextResources.Todolist.DeleteWorkflowUnexpectedError);
        }

        return null;
    }

    private async handleRelatedEntitiesError(counts: WorkflowRelatedEntities): Promise<IWorkflowEditorResult> {
        const errorModal = new RelatedEntitiesDetails(counts);
        await errorModal.show();
        return null;
    }

    private async internalDeleteWorkflow(skipRelatedDocumentsAndWorkedHoursCheck = false): Promise<ResponseBase> {
        return await this.todoListService.DeleteWorkflow(this.workflow.Id, skipRelatedDocumentsAndWorkedHoursCheck);
    }

    public EditOutcome() {
        this.EditingOutcome(!this.EditingOutcome());
    }

    public ShowCategoriesManager() {
        const dialog: IDialog = new WorkflowCategoriesManagerDialog();
        this.dialogService.ShowModal<void>(dialog, "large", {}, dialog.templateUrl, dialog.templateName);
    }

    async closeEditor(): Promise<boolean> {
        let confirm = true;

        if (this.HasChanges()) {
            confirm = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.Todolist.WorkflowPendingChangesMessage,
                ProlifeSdk.TextResources.Todolist.WorkflowPendingChangesMessageCancel,
                ProlifeSdk.TextResources.Todolist.WorkflowPendingChangesMessageConfirm
            );
        }

        return confirm;
    }

    async action(): Promise<IWorkflowEditorResult> {
        const jobOrderId: number = this.JobOrderId();
        const categoryId: number = parseInt(this.Category());

        if (this.Title().trim().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InsertWorkflowTitle);
            return null;
        }

        if (isNaN(jobOrderId) || !jobOrderId) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectReferenceJobOrder);
            return null;
        }

        if (isNaN(categoryId) || !categoryId) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectCategory);
            return null;
        }

        if (!this.Multiplier() || this.Multiplier() < 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InvalidWorkflowMultiplier);
            return null;
        }

        if (this.Roles().filter((r: IUserCharacter) => r.IdUserCharacter == this.DefaultRoleId()).length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectDefaultRole);
            return null;
        }

        if (this.RelatedDocumentsEditor() && !this.RelatedDocumentsEditor().isValid()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InvalidWorkflowBudgetRows);
            return null;
        }

        if (this.props.copyFromWorkflowMode) {
            const sourceWorkflowId: number = this.SourceWorkflowId();
            if (!sourceWorkflowId || sourceWorkflowId <= 0) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectAWorkflow);
                return null;
            }
        }

        if (this.props.importFromTemplateMode) {
            const templateId: number = this.TemplateId();
            if (isNaN(templateId) || !templateId) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectTemplate);
                return null;
            }
        }

        try {
            const newWorkflow: ITodoListWorkflow = await this.saveWorkflow();

            const relatedDocumentsEditor = this.RelatedDocumentsEditor();
            if (relatedDocumentsEditor) {
                const relatedDocumentsToBeSaved: IWorkflowRelatedDocument[] = relatedDocumentsEditor.getData();
                await this.todoListService.InsertOrUpdateWorkflowRelatedDocuments(
                    newWorkflow.Id,
                    relatedDocumentsToBeSaved
                );
            }

            return { Action: "CHANGE", Workflow: newWorkflow };
        } catch (e) {
            console.log(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.SaveWorkflowBudgetRequestsError);
            return null;
        }
    }

    private async saveWorkflow(): Promise<ITodoListWorkflow> {
        const workflow: ITodoListWorkflow = this.GetData();
        if (this.props.copyFromWorkflowMode)
            return this.todoListService.CreateWorkflowFromWorkflow(workflow, this.SourceWorkflowId());

        if (this.props.importFromTemplateMode) return this.todoListService.CreateFromTemplate(workflow);

        return this.todoListService.InsertOrUpdateWorkflow(workflow);
    }

    public async previewCode(): Promise<string> {
        const code = await this.todoListService.PreviewWorkflowCode(this.GetData());
        this.CodePreview(code);
        return code;
    }

    public DeallocateWorkflowFromCart() {
        if (!this.workflow || this.workflow.Id <= 0) return;

        this.allocationsService.deleteElement(this.workflow.Id, false).then(() => {
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.WorkflowSuccessfullyDeallocated);
            this.CanDeallocateFromCart(false);
        });
    }

    public ShowWorkflowActivitiesMultipliersManager(): void {
        const workflow = this.GetData();
        const manager = this.todoListService.GetWorkflowActivitiesMultipliersManagerUI(workflow);
        manager.show().then((updatedWorkflow: ITodoListWorkflow) => {
            this.LoadFromModel(updatedWorkflow);
        });
    }

    public GetData(): ITodoListWorkflow {
        const jobOrderId: number = this.JobOrderId();
        const model = super.GetData() as ITodoListWorkflow;

        if (!this.workflow) {
            const notInitializedStates = this.outcomesSettings.getOutcomes().filter((o: IWorkflowOutcome) => {
                o.LogicalStatus = 0;
            });
            const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

            (model.JobOrderId = -1),
                (model.Status = 0),
                (model.Code = ""),
                (model.ShownInWorkflowId = null),
                (model.OutcomeId = notInitializedState),
                (model.Links = []),
                (model.SourceTemplateId = null);
        }

        model.JobOrderId = jobOrderId;
        model.Status = this.Status();
        model.Code = this.Code();
        model.ShownInWorkflowId = this.ShowInWorkflowId();
        model.OutcomeId = this.OutcomeId();
        model.Links = this.Links().map((l) => l.getJson());
        model.StartDate = this.StartDate();
        model.EndDate = this.EndDate();
        model.SourceTemplateId = this.SourceTemplateId();
        model.WorkedHoursDefaultRoles = this.WorkedHoursDefaultRoles().map((r) => ({
            WorkflowId: this.Id,
            RoleId: r.RoleId(),
            Order: r.Order(),
        }));
        model.WorkedHoursDefaultWorkTimeCategories = this.WorkedHoursDefaultWorkTimeCategories().map((w) => ({
            WorkflowId: this.Id,
            WorkTimeCategoryId: w.WorkTimeCategoryId(),
            Order: w.Order(),
        }));

        if (this.props.importFromTemplateMode) model.SourceTemplateId = this.TemplateId();

        return model;
    }

    public SetFocusOnTitle(): void {
        this.TitleHasFocus(true);
    }

    public ShowRelatedDocumentsTab(): void {
        this.ShowSnapshotsEditor(false);
        this.ShowWorkflowData(false);
        this.ShowPrerequisites(false);
        this.ShowRelatedDocuments(true);
    }

    public ShowSnapshotsEditorTab(): void {
        this.ShowRelatedDocuments(false);
        this.ShowWorkflowData(false);
        this.ShowPrerequisites(false);
        this.ShowSnapshotsEditor(true);
    }

    public ShowWorkflowDataTab(): void {
        this.ShowRelatedDocuments(false);
        this.ShowSnapshotsEditor(false);
        this.ShowPrerequisites(false);
        this.ShowWorkflowData(true);
    }

    public ShowPrerequisitesTab(): void {
        this.ShowRelatedDocuments(false);
        this.ShowSnapshotsEditor(false);
        this.ShowWorkflowData(false);
        this.ShowPrerequisites(true);
    }

    public showProductionActivitiesPrintDialog(): void {
        if (this.IsNew() || !this.JobOrderId()) return;

        this.jobOrderService.ui.getProductionActivitiesPrintDialog(this.JobOrderId(), this.Id).show();
    }

    //---- Metodi per gestione interfaccia
    public async saveSectionsConfig(): Promise<void> {
        const config: IWorkflowSectionsDefaultSettings = {
            WorkflowStateOpened: this.WorkflowTaskHelpersOpened(),
            ResourcesOpened: this.ResourcesOpened(),
            AdvancedSettingsOpened: this.AdvancedSettingsOpened(),
        };

        await this.todoListService.saveWorkflowSectionsConfiguration(config);

        this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.CartInfoConfigSaved);
    }

    public async loadSectionsSettings(): Promise<void> {
        const config: IWorkflowSectionsDefaultSettings = await this.todoListService.getWorkflowSectionsConfiguration();

        this.WorkflowTaskHelpersOpened(config.WorkflowStateOpened);
        this.ResourcesOpened(config.ResourcesOpened);
        this.AdvancedSettingsOpened(config.AdvancedSettingsOpened);
    }

    public scrollToGeneralData(): void {
        this.GeneralDataScroller(!this.GeneralDataScroller());
    }

    public scrollToWorkflowState(): void {
        this.WorkflowTaskHelpersOpened(true);
        this.WorkflowTaskHelpersScroller(!this.WorkflowTaskHelpersScroller());
    }

    public scrollToResources(): void {
        this.ResourcesOpened(true);
        this.ResourcesScroller(!this.ResourcesScroller());
    }

    public scrollToAdvancedSettings(): void {
        this.AdvancedSettingsOpened(true);
        this.AdvancedSettingsScroller(!this.AdvancedSettingsScroller());
    }

    public switchWorkflowTaskHelpersOpenedState(): void {
        this.WorkflowTaskHelpersOpened(!this.WorkflowTaskHelpersOpened());
    }

    public switchResourceOpenedState(): void {
        this.ResourcesOpened(!this.ResourcesOpened());
    }

    public switchAdvancedSettingsOpenedState(): void {
        this.AdvancedSettingsOpened(!this.AdvancedSettingsOpened());
    }
    //----

    public addListener(listener: IWorkflowEditorListener): void {
        if (this.listeners.indexOf(listener) >= 0) return;

        this.listeners.push(listener);
    }

    public removeListener(listener: IWorkflowEditorListener): void {
        const index = this.listeners.indexOf(listener);

        if (index >= 0) this.listeners.splice(index, 1);
    }

    render() {
        return ComponentUtils.bindTo(
            <>
                {!this.props.copyFromWorkflowMode && !this.props.importFromTemplateMode && this.renderWorkflowEditor()}
                {this.props.copyFromWorkflowMode && this.renderWorkflowCopyEditor()}
                {this.props.importFromTemplateMode && this.renderImportFromTemplateEditor()}
            </>,
            this,
            "workflowEditor"
        );
    }

    private renderWorkflowCopyEditor() {
        return (
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} style={{ height: "100%" }}>
                <Layout.Grid.Cell row={1} column={1} className="flex-vertical">
                    <div className="horizontal-form">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <Select.WithLabel
                                            value={this.SourceJobOrderId}
                                            dataSource={this.JobOrdersDataSource}
                                            placeholder="Seleziona..."
                                            label="Progetto"
                                            allowClear
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <Select.WithLabel
                                            value={this.SourceWorkflowId}
                                            dataSource={this.SourceWorkflowsDataSource}
                                            placeholder="Seleziona..."
                                            label="Piano"
                                            allowClear
                                            onSelect={this.onSourceWorkflowSelected.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1} className="flex-vertical">
                    <If condition={this.IsSourceWorkflowSelected}>{() => this.renderWorkflowEditForm()}</If>
                </Layout.Grid.Cell>
            </Layout.Grid>
        );
    }

    private renderImportFromTemplateEditor() {
        return (
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} style={{ height: "100%" }}>
                <Layout.Grid.Cell row={1} column={1} className="flex-vertical">
                    <div className="horizontal-form">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <Select.WithLabel
                                            value={this.TemplateId}
                                            dataSource={this.TemplatesDataSource}
                                            placeholder="Seleziona..."
                                            label="Modello"
                                            allowClear
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1} className="flex-vertical">
                    <If condition={this.IsTemplateSelected}>{() => this.renderWorkflowEditForm()}</If>

                    <IfNot condition={this.IsTemplateSelected}>
                        {() => <div class="alert alert-info">Selezionare un modello...</div>}
                    </IfNot>
                </Layout.Grid.Cell>
            </Layout.Grid>
        );
    }

    private renderWorkflowEditor() {
        return (
            <>
                <If condition={this.EditingOutcome}>{() => this.renderWorkflowOutcomeForm()}</If>

                <IfNot condition={this.EditingOutcome}>{() => this.renderWorkflowEditForm()}</IfNot>
            </>
        );
    }

    private renderWorkflowOutcomeForm(): React.ReactElement {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workflowEditor = this;

        return (
            <div className="horizontal-form">
                <div className="form-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="control-label">{TextResources.Todolist.Outcome}</label>
                                <select
                                    className="form-control"
                                    data-bind={{
                                        options: workflowEditor.Outcomes,
                                        optionsText: "Description",
                                        optionsValue: "Id",
                                        value: workflowEditor.OutcomeId,
                                    }}
                                ></select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderWorkflowEditForm(): React.ReactElement {
        const classNames = ComponentUtils.classNames(
            "flex-container flex-vertical flex-full-height",
            classes.workflowEditor
        );
        return (
            <TabNav className={classNames}>
                <TabNav.Page title={TextResources.Todolist.WorkflowDetails}>{() => this.renderDetails()}</TabNav.Page>
                <TabNav.Page title={TextResources.Todolist.WorkflowLinks}>{() => this.renderLinks()}</TabNav.Page>
                <TabNav.Page title={TextResources.Todolist.WorkflowRelatedDocuments}>
                    {() => this.renderRelatedDocuments()}
                </TabNav.Page>
                {this.canManageWorkflowSnapshot && (
                    <TabNav.Page
                        title={TextResources.Todolist.SnapshotsManagement}
                        default={this.props.showSnapshotsEditorTab}
                        visible={this.CanEditSnapshots}
                    >
                        {() => this.rendersSnapshotsEditor()}
                    </TabNav.Page>
                )}
            </TabNav>
        );
    }

    private renderDetails() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workflowEditor = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const allocationInfo: IWorkflowAllocationInfo = null;
        const plannedResource: IPlannedResource = null;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const resourcesManager: ResourcesAndGroupsManager = null;

        return (
            <Layout.Grid rows={["1fr"]} columns={["1fr", "185px"]} noOverflow className="workflowDetails">
                <Layout.Grid.Cell row={1} column={1} className="flex-vertical" noOverflow>
                    <Layout.ScrollContainer className="workflow-editor" systemScrollable>
                        <div
                            className="row workflow-general-data"
                            data-bind={{ scrollIntoView: workflowEditor.GeneralDataScroller }}
                        >
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 joborder-field-wrapper">
                                        <div className="form-group">
                                            <label className="control-label">Commessa</label>
                                            <Select
                                                value={this.JobOrderId}
                                                allowClear
                                                placeholder="Seleziona..."
                                                readOnly={this.LockJobOrderField}
                                                dataSource={this.JobOrdersDataSource}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label className="control-label">Nome</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Inserisci il nome..."
                                                data-bind={{
                                                    selectOnFocus: {},
                                                    value: workflowEditor.Title,
                                                    hasfocus: workflowEditor.TitleHasFocus,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Codice Piano</label>
                                            <If condition={this.EditCode}>
                                                {() => (
                                                    <input
                                                        placeholder="Codice piano..."
                                                        className="form-control"
                                                        type="text"
                                                        pattern="^[A-Za-z0-9_\-\.]*$"
                                                        title="Hai inserito caratteri non validi. Il campo Codice Piano può contenere solamente numeri e lettere."
                                                        data-bind={{
                                                            selectOnFocus: {},
                                                            validatedValue: workflowEditor.Code,
                                                        }}
                                                    />
                                                )}
                                            </If>
                                            <IfNot condition={this.EditCode}>
                                                {() => (
                                                    <>
                                                        <If condition={this.CanEditCode}>
                                                            {() => (
                                                                <>
                                                                    <div className="input-group">
                                                                        <span
                                                                            className="form-control"
                                                                            data-bind={{
                                                                                text: workflowEditor.Code,
                                                                            }}
                                                                        ></span>
                                                                        <i className="input-group-btn">
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                type="button"
                                                                                data-bind={{
                                                                                    toggle: workflowEditor.EditCode,
                                                                                    tooltip: { container: ".modal" },
                                                                                }}
                                                                                title="Modifica Codice Piano"
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                        </i>
                                                                    </div>
                                                                    <div className="help-block">
                                                                        <span
                                                                            data-bind={{
                                                                                text: workflowEditor.CodePreview,
                                                                            }}
                                                                        ></span>
                                                                        &nbsp;
                                                                        <a
                                                                            className="pull-right"
                                                                            href="#"
                                                                            data-bind={{
                                                                                asyncClick: workflowEditor.previewCode,
                                                                            }}
                                                                            title="Visualizza anteprima codice"
                                                                        >
                                                                            <i className="fa fa-info-circle"></i>
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </If>
                                                        <IfNot condition={this.CanEditCode}>
                                                            {() => (
                                                                <span
                                                                    className="form-control"
                                                                    data-bind={{ text: workflowEditor.Code }}
                                                                ></span>
                                                            )}
                                                        </IfNot>
                                                    </>
                                                )}
                                            </IfNot>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group task-inline-description-editor-wrapper">
                                            <textarea
                                                className="task-inline-description-editor"
                                                data-bind={{
                                                    htmlEditor: workflowEditor.Description,
                                                    html: workflowEditor.Description,
                                                    htmlEditorOptions: { inlineMode: true },
                                                }}
                                                placeholder="Inserisci la descrizione..."
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="control-label">Inizio previsto</label>
                                            <input
                                                className="form-control input-small"
                                                data-bind={{ selectOnFocus: {}, datepicker: workflowEditor.StartDate }}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="control-label">Fine prevista</label>
                                            <input
                                                className="form-control input-small"
                                                data-bind={{ selectOnFocus: {}, datepicker: workflowEditor.EndDate }}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Categoria</label>
                                            <input
                                                className="form-control"
                                                type="hidden"
                                                data-bind={{
                                                    value: workflowEditor.Category,
                                                    select2: {
                                                        allowClear: true,
                                                        query: workflowEditor.CategoriesProvider.findEntities.bind(
                                                            workflowEditor.CategoriesProvider
                                                        ),
                                                        placeholder: "Seleziona...",
                                                        initSelection:
                                                            workflowEditor.CategoriesProvider.findEntity.bind(
                                                                workflowEditor.CategoriesProvider
                                                            ),
                                                    },
                                                    select2SetValueFromField: workflowEditor.Category,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Stato</label>
                                            <select
                                                className="form-control"
                                                data-bind={{
                                                    options: workflowEditor.States,
                                                    optionsText: "Description",
                                                    optionsValue: "Id",
                                                    value: workflowEditor.Status,
                                                }}
                                            ></select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="portlet light bordered task-section"
                            data-bind={{ scrollIntoView: workflowEditor.WorkflowTaskHelpersScroller }}
                            style="margin-top: 40px"
                        >
                            <div
                                className="portlet-title"
                                data-bind={{
                                    click: workflowEditor.switchWorkflowTaskHelpersOpenedState.bind(workflowEditor),
                                }}
                            >
                                <div className="caption bold" style="color: #67b5ca">
                                    <i className="fa fa-info"></i>
                                    Attività - valori predefiniti per la creazione di nuove attività
                                </div>

                                <div className="tools">
                                    <a
                                        href="javascript:void(0)"
                                        className="collapse"
                                        data-bind={{
                                            css: {
                                                collapse: workflowEditor.WorkflowTaskHelpersOpened,
                                                expand: !workflowEditor.WorkflowTaskHelpersOpened(),
                                            },
                                            click: workflowEditor.switchWorkflowTaskHelpersOpenedState.bind(
                                                workflowEditor
                                            ),
                                            clickBubble: false,
                                        }}
                                    ></a>
                                </div>
                            </div>

                            <div
                                className="portlet-body"
                                data-bind={{ visible: workflowEditor.WorkflowTaskHelpersOpened }}
                            >
                                <div className="row" style={{ display: "flex" }}>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            {this.canEditTasksMultipliersInMassiveMode && (
                                                <a
                                                    href="#"
                                                    className="btn btn-xs btn-success pull-right"
                                                    data-bind={{
                                                        click: workflowEditor.ShowWorkflowActivitiesMultipliersManager.bind(
                                                            workflowEditor
                                                        ),
                                                        visible:
                                                            workflowEditor.CanEditTasksMultiplier() &&
                                                            !workflowEditor.IsNew(),
                                                    }}
                                                >
                                                    Gestisci q.tà attività
                                                </a>
                                            )}
                                            <label className="control-label">Avanzamento attività</label>
                                            <select
                                                className="form-control"
                                                data-bind={{
                                                    options: workflowEditor.ActivitiesProgressAmountModeOptions,
                                                    value: workflowEditor.ActivitiesProgressAmountMode,
                                                    optionsText: "label",
                                                    optionsValue: "value",
                                                    optionsCaption: "Usa le impostazioni predefinite",
                                                }}
                                            ></select>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-6"
                                        style={{ flexGrow: 1, alignSelf: "end", paddingBottom: "5px" }}
                                    >
                                        <div className="form-group">
                                            <label className="control-label" style="width: 75%">
                                                In qualit&agrave;
                                            </label>
                                            <CheckBox
                                                checked={this.InQuality}
                                                switchLabels={{
                                                    onLabel: TextResources.ProlifeSdk.Yes,
                                                    offLabel: TextResources.ProlifeSdk.No,
                                                }}
                                                switch
                                                switchSize="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">Moltiplicatore</label>
                                            <br />
                                            <input
                                                className="form-control"
                                                data-bind={{
                                                    numberValue: workflowEditor.Multiplier,
                                                    selectOnFocus: {},
                                                }}
                                                type="text"
                                                placeholder="Inserire un numero..."
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">U.d.m.</label>
                                            <br />
                                            <input
                                                type="text"
                                                data-bind={{ value: workflowEditor.MultiplierUoM, selectOnFocus: {} }}
                                                className="form-control"
                                                placeholder="Unità di misura"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">Tipo Rendicontazione</label>
                                            <select
                                                className="form-control"
                                                data-bind={{
                                                    options: workflowEditor.ReportingTypes,
                                                    optionsText: "Description",
                                                    optionsValue: "Id",
                                                    value: workflowEditor.ReportingType,
                                                }}
                                            ></select>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">Mansione Predefinita</label>
                                            <select
                                                className="form-control"
                                                data-bind={{
                                                    options: workflowEditor.Roles,
                                                    optionsText: "Description",
                                                    optionsValue: "IdUserCharacter",
                                                    value: workflowEditor.DefaultRoleId,
                                                }}
                                            ></select>
                                        </div>
                                    </div>
                                </div>
                                {this.canViewHourReportingSection && (
                                    <WorkedHoursDefaultsEditor
                                        workPlace={this.WorkedHoursDefaultPlace}
                                        travelDistance={this.WorkedHoursDefaultTravelDistance}
                                        forceDataOnWorkedHours={this.WorkedHoursDefaultsStrictMode}
                                        ddc={this.DDC}
                                        hideAdministrativeDataOnWorkedHours={this.HideAdministrativeDataOnWorkedHours}
                                        defaultRoles={this.WorkedHoursDefaultRoles}
                                        defaultWorkTimeCategories={this.WorkedHoursDefaultWorkTimeCategories}
                                    />
                                )}
                            </div>
                        </div>

                        <div
                            className="portlet light bordered task-section"
                            data-bind={{ scrollIntoView: workflowEditor.ResourcesScroller }}
                        >
                            <div
                                className="portlet-title"
                                data-bind={{ click: workflowEditor.switchResourceOpenedState.bind(workflowEditor) }}
                            >
                                <div className="caption bold" style="color: #c56565">
                                    <i className="fa fa-users"></i>
                                    Risorse
                                </div>

                                <div className="tools">
                                    <a
                                        href="javascript:void(0)"
                                        className="collapse"
                                        data-bind={{
                                            css: {
                                                collapse: workflowEditor.ResourcesOpened,
                                                expand: !workflowEditor.ResourcesOpened(),
                                            },
                                            click: workflowEditor.switchResourceOpenedState.bind(workflowEditor),
                                            clickBubble: false,
                                        }}
                                    ></a>
                                </div>
                            </div>

                            <div className="portlet-body" data-bind={{ visible: workflowEditor.ResourcesOpened }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Risorse</label>
                                            <ko-bind
                                                data-bind={{ with: workflowEditor.Resources, as: "resourcesManager" }}
                                            >
                                                <input
                                                    className="form-control default"
                                                    type="hidden"
                                                    data-bind={{
                                                        value: resourcesManager.Resources,
                                                        select2: {
                                                            ajax: { quietMillis: 500 },
                                                            query: resourcesManager.resourcesProvider.findEntities.bind(
                                                                resourcesManager.resourcesProvider
                                                            ),
                                                            initSelection:
                                                                resourcesManager.resourcesProvider.findEntity.bind(
                                                                    resourcesManager.resourcesProvider
                                                                ),
                                                            allowClear: true,
                                                            placeholder: "Seleziona...",
                                                            multiple: true,
                                                            minimumInputLength: 1,
                                                        },
                                                        select2SetValueFromField: resourcesManager.Resources,
                                                    }}
                                                />
                                            </ko-bind>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Responsabile</label>
                                            <input
                                                className="form-control"
                                                type="hidden"
                                                data-bind={{
                                                    value: workflowEditor.ResponsibleId,
                                                    select2: {
                                                        allowClear: true,
                                                        query: workflowEditor.resourcesSearchService.findEntities.bind(
                                                            workflowEditor.resourcesSearchService
                                                        ),
                                                        placeholder: "Seleziona...",
                                                        initSelection:
                                                            workflowEditor.resourcesSearchService.findEntity.bind(
                                                                workflowEditor.resourcesSearchService
                                                            ),
                                                    },
                                                    select2SetValueFromField: workflowEditor.ResponsibleId,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.canStartAllocation && (
                                    <div
                                        className="row allocations-info"
                                        style="margin-bottom: 10px"
                                        data-bind={{ visible: workflowEditor.ShowAllocationsInfo }}
                                    >
                                        <div className="col-md-8">
                                            <label>
                                                Pianificazione
                                                <span
                                                    className="badge"
                                                    data-bind={{
                                                        visible: workflowEditor.HasAllocationInfo,
                                                        percentageText: workflowEditor.AllocationPercentage,
                                                        percentageTextNullable: true,
                                                        percentageTextNull: "N/D",
                                                        css: {
                                                            "badge-warning": workflowEditor.IsPartiallyAllocated,
                                                            "badge-success": !workflowEditor.HasNotEstimatedElements(),
                                                            "badge-danger":
                                                                workflowEditor.HasNotEstimatedElementsAllocated() ||
                                                                workflowEditor.IsUnestimated() ||
                                                                workflowEditor.HasNotEstimatedElements(),
                                                        },
                                                    }}
                                                ></span>
                                            </label>
                                            <If condition={this.CanDeallocateFromCart}>
                                                {() => (
                                                    <button
                                                        className="btn btn-primary btn-xs pull-right"
                                                        data-bind={{
                                                            click: workflowEditor.DeallocateWorkflowFromCart,
                                                            visible: workflowEditor.CanDeallocateFromCart,
                                                        }}
                                                    >
                                                        <i className="fa fa-trash-o"></i>
                                                        Dealloca
                                                    </button>
                                                )}
                                            </If>
                                            <table className="table table-condensed" style="width: 100%">
                                                <thead>
                                                    <tr style="border-bottom: 1px solid lightgray">
                                                        <th style="width: 20%">Team</th>
                                                        <th style="width: 20%">Carrello</th>
                                                        <th style="width: 20%">Data inizio allocazione</th>
                                                        <th style="width: 20%">Data fine allocazione effettiva</th>
                                                        <th style="width: 20%">Data fine allocazione teorica</th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    data-bind={{
                                                        foreach: {
                                                            data: workflowEditor.AllocationInfo,
                                                            as: "allocationInfo",
                                                        },
                                                    }}
                                                >
                                                    <tr>
                                                        <td
                                                            data-bind={{
                                                                text: allocationInfo.TeamName
                                                                    ? allocationInfo.TeamName
                                                                    : "N/A",
                                                            }}
                                                        ></td>
                                                        <td
                                                            data-bind={{
                                                                text: allocationInfo.CartName
                                                                    ? allocationInfo.CartName
                                                                    : "N/A",
                                                            }}
                                                        ></td>
                                                        <td
                                                            data-bind={{
                                                                dateText: allocationInfo.AllocationStartDate,
                                                                dateTextNoValue: "N/A",
                                                            }}
                                                        ></td>
                                                        <td
                                                            data-bind={{
                                                                dateText: allocationInfo.RealAllocationEndDate,
                                                                dateTextNoValue: "N/A",
                                                            }}
                                                        ></td>
                                                        <td
                                                            data-bind={{
                                                                dateText: allocationInfo.TheoreticalAllocationEndDate,
                                                                dateTextNoValue: "N/A",
                                                            }}
                                                        ></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="control-label">Risorse Pianificate</label>
                                                <div className="task-planned-resources" data-bind={{ slimScroll: {} }}>
                                                    <ko-bind
                                                        data-bind={{
                                                            foreach: {
                                                                data: workflowEditor.PlannedResources,
                                                                as: "plannedResource",
                                                            },
                                                        }}
                                                    >
                                                        <span
                                                            className="label label-primary"
                                                            data-bind={{ text: plannedResource.ResourceName }}
                                                        ></span>
                                                    </ko-bind>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className="portlet light bordered task-section"
                            data-bind={{ scrollIntoView: workflowEditor.AdvancedSettingsScroller }}
                        >
                            <div
                                className="portlet-title"
                                data-bind={{
                                    click: workflowEditor.switchAdvancedSettingsOpenedState.bind(workflowEditor),
                                }}
                            >
                                <div className="caption bold" style="color: #b5974b">
                                    <i className="fa fa-cogs"></i>
                                    Avanzate
                                </div>

                                <div className="tools">
                                    <a
                                        href="javascript:void(0)"
                                        className="collapse"
                                        data-bind={{
                                            css: {
                                                collapse: workflowEditor.AdvancedSettingsOpened,
                                                expand: !workflowEditor.AdvancedSettingsOpened(),
                                            },
                                            click: workflowEditor.switchAdvancedSettingsOpenedState.bind(
                                                workflowEditor
                                            ),
                                            clickBubble: false,
                                        }}
                                    ></a>
                                </div>
                            </div>

                            <div
                                className="portlet-body"
                                data-bind={{ visible: workflowEditor.AdvancedSettingsOpened }}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-9"></div>
                                            <div className="col-md-3">
                                                <label style="">Sovrascrivi le impostazioni di categoria</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label className="control-label" style="width: 75%">
                                                        Nascondi dai Suggerimenti
                                                    </label>
                                                    <CheckBox
                                                        checked={this.HideFromSuggestions}
                                                        readOnly={this.DisableHideFromSuggestion}
                                                        switchLabels={{
                                                            onLabel: TextResources.ProlifeSdk.Yes,
                                                            offLabel: TextResources.ProlifeSdk.No,
                                                        }}
                                                        switch
                                                        switchSize="small"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <CheckBox checked={this.OverrideCategoryHideFromSuggestionsSetting} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label className="control-label" style="width: 75%">
                                                        Richiedi stima sui task
                                                    </label>
                                                    <input
                                                        className=""
                                                        data-bind={{
                                                            onOffSwitch: workflowEditor.ShowAlertOnUnestimatedTasks,
                                                            switchSize: "small",
                                                            enable: workflowEditor.OverrideCategoryShowAlertOnUnestimatedTasksSetting,
                                                        }}
                                                        type="checkbox"
                                                        checked={false}
                                                        data-size="small"
                                                        data-on-text="Si"
                                                        data-off-text="No"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <CheckBox
                                                    checked={this.OverrideCategoryShowAlertOnUnestimatedTasksSetting}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label className="control-label" style="width: 75%">
                                                        Richiedi associazione con ordini cliente
                                                    </label>
                                                    <input
                                                        className=""
                                                        data-bind={{
                                                            onOffSwitch:
                                                                workflowEditor.WorkflowMustBeRelatedToCustomerOrders,
                                                            switchSize: "small",
                                                            enable: workflowEditor.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting,
                                                        }}
                                                        type="checkbox"
                                                        checked={false}
                                                        data-size="small"
                                                        data-on-text="Si"
                                                        data-off-text="No"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <CheckBox
                                                    checked={
                                                        this
                                                            .OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {this.allocationsModuleEnabled && (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label className="control-label" style="width: 75%">
                                                                Pianificazione richiesta
                                                            </label>
                                                            <input
                                                                className=""
                                                                data-bind={{
                                                                    onOffSwitch: workflowEditor.WorkflowMustBePlanned,
                                                                    switchSize: "small",
                                                                    enable: workflowEditor.OverrideCategoryWorkflowMustBePlannedSetting,
                                                                }}
                                                                type="checkbox"
                                                                checked={false}
                                                                data-size="small"
                                                                data-on-text="Si"
                                                                data-off-text="No"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <CheckBox
                                                            checked={this.OverrideCategoryWorkflowMustBePlannedSetting}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label className="control-label" style="width: 75%">
                                                                Attività da rivedere
                                                            </label>
                                                            <input
                                                                className=""
                                                                data-bind={{
                                                                    onOffSwitch:
                                                                        workflowEditor.ActivitiesMustBeReviewed,
                                                                    switchSize: "small",
                                                                    enable: workflowEditor.OverrideCategoryActivitiesMustBeReviewedSetting,
                                                                }}
                                                                type="checkbox"
                                                                checked={false}
                                                                data-size="small"
                                                                data-on-text="Si"
                                                                data-off-text="No"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <CheckBox
                                                            checked={
                                                                this.OverrideCategoryActivitiesMustBeReviewedSetting
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Select.WithLabel
                                                value={this.ShowInWorkflowId}
                                                dataSource={this.WorkflowsDataSource}
                                                placeholder="Seleziona un flusso..."
                                                label="Mostra in piano"
                                                allowClear
                                            />
                                        </div>
                                        <If condition={this.ShowSourceTemplateField}>
                                            {() => (
                                                <div className="form-group">
                                                    <Select.WithLabel
                                                        value={this.SourceTemplateId}
                                                        dataSource={this.TemplatesDataSource}
                                                        placeholder="Seleziona un template..."
                                                        label="Template sorgente"
                                                        allowClear
                                                        readOnly={this.LockSourceTemplateField}
                                                    />
                                                </div>
                                            )}
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={2}>
                    <div className="navbar pushbutton-panel">
                        <div className="collapse navbar-collapse">
                            <h3 className="sections-title">Sezioni</h3>
                            <ul className="nav navbar-nav">
                                <li className="">
                                    <button
                                        className="btn btn-default btn-scroller btn-general-data"
                                        style="width: 100%; text-align: center"
                                        data-bind={{ click: workflowEditor.scrollToGeneralData.bind(workflowEditor) }}
                                    >
                                        Dati generali
                                    </button>
                                </li>
                                <li className="">
                                    <button
                                        className="btn btn-default btn-xs btn-status"
                                        data-bind={{
                                            css: { "btn-primary": workflowEditor.WorkflowTaskHelpersOpened },
                                            toggle: workflowEditor.WorkflowTaskHelpersOpened,
                                            attr: {
                                                "data-original-title": workflowEditor.WorkflowTaskHelpersOpened()
                                                    ? "La sezione è aperta"
                                                    : "La sezione è chiusa",
                                            },
                                            tooltip: { placement: "left" },
                                        }}
                                    >
                                        <i
                                            className="fa"
                                            data-bind={{
                                                css: {
                                                    "fa-folder-open-o": workflowEditor.WorkflowTaskHelpersOpened,
                                                    "fa-folder-o": !workflowEditor.WorkflowTaskHelpersOpened(),
                                                },
                                            }}
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-default btn-scroller btn-task-state"
                                        data-bind={{
                                            click: workflowEditor.scrollToWorkflowState.bind(workflowEditor),
                                        }}
                                    >
                                        <i className="fa fa-info"></i>
                                        Attività
                                    </button>
                                </li>
                                <li className="">
                                    <button
                                        className="btn btn-default btn-xs btn-status"
                                        data-bind={{
                                            css: { "btn-primary": workflowEditor.ResourcesOpened },
                                            toggle: workflowEditor.ResourcesOpened,
                                            attr: {
                                                "data-original-title": workflowEditor.ResourcesOpened()
                                                    ? "La sezione è aperta"
                                                    : "La sezione è chiusa",
                                            },
                                            tooltip: { placement: "left" },
                                        }}
                                    >
                                        <i
                                            className="fa"
                                            data-bind={{
                                                css: {
                                                    "fa-folder-open-o": workflowEditor.ResourcesOpened,
                                                    "fa-folder-o": !workflowEditor.ResourcesOpened(),
                                                },
                                            }}
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-default btn-scroller btn-resources"
                                        data-bind={{ click: workflowEditor.scrollToResources.bind(workflowEditor) }}
                                    >
                                        <i className="fa fa-users"></i>
                                        Risorse
                                    </button>
                                </li>
                                <li className="">
                                    <button
                                        className="btn btn-default btn-xs btn-status"
                                        data-bind={{
                                            css: { "btn-primary": workflowEditor.AdvancedSettingsOpened },
                                            toggle: workflowEditor.AdvancedSettingsOpened,
                                            attr: {
                                                "data-original-title": workflowEditor.AdvancedSettingsOpened()
                                                    ? "La sezione è aperta"
                                                    : "La sezione è chiusa",
                                            },
                                            tooltip: { placement: "left" },
                                        }}
                                    >
                                        <i
                                            className="fa"
                                            data-bind={{
                                                css: {
                                                    "fa-folder-open-o": workflowEditor.AdvancedSettingsOpened,
                                                    "fa-folder-o": !workflowEditor.AdvancedSettingsOpened(),
                                                },
                                            }}
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-default btn-scroller btn-advanced"
                                        data-bind={{
                                            click: workflowEditor.scrollToAdvancedSettings.bind(workflowEditor),
                                        }}
                                    >
                                        <i className="fa fa-cogs"></i>
                                        Avanzate
                                    </button>
                                </li>
                                <li className="actions-separator">&nbsp;</li>
                                <li className="save-button text-right">
                                    <button
                                        className="btn"
                                        data-bind={{ click: workflowEditor.saveSectionsConfig.bind(workflowEditor) }}
                                    >
                                        Salva impostazioni
                                    </button>
                                </li>
                                <li className="defaults-button text-right">
                                    <button
                                        className="btn"
                                        data-bind={{ click: workflowEditor.loadSectionsSettings.bind(workflowEditor) }}
                                    >
                                        Carica impostazioni
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Layout.Grid.Cell>
            </Layout.Grid>
        );
    }

    private renderLinks() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workflowEditor = this;
        const link: IDataSourceModel<number, WorkflowLink> = null;
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const newLink: WorkflowLink = null;

        return (
            <Table
                title={TextResources.Todolist.WorkflowLinks}
                dataSource={Table.defaultDataSource(this.Links, (link) => ({
                    id: link.TaskId(),
                    title: link.TaskName(),
                }))}
                components={{ emptyTableTemplate: () => <h4>{TextResources.Todolist.EmptyLinksTable}</h4> }}
                fixedLayout
                systemScrollable
                striped
                rowAs="link"
            >
                <Column title={TextResources.Todolist.JobOrder} style={{ verticalAlign: "middle", width: "30%" }}>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowLink>) => (
                            <span data-bind={{ text: link.model.JobOrderName }}></span>
                        )}
                    </ColumnBody>
                    <ColumnFooter>
                        {() => (
                            <With data={this.NewWorkflowLink} as="newLink">
                                {() => (
                                    <input
                                        type="hidden"
                                        data-bind={{
                                            value: newLink.JobOrderId,
                                            select2SetValueFromField: newLink.JobOrderId,
                                            select2: {
                                                allowClear: true,
                                                query: newLink.findJobOrders.bind(newLink),
                                                initSelection: newLink.findJobOrder.bind(newLink),
                                                placeholder: "Seleziona una commessa...",
                                            },
                                        }}
                                        className="form-control"
                                    />
                                )}
                            </With>
                        )}
                    </ColumnFooter>
                </Column>
                <Column title={TextResources.Todolist.WorkflowAlt} style={{ verticalAlign: "middle", width: "30%" }}>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowLink>) => (
                            <span data-bind={{ text: link.model.WorkflowName }}></span>
                        )}
                    </ColumnBody>
                    <ColumnFooter>
                        {() => (
                            <With data={this.NewWorkflowLink} as="newLink">
                                {() => (
                                    <input
                                        type="hidden"
                                        data-bind={{
                                            value: newLink.WorkflowId,
                                            select2SetValueFromField: newLink.WorkflowId,
                                            select2: {
                                                allowClear: true,
                                                query: newLink.findWorkflows.bind(newLink),
                                                initSelection: newLink.findWorkflow.bind(newLink),
                                                placeholder: "Seleziona un flusso...",
                                            },
                                        }}
                                        className="form-control"
                                    />
                                )}
                            </With>
                        )}
                    </ColumnFooter>
                </Column>
                <Column title={TextResources.Todolist.Task} style={{ verticalAlign: "middle", width: "30%" }}>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowLink>) => <span data-bind={{ text: link.model.TaskName }}></span>}
                    </ColumnBody>
                    <ColumnFooter>
                        {() => (
                            <With data={this.NewWorkflowLink} as="newLink">
                                {() => (
                                    <input
                                        type="hidden"
                                        data-bind={{
                                            value: newLink.TaskId,
                                            select2SetValueFromField: newLink.TaskId,
                                            select2: {
                                                allowClear: true,
                                                query: newLink.findTasks.bind(newLink),
                                                initSelection: newLink.findTask.bind(newLink),
                                                placeholder: "Seleziona una attività...",
                                            },
                                        }}
                                        className="form-control"
                                    />
                                )}
                            </With>
                        )}
                    </ColumnFooter>
                </Column>
                <Column style={{ width: "10%" }}>
                    <ColumnBody>
                        {() => (
                            <button
                                className="btn btn-danger btn-sm pull-right"
                                data-bind={{ click: workflowEditor.RemoveLink.bind(workflowEditor, link.model) }}
                            >
                                <i className="fa fa-trash-o"></i>&nbsp;{TextResources.Todolist.Remove}
                            </button>
                        )}
                    </ColumnBody>
                    <ColumnFooter>
                        {() => (
                            <button
                                className="btn btn-success pull-right"
                                data-bind={{ click: workflowEditor.AddNewLink.bind(workflowEditor) }}
                            >
                                <i className="fa fa-plus"></i>&nbsp;{TextResources.Todolist.Add}
                            </button>
                        )}
                    </ColumnFooter>
                </Column>
            </Table>
        );
    }

    private renderRelatedDocuments() {
        return (
            <WorkflowRelatedDocumentsEditorComponent
                workflowId={this.Id}
                jobOrderId={this.JobOrderId}
                owner={this}
                forwardRef={(editor) => this.RelatedDocumentsEditor(editor)}
            />
        );
    }

    private rendersSnapshotsEditor() {
        return <WorkflowSnapshotsEditor workflowId={this.Id} />;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkflowEditor);
}
