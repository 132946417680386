import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout"
import { IApplicationNavBarAction } from "../../Desktop/interfaces/IApplication";
import { LazyImport } from "../../Core/DependencyInjection";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { TicketsRoot, _TicketsRoot } from "./TicketsRoot";
import { BaseComponentApplication } from "../../Desktop/BaseComponentApplication";

export class TicketsApplication extends BaseComponentApplication
{
    private editor : _TicketsRoot;

    public navBarActions: ko.ObservableArray<IApplicationNavBarAction> = ko.observableArray([]);
    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IOPAService>())
    private OPAService : IOPAService;
    
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    // @Right("GDPR_CanInsertOrUpdateTreatments")
    // private canInsertOrUpdateTreatments: boolean;

    constructor()
    {
        super({tileColor: "tile-green"});
    }

    getApplicationCode() {
        return ProlifeSdk.TicketsApplicationCode;
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Tickets.ApplicationName;
    }

    getIcon(): string
    {
        return "fa fa-paper-plane-o";
    }

    registerRoutes() : void
    {
        this.OPAService.Get("#/Tickets", () => this.startApplication());
    }

    getApplicationRoute() : string
    {
        return "#/Tickets";
    }

    getComponent(){
        return <TicketsRoot application={this} forwardRef={e => this.editor = e} />
    }

    startApplication() {
        this.onGoingDown();

        this.desktopService.SystemHeader.setSidebarVisibility(true);
        this.applicationsService.startApplication(this);
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(TicketsApplication);
}