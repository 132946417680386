import * as React from "@abstraqt-dev/jsxknockout";
import { ResponseData, ResponseError } from "../../../Core/response/ResponseBase";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import {
    DocumentResourcesSettings,
    IDocumentResourcesSettingsManager,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IDocumentResourcesSettingsManager";
import { DocumentResourcesSettingsEditor } from "./ui/DocumentResourcesSettingsEditor";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxServiceNew } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";

export class DocumentResourcesSettingsManager implements IDocumentResourcesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxService: IAjaxServiceNew;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private settings: DocumentResourcesSettings;

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }

    async load(): Promise<DocumentResourcesSettings> {
        try {
            const loadResponse = await this.ajaxService.Get<ResponseData<DocumentResourcesSettings>>(
                "d/documentsResourcesSettings",
                "",
                {}
            );
            if (!loadResponse.succeeded) {
                loadResponse.errors && this.handleResponseErrors(loadResponse.errors);
            } else {
                this.settings = loadResponse.data;
            }
        } catch (e) {
            console.error("Impossibile caricare le impostazioni per le risorse assegnate ai documenti");
            this.infoToastService.Error(TextResources.Invoices.LoadResourcesSettingsError);
        }

        return this.settings;
    }

    getName(): string {
        return nameof<IDocumentResourcesSettingsManager>();
    }

    getLabel(): string {
        return TextResources.Invoices.DocumentResourcesSettingsManagerLabel;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView | { render(): React.ReactNode } {
        return {
            templateName: null,
            templateUrl: null,
            viewModel: null,
            render: () => <DocumentResourcesSettingsEditor settingsManager={this} />,
        };
    }

    getSettings(): DocumentResourcesSettings {
        return this.settings;
    }

    async saveSettings(settings: DocumentResourcesSettings): Promise<DocumentResourcesSettings> {
        try {
            const saveResponse = await this.ajaxService.Post<ResponseData<DocumentResourcesSettings>>(
                "d/documentsResourcesSettings",
                "save",
                {
                    methodData: settings,
                }
            );

            if (!saveResponse.succeeded) {
                saveResponse.errors && this.handleResponseErrors(saveResponse.errors);
            } else {
                this.settings = saveResponse.data;
                this.infoToastService.Success(TextResources.Invoices.SaveResourcesSettingsSuccess);
            }
        } catch (e) {
            console.error("Impossibile salvare le impostazioni per le risorse assegnate ai documenti");
            this.infoToastService.Error(TextResources.Invoices.SaveResourcesSettingsError);
        }

        return this.settings;
    }

    private handleResponseErrors(errors: ResponseError[]): void {
        let message = "";

        for (const error of errors) {
            message += TextResources.Invoices[error.code] + "<br />";
        }

        this.infoToastService.Error(message);
    }
}
