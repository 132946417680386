import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IMovementService } from "../../../../ProlifeSdk/interfaces/warehouse/IMovementService";
import { IOrdersService } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IReferenceDetailsViewModelFactory,
    IEntityRefInfo,
    IReferenceDetailsViewModel,
    ISimpleEntitiesViewModel,
} from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IDocumentReferencesMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IReferenceForMap } from "../../../../bindings";
import { IArticlesService, IArticle, IArticleById } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IWarehousesService, IWarehouse } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IStockService } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import {
    IRecentLoadForArticle,
    IPendingSupplierOrderForArticle,
    IPendingCustomerOrderForArticle,
    IAllBusinessInfoForArticle,
    IArticleStockOnWarehouse,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticleBusinessInfo";
import { Deferred } from "../../../../Core/Deferred";

export class PurchaseArticleRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory {
    public UrlProvider: (docId: number, regId: number) => string;

    constructor() {}

    GetEntityTypeDescription(): string {
        return ProlifeSdk.TextResources.Blog.Purchases;
    }

    CreateViewModelsFor(
        references: IRefDocumentRow[],
        details: IEntityRefInfo[],
        customerId: number,
        isReferencer: boolean
    ): IReferenceDetailsViewModel[] {
        const viewModels: IReferenceDetailsViewModel[] = [];
        references.forEach((r: IRefDocumentRow) => {
            const matches = details.filter((d) => d.EntityKeyId == r.SourceEntityKeyId);
            viewModels.push(new PurchaseArticleRefDetailsViewModel(customerId, r, matches[0], isReferencer));
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(
        referencedLeafsInfo: ILeafReferenceInfo[],
        warehouseId: number,
        customerId: number
    ): IReferenceDetailsViewModel[] {
        const viewModels: IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r: ILeafReferenceInfo) => {
            viewModels.push(new LeafPurchaseArticleViewModel(warehouseId, customerId, r));
        });
        return viewModels;
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(
        referenceMap: IDocumentReferencesMap
    ): ISimpleEntitiesViewModel {
        //Implementare se necessario
        return new WarehousePurchaseEntitiesViewModel(referenceMap);
    }
}

export class WarehousePurchaseEntitiesViewModel implements ISimpleEntitiesViewModel {
    @LazyImport(nameof<IArticlesService>())
    private articleService: IArticlesService;

    templateName = "documents-map-viewer-warehouse-purchases";
    templateUrl = "warehouse/templates/documents/referencemap";
    EntityType: string = ProlifeSdk.WarehousePurchaseArticleEntityTypeCode;

    public Articles: ko.ObservableArray<WarehousePurchaseArticleForReferenceMap> = ko.observableArray([]);

    constructor(private referenceMap: IDocumentReferencesMap) {}

    public LoadEntities(references: IReferenceForMap[]): Promise<void> {
        const entitiesIds = references.filter((r) => r.SourceEntityType == this.EntityType).map((r) => r.SourceId);

        const def = new Deferred<void>();
        this.articleService
            .GetArticlesByIds(entitiesIds)
            .then((articles: IArticleById[]) => {
                this.Articles(
                    articles.map((a: IArticleById) => {
                        return new WarehousePurchaseArticleForReferenceMap(
                            a,
                            this,
                            this.referenceMap.SourceWarehouseId,
                            this.referenceMap.CustomerId
                        );
                    })
                );
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    public UnselectAll(): void {
        this.Articles().forEach((a) => a.IsSelected(false));
    }

    public SelectRelatedRows(entityId: number, entityType: string) {
        this.Articles().forEach((a) => {
            a.IsSelected(a.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }

    public OnSelectionChanged(selected: WarehousePurchaseArticleForReferenceMap) {
        this.Articles().forEach((a) => {
            a.IsSelected(a == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.article.ArticleId, this.EntityType);
    }
}

export class WarehousePurchaseArticleForReferenceMap extends NodeSubElement {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    public IsSelected: ko.Observable<boolean> = ko.observable(false);

    constructor(
        public article: any,
        private container: WarehousePurchaseEntitiesViewModel,
        private sourceWarehouseId: number,
        private customerId: number
    ) {
        super(container, article.ArticleId, container.EntityType);
    }

    public ShowArticleBusinessInfo() {
        const vm: IDialog = new ArticleBusinessInfoDialog(
            this.sourceWarehouseId,
            this.article.CatalogId,
            this.customerId,
            this.article
        );
        this.dialogsService.ShowModal<void>(vm, "fullscreen", null, vm.templateUrl, vm.templateName);
    }
}

export class PurchaseArticleRefViewModelBase {
    public templateName: string;
    public templateUrl: string;

    @LazyImport(nameof<IArticlesService>())
    public articlesService: IArticlesService;

    @LazyImport(nameof<IWarehousesService>())
    public warehouseService: IWarehousesService;

    @LazyImport(nameof<IMovementService>())
    public movementsService: IMovementService;

    @LazyImport(nameof<IOrdersService>())
    public ordersService: IOrdersService;

    @LazyImport(nameof<IStockService>())
    public stockService: IStockService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    private ArticleStatusForWarehouse: ko.Computed<number>;
    private ShowedWarehouses: ko.Computed<WarehouseStatusForArticle[]>;
    private Warehouses: ko.ObservableArray<WarehouseStatusForArticle> = ko.observableArray([]);
    private AreCustomersWarehousesVisible: ko.Observable<boolean> = ko.observable(false);

    private WarehouseId: ko.Observable<number> = ko.observable();
    private WarehouseCustomerId: ko.Observable<number> = ko.observable();
    private ShowBusinessInfo: ko.Observable<boolean> = ko.observable(false);
    private StockAmount: ko.Observable<number> = ko.observable(0);
    private StockAmountOnGeneralWarehouses: ko.Observable<number> = ko.observable(0);
    private ReservedAmount: ko.Observable<number> = ko.observable(0);
    private LoadPriceAvg: ko.Observable<number> = ko.observable(0);
    private RecentLoads: ko.ObservableArray<IRecentLoadForArticle> = ko.observableArray([]);
    private PendingSupplierOrders: ko.ObservableArray<IPendingSupplierOrderForArticle> = ko.observableArray([]);
    private PendingCustomerOrders: ko.ObservableArray<IPendingCustomerOrderForArticle> = ko.observableArray([]);
    private TotalOrdered: ko.Observable<number> = ko.observable(0);
    private MinimumAmount: ko.Observable<number> = ko.observable(0);
    private RecentSellPrices: ko.ObservableArray<number> = ko.observableArray([]);

    constructor(warehouseId: number, articleId: number, catalogId: number, customerId: number) {
        this.templateUrl = "warehouse/templates/documents/referencemap/referencedetails";

        if (warehouseId) {
            this.warehouseService.getWarehouseById(warehouseId).then((w: IWarehouse) => {
                this.WarehouseId(w.Id);
                this.WarehouseCustomerId(w.CustomerId);
            });
        }

        this.ArticleStatusForWarehouse = ko.computed(() => {
            if (!warehouseId) return -1;

            return this.stockService.CalculateArticleStatus(
                this.StockAmount(),
                this.ReservedAmount(),
                this.TotalOrdered(),
                this.MinimumAmount()
            );
        });

        this.ShowedWarehouses = ko.computed(() => {
            return this.Warehouses().filter((w) => {
                return !w.IsCustomerWarehouse || this.AreCustomersWarehousesVisible();
            });
        });

        //Per ora ignoro il cliente selezionato sul documento
        this.articlesService
            .GetAllBusinessInfoForArticle(articleId, warehouseId, catalogId, customerId)
            .then((info: IAllBusinessInfoForArticle) => {
                this.StockAmount(info.GeneralInfo.StockAmount);
                this.StockAmountOnGeneralWarehouses(info.GeneralInfo.StockAmountOnGeneralWarehouses);
                this.ReservedAmount(info.GeneralInfo.ReservedAmount < 0 ? 0 : info.GeneralInfo.ReservedAmount);
                this.LoadPriceAvg(info.GeneralInfo.LoadPriceAvg);
                this.RecentLoads(info.RecentLoads);
                this.PendingSupplierOrders(info.PendingSupplierOrders);
                this.PendingCustomerOrders(info.PendingCustomerOrders);
                this.MinimumAmount(info.GeneralInfo.MinimumAmount);
                this.RecentSellPrices(info.RecentSellPrices);
                this.Warehouses(
                    info.StocksOnWarehouses.filter((s: IArticleStockOnWarehouse) => {
                        return s.StockAmount > 0 && s.WarehouseId != warehouseId;
                    }).map((s: IArticleStockOnWarehouse) => {
                        return new WarehouseStatusForArticle(s);
                    })
                );

                var ordered = 0;
                info.PendingSupplierOrders.forEach((o: IPendingSupplierOrderForArticle) => {
                    ordered += o.PendingAmount;
                });
                this.TotalOrdered(ordered);
            });
    }

    ShowDetails() {}

    OpenLoadDetails(recentLoad: IRecentLoadForArticle) {
        this.documentsService.OpenDocumentOverlay(ProlifeSdk.WarehouseLoadEntityTypeCode, recentLoad.Id);
    }

    OpenSupplierOrderDetails(order: IPendingSupplierOrderForArticle) {
        this.documentsService.OpenDocumentOverlay(ProlifeSdk.SupplierOrderEntityTypeCode, order.Id);
    }

    OpenCustomerOrderDetails(order: IPendingCustomerOrderForArticle) {
        this.documentsService.OpenDocumentOverlay(ProlifeSdk.CustomerOrderEntityTypeCode, order.Id);
    }

    SwitchBusinessInfoVisibility() {
        this.ShowBusinessInfo(!this.ShowBusinessInfo());
    }
}

export class LeafPurchaseArticleViewModel
    extends PurchaseArticleRefViewModelBase
    implements IReferenceDetailsViewModel
{
    constructor(private warehouseId: number, private customerId: number, private reference: ILeafReferenceInfo) {
        super(warehouseId, reference.EntityKeyId, reference.CatalogId, customerId);
        this.templateName = "leaf-purchasearticle";
    }
}

export class PurchaseArticleRefDetailsViewModel
    extends PurchaseArticleRefViewModelBase
    implements IReferenceDetailsViewModel
{
    constructor(
        private customerId: number,
        private reference: IRefDocumentRow,
        private details: IEntityRefInfo,
        isReferencer: boolean
    ) {
        super(reference.WarehouseId, reference.SourceEntityKeyId, reference.CatalogId, customerId);
        this.templateName = "purchasearticle";
    }
}

export class ArticleBusinessInfoDialog extends PurchaseArticleRefViewModelBase implements IDialog {
    public title: string;
    public modal: {
        close: (result?: any) => void;
    };

    constructor(warehouseId: number, catalogId: number, customerId: number, article: any) {
        super(warehouseId, article.ArticleId, catalogId, customerId);

        this.title = article.Description;

        this.templateName = "article-business-info-dialog";
    }

    close(): void {
        this.modal.close();
    }

    action(): void {}
}

export class WarehouseStatusForArticle {
    public StockAmount: ko.Observable<number> = ko.observable(0);
    public Reservation: ko.Observable<number> = ko.observable(0);
    public WarehouseName: ko.Observable<string> = ko.observable("");
    public CustomerName: ko.Observable<string> = ko.observable("");
    public IsCustomerWarehouse: boolean;
    public OverReserved: boolean;

    constructor(s: IArticleStockOnWarehouse) {
        this.StockAmount(s.StockAmount);
        this.Reservation(s.Reservation);
        this.WarehouseName(s.WarehouseName + " (" + s.CustomerName + ")");
        this.CustomerName(s.CustomerName);
        this.IsCustomerWarehouse = s.CustomerId != null;
        this.OverReserved = this.StockAmount() - this.Reservation() < 0;
    }
}
