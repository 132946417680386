import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 03/12/2015
 * Time: 12:46
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { TemplateActivitiesImportResultDialog } from "./TemplateActivitiesImportResultDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export interface ITemplateActivitiesImportResult
{
    Errors : string[];
}

export class TemplateImportDialog implements IDialog {
    private dialogsService : IDialogsService;
    private desktopService : IDesktopService;
    private ajaxService : IAjaxService;

    templateName: string = "template-activities-import-dialog";
    templateUrl: string = "todolist/templates/templates-designer";
    title : string = ProlifeSdk.TextResources.Todolist.ImportTemplate;

    public selectedFiles : ko.ObservableArray<File> = ko.observableArray();

    modal: { close: (result?: any) => void; };

    constructor(private serviceLocator : IServiceLocator, private templateId : number, templateTitle : string)
    {
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.desktopService = <IDesktopService> serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);

        this.title = String.format(this.title, templateTitle);
    }

    dispose()
    {
    }

    public close(): void
    {
        this.modal.close(false);
    }

    public action(): void
    {
        if(this.selectedFiles().length == 0)
        {
            this.close();
            return;
        }

        this.uploadFile()
            .then((r : ITemplateActivitiesImportResult) => {
                var dialog = new TemplateActivitiesImportResultDialog(this.serviceLocator, r);
                setTimeout(() => {
                    this.dialogsService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName).finally(() => {
                        this.modal.close(true);
                    });
                }, 1000);
            }).catch(() => {
                this.close();
            });
    }

    public setValues(item: any)
    {
    }

    public clearAndShow()
    {
        this.selectedFiles([]);
        this.dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
    }

    public uploadFile() : Promise<ITemplateActivitiesImportResult>
    {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Todolist.ImportInProgress);
        var files = this.selectedFiles();
        var data = {};
        for(var i = 0; i < files.length; i++)
        {
            data['activities' + i] = files[i];
        }

        return this.ajaxService.Upload<ITemplateActivitiesImportResult>("todoList-api", "TemplateActivityImport/" + this.templateId, data)
            .finally(() => { this.desktopService.UnblockPageUI(); });
    }
}