import * as ko from "knockout";
import { IDocumentRowInlineReferenceProviderFactoryWithState, IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider } from "../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { ISelectedEntityInlineInfo, IEntityForRef } from "../ProlifeSdk/interfaces/invoice/IDocumentRow";

export class EntitiesFinderForDocumentRow
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let entitiesReferencesProviders : IDocumentRowInlineReferenceProviderFactoryWithState[] = valueAccessor()["providers"];

        let descriptionField = valueAccessor()["descriptionField"];
        let entityKeyIdField = valueAccessor()["entityKeyIdField"];
        let enabledField = valueAccessor()["enabledField"];
        let onSearchStartEventHandler = valueAccessor()["onSearchStartEventHandler"];
        let onSearchEndEventHandler = valueAccessor()["onSearchEndEventHandler"];
        let docInfoCallback = valueAccessor()["docInfoCallback"];

        let typeahead = (<any>$(element)).typeahead({
            items: 50,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            display: (json) => {
                var item = JSON.parse(json);
                if(!item)
                    return "";
                if(item.EntityType == "PROVIDER")
                    return item.ActivationCode;
                return item.Code + " " + item.Description;
            },
            limit: 1000,
            source : EntitiesFinderForDocumentRow.findEntities.bind(null, {}, entitiesReferencesProviders, enabledField, onSearchStartEventHandler, onSearchEndEventHandler, docInfoCallback),
            templates : {
                suggestion : function(json)
                {
                    var data = JSON.parse(json);
                    let template = `<div style="border-bottom: 1px solid #DDDDDD">
                                        <table style="min-height: ${data.SubTitle ? '32px' : '16px'}; table-layout: fixed">
                                            <tbody>
                                                <tr>`;
                    if(data.Icon)
                        template +=                 `<td rowspan="2" style="padding-right: 5px; vertical-align: top !important; width: 32px">
                                                        <span style="background-color: ${data.Icon.background}; color: ${data.Icon.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px;" class="${data.Icon.icon}"></span>
                                                    </td>`;
                    
                    template +=                     `<td>${data.Code}&nbsp;<small>${data.Description}<small></td>
                                                </tr>`;
                    if(data.SubTitle)
                        template +=             `<tr>
                                                    <td colspan="2"><small class="text-muted">${data.SubTitle}</span></td>
                                                </tr>`;
                    template +=             `</tbody>
                                        </table>
                                    </div>`;
                    return template;
                    //return "<div>" + item.Description + "</div>";
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);

            if(!item)
            {
                entityKeyIdField(null);
                descriptionField("");
                return "";
            }

            //Caso elenco provider disponibili
            if(item.EntityType == "PROVIDER")
            {
                entityKeyIdField(null);
                descriptionField(item.ActivationCode);
                return item.ActivationCode;
            }

            var info : ISelectedEntityInlineInfo = {
                ProviderActivationCode : item.ActivationCode,
                Entity : {
                    EntityKeyType : item.EntityType,
                    EntityKeyId : item.id
                }
            };

            entityKeyIdField(info);
            descriptionField(item.Code + " " + item.Description);
            return item.Code + " " + item.Description;
        });

        var closestForm = $(element).closest("form");
        if(closestForm.length > 0) {
            closestForm.first().on('submit', () => {
                typeahead.typeahead('val', descriptionField());
            });
        }
    }

    private static findEntities(_this : any, providers : IDocumentRowInlineReferenceProviderFactoryWithState[], enabledField : ko.Observable<boolean>,
            onSearchStartEventHandler : (searchProvider : IDocumentRowInlineReferenceProviderFactory, query : string) => {},
            onSearchEndEventHandler : (searchProvider : IDocumentRowInlineReferenceProviderFactory, query : string) => {},
            docInfoCallback : () => IDocumentInfoForInlineRefProvider,
            query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any, numberOfElementsLoaded? : number) {

        if((query || "").length == 0) {
            setTimeout(() => {
                asyncProcess([]);
            }, 0);
            return;
        }

        //Quando l'utente scrive chiocciola mostro l'elenco dei provider disponibili
        if(query == "@")
        {
            setTimeout(() => {
                asyncProcess(providers
                    .sort((a, b) => { return a.Provider.ActivationCode < b.Provider.ActivationCode ? -1 : 1; })
                    .filter(p => p.Enabled == true)
                    .map((p : IDocumentRowInlineReferenceProviderFactoryWithState) => { return EntitiesFinderForDocumentRow.transformEntity({ 
                        Id : null, 
                        EntityType : "PROVIDER",
                        Code: p.Provider.ActivationCode,
                        Description : " - " + p.Provider.Description,
                        Icon: {
                            background: "white",
                            foreground: "black",
                            icon: p.Provider.Icon
                        }
                    }, p.Provider); })
                );
            }, 0);
            return;
        }

        var activationCode : string = (query && query.length > 1 ? query.substring(0, 3) : "").toUpperCase();
        var matchesByCode : IDocumentRowInlineReferenceProviderFactory[] = providers.filter((p : IDocumentRowInlineReferenceProviderFactoryWithState) => { return p.Enabled == true && p.Provider.ActivationCode == activationCode; }).map(p => p.Provider);
        var adaptedQuery : string = (query || "").length > 0 && query[0] == "@" ? query.substring(3) : query; //Rimuovo dalla testa l'activation code

        //Devo limitare la adaptedQuery ad un massimo di 10 parole
        var words : string[] = adaptedQuery.replace(/[ ]+/, " ").split(" ");
        var limitedQuery : string  = words.length > 10 ? words.slice(0, 10).join(" ") : adaptedQuery;

        if(limitedQuery.length == 0 || matchesByCode.length == 0 || (enabledField && !enabledField())) {
            setTimeout(() => {
                asyncProcess([]);
            }, 0);
            return;
        }

        if(_this.lastTimeout)
            clearTimeout(_this.lastTimeout);

        var provider : IDocumentRowInlineReferenceProviderFactory = matchesByCode[0];

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;

            if(onSearchStartEventHandler)
                onSearchStartEventHandler(provider, limitedQuery);

            provider.findEntities(limitedQuery, numberOfElementsLoaded || 0, 50, docInfoCallback()).then((entities : IEntityForRef[]) => {

                if(onSearchEndEventHandler)
                    onSearchEndEventHandler(provider, limitedQuery);

                return asyncProcess(entities.map((e : IEntityForRef) => { return EntitiesFinderForDocumentRow.transformEntity(e, provider); }));
            });
        }, 1000);
    }

    public static transformEntity(entity : IEntityForRef, provider : IDocumentRowInlineReferenceProviderFactory) : any {
        return {
            id: entity.Id,
            EntityType : entity.EntityType,
            Code: entity.Code,
            Description: entity.Description,
            ActivationCode : provider.ActivationCode,
            SubTitle: entity.SubTitle,
            Icon: entity.Icon,

            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return (this.Code + " " + this.Description).toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply((this.Code + " " + this.Description), arguments);
            },
            replace: function() {
                return String.prototype.replace.apply((this.Code + " " + this.Description), arguments);
            }
        }
    }
}

ko.bindingHandlers["entitiesFinderForDocumentRow"] = new EntitiesFinderForDocumentRow();