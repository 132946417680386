import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { FilterViewModel } from "../FilterViewModel";
import { BlogBooklet } from "../BlogBooklet";
import { EventsViewModel } from "./EventsViewModel";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import {
    IJobOrderForTaskBoard,
    ITodoListService,
    ITaskForTaskBoard,
} from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ILogFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { ITag } from "../../../ProlifeSdk/interfaces/ITag";
import { Delay } from "../../../Decorators/Delay";

export class JobOrderNotes {
    protected todoListService: ITodoListService;
    protected jobOrdersService: IJobOrderService;
    protected dialogsService: IDialogsService;

    public templateName = "job-order-notes";
    public templateUrl = "blog/Templates/JobOrderNotes";
    public viewModel: any;

    private events: ko.Observable<any> = ko.observable();
    private filterViewModel: FilterViewModel;
    private sessionFilter: ILogFilter[];
    private newTag: ITag[];
    private isSelectable: ko.Observable<boolean> = ko.observable(false);
    private canLock: ko.Observable<boolean> = ko.observable(false);

    public jobOrdersToShowEvents: ko.ObservableArray<IJobOrderForTaskBoard> = ko.observableArray([]);
    public showSelectedJobOrders: ko.Observable<boolean> = ko.observable(false);
    private selectedTasks: number[] = [];

    private firstJobOrder: ko.Computed<IJobOrderForTaskBoard>;
    private secondJobOrder: ko.Computed<IJobOrderForTaskBoard>;
    private otherJobOrders: ko.Computed<IJobOrderForTaskBoard[]>;

    //private workflowNavigator : IWorkflowNavigator = null;

    constructor(private serviceLocator: IServiceLocator, private console: BlogBooklet) {
        this.viewModel = this;
        this.sessionFilter = [];
        this.newTag = [];

        this.events(new EventsViewModel(this.serviceLocator, <any>this.console, this.jobOrdersToShowEvents));
        this.filterViewModel = new FilterViewModel(this.serviceLocator, <any>this.console);

        this.jobOrdersService = <IJobOrderService>this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.dialogsService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        /*this.workflowNavigator = this.todoListService.GetWorkflowNavigatorUI();
        this.workflowNavigator.SetCollapsibleMode(true)
        this.workflowNavigator.setMobileStatus(true);
        this.workflowNavigator.addMenuObserver(this);
        this.workflowNavigator.ShowNumberOfEvents(true);*/

        this.firstJobOrder = ko.computed(() => {
            return this.jobOrdersToShowEvents().length == 0 ? null : this.jobOrdersToShowEvents()[0];
        });

        this.secondJobOrder = ko.computed(() => {
            return this.jobOrdersToShowEvents().length < 2 ? null : this.jobOrdersToShowEvents()[1];
        });

        this.otherJobOrders = ko.computed(() => {
            return this.jobOrdersToShowEvents().filter(
                (j: IJobOrderForTaskBoard) => j != this.firstJobOrder() && j != this.secondJobOrder()
            );
        });

        const authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.canLock(authorizationService.isAuthorized("Blog_LockUnlock"));
    }

    public dispose() {
        //this.workflowNavigator.Dispose();
    }

    private RefreshWorkflowNavigator(): void {
        this.selectedTasks = [];
        this.console.TasksNavigatorMenu().refresh();
        //return this.workflowNavigator.Initialize(this.jobOrdersToShowEvents().map((j) => { return j.JobOrderId; }));
    }

    /*public SelectTasksOnWorkflowNavigator(tasksIds : number[])
    {
        this.workflowNavigator.SetSelectedTasks(tasksIds).then(() => {
            this.onSelectionChanged(this.workflowNavigator.getSelectedProviders());
        });
    }*/

    public RefreshAfterEventSaved() {
        this.RefreshEvents();
        //this.workflowNavigator.refreshItems().then(() => {
        //    this.RefreshEvents();
        // });
    }

    /*onSelectionChanged(selectedTasks: INavigationMenuProvider[])
    {
        var selectedTasksIds : number[] = selectedTasks.map((p : any) => { return p.task.Id; });
        this.selectedTasks = selectedTasksIds;
        this.RefreshEvents();
    }*/

    setSelectedTasksById(taskIds: number[]) {
        this.selectedTasks = taskIds;
        this.RefreshEvents();
    }

    setSelectedTasks(tasks: ITaskForTaskBoard[]) {
        const selectedTasksIds: number[] = tasks.map((t) => t.Id);
        this.setSelectedTasksById(selectedTasksIds);
    }

    /*removeAll()
    {
        var jobOrders = this.jobOrdersToShowEvents().slice();
        jobOrders.forEach((j : IJobOrderForTaskBoard) => {
            this.jobOrdersToShowEvents.remove(j);
            this.console.RemoveJobOrderFromSelection(j);
        });
        this.RefreshWorkflowNavigator();
        this.RefreshEvents();
    }

    switchSelectedJobOrdersVisibility()
    {
        this.showSelectedJobOrders(!this.showSelectedJobOrders());
    }*/

    /*findJobOrderIntoSelectionList(jobOrderId : number) : IJobOrderForTaskBoard
    {
        var jobOrder : IJobOrderForTaskBoard = null;
        this.jobOrdersToShowEvents().forEach((j : IJobOrderForTaskBoard) => {
            jobOrder = j.Id == jobOrderId ? j : jobOrder;
        });
        return jobOrder;
    }*/

    /*addJobOrderToSelection(jobOrder : IJobOrderForTaskBoard, doRefreshNow : boolean = true)
    {
        var alreadyPresent = this.findJobOrderIntoSelectionList(jobOrder.Id) != null;

        if(!alreadyPresent)
        {
            this.jobOrdersToShowEvents.push(jobOrder);
            if(doRefreshNow)
                this.RefreshWorkflowNavigator();
        }

        return !alreadyPresent;
    }*/

    removeSelectedJobOrder(jobOrderToRemove: IJobOrderForTaskBoard) {
        this.jobOrdersToShowEvents.remove(jobOrderToRemove);
        this.RefreshWorkflowNavigator();
        this.console.RemoveJobOrderFromSelection(jobOrderToRemove);
        this.RefreshEvents();
    }

    goToJobOrder(jobOrder: IJobOrderForTaskBoard) {
        window.open(this.jobOrdersService.getJobOrderUrl(jobOrder.Id), "_blank");
    }

    /*notifyFilterResultIsChanged(filteredLeafs : INavigationMenuProvider[])
    {
        //Nulla da fare
    }*/

    setJobOrders(jobOrders: IJobOrderForTaskBoard[]): void {
        this.jobOrdersToShowEvents(jobOrders.slice(0));
        this.RefreshWorkflowNavigator();
        this.RefreshEvents();
    }

    /*jobOrderClicked(jobOrderProvider : IJobOrderNavigationMenuLeafProvider)
    {
        //Nulla da fare
    }*/

    /*allJobOrderSelected(jobOrderProviders : IJobOrderNavigationMenuLeafProvider[])
    {
        var atLeastOneAdded = false;

        jobOrderProviders.forEach(j => {
            atLeastOneAdded = this.addJobOrderToSelection(j.jobOrder, false) || atLeastOneAdded;
        });

        if(atLeastOneAdded) {
            this.RefreshEvents();
            this.RefreshWorkflowNavigator();
        }

        this.console.goToPage(ProlifeSdk.Page_BlogBookLet);
    }*/

    public SelectAll() {
        const events: BlogEvent[] = this.events().GetAllEvents();
        const unselectedEvents: BlogEvent[] = events.filter((e: BlogEvent) => {
            return !e.isSelected();
        });
        events.forEach((e: BlogEvent) => {
            e.isSelected(unselectedEvents.length > 0);
        });
    }

    public PrintSelection() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Blog.PrintSelectionMsg,
            ProlifeSdk.TextResources.Blog.PrintSelectionList,
            ProlifeSdk.TextResources.Blog.PrintSelectionDetails,
            (printDetails: boolean) => {
                const selection = this.events().getAllSelectedEvents();
                const defs: Promise<any>[] = [];

                //Espando gli eventi selezionati per renderizzare il dettaglio
                if (printDetails) {
                    selection.forEach((e: BlogEvent) => {
                        if (!e.isExtended()) defs.push(e.switchExtensionState());
                    });
                }

                Promise.all(defs).then(() => {
                    //HACK : Mi serve un timeout per dare tempo a chrome di fare il rendering dei template di dettaglio
                    setTimeout(() => {
                        const selectedArticles = $(".timeline-blue.selected");
                        const currentDayNode = null;
                        const container = $("<ul class='timeline light'></ul>");

                        selectedArticles.each((index, element) => {
                            /*var day = $(element).parent().find("header")[0];

                        if(currentDayNode != day)
                        {
                            currentDayNode = day;
                            container.append($(currentDayNode).clone());
                        }*/

                            container.append($(element).clone());
                        });

                        const tmpNode = $("<div></div>");
                        tmpNode.append(container);
                        const htmlCode: string = tmpNode.html();

                        //Chiudo gli eventi espansi
                        selection.forEach((e: BlogEvent) => {
                            if (e.isExtended()) e.switchExtensionState();
                        });

                        localStorage.setItem("HtmlPrintViewer_BodyContent", htmlCode);
                        window.open("/Print", "_blank");
                    }, 1500);
                });
            }
        );
    }

    public lockEvents() {
        this.events().setLockStatusForEvents(true);
    }

    public unlockEvents() {
        this.events().setLockStatusForEvents(false);
    }

    public switchSelectability() {
        this.events().switchSelectability.apply(this.events());
        this.isSelectable(!this.isSelectable());
    }

    @Delay(100)
    public RefreshEvents() {
        const filters: ILogFilter[] = [];

        if (this.selectedTasks.length > 0) filters.push({ Tasks: this.selectedTasks });

        this.sessionFilter.forEach((f: ILogFilter) => {
            filters.push(f);
        });

        this.isSelectable(false);

        this.filterViewModel.pushFilters(filters);
        this.events(new EventsViewModel(this.serviceLocator, this.console, this.jobOrdersToShowEvents));
        this.events().LoadEvents(filters);
    }

    public GetTagForNewEvents() {
        const Tags: ITag[] = [];

        if (this.jobOrdersToShowEvents().length == 1) {
            const jobOrder = this.jobOrdersToShowEvents()[0];
            Tags.push(<ITag>{
                TagName: ProlifeSdk.Tag_JobOrder,
                Value: jobOrder.Id.toString(),
                TagTypeId: ProlifeSdk.JobOrderEntityType,
            });
            Tags.push(<ITag>{
                TagName: ProlifeSdk.Tag_CustomTag,
                Value: jobOrder.CustomerId.toString(),
                TagTypeId: ProlifeSdk.CustomerEntityType,
            });

            if (jobOrder.ResponsableUserId != null && jobOrder.ResponsableUserId > 0)
                Tags.push(<ITag>{
                    TagName: ProlifeSdk.Tag_CustomTag,
                    Value: jobOrder.ResponsableUserId.toString(),
                    TagTypeId: ProlifeSdk.HumanResources,
                });
        }

        return Tags.concat(this.newTag);
    }
}
