import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IArticlesService } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { Purchase } from "./Purchase";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class EditPurchaseModal implements IDialog {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;

    title: string = TextResources.Warehouse.EditPurchaseModalTitle;
    templateName = "warehouse-purchase-modal";
    templateUrl = "warehouse/templates";

    modal: { close: (result?: any) => void };

    private purchase: Purchase;

    constructor(purchase: Purchase, readOnly = false) {
        this.templateName = readOnly ? "warehouse-purchase-readonly-modal" : this.templateName;
        this.purchase = purchase;
    }

    close(): void {
        this.modal && this.modal.close(false);
    }

    action(): void {
        if (!this.purchase.isChanged()) {
            this.infoToast.Info(TextResources.Warehouse.NoPurchaseChangesMade);
            this.close();
            return;
        }

        this.articlesService
            .updatePurchase(this.purchase.getData())
            .then(this.onSaveSuccess.bind(this))
            .catch(() => this.infoToast.Error(TextResources.ProlifeSdk.GenericError));
    }

    private onSaveSuccess() {
        this.infoToast.Success(TextResources.Warehouse.PurchaseSavedSuccessfully);
        this.close();
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
    }
}
