import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 27/03/2019
 * Time: 10:52
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { ITodoListFolder } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { ITaskBoardAdvancedFilterDialog, ITaskBoardAdvancedFilterDialogResult } from "../../../../interfaces/ITaskBoardAdvancedFilterDialog";
import { ITaskBoardAdvancedFilter } from "../../../../../ProlifeSdk/interfaces/todolist/ITaskBoardAdvancedFilter";

export class AdvancedFilterDialog implements ITaskBoardAdvancedFilterDialog {
    public templateName: string = "taskboard-advanced-filter-dialog";
    public templateUrl: string = "todolist/templates/task-board/dialogs";
    public title: string = ProlifeSdk.TextResources.Todolist.TaskBoardAdvancedFilterDialogTitle;

    public modal: { close: (result?: any) => void; };

    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public DestinationStatus: ko.Observable<any> = ko.observable();

    public StatusesList: ko.ObservableArray<ITodoListFolder> = ko.observableArray([]);
    
    private dialogsService: IDialogsService;
    private todoListService: ITodoListService;

    constructor(private serviceLocator: IServiceLocator, currentFilter: ITaskBoardAdvancedFilter = null) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.StatusesList(this.todoListService.GetTasksFolders().filter((f) => f.Id != null));

        if (currentFilter) {
            this.StartDate(currentFilter.StartDate);
            this.EndDate(currentFilter.EndDate);

            var statusesMatch = this.StatusesList().filter((s) => s.Id == currentFilter.DestinationStatus);
            this.DestinationStatus(statusesMatch.length == 0 ? null : statusesMatch[0]);
        }
    }

    public show(): Promise<ITaskBoardAdvancedFilterDialogResult> {
        return this.dialogsService.ShowModal<ITaskBoardAdvancedFilterDialogResult>(this, null, null, this.templateUrl, this.templateName);
    }

    public reset(): void {
        this.StartDate(null);
        this.EndDate(null);
        this.DestinationStatus(null);
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        var result = !this.StartDate() && !this.EndDate() && !this.DestinationStatus() ? null : {
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            DestinationStatus: this.DestinationStatus() ? this.DestinationStatus().Id : null
        };

        this.modal.close(result);
    }

    public setStatus(status: ITodoListFolder): void {
        this.DestinationStatus(status);
    }
}