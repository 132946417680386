import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ContactTagsManager } from "./ContactTagsManager";
import { OrganizationalUnit } from "./OrganizationalUnit";
import { CustomerRolesPrices } from "./CustomerRolesPrices";
import { RecipientCodeViewModel } from "./RecipientCodeViewModel";
import { NationsProvider } from "../../../ProlifeSdk/prolifesdk/providers/NationsProvider";
import { ProvincesProvider } from "../../../ProlifeSdk/prolifesdk/providers/ProvincesProvider";
import { TrustManager } from "./TrustManager";
import { TrustAuthorizationsHistory } from "./TrustAuthorizationsHistory";
import { Validations } from "../../../Desktop/utilities/Validations";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { ICustomersGroupsService, ICustomerGroup } from "../../CustomersGroupsService";
import { CustomerGroupsDataSource, ICustomerGroupDataModel } from "../../../DataSources/CustomerGroupsDataSource";
import { CustomerDiscountsEditor } from "../../../Warehouse/warehouse/ui/Discounts/Discounts/CustomerDiscountsEditor";
import { LettersOfAttemptsManager } from "./LettersOfAttemptsManager";
import { OffsetsCodesDataSource } from "../../../DataSources/OffsetsCodesDataSource";
import { WorkflowOutcomesDataSource } from "../../../DataSources/WorkflowOutcomesDataSource";
import { CurrenciesDataSource } from "../../../DataSources/CurrenciesDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IInvoicesService } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IException } from "../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICustomerViewModel } from "../../interfaces/ICustomerViewModel";
import { IRecipientCodesProvider } from "../../interfaces/IRecipientCodesProvider";
import { INationsProvider } from "../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { IProvincesProvider } from "../../../ProlifeSdk/interfaces/settings/IProvincesSettingsManager";
import { ICustomer, IOrganizationalUnit } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IExpireModes, IExpireMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { IIvaModes, IIvaMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { IPaymentTypeManagerUi } from "../../../ProlifeSdk/interfaces/prolife-sdk/ui/IPaymentTypeManagerUi";
import { IRecipientCodeViewModel } from "../../interfaces/IRecipientCodeViewModel";
import { IRecipientCode } from "../../../ProlifeSdk/interfaces/customer/IRecipientCode";
import { ISelect2Query } from "../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { IValidationService, IValidator, IValidation } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Deferred } from "../../../Core/Deferred";
import { ResourcesDataSource } from "../../../DataSources/ResourcesDataSource";
import { ICustomerPaymentExtensionsEditor } from "./CustomerPaymentExtensionsEditor";
import { ICustomerAliasesEditor } from "./CustomerAliasesEditor";
import { CustomerEditorViewModel } from "./CustomerEditorViewModel";

export class CustomerViewModel implements ICustomerViewModel, IRecipientCodesProvider, IDataSourceListener {
    @LazyImport(nameof<ICustomersGroupsService>())
    private customersGroupsService: ICustomersGroupsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;
    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IProLifeSdkService>())
    private prolifeSdkService: IProLifeSdkService;
    @LazyImport(nameof<IInvoicesService>())
    private invoiceService: IInvoicesService;

    isNewCustomer: ko.Observable<boolean> = ko.observable(true);

    public CompactView: ko.Observable<boolean> = ko.observable(false);
    public IsCompany: ko.Observable<boolean> = ko.observable(false);

    CreationDate: ko.Observable<Date> = ko.observable();
    LastModificationDate: ko.Observable<Date> = ko.observable();
    IdCliente: ko.Observable<number> = ko.observable();

    RagioneSociale: ko.Observable<string> = ko.observable();
    Nome: ko.Observable<string> = ko.observable();
    Cognome: ko.Observable<string> = ko.observable();

    PIVA: ko.Observable<string> = ko.observable();
    CF: ko.Observable<string> = ko.observable();
    Indirizzo: ko.Observable<string> = ko.observable();
    CAP: ko.Observable<string> = ko.observable();
    Citta: ko.Observable<string> = ko.observable();
    Provincia: ko.Observable<string> = ko.observable();
    Stato: ko.Observable<string> = ko.observable();
    Telefono: ko.Observable<string> = ko.observable();
    PEC: ko.Observable<string> = ko.observable();
    FAX: ko.Observable<string> = ko.observable();
    EMail: ko.Observable<string> = ko.observable();
    RifAmministrativo: ko.Observable<string> = ko.observable();
    Sesso: ko.Observable<string> = ko.observable();
    SecondoNome: ko.Observable<string> = ko.observable();
    Eliminato: ko.Observable<boolean> = ko.observable(false);
    Comune: ko.Observable<string> = ko.observable();
    IsCliente: ko.Observable<boolean> = ko.observable(true);
    IsFornitore: ko.Observable<boolean> = ko.observable(false);
    IsPrivateContact: ko.Observable<boolean> = ko.observable(false);
    Qualification: ko.Observable<string> = ko.observable();
    Notes: ko.Observable<string> = ko.observable();

    Editable: ko.Observable<boolean> = ko.observable(false);

    public NationsProvider: INationsProvider;
    public ProvincesProvider: IProvincesProvider;

    private BlockedBy: ko.Observable<number> = ko.observable();
    private FkBlockCause: ko.Observable<number> = ko.observable();

    public FkCommercialResponsible: ko.Observable<string | number> = ko.observable();
    public CommercialResponsibleName: ko.Observable<string> = ko.observable();

    public FkAdministrativeResponsible: ko.Observable<string | number> = ko.observable();
    public AdministrativeResponsibleName: ko.Observable<string> = ko.observable();

    public CommercialResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();
    public AdministrativeResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();

    public TagsManager: ContactTagsManager;

    public formattedAddressLocation: ko.Computed<string>;

    public OrganizationalUnits: ko.ObservableArray<OrganizationalUnit> = ko.observableArray([]);
    public ContactName: ko.Computed<string>;
    public SelectedUo: ko.Observable<OrganizationalUnit> = ko.observable();
    private CanBeSetAsPrivate: ko.Observable<boolean> = ko.observable(false);

    public CustomerDiscountsEditor: CustomerDiscountsEditor;

    private isLoading = false;
    private originalData: ICustomer;

    public rolesPricesManager: CustomerRolesPrices;

    public TrustManager: ko.Observable<TrustManager> = ko.observable();
    public TrustManagementEnabled: ko.Observable<boolean> = ko.observable();
    public StartDocumentsEmissionDateForTrustMovements: ko.Observable<Date> = ko.observable();
    public TrustAuthorizationsHistory: ko.Observable<TrustAuthorizationsHistory> = ko.observable();
    public LettersOfAttemptsManager: ko.Observable<LettersOfAttemptsManager> = ko.observable();

    public CustomerGroupId: ko.Observable<number> = ko.observable();
    public CustomerGroupName: ko.Observable<string> = ko.observable();
    public CustomerGroupsDataSource: CustomerGroupsDataSource = new CustomerGroupsDataSource();

    public PublicAdministration: ko.Observable<boolean> = ko.observable(false);
    public ElectronicInvoicing: ko.Observable<boolean> = ko.observable(false);
    public NewRecipientCode: ko.Observable<string> = ko.observable();
    public RecipientCodesList: ko.ObservableArray<RecipientCodeViewModel> = ko.observableArray([]);

    public EORICode: ko.Observable<string> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expiriesManager: IExpireModes;
    // Per il default del codice IVA
    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModesManager: IIvaModes;
    private IvaModes: IIvaMode[];
    private DefaultIva: ko.Observable<IIvaMode> = ko.observable(null);

    // Per i default di modalità e scadenza di pagamento
    public DefaultPaymentExpiryType: ko.Observable<IExpireMode> = ko.observable();
    public DefaultPassivePaymentExpiryType: ko.Observable<IExpireMode> = ko.observable();
    public Expiries: ko.ObservableArray<IExpireMode> = ko.observableArray([]);
    public ActivePaymentTypeManagerUi: IPaymentTypeManagerUi;
    public PassivePaymentTypeManagerUi: IPaymentTypeManagerUi;

    public DefaultOffset: ko.Observable<number> = ko.observable();
    public OffsetsCodesDataSource: OffsetsCodesDataSource = new OffsetsCodesDataSource();

    public DefaultOutcome: ko.Observable<number> = ko.observable();
    public OutcomesDataSource: WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();

    public DefaultCurrency: ko.Observable<number> = ko.observable();
    public CurrenciesDataSource: CurrenciesDataSource;

    public HasDefaultValues: ko.Computed<boolean>;

    private OrganizationalUnitsChangesInterceptor: ko.Computed<void>;
    private electronicInvoicingInterceptor: ko.Computed<void>;

    public Values: ko.Observable<string> = ko.observable();
    private lastTimeout: ReturnType<typeof setTimeout>;

    public PaymentExtensionsEditor: ko.Observable<ICustomerPaymentExtensionsEditor> = ko.observable();
    public CustomerAliasesEditor: ko.Observable<ICustomerAliasesEditor> = ko.observable();

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    private validator: IValidator<CustomerViewModel>;

    private lastFakeId = 1;
    private autoScroll = true;

    constructor(private customer?: ICustomer, private customerEditor?: CustomerEditorViewModel) {
        this.rolesPricesManager = new CustomerRolesPrices();

        this.CurrenciesDataSource = new CurrenciesDataSource();
        this.CurrenciesDataSource.returnDeletedCurrencies(false);

        this.NationsProvider = new NationsProvider();
        this.ProvincesProvider = new ProvincesProvider();

        this.NationsProvider.SelectedNationCode.subscribe((code: string) => {
            if (this.ProvincesProvider.SelectedNationCode() == "IT" || code == "IT")
                this.ProvincesProvider.SelectedProvinceCode(undefined);

            this.ProvincesProvider.SelectedNationCode(code);
        });

        if (this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled")) {
            this.TrustManager(new TrustManager(this));
            this.TrustManager().SetEditMode(false);
        }

        if (this.authorizationsService.isAuthorized("Customers_ViewLettersOfAttempts")) {
            this.LettersOfAttemptsManager(new LettersOfAttemptsManager(customer ? customer.IdCliente : null));
        }

        if (
            this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled") &&
            this.authorizationsService.isAuthorized("Customers_CanViewTrustsAuthorizationsHistory")
        )
            this.TrustAuthorizationsHistory(
                new TrustAuthorizationsHistory(!this.customer ? 0 : this.customer.IdCliente)
            );

        // Per il default del codiceIVA
        this.IvaModes = this.ivaModesManager.getIvaModes();

        // Per i default di modalità e scadenza pagamento
        this.ActivePaymentTypeManagerUi = this.prolifeSdkService.Ui.GetPaymentTypeManagerUi();
        this.PassivePaymentTypeManagerUi = this.prolifeSdkService.Ui.GetPaymentTypeManagerUi(false);

        this.Expiries(this.expiriesManager.getExpireModes(false));

        if (customer) {
            this.CustomerDiscountsEditor = new CustomerDiscountsEditor(this.customer.IdCliente);
        }

        this.formattedAddressLocation = ko.computed(() => {
            let result = "";
            result += this.CAP() ? this.CAP() + " " : "";
            result += this.Citta() ? this.Citta() : "";
            result += this.Citta() && (this.Comune() || this.Stato()) ? " - " : "";
            result += this.Comune() ? this.Comune() + " " : " ";
            result += this.Provincia() ? "(" + this.Provincia() + ") " : "";
            result += this.Stato() ? this.Stato() : "";
            return result;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const ragioneSocialeInterceptor = ko.computed(() => {
            if (this.isLoading) return;

            const nome = (this.Nome() || "").trim();
            const cognome = (this.Cognome() || "").trim();

            const ragioneSociale = [cognome, nome].join(" ").trim();
            this.RagioneSociale(ragioneSociale);
        });

        this.TagsManager = new ContactTagsManager(customer);

        this.ContactName = ko.computed(() => {
            let peopleName = "";
            peopleName +=
                !this.Qualification() || this.Qualification().trim().length == 0 ? "" : this.Qualification() + " ";
            peopleName += !this.Nome() || this.Nome().trim().length == 0 ? "" : this.Nome() + " ";
            peopleName += !this.Cognome() || this.Cognome().trim().length == 0 ? "" : this.Cognome() + " ";

            const name = this.IsCompany() ? this.RagioneSociale() : peopleName;
            return name.trim();
        });

        this.IsCompany.subscribe((value) => {
            this.RagioneSociale("");
            this.Nome("");
            this.Cognome("");
            this.Qualification("");

            if (!value) this.PublicAdministration(false);
        });

        this.PublicAdministration.subscribe((value: boolean) => {
            if (value) this.IsCompany(true);
        });

        this.electronicInvoicingInterceptor = ko.computed(() => {
            if (this.IsCompany() || this.PublicAdministration() || !!this.PIVA()) this.ElectronicInvoicing(true);
        });

        this.PEC.subscribe((v) => {
            if (this.customerEditor && this.customerEditor.state() == "view") return;

            // eslint-disable-next-line no-useless-escape
            const mailRegExp = new RegExp(
                "^([0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-w]*[0-9a-zA-Z].)+[a-zA-Z]{2,9})$"
            );

            if ((v || "").length == 0 || v.match(mailRegExp)) return;

            this.infoToast.Warning(ProlifeSdk.TextResources.Customers.InvalidPECFormat);
        });

        this.OrganizationalUnitsChangesInterceptor = ko.computed(() => {
            const ous: OrganizationalUnit[] = this.OrganizationalUnits();

            for (let i = 0; i < ous.length; i++) {
                ous[i].Banks.isChanged();
                const banks = ous[i].Banks.AllBanks();
                for (const bank of banks) {
                    bank.Name();
                    bank.IBAN();
                    bank.ABI();
                    bank.CAB();
                }
            }

            ko.ignoreDependencies(() => {
                this.ActivePaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                    ous.map((ou: OrganizationalUnit) => ou.GetData())
                );
                this.PassivePaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                    ous.map((ou: OrganizationalUnit) => ou.GetData())
                );

                this.OrganizationalUnits().forEach((ou: OrganizationalUnit) =>
                    ou.RefreshOrganizationalUnitsForProtocolsDefaultsPaymentOptions(ous)
                );
            });
        });

        this.load();
        this.ConfigureValidations();

        this.IsPrivateContact.subscribe(() => {
            this.IsCliente(false);
            this.IsFornitore(false);
        });

        this.HasDefaultValues = ko.computed(() => {
            return (
                !!this.DefaultIva() ||
                !!this.DefaultPaymentExpiryType() ||
                !!this.ActivePaymentTypeManagerUi.Payment() ||
                !!this.PassivePaymentTypeManagerUi.Payment() ||
                !!this.DefaultOffset() ||
                !!this.DefaultOutcome() ||
                !!this.DefaultCurrency()
            );
        });
    }

    onItemSelected(sender: IDataSource, model: ICustomerGroupDataModel): void {
        if (sender == this.CustomerGroupsDataSource) {
            this.CustomerGroupName(model ? model.title : "");
        } else if (sender === this.AdministrativeResourcesDataSource) {
            this.AdministrativeResponsibleName(model?.title);
        } else if (sender === this.CommercialResourcesDataSource) {
            this.CommercialResponsibleName(model?.title);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender == this.CustomerGroupsDataSource) {
            this.CustomerGroupName("");
        } else if (sender === this.AdministrativeResourcesDataSource) {
            this.AdministrativeResponsibleName(null);
        } else if (sender === this.CommercialResourcesDataSource) {
            this.CommercialResponsibleName(null);
        }
    }

    public disableAutoScrollOnMetadatas() {
        this.autoScroll = false;
    }

    public enableAutoScrollOnMetadatas() {
        this.autoScroll = true;
    }

    public AddPhoneNumber(label: string, phoneNumber: string) {
        const defaultOrganizationalUnit: OrganizationalUnit = this.OrganizationalUnits()[0];
        defaultOrganizationalUnit.AddPhoneNumber(label, phoneNumber, this.autoScroll);
    }

    public AddMail(label: string, mail: string) {
        const defaultOrganizationalUnit: OrganizationalUnit = this.OrganizationalUnits()[0];
        defaultOrganizationalUnit.AddMail(label, mail, this.autoScroll);
    }

    public AddNewRecipientCode(): void {
        this.RecipientCodesList.push(
            new RecipientCodeViewModel(
                {
                    Id: this.getNextFakeId(),
                    CustomerId: this.IdCliente(),
                    RecipientCode: "",
                    Description: "",
                    PEC: "",
                    OrganizationalUnitsIds: [],
                },
                this
            )
        );
    }

    private getNextFakeId(): number {
        return --this.lastFakeId;
    }

    public OnRecipientCodeChanges(recipientCode: IRecipientCodeViewModel): void {
        this.OrganizationalUnits()
            .filter((ou: OrganizationalUnit) => ou.AssignedRecipientCodeId() == recipientCode.Id)
            .forEach((ou: OrganizationalUnit) => {
                ou.AssignedRecipientCodeId(undefined);
                ou.AssignedRecipientCodeId(recipientCode.Id);
            });
    }

    public DeleteRecipientCode(recipientCode: RecipientCodeViewModel): void {
        const recipientIndex: number = this.RecipientCodesList.indexOf(recipientCode);
        if (recipientIndex >= 0) {
            const recipientCodes = this.RecipientCodesList();
            recipientCodes.splice(recipientIndex, 1);

            this.RecipientCodesList(recipientCodes);

            this.OrganizationalUnits().forEach((ou: OrganizationalUnit) => {
                if (ou.AssignedRecipientCodeId() == recipientCode.Id) ou.AssignedRecipientCodeId(undefined);
            });
        }
    }

    public SetEditMode(editable: boolean) {
        this.Editable(editable);

        /*if(this.WarehouseCustomerDiscounts)
            this.WarehouseCustomerDiscounts.SetEditMode(editable);*/
        if (this.CustomerDiscountsEditor) this.CustomerDiscountsEditor.SetEditMode(editable);

        this.rolesPricesManager.setEditMode(editable);

        /*if(this.WarehouseSupplierDiscounts)
            this.WarehouseSupplierDiscounts.SetEditMode(editable);*/

        if (this.TrustManager()) this.TrustManager().SetEditMode(editable);

        this.OrganizationalUnits().forEach((uo) => {
            uo.SetEditMode(editable);
        });
    }

    private ConfigureValidations() {
        this.validator = this.validationService
            .createValidator<CustomerViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(
                (c) => c.RagioneSociale(),
                TextResources.Customers.NoCompanyName,
                () => this.IsCompany()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (c) => c.Nome(),
                TextResources.Customers.NoName,
                () => !this.IsCompany()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (c) => c.Cognome(),
                TextResources.Customers.NoSurname,
                () => !this.IsCompany()
            );
    }

    public AddUo() {
        this.OrganizationalUnits.push(new OrganizationalUnit(false, this));
    }

    public updateCustomer(customer: ICustomer) {
        if (!this.customer || this.customer.IdCliente == customer.IdCliente) {
            this.customer = customer;
            this.load();
        }
    }

    public SelectDefaultUo() {
        const matches = this.OrganizationalUnits().filter((uo: OrganizationalUnit) => {
            return uo.IsDefault;
        });
        const defaultUo =
            matches.length > 0
                ? matches[0]
                : this.OrganizationalUnits().length > 0
                ? this.OrganizationalUnits()[0]
                : null;
        this.SelectedUo(defaultUo);
    }

    public Refresh(c: ICustomer) {
        this.customer = c;
        this.load();
    }

    public load() {
        const defaultUo = new OrganizationalUnit(true, this);
        this.OrganizationalUnits([defaultUo]);

        this.SelectedUo(defaultUo);
        this.CanBeSetAsPrivate(
            !this.customer || this.customer.CreationUserId == this.authenticationService.getUser().UserId
        );

        this.NationsProvider.SelectedNationCode("IT");

        if (this.customer) {
            this.isLoading = true;

            this.isNewCustomer(false);
            this.IsCompany(this.customer.IsCompany);
            this.RagioneSociale(this.customer.RagioneSociale);
            this.PIVA(this.customer.PIVA);
            this.CF(this.customer.CF);
            this.Indirizzo(this.customer.Indirizzo);
            this.CAP(this.customer.CAP);
            this.PEC(this.customer.PEC);
            this.Citta(this.customer.Citta);
            this.Provincia(this.customer.Provincia);
            this.Stato(this.customer.Stato);
            this.Telefono(this.customer.Telefono);
            this.FAX(this.customer.FAX);
            this.EMail(this.customer.EMail);
            this.RifAmministrativo(this.customer.RifAmministrativo);
            this.Sesso(this.customer.Sesso);
            this.Nome(this.customer.Nome);
            this.Cognome(this.customer.Cognome);
            this.SecondoNome(this.customer.SecondoNome);
            this.Eliminato(this.customer.Eliminato);
            this.Comune(this.customer.Comune);
            this.Qualification(this.customer.Qualification);
            this.Notes(this.customer.Notes);
            this.FkBlockCause(this.customer.FkBlockCause);
            this.BlockedBy(this.customer.BlockedBy);

            this.NationsProvider.SelectedNationCode(this.customer.Stato);
            this.ProvincesProvider.SelectedProvinceCode(this.customer.Provincia);

            this.rolesPricesManager.initialize(this.customer);

            this.IsPrivateContact(this.customer.IsPrivateContact);
            this.IsCliente(this.customer.IsCliente);
            this.IsFornitore(this.customer.IsFornitore);

            this.CreationDate(this.customer.CreationDate);
            this.LastModificationDate(this.customer.LastModificationDate);
            this.IdCliente(this.customer.IdCliente);

            const uos = this.customer.OrganizationalUnits.map((uo: IOrganizationalUnit) => {
                return new OrganizationalUnit(uo.IsDefault, this).Load(uo);
            });

            if (uos.length == 0) uos.push(defaultUo);

            this.CommercialResourcesDataSource.setAllowFreeText(true);
            this.CommercialResourcesDataSource.setGetMaterialResources(false);

            this.AdministrativeResourcesDataSource.setAllowFreeText(true);
            this.AdministrativeResourcesDataSource.setGetMaterialResources(false);

            if (!!this.customer.FkCommercialResponsible && this.customer.FkCommercialResponsible > 0) {
                this.FkCommercialResponsible(this.customer.FkCommercialResponsible);
            } else if (this.customer.CommercialResponsibleName) {
                this.FkCommercialResponsible(this.customer.CommercialResponsibleName);
            }

            this.CommercialResponsibleName(this.customer.CommercialResponsibleName);

            if (!!this.customer.FkAdministrativeResponsible && this.customer.FkAdministrativeResponsible > 0) {
                this.FkAdministrativeResponsible(this.customer.FkAdministrativeResponsible);
            } else if (this.customer.AdministrativeResponsibleName) {
                this.FkAdministrativeResponsible(this.customer.AdministrativeResponsibleName);
            }

            this.AdministrativeResponsibleName(this.customer.AdministrativeResponsibleName);

            this.TagsManager.Initialize(this.customer.Tags);

            //this.TrustManagementEnabled(this.customer.TrustManagementEnabled == null ? false : this.customer.TrustManagementEnabled);
            this.TrustManagementEnabled(this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled"));

            if (this.TrustManager()) {
                this.StartDocumentsEmissionDateForTrustMovements(
                    this.customer.StartDocumentsEmissionDateForTrustMovements
                );
                this.TrustManager().Initialize();
            }

            if (this.LettersOfAttemptsManager()) {
                this.LettersOfAttemptsManager().refresh();
            }

            this.customersGroupsService.GetCustomerGroups(this.customer.IdCliente).then((groups: ICustomerGroup[]) => {
                if (groups.length > 0) {
                    this.CustomerGroupId(groups[0].Id);
                    this.CustomerGroupName(groups[0].Name);
                }
            });

            this.OrganizationalUnits(uos);
            this.SelectedUo(uos[0]);

            const ivaModes: IIvaMode[] = this.ivaModesManager
                .getIvaModes(false)
                .filter((i: IIvaMode) => i.IdTipoIVA == this.customer.DefaultIvaMode);
            this.DefaultIva(ivaModes.length > 0 ? ivaModes[0] : null);

            let expiries: IExpireMode[] = this.expiriesManager
                .getExpireModes(false)
                .filter((e: IExpireMode) => e.IdTipoScadenza == this.customer.DefaultPaymentExpiryTypeId);
            this.DefaultPaymentExpiryType(expiries.length == 0 ? null : expiries[0]);
            this.ActivePaymentTypeManagerUi.PaymentId(this.customer.DefaultPaymentTypeId);
            this.ActivePaymentTypeManagerUi.PaymentAccountId(this.customer.DefaultPaymentAccountId);
            this.ActivePaymentTypeManagerUi.PaymentABI(this.customer.DefaultPaymentABI);
            this.ActivePaymentTypeManagerUi.PaymentCAB(this.customer.DefaultPaymentCAB);

            expiries = this.expiriesManager
                .getExpireModes(false)
                .filter((e: IExpireMode) => e.IdTipoScadenza == this.customer.DefaultPassivePaymentExpiryTypeId);
            this.DefaultPassivePaymentExpiryType(expiries.length == 0 ? null : expiries[0]);
            this.PassivePaymentTypeManagerUi.PaymentId(this.customer.DefaultPassivePaymentTypeId);
            this.PassivePaymentTypeManagerUi.PaymentAccountId(this.customer.DefaultPassivePaymentAccountId);
            this.PassivePaymentTypeManagerUi.PaymentABI(this.customer.DefaultPassivePaymentABI);
            this.PassivePaymentTypeManagerUi.PaymentCAB(this.customer.DefaultPassivePaymentCAB);

            this.DefaultOffset(this.customer.DefaultOffset);
            this.DefaultOutcome(this.customer.DefaultOutcome);
            this.DefaultCurrency(this.customer.DefaultCurrency);

            this.PublicAdministration(this.customer.PublicAdministration);
            this.ElectronicInvoicing(this.customer.ElectronicInvoicing);

            this.RecipientCodesList(
                this.customer.RecipientCodesList.map((c: IRecipientCode) => new RecipientCodeViewModel(c, this))
            );
            this.OrganizationalUnits().forEach((ou: OrganizationalUnit) => ou.OnRecipientCodesLoaded());

            this.EORICode(this.customer.EORICode);

            if (this.PaymentExtensionsEditor()) this.PaymentExtensionsEditor().Load();

            this.isLoading = false;
        }

        this.originalData = this.getData();
    }

    public getData(): ICustomer {
        const newCustomer: ICustomer = Object.assign({}, this.customer || {}) as ICustomer;

        let piva = this.PIVA();
        piva = piva ? piva.trim() : null;
        piva = piva == "" ? null : piva;

        let cf = this.CF();
        cf = cf ? cf.trim() : null;
        cf = cf && cf.length == 0 ? null : cf;

        newCustomer.RagioneSociale = this.RagioneSociale();
        newCustomer.PIVA = piva;
        newCustomer.CF = cf;
        newCustomer.Indirizzo = this.Indirizzo();
        newCustomer.CAP = this.CAP();
        newCustomer.Citta = this.Citta();
        newCustomer.Provincia = this.ProvincesProvider.SelectedProvinceCode();
        newCustomer.Stato = this.NationsProvider.SelectedNationCode();
        newCustomer.Telefono = this.Telefono();
        newCustomer.FAX = this.FAX();
        newCustomer.EMail = this.EMail();
        newCustomer.RifAmministrativo = this.RifAmministrativo();
        newCustomer.Sesso = this.Sesso();
        newCustomer.Nome = this.Nome();
        newCustomer.Cognome = this.Cognome();
        newCustomer.SecondoNome = this.SecondoNome();
        newCustomer.Eliminato = this.Eliminato();
        newCustomer.Comune = this.Comune();
        newCustomer.IsCliente = this.IsCliente();
        newCustomer.IsFornitore = this.IsFornitore();
        newCustomer.Qualification = this.Qualification();
        newCustomer.Notes = this.Notes();
        newCustomer.IsCompany = this.IsCompany();
        newCustomer.PEC = this.PEC();
        newCustomer.IsPrivateContact = this.IsPrivateContact();
        newCustomer.OrganizationalUnits = this.OrganizationalUnits().map((uo: OrganizationalUnit) => {
            return uo.GetData();
        });

        newCustomer.FkCommercialResponsible =
            typeof this.FkCommercialResponsible() === "string" ? null : (this.FkCommercialResponsible() as number);
        newCustomer.CommercialResponsibleName = this.CommercialResponsibleName();

        newCustomer.FkAdministrativeResponsible =
            typeof this.FkAdministrativeResponsible() === "string"
                ? null
                : (this.FkAdministrativeResponsible() as number);
        newCustomer.AdministrativeResponsibleName = this.AdministrativeResponsibleName();

        newCustomer.Tags = this.TagsManager.GetData();

        newCustomer.TrustManagementEnabled = this.TrustManagementEnabled();
        if (this.TrustManager())
            newCustomer.StartDocumentsEmissionDateForTrustMovements =
                this.TrustManager().StartDocumentsEmissionDateForTrustMovements();
        newCustomer.DefaultIvaMode = this.DefaultIva() ? this.DefaultIva().IdTipoIVA : null;

        newCustomer.DefaultPaymentTypeId = this.ActivePaymentTypeManagerUi.PaymentId();
        newCustomer.DefaultPaymentAccountId = this.ActivePaymentTypeManagerUi.PaymentAccount()
            ? this.ActivePaymentTypeManagerUi.PaymentAccount().Id
            : null;
        newCustomer.DefaultPaymentABI = this.ActivePaymentTypeManagerUi.PaymentABI();
        newCustomer.DefaultPaymentCAB = this.ActivePaymentTypeManagerUi.PaymentCAB();
        newCustomer.DefaultPaymentExpiryTypeId = this.DefaultPaymentExpiryType()
            ? this.DefaultPaymentExpiryType().IdTipoScadenza
            : null;
        newCustomer.DefaultPaymentExpiryType = this.DefaultPaymentExpiryType()
            ? this.DefaultPaymentExpiryType().Descrizione
            : null;

        newCustomer.DefaultPassivePaymentTypeId = this.PassivePaymentTypeManagerUi.PaymentId();
        newCustomer.DefaultPassivePaymentAccountId = this.PassivePaymentTypeManagerUi.PaymentAccount()
            ? this.PassivePaymentTypeManagerUi.PaymentAccount().Id
            : null;
        newCustomer.DefaultPassivePaymentABI = this.PassivePaymentTypeManagerUi.PaymentABI();
        newCustomer.DefaultPassivePaymentCAB = this.PassivePaymentTypeManagerUi.PaymentCAB();
        newCustomer.DefaultPassivePaymentExpiryTypeId = this.DefaultPassivePaymentExpiryType()
            ? this.DefaultPassivePaymentExpiryType().IdTipoScadenza
            : null;
        newCustomer.DefaultPassivePaymentExpiryType = this.DefaultPassivePaymentExpiryType()
            ? this.DefaultPassivePaymentExpiryType().Descrizione
            : null;

        newCustomer.DefaultOffset = this.DefaultOffset();
        newCustomer.DefaultOutcome = this.DefaultOutcome();
        newCustomer.DefaultCurrency = this.DefaultCurrency();

        newCustomer.PublicAdministration = this.PublicAdministration();
        newCustomer.ElectronicInvoicing = this.ElectronicInvoicing();
        newCustomer.RecipientCodesList = this.RecipientCodesList()
            .filter((c) => c.RecipientCode())
            .map((c: IRecipientCodeViewModel) => c.GetData());
        newCustomer.EORICode = this.EORICode();

        this.rolesPricesManager.setDataOnCustomer(newCustomer);

        return newCustomer;
    }

    public isChanged(): boolean {
        return (
            !Object.identical(this.getData(), this.originalData) ||
            (this.TrustManager() && this.TrustManager().HasChanges()) ||
            (this.LettersOfAttemptsManager() && this.LettersOfAttemptsManager().hasChanges()) ||
            (this.PaymentExtensionsEditor() && this.PaymentExtensionsEditor().HasChanges()) ||
            (this.CustomerAliasesEditor() && this.CustomerAliasesEditor().HasChanges()) ||
            !!this.OrganizationalUnits().firstOrDefault((ou) => ou.HasChanges())
        );
    }

    public getCustomerId(): number {
        return this.customer.IdCliente || 0;
    }

    public getValidation(): IValidation[] {
        let result = this.validator.validate(this);

        for (const rcl of this.RecipientCodesList()) result.push(...rcl.GetValidation().validate(rcl));

        if (this.PaymentExtensionsEditor()) {
            const paymentExtenstionsError = this.PaymentExtensionsEditor().Validate();
            if (!paymentExtenstionsError.valid) result.push(paymentExtenstionsError);
        }

        const ous = this.OrganizationalUnits();
        for (const ou of ous) {
            const validation = ou.Validate().filter((v) => !v.valid);
            result = result.concat(validation);
        }

        return result;
    }

    public getValidationWarnings(): Promise<string[]> {
        const def = new Deferred<string[]>();

        let alerts: string[] = [];

        if (this.PIVA() == null || this.PIVA().length == 0) alerts.push(ProlifeSdk.TextResources.Customers.NoVAT);
        else if (!Validations.isPartitaIva(this.PIVA())) {
            alerts.push(ProlifeSdk.TextResources.Customers.InvalidVAT);
        }

        if (this.CF() == null || this.CF().length == 0) alerts.push(ProlifeSdk.TextResources.Customers.NoSSN);
        else if (!Validations.isCodiceFiscale(this.CF()) && !Validations.isCodiceFiscaleAziende(this.CF())) {
            alerts.push(ProlifeSdk.TextResources.Customers.InvalidSSN);
        }

        if (
            this.Indirizzo() == null ||
            this.Indirizzo().length == 0 ||
            this.Citta() == null ||
            this.Citta().length == 0 ||
            this.CAP() == null ||
            this.CAP().length == 0 ||
            this.Provincia() == null ||
            this.Provincia().length == 0
        )
            alerts.push(ProlifeSdk.TextResources.Customers.InvalidAddress);

        if (this.ElectronicInvoicing() && this.RecipientCodesList().length == 0)
            alerts.push(ProlifeSdk.TextResources.Customers.RecipientCodeWarning);

        if (this.LettersOfAttemptsManager()) {
            alerts = alerts.concat(this.LettersOfAttemptsManager().getValidationWarnings());
        }

        if (this.RecipientCodesList().length > 0) {
            const allRecipientCodes = this.RecipientCodesList().map((r) => r.RecipientCode());
            let duplicateFound = false;
            let lastCode = null;

            for (const code of allRecipientCodes) {
                if (code == lastCode) {
                    duplicateFound = true;
                    break;
                }

                lastCode = code;
            }

            if (duplicateFound) {
                return def.reject(ProlifeSdk.TextResources.Customers.DuplicateRecipientCodeError).promise();
            }
        }

        if (!this.TrustManager()) return def.resolve(alerts).promise();

        if (!this.TrustManager().ShowAlertIfActualTrustNotConfigured()) return def.resolve(alerts).promise();

        if (!this.TrustManagementEnabled()) {
            alerts.push(ProlifeSdk.TextResources.Customers.TrustManagementDisabled);
            return def.resolve(alerts).promise();
        }

        this.TrustManager()
            .GetValidationWarnings()
            .then((warnings: string[]) => {
                alerts = alerts.concat(warnings);
                def.resolve(alerts);
            });

        return def.promise();
    }

    public VerifyVat() {
        if (!this.PIVA()) {
            this.infoToast.Warning(ProlifeSdk.TextResources.Customers.NoVAT);
            return;
        }

        let piva = this.PIVA();
        let countryCode = "IT";
        if (piva.charAt(0).match(/[a-z]/i) && piva.charAt(1).match(/[a-z]/i)) {
            countryCode = piva.substr(0, 2);
            piva = piva.substr(2);
        }

        this.invoiceService
            .VerifyVat(countryCode, piva)
            .then((result) => {
                this.IsCompany(true);
                this.RagioneSociale(result.Name);
                this.Indirizzo(result.Address);
            })
            .catch((exception: IException) => {
                if (exception.ExceptionMessage == "SERVICE_UNAVAILABLE")
                    this.infoToast.Error(ProlifeSdk.TextResources.Invoices.VatServiceUnavailable);
                else if (exception.ExceptionMessage == "javax.xml.rpc.soap.SOAPFaultException: IP_BLOCKED")
                    this.infoToast.Warning(ProlifeSdk.TextResources.Invoices.VatServicePleaseRetry);
            });
    }

    GetRecipientCodeById(id: number): IRecipientCodeViewModel {
        const matches = this.RecipientCodesList().filter((c: IRecipientCodeViewModel) => c.Id == id);
        return matches.length > 0 ? matches[0] : undefined;
    }

    GetFormattedCodeForList(data: any): string {
        return (
            '<div style="display: inline-block;"><div><strong>' +
            data.text +
            '</strong></div><div style="margin: 3px 0px">' +
            data.pec +
            "</div><div>" +
            data.description +
            "</div></div>"
        );
    }

    GetFormattedCodeForSelection(data: any): string {
        return "<span>" + data.text + "</span>";
    }

    Search(query: ISelect2Query) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.internalSearch(query);
        }, 500);
    }

    Init(element, callback) {
        const recipintCodeId: number = !$(element).val() ? 0 : parseInt(<string>$(element).val());
        if (recipintCodeId) {
            const recipientCodeMatches = this.RecipientCodesList().filter(
                (c: IRecipientCodeViewModel) => c.Id == recipintCodeId
            );
            if (!recipientCodeMatches.length) {
                callback(null);
                return;
            }

            callback({
                id: recipientCodeMatches[0].Id,
                text: recipientCodeMatches[0].RecipientCode(),
                pec: recipientCodeMatches[0].PEC(),
                description: recipientCodeMatches[0].Description(),
            });
        }
    }

    EscapeMarkup(markup) {
        return markup;
    }

    private internalSearch(query: ISelect2Query): void {
        const matches = !query.term
            ? this.RecipientCodesList()
            : this.RecipientCodesList().filter(
                  (c: IRecipientCodeViewModel) =>
                      (c.RecipientCode() || "").toLowerCase().indexOf(query.term.toLowerCase()) > -1 ||
                      (c.PEC() || "").toLowerCase().indexOf(query.term.toLowerCase()) > -1 ||
                      (c.Description() || "").toLowerCase().indexOf(query.term.toLowerCase()) > -1
              );

        if (query.term) {
            for (let i = 0; i < matches.length; i++) {
                if (matches[i].RecipientCode().toLowerCase().indexOf(query.term.toLowerCase()) > -1) {
                    const elem = matches.splice(i, 1);
                    matches.unshift(elem[0]);
                    i++;
                }
            }
        }

        query.callback({
            results: matches.map((c: IRecipientCodeViewModel) => {
                const obj: IRecipientCode = c.GetData();
                return { id: obj.Id, text: obj.RecipientCode, pec: obj.PEC, description: obj.Description };
            }),
        });
    }
}
