import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 02/07/13
 * Time: 18.08
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IFileRepositoryService } from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileRepositorySearchCriteria, ISelect2FileRepositorySearchCriteria } from "../FileRepository/interfaces/IFileRepositorySearchCriteria";
import { ITagHint } from "../ProlifeSdk/interfaces/files/ITagHint";

export class FileSearchHint {
    public fileRepository : IFileRepositoryService;
    public lastTimeout : ReturnType<typeof setTimeout>;

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) {
        var instance = {
            fileRepository: <IFileRepositoryService> Core.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        };

        (<any>$(element)).typeahead({
            items: 10,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source: FileSearchHint.getSearchHints.bind(null, instance),
            limit: 100,
            display: (json) => {
                var item : IFileRepositorySearchCriteria = <IFileRepositorySearchCriteria> JSON.parse(json);
                return item.Value;
            },
            templates: {
                suggestion: function(json) {
                    var item : IFileRepositorySearchCriteria = <IFileRepositorySearchCriteria> JSON.parse(json);
                    if(item.Color !== undefined) {
                        return '<div><label>' + item.Label + '</label><span><span class="fa fa-tag tag c' + item.Color + '"></span>' + item.Value + '</span></div>';
                    } else {
                        return '<div><label>' + item.Label + '</label><span>' + item.Value + '</span></div>';
                    }
                }
            }
        }).on("typeahead:selected", (event, json) => {
            var item : IFileRepositorySearchCriteria = <IFileRepositorySearchCriteria> JSON.parse(json);
            valueAccessor().call(viewModel, item);
            return "";
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var otherBindings = allBindingsAccessor();

        if(!newValue) {
            !otherBindings.value && (<any>$(element)).typeahead("val", "");
            return;
        }
    }

    private static getSearchHints(_this : FileSearchHint, query : string, process : (items : any[]) => any, asyncProcess : (items : any[]) => any) {
        var items = [];
        items.push(FileSearchHint.createSearchItem("name", ProlifeSdk.TextResources.FileRepository.FileNameContains, query));
        items.push(FileSearchHint.createSearchItem("contents", ProlifeSdk.TextResources.FileRepository.FileContains, query));
        items.push(FileSearchHint.createSearchItem("extension", ProlifeSdk.TextResources.FileRepository.Extension, query));
        items.push(FileSearchHint.createSearchItem("contentType", ProlifeSdk.TextResources.FileRepository.Type, "image"));
        items.push(FileSearchHint.createSearchItem("contentType", ProlifeSdk.TextResources.FileRepository.Type, "application"));
        items.push(FileSearchHint.createSearchItem("shared", ProlifeSdk.TextResources.FileRepository.Shared, ProlifeSdk.TextResources.FileRepository.AtLeastOneShare));

        if(moment(query, "DD/MM/YYYY").isValid()) {
            items.push(FileSearchHint.createSearchItem("fromDate", ProlifeSdk.TextResources.FileRepository.ModifiedSince, query));
            items.push(FileSearchHint.createSearchItem("toDate", ProlifeSdk.TextResources.FileRepository.ModifiedWithin, query));
        }

        //asyncProcess(items);

        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(() => {
            _this.fileRepository.getTagHints(query)
                .then((tags : ITagHint[]) => {
                    if(tags.length != 0) {
                        items.push.apply(items, tags.map(FileSearchHint.createTagSearchItem));
                    }

                    asyncProcess(items);
                });
        }, 500);
    }

    private static createTagSearchItem(tag : ITagHint) : ISelect2FileRepositorySearchCriteria {
        return {
            id: 'tag=' + tag.LabelId,
            TagId: tag.LabelId,
            Color: tag.Color,
            Type: 'tag',
            Label: ProlifeSdk.TextResources.FileRepository.Tag,
            Value: tag.Name,
            Display: ProlifeSdk.TextResources.FileRepository.Tag + ": " + tag.Name,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.Display.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.Display, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.Display, arguments);
            }
        }
    }

    private static createSearchItem(type : string, typeLabel : string, value : string) : ISelect2FileRepositorySearchCriteria {
        return {
            id: type + "=" + value,
            Type: type,
            Label: typeLabel,
            Value: value,
            Display: typeLabel + ": " + value,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.Display.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.Display, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.Display, arguments);
            }
        }
    }
}

ko.bindingHandlers['fileSearchHint'] = new FileSearchHint();