import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IJobOrderTypeResponsible, IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { ProxyDataSource } from "../../../../DataSources/ProxyDataSource";
import { ITemplateDataSourceModel, TemplatesDataSource } from "../../../../DataSources/TemplatesDataSource";
import { JobOrderResponsiblesPanel, _JobOrderResponsiblesPanel } from "../../ui/JobOrderResponsiblesPanel";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { JobOrderResponsibleType } from "../../enums/JobOrderResponsibleType";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { TextInput } from "../../../../Components/TextInput";
import { CheckBox } from "../../../../Components/Checkbox";
import { SelectMultiple } from "../../../../Components/SelectMultiple";
import { ColorInput } from "../../../../Components/ColorInput";
import { IconInput } from "../../../../Components/IconInput";
import { IconPreview } from "../../../../Components/IconPreview";

const styleSheet = jss.createStyleSheet({
    jobOrderType: {
        "& > .row": {
            marginRight: 0,
        },

        "& .toggler": {
            padding: "5px 7px",
        },
    },
});
const { classes } = styleSheet.attach();

type JobOrderTypeProps = {
    templatesDataSource: TemplatesDataSource;
    jobOrderType: IJobOrderType;

    onTypeDeleted?: (jobOrderType: IJobOrderType) => void;

    forwardRef?: (jobOrderType: _JobOrderType) => void;
};

export interface IJobOrderTypesProvider {
    Id: number;
    Description: ko.Observable<string>;

    getData(): IJobOrderType;
    update(jobOrderType: IJobOrderType): void;
}

export function JobOrderType(props: JobOrderTypeProps) {
    const C = require("./JobOrderType")._JobOrderType as typeof _JobOrderType;
    return <C {...props} />;
}

export class _JobOrderType implements IJobOrderTypesProvider {
    static defaultProps: Partial<JobOrderTypeProps> = {};

    get Id(): number {
        return this.props.jobOrderType?.Id;
    }

    get IsLocked(): boolean {
        return this.props.jobOrderType?.IsLocked;
    }

    Description: ko.Observable<string> = ko.observable();
    Icon: ko.Observable<string> = ko.observable();
    Background: ko.Observable<string> = ko.observable();
    Foreground: ko.Observable<string> = ko.observable();
    Index: ko.Observable<number> = ko.observable();
    HideFromLists: ko.Observable<boolean> = ko.observable(false);
    Templates: ko.ObservableArray<number> = ko.observableArray([]);
    TypeCode: ko.Observable<string> = ko.observable();

    DefaultResponsibleId: ko.Observable<number> = ko.observable();
    DefaultAdministrativeResponsibleId: ko.Observable<number | string> = ko.observable();
    DefaultCommercialResponsibleId: ko.Observable<number | string> = ko.observable();
    AuthorizedResources: ko.ObservableArray<string> = ko.observableArray();

    Collapsed: ko.Observable<boolean> = ko.observable(true);
    hasFocus: ko.Observable<boolean> = ko.observable(false);

    public TemplatesDataSource: ProxyDataSource<TemplatesDataSource, ITemplateDataSourceModel>;

    private currentDescription: string;

    private updating: boolean = false;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesManager: IJobOrderTypesSettingsManager;

    constructor(private props: JobOrderTypeProps) {
        this.TemplatesDataSource = new ProxyDataSource(this.props.templatesDataSource);

        if (!this.props.jobOrderType.Id) {
            this.update(this.props.jobOrderType);
            this.createOrUpdateEntry();
            this.Collapsed(false);
        } else this.update(this.props.jobOrderType);

        this.Description.subscribe(this.onNameChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
        this.Templates.subscribe(this.onDataChanged.bind(this));
        this.HideFromLists.subscribe(this.onDataChanged.bind(this));
        this.TypeCode.subscribe(this.onDataChanged.bind(this));
        this.DefaultResponsibleId.subscribe(this.onDataChanged.bind(this));
        this.DefaultAdministrativeResponsibleId.subscribe(this.onDataChanged.bind(this));
        this.DefaultCommercialResponsibleId.subscribe(this.onDataChanged.bind(this));
        this.AuthorizedResources.subscribe(this.onDataChanged.bind(this));

        this.props.forwardRef && this.props.forwardRef(this);
    }

    toggleCollapsedState(): void {
        this.Collapsed(!this.Collapsed());
    }

    private onNameChanged(newValue: string) {
        if (this.currentDescription == newValue || this.updating) return;

        if (!newValue) {
            if (this.props.jobOrderType.IsLocked) {
                this.infoToastService.Warning(TextResources.JobOrder.InvalidDefaultOrderName);
                this.Description(this.currentDescription);
                return;
            }

            this.deleteEntry();
        } else {
            this.createOrUpdateEntry();
        }
    }

    private onDataChanged() {
        this.createOrUpdateEntry();
    }

    public async createOrUpdateEntry(): Promise<void> {
        if (this.updating) return;

        this.updating = true;
        let jobOrderType = this.getData();

        try {
            let updated = await this.jobOrderTypesManager.createOrUpdate(jobOrderType);
            this.update(updated);
        } catch (e) {
            console.error(e);
        } finally {
            this.updating = false;
        }
    }

    public async deleteEntry(): Promise<void> {
        let jobOrderType = this.getData();
        try {
            await this.jobOrderTypesManager.remove(jobOrderType.Id);
            this.props.onTypeDeleted && this.props.onTypeDeleted(jobOrderType);
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.UnableToRemoveType);
        }
    }

    getData(): IJobOrderType {
        let jobOrderType: IJobOrderType = Object.assign({}, this.props.jobOrderType) as IJobOrderType;

        jobOrderType.Description = this.Description();
        jobOrderType.Icon = this.Icon();
        jobOrderType.Background = this.Background();
        jobOrderType.Foreground = this.Foreground();
        jobOrderType.DefaultTemplates = this.Templates().map((t) => ({
            Id: this.props.jobOrderType.Id,
            TemplateId: t,
        }));
        jobOrderType.Index = this.Index();
        jobOrderType.HideFromLists = this.HideFromLists();
        jobOrderType.TypeCode = this.TypeCode();
        jobOrderType.DefaultResponsiblesAndAuthorizedResources = this.createDefaultResponsiblesAndAuthorizedResources();

        return jobOrderType;
    }

    private createDefaultResponsiblesAndAuthorizedResources(): IJobOrderTypeResponsible[] {
        let result = [];

        if (this.DefaultResponsibleId())
            result.push(
                this.createJobOrderResponsible(
                    this.DefaultResponsibleId(),
                    null,
                    "",
                    JobOrderResponsibleType.Responsible
                )
            );

        const administrativeResponsibleIdIsNumber = typeof this.DefaultAdministrativeResponsibleId() === "number";
        const administrativeResponsibleId = administrativeResponsibleIdIsNumber
            ? (this.DefaultAdministrativeResponsibleId() as number)
            : null;
        const administrativeResponsibleName = administrativeResponsibleIdIsNumber
            ? null
            : (this.DefaultAdministrativeResponsibleId() as string);

        const commercialResponsibleIdIsNumber = typeof this.DefaultCommercialResponsibleId() === "number";
        const commercialResponsibleId = commercialResponsibleIdIsNumber
            ? (this.DefaultCommercialResponsibleId() as number)
            : null;
        const commercialResponsibleName = commercialResponsibleIdIsNumber
            ? null
            : (this.DefaultCommercialResponsibleId() as string);

        if (administrativeResponsibleId || administrativeResponsibleName)
            result.push(
                this.createJobOrderResponsible(
                    administrativeResponsibleId,
                    administrativeResponsibleName,
                    "",
                    JobOrderResponsibleType.AdministrativeResponsible
                )
            );

        if (commercialResponsibleId || commercialResponsibleName)
            result.push(
                this.createJobOrderResponsible(
                    commercialResponsibleId,
                    commercialResponsibleName,
                    "",
                    JobOrderResponsibleType.CommercialResponsible
                )
            );

        const authoriuzedResources = this.AuthorizedResources();
        for (const res of authoriuzedResources) {
            const [resourceId, resourceType] = res.split("|");
            result.push(
                this.createJobOrderResponsible(
                    parseInt(resourceId),
                    null,
                    resourceType,
                    JobOrderResponsibleType.AuthorizedResource
                )
            );
        }

        return result;
    }

    private createJobOrderResponsible(
        responsibleId: number,
        responsibleName: string,
        resourceType: string,
        responsibleType: JobOrderResponsibleType
    ): IJobOrderTypeResponsible {
        return {
            Id: null,
            JobOrderTypeId: this.props.jobOrderType?.Id,
            ResourceOrResourcesGroupId: responsibleId,
            ResourceOrResourcesGroupName: responsibleName,
            ResourceType: resourceType,
            ResponsibleType: responsibleType,
        };
    }

    update(jobOrderType: IJobOrderType): void {
        this.props.jobOrderType.Id = jobOrderType.Id;

        this.Description(jobOrderType.Description);
        this.Icon(jobOrderType.Icon);
        this.Background(jobOrderType.Background);
        this.Foreground(jobOrderType.Foreground);
        this.Templates(jobOrderType.DefaultTemplates.map((dt) => dt.TemplateId));
        this.Index(jobOrderType.Index);
        this.HideFromLists(jobOrderType.HideFromLists);
        this.TypeCode(jobOrderType.TypeCode);

        let defaultResposible = jobOrderType.DefaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.Responsible
        );
        if (defaultResposible) this.DefaultResponsibleId(defaultResposible.ResourceOrResourcesGroupId);

        let defaultAdministrativeResposible = jobOrderType.DefaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.AdministrativeResponsible
        );
        if (defaultAdministrativeResposible)
            this.DefaultAdministrativeResponsibleId(
                defaultAdministrativeResposible.ResourceOrResourcesGroupId ??
                    defaultAdministrativeResposible.ResourceOrResourcesGroupName
            );

        let defaultCommercialResposible = jobOrderType.DefaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.CommercialResponsible
        );
        if (defaultCommercialResposible)
            this.DefaultCommercialResponsibleId(
                defaultCommercialResposible.ResourceOrResourcesGroupId ??
                    defaultCommercialResposible.ResourceOrResourcesGroupName
            );

        let authorizedResources = jobOrderType.DefaultResponsiblesAndAuthorizedResources.filter(
            (r) => r.ResponsibleType === JobOrderResponsibleType.AuthorizedResource
        );
        let authorizedResourcesIds = [];
        for (const res of authorizedResources)
            authorizedResourcesIds.push(res.ResourceOrResourcesGroupId + "|" + res.ResourceType);

        this.AuthorizedResources(authorizedResourcesIds);

        this.currentDescription = jobOrderType.Description;
    }

    render() {
        const jobOrderType = this;

        return ComponentUtils.bindTo(
            <div className={classes.jobOrderType}>
                <div className="row">
                    <div className="col-md-6">
                        <label>{TextResources.JobOrder.JobOrderTypeLabel}</label>
                        {this.IsLocked && (
                            <div className="input-group">
                                <TextInput value={this.Description} simple />
                                <span className="input-group-addon">
                                    <i className="fa fa-lock"></i>
                                </span>
                            </div>
                        )}
                        {!this.IsLocked && <TextInput value={this.Description} />}
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-2">
                                <IconInput icon={this.Icon} label={TextResources.JobOrder.JobOrderTypeIconLabel} />
                            </div>
                            <div className="col-md-2">
                                <ColorInput
                                    value={this.Background}
                                    label={TextResources.JobOrder.JobOrderTypeIconBackgroundLabel}
                                />
                            </div>
                            <div className="col-md-2">
                                <ColorInput
                                    value={this.Foreground}
                                    label={TextResources.JobOrder.JobOrderTypeIconForegroundLabel}
                                />
                            </div>
                            <div className="col-md-6">
                                <IconPreview
                                    label={TextResources.JobOrder.JobOrderTypeIconPreviewLabel}
                                    icon={this.Icon}
                                    background={this.Background}
                                    foreground={this.Foreground}
                                />
                                <button
                                    type="button"
                                    className="btn btn-circle btn-default toggler pull-right"
                                    data-bind={{ click: jobOrderType.toggleCollapsedState.bind(jobOrderType) }}>
                                    <i
                                        className="fa"
                                        data-bind={{
                                            css: {
                                                "fa-chevron-down": jobOrderType.Collapsed,
                                                "fa-chevron-up": !jobOrderType.Collapsed(),
                                            },
                                        }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" data-bind={{ visible: !jobOrderType.Collapsed() }}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <SelectMultiple.WithLabel
                                    value={this.Templates}
                                    dataSource={this.TemplatesDataSource}
                                    label={TextResources.JobOrder.JobOrderTypePredefinedWorkflowsLabel}
                                    placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                    minimumInputLength={1}
                                    allowClear
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {TextResources.JobOrder.JobOrderTypeCodeLabel}&nbsp;
                                                <i
                                                    className="fa fa-info-circle"
                                                    title={TextResources.JobOrder.JobOrderTypeCodeTooltip}></i>
                                            </label>
                                            <TextInput
                                                data-bind={{ value: this.TypeCode }}
                                                placeholder={TextResources.JobOrder.JobOrderTypeCodePlaceholder}
                                                maxLength={255}
                                                simple
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ paddingTop: "31px" }}>
                                        <CheckBox
                                            checked={this.HideFromLists}
                                            label={TextResources.JobOrder.JobOrderTypeHideFromListsLabel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="bold">{TextResources.JobOrder.JobOrderTypeDefaultResponsibles}</label>
                                <JobOrderResponsiblesPanel
                                    responsableUserId={this.DefaultResponsibleId}
                                    administrativeResponsibleId={this.DefaultAdministrativeResponsibleId}
                                    commercialResponsibleId={this.DefaultCommercialResponsibleId}
                                    authorizedResources={this.AuthorizedResources}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "jobOrderType"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderType);
}
