import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { ReportSection } from "../Components/ReportSection";
import { _ReportDesigner } from "../ReportDesigner";
import TsxForEach from "../../ForEach";
import { ReportPageRenderer } from "./ReportPageRenderer";
import { ReportComponent, ReportPage } from "../Components";

const styleSheet = jss.createStyleSheet({
    reportDesignerSection: {

    }
});
const { classes } = styleSheet.attach();

type ReportSectionRendererProps = {
    section: ReportSection;
    reportDesigner: _ReportDesigner;
    onEdit: (component: ReportComponent, page: ReportPage) => void;
}

export function ReportSectionRenderer(props: ReportSectionRendererProps) {
    const C = require("./ReportSectionRenderer")._ReportSectionRenderer as typeof _ReportSectionRenderer;
    return <C {...props} />;
}

export class _ReportSectionRenderer {
    static defaultProps: Partial<ReportSectionRendererProps> = {
    }

    constructor(private props : ReportSectionRendererProps) {
        
    }

    editing : ko.Observable<boolean> = ko.observable(false);
    
    render() {
        let s = this;

        return  <TsxForEach data={this.props.section.children} as="page">
                    {(page: ReportPage) => <ReportPageRenderer page={page} editingSection={this.editing} section={this.props.section} reportDesigner={this.props.reportDesigner} onEdit={(c) => this.props.onEdit(c, page)} />}
                </TsxForEach>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportSectionRenderer);
}