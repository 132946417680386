import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { If } from "../../../Components/IfIfNotWith";

const styleSheet = jss.createStyleSheet({
    electronicInvoiceTag: {
        position: "absolute",
        top: "-30px",

        "& .tag": {
            fontSize: "13px",
        },
    },
});

const { classes } = styleSheet.attach();

type ElectronicInvoiceTagProps = {
    electronicInvoice: ko.Observable<boolean>;
    includeAttachmentsAndDocumentInElectronicInvoiceExport: ko.Observable<boolean>;
};

export function ElectronicInvoiceTag(props: ElectronicInvoiceTagProps) {
    const C = require("./ElectronicInvoiceTag")._ElectronicInvoiceTag as typeof _ElectronicInvoiceTag;
    return <C {...props} />;
}

export class _ElectronicInvoiceTag {
    static defaultProps: Partial<ElectronicInvoiceTagProps> = {};

    constructor(private props: ElectronicInvoiceTagProps) {}

    render() {
        const { electronicInvoice, includeAttachmentsAndDocumentInElectronicInvoiceExport } = this.props;

        return (
            <If condition={electronicInvoice}>
                {() => (
                    <div className={classes.electronicInvoiceTag}>
                        <If condition={includeAttachmentsAndDocumentInElectronicInvoiceExport}>
                            {() => (
                                <span>
                                    <i className="fa fa-paperclip"></i>
                                </span>
                            )}
                        </If>
                        <span className="tag bold">{TextResources.Invoices.DocumentElectronicInvoice}</span>
                    </div>
                )}
            </If>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ElectronicInvoiceTag);
}
