import * as ko from "knockout";
export class FileDrop {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext)
    {
        var lastTimeout : ReturnType<typeof setTimeout>;

        const self = {
            dropDisabled: (allBindingsAccessor.get("dropDisabled") as ko.Subscribable) ?? ko.observable(false)
        };

        const ignoreEventsFrom = (allBindingsAccessor.get("ignoreEventsFrom") as string[]) ?? [];

        $(element).addClass("drop-area");
        $(element)
            .on("drop", function (evt : any) {
                evt.stopPropagation();
                evt.preventDefault();

                if (self.dropDisabled() || FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                    return;

                $(element).removeClass("file-drop-activated");

                var dataTransfer = evt.dataTransfer || evt.originalEvent.dataTransfer;
                var files = dataTransfer.files; // FileList object.

                var uploadFunction = ko.utils.unwrapObservable(valueAccessor());
                for (var i = 0, f; f = files[i]; i++) {
                    uploadFunction.call(viewModel, f);
                }

                if (allBindingsAccessor().fileDropNoFiles && files.length == 0) {
                    allBindingsAccessor().fileDropNoFiles.call(viewModel);
                }
            })
            .on("dragover", function (evt : any) {
                if (self.dropDisabled() || FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                    return;

                if (lastTimeout)
                    clearTimeout(lastTimeout);

                evt.stopPropagation();
                evt.preventDefault();

                var dataTransfer = evt.dataTransfer || evt.originalEvent.dataTransfer;
                dataTransfer.dropEffect = 'copy';
            })
            .on("dragenter", function (evt) {
                if (self.dropDisabled() || FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                    return;

                if (lastTimeout)
                    clearTimeout(lastTimeout);

                let jqTarget = $(evt.target);
                if (jqTarget.hasClass("drop-area")) {
                    $(element).addClass("file-drop-activated");
                } else {
                    let parents = jqTarget.parents(".drop-area");
                    parents.each((index, node) => { index === 0 ? $(node).addClass("file-drop-activated") : $(node).removeClass("file-drop-activated"); });
                }
            })
            .on("dragleave", function (evt) {
                if (self.dropDisabled() || FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                    return;

                if (lastTimeout)
                    clearTimeout(lastTimeout);

                lastTimeout = setTimeout(function() {
                    $(element).removeClass("file-drop-activated");
                }, 100);
            });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            self.dropDisabled = undefined;
        });
    }
    
    static mustIgnoreEventsFrom(ignoreDropsFrom: string[], target: HTMLElement): boolean {
        if (ignoreDropsFrom.length === 0)
            return false;

        const jqTarget = $(target);
        for (const className of ignoreDropsFrom) {
            if (jqTarget.hasClass(className) || jqTarget.parents(className).length > 0)
                return true;
        }



        return false;
    }
}

ko.bindingHandlers['fileDrop'] = new FileDrop();