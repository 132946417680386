import * as ko from "knockout";
import { CallRightRefConverter } from "./CallRightRefConverter";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider, IDocumentRowInlineReferenceProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { ProlifeSdkService } from "../../../../services";
import { IEntityForRef } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";

export class CallRightRefConverterFactory implements IDocumentRowInlineReferenceProviderFactory
{
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsManager : ICallRightTypesSettingsManager;

    public EntityType : string  = ProlifeSdk.EstimatedCallRightTypeCode;
    public Icon : string = "fa fa-phone";
    public ActivationCode : string = "@03";
    public Description : string = ProlifeSdk.TextResources.WorkedHours.CallRights;
    public DefaultActivation : boolean = false;
    public SupportedDocTypes : string[] = [
        ProlifeSdk.EstimateEntityTypeCode,
        ProlifeSdk.CustomerOrderEntityTypeCode,
        ProlifeSdk.InvoiceEntityTypeCode,
        ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
        ProlifeSdk.SalEntityTypeCode,
        ProlifeSdk.WarehouseLoadEntityTypeCode
    ];

    constructor()
    {
        
    }

    public findEntities(query : string, skip : number, count : number, docInfo : IDocumentInfoForInlineRefProvider) : Promise<IEntityForRef[]>
    {
        var entities = this.callRightsManager.getAll(false)
            .filter((a : ICallRightType) => {
                var code : string = this.sdkService.Utilities.Numbers.ZeroPad(a.Id.toString(), 5);
                return (query || "").trim().length == 0 || code.toLowerCase().indexOf(query.toLowerCase()) > -1 || a.Name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            })
            .map((a : ICallRightType) => {
                var code : string = this.sdkService.Utilities.Numbers.ZeroPad(a.Id.toString(), 5);
                return { 
                    Id : a.Id, 
                    EntityType : ProlifeSdk.EstimatedCallRightTypeCode, 
                    Code: code,
                    Description : a.Name,
                    Icon: {
                        background: "white",
                        foreground: "black",
                        icon: this.Icon
                    }
                }
            });
        return Promise.resolve<IEntityForRef[]>(entities);
    }

    public create(document : IDocumentInfoForInlineRefProvider) : IDocumentRowInlineReferenceProvider
    {
        return new CallRightRefConverter(this.ActivationCode, document);
    }
}
