export interface PromiseWithProgress<T> extends Promise<T> {
    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): PromiseWithProgress<TResult1 | TResult2>
    catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): PromiseWithProgress<T | TResult>;
    finally(onfinally?: (() => void) | undefined | null): PromiseWithProgress<T>
    progress<K = any>(callback: (data: K) => void) : PromiseWithProgress<T>;
}
export class Deferred<T = any> {
    private internalResolve : (value? : T | PromiseLike<T>) => void;
    private internalReject : (value? : any) => void;
    
    private internalPromise : Promise<T>;
    private progressCallbacks : ((data: any) => void)[] = [];
    
    constructor() {
        let self = this;

        this.internalPromise = new Promise<T>((res, rej) => {
            this.internalResolve = res;
            this.internalReject = rej;
        });
        this.internalPromise["progress"] = function progress(callback: (data: any) => void) {
            self.progressCallbacks.push(callback);
            return this;
        };

        Object.freeze(this);
    }

    public notify<K>(data: K) {
        for(let cb of this.progressCallbacks)   
            cb(data);
    }

    public resolve(value? : T | PromiseLike<T>) : Deferred<T> {
        this.internalResolve(value);
        return this;
    }

    public reject(value?: any) : Deferred<T> {
        this.internalReject(value);
        return this;
    }

    public promise() : PromiseWithProgress<T> {
        return this.internalPromise as PromiseWithProgress<T>;
    }
}