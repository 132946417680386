import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, ComponentParam, ComponentParamArray } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ArrayDataSource, IArrayDataSourceModel } from "../../../../DataSources/ArrayDataSource";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IIncompleteAllocationInfo, IAllocationsService } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";

const { classes } = jss.createStyleSheet({
    "read-only-field": {
        padding: "5px"
    }
}).attach();

interface IIncompleteAllocationInfoDataSourceModel extends IArrayDataSourceModel<IIncompleteAllocationInfo> {
    OperationalUnitAllocationInfo: string;
}

export interface IIncompleteAllocationInfoParams {
    allocationsIds: number[];
    allocationType: number;
}

export class IncompleteAllocationInfo extends DialogComponentBase {
    public AllocationsIds: ComponentParamArray<number>;
    public AllocationType: ComponentParam<number>;

    public TeamName : ko.Observable<string> = ko.observable();

    public IncompleteAllocationInfoDataSource: ArrayDataSource<IIncompleteAllocationInfo> = new ArrayDataSource();

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    constructor(private props: IIncompleteAllocationInfoParams) {
        super({ className: "large", noPrompt: true });
        this.title(TextResources.Allocations.IncompleteAllocationInfoTitle);
        
        this.loadInfo();
    }

    renderBody() {
        const infoDialog = this;
        let infoRow: IIncompleteAllocationInfoDataSourceModel;

        return ComponentUtils.bindTo((
            <div class="row">
                <div class="col-md-12">
                    <h3 data-bind={{ text: infoDialog.TeamName }}></h3>
                    <Table bordered={true} dataSource={infoDialog.IncompleteAllocationInfoDataSource} rowAs="infoRow">
                        <Column title={TextResources.Allocations.CartName} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ text: infoRow.model.CartName }}></span>
                        </Column>
                        <Column title={TextResources.Allocations.Role} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ text: infoRow.model.RoleName }}></span>
                        </Column>
                        <Column title={TextResources.Allocations.IncompleteAllocationOperationalUnitInfo} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ text: infoRow.OperationalUnitAllocationInfo }}></span>
                        </Column>
                        <Column title={TextResources.Allocations.IncompleteAllocationCartHoursInfo} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ numberText: infoRow.model.CartHoursAmount }}></span>
                        </Column>
                        <Column title={TextResources.Allocations.IncompleteAllocationPlannedWorkInfo} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ numberText: infoRow.model.PlannedWork }}></span>
                        </Column>
                        <Column title={TextResources.Allocations.IncompleteAllocationPlannedWorkOnOtherTeamsInfo} cssClasses={classes["read-only-field"]}>
                            <span data-bind={{ numberText: infoRow.model.PlannedWorkOnOtherTeams }}></span>
                            <span style="margin-left: 10px" data-bind={{ visible: infoRow.model.PlannedWorkIncludeTheoreticalWork }} title={TextResources.Allocations.PlannedWorkIncludeTheoreticalWork}>
                                <i class="fa fa-exclamation-circle"></i>
                            </span>
                        </Column>
                    </Table>
                </div>
            </div>
        ), this, "infoDialog");
    }

    private async loadInfo(): Promise<void> {
        const info = await this.allocationsService.GetIncompleteAllocationInfo(this.props.allocationsIds, this.props.allocationType);

        const teamName = info.firstOrDefault()?.TeamName;
        this.TeamName(teamName);

        this.IncompleteAllocationInfoDataSource.setData(...info.map(i => this.createDataSourceModel(i)));
        this.IncompleteAllocationInfoDataSource.refresh();
    }

    private createDataSourceModel(allocationInfo: IIncompleteAllocationInfo): IIncompleteAllocationInfoDataSourceModel {
        return {
            id: allocationInfo.AllocationId,
            isGroup: false,
            isLeaf: true,
            title: allocationInfo.CartName,
            model: allocationInfo,
            OperationalUnitAllocationInfo: this.getAllocationInfoMessage(allocationInfo)
        };
    }
    
    private getAllocationInfoMessage(allocationInfo: IIncompleteAllocationInfo): string {
        if (allocationInfo.CartWorkedByOtherTeam)
            return TextResources.Allocations.CartWorkedByOtherTeam;

        if (allocationInfo.CartIsEmpty)
            return TextResources.Allocations.CartIsEmpty;

        if (allocationInfo.CartHasUnestimatedWorkOnly)
            return TextResources.Allocations.CartHasUnestimatedWorkOnly;

        if (allocationInfo.CartIsNotWorkableByTheTeam)
            return TextResources.Allocations.CartIsNotWorkableByTheTeam;

        if (allocationInfo.IncompleteAllocation && !allocationInfo.CartIsEmpty && !allocationInfo.CartIsNotWorkableByTheTeam && !allocationInfo.CartHasUnestimatedWorkOnly && !allocationInfo.CartWorkedByOtherTeam) {
            if (allocationInfo.PlannedWork === 0)
                return TextResources.Allocations.MissingRoleOnCart;
            else if (allocationInfo.PlannedWork > 0 && allocationInfo.PlannedWorkOnOtherTeams > 0 && allocationInfo.PlannedWorkIncludeTheoreticalWork)
                return TextResources.Allocations.SharedCartNotFinished;
            else
                return TextResources.Allocations.NotEnoughtOperationalUnitOnCart;
        }

        return "";
    }
}