/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 08/02/13
 * Time: 12.24
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { LetterGroupingViewModel, CustomerForListAdapter } from "./LetterGroupingViewModel";
import { CustomerEditorViewModel } from "./CustomerEditorViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ICustomersService, ICustomerForList } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomersViewer } from "../../interfaces/ICustomersViewer";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { AllCustomersPaymentExtensionsDialog } from "./dialogs/AllCustomersPaymentExtensionsDialog";
import { LazyImport } from "../../../Core/DependencyInjection";

export class CustomersViewModel implements ICustomersViewer {
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    public leftPane: LetterGroupingViewModel;
    public detail: ko.Observable<CustomerEditorViewModel> = ko.observable();
    public selectedCustomer: ko.Observable<ICustomerForList> = ko.observable();
    public searchFilter: ko.Observable<string> = ko.observable();
    public CanExportContacts: ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator: IServiceLocator, openFirstContact: boolean) {
        this.leftPane = new LetterGroupingViewModel(serviceLocator, this, openFirstContact);
        this.CanExportContacts(this.authorizationsService.isAuthorized("Customers_ExportContacts"));

        this.changeDetail();
    }

    public OpenCustomer(customerId: number) {
        this.customersService.getCustomer(customerId).then((c: ICustomer) => {
            this.detail(new CustomerEditorViewModel(c));
            this.selectedCustomer(new CustomerForListAdapter(this.serviceLocator).FromCustomer(c));
        });
    }

    dispose() {
        this.leftPane.dispose();
        this.detail().dispose();
    }

    public ClearFilter() {
        this.searchFilter("");
    }

    public goHome() {
        location.href = "#/";
    }

    public addNew() {
        this.changeDetail();
    }

    public async showAllCustomersPaymentExtensions(): Promise<void> {
        const dialog = new AllCustomersPaymentExtensionsDialog();
        return dialog.show();
    }

    public setSelectedCustomer(customer: ICustomerForList, isNew = false) {
        this.changeDetail(customer.Id, !isNew).then((result: boolean) => {
            if (!result) return;

            this.selectedCustomer(customer);
        });
    }

    public getSelectedCustomer(): ICustomerForList {
        return this.selectedCustomer();
    }

    private changeDetail(customerId?: number, checkDataChanges = true): Promise<boolean> {
        const oldDetail = this.detail();
        if (oldDetail) {
            oldDetail.dispose();
            //delete oldDetail;
        }

        const d = this.detail() && checkDataChanges ? this.detail().cancel() : Promise.resolve(true);

        return d.then((confirm: boolean) => {
            if (!confirm) return false;

            if (customerId && customerId > 0)
                this.customersService.getCustomer(customerId).then((c: ICustomer) => {
                    this.detail(new CustomerEditorViewModel(c));
                });
            else this.detail(new CustomerEditorViewModel(null));

            return true;
        });
    }
}
