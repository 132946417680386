export enum HoursApprovalState {
    Created = 1,
    PartiallyApproved = 2, // Utilizzato solamente lato client, non si trova mai nella tabella su DB
    Approved = 3,
    Rejected = 4,
    Modified = 5,
    Reset = 6,
    Unmanaged = 7, // Utilizzato solamente lato client, non si trova mai nella tabella su DB
    SignRemoved = 8,
    SignAdded = 9,
    RowDeleted = 10
}