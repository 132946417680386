import * as React from "@abstraqt-dev/jsxknockout";
import { ReactElement } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../Core/utils/ComponentUtils";
import { If } from "../IfIfNotWith";
import { ITableItem } from "./TableComponent";

export interface ISecondaryRowParams<T> {
    className? : string;
    visible?: boolean | (() => string);
    if?: (() => string);
    cssClasses?: string;
    children?: (item: ITableItem<T>) => ReactElement;
}

export class SecondaryRow<T = any> {
    constructor(private props : ISecondaryRowParams<T>) {
        
    }

    renderRow(item: ITableItem<T>) {
        let bindings = "";

        if (this.props["bind-visible"])
            bindings += "visible: " + this.props["bind-visible"];

        if (this.props.visible)
            bindings += "visible: " + this.props.visible;

        const [children] = this.props.children as unknown as (((item: ITableItem<T>) => ReactElement)[]);

        if(this.props["bind-if"])
            return <If condition={this.props["bind-if"]}>
                {() =>  <tr data-bind={bindings} className={this.props.className}>
                            {children(item)}
                        </tr>
                }
            </If>

        return (
            <tr data-bind={bindings} className={this.props.className}>
                {children(item)}
            </tr>
        );
    }

    render() {
        return <></>;
    }
}