import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { IProvisioningService, IRequestForQuotationForList, IGetRequestsForQuotationsRequest } from "../Provisioning/ProvisioningService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export interface IRequestsForQuotationsDataSourceModel extends IDataSourceModel<number, IRequestForQuotationForList> {
    DescriptionVisible?: ko.Observable<boolean>;
    toggleDescription: () => void;
}

export class RequestsForQuotationsDataSource extends BaseDataSource<IRequestsForQuotationsDataSourceModel> {
    public supplierId: number = null;
    public dateFrom: Date = null;
    public dateTo: Date = null;
    public stateId: number = null;
    
    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;
    
    constructor() {
        super();
    }

    public setSupplierFilter(supplierId: number): void {
        this.supplierId = supplierId;
    }

    public setDateFromFilter(date: Date): void {
        this.dateFrom = date;
    }

    public setDateToFilter(date: Date): void {
        this.dateTo = date;
    }

    public setStateIdFilter(stateId: number): void {
        this.stateId = stateId;
    }

    public getTitle(currentModel: IRequestsForQuotationsDataSourceModel): string {
        return ProlifeSdk.TextResources.Provisioning.RequestsForQuotations;
    }
    
    public async getData(currentModel: IRequestsForQuotationsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IRequestsForQuotationsDataSourceModel[]> {
        let params: IGetRequestsForQuotationsRequest = {
            SupplierId: this.supplierId,
            DateFrom: this.dateFrom,
            DateTo: this.dateTo,
            StateId: this.stateId,
            TextFilter: textFilter,
            Skip: skip,
            Count: count
        };

        let requests = await this.provisioningService.GetRequestsForQuotations(params);
        return requests.map(this.createRequestDataSourceModel, this);
    }
    
    public async getById(currentModel: IRequestsForQuotationsDataSourceModel, ids: number[]): Promise<IRequestsForQuotationsDataSourceModel[]> {
        let requests = await this.provisioningService.GetRequestsForQuotationsByIds(ids);
        return requests.map(this.createRequestDataSourceModel, this);
    }
    
    private createRequestDataSourceModel(request: IRequestForQuotationForList): IRequestsForQuotationsDataSourceModel {
        const description = ko.observable(false);

        Object.defineProperty(request, "DescriptionVisible", {
            value: description
        });

        

        return {
            id: request.Id,
            title: String.format(ProlifeSdk.TextResources.Provisioning.RequestForQuotationForList, request.Number, moment(request.CreationDate).format("L"), request.SupplierName),
            subTitle: request.Description,
            isGroup: false,
            isLeaf: true,
            model: request,
            DescriptionVisible: description,
            toggleDescription: () => description(!description())
        };
    }
}