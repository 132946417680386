import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IDocumentsService } from "../../DocumentsService";
import { IDocumentsProvider } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";

export class ProtocolsDocumentsReport extends ProLifeReport implements IReport, IReportViewModel {
    private fileRepositoryService: IFileRepositoryService;
    private toastService: IInfoToastService;
    private sdkService: IProLifeSdkService;
    private docsService: IDocumentsService;
    private dialogsService: IDialogsService;

    Name: string = "Documenti emessi";
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";

    detailsTemplateName: string = "protocols-documents";
    detailsTemplateUrl: string = "invoices/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    private startDate: ko.Observable<Date> = ko.observable();
    private endDate: ko.Observable<any> = ko.observable();

    public Protocols: ko.ObservableArray<Protocol> = ko.observableArray([]);
    public ProtocolTypes: ko.ObservableArray<ProtocolType> = ko.observableArray([]);

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(groupId, 4);
        this.fileRepositoryService = <IFileRepositoryService>(
            serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.docsService = <IDocumentsService>serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
    }

    public getExcelExportData(): any | null {
        if (!this.startDate() || !this.endDate() || moment(this.startDate()).isAfter(this.endDate())) {
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.SelectValidInterval);
            return null;
        }

        var selectedProtocols: string[] = this.Protocols()
            .filter((p: Protocol) => {
                return p.Selected();
            })
            .map((p: Protocol) => {
                return p.Id.toString();
            });

        if (selectedProtocols.length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.SelectProtocol);
            return null;
        }

        return {
            start: this.startDate(),
            end: this.endDate(),
            protocolsIds: selectedProtocols.join("_"),
        };
    }

    initialize() {
        //super.initialize();
        var registeredDocumentProviders = this.docsService.getRegisteredDocumentProviders();
        this.Protocols(
            registeredDocumentProviders.map((p: IDocumentsProvider) => {
                return new Protocol(p);
            })
        );

        var documentEntityCodes = registeredDocumentProviders.map((p: IDocumentsProvider) => p.DocumentEntityCode);
        documentEntityCodes = documentEntityCodes.filter((itm, i, a) => {
            return i == a.indexOf(itm);
        });

        this.ProtocolTypes(documentEntityCodes.map((c) => new ProtocolType(c, this.Protocols)));

        this.startDate(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.endDate(moment(new Date()).subtract("month", 1).endOf("month").startOf("day").toDate());
    }

    Open() {
        this.navigator.openReport(this);
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }

    getViewModel(): IReportViewModel {
        return this;
    }
}

export class Protocol {
    public Selected: ko.Observable<boolean> = ko.observable(true);
    public Name: string;
    public Id: number;
    public Code: string;

    constructor(provider: IDocumentsProvider) {
        this.Name = provider.Name();
        this.Id = provider.Id;
        this.Code = provider.DocumentEntityCode;
    }
}

export class ProtocolType {
    public Name: string;
    public Code: string;
    public Selected: ko.Observable<boolean> = ko.observable(true);

    constructor(code: string, private Protocols: ko.ObservableArray<Protocol>) {
        this.Code = code;

        switch (code) {
            case ProlifeSdk.InvoiceEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.VATRegistries;
                break;

            case ProlifeSdk.DdtEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.Deliveries;
                break;

            case ProlifeSdk.EstimateEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.Quotes;
                break;

            case ProlifeSdk.CustomerOrderEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.CustomerOrders;
                break;

            case ProlifeSdk.SupplierOrderEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.VendorOrders;
                break;

            case ProlifeSdk.WarehouseLoadEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.WarehouseLoad;
                break;

            case ProlifeSdk.SalEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.Sal;
                break;

            case ProlifeSdk.WarehouseTransferEntityTypeCode:
                this.Name = ProlifeSdk.TextResources.Invoices.Transfers;
                break;

            default:
                this.Name = ProlifeSdk.TextResources.Invoices.UnknownSimple;
        }
    }

    public run() {
        this.Selected(!this.Selected());

        this.Protocols()
            .filter((p: Protocol) => p.Code == this.Code)
            .forEach((p: Protocol) => p.Selected(this.Selected()));
    }
}
