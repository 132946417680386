import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { JobOrdersListReport, JobOrderForReport } from "../JobOrdersListReport";
import {
    ICustomerJobOrdersGroup,
    IEventfulJobOrder,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../../Core/Deferred";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class JobOrderWithIncompleteQualityReport extends JobOrdersListReport {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    detailsTemplateName = "job-order-with-incomplete-quality";
    detailsTemplateUrl = "jobOrder/templates/reports";

    public Customers: ko.ObservableArray<ICustomerJobOrdersGroup> = ko.observableArray([]);
    public ShowWithoutEvents: ko.Observable<boolean> = ko.observable(false);

    private refreshEnabled = true;

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(serviceLocator, groupId, 1);
        this.Name = ProlifeSdk.TextResources.JobOrder.OrdersNotQualityCompleted;

        this.ActivitiesFilter.ConfigureToggles(
            true,
            ProlifeSdk.TextResources.JobOrder.Present,
            ProlifeSdk.TextResources.JobOrder.Absent,
            this.onToggleIsChanged.bind(this)
        );

        this.ShowWithoutEvents.subscribe((v) => {
            this.refreshEnabled = false;
            if (v) this.ActivitiesFilter.clearSelection(false);
            this.ActivitiesFilter.setMultipleSelectionStatus(!v);
            this.refreshEnabled = true;
        });
        this.ShowWithoutEvents.subscribe(this.NotifyFilterChange.bind(this));
    }

    public onToggleIsChanged(provider: INavigationMenuProvider) {
        this.NotifyFilterChange();
    }
    public onSelectionChanged(selection: INavigationMenuProvider[]) {
        this.NotifyFilterChange();
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();

        if (!this.refreshEnabled) return def.resolve().promise();

        const tasks = this.ActivitiesFilter.getSelectedProviders().map((p: any) => {
            return { Id: p.task.Id, With: p.Toggle() };
        });

        const withoutTasksFilters = tasks
            .filter((t) => {
                return !t.With;
            })
            .map((t: any) => {
                return t.Id;
            });

        const templatesTasksFilters = tasks
            .filter((t) => {
                return t.With;
            })
            .map((t: any) => {
                return t.Id;
            });

        this.jobOrderService
            .getJobOrdersWithIncompleteQuality(
                moment(this.From()).startOf("day").toDate(),
                moment(this.To()).endOf("day").toDate(),
                templatesTasksFilters,
                withoutTasksFilters,
                this.StateId(),
                this.TypeId(),
                this.ShowWithoutEvents()
            )
            .then((results: IEventfulJobOrder[]) => {
                const customers: ICustomerJobOrdersGroup[] = [];
                const processedCustomers = [];

                results.forEach((r: any) => {
                    if (processedCustomers.indexOf(r.CustomerId) != -1) return;

                    processedCustomers.push(r.CustomerId);

                    const customer: ICustomerJobOrdersGroup = {
                        Name: r.CustomerName,
                        JobOrders: [],
                    };

                    customer.JobOrders = results
                        .filter((r1: IEventfulJobOrder) => {
                            return r1.CustomerId == r.CustomerId;
                        })
                        .map((r2: IEventfulJobOrder) => {
                            return new JobOrderForReport(r2);
                        });

                    customers.push(customer);
                });

                this.Customers(customers);
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    initialize() {
        super.initialize();
        this.Refresh();
    }

    public ExportAsPdf(versionId: number) {
        const url: string =
            "JobOrder/JobOrdersWithIncompleteQualityReportPrint/GeneratePdf" +
            this.PrepareExportUrlParameters(versionId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public ExportAsExcel(versionId: number) {
        const url: string =
            "JobOrder/JobOrdersWithIncompleteQualityReportPrint/GenerateExcel" +
            this.PrepareExportUrlParameters(versionId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private PrepareExportUrlParameters(versionId: number) {
        const tasks = this.ActivitiesFilter.getSelectedProviders().map((p: any) => {
            return { Id: p.task.Id, With: p.Toggle() };
        });
        const withoutTasks = tasks.filter((t) => {
            return !t.With;
        });
        const withTasks = tasks.filter((t) => {
            return t.With;
        });

        let withTasksString = "";

        withTasks
            .map((t: any) => {
                return t.Id;
            })
            .forEach((t: number) => {
                if (withTasksString.length > 0) withTasksString += "-";
                withTasksString += t.toString();
            });

        let withoutTasksString = "";

        withoutTasks
            .map((t: any) => {
                return t.Id;
            })
            .forEach((t: number) => {
                if (withoutTasksString.length > 0) withoutTasksString += "-";
                withoutTasksString += t.toString();
            });

        const result =
            "?versionId=" +
            versionId +
            "&from=" +
            encodeURIComponent(moment(this.From()).format()) +
            "&to=" +
            encodeURIComponent(moment(this.To()).format()) +
            "&templatesTasksFilters=" +
            withTasksString +
            "&withoutTasksFilters=" +
            withoutTasksString +
            "&stateId=" +
            this.StateId() +
            "&typeId=" +
            this.TypeId() +
            "&showWithoutActivities=" +
            this.ShowWithoutEvents();

        return result;
    }
}
