import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 16/08/2018
 * Time: 18:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IGoogleCalendarService, IGoogleUser, IGoogleCalendar } from "../ProlifeSdk/interfaces/agenda/IGoogleCalendarService";

export class GoogleCalendarService extends ProLifeService implements IGoogleCalendarService {
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.GoogleCalendarServiceType);
        
        this.serviceLocator.registerServiceInstance(this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType():string {
        return ProlifeSdk.GoogleCalendarServiceType;
    }

    isOfType(serviceType:string) : boolean {
        return serviceType == this.getServiceType();
    }

    getUserDetails(agendaId : number, synchronous: boolean) : Promise<IGoogleUser> {
        return this.ajaxService.Get("GoogleCalendar-api", "User/GetUserInfo?agendaId=" + agendaId, {
            async: !synchronous
        });
    }

    getCalendars(agendaId : number) : Promise<IGoogleCalendar[]> {
        return this.ajaxService.Get("GoogleCalendar-api", "Calendar/GetCalendars?agendaId=" + agendaId, {});
    }

    Logout(agendaId : number) : Promise<void> {
        return this.ajaxService.Get("GoogleCalendar-api", "User/Logout?agendaId=" + agendaId, {});
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new GoogleCalendarService(serviceLocator);
}