import * as ko from "knockout";
import { IFilterWithMatches } from "../../interfaces/IFilterWithMatches";
import { IBaseFilter } from "../../interfaces/IBaseFilter";

export class FilterWithMatches implements IFilterWithMatches {
    matches: string[];

    constructor(public filter : IBaseFilter) {
        this.matches = [];
    }

    addMatch(match : string) : void {
        this.matches.push(match);
    }
}


