import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import { EventHandler } from "../../utils/EventHandler";
import { IEventHandler } from "../../../interfaces/IEventHandler";
import { ITreeGridSelect2DataProvider } from "../../../interfaces/controls/treegrid/TreeGridInterfaces";

export interface ITreeGridAction<T> {
    Classes : string | ko.Observable<string>;
    Icon : string | ko.Observable<string>;
    Action(item : TreeGridItem<T>, action?: ITreeGridAction<T>, event? : Event) : void;
}

export class TreeGrid<T = any> {
    public Items : ko.ObservableArray<TreeGridItem<T>> = ko.observableArray<TreeGridItem<T>>().extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } });
    public Columns : ko.ObservableArray<TreeGridColumn<T>> = ko.observableArray();

    public ItemHeight : ko.Observable<number> = ko.observable(30);

    public SearchFilter : ko.Observable<string> = ko.observable();
    public TotalWidth : ko.Computed<number>;
    public TotalHeight: ko.Computed<number>;
    public VisibleItems : ko.Computed<number>;

    public ShowActions : ko.Observable<boolean> = ko.observable(true);
    public AllowSort : ko.Observable<boolean> = ko.observable(true);

    public SelectedItem : ko.Observable<any> = ko.observable();
    public SortingModeChanged : IEventHandler = new EventHandler();

    constructor() {
        this.TotalWidth = ko.computed(() => {
            var width = 0;
            this.Columns().forEach((c : TreeGridColumn<T>) => {
                width += c.DefaultWidth();
            });
            return width;
        }).extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } });

        this.VisibleItems = ko.computed(() => {
            var visibleItems = this.Items().filter((item : TreeGridItem<T>) => item.Visible());
            for(var i = 0; i < visibleItems.length; i++) {
                var item:TreeGridItem<T> = visibleItems[i]
                if (item.IsExpanded()) {
                    item.Children().filter((child:TreeGridItem<T>) => child.Visible()).forEach(c => visibleItems.push(c));
                }
            }
            return visibleItems.length;
        });

        this.TotalHeight = ko.computed(() => {
            return this.VisibleItems() * this.ItemHeight();
        }).extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } });

        this.AllowSort.subscribe((value) => {
            this.Columns().forEach((c : TreeGridColumn<T>) => c.AllowSort(value));
        });
    }

    public AddItem(value : any, title : string, fullRow : boolean = false) : TreeGridItem<T> {
        var item = new TreeGridItem<T>(this, value, title);
        item.FullRow(fullRow);
        item.ShowActions = this.ShowActions;
        this.Items.push(item);
        return item;
    }

    public AddChildAfter(value : any, title : string, insertAfterNode : TreeGridItem<T>, fullRow : boolean = false) : TreeGridItem<T> {
        var item = new TreeGridItem<T>(this, value, title);
        item.Parent(this);
        item.FullRow(fullRow);
        item.ShowActions = this.ShowActions;

        var index = this.Items().indexOf(insertAfterNode);
        this.Items.splice(index+1, 0, item);

        return item;
    }

    public AddColumn(newColumn : TreeGridColumn<T>) : TreeGridColumn<T> {
        this.Columns.push(newColumn);
        newColumn.SortingModeChanged.Add(this.OnSortingModeChanged.bind(this));
        newColumn.AllowSort(this.AllowSort());
        return newColumn;
    }

    public ClearItems() {
        this.Items([]);
    }

    public Select(item : TreeGridItem<T>) {
        this.Items().forEach(i => i.Deselect());
        item.Selected(true);
        this.SelectedItem(item);
    }

    public ExpandAll() {

    }

    public CollapseAll() {

    }

    private OnSortingModeChanged(column : TreeGridColumn<T>, sortingMode : number) {
        this.Columns().forEach(c => {
            if(c == column) return;
            c.SortMode(0);
        });

        if(this.AllowSort())
            this.SortingModeChanged.Call(column, sortingMode);
    }
}

export class TreeGridColumn<T = any> {
    public Title : ko.Observable<string> = ko.observable();
    public TitleFormatter : (title : string) => string;
    public Accessor : (data : T) => any;
    public DefaultWidth : ko.Observable<number> = ko.observable();
    public Alignment : ko.Observable<string> = ko.observable('center');
    public TitleIcon : ko.Observable<string> = ko.observable();
    public FormattedTitle : ko.Computed<string>;

    public AllowSort : ko.Observable<boolean> = ko.observable(true);
    public SortMode : ko.Observable<number> = ko.observable(0); //0 - No Sort, 1 - Ascending, 2 - Descending
    public SortingModeChanged : IEventHandler = new EventHandler();

    constructor(title : string, accessor : (data : T) => any, width: number) {
        this.Title(title);
        this.Accessor = accessor;
        this.TitleFormatter = this.defaultFormatter.bind(this);
        this.DefaultWidth(width);

        this.FormattedTitle = ko.computed(() => {
            return this.TitleFormatter(this.Title());
        });
    }

    private defaultFormatter(title : string) {
        if(!this.TitleIcon()) return title;
        var icon = this.TitleIcon();
        return "<div title='" + title + "' style='overflow: hidden; text-overflow: ellipsis; text-align: center; margin-left: 4px; margin-right: 4px; margin-bottom: 7px; margin-top: 5px;'>" +
               "<span style='text-overflow: ellipsis; cursor: default;'><i class='" + icon + "'/>&nbsp;" + title + "</span>" +
               "<div style='clear: both;'></div></div>";
    }

    public Icon(icon : string) : TreeGridColumn<T> {
        this.TitleIcon(icon);
        return this;
    }

    public GetHtml(value : any) : string {
        return value;
    }

    public FormatTitle(formatter : (title : string) => string) : TreeGridColumn<T> {
        this.TitleFormatter = formatter;
        return this;
    }

    public Align(alignment : string) : TreeGridColumn<T> {
        this.Alignment(alignment);
        return this;
    }

    public SwitchSortingMode() {
        if(!this.AllowSort())
            return;

        this.SortMode((this.SortMode() + 1) % 3);
        this.SortingModeChanged.Call(this, this.SortMode());
    }
}

export class TreeGridStringColumn<T = any, N = any> extends TreeGridColumn<T> {
    public Formatter : (value : N) => string;

    constructor(title : string, accessor : (data : T) => N, width: number) {
        super(title, accessor, width);

        this.Formatter = (value : N) => "" + (value === null || value === undefined ? "" : value);
    }

    public GetHtml(value : N) : string {
        return this.Formatter(value);
    }

    public Format(formatter : (value : N) => string) : TreeGridStringColumn<T, N> {
        this.Formatter = formatter;
        return this;
    }
}

export class TreeGridMultilineStringColumn<T = any, N = any> extends TreeGridColumn<T> {
    public Formatter: (value : N) => string;
    public AlternativeFormatter: (value : N) => string;

    public Action: (value: N, target: HTMLElement) => void;

    private FormatterSwitcher: ko.Observable<boolean>;

    constructor(title: string, accessor: (data: T) => N, width: number) {
        super(title, accessor, width);
    }

    public GetHtml(value : any) : string {
        return this.FormatterSwitcher() ? this.Formatter(value) : this.AlternativeFormatter(value);
    }

    public Format(formatter: (value : N) => string, alternativeFormatter: (value : N) => string, formatterSwitcher: ko.Observable<boolean>): TreeGridMultilineStringColumn<T, N> {
        this.Formatter = formatter;
        this.AlternativeFormatter = alternativeFormatter;
        this.FormatterSwitcher = formatterSwitcher;

        return this;
    }

    public RegisterAction(action: (value: N, target: HTMLElement) => void): void {
        this.Action = action;
    }
}

export class TreeGridMoneyColumn<T = any> extends TreeGridColumn<T> {
    public Formatter : (value : number) => string;

    constructor(title : string, accessor : (data : T) => number, width: number) {
        super(title, accessor, width);

        this.Formatter = (value : number) => (value == null || value == undefined) ? "" : numeral(value).format("0,0.00 $");
    }

    public GetHtml(value : any) : string {
        return this.Formatter(value);
    }

    public Format(formatter : (value : number) => string) : TreeGridMoneyColumn<T> {
        this.Formatter = formatter;
        return this;
    }
}

export class TreeGridDateTimeColumn<T = any> extends TreeGridColumn<T> {
    public Formatter : (value : any) => string;

    constructor(title : string, accessor : (data : T) => any, width: number) {
        super(title, accessor, width);

        this.Formatter = (value : Date) => {
            if(!value) return "";
            return moment(value).format("L") + ' ' + moment(value).format("LT");
        }
    }

    public GetHtml(value : any) : string {
        return this.Formatter(value);
    }

    public Format(formatter : (value : Date) => string) : TreeGridDateTimeColumn<T> {
        this.Formatter = formatter;
        return this;
    }
}

export class TreeGridSelect2Column<T = any> extends TreeGridColumn<T> {
    private DataProvider : ITreeGridSelect2DataProvider;

    constructor(title : string, accessor : (data : T) => any, width: number, provider : ITreeGridSelect2DataProvider) {
        super(title, accessor, width);

        this.DataProvider = provider;
    }
}

export class TreeGridActionsColumn<T = any> extends TreeGridColumn<T> {
    public Actions : ko.ObservableArray<ITreeGridAction<T>> = ko.observableArray();

    constructor(title : string, accessor : (data : T) => any, width: number) {
        super(title, accessor, width);
    }
}

export class TreeGridItem<T = any> {
    public Title : ko.Observable<string> = ko.observable();
    public Children : ko.ObservableArray<TreeGridItem<T>> = ko.observableArray();
    public Visible : ko.Observable<boolean> = ko.observable(true);
    public Selected : ko.Observable<boolean> = ko.observable(false);
    public IsExpanded : ko.Observable<boolean> = ko.observable(false);
    public Actions : ko.ObservableArray<ITreeGridAction<T>> = ko.observableArray();
    public CanHaveChildren : ko.Observable<boolean> = ko.observable(true);
    public WereChildrenLoaded : ko.Observable<boolean> = ko.observable(false);
    public CanExpand : ko.Computed<boolean>;
    public OnChildrenNeeded : (childrenLoaded : () => void) => void;
    public Columns : ko.ObservableArray<TreeGridColumn<T>>;
    public FullRow : ko.Observable<boolean> = ko.observable(false);
    public ShowActions : ko.Observable<boolean> = ko.observable(true);

    public Level : ko.Computed<number>;
    public Parent : ko.Observable<any> = ko.observable();
    public Value : ko.Observable<T> = ko.observable();

    public IsExpandedChanged : IEventHandler = new EventHandler();

    constructor(private treeGrid : TreeGrid<T>, value : T, title : string) {
        this.Value(value);
        this.Title(title);
        this.Columns = treeGrid.Columns;

        this.CanExpand = ko.computed(() => {
            return this.CanHaveChildren() && (this.Children().length > 0 || !this.WereChildrenLoaded());
        });

        this.Level = ko.computed(() => {
            if(!this.Parent()) return 0;
            return this.Parent().Level() + 1;
        });

        this.IsExpanded.subscribe(this.onExpandedChanged.bind(this));
    }

    private onExpandedChanged() {
        if(this.IsExpanded() && !this.WereChildrenLoaded()) {
            this.OnChildrenNeeded && this.OnChildrenNeeded(this.onChildrenLoaded.bind(this));
        }

        this.IsExpandedChanged.Call(this.IsExpanded());
    }

    private onChildrenLoaded() {
        this.WereChildrenLoaded(true);
    }

    public Select() {
        this.treeGrid.Select(this);
    }

    public Deselect() {
        this.Selected(false);
        this.Children().forEach(c => c.Deselect());
    }

    public AddChild(value : any, title : string, fullRow : boolean = false) : TreeGridItem<T> {
        var item = new TreeGridItem<T>(this.treeGrid, value, title);
        item.Parent(this);
        item.FullRow(fullRow);
        item.ShowActions = this.ShowActions;
        this.Children.push(item);
        return item;
    }

    public AddChildAfter(value : any, title : string, insertAfterNode : TreeGridItem<T>, fullRow : boolean = false) : TreeGridItem<T> {
        var item = new TreeGridItem<T>(this.treeGrid, value, title);
        item.Parent(this);
        item.FullRow(fullRow);
        item.ShowActions = this.ShowActions;

        var index = this.Children().indexOf(insertAfterNode);
        this.Children.splice(index+1, 0, item);

        return item;
    }
}