import * as numeral from "numeral";
export class DiscountsUtilities
{
    static calculateDiscount(discounts : string) : number {
        let totalDiscount = 1;
        const safeDiscounts = (discounts || "").replace(/[^0-9.,]/g, " ");

        safeDiscounts.split(" ")
            .filter((discountString : string) => discountString.length > 0)
            .map((discountString : string) => 1 - numeral(discountString + "%").value())
            .forEach((discount : number) => totalDiscount *= discount);

        return totalDiscount;
    }

    static calculateDiscountFromPercentages(...discounts : number[]) : number {
        let totalDiscount = 1;

        discounts
            .filter(d => d != null && d != undefined)
            .forEach((discount : number) => totalDiscount *= (1 - (discount / 100)));

        return (1 - totalDiscount) * 100;
    }
}