import * as ko from "knockout";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";

export class ResourceManagerArea {

    public title : string;
    public icon : string;
    public isActive : ko.Computed<boolean>;
    public templateUrl : string;
    public templateName : string;
    public componentName : string;

    constructor(title : string, icon : string, private mainLayout : IResourcesManagerViewModel, templateUrl : string, templateName : string, private areaRoot : string, componentName? : string) {
        this.title = title;
        this.icon = icon;
        this.templateName = templateName;
        this.templateUrl = templateUrl;
        this.componentName = componentName;

        this.isActive = ko.computed(() => {
            return this == this.mainLayout.selectedArea();
        });
    }

    public show() {
        this.mainLayout.selectedArea(this);
    }

    public open(){
        location.href = "#/Risorse/" + this.areaRoot;
    }
}