import * as ko from "knockout";
import { INavigationMenuComponentActionItem, INavigationMenuComponentAction, INavigationMenuComponent } from "./INavigationMenuComponent";

export class NavigationMenuComponentAction implements INavigationMenuComponentActionItem {
    public isSeparator: false;
    public isGroup: false;
    public visible: ko.Computed<boolean>;
    public canExecute: ko.Computed<boolean>;
    public icon : ko.Computed<string>;
    public text : ko.Computed<string>;
    public title : ko.Computed<string>;
    public active : ko.Computed<boolean>;
    public activeClass : ko.Observable<string> = ko.observable();
    public defaultClass : ko.Observable<string> = ko.observable();
    
    constructor(public actionInfo: INavigationMenuComponentAction, private navigator: INavigationMenuComponent) {
        this.isGroup = this.actionInfo.isGroup;
        this.isSeparator = this.actionInfo.isSeparator;

        this.visible = ko.computed(() => {
            if(actionInfo.visible === undefined)
                return true;
            return actionInfo.visible();
        });

        this.canExecute = ko.computed(() => {
            if(actionInfo.canExecute === undefined)
                return true;
            return actionInfo.canExecute();
        });

        this.icon = ko.computed(() => {
            if(typeof this.actionInfo.icon === 'function')
                return this.actionInfo.icon();
            return this.actionInfo.icon;
        });

        this.text = ko.computed(() => {
            if(typeof this.actionInfo.text === 'function')
                return this.actionInfo.text();
            return this.actionInfo.text;
        });
        
        this.title = ko.computed(() => {
            if(typeof this.actionInfo.title === 'function')
                return this.actionInfo.title();
            return this.actionInfo.title;
        });

        this.active = ko.computed(() => {
            if(actionInfo.active === undefined) return false;
            return actionInfo.active();
        });

        this.activeClass(this.actionInfo.activeClass || "");
        this.defaultClass(this.actionInfo.defaultClass || "btn-primary");
    }

    public click(): void  {
        if (!this.canExecute() || !this.visible())
            return;

        this.actionInfo.action(this.actionInfo, this.navigator);
    }
}