import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 26/02/13
 * Time: 15.29
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CustomerControlsEntityProvider } from "./CustomerControlsEntityProvider";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { ICustomerHint, ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class CustomerEntityProvider implements IEntityProvider<number, ICustomerHint|ICustomer> {

    private customerService : ICustomersService;

    constructor(private serviceLocator : IServiceLocator ) {

		var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
		entityProviderService.registerEntityProvider(this, this.getType());

        this.customerService = <ICustomersService> serviceLocator.findService(ProlifeSdk.CustomersServiceType);
    }

    getType() : string
    {
        return ProlifeSdk.CustomerEntityType;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Customers.AddressBook;
    }

    getPkValue(item : ICustomerHint|ICustomer) : number
    {
        return (item as ICustomerHint).Id || (item as ICustomer).IdCliente;
    }

    getDisplayName(item : ICustomerHint) : string
    {
        return item.FormattedContactName;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.customerService.getCustomer(pkValue)
            .then((customer : ICustomer) => {
                def.resolve(customer.FormattedContactName);
            })
            .catch(() => { def.reject(); });
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<ICustomerHint[]>
    {
        return this.customerService.hintSearch(stringFilter);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new CustomerControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : any) : Promise<ICustomer>
    {
        return this.customerService.getCustomerById(pkValue);
    }
}