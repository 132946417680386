import * as ProlifeTextResources from "./ProlifeTextResources";

export const TextResources = ProlifeTextResources.TextResources;

//Eccezioni server
export const ServerException_SessionExpired = "ProLife.AdvancedSecurity.Security.ProLifeSessionExpiredException";
export const ServerException_Authentication = "ProLife.AdvancedSecurity.Security.ProLifeAuthenticationException";
export const ServerException_Authorization = "ProLife.AdvancedSecurity.Security.ProLifeAuthorizationException";
export const ServerException_MaintenanceMode = "ProLife.AdvancedSecurity.Security.ProLifeMaintenanceModeException";
export const ServerException_OldVersion = "ProLife.AdvancedSecurity.Security.ProLifeOldVersionException";
export const ServerException_ProLife = "ProLife.Core.Exceptions.ProLifeException";
export const ServerException_ProLifeValidationException = "ProLife.Core.Exceptions.ProLifeValidationException";
export const ServerException_AllocateCartsAtPositionOnTeamValidationException =
    "Service.Exceptions.AllocateCartsAtPositionOnTeamValidationException";
export const ServerException_WarehouseMovementChangesValidationException =
    "ProLife.DocumentsSDK.Exceptions.WarehouseMovementChangesValidationException";
export const ServerException_WorkflowOutcomeChangeValidationException =
    "ProLife.TodolistSDK.Exceptions.WorkflowOutcomeChangeValidationException";
export const ServerException_WorkedHoursImportedInDocumentsValidationException =
    "ProLife.WorkedHoursSDK.Exceptions.WorkedHoursImportedInDocumentsValidationException";
export const InactivityPeriodExceeded = "InactivityPeriodExceeded";
export const PasswordExpired = "PasswordExpired";
export const PasswordChangeRequired = "PasswordChangeRequired";
export const InvalidCredentials = "InvalidCredentials";
export const InvalidCompany = "InvalidCompany";

//Sdk
export const ProlifeSdkServiceType = "ProlifeSdkService";
export const TodoListServiceType = "TodoListService";
export const ApplicationsConfigurationsServiceCode = "ApplicationsConfigurationsService";

// Core
export const MailTemplateDocumentContactNamePlaceholder = "%%DOCUMENT-CONTACT-NAME%%";
export const MailTemplateDocumentProtocolPlaceholder = "%%DOCUMENT-PROTOCOL%%";
export const MailTemplateDocumentNumberPlaceholder = "%%DOCUMENT-NUMBER%%";
export const MailTemplateDocumentDatePlaceholder = "%%DOCUMENT-DATE%%";
export const MailTemplateDocumentLabelPlaceholder = "%%DOCUMENT-LABEL%%";

//Assets
export const AssetsServiceType = "AssetsService";

//FunctionPointComputation
export const FunctionPointComputationServiceType = "FunctionPointComputationService";

//Projects
export const ProjectsServiceType = "ProjectsService";
export const ProjectEntityType = "Project";
export const ProjectsApplicationCode = "Projects";

//Protocols
export const ProtocolsServiceType = "ProtocolsService";

//Contracts
export const ContractsServiceType = "ContractsService";

//Msdn
export const MsdnServiceType = "MsdnService";

// GDPR
export const GDPRServiceType = "GDPRService";
export const GDPRApplicationCode = "GDPR";

// Tickets
export const TicketsServiceType = "TicketsService";
export const TicketsApplicationCode = "Tickets";

//Resources Manager
export const ResourcesManagerServiceType = "ResourcesManager";
export const OperationalUnitsSettingsServiceType = "OperationalUnitsSettingsService";

//Reminder
export const ReminderServiceType = "Reminder";

//Planning
export const PlanningServiceType = "Planning";

//Expense
export const ExpenseServiceType = "ExpenseService";
export const ExpenseEntityType = "Expense";

//ResourcesManager
export const ResourcesManagerApplicationCode = "ResourcesManager";

//Desktop
export const UserSessionServiceType = "UserSession";
export const UserInfoServiceType = "UserInfo";
export const DesktopApplicationCode = "Desktop";
export const ApplicationsServiceType = "ApplicationsService";
export const ApplicationHostServiceType = "ApplicationHostService";
export const EntityProviderServiceType = "EntityProviderService";
export const LogServiceType = "LogService";
export const DesktopServiceType = "DesktopService";
export const ChangesNotificationsServiceType = "ChangesNotificationsService";
export const EntitiesLockServiceType = "EntitiesLockService";
export const ModulesServiceType = "ModulesService";
export const FinancialDataServiceType = "FinancialDataService";
export const FinancialDataWorkedHoursProviderType = "WorkedHours";
export const FinancialDataPurchasesProviderType = "Purchases";
export const EventsQualityType = "EventsQualityType";
export const EventsTodoListTask = "EventsTodoListTask";
export const SmtpConfigurationServiceType = "SmtpConfigurationService";

//Todolist
export const TodoListResourcesGroupsType = "TodoListResourcesGroups";
export const PersonalTodolistApplicationCode = "PersonalTodoList";
export const TodolistApplicationCode = "Todolist";
export const TaskBoardApplicationCode = "TaskBoard";
export const DefaultTemplateType = "Todolist";
export const WorkflowCategoriesType = "WorkflowCategories";
export const WorkflowStates = "WorkflowStates";
export const WorkflowOutcomes = "WorkflowOutcomes";
export const BudgetRequestCauses = "BudgetRequestCauses";
export const WorkflowEntityType = "Workflow";

export const WorkflowCodeGeneratorType = "WorkflowCodeGeneratorType";

export const JobOrderStateFilterCode = "JOS";
export const JobOrderTypeFilterCode = "JOT";
export const WorkflowTypeFilterCode = "WFT";
export const OpUnitFilterCode = "OPU";

export const EstimatedBudgetForTaskStatesSettingsManager = "EstimatedBudgetForTaskStatesSettings";

//JobOrders
export const JobOrderServiceType = "JobOrderService";
export const SalServiceType = "SalService";
export const JobOrderEntityType = "JobOrder";
export const SalEntityType = "Sal";
export const JobOrderState = "JobOrderState";
export const JobOrderGeneralSettings = "JobOrderGeneralSettings";
export const JobOrderType = "JobOrderType";
export const JobOrderCodeGeneratorType = "JobOrderCodeGeneratorType";
export const JobOrderBlockMotivation = "JobOrderBlockMotivation";
export const JobOrderApplicationCode = "JobOrders";
export const PurchasesTypesSettingsManager = "PurchasesTypesSettingsManager";
export const JobOrderMetadataSettings = "JobOrderMetadataSettings";

export const JobOrderClosedLogicalState = 1;

//Customers
export const CustomersApplication = "Customers";
export const CustomersServiceType = "CustomersService";
export const TrustMovementsServiceType = "TrustMovementsService";
export const TrustsServiceType = "TrustsService";
export const CustomersMetadataTypesSettingsManager = "CustomerMetadataTypes";
export const CustomerEntityType = "Customer";
export const TrustsSettingsManager = "TrustsSettingsManager";
export const TrustUpdateDelaysSettingsManager = "TrustUpdateDelaysSettingsManager";
export const TrustsValidationValidStatus = "VALID";
export const TrustsValidationInvalidStatus = "INVALID";

//Settings
export const SettingsServiceType = "SettingsService";
export const CompanySettingsType = "CompanySettings";
export const HolidaysSettingsType = "HolidaysSettings";

//Users
export const UsersServiceType = "UsersServiceType";
export const UserCharactersServiceType = "UserCharactersServiceType";
export const HumanResourcesServiceType = "HumanResourcesServiceType";
export const CompanyUsers = "CompanyUsers";
export const UsersEntityType = "User";
export const RolesEntityType = "UserRole";
export const HumanResources = "HumanResources";
export const ResourcesGroups = "ResourcesGroups";

//Schedule
export const ScheduleServiceType = "ScheduleService";
export const ScheduleState = "ScheduleState";

export const PricePartPercentageCalculation = 0;
export const PricePartValueCalculation = 1;

//Worked Hours
export const WorkedHoursApplicationCode = "WorkedHours";
export const WorkedHoursServiceType = "WorkedHoursService";
export const WorkTimeCategoriesSettingsServiceType = "WorkTimeCategoriesSettingsService";
export const CallRightTypesSettingsManagerType = "CallRightTypeSettingsManager";
export const WorkTimeCategoryEntityType = "WorkTimeCategory";
export const Tag_WorkSheet = "WorkSheet";
export const Tag_WorkedHours = "WorkedHours";
export const TagType_WorkSheet = "WorkSheet";

//Invoices
export const DocumentsServiceType = "DocumentsService";
export const InvoicesServiceType = "InvoicesService";
export const EstimatesServiceType = "EstimatesService";
export const DDTAppearance = "DDTAppearance";
export const DDTPorti = "DDTPorti";
export const DDTCause = "DDTCause";
export const PaymentMode = "PaymentMode";
export const Conveyance = "Conveyance";
export const Offset = "Offset";
export const DocumentStates = "DocumentStates";
export const DocumentTypes = "DocumentTypes";
export const ExpireModes = "ExpireModes";
export const IvaModes = "IvaModes";
export const ValidityTypes = "ValidityTypes";
export const WitholdingTax = "WitholdingTax";
export const Exercises = "Exercises";
export const VatRegisters = "VatRegisters";
export const EstimateStates = "EstimateStates";
export const DdtStates = "DdtStates";
export const Tag_DocumentId = "DocumentId";
export const Tag_DocumentTypeCode = "DocumentTypeCode";
export const Tag_DocumentTypeDescription = "DocumentTypeDescription";
export const InvoiceApplicationCode = "Documents";
export const TrustAuthorizationProcessServiceType = "TrustAuthorizationProcessService";

export const DDTCausalSaleType = 1;
export const DDTCausalTransferType = 3;
export const DetailedWorkedHours = 0;
export const AggregatedByResourceWorkedHours = 1;
export const AggregatedByRoleWorkedHours = 2;
export const DetailedPurchases = 0;
export const AggregatedPurchases = 1;

export const TrustAuthorizationRequestNotSent = 1;
export const TrustAuthorizationRequestSent = 2;
export const TrustAuthorizationRequestIgnored = 3;
export const TrustAuthorizationRequestDenied = 4;
export const TrustAuthorizationRequestOnEvaluation = 5;
export const TrustAuthorizationRequestAccepted = 6;
export const TrustAuthorizationRequestOnEvaluationByOther = 7;
export const TrustAuthorizationRequestSendingInProgress = 8;

export const IvaSettingKey = "IVA";
export const DocumentStateSettingKey = "State";
export const AppearanceSettingKey = "Appearance";
export const CauseSettingKey = "Cause";
export const TransportTypeSettingKey = "Transport";
export const DdtStateSettingKey = "DdtState";
export const DdtPortoSettingKey = "DdtPorto";
export const ShowCustomerOrderReferenceOnFinalDocumentSettingKey = "ShowCustomerOrderReferimentOnFinalDocument";
export const ShowExternalReferenceOnFinalDocumentSettingKey = "ShowExternalReferimentOnFinalDocument";
export const ExpireModeSettingKey = "ExpireMode";
export const ActiveExpireModeSettingKey = "ActiveExpireMode";
export const PassiveExpireModeSettingKey = "PassiveExpireMode";
export const PaymentModeSettingKey = "PaymentMode";
export const ActivePaymentModeSettingKey = "ActivePaymentMode";
export const PassivePaymentModeSettingKey = "PassivePaymentMode";
export const PaymentAccountSettingKey = "PaymentAccount";
export const ActivePaymentAccountSettingKey = "ActivePaymentAccount";
export const PassivePaymentAccountSettingKey = "PassivePaymentAccount";
export const PaymentABISettingKey = "PaymentABI";
export const ActivePaymentABISettingKey = "ActivePaymentABI";
export const PassivePaymentABISettingKey = "PassivePaymentABI";
export const PaymentCABSettingKey = "PaymentCAB";
export const ActivePaymentCABSettingKey = "ActivePaymentCAB";
export const PassivePaymentCABSettingKey = "PassivePaymentCAB";
export const PaymentOffsetSettingKey = "Offset";
export const WorkflowOutcomeSettingKey = "Outcome";
export const CurrencySettingKey = "Currency";
export const SendDocumentMailSettingKey = "SendDocumentMail";
export const IncludeDocumentAttachmentsMailSettingKey = "IncludeDocumentAttachmentsInMail";
export const MailTemplateSettingKey = "MailTemplate";

export const MonthlyInvoicingGenerationQueuedState = 0;
export const MonthlyInvoicingGenerationGeneratedState = 1;
export const MonthlyInvoicingGenerationNotGeneratedForInvalidTrustBalanceState = 2;
export const MonthlyInvoicingGenerationNotGeneratedForUnexpectedError = 3;
export const MonthlyInvoicingLogMailAborted = 4;
export const MonthlyInvoicingLogMailSendSuccess = 5;
export const MonthlyInvoicingLogMailSendPartial = 6;
export const MonthlyInvoicingLogMailSendError = 7;
export const MonthlyInvoicingLogMailSendRefused = 8;

export const MonthlyInvoicingLabelsSelectionCheckedState = 0;
export const MonthlyInvoicingLabelsSelectionUncheckedState = 1;
export const MonthlyInvoicingLabelsSelectionMixedState = 2;

export const EnabledProtocolState = 1;
export const DisabledProtocolState = 0;
export const HiddenProtocolState = 2;

export const VersionNumberGenerationModeKey = "VersionNumberGenerationMode";
export const DocumentNumberPrintChoiceKey = "DocumentNumberPrintChoice";

export const EuroCurrencyAlpha3Code = "EUR";

//Reports
export const ReportsServiceType = "ReportsService";

//Survey
export const SurveyApplicationCode = "Survey";
export const QuestionnaireState = "QuestionnaireState";
export const QuestionnaireCategory = "QuestionnaireCategory";
export const CampaignState = "CampaignState";
export const CampaignType = "CampaignType";
export const SampleState = "SampleState";
export const SampleType = "SampleType";
export const SurveyServiceType = "SurveyService";
export const CampaignServiceType = "CampaignService";
export const SampleServiceType = "SampleService";
export const PeopleServiceType = "PeopleService";

export const SingleAnswerQuestionType = 1;
export const MultipleAnswerQuestionType = 2;
export const OpenAnswerQuestionType = 3;

export const AnswerMoneyField = 2;
export const AnswerTextField = 1;
export const AnswerDateField = 3;
export const AnswerNumericField = 4;
export const AnswerBoolField = 5;

export const ImportActionSuccess = 3;
export const ImportActionPartial = 2;
export const ImportActionError = 1;

export const ImportFromExcel = "excel";
export const ImportFromExistingSample = "existingSample";
export const ImportByMetadataFiltering = "metadataFiltering";
export const ImportSingleContacts = "singleContacts";
export const PeopleGeneralDataTab = "general";
export const PeopleMetadataTab = "metadata";

export const StringDataType = "String";
export const IntegerDataType = "Int32";
export const FloatDataType = "Decimal";
export const DateDataType = "DateTimeOffset";
export const BooleanDataType = "Boolean";

//SurveyWizard
export const SurveyWizardServiceType = "SurveyWizardService";
export const SurveyWizardApplicationCode = "SurveyWizard";
export const CallResult = "CallResult";
export const QueueState = "QueueState";
export const ManualContactSelectionForWizard = 1;
export const AutomaticContactSelectionForWizard = 2;

export const WizardTestMode = 1;
export const WizardExecutionMode = 2;
export const InboundWizardExecutionMode = 3;

export const RecallMotivationType = 1;
export const ClosingMotivationType = 2;
export const SystemReservedClosingMotivationType = 3;

export const ToCallQueueState = 1;
export const InProgressQueueState = 2;
export const FinishedQueueState = 3;

export const DraftCampaignState = 1;
export const PlannedCampaignState = 2;
export const InProgressCampaignState = 3;
export const OverCampaignState = 4;

export const SuccesfullInterviewState = 6;
export const MaximumAttemptNumberExceded = 4;

//Warehouse
export const ArticlesServiceType = "ArticlesService";
export const ManufacturersServiceType = "ManufacturersService";
export const CatalogsServiceType = "CatalogsService";
export const FamiliesServiceType = "FamiliesService";
export const DiscountsServiceType = "DiscountsService";
export const WarehousesServiceType = "WarehousesService";
export const StockServiceType = "StockService";
export const OrdersServiceType = "OrdersService";
export const MovementServiceType = "MovementService";
export const WarehouseSettingsManager = "WarehouseSettingsManager";
export const WarehouseApplicationCode = "Warehouse";
export const WarehouseCodeGeneratorType = "WarehouseCodeGeneratorType";
export const WarehouseLoadReasonsForShipmentSettingsManager = "WarehouseLoadReasonsForShipmentSettingsManager";

//Files
export const FileRepositoryServiceType = "FileRepositoryService";
export const FileRepositoryUiServiceType = "FileRepositoryUiService";
export const TagType_DocumentReference = "FileRepositoryDocumentReference";
export const TagType_LastVersionReference = "FileRepositoryLastVersionReference";
export const CustomerResultType = "Rubrica";
export const FilesRepositoryResultType = "Files";
export const UserResultType = "Utenti";
export const JobOrderResultType = "Commesse";
export const FileRepositoryApplicationCode = "FileRepository";

//Blog
export const BlogServiceType = "BlogService";
export const TagType_String = "string";
export const TagType_Int = "int";
export const TagType_Bool = "bool";
export const TagType_DateTime = "DateTime";
export const TagType_Decimal = "Decimal";
export const TagType_Image = "Image";
export const Tag_CustomTag = "CustomTag";
export const Tag_AllowedUser = "AllowedUser";
export const Tag_Priority = "Priority";
export const Tag_State = "State";
export const Tag_Client = "Client";
export const Tag_Project = "Project";
export const Tag_JobOrder = "Commessa";
export const Tag_Expense = "Nota spese";
export const Tag_Billed = "Billed";
export const Tag_Sal = "Stato di avanzamento";
export const Tag_TracingQuality = "TracingQuality";
export const Tag_IsAdministration = "IsAdministration";
export const Tag_IsCommercial = "IsCommercial";
export const Tag_IsTechnical = "IsTechnical";
export const Tag_IsSigned = "IsSigned";
export const Tag_Signature = "Signature";
export const Tag_SignatureSigner = "SignatureSigner";
export const Tag_Locked = "Tag_Locked";
export const Tag_ReferenceDate = "ReferenceDate";
export const Tag_QualityType = "QualityType";
export const Tag_TodoListTask = "TodoListTask";
export const Tag_EventUser = "EventUser";
export const Tag_TracingQuality_true = "True";
export const Tag_TracingQuality_false = "False";
export const Tag_IsAdministration_true = "True";
export const Tag_IsAdministration_false = "False";
export const Tag_IsCommercial_true = "True";
export const Tag_IsCommercial_false = "False";
export const Tag_IsTechnical_true = "True";
export const Tag_IsTechnical_false = "False";
export const Tag_IsSigned_true = "True";
export const Tag_IsSigned_false = "False";
export const Tag_Priority_Normal = "Normale";
export const Tag_Priority_Low = "Bassa";
export const Tag_Priority_High = "Alta";
export const Tag_State_Undefined = "Non definito";
export const Tag_State_ToDo = "Da Fare";
export const Tag_State_InProgress = "In Lavorazione";
export const Tag_State_Done = "Fatto";
export const Tag_Subject = "Subject";
export const Tag_File = "File";
export const BlogApplicationCode = "Blog";

export const TagEmail_Subject = "MailSubject";
export const TagEmail_From = "MailFrom";
export const TagEmail_To = "MailTo";
export const TagEmail_Cc = "MailCc";
export const TagEmail_Ccn = "MailCcn";

export const TagSystem_Module = "Module";
export const TagSystem_Hyperlink = "Hyperlink";

export const TagMeeting_Duration = "Duration";
export const TagMeeting_Participant = "Partecipanti";
export const TagPhone_Duration = "Duration";
export const TagPhone_Participant = "Partecipanti";
export const TagWork_Duration = "Duration";
export const TagWork_Invoiced = "Invoiced";
export const TagWork_WorkTimeCategory = "WorkTimeCategory";
export const TagWork_Worker = "Worker";
export const TagWork_WorkerRole = "WorkerRole";
export const TagWorkHours_WorkerRole = "WorkerRole";
export const TagWorkHours_Worker = "Worker";
export const TagWorkHours_FromTime = "FromTime";
export const TagWorkHours_ToTime = "ToTime";
export const TagWorkHours_Billable = "Billable";
export const TagWorkHours_Duration = "Duration";
export const TagWorkHours_Overtime = "Overtime";
export const TagWorkHours_Hours = "Hours";
export const TagWorkHours_Breaks = "Breaks";
export const TagWorkHours_TravelDistance = "TravelDistance";
export const TagWorkHours_CallRight = "CallRight";
export const TagWorkHours_Sal = "Sal";
export const TagWork_Invoiced_true = "True";
export const TagWork_Invoiced_false = "False";
export const TagWork_WorkDate = "WorkDate";
export const TagMaterials_MaterialsDate = "MaterialsDate";
export const TagMaterials_Amount = "Amount";
export const TagMaterials_SalesAmount = "SalesAmount";
export const TagMaterials_Invoiced = "Invoiced";
export const TagMaterials_Invoiced_true = "True";
export const TagMaterials_Invoiced_false = "False";
export const BlogEventType_PhoneCall = "Blog.PhoneCall";
export const BlogEventType_Mail = "Blog.Mail";
export const BlogEventType_Comment = "Blog.Comment";
export const BlogEventType_Meeting = "Blog.Meeting";
export const BlogEventType_Milestone = "Blog.Milestone";
export const BlogEventType_Work = "Blog.Work";
export const BlogEventType_WorkedHours = "Blog.WorkedHours";
export const BlogEventType_Materials = "Blog.Materials";
export const BlogEventType_System = "Blog.System";
export const WorkedHoursEventType_WorkSheet = "WorkedHours.WorkSheet";
export const WorkedHoursEventType_WorkedHours = "WorkedHours.WorkedHours";
export const InvoiceEventType_Invoice = "Invoices.Invoice";
export const InvoiceEventType_Ddt = "Invoices.DDT";
export const InvoiceEventType_Estimate = "Invoices.Estimate";
export const InvoiceEventType_TrustAuthorization = "Invoices.TrustAuthorization";
export const WarehouseEventType_CustomerOrder = "Warehouse.CustomerOrder";
export const WarehouseEventType_SupplierOrder = "Warehouse.SupplierOrder";
export const WarehouseEventType_WarehouseLoad = "Warehouse.WarehouseLoad";
export const ExpenseEventType_Expense = "Expense.Expense";

//Tipi di eventi logici di blog configurabili da protocollo
export const BlogEventForProtocol_GeneratedDocument = 0;
export const BlogEventForProtocol_ScheduleStatusChanged = 1;

//Tipologie di documento
export const InvoiceOrDdtTypeId = 0;
export const DdtTypeId = 1;
export const EstimateTypeId = 3;
export const CustomerOrderTypeId = 5;
export const SalTypeId = 6;
export const SupplierOrderTypeId = 7;
export const WarehouseLoadTypeId = 8;
export const RequestForQuotationTypeId = 11;
export const PurchaseRequestTypeId = 12;
export const PassiveInvoiceTypeId = 13;

//Tipologie di documento fiscale (fattura, nota di credito o fattura accompagnatoria)
export const InvoiceDocumentType = 0;
export const AccompanyingInvoiceDocumentType = 1;
export const CreditNoteDocumentType = 2;

//Codici tipologie di entità
export const InvoiceEntityTypeCode = "DOC";
export const PassiveInvoiceEntityTypeCode = "PDO";
export const AccompanyingInvoiceEntityTypeCode = "DOA";
export const CreditNoteEntityTypeCode = "NDC";
export const DdtEntityTypeCode = "DDT";
export const EstimateEntityTypeCode = "EST";
export const CustomerOrderEntityTypeCode = "COR";
export const SalEntityTypeCode = "SAL";
export const WorkedHoursEntityTypeCode = "WRK";
export const EstimatedWorkEntityTypeCode = "EWK";
export const EstimatedCallRightTypeCode = "ECR";
export const EstimatedPurchaseEntityTypeCode = "EPC";
export const PurchasesEntityTypeCode = "PRC";
export const WarehouseArticleEntityTypeCode = "WAR";
export const WarehousePurchaseArticleEntityTypeCode = "WPC";
export const SupplierOrderEntityTypeCode = "SOR";
export const WarehouseEntityTypeCode = "WHR";
export const WarehouseLoadEntityTypeCode = "WHL";
export const WarehouseUnloadEntityTypeCode = "WHU";
export const WarehouseTransferEntityTypeCode = "WHT";
export const ProjectEntityTypeCode = "PRJ";
export const WorkItemEntityTypeCode = "WIT";
export const JobOrderTaskEntityTypeCode = "JTK";
export const TaskBoardTaskEntityTypeCode = "TBT";
export const RemindEntityTypeCode = "RMD";
export const TemplateTaskEntityTypeCode = "TTK";
export const JobOrderActivityEntityTypeCode = "ATK";
export const JobOrderEntityTypeCode = "JOB";
export const JobOrderEntityTypeCode_ALT = "JOR";
export const WorkflowEntityTypeCode = "WFL";
export const WorkflowTemplateEntityTypeCode = "WTL";
export const ScheduleEntityTypeCode = "SCD";
export const BlogEventEntityTypeCode = "BEV";
export const SurveyEntityTypeCode = "SUR";
export const HumanResourceEntityTypeCode = "HRS";
export const RequestForQuotationEntityTypeCode = "RFQ";
export const PurchaseRequestEntityTypeCode = "RDA";
export const FlexibilityEntityTypeCode = "FLX";

//Codici tipologie update settings
export const SettingsUpdateType_DocumentsProtocols = "DocumentsProtocols";

//TodoList
export const TaskStateChangeEventType = "Todolist.TaskStateChange";
export const TagType_TaskBoardStatus = "TaskBoardStatus";
export const Tag_TaskStateChange_OldState = "OldState";
export const Tag_TaskStateChange_NewState = "NewState";
export const Tag_TaskStateChange_TaskName = "TaskName";

export const TaskDataChangeEventType = "Todolist.TaskDataChange";
export const Tag_TaskDataChange_Title = "Title";
export const Tag_TaskDataChange_StartDate = "StartDate";
export const Tag_TaskDataChange_DueDate = "DueDate";
export const Tag_TaskDataChange_Resources = "Resources";

export const WorkflowStateChangeEventType = "Todolist.WorkflowStateChange";
export const TagType_WorkflowStatus = "WorkflowStatus";
export const Tag_WorkflowStateChange_OldState = "WorkflowOldState";
export const Tag_WorkflowStateChange_NewState = "WorkflowNewState";
export const Tag_WorkflowStateChange_OldOutcome = "WorkflowOldOutcome";
export const Tag_WorkflowStateChange_NewOutcome = "WorkflowNewOutcome";
export const Tag_WorkflowStateChange_OldEstimate = "WorkflowOldEstimate";
export const Tag_WorkflowStateChange_NewEstimate = "WorkflowNewEstimate";
export const Tag_WorkflowStateChange_WorkflowName = "WorkflowWorkflowName";
export const Tag_WorkflowStateChange_WorkflowId = "WorkflowWorkflowId";
export const Tag_WorkflowStateChange_WorkflowCreated = "WorkflowCreated";
export const Tag_WorkflowStateChange_WorkflowDeleted = "WorkflowDeleted";
export const Tag_WorkflowStateChange_WorkflowStateChangeId = "WorkflowStateChangeId";

//JobOrder
export const GitCommitEventType = "JobOrder.GitCommit";
export const JobOrderStatusChangeEventType = "JobOrder.JobOrderStateChange";
export const SalEventType = "JobOrder.Sal";
export const JobOrderCodeConstraint = "AK_JOBORDER_Code";

//Allocations
export const AllocationsServiceCode = "Allocations";
export const CartsServiceCode = "Carts";
export const AllocationsApplicationCode = "Allocations";
export const WorkflowCartElementType = 0;
export const TaskCartElementType = 1;
export const CartStatus = "CartStatus";
export const CartOpendStatus = 1;
export const CartClosedStatus = 2;
export const RealAllocation = 0;
export const TheoreticalAllocation = 1;
export const RealWorkedHoursType = 1;
export const AllocationHoursType = 0;
export const PercentageEditigMode = 1;
export const HoursEditigMode = 2;
export const ServiceOrderEditigMode = 3;
export const IntervalEditigMode = 4;
export const ResourceAllocationByPercentage = 1;
export const ResourceAllocationByHours = 2;
export const TeamFirstRenderer = 1;
export const ResourceFirstRenderer = 2;
export const CartsAllocationsRenderer = 3;

//Taskboard Pages Identifier (mobile version)
export const Page_Projects = "Projects";
export const Page_Plans = "Plans";
export const Page_Taskboard = "Taskboard";

//WorkedHours Pages Identifier (mobile version)
export const Page_Calendar = "Calendar";
export const Page_SelectActivity = "SelectActivity";
export const Page_ModifyActivity = "ModifyActivity";
export const Page_Suggestions = "Suggestions";

//BlogBookLet Pages Identifier (mobile version)
export const Page_ProjectBlogBookLet = "ProjectBlogBookLet";
export const Page_BlogBookLet = "BlogBookLet";
export const Page_ModifyBlog = "ModifyBlog";
export const Page_NewEvent = "NewEvent";

//Provisioning
export const ProvisioningApplicationCode = "Provisioning";
export const ProvisioningApplicationRoute = "#/Provisioning";
export const ProvisioningPreOrdersRoute = "#/Provisioning/PreOrders";
export const ProvisioningRequestsForQuotationsRoute = "#/Provisioning/RequestsForQuotations";
export const ProvisioningSupplierOrdersRoute = "#/Provisioning/SupplierOrders";
export const ProvisioningServiceCode = "ProvisioningService";
export const PreOrdersPanelId = 1;
export const OrdersRequestsPanelId = 2;
export const SupplierOrdersPanelId = 3;

//Quotation
export const QuotationApplicationRoute = "#/Quotation";
export const QuotationApplicationCode = "Quotation";

//Agenda
export const AgendaServiceCode = "AgendaService";
export const GoogleCalendarServiceType = "GoogleCalendarService";
export const FestivitiesAgendaServiceCode = "FestivitiesAgendaService";
export const EventsServiceCode = "EventsService";
export const FestivitiesServiceCode = "FestivitiesService";
export const FolderServiceCode = "FolderService";
export const AgendaApplication = "Agenda";
export const EventsCategoriesSettingsManager = "EventsCategoriesSettings";
export const EventCanceledMotivationsSettingsManager = "EventCanceledMotivationsSettingsManager";

export const FestivityCategoryId = 1;
export const ClosingDaysCategoryId = 2;

export const LogicalStateNotSet = 0;
export const PlannedEventState = 1;
export const ConfirmedEventState = 2;
export const WaitingListEventState = 3;
export const AbortedEventState = 4;

export const NotDeletedState = 0;
export const DeletedState = 1;
export const AgendaDeletedState = 2;
export const FolderDeletedStateForAgenda = 2;
export const FolderDeletedStateForEvent = 3;

export const AgendasEventsCustomersEntityType = "EventCustomer";
export const AgendaEntityType = "Agenda";
export const AgendaEventCategoryEntityType = "EventCategory";

export const TagAgendaEvent_Title = "EventTitle";
export const TagAgendaEvent_EventCategory = "EventCategory";
export const TagAgendaEvent_EventAgenda = "Agenda";
export const TagAgendaEvent_EventCustomer = "EventCustomer";
export const TagAgendaEvent_EventPlace = "EventPlace";
export const TagAgendaEvent_EventNotes = "EventNotes";
export const TagAgendaEvent_EventStart = "EventStart";
export const TagAgendaEvent_EventEnd = "EventEnd";

export const AgendaEntityTypeCode = "AGN";
export const FolderEntityTypeCode = "FLD";
export const AgendaEventEntityTypeCode = "AGE";
export const AgendaFestivityEntityTypeCode = "AGF";

export const AgendaEventDefaultColor = "#428bca";

//Calendar options
export const CalendarTitle = "title";
export const CalendarPrevButton = "prev";
export const CalendarNextButton = "next";
export const CalendarPrevYearButton = "prevYear";
export const CalendarNextYearButton = "nextYear";
export const CalendarTodayButton = "today";

export const CalendarMonthView = "month";
export const CalendarBasicWeekView = "basicWeek";
export const CalendarBasicDayView = "basicDay";
export const CalendarAgendaWeekView = "agendaWeek";
export const CalendarAgendaDayView = "agendaDay";
export const CalendarListYearView = "listYear";
export const CalendarListMonthView = "listMonth";
export const CalendarListWeekView = "listWeek";
export const CalendarListDayView = "listDay";

// Regimi fiscali
export const RF01_Ordinary = "RF01";
export const RF02_MinimumTaxPayer = "RF02";
export const RF04_FarmingFishingAndRelatedActivities = "RF04";
export const RF05_SaltAndTobacco = "RF05";
export const RF06_MatchstickTrade = "RF06";
export const RF07_PublishingIndustry = "RF07";
export const RF08_PublicTelephonyServices = "RF08";
export const RF09_PublicTransportDocumentsReselling = "RF09";
export const RF10_EntertainmentGamesAndRelatedActivities = "RF10";
export const RF11_TravelAgency = "RF11";
export const RF12_Agritourism = "RF12";
export const RF13_DoorToDoorSales = "RF13";
export const RF14_AntiqueSecondHandGoodsAndWorksOfArtReselling = "RF14";
export const RF15_AntiqueSecondHandGoodsAndWorksOfArtAuctioningReselling = "RF15";
export const RF16_CashAccountingPA = "RF16";
export const RF17_CashAccounting = "RF17";
export const RF18_Other = "RF18";
export const RF19_FlatRateScheme = "RF19";

// Codici modalità pagamento per fatture elettroniche
export const MP01_Cash = "MP01";
export const MP02_BankCheck = "MP02";
export const MP03_CashiersCheck = "MP03";
export const MP04_OperatingCash = "MP04";
export const MP05_CreditTransfer = "MP05";
export const MP06_MoneyOrder = "MP06";
export const MP07_BankBulletin = "MP07";
export const MP08_PaymentCard = "MP08";
export const MP09_Rid = "MP09";
export const MP10_RidUtilities = "MP10";
export const MP11_RidFast = "MP11";
export const MP12_Riba = "MP12";
export const MP13_Mav = "MP13";
export const MP14_StateTreasuryDischarge = "MP14";
export const MP15_GiroPayments = "MP15";
export const MP16_BankDirectDebit = "MP16";
export const MP17_PostalDirectDebit = "MP17";
export const MP18_PostalBulletin = "MP18";
export const MP19_SepaDirectDebit = "MP19";
export const MP20_SepaDirectDebitCore = "MP20";
export const MP21_SepaDirectDebitB2B = "MP21";
export const MP22_RetentionOnAlreadyColectedAmounts = "MP22";

//Codici natura operazione per esenzione IVA
export const N1_ExcludedExArt15 = "N1";
export const N2_NotSubject = "N2";
export const N3_NotTaxable = "N3";
export const N3_5_NotTaxableForLetterOfAttempt = "N3.5";
export const N4_VatExempt = "N4";
export const N5_MarginScheme = "N5";
export const N6_ReverseCharge = "N6";
export const N7_VatIncurred = "N7";

//Esigibilità IVA
export const ImmediateVatChargeability = "I";
export const PostponedVatChargeability = "D";
export const SplitPaymentVatChargeability = "S";

//Nazioni e province
export const NationsSettings = "NationsSettings";
export const ProvincesSettings = "ProvincesSettings";

// Tipologia di avanzamento attività
export const WorkedAmountMode = 1;
export const WorkedHoursMode = 0;

// Tipologie di configurazione (sono per modulo, quindi ci possono essere duplicati (è un troiaio))
/* Agenda */
export const UserPredefinedCategoryConfigurationType = 1;
/* --- */

/* Allocazioni */
export const AdvancedFiltersConfigAllocationType = 1;
export const CartInfoDialogConfigType = 2;
/* --- */

/* JobOrders */
export const JobOrderSectionsConfigurationType = 1;
/* --- */

/* Todolist */
export const ActivityDetailsDialogConfigType = 1;
export const WorkflowDetailsDialogConfigType = 2;
export const WorkflowRelatedDocumentsConfigType = 3;
/* --- */

/* ProlifeSdk (?? è usata in un componente generico) */
export const KeyboardPanelConfigurationType = 5;
export const ProLifeTableConfigurationType = 6;
/* --- */

/* Id tabelle per gestione configurazioni colonne */
export const DocumentWithMetadataTable = 75;
export const DocumentResourcesTable = 120;
export const AllocatedResourcesTable = 130;
export const JobOrderDocumentsTable = 140;
export const DocumentTaxReliefTable = 150;
export const PlanningBudgetViewerTable = 160;
export const PlanningSnapshotTable = 170;
export const WorkflowRelatedDocumentsTable = 180;
export const AutomaticContactSelectionTable = 190;
export const DocumentResourceAssignmentDetailsTable = 200;
export const WorkflowsAnalysis = 210;
export const MetadataListValuesTable = 220;
/* --- */

/// <reference path="prolifesdk/DocumentsUtilities.ts" />
/// <reference path="prolifesdk/documents/GenericDocument.ts" />
/// <reference path="prolifesdk/documents/DocumentsProvider.ts" />
/// <reference path="prolifesdk/documents/DocumentStatesProvider.ts" />
/// <reference path="prolifesdk/documents/wizard/steps/ReportStepBase.ts" />
/// <reference path="prolifesdk/documents/wizard/steps/WarehouseArticleTransformStep.ts" />
/// <reference path="prolifesdk/documents/wizard/MonthlyInvoicingDataRow.ts" />
/// <reference path="prolifesdk/RegisterDocument.ts" />
/// <reference path="prolifesdk/ProLifeService.ts" />
/// <reference path="prolifesdk/ApplicationsConfigurationsService.ts" />
/// <reference path="prolifesdk/WizardStep.ts" />
/// <reference path="prolifesdk/ImportDataWizardSelectSourcesStep.ts" />
/// <reference path="prolifesdk/ImportDataWizardDataSourceStep.ts" />
/// <reference path="prolifesdk/WizardDialog.ts" />
/// <reference path="prolifesdk/DocumentDataSource.ts" />
/// <reference path="prolifesdk/MultipleEntitiesProvider.ts" />

/// <reference path="prolifesdk/providers/GroupedEntitiesProvider.ts" />
/// <reference path="prolifesdk/providers/TemplatesProvider.ts" />
/// <reference path="prolifesdk/providers/NationsProvider.ts" />
/// <reference path="prolifesdk/providers/ProvincesProvider.ts" />

/// <reference path="prolifesdk/reports/ProLifeReport.ts" />
/// <reference path="prolifesdk/reports/ReportColumnsInfoDialog.ts" />

/// <reference path="prolifesdk/settings/EntityCodeGeneratorSettingsManager.ts" />
/// <reference path="prolifesdk/settings/EntityCodeGeneratorEditingViewModel.ts" />

/// <reference path="prolifesdk/documents/DocumentRefDetailsViewModelFactory.ts" />
/// <reference path="prolifesdk/documents/ReferencesDetailsBalloon.ts" />
/// <reference path="prolifesdk/documents/DocumentRow.ts" />
/// <reference path="prolifesdk/documents/DocumentExportTypeDialog.ts" />
/// <reference path="prolifesdk/documents/converters/RefConverterBase.ts" />
/// <reference path="prolifesdk/documents/events/DocumentEvent.ts" />
/// <reference path="prolifesdk/documents/events/details/DocumentEventDetailsEdit.ts" />
/// <reference path="prolifesdk/documents/default-values/DefaultValuesSettingsUi.ts" />

/// <reference path="prolifesdk/job-order/JobOrderDocumentsContainer.ts" />
/// <reference path="prolifesdk/controls/behaviours/EntitiesListNotificationsManager.ts" />
/// <reference path="prolifesdk/controls/behaviours/EntityNotificationsManager.ts" />
/// <reference path="prolifesdk/controls/behaviours/EntitiesListLockManager.ts" />
/// <reference path="prolifesdk/controls/behaviours/EntityLockManager.ts" />
/// <reference path="prolifesdk/controls/navigation/NavigationMenu.ts" />
/// <reference path="prolifesdk/controls/navigation/NavigationProvider.ts" />
/// <reference path="prolifesdk/controls/lists/ListView.ts" />
/// <reference path="prolifesdk/controls/treegrid/TreeGrid.ts" />
/// <reference path="prolifesdk/controls/gantt/PlannerGantt.ts" />
/// <reference path="prolifesdk/controls/gantt/PlannerGanttItem.ts" />
/// <reference path="prolifesdk/utils/EventHandler.ts" />
/// <reference path="prolifesdk/utils/Proxy.ts" />

/// <reference path="prolifesdk/blog/Attachment.ts" />
/// <reference path="prolifesdk/blog/Tag.ts" />
/// <reference path="prolifesdk/blog/EventBaseInput.ts" />
/// <reference path="prolifesdk/blog/BlogEventBase.ts" />
/// <reference path="prolifesdk/blog/CommentInput.ts" />
/// <reference path="prolifesdk/blog/CommentBlogEvent.ts" />
/// <reference path="prolifesdk/blog/BlogEvent.ts" />
/// <reference path="prolifesdk/blog/CustomTag.ts" />
/// <reference path="prolifesdk/blog/EventClassificationInput.ts" />
/// <reference path="prolifesdk/blog/BlogEventBaseInput.ts" />
/// <reference path="prolifesdk/PageSwitcher.ts" />
/// <reference path="prolifesdk/ContextMenuDialog.ts" />
