import * as ko from "knockout";
import * as moment from "moment";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import { DateTimeInput } from "./DateTimeInput";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

type DateRangeInputProps = {
    title?: string;
    className?: string;
    startDate: ko.Observable<Date>;
    endDate: ko.Observable<Date>;

    readOnly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean> | (() => boolean);
    onDateRangeChanges?: (from: Date, to: Date) => void;

    datePickerParent?: string;
};

export function DateRangeInput(props: DateRangeInputProps) {
    const C = require("./DateRangeInput")._DateRangeInput as typeof _DateRangeInput;
    return <C {...props} />;
}

export class _DateRangeInput {
    static defaultProps: Partial<DateRangeInputProps> = {
        className: "",
    };

    private subscriptions: ko.Subscription[] = [];
    private workingOnStartDate = false;
    private workingOnEndDate = false;
    private lastStartDate: Date = null;
    private lastEndDate: Date = null;

    constructor(private props: DateRangeInputProps) {
        if (!this.props.startDate) this.props.startDate = ko.observable();
        if (!this.props.endDate) this.props.endDate = ko.observable();
    }

    componentDidMount() {
        const initialStartDate = this.props.startDate();
        const initialEndDate = this.props.endDate();

        if (initialStartDate) {
            const startDate = moment(initialStartDate).startOf("day").toDate();
            this.props.startDate(startDate);
            this.lastStartDate = startDate;
        }

        if (initialEndDate) {
            const endDate = moment(initialEndDate).endOf("day").toDate();
            this.props.endDate(endDate);
            this.lastEndDate = endDate;
        }

        this.subscriptions.push(this.props.startDate.subscribe(this.onStartDateChanges.bind(this)));
        this.subscriptions.push(this.props.endDate.subscribe(this.onEndDateChanges.bind(this)));
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];
    }

    render() {
        return ComponentUtils.bindTo(
            <div className={"row " + this.props.className}>
                <div className="col-md-12">
                    {this.props.title && <label>{this.props.title}</label>}
                    <div className="row">
                        <div className="col-md-6">
                            <DateTimeInput
                                value={this.props.startDate}
                                label={TextResources.ProlifeSdk.DateRangePickerStartDateLabel}
                                placeholder={TextResources.ProlifeSdk.DateRangePickerStartDatePlaceholder}
                                dateonly
                                allowClear
                                readonly={this.props.readOnly}
                                parent={this.props.datePickerParent}
                            />
                        </div>
                        <div className="col-md-6">
                            <DateTimeInput
                                value={this.props.endDate}
                                label={TextResources.ProlifeSdk.DateRangePickerEndDateLabel}
                                placeholder={TextResources.ProlifeSdk.DateRangePickerEndDatePlaceholder}
                                dateonly
                                allowClear
                                readonly={this.props.readOnly}
                                parent={this.props.datePickerParent}
                            />
                        </div>
                    </div>
                </div>
            </div>,
            this
        );
    }

    private onStartDateChanges(date: Date) {
        if (this.workingOnStartDate) return;

        const startOfDay = !date ? null : moment(date).startOf("day");
        if (this.lastStartDate && startOfDay && moment(this.lastStartDate).isSame(startOfDay)) return;

        this.workingOnStartDate = true;

        this.props.startDate(startOfDay?.toDate());
        this.lastStartDate = this.props.startDate();

        this.props.onDateRangeChanges && this.props.onDateRangeChanges(this.lastStartDate, this.lastEndDate);

        const endDate = this.props.endDate();
        if (endDate && startOfDay && startOfDay.isAfter(moment(endDate).endOf("day")))
            this.props.endDate(moment(startOfDay).endOf("day").toDate());

        this.workingOnStartDate = false;
    }

    private onEndDateChanges(date: Date) {
        if (this.workingOnEndDate) return;

        const endOfDay = !date ? null : moment(date).endOf("day");
        if (this.lastEndDate && endOfDay && moment(this.lastEndDate).isSame(endOfDay)) return;

        this.workingOnEndDate = true;

        this.props.endDate(endOfDay?.toDate());
        this.lastEndDate = this.props.endDate();

        this.props.onDateRangeChanges && this.props.onDateRangeChanges(this.lastStartDate, this.lastEndDate);

        const startDate = this.props.startDate();
        if (startDate && endOfDay && endOfDay.isBefore(moment(startDate).startOf("day")))
            this.props.startDate(moment(endOfDay).startOf("day").toDate());

        this.workingOnEndDate = false;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(DateRangeInput);
}
