import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { CostsAndRevenuesProvider } from "../../../CostsAndRevenuesProvider";
import { CostsDashboard } from "../CostsDashboard";
import { EstimatedRevenues } from "../EstimatedRevenues";
import { RevenuesDashboard } from "../RevenuesDashboard";
import { WorkflowEconomicsDialog } from "../WorkflowEconomicsDialog";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import {
    IJobOrderEditorPanelFactory,
    IJobOrderEditor,
    IJobOrderEditorPanel,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IJobOrderDocumentsContainer } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderDocumentsContainer";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IWorkflowRevenue } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowRevenue";

export type CostMode = "reestimated" | "estimated";
export type RevenuesMode = "allOrders" | "assignedOrders";

export class JobOrderCostsRevenuesFactory implements IJobOrderEditorPanelFactory {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    constructor(public Color: string) {}

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderCostsRevenues(editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewCostsDashboard");
    }
}

class JobOrderCostsRevenues implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.CostsRevenues);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "business";

    private costsAndRevenuesProvider: CostsAndRevenuesProvider;
    public costsDashboard: CostsDashboard;
    public revenuesDashboard: RevenuesDashboard;
    public EstimatedRevenuesSection: EstimatedRevenues;

    private selectedDocumentsSection: ko.Observable<IJobOrderDocumentsContainer> = ko.observable();
    public documentsSections: ko.ObservableArray<IJobOrderDocumentsContainer> = ko.observableArray([]);

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    public ShowWorkflowDetails: ko.Observable<boolean> = ko.observable(false);
    public WorkflowRows: ko.ObservableArray<IWorkflowRevenue> = ko.observableArray([]);

    public CostsMode: ko.Observable<CostMode> = ko.observable("reestimated");
    public RevenuesMode: ko.Observable<RevenuesMode> = ko.observable("allOrders");

    public SelectedWorkflowAmountsBiggerThanTotal: ko.Computed<boolean>;
    public SelectedWorkflowAmountsLowerThanTotal: ko.Computed<boolean>;
    public SelectedWorkflowEstimatedAmountsBiggerThanTotal: ko.Computed<boolean>;
    public SelectedWorkflowEstimatedAmountsLowerThanTotal: ko.Computed<boolean>;

    private selectedWorkflow: number = null;
    private disableRevenuesObserver = false;

    constructor(private editor: IJobOrderEditor, public Color: string) {
        this.costsAndRevenuesProvider = new CostsAndRevenuesProvider();
        this.costsDashboard = new CostsDashboard(this.costsAndRevenuesProvider);
        this.revenuesDashboard = new RevenuesDashboard(
            this.costsAndRevenuesProvider,
            this.CostsMode,
            this.RevenuesMode
        );
        this.EstimatedRevenuesSection = new EstimatedRevenues(this.revenuesDashboard);

        this.SelectedWorkflowEstimatedAmountsBiggerThanTotal = ko.computed(() => {
            return this.EstimatedRevenuesSection.WorkflowTotal() > this.EstimatedRevenuesSection.Total();
        });

        this.SelectedWorkflowEstimatedAmountsLowerThanTotal = ko.computed(() => {
            return this.EstimatedRevenuesSection.WorkflowTotal() < this.EstimatedRevenuesSection.Total();
        });

        this.SelectedWorkflowAmountsBiggerThanTotal = ko.computed(() => {
            return this.costsAndRevenuesProvider.TotalRevenues() > this.costsAndRevenuesProvider.CachedTotalRevenues();
        });

        this.SelectedWorkflowAmountsLowerThanTotal = ko.computed(() => {
            return this.costsAndRevenuesProvider.TotalRevenues() < this.costsAndRevenuesProvider.CachedTotalRevenues();
        });

        this.CostsMode.subscribe(() => {
            this.costsDashboard.onDataLoaded();
            this.revenuesDashboard.onDataLoaded();
        });

        this.RevenuesMode.subscribe(() => {
            if (this.disableRevenuesObserver) return;
            this.costsDashboard.onDataLoaded();
            this.revenuesDashboard.onDataLoaded();
        });
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {}

    public getExcelExportData() {
        return {
            jobOrderId: this.editor.JobOrderId,
            workflows: [],
        };
    }

    @Delay()
    async load(): Promise<void> {
        this.dialogService.LockUI();

        try {
            await this.costsAndRevenuesProvider.loadJobOrderData(
                this.editor.JobOrder().jobOrder,
                this.selectedWorkflow
            );
            await this.EstimatedRevenuesSection.Initialize(this.editor.JobOrderId, this.selectedWorkflow);
        } catch (e) {
            console.log(e);
        }

        this.dialogService.UnlockUI();
    }

    isDefaultOnNew(): boolean {
        return false;
    }

    isDefault(): boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel(): Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(true);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.selectedWorkflow = null;
        this.disableRevenuesObserver = true;
        this.WorkflowRows([]);
        this.RevenuesMode("assignedOrders");
        this.disableRevenuesObserver = false;

        this.selectedWorkflow = model.id as number;
        this.todoListService.GetWorkflowsRevenuesForWorkflow(this.selectedWorkflow).then((rows: IWorkflowRevenue[]) => {
            this.WorkflowRows(rows.filter((r) => !r.IsEstimatedRevenue));
        });

        this.ShowWorkflowDetails(!!this.selectedWorkflow);

        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        this.selectedWorkflow = null;
        this.load();
    }

    OnWorkflowSelectionChanged(selection: number[]) {
        this.selectedWorkflow = null;
        this.WorkflowRows([]);

        if (selection.length > 0) {
            this.selectedWorkflow = selection[0];
            this.todoListService
                .GetWorkflowsRevenuesForWorkflow(this.selectedWorkflow)
                .then((rows: IWorkflowRevenue[]) => {
                    this.WorkflowRows(rows.filter((r) => !r.IsEstimatedRevenue));
                });
        }

        this.ShowWorkflowDetails(!!this.selectedWorkflow);

        this.load();
    }

    OnWorkflowDeleted(workflowId: number) {
        this.OnWorkflowOperation();
    }
    OnWorkflowChanged(workflowId: number) {
        this.OnWorkflowOperation();
    }
    OnWorkflowCreated(workflowId: number) {
        this.OnWorkflowOperation();
    }
    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    OnWorkflowImportedFromTemplate(workflowId: number) {}

    private OnWorkflowOperation() {
        this.editor.reload();
    }

    public EditWorkflowEconomics() {
        const vm = new WorkflowEconomicsDialog(
            this.editor.JobOrderId,
            this.EstimatedRevenuesSection.Total(),
            this.costsAndRevenuesProvider.CachedTotalRevenues()
        );

        this.dialogService
            .ShowModal<boolean>(vm, "fullscreen", {}, vm.templateUrl, vm.templateName)
            .then((result: boolean) => {
                if (!result) return;
                this.load();
            });
    }
}
