import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { DialogComponentBase } from "../../../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { DocumentImportModes } from "./DocumentImportModes";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type RowsImportModeDialogProps = {
}

export class RowsImportModeDialog extends DialogComponentBase {
    static defaultProps: Partial<RowsImportModeDialogProps> = {
    }

    constructor(private props : RowsImportModeDialogProps) {
        super({});

        this.title(TextResources.Invoices.DocumentAlreadyClosed)
    }
    
    close(): void {
        this.modal.close(DocumentImportModes.NoImport);
    }

    action(): void {
        this.modal.close(DocumentImportModes.UnitAmount);
    }
    
    importOnlyDescriptions(): void {
        this.modal.close(DocumentImportModes.OnlyDescriptions);
    }

    renderBody() {
        return ComponentUtils.bindTo((
            <>{TextResources.Invoices.ImportRowWarning}</>
        ), this, "modeDialog");
    }

    renderFooter() {
        const modeDialog = this;

        return ComponentUtils.bindTo((
            <>
                <button className="btn btn-default" data-bind={{ click: modeDialog.close.bind(modeDialog) }}>
                    {TextResources.Invoices.ImportRowWarningCancel}
                </button>
                <button className="btn btn-primary" data-bind={{ click: modeDialog.importOnlyDescriptions.bind(modeDialog) }}>
                    {TextResources.Invoices.ImportRowWarningOnlyDescriptions}
                </button>
                <button className="btn btn-primary" data-bind={{ click: modeDialog.action.bind(modeDialog) }}>
                    {TextResources.Invoices.ImportRowWarningImportWithUnitAmount}
                </button>
            </>
        ), this, "modeDialog") as React.ReactElement;
    }
}