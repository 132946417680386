import * as ko from "knockout";
import { MergedReportComponentConstructor, MergeParams, ReportComponent, ReportComponentConstructor, Tail } from "./ReportComponent";
import { Component } from "./ReportComponentProvider";
import { HasBackground } from "./ReportComponentWithBackground";
import { ReportComponentDataSource, ReportComponentDataSourceProps } from "./ReportComponentWithDataSource";
import { HasFont } from "./ReportComponentWithFont";
import { HasPosition } from "./ReportComponentWithPosition";
import { HasVisibility } from "./ReportComponentWithVisibility";
import { ReportDataBoundValue } from "./ReportDataBoundValue";

type TextAlign = "left" | "center" | "right" | "justify";
type VerticalAlign = "top" | "center" | "bottom";
export interface ReportComponentWithText { 
    text: ko.Observable<string>;
    textAlign: ko.Observable<TextAlign>;
    verticalAlign: ko.Observable<VerticalAlign>;

    boundText: ReportDataBoundValue<string>;
}

export type ReportComponentWithTextProps = { 
    text: string, 
    textAlign?: TextAlign, 
    verticalAlign?: VerticalAlign, 
    boundText?: { 
        dataSource: ReportComponentDataSourceProps, 
        boundExpression: string, 
        compiledBoundExpression: string, 
        bound: boolean 
    } 
}

function HasText<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithText, ReportComponentWithTextProps> {
    return class ReportComponentWithText extends Base {
        constructor(...rest: any[]) {
            super(...rest);
            this.features = [...this.features, "Text"];

            const { text, textAlign, verticalAlign, boundText } = rest[0];
            this.text(text);
            this.textAlign(textAlign ?? "left");
            this.verticalAlign(verticalAlign ?? "top");

            this.boundText = new ReportDataBoundValue(boundText?.dataSource, boundText?.boundExpression, boundText?.compiledBoundExpression, boundText?.bound);
        }

        getData() {
            const data = super.getData();
            return {
                ...data,
                text: this.text(),
                textAlign: this.textAlign(),
                verticalAlign: this.verticalAlign(),
                boundText: {
                    dataSource: this.boundText.dataSource.getData(),
                    boundExpression: this.boundText.boundExpression,
                    compiledBoundExpression: this.boundText.compiledBoundExpression,
                    bound: this.boundText.bound()
                }
            }
        }

        text: ko.Observable<string> = ko.observable();
        textAlign: ko.Observable<TextAlign> = ko.observable();
        verticalAlign: ko.Observable<VerticalAlign> = ko.observable();

        boundText: ReportDataBoundValue<string>;
    }
}

@Component(nameof<ReportText>())
export class ReportText extends HasFont(HasText(HasBackground(HasVisibility(HasPosition(ReportComponent))))) {
    get type() { return nameof<ReportText>(); }
}