import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/07/2017
 * Time: 12:56
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { IException } from "../../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IPeopleForList, IPeople, IPeopleMetadata } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IProvincesSettingsManager } from "../../../../ProlifeSdk/interfaces/settings/IProvincesSettingsManager";
import { INationsSettingsManager } from "../../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";

export class PeopleForList {
    public Id: number;
    public BusinessName: ko.Observable<string> = ko.observable();
    public Name: ko.Observable<string> = ko.observable();
    public Surname: ko.Observable<string> = ko.observable();
    public Address: ko.Observable<string> = ko.observable();
    public CAP: ko.Observable<string> = ko.observable();
    public City: ko.Observable<string> = ko.observable();
    public Municipality: ko.Observable<string> = ko.observable();
    public Province: ko.Observable<string> = ko.observable();
    public Country: ko.Observable<string> = ko.observable();
    public FiscalCode: ko.Observable<string> = ko.observable();
    public PIVA: ko.Observable<string> = ko.observable();
    public IsCompany: ko.Observable<boolean> = ko.observable();

    public SourceDescription: string;
    public ImportDate: Date;

    constructor(peopleForList: IPeopleForList) {
        this.Id = peopleForList.Id;
        this.BusinessName(peopleForList.BusinessName);
        this.Name(peopleForList.Name);
        this.Surname(peopleForList.Surname);
        this.Address(peopleForList.Address);
        this.CAP(peopleForList.CAP);
        this.City(peopleForList.City);
        this.Municipality(peopleForList.Municipality);
        this.Province(peopleForList.Province);
        this.Country(peopleForList.Country);
        this.FiscalCode(peopleForList.FiscalCode);
        this.PIVA(peopleForList.PIVA);
        this.IsCompany(peopleForList.IsCompany);
        this.SourceDescription = peopleForList.SourceDescription;
        this.ImportDate = peopleForList.ImportDate;
    }

    public toJSON(): IPeopleForList {
        return <IPeopleForList> {
            Id: this.Id,
            BusinessName: this.BusinessName(),
            Name: this.Name(),
            Surname: this.Surname(),
            Address: this.Address(),
            CAP: this.CAP(),
            City: this.City(),
            Municipality: this.Municipality(),
            Province: this.Province(),
            Country: this.Country(),
            FiscalCode: this.FiscalCode(),
            PIVA: this.PIVA(),
            SourceDescription: this.SourceDescription,
            ImportDate: this.ImportDate,
            IsCompany: this.IsCompany()
        };
    }
}

export class People extends PeopleForList {
    public DateOfBirth: ko.Observable<Date> = ko.observable();
    public Gender: ko.Observable<string> = ko.observable();
    public PhoneNumber: ko.Observable<string> = ko.observable();
    public Email: ko.Observable<string> = ko.observable();
    public PEC: ko.Observable<string> = ko.observable();
    public LastCall: ko.Observable<Date> = ko.observable();
    public CustomerId: ko.Observable<number> = ko.observable();
    public CreationDate: Date;
    public CreatorId: number;
    public ModifyDate: Date;
    public ModifierId: number;
    public SourceId: number;
    public ImporterId: number;
    public RowIndexInSource: number;

    public CustomersDataSource: CustomersDataSource;

    @LazyImport(nameof<ICustomersService>())
    protected customersService: ICustomersService;
    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.ProvincesSettings)
    protected provincesSettingsManager: IProvincesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    protected countriesSettingsManager: INationsSettingsManager;

    constructor(people: IPeople) {
        super(<IPeopleForList> people);

        this.CustomersDataSource = new CustomersDataSource();
        this.CustomersDataSource.setReturnGroupedData(false);

        this.DateOfBirth(people.DateOfBirth);
        this.Gender(people.Gender);
        this.PhoneNumber(people.PhoneNumber);
        this.Email(people.Email);
        this.PEC(people.PEC);
        this.LastCall(people.LastCall);
        this.CustomerId(people.CustomerId);
        this.CreationDate = people.CreationDate;
        this.CreatorId = people.CreatorId;
        this.ModifyDate = people.ModifyDate;
        this.ModifierId = people.ModifierId;
        this.SourceId = people.SourceId;
        this.ImporterId = people.ImporterId;
        this.RowIndexInSource = people.RowIndexInSource;

        this.FiscalCode.subscribe(this.loadCustomerFromCfOrPiva.bind(this));
        this.PIVA.subscribe(this.loadCustomerFromCfOrPiva.bind(this));
        this.IsCompany.subscribe((value: boolean) => {
            if (!value)
                this.PIVA(null);
        });
    }

    public async ShowCustomersDialog(): Promise<void> {
        let peopleData: ICustomer = {
            CF: this.FiscalCode(),
            PIVA: this.PIVA(),
            Nome: !this.IsCompany() ? this.Name() : null,
            SecondoNome: null,
            Qualification: null,
            Cognome: !this.IsCompany() ? this.Surname() : null,
            RagioneSociale: this.IsCompany() ? this.BusinessName() : null,
            Sesso: this.Gender(),
            Indirizzo: this.Address(),
            CAP: this.CAP(),
            Citta: this.City(),
            Comune: this.Municipality(),
            Provincia: this.provincesSettingsManager.findProvince(this.Province())?.Code,
            Stato: this.countriesSettingsManager.findCountry(this.Country())?.Alpha2Code,
            IsCliente: true,
            IsFornitore: false,
            IsCompany: this.IsCompany(),
            IsPrivateContact: false,
            Telefono: null,
            FAX: null,
            EMail: null,
            PEC: null,
            Notes: null,
            Eliminato: false,
            RifAmministrativo: null,
            CreationUserId: null,
            CreationDate: null,
            LastModificationDate: null,
            OrganizationalUnits: [],
            Tags: [],
            CustomRoles: false,
            CustomMaterialsRoles: false,
            RolesPrices: [],
            TrustManagementEnabled: false,
            StartDocumentsEmissionDateForTrustMovements: null,
            DefaultIvaMode: null,
            DefaultPaymentExpiryTypeId: null,
            DefaultPassivePaymentExpiryTypeId: null,
            DefaultPaymentExpiryType: null,
            DefaultPassivePaymentExpiryType: null,
            DefaultPaymentTypeId: null,
            DefaultPassivePaymentTypeId: null,
            DefaultPaymentAccountId: null,
            DefaultPassivePaymentAccountId: null,
            DefaultPaymentABI: null,
            DefaultPassivePaymentABI: null,
            DefaultPaymentCAB: null,
            DefaultPassivePaymentCAB: null,

            PublicAdministration: false,
            ElectronicInvoicing: true,
            RecipientCodesList: [],
            EORICode: null,
            DefaultOffset: null,
            DefaultOutcome: null,
            DefaultCurrency: null
        };

        try {
            let customer = await this.customersService.ui.showNewCustomerDialogWithData(peopleData, this.PhoneNumber(), null, this.Email(), this.PEC(), true);
            if (!!customer && !this.CustomerId())
                this.CustomerId(customer.IdCliente);
        } catch(e) {
            let exception = (e as unknown) as IException;
            this.infoToastService.Error(exception.ExceptionMessage);
        }
    }

    public toJSON(): IPeople {
        var people: IPeople = <IPeople> super.toJSON();
        people.DateOfBirth = this.DateOfBirth();
        people.Gender = this.Gender();
        people.PhoneNumber = this.PhoneNumber();
        people.Email = this.Email();
        people.PEC = this.PEC();
        people.LastCall = this.LastCall();
        people.CustomerId = this.CustomerId();
        people.CreationDate = this.CreationDate;
        people.CreatorId = this.CreatorId;
        people.ModifyDate = this.ModifyDate;
        people.ModifierId = this.ModifierId;
        people.SourceId = this.SourceId;
        people.ImporterId = this.ImporterId;
        people.RowIndexInSource = this.RowIndexInSource;
        return people;
    }

    private async loadCustomerFromCfOrPiva(): Promise<void> {

        if (!this.PIVA() && !this.FiscalCode())
            return;

        let customer = (await this.customersService.GetCustomerByTaxCodeOrVatNumber(this.FiscalCode(), this.PIVA())).firstOrDefault()
        this.CustomerId(customer?.Id);
    }
}

export class PeopleMetadata {
    public Id: number;
    public PeopleId: number;
    public MetaName: ko.Observable<string> = ko.observable();
    public MetaType: ko.Observable<string> = ko.observable();
    public IntegerValue: ko.Observable<number> = ko.observable();
    public FloatValue: ko.Observable<number> = ko.observable();
    public StringValue: ko.Observable<string> = ko.observable();
    public BooleanValue: ko.Observable<boolean> = ko.observable();
    public DateTimeOffsetValue: ko.Observable<Date> = ko.observable();

    constructor(metadata: IPeopleMetadata) {
        this.Id = metadata.Id;
        this.PeopleId = metadata.PeopleId;
        this.MetaName(metadata.MetaName);
        this.MetaType(metadata.MetaType);
        this.IntegerValue(metadata.IntegerValue);
        this.FloatValue(metadata.FloatValue);
        this.StringValue(metadata.StringValue);
        this.BooleanValue(metadata.BooleanValue);
        this.DateTimeOffsetValue(metadata.DateTimeOffsetValue);
    }

    public toJSON(): IPeopleMetadata {
        return <IPeopleMetadata> {
            Id: this.Id,
            PeopleId: this.PeopleId,
            MetaName: this.MetaName(),
            MetaType: this.MetaType(),
            IntegerValue: this.MetaType() === ProlifeSdk.IntegerDataType ? this.IntegerValue() : null,
            FloatValue: this.MetaType() === ProlifeSdk.FloatDataType ? this.FloatValue() : null,
            StringValue: this.MetaType() === ProlifeSdk.StringDataType ? this.StringValue() : null,
            BooleanValue: this.MetaType() === ProlifeSdk.BooleanDataType ? this.BooleanValue() : null,
            DateTimeOffsetValue: this.MetaType() === ProlifeSdk.DateDataType ? this.DateTimeOffsetValue() : null
        };
    }
}