import * as ko from "knockout";
import { IDiscountsCatalogsService, IResolvedDiscountCatalogArticle, DiscountCatalogRowMode } from "../Warehouse/DiscountsCatalogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface ICustomerDiscountCatalogSolutionDataSourceModel extends IDataSourceModel<number, IResolvedDiscountCatalogArticle> {

}

export interface ICustomerDiscountCatalogSolutionDataSource extends IDataSource {

}

export class CustomerDiscountCatalogSolutionDataSource implements ICustomerDiscountCatalogSolutionDataSource {
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService! : IDiscountsCatalogsService;

    private customerId : number;
    private view: IDataSourceView;
    private refreshRequested: boolean;
    private isCustomer: boolean;
    private referenceDate: Date = new Date();

    setCustomerId(customerId : number, isCustomer : boolean) {
        this.customerId = customerId;
        this.isCustomer = isCustomer;
    }

    setReferenceDate(referenceDate : Date) {
        this.referenceDate = referenceDate;
    }

    getTitle(currentModel: ICustomerDiscountCatalogSolutionDataSourceModel): string {
        return "Test Scontistica Cliente";
    }    
    
    isGroupedData(currentModel: ICustomerDiscountCatalogSolutionDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: ICustomerDiscountCatalogSolutionDataSourceModel, b: ICustomerDiscountCatalogSolutionDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    getData(currentModel: ICustomerDiscountCatalogSolutionDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICustomerDiscountCatalogSolutionDataSourceModel[]> {
        return this.discountsCatalogsService.ResolveCustomerDiscountCatalogs({
            customerId: this.customerId, 
            referenceDate: this.referenceDate,
            isCustomer: this.isCustomer,
            textFilter: textFilter, 
            skip: skip, 
            count: count
        }).then((articles : IResolvedDiscountCatalogArticle[]) => {
                return articles.map(this.createModelFor, this);
            });
    }
    
    async getById(currentModel: ICustomerDiscountCatalogSolutionDataSourceModel, ids: (string | number)[]): Promise<ICustomerDiscountCatalogSolutionDataSourceModel[]> {
        return [];
    }

    private createModelFor(article : IResolvedDiscountCatalogArticle) : ICustomerDiscountCatalogSolutionDataSourceModel {
        Object.defineProperty(article, 'ModeDescription', { value: DiscountCatalogRowMode.getDescription(article.Mode) });

        return {
            id: article.ArticleId,
            title: article.Code,
            subTitle: article.Description,
            isLeaf: true,
            isGroup: false,
            model: article
        };
    }

    refresh() {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}