import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
    "item-with-icon": {
        alignItems: "center",
        height: "28px",
        fontWeight: 300,

        "& .icon": {
            textAlign: "center",
            height: "18px",
            width: "18px",
            lineHeight: "18px",
        },

        "& .description": {
            height: "28px",
            lineHeight: "1em",
            alignItems: "center",
        },
    },
});
const { classes } = styleSheet.attach();

type ItemWithIconProps = {
    label: string;
    icon: string;
    background: string;
    foreground: string;
};

export function ItemWithIcon(props: ItemWithIconProps) {
    const C = require("./ItemWithIcon")._ItemWithIcon as typeof _ItemWithIcon;
    return <C {...props} />;
}

export class _ItemWithIcon {
    static defaultProps: Partial<ItemWithIconProps> = {};

    constructor(private props: ItemWithIconProps) {}

    render() {
        const classNames = ComponentUtils.classNames("flex-container", classes["item-with-icon"]);

        return (
            <div className={classNames}>
                <div className="icon" style={{ backgroundColor: this.props.background, color: this.props.foreground }}>
                    <i className={this.props.icon}></i>
                </div>
                <div className="flex-fill flex-container description">
                    <span style={{ display: "inline-block", lineHeight: "1em" }}>{this.props.label}</span>
                </div>
            </div>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ItemWithIcon);
}
