import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { INavigationMenuComponentAlert } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IJobOrderForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IJobOrderForAllocations } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class JobOrdersUtils {
    public static getAlertsForJobOrder(j: IJobOrderForTaskBoard | IJobOrderForAllocations) {
        const alerts: INavigationMenuComponentAlert[] = [];

        if (j.IsLateStart)
            alerts.push({
                icon: {
                    icon: "icon-hourglass",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderIsLateStart,
            });
        if (j.IsLateEnd)
            alerts.push({
                icon: {
                    icon: "icon-hourglass",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderIsLateEnd,
            });
        if (!j.IsReady)
            alerts.push({
                icon: {
                    icon: "fa fa-exclamation-circle",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderTasksAreNotReady,
            });
        if (!j.HasWarehouse)
            alerts.push({
                icon: {
                    icon: "fa fa-shopping-cart",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsWarehouseArticles,
            });
        if (!j.HasPurchases)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsPurchases,
            });
        if (!j.HasResources)
            alerts.push({
                icon: {
                    icon: "fa fa-users",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsResources,
            });
        if (j.HasReportingTasks)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasBillableTasks,
            });
        if (j.HoursToBeBilled > 0)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasToBeBilledHours,
            });

        if (j.HasNotApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasNotApprovedBudgetRequests,
            });
        if (j.HasApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasApprovedBudgetRequests,
            });
        if (!j.HasEstimatedWork)
            alerts.push({
                icon: {
                    icon: "fa fa-terminal",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasNotEstimatedWork,
            });

        if (j.WorkflowMustBePlanned)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasWorkflowToBePlanned,
            });

        if (j.ActivitiesMustBeReviewed)
            alerts.push({
                icon: {
                    icon: "fa fa-bookmark",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasActivitiesToBeReviewed,
            });

        if (j.WorkflowsMustBeRelatedToCustomerOrders) {
            alerts.push({
                icon: {
                    icon: "fa fa-files-o",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.JobOrder.WorkflowsMustBeRelatedToCustomerOrders,
            });
        }

        return alerts;
    }

    public static getBadgeClassForWorkflow(workflow: IJobOrderForTaskBoard | IJobOrderForAllocations): string {
        if (workflow.HasNotEstimatedElementsAllocated) return "badge-danger";

        return "badge-success";
    }

    public static getAllAlerts() {
        const alerts: INavigationMenuComponentAlert[] = [];

        alerts.push({
            icon: {
                icon: "icon-hourglass",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderIsLateStart,
        });

        alerts.push({
            icon: {
                icon: "icon-hourglass",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderIsLateEnd,
        });

        alerts.push({
            icon: {
                icon: "fa fa-exclamation-circle",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderTasksAreNotReady,
        });

        alerts.push({
            icon: {
                icon: "fa fa-shopping-cart",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsWarehouseArticles,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsPurchases,
        });

        alerts.push({
            icon: {
                icon: "fa fa-users",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderNeedsResources,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasBillableTasks,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasToBeBilledHours,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasNotApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "fa fa-terminal",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasNotEstimatedWork,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasWorkflowToBePlanned,
        });

        alerts.push({
            icon: {
                icon: "fa fa-bookmark",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.JobOrderHasActivitiesToBeReviewed,
        });

        alerts.push({
            icon: {
                icon: "fa fa-files-o",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.JobOrder.WorkflowsMustBeRelatedToCustomerOrders,
        });

        return alerts;
    }
}
