import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { MetadataType } from "../../enums/MetadataType";
import { TextInput } from "../../../../Components/TextInput";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { NumberInput } from "../../../../Components/NumberInput";
import { DateRangeInput } from "../../../../Components/DateRangeInput";

type MetadataFilterProps = {
    metadataId: number;
    metadataType: MetadataType;

    onFilterChanges: (metadataId: number, metadataType: MetadataType, firstValue: any, secondValue: any) => void;

    datePickerParent?: string;
}

export function MetadataFilter(props: MetadataFilterProps) {
    const C = require("./MetadataFilter")._MetadataFilter as typeof _MetadataFilter;
    return <C {...props} />;
}

export class _MetadataFilter {
    static defaultProps: Partial<MetadataFilterProps> = {
    }

    private FirstValue : ko.Observable<any> = ko.observable();
    private SecondValue : ko.Observable<any> = ko.observable();

    private subscriptions: ko.Subscription[] = [];

    constructor(private props : MetadataFilterProps) {
        
    }

    componentDidMount() {
        this.subscriptions.push(this.FirstValue.subscribe(v => this.props.onFilterChanges(this.props.metadataId, this.props.metadataType, this.normalizeValue(v), this.normalizeValue(this.SecondValue()))));
        this.subscriptions.push(this.SecondValue.subscribe(v => this.props.onFilterChanges(this.props.metadataId, this.props.metadataType, this.normalizeValue(this.FirstValue()), this.normalizeValue(v))));
    }

    componentWillUnmount() {
        for (let sub of this.subscriptions)
            sub.dispose();

        this.subscriptions = [];
    }
    
    render() {
        if (this.props.metadataType === MetadataType.Text || this.props.metadataType === MetadataType.List)
            return <TextInput value={this.FirstValue} label={TextResources.Invoices.MetadataTextFilter} placeholder={TextResources.Invoices.MetadataTextFilter} selectOnFocus />

        if (this.props.metadataType === MetadataType.Integer || this.props.metadataType === MetadataType.Decimal)
            return <NumberInput value={this.FirstValue} label={TextResources.Invoices.MetadataNumberFilter} placeholder={TextResources.Invoices.MetadataNumberFilter} nullable selectOnFocus />
        
        if (this.props.metadataType === MetadataType.Boolean)
            return <></>; // TODO
        
        if (this.props.metadataType === MetadataType.DateTime)
            return <DateRangeInput startDate={this.FirstValue} endDate={this.SecondValue} title={TextResources.Invoices.DocumentDateFilter} datePickerParent={this.props.datePickerParent} />

        return <></>;
    }

    private normalizeValue(v: any): any {
        if (typeof(v) === "string" && !v)
            return undefined;

        return v;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(MetadataFilter);
}