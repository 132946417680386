import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { DdtEventDetailsEdit } from "./details/DdtEventDetailsEdit";
import { IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IInvoice, IInvoiceRow } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class DdtEvent extends DocumentEvent implements IView
{
    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.DdtEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.Invoices.Delivery;
        this.invoicesService = <IInvoicesService>serviceLocator.findService(ProlifeSdk.InvoicesServiceType);
    }

    getEditViewModel() : IEventBaseInput
    {
        return new DdtEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.LoadDocumentInfo();
    }

    public LoadDocumentInfo() : Promise<void>
    {
        var def = new Deferred<void>();
        
        this.invoicesService.getDocumentById(this.DocumentId())
            .then((d : IInvoice) => {
                if(!d)
                    return;

                this.Total(d.TotaleFattura - d.IVA);

                this.invoicesService.getDocumentRowsById(this.DocumentId()).then((r : IInvoiceRow[]) => {
                    this.NumberOfRows(r ? r.length : 0);
                });
            }).finally(() => {
                def.resolve();
            });
        
        return def.promise();
    }
}