import * as ko from "knockout"
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk"
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes"
import { Allocations } from "./ui/Allocations"
import { AllocationMode } from "./enums/AllocationMode"
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator"
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService"
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService"
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService"
import { IOPAService } from "../../Core/interfaces/IOPAService"
import { IApplication } from "../../Desktop/interfaces/IApplication"
import { IAuthenticationServiceObserver } from "../../Core/interfaces/IAuthenticationServiceObserver"
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver"
import { INavBarAction, INavBarActionWithDropdown } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader"
import { LazyImport } from "../../Core/DependencyInjection"
import { IAllocationsService } from "../../ProlifeSdk/interfaces/allocations/IAllocationsService"
import { IUserForClient } from "../../Core/interfaces/IPrincipal"

const csss = require("../css/allocations.scss")

export class AllocationsApplication
    implements IApplication, IAuthenticationServiceObserver, IAuthorizationServiceObserver
{
    public templateName = "allocationsApplication"
    public templateUrl = "allocations/templates"
    public templateData: ko.Observable<Allocations> = ko.observable()
    public tileColor = "tile-green"

    private applicationsService: IApplicationsService
    private authorizationService: IAuthorizationService
    private authenticationService: IAuthenticationService

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true)
    public navBarActions: ko.ObservableArray<INavBarAction | INavBarActionWithDropdown> = ko.observableArray([])
    public embeddedFields: ko.ObservableArray<any> = ko.observableArray([])

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService
    private canRunAutoAllocation = false

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService = <IApplicationsService>serviceLocator.findService(ProlifeSdk.ApplicationsServiceType)
        this.applicationsService.registerApplication(this)

        this.authenticationService = <IAuthenticationService>serviceLocator.findService(ServiceTypes.Authentication)
        this.authenticationService.addObserver(this)

        this.authorizationService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization)
        this.authorizationService.addObserver(this)

        this.canRunAutoAllocation = this.authorizationService.isAuthorized("Allocations_RunAutoAllocations")
    }

    authorizationLoaded(): void {
        this.canRunAutoAllocation = this.authorizationService.isAuthorized("Allocations_RunAutoAllocations")
    }

    userLoggedIn(user: IUserForClient): void {}

    userLoggedOut(): void {}

    loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any): void {}

    getName(): string {
        return ProlifeSdk.TextResources.Allocations.Allocations
    }

    getIcon(): string {
        return "icon-compass"
    }

    registerRoutes(): void {
        var opaService = <IOPAService>this.serviceLocator.findService(ServiceTypes.OPA)
        opaService.Get("#/Allocations", (context) => this.start())
    }

    getApplicationRoute(): string {
        return "#/Allocations"
    }

    getApplicationCode(): string {
        return ProlifeSdk.AllocationsApplicationCode
    }

    onGoingDown(): void {
        if (this.templateData()) {
            this.templateData().dispose && this.templateData().dispose()
            //delete this.templateData();
            this.templateData(undefined)
        }
    }

    private start() {
        this.onGoingDown()

        this.templateData(new Allocations(this.serviceLocator))
        this.createNavbarActions()

        this.applicationsService.startApplication(this)
    }

    private createNavbarActions(): void {
        this.navBarActions([])

        this.navBarActions([
            {
                Name: ko.observable(ProlifeSdk.TextResources.Allocations.TeamManager),
                CanRun: ko.computed(() => {
                    return true
                }),
                Icon: ko.observable("fa-group"),
                Run: this.templateData().SelectResourceGroup.bind(this.templateData()),
            },
        ])

        this.navBarActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Allocations.AutomaticAllocationButtonLabel),
            CanRun: ko.computed(
                () => this.templateData() && this.templateData().AllocationMode() === AllocationMode.ManualAllocation
            ),
            Right: true,
            Icon: ko.observable("fa-tasks"),
            Run: this.setAutomaticAllocationMode.bind(this),
        })

        this.navBarActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Allocations.ManualAllocationButtonLabel),
            CanRun: ko.computed(
                () => this.templateData() && this.templateData().AllocationMode() === AllocationMode.AutomaticAllocation
            ),
            Right: true,
            Icon: ko.observable("fa-calendar"),
            Run: this.setManualAllocationMode.bind(this),
        })

        this.navBarActions.push({
            Name: ko.observable("Auto-allocazione"),
            CanRun: ko.computed(() => this.canRunAutoAllocation),
            Right: true,
            Icon: ko.observable("fa-shopping-cart"),
            Run: this.runAutoAllocation.bind(this),
        })
    }

    private async runAutoAllocation(): Promise<void> {
        try {
            await this.allocationsService.startAutoallocationProcess()
        } catch (e) {
            console.log(e)
        }
    }

    private setManualAllocationMode(): void {
        this.templateData().SetAllocationMode(AllocationMode.ManualAllocation)
    }

    private setAutomaticAllocationMode(): void {
        this.templateData().SetAllocationMode(AllocationMode.AutomaticAllocation)
    }
}
