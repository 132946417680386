import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/06/2017
 * Time: 10:49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { Questionnaire } from "../Questionnaire";
import { SectionForEdit } from "./SectionForEdit";
import { SectionQuestionsForEdit } from "./SectionQuestionsForEdit";
import { QuestionForEdit } from "./QuestionForEdit";
import { SurveyPanelViewModel } from "../../SurveyPanelViewModel";
import { QuestionsWithoutSectionForEdit } from "./QuestionsWithoutSectionForEdit";
import { QuestionnaireEditDialog } from "./dialogs/QuestionnaireEditDialog";
import { SectionEditDialog } from "./dialogs/SectionEditDialog";
import { AnswerForEdit } from "./AnswerForEdit";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelectionObserver } from "../../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { IQuestionMovementObserver } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionMovementObserver";
import { IQuestionType, IQuestion } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { IAnswerType } from "../../../../../ProlifeSdk/interfaces/survey/IAnswer";
import { ISortable } from "../../../../interfaces/ISortable";
import { INavBarAction } from "../../../../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { IQuestionnaire, IStructuredQuestionnaire } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { ISection } from "../../../../../ProlifeSdk/interfaces/survey/ISection";
import { ISelectable } from "../../../../../ProlifeSdk/interfaces/survey/ISelectable";

export interface IAvailableSectionForList {
    Value: number;
    Caption: string;    
}

export class QuestionnaireEditViewModel implements ISelectionObserver, IQuestionMovementObserver {
    public templateUrl:string = "survey/templates/questionnaire";
    public templateName: string = "edit-questionnaire-details";

    public Questionnaire: ko.Observable<any> = ko.observable();
    public Sections: ko.ObservableArray<SectionForEdit> = ko.observableArray([]);
    public Questions: ko.ObservableArray<QuestionForEdit> = ko.observableArray([]);

    public SelectedSection: ko.Observable<any> = ko.observable();
    public SelectedQuestion: ko.Observable<any> = ko.observable();

    public InsertMode: ko.Observable<boolean> = ko.observable();

    public QuestionTypes: ko.ObservableArray<IQuestionType> = ko.observableArray([]);
    public AnswerTypes: ko.ObservableArray<IAnswerType> = ko.observableArray([]);

    public NewQuestionId: number = 1;
    public NewSectionId: number = 1;

    public SectionsNumber: ko.Computed<number>;
    public QuestionsNumber: ko.Computed<number>;
    public QuestionnaireElements: ko.Computed<ISortable[]>;
    public AvailableSections: ko.Computed<IAvailableSectionForList[]>;

    private surveyService: ISurveyService;
    private dialogService: IDialogsService;
    private infoToastService: IInfoToastService;

    private NavBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);

    public IsQuestionnaireEditor: boolean = true;
    public ShowPrompt: ko.Observable<boolean> = ko.observable(true);

    constructor (private serviceLocator: IServiceLocator, questionnaireId: number, private parent: SurveyPanelViewModel){
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.InsertMode(false);

        this.surveyService.getQuestionnaireData(questionnaireId)
            .then((questionnaire: IQuestionnaire) => {
                if (!questionnaire) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
                    return;
                }
                this.Questionnaire(new Questionnaire(questionnaire));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionnaireError);
            });

        this.surveyService.getQuestionsWithoutSection(questionnaireId)
            .then((questions: IQuestion[]) => {
                if (questions.length == 0) {
                    return;
                }
                this.Questions(questions.map((q: IQuestion) => {
                    var questionViewModel: QuestionForEdit = new QuestionForEdit(this.serviceLocator, q, false, null, this);
                    questionViewModel.RegisterSelectionObserver(this);
                    questionViewModel.RegisterQuestionMovementObserver(this);
                    return questionViewModel;
                }));
                this.NewQuestionId += this.Questions().length;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
            });

        this.surveyService.getQuestionnaireSections(questionnaireId)
            .then((sections: ISection[]) => {
                if (sections.length == 0)
                    return;
                this.Sections(sections.map((s: ISection) => {
                    var sectionViewModel: SectionForEdit = new SectionForEdit(this.serviceLocator, s, false, this);
                    sectionViewModel.RegisterSelectionObserver(this);
                    sectionViewModel.loadQuestions();
                    return sectionViewModel;
                }));
                this.NewSectionId += this.Sections().length;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadSectionsError);
            });

        this.surveyService.getQuestionTypes()
            .then((types: IQuestionType[]) => {
                if (types.length == 0)
                    return;
                this.QuestionTypes(types);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionTypesError);
            });

        this.surveyService.getAnswerTypes()
            .then((types: IAnswerType[]) => {
                if (types.length == 0)
                    return;
                this.AnswerTypes(types);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadAnswerTypesError);
            });

        this.SectionsNumber = ko.computed(() => {
            return this.Sections().filter((s: SectionForEdit) => {
                return !s.Deleted();
            }).length;
        });

        this.QuestionsNumber = ko.computed(() => {
            var questions: number = this.Questions().filter((q: QuestionForEdit) => {
                return !q.Deleted();
            }).length;
            this.Sections().forEach((s: SectionForEdit) => {
                questions += s.QuestionsNumber();
            });
            return questions;
        });

        this.QuestionnaireElements = ko.computed(() => {
            // NON RIMUOVERE: server per forzare il ricalcolo della computed
            this.Questions();
            var elements: ISortable[] = [];
            elements.push(new QuestionsWithoutSectionForEdit(this.Questions, this.serviceLocator, this));
            this.Sections().forEach((s: SectionForEdit) => {
                elements.push(new SectionQuestionsForEdit(s));
            });
            elements.sort((a: any, b: any) => {
                return a.Index() - b.Index();
            });
            return elements;
        });

        this.AvailableSections = ko.computed(() => {
            var options: IAvailableSectionForList[] = [];
            options.push({ Value: null, Caption: ProlifeSdk.TextResources.Survey.WithoutSectionSelectCaption });
            var sections: SectionForEdit[] = this.Sections().filter((s: SectionForEdit) => {
                return !s.Deleted();
            });
            sections.forEach((s: SectionForEdit) => {
                options.push({ Value: s.Id, Caption: s.Label() + ' - ' + s.Name() });
            });
            return options;
        });

        this.NavBarActions.subscribe(() => {
            this.parent.SplashPage.setNavBarActions(this.NavBarActions());
        });

        this.NavBarActions([/*{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.EditQuestionnaire),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-edit"),
            Run: this.EditQuestionnaire.bind(this)
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Survey.AddQuestionnaireSection),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.AddSection.bind(this)
        }*/]);


        $(window).on("beforeunload.questionnaireEditor", this.onBeforeUnload.bind(this));
    }

    public onBeforeUnload() {
        return ProlifeSdk.TextResources.Survey.UnsavedChanges;
    }

    public EditQuestionnaire() {
        var editQuestionnaireDialog = new QuestionnaireEditDialog(this.serviceLocator, this.Questionnaire());
        this.dialogService.ShowModal<Questionnaire>(editQuestionnaireDialog, null, null, "survey/templates/questionnaire/dialogs", "edit-questionnaire-dialog")
            .then((questionnaire: Questionnaire) => {
                this.Questionnaire(questionnaire);
            });
    }

    public AddSection() {
        var editSectionDialog = new SectionEditDialog(this.serviceLocator, null, this);
        this.dialogService.ShowModal<void>(editSectionDialog, null, null, "survey/templates/questionnaire/dialogs", "edit-section-dialog");
    }

    public dispose(){
        $(window).off("beforeunload.questionnaireEditor");
    }

    public OnQuestionMoved(beforeId : number, movedId : number, afterId : number) {
        var actualPosition: number = this.getElementIndex(movedId, this.Questions());
        var previousQuestionPosition: number = this.getElementIndex(beforeId, this.Questions());
        var nextQuestionPosition: number = this.getElementIndex(afterId, this.Questions());
        this.updateIndexes(movedId, actualPosition, previousQuestionPosition, nextQuestionPosition, this.Questions());
    }

    private getElementIndex(id: number, elements: ISortable[]): number {
        var index: number = null;
        elements.forEach((el: ISortable) => {
            if (el.Id == id)
                index = el.Index();
        });
        return index;
    }

    private updateIndexes(elementId: number, actualIndex: number, previousObjectIndex: number, nextObjectIndex: number, elements: ISortable[]): void {
        if (!previousObjectIndex && !nextObjectIndex)
            return;
        previousObjectIndex = !previousObjectIndex ? 0 : previousObjectIndex;
        nextObjectIndex = !nextObjectIndex ? 99999 : nextObjectIndex;
        if (actualIndex < previousObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() >= actualIndex && e.Index() <= previousObjectIndex && e.Id != elementId)
                    e.Index(e.Index() - 1);
                if (e.Id == elementId)
                    e.Index(previousObjectIndex);
            });
            return;
        }
        if (actualIndex > nextObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() <= actualIndex && e.Index() >= nextObjectIndex && e.Id != elementId)
                    e.Index(e.Index() + 1);
                if (e.Id == elementId)
                    e.Index(nextObjectIndex);
            });
        }
    }

    public selectSection(section: ISelectable) {
        this.Sections().forEach((s: SectionForEdit) => {
            s.Selected(false);
        });
        section.Selected(true);
        this.SelectedSection(section);
    }

    public selectQuestion(question: ISelectable) {
        this.Questions().forEach((q: ISelectable) => {
            q.Selected(false);
        });
        this.Sections().forEach((s: SectionForEdit) => {
            s.DeselectQuestions();
        });
        question.Selected(true);
        this.SelectedQuestion(question);
    }

    public selectNextQuestion(question: ISelectable) {

    }

    public selectPreviousQuestion(question: ISelectable) {

    }

    public moveQuestion(questionId: number, oldParent: number, newParent: number): void {
        var question: QuestionForEdit = this.removeQuestionFromParent(questionId, oldParent);
        if (newParent) {
            var section: SectionForEdit = this.Sections().filter((s: SectionForEdit) => {
                return s.Id == newParent;
            })[0];
            question.section = section;
            question.ParentLabel(section.Label());
            section.Questions.push(question);
            this.RefreshIndexes(section.Questions());
            return;
        }
        question.section = null;
        question.ParentLabel(null);
        this.Questions.push(question);
        this.RefreshIndexes(this.Questions());
    }

    private removeQuestionFromParent(questionId: number, parentId: number): QuestionForEdit {
        if (parentId) {
            var section: SectionForEdit = this.Sections().filter((s: SectionForEdit) => {
                return s.Id == parentId;
            })[0];
            var question: QuestionForEdit = <QuestionForEdit>section.Questions.splice(this.getQuestionPosition(questionId, section.Questions()),1)[0];
            this.RefreshIndexes(section.Questions());
            return question;
        }
        var question: QuestionForEdit = <QuestionForEdit>this.Questions.splice(this.getQuestionPosition(questionId, this.Questions()),1)[0];
        this.RefreshIndexes(this.Questions());
        return question;
    }

    private getQuestionPosition(id: number, questions: QuestionForEdit[]): number {
        var pos: number = -1;
        var iterator: number = 0;
        questions.forEach((q: QuestionForEdit) => {
            if (q.Id == id)
                pos = iterator;
            iterator += 1;
        });
        return pos;
    }

    public RefreshIndexes(questions: QuestionForEdit[]): void {
        var index = 1;
        questions.forEach((q: QuestionForEdit) => {
            q.Index(index);
            index += 1;
        });
    }

    public BackToSplash(): void {
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    public Abort(): void {
        this.dialogService.Confirm(ProlifeSdk.TextResources.Survey.AbortQuestionnaireEdit,
            ProlifeSdk.TextResources.Survey.AbortQuestionnaireEditCancel,
            ProlifeSdk.TextResources.Survey.AbortQuestionnaireEditConfirm, (confirm: boolean) => {
                if (confirm) {
                    this.ShowPrompt(false);
                    location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + this.Questionnaire().Id;
                }
            });
    }

    public OnSectionMoved(beforeId : number, movedId : number, afterId : number) {
        var actualPosition: number = this.getElementIndex(movedId, this.Sections());
        var previousSectionPosition: number = this.getElementIndex(beforeId, this.Sections());
        var nextSectionPosition: number = this.getElementIndex(afterId, this.Sections());
        this.updateIndexes(movedId, actualPosition, previousSectionPosition, nextSectionPosition, this.Sections());
    }

    public Save(): void {
        var canSave: boolean = !this.checkForMissingData();
        if (!canSave) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.MissingQuestionnaireRequiredData);
            return;
        }
        var data: IStructuredQuestionnaire = {
            Questionnaire: this.Questionnaire().ToJSON(),
            Sections: this.Sections().map((s: SectionForEdit) => {
                return s.ToJSON();
            }),
            Questions: this.Questions().map((q: QuestionForEdit) => {
                return q.ToJSON();
            })
        };
        this.surveyService.saveFullQuestionnaire(data)
            .then((questionnaireId: number) => {
                if (questionnaireId == -1) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CanNotChangeQuestionnaireState);
                    return;
                }
                this.ShowPrompt(false);
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + questionnaireId;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.SaveFullQuestionnaireError);
                return;
            });
    }

    public Delete(): void {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Survey.DeleteQuestionnaire,
            ProlifeSdk.TextResources.Survey.DeleteQuestionnaireCancel,
            ProlifeSdk.TextResources.Survey.DeleteQuestionnaireConfirm,
            (confirm: boolean) => {
                if (confirm) {
                    this.surveyService.deleteQuestionnaire(this.Questionnaire().Id)
                        .then((result: number) => {
                            if (result == -1) {
                                this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CanNotDeleteQuestionnaire);
                                return;
                            }
                            this.ShowPrompt(false);
                            this.parent.Reset = false;
                            location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
                        })
                        .catch(() => {
                            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DeleteQuestionnaireError);
                        });
                }
            }
        );
    }

    public getAvailableSectionId(): number {
        return this.SectionsNumber() == 0 ? 1 : (Math.max.apply(Math, this.Sections().map((s: SectionForEdit) => { return s.Id; }))) + 1;
    }

    private checkForMissingData(): boolean {
        var result: boolean = this.checkForIncompleteQuestions(this.Questions());
        if (result)
            return result;
        this.Sections().forEach((s: SectionForEdit) => {
            if (this.checkForIncompleteQuestions(s.Questions()))
                result = true;
        });
        return result;
    }

    private checkForIncompleteQuestions(questions: QuestionForEdit[]): boolean {
        var result: boolean = false;
        questions.forEach((q: QuestionForEdit) => {
            if (q.MissingRequiredData() && !q.IsParentDeleted())
                result = true;
        });
        return result;
    }

    public getLabelsListForTypeahead(listLength: number, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any, numberOfElementsLoaded? : number) {
        var elements: any[] = [];
        if (query)
            query = query.toLocaleLowerCase();
        this.Questions().forEach((q: QuestionForEdit) => {
            if (!q.Deleted() && (!query || q.Label().toLocaleLowerCase().indexOf(query) != -1 || q.Text().toLocaleLowerCase().indexOf(query) != -1))
                elements.push( { Label: q.Label(), FullName: q.Label() + ' - ' + q.Text(), Enabled: q.Enabled() } );
        });
        this.Sections().forEach((s: SectionForEdit) => {
            if (!s.Deleted()) {
                if ((!query || s.Label().toLocaleLowerCase().indexOf(query) != -1 || s.Name().toLocaleLowerCase().indexOf(query) != -1)) {
                    elements.push( { Label: s.Label(), FullName: s.Label() + ' - ' + s.Name(), Enabled: s.Enabled } );
                }
                s.Questions().forEach((q: QuestionForEdit) => {
                    if (!q.Deleted() && (!query || q.Label().toLocaleLowerCase().indexOf(query) != -1 || q.Text().toLocaleLowerCase().indexOf(query) != -1))
                        elements.push({ Label: q.Label(), FullName: q.Label() + ' - ' + q.Text(), Enabled: q.Enabled() });
                });
            }
        });
        if (elements.length > listLength)
            elements.splice(listLength, (elements.length - listLength));
        return process(this.transformElements(elements));
    }

    private transformElements(elements: any[]): any[] {
        return elements.map(this.transform);
    }

    private transform(elem: any): any {
        return {
            Label: elem.Label,
            FullName: elem.FullName,
            Enabled: elem.Enabled,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}