import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 17/10/13
 * Time: 10.01
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";

export class WorkTimeCategoriesEditingViewModel
{
    title : string;
    elements : ko.ObservableArray<WorkTimeCategoryViewModel> = ko.observableArray();

    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;

    public HumanResourcesWorkTimeCategories : ko.Computed<WorkTimeCategoryViewModel[]>;
    public MaterialResourcesWorkTimeCategories : ko.Computed<WorkTimeCategoryViewModel[]>;

    constructor(private workTimeCategoriesManager : IWorkTimeCategoriesSettingsManager)
    {
        this.title = workTimeCategoriesManager.getLabel();
        workTimeCategoriesManager.getAll(false)
            .forEach(this.createViewModelFor.bind(this));

        this.HumanResourcesWorkTimeCategories = ko.computed(() => {
            return this.elements().filter((c : WorkTimeCategoryViewModel) => { return c.ResourceType == 0; });
        });

        this.MaterialResourcesWorkTimeCategories = ko.computed(() => {
            return this.elements().filter((c : WorkTimeCategoryViewModel) => { return c.ResourceType != 0; });
        });
    }

    private createViewModelFor(category : IWorkTimeCategory)
    {
        var categoryVm = new WorkTimeCategoryViewModel(this, category);
        this.elements.push(categoryVm);
    }

    public createOrUpdateEntry(element : WorkTimeCategoryViewModel)
    {
        var category : IWorkTimeCategory = element.getData();
        this.workTimeCategoriesManager.createOrUpdate(category)
            .then((updatedcategory) => { element.update(updatedcategory); });
    }

    public AddNewHumanResourcesWorkTimeCategory()
    {
        this.AddNewCategory(<IWorkTimeCategory>{
            Id : -1,
            Name: ProlifeSdk.TextResources.WorkedHours.NewWorkTimeCategory,
            DefaultHourSalary : 0,
            IsExtraordinary : false,
            IsBuiltIn : false,
            ResourceType : 0,
            IsAbsent: false
        });
    }

    public AddNewMaterialResourcesWorkTimeCategory()
    {
        this.AddNewCategory(<IWorkTimeCategory>{
            Id : -1,
            Name: ProlifeSdk.TextResources.WorkedHours.NewWorkTimeCategory,
            DefaultHourSalary : 0,
            IsExtraordinary : false,
            IsBuiltIn : false,
            ResourceType : 1,
            IsAbsent: false
        });
    }

    private AddNewCategory(category : IWorkTimeCategory)
    {
        var resourceViewModel : WorkTimeCategoryViewModel = new WorkTimeCategoryViewModel(this, category);
        this.elements.push(resourceViewModel);
        this.createOrUpdateEntry(resourceViewModel);
    }

    public deleteEntry(element : WorkTimeCategoryViewModel)
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.WorkedHours.SureToDeleteWorkTimeCategory, ProlifeSdk.TextResources.WorkedHours.DoNotDelete, ProlifeSdk.TextResources.WorkedHours.Delete, (dialogResult) => {
            if(dialogResult)
            {
                this.elements.remove(element);
                var category : IWorkTimeCategory = element.getData();
                this.workTimeCategoriesManager.delete(category);
            }
        })
    }
}

export class WorkTimeCategoryViewModel
{
    public Id : number;
    public Name : ko.Observable<string> = ko.observable("");
    public IsExtraordinary : ko.Observable<boolean> = ko.observable(false);
    public DefaultSalary : ko.Observable<number> = ko.observable(0);
    public IsBuiltIn : boolean = false;

    private updating : boolean = false;
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    hasDefaultSalaryFocus : ko.Observable<boolean> = ko.observable(false);
    hasNameFocus : ko.Observable<boolean> = ko.observable(false);
    hasIsExtraordinaryFocus : ko.Observable<boolean> = ko.observable(false);

    public ResourceType : number;

    constructor(private container : WorkTimeCategoriesEditingViewModel, private category : IWorkTimeCategory)
    {
        this.Id = category.Id;
        this.Name(category.Name);
        this.IsExtraordinary(category.IsExtraordinary);
        this.DefaultSalary(category.DefaultHourSalary);
        this.IsBuiltIn = category.IsBuiltIn;

        this.Name.subscribe(this.onDataChanged.bind(this));
        this.IsExtraordinary.subscribe(this.onDataChanged.bind(this));
        this.DefaultSalary.subscribe(this.onDataChanged.bind(this));

        this.hasDefaultSalaryFocus.subscribe(this.checkFocus.bind(this));
        this.hasNameFocus.subscribe(this.checkFocus.bind(this));
        this.hasIsExtraordinaryFocus.subscribe(this.checkFocus.bind(this));

        this.ResourceType = category.ResourceType;
    }

    public Delete()
    {
        this.container.deleteEntry(this);
    }

    public SwitchExtraordinary()
    {
        this.IsExtraordinary(!this.IsExtraordinary());
    }

    private checkFocus()
    {
        this.hasFocus(this.hasDefaultSalaryFocus() || this.hasNameFocus() || this.hasIsExtraordinaryFocus());
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating)
            return;

        this.container.createOrUpdateEntry(this);
    }

    getData() : IWorkTimeCategory
    {
        var category : IWorkTimeCategory = <IWorkTimeCategory> $.extend({}, this.category);
        category.Name = this.Name();
        category.DefaultHourSalary = this.DefaultSalary();
        category.IsExtraordinary = this.IsExtraordinary();
        return category;
    }

    update(category : IWorkTimeCategory) : void
    {
        this.updating = true;
        this.category = category;
        this.Name(category.Name);
        this.IsExtraordinary(category.IsExtraordinary);
        this.DefaultSalary(category.DefaultHourSalary);
        this.updating = false;
    }
}