import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { KnockoutType } from "@abstraqt-dev/jsxknockout";

const styleSheet = jss
    .createStyleSheet({
        colorInput: {
            display: "block",

            "&.xs": {
                "& .prolife-color-picker .input-group-btn .btn": {
                    padding: "4px 5px",
                    height: "24px",

                    "& .prolife-color-picker-preview-bg": {
                        fontSize: "10px",
                    },

                    "& .caret": {
                        height: "10px",
                    },
                },
            },
        },
    })
    .attach();
const { classes } = styleSheet;

type ColorInputProps = {
    label?: string;
    size?: "md" | "xs";
    readonly?: KnockoutType<boolean>;
    value?: ko.Observable<string>;
    simple?: boolean;
    allowAlpha?: boolean;
};

export function ColorInput(props: ColorInputProps) {
    const C = require("./ColorInput")._ColorInput as typeof _ColorInput;
    return <C {...props} />;
}

export class _ColorInput {
    static defaultProps: Partial<ColorInputProps> = {
        allowAlpha: false,
        size: "md",
    };

    constructor(private props: ColorInputProps) {}

    render() {
        let cli = this.props;
        const classNames = ComponentUtils.classNames("form-group", classes.colorInput, this.props.size);

        const wrapFormGroup = (input: React.ReactElement) => {
            if (this.props.simple) return input;

            return (
                <div className={classNames}>
                    {this.props.label && <label className="control-label">{this.props.label}</label>}
                    {input}
                </div>
            );
        };

        return ComponentUtils.bindTo(
            wrapFormGroup(
                <input
                    className="form-control"
                    type="text"
                    data-bind={{
                        disable: cli.readonly,
                        colorPicker: { colorField: cli.value, options: { showHexInput: false } },
                        colorPickerAllowAlpha: cli.allowAlpha,
                    }}
                ></input>
            ),
            this.props,
            "cli"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ColorInput);
}
