import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { ContactList } from "./SearchService/ContactList";
import { MailBlogEvent } from "../EventViewModels/MailBlogEvent";
import { Tag } from "../../../ProlifeSdk/prolifesdk/blog/Tag";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IBlogMailData } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService, AjaxOptions } from "../../../Core/interfaces/IAjaxService";
import { ShowModalOptions, IDialog } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";
import { ITag } from "../../../ProlifeSdk/interfaces/ITag";
import { IJobOrder } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class EmailInput extends BlogEventBaseInput {
    public humanResourcesSearchService: IControlsEntityProvider;
    public templateName = "mail-details";
    public title: string = ProlifeSdk.TextResources.Blog.EmailTitle;
    mailFrom: ContactList;
    mailTo: ContactList;
    mailCc: ContactList;
    mailCcn: ContactList;
    mailSubject: ko.Observable<string> = ko.observable("");

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver, event: MailBlogEvent) {
        super(serviceLocator, contextEventsObserver);

        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.humanResourcesSearchService = entityProviderService
            .getEntityProvider(ProlifeSdk.HumanResources)
            .getControlsProvider();

        this.iconName = "f-icon-mail-1";
        this.eventDate(new Date());
        this.mailFrom = new ContactList(ProlifeSdk.TagEmail_From);
        this.mailTo = new ContactList(ProlifeSdk.TagEmail_To);
        this.mailCc = new ContactList(ProlifeSdk.TagEmail_Cc);
        this.mailCcn = new ContactList(ProlifeSdk.TagEmail_Ccn);
        this.setValues(event);
    }

    public setValues(email: MailBlogEvent): void {
        this.mailSubject(email.MailSubject());
        this.mailFrom.clear();
        this.mailTo.clear();
        this.mailCc.clear();
        this.mailCcn.clear();
        this.mailFrom.addElements(email.MailFrom());
        this.mailTo.addElements(email.MailTo());
        this.mailCc.addElements(email.MailCc());
        this.mailCcn.addElements(email.MailCcn());
        super.setValues(email);
    }

    getBlogEvent(): ILogEvent {
        const localBlogEvent: ILogEvent = super.getBlogEvent();

        localBlogEvent.Tags.push({
            TagName: ProlifeSdk.TagEmail_Subject,
            TagTypeId: ProlifeSdk.TagType_String,
            Value: this.mailSubject(),
        });

        this.mailFrom.getElement().forEach((tag: ITag) => {
            localBlogEvent.Tags.push(tag);
        });

        this.mailTo.getElement().forEach((tag: ITag) => {
            localBlogEvent.Tags.push(tag);
        });

        this.mailCc.getElement().forEach((tag: ITag) => {
            localBlogEvent.Tags.push(tag);
        });

        this.mailCcn.getElement().forEach((tag: ITag) => {
            localBlogEvent.Tags.push(tag);
        });
        localBlogEvent.EventType = "Blog.Mail";
        return localBlogEvent;
    }

    public getValidationMessage(): string[] {
        let message: string[] = super.getValidationMessage();

        if (this.subject() == null || this.subject().trim() == "")
            message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

        return message;
    }

    public onFileDropped(dataTransfer: DataTransfer) {
        if (dataTransfer.files.length > 0) {
            this.loadEmail(dataTransfer.files[0]);
        }
    }

    public loadEmail(file: File) {
        const data = { mail: file, path: "" };

        if (parseInt(this.commessaId() || "-1") <= 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.Blog.LoadMissingObjectWarning);
            return;
        }

        const jobOrderId = parseInt(this.commessaId());
        this.jobOrderService.get(jobOrderId).then((jobOrder: IJobOrder) => {
            const destinationPath =
                "/" +
                ProlifeSdk.TextResources.Blog.Jobs +
                "/" +
                (jobOrder.Name || ProlifeSdk.TextResources.Blog.NoJobs) +
                "/";
            data.path = destinationPath;

            const ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);

            const uploadDef = ajaxService.Upload<IBlogMailData>("Blog-api/Mail", "MailUpload", data, false);
            uploadDef.then(this.onLoadEmail.bind(this));

            this.desktopService.AddUploadNotification("E-Mail", uploadDef);
        });
    }

    public openMailClient() {
        const options: ShowModalOptions = {
            noPrompt: false,
            fade: false,
        };

        const dialogViewModel = {
            templateName: "mail-client",
            templateUrl: "blog/templates/events-details",
            title: ProlifeSdk.TextResources.Blog.EmailDialogTitle,
            close: () => {
                (<IDialog>dialogViewModel).modal.close();
            },
            action: () => {
                (<IDialog>dialogViewModel).modal.close(true);
            },
            selectedEmailId: "",
            selectedAccountId: 0,
            selectedFolder: "",
        };

        (<any>window).onMailClientMailSelected = (accountId: number, folder: string, mailId: string) => {
            dialogViewModel.selectedEmailId = mailId;
            dialogViewModel.selectedAccountId = accountId;
            dialogViewModel.selectedFolder = folder;
        };

        this.dialogService.ShowModal<void>(dialogViewModel, "mail-client-dialog", options).then(() => {
            const options: AjaxOptions = {
                methodData: {
                    accountId: dialogViewModel.selectedAccountId,
                    folder: dialogViewModel.selectedFolder,
                    mailId: dialogViewModel.selectedEmailId,
                },
                background: false,
            };

            const ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);
            ajaxService.Post("Blog-api/Mail", "MailFromIMAP", options).then(this.onLoadEmail.bind(this));
        });
    }

    private async onLoadEmail(mail: IBlogMailData): Promise<void> {
        this.mailSubject(mail.Subject);

        this.mailFrom.clear();
        await this.populateContacts(mail.Senders, this.mailFrom, ProlifeSdk.TagEmail_From);

        this.mailTo.clear();
        await this.populateContacts(mail.To, this.mailTo, ProlifeSdk.TagEmail_To);

        this.mailCc.clear();
        await this.populateContacts(mail.Cc, this.mailCc, ProlifeSdk.TagEmail_Cc);

        this.mailCcn.clear();
        await this.populateContacts(mail.Bcc, this.mailCcn, ProlifeSdk.TagEmail_Ccn);

        this.eventDate(moment(mail.MailDate).toDate());
        this.SetAttachments(mail.Attachments);
        this.message(mail.Body);
        this.messageTrigger.valueHasMutated();
    }

    private async populateContacts(sourceContacts: string[], targetList: ContactList, tagType: string) {
        const senders = [];
        for (const sender of sourceContacts) {
            const tag = await this.createTagForAddress(tagType, sender);
            senders.push(tag);
        }
        targetList.addElements(senders);
    }

    public onInvalidDrop() {
        const infoToast = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        infoToast.Warning(ProlifeSdk.TextResources.Blog.InvalidDrop);
    }

    private async createTagForAddress(addressType: string, address: string): Promise<Tag> {
        const iTag = <ITag>{
            TagName: addressType,
            TagTypeId: ProlifeSdk.TagType_String,
            Value: address,
            DisplayName: address,
        };

        const tag = new Tag();
        await tag.LoadItemFromObject(iTag);
        return tag;
    }
}
