import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 12/09/2017
 * Time: 15:21
 * To change this template use File | Settings | File Templates.
 */

export class DescriptionPopOver {
    public name: string = "description-popover";
    public data: ko.Observable<any> = ko.observable();
    public templateUrl: string = "survey/templates";
    public customClass: string;

    constructor(data: PopOverData) {
        this.data(data);
    }
}

export class PopOverData {
    public Description: ko.Observable<string>;
    public isVisible: ko.Observable<boolean> = ko.observable(false);

    constructor(description: ko.Observable<string>) {
        this.Description = description;
    }
}