import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ITodoListService, IWorkflowCategory } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class WorkflowCategoryControlsEntityProvider implements IControlsEntityProvider
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;
    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor() {}

    public findEntities(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.todoListService.GetWorkflowCategories(query.term, false)
                .then((data) => {
                    query.callback({
                        results: data.map((t : IWorkflowCategory) => {
                            return {
                                id: t.Id,
                                text: t.Name
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback)
    {
        var id = parseInt(<string> $(element).val());

        if(!isNaN(id) && id > 0) {
            this.todoListService.GetWorkflowCategory(id)
                .then((t : IWorkflowCategory) => callback({
                    id: t.Id,
                    text: t.Name
                }));
        }
    }
}