import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../../Components/Layouts";
import { SelectMultiple } from "../../../../../Components/SelectMultiple";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { JobOrderTypesDataSource } from "../../../../../DataSources/JobOrderTypesDataSource";
import { JobOrderLogicalStates, JobOrderStatesDataSource } from "../../../../../DataSources/JobOrderStatesDataSource";
import { JobOrderMetadataDataSource } from "../../../../../DataSources/JobOrderMetadataDataSource";
import { CustomersDataSource } from "../../../../../DataSources/CustomersDataSource";
import { WorkflowCategoriesDataSource } from "../../../../../DataSources/WorkflowCategoriesDataSource";
import { WorkflowStatesDataSource } from "../../../../../DataSources/WorkflowStatesDataSource";
import { WorkflowOutcomesDataSource } from "../../../../../DataSources/WorkflowOutcomesDataSource";
import { WorkflowsAnalysisFilters } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { CheckBox } from "../../../../../Components/Checkbox";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { If } from "../../../../../Components/IfIfNotWith";
import { JobOrderLogicalStateSelector } from "../../../../../Components/JobOrderLogicalStateSelector";

const styleSheet = jss.createStyleSheet({
    filters: {
        columnGap: "5px",

        "& .form-group": {
            margin: "0px",
        },
    },
});
const { classes } = styleSheet.attach();

type FiltersProps = {
    initialState?: WorkflowsAnalysisFilters;
    onFiltersChanged?: (filters: WorkflowsAnalysisFilters) => void;
};

export function Filters(props: FiltersProps) {
    const C = require("./Filters")._Filters as typeof _Filters;
    return <C {...props} />;
}

export class _Filters {
    static defaultProps: Partial<FiltersProps> = {};

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    private CanViewAll: ko.Observable<boolean> = ko.observable(false);

    private SelectedJobOrderTypes: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrderStates: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrderClassifications: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedCustomers: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedWorkflowCategories: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedWorkflowStates: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedWorkflowOutcomes: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrderLogicalState: ko.Observable<JobOrderLogicalStates> = ko.observable(
        JobOrderLogicalStates.Opened
    );
    private ViewAll: ko.Observable<boolean> = ko.observable(false);

    private jobOrderTypesDataSource: JobOrderTypesDataSource = new JobOrderTypesDataSource();
    private jobOrderStatesDataSource: JobOrderStatesDataSource = new JobOrderStatesDataSource();
    private jobOrdersClassificationDataSource: JobOrderMetadataDataSource = new JobOrderMetadataDataSource();
    private customersDataSource: CustomersDataSource = new CustomersDataSource();
    private workflowCategoriesDataSource: WorkflowCategoriesDataSource = new WorkflowCategoriesDataSource();
    private workflowStatesDataSource: WorkflowStatesDataSource = new WorkflowStatesDataSource();
    private workflowOutcomesDataSource: WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();

    private subscriptions: ko.Subscription[] = [];

    constructor(private props: FiltersProps) {
        this.customersDataSource.setReturnGroupedData(false);

        this.initializeFilters();
        this.initializeRights();
    }

    private initializeFilters() {
        const initialState = this.props.initialState;

        if (initialState) {
            this.SelectedJobOrderTypes(initialState.jobOrderTypes);
            this.SelectedJobOrderStates(initialState.jobOrderStates);
            this.SelectedJobOrderClassifications(initialState.jobOrderClassifications);
            this.SelectedCustomers(initialState.customers);
            this.SelectedWorkflowCategories(initialState.workflowCategories);
            this.SelectedWorkflowStates(initialState.workflowStates);
            this.SelectedWorkflowOutcomes(initialState.workflowOutcomes);
            this.SelectedJobOrderLogicalState(initialState.jobOrderLogicalState);
            this.ViewAll(initialState.viewAll);
        }
    }

    private async initializeRights(): Promise<void> {
        const canViewAll = await this.authorizationsService.isAuthorized("JobOrders_ViewAllJobOrders");
        this.CanViewAll(canViewAll);
    }

    public componentDidMount() {
        this.subscriptions.push(this.SelectedJobOrderTypes.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedJobOrderStates.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedJobOrderClassifications.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedCustomers.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedWorkflowCategories.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedWorkflowStates.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.SelectedWorkflowOutcomes.subscribe(() => this.onFiltersChanged()));
        this.subscriptions.push(this.ViewAll.subscribe(() => this.onFiltersChanged()));
    }

    private onFiltersChanged() {
        this.props.onFiltersChanged?.({
            jobOrderTypes: this.SelectedJobOrderTypes(),
            jobOrderStates: this.SelectedJobOrderStates(),
            jobOrderClassifications: this.SelectedJobOrderClassifications(),
            customers: this.SelectedCustomers(),
            workflowCategories: this.SelectedWorkflowCategories(),
            workflowStates: this.SelectedWorkflowStates(),
            workflowOutcomes: this.SelectedWorkflowOutcomes(),
            jobOrderLogicalState: this.SelectedJobOrderLogicalState(),
            viewAll: this.ViewAll(),
        });
    }

    public componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.dispose());
    }

    private renderFirstFiltersLine() {
        return (
            <>
                <Layout.Grid.Cell row={1} column={"1/3"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.customersDataSource}
                        value={this.SelectedCustomers}
                        label={TextResources.JobOrder.WorkflowsAnalysisCustomersLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={3} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrderTypesDataSource}
                        value={this.SelectedJobOrderTypes}
                        label={TextResources.JobOrder.WorkflowsAnalysisJobOrderTypeLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={4} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrderStatesDataSource}
                        value={this.SelectedJobOrderStates}
                        label={TextResources.JobOrder.WorkflowsAnalysisJobOrderStateLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={5} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrdersClassificationDataSource}
                        value={this.SelectedJobOrderClassifications}
                        label={TextResources.JobOrder.WorkflowsAnalysisJobOrderClassificationLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
            </>
        );
    }

    private handleLogicalStateChange(logicalState: JobOrderLogicalStates) {
        this.SelectedJobOrderLogicalState(logicalState);
        this.SelectedJobOrderStates([]);

        this.jobOrderStatesDataSource.setLogicalState(logicalState);

        this.onFiltersChanged();
    }

    private renderSecondFiltersLine() {
        return (
            <>
                <Layout.Grid.Cell row={2} column={1} className="flex-vertical">
                    <JobOrderLogicalStateSelector
                        value={this.SelectedJobOrderLogicalState}
                        onChange={this.handleLogicalStateChange.bind(this)}
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={2} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.workflowCategoriesDataSource}
                        value={this.SelectedWorkflowCategories}
                        label={TextResources.JobOrder.WorkflowsAnalysisWorkflowCategoriesLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={3} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.workflowStatesDataSource}
                        value={this.SelectedWorkflowStates}
                        label={TextResources.JobOrder.WorkflowsAnalysisWorkflowStatesLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={4} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.workflowOutcomesDataSource}
                        value={this.SelectedWorkflowOutcomes}
                        label={TextResources.JobOrder.WorkflowsAnalysisWorkflowOutcomesLabel}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        hideSelectedDataFromMenu
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={5} className="flex-vertical flex-end">
                    <If condition={this.CanViewAll}>
                        {() => <CheckBox checked={this.ViewAll} label={TextResources.JobOrder.ViewAllJobOrders} />}
                    </If>
                </Layout.Grid.Cell>
            </>
        );
    }

    render() {
        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["min-content", "min-content"]}
                columns={["1fr", "1fr", "1fr", "1fr", "1fr"]}
                className={classes.filters}
            >
                {this.renderFirstFiltersLine()}
                {this.renderSecondFiltersLine()}
            </Layout.Grid>,
            this,
            "waf"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(Filters);
}
