import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { SystemHeader } from "./desktop/SystemHeader";
import { SignalrConnectionManager } from "./desktop/SignalrConnectionManager";
import { ITrustAuthorizationProcessService } from "../Invoices/TrustAuthorizationProcessService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IService } from "../Core/interfaces/IService";
import { IDesktopService, ISignalrConnection } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ITrustAuthorizationRequest } from "../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";
import { IChangelog } from "../ProlifeSdk/interfaces/desktop/IChangelog";
import { PromiseWithProgress } from "../Core/Deferred";
import { LazyImport } from "../Core/DependencyInjection";
import { MailTemplatesSettingsManager } from "./desktop/settings/MailTemplatesSettingsManager";
import { CustomTile } from "../ProlifeSdk/interfaces/desktop/CustomTile";
import { ResponseData } from "../Core/response/ResponseBase";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { Settings } from "../ProlifeSdk/interfaces/desktop/Settings";

export class DesktopService implements IDesktopService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public SignalrConnection: ISignalrConnection;
    public OperationsProgressManager: OperationsProgressViewModel;
    public TrustAuthorizationRequestsManager: TrustAuthorizationRequestsManager;

    private blockUiCalls = 0;

    public SystemHeader: SystemHeader;

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IDesktopService>(), this);

        //Avvio l'ascolto sugli hub signalr
        this.SignalrConnection = new SignalrConnectionManager(this.serviceLocator);
    }

    async getDesktopSettings(): Promise<Settings> {
        try {
            const response = await this.ajaxServiceNew.Get<ResponseData<Settings>>("dt/settings", "", {});

            if (!response.succeeded) throw response.errors;

            return response.data;
        } catch (error) {
            console.error(error);
            this.infoToastService.Error(TextResources.Desktop.DesktopSettingsError);

            return {
                id: 0,
                customTilesFirstGroupTitle: null,
                customTilesSecondGroupTitle: null,
                systemMessagesGroupTitle: null,
            } as Settings;
        }
    }

    async getCustomTiles(): Promise<CustomTile[]> {
        try {
            const response = await this.ajaxServiceNew.Get<ResponseData<CustomTile[]>>("dt/CustomTiles", "", {});

            if (!response.succeeded) throw response.errors;

            return response.data;
        } catch (error) {
            console.error(error);
            this.infoToastService.Error(TextResources.Desktop.CustomTilesError);

            return [];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public BlockPageUI(message: string) {
        this.blockUiCalls = this.blockUiCalls + 1;
        //(<any>$).blockUI({ baseZ : 20000, message: '<h3>' + message + '</h3>', css: { backgroundColor: 'rgb(203, 137, 15)', color: '#fff', border : '0', padding : '20px'} });
    }

    public UnblockPageUI() {
        this.blockUiCalls = this.blockUiCalls > 0 ? this.blockUiCalls - 1 : 0;

        /*if(this.blockUiCalls == 0)
            (<any>$).unblockUI();*/
    }

    public Connect() {
        this.SignalrConnection.StartConnection();
    }

    public InitializeService() {
        new MailTemplatesSettingsManager();

        this.OperationsProgressManager = new OperationsProgressViewModel(this.serviceLocator, this);
        this.TrustAuthorizationRequestsManager = new TrustAuthorizationRequestsManager(this.serviceLocator);

        this.SignalrConnection.Initialize();
    }

    public StartBackup(): Promise<string> {
        return this.ajaxService.Post("Desktop-api", "Backup/StartBackup", { methodData: {}, background: true });
    }

    /*public GetBackupProgress(guid : string) : Promise<string>
    {
        return this.ajaxService.Get("Desktop-api", "Backup/GetBackupProgress?backupGuid=" + guid, { background : true })
            ;
    }*/

    public isMobileBrowser(): boolean {
        if (navigator.userAgent.length < 4) return false;
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                navigator.userAgent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                navigator.userAgent.substr(0, 4)
            )
        )
            return true;
        return false;
        //return true;
    }

    public isIOsSystem(): boolean {
        return navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
    }

    getServiceType(): string {
        return ProlifeSdk.DesktopServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public GetNewGuid(): string {
        return (
            this.s4() +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            this.s4() +
            this.s4()
        );
    }

    private s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    public StartSaving(message: string) {
        return this.OperationsProgressManager.StartOperation(message);
    }

    public AddUploadNotification(fileName: string, deferred: PromiseWithProgress<any>) {
        this.OperationsProgressManager.StartUpload(fileName, deferred);
    }

    public AddDownloadNotification(fileName: string, deferred: PromiseWithProgress<void>) {
        this.OperationsProgressManager.StartDownload(fileName, deferred);
    }

    public EndSaving(guid: string) {
        this.OperationsProgressManager.EndOperation(guid);
    }

    public AddTrustAuthorizationPendingRequests(requests: ITrustAuthorizationRequest[]): void {
        this.TrustAuthorizationRequestsManager.AddPendingRequests(requests);
    }

    public RemoveTrustAuthorizationPendingRequest(requestId: string): void {
        this.TrustAuthorizationRequestsManager.RemovePendingRequest(requestId);
    }

    public IsRequestOnEvaluation(requestId: string): boolean {
        return this.TrustAuthorizationRequestsManager.IsRequestOnEvaluation(requestId);
    }

    public GetOperationsProgressVM(): any {
        return this.OperationsProgressManager;
    }

    public GetTrustAuthorizationRequestsVM(): any {
        return this.TrustAuthorizationRequestsManager;
    }

    public GetChangelog(): Promise<IChangelog> {
        return this.ajaxService.Post("Desktop-api/Changelog", "GetChangelog", {});
    }

    public SaveChangelogReadingStatus(version: string): Promise<void> {
        return this.ajaxService.Post("Desktop-api/Changelog", "SaveChangelogReadingStatus", {
            methodData: {
                Version: version,
            },
        });
    }
}

interface IOperation {
    Guid: string;
    Message: string;
}

export class OperationsProgressViewModel {
    public templateName: string = "operations-notifications";
    public templateUrl: string = "desktop/templates";

    private OperationsInProgress: ko.ObservableArray<IOperation> = ko.observableArray([]);
    public UploadInProgress: ko.ObservableArray<FileMovement> = ko.observableArray([]);
    public DownloadInProgress: ko.ObservableArray<FileMovement> = ko.observableArray([]);

    public IsVisible: ko.Computed<boolean>;

    public NumberOfOperationsInProgress: ko.Computed<number>;
    public UploadDownloadOperationsInProgress: ko.Computed<FileMovement[]>;

    constructor(private serviceLocator: IServiceLocator, private desktopService: DesktopService) {
        this.NumberOfOperationsInProgress = ko.computed(() => {
            return this.UploadInProgress().length + this.DownloadInProgress().length;
        });

        this.IsVisible = ko.computed(() => {
            return (
                this.OperationsInProgress().length > 0 ||
                this.UploadInProgress().length > 0 ||
                this.DownloadInProgress().length > 0
            );
        });

        this.UploadDownloadOperationsInProgress = ko.computed(() => {
            return this.DownloadInProgress().concat(this.UploadInProgress());
        });
    }

    public StartOperation(message: string) {
        var guid = this.desktopService.GetNewGuid();
        this.OperationsInProgress.push({ Guid: guid, Message: message });
        return guid;
    }

    public EndOperation(guid: string) {
        var matches: any[] = this.OperationsInProgress().filter((o) => {
            return o.Guid == guid;
        });

        if (matches.length > 0) this.OperationsInProgress.remove(matches[0]);
    }

    public StartUpload(fileName: string, deferred: PromiseWithProgress<any>) {
        new FileMovement(this.serviceLocator, fileName, deferred, this.UploadInProgress);
    }

    public StartDownload(fileName: string, deferred: PromiseWithProgress<any>) {
        new FileMovement(this.serviceLocator, fileName, deferred, this.DownloadInProgress, true);
    }
}

export class FileMovement {
    private toastService: IInfoToastService;
    public Description: string;
    public Progress: ko.Observable<number> = ko.observable(0);

    constructor(
        serviceLocator: IServiceLocator,
        fileName: string,
        deferred: PromiseWithProgress<any>,
        collection: ko.ObservableArray<FileMovement>,
        isDownload: boolean = false
    ) {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.Description = (isDownload ? "Download: " : "Upload: ") + fileName;

        collection.push(this);

        deferred
            .progress((info) => {
                this.Progress(Math.round(info.percentComplete * 100));
            })
            .then(() => {
                collection.remove(this);
            })
            .catch(() => {
                collection.remove(this);
                var msg: string = String.format(
                    ProlifeSdk.TextResources.Desktop.FileOperationError,
                    isDownload
                        ? ProlifeSdk.TextResources.Desktop.FileDownload
                        : ProlifeSdk.TextResources.Desktop.FileUpload,
                    fileName
                );
                this.toastService.Error(msg);
            });
    }
}

export class TrustAuthorizationRequestsManager {
    public Requests: ko.ObservableArray<PendingRequest> = ko.observableArray([]);
    public TotalPendingRequests: ko.Computed<number>;

    public RequestsOnEval: ko.Computed<PendingRequest[]>;
    public PendingRequests: ko.Computed<PendingRequest[]>;

    public RequestsOnEvalNumber: ko.Computed<number>;
    public PendingRequestsNumber: ko.Computed<number>;

    public PendingRequestsSentByMe: ko.Computed<boolean>;

    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    constructor(private serviceLocator: IServiceLocator) {
        this.trustAuthorizationProcessService = <ITrustAuthorizationProcessService>(
            this.serviceLocator.findService(ProlifeSdk.TrustAuthorizationProcessServiceType)
        );

        this.TotalPendingRequests = ko.computed(() => {
            return this.Requests().length;
        });

        this.RequestsOnEval = ko.computed(() => {
            return this.Requests().filter(
                (r: PendingRequest) => r.State == ProlifeSdk.TrustAuthorizationRequestOnEvaluation
            );
        });

        this.PendingRequests = ko.computed(() => {
            return this.Requests().filter(
                (r: PendingRequest) => r.State != ProlifeSdk.TrustAuthorizationRequestOnEvaluation
            );
        });

        this.RequestsOnEvalNumber = ko.computed(() => {
            return this.RequestsOnEval().length;
        });

        this.PendingRequestsNumber = ko.computed(() => {
            return this.PendingRequests().length;
        });

        this.PendingRequestsSentByMe = ko.computed(() => {
            if (this.PendingRequests().length == 0) return false;

            return this.PendingRequests()[0].SentByMe;
        });
    }

    public AddPendingRequests(pendingRequests: ITrustAuthorizationRequest[]): void {
        pendingRequests.forEach((r: ITrustAuthorizationRequest) => {
            if (this.Requests().filter((pr: PendingRequest) => pr.Id == r.Id).length == 0)
                this.Requests.push(this.CreatePendingRequestViewModel(r));
        });
    }

    public RemovePendingRequest(requestId: string): void {
        var pendingRequests: PendingRequest[] = this.Requests();
        for (var i: number = 0; i < pendingRequests.length; i++) {
            if (pendingRequests[i].Id == requestId) {
                pendingRequests.splice(i, 1);
                break;
            }
        }
        this.Requests(pendingRequests);
    }

    public IsRequestOnEvaluation(requestId: string): boolean {
        return this.Requests().filter((r: PendingRequest) => r.Id == requestId).length != 0;
    }

    private CreatePendingRequestViewModel(request: ITrustAuthorizationRequest): PendingRequest {
        return new PendingRequest(this.serviceLocator, request);
    }
}

export class PendingRequest {
    public Id: string;
    public ApplicantResource: string;
    public Resource: string;
    public Document: string;
    public TotalPrice: number;
    public Customer: string;
    public JobOrder: string;
    public RequestDate: string;
    public State: number;

    public SentByMe: boolean = false;

    public CustomerLink: string;
    public JobOrderLink: string;

    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;
    private authorizationsService: IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator, private request: ITrustAuthorizationRequest) {
        this.trustAuthorizationProcessService = <ITrustAuthorizationProcessService>(
            this.serviceLocator.findService(ProlifeSdk.TrustAuthorizationProcessServiceType)
        );
        this.authorizationsService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);

        this.Id = this.request.Id;
        this.ApplicantResource = this.request.ApplicantResourceName;
        this.Resource = this.request.ResourceName;
        this.Document = this.request.VatRegisterDocumentLabel;
        this.TotalPrice = this.request.TotalPrice;
        this.Customer = this.request.CustomerName;
        this.JobOrder = this.request.JobOrderName;
        this.RequestDate = moment(this.request.RequestDate).format("L LT");
        this.State = this.request.State;

        this.SentByMe =
            !this.authorizationsService.isAuthorized("Customers_CanApproveExtraTrust") &&
            this.State != ProlifeSdk.TrustAuthorizationRequestOnEvaluation;

        this.CustomerLink =
            "#/" + ProlifeSdk.TextResources.Customers.CustomerURL.replace("{0}", this.request.CustomerId.toString());
        this.JobOrderLink = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + this.request.JobOrderId;
    }

    public Open(): Promise<void> {
        if (this.authorizationsService.isAuthorized("Customers_CanApproveExtraTrust"))
            return this.trustAuthorizationProcessService.ShowTrustAuthorizationRequestDialog(this.request, true);

        return this.trustAuthorizationProcessService.ShowTrustAuthorizationAbortDialog(this.request);
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new DesktopService(serviceLocator);
}
