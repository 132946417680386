import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SchedulesDataSource, SchedulesFilterMode, SchedulesOrderBy } from "../../../DataSources/SchedulesDataSource";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ScheduleDetailViewModel } from "./ScheduleDetailViewModel";
import { LightInvoicesWithSchedulesViewModel } from "./LightInvoicesWithSchedulesViewModel";
import { ScheduleViewModel } from "./ScheduleViewModel";
import { InvoiceDetailsDialog } from "./InvoiceDetailsDialog";
import { CustomersDataSource } from "../../../DataSources/CustomersDataSource";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { ILightInvoicesWithSchedules } from "../../../ProlifeSdk/interfaces/schedule/ILightInvoicesWithSchedules";
import { ICurrencyInfoForSchedule } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IDialogsService, IDialog } from "../../../Core/interfaces/IDialogsService";
import { ISchedule } from "../../../ProlifeSdk/interfaces/schedule/ISchedule";

export class SchedulePanelViewModel {
    public SchedulesDataSource : SchedulesDataSource = new SchedulesDataSource();
    public CustomersDataSource : CustomersDataSource = new CustomersDataSource();

    public SearchFilter : ko.Observable<string> = ko.observable().extend({ throttle: 500 });

    public ActiveList : ko.Observable<SchedulesFilterMode> = ko.observable(SchedulesFilterMode.WithAtLeastOneExpiredSchedule);
    public OrderBy : ko.Observable<SchedulesOrderBy> = ko.observable(SchedulesOrderBy.DocumentDate);
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public CustomerId : ko.Observable<number> = ko.observable();

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;

    public ExcelExporterId: string;
    public ExcelExporterMethod: string;
    
    constructor()
    {
        this.ExcelExporterId = "Schedule/ScheduleExport";
        this.ExcelExporterMethod = "GenerateExcel";

        this.CustomersDataSource.setReturnGroupedData(false);

        this.ActiveList.subscribe((value) => {
            this.SchedulesDataSource.setFilterMode(value);
        });

        this.OrderBy.subscribe((value) => {
            this.SchedulesDataSource.setOrderBy(value);
        });

        this.StartDate.subscribe((value) => {
            this.SchedulesDataSource.setStartDate(value);
        });

        this.EndDate.subscribe((value) => {
            this.SchedulesDataSource.setEndDate(value);
        });

        this.CustomerId.subscribe((value) => {
            this.SchedulesDataSource.setCustomerId(value);
        });
    }

    public excelExporterDataProvider() {
        return {
            textFilter: this.SearchFilter(),
            skip: 0,
            count: 10000000,
            filterMode: this.ActiveList(),
            customerId: this.CustomerId(),
            orderBy: this.OrderBy(),
            startDate: this.StartDate(),
            endDate: this.EndDate()
        };
    }
    
    public viewInvoiceWithoutSchedule() {
        this.ActiveList(SchedulesFilterMode.WithoutSchedules);
    }

    public viewInvoiceWithExpiredSchedule() {
        this.ActiveList(SchedulesFilterMode.WithAtLeastOneExpiredSchedule);
    }

    public viewInvoiceWithSchedule() {
        this.ActiveList(SchedulesFilterMode.WithSchedulesToPay);
    }

    public viewInvoiceWithScheduleEnded() {
        this.ActiveList(SchedulesFilterMode.WithOnlyClosedSchedules);
    }

    public orderByDocumentDate() {
        this.OrderBy(SchedulesOrderBy.DocumentDate);
    }

    public orderByOpenedSchedules() {
        this.OrderBy(SchedulesOrderBy.FirstOpenScheduleDate);
    }

    public orderByCustomerName() {
        this.OrderBy(SchedulesOrderBy.CustomerName);
    }

    public ShowDetails(model : ILightInvoicesWithSchedules) {
        let currencyInfo : ICurrencyInfoForSchedule = {
            CurrencyExchangeValue: model.Invoice.CurrencyExchangeValue,
            CurrencyId: model.Invoice.CurrencyId,
            CurrencySymbol: model.Invoice.CurrencySymbol
        };

        let invoice = new LightInvoicesWithSchedulesViewModel(model.Invoice, model.Schedules.map(s => new ScheduleViewModel(s, currencyInfo, model.Customer)), model.Customer)
        var details = new ScheduleDetailViewModel(invoice);
        var dialog : IDialog = new InvoiceDetailsDialog(details);
        this.dialogService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
    }

    public OpenInvoice(model : ILightInvoicesWithSchedules) {
        this.documentsService.OpenDocumentOverlayById(model.Invoice.Id);
    }

    public ShowNote(model : ISchedule) {
        this.dialogService.Alert(model.Nota, ProlifeSdk.TextResources.Schedule.Notes, () => {});
    }
}