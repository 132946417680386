import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param } from "../../../../Core/utils/ComponentUtils";
import { IHumanResourceForList, IHumanResourcesService, IHumanResourceOrders, IHumanResource, IHumanResourceOrdersRoles, IHumanResourceOrdersSalaries, IHumanResourceOrdersWorkingHours, IHumanResourceResource, IHumanResourceOrdersWithSplitInfo, IHumanResourceWithSplitInfo } from "../../../../Users/HumanResourcesService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { RolesDataSource } from "../../../../DataSources/RolesDataSource";
import { UsersDataSource } from "../../../../DataSources/UsersDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ServiceOrderEditor } from "./ServiceOrderEditor";
import moment = require("moment");
import { IIdGeneratorService } from "../../../../ProlifeSdk/IdGeneratorService";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IException, IValidationException } from "../../../../Core/interfaces/IException";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IOperationalUnitsSettingsManager } from "../../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { ServiceOrderSplitEditor } from "./ServiceOrderSplitEditor";
import { IValidationService, IValidator, IValidation } from "../../../../ProlifeSdk/ValidationService";
import { InvalidHoursReportDialog } from "./InvalidHoursReportDialog";

const attributes = {
    HumanResource: "human-resource",
    Listener: "listener",
    InjectTo: "injectTo"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "resource-editor": {
               params?: {
                   HumanResource: string;
               },
               "human-resource": IHumanResourceForList | (() => string),
               "listener"?: IResourceListener | (() => string);
               "injectTo"?: (() => string);

           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IResourceListener {
    OnResourceChanged(sender: Resource) : void;
    OnResourceDeleted(sender: Resource) : void;
}

export interface IResourceParams {
    HumanResource: Param<IHumanResourceForList>;
    Listener: Param<IResourceListener>;
    InjectTo: ko.Observable<Resource>;
}

export class Resource implements IDataSourceListener {
    OriginalName : ko.Observable<string> = ko.observable(TextResources.ProlifeSdk.Loading);
    ResourceType: ko.Observable<number> = ko.observable();
    Name : ko.Observable<string> = ko.observable();
    Surname : ko.Observable<string> = ko.observable();
    SerialNumber : ko.Observable<string> = ko.observable();
    DefaultRoleId : ko.Observable<number> = ko.observable();
    UserId : ko.Observable<number> = ko.observable();
    Disabled : ko.Observable<boolean> = ko.observable();

    Deleted : ko.Observable<boolean> = ko.observable();
    IsNew : ko.Observable<boolean> = ko.observable();
    IsChanged : ko.Observable<boolean> = ko.observable();

    Orders : ko.ObservableArray<ServiceOrder> = ko.observableArray();

    RolesDataSource: RolesDataSource = new RolesDataSource();
    UsersDataSource : UsersDataSource = new UsersDataSource();

    HasActiveServiceOrder: ko.Computed<boolean>;

    private resourceForList : IHumanResourceForList;
    private listener: IResourceListener;
    private resource: IHumanResource;

    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService : IHumanResourcesService;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    private validator: IValidator<Resource>;

    constructor(params : IResourceParams) {
        this.resourceForList = ComponentUtils.parseParameter(params.HumanResource, null)();
        this.listener = ComponentUtils.parseParameter(params.Listener, null)();

        this.HasActiveServiceOrder = ko.computed(() => {
            const orders = this.Orders();
            const refDate = moment().endOf('day');
            
            const activeOrders = orders.filter(o => !o.ToDate() || moment(o.ToDate()).isAfter(refDate));

            return activeOrders.length > 0;
        });

        this.validator = (this.validationService.createValidator() as IValidator<Resource>)
            .isFalse((r) => r.HasActiveServiceOrder(), TextResources.ResourcesManager.ActiveServiceOrderOnDisabledOrDeletedResourceError, (r) => r.Deleted() || r.Disabled());

        params.InjectTo && params.InjectTo(this);

        this.ResourceType(this.resourceForList.ResourceType);
        this.RolesDataSource.setResourceType(this.resourceForList.ResourceType);

        this.load();
    }

    private async load() {
        if(this.resourceForList.Id <= 0) {
            this.OriginalName(this.humanResourcesService.getFullName({ Resource: { Id: -1 } as unknown as IHumanResourceResource } as unknown as IHumanResource, true));
            this.resource = {
                Resource: {
                    ResourceType: this.ResourceType(),
                    Deleted: this.Deleted(),
                    Disabled: this.Disabled(),
                    FkDefaultCharacter: this.DefaultRoleId(),
                    Id: -1,
                    ShowPreferred: false,
                    FkUser: this.UserId(),
                    Name: this.Name(),
                    SerialNumber: this.SerialNumber(),
                    Surname: this.Surname()
                },
                Orders: [],
                OrdersRoles: [],
                OrdersSalaries: [],
                OrdersWorkingHours: []
            };
            return;
        }

        this.resource = await this.humanResourcesService.GetHumanResource(this.resourceForList.Id);
        this.OriginalName(this.humanResourcesService.getFullName(this.resource, true));
        if(!this.resource || !this.resource.Resource)
            return;

        this.Name(this.resource.Resource.Name);
        this.Surname(this.resource.Resource.Surname);
        this.SerialNumber(this.resource.Resource.SerialNumber);
        this.DefaultRoleId(this.resource.Resource.FkDefaultCharacter);
        this.UserId(this.resource.Resource.FkUser);
        this.Disabled(this.resource.Resource.Disabled);
        this.Deleted(this.resource.Resource.Deleted);
        this.IsNew(!(this.resource.Resource.Id > 0));

        const orders : ServiceOrder[] = [];
        for(const order of this.resource.Orders.orderByDesc(o => o.FromDate)) {
            orders.push(new ServiceOrder(order, this.resource));
        }
        this.Orders(orders);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    async Save() {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving);

        try
        {
            const validationResult: boolean = this.validator.validateAndShowInfoToast(this);
            if (!validationResult)
                return;

            this.resource = await this.humanResourcesService.InsertOrUpdateHumanResource(this.getData());
            this.resourceForList.Id = this.resource.Resource.Id;
            
            await this.load();
            this.listener?.OnResourceChanged(this);
        }
        catch(e)
        {
            const ex : IException = e;
            if(ex.ExceptionType === ProlifeSdk.ServerException_ProLifeValidationException) {
                const dialog = new InvalidHoursReportDialog((ex as IValidationException).ValidationData);
                //this.infoToastService.Warning(ex.ExceptionMessage);
                dialog.showModal();
                return;
            } else if(ex.ExceptionType !== ProlifeSdk.ServerException_ProLife) {
                this.infoToastService.Error(ex.ExceptionMessage);
            }
        }
        finally
        {
            this.dialogsService.UnlockUI();
        }
    }
    
    async RestoreOriginalValues() {
        if(!await this.dialogsService.ConfirmAsync(TextResources.ResourcesManager.RestoreResourceDataWarning, TextResources.ResourcesManager.RestoreResourceDataWarningCancel, TextResources.ResourcesManager.RestoreResourceDataWarningConfirm))
            return;

        await this.load();
    }

    async Delete() {
        // N.B. la validazione in caso di eliminazione viene fatta sui dati originali perché la risorsa non viene risalvata in questo caso, quindi, impostando una data di chiusura sugli ordini di servizio
        // posso eliminare la risorsa senza aver effettivamente chiuso gli ordini di servizio perché il dato potrebbe non essere stato salvato prima di fare elimina
        if (!this.canBeDeleted()) {
            this.infoToastService.Error(TextResources.ResourcesManager.ActiveServiceOrderOnDisabledOrDeletedResourceError);
            return;
        }

        if (!await this.dialogsService.ConfirmAsync(TextResources.ResourcesManager.ResourcesDeleteWarning, TextResources.ResourcesManager.ResourcesDeleteWarningCancel, TextResources.ResourcesManager.ResourcesDeleteWarningConfirm))
            return;

        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving);

        try
        {
            await this.humanResourcesService.DeleteHumanResource(this.resourceForList.Id);

            this.Deleted(true);
            this.listener?.OnResourceDeleted(this);
        }
        catch(e)
        {
            const ex : IException = e;
            this.infoToastService.Error(ex.ExceptionMessage);
        }
        finally
        {
            this.dialogsService.UnlockUI();
        }
    }

    private canBeDeleted(): boolean {
        const orders = this.resource.Orders;
        const refDate = moment().endOf('day');
        
        const activeOrders = orders.filter(o => !o.ToDate || moment(o.ToDate).isAfter(refDate));

        return activeOrders.length === 0;
    }

    async Restore() {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving);

        try
        {
            await this.humanResourcesService.RestoreHumanResource(this.resourceForList.Id);

            this.Deleted(false);
            this.listener?.OnResourceChanged(this);
        }
        catch(e)
        {
            const ex : IException = e;
            this.infoToastService.Error(ex.ExceptionMessage);
        }
        finally
        {
            this.dialogsService.UnlockUI();
        }
    }

    DeleteOrder(serviceOrder: ServiceOrder) {
        this.Orders.remove(serviceOrder);
        this.IsChanged(true);
    }

    IntersectsWithOrders(serviceOrderId: number, fromDate: Date, toDate: Date, operationalUnitIt: number): boolean {
        const allOtherOrders = this.Orders().filter(o => o.Id() != serviceOrderId);
        const maxDate = moment("2100-01-01T00:00:00+01:00").toDate();
        const overlappingOrder = allOtherOrders.firstOrDefault(o => o.FromDate() <  (toDate ?? maxDate) && (o.ToDate() ?? maxDate) > fromDate && o.FkOperationalUnit() === operationalUnitIt);
        return !!overlappingOrder;
    }

    public newServiceOrderId() : number {
        return this.idGeneratorService.getNextId();
    }

    async NewServiceOrder() {
        const newOrder = new ServiceOrder({
            Id: this.newServiceOrderId(),
            FromDate: moment().toDate(),
            EstimatedHoursToWork: 0,
            MaxFlexiblePositive: 0,
            MaxFlexibleNegative: 0,
            FkOperationalUnit: null,
            FkResource: this.resourceForList.Id,
            HoursMonday: 0,
            HoursTuesday: 0,
            HoursWednesday: 0,
            HoursThursday: 0,
            HoursFriday: 0,
            HoursSaturday: 0,
            HoursSunday: 0
        }, this.resource);

        if(!await newOrder.Edit(this))
            return;

        const allOrders = this.Orders.peek();
        this.Orders.valueWillMutate();
        allOrders.push(newOrder);
        allOrders.sort((a,b) => b.FromDate().valueOf() - a.FromDate().valueOf());
        this.Orders.valueHasMutated();

        this.IsChanged(true);
    }

    async EditServiceOrder(serviceOrder : ServiceOrder) {
        if(!await serviceOrder.Edit(this))
            return;

        this.IsChanged(true);
    }

    async SplitServiceOrder(serviceOrder : ServiceOrder) {
        if(!await serviceOrder.Split(this))
            return;

        this.IsChanged(true);
    }

    public addServiceOrder(serviceOrder: ServiceOrder) {
        const orders = this.Orders();
        orders.push(serviceOrder);
        this.Orders(orders.orderByDesc(o => o.FromDate()));
    }

    getData(): IHumanResourceWithSplitInfo {
        return {
            Resource: {
                Id: this.resource.Resource.Id,
                Name: this.Name(),
                Surname: this.Surname(),
                ResourceType: this.resource.Resource.ResourceType,
                Deleted: false,
                Disabled: this.Disabled(),
                FkDefaultCharacter: null,
                ShowPreferred: false,
                FkUser: this.UserId(),
                SerialNumber: this.SerialNumber()
            },
            Orders: this.Orders().map(o => o.getData()),
            OrdersRoles: this.Orders().selectMultiple(o => o.getRolesData()),
            OrdersSalaries: this.Orders().selectMultiple(o => o.getCostsData()),
            OrdersWorkingHours: this.Orders().selectMultiple(o => o.getWorkingHoursData())
        }
    }
}

function renderServiceOrdersList() {
    let vm : Resource;
    let order: ServiceOrder;
    let role : ServiceOrderRole;
    let cost: ServiceOrderCost;

    return <div class="portlet box red">
                <div class="portlet-title">
                    <div class="caption">
                        <span>Ordini di servizio</span>
                    </div>
                    <div class="actions">
                        <a class="btn blue btn-sm" data-bind={{ click : vm.NewServiceOrder }}>
                            <i class="fa fa-plus"></i>&nbsp;Nuovo
                        </a>
                    </div>
                </div>
                <div class="portlet-body" style="padding: 0; overflow-y: auto; height: 485px;">
                    <ko-bind data-bind={{ if: vm.Orders().length === 0 }}>
                        <h2 class="text-center">Nessun ordine di servizio</h2>
                    </ko-bind>
                    <ko-bind data-bind={{ foreach: { data: vm.Orders, as: 'order' } }}>
                        <div class="news-blocks margin-bottom-10" style="padding: 10px; position: relative;" data-bind={{ style: { background: order.IsChanged() ? '#f3de9d' : '#E4E4E4' }}}>
                            <div className="flex-container flex-child-center">
                                <h3 class="flex-1" style="margin-top: 0;" data-bind={{ text : order.Title }}></h3>

                                <a href="#" class="btn btn-xs btn-primary" data-bind={{ click : vm.EditServiceOrder.bind(vm, order) }}>
                                    <i class="fa fa-pencil"></i>&nbsp;Modifica
                                </a>

                                <a href="#" class="btn btn-xs btn-success" data-bind={{ click : vm.SplitServiceOrder.bind(vm, order) }}>
                                    <i class="fa fa-cut"></i>&nbsp;Dividi
                                </a>
                            </div>

                            <div class="news-block-tags">
                                <strong>Dal&nbsp;<span data-bind={{ dateTimeText : order.FromDate }}></span>&nbsp;al&nbsp;<span data-bind={{ dateTimeText : order.ToDate, dateTextNoValue : 'In corso' }}></span></strong>

                                <strong class="pull-right" data-bind={{ text : order.OperationalUnitName }}></strong>
                            </div>

                            <div>
                                Ore di lavoro stimate:&nbsp; <span data-bind={{ numberText : order.EstimatedHoursToWork }}></span>
                            </div>

                            <br/>
                            <table class="table table-bordered table-advance">
                                <thead>
                                <tr>
                                    <th class="text-center" style="background-color: #989898; color: white;">LUN</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">MAR</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">MER</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">GIO</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">VEN</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">SAB</th>
                                    <th class="text-center" style="background-color: #989898; color: white;">DOM</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="text-center" data-bind={{ numberText : order.HoursMonday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursTuesday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursWednesday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursThursday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursFriday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursSaturday }}></td>
                                    <td class="text-center" data-bind={{ numberText : order.HoursSunday }}></td>
                                </tr>
                                </tbody>
                            </table>
                            <div>
                                <strong>Mansioni</strong>
                                <div data-bind={{ foreach: { data: order.Roles, as: 'role' }}} class="flex-container flex-wrap">
                                    <span class="label label-primary" style="margin-bottom: 2px" data-bind={{ text: role.Description, css: { 'label-info': role.IsDefault, 'label-primary': !role.IsDefault() }, style: { 'text-decoration': role.IsDeleted() ? 'line-through' : 'none' } }}></span>
                                </div>
                            </div>
                            <div>
                                <strong>Costi</strong>
                                <div data-bind={{ foreach: { data: order.Costs, as: 'cost' }}} class="flex-container flex-wrap">
                                    <span class="label label-primary" style="margin-bottom: 2px" data-bind={{ text: cost.Description, css: { 'label-info': cost.IsDefault, 'label-primary': !cost.IsDefault() }, style: { 'text-decoration': cost.IsDeleted() ? 'line-through' : 'none' } }}></span>
                                </div>
                            </div>
                        </div>
                    </ko-bind>
                </div>
            </div>

}

ko.components.register("resource-editor", {
    viewModel: {
        createViewModel: (params: IResourceParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            const vm = new Resource(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <ko-bind data-as={{vm}}>
                    <div class="flex-container flex-child-baseline">
                        <h1 class="flex-1" data-bind={{ text : vm.OriginalName }}></h1>
                        <i class="fa" style="font-size: 36px" data-bind={{ css: { 'fa-user': vm.ResourceType() === 0, 'fa-cubes': vm.ResourceType() === 1 } }}></i>
                    </div>

                    <div class="form-body">
                        <div class="row">
                            <ko-bind data-bind={{ if: vm.ResourceType() === 0 }}>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">{TextResources.ResourcesManager.ResourceName}</label>
                                        <input type="text" class="form-control" spellCheck={false} data-bind={{ value: vm.Name, selectOnFocus: {} }} placeholder={TextResources.ResourcesManager.ResourceNamePlaceholder}/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">{TextResources.ResourcesManager.ResourceSurname}</label>
                                        <input type="text" class="form-control" spellCheck={false} data-bind={{ value : vm.Surname, selectOnFocus: {} }} placeholder={TextResources.ResourcesManager.ResourceSurnamePlaceholder} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">{TextResources.ResourcesManager.ResourceSerialNumber}</label>
                                        <input type="text" class="form-control" spellCheck={false} data-bind={{ value : vm.SerialNumber, selectOnFocus: {} }} placeholder={TextResources.ResourcesManager.ResourceSerialNumberPlaceholder} />
                                    </div>
                                </div>
                            </ko-bind>
                            <ko-bind data-bind={{ if: vm.ResourceType() === 1 }}>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label">{TextResources.ResourcesManager.ResourceDescription}</label>
                                        <input type="text" class="form-control" spellCheck={false} data-bind={{ value: vm.Name, selectOnFocus: {} }} placeholder={TextResources.ResourcesManager.ResourceDescriptionPlaceholder}/>
                                    </div>
                                </div>
                            </ko-bind>
                        </div>

                        <div class="row">
                            <ko-bind data-bind={{ if: vm.ResourceType() === 0 }}>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select2 label={TextResources.ResourcesManager.ResourceUser} dataSource={() => "vm.UsersDataSource"} listener={() => "$data"} value={() => "vm.UserId"} allowClear={true} placeholder={TextResources.ResourcesManager.ResourceUserPlaceholder}></select2>
                                    </div>
                                </div>
                            </ko-bind>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="control-label">{TextResources.ResourcesManager.ResourceDisabled}</label>
                                    <br/>
                                    <div class="checker">
                                        <span data-bind={{ css : { checked : vm.Disabled } }}>
                                            <input class="form-control" type="checkbox"  data-bind={{ checked : vm.Disabled }}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {renderServiceOrdersList()}

                        <div class="flex-container" style="justify-content: center;">
                            <button class="btn btn-primary" data-bind={{ asyncClick : vm.Save }}>
                                <i class="fa fa-floppy-o"></i>&nbsp;{TextResources.ResourcesManager.ResourceSave}
                            </button>
                            <button class="btn btn-danger" data-bind={{ asyncClick : vm.RestoreOriginalValues }}>
                                <i class="fa fa-history"></i>&nbsp;{TextResources.ResourcesManager.ResourceRestoreOriginalValues}
                            </button>

                            <ko-bind data-bind={{ ifnot: vm.IsNew }}>
                                <ko-bind data-bind={{ ifnot: vm.Deleted }}>
                                    <button class="btn btn-danger" data-bind={{ asyncClick : vm.Delete }}>
                                        <i class="fa fa-trash-o"></i>&nbsp;{TextResources.ResourcesManager.ResourceDelete}
                                    </button>
                                </ko-bind>

                                <ko-bind data-bind={{ if: vm.Deleted }}>
                                    <button class="btn btn-primary" data-bind={{ asyncClick : vm.Restore }}>
                                        <i class="fa fa-trash-o"></i>&nbsp;{TextResources.ResourcesManager.ResourceRestore}
                                    </button>
                                </ko-bind>
                            </ko-bind>

                        </div>
                    </div>
                </ko-bind>
            ]);
            
            return vm;
        },
    },
    template: []
});

export class ServiceOrder {
    IsNew: ko.Observable<boolean> = ko.observable();
    IsChanged : ko.Observable<boolean> = ko.observable(false);

    Id : ko.Observable<number> = ko.observable();
    Title: ko.Observable<string> = ko.observable();
    FromDate: ko.Observable<Date> = ko.observable();
    ToDate: ko.Observable<Date> = ko.observable();
    FkOperationalUnit: ko.Observable<number> = ko.observable();
    OperationalUnitName: ko.Observable<string> = ko.observable();
    EstimatedHoursToWork: ko.Observable<number> = ko.observable();
    MaxFlexiblePositive: ko.Observable<number> = ko.observable();
    MaxFlexibleNegative: ko.Observable<number> = ko.observable();
    Notes : ko.Observable<string> = ko.observable();
    ResourceType: ko.Observable<number> = ko.observable();

    HoursMonday: ko.Observable<number> = ko.observable();
    HoursTuesday: ko.Observable<number> = ko.observable();
    HoursWednesday: ko.Observable<number> = ko.observable();
    HoursThursday: ko.Observable<number> = ko.observable();
    HoursFriday: ko.Observable<number> = ko.observable();
    HoursSaturday: ko.Observable<number> = ko.observable();
    HoursSunday: ko.Observable<number> = ko.observable();

    SplitFrom: number;

    Roles: ko.ObservableArray<ServiceOrderRole> = ko.observableArray();
    Costs: ko.ObservableArray<ServiceOrderCost> = ko.observableArray();
    WorkingHours: ko.ObservableArray<ServiceOrderWorkingHour> = ko.observableArray();

    @LazyImportSettingManager(ProlifeSdk.OperationalUnitsSettingsServiceType)
    private ouSettings : IOperationalUnitsSettingsManager;

    constructor(private serviceOrder : IHumanResourceOrdersWithSplitInfo, private resource: IHumanResource) {
        this.load(serviceOrder, resource);
    }

    private load(order : IHumanResourceOrdersWithSplitInfo, resource: IHumanResource) {
        this.IsNew(order?.Id <= 0);

        this.Id(order?.Id ?? -1);
        this.Title(order.Title);
        this.FromDate(order.FromDate);
        this.ToDate(order.ToDate);
        this.FkOperationalUnit(order.FkOperationalUnit);
        this.OperationalUnitName(this.ouSettings.getById(order.FkOperationalUnit)?.Name ?? "N.D.");
        this.EstimatedHoursToWork(order.EstimatedHoursToWork);
        this.MaxFlexiblePositive(order.MaxFlexiblePositive);
        this.MaxFlexibleNegative(order.MaxFlexibleNegative);
        this.Notes(order.Notes);
        this.ResourceType(resource.Resource.ResourceType);
        this.HoursMonday(order.HoursMonday);
        this.HoursTuesday(order.HoursTuesday);
        this.HoursWednesday(order.HoursWednesday);
        this.HoursThursday(order.HoursThursday);
        this.HoursFriday(order.HoursFriday);
        this.HoursSaturday(order.HoursSaturday);
        this.HoursSunday(order.HoursSunday);
        this.SplitFrom = order.SplitFrom;

        this.LoadRoles(resource.OrdersRoles.filter(r => r.FkServiceOrder === order.Id));
        this.LoadCosts(resource.OrdersSalaries.filter(r => r.FkServiceOrder === order.Id));
        this.LoadWorkingHours(resource.OrdersWorkingHours.filter(r => r.ServiceOrderId === order.Id));
    }

    LoadWorkingHours(whs: IHumanResourceOrdersWorkingHours[]) {
        this.WorkingHours(whs.map(this.createWorkingHourViewModel, this));
    }

    LoadCosts(costs: IHumanResourceOrdersSalaries[]) {
        this.Costs(costs.map(this.createCostViewModel, this));
    }

    LoadRoles(roles: IHumanResourceOrdersRoles[]) {
        this.Roles(roles.map(this.createRoleViewModel, this));
    }

    private createRoleViewModel(role : IHumanResourceOrdersRoles) : ServiceOrderRole {
        return new ServiceOrderRole(role);
    }

    private createCostViewModel(cost : IHumanResourceOrdersSalaries) : ServiceOrderCost {
        return new ServiceOrderCost(cost);
    }
    
    private createWorkingHourViewModel(workingHours : IHumanResourceOrdersWorkingHours) : ServiceOrderWorkingHour {
        return new ServiceOrderWorkingHour(workingHours);
    }

    public Edit(resource: Resource) {
        return new ServiceOrderEditor({
            serviceOrder: this,
            resource: resource
        }).ShowModal();
    }

    public Split(resource : Resource) {
        return new ServiceOrderSplitEditor(this, resource).ShowModal();
    }

    getData(): IHumanResourceOrdersWithSplitInfo {
        return {
            Id: this.Id(),
            Title: this.Title(),
            FromDate: this.FromDate(),
            ToDate: this.ToDate(),
            FkOperationalUnit: this.FkOperationalUnit(),
            EstimatedHoursToWork: this.EstimatedHoursToWork(),
            MaxFlexiblePositive: this.MaxFlexiblePositive(),
            MaxFlexibleNegative: this.MaxFlexibleNegative(),
            FkResource: this.resource.Resource.Id,
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday(),
            Notes: this.Notes(),
            SplitFrom: this.SplitFrom
        }
    }

    getRolesData(): IHumanResourceOrdersRoles[] {
        return this.Roles().map(r => ({
            FkCharacter: r.Id(),
            FkServiceOrder: this.Id(),
            IncludeInMeanCostCalculations: r.IncludeInMeanCostCalculations(),
            IsDefault: r.IsDefault()
        }));
    }

    getCostsData(): IHumanResourceOrdersSalaries[] {
        return this.Costs().map(c => ({
            FkServiceOrder: this.Id(),
            FkWorkTimeCategoryId: c.Id(),
            HourSalary: c.HourSalary(),
            IsDefault: c.IsDefault(),
            MaxAnnualHoursAmount: c.MaxAnnualHours(),
            OffsetMaxAnnualHoursAmount: c.OffsetMaxAnnualHours(),
            MaxAnnualHoursAmountBehaviour: c.MaxAnnualHoursBehaviour(),
            MinAnnualHoursAmount: c.MinAnnualHours(),
            MinAnnualHoursAmountBehaviour: c.MinAnnualHoursBehaviour()
        }));
    }

    getWorkingHoursData(): IHumanResourceOrdersWorkingHours[] {
        return this.WorkingHours().map(w => ({
            Id: w.Id(),
            ServiceOrderId: this.Id(),
            DayOfWeek: w.DayOfWeek(),
            Start: moment(w.Start()).format("HH:mm:ss"),
            Duration: w.Duration(),
            End: moment(w.Start()).add('hours', w.Duration()).format("HH:mm:ss")
        }));
    }

    clone(newId : number) : ServiceOrder {
        const clonedSO : IHumanResourceOrders = Object.assign({}, this.serviceOrder, { Id: newId });
        const newSO = new ServiceOrder(clonedSO, this.resource);

        newSO.Roles(this.Roles().map(r => r.clone(newId)));
        newSO.Costs(this.Costs().map(r => r.clone(newId)));
        newSO.WorkingHours(this.WorkingHours().map(r => r.clone(newId)));

        return newSO;
    }
}

export class ServiceOrderRole {
    Id : ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    IncludeInMeanCostCalculations: ko.Observable<boolean> = ko.observable();
    IsDefault: ko.Observable<boolean> = ko.observable();
    IsDeleted: ko.Observable<boolean> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacterService : IUserCharactersSettingsManager;

    private userCharacter: IUserCharacter;

    constructor(role : IHumanResourceOrdersRoles) {
        this.userCharacter = this.userCharacterService.getUserCharacterById(role.FkCharacter);

        this.Id(role.FkCharacter);
        this.Description(this.userCharacter?.Description);
        this.IncludeInMeanCostCalculations(role.IncludeInMeanCostCalculations);
        this.IsDefault(role.IsDefault);
        this.IsDeleted(!!this.userCharacter.Eliminato);
    }

    clone(newServiceOrderId: number): ServiceOrderRole {
        return new ServiceOrderRole({
            FkCharacter: this.Id(),
            FkServiceOrder: newServiceOrderId,
            IncludeInMeanCostCalculations: this.IncludeInMeanCostCalculations(),
            IsDefault: this.IsDefault()
        });
    }
}

export class ServiceOrderCost {
    Id: ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    HourSalary: ko.Observable<number> = ko.observable();
    IsDefault: ko.Observable<boolean> = ko.observable();
    IsDeleted: ko.Observable<boolean> = ko.observable();
    MaxAnnualHours : ko.Observable<number> = ko.observable();
    OffsetMaxAnnualHours : ko.Observable<number> = ko.observable();
    MaxAnnualHoursBehaviour : ko.Observable<number> = ko.observable();
    MinAnnualHours : ko.Observable<number> = ko.observable();
    MinAnnualHoursBehaviour : ko.Observable<number> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoryService : IWorkTimeCategoriesSettingsManager;

    private workTimeCategory: IWorkTimeCategory;

    constructor(cost : IHumanResourceOrdersSalaries) {
        this.workTimeCategory = this.workTimeCategoryService.getById(cost.FkWorkTimeCategoryId);
        
        this.Id(cost.FkWorkTimeCategoryId);
        this.Description(this.workTimeCategory?.Name + ": " + numeral(cost.HourSalary).format("0,0.[#0] $"));
        this.HourSalary(cost.HourSalary);
        this.IsDefault(cost.IsDefault);
        this.IsDeleted(!!this.workTimeCategory.Deleted);
        this.MaxAnnualHours(cost.MaxAnnualHoursAmount);
        this.OffsetMaxAnnualHours(cost.OffsetMaxAnnualHoursAmount);
        this.MaxAnnualHoursBehaviour(cost.MaxAnnualHoursAmountBehaviour);
        this.MinAnnualHours(cost.MinAnnualHoursAmount);
        this.MinAnnualHoursBehaviour(cost.MinAnnualHoursAmountBehaviour);
    }

    clone(newServiceOrderId: number): ServiceOrderCost {
        return new ServiceOrderCost({
            FkWorkTimeCategoryId: this.Id(),
            FkServiceOrder: newServiceOrderId,
            HourSalary: this.HourSalary(),
            IsDefault: this.IsDefault(),
            MaxAnnualHoursAmount: this.MaxAnnualHours(),
            OffsetMaxAnnualHoursAmount: this.OffsetMaxAnnualHours(),
            MaxAnnualHoursAmountBehaviour: this.MaxAnnualHoursBehaviour(),
            MinAnnualHoursAmount: this.MinAnnualHours(),
            MinAnnualHoursAmountBehaviour: this.MinAnnualHoursBehaviour()
        });
    }
}

export class ServiceOrderWorkingHour {
    Id : ko.Observable<number> = ko.observable();
    DayOfWeek: ko.Observable<number> = ko.observable();
    Start: ko.Observable<Date> = ko.observable();
    Duration : ko.Observable<number> = ko.observable();

    constructor(workingHours : IHumanResourceOrdersWorkingHours) {
        this.Id(workingHours.Id);
        this.DayOfWeek(workingHours.DayOfWeek);
        this.Start(moment(workingHours.Start, "HH:mm:ss", true).toDate());
        this.Duration(workingHours.Duration);
    }

    clone(newServiceOrderId: number): ServiceOrderWorkingHour {
        return new ServiceOrderWorkingHour({
            Id: -1,
            ServiceOrderId: newServiceOrderId,
            DayOfWeek: this.DayOfWeek(),
            Duration: this.Duration(),
            Start: moment(this.Start()).format("HH:mm:ss"),
            End: moment(this.Start()).add(this.Duration(), 'hours').format("HH:mm:ss")
        });
    }
}