import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils, classNames } from "../Core/utils/ComponentUtils";
import jss from "jss";

const styleSheet = jss.createStyleSheet({
    dropDownList: {
        "&:required:invalid": {
            color: '#999'
        },

        "& > option": {
            color: 'black'
        }
    }
});
const { classes } = styleSheet.attach();

type DropdownListProps<T, P = number> = {
    value: ko.Observable<T|P>;
    values: T[];
    label?: string;
    simple?: boolean;
    noSelectionPlaceholder?: string;
    readonly?: ko.MaybeObservable;
    className?: string;
    containerClassName?: string;

    idGetter?: (item: T) => P;

    valueGetter: (item: T) => string;
    textGetter: (item: T) => string;
}

export function DropdownList<T, P>(props : DropdownListProps<T, P>) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const DL = require("./DropdownList")._DropdownList as typeof _DropdownList;
    return <DL {...props} />;
}

export class _DropdownList<T, P> {
    static defaultProps: Partial<DropdownListProps<any, any>> = {
        readonly: false
    }

    private select: HTMLSelectElement;
    private onChangeSubscription: ko.Subscription;

    constructor(private props : DropdownListProps<T, P>) {
        
    }
    
    componentDidMount() {
        this.onChangeSubscription = this.props.value.subscribe(this.onValueChanged.bind(this));
        this.onValueChanged(this.props.value());
    }

    componentWillUnmount() {
        this.onChangeSubscription.dispose();
    }

    private onValueChanged(value: T | P) {
        if (this.props.idGetter) {
            let item = this.props.values.firstOrDefault(i => this.props.idGetter(i) === value);
            this.select.value = !item ? "" : this.props.valueGetter(item);
        } else {
            this.select.value = this.props.valueGetter(value as T);
        }
    }
    
    private renderSelect() {
        const { valueGetter, textGetter, idGetter, value, values, noSelectionPlaceholder, readonly, className } = this.props;

        const onChange = ({ target }: Event) => {
            const item = values.firstOrDefault(v => valueGetter(v) === (target as HTMLSelectElement).value);
            const newValue = !item ? undefined : ((idGetter && idGetter(item)) ?? item);
            value(newValue);
        }

        const css = ComponentUtils.classNames("form-control", classes.dropDownList, className);
        const addProps: any = {};
        if (noSelectionPlaceholder)
            addProps.required = true;

        if (readonly)
            addProps.disabled = ko.utils.unwrapObservable(readonly);

        return  <select className={css} onChange={onChange} ref={(element) => this.select = element } {...addProps}>
                    {noSelectionPlaceholder && <option value="">{noSelectionPlaceholder}</option>}
                    {values.map(v => <option value={valueGetter(v)}>{textGetter(v)}</option>)}
                </select>
    }

    render() {
        const { simple, label } = this.props;

        if(simple) {
            return  this.renderSelect();
        }

        return (
            <div className={classNames("form-group", this.props.containerClassName)}>
                {label && <label className="control-label">{label}</label>}
                {this.renderSelect()}
            </div>
        );
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DropdownList);
}