import * as React from "@abstraqt-dev/jsxknockout";
import { TabNav, TabNavPage } from "../../../../Components/TabNavComponent";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { SplashPageOrderDetails } from "../splash-page/SplashPageOrderDetailsComponent";
import { SplashPageWorkflowsDetails } from "../splash-page/SplashPageWorkflowsDetailsComponent";
import { SplashPageAllocationDetails } from "../splash-page/SplashPageAllocationDetailsComponent";

export interface IJobOrderDetailsParams {
    jobOrderId: number
}

export function JobOrderDetails(props : IJobOrderDetailsParams) {
    return (
        <TabNav className="flex-1">
            <TabNavPage title={TextResources.JobOrder.SplashPageDetailsOrdersTab}>
                {() => <SplashPageOrderDetails jobOrderId={props.jobOrderId} />}
            </TabNavPage>
            <TabNavPage title={TextResources.JobOrder.SplashPageDetailsWorkflowsTab}>
                {() => <SplashPageWorkflowsDetails jobOrderId={props.jobOrderId} />}
            </TabNavPage>
            <TabNavPage title={TextResources.JobOrder.SplashPageDetailsAllocationsTab}>
                {() => <SplashPageAllocationDetails jobOrderId={props.jobOrderId} />}
            </TabNavPage>
        </TabNav>
    );
}