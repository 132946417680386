import * as ko from "knockout";
export class SvgInteraction {
    init(element: any, valueAccessor: () => any): void
    {
        var svgViewModel = valueAccessor();

        element.addEventListener('mousemove', (event) => {
            var pt = element.createSVGPoint();
            pt.x = event.clientX; pt.y = event.clientY;
            var svgLocation = pt.matrixTransform(element.getScreenCTM().inverse());
            svgViewModel.MouseX(svgLocation.x);
            svgViewModel.MouseY(svgLocation.y);
        });

        element.addEventListener('mousedown', (event) => {
            svgViewModel.LeftMouseButtonIsDown(true);
        });

        element.addEventListener('mouseup', (event) => {
            svgViewModel.LeftMouseButtonIsDown(false);
        });

        var resizeHandler = () => {
            svgViewModel.Width(element.clientWidth);
            svgViewModel.Height(element.clientHeight);
        }

        window.addEventListener('resize', resizeHandler);

        $(element).on("remove", function ()
        {
            //Dispose
            window.removeEventListener('resize', resizeHandler);
        })

        //Inizializzazione
        svgViewModel.Width(element.clientWidth);
        svgViewModel.Height(element.clientHeight);
    }

}

ko.bindingHandlers['svgInteraction'] = new SvgInteraction();