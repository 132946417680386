import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import TsxForEach from "../ForEach";
import { CheckBox } from "../Checkbox";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { If } from "../IfIfNotWith";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { Layout } from "../Layouts";

const { classes } = jss
    .createStyleSheet({
        tableFiltersPopover: {
            maxHeight: "280px !important",
            overflow: "hidden",

            display: "flex !important",
            flexDirection: "column",

            "& .popover-content": {
                alignSelf: "stretch",
                alignItems: "stretch",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden",

                "& .table-filters": {
                    maxHeight: "227px",

                    overflowX: "hidden !important",
                },
            },
        },

        "select-all": {
            borderBottom: "1px solid #ddd",
            backgroundColor: "#f5f5f5",
        },
    })
    .attach();

export function TableFilter(props: ITableFilterProps) {
    const C = require("./TableFilter")._TableFilter as typeof _TableFilter;
    const { children } = props;

    return <C {...props}>{children}</C>;
}

export class TableFilterItem<T = any, K = any> {
    public Selected: ko.Observable<boolean> = ko.observable(true);

    public get Value(): T {
        return this.itemValue;
    }

    public get Source(): K {
        return this.source;
    }

    constructor(public itemLabel: string, private itemValue: T, private source: K) {
        if (!this.itemLabel) this.itemLabel = TextResources.ProlifeSdk.EmptyTableFilterItem;
    }
}

export interface ITableFilterProps<T = any, K = any> {
    label?: string;
    filterSource?: ko.ObservableArray<K>;
    children?: () => React.ReactNode;

    itemLabelGetter?: (item: K) => string;
    itemKeyGetter?: (item: K) => T;
    itemRenderer?: (item: K) => React.ReactElement;

    onSelectionChange?: (selectedValues: T[]) => void;

    customFilterStateHandler?: () => boolean;

    popoverContainer?: string;
    popoverViewport?: string;
}

export class _TableFilter<T, K> {
    Active: ko.Computed<boolean>;

    private Items: ko.ObservableArray<TableFilterItem<T>> = ko.observableArray([]);
    private subscriptions: ko.Subscription[] = [];
    private SelectAll: ko.Computed<boolean>;
    private SelectionInterceptor: ko.Computed<void>;
    private disableInterceptor = false;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public props: ITableFilterProps) {
        this.ensurePropsData();

        this.SelectAll = ko.computed({
            // TODO rivedere questa logica di selezione perché in fase di inizializzazione chiama troppe volte la notifySelection
            read: () => {
                const items = this.Items();

                const itemsCount = items.length;
                const selectedItemsCount = items.filter((i) => i.Selected()).length;

                if (selectedItemsCount === 0) return false;

                if (selectedItemsCount === itemsCount) return true;

                return null;
            },
            write: (value: boolean) => {
                this.disableInterceptor = true;

                for (const item of this.Items()) {
                    item.Selected(value);
                }

                this.notifySelection();

                this.disableInterceptor = false;
            },
        });

        this.Active = ko.computed(() => {
            const selectAll = this.SelectAll();
            const items = this.props.filterSource();
            const customState = this.props.customFilterStateHandler ? this.props.customFilterStateHandler() : false;

            return (!selectAll && items.length > 0) || customState;
        });

        this.disableInterceptor = true;
        this.SelectionInterceptor = ko.computed(() => {
            this.Items().filter((i) => i.Selected());

            if (this.disableInterceptor) return;

            this.notifySelection();
        });

        const sub = this.props.filterSource.subscribe(this.generateItems.bind(this));
        this.subscriptions.push(sub);

        this.generateItems(this.props.filterSource());
        this.notifySelection(); // Notifica lo stato di selezione iniziale
        this.disableInterceptor = false;
    }

    private generateItems(sources: K[]): void {
        const actualItems = this.Items();
        const newItems: TableFilterItem[] = [];

        for (const source of sources) {
            const key = this.props.itemKeyGetter(source);
            const label = this.props.itemLabelGetter(source);

            let actualItem = actualItems.firstOrDefault((i) => this.verifyEquality(i.Value, key));
            if (!actualItem) actualItem = new TableFilterItem(label, key, source);

            if (!newItems.firstOrDefault((i) => this.verifyEquality(i.Value, actualItem.Value))) {
                if (!actualItem.Value) {
                    newItems.unshift(actualItem);
                } else {
                    newItems.push(actualItem);
                }
            }
        }

        this.Items(newItems.sort((a, b) => a.itemLabel.localeCompare(b.itemLabel)));
    }

    private verifyEquality(a: T, b: T): boolean {
        // TODO da rivedere se vogliamo usare un oggetto come chiave
        return (!a && !b) || a === b;
    }

    public componentWillUnmount(): void {
        for (const sub of this.subscriptions) sub.dispose();

        this.SelectionInterceptor.dispose();
        this.SelectAll.dispose();
        this.Active.dispose();
    }

    private notifySelection(): void {
        if (!this.props.onSelectionChange) return;

        const selectedValues = this.Items()
            .filter((i) => i.Selected())
            .map((i) => i.Value);

        this.props.onSelectionChange(selectedValues);
    }

    private ensurePropsData(): void {
        if (!this.props.filterSource) this.props.filterSource = ko.observableArray([]);

        if (!this.props.itemKeyGetter) this.props.itemKeyGetter = () => null;

        if (!this.props.itemLabelGetter) this.props.itemLabelGetter = () => "";
    }

    private async showMenu(viewModel: any, evt: MouseEvent): Promise<void> {
        const popover = new TableFilterMenuPopOver({
            items: this.Items,
            selectAll: this.SelectAll,
            itemRenderer: this.props.itemRenderer,
            children: this.props.children[0],
        });
        return this.dialogsService.ShowPopoverComponent(
            evt.currentTarget as HTMLElement,
            popover,
            "bottom",
            this.props.popoverViewport,
            classes.tableFiltersPopover,
            this.props.popoverContainer
        );
    }

    render() {
        const tableFilters = this;
        return ComponentUtils.bindTo(
            <div className="btn-group btn-group-sm ignore-sort header-btn" style={{ marginRight: "5px;" }}>
                <button
                    className="btn"
                    data-bind={{
                        css: { "btn-default": !tableFilters.Active(), "btn-primary": tableFilters.Active },
                        asyncClick: tableFilters.showMenu.bind(tableFilters),
                    }}
                >
                    {!this.props.label ? "" : this.props.label + "&nbsp;"}
                    <i className="fa fa-filter"></i>
                </button>
            </div>,
            this,
            "tableFilters"
        );
    }
}

type TableFilterMenuPopOverProps<T = any, K = any> = {
    items: ko.ObservableArray<TableFilterItem<T>>;
    selectAll: ko.Computed<boolean>;
    itemRenderer?: (item: K) => React.ReactElement;
    children?: () => React.ReactNode;
};

class TableFilterMenuPopOver<T, K> extends DialogComponentBase {
    constructor(private props: TableFilterMenuPopOverProps) {
        super({ popover: true });

        this.title(TextResources.ProlifeSdk.TableFilters);
    }

    renderBody() {
        return ComponentUtils.bindTo(
            <Layout.ScrollContainer systemScrollable className="table-filters flex-full-height">
                <If
                    condition={() =>
                        this.props.items().length === 0 && (!this.props.children || this.props.children.length === 0)
                    }
                >
                    {() => <span>{TextResources.ProlifeSdk.EmptyTable}</span>}
                </If>
                {this.props.children && this.props.children[0] && this.props.children[0]()}
                <If condition={() => this.props.items().length > 0}>
                    {() => (
                        <CheckBox
                            label={TextResources.ProlifeSdk.SelectAll}
                            checked={this.props.selectAll}
                            labelCss={classes["select-all"]}
                        ></CheckBox>
                    )}
                </If>
                <TsxForEach data={this.props.items} as="filterItem">
                    {(item) => (
                        <>
                            {!this.props.itemRenderer && (
                                <CheckBox label={item.itemLabel} checked={item.Selected}></CheckBox>
                            )}
                            {this.props.itemRenderer && (
                                <div className="flex-container">
                                    <CheckBox label={""} checked={item.Selected}></CheckBox>
                                    {this.props.itemRenderer(item.Source)}
                                </div>
                            )}
                        </>
                    )}
                </TsxForEach>
            </Layout.ScrollContainer>,
            this
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(TableFilter);
}
