import * as ProLifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { MetadataListValue } from "./MetadataEditor";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { TextInput } from "../../../../Components/TextInput";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { If, IfNot } from "../../../../Components/IfIfNotWith";

const styleSheet = jss.createStyleSheet({
    metadataListValuesEditor: {
        "& .deleted": {
            "& .form-control": {
                textDecoration: "line-through",
            },
        },
    },
});

const { classes } = styleSheet.attach();

export type MetadataListValuesEditorProps = { listValues: ko.ObservableArray<MetadataListValue>; metadataId: number };

export class MetadataListValuesEditor extends DialogComponentBase {
    static defaultProps: Partial<MetadataListValuesEditorProps> = {};

    private ShowDeletedValues: ko.Observable<boolean> = ko.observable(false);
    private FilteredListValues: ko.Computed<MetadataListValue[]>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props: MetadataListValuesEditorProps) {
        super({ popover: true });

        this.title(TextResources.Invoices.MetadatasListValuesEditorTitle);

        this.FilteredListValues = ko
            .computed(() => {
                const showDeletedValues = this.ShowDeletedValues();
                return this.props.listValues().filter((lv) => showDeletedValues || !lv.Deleted.peek());
            })
            .extend({ trackArrayChanges: true });
    }

    private addValue(): void {
        this.props.listValues.push({
            Id: null,
            Label: ko.observable(),
            FKMetadata: this.props.metadataId,
            Order: ko.observable(this.props.listValues.length + 1),
            Deleted: ko.observable(false),
        });
    }

    private showDeleted(): void {
        this.ShowDeletedValues(!this.ShowDeletedValues());
    }

    private restoreItem(item: MetadataListValue): void {
        this.props.listValues.valueWillMutate();

        item.Deleted(false);
        this.refreshOrderingValues();

        this.props.listValues.valueHasMutated();
    }

    private async removeItem(item: MetadataListValue): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Invoices.MetadataListValueDeleteConfirm,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );

        if (!confirm) return;

        this.props.listValues.valueWillMutate();

        item.Deleted(true);

        this.props.listValues.valueHasMutated();
    }

    private refreshOrderingValues() {
        const currentValues = this.props.listValues();
        let order = 1;
        currentValues.sort((a, b) => a.Order() - b.Order());
        for (const listValue of currentValues) {
            listValue.Order(order++);
        }
    }

    private modelsFactory(model: MetadataListValue): IDataSourceModel<number, MetadataListValue> {
        return {
            id: model.Id,
            title: model.Label(),
            isGroup: false,
            isLeaf: true,
            model: model,
        };
    }

    renderBody() {
        const metadataListValuesEditor = this;
        const listValue: IDataSourceModel<MetadataListValue> = null;

        return ComponentUtils.bindTo(
            <div>
                <Table
                    id={ProLifeSdk.MetadataListValuesTable + this.props.metadataId}
                    dataSource={{ array: this.FilteredListValues, factory: this.modelsFactory.bind(this) }}
                    rowsSortingValueGetter={(listValue) => listValue.Order()}
                    rowsSortingValueSetter={(listValue, newOrder) => listValue.Order(newOrder)}
                    rowAs="listValue"
                    compact
                    fixedLayout
                    className={classes.metadataListValuesEditor}
                >
                    <Column title={TextResources.Invoices.MetadataListValueLabelColumn}>
                        <ColumnBody>
                            {(item: ITableItem<MetadataListValue>) => (
                                <>
                                    <If condition={item.Data.model.Deleted}>
                                        {() => (
                                            <TextInput
                                                value={item.Data.model.Label}
                                                placeholder={TextResources.Invoices.MetadataListValueLabelPlaceholder}
                                                className="deleted"
                                            ></TextInput>
                                        )}
                                    </If>
                                    <IfNot condition={item.Data.model.Deleted}>
                                        {() => (
                                            <TextInput
                                                value={item.Data.model.Label}
                                                placeholder={TextResources.Invoices.MetadataListValueLabelPlaceholder}
                                            ></TextInput>
                                        )}
                                    </IfNot>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column className="actions text-right">
                        <ColumnHeader>
                            <button
                                type="button"
                                className="btn btn-xs"
                                title={TextResources.Invoices.ShowOrHideDeletedMetadataListValues}
                                data-bind={{
                                    css: {
                                        "btn-default": !metadataListValuesEditor.ShowDeletedValues(),
                                        "btn-success": metadataListValuesEditor.ShowDeletedValues(),
                                    },
                                    click: metadataListValuesEditor.showDeleted.bind(metadataListValuesEditor),
                                }}
                            >
                                <i className="fa fa-trash-o"></i>
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                title={TextResources.Invoices.AddMetadataListValues}
                                data-bind={{ click: metadataListValuesEditor.addValue.bind(metadataListValuesEditor) }}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </ColumnHeader>
                        <ColumnBody>
                            {(item: ITableItem<MetadataListValue>) => (
                                <>
                                    <IfNot condition={item.Data.model.Deleted}>
                                        {() => (
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-xs"
                                                title={TextResources.Invoices.RemoveMetadataListValues}
                                                data-bind={{
                                                    click: metadataListValuesEditor.removeItem.bind(
                                                        metadataListValuesEditor,
                                                        listValue.model
                                                    ),
                                                }}
                                            >
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        )}
                                    </IfNot>
                                    <If condition={item.Data.model.Deleted}>
                                        {() => (
                                            <button
                                                type="button"
                                                className="btn btn-success btn-xs"
                                                title={TextResources.Invoices.RestoreMetadataListValues}
                                                data-bind={{
                                                    click: metadataListValuesEditor.restoreItem.bind(
                                                        metadataListValuesEditor,
                                                        listValue.model
                                                    ),
                                                }}
                                            >
                                                <i className="fa fa-recycle"></i>
                                            </button>
                                        )}
                                    </If>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                </Table>
            </div>,
            this,
            "metadataListValuesEditor"
        );
    }
}
