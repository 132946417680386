import * as React from "@abstraqt-dev/jsxknockout";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import {
    IMailTemplatesSettingsManager,
    MailTemplate,
} from "../../../ProlifeSdk/interfaces/desktop/settings/IMailTemplatesSettingsManager";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAjaxServiceNew } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ResponseBase, ResponseData, ResponseError } from "../../../Core/response/ResponseBase";
import { MailTemplatesSettingsEditor } from "./ui/MailTemplatesSettingsEditor";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";

export class MailTemplatesSettingsManager implements IMailTemplatesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxService: IAjaxServiceNew;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    private templates: MailTemplate[] = [];

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }

    async load(): Promise<MailTemplate[]> {
        try {
            const templatesResponse = await this.ajaxService.Get<ResponseData<MailTemplate[]>>(
                "cc/mailTemplates",
                "",
                {}
            );
            if (!templatesResponse.succeeded) {
                throw templatesResponse;
            } else {
                this.templates = templatesResponse.data;
            }
        } catch (e) {
            this.handleResponseErrors(e.errors);
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }

        return this.templates;
    }

    getName(): string {
        return nameof<IMailTemplatesSettingsManager>();
    }

    getLabel(): string {
        return TextResources.Desktop.MailTemplatesSettingsManagerLabel;
    }

    hasEditingUI(): boolean {
        return true; //this.authorizationsService.isAuthorized("Core_ManageMailTemplates");
    }

    getEditingUI(): IView | { render(): ReactNode } {
        const editor = {
            templateName: null,
            templateUrl: null,
            viewModel: null,
            render: null,
        };

        editor.render = () => (
            <MailTemplatesSettingsEditor settingsManager={this} ref={(vm) => (editor.viewModel = vm)} />
        );

        return editor;
    }

    get(): MailTemplate[] {
        return this.templates.slice();
    }

    async create(template: MailTemplate): Promise<ResponseData<MailTemplate>> {
        try {
            const response = await this.ajaxService.Post<ResponseData<MailTemplate>>("cc/mailTemplates", "", {
                methodData: template,
            });

            if (response.succeeded) {
                const subsequentItem = this.templates.firstOrDefault((t) => t.name > template.name);
                let subsequentItemIndex = this.templates.indexOf(subsequentItem);
                subsequentItemIndex = subsequentItemIndex <= 0 ? 0 : subsequentItemIndex;
                this.templates.splice(subsequentItemIndex, 0, response.data);
            }

            return response;
        } catch (e) {
            this.handleResponseErrors(e.errors);
            return e;
        }
    }

    async update(template: MailTemplate): Promise<ResponseData<MailTemplate>> {
        try {
            const response = await this.ajaxService.Put<ResponseData<MailTemplate>>("cc/mailTemplates", "", {
                methodData: template,
            });

            if (response.succeeded) {
                const item = this.templates.firstOrDefault((t) => t.id === template.id);
                const itemIndex = this.templates.indexOf(item);
                this.templates.splice(itemIndex, 1, response.data);
            }

            return response;
        } catch (e) {
            this.handleResponseErrors(e.errors);
            return e;
        }
    }

    async delete(templateId: number): Promise<ResponseBase> {
        try {
            const response = await this.ajaxService.Delete<ResponseData<MailTemplate>>(
                "cc/mailTemplates",
                templateId.toString(),
                {}
            );

            if (response.succeeded) {
                const item = this.templates.firstOrDefault((t) => t.id === templateId);
                this.templates.remove(item);
            }

            return response;
        } catch (e) {
            this.handleResponseErrors(e.errors);
            return e;
        }
    }

    private handleResponseErrors(errors: ResponseError[]): void {
        let message = "";

        for (const error of errors) {
            message += TextResources.Desktop[error.code] + "<br />";
        }

        this.infoToastService.Error(message);
    }
}
