import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 18:57
 * To change this template use File | Settings | File Templates.
 */

import { ISampleIdentifier } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISampleIdentifier";

export class SampleIdentifierViewModel {
    public Id: number;
    public Title: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Size: ko.Observable<number> = ko.observable();
    public Type: ko.Observable<string> = ko.observable();
    public PublicId: ko.Observable<string> = ko.observable();

    constructor(sample: ISampleIdentifier) {
        this.Id = sample.Id;
        this.Title(sample.Title);
        this.Description(sample.Description);
        this.Size(sample.Size);
        this.Type(sample.Type);
        this.PublicId(sample.PublicId);
    }
}