import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import { Application } from "../../Components/Application";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { CustomersDataSource, ICustomersDataSourceModel } from "../../DataSources/CustomersDataSource";
import { IDataSourceModel, IDataSourceListener, IDataSource } from "../../DataSources/IDataSource";
import { ICustomerForList } from "../../ProlifeSdk/interfaces/customer/ICustomersService";
import { If, IfNot } from "../../Components/IfIfNotWith";
import { QuotationsList } from "./QuotationsList";
import { QuotationEditor, QuotationForList } from "./QuotationEditor";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { IApplicationNavBarAction, INavBarActionManager } from "../../Desktop/interfaces/IApplication";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

function CustomerMenuItem(props: { customer: ICustomersDataSourceModel }) {
    let m : ICustomersDataSourceModel & { model: ICustomerForList };

    return  <div className="col1">
                <div className="cont">
                    <div className="cont-col1">
                        <div className="btn btn-primary btn-lg" data-bind={{ style: { background: m.icon.background, color: m.icon.foreground } }} style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                            <i data-bind={{ css: m.icon.icon }}></i>
                        </div>
                    </div>
                    <div className="cont-col2">
                        <div className="desc">
                            <div data-bind={{ text: m.title }}></div>
                            <div className="margin-top-5">
                                <ko-bind data-bind={{ if: m.model.IsCustomer }}>
                                    <span style="margin-left : 3px" className="label label-primary label-sm">Cliente</span>
                                </ko-bind>
                                <ko-bind data-bind={{ if: m.model.IsSupplier }}>
                                    <span style="margin-left : 3px" className="label label-danger label-sm">Fornitore</span>
                                </ko-bind>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

type QuotationSelectorProps = {
    navBarActionManager: INavBarActionManager;
}

export function QuotationSelector(props: QuotationSelectorProps) {
    const C = require("./QuotationSelector")._QuotationSelector as typeof _QuotationSelector;
    return <C {...props} />;
}

export class _QuotationSelector implements IDataSourceListener {
    private customersDataSource : CustomersDataSource = new CustomersDataSource();
    private selectedQuotation: ko.Observable<QuotationForList> = ko.observable();
    private isEditingQuotation : ko.Computed<boolean>;
    private selectedCustomer: ko.Observable<ICustomerForList> = ko.observable();

    isCustomerSelected : ko.Computed<boolean>;
    canCreateNewQuotation : ko.Computed<boolean>;
    navBarNewAction: IApplicationNavBarAction;

    constructor(private props: QuotationSelectorProps) {
        this.customersDataSource.setReturnGroupedData(false);

        this.isEditingQuotation = ko.computed(() => {
            return !!this.selectedQuotation();
        });

        this.isCustomerSelected = ko.computed(() => !!this.selectedCustomer());
        this.canCreateNewQuotation = ko.computed(() => !!this.selectedCustomer() && !this.isEditingQuotation());
    }

    componentDidMount() {
        this.navBarNewAction = this.props.navBarActionManager.addNavBarAction(
            TextResources.Quotation.NewQuotation,
            "fa fa-plus",
            () => this.canCreateNewQuotation(),
            () => this.newQuotation()
        );
    }

    componentWillUnmount() {
        this.props.navBarActionManager.removeNavBarAction(this.navBarNewAction);
    }
    
    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(sender === this.customersDataSource)
            this.selectedCustomer((model as ICustomersDataSourceModel).model as ICustomerForList);
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    newQuotation() {
        this.selectedQuotation({ 
            Id: -1, 
            FKCustomer: this.selectedCustomer().Id,
            CustomerName: this.selectedCustomer().Name,
            FKJobOrder: ko.observable(-1),
            JobOrderName: null,
            Name: "", 
            Description: "", 
            FKState: 1
        });
    }

    renderEditor() {
        let QE = require("./QuotationEditor").QuotationEditor as typeof QuotationEditor;

        return  <QE quotation={this.selectedQuotation} customerId={this.selectedCustomer()?.Id} onClose={() => this.selectedQuotation(undefined)} />
    }

    renderList() {
        let QL = require("./QuotationsList").QuotationsList as typeof QuotationsList;

        return  <Application>
                    <Application.LeftMenu>
                        <NavigationMenu allowSearch dataSource={this.customersDataSource} listener={this} templatesProvider={{
                            hasTemplateFor: (m) => true,
                            templatesProvider: (ds, m : ICustomersDataSourceModel) => <CustomerMenuItem customer={m} />
                        }} />
                    </Application.LeftMenu>
                    <Application.Content>
                        <QL customer={this.selectedCustomer} onQuotationSelected={(q) => this.selectedQuotation(q)} onClose={() => this.selectedQuotation(undefined)} />
                    </Application.Content>
                </Application>
    }

    render() {
        return  <>
            <If condition={this.isEditingQuotation}>
                {this.renderEditor.bind(this)}
            </If>
            <IfNot condition={this.isEditingQuotation}>
                {this.renderList.bind(this)}
            </IfNot>
        </>;

        
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationSelector);
}