import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

export interface IPortletComponentParams {
    name: string;

    title: string;
    icon?: string;
    
    portletCssClasses?: string;
    buttonsCssClasses?: string;

    isGeneralSection?: boolean;
    renderIf?: boolean;
    
    children?: ReactNode;
}

export class Portlet {
    public get Name(): string {
        return this.props.name;
    }

    constructor(private props: IPortletComponentParams) {
    }

    render(): React.ReactNode {
        if (typeof(this.props.renderIf) !== "undefined" && !this.props.renderIf)
            return (<></>);

        if (this.props.isGeneralSection) {
            return (
                <div data-bind={"scrollIntoView: Portlets()['" + this.Name + "'].Scroller, css: '" + (this.props.portletCssClasses || "") + "'"}>
                    {this.props.children}
                </div>
            );
        }

        return (
            <div class="portlet light bordered" data-bind={"scrollIntoView: Portlets()['" + this.Name + "'].Scroller, css: '" + (this.props.portletCssClasses || "") + "'"}>
                <div class="portlet-title" data-bind={"click: $data.switchOpenedState.bind($data, '" + this.Name + "')"}>
                    <div class="caption bold">
                        <i class={"fa " + (this.props.icon || "")}></i>
                        {this.props.title}
                    </div>

                    <div class="tools">
                        <a href="javascript:void(0)" class="" data-bind={"css: { collapse: !Portlets()['" + this.Name + "'].Opened(), expand: Portlets()['" + this.Name + "'].Opened }, click: $data.switchOpenedState.bind($data, '" + this.Name + "'), clickBubble: false"}>
                        </a>
                    </div>
                </div>
                <div class="portlet-body" data-bind={"visible: Portlets()['" + this.Name + "'].Opened"}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    public renderButtons(): Node {
        if (this.props.isGeneralSection) {
            return (
                <li class="">
                    <button class="btn btn-default btn-scroller btn-general-data" style="width: 100%; text-align: center" data-bind={"click: $data.scrollTo.bind($data, '" + this.Name + "')"}>{this.props.title}</button>
                </li>
            );
        }

        return (
            <li class="">
                <button class="btn btn-default btn-xs btn-open-close " data-bind={"css: { 'btn-primary': $data.Portlets()['" + this.Name + "'].Opened }, toggle: $data.Portlets()['" + this.Name + "'].Opened, attr: { title: ($data.Portlets()['" + this.Name + "'].Opened() ? '" + TextResources.ProlifeSdk.KeyboardPanelOpenedSection + "' : '" + TextResources.ProlifeSdk.KeyboardPanelClosedSection + "') }"}>
                    <i class="fa" data-bind={"css: { 'fa-folder-open-o': $data.Portlets()['" + this.Name + "'].Opened, 'fa-folder-o': !$data.Portlets()['" + this.Name + "'].Opened() }"}></i>
                </button>
                <button class={"btn btn-default btn-scroller pull-right " + (this.props.portletCssClasses || "")} data-bind={"click: $data.scrollTo.bind($data, '" + this.Name + "')"}>
                    <i class={"fa " + (this.props.icon || "")}></i>
                    {this.props.title}
                </button>
            </li>
        );
    }
}