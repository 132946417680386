import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { WorkflowsAndTasksDataSource, IWorkflowsAndTasksDataSourceModel } from "../../../DataSources/WorkflowsAndTasksDataSource";
import { LazyImport } from "../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { IDocumentDataWizardRow } from "../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IDataSourceListener, IDataSource } from "../../../DataSources/IDataSource";
import { ICustomerOrderRow } from "../../../ProlifeSdk/interfaces/warehouse/ICustomerOrder";
import { IWizardInitializationInfo } from "../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ITodoListService, IElementToBeOrdered } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";

interface ILoadElementsToBeOrderedParam { 
    TasksIds: number[];
    WorkflowsIds: number[];
}

export class ElementsToBeOrderedFromActivitiesDataSource extends DocumentDataWizardStep<ElementToBeOrdered> implements IDataSourceListener {
    public AllSelectedToggle: ko.Computed<boolean>;
    public WorkflowsAndTasksDataSource: WorkflowsAndTasksDataSource;

    private selectedTasks: number[] = [];
    private selectedWorkflows: number[] = [];
    
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Warehouse.CustomerOrdersAdjustmentsDataSource);
        //this.RegisterDestinationMapper(new ElementsToBeOrderedToCustomerOrderMapper());
        //this.documentsService.registerDocumentDataSource(this);

        this.WorkflowsAndTasksDataSource = new WorkflowsAndTasksDataSource();
        this.WorkflowsAndTasksDataSource.enableNotifications(true);

        this.AllSelectedToggle = ko.computed({
            read: () => {
                const rows = this.Rows();
                return rows.filter((r) => r.Selected()).length === rows.length;
            },
            write: (value: boolean) => {
                this.Rows().forEach((r: ElementToBeOrdered) => r.Selected(value));
            }
        });
    }
    
    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);

        this.WorkflowsAndTasksDataSource.setJobOrders([this.initializationInfo.JobOrderId]);
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        throw new Error("Method not implemented.");
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true; // TODO this.authorizationsService.isAuthorized("");
    }

    public onItemSelected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        if (model.isWorkflow) {
            const indexOf = this.selectedWorkflows.indexOf(model.id);
            indexOf < 0 && this.selectedWorkflows.push(model.id);
            this.loadElementsToBeOrdered({ TasksIds: [], WorkflowsIds: [model.id] });
        } else {
            const indexOf = this.selectedTasks.indexOf(model.id);
            indexOf < 0 && this.selectedTasks.push(model.id);
            this.loadElementsToBeOrdered({ TasksIds: [model.id], WorkflowsIds: [] });
        }
    }
    
    public async loadElementsToBeOrdered(selectedTasksAndWorkflows: ILoadElementsToBeOrderedParam): Promise<void> {
        const rows: IElementToBeOrdered[] = await this.todoListService.GetElementsToBeOrderedFromActivities(selectedTasksAndWorkflows.TasksIds, selectedTasksAndWorkflows.WorkflowsIds);
        for (const row of rows)
            this.Rows.push(this.createViewModelFor(row));
    }

    public importDetailedRows(): void {

    }

    public importGroupedByTaskRows(): void {

    }

    public importGroupedByTypeRows(): void {

    }

    public importGroupedByWorkflowRows(): void {

    }

    public importGroupedByWorkflowAndTasksRows(): void {

    }

    public clearSelection(): void {
        this.Rows([]);
    }
    
    public onItemDeselected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        if (model.isWorkflow) {
            const indexOf = this.selectedWorkflows.indexOf(model.id);
            indexOf < 0 && this.selectedWorkflows.splice(indexOf, 1);
            this.removeRowsOfUnselectedElement((r) => model.id === r.WorkflowId);
        } else {
            const indexOf = this.selectedTasks.indexOf(model.id);
            indexOf < 0 && this.selectedTasks.splice(indexOf, 1);
            this.removeRowsOfUnselectedElement((r) => model.id === r.TaskId);
        }
    }

    private removeRowsOfUnselectedElement(searchCallback: (r) => boolean): void {
        const rowsToBeRemoved = this.Rows().filter(searchCallback);
            for (const row of rowsToBeRemoved)
                this.Rows.remove(row);
    }

    private createViewModelFor(row: IElementToBeOrdered): ElementToBeOrdered {
        return new ElementToBeOrdered(row);
    }

    render() {
        return ComponentUtils.bindTo(
            <div class="flex-container flex-full-height">
                <div class="flex-fill" style="background-color: green">
                </div>
                <navigation-menu
                    params="DataSource: WorkflowsAndTasksDataSource; Listeners: $data, WrapperCssClasses: 'menu-wrapper-for-modal'" style="width: 270px"></navigation-menu>
            </div>
        , this);
    }
}

class ElementToBeOrdered {
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public References: ElementToBeOrdered[] = [];

    constructor(private row: IElementToBeOrdered) {

    }

    public toCustomerOrderRow(): ICustomerOrderRow {
        return undefined;
    }
}