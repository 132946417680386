import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/02/2018
 * Time: 12:57
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { PlannedEventsAlertDialog } from "./dialogs/PlannedEventsAlertDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IFolderViewModel } from "../../interfaces/IFolder";
import { IFolderService, IFolder } from "../../../ProlifeSdk/interfaces/agenda/IFolderService";
import { IAgendaService } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IEventOnDatesIntervalDetails } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { Deferred } from "../../../Core/Deferred";

export class FolderViewModel implements IFolderViewModel {
    public FolderId: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable();

    public IsNew: ko.Computed<boolean>;

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private folderService: IFolderService;
    private agendasService: IAgendaService;

    constructor(private serviceLocator : IServiceLocator, private folder: IFolder) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.folderService = <IFolderService> this.serviceLocator.findService(ProlifeSdk.FolderServiceCode);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);

        this.FolderId(this.folder.Id);
        this.Title(this.folder.Name);
        this.Icon(this.folder.Icon);
        this.Background(this.folder.Background);
        this.Foreground(this.folder.Foreground);
        this.Deleted(this.folder.Deleted)

        this.IsNew = ko.computed(() => {
            return this.FolderId() <= 0;
        });
    }

    public Delete(): Promise<boolean> {
        var def = new Deferred<boolean>();
        this.folderService.GetFolderFutureEvents(this.FolderId())
            .then((events: IEventOnDatesIntervalDetails[]) => {
                if (events.length == 0) {
                    this.ConfirmDelete()
                        .then((confirm: boolean) => {
                            def.resolve(confirm);
                        })
                        .catch(() => {
                            def.reject();
                        });
                    return;
                }

                var message = ProlifeSdk.TextResources.Agenda.FolderFuturePlannedEventsText;
                var plannedEventsDialog = new PlannedEventsAlertDialog(this.serviceLocator, events, message);
                plannedEventsDialog.Show();
                def.resolve(false);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    public Copy(): IFolderViewModel {
        return new FolderViewModel(this.serviceLocator, this.GetData());
    }

    public GetData(): IFolder {
        return <IFolder> {
            Id: this.FolderId(),
            Name: this.Title(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            CreatedBy: this.folder.CreatedBy,
            CreationDate: this.folder.CreationDate,
            ModifiedBy: this.folder.ModifiedBy,
            ModifyDate: this.folder.ModifyDate,
            Deleted: this.Deleted()
        };
    }

    private ConfirmDelete(): Promise<boolean> {
        var def = new Deferred<boolean>();
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.DeleteFolderConfirmMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.folderService.Delete(this.FolderId())
                        .then(() => {
                            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.DeleteFolderSuccess);
                            def.resolve(true);
                        })
                        .catch(() => {
                            this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.DeleteFailed);
                            def.reject();
                        });
                else
                    def.resolve(false);
            }
        );
        return def.promise();
    }
 }