import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 13/02/2018
 * Time: 14:36
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IResourceForManager, IAgendaResource } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IControlsMultipleEntitiesProvider, IItemIdWithType } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class ResourcesAndGroupsManager
{
    public resourcesProvider : IControlsMultipleEntitiesProvider;

    public Resources : ko.Observable<string> = ko.observable("");
    public IsChanged : ko.Computed<boolean>;
    public ResourcesIdsAndTypes : ko.ObservableArray<IResourceForManager> = ko.observableArray([]);

    private originalResources : string = "";

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService : IEntityProviderService;

    constructor()
    {
        this.resourcesProvider = this.entitiesProviderService.GetMultipleEntitiesProviderForControls([ProlifeSdk.HumanResources, ProlifeSdk.ResourcesGroups], false, false);

        this.IsChanged = ko.computed(() => {
            return this.originalResources != this.Resources();
        });
    }

    public LoadResources(resources : IResourceForManager[])
    {
        this.ResourcesIdsAndTypes(resources);

        var resourcesString : string = this.resourcesProvider.formatItemsForControlValueField(resources.map((r : IResourceForManager) => {
            return <IItemIdWithType> {
                Id : r.ElementId,
                Type : r.ElementType
            };
        }));
        this.originalResources = resourcesString;
        this.Resources(resourcesString);
    }

    public GetResources() : IResourceForManager[]
    {
        return this.resourcesProvider.extractItemsAndTypesFromStringValue(this.Resources()).map((i : IItemIdWithType) => {
            return <IAgendaResource> {
                ElementType : i.Type,
                ElementId : i.Id
            }
        });
    }
}
