import * as ko from "knockout";
/**
 * Created by d.bucchi on 12/06/2017.
 */

interface ISelfBehindMenu {
    panDirections: any;
    BindingToLeft: any;
    BindingToRight: any;
    startX: number;
    startY: number;
    startLeftPosition: number;
    startTime: Date;
    behindLeftMenuOpened: boolean;
    behindRightMenuOpened: boolean;
    onXScrollEnabled: boolean;
    disableDefault: boolean;
}

export class MenuBehindBindingHandler {

    public init(element: HTMLElement, valueAccessor: () => any, allBindings: () => any) {
        var self: ISelfBehindMenu = {
            panDirections: ko.utils.unwrapObservable(valueAccessor()),
            BindingToLeft: allBindings()["behindMenuFunctionToLeft"],
            BindingToRight: allBindings()["behindMenuFunctionToRight"],
            startX: 0,
            startY: 0,
            startLeftPosition: 0,
            startTime: null,
            behindLeftMenuOpened: false,
            behindRightMenuOpened: false,
            onXScrollEnabled: true,
            disableDefault: false
        };

        /*self.panDirections.registerObserver(() => {
            MenuBehindBindingHandler.moveToCenter(element, self);
        });*/

        $(element).on("touchstart",
            (event) => {
                if (self.onXScrollEnabled) {
                    var ev = (event.originalEvent as TouchEvent).changedTouches;
                    self.startX = ev[0].clientX;
                    self.startY = ev[0].clientY;
                    self.startLeftPosition = MenuBehindBindingHandler.getLeftPosition(element);
                    self.startTime = new Date();

                    /*if((self.startX < 50 && self.behindRightMenuOpened) || (self.startX > $(window).width() - 50 && self.behindLeftMenuOpened))
                        self.disableDefault = true;*/
                }
            });
        $(element).on("touchmove",
            (event) => {
                if (self.onXScrollEnabled) {
                    if(self.disableDefault)
                        event.preventDefault();

                    var ev = (event.originalEvent as TouchEvent);
                    var dx = MenuBehindBindingHandler.deltaX(self.startX, ev.changedTouches[0].clientX);
                    var dy = MenuBehindBindingHandler.deltaY(self.startY, ev.changedTouches[0].clientY);
                    if (MenuBehindBindingHandler.shouldScrollOnX(dx) && !MenuBehindBindingHandler.shouldScrollOnY(dy)) {
                        event.preventDefault();
                        var newLeft = (self.startLeftPosition + (ev.changedTouches[0].clientX - self.startX));
                        newLeft = Math.max(-($(window).width() - 50), newLeft);
                        newLeft = Math.min($(window).width() - 50, newLeft);

                        if(newLeft < 0 && !self.panDirections.RightMenuEnabled())
                            newLeft = 0;
                        if(newLeft > 0 && !self.panDirections.LeftMenuEnabled())
                            newLeft = 0;

                        $(element).css({ "transform": "translate(" + newLeft + "px, 0px)" });
                    }

                    if(MenuBehindBindingHandler.getLeftPosition(element) > 0)
                    {
                        $(element).parent().find(".left-menu").removeClass("hidden");
                        $(element).parent().find(".right-menu").addClass("hidden");
                    }
                    else if(MenuBehindBindingHandler.getLeftPosition(element) < 0)
                    {
                        $(element).parent().find(".right-menu").removeClass("hidden");
                        $(element).parent().find(".left-menu").addClass("hidden");
                    }
                    else
                    {
                        $(element).parent().find(".left-menu").addClass("hidden");
                        $(element).parent().find(".right-menu").addClass("hidden");
                    }
                }
            });
        $(element).on("touchend",
            (event) => {
                if (self.onXScrollEnabled) {
                    var left = MenuBehindBindingHandler.getLeftPosition(element);
                    self.onXScrollEnabled = false;

                    if(left >= $(window).width() - 50) { //Auto Execute left action
                        self.behindLeftMenuOpened = true;
                        self.behindRightMenuOpened = false;
                        self.onXScrollEnabled = true;
                        MenuBehindBindingHandler.translate(element, $(window).width());

                        if(self.BindingToLeft)
                            self.BindingToLeft();
                    } else if(left >= ($(window).width() * 2) / 4) { //Open left menu
                        self.behindLeftMenuOpened = true;
                        self.behindRightMenuOpened = false;
                        self.onXScrollEnabled = true;
                        MenuBehindBindingHandler.translate(element, $(window).width() - 50);
                    } else if(left <= -($(window).width() - 50)) { //Auto Execute right action
                        self.behindLeftMenuOpened = false;
                        self.behindRightMenuOpened = true;
                        self.onXScrollEnabled = true;
                        MenuBehindBindingHandler.translate(element, -$(window).width());

                        if(self.BindingToRight)
                            self.BindingToRight();
                    } else if(left <= -(($(window).width() - 50) * 2) / 4) { //Open right menu
                        self.behindLeftMenuOpened = false;
                        self.behindRightMenuOpened = true;
                        self.onXScrollEnabled = true;
                        MenuBehindBindingHandler.translate(element, -($(window).width() - 50));
                    } else { //Move to center
                        self.behindLeftMenuOpened = false;
                        self.behindRightMenuOpened = false;
                        self.onXScrollEnabled = true;
                        MenuBehindBindingHandler.moveToCenter(element, self);
                    }
                    self.onXScrollEnabled = true;
                    self.disableDefault = false;

                    /*var ev = event.originalEvent;
                    self.onXScrollEnabled = false;
                    var dy = MenuBehindBindingHandler.deltaY(self.startY, event.originalEvent.changedTouches[0].clientY);
                    if (MenuBehindBindingHandler.toRight(self.startX, event.originalEvent.changedTouches[0].clientX)) {
                        if (!self.behindLeftMenuOpened && !self.behindRightMenuOpened && self.panDirections.LeftMenuEnabled()) {
                            if (MenuBehindBindingHandler.shouldCompleteToEndRight(event.originalEvent.changedTouches[0].clientX) && self.panDirections.LeftToEndMenuEnabled()) {
                                var transition = $(window).width();
                                MenuBehindBindingHandler.translate(element, transition);
                                self.behindLeftMenuOpened = true;
                                self.onXScrollEnabled = true;
                                if (self.BindingToLeft)
                                    self.BindingToLeft();
                            } else {
                                if (MenuBehindBindingHandler.shouldCompleteToRight(MenuBehindBindingHandler.getLeftPosition(element)) && !MenuBehindBindingHandler.shouldScrollOnY(dy)) {
                                    var transition = ($(window).width() - 50);
                                    MenuBehindBindingHandler.translate(element, transition);
                                    self.behindLeftMenuOpened = true;
                                    self.onXScrollEnabled = true;
                                } else {
                                    MenuBehindBindingHandler.moveToCenter(element, self);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        } else {
                            if (self.behindRightMenuOpened) {
                                if (MenuBehindBindingHandler.shouldCompleteToRight(MenuBehindBindingHandler.getRightPosition(element)) && !MenuBehindBindingHandler.shouldScrollOnY(dy)) {
                                    MenuBehindBindingHandler.moveToCenter(element, self);
                                    self.behindRightMenuOpened = false;
                                    setTimeout(() => { $(element).parent().find(".right-menu").addClass("hidden") }, 500);
                                    self.onXScrollEnabled = true;
                                } else {
                                    var transition = ($(window).width() - 50);
                                    MenuBehindBindingHandler.translate(element, -transition);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        }
                    }
                    if (MenuBehindBindingHandler.toLeft(self.startX, event.originalEvent.changedTouches[0].clientX)) {
                        if (!self.behindRightMenuOpened && !self.behindLeftMenuOpened && self.panDirections.RightMenuEnabled()) {
                            if (MenuBehindBindingHandler.shouldCompleteToEndLeft(MenuBehindBindingHandler.getLeftPosition(element)) ||
                                !MenuBehindBindingHandler.shouldScrollOnY(dy)) {
                                var transition = ($(window).width() - 50);
                                MenuBehindBindingHandler.translate(element, -transition);
                                self.behindRightMenuOpened = true;
                                self.onXScrollEnabled = true;
                                if (self.BindingToRight)
                                    self.BindingToRight();
                            } else {
                                if (MenuBehindBindingHandler.shouldCompleteToLeft(MenuBehindBindingHandler.getLeftPosition(element)) ||
                                    !MenuBehindBindingHandler.shouldScrollOnY(dy)) {
                                    var transition = ($(window).width() - 50);
                                    MenuBehindBindingHandler.translate(element, transition);
                                    self.behindRightMenuOpened = true;
                                    self.onXScrollEnabled = true;
                                } else {
                                    MenuBehindBindingHandler.moveToCenter(element, self);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        } else {
                            if (self.behindLeftMenuOpened) {
                                if (!MenuBehindBindingHandler.shouldScrollOnY(dy) ||
                                    !MenuBehindBindingHandler.shouldCompleteToLeft(MenuBehindBindingHandler.getLeftPosition(element))) {
                                    MenuBehindBindingHandler.moveToCenter(element, self);
                                    self.behindLeftMenuOpened = false;
                                    setTimeout(() => { $(element).parent().find(".left-menu").addClass("hidden") }, 500);
                                    self.onXScrollEnabled = true;
                                } else {
                                    var transition = ($(window).width() - 50);
                                    MenuBehindBindingHandler.translate(element, transition);
                                    self.onXScrollEnabled = true;
                                }
                            }
                        }
                    }
                    self.onXScrollEnabled = true;*/
                }
            });
    }

    private static translate(element: HTMLElement, x: number) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(" + x + "px, 0px)");
        setTimeout(() => {
            $(element).css("transition", "");
        }, 500);
    }

    private static getLeftPosition(element: HTMLElement): number {
        if (!isNaN(parseInt($(element).css("transform").split(",")[4]))) {
            return parseInt($(element).css("transform").split(",")[4]);
        }
        return parseInt($(element).css("left"));
    }

    private static moveToCenter(element: HTMLElement, self: any) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(0px,0px)");
        setTimeout(() => {
                $(element).css("transition", "");
                $(element).parent().find(".leftmenu").addClass("hidden");
                self.leftMenuOpened = false;
                self.rightMenuOpened = false;
            },
            500);
    }

    private static shouldCompleteToEndRight(position: number): boolean {
        return position > (($(window).width() * 3) / 4);
    }

    private static shouldCompleteToEndLeft(position: number): boolean {
        return position < (3 / 4);
    }

    private static shouldCompleteToLeft(position: number): boolean {
        return position < -(($(window).width() * 2) / 4);
    }

    private static shouldCompleteToRight(position: number): boolean {
        return position > (($(window).width() * 2) / 4);
    }

    private static deltaX(startXTouch: number, endXTouch: number): number {
        return Math.abs(endXTouch - startXTouch);
    }

    private static shouldCompleteForInertia(startTime: Date, startXTouch: number, endXTouch: number): boolean {
        var endTime = new Date();
        var deltaTime = endTime.getMilliseconds() - startTime.getMilliseconds();
        var deltaX = MenuBehindBindingHandler.deltaX(startXTouch, endXTouch);
        return (deltaX / deltaTime) > 0.2;
    }

    private static deltaY(startYTouch: number, endYTouch: number): number {
        return Math.abs(endYTouch - startYTouch);
    }

    private static shouldScrollOnX(dx: number): boolean {
        return dx > 50;
    }

    private static shouldScrollOnY(dy: number): boolean {
        return dy > 15;
    }

    private static stopMovement(prevX: number, nextX: number, leftMenuOpened: boolean, rightMenuOpened: boolean, element: HTMLElement): boolean {
        if (MenuBehindBindingHandler.toRight(prevX, nextX) && (leftMenuOpened || MenuBehindBindingHandler.getLeftPosition(element) >= $(window).width() - 50)) {
            return true;
        } else {
            if (MenuBehindBindingHandler.toLeft(prevX, nextX) && (rightMenuOpened || MenuBehindBindingHandler.getRightPosition(element) >= $(window).width() - 50)) {
                return true;
            } else {
                return false;
            }
        }
    }

    private static toRight(prevX: number, nextX: number): boolean {
        return nextX > prevX;
    }

    private static toLeft(prevX: number, nextX: number): boolean {
        return nextX < prevX;
    }

    private static getRightPosition(element: HTMLElement): number {
        return -MenuBehindBindingHandler.getLeftPosition(element);
    }

    private static leftMenuOpen(element: HTMLElement): boolean {
        return MenuBehindBindingHandler.getLeftPosition(element) > 0;
    }
    private static rightMenuOpen(element: HTMLElement): boolean {
        return MenuBehindBindingHandler.getLeftPosition(element) < 0;
    }

}

ko.bindingHandlers["behindMenu"] = new MenuBehindBindingHandler();