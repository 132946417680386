import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTaskTagsProvider } from "./TodoListTaskTagsProvider";
import { TodoListTask } from "./TodoListTask";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import {
    ITodoListService,
    ITaskInsertOrUpdateResponse,
} from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IException } from "../../../../../Core/interfaces/IException";
import {
    ITodoListUiProvider,
    ITodoListElementsProvider,
    ITodoList,
    ITodoListActivitiesTagsProvider,
    ITodoListTask,
    ITaskResource,
    ITodoListTaskTag,
    ITodoListActivitiesFiltersProvider,
    ITodoListTaskTagsAndResources,
    INewActivityDefaultData,
} from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { SaveTaskErrors } from "./SaveTaskErrors";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { ResponseData } from "../../../../../Core/response/ResponseBase";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { TODOLIST_SERVICE_ERRORS } from "../../../../TodoListService";
import { error } from "jquery";

export class TodoListUiProvider implements ITodoListUiProvider {
    constructor(private activitiesProvider: ITodoListElementsProvider) {}

    /* public GetTodoList(activitiesEditorDialog? : IActivityEditorDialog, options? : ITodoListOptions) : ITodoList
    {
        return new TodoList(this.activitiesProvider,
            new TaskListNotificationsManagerAdapter(options),
            activitiesEditorDialog, options);
    }

    public GetActivityEditorDialog() : IActivityEditorDialog
    {
        return new ActivityDetailsDialog({ activitiesProvider: this.activitiesProvider, dataSource: new TasksDataSource() });
    } */
}

export class TodoListTasksProvider implements ITodoListElementsProvider {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    TagsProvider: ITodoListActivitiesTagsProvider;
    UI: ITodoListUiProvider;

    public ChooseContainerLabel: string = ProlifeSdk.TextResources.Todolist.SelectAWorkflow;

    constructor() {
        this.TagsProvider = new TodoListTaskTagsProvider();
        this.UI = new TodoListUiProvider(this);
    }

    public InitFromOtherActivity(activityToInitialize: ITodoListTask, otherActivity: ITodoListTask) {
        activityToInitialize.Priority = otherActivity.Priority;
        activityToInitialize.Duration = otherActivity.Duration;
        activityToInitialize.Resources = otherActivity.Resources.map((r: ITaskResource) => {
            return { ElementType: r.ElementType, ElementId: r.ElementId };
        });
        activityToInitialize.RelatedWorkFlow = otherActivity.RelatedWorkFlow;

        otherActivity.Tags.filter((t: ITodoListTaskTag) => {
            return t.Field.toUpperCase() != "FILE";
        }).forEach((t: ITodoListTaskTag) => {
            activityToInitialize.Tags.push({
                Type: t.Type,
                Value: t.Value,
                Field: t.Field,
            });
        });
    }

    public GetAdditionalDataForActivities(tasks: ITodoListTask[]): Promise<any> {
        const tasksIds: number[] = tasks.map((t) => t.Id);
        return this.todoListService.GetNumberOfBlogEventsRelatedToTasks(tasksIds).then((d) => {});
    }

    public GetActivities(
        filtersProvider: ITodoListActivitiesFiltersProvider,
        skip: number,
        count: number
    ): Promise<{ Id: number }[]> {
        return <Promise<{ Id: number }[]>>(
            this.todoListService.GetTasksAndWorkflows(
                filtersProvider.GetWordsFilters(),
                filtersProvider.GetFilterTags(),
                filtersProvider.GetWorkflowsFilters(),
                filtersProvider.GetFolderId(),
                filtersProvider.GetResourceGroupId(),
                skip,
                count
            )
        );
    }

    GetActivitiesTagsAndResources(activitiesIds: number[]): Promise<ITodoListTaskTagsAndResources[]> {
        return this.todoListService.GetActivitiesTagsAndResources(activitiesIds);
    }

    public GetSearchFilters(filtersProvider: ITodoListActivitiesFiltersProvider): any {
        return {
            TextFilters: filtersProvider.GetWordsFilters(),
            WorkflowsIds: filtersProvider.GetWorkflowsFilters(),
            StatusFolder: filtersProvider.GetFolderId(),
            Tags: filtersProvider.GetFilterTags(),
            ResourcesGroup: filtersProvider.GetResourceGroupId(),
        };
    }

    public MoveActivity(
        activityId: number,
        activityIsTask: boolean,
        destinationActivityId: number,
        destinationActivityIsTask: boolean,
        afterOrBefore: boolean
    ): Promise<void> {
        return this.todoListService.MoveTask(
            activityId,
            activityIsTask,
            destinationActivityId,
            destinationActivityIsTask,
            afterOrBefore
        );
    }

    CreateViewModelForActivity(
        activity: ITodoListTask,
        todolist: ITodoList,
        additionalData?: { TaskId: number; NumberOfEvents: number }[]
    ): any {
        const matches: any[] = additionalData
            ? additionalData.filter((tn) => {
                  return tn.TaskId == activity.Id;
              })
            : [];
        const numberOfBlogEvents = matches.length > 0 ? matches[0].NumberOfEvents : 0;
        return new TodoListTask(todolist, activity, this, numberOfBlogEvents);
    }

    GetEmptyActivityModel(defaultData?: INewActivityDefaultData): ITodoListTask {
        return {
            Id: -1,
            Status: 0,
            Duration: 0,
            ProgressAvg: 0,
            Priority: 1,
            RelatedWorkFlow: defaultData && defaultData.ContainerId ? defaultData.ContainerId : null,
            Deleted: false,
            StateFolder: defaultData && defaultData.FolderId ? defaultData.FolderId : -1,
            InQuality: defaultData && defaultData.InQuality ? defaultData.InQuality : false,
            Tags: defaultData && defaultData.Tags ? this.GetTagsForEmptyActivity(defaultData.Tags) : [],
            Resources: defaultData && defaultData.Resources ? defaultData.Resources || [] : [],
            EstimatedBudget: 0,
            EstimatedBudgetRows: [],
            ReportingType: defaultData && defaultData.ReportingType ? defaultData.ReportingType : 0,
            Locked: false,
            TaskBoardStatus: defaultData && defaultData.FolderId ? defaultData.FolderId : -1,
            RemainingWork: 0,
            Optional: false,
            HideFromSuggestions:
                defaultData && defaultData.HideFromSuggestions ? defaultData.HideFromSuggestions : false,
            IsMilestone: false,
            Links: [],
            IsAllocated: false,
            IsEstimated: false,
            ActivitiesProgressAmountMode:
                defaultData && defaultData.ActivitiesProgressAmountMode ? defaultData.ActivitiesProgressAmountMode : 0,
            MultiplierUnitOfMeasure: defaultData && defaultData.MultiplierUoM ? defaultData.MultiplierUoM : "",
            Multiplier: defaultData && defaultData.Multiplier ? defaultData.Multiplier : 1,
            DefaultWorkPlace: defaultData?.DefaultWorkPlace,
            DefaultTravelDistance: defaultData?.DefaultTravelDistance,
            DefaultCallRight: defaultData?.DefaultCallRight,
            WorkedHoursDefaultsStrictMode: defaultData?.WorkedHoursDefaultsStrictMode ?? false,
            HideAdministrativeFieldsOnWorkedHours: defaultData?.HideAdministrativeFieldsOnWorkedHours ?? false,
            WorkedHoursDefaultRoles: defaultData.DefaultRoles ?? [],
            WorkedHoursDefaultWorkTimeCategories: defaultData.DefaultWorkTimeCategories ?? [],
        };
    }

    private GetTagsForEmptyActivity(tags: ITodoListTaskTag[]): ITodoListTaskTag[] {
        return tags.filter((t: ITodoListTaskTag) => {
            return t.Field != null && t.Field != undefined;
        });
    }

    GetActivityById(id: number): Promise<ITodoListTask> {
        return this.todoListService.GetTaskById(id);
    }

    async DeleteActivity(id: number): Promise<void> {
        const response = await this.todoListService.DeleteTask(id);
        if (!response.succeeded) {
            const errors = response.errors ?? [];
            const errorMessage = errors.map((e) => TextResources.Todolist[e.code]).join("<br/>");
            this.infoToastService.Error(errorMessage);
        }
    }

    async InsertOrUpdateActivity(activity: ITodoListTask): Promise<ResponseData<ITaskInsertOrUpdateResponse>> {
        let overwriteOtherUsersChanges = false;
        let ignoreUnestimatedTaskCheck = false;

        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const result = await this.todoListService.InsertOrUpdateTask(
                    activity,
                    ignoreUnestimatedTaskCheck,
                    overwriteOtherUsersChanges
                );

                return result;
            } catch (e) {
                const err = e as ResponseData<ITaskInsertOrUpdateResponse>;

                if (err && !err.succeeded) {
                    const errors = err.errors ?? [];
                    if (errors.firstOrDefault((e) => e.code === "TaskWasUpdatedByAnotherUser")) {
                        overwriteOtherUsersChanges = await this.ConfirmOverwriteOtherUsersChanges();
                        if (!overwriteOtherUsersChanges) return null;
                        else continue;
                    } else if (errors.firstOrDefault((e) => e.code === "MissingTaskWorkEstimation")) {
                        ignoreUnestimatedTaskCheck = await this.ConfirmSavingUnestimatedTask();
                        if (!ignoreUnestimatedTaskCheck) return null;
                        else continue;
                    } else {
                        const errorMessage = errors.map((e) => TextResources.Todolist[e.code]).join("<br/>");
                        for (const err of errors) {
                            if (TODOLIST_SERVICE_ERRORS.includes(err.code)) {
                                this.infoToastService.Error(errorMessage);
                                return null;
                            }
                        }

                        throw new Error(errorMessage);
                    }
                }
                /* const ex = e as IException;

                if (ex.ExceptionType != ProlifeSdk.ServerException_ProLifeValidationException) throw e;

                if (ex.ExceptionCode === SaveTaskErrors.ModifiedByOtherUser) {
                    overwriteOtherUsersChanges = await this.ConfirmOverwriteOtherUsersChanges();
                    if (!overwriteOtherUsersChanges) return null;
                    else continue;
                } else if (ex.ExceptionCode === SaveTaskErrors.UnestimatedTask) {
                    ignoreUnestimatedTaskCheck = await this.ConfirmSavingUnestimatedTask();
                    if (!ignoreUnestimatedTaskCheck) return null;
                    else continue;
                } else {
                    throw e;
                } */
            }
        }
    }

    private async ConfirmOverwriteOtherUsersChanges(): Promise<boolean> {
        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Todolist.TaskExternalChangesAlert,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );

        return confirm;
    }

    private async ConfirmSavingUnestimatedTask(): Promise<boolean> {
        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessage,
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessageCancel,
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessageConfirm
        );

        return confirm;
    }
}
