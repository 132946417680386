import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { Document } from "./Document";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ElectronicInvoiceTag } from "./ElectronicInvoiceTag";
import { Flag } from "./Flag";

const { classes } = jss
    .createStyleSheet({
        documentHeader: {
            display: "block",
            minHeight: "4cm",

            "& .documents-logo": {
                marginTop: "10px",
            },

            "& .document-recipient": {
                marginTop: "10px",

                "&.left": {
                    marginRight: "auto",
                },

                "&.right": {
                    marginLeft: "auto",
                },
            },

            "& .document-header-flags": {
                "& table": {
                    tableLayout: "fixed",
                    width: "100%",
                },
            },
        },
    })
    .attach();

export default function DocumentHeader(props: { document: Document }) {
    const { document } = props;

    return (
        <>
            <div class="document-header-image" data-bind="if: HeaderImage">
                <div
                    class="image"
                    data-bind="style : { backgroundImage : 'url(data:image/jpg;base64,' + HeaderImage() + ')' }"></div>
            </div>

            <DocumentBusinessInfoHeader document={document} />
        </>
    );
}

function DocumentBusinessInfoHeader(props: { document: Document }) {
    const { document } = props;

    return (
        <div class={"invoice-header " + classes.documentHeader}>
            <ElectronicInvoiceTag
                electronicInvoice={document.ElectronicInvoice}
                includeAttachmentsAndDocumentInElectronicInvoiceExport={
                    document.IncludeAttachmentsAndDocumentInElectronicInvoiceExport
                }
            />
            <div class="flex-container document-header-flags">
                <DocumentBusinessInfoHeaderFlags />
            </div>
            <div class="flex-container">
                <div class="documents-logo">
                    <div
                        class="logo"
                        data-bind="style : { backgroundImage : 'url(data:image/jpg;base64,' + CompanyLogo() + ')' }"></div>
                </div>

                <div class="document-recipient" data-bind="css: RecipientPosition">
                    <div>
                        <document-customer
                            customer={() => "Recipient"}
                            readonly={() => "ReadOnly"}
                            isSourceForCopy={() => "CanCopy"}
                            forCustomers={() => "CanSelectCustomers"}
                            forSuppliers={() => "CanSelectSuppliers"}
                            showOptions={true}
                            filterJobOrders={() => "FilterJobOrdersByCustomer"}
                            showAllCustomers={() => "ShowAllCustomers"}
                            collapsedWhenEmpty={false}
                            label={TextResources.Invoices.DocumentMessrs}></document-customer>
                    </div>
                    <div data-bind="if: CanHaveDestinationRecipient">
                        <document-customer
                            customer={() => "DestinationRecipient"}
                            readonly={() => "ReadOnly"}
                            isSourceForCopy={() => "CanCopy"}
                            forCustomers={() => "CanSelectCustomers"}
                            forSuppliers={() => "CanSelectSuppliers"}
                            showOptions={false}
                            collapsedWhenEmpty={true}
                            label={TextResources.Invoices.DocumentDest}
                            placeholder={TextResources.Invoices.DocumentIDEM}></document-customer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function DocumentBusinessInfoHeaderFlags() {
    return (
        <ko-ifnot data-bind="ReadOnly">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Flag
                                /* className="invoice-electronic" */
                                visible="CanBeAnElectronicInvoice"
                                disabled="ForPartialInvoices"
                                checked="ElectronicInvoice"
                                label={TextResources.Invoices.DocumentElectronic}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-public-administration" */
                                visible="CanBeForPublicAdministration"
                                disabled="WithWithholdingTax"
                                checked="ForPublicAdministration"
                                label={TextResources.Invoices.DocumentForPublicAdministration}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-tax-relief" */
                                visible="CanHaveTaxRelief"
                                checked="ShowTaxReliefSection"
                                label={TextResources.Invoices.DocumentEnableTaxReliefFlagLabel}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-withholding-tax-switch" */
                                visible="CanHaveWithholdingTax"
                                disabled="ForPublicAdministration"
                                checked="WithWithholdingTax"
                                label={TextResources.Invoices.DocumentWithWithholdingTax}></Flag>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Flag
                                /* className="invoice-include-attachments" */
                                visible="CanBeAnElectronicInvoice"
                                disabled="!ElectronicInvoice()"
                                checked="IncludeAttachmentsAndDocumentInElectronicInvoiceExport"
                                label={TextResources.Invoices.DocumentIncludeAttachments}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-sal" */
                                visible="CanBeDownPayment"
                                checked="DownPayment"
                                label={TextResources.Invoices.DocumentDownPayment}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-secondary-recipients-switch" */
                                visible="CanHaveSecondaryRecipients"
                                checked="SecondaryRecipientsEnabled"
                                label={TextResources.Invoices.DocumentEnableSecondaryRecipients}></Flag>
                        </td>
                        <td>
                            <Flag
                                /* className="invoice-stampduty" */
                                visible="CanHaveStampDuty"
                                disabled="StampDutyDisabled"
                                checked="StampDuty"
                                label={TextResources.Invoices.DocumentWithStampDuty}></Flag>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <Flag
                                /* className="show-amounts-switch" */
                                visible="CanHideAmounts"
                                checked="ShowAmounts"
                                label={TextResources.Invoices.DocumentShowAmounts}></Flag>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ko-ifnot>
    );
}
