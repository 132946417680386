import * as ko from "knockout";
import { ReportComponent } from "./ReportComponent";
import { Component } from "./ReportComponentProvider";
import { HasBackground } from "./ReportComponentWithBackground";
import { HasChildren } from "./ReportComponentWithChildren";
import { HasDataSourceId } from "./ReportComponentWithDataSourceId";
import { HasPosition, ReportComponentWithPosition } from "./ReportComponentWithPosition";
import { HasVisibility } from "./ReportComponentWithVisibility";

const Base = HasDataSourceId(HasBackground(HasChildren(HasVisibility(HasPosition(ReportComponent)))));

type FlowDirection = "leftToRight" | "rightToLeft" | "topToBottom" | "bottomToTop";
export interface ReportComponentWithFlow {
    flowDirection: ko.Observable<FlowDirection>;
}

interface ReportFlowPanelProps {
    flowDirection?: FlowDirection;
}

@Component(nameof<ReportFlowPanel>())
export class ReportFlowPanel extends Base implements ReportComponentWithFlow {
    get type() { return nameof<ReportFlowPanel>(); }

    flowDirection: ko.Observable<FlowDirection> = ko.observable();

    private originalSizes : WeakMap<ReportComponent, { w: number, h: number }> = new WeakMap();
    private lastFlowDirection : FlowDirection = null;
    private updatingLayout : boolean = false;

    constructor(...args: ConstructorParameters<typeof Base>) {
        super(...args);
        this.features = [...this.features, "Flow"];

        const props = args[0] as ReportFlowPanelProps;
        this.flowDirection(props.flowDirection ?? "leftToRight");

        this.children.subscribe(this.handleChildrenChange, this, "arrayChange");
        this.handleChildrenChange(ko.utils.compareArrays([], this.children()));

        ko.computed(() => {
            this.updatingLayout = true;

            this.doLayout();

            this.updatingLayout = false;
        });
    }

    getData() {
        return {
            ...super.getData(),
            flowDirection: this.flowDirection(),
        }
    }

    private handleChildrenChange(changes : ko.utils.ArrayChanges<ReportComponent>) {
        for(const change of changes) {
            if(change.status === "added") {
                const positionable = change.value as unknown as ReportComponentWithPosition;
                ko.computed(() => {
                    const width = positionable.width();
                    const height = positionable.height();

                    if(this.updatingLayout)
                        return;

                    if(this.originalSizes.has(change.value)) {
                        if(this.flowDirection.peek() === "leftToRight" || this.flowDirection.peek() === "rightToLeft") {
                            const actualValue = this.originalSizes.get(change.value);
                            actualValue.w = width;
                            this.originalSizes.set(change.value, actualValue);
                        } else if(this.flowDirection.peek() === "topToBottom" || this.flowDirection.peek() === "bottomToTop") {
                            const actualValue = this.originalSizes.get(change.value);
                            actualValue.h = height;
                            this.originalSizes.set(change.value, actualValue);
                        }
                    } else {
                        this.originalSizes.set(change.value, { w: width, h: height });
                    }
                })
            } else if(change.status === "deleted") {
                this.originalSizes.delete(change.value);
            }
        }
    }

    private doLayout() {
        const children = this.children();
        const currentHeight = this.height();
        const currentWidth = this.width();
        const flowDirection = this.flowDirection();

        let currentOffset = 0;
        for(let child of children) {
            const positionable = child as unknown as ReportComponentWithPosition;
            const originalSize = this.originalSizes.get(child);

            if(flowDirection === "leftToRight") {
                if(flowDirection !== this.lastFlowDirection) {
                    positionable.width(originalSize.w);
                }

                positionable.height(currentHeight);
                positionable.y(0);
                positionable.x(currentOffset);
                currentOffset += positionable.width();
            } else if(flowDirection === "rightToLeft") {
                if(flowDirection !== this.lastFlowDirection) {
                    positionable.width(originalSize.w);
                }

                positionable.height(currentHeight);
                positionable.y(0);
                positionable.x(currentWidth - currentOffset - positionable.width());
                currentOffset += positionable.width();
            } else if(flowDirection === "topToBottom") {
                if(flowDirection !== this.lastFlowDirection) {
                    positionable.height(originalSize.h);
                }
                
                positionable.width(currentWidth);
                positionable.x(0);
                positionable.y(currentOffset);
                currentOffset += positionable.height();
            } else if(flowDirection === "bottomToTop") {
                if(flowDirection !== this.lastFlowDirection) {
                    positionable.height(originalSize.h);
                }

                positionable.width(currentWidth);
                positionable.x(0);
                positionable.y(currentHeight - currentOffset - positionable.height());
                currentOffset += positionable.height();
            }
        }

        this.lastFlowDirection = flowDirection;
    }
}