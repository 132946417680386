import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 03/04/13
 * Time: 10.50
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { CreateNewWarehouseDialog } from "./Inventory/CreateNewWarehouseDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IWarehouseUI, IWarehouseWithStockInfo, IWarehouse } from "../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export class WarehouseServiceUI implements IWarehouseUI {
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
    }

    showCreateWarehouseDialog(customerId? : number, jobOrderId? : number) : Promise<boolean> {
        var emptyWarehouseWithStockInfo = <IWarehouseWithStockInfo> {
            Name: '',
            CustomerId: customerId,
            JobOrderId: jobOrderId
        };

        return this.dialogsService.ShowModal<boolean>(new CreateNewWarehouseDialog(emptyWarehouseWithStockInfo), "long");
    }

    showEditWarehouseDialog(warehouse : IWarehouse) : Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(new CreateNewWarehouseDialog(warehouse), "long");
    }
}