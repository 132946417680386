import * as ko from "knockout";
export class DocumentsRowsResizeInterceptor {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let targetTableBodyClass = valueAccessor();

        let resizeObserver = new ResizeObserver((mutations, observer) => {
            for(let mut of mutations) {
                let indexOfRow = $(element).children("tr").index(mut.target);
                let targetRow = $(element).parent().parent().find(targetTableBodyClass).children("tr").get(indexOfRow);
                $(targetRow).outerHeight(mut.contentRect.height);
            }
        });

        let childObserver = new MutationObserver((mutations) => {
            for(let mut of mutations) {
                if(mut.type === "childList") {
                    mut.addedNodes.forEach(n => n.nodeType === 1 && n.nodeName === "TR" && resizeObserver.observe(n as Element));
                    mut.removedNodes.forEach(n => n.nodeType === 1 && n.nodeName === "TR" && resizeObserver.unobserve(n as Element));
                }
            }
        });
        childObserver.observe(element, { childList: true });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            resizeObserver.disconnect();
            childObserver.disconnect();
        });

        /*let timer = setInterval(() => {
            let rows = $(element).children("tr");
            let targetRows = $(element).parent().parent().find(targetTableBodyClass).children("tr");

            rows.each((index: number, row: HTMLElement) => {
                if (index < targetRows.length) {
                    let rowHeight = $(row).outerHeight();

                    let targetRow = targetRows.get(index);
                    $(targetRow).outerHeight(rowHeight);
                }
            });


        }, 100);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            clearInterval(timer);
        });*/
    }
}

ko.bindingHandlers["documentsRowsResizeInterceptor"] = new DocumentsRowsResizeInterceptor();