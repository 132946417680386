import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

export type CartDependencies = {
    CartId: number;
    CartName: string;
    Level: number;
    IsCircularDependency: boolean;
    Dependencies: CartDependencies[];
}

export type CartCircularDependenciesAlertProps = {
    dependencies: CartDependencies
}

export class CartCircularDependenciesAlert extends DialogComponentBase {
    static defaultProps: Partial<CartCircularDependenciesAlertProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : CartCircularDependenciesAlertProps) {
        super({ noPrompt: true });
        this.title(TextResources.Allocations.CartCircularDependencyDetectedTitle);
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return ComponentUtils.bindTo((
            <div className="row">
                <div className="col-md-12">
                    <p>{TextResources.Allocations.CartCircularDependencyDetected}</p>
                    <Dependency cartDependencies={this.props.dependencies} isRoot />
                </div>
            </div>
        ), this, "cartDependenciesDialog");
    }
}

function Dependency(props: { cartDependencies: CartDependencies, isRoot?: boolean }) {

    return (
        <>
            <label>
                {(props.cartDependencies.IsCircularDependency || props.isRoot) && <i style={{ marginRight: "5px" }} className="fa fa-exclamation-triangle"></i>}
                <span className={classNames({
                    "bold": props.cartDependencies.IsCircularDependency || props.isRoot
                })}>{props.cartDependencies.CartName}</span>
            </label>
            <ul>
                {props.cartDependencies.Dependencies.map((d) => (
                    <li>
                        <Dependency cartDependencies={d} />
                    </li>
                ))}
            </ul>
        </>
    );
}