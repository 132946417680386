/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/07/2018
 * Time: 18:28
 * To change this template use File | Settings | File Templates.
 */
import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Moment } from "moment";
import { MonthlyInvoicingDataRow } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/MonthlyInvoicingDataRow";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { BaseDataSourceForMonthlyInvoicing } from "../../../../Invoices/invoices/documents/wizard/data-sources/BaseDataSourceForMonthlyInvoicing";
import { TasksDataSource } from "../../../../DataSources/TasksDataSource";
import { WorkflowsForSelectListDataSource } from "../../../../DataSources/WorkflowsForSelectListDataSource";
import { ICurrenciesSettingsManager } from "../../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IMonthlyInvoicingDataSource, IMonthlyInvoicingDataSourceBaseFilters, IMonthlyInvoicingOptions, IJobOrderForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IJobOrderRolePrice } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IWorkedHoursDataSourceForMonthlyInvoicingWizard } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursDataSourceForMonthlyInvoicingWizard";
import { IWorkedHoursRow, IWorkedHoursForMonthlyInvoicingWizard } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursForMonthlyInvoicingWizard";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWorkedHoursForDocumentForMonthlyInvoicing, IEntityForMonthlyInvoicingTree, IWorkedHoursEnvelopeForMontlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IJobOrdersDataForMonthlyInvoicingRolesPrices } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { ILabelInfo, IEntityToImportInfoForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IDDTToImportForMonthlyInvoicingInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDDTToImportForMonthlyInvoicingInfo";
import { IWorkedHoursService, IExtendedWorkSheetRow, ISearchRequest, ISearchCountRequest } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IFiltersEngine } from "../../../../Core/interfaces/IFiltersEngine";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { ILogFilter, ILogTagFilter } from "../../../../ProlifeSdk/interfaces/ILogFilter";
import { IEntitySearchResult } from "../../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { ISearchTagFilter } from "../../../../ProlifeSdk/interfaces/ISearchTagFilter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IJobOrderCallRightTypesPrice } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { HoursApprovalState } from "../../../../WorkedHours/workedhours/enums/HoursApprovalState";

export class WorkedHoursDataSourceForMonthlyInvoicingWizard extends BaseDataSourceForMonthlyInvoicing implements IWorkedHoursDataSourceForMonthlyInvoicingWizard {
    public ShowAlreadyImported: ko.Observable<boolean> = ko.observable(false);
    public ShowJobOrderReferenceForWorkedHoursRows: ko.Observable<boolean> = ko.observable(false);
    
    public WorkflowId: ko.Observable<number> = ko.observable();
    public TaskId: ko.Observable<number> = ko.observable();
    public SearchFilters: ko.Observable<string> = ko.observable();
    public ShowOnlyApproved: ko.Observable<boolean> = ko.observable(true);

    public WorkflowsDataSource: WorkflowsForSelectListDataSource;
    public TasksDataSource: TasksDataSource;

    public WorkedHours: ko.ObservableArray<WorkedHoursRow> = ko.observableArray();
    
    public SelectAll: ko.Computed<boolean>;

    private wizard: IMonthlyInvoicingDataSource;
    private WatchesInstalled = false;
    private IsLoading = false;
    
    private lastTimeout : ReturnType<typeof setTimeout>;

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService : IEntityProviderService;
    @LazyImport(nameof<IFiltersEngine>())
    private filtersEngine: IFiltersEngine;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager : IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private jobOrderGeneralSettingsManager : IJobOrderGeneralSettingsManager;
    
    constructor() {
        super('worked-hours-step-for-monthly-invoicing', 'desktop/templates/wizard/data-sources', ProlifeSdk.TextResources.Desktop.ReportedHours);

        this.registerSupportedDestinationDocumentTypeCodes();
        this.documentsService.registerDocumentDataSourceForMonthlyInvoicing(this);

        const userId = this.userInfoService.getIdUser();
        const resourceId = this.resourcesManager.getLoggedResourceId();

        this.WorkflowsDataSource = new WorkflowsForSelectListDataSource();
        this.WorkflowsDataSource.setResourceId(resourceId);
        this.WorkflowsDataSource.setViewAll(true);
        this.WorkflowsDataSource.setViewResponsible(true);
        this.WorkflowsDataSource.setViewWorker(true);
        
        this.TasksDataSource = new TasksDataSource();
        this.TasksDataSource.setUserId(userId);
        this.TasksDataSource.setResourceId(resourceId);
        this.TasksDataSource.setBypassCanViewAllForJobOrderTasksPlanning(true);
        this.TasksDataSource.setGetTasksIfNoFilter(true);
        this.TasksDataSource.setGetWorkflowsInWorkflows(false);
        this.TasksDataSource.setShowClosed(true);
        this.TasksDataSource.setViewAll(true);
        
        this.JobOrder.subscribe((id) => {
            this.WorkflowsDataSource.setJobOrderId(id);
            this.TasksDataSource.setJobOrderId(id);
        });

        this.WorkflowId.subscribe((id) => {
            this.TasksDataSource.setWorkflows([id]);
        });

        this.SelectAll = ko.computed({
            read: () => {
                const workedHours = this.WorkedHours();
                return workedHours.filter((r : IWorkedHoursRow) => !r.Selected()).length == 0;
            },
            write: (selected : boolean) => {
                const workedHours = this.WorkedHours();
                workedHours.forEach((r : IWorkedHoursRow) => r.Selected(selected));
            }
        });
    }

    public Initialize()
    {
        this.DisableDocumentsReload = true;

        super.Initialize();

        this.SearchFilters(null);
        this.ShowAlreadyImported(false);
        this.ShowJobOrderReferenceForWorkedHoursRows(false);
        this.ShowOnlyApproved(this.jobOrderGeneralSettingsManager.getSetFlagIsApprovedWorkedHours());

        this.installWatches();

        this.DisableDocumentsReload = false;

        if (this.loadRowsAfterInitialize)
            this.loadWorkedHours();
    }

    public InitializeFilters(filters: IMonthlyInvoicingDataSourceBaseFilters): void {
        super.InitializeFilters(filters);
        this.loadWorkedHours();
    }

    public SetDataSourceOptions(monthlyInvoicingOptions: IMonthlyInvoicingOptions): void {
        monthlyInvoicingOptions.ShowJobOrderReferenceForWorkedHoursRows = this.ShowJobOrderReferenceForWorkedHoursRows();
    }

    public SetWizard(wizard: IMonthlyInvoicingDataSource): void {
        this.wizard = wizard;
    }

    public async GetDocumentsToImportInfo(): Promise<IDocumentToImportInfo[]> {
        const result: IWorkedHoursForMonthlyInvoicingWizard[] = [];
        const rows: IWorkedHoursForDocumentForMonthlyInvoicing[] = this.Rows();

        rows.forEach((r: IWorkedHoursForDocumentForMonthlyInvoicing) => {
            result.push(new WorkedHoursRowForGrouping(r, this.wizard));
        });

        return result;
    }

    public async importAggregateResource(): Promise<void>
    {
        const selectedRows = this.filterRows();
        await this.loadRolesForSelectedRows(selectedRows);

        this.aggregateByResourceId(selectedRows)
            .forEach((filteredRows: IWorkedHoursRow[]) => this.aggregateByJobOrderId(filteredRows).forEach((jobOrderRows: IWorkedHoursRow[]) => this.aggregateByRoleId(jobOrderRows).forEach(this.addResourceAggregatedRow.bind(this))));
    }

    public async importAggregateRole(): Promise<void>
    {
        const selectedRows = this.filterRows();
        await this.loadRolesForSelectedRows(selectedRows);

        this.aggregateByJobOrderId(selectedRows)
            .forEach((filteredRows: IWorkedHoursRow[]) => this.aggregateByRoleId(filteredRows).forEach(this.addRoleAggregatedRow.bind(this)));
    }

    public async importDetailed(): Promise<void>
    {
        const selectedRows = this.filterRows();
        await this.loadRolesForSelectedRows(selectedRows);

        selectedRows.forEach((r: IWorkedHoursRow) => this.addDetailedRow(r));
    }

    public removeRow(row : IWorkedHoursForDocumentForMonthlyInvoicing)
    {
        this.Rows.remove(row);
    }

    public EmptiesImportList(): void {
        this.Rows([]);
    }

    public findSearchMatches(query: ISelect2Query): void
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() =>
        {
            this.search(query.term, query.callback);
        }, 500);
    }

    public findSearchMatch(element: HTMLElement, callback: (result: any) => void): void {
        if (!this.SearchFilters()) {
            callback(null);
            return;
        }

        const searchFilters = this.SearchFilters();

        const tags: ILogFilter[] = searchFilters.split("|").map(j => JSON.parse(j));
        const providersDefs: Promise<any>[] = [];

        const requestsResults: IEntitySearchResult[] = [];
        const results: any[] = [];

        tags.forEach((tag) => {
            tag.Tags.forEach((t) => {
                const provider = this.entitiesProviderService.getEntityProvider(t.ValueTypeId);
    
                t.Values.forEach((v) => {
                    providersDefs.push(provider.getEntity(v).then((e) => {
                        requestsResults.push({ provider: provider, entities: [e] });
                    }));
                });
            });
        });
        
        Promise.all(providersDefs).then(() => {
            requestsResults.forEach((result : IEntitySearchResult) => {

                if(result.entities.length == 0)
                    return;

                result.entities.forEach(e => {
                    const tags : ILogTagFilter[] = [{
                        Values : [result.provider.getPkValue(e)],
                        ValueTypeId : result.provider.getType()
                    }];

                    const entityFilter : ILogFilter = {
                        Tags : tags
                    };

                    results.push({
                        id : JSON.stringify(entityFilter),
                        text : result.provider.getEntityTypeName() + ':   ' + result.provider.getDisplayName(e)
                    });
                });
            });

            callback(results);
        });
    }

    private async loadRolesForSelectedRows(rows: IWorkedHoursRow[]): Promise<void> {
        const jobOrders = this.filterRows().map((r) => r.JobOrderId());
        await this.wizard.LoadRolesConfiguredOnJobOrders(jobOrders);
    }

    private registerSupportedDestinationDocumentTypeCodes(): void {
        this.SupportedDestinationDocumentsTypesCodes.push(ProlifeSdk.InvoiceEntityTypeCode);
    }

    private wasUsedDuringThisWizard(wh: IExtendedWorkSheetRow) : boolean
    {
        return this.Rows()
            .filter((r: IWorkedHoursForDocumentForMonthlyInvoicing) => r.WorkedHours.indexOf(wh) > -1)
            .length > 0;
    }

    private async onWorkedHoursLoaded(workedHours : IExtendedWorkSheetRow[]): Promise<void>
    {
        const jobOrdersToLoad = [];
        const customersToLoad = [];

        for (const wh of workedHours) {
            jobOrdersToLoad.push(wh.JobOrderId);

            if (wh.CustomerId)
                customersToLoad.push(wh.CustomerId);
        }

        await Promise.all([
            this.wizard.LoadJobOrdersIntoCache(jobOrdersToLoad),
            this.wizard.LoadCustomersIntoCache(customersToLoad)
        ]);

        const workedHoursViewModels: WorkedHoursRow[] = workedHours.map(this.createViewModelFor.bind(this));
        this.WorkedHours(workedHoursViewModels);

        this.SelectAll(true);
    }

    private createViewModelFor(workedHours: IExtendedWorkSheetRow): WorkedHoursRow
    {
        return new WorkedHoursRow(workedHours, this.rolesManager, this.wizard);
    }

    private filterRows(): IWorkedHoursRow[]
    {
        const selectedWorkedHours = this.WorkedHours().filter((r: IWorkedHoursRow) => r.Selected());
        if(selectedWorkedHours.length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.NoWorkSheetSelected);
            return [];
        }

        const notImportedWorkedHours: IWorkedHoursRow[] = selectedWorkedHours
            .filter((wh: IWorkedHoursRow) => !this.wasUsedDuringThisWizard(wh.GetWorkedHours()));

        if(notImportedWorkedHours.length != selectedWorkedHours.length) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.ImportWorkSheetsWarning);
        }

        return notImportedWorkedHours;
    }

    private aggregateByResourceId(rows: IWorkedHoursRow[]): IWorkedHoursRow[][]
    {
        return rows.reduce((prev: any, item: IWorkedHoursRow) => {
            if( prev[item.ResourceId()] ) prev[item.ResourceId()].push(item);
            else prev[item.ResourceId()] = [item];
            return prev;
        }, []);
    }

    private aggregateByRoleId(rows: IWorkedHoursRow[]): IWorkedHoursRow[][]
    {
        return rows.reduce((prev: any, item: IWorkedHoursRow) => {
            if (prev[item.RoleId()])
                prev[item.RoleId()].push(item);
            else
                prev[item.RoleId()] = [item];
            return prev;
        }, []);
    }

    private aggregateByJobOrderId(rows: IWorkedHoursRow[]): IWorkedHoursRow[][]
    {
        return rows.reduce((prev: any, item: IWorkedHoursRow) => {
            if (prev[item.JobOrderId()])
                prev[item.JobOrderId()].push(item);
            else
                prev[item.JobOrderId()] = [item];
            return prev;
        }, []);
    }

    private getRole(roleId: number, jobOrder: IJobOrderForMonthlyInvoicing): IJobOrdersDataForMonthlyInvoicingRolesPrices
    {
        const defaultRole = this.rolesManager.getUserCharacterById(roleId);

        if (!defaultRole)
            return { Salary: 0, Description: ProlifeSdk.TextResources.Desktop.RoleNotFound, RoleId: -1, JobOrderId: null, RolePriceId: null };

        const roles: IJobOrdersDataForMonthlyInvoicingRolesPrices[] = jobOrder.RolesPrices.filter((rp : IJobOrdersDataForMonthlyInvoicingRolesPrices) => rp.RoleId == roleId);

        return {
            Salary: roles.length > 0 ? roles[0].Salary : defaultRole.Salary,
            Description: defaultRole.Description,
            RoleId: defaultRole.IdUserCharacter,
            JobOrderId: null,
            RolePriceId: null
        };
    }

    private addDetailedRow(workedHours: IWorkedHoursRow)
    {
        const salary = workedHours.GetHoursUnitPrice();
        const totalHours : number = workedHours.CalculateWorkTime();
        const role = this.getRole(workedHours.RoleId(), workedHours.GetJobOrder());
        const callRightCost = workedHours.GetCallRightCost();
        const description = moment(workedHours.WorkDate()).format("L") + " - " + workedHours.ResourceName() + " - " + role.Description + (workedHours.Description() ? " - " + workedHours.Description() : "");
        const jobOrder: IJobOrderForMonthlyInvoicing = workedHours.GetJobOrder();

        const extWorksheetRow = workedHours.GetWorkedHours();

        const hours : IWorkedHoursForDocumentForMonthlyInvoicing = {
            WorkedHours : [extWorksheetRow],
            Description : description,
            Amount : totalHours,
            NetUnitPrice : salary,
            NetPrice : salary * totalHours,
            TotalDdc : 0,
            Dependency: null,
            JobOrderId: jobOrder.JobOrderId,
            JobOrderName: workedHours.JobOrderName(),
            CustomerId: workedHours.CustomerId(),
            CustomerName: extWorksheetRow.CustomerName,
            AggregationType: ProlifeSdk.DetailedWorkedHours,
            CurrencyCode: workedHours.CurrencyCode(),
            CurrencySymbol: workedHours.CurrencySymbol()
        };

        this.Rows.push(hours);
        this.addCallsRightRow(workedHours.CallRight() ? 1 : 0, callRightCost, hours);
    }

    private addResourceAggregatedRow(hours: IWorkedHoursRow[], roleId: number)
    {
        const salaries = [];
        hours.forEach((h : IWorkedHoursRow) => {
            const salary: number = h.GetHoursUnitPrice();
            salaries.push({ Salary: salary, Row: h });
        });

        let totalPrice = 0;
        let totalHours = 0;
        let callRightCost = 0;
        let callsNumber = 0;

        hours.forEach((h : IWorkedHoursRow) => {
            const matches = salaries.filter((s) => {
                return s.Row == h;
            });
            totalHours += h.CalculateWorkTime();
            totalPrice += (matches[0].Salary * h.CalculateWorkTime());
            const crCost = h.GetCallRightCost();
            callRightCost += crCost;
            callsNumber += crCost > 0 ? 1 : 0;
        });

        const role = this.getRole(roleId, hours[0].GetJobOrder());
        let description = "";
        description += this.DateFrom() ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L")) : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo() ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L")) : ProlifeSdk.TextResources.Desktop.EndDateToday;
        description += " - " + hours[0].ResourceName() + " - " + role.Description;

        const hourPrice = totalHours > 0 ? totalPrice / totalHours : 0;
        const jobOrder: IJobOrderForMonthlyInvoicing = hours[0].GetJobOrder();

        const workedHours : IWorkedHoursForDocumentForMonthlyInvoicing = {
            WorkedHours : hours.map((h: IWorkedHoursRow) => h.GetWorkedHours()),
            Description : description,
            Amount : totalHours,
            NetUnitPrice : hourPrice,
            NetPrice : totalPrice,
            TotalDdc : 0,
            Dependency: null,
            JobOrderId: jobOrder.JobOrderId,
            JobOrderName: hours[0].JobOrderName(),
            CustomerId: hours[0].CustomerId(),
            CustomerName: hours[0].GetWorkedHours().CustomerName,
            AggregationType: ProlifeSdk.AggregatedByResourceWorkedHours,
            CurrencyCode: hours[0].CurrencyCode(), // N.B. per ora le ore lavorate sono sempre in Euro, quindi posso aggregare senza problemi. In futuro questa logica andrà rivista.
            CurrencySymbol: hours[0].CurrencySymbol()
        };

        this.Rows.push(workedHours);

        const callPrice = callsNumber > 0 ? callRightCost / callsNumber : 0;
        this.addCallsRightRow(callsNumber, callPrice, workedHours);
    }

    private addRoleAggregatedRow(hours: IWorkedHoursRow[], roleId: number)
    {
        const salaries = [];
        hours.forEach((h : IWorkedHoursRow) => {
            const salary = h.GetHoursUnitPrice();
            salaries.push({ Salary : salary, Row : h});
        });

        let totalPrice = 0;
        let totalHours = 0;
        let callRightCost = 0;
        let callsNumber = 0;
        hours.forEach((h : IWorkedHoursRow) => {
            const matches = salaries.filter((s) => {
                return s.Row == h;
            });
            totalHours += h.CalculateWorkTime();
            totalPrice += (matches[0].Salary * h.CalculateWorkTime());
            const crCost = h.GetCallRightCost();
            callRightCost += crCost;
            callsNumber += crCost > 0 ? 1 : 0;
        });

        const role = this.getRole(roleId, hours[0].GetJobOrder());
        let description = "";
        description += this.DateFrom() ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L")) : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo() ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L")) : ProlifeSdk.TextResources.Desktop.EndDateToday;
        description += " - " + role.Description;

        const hourPrice = totalHours > 0 ? totalPrice / totalHours : 0;
        const jobOrder: IJobOrderForMonthlyInvoicing = hours[0].GetJobOrder();

        const workedHours : IWorkedHoursForDocumentForMonthlyInvoicing = {
            WorkedHours : hours.map((h: IWorkedHoursRow) => h.GetWorkedHours()),
            Description : description,
            Amount : totalHours,
            NetUnitPrice : hourPrice,
            NetPrice : totalPrice,
            TotalDdc : 0,
            Dependency: null,
            JobOrderId: jobOrder.JobOrderId,
            JobOrderName: hours[0].JobOrderName(),
            CustomerId: hours[0].CustomerId(),
            CustomerName: hours[0].GetWorkedHours().CustomerName,
            AggregationType: ProlifeSdk.AggregatedByRoleWorkedHours,
            CurrencyCode: hours[0].CurrencyCode(), // N.B. per ora le ore lavorate sono sempre in Euro, quindi posso aggregare senza problemi. In futuro questa logica andrà rivista.
            CurrencySymbol: hours[0].CurrencySymbol()
        };

        this.Rows.push(workedHours);
        const callPrice = callsNumber > 0 ? callRightCost / callsNumber : 0;
        this.addCallsRightRow(callsNumber, callPrice, workedHours);
    }

    private addCallsRightRow(calls: number, callsPrice: number, dependency: IWorkedHoursForDocumentForMonthlyInvoicing)
    {
        if(calls == 0)
            return;

        const callRightRow: IWorkedHoursForDocumentForMonthlyInvoicing = {
            WorkedHours: [],
            Description: dependency.Description + ProlifeSdk.TextResources.Desktop.CallsRights,
            Amount: calls,
            NetUnitPrice: callsPrice,
            NetPrice: callsPrice * calls,
            TotalDdc: 0,
            Dependency: dependency,
            JobOrderId: dependency.JobOrderId,
            JobOrderName: dependency.JobOrderName,
            CustomerId: dependency.CustomerId,
            CustomerName: dependency.CustomerName,
            AggregationType: dependency.AggregationType,
            CurrencyCode: dependency.CurrencyCode,
            CurrencySymbol: dependency.CurrencySymbol
        };

        this.Rows.push(callRightRow);
    }

    private installWatches(): void {
        if (this.WatchesInstalled)
            return;

        this.DateFrom.subscribe(() => {
            if(!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateTo(moment(this.DateFrom()).endOf("day").toDate());

            this.loadWorkedHours();
        });
        this.DateTo.subscribe(() => {
            if(!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateTo(moment(this.DateFrom()).endOf("day").toDate());

            this.loadWorkedHours();
        });
        this.JobOrder.subscribe(this.loadWorkedHours.bind(this));
        this.Customer.subscribe(this.loadWorkedHours.bind(this));
        this.JobOrderType.subscribe(this.loadWorkedHours.bind(this));
        this.WorkflowId.subscribe(this.loadWorkedHours.bind(this));
        this.TaskId.subscribe(this.loadWorkedHours.bind(this));
        this.SearchFilters.subscribe(this.loadWorkedHours.bind(this));
        this.ShowAlreadyImported.subscribe(this.loadWorkedHours.bind(this));
        this.ShowOnlyApproved.subscribe(this.loadWorkedHours.bind(this));

        this.WatchesInstalled = true;
    }

    private async loadWorkedHours(): Promise<IExtendedWorkSheetRow[]> {
        if (this.DisableDocumentsReload /*|| this.IsLoading*/)
            return [];

        this.IsLoading = true;

        const tagsFilters: ISearchTagFilter[] = this.prepareTagsList();
        const approvalState = this.ShowOnlyApproved() ? HoursApprovalState.Approved : null;

        const request: ISearchRequest = {
            JobOrderId: this.JobOrder(),
            JobOrderType: this.JobOrderType(),
            CustomerId: this.Customer(),
            From: this.DateFrom(),
            To: this.DateTo(),
            Billable: true,
            ShowBilled: this.ShowAlreadyImported(),
            IgnoreRowsWithoutCustomer: true,
            WorkflowId: this.WorkflowId(),
            TaskId: this.TaskId(),
            SelectedTasks: [],
            Roles: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "UserRole"),
            HumanResources: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "HumanResources"),
            ResourcesGroups: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "ResourcesGroups"),
            ApprovalState: approvalState,
            Skip: null,
            Count: null
        };

        const countRequest: ISearchCountRequest = {
            JobOrderId: this.JobOrder(),
            JobOrderType: this.JobOrderType(),
            CustomerId: this.Customer(),
            From: this.DateFrom(),
            To: this.DateTo(),
            Billable: true,
            ShowBilled: this.ShowAlreadyImported(),
            IgnoreRowsWithoutCustomer: true,
            Roles: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "UserRole"),
            HumanResources: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "HumanResources"),
            ResourcesGroups: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "ResourcesGroups"),
            WorkflowId: this.WorkflowId(),
            TaskId: this.TaskId(),
            SelectedTasks: [],
            ApprovalState: approvalState
        };

        try {
            const rowsNumber: number = await this.workedHoursService.SearchCount(countRequest);
            let confirm = true;
            if (rowsNumber >= 1000)
                confirm = await this.confirmRowsSearch(rowsNumber);

            if (confirm) {
                const workedHours: IExtendedWorkSheetRow[] = await this.workedHoursService.Search(request);
                this.onWorkedHoursLoaded(workedHours);

                return workedHours;
            }
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => { this.IsLoading = false; }, 200);
        }

        return [];
    }

    private async confirmRowsSearch(rows: number): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(
            String.format(ProlifeSdk.TextResources.Desktop.ConfirmEntitiesSearch, rows),
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton
        );
    }

    private prepareTagsList(): ISearchTagFilter[] {
        if (!this.SearchFilters())
            return [];

        const filters: ISearchTagFilter[] = [];
        const tags: string[] = this.SearchFilters().split("|");

        tags.forEach((t: string) => {
            JSON.parse(t).Tags.forEach((tag: ISearchTagFilter) => filters.push(tag));
        });

        return filters;
    }

    private search(searchFilter : string, callback : (data : any) => void)
    {
        const data = { results: [] };

        const providersDef = new Deferred();
        this.entitiesProviderService.search(searchFilter, [ProlifeSdk.HumanResources, ProlifeSdk.ResourcesGroups, ProlifeSdk.RolesEntityType]).then((results : IEntitySearchResult[]) => {

            results.forEach((result : IEntitySearchResult) => {

                if(result.entities.length == 0)
                    return;

                const menuGroup = {
                    text : result.provider.getEntityTypeName(),
                    children : []
                };

                result.entities.forEach(e => {
                    const tags : ILogTagFilter[] = [{
                        Values : [result.provider.getPkValue(e)],
                        ValueTypeId : result.provider.getType()
                    }];

                    const entityFilter : ILogFilter = {
                        Tags : tags
                    };

                    menuGroup.children.push({
                        id : JSON.stringify(entityFilter),
                        text : result.provider.getEntityTypeName() + ':   ' + result.provider.getDisplayName(e)
                    });
                });

                data.results.push(menuGroup);

            });

            providersDef.resolve();
        }).catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
    }
}

class WorkedHoursRow implements IWorkedHoursRow
{
    public Id: number;
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public Date: ko.Observable<Date> = ko.observable();
    public CustomerId: ko.Observable<number> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public WorkflowTitle: ko.Observable<string> = ko.observable();
    public TaskTitle: ko.Observable<string> = ko.observable();
    public ResourceId: ko.Observable<number> = ko.observable();
    public ResourceName: ko.Observable<string> = ko.observable();
    public RoleId: ko.Observable<number> = ko.observable();
    public ResourceRole: ko.Observable<string> = ko.observable();
    public Hours: ko.Observable<number> = ko.observable();
    public Overtime: ko.Observable<number> = ko.observable();
    public AlreadyImported: ko.Observable<boolean> = ko.observable();
    public CallRightPrice: ko.Observable<number> = ko.observable();
    public WorkTimeCategoryDescr: ko.Observable<string> = ko.observable();
    public WorkDate: ko.Observable<Date> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public CallRight: ko.Observable<boolean> = ko.observable();
    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public CurrencyCode: ko.Observable<string> = ko.observable();
    
    private jobOrder : IJobOrderForMonthlyInvoicing;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesManager: ICallRightTypesSettingsManager;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(private workedHours: IExtendedWorkSheetRow, private rolesManager: IUserCharactersSettingsManager, private wizard: IMonthlyInvoicingDataSource)
    {
        this.Id = this.workedHours.HoursId;

        this.Date(this.workedHours.WorkDate);
        this.CustomerId(this.workedHours.CustomerId);
        this.JobOrderId(this.workedHours.JobOrderId);
        this.ResourceId(this.workedHours.ResourceId);
        this.ResourceName(this.workedHours.ResourceName);
        this.RoleId(this.workedHours.RoleId);
        this.ResourceRole(this.rolesManager.getUserCharacterById(this.workedHours.RoleId).Description);
        this.WorkTimeCategoryDescr(this.workTimeCategoriesManager.getById(this.workedHours.WorkTimeCategoryId).Name);
        this.Hours(this.workedHours.Hours);
        this.Overtime(0);
        this.AlreadyImported(this.workedHours.IsAlreadyImported);
        this.WorkDate(this.workedHours.WorkDate);
        this.Description(this.workedHours.Description);
        this.CallRight(this.workedHours.CallRight);
        this.JobOrderName(this.workedHours.JobOrderName);
        this.WorkflowTitle(this.workedHours.WorkflowTitle);
        this.TaskTitle(this.workedHours.TaskTitle);

        const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
        this.CurrencyCode(defaultCurrency.CodeISO4217alpha3);
        this.CurrencySymbol(defaultCurrency.Symbol);

        this.jobOrder = this.wizard.JobOrdersCache.get(this.workedHours.JobOrderId);
        
        const callRightTypeId = this.rolesManager.getUserCharacterById(this.workedHours.RoleId).DefaultCallRightType;
        const callRightTypesMatches = this.jobOrder.CallRightTypesPrices.filter((t : IJobOrderCallRightTypesPrice) => { return t.CallRightId == callRightTypeId; });
        let callRightPrice = 0;

        if(callRightTypesMatches.length > 0)
            callRightPrice = callRightTypesMatches[0].Price;
        else {
            const callRight = this.callRightTypesManager.getById(callRightTypeId);
            if(callRight)
                callRightPrice = callRight.Price;
        }

        this.CallRightPrice(this.workedHours.CallRight ? callRightPrice : 0);
    }
    
    public GetCallRightCost(): number
    {
        if(!this.workedHours.CallRight)
            return 0;

        const callRightTypeId = this.rolesManager.getUserCharacterById(this.workedHours.RoleId).DefaultCallRightType;
        const callRightTypesMatches = this.jobOrder.CallRightTypesPrices.filter((t: IJobOrderCallRightTypesPrice) => {
            return t.CallRightId == callRightTypeId;
        });
        if(callRightTypesMatches.length > 0)
            return callRightTypesMatches[0].Price;

        const callRight = this.callRightTypesManager.getById(callRightTypeId);
        if(callRight)
            return callRight.Price;

        return 0;
    }

    public GetHoursUnitPrice(): number
    {
        const role : IUserCharacter = this.rolesManager.getUserCharacterById(this.workedHours.RoleId);
        const roles = this.wizard.RolesPrices.get(this.JobOrderId());

        if (!roles)
            return role.Salary;

        const matches : IJobOrderRolePrice[] = roles.filter((r : IJobOrderRolePrice) => { return r.UserCharacterId == this.workedHours.RoleId; });
        return matches.length > 0 ? matches[0].Salary : role.Salary;
    }

    public CalculateWorkTime(): number
    {
        return this.workedHours.Hours;
    }

    public GetWorkedHours(): IExtendedWorkSheetRow
    {
        return this.workedHours;
    }

    public GetJobOrder(): IJobOrderForMonthlyInvoicing
    {
        return this.jobOrder;
    }
}

class WorkedHoursRowForGrouping extends MonthlyInvoicingDataRow implements IWorkedHoursForMonthlyInvoicingWizard {
    public templateName = "worked-hours-for-grouping";
    public templateUrl = "invoices/templates/wizard/steps";

    public WorkedHoursRow: IWorkedHoursForDocumentForMonthlyInvoicing;

    public WorkPeriod: ko.Observable<string> = ko.observable();
    public JobOrderId:ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public CustomerId:ko.Observable<number> = ko.observable();
    public CustomerName:ko.Observable<string> = ko.observable();
    public PaymentTypeId:ko.Observable<number> = ko.observable();
    public PaymentType:ko.Observable<string> = ko.observable();
    public ExpiryTypeId:ko.Observable<number> = ko.observable();
    public ExpiryType:ko.Observable<string> = ko.observable();

    public Resource: ko.Computed<string>;
    public Hours: ko.Computed<number>;
    public Role: ko.Computed<string>;

    private computedJobOrderId: ko.Computed<number>;

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager : IUserCharactersSettingsManager;

    constructor(wh: IWorkedHoursForDocumentForMonthlyInvoicing, private wizard: IMonthlyInvoicingDataSource) {
        super();

        this.Id = wh.WorkedHours.length == 1 ? wh.WorkedHours[0].HoursId : null;
        this.Name = wh.Description;
        this.EntityType = ProlifeSdk.WorkedHoursEntityTypeCode;
        this.WorkedHoursRow = wh;

        this.JobOrderId(wh.WorkedHours.length == 0 ? wh.Dependency.WorkedHours[0].JobOrderId : wh.WorkedHours[0].JobOrderId);
        this.JobOrderName(wh.JobOrderName);
        this.CustomerId(wh.CustomerId);
        this.CustomerName(wh.CustomerName);
        this.PaymentTypeId(null);
        this.PaymentType(null);
        this.ExpiryTypeId(null);
        this.ExpiryType(null);
        this.Total(wh.NetPrice);

        this.CurrencyCode(wh.CurrencyCode);
        this.CurrencySymbol(wh.CurrencySymbol);

        this.DocumentCurrencies.push(this.createDocumentCurrencyFromDefaultCurrency(this.Id, this.EntityType));

        this.TotalInDefaultCurrency = ko.computed(() => {
            return this.Total();
        });

        this.Resource = ko.computed(() => {
            if (!this.WorkedHoursRow)
                return "";

            return this.WorkedHoursRow.WorkedHours.length == 0 ? this.WorkedHoursRow.Dependency.WorkedHours[0].ResourceName : this.WorkedHoursRow.WorkedHours[0].ResourceName;
        });

        this.Hours = ko.computed(() => {
            if (!this.WorkedHoursRow)
                return 0;

            return this.WorkedHoursRow.WorkedHours.length == 0 ? this.WorkedHoursRow.Dependency.WorkedHours.reduce((acc: number, current: IExtendedWorkSheetRow) => { return acc + current.Hours}, 0) : this.WorkedHoursRow.WorkedHours.reduce((acc: number, current: IExtendedWorkSheetRow) => { return acc + current.Hours}, 0);
        });

        this.Role = ko.computed(() => {
            if (!this.WorkedHoursRow)
                return "";

            const role = this.rolesManager.getUserCharacterById(this.WorkedHoursRow.WorkedHours.length == 0 ? this.WorkedHoursRow.Dependency.WorkedHours[0].RoleId : this.WorkedHoursRow.WorkedHours[0].RoleId)
            return !role ? "" : role.Description;
        });

        this.computedJobOrderId = ko.computed(() => {
            if (!this.WorkedHoursRow)
                return 0;

            const jobOrderId = this.WorkedHoursRow.WorkedHours.length == 0 ? this.WorkedHoursRow.Dependency.WorkedHours[0].JobOrderId : this.WorkedHoursRow.WorkedHours[0].JobOrderId;
            this.JobOrderId(jobOrderId);
            return jobOrderId;
        });

        this.calculateWorkPeriod();
    }

    public GetLabelInfo(): ILabelInfo {
        return {
            EntityType: this.EntityType,
            Label: this.LabelForInvoiceGrouping(),
            CustomerId: this.CustomerId(),
            JobOrder: this.JobOrderId(),
            PaymentMode: null,
            ExpiryMode: null,
            NumberOfItemsWithLabel: 0
        };
    }

    public VerifyDocumentCompatibility(labelInfo: ILabelInfo): boolean {
        return this.JobOrderId() == labelInfo.JobOrder
            && this.CustomerId() == labelInfo.CustomerId;
    }

    public ApplyLabelToSelectedEntities(): void {
        if (!this.storage())
            return;

        const invalidSelections: string[] = [];

        this.storage().SelectedEntities().forEach((entity: IEntityToImportInfoForMonthlyInvoicing) => {
            if (!entity.VerifyDocumentCompatibility(this.GetLabelInfo())) {
                let name: string = entity.Name;
                if (entity.EntityType == ProlifeSdk.DdtEntityTypeCode)
                    name += " - " + (<IDDTToImportForMonthlyInvoicingInfo>entity).ProtocolName();

                invalidSelections.push(name);
                return;
            }

            entity.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        });

        if (invalidSelections.length > 0) {
            let message = "<br/>";
            invalidSelections.forEach((m: string) => message += m + "<br/>");
            message += "<br/>";
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Invoices.InvalidDocumentsForLabelApplication, message));
        }
    }

    public async GetCopy(): Promise<IEntityToImportInfoForMonthlyInvoicing> {
        const wh = new WorkedHoursRowForGrouping(this.WorkedHoursRow, this.wizard);
        wh.storage(null);
        wh.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        wh.CustomerName(this.CustomerName());
        return wh;
    }

    public GetDocumentForMonthlyInvoicingTree(importedDocuments: IEntityForMonthlyInvoicingTree[], documentLabel: string = null): IEntityForMonthlyInvoicingTree {
        return {
            EntityId: this.Id,
            EntityType: this.EntityType,
            DocumentLabel: documentLabel === null ? this.Name : documentLabel,
            VatRegisterId: null,
            CustomerId: this.CustomerId(),
            CustomerName: this.CustomerName(),
            JobOrderId: this.JobOrderId(),
            ExpiryTypeId: this.ExpiryTypeId(),
            ExpiryType: this.ExpiryType(),
            PaymentTypeId: this.PaymentTypeId(),
            PaymentType: this.PaymentType(),
            PaymentIBAN: null,
            PaymentABI: null,
            PaymentCAB: null,
            DocumentCurrencies: this.DocumentCurrencies().map((c) => c.getData()),
            IsPartialInvoice: false,
            ImportedDocuments: importedDocuments,
            WorkedHoursRow: this.createWorkedHoursForMonthlyInvoicing(),
            PurchasesRow: null
        };
    }

    private createWorkedHoursForMonthlyInvoicing(): IWorkedHoursEnvelopeForMontlyInvoicing {
        const wh: IWorkedHoursEnvelopeForMontlyInvoicing = {
            HoursIds: this.WorkedHoursRow.WorkedHours.map((w: IExtendedWorkSheetRow) => w.HoursId),
            AggregationType: this.WorkedHoursRow.AggregationType,
            IsCallRightRow: !!this.WorkedHoursRow.Dependency,
            Dependency: !this.WorkedHoursRow.Dependency ? null : {
                HoursIds: this.WorkedHoursRow.Dependency.WorkedHours.map((w: IExtendedWorkSheetRow) => w.HoursId),
                AggregationType: (<IWorkedHoursForDocumentForMonthlyInvoicing> this.WorkedHoursRow.Dependency).AggregationType,
                IsCallRightRow: false,
                Dependency: null
            }
        };

        return wh;
    }

    private calculateWorkPeriod(): void {
        const workedHours: IExtendedWorkSheetRow[] = this.WorkedHoursRow.WorkedHours.length == 0 ? this.WorkedHoursRow.Dependency.WorkedHours : this.WorkedHoursRow.WorkedHours;
        workedHours.sort((a: IExtendedWorkSheetRow, b: IExtendedWorkSheetRow) => {
            return moment(a.WorkDate).valueOf() - moment(b.WorkDate).valueOf();
        });

        const startDate: Moment = moment(workedHours[0].WorkDate);
        const endDate: Moment = moment(workedHours[workedHours.length - 1].WorkDate);

        const text: string = endDate.startOf("day").diff(startDate.startOf("day"), "days") > 1 ?
            String.format(ProlifeSdk.TextResources.Invoices.WorkedHoursWorkPeriodString, moment(workedHours[0].WorkDate).format("L"), moment(workedHours[workedHours.length - 1].WorkDate).format("L")) :
            startDate.format("L");

        this.WorkPeriod(text);
    }
}