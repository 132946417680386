import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDDTPorto, IDDTPortiSettingsManager } from "../../../../ProlifeSdk/interfaces/invoice/settings/IDDTPortiSettingsManager";

export interface IDDTPortiProvider {
    getData() : IDDTPorto;
    update(porto : IDDTPorto) : void;
}

export class DDTPortiEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IDDTPortiProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private portiSettingsManager : IDDTPortiSettingsManager) {
        this.title = portiSettingsManager.getLabel();

        var porti = portiSettingsManager.getDDTPorti();
        porti.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(porto : IDDTPorto): void {
        this.elements.push(new DDTPortoViewModel(this, porto));
    }

    public addNewSetting(): void {
        var portoViewModel = new DDTPortoViewModel(this, {
            Description : this.newSettingName()
        });
        this.elements.push(portoViewModel);
        this.createOrUpdateEntry(portoViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IDDTPortiProvider) {
        var porto = element.getData();
        this.portiSettingsManager.createOrUpdate(porto)
            .then((p) => element.update(p));
    }

    public deleteEntry(element : IDDTPortiProvider) {
        this.elements.remove(element);
        var p = element.getData();
        this.portiSettingsManager.remove(p.Id);
    }
}

class DDTPortoViewModel implements IDDTPortiProvider {
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : DDTPortiEditingViewModel, private porto : IDDTPorto) {
        this.name(porto.Description);
        this.name.subscribe(this.onNameChanged.bind(this));
    }

    private onNameChanged(newValue : string) {
        if(!newValue) {
            this.container.deleteEntry(this);
        } else {
            this.container.createOrUpdateEntry(this);
        }
    }

    getData() : IDDTPorto {
        var porto : IDDTPorto = <IDDTPorto> $.extend({}, this.porto);
        porto.Description = this.name();
        return porto;
    }

    update(porto : IDDTPorto) : void {
        this.porto = porto;
        this.name(this.porto.Description);
    }
}