import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { JobOrderRolesPrices } from "../JobOrderRolesPrices";
import { JobOrderCallRightTypesPrice } from "../JobOrderCallRightTypesPrice";
import { EntityNotificationsManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntityNotificationsManager";
import { JobOrderMetadataDataSource } from "../../../../DataSources/JobOrderMetadataDataSource";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderMetadataSettingsManager } from "../../settings/JobOrderMetadataSettingsManager";
import { OffsetsCodesDataSource } from "../../../../DataSources/OffsetsCodesDataSource";
import { WorkflowOutcomesDataSource } from "../../../../DataSources/WorkflowOutcomesDataSource";
import { CurrenciesDataSource } from "../../../../DataSources/CurrenciesDataSource";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ResourcesDataSource } from "../../../../DataSources/ResourcesDataSource";
import {
    IJobOrderViewModel,
    IJobOrderProjectViewModel,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IDocumentsProvider } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IException } from "../../../../Core/interfaces/IException";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IWarehousesService,
    IWarehouseWithStockInfo,
    IWarehouse,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IEntitiesLockService } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { IEntityProvider } from "../../../../ProlifeSdk/interfaces/IEntityProvider";
import { IJobOrderState } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType, IJobOrderTypeResponsible } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { ICustomerControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/customer/providers/ICustomerControlsEntityProvider";
import {
    IProtocolDefaultValuesSettingsUi,
    IProtocolSetting,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { IIvaMode, IIvaModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { IExpireMode, IExpireModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { IPaymentTypeManagerUi } from "../../../../ProlifeSdk/interfaces/prolife-sdk/ui/IPaymentTypeManagerUi";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IJobOrderDetailsObserver } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderDetailsObserver";
import {
    IJobOrder,
    IJobOrderProject,
    IJobOrderCallRightTypesPrice,
    IProtocolSettingsForJobOrder,
    IJobOrderWithMetadataId,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ICustomer, IOrganizationalUnit } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrderRolesPrices } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../Core/Deferred";
import { MetadataType } from "../../../../Invoices/invoices/enums/MetadataType";
import { ResponsiblesPanelUI, _JobOrderResponsiblesPanel } from "../JobOrderResponsiblesPanel";
import { JobOrderResponsibleType } from "../../enums/JobOrderResponsibleType";
import { ResourcesAndGroupsDataSource } from "../../../../DataSources/ResourcesAndGroupsDataSource";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IResourcesGroupsSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { JobOrderResponsiblesApplicationModes } from "../dialogs/JobOrderResponsiblesApplicationModes";
import { JobOrderResponsiblesApplicationMode } from "../../enums/JobOrderResponsiblesApplicationMode";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ProtocolMailRecipientMapper } from "./ProtocolMailRecipientMapper";

export class JobOrderViewModel implements IJobOrderViewModel {
    public JobOrderId: ko.Observable<number> = ko.observable();
    public RolesPrices: ko.ObservableArray<JobOrderRolesPrices> = ko.observableArray([]);
    public MaterialsRolesPrices: ko.ObservableArray<JobOrderRolesPrices> = ko.observableArray([]);
    public EnabledRolesPrices: ko.Computed<JobOrderRolesPrices[]>;
    public EnabledMaterialsRolesPrices: ko.Computed<JobOrderRolesPrices[]>;
    public CallRightTypesPrices: ko.ObservableArray<JobOrderCallRightTypesPrice> = ko.observableArray([]);
    public RelatedProjects: ko.ObservableArray<IJobOrderProjectViewModel> = ko.observableArray([]);
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreationUserId: ko.Observable<number> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();
    public TypeId: ko.Observable<number> = ko.observable();
    public Name: ko.Observable<string> = ko.observable("");
    public Code: ko.Observable<string> = ko.observable();
    public Customer: ko.Observable<ICustomer> = ko.observable();
    public CustomerId: ko.Observable<string> = ko.observable();
    public OrganizationalUnitId: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public CommercialNotes: ko.Observable<string> = ko.observable();
    public TechnicalNotes: ko.Observable<string> = ko.observable();
    public ResponsableUserId: ko.Observable<number> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public RequestEndDate: ko.Observable<Date> = ko.observable();

    public EditCode: ko.Observable<boolean> = ko.observable();
    public CanEditCode: ko.Observable<boolean> = ko.observable();
    public CodePreview: ko.Observable<string> = ko.observable();

    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();

    public EstimatedWork: ko.Observable<number> = ko.observable();
    public EstimatedRevenue: ko.Observable<number> = ko.observable();

    @LazyImport(nameof<IEntitiesLockService>())
    private lockService: IEntitiesLockService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IWarehousesService>())
    private warehouseService: IWarehousesService;
    @LazyImport(nameof<ICustomersService>())
    public customersService: ICustomersService;
    @LazyImport(nameof<IProLifeSdkService>())
    private prolifeSdkService: IProLifeSdkService;

    private customersProvider: IEntityProvider<number, ICustomer>;

    public jobOrderStates: IJobOrderState[];
    public jobOrderTypes: IJobOrderType[];
    public selected: ko.Observable<boolean> = ko.observable(false);

    public customerSearchService: ICustomerControlsEntityProvider;
    public ResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();

    public viewModel = null;

    public isNew: ko.Computed<boolean>;

    public AreProjectsEnabled: ko.Computed<boolean>;

    public ProtocolsSettings: ko.ObservableArray<IProtocolDefaultValuesSettingsUi> = ko.observableArray([]);
    private SelectedProtocol: ko.Observable<IProtocolDefaultValuesSettingsUi> = ko.observable(null);

    private loadingJobOrder = false;

    private NotificationsManager: EntityNotificationsManager;
    private Warehouses: ko.ObservableArray<IWarehouseWithStockInfo> = ko.observableArray([]);

    private originalCustomerId: string;

    public isInEditMode: ko.Observable<boolean> = ko.observable(false);

    public customRoles: ko.Observable<boolean> = ko.observable(false);
    public customMaterialsRoles: ko.Observable<boolean> = ko.observable(false);

    // Per il default del codice IVA
    private IvaModes: IIvaMode[];
    public DefaultIva: ko.Observable<IIvaMode> = ko.observable(null);

    // Per i default di modalità e scadenza di pagamento
    public DefaultPaymentExpiryType: ko.Observable<IExpireMode> = ko.observable();
    public Expiries: ko.ObservableArray<IExpireMode> = ko.observableArray([]);
    public PaymentTypeManagerUi: IPaymentTypeManagerUi;

    public DefaultOffset: ko.Observable<number> = ko.observable();
    public OffsetsCodesDataSource: OffsetsCodesDataSource = new OffsetsCodesDataSource();

    public DefaultOutcome: ko.Observable<number> = ko.observable();
    public OutcomesDataSource: WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();

    public DefaultCurrency: ko.Observable<number> = ko.observable();
    public CurrenciesDataSource: CurrenciesDataSource;

    public HasDefaultValues: ko.Computed<boolean>;

    // Impostazioni avanzamento attività
    public EnableActivitiesProgressModeSetting: ko.Observable<boolean> = ko.observable(false);
    public ActivitiesProgressAmountMode: ko.Observable<number> = ko.observable();

    //Metadati commessa
    public MetadataId: ko.Observable<number> = ko.observable();
    public MetadataLabel: ko.Observable<string> = ko.observable();

    public JobOrderMetadataDataSource: JobOrderMetadataDataSource = new JobOrderMetadataDataSource();

    public FkCommercialResponsible: ko.Observable<string | number> = ko.observable();
    public CommercialResponsibleName: ko.Observable<string> = ko.observable();

    public FkAdministrativeResponsible: ko.Observable<string | number> = ko.observable();
    public AdministrativeResponsibleName: ko.Observable<string> = ko.observable();

    public AuthorizedResources: ko.ObservableArray<string> = ko.observableArray([]);
    public AuthorizedResourcesNames: ko.Computed<string[]>;

    public AdministrativeResponsibleSelectedFromResources: ko.Computed<boolean>;
    public AdministrativeResponsibleSelectionDescription: ko.Computed<string>;
    public CommercialResponsibleSelectedFromResources: ko.Computed<boolean>;
    public CommercialResponsibleSelectionDescription: ko.Computed<string>;

    public ResponsiblesPanelUI: ResponsiblesPanelUI;

    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expiriesManager: IExpireModes;
    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager: IJobOrderMetadataSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStateSettingsManager: IJobOrderStateSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesSettingsManager: IJobOrderTypesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager: IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesManager: ICallRightTypesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModesManager: IIvaModes;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private resourcesGroupsSettingsManager: IResourcesGroupsSettingsManager;
    @LazyImport(nameof<IUserInfo>())
    private userService: IUserInfo;

    private responsiblesPanel: _JobOrderResponsiblesPanel;

    constructor(
        private serviceLocator: IServiceLocator,
        private jobOrderPanel: IJobOrderDetailsObserver,
        public jobOrder: IJobOrder
    ) {
        this.viewModel = this;

        this.ResponsiblesPanelUI = new ResponsiblesPanelUI({
            responsableUserId: this.ResponsableUserId,
            administrativeResponsibleId: this.FkAdministrativeResponsible,
            commercialResponsibleId: this.FkCommercialResponsible,
            authorizedResources: this.AuthorizedResources,

            administrativeResponsibleName: this.AdministrativeResponsibleName,
            commercialResponsibleName: this.CommercialResponsibleName,

            forwardRef: (respPanel) => (this.responsiblesPanel = respPanel),
        });

        this.AuthorizedResourcesNames = ko.computed(() => {
            const authResources = this.AuthorizedResources();

            const names = [];
            for (const resource of authResources) {
                const [resourceId, resourceType] = resource.split("|");
                names.push(this.getAuthorizedResourceName(parseInt(resourceId), resourceType));
            }

            return names;
        });

        this.AdministrativeResponsibleSelectedFromResources = ko.computed(() => {
            return typeof this.FkAdministrativeResponsible() === "number";
        });

        this.AdministrativeResponsibleSelectionDescription = ko.computed(() => {
            return this.AdministrativeResponsibleSelectedFromResources()
                ? TextResources.JobOrder.ResponsibleProviderSelectedResourceTitle
                : TextResources.JobOrder.ResponsibleProviderPlainTextResourceTitle;
        });

        this.CommercialResponsibleSelectedFromResources = ko.computed(() => {
            return typeof this.FkCommercialResponsible() === "number";
        });

        this.CommercialResponsibleSelectionDescription = ko.computed(() => {
            return this.CommercialResponsibleSelectedFromResources()
                ? TextResources.JobOrder.ResponsibleProviderSelectedResourceTitle
                : TextResources.JobOrder.ResponsibleProviderPlainTextResourceTitle;
        });

        this.CurrenciesDataSource = new CurrenciesDataSource();
        this.CurrenciesDataSource.returnDeletedCurrencies(false);

        this.jobOrderStates = this.jobOrderStateSettingsManager.getJobOrderStates();

        this.JobOrderMetadataDataSource.setListMode(true);

        this.TypeId.subscribe((value) => {
            this.JobOrderMetadataDataSource.setTypeFilter(value);
        });

        const authorizationService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
        this.CanEditCode(authorizationService.isAuthorized("JobOrders_EditJobOrderCode"));

        // Per il default del codice IVA
        this.IvaModes = this.ivaModesManager.getIvaModes();

        // Per i default di modalità e scadenza pagamento
        this.PaymentTypeManagerUi = <IPaymentTypeManagerUi>this.prolifeSdkService.Ui.GetPaymentTypeManagerUi();
        const expiries: IExpireMode[] = this.expiriesManager.getExpireModes(false);
        this.Expiries(expiries);
        const expiryMatches = expiries.filter(
            (e: IExpireMode) => e.IdTipoScadenza == jobOrder.DefaultPaymentExpiryTypeId
        );
        this.DefaultPaymentExpiryType(expiryMatches.length == 0 ? null : expiryMatches[0]);
        this.PaymentTypeManagerUi.PaymentId(jobOrder.DefaultPaymentTypeId);
        this.PaymentTypeManagerUi.PaymentAccountId(jobOrder.DefaultPaymentAccountId);
        this.PaymentTypeManagerUi.PaymentABI(jobOrder.DefaultPaymentABI);
        this.PaymentTypeManagerUi.PaymentCAB(jobOrder.DefaultPaymentCAB);

        this.jobOrderTypes = this.jobOrderTypesSettingsManager.getJobOrderTypes();

        this.ResourcesDataSource.setGetHumanResources(true);
        this.ResourcesDataSource.setGetMaterialResources(false);
        this.ResourcesDataSource.setIncludeDeletedResources(false);
        this.ResourcesDataSource.setIncludeDisabledResources(false);

        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.customersProvider = entityProviderService.getEntityProvider(ProlifeSdk.CustomerEntityType);
        this.customerSearchService = <ICustomerControlsEntityProvider>this.customersProvider.getControlsProvider();
        this.customerSearchService.IsCustomer = true;

        //Carico le gui per le configurazioni dei protocolli
        this.documentsService.getRegisteredDocumentProviders().forEach((p: IDocumentsProvider) => {
            if (!p.HasDefaultValuesSettingsUi || !p.CanBeAssociatedToJobOrder) return;

            this.ProtocolsSettings.push(p.GetDefaultValuesSettingsUi());
        });
        this.SelectedProtocol(this.ProtocolsSettings().length > 0 ? this.ProtocolsSettings()[0] : null);

        this.CustomerId.subscribe(() => {
            this.LoadCustomer(false, parseInt(this.OrganizationalUnitId()));
        });

        this.Customer.subscribe((c: ICustomer) => {
            if (!c) {
                this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions([]);
                this.setOrganizationalUnitsOnProtocolsDefaultsProviders();
                return;
            }

            this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(c.OrganizationalUnits);
            this.setOrganizationalUnitsOnProtocolsDefaultsProviders();
        });

        this.OrganizationalUnitId.subscribe((ouId: string) => {
            this.SetDefaultResponsiblesForCurrentSelection();
            if (!ouId) {
                this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                    !this.Customer()
                        ? []
                        : this.Customer().OrganizationalUnits.filter((o: IOrganizationalUnit) => o.IsDefault)
                );
                this.setOrganizationalUnitsOnProtocolsDefaultsProviders();
                return;
            }
            //this.PaymentTypeManagerUi.CustomerOrganizationalUnits(!this.Customer() ? [] : this.Customer().OrganizationalUnits.filter((ou: IOrganizationalUnit) => ou.Id == parseInt(ouId)));
            this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                !this.Customer()
                    ? []
                    : this.Customer().OrganizationalUnits.filter((o: IOrganizationalUnit) => o.IsDefault)
            );
            this.setOrganizationalUnitsOnProtocolsDefaultsProviders();
        });

        this.EnabledRolesPrices = ko.computed(() => {
            return this.RolesPrices().filter((r: JobOrderRolesPrices) => {
                return r.enabled();
            });
        });

        this.EnabledMaterialsRolesPrices = ko.computed(() => {
            return this.MaterialsRolesPrices().filter((r: JobOrderRolesPrices) => {
                return r.enabled();
            });
        });

        this.TypeId.subscribe(() => {
            const type = this.jobOrderTypesSettingsManager.getJobOrderTypeById(this.TypeId());
            if (type) {
                this.Icon(type.Icon);
                this.Background(type.Background);
                this.Foreground(type.Foreground);

                if (type.DefaultResponsiblesAndAuthorizedResources?.length > 0 && this.isInEditMode())
                    this.startResponsiblesApplicationProcess(type.DefaultResponsiblesAndAuthorizedResources);
            }
        });

        this.load(jobOrder);

        this.EnableActivitiesProgressModeSetting.subscribe((value: boolean) => {
            if (!value) this.ActivitiesProgressAmountMode(null);

            if (value && this.ActivitiesProgressAmountMode() == null) this.ActivitiesProgressAmountMode(0);
        });

        this.isNew = ko.computed(() => {
            return !this.JobOrderId() || this.JobOrderId() == -1;
        });

        this.AreProjectsEnabled = ko.computed(() => {
            return false;
        });

        this.RelatedProjects([]);

        this.NotificationsManager = new EntityNotificationsManager(
            serviceLocator,
            ProlifeSdk.JobOrderEntityTypeCode,
            jobOrder.JobOrderId
        )
            .OnUpdate(
                (() => {
                    this.jobOrderPanel.notifyChanges(jobOrder.JobOrderId);
                }).bind(this)
            )
            .OnDelete(
                (() => {
                    this.jobOrderPanel.notifyChanges(-1);
                }).bind(this)
            );

        this.SetEditMode(false);

        this.HasDefaultValues = ko.computed(() => {
            return (
                !!this.DefaultIva() ||
                !!this.DefaultPaymentExpiryType() ||
                !!this.PaymentTypeManagerUi.Payment() ||
                !!this.DefaultOffset() ||
                !!this.DefaultOutcome() ||
                !!this.DefaultCurrency()
            );
        });
    }

    private async startResponsiblesApplicationProcess(
        defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]
    ): Promise<void> {
        const dialog = new JobOrderResponsiblesApplicationModes({});
        const mode = await dialog.show();

        if (mode === JobOrderResponsiblesApplicationMode.NotApply) return;

        if (mode === JobOrderResponsiblesApplicationMode.Merge) {
            this.mergeResponsibles(defaultResponsiblesAndAuthorizedResources);
            return;
        }

        if (mode === JobOrderResponsiblesApplicationMode.Apply) {
            this.applyResponsibles(defaultResponsiblesAndAuthorizedResources);
            return;
        }
    }

    private applyResponsibles(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        this.applyResponsible(defaultResponsiblesAndAuthorizedResources);
        this.applyCommercialResponsible(defaultResponsiblesAndAuthorizedResources);
        this.applyAdministrativeResponsible(defaultResponsiblesAndAuthorizedResources);
        this.applyAuthorizedResources(defaultResponsiblesAndAuthorizedResources);
    }

    private applyAuthorizedResources(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        const resources = defaultResponsiblesAndAuthorizedResources
            .filter((r) => r.ResponsibleType === JobOrderResponsibleType.AuthorizedResource)
            .map((r) => r.ResourceOrResourcesGroupId + "|" + r.ResourceType);

        this.AuthorizedResources([]);
        this.AuthorizedResources(resources);
    }

    private applyAdministrativeResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        const resp = defaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.AdministrativeResponsible
        );
        this.FkAdministrativeResponsible(resp?.ResourceOrResourcesGroupId ?? resp?.ResourceOrResourcesGroupName);
        this.responsiblesPanel.loadAdministrativeResponsibleName();
    }

    private applyCommercialResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        const resp = defaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.CommercialResponsible
        );
        this.FkCommercialResponsible(resp?.ResourceOrResourcesGroupId ?? resp?.ResourceOrResourcesGroupName);
        this.responsiblesPanel.loadCommercialResponsibleName();
    }

    private applyResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        const resp = defaultResponsiblesAndAuthorizedResources.firstOrDefault(
            (r) => r.ResponsibleType === JobOrderResponsibleType.Responsible
        );
        this.ResponsableUserId(resp?.ResourceOrResourcesGroupId);
    }

    private mergeResponsibles(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        this.mergeResponsible(defaultResponsiblesAndAuthorizedResources);
        this.mergeCommercialResponsible(defaultResponsiblesAndAuthorizedResources);
        this.mergeAdministrativeResponsible(defaultResponsiblesAndAuthorizedResources);
        this.mergeAuthorizedResources(defaultResponsiblesAndAuthorizedResources);
    }

    private mergeAuthorizedResources(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        const actualAuthorizedResources = this.AuthorizedResources();

        const resources = defaultResponsiblesAndAuthorizedResources
            .filter(
                (r) =>
                    r.ResponsibleType === JobOrderResponsibleType.AuthorizedResource &&
                    actualAuthorizedResources.indexOf(r.ResourceOrResourcesGroupId + "|" + r.ResourceType) < 0
            )
            .map((r) => r.ResourceOrResourcesGroupId + "|" + r.ResourceType);

        this.AuthorizedResources([]);
        this.AuthorizedResources(actualAuthorizedResources.concat(resources));
    }

    private mergeAdministrativeResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        if (this.FkAdministrativeResponsible()) return;

        this.applyAdministrativeResponsible(defaultResponsiblesAndAuthorizedResources);
    }

    private mergeCommercialResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        if (this.FkCommercialResponsible()) return;

        this.applyCommercialResponsible(defaultResponsiblesAndAuthorizedResources);
    }

    private mergeResponsible(defaultResponsiblesAndAuthorizedResources: IJobOrderTypeResponsible[]) {
        if (this.ResponsableUserId()) return;

        this.applyResponsible(defaultResponsiblesAndAuthorizedResources);
    }

    private getAuthorizedResourceName(resourceId: number, resourceType: string): string {
        if (resourceType === ResourcesAndGroupsDataSource.HumanResourcesType) {
            const resource = this.humanResourcesSettingsManager.getHumanResourceById(resourceId);
            return this.humanResourcesSettingsManager.getFullName(resource);
        } else if (resourceType === ResourcesAndGroupsDataSource.ResourcesGroupsType) {
            const group = this.resourcesGroupsSettingsManager.GetGroupById(resourceId);
            return group?.Name ?? "";
        } else {
            return "";
        }
    }

    public formatCustomerItem(customerItem) {
        return customerItem.locked ? "<i class='fa fa-lock'></i>  " + customerItem.text : customerItem.text;
    }

    public SetEditMode(edit: boolean) {
        this.isInEditMode(edit);

        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            p.SetEditMode(edit);
        });
    }

    public SetActivitiesProgressAmountMode(value: number) {
        this.ActivitiesProgressAmountMode(value);
    }

    public SwitchActivitiesProgressSettingMode() {
        this.EnableActivitiesProgressModeSetting(!this.EnableActivitiesProgressModeSetting());
    }

    public Dispose() {
        this.NotificationsManager.Dispose();
    }

    private setOrganizationalUnitsOnProtocolsDefaultsProviders(): void {
        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            if (p.PaymentTypeManagerUi) {
                if (!this.Customer()) {
                    p.PaymentTypeManagerUi.refreshCustomerPaymentsOptions([]);
                    return;
                }

                if (this.Customer() && !this.OrganizationalUnitId()) {
                    p.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                        this.Customer().OrganizationalUnits.filter((o: IOrganizationalUnit) => o.IsDefault)
                    );
                    return;
                }

                /*if (this.Customer() && !!this.OrganizationalUnitId()) {
                    (<any>p).PaymentTypeManagerUi.CustomerOrganizationalUnits(this.Customer().OrganizationalUnits.filter((ou: IOrganizationalUnit) => ou.Id == parseInt(this.OrganizationalUnitId())));
                }*/
            }
        });
    }

    private SetDefaultResponsiblesForCurrentSelection() {
        if (this.loadingJobOrder) return;

        const customer: ICustomer = this.Customer() || ({ OrganizationalUnits: [] } as ICustomer);
        const uos: IOrganizationalUnit[] = customer.OrganizationalUnits.filter((o: IOrganizationalUnit) => {
            return o.Id == parseInt(this.OrganizationalUnitId());
        });

        const administrativeResponsibleId: number =
            uos.length > 0 && uos[0].FkAdministrativeResponsible
                ? uos[0].FkAdministrativeResponsible
                : customer.FkAdministrativeResponsible
                ? customer.FkAdministrativeResponsible
                : this.jobOrder.FkAdministrativeResponsible;
        const administrativeResponsibleName: string =
            uos.length > 0 && uos[0].AdministrativeResponsibleName
                ? uos[0].AdministrativeResponsibleName
                : customer.AdministrativeResponsibleName
                ? customer.AdministrativeResponsibleName
                : this.jobOrder.AdministrativeResponsibleName;
        const commercialResponsibleId: number =
            uos.length > 0 && uos[0].FkCommercialResponsible
                ? uos[0].FkCommercialResponsible
                : customer.FkCommercialResponsible
                ? customer.FkCommercialResponsible
                : this.jobOrder.FkCommercialResponsible;
        const commercialResponsibleName: string =
            uos.length > 0 && uos[0].CommercialResponsibleName
                ? uos[0].CommercialResponsibleName
                : customer.CommercialResponsibleName
                ? customer.CommercialResponsibleName
                : this.jobOrder.CommercialResponsibleName;

        this.loadCommercialResponsible(commercialResponsibleId, commercialResponsibleName);
        this.loadAdministrativeResponsible(administrativeResponsibleId, administrativeResponsibleName);
    }

    private loadCommercialResponsible(commercialResponsibleId: number, commercialResponsibleName: string): void {
        if (!!commercialResponsibleId && commercialResponsibleId > 0) {
            this.FkCommercialResponsible(commercialResponsibleId);
        } else if (commercialResponsibleName) {
            this.FkCommercialResponsible(commercialResponsibleName);
        }

        this.CommercialResponsibleName(commercialResponsibleName);
    }

    private loadAdministrativeResponsible(
        administrativeResponsibleId: number,
        administrativeResponsibleName: string
    ): void {
        if (!!administrativeResponsibleId && administrativeResponsibleId > 0) {
            this.FkAdministrativeResponsible(administrativeResponsibleId);
        } else if (administrativeResponsibleName) {
            this.FkAdministrativeResponsible(administrativeResponsibleName);
        }

        this.AdministrativeResponsibleName(administrativeResponsibleName);
    }

    private LoadCustomer(initializing, uoId): Promise<ICustomer> {
        const d = new Deferred<ICustomer>();

        if (this.loadingJobOrder && !initializing) return d.resolve().promise();

        if (!this.CustomerId() || this.CustomerId() == "0" || this.CustomerId() == "") {
            this.Customer(null);
            this.OrganizationalUnitId(null);
            d.resolve(null);
        } else
            this.customersProvider.getEntity(parseInt(this.CustomerId())).then((c: ICustomer) => {
                this.Customer(c);
                const matches = c.OrganizationalUnits.filter((uo: IOrganizationalUnit) => {
                    return uo.Id == uoId;
                });
                this.OrganizationalUnitId(uoId && matches.length > 0 ? uoId.toString() : null);
                this.SetDefaultResponsiblesForCurrentSelection();
                d.resolve(c);
            });

        return d.promise();
    }

    FindOrganizationalUnit(element, callback) {
        const id = parseInt($(element).val() as string);
        if (!isNaN(id) && id > 0 && this.Customer()) {
            const matches = this.Customer().OrganizationalUnits.filter((uo: IOrganizationalUnit) => {
                return uo.Id == id;
            });
            callback(
                matches.length > 0
                    ? {
                          id: matches[0].Id,
                          text: matches[0].Description || ProlifeSdk.TextResources.JobOrder.Unnamed,
                      }
                    : null
            );
        }
    }

    FindOrganizationalUnits(query: ISelect2Query) {
        query.callback({
            results: (this.Customer() && this.Customer().OrganizationalUnits ? this.Customer().OrganizationalUnits : [])
                .filter((uo: IOrganizationalUnit) => {
                    return (
                        !query.term ||
                        query.term.trim().length == 0 ||
                        (uo.Description && uo.Description.toUpperCase().indexOf(query.term.toUpperCase()) > -1)
                    );
                })
                .map((uo: IOrganizationalUnit) => {
                    return {
                        id: uo.Id,
                        text: uo.Description || ProlifeSdk.TextResources.JobOrder.Unnamed,
                    };
                }),
        });
    }

    AddNewProject() {
        this.RelatedProjects.push(new JobOrderProject(this.serviceLocator, { Id: 0 }, this.RelatedProjects));
    }

    createNewCustomer() {
        this.customersService.ui.showNewCustomerDialog();
    }

    load(jobOrder: IJobOrder): void {
        this.loadingJobOrder = true;
        jobOrder.CallRightTypesPrices = jobOrder.CallRightTypesPrices ? jobOrder.CallRightTypesPrices : [];
        this.JobOrderId(jobOrder.JobOrderId);
        this.CreationDate(jobOrder.CreationDate ? moment(jobOrder.CreationDate).toDate() : new Date());
        this.CreationUserId(jobOrder.CreationUserId);
        this.StateId(jobOrder.StateId);
        this.TypeId(jobOrder.TypeId);
        this.CallRightTypesPrices(
            jobOrder.CallRightTypesPrices.map((costo: IJobOrderCallRightTypesPrice) => {
                return new JobOrderCallRightTypesPrice(this.serviceLocator, costo);
            })
        );
        this.Name(jobOrder.Name);
        this.Code(jobOrder.Code);
        this.CustomerId((jobOrder.CustomerId || 0).toString());
        this.originalCustomerId = jobOrder.CustomerId?.toString() || "";
        this.customRoles(jobOrder.CustomRoles);
        this.customMaterialsRoles(jobOrder.CustomMaterialsRoles);

        const ivaModes: IIvaMode[] = this.ivaModesManager
            .getIvaModes(false)
            .filter((i: IIvaMode) => i.IdTipoIVA == jobOrder.DefaultIvaMode);
        this.DefaultIva(ivaModes.length > 0 ? ivaModes[0] : null);

        this.Description(jobOrder.Description);
        this.CommercialNotes(jobOrder.CommercialNotes);
        this.TechnicalNotes(jobOrder.TechnicalNotes);
        this.ResponsableUserId(jobOrder.ResponsableUserId !== 0 ? jobOrder.ResponsableUserId : null);
        this.StartDate(jobOrder.StartDate ? moment(jobOrder.StartDate).toDate() : undefined);
        this.EndDate(jobOrder.EndDate ? moment(jobOrder.EndDate).toDate() : undefined);
        this.RequestEndDate(jobOrder.RequestEndDate ? moment(jobOrder.RequestEndDate).toDate() : undefined);

        this.DefaultOffset(jobOrder.DefaultOffset);
        this.DefaultOutcome(jobOrder.DefaultOutcome);
        this.DefaultCurrency(jobOrder.DefaultCurrency);

        this.ActivitiesProgressAmountMode(jobOrder.ActivitiesProgressAmountMode);
        this.EnableActivitiesProgressModeSetting(jobOrder.ActivitiesProgressAmountMode != null);

        this.EstimatedWork(jobOrder.EstimatedWork);
        this.EstimatedRevenue(jobOrder.EstimatedRevenue);

        if (this.JobOrderId() > 0) {
            this.jobOrderService.GetJobOrderMetadataId({ JobOrderId: this.JobOrderId() }).then((metadataId: number) => {
                if (!metadataId || metadataId <= 0) return;

                this.MetadataId(metadataId);

                const metadata = this.metadataSettingsManager.getByIds([metadataId]);

                this.MetadataLabel(metadata.length === 0 ? "" : metadata[0].Name + " (" + metadata[0].Code + ")");
            });
        }

        this.loadCommercialResponsible(jobOrder.FkCommercialResponsible, jobOrder.CommercialResponsibleName);
        this.loadAdministrativeResponsible(
            jobOrder.FkAdministrativeResponsible,
            jobOrder.AdministrativeResponsibleName
        );

        const authorizedResourcesIds = [];
        for (const authResource of jobOrder.ResponsiblesAndAuthorizedResources.filter(
            (r) => r.ResponsibleType === JobOrderResponsibleType.AuthorizedResource
        )) {
            const id = authResource.ResourceOrResourcesGroupId + "|" + authResource.ResourceType;
            authorizedResourcesIds.push(id);
        }

        this.AuthorizedResources(authorizedResourcesIds);

        let userCharacters: IUserCharacter[] = this.userCharactersSettingsManager.getUserCharacters(0);

        this.RolesPrices(
            jobOrder.RolesPrices.filter((c: IJobOrderRolesPrices) => {
                return (
                    userCharacters.filter((co: IUserCharacter) => c.FkUserCharacterId == co.IdUserCharacter).length > 0
                );
            }).map((costo: IJobOrderRolesPrices) => {
                return new JobOrderRolesPrices(this.serviceLocator, costo, true);
            })
        );

        userCharacters.forEach((userCharacter: IUserCharacter) => {
            const filters: IJobOrderRolesPrices[] = this.jobOrder.RolesPrices.filter(
                (costo: IJobOrderRolesPrices) => costo.FkUserCharacterId == userCharacter.IdUserCharacter
            );
            if (filters.length == 0)
                this.RolesPrices.push(
                    new JobOrderRolesPrices(
                        this.serviceLocator,
                        {
                            Description: userCharacter.Description,
                            Salary: userCharacter.Salary,
                            FkUserCharacterId: userCharacter.IdUserCharacter,
                        },
                        false
                    )
                );
        });

        userCharacters = this.userCharactersSettingsManager.getUserCharacters(1);
        this.MaterialsRolesPrices(
            jobOrder.RolesPrices.filter((c: IJobOrderRolesPrices) => {
                return (
                    userCharacters.filter((co: IUserCharacter) => c.FkUserCharacterId == co.IdUserCharacter).length > 0
                );
            }).map((costo: IJobOrderRolesPrices) => {
                return new JobOrderRolesPrices(this.serviceLocator, costo, true);
            })
        );

        userCharacters.forEach((userCharacter: IUserCharacter) => {
            const filters: IJobOrderRolesPrices[] = this.jobOrder.RolesPrices.filter(
                (costo: IJobOrderRolesPrices) => costo.FkUserCharacterId == userCharacter.IdUserCharacter
            );
            if (filters.length == 0)
                this.MaterialsRolesPrices.push(
                    new JobOrderRolesPrices(
                        this.serviceLocator,
                        {
                            Description: userCharacter.Description,
                            Salary: userCharacter.Salary,
                            FkUserCharacterId: userCharacter.IdUserCharacter,
                        },
                        false
                    )
                );
        });

        //Aggiungo le tipologie di D.D.C. non ancora mappate
        const mappedCallRightTypesIds: number[] = jobOrder.CallRightTypesPrices.map(
            (rp: IJobOrderCallRightTypesPrice) => {
                return rp.CallRightId;
            }
        );

        const notMappedCallRightTypes: ICallRightType[] = this.callRightTypesManager
            .getAll(false)
            .filter((t: ICallRightType) => {
                return mappedCallRightTypesIds.indexOf(t.Id) == -1;
            });

        notMappedCallRightTypes
            .map((t: ICallRightType) => {
                return new JobOrderCallRightTypesPrice(this.serviceLocator, {
                    JobOrderId: jobOrder.JobOrderId,
                    CallRightId: t.Id,
                    Price: t.Price,
                });
            })
            .forEach((item) => {
                this.CallRightTypesPrices.push(item);
            });

        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            const matches = jobOrder.ProtocolsSettings.filter((ps: IProtocolSettingsForJobOrder) => {
                return ps.ProtocolId == p.ProtocolId;
            });

            const metadatas = jobOrder.ProtocolsDefaultMetadatas.filter((m) => m.ProtocolId === p.ProtocolId).map(
                (m) => ({
                    Id: m.Id,
                    FKProtocol: null,
                    FKMetadata: m.FkMetadata,
                    MetadataValueType: m.MetadataValueType as MetadataType,
                    ShowMarkerOnAllocationsGantt: m.ShowMarkerOnAllocationsGantt,
                    Order: null,
                })
            );

            p.SetStaticMetadatasTable(true);
            p.SetEnableMetadatasSorting(false);
            p.ShowAbiAndCabFields(true);
            p.LoadSettings(jobOrder.ProtocolsDefaultValues, matches.length > 0 && matches[0].DefaultValuesEnabled);
            p.LoadMetadata(metadatas, matches.length > 0 && matches[0].DefaultMetadatasEnabled);

            const protocolMailTo = jobOrder.MailRecipientsForDocumentsSendTo.filter(
                (m) => m.ProtocolId === p.vatRegisterId
            );
            const protocolMailCc = jobOrder.MailRecipientsForDocumentsSendCc.filter(
                (m) => m.ProtocolId === p.vatRegisterId
            );
            const protocolMailBcc = jobOrder.MailRecipientsForDocumentsSendBcc.filter(
                (m) => m.ProtocolId === p.vatRegisterId
            );

            p.loadMailRecipientTypes(
                ProtocolMailRecipientMapper.mapToProtocolMailRecipient(protocolMailTo),
                ProtocolMailRecipientMapper.mapToProtocolMailRecipient(protocolMailCc),
                ProtocolMailRecipientMapper.mapToProtocolMailRecipient(protocolMailBcc)
            );
        });

        this.SelectedProtocol(this.ProtocolsSettings().length > 0 ? this.ProtocolsSettings()[0] : null);

        this.LoadCustomer(true, jobOrder.OrganizationalUnitId).then(() => {
            this.loadingJobOrder = false;

            this.setOrganizationalUnitsOnPaymentManager();
        });

        if (jobOrder.JobOrderId)
            this.warehouseService.GetJobOrderWarehouses(jobOrder.JobOrderId).then((w: IWarehouseWithStockInfo[]) => {
                this.Warehouses(w);
            });
    }

    reset(): void {
        this.load(this.jobOrder);
    }

    editState(stateId: number) {
        this.StateId(stateId);
    }

    editType(typeId: number) {
        this.TypeId(typeId);
    }

    private SelectProtocol(p: IProtocolDefaultValuesSettingsUi) {
        this.SelectedProtocol(p);
    }

    public OpenWarehouseInventory(w: IWarehouse) {
        const url: string = this.warehouseService.GetWarehouseStockUrl(w.Id);
        window.open(url, "_blank");
    }

    async save(): Promise<void> {
        if (!this.Name()) {
            this.infoToast.Warning(ProlifeSdk.TextResources.JobOrder.OrderWithoutName);
            return;
        }

        if (this.Name().indexOf("/") >= 0) {
            this.infoToast.Warning(ProlifeSdk.TextResources.JobOrder.InvalidCharacterInJobOrderName);
            return;
        }

        if (!(this.TypeId() > 0)) {
            this.infoToast.Warning(ProlifeSdk.TextResources.JobOrder.NoJobOrderTypeSelected);
            return;
        }

        if (!(this.StateId() > 0)) {
            this.infoToast.Warning(ProlifeSdk.TextResources.JobOrder.NoJobOrderStateSelected);
            return;
        }

        if (!this.MetadataId()) {
            if (this.metadataSettingsManager.hasMetadataForJobOrderType(this.TypeId())) {
                this.infoToast.Warning("Selezionare un metadato progetto prima di continuare");
                return;
            }
        }

        if (!this.PaymentTypeManagerUi.getValidator().validateAndShowInfoToast(this.PaymentTypeManagerUi)) {
            return;
        }

        const messages: string[] = [];

        this.ProtocolsSettings().forEach((p) => {
            if (p.PaymentTypeManagerUi) {
                const errors = p.PaymentTypeManagerUi.getValidator().validate(p.PaymentTypeManagerUi);
                if (errors.length > 0) {
                    const message: string =
                        ProlifeSdk.TextResources.JobOrder.JobOrderDefaultValuesError +
                        " (" +
                        p.ProtocolName +
                        ")" +
                        ":<br/>" +
                        "<br/>" +
                        errors.map((e) => e.message).join("<br/>");
                    messages.push(message);
                }
            }

            const metadatasError = p.validateMetadata();
            if (metadatasError)
                messages.push(String.format(TextResources.ProlifeSdk.MetadataError, p.ProtocolName, metadatasError));
        });

        if (messages.length > 0) {
            let message = "";
            messages.forEach((m: string) => (message += m + "<br/><br/>"));
            this.infoToast.Warning(message);
            return;
        }

        const logicalState: number = this.jobOrderStates.filter((s: IJobOrderState) => {
            return s.IdJobOrderStateId == this.StateId();
        })[0].LogicalState;

        let confirm = true;

        if (logicalState == 1)
            confirm = await this.jobOrderService.CheckOpenedTasksWorkedHoursAndDocuments(this.JobOrderId());

        if (!confirm) return;

        //Controllo la presenza del cliente solo sulle commmesse e non sulle opportunità
        if (isNaN(parseInt(this.CustomerId())) || parseInt(this.CustomerId()) <= 0)
            confirm = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.JobOrder.ConfirmNoCustomer,
                ProlifeSdk.TextResources.JobOrder.ConfirmNoCustomerCancel,
                ProlifeSdk.TextResources.JobOrder.ConfirmNoCustomerConfirm
            );

        if (!confirm) return;

        this.PreSaveAfterValidation();
    }

    private PreSaveAfterValidation() {
        if (this.Warehouses().length > 0 && parseInt(this.originalCustomerId) != parseInt(this.CustomerId())) {
            this.dialogService.Confirm(
                ProlifeSdk.TextResources.JobOrder.ChangeCustomerMsg,
                ProlifeSdk.TextResources.JobOrder.ChangeCustomerMsgCancel,
                ProlifeSdk.TextResources.JobOrder.ChangeCustomerMsgConfirm,
                (confirm: boolean) => {
                    if (!confirm) return;
                    this.SaveAfterValidation();
                }
            );
        } else this.SaveAfterValidation();
    }

    private SaveAfterValidation() {
        this.CustomerId(
            this.TypeId() == 0 && (isNaN(parseInt(this.CustomerId())) || parseInt(this.CustomerId()) <= 0)
                ? null
                : this.CustomerId()
        );
        this.OrganizationalUnitId(
            this.TypeId() == 0 &&
                (isNaN(parseInt(this.OrganizationalUnitId())) || parseInt(this.OrganizationalUnitId()) <= 0)
                ? null
                : this.OrganizationalUnitId()
        );

        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.JobOrder.Saving);
        const saveDef: Promise<IJobOrder> = this.JobOrderId()
            ? this.jobOrderService.update(this.getData())
            : this.jobOrderService.insert(this.getData());
        saveDef
            .then(this.onSaved.bind(this))
            .catch(this.onFailed.bind(this))
            .finally(() => {
                this.desktopService.UnblockPageUI();
            });
    }

    private setOrganizationalUnitsOnPaymentManager(): void {
        if (!this.Customer()) {
            this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions([]);
            return;
        }

        let organizationalUnits: IOrganizationalUnit[] = this.Customer().OrganizationalUnits;

        if (this.OrganizationalUnitId()) {
            const ouId = parseInt(this.OrganizationalUnitId());
            if (ouId > 0) organizationalUnits = organizationalUnits.filter((ou: IOrganizationalUnit) => ou.Id == ouId);
        }

        this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(organizationalUnits);
        for (const p of this.ProtocolsSettings())
            p.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(organizationalUnits);
    }

    async DeleteJobOrder(): Promise<void> {
        let confirm = await this.dialogService.ConfirmAsync(
            ProlifeSdk.TextResources.JobOrder.OrderDeleteMsg,
            ProlifeSdk.TextResources.JobOrder.OrderDeleteMsgCancel,
            ProlifeSdk.TextResources.JobOrder.OrderDeleteMsgConfirm
        );

        if (confirm) confirm = await this.jobOrderService.CheckOpenedTasksWorkedHoursAndDocuments(this.JobOrderId());

        if (confirm) {
            try {
                await this.jobOrderService.delete(this.getData());
                this.onDeleted();
            } catch (e) {
                this.onFailed(e);
            }
        }
    }

    UndeleteJobOrder() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.JobOrder.OrderUndeleteMsg,
            ProlifeSdk.TextResources.JobOrder.OrderUndeleteMsgCancel,
            ProlifeSdk.TextResources.JobOrder.OrderUndeleteMsgConfirm,
            (result: boolean) => {
                if (result)
                    this.jobOrderService
                        .undelete(this.getData())
                        .then(this.onUndeleted.bind(this))
                        .catch(this.onFailed.bind(this));
            }
        );
    }

    onFailed(_: IException): void {}

    onSaved(jobOrder: IJobOrder) {
        this.lockService.UnlockEntity(ProlifeSdk.JobOrderEntityTypeCode, jobOrder.JobOrderId);
        this.infoToast.Success(ProlifeSdk.TextResources.JobOrder.OrderSaved);
        this.jobOrderPanel.notifyChanges(jobOrder.JobOrderId);
    }

    onDeleted() {
        this.infoToast.Success(ProlifeSdk.TextResources.JobOrder.OrderDeleted);
        this.jobOrderPanel.notifyChanges(-1);
    }

    onUndeleted(jobOrder: IJobOrder) {
        this.lockService.UnlockEntity(ProlifeSdk.JobOrderEntityTypeCode, jobOrder.JobOrderId);
        this.infoToast.Success(ProlifeSdk.TextResources.JobOrder.OrderUndeleted);
        this.jobOrderPanel.notifyChanges(jobOrder.JobOrderId);
    }

    someIsChanged(): boolean {
        return !(JSON.stringify(this.getData()) === JSON.stringify(this.jobOrder));
    }

    AddNewWarehouse() {
        this.warehouseService.ui.showCreateWarehouseDialog(parseInt(this.CustomerId()), this.JobOrderId()).then(() => {
            this.jobOrderPanel.notifyChanges(this.JobOrderId());
        });
    }

    EditWarehouse(warehouseId: number) {
        this.warehouseService.getWarehouseById(warehouseId).then((w: IWarehouse) => {
            this.warehouseService.ui.showEditWarehouseDialog(w).then(() => {
                this.jobOrderPanel.notifyChanges(this.JobOrderId());
            });
        });
    }

    getData(): IJobOrderWithMetadataId {
        const job: IJobOrderWithMetadataId = Object.assign({}, this.jobOrder) as IJobOrderWithMetadataId;

        const responsible: IHumanResource = this.humanResourcesSettingsManager.getHumanResourceByUserId(
            this.userService.getIdUser()
        );
        const responsibleId = responsible != null ? responsible.Resource.Id : 0;

        job.StateId = this.StateId();
        job.TypeId = this.TypeId();
        job.Name = this.Name();
        job.Code = this.Code();
        job.CustomerId = isNaN(parseInt(this.CustomerId())) ? null : parseInt(this.CustomerId());
        job.Description = this.Description();
        job.CommercialNotes = this.CommercialNotes();
        job.TechnicalNotes = this.TechnicalNotes();
        job.ResponsableUserId = this.ResponsableUserId() ?? responsibleId ?? -1;
        job.StartDate = this.StartDate();
        job.EndDate = this.EndDate();
        job.RequestEndDate = this.RequestEndDate();
        job.CustomRoles = this.customRoles();
        job.CustomMaterialsRoles = this.customMaterialsRoles();
        job.OrganizationalUnitId = isNaN(parseInt(this.OrganizationalUnitId()))
            ? null
            : parseInt(this.OrganizationalUnitId());

        job.RolesPrices = this.RolesPrices()
            .filter(() => {
                return this.customRoles();
            })
            .filter((costo: JobOrderRolesPrices) => {
                return costo.enabled();
            })
            .map((costo: JobOrderRolesPrices) => {
                return costo.getData();
            });

        job.RolesPrices = job.RolesPrices.concat(
            this.MaterialsRolesPrices()
                .filter(() => {
                    return this.customMaterialsRoles();
                })
                .filter((costo: JobOrderRolesPrices) => {
                    return costo.enabled();
                })
                .map((costo: JobOrderRolesPrices) => {
                    return costo.getData();
                })
        );

        job.CallRightTypesPrices = this.CallRightTypesPrices().map((costo: JobOrderCallRightTypesPrice) => {
            return costo.getData();
        });

        job.ProtocolsSettings = [];
        job.ProtocolsDefaultValues = [];
        job.ProtocolsDefaultMetadatas = [];

        job.FkAdministrativeResponsible = !this.AdministrativeResponsibleSelectedFromResources()
            ? null
            : (this.FkAdministrativeResponsible() as number);
        job.AdministrativeResponsibleName = this.AdministrativeResponsibleName();

        job.FkCommercialResponsible = !this.CommercialResponsibleSelectedFromResources()
            ? null
            : (this.FkCommercialResponsible() as number);
        job.CommercialResponsibleName = this.CommercialResponsibleName();

        job.ResponsiblesAndAuthorizedResources = [];
        for (const authResource of this.AuthorizedResources()) {
            const [resourceId, resourceType] = authResource.split("|");

            job.ResponsiblesAndAuthorizedResources.push({
                Id: null,
                JobOrderId: job.JobOrderId,
                ResourceOrResourcesGroupId: parseInt(resourceId),
                ResourceOrResourcesGroupName: null,
                ResourceType: resourceType,
                ResponsibleType: JobOrderResponsibleType.AuthorizedResource,
            });
        }

        job.MailRecipientsForDocumentsSendTo = [];
        job.MailRecipientsForDocumentsSendCc = [];
        job.MailRecipientsForDocumentsSendBcc = [];

        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            job.ProtocolsSettings.push({
                ProtocolId: p.ProtocolId,
                DefaultValuesEnabled: p.Enabled(),
                DefaultMetadatasEnabled: p.MetadatasEnabled(),
            });

            if (p.Enabled()) {
                p.GetSettings().forEach((s: IProtocolSetting) => {
                    job.ProtocolsDefaultValues.push(s);
                });
            }

            if (p.MetadatasEnabled()) {
                p.GetMetadata().forEach((m) => {
                    const metadata = {
                        Id: m.Id,
                        FkJobOrder: job.JobOrderId,
                        ProtocolId: p.ProtocolId,
                        FkMetadata: m.FKMetadata,
                        MetadataValueType: m.MetadataValueType,
                        ShowMarkerOnAllocationsGantt: m.ShowMarkerOnAllocationsGantt,
                    };

                    job.ProtocolsDefaultMetadatas.push(metadata);
                });
            }

            job.MailRecipientsForDocumentsSendTo = job.MailRecipientsForDocumentsSendTo.concat(
                ProtocolMailRecipientMapper.mapToJobOrderMailRecipient(
                    p.getMailRecipientsForDocumentsSendTo(),
                    job.JobOrderId
                )
            );

            job.MailRecipientsForDocumentsSendCc = job.MailRecipientsForDocumentsSendCc.concat(
                ProtocolMailRecipientMapper.mapToJobOrderMailRecipient(
                    p.getMailRecipientsForDocumentsSendCc(),
                    job.JobOrderId
                )
            );

            job.MailRecipientsForDocumentsSendBcc = job.MailRecipientsForDocumentsSendBcc.concat(
                ProtocolMailRecipientMapper.mapToJobOrderMailRecipient(
                    p.getMailRecipientsForDocumentsSendBcc(),
                    job.JobOrderId
                )
            );
        });

        job.DefaultIvaMode = this.DefaultIva() ? this.DefaultIva().IdTipoIVA : null;
        job.DefaultPaymentTypeId = this.PaymentTypeManagerUi.PaymentId();
        job.DefaultPaymentAccountId = this.PaymentTypeManagerUi.PaymentAccount()
            ? this.PaymentTypeManagerUi.PaymentAccount().Id
            : null;
        job.DefaultPaymentABI = this.PaymentTypeManagerUi.PaymentABI();
        job.DefaultPaymentCAB = this.PaymentTypeManagerUi.PaymentCAB();
        job.DefaultPaymentExpiryTypeId = this.DefaultPaymentExpiryType()
            ? this.DefaultPaymentExpiryType().IdTipoScadenza
            : null;
        job.DefaultPaymentExpiryType = this.DefaultPaymentExpiryType()
            ? this.DefaultPaymentExpiryType().Descrizione
            : null;
        job.DefaultOffset = this.DefaultOffset();
        job.DefaultOutcome = this.DefaultOutcome();
        job.DefaultCurrency = this.DefaultCurrency();
        job.ActivitiesProgressAmountMode = this.ActivitiesProgressAmountMode();

        job.MetadataId = this.MetadataId();
        job.EstimatedWork = this.EstimatedWork();
        job.EstimatedRevenue = this.EstimatedRevenue();

        return job;
    }

    public async previewCode(): Promise<string> {
        const code = await this.jobOrderService.PreviewCode(this.getData());
        this.CodePreview(code);
        return code;
    }
}

class JobOrderProject implements IJobOrderProjectViewModel {
    public projectSearchService: IControlsEntityProvider;

    JobOrderId = 0;
    Id: ko.Observable<number> = ko.observable(0);
    Name: ko.Observable<string> = ko.observable("");
    Description: ko.Observable<string> = ko.observable("");
    ProjectManagerId: ko.Observable<number> = ko.observable(0);
    CustomerId: ko.Observable<number> = ko.observable(0);

    constructor(
        private serviceLocator: IServiceLocator,
        private project: IJobOrderProject,
        private parentCollection: ko.ObservableArray<IJobOrderProjectViewModel>,
        private jobOrderId: number = 0
    ) {
        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.projectSearchService = entityProviderService
            .getEntityProvider(ProlifeSdk.ProjectEntityTypeCode)
            .getControlsProvider();
        this.Id(project.FkProjectId);
    }

    Remove() {
        this.parentCollection.remove(this);
    }

    GetData() {
        return <IJobOrderProject>{
            FkProjectId: this.Id(),
            FkJobOrderId: this.JobOrderId,
        };
    }
}
