import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { Param, ComponentParam, ComponentUtils } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { Portlet } from "./Portlet";

let attributes = {
    PortletTitle: "portlet-title",
    Icon: "icon",
    InjectTo: "inject-to",
    Collapsible: "collapsible",
    ViewModel: "view-model"
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "portlet": {
                params?: {
                    PortletTitle?: string,
                    Icon?: string,
                    InjectTo?: ko.Observable<IPortletComponent>,
                    Collapsible?: boolean,
                    ViewModel?: any
                };

                "portlet-title"?: string | (() => string);
                icon?: string | (() => string);
                "inject-to"?: (() => string);
                collapsible?: boolean | (() => string);
                "view-model"?: (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

export interface IPortletComponent {
    expand(): void;
    collapse(): void;
    switchCollapsedMode(): void;

    setViewModel(vm: any);
}

export interface IPortletComponentParams {
    PortletTitle: Param<string>;
    Icon: Param<string>;
    ViewModel: Param<any>;
    Collapsible: Param<boolean>

    InjectTo: ko.Observable<IPortletComponent>;
}

export class PortletComponent implements IPortletComponent {
    public Title: ComponentParam<string>;
    public Icon: ComponentParam<string>;
    public ViewModel: ComponentParam<any>;
    public Collapsed: ko.Observable<boolean> = ko.observable(true);
    public Collapsible: ko.Observable<boolean> = ko.observable(true);

    constructor(params : IPortletComponentParams) {
        this.Title = ComponentUtils.parseParameter(params.PortletTitle, "");
        this.Icon = ComponentUtils.parseParameter(params.Icon, "");
        this.ViewModel = ComponentUtils.parseParameter(params.ViewModel, undefined);
        this.Collapsible = ComponentUtils.parseParameter(params.Collapsible, false) as ko.Observable;

        if (!this.Collapsible())
            this.Collapsed(false);

        this.Collapsible.subscribe((value: boolean) => {
            if (!value)
                this.Collapsed(false);
        });

        if (params.InjectTo)
            params.InjectTo(this);
    }
    
    public setViewModel(vm: any) {
        this.ViewModel(vm);
    }

    public expand(): void {
        if (!this.Collapsible())
            return;

        this.Collapsed(false);
    }

    public collapse(): void {
        if (!this.Collapsible())
            return;

        this.Collapsed(true);
    }

    public switchCollapsedMode(): void {
        if (!this.Collapsible())
            return;

        this.Collapsed(!this.Collapsed());
    }
}

ko.components.register("portlet", {
    viewModel: {
        createViewModel: (params: IPortletComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            let vm = new PortletComponent(params);
            
            let htmlElement = componentInfo.element as HTMLElement;
            let classes = htmlElement.className;

            /* ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <Portlet portletTitle={vm.Title()} collapsible={vm.Collapsible()} className={classes} icon={vm.Icon()}>
                    <ko-template data-bind="{ nodes: $componentTemplateNodes, data: $component.ViewModel }"></ko-template>
                </Portlet>
            ]); */

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={"portlet light bordered task-section " + classes}>
                    <div class="portlet-title" data-bind="click: switchCollapsedMode">
                        <div class="caption bold">
                            <ko-if data-bind="Icon">
                            <i class="" data-bind="css: Icon"></i>
                            </ko-if>
                            <ko-text data-bind="Title"></ko-text>
                        </div>

                        <div class="tools" data-bind="visible: Collapsible">
                            <a href="javascript:void(0)" class="collapse" data-bind="click: switchCollapsedMode, clickBubble: false"></a>
                        </div>
                    </div>

                    <div class="portlet-body" data-bind="visible: !Collapsed(), with: ViewModel">
                        <ko-template data-bind="{ nodes: $componentTemplateNodes }"></ko-template>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});