import * as ko from "knockout";
import { ICustomerDiscountCatalog } from "../../../../DiscountsCatalogsService";
import { CustomerDiscountCatalogSolutionDataSource } from "../../../../../DataSources/CustomerDiscountCatalogSolutionDataSource";
import { CustomerDiscountCatalogsDataSource, IBaseCustomerDiscountCatalogsDataSourceObserver, BaseCustomerDiscountCatalogsDataSource, SupplierDiscountCatalogsDataSource } from "../../../../../DataSources/CustomerDiscountCatalogsDataSource";
import { AssignDiscountCatalogDialog } from "./AssignDiscountCatalogDialog";
import { IContactDiscounts } from "../../../../../ProlifeSdk/interfaces/warehouse/IContactDiscounts";

export class CustomerDiscountsEditor implements IContactDiscounts, IBaseCustomerDiscountCatalogsDataSourceObserver {
    ReadOnly : ko.Observable<boolean> = ko.observable(true);

    CustomerDiscounts : ko.ObservableArray<ICustomerDiscountCatalog> = ko.observableArray();
    CustomerDiscountCatalogsDataSource : BaseCustomerDiscountCatalogsDataSource = new CustomerDiscountCatalogsDataSource();
    CustomerDiscountCatalogSolutionDataSource : CustomerDiscountCatalogSolutionDataSource = new CustomerDiscountCatalogSolutionDataSource();

    SuppliersDiscounts : ko.ObservableArray<ICustomerDiscountCatalog> = ko.observableArray();
    SuppliersDiscountCatalogsDataSource : BaseCustomerDiscountCatalogsDataSource = new SupplierDiscountCatalogsDataSource();

    TestingDiscounts : ko.Observable<boolean> = ko.observable();
    TestingDiscountsFilter : ko.Observable<string> = ko.observable();

    IsCliente : ko.Observable<boolean> = ko.observable(true);

    constructor(private customerId : number) {
        this.CustomerDiscountCatalogsDataSource.addObserver(this);
        this.CustomerDiscountCatalogsDataSource.setCustomerId(this.customerId);
        this.CustomerDiscountCatalogSolutionDataSource.setCustomerId(this.customerId, true);

        this.SuppliersDiscountCatalogsDataSource.addObserver(this);
        this.SuppliersDiscountCatalogsDataSource.setCustomerId(this.customerId);
    }

    onDataChanged(): void {
        if(this.TestingDiscounts())
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
    }

    public toggleTestingMode() {
        this.TestingDiscounts(!this.TestingDiscounts());

        if(this.TestingDiscounts())
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
    }

    async assignDiscountCatalog() : Promise<void> {
        let result = await new AssignDiscountCatalogDialog(this.customerId, false, true).showModal()
        if(result)
            this.CustomerDiscountCatalogsDataSource.refresh();
    }

    SetEditMode(editable: boolean) {
        this.ReadOnly(!editable);
    }    
    
    reloadDiscounts(): Promise<void> {
        this.CustomerDiscountCatalogsDataSource.refresh();
        return Promise.resolve();
    }

    showCustomerDiscounts() {
        this.IsCliente(true);
        this.TestingDiscounts(false);
        this.CustomerDiscountCatalogSolutionDataSource.setCustomerId(this.customerId, true);
    }

    showSupplierDiscounts() {
        this.IsCliente(false);
        this.TestingDiscounts(false);
        this.CustomerDiscountCatalogSolutionDataSource.setCustomerId(this.customerId, false);
    }
}