import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { StockManagement } from "./ui/Stock/StockManagement";
import { CatalogsList } from "./ui/Catalogs/CatalogsList";
import { WarehouseArticles } from "./ui/Articles/WarehouseArticles";
import { InventoryManagement } from "./ui/Inventory/InventoryManagement";
import { HistoricalStockManagement } from "./ui/Stock/HistoricalStockManagement";
import { MovementsHistoryManagement } from "./ui/Stock/MovementsHistoryManagement";
import { Transforms } from "./ui/Articles/Transforms";
import { WarehouseViewModel, IWarehouseSection } from "./ui/WarehouseViewModel";
import { DiscountsCatalogsList } from "./ui/Discounts/DiscountsCatalogsList";
import { DiscountsList } from "./ui/Discounts/Discounts/DiscountsList";
import { SuppliersDiscountsList } from "./ui/Discounts/Discounts/SuppliersDiscountsList";
import { PreferredSuppliers } from "./ui/Discounts/PreferredSuppliers/PreferredSuppliers";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { WarehouseInspectionsUI } from "./ui/WarehouseInspections/WarehouseInspections";
import {
    WarehouseInspectionEditorUI,
    WarehouseInspectionEditorProps,
} from "./ui/WarehouseInspections/WarehouseInspectionEditor";
import { LazyImport } from "../../Core/DependencyInjection";
import { IWarehouseInspectionsService, IFullWarehouseInspection } from "../WarehouseInspectionsService";
import { WarehouseInspectionStatus } from "./ui/WarehouseInspections/Enums/WarehouseInspectionStatus";
import { Right } from "../../Core/Authorizations";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { FunctionalComponent } from "../../Core/utils/ComponentUtils";

const csss = require("../css/warehouse.scss");

export class WarehouseApplication implements IApplication {
    public templateName = "warehouse";
    public templateUrl = "warehouse/templates";
    public templateData: ko.Observable<any> = ko.observable();
    public tileColor = "tile-orange";

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService: IApplicationsService;
    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;
    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    @Right("Warehouse_WarehouseInspections")
    private canViewWarehouseInspections: boolean;

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return ProlifeSdk.WarehouseApplicationCode;
    }

    getName(): string {
        return ProlifeSdk.TextResources.Warehouse.Warehouse;
    }

    getIcon(): string {
        return "icon-basket-loaded";
    }

    registerRoutes(): void {
        this.opaService.Get(
            ProlifeSdk.TextResources.Warehouse.WarehouseURL,
            (context) => (location.href = ProlifeSdk.TextResources.Warehouse.ArticlesListURL)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.CatalogsURL, (context) =>
            this.startApplication("warehouse/ui/Catalogs/CatalogsList", CatalogsList)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.ArticlesListURL, (context) =>
            this.startApplicationFC("warehouse/ui/Articles/WarehouseArticles", 6, WarehouseArticles)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.DiscountsCatalogsURL, (context) =>
            this.startApplication("warehouse/ui/Discounts/DiscountsCatalogsList", DiscountsCatalogsList)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.CustomersDiscountsURL, (context) =>
            this.startApplication("warehouse/ui/Discounts/WarehouseClientsDiscountsList", DiscountsList)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.SuppliersDiscountsURL, (context) =>
            this.startApplication("warehouse/ui/Discounts/WarehouseSuppliersDiscountsList", SuppliersDiscountsList)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.WarehouseManagementURL, (context) =>
            this.startApplication("warehouse/ui/Inventory/InventoryManagement", InventoryManagement)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.InventoryURL, (context) =>
            this.startApplicationFC("warehouse/ui/Stock/StockManagement", 1, StockManagement)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.InventoryURL + "/:id", (context) =>
            this.StartApplicationFromWarehouseStock(parseInt(context.params["id"]), 1)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.StocksHistoryURL, (context) =>
            this.startApplicationFC("warehouse/ui/Stock/HistoricalStockManagement", 7, HistoricalStockManagement)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.MovementsURL, (context) =>
            this.startApplication("warehouse/ui/Stock/MovementsHistoryManagement", MovementsHistoryManagement)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.TransformsURL, (context) =>
            this.startApplication("warehouse/ui/Articles/Transforms", Transforms)
        );
        this.opaService.Get(ProlifeSdk.TextResources.Warehouse.PreferredSuppliersURL, () =>
            this.startApplication("", PreferredSuppliers)
        );

        if (this.canViewWarehouseInspections) {
            this.opaService.Get(ProlifeSdk.TextResources.Warehouse.WarehouseInspectionsURL, (context) =>
                this.startApplication("", WarehouseInspectionsUI)
            );
            this.opaService.Get(ProlifeSdk.TextResources.Warehouse.WarehouseInspectionsURL + "/:id", (context) =>
                this.startInspectionEditor(parseInt(context.params["id"]))
            );
        }
    }

    private async startInspectionEditor(inspectionId: number): Promise<void> {
        let fullInspection: IFullWarehouseInspection = null;

        if (!!inspectionId && inspectionId > 0)
            fullInspection = await this.warehouseInspectionsService.GetFullWarehouseInspection(inspectionId);
        else {
            const emptyWarehouseInspection = this.createEmptyFullWarehouseInspection();
            fullInspection = await this.warehouseInspectionsService.CreateOrUpdateWarehouseInspections(
                emptyWarehouseInspection.WarehouseInspection,
                emptyWarehouseInspection.WarehouseInspectionOperations,
                emptyWarehouseInspection.WarehouseInspectionOperationsSources,
                null
            );
        }

        const editorProps: WarehouseInspectionEditorProps = {
            fullInspection: fullInspection,
            onDeleteSuccess: this.goToInspections.bind(this),
            onEditingAbort: this.goToInspections.bind(this),
            focusOnTitle: !inspectionId || inspectionId <= 0,
        };

        const editor = new WarehouseInspectionEditorUI(editorProps);
        this.start(editor);
    }

    private goToInspections(): void {
        location.href = TextResources.Warehouse.WarehouseInspectionsURL;
    }

    private StartApplicationFromWarehouseStock(warehouseId: number, sectionId: number) {
        this.start({
            SectionId: sectionId,
            render: () => <StockManagement selectDefaultWarehouse={false} warehouseId={warehouseId} />,
        });
    }

    private startApplication(path: string, constr: new () => IWarehouseSection) {
        this.start(new constr());
    }

    private startApplicationFC(path: string, sectionId: number, component: FunctionalComponent) {
        this.start({
            SectionId: sectionId,
            render: component,
        });
    }

    getApplicationRoute(): string {
        return ProlifeSdk.TextResources.Warehouse.ArticlesListURL;
    }

    onGoingDown(): void {
        if (this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            //delete this.templateData();
        }
    }

    private start(section?: IWarehouseSection) {
        this.onGoingDown();
        this.templateData(new WarehouseViewModel(section));
        this.applicationsService.startApplication(this);
    }

    private createEmptyFullWarehouseInspection(): IFullWarehouseInspection {
        return {
            WarehouseInspection: [
                {
                    Id: this.warehouseInspectionsService.GetNextFakeId(),
                    Title: TextResources.Warehouse.NewInspectionDefaultTitle,
                    Status: WarehouseInspectionStatus.Draft,
                    CreationDate: null,
                    CreationUserId: null,
                    LastModificationDate: null,
                    LastModificationUserId: null,
                    AutomaticDocumentGeneration: true,
                },
            ],
            WarehouseInspectionOperations: [],
            WarehouseInspectionOperationsSources: [],
            DocumentsGenerated: null,
            LastDocumentsGenerationError: null,
        };
    }
}
