import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { AgendasProvider } from "./providers/AgendasProvider";
import { CategoriesProvider } from "./providers/CategoriesProvider";
import { EventCanceledMotivationsProvider } from "./providers/EventCanceledMotivationsProvider";
import { CustomersManager } from "./CustomersManager";
import { ResourcesAndGroupsManager } from "./ResourcesAndGroupsManager";
import { UiUtilities } from "./utils/UiUtilities";
import { DateTimeUtilities } from "./utils/DateTimeUtilities";
import { Moment } from 'moment';
import * as moment from "moment";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import { IAgendaEventResource, IEventsService, IAgendaEventForEditingEx, IEventCategory, ICustomerEnvelope, ITimeslotForEvent } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IAgendaService, IAgendaConfiguration, IResourceForManager, ITimetableForAgendaConfiguration, ITimeslotForAgendaConfiguration, ITimeslotsIntervalForConfiguration } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IApplicationsConfigurationsService, IApplicationConfiguration } from "../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IAgendaEventViewModel, ITimeslotForEventViewModel, ICustomerForListViewModel } from "../../interfaces/IAgendaEvent";
import { IFullCalendar, IFullCalendarViewObject, IFullCalendarSelectedInterval } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { ILogTagFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { Deferred } from "../../../Core/Deferred";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class AgendaEventViewModel implements IAgendaEventViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public AgendaId: ko.Observable<number> = ko.observable();
    public CategoryId: ko.Observable<number> = ko.observable();
    public Note: ko.Observable<string> = ko.observable();
    public AllDay: ko.Observable<boolean> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public StartTime: ko.Observable<Date> = ko.observable();
    public EndTime: ko.Observable<Date> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public EventPlace: ko.Observable<string> = ko.observable();
    public Deleted: ko.Observable<number> = ko.observable();
    public CanceledMotivationId: ko.Observable<number> = ko.observable();
    public CustomersIds: ko.ObservableArray<number> = ko.observableArray([]);
    public Resources: ko.ObservableArray<IAgendaEventResource> = ko.observableArray([]);
    public Allocable: ko.Observable<boolean> = ko.observable();
    public PreEventAllocatedHours: ko.Observable<number> = ko.observable();
    public PostEventAllocatedHours: ko.Observable<number> = ko.observable();
    public AvailableTimeslots: ko.ObservableArray<ITimeslotForEventViewModel> = ko.observableArray([]);
    public SelectedTimeslot: ko.Observable<any> = ko.observable();
    public AbortedMotivationId: ko.Observable<number> = ko.observable();
    public EventSourceProvider: ko.Observable<string> = ko.observable();
    public EventCalendarOnProvider: ko.Observable<string> = ko.observable();
    public EventIdOnProvider: ko.Observable<string> = ko.observable();

    public CategoryName: ko.Observable<string> = ko.observable();
    public AgendaName: ko.Observable<string> = ko.observable();
    public ResourcesNames: ko.ObservableArray<string> = ko.observableArray([]);
    public CustomersNames: ko.ObservableArray<string> = ko.observableArray([]);
    public CanceledMotivationLabel: ko.Observable<string> = ko.observable();
    public CustomersListForMenu: ko.ObservableArray<ICustomerForListViewModel> = ko.observableArray([]);

    private IsRelatedEvent: ko.Observable<boolean> = ko.observable();

    public AgendaTimeslotsModeEnabled: ko.Computed<boolean>;
    public ShowMotivationInputFieldForWaitingList: ko.Computed<boolean>;
    public ShowMotivationInputFieldForAborting: ko.Computed<boolean>;
    public IsNew: ko.Computed<boolean>;
    public IsAborted:ko.Computed<boolean>;
    public IsOnWaitingList: ko.Computed<boolean>;

    public AgendasProvider: AgendasProvider;
    public CategoriesProvider: CategoriesProvider;
    public EventCanceledMotivationsProvider: EventCanceledMotivationsProvider;
    public CustomersManager: CustomersManager;
    public ResourcesAndGroupsManager: ResourcesAndGroupsManager;
    public DateTimeUtilities: DateTimeUtilities;

    public IsReadOnly: ko.Observable<boolean> = ko.observable(true);
    public CanEditCategory: ko.Observable<boolean> = ko.observable();
    public CanDeleteEvents: ko.Observable<boolean> = ko.observable();
    public CanPlanEvents: ko.Observable<boolean> = ko.observable();
    public CanConfirmEvents: ko.Observable<boolean> = ko.observable();
    public CanAbortEvents: ko.Observable<boolean> = ko.observable();
    public CanPutEventsOnWaitingList: ko.Observable<boolean> = ko.observable();
    public CanEditResources: ko.Observable<boolean> = ko.observable();
    public CanManageCustomers: ko.Observable<boolean> = ko.observable();

    public CanChangeEventState: ko.Computed<boolean>;

    public AllocationsModuleEnabled: ko.Observable<boolean> = ko.observable(false);

    private InitialEventCategory: ko.Observable<any> = ko.observable();
    private AgendaConfiguration: ko.Observable<any> = ko.observable();

    private InitialSelectedSlot: any;

    private lastStartTime: Date;
    private lastEndTime: Date;

    private color: string;
    private configurationsOnLoading = false;
    private lastGeneratedTitle: string;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IAgendaService>())
    private agendasService: IAgendaService;
    @LazyImport(nameof<IEventsService>())
    private eventsService: IEventsService;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService : IAuthenticationService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;
    
    constructor(private serviceLocator: IServiceLocator, private event: IAgendaEventForEditingEx, private calendar: IFullCalendar = null) {
        this.eventsService.IsEventRelatedToLoggedUser(this.event.id)
            .then((result: boolean) => {
                this.IsRelatedEvent(result);
                this.IsReadOnly(!this.CanEditEvent());
            });

        this.CategoryId.subscribe((newCategory: number) => {
            if (!newCategory) {
                this.color = ProlifeSdk.AgendaEventDefaultColor;
                this.CategoryName("");
                return;
            }

            const category: IEventCategory = this.CategoriesProvider.GetCategoryById(newCategory);

            if (!category)
                return;

            this.color = category.Color;
            this.CategoryName(category.Label);

            if (category.LogicalState != ProlifeSdk.AbortedEventState)
                this.AbortedMotivationId(null);

            if (category.LogicalState != ProlifeSdk.WaitingListEventState)
                this.CanceledMotivationId(null);
        });

        this.AllocationsModuleEnabled(this.authorizationService.isAuthorized("Allocations_Start"));

        this.CanEditCategory(this.CanEditEventCategory());
        this.CanDeleteEvents(this.CanDelete());
        this.CanPlanEvents(this.CanPlan());
        this.CanConfirmEvents(this.CanConfirm());
        this.CanAbortEvents(this.CanAbort());
        this.CanPutEventsOnWaitingList(this.CanPutOnWaitingList());
        this.CanEditResources(this.CanEditEvent());
        this.CanManageCustomers(this.CanEditCustomers() && this.CanEditEvent());

        this.AgendasProvider = new AgendasProvider(this.serviceLocator);
        this.CategoriesProvider = new CategoriesProvider(this.serviceLocator);
        this.EventCanceledMotivationsProvider = new EventCanceledMotivationsProvider(this.serviceLocator);
        this.CustomersManager = new CustomersManager();
        this.ResourcesAndGroupsManager = new ResourcesAndGroupsManager();
        this.DateTimeUtilities = new DateTimeUtilities();

        this.event.color = !this.event.color ? ProlifeSdk.AgendaEventDefaultColor : this.event.color;
        this.InitialEventCategory(this.CategoriesProvider.GetCategoryById(this.event.CategoryId));
        this.color = this.event.color;

        this.lastStartTime = this.event.start;
        this.lastEndTime = this.event.end;

        this.Id(this.ExtractEventId(this.event.id));
        this.Title(this.event.title);
        this.AgendaId(this.event.AgendaId);
        this.CategoryId(this.event.CategoryId);
        this.Note(this.event.Note);
        this.AllDay(this.event.allDay);
        this.StartDate(!this.event.start ? null : moment(this.event.start).toDate());
        this.StartTime(!this.event.start ? null : moment(this.event.start).toDate());
        this.EndDate(!this.event.end ? null : moment(this.event.end).toDate());
        this.EndTime(!this.event.end ? null : moment(this.event.end).toDate());
        this.Color(this.event.color);
        this.EventPlace(this.event.EventPlace);
        this.Deleted(this.event.Deleted);
        this.CanceledMotivationId(this.event.CanceledMotivationId);
        this.CustomersIds(this.event.CustomersIds);
        this.Resources(this.event.Resources);
        this.Allocable(this.event.Allocable);
        this.PreEventAllocatedHours(this.event.PreEventAllocatedHours);
        this.PostEventAllocatedHours(this.event.PostEventAllocatedHours);
        this.AbortedMotivationId(this.event.EventAbortedMotivationId);
        this.EventSourceProvider(this.event.EventSourceProvider);
        this.EventCalendarOnProvider(this.event.EventCalendarOnProvider);
        this.EventIdOnProvider(this.event.EventIdOnProvider);

        this.CustomersManager.LoadCustomers(this.CustomersIds());
        this.ResourcesAndGroupsManager.LoadResources(this.Resources());

        this.ShowMotivationInputFieldForWaitingList = ko.computed(() => {
            if (!this.CategoryId())
                return false;
            const categoryLogicalState: number = this.CategoriesProvider.GetCategoryLogicalState(this.CategoryId());
            return categoryLogicalState == ProlifeSdk.WaitingListEventState;
        });

        this.ShowMotivationInputFieldForAborting = ko.computed(() => {
            if (!this.CategoryId())
                return false;
            const categoryLogicalState: number = this.CategoriesProvider.GetCategoryLogicalState(this.CategoryId());
            return categoryLogicalState == ProlifeSdk.AbortedEventState;
        });

        this.AgendaTimeslotsModeEnabled = ko.computed(() => {
            if (!this.AgendaConfiguration())
                return false;

            return this.AgendaConfiguration().TimeslotsModeEnabled;
        });

        this.IsNew = ko.computed(() => {
            return this.Id() <= 0;
        });

        this.IsAborted = ko.computed(() => {
            return !this.InitialEventCategory() ? false : this.InitialEventCategory().LogicalState == ProlifeSdk.AbortedEventState;
        });

        this.IsOnWaitingList = ko.computed(() => {
            return !this.InitialEventCategory() ? false : this.InitialEventCategory().LogicalState == ProlifeSdk.WaitingListEventState;
        });

        this.CanChangeEventState = ko.computed(() => {
            return this.CanEditCategory()
                && (this.CanPlanEvents()
                    || this.CanConfirmEvents()
                    || this.CanAbortEvents()
                    || this.CanPutEventsOnWaitingList());
        });

        this.AgendaId.subscribe((agendaId: number) => {
            if (this.configurationsOnLoading)
                return;

            this.configurationsOnLoading = true;

            this.LoadAgendaConfiguration()
                .then((config: IAgendaConfiguration) => {
                    this.AgendaConfiguration(config);
                    this.AgendaName(config.AgendaName);

                    this.configurationsOnLoading = false;
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.LoadAgendaConfigurationError);

                    this.configurationsOnLoading = false;
                });
        });

        this.AgendaConfiguration.subscribe((config: IAgendaConfiguration) => {
            if (config.TimeslotsModeEnabled) {
                this.LoadAvailableTimeslots();
            }
        });

        let lastStartDate = moment(this.StartDate());
        this.StartDate.subscribe((newStartDate: Date) => {
            if (!this.AgendaConfiguration())
                return;
            if (this.AgendaConfiguration().TimeslotsModeEnabled && (!newStartDate ? null : moment(newStartDate).format('L')) != moment(lastStartDate).format('L')) {
                this.LoadAvailableTimeslots();
                lastStartDate = moment(newStartDate);
                this.EndDate(newStartDate);
            }
        });

        this.CanceledMotivationId.subscribe((newMotivationId: number) => {
            if (!newMotivationId) {
                this.CanceledMotivationLabel("");
                return;
            }

            this.CanceledMotivationLabel(this.EventCanceledMotivationsProvider.GetMotivationById(newMotivationId).Label);
        });

        this.AbortedMotivationId.subscribe((newMotivationId: number) => {
            if (!newMotivationId) {
                this.CanceledMotivationLabel("");
                return;
            }

            this.CanceledMotivationLabel(this.EventCanceledMotivationsProvider.GetMotivationById(newMotivationId).Label);
        });

        this.AllDay.subscribe((value: boolean) => {
            if (value) {
                this.lastStartTime = this.StartTime();
                this.lastEndTime = this.EndTime();

                this.EndDate(this.StartDate());
                this.EndTime(this.StartTime());
                return;
            }

            this.StartTime(this.lastStartTime);
            this.EndTime(this.lastEndTime);
        });

        const EventDurationInterceptor = ko.computed(() => {
            if (!this.StartDate() || !this.StartTime() || !this.EndDate() || !this.EndTime())
                return;

            if (this.AllDay()) {
                this.EndDate(this.StartDate());
                return;
            }

            const startTime: number[] = (!this.StartTime() ? '00:00:00' : moment(this.StartTime()).format('HH:mm:ss')).split(':').map((t: string)=> parseInt(t));
            const startDateAndTime = moment(this.StartDate()).hours(startTime[0]).minutes(startTime[1]).seconds(startTime[2]);

            const endTime: number[] = (!this.EndTime() ? '00:00:00' : moment(this.EndTime()).format('HH:mm:ss')).split(':').map((t: string)=> parseInt(t));
            const endDateAndTime = moment(this.EndDate()).hours(endTime[0]).minutes(endTime[1]).seconds(endTime[2]);

            if (startDateAndTime > endDateAndTime) {
                this.EndDate(this.StartDate());
                this.EndTime(this.StartTime());

                this.lastStartTime = moment(this.StartTime()).toDate();
                this.lastEndTime = moment(this.EndTime()).toDate();
            }
        });

        const CustomersListInterceptor = ko.computed(() => {
            const customersIds: number[] = this.CustomersManager.GetCustomers(); //this.CustomersManager.Customers().split('|').map((id: string) => parseInt(id));

            this.eventsService.GetCustomersEnvelopes(customersIds)
                .then((customers: ICustomerEnvelope[]) => {
                    this.CustomersListForMenu(customers.map((c: ICustomerEnvelope) => this.CreateCustomerForListViewModel(c)));

                    if (!!this.Title() && this.Title() != this.lastGeneratedTitle)
                        return;

                    const title = customers.map((customer) => customer.NameOrBusinessName).join(", ");
                        
                    if (!this.Title() || this.Title() == this.lastGeneratedTitle)
                        this.Title(title);
                    
                    this.lastGeneratedTitle = title;
                });
        });


        this.configurationsOnLoading = true;
        Promise.all(this.LoadConfigurations())
            .then(([preferredCategory, agendaConfig]) => {
                if (!!preferredCategory && this.CanEditCategory() && this.CanChangeEventState() && this.IsNew())
                    this.CategoryId(preferredCategory);

                this.AgendaConfiguration(agendaConfig);
                this.AgendaName(agendaConfig.AgendaName);

                this.SetEventDates();

                if (agendaConfig.TimeslotsModeEnabled)
                    this.SelectInitialTimeslot();
                else {
                    const category: IEventCategory = this.CategoriesProvider.GetCategoryByLogicalState(ProlifeSdk.ConfirmedEventState, false);
                    this.CategoryId(!category ? null : category.Id);
                }

                this.configurationsOnLoading = false;

            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.LoadConfigurationsError);
                this.configurationsOnLoading = false;
            });

        if (this.IsReadOnly)
            this.LoadResourcesAndCustomersLabels();
    }

    public Delete(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.DeleteEventMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.eventsService.Delete(this.Id())
                        .then(() => {
                            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.EventDeleteSuccess);
                            def.resolve(true);
                        })
                        .catch(() => {
                            this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.DeleteFailed);
                            def.resolve(false);
                        });
            }
        );

        return def.promise();
    }

    public GetData(): IAgendaEventForEditingEx{
        const color: string = this.AgendaTimeslotsModeEnabled() ? this.color : ProlifeSdk.AgendaEventDefaultColor;
        return {
            id: this.Id(),
            title: this.Title(),
            allDay: this.AllDay(),
            start: this.GetDateTimeForEvent(this.StartDate(), this.StartTime()),
            end: this.GetDateTimeForEvent(this.EndDate(), this.EndTime()),
            color: UiUtilities.GetColorWithOpacity(color, 0.6),
            EventPlace:this.EventPlace(),
            AgendaId: this.AgendaId(),
            CategoryId: this.CategoryId(),
            Note: this.Note(),
            CanceledMotivationId: this.CanceledMotivationId(),
            CustomersIds: this.CustomersManager.GetCustomers(),
            Resources: this.ResourcesAndGroupsManager.GetResources().map((r: IResourceForManager) => { return { EventId: this.Id(), ElementType: r.ElementType, ElementId: r.ElementId } }),
            Deleted: this.Deleted(),
            textColor: UiUtilities.GetForegroundColorForAlphaColor(color, 0.6, "#FFFFFF"),
            Tags: this.GetEventTags(),
            editable: true,
            Allocable: this.Allocable(),
            PreEventAllocatedHours: this.PreEventAllocatedHours(),
            PostEventAllocatedHours: this.PostEventAllocatedHours(),
            IsFromBookingPortal: false,
            CreatedBy: this.event.CreatedBy,
            CreationDate: this.event.CreationDate,
            ModifiedBy: this.event.ModifiedBy,
            ModifyDate: this.event.ModifyDate,
            EventAbortedMotivationId: this.AbortedMotivationId(),
            EventSourceProvider: this.event.EventSourceProvider,
            EventCalendarOnProvider: this.event.EventCalendarOnProvider,
            EventIdOnProvider: this.event.EventIdOnProvider
        };
    }

    public GetEventStartDateAndTime(): Date {
        if (!this.StartDate() || !this.StartTime())
            return null;
        return this.GetDateTimeForEvent(this.StartDate(), this.StartTime());
    }

    public GetEventEndDateAndTime(): Date {
        if (!this.EndDate() || !this.EndTime())
            return null;
        return this.GetDateTimeForEvent(this.EndDate(), this.EndTime());
    }

    public GetEventTags(): ILogTagFilter[] {
        const tags: ILogTagFilter[] = [];
        tags.push({
            TagName: ProlifeSdk.TagAgendaEvent_Title,
            ValueTypeId: ProlifeSdk.TagType_String,
            Values: [this.Title()]
        });

        if (this.CategoryId()) {
            tags.push({
                TagName: ProlifeSdk.TagAgendaEvent_EventCategory,
                ValueTypeId: ProlifeSdk.TagAgendaEvent_EventCategory,
                Values: [this.CategoryId().toString()]
            });
        }

        tags.push({
            TagName: ProlifeSdk.TagAgendaEvent_EventAgenda,
            ValueTypeId: ProlifeSdk.TagAgendaEvent_EventAgenda,
            Values: [this.AgendaId().toString()]
        });

        tags.push({
            TagName: ProlifeSdk.TagAgendaEvent_EventStart,
            ValueTypeId: ProlifeSdk.TagType_DateTime,
            Values: [moment(this.StartDate()).toDate().toLocaleString()]
        });

        tags.push({
            TagName: ProlifeSdk.TagAgendaEvent_EventEnd,
            ValueTypeId: ProlifeSdk.TagType_DateTime,
            Values: [moment(this.EndDate()).toDate().toLocaleString()]
        });

        const customers = this.CustomersManager.GetCustomers();
        if (customers.length > 0) {
            tags.push({
                TagName: ProlifeSdk.TagAgendaEvent_EventCustomer,
                ValueTypeId: ProlifeSdk.TagAgendaEvent_EventCustomer,
                Values: customers.map((cId: number) => cId.toString())
            });
        }

        if (this.EventPlace()) {
            tags.push({
                TagName: ProlifeSdk.TagAgendaEvent_EventPlace,
                ValueTypeId: ProlifeSdk.TagType_String,
                Values: [this.EventPlace()]
            });
        }

        if (this.Note()) {
            tags.push({
                TagName: ProlifeSdk.TagAgendaEvent_EventNotes,
                ValueTypeId: ProlifeSdk.TagType_String,
                Values: [this.Note()]
            });
        }

        return tags;
    }

    public GetEventLogicalState(): number {
        const category: IEventCategory = this.CategoriesProvider.GetCategoryById(this.CategoryId(), false);
        if (!category)
            return null;

        return category.LogicalState;
    }

    public  DataAreValid(): Promise<boolean> {
        const def = new Deferred<boolean>();
        if (!this.Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventTitleRequired);
            return def.resolve(false).promise();
        }

        if (this.AgendaTimeslotsModeEnabled()) {
            if (!this.SelectedTimeslot()) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.TimeslotForEventRequired);
                return def.resolve(false).promise();
            }

            if (!this.CategoryId()) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventCategoryRequired);
                return def.resolve(false).promise();
            }

            if (this.CategoriesProvider.GetCategoryLogicalState(this.CategoryId()) == ProlifeSdk.WaitingListEventState && !this.CanceledMotivationId()) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.MotivationRequired);
                return def.resolve(false).promise();
            }
        }

        if (this.Allocable() && this.ResourcesAndGroupsManager.GetResources().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.AllocableEventError);
            return def.resolve(false).promise();
        }

        if (this.AgendaConfiguration().CustomersSelectionOnEventsRequired && this.CustomersManager.GetCustomers().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.CustomersSelectionRequiredError);
            return def.resolve(false).promise();
        }

        if (moment(this.StartDate()) < moment().startOf('day')) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Agenda.SaveEventOnPastDateMessage,
                ProlifeSdk.TextResources.Agenda.CancelButton,
                ProlifeSdk.TextResources.Agenda.ConfirmButton,
                (confirm: boolean) => {
                    def.resolve(confirm);
                }
            );
            return def.promise();
        }

        return def.resolve(true).promise();
    }

    public SelectTimeslot(timeSlot: ITimeslotForEventViewModel): void {
        this.AvailableTimeslots().forEach((t: ITimeslotForEventViewModel) => { if (t.Identifier != timeSlot.Identifier) t.Selected(false); });
        timeSlot.Selected(true);
        this.SelectedTimeslot(timeSlot);

        const start = this.AgendaTimeslotsModeEnabled() ? this.SetDateAndTimeForTimeslotMode(this.StartDate(), this.SelectedTimeslot().Start) : this.StartDate();
        const end = this.AgendaTimeslotsModeEnabled() ? this.SetDateAndTimeForTimeslotMode(this.StartDate(), this.SelectedTimeslot().End) : (this.AllDay() ? start : this.EndDate());

        this.StartDate(start);
        this.EndDate(end);
    }

    public IsInitialTimeslot(timeSlot: ITimeslotForEventViewModel): boolean {
        if (!this.InitialSelectedSlot)
            return false;
        const initialDate = moment(this.InitialSelectedSlot.StartDate).format("L");
        const initialStartTime = moment(this.InitialSelectedSlot.StartDate).format("HH:mm");
        const initialEndTime = moment(this.InitialSelectedSlot.EndDate).format("HH:mm");

        return (initialStartTime == timeSlot.Start &&
            initialEndTime == timeSlot.End &&
            moment(this.StartDate()).format("L") == initialDate);
    }

    public  OnDatePickerViewChange(picker : any, e: { change: string; viewDate: any; }) {
        if (!this.AgendaConfiguration())
            return [];

        const periodStart: Moment = moment(e.viewDate).startOf('month').add('days', -7);
        const periodEnd: Moment = moment(e.viewDate).endOf('month').add('days', 15);

        const agendaConfiguration: IAgendaConfiguration = this.AgendaConfiguration();
        if (agendaConfiguration.Timetables.length == 0) {
            return [];
        }

        const filteredTimetables: ITimetableForAgendaConfiguration[] = agendaConfiguration.Timetables.filter((t: ITimetableForAgendaConfiguration) => {
            return (!!t.EndDate && periodStart <= moment(t.EndDate) && periodEnd >= moment(t.EndDate))
                || (moment(t.StartDate) >= periodStart && moment(t.StartDate) <= periodEnd)
                || (periodStart >= moment(t.StartDate) && (!t.EndDate || periodStart <= moment(t.EndDate)));
        });

        if (filteredTimetables.length == 0) {
            return [];
        }

        picker.enabledDates(this.CreateDatepickerDatesArray(filteredTimetables, periodStart, periodEnd));
        return [];
    }

    public EditSelectedCustomer(): void {
        if (this.CustomersListForMenu().length == 0)
            return;

        this.CustomersListForMenu()[0].Edit();
    }

    public GetSelectedAgendaConfiguration(): IAgendaConfiguration {
        return this.AgendaConfiguration();
    }

    private CreateDatepickerDatesArray(timetables: ITimetableForAgendaConfiguration[], from: Moment, to: Moment): Moment[] {
        const datesIntervals: any[] = this.CreateDatesIntervalsFromAgendasTimetables(timetables);
        let filteredTimetables: ITimetableForAgendaConfiguration[] = [];

        const validDates = [];

        datesIntervals.forEach((i: any) => {
            filteredTimetables = timetables.filter((t: ITimetableForAgendaConfiguration) => { return moment(i.start) >= moment(t.StartDate) && (!i.end || !t.EndDate || moment(i.end) <= moment(t.EndDate)); });
            if (filteredTimetables.length == 0)
                return;
            if (filteredTimetables.length > 1)
                filteredTimetables = filteredTimetables.filter((t: ITimetableForAgendaConfiguration) => { return t.IsVariation; });

            const workableDays: number[] = filteredTimetables[0].Timeslots.map((ts: ITimeslotForAgendaConfiguration) => { return ts.DayOfWeek; }).sort((a, b) => { return a - b; });

            let lastDate: Moment = from < moment(filteredTimetables[0].StartDate) ? moment(filteredTimetables[0].StartDate) : moment(from);
            const timeTableEnd: Moment = !filteredTimetables[0].EndDate ? moment(to) : moment(filteredTimetables[0].EndDate);
            while(lastDate <= to && lastDate <= timeTableEnd) {
                workableDays.forEach((d: number) => {
                    const newDate = moment(lastDate).day(d);
                    if (newDate < lastDate)
                        return;
                    validDates.push(moment(newDate));
                });
                lastDate = moment(lastDate).endOf('week').add('days', 1);
            }
        });

        return validDates;
    }

    private LoadResourcesAndCustomersLabels() {
        this.eventsService.GetResourcesLabels(this.Id())
            .then((labels: string[]) => {
                this.ResourcesNames(labels);
            });

        this.eventsService.GetCustomersLabels(this.Id())
            .then((labels: string[]) => {
                this.CustomersNames(labels);
            });
    }

    private CanEditEvent() {
        return this.authorizationService.isAuthorized("Agenda_EditAllEvents")
            || (this.authorizationService.isAuthorized("Agenda_EditRelatedEvents") && this.IsRelatedEvent())
            || (this.event.CreatedBy == (<any>this.authenticationService.getUser()).IdUser)
            || (this.authorizationService.isAuthorized("Agenda_ManageEventsFromBookingPortal") && this.event.IsFromBookingPortal)
            || this.event.id <= 0;
    }

    private CanEditEventCategory() {
        return this.authorizationService.isAuthorized("Agenda_EditEventCategory");
    }

    private CanDelete() {
        return this.authorizationService.isAuthorized("Agenda_DeleteEvents")
            || (this.event.CreatedBy == (<any>this.authenticationService.getUser()).IdUser);
    }

    private CanPlan(): boolean {
        return this.authorizationService.isAuthorized("Agenda_PlanEvents");
    }

    private CanConfirm(): boolean {
        return this.authorizationService.isAuthorized("Agenda_ConfirmEvents");
    }

    private CanAbort(): boolean {
        return this.authorizationService.isAuthorized("Agenda_AbortEvents");
    }

    private CanPutOnWaitingList(): boolean {
        return this.authorizationService.isAuthorized("Agenda_PutEventsOnWaitingList");
    }

    private CanEditCustomers(): boolean {
        return this.authorizationService.isAuthorized("Customers_Start");
    }

    private SetDateAndTimeForTimeslotMode(date: Date, time: string): Date {
        const mDate: Moment = moment(date);
        const timeComponents: string[]= time.split(':');

        mDate.hours(parseInt(timeComponents[0]));
        mDate.minutes(parseInt(timeComponents[1]));

        return mDate.toDate();
    }

    private LoadConfigurations(): Promise<any>[] {
        const defs: Promise<any>[] = [];

        defs.push(this.LoadUserConfigurations());
        defs.push(this.LoadAgendaConfiguration());

        return defs;
    }

    private LoadUserConfigurations(): Promise<any> {
        const def = new Deferred();

        this.modulesService.getModuleId(ProlifeSdk.AgendaApplication)
            .then((moduleId: number) => {
                if (!moduleId)
                    return;

                this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.UserPredefinedCategoryConfigurationType, this.userInfo.getIdUser(), moduleId, null)
                    .then((config: IApplicationConfiguration[]) => {
                        if (!config || !config[0] || !config[0].Configuration) {
                            def.resolve(null);
                            return;
                        }

                        def.resolve(JSON.parse(config[0].Configuration));
                    })
                    .catch(() => {
                        def.reject();
                    });
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private LoadAgendaConfiguration(): Promise<IAgendaConfiguration> {
        const def = new Deferred<IAgendaConfiguration>();
        this.agendasService.GetAgendaConfiguration(this.AgendaId())
            .then((agendaConfig: IAgendaConfiguration) => {
                def.resolve(agendaConfig);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    private SelectInitialTimeslot(): void {
        this.SetInitialSelectedSlotInfo();
        this.SetSelectedTimeslot();
    }

    private SetInitialSelectedSlotInfo(): void {
        this.InitialSelectedSlot = {
            StartDate: this.StartDate(),
            EndDate: this.EndDate()
        };
    }

    private SetSelectedTimeslot(): void {
        const timeslot: string = moment(this.StartDate()).format("HH:mm") + '-' + moment(this.EndDate()).format("HH:mm");
        const selectedTimeslots: ITimeslotForEventViewModel[] = this.AvailableTimeslots().filter((t: ITimeslotForEventViewModel) => { return t.Identifier == timeslot; });
        if (selectedTimeslots.length != 0) {
            this.SelectedTimeslot(selectedTimeslots[0]);
            selectedTimeslots[0].Selected(true);
            return;
        }
        this.SelectedTimeslot(undefined);
    }

    private LoadAvailableTimeslots(): void {
        if (!this.StartDate())
            return;
        this.eventsService.GetAvailableTimeslotsForDate(this.StartDate(), this.AgendaId())
            .then((timeslots: ITimeslotForEvent[]) => {
                this.AvailableTimeslots(timeslots.map((t: ITimeslotForEvent) => { return this.CreateTimeslotForEventViewModel(t); }));
                this.SetSelectedTimeslot();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAvailableTimeslotsError);
            });
    }

    private CreateTimeslotForEventViewModel(timeslot: ITimeslotForEvent): TimeslotForEvent {
        return new TimeslotForEvent(timeslot, this);
    }

    private CreateCustomerForListViewModel(customer: ICustomerEnvelope): ICustomerForListViewModel {
        return new CustomerForListViewModel(this.CustomersManager, customer);
    }

    private SetEventDates(): any {
        if (!this.IsNew())
            return;

        let start: Moment = null;
        let end: Moment = null;

        if (this.calendar) {
            const view: IFullCalendarViewObject = !this.calendar.SelectedInterval() ? this.calendar.GetActualView() : this.calendar.SelectedInterval().view;
            if (this.calendar.SelectedInterval()) {
                const interval: IFullCalendarSelectedInterval = this.calendar.SelectedInterval();
                var timeComponents: number[] = view.name == ProlifeSdk.CalendarMonthView ? moment().format("LT").split(':').map((o: string) => parseInt(o)) : interval.start.format("LT").split(':').map((o: string) => parseInt(o));
                start = interval.start.hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local();
            } else {
                var timeComponents: number[] = moment().format("LT").split(':').map((o: string) => parseInt(o));
                const paramDate = view.start > moment().startOf('day') ? view.start.hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local() : moment().hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local();
                start = this.AgendaTimeslotsModeEnabled() ? this.GetFirstValidDayForAgenda(paramDate) : paramDate;
            }
        } else {
            start = moment();
        }

        if (this.AgendaTimeslotsModeEnabled()) {
            const minutes = this.GetSlotDurationForDateTime(start);
            end = moment(start).add('minutes', minutes);
        } else {
            end = moment(start).add('minutes', 30);
        }

        this.StartDate(start.toDate());
        this.StartTime(start.toDate());
        this.EndDate(end.toDate());
        this.EndTime(end.toDate());

        this.lastStartTime = start.toDate();
        this.lastEndTime = end.toDate();
    }

    private GetSlotDurationForDateTime(dateTime: Moment): number {
        if (!this.AgendaConfiguration())
            return 0;

        const agendaConfiguration: IAgendaConfiguration = this.AgendaConfiguration();
        if (agendaConfiguration.Timetables.length == 0)
            return 0;

        const timetablesForDate: ITimetableForAgendaConfiguration[] = agendaConfiguration.Timetables.filter((t: ITimetableForAgendaConfiguration) => {
            return moment(t.StartDate) <= dateTime && (!t.EndDate || dateTime < moment(t.EndDate));
        });

        if (timetablesForDate.length == 0)
            return 0;

        timetablesForDate.sort((a: ITimetableForAgendaConfiguration, b: ITimetableForAgendaConfiguration) => {
            if (moment(b.StartDate).diff(moment(a.StartDate)) == 0)
                return a.IsVariation ? -1 : 1;
            return moment(b.StartDate).diff(moment(a.StartDate));
        });

        const time: string = dateTime.format("LT");
        let result = 0;
        const dayOfWeek: number = dateTime.day();
        timetablesForDate[0].Timeslots.forEach((t: ITimeslotForAgendaConfiguration) => {
            if (t.DayOfWeek == dayOfWeek) {
                t.Intervals.forEach((i: ITimeslotsIntervalForConfiguration) => {
                    if (time >= this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(dateTime, moment(i.StartTime)) && time < this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(dateTime, moment(i.EndTime)))
                        result = i.EventsAvgDuration;
                });
            }
        });

        return result;
    }

    private GetFirstValidDayForAgenda(start: Moment): Moment {
        if (!this.AgendaConfiguration())
            return start;

        const agendaConfiguration: IAgendaConfiguration = this.AgendaConfiguration();
        if (agendaConfiguration.Timetables.length == 0)
            return start;

        const filteredTimetables: ITimetableForAgendaConfiguration[] = agendaConfiguration.Timetables.filter((t: ITimetableForAgendaConfiguration) => {
            return !t.EndDate || start < moment(t.EndDate);
        });

        if (filteredTimetables.length == 0)
            return start;
        let result: Moment = start;
        let found = false;
        filteredTimetables.forEach((t: ITimetableForAgendaConfiguration) => {
            if (!found) {
                const days: number[] = t.Timeslots.map((ts: ITimeslotForAgendaConfiguration) => ts.DayOfWeek);
                const dayOfWeek = start.day();

                if (days.indexOf(dayOfWeek) > -1) {
                    result = moment(start);
                    found = true;
                }

                let newDate: Moment = moment(start);
                let weeks = 0;
                while (!found && (!t.EndDate || newDate < moment(t.EndDate))) {
                    days.forEach((d: number) => {
                        if (!found) {
                            newDate = moment(newDate).day(d + (weeks * 7));
                            if (newDate > start) {
                                result = newDate;
                                found = true;
                            }
                        }
                    });
                    weeks++;
                }
            }
        });

        return result;
    }

    private CreateDatesIntervalsFromAgendasTimetables(timetables: ITimetableForAgendaConfiguration[]): any[] {
        const dates: Moment[] = [];
        const datesMap = {};

        timetables.forEach((t: ITimetableForAgendaConfiguration) => {
            const startDate = moment(t.StartDate);
            if (!datesMap[startDate.toDate().toString()]) {
                dates.push(moment(t.StartDate));
                datesMap[startDate.toDate().toString()] = startDate;
            }
            const endDate = !t.EndDate ? moment('2100-01-01T00:00:00').add('days', 1) : moment(t.EndDate).add('days', 1);
            if (!datesMap[endDate.toDate().toString()]) {
                dates.push(endDate);
                datesMap[endDate.toDate().toString()] = endDate;
            }
        });
        dates.sort((a: Moment, b: Moment) => { return a.diff(b, 'seconds'); });

        const intervals = [];
        for (let i = 0; i < dates.length - 1; i++) {
            intervals.push({ start: moment(dates[i]), end: moment(dates[i + 1]).add('days', -1) });
        }
        intervals.push({ start: dates[dates.length -1], end: null });

        return intervals;
    }

    private GetDateTimeForEvent(date: Date, time: Date): Date {
        if (!this.AgendaConfiguration() || !this.AgendaConfiguration().TimeslotsModeEnabled)
            return this.MergeDateAndTime(date, time);

        return date;
    }

    private MergeDateAndTime(date: Date, time: Date): Date {
        const timeComponents: number[] = moment(time).format("HH:mm").split(':').map((t: string) => parseInt(t));
        return moment(date).hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).toDate();
    }

    private ExtractEventId(eventId: any): number {
        if (typeof(eventId) == "number")
            return eventId;

        return parseInt(eventId.split("-")[1]);
    }
}

class CustomerForListViewModel implements ICustomerForListViewModel {

    public CustomerLabel: ko.Observable<string> = ko.observable();

    constructor(private CustomersManager: CustomersManager, private customer: ICustomerEnvelope) {
        this.CustomerLabel(this.customer.NameOrBusinessName);
    }

    public Edit(): void {
        this.CustomersManager.ShowCustomersDialog(this.customer.Id, true);
    }
}

export class TimeslotForEvent implements ITimeslotForEventViewModel {
    public Identifier: string;
    public Start: string;
    public End: string;
    public NumberOfEvents: ko.Observable<number> = ko.observable();
    public MaxEventsNumber: number;
    public EventsIds: number[] = [];

    public Selected: ko.Observable<boolean> = ko.observable();

    public Label: ko.Computed<string>;
    public IsFull: ko.Computed<boolean>;

    constructor(timeslot: ITimeslotForEvent, private parent: IAgendaEventViewModel) {
        this.Identifier = timeslot.Identifier;
        this.Start = timeslot.Start;
        this.End = timeslot.End;
        this.NumberOfEvents(timeslot.NumberOfEvents);
        this.MaxEventsNumber = timeslot.MaxEventsNumber;
        this.EventsIds = timeslot.EventsIds;

        this.Label = ko.computed(() => {
            return this.Start;
        });

        this.IsFull = ko.computed(() => {
            return this.NumberOfEvents() == this.MaxEventsNumber
                && (!this.parent.IsInitialTimeslot(this)
                    || this.parent.IsAborted()
                    || this.parent.IsOnWaitingList()
                    || this.EventsIds.indexOf(this.parent.Id()) < 0);
        });

        if (this.parent.IsInitialTimeslot(this))
            this.Selected(true);
    }

    public Select(): void {
        if (!this.IsFull())
            this.parent.SelectTimeslot(this);
    }
}