import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { TagsContextMenu } from "./TagsContextMenu";
import { FileRepositoryMainViewModel } from "./FileRepositoryMainViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IFileRepositoryService } from "../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ITagHint } from "../../ProlifeSdk/interfaces/files/ITagHint";

export class TagsExplorer
{
    private fileRepositoryService : IFileRepositoryService;

    public IsVisible : ko.Observable<boolean> = ko.observable(false);
    public IsSearching : ko.Observable<boolean> = ko.observable(false);

    private Tags : ko.ObservableArray<ITagHint> = ko.observableArray([]);
    private TagsContextMenu : TagsContextMenu;
    private TextFilter : ko.Observable<string> = ko.observable("");

    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor(serviceLocator : IServiceLocator, private parent : FileRepositoryMainViewModel)
    {
        this.fileRepositoryService = <IFileRepositoryService>serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);
        this.TagsContextMenu = new TagsContextMenu(serviceLocator, parent.doReload.bind(parent), parent.doReload.bind(parent));

        this.TextFilter.subscribe(this.Search.bind(this));
    }

    public Refresh()
    {
        this.Search();
    }

    private Search()
    {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.fileRepositoryService.SearchTags(this.TextFilter(), -1, -1).then((tags : ITagHint[]) => {
                this.IsSearching(this.TextFilter().length > 0);
                this.Tags(tags);
            });
        }, 500);
    }

    public Show()
    {
        this.Search();
        this.IsVisible(true);
    }

    public Hide()
    {
        this.IsVisible(false);
    }
}
