import * as ko from "knockout";
import { IDiscountsCatalogsService, IGetDiscountCatalogSuggestionsForArticleRequest, IResolvedCustomerDiscountCatalogArticleSuggestion, DiscountCatalogRowMode } from "../Warehouse/DiscountsCatalogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IDiscountCatalogSuggestionsForArticleDataSourceModel extends IDataSourceModel<number, IResolvedCustomerDiscountCatalogArticleSuggestion> {

}

export interface IDiscountCatalogSuggestionsForArticleDataSource extends IDataSource {

}

export class DiscountCatalogSuggestionsForArticleDataSource implements IDiscountCatalogSuggestionsForArticleDataSource {
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService : IDiscountsCatalogsService;

    private filter : IGetDiscountCatalogSuggestionsForArticleRequest = {};

    setFilter(filter: IGetDiscountCatalogSuggestionsForArticleRequest) {
        this.filter = filter;
    }

    getTitle(currentModel: IDiscountCatalogSuggestionsForArticleDataSourceModel): string {
        return "Suggerimenti Scontistiche";
    }    
    
    isGroupedData(currentModel: IDiscountCatalogSuggestionsForArticleDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IDiscountCatalogSuggestionsForArticleDataSourceModel, b: IDiscountCatalogSuggestionsForArticleDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IDiscountCatalogSuggestionsForArticleDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDiscountCatalogSuggestionsForArticleDataSourceModel[]> {
        if(skip != 0) return [];

        let suggestions = await this.discountsCatalogsService.GetDiscountCatalogSuggestionsForArticle(this.filter);
        return suggestions.map(this.createModelFor, this);
    }
    
    async getById(currentModel: IDiscountCatalogSuggestionsForArticleDataSourceModel, ids: (string | number)[]): Promise<IDiscountCatalogSuggestionsForArticleDataSourceModel[]> {
        return [];
    }

    private createModelFor(suggestion : IResolvedCustomerDiscountCatalogArticleSuggestion) : IDiscountCatalogSuggestionsForArticleDataSourceModel {
        Object.defineProperty(suggestion, 'ModeDescription', { value: DiscountCatalogRowMode.getDescription(suggestion.Mode) });

        return {
            id: suggestion.Id,
            title: suggestion.GroupName || "Scontistica Cliente",
            isLeaf: true,
            isGroup: false,
            model: suggestion
        };
    }
    
    setView(view: IDataSourceView): void {
        
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}