import * as ko from "knockout";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { TaskBoard } from "./ui/task-board/TaskBoard";
import { PlanningTaskBoard } from "./ui/task-board/PlanningTaskBoard";
import { LazyImport } from "../../Core/DependencyInjection";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication, IApplicationEmbeddedField } from "../../Desktop/interfaces/IApplication";
import { IAuthenticationServiceObserver } from "../../Core/interfaces/IAuthenticationServiceObserver";
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver";
import { INavBarAction, INavBarActionWithDropdown } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { IJobOrderGeneralSettingsManager } from "../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { IUserForClient } from "../../Core/interfaces/IPrincipal";

export class TaskBoardApplication
    implements IApplication, IAuthenticationServiceObserver, IAuthorizationServiceObserver
{
    authorizationLoaded(rights: any): void {
        // DEPRECATO
        /*
        var canSelectResource = this.authorizationService.isAuthorized("TaskBoard_SelectResource");
        this.embeddedFields()[0].IsVisible(canSelectResource);
        */
    }

    userLoggedIn(user: IUserForClient): void {
        if (!user) return;
        this.UserId(user.UserId);
    }

    userLoggedOut(): void {}

    loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any): void {}

    public templateName = "taskBoardOuter";
    public templateUrl = "todolist/templates/task-board";
    public templateData: ko.Observable<any> = ko.observable();
    public tileColor = "tile-blue";

    @LazyImport(ProlifeSdk.ApplicationsServiceType)
    private applicationsService!: IApplicationsService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);
    public navBarActions: ko.ObservableArray<INavBarAction | INavBarActionWithDropdown> = ko.observableArray([]);

    public embeddedFields: ko.ObservableArray<IApplicationEmbeddedField> = ko.observableArray([]);

    public UserId: ko.Observable<number> = ko.observable();

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService.registerApplication(this);
        this.authenticationService.addObserver(this);
        this.authorizationService.addObserver(this);

        this.embeddedFields.push({
            templateName: "resource-selection-field",
            templateUrl: "todolist/templates/task-board",
            templateData: this,
            IsVisible: ko.observable(false),
        });
    }

    getApplicationCode() {
        return ProlifeSdk.TaskBoardApplicationCode;
    }

    getName(): string {
        return ProlifeSdk.TextResources.Todolist.TaskBoard;
    }

    getIcon(): string {
        return "icon-list";
    }

    registerRoutes(): void {
        const opaService = <IOPAService>this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/TaskBoard", (context) => this.start());
    }

    getApplicationRoute(): string {
        return "#/TaskBoard";
    }

    onGoingDown(): void {
        this.templateData().dispose();
        //this.templateData(undefined);
        //delete this.templateData();
    }

    private start() {
        if (this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            //this.templateData(undefined);
            //delete this.templateData();
        }

        const generalSettings: IJobOrderGeneralSettingsManager = this.settingsService.findSettingsManager(
            ProlifeSdk.JobOrderGeneralSettings
        ) as IJobOrderGeneralSettingsManager;
        const taskboardStartMode = generalSettings.getTaskboardStartMode();

        if (taskboardStartMode === 0 || !this.authorizationService.isAuthorized("Allocations_ViewPlanningTaskboard"))
            this.templateData(new TaskBoard(this.serviceLocator, this.UserId));
        else this.templateData(new PlanningTaskBoard(this.serviceLocator, this.UserId));

        this.navBarActions([
            {
                Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewTask),
                CanRun: ko.computed(() => {
                    return (
                        this.templateData() &&
                        this.templateData().selectedWorkflow() != null &&
                        this.authorizationService.isAuthorized("TaskBoard_InsertTask")
                    );
                }),
                Icon: ko.observable("fa-plus"),
                Run: () => {
                    this.templateData() && this.templateData().CreateNewTask();
                },
                Actions: [
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewTaskFromTask),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() &&
                                this.templateData().selectedWorkflow() != null &&
                                this.authorizationService.isAuthorized("TaskBoard_InsertTask")
                            );
                        }),
                        Icon: ko.observable("fa-copy"),
                        Run: () => {
                            this.templateData() && this.templateData().NewTaskFromTask();
                        },
                    },
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewTaskFromTemplate),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() && this.authorizationService.isAuthorized("TaskBoard_InsertTask")
                            );
                        }),
                        Icon: ko.observable("fa-magic"),
                        Run: () => {
                            this.templateData() && this.templateData().NewTaskFromTemplate();
                        },
                    },
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.EditTask),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() &&
                                this.templateData().selectedTask() &&
                                this.templateData().selectedTask().task.IsTask &&
                                this.authorizationService.isAuthorized("TaskBoard_EditTask")
                            );
                        }),
                        Icon: ko.observable("fa-pencil"),
                        Run: () => {
                            this.templateData() && this.templateData().EditSelectedTask();
                        },
                    },
                ],
            },
            {
                Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflow),
                CanRun: ko.computed(() => {
                    return (
                        this.templateData() &&
                        this.templateData().selectedJobOrder &&
                        this.templateData().selectedJobOrder() != null &&
                        this.authorizationService.isAuthorized("TaskBoard_InsertWorkflow")
                    );
                }),
                Icon: ko.observable("fa-plus"),
                Run: () => {
                    this.templateData().CreateNewWorkflow();
                },
                Actions: [
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflowFromTemplate),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() &&
                                this.templateData().selectedJobOrder &&
                                this.templateData().selectedJobOrder() != null &&
                                this.authorizationService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
                            );
                        }),
                        Icon: ko.observable("fa-plus"),
                        Run: () => {
                            this.templateData().CreateNewWorkflowFromTemplate();
                        },
                    },
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() &&
                                this.templateData().selectedJobOrder &&
                                this.templateData().selectedJobOrder() != null &&
                                this.templateData() instanceof TaskBoard &&
                                this.authorizationService.isAuthorized("TaskBoard_CloneWorkflow")
                            );
                        }),
                        Icon: ko.observable("fa-copy"),
                        Run: () => {
                            this.templateData() && this.templateData().CreateNewWorkflowFromWorkflow();
                        },
                    },
                    {
                        Name: ko.observable(ProlifeSdk.TextResources.Todolist.EditWorkflow),
                        CanRun: ko.computed(() => {
                            return (
                                this.templateData() &&
                                this.templateData().selectedWorkflow() != null &&
                                this.authorizationService.isAuthorized("TaskBoard_EditWorkflow")
                            );
                        }),
                        Icon: ko.observable("fa-pencil"),
                        Run: () => {
                            this.templateData() && this.templateData().EditSelectedWorkflow();
                        },
                    },
                ],
            },
        ]);

        if (this.desktopService.isMobileBrowser()) {
            this.navBarActions([
                {
                    Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewTask),
                    CanRun: ko.computed(() => {
                        return (
                            this.templateData() &&
                            this.templateData().selectedWorkflow() != null &&
                            this.authorizationService.isAuthorized("TaskBoard_InsertTask")
                        );
                    }),
                    Icon: ko.observable("fa-plus"),
                    Run: () => {
                        this.templateData().CreateNewTask();
                    },
                },
                {
                    Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflow),
                    CanRun: ko.computed(() => {
                        return (
                            this.templateData() &&
                            this.templateData().selectedJobOrder &&
                            this.templateData().selectedJobOrder() != null &&
                            this.authorizationService.isAuthorized("TaskBoard_InsertWorkflow")
                        );
                    }),
                    Icon: ko.observable("fa-plus"),
                    Run: () => {
                        this.templateData().CreateNewWorkflow();
                    },
                },
                {
                    Name: ko.observable(ProlifeSdk.TextResources.Todolist.EditWorkflow),
                    CanRun: ko.computed(() => {
                        return (
                            this.templateData() &&
                            this.templateData().selectedWorkflow() != null &&
                            this.authorizationService.isAuthorized("TaskBoard_EditWorkflow")
                        );
                    }),
                    Icon: ko.observable("fa-pencil"),
                    Run: () => {
                        this.templateData().EditSelectedWorkflow();
                    },
                },
            ]);
        }

        if (this.authorizationService.isAuthorized("Allocations_ViewPlanningTaskboard")) {
            this.navBarActions.push({
                Name: ko.observable(ProlifeSdk.TextResources.Todolist.PlanningView),
                CanRun: ko.computed(() => {
                    return this.templateData() && this.templateData() instanceof TaskBoard;
                }),
                Icon: ko.observable("fa-shopping-cart"),
                Right: true,
                Run: () => {
                    this.templateData().dispose && this.templateData().dispose();
                    //this.templateData(undefined);
                    //delete this.templateData();

                    this.templateData(new PlanningTaskBoard(this.serviceLocator, this.UserId));
                },
            });
            this.navBarActions.push({
                Name: ko.observable(ProlifeSdk.TextResources.Todolist.ProjectsView),
                CanRun: ko.computed(() => {
                    return this.templateData() && this.templateData() instanceof PlanningTaskBoard;
                }),
                Icon: ko.observable("fa-tasks"),
                Right: true,
                Run: () => {
                    this.templateData().dispose && this.templateData().dispose();
                    //this.templateData(undefined);
                    //delete this.templateData();

                    this.templateData(new TaskBoard(this.serviceLocator, this.UserId));
                },
            });
        }

        this.applicationsService.startApplication(this);
    }
}
