import * as ko from "knockout";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListActivitiesTagsProvider } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";

export class TodoListTaskTagsProvider implements ITodoListActivitiesTagsProvider
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    constructor() {}

    public SearchLabels(filter : string) : Promise<string[]>
    {
        return this.todoListService.SearchLabels(filter);
    }

}