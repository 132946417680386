import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 25/08/2016
 * Time: 18:09
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { JobOrderBudget as JobOrderBudgetExt } from "../JobOrderBudget";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class JobOrderBudgetFactory implements IJobOrderEditorPanelFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderBudget(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("JobOrders_BudgetRequest_View");
    }

}

class JobOrderBudget implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Budget);
    TemplateUrl = "joborder/templates/joborderdetail/budget-assignment";
    TemplateName = "budget";

    public BudgetSection : JobOrderBudgetExt;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {
        this.BudgetSection = new JobOrderBudgetExt(serviceLocator);
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
        this.BudgetSection.Dispose();
    }

    @Delay()
    load(): Promise<boolean> {
        this.BudgetSection.Initialize(this.editor.JobOrderId);
        return Promise.resolve<boolean>(true);
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() :Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }
    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) { this.load(); }

    OnWorkflowChanged(workflowId:number) { this.load(); }

    OnWorkflowCreated(workflowId:number) { this.load(); }

    OnWorkflowImportedFromTemplate(workflowId:number) { this.load(); }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}
}