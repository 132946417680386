import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IFamiliesService, IFamily, IStatisticFamily } from "../ProlifeSdk/interfaces/warehouse/IFamiliesService";

class FamiliesService implements IFamiliesService {
    private ajaxService : IAjaxService;

    constructor(serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IFamiliesService>(), this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType() : string{
        return ProlifeSdk.FamiliesServiceType;
    }

    isOfType(serviceType: string) : boolean{
        return serviceType == this.getServiceType();
    }

    getFamilyById(familyId : number) : Promise<IFamily> {
        if(!familyId) {
            return Promise.reject<IFamily>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "Families/GetFamilyById/" + familyId, {});
    }

    getFamilies(searchFilter : string, skip : number, count : number) : Promise<IFamily[]> {
        return this.ajaxService.Get("Warehouse-api", "Families/Search?filter=" + encodeURI($.trim(searchFilter)) + "&skip=" + skip + "&count=" + count, {});
    }

    GetStatisticFamilies(manufacturerId: number | null, textFilter: string | null, skip: number | null, count: number | null): Promise<IStatisticFamily[]> {
        return this.ajaxService.Post<IStatisticFamily[]>("Warehouse-api/Families", "GetStatisticFamilies", {
            background: true,
            methodData: {
        		manufacturerId: manufacturerId,
        		textFilter: textFilter,
        		skip: skip,
        		count: count,
        	}
        });
    }

    GetStatisticFamiliesByIds(ids: number[] | null): Promise<IStatisticFamily[]> {
        return this.ajaxService.Post<IStatisticFamily[]>("Warehouse-api/Families", "GetStatisticFamiliesByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new FamiliesService(serviceLocator);
}