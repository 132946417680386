import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/02/2018
 * Time: 09:55
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAgendasProvider, IAgendaService, IAgendaEnvelope } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IFestivitiesAgendasService } from "../../../../ProlifeSdk/interfaces/agenda/IFestivitiesAgendasService";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

export class AgendasProvider implements IAgendasProvider, IControlsEntityProvider {
    private infoToastService: IInfoToastService;
    private agendasService: IAgendaService;
    private festivitieasAgendasService: IFestivitiesAgendasService;
    private lastTimeout: ReturnType<typeof setTimeout>;
    private isLoading: boolean = false;

    constructor(private serviceLocator: IServiceLocator) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.festivitieasAgendasService = <IFestivitiesAgendasService> this.serviceLocator.findService(ProlifeSdk.FestivitiesAgendaServiceCode);
    }

    GetAgendas(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.agendasService.GetAgendasEnvelopesLimitedList(query.term)
                .then((agendas: IAgendaEnvelope[]) => {
                    query.callback({
                        results: agendas.map((a: IAgendaEnvelope) => { return { id: a.Id, text: a.Name } })
                    });
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendasError);
                });
        }, 500);
    }

    GetAgenda(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {
        var agendaId: number = parseInt(<string>$(element).val());
        if (agendaId != null && !this.isLoading && !isNaN(agendaId)) {
            this.isLoading = true;

            this.agendasService.GetAgendaEnvelope(agendaId)
                .then((agenda: IAgendaEnvelope) => {
                    callback({ id: agenda.Id, text: agenda.Name });
                    this.isLoading = false;
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendasError);
                    this.isLoading = false;
                });
        }
    }

    GetFestivitiesAgendas(query: ISelect2Query) {

    }

    GetFestivitiesAgenda(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {

    }

    findEntities(query: ISelect2Query) {
        this.GetAgendas(query);
    }

    findEntity(element, callback) {
        this.GetAgenda(element, callback);
    }
}