import * as ko from "knockout";
/*
 * Created by JetBrains WebStorm.
 * User: n.angeli
 * Date: 11/04/13
 * Time: 17.27
 */


import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class RoleEntityProvider implements IEntityProvider<number, IUserCharacter> {

	private userCharactersSettingsManager : IUserCharactersSettingsManager;

	constructor(private serviceLocator : IServiceLocator ) {

		var entityProviderService : IEntityProviderService= <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
		entityProviderService.registerEntityProvider(this, this.getType());
		var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
		this.userCharactersSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
	}

	getType() : string
	{
		return ProlifeSdk.RolesEntityType;
	}

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Users.Role;
    }

	getPkValue(item : IUserCharacter) : number
	{
		return item.IdUserCharacter;
	}

    getDisplayName(item : IUserCharacter) : string
    {
        return item.Description;
    }

	getDisplayNameForKeyValue(pkValue : any) : Promise<string>
	{
		var dfd = new Deferred<string>();

        var userCharacter : IUserCharacter = this.userCharactersSettingsManager.getUserCharacterById(pkValue);
        if(userCharacter)
            dfd.resolve(this.userCharactersSettingsManager.getUserCharacterById(pkValue).Description);
        else
            dfd.resolve("");

		return dfd.promise();
	}

    hintSearch(stringFilter: string): Promise<IUserCharacter[]>
    {
        var dfd = new Deferred<IUserCharacter[]>();
        dfd.resolve(this.userCharactersSettingsManager.getUserCharacters()
            .filter((c : IUserCharacter) => c.Description.toLowerCase().indexOf(stringFilter.toLowerCase()) > -1));
        return dfd.promise();
    }

    getControlsProvider() : IControlsEntityProvider
    {
        //Da implementare se necessario
        return null;
    }

    getEntity(pkValue : number) : Promise<IUserCharacter>
    {
        return Promise.resolve<IUserCharacter>(this.userCharactersSettingsManager.getUserCharacterById(pkValue));
    }
}