import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/05/2017
 * Time: 10:12
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";

import * as moment from "moment";
import { ResourceReallocationDialog } from "./dialogs/ResourceReallocationDialog";
import { IResourceAllocationRangeViewModel, IResourceOperationalUnitViewModel, ITeamsManagerProvider } from "./TeamsManager";
import { IAllocationsService, IResourceAllocationRange } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IRangeSelectionObserver } from "../../interfaces/IRangeSelectionObserver";
import { IAllocationPerDays } from "../../interfaces/IAllocationPerDays";

export class ResourceAllocationRangeViewModel implements IResourceAllocationRangeViewModel {
    StartDate : ko.Observable<Date> = ko.observable();
    EndDate : ko.Observable<Date> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    Deleted : ko.Observable<boolean> = ko.observable(false);
    HasChanged : ko.Observable<boolean> = ko.observable(false);
    Selected: ko.Observable<boolean> = ko.observable(false);

    HoursMonday : ko.Observable<number> = ko.observable();
    HoursTuesday : ko.Observable<number> = ko.observable();
    HoursWednesday : ko.Observable<number> = ko.observable();
    HoursThursday : ko.Observable<number> = ko.observable();
    HoursFriday : ko.Observable<number> = ko.observable();
    HoursSaturday : ko.Observable<number> = ko.observable();
    HoursSunday : ko.Observable<number> = ko.observable();

    IsEditing : ko.Observable<boolean> = ko.observable();

    AllocationMode: ko.Observable<number> = ko.observable();
    AllocationModeForEditing: ko.Observable<number> = ko.observable();
    PercentageAllocationEnabled: ko.Computed<boolean>;
    HoursAllocationEnabled: ko.Computed<boolean>;

    private selectionObservers: IRangeSelectionObserver[] = [];

    private oldStartDate : Date;
    private oldEndDate : Date;
    private oldAmount : number;
    private oldHoursMonday : number;
    private oldHoursTuesday : number;
    private oldHoursWednesday : number;
    private oldHoursThursday : number;
    private oldHoursFriday : number;
    private oldHoursSaturday : number;
    private oldHoursSunday : number;
    private oldAllocationMode : number;

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private allocationsService : IAllocationsService;

    constructor(private serviceLocator : IServiceLocator, private resourceAllocation : IResourceAllocationRange, private operationalUnitId : number, private parent: IResourceOperationalUnitViewModel = null, public resourceId: number = 0, public resourceName: string = "", public teamsManagerProvider: ITeamsManagerProvider = null) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.allocationsService = <IAllocationsService> this.serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);

        this.StartDate(resourceAllocation.StartDate);
        this.EndDate(resourceAllocation.EndDate);
        this.Amount(resourceAllocation.Amount);
        this.HoursMonday(resourceAllocation.Monday);
        this.HoursTuesday(resourceAllocation.Tuesday);
        this.HoursWednesday(resourceAllocation.Wednesday);
        this.HoursThursday(resourceAllocation.Thursday);
        this.HoursFriday(resourceAllocation.Friday);
        this.HoursSaturday(resourceAllocation.Saturday);
        this.HoursSunday(resourceAllocation.Sunday);

        if(!resourceAllocation.Id || resourceAllocation.Id <= 0)
            this.HasChanged(true);

        this.PercentageAllocationEnabled = ko.computed(() => {
            return this.AllocationModeForEditing() == ProlifeSdk.ResourceAllocationByPercentage;
        });

        this.HoursAllocationEnabled = ko.computed(() => {
            return this.AllocationModeForEditing() == ProlifeSdk.ResourceAllocationByHours;
        });

        this.AllocationMode(resourceAllocation.AllocationType);
        this.AllocationModeForEditing(resourceAllocation.AllocationType);
    }

    public IsIntersecting(startDate : Date, endDate : Date) : boolean {
        return !(moment(this.StartDate()) > moment(endDate) || moment(this.EndDate()) < moment(startDate));

        /*return moment(this.StartDate()) <= moment(startDate) && moment(this.EndDate()) >= moment(startDate) ||
               moment(this.StartDate()) <= moment(endDate) && moment(this.EndDate()) >= moment(endDate) ||
               moment(this.StartDate()) >= moment(startDate) && moment(this.EndDate()) <= moment(endDate);*/
    }

    public IsInRange(date : Date) : boolean {
        return moment(this.StartDate()) <= moment(date) && moment(this.EndDate()) >= moment(date);
    }

    public IsStartInRange(startDate : Date, endDate : Date) : boolean {
        return moment(startDate) <= moment(this.StartDate()) && moment(endDate) >= moment(this.StartDate());
    }

    public IsEndInRange(startDate : Date, endDate : Date) : boolean {
        return moment(startDate) <= moment(this.EndDate()) && moment(endDate) >= moment(this.EndDate());
    }

    public IsFullyInRange(startDate : Date, endDate : Date) : boolean {
        return moment(this.StartDate()) >= moment(startDate) && moment(this.EndDate()) <= moment(endDate);
    }

    public Edit()
    {
        this.IsEditing(true);
        this.oldStartDate = this.StartDate();
        this.oldEndDate = this.EndDate();
        this.oldAmount = this.Amount();
        this.oldHoursMonday = this.HoursMonday();
        this.oldHoursTuesday = this.HoursTuesday();
        this.oldHoursWednesday = this.HoursWednesday();
        this.oldHoursThursday = this.HoursThursday();
        this.oldHoursFriday = this.HoursFriday();
        this.oldHoursSaturday = this.HoursSaturday();
        this.oldHoursSunday = this.HoursSunday();
        this.oldAllocationMode = this.AllocationMode();
    }

    private VerifyRange() : boolean {
        const startDate = this.StartDate();
        const endDate = this.EndDate();

        if (!startDate) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoStartDateSelected);
            return false;
        }

        if (!endDate) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoEndDateSelected);
            return false;
        }

        if (moment(endDate).startOf("day") < moment(startDate).startOf("day")) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.InvalidRange);
            return false;
        }

        if (moment(startDate).startOf('day').diff(moment().startOf("day"), "days") > 365 * 2) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.InvalidResourceAllocationStartDate);
            return false;
        }

        if (moment(endDate).startOf('day').diff(moment().startOf("day"), "days") > 365 * 2) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.InvalidResourceAllocationEndDate);
            return false;
        }

        if (!this.Amount() && this.AllocationModeForEditing() == ProlifeSdk.PercentageEditigMode) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoAmountSelected);
            return false;
        }

        if (this.AllocationModeForEditing() == ProlifeSdk.HoursEditigMode && !this.VerifyHours()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoHoursSelected);
            return false;
        }

        if (!this.parent.VerifyServiceOrders(this.StartDate(), this.EndDate())) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.MissingServiceOrderOnResourceAllocationRangeCreation);
            return false;
        }

        return true;
    }

    private VerifyHours(): boolean {
        return this.HoursMonday() > 0 || this.HoursTuesday() > 0 || this.HoursWednesday() > 0 || this.HoursThursday() > 0 || this.HoursFriday() > 0 || this.HoursSaturday() > 0 || this.HoursSunday() > 0;
    }

    public Save()
    {
        if(!this.VerifyRange())
            return;

        const intersectingRanges = this.parent.AllocationRanges().filter((a : ResourceAllocationRangeViewModel) => { return a.IsIntersecting(this.StartDate(), this.EndDate()) && this.resourceAllocation.Id != a.resourceAllocation.Id; });
        if(intersectingRanges.length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.RangeIntersecting);
            return;
        }

        this.AllocationMode(this.AllocationModeForEditing());
        this.IsEditing(false);
        this.HasChanged(true);

        this.teamsManagerProvider.SynchronizeResourceRanges(this.resourceId, this.resourceAllocation.TeamResourceAllocationId, this.operationalUnitId);
    }

    public Cancel()
    {
        this.StartDate(this.oldStartDate);
        this.EndDate(this.oldEndDate);
        this.Amount(this.oldAmount);
        this.HoursMonday(this.oldHoursMonday);
        this.HoursTuesday(this.oldHoursTuesday);
        this.HoursWednesday(this.oldHoursWednesday);
        this.HoursThursday(this.oldHoursTuesday);
        this.HoursFriday(this.oldHoursFriday);
        this.HoursSaturday(this.oldHoursSaturday);
        this.HoursSunday(this.oldHoursSunday);
        this.AllocationMode(this.oldAllocationMode);
        this.IsEditing(false);
    }

    public Select(): void {
        this.selectionObservers.forEach((o: IRangeSelectionObserver) => {
            o.ResetRangeSelection();
        });
        this.Selected(true);
    }

    public RegisterSelectionObserver(observer: IRangeSelectionObserver): void {
        this.selectionObservers.push(observer);
    }

    public getData(allocationId : number) : IResourceAllocationRange {
        const range : IResourceAllocationRange = {
            Id: this.resourceAllocation.Id,
            TeamResourceAllocationId: allocationId,
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            Amount: this.Amount(),
            Monday: this.HoursMonday(),
            Tuesday: this.HoursTuesday(),
            Wednesday: this.HoursWednesday(),
            Thursday: this.HoursThursday(),
            Friday: this.HoursFriday(),
            Saturday: this.HoursSaturday(),
            Sunday: this.HoursSunday(),
            OperationalUnitId: this.operationalUnitId,
            AllocationType: this.AllocationMode()
        };

        return range;
    }

    public SwitchAllocationMode(): void {
        this.AllocationModeForEditing(this.AllocationModeForEditing() == ProlifeSdk.ResourceAllocationByPercentage ? ProlifeSdk.ResourceAllocationByHours : ProlifeSdk.ResourceAllocationByPercentage);
    }

    public RelocateResource(): void {
        this.Cancel();
        const allocationPerDays: IAllocationPerDays = this.AllocationMode() == ProlifeSdk.ResourceAllocationByPercentage ?
            null :
        {
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday()
        };

        const selectedTeamId: number = this.teamsManagerProvider.GetTeamFromResourceAllocationId(this.resourceAllocation.TeamResourceAllocationId).Id();

        const reallocationDialog = new ResourceReallocationDialog(
            this.serviceLocator,
            selectedTeamId,
            this.resourceId,
            this.parent.OperationalUnit,
            this.StartDate(),
            this.EndDate(),
            this.Amount(),
            allocationPerDays,
            this.resourceName,
            this.teamsManagerProvider
        );

        this.dialogsService.ShowModal<ITeamsManagerProvider>(reallocationDialog, "fullscreen minheight800 minwidth1280", null, "allocations/templates/dialogs", "resource-reallocation-dialog")
            .then((teamsManager: ITeamsManagerProvider) => {
                if (!teamsManager)
                    return;
                this.parent.UpdateAllocationsRanges(teamsManager);
            });
    }

    public Clone(operationalUnit: IResourceOperationalUnitViewModel, teamsManagerProvider: ITeamsManagerProvider, assignNewId  = false): ResourceAllocationRangeViewModel {
        let resourceAllocation = this.resourceAllocation;

        if(assignNewId) {
            resourceAllocation = <IResourceAllocationRange> $.extend({}, this.resourceAllocation);
            resourceAllocation.Id = this.allocationsService.GenerateNextId();
        }

        const clonedRange: ResourceAllocationRangeViewModel =  new ResourceAllocationRangeViewModel(this.serviceLocator, resourceAllocation, this.operationalUnitId, operationalUnit, this.resourceId, this.resourceName, teamsManagerProvider);

        clonedRange.StartDate(this.StartDate());
        clonedRange.EndDate(this.EndDate());
        clonedRange.Amount(this.Amount());
        clonedRange.HoursMonday(this.HoursMonday());
        clonedRange.HoursTuesday(this.HoursTuesday());
        clonedRange.HoursWednesday(this.HoursWednesday());
        clonedRange.HoursThursday(this.HoursThursday());
        clonedRange.HoursFriday(this.HoursFriday());
        clonedRange.HoursSaturday(this.HoursSaturday());
        clonedRange.HoursSunday(this.HoursSunday());
        clonedRange.AllocationMode(this.AllocationMode());
        clonedRange.AllocationModeForEditing(this.AllocationModeForEditing());

        return clonedRange;
    }
}