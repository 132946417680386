import * as ko from "knockout";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import { SearchContext } from "./SearchContext";
import { SearchResult } from "./SearchResult";
import { FilterWithMatches } from "./objects/FilterWithMatches";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { IService } from "../interfaces/IService";
import { IFiltersEngine } from "../interfaces/IFiltersEngine";
import { ISearchContext } from "../interfaces/ISearchContext";
import { ISearchResult } from "../interfaces/ISearchResult";
import { IWordFound } from "../interfaces/IWordFound";
import { IFilterWithValue } from "../interfaces/IFilterWithValue";
import { ISearchTagFilter } from "../../ProlifeSdk/interfaces/ISearchTagFilter";

class FiltersEngine implements IFiltersEngine, IService {
    constructor(serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IFiltersEngine>(), this)
    }

    getServiceType() : string {
        return ServiceTypes.Search;
    }

    isOfType(serviceType: string) : boolean {
        return serviceType == this.getServiceType();
    }

    CreateContext() : ISearchContext{
        return new SearchContext();
    }

    public Search(context : ISearchContext, text : string) : ISearchResult {
        //Applico i filtri selezionati
        var allMatches = this.MatchAll(context.items, context, context.appliedFilters);
        return this.AtLeastOneMatch(allMatches, context, text);
    }

    private AtLeastOneMatch(objects : any[], context : ISearchContext, text : string) : ISearchResult {
        var results : ISearchResult = new SearchResult(context);

        if(!text) {
            results.allMatches = objects;
            return results;
        }

        var wordsFoundIntoMatches = [];
        var filters = context.getFiltersChain();

        for(var filC = 0; filC < filters.length; filC++)
        {
            var filter = filters[filC];
            var filterWithMatches = new FilterWithMatches(filter);

            for(var objC = 0; objC < objects.length; objC++)
            {
                var obj = objects[objC];
                var objToCheck = context.getValueFor(obj);

                if(!filter.Filter(objToCheck, text))
                    continue;

                var matchedValue = filter.GetValue(objToCheck);

                results.addMatch(obj);
                results.addFilterMatch(filterWithMatches);
                matchedValue.forEach((match : string) => filterWithMatches.addMatch(match));

                var wordsThatMatch = filter.FindWordThatMatch(objToCheck, text);
                wordsThatMatch.forEach((wordThatMatch : IWordFound) => {
                    if(wordThatMatch != null && wordsFoundIntoMatches.indexOf(wordThatMatch.word) < 0 && results.wordsFound.length < 5)
                    {
                        results.addMatchedWord(wordThatMatch);
                        wordsFoundIntoMatches.push(wordThatMatch.word);
                    }
                });
            }
        }

        return results;
    }

    private MatchAll(objects : any[], context : ISearchContext, filtersWithValues : IFilterWithValue[]) : any[] {
        var results = [];

        if(filtersWithValues == null || filtersWithValues == undefined || filtersWithValues == [])
            return results;

        for(var objC=0;objC<objects.length;objC++)
        {
            var obj = objects[objC];
            var objToCheck = context.getValueFor(obj);
            var matchAll = true;

            for(var filC=0;filC<filtersWithValues.length;filC++)
            {
                var filter = filtersWithValues[filC];

                if(!filter.value)
                    continue;

                matchAll = matchAll && filtersWithValues[filC].filter().Filter(objToCheck, filtersWithValues[filC].value);
            }

            if(matchAll && results.indexOf(obj) == -1)
                results.push(obj);
        }

        return results;
    }

    ExtractIdListFromTags(tags: ISearchTagFilter[], valueType: string): number[] {
		let filteredTags = tags.filter((t) => t.ValueTypeId == valueType);

		let result: number[] = [];

		filteredTags.forEach((ft) => {
			ft.Values.forEach(v => { 
				let id = parseInt(v); 
				if (!isNaN(id)) 
					result.push(id); 
			});
		});

		return result;
	}
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new FiltersEngine(serviceLocator);
}