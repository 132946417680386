import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import * as EnjoyHint from "enjoyhint";
import { PageSwitcher } from "../../../ProlifeSdk/prolifesdk/PageSwitcher";
import { LazyImport } from "../../../Core/DependencyInjection";
import {
    SuggestedWorkableElementsDataSource,
    ITaskElementNavigationMenuComponentModel,
} from "./navigation/SuggestedWorkableElementsDataSource";
import { DateDataSource, IDayNavigationMenuComponentModel } from "./navigation/DateDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import {
    INavigationMenuComponentTemplatesProvider,
    INavigationMenuComponentParameters,
    INavigationMenuComponent,
    INavigationMenuComponentModel,
    INavigationMenuComponentActionsGroup,
    INavigationMenuComponentAction,
} from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { ISuggestedWorkableElementsDataSource } from "../../interfaces/ISuggestedWorkableElementsDataSource";
import {
    IWorkedHoursEditorListener,
    IWorkedHoursEditorParameters,
    IWorkedHoursEditor,
} from "../../interfaces/IWorkedHoursEditor";
import {
    IWorkedHoursForEditing,
    ISuggestedWorkableElement,
} from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationHost } from "../../../Desktop/interfaces/IApplicationHost";
import { IPageSwitcherObserver } from "../../../ProlifeSdk/interfaces/prolife-sdk/IPageSwitcherObserver";
import { IPageConfigurationMap } from "../../../ProlifeSdk/interfaces/IPageConfigurationMap";
import { _WorkedHoursEditor } from "./editor/WorkedHoursEditor";
import {
    WorkedHoursJobOrderDateDataSource,
    IDayNavigationMenuComponentModel as IJobOrderDayNavigationMenuComponentModel,
} from "../../../DataSources/WorkedHoursJobOrderDateDataSource";
import { DatesMenuTemplateProvider } from "./navigation/DatesMenuTemplateProvider";
import {
    IJobOrderForTaskBoardDataSourceModel,
    ITaskForTaskBoardDataSourceModel,
    IWorkflowForTaskBoardDataSourceModel,
    JobOrdersWorkflowsAndTasksDataSource,
} from "../../../DataSources/JobOrdersWorkflowsAndTasksDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { AlertsLegendUI } from "../../../Components/AlertsLegend";
import { WorkflowsMenuSearchModes } from "../../../Todolist/Todolist/enums/WorkflowsMenuSearchModes";

export class WorkedHoursPanelViewModel
    implements IPageSwitcherObserver, IDataSourceListener, IWorkedHoursEditorListener
{
    public templateName = "workedhours-panel";
    public templateUrl = "workedhours/templates/panels";

    public workedHoursEditorParameters: IWorkedHoursEditorParameters;
    private editor: IWorkedHoursEditor;

    public suggestedWorkableElementsParameters: INavigationMenuComponentParameters;
    private suggestedWorkableElementsMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private suggestedWorkableElementsDataSource: ISuggestedWorkableElementsDataSource;
    private jobOrdersWorkflowsAndTasksDataSource: JobOrdersWorkflowsAndTasksDataSource;

    private ShowingSecondaryActionsOnMenu = ko.observable(false);
    private IsShowingWorkflowsOnMenu = ko.observable(false);
    private IsShowingTasksOnMenu = ko.observable(false);
    private selectedJobOrder: number;
    private selectedWorkflow: number;

    public datesMenuParameters: INavigationMenuComponentParameters;
    private datesMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private datesDataSource: DateDataSource;

    public jobOrderDatesMenuParameters: INavigationMenuComponentParameters;
    private jobOrderDatesMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private jobOrderDatesDataSource: WorkedHoursJobOrderDateDataSource;

    private datesMenuTemplateProvider: INavigationMenuComponentTemplatesProvider;

    public ShowingSuggestions: ko.Observable<boolean> = ko.observable(true);
    public ShowingActivities: ko.Observable<boolean> = ko.observable(false);

    public ResourceId: ko.Observable<number> = ko.observable();
    public ShowClosed: ko.Observable<boolean> = ko.observable(false);
    public ShowPreferred: ko.Observable<boolean> = ko.observable(true);
    public MonthlyImbalance: ko.Observable<number> = ko.observable(0);
    public MonthlyExtraordinary: ko.Observable<number> = ko.observable(0);
    public RefreshResourceField: ko.Observable<boolean> = ko.observable(false);

    public WorkedHoursPageSwitcherHandler: PageSwitcher;
    public pageSwitcherMap: IPageConfigurationMap = {};

    @LazyImport(ProlifeSdk.ApplicationHostServiceType)
    private applicationHostService!: IApplicationHost;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService!: IAuthorizationService;
    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    private pendingRefreshRequest = false;
    private requestedDate: Date = null;
    private requestedResource: number = null;
    private requestedJobOrder: number = null;
    private requestedTask: number = null;
    private multipleInsertEnabled = false;
    private wasMultipleInsertEnabled = false;
    private componentsAreInitialized: ko.Computed<boolean>;

    public get insertPerJobOrderMode(): boolean {
        return this.insertByJobOrder;
    }

    constructor(date: Date, resourceId: number, private insertByJobOrder: boolean = false, jobOrderId: number = null) {
        /*----Per versione mobile----*/

        this.WorkedHoursPageSwitcherHandler = new PageSwitcher();
        this.WorkedHoursPageSwitcherHandler.addPage(
            ProlifeSdk.Page_SelectActivity,
            true,
            ProlifeSdk.TextResources.WorkedHours.SelectActivityPageTitle,
            false
        );
        this.WorkedHoursPageSwitcherHandler.addPage(ProlifeSdk.Page_Calendar, false, "", false);
        this.WorkedHoursPageSwitcherHandler.addPage(
            ProlifeSdk.Page_ModifyActivity,
            false,
            ProlifeSdk.TextResources.WorkedHours.ModifyActivityPageTitle,
            false
        );
        this.WorkedHoursPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Suggestions,
            false,
            ProlifeSdk.TextResources.WorkedHours.SuggestionsPageTitle,
            false,
            "next",
            false,
            true
        );
        this.WorkedHoursPageSwitcherHandler.registerObserver(this);

        this.pageSwitcherMap[ProlifeSdk.Page_Calendar] = {
            onGoingBack: this.onShowCalendar.bind(this),
            LeftMenuEnabled: true,
            RightMenuEnabled: false,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_SelectActivity] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_ModifyActivity] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_Suggestions] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.applicationHostService.SetSideMenuEnabled(true, true);
        /*---------------------------*/

        this.workedHoursEditorParameters = {
            resourceId: resourceId,
            jobOrderId: (jobOrderId ?? -1) <= 0 ? null : jobOrderId,
            date: date,
            listeners: this,
            canEditOtherUsersWorkedHours: this.authorizationsService.isAuthorized(
                "WorkedHours_CanEditOtherUsersWorkSheets"
            ),
            insertPerJobOrder: this.insertPerJobOrderMode,
        };

        this.editor = new _WorkedHoursEditor(this.workedHoursEditorParameters);

        this.componentsAreInitialized = ko.computed(() => {
            const datesMenu = this.datesMenu();
            const jobOrderDatesMenu = this.jobOrderDatesMenu();

            return (!!datesMenu && !this.insertByJobOrder) || (!!jobOrderDatesMenu && this.insertByJobOrder);
        });

        this.componentsAreInitialized.subscribe((value: boolean) => {
            if (!value || !this.pendingRefreshRequest) return;

            this.internalRefresh(this.requestedDate, this.requestedResource, this.requestedJobOrder);

            if (!this.suggestedWorkableElementsMenu()) return;

            this.pendingRefreshRequest = false;
        });

        this.suggestedWorkableElementsMenu.subscribe((menu: INavigationMenuComponent) => {
            this.configureMenuLegendAction();
            this.configureMenuWorkflowActions();
            this.configureMenuTasksActions();
            this.configureMenuSearchActions();

            if (!this.pendingRefreshRequest) return;

            this.initializeSuggestionsMenu(this.requestedDate, this.requestedResource);

            if (!this.componentsAreInitialized()) return;

            this.pendingRefreshRequest = false;
        });

        this.datesMenuTemplateProvider = new DatesMenuTemplateProvider();

        this.suggestedWorkableElementsDataSource = new SuggestedWorkableElementsDataSource();
        this.jobOrdersWorkflowsAndTasksDataSource = new JobOrdersWorkflowsAndTasksDataSource();
        this.suggestedWorkableElementsParameters = {
            DataSource: ko.observable(this.suggestedWorkableElementsDataSource),
            Listeners: this,
            InjectTo: this.suggestedWorkableElementsMenu,
            DisableSelection: true,
        };

        this.datesDataSource = new DateDataSource();
        this.datesMenuParameters = {
            DataSource: this.datesDataSource,
            Listeners: this,
            InjectTo: this.datesMenu,
            DisableSearch: true,
            TemplatesProvider: this.datesMenuTemplateProvider,
        };

        this.jobOrderDatesDataSource = new WorkedHoursJobOrderDateDataSource();
        this.jobOrderDatesMenuParameters = {
            DataSource: this.jobOrderDatesDataSource,
            Listeners: this,
            InjectTo: this.jobOrderDatesMenu,
            DisableSearch: true,
            TemplatesProvider: this.datesMenuTemplateProvider,
        };

        setTimeout(() => this.configureAndRunTutorial(), 5000);
    }

    private configureAndRunTutorial() {
        if (localStorage.getItem("WorkedHoursPanelViewModelTutorial1") || this.desktopService.isMobileBrowser()) return;

        const tutorial = new EnjoyHint({
            onEnd: () => {
                localStorage.setItem("WorkedHoursPanelViewModelTutorial1", "true");
            },
            onSkip: () => {
                localStorage.setItem("WorkedHoursPanelViewModelTutorial1", "true");
            },
        });

        const steps = [
            {
                selector:
                    "body > div.page-header.navbar.navbar-fixed-top > div > div.hor-menu.hor-menu-light.hidden-sm.hidden-xs > ul:nth-child(2) > li",
                description:
                    "Benventuo sulla pagina di Inserimento Ore.<br/>Questo tour guidato ti insegnerà ad utilizzare le nuove funzionalità introdotte nell'ultimo aggiornamento di ProLife",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector: "body > div.page-container > div.page-sidebar-wrapper > navigation-menu > div",
                description:
                    "Sulla sinistra troviamo il calendario con le ore lavorate evidenziate in maniera diversa in base all’orario svolto.<br/>Seleziona un giorno per poter inserire le ore lavorate.",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector: "body > div.page-container > navigation-menu > div",
                description:
                    "Sulla destra troviamo i suggerimenti per la compilazione derivanti dalle precedenti attività svolte",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector:
                    "body > div.page-container > div.page-content-wrapper > div > workedhours-editor > div > div > div.row.workedhours-editor-body > div > div.form > div > div:nth-child(1) > div.col-md-7",
                description:
                    "In questa sezione è possibile selezionare il progetto e l'attività su cui si desidera inserire le ore",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector:
                    "body > div.page-container > div.page-content-wrapper > div > workedhours-editor > div > div > div.row.workedhours-editor-body > div > div.form > div > div:nth-child(1) > div.col-md-5",
                description:
                    "In questa sezione invece è necessario specificare<br/>l'ora di inizio e fine dell'attività, l'eventuale pausa<br/>e il numero delle ore lavorate.<br/>NOTA: Il tempo di lavorazione viene calcolato<br/>automaticamente dopo aver inserito ora di<br/>inizio e fine.",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector:
                    "body > div.page-container > div.page-content-wrapper > div > workedhours-editor > div > div > div.row.workedhours-editor-body > div > div.row.day-workedhours",
                description:
                    "In questo spazio verranno visualizzate le ore che sono gia state inserite nel giorno selezionato",
                showNext: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
            },
            {
                selector:
                    "body > div.page-container > div.page-content-wrapper > div > workedhours-editor > div > div > div.row.workedhours-editor-body > div > div.form > div > div:nth-child(1) > div.col-md-5",
                description:
                    "ATTENZIONE: Da questa versione di ProLife le ore<br/>vengono espresse in ORE:MINUTI invece che<br/>in ORE decimali.<br/>Per semplicità è possibile utilizzare<br/>il carattere '.' come separatore per<br/>l'inserimento rapido tramite il tastierino numerico.<br/>ES: 8.00 verrà considerato come le 8:00 di mattina.<br/>ES: 12.50 verrà considerato come le 12:50",
                showNext: true,
                showSkip: false,
                nextButton: { text: "Fine" },
            },
        ];

        tutorial.set(steps);
        tutorial.run();
    }

    switchToSuggestionsMenu(): void {
        (this.suggestedWorkableElementsParameters.DataSource as ko.Subscribable)(
            this.suggestedWorkableElementsDataSource
        );
        this.ShowingSuggestions(true);
        this.ShowingActivities(false);
    }

    switchToActivitiesMenu(): void {
        (this.suggestedWorkableElementsParameters.DataSource as ko.Subscribable)(
            this.jobOrdersWorkflowsAndTasksDataSource
        );
        this.ShowingSuggestions(false);
        this.ShowingActivities(true);
    }

    public GoBack(newPageName: string) {
        if (!this.insertByJobOrder) this.datesMenu().goBack();
        else this.jobOrderDatesMenu().goBack();
    }

    public canSelectItem(sender: IDataSource, model: INavigationMenuComponentModel): Promise<boolean> {
        if (
            (!this.multipleInsertEnabled && !this.wasMultipleInsertEnabled) ||
            sender === this.suggestedWorkableElementsDataSource
        ) {
            return this.editor.confirmIfHasChanges();
        }

        return new Promise<boolean>((resolve, reject) => resolve(true));
    }

    public onViewAllChanged(viewAllJobOrders: boolean, viewAllActivities: boolean): void {
        this.jobOrdersWorkflowsAndTasksDataSource.setViewFilters(true, true, viewAllJobOrders);
        this.jobOrdersWorkflowsAndTasksDataSource.setViewAllTasks(viewAllActivities);

        if (this.ShowingActivities()) this.jobOrdersWorkflowsAndTasksDataSource.refresh();
    }

    public onShowClosedChanged(jobOrdersShowClosed: boolean, tasksShowClosed: boolean): void {
        this.jobOrdersWorkflowsAndTasksDataSource.setJobOrdersShowClosed(jobOrdersShowClosed);
        this.jobOrdersWorkflowsAndTasksDataSource.setTasksShowClosed(tasksShowClosed);
    }

    public onItemSelected(sender: IDataSource, model: INavigationMenuComponentModel): void {
        if (sender === this.suggestedWorkableElementsDataSource) {
            const modelAsTaskSuggestion = model as ITaskElementNavigationMenuComponentModel;
            this.onSuggestionSelected(modelAsTaskSuggestion);
            return;
        }

        if (sender === this.jobOrdersWorkflowsAndTasksDataSource) {
            const modelAsTask = model as ITaskForTaskBoardDataSourceModel;
            this.onTaskSelected(modelAsTask);
            return;
        }

        if (sender === this.datesDataSource) {
            const modelAsDay = model as IDayNavigationMenuComponentModel;
            this.onDaySelected(modelAsDay?.date);
        }

        if (sender === this.jobOrderDatesDataSource) {
            const modelAsDay = model as IJobOrderDayNavigationMenuComponentModel;
            this.onDaySelected(modelAsDay?.date);
        }
    }

    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.datesDataSource) {
            const modelAsDay = model as IDayNavigationMenuComponentModel;
            this.onDayDeselected(modelAsDay?.date);
        }

        if (sender === this.jobOrderDatesDataSource) {
            const modelAsDay = model as IJobOrderDayNavigationMenuComponentModel;
            this.onDayDeselected(modelAsDay?.date);
        }
    }

    public onResourceSelected(resourceId: number, workDay: Date, jobOrderId: number): void {
        this.goToDay(workDay, resourceId, jobOrderId);

        if (this.desktopService.isMobileBrowser()) return;

        if (
            this.isSameLocation(location.href, this.getDayUrl(workDay, resourceId, jobOrderId)) &&
            this.editor?.insertPerJobOrder
        )
            this.initializeSuggestionsMenu(workDay, resourceId, jobOrderId);
    }

    public onJobOrderSelected(jobOrderId: number, workDay: Date, resourceId: number, taskId: number = null): void {
        if (this.desktopService.isMobileBrowser()) return;

        if (taskId && this.insertPerJobOrderMode) this.requestedTask = taskId;
        else this.requestedTask = null;

        this.goToDay(workDay, resourceId, jobOrderId);
    }

    public onMultipleWorkedHoursInserted(): void {
        this.refreshDatesMenu();
        this.suggestedWorkableElementsMenu().refresh();
    }

    public onWorkedHoursInserted(workedHours: IWorkedHoursForEditing): void {
        this.refreshDatesMenu();
        this.suggestedWorkableElementsMenu().refresh();
    }

    public onWorkedHoursUpdated(workedHours: IWorkedHoursForEditing): void {
        this.refreshDatesMenu();
        this.suggestedWorkableElementsMenu().refresh();
    }

    public onWorkedHoursDeleted(workedHoursId: number): void {
        this.refreshDatesMenu();
        this.suggestedWorkableElementsMenu().refresh();
    }

    public onWorkedHoursApproval(workedHoursId: number): void {
        this.refreshDatesMenu();
    }

    public onMassiveWorkedHoursApproval(): void {
        this.refreshDatesMenu();
    }

    public setMultipleInsertMode(enable: boolean): void {
        this.wasMultipleInsertEnabled = this.multipleInsertEnabled;
        this.multipleInsertEnabled = enable;

        const datesMenu = this.getDatesMenu();
        datesMenu.setMultipleSelection({ multipleSelection: enable, selectLeafsOnly: true });
    }

    public refresh(date: Date, resourceId: number, jobOrderId: number): void {
        jobOrderId = (jobOrderId ?? -1) <= 0 ? null : jobOrderId;

        if (!this.componentsAreInitialized()) {
            this.pendingRefreshRequest = true;
            this.requestedDate = date;
            this.requestedResource = resourceId;
            this.requestedJobOrder = jobOrderId;
            return;
        }

        this.internalRefresh(date, resourceId, jobOrderId);
    }

    public goHome() {
        //Eventuali check di modifiche non salvate
        this.goHomeInner.apply(this, [true]);
    }

    public goHomeInner(result: boolean) {
        if (!result) return;

        location.href = "#/";
    }

    public hasPendingChanges(): boolean {
        return this.editor && this.editor.hasPendingChanges();
    }

    public dispose(): void {}

    private getDatesMenu(): INavigationMenuComponent {
        return this.insertByJobOrder ? this.jobOrderDatesMenu() : this.datesMenu();
    }

    private refreshDatesMenu(): void {
        const datesMenu = this.getDatesMenu();
        datesMenu.refresh(true);
    }

    private internalRefresh(date: Date, resourceId: number, jobOrderId: number) {
        this.initializeEditor(date, resourceId, jobOrderId);
        this.initializeSuggestionsMenu(date, resourceId);
        this.initializeDatesMenu(date, resourceId, jobOrderId);
    }

    private initializeEditor(date: Date, resourceId: number, jobOrderId: number): void {
        if (!this.editor) return;

        this.editor.initialize(date, resourceId, this.requestedTask, jobOrderId);
    }

    private initializeSuggestionsMenu(date: Date, resourceId: number, jobOrderId: number = null): void {
        if (!this.suggestedWorkableElementsMenu()) return;

        this.suggestedWorkableElementsDataSource.setResourceId(resourceId);
        this.suggestedWorkableElementsDataSource.setJobOrderId(jobOrderId);
        this.suggestedWorkableElementsDataSource.setWorkDay(date);

        this.jobOrdersWorkflowsAndTasksDataSource.setResourceId(resourceId);
        this.jobOrdersWorkflowsAndTasksDataSource.setWorkFilters(true);

        this.suggestedWorkableElementsMenu().refresh();
    }

    private configureMenuWorkflowActions() {
        const menu = this.suggestedWorkableElementsMenu();

        const action = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            visible: () => this.ShowingActivities() && this.IsShowingWorkflowsOnMenu(),
            actions: [],
        };

        if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-plus",
                text: TextResources.Todolist.NewWorkflow,
                visible: () => this.ShowingActivities(),
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                action: () => {
                    this.createNewWorkflow();
                },
            });

        if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-copy",
                text: TextResources.Todolist.NewWorkflowFromWorkflow,
                visible: () => this.ShowingActivities(),
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                action: () => {
                    this.createNewWorkflowFromWorkflow();
                },
            });

        if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"))
            action.actions.push({
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-magic",
                text: TextResources.Todolist.CreateFromModel,
                visible: () => this.ShowingActivities(),
                canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                action: () => {
                    this.createNewWorkflowFromTemplate();
                },
            });

        if (action.actions.length > 0) menu.registerAction(action);

        if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
            const showSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                visible: () =>
                    this.IsShowingWorkflowsOnMenu() && !this.ShowingSecondaryActionsOnMenu() && this.canEditWorkflows(),
                text: null,
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(true);
                    menu.showSecondaryAction(true);
                },
            };

            const hideSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                visible: () =>
                    this.IsShowingWorkflowsOnMenu() && this.ShowingSecondaryActionsOnMenu() && this.canEditWorkflows(),
                text: null,
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(false);
                    menu.showSecondaryAction(false);
                },
            };

            menu.registerAction(showSecondaryAction);
            menu.registerAction(hideSecondaryAction);
        }
    }

    private configureMenuTasksActions() {
        const menu = this.suggestedWorkableElementsMenu();

        const tasksActions: INavigationMenuComponentActionsGroup = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            visible: () => this.ShowingActivities() && this.IsShowingTasksOnMenu(),
            actions: [],
        };

        if (this.authorizationsService.isAuthorized("TaskBoard_InsertTask")) {
            const insertTaskAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-plus",
                text: TextResources.Todolist.NewTask,
                visible: () => this.ShowingActivities() && this.IsShowingTasksOnMenu(),
                canExecute: () => true,
                action: () => {
                    this.createTask();
                },
            };

            tasksActions.actions.push(insertTaskAction);
        }

        if (tasksActions.actions.length > 0) menu.registerAction(tasksActions);

        if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
            const showSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                text: null,
                visible: () =>
                    this.IsShowingTasksOnMenu() && !this.ShowingSecondaryActionsOnMenu() && this.canEditTasks(),
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(true);
                    menu.showSecondaryAction(true);
                },
            };

            const hideSecondaryAction: INavigationMenuComponentAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                text: null,
                visible: () =>
                    this.IsShowingTasksOnMenu() && this.ShowingSecondaryActionsOnMenu() && this.canEditTasks(),
                canExecute: () => true,
                action: () => {
                    this.ShowingSecondaryActionsOnMenu(false);
                    menu.showSecondaryAction(false);
                },
            };

            menu.registerAction(showSecondaryAction);
            menu.registerAction(hideSecondaryAction);
        }
    }

    private configureMenuSearchActions() {
        const menu = this.suggestedWorkableElementsMenu();

        menu.registerSearchAction({
            text: TextResources.ProlifeSdk.SearchAll,
            action: () =>
                this.jobOrdersWorkflowsAndTasksDataSource.setWorkflowsSearchMode(
                    WorkflowsMenuSearchModes.SearchActivitiesInWorkflows
                ),
            visible: () => this.ShowingActivities() && this.IsShowingWorkflowsOnMenu(),
            icon: "",
            isGroup: false,
            isSeparator: false,
        });

        menu.registerSearchAction({
            text: TextResources.ProlifeSdk.SearchOnlyInList,
            action: () =>
                this.jobOrdersWorkflowsAndTasksDataSource.setWorkflowsSearchMode(
                    WorkflowsMenuSearchModes.SearchWorkflows
                ),
            visible: () => this.ShowingActivities() && this.IsShowingWorkflowsOnMenu(),
            icon: "",
            isGroup: false,
            isSeparator: false,
            isDefault: true,
        });

        this.jobOrdersWorkflowsAndTasksDataSource.setWorkflowsSearchMode(
            WorkflowsMenuSearchModes.SearchActivitiesInWorkflows
        );
    }

    private configureMenuLegendAction() {
        const menu = this.suggestedWorkableElementsMenu();

        const showLegendAction: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-info-circle",
            text: "",
            title: TextResources.ProlifeSdk.AlertsLegendTitle,
            visible: () => this.ShowingActivities(),
            activeClass: "",
            defaultClass: "btn-transparent",
            active: () => false,
            canExecute: () => true,
            action: () => {
                const component = new AlertsLegendUI();
                component.show();
            },
        };

        menu.registerAction(showLegendAction);
    }

    private canEditTasks(): boolean {
        return this.IsShowingTasksOnMenu() && this.authorizationsService.isAuthorized("TaskBoard_EditTask");
    }

    private canEditWorkflows(): boolean {
        return this.IsShowingWorkflowsOnMenu() && this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow");
    }

    private async createNewWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowDialog(this.selectedJobOrder);
        this.jobOrdersWorkflowsAndTasksDataSource.refresh();
    }

    private async createNewWorkflowFromWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder);
        this.jobOrdersWorkflowsAndTasksDataSource.refresh();
    }

    private async createNewWorkflowFromTemplate(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder);
        this.jobOrdersWorkflowsAndTasksDataSource.refresh();
    }

    private async createTask(): Promise<void> {
        await this.todoListService.ShowCreateNewTaskDialog(this.selectedJobOrder, this.selectedWorkflow, {
            initialViewAll: true,
        });
        this.jobOrdersWorkflowsAndTasksDataSource.refresh();
    }

    public onNavigate(sender: IDataSource, ...history: INavigationMenuComponentModel[]): void {
        if (sender !== this.jobOrdersWorkflowsAndTasksDataSource) return;

        if (history.length === 0) {
            this.IsShowingWorkflowsOnMenu(false);
            this.IsShowingTasksOnMenu(false);

            this.selectedJobOrder = null;
            this.selectedWorkflow = null;

            return;
        }

        const model = history[history.length - 1];

        const jobOrderModel = model as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrderModel && jobOrderModel.isJobOrder) {
            this.IsShowingWorkflowsOnMenu(true);
            this.IsShowingTasksOnMenu(false);

            this.selectedJobOrder = jobOrderModel.id;

            return;
        }

        const workflowModel = model as IWorkflowForTaskBoardDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            this.IsShowingWorkflowsOnMenu(false);
            this.IsShowingTasksOnMenu(true);

            this.selectedWorkflow = workflowModel.id;
        }
    }

    private initializeDatesMenu(date: Date, resourceId: number, jobOrderId: number): void {
        if (this.insertByJobOrder) {
            this.jobOrderDatesDataSource.setJobOrderId(jobOrderId);
            this.jobOrderDatesDataSource.selectDay(date);
        } else {
            this.datesDataSource.setResourceId(resourceId);
            this.datesDataSource.selectDay(date);
        }

        this.WorkedHoursPageSwitcherHandler.goToPage(ProlifeSdk.Page_SelectActivity);
    }

    private goToDay(day: Date, resourceId: number, jobOrderId: number) {
        const destinationUrl = this.getDayUrl(day, resourceId, jobOrderId);
        const actualUrl = location.href;

        location.href = destinationUrl;

        if (this.desktopService.isMobileBrowser() && this.isSameLocation(actualUrl, destinationUrl)) {
            this.initializeEditor(day, resourceId, jobOrderId);
            this.initializeSuggestionsMenu(day, resourceId);
            this.WorkedHoursPageSwitcherHandler.goToPage(ProlifeSdk.Page_SelectActivity);
        }
    }

    private isSameLocation(actualUrl: string, destinationUrl: string) {
        const url = actualUrl.substring(actualUrl.indexOf("#"));
        return url == destinationUrl;
    }

    private getDayUrl(day: Date, resourceId: number, jobOrderId: number): string {
        if (!resourceId)
            resourceId =
                !this.editor || !this.editor.getSelectedResourceId() ? null : this.editor.getSelectedResourceId();

        if (!jobOrderId && this.insertByJobOrder)
            jobOrderId = !this.editor || !this.editor.selectedJobOrderId() ? -1 : this.editor.selectedJobOrderId();

        const urlInitPart = "#/WorkedHours/" + moment(day).format("DDMMYYYY");

        if (this.insertByJobOrder) return urlInitPart + "/JobOrder" + (jobOrderId ? "/" + jobOrderId : "");

        return urlInitPart + (!resourceId ? "" : "/" + resourceId);
    }

    private onDaySelected(date: Date) {
        if (!this.multipleInsertEnabled) {
            if (!this.wasMultipleInsertEnabled) {
                let jobOrderId = null;
                const resourceId = this.editor.getSelectedResourceId();

                if (this.insertByJobOrder) jobOrderId = this.editor.selectedJobOrderId();

                this.goToDay(date, resourceId, jobOrderId);
            }
            this.wasMultipleInsertEnabled = false;
            return;
        }

        this.editor.onDaySelectedForMultipleInsert(date);
    }

    private onDayDeselected(date: Date) {
        this.editor.onDayDeselectedForMultipleInsert(date);
    }

    private onSuggestionSelected(modelAsTask: ITaskElementNavigationMenuComponentModel) {
        const model: ISuggestedWorkableElement = {
            JobOrderId: modelAsTask.jobOrderId,
            JobOrderName: modelAsTask.jobOrderName,
            WorkflowId: modelAsTask.workflowId,
            WorkflowName: modelAsTask.workflowName,
            TaskId: modelAsTask.taskId,
            TaskName: modelAsTask.taskName,
            TaskProgressAvg: modelAsTask.taskProgressAvg,
            TaskRemainingWork: modelAsTask.taskRemainingWork,
            TaskDuration: modelAsTask.taskDuration,
            ActivitiesProgressAmountMode: modelAsTask.activitiesProgressAmountMode,
            Billable: modelAsTask.billable,
            SuggestionSource: modelAsTask.suggestionSource,
            Note: modelAsTask.note,
            EstimatedWork: modelAsTask.estimatedWork,
            WorkedHours: modelAsTask.workedHours,
            CanEditEstimate: modelAsTask.canEditEstimate,
            HideAdministrativeData: modelAsTask.hideAdministrativeData,
            CallRight: modelAsTask.callRight ?? false,
            WorkPlace: modelAsTask.workPlace,
            TravelDistance: modelAsTask.travelDistance,
            HasDefaultRolesSpecified: modelAsTask.hasDefaultRolesSpecified,
            HasDefaultWorkTimeCategoriesSpecified: modelAsTask.hasDefaultWorkTimeCategoriesSpecified,
        };

        this.editor.editRowFromSuggestions(model);
    }

    private onTaskSelected(task: ITaskForTaskBoardDataSourceModel) {
        const model: ISuggestedWorkableElement = {
            JobOrderId: task.model.JobOrderId,
            JobOrderName: task.model.JobOrderName,
            WorkflowId: task.model.WorkflowId,
            WorkflowName: task.model.WorkflowTitle,
            TaskId: task.model.Id,
            TaskName: task.model.Title,
            TaskProgressAvg: task.model.Progress,
            TaskRemainingWork: task.model.RemainingWork,
            TaskDuration: task.model.EstimatedDuration,
            ActivitiesProgressAmountMode: task.model.ActivityProgressMode,
            Billable: task.model.Billable,
            SuggestionSource: "TSK",
            Note: "",
            EstimatedWork: task.model.EstimatedDuration,
            WorkedHours: task.model.WorkedHours,
            CanEditEstimate: task.model.CanEditEstimates,
            HideAdministrativeData: task.model.HideAdministrativeFieldsOnWorkedHours,
            CallRight: task.model.DefaultCallRight ?? false,
            WorkPlace: task.model.DefaultWorkPlace,
            TravelDistance: task.model.DefaultTravelDistance,
            HasDefaultRolesSpecified: task.model.HasDefaultRolesSpecified,
            HasDefaultWorkTimeCategoriesSpecified: task.model.HasDefaultWorkTimeCategoriesSpecified,
        };

        this.editor.editRowFromSuggestions(model);
    }

    /* PER MOBILE */

    public showSuggestions(): void {
        this.WorkedHoursPageSwitcherHandler.goToPage(ProlifeSdk.Page_Suggestions);
    }

    public onGoingBack(newPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined) this.pageSwitcherMap[newPageName].onGoingBack();
    }

    public onPageChanged(newPageName: string, oldPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined)
            this.applicationHostService.SetSideMenuEnabled(
                this.pageSwitcherMap[newPageName].LeftMenuEnabled,
                this.pageSwitcherMap[newPageName].RightMenuEnabled
            );
    }

    public goToCalendar() {
        this.WorkedHoursPageSwitcherHandler.goToCalendar();
    }

    public goBackForMobile(): void {
        this.WorkedHoursPageSwitcherHandler.goBack();
    }

    public setPageForMobile(pageName: string, pageTitle: string): void {
        this.WorkedHoursPageSwitcherHandler.setPageTitle(pageName, pageTitle);
        this.WorkedHoursPageSwitcherHandler.goToPage(pageName);
    }

    private onShowCalendar(): void {
        this.datesMenu().select();
    }

    onItemMoved(before: IDataSourceModel, itemMoved: IDataSourceModel, after: IDataSourceModel) {}
}
