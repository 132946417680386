import * as ko from "knockout";
import { CostsOnProtocolsReport } from "./costs-on-protocols/CostsOnProtocolsReport";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class WarehouseReportsProvider implements IReportsProvider
{

    Id : number = 5;
    Name : string = ProlifeSdk.TextResources.Warehouse.Warehouse;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";
    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    private navigator : IReportsNavigator;

    @LazyImport(nameof<IAuthorizationService>())
    authorizationService : IAuthorizationService;

    constructor()
    {
        this.initialize();
    }

    initialize()
    {
        this.Reports([]);

        if(this.authorizationService.isAuthorized("Warehouse_ArticleCostsReport"))
            this.Reports.push(new CostsOnProtocolsReport(this.Id));

        this.setCurrentNavigator(this.navigator);
    }

    getReportById(reportId : number) : IReport
    {
        var report : IReport = null;

        this.Reports().forEach((r : IReport) => {
            report = r.Id == reportId ? r : report;
        })

        return report;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
        this.Reports().forEach((provider) => provider.setNavigator(navigator));
    }

    Open()
    {
        this.navigator.navigateTo(this);
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}
