import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ReportsApplication } from "./ReportsApplication";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IReportsService, IReportsServiceObserver } from "../ProlifeSdk/interfaces/report/IReportsService";
import { IReportsProvider } from "../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReportVersion } from "../ProlifeSdk/interfaces/report/IReport";

export class ReportsService implements IReportsService
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    private reportsApplication : ReportsApplication;
    private registeredReportsProviders : IReportsProvider[] = [];
    private observers : IReportsServiceObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IReportsService>(), this);
    }

    InitializeService() {
        this.reportsApplication = new ReportsApplication(this.serviceLocator);
    }

    RegisterObserver(o : IReportsServiceObserver)
    {
        this.observers.push(o);
    }

    getServiceType() : string
    {
        return ProlifeSdk.ReportsServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getRegisteredReportsProviders() : IReportsProvider[]
    {
        return this.registeredReportsProviders;
    }

    registerReportsProvider(provider : IReportsProvider) : void
    {
        var matches : IReportsProvider[] = this.registeredReportsProviders.filter((p : IReportsProvider) => p.Id == provider.Id);
        if(matches.length == 0)
        {
            this.registeredReportsProviders.push(provider);
            this.observers.forEach((o : IReportsServiceObserver) => {o.OnReportsProviderRegistered(provider); });
        }
    }

    unregisterReportsProvider(provider : IReportsProvider) : void
    {
        var index = this.registeredReportsProviders.indexOf(provider);
        if(index < 0)
            return;
        this.registeredReportsProviders.splice(index, 1);
    }

    GetReportVersions(groupId : number, reportId : number) : Promise<IReportVersion[]>
    {
        if(groupId < 1 || reportId < 1)
            return Promise.resolve<IReportVersion[]>([]);

        return this.ajaxService.Get("Reports-api/Templates", "GetReportVersions?groupId=" + groupId + "&reportId=" + reportId , {});
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return new ReportsService(serviceLocator);
}