import * as ko from "knockout";
import { ITodoListTemplateTask } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IDefaultTemplateSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IDefaultTemplateSettingsManager";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";

export class DefaultTemplateSettingsViewModel
{
    title : string;
    elements : ko.ObservableArray<DefaultTemplateTask> = ko.observableArray([]);
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    public NewDescription : ko.Observable<string> = ko.observable("");

    constructor(serviceLocator : IServiceLocator, private settingManager : IDefaultTemplateSettingsManager)
    {
        this.title = settingManager.getLabel();
        this.elements(settingManager.GetTasks().map((t : ITodoListTemplateTask) => { return new DefaultTemplateTask(t, settingManager, this); }));
    }

    remove(element : DefaultTemplateTask)
    {
        this.elements.remove(element);
    }

    public addNewSetting()
    {
        var task : ITodoListTemplateTask = {
            TemplateId : -1,
            Title : this.NewDescription(),
            Status : 0,
            Duration : 0,
            Deleted : false,
            Tags : [],
            Resources : [],
            ReportingType : 0,
            Optional: false,
            HideFromSuggestions: false,
            BudgetRequestCause: null,
            EstimatedBudgetRows: [],
            ActivitiesProgressAmountMode: 0,
            MultiplierUnitOfMeasure: '',
            Multiplier: 1,
            IsMilestone: false
        };

        var newElement : DefaultTemplateTask = new DefaultTemplateTask(task, this.settingManager, this);
        newElement.saveChanges();
        this.elements.push(newElement);
        this.NewDescription("");
        this.newSettingHasFocus(true);
    }
}


export class DefaultTemplateTask
{
    private descriptionHasFocus : ko.Observable<boolean> = ko.observable(false);
    private settingsManager : IDefaultTemplateSettingsManager;
    private hasFocus : ko.Observable<boolean> = ko.observable(false);
    public Title : ko.Observable<string> = ko.observable("");
    private parent : DefaultTemplateSettingsViewModel;

    constructor(private task : ITodoListTemplateTask, settingsManager : IDefaultTemplateSettingsManager, parent : DefaultTemplateSettingsViewModel)
    {
        this.settingsManager = settingsManager;
        this.Title(task.Title);
        this.Title.subscribe(this.onDescriptionChanged.bind(this));
        this.parent = parent;
    }

    onDescriptionChanged()
    {
        if(!this.Title() || this.Title() == "")
            this.delete();
        else
            this.saveChanges();
    }

    saveChanges()
    {
        this.task.Title = this.Title();
        this.settingsManager
            .CreateOrUpdateTask(this.task)
            .then((task) => {
                this.task = task;
            });
    }

    delete()
    {
        this.settingsManager
            .DeleteTask(this.task.Id)
            .then(() => { this.parent.remove(this); });
    }

    public onSortChanged()
    {
        var myPosition : number = this.parent.elements().indexOf(this);
        var afterTaskId : number = myPosition == this.parent.elements().length -1 ? -1 : this.parent.elements()[myPosition+1].task.Id;
        var beforeTaskId : number = myPosition == 0 ? -1 : this.parent.elements()[myPosition-1].task.Id;
        var afterOrBefore : boolean = afterTaskId > -1;
        this.settingsManager.MoveTask(this.task.Id, afterOrBefore ? afterTaskId : beforeTaskId, afterOrBefore);
    }
}
