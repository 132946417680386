import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { Application } from "../../Components/Application";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { JobOrdersDataSource } from "../../DataSources/JobOrdersDataSource";
import { LazyImport } from "../../Core/DependencyInjection";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IApplicationNavBarAction, INavBarActionManager } from "../../Desktop/interfaces/IApplication";
import { IFunctionPointComputationService, IInfrastructureEnvelope, IInfrastructureStatementEnvelope } from "../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { Layout } from "../../Components/Layouts";
import { Portlet } from "../../Components/Portlet";
import { ITableItem, Table } from "../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../Components/TableComponent/SecondaryRow";
import { IDataSourceModel } from "../../DataSources/IDataSource";

type FunctionPointComputationHomeProps = {
    navBarActionManager: INavBarActionManager;

    onEdit: (infrastructureId: number) => void;
    onDelete: (infrastructureId: number) => void;
    onNew: () => void;
}

type IInfrastructureEnvelopeEx = IInfrastructureEnvelope & {
    Expanded: ko.Observable<boolean>;
    Statements: ko.ObservableArray<IInfrastructureStatementEnvelope>;
};

export function FunctionPointComputationHome(props: FunctionPointComputationHomeProps) {
    const C = require("./FunctionPointComputationHome")._FunctionPointComputationHome as typeof _FunctionPointComputationHome;
    return <C {...props} />;
}

export class _FunctionPointComputationHome {
    static defaultProps: Partial<FunctionPointComputationHomeProps> = {
    }
    private dataSource : JobOrdersDataSource = new JobOrdersDataSource();
    
    @LazyImport(nameof<IFunctionPointComputationService>())
    private functionPointComputationService : IFunctionPointComputationService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    infrastructures : ko.ObservableArray<IInfrastructureEnvelopeEx> = ko.observableArray();

    newNavBarAction: IApplicationNavBarAction;
    
    constructor(private props : FunctionPointComputationHomeProps) {
        this.dataSource.setUserId(this.userInfo.getIdUser());
        this.dataSource.setViewFilters(true, true, true); 
        
    }

    componentDidMount(){
        this.newNavBarAction = this.props.navBarActionManager.addNavBarAction("Nuovo", "fa fa-plus", true, this.props.onNew);        

        this.loadInfrastructures();
    }

    componentWillUnmount(){
        this.props.navBarActionManager.removeNavBarAction(this.newNavBarAction);
    }

    private async loadInfrastructures() {
        const infrastructures = await this.functionPointComputationService.searchInfrastructures();
        this.infrastructures(infrastructures.map(i => this.createInfrastructure(i)));
    }

    private createInfrastructure(i: IInfrastructureEnvelope): IInfrastructureEnvelopeEx {
        const expanded = ko.observable(false);
        const statements = ko.observableArray();
        let loaded = false;

        expanded.subscribe(async (newValue: boolean) => {
            if(!newValue || loaded) return;
            //Carico i dati delle schede
            const statementModels = await this.functionPointComputationService.getStatements(i.Id);
            statements(statementModels);
            loaded = true;
        });

        return {
            ...i,
            Expanded: expanded,
            Statements: statements
        }
    }

    private createModel(container: IInfrastructureEnvelopeEx): IDataSourceModel<number, IInfrastructureEnvelopeEx> {
        return {
            id: container.Id,
            isGroup: false,
            isLeaf: true,
            title: container.Title,
            model: container,
        }
    }

    private createStatementModel(statement: IInfrastructureStatementEnvelope): IDataSourceModel<number, IInfrastructureStatementEnvelope> {
        return {
            id: statement.Id,
            isGroup: false,
            isLeaf: true,
            title: statement.Title,
            model: statement,
        }
    }

    render() {
        let row: IDataSourceModel<number, IInfrastructureEnvelopeEx>;
        
        return  <Application>
                    <Application.LeftMenu>
                        <NavigationMenu dataSource={this.dataSource}/>                                          
                    </Application.LeftMenu>
                    <Application.Content>
                        <Layout.Grid columns={["1fr"]} rows={["120px", "1fr"]}>
                            <Layout.Grid.Cell column={1} row={1}>
                                <Portlet collapsible={false}>
                                    <Portlet.Header>
                                        <Portlet.Header.Default title="Infrastrutture" className="font-red-sunglo bold uppercase" />
                                    </Portlet.Header>
                                </Portlet>
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={1} row={2}>
                                <Layout.ScrollContainer>
                                    <Table dataSource={{ array: this.infrastructures, factory: this.createModel.bind(this) }} showColumnSelector rowAs={"row"}>
                                        <Column style={{ width: "40px" }}>
                                            <ColumnBody>{(item: ITableItem<IInfrastructureEnvelopeEx>) =>
                                                <button className="btn btn-transparent btn-xs" onClick={() => item.Data.model.Expanded()}>
                                                    <i className="fa" data-bind={{ css: { "fa-caret-down": row.model.Expanded(), "fa-caret-right": !row.model.Expanded() } }}></i>
                                                </button>}
                                            </ColumnBody>
                                        </Column>
                                        <Column title="Titolo">
                                            <span data-bind={{ text: row.model.Title }} />
                                        </Column>
                                        <Column title="Descrizione">
                                            <span data-bind={{ text: row.model.Description }} />
                                        </Column>
                                        <Column title="Creato il">
                                            <span data-bind={{ dateText: row.model.CreationDate }} />
                                        </Column>
                                        <Column>
                                            <ColumnBody>{(item: ITableItem<IInfrastructureEnvelopeEx>) =>
                                                <div>
                                                    <button className="btn btn-danger btn-xs btn-circle btn-icon-only" onClick={() => this.props.onDelete(item.Data.model.Id)} >
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                    <button className="btn btn-primary btn-xs btn-circle btn-icon-only" onClick={() => this.props.onEdit(item.Data.model.Id)} >
                                                        <i className="fa fa-edit"></i>
                                                    </button>
                                                </div>}
                                            </ColumnBody>
                                        </Column>
                                        <SecondaryRow if={() => "row.model.Expanded"}>
                                            {(item: ITableItem<IInfrastructureEnvelopeEx>) =>
                                                <td colSpan={4}>
                                                    <Table dataSource={{ array: item.Data.model.Statements, factory: this.createStatementModel }} rowAs="jobOrder">
                                                        <Column title="Schede:">
                                                            <span data-bind={{ text: "jobOrder.title" }}></span>
                                                        </Column>
                                                    </Table>
                                                </td>}
                                        </SecondaryRow>
                                    </Table>
                                </Layout.ScrollContainer>
                            </Layout.Grid.Cell>
                        </Layout.Grid>
                    </Application.Content>
                </Application>; 
        
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(FunctionPointComputationHome);
}