import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { WorkflowOutcomesDataSource } from "../../../../DataSources/WorkflowOutcomesDataSource";
import { WorkflowStatesDataSource } from "../../../../DataSources/WorkflowStatesDataSource";
import { IWorkflowOutcomeSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IWorkflowOutcome } from "../../../WorkflowOutcomesService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class WorkflowOutcomeSettingsViewModel {
    title : string;

    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    private outcomesManager : IWorkflowOutcomeSettingsManager;

    WorkflowOutcomesDataSource : WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();
    WorkflowStatesDataSource : WorkflowStatesDataSource = new WorkflowStatesDataSource();

    constructor()
    {
        this.title = this.outcomesManager.getLabel();
    }

    public createNew()
    {
        this.outcomesManager.createOrUpdate({
            Description : '',
            LogicalStatus : 0,
            Deleted : false,
            Position : -1,
            Icon: "icon-info",
            Background: "#FFFFFF",
            Foreground: "#000000",
            PreventArticlesLoad: false,
            PreventArticlesUnload: false,
            PreventTasksEditing: false,
            PreventPurchasesCreationOrChange: false,
            PreventWorkedHoursCreationOrChange: false,
            MoveWorkflowToState: null
        } as IWorkflowOutcome)
            .then(() => this.WorkflowOutcomesDataSource.refresh());
    }
}

ko.components.register("workflow-outcomes-editor", {
    viewModel: {
        createViewModel: (params: any, componentInfo : ko.components.ComponentInfo) => {
            const vm = new WorkflowOutcomeSettingsViewModel();

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <h3 class="page-title">
                        <span data-bind="text: title"></span>
                        <small class="alert alert-danger" style="position: absolute; left: 60%; margin-left: -25%; text-align: center; top: 0px; width: 500px; color: #a94442;">{TextResources.ProlifeSdk.SettingsheaderMessage}</small>
                    </h3>

                    <button class="btn btn-primary" style="position: absolute; top : 25px; right : 20px" data-bind="click : createNew">
                        <i class="fa fa-plus"></i>
                        &nbsp;{TextResources.Todolist.NewWorkflowOutcomeButton}
                    </button>

                    <list params="Sortable: true, AllowNoSelection: true, DataSource: WorkflowOutcomesDataSource">
                        <div class="row no-gutters" data-bind="with: Model">
                            <div class="col-md-4">
                                <label>{TextResources.Todolist.WorkflowOutcomeEditorNameLabel}</label>
                                <input class="form-control" placeholder="Inserire una descrizione..." type="text" data-bind="value: Description" />
                            </div>

                            <div class="col-md-4">
                                <div class="col-md-3">
                                    <label>{TextResources.Todolist.WorkflowOutcomeEditorIconLabel}</label>
                                    <div>
                                        <button class="btn btn-default" data-bind="iconPicker: Icon" role="iconpicker" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>{TextResources.Todolist.WorkflowOutcomeEditorBackgroundLabel}</label>
                                    <input type="text" class="form-control" data-bind="colorPicker: { colorField: Background, options: { showHexInput: false } }" />
                                </div>
                                <div class="col-md-2">
                                    <label>{TextResources.Todolist.WorkflowOutcomeEditorForegroundLabel}</label>
                                    <input type="text" class="form-control" data-bind="colorPicker: { colorField: Foreground, options: { showHexInput: false } }" />
                                </div>
                                <div class="col-md-5">
                                    <label>{TextResources.Todolist.WorkflowOutcomeEditorPreviewLabel}</label>
                                    <div>
                                        <div style="padding: 8px; margin-top: -6px; float: left;">
                                            <div class="btn btn-primary btn-lg" data-bind="style: { background: Background, color: Foreground }" style="min-height: 30px; min-width: 35px; text-align: center; padding: 0; margin: 2px; line-height: 30px">
                                                <i data-bind="css: Icon"></i>
                                            </div>
                                        </div>
                                        <div style="padding: 8px; margin-top: -6px; float: left; background: #1F1F1F;">
                                            <div class="btn btn-primary btn-lg" data-bind="style: { background: Background, color: Foreground }" style="min-height: 30px; min-width: 35px; text-align: center; padding: 0; margin: 2px; line-height: 30px">
                                                <i data-bind="css: Icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <label>{TextResources.Todolist.WorkflowOutcomeEditorStateLabel}</label>
                                <div>
                                    <select class="form-control" data-bind="value: Outcome, options: OutcomeNames, optionsValue: 'Id', optionsText: 'Text'"></select>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <label>&nbsp;</label>
                                <div class="text-right">
                                <i class="fa fa-circle-o-notch fa-spin" data-bind="visible: Saving"></i>
                                    <button class="btn btn-danger" style="margin-left: 10px" data-bind="click : markDeleted">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters" data-bind="with: Model">
                            <div class="col-md-4">
                                <label></label>
                                <div>
                                    <checkbox class="col-md-12" checked={() => "PreventArticlesUnload"}>{TextResources.Todolist.WorkflowOutcomeEditorPreventWarehouseUnload}</checkbox>
                                    <checkbox class="col-md-12" checked={() => "PreventArticlesLoad"}>{TextResources.Todolist.WorkflowOutcomeEditorPreventWarehouseLoad}</checkbox>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label></label>
                                <div>
                                    <checkbox class="col-md-12" checked={() => "PreventWorkedHoursCreationOrChange"} read-only={() => "PreventTasksCreationOrChanges"}>{TextResources.Todolist.WorkflowOutcomeEditorPreventWorkedhoursEditingAndInsert}</checkbox>
                                    <checkbox class="col-md-12" checked={() => "PreventPurchasesCreationOrChange"} read-only={() => "PreventTasksCreationOrChanges"}>{TextResources.Todolist.WorkflowOutcomeEditorPreventPurchasesEditingAndInsert}</checkbox>
                                    <checkbox class="col-md-12" checked={() => "PreventTasksCreationOrChanges"}>{TextResources.Todolist.WorkflowOutcomeEditorPreventTasksEditingAndInsert}</checkbox>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <select2 params="Label: 'Sposta il piano in questo stato', AllowClear: true, DataSource: $parents[2].WorkflowStatesDataSource, Value: MoveWorkflowToState"></select2>
                            </div>
                        </div>
                    </list>
                </>
            ]);

            return vm;
        }
    },
    template: []
})