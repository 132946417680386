import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentVersionNumberGenerationModes } from "../../../enums/DocumentVersionNumberGenerationModes";

export interface IVersionNumberGenerationMode {
    Id: number,
    Description: string;
}

export class DocumentVersionNumberGenerationModesProvider {
    public static getAvailableGenerationModes(): IVersionNumberGenerationMode[] {
        return [
            { Id: DocumentVersionNumberGenerationModes.AutoFromProtocolNumber, Description: ProlifeSdk.TextResources.Invoices.AutoFromProtocolVersionNumber },
            { Id: DocumentVersionNumberGenerationModes.AutoFromDocumentVersionNumber, Description: ProlifeSdk.TextResources.Invoices.AutoFromVersionNumber }
        ];
    }
}