import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IProvisioningService, IPreOrderRowForList, IGetPreOrderRowsForListRequest } from "../Provisioning/ProvisioningService";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";

export class PreOrdersRowsDataSource implements IDataSource {
    private view: IDataSourceView;
    private preOrderId: number = null;
    private entityId: number = null;
    private entityType: string = null;
    private stateId: number = null;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    constructor() {

    }

    public setView(view: IDataSourceView): void {
        this.view = view;    
    }

    public setPreOrderId(id: number): void {
        this.preOrderId = id;
    }
    
    public setEntity(id: number, type: string): void {
        this.entityId = id;
        this.entityType = type;
    }

    public setStateId(state: number): void {
        this.stateId = state;
    }

    public getTitle(currentModel: IDataSourceModel<number, IPreOrderRowForList>): string {
        return ProlifeSdk.TextResources.Provisioning.PreOrdersRows;
    }
    
    public async getData(currentModel: IDataSourceModel<number, IPreOrderRowForList>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, IPreOrderRowForList>[]> {
        let request: IGetPreOrderRowsForListRequest = {
            TextFilter: textFilter,
            PreOrderId: this.preOrderId,
            EntityId: this.entityId,
            EntityType: this.entityType,
            StateId: this.stateId,
            Skip: skip,
            Count: count
        };

        let rows: IPreOrderRowForList[] = await this.provisioningService.GetPreOrderRowsForList(request);
        return rows.map(this.createDataSourceModelFor, this);
    }
    
    public async getById(currentModel: IDataSourceModel<number, IPreOrderRowForList>, ids: number[]): Promise<IDataSourceModel<number, IPreOrderRowForList>[]> {
        let rows: IPreOrderRowForList[] = await this.provisioningService.GetPreOrderRowsForListByIds(ids);
        return rows.map(this.createDataSourceModelFor, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
    
    public isGroupedData(currentModel: IDataSourceModel<number, IPreOrderRowForList>, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IDataSourceModel<number, IPreOrderRowForList>, b: IDataSourceModel<number, IPreOrderRowForList>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    public onItemBeginMove(model: IDataSourceModel<number, IPreOrderRowForList>, dataTransfer: DataTransfer) {
        
    }
    
    public async onItemMoved(dataTransfer: DataTransfer, model: IDataSourceModel<number, IPreOrderRowForList>, before: boolean) : Promise<void> {
        
    }

    private createDataSourceModelFor(row: IPreOrderRowForList): IDataSourceModel<number, IPreOrderRowForList> {
        return {
            id: row.Id,
            title: row.EntityDescription,
            isGroup: false,
            isLeaf: true,
            model: row
        };
    }
}