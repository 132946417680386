import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import moment = require("moment");
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { SentMail, SentMailStatus } from "../../DocumentsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { MailLogModal } from "./MailLogModal";
import { Button } from "../../../Components/ButtonGroup";
import { Flag } from "./Flag";
import { Document } from "./Document";

const styleSheet = jss.createStyleSheet({
    options: {
        position: "relative",
        height: "28.5px",
        display: "flex !important",
        alignItems: "center",
    },

    lastMailSentStatus: {
        width: "11.5cm",
        height: "28.5px",
        padding: "0 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",

        "& .icon": {
            fontSize: "13px",
            marginRight: "5px",
        },

        "& .text": {
            fontSize: "13px",

            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
    },

    "log-btn": {
        position: "absolute",
        top: "0px",
        right: "20px",
    },

    label: {
        margin: "0 0 0 20px",
    },

    "inline-flag": {},
});
const { classes } = styleSheet.attach();

function showLog(documentId: number): void {
    const logModal = new MailLogModal({ documentId: documentId });
    logModal.show();
}

type SendMailOptionsProps = {
    documentId: ko.Observable<number>;
    automaticMailSend: ko.Observable<boolean>;
    includeDocumentAttachments: ko.Observable<boolean>;
    lastSentMail: ko.Observable<SentMail>;
};

export function SendMailOptions(props: SendMailOptionsProps) {
    const C = require("./SendMailOptions")._SendMailOptions as typeof _SendMailOptions;
    return <C {...props} />;
}

export class _SendMailOptions {
    static defaultProps: Partial<SendMailOptionsProps> = {};

    private documentId: number;

    constructor(private props: SendMailOptionsProps) {
        this.documentId = this.props.documentId();
        this.props.documentId.subscribe((documentId) => (this.documentId = documentId));
    }

    render() {
        const { lastSentMail } = this.props;

        return (
            <div class="row single-field striped">
                <div class={"control-group " + classes.options}>
                    <Label value={TextResources.Invoices.SendMailLabel} />
                    <Flag checked="SendDocumentMail" disabled="ReadOnly" className={classes["inline-flag"]} />
                    <Label value={TextResources.Invoices.IncludeDocumentAttachmentsLabel} />
                    <Flag
                        checked="IncludeDocumentAttachmentsInMail"
                        disabled="ReadOnly"
                        className={classes["inline-flag"]}
                    />
                    <LastSentMailStatus lastSentMail={lastSentMail()} />
                    <Button
                        color="primary"
                        size="sm"
                        className={"margin-left-5 " + classes["log-btn"]}
                        onClick={showLog.bind(null, this.documentId)}>
                        <i className="fa fa-list"></i>
                    </Button>
                </div>
            </div>
        );
    }
}

class Label {
    constructor(private props: { value: string }) {}

    render() {
        const { value } = this.props;

        return <label className={"without-dot " + classes.label}>{value}</label>;
    }
}

class LastSentMailStatus {
    constructor(private props: { lastSentMail: SentMail }) {}

    render() {
        const { lastSentMail } = this.props;
        return (
            <div className={classes.lastMailSentStatus}>
                <ko-with data-bind="LastMailSentStatus">
                    <StatusIcon status={lastSentMail?.status} />
                    <StatusText sentMail={lastSentMail} />
                </ko-with>
            </div>
        );
    }
}

class StatusIcon {
    constructor(private props: { status: SentMailStatus }) {}

    render() {
        const { status } = this.props;
        const $data: SentMail = null;

        return (
            <span
                title={this.getStatusTitle(status)}
                data-bind={{
                    attr: { title: $data.status === 6 ? "Invio mail parziale" : "Invio mail avvenuto con successo" },
                }}>
                <i
                    className="fa fa-envelope icon"
                    data-bind={{ css: { "text-warning": $data.status === 6, "text-success": $data.status === 5 } }}></i>
            </span>
        );
    }

    private getStatusTitle(status: SentMailStatus): string {
        return status === SentMailStatus.MailSendPartial
            ? TextResources.Invoices.DocumentLastMailSentPartial
            : TextResources.Invoices.DocumentLastMailSentSuccess;
    }
}

class StatusText {
    constructor(private props: { sentMail: SentMail }) {}

    render() {
        const { sentMail } = this.props;
        const $data: SentMail = null;
        const $parent: Document = null;

        return (
            <span className="text" data-bind={{ click: $parent.showMailingLog.bind($parent, $data.documentId) }}>
                Ultimo invio mail il <span data-bind={{ dateTimeText: $data.sentAt, format: "L LT" }}></span> da{" "}
                <span data-bind={{ text: $data.sentBy }}></span>
                {/* {this.renderText(sentMail)} */}
            </span>
        );
    }

    private renderText(sentMail: SentMail): string {
        return !sentMail ? "" : this.formatText(sentMail);
    }

    private formatText(sentMail: SentMail): string {
        return String.format(
            TextResources.Invoices.DocumentLastMailSentStatus,
            moment(sentMail.sentAt).format("L LTS"),
            sentMail.sentBy
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(SendMailOptions);
}
