import * as ko from "knockout";
export class DynamicListItemsLoader
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var renderHandler : (idsToRender : { Id : number; Element : any; }[]) => Promise<{ Id : number; ViewModel : any; }[]> = ko.utils.unwrapObservable(valueAccessor()["renderHandler"]);
        var itemTemplateName : string = ko.utils.unwrapObservable(valueAccessor()["itemTemplateName"]);
        var itemTemplateUrl : string = ko.utils.unwrapObservable(valueAccessor()["itemTemplateUrl"]);

        var containerSelector : string = ko.utils.unwrapObservable(valueAccessor()["itemsContainerSelector"]);
        var itemsContainer = containerSelector ?
            $(element).find(containerSelector)[0]:
            element;

        var scrollTimer;

        $(element).scroll(() => {
            if (scrollTimer) {
                clearTimeout(scrollTimer);
            }
            scrollTimer = setTimeout(() => { DynamicListItemsLoader.OnScroll(element, itemsContainer, renderHandler, itemTemplateName, itemTemplateUrl); }, 500);
        });

        DynamicListItemsLoader.OnScroll(element, itemsContainer, renderHandler, itemTemplateName, itemTemplateUrl);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var renderHandler : (idsToRender : { Id : number; Element : any; }[]) => Promise<{ Id : number; ViewModel : any; }[]> = ko.utils.unwrapObservable(valueAccessor()["renderHandler"]);
        var itemTemplateName : string = ko.utils.unwrapObservable(valueAccessor()["itemTemplateName"]);
        var itemTemplateUrl : string = ko.utils.unwrapObservable(valueAccessor()["itemTemplateUrl"]);
        var data : { Items : { Id : number; }[]; } = ko.utils.unwrapObservable(valueAccessor()["data"]);
        var classes : string = ko.utils.unwrapObservable(valueAccessor()["itemCss"]);

        var containerSelector : string = ko.utils.unwrapObservable(valueAccessor()["itemsContainerSelector"]);
        var itemsContainer = containerSelector ?
            $(element).find(containerSelector)[0]:
            element;

        //Rimuovo tutti i vecchi figli
        while (itemsContainer.firstChild)
        {
            itemsContainer.removeChild(itemsContainer.firstChild);
        }

        //Aggiungo i nuovi figli
        for(var i = 0;i<data.Items.length;i++)
        {
            var item = document.createElement("li");

            var idAttr = document.createAttribute("task-id");
            idAttr.value = data.Items[i].Id.toString();
            item.setAttributeNode(idAttr);

            if(classes)
            {
                var cssAttr = document.createAttribute("class");
                cssAttr.value = classes;
                item.setAttributeNode(cssAttr);
            }

            itemsContainer.appendChild(item);
        }

        DynamicListItemsLoader.OnScroll(element, itemsContainer, renderHandler, itemTemplateName, itemTemplateUrl);
    }

    private static OnScroll(scrollableElement, list, renderHandler : (idsToRender : { Id : number; Element : any; }[]) => Promise<{ Id : number; ViewModel : any; }[]>, templateName : string, templateUrl : string)
    {
        if($(list).find("li").length == 0)
            return;

        var itemsToRender : any[] = [];
        var firstToRenderFound : boolean = false;

        for(var i=0;i<list.childNodes.length;i++)
        {
            var isVisible = DynamicListItemsLoader.IsIntoView(list.childNodes[i], scrollableElement);
            firstToRenderFound = isVisible || firstToRenderFound;

            if(isVisible)
                itemsToRender.push(list.childNodes[i]);
            else if(firstToRenderFound)
                break;
        }

        DynamicListItemsLoader.RenderItems(itemsToRender, renderHandler, templateName, templateUrl);
    }

    private static RenderItems(itemsToRender : any[], renderHandler : (idsToRender : { Id : number; Element : any; }[]) => Promise<{ Id : number; ViewModel : any; }[]>, templateName : string, templateUrl : string)
    {
        var taskIdsWithElements : { Id : number; Element : any; }[] = itemsToRender
            .filter((item) => { return !item.getAttributeNode("render-done") || item.getAttributeNode("render-done") != "render-done"; })
            .map((item) => { return { Element : item, Id : parseInt(item.getAttribute("task-id")) } });

        if(taskIdsWithElements.length == 0)
            return;

        renderHandler(taskIdsWithElements).then((itemsVms : { Id : number; ViewModel : any; }[]) => {
            itemsVms.forEach((itemVm : { Id : number; ViewModel : any; }) => {
                var item : any = itemsToRender.filter((itemToRender) => { return parseInt(itemToRender.getAttribute("task-id")) == itemVm.Id; })[0];
                ko.applyBindingsToNode(item, { template: { name: templateName, templateUrl : templateUrl } }, itemVm.ViewModel);

                var renderAttr = document.createAttribute("task-id");
                renderAttr.value = "render-done";
                item.setAttributeNode(renderAttr);
            });
        });
    }

    private static IsIntoView(item, scrollableElement)
    {
        var scrollTop = $(scrollableElement).scrollTop();
        var screenHeight = $(scrollableElement).height();
        var itemHeight = $(item).height();
        var itemTop = $(item).position().top;


        return itemTop + itemHeight > scrollTop && itemTop < screenHeight + scrollTop;
    }
}

ko.bindingHandlers["dynamicListItemsLoader"] = new DynamicListItemsLoader();