import * as ko from "knockout";
import { ICustomersDataSourceModel } from "../../../../../DataSources/CustomersDataSource";
import { ICustomerDiscountCatalog } from "../../../../DiscountsCatalogsService";
import { SupplierDiscountCatalogsDataSource, IBaseCustomerDiscountCatalogsDataSourceObserver } from "../../../../../DataSources/CustomerDiscountCatalogsDataSource";
import { AssignDiscountCatalogDialog } from "./AssignDiscountCatalogDialog";
import { SuppliersDataSource, ISuppliersDataSourceModel } from "../../../../../DataSources/SuppliersDataSource";
import { CustomerDiscountCatalogSolutionDataSource } from "../../../../../DataSources/CustomerDiscountCatalogSolutionDataSource";
import { IDataSourceListener, IDataSource } from "../../../../../DataSources/IDataSource";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";
import { ICustomer } from "../../../../../ProlifeSdk/interfaces/customer/ICustomer";

export class SuppliersDiscountsList implements IView, IDataSourceListener, IBaseCustomerDiscountCatalogsDataSourceObserver {
    templateName: string = "customerDiscounts";
    templateUrl: string = "warehouse/templates/discounts";
    viewModel: any;

    SectionId: number = 4;
    IsCustomer : boolean = false;

    public CustomersDataSource : SuppliersDataSource = new SuppliersDataSource();
    public CustomerDiscountCatalogsDataSource : SupplierDiscountCatalogsDataSource = new SupplierDiscountCatalogsDataSource();
    public CustomersSearch : ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });

    SelectedCustomer : ko.Observable<ICustomer> = ko.observable();
    CustomerDiscounts : ko.ObservableArray<ICustomerDiscountCatalog> = ko.observableArray();
    CustomerDiscountCatalogSolutionDataSource : CustomerDiscountCatalogSolutionDataSource = new CustomerDiscountCatalogSolutionDataSource();

    TestingDiscounts : ko.Observable<boolean> = ko.observable();
    TestingDiscountsFilter : ko.Observable<string> = ko.observable();

    constructor() {
        this.viewModel = this;

        this.CustomerDiscountCatalogsDataSource.addObserver(this);
    }

    onDataChanged(): void {
        if(this.TestingDiscounts())
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
    }

    public toggleTestingMode() {
        if(!this.SelectedCustomer()) return;

        this.TestingDiscounts(!this.TestingDiscounts());

        if(this.TestingDiscounts()) {
            this.CustomerDiscountCatalogSolutionDataSource.setCustomerId(this.SelectedCustomer().IdCliente, false);
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
        }
    }

    onItemSelected(sender: IDataSource, model: ISuppliersDataSourceModel): void {
        if(sender == this.CustomersDataSource) {
            this.TestingDiscounts(false);
            this.SelectedCustomer(model.model as ICustomer);
            this.CustomerDiscountCatalogsDataSource.setCustomerId(model.id);
            this.CustomerDiscountCatalogsDataSource.refresh();
        }
    }

    onItemDeselected(sender: IDataSource, model: ISuppliersDataSourceModel): void {
        
    }
    
    async canSelectItem(sender: IDataSource, model: ISuppliersDataSourceModel): Promise<boolean> {
        return true;
    }

    async assignDiscountCatalog() : Promise<void> {
        let customerId = this.SelectedCustomer().IdCliente;
        let result = await new AssignDiscountCatalogDialog(customerId, false, false).showModal()
        if(result)
            this.CustomerDiscountCatalogsDataSource.refresh();
    }
}

