import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 11/07/13
 * Time: 9.05
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SupplierOrderProtocolSettings } from "./SupplierOrderProtocolSettings";
import {
    IProtocolSettingsViewModel,
    IProtocolType,
    IVatRegisterEditingContainer,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Right } from "../../../../Core/Authorizations";

export class SupplierOrderProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.SupplierOrderTypeId;
    public DocumentTypeName: string = ProlifeSdk.TextResources.Warehouse.SupplierOrders;
    public DocumentTypeLabelForSettings: string = ProlifeSdk.TextResources.Warehouse.SupplierOrdersProtocols;
    public DocumentTypeShortLabelForSettings: string =
        ProlifeSdk.TextResources.Warehouse.SupplierOrdersProtocolsShortLabel;

    HasCustomTemplate = true;

    @Right("Warehouse_SupplierOrder")
    private canAddProtocolsRight: boolean;

    constructor() {}

    canAddProtocols(): boolean {
        return this.canAddProtocolsRight;
    }

    createSettingsViewModel(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister
    ): IProtocolSettingsViewModel {
        return new SupplierOrderProtocolSettings(container, vatRegister);
    }
}
