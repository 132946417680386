import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { SurveyPanelViewModel } from "../SurveyPanelViewModel";
import { Campaign } from "./Campaign";
import { CampaignForDetails } from "./CampaignForDetails";
import { EditCampaignDialog } from "./dialogs/EditCampaignDialog";
import { AlertDialog } from "../alert-dialog/AlertDialog";
import { QueueDetailsElement } from "./QueueDetailsElement";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { ITodoListResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ICampaignService } from "../../../../ProlifeSdk/interfaces/survey/ICampaignService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ICampaign } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { IResourceForList } from "../../../interfaces/IResourceForList";
import { IQueueDetailsForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueElement";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class CampaignDetails {
    public templateName = "campaign-details";
    public templateUrl = "survey/templates/campaign";

    public Campaign: ko.Observable<any> = ko.observable();
    public CanModify: ko.Observable<boolean> = ko.observable();

    public ResourcesList: ko.ObservableArray<ResourceForList> = ko.observableArray([]);
    public WithoutResources: ko.Computed<boolean>;

    public Queue: ko.ObservableArray<QueueDetailsElement> = ko.observableArray([]);
    public EmptyQueue: ko.Computed<boolean>;

    public ResultExportUrl: ko.Observable<string> = ko.observable();
    public ResultPivotExportUrl: ko.Observable<string> = ko.observable();
    public QueueExportUrl: ko.Observable<string> = ko.observable();

    private campaignService: ICampaignService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;
    private desktopService: IDesktopService;
    private surveyWizardService: ISurveyWizardService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private LockManager: EntitiesListLockManager;
    private canLoadNextBlock = true;

    constructor(
        private serviceLocator: IServiceLocator,
        private campaignId: number,
        private parent: SurveyPanelViewModel
    ) {
        this.campaignService = <ICampaignService>this.serviceLocator.findService(ProlifeSdk.CampaignServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.surveyWizardService = <ISurveyWizardService>(
            this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType)
        );

        this.LockManager = new EntitiesListLockManager(this.Queue, ProlifeSdk.SurveyEntityTypeCode);

        this.CanModify(true);

        this.campaignService
            .getCampaignDetails(this.campaignId)
            .then((campaign: ICampaign) => {
                if (!campaign.Description) campaign.Description = ProlifeSdk.TextResources.Survey.EmptyDescription;
                this.Campaign(new CampaignForDetails(campaign));

                this.ResultExportUrl("SurveyWizard/QuestionnaireResultsExport/ExcelExport/" + this.campaignId);
                this.ResultPivotExportUrl(
                    "SurveyWizard/QuestionnaireResultsExport/ExcelPivotExport?campaignId=" +
                        this.campaignId +
                        "&questionnaireId=" +
                        campaign.QuestionnaireId
                );
                this.QueueExportUrl("/Service/SurveyWizard/QueueExport/ExcelExport/" + this.campaignId);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetCampaignDetailsError);
            });

        this.campaignService
            .getCampaignResourcesForList(this.campaignId)
            .then((resources: IResourceForList[]) => {
                this.ResourcesList(
                    resources.map((r: IResourceForList) => {
                        return new ResourceForList(r);
                    })
                );
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetCampaignResourcesError);
            });

        this.WithoutResources = ko.computed(() => {
            return this.ResourcesList().length == 0;
        });

        this.EmptyQueue = ko.computed(() => {
            return this.Queue().length == 0;
        });

        this.LoadNextQueueBlock();
    }

    public ResultExport() {
        this.ajaxService.DownloadFileFromUrl(this.ResultExportUrl(), {});
    }

    public ResultPivotExport() {
        this.ajaxService.DownloadFileFromUrl(this.ResultPivotExportUrl(), {});
    }

    public LoadNextQueueBlock(): void {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);
        this.LockManager.WhenIsReady().then(() => {
            if (this.canLoadNextBlock) {
                this.canLoadNextBlock = false;
                this.surveyWizardService
                    .getCallsQueueDetails(this.campaignId, this.Queue().length, 100, false)
                    .then((block: IQueueDetailsForWizard[]) => {
                        var queue = this.Queue();
                        block.forEach((q: IQueueDetailsForWizard) => {
                            queue.push(new QueueDetailsElement(q));
                        });
                        this.Queue(queue);
                    })
                    .finally(() => {
                        this.desktopService.UnblockPageUI();
                        setTimeout(() => {
                            this.canLoadNextBlock = true;
                        }, 500);
                    });
            }
        });
    }

    public BackToSplash(): void {
        this.parent.Reset = false;
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    public Edit(): void {
        this.campaignService
            .getCampaignLogicalState(this.campaignId)
            .then((state: number) => {
                if (state != ProlifeSdk.DraftCampaignState) {
                    var alertDialog = new AlertDialog([], ProlifeSdk.TextResources.Survey.EditStartedCampaignAlert);
                    this.dialogService
                        .ShowModal<boolean>(alertDialog, null, null, "survey/templates/dialogs", "alert-dialog")
                        .then((confirm: boolean) => {
                            if (confirm) {
                                this.campaignService
                                    .getCampaignResources(this.campaignId)
                                    .then((res: ITodoListResource[]) => {
                                        var editCampaignDialog = new EditCampaignDialog(
                                            this.serviceLocator,
                                            this.Campaign(),
                                            res
                                        );
                                        this.dialogService
                                            .ShowModal<Campaign>(
                                                editCampaignDialog,
                                                null,
                                                null,
                                                "survey/templates/campaign/dialogs",
                                                "add-or-update-campaign"
                                            )
                                            .then((campaign: Campaign) => {
                                                if (!campaign) return;
                                                this.Campaign(new CampaignForDetails(campaign.toJSON()));
                                            });
                                    })
                                    .catch(() => {
                                        this.infoToastService.Error(
                                            ProlifeSdk.TextResources.Survey.GetCampaignResourcesError
                                        );
                                    });
                            }
                        });
                    return;
                }

                this.campaignService
                    .getCampaignResources(this.campaignId)
                    .then((res: ITodoListResource[]) => {
                        var editCampaignDialog = new EditCampaignDialog(this.serviceLocator, this.Campaign(), res);
                        this.dialogService
                            .ShowModal<Campaign>(
                                editCampaignDialog,
                                null,
                                null,
                                "survey/templates/campaign/dialogs",
                                "add-or-update-campaign"
                            )
                            .then((campaign: Campaign) => {
                                if (!campaign) return;
                                this.Campaign(new CampaignForDetails(campaign.toJSON()));
                            });
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetCampaignResourcesError);
                    });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.EditCampaignError);
            });
    }

    public Delete(): void {
        this.campaignService
            .deleteCampaign(this.campaignId)
            .then(() => {
                this.parent.Reset = false;
                this.BackToSplash();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DeleteCampaignError);
            });
    }

    public dispose() {
        this.Campaign(null);
    }
}

export class ResourceForList {
    public Resource: ko.Observable<string> = ko.observable();
    constructor(resource: IResourceForList) {
        this.Resource(resource.Name + " " + resource.Surname);
    }
}
