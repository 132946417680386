import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ReactNode, CSSProperties } from "@abstraqt-dev/jsxknockout";
import { PropsWithChildren, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { ITableItem, Table } from "./TableComponent";
import { TableFooterAggregationMode } from "./TableFooterAggregationMode";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import moment = require("moment");
import numeral = require("numeral");
import { useService } from "../../Core/DependencyInjection";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";

export type ColumnOptions = {
    stickyColumn?: boolean;
};

export interface ICustomColumnParams<T> {
    id?: number;
    title?: string;
    tooltip?: string;
    cssClasses?: string | (() => string);

    className?: string;

    headerColSpan?: number;
    headerCssClasses?: string;
    headerNodesProvider?: () => ReactNode;

    footerColSpan?: number;
    footerCssClasses?: string | (() => string);
    footerNodesProvider?: () => ReactNode;

    children?: ReactNode;
    style?: CSSProperties;
    visible?: boolean;

    sorter?: (a: T, b: T) => number;
    filterComponent?: () => React.ReactElement;

    aggregateOn?: (item: ITableItem<T>) => any;
    aggregationFormatter?: (value: any) => string;
    defaultAggregation?: TableFooterAggregationMode;

    forwardRef?: (c: Column<T>) => void;
}

export interface IColumnHeaderContentParams {
    children?: ReactNode;
}

export class ColumnHeader {
    public get style() {
        return this.props.style;
    }

    constructor(private props: { style?: React.CSSProperties; children?: React.ReactNode | (() => React.ReactNode) }) {}

    render() {
        return <></>;
    }

    renderHeader() {
        if (typeof this.props.children[0] === "function") {
            const template = this.props.children[0] as () => React.ReactNode;
            return <>{template()}</>;
        } else {
            const nodes = [];
            ComponentUtils.Children.forEach(this.props.children as React.ReactNode, (c) =>
                nodes.push(ko.cleanNode(ComponentUtils.cloneNode(c as Node)))
            );

            return <>{nodes}</>;
        }
    }
}

export class ColumnBody<T> {
    constructor(private props: { children: React.ReactNode<T> | ((item: ITableItem<T>) => React.ReactNode) }) {}

    render() {
        return <></>;
    }

    renderBody(item: ITableItem<T>) {
        if (typeof this.props.children[0] === "function") {
            const template = this.props.children[0] as (item: ITableItem<T>) => React.ReactNode;
            return <>{template(item)}</>;
        } else {
            const nodes = [];
            ComponentUtils.Children.forEach(this.props.children as React.ReactNode<T>, (c) =>
                nodes.push(ko.cleanNode(ComponentUtils.cloneNode(c as Node)))
            );

            return <>{nodes}</>;
        }
    }
}

export class ColumnFooter<T> {
    constructor(private props: { children?: React.ReactNode<T> | ((items: ITableItem<T>[]) => React.ReactNode) }) {}

    render() {
        return <></>;
    }

    renderFooter(items: ITableItem<T>[]) {
        if (typeof this.props.children[0] === "function") {
            const template = this.props.children[0] as (items: ITableItem<T>[]) => React.ReactElement;
            const nodes = template(items);
            return <>{nodes}</>;
        } else {
            const nodes = [];
            ComponentUtils.Children.forEach(this.props.children as React.ReactNode<T>, (c) => {
                const node = ko.cleanNode(ComponentUtils.cloneNode(c as Node));
                nodes.push(node);
            });

            return <>{nodes}</>;
        }
    }
}

type FooterAggregationFuncionsDictionary = { [mode: number]: (items) => string };
type FooterAggregationFuncionsLabelsDictionary = { [mode: number]: string };

export type AggregatorProps<T> = {
    items?: ko.ObservableArray<T> | ko.Computed<T[]>;
    //value?: ko.Observable<any>;
    aggregationMode?: ko.Observable<TableFooterAggregationMode>;
    aggregateOn: (item: T) => any;
    formatter?: (value: any) => string;
};

export class Aggregator<T> {
    static defaultProps: Partial<AggregatorProps<any>> = {};

    value: ko.Computed<string>;
    aggregatorLabel: ko.Computed<string>;

    private aggregationFunctions: FooterAggregationFuncionsDictionary = {};
    private aggregationFunctionsLabels: FooterAggregationFuncionsLabelsDictionary = {};
    private subscriptions: ko.Subscription[] = [];

    constructor(private props: AggregatorProps<T>) {
        if (!this.props.items) this.props.items = ko.observableArray([]);
        if (!this.props.aggregationMode) this.props.aggregationMode = ko.observable();

        this.aggregationFunctions[TableFooterAggregationMode.Sum] = this.sum.bind(this);
        this.aggregationFunctions[TableFooterAggregationMode.Avg] = this.avg.bind(this);
        this.aggregationFunctions[TableFooterAggregationMode.Count] = this.count.bind(this);
        this.aggregationFunctions[TableFooterAggregationMode.Max] = this.max.bind(this);
        this.aggregationFunctions[TableFooterAggregationMode.Min] = this.min.bind(this);
        this.aggregationFunctions[TableFooterAggregationMode.DistinctCount] = this.distinctCount.bind(this);

        this.aggregationFunctionsLabels[TableFooterAggregationMode.Sum] = TextResources.ProlifeSdk.Sum;
        this.aggregationFunctionsLabels[TableFooterAggregationMode.Avg] = TextResources.ProlifeSdk.Avg;
        this.aggregationFunctionsLabels[TableFooterAggregationMode.Count] = TextResources.ProlifeSdk.Count;
        this.aggregationFunctionsLabels[TableFooterAggregationMode.Max] = TextResources.ProlifeSdk.Max;
        this.aggregationFunctionsLabels[TableFooterAggregationMode.Min] = TextResources.ProlifeSdk.Min;
        this.aggregationFunctionsLabels[TableFooterAggregationMode.DistinctCount] =
            TextResources.ProlifeSdk.DistinctCount;

        this.aggregatorLabel = ko.computed(() => {
            const mode = this.props.aggregationMode();

            if (mode === undefined || mode === null) return "";

            return this.aggregationFunctionsLabels[mode];
        });

        this.value = ko.computed(() => {
            const aggregationMode = this.props.aggregationMode();
            if (aggregationMode === null || aggregationMode === undefined) return "";

            const aggregationFunction = this.aggregationFunctions[aggregationMode];
            const items = this.props.items();
            return aggregationFunction(items);
        });
    }

    //componentDidMount() {}

    componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];
    }

    private getNumericValue(value: any) {
        if (value instanceof Date) {
            return value.valueOf();
        } else if (typeof value === "string") {
            return parseFloat(value);
        } else if (typeof value === "boolean") {
            return value ? 1 : 0;
        }

        return value;
    }

    private getDefaultFormatter(items: T[], aggregator): (val: any) => string {
        const value =
            items.length === 0 ? null : aggregator(items.firstOrDefault((i) => i !== null && i !== undefined));

        if (value instanceof Date) {
            return (v) => moment(v).format("L");
        } else if (typeof value === "number" || typeof value === "bigint") {
            return (v) => numeral(v).format("0,0.00[0]");
        } else if (typeof value === "boolean") {
            return (v) => (v ? "SI" : "NO");
        }

        return (v) => v;
    }

    private sum(items: T[]): string {
        const defaultFormatter = this.getDefaultFormatter(items, this.props.aggregateOn);
        const val = items.sum((i) => this.getNumericValue(this.props.aggregateOn(i) ?? "0")) ?? 0;
        return this.props.formatter ? this.props.formatter(val) : defaultFormatter(val);
    }

    private avg(items: T[]): string {
        const defaultFormatter = this.getDefaultFormatter(items, this.props.aggregateOn);
        const val =
            items.sum((i) => this.getNumericValue(this.props.aggregateOn(i) ?? 0)) /
            (items.length === 0 ? 1 : items.length);
        return this.props.formatter ? this.props.formatter(val) : defaultFormatter(val);
    }

    private count(items: T[]): string {
        let count = 0;
        for (const item of items) {
            const value = this.props.aggregateOn(item);
            count += value !== null && value !== undefined && value !== "" ? 1 : 0;
        }
        return this.props.formatter ? this.props.formatter(count) : count.toString();
    }

    private max(items: T[]): string {
        let maxValue = null;
        for (const item of items) {
            const value = this.props.aggregateOn(item);
            maxValue =
                (maxValue === null || value > maxValue) && value !== undefined && value !== null && value !== ""
                    ? value
                    : maxValue;
        }

        if (maxValue === null) return "";

        return this.props.formatter ? this.props.formatter(maxValue) : maxValue.toString();
    }

    private min(items: T[]): string {
        let minValue = null;
        for (const item of items) {
            const value = this.props.aggregateOn(item);
            minValue =
                (minValue === null || value < minValue) && value !== undefined && value !== null && value !== ""
                    ? value
                    : minValue;
        }

        if (minValue === null) return "";

        return this.props.formatter ? this.props.formatter(minValue) : minValue.toString();
    }

    private distinctCount(items: T[]): string {
        const distinctValues = {};
        let count = 0;

        for (const item of items) {
            const value = this.props.aggregateOn(item);
            if (value !== null && value !== undefined && value !== "" && !distinctValues[value]) {
                distinctValues[value] = value;
                count++;
            }
        }

        return this.props.formatter ? this.props.formatter(count) : count.toString();
    }

    public render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const columnAggregator = this;
        return ComponentUtils.bindTo(
            <span>
                <span style={{ fontWeight: "bold" }} data-bind={{ if: !!columnAggregator.aggregatorLabel }}>
                    <span data-bind={{ text: columnAggregator.aggregatorLabel }}></span>
                </span>
                &nbsp;
                <span data-bind={{ text: columnAggregator.value }}></span>
            </span>,
            this,
            "columnAggregator"
        );
    }
}

function Identifier(props: PropsWithChildren<{ style?: CSSProperties }>) {
    const { children, ...rest } = props;

    return (
        <div className="with-identifier">
            <span className="identifier" {...rest}></span>
            {children}
        </div>
    );
}

export class Column<T> {
    static WithIdentifier = Identifier;

    isColumn = true;
    id: number;
    private m_header: ColumnHeader;
    private m_body: ColumnBody<T>;
    private m_footer: ColumnFooter<T>;

    public visible: ko.Observable<boolean> = ko.observable();
    public order: ko.Observable<number> = ko.observable();
    public aggregationMode: ko.Observable<TableFooterAggregationMode> = ko.observable();
    public width: ko.Observable<number> = ko.observable();
    public height: ko.Observable<number> = ko.observable();
    public textColor: ko.Observable<string> = ko.observable("#000");
    public useCustomTextColor: ko.Observable<boolean> = ko.observable(false);
    public backgroundColor: ko.Observable<string> = ko.observable("#fff");
    public useCustomBackgroundColor: ko.Observable<boolean> = ko.observable(false);
    public breakText: ko.Observable<boolean> = ko.observable(false);
    public sorting: ko.Observable<boolean> = ko.observable();
    public sortingAsc: ko.Observable<boolean> = ko.observable();
    public sortingDesc: ko.Observable<boolean> = ko.observable();

    public get sorter() {
        return this.props.sorter;
    }

    public get title() {
        return this.props.title;
    }

    public get hasTitle() {
        return (this.props.title || "").trim().length != 0;
    }

    public get hasAggregator(): boolean {
        return !this.m_footer && !this.props.footerNodesProvider && !!this.props.aggregateOn;
    }

    public get header() {
        return this.m_header;
    }
    public get body() {
        return this.m_body;
    }

    public get footer() {
        return this.m_footer;
    }

    constructor(private props: ICustomColumnParams<T>) {
        const bodyNodes = [];
        ComponentUtils.Children.forEach(this.props.children, (c) => {
            if (ComponentUtils.isOfType(c, ColumnHeader)) this.m_header = ComponentUtils.getComponent(c);
            else if (ComponentUtils.isOfType(c, ColumnBody)) this.m_body = ComponentUtils.getComponent(c);
            else if (ComponentUtils.isOfType(c, ColumnFooter)) this.m_footer = ComponentUtils.getComponent(c);
            else bodyNodes.push(c);
        });

        if (bodyNodes.length > 0 && !this.m_body) this.m_body = new ColumnBody({ children: bodyNodes });

        this.id = this.props.id;
        this.visible(props.visible ?? true);

        if (this.props.defaultAggregation !== null && this.props.defaultAggregation !== undefined)
            this.aggregationMode(this.props.defaultAggregation);

        if (props.forwardRef) props.forwardRef(this);
    }

    renderFooter(items: ko.ObservableArray<ITableItem<T>>): ReactNode {
        if (!this.m_footer && !this.props.footerNodesProvider && !this.props.aggregateOn) return null;

        const attr = {};
        if (this.props.footerCssClasses)
            attr["data-bind"] =
                "css: " +
                (this.props.footerCssClasses
                    ? '"' + this.props.footerCssClasses + '"'
                    : this.props["bind-footerCssClasses"] || "");

        const node = ComponentUtils.setBinding(
            <td {...attr} colSpan={this.props.footerColSpan} className={this.props.className}>
                {this.m_footer && this.m_footer.renderFooter(items())}
                {!this.m_footer && this.props.footerNodesProvider && this.props.footerNodesProvider()}
                {!this.m_footer && !this.props.footerNodesProvider && this.props.aggregateOn && (
                    <Aggregator
                        items={items}
                        aggregateOn={this.props.aggregateOn}
                        formatter={this.props.aggregationFormatter}
                        aggregationMode={this.aggregationMode}
                    />
                )}
            </td>,
            { visible: this.visible }
        );

        return node;
    }

    renderHeader(table: Table<T>, options: ColumnOptions): ReactNode {
        const classes = ComponentUtils.classNames(
            {
                sortable: !!this.props.sorter,
                "sticky-column": options.stickyColumn,
            },
            this.props.headerCssClasses,
            this.props.className
        );

        const dataBind: any = {
            visible: this.visible,
            style: {
                "min-width": ko.pureComputed(() => (this.width() ? this.width() + "px" : null)),
            },
        };

        if (this.useCustomBackgroundColor()) dataBind.style["background-color"] = this.backgroundColor();

        if (this.useCustomTextColor()) dataBind.style.color = this.textColor();

        if (this.props.sorter) {
            dataBind.css = {
                sorting_asc: ko.pureComputed(() => this.sorting() && this.sortingAsc()),
                sorting_desc: ko.pureComputed(() => this.sorting() && this.sortingDesc()),
            };

            dataBind.click = (vm: Table<T>, event: Event) => {
                if ($(event.target).closest(".ignore-sort").length > 0)
                    //Se ho cliccato un pulsante sull'header
                    return;

                event.preventDefault();
                event.stopPropagation();
                table.sortBy(this);
            };
            //dataBind.clickBubble = false;
        }

        const showFilterPopover = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();

            const ds = useService<IDialogsService>(nameof<IDialogsService>());
            ds.ShowPopoverComponent(
                e.currentTarget as HTMLElement,
                {
                    title: this.props.title,
                    content: this.props.filterComponent,
                },
                "bottom"
            );
        };

        const finalStyle = Object.assign({}, this.props.style ?? {}, this.m_header?.style ?? {});

        return ComponentUtils.setBinding(
            <th className={classes} style={finalStyle} colSpan={this.props.headerColSpan} title={this.props.tooltip}>
                {this.m_header && this.m_header.renderHeader()}
                {!this.m_header && (
                    <>
                        {this.props.title}
                        {this.props.headerNodesProvider ? this.props.headerNodesProvider() : <></>}
                    </>
                )}
                {this.props.filterComponent && (
                    <button
                        className="btn btn-xs btn-default pull-right"
                        style={{ lineHeight: 1, paddingLeft: "3px", paddingRight: "3px" }}
                        onClick={(e) => showFilterPopover(e)}
                    >
                        <i className="fa fa-filter" />
                    </button>
                )}
            </th>,
            dataBind
        );
    }

    renderBody(item: ITableItem<T>, options: ColumnOptions) {
        const bindings = [];
        if (this.props.cssClasses)
            bindings.push(
                "css: " +
                    (this.props.cssClasses ? '"' + this.props.cssClasses + '"' : this.props["bind-cssClasses"] || "")
            );
        if (this.props["data-bind"]) bindings.push(this.props["data-bind"]);

        const attr: any = {};
        if (bindings.length > 0) attr["data-bind"] = bindings.join(", ");

        const bodyBindings: any = {
            style: {
                width: ko.pureComputed(() => (this.width() ? this.width() + "px" : null)),
            },
            css: {
                "text-ellipsis": ko.pureComputed(() => !this.breakText()),
                "break-text": this.breakText,
            },
        };

        if (this.height()) {
            bodyBindings.style["max-height"] = this.height() + "px";
            bodyBindings.style.overflow = "auto";
        }

        if (this.useCustomTextColor()) bodyBindings.style.color = this.textColor();

        const body = ComponentUtils.setBinding(
            <span className="pr_table_column_body">{this.m_body?.renderBody(item)}</span>,
            bodyBindings
        );

        const backgroundColor = this.backgroundColor();
        const finalStyle = Object.assign({}, this.props.style ?? {}, {
            "background-color": this.useCustomBackgroundColor() ? backgroundColor : undefined,
        });

        return ComponentUtils.setBinding(
            <td
                {...attr}
                style={finalStyle}
                className={ComponentUtils.classNames(this.props.className, { "sticky-column": options.stickyColumn })}
            >
                {body}
            </td>,
            { visible: this.visible }
        );
    }

    render(): ReactNode {
        return <></>;
    }
}
