import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { Layout } from "../../../Components/Layouts";
import { Portlet, PortletBody, PortletHeader } from "../../../Components/Portlet";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { BackButton } from "../../../Components/BackButton";
import { ListItem } from "../../../Components/ListComponent";
import { IJobOrderDataSourceModel } from "../../../DataSources/JobOrdersDataSource";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { SecondaryRow } from "../../../Components/TableComponent/SecondaryRow";
import { Statement } from "./Statement";
import { IFunctionPointComputationService, IInfrastructureStatement, IInfrastructureStatementEnvelope } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IJobOrderForTaskBoard } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { StatementEditor } from "./StatementEditor";

const styleSheet = jss.createStyleSheet({
    
}).attach();

const { classes } = styleSheet;

type StatementsListProps = {
    onBack: () => void;
    
    infrastructureId: number;
}

type IInfrastructureStatementEnvelopeEx = IInfrastructureStatementEnvelope & {
    Expanded: ko.Observable<boolean>;
    JobOrders: ko.ObservableArray<IJobOrderForTaskBoard>;
}

export function StatementsList(props: StatementsListProps) {
    const C = require("./StatementsList")._StatementsList as typeof _StatementsList;
    return <C {...props} />;
}

export class _StatementsList {
    static defaultProps: Partial<StatementsListProps> = {

    }

    @LazyImport(nameof<IFunctionPointComputationService>())
    private functionPointComputationService : IFunctionPointComputationService;
    
    subTitle: ko.Observable<string> = ko.observable();
    statements : ko.ObservableArray<IInfrastructureStatementEnvelopeEx> = ko.observableArray();
    statementId: ko.Observable<number> = ko.observable();

    constructor(private props: StatementsListProps) {
    }

    componentDidMount() {
        this.loadStatements();
    }

    private async loadStatements() {
        const infrastructure = await this.functionPointComputationService.getInfrastructureEnvelopeById(this.props.infrastructureId);
        const statements = await this.functionPointComputationService.getStatements(this.props.infrastructureId);
        this.statements(statements.map(this.createStatement, this));

        this.subTitle(" - " + infrastructure.Title);
    }

    private createStatement(statement: IInfrastructureStatementEnvelope) : IInfrastructureStatementEnvelopeEx {
        const expanded = ko.observable(false);
        const jobOrders = ko.observableArray<IJobOrderForTaskBoard>();
        let loaded = false;

        expanded.subscribe(async (newValue) => {
            if(!newValue || loaded) return;

            const statementJobOrders = await this.functionPointComputationService.getStatementJobOrders(statement.Id);
            jobOrders(statementJobOrders);
        });

        return {
            ...statement,
            Expanded: expanded,
            JobOrders: jobOrders
        }
    }

    createStatementModel(item: IInfrastructureStatementEnvelopeEx): IDataSourceModel<number, IInfrastructureStatementEnvelopeEx> {
        return {
            id: item.Id,
            title: item.Title,
            subTitle: item.Description,
            isGroup: false,
            isLeaf: true,
            model: item
        }
    }

    createJobOrderModel(jobOrder: IJobOrderForTaskBoard): IJobOrderDataSourceModel {
        return {
            id: jobOrder.Id,
            title: jobOrder.Name,
            isGroup: false,
            isLeaf: true,
            model: jobOrder
        };
    }

    private onNewStatement() {
        this.statementId(-1);
        this.render();
    }

    private onEditStatement(statement: IInfrastructureStatementEnvelopeEx) {

    }

    private onDeleteStatement(statement: IInfrastructureStatementEnvelopeEx) {
        
    }

    renderStatementTable() {
        let statementDataSource = Table.defaultDataSource(this.statements, (item) => this.createStatementModel(item));
        let row: IDataSourceModel<number, IInfrastructureStatementEnvelopeEx>;
        let joRow: IDataSourceModel<number, IJobOrderForTaskBoard>;
        return <Layout.ScrollContainer>
            <Table dataSource={statementDataSource} showColumnSelector rowAs={"row"}>
                <Column style={{ width: "40px" }}>
                    <ColumnBody>
                        {(item: ITableItem<IInfrastructureStatementEnvelopeEx>) =>
                            <button className="btn btn-transparent btn-xs" onClick={() => item.Data.model.Expanded(!item.Data.model.Expanded())}>
                                <i className="fa" data-bind={{ css: { "fa-caret-down": row.model.Expanded(), "fa-caret-right": !row.model.Expanded() } }}></i>
                            </button>
                        }
                    </ColumnBody>
                </Column>
                <Column title="Titolo">
                    <span data-bind={{ text: row.model.Title }}></span>
                </Column>
                <Column title="Referente">
                    <span data-bind={{ text: "User" }}></span>
                </Column>
                <Column title="Data Richiesta">
                    <span data-bind={{ text: "1/1/2030" }}></span>
                </Column>
                <Column title="Data Rilascio">
                    <span data-bind={{ text: "1/1/2030" }}></span>
                </Column>
                <Column title="Data Chiusura">
                    <span data-bind={{ text: "1/1/2030" }}></span>
                </Column>
                <Column title="Part1 Documento">
                    <span data-bind={{ text: "3 parti" }}></span>
                </Column>
                <Column>
                    <ColumnBody>
                        {(item: ITableItem<IInfrastructureStatementEnvelopeEx>) =>
                            <div>
                                <button className="btn btn-danger btn-xs btn-cirtcle btn-icon-only" onClick={() => this.onDeleteStatement(item.Data.model)}>
                                    <i className="fa fa-trash-o"></i>
                                </button>
                                <button className="btn btn-primary btn-xs btn-circle btn-icon-only" onClick={() => this.onEditStatement(item.Data.model)}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </div>
                        }
                    </ColumnBody>
                </Column>
                <SecondaryRow if={() => "row.model.Expanded"}>
                    {(item: ITableItem<IInfrastructureStatementEnvelopeEx>) =>
                        <td colSpan={4}>
                            <Table dataSource={{ array: item.Data.model.JobOrders, factory: (item) => this.createJobOrderModel(item) }} rowAs="joRow">
                                <Column title="Commesse collegate:">
                                    <span data-bind={{ text: joRow.model.Name }}></span>
                                </Column>
                            </Table>
                        </td>}
                </SecondaryRow>
            </Table>
        </Layout.ScrollContainer>;
    }

    renderEditorLayout() {
        return <Layout.ScrollContainer>
            <StatementEditor statement={null} onClose={() => {}} onStatementSaved={() => {}} />
        </Layout.ScrollContainer>
    }

    /*renderStatementsList(item: ListItem<number, Statement>) {
        return <Portlet className={"task-section"} collapsible={false}>
            <PortletHeader>
                <Portlet.Header.Default title={"Dati scheda"} />
            </PortletHeader>
            <Portlet.Actions>
                <button className={"btn btn-success btn-circle btn-sm"} onClick={() => this.props.onNewStatement()}>
                    <i class="fa fa-floppy-o"></i>&nbsp;Salva
                </button>
                <button className="btn btn-circle btn-primary " onClick={() => this.props.onEditStatement(item.Model)}>
                    <i class="fa fa-pencil"></i>&nbsp;Modifica
                </button>
                <button className="btn btn-circle btn-danger " onClick={() => this.props.onDeleteStatement(item.Model)}>
                    <i class="fa fa-trash-o"></i>&nbsp;Elimina
                </button>
            </Portlet.Actions>
            <PortletBody>
                {() =>
                    <div className="flex-container">
                        <div className="flex-1">
                            <h4 className="" style="width: 250px" data-bind={{ text: item.Title }}></h4>
                            <span style="width: 350px" data-bind={{ text: item.Subtitle }}></span>
                        </div>
                        <div className="flex-1">
                            <h4>Lista Commesse</h4>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                        <div className="flex-1">
                            <h4>Lista Requisiti</h4>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                }
            </PortletBody>
        </Portlet>
    }*/

    render() {
        return  <Layout.Grid columns={["1fr"]} rows={["150px", "auto"]}>
                    <Layout.Grid.Cell column={"1/3"} row={1}>
                        <Portlet collapsible={false}>
                            <PortletHeader>
                                <BackButton style={{ marginRight: "20px" }} onClick={() => this.props.onBack()} />
                                <Portlet.Header.Default title={"Schede"} subTitle={this.subTitle} className={""} />
                            </PortletHeader>
                            <Portlet.Actions>
                                <button className="btn btn-circle btn-primary" onClick={() => this.onNewStatement}>
                                    <i class="fa fa-pencil"></i>&nbsp;Aggiungi Scheda
                                </button>
                            </Portlet.Actions>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        {/*<List dataSource={{ array: this.statements, factory: (item) => this.createStatementModel(item) }} itemRenderer={this.renderStatementsList} /> */}
                        {(this.statementId() < 0)?this.renderEditorLayout():this.renderStatementTable()}
                    </Layout.Grid.Cell>
                </Layout.Grid>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(StatementsList);
}