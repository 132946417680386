import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { RequestForQuotationDocumentProtocolSettings } from "./RequestForQuotationDocumentProtocolSettings";
import { IProtocolType, IVatRegisterEditingContainer } from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Right } from "../../../Core/Authorizations";

export class RequestForQuotationDocumentProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.RequestForQuotationTypeId;
    public DocumentTypeName: string = ProlifeSdk.TextResources.Provisioning.RequestsForQuotation;
    public DocumentTypeLabelForSettings: string = ProlifeSdk.TextResources.Provisioning.RequestsForQuotationProtocols;
    public DocumentTypeShortLabelForSettings: string =
        ProlifeSdk.TextResources.Provisioning.RequestsForQuotationProtocolsShortLabel;

    HasCustomTemplate = true;

    @Right("Provisioning_RequestForQuotation")
    private canAddProtocolsRight: boolean;

    constructor() {}

    canAddProtocols(): boolean {
        return this.canAddProtocolsRight;
    }

    createSettingsViewModel(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister
    ): RequestForQuotationDocumentProtocolSettings {
        return new RequestForQuotationDocumentProtocolSettings(container, vatRegister);
    }
}
