import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Html } from "../../../../Components/Html";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { HoursApprovalStateUtilities } from "../approval/HoursApprovalStateUtilities";

const styleSheet = jss.createStyleSheet({
    approvalRow: {
        display: "flex",
        alignItems: "stretch",

        "& > div": {
            padding: "0px 5px !important",
            borderBottom: "1px solid #ddd",
            whiteSpace: "break-spaces"
        }
    }
});
const { classes } = styleSheet.attach();

type ApprovalRowProps = {
    approverId: number;
    group: string;
    role: string;
    outcome: HoursApprovalState;
    notes: string;
}

export function ApprovalRow(props: ApprovalRowProps) {
    const C = require("./ApprovalRow")._ApprovalRow as typeof _ApprovalRow;
    return <C {...props} />;
}

export class _ApprovalRow {
    static defaultProps: Partial<ApprovalRowProps> = {
    }

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager : IHumanResourcesSettingsManager;

    constructor(private props : ApprovalRowProps) {
        
    }

    private getApproverName(): string {
        const resource = this.humanResourcesSettingsManager.getHumanResourceById(this.props.approverId);
        if (!resource)
            return TextResources.ProlifeSdk.NotAvailable;

        return this.humanResourcesSettingsManager.getFullName(resource);
    }

    private getApprovalState(): string {
        return HoursApprovalStateUtilities.getLabelFor(this.props.outcome);
    }
    
    render() {
        return  <div className={ComponentUtils.classNames("row", "no-gutters", classes.approvalRow)}>
                    <div className="col-md-2">
                        {this.getApproverName()}
                    </div>
                    <div className="col-md-2">
                        {this.props.role}
                    </div>
                    <div className="col-md-2">
                        {this.props.group}
                    </div>
                    <div className="col-md-2">
                        {this.getApprovalState()}
                    </div>
                    <div className="col-md-4">
                        <Html html={ko.observable(this.props.notes ?? TextResources.ProlifeSdk.NotAvailable)} />
                    </div>
                </div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ApprovalRow);
}