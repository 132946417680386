import * as ko from "knockout";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAllocationsService } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IManualAllocationWorkPoolRole, IWorkPool } from "../../interfaces/IManualAllocation";

@DetectClassChanges
export class WorkPoolsEditor {
    @DetectChanges
    public WorkPools : ko.ObservableArray<WorkPool> = ko.observableArray([]);

    public HasChanges: ko.Computed<boolean>;

    public isChanged: ko.Observable<number> = ko.observable(0);

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    constructor(private baseRoles: IManualAllocationWorkPoolRole[]) {
        this.HasChanges = ko.computed(() => {
            let isChanged = this.isChanged() !== 0;
            let workPoolsAreChanged = !!this.WorkPools().firstOrDefault(wp => wp.HasChanges());

            return isChanged || workPoolsAreChanged;
        });
    }

    public async loadWorkPools(workPools: IWorkPool[]): Promise<void> {
        let result = [];
        workPools.forEach(wp => {
            let workPool = new WorkPool(wp, this.baseRoles);
            result.push(workPool);
        });

        this.WorkPools(result);
        this.isChanged(0);
    }

    public setRoles(baseRoles: IManualAllocationWorkPoolRole[]): void {
        this.baseRoles = baseRoles;
    }

    public addWorkPool(roles: IManualAllocationWorkPoolRole[]): void {
        let model = this.createWorkPoolModel();
        let workPool = new WorkPool(model, roles);
        this.WorkPools.push(workPool);
    }

    public removeWorkPool(workPool: WorkPool): void {
        let index = this.WorkPools().indexOf(workPool);
        if (index < 0)
            return;

        this.WorkPools.splice(index, 1);
    }

    public dispose(): void {

    }

    private createWorkPoolModel(): IWorkPool {
        return {
            Id: this.allocationsService.GenerateNextId(),
            HoursAmount: 0,
            Roles: this.baseRoles.slice()
        };
    }
}

@DetectClassChanges
class WorkPool {
    @DetectChanges
    public HoursAmount: ko.Observable<number> = ko.observable(0);
    public Roles: ko.ObservableArray<WorkableRole> = ko.observableArray([]);

    public isChanged : ko.Observable<number> = ko.observable(0);

    public ShowRolesList: ko.Observable<boolean> = ko.observable(false);
    public HasChanges: ko.Computed<boolean>;
    public SelectAllRoles: ko.Computed<boolean>;
    
    private isNewWorkPool: boolean = true;

    constructor(private workPool: IWorkPool, baseRoles: IManualAllocationWorkPoolRole[]) {
        this.isNewWorkPool = !this.workPool.Id || this.workPool.Id <= 0;

        this.HoursAmount(this.workPool.HoursAmount);
        this.generateRoles(baseRoles);

        this.isChanged(0);

        this.HasChanges = ko.computed(() => {
            let isChanged = this.isChanged();
            let rolesAreChanged = this.Roles().filter(r => r.isChanged() !== 0).length > 0;
            return isChanged !== 0 || rolesAreChanged;
        });

        this.SelectAllRoles = ko.computed({
            read: () => {
                let anyoneSelected = false;
                let allSelected = true;
                
                for(let role of this.Roles()) {
                    let roleIsSelected = role.IsSelected();

                    anyoneSelected = anyoneSelected || roleIsSelected;
                    allSelected = roleIsSelected && allSelected;
                }
                return allSelected ? true : (anyoneSelected ? null : false);
            },
            write: (value: boolean) => {
                for(let role of this.Roles()) {
                    role.IsSelected(value);
                }
            }
        });
    }

    public getData(): IWorkPool {
        let wp = Object.assign({}, this.workPool) as IWorkPool;

        wp.HoursAmount = this.HoursAmount();
        wp.Roles = this.Roles().filter(r => r.IsSelected()).map(r => r.getData());

        return wp;
    }

    public dispose(): void {

    }

    private generateRoles(cartRoles: IManualAllocationWorkPoolRole[]): void {
        let roles = this.workPool.Roles;
        
        for (var role of cartRoles) {
            let selected = !!roles.firstOrDefault(r => r.RoleId === role.RoleId) || this.isNewWorkPool;
            let workableRole = new WorkableRole(role, selected);
            this.Roles.push(workableRole);    
        }
    }
}

@DetectClassChanges
class WorkableRole {
    public get RoleId(): number {
        return this.m_role?.RoleId;
    }

    public Description : string;
    @DetectChanges
    public IsSelected: ko.Observable<boolean> = ko.observable(false);

    public isChanged: ko.Observable<number> = ko.observable(0);

    constructor(private m_role: IManualAllocationWorkPoolRole, selected: boolean = false) {
        this.Description = m_role?.RoleName;
        this.IsSelected(selected);

        this.isChanged(0);
    }

    public getData(): IManualAllocationWorkPoolRole {
        return {
            RoleId: this.m_role.RoleId,
            RoleName: this.Description
        };
    }

    public dispose(): void {

    }
}