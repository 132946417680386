import * as ko from "knockout";
/**
 * Created by d.bucchi on 14/06/2017.
 */

import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";
import { INavigationMenuActionBase, INavigationMenuAction } from "../interfaces/navigation-menu/INavigationMenuProvider";

export class ContextMenuDialog implements IDialog {
    private dialogsService: IDialogsService;

    public templateName: string = "context-menu-dialog";
    public templateUrl: string = "TodoList/templates/Dialog";
    public title: string = "Seleziona";
    public actions: INavigationMenuActionBase[];
    modal: { close: (result?: any) => void; };
    private showed: boolean = false;
    private modalDeferred: Promise<boolean> = null;

    constructor(serviceLocator: IServiceLocator, actions?: INavigationMenuActionBase[]) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.actions = actions;
    }

    public ShowModal(): Promise<boolean> {
        if (this.showed)
            return this.modalDeferred;

        this.showed = true;
        return this.modalDeferred = this.dialogsService.ShowModal<boolean>(this, "fullscreen", {}, this.templateUrl, this.templateName);        
    }

    close(): void {
        this.modal && this.modal.close();
    }

    goAction(text: ko.Observable<string>) {
        this.actions.filter(a => !a.IsSeparator).forEach( (a : INavigationMenuAction) =>{
            if (!a.IsSeparator && a.Text() === text()) {
                a.Action();
                this.modal && this.modal.close();
            }
        });
    }

    action(): void {
        this.modal && this.modal.close(true);
    }
}