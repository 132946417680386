import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/02/2018
 * Time: 15:35
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { AgendaTimetableViewModel } from "./AgendaTimetableViewModel";
import { Calendar } from "./Calendar";
import { ResourcesAndGroupsManager } from "./ResourcesAndGroupsManager";
import { FoldersProvider } from "./providers/FoldersProvider";
import { WaitingList } from "./WaitingList";
import { UiUtilities } from "./utils/UiUtilities";
import { DateTimeUtilities } from "./utils/DateTimeUtilities";
import { PlannedEventsAlertDialog } from "./dialogs/PlannedEventsAlertDialog";
import { SelectTimetableForVariationDialog } from "./dialogs/SelectTimetableForVariationDialog";
import { Moment } from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import {
    IAgendaTimetableViewModel,
    IAgendaResource,
    IAgendaService,
    IAgendasDetails,
    IAgenda,
    IEventsNumbersOverview,
    IAgendaTimeslotViewModel,
    IDetailedAgenda,
    IAgendaTimetable,
    IResourceForManager,
} from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import {
    IEventsService,
    IEventCanceledMotivation,
    IAgendaEvent,
    IEventCategory,
    IEventOnDatesIntervalDetails,
    IAgendaEventDescriptor,
} from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IFestivitiesService, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IFolder } from "../../../ProlifeSdk/interfaces/agenda/IFolderService";
import {
    IFullCalendarEventsSource,
    IFullCalendarEventsDragObserver,
    IFullCalendarViewObject,
    IFullCalendarEvent,
    IFullCalendarBusinessHours,
    IFullCalendar,
    IFullCalendarEventsReceiver,
    IFullCalendarHeader,
} from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IAgendaViewModel, IAgendaEditingViewModel } from "../../interfaces/IAgenda";
import {
    IEventCanceledMotivationViewModel,
    IEventsSearchObserver,
    IAgendaEventDescriptorViewModel,
} from "../../interfaces/IAgendaEvent";
import { IEventCanceledMotivationsSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/IEventCanceledMotivationsSettingsManager";
import { ICategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { Deferred } from "../../../Core/Deferred";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { OptionsInput } from "fullcalendar/src/types/input-types";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { LazyImport } from "../../../Core/DependencyInjection";

export class AgendaViewModel implements IFullCalendarEventsSource, IAgendaViewModel, IFullCalendarEventsDragObserver {
    public Calendar: ko.Observable<Calendar> = ko.observable();
    public CalendarOptions: OptionsInput;
    public WaitingList: WaitingList;
    private DateTimeUtilities: DateTimeUtilities;

    public EventCanceledMotivations: ko.ObservableArray<IEventCanceledMotivationViewModel> = ko.observableArray([]);
    public ShowEventCanceledMotivationsList: ko.Observable<boolean> = ko.observable(false);

    public AgendaId: ko.Observable<number> = ko.observable();
    public FolderId: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public TimeslotsModeEnabled: ko.Observable<boolean> = ko.observable();
    public Timetables: ko.ObservableArray<IAgendaTimetableViewModel> = ko.observableArray([]);
    public TimetablesVariations: ko.ObservableArray<IAgendaTimetableViewModel> = ko.observableArray([]);
    public Resources: ko.ObservableArray<IAgendaResource> = ko.observableArray([]);
    public Deleted: ko.Observable<number> = ko.observable();
    public FestivitiesAgendaId: ko.Observable<number> = ko.observable();
    public ShowDialogOnDropOnWaitingList: ko.Observable<boolean> = ko.observable();
    public DaysNumberForBadgeAlert: ko.Observable<number> = ko.observable();
    public DaysNumberForPlannedEventsAlert: ko.Observable<number> = ko.observable();
    public CustomersSelectionOnEventsRequired: ko.Observable<boolean> = ko.observable();
    public CalendarSlotDuration: ko.Observable<number> = ko.observable();
    public SelectedDate: ko.Observable<Date> = ko.observable();

    public FolderName: ko.Observable<string> = ko.observable();

    public IsRelatedAgenda: ko.Observable<boolean> = ko.observable();
    public IsReadOnly: ko.Computed<boolean>;

    public NoTimetablesConfigured: ko.Computed<boolean>;
    public NoTimetablesVariationsConfigured: ko.Computed<boolean>;
    public CalendarTitle: ko.Computed<string>;

    public CanShowExportButton: ko.Computed<boolean>;
    public ExportUrl: ko.Computed<string>;
    public FullAgendaExportUrl: ko.Computed<string>;

    public agendasEditor: IEventsSearchObserver;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    protected dialogsService: IDialogsService;
    protected infoToastService: IInfoToastService;
    protected agendaService: IAgendaService;
    protected eventsService: IEventsService;
    protected festivitiesService: IFestivitiesService;
    protected authorizationService: IAuthorizationService;
    protected authenticationService: IAuthenticationService;
    protected settingsService: ISettingsService;

    private agendaDetails: IAgendasDetails;
    private CanChangeTimeslotMode = true;

    private calendarUpdating = false;
    private eventsAreLoading = false;
    private festivitiesAreLoading = false;

    constructor(protected serviceLocator: IServiceLocator, protected agenda: IAgenda) {
        this.dialogsService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.agendaService = <IAgendaService>this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.eventsService = <IEventsService>this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.festivitiesService = <IFestivitiesService>(
            this.serviceLocator.findService(ProlifeSdk.FestivitiesServiceCode)
        );
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.authenticationService = <IAuthenticationService>(
            this.serviceLocator.findService(ServiceTypes.Authentication)
        );
        this.settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);

        this.agendaService.IsAgendaRelatedToLoggedUser(this.agenda.Id).then((result: boolean) => {
            this.IsRelatedAgenda(result);
        });

        this.DateTimeUtilities = new DateTimeUtilities();

        const canceledMotivationsManager: IEventCanceledMotivationsSettingsManager = <
            IEventCanceledMotivationsSettingsManager
        >this.settingsService.findSettingsManager(ProlifeSdk.EventCanceledMotivationsSettingsManager);
        this.EventCanceledMotivations(
            canceledMotivationsManager
                .getMotivations()
                .filter((m: IEventCanceledMotivation) => m.ForWaitingList)
                .map((m: IEventCanceledMotivation) => {
                    return this.CreateEventCanceledMotivationViewModel(m);
                })
        );

        this.IsReadOnly = ko.computed(() => {
            return (
                !this.authorizationService.isAuthorized("Agenda_EditAgendas") &&
                (!this.authorizationService.isAuthorized("Agenda_EditRelatedAgendas") || !this.IsRelatedAgenda()) &&
                this.agenda.CreatedBy != (<any>this.authenticationService.getUser()).IdUser
            );
        });

        this.AgendaId(this.agenda.Id);
        this.FolderId(this.agenda.FolderId);
        this.Title(this.agenda.Name);
        this.Icon(this.agenda.Icon);
        this.Background(this.agenda.Background);
        this.Foreground(this.agenda.Foreground);
        this.TimeslotsModeEnabled(this.agenda.TimeslotsModeEnabled);
        this.Deleted(this.agenda.Deleted);
        this.FestivitiesAgendaId(this.agenda.FestivitiesAgendaId);
        this.ShowDialogOnDropOnWaitingList(this.agenda.ShowDialogOnDropOnWaitingList);
        this.DaysNumberForBadgeAlert(this.agenda.DaysNumberForBadgeAlert);
        this.DaysNumberForPlannedEventsAlert(this.agenda.DaysNumberForPlannedEventsAlert);
        this.CustomersSelectionOnEventsRequired(this.agenda.CustomersSelectionOnEventsRequired);
        this.CalendarSlotDuration(this.agenda.CalendarSlotDuration);

        this.SetupCalendar();
        this.SetCanChangeTimeslotMode();

        this.WaitingList = new WaitingList(this.serviceLocator, this);
        this.WaitingList.LoadWaitingList().finally(() => {
            let waitingListLength = this.WaitingList.Events().length;
            const waitingListChangesInterceptor = ko.computed(() => {
                if (waitingListLength != this.WaitingList.Events().length) {
                    this.Calendar().RefreshEvents();
                    waitingListLength = this.WaitingList.Events().length;
                }
            });
        });

        this.NoTimetablesConfigured = ko.computed(() => {
            return this.Timetables().length == 0;
        });

        this.NoTimetablesVariationsConfigured = ko.computed(() => {
            return this.TimetablesVariations().length == 0;
        });

        this.CanShowExportButton = ko.computed(() => {
            return this.authorizationService.isAuthorized("Agenda_ExportEvents");
        });

        this.ExportUrl = ko.computed(() => {
            if (!this.Calendar()) return "#";
            if (!this.Calendar().GetActualView()) return "#";
            const view: IFullCalendarViewObject = this.Calendar().GetActualView();
            const startDate = view.start.format("YYYY-MM-DD");
            const endDate = view.end.format("YYYY-MM-DD");

            return (
                "/Agenda/Export/EventsExcel?agendaId=" +
                this.AgendaId() +
                "&startDate=" +
                startDate +
                "&endDate=" +
                endDate
            );
        });

        this.FullAgendaExportUrl = ko.computed(() => {
            return "/Agenda/Export/EventsExcel?agendaId=" + this.AgendaId() + "&startDate=&endDate=";
        });

        this.CalendarTitle = ko.computed(() => {
            if (!this.Calendar()) return "";
            const currentView: IFullCalendarViewObject = this.Calendar().GetActualView();
            if (!currentView) return "";

            return currentView.title;
        });

        this.SelectedDate.subscribe((date: Date) => {
            if (!this.Calendar()) return;

            this.Calendar().GoToDate(moment(date));
        });

        let updating = false;
        this.TimeslotsModeEnabled.subscribe((newSetting: boolean) => {
            if (updating) return;
            updating = true;
            if (!this.CanChangeTimeslotMode) {
                this.dialogsService.Alert(
                    ProlifeSdk.TextResources.Agenda.CannotChengeAgendaConfigurationMessage,
                    ProlifeSdk.TextResources.Agenda.CannotChengeAgendaConfigurationAlert,
                    () => {
                        this.TimeslotsModeEnabled(!newSetting);
                        updating = false;
                    }
                );
            }
        });

        this.CalendarSlotDuration.greaterThan(<ko.Observable<number>>ko.observable(0));

        const timetablesInterceptor = ko.computed(() => {
            this.Timetables();
            this.TimetablesVariations();
            this.SetupCalendar();
        });

        this.CalendarSlotDuration.subscribe(() => {
            this.Calendar().SetSlotDuration(this.getSlotDuration());
            //this.SetupCalendar();
        });
    }

    public SimpleExport(): void {
        if (this.ExportUrl() == "#") return;
        this.ajaxService.DownloadFileFromUrl(this.ExportUrl(), {});
    }

    public FullExport() {
        if (this.FullAgendaExportUrl() == "#") return;
        this.ajaxService.DownloadFileFromUrl(this.FullAgendaExportUrl(), {});
    }

    public SetAgendasEditor(editor: IEventsSearchObserver): void {
        this.agendasEditor = editor;
    }

    public LoadDetails(): Promise<IAgendasDetails> {
        return this.agendaService
            .GetAgendasDetails(this.AgendaId(), false)
            .then((details: IAgendasDetails) => {
                if (!details) return details;
                this.agendaDetails = details;
                this.CreateDetailsViewModels(details);
                return details;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.LoadAgendaDetailsError);
                return null;
            });
    }

    public GetEventsOverview(): Promise<IEventsNumbersOverview> {
        return this.agendaService.GetEventsNumbersOverview(this.AgendaId());
    }

    public GetEventsSources(): any[] {
        return [{ events: this.GetEvents.bind(this) }, { events: this.GetFestivitiesAndClosingDays.bind(this) }];
    }

    public GetEvents(
        start: Moment,
        end: Moment,
        timezone: any,
        callback: (events: IFullCalendarEvent[]) => void
    ): void {
        this.eventsService
            .GetEvents(start.toDate(), end.toDate(), this.AgendaId())
            .then((events: IAgendaEvent[]) => {
                if (!events) {
                    callback([]);
                    return;
                }

                callback(
                    events.map((e: IAgendaEvent) => {
                        e.color = !e.color ? ProlifeSdk.AgendaEventDefaultColor : e.color;
                        const color = e.color;
                        e.id = "E-" + e.id;
                        e.editable = true;
                        e.durationEditable = !this.TimeslotsModeEnabled();
                        e.color = UiUtilities.GetColorWithOpacity(e.color, 0.6);
                        e.textColor = UiUtilities.GetForegroundColorForAlphaColor(color, 0.6, "#FFFFFF");
                        return e;
                    })
                );
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendaEventsError);
            });
    }

    public GetFestivitiesAndClosingDays(
        start: Moment,
        end: Moment,
        timezone: any,
        callback: (events: IFullCalendarEvent[]) => void
    ): void {
        this.festivitiesService
            .GetFestivities(start.toDate(), end.toDate(), this.FestivitiesAgendaId())
            .then((festivities: IFestivity[]) => {
                if (!festivities) {
                    callback([]);
                    return;
                }
                callback(
                    festivities.map((f: IFestivity) => {
                        const color = f.color;
                        f.id = "F-" + f.id;
                        f.editable = false;
                        f.color = UiUtilities.GetColorWithOpacity(f.color, 0.6);
                        f.textColor = UiUtilities.GetForegroundColorForAlphaColor(color, 0.6, "#FFFFFF");
                        return f;
                    })
                );
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFestivitiesError);
            });
    }

    public GetTimetableForDay(date: Moment): IAgendaTimetableViewModel {
        let timeTables: IAgendaTimetableViewModel[] = this.TimetablesVariations().filter(
            (tt: IAgendaTimetableViewModel) => {
                return (
                    moment(tt.StartDate()).startOf("day") <= date &&
                    (!tt.EndDate() || moment(tt.EndDate()).endOf("day") >= date) &&
                    !tt.Deleted()
                );
            }
        );
        if (timeTables.length > 0) return timeTables[0];

        timeTables = this.Timetables().filter((tt: IAgendaTimetableViewModel) => {
            return (
                moment(tt.StartDate()).startOf("day") <= date &&
                (!tt.EndDate() || moment(tt.EndDate()).endOf("day") >= date) &&
                !tt.Deleted()
            );
        });
        if (timeTables.length > 0) return timeTables[0];

        return null;
    }

    public GetActualTimeTable(): IAgendaTimetableViewModel {
        const today = moment();
        return this.GetTimetableForDay(today);
    }

    public GetBusinessHours(periodStart: Moment, periodEnd: Moment): IFullCalendarBusinessHours[] {
        const datesIntervals: any[] = this.CreateDatesIntervalsFromAgendasTimetables(periodStart, periodEnd);
        let filteredTimetables: IAgendaTimetableViewModel[] = [];
        const timetables: IAgendaTimetableViewModel[] = this.Timetables().concat(this.TimetablesVariations());
        const businessHours: IFullCalendarBusinessHours[] = [];
        let lastDate: Moment = moment(periodStart);

        datesIntervals.forEach((i: any) => {
            filteredTimetables = timetables.filter((t: IAgendaTimetableViewModel) => {
                return (
                    moment(i.start) >= moment(t.StartDate()) &&
                    (!i.end || !t.EndDate() || moment(i.end) <= moment(t.EndDate()))
                );
            });

            if (filteredTimetables.length > 1)
                filteredTimetables = filteredTimetables.filter((t: IAgendaTimetableViewModel) => {
                    return t.IsVariation();
                });

            if (filteredTimetables.length == 0) return;

            const workableDays: IAgendaTimeslotViewModel[] = filteredTimetables[0]
                .Timeslots()
                .sort((a: IAgendaTimeslotViewModel, b: IAgendaTimeslotViewModel) => {
                    return a.DayOfWeek() - b.DayOfWeek();
                });

            lastDate =
                lastDate < moment(filteredTimetables[0].StartDate())
                    ? moment(filteredTimetables[0].StartDate())
                    : moment(lastDate);
            const intervalEnd: Moment = moment(i.end);
            while (lastDate <= periodEnd && lastDate <= intervalEnd) {
                workableDays.forEach((d: IAgendaTimeslotViewModel) => {
                    const newDate = moment(lastDate).day(d.DayOfWeek());
                    if (newDate < lastDate) return;
                    businessHours.push({
                        dow: [d.DayOfWeek()],
                        start: this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(
                            newDate,
                            d.StartTime()
                        ),
                        end: this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(newDate, d.EndTime()),
                    });
                });
                lastDate = moment(lastDate).endOf("week").add("days", 1);
            }
        });

        return businessHours;
    }

    public IsTimetableRespected(
        eventStart: any,
        eventEnd: any,
        allDayEvent: boolean,
        checkOnEventDuration = false
    ): boolean {
        if (!this.TimeslotsModeEnabled()) return true;

        const actualTimetable: IAgendaTimetableViewModel = this.GetTimetableForDay(moment(eventStart));
        if (!actualTimetable) return false;

        const timeslots: IAgendaTimeslotViewModel[] = actualTimetable.GetTimeslotsForDay(moment(eventStart).toDate());
        if (timeslots.length == 0) return false;

        let result = false;
        timeslots.forEach((t: IAgendaTimeslotViewModel) => {
            if (t.IsTimeslotRespected(moment(eventStart).toDate(), moment(eventEnd).toDate(), checkOnEventDuration))
                result = true;
        });

        return result;
    }

    public IsMaxNumberOfEventsForTimeRespected(eventStart: any, eventId: any = null): boolean {
        if (!this.TimeslotsModeEnabled()) return true;

        const actualTimetable: IAgendaTimetableViewModel = this.GetTimetableForDay(moment(eventStart));
        if (!actualTimetable) return false;

        const timeslots: IAgendaTimeslotViewModel[] = actualTimetable.GetTimeslotsForDay(moment(eventStart).toDate());
        if (timeslots.length == 0) return false;

        const timeslotsForTime = timeslots.filter((t: IAgendaTimeslotViewModel) => {
            const eventTime = moment(eventStart).format("LT");
            return (
                this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(eventStart, moment(t.StartTime())) <=
                    eventTime &&
                this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(eventStart, moment(t.EndTime())) >
                    eventTime
            );
        });

        if (timeslotsForTime.length == 0) return false;

        const events: IAgendaEvent[] = this.Calendar().GetEvents();
        let numberOfEvents = 0;
        events.forEach((e: IAgendaEvent) => {
            if (
                (<any>moment(e.start.seconds(0)).local()).isSame(moment(eventStart.seconds(0)).local()) &&
                (!eventId || e.id != eventId)
            )
                numberOfEvents++;
        });

        return numberOfEvents < timeslotsForTime[0].ContemporaryEventsNumber();
    }

    public UpdateFromModel(agenda: IDetailedAgenda): void {
        this.FolderId(agenda.FolderId);
        this.Title(agenda.Name);
        this.Icon(agenda.Icon);
        this.Background(agenda.Background);
        this.Foreground(agenda.Foreground);
        this.TimeslotsModeEnabled(agenda.TimeslotsModeEnabled);
        this.Deleted(agenda.Deleted);
        this.FestivitiesAgendaId(agenda.FestivitiesAgendaId);
        this.ShowDialogOnDropOnWaitingList(agenda.ShowDialogOnDropOnWaitingList);
        this.DaysNumberForBadgeAlert(agenda.DaysNumberForBadgeAlert);
        this.DaysNumberForPlannedEventsAlert(agenda.DaysNumberForPlannedEventsAlert);
        this.CustomersSelectionOnEventsRequired(agenda.CustomersSelectionOnEventsRequired);
        this.CalendarSlotDuration(agenda.CalendarSlotDuration);

        const details: IAgendasDetails = {
            Timetables: agenda.Timetables,
            TimetablesVariations: agenda.TimetablesVariations,
            Resources: agenda.Resources,
        };

        this.CreateDetailsViewModels(details);
    }

    public RenderEventOnCalendar(event: IAgendaEvent): void {
        this.Calendar().RenderEvent(event);
    }

    public UpdateEventOnCalendar(event: IAgendaEvent): void {
        this.Calendar().UpdateEvent(event);
    }

    public RemoveEventFromCalendar(eventId: number): void {
        this.Calendar().RemoveEvent(eventId);
    }

    public RefreshWaitingList(): void {
        this.WaitingList.LoadWaitingList();
    }

    public RefreshCalendar(): void {
        this.Calendar().RefreshEvents();
    }

    public OnEventDragStart(
        event: IAgendaEvent,
        jsEvent: any,
        view: IFullCalendarViewObject,
        sourceCalendar: IFullCalendar
    ): void {
        this.ShowEventCanceledMotivationsList(true);
    }

    public OnEventDragStop(
        event: IAgendaEvent,
        jsEvent: any,
        view: IFullCalendarViewObject,
        sourceCalendar: IFullCalendar
    ): void {
        const motivations: IFullCalendarEventsReceiver[] = <any[]>this.EventCanceledMotivations();

        let validDrop = false;
        for (let i = 0; i < motivations.length; i++) {
            if (!motivations[i].IsEventOverMe) continue;

            if (motivations[i].IsEventOverMe(jsEvent.clientX, jsEvent.clientY)) {
                validDrop = true;
                this.InsertEventInWaitingList(event, (<any>motivations[i]).Id, sourceCalendar).then(
                    (inserted: boolean) => {
                        this.ShowEventCanceledMotivationsList(false);
                    }
                );
                break;
            }
        }

        if (!validDrop) this.ShowEventCanceledMotivationsList(false);
    }

    public OnEventDroppedOnCanceledMotivation(event: IAgendaEvent, canceledMotivationId: number): void {
        const selectedEvents: IAgendaEventDescriptorViewModel[] = this.agendasEditor
            .EventsSearchViewModel()
            .SelectedResults();

        event.CategoryId = this.GetEventCategoryIdByLogicalState(ProlifeSdk.WaitingListEventState);

        if (this.ShowDialogOnDropOnWaitingList() && selectedEvents.length <= 1) {
            this.eventsService.ui
                .GetEventEditingDialog(event.id, event.AgendaId)
                .then((modifiedEvent: IAgendaEvent) => {
                    this.ShowEventCanceledMotivationsList(false);

                    if (!modifiedEvent) return;

                    (<any>this.agendasEditor).RefreshAll();
                });
        } else {
            if (selectedEvents.length <= 1) {
                this.agendaService
                    .InsertEventInWaitingList(event.id, canceledMotivationId)
                    .then(() => {
                        (<any>this.agendasEditor).RefreshAll();
                    })
                    .finally(() => {
                        this.ShowEventCanceledMotivationsList(false);
                    });
            } else {
                this.eventsService
                    .SetEventsState(
                        selectedEvents.map((e: IAgendaEventDescriptorViewModel) => {
                            return e.Id;
                        }),
                        event.CategoryId,
                        canceledMotivationId
                    )
                    .then(() => {
                        (<any>this.agendasEditor).RefreshAll();
                    })
                    .finally(() => {
                        this.ShowEventCanceledMotivationsList(false);
                    });
            }
        }
    }

    public RefreshEditorMenu(): void {
        this.agendasEditor.RefreshMenu();
    }

    public OnEventModified(event: IAgendaEvent): void {
        this.RefreshEditorMenu();
        this.RefreshWaitingList();
    }

    private GetEventCategoryIdByLogicalState(logicalState: number): number {
        const settingsService: ISettingsService = <ISettingsService>(
            this.serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        const categoriesManager: ICategoriesSettingsManager = <ICategoriesSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager)
        );
        return categoriesManager.getCategories().filter((c: IEventCategory) => c.LogicalState == logicalState)[0].Id;
    }

    private InsertEventInWaitingList(
        event: IAgendaEvent,
        canceledMotivationId: number,
        sourceCalendar: IFullCalendar
    ): Promise<boolean> {
        const def = new Deferred<boolean>();

        const eventId: number = event.id.split("-").length > 1 ? parseInt(event.id.split("-")[1]) : parseInt(event.id);

        if (this.ShowDialogOnDropOnWaitingList()) {
            const originalState: number = event.CategoryId;
            const categoriesManager: ICategoriesSettingsManager = <ICategoriesSettingsManager>(
                this.settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager)
            );
            event.CategoryId = categoriesManager
                .getCategories()
                .filter((c: IEventCategory) => c.LogicalState == ProlifeSdk.WaitingListEventState)[0].Id;

            sourceCalendar.ModifyEvent(event).then((modifiedEvent: IAgendaEvent) => {
                if (!modifiedEvent) {
                    event.CategoryId = originalState;
                    def.resolve(false);
                    return;
                }
                def.resolve(true);
            });
        } else {
            this.agendaService
                .InsertEventInWaitingList(eventId, canceledMotivationId)
                .then(() => {
                    sourceCalendar.RemoveEvent(event.id);
                    this.RefreshWaitingList();
                    this.RefreshEditorMenu();
                    def.resolve(true);
                })
                .catch(() => {
                    def.reject();
                });
        }
        return def.promise();
    }

    private CreateEventCanceledMotivationViewModel(
        motivation: IEventCanceledMotivation
    ): IEventCanceledMotivationViewModel {
        return new EventCanceledMotivationViewModel(this.serviceLocator, motivation, this);
    }

    private CreateDatesIntervalsFromAgendasTimetables(periodStart: Moment, periodEnd: Moment): any[] {
        const dates: Moment[] = [];
        const datesMap = {};

        let timetables = this.Timetables().filter((t: IAgendaTimetableViewModel) => {
            return t.IsValidOnPeriod(periodStart, periodEnd);
        });
        timetables = timetables.concat(
            this.TimetablesVariations().filter((t: IAgendaTimetableViewModel) => {
                return t.IsValidOnPeriod(periodStart, periodEnd);
            })
        );

        timetables.forEach((t: IAgendaTimetableViewModel) => {
            const startDate = moment(t.StartDate());
            if (!datesMap[startDate.toDate().toString()]) {
                dates.push(moment(t.StartDate()));
                datesMap[startDate.toDate().toString()] = startDate;
            }
            const endDate = !t.EndDate()
                ? moment("2100-01-01T00:00:00").add("days", 1)
                : moment(t.EndDate()).add("days", 1);
            if (!datesMap[endDate.toDate().toString()]) {
                dates.push(endDate);
                datesMap[endDate.toDate().toString()] = endDate;
            }
        });
        dates.sort((a: Moment, b: Moment) => {
            return a.diff(b, "seconds");
        });

        const intervals = [];
        for (let i = 0; i < dates.length - 1; i++) {
            intervals.push({ start: moment(dates[i]), end: moment(dates[i + 1]).add("days", -1) });
        }
        intervals.push({ start: dates[dates.length - 1], end: null });

        return intervals;
    }

    protected CreateViewModelForTimetable(timeTable: IAgendaTimetable): IAgendaTimetableViewModel {
        return new AgendaTimetableViewModel(this.serviceLocator, timeTable, this);
    }

    private CreateDetailsViewModels(details: IAgendasDetails) {
        details.Timetables.sort((a: IAgendaTimetable, b: IAgendaTimetable) => {
            return moment(a.StartDate) < moment(b.StartDate) ? 1 : moment(a.StartDate) > moment(b.StartDate) ? -1 : 0;
        });
        details.TimetablesVariations.sort((a: IAgendaTimetable, b: IAgendaTimetable) => {
            return moment(a.StartDate) < moment(b.StartDate) ? 1 : moment(a.StartDate) > moment(b.StartDate) ? -1 : 0;
        });

        this.Timetables(
            details.Timetables.map((ts: IAgendaTimetable) => {
                return this.CreateViewModelForTimetable(ts);
            })
        );
        this.TimetablesVariations(
            details.TimetablesVariations.map((ts: IAgendaTimetable) => {
                return this.CreateViewModelForTimetable(ts);
            })
        );
        this.Resources(details.Resources);
    }

    private setCalendarOptions(calendar: IFullCalendar): void {
        const agendaHeader: IFullCalendarHeader = {
            left: "",
            center: "",
            right:
                ProlifeSdk.CalendarPrevButton +
                "," +
                ProlifeSdk.CalendarNextButton +
                "," +
                ProlifeSdk.CalendarTodayButton +
                "," +
                ProlifeSdk.CalendarMonthView +
                "," +
                ProlifeSdk.CalendarAgendaWeekView +
                "," +
                ProlifeSdk.CalendarAgendaDayView,
        };

        this.CalendarOptions = {
            header: agendaHeader,
            defaultView: ProlifeSdk.CalendarMonthView,
            slotDuration: this.getSlotDuration(),
            snapDuration: this.getSnapDuration(),
            dragRevertDuration: 0,
            editable: true,
            droppable: true,
            selectable: true,
            dropAccept: ".prolife-calendar-event",
            locale: "it",
            height: "parent",
            customButtons: undefined,
            buttonText: undefined,
            unselectAuto: false,
            allDaySlot: this.TimeslotsModeEnabled() ? false : true,
            eventOverlap: true,
            eventSources: this.GetEventsSources(),
            viewRender: calendar.OnViewRenderCallback.bind(calendar),
            eventRender: calendar.OnEventRenderCallback.bind(calendar),
            eventDrop: calendar.OnEventDropCallback.bind(calendar),
            eventResize: calendar.OnEventResizeCallback.bind(calendar),
            eventClick: calendar.OnEventClickCallback, //.bind(calendar)
            dayRender: calendar.OnDayRenderCallback.bind(calendar),
            drop: calendar.OnDropCallback.bind(calendar),
            eventReceive: calendar.OnEventReceiveCallback.bind(calendar),
            select: calendar.OnSelectCallback.bind(calendar),
            unselect: calendar.OnUnselectCallback.bind(calendar),
            eventDragStop: calendar.OnDragStopCallback.bind(calendar),
            eventDragStart: calendar.OnDragStartCallback.bind(calendar),
        };
    }

    private getSlotDuration(): string {
        return "00:" + this.CalendarSlotDuration() + ":00";
    }

    private getSnapDuration(): string {
        return "00:" + this.CalendarSlotDuration() + ":00";
    }

    private SetupCalendar(): void {
        if (this.calendarUpdating) return;

        this.calendarUpdating = true;

        const calendar = new Calendar(this.serviceLocator, this);
        calendar.RegisterEventsDragObserver(this);
        this.setCalendarOptions(calendar);
        this.Calendar(calendar);

        setTimeout(() => {
            this.calendarUpdating = false;
        }, 200);
    }

    private SetCanChangeTimeslotMode(): void {
        if (this.TimeslotsModeEnabled()) {
            this.CanChangeTimeslotMode = true;
            return;
        }

        this.agendaService
            .HasPlannedEvents(this.AgendaId())
            .then((result: boolean) => {
                this.CanChangeTimeslotMode = !result;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CheckExistingEventsError);
                this.CanChangeTimeslotMode = false;
            });
    }
}

export class AgendaEditingViewModel extends AgendaViewModel implements IAgendaEditingViewModel {
    public ShowOldTimetables: ko.Observable<boolean> = ko.observable();
    public ShowOldTimetablesVariations: ko.Observable<boolean> = ko.observable();
    public Recovery: ko.Observable<boolean> = ko.observable();

    public IsNew: ko.Computed<boolean>;
    public VisibleTimetables: ko.Computed<IAgendaTimetableViewModel[]>;
    public VisibleTimetablesVariations: ko.Computed<IAgendaTimetableViewModel[]>;
    public CanAddVariations: ko.Computed<boolean>;
    public HasChanges: ko.Computed<boolean>;
    public HasTimetablesInEditing: ko.Computed<boolean>;
    public HasEvents: ko.Observable<boolean> = ko.observable();
    public HasFutureEvents: ko.Observable<boolean> = ko.observable();
    public HasEventsOnWaitingList: ko.Observable<boolean> = ko.observable();

    public ResourcesNames: ko.ObservableArray<string> = ko.observableArray([]);

    public FoldersProvider: FoldersProvider;
    public ResourcesAndGroupsManager: ResourcesAndGroupsManager;

    private IsChanged: ko.Computed<boolean>;
    private TimetablesAreChanged: ko.Computed<boolean>;

    private agendasDetails: IAgendasDetails;

    private categoriesManager: ICategoriesSettingsManager;

    constructor(serviceLocator: IServiceLocator, agenda: IAgenda) {
        super(serviceLocator, agenda);

        const settingsService: ISettingsService = <ISettingsService>(
            this.serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        this.categoriesManager = <ICategoriesSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager)
        );
        this.setUpFolderName(this.agenda.FolderId);
        this.ShowOldTimetables(false);
        this.ShowOldTimetablesVariations(false);

        this.FoldersProvider = new FoldersProvider(this.serviceLocator);
        this.ResourcesAndGroupsManager = new ResourcesAndGroupsManager();

        this.IsNew = ko.computed(() => {
            return this.AgendaId() == -1;
        });

        this.VisibleTimetables = ko.computed(() => {
            if (!this.Timetables()) return [];
            return this.Timetables().filter((tt: IAgendaTimetableViewModel) => {
                return !tt.Deleted() && (this.ShowOldTimetables() || tt.Visible());
            });
        });

        this.VisibleTimetablesVariations = ko.computed(() => {
            if (!this.TimetablesVariations()) return [];
            return this.TimetablesVariations().filter((tt: IAgendaTimetableViewModel) => {
                return !tt.Deleted() && (this.ShowOldTimetablesVariations() || tt.Visible());
            });
        });

        this.NoTimetablesConfigured = ko.computed(() => {
            return this.VisibleTimetables().length == 0;
        });

        this.NoTimetablesVariationsConfigured = ko.computed(() => {
            return this.VisibleTimetablesVariations().length == 0;
        });

        this.HasTimetablesInEditing = ko.computed(() => {
            let timetables: IAgendaTimetableViewModel[] = this.Timetables();
            for (var i = 0; i < timetables.length; i++) {
                if (timetables[i].InEditing()) return true;
            }

            timetables = this.TimetablesVariations();
            for (var i = 0; i < timetables.length; i++) {
                if (timetables[i].InEditing()) return true;
            }

            return false;
        });

        this.IsChanged = ko.computed(() => {
            return (
                this.FolderId() != this.agenda.FolderId ||
                this.Title() != this.agenda.Name ||
                this.Icon() != this.agenda.Icon ||
                this.Background() != this.agenda.Background ||
                this.Foreground() != this.agenda.Foreground ||
                this.TimeslotsModeEnabled() != this.agenda.TimeslotsModeEnabled ||
                this.Deleted() != this.agenda.Deleted ||
                this.FestivitiesAgendaId() != this.agenda.FestivitiesAgendaId
            );
        });

        this.TimetablesAreChanged = ko.computed(() => {
            this.VisibleTimetables();
            this.VisibleTimetablesVariations();

            let hasModifiedTimetables = false;
            let timetables: IAgendaTimetableViewModel[] = this.Timetables();
            for (var i = 0; i < timetables.length; i++) {
                if (timetables[i].HasChanges() && !timetables[i].Deleted()) {
                    hasModifiedTimetables = true;
                    break;
                }
            }

            if (!hasModifiedTimetables) {
                timetables = this.TimetablesVariations();
                for (var i = 0; i < timetables.length; i++) {
                    if (timetables[i].HasChanges() && !timetables[i].Deleted()) {
                        hasModifiedTimetables = true;
                        break;
                    }
                }
            }

            if (!this.agendasDetails) return false;

            return (
                this.Timetables().filter((tt: IAgendaTimetableViewModel) => {
                    return !tt.Deleted() && (this.ShowOldTimetables() || tt.Visible());
                }).length != this.VisibleTimetables().length ||
                this.TimetablesVariations().filter((tt: IAgendaTimetableViewModel) => {
                    return !tt.Deleted() && (this.ShowOldTimetablesVariations() || tt.Visible());
                }).length != this.VisibleTimetablesVariations().length ||
                hasModifiedTimetables
            );
        });

        this.HasChanges = ko.computed(() => {
            return this.IsChanged() || this.ResourcesAndGroupsManager.IsChanged() || this.TimetablesAreChanged();
        });

        this.CanAddVariations = ko.computed(() => {
            this.VisibleTimetables();
            return (
                this.VisibleTimetables().filter((tt: IAgendaTimetableViewModel) => {
                    return tt.InEditing();
                }).length == 0 && !this.TimetablesAreChanged()
            );
        });

        this.FolderId.subscribe((value: number) => {
            this.setUpFolderName(value);
        });

        this.LoadDetails().then((agendasDetails: IAgendasDetails) => {
            this.agendasDetails = agendasDetails;
            this.ResourcesAndGroupsManager.LoadResources(agendasDetails.Resources);
            this.LoadResourcesLabels();
        });

        this.GetEventsOverview()
            .then((eventsInfo: IEventsNumbersOverview) => {
                this.HasEvents(eventsInfo.EventsNumber > 0);
                this.HasFutureEvents(eventsInfo.FutureEventsNumber > 0);
                this.HasEventsOnWaitingList(eventsInfo.WaitingListEventsNumber > 0);
            })
            .catch(() => {
                this.HasEvents(true);
            });
    }

    private LoadResourcesLabels(): void {
        this.agendaService.GetResourcesLabels(this.agenda.Id).then((labels: string[]) => {
            this.ResourcesNames(labels);
        });
    }

    private setUpFolderName(folderId: number): void {
        if (!folderId) {
            this.FolderName("");
            return;
        }

        const matches: IFolder[] = this.categoriesManager.getFolders().filter((f: IFolder) => f.Id == folderId);
        this.FolderName(matches.length > 0 ? matches[0].Name : null);
    }

    AddNewTimetable(): void {
        const timeTable: IAgendaTimetableViewModel = this.CreateEmptyTimetableViewModel(false);
        timeTable.StartDate(this.GetStartDateForNewTimetable());
        timeTable.InEditing(true);
        this.Timetables.unshift(timeTable);
    }

    AddNewTimetableVariation(): void {
        const timeTables: IAgendaTimetableViewModel[] = this.GetTimetablesForNewVariation();
        if (timeTables.length == 0) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.CannotAddTimetableVariationMessage,
                ProlifeSdk.TextResources.Agenda.CannotAddTimetableVariationLabel,
                () => {}
            );
            return;
        }

        if (timeTables.length == 1) {
            const newTimetableVariation = timeTables[0].GetCopy(true);
            newTimetableVariation.Id(this.agendaService.GenerateTemporaryId());
            newTimetableVariation.InEditing(true);

            this.TimetablesVariations.unshift(newTimetableVariation);
            return;
        }

        const selectionDiaog = new SelectTimetableForVariationDialog(this.serviceLocator, timeTables);
        this.dialogsService
            .ShowModal<IAgendaTimetableViewModel>(selectionDiaog)
            .then((selectedTimetable: IAgendaTimetableViewModel) => {
                if (!selectedTimetable) return;

                const newTimetableVariation = selectedTimetable.GetCopy(true);
                newTimetableVariation.Id(-1);
                newTimetableVariation.InEditing(true);

                this.TimetablesVariations.unshift(newTimetableVariation);
            });
    }

    GetCopy(): IAgendaViewModel {
        const copy: IAgendaViewModel = new AgendaViewModel(this.serviceLocator, this.GetData());
        copy.Timetables(
            this.Timetables().map((tt: IAgendaTimetableViewModel) => {
                return tt.GetCopy(false);
            })
        );
        copy.TimetablesVariations(
            this.TimetablesVariations().map((tt: IAgendaTimetableViewModel) => {
                return tt.GetCopy(true);
            })
        );
        copy.Resources(
            this.Resources().map((r: IAgendaResource) => {
                return r;
            })
        );
        return copy;
    }

    GetData(): IAgenda {
        const agenda: IAgenda = {
            Id: this.AgendaId(),
            FolderId: this.FolderId(),
            Name: this.Title(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            CreatedBy: this.agenda.CreatedBy,
            CreationDate: this.agenda.CreationDate,
            ModifiedBy: this.agenda.ModifiedBy,
            ModifyDate: this.agenda.ModifyDate,
            TimeslotsModeEnabled: this.TimeslotsModeEnabled(),
            Deleted: this.Deleted(),
            FestivitiesAgendaId: this.FestivitiesAgendaId(),
            ShowDialogOnDropOnWaitingList: this.ShowDialogOnDropOnWaitingList(),
            DaysNumberForBadgeAlert: this.DaysNumberForBadgeAlert(),
            DaysNumberForPlannedEventsAlert: this.DaysNumberForPlannedEventsAlert(),
            CustomersSelectionOnEventsRequired: this.CustomersSelectionOnEventsRequired(),
            CalendarSlotDuration: this.CalendarSlotDuration(),
            HasSyncErrors: false,
        };

        return agenda;
    }

    GetDetailedData(): IDetailedAgenda {
        const agenda: IDetailedAgenda = {
            Id: this.AgendaId(),
            FolderId: this.FolderId(),
            Name: this.Title(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            CreatedBy: this.agenda.CreatedBy,
            CreationDate: this.agenda.CreationDate,
            ModifiedBy: this.agenda.ModifiedBy,
            ModifyDate: this.agenda.ModifyDate,
            TimeslotsModeEnabled: this.TimeslotsModeEnabled(),
            Timetables: this.Timetables()
                .filter((ts: IAgendaTimetableViewModel) => {
                    return !ts.Deleted();
                })
                .map((ts: IAgendaTimetableViewModel) => {
                    return ts.GetData();
                }),
            TimetablesVariations: this.TimetablesVariations()
                .filter((ts: IAgendaTimetableViewModel) => {
                    return !ts.Deleted();
                })
                .map((ts: IAgendaTimetableViewModel) => {
                    return ts.GetData();
                }),
            Resources: this.ResourcesAndGroupsManager.GetResources().map((r: IResourceForManager) => {
                return { AgendaId: this.AgendaId(), ElementType: r.ElementType, ElementId: r.ElementId };
            }),
            Deleted: this.Deleted(),
            FestivitiesAgendaId: this.FestivitiesAgendaId(),
            ShowDialogOnDropOnWaitingList: this.ShowDialogOnDropOnWaitingList(),
            DaysNumberForBadgeAlert: this.DaysNumberForBadgeAlert(),
            DaysNumberForPlannedEventsAlert: this.DaysNumberForPlannedEventsAlert(),
            CustomersSelectionOnEventsRequired: this.CustomersSelectionOnEventsRequired(),
            CalendarSlotDuration: this.CalendarSlotDuration(),
            Recovery: this.Recovery(),
            HasSyncErrors: false,
        };

        return agenda;
    }

    Delete(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.ConfirmDelete()
            .then((result: boolean) => {
                if (!result) {
                    def.resolve(result);
                    return;
                }

                this.agendaService
                    .GetAgendaFutureEvents(this.AgendaId())
                    .then((events: IEventOnDatesIntervalDetails[]) => {
                        if (events.length == 0) {
                            this.InternalDelete(def);
                            return;
                        }
                        const message = ProlifeSdk.TextResources.Agenda.AgendaFuturePlannedEventsText;
                        const plannedEventsDialog = new PlannedEventsAlertDialog(
                            this.serviceLocator,
                            events,
                            message,
                            true
                        );
                        plannedEventsDialog.Show().then((confirm: boolean) => {
                            if (!confirm) {
                                def.resolve(false);
                                return;
                            }
                            this.InternalDelete(def);
                        });
                    })
                    .catch(() => {
                        def.reject();
                    });
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    public SwitchTimetablesVisibility(): void {
        this.ShowOldTimetables(!this.ShowOldTimetables());
    }

    public SwitchTimetablesVariationsVisibility(): void {
        this.ShowOldTimetablesVariations(!this.ShowOldTimetablesVariations());
    }

    public IsValid(): boolean {
        if (!this.Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.AgendaTitleRequired);
            return false;
        }

        if (!this.FolderId()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.AgendaFolderRequired);
            return false;
        }

        return true;
    }

    async HasValidateTimetables(): Promise<boolean> {
        if (this.HasTimetablesInEditing()) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.TimetablesInEditingAlert,
                ProlifeSdk.TextResources.Agenda.TimetablesInEditingAlertLabel,
                () => {}
            );

            return false;
        }

        const actualTimeTable: IAgendaTimetableViewModel = this.GetActualTimeTable();

        if (!actualTimeTable) {
            const confirm = await this.dialogsService.ConfirmAsync(
                ProlifeSdk.TextResources.Agenda.InsertAgendaWithoutTimetableMessage,
                ProlifeSdk.TextResources.Agenda.CancelButton,
                ProlifeSdk.TextResources.Agenda.ConfirmButton
            );

            if (!confirm) return false;
        } else {
            if (actualTimeTable.NoTimeSlotsConfigured()) {
                const confirm = await this.dialogsService.ConfirmAsync(
                    ProlifeSdk.TextResources.Agenda.InsertTimetableWithoutTimeSlotsMessage,
                    ProlifeSdk.TextResources.Agenda.CancelButton,
                    ProlifeSdk.TextResources.Agenda.ConfirmButton
                );

                if (!confirm) return false;
            }

            if (!actualTimeTable.IsValid()) {
                await this.dialogsService.AlertAsync(
                    ProlifeSdk.TextResources.Agenda.InvalidTimetable,
                    ProlifeSdk.TextResources.Agenda.InvalidTimetableAlertLabel
                );

                return false;
            }
        }

        const timetables = this.Timetables();
        for (const timetable of timetables) {
            if (timetable === actualTimeTable) continue;

            if (!timetable.IsValid()) {
                const startDate = timetable.StartDate();
                const endDate = timetable.EndDate();
                const message = String.format(
                    TextResources.Agenda.TimetablesValidationError,
                    !startDate ? TextResources.ProlifeSdk.NotAvailable : moment(startDate).format("L"),
                    !endDate ? TextResources.ProlifeSdk.NotAvailable : moment(endDate).format("L")
                );

                await this.dialogsService.AlertAsync(message, TextResources.Agenda.TimetablesValidationErrorTitle);
                return false;
            }
        }

        const timetablesVariations = this.TimetablesVariations();
        for (const timetable of timetablesVariations) {
            if (timetable === actualTimeTable) continue;

            if (!timetable.IsValid()) {
                const startDate = timetable.StartDate();
                const endDate = timetable.EndDate();
                const message = String.format(
                    TextResources.Agenda.TimetablesVariationValidationError,
                    !startDate ? TextResources.ProlifeSdk.NotAvailable : moment(startDate).format("L"),
                    !endDate ? TextResources.ProlifeSdk.NotAvailable : moment(endDate).format("L")
                );

                await this.dialogsService.AlertAsync(message, TextResources.Agenda.TimetablesValidationErrorTitle);
                return false;
            }
        }

        return true;
    }

    private InternalDelete(deferred: Deferred<boolean>): void {
        this.agendaService
            .Delete(this.AgendaId())
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.DeleteAgendaSuccess);
                deferred.resolve(true);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.DeleteFailed);
                deferred.reject();
            });
    }

    private GetStartDateForNewTimetable(): Date {
        if (this.VisibleTimetables().length == 0) return moment().startOf("day").toDate();

        const timetable: IAgendaTimetableViewModel = this.VisibleTimetables()[0];
        return !timetable.EndDate()
            ? moment().startOf("day").toDate()
            : moment(timetable.EndDate()).startOf("day").add("days", 1).toDate();
    }

    private GetTimetablesForNewVariation(): IAgendaTimetableViewModel[] {
        return this.Timetables().filter((t: IAgendaTimetableViewModel) => {
            return !t.EndDate() || moment(t.EndDate()) > moment().startOf("day");
        });
    }

    protected CreateViewModelForTimetable(timeTable: IAgendaTimetable): IAgendaTimetableViewModel {
        return new AgendaTimetableViewModel(this.serviceLocator, timeTable, this);
    }

    private CreateEmptyTimetableViewModel(isVariation: boolean): IAgendaTimetableViewModel {
        const timeTable: IAgendaTimetable = {
            Id: this.agendaService.GenerateTemporaryId(),
            AgendaId: this.AgendaId(),
            StartDate: null,
            EndDate: null,
            Description: "",
            Timeslots: [],
            IsVariation: isVariation,
            ObserveFestivities: false,
            HasChanges: false,
        };
        return this.CreateViewModelForTimetable(timeTable);
    }

    private ConfirmDelete(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.DeleteAgendaConfirmMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}

class EventCanceledMotivationViewModel implements IEventCanceledMotivationViewModel, IFullCalendarEventsReceiver {
    public Id: number;
    public Motivation: string;

    public IsEventOverMe: (x: number, y: number) => boolean;

    private agendaService: IAgendaService;
    private eventsService: IEventsService;

    constructor(
        private serviceLocator: IServiceLocator,
        private motivation: IEventCanceledMotivation,
        private parent: IFullCalendarEventsSource
    ) {
        this.agendaService = <IAgendaService>this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.eventsService = <IEventsService>this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);

        this.Id = this.motivation.Id;
        this.Motivation = this.motivation.Label;
    }

    public OnElementDropped(event, ui): void {
        const eventDescriptor: IAgendaEventDescriptor = ui.draggable.data("event");
        if (eventDescriptor) this.parent.OnEventDroppedOnCanceledMotivation(eventDescriptor, this.Id);
    }
}
