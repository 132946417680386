import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 12/05/2017
 * Time: 10:22
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { AllocationsGantt } from "./AllocationsGantt";
import { CartsList } from "./CartsList";
import { TeamManagerDialog } from "./TeamManagerDialog";
import { CartForList } from "./CartForList";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { AllocationsMenuDataSource, IJobOrderForAllocationsDataSourceModel, IWorkflowForAllocationsDataSourceModel } from "../../../DataSources/AllocationsMenuDataSource";
import { AllocationMode } from "../enums/AllocationMode";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { INavigationMenuComponent, INavigationMenuComponentActionsGroup, INavigationMenuComponentAction, INavigationMenuComponentActionSeparator, INavigationMenuComponentModel } from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IAllocationsService } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ICartsListObserver } from "../../../ProlifeSdk/interfaces/allocations/ICartsListObserver";
import { ICartsManager } from "../../interfaces/ICartsManager";
import { ICartsReallocationObserver, IChangesNotificationsService } from "../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { ICartWithContent } from "../../../ProlifeSdk/interfaces/allocations/ICart";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { WorkflowsMenuSearchModes } from "../../../Todolist/Todolist/enums/WorkflowsMenuSearchModes";
import { AlertsLegendUI } from "../../../Components/AlertsLegend";

export class Allocations implements ICartsListObserver, ICartsManager, ICartsReallocationObserver, IDataSourceListener {
    public AllocationMode: ko.Observable<number> = ko.observable();
    public resourcesFilter : ko.Observable<string> = ko.observable().extend({ rateLimit: 500 });
    
    public ManualAllocationEnabled: ko.Computed<boolean>;

    public AllocationsGantt : AllocationsGantt;
    public CartsList : CartsList;
    public AllocationsMenuDataSource: AllocationsMenuDataSource;
    public JobOrdersMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    public ShowCartEditingFormOnActivityAllocation: ko.Computed<boolean>;

    private ShowingWorkflows: ko.Observable<boolean> = ko.observable(false);
    private ShowingTasks: ko.Observable<boolean> = ko.observable(false);
    private selectedJobOrder: number = null;
    private selectedWorkflow: number = null;
    
    @LazyImport(nameof<ICartsService>())
    private cartsService: ICartsService;

    @LazyImport(nameof<IInfoToastService>())
    public infoToastService: IInfoToastService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationService: IChangesNotificationsService;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator) {
        this.AllocationsMenuDataSource = new AllocationsMenuDataSource();
        
        this.JobOrdersMenu.subscribe((navigationMenu) => {
            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            }

            navigationMenu.registerAction(showLegendAction);
            
            const action: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-filter",
                text: "",
                activeClass: "red",
                defaultClass: "btn-transparent",
                active: () => this.AllocationsMenuDataSource.AdvancedFilterIsActive(),
                canExecute: () => true,
                action: () => {
                    this.AllocationsMenuDataSource.editAdvancedFilters();
                },
            }
            
            navigationMenu.registerAction(action);

            const workflowActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingWorkflows(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow")) {
                const insertWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflow();
                    }
                }

                workflowActions.actions.push(insertWorkflowAction);
            }
            
            if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow")) {
                const cloneWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromWorkflow();
                    }
                }

                workflowActions.actions.push(cloneWorkflowAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")) {
                const copyWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromTemplate();
                    }
                }

                workflowActions.actions.push(copyWorkflowAction);
            }

            const showingSecondaryActions = ko.observable(false);

            if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: ProlifeSdk.TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.JobOrdersMenu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: ProlifeSdk.TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.JobOrdersMenu().showSecondaryAction(false)
                    }
                };

                if (workflowActions.actions.length > 0) {
                    workflowActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                workflowActions.actions.push(editingAction);
                workflowActions.actions.push(endEditingAction);
            }

            if (workflowActions.actions.length > 0)
                navigationMenu.registerAction(workflowActions);

            const tasksActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingTasks(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertTask")) {
                const insertTaskAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewTask,
                    visible: () => this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        this.CreateTask();
                    }
                };

                tasksActions.actions.push(insertTaskAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: ProlifeSdk.TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.JobOrdersMenu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: ProlifeSdk.TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.JobOrdersMenu().showSecondaryAction(false)
                    }
                };

                if (tasksActions.actions.length > 0) {
                    tasksActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                tasksActions.actions.push(editingAction);
                tasksActions.actions.push(endEditingAction);
            }

            if (tasksActions.actions.length > 0)
                navigationMenu.registerAction(tasksActions);

            navigationMenu.registerSearchAction({
                text: "Cerca piano",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchWorkflows);
                    if (this.JobOrdersMenu().getTextFilter())
                        this.JobOrdersMenu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                isDefault: true,
                visible: () => this.ShowingWorkflows()
            });

            navigationMenu.registerSearchAction({
                text: "Cerca attività",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchActivitiesInWorkflows);
                    if (this.JobOrdersMenu().getTextFilter())
                        this.JobOrdersMenu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                visible: () => this.ShowingWorkflows()
            })
        });

        this.AllocationsGantt = new AllocationsGantt(this.serviceLocator, this);
        this.CartsList = new CartsList(true);
        this.CartsList.RegisterObserver(this);
        this.ShowCartEditingFormOnActivityAllocation = ko.computed(() => {
            return this.CartsList.ShowCartEditingFormOnActivityAllocation();
        });

        this.changesNotificationService.RegisterCartsReallocationObserver(this);
        this.SetAllocationMode(AllocationMode.AutomaticAllocation);

        this.ManualAllocationEnabled = ko.computed(() => {
            return this.AllocationMode() === AllocationMode.ManualAllocation;
        });
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    public onNavigate(sender: IDataSource, ...history: INavigationMenuComponentModel[]): void {
        if (history.length === 0) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(false);

            this.selectedJobOrder = null;
            this.selectedWorkflow = null;

            return;
        }

        const model = history[history.length - 1];

        const jobOrderModel = model as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel && jobOrderModel.isJobOrder) {
            this.ShowingWorkflows(true);
            this.ShowingTasks(false);

            this.selectedJobOrder = jobOrderModel.id;

            return;
        }

        const workflowModel = model as IWorkflowForAllocationsDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(true);

            this.selectedWorkflow = workflowModel.id;
        }
    }

    public SwitchAllocationMode(): void {
        this.SetAllocationMode(this.AllocationMode() === AllocationMode.AutomaticAllocation ? AllocationMode.ManualAllocation : AllocationMode.AutomaticAllocation);

        if (this.ManualAllocationEnabled())
            this.allocationsService.triggerNextTutorialStep("manual-allocation-enabled");
    }

    public SetAllocationMode(allocationMode: number): void {
        this.AllocationMode(allocationMode);
        this.CartsList.AllocationMode(allocationMode);
        this.AllocationsGantt.AllocationMode(allocationMode);
        this.RefreshMenu();
    }

    public SelectResourceGroup() {
        const dialog = new TeamManagerDialog(this.serviceLocator, this);
        dialog.ShowDialog().then(() => {
            this.RefreshGantt();
            this.UpdateCartsList();
            this.RefreshMenu();
        });
    }

    public OnListChanges(): void {
        this.RefreshMenu();
    }

    public dispose() {
        this.changesNotificationService.UnregisterCartsReallocationObserver(this);
        this.CartsList.Dispose();
    }

    public AllocateCartOnTeam(cartId: number, teamId: number) {
        let cart: CartForList = this.CartsList.GetCart(cartId);
        if (cart) {
            this.AllocationsGantt.AllocateCart(cart, teamId);
            return;
        }
        this.cartsService.getCartWithContent(cartId)
            .then((cartWithContent: ICartWithContent) => {
                cart = new CartForList(cartWithContent, this.CartsList)
                this.AllocationsGantt.AllocateCart(cart, teamId);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
            });

    }

    public UpdateTeam(teamId : number) {
        this.AllocationsGantt.UpdateTeam(teamId);
    }

    public UpdateCartsList() {
        this.CartsList.RefreshCarts();
    }

    public RefreshGantt() {
        this.AllocationsGantt.RefreshGantt();
    }

    public RefreshMenu(): void {
        this.AllocationsMenuDataSource.refresh();
    }

    public OnCartsReallocation(teamId : number) {
        this.AllocationsGantt.UpdateTeam(teamId);
        this.CartsList.RefreshCarts();
        this.RefreshMenu();
    }

    public OnReallocation(): void {
        this.AllocationsGantt.RefreshGantt();
        this.CartsList.RefreshCarts();
        this.RefreshMenu();
    }

    public OnReallocationError(errorMessage: string) {
        this.infoToastService.Error(errorMessage);
    }

    private async CreateNewWorkflow(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowDialog(this.selectedJobOrder);
        this.RefreshMenu();
    }

    private async CreateNewWorkflowFromWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder);
        this.RefreshMenu();
    }

    private async CreateNewWorkflowFromTemplate(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder);
        this.RefreshMenu();
    }

    private async CreateTask(): Promise<void> {
        await this.todoListService.ShowCreateNewTaskDialog(this.selectedJobOrder, this.selectedWorkflow, { initialViewAll: true });
        this.RefreshMenu();
    }
}