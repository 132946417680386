import { IService } from "../Core/interfaces/IService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { MetadataSettingsManager as MetadataSettingsManager } from "./invoices/settings/MetadataSettingsManager";

export interface IFullMetadata extends IMetadataMetadata {
    ListValues: IMetadataMetadataListValues[];
}

export interface IPlainMetadata {
    Id: number;
    Label: string;
    ValueType: string;
    Deleted: boolean;
    ShowMarkerOnAllocationsGantt: boolean;
}

export interface IProtocolDefaultMetadata {
    Id: number;
    FKProtocol: number;
    FKMetadata: number;
    ShowMarkerOnAllocationsGantt: boolean;
    MetadataValueType: string;
    PreloadOnDocument: boolean;
    Order: number;
}

export interface IGetMetadatasRequest {
    textFilter?: string;
    getDeleted?: boolean;
    protocolId?: number;
    includeDeletedListValues?: boolean;
    skip?: number;
    count?: number;
}

export interface IMetadata_Type {
    Id?: number;
    Label?: string;
    ValueType?: string;
    Deleted: boolean;
    ShowMarkerOnAllocationsGantt: boolean;
}

export interface IMetadataListValues_Type {
    Id: number;
    Label?: string;
    FKMetadata: number;
    Order: number;
    Deleted: boolean;
}

export interface IMetadataMetadata {
    Id: number;
    Label: string;
    ValueType: string;
    Deleted: boolean;
    ShowMarkerOnAllocationsGantt: boolean;
}

export interface IMetadataMetadataListValues {
    Id: number;
    Label: string;
    FKMetadata: number;
    Order: number;
    Deleted: boolean;
}

export interface IMetadata {
    Metadatas: IMetadataMetadata[];
    MetadatasListValues: IMetadataMetadataListValues[];
}

export interface IGetDocumentsWithMetadataReportRequest {
    startDateFilter?: Date;
    endDateFilter?: Date;
    vatRegisters?: number[];
    customers?: number[];
    jobOrders?: number[];
    jobOrderTypes?: number[];
    jobOrderStates?: number[];
    documentClosingStatus?: number[];
    documentSchedulesStatus?: number[];
    documentTypes?: string[];
    skip?: number;
    count?: number;
}

export interface IDocumentsWithMetadataReportDocuments {
    Id: number;
    EntityType: string;
    DownPayment: boolean;
    Number?: string;
    Date: Date;
    DocumentClosingState: number;
    CustomerId?: number;
    CustomerName?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    AdministrativeResponsibleName?: string;
    CommercialResponsibleName?: string;
    ExternalReference?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    FullVersionRevisionNumber?: string;
    RegisterId: number;
    RegisterName?: string;
    RequireVersionRevision: boolean;
    ForPartialInvoicesRegister: boolean;
    PaymentType?: string;
    ExpiryType?: string;
    FKOutcome?: number;
    Outcome?: string;
    DocumentStateId?: number;
    DocumentState?: string;
    DocumentLogicalState?: number;
    SourceWarehouseId?: number;
    SourceWarehouse?: string;
    DestinationWarehouseId?: number;
    DestinationWarehouse?: string;
    CauseId?: number;
    Cause?: string;
    CauseLogicType?: number;
    TaxableTotal: number;
    VAT: number;
    NotTaxableTotal: number;
    Total: number;
    JobOrderMetadataId?: number;
    JobOrderMetadataName?: string;
    JobOrderMetadataCode?: string;
    AssignedToWorkflowsTotal: number;
    AssignmentToWorkflowsState: number;
    WaitingToStartTotal: number;
    InProgressTotal: number;
    InProgressTotalCompleted: number;
    CompletedTotal: number;
    AssignedResources: string;
    LastMailSentDate: Date | null;
}

export interface IDocumentsWithMetadataReportDocumentsMetadata {
    FKDocument: number;
    FKMetadata: number;
    IntValue?: number;
    DecimalValue?: number;
    StringValue?: string;
    DateValue?: Date;
    BoolValue?: boolean;
    ValueLabel?: string;
    ValueOrder?: number;
}

export interface IDocumentsWithMetadataReportMetadata {
    Id: number;
    Label?: string;
    Deleted: boolean;
    ValueType: string;
}

export interface IDocumentsWithMetadataReport {
    Documents: IDocumentsWithMetadataReportDocuments[];
    DocumentsMetadatas: IDocumentsWithMetadataReportDocumentsMetadata[];
    Metadatas: IDocumentsWithMetadataReportMetadata[];
    AffectedRows?: number;
}

export interface IMetadataService extends IService {
    GetPlainMetadatas(getDeleted: boolean | null): Promise<IPlainMetadata[]>;
    GetProtocolDefaultMetadata(protocolId: number | null): Promise<IProtocolDefaultMetadata[]>;
    GetMetadatasByIds(ids: number[] | null, includeDeletedListValues: boolean | null): Promise<IMetadata>;
    GetMetadatas(request: IGetMetadatasRequest): Promise<IMetadata>;
    CreateOrUpdateMetadata(
        metadatas: IMetadata_Type[] | null,
        metadatasListValues: IMetadataListValues_Type[] | null
    ): Promise<IMetadata>;
    GetDocumentsWithMetadataReport(
        request: IGetDocumentsWithMetadataReportRequest
    ): Promise<IDocumentsWithMetadataReport>;
}

@Service(nameof<IMetadataService>())
class MetadataService implements IMetadataService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    InitializeService() {
        new MetadataSettingsManager();
    }

    GetPlainMetadatas(getDeleted: boolean | null): Promise<IPlainMetadata[]> {
        const result = this.ajaxService.Post<IPlainMetadata[]>("Documents-api/Metadatas", "GetPlainMetadatas", {
            background: true,
            methodData: {
                getDeleted: getDeleted,
            },
        });

        return result;
    }

    GetProtocolDefaultMetadata(protocolId: number | null): Promise<IProtocolDefaultMetadata[]> {
        const result = this.ajaxService.Post<IProtocolDefaultMetadata[]>(
            "Documents-api/Metadatas",
            "GetProtocolDefaultMetadatas",
            {
                background: true,
                methodData: {
                    protocolId: protocolId,
                },
            }
        );

        return result;
    }

    GetMetadatasByIds(ids: number[] | null, includeDeletedListValues: boolean | null): Promise<IMetadata> {
        const result = this.ajaxService.Post<IMetadata>("Documents-api/Metadatas", "GetMetadatasByIds", {
            background: true,
            methodData: {
                ids: ids,
                includeDeletedListValues: includeDeletedListValues,
            },
        });

        return result;
    }

    GetMetadatas(request: IGetMetadatasRequest): Promise<IMetadata> {
        const result = this.ajaxService.Post<IMetadata>("Documents-api/Metadatas", "GetMetadatas", {
            background: true,
            methodData: request,
        });

        return result;
    }

    CreateOrUpdateMetadata(
        metadatas: IMetadata_Type[] | null,
        metadatasListValues: IMetadataListValues_Type[] | null
    ): Promise<IMetadata> {
        const result = this.ajaxService.Post<IMetadata>("Documents-api/Metadatas", "CreateOrUpdateMetadatas", {
            background: true,
            methodData: {
                metadatas: metadatas,
                metadatasListValues: metadatasListValues,
            },
        });

        return result;
    }

    GetDocumentsWithMetadataReport(
        request: IGetDocumentsWithMetadataReportRequest
    ): Promise<IDocumentsWithMetadataReport> {
        const result = this.ajaxService.Post<IDocumentsWithMetadataReport>(
            "Documents-api/DocumentsWithMetadatasReport",
            "GetDocumentsWithMetadatasReport",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return serviceLocator.findService(nameof<IMetadataService>());
}
