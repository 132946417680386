import * as ko from "knockout";
//import * as Highcharts from "highcharts";

/**
 * Created with JetBrains WebStorm.
 * User: n.angeli
 * Date: 20/02/13
 * Time: 14.28
 * To change this template use File | Settings | File Templates.
 */

export class HighChart {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options : any = valueAccessor();
		var data = ko.utils.unwrapObservable(options.data);
        var override = ko.utils.unwrapObservable(options.override) || {};

        Highcharts.setOptions({
            lang: {
                loading: 'Sto caricando...',
                months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
                weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
                shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                //exportButtonTitle: "Esporta",
                //printButtonTitle: "Importa",
                //rangeSelectorFrom: "Da",
                //rangeSelectorTo: "A",
                //rangeSelectorZoom: "Periodo",
                downloadPNG: 'Download immagine PNG',
                downloadJPEG: 'Download immagine JPEG',
                downloadPDF: 'Download documento PDF',
                downloadSVG: 'Download immagine SVG',
                printChart: 'Stampa grafico',
                thousandsSep: ".",
                decimalPoint: ','
            }
        });

		var containerId = $(element).attr("id");
		data.chart.renderTo = containerId;
        data.chart.backgroundColor = 'transparent';
        data.chart.type = override.type || data.chart.type;

        var currentChart : Highcharts.ChartObject = <Highcharts.ChartObject>$(element).data("__highChart__");

        var interval = $(element).data("__highChart__refreshInterval");
        if(interval)
            clearInterval(interval);

        if(currentChart) {
            currentChart.destroy();
        }

        interval = setInterval(function() {
            (<any>currentChart).reflow();
        }, 1000);
        $(element).data("__highChart__refreshInterval", interval);

        currentChart = new Highcharts.Chart(data);
        $(element).data("__highChart__", currentChart);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            try {
                currentChart.destroy();
            }
            catch(a) {
                console.log("HighChart was already destroyed")
            }
            clearInterval(interval);
        });
	}
}

export class HighStock {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options : any = valueAccessor();
        var data = ko.utils.unwrapObservable(options.data);
        var override = ko.utils.unwrapObservable(options.override) || {};

        Highcharts.setOptions({
            global: {
                useUTC: false
            },
            lang: {
                loading: 'Sto caricando...',
                months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
                weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
                shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                //exportButtonTitle: "Esporta",
                //printButtonTitle: "Importa",
                //rangeSelectorFrom: "Da",
                //rangeSelectorTo: "A",
                //rangeSelectorZoom: "Periodo",
                downloadPNG: 'Download immagine PNG',
                downloadJPEG: 'Download immagine JPEG',
                downloadPDF: 'Download documento PDF',
                downloadSVG: 'Download immagine SVG',
                printChart: 'Stampa grafico',
                thousandsSep: ".",
                decimalPoint: ','
            }
        });

        if(!data.rangeSelector)
            data.rangeSelector = {};
        data.rangeSelector.inputEditDateFormat = '%d/%m/%Y';
        data.rangeSelector.inputDateFormat = '%d/%m/%Y';
        data.rangeSelector.inputEnabled = false;
        data.rangeSelector.buttons = [{
            type: 'month',
            count: 1,
            text: '1m'
        }, {
            type: 'month',
            count: 3,
            text: '3m'
        }, {
            type: 'month',
            count: 6,
            text: '6m'
        }, {
            type: 'ytd',
            text: "IdA"
        }, {
            type: 'year',
            count: 1,
            text: '1a'
        }, {
            type: 'all',
            text: 'Tutto'
        }];

        /*var containerId = $(element).attr("id");
        data.chart.renderTo = containerId;
        data.chart.backgroundColor = 'transparent';
        data.chart.type = override.type || data.chart.type;*/

        var currentChart : Highcharts.ChartObject = <Highcharts.ChartObject>$(element).data("__highChart__");

        var interval = $(element).data("__highChart__refreshInterval");
        if(interval)
            clearInterval(interval);

        if(currentChart) {
            currentChart.destroy();
        }

        interval = setInterval(function() {
            (<any>currentChart).reflow();
        }, 1000);
        $(element).data("__highChart__refreshInterval", interval);

        //currentChart = new Highcharts.StockChart(data);
        (<any>$(element)).highcharts("StockChart", data);
        currentChart = (<any>$(element)).highcharts();

        $(element).data("__highChart__", currentChart);

        if(options.init) {
            options.init(element, currentChart);
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            try {
                currentChart.destroy();
            }
            catch(a) {
                console.log("HighChart was already destroyed")
            }
            clearInterval(interval);
        });
    }
}

ko.bindingHandlers["highChart"] = new HighChart();
ko.bindingHandlers["highStock"] = new HighStock();
ko.virtualElements.allowedBindings["highChart"] = true;
ko.virtualElements.allowedBindings["highStock"] = true;