import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SpeedFilter } from "./SpeedFilter";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogEventType, ILogEventGroup, ILogEventTypesInGroups, ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";

interface BlogCategoryFilters{
    [type : string] : boolean;
}

export class EventType
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public type : ILogEventType, selected : boolean)
    {
        this.IsSelected(selected);
    }

    public ChangeSelection()
    {
        this.IsSelected(!this.IsSelected());
    }
}

export class EventGroup
{
    public IsSelected : ko.Computed<boolean>;
    public PartiallySelected : ko.Computed<boolean>;

    public EventTypes : ko.ObservableArray<EventType> = ko.observableArray([]);

    constructor(public group : ILogEventGroup)
    {
        this.EventTypes(group.EventProvidersInGroups.map((t : ILogEventTypesInGroups) => { return new EventType(t.EventProvider, t.Active); }) || []);

        this.IsSelected = ko.computed({
            read: () => {
                return this.EventTypes().filter(t => t.IsSelected()).length == this.EventTypes().length;
            },
            write: (value : boolean) => {
                this.EventTypes().forEach(t => t.IsSelected(value));
            }
        });

        this.PartiallySelected = ko.computed(() => {
            var selectedCount = this.EventTypes().filter(t => t.IsSelected()).length;
            return selectedCount > 0 && selectedCount < this.EventTypes().length;
        });
    }

    public ChangeSelection()
    {
        this.IsSelected(!this.IsSelected());
    }
}

export class CategorySpeedFilter extends SpeedFilter
{
    private categoryFilters : string[];
    private logService : ILogService;

    public templateName : string = "events-types-filter";
    public templateUrl : string = "blog/templates/job-order-notes/filters";

    //public EventTypes : KnockoutObservableArray = ko.observableArray([]);
    public SelectedTypes : ko.Computed<EventType[]>;
    private applyDisabled : boolean = false;

    public EventGroups : ko.ObservableArray<EventGroup> = ko.observableArray([]);
    public filterActive: ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator, private onApply : () => void)
    {
		super();
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);

        this.logService.GetEventGroups()
            .then((groups : ILogEventGroup[]) => {
                this.EventGroups(groups.map(g => new EventGroup(g)));
            });

        /*this.logService.GetEventTypes().then((types : ILogEventType[]) => {
            this.EventTypes(types.filter((t : ILogEventType) => { return t.Searchable; }).map((t : ILogEventType) => { return new EventType(t); }) || []);
        });*/

        this.SelectedTypes = ko.computed(() => {
            var selection = [];

            this.EventGroups().forEach(g => selection = selection.concat(g.EventTypes().filter(t => t.IsSelected())));

            //var selection = this.EventTypes().filter((t : EventType) => { return t.IsSelected();});
            return selection;
        });

        this.SelectedTypes.subscribe(this.OnSelectionChanged.bind(this));

        this.applyFilter();
    }

    public getFilter() : string[]
    {
        return this.categoryFilters;
    }

    private OnSelectionChanged()
    {
        if(this.applyDisabled)
            return;

        this.applyFilter();

        if (this.onApply)
            this.onApply();
    }

    /*public InvertSelection(eventType : EventType)
    {
        this.applyDisabled = true;
        this.EventTypes().forEach((t : EventType) => {
            t.IsSelected(eventType.IsSelected());
        });
        eventType.ChangeSelection();
        this.applyDisabled = false;
        this.applyFilter();

        if (this.onApply)
            this.onApply();
    }*/

    private applyFilter(): void
    {
        this.categoryFilters = this.SelectedTypes().map((t : EventType) => { return t.type.EventType; });
    }
}