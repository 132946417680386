import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 05/06/13
 * Time: 10.45
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { PurchasesDataSource } from "./desktop/documents/data-sources/PurchasesDataSource";
import { PurchasesDataSourceForMonthlyInvoicing } from "./desktop/documents/data-sources/PurchasesDataSourceForMonthlyInvoicing";
import { WorkedHoursDataSource } from "./desktop/documents/data-sources/WorkedHoursDataSource";
import { WorkedHoursDataSourceForMonthlyInvoicingWizard } from "./desktop/documents/data-sources/WorkedHoursDataSourceForMonthlyInvoicingWizard";
import { IFinancialDataProvider } from "../ProlifeSdk/interfaces/financial-data/IFinancialDataProvider";
import { IFinancialDataService } from "../ProlifeSdk/interfaces/financial-data/IFinancialDataService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IService } from "../Core/interfaces/IService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IPurchaseForMonthlyInvoicing } from "../Blog/interfaces/IPurchasesService";
import { ISearchFilter } from "../ProlifeSdk/interfaces/ISearchFilter";

export class FinancialDataService implements IFinancialDataService, ISettingsServiceObserver {
    private providers: IFinancialDataProvider[] = [];
    private dataSourceLoaded: boolean = false;

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IFinancialDataService>(), this);
    }

    InitializeService() {
        const settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.addObserver(this);
    }

    onSettingsUpdated(updateType: string) {
        //Niente da fare
    }

    getServiceType(): string {
        return ProlifeSdk.FinancialDataServiceType;
    }

    isOfType(serviceType: string): boolean {
        return this.getServiceType() == serviceType;
    }

    onSettingsLoaded(): void {
        if (this.dataSourceLoaded) return;

        new PurchasesDataSource();
        new PurchasesDataSourceForMonthlyInvoicing();
        new WorkedHoursDataSource();
        new WorkedHoursDataSourceForMonthlyInvoicingWizard();

        this.dataSourceLoaded = true;
    }

    registerProvider(provider: IFinancialDataProvider): void {
        this.providers.push(provider);
    }

    private getProviderByType(type: string): IFinancialDataProvider {
        //ATTENZIONE: Per il momento viene supportato solo un provider!
        const filteredProviders = this.providers.filter((p: IFinancialDataProvider) => p.getType() == type);
        if (filteredProviders.length == 0) return null;
        return filteredProviders[0];
    }

    private getDataByType(
        type: string,
        skip: number,
        count: number,
        jobOrderId: number,
        filters?: ISearchFilter[],
        includeNotBillable?: boolean,
        getCosts?: boolean,
        includeAlreadyImported?: boolean
    ): Promise<any[]> {
        const provider = this.getProviderByType(type);
        if (!provider) return Promise.resolve<any[]>([]);

        return provider.getData(skip, count, jobOrderId, filters, includeNotBillable, getCosts, includeAlreadyImported);
    }

    getWorkedHours(skip: number, count: number, jobOrderId: number, filters?: ISearchFilter[]): Promise<any[]> {
        return this.getDataByType(ProlifeSdk.FinancialDataWorkedHoursProviderType, skip, count, jobOrderId, filters);
    }

    getPurchases(
        skip: number,
        count: number,
        jobOrderId: number,
        filters?: ISearchFilter[],
        includeNotBillable?: boolean,
        getCosts?: boolean,
        includeAlreadyImported?: boolean
    ): Promise<any[]> {
        return this.getDataByType(
            ProlifeSdk.FinancialDataPurchasesProviderType,
            skip,
            count,
            jobOrderId,
            filters,
            includeNotBillable,
            getCosts,
            includeAlreadyImported
        );
    }

    getPurchasesForMonthlyInvoicing(
        jobOrderId: number,
        jobOrderType: number,
        customerId: number,
        billed: boolean,
        from: Date,
        to: Date
    ): Promise<IPurchaseForMonthlyInvoicing[]> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataPurchasesProviderType);
        if (!provider) return Promise.resolve<any[]>([]);

        return provider.getDataForMonthlyInvoicing(jobOrderId, jobOrderType, customerId, billed, from, to);
    }

    getPurchasesForMonthlyInvoicingCount(
        jobOrderId: number,
        jobOrderType: number,
        customerId: number,
        billed: boolean,
        from: Date,
        to: Date
    ): Promise<number> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataPurchasesProviderType);
        if (!provider) return Promise.resolve<number>(0);

        return provider.getDataForMonthlyInvoicingCount(jobOrderId, jobOrderType, customerId, billed, from, to);
    }

    setSalIdOnPurchases(purchaseIds: number[], salId: number): Promise<void> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataPurchasesProviderType);
        if (!provider) return Promise.resolve<void>(undefined);
        return provider.setSalId(purchaseIds, salId);
    }

    setSalIdOnWorkedHours(workedHoursIds: number[], salId: number): Promise<void> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataWorkedHoursProviderType);
        if (!provider) return Promise.resolve<void>(undefined);
        return provider.setSalId(workedHoursIds, salId);
    }

    deletePurchaseByEventId(eventId: number): Promise<void> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataPurchasesProviderType);
        if (!provider) return Promise.resolve<void>(undefined);

        return provider.deleteByEventId(eventId);
    }

    deleteWorkedHoursByEventId(eventId: number): Promise<void> {
        const provider = this.getProviderByType(ProlifeSdk.FinancialDataWorkedHoursProviderType);
        if (!provider) return Promise.resolve<void>(undefined);

        return provider.deleteByEventId(eventId);
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new FinancialDataService(serviceLocator);
}
