import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { ILogService, ILogEventType } from "../../../../ProlifeSdk/interfaces/desktop/ILogService";

export class BlogEventTypesDropDownList implements IDropDownList
{
    private logService : ILogService;
    private lastTimeout : number;
    public selectedId : ko.Observable<string> = ko.observable();

    private types : ILogEventType[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.logService.GetEventTypes().then((data) => {
            this.types = data;
        });
    }

    public search(query : any)
    {
        query.callback({
            results: this.types
                .filter((d : ILogEventType) => { return (d.Description || "").toUpperCase().indexOf((query.term).toUpperCase()) > -1; })
                .map((g : ILogEventType) =>
                {
                    return {
                        id: g.EventType,
                        text: g.Description
                    };
                })
        });
    }

    public getSelected(element, callback)
    {
        if(this.selectedId()) {
            var matches = this.types.filter((t : ILogEventType) => { return t.EventType == this.selectedId(); });

            callback({
                id: matches[0].EventType,
                text: matches[0].Description
            });
        }
    }
}