import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { SchedulePanelViewModel } from "./ui/SchedulePanelViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";

export class ScheduleApplication implements IApplication {
	public templateName : string = 'schedule_application';
	public templateUrl : string = 'schedule/templates';
	public templateData : SchedulePanelViewModel;
    public tileColor : string = 'tile-red';

	public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

	private applicationsService : IApplicationsService;

	constructor(private serviceLocator : IServiceLocator) {
		this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
		this.applicationsService.registerApplication(this);
	}

    onGoingDown() : void {

    }

    getApplicationCode() {
        return "Schedules";
    }

	getName(): string {
		return ProlifeSdk.TextResources.Schedule.BillBook;
	}

	getIcon(): string {
        return "icon-calendar";
	}

	registerRoutes() : void {
		var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
		opaService.Get(ProlifeSdk.TextResources.Schedule.BillBookURL, (context) => this.start());
	}

	getApplicationRoute() : string {
		return ProlifeSdk.TextResources.Schedule.BillBookURL;
	}

	private start() {
		
		this.templateData = new SchedulePanelViewModel();
		this.templateData.viewInvoiceWithExpiredSchedule();
		this.applicationsService.startApplication(this);
	}
}
/*
export default function Create(serviceLocator : IServiceLocator) : IApplication {
	return new ScheduleApplication(serviceLocator);
}*/