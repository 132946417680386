import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { MonthlyInvoicingDataRow } from "../../../../../ProlifeSdk/prolifesdk/documents/wizard/MonthlyInvoicingDataRow";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { VatRegisterStatus } from "../../../settings/ui/protocols/VatRegisterStatus";
import { CurrencyUtils } from "../../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { IDocumentsService } from "../../../../DocumentsService";
import {
    ICustomerForMonthlyInvoicing,
    IJobOrderForMonthlyInvoicing,
    IMonthlyInvoicingDataSource,
} from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import {
    IDocumentCurrencyViewModel,
    IProtocolDefaults,
} from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IEntityToImportInfoForMonthlyInvoicing,
    ILabelInfo,
} from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IInvoiceForMonthlyInvoicingWizard } from "../../../../interfaces/IInvoiceForMonthlyInvoicingWizard";
import { IEntityForMonthlyInvoicingTree } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IInvoiceForLabelsList } from "../../../../interfaces/IInvoiceForLabelsList";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICurrencyWithCountriesIds } from "../../../../interfaces/ICurrenciesService";
import { IVatRegister, IVatRegisters } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IExpireMode, IExpireModes } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { IPaymentTypeManagerUi } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/ui/IPaymentTypeManagerUi";
import { IPaymentModes } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { ICompanySettingsManager } from "../../../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { INationsSettingsManager } from "../../../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { Deferred } from "../../../../../Core/Deferred";

export class Invoice extends MonthlyInvoicingDataRow implements IInvoiceForMonthlyInvoicingWizard {
    public CustomerId: ko.Observable<number> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public Total: ko.Observable<number> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();
    public ExpiryTypeId: ko.Observable<number> = ko.observable();
    public PaymentTypeId: ko.Observable<number> = ko.observable();
    public PaymentType: ko.Observable<string> = ko.observable();
    public VatRegisterForFinalInvoicing: ko.Observable<number> = ko.observable();
    public VatRegisterNameForFinalInvoicing: ko.Observable<string> = ko.observable();
    public VatRegisterForPartialInvoicing: ko.Observable<number> = ko.observable();
    public VatRegisterNameForPartialInvoicing: ko.Observable<string> = ko.observable();
    public VatRegisters: ko.Computed<IVatRegister[]>;
    public Expiries: ko.ObservableArray<IExpireMode> = ko.observableArray([]);
    public Payment: ko.Observable<any> = ko.observable();
    public PaymentIBAN: ko.Observable<string> = ko.observable();
    public PaymentABI: ko.Observable<string> = ko.observable();
    public PaymentCAB: ko.Observable<string> = ko.observable();
    public PaymentTypeManagerUi: IPaymentTypeManagerUi;
    public DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;
    public TotalIsAvailable: ko.Observable<boolean> = ko.observable(false);
    public ShowTotalInDefaultCurrency: ko.Observable<boolean> | ko.Computed<boolean> = ko.observable(false);
    public IsPartialInvoice = false;

    private customer: ICustomerForMonthlyInvoicing;
    private jobOrder: IJobOrderForMonthlyInvoicing;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegistersManager: IVatRegisters;
    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expiriesManager: IExpireModes;
    @LazyImportSettingManager(ProlifeSdk.PaymentMode)
    public paymentModes: IPaymentModes;
    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    public companySettings: ICompanySettingsManager;
    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    private nationsSettingsManager: INationsSettingsManager;
    @LazyImport(nameof<IDocumentsService>())
    public documentsService: IDocumentsService;
    @LazyImport(nameof<IProLifeSdkService>())
    private prolifeSdkService: IProLifeSdkService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private paymentTypeInterceptor: ko.Computed<void>;
    private currenciesInterceptor: ko.Computed<void>;

    constructor(
        private groupLabel: string,
        isPartialInvoice: boolean,
        public entities: IEntityToImportInfoForMonthlyInvoicing[],
        private wizard: IMonthlyInvoicingDataSource
    ) {
        super();
        this.PaymentTypeManagerUi = <IPaymentTypeManagerUi>this.prolifeSdkService.Ui.GetPaymentTypeManagerUi();
        this.Id = 0;
        this.Name = this.groupLabel;
        this.EntityType = ProlifeSdk.InvoiceEntityTypeCode;
        this.IsPartialInvoice = isPartialInvoice;
        this.Expiries(this.expiriesManager.getExpireModes(false));
        this.ExpiryType(this.getPaymentExpiryFormImportedDocuments());
        this.ExpiryTypeId(this.expiriesManager.getExpireModeIdByName(this.ExpiryType()));

        this.paymentTypeInterceptor = ko.computed(() => {
            this.Payment(this.PaymentTypeManagerUi.Payment());
            this.PaymentTypeId(this.PaymentTypeManagerUi.PaymentId());
            this.PaymentType(this.PaymentTypeManagerUi.PaymentDescription());
            this.PaymentABI(this.PaymentTypeManagerUi.PaymentABI());
            this.PaymentCAB(this.PaymentTypeManagerUi.PaymentCAB());
            this.PaymentIBAN(this.PaymentTypeManagerUi.PaymentIBAN());
        });

        this.PaymentTypeManagerUi.setPaymentInfoFromDescription(this.getPaymentModeFromImportedDocuments());
        this.setExpiryTypeId();

        this.DocumentCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
            if (documentCurrency) {
                const currency = documentCurrency.Currency();
                this.CurrencyCode(currency.CodeISO4217alpha3);
                this.CurrencySymbol(currency.Symbol);
            }
            return documentCurrency;
        });

        this.currenciesInterceptor = ko.computed(() => {
            const documentCurrencies = this.DocumentCurrencies();
            let sum = 0;
            let totalIsAvailable = true;
            this.entities.forEach((d: IEntityToImportInfoForMonthlyInvoicing) => {
                const currency = documentCurrencies.firstOrDefault(
                    (c) => c.Currency().CodeISO4217alpha3 === d.CurrencyCode()
                );
                if (!currency) {
                    totalIsAvailable = false;
                    return;
                }
                const value = CurrencyUtils.applyCurrencyExchange(d.Total(), currency);
                sum += value;
            });
            this.TotalIsAvailable(totalIsAvailable);
            this.Total(sum);
        });

        this.TotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();
            if (!documentCurrency) return 0;
            return CurrencyUtils.applyCurrencyExchange(this.Total(), documentCurrency);
        });

        this.ShowTotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();
            if (!documentCurrency) return false;
            const defualtCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            return defualtCurrency.CodeISO4217alpha3 !== documentCurrency.Currency().CodeISO4217alpha3;
        });

        this.CustomerId(this.entities[0].CustomerId());
        this.CustomerName(this.entities[0].CustomerName());
        this.JobOrderId(this.entities[0].JobOrderId());
        this.JobOrderName(this.entities[0].JobOrderName());

        this.VatRegisters = ko.computed(() => {
            if (!this.storage()) return [];
            return this.getVatRegistersForInvoicing(this.storage().PartialInvoicing());
        });

        this.VatRegisters.subscribe((registers: IVatRegister[]) => {
            if (!this.storage()) return;
            if (this.storage().PartialInvoicing()) {
                this.VatRegisterForPartialInvoicing(
                    registers.length > 0 && !this.VatRegisterForPartialInvoicing()
                        ? registers[0].IdRegistroIVA
                        : this.VatRegisterForPartialInvoicing()
                );
                return;
            }
            if (!this.storage().PartialInvoicing()) {
                this.VatRegisterForFinalInvoicing(
                    registers.length > 0 && !this.VatRegisterForFinalInvoicing()
                        ? registers[0].IdRegistroIVA
                        : this.VatRegisterForFinalInvoicing()
                );
                return;
            }
        });

        this.VatRegisterForFinalInvoicing.subscribe((registerId: number) => {
            if (!registerId) {
                this.VatRegisterNameForFinalInvoicing(null);
                return;
            }
            this.VatRegisterNameForFinalInvoicing(
                this.VatRegisters().length == 0
                    ? null
                    : this.vatRegistersManager
                          .getVatRegisters()
                          .filter((r: IVatRegister) => r.IdRegistroIVA == registerId)[0].NomeRegistroIVA
            );
            this.setDefaultValues(registerId);
        });

        this.VatRegisterForPartialInvoicing.subscribe((registerId: number) => {
            if (!registerId) {
                this.VatRegisterNameForPartialInvoicing(null);
                return;
            }
            this.VatRegisterNameForPartialInvoicing(
                this.VatRegisters().length == 0
                    ? null
                    : this.vatRegistersManager
                          .getVatRegisters()
                          .filter((r: IVatRegister) => r.IdRegistroIVA == registerId)[0].NomeRegistroIVA
            );
            if (!this.storage()) return;
            if (this.storage().PartialInvoicing()) this.setDefaultValues(registerId);
        });

        this.storage.subscribe((storage) => {
            if (storage) {
                this.setInitialVatRegister();
                //this.generateDocumentCurrencies();
            }
        });

        this.ExpiryType.subscribe((expiry: string) => {
            this.ExpiryTypeId(this.expiriesManager.getExpireModeIdByName(expiry));
        });
    }

    public async Initialize(): Promise<void> {
        this.customer = this.wizard.CustomersCache.get(this.CustomerId());
        this.jobOrder = this.wizard.JobOrdersCache.get(this.JobOrderId());

        this.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(
            !this.customer ? [] : this.customer.OrganizationalUnits
        );
        if (this.storage()) {
            await this.setDefaultValues(
                this.storage().PartialInvoicing()
                    ? this.VatRegisterForPartialInvoicing()
                    : this.VatRegisterForFinalInvoicing()
            );
            this.storage().UpdateLabelInfo(this.LabelForInvoiceGrouping(), this.GetLabelInfo());
            this.generateDocumentCurrencies();
        }
    }

    public MatchByGroupLabel(label: string): boolean {
        return (!this.groupLabel ? "" : this.groupLabel.toUpperCase()) == (!label ? "" : label.toUpperCase());
    }

    public async GetCopy(): Promise<IInvoiceForMonthlyInvoicingWizard> {
        const invoice = new Invoice(this.groupLabel, this.IsPartialInvoice, this.entities, this.wizard);
        await invoice.Initialize();
        invoice.storage(null);
        invoice.VatRegisterForFinalInvoicing(this.VatRegisterForFinalInvoicing());
        invoice.VatRegisterNameForFinalInvoicing(this.VatRegisterNameForFinalInvoicing());
        invoice.VatRegisterForPartialInvoicing(this.VatRegisterForPartialInvoicing());
        invoice.VatRegisterNameForPartialInvoicing(this.VatRegisterNameForPartialInvoicing());
        invoice.ExpiryTypeId(this.ExpiryTypeId());
        invoice.ExpiryType(this.ExpiryType());
        invoice.PaymentTypeManagerUi.Payment(this.PaymentTypeManagerUi.Payment());
        invoice.PaymentTypeManagerUi.PaymentIBAN(this.PaymentTypeManagerUi.PaymentIBAN());
        invoice.PaymentTypeManagerUi.PaymentABI(this.PaymentTypeManagerUi.PaymentABI());
        invoice.PaymentTypeManagerUi.PaymentCAB(this.PaymentTypeManagerUi.PaymentCAB());
        invoice.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        invoice.DocumentCurrencies(
            this.DocumentCurrencies().map((c) =>
                this.documentsService.DocumentCurrenciesFactory.createFromModel(c.getData())
            )
        );
        return invoice;
    }

    public GetDocumentForMonthlyInvoicingTree(
        importedDocuments: IEntityForMonthlyInvoicingTree[],
        documentLabel: string = null
    ): IEntityForMonthlyInvoicingTree {
        return {
            EntityId: this.Id,
            EntityType: this.EntityType,
            DocumentLabel: documentLabel === null ? this.Name : documentLabel,
            VatRegisterId:
                !this.storage() || !this.storage().PartialInvoicing()
                    ? this.VatRegisterForFinalInvoicing()
                    : this.VatRegisterForPartialInvoicing(),
            CustomerId: this.CustomerId(),
            CustomerName: this.CustomerName(),
            JobOrderId: this.JobOrderId(),
            ExpiryTypeId: this.ExpiryTypeId(),
            ExpiryType: this.ExpiryType(),
            PaymentTypeId: this.PaymentTypeManagerUi.PaymentId(),
            PaymentType: this.PaymentTypeManagerUi.PaymentDescription(),
            PaymentIBAN: this.PaymentTypeManagerUi.PaymentIBAN(),
            PaymentABI: this.PaymentTypeManagerUi.PaymentABI(),
            PaymentCAB: this.PaymentTypeManagerUi.PaymentCAB(),
            DocumentCurrencies: this.DocumentCurrencies().map((c) => c.getData()),
            IsPartialInvoice: this.IsPartialInvoice,
            ImportedDocuments: importedDocuments,
            WorkedHoursRow: null,
            PurchasesRow: null,
        };
    }

    public ApplyProtocolToSelectedInvoices(): void {
        if (!this.storage()) return;
        this.storage()
            .SelectedEntities()
            .forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
                if (this.storage().PartialInvoicing())
                    i.VatRegisterForPartialInvoicing(this.VatRegisterForPartialInvoicing());
                else i.VatRegisterForFinalInvoicing(this.VatRegisterForFinalInvoicing());
            });
    }

    public ApplyLabelToSelectedInvoices(): void {
        if (!this.storage()) return;
        const invalidSelections: string[] = [];

        const selectedEntities: IInvoiceForMonthlyInvoicingWizard[] = this.storage().SelectedEntities();
        for (const entity of selectedEntities) {
            if (!this.VerifyDocumentCompatibility(entity.GetLabelInfo())) {
                invalidSelections.push(entity.Name);
                continue;
            }

            entity.ApplyLabelForInvoicingGrouping(this.LabelForInvoiceGrouping());
        }

        if (invalidSelections.length > 0) {
            let message = "<br/>";
            invalidSelections.forEach((m: string) => (message += m + "<br/>"));
            message += "<br/>";
            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.InvalidDocumentsForLabelApplication, message)
            );
        }
    }

    public ApplyExpiryToSelectedInvoices(): void {
        if (!this.storage()) return;

        this.storage()
            .SelectedEntities()
            .forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
                i.ExpiryTypeId(this.ExpiryTypeId());
                i.ExpiryType(this.ExpiryType());
            });

        this.regenerateLabels().then((confirm: boolean) => {
            if (confirm) this.storage().RegenerateLabels();
        });
    }

    public ApplyPaymentToSelectedInvoices(): void {
        if (!this.storage()) return;

        this.storage()
            .SelectedEntities()
            .forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
                i.PaymentTypeManagerUi.Payment(this.PaymentTypeManagerUi.Payment());
                i.PaymentTypeManagerUi.PaymentIBAN(this.PaymentTypeManagerUi.PaymentIBAN());
                i.PaymentTypeManagerUi.PaymentABI(this.PaymentTypeManagerUi.PaymentABI());
                i.PaymentTypeManagerUi.PaymentCAB(this.PaymentTypeManagerUi.PaymentCAB());
            });

        this.regenerateLabels().then((confirm: boolean) => {
            if (confirm) this.storage().RegenerateLabels();
        });
    }

    public GetLabelInfo(): ILabelInfo {
        const ddtLabelInfo: ILabelInfo = this.entities[0].GetLabelInfo();
        return {
            EntityType: this.EntityType,
            Label: this.LabelForInvoiceGrouping(),
            CustomerId: ddtLabelInfo.CustomerId,
            JobOrder: ddtLabelInfo.JobOrder,
            PaymentMode: this.PaymentTypeManagerUi.PaymentDescription(),
            ExpiryMode: this.ExpiryType(),
            NumberOfItemsWithLabel: 0,
        };
    }

    public VerifyDocumentCompatibility(labelInfo: ILabelInfo): boolean {
        return (
            this.CustomerId() == labelInfo.CustomerId &&
            (this.ExpiryType() || "").replace(/\s/g, "") == (labelInfo.ExpiryMode || "").replace(/\s/g, "") &&
            (this.PaymentTypeManagerUi.PaymentDescription() || "").replace(/\s/g, "") ==
                (labelInfo.PaymentMode || "").replace(/\s/g, "")
        );
    }

    public HasValidExpiry(): boolean {
        return !!this.ExpiryType();
    }

    public HasValidPayment(): boolean {
        let isValid = !!this.PaymentTypeManagerUi.Payment();
        const errors = this.PaymentTypeManagerUi.getValidator().validate(this.PaymentTypeManagerUi);
        isValid = isValid && errors.length === 0;
        if (errors.length > 0) this.infoToastService.Warning(errors.map((e) => e.message).join("<br/>"));
        return isValid;
    }

    private generateDocumentCurrencies() {
        const documentCurrencyCode = this.DocumentCurrency().Currency().CodeISO4217alpha3;
        const entitiesCurrenciesMap = {};
        for (const entity of this.entities) {
            // Faccio uan distinct delle valute
            entitiesCurrenciesMap[entity.CurrencyCode()] = entity.CurrencyCode();
        }
        for (const currencyCode in entitiesCurrenciesMap) {
            if (documentCurrencyCode !== currencyCode) {
                if (this.DocumentCurrencies().any((c) => c.Currency().CodeISO4217alpha3 === currencyCode)) continue;

                const currency = this.currenciesSettingsManager.getCurrencyByCode(currencyCode);
                const entityCurrency = this.documentsService.DocumentCurrenciesFactory.create(
                    this.Id,
                    this.EntityType,
                    currency.Id
                );
                entityCurrency.ExchangeValue(null);
                entityCurrency.ExchangeValueForVat(null);
                entityCurrency.IsDocumentCurrency(false);
                this.DocumentCurrencies.push(entityCurrency);
            }
        }
    }

    private regenerateLabels(): Promise<boolean> {
        const def = new Deferred<boolean>();
        if (this.storage() && !this.storage().PartialInvoicing()) return def.resolve(false).promise();
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Invoices.ConfirmLabelsRegeneration,
            ProlifeSdk.TextResources.Invoices.NoButton,
            ProlifeSdk.TextResources.Invoices.YesButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private async setDefaultValues(protocolId: number): Promise<void> {
        const defaults = await this.documentsService.ComputeDefaultsForDocument(
            protocolId,
            this.CustomerId(),
            this.JobOrderId(),
            ProlifeSdk.InvoiceEntityTypeCode
        );
        this.applyDefaultSettings(defaults);
    }

    private applyDefaultSettings(defaults: IProtocolDefaults): void {
        if (defaults.FKPaymentType && !this.PaymentTypeManagerUi.Payment())
            this.PaymentTypeManagerUi.Payment(
                this.paymentModes.getPaymentModes().firstOrDefault((p) => p.IdTipoPagamento === defaults.FKPaymentType)
            );
        if (defaults.PaymentIBAN && !this.PaymentTypeManagerUi.PaymentIBAN())
            this.PaymentTypeManagerUi.PaymentIBAN(defaults.PaymentIBAN);
        if (defaults.PaymentABI && !this.PaymentTypeManagerUi.PaymentABI())
            this.PaymentTypeManagerUi.PaymentABI(defaults.PaymentABI);
        if (defaults.PaymentCAB && !this.PaymentTypeManagerUi.PaymentCAB())
            this.PaymentTypeManagerUi.PaymentCAB(defaults.PaymentCAB);
        if (defaults.FKExpiryType && !this.ExpiryType()) {
            const expiry = this.expiriesManager
                .getExpireModes()
                .firstOrDefault((e) => e.IdTipoScadenza === defaults.FKExpiryType);
            this.ExpiryType(expiry?.Descrizione);
            this.ExpiryTypeId(expiry?.IdTipoScadenza);
        }
        if (defaults.FKCurrency) {
            this.createOrSetDocumentCurrency(defaults.FKCurrency);
        }
        if (!this.DocumentCurrency())
            this.createOrSetDocumentCurrencyFromCustomerAndImportedEntitiesCurrenciesIntersection();
    }

    private createOrSetDocumentCurrencyFromCustomerAndImportedEntitiesCurrenciesIntersection(): void {
        if (!this.customer) return;
        const stateCode = this.customer.Country;
        const nation = this.nationsSettingsManager.getNationByAplpha2Code(stateCode);
        let customerCurrencies: ICurrencyWithCountriesIds[] = [];
        if (nation) customerCurrencies = this.currenciesSettingsManager.getCurrencies(nation.Name, true);
        const importedDdtsCurrencies: string[] = this.entities
            .filter((e) => e.EntityType === ProlifeSdk.DdtEntityTypeCode)
            .map((e) => e.CurrencyCode());
        let selectedCurrency: ICurrencyWithCountriesIds = null;
        for (const currency of customerCurrencies) {
            if (importedDdtsCurrencies.indexOf(currency.CodeISO4217alpha3) >= 0) {
                selectedCurrency = currency;
                break;
            }
        }
        if (!selectedCurrency) {
            selectedCurrency =
                customerCurrencies.length > 0
                    ? customerCurrencies[0]
                    : this.currenciesSettingsManager.getDefaultCurrency(); // Se non ho nessuna valuta per il cliente prendo la valuta di default perché per ora le altre entità (acquisti e ore lavorate) sono sempre in Euro
        }
        const documentCurrency = this.documentsService.DocumentCurrenciesFactory.create(
            this.Id,
            this.EntityType,
            selectedCurrency.Id
        );
        documentCurrency.IsDocumentCurrency(true);
        this.DocumentCurrencies.push(documentCurrency);
        this.setDefaultExchangeValuesForDocumentCurrency();
    }

    private createOrSetDocumentCurrency(currencyId: number): void {
        let documentCurrency = this.DocumentCurrency();
        if (documentCurrency && !!documentCurrency.CurrencyId()) return;
        if (!documentCurrency) {
            documentCurrency = this.documentsService.DocumentCurrenciesFactory.create(
                this.Id,
                this.EntityType,
                currencyId
            );
            documentCurrency.IsDocumentCurrency(true);
            this.DocumentCurrencies.push(documentCurrency);
            this.setDefaultExchangeValuesForDocumentCurrency();
        }
        if (!documentCurrency.CurrencyId()) {
            documentCurrency.CurrencyId(currencyId);
            this.setDefaultExchangeValuesForDocumentCurrency();
        }

        this.generateDocumentCurrencies();
    }

    private setDefaultExchangeValuesForDocumentCurrency(): void {
        const documentCurrency = this.DocumentCurrency();
        const defualtCurrency = this.currenciesSettingsManager.getDefaultCurrency();
        if (defualtCurrency.CodeISO4217alpha3 === documentCurrency.Currency().CodeISO4217alpha3) {
            documentCurrency.ExchangeValue(1);
            documentCurrency.ExchangeValueForVat(1);
        } else {
            documentCurrency.ExchangeValue(null);
            documentCurrency.ExchangeValueForVat(null);
        }
    }

    private getVatRegisterForFinalInvoicing(): IVatRegister {
        const vatRegisterId: number = this.storage().PartialInvoicing()
            ? this.getVatRegisterIdFromInvoicingLabel()
            : this.VatRegisterForFinalInvoicing();
        return this.vatRegistersManager.getVatRegisterById(vatRegisterId);
    }

    private getVatRegisterIdFromInvoicingLabel(): number {
        const labels: IInvoiceForLabelsList[] = this.storage()
            .InvoicesToProduce()
            .filter(
                (l: IInvoiceForLabelsList) =>
                    (!l.label ? "" : l.label.toUpperCase()) ==
                    (!this.LabelForInvoiceGrouping() ? "" : this.LabelForInvoiceGrouping().toUpperCase())
            );
        if (labels.length == 0) return null;
        return labels[0].VatRegister();
    }

    private getPaymentExpiryFormImportedDocuments(): string {
        const expiries: string[] = this.entities
            .filter((d: IEntityToImportInfoForMonthlyInvoicing) => !!d.ExpiryType())
            .map((d: IEntityToImportInfoForMonthlyInvoicing) => d.ExpiryType());
        if (expiries.length == 0) return "";
        return expiries[0];
    }

    private getPaymentModeFromImportedDocuments(): string {
        const payments: string[] = this.entities
            .filter((d: IEntityToImportInfoForMonthlyInvoicing) => !!d.PaymentType())
            .map((d) => d.PaymentType());
        if (payments.length == 0) return null;
        return payments[0];
    }

    private getVatRegistersForInvoicing(partialInvoicing = false): IVatRegister[] {
        return this.vatRegistersManager.getVatRegisters().filter((v: IVatRegister) => {
            return (
                v.TipoDocumento == ProlifeSdk.InvoiceDocumentType &&
                (v.TipiFatture.charAt(0) == "1" || v.TipiFatture.charAt(2) == "1") &&
                v.ForPartialInvoices == partialInvoicing &&
                v.Stato == VatRegisterStatus.Enabled
            );
        });
    }

    private setInitialVatRegister(): void {
        let registersForInvoicing: IVatRegister[] = this.getVatRegistersForInvoicing();
        if (registersForInvoicing.length > 0 && !this.VatRegisterForFinalInvoicing())
            this.VatRegisterForFinalInvoicing(registersForInvoicing[0].IdRegistroIVA);
        registersForInvoicing = this.getVatRegistersForInvoicing(true);
        if (registersForInvoicing.length > 0 && !this.VatRegisterForPartialInvoicing())
            this.VatRegisterForPartialInvoicing(registersForInvoicing[0].IdRegistroIVA);
    }

    private setExpiryTypeId(): void {
        this.ExpiryTypeId(this.expiriesManager.getExpireModeIdByName(this.ExpiryType()));
    }
}
