import * as React from "@abstraqt-dev/jsxknockout";
import { IDocumentAction } from "./DocumentAction";
import { Document } from "./Document";
import { ReportPrintButton, _ReportPrintButton } from "../../../Components/ReportDesigner/ReportPrintButton";
import "./DocumentAction";

export function DocumentActions(props: { document: Document }) {
    const doc: Document = props.document;
    let $data: Document;
    let action: IDocumentAction;

    return (
        <>
            <div class="document-menu flex-container" data-as={{ doc }}>
                <ko-bind data-bind={{ if: doc.HasActions }}>
                    <ko-bind data-bind={{ foreach: { data: doc.Actions, as: "action" } }}>
                        <ko-if data-bind="action.render">
                            <ko-bind data-bind={{ tsxtemplate: action }} />
                        </ko-if>
                        <ko-ifnot data-bind="action.render">
                            <ko-bind
                                data-bind={{ component: { name: "document-action", params: { Config: action } } }}
                            ></ko-bind>
                        </ko-ifnot>
                    </ko-bind>
                </ko-bind>
                <excel-exporter
                    data-bind="visible: doc.ReadOnly"
                    exporterId={() => "doc.ExcelExporterId"}
                    exporterMethod={() => "doc.ExcelExporterMethod"}
                    exporterController={() => "doc.ExcelExporterController"}
                    dataProvider={() => "doc.excelExporterDataProvider.bind(doc)"}
                    cssClasses="btn btn-sm blue"
                >
                    <i class="fa fa-download"></i>&nbsp;Esporta
                </excel-exporter>

                <excel-exporter
                    data-bind="visible: doc.ReadOnly"
                    exporterId={() => "doc.RefExcelExporterId"}
                    exporterMethod={() => "doc.RefExcelExporterMethod"}
                    dataProvider={() => "doc.refExcelExporterDataProvider.bind(doc)"}
                    cssClasses="btn btn-sm blue"
                >
                    <i class="fa fa-download"></i>&nbsp;Esporta Rif.
                </excel-exporter>

                <ko-bind data-bind={{ if: doc.ReadOnly }}>
                    <ko-bind data-bind={{ tsxtemplate: doc.MailSendButton }} />
                </ko-bind>
            </div>

            <ko-bind data-bind={{ if: $data.HasEditActions }}>
                <div class="document-menu hidden-print right-actions flex-container">
                    <ko-foreach data-bind="EditActions">
                        <document-action config={() => "$data"}></document-action>
                    </ko-foreach>

                    {/* <a href="#" class="btn btn-sm btn-danger" data-bind="click: close, visible: ReadOnly() && $index() == 0">
                            <i class="fa fa-times" style="margin-right : 5px;"></i>
                            <span class="btn-small-text">@T.Label("Chiudi")</span>
                        </a> */}
                </div>
            </ko-bind>
        </>
    );
}
