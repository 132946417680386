import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { If } from "../../../../../Components/IfIfNotWith";
import { AllocationMode } from "../../../enums/AllocationMode";
import { PriorityMarker } from "./PriorityMarker";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { TeamsPopover } from "../TeamsPopover";
import { CartContentDialog } from "../CartContentDialog";

const styleSheet = jss.createStyleSheet({
    "cart-name": {
        alignItems: "center",

        "& span": {
            display: "inline-block",

            "&.icon": {
                width: "15px"
            }
        }
    }
});
const { classes } = styleSheet.attach();

type AllocatedCartProps = {
    cart: CartAllocation;
}

export type CartAllocation = {
    allocationId: number;
    teamId: number;
    cartId: number;
    cartName: string;
    cartOrder: number;
    allocationMode: AllocationMode;

    startDate: Date;
    endDate: Date;

    HasPriority: ko.Observable<boolean>;
    AllocatedOnTeamsNumber: ko.Observable<number>;
}

export function AllocatedCart(props: AllocatedCartProps) {
    const C = require("./AllocatedCart")._AllocatedCart as typeof _AllocatedCart;
    return <C {...props} />;
}

export class _AllocatedCart {
    static defaultProps: Partial<AllocatedCartProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : AllocatedCartProps) {
        
    }

    public async showTeamsPopOver(event: MouseEvent): Promise<void> {
        if (!this.props.cart.AllocatedOnTeamsNumber() || this.props.cart.AllocatedOnTeamsNumber() < 2)
            return;
        const popover = new TeamsPopover(this.props.cart.cartId);

        await this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, popover, 'right');
    }

    public async showCartContent(): Promise<void> {
        const cartContentDialog = new CartContentDialog({ cartId: this.props.cart.cartId });
        return this.dialogsService.ShowModal(cartContentDialog);
    }
    
    render() {
        return  <div className={ComponentUtils.classNames("flex-container", classes["cart-name"])}>
                    <span className="icon" style={{ height: "100%" }}>
                        <If condition={this.props.cart.HasPriority}>
                            {() => <PriorityMarker />}
                        </If>
                    </span>
                    <span className="icon">
                        <If condition={() => this.props.cart.AllocatedOnTeamsNumber() > 1}>
                            {() => <i className="item-text-icon fa fa-users black" style="margin-right: 5px" onClick={(e) => this.showTeamsPopOver(e)}></i>}
                        </If>
                    </span>
                    <span className="icon">
                        <i className="item-text-icon fa fa-list-alt" style="margin-right: 5px" onClick={() => this.showCartContent()}></i>
                    </span>
                    <span className="icon">
                        <i
                            className={ComponentUtils.classNames("item-text-icon",  { 
                                                                                        "fa fa-dropbox": this.props.cart.allocationMode === AllocationMode.AutomaticAllocation,
                                                                                        "fa fa-calendar": this.props.cart.allocationMode === AllocationMode.ManualAllocation
                                                                                    })
                                        }
                            style="margin-right: 5px"
                        ></i>
                    </span>
                    <span className="flex-fill text-ellipsis">{this.props.cart.cartName}</span>
                </div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AllocatedCart);
}