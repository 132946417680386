import * as ko from "knockout"

export class KoBindingProvider implements ko.IBindingProvider {
    constructor(private originalBindingProvider : ko.IBindingProvider) {

    }
    
    nodeHasBindings(node : Node) {
        switch (node.nodeType) {
            case 1: // Element               
            case 8: // Comment node
                return typeof $.data(node, "___ko_prolife_binding___") === "function"
                    || $.data(node, "___ko_prolife_viewModel___") !== undefined
                    || $.data(node, "___ko_prolife_additionalProperties___") !== undefined
                    || this.originalBindingProvider.nodeHasBindings(node);
            default: 
                return false;
        }
    }

    getBindings(node : Node, bindingContext : ko.BindingContext) {
        let context = bindingContext;

        const forceViewModel = $.data(node, "___ko_prolife_viewModel___");
        const forceAs = $.data(node, "___ko_prolife_as___");
        const additionalProperties = $.data(node, "___ko_prolife_additionalProperties___");

        if(forceViewModel) {
            context = bindingContext.createChildContext(forceViewModel, forceAs);
        }

        const additionalBindings = additionalProperties ? Object.assign({}, { let: additionalProperties }) : null;
        const bindingFunction = $.data(node, "___ko_prolife_binding___");
        const parsedBindings = bindingFunction ? bindingFunction(node, context) : null;

        const bindingsString = this.originalBindingProvider['getBindingsString'](node, context);
        const attributeBindings = bindingsString ? this.originalBindingProvider['parseBindingsString'](bindingsString, context, node) : null;

        const allBindings = (parsedBindings || attributeBindings || additionalBindings) ? Object.assign({}, parsedBindings, attributeBindings, additionalBindings) : null;

        return (ko.components as any).addBindingsForCustomElement(allBindings, node, context, /* valueAccessors */ false);
    }

    getBindingAccessors(node : Node, bindingContext : ko.BindingContext) {
        let context = bindingContext;

        const forceViewModel = $.data(node, "___ko_prolife_viewModel___");
        const forceAs = $.data(node, "___ko_prolife_as___");
        const additionalProperties = $.data(node, "___ko_prolife_additionalProperties___");

        if(forceViewModel) {
            context = bindingContext.createChildContext(forceViewModel, forceAs);
        }

        const additionalBindings = additionalProperties ? Object.assign({}, { let: additionalProperties }) : null;
        const bindingFunction = $.data(node, "___ko_prolife_binding___");
        const parsedBindings = bindingFunction ? Object.assign({}, bindingFunction(node, context)) : null;
        
        for(const key in parsedBindings) {
            if(!parsedBindings.hasOwnProperty(key)) continue;
            const actualBinding = parsedBindings[key];
            parsedBindings[key] = function() { return actualBinding; }
        }

        const bindingsString = this.originalBindingProvider['getBindingsString'](node, context);
        const attributeBindings = bindingsString ? this.originalBindingProvider['parseBindingsString'](bindingsString, context, node, { 'valueAccessors': true }) : null;

        const allBindings = (parsedBindings || attributeBindings || additionalBindings) ? Object.assign({}, parsedBindings, attributeBindings, additionalBindings) : null;

        return (ko.components as any).addBindingsForCustomElement(allBindings, node, context, /* valueAccessors */ true);
    }
}

const oldBindingProvider = ko.bindingProvider['instance'];
ko.bindingProvider['instance'] = new KoBindingProvider(oldBindingProvider);
