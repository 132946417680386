import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/06/2017
 * Time: 13:07
 * To change this template use File | Settings | File Templates.
 */

import { Answer } from "../Answer";
import { QuestionForEdit } from "./QuestionForEdit";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionnaireEditViewModel } from "./QuestionnaireEditViewModel";
import { ISortable } from "../../../../interfaces/ISortable";
import { IAnswer, IAnswerForEdit } from "../../../../../ProlifeSdk/interfaces/survey/IAnswer";

export class AnswerForEdit extends Answer implements ISortable{
    public templateName = "edit-answer";
    public templateUrl = "survey/templates/questionnaire";

    public valueTemplate: ko.Computed<string>;

    public HideButtonText: ko.Computed<string>;

    public IsNew: boolean;
    public Deleted: ko.Observable<boolean> = ko.observable(false);

    public CanDelete: ko.Computed<boolean>;
    public CanRestore: ko.Computed<boolean>;

    public IsIncomplete: ko.Computed<boolean>;

    private IsParentDeleted: ko.Computed<boolean>;

    public Editor: QuestionnaireEditViewModel;

    constructor(answer: IAnswer, isNew: boolean, private question: QuestionForEdit) {
        super(answer);

        this.IsNew = isNew;

        this.Editor = question.GetEditor();

        this.valueTemplate = ko.computed(() => {
            if( question.AnswerTypeId() == ProlifeSdk.AnswerMoneyField)
                return "answer-money-field";
            if( question.AnswerTypeId() == ProlifeSdk.AnswerTextField)
                return "answer-text-field";
            if( question.AnswerTypeId() == ProlifeSdk.AnswerDateField)
                return "answer-date-field";
            if( question.AnswerTypeId() == ProlifeSdk.AnswerNumericField)
                return "answer-number-field";
            if( question.AnswerTypeId() == ProlifeSdk.AnswerBoolField)
                return "answer-boolean-field";
            return "";

        });

        this.IsParentDeleted = ko.computed(() => {
            return !this.question ? false : question.Deleted();
        });

        this.CanDelete = ko.computed(() => {
            return !this.IsParentDeleted() && !this.Deleted();
        });

        this.CanRestore = ko.computed(() => {
            return !this.IsParentDeleted() && this.Deleted();
        });

        this.IsIncomplete = ko.computed(() => {
            return !this.Text() || !this.Value();
        });

        this.HideButtonText = ko.computed(() => {
            return this.HideValue() ? ProlifeSdk.TextResources.Survey.HideButtonEnabled : ProlifeSdk.TextResources.Survey.HideButtonDisabled;
        });
    }

    public Delete() {
        this.Deleted(true);
    }

    public Restore() {
        this.Deleted(false);
    }

    public ToJSON(): IAnswerForEdit {
        var json =  <IAnswerForEdit> super.ToJSON();
        json.IsNew = this.IsNew;
        json.Deleted = this.Deleted();
        return json;
    }

    public GetLabelsListForTypeahead(listLength: number, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any, numberOfElementsLoaded? : number) {
        return this.Editor.getLabelsListForTypeahead(listLength, query, process, asyncProcess, numberOfElementsLoaded);
    }
}