import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 27/04/2017
 * Time: 10:14
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../.././../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import {
    IJobOrderEditorPanelFactory,
    IJobOrderEditor,
    IJobOrderEditorPanel,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import {
    IJobOrderService,
    IDocumentExpire,
    IDocumentExpiryHistory,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IFileRepositoryService } from "../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";

export class JobOrderDocumentExpiriesFactory implements IJobOrderEditorPanelFactory {
    private authorizationService: IAuthorizationService;

    constructor(serviceLocator: IServiceLocator, public Color: string) {
        this.authorizationService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderDocumentExpiries(serviceLocator, editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return (
            this.authorizationService.isAuthorized("JobOrders_ViewDocuments") &&
            this.authorizationService.isAuthorized("!Warehouse")
        );
    }
}

class JobOrderDocumentExpiries implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.DocumentExpiries);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "documentExpiries";

    ExporterId = "JobOrder/JobOrderDocumentExpiriesExport";

    private jobOrderService: IJobOrderService;
    private fileRepositoryService: IFileRepositoryService;
    private dialogsService: IDialogsService;

    public ShowAll: ko.Observable<boolean> = ko.observable(false);
    public Expires: ko.ObservableArray<IDocumentExpire> = ko.observableArray();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator: IServiceLocator, private editor: IJobOrderEditor, public Color: string) {
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.fileRepositoryService = <IFileRepositoryService>(
            serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);

        this.ShowAll.subscribe(this.load.bind(this));
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {}

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    OnWorkflowSelectionChanged(selection: number[]) {}

    OnWorkflowDeleted(workflowId: number) {}

    OnWorkflowChanged(workflowId: number) {}

    OnWorkflowCreated(workflowId: number) {}

    OnWorkflowImportedFromTemplate(workflowId: number) {}

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    dispose() {}

    load(): Promise<IDocumentExpire[]> {
        return this.jobOrderService
            .getDocumentExpires(this.editor.JobOrderId, this.ShowAll())
            .then((expires: IDocumentExpire[]) => {
                this.Expires(expires);
                return expires;
            });
    }

    isDefaultOnNew(): boolean {
        return false;
    }

    isDefault(): boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel(): Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    public editExpiry(expiry: IDocumentExpire) {
        var dialog = new EditExpiryDialog(this.serviceLocator, expiry);
        dialog.ShowModal().then(() => {
            this.load();
        });
    }

    public getExcelExportData(): any | null {
        return {
            jobOrderId: this.editor.JobOrderId,
        };
    }
}

export class EditExpiryDialog implements IDialog {
    templateName = "editExpiryDialog";
    templateUrl = "joborder/templates/joborderdetail";
    title: string = ProlifeSdk.TextResources.JobOrder.EditExpiryDialog;

    modal: {
        close: (result?: any) => void;
    };

    private jobOrderService: IJobOrderService;
    private infoToastService: IInfoToastService;

    public History: ko.ObservableArray<IDocumentExpiryHistory> = ko.observableArray();
    public NewExpiryDate: ko.Observable<Date> = ko.observable(new Date());
    public Note: ko.Observable<string> = ko.observable();

    public CanEdit: ko.Observable<boolean> = ko.observable();

    constructor(private serviceLocator: IServiceLocator, private expiry: IDocumentExpire) {
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.infoToastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.load();
    }

    public ShowModal(canEdit = true): Promise<Date> {
        var dialogsService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.CanEdit(canEdit);
        this.title = canEdit
            ? ProlifeSdk.TextResources.JobOrder.EditExpiryDialog
            : ProlifeSdk.TextResources.JobOrder.EditExpiryDialogReadOnlyMode;
        return dialogsService.ShowModal<Date>(this, "", null, this.templateUrl, this.templateName);
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        if (!this.CanEdit()) {
            this.modal.close(null);
            return;
        }

        this.jobOrderService
            .insertDocumentExpiryVariation(this.expiry.RowId, this.expiry.RowType, this.NewExpiryDate(), this.Note())
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.DocumentExpiryVariationSaved);

                this.modal.close(this.NewExpiryDate());
            });
    }

    private load() {
        this.jobOrderService
            .getDocumentExpiryHistory(this.expiry.RowId, this.expiry.RowType)
            .then((results: IDocumentExpiryHistory[]) => {
                results = results.map((h: IDocumentExpiryHistory) => {
                    if (!h.Id) h.Note = ProlifeSdk.TextResources.JobOrder.RowExpiryFromDocumentRow;
                    return h;
                });
                this.History(results);
            });
    }
}
