import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImportSettingManager, LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService, IDocumentForList, IGetDocumentsForListRequest } from "../Invoices/DocumentsService";
import { DocumentNumberPrintChoices } from "../Invoices/invoices/enums/DocumentNumberPrintChoices";
import { IDataSourceModel, IDataSourceIcon } from "./IDataSource";
import { INavigationMenuComponentBadge } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IEstimateStates } from "../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { IChangesNotificationsService } from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IDocumentCreated, IDocumentChanged, IDocumentDeleted, IDocumentEventBase } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { DocumentLogicalStatus } from "../Invoices/invoices/enums/DocumentLogicalStatus";
import { DocumentClosingStatusIndicatorUI } from "../Components/DocumentClosingStatusIndicator";

export interface IRegistersGroup {
    label: string;
    vatRegisters: IVatRegister[];
}

export interface IRegistersGroupDataSourceModel extends IDataSourceModel<string, IRegistersGroup> {
    isRegistersGroup: true;
}

export interface IRegisterForDocumentsMenuDataSourceModel extends IDataSourceModel<number, IVatRegister> {
    isVatRegister: true;
}

export interface IDocumentForMenuDataSourceModel extends IDataSourceModel<number, IDocumentForList> {
    entityType: string;
    documentDate: Date;
    totalInDocumentCurrency: number;
    currencySymbol: string;
    jobOrderName: string;
    isApproved: boolean;
    partialInvoice: boolean;
    creditNote: boolean;
    downPayment: boolean;
    hasWithholdingTax: boolean;
    schedulesState: number;
    stateIcon: IStateIconInfo;
    blocked: boolean;
    registerId: number;

    showStateIcon: boolean;
    showBillingStateBadge: boolean;

    billingStateBadge: INavigationMenuComponentBadge;

    closingStatusIndicator: DocumentClosingStatusIndicatorUI;
}

interface ILabelsForDocumentType {
    DocumentTypeCodes: string[];
    ProviderIdPrefix: string;
}

interface IClosureStatusColors {
    background: string;
    foreground: string;
}

interface IBillingStateInfo {
    label: string;
    title: string;
    cssClass: string;
}

interface IStateIconInfo {
    icon: string;
    class: string;
}

export class DocumentsMenuDataSource extends BaseDataSource<IDataSourceModel<number|string, IRegistersGroup|IVatRegister|IDocumentForList>> {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImportSettingManager(ProlifeSdk.EstimateStates)
    private estimateStates: IEstimateStates;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;
    
    private registersGroupsCache: IRegistersGroupDataSourceModel[] = [];
    
    private closureStatusColors: Map<number, IClosureStatusColors>;
    private billingStates: Map<number, IBillingStateInfo>;
    private stateIconsInfo: Map<number, IStateIconInfo>;
    
    private startDate: Date;
    private endDate: Date;
    private searchOnDocumentsContent: boolean;
    
    constructor() {
        super();
        this.setupClosureStatusColors();
        this.setupBillingStates();
        this.setupStateIconsInfo();

        this.changesNotificationsService.RegisterEventHandler("OnDocumentCreated", this.onDocumentCreated.bind(this));
        this.changesNotificationsService.RegisterEventHandler("OnDocumentChanged", this.onDocumentChanged.bind(this));
        this.changesNotificationsService.RegisterEventHandler("OnDocumentDeleted", this.onDocumentDeleted.bind(this));
    }

    private createDocumentForListFromEvent(event: IDocumentEventBase) : IDocumentForList {
        return {
            Id: event.id,
            FKRegister: event.fkRegister,
            EntityType: event.entityType,
            Date: event.date,
            Number: event.number,
            CustomerName: event.customerName,
            JobOrderName: event.jobOrderName,
            TotalInDocumentCurrency: event.totalInDocumentCurrency,
            Currency: event.currency,
            IsApproved: event.isApproved,
            NumberPrintChoice: event.numberPrintChoice,
            FullVersionRevisionNumber: event.fullVersionRevisionNumber,
            ClosureStatus: event.closureStatus,
            BillingLogicalStatus: event.billingLogicalStatus,
            DownPayment: event.downPayment,
            HasWithholdingTax: event.hasWithholdingTax,
            SchedulesStatus: event.schedulesStatus,
            Blocked: event.blocked,
            ForPartialInvoicesRegister: event.forPartialInvoicesRegister,
            CurrencyCode: null, //event.currencyCode,
            FKCurrency: null, //event.fkCurrency,
            DocumentCurrencyId: null, //event.documentCurrencyId,
            CauseLogicType: null, //event.causeLogicType,
            ExchangeValue: null, //event.exchangeValue,
            ExchangeValueForVat: null //event.exchangeValueForVat
        };
    }

    private onDocumentCreated(event : IDocumentCreated) {
        let vatRegister = {
            IdRegistroIVA: event.fkRegister,
            NomeRegistroIVA: "Unknown"
        } as unknown as IVatRegister;
        
        let parent = this.createVatRegisterDataSourceModel(vatRegister);
        let model = this.createDocumentModel(vatRegister, this.createDocumentForListFromEvent(event));

        super.notifyModelCreated(parent, model);
    }

    private onDocumentChanged(event : IDocumentChanged) {
        let vatRegister = {
            IdRegistroIVA: event.fkRegister,
            NomeRegistroIVA: "Unknown"
        } as unknown as IVatRegister;
        
        let parent = this.createVatRegisterDataSourceModel(vatRegister);
        let model = this.createDocumentModel(vatRegister, this.createDocumentForListFromEvent(event));

        super.notifyModelChanged(parent, model);
    }

    private onDocumentDeleted(event : IDocumentDeleted) {
        let vatRegister = {
            IdRegistroIVA: event.fkRegister,
            NomeRegistroIVA: "Unknown"
        } as unknown as IVatRegister;
        
        let parent = this.createVatRegisterDataSourceModel(vatRegister);
        let model = this.createDocumentModel(vatRegister, this.createDocumentForListFromEvent(event));

        super.notifyModelDeleted(parent, model);
    }

    public isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return !currentModel;
    }

    public setStartDate(date: Date): void {
        this.startDate = date;
    }

    public setEndDate(date: Date): void {
        this.endDate = date;
    }

    public setSearchOnDocumentsContent(value: boolean): void {
        this.searchOnDocumentsContent = value;
    }

    public getTitle(currentModel: IDataSourceModel<number, IRegistersGroup>): string {
        return !currentModel ? TextResources.Invoices.DocumentsMenuTitle : currentModel.title;
    }
    
    public async getData(currentModel: IDataSourceModel<number|string, IRegistersGroup|IVatRegister>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number|string, IRegistersGroup|IVatRegister|IDocumentForList>[]> {
        if (!currentModel) {
            if (skip != 0)
                return [];

            return await this.getRegistersGroups();
        }

        let group = currentModel as IRegistersGroupDataSourceModel;
        if (group.isRegistersGroup && !textFilter) {
            if (skip != 0)
                return [];

            return this.getRegistersDataSourceModels(group.model);
        }

        let selectedModel = currentModel as IRegisterForDocumentsMenuDataSourceModel;

        const request: IGetDocumentsForListRequest = {
            registerId: selectedModel.id as number,
            documentTypeId: selectedModel.model.TipoDocumento,
            textFilter: textFilter,
            includeWithoutJobOrder: true,
            jobOrderId: null,
            customerId: null,
            startDate: this.startDate,
            endDate: this.endDate,
            searchOnDocumentsContent: this.searchOnDocumentsContent,
            skip: group.isRegistersGroup ? 0 : skip,
            count: group.isRegistersGroup ? 10000000 : count
        };

        let documents = await this.documentsService.GetDocumentsForList(request);
        
        if (!group.isRegistersGroup) {
            let register = currentModel as IRegisterForDocumentsMenuDataSourceModel;
            return this.createDocumentsModels(documents, register.model);
        }
        else {
            if (skip > 0)
                return [];

            let documentsModels = [];

            let vatRegisters = group.model.vatRegisters;
            for (let vatRegister of vatRegisters) {
                let filterdByRegisterDocuments = documents.filter(d => d.FKRegister === vatRegister.IdRegistroIVA);
                documentsModels = documentsModels.concat(this.createDocumentsModels(filterdByRegisterDocuments, vatRegister));
            }

            return documentsModels;
        }
    }
    
    public async getById(currentModel: IDataSourceModel<number|string, IRegistersGroup|IVatRegister>, ids: (number|string)[]): Promise<IDataSourceModel<number|string, IRegistersGroup|IVatRegister|IDocumentForList>[]> {
        if (!currentModel) {
            let groupsIds = ids as string[];
            return (await this.getRegistersGroups()).filter(g => groupsIds.indexOf(g.id) >= 0);
        }
        
        let group = currentModel as IRegistersGroupDataSourceModel;
        if (group.isRegistersGroup)
            return this.getRegistersDataSourceModels(group.model).filter((p) => ids.indexOf(p.id) >= 0);

        let documents = await this.documentsService.GetDocumentsForListByIds(ids as number[]);
        let register = currentModel as IRegisterForDocumentsMenuDataSourceModel;
        return this.createDocumentsModels(documents, register.model);
    }

    private createDocumentsModels(documents: IDocumentForList[], vatRegister: IVatRegister): IDocumentForMenuDataSourceModel[] {
        return documents.map(this.createDocumentModel.bind(this, vatRegister));
    }

    private createDocumentModel(vatRegister: IVatRegister, d : IDocumentForList) : IDocumentForMenuDataSourceModel {
        return {
            id: d.Id,
            isGroup: false,
            isLeaf: true,
            title: d.CustomerName,
            subTitle: d.NumberPrintChoice == DocumentNumberPrintChoices.OnlyProtocolNumber ? d.Number : d.FullVersionRevisionNumber,
            model: d,
            icon: this.getIconForDocument(d),
            registerId: d.FKRegister,
            entityType: d.EntityType,
            documentDate: d.Date,
            totalInDocumentCurrency: d.TotalInDocumentCurrency * (d.CreditNote ? -1 : 1),
            currencySymbol: d.Currency,
            jobOrderName: d.JobOrderName,
            isApproved: d.IsApproved,
            partialInvoice: vatRegister.ForPartialInvoices,
            creditNote: d.CreditNote,
            downPayment: d.DownPayment,
            hasWithholdingTax: d.HasWithholdingTax,
            schedulesState: d.SchedulesStatus,
            stateIcon: this.getStateForDocument(d.EntityType, d.FKEStimateState),
            blocked: d.Blocked,

            showStateIcon: d.EntityType === ProlifeSdk.EstimateEntityTypeCode || d.EntityType === ProlifeSdk.SupplierOrderEntityTypeCode || d.EntityType === ProlifeSdk.RequestForQuotationEntityTypeCode,
            showBillingStateBadge: d.DownPayment,

            billingStateBadge: this.createBillingStateBadge(d),

            closingStatusIndicator: new DocumentClosingStatusIndicatorUI({
                documentId: d.Id,
                documentType: d.EntityType,
                protocolId: d.FKRegister,
                documentState: d.FKEStimateState,
                documentClosingState: this.getDocumentClosingStatus(d),
                documentCauseLogicType: d.CauseLogicType,

                className: "btn-lg documents-menu-icon",
                content: () => <i class={"fa" + (d.Blocked ? ' fa-lock' : ' fa-file-text')}></i>
            })
        };
    }
    
    private getDocumentClosingStatus(document: IDocumentForList): number {
        return [ProlifeSdk.InvoiceEntityTypeCode, ProlifeSdk.AccompanyingInvoiceEntityTypeCode].indexOf(document.EntityType) >= 0 && !document.ForPartialInvoicesRegister ? document.SchedulesStatus : document.ClosureStatus;
    }

    private getRegistersDataSourceModels(model: IRegistersGroup): IRegisterForDocumentsMenuDataSourceModel[] {
        return model.vatRegisters.map(this.createVatRegisterDataSourceModel, this);
    }

    private async getRegistersGroups(): Promise<IRegistersGroupDataSourceModel[]> {
        if (this.registersGroupsCache.length === 0)
            this.registersGroupsCache = await this.createProtocolsGroups();

        return this.registersGroupsCache;
    }

    private createBillingStateBadge(document: IDocumentForList): INavigationMenuComponentBadge {
        let badgeInfo = this.getBillingStateBadgeInfo(document);

        if (!badgeInfo)
            return null;

        return {
            text: badgeInfo.label,
            cssClass: badgeInfo.cssClass,
            title: badgeInfo.title
        }
    }

    private getBillingStateBadgeInfo(document: IDocumentForList): IBillingStateInfo {
        if (document.DownPayment)
            return this.billingStates.get(document.ClosureStatus + 1);

        return null;
    }
    
    private getIconForDocument(document: IDocumentForList): IDataSourceIcon {
        let closureStatusColors : IClosureStatusColors = { background: "transparent", foreground: "#fff" };
        let logicalState = this.estimateStates.getEstimateStateById(document.FKEStimateState)?.Stato ?? DocumentLogicalStatus.Draft;
        if(logicalState != DocumentLogicalStatus.Rejected) {
            closureStatusColors = this.closureStatusColors.get(document.ClosureStatus) ?? { background: "transparent", foreground: "#fff" };
        }

        return {
            icon: document.Blocked ? "fa fa-lock" : "fa fa-file-text",
            foreground: closureStatusColors.foreground,
            background: closureStatusColors.background
        };
    }
    
    private getStateForDocument(documentType: string, estimateStateId: number): IStateIconInfo {
        if (documentType === ProlifeSdk.EstimateEntityTypeCode || documentType === ProlifeSdk.SupplierOrderEntityTypeCode || documentType === ProlifeSdk.RequestForQuotationEntityTypeCode) {
            let logicalState = this.estimateStates.getEstimateStateById(estimateStateId)?.Stato ?? DocumentLogicalStatus.Draft;
            let stateIconInfo = this.stateIconsInfo.get(logicalState);

            return {
                icon: stateIconInfo.icon,
                class: stateIconInfo.class
            };
        }

        return null;
    }

    private createVatRegisterDataSourceModel(register: IVatRegister): IRegisterForDocumentsMenuDataSourceModel {
        return {
            id: register.IdRegistroIVA,
            isGroup: false,
            isLeaf: false,
            title: register.NomeRegistroIVA,
            icon: {
                icon: "fa fa-folder",
                background: "#428bca",
                foreground: "#fff"
            },
            model: register,
            isVatRegister: true
        };
    }

    private async createProtocolsGroups(): Promise<IRegistersGroupDataSourceModel[]> {
        let registersGroups = await this.documentsService.GetVatRegistersGroups();
        let groups: IRegistersGroupDataSourceModel[] = [];
        
        for (let registersGroup of registersGroups) {
            let group: IRegistersGroupDataSourceModel = {
                id: registersGroup.GroupLabel,
                isGroup: true,
                isLeaf: false,
                title: registersGroup.GroupLabel,
                isRegistersGroup: true,
                collapsed: false,
                model: {
                    label: registersGroup.GroupLabel,
                    vatRegisters: registersGroup.VatRegisters
                }
            };

            groups.push(group);
        }

        return groups;
    }
    
    private setupStateIconsInfo(): void {
        this.stateIconsInfo = new Map();

        this.stateIconsInfo.set(0, { icon: "glyphicon glyphicon-time", class: "font-yellow" });
        this.stateIconsInfo.set(1, { icon: "glyphicon glyphicon-ok", class: "font-green" });
        this.stateIconsInfo.set(2, { icon: "glyphicon glyphicon-remove", class: "font-red" });
    }

    private setupBillingStates(): void {
        this.billingStates = new Map();

        this.billingStates.set(0, { label: TextResources.Invoices.NotManagedBillingStateLabel, title: TextResources.Invoices.NotManagedBillingStateTitle, cssClass: "badge-default" });
        this.billingStates.set(1, { label: TextResources.Invoices.NotBilledBillingStateLabel, title: TextResources.Invoices.NotBilledBillingStateTitle, cssClass: "badge-danger" });
        this.billingStates.set(2, { label: TextResources.Invoices.PartiallyBilledBillingStateLabel, title: TextResources.Invoices.PartiallyBilledBillingStateTitle, cssClass: "badge-warning" });
        this.billingStates.set(3, { label: TextResources.Invoices.BilledBillingStateLabel, title: TextResources.Invoices.BilledBillingStateTitle, cssClass: "badge-success" });
    }

    private setupClosureStatusColors(): void {
        this.closureStatusColors = new Map();

        this.closureStatusColors.set(0, { background: "#f3565d", foreground: "#fff" });
        this.closureStatusColors.set(1, { background: "#ecbc29", foreground: "#fff" });
        this.closureStatusColors.set(2, { background: "#45b6af", foreground: "#fff" });
    }
}