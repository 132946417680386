import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { EstimateEvent } from "../EstimateEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class EstimateEventDetailsEdit extends DocumentEventDetailsEdit {
    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver, event: EstimateEvent) {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "f-icon-doc-text";
        this.title = ProlifeSdk.TextResources.Invoices.Quote;
        this.setValues(event);
    }

    public setValues(item: EstimateEvent): void {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent {
        const event: ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.InvoiceEventType_Estimate;
        return event;
    }
}
