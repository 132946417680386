import { ResponseError } from "../../../../Core/response/ResponseBase";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class SaveProtocolError extends Error {
    public get errors(): ResponseError[] {
        return this._errors;
    }

    constructor(private _errors: ResponseError[]) {
        super(TextResources.Invoices.SaveProtocolError);
    }
}
