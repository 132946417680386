import * as ko from "knockout";
export class Droppable
{
    init(element: any, valueAccessor: () => any): void
    {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var dragOptions = options["dropOptions"];
        $(element).droppable(dragOptions);
    }
}

ko.bindingHandlers['droppable'] = new Droppable();

