import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceModel, IDataSourceView } from "./IDataSource";
import { IJobOrderTypesSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export class JobOrderTypesDataSource implements IDataSource {
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesSettingsManager: IJobOrderTypesSettingsManager;

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.JobOrder.OrderTypes;
    }

    isGroupedData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string
    ): boolean {
        return false;
    }

    areEqual(
        a: IDataSourceModel<string | number, any, string | number, any>,
        b: IDataSourceModel<string | number, any, string | number, any>
    ): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    async getData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        return this.jobOrderTypesSettingsManager
            .getJobOrderTypes()
            .filter((t) => TextFiltersUtilities.contains(t.Description, textFilter))
            .slice(skip, skip + count)
            .map(this.createModelFor, this);
    }

    async getById(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        ids: (string | number)[]
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        return this.jobOrderTypesSettingsManager
            .getJobOrderTypes()
            .filter((t) => ids.indexOf(t.Id) != -1)
            .map(this.createModelFor, this);
    }

    setView(view: IDataSourceView): void {}

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    private createModelFor(type: IJobOrderType): IDataSourceModel {
        return {
            id: type.Id,
            title: type.Description,
            isGroup: false,
            isLeaf: true,
            icon: {
                icon: type.Icon,
                background: type.Background,
                foreground: type.Foreground,
            },
            model: type,
        };
    }
}
