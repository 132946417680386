import * as ko from "knockout";
import { MergedReportComponentConstructor, ReportComponent, ReportComponentConstructor, ReportComponentProps } from "./ReportComponent";
import { ReportComponentProvider } from "./ReportComponentProvider";
import { ReportComponentWithPositionProps } from "./ReportComponentWithPosition";

export interface ReportComponentWithChildren {
    children: ko.ObservableArray<ReportComponent>;
}

export type ReportComponentWithChildrenProps = { children?: (ReportComponentProps | (ReportComponentWithPositionProps & ReportComponentProps))[] };

export function HasChildren<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithChildren, ReportComponentWithChildrenProps> {
    return class ReportComponentWithChildren extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "Children"];

            this.children.subscribe((changes) => {
                for(const change of changes) {
                    if(change.status === "added") {
                        const newChild = change.value;
                        if(!!newChild.parent.peek())
                            throw "The child already has a parent";
                        newChild.internalParent(this as unknown as ReportComponent & ReportComponentWithChildren);
                    } else if(change.status === "deleted") {
                        const newChild = change.value;
                        if(!newChild.parent.peek())
                            throw "The child has no parent";
                        newChild.internalParent(undefined);
                    }
                }
            }, this, "arrayChange");

            const { children } = args[0] as ReportComponentWithChildrenProps;
            if(children) {
                for(const child of children) {
                    const childComponent = ReportComponentProvider.createComponent(child);
                    this.children.push(childComponent);
                }
            }
        }

        getChildrenNames() {
            return [...super.getChildrenNames(), ...this.children().reduce((all, c) => all.concat(c.getChildrenNames()), [])];
        }

        getData() {
            const data = super.getData();

            return {
                ...data,
                children: this.children().map(c => c.getData())
            }
        }

        children: ko.ObservableArray<ReportComponent> = ko.observableArray();
    }
}

export function CanHaveChildren(component: ReportComponent) : component is ReportComponent & ReportComponentWithChildren {
    if(component !== null && component !== undefined && component.hasOwnProperty("children"))
        return true;
    return false;
}