import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 17:16
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { NavigationProvider } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { RootNavigationProvider } from "./providers/RootNavigationProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IAgendasNavigationMenu, IAgendaNavigationMenuObserver } from "../../../interfaces/IAgendaNavigationMenu";
import { IFullCalendarEventsDragObserver, IFullCalendarViewObject, IFullCalendar } from "../../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IEventsSearchObserver } from "../../../interfaces/IAgendaEvent";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { IEventsService, IAgendaEvent } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IAgenda } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";

export class AgendasMenu extends NavigationMenu implements IAgendasNavigationMenu, IFullCalendarEventsDragObserver {
    public ShowDeletedElements: ko.Observable<boolean> = ko.observable();
    public DeletedElementsButtonEnabled: ko.Observable<boolean> = ko.observable();

    public searchedEventsEditor: IEventsSearchObserver = null;

    private lastSelectedProvider: INavigationMenuProvider = null;

    private observers: IAgendaNavigationMenuObserver[] = [];
    private dialogsService: IDialogsService;
    private authorizationsService: IAuthorizationService;
    private eventsService: IEventsService;

    constructor(private serviceLocator: IServiceLocator) {
        super(serviceLocator, new RootNavigationProvider(serviceLocator));
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.authorizationsService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);

        this.templateUrl = "agenda/templates/navigation";
        this.templateName = "agendas-navigator";
        this.setDefaultMenuLocation();

        this.Title = ko.computed(() => {
            return (<any>this.CurrentView()).Title();
        });

        this.DeletedElementsButtonEnabled(this.authorizationsService.isAuthorized("Agenda_ShowDeletedAgendas"));
        this.ShowDeletedElements(false);
        this.ShowDeletedElements.subscribe(() => {
            if (!this.CurrentView())
                return;
            this.CurrentView().refreshItems();
        });
    }

    public SelectAgenda(agendaId : number) {
        (<RootNavigationProvider>this.RootMenuProvider).SelectProviderById(agendaId);
    }

    AddEventsMovingObserver(observer: IAgendaNavigationMenuObserver) {
        this.observers.push(observer);
    }

    NotifyEventDroppedOnAgenda(event: IAgendaEvent, agendaId: number) {
        this.observers.forEach((o: IAgendaNavigationMenuObserver) => {
            o.OnEventDroppedOnAgenda(event, agendaId);
        });
    }

    CreateNewAgenda(agenda: IAgenda): void {
        (<any>this.RootMenuProvider).insertAgenda(agenda);
    }

    UpdateAgenda(agenda: IAgenda): void {
        (<any>this.RootMenuProvider).updateAgenda(agenda);
    }

    RemoveAgenda(agendaId: number, folderId: number): void {
        (<any>this.RootMenuProvider).removeAgenda(agendaId, folderId);
    }

    OnEventDragStart(event: IAgendaEvent, jsEvent: any, view: IFullCalendarViewObject, sourceCalendar: IFullCalendar): void {}

    OnEventDragStop(event: IAgendaEvent, jsEvent: any, view: IFullCalendarViewObject, sourceCalendar: IFullCalendar): void {
        var provider: NavigationProvider = <NavigationProvider> this.MenuProvidersStack()[this.MenuProvidersStack().length - 1];

        var items = <any[]> provider.Items();

        for (var i: number = 0; i < items.length; i++) {
            if (!items[i].IsEventOverMe)
                continue;

            if (items[i].IsEventOverMe(jsEvent.clientX, jsEvent.clientY)) {
                var originalAgenda: number = event.AgendaId;
                event.AgendaId = items[i].Id;
                this.eventsService.ui.GetEventEditingDialog(event.id, event.AgendaId)
                    .then((modifiedEvent: IAgendaEvent) => {
                        if (!modifiedEvent) {
                            event.AgendaId = originalAgenda;
                            return;
                        }

                        if (modifiedEvent.AgendaId != originalAgenda)
                            sourceCalendar.RemoveEvent(event.id);

                        this.NotifyEventDroppedOnAgenda(modifiedEvent, items[i].Id);
                    });
                break;
            }
        }
    }

    SetSearchedEventsEditor(editor: IEventsSearchObserver): void {
        this.searchedEventsEditor = editor;
    }

    UnselectAgendaForFilter(): void {
        this.lastSelectedProvider = this.selectedProviders[this.selectedProviders.length - 1];
        this.clearSelection(true);
    }

    RestoreAgendaSelectionForFilter(): void {
        if (!this.lastSelectedProvider)
            return;
        this.selectProvider(this.lastSelectedProvider);
    }

    private setDefaultMenuLocation()
    {
        this.MenuProvidersStack([this.RootMenuProvider]);
    }
}