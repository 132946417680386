import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/03/2019
 * Time: 15:36
 * To change this template use File | Settings | File Templates.
 */
import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk";
import { DocumentsJournalExtendedRowViewModel } from "./DocumentsJournalExtendedRow";
import { Document } from "../../../Invoices/invoices/documents/Document";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDocumentsJournalDialogResult, IDocumentJournalExtendedRow } from "../../interfaces/prolife-sdk/IDocumentsJournalRow";

export class DocumentsJournalDialog implements IDialog {
    public templateName: string = "documents-journal-dialog";
    public templateUrl: string = "prolifesdk/templates/documents/dialogs";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.DocumentsJournalDialogTitle;

    public modal: { close: (result?: any) => void; };

    public JournalRows: ko.ObservableArray<DocumentsJournalExtendedRowViewModel> = ko.observableArray([]);
    public Date: ko.Observable<Date> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    private result: IDocumentsJournalDialogResult;

    constructor(document: Document) {
        this.title = String.format(this.title, moment(document.Date()).format("L"));

        this.Date(document.Date());

        this.documentsService.loadDocumentsJournal(document.Date())
            .then((journalOfTheDay: IDocumentJournalExtendedRow[]) => {
                var isNew: boolean = true;

                var rows = journalOfTheDay.map((r) => {
                    var vm = new DocumentsJournalExtendedRowViewModel(r);

                    if (r.DocumentId == document.DocumentId()) {
                        vm.Draggable(true);
                        isNew = false;
                    }
                    return vm;
                });

                if (isNew) {
                    var row = {
                        Id: "",
                        DocumentId: document.DocumentId(),
                        DocumentType: document.DocumentType(),
                        DocumentDate: document.Date(),
                        DocumentNumber: document.Number(),
                        ProtocolId: document.FKRegister(),
                        ProtocolProgressiveNumber: 0,
                        FiscalYearId: 0,
                        CustomerId: document.Recipient.Id(),
                        CustomerName: document.Recipient.FormattedName(),
                        DocumentLabel: document.RegisterCache.NuovoLabel,
                        ProtocolName: document.RegisterCache.NomeRegistroIVA
                    };

                    var vm = new DocumentsJournalExtendedRowViewModel(row);
                    rows.push(vm);
                    vm.Draggable(true);
                }

                this.JournalRows(rows);
            });

    }

    public show(): Promise<IDocumentsJournalDialogResult> {
        return this.dialogsService.ShowModal<IDocumentsJournalDialogResult>(this, null, { noPrompt: true });
    }

    public close(): void {
        this.modal.close(this.result);
    }

    public action(): void {
        this.modal.close(this.result);
    }

    public OnRowMoved(beforeId, movedId, afterId): void {
        if (!this.result)
            this.result = {
                BeforeId: "",
                MovedId: "",
                AfterId: ""
            };

        this.result.BeforeId = beforeId;
        this.result.MovedId = movedId;
        this.result.AfterId = afterId;
    }
}