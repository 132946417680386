import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class DocumentForReports
{
    register : IVatRegister;
    document : any;
    selected : ko.Observable<boolean> = ko.observable(false);
    freezed : ko.Observable<boolean> = ko.observable(false);
    IsCreditNote : ko.Computed<boolean>;

    public TotalWithoutTax : number;

    constructor(document, register)
    {
        var self = this;
        this.document = document;
        this.register = register;
        this.freezed(document.Congelato == 1);
        this.IsCreditNote = ko.computed(() => self.document.NotaCredito == 1);
        this.TotalWithoutTax = document ? this.formatPrice((document.TotaleImponibile || 0) + (document.TotaleAltro || 0)) : 0;
    }

    GetDdtBillingStatusName(value) : string
    {
        var name : string = ProlifeSdk.TextResources.Invoices.Unprocessed;
        name = value == 1 ? ProlifeSdk.TextResources.Invoices.Open : name;
        name = value == 2 ? ProlifeSdk.TextResources.Invoices.PartiallyInvoiced : name;
        name = value == 3 ? ProlifeSdk.TextResources.Invoices.Invoiced : name;
        return name;
    }

    formatPrice(value) : number
    {
        return this.IsCreditNote() ? -1 * value : value;
    }

    getVatRegister() : string
    {
        return this.register != null ? this.register.NomeRegistroIVA : "";
    }

    openDocumentDetail()
    {
        //To be implemented...
    }

    changeSelection()
    {
        this.selected(!this.selected());
    }
}