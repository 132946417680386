import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ResourceManagerArea } from "./ResourceManagerArea";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IResourcesGroupsSettingsManager, IResourcesGroup, IHoursApprovalEnabledResource } from "../../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
import { Deferred } from "../../../../Core/Deferred";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { If } from "../../../../Components/IfIfNotWith";
import { DetectChanges, DetectClassChanges } from "../../../../Core/ChangeDetection";
import { TextInput } from "../../../../Components/TextInput";
import { ResourcesAndGroupsDataSource } from "../../../../DataSources/ResourcesAndGroupsDataSource";
import { ResourcesMultipleSelector, ResourcesSelector } from "../../../../Components/ResourcesSelector";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Layout } from "../../../../Components/Layouts";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { CheckBox } from "../../../../Components/Checkbox";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";

const { classes } = jss.createStyleSheet({
    resourcesGroupArea: {
        "& .edit-mask-wrapper": {
            padding: "0px"
        },

        "& .portlet": {
            "& .actions": {
                "& .btn": {
                    "&:not(:last-child)": {
                        marginRight: "5px"
                    }
                }
            },

            "& .portlet-body": {
                padding: "0",

                "& .hours-approval-state": {
                    display: "inline-block",
                    width: "22px",
                    textAlign: "center",
                    height: "22px",
                    
                    "&.approval": {
                        backgroundColor: "#428bca",
                        color: "white"
                    },

                    "&.approval-view": {
                        backgroundColor: "#bbb",
                        color: "white"
                    },
                }
            }
        },

        "& .padding-left-15": {
            paddingLeft: "15px"
        },

        "& .padding-right-15": {
            paddingRight: "15px"
        },

        "& .padding-15": {
            padding: "15px"
        },

        "& .bordered": {
            border: "1px solid #ddd"
        }
    },

    groupEditMask: {
        "& .unavailable-resource": {
            textDecoration: "line-through"
        },

        "& .actions": {
            "& .btn": {
                "&:not(:last-child)": {
                    marginRight: "5px"
                }
            }
        },

        "& .hours-approval-resources": {
            "& .actions": {
                width: "35px",
                textAlign: "center",
    
                "& .btn": {
                    margin: "0px"
                }
            },
    
            "& .can-approve": {
                width: "200px"
            },
    
            "& td.can-approve": {
                padding: "0px 5px !important"
            }
        }
    }
}).attach();

export class ResourcesGroups extends ResourceManagerArea {

    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private groupsSettingsService : IResourcesGroupsSettingsManager;

    private list : ko.ObservableArray<GroupForList> = ko.observableArray([]);
    public filteredList : ko.Computed<GroupForList[]>;
    public selected : ko.Observable<IResourcesGroup> = ko.observable(null);
    public textFilter : ko.Observable<string> = ko.observable("");
    public showDeleted : ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    public canShowDeleted: ko.Observable<boolean> = ko.observable(false);

    private _hasChanges = false;

    constructor(mainLayout : IResourcesManagerViewModel) {
        super(ProlifeSdk.TextResources.ResourcesManager.ResourcesGroupsAreaTitle, "fa-users", mainLayout, "ResourcesManager/Templates/Areas/ResourcesGroups", "main-panel", ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Groups);

        const showDeletedRight = this.authorizationsService.isAuthorized("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager");
        this.canShowDeleted(showDeletedRight);

        this.filteredList = ko.computed(() => {
            return this.list()
                .filter((c : GroupForList) => { return c.model.Name.toUpperCase().indexOf(this.textFilter().toUpperCase()) > -1; })
                .filter((c : GroupForList) => { return !c.model.Deleted || this.showDeleted(); });
        });

        this.createNew();
    }

    public hasChanges(): boolean {
        return this._hasChanges;
    }

    public updateElementInLists(model : IResourcesGroup) {
        const list = this.list();
        let index = -1;
        for(let i = 0; i < list.length; i++) {
            if (list[i].model.Id === model.Id) {
                index = i;
                break;
            }
        }

        list.splice(index, 1, new GroupForList(model, this));
        this.list(list);
    }

    public edit(model : IResourcesGroup){
        this.selected(model);
    }

    public createNew() {
        const emptyModel : IResourcesGroup = {
            Id: -1,
            Name: "",
            Description: "",
            WorkedHoursApprovalRole: "",
            Deleted: false,
            BuiltIn: false,
            ResourcesIds: [],
            HoursApprovalResources: [],
            HoursApprovalState: 0
        };
        this.edit(emptyModel);
    }

    public show() {
        super.show();

        this.groupsSettingsService.load().then(() => {
            this.list(this.groupsSettingsService.GetGroups()
                .map(c => new GroupForList(c, this)));
        });
    }

    render() {
        const resourcesGroupArea = this;
        const groupForList: GroupForList = null;
        const groupModel: IResourcesGroup = null;

        return ComponentUtils.bindTo((
            <div className={"row  flex-container flex-full-height " + classes.resourcesGroupArea}>
                <div className="col-md-4">
                    <div className="portlet box red">
                        <div className="portlet-title">
                            <div className="caption search">
                                <div className="input-icon input-inline input-icon-small input-transparent">
                                    <i className="fa fa-search"></i>
                                    <input placeholder={TextResources.ResourcesManager.GroupsSearch} type="text" className="form-control input-sm" data-bind={{ value: resourcesGroupArea.textFilter, valueUpdate :  'afterkeydown' }} />
                                </div>
                            </div>
                            <div className="actions">
                                <ko-bind data-bind={{ if: resourcesGroupArea.canShowDeleted }}>
                                <a href="#" className="btn default btn-sm" data-bind={{ toggle: resourcesGroupArea.showDeleted, css : { 'default' : !resourcesGroupArea.showDeleted(), 'btn-primary' : resourcesGroupArea.showDeleted } }}>
                                    <i className="fa fa-times"></i>&nbsp;{TextResources.ResourcesManager.DeletedGroups}
                                </a>
                                </ko-bind>
                                <a href="#" className="btn default btn-sm" data-bind={{ click: resourcesGroupArea.createNew.bind(resourcesGroupArea) }}>
                                    <i className="fa fa-plus"></i>&nbsp;{TextResources.ResourcesManager.NewGroup}
                                </a>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div data-bind={{ slimScroll: '700px' }}>
                                        <div className="list-group" data-bind={{ foreach: { data: resourcesGroupArea.filteredList, as: 'groupForList' } }}>
                                            <a href="#" className="list-group-item" data-bind={{ click: groupForList.showDetails, css : { 'active' : groupForList.isSelected } }}>
                                                <ko-bind data-bind={{ with : groupForList.model, as: 'groupModel' }}>
                                                    <h4>
                                                        <span className="hours-approval-state" data-bind={{ css: { "approval-view": groupModel.HoursApprovalState === 1, "approval": groupModel.HoursApprovalState === 2 } }}>
                                                            <i className="fa fa-legal" data-bind={{ visible: groupModel.HoursApprovalState !== 0 }}></i>
                                                        </span>&nbsp;
                                                        <span data-bind={{ text: groupModel.Name, style : { 'text-decoration' : groupModel.Deleted ? 'line-through' : 'none', 'color' :  groupModel.Deleted ? 'red' : 'auto', 'font-weight' :  groupModel.Deleted ? 'bold' : 'auto' } }}></span>
                                                    </h4>
                                                </ko-bind>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 edit-mask-wrapper">
                    <If condition={this.selected}>
                        {() => <GroupEditMask model={this.selected()} parent={this} onDataChange={() => this._hasChanges = true} />}
                    </If>
                </div>
            </div>
        ), this, "resourcesGroupArea");
    }
}

type GroupEditMaskProps = {
    model : IResourcesGroup;
    parent : ResourcesGroups;

    onDataChange?: () => void;
}

@DetectClassChanges
export class GroupEditMask {
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private groupsSettingsService : IResourcesGroupsSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    public isChanged: ko.Observable<number> = ko.observable(0);
    private isChangedSubscription: ko.Subscription;
    
    private isLoading = false;

    @DetectChanges
    public originalName : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public name : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public description : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public groupMembersIds : ko.ObservableArray<string> = ko.observableArray([]);
    public isNew : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public deleted : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public builtIn : ko.Observable<boolean> = ko.observable(false);

    @DetectChanges
    public WorkedHoursApprovalRole: ko.Observable<string> = ko.observable();
    @DetectChanges
    public HoursApprovalEnabledResources: ko.ObservableArray<HoursApprovalResource> = ko.observableArray([]);

    private HoursApprovalResourceId: ko.Observable<string> = ko.observable();

    get model() {
        return this.props.model;
    }

    constructor(private props: GroupEditMaskProps) {
        this.updateData(this.props.model);
    }

    componentDidMount() {
        this.isChangedSubscription = this.isChanged.subscribe(() => {
            if (this.isLoading)
                return;

            this.props.onDataChange && this.props.onDataChange();
        });
    }

    componentWillUnmount() {
        this.dispose();
    }

    private updateData(model : IResourcesGroup) {
        this.isLoading = true;

        this.props.model = model;
        this.name(model.Name);
        this.description(model.Description);
        this.deleted(model.Deleted);
        this.builtIn(model.BuiltIn);
        this.groupMembersIds(model.ResourcesIds.map(r => r + "|" + ResourcesAndGroupsDataSource.HumanResourcesType));
        this.WorkedHoursApprovalRole(model.WorkedHoursApprovalRole);
        this.HoursApprovalEnabledResources(model.HoursApprovalResources.map(r => new HoursApprovalResource(r)));
        
        this.isNew(model.Id <= 0);
        this.originalName(this.isNew() ? ProlifeSdk.TextResources.ResourcesManager.ResourceGroupNewName : model.Name);

        setTimeout(() => {
            this.isChanged(0);
            this.isLoading = false;
        }, 200);
    }

    public async save(): Promise<void> {
        if (this.name().length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupInvalidName);
            return;
        }

        const model = Object.assign({}, this.props.model) as IResourcesGroup;

        model.Name = this.name();
        model.Description = this.description();
        model.ResourcesIds = this.groupMembersIds().map((s) => parseInt(s.split("|")[0]));
        model.WorkedHoursApprovalRole = this.WorkedHoursApprovalRole();
        model.HoursApprovalResources = this.HoursApprovalEnabledResources().map(r => r.gtData());

        try {
            const group = await this.groupsSettingsService.CreateOrUpdate(model);
            this.updateData(group);
            this.infoToastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupUpdated);
            this.props.parent.updateElementInLists(group);
        } catch(e) {
            console.log(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupUpdateError);
        }
    }

    public async restoreItem(): Promise<void> {
        const model = Object.assign({}, this.props.model) as IResourcesGroup;
        model.Deleted = false;

        try {
            const group: IResourcesGroup = await this.groupsSettingsService.CreateOrUpdate(model);
            this.updateData(group);
            this.infoToastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupRestored);
            this.props.parent.updateElementInLists(group);
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupRestoredError);
        }
    }

    public async deleteItem(): Promise<void> {

        const confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.ResourcesManager.ResourcesGroupsDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesGroupsDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesGroupsDeleteWarningConfirm);

        if(!confirm)
            return;

        try {
            await this.groupsSettingsService.Remove(this.props.model.Id);
            this.infoToastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupDeleted);
            this.props.model.Deleted = true;
            this.props.parent.updateElementInLists(this.props.model);
            this.props.parent.createNew();
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourceGroupDeleteError);
        }
    }

    public dispose() {
        this.isChangedSubscription.dispose();
        this.isChangedSubscription = null;
    }

    private createHoursApprovalResourceDataSourceModel(hoursApprovalResource: HoursApprovalResource): IDataSourceModel<string, HoursApprovalResource> {
        return {
            id: hoursApprovalResource.Id,
            title: hoursApprovalResource.Label(),
            isGroup: false,
            isLeaf: true,
            model: hoursApprovalResource
        };
    }

    private onResourceSelect(model: IDataSourceModel<string, string|IHumanResource|IResourcesGroup>): void {
        if (typeof model === "string") {
            this.HoursApprovalResourceId(null);
            return;
        }

        const resourceModel = model as IDataSourceModel<string, IHumanResource>;
        let resourceType = "";
        let resourceId: number;

        if (resourceModel && resourceModel.model.Resource) {
            resourceType = ResourcesAndGroupsDataSource.HumanResourcesType;
            resourceId = resourceModel.model.Resource.Id;
        }
        else {
            resourceType = ResourcesAndGroupsDataSource.ResourcesGroupsType;
            resourceId = (model as IDataSourceModel<string, IResourcesGroup>).model.Id;
        }

        const selectedResources = this.HoursApprovalEnabledResources();
        if (selectedResources.firstOrDefault(r => r.is(resourceId, resourceType))) {
            this.infoToastService.Warning(TextResources.ResourcesManager.ApprovalResourceAlreadySelected);
        } else {
            let hoursApprovalResource: IHoursApprovalEnabledResource = null;
            hoursApprovalResource = {
                Id: 0,
                FKGroup: 0,
                ResourceTypeLabel: resourceType,
                FKResource: resourceId,
                CanApprove: true,
            };
            
            this.HoursApprovalEnabledResources.push(new HoursApprovalResource(hoursApprovalResource));
        }

        this.HoursApprovalResourceId(null);
    }

    public removeHoursApprovalResource(resource: HoursApprovalResource): void {
        this.HoursApprovalEnabledResources.remove(resource);
    }

    public render() {
        const groupEditMask = this;

        return ComponentUtils.bindTo((
            <Layout.WithHeader>
                <Layout.WithHeader.Header>
                    <h1 data-bind={{ text : groupEditMask.originalName }}></h1>
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content className={classes.groupEditMask + " form-body"} noOverflow>
                    <Layout.Grid rows={["min-content", "1fr", "min-content"]} columns={["1fr"]} noOverflow>
                        <Layout.Grid.Cell column={1} row = {1} className="group-data flex-vertical padding-left-15 padding-right-15">
                            <TextInput 
                                label={TextResources.ResourcesManager.GroupName}
                                value={groupEditMask.name}
                                readonly={groupEditMask.builtIn}
                                placeholder={TextResources.ResourcesManager.GroupNamePlaceholder}
                                selectOnFocus
                            />
                            <ResourcesMultipleSelector
                                label={TextResources.ResourcesManager.GroupMembers}
                                placeholder={TextResources.ResourcesManager.GroupMembersPlaceholder}
                                value={this.groupMembersIds}
                                allowClear
                                readOnly={this.builtIn}
                                minimumInputLength={1}
                                includeMaterialResources
                            />
                            <TextInput 
                                label={TextResources.ResourcesManager.GroupDescription}
                                value={groupEditMask.description}
                                readonly={groupEditMask.builtIn}
                                placeholder={TextResources.ResourcesManager.GroupDescriptionPlaceholder}
                                multiline
                            />
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell column={1} row={2} className="hours-approval flex-vertical" noOverflow>
                            <Layout.WithHeader noOverflow>
                                <Layout.WithHeader.Header className="flex-vertical padding-left-15 padding-right-15">
                                    <h2>{TextResources.ResourcesManager.HoursApproval}</h2>
                                    <TextInput 
                                        label={TextResources.ResourcesManager.HoursApprovalGroupRole}
                                        value={groupEditMask.WorkedHoursApprovalRole}
                                        placeholder={TextResources.ResourcesManager.HoursApprovalGroupRolePlaceholder}
                                        selectOnFocus
                                    />
                                </Layout.WithHeader.Header>
                                <Layout.WithHeader.Content className="padding-15 bordered" noOverflow>
                                    <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} noOverflow>
                                        <Layout.Grid.Cell row={1} column={1} className="flex-vertical" noOverflow>
                                            <ResourcesSelector
                                                label={TextResources.ResourcesManager.HoursApprovalAuthorizedResourcesAndGroups}
                                                placeholder={TextResources.ResourcesManager.GroupMembersPlaceholder}
                                                value={this.HoursApprovalResourceId}
                                                allowClear
                                                includeGroups={true}

                                                onSelect={this.onResourceSelect.bind(this)}
                                            />
                                        </Layout.Grid.Cell>
                                        <Layout.Grid.Cell row={2} column={1} noOverflow>
                                            <Table
                                                dataSource={{ array: this.HoursApprovalEnabledResources, factory: this.createHoursApprovalResourceDataSourceModel.bind(this) }}
                                                systemScrollable
                                                compact
                                                fixedLayout
                                                className="hours-approval-resources"
                                                >
                                                <Column title={TextResources.ResourcesManager.HoursApprovalResource}>
                                                    <ColumnBody>
                                                        {(item: ITableItem<HoursApprovalResource>) => (
                                                            <span>
                                                                <i className={ComponentUtils.classNames("fa", item.Data.model.Icon())}></i>&nbsp;<span>{item.Data.model.Label()}</span>
                                                            </span>
                                                        )}
                                                    </ColumnBody>
                                                </Column>
                                                <Column className="can-approve" title={TextResources.ResourcesManager.HoursApprovalCanApprove}>
                                                    <ColumnBody>
                                                        {(item: ITableItem<HoursApprovalResource>) => (<CheckBox checked={item.Data.model.CanApprove} />)}
                                                    </ColumnBody>
                                                </Column>
                                                <Column className="actions">
                                                    <ColumnBody>
                                                        {(item: ITableItem<HoursApprovalResource>) => (
                                                            <button type="button" className="btn btn-danger btn-xs" onClick={() => this.removeHoursApprovalResource(item.Data.model)} title={TextResources.ResourcesManager.HoursApprovalRemoveTooltip}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        )}
                                                    </ColumnBody>
                                                </Column>
                                            </Table>
                                        </Layout.Grid.Cell>
                                    </Layout.Grid>
                                </Layout.WithHeader.Content>
                            </Layout.WithHeader>
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell column={1} row={3} className="actions flex-vertical padding-15">
                            <div className="text-center">
                                <button className="btn btn-primary" data-bind={{ click : groupEditMask.save.bind(groupEditMask) }}>
                                    <i className="fa fa-floppy-o"></i>&nbsp;{TextResources.ResourcesManager.Save}
                                </button>

                                <ko-bind data-bind={{ ifnot: groupEditMask.builtIn }}>
                                <ko-bind data-bind={{ ifnot : groupEditMask.isNew }}>
                                <ko-bind data-bind={{ ifnot : groupEditMask.deleted }}>
                                <button className="btn btn-danger" data-bind={{ click : groupEditMask.deleteItem.bind(groupEditMask) }}>
                                    <i className="fa fa-trash-o"></i>&nbsp;{TextResources.ResourcesManager.Delete}
                                </button>
                                </ko-bind>
                                <ko-bind data-bind={{ if : groupEditMask.deleted }}>
                                <button className="btn btn-primary" data-bind={{ click : groupEditMask.restoreItem.bind(groupEditMask) }}>
                                    <i className="fa fa-trash-o"></i>&nbsp;{TextResources.ResourcesManager.Restore}
                                </button>
                                </ko-bind>
                                </ko-bind>
                                </ko-bind>
                            </div>
                        </Layout.Grid.Cell>
                    </Layout.Grid>
                </Layout.WithHeader.Content>
            </Layout.WithHeader>
        ), this, "groupEditMask");
    }
}

class HoursApprovalResource {
    get Id(): string {
        return this.createId(this.resource.FKResource, this.resource.ResourceTypeLabel);
    }

    public Icon: ko.Observable<string> = ko.observable("");
    public Label : ko.Observable<string> = ko.observable("");
    public CanApprove : ko.Observable<boolean> = ko.observable(true);
    public DeletedOrDisabled : ko.Observable<boolean> = ko.observable(false);

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourceSettingsManager : IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private resourcesGroupsSettingsManager : IResourcesGroupsSettingsManager;

    constructor(private resource: IHoursApprovalEnabledResource) {
        let label = "";
        if (resource.ResourceTypeLabel === ResourcesAndGroupsDataSource.HumanResourcesType) {
            const hr = this.humanResourceSettingsManager.getHumanResourceById(resource.FKResource);
            label = this.humanResourceSettingsManager.getFullName(hr);
            this.Icon("fa-user");
            this.DeletedOrDisabled(hr.Resource.Deleted || hr.Resource.Disabled);
        } else {
            const group = this.resourcesGroupsSettingsManager.GetGroupById(resource.FKResource);
            label = group?.Name ?? "";
            this.Icon("fa-users");
            this.DeletedOrDisabled(group?.Deleted || false);
        }

        this.Label(label);
        this.CanApprove(resource.CanApprove);
    }

    public is(id: number, resourceType: string): boolean {
        return this.Id === this.createId(id, resourceType);
    }

    public gtData(): IHoursApprovalEnabledResource {
        const data = Object.assign({}, this.resource) as IHoursApprovalEnabledResource;
        data.CanApprove = this.CanApprove();
        return data;
    }

    private createId(id: number, type: string): string {
        return id + "|" + type;
    }
}

export class GroupForList {

    public isSelected : ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public model : IResourcesGroup, private parent : ResourcesGroups) {
        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().Id == this.model.Id;
        });
    }

    public showDetails(){
        if (!this.parent.selected()) {
            this.parent.edit(this.model);
            return;
        }

        if (this.parent.hasChanges()) {
            this.ShowConfirmOnLostChanges()
                .then((confirm: boolean) => {
                    if (confirm)
                        this.parent.edit(this.model);
                });
            return;
        }

        this.parent.edit(this.model);
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}