import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 30/05/2019
 * Time: 16:42
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IEntityCodeGeneratorSettingsManager, IEntityCodeGeneratorConfig, IEntityCodeGeneratorConfigPart } from "../../interfaces/job-order/IEntityCodeGeneratorSettingsManager";

export interface IEntityCodeGeneratorPartType {
    Name: string;
    CreateNew: () => BasePartEditorViewModel;
}

export abstract class EntityCodeGeneratorEditingViewModel {
    Parts : ko.ObservableArray<BasePartEditorViewModel> = ko.observableArray([]);
    PartTypes : ko.ObservableArray<IEntityCodeGeneratorPartType> = ko.observableArray();
    EditingMode : ko.Observable<boolean> = ko.observable();

    public Preview : ko.Computed<string>;
    public PreviewCharacters : ko.Computed<string[]>;

    private dialogsService : IDialogsService;
    private infoToastService : IInfoToastService;

    constructor(protected serviceLocator : IServiceLocator, protected settingsManager : IEntityCodeGeneratorSettingsManager) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);

        this.Preview = ko.computed(() => {
            var preview = "";
            var parts = this.Parts();
            parts.forEach(p => preview = preview + p.Preview());
            return preview;
        });
        this.PreviewCharacters = ko.computed({
            read: () => {
                return (this.Preview() || "").split('').map(c => c == ' ' ? "&nbsp;" : c);
            },
            deferEvaluation: true
        });

        this.configurePartTypes();
        this.load();
    }

    protected abstract configurePartTypes();

    private load() : Promise<IEntityCodeGeneratorConfig> {
        return this.settingsManager.getConfiguration()
            .then((configuration : IEntityCodeGeneratorConfig) => {
                this.loadFromConfiguration(configuration);
                return configuration;
            });
    }

    private loadFromConfiguration(config : IEntityCodeGeneratorConfig) {
        if(!config) {
            this.Parts([]);
            return;
        }

        this.Parts(config.Parts.map(this.createViewModelForPart.bind(this)));
    }

    protected abstract createViewModelForPart(part : IEntityCodeGeneratorConfigPart) : BasePartEditorViewModel;

    public addNewBefore(part : BasePartEditorViewModel, newType : IEntityCodeGeneratorPartType) {
        var indexOfPart = this.Parts().indexOf(part);
        if(indexOfPart < 0)
            return;

        this.Parts.splice(indexOfPart, 0, newType.CreateNew());
    }

    public addAtEnd(newType : IEntityCodeGeneratorPartType) {
        this.Parts.push(newType.CreateNew());
    }

    public removePart(part : BasePartEditorViewModel) {
        this.Parts.remove(part);
    }

    public edit() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Core.ConfirmEditEntityCodeConfiguration,
            ProlifeSdk.TextResources.Core.ConfirmEditEntityCodeConfigurationCancel,
            ProlifeSdk.TextResources.Core.ConfirmEditEntityCodeConfigurationOk,
            (confirm : boolean) => {
                if(confirm)
                    this.EditingMode(true);
            }
        );
    }

    public save() {
        this.settingsManager.saveConfiguration(this.getData())
            .then(() => {
                this.EditingMode(false);
                this.load();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Core.ErrorSavingEntityCodeGeneratorConfig);
            });
    }

    public cancel() {
        this.EditingMode(false);
        this.load();
    }

    private getData() : IEntityCodeGeneratorConfig {
        var parts = this.Parts().map((p : BasePartEditorViewModel) => p.getData());
        return {
            Parts: parts
        };
    }
}

export class BasePartEditorViewModel {
    public IsText : ko.Observable<boolean> = ko.observable(false);
    public IsDate : ko.Observable<boolean> = ko.observable(false);
    public IsSequence : ko.Observable<boolean> = ko.observable(false);
    public IsType : ko.Observable<boolean> = ko.observable(false);
    public IsParent : ko.Observable<boolean> = ko.observable(false);
    public IsCustomer : ko.Observable<boolean> = ko.observable(false);
    public IsId : ko.Observable<boolean> = ko.observable(false);
    public Name : string;

    public Preview : ko.Computed<string>;
    public PreviewCharacters : ko.Computed<string[]>;

    constructor(name : string) {
        this.Name = name;

        this.PreviewCharacters = ko.computed({
            read: () => {
                return (this.Preview() || "").split('').map(c => c == ' ' ? "&nbsp;" : c);
            },
            deferEvaluation: true
        });
    }

    public pad(num, size) {
        var s = "0000000000000000000000000000000" + num;
        return s.substr(s.length-size);
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return null;
    }
}

export class TextPartEditorViewModel extends BasePartEditorViewModel {
    public Text : ko.Observable<string> = ko.observable();

    constructor(name : string, private part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsText(true);
        this.Text(part.Text);

        this.Preview = ko.computed(() => this.Text());
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 0,
            Text: this.Text(),
            DatePart: 0,
            Padding: 1,
            NullPlaceholder: "",
            SequenceStart: 1
        };
    }
}

export class DatePartEditorViewModel extends BasePartEditorViewModel {
    public SelectedDatePart : ko.Observable<string> = ko.observable();
    public DateParts : ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.Core.DayLabel,
        ProlifeSdk.TextResources.Core.MonthLabel,
        ProlifeSdk.TextResources.Core.YearLabel,
        ProlifeSdk.TextResources.Core.Year2DigitsLabel
    ]);

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsDate(true);
        this.SelectedDatePart(this.DateParts()[part.DatePart]);

        this.Preview = ko.computed(() => {
            if(this.SelectedDatePart() == this.DateParts()[0])
                return moment().format("DD");
            else if(this.SelectedDatePart() == this.DateParts()[1])
                return moment().format("MM");
            else if(this.SelectedDatePart() == this.DateParts()[2])
                return moment().format("YYYY");
            else
                return moment().format("YY");
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 1,
            Text: "",
            DatePart: this.DateParts().indexOf(this.SelectedDatePart()),
            Padding: 1,
            NullPlaceholder: "",
            SequenceStart: 1
        };
    }
}

export class SequencePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable(1);
    public SequenceName : ko.Observable<string> = ko.observable();
    public SequenceNames : ko.ObservableArray<string> = ko.observableArray();
    public NewSequence : ko.Observable<boolean> = ko.observable(false);
    public LastSequenceId : ko.Observable<number> = ko.observable();
    public SequenceStart : ko.Observable<number> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsSequence(true);
        this.Padding(part.Padding);
        this.SequenceStart(part.SequenceStart);

        var interceptor = ko.computed(() => {
            if(this.NewSequence())
                return;

            settingsManager.getSequenceLastValue(this.SequenceName())
                .then((value : number) => this.LastSequenceId(value));
        });

        settingsManager.getSequenceNames()
            .then((sequences : string[]) => {
                this.SequenceNames(sequences);
                this.SequenceName(part.Text);
            });

        this.Preview = ko.computed(() => {
            return this.pad(0, this.Padding());
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 2,
            Text: this.SequenceName(),
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: "",
            SequenceStart: this.SequenceStart()
        };
    }
}

export class TypePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsType(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 3,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class SecondTypePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsType(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 10,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class ParentPartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsParent(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 4,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class SecondParentPartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsParent(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 7,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class CustomerPartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsCustomer(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 5,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class IdPartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsId(true);
        this.Padding(part.Padding);

        this.Preview = ko.computed(() => {
            return this.pad(0, this.Padding());
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 6,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: "",
            SequenceStart: 1
        };
    }
}

export class ParentSequencePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsParent(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 8,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class SuperParentSequencePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsParent(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 9,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}

export class TypeSequencePartEditorViewModel extends BasePartEditorViewModel {
    public Padding : ko.Observable<number> = ko.observable();
    public NullPlaceholder : ko.Observable<string> = ko.observable();

    constructor(name : string, part : IEntityCodeGeneratorConfigPart, settingsManager : IEntityCodeGeneratorSettingsManager)
    {
        super(name);
        this.IsParent(true);
        this.Padding(part.Padding);
        this.NullPlaceholder(part.NullPlaceholder);

        this.Preview = ko.computed(() => {
            var padding = Math.max(this.Padding(), (this.NullPlaceholder() || "A").length);
            return this.pad(this.NullPlaceholder() || "A", padding);
        });
    }

    public getData() : IEntityCodeGeneratorConfigPart {
        return {
            Type: 13,
            Text: "",
            DatePart: 0,
            Padding: this.Padding(),
            NullPlaceholder: this.NullPlaceholder(),
            SequenceStart: 1
        };
    }
}