import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { Layout } from "../../Components/Layouts";
import { ITableItem, Table } from "../../Components/TableComponent/TableComponent";
import { IDocumentsService, IResourceOnDocumentAllocation } from "../DocumentsService";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { Column, ColumnBody, ColumnHeader } from "../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../DataSources/IDataSource";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { TableFilter } from "../../Components/TableComponent/TableFilter";
import { Delay } from "../../Decorators/Delay";
import { TableFooterAggregationMode } from "../../Components/TableComponent/TableFooterAggregationMode";
import { DateTimeInput } from "../../Components/DateTimeInput";
import { IDocumentEventBase } from "../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IChangesNotificationsService } from "../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { DocumentClosingStatusIndicator } from "../../Components/DocumentClosingStatusIndicator";
import { JobOrderMetadata } from "../../Components/JobOrderMetadata";
import { DocumentExternalReferenceInfo } from "../../Components/DocumentExternalReferenceInfo";
import { IJobOrderMetadataSettingsManager } from "../../JobOrder/jobOrder/settings/JobOrderMetadataSettingsManager";
import { SplashPageDetailsDialog } from "../../JobOrder/jobOrder/ui/splash-page/SplashPageDetailsComponent";
import { JobOrderType } from "../../Components/JobOrderType";
import { ExcelExporterButton } from "../../Components/ExcelExporterComponent/ExcelExporterComponent";
import { JobOrderClassificationUtils } from "../../JobOrder/jobOrder/settings/ui/JobOrderClassificationUtils";

const styleSheet = jss.createStyleSheet({
    allocatedResourcesReport: {
        "& .header": {
            alignItems: "end",
            overflow: "unset !important",
            marginBottom: "10px",

            "& .form-group": {
                marginBottom: 0,
            },
        },
    },
});
const { classes } = styleSheet.attach();

export class AllocatedResourcesReportDialog extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        super({ className: "fullscreen", noPrompt: true });

        this.title(TextResources.Invoices.AllocatedResourcesOnDocumentsReportTitle);
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    action() {
        this.modal.close();
    }

    renderBody() {
        return <AllocatedResourcesReport />;
    }
}

export function AllocatedResourcesReport() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./AllocatedResourcesReport")._AllocatedResourcesReport as typeof _AllocatedResourcesReport;
    return <C />;
}

type ResourceOnDocumentAllocationExt = IResourceOnDocumentAllocation & {
    JobOrderMetadataParentId: number;
    JobOrderMetadataEntityKeyId: number;
    JobOrderMetadataEntityType: string;
    JobOrderMetadataHasChildren: boolean;
};

type DocumentEventHandler = { handle: number; eventName: string };

export class _AllocatedResourcesReport {
    private From: ko.Observable<Date> = ko.observable();
    private To: ko.Observable<Date> = ko.observable();

    private Resources: ko.ObservableArray<ResourceOnDocumentAllocationExt> = ko.observableArray([]);
    private SelectedResources: ko.ObservableArray<ResourceOnDocumentAllocationExt> = ko.observableArray([]);

    private selectedResource: number[] = [];
    private selectedJobOrders: number[] = [];
    private selectedJobOrderClassifications: number[] = [];
    private selectedJobOrderTypes: number[] = [];
    private selectedProtocols: number[] = [];
    private selectedReferenceNumbers: string[] = [];

    private subscriptions: ko.Subscription[] = [];
    private eventHandlers: DocumentEventHandler[] = [];
    private changingDates = false;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager: IJobOrderMetadataSettingsManager;

    constructor() {}

    componentDidMount() {
        this.From(moment().startOf("month").toDate());

        this.subscriptions.push(this.From.subscribe(this.onFromChanges.bind(this)));
        this.subscriptions.push(this.To.subscribe(this.onToChanges.bind(this)));

        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentCreated",
                this.refreshResourcesListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentCreated",
        });
        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentChanged",
                this.refreshResourcesListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentChanged",
        });
        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentDeleted",
                this.refreshResourcesListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentDeleted",
        });

        this.load();
    }

    private onFromChanges(date: Date) {
        if (this.changingDates) return;

        this.changingDates = true;

        if (date) {
            const to = this.To();

            if (!!to && moment(date).isAfter(moment(to))) this.From(to);
        }

        this.changingDates = false;
    }

    private onToChanges(date: Date) {
        if (this.changingDates) return;

        this.changingDates = true;

        if (date) {
            const from = this.From();

            if (!!from && moment(date).isBefore(moment(from))) this.To(from);
        }

        this.changingDates = false;
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];

        for (const handler of this.eventHandlers)
            this.changesNotificationsService.UnregisterEventHandler(handler.eventName, handler.handle);
    }

    private refreshResourcesListIfNeeded(event: IDocumentEventBase): void {
        const documentId = event.id;
        const resources = this.Resources();

        if (resources.firstOrDefault((r) => r.DocumentId === documentId)) {
            this.load();
        }
    }

    async search(): Promise<void> {
        await this.load();
        this.applyFilters();
    }

    private async load(): Promise<void> {
        try {
            const resources = await this.documentsService.GetAllocatedResourcesOnDocuments(this.From(), this.To());
            this.Resources(resources.map(this.extendResourceOnDocumentAllocation, this));
        } catch (e) {
            console.error(e);
        }
    }

    private extendResourceOnDocumentAllocation(
        resource: IResourceOnDocumentAllocation
    ): ResourceOnDocumentAllocationExt {
        const extResource = Object.assign({}, resource) as ResourceOnDocumentAllocationExt;
        if (!resource.JobOrderMetadataId) return extResource;

        const metadata = this.metadataSettingsManager.getByIds([resource.JobOrderMetadataId]).firstOrDefault();
        if (!metadata) return extResource;

        extResource.JobOrderMetadataParentId = metadata.ParentId;
        extResource.JobOrderMetadataEntityKeyId = metadata.EntityKeyId;
        extResource.JobOrderMetadataEntityType = metadata.EntityType;
        extResource.JobOrderMetadataHasChildren = metadata.HasChildren;

        return extResource;
    }

    private onResourcesSelectionChange(selection: number[]): void {
        this.selectedResource = selection ?? [];
        this.applyFilters();
    }

    private onJobOrdersSelectionChange(selection: number[]): void {
        this.selectedJobOrders = selection ?? [];
        this.applyFilters();
    }

    private onJobOrderTypesSelectionChange(selection: number[]): void {
        this.selectedJobOrderTypes = selection;
        this.applyFilters();
    }

    private onJobOrderClassificationSelectionChange(selection: number[]): void {
        this.selectedJobOrderClassifications = selection;
        this.applyFilters();
    }

    private onProtocolsSelectionChange(selection: number[]): void {
        this.selectedProtocols = selection;
        this.applyFilters();
    }

    private onReferenceNumberSelectionChange(selection: string[]): void {
        this.selectedReferenceNumbers = selection;
        this.applyFilters();
    }

    @Delay(200)
    private applyFilters(): void {
        const allResources = this.Resources();
        const selectedResources = allResources.filter((r) => {
            return (
                this.selectedResource.indexOf(r.ResourceId) >= 0 &&
                this.selectedJobOrders.indexOf(r.JobOrderId) >= 0 &&
                this.selectedJobOrderTypes.indexOf(r.JobOrderTypeId) >= 0 &&
                this.selectedProtocols.indexOf(r.DocumentProtocolId) >= 0 &&
                this.applyJobOrderClassificationsFilter(r) &&
                this.applyReferenceNumberFilter(r)
            );
        });

        this.SelectedResources(selectedResources);
    }
    applyReferenceNumberFilter(resource: ResourceOnDocumentAllocationExt): unknown {
        const viewItemsWithoutValue: boolean = this.selectedReferenceNumbers.filter((f) => !f).length > 0;
        return (
            this.selectedReferenceNumbers.indexOf(resource?.ReferenceNumber ?? null) >= 0 ||
            (viewItemsWithoutValue && !resource?.ReferenceNumber)
        );
    }

    private applyJobOrderClassificationsFilter(resource: ResourceOnDocumentAllocationExt): boolean {
        const viewItemsWithoutValue: boolean = this.selectedJobOrderClassifications.filter((f) => !f).length > 0;
        return (
            this.selectedJobOrderClassifications.indexOf(resource?.JobOrderMetadataId ?? null) >= 0 ||
            (viewItemsWithoutValue && !resource?.JobOrderMetadataId)
        );
    }

    public async openJobOrderDetailedInfo(
        resource: IResourceOnDocumentAllocation,
        viewModel: any,
        e: Event
    ): Promise<void> {
        if (!resource.JobOrderId) return;

        return this.dialogsService.ShowModal(
            new SplashPageDetailsDialog({
                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, resource.JobOrder),
                JobOrderId: resource.JobOrderId,
                className: "large",
            })
        );
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const report = this;
        const resource: IDataSourceModel<number, IResourceOnDocumentAllocation> = null;

        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<IResourceOnDocumentAllocation>();

        const renderJobOrderClassificationItem = (resource: ResourceOnDocumentAllocationExt) => (
            <>
                {resource && (
                    <JobOrderMetadata
                        id={resource.JobOrderMetadataId}
                        name={resource.JobOrderMetadataName}
                        code={resource.JobOrderMetadatCode}
                        parentId={resource.JobOrderMetadataParentId}
                        entityKeyId={resource.JobOrderMetadataEntityKeyId}
                        entityType={resource.JobOrderMetadataEntityType}
                        hasChildren={resource.JobOrderMetadataHasChildren}
                        inline
                    />
                )}
            </>
        );

        const renderJobOrderCassificationItemForFilter = (resource: ResourceOnDocumentAllocationExt) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                {!resource.JobOrderMetadataId && TextResources.ProlifeSdk.EmptyTableFilterLabel}
                {resource.JobOrderMetadataId && renderJobOrderClassificationItem(resource)}
            </div>
        );

        const renderJobOrderType = (resource: ResourceOnDocumentAllocationExt) => {
            return (
                <JobOrderType
                    name={resource.JobOrderType}
                    icon={resource.JobOrderTypeIcon}
                    background={resource.JobOrderTypeIconBackground}
                    foreground={resource.JobOrderTypeIconForeground}
                />
            );
        };

        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["min-content", "1fr"]}
                columns={["1fr"]}
                style={{ height: "100%" }}
                className={classes.allocatedResourcesReport}
            >
                <Layout.Grid.Cell row={1} column={1} className="header">
                    <DateTimeInput
                        value={this.From}
                        label={TextResources.Invoices.From}
                        placeholder={TextResources.ProlifeSdk.DateTimeInputPlaceholder}
                        allowClear
                        dateonly
                        parent=".modal-body"
                    />
                    <DateTimeInput
                        value={this.To}
                        label={TextResources.Invoices.To}
                        placeholder={TextResources.ProlifeSdk.DateTimeInputPlaceholder}
                        allowClear
                        dateonly
                        parent=".modal-body"
                    />
                    <div className="flex-fill">
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-bind={{ asyncClick: report.search.bind(report) }}
                        >
                            <i className="fa fa-search"></i>&nbsp;{TextResources.ProlifeSdk.Search}
                        </button>
                    </div>
                    <ExcelExporterButton
                        exporterId="Invoices/DocumentResourcesExport"
                        exporterMethod="GenerateExcel"
                        dataProvider={() => ({ from: this.From(), to: this.To() })}
                    >
                        <i className="fa fa-download"></i>&nbsp;{TextResources.Invoices.DocumentResourcesExportButton}
                    </ExcelExporterButton>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <Table
                        id={ProlifeSdk.AllocatedResourcesTable}
                        dataSource={{
                            array: this.SelectedResources,
                            factory: Table.defaultFactory((resource: IResourceOnDocumentAllocation) => ({
                                id: resource.DocumentId,
                                title: resource.Surname + " " + resource.Name,
                            })),
                        }}
                        compact
                        systemScrollable
                        showColumnSelector
                        columnSelectorPosition="right"
                        enableAggregators
                        rowAs="resource"
                    >
                        <Column
                            title={TextResources.Invoices.Resource}
                            sorter={sortString((item) => item.Surname + " " + item.Name)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) => item.Data.model.Surname}
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.Resource}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) => item.ResourceId}
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.Surname + " " + item.Name
                                            }
                                            onSelectionChange={this.onResourcesSelectionChange.bind(this)}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => <span>{item.Data.title}</span>}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.From}
                            sorter={sortDate((item) => item.StartDate)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) => item.Data.model.StartDate}
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span data-bind={{ dateText: resource.model.StartDate }}></span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.To}
                            sorter={sortDate((item) => item.EndDate)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) => item.Data.model.EndDate}
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span data-bind={{ dateText: resource.model.EndDate }}></span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.Protocol}
                            sorter={sortString((item) => item.DocumentProtocol)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.DocumentProtocol
                            }
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.Protocol}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.DocumentProtocolId
                                            }
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.DocumentProtocol
                                            }
                                            onSelectionChange={this.onProtocolsSelectionChange.bind(this)}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span>{item.Data.model.DocumentProtocol}</span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentNumberColumn}
                            sorter={sortString((item) => item.DocumentNumber)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.DocumentNumber
                            }
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <DocumentClosingStatusIndicator
                                        documentId={item.Data.model.DocumentId}
                                        documentType={item.Data.model.DocumentType}
                                        protocolId={item.Data.model.DocumentProtocolId}
                                        documentClosingState={item.Data.model.DocumentClosingState}
                                        documentState={item.Data.model.DocumentStateId}
                                        documentCauseLogicType={item.Data.model.DocumentCauseLogicType}
                                        className="btn-xs"
                                        content={<span>{item.Data.model.DocumentNumber}</span>}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentDateColumn}
                            sorter={sortDate((item) => item.DocumentDate)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.DocumentDate
                            }
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span data-bind={{ dateText: resource.model.DocumentDate }}></span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.ExternalReferenceColumn}
                            sorter={sortString((item) => item.ReferenceNumber)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.ReferenceNumber
                            }
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.ExternalReferenceColumn}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.ReferenceNumber
                                            }
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.ReferenceNumber
                                            }
                                            onSelectionChange={this.onReferenceNumberSelectionChange.bind(this)}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                    (item.Data.model.ExternalReference ||
                                        item.Data.model.ReferenceNumber ||
                                        item.Data.model.ReferenceDate) && (
                                        <DocumentExternalReferenceInfo
                                            externalReference={item.Data.model.ExternalReference}
                                            referenceNumber={item.Data.model.ReferenceNumber}
                                            referenceDate={item.Data.model.ReferenceDate}
                                        />
                                    )
                                }
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.JobOrder}
                            sorter={sortString((item) => item.JobOrder)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) => item.Data.model.JobOrder}
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.JobOrder}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) => item.JobOrderId}
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) => item.JobOrder}
                                            onSelectionChange={this.onJobOrdersSelectionChange.bind(this)}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <div className="flex-container">
                                        <div>
                                            {!!item.Data.model.JobOrderId && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-xs"
                                                    title={TextResources.JobOrder.DetailedInfoTitleSimple}
                                                    data-bind={{
                                                        enable: !!resource.model.JobOrder,
                                                        click: report.openJobOrderDetailedInfo.bind(
                                                            report,
                                                            resource.model
                                                        ),
                                                    }}
                                                >
                                                    <i className="fa fa-info-circle"></i>
                                                </button>
                                            )}
                                        </div>
                                        <div className="flex-fill text-ellipsis">
                                            <span>{item.Data.model.JobOrder}</span>
                                        </div>
                                    </div>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.JobOrderType}
                            sorter={sortString((item) => item.JobOrderType)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.JobOrderType
                            }
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.JobOrderType}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) => item.JobOrderTypeId}
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) => item.JobOrderType}
                                            onSelectionChange={this.onJobOrderTypesSelectionChange.bind(this)}
                                            itemRenderer={renderJobOrderType}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ResourceOnDocumentAllocationExt>) =>
                                    renderJobOrderType(item.Data.model)
                                }
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.JobOrderClassification}
                            sorter={sortString((item) => item.JobOrderMetadatCode + " " + item.JobOrderMetadataName)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.JobOrderMetadatCode + " " + item.Data.model.JobOrderMetadataName
                            }
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Invoices.JobOrderClassification}</span>
                                        <TableFilter
                                            filterSource={this.Resources}
                                            itemKeyGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.JobOrderMetadataId
                                            }
                                            itemLabelGetter={(item: IResourceOnDocumentAllocation) =>
                                                item.JobOrderMetadataName
                                            }
                                            onSelectionChange={this.onJobOrderClassificationSelectionChange.bind(this)}
                                            itemRenderer={renderJobOrderCassificationItemForFilter}
                                            popoverContainer=".modal-body"
                                            popoverViewport=".modal-body"
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ResourceOnDocumentAllocationExt>) =>
                                    renderJobOrderClassificationItem(item.Data.model)
                                }
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.HoursAmount}
                            sorter={sortNumber((item) => item.HoursAmount)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.HoursAmount
                            }
                            defaultAggregation={TableFooterAggregationMode.Sum}
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span
                                        data-bind={{ numberText: resource.model.HoursAmount, format: "0,0.00" }}
                                    ></span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.WorkingHoursAmount}
                            sorter={sortNumber((item) => item.WorkingHoursAmount)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.WorkingHoursAmount
                            }
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span
                                        data-bind={{
                                            numberText: resource.model.WorkingHoursAmount,
                                            format: "0,0.00",
                                        }}
                                    ></span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.StandardWorkingHoursAmount}
                            sorter={sortNumber((item) => item.StandardWorkingHoursAmount)}
                            aggregateOn={(item: ITableItem<IResourceOnDocumentAllocation>) =>
                                item.Data.model.StandardWorkingHoursAmount
                            }
                        >
                            <ColumnBody>
                                {(item: ITableItem<IResourceOnDocumentAllocation>) => (
                                    <span
                                        data-bind={{
                                            numberText: resource.model.StandardWorkingHoursAmount,
                                            format: "0,0.00",
                                        }}
                                    ></span>
                                )}
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "report"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AllocatedResourcesReport);
}
