import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export type MailPlaceholder = {
    id: string;
    name: string;
    value: string;
};

export type MailPlaceholderDataSourceModel = IDataSourceModel<string, MailPlaceholder>;

export class DocumentMailPlaceholderDataSource extends BaseDataSource<MailPlaceholderDataSourceModel> {
    private readonly placeholders = [
        {
            id: ProlifeSdk.MailTemplateDocumentContactNamePlaceholder,
            name: TextResources.Core.FullContactName,
            value: ProlifeSdk.MailTemplateDocumentContactNamePlaceholder,
        },
        {
            id: ProlifeSdk.MailTemplateDocumentProtocolPlaceholder,
            name: TextResources.Core.DocumentProtocol,
            value: ProlifeSdk.MailTemplateDocumentProtocolPlaceholder,
        },
        {
            id: ProlifeSdk.MailTemplateDocumentNumberPlaceholder,
            name: TextResources.Core.DocumentNumber,
            value: ProlifeSdk.MailTemplateDocumentNumberPlaceholder,
        },
        {
            id: ProlifeSdk.MailTemplateDocumentDatePlaceholder,
            name: TextResources.Core.DocumentDate,
            value: ProlifeSdk.MailTemplateDocumentDatePlaceholder,
        },
        {
            id: ProlifeSdk.MailTemplateDocumentLabelPlaceholder,
            name: TextResources.Core.DocumentLabel,
            value: ProlifeSdk.MailTemplateDocumentLabelPlaceholder,
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTitle(_: MailPlaceholderDataSourceModel): string {
        return TextResources.Core.DocumentMailTemplatesPlaceholdersListTitle;
    }

    async getData(
        _: MailPlaceholderDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<MailPlaceholderDataSourceModel[]> {
        return this.placeholders
            .filter(this.applyFilter.bind(this, textFilter))
            .slice(skip, skip + count)
            .map((p) => this.createModel(p));
    }

    private applyFilter(textFilter: string, placeholder: MailPlaceholder): boolean {
        return TextFiltersUtilities.contains(placeholder.name, textFilter);
    }

    private createModel(placeholder: MailPlaceholder): MailPlaceholderDataSourceModel {
        return {
            id: placeholder.id,
            title: placeholder.name,
            subTitle: placeholder.value,
            isGroup: false,
            isLeaf: true,
            model: placeholder,
        };
    }

    async getById(_: MailPlaceholderDataSourceModel, ids: string[]): Promise<MailPlaceholderDataSourceModel[]> {
        return this.placeholders.filter((p) => ids.includes(p.id)).map((p) => this.createModel(p));
    }
}
