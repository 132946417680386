import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IPreOrderRowSupplier, IPreOrderRowSupplierDetails, IProvisioningService, ISavePreOrderRequestRowSupplier } from "../../../../ProvisioningService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { Table, ITableItem } from "../../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { Column, ColumnHeader, ColumnBody } from "../../../../../Components/TableComponent/CustomColumn";
import { RequestForQuotationResponseStateHelper } from "../../../entities/RequestForQuotation/Utils/RequestForQuotationResponseStateHelper";
import { IChangesNotificationsService } from "../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { ISuppliersDataSourceModel, SuppliersDataSource } from "../../../../../DataSources/SuppliersDataSource";
import { ProxyDataSource } from "../../../../../DataSources/ProxyDataSource";
import { getStatesList } from "../RequestForQuotationStatesList";
import moment = require("moment-timezone");
import { Select } from "../../../../../Components/Select";
import { RouteParams } from "../../../../../Components/Routing";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { createShipmentRow, RequestForQuotationRowShipmentDate } from "../RequestForQuotationEditor/RequestForQuotationRowsEditor";
import { IIdGeneratorService } from "../../../../../ProlifeSdk/IdGeneratorService";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { WarehousesDataSource } from "../../../../../DataSources/WarehousesDataSource";
import { RequestForQuotationRowShipmentEditor } from "../RequestForQuotationEditor/RequestForQuotationRowShipmentEditor";
import { ICustomersService } from "../../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { AccordionSecondaryRow } from "../AccordionSecondaryRow";

export type PreOrderRowSuppliersEditorParams = RouteParams<{
    preOrderRowId: number;
    hasChanges: ko.Observable<boolean>;
    suppliers: IPreOrderRowSupplierDetails[];
    forwardRef?: (e: _PreOrderRowSuppliersEditor) => void;
}>;

export function PreOrderRowSuppliersEditor(props : PreOrderRowSuppliersEditorParams) {
    const C = require("./PreOrderRowSuppliersEditor")._PreOrderRowSuppliersEditor as typeof _PreOrderRowSuppliersEditor;
    return <C {...props} />;
}

export type PreOrderRowSupplier = {
    RequestForQuotationRowId: number;
    SupplierId: ko.Observable<number>;
    SupplierName: string;
    Selected: ko.Observable<boolean>;
    SuppliersDataSource: ProxyDataSource<SuppliersDataSource, ISuppliersDataSourceModel>;
    RequestForQuotation: string;
    RequestForQuotationState: string;
    ResponseDeliveryDate: ko.Computed<Date>;
    DetailsShown: ko.Observable<boolean>;
    ResponseAmount: ko.Computed<number>;

    Shipments: ko.ObservableArray<RequestForQuotationRowShipmentDate>;

    goToRequestForQuotation: () => void;
    toggleDetails: () => void;
} & Omit<IPreOrderRowSupplier, "SupplierId" | "ResponseDeliveryDate" | "ResponseAmount">

export class _PreOrderRowSuppliersEditor extends ComponentBase {
    public AllSuppliersSelected: ko.Computed<boolean>;

    public Suppliers : ko.ObservableArray<PreOrderRowSupplier> = ko.observableArray();
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService : IProvisioningService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;

    SuppliersDataSource: SuppliersDataSource = new SuppliersDataSource();
    WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();
    
    private loaded: boolean = false;

    constructor(public props : PreOrderRowSuppliersEditorParams) {
        super();

        this.AllSuppliersSelected = ko.computed({
            read: () => {
                return this.Suppliers().filter(s => s.Selected()).length == this.Suppliers().length;
            },
            write: (value: boolean) => {
                this.Suppliers().forEach(s => s.Selected(value));
            }
        });

        if(this.props.forwardRef)
            this.props.forwardRef(this);
    }

    componentDidMount() {
        if(!this.loaded)
            this.load();
    }

    componentWillUnmount() {

    }

    getSuppliers() : ISavePreOrderRequestRowSupplier[] {
        return this.Suppliers().map(s => ({
            Id: s.RequestForQuotationRowId ?? -1,
            SupplierId: s.SupplierId(),
            Shipments: s.Shipments().map(h => ({
                Id: h.Id,
                IsDefault: !h.CanDelete,
                ShipmentDate: h.ShipmentDate(),
                Amount: h.Amount(),
                WarehouseId: h.WarehouseId(),
                Address: h.Address(),
                PostalCode: h.PostalCode(),
                City: h.City(),
                Province: h.Province(),
                State: h.State()
            }))
        }));
    }

    private async load() {
        this.Suppliers(this.props.suppliers.map(this.createSupplier, this));
        this.loaded = true;

        this.disposeOnUnmount(this.Suppliers.subscribe(() => this.props.hasChanges(true)));
    }

    private createSupplier(supplier: IPreOrderRowSupplierDetails) : PreOrderRowSupplier {
        const statesList = getStatesList();
        const detailsShown = ko.observable(false);
        const shipments = ko.observableArray(supplier.Shipments.map(s => createShipmentRow(s, this)));
        const deliveryDate = ko.computed(() => {
            return shipments().firstOrDefault().ShipmentDate();
        });
        const responseAmount = ko.computed(() => {
            return shipments().sum(s => s.Amount());
        });

        return {
            ...supplier,
            SupplierId: ko.observable(supplier.SupplierId),
            SupplierName: supplier.SupplierName,
            Selected: ko.observable(false),
            SuppliersDataSource: new ProxyDataSource(this.SuppliersDataSource),
            RequestForQuotation: supplier.RequestForQuotationRowId <= 0 ? `Nuova Richiesta d'offerta` : `RDO n° ${supplier.Number} del ${moment(supplier.RequestForQuotationCreationDate).format("L")}`,
            RequestForQuotationState: statesList.firstOrDefault(s => s.Id === supplier.StateId)?.Label,
            ResponseDeliveryDate: deliveryDate,
            ResponseAmount: responseAmount,

            DetailsShown: detailsShown,
            Shipments: shipments,

            goToRequestForQuotation: () => this.props.navigator.navigateTo(`/Provisioning/RequestsForQuotations/${supplier.RequestForQuotationId}`),
            toggleDetails: () => detailsShown(!detailsShown())
        };
    }

    public addSupplierForRequestForQuotation(): void {
        this.Suppliers.push(this.createSupplier({
            Number: '',
            RequestForQuotationCreationDate: null,
            RequestForQuotationId: null,
            RequestForQuotationRowId: null,
            Shipments: [{
                Address: null,
                Amount: 0,
                City: null,
                FkrequestForQuotationRow: -1,
                Fkwarehouse: -1,
                Id: -1,
                IsDefault: true,
                PostalCode: null,
                Province: null,
                ShipmentDate: null,
                State: null
            }],
            StateId: 0,
            SupplierId: null,
            SupplierName: '',
        }));
    }

    public async removeSupplierForRequestForQuotation(supplier: PreOrderRowSupplier): Promise<void> 
    {
        if(supplier.RequestForQuotationRowId > 0) {
            const confirm = await this.dialogsService.ConfirmAsync(
                ProlifeSdk.TextResources.Provisioning.RemoveSupplierFromPreOrderRowMessage,
                ProlifeSdk.TextResources.Provisioning.Abort,
                ProlifeSdk.TextResources.Provisioning.Confirm);
    
            if (!confirm)
                return;
        }
        this.Suppliers.remove(supplier);
    }

    renderDetails(item : ITableItem<PreOrderRowSupplier>) {
        return  <td colSpan={11} style={{ padding: 0 }}>
                    <div className="flex-container no-gutters">
                        <div style={{ width: '19px', borderRight: '1px solid #ddd', alignSelf: 'stretch', backgroundColor: '#f5f5f5' }}></div>
                        <div className="flex-1">
                            <AccordionSecondaryRow>
                                <AccordionSecondaryRow.Section 
                                    label="Consegne" 
                                    title="Apri/Chiudi Consegne" 
                                    element={() => <RequestForQuotationRowShipmentEditor shipments={item.Data.model.Shipments} deliveryDate={item.Data.model.ResponseDeliveryDate} hasChanges={this.props.hasChanges} />} />
                            </AccordionSecondaryRow>
                        </div>
                    </div>
                </td>
    }
    
    render() {
        const _porse = this;
        const supplierModel: IDataSourceModel<number, PreOrderRowSupplier> = null;
        const dataSource = Table.defaultDataSource(this.Suppliers, (s) => ({ id: s.RequestForQuotationId, title: "", model: s }));
            
        return ComponentUtils.bindTo(
            <div>
                <Table compact striped fixedLayout dataSource={dataSource} rowAs="supplierModel">
                    <Column style={{ width: '19px', textAlign: 'right', padding: 0 }}>
                        <ColumnBody>
                            <span class="btn-link btn-xs" title="Apri/Chiudi Dettagli" style={{ minWidth: '19px', borderRight: '1px solid #ddd' }} data-bind={{ click: supplierModel.model.toggleDetails.bind(supplierModel.model), tooltip: { placement: 'left' } }}>
                                <i class="fa" data-bind={{ css: { 'fa-angle-right': !supplierModel.model.DetailsShown(), 'fa-angle-down': supplierModel.model.DetailsShown }}}></i>
                            </span>
                        </ColumnBody>
                    </Column>
                    <Column title="Fornitore" headerCssClasses="text-left" cssClasses="text-left"  style={{ width: '300px' }}>
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRowSupplier>) => (
                                item.Data.model.RequestForQuotationRowId <= 0 
                                    ? <Select dataSource={item.Data.model.SuppliersDataSource} value={item.Data.model.SupplierId} className="input-sm" placeholder='Cerca fornitori...' />
                                    : <span data-bind={{ text: supplierModel.model.SupplierName, click: supplierModel.model.toggleDetails.bind(supplierModel.model) }} style={{ cursor: 'pointer', width: '100%' }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title="R.d.o." headerCssClasses="text-left" cssClasses="text-left" style={{ width: '200px' }}>
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRowSupplier>) => (
                                item.Data.model.RequestForQuotationRowId <= 0
                                    ? <span className="font-green-jungle" data-bind={{ text: supplierModel.model.RequestForQuotation }}></span>
                                    : <a href="#" data-bind={{ text: supplierModel.model.RequestForQuotation, click: supplierModel.model.goToRequestForQuotation.bind(supplierModel.model) }}></a>
                            )}
                        </ColumnBody>
                        
                    </Column>
                    <Column title="Stato R.d.o." headerCssClasses="text-left" cssClasses="text-left text-ellipsis">
                        <span data-bind={{ text: supplierModel.model.RequestForQuotationState }}></span>
                    </Column>
                    <Column title="Data invio">
                        <span data-bind={{ dateText: supplierModel.model.DateOfSending, dateTextNoValue: 'N/A' }}></span>
                    </Column>
                    <Column title="Data risp.">
                        <span data-bind={{ dateText: supplierModel.model.ResponseDate, dateTextNoValue: 'N/A' }}></span>
                    </Column>
                    <Column title="Q.tà" className="text-right">
                        <span data-bind={{ numberText: supplierModel.model.ResponseAmount }}></span>
                    </Column>
                    <Column title="Prezzo" className="text-right">
                        <span data-bind={{ moneyText: supplierModel.model.ResponsePrice }}></span>
                    </Column>
                    <Column title="Data cons.">
                        <span data-bind={{ dateText: supplierModel.model.ResponseDeliveryDate, dateTextNoValue: 'N/A' }}></span>
                    </Column>
                    <Column>
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRowSupplier>) => <RequestForQuotationResponseStateHelper response={item.Data.model} />}
                        </ColumnBody>
                    </Column>
                    <Column className="text-right">
                        <ColumnHeader style={{ verticalAlign: 'middle '}}>
                            <button type="button" class="btn btn-xs btn-primary btn-circle" data-bind={{ click: _porse.addSupplierForRequestForQuotation.bind(_porse), tooltip: { placement: 'left' } }} title="Aggiungi un fornitore">
                                <i class="fa fa-plus"></i>&nbsp;Nuovo
                            </button>
                        </ColumnHeader>
                        <ColumnBody>
                            <button type="button" class="btn btn-xs btn-danger btn-circle" data-bind={{ click: _porse.removeSupplierForRequestForQuotation.bind(_porse, supplierModel.model), tooltip: { placement: 'left' } }} title="Rimuovi la riga del fornitore">
                                <i class="fa fa-trash-o"></i>&nbsp;Elimina
                            </button>
                        </ColumnBody>
                    </Column>
                    <SecondaryRow visible={() => "supplierModel.model.DetailsShown"}>
                        {(item: ITableItem<PreOrderRowSupplier>) => this.renderDetails(item)}
                    </SecondaryRow>
                </Table>
            </div>
        , this, "_porse");
    }
}