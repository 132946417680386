import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../Core/utils/DialogComponentBase";
import { Layout } from "./Layouts";
import {
    INavigationMenuComponentAlert,
    INavigationMenuComponentBadge,
} from "./NavigationMenuComponent/INavigationMenuComponent";
import { JobOrdersUtils } from "../JobOrder/jobOrder/ui/utils/JobOrdersUtils";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { TasksUtils } from "../Todolist/Todolist/ui/utils/TasksUtils";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    "workflow-navigator-alert-badge": {
        padding: "0 2px",
    },
});
const { classes } = styleSheet.attach();

export class AlertsLegendUI extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        super({ noPrompt: true });
        this.title(TextResources.ProlifeSdk.AlertsLegendTitle);
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return <AlertsLegend />;
    }
}

export function AlertsLegend() {
    const C = require("./AlertsLegend")._AlertsLegend as typeof _AlertsLegend;
    return <C />;
}

export class _AlertsLegend {
    public jobOrderAlerts: INavigationMenuComponentAlert[] = [];
    public workflowBadges: INavigationMenuComponentBadge[] = [];
    public workflowAlerts: INavigationMenuComponentAlert[] = [];
    public taskBadges: INavigationMenuComponentBadge[] = [];
    public taskAlerts: INavigationMenuComponentAlert[] = [];

    constructor() {
        this.jobOrderAlerts = JobOrdersUtils.getAllAlerts();
        this.workflowBadges = WorkflowsUtils.getAllBadges();
        this.workflowAlerts = WorkflowsUtils.getAllAlerts();
        this.taskBadges = TasksUtils.getAllBadges();
        this.taskAlerts = TasksUtils.getAllAlerts();
    }

    render() {
        return ComponentUtils.bindTo(
            <Layout.ScrollContainer systemScrollable style={{ maxHeight: "400px" }}>
                <h3>{TextResources.JobOrder.JobOrder}</h3>
                {this.jobOrderAlerts.map((alert) => (
                    <div className="flex-container">
                        <div>
                            <div className="btn btn-default workflow-navigator-alert-icon">
                                <i
                                    className={alert.icon.icon}
                                    style={{ color: alert.icon.foreground, width: "1.25em" }}></i>
                            </div>
                        </div>
                        <div className="flex-fill">
                            <span>{alert.tooltip}</span>
                        </div>
                    </div>
                ))}

                <h3>{TextResources.Todolist.Workflow}</h3>
                {this.workflowBadges.map((badge) => (
                    <div className="flex-container">
                        <div>
                            <div className={classes["workflow-navigator-alert-badge"]}>
                                <span className={badge.cssClass}>{badge.text}</span>
                            </div>
                        </div>
                        <div className="flex-fill">
                            <span>{badge.title}</span>
                        </div>
                    </div>
                ))}
                {this.workflowAlerts.map((alert) => (
                    <div className="flex-container">
                        <div>
                            <div className="btn btn-default workflow-navigator-alert-icon">
                                <i
                                    className={alert.icon.icon}
                                    style={{ color: alert.icon.foreground, width: "1.25em" }}></i>
                            </div>
                        </div>
                        <div className="flex-fill">
                            <span>{alert.tooltip}</span>
                        </div>
                    </div>
                ))}

                <h3>{TextResources.Todolist.Task}</h3>
                {this.taskBadges.map((badge) => (
                    <div className="flex-container">
                        <div>
                            <div className={classes["workflow-navigator-alert-badge"]}>
                                <span className={badge.cssClass}>{badge.text}</span>
                            </div>
                        </div>
                        <div className="flex-fill">
                            <span>{badge.title}</span>
                        </div>
                    </div>
                ))}
                {this.taskAlerts.map((alert) => (
                    <div className="flex-container">
                        <div>
                            <div className="btn btn-default workflow-navigator-alert-icon">
                                <i
                                    className={alert.icon.icon}
                                    style={{ color: alert.icon.foreground, width: "1.25em" }}></i>
                            </div>
                        </div>
                        <div className="flex-fill">
                            <span>{alert.tooltip}</span>
                        </div>
                    </div>
                ))}
            </Layout.ScrollContainer>,
            this,
            "alertsLegend"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AlertsLegend);
}
