import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../../../Components/Layouts";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IHoursApprovalRow, IHoursApproval_Type, IWorkedHoursService, IWorkedHoursWithApprovalStateForListWorkedHours } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IGetResourceHoursApprovalGroupsRequest, IHoursApprovalGroup, IUsersService } from "../../../../Users/UsersService";
import { If } from "../../../../Components/IfIfNotWith";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { CheckBox } from "../../../../Components/Checkbox";
import { RichTextInput } from "../../../../Components/RichTextInput";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { IconSwitcher } from "../../../../Components/IconSwitcher";
import { HoursApprovalRowState } from "./HoursApprovalRowState";
import { DateTimeText } from "../../../../Components/DateTimeText";
import { NumericText } from "../../../../Components/NumericText";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";

const styleSheet = jss.createStyleSheet({
    hoursApprovalModal: {
        "& .checkbox-wrapper": {
            width: "25px",
            padding: "0px 5px"
        },

        "& .actions": {
            width: "60px"
        },

        "& .outcome": {
            width: "125px",

            "& i": {
                marginRight: "10px"
            }
        },

        "& .role, & .group": {
            width: "30%"
        },

        "& .approver": {
            width: "calc(40% - 185px)"
        },

        "& .history-notes": {
            display: "inline-block",
            padding: "5px"
        },

        "& .hours-info": {
            "& label": {
                display: "block",
                fontWeight: "bold"
            }
        }
    }    
});
const { classes } = styleSheet.attach();

type HoursApprovalDialogProps = {
    hours: IWorkedHoursWithApprovalStateForListWorkedHours;
}

class HoursApprovalRow {
    groupId: number;
    groupLabel: string;
    approvalRole: string;
    approvalDate: Date;
    approvalState: HoursApprovalState;
    approver: string;
    
    canApprove: boolean;
    
    CanEdit : ko.Observable<boolean> = ko.observable();
    Notes : ko.Observable<string> = ko.observable();
    ShowNotes : ko.Observable<boolean> = ko.observable(false);
    Selected : ko.Observable<boolean> = ko.observable(false);

    HasNotes : ko.Computed<boolean>;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager : IHumanResourcesSettingsManager;
    
    constructor(private data: IHoursApprovalRow, private approverGroups: IHoursApprovalGroup[]) {
        this.groupId = this.data.ApprovalGroupId;
        this.groupLabel = this.data.ApprovalGroupLabel;
        this.approvalRole = this.data.ApprovalGroupRole;
        this.approvalDate = this.data.ApprovalDate;
        this.approvalState = this.data.ApprovalState;
        this.approver = this.data.ApproverResourceName;

        const currentResourceId = this.humanResourcesSettingsManager.getLoggedResourceId();
        const userCanApproveOnGroup = !!this.approverGroups.firstOrDefault(g => g.GroupId === this.groupId && g.CanApprove);

        this.canApprove = userCanApproveOnGroup && this.data.ApprovalState !== HoursApprovalState.SignRemoved;
        this.CanEdit(((this.data.ApprovalState !== 2 && this.data.ApprovalState !== 3) || this.data.ApproverResourceId === currentResourceId) && userCanApproveOnGroup);
        this.Selected(this.canApprove);

        this.Notes(this.data.ApprovalNotes);

        this.HasNotes = ko.computed(() => {
            return !String.isNullOrUndefinedOrWhiteSpace(this.Notes());
        });

        if (!String.isNullOrUndefinedOrWhiteSpace(this.data.ApprovalNotes))
            this.ShowNotes(true);
    }
}

export class HoursApprovalDialog extends DialogComponentBase {
    static defaultProps: Partial<HoursApprovalDialogProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IUsersService>())
    private usersService: IUsersService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager : IHumanResourcesSettingsManager;

    private CurrentStateRows: ko.ObservableArray<HoursApprovalRow> = ko.observableArray([]);
    private StateHistoryRows: ko.ObservableArray<HoursApprovalRow> = ko.observableArray([]);
    private AllCurrentExpanded: ko.Observable<boolean> = ko.observable(false);
    private AllHistoryExpanded: ko.Observable<boolean> = ko.observable(false);
    private CanApprove : ko.Observable<boolean> = ko.observable(false);

    private someIsChanged = false;

    constructor(private props : HoursApprovalDialogProps) {
        super({ className: "large" });

        this.title(TextResources.WorkedHours.ApprovalDialogTitle);
    }

    componentDidMount() {
        this.load();
    }

    private async load(): Promise<void> {
        try {
            const approvalGroupsRequest: IGetResourceHoursApprovalGroupsRequest = {
                textFilter: ""
            };
            const [resourceApprovalGroups, approvalRows] = await Promise.all<IHoursApprovalGroup[], IHoursApprovalRow[]>([
                this.usersService.GetResourceHoursApprovalGroups(approvalGroupsRequest),
                this.workedHoursService.GetHoursApprovalState(this.props.hours.HoursId)
            ]);

            const actual = approvalRows.filter(r => r.IsValid);
            const history = approvalRows.filter(r => !r.IsValid);

            this.CurrentStateRows(actual.map(r => new HoursApprovalRow(r, resourceApprovalGroups)));
            this.StateHistoryRows(history.map(r => new HoursApprovalRow(r, resourceApprovalGroups)));
            
            const currentResourceId = this.humanResourcesSettingsManager.getLoggedResourceId();
            const isRejectedByOthers = actual.firstOrDefault(s => s.ApprovalState === HoursApprovalState.Rejected && s.ApproverResourceId !== currentResourceId);
            const canApproveOnAtLeastOneGroup = !!resourceApprovalGroups.firstOrDefault(g => g.CanApprove);
            this.CanApprove(!isRejectedByOthers && canApproveOnAtLeastOneGroup);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        this.CurrentStateRows([]);
        this.StateHistoryRows([]);
    }
    
    close() {
        this.modal.close(this.someIsChanged);
    }

    action() {
        this.setApprovalState(HoursApprovalState.Approved);
    }
    
    reject() {
        this.setApprovalState(HoursApprovalState.Rejected);
    }
    
    removeState() {
        this.setApprovalState(HoursApprovalState.Reset);
    }
    
    private async setApprovalState(state: HoursApprovalState): Promise<void> {
        try {
            const actualState = this.CurrentStateRows();
            const selectedRows = actualState.filter(r => r.Selected());

            if (selectedRows.length === 0) {
                await this.dialogsService.AlertAsync(TextResources.WorkedHours.SelectHoursToApprove, TextResources.WorkedHours.SelectHoursToApproveTitle);
                return;
            }

            const approvatState: IHoursApproval_Type[] = selectedRows.map(r => ({
                HoursId: this.props.hours.HoursId,
                ApprovalGroupId: r.groupId,
                ApprovalNotes: r.Notes(),
                ApprovalState: state
            }));

            await this.workedHoursService.SetApprovalState(approvatState, null, null);
            this.someIsChanged = true;
            await this.load();
        } catch (e) {
            console.log(e);
        }
    }

    private manageCollapseExpandAllCurrentRows(): void {
        const actualState = this.AllCurrentExpanded();
        const endState = !actualState;
        for (const row of this.CurrentStateRows()) {
            row.ShowNotes(endState);
        }

        this.AllCurrentExpanded(endState);
    }
    
    private manageCollapseExpandAllHistoryRows(): void {
        const actualState = this.AllHistoryExpanded();
        const endState = !actualState;
        for (const row of this.StateHistoryRows()) {
            row.ShowNotes(endState);
        }

        this.AllHistoryExpanded(endState);
    }

    private createStateModel(row: HoursApprovalRow): IDataSourceModel<number, HoursApprovalRow> {
        return {
            id: row.groupId,
            title: row.approvalRole,
            isGroup: false,
            isLeaf: true,
            model: row
        }
    }
    
    renderBody() {
        return ComponentUtils.bindTo((
            <div className={"flex-container flex-vertical flex-full-height " + classes.hoursApprovalModal} style={{ overflow: "hidden" }}>
                <div className="row hours-info">
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalResourceName}</label>
                        <span>{this.props.hours.ResourceName}</span>
                    </div>
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalHours}</label>
                        <NumericText value={this.props.hours.Hours} />
                    </div>
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalRole}</label>
                        <span>{this.props.hours.RoleName}</span>
                    </div>
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalWorktimeCategory}</label>
                        <span>{this.props.hours.WorkTimeCategoryName}</span>
                    </div>
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalActivity}</label>
                        <div title={this.props.hours.TaskTitle}>{this.props.hours.TaskTitle}</div>
                        <div title={this.props.hours.WorkflowTitle}><small class="text-muted">{this.props.hours.WorkflowTitle}</small></div>
                    </div>
                    <div className="col-md-2">
                        <label>{TextResources.WorkedHours.HoursApprovalJobOrder}</label>
                        <div title={this.props.hours.JobOrderTitle}>{this.props.hours.JobOrderTitle}</div>
                    </div>
                </div>
                <div className="flex-1" style={{ overflow: "hidden" }}>
                    {this.renderCurrentState()}
                </div>
                <If condition={() => this.StateHistoryRows().length > 0}>
                    {() => (
                        <div className="flex-1" style={{ overflow: "hidden" }}>
                            {this.renderHistory()}
                        </div>
                    )}
                </If>
            </div>
        ), this, "hoursApproval");
    }

    renderFooter() {
        return  ComponentUtils.bindTo((
                    <div>
                        <a href="#" className="btn btn-default" onClick={(e) => {e.preventDefault(); this.close(); }}>{TextResources.ProlifeSdk.Close}</a>
                        <If condition={this.CanApprove}>
                            {() => (
                                <>
                                    <button className="btn btn-primary" onClick={() => this.removeState()}>{TextResources.WorkedHours.ApproveDialogRemoveStateButton}</button>
                                    <button className="btn btn-danger" onClick={() => this.reject()}>{TextResources.WorkedHours.ApproveDialogRejectButton}</button>
                                    <button className="btn btn-success" onClick={() => this.action()}>{TextResources.WorkedHours.ApproveDialogApproveButton}</button>
                                </>
                            )}
                        </If>
                    </div>
                ) , this, "hoursApproval") as React.ReactElement;
    }

    private renderCurrentState() {
        return  <Layout.WithHeader>
                    <Layout.WithHeader.Header>
                        <h3>{TextResources.WorkedHours.CurrentApprovalStateTitle}</h3>
                    </Layout.WithHeader.Header>
                    <Layout.WithHeader.Content noOverflow>
                        <Table
                            dataSource={{ array: this.CurrentStateRows, factory: this.createStateModel.bind(this) }}
                            systemScrollable
                            compact
                            editable
                            rowAs="row"
                            >
                            <Column className="group">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalGroupColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <div className="flex-container">
                                                                                <div className="checkbox-wrapper">
                                                                                    {row.Data.model.canApprove && <CheckBox checked={row.Data.model.Selected} readOnly={ko.computed(() => !this.CanApprove() || !row.Data.model.canApprove)} />}
                                                                                </div>
                                                                                <div className="flex-fill">
                                                                                    <span>{row.Data.model.groupLabel || TextResources.ProlifeSdk.NotAvailable}</span>
                                                                                </div>
                                                                            </div>
                                    }
                                </ColumnBody>
                            </Column>
                            <Column className="outcome">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalOutcomeColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <HoursApprovalRowState state={row.Data.model.approvalState} />}
                                </ColumnBody>
                            </Column>
                            <Column className="date">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalDateColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <DateTimeText dateTime={row.Data.model.approvalDate} />}
                                </ColumnBody>
                            </Column>
                            <Column className="approver">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalApproverColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <span>{row.Data.model.approver || TextResources.ProlifeSdk.NotAvailable}</span>}
                                </ColumnBody>
                            </Column>
                            <Column className="role">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalRoleColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <span>{row.Data.model.approvalRole || TextResources.ProlifeSdk.NotAvailable}</span>}
                                </ColumnBody>
                            </Column>
                            <Column className="text-right actions">
                                <ColumnHeader>
                                    {() => (
                                        <span onClick={() => this.manageCollapseExpandAllCurrentRows() }>
                                            <IconSwitcher isTrue="fa-chevron-up" isFalse="fa-chevron-down" trigger={this.AllCurrentExpanded} />
                                        </span>
                                    )}
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => (
                                        <>
                                            <If condition={row.Data.model.HasNotes}>
                                                {() => (
                                                    <span title={TextResources.WorkedHours.HoursApprovalNotesIconTooltip}>
                                                        <i className="fa fa-font"></i>
                                                    </span>
                                                )}
                                            </If>
                                            <span onClick={() => row.Data.model.ShowNotes(!row.Data.model.ShowNotes()) }>
                                                <IconSwitcher isTrue="fa-chevron-up" isFalse="fa-chevron-down" trigger={row.Data.model.ShowNotes} />
                                            </span>
                                        </>
                                    )}
                                </ColumnBody>
                            </Column>
                            <SecondaryRow if={() => "row.model.ShowNotes()"}>
                                {(row: ITableItem<HoursApprovalRow>) => (
                                    <td colSpan={5}>
                                        <RichTextInput
                                            value={row.Data.model.Notes}
                                            inline
                                            simple
                                            readonly={ko.computed(() => !this.CanApprove() || !row.Data.model.CanEdit())}
                                            placeholder={TextResources.WorkedHours.HouraApprovalNotesPlaceholder}
                                        />
                                    </td>
                                )}
                            </SecondaryRow>
                        </Table>
                    </Layout.WithHeader.Content>
                </Layout.WithHeader>;
    }

    private renderHistory() {
        return  <Layout.WithHeader>
                    <Layout.WithHeader.Header>
                        <h3>{TextResources.WorkedHours.ApprovalStateHistoryTitle}</h3>
                    </Layout.WithHeader.Header>
                    <Layout.WithHeader.Content noOverflow>
                        <Table
                            dataSource={{ array: this.StateHistoryRows, factory: this.createStateModel.bind(this) }}
                            systemScrollable
                            compact
                            rowAs="row"
                            >
                            <Column className="group">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalGroupColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <span>{row.Data.model.groupLabel || TextResources.ProlifeSdk.NotAvailable}</span>}
                                </ColumnBody>
                            </Column>
                            <Column className="outcome">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalOutcomeColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <HoursApprovalRowState state={row.Data.model.approvalState} />}
                                </ColumnBody>
                            </Column>
                            <Column className="date">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalDateColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <DateTimeText dateTime={row.Data.model.approvalDate} />}
                                </ColumnBody>
                            </Column>
                            <Column className="approver">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalApproverColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <span>{row.Data.model.approver || TextResources.ProlifeSdk.NotAvailable}</span>}
                                </ColumnBody>
                            </Column>
                            <Column className="role">
                                <ColumnHeader>
                                    <span>{TextResources.WorkedHours.HoursApprovalModalRoleColumn}</span>
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => <span>{row.Data.model.approvalRole || TextResources.ProlifeSdk.NotAvailable}</span>}
                                </ColumnBody>
                            </Column>
                            <Column className="text-right actions">
                                <ColumnHeader>
                                    {() => (
                                        <span onClick={() => this.manageCollapseExpandAllHistoryRows() }>
                                            <IconSwitcher isTrue="fa-chevron-up" isFalse="fa-chevron-down" trigger={this.AllHistoryExpanded} />
                                        </span>
                                    )}
                                </ColumnHeader>
                                <ColumnBody>
                                    {(row: ITableItem<HoursApprovalRow>) => (
                                        <span onClick={() => row.Data.model.ShowNotes(!row.Data.model.ShowNotes()) }>
                                            <IconSwitcher isTrue="fa-chevron-up" isFalse="fa-chevron-down" trigger={row.Data.model.ShowNotes} />
                                        </span>
                                    )}
                                </ColumnBody>
                            </Column>
                            <SecondaryRow if={() => "row.model.ShowNotes()"}>
                                {(row: ITableItem<HoursApprovalRow>) => (<span className="history-notes">{row.Data.model.Notes() ?? TextResources.WorkedHours.HouraApprovalNotesPlaceholder}</span>)}
                            </SecondaryRow>
                        </Table>
                    </Layout.WithHeader.Content>
                </Layout.WithHeader>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(HoursApprovalDialog);
}