import * as ko from "knockout";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { CustomersDataSource, ICustomersDataSourceModel } from "../../../../../DataSources/CustomersDataSource";
import { ICustomerDiscountCatalog, IDiscountsCatalogsService } from "../../../../DiscountsCatalogsService";
import { CustomerDiscountCatalogsDataSource, IBaseCustomerDiscountCatalogsDataSourceObserver } from "../../../../../DataSources/CustomerDiscountCatalogsDataSource";
import { AssignDiscountCatalogDialog } from "./AssignDiscountCatalogDialog";
import { CustomerDiscountCatalogSolutionDataSource } from "../../../../../DataSources/CustomerDiscountCatalogSolutionDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ICustomerForList } from "../../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";

export class DiscountsList implements IView, IDataSourceListener, IBaseCustomerDiscountCatalogsDataSourceObserver {
    templateName: string = "customerDiscounts";
    templateUrl: string = "warehouse/templates/discounts";
    viewModel: any;

    SectionId: number = 3;

    IsCustomer : boolean = true;

    public CustomersDataSource : CustomersDataSource = new CustomersDataSource();
    public CustomerDiscountCatalogsDataSource : CustomerDiscountCatalogsDataSource = new CustomerDiscountCatalogsDataSource();
    public CustomersSearch : ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });

    SelectedCustomer : ko.Observable<ICustomerForList> = ko.observable();
    CustomerDiscounts : ko.ObservableArray<ICustomerDiscountCatalog> = ko.observableArray();
    CustomerDiscountCatalogSolutionDataSource : CustomerDiscountCatalogSolutionDataSource = new CustomerDiscountCatalogSolutionDataSource();

    TestingDiscounts : ko.Observable<boolean> = ko.observable();
    TestingDiscountsFilter : ko.Observable<string> = ko.observable();

    constructor() {
        this.viewModel = this;
        this.CustomersDataSource.setViewModelCreationCallback((model : ICustomersDataSourceModel) => {
            if(!model.isGroup || !model.id)
                return model;

            model.action = {
                title: "Gestisci Gruppo",
                icon: "icon-pencil",
                action: async (model) => this.assignDiscountCatalogToGroup(model.id)
            }
            return model;
        });

        this.CustomerDiscountCatalogsDataSource.addObserver(this);
    }

    onDataChanged(): void {
        if(this.TestingDiscounts())
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
    }

    public toggleTestingMode() {
        if(!this.SelectedCustomer()) return;

        this.TestingDiscounts(!this.TestingDiscounts());

        if(this.TestingDiscounts()) {
            this.CustomerDiscountCatalogSolutionDataSource.setCustomerId(this.SelectedCustomer().Id, true);
            this.CustomerDiscountCatalogSolutionDataSource.refresh();
        }
    }

    onItemSelected(sender: IDataSource, model: ICustomersDataSourceModel): void {
        if(sender == this.CustomersDataSource) {
            this.TestingDiscounts(false);
            this.SelectedCustomer(model.model as ICustomerForList);
            this.CustomerDiscountCatalogsDataSource.setCustomerId(model.id);
            this.CustomerDiscountCatalogsDataSource.refresh();
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }
    
    async canSelectItem(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): Promise<boolean> {
        return true;
    }

    async assignDiscountCatalog() : Promise<void> {
        let customerId = this.SelectedCustomer().Id;
        let result = await new AssignDiscountCatalogDialog(customerId, false, true).showModal()
        if(result)
            this.CustomerDiscountCatalogsDataSource.refresh();
    }

    async assignDiscountCatalogToGroup(groupId : number) : Promise<void> {
        let result = await new AssignDiscountCatalogDialog(groupId, true, true).showModal()
        if(result)
            this.CustomerDiscountCatalogsDataSource.refresh();
    }
}

