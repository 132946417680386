import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 30/08/2017
 * Time: 11:50
 * To change this template use File | Settings | File Templates.
 */

import { TelephoneNumber as TelephoneNumberUtilities } from "../../../../Desktop/utilities/TelephoneNumber";
import { IContactForList } from "../../../../ProlifeSdk/interfaces/survey-wizard/IContactForList";

export class ContactIdentifier {
    public QueueId: number;
    public Id: number;
    public BusinessName: ko.Observable<string> = ko.observable();
    public Name: ko.Observable<string> = ko.observable();
    public Surname: ko.Observable<string> = ko.observable();
    public PhoneNumber: ko.Observable<string> = ko.observable();
    public Email: ko.Observable<string> = ko.observable();
    public PEC: ko.Observable<string> = ko.observable();

    public ContactIdentifier: ko.Computed<string>;
    public FormattedTelephone : ko.Observable<string> = ko.observable();

    constructor(contact: IContactForList) {
        this.QueueId = contact.QueueId;
        this.Id = contact.Id;
        this.BusinessName(contact.BusinessName);
        this.Name(contact.Name);
        this.Surname(contact.Surname);
        this.PhoneNumber(contact.PhoneNumber);
        this.Email(contact.Email);
        this.PEC(contact.PEC);

        this.ContactIdentifier = ko.computed(() => {
            return this.BusinessName() ? this.BusinessName() : this.Name() + " " + this.Surname();
        });

        this.FormattedTelephone(TelephoneNumberUtilities.formatGSM(this.PhoneNumber()));
    }
}