import * as ko from "knockout";
import { IDataSourceModel, IDataSource, IDataSourceListener, IDataSourceView } from "../DataSources/IDataSource";

export interface ITreeComponentModel extends IDataSourceModel {
    parentId?: number;
}

export interface ITreeComponentParameters {
    RootNode?: string;
    
    Value: ko.Observable<number|string>;

    DataSource: IDataSource;
    Listener?: IDataSourceListener | IDataSourceListener[];
    InjectTo?: ko.Observable<ITreeComponent>;
}

export type ITreeComponent = IDataSourceView

class TreeNode {
    public Name : ko.Observable<string> = ko.observable();
    public SubTitle : ko.Observable<string> = ko.observable();
    public Children : ko.ObservableArray<TreeNode> = ko.observableArray();
    public Selected : ko.Observable<boolean> = ko.observable();
    public Expanded : ko.Observable<boolean> = ko.observable();
    public Icon : ko.Observable<string> = ko.observable();
    public Background : ko.Observable<string> = ko.observable();
    public Foreground : ko.Observable<string> = ko.observable();
    public HasChildren : ko.Computed<boolean>;
    public HasLoadedChildren : ko.Observable<boolean> = ko.observable();

    public Parent : TreeNode = null;

    constructor(private tree : TreeComponent, private model : ITreeComponentModel) {
        this.HasChildren = ko.computed(() => {
            return this.Children().length != 0;
        });

        if(model) {
            this.Name(model.title);
            this.SubTitle(model.subTitle);
            this.Icon(model.icon ? model.icon.icon : "");
            this.HasLoadedChildren(model.isLeaf);
            this.Background(model.icon ? model.icon.background : "transparent");
            this.Foreground(model.icon ? model.icon.foreground : "black");
        }
    }

    public async Select() {
        this.expandParents();

        if(await this.tree.onNodeSelected(this.model))
            this.Selected(true);
    }

    private expandParents() {
        let parent = this.Parent;
        while(parent) {
            if(!parent.Expanded())
                parent.Expand();
            parent = parent.Parent;
        }
    }

    public deselectAll() {
        let wasSelected = this.Selected();
        this.Selected(false);
        
        if(wasSelected)
            this.tree.onNodeDeselected(this.model);

        for(let child of this.Children())
            child.deselectAll();
    }

    public async Expand() : Promise<void> {
        this.Expanded(!this.Expanded());
        if(this.Expanded() && !this.HasLoadedChildren()) {
            this.LoadChildren();
        }
    }

    public async LoadChildren() : Promise<void> {
        this.HasLoadedChildren(true);
        let children = await this.tree.loadChildren(this.model, this);
        this.Children(children);
    }

    getModel(): IDataSourceModel<string | number, any, string | number, any> {
        return this.model;
    }
}

export class TreeComponent implements ITreeComponent {
    public Children : ko.ObservableArray<TreeNode> = ko.observableArray();
    public Value : ko.Observable<number|string>;

    private dataSource : IDataSource;
    private listeners : IDataSourceListener[];
    private expandedNodeModels: ITreeComponentModel[];

    constructor(private params : ITreeComponentParameters) {
        this.dataSource = params.DataSource;
        this.listeners = Array.isArray(params.Listener) ? params.Listener : (params.Listener ? [params.Listener] : []);

        this.Value = params.Value || ko.observable();

        if(params.InjectTo)
            params.InjectTo(this);

        this.dataSource.setView(this);

        this.refresh();
    }

    async load() : Promise<void> {
        let children = await this.loadChildren(null, null);
        this.Children(children);
    }

    async loadChildren(model : ITreeComponentModel, parent: TreeNode) : Promise<TreeNode[]> {
        let childData = await this.dataSource.getData(model, null, 0, 10000000);
        return childData.map(this.createTreeNodeFor.bind(this, parent));
    }

    private createTreeNodeFor(parent: TreeNode, model : ITreeComponentModel) : TreeNode {
        const node = new TreeNode(this, model);
        node.Parent = parent;

        if(this.expandedNodeModels.filter(m => this.dataSource.areEqual(m, model)).length > 0)
            node.Expand();

        return node;
    }

    private deselectAll() {
        for(let root of this.Children())
            root.deselectAll();
    }

    async onNodeSelected(model: ITreeComponentModel) : Promise<boolean> {
        if(!await this.canSelectItem(model))
            return false;

        this.deselectAll();
        this.listeners.forEach(l => l.onItemSelected(this.dataSource, model));

        this.Value(model ? model.id : undefined);
        return true;
    }

    onNodeDeselected(model: ITreeComponentModel) {
        this.listeners.forEach(l => l.onItemDeselected(this.dataSource, model));
    }

    private async canSelectItem(model : ITreeComponentModel): Promise<boolean> {
        for(let listener of this.listeners) {
            if(!listener.canSelectItem) continue;
            if(!await listener.canSelectItem(this.dataSource, model))
                return false;
        }

        return true;
    }

    getPageSize(): number {
        return 10000000;
    }

    setPageSize(size: number): void {
        
    }

    refresh(keepSelection?: boolean): Promise<void> {
        return this.refreshImmediate(keepSelection);
    }

    async refreshImmediate(keepSelection?: boolean): Promise<void> {
        this.expandedNodeModels = this.getExpandedNodesModels(this.Children());

        if (this.params.RootNode) {
            let node = new TreeNode(this, null);
            node.Icon("fa fa-folder icon-state-warning icon-lg");
            node.Name(this.params.RootNode);
            await node.Expand();
            this.Children([node]);
        }
        else {
            await this.load();
        }
    }

    private getExpandedNodesModels(list : TreeNode[]): ITreeComponentModel[] {
        let models : ITreeComponentModel[] = [];
        for(let item of list) {
            if(item.Expanded()) {
                let model = item.getModel();
                if(model)
                    models.push(model);
            }

            models = models.concat(this.getExpandedNodesModels(item.Children()));
        }
        return models;
    }

    pushState(): void {
        
    }

    popState(): void {
        
    }

    async select(...models: IDataSourceModel<string | number, any, string | number, any>[]): Promise<void> {
        let newModels = await this.dataSource.getById(null, models.map(m => m.id))
        await this.internalSelect(newModels);
    }

    private internalSelect(newModels: IDataSourceModel<string | number, any, string | number, any>[]) : Promise<void> {
        if(this.params.RootNode) {
            let root = this.Children()[0];
            root.HasLoadedChildren(false);
        }
        return this.internalSelectInList(newModels, this.Children());
    }

    private async internalSelectInList(models:  IDataSourceModel<string | number, any, string | number, any>[], list: TreeNode[]) : Promise<void> {
        for(let item of list) {
            if(models.filter(m => this.dataSource.areEqual(item.getModel(), m)).length > 0) {
                console.log("Model to select found: ", item);
                item.Select();
            }

            if(!item.HasLoadedChildren()) {
                console.log("Node children not loaded, loading...");
                await item.LoadChildren();
            }
            
            await this.internalSelectInList(models, item.Children());
        }
    }

    navigateTo(...history: IDataSourceModel<string | number, any, string | number, any>[]): void {
        
    }
}

ko.components.register('tree', {
    viewModel: {
        createViewModel: (params : ITreeComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            let vm = new TreeComponent(params);
            return vm;
        }
    },
    template:  `<div class="jstree jstree-1 jstree-default" role="tree">
                    <ul class="jstree-container-ul" data-bind="template: { name: 'treeNode', templateUrl: 'ProlifeSdk/Templates/Controls', foreach: Children }">
                        
                    </ul>
                </div>`
});