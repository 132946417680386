import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Document } from "./Document";
import { RelatedDocumentInfo } from "./RelatedDocumentInfo";
import { IPaymentMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { IInvoiceSchedule } from "../../../ProlifeSdk/interfaces/invoice/IInvoiceSchedule";

export function DocumentRelatedDocumentReadOnly() {
    let doc: Document;
    let row: RelatedDocumentInfo;

    return  <div class="invoice-additional-data" data-bind={{ if: doc.ShowImportedDocumentsTableOnPrintedDocument }}>
                <table class="table table-condensed table-advance related-documents-block">
                    <thead class="related-documents-block-head">
                    <tr>
                        <th>{TextResources.Invoices.DocumentRelatedDocuments}</th>
                    </tr>
                    </thead>
                    <tbody class="related-documents-block-body">
                    <tr data-bind={{ visible: doc.RelatedDocuments().length == 0 }}>
                        <td class="text-center">{TextResources.Invoices.DocumentNoRelatedDocument}</td>
                    </tr>
                    <tr>
                        <td>
                            <ko-bind data-bind={{ if: doc.RelatedDocuments().length > 0 }}>
                            <table class="table table-condensed related-documents-table">
                                <thead>
                                <tr>
                                    <th class="doc-number">{TextResources.Invoices.DocumentRelatedDocumentsNumber}</th>
                                    <th class="text-right doc-date">{TextResources.Invoices.DocumentRelatedDocumentsDate}</th>
                                    <th class="text-right doc-type">{TextResources.Invoices.DocumentRelatedDocumentsType}</th>
                                    <th class="text-right rows-numbers">{TextResources.Invoices.DocumentRelatedDocumentsRowNumbers}</th>
                                    <th class="text-right cig">{TextResources.Invoices.DocumentRelatedDocumentsCIG}</th>
                                    <th class="text-right cup">{TextResources.Invoices.DocumentRelatedDocumentsCUP}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ko-bind data-bind={{ foreach: { data: doc.RelatedDocuments, as: 'row' }}}>
                                <tr>
                                    <td>
                                        <a data-bind={{ click: row.openDocument }} class="document-link">
                                            <span data-bind={{ text: row.DocumentNumber }}></span>
                                        </a>
                                    </td>
                                    <td class="text-right" data-bind={{ dateText: row.DocumentDate, format: 'L' }}></td>
                                    <td class="text-right" data-bind={{ text: row.DocumentTypeLabel }}></td>
                                    <td class="text-right" data-bind={{ text: (!row.RowsIndices() ? 'N/A' : row.RowsIndices()) }}></td>
                                    <td class="text-right" data-bind={{ text: (!row.CIG() ? 'N/A' : row.CIG()) }}></td>
                                    <td class="text-right" data-bind={{ text: (!row.CUP() ? 'N/A' : row.CUP()) }}></td>
                                </tr>
                                </ko-bind>
                                </tbody>
                            </table>
                            </ko-bind>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>;
}

export function DocumentRelatedDocumentNonReadOnly() {
    let doc: Document;
    let row: RelatedDocumentInfo;

    return  <div class="invoice-additional-data">
                <table class="table table-condensed table-advance related-documents-block">
                    <thead class="related-documents-block-head">
                        <tr>
                            <th>
                                <label class="show-table-on-print">
                                    <div class="checker">
                                        <span data-bind={{ css: { checked: doc.ShowImportedDocumentsTableOnPrintedDocument } }}>
                                            <input type="checkbox" data-bind={{ checked: doc.ShowImportedDocumentsTableOnPrintedDocument, tooltip: {} }} title={TextResources.Invoices.DocumentShowImportedDocumentsTable}></input>
                                        </span>
                                    </div> {TextResources.Invoices.DocumentRelatedDocuments}
                                </label>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-primary btn-xs" data-bind={{ click: doc.getRelatedDocumentsFromRefDocumentRows }}>
                                        <i class="fa fa-database"></i>
                                        {TextResources.Invoices.DocumentRelatedDocumentsLoadImportedDocuments}
                                    </button>
                                    <button type="button" class="btn btn-primary btn-xs" data-bind={{ click: doc.addRelatedDocument }}>
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="related-documents-block-body">
                        <tr>
                            <td>
                                <table class="table table-condensed related-documents-table">
                                    <thead>
                                        <tr>
                                            <th class="doc-number">{TextResources.Invoices.DocumentRelatedDocumentsNumber}</th>
                                            <th class="text-right doc-date">{TextResources.Invoices.DocumentRelatedDocumentsDate}</th>
                                            <th class="text-right doc-type">{TextResources.Invoices.DocumentRelatedDocumentsType}</th>
                                            <th class="text-right rows-numbers" style="position: relative;">
                                                {TextResources.Invoices.DocumentRelatedDocumentsRowNumbers}
                                                <i class="fa fa-question-circle" data-bind={{ tooltip: { placement: 'right' } }} title={TextResources.Invoices.DocumentRelatedDocumentsRowNumbersTooltip}></i>
                                            </th>
                                            <th class="text-right cig">{TextResources.Invoices.DocumentRelatedDocumentsCIG}</th>
                                            <th class="text-right cup">{TextResources.Invoices.DocumentRelatedDocumentsCUP}</th>
                                            <th class="text-right actions"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr data-bind={{ visible: doc.RelatedDocuments().length == 0 }}>
                                            <td class="text-center" colSpan={7}>{TextResources.Invoices.DocumentRelatedDocumentsNoDocument}</td>
                                        </tr>
                                        <ko-bind data-bind={{ foreach: { data: doc.RelatedDocuments, as: 'row' }}}>
                                        <tr>
                                            <td>
                                                <input autoComplete="off" type="text" class="control-group" placeholder={TextResources.Invoices.DocumentRelatedDocumentsNumber} data-bind={{ value: row.DocumentNumber }} />
                                            </td>
                                            <td class="text-right">
                                                <input autoComplete="off" type="text" class="control-group text-right" placeholder={TextResources.Invoices.DocumentRelatedDocumentsDate} data-bind={{ datePicker: row.DocumentDate }} />
                                            </td>
                                            <td class="text-right">
                                                <select class="control-group text-right" data-bind={{ options: row.DocumentsTypesList, value: row.DocumentType, optionsValue: 'Type', optionsText: 'Label' }}></select>
                                            </td>
                                            <td class="text-right">
                                                <input autoComplete="off" type="text" class="control-group text-right" placeholder={TextResources.Invoices.DocumentRelatedDocumentsRowNumbers} data-bind={{ value: row.RowsIndices }} />
                                            </td>
                                            <td class="text-right">
                                                <ko-bind data-bind={{ if: row.ShowCIGAndCUP }}>
                                                    <input autoComplete="off" type="text" class="control-group text-right" placeholder={TextResources.Invoices.DocumentRelatedDocumentsCIG} data-bind={{ value: row.CIG }} />
                                                </ko-bind>
                                                <ko-bind data-bind={{ ifnot: row.ShowCIGAndCUP }}>
                                                    <span class="control-group text-right">{TextResources.Invoices.DocumentRelatedDocumentsNotAvailable}</span>
                                                </ko-bind>
                                            </td>
                                            <td class="text-right">
                                                <ko-bind data-bind={{ if: row.ShowCIGAndCUP }}>
                                                    <input autoComplete="off" type="text" class="control-group text-right" placeholder={TextResources.Invoices.DocumentRelatedDocumentsCUP} data-bind={{ value: row.CUP }} />
                                                </ko-bind>
                                                <ko-bind data-bind={{ ifnot: row.ShowCIGAndCUP }}>
                                                    <span class="control-group text-right">{TextResources.Invoices.DocumentRelatedDocumentsNotAvailable}</span>
                                                </ko-bind>
                                            </td>
                                            <td class="text-right">
                                                <button type="button" class="btn btn-danger btn-xs" data-bind={{ click: row.remove }}>
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>;
}

export function DocumentRelatedDocument() {
    let doc: Document;

    return  <>
                <ko-bind data-bind={{ if: doc.ReadOnly }}>
                    <DocumentRelatedDocumentReadOnly></DocumentRelatedDocumentReadOnly>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                    <DocumentRelatedDocumentNonReadOnly></DocumentRelatedDocumentNonReadOnly>
                </ko-bind>
            </>;
}

export function DocumentNotesReadOnly() {
    let $data: Document;

    return  <div class="invoice-additional-data" data-bind={{ if: !!$data.Notes() }}>
                <table class="table table-condensed table-advance">
                    <thead>
                    <tr>
                        <th>{TextResources.Invoices.DocumentNotes}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <div data-bind={{ html: $data.Notes }}></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
}

export function DocumentNotesNonReadOnly() {
    let $data: Document;

    return  <div class="document-notes">
                <textarea 
                    placeholder={TextResources.Invoices.DocumentNotesPlaceholder} 
                    data-bind={{ htmlEditor : $data.Notes, htmlEditorTrigger: $data.NotesTrigger, htmlEditorToolbarConfig : 'supportedByReportsTools', htmlEditorOptions : { inlineMode : true, fontSize_sizes : '9pt;10pt;11pt;12pt;13pt;14pt;15pt;16pt;17pt;18pt;19pt;20pt;21pt;22pt;23pt;24pt', language : 'it', resize_dir : 'vertical', scayt_sLang: 'it_IT', height : 100 }}}></textarea>
            </div>
}

export function DocumentNotes() {
    let doc: Document;

    return  <>
                <ko-bind data-bind={{ if: doc.ReadOnly }}>
                    <DocumentNotesReadOnly></DocumentNotesReadOnly>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                    <DocumentNotesNonReadOnly></DocumentNotesNonReadOnly>
                </ko-bind>
            </>;
}

export function DocumentPaymentInfo() {
    let doc: Document;
    let payment: IPaymentMode;
    let $index: () => number;
    let schedule: IInvoiceSchedule;

    return  <ko-bind data-bind={{ if: doc.CanBeAnElectronicInvoice() }}>
                <div class="invoice-payment-info" data-bind={{ if: !!(doc.FKPaymentType() || doc.FKExpiryType()) }}>
                    <table class="table table-condensed table-advance">
                        <thead>
                        <tr>
                            <th>{TextResources.Invoices.DocumentPaymentMode}</th>
                            <th>{TextResources.Invoices.DocumentSchedules}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="payment-mode-info">
                                <ko-bind data-bind={{ with : doc.Payment, as: 'payment' }}>
                                <span class="row-span">
                                    <span style="position: relative"><input type="checkbox" data-bind={{ visible: doc.CanCopy, checked: doc.PaymentSelected }} />{TextResources.Invoices.DocumentMethod}&nbsp;</span><span data-bind={{ text: payment.Descrizione }}></span>
                                </span>
                                </ko-bind>
                                <span class="row-span">{TextResources.Invoices.DocumentPaymentLabel}&nbsp;
                                    <span data-bind={{ visible: doc.Schedules().length > 1 }}>{TextResources.Invoices.DocumentPaymentPlan}</span>
                                    <span data-bind={{ hidden: doc.Schedules().length > 1 }}>{TextResources.Invoices.DocumentSinglePayment}</span>
                                </span>
                                <span class="row-span" data-bind={{ if: !!doc.PaymentBankName() }}>
                                    {TextResources.Invoices.DocumentBank}&nbsp;<span data-bind={{ text: doc.PaymentBankName }}></span>
                                </span>
                                <span class="row-span" data-bind={{ if: doc.Payment() && doc.Payment().AssociaBanca === 2 }}>
                                    {TextResources.Invoices.DocumentIBAN}&nbsp;<span data-bind={{ text: doc.PaymentIBAN }}></span>
                                </span>
                                <span class="row-span" data-bind={{ if: doc.Payment() && doc.Payment().AssociaBanca === 1 }}>
                                    {TextResources.Invoices.DocumentABI}&nbsp;<span data-bind={{ text: doc.PaymentABI }}></span>
                                </span>
                                <span class="row-span" data-bind={{ if: doc.Payment() && doc.Payment().AssociaBanca === 1 }}>
                                    {TextResources.Invoices.DocumentCAB}&nbsp;<span data-bind={{ text: doc.PaymentCAB }}></span>
                                </span>
                            </td>
                            <td>
                                <div data-bind={{ if: !!doc.FKExpiryType() || !!doc.ExpiryType() }}>
                                    <span style="position: relative">
                                        <input type="checkbox" data-bind={{ visible: doc.CanCopy, checked: doc.ExpirySelected }} />
                                        {TextResources.Invoices.DocumentExpiry}&nbsp;
                                    </span>
                                    <span data-bind={{ text: doc.ExpiryType }}></span>
                                    <ko-bind data-bind={{ if : doc.Schedules().length > 0 }}>
                                    <table class="table table-condensed schedules-table">
                                        <thead>
                                            <tr>
                                                <th class="schedule-number">{TextResources.Invoices.DocumentTrancheNumber}</th>
                                                <th class="schedule-description text-right">{TextResources.Invoices.DocumentTrancheDescription}</th>
                                                <th class="schedule-date text-right">{TextResources.Invoices.DocumentTrancheDueDate}</th>
                                                <th class="schedule-total text-right">{TextResources.Invoices.DocumentTrancheTotal}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ko-bind data-bind={{ foreach : { data: doc.Schedules, as: 'schedule' }}}>
                                            <tr>
                                                <td data-bind={{ text: ($index() + 1) }}></td>
                                                <td class="text-right" data-bind={{ text: schedule.Description }}></td>
                                                <td class="text-right" data-bind={{ dateText: schedule.ScheduleDate }}></td>
                                                <td class="text-right"><span data-bind={{ moneyText: schedule.Total, currencySymbol: doc.DocumentCurrencySymbol }}></span></td>
                                            </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                    </ko-bind>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ko-bind>
}

export function DocumentAdditionaInformationReadOnly() {
    let doc: Document;
    let $data: any;

    return  <ko-bind data-bind={{ let: { doc: $data }}}>
                <DocumentPaymentInfo></DocumentPaymentInfo>
                <ko-bind data-bind={{ if: doc.CanHaveRelatedDocuments }}>
                    <DocumentRelatedDocumentReadOnly></DocumentRelatedDocumentReadOnly>
                </ko-bind>
            </ko-bind>;
}

export function DocumentAdditionaInformationNonReadOnly() {
    let doc: Document;
    let $data: any;

    return  <ko-bind data-bind={{ let: { doc: $data }}}>
                <ko-bind data-bind={{ if: doc.CanHaveRelatedDocuments }}>
                    <DocumentRelatedDocumentNonReadOnly></DocumentRelatedDocumentNonReadOnly>
                </ko-bind>
            </ko-bind>;
}