import { isArray, isDate, isObject, mapKeys, upperFirst } from "lodash";

export function toPascalCase(obj): any {
    if (isArray(obj)) return obj.map((item) => toPascalCase(item));

    const result = mapKeys(obj, (value, key) => {
        return upperFirst(key);
    });

    for (const key in result) {
        if (isDate(result[key])) continue;

        if (isArray(result[key])) {
            result[key] = result[key].map((item) => {
                return toPascalCase(item);
            });
        } else if (isObject(result[key])) {
            result[key] = toPascalCase(result[key]);
        }
    }

    return result;
}
