import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import {
    ICustomersService,
    MetadataLogicalType,
    MetadataLogicalTypeSource,
} from "../ProlifeSdk/interfaces/customer/ICustomersService";

export type MailRecipientTypeDataSourceModel = IDataSourceModel<number, MetadataLogicalType>;

export class MailRecipientTypesDataSource extends BaseDataSource<
    MailRecipientTypeDataSourceModel,
    number,
    MetadataLogicalType
> {
    private allowFixedAddress = false;
    private dataSources = [MetadataLogicalTypeSource.All];

    public static FIXED_ADDRESS = 1;
    public static ADDRESS_TYPE = 2;
    public static METADATA_MAIL_CATEGORY = 3;

    @LazyImport(nameof<ICustomersService>())
    private customerService: ICustomersService;

    setAllowFixedAddress(enabled: boolean) {
        this.allowFixedAddress = enabled;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isGroupedData(currentModel: MailRecipientTypeDataSourceModel, textFilter: string): boolean {
        return this.allowFixedAddress;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTitle(currentModel: IDataSourceModel): string {
        return TextResources.ProlifeSdk.MailRecipientTypes;
    }

    public async getData(
        currentModel: MailRecipientTypeDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<MailRecipientTypeDataSourceModel[]> {
        if (this.allowFixedAddress) {
            if (this.isOnRoot(currentModel)) {
                if (this.isNotSearching(textFilter))
                    return [
                        this.createGroupModel(
                            MailRecipientTypesDataSource.ADDRESS_TYPE,
                            TextResources.ProlifeSdk.MailRecipientType
                        ),
                    ];
                return [
                    this.createGroupModel(
                        MailRecipientTypesDataSource.FIXED_ADDRESS,
                        TextResources.ProlifeSdk.FixedAddress
                    ),
                    this.createGroupModel(
                        MailRecipientTypesDataSource.ADDRESS_TYPE,
                        TextResources.ProlifeSdk.MailRecipientType
                    ),
                ];
            }

            if (currentModel.isGroup && currentModel.id == MailRecipientTypesDataSource.FIXED_ADDRESS) {
                return [this.createFixedAddressModel(textFilter)];
            } else if (currentModel.isGroup && currentModel.id == MailRecipientTypesDataSource.ADDRESS_TYPE) {
                return await this.searchAndCreateMetadataLogicalTypes(textFilter, skip, count);
            } else {
                return [];
            }
        } else {
            return await this.searchAndCreateMetadataLogicalTypes(textFilter, skip, count);
        }
    }

    private async searchAndCreateMetadataLogicalTypes(textFilter: string, skip: number, count: number) {
        const recipientTypes = await this.customerService.searchMetadataLogicalTypes(
            MailRecipientTypesDataSource.METADATA_MAIL_CATEGORY,
            textFilter,
            this.dataSources,
            skip,
            count
        );
        return recipientTypes.map((r) => this.createRecipientTypeDataSourceModel(r));
    }

    private isNotSearching(textFilter: string) {
        return !textFilter;
    }

    private isOnRoot(currentModel: MailRecipientTypeDataSourceModel) {
        return !currentModel;
    }

    public async getById(
        _: MailRecipientTypeDataSourceModel,
        ids: (number | string)[]
    ): Promise<MailRecipientTypeDataSourceModel[]> {
        const fixedAddressIds = this.extractFixedAddressIds(ids);
        const addressTypesIds = this.extractLogicalTypesIds(ids);

        const fixedAddress = this.createFixedAddressModels(fixedAddressIds);
        const addressTypes =
            addressTypesIds.length > 0 ? await this.customerService.getMetadataLogicalTypes(addressTypesIds) : [];

        const recipientTypes = fixedAddress.concat(addressTypes);
        return recipientTypes.map(this.createRecipientTypeDataSourceModel, this);
    }

    private extractFixedAddressIds(ids: (string | number)[]) {
        return ids.filter((id) => typeof id === "string") as string[];
    }

    private extractLogicalTypesIds(ids: (string | number)[]) {
        return ids.filter((id) => typeof id !== "string") as number[];
    }

    private createFixedAddressModels(fixedAddressIds: string[]) {
        return fixedAddressIds.map((id) => ({
            id: id as unknown as number,
            label: id,
        }));
    }

    private createRecipientTypeDataSourceModel(recipientType: MetadataLogicalType): MailRecipientTypeDataSourceModel {
        return {
            id: recipientType.id,
            isGroup: false,
            isLeaf: true,
            title: recipientType.label,
            model: recipientType,
        };
    }

    private createGroupModel(id: number, label: string): MailRecipientTypeDataSourceModel {
        return {
            id: id,
            isGroup: true,
            isLeaf: false,
            title: label,
        };
    }

    private createFixedAddressModel(label: string): MailRecipientTypeDataSourceModel {
        return {
            id: label as unknown as number,
            isGroup: false,
            isLeaf: true,
            title: label,
        };
    }

    public clearDataSources(): void {
        this.dataSources = [];
    }

    public addDataSource(dataSource: MetadataLogicalTypeSource): void {
        this.dataSources.push(dataSource);
    }
}
