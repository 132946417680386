import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { DDTAppearancesEditingViewModel } from "./ui/DDTAppearancesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDDTAppearances, IDDTAppearance, IDDTAppearancesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IDDTAppearances";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class DDTAppearanceSettingsManager implements IDDTAppearances {
    private ajaxService : IAjaxService;
    private appearances : IDDTAppearance[];
    private observers : IDDTAppearancesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer : IDDTAppearancesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IDDTAppearancesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IDDTAppearance[]> {
        return this.ajaxService.Get<IDDTAppearance[]>("Invoices-api", "DDTAppearance", {})
            .then((appearances) => this.appearances = appearances);
    }

    getName(): string {
        return ProlifeSdk.DDTAppearance;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.DeliveryLookAndFeel;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "setting",
            templateUrl: "settings/templates",
            viewModel: new DDTAppearancesEditingViewModel(this.serviceLocator, this)
        };
    }

    getDDTAppearances() : IDDTAppearance[] {
        return this.appearances;
    }

    getDDTAppearanceIdByName(ddtAppearanceName : string) : number {
        var appearancesWithName = this.appearances.filter((appearance : IDDTAppearance) => appearance.Descrizione == ddtAppearanceName);
        if(appearancesWithName.length == 0) return 0;
        return appearancesWithName[0].Id;
    }

    createOrUpdate(appearance : IDDTAppearance) : Promise<IDDTAppearance> {
        if(appearance.Id > 0) {
            return this.ajaxService.Put<IDDTAppearance>("Invoices-api", "DDTAppearance", { methodData: appearance})
                .then(this.onDDTAppearanceChanged.bind(this));
        }

        return this.ajaxService.Post<IDDTAppearance>("Invoices-api", "DDTAppearance", { methodData: appearance})
            .then(this.onDDTAppearanceAdded.bind(this));
    }

    remove(appearanceId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Invoices-api", "DDTAppearance/" + appearanceId, {})
            .then(this.onDDTAppearanceDeleted.bind(this, appearanceId));
    }

    private onDDTAppearanceChanged(appearance : IDDTAppearance) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTAppearanceChanged(appearance));
    }

    private onDDTAppearanceAdded(appearance : IDDTAppearance) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTAppearanceAdded(appearance));
    }

    private onDDTAppearanceDeleted(appearanceId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTAppearanceDeleted(appearanceId));
    }
}