import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { LazyImport } from "../../Core/DependencyInjection";
import { ITodoListService, IUserMilestone } from "../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { UserMilestonesDialogUI } from "./UserMilestonesDialog";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import {
    IChangesNotificationsService,
    IChangesNotificationsServiceObserver,
    IObjectChangesInfo,
} from "../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

const styleSheet = jss.createStyleSheet({
    userMilestones: {
        margin: "0px",
        padding: "17px 10px 8px 10px",
        minWidth: "48px",

        "& > i": {
            color: "#999999",
            fontSize: "17px",
        },

        "& .badge": {
            right: "12px !important",
        },
    },
});
const { classes } = styleSheet.attach();

export class UserMilestonesUI {
    constructor() {}

    render() {
        return <UserMilestones />;
    }
}

export function UserMilestones() {
    const C = require("./UserMilestonesComponent")._UserMilestones as typeof _UserMilestones;
    return <C />;
}

export class _UserMilestones implements IChangesNotificationsServiceObserver {
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    public Milestones: ko.ObservableArray<IUserMilestone> = ko.observableArray([]);
    public Tooltip: ko.Observable<string> = ko.observable(TextResources.Todolist.UserMilestonesTooltip);
    public MilestonesNumber: ko.Computed<number>;

    constructor() {
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);

        this.MilestonesNumber = ko.computed(() => {
            return this.Milestones().length;
        });
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        this.loadMilestones();
        return false;
    }

    componentDidMount() {
        this.loadMilestones();
    }

    private async loadMilestones(): Promise<void> {
        try {
            const milestones = await this.todoListService.GetUserMilestones(null, null);
            this.Milestones(milestones);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        this.MilestonesNumber.dispose();
    }

    private async showMilestonesDialog(): Promise<void> {
        const modal: UserMilestonesDialogUI = new UserMilestonesDialogUI({ milestones: this.Milestones });
        return this.dialogsService.ShowModal(modal);
    }

    render() {
        const userMilestones = this;
        const classNames = ComponentUtils.classNames(classes.userMilestones, "dropdown-toggle");

        return ComponentUtils.bindTo(
            <a
                href="javascript:void(0)"
                className={classNames}
                data-bind={{ click: userMilestones.showMilestonesDialog.bind(userMilestones), attr: { title: userMilestones.Tooltip } }}
            >
                <i className="fa fa-star"></i>
                <span
                    className="badge badge-default"
                    data-bind={{ text: userMilestones.MilestonesNumber, visible: userMilestones.MilestonesNumber() > 0 }}
                ></span>
            </a>,
            this,
            "userMilestones"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(UserMilestones);
}
