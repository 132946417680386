import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ICustomer, ICustomerRolePrice } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrderRolesPrices } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";

export class CustomerRolesPrices {
    public settingsService : ISettingsService;

    public customRoles : ko.Observable<boolean> = ko.observable(false);
    public customMaterialsRoles : ko.Observable<boolean> = ko.observable(false);

    public RolesPrices : ko.ObservableArray<RolePrice> = ko.observableArray([]);
    public MaterialsRolesPrices : ko.ObservableArray<RolePrice> = ko.observableArray([]);
    public EnabledRolesPrices : ko.Computed<RolePrice[]>;
    public EnabledMaterialsRolesPrices : ko.Computed<RolePrice[]>;
    public editable : ko.Observable<boolean> = ko.observable(false);

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager: IUserCharactersSettingsManager;

    constructor() {
        this.EnabledRolesPrices = ko.computed(() => {
            return this.RolesPrices().filter((r : RolePrice) => { return r.enabled(); })
        });

        this.EnabledMaterialsRolesPrices = ko.computed(() => {
            return this.MaterialsRolesPrices().filter((r : RolePrice) => { return r.enabled(); })
        });
    }

    public setEditMode(edit : boolean)
    {
        this.editable(edit);
    }

    public initialize(customer : ICustomer){
        let userCharacters = this.userCharactersSettingsManager.getUserCharacters(0);

        this.RolesPrices(customer ? customer.RolesPrices
            .filter((r: ICustomerRolePrice) => { return userCharacters.filter((ch: IUserCharacter) => ch.IdUserCharacter == r.FkUserCharacterId).length > 0 })
            .map((p : ICustomerRolePrice) => { return new RolePrice(p, true) } ) : []);

        userCharacters.forEach((userCharacter: IUserCharacter) => {
            var filters : ICustomerRolePrice[] = customer.RolesPrices.filter((costo : ICustomerRolePrice) => costo.FkUserCharacterId == userCharacter.IdUserCharacter);
            if (filters.length == 0)
                this.RolesPrices.push(new RolePrice({Description: userCharacter.Description, Salary:userCharacter.Salary, FkUserCharacterId:userCharacter.IdUserCharacter}, false));
        });

        userCharacters = this.userCharactersSettingsManager.getUserCharacters(1);

        this.MaterialsRolesPrices(customer ? customer.RolesPrices
            .filter((r: ICustomerRolePrice) => { return userCharacters.filter((ch: IUserCharacter) => ch.IdUserCharacter == r.FkUserCharacterId).length > 0 })
            .map((p : ICustomerRolePrice) => { return new RolePrice(p, true) } ) : []);
        
        userCharacters.forEach((userCharacter: IUserCharacter) => {
            var filters : ICustomerRolePrice[] = customer.RolesPrices.filter((costo : ICustomerRolePrice) => costo.FkUserCharacterId == userCharacter.IdUserCharacter);
            if (filters.length == 0)
                this.MaterialsRolesPrices.push(new RolePrice({Description: userCharacter.Description, Salary:userCharacter.Salary, FkUserCharacterId:userCharacter.IdUserCharacter}, false));
        });

        this.customRoles(customer ? customer.CustomRoles : false);
        this.customMaterialsRoles(customer ? customer.CustomMaterialsRoles : false);
    }

    public setDataOnCustomer(customer : ICustomer){
        customer.CustomRoles = this.customRoles();
        customer.RolesPrices = this.EnabledRolesPrices()
            .filter((r : RolePrice) => { return this.customRoles(); })
            .map((r : RolePrice) => { return r.getData(); });

        customer.CustomMaterialsRoles = this.customMaterialsRoles();
        customer.RolesPrices = customer.RolesPrices.concat(this.EnabledMaterialsRolesPrices()
            .filter((r : RolePrice) => { return this.customMaterialsRoles(); })
            .map((r : RolePrice) => { return r.getData(); }));
    }
}

export class RolePrice{

    public Salary : ko.Observable<number> = ko.observable(0);
    public enabled : ko.Observable<boolean> = ko.observable(false);

    constructor(private costo : ICustomerRolePrice, enabled : boolean){
        this.load(costo);
        this.enabled(enabled);
    }

    load(costo : IJobOrderRolesPrices) : void{
        this.Salary(costo.Salary);
    }

    getData() : ICustomerRolePrice{
        var costo : ICustomerRolePrice = <ICustomerRolePrice> $.extend({}, this.costo);

        costo.Salary = this.Salary();
        return costo;
    }
}
