import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";

export class TaskDataChangeEvent extends BlogEvent implements IView
{
    private humanResourcesService : IHumanResourcesSettingsManager;
    private todoListService : ITodoListService;

    public Title : ko.Observable<string> = ko.observable();
    public UserId : ko.Observable<number> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public DueDate : ko.Observable<Date> = ko.observable();
    public Resources : ko.Observable<string> = ko.observable();

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Updatable(false);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.iconClass = "fa-tasks";
        this.eventTypeName = ProlifeSdk.TextResources.Todolist.Task;
        this.templateName = "task-data-change-event";
        this.templateUrl = "todolist/templates/events";
        this.isDeleteLocked(true);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    public GetMailSubject() : string
    {
        return ProlifeSdk.TextResources.Todolist.TaskDataChangeMailSubject;
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {

            switch(item.TagName()) {
                case ProlifeSdk.Tag_TaskDataChange_Title:
                    this.Title(item.Value());
                    break;

                case ProlifeSdk.Tag_TaskDataChange_StartDate:
                    this.StartDate(moment(item.Value()).toDate());
                    break;

                case ProlifeSdk.Tag_TaskDataChange_DueDate:
                    this.DueDate(moment(item.Value()).toDate());
                    break;

                case ProlifeSdk.Tag_TaskDataChange_Resources:
                    this.Resources(item.Value());
                    break;
            }
        });
    }
}