import { LazyImport } from "../Core/DependencyInjection";
import { IEntityProviderService } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TagsDataSourceUtils } from "./Utils/TagsDataSourceUtils";
import { IEntityProvider } from "../ProlifeSdk/interfaces/IEntityProvider";
import { ITag } from "../ProlifeSdk/interfaces/ITag";

type PromisesPerProvider = {
    provider: IEntityProvider;
    promises: Promise<any>[];
}

type PromisesPerProviderMap = { [type: string]: PromisesPerProvider };

export class CustomTagsDataSource extends BaseDataSource<IDataSourceModel> {
    private freeTextEnabled: boolean = false;

    @LazyImport(nameof<IEntityProviderService>())
	private entityProviderService: IEntityProviderService;

    private enabledProviders: string[] = []; // Array vuoto equivale a tutto abilitato

    getTitle(currentModel: IDataSourceModel): string {
        return "";
    }

    async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDataSourceModel[]> {
        if (!currentModel) {
            let results = [];
            if (!!textFilter && this.freeTextEnabled)
                results.push(TagsDataSourceUtils.createFreeTextModel(textFilter));

            return results.concat(this.getGroups());
        }

        const provider = this.findProvider(currentModel.id as string);
        if (!provider)
            return [];

        const entities = await provider.hintSearch(textFilter);
        return entities.map(e => this.createDataSourceModel(provider, e));
    }
    
    private findProvider(providerType: string): IEntityProvider {
        return this.getEnabledProviders().firstOrDefault(p => p.getType() === providerType)
    }
    
    private createDataSourceModel(provider: IEntityProvider, resource: any): IDataSourceModel {
        return {
            id: this.createModelId(provider, resource),
            title: provider.getDisplayName(resource),
            isGroup: false,
            isLeaf: true,
            model: resource
        };
    }

    private createModelId(provider: IEntityProvider, item: any): string {
        const tag = {
            TagName: "",
            TagTypeId: provider.getType(),
            Value: provider.getPkValue(item)
        };

        return JSON.stringify(tag) + '#' + provider.getDisplayName(item);
    }

    private getGroups(): IDataSourceModel[] {
        const entityProviders = this.getEnabledProviders();
        return entityProviders.map(p => this.createGroup(p.getType(), p.getEntityTypeName()));
    }

    private getEnabledProviders(): IEntityProvider[] {
        return this.entityProviderService
            .getRegisteredEntityProviders()
            .filter(p => this.enabledProviders.length === 0 || this.enabledProviders.indexOf(p.getType()) >= 0);
    }

    private createGroup(id: string, title: string): IDataSourceModel {
        return {
            id: id,
            title: title,
            isGroup: true,
            isLeaf: false
        };
    }

    async getById(currentModel: IDataSourceModel, ids: string[]): Promise<IDataSourceModel[]> {
        const serializedTagAndDisplayName = ids.selectMultiple(i => i.split('|'));
        
        let promisesPerProviderMap: PromisesPerProviderMap = {};
        
        for (const tagAndDisplayName of serializedTagAndDisplayName) {
            const [serializedTag] = tagAndDisplayName.split('#');
            const tag = JSON.parse(serializedTag) as ITag;
            let promisesPerProvider = promisesPerProviderMap[tag.TagTypeId];
            if (!promisesPerProvider) {
                promisesPerProvider = { provider: this.findProvider(tag.TagTypeId), promises: [] }
                promisesPerProviderMap[tag.TagTypeId] = promisesPerProvider;
            }

            const promise = promisesPerProvider.provider.getEntity(tag.Value);
            promisesPerProvider.promises.push(promise);
        }

        let models = [];

        for (const type in promisesPerProviderMap) {
            if (Object.prototype.hasOwnProperty.call(promisesPerProviderMap, type)) {
                const promisesPerProvider = promisesPerProviderMap[type];
                const entities = await Promise.all(promisesPerProvider.promises);
                models = models.concat(entities.map(e => this.createDataSourceModel(promisesPerProvider.provider, e)));
            }
        }

        return models;
    }

    setFreeTextEnabled(value: boolean): void {
        this.freeTextEnabled = value;
    }

    setEnabledProviders(...providerType: string[]): void {
        this.enabledProviders = providerType;
    }

    isGroupedData(currentModel: IDataSourceModel): boolean {
        return !currentModel;
    }
}