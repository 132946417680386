import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { ICustomersService, ILetterOfAttemptInvoice } from "../ProlifeSdk/interfaces/customer/ICustomersService";

export class LetterOfAttemptsInvoicesDataSource implements IDataSource {
    private letterOfAttemptId: number = null;
    private view: IDataSourceView = null;

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;

    constructor() {

    }
    
    public getTitle(currentModel: IDataSourceModel<number, ILetterOfAttemptInvoice>): string {
        return ProlifeSdk.TextResources.Customers.LetterOfAttemptInvoices;
    }
    
    public async getData(currentModel: IDataSourceModel<number, ILetterOfAttemptInvoice>, textFilter: string, skip: number = 0, count: number = 1000000): Promise<IDataSourceModel<number, ILetterOfAttemptInvoice>[]> {
        let invoices = await this.customersService.GetLetterOfAttemptInvoices(this.letterOfAttemptId, skip, count);
        return invoices.map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<number, ILetterOfAttemptInvoice>, ids: number[]): Promise<IDataSourceModel<number, ILetterOfAttemptInvoice>[]> {
        let invoices = await this.customersService.GetLetterOfAttemptInvoicesByIds(ids);
        return invoices.map(this.createModel, this);
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setLetterOfAttemptId(letterOfAttemptId: number): void {
        this.letterOfAttemptId = letterOfAttemptId;
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public isGroupedData(currentModel: IDataSourceModel<number, ILetterOfAttemptInvoice>, textFilter: string): boolean {
        return false;
    }

    public areEqual(a: IDataSourceModel<number, ILetterOfAttemptInvoice>, b: IDataSourceModel<number, ILetterOfAttemptInvoice>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModel(invoice: ILetterOfAttemptInvoice): IDataSourceModel<number, ILetterOfAttemptInvoice> {
        return {
            id: invoice.InvoiceId,
            title: String.format(ProlifeSdk.TextResources.Customers.LetterOfAttemptInvoiceName, invoice.InvoiceNumber, moment(invoice.InvoiceDate).format("L"), invoice.ProtocolName),
            isGroup: false,
            isLeaf: true,
            model: invoice
        }
    }
}