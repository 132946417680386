import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/11/2017
 * Time: 10:26
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { SalEvent } from "../SalEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class SalEventDetailsEdit extends DocumentEventDetailsEdit {
    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver, event: SalEvent) {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "fa-file-text";
        this.title = ProlifeSdk.TextResources.JobOrder.SAL;
    }

    public setValues(item: SalEvent): void {
        super.setValues(item);
        item.LoadDetails();
    }

    public getBlogEvent(): ILogEvent {
        var event: ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.SalEventType;
        return event;
    }
}