import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IStatisticFamily, IFamiliesService } from "../ProlifeSdk/interfaces/warehouse/IFamiliesService";

export interface IStatisticFamilyDataSourceModel extends IDataSourceModel<number, IStatisticFamily> {}

export class StatisticsFamiliesDataSource implements IDataSource {
    @LazyImport(nameof<IFamiliesService>())
    private familiesService: IFamiliesService;
    private manufacturerId: number;

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.StatisticFamilies;
    }

    isGroupedData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string
    ): boolean {
        return false;
    }

    areEqual(
        a: IDataSourceModel<string | number, any, string | number, any>,
        b: IDataSourceModel<string | number, any, string | number, any>
    ): boolean {
        return (!a && !b) || (!!a && !!b && a.id == b.id);
    }

    setManufacturerId(manufacturerId: number) {
        this.manufacturerId = manufacturerId;
    }

    async getData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        const families = await this.familiesService.GetStatisticFamilies(this.manufacturerId, textFilter, skip, count);
        return families.map(this.createModelFor, this);
    }

    async getById(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        ids: number[]
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        const families = await this.familiesService.GetStatisticFamiliesByIds(ids);
        return families.map(this.createModelFor, this);
    }

    private createModelFor(family: IStatisticFamily): IStatisticFamilyDataSourceModel {
        return {
            id: family.Id,
            title: family.Name,
            subTitle: family.Description,
            isLeaf: true,
            isGroup: false,
            model: family,
        };
    }

    setView(view: IDataSourceView): void {}

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
