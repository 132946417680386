import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 14/03/13
 * Time: 10.48
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IWarehousesService,
    IWarehousesProvider,
    IWarehouse,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export class WarehousesProvider {
    private warehousesLastTimeout: ReturnType<typeof setTimeout>;

    public showOnlyJobOrderWarehouses: boolean = false;
    public jobOrderId: number = null;

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;

    constructor(target: IWarehousesProvider) {
        target.findWarehouses = this.findWarehouses.bind(this);
        target.findWarehouse = this.findWarehouse.bind(this);
    }

    private findWarehouses(customerId: number, query: any) {
        if (this.warehousesLastTimeout) {
            clearTimeout(this.warehousesLastTimeout);
        }

        this.warehousesLastTimeout = setTimeout(() => {
            this.warehousesService
                .getWarehouses(query.term, 0, 100, [customerId], false, false, this.showOnlyJobOrderWarehouses, [
                    this.jobOrderId,
                ])
                .then((data: IWarehouse[]) => {
                    query.callback({
                        results: data.map((warehouse: IWarehouse) => {
                            return {
                                id: warehouse.Id,
                                text: warehouse.Name,
                            };
                        }),
                    });
                })
                .catch(() => {
                    query.callback({ results: [] });
                });
        }, 500);
    }

    private findWarehouse(element, callback) {
        var id = $(element).val();
        if (id !== "") {
            this.warehousesService.getWarehouseById(<number>id).then((warehouse: IWarehouse) => {
                callback({
                    id: warehouse.Id,
                    text: warehouse.Name,
                });
            });
        }
    }
}
