import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { IFullTicketReplies, IFullTicketRepliesAttachments } from "../../TicketsService";

export class TicketReply {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    validator: IValidator<TicketReply>;

    public Id: number;
    public FkTicket: number;
    public CreationDate: Date;
    public FkCreationUser: number;
    public CreationUserFullName: string;
    public LastEditDate: Date;
    public FkLastEditUser: number;
    public LastEditUserFullName: string;
    public IsPrivate: boolean;

    public Text: ko.Observable<string> = ko.observable();
    public TextTrigger: ko.Observable<any> = ko.observable();

    public Attachments: ko.ObservableArray<string> = ko.observableArray();

    public ReadOnly: ko.Observable<boolean> = ko.observable(true);

    public Author: ko.Observable<string> = ko.observable();

    public Date: ko.Observable<Date> = ko.observable();

    constructor(item: IFullTicketReplies = null, attachments: IFullTicketRepliesAttachments[] = null ){
        if (item){
            this.Id = item.Id;
            this.FkTicket = item.FkTicket;
            this.CreationDate = item.CreationDate;
            this.FkCreationUser = item.FkCreationUser;
            this.CreationUserFullName = item.CreationUserFullName;
            this.LastEditDate = item.LastEditDate;
            this.FkLastEditUser = item.FkLastEditUser;
            this.LastEditUserFullName = item.LastEditUserFullName;
            this.IsPrivate = item.IsPrivate;
            this.Author(item.Author);
            this.Date(item.Date);

            this.Text(item.Text);

            if (attachments) {
                this.Attachments(attachments.map(a => a.AttachmentGUID));
            }
        }

        this.validator = this.validationService.createValidator<TicketReply>()
            .isNotNullOrUndefinedOrWhiteSpace(r => r.Author(), TextResources.Tickets.ErrorNoAuthor)
            .isNotNullOrUndefined(r => r.Date(), TextResources.Tickets.ErrorNoDate)
            .isNotNullOrUndefinedOrWhiteSpace(r => r.Text(), TextResources.Tickets.ErrorNoDescription);
    }

    toggleEdit(){
        this.ReadOnly(false);
    }
}