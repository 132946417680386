import * as ko from "knockout";
import { ReferencesMapNode } from "./ReferencesMapNode";
import { IReferenceForMap } from "../../../ProlifeSdk/interfaces/invoice/IReferencesMap";

export class ReferencesMapConnector
{
    SourceNodeIdentifier : string;
    DestNodeIdentifier : string;

    constructor(public sourceId : number, public sourceEntityType : string, public destDocId : number, public destEntityType : string, allReferences : IReferenceForMap[])
    {
        this.SourceNodeIdentifier = ReferencesMapNode.GetIdentifier(sourceId, sourceEntityType);
        this.DestNodeIdentifier = ReferencesMapNode.GetIdentifier(destDocId, destEntityType);
    }
}
