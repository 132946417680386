import * as ko from "knockout";
import { NavigationMenuComponentItem } from "./NavigationMenuComponentItem";
import {
    INavigationMenuComponentGroup,
    INavigationMenuComponentItem,
    INavigationMenuComponentModel,
    INavigationMenuComponentItemFactory,
    INavigationMenuComponentDataSource,
} from "./INavigationMenuComponent";
import { NavigationMenuComponent } from "../NavigationMenuComponent";

export class NavigationMenuComponentGroup extends NavigationMenuComponentItem implements INavigationMenuComponentGroup {
    public Items: ko.ObservableArray<INavigationMenuComponentItem> = ko.observableArray([]);
    public Collapsed: ko.Observable<boolean> = ko.observable(true);
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public IsLoading: ko.Observable<boolean> = ko.observable(false);
    public HasLoadedData: ko.Observable<boolean> = ko.observable(false);

    public HasItems: ko.Computed<boolean>;

    constructor(
        model: INavigationMenuComponentModel,
        navigator: NavigationMenuComponent,
        private factory: INavigationMenuComponentItemFactory,
        protected dataSource: INavigationMenuComponentDataSource
    ) {
        super(model, navigator, dataSource);

        this.Collapsed(model.collapsed == undefined || model.collapsed == null ? true : model.collapsed);

        this.HasItems = ko.computed(() => {
            return this.Items().length > 0;
        });
    }

    public refresh(): void {
        this.Items([]);
        this.HasLoadedData(false);
        this.ShowLoadMore(true);
    }

    public async loadNextPage(): Promise<boolean> {
        if (this.IsLoading()) return false;

        this.IsLoading(true);
        this.HasLoadedData(true);
        this.ShowLoadMore(false);

        try {
            const models = await this.dataSource.getData(
                this.model,
                this.navigator.getTextFilter(),
                this.Items().length,
                this.navigator.getPageSize()
            );

            if (models.length == 0) {
                this.IsLoading(false);
                this.HasLoadedData(true);
                this.ShowLoadMore(false);
                return false;
            }

            const viewModels = models.map(this.factory.createViewModelFor, this.factory);
            this.Items(this.Items().concat(viewModels));

            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(true);

            return true;
        } catch (e) {
            console.log(e);
            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(false);
            return false;
        }
    }

    public click(): void {
        this.Collapsed(!this.Collapsed());
    }
}
