import * as ko from "knockout";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IJobOrderState } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { INavigationFilters } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class StatesNavigationProvider extends NavigationProvider
{
    public Icon: string;
    public Background: string;
    public Foreground: string;

    constructor(serviceLocator : IServiceLocator, private state : IJobOrderState)
    {
        super();

        this.Id = state.IdJobOrderStateId;
        this.Name = state.Description;
        this.Icon = state.Icon;
        this.Background = state.Background;
        this.Foreground = state.Foreground;
        this.templateName = "job-orders-folder";
        this.templateUrl = "jobOrder/templates/navigation";
        this.IsLeaf = true;
    }

    public SetFilterInfoOnModel(f : INavigationFilters)
    {
        f.State = this.state.IdJobOrderStateId;
    }
}
