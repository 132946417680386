/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/08/2017
 * Time: 11:00
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { CampaignStatesControlsEntityProvider } from "./CampaignStatesControlsEntityProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEntityProvider } from "../../../../../ProlifeSdk/interfaces/IEntityProvider";
import { ICampaignState } from "../../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ICampaignStateSettingManager } from "../../../../interfaces/ICampaignStateSettingManager";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../../Core/Deferred";

export class CampaignStatesEntityProvider implements IEntityProvider<number, ICampaignState> {
    private states: ICampaignState[];
    private statesManager: ICampaignStateSettingManager;

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService: ISettingsService = <ISettingsService>(
            serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        this.statesManager = <ICampaignStateSettingManager>(
            settingsService.findSettingsManager(ProlifeSdk.CampaignState)
        );

        this.states = this.statesManager.getCampaignStates();
    }

    getType(): string {
        return ProlifeSdk.CampaignState;
    }

    getEntityTypeName(): string {
        return ProlifeSdk.TextResources.Survey.CampaignStatus;
    }

    getPkValue(item: ICampaignState): number {
        return item.Id;
    }

    getDisplayName(item: ICampaignState): string {
        return item.Label;
    }

    getDisplayNameForKeyValue(pkValue: number): Promise<string> {
        const def = new Deferred<string>();
        const state: ICampaignState[] = this.states.filter((s: ICampaignState) => {
            s.Id == pkValue;
        });
        def.resolve(state[0].Label);
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<ICampaignState[]> {
        const def = new Deferred<ICampaignState[]>();
        const states: ICampaignState[] = this.states.filter((s: ICampaignState) => {
            return s.Label.toUpperCase().indexOf(stringFilter.trim().toUpperCase()) > -1;
        });
        def.resolve(states);
        return def.promise();
    }

    getEntity(pkValue: any): Promise<ICampaignState> {
        const def = new Deferred<ICampaignState>();
        const state: ICampaignState = this.states.filter((s: ICampaignState) => {
            return s.Id == pkValue;
        })[0];
        def.resolve(state);
        return def.promise();
    }

    getControlsProvider(): IControlsEntityProvider {
        return new CampaignStatesControlsEntityProvider(this.serviceLocator);
    }
}
