import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/03/2018
 * Time: 16:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IEventCustomersProvider, IEventsService, ICustomerEnvelope, ISelect2Customer } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

export class CustomersProvider implements IEventCustomersProvider, IControlsEntityProvider {
    @LazyImport(nameof<IEventsService>())
    private eventsService: IEventsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor() {}

    public GetCustomers(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.eventsService.GetCustomersForEventLimitedList(query.term)
                .then((customers: ICustomerEnvelope[]) => {
                    query.callback({
                        results: customers.map((c: ICustomerEnvelope) => { return this.CreateCustomerForSelect2(c); })
                    });
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetCustomersError);
                });
        }, 500);
    }

    public GetCustomer(element: HTMLElement, callback: (result: ISelect2Customer | ISelect2Customer[]) => void) {
        var customersString: string = <string>$(element).val();
        if (!customersString) {
            callback(null);
            return;
        }

        var ids: number[] = customersString.split("|").map((id: string) => parseInt(id));
        var defs: Promise<ICustomerEnvelope>[] = [];

        var customers: ICustomerEnvelope[] = [];
        var result: ISelect2Customer[] = [];

        ids.forEach((id: number) => {
            defs.push(this.eventsService.GetCustomerForEvent(id).then((c: ICustomerEnvelope) => { customers.push(c); return c; }));
        });

        Promise.all(defs)
            .then(() => {
                customers.forEach((c: ICustomerEnvelope) => {
                    result.push(this.CreateCustomerForSelect2(c));
                });
                callback(result.length === 1 ? result[0] : result);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetCustomersError);
            });
    }

    public findEntities(query: ISelect2Query) {
        this.GetCustomers(query);
    }

    public findEntity(element, callback) {
        this.GetCustomer(element, callback);
    }

    public GetFormattedNameForList(customer: ISelect2Customer): string {
        return "<div style=\"display: inline-block;\"><span>" + customer.text + "</span>&nbsp;-&nbsp;<span>CF: " + (!customer.cf ? ProlifeSdk.TextResources.ProlifeSdk.NotAvailable : customer.cf) + "</span>&nbsp;-&nbsp;<span>Indirizzo: " + (!customer.address ? ProlifeSdk.TextResources.ProlifeSdk.NotAvailable : customer.address) + "</span></div>";
    }

    public GetFormattedNameForSelection(customer: ISelect2Customer): string {
        return "<span>" + customer.text + "</span>";
    }

    private CreateCustomerForSelect2(customer: ICustomerEnvelope): ISelect2Customer {
        return { id: customer.Id, text: customer.NameOrBusinessName, cf: customer.CFOrPIVA, address: customer.Address };
    }
}