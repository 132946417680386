import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ParamArray } from "../../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { ICallAttemptDetails } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";

let attributes = {
    AttemptsDetails: "attempts-details",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "surveywizard-queue-attempts-details": {
                params?: {
                    AttemptsDetails: ICallAttemptDetails[];
                };

                "attempts-details": ICallAttemptDetails[] | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IAttemptsDetaildPopoverParams {
    AttemptsDetails: ParamArray<ICallAttemptDetails>;
}

export class AttemptsDetailsPopover {
    public AttemptsList: ko.ObservableArray<AttemptDetails> = ko.observableArray([]);

    constructor(params: IAttemptsDetaildPopoverParams) {
        let data = ComponentUtils.parseParameterArray(params.AttemptsDetails, []);
        let list = data().map((d: ICallAttemptDetails) => {
            return new AttemptDetails(d);
        });

        this.AttemptsList(list);
    }
}

ko.components.register("surveywizard-queue-attempts-details", {
    viewModel: {
        createViewModel: (params: IAttemptsDetaildPopoverParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new AttemptsDetailsPopover(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div style="position: relative">
                    <div class="dataTables_scroll" style="position: relative; border: 1px solid #dddddd">
                        <div class="dataTables_scrollHead" style="position: relative; height: 39px">
                            <div class="dataTables_scrollHeadInner">
                                <table
                                    class="table table-striped table-hover dataTable no-footer"
                                    style="margin-top: 0px">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%">{TextResources.SurveyWizard.Attempt}</th>
                                            <th style="width: 15%">{TextResources.SurveyWizard.AttemptDate}</th>
                                            <th style="width: 30%">{TextResources.SurveyWizard.AttemptResult}</th>
                                            <th style="width: 25%">
                                                {TextResources.SurveyWizard.AttemptResultLogicalStatus}
                                            </th>
                                            <th style="width: 20%">{TextResources.SurveyWizard.AttemptOperator}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div
                            class="dataTables_scrollBody"
                            style="overflow-y: auto; position: relative; max-height: 250px">
                            <table
                                class="table table-striped table-hover dataTable no-footer"
                                style="width: 100%; margin-top: 0px">
                                <thead>
                                    <tr style="height: 0px;"></tr>
                                </thead>
                                <tbody data-bind="foreach: AttemptsList">
                                    <tr>
                                        <td style="width: 10%">
                                            <span data-bind="text: AttemptNumber"></span>
                                        </td>
                                        <td style="width: 15%">
                                            <span data-bind="dateTimeText: CallDate"></span>
                                        </td>
                                        <td style="width: 30%">
                                            <span data-bind="text: Label"></span>
                                        </td>
                                        <td style="width: 25%">
                                            <span data-bind="text: LogicalStatusLabel"></span>
                                        </td>
                                        <td style="width: 20%">
                                            <span data-bind="text: OperatorIdentifier"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>,
            ]);

            return vm;
        },
    },
    template: [],
});

export class AttemptDetails {
    public AttemptNumber: ko.Observable<number> = ko.observable();
    public CallDate: ko.Observable<Date> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public LogicalStatusLabel: ko.Observable<string> = ko.observable();
    public Nome: ko.Observable<string> = ko.observable();
    public Cognome: ko.Observable<string> = ko.observable();

    public OperatorIdentifier: ko.Computed<string>;

    constructor(data: ICallAttemptDetails) {
        this.AttemptNumber(data.AttemptNumber);
        this.CallDate(data.CallDate);
        this.Label(data.Label);
        this.LogicalStatusLabel(data.LogicalStatusLabel);
        this.Nome(data.Nome);
        this.Cognome(data.Cognome);

        this.OperatorIdentifier = ko.computed(() => {
            return this.Nome() + " " + this.Cognome();
        });
    }
}
