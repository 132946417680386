import { IWarehouseInspectionWizardArticleInfo, IFullWarehouseInspectionWarehouseInspectionOperations, IWarehouseInspectionWizardArticleInfoSource, IFullWarehouseInspectionWarehouseInspectionOperationsSources, IArticleRequirementsInfo } from "../../../WarehouseInspectionsService";
import { WarehouseInspectionAction } from "./Enums/WarehouseInspectionAction";

export class FullWarehouseInspectionFactory {
    public static newFullWarehouseInspectionOperation(inspectionId: number): IFullWarehouseInspectionWarehouseInspectionOperations {
        return {
            Id: null,
            FKInspection: inspectionId,
            FKArticle: null,
            ArticleDescription: null,
            FKSourceWarehouse: null,
            SourceWarehouse: null,
            SourceWarehouseStock: null,
            FKDestinationWarehouse: null,
            DestinationWarehouse: null,
            DestinationWarehouseStock: null,
            CustomerId: null,
            CustomerName: null,
            JobOrderId: null,
            JobOrderName: null,
            RequestedAmount: 0,
            ActualAmount: null,
            MovementAmount: null,
            Done: null,
            OperationType: null,
            ArticleCode: null,
            CreationDate: null,
            CreationUserId: null,
            LastModificationDate: null,
            LastModificationUserId: null
        };
    }

    public static toFullWarehouseInspectionOperationInfo(inspectionOperationInfo: IWarehouseInspectionWizardArticleInfo): IFullWarehouseInspectionWarehouseInspectionOperations {
        return {
            Id: inspectionOperationInfo.Id,
            FKInspection: inspectionOperationInfo.WarehouseInspectionId,
            FKArticle: inspectionOperationInfo.ArticleId,
            ArticleDescription: inspectionOperationInfo.ArticleDescription,
            FKSourceWarehouse: inspectionOperationInfo.SourceWarehouseId,
            SourceWarehouse: inspectionOperationInfo.SourceWarehouse,
            SourceWarehouseStock: inspectionOperationInfo.SourceWarehouseStock,
            FKDestinationWarehouse: inspectionOperationInfo.DestinationWarehouseId,
            DestinationWarehouse: inspectionOperationInfo.DestinationWarehouse,
            DestinationWarehouseStock: inspectionOperationInfo.DestinationWarehouseStock,
            CustomerId: inspectionOperationInfo.CustomerId,
            CustomerName: inspectionOperationInfo.CustomerName,
            JobOrderId: inspectionOperationInfo.JobOrderId,
            JobOrderName: inspectionOperationInfo.JobOrderName,
            RequestedAmount: inspectionOperationInfo.Amount,
            ActualAmount: null,
            MovementAmount: null,
            Done: inspectionOperationInfo.Done,
            OperationType: inspectionOperationInfo.OperationType,
            ArticleCode: inspectionOperationInfo.ArticleCode,
            CreationDate: null,
            CreationUserId: null,
            LastModificationDate: null,
            LastModificationUserId: null
        };
    }

    public static toFullWarehouseInspectionOperationSource(source: IWarehouseInspectionWizardArticleInfoSource): IFullWarehouseInspectionWarehouseInspectionOperationsSources {
        return {
            FKDocument: source.DocumentId,
            FKInspectionOperation: source.WarehouseInspectionOperationId,
            FKRef: source.RefId,
            RefAmount: source.RefAmount,
            DocumentDate: source.DocumentDate,
            DocumentNumber: source.DocumentNumber,
            DocumentLabel: source.DocumentLabel,
            EntityType: source.DocumentType,
            FKRegister: source.DocumentRegisterId,
            RegisterName: source.DocumentRegisterName
        };
    }
}