import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { reloadNow, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeTextResources";
import { Table, ITableItem, ITableComponent } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { MetadataType } from "../../../../Invoices/invoices/enums/MetadataType";
import { If } from "../../../../Components/IfIfNotWith";
import { CheckBox } from "../../../../Components/Checkbox";
import { DocumentMetadata } from "./DocumentMetadata";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { Right } from "../../../../Core/Authorizations";
import { DocumentMetadatasDataSource } from "../../../../DataSources/DocumentMetadatasDataSource";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";
import { Delay } from "../../../../Decorators/Delay";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { TextInput } from "../../../../Components/TextInput";
import {
    IMetadataSettingsManager,
    MetadataTypeDescriptor,
} from "../../../../Invoices/invoices/settings/MetadataSettingsManager";
import { Select } from "../../../../Components/Select";

const styleSheet = jss.createStyleSheet({
    documentMetadatasEditor: {
        "& .table-wrapper": {
            width: "100%",
            "& .flex-container": {
                overflow: "unset !important",
            },
        },
        "& .table-wrapper-not-simple": {
            width: "88%",
        },
        "& .metadatas-table": {
            "& .metadata-column": {
                width: "50%",
            },

            "& td.metadata-table-column": {
                verticalAlign: "top",

                "& .form-control": {
                    height: "30px !important",
                },
            },

            "&.table-condensed": {
                "& thead": {
                    "& > tr": {
                        "& > th.actions-column": {
                            "& button:last-child": {
                                margin: 0,
                            },
                        },
                    },
                },
                "& tbody": {
                    "& > tr": {
                        "& > td.actions-column": {
                            paddingTop: "5px !important",
                        },
                    },
                },
            },

            "& td": {
                "& label": {
                    display: "block",
                    fontSize: "12px",
                },
            },
        },
        "& .rows-sorting-anchor": {
            width: "50px",
        },
    },
});

const { classes } = styleSheet.attach();

type DocumentMetadatasEditorProps = {
    selectable?: ko.MaybeSubscribable<boolean>;
    metadatas: ko.ObservableArray<DocumentMetadata>;
    commonMetadatasDataSource: DocumentMetadatasDataSource;
    readOnly: ko.Subscribable<boolean>;
    showValueColumn?: boolean;
    showTypeColumn?: boolean;
    showConfigurationsColumn?: boolean;
    title?: string;
    titleOnTop?: boolean;
    compactTable?: boolean;
    tableAdvanceStyle?: boolean;
    className?: string;
    showPreloadOnDocumentCheckbox?: boolean;
    staticTable?: ko.Observable<boolean>;
    enableSorting?: ko.Observable<boolean>;

    customActions?: () => React.ReactNode;

    onMetadataSelected?: (metadata: DocumentMetadata) => void;
    onMetadataDeselected?: (metadata: DocumentMetadata) => void;

    forwardRef?: (editor: _DocumentMetadatasEditor) => void;
};

export function DocumentMetadatasEditor(props: DocumentMetadatasEditorProps) {
    const C = require("./DocumentMetadatasEditor")._DocumentMetadatasEditor as typeof _DocumentMetadatasEditor;
    return <C {...props} />;
}

export class _DocumentMetadatasEditor implements IDataSourceListener {
    static defaultProps: Partial<DocumentMetadatasEditorProps> = {
        selectable: false,
        showValueColumn: false,
        showTypeColumn: false,
        showConfigurationsColumn: false,
        titleOnTop: false,
        compactTable: false,
        tableAdvanceStyle: false,
        className: "",
        showPreloadOnDocumentCheckbox: false,
    };

    EditingDisabled: ko.Observable<boolean> = ko.observable(true);
    LockMetadatasSelector: ko.Observable<boolean> = ko.observable(false);
    CanAddOrRemoveMetadatasOnTable: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(nameof<IMetadataSettingsManager>())
    private metadatasSettingsManager: IMetadataSettingsManager;

    @Right("Documents_CanAddOrRemoveMetadatas")
    private canAddOrRemoveMetadatas: boolean;

    private subscriptions = [];

    private FilteredMetadatas: ko.ObservableArray<DocumentMetadata> = ko.observableArray([]);
    private MetadataTypes: MetadataTypeDescriptor[] = [];

    private MetadatasFilter: ko.Observable<string> = ko.observable();
    private MetadataTypeFilter: MetadataType[] = [];

    private table: ITableComponent<DocumentMetadata>;

    constructor(private props: DocumentMetadatasEditorProps) {
        this.initializePropsDefaultObservables();

        this.CanAddOrRemoveMetadatasOnTable = ko.computed(() => {
            return !this.EditingDisabled() && !this.props.readOnly() && !this.props.staticTable();
        });

        this.populateTypesList();
        this.applyFilters();

        this.props.forwardRef && this.props.forwardRef(this);
    }

    private initializePropsDefaultObservables() {
        if (!this.props.metadatas) this.props.metadatas = ko.observableArray([]);
        if (!this.props.readOnly) this.props.readOnly = ko.observable(false);
        if (!this.props.staticTable) this.props.staticTable = ko.observable(false);
        if (!this.props.enableSorting) this.props.enableSorting = ko.observable(false);
    }

    resetSelection(): void {
        if (!this.table) return;

        this.table.resetSelection();
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<number, DocumentMetadata>): void {
        this.props.onMetadataSelected && this.props.onMetadataSelected(model.model);
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, DocumentMetadata>): void {
        this.props.onMetadataDeselected && this.props.onMetadataDeselected(model.model);
    }

    componentDidMount() {
        this.subscriptions.push(this.props.metadatas.subscribe((v) => this.applyFilters()));
        this.subscriptions.push(this.MetadatasFilter.subscribe((v) => this.applyFilters()));

        if (this.canAddOrRemoveMetadatas) {
            this.EditingDisabled(this.props.readOnly());

            this.subscriptions.push(
                this.props.readOnly.subscribe((r) => this.EditingDisabled(this.canAddOrRemoveMetadatas && r))
            );
            this.subscriptions.push(
                this.CanAddOrRemoveMetadatasOnTable.subscribe((v) => this.LockMetadatasSelector(!v))
            );
        } else {
            this.EditingDisabled(true);
        }

        this.LockMetadatasSelector(!this.CanAddOrRemoveMetadatasOnTable());
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.dispose());
        this.subscriptions = [];
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const documentMetadatasEditor = this;
        let metadata: IDataSourceModel<number, DocumentMetadata>;

        const tableWrapperClasses = ComponentUtils.classNames({
            "col-md-10": !this.props.titleOnTop,
            "table-wrapper-not-simple": !this.props.titleOnTop,
            "col-md-12": this.props.titleOnTop,
            "table-wrapper": true,
        });

        const tableClasses = ComponentUtils.classNames("metadatas-table", {
            "table-advance": this.props.tableAdvanceStyle,
        });

        const addButtonClasses = ComponentUtils.classNames("btn btn-primary", {
            "btn-sm": !this.props.compactTable,
            "btn-xs": this.props.compactTable,
        });

        const deleteButtonClasses = ComponentUtils.classNames("btn btn-danger", {
            "btn-sm": !this.props.compactTable,
            "btn-xs": this.props.compactTable,
        });

        const metadataColumnClasses = ComponentUtils.classNames({
            "metadata-column": this.props.showValueColumn,
        });

        const tableAdditionalProps = {};
        if (this.props.titleOnTop) tableAdditionalProps["title"] = this.props.title;

        if (this.props.enableSorting()) {
            tableAdditionalProps["rowsSortingValueGetter"] = (model: DocumentMetadata) => model.Order();
            tableAdditionalProps["rowsSortingValueSetter"] = (model, newOrder: number) => model.Order(newOrder);
        }

        if (this.props.selectable) {
            tableAdditionalProps["selectableRows"] = this.props.selectable;
            tableAdditionalProps["selectRowsByCheckbox"] = true;
            tableAdditionalProps["multipleRowsSelection"] = true;
        }

        const metadataTypeLabelGetter = (type: MetadataType) =>
            this.MetadataTypes.firstOrDefault((mt) => mt.ValueType === type)?.TypeLabel ?? "";

        return ComponentUtils.bindTo(
            <div className={"row " + classes.documentMetadatasEditor + " " + this.props.className}>
                <div className="col-md-12">
                    <div className="form-group">
                        {!this.props.titleOnTop && (
                            <label className="control-label col-md-2" style={{ width: "12%" }}>
                                {this.props.title}
                            </label>
                        )}
                        <div className={tableWrapperClasses}>
                            <Table
                                dataSource={{ array: this.FilteredMetadatas, factory: this.modelsFactory.bind(this) }}
                                rowAs="metadata"
                                className={tableClasses}
                                compact={this.props.compactTable}
                                listener={this}
                                {...tableAdditionalProps}
                                forwardRef={(table) => (this.table = table)}
                            >
                                <Column
                                    title={TextResources.ProlifeSdk.DefaultMetadataColumn}
                                    className={metadataColumnClasses}
                                >
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.ProlifeSdk.DefaultMetadataColumn}
                                                <TableFilter customFilterStateHandler={() => !!this.MetadatasFilter()}>
                                                    {() => (
                                                        <TextInput
                                                            value={this.MetadatasFilter}
                                                            placeholder={TextResources.Invoices.MetadataPlaceholder}
                                                        />
                                                    )}
                                                </TableFilter>
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(item: ITableItem<DocumentMetadata>) => (
                                            <>
                                                <Select
                                                    dataSource={item.Data.model.MetadatasDataSource}
                                                    value={item.Data.model.MetadataId}
                                                    placeholder={
                                                        TextResources.ProlifeSdk.DefaultMetadataSelectorPlaceholder
                                                    }
                                                    readOnly={this.LockMetadatasSelector}
                                                    className="metadata-selector"
                                                    onSelect={item.Data.model.onItemSelected.bind(
                                                        item.Data.model,
                                                        null
                                                    )}
                                                    onDeselect={item.Data.model.onItemDeselected.bind(
                                                        item.Data.model,
                                                        null
                                                    )}
                                                />
                                                {!this.props.showConfigurationsColumn && (
                                                    <If
                                                        condition={() =>
                                                            item.Data.model.MetadataValueType() ===
                                                            MetadataType.DateTime
                                                        }
                                                    >
                                                        {() => (
                                                            <CheckBox
                                                                label={
                                                                    TextResources.Invoices.ShowMarkerOnAllocationsGantt
                                                                }
                                                                checked={item.Data.model.ShowMarkerOnAllocationsGantt}
                                                                readOnly={this.EditingDisabled}
                                                            />
                                                        )}
                                                    </If>
                                                )}
                                            </>
                                        )}
                                    </ColumnBody>
                                </Column>
                                {this.props.showTypeColumn && (
                                    <Column
                                        title={TextResources.ProlifeSdk.DefaultMetadataTypeColumn}
                                        style={{ width: "200px" }}
                                    >
                                        <ColumnHeader>
                                            {() => (
                                                <>
                                                    {TextResources.Invoices.MetadataValueTypeColumn}
                                                    <TableFilter
                                                        filterSource={this.props.metadatas}
                                                        itemLabelGetter={(m: DocumentMetadata) =>
                                                            metadataTypeLabelGetter(m.MetadataValueType())
                                                        }
                                                        itemKeyGetter={(m: DocumentMetadata) => m.MetadataValueType()}
                                                        onSelectionChange={this.onMetadataTypeFilterChanges.bind(this)}
                                                    />
                                                </>
                                            )}
                                        </ColumnHeader>
                                        <span data-bind={{ text: metadata.model.Type }}></span>
                                    </Column>
                                )}
                                {this.props.showValueColumn && (
                                    <Column
                                        title={TextResources.ProlifeSdk.DefaultMetadataValueColumn}
                                        cssClasses="metadata-table-column"
                                    >
                                        <ColumnBody>
                                            {(item: ITableItem<DocumentMetadata>) => (
                                                <If condition={item.Data.model.SelectedMetadataChanged}>
                                                    {() => item.Data.model.renderValueInput(this.props.readOnly)}
                                                </If>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                )}
                                {this.props.showConfigurationsColumn && (
                                    <Column
                                        title={TextResources.ProlifeSdk.DefaultMetadataConfigurationsColumn}
                                        style={{ width: "350px" }}
                                    >
                                        <ColumnBody>
                                            {(item: ITableItem<DocumentMetadata>) => (
                                                <>
                                                    <If
                                                        condition={() =>
                                                            item.Data.model.MetadataValueType() ===
                                                            MetadataType.DateTime
                                                        }
                                                    >
                                                        {() => (
                                                            <CheckBox
                                                                label={
                                                                    TextResources.Invoices.ShowMarkerOnAllocationsGantt
                                                                }
                                                                checked={item.Data.model.ShowMarkerOnAllocationsGantt}
                                                                readOnly={this.EditingDisabled}
                                                            />
                                                        )}
                                                    </If>
                                                    {this.props.showPreloadOnDocumentCheckbox && (
                                                        <CheckBox
                                                            label={TextResources.Invoices.PreloadOnDocument}
                                                            checked={item.Data.model.PreloadOnDocument}
                                                            readOnly={this.EditingDisabled}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                )}
                                <Column
                                    style={{ width: "65px" }}
                                    className="text-right actions-column"
                                    cssClasses="metadata-table-column"
                                    headerCssClasses="actions-column"
                                >
                                    <ColumnHeader>
                                        {() => (
                                            <If condition={this.CanAddOrRemoveMetadatasOnTable}>
                                                {() => (
                                                    <>
                                                        {this.props.customActions && this.props.customActions()}
                                                        {this.canAddOrRemoveMetadatas && (
                                                            <button
                                                                className={addButtonClasses}
                                                                type="button"
                                                                data-bind={{
                                                                    click: documentMetadatasEditor.addMetadata.bind(
                                                                        documentMetadatasEditor
                                                                    ),
                                                                }}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </If>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {this.canAddOrRemoveMetadatas && (
                                            <If condition={this.CanAddOrRemoveMetadatasOnTable}>
                                                {() => (
                                                    <button
                                                        className={deleteButtonClasses}
                                                        type="button"
                                                        data-bind={{
                                                            asyncClick: documentMetadatasEditor.removeMetadata.bind(
                                                                documentMetadatasEditor,
                                                                metadata.model
                                                            ),
                                                        }}
                                                    >
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                )}
                                            </If>
                                        )}
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "documentMetadatasEditor"
        );
    }

    private populateTypesList() {
        const metadataTypes = this.metadatasSettingsManager.getMetadataTypeDescriptors();
        this.MetadataTypes = metadataTypes;
    }

    @Delay(100)
    private applyFilters(): void {
        const metadata = this.props.metadatas();
        const textFilter = this.MetadatasFilter();
        const selectedTypes = this.MetadataTypeFilter.slice();

        const filteredMetadata = metadata.filter((m) => {
            return (
                TextFiltersUtilities.contains(m.MetadataLabel(), textFilter) &&
                (!this.props.showTypeColumn || selectedTypes.indexOf(m.MetadataValueType()) >= 0)
            );
        });

        this.FilteredMetadatas([]);
        this.FilteredMetadatas(filteredMetadata);
    }

    private onMetadataTypeFilterChanges(selectedTypes: MetadataType[]) {
        this.MetadataTypeFilter = selectedTypes;
        this.applyFilters();
    }

    private modelsFactory(metadata: DocumentMetadata): IDataSourceModel<number, DocumentMetadata> {
        return {
            id: metadata.MetadataId(),
            title: null,
            isGroup: false,
            isLeaf: true,
            model: metadata,
        };
    }

    private addMetadata() {
        this.props.metadatas.push(new DocumentMetadata(this.props.commonMetadatasDataSource));
    }

    private async removeMetadata(metadata: DocumentMetadata): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.ProlifeSdk.RemoveMetadataConfirmMessage,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        this.props.metadatas.remove(metadata);
    }
}

export class DocumentMetadatasEditorUI {
    constructor(private props: DocumentMetadatasEditorProps) {}

    render() {
        return <DocumentMetadatasEditor {...this.props} />;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentMetadatasEditor);
}
