import * as ko from "knockout";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { ITeamsManagerProvider, ITeamsManager, ITeamsManagerResource, ITeamViewModel } from "./TeamsManager";
import { IEventHandler } from "../../../ProlifeSdk/interfaces/IEventHandler";

/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 31/01/2018
 * Time: 16:30
 * To change this template use File | Settings | File Templates.
 */

export class TeamsManagerProvider implements ITeamsManagerProvider {
    OnTeamsLoaded:IEventHandler = new EventHandler();
    OnTeamCreated:IEventHandler = new EventHandler();
    OnTeamDeleted:IEventHandler = new EventHandler();
    OnTeamSelected:IEventHandler = new EventHandler();
    OnBeforeTeamsManagerChanged:IEventHandler = new EventHandler();
    OnAfterTeamsManagerChanged:IEventHandler = new EventHandler();

    private teamsManager : ITeamsManager;

    private onTeamsLoadedCallback : any;
    private onTeamsCreatedCallback : any;
    private onTeamsDeletedCallback : any;
    private onTeamSelectedCallback : any;

    constructor() {
    }

    GetResource(resourceId:number):ITeamsManagerResource {
        return this.teamsManager.GetResource(resourceId);
    }

    GetResources():ITeamsManagerResource[] {
        return this.teamsManager.Resources();
    }

    GetTeam(teamId:number):ITeamViewModel {
        return this.teamsManager.GetTeam(teamId);
    }

    GetTeams(filterHiddenTeams: boolean = false):ITeamViewModel[] {
        let teams = !filterHiddenTeams ? this.teamsManager.Teams() : this.teamsManager.Teams().filter((t) => !t.Hide());
        return teams.sort((t1, t2) => t1.Position() - t2.Position());
    }

    RemoveTeamOnResource(resourceId:number, teamId:number) {
        this.teamsManager.RemoveTeamOnResource(resourceId, teamId);
    }

    OptimizeAllocations(resourceId:number, operationalUnitId:number) {
        this.teamsManager.OptimizeAllocations(resourceId, operationalUnitId);
    }

    SynchronizeResourceAllocations(resourceId: number, teamId: number) {
        this.teamsManager.SynchronizeResourceAllocations(resourceId, teamId);
    }

    SynchronizeResourceRanges(resourceId: number, teamId: number, operationalUnitId: number): void {
        this.teamsManager.SynchronizeResourceRanges(resourceId, teamId, operationalUnitId);
    }

    GetTeamFromResourceAllocationId(teamResourceAllocationId:number):ITeamViewModel {
        return this.teamsManager.GetTeamFromResourceAllocationId(teamResourceAllocationId);
    }

    selectTeam(team:any) {
        this.teamsManager.selectTeam(team);
    }

    deleteTeam(team:any) {
        this.teamsManager.deleteTeam(team);
    }

    Clone():ITeamsManagerProvider {
        var newProvider = new TeamsManagerProvider();

        this.CloneToProvider(newProvider);

        return newProvider;
    }

    CloneToProvider(newProvider : ITeamsManagerProvider) {
        var clonedTeamsManager = this.teamsManager.CreateNew();
        clonedTeamsManager.Teams(this.teamsManager.Teams().map((t: ITeamViewModel) => { return t.Clone(newProvider); }));
        clonedTeamsManager.populateResourcesViewModels();

        newProvider.SetTeamsManger(clonedTeamsManager);
    }

    StealTeamMangerFrom(teamsManagerProvider:ITeamsManagerProvider) {
        var otherProvider = <TeamsManagerProvider>teamsManagerProvider;
        var newTeamsManager = otherProvider.teamsManager;
        otherProvider.SetTeamsManger(null);
        this.SetTeamsManger(newTeamsManager);
    }

    SetTeamsManger(teamsManager : ITeamsManager) {
        this.OnBeforeTeamsManagerChanged.Call();

        if(this.teamsManager) {
            this.OnTeamsLoaded.Remove(this.onTeamsLoadedCallback);
            this.OnTeamCreated.Remove(this.onTeamsCreatedCallback);
            this.OnTeamDeleted.Remove(this.onTeamsDeletedCallback);
            this.OnTeamSelected.Remove(this.onTeamSelectedCallback);
        }

        this.teamsManager = teamsManager;
        if(!this.teamsManager) {
            this.OnAfterTeamsManagerChanged.Call();
            return;
        }

        this.teamsManager.SetProvider(this);

        this.onTeamsLoadedCallback = this.teamsManager.OnTeamsLoaded.Add(() => this.OnTeamsLoaded.Call());
        this.onTeamsCreatedCallback = this.teamsManager.OnTeamCreated.Add((team) => this.OnTeamCreated.Call(team));
        this.onTeamsDeletedCallback = this.teamsManager.OnTeamDeleted.Add((team) => this.OnTeamDeleted.Call(team));
        this.onTeamSelectedCallback = this.teamsManager.OnTeamSelected.Add((team) => this.OnTeamSelected.Call(team));

        this.OnAfterTeamsManagerChanged.Call();
    }

    Load() {
        this.teamsManager.Load();
    }

    SearchTeams(query:string):ITeamViewModel[] {
        return this.teamsManager.search(query);
    }

    SaveChanges(): Promise<void>  {
        return this.teamsManager.SaveChanges();
    }

    addNewTeam():ITeamViewModel {
        return this.teamsManager.addNewTeam();
    }

    HasTeamsManager() : boolean {
        return !!this.teamsManager;
    }
}