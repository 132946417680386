import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { Select } from "./Select";
import { IDataSourceModel } from "../DataSources/IDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { JobOrderLogicalStates } from "../DataSources/JobOrderStatesDataSource";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type JobOrderLogicalStateSelectorProps = {
    simple?: boolean;
    value: ko.Observable<JobOrderLogicalStates>;
    onChange: (value: JobOrderLogicalStates | null) => void;
};

type LogicalStateOption = {
    id: number;
    status: JobOrderLogicalStates | null;
    name: string;
};

export function JobOrderLogicalStateSelector(props: JobOrderLogicalStateSelectorProps) {
    const C = require("./JobOrderLogicalStateSelector")
        ._JobOrderLogicalStateSelector as typeof _JobOrderLogicalStateSelector;
    return <C {...props} />;
}

export class _JobOrderLogicalStateSelector {
    static defaultProps: Partial<JobOrderLogicalStateSelectorProps> = {};

    private Options: ko.ObservableArray<LogicalStateOption> = ko.observableArray([
        { id: 1, status: null, name: TextResources.JobOrder.LogicalStateAll },
        { id: 2, status: JobOrderLogicalStates.Opened, name: TextResources.JobOrder.LogicalStateOpened },
        { id: 3, status: JobOrderLogicalStates.Closed, name: TextResources.JobOrder.LogicalStateClosed },
    ]);

    private State: ko.Observable<number> = ko.observable(1);

    private subscriptions: ko.Subscription[] = [];

    constructor(private props: JobOrderLogicalStateSelectorProps) {}

    componentDidMount() {
        this.State(this.translateState(this.props.value()).id);
        this.subscriptions.push(this.props.value.subscribe((value) => this.State(this.translateState(value).id)));
    }

    private translateState(state: JobOrderLogicalStates | null) {
        return this.Options().find((o) => o.status === state);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.dispose());
    }

    private createModel(item: LogicalStateOption): IDataSourceModel<JobOrderLogicalStates, LogicalStateOption> {
        return {
            id: item.id,
            title: item.name,
            isGroup: false,
            isLeaf: true,
            model: item,
        };
    }

    render() {
        const props = {
            onSelect: (item) => this.props.onChange(item.model.status),
            placeholder: TextResources.JobOrder.LogicalState,
            value: this.State,
            dataSource: {
                array: this.Options,
                factory: this.createModel.bind(this),
            },
        };

        return ComponentUtils.bindTo(
            <>
                {this.props.simple ? (
                    <Select {...props} />
                ) : (
                    <Select.WithLabel {...props} label={TextResources.JobOrder.LogicalState} />
                )}
            </>,
            this,
            "jls"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderLogicalStateSelector);
}
