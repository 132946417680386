import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { MultipleEntitiesProvider } from "../ProlifeSdk/prolifesdk/MultipleEntitiesProvider";
import * as Core from "../Core/Core";
import { IWorkedHoursService } from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IWorkableElement } from "../ProlifeSdk/interfaces/worked-hours/IWorkableElement";

export class WorkActivitiesContainerValue
{
    init(element: any, valueAccessor: () => any): void
    {
        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        var instance = {};

        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var descrField = valueAccessor()["descrField"];
        var getClosedField = valueAccessor()["getClosedField"];

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : WorkActivitiesContainerValue.findWorkActiovitiesContainers.bind(null, instance, workableElementsService, getClosedField),
            display: (json) => {
                var item = JSON.parse(json);
                return item.FullName;
            },
            limit: 1000,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\"><span style='" + (item.IsLocked ? "color : red" : "") + "' class='fa " + (item.IsLocked ? "fa-lock" : (item.TypeCode == "JOR" ? "fa-briefcase" : "fa-wrench")) +  "'></span>&nbsp;" + item.FullName + "</div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            typeField(item.TypeCode);
            keyField(item.Id);
            if(descrField)
                descrField(item.FullName);
            $(element).data("typeField", item.TypeCode);
            $(element).data("keyField", item.Id);
            return item.FullName;
        });

        //Gestione tasto freccia giù per aprire il menu senza digitare
        /*$(element).keyup((e) => {
            if(e.keyCode == 40) {
                $(element).typeahead("open");
            }
        });*/
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var descrField = valueAccessor()["descrField"];
        var previousType = $(element).data("typeField");
        var previousId = $(element).data("keyField");

        if(typeField() == previousType && keyField() == previousId)
            return;

        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);

        if(!typeField() || !keyField())
        {
            (<any>$(element)).typeahead("val", "");
            if(descrField)
                descrField("");
            return;
        }

        workableElementsService.GetWorkableElement(keyField(),typeField())
            .then((result : any) => {
                var text = result ? WorkActivitiesContainerValue.transformItem(result).FullName : "";
                (<any>$(element)).typeahead("val", text);
                if(descrField)
                    descrField(text);
            });
    }

    private static findWorkActiovitiesContainers(_this : any, findService : IWorkedHoursService, getClosedField : ko.Observable<boolean>,
        query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any,
        numberOfElementsLoaded? : number)
    {
        if(_this.lastTimeout)
            clearTimeout(_this.lastTimeout);

        var getClosed: boolean = getClosedField ? getClosedField() : true;

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            findService.GetWorkableElements(true, true, false, false, getClosed, query, numberOfElementsLoaded || 0, 50, -1, null, null)
                .then((results : any[]) => {
                    return asyncProcess(WorkActivitiesContainerValue.transformItems(results));
                });
        }, 500);
    }

    private static transformItems(items : IWorkableElement[]) : any[] {
        return items.map(WorkActivitiesContainerValue.transformItem);
    }

    public static transformItem(item : IWorkableElement) : any {
        return {
            Id: item.KeyId,
            TypeCode: item.ElementType,
            IsLocked : item.IsLocked,
            FullName: item.Name,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["workActivitiesContainerValue"] = new WorkActivitiesContainerValue();
