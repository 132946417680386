import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { CustomerOrderEventDetailsEdit } from "./details/CustomerOrderEventDetailsEdit";
import { ICustomerOrder, ICustomerOrderRow } from "../../../../ProlifeSdk/interfaces/warehouse/ICustomerOrder";
import { IOrdersService } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class CustomerOrderEvent extends DocumentEvent implements IView
{
    private ordersService : IOrdersService;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.CustomerOrderEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.Warehouse.CustomerOrderAbb;
        this.ordersService = <IOrdersService>serviceLocator.findService(ProlifeSdk.OrdersServiceType);
    }

    getEditViewModel() : IEventBaseInput
    {
        return new CustomerOrderEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.LoadDocumentInfo();
    }

    public LoadDocumentInfo() : Promise<void>
    {
        var def = new Deferred<void>();
        this.ordersService.getCustomerOrderById(this.DocumentId()).then((d : ICustomerOrder) => {
            if(!d)
                return;

            this.Total(d.TotaleOrdine);

            this.ordersService.getCustomerOrderRowsById(this.DocumentId()).then((r : ICustomerOrderRow[]) => {
                this.NumberOfRows(r ? r.length : 0);
            });
        }).finally(() => {
            def.resolve();
        });
        return def.promise();
    }
}