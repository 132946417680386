import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { JobOrdersUtils } from "../JobOrder/jobOrder/ui/utils/JobOrdersUtils";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { TasksUtils } from "../Todolist/Todolist/ui/utils/TasksUtils";
import {
    INavigationMenuComponentModel,
    INavigationMenuComponentDataSource,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import {
    IJobOrderService,
    IGetJobOrdersListForAllocationsRequest,
} from "../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodolistWorkflowForAllocationsList } from "../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import {
    ITodoListTaskForAllocations,
    IDraggedWorkflow,
    IDraggedTask,
} from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IJobOrdersMenuAdvancedFilters } from "../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAllocationsService } from "../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IJobOrderForAllocations } from "../ProlifeSdk/interfaces/job-order/IJobOrder";
import {
    IWorkflowState,
    IWorkflowStatesSettingsManager,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { WorkflowsMenuSearchModes } from "../Todolist/Todolist/enums/WorkflowsMenuSearchModes";

export interface IJobOrderForAllocationsDataSourceModel
    extends INavigationMenuComponentModel<number, IJobOrderForAllocations> {
    isJobOrder: true;
}

export interface IWorkflowForAllocationsDataSourceModel
    extends INavigationMenuComponentModel<number, ITodolistWorkflowForAllocationsList> {
    isWorkflow: true;
}

export interface IWorkflowsGroupDataSourceModel extends INavigationMenuComponentModel<number, IWorkflowState> {
    isGroup: true;
    isLeaf: false;
    isWorkflowGroup: true;
    workflowsCount: number;
}

export interface ITasksGroupsDataSourceModel extends INavigationMenuComponentModel<number, IWorkflowState> {
    isGroup: true;
    isLeaf: false;
    isTasksGroup: true;
}

export interface ITaskForAllocationsDataSourceModel
    extends INavigationMenuComponentModel<number, ITodoListTaskForAllocations> {
    isTask: true;
}

export class AllocationsMenuDataSource implements INavigationMenuComponentDataSource {
    public AdvancedFilterIsActive: ko.Observable<boolean> = ko.observable(false);

    private advancedFilters: IJobOrdersMenuAdvancedFilters;

    private workflowsCache: ITodolistWorkflowForAllocationsList[] = [];
    private tasksCache: ITodoListTaskForAllocations[] = [];
    private lastTextFilter: string = null;
    private lastJobOrderId: number = null;
    private workflowsCacheClearRequested: boolean;
    private tasksCacheClearRequested: boolean;

    private workflowsSearchMode: WorkflowsMenuSearchModes;

    private view: IDataSourceView;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStatesManager: IWorkflowStatesSettingsManager;

    constructor() {
        this.workflowsSearchMode = WorkflowsMenuSearchModes.SearchWorkflows;
        this.workflowsCacheClearRequested = true;
        this.tasksCacheClearRequested = true;

        const filtersProvider = this.allocationsService.getMenuAdvancedFiltersProvider();
        const filters = filtersProvider.getCurrentFilters();
        this.setAdvancedFilters(filters);
    }

    public setWorkflowsSearchMode(searchMode: WorkflowsMenuSearchModes): void {
        this.workflowsSearchMode = searchMode;
        this.workflowsCacheClearRequested = true;
        this.tasksCacheClearRequested = true;
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public getTitle(currentModel: IDataSourceModel): string {
        if (!currentModel) return ProlifeSdk.TextResources.Desktop.Orders;

        const jobOrderModel = currentModel as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel.isJobOrder) return jobOrderModel.title;

        return currentModel.title;
    }

    public async getData(
        currentModel: IDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel[]> {
        if (!currentModel) return this.getJobOrders(textFilter, skip, count);

        const jobOrderModel = currentModel as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel.isJobOrder) {
            if (skip != 0) return [];

            if (this.lastTextFilter != textFilter || this.lastJobOrderId != currentModel.id) {
                this.workflowsCache = [];
                this.tasksCache = [];
                this.workflowsCacheClearRequested = true;
                this.tasksCacheClearRequested = true;
                this.lastTextFilter = textFilter;
                this.lastJobOrderId = currentModel.id as number;
            }

            if (this.workflowsSearchMode === WorkflowsMenuSearchModes.SearchWorkflows || !textFilter)
                return this.getWorkflowsGroups(jobOrderModel.id, textFilter);
            else return this.getTasksGroups(jobOrderModel.id, textFilter);
        }

        const workflowsGroupModel = currentModel as IWorkflowsGroupDataSourceModel;
        if (workflowsGroupModel.isWorkflowGroup) {
            if (skip != 0) return [];

            return this.getWorkflows(workflowsGroupModel.id);
        }

        const tasksGroup = currentModel as ITasksGroupsDataSourceModel;
        if (tasksGroup.isTasksGroup) {
            if (skip != 0) return [];

            return this.getTasksFromCache(tasksGroup.id);
        }

        const workflowModel = currentModel as IWorkflowForAllocationsDataSourceModel;
        return this.getTasks(workflowModel.id, workflowModel.model.JobOrderId, textFilter, skip, count);
    }

    public async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        if (!currentModel) return this.getJobOrdersByIds(ids);

        const jobOrderModel = currentModel as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel.isJobOrder) return this.getWorkflowsByIds(ids);

        return this.getTasksByIds(ids);
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public async editAdvancedFilters(): Promise<void> {
        const filtersEditor = this.allocationsService.getMenuAdvancedFiltersEditor();
        const newFilters = await filtersEditor.editFilters();
        this.setAdvancedFilters(newFilters);
        this.view.refresh();
    }

    public isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        const jobOrderModel = currentModel as IJobOrderForAllocationsDataSourceModel;
        return jobOrderModel && !!jobOrderModel.isJobOrder;
    }

    public areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    public onItemBeginMove(model: IDataSourceModel, dataTransfer: DataTransfer) {
        const workflowModel = model as IWorkflowForAllocationsDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            const draggedWorkflow: IDraggedWorkflow = {
                IsTask: false,
                WorkflowId: workflowModel.id,
                JobOrderId: workflowModel.model.JobOrderId,
                CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
            };

            dataTransfer.setData("text/plain", workflowModel.title);
            dataTransfer.setData("application/prolife-workflow", JSON.stringify(draggedWorkflow));

            return;
        }

        const taskModel = model as ITaskForAllocationsDataSourceModel;
        if (taskModel && taskModel.isTask) {
            const draggedTask: IDraggedTask = {
                IsTask: true,
                TaskId: taskModel.id,
                TaskBoardStatus: taskModel.model.TaskBoardStatus,
                WorkflowId: taskModel.model.RelatedWorkflow,
                JobOrderId: taskModel.model.JobOrderId,
                CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
            };

            dataTransfer.setData("text/plain", taskModel.title);
            dataTransfer.setData("application/prolife-task", JSON.stringify(draggedTask));
        }
    }

    private async getJobOrders(
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IJobOrderForAllocationsDataSourceModel[]> {
        const request: IGetJobOrdersListForAllocationsRequest = {
            TextFilter: textFilter,
            AdvancedFilters: this.advancedFilters,
            Skip: skip,
            Count: count,
        };

        const jobOrders = await this.jobOrdersService.GetJobOrdersListForAllocations(request);

        return jobOrders.map(this.createModelForJobOrder, this);
    }

    private async getJobOrdersByIds(ids: number[]): Promise<IJobOrderForAllocationsDataSourceModel[]> {
        const jobOrder = await this.jobOrdersService.GetJobOrderForAllocationsList(ids.firstOrDefault());
        return [this.createModelForJobOrder(jobOrder)];
    }

    private createModelForJobOrder(jobOrder: IJobOrderForAllocations): IJobOrderForAllocationsDataSourceModel {
        return {
            id: jobOrder.JobOrderId,
            isGroup: false,
            isLeaf: false,
            title: jobOrder.Name,
            dragEnabled: false,
            icon: {
                icon: jobOrder.Icon,
                background: jobOrder.Background,
                foreground: jobOrder.Foreground,
            },
            alerts: {
                label: ProlifeSdk.TextResources.Todolist.Alerts,
                icons: JobOrdersUtils.getAlertsForJobOrder(jobOrder),
            },
            subTitle: `(${jobOrder.StateDescription}) - ${jobOrder.CustomerName || "Nessun Cliente"}`,
            model: jobOrder,
            isJobOrder: true,
            details: {
                componentName: "allocations-menu-details",
                model: jobOrder,
            },
        };
    }

    private async getWorkflowsGroups(
        jobOrderId: number,
        textFilter: string
    ): Promise<IWorkflowsGroupDataSourceModel[]> {
        const workflowStates = this.workflowStatesManager.getStates(false);

        while (this.workflowsCacheClearRequested) {
            this.workflowsCacheClearRequested = false;
            this.workflowsCache = await this.todoListService.GetWorkflowsForAllocations(
                jobOrderId,
                textFilter,
                this.advancedFilters
            );

            if (this.workflowsCacheClearRequested) this.workflowsCache = [];
        }

        const groups = workflowStates
            .map((s) =>
                this.createModelForWorkflowState(s, this.workflowsCache.filter((w) => w.Status === s.Id).length)
            )
            .filter((g) => g.workflowsCount > 0);
        const workStatusUsed: boolean =
            groups.filter((g) => g.model.LogicalStatus === 1 && g.workflowsCount > 0).length > 0;
        for (const group of groups)
            group.collapsed = workStatusUsed ? group.model.LogicalStatus != 1 : group.model.LogicalStatus != 0;

        return groups;
    }

    private async getTasksGroups(jobOrderId: number, textFilter: string): Promise<ITasksGroupsDataSourceModel[]> {
        while (this.tasksCacheClearRequested) {
            this.tasksCacheClearRequested = false;

            this.tasksCache = await this.todoListService.GetTasksForAllocations(
                textFilter,
                null,
                jobOrderId,
                this.advancedFilters,
                0,
                100000
            );

            if (this.tasksCacheClearRequested) this.tasksCache = [];
        }

        const groups: ITasksGroupsDataSourceModel[] = [];
        let lastGroup: ITasksGroupsDataSourceModel = null;

        for (const task of this.tasksCache) {
            if (!lastGroup || lastGroup.id != task.RelatedWorkflow) {
                lastGroup = {
                    id: task.RelatedWorkflow,
                    isGroup: true,
                    isLeaf: false,
                    isTasksGroup: true,
                    title: task.WorkflowTitle,
                    collapsed: false,
                };

                groups.push(lastGroup);
            }
        }

        return groups;
    }

    private async getWorkflows(state: number): Promise<IWorkflowForAllocationsDataSourceModel[]> {
        return this.workflowsCache.filter((w) => w.Status === state).map((w) => this.createModelForWorkflow(w));
    }

    private async getWorkflowsByIds(ids: number[]): Promise<IWorkflowForAllocationsDataSourceModel[]> {
        const workflow = await this.todoListService.GetWorkflowForAllocationsList(ids.firstOrDefault());
        return [this.createModelForWorkflow(workflow)];
    }

    private createModelForWorkflow(
        workflow: ITodolistWorkflowForAllocationsList
    ): IWorkflowForAllocationsDataSourceModel {
        const item: IWorkflowForAllocationsDataSourceModel = {
            id: workflow.Id,
            isGroup: false,
            isLeaf: false,
            title: workflow.Title,
            model: workflow,
            dragEnabled: !workflow.IsAllocated,
            isWorkflow: true,
            icon: {
                icon: workflow.Icon,
                background: workflow.Background,
                foreground: workflow.Foreground,
            },
            progressBar: {
                progress: ((workflow.TasksCount - workflow.NotCompleteTasksCount) / workflow.TasksCount) * 100,
            },
            subTitle:
                ProlifeSdk.TextResources.Todolist.ClosedTasks +
                ": " +
                (workflow.TasksCount - workflow.NotCompleteTasksCount) +
                "/" +
                workflow.TasksCount,
            secondaryAction: {
                icon: {
                    icon: "fa fa-pencil",
                    background: "#ecbc29",
                    foreground: "white",
                },
                action: () => {
                    this.todoListService.ShowEditWorkflowDialog(workflow.Id).then((result) => {
                        if (!result) return;
                        this.view.refresh();
                    });
                },
            },
            alerts: {
                label: ProlifeSdk.TextResources.Todolist.Alerts,
                icons: WorkflowsUtils.getAlertsForWorkflow(workflow),
            },
            details: {
                componentName: "allocations-menu-details",
                model: workflow,
            },
        };

        item.badge = [];

        if ((workflow.AllocationPercentage || 0) > 0 || workflow.HasNotEstimatedElementsAllocated) {
            item.badge.push(WorkflowsUtils.getAllocationBadge(workflow, this.dialogsService));
        }

        if (workflow.RelatedDocuments > 0 || workflow.WorkflowMustBeRelatedToCustomerOrders) {
            item.badge.push(WorkflowsUtils.getRelatedDocumentsBadge(workflow));
        }

        return item;
    }

    private createModelForWorkflowState(state: IWorkflowState, workflowsCount: number): IWorkflowsGroupDataSourceModel {
        return {
            id: state.Id,
            isGroup: true,
            isLeaf: false,
            isWorkflowGroup: true,
            title: String.format(
                ProlifeSdk.TextResources.Todolist.WorkflowStateForMenu,
                state.Description,
                workflowsCount
            ),
            model: state,
            collapsed: true,
            workflowsCount: workflowsCount,
        };
    }

    private async getTasks(
        workflowId: number,
        jobOrderId: number,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<ITaskForAllocationsDataSourceModel[]> {
        const tasks = await this.todoListService.GetTasksForAllocations(
            textFilter,
            workflowId,
            jobOrderId,
            this.advancedFilters,
            skip,
            count
        );
        return tasks.map(this.createModelForTask, this);
    }

    private async getTasksByIds(ids: number[]): Promise<any[]> {
        const tasks = await this.todoListService.GetTasksByIdsForAllocationsList(ids);
        return tasks.map(this.createModelForTask, this);
    }

    private createModelForTask(task: ITodoListTaskForAllocations): ITaskForAllocationsDataSourceModel {
        const taskModel: ITaskForAllocationsDataSourceModel = {
            id: task.Id,
            title: task.Title,
            isGroup: false,
            isLeaf: true,
            dragEnabled: !task.IsAllocated,
            model: task,
            icon: TasksUtils.getTaskStatusIcon(task.TaskBoardStatus),
            subTitle:
                ProlifeSdk.TextResources.Todolist.Status + ": " + TasksUtils.getTaskStatusName(task.TaskBoardStatus),
            badge: [
                {
                    text: task.EventsCount.toString(),
                    cssClass: task.EventsCount == 0 ? "badge-danger" : "badge-success",
                },
            ],
            alerts: {
                label: ProlifeSdk.TextResources.Todolist.Alerts,
                icons: TasksUtils.getAlertsForTask(task),
            },
            details: {
                componentName: "allocations-menu-details",
                model: task,
            },
            secondaryAction: {
                icon: {
                    icon: "fa fa-pencil",
                    background: "#ecbc29",
                    foreground: "white",
                },
                action: () => {
                    this.todoListService.ShowEditTaskDialog(task.Id).then((result) => {
                        if (!result) return;
                        this.view.refresh();
                    });
                },
            },
            isTask: true,
        };

        if (task.IsAllocated) {
            taskModel.iconsList = [
                {
                    icon: "fa fa-shopping-cart",
                    background: task.Duration > 0 ? "#45b6af" : "#f3565d",
                    foreground: "#ffffff",
                },
            ];
        }

        return taskModel;
    }

    private getTasksFromCache(workflowId: number): ITaskForAllocationsDataSourceModel[] {
        return this.tasksCache.filter((t) => t.RelatedWorkflow === workflowId).map(this.createModelForTask, this);
    }

    private setAdvancedFilters(filters: IJobOrdersMenuAdvancedFilters): void {
        this.advancedFilters = filters;
        this.AdvancedFilterIsActive(
            filters.SelectedJobOrdersStates.length > 0 ||
                filters.SelectedJobOrdersTypes.length > 0 ||
                filters.SelectedOpUnits.length > 0 ||
                filters.SelectedWorkflowTypes.length > 0 ||
                filters.OnlyActivitiesWithRequiredAllocation
        );
        this.workflowsCacheClearRequested = true;
    }

    refresh() {
        if (!this.view) return;

        this.workflowsCache = [];
        this.workflowsCacheClearRequested = true;

        this.view.refresh(true);
    }
}
