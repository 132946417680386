import * as Core from "./Core";
import "reflect-metadata";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IService } from "./interfaces/IService";
import { ISettingsManager } from "../ProlifeSdk/interfaces/settings/ISettingsManager";

export function Export(...serviceNames : string[]) : ClassDecorator {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (constructor : any) => {
        Core.serviceLocator.registerService(serviceNames, constructor);
    };
}

export function Service(...serviceNames : string[]) : ClassDecorator {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (constructor : any) => {
        Core.serviceLocator.registerService(serviceNames, constructor, true);
    }
}

export function SettingsManager(...serviceNames : string[]) : ClassDecorator {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (constructor : any) => {
        Core.serviceLocator.registerService(serviceNames, constructor, true);
    }
}

export function Import() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function _Import<T extends { new(...args: any[]): Record<string, unknown> }>(constructor : T) {
        const parameters : string[] = Reflect.getMetadata("constructor:parameters", constructor) || [];

        return class extends constructor {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
            constructor(...args : any[]) {
                const paramValues = parameters.map((serviceName : string) => {
                    if(serviceName.indexOf('[') != -1) {
                        const realServiceName = serviceName.replace('[]', '');
                        return Core.serviceLocator.findServices(realServiceName);
                    }
                    return Core.serviceLocator.findService(serviceName);
                });
                super(...paramValues);
            }
        };
    }
}

export function LazyImport(serviceName : string) : PropertyDecorator {
    return function _LazyImport(target: any, propertyName: string) {
        let cachedValue : any;

        Object.defineProperty(
            target,
            propertyName,
            {
                get: () => {
                    if(!cachedValue) {
                        if(serviceName.indexOf('[') != -1) {
                            let realServiceName = serviceName.replace('[]', '');
                            cachedValue = Core.serviceLocator.findServices(realServiceName);
                        }
                        else
                        {
                            cachedValue = Core.serviceLocator.findService(serviceName);
                        }
                    }
                    return cachedValue;
                },
                set: (newValue : any) => {
                    cachedValue = newValue;
                }
            }
        )
    }
}

export function LazyImportSettingManager(settingManagerName : string) : PropertyDecorator {
    return function _LazyImportSettingManager(target: any, propertyName: string) {
        let cachedValue : any;

        Object.defineProperty(
            target,
            propertyName,
            {
                get: () => {
                    if(!cachedValue) {
                        let settingsService = Core.serviceLocator.findService("SettingsService") as ISettingsService; //TODO: Rimuovere stringa e mettere variabile
                        cachedValue = settingsService.findSettingsManager(settingManagerName);
                    }
                    return cachedValue;
                },
                set: (newValue : any) => {
                    cachedValue = newValue;
                }
            }
        )
    }
}

export function findService<T extends IService>(serviceName : string) : T {
    return Core.serviceLocator.findService(serviceName);
}

export function useService<T extends IService>(serviceName : string) : T {
    return Core.serviceLocator.findService(serviceName);
}

export function useSettingsManager<T extends ISettingsManager>(serviceName : string) : T {
    const settingsService = useService<ISettingsService>(nameof<ISettingsService>());
    return settingsService.findSettingsManager(serviceName) as T;
}