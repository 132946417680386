import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ICartLinkWithInfo } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";

const styleSheet = jss.createStyleSheet({
    cartLinksPopover: {
        "& .links-list": {
            "list-style": "none",
            padding: 0,
            width: "260px",

            "& li": {
                padding: "3px 6px",

                "&.unfulfilled": {
                    "background-color": "red",
                    color: "white"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type CartLinksPopOverProps = {
    cartLinks: ICartLinkWithInfo[];
}

export class CartLinksPopOver extends DialogComponentBase {
    static defaultProps: Partial<CartLinksPopOverProps> = {
    }

    constructor(private props : CartLinksPopOverProps) {
        super({ popover: true });

        this.title(TextResources.Allocations.CartLinks);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return ComponentUtils.bindTo((
            <div className={ComponentUtils.classNames(classes.cartLinksPopover, "row")}>
                <div className="col-md-12">
                    <ul className="links-list">
                        {this.props.cartLinks.map((l) => <li className={ComponentUtils.classNames({ unfulfilled: l.IncompleteAllocation || (l.LinkCartIsNotAllocated && !l.LinkCartIsClosed) || l.LinkCartNotFound })}>{l.LinkCartTitle}</li>)}
                    </ul>
                </div>
            </div>
        ), this);
    }
}