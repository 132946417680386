import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { TemplateProvider } from "./TemplateProvider";
import { TemplateWithChildrenProvider } from "./TemplateWithChildrenProvider";
import { ITodoListTemplateForList } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { INavigationMenuProvider } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../../../../../Core/Deferred";

export class RootNavigationProvider extends WorkflowsNavigatorProvider implements IChangesNotificationsServiceObserver {
    private changesNotificationsService: IChangesNotificationsService;
    public Groups: ko.ObservableArray<TemplateGroup> = ko.observableArray();

    constructor(serviceLocator: IServiceLocator, private navigateTasks: boolean) {
        super(serviceLocator);
        this.changesNotificationsService = <IChangesNotificationsService>serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);
        this.Name = ProlifeSdk.TextResources.Todolist.WorkflowModels;
        this.AllowMultiLevelSearch = navigateTasks;
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowTemplateEntityTypeCode, this);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        this.refreshItems();
        return false;
    }

    search(textFilter: string): Promise<void> {
        return this.navigateTasks ? this.multiLevelSearch(textFilter) : this.simpleSearch(textFilter);
    }

    private simpleSearch(textFilter: string): Promise<void> {
        var adaptedFilter: string = (textFilter || "").toUpperCase().trim();
        var filterResult = this.Items().filter((p) => {
            return p.Name.toUpperCase().indexOf(adaptedFilter) > -1;
        });
        this.SearchedItems(filterResult);
        return Promise.resolve<void>(undefined);
    }

    private multiLevelSearch(textFilter: string): Promise<void> {
        var resultDef = new Deferred<void>();

        var defs: Promise<void>[] = [];
        var results: any[] = [];
        this.Items().forEach((p) => {
            defs.push(p.search(textFilter));
        });

        Promise.all(defs).then(() => {
            results = this.Items().map((p) => {
                return { title: p.Name, results: p.SearchedItems() };
            });
            this.SearchedItems(results);
            resultDef.resolve();
        });

        return resultDef.promise();
    }

    private CreateItemViewModel(t: ITodoListTemplateForList): INavigationMenuProvider {
        var p: INavigationMenuProvider = this.navigateTasks
            ? <any>new TemplateWithChildrenProvider(this.serviceLocator, t)
            : <any>new TemplateProvider(this.serviceLocator, t);
        p.setCurrentNavigator(this.navigator());
        return p;
    }

    public RemoveFromList(templateItem: any) {
        templateItem.Dispose();
        this.Items.remove(templateItem);
    }

    refreshItems(): Promise<void> {
        var d = new Deferred<void>();

        var oldItems: TemplateProvider[] = <any>this.Items();

        var templatesItems = [];
        var templatesGroups = [];

        this.todoListService.GetTemplatesForList("", 0, 50000).then((templates: ITodoListTemplateForList[]) => {
            var templatesDefs: Promise<void>[] = [];

            templates.forEach((t: ITodoListTemplateForList) => {
                var oldItem: TemplateProvider[] = oldItems.filter((o: TemplateProvider) => {
                    return o.template.Id == t.Id;
                });
                var p: INavigationMenuProvider = oldItem.length > 0 ? <any>oldItem[0] : this.CreateItemViewModel(t);
                templatesDefs.push((<any>p).RefreshData(t));
                templatesItems.push(p);

                var groups = templatesGroups.filter((g) => g.Title() == (t.CategoryName || ProlifeSdk.TextResources.Todolist.NoCategory));
                if (groups.length == 0) {
                    var group = new TemplateGroup(t.CategoryName || ProlifeSdk.TextResources.Todolist.NoCategory);
                    templatesGroups.push(group);
                    groups.push(group);
                }

                groups[0].Items.push(p);
            });

            //Dispongo gli items rimossi
            oldItems
                .filter((i: TemplateProvider) => {
                    return templatesItems.indexOf(i) == -1;
                })
                .forEach((toDispose: TemplateProvider) => {
                    toDispose.Dispose();
                });

            this.Items(templatesItems);
            this.Groups(templatesGroups);

            Promise.all(templatesDefs)
                .then(() => {
                    d.resolve();
                })
                .catch(() => {
                    d.reject([]);
                });
        });

        return d.promise();
    }

    public Dispose() {
        this.changesNotificationsService.RemoveObserver(this);
        this.Items().forEach((i: any) => {
            i.Dispose();
        });
        this.Items([]);
    }

    GetSelectedTasksIds(): number[] {
        var ids: number[] = [];
        this.Items().forEach((p: any) => {
            p.GetSelectedTasksIds().forEach((id) => {
                ids.push(id);
            });
        });
        return ids;
    }

    SetSelectedTasks(ids: number[]): Promise<void> {
        var def = new Deferred<void>();

        if (!this.navigateTasks) return def.resolve().promise();

        var itemsDefs: Promise<any>[] = [];

        this.Items().forEach((p: any) => {
            itemsDefs.push(p.SetSelectedTasks(ids));
        });

        Promise.all(itemsDefs).then(() => {
            def.resolve();
        });
        return def.promise();
    }

    SetSelectedTemplates(ids: number[]) {
        this.Items().forEach((p: any) => {
            if (ids.indexOf(p.template.Id) > -1) this.navigator().selectProvider(p, false);
        });
    }
}

class TemplateGroup {
    Title: ko.Observable<string> = ko.observable();
    Collapsed: ko.Observable<boolean> = ko.observable(false);

    Items: ko.ObservableArray<any> = ko.observableArray([]);

    HasItems: ko.Computed<boolean>;

    constructor(name: string) {
        this.Title(name);

        this.HasItems = ko.computed(() => {
            return this.Items().length > 0;
        });
    }

    public SwitchCollapsed() {
        this.Collapsed(!this.Collapsed());
    }
}
