import * as ko from "knockout";
export class OnKeyPress
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var options = ko.utils.unwrapObservable(valueAccessor());

        element.addEventListener('keydown', (event) =>
        {
            var keycode = event.keyCode || event.which;

            if(!event.shiftKey && (keycode == options.keyCode))
                options.funcToInvoke.apply(viewModel);

            return true;
        });
    }

}

ko.bindingHandlers["onKeyPress"] = new OnKeyPress();
