import * as ko from "knockout";
import { IDocumentOffset, IOffsetsService } from "../Invoices/OffsetsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IDocumentOffsetsDataSourceModel extends IDataSourceModel<number, IDocumentOffset> {

}

export interface IDocumentOffsetsDataSource extends IDataSource {

}

export class DocumentOffsetsDataSource implements IDocumentOffsetsDataSource {
    @LazyImport(nameof<IOffsetsService>())
    private offsetsService : IOffsetsService;

    private view : IDataSourceView;
    
    getTitle(currentModel: IDocumentOffsetsDataSourceModel): string {
        return "Contropartite";
    }    
    
    isGroupedData(currentModel: IDocumentOffsetsDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IDocumentOffsetsDataSourceModel, b: IDocumentOffsetsDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IDocumentOffsetsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDocumentOffsetsDataSourceModel[]> {
        if(skip != 0) return [];

        var offsets = await this.offsetsService.GetOffsets(false, currentModel ? currentModel.id : null);
        return offsets.map(this.createModelFor, this);
    }
    
    async getById(currentModel: IDocumentOffsetsDataSourceModel, ids: number[]): Promise<IDocumentOffsetsDataSourceModel[]> {
        var offsets = await this.offsetsService.GetOffsetsByIds(ids);
        return offsets.map(this.createModelFor, this);
    }

    private createModelFor(offset : IDocumentOffset) : IDocumentOffsetsDataSourceModel {
        return {
            id: offset.Id,
            title: offset.Description,
            subTitle: offset.Code,
            isLeaf: false,
            isGroup: false,
            model: offset
        };
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;
    }

    refresh() {
        if(this.view) {
            this.view.refresh();
        }
    }

    select(model: IDocumentOffset) {
        if(this.view) {
            this.view.select(this.createModelFor(model));
        }
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}