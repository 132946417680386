import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { PlanningApplication } from "./planning/PlanningApplication";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IPlanningService, IAvailableWorkPerPeriodResult, IPlanningHistoricalEconomics, IPlanningDetailedEconomics, ICompanyAnalysis, IPlanningJobOrder, IPlanningMoveResult, IPlanningWorkflow, IJobOrderPerformance, IGetCompanyAnalysisRequest, IGetPlanningSnapshotsRequest, IPlanningSnapshot, IPlanningSnapshot_Type, IInsertedIdsMapping, IPlanningSnapshotData_Type, IPlanningSnapshotData } from "../ProlifeSdk/interfaces/planning/IPlanningService";
import { IService } from "../Core/interfaces/IService";
import { LazyImport } from "../Core/DependencyInjection";

class PlanningService implements IPlanningService
{

    private application : PlanningApplication;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IPlanningService>(), this);
    }

    InitializeService() {
        this.application = new PlanningApplication(this.serviceLocator);
    }

    getServiceType(): string
    {
        return ProlifeSdk.PlanningServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getAvailableWorkPerPeriod(): Promise<IAvailableWorkPerPeriodResult> {
        return this.ajaxService.Post("Planning-api/Planning", "GetAvailableWorkPerPeriod", {
            background: true
        });
    }

    getHistoricalEconomicsForTypeAndState(typeId : number, stateId : number, startDate : Date, endDate : Date) : Promise<IPlanningHistoricalEconomics> {
        return this.ajaxService.Post("Planning-api/Planning", "GetHistoricalEconomicsForTypeAndState", {
            methodData: {
                jobOrderCategoryId : typeId,
                jobOrderStateId : stateId,
                startDate : startDate,
                endDate : endDate
            },
            background: false
        });
    }

    getDetailedEconomics(typeId : number, stateId : number, startDate : Date, endDate : Date) : Promise<IPlanningDetailedEconomics[]> {
        return this.ajaxService.Post("Planning-api/Planning", "GetDetailedEconomics", {
            methodData: {
                jobOrderCategoryId : typeId,
                jobOrderStateId : stateId,
                startDate : startDate,
                endDate : endDate
            },
            background: false
        });
    }

    getJobOrders(typeId : number, stateId : number, startDate : Date, endDate : Date) : Promise<IPlanningJobOrder[]> {
        return this.ajaxService.Post("Planning-api/Planning", "GetJobOrders", {
            methodData: {
                jobOrderCategoryId : typeId,
                jobOrderStateId : stateId,
                startDate : startDate,
                endDate : endDate
            },
            background: false
        });
    }

    updateJobOrder(jobOrder : IPlanningJobOrder) : Promise<void> {
        return this.ajaxService.Post("Planning-api/Planning", "UpdateJobOrder", {
            methodData: jobOrder,
            background: false
        });
    }

    moveJobOrder(jobOrder : IPlanningJobOrder) : Promise<IPlanningMoveResult> {
        return this.ajaxService.Post("Planning-api/Planning", "MoveJobOrder", {
            methodData: jobOrder,
            background: false
        });
    }

    updateWorkflow(workflow : IPlanningWorkflow) : Promise<void> {
        return this.ajaxService.Post("Planning-api/Planning", "UpdateWorkflow", {
            methodData: workflow,
            background: false
        });
    }

    GetJobOrdersPerformance(stateIds: number[] | null, typeIds: number[] | null): Promise<IJobOrderPerformance[]> {
        let result = this.ajaxService.Post<IJobOrderPerformance[]>("Planning-api/Planning", "GetJobOrdersPerformance", {
            background: true,
            methodData: {
        		stateIds: stateIds,
        		typeIds: typeIds,
        	}
        });



        return result;
    }

    GetCompanyAnalysis(request: IGetCompanyAnalysisRequest): Promise<ICompanyAnalysis[]> {
        let result = this.ajaxService.Post<ICompanyAnalysis[]>("Planning-api/Planning", "GetCompanyAnalysis", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetPlanningSnapshots(textFilter: string | null, skip: number | null, count: number | null): Promise<IPlanningSnapshot[]> {
        let result = this.ajaxService.Post<IPlanningSnapshot[]>("Planning-api/Planning", "GetPlanningSnapshots", {
            background: true,
            methodData: {
        		textFilter: textFilter,
        		skip: skip,
        		count: count,
        	}
        });



        return result;
    }

    GetPlanningSnapshotsByIds(ids: number[] | null): Promise<IPlanningSnapshot[]> {
        let result = this.ajaxService.Post<IPlanningSnapshot[]>("Planning-api/Planning", "GetPlanningSnapshotsByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });



        return result;
    }

    GetSnapshotData(snapshotId: number | null): Promise<IPlanningSnapshotData[]> {
        let result = this.ajaxService.Post<IPlanningSnapshotData[]>("Planning-api/Planning", "GetSnapshotData", {
            background: true,
            methodData: {
        		snapshotId: snapshotId,
        	}
        });



        return result;
    }

    CreateOrUpdateSnapshot(snapshots: IPlanningSnapshot_Type[] | null, snapshotsData: IPlanningSnapshotData_Type[] | null): Promise<IInsertedIdsMapping[]> {
        let result = this.ajaxService.Post<IInsertedIdsMapping[]>("Planning-api/Planning", "CreateOrUpdateSnapshot", {
            background: true,
            methodData: {
        		snapshots: snapshots,
        		snapshotsData: snapshotsData,
        	}
        });



        return result;
    }

    DeleteSnapshot(snapshotId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Planning-api/Planning", "DeleteSnapshot", {
            background: true,
            methodData: {
        		snapshotId: snapshotId,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new PlanningService(serviceLocator);
}
