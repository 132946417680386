import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
    'prolife-progress-bar': {

    }
});
const { classes } = styleSheet.attach();

type ProgressBarProps = {
    progress: ko.MaybeSubscribable<number>;
    barStyle?: "success"|"info"|"warning"|"danger";
    striped?: boolean;
    className?: string;
}

export function ProgressBar(props: ProgressBarProps) {
    const C = require("./ProgressBar")._ProgressBar as typeof _ProgressBar;
    return <C {...props} />;
}

export class _ProgressBar {
    static defaultProps: Partial<ProgressBarProps> = {
        barStyle: "success",
        striped: false
    }

    private ProgressPercentage: ko.Computed<string>;

    constructor(private props: ProgressBarProps) {
        this.ProgressPercentage = ko.computed(() => {
            const progress = ko.unwrap(this.props.progress);
            return progress + "%";
        });   
    }
    
    render() {
        const pb = this;

        const styles = "progress-bar-" + this.props.barStyle;

        return  ComponentUtils.bindTo((
            <div className={classNames("progress", classes["prolife-progress-bar"], { 'progress-striped': this.props.striped })}>
                <div className={classNames("progress-bar", styles)} style="min-width: 0" role="progressbar" aria-valuemin={0} aria-valuemax={100} data-bind={{ attr: { 'aria-valuenow': pb.props.progress }, style: { width: pb.ProgressPercentage } }}></div>
            </div>
        ), this, "pb");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ProgressBar);
}