import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IWorkflowForSelectList, ITodoListService, IGetWorkflowsForSelectListRequest } from "../ProlifeSdk/interfaces/todolist/ITodoListService";

export class WorkflowsForSelectListDataSource implements IDataSource {
    private resourceId: number;
    private jobOrderId: number;
    private viewAll: boolean;
    private viewWorker: boolean;
    private viewResponsible: boolean;
    private forceJobOrderFilter: boolean = false;
    private filterCallback: (model: IWorkflowForSelectList) => boolean;
    
    private view: IDataSourceView;

    private showJobOrderName: boolean = true;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    
    constructor() {}

    public getTitle(currentModel: IDataSourceModel<number, IWorkflowForSelectList>): string {
        return "";
    }
    
    public async getData(currentModel: IDataSourceModel<number, IWorkflowForSelectList>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, IWorkflowForSelectList>[]> {

        let request: IGetWorkflowsForSelectListRequest = {
            textFilter: textFilter,
            resourceId: this.resourceId,
            jobOrderId: this.jobOrderId,
            viewAll: this.viewAll,
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            forceJobOrderFilter: this.forceJobOrderFilter,
            skip: skip,
            count: count
        };

        let workflows = await this.todoListService.GetWorkflowsForSelectList(request);

        if (this.filterCallback)
            workflows = workflows.filter((m) => this.filterCallback(m));

        return workflows.map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IDataSourceModel<number, IWorkflowForSelectList>, ids: number[]): Promise<IDataSourceModel<number, IWorkflowForSelectList>[]> {
        let workflows = await this.todoListService.GetWorkflowsForSelectListByIds(ids);
        return workflows.map(this.createModelFor, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setResourceId(id: number): void {
        this.resourceId = id;
    }

    public setJobOrderId(id: number): void {
        this.jobOrderId = id;
    }

    public setForceJobOrderFilter(value: boolean) {
        this.forceJobOrderFilter = value;
    }

    public setViewAll(value: boolean): void {
        this.viewAll = value;
    }

    public setViewWorker(value: boolean): void {
        this.viewWorker = value;
    }

    public setViewResponsible(value: boolean): void {
        this.viewResponsible = value;
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setShowJobOrderName(value: boolean): void {
        this.showJobOrderName = value;
    }

    public setFilterCallback(callback: (model: IWorkflowForSelectList) => boolean) {
        this.filterCallback = callback;
    }

    public isGroupedData(currentModel: IDataSourceModel<number, IWorkflowForSelectList>, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IDataSourceModel<number, IWorkflowForSelectList>, b: IDataSourceModel<number, IWorkflowForSelectList>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(workflow: IWorkflowForSelectList): IDataSourceModel<number, IWorkflowForSelectList> {
        return {
            id: workflow.Id,
            title: workflow.Title,
            subTitle: this.showJobOrderName ? workflow.JobOrderName : "",
            isGroup: false,
            isLeaf: true,
            icon: {
                icon: workflow.Icon,
                background: workflow.Background,
                foreground: workflow.Foreground
            },
            model: workflow

        };
    }
}