import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDocumentTypes, IDocumentType } from "../../../ProlifeSdk/interfaces/invoice/settings/IDocumentTypes";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class DocumentTypesSettingsManager implements IDocumentTypes {
    private ajaxService : IAjaxService;
    private documentTypes : IDocumentType[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<IDocumentType[]> {
        var def = new Deferred<IDocumentType[]>();

        this.ajaxService.Get("Invoices-api", "DocumentType", {})
            .then((documentTypes: IDocumentType[]) => {
                this.documentTypes = documentTypes;
                def.resolve(documentTypes);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.DocumentTypes;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.DocumentTypes;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return null;
    }

    getDocumentTypes() : IDocumentType[] {
        return this.documentTypes;
    }
}