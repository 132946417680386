import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Document, IDocumentConfiguration } from "../Document";

export class CreditNoteConfiguration implements IDocumentConfiguration {
    DocumentType: string = ProlifeSdk.CreditNoteEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(true);

        document.DDTCausesDataSource.setShowOnlySalesCauses(true);

        document.CanHaveDestinationRecipient(false);
        document.CanBeAnElectronicInvoice(true);
        // Secondo questo documento (https://www.fpcu.it/App_Common/Files/FilesEventi/385860/iva_2016_11-12-Bollo%20fatture%20(1).pdf)
        // è possibile avere il bollo quando le note di credito sono senza indicazione IVA oppure se sono riferite ad operazioni
        // originariamente non assoggettate IVA.
        // Mi verrebbe quindi da dire che possono avere l'imposta di bollo
        document.CanHaveStampDuty(true); //TODO: Rifarsi la domanda. Verificare con quali logiche va applicato.
        document.CanBeDownPayment(false);
        document.CanHaveSecondaryRecipients(true);
        document.CanBeForPublicAdministration(true);
        document.CanHaveWithholdingTax(true);
        document.CanHideAmounts(false);
        document.CanSelectCustomers(true);
        document.CanSelectSuppliers(false);
        document.CanHaveRelatedDocuments(true);
        document.CanHaveSourceWarehouse(false);
        document.CanHaveDestinationWarehouse(false);
        document.CanHaveShippingInformation(false);
        document.CanHaveCause(false);
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(true); //TODO: Ci sono?
        document.CanHaveTaxReport(true);
        document.CanReferenceArticles(true); //TODO: E' vero?
        document.CanHaveVAT(true);
        document.CanCloseRows(true);
        document.CanHaveLetterOfAttempts(true);
        document.CanViewOldLetterOfAttempts(true);
        document.CanChangeDiscountCatalog(true); //TODO: E' vero?
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(false);
        document.CanEditRevisionNumber(false);
        document.CanHaveSchedules(false);
        document.CanExportElectronicInvoices(true);
        document.CanHaveState(false);
        document.CanSelectClosedLettersOfAttempt(true);
        document.CanHaveCIGCUP(false);
        document.MustCheckTrust(false);
        document.IsCustomerDocument(true);
        document.ShowVersionNumbersSuggestions(false);
        document.RecipientPosition("right");

        document.ExcelExporterId("Invoices/DocumentExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.CreditNoteLabel);
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    print(documentId: number) {
        this.dialogsService.OpenUrlAsDialog("/Invoices/Print/Index/" + documentId, "Stampa Nota di Credito");
        /*var popup = window.open("about:blank", "_blank");
        popup.location.href = "/Service/Invoices/Print/Index/" + documentId;*/
    }
}
