import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 30/08/2017
 * Time: 09:46
 * To change this template use File | Settings | File Templates.
 */

import { ContactIdentifier } from "./ContactIdentifier";
import { QueueWithManualContactSelection } from "./QueueWithManualContactSelection";
import { IContactForList } from "../../../../ProlifeSdk/interfaces/survey-wizard/IContactForList";

export class ContactForList extends ContactIdentifier {
    public Selected: ko.Observable<boolean> = ko.observable(false);

    constructor(contact: IContactForList, private parent: QueueWithManualContactSelection) {
        super(contact);
    }

    public Select(): void {
        this.parent.SelectContact(this);
    }
}