import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";

export class JobOrderStatesControlsEntityProvider implements IControlsEntityProvider
{
    private statesManager : IJobOrderStateSettingsManager;
    private lastTimeout : number;

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <IJobOrderStateSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
    }

    public findEntities(query : any)
    {
        var searchFilter : string = query.term || "";

        var states : IJobOrderState[] = this.statesManager.getJobOrderStates().filter((s : IJobOrderState) => {
            s.Description.toUpperCase().indexOf(searchFilter.trim().toUpperCase()) > -1;
        });

        query.callback({
            results: states.map((s : IJobOrderState) => {
                return {
                    id: s.IdJobOrderStateId,
                    text: s.Description
                };
            })
        });
    }

    public findEntity(element, callback) {
        var id = parseInt(<string> $(element).val());
        if(!isNaN(id) && id > 0)
        {
            var s : IJobOrderState = this.statesManager.getJobOrderStateById(id);

            callback({
                id: s.IdJobOrderStateId,
                text: s.Description
            });
        }
    }
}