import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IJobOrderForReportViewModel, IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { ITemplatesNavigator } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class JobOrderForReport implements IJobOrderForReportViewModel
{
    @LazyImport(nameof<IJobOrderService>())
    public jobOrderService : IJobOrderService;
    @LazyImport(nameof<IBlogService>())
    public blogService : IBlogService;

    IsChecked : ko.Observable<boolean> = ko.observable(false);
    Name : string;
    Id : number;

    constructor(jsonData)
    {
        this.Name = jsonData.JobOrderName;
        this.Id = jsonData.JobOrderId;
    }

    SwitchDone(): void
    {
        this.IsChecked(!this.IsChecked());
    }

    OpenDetails(): void
    {
        window.open(this.jobOrderService.getJobOrderUrl(this.Id), "_blank");
    }

    OpenBlog(): void
    {
        this.blogService.openBlogInNewWindow(this.Id)
    }
}

export class JobOrdersListReport extends ProLifeReport implements IReport, IReportViewModel, INavigationMenuObserver
{
    public todoListService : ITodoListService;
    public logService : ILogService;
    public jobOrderService : IJobOrderService;
    public settingsService : ISettingsService;
    public desktopService : IDesktopService;

    Name: string = "";
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";
    viewModel : any;

    public navigator : IReportsNavigator;

    public From : ko.Observable<Date> = ko.observable();
    public To : ko.Observable<Date> = ko.observable();
    public ActivitiesFilter : ITemplatesNavigator;
    public StateId : ko.Observable<number> = ko.observable(-1);
    public TypeId : ko.Observable<number> = ko.observable(-1);

    public JobOrderStates : IJobOrderState[];
    public JobOrderTypes : IJobOrderType[];

    constructor(serviceLocator : IServiceLocator, groupId : number, reportId : number)
    {
        super(groupId, reportId);

        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.desktopService = <IDesktopService> serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        var jobOrderStateSettingsManager : IJobOrderStateSettingsManager = <IJobOrderStateSettingsManager> this.settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
        var states : IJobOrderState[] = jobOrderStateSettingsManager.getJobOrderStates();
        this.JobOrderStates = <IJobOrderState[]>states.slice(0);
        this.JobOrderStates.splice(0,0, <IJobOrderState>{
            IdJobOrderStateId : -1,
            Description : ProlifeSdk.TextResources.JobOrder.All,
            LogicalState : 0
        });
        var jobOrderTypesSettingsManager : IJobOrderTypesSettingsManager = <IJobOrderTypesSettingsManager> this.settingsService.findSettingsManager(ProlifeSdk.JobOrderType);
        var types : IJobOrderType[] = jobOrderTypesSettingsManager.getJobOrderTypes();
        this.JobOrderTypes = <IJobOrderType[]>types.slice(0);
        this.JobOrderTypes.splice(0,0, <IJobOrderType>{
            Id : -1,
            Description : ProlifeSdk.TextResources.JobOrder.All
        });

        this.ActivitiesFilter = this.todoListService.GetTemplatesNavigatorUI();
        this.ActivitiesFilter.addMenuObserver(this);

        var from = moment(new Date());
        from.date(1);
        this.From(from.toDate());

        var to = moment(new Date());
        to.add(1, 'months').date(1).subtract(1, 'days');
        this.To(to.toDate());

        this.From.subscribe(this.NotifyFilterChange.bind(this));
        this.To.subscribe(this.NotifyFilterChange.bind(this));
        this.StateId.subscribe(this.NotifyFilterChange.bind(this));
        this.TypeId.subscribe(this.NotifyFilterChange.bind(this));
    }

    public notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[])
    {
    }

    public onSelectionChanged(selection: INavigationMenuProvider[])
    {
        this.NotifyFilterChange();
    }

    SelectType(typeId : number)
    {
        this.TypeId(typeId);
    }

    SelectState(stateId : number)
    {
        this.StateId(stateId);
    }

    Open()
    {
        this.navigator.openReport(this);
    }

    setNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
    }

    getViewModel(): IReportViewModel
    {
        return this;
    }

    initialize()
    {
        //To be implemented into children
        super.initialize();
        this.ActivitiesFilter.Initialize();
    }
}


