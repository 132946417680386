import moment = require("moment");
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { IGetWarehouseArticlesStockForWarehouseInspectionRequest, IWarehouseArticleStock, IWarehouseInspectionWizardArticleInfo } from "../../../../../WarehouseInspectionsService";
import { ArticleRequirements as ArticleRequirements, IArticleRequirement } from "./ArticleRequirements";
import { WarehouseInspectionAction } from "../../Enums/WarehouseInspectionAction";
import { IWarehouseArticlesImporterForInspectionInitializationInfo } from "../WarehouseArticlesImporterForInspection";
import { SelectedArticleInfo } from "./SelectedArticleInfo";
import { _WarehouseArticlesImporterDataSourceBase } from "./WarehouseArticlesImporterDataSourceBase";
import { SourceArticlesTableFilters } from "./SourceArticlesTable";

export class WarehouseInventoryDataSource extends _WarehouseArticlesImporterDataSourceBase {
    public TextFilter : ko.Observable<string> = ko.observable();
    public PeriodSinceLastInspection : ko.Observable<number> = ko.observable(60);

    constructor(props: IWarehouseArticlesImporterForInspectionInitializationInfo) {
        super(props);
        
        this.showLastInspectionDateColumn = true;
        this.sourcesFromWarehouseStocks = true;
        this.defaultMaxShownArticlesNumberFilter = 100;
        this.defaultLastInspectionDateFilter = moment().startOf("day").add(-1, "month").toDate();

        this.Title(TextResources.Warehouse.WarehouseInventoryDataSource);
    }

    public importSelectedArticles(): void {
        let selectedArticles = this.Articles().filter(a => a.Selected() || a.Selected() === null);
        let actualImportedArticles = this.ChoosenArticles();

        for (let article of selectedArticles) {
            let importedArticle = actualImportedArticles.firstOrDefault(a => a.DestinationWarehouseId === article.WarehouseId);
            let operationsInfo = article.SelectedRequirementsInfo()
                                        .filter(s => s.Selected())
                                        .map(s => s.getData())
                                        .map(s => this.createWizardWarehouseInspectionOperationInfo(s));

            if (!importedArticle) {
                this.ChoosenArticles.push(new SelectedArticleInfo(operationsInfo));
            } else {
                importedArticle.mergeOperationsInfo(operationsInfo);
            }
        }
    }

    protected async loadArticles(): Promise<void> {
        let request: IGetWarehouseArticlesStockForWarehouseInspectionRequest = {
            textFilter: null,
            lastWarehouseInspectionFilter: this.defaultLastInspectionDateFilter,
            maxArticlesNumberPerWarehouse: this.defaultMaxShownArticlesNumberFilter,
            warehouseId: null,
            skip: 0,
            count: 300
        };

        return this.internalLoadArticles(request);
    }
    
    protected async onFiltersChanges(filters: SourceArticlesTableFilters): Promise<void> {
        let request: IGetWarehouseArticlesStockForWarehouseInspectionRequest = {
            textFilter: null,
            lastWarehouseInspectionFilter: filters.LastInspectionDateFilter,
            maxArticlesNumberPerWarehouse: filters.MaxShownArticlesNumberPerWarehouse,
            warehouseId: null,
            skip: 0,
            count: 300
        };

        return this.internalLoadArticles(request);
    }

    private async internalLoadArticles(request: IGetWarehouseArticlesStockForWarehouseInspectionRequest): Promise<void> {
        let articles = await this.warehouseInspectionsService.GetWarehouseArticlesStockForWarehouseInspection(request);
        this.Articles(this.createArticlesViewModels(articles));
    }

    private createArticlesViewModels(articles: IWarehouseArticleStock[]): ArticleRequirements[] {
        let result: ArticleRequirements[] = [];
        let lastArticle: ArticleRequirements = null;

        for (let articleStock of articles) {
            if (!lastArticle || lastArticle.WarehouseId !== articleStock.WarehouseId) {
                lastArticle = new ArticleRequirements(articleStock);
                result.push(lastArticle);
            }

            lastArticle.addOrUpdateRequirement(articleStock);
        }

        return result;
    }

    private createWizardWarehouseInspectionOperationInfo(articleRequirementInfo: IArticleRequirement): IWarehouseInspectionWizardArticleInfo {
        return {
            Id: this.warehouseInspectionsService.GetNextFakeId(),
            WarehouseInspectionId: this.props.WarehouseInspectionId,
            ArticleId: articleRequirementInfo.ArticleId,
            ArticleDescription: articleRequirementInfo.ArticleDescription,
            ArticleCode: articleRequirementInfo.ArticleCode,
            EanCode: articleRequirementInfo.EanCode,
            MefCode: articleRequirementInfo.MefCode,
            SourceWarehouseId: articleRequirementInfo.WarehouseId,
            SourceWarehouse: articleRequirementInfo.Warehouse,
            SourceWarehouseStock: articleRequirementInfo.Amount,
            DestinationWarehouseId: articleRequirementInfo.WarehouseId,
            DestinationWarehouse: articleRequirementInfo.Warehouse,
            DestinationWarehouseStock: articleRequirementInfo.Amount,
            CustomerId: articleRequirementInfo.CustomerId,
            CustomerName: articleRequirementInfo.CustomerName,
            JobOrderId: articleRequirementInfo.JobOrderId,
            JobOrderName: articleRequirementInfo.JobOrderName,
            Amount: articleRequirementInfo.Amount,
            LastInspectionDate: articleRequirementInfo.LastInspectionDate,
            OperationType: WarehouseInspectionAction.StockCheck,

            Sources: []
        };
    }
}