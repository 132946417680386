import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { NavigationMenu } from "../../../Components/NavigationMenu";
import { AllocationsMenuDataSource, IJobOrderForAllocationsDataSourceModel, IWorkflowForAllocationsDataSourceModel } from "../../../DataSources/AllocationsMenuDataSource";
import { INavigationMenuComponent, INavigationMenuComponentAction, INavigationMenuComponentActionSeparator, INavigationMenuComponentActionsGroup, INavigationMenuComponentModel } from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { AlertsLegendUI } from "../../../Components/AlertsLegend";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { WorkflowsMenuSearchModes } from "../../../Todolist/Todolist/enums/WorkflowsMenuSearchModes";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../DataSources/IDataSource";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";

export type AllocationsJobOrdersMenuProps = {
    useWhiteTheme?: boolean;
    forwardRef?: (ref: AllocationsJobOrdersMenu) => void;
}

export class AllocationsJobOrdersMenu implements IDataSourceListener {
    public AllocationsMenuDataSource: AllocationsMenuDataSource;

    private Menu: ko.Observable<INavigationMenuComponent> = ko.observable(null);
    private ShowingWorkflows: ko.Observable<boolean> = ko.observable(false);
    private ShowingTasks: ko.Observable<boolean> = ko.observable(false);
    private selectedJobOrder: number = null;
    private selectedWorkflow: number = null;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService : IAuthorizationService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    constructor(private props: AllocationsJobOrdersMenuProps) {
        this.AllocationsMenuDataSource = new AllocationsMenuDataSource();

        this.Menu.subscribe((navigationMenu) => {
            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            }

            navigationMenu.registerAction(showLegendAction);
            
            const action: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-filter",
                text: "",
                activeClass: "red",
                defaultClass: "btn-transparent",
                active: () => this.AllocationsMenuDataSource.AdvancedFilterIsActive(),
                canExecute: () => true,
                action: () => {
                    this.AllocationsMenuDataSource.editAdvancedFilters();
                },
            }
            
            navigationMenu.registerAction(action);

            const workflowActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingWorkflows(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow")) {
                const insertWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflow();
                    }
                }

                workflowActions.actions.push(insertWorkflowAction);
            }
            
            if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow")) {
                const cloneWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromWorkflow();
                    }
                }

                workflowActions.actions.push(cloneWorkflowAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")) {
                const copyWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromTemplate();
                    }
                }

                workflowActions.actions.push(copyWorkflowAction);
            }

            const showingSecondaryActions = ko.observable(false);

            if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.Menu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.Menu().showSecondaryAction(false)
                    }
                };

                if (workflowActions.actions.length > 0) {
                    workflowActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                workflowActions.actions.push(editingAction);
                workflowActions.actions.push(endEditingAction);
            }

            if (workflowActions.actions.length > 0)
                navigationMenu.registerAction(workflowActions);

            const tasksActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingTasks(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertTask")) {
                const insertTaskAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: TextResources.Todolist.NewTask,
                    visible: () => this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        this.CreateTask();
                    }
                };

                tasksActions.actions.push(insertTaskAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.Menu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.Menu().showSecondaryAction(false)
                    }
                };

                if (tasksActions.actions.length > 0) {
                    tasksActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                tasksActions.actions.push(editingAction);
                tasksActions.actions.push(endEditingAction);
            }

            if (tasksActions.actions.length > 0)
                navigationMenu.registerAction(tasksActions);

            navigationMenu.registerSearchAction({
                text: "Cerca piano",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchWorkflows);
                    if (this.Menu().getTextFilter())
                        this.Menu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                isDefault: true,
                visible: () => this.ShowingWorkflows()
            });

            navigationMenu.registerSearchAction({
                text: "Cerca attività",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchActivitiesInWorkflows);
                    if (this.Menu().getTextFilter())
                        this.Menu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                visible: () => this.ShowingWorkflows()
            })
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    refresh(): void {
        this.AllocationsMenuDataSource.refresh();
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {}
    
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {}
    
    public onNavigate(sender: IDataSource, ...history: INavigationMenuComponentModel[]): void {
        if (history.length === 0) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(false);

            this.selectedJobOrder = null;
            this.selectedWorkflow = null;

            return;
        }

        const model = history[history.length - 1];

        const jobOrderModel = model as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel && jobOrderModel.isJobOrder) {
            this.ShowingWorkflows(true);
            this.ShowingTasks(false);

            this.selectedJobOrder = jobOrderModel.id;

            return;
        }

        const workflowModel = model as IWorkflowForAllocationsDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(true);

            this.selectedWorkflow = workflowModel.id;
        }
    }

    private async CreateNewWorkflow(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowDialog(this.selectedJobOrder);
        this.AllocationsMenuDataSource.refresh();
    }

    private async CreateNewWorkflowFromWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder);
        this.AllocationsMenuDataSource.refresh();
    }

    private async CreateNewWorkflowFromTemplate(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder);
        this.AllocationsMenuDataSource.refresh();
    }

    private async CreateTask(): Promise<void> {
        await this.todoListService.ShowCreateNewTaskDialog(this.selectedJobOrder, this.selectedWorkflow, { initialViewAll: true });
        this.AllocationsMenuDataSource.refresh();
    }
    
    render() {
        return <NavigationMenu
                    dataSource={this.AllocationsMenuDataSource}
                    disableSelection
                    listener={this}
                    whiteTheme={!!this.props.useWhiteTheme}
                    forwardRef={(menu) => this.Menu(menu) }
                />;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(AllocationsJobOrdersMenu);
}