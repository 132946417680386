import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IEstimateStates, IEstimateState } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolSetting } from "../../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { DefaultValuesSettingsUi } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";
import { DefaultValueType } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValueType";

export class SupplierOrderDefaultValuesSettingsUi extends DefaultValuesSettingsUi {
    @LazyImportSettingManager(ProlifeSdk.EstimateStates)
    private estimateStatesManager: IEstimateStates;
    private States: IEstimateState[];
    private DefaultState: ko.Observable<IEstimateState> = ko.observable(null);

    constructor(protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);

        this.templateUrl = "warehouse/templates/defaultvalues";
        this.templateName = "supplier-order-default-values";

        this.States = this.estimateStatesManager.getEstimateStates();
    }

    SetDefaultState(value: IEstimateState) {
        this.DefaultState(value);
    }

    LoadSettings(settings: IProtocolSetting[], settingsEnabled: boolean) {
        super.LoadSettings(settings, settingsEnabled);

        settings.forEach((s: IProtocolSetting) => {
            if (s.ProtocolId != this.ProtocolId) return;

            if (s.SettingKey == ProlifeSdk.DocumentStateSettingKey) {
                const states = this.States.filter((state: IEstimateState) => {
                    return state.IdStatoPreventivo == s.IntValue;
                });

                this.DefaultState(states.length > 0 ? states[0] : null);
            }
        });
    }

    GetSettings(): IProtocolSetting[] {
        const settings: IProtocolSetting[] = [];

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.DocumentStateSettingKey,
                this.DefaultState() ? this.DefaultState().IdStatoPreventivo : null,
                DefaultValueType.IntValue
            )
        );

        return settings;
    }
}
