import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { WorkEvent } from "../EventViewModels/WorkEvent";
import { ResourceHoursGroup } from "./ResourceHoursGroup";
import { HoursRecordEvent } from "../EventViewModels/HoursRecordEvent";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IWorkSheetRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";
import { ILogFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { Deferred } from "../../../Core/Deferred";

export class WorkInput  extends BlogEventBaseInput
{
    protected blogService : IBlogService;

	public userCharacters : IUserCharacter[];
	public templateName : string= "work-details";
	public title : string = ProlifeSdk.TextResources.Blog.WorkInputTitle;
    public durata: ko.Observable<number> = ko.observable(0);
    public resourcesGroups : ko.ObservableArray<ResourceHoursGroup> = ko.observableArray([]);

    private referencedHours : HoursRecordEvent[] = [];
    private originalHoursRecord : HoursRecordEvent[] = [];

    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver, event : WorkEvent)
    {
        super(serviceLocator, contextEventsObserver);
        this.iconName = "f-icon-clock";

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
		var userCharactersSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
		this.userCharacters = userCharactersSettingsManager.getUserCharacters();

        this.blogService = <IBlogService> this.serviceLocator.findService(ProlifeSdk.BlogServiceType);

        this.setValues(event);
    }

    private SaveAfterSalLinksCheck(def : Deferred<ILogEvent | ILogEvent[]>)
    {
        if(!this.ValidateHours())
        {
            def.reject();
            return;
        }

        var totalTime = 0;

        //Leggo la durata totale sommando il lavorato dei singoli record ore
        //Imposto la data selezionata su tutti gli intervalli orari prima di salvare
        var allRecords : IWorkSheetRow[] = [];
        this.GetAllHoursRecords().forEach((r : HoursRecordEvent) => {
            totalTime += r.Hours();
            allRecords.push(r.getWorkedHoursRecord());
        });

        (<any>this.blogService).workedHoursProvider.checkConsistency(allRecords)
            .then(() => {
                this.durata(totalTime);

                var promise = this.pkEvent() == 0 ?
                    this.logService.insertEventAndChildrenOfType(this.getBlogEvent(), this.getChildEvents(), ProlifeSdk.BlogEventType_WorkedHours) :
                    this.logService.updateEventAndChildrenOfType(this.getBlogEvent(), this.getChildEvents(), ProlifeSdk.BlogEventType_WorkedHours);

                promise
                    .then((data : ILogEvent|ILogEvent[]) => {
                        //Aggiornamento tabella orizzontale
                        var event : ILogEvent = Object.prototype.toString.call(data) === '[object Array]' ? data[0] : data;

                        this.loadHoursEvents(event.EventId)
                            .then(() => {
                                var workedHours : IWorkSheetRow [] = [];
                                this.GetAllHoursRecords().forEach((h : HoursRecordEvent, index : number) => {
                                    var record = h.getWorkedHoursRecord();
                                    record.EventId = event.EventId;
                                    record.Description = this.subject();
                                    workedHours.push(record);
                                });

                                var resourceId : number = -1;
                                workedHours.sort((a : IWorkSheetRow, b : IWorkSheetRow) => {
                                    return a.ResourceId < b.ResourceId ? -1 : (a.ResourceId > b.ResourceId ? 1 : 0);
                                }).forEach((wh : IWorkSheetRow) => {
                                    wh.TravelDistance = wh.ResourceId == resourceId ? 0 : wh.TravelDistance;  //I km li devo settare solo sul primo record di ogni risorsa!
                                    resourceId = wh.ResourceId;
                                });

                                (<any>this.blogService).workedHoursProvider.save(workedHours);
                                def.resolve(data);
                            });
                    })
                    .catch(() => {
                        def.reject();
                    });
            })
        .catch(() => {
           def.reject();
        });
    }



    //Fare override per customizzare il salvataggio
    public save() : Promise<ILogEvent | ILogEvent[]>
    {
        var def = new Deferred<ILogEvent | ILogEvent[]>();

        var referencedHoursAreChanged = false;
        var allRecords = this.GetAllHoursRecords();
        this.referencedHours.forEach(h => {
            referencedHoursAreChanged = referencedHoursAreChanged || allRecords.indexOf(h) < 0
                || h.IsChanged();
        });

        if(referencedHoursAreChanged)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Blog.ReferencedHoursChangedMsg);
            def.reject();
        }
        else
            this.SaveAfterSalLinksCheck(def);

        return def.promise();
    }

    private ValidateHours() : boolean
    {
        var result = true;
        var resourcesIds = [];
        var moreThanOneForResource = false;
        this.resourcesGroups().forEach((g : ResourceHoursGroup) => {

            moreThanOneForResource = moreThanOneForResource || resourcesIds.indexOf(g.WorkerId()) > -1;
            resourcesIds.push(g.WorkerId());

            if(!result)
                return;
            result = result && g.ValidateHours();
        });

        if(moreThanOneForResource)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Blog.DuplicatedResource);
            return false;
        }

        return result;
    }

    public AddNewHoursRecord()
    {
        var newResourceGroup = new ResourceHoursGroup(this.serviceLocator, [], this.contextEventsObserver, true, this.eventDate, this.pkEvent(), this.selectedTasksObservable, this.commessaId);
        this.resourcesGroups.push(newResourceGroup);
    }

    public RemoveHoursRecord(record)
    {
        this.resourcesGroups.remove(record);
    }

    public setValues(item : WorkEvent) : void
    {
        super.setValues(item);
        this.durata(item.Duration());
        this.loadHoursEvents(item.IdEvent());
	}

    private loadHoursEvents(eventId : number) : Promise<void>
    {
        var def = new Deferred<void>();
        this.referencedHours = [];
        this.originalHoursRecord = [];
        this.resourcesGroups([]);

        var filters : ILogFilter[] = [{
            HintSearch : null,
            From : null,
            To : null,
            UserId : null,
            Tags : null,
            ExcludeCreatedBySystem : null,
            FkParentId : eventId,
            EventTypeIds : [ProlifeSdk.BlogEventType_WorkedHours]
        }];

        this.blogService.detailedSearch(filters, -1, -1)
            .then((data : HoursRecordEvent []) =>
            {
                var childrenDefs : Promise<void>[] = [];
                data.forEach((h : HoursRecordEvent) => {
                    var childDef = new Deferred<void>();

                    h.LoadDetailsAsync().then(() => {
                        this.originalHoursRecord.push(h);
                        (<any>this.blogService).workedHoursProvider.getByWorkEventId(h.IdEvent())
                            .then((wh : IWorkSheetRow) => {
                                if(wh == null)
                                    return;

                                h.setWorkedHoursRecord(wh);

                                if(wh.ReferencingRows.length > 0)
                                    this.referencedHours.push(h);
                            }).then(() => { childDef.resolve()});
                    });

                    childrenDefs.push(childDef.promise());
                });

                Promise.all(childrenDefs)
                    .then(() => {

                        var groups = [];
                        var resourcesIds = [];
                        data
                            .map((r) => { return parseInt(r.WorkerId()); })
                            .forEach((id : number) => {
                                    if(resourcesIds.indexOf(id) == -1)
                                    {
                                        var records : HoursRecordEvent [] = data.filter((r1 : HoursRecordEvent) => { return parseInt(r1.WorkerId()) == id; });
                                        var group = new ResourceHoursGroup(this.serviceLocator, records, this.contextEventsObserver, true, this.eventDate, this.pkEvent(), this.selectedTasksObservable, this.commessaId);
                                        groups.push(group);
                                        resourcesIds.push(id);
                                    }
                                });
                        this.resourcesGroups(groups);
                        def.resolve();
                    })
                    .catch(() => { def.reject(); });

            })
            .catch(() => { def.reject();});

        return def.promise();
    }

    private GetAllHoursRecords()
    {
        var allRecords = [];
        this.resourcesGroups().forEach((g : ResourceHoursGroup) => {
            g.HoursRecords().forEach((r : HoursRecordEvent) => { allRecords.push(r); });
        });
        return allRecords;
    }

    getChildEvents() : ILogEvent []
    {
        var events : ILogEvent [] = [];
        this.GetAllHoursRecords().forEach((hoursRecord : HoursRecordEvent) => {
            events.push(hoursRecord.ToBlogEvent())
        });
        return events;
    }

    getBlogEvent(): ILogEvent
    {
        var localBlogEvent : ILogEvent = super.getBlogEvent();

        localBlogEvent.Tags.push( {TagName : ProlifeSdk.TagWork_Duration,
            Value : this.durata(),
            TagTypeId : ProlifeSdk.TagType_Decimal});

        localBlogEvent.EventType = "Blog.Work";
        return localBlogEvent;
    }

	public getValidationMessage() : string[]
    {
		var message : string[] = super.getValidationMessage();

		if (this.subject() == null || this.subject().trim() == "")
			message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

		return message;
	}

    public isChanged() : boolean
    {
        var isChanged : boolean = super.isChanged();
        var allRecords = this.GetAllHoursRecords();

        this.originalHoursRecord.forEach(h => {
            isChanged = isChanged || allRecords.indexOf(h) < 0
                || h.IsChanged();
        });

        return isChanged;
    }
}
