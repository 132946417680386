import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { IWarehouseInspectionWizardArticleInfo, IArticleRequirementFromDocuments, IGetRequirementsFromDocumentsForWarehouseInspectionRequest } from "../../../../../WarehouseInspectionsService";
import { ArticleRequirements, IArticleRequirement } from "./ArticleRequirements";
import { WarehouseInspectionAction } from "../../Enums/WarehouseInspectionAction";
import { _WarehouseArticlesImporterDataSourceBase } from "./WarehouseArticlesImporterDataSourceBase";
import { IWarehouseArticlesImporterForInspectionInitializationInfo } from "../WarehouseArticlesImporterForInspection";
import { SelectedArticleInfo } from "./SelectedArticleInfo";
import { SourceArticlesTableFilters } from "./SourceArticlesTable";

export class RequirementsFromDocumentsDataSource extends _WarehouseArticlesImporterDataSourceBase {
    protected fromWarehouseLoads: boolean;

    constructor(props: IWarehouseArticlesImporterForInspectionInitializationInfo) {
        super(props);
    
        this.fromWarehouseLoads = false;
        this.showDocumentColumn = true;
        this.showAmountColumnOnSelectedArticles = true;

        this.Title(TextResources.Warehouse.RequirementsFromDocumentsDataSource);
    }
    
    public importSelectedArticles(): void {
        let selectedArticles = this.Articles().filter(a => a.Selected() || a.Selected() === null);
        let actualImportedArticles = this.ChoosenArticles();

        for (let article of selectedArticles) {
            let importedArticle = actualImportedArticles.firstOrDefault(a => a.DestinationWarehouseId === article.WarehouseId);
            let operationsInfo = article.SelectedRequirementsInfo()
                                        .filter(s => s.Selected())
                                        .map(s => s.getData())
                                        .map(s => this.createWizardWarehouseInspectionOperationInfo(s));

            let groupedOperationsInfo = this.groupByArticleAndOperationType(operationsInfo);

            if (!importedArticle) {
                this.ChoosenArticles.push(new SelectedArticleInfo(groupedOperationsInfo));
            } else {
                importedArticle.mergeOperationsInfo(groupedOperationsInfo);
            }
        }
    }
    
    protected async loadArticles(): Promise<void> {
        let request: IGetRequirementsFromDocumentsForWarehouseInspectionRequest = {
            startDateFilter: null,
            endDateFilter: null,
            fromWarehouseLoads: this.fromWarehouseLoads,
            skip: null,
            count: null
        };
        let requirements = await this.warehouseInspectionsService.GetRequirementsFromDocumentsForWarehouseInspection(request);
        this.Articles(this.createArticlesViewModels(requirements));
    }

    protected async onFiltersChanges(filters: SourceArticlesTableFilters): Promise<void> {
        
    }

    private groupByArticleAndOperationType(inputArticles: IWarehouseInspectionWizardArticleInfo[]): IWarehouseInspectionWizardArticleInfo[] {
        let result: IWarehouseInspectionWizardArticleInfo[] = [];
        let lastArticle: IWarehouseInspectionWizardArticleInfo;

        for (let article of inputArticles) {
            if (!lastArticle || lastArticle.ArticleId !== article.ArticleId || (lastArticle.ArticleId === article.ArticleId && lastArticle.OperationType !== article.OperationType)) {
                let operationId = this.warehouseInspectionsService.GetNextFakeId();
                article.Id = operationId;

                if (lastArticle)
                    lastArticle.Sources.forEach(s => s.WarehouseInspectionOperationId = lastArticle.Id);

                lastArticle = article;
                result.push(lastArticle);

                continue;
            }

            lastArticle.Amount += article.Amount;
            lastArticle.Sources = lastArticle.Sources.concat(article.Sources);
        }

        if (lastArticle)
            lastArticle.Sources.forEach(s => s.WarehouseInspectionOperationId = lastArticle.Id);

        return result;
    }

    private createArticlesViewModels(articles: IArticleRequirementFromDocuments[]): ArticleRequirements[] {
        let result: ArticleRequirements[] = [];
        let lastArticle: ArticleRequirements = null;

        for (let articleStock of articles.map(a => this.createArticleRequirementModel(a))) {
            if (!lastArticle || lastArticle.WarehouseId !== articleStock.WarehouseId) {
                lastArticle = new ArticleRequirements(articleStock);
                result.push(lastArticle);
            }

            lastArticle.addOrUpdateRequirement(articleStock);
        }

        return result;
    }

    private createArticleRequirementModel(article: IArticleRequirementFromDocuments): IArticleRequirement {
        return {
            ArticleId : article.ArticleId,
            ArticleDescription: article.ArticleDescription,
            ArticleCode: article.ArticleCode,
            EanCode: article.EanCode,
            MefCode: article.MefCode,
            RefsInfos: !article.RefId ? [] : [{ DocumentId: article.DocumentId,
                                                RefId: article.RefId,
                                                RefAmount: article.Amount,
                                                DocumentDate: article.DocumentDate,
                                                DocumentLabel: article.DocumentLabel,
                                                DocumentNumber: article.DocumentNumber,
                                                DocumentType: article.DocumentType,
                                                DocumentRegisterId: article.DocumentRegisterId,
                                                DocumentRegisterName: article.DocumentRegisterName,
                                             }],
            WarehouseId: article.DestinationWarehouseId,
            Warehouse: article.DestinationWarehouse,
            WarehouseStock: article.DestinationWarehouseStock,
            DocumentId: article.DocumentId,
            DocumentDate: article.DocumentDate,
            DocumentNumber: article.DocumentNumber,
            DocumentRegisterName: article.DocumentRegisterName,
            CustomerId: article.CustomerId,
            CustomerName: article.CustomerName,
            JobOrderId: article.JobOrderId,
            JobOrderName: article.JobOrderName,
            Amount: article.Amount,
            LastInspectionDate: null,
            SourceWarehouseId: article.SourceWarehouseId,
            SourceWarehouseName: article.SourceWarehouseName,
            SourceWarehouseStock: article.SourceWarehouseStock
        };
    }

    private createWizardWarehouseInspectionOperationInfo(articleRequirementInfo: IArticleRequirement): IWarehouseInspectionWizardArticleInfo {
        let actionType = articleRequirementInfo.WarehouseId === articleRequirementInfo.SourceWarehouseId ? WarehouseInspectionAction.StockCheck : WarehouseInspectionAction.WarehouseTransfer;
        
        return {
            Id: null,
            WarehouseInspectionId: this.props.WarehouseInspectionId,
            ArticleId: articleRequirementInfo.ArticleId,
            ArticleDescription: articleRequirementInfo.ArticleDescription,
            ArticleCode: articleRequirementInfo.ArticleCode,
            EanCode: articleRequirementInfo.EanCode,
            MefCode: articleRequirementInfo.MefCode,
            SourceWarehouseId: articleRequirementInfo.SourceWarehouseId,
            SourceWarehouse: articleRequirementInfo.SourceWarehouseName,
            SourceWarehouseStock: articleRequirementInfo.SourceWarehouseStock,
            DestinationWarehouseId: articleRequirementInfo.WarehouseId,
            DestinationWarehouse: articleRequirementInfo.Warehouse,
            DestinationWarehouseStock: articleRequirementInfo.WarehouseStock,
            CustomerId: articleRequirementInfo.CustomerId,
            CustomerName: articleRequirementInfo.CustomerName,
            JobOrderId: articleRequirementInfo.JobOrderId,
            JobOrderName: articleRequirementInfo.JobOrderName,
            Amount: articleRequirementInfo.Amount,
            LastInspectionDate: articleRequirementInfo.LastInspectionDate,
            OperationType: actionType,

            Sources: articleRequirementInfo.RefsInfos.map(r => ({ 
                WarehouseInspectionOperationId: null, 
                DocumentId: r.DocumentId, 
                RefId: r.RefId, 
                RefAmount: r.RefAmount,
                DocumentDate: r.DocumentDate,
                DocumentLabel: r.DocumentLabel,
                DocumentNumber: r.DocumentNumber,
                DocumentType: r.DocumentType,
                DocumentRegisterId: r.DocumentRegisterId,
                DocumentRegisterName: r.DocumentRegisterName
            }))
        };
    }
}