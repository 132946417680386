import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/07/2017
 * Time: 16:30
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { SampleViewModel } from "../SampleViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISampleService } from "../../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISampleType, ISample } from "../../../../../ProlifeSdk/interfaces/survey/ISample";
import { ISampleState } from "../../../../../ProlifeSdk/interfaces/survey/ISampleState";
import { ISampleStateSettingManager } from "../../../../interfaces/ISampleStateSettingManager";
import { ISampleTypeSettingManager } from "../../../../interfaces/ISampleTypeSettingManager";

export class SampleEditDialog implements IDialog {
    public templateName: string = "add-sample-dialog";
    public templateUrl: string = "survey/templates/sample/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.EditSampleDialogAddTitle;
    public modal: { close: (result?: any) => void; };

    public Sample: ko.Observable<SampleViewModel> = ko.observable();
    public SampleTypes: ko.ObservableArray<ISampleType> = ko.observableArray([]);
    public SampleStates: ko.ObservableArray<ISampleState> = ko.observableArray([]);

    public IsNew: ko.Computed<boolean>;

    private sampleService: ISampleService;
    private infoToastService: IInfoToastService;

    private statesManager: ISampleStateSettingManager;
    private typesManager: ISampleTypeSettingManager;

    constructor(private serviceLocator: IServiceLocator, sample: SampleViewModel) {
        this.sampleService = <ISampleService>this.serviceLocator.findService(ProlifeSdk.SampleServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <ISampleStateSettingManager>settingsService.findSettingsManager(ProlifeSdk.SampleState);
        this.typesManager = <ISampleTypeSettingManager>settingsService.findSettingsManager(ProlifeSdk.SampleType);

        if (!sample) {
            this.Sample(new SampleViewModel(this.serviceLocator, this.newEmptySample()));
        }

        if (sample) {
            this.title = ProlifeSdk.TextResources.Survey.EditSampleDialogEditTitle;
            sample.currentSample = sample.ToJSON();
            this.Sample(sample);
        }

        var types: ISampleType[] = this.typesManager.getSampleTypes();
        this.SampleTypes(types);
        if (!this.Sample().Id() && types.length > 0) {
            this.Sample().TypeId(types[0].Id);
            this.Sample().TypeName(types[0].Name);
            this.Sample().Icon(types[0].Icon);
            this.Sample().Background(types[0].Background);
            this.Sample().Foreground(types[0].Foreground);
        }
        if (this.Sample().Id())
            this.Sample().TypeId(this.Sample().currentSample.TypeId);

        var states: ISampleState[] = this.statesManager.getSampleStates();
        this.SampleStates(states);
        if (!this.Sample().Id() && states.length > 0) {
            this.Sample().StateId(states[0].Id);
            this.Sample().StateName(states[0].Description);
            this.Sample().Locked(states[0].Locked);
            return;
        }
        if (this.Sample().Id())
            this.Sample().StateId(this.Sample().currentSample.StateId);

        this.IsNew = ko.computed(() => {
            let sample = this.Sample();
            return (sample?.Id || 0) <= 0;
        });
    }

    action(): void {
        if (!this.Sample().Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.SampleTitleRequired);
            return;
        }

        if (this.Sample().Id()) {
            this.OnSampleTypeChenge(this.Sample().TypeId());
            this.modal.close(this.Sample().Id());
            return;
        }
        
        this.sampleService.newSample(this.Sample().ToJSON())
            .then((sampleId: number) => {
                this.modal.close(sampleId);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.SampleCreationError);
            });
    }

    close(): void {
        this.modal.close(null);
    }

    private OnSampleTypeChenge(typeId: number): void {
        this.SampleTypes().forEach((t: ISampleType) => {
            if (t.Id == typeId) {
                this.Sample().TypeId(t.Id);
                this.Sample().TypeName(t.Name);
                this.Sample().Icon(t.Icon);
                this.Sample().Background(t.Background);
                this.Sample().Foreground(t.Foreground);
            }
        });
    }

    private newEmptySample(): ISample {
        return <ISample> {
            Id: null,
            Title: null,
            Description: null,
            Size: 0,
            CreatorId: null,
            CreationDate: null,
            ModifierId: null,
            ModifyDate: null,
            StateId: null,
            StateName: null,
            Locked: false,
            Visible: true,
            TypeId: null,
            TypeName: null,
            Icon: null,
            Background: null,
            Foreground: null,
            PublicId: null
        }
    }
}