import * as ko from "knockout";
import { OrAggregatorFilter } from "./filters/OrFilter";
import { StringPropertyFilter } from "./filters/StringPropertyFilter";
import { HtmlStringPropertyFilter } from "./filters/HtmlStringPropertyFilter";
import { DatePropertyFilter } from "./filters/DatePropertyFilter";
import { ArrayFilter } from "./filters/ArrayFilter";
import { IBaseFilter } from "../interfaces/IBaseFilter";
import { ISearchContext } from "../interfaces/ISearchContext";
import { IFilterWithValue } from "../interfaces/IFilterWithValue";

/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 20/12/12
 * Time: 16.14
 * To change this template use File | Settings | File Templates.
 */

export class SearchContext implements ISearchContext {
    subPropertyObjToFilter : string;
    appliedFilters : IFilterWithValue[];
    aggregatedFilter : OrAggregatorFilter;

    constructor(public items : any[] = [], public filters : IBaseFilter[] = []) {
        this.appliedFilters = [];
        this.aggregatedFilter = new OrAggregatorFilter(filters);
    }

    public getFiltersChain() : IBaseFilter[] {
        var filtersChain = this.filters.slice(0, Number.MAX_VALUE);
        filtersChain.splice(0,0, this.aggregatedFilter);
        return filtersChain;
    }

    public getValueFor(obj : any) : any {
        return this.subPropertyObjToFilter ? ko.utils.unwrapObservable(obj[this.subPropertyObjToFilter]) : obj;
    }

    public addStringFilter(fieldName : string, label: string) : IBaseFilter {
        var filter = new StringPropertyFilter(fieldName, label);
        this.filters.push(filter);
        return filter;
    }

    public addHtmlStringFilter(fieldName : string, label : string) : IBaseFilter {
        var filter = new HtmlStringPropertyFilter(fieldName, label);
        this.filters.push(filter);
        return filter;
    }

    public addDateFilter(fieldName : string, label: string) : IBaseFilter {
        var filter = new DatePropertyFilter(fieldName, label);
        this.filters.push(filter);
        return filter;
    }

    public addArrayFilter(fieldName : string, label: string, filterProperty : string, filterValue : string, valueProperty : string) : IBaseFilter {
        var filter = new ArrayFilter(fieldName, label, filterProperty, filterValue, valueProperty);
        this.filters.push(filter);
        return filter;
    }

    public clearAppliedFilters() : void {
        this.appliedFilters = [];
    }

    public removeAppliedFilter(filter:IFilterWithValue) {
        var filters = this.appliedFilters.filter((item) => {
            return item.filter == filter.filter && item.value == filter.value;
        });
        filters.forEach(this._removeAppliedFilter.bind(this));
    }

    private _removeAppliedFilter(filter: IFilterWithValue) {
        var index = this.appliedFilters.indexOf(filter);
        if(index != -1) this.appliedFilters.splice(index, 1);
    }

    public removeItem(item : any) : void {
        var index = this.items.indexOf(item);
        if(index != -1) this.items.splice(index, 1);
    }
}