import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type TextProps = {
    text: ko.Subscribable<string>;
}

export function Text(props: TextProps) {
    const C = require("./Text")._Text as typeof _Text;
    return <C {...props} />;
}

export class _Text {
    static defaultProps: Partial<TextProps> = {
    }

    constructor(private props : TextProps) {}
    
    render() {
        const text = this;

        return ComponentUtils.bindTo((
            <span data-bind={{ text: text.props.text }}></span>
        ), this, "text");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(Text);
}