import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 11:43
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { SampleStateEditingViewModel } from "./ui/SampleStateEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISampleStateSettingManager } from "../../../../interfaces/ISampleStateSettingManager";
import { ISampleState, ISampleLogicalState } from "../../../../../ProlifeSdk/interfaces/survey/ISampleState";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";
import { IQuestionnaireState } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class SampleStateSettingManager implements ISampleStateSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private sampleStates: ISampleState[] = [];
    private sampleLogicalStates: ISampleLogicalState[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        this.sampleLogicalStates.push(<ISampleLogicalState>{Id: 1, Description: ProlifeSdk.TextResources.Survey.DraftState, Locked: true });
        this.sampleLogicalStates.push(<ISampleLogicalState>{Id: 2, Description: ProlifeSdk.TextResources.Survey.EnableState, Locked: false });
        this.sampleLogicalStates.push(<ISampleLogicalState>{Id: 3, Description: ProlifeSdk.TextResources.Survey.DisabledState, Locked: true });
    }

    load(): Promise<ISampleState[]> {
        return this.ajaxService.Post<ISampleState[]>("Survey-api/SampleApi", "GetSampleStates", {})
            .then((states: ISampleState[]) => {
                this.sampleStates = states;
                return states;
            });
    }

    getName(): string {
        return ProlifeSdk.SampleState;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.SampleStatus;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "sample-state",
            templateUrl: "survey/templates/sample/settings",
            viewModel: new SampleStateEditingViewModel(this.serviceLocator, this)
        };
    }

    getSampleLogicalState(): ISampleLogicalState[] {
        return this.sampleLogicalStates;
    }

    getSampleStates(): ISampleState[] {
        return this.sampleStates;
    }

    getSampleStateById(id: number): ISampleState {
        var states: ISampleState[] = this.sampleStates.filter((s: ISampleState) => { s.Id == id });
        return states[0];
    }

    addOrUpdate(state: IQuestionnaireState): Promise<ISampleState> {
        return this.ajaxService.Post<ISampleState>("Survey-api/SampleApi", "AddOrUpdateSampleState", {
            methodData: {
                State: state
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/SampleApi", "RemoveSampleState", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: ISampleState): void {
        var found: boolean = false;

        this.sampleStates.forEach((s: ISampleState) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Description = state.Description;
            s.LogicalStateId = state.LogicalStateId;
            s.Locked = state.Locked;

            found = true;
        });

        if (!found)
            this.sampleStates.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.sampleStates.length; i++) {
            if (this.sampleStates[i].Id == id) {
                this.sampleStates.splice(i, 1);
                break;
            }
        }
    }
}