import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ITodoListService, IWorkflowChange } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";

export class WorkflowStateChangeEvent extends BlogEvent implements IView
{
    private humanResourcesService : IHumanResourcesSettingsManager;
    private todoListService : ITodoListService;

    public OldState : ko.Observable<string> = ko.observable();
    public NewState : ko.Observable<string> = ko.observable();
    public OldOutcome : ko.Observable<string> = ko.observable();
    public NewOutcome : ko.Observable<string> = ko.observable();
    public OldEstimate : ko.Observable<string> = ko.observable();
    public NewEstimate : ko.Observable<string> = ko.observable();
    public WorkflowName : ko.Observable<string> = ko.observable();
    public WorkflowId : ko.Observable<number> = ko.observable();
    public Created : ko.Observable<boolean> = ko.observable();
    public Deleted : ko.Observable<boolean> = ko.observable();
    public WorkflowStateChangeId : ko.Observable<number> = ko.observable();

    public WorkflowChangeHistory : ko.ObservableArray<IWorkflowChange> = ko.observableArray([]);

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Updatable(false);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.iconClass = "fa-tasks";
        this.eventTypeName = ProlifeSdk.TextResources.Todolist.Workflow;
        this.templateName = "workflow-state-change-event";
        this.templateUrl = "todolist/templates/events";
        this.isDeleteLocked(true);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    public GetMailSubject() : string
    {
        return ProlifeSdk.TextResources.Todolist.WorkflowChangeMailSubject;
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {

            switch(item.TagName()) {
                case ProlifeSdk.Tag_WorkflowStateChange_OldState:
                    this.OldState(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_NewState:
                    this.NewState(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_OldOutcome:
                    this.OldOutcome(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_NewOutcome:
                    this.NewOutcome(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_OldEstimate:
                    this.OldEstimate(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_NewEstimate:
                    this.NewEstimate(item.displayName());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_WorkflowName:
                    this.WorkflowName(item.Value());
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_WorkflowId:
                    this.WorkflowId(parseInt(item.Value()));
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_WorkflowCreated:
                    this.Created(item.Value().toLowerCase() === "true");
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_WorkflowDeleted:
                    this.Deleted(item.Value().toLowerCase() === "true");
                    break;

                case ProlifeSdk.Tag_WorkflowStateChange_WorkflowStateChangeId:
                    this.WorkflowStateChangeId(parseInt(item.Value()));
                    break;
            }
        });
    }

    public LoadDetails()
    {
        super.LoadDetails();

        this.todoListService.GetWorkflowChangeHistory(this.WorkflowId())
            .then((hs : IWorkflowChange[]) => {
                /*var lastProgress = 0;
                var lastEstimate = 0;
                var lastState = -1;

                var firstChange = true;
                hs.reverse().forEach(h => {
                    if(firstChange) {
                        lastProgress = h.NewProgressAvg || lastProgress;
                        lastEstimate = h.NewEstimatedWork || lastEstimate;
                        lastState = h.NewTaskBoardStatus || lastState;
                        firstChange = false;
                    } else {
                        if(h.NewEstimatedWork == null) { //Cambio di stima o progress
                            h.NewEstimatedWork = lastEstimate;
                            h.NewProgressAvg = lastProgress;
                            lastState = h.NewTaskBoardStatus;
                        } else { //Cambio di stato
                            h.NewTaskBoardStatus = lastState;
                            lastProgress = h.NewProgressAvg;
                            lastEstimate = h.NewEstimatedWork;
                        }
                    }
                });*/
                hs.reverse();
                this.WorkflowChangeHistory(hs);
            });
    }
}