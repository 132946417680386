import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type IconSwitcherProps = {
    isTrue: string;
    isFalse: string;
    trigger: ko.Observable<boolean>;
}

export function IconSwitcher(props: IconSwitcherProps) {
    const C = require("./IconSwitcher")._IconSwitcher as typeof _IconSwitcher;
    return <C {...props} />;
}

export class _IconSwitcher {
    static defaultProps: Partial<IconSwitcherProps> = {
    }

    private Css : ko.Observable<string> = ko.observable();
    private Subscription: ko.Subscription;

    constructor(private props : IconSwitcherProps) {
        
    }

    componentDidMount() {
        this.Subscription = this.props.trigger.subscribe(v => this.Css(v ? this.props.isTrue : this.props.isFalse));
        this.Css(this.props.trigger() ? this.props.isTrue : this.props.isFalse);
    }

    componentWillUnmount() {
        this.Subscription.dispose();
        this.Subscription = undefined;
    }
    
    render() {
        const _this = this;

        return ComponentUtils.bindTo((
            <i className="fa" data-bind={{ css: _this.Css }}></i>
        ), this, "_this");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(IconSwitcher);
}