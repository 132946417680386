import { LazyImport } from "../../../Core/DependencyInjection";
import { IDataSourceColumn, IDataSourcesService } from "../../../Desktop/DataSourcesService";
import { ReportComponentDataSource, ReportComponentDataSourceProps } from "./ReportComponentWithDataSource";

const librariesDef: string = `
        function toDate(date: Date) : string;
        function toDateAndTime(date: Date) : string;
        function toTime(date: Date) : string;
        function toMoney(value: number, extendedFormat: boolean = false): string;

        interface Date {
            valueOf(): number;
            toString(): string;
        }

        interface DateConstructor {
            new (stringDate: string): Date;
            new (numberDate: number): Date;
            new (): Date;
        }

        declare var Date: DateConstructor;
        declare var store: any;
    `;

const libraries: string = `
        function toDate(date) { return moment(date).format("L"); }
        function toDateAndTime(date) { return moment(date).format("L LT"); }
        function toTime(date) { return moment(date).format("LT"); }
        function toMoney(value, extended) { return numeral(value).format(extended ? "0,0[.]0000" : "0,0[.]00"); }
    `;

export class ReportDataBoundValue<T> {
    dataSource: ReportComponentDataSource;
    boundExpression: string;
    compiledBoundExpression: string;
    bound: ko.Observable<boolean> = ko.observable(false);

    constructor(dataSource?: ReportComponentDataSourceProps, boundExpression?: string, compiledBoundExpression?: string, bound?: boolean) {
        this.dataSource = new ReportComponentDataSource(dataSource);
        this.boundExpression = boundExpression;
        this.compiledBoundExpression = compiledBoundExpression;
        this.bound(bound);
    }

    loadFrom(result: ReportDataBoundValue<T>) {
        this.dataSource = new ReportComponentDataSource(result.dataSource.getData());
        this.boundExpression = result.boundExpression;
        this.compiledBoundExpression = result.compiledBoundExpression;
        this.bound(result.bound());
    }

    executeExpression() : T {
        if(!this.dataSource || !this.boundExpression) return undefined;

        const record = this.dataSource.getValue();
        const expression = new Function("record", libraries + "\r\n" + this.compiledBoundExpression + "\r\nreturn getValue(record);");
        const value = expression(record);
        return value;
    }

    isBound() {
        return this.bound();
    }

    getTypeDefinitions(): string[] {
        const properties = Array.from(this.getDataSourceProperties()).reduce((fs, p) => fs += "    " + p + "\r\n", "");

        return [`interface DataSourceRecord {\r\n${properties}}`, librariesDef];
    }

    *getDataSourceProperties() {
        if(!this.dataSource) return;
        
        for(const column of this.dataSource.getColumns()) {
            yield `/** ${column.Description.replace(/(\r\n|\r|\n)/g, '$1     * ')}\r\n     */`;
            yield `${column.Name}: ${this.getTypescriptType(column)};`;
        }
    }

    getTypescriptType(column: IDataSourceColumn) : string {
        switch(column.Type) {
            case "Boolean": 
                return "boolean";
            case "DateTimeOffset": 
                return "Date";
            case "Int32":
            case "Int32Key": 
            case "Money":
            case "MoneyEuro":
                return "number";
            case "String":
                return "string";
            default:
                return "unknown";
        }
    }
}