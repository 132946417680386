export class TextFiltersUtilities {
    public static contains(value: string, filter: string): boolean {
        if (!filter) return true;

        const safeValue = (value || "").toLocaleLowerCase();
        const words = filter
            .trim()
            .toLocaleLowerCase()
            .split(" ")
            .filter((w) => !!w);
        for (const word of words) {
            if (safeValue.indexOf(word) < 0) return false;
        }

        return true;
    }
}
