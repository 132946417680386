import { Validator } from "../../../ProlifeSdk/ValidationService";

function isCabOrAbi(val)
{
    return !val || val.length == 0 || (val.length == 5 && val.match(/^\d+$/));
}

Validator.prototype["mustBeAbi"] = function<T,V>(this: Validator<T>, selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean) {
    this.rules.push({
        validate: (value: T) => {
            if(onlyIf && !onlyIf(value))
                return this.ok();

            let val = selector(value);
            if(!isCabOrAbi(val)) {
                return this.error(errorMessage ?? "Il valore deve essere un codice ABI valido: " + selector.toString());
            }
            return this.ok();
        }
    });
    return this;
};

Validator.prototype["mustBeCab"] = function<T,V>(this: Validator<T>, selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean) {
    this.rules.push({
        validate: (value: T) => {
            if(onlyIf && !onlyIf(value))
                return this.ok();

            let val = selector(value);
            if(!isCabOrAbi(val)) {
                return this.error(errorMessage ?? "Il valore deve essere un codice CAB valido: " + selector.toString());
            }
            return this.ok();
        }
    });
    return this;
};

declare module "../../../ProlifeSdk/ValidationService" {
    interface IValidator<T> {
        mustBeAbi?(selector : (value : T) => string, errorMessage?: string, onlyIf?: () => boolean) : IValidator<T>;
        mustBeCab?(selector : (value : T) => string, errorMessage?: string, onlyIf?: () => boolean) : IValidator<T>;
    }
}