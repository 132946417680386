import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export interface IMonthSyntheticDataPopoverProps {
    Title: string;

    TotalOrdinaryHours: number;
    MonthlyImbalance: number;
    MonthlyOvertime: number;
}

export class MonthSyntheticDataPopover extends DialogComponentBase {
    constructor(private props: IMonthSyntheticDataPopoverProps) {
        super({});

        this.title(this.props.Title);
    }

    renderBody() {
        let monthSyntheticData: MonthSyntheticDataPopover;

        return ComponentUtils.bindTo((
            <ul>
                <li>{TextResources.WorkedHours.TotalHoursOfTheDay}: <span data-bind={{ timeTextFromNumber: monthSyntheticData.props.TotalOrdinaryHours }}></span></li>
                <li>{TextResources.WorkedHours.MonthImbalance}: <span data-bind={{ timeTextFromNumber: monthSyntheticData.props.MonthlyImbalance }}></span></li>
                <li>{TextResources.WorkedHours.OvertimeHours}: <span data-bind={{ timeTextFromNumber: monthSyntheticData.props.MonthlyOvertime }}></span></li>
            </ul>
        ), this, "monthSyntheticData");
    }
}