import * as Core from "../Core";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { ILocalizationService, ILocalizationServiceConfiguration } from "../interfaces/ILocalizationService";
import { IAjaxService } from "../interfaces/IAjaxService";
import { IService } from "../interfaces/IService";

class LocalizationService implements ILocalizationService
{
    private ajaxService: IAjaxService;

    constructor(serviceLocator: IServiceLocator, private configuration : ILocalizationServiceConfiguration = Core.configuration.localizationConfig)
    {
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ILocalizationService>(), this);
    }

    isOfType(serviceType: string) : boolean {
        return serviceType == this.getServiceType();
    }

    getServiceType() : string {
        return ServiceTypes.Localization;
    }

    getClientLabelsTranslations() : Promise<any>
    {
        return this.ajaxService.Post<any>(this.configuration.serviceName, this.configuration.getClientLabelsTranslationsMethodName, { background: true });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new LocalizationService(serviceLocator);
}
