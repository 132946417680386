import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { BlogEventBase } from "./BlogEventBase";
import { CommentInput } from "./CommentInput";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../interfaces/IView";
import { IEventBaseInput } from "../../interfaces/blog/IEventBaseInput";

export class CommentBlogEvent extends BlogEventBase implements IView
{
    public viewModel : any;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Clear();
    }

    getEditViewModel() : IEventBaseInput
    {
        return new CommentInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();
        this.Subject("");
    }

    public deleteEvent()
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.ProlifeSdk.ConfirmDeleteComment,
            ProlifeSdk.TextResources.ProlifeSdk.DoNotDeleteComment, ProlifeSdk.TextResources.ProlifeSdk.DeleteComment, (dialogResult : boolean) => {
            if(dialogResult)
                this.logService.deleteEvent(this.IdEvent())
                    .then(() => {
                        if(this.parent != null)
                            this.parent.onChildDeleted(this);
                    });
        });
    }
}