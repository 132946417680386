import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/10/2018
 * Time: 09:51
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { AllocationStartType } from "../enums/AllocationStartType";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ICartsFactory } from "../../interfaces/ICartsFactory";
import { IJobOrderGeneralSettingsManager } from "../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { ICart, ICartResource } from "../../../ProlifeSdk/interfaces/allocations/ICart";

export class CartsFactory implements ICartsFactory {
    @LazyImport(ProlifeSdk.CartsServiceCode)
    private cartsService!: ICartsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettings: IJobOrderGeneralSettingsManager;

    public async createCartForElementAllocation(elementId: number, isTask: boolean, showCartEditor = false): Promise<ICart> {
        const cartName: string = await this.cartsService.getAutoCartName(elementId, isTask);
        
        const cartObject = this.createCartObject(cartName);
        if (showCartEditor) {
            return await this.cartsService.openCartEditorDialog(cartObject, [[elementId, isTask]], true);
        } else {
            let newCart : ICart = null;
            try {
                const resource: ICartResource = await this.cartsService.getResourceForCart();
                newCart = await this.cartsService.addCart(cartObject, !resource ? [] : [resource], []);
                const cartElement = await this.cartsService.addElementToCart(newCart.Id, elementId, isTask, 100);
                if (!cartElement) {
                    await this.dialogsService.AlertAsync(ProlifeSdk.TextResources.Allocations.CanNotAllocateElementAlreadyAllocated, ProlifeSdk.TextResources.Allocations.CanNotAllocateWorkflowPartiallyAllocatedLabel);
                    await this.cartsService.deleteCart(newCart.Id);
                    return null;
                }
            } catch(e) {
                if (newCart)
                    await this.cartsService.deleteCart(newCart.Id);

                throw e;
            }

            return newCart;
        }
    }

    private createCartObject(cartName: string): ICart {
        return {
            Id: 0,
            Title: !cartName ? ProlifeSdk.TextResources.Allocations.AutoCartTitle : cartName,
            Color: this.getRandomColor(),
            Status: 1,
            CreationDate: moment().toDate(),
            CreatorId: 0,
            ModifyDate: moment().toDate(),
            ModifierId: 0,
            IgnoreBacklog: false,
            AllocationStartType: AllocationStartType.AsSoonAsPossible,
            Priority: 0,
            CartWorkableHoursCalculationMode: this.generalSettings.getCartWorkableHoursCalculationMode()
        }
    }

    private getRandomColor() : string {
        const golden_ratio_conjugate = 0.618033988749895;
        let h = Math.random();

        h += golden_ratio_conjugate;
        h %= 1;
        const rgb = this.hsv_to_rgb(h, 0.5, 0.95);

        return "#" + rgb[0].toString(16) + rgb[1].toString(16) + rgb[2].toString(16);
    }

    private hsv_to_rgb(h, s, v) {
        const h_i = Math.floor(h * 6);
        const f = h * 6 - h_i
        const p = v * (1 - s)
        const q = v * (1 - f*s)
        const t = v * (1 - (1 - f) * s)

        let r = 0, g = 0, b = 0;

        switch(h_i)
        {
            case 0:
                r = v;
                g = t;
                b = p;
                break;

            case 1:
                r = q;
                g = v;
                b = p;
                break;

            case 2:
                r = p;
                g = v;
                b = t;
                break;

            case 3:
                r = p;
                g = q;
                b = v;
                break;

            case 4:
                r = t;
                g = p;
                b = v;
                break;

            case 5:
                r = v;
                g = p;
                b = q;
                break;
        }

        return [Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256)];
    }
}