import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { SettingsManagerGroup } from "./settings/SettingsManagerGroup";
import { CompanySettingsManager } from "./settings/company/CompanySettingsManager";
import { NationsSettingsManager } from "./settings/nations/NationsSettingsManager";
import { ProvincesSettingsManager } from "./settings/provinces/ProvincesSettingsManager";
import { SettingsApplication } from "./SettingsApplication";
import { HolidaysSettingsManager } from "./settings/holidays/HolidaysSettingsManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IService } from "../Core/interfaces/IService";
import { ISettingsService, ISettingsServiceObserver } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISettingsGroup } from "../ProlifeSdk/interfaces/settings/ISettingsGroup";
import { ISettingsManager } from "../ProlifeSdk/interfaces/settings/ISettingsManager";

interface SettingsGroupEntry {
    [settingsGroupName : string] : ISettingsGroup;
}

export class SettingsService implements ISettingsService {

    private settingsGroupsList : ISettingsGroup[] = [];
    private settingsGroups : SettingsGroupEntry = {};

    private settingsApplication : SettingsApplication;

    private observers : ISettingsServiceObserver[] = [];
    private settingsLoaded : boolean = false;

	constructor(private serviceLocator : IServiceLocator){
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ISettingsService>(), this);
    }
    
    InitializeService() {
        this.settingsApplication = new SettingsApplication(this.serviceLocator);
        this.registerDefaultSettings();
    }

    private registerDefaultSettings() {
        this.registerSettingsManager(new CompanySettingsManager(this.serviceLocator), ProlifeSdk.TextResources.Settings.CompanySettingsGroup);
        this.registerSettingsManager(new HolidaysSettingsManager(), ProlifeSdk.TextResources.Settings.CompanySettingsGroup);
        this.registerSettingsManager(new NationsSettingsManager(this.serviceLocator), ProlifeSdk.TextResources.Settings.CountriesLists);
        this.registerSettingsManager(new ProvincesSettingsManager(this.serviceLocator), ProlifeSdk.TextResources.Settings.CountriesLists);
    }

    private createSettingsGroup(settingsGroupName : string, settingsGroupLabel : string) : ISettingsGroup {
        var settingsGroup = new SettingsManagerGroup(this.serviceLocator, settingsGroupName, settingsGroupLabel);
        this.settingsGroupsList.push(settingsGroup);
        this.settingsGroups[settingsGroupName] = settingsGroup;
        return settingsGroup;
    }

    private createOrGetSettingsGroup(settingsGroupName : string, settingsGroupLabel? : string) : ISettingsGroup {
        return this.settingsGroups[settingsGroupName] ||
            this.createSettingsGroup(settingsGroupName, settingsGroupLabel || settingsGroupName);
    }

    getServiceType() : string{
		return ProlifeSdk.SettingsServiceType;
	}

	isOfType(serviceType: string) : boolean{
		return serviceType == this.getServiceType();
	}

    reload() : Promise<void> {
        return Promise.all(this.settingsGroupsList.map((group) => group.load()))
            .then(this.onSettingsLoaded.bind(this));
    }

	registerSettingsManager(settingsManager : ISettingsManager, settingsGroupName : string, overrideGroupLabel? : string) : void {
        var settingsGroup = this.createOrGetSettingsGroup(settingsGroupName, overrideGroupLabel);
        settingsGroup.add(settingsManager);
	}

	findSettingsManager(settingsManagerName : string) : ISettingsManager {
        var settingsManager : ISettingsManager = null;
        for(var i = 0; i < this.settingsGroupsList.length && !settingsManager; i++) {
            settingsManager = this.settingsGroupsList[i].get(settingsManagerName);
        }
		return settingsManager;
	}

	getSettingsManagersGroups() : ISettingsGroup[]{
		return this.settingsGroupsList;
	}

    addObserver(observer : ISettingsServiceObserver) {
        this.observers.push(observer);
        if (this.settingsLoaded)
            observer.onSettingsLoaded();
    }

    removeObserver(observer : ISettingsServiceObserver) {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onSettingsLoaded()
    {
        this.settingsLoaded = true;
        this.observers.forEach((observer) => observer.onSettingsLoaded());
    }

    public NotifySettingsUpdated(updateType : string)
    {
        this.observers.forEach((observer) => {
            if(observer.onSettingsUpdated)
                observer.onSettingsUpdated(updateType)
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new SettingsService(serviceLocator);
}