import * as ko from "knockout";
import { BaseFilter } from "./BaseFilter";

export class OrAggregatorFilter extends BaseFilter {
    filtersToAggregate: any[];

    constructor(filtersToAggregate) {
        super(null, "Tutto");
        this.filtersToAggregate = filtersToAggregate;
    }

    Filter(obj, filterText) : boolean {
        for(var filtC=0;filtC<this.filtersToAggregate.length;filtC++)
        {
            if(this.filtersToAggregate[filtC].Filter(obj, filterText))
                return true;
        }

        return false;
    }
}
