import { Service, LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export enum DiscountCatalogRowType {
    General = 0,
    Manufacturer = 1,
    DiscountFamily = 2,
    StatisticsFamily = 3,
    Article = 4,
    Explicit = 5
}

export enum DiscountCatalogRowMode {
    MarkupOnUsualSupplier = 0, //Ricarico su costo listino fornitore abituale
    MarkupOnSupplierCatalog = 1, //Ricarico su listino fornitore
    MarkupOnMeanLoadCost = 2, //Ricarico su costo medio di carico
    MarkupOnLastPurchaseCost = 3, //Ricarico su ultimo costo di acquisto
    DiscountOnSuggestedPrice = 4, //Sconto su prezzo suggerito
    DiscountOnSupplierPrice = 5 //Sconto su prezzo fornitore
}

export namespace DiscountCatalogRowMode {
    export function getDiscountSign(mode : DiscountCatalogRowMode) : number {
        switch (mode)
        {
            case DiscountCatalogRowMode.MarkupOnUsualSupplier:
            case DiscountCatalogRowMode.MarkupOnSupplierCatalog:
            case DiscountCatalogRowMode.MarkupOnMeanLoadCost:
            case DiscountCatalogRowMode.MarkupOnLastPurchaseCost:
                return 1;

            default:
                return -1;
        }
    }

    export function getDescription(mode : DiscountCatalogRowMode) : string {
        switch (mode)
        {
            case DiscountCatalogRowMode.MarkupOnUsualSupplier:
                return "Ricarico su costo listino fornitore abituale";
            case DiscountCatalogRowMode.MarkupOnSupplierCatalog:
                return "Ricarico su sconto fornitore";
            case DiscountCatalogRowMode.MarkupOnMeanLoadCost:
                return "Ricarico su costo medio di carico";
            case DiscountCatalogRowMode.MarkupOnLastPurchaseCost:
                return "Ricarico su ultimo costo di acquisto";
            case DiscountCatalogRowMode.DiscountOnSuggestedPrice:
                return "Sconto su prezzo suggerito cliente";
            case DiscountCatalogRowMode.DiscountOnSupplierPrice:
                return "Sconto su prezzo suggerito rivenditore";
            default:
                return "";
        }
    }

    export function requiresSupplier(mode : DiscountCatalogRowMode) : boolean {
        switch (mode)
        {
            case DiscountCatalogRowMode.MarkupOnSupplierCatalog:
                return true;

            default:
                return false;
        }
    }
}

export interface IDeleteDiscountCatalogRequest {
    discountCatalogId?: number;
}

export interface IDeleteDiscountCatalogRowRequest {
    discountCatalogRowId?: number;
}

export interface IGetDiscountCatalogsResponse {
    Id : number;
    Name?: string;
    Description?: string;
    StartValidity?: Date;
    EndValidity?: Date;
    RevisionDate?: Date;
    ForCustomers : boolean;
}

export interface IGetDiscountCatalogsCountRequest {
    textFilter?: string;
    showExpired?: boolean;
}

export interface IInsertOrUpdateDiscountCatalogRequest {
    discountCatalog?: IDiscountCatalogsType[];
}

export interface IInsertOrUpdateDiscountCatalogRowRequest {
    discountCatalogRow?: IDiscountCatalogRowsType[];
}

export interface IDiscountCatalogsType {
    Id : number;
    Name?: string;
    Description?: string;
    StartValidity : Date;
    EndValidity?: Date;
    RevisionDate?: Date;
    ForCustomers : boolean;
}

export interface IDiscountCatalogRow {
    Id : number;
    FkDiscountCatalog : number;
    FkManufacturer?: number;
    FkDiscountFamily?: number;
    FkStatisticFamily?: number;
    ArticleFilter?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
}

export interface IGetDiscountCatalogRowsRequest {
    discountCatalogId?: number;
    fkManufacturer?: number;
    fkDiscountFamily?: number;
    fkStatisticFamily?: number;
    articleFilter?: string;
    mode?: number;
    skip?: number;
    count?: number;
}

export interface IDiscountCatalogRowWithDescriptions {
    Id : number;
    FkDiscountCatalog : number;
    FkManufacturer?: number;
    FkDiscountFamily?: number;
    FkStatisticFamily?: number;
    ArticleFilter?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
    ManufacturerDescription?: string;
    DiscountFamilyDescription?: string;
    StatisticFamilyDescription?: number;
    SupplierDescription?: string;
}

export interface IGetDiscountCatalogsRequest {
    textFilter?: string;
    showExpired?: boolean;
    showForCustomers?: boolean;
    showForSuppliers?: boolean;
    skip?: number;
    count?: number;
}

export interface IDiscountCatalog {
    Id : number;
    Name?: string;
    Description?: string;
    StartValidity?: Date;
    EndValidity?: Date;
    RevisionDate?: Date;
    ForCustomers : boolean;
}

export interface ICustomerGroupDiscountCatalog {
    Order : number;
    Id : number;
    Name?: string;
    Description?: string;
    StartValidity?: Date;
    EndValidity?: Date;
    RevisionDate?: Date;
}

export interface ICustomerDiscountCatalog {
    Order : number;
    Id : number;
    Name?: string;
    Description?: string;
    StartValidity?: Date;
    EndValidity?: Date;
    RevisionDate?: Date;
}

export interface IOrderedCatalogsList {
    CatalogId : number;
    Order : number;
}

export interface IMoveCustomerDiscountCatalogRequest {
    customerId?: number;
    catalogId?: number;
    destinationId?: number;
    before?: boolean;
    isCustomer?: boolean;
}

export interface IResolveCustomerDiscountCatalogsRequest {
    customerId?: number;
    isCustomer?: boolean;
    warehouseId?: number;
    referenceDate?: Date;
    textFilter?: string;
    skip?: number;
    count?: number;
}

export interface IDiscountCatalogRowsType {
    Id?: number;
    FkDiscountCatalog : number;
    FkManufacturer?: number;
    FkDiscountFamily?: number;
    FkStatisticFamily?: number;
    ArticleFilter?: string;
    Discount0 : number;
    Discount1 : number;
    Discount2 : number;
    Discount3 : number;
    Discount4 : number;
    TotalDiscount : number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
}

export interface IResolvedDiscountCatalogArticle {
    Id?: number;
    ArticleId?: number;
    Code?: string;
    Description?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
    UnitPrice?: number;
}

export interface IResolvedCustomerDiscountCatalogArticle {
    Id : number;
    FkDiscountCatalog : number;
    ArticleId?: number;
    Code?: string;
    Description?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
    UnitPrice?: number;
}

export interface IResolveCustomerDiscountCatalogsForArticleRequest {
    customerId?: number;
    isCustomer?: boolean;
    warehouseId?: number;
    referenceDate?: Date;
    articleId?: number;
    overrideGroupId?: number;
}

export interface IGetDiscountCatalogSuggestionsForArticleRequest {
    articleId?: number;
    customerId?: number;
    isCustomer?: boolean;
    referenceDate?: Date;
    warehouseId?: number;
}

export interface IResolvedCustomerDiscountCatalogArticleSuggestion {
    Id : number;
    CustomerGroupId?: number;
    GroupName?: string;
    FkDiscountCatalog : number;
    ArticleId?: number;
    CatalogId?: number;
    UOM?: string;
    Code?: string;
    Description?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
    UnitPrice?: number;
}

export interface IResolveCustomerDiscountCatalogsForArticlesRequest {
    customerId?: number;
    isCustomer?: boolean;
    warehouseId?: number;
    referenceDate?: Date;
    articleIds?: number[];
    overrideGroupId?: number;
}

export interface IResolvedCustomerDiscountCatalogArticleWithCatalog {
    Id : number;
    FkDiscountCatalog : number;
    ArticleId?: number;
    CatalogId?: number;
    UOM?: string;
    Code : string;
    Description?: string;
    Discount0?: number;
    Discount1?: number;
    Discount2?: number;
    Discount3?: number;
    Discount4?: number;
    TotalDiscount?: number;
    ExplicitPrice?: number;
    Mode : number;
    FkSupplier?: number;
    UnitPrice : number;
}

export interface IDiscountsCatalogsService extends IService {
    InsertOrUpdateDiscountCatalog(discountCatalog : IDiscountCatalogsType[] | null) : Promise<IDiscountCatalog>;
    InsertOrUpdateDiscountCatalogRow(discountCatalogRow : IDiscountCatalogRowsType[] | null) : Promise<IDiscountCatalogRow>;
    GetDiscountCatalogsCount(textFilter : string | null, showExpired : boolean | null) : Promise<number>;
    DeleteDiscountCatalog(discountCatalogId : number | null) : Promise<void>;
    DeleteDiscountCatalogRow(discountCatalogRowId : number | null) : Promise<void>;
    GetDiscountCatalogRows(request : IGetDiscountCatalogRowsRequest) : Promise<IDiscountCatalogRowWithDescriptions[]>;
    GetDiscountCatalogs(request : IGetDiscountCatalogsRequest) : Promise<IDiscountCatalog[]>;
    GetCustomerDiscountCatalogsByIds(ids : number[] | null) : Promise<ICustomerDiscountCatalog[]>;
    GetDiscountCatalogsByIds(ids : number[] | null) : Promise<IDiscountCatalog[]>;
    GetCustomerGroupDiscountCatalogs(customerGroupId : number | null, skip : number | null, count : number | null) : Promise<ICustomerGroupDiscountCatalog[]>;
    GetCustomerGroupDiscountCatalogsByIds(ids : number[] | null) : Promise<ICustomerGroupDiscountCatalog[]>;
    AssignDiscountCatalogsToCustomerGroup(customerGroupId : number | null, catalogIds : IOrderedCatalogsList[] | null) : Promise<void>;
    GetCustomerDiscountCatalogs(customerId : number | null, skip : number | null, count : number | null, forCustomers : boolean | null) : Promise<ICustomerDiscountCatalog[]>;
    AssignDiscountCatalogsToCustomer(customerId : number | null, catalogIds : IOrderedCatalogsList[] | null, isCustomer : boolean | null) : Promise<void>;
    MoveCustomerDiscountCatalog(request : IMoveCustomerDiscountCatalogRequest) : Promise<void>;
    ResolveCustomerDiscountCatalogs(request : IResolveCustomerDiscountCatalogsRequest) : Promise<IResolvedCustomerDiscountCatalogArticle[]>;
    ResolveDiscountCatalog(catalogId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IResolvedDiscountCatalogArticle[]>;
    ResolveDiscountCatalogRows(row : IDiscountCatalogRowsType[] | null) : Promise<IResolvedDiscountCatalogArticle[]>;
    ResolveCustomerDiscountCatalogsForArticle(request : IResolveCustomerDiscountCatalogsForArticleRequest) : Promise<IResolvedCustomerDiscountCatalogArticleWithCatalog>;
    GetDiscountCatalogSuggestionsForArticle(request : IGetDiscountCatalogSuggestionsForArticleRequest) : Promise<IResolvedCustomerDiscountCatalogArticleSuggestion[]>;
    ResolveCustomerDiscountCatalogsForArticles(request : IResolveCustomerDiscountCatalogsForArticlesRequest) : Promise<IResolvedCustomerDiscountCatalogArticleWithCatalog[]>;
}

@Service(nameof<IDiscountsCatalogsService>())
class DiscountsCatalogsService implements IDiscountsCatalogsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService! : IAjaxService;

    InsertOrUpdateDiscountCatalog(discountCatalog : IDiscountCatalogsType[] | null) : Promise<IDiscountCatalog> {
        return this.ajaxService.Post<IDiscountCatalog>("Warehouse-api/DiscountCatalogs", "InsertOrUpdateDiscountCatalog", {
            methodData: {
                discountCatalog: discountCatalog,
            }
        });
    }

    InsertOrUpdateDiscountCatalogRow(discountCatalogRow : IDiscountCatalogRowsType[] | null) : Promise<IDiscountCatalogRow> {
        return this.ajaxService.Post<IDiscountCatalogRow>("Warehouse-api/DiscountCatalogs", "InsertOrUpdateDiscountCatalogRow", {
            methodData: {
                discountCatalogRow: discountCatalogRow,
            }
        });
    }

    GetDiscountCatalogsCount(textFilter : string | null, showExpired : boolean | null) : Promise<number> {
        return this.ajaxService.Post<number>("Warehouse-api/DiscountCatalogs", "GetDiscountCatalogsCount", {
            methodData: {
                textFilter: textFilter,
                showExpired: showExpired,
            }
        });
    }

    DeleteDiscountCatalog(discountCatalogId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/DiscountCatalogs", "DeleteDiscountCatalog", {
            methodData: {
                discountCatalogId: discountCatalogId,
            }
        });
    }

    DeleteDiscountCatalogRow(discountCatalogRowId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/DiscountCatalogs", "DeleteDiscountCatalogRow", {
            methodData: {
                discountCatalogRowId: discountCatalogRowId,
            }
        });
    }

    GetDiscountCatalogRows(request : IGetDiscountCatalogRowsRequest) : Promise<IDiscountCatalogRowWithDescriptions[]> {
        return this.ajaxService.Post<IDiscountCatalogRowWithDescriptions[]>("Warehouse-api/DiscountCatalogs", "GetDiscountCatalogRows", {
            methodData: request
        });
    }

    GetDiscountCatalogs(request : IGetDiscountCatalogsRequest) : Promise<IDiscountCatalog[]> {
        return this.ajaxService.Post<IDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetDiscountCatalogs", {
            methodData: request
        });
    }

    GetCustomerDiscountCatalogsByIds(ids : number[] | null) : Promise<ICustomerDiscountCatalog[]> {
        return this.ajaxService.Post<ICustomerDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetCustomerDiscountCatalogsByIds", {
            methodData: {
                ids: ids,
            }
        });
    }

    GetDiscountCatalogsByIds(ids : number[] | null) : Promise<IDiscountCatalog[]> {
        return this.ajaxService.Post<IDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetDiscountCatalogsByIds", {
            methodData: {
                ids: ids,
            }
        });
    }

    GetCustomerGroupDiscountCatalogs(customerGroupId : number | null, skip : number | null, count : number | null) : Promise<ICustomerGroupDiscountCatalog[]> {
        return this.ajaxService.Post<ICustomerGroupDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetCustomerGroupDiscountCatalogs", {
            methodData: {
                customerGroupId: customerGroupId,
                skip: skip,
                count: count,
            }
        });
    }

    GetCustomerGroupDiscountCatalogsByIds(ids : number[] | null) : Promise<ICustomerGroupDiscountCatalog[]> {
        return this.ajaxService.Post<ICustomerGroupDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetCustomerGroupDiscountCatalogsByIds", {
            methodData: {
                ids: ids,
            }
        });
    }

    AssignDiscountCatalogsToCustomerGroup(customerGroupId : number | null, catalogIds : IOrderedCatalogsList[] | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/DiscountCatalogs", "AssignDiscountCatalogsToCustomerGroup", {
            methodData: {
                customerGroupId: customerGroupId,
                catalogIds: catalogIds,
            }
        });
    }

    GetCustomerDiscountCatalogs(customerId : number | null, skip : number | null, count : number | null, forCustomers : boolean | null) : Promise<ICustomerDiscountCatalog[]> {
        return this.ajaxService.Post<ICustomerDiscountCatalog[]>("Warehouse-api/DiscountCatalogs", "GetCustomerDiscountCatalogs", {
            methodData: {
                customerId: customerId,
                skip: skip,
                count: count,
                forCustomers: forCustomers,
            }
        });
    }

    AssignDiscountCatalogsToCustomer(customerId : number | null, catalogIds : IOrderedCatalogsList[] | null, isCustomer : boolean | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/DiscountCatalogs", "AssignDiscountCatalogsToCustomer", {
            methodData: {
                customerId: customerId,
                catalogIds: catalogIds,
                isCustomer: isCustomer,
            }
        });
    }

    MoveCustomerDiscountCatalog(request : IMoveCustomerDiscountCatalogRequest) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/DiscountCatalogs", "MoveCustomerDiscountCatalog", {
            background: true,
            methodData: request
        });
    }

    ResolveCustomerDiscountCatalogs(request : IResolveCustomerDiscountCatalogsRequest) : Promise<IResolvedCustomerDiscountCatalogArticle[]> {
        return this.ajaxService.Post<IResolvedCustomerDiscountCatalogArticle[]>("Warehouse-api/DiscountCatalogs", "ResolveCustomerDiscountCatalogs", {
            background: true,
            methodData: request
        });
    }

    ResolveDiscountCatalog(catalogId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IResolvedDiscountCatalogArticle[]> {
        return this.ajaxService.Post<IResolvedDiscountCatalogArticle[]>("Warehouse-api/DiscountCatalogs", "ResolveDiscountCatalog", {
            background: true,
            methodData: {
                catalogId: catalogId,
                textFilter: textFilter,
                skip: skip,
                count: count,
            }
        });
    }

    ResolveDiscountCatalogRows(row : IDiscountCatalogRowsType[] | null) : Promise<IResolvedDiscountCatalogArticle[]> {
        return this.ajaxService.Post<IResolvedDiscountCatalogArticle[]>("Warehouse-api/DiscountCatalogs", "ResolveDiscountCatalogRows", {
            background: true,
            methodData: {
                row: row,
            }
        });
    }

    ResolveCustomerDiscountCatalogsForArticle(request : IResolveCustomerDiscountCatalogsForArticleRequest) : Promise<IResolvedCustomerDiscountCatalogArticleWithCatalog> {
        return this.ajaxService.Post<IResolvedCustomerDiscountCatalogArticleWithCatalog>("Warehouse-api/DiscountCatalogs", "ResolveCustomerDiscountCatalogsForArticle", {
            background: true,
            methodData: request
        });
    }

    GetDiscountCatalogSuggestionsForArticle(request : IGetDiscountCatalogSuggestionsForArticleRequest) : Promise<IResolvedCustomerDiscountCatalogArticleSuggestion[]> {
        return this.ajaxService.Post<IResolvedCustomerDiscountCatalogArticleSuggestion[]>("Warehouse-api/DiscountCatalogs", "GetDiscountCatalogSuggestionsForArticle", {
            background: true,
            methodData: request
        });
    }

    ResolveCustomerDiscountCatalogsForArticles(request: IResolveCustomerDiscountCatalogsForArticlesRequest): Promise<IResolvedCustomerDiscountCatalogArticleWithCatalog[]> {
        let result = this.ajaxService.Post<IResolvedCustomerDiscountCatalogArticleWithCatalog[]>("Warehouse-api/DiscountCatalogs", "ResolveCustomerDiscountCatalogsForArticles", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create() {
    return new DiscountsCatalogsService();
}
