import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 22/10/13
 * Time: 11.31
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ExtractPurchasesReport } from "./ExtractPurchasesReport";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class BlogReportsProvider implements IReportsProvider
{
    authorizationService : IAuthorizationService;

    Id : number = 6;
    Name : string = ProlifeSdk.TextResources.Blog.Purchases;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";
    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    private navigator : IReportsNavigator;
    serviceLocator : IServiceLocator;

    constructor(serviceLocator : IServiceLocator)
    {
        this.serviceLocator = serviceLocator;

        this.authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.initialize();
    }

    initialize()
    {
        this.Reports([]);

        if(this.authorizationService.isAuthorized("WorkedHours_PurchasesExportReport"))
            this.Reports.push(new ExtractPurchasesReport(this.serviceLocator, this.Id));

        this.setCurrentNavigator(this.navigator);
    }

    getReportById(reportId : number) : IReport
    {
        var report : IReport = null;

        this.Reports().forEach((r : IReport) => {
            report = r.Id == reportId ? r : report;
        })

        return report;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
        this.Reports().forEach((provider) => provider.setNavigator(navigator));
    }

    Open()
    {
        this.navigator.navigateTo(this);
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}