import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/06/2018
 * Time: 09:36
 * To change this template use File | Settings | File Templates.
 */

import {
    IProtocolSettingsViewModel,
    IVatRegisterEditingContainer,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class CustomerOrderProtocolSettings implements IProtocolSettingsViewModel {
    public templateName = "customer-order-protocol-settings";
    public templateUrl = "warehouse/templates/settings";

    public CFRequired: ko.Observable<boolean> = ko.observable();
    public PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable();
    public DateSequenceRequired: ko.Observable<boolean> = ko.observable();

    constructor(private container: IVatRegisterEditingContainer, private vatRegister: IVatRegister) {
        this.CFRequired(this.vatRegister.CFRequired);
        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);
        this.DateSequenceRequired(this.vatRegister.DateSequenceRequired);
    }

    public applyChangesToRegister(vatRegister: IVatRegister) {
        vatRegister.CFRequired = this.CFRequired();
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        vatRegister.DateSequenceRequired = this.DateSequenceRequired();
    }

    public async load() {}

    public isChanged(): boolean {
        return (
            this.vatRegister.CFRequired != this.CFRequired() ||
            this.vatRegister.PaymentAndExpireModeRequired != this.PaymentAndExpireModeRequired() ||
            this.vatRegister.DateSequenceRequired != this.DateSequenceRequired()
        );
    }
}
