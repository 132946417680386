import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { ReportPage, ReportText } from "../Components";
import { _ReportDesigner } from "../ReportDesigner";
import jss from "jss"
import { RendererFor } from "./ReportRendererProvider";
import { PositionableContainer } from "./PositionableContainer";
import { ReportRenderer } from "./ReportRenderer";
import jsPDF, { GState, Matrix } from "jspdf";
import "../../../../fonts/casino_hand-webfont-normal.js";
import { PointsToCentimeters } from "../Utilities/UnitOfMeasureUtils";
import { ExportBackground } from "../Exporters/ReportBackgroundExporter";
import { ParseColor } from "../Utilities/ColorUtils";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type ReportTextRendererProps = {
    component: ReportText;
    parentPage: ReportPage;
    reportDesigner: _ReportDesigner;
}

export function ReportTextRenderer(props: ReportTextRendererProps) {
    const C = require("./ReportTextRenderer")._ReportTextRenderer as typeof _ReportTextRenderer;
    return <C {...props} />;
}

@RendererFor(nameof<ReportText>())
export class _ReportTextRenderer implements ReportRenderer {
    static defaultProps: Partial<ReportTextRendererProps> = {
    }

    editing: ko.Observable<boolean> = ko.observable(false);
    computedAlignment: ko.Computed<string>;
    text: ko.Computed<string>;

    constructor(private props : ReportTextRendererProps) {
        this.computedAlignment = ko.computed(() => {
            switch(this.props.component.verticalAlign())
            {
                case "top": return "flex-start";
                case "center": return "center";
                case "bottom": return "flex-end";
                default: return "flex-start";
            }
        });

        this.props.reportDesigner.on("edit", this.props.component, (parentPage: ReportPage) => this.editing(parentPage === this.props.parentPage));

        this.text = ko.computed(() => {
            const text = this.props.component.text();
            if(this.props.component.boundText?.isBound())
                return "<Espr.>";
            return text;
        });
    }
    
    render() {
        const _rdt = this;
        return  <PositionableContainer component={this.props.component}>
            {() => ComponentUtils.bindTo(
                <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', inset: 0 }} data-bind={{ style: { justifyContent: _rdt.computedAlignment, backgroundColor: _rdt.props.component.backgroundColor, backgroundImage: _rdt.props.component.backgroundImageCSSUrl, backgroundSize: _rdt.props.component.backgroundSize }}}>
                    <div style={{ wordBreak: "break-word", whiteSpace: 'pre-line', lineHeight: 1.5 }} data-bind={{ hidden: _rdt.editing, text: _rdt.text, style: { color: _rdt.props.component.fontColor, fontFamily: _rdt.props.component.fontFamily, fontSize: _rdt.props.component.fontSize() + "pt", fontWeight: _rdt.props.component.fontWeight, fontStyle: _rdt.props.component.fontStyle, textAlign: _rdt.props.component.textAlign, textAlignLast: _rdt.props.component.textAlign } }}></div>
                    <textarea 
                        style={{ position: 'absolute', inset: 0, wordBreak: "break-word", resize: 'none', width: '100%', overflow: 'hidden', border: 0, pointerEvents: 'auto', outline: 'none', lineHeight: 1.5, zIndex: 1 }} 
                        data-bind={{ visible: _rdt.editing, hasFocus: _rdt.editing, value: _rdt.props.component.text, style: { backgroundColor: _rdt.props.component.backgroundColor, backgroundImage: _rdt.props.component.backgroundImageCSSUrl, backgroundSize: _rdt.props.component.backgroundSize, color: _rdt.props.component.fontColor, fontFamily: _rdt.props.component.fontFamily, fontSize: _rdt.props.component.fontSize() + "pt", fontWeight: _rdt.props.component.fontWeight, fontStyle: _rdt.props.component.fontStyle, textAlign: _rdt.props.component.textAlign, textAlignLast: _rdt.props.component.textAlign, userSelect: _rdt.editing() ? 'auto' : 'none' } }}></textarea>
                </div>
            , this, '_rdt')}
        </PositionableContainer>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportTextRenderer);
}