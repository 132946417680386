import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { OffsetSettingsManager } from "./invoices/settings/OffsetSettingsManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IOffsetsType {
    Id : number;
    ParentId?: number;
    Code?: string;
    Description?: string;
    Type : number;
}

export interface IDocumentOffset {
    Id : number;
    ParentId?: number;
    Code?: string;
    Description?: string;
    Type : number;
    Deleted?: boolean;
}

export interface IDocumentOffsetLeaf {
    Id : number;
    Code?: string;
    Description?: string;
    Type : number;
}

export interface IOffsetsService extends IService {
    DeleteOffset(offsetId : number | null) : Promise<void>;
    GetOffsets(showDeleted : boolean | null, parentId : number | null) : Promise<IDocumentOffset[]>;
    GetOffsetsLeafByIds(ids : number[] | null) : Promise<IDocumentOffsetLeaf[]>;
    GetOffsetsByIds(ids : number[] | null) : Promise<IDocumentOffset[]>;
    InsertOrUpdateOffset(offsets : IOffsetsType[] | null) : Promise<IDocumentOffset[]>;
    GetOffsetsLeafs(textFilter : string | null, skip : number | null, count : number | null) : Promise<IDocumentOffsetLeaf[]>;
}

class OffsetsService implements IOffsetsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor (serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstanceWithName(nameof<IOffsetsService>(), this);
    }

    InitializeService() : void {
        new OffsetSettingsManager();
    }

    DeleteOffset(offsetId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Invoices-api/Offset", "DeleteOffset", {
            background: true,
            methodData: {
                offsetId: offsetId,
            }
        });
    }

    GetOffsets(showDeleted : boolean | null, parentId : number | null) : Promise<IDocumentOffset[]> {
        return this.ajaxService.Post<IDocumentOffset[]>("Invoices-api/Offset", "GetOffsets", {
            background: true,
            methodData: {
                showDeleted: showDeleted,
                parentId: parentId,
            }
        });
    }

    GetOffsetsLeafByIds(ids : number[] | null) : Promise<IDocumentOffsetLeaf[]> {
        return this.ajaxService.Post<IDocumentOffsetLeaf[]>("Invoices-api/Offset", "GetOffsetsLeafByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });
    }

    GetOffsetsByIds(ids : number[] | null) : Promise<IDocumentOffset[]> {
        return this.ajaxService.Post<IDocumentOffset[]>("Invoices-api/Offset", "GetOffsetsByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });
    }

    InsertOrUpdateOffset(offsets : IOffsetsType[] | null) : Promise<IDocumentOffset[]> {
        return this.ajaxService.Post<IDocumentOffset[]>("Invoices-api/Offset", "InsertOrUpdateOffset", {
            background: true,
            methodData: {
                offsets: offsets,
            }
        });
    }

    GetOffsetsLeafs(textFilter: string | null, skip: number | null, count: number | null): Promise<IDocumentOffsetLeaf[]> {
        return this.ajaxService.Post<IDocumentOffsetLeaf[]>("Invoices-api/Offset", "GetOffsetsLeafs", {
            background: true,
            methodData: {
        		textFilter: textFilter,
        		skip: skip,
        		count: count,
        	}
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new OffsetsService(serviceLocator);
}
