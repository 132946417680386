/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 08/02/13
 * Time: 14.34
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CustomerJobOrders } from "./CustomerJobOrders";
import { CustomerViewModel } from "./CustomerViewModel";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IJobOrderService, IJobOrderLockInfo } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderMenuActionsManager } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderMenuActionsManager";
import { IJobOrderNavigationMenuLeafProvider } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenuLeafProvider";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ICustomersServiceObserver } from "../../../ProlifeSdk/interfaces/customer/ICustomersServiceObserver";
import { ICustomer, IFullCustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { IJobOrderForList } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IJobOrderBlockMotivation } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";
import { Deferred } from "../../../Core/Deferred";

export class CustomerEditorViewModel implements ICustomersServiceObserver, IDialog, IJobOrderMenuActionsManager {
    public templateUrl = "customers/templates/customer";
    public state: ko.Observable<string> = ko.observable("view");

    public showValidation: ko.Observable<boolean> = ko.observable(false);
    public cancelVisible: ko.Observable<boolean> = ko.observable(true);
    public editVisible: ko.Observable<boolean> = ko.observable(false);
    public deleteVisible: ko.Observable<boolean> = ko.observable(false);
    public saveVisible: ko.Observable<boolean> = ko.observable(true);
    public dataViewModel: ko.Observable<CustomerViewModel> = ko.observable();
    public jobOrdersList: ko.Observable<CustomerJobOrders> = ko.observable(null);

    private userCanLockJobOrder: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IAuthorizationService>())
    private authService: IAuthorizationService;

    constructor(private customer?: ICustomer, forceEditing = false) {
        this.customersService.addObserver(this);

        this.userCanLockJobOrder(this.authService.isAuthorized("JobOrders_CanLockOrUnlockJobOrders"));

        this.dataViewModel(new CustomerViewModel(customer, this));
        this.state(customer && !forceEditing ? "view" : "edit");
        this.dataViewModel().SetEditMode(!customer || forceEditing);
        this.editVisible(!!customer && !forceEditing);
        this.deleteVisible(!customer || forceEditing);
        this.cancelVisible(!customer || forceEditing);
        this.saveVisible(!customer || forceEditing);

        if (this.jobOrderService.IsServiceEnabled()) {
            const jobOrdersList = new CustomerJobOrders(customer ? customer.IdCliente : -1);
            jobOrdersList.Refresh();
            this.jobOrdersList(jobOrdersList);
        }
    }

    public SetCompactView(compactView: boolean) {
        const customerViewModel = this.dataViewModel();
        if (!customerViewModel) return;

        customerViewModel.CompactView(compactView);
    }

    public enableAutoScrollOnMetadatas() {
        const viewModel = this.dataViewModel();
        viewModel?.enableAutoScrollOnMetadatas();
    }

    public disableAutoScrollOnMetadatas() {
        const viewModel = this.dataViewModel();
        viewModel?.disableAutoScrollOnMetadatas();
    }

    public AddPhoneNumber(label: string, phoneNumber: string) {
        this.dataViewModel().AddPhoneNumber(label, phoneNumber);
    }

    public AddMail(label: string, mail: string) {
        this.dataViewModel().AddMail(label, mail);
    }

    public SetCustomerField(isCustomer: boolean) {
        if (isCustomer !== undefined && isCustomer !== null) this.dataViewModel().IsCliente(isCustomer);
    }

    public SetSupplierField(isSupplier: boolean) {
        if (isSupplier !== undefined && isSupplier !== null) this.dataViewModel().IsFornitore(isSupplier);
    }

    jobOrderClicked(jobOrderProvider: IJobOrderNavigationMenuLeafProvider) {
        const url: string = this.jobOrderService.getJobOrderUrl(jobOrderProvider.jobOrder.Id);
        window.open(url, "_blank");
    }

    allJobOrderSelected(jobOrderProviders: IJobOrderNavigationMenuLeafProvider[]) {
        //Do nothing
    }

    dispose() {
        this.customersService.removeObserver(this);
    }

    public cancel(): Promise<boolean> {
        const def = new Deferred<boolean>();

        if (this.dataViewModel().isChanged()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Customers.CancelMsg,
                ProlifeSdk.TextResources.Customers.CancelNo,
                ProlifeSdk.TextResources.Customers.CancelConfirm,
                (result) => {
                    def.resolve(result);

                    if (!result) return;

                    this.revertChanges();
                    this.switchToViewMode();

                    if (this.modal) this.modal.close(false);
                }
            );
        } else {
            def.resolve(true);
            this.switchToViewMode();
            if (this.modal) this.modal.close(false);
        }

        return def.promise();
    }

    private switchToViewMode() {
        if (this.modal) return;
        this.state("view");
        this.saveVisible(false);
        this.cancelVisible(false);
        this.deleteVisible(false);
        this.editVisible(true);
        this.dataViewModel().SetEditMode(false);
    }

    private revertChanges() {
        if (this.modal) return;
        this.dataViewModel().load(/*this.customer*/);
    }

    public save() {
        let errors = this.dataViewModel().getValidation();

        if (errors.length > 0) {
            this.showValidation(true);
            this.infoToast.Warning(errors.map((e) => e.message).join("<br/>"));
            return;
        }

        errors = this.dataViewModel()
            .ActivePaymentTypeManagerUi.getValidator()
            .validate(this.dataViewModel().ActivePaymentTypeManagerUi);
        if (errors.length > 0) {
            const message: string = ProlifeSdk.TextResources.Customers.CustomerDefaultValuesError;
            this.infoToast.Warning(message + ":<br/>" + "<br/>" + errors.map((e) => e.message).join("<br/>"));
            return;
        }

        if (
            this.dataViewModel().LettersOfAttemptsManager() &&
            !this.dataViewModel().LettersOfAttemptsManager().isValid()
        ) {
            return;
        }

        if (!this.dataViewModel().TrustManagementEnabled() || !this.dataViewModel().TrustManager()) {
            this.ShowValidationWarningsAndSave();
            return;
        }

        this.dataViewModel()
            .TrustManager()
            .IsValid()
            .then((result: boolean) => {
                if (!result) return;

                this.ShowValidationWarningsAndSave();
            });
    }

    private ShowValidationWarningsAndSave(): void {
        this.dataViewModel()
            .getValidationWarnings()
            .then((warnings: string[]) => {
                if (warnings.length > 0) {
                    this.dialogsService.Confirm(
                        String.format(ProlifeSdk.TextResources.Customers.SaveMsg, warnings.join("<br> - ")),
                        ProlifeSdk.TextResources.Customers.SaveNo,
                        ProlifeSdk.TextResources.Customers.SaveConfirm,
                        this.onDoSave.bind(this)
                    );
                } else {
                    this.onDoSave();
                }
            })
            .catch((error: string) => {
                this.infoToast.Error(error);
            });
    }

    private onDoSave(result = true) {
        if (!result) return;

        this.dialogsService.LockUI(ProlifeSdk.TextResources.Customers.Saving);

        const fullCustomer: IFullCustomer = { Customer: null, Trusts: [], CustomerGroup: null, LettersOfAttempts: [] };

        fullCustomer.Customer = this.dataViewModel().getData();
        fullCustomer.Trusts =
            fullCustomer.Customer.TrustManagementEnabled && this.dataViewModel().TrustManager()
                ? this.dataViewModel().TrustManager().GetData()
                : [];
        fullCustomer.CustomerGroup = this.dataViewModel().CustomerGroupId();
        fullCustomer.LettersOfAttempts = !this.dataViewModel().LettersOfAttemptsManager()
            ? []
            : this.dataViewModel().LettersOfAttemptsManager().getModifiedLetters();
        fullCustomer.PaymentExtensions = !this.dataViewModel().PaymentExtensionsEditor()
            ? []
            : this.dataViewModel().PaymentExtensionsEditor().GetData();

        this.customersService
            .saveCustomer(fullCustomer)
            .then(this.customerSaved.bind(this))
            .catch(this.customerSaveFailed.bind(this))
            .finally(() => {
                this.dialogsService.UnlockUI();
            });
    }

    private customerSaved(c: ICustomer) {
        if (this.modal) this.modal.close(c);
        else {
            this.dataViewModel().Refresh(c);
            this.switchToViewMode();
            this.infoToast.Success(ProlifeSdk.TextResources.Customers.CustomerSaved);
        }
    }

    private customerSaveFailed(jqXHR: JQueryXHR) {
        //this.infoToast.Error(ProlifeSdk.TextResources.Customers.SaveError);
    }

    public edit() {
        this.state("edit");
        this.saveVisible(true);
        this.cancelVisible(true);
        this.deleteVisible(true);
        this.editVisible(false);
        this.dataViewModel().SetEditMode(true);
    }

    public showJobOrdersLockDialog() {
        const dialog: IDialog = new JobOrdersLockDialog(this.customer.IdCliente);
        this.dialogsService
            .ShowModal<boolean>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName)
            .then((result: boolean) => {
                if (!result) return;

                this.jobOrdersList().Refresh();
                this.infoToast.Success(ProlifeSdk.TextResources.Customers.OperationDone);
            });
    }

    public showCustomerLockDialog() {
        const dialog: IDialog = new CustomerLockDialog(this.customer.IdCliente);
        this.dialogsService
            .ShowModal<ICustomer>(dialog, null, null, dialog.templateUrl, dialog.templateName)
            .then((result: ICustomer) => {
                if (!result) return;

                this.dataViewModel().Refresh(result);
                this.jobOrdersList().Refresh();
                this.infoToast.Success(ProlifeSdk.TextResources.Customers.ContactBlocked);
            });
    }

    public unlockCustomer() {
        this.customersService
            .SetCustomerBlockStatus(this.customer.IdCliente, false, false, null)
            .then((c: ICustomer) => {
                this.dataViewModel().Refresh(c);
                this.infoToast.Success(ProlifeSdk.TextResources.Customers.ContactUnblocked);
                this.showJobOrdersLockDialog();
            });
    }

    public deleteItem() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Customers.CustomerDeleteMsg,
            ProlifeSdk.TextResources.Customers.CustomerDeleteCancel,
            ProlifeSdk.TextResources.Customers.CustomerDeleteConfirm,
            this.onDeleteItemConfirm.bind(this)
        );
    }

    private onDeleteItemConfirm(result: boolean) {
        if (!result) return;
        this.customersService
            .deleteCustomer(this.customer.IdCliente)
            .then(this.customerDeleted.bind(this))
            .catch(this.customerDeleteFailed.bind(this));
    }

    private customerDeleted() {
        this.infoToast.Success(ProlifeSdk.TextResources.Customers.CustomerDeleted);
    }

    private customerDeleteFailed() {
        this.infoToast.Error(ProlifeSdk.TextResources.Customers.CustomerDeleteError);
    }

    onCustomerChanged(customer: ICustomer): void {
        this.dataViewModel().updateCustomer(customer);
    }

    onCustomerAdded(customer: ICustomer): void {
        this.dataViewModel().updateCustomer(customer);
    }

    onCustomerDeleted(customerId: number): void {
        /* if(this.dataViewModel().getCustomerId() == customerId) {

        } */
    }

    close(): void {
        this.cancel();
    }

    action(): void {
        this.save();
    }

    templateName = "general-info";
    title: string = ProlifeSdk.TextResources.Customers.NewContact;
    modal: { close: (result?: any) => void };
}

export class CustomerJobOrderForLock {
    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    public isSelected: ko.Observable<boolean> = ko.observable(false);
    public selectedMotivationDescription: ko.Computed<string>;
    public isDefault: ko.Computed<boolean>;
    private selectedMotivation: ko.Observable<any> = ko.observable(null);
    public motivations: any[] = [];

    constructor(private jobOrder: IJobOrderForList) {
        this.motivations = [
            {
                Id: -1,
                Description: ProlifeSdk.TextResources.Customers.Unblocked,
                Deleted: false,
                IsDefault: false,
            },
        ].concat(
            this.motivationsSettings.getMotivations().map((m: any) => {
                m.IsDefault = false;
                return m;
            })
        );

        const matches = this.motivations.filter((m: any) => {
            return (!jobOrder.Locked && m.Id == -1) || m.Id == jobOrder.LockMotivationId;
        });
        if (matches.length > 0) {
            matches[0].IsDefault = true;
        }
        this.setMotivation(matches[0]);

        this.selectedMotivationDescription = ko.computed(() => {
            return this.selectedMotivation()
                ? this.selectedMotivation().Description
                : ProlifeSdk.TextResources.Customers.Unblocked;
        });

        this.isDefault = ko.computed(() => {
            return this.selectedMotivation() ? this.selectedMotivation().IsDefault : false;
        });
    }

    public setMotivation(m: IJobOrderBlockMotivation) {
        this.selectedMotivation(m);
    }

    public switchSelection() {
        this.isSelected(!this.isSelected());
    }

    public getId() {
        return this.jobOrder.JobOrderId;
    }

    public getLockMotivationId() {
        return this.selectedMotivation() ? this.selectedMotivation().Id : -1;
    }
}

export class JobOrdersLockDialog implements IDialog {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    templateName = "customer-job-orders-lock-dialog";
    templateUrl = "customers/templates/customer";
    title: string;
    modal: {
        close: (result?: any) => void;
    };

    private jobOrders: ko.ObservableArray<CustomerJobOrderForLock> = ko.observableArray([]);
    public selectedJobOrders: ko.Computed<CustomerJobOrderForLock[]>;
    public motivations: IJobOrderBlockMotivation[] = [];
    public selectedMotivationDescription: ko.Computed<string>;
    private selectedMotivation: ko.Observable<IJobOrderBlockMotivation> = ko.observable();
    public allJobOrdersSelected: ko.Computed<boolean>;

    constructor(customerId: number) {
        this.motivations = [
            <IJobOrderBlockMotivation>{
                Id: -1,
                Description: ProlifeSdk.TextResources.Customers.Unblocked,
                Deleted: false,
            },
        ].concat(this.motivationsSettings.getMotivations());

        this.setMotivation(this.motivations[0]);

        this.jobOrdersService
            .GetJobOrdersList(-1, -1, -1, false, "", customerId)
            .then((jobOrders: IJobOrderForList[]) => {
                this.jobOrders(
                    jobOrders.map((j: IJobOrderForList) => {
                        return new CustomerJobOrderForLock(j);
                    })
                );
            });

        this.selectedJobOrders = ko.computed(() => {
            return this.jobOrders().filter((j) => {
                return j.isSelected();
            });
        });

        this.selectedMotivationDescription = ko.computed(() => {
            return this.selectedMotivation()
                ? this.selectedMotivation().Description
                : ProlifeSdk.TextResources.Customers.Unblocked;
        });

        this.allJobOrdersSelected = ko.computed({
            read: () => {
                return this.selectedJobOrders().length == this.jobOrders().length;
            },
            write: (value: boolean) => {
                this.jobOrders().forEach((jobOrder: CustomerJobOrderForLock) => {
                    jobOrder.isSelected(value);
                });
            },
        });
    }

    public setMotivation(m: IJobOrderBlockMotivation) {
        this.selectedMotivation(m);
    }

    public setMotivationToSelected() {
        this.selectedJobOrders().forEach((jobOrder: CustomerJobOrderForLock) => {
            const motivation = jobOrder.motivations.filter((m) => m.Id == this.selectedMotivation().Id);
            jobOrder.setMotivation(motivation[0]);
        });
    }

    close() {
        this.modal.close(false);
    }

    action() {
        const jobOrdersToUpdate = this.jobOrders().filter((jobOrder: CustomerJobOrderForLock) => {
            return !jobOrder.isDefault();
        });

        this.jobOrdersService
            .SetLockStatusForJobOrders(
                jobOrdersToUpdate.map((j: CustomerJobOrderForLock) => {
                    return <IJobOrderLockInfo>{
                        JobOrderId: j.getId(),
                        Locked: j.getLockMotivationId() != -1,
                        BlockCause: j.getLockMotivationId() != -1 ? j.getLockMotivationId() : null,
                    };
                })
            )
            .then(() => {
                this.modal.close(true);
            });
    }
}

export class CustomerLockDialog implements IDialog {
    @LazyImport(nameof<ICustomersService>())
    private customerService: ICustomersService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    templateName = "customer-lock-dialog";
    templateUrl = "customers/templates/customer";
    title: string = ProlifeSdk.TextResources.Customers.BlockContact;
    modal: {
        close: (result?: any) => void;
    };

    public motivations: IJobOrderBlockMotivation[] = [];
    public selectedMotivation: ko.Observable<any> = ko.observable(null);

    constructor(private customerId: number) {
        this.motivations = this.motivationsSettings.getMotivations();
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        if (!this.selectedMotivation()) return;

        this.customerService
            .SetCustomerBlockStatus(this.customerId, true, true, this.selectedMotivation())
            .then((customer: ICustomer) => {
                this.modal.close(customer);
            });
    }
}
