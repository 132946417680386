import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderEditor } from "./job-order-detail/JobOrderEditor";
import { SplashPage } from "./splash-page/SplashPage";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IJobOrderDetailsObserver } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderDetailsObserver";

//
//  Brief : ViewModel principale del modulo JobOrder
//
export class jobOrderPannel implements IJobOrderDetailsObserver
{
	public CurrentViewModel : ko.Observable<any> = ko.observable(null);
    public SplashPage : SplashPage;
    public ShowedJobOrder : JobOrderEditor;

	constructor(private serviceLocator : IServiceLocator)
    {
        this.SplashPage = new SplashPage(serviceLocator);
	}

    public initialize() : Promise<void>
    {
        return this.SplashPage.Initialize();
    }

    public dispose()
    {
        this.SplashPage.dispose();

        if(this.ShowedJobOrder)
            this.ShowedJobOrder.dispose();
    }

    public OpenSplashPage()
    {
        if(this.ShowedJobOrder)
            this.ShowedJobOrder.dispose();

        this.CurrentViewModel(this.SplashPage);
    }

	public openJobOrder(jobOrderId : number, refreshMenu : boolean)
    {
        if(this.ShowedJobOrder)
            this.ShowedJobOrder.dispose();

        this.ShowedJobOrder = new JobOrderEditor(this.serviceLocator, this);
        this.CurrentViewModel(this.ShowedJobOrder);
        this.CurrentViewModel().load(jobOrderId);
	}

    public notifyChanges(jobOrderId : number)
    {
        if(jobOrderId == -1)
            location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL;
        else if(!this.ShowedJobOrder.JobOrder().JobOrderId())
            location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + jobOrderId;
        else
            this.openJobOrder(jobOrderId, true);
    }

    public newCommessa() : void
    {
        if(this.ShowedJobOrder)
            this.ShowedJobOrder.dispose();

        this.ShowedJobOrder = new JobOrderEditor(this.serviceLocator, this);
        this.CurrentViewModel(this.ShowedJobOrder);
		this.CurrentViewModel().createNew();
	}
}