import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { SystemHeader } from "./desktop/SystemHeader";
import { SessionExpiredHandler } from "./session/SessionExpiredHandler";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxFilterHostService } from "../Core/interfaces/IAjaxFilterHostService";
import { IDesktopService } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationHost } from "./interfaces/IApplicationHost";
import { IApplicationsService } from "./interfaces/IApplicationsService";
import { IApplicationBase } from "./interfaces/IApplication";
import { IMailsService } from "../Blog/MailsService";
import { LazyImport } from "../Core/DependencyInjection";
import { MailEventsGeneratorDialog } from "../Blog/Blog/Dialog/MailEventsGenerator/MailEventsGenerator";

export class Main implements IApplicationHost
{
	public application : ko.Observable<IApplicationBase> = ko.observable();
    public systemHeader : SystemHeader;

    /* Per versione mobile */
    LeftMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    RightMenuEnabled: ko.Observable<boolean> = ko.observable(false);
    observers: (() => void)[] = [];
    /* ------------------- */

    @LazyImport(nameof<IMailsService>())
    private mailsService : IMailsService;
    @LazyImport(nameof<IAjaxFilterHostService>())
    private ajaxFilterHostService : IAjaxFilterHostService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    private mailEventsGeneratorDialog: MailEventsGeneratorDialog;

	constructor(private serviceLocator : IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IApplicationHost>(), this);

		this.ajaxFilterHostService.addFilter(new SessionExpiredHandler(serviceLocator));

        window.onbeforeunload = this.onApplicationClose.bind(this);

        if("onpagehide" in window) {
            window.addEventListener("pagehide", this.disposeApplication.bind(this), false);
        } else {
            //Sembrerebbe che questo non sia più possibile, quindi casto ad any per mantenere il codice
            window.addEventListener("unload", this.disposeApplication.bind(this), false);
        }

        this.systemHeader = new SystemHeader(serviceLocator);
        this.systemHeader.Initialize();

        this.desktopService.SystemHeader = this.systemHeader;

        this.desktopService.Connect();
	}

    disposeApplication(evt : any = null, application : IApplicationBase = null) {
        if(evt && evt.persisted) return;

        const oldApplication : IApplicationBase = this.application();
        this.application(undefined);

        if(oldApplication && oldApplication != application) {
            oldApplication.onGoingDown();
            //delete oldApplication;
        }
    }

    getServiceType(): string {
        return ProlifeSdk.ApplicationHostServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

	private onApplicationClose() {
		if(this.application() && this.application().beforeClose)
			return this.application().beforeClose();
	}

	goHome() {
		location.href = "#/";
        this.SetSideMenuEnabled(true,false);
	}

	startHome() {
        const applicationLauncher = <IApplicationBase> <IApplicationsService> this.serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
		this.startApplication(applicationLauncher);
	}

	startApplication(application : IApplicationBase) : void {
        if(application === this.application())
            return;

        this.disposeApplication(null, application);
        this.systemHeader.setCurrentApplication(application);
		this.application(application);
    }
    
    getCurrentApplicationCode(): string {
        const currentApplication = this.application();
        if (!currentApplication)
            return undefined;
            
        return currentApplication.getApplicationCode();
    }

    public registerObserver(observer : (() => void)) {
        this.observers.push(observer);
    }

    public closeMenu() {
        this.observers.forEach( (obs : () => void) =>
        {
            obs();
        });
    }

    public goDesktop() {
        location.href = "#/";
        this.closeMenu();
    }

    public SetSideMenuEnabled(leftMenuEnabled: boolean, rightMenuEnabled: boolean): void {
        this.LeftMenuEnabled(leftMenuEnabled);
        this.RightMenuEnabled(rightMenuEnabled);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFileDrop(filesList: File[], evt: MouseEvent) {
        if (!this.mailEventsGeneratorDialog) {
            this.mailEventsGeneratorDialog = new MailEventsGeneratorDialog();
            this.mailEventsGeneratorDialog
                .show()
                .finally(() => this.mailEventsGeneratorDialog = undefined);
        }

        if (filesList.length > 0) {
            this.mailEventsGeneratorDialog.uploadMails(filesList);
        }
    }
}