/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 16/11/2016
 * Time: 17:03
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import {
    IArticlesService,
    IArticleTransform,
    IArticleTransformComponent,
    IArticle,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class ArticleTransformEditorDialog implements IDialog {
    templateName = "transformEditorDialog";
    templateUrl = "warehouse/templates";
    title: string;
    modal: {
        close: (result?: any) => void;
    };

    public Construction: ko.ObservableArray<TransformComponent> = ko.observableArray();

    public ArticleId: ko.Observable<number> = ko.observable(0);
    public ArticleCode: ko.Observable<string> = ko.observable();
    public ArticleDescription: ko.Observable<string> = ko.observable();
    public ArticleIsRecursive: ko.Observable<boolean> = ko.observable(false);

    public NewConstructionArticleId: ko.Observable<number> = ko.observable(0);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService: IArticlesService;

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if (this.ArticleId() <= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Warehouse.SelectAnArticle);
            return;
        }

        if (this.Construction().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Warehouse.DefineAtLeastATransform);
            return;
        }

        if (this.Construction().filter((c) => c.IsRecursive()).length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Warehouse.PossibleRecursion);
            return;
        }

        this.articlesService
            .InsertOrUpdateArticleTransform(this.ArticleId(), this.getTransform())
            .then(() => {
                this.modal.close(true);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Warehouse.ErrorWhileSavingTransforms);
            });
    }

    constructor(private transform: IArticleTransform) {
        if (this.transform) {
            this.title = ProlifeSdk.TextResources.Warehouse.EditTransform;
            this.Construction(transform.Construction.map(this.createViewModelFor.bind(this)));

            this.ArticleId(transform.ArticleId);
            this.ArticleCode(transform.Code);
            this.ArticleDescription(transform.Description);
        } else {
            this.title = ProlifeSdk.TextResources.Warehouse.CreateTransform;
        }

        this.ArticleId.subscribe(this.updateDescription.bind(this));
    }

    private createViewModelFor(transformComponent: IArticleTransformComponent) {
        return new TransformComponent(this.articlesService, this.ArticleId, transformComponent);
    }

    private getTransform(): IArticleTransform {
        const transform: IArticleTransform = {
            ArticleId: this.ArticleId(),
            Code: this.ArticleCode(),
            Description: this.ArticleDescription(),
            Construction: this.Construction().map((c) => c.getTransformComponent()),
            Destruction: [],
        };

        return transform;
    }

    private updateDescription() {
        this.articlesService.getArticleByArticleId(this.ArticleId(), true).then((article: IArticle) => {
            this.ArticleCode(article.Code);
            this.ArticleDescription(article.Description);
        });
    }

    public show(): Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(this, "large", {}, this.templateUrl, this.templateName);
    }

    public addConstructionArticle() {
        if (this.NewConstructionArticleId() <= 0) return false;

        const newArticle: IArticleTransformComponent = {
            Id: 0,
            ComponentArticleId: this.NewConstructionArticleId(),
            ComponentCode: "",
            ComponentDescription: "",
            Amount: 1,
        };
        const vm = this.createViewModelFor(newArticle);

        this.articlesService.getArticleByArticleId(this.NewConstructionArticleId(), true).then((article: IArticle) => {
            newArticle.ComponentCode = article.Code;
            newArticle.ComponentDescription = article.Description;

            vm.ComponentCode(article.Code);
            vm.ComponentDescription(article.Description);
        });

        this.Construction.push(vm);

        this.NewConstructionArticleId(0);

        return false;
    }

    public removeCompositionArticle(component: TransformComponent) {
        this.Construction.remove(component);
    }
}

export class TransformComponent {
    Id: ko.Observable<number> = ko.observable();
    ComponentArticleId: ko.Observable<number> = ko.observable();
    ComponentCode: ko.Observable<string> = ko.observable();
    ComponentDescription: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable();
    IsRecursive: ko.Observable<boolean> = ko.observable();

    constructor(
        private articlesService: IArticlesService,
        private ArticleId: ko.Observable<number>,
        component: IArticleTransformComponent
    ) {
        this.Id(component.Id);
        this.ComponentArticleId(component.ComponentArticleId);
        this.ComponentCode(component.ComponentCode);
        this.ComponentDescription(component.ComponentDescription);
        this.Amount(component.Amount);

        this.ArticleId.subscribe(this.checkRecursive.bind(this));
        this.checkRecursive();
    }

    private checkRecursive() {
        this.articlesService
            .IsTransformParentRecursive(this.ArticleId(), this.ComponentArticleId())
            .then((isRecursive: boolean) => {
                this.articlesService
                    .IsTransformChildRecursive(this.ArticleId(), this.ComponentArticleId())
                    .then((isChildRecursive: boolean) => {
                        this.IsRecursive(isRecursive || isChildRecursive);
                    });
            });
    }

    public getTransformComponent(): IArticleTransformComponent {
        const component: IArticleTransformComponent = {
            Id: this.Id(),
            ComponentArticleId: this.ComponentArticleId(),
            ComponentCode: this.ComponentCode(),
            ComponentDescription: this.ComponentDescription(),
            Amount: this.Amount(),
        };
        return component;
    }
}
