import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../Core/DependencyInjection";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { BaseComponentApplication } from "../../Desktop/BaseComponentApplication";

export class QuotationApplication extends BaseComponentApplication {
    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;

    constructor() {
        super({
            tileColor: "tile-red"
        });
    }
    
    getName(): string {
        return TextResources.Quotation.ApplicationName;
    }

    getIcon(): string {
        return "fa fa-file-text-o";
    }

    registerRoutes(): void {
        this.opaService.Get(ProlifeSdk.QuotationApplicationRoute, () => this.startApplication());
    }

    getApplicationRoute(): string {
        return ProlifeSdk.QuotationApplicationRoute;
    }

    getApplicationCode(): string {
        return ProlifeSdk.QuotationApplicationCode;
    }

    startApplication() {
        this.onGoingDown();
        this.applicationsService.startApplication(this);
    }
    
    getComponent(): React.ReactNode {
        const QuotationSelector = require("./QuotationSelector").QuotationSelector;
        return <QuotationSelector navBarActionManager={this} />
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationApplication);
}