import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 15/03/2018
 * Time: 19:01
 * To change this template use File | Settings | File Templates.
 */

export interface IRgba {
    r: number;
    g: number;
    b: number;
    a: number;
}

export class UiUtilities {
    
    private constructor() {}
    
    public static GetForegroundColor(color : string) : string {
        let c = UiUtilities.HexToRgb(color);
        let percievedBrightness = Math.round(Math.sqrt( 0.299*c.r*c.r + 0.587*c.g*c.g + 0.114*c.b*c.b ));

        if (percievedBrightness <= 128)
            return "#FFFFFF";
        return "#000000";
    }

    public static GetForegroundColorForAlphaColor(color : string, alpha: number, backgroundColor: string) : string {
        let c = UiUtilities.BlendColor(alpha, color, backgroundColor);
        let percievedBrightness = Math.round(Math.sqrt( 0.299*c.r*c.r + 0.587*c.g*c.g + 0.114*c.b*c.b ));

        if(percievedBrightness <= 128)
            return "#FFFFFF";
        return "#000000";
    }

    public static BlendColor(alpha: number, source: string, dest: string): { r: number; g: number; b: number; } {
        let sourceRGB = UiUtilities.HexToRgb(source);
        let destRGB = UiUtilities.HexToRgb(dest);

        return {
            r: Math.round(alpha * (sourceRGB.r - destRGB.r) + destRGB.r),
            g: Math.round(alpha * (sourceRGB.g - destRGB.g) + destRGB.g),
            b: Math.round(alpha * (sourceRGB.b - destRGB.b) + destRGB.b)
        };
    }

    public static HexToRgb(hexColor : string) : IRgba {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1
        } : null;
    }

    public static GetColorWithOpacity(hexColor : string, opacity : number) : string {
        let rgb = UiUtilities.HexToRgb(hexColor);
        return UiUtilities.RgbToString(rgb, opacity);
    }

    public static RgbToString(rgb : IRgba, overrideAlpha? : number) {
        if(overrideAlpha != undefined || overrideAlpha != null) {
            rgb.a = overrideAlpha;
        }

        return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a +")";
    }

    public static StringToRGB(color: string): IRgba {
        if (color.startsWith("#"))
            return UiUtilities.HexToRgb(color);

        let result = /^rgb[a]?\(([0-9]+){1},([0-9]+){1},([0-9]+){1}(,[0-1](\.[0-9]+)?)?\)$/i.exec(UiUtilities.RemoveWhiteSpaces(color));
        return result ? {
            r: parseInt(result[1]),
            g: parseInt(result[2]),
            b: parseInt(result[3]),
            a: !result[4] ? 1 : parseFloat(result[4].substr(1))
        } : null;
    }

    private static RemoveWhiteSpaces(input: string): string {
        return (input || "").trim().split(" ").filter(p => p.length > 0).join("");
    }
}