import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/11/2017
 * Time: 10:20
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { SalEventDetailsEdit } from "./details/SalEventDetailsEdit";
import { ISalService } from "../../../../ProlifeSdk/interfaces/sal/ISalService";
import { ISal, ISalRow } from "../../../../ProlifeSdk/interfaces/sal/ISal";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class SalEvent extends DocumentEvent implements IView {
    private salService: ISalService;

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.SalEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.JobOrder.SAL;
        this.salService = <ISalService> this.serviceLocator.findService(ProlifeSdk.SalServiceType);
    }

    public getEditViewModel(): IEventBaseInput {
        return new SalEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails() {
        super.LoadDetails();
        this.LoadDocumentsInfo();
    }

    public LoadDocumentsInfo(): Promise<void> {
        var def = new Deferred<void>();
        this.salService.get(this.DocumentId())
            .then((d: ISal) => {
                if (!d)
                    return;

                this.Total(d.Total);

                this.salService.getDocumentRowsById(this.DocumentId())
                    .then((r: ISalRow[]) => {
                        this.NumberOfRows(r ? r.length : 0);
                    });
            })
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }
}