import * as ko from "knockout";
import * as numeral from "numeral";
import { IDiscountsManager, IDiscount, IDiscountsService } from "../../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { Deferred } from "../../../../Core/Deferred";

export class SupplierDiscountsManager implements IDiscountsManager {
    private discounts : IDiscount[] = [];

    constructor(private discountsService : IDiscountsService, private supplierId : number, loadNow : boolean) {
        if(loadNow)
            this.RefreshDiscounts();
    }

    public RefreshDiscounts() : Promise<void>
    {
        var def = new Deferred<void>();
        this.discountsService.getDiscountsBySupplierId(this.supplierId, false)
            .then((discounts) => {
                this.discounts = discounts || []
                def.resolve();
            }).catch(() => {
                def.resolve();
            });
        return def.promise();
    }

    getExplicitPriceForArticle(article : IArticle) : number {
        var explicitPriceDiscounts : IDiscount[] = this.discounts.filter((discount : IDiscount) => discount.Category == 4 && discount.ArticleId == article.Id);
        if(explicitPriceDiscounts.length == 0) return null;
        return explicitPriceDiscounts[0].Price;
    }

    getDiscountsForArticle(article : IArticle) : number[] {
        var articleDiscounts : IDiscount[] = this.discounts
            .filter((discount : IDiscount) => discount.Category == 0 || ((discount.ArticleId == article.Id || discount.ManufacturerId == article.ManufacturerId || discount.DiscountFamilyId == article.DiscountFamily) && discount.Category < 4))
            .sort((left : IDiscount, right : IDiscount) => right.Category - left.Category);

        if(articleDiscounts.length == 0) return [0];
        var discount : IDiscount = articleDiscounts[0];
        var result = [];
        discount.Discount0 > 0 && result.push(discount.Discount0);
        discount.Discount1 > 0 && result.push(discount.Discount1);
        discount.Discount2 > 0 && result.push(discount.Discount2);
        discount.Discount3 > 0 && result.push(discount.Discount3);
        discount.Discount4 > 0 && result.push(discount.Discount4);
        return result || [0];
    }

    getDefaultDiscounts() : number[] {
        return [0];
    }

    getFormattedDiscountsForArticle(article : IArticle) : string {
        var discounts = this.getDiscountsForArticle(article);
        var formatted = discounts.map((discount : number) => numeral(discount / 100).format("0%"));
        return formatted.join(" ");
    }

    getFormattedDefaultDiscounts() : string {
        return "0%";
    }
}