import * as ko from "knockout";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { ArrayDataSource, IArrayDataSourceModel, IArrayDataSourceListener, IArrayDataSource } from "../../../../../DataSources/ArrayDataSource";
import { IDiscountCatalog, IDiscountsCatalogsService, ICustomerDiscountCatalog, ICustomerGroupDiscountCatalog } from "../../../../DiscountsCatalogsService";
import { IDialogsService, IDialog } from "../../../../../Core/interfaces/IDialogsService";

export class AssignDiscountCatalogDialog implements IDialog, IArrayDataSourceListener<IDiscountCatalog> {
    templateName: string = "assignDiscountCatalog";
    templateUrl: string = "warehouse/templates/discounts";
    title: string = "Assegnazione scontistiche";
    modal: {
        close: (result?: any) => void;
    };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService : IDiscountsCatalogsService;
    
    public SourceArrayDataSource: ArrayDataSource<IDiscountCatalog> = new ArrayDataSource<IDiscountCatalog>();
    public ArrayDataSource: ArrayDataSource<IDiscountCatalog> = new ArrayDataSource<IDiscountCatalog>();

    constructor(private customerOrGroupId: number, private isCustomerGroup : boolean, private isCustomer : boolean) {
        let allDiscountCatalogsPromise = this.discountsCatalogsService.GetDiscountCatalogs({skip: 0, count: 1000000, showForCustomers: isCustomer, showForSuppliers: !isCustomer, showExpired: false});
        let customerDiscountCatalogsPromise : Promise<ICustomerDiscountCatalog[] | ICustomerGroupDiscountCatalog[]>;
        if(!isCustomerGroup)
            customerDiscountCatalogsPromise = this.discountsCatalogsService.GetCustomerDiscountCatalogs(customerOrGroupId, 0, 1000000, isCustomer);
        else
            customerDiscountCatalogsPromise = this.discountsCatalogsService.GetCustomerGroupDiscountCatalogs(customerOrGroupId, 0, 1000000);

        this.SourceArrayDataSource.setDefaultMimeType("application/prolife-customer-discount-catalog");
        this.SourceArrayDataSource.setSupportedDropMimeTypes("application/prolife-customer-discount-catalog")
        this.ArrayDataSource.setDefaultMimeType("application/prolife-customer-discount-catalog");
        this.ArrayDataSource.setSupportedDropMimeTypes("application/prolife-customer-discount-catalog");

        this.SourceArrayDataSource.addListener(this);
        this.ArrayDataSource.addListener(this);

        Promise.all([allDiscountCatalogsPromise, customerDiscountCatalogsPromise])
            .then((result) => {
                let discountCatalogs = result[0];
                let customerDiscountCatalogs = result[1];

                let notAssignedDiscountCatalogs = discountCatalogs.filter(c => customerDiscountCatalogs.findIndex(cdc => cdc.Id == c.Id) == -1);

                this.SourceArrayDataSource.setData(...notAssignedDiscountCatalogs.map(this.createModelFor, this));
                this.ArrayDataSource.setData(...customerDiscountCatalogs.map(this.createModelCustomerFor, this));

                this.SourceArrayDataSource.refresh();
                this.ArrayDataSource.refresh();
            })
    }

    onItemAdded(sender : IArrayDataSource<IDiscountCatalog>, model : IArrayDataSourceModel<IDiscountCatalog>) : void {
        if(sender == this.ArrayDataSource) {
            this.SourceArrayDataSource.removeItem(model);
        } else if(sender == this.SourceArrayDataSource) {
            this.ArrayDataSource.removeItem(model);
        }
    }

    async onItemMoved(sender : IArrayDataSource<IDiscountCatalog>, model : IArrayDataSourceModel<IDiscountCatalog>) : Promise<void> {
        if(sender == this.ArrayDataSource) {

        } else if(sender == this.SourceArrayDataSource) {
            
        }
    }

    private createModelFor(discountCatalog : IDiscountCatalog) : IArrayDataSourceModel<IDiscountCatalog> {
        return {
            id: discountCatalog.Id,
            title: discountCatalog.Name,
            subTitle: discountCatalog.Description,
            isLeaf: true,
            isGroup: false,
            dragEnabled: true,
            model: discountCatalog
        }
    }
    
    private createModelCustomerFor(discountCatalog : ICustomerDiscountCatalog | ICustomerGroupDiscountCatalog) : IArrayDataSourceModel<IDiscountCatalog> {
        return {
            id: discountCatalog.Id,
            title: discountCatalog.Name,
            subTitle: discountCatalog.Description,
            isLeaf: true,
            isGroup: false,
            dragEnabled: true,
            model: {
                Id: discountCatalog.Id,
                Name: discountCatalog.Name,
                Description: discountCatalog.Description,
                StartValidity: discountCatalog.StartValidity,
                EndValidity: discountCatalog.EndValidity,
                RevisionDate: discountCatalog.RevisionDate,
                ForCustomers: true
            }
        }
    }
    
    close(): void {
        this.modal.close(false);
    }
    async action(): Promise<void> {
        let assignedDiscountCatalogs = this.ArrayDataSource.getAllData()
            .map((dc, index) => {
                return {
                    CatalogId: dc.id,
                    Order: index
                }
            });

        let promise;
        if(!this.isCustomerGroup) 
            promise = this.discountsCatalogsService.AssignDiscountCatalogsToCustomer(this.customerOrGroupId, assignedDiscountCatalogs, this.isCustomer);
        else {
            if(!await this.dialogsService.ConfirmAsync("Questa operazione imposterà le scontistiche selezionate su tutti i clienti assegnati al gruppo. Sei sicuro di voler continuare?", "Annulla", "Applica Scontistiche"))
                return;

            promise = this.discountsCatalogsService.AssignDiscountCatalogsToCustomerGroup(this.customerOrGroupId, assignedDiscountCatalogs);
        }
        
            promise
                .then(() => {
                    this.modal.close(true);
                });
    }
    showModal(): Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(this, "large");
    }
}
