import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IPrintTemplate, IPrintTemplateSettingsManager } from "../PrintTemplateSettingsManager";
import { List, ListItem } from "../../../../Components/ListComponent";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { CheckBox } from "../../../../Components/Checkbox";

const styleSheet = jss.createStyleSheet({
    printTemplatesSelector: {
        border: '1px solid #e5e5e5',

        "&.list-notification-container .list-notification .list-notification-item:last-child": {
            borderBottom: '1px solid #e5e5e5',
        }
    }
});
const { classes } = styleSheet.attach();

type PrintTemplatesSelectorProps = {
    printTemplates: ko.ObservableArray<IPrintTemplate>;
    preferredPrintTemplateId: ko.Observable<number>;
}

export function PrintTemplatesSelector(props: PrintTemplatesSelectorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./PrintTemplatesSelector")._PrintTemplatesSelector as typeof _PrintTemplatesSelector;
    return <C {...props} />;
}

type SelectablePrintTemplate = {
    Selected: ko.Computed<boolean>;
    Preferred: ko.Computed<boolean>;
} & IPrintTemplate;

export class _PrintTemplatesSelector {
    static defaultProps: Partial<PrintTemplatesSelectorProps> = {
    }

    @LazyImportSettingManager(nameof<IPrintTemplateSettingsManager>())
    private printTemplateSettingsManager : IPrintTemplateSettingsManager;

    allPrintTemplates : ko.ObservableArray<SelectablePrintTemplate> = ko.observableArray();

    constructor(private props : PrintTemplatesSelectorProps) {
        
    }
    
    componentDidMount() {
        this.reload();
    }

    reload() {
        this.allPrintTemplates(this.printTemplateSettingsManager.getPrintTemplates().map(pt => {
            return {
                ...pt,
                Selected: ko.computed({
                    read: () => {
                        return this.props.printTemplates().any(p => p.id === pt.id);
                    },
                    write: (value: boolean) => {
                        if(value) {
                            if(this.props.printTemplates().any(p => p.id === pt.id))
                                return;

                            this.props.printTemplates.push(pt);
                        } else {
                            const ptToRemove = this.props.printTemplates().firstOrDefault(p => p.id === pt.id);
                            this.props.printTemplates.remove(ptToRemove);
                        }
                    }
                }),
                Preferred: ko.computed({
                    read: () => this.props.preferredPrintTemplateId() == pt.id,
                    write: (value: boolean) => {
                        if(value) {
                            this.props.preferredPrintTemplateId(pt.id);
                        }
                    }
                })
            }
        }));
    }

    renderItem(item: ListItem<number, SelectablePrintTemplate>) {
        const notSelected = ko.computed(() => !item.Model.Selected());
        return <div className="flex-container flex-child-center">
            <CheckBox checked={item.Model.Selected} style={{ margin: '0 5px'}} />
            <span>{item.Title()}</span>
            <CheckBox readOnly={notSelected} checked={item.Model.Preferred} icon onIcon="fa fa-star" offIcon="fa fa-star-o" style={{ margin: 0, marginLeft: 'auto', marginRight: '5px', fontSize: '1.5em' }} />
        </div>
    }

    render() {
        return <List 
                    containerHeight="180px"
                    scrollable={false}
                    systemScrollable
                    className={classNames(classes.printTemplatesSelector, "flex-1")} 
                    dataSource={Table.defaultDataSource(this.allPrintTemplates, (i) => ({ id: i.id, title: i.title, model: i }))} 
                    emptyResultMessage="Nessun modello di stampa selezionato"
                    itemRenderer={this.renderItem.bind(this)}/>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PrintTemplatesSelector);
}