import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { NavigationMenu } from "../../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { ITodoListTemplateTask } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import { INavigationMenuProvider } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";

export class TaskProvider extends WorkflowsNavigatorProvider
{
    public IsVisible : ko.Computed<boolean>;

    //Toggle
    public ShowToggles : ko.Observable<boolean> = ko.observable(false);
    public ToggleTrueLabel : ko.Observable<string> = ko.observable();
    public ToggleFalseLabel : ko.Observable<string> = ko.observable();
    public Toggle : ko.Observable<boolean> = ko.observable(false);
    public IsToggleEnabled : ko.Computed<boolean>;
    private OnTogglesChangesCallback : (p : INavigationMenuProvider) => { } = null;

    constructor(serviceLocator : IServiceLocator, public task : ITodoListTemplateTask)
    {
        super(serviceLocator);
        this.Name = task.Title;
        this.templateName = "template-task-item";
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.IsLeaf = true;

        this.IsToggleEnabled = ko.computed(() => {
            return this.ShowToggles() && this.IsSelected();
        });

        this.Toggle.subscribe(() => {
            if(!this.IsToggleEnabled())
                return;

            if(this.OnTogglesChangesCallback)
                this.OnTogglesChangesCallback(this);
        });
    }

    public setCurrentNavigator(navigator: INavigationMenu)
    {
        super.setCurrentNavigator(navigator);
        this.ShowToggles((<NavigationMenu>navigator).ShowToggles);
        this.ToggleTrueLabel((<NavigationMenu>navigator).TogglesTrueLabel);
        this.ToggleFalseLabel((<NavigationMenu>navigator).TogglesFalseLabel);
        this.OnTogglesChangesCallback = (<NavigationMenu>navigator).OnTogglesChangesCallback;
    }

    search(textFilter : string) : Promise<void>
    {
        return Promise.resolve<void>(undefined);
    }
}
