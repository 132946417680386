import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { WarehouseLoadDefaultValuesSettingsUi } from "../../ui/Documents/default-values/WarehouseLoadDefaultValuesSettingsUi";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolDefaultValuesSettingsUi } from "../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";

export class WarehouseLoadsProvider extends DocumentsProvider
{
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private protocol : IVatRegister)
    {
        super(ProlifeSdk.WarehouseLoadEntityTypeCode);

        this.CanEdit = protocol.Stato == ProlifeSdk.EnabledProtocolState;
        this.CanBeAssociatedToJobOrder = false;
        this.ProviderId = "Whl";

        this.Id = protocol.IdRegistroIVA;
        this.Name(protocol.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(protocol.NuovoLabel);

        this.CanCreateNew(protocol.Stato == ProlifeSdk.EnabledProtocolState && this.authorizationService.isAuthorized("Warehouse_Load"));
        this.CanView(this.CanCreateNew() || (protocol.Stato != ProlifeSdk.HiddenProtocolState && this.authorizationService.isAuthorized("Warehouse_ViewLoad")));

        this.ProviderId = "Whl" + this.Id;
        this.HasDefaultValuesSettingsUi = true;
        this.DefaultSearchProvider = protocol.DefaultSearchProvider;
    }

    GetDocumentGroupLabel(): string {
        return this.protocol.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.protocol.PositionInList;
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        return [{
            DocumentType: ProlifeSdk.WarehouseLoadEntityTypeCode,
            NewLabel: this.protocol.NuovoLabel
        }];
    }

    GetDefaultValuesSettingsUi() : IProtocolDefaultValuesSettingsUi
    {
        return new WarehouseLoadDefaultValuesSettingsUi(this.protocol, this.ProviderId);
    }
}
