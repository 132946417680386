import { ProtocolMailRecipient } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IMailRecipientForDocumentSend } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class ProtocolMailRecipientMapper {
    public static mapToProtocolMailRecipient(recipients: IMailRecipientForDocumentSend[]): ProtocolMailRecipient[] {
        return recipients.map((r) => ({
            protocolId: r.ProtocolId,
            valueType: r.ValueType,
            value: r.Value,
            valueDataType: r.ValueDataType,
        }));
    }

    public static mapToJobOrderMailRecipient(
        recipients: ProtocolMailRecipient[],
        jobOrderId: number
    ): IMailRecipientForDocumentSend[] {
        return recipients.map((r) => ({
            JobOrderId: jobOrderId,
            ProtocolId: r.protocolId,
            ValueType: r.valueType,
            Value: r.value,
            ValueDataType: r.valueDataType,
        }));
    }
}
