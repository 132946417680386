import * as ko from "knockout";
/**
 * Created by d.bucchi on 13/06/2017.
 */
export class ShowTaskboardSearchField {
    public TaskboardSearchFieldOpened: ko.Observable<boolean>;

    constructor() {
        this.TaskboardSearchFieldOpened = ko.observable(false);
    }

    public switchOpenedStatus() {
        this.TaskboardSearchFieldOpened(!this.TaskboardSearchFieldOpened());
    }
}