import * as ko from "knockout";
import { KnockoutType } from "@abstraqt-dev/jsxknockout";

export interface ISelectOnFocusOptions {
    moveToEnd?: boolean;
    observeValue?: ko.Observable<boolean>;
}

declare global {
    interface KnockoutDataBindsAttributes {
        selectOnFocus?: void | ko.MaybeSubscribable<ISelectOnFocusOptions>;
    }
}

export class SelectOnFocus {
    init(element: any, valueAccessor: () => ko.MaybeSubscribable<ISelectOnFocusOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options : ISelectOnFocusOptions = ko.utils.unwrapObservable(valueAccessor()) as ISelectOnFocusOptions;

        var focusState = false;
        element.addEventListener('mousedown', () => {
            focusState = document.activeElement == element;
        });

        if(options.moveToEnd) {
            element.addEventListener('click', () => {
                if (focusState)
                    return false;
                focusState = false;

                if (element.selectionStart != element.selectionEnd)
                    return false;

                if(element.selectionStart != 0 || element.selectionEnd != 0)
                    return false;

                element.selectionStart = element.selectionEnd = (element.val || "").length;
                return false;
            });
        } else {
            element.addEventListener('click', () => {
                if (focusState)
                    return false;
                focusState = false;

                if (element.selectionStart != element.selectionEnd)
                    return false;

                $(element).trigger("select");
                return false;
            });
        }

        if (options.observeValue && ko.isObservable(options.observeValue)) {
            $(element).on('focus', () => {
                $(element).trigger("select");
                return false;
            });

            var subscribe = options.observeValue.subscribe((newValue: boolean) => {
                if (newValue)
                    $(element).trigger("focus");
            });

            if (options.observeValue())
                $(element).trigger("focus");

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscribe.dispose();
            });
        }
    }
}

ko.bindingHandlers["selectOnFocus"] = new SelectOnFocus();