import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { JobOrderMetadata } from "../../../../../Components/JobOrderMetadata";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { TableFilter } from "../../../../../Components/TableComponent/TableFilter";
import { TextInput } from "../../../../../Components/TextInput";
import { DateRangeInput } from "../../../../../Components/DateRangeInput";
import { JobOrderLink } from "../../JobOrderLink";
import {
    JobOrderAlerts,
    WorkflowAlerts,
    WorkflowAnalysis,
    WorkflowAnalysisCategory,
    WorkflowAnalysisJobOrderClassification,
    WorkflowAnalysisJobOrderState,
    WorkflowAnalysisJobOrderType,
    WorkflowAnalysisOutcome,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { JobOrderDetailsButton } from "../../JobOrderDetailsButton";
import { ItemWithIcon } from "../../../../../Components/ItemWithIcon";
import { Html } from "../../../../../Components/Html";
import { DateTimeText } from "../../../../../Components/DateTimeText";
import { NumericText } from "../../../../../Components/NumericText";
import { AllocationBadge } from "../../../../../Components/AllocationBadge";
import { RelatedDocumentsBadge } from "../../../../../Components/RelatedDocumentsBadge";
import { toPascalCase } from "../../../../../Core/utils/NamingConventions";
import { WorkflowsUtils } from "../../../../../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { Alert } from "../../../../../Components/Alert";
import { JobOrdersUtils } from "../../utils/JobOrdersUtils";
import { RangeInput } from "../../../../../Components/RangeInput";
import { TextFiltersUtilities } from "../../../../../Core/utils/TextFiltersUtilities";
import { ListFilterUtilities } from "../../../../../Core/utils/ListFilterUtilities";
import { DateRangeFilterUtilities } from "../../../../../Core/utils/DateRangeFilterUtilities";
import { NumericRangeFilterUtilities } from "../../../../../Core/utils/NumericRangeFilterUtilities";
import { JobOrderBlogButton } from "../../JobOrderBlogButton";
import { WorkflowEditorButton } from "../../WorkflowEditorButton";
import { WorkflowTasksEditorButton } from "../../WorkflowTasksEditorButton";

const styleSheet = jss.createStyleSheet({
    table: {
        "& > thead": {
            "& > tr": {
                "& > th": {
                    minWidth: "190px",

                    "&.doc-number, &.doc-date": {
                        minWidth: "120px",
                    },

                    "&.doc-version-revision": {
                        minWidth: "135px",
                    },

                    "& .dropdown-menu": {
                        minWidth: "290px !important",
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type WorkflowsTableProps = {
    workflows: ko.ObservableArray<WorkflowAnalysis>;
};

type WorkflowAnalysisModel = IDataSourceModel<number, WorkflowAnalysis>;

function WorkflowAlerts(props: { alerts: WorkflowAlerts }) {
    const pascalCaseAlerts = toPascalCase(props.alerts);
    return (
        <>
            {WorkflowsUtils.getAlertsForWorkflow(pascalCaseAlerts).map((alert, index) => (
                <Alert icon={alert.icon.icon} foreground={alert.icon.foreground} tooltip={alert.tooltip} key={index} />
            ))}
        </>
    );
}

function JobOrderAlerts(props: { alerts: JobOrderAlerts }) {
    const pascalCaseAlerts = toPascalCase(props.alerts);
    return (
        <>
            {JobOrdersUtils.getAlertsForJobOrder(pascalCaseAlerts).map((alert, index) => (
                <Alert icon={alert.icon.icon} foreground={alert.icon.foreground} tooltip={alert.tooltip} key={index} />
            ))}
        </>
    );
}

export function WorkflowsTable(props: WorkflowsTableProps) {
    const C = require("./WorkflowsTable")._WorkflowsTable as typeof _WorkflowsTable;
    return <C {...props} />;
}

export class _WorkflowsTable {
    static defaultProps: Partial<WorkflowsTableProps> = {};

    private Workflows: ko.ObservableArray<WorkflowAnalysis> = ko.observableArray([]);
    private subscriptions: ko.Subscription[] = [];

    private jobOrdersSearchField: ko.Observable<string> = ko.observable();
    private customersSearchField: ko.Observable<string> = ko.observable();
    private jobOrderNotesSearchField: ko.Observable<string> = ko.observable();
    private jobOrderAdministrativeNotesSearchField: ko.Observable<string> = ko.observable();
    private jobOrderCommercialNotesSearchField: ko.Observable<string> = ko.observable();
    private workflowTitleSearchField: ko.Observable<string> = ko.observable();
    private workflowStartDateFromFilter: ko.Observable<Date> = ko.observable();
    private workflowStartDateToFilter: ko.Observable<Date> = ko.observable();
    private workflowEndDateFromFilter: ko.Observable<Date> = ko.observable();
    private workflowEndDateToFilter: ko.Observable<Date> = ko.observable();
    private workflowDescriptionSearchField: ko.Observable<string> = ko.observable();
    private estimatedWorkMinValueFilter: ko.Observable<number> = ko.observable();
    private estimatedWorkMaxValueFilter: ko.Observable<number> = ko.observable();
    private progressedEstimatedWorkMinValueFilter: ko.Observable<number> = ko.observable();
    private progressedEstimatedWorkMaxValueFilter: ko.Observable<number> = ko.observable();
    private remainingEstimatedWorkMinValueFilter: ko.Observable<number> = ko.observable();
    private remainingEstimatedWorkMaxValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedWorkWithWorkflowSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedWorkWithWorkflowSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedWorkWithJobOrderSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedWorkWithJobOrderSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private workedHoursMinValueFilter: ko.Observable<number> = ko.observable();
    private workedHoursMaxValueFilter: ko.Observable<number> = ko.observable();
    private remainingHoursBasedOnJobOrderSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private remainingHoursBasedOnJobOrderSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private remainingHoursBasedOnWorkflowSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private remainingHoursBasedOnWorkflowSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private workflowSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private workflowSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private jobOrderSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private jobOrderSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private corBudgetMinValueFilter: ko.Observable<number> = ko.observable();
    private corBudgetMaxValueFilter: ko.Observable<number> = ko.observable();
    private requestedBudgetMinValueFilter: ko.Observable<number> = ko.observable();
    private requestedBudgetMaxValueFilter: ko.Observable<number> = ko.observable();
    private approvedBudgetMinValueFilter: ko.Observable<number> = ko.observable();
    private approvedBudgetMaxValueFilter: ko.Observable<number> = ko.observable();
    private estimatedCostsMinValueFilter: ko.Observable<number> = ko.observable();
    private estimatedCostsMaxValueFilter: ko.Observable<number> = ko.observable();
    private progressedEstimatedCostsMinValueFilter: ko.Observable<number> = ko.observable();
    private progressedEstimatedCostsMaxValueFilter: ko.Observable<number> = ko.observable();
    private remainingEstimatedCostsMinValueFilter: ko.Observable<number> = ko.observable();
    private remainingEstimatedCostsMaxValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedCostsWithWorkflowSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedCostsWithWorkflowSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedCostsWithJobOrderSpeedMinValueFilter: ko.Observable<number> = ko.observable();
    private reestimatedCostsWithJobOrderSpeedMaxValueFilter: ko.Observable<number> = ko.observable();
    private nextMilestoneFromFilter: ko.Observable<Date> = ko.observable();
    private nextMilestoneToFilter: ko.Observable<Date> = ko.observable();
    private remainingMilestonesMinValueFilter: ko.Observable<number> = ko.observable();
    private remainingMilestonesMaxValueFilter: ko.Observable<number> = ko.observable();
    private allocationStatusMinValueFilter: ko.Observable<number> = ko.observable();
    private allocationStatusMaxValueFilter: ko.Observable<number> = ko.observable();
    private firstAllocationDateFromFilter: ko.Observable<Date> = ko.observable();
    private firstAllocationDateToFilter: ko.Observable<Date> = ko.observable();
    private lastAllocationDateFromFilter: ko.Observable<Date> = ko.observable();
    private lastAllocationDateToFilter: ko.Observable<Date> = ko.observable();
    private workEndDateFromFilter: ko.Observable<Date> = ko.observable();
    private workEndDateToFilter: ko.Observable<Date> = ko.observable();
    private relatedDocumentsStatusMinValueFilter: ko.Observable<number> = ko.observable();
    private relatedDocumentsStatusMaxValueFilter: ko.Observable<number> = ko.observable();

    private selectedJobOrderClassifications: number[] = [];
    private selectedJobOrderTypes: number[] = [];
    private selectedJobOrderStates: number[] = [];
    private selectedJobOrderLogicalStates: number[] = [];
    private selectedResponsible: string[] = [];
    private selectedAdministrativeResponsible: string[] = [];
    private selectedCommercialResponsible: string[] = [];
    private selectedOutcomes: number[] = [];
    private selectedWorkflowCategories: number[] = [];
    private selectedWorkflowStates: number[] = [];
    private selectedWorkflowLogicalStates: number[] = [];

    constructor(private props: WorkflowsTableProps) {}

    private applyFilters(workflows: WorkflowAnalysis[]) {
        return workflows.filter((workflow) => {
            return (
                TextFiltersUtilities.contains(workflow.jobOrder.title, this.jobOrdersSearchField()) &&
                TextFiltersUtilities.contains(workflow.jobOrder.customer.formattedName, this.customersSearchField()) &&
                TextFiltersUtilities.contains(workflow.jobOrder.notes, this.jobOrderNotesSearchField()) &&
                TextFiltersUtilities.contains(
                    workflow.jobOrder.administrativeNotes,
                    this.jobOrderAdministrativeNotesSearchField()
                ) &&
                TextFiltersUtilities.contains(
                    workflow.jobOrder.commercialNotes,
                    this.jobOrderCommercialNotesSearchField()
                ) &&
                TextFiltersUtilities.contains(workflow.title, this.workflowTitleSearchField()) &&
                TextFiltersUtilities.contains(workflow.description, this.workflowDescriptionSearchField()) &&
                ListFilterUtilities.isContained(workflow.jobOrder.responsibleName, this.selectedResponsible) &&
                ListFilterUtilities.isContained(
                    workflow.jobOrder.administrativeResponsibleName,
                    this.selectedAdministrativeResponsible
                ) &&
                ListFilterUtilities.isContained(
                    workflow.jobOrder.commercialResponsibleName,
                    this.selectedCommercialResponsible
                ) &&
                ListFilterUtilities.isContained(workflow.outcome.id, this.selectedOutcomes) &&
                ListFilterUtilities.isContained(workflow.category.id, this.selectedWorkflowCategories) &&
                ListFilterUtilities.isContained(workflow.state.id, this.selectedWorkflowStates) &&
                ListFilterUtilities.isContained(workflow.state.logicalState, this.selectedWorkflowLogicalStates) &&
                ListFilterUtilities.isContained(
                    workflow.jobOrder.classification?.id,
                    this.selectedJobOrderClassifications
                ) &&
                ListFilterUtilities.isContained(workflow.jobOrder.type.id, this.selectedJobOrderTypes) &&
                ListFilterUtilities.isContained(workflow.jobOrder.state.id, this.selectedJobOrderStates) &&
                ListFilterUtilities.isContained(
                    workflow.jobOrder.state.logicalState,
                    this.selectedJobOrderLogicalStates
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.startDate,
                    this.workflowStartDateFromFilter(),
                    this.workflowStartDateToFilter()
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.endDate,
                    this.workflowEndDateFromFilter(),
                    this.workflowEndDateToFilter()
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.allocationStatus.firstAllocationDate,
                    this.firstAllocationDateFromFilter(),
                    this.firstAllocationDateToFilter()
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.allocationStatus.lastAllocationDate,
                    this.lastAllocationDateFromFilter(),
                    this.lastAllocationDateToFilter()
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.allocationStatus.workEndDate,
                    this.workEndDateFromFilter(),
                    this.workEndDateToFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.estimatedHours,
                    this.estimatedWorkMinValueFilter(),
                    this.estimatedWorkMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.progressedEstimatedHours,
                    this.progressedEstimatedWorkMinValueFilter(),
                    this.progressedEstimatedWorkMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.remainingEstimatedHours,
                    this.remainingEstimatedWorkMinValueFilter(),
                    this.remainingEstimatedWorkMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.reestimatedHoursWithWorkflowSpeed,
                    this.reestimatedWorkWithWorkflowSpeedMinValueFilter(),
                    this.reestimatedWorkWithWorkflowSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.reestimatedHoursWithJobOrderSpeed,
                    this.reestimatedWorkWithJobOrderSpeedMinValueFilter(),
                    this.reestimatedWorkWithJobOrderSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.workedHours,
                    this.workedHoursMinValueFilter(),
                    this.workedHoursMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.remainingHoursBasedOnJobOrderSpeed,
                    this.remainingHoursBasedOnJobOrderSpeedMinValueFilter(),
                    this.remainingHoursBasedOnJobOrderSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.workInfo.remainingHoursBasedOnWorkflowSpeed,
                    this.remainingHoursBasedOnWorkflowSpeedMinValueFilter(),
                    this.remainingHoursBasedOnWorkflowSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.speed,
                    this.workflowSpeedMinValueFilter(),
                    this.workflowSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.jobOrder.speed,
                    this.jobOrderSpeedMinValueFilter(),
                    this.jobOrderSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.budgetFromCustomerOrders,
                    this.corBudgetMinValueFilter(),
                    this.corBudgetMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.requestedBudget,
                    this.requestedBudgetMinValueFilter(),
                    this.requestedBudgetMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.approvedBudget,
                    this.approvedBudgetMinValueFilter(),
                    this.approvedBudgetMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.estimatedCosts,
                    this.estimatedCostsMinValueFilter(),
                    this.estimatedCostsMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.progressedEstimatedCosts,
                    this.progressedEstimatedCostsMinValueFilter(),
                    this.progressedEstimatedCostsMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.remainingEstimatedCosts,
                    this.remainingEstimatedCostsMinValueFilter(),
                    this.remainingEstimatedCostsMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.reestimatedCostsWithWorkflowSpeed,
                    this.reestimatedCostsWithWorkflowSpeedMinValueFilter(),
                    this.reestimatedCostsWithWorkflowSpeedMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.economicalInfo.reestimatedCostsWithJobOrderSpeed,
                    this.reestimatedCostsWithJobOrderSpeedMinValueFilter(),
                    this.reestimatedCostsWithJobOrderSpeedMaxValueFilter()
                ) &&
                DateRangeFilterUtilities.isInRange(
                    workflow.nextMilestone,
                    this.nextMilestoneFromFilter(),
                    this.nextMilestoneToFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.remainingMilestones,
                    this.remainingMilestonesMinValueFilter(),
                    this.remainingMilestonesMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.allocationStatus.allocationPercentage,
                    this.allocationStatusMinValueFilter(),
                    this.allocationStatusMaxValueFilter()
                ) &&
                NumericRangeFilterUtilities.isInRange(
                    workflow.relatedDocumentsStatus.relatedDocuments,
                    this.relatedDocumentsStatusMinValueFilter(),
                    this.relatedDocumentsStatusMaxValueFilter()
                )
            );
        });
    }

    public componentDidMount() {
        this.subscriptions.push(
            this.props.workflows.subscribe((workflows) => this.Workflows(this.applyFilters(workflows)))
        );

        this.subscriptions.push(
            this.jobOrdersSearchField.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );

        this.subscriptions.push(
            this.customersSearchField.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.jobOrderNotesSearchField.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.jobOrderAdministrativeNotesSearchField.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.jobOrderCommercialNotesSearchField.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.workflowTitleSearchField.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowStartDateFromFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowStartDateToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowEndDateFromFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowEndDateToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.firstAllocationDateFromFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.firstAllocationDateToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.lastAllocationDateFromFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.lastAllocationDateToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workEndDateFromFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workEndDateToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowDescriptionSearchField.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.estimatedWorkMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.estimatedWorkMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.progressedEstimatedWorkMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.progressedEstimatedWorkMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingEstimatedWorkMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingEstimatedWorkMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedWorkWithWorkflowSpeedMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedWorkWithWorkflowSpeedMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedWorkWithJobOrderSpeedMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedWorkWithJobOrderSpeedMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.workedHoursMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workedHoursMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.remainingHoursBasedOnJobOrderSpeedMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingHoursBasedOnJobOrderSpeedMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.workflowSpeedMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.workflowSpeedMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.jobOrderSpeedMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.jobOrderSpeedMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.corBudgetMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.corBudgetMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.requestedBudgetMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.requestedBudgetMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.approvedBudgetMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.approvedBudgetMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.estimatedCostsMinValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.estimatedCostsMaxValueFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.progressedEstimatedCostsMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.progressedEstimatedCostsMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedCostsWithWorkflowSpeedMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingEstimatedCostsMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingEstimatedCostsMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedCostsWithWorkflowSpeedMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedCostsWithJobOrderSpeedMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.reestimatedCostsWithJobOrderSpeedMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.nextMilestoneFromFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.nextMilestoneToFilter.subscribe(() => this.Workflows(this.applyFilters(this.props.workflows())))
        );
        this.subscriptions.push(
            this.remainingMilestonesMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.remainingMilestonesMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.allocationStatusMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.allocationStatusMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.relatedDocumentsStatusMinValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
        this.subscriptions.push(
            this.relatedDocumentsStatusMaxValueFilter.subscribe(() =>
                this.Workflows(this.applyFilters(this.props.workflows()))
            )
        );
    }

    public componentWillUnmount() {
        this.subscriptions.forEach((s) => s.dispose());
    }

    private workflowsFactory(workflow: WorkflowAnalysis): WorkflowAnalysisModel {
        return {
            id: workflow.id,
            title: workflow.title,
            isGroup: false,
            isLeaf: true,
            model: workflow,
        };
    }

    private onJobOrderClassificationSelectionChange(selectedItems: number[]) {
        this.selectedJobOrderClassifications = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onJobOrderTypeSelectionChange(selectedItems: number[]) {
        this.selectedJobOrderTypes = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onJobOrderStateSelectionChange(selectedItems: number[]) {
        this.selectedJobOrderStates = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onJobOrderLogicalStateSelectionChange(selectedItems: number[]) {
        this.selectedJobOrderLogicalStates = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onResponsibleSelectionChange(selectedItems: string[]): void {
        this.selectedResponsible = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onAdministrativeResponsibleSelectionChange(selectedItems: string[]): void {
        this.selectedAdministrativeResponsible = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onCommercialResponsibleSelectionChange(selectedItems: string[]): void {
        this.selectedCommercialResponsible = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onOutcomeSelectionChange(selectedItems: number[]) {
        this.selectedOutcomes = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onWorkflowCategorySelectionChange(selectedItems: number[]) {
        this.selectedWorkflowCategories = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onWorkflowStateSelectionChange(selectedItems: number[]) {
        this.selectedWorkflowStates = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private onWorkflowLogicalStateSelectionChange(selectedItems: number[]) {
        this.selectedWorkflowLogicalStates = selectedItems;
        this.Workflows(this.applyFilters(this.props.workflows()));
    }

    private getJobOrderLogicalStateLabel(logicalState: number) {
        return logicalState === 0 ? TextResources.JobOrder.Open : TextResources.JobOrder.Closed;
    }

    private getWorkflowLogicalStateLabel(logicalState: number) {
        switch (logicalState) {
            case 0:
                return TextResources.JobOrder.WorkflowTodo;
            case 1:
                return TextResources.JobOrder.WorkflowInProgress;
            case 2:
                return TextResources.JobOrder.WorkflowDone;
            case 3:
                return TextResources.JobOrder.WorkflowVerified;
            default:
                return "";
        }
    }

    render() {
        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<WorkflowAnalysis>();

        const renderJobOrderClassificationItemForFilter = (classification: WorkflowAnalysisJobOrderClassification) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                {!classification?.id && TextResources.ProlifeSdk.EmptyTableFilterLabel}
                {classification && <JobOrderMetadata {...classification} />}
            </div>
        );

        const renderJobOrderTypeItemForFilter = (type: WorkflowAnalysisJobOrderType) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                <ItemWithIcon
                    label={type.label}
                    icon={type.icon}
                    background={type.background}
                    foreground={type.foreground}
                />
            </div>
        );

        const renderJobOrderStateItemForFilter = (state: WorkflowAnalysisJobOrderState) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                <ItemWithIcon
                    label={state.label}
                    icon={state.icon}
                    background={state.background}
                    foreground={state.foreground}
                />
            </div>
        );

        const renderWorkflowCategoryItemForFilter = (category: WorkflowAnalysisCategory) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                {!category?.id && TextResources.ProlifeSdk.EmptyTableFilterLabel}
                {category && (
                    <ItemWithIcon
                        label={category.label}
                        icon={category.icon}
                        background={category.background}
                        foreground={category.foreground}
                    />
                )}
            </div>
        );

        const renderJobOrderLogicalStateItemForFilter = (logicalState: number) => (
            <span>{this.getJobOrderLogicalStateLabel(logicalState)}</span>
        );

        const renderWorkflowLogicalStateItemForFilter = (logicalState: number) => (
            <span>{this.getWorkflowLogicalStateLabel(logicalState)}</span>
        );

        const renderWorkflowOutcomeItemForFilter = (outcome: WorkflowAnalysisOutcome) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                {!outcome?.id && TextResources.ProlifeSdk.EmptyTableFilterLabel}
                {outcome && (
                    <ItemWithIcon
                        label={outcome.label}
                        icon={outcome.icon}
                        background={outcome.background}
                        foreground={outcome.foreground}
                    />
                )}
            </div>
        );

        return (
            <Table
                id={ProlifeSdk.WorkflowsAnalysis}
                dataSource={{ array: this.Workflows, factory: this.workflowsFactory.bind(this) }}
                showColumnSelector
                columnSelectorPosition="right"
                systemScrollable
                compact
                rowAs="workflow"
                className={classes.table}
                enableAggregators
                sortableColumns
                useHoverEffect
                selectableRows
                selectRowsByCheckbox
                multipleRowsSelection
            >
                <Column
                    id={1}
                    title={TextResources.JobOrder.JobOrderTitle}
                    sorter={sortString((d) => d.jobOrder.title)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.title}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrdersSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.jobOrdersSearchField}
                                            label={TextResources.JobOrder.JobOrderFilter}
                                            placeholder={TextResources.JobOrder.JobOrderFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <div className="flex-container">
                                <div>
                                    <JobOrderDetailsButton
                                        jobOrderId={item.Data.model.jobOrder.id}
                                        jobOrderTitle={item.Data.model.jobOrder.title}
                                        extraSmall
                                    />
                                </div>
                                <div>
                                    <JobOrderBlogButton jobOrderId={item.Data.model.jobOrder.id} extraSmall />
                                </div>
                                <JobOrderLink
                                    className="flex-fill text-ellipsis"
                                    jobOrderId={item.Data.model.jobOrder.id}
                                    jobOrderName={item.Data.model.jobOrder.title}
                                    openNewTab
                                />
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={2}
                    title={TextResources.JobOrder.JobOrderClassificationTitle}
                    sorter={sortString((d) => d.jobOrder.classification?.name ?? "")}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.classification?.name}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderClassificationTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.classification?.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.jobOrder.classification?.name}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderJobOrderClassificationItemForFilter(item.jobOrder.classification)
                                    }
                                    onSelectionChange={this.onJobOrderClassificationSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) =>
                            item.Data.model.jobOrder.classification && (
                                <JobOrderMetadata {...item.Data.model.jobOrder.classification} />
                            )
                        }
                    </ColumnBody>
                </Column>
                <Column
                    id={3}
                    title={TextResources.JobOrder.JobOrderTypeTitle}
                    sorter={sortString((d) => d.jobOrder.type.label)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.type.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderTypeTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.type.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.jobOrder.type.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderJobOrderTypeItemForFilter(item.jobOrder.type)
                                    }
                                    onSelectionChange={this.onJobOrderTypeSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <ItemWithIcon
                                label={item.Data.model.jobOrder.type.label}
                                icon={item.Data.model.jobOrder.type.icon}
                                background={item.Data.model.jobOrder.type.background}
                                foreground={item.Data.model.jobOrder.type.foreground}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={4}
                    title={TextResources.JobOrder.JobOrderStateTitle}
                    sorter={sortString((d) => d.jobOrder.state.label)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.state.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderStateTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.state.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.jobOrder.state.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderJobOrderStateItemForFilter(item.jobOrder.state)
                                    }
                                    onSelectionChange={this.onJobOrderStateSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <ItemWithIcon
                                label={item.Data.model.jobOrder.state.label}
                                icon={item.Data.model.jobOrder.state.icon}
                                background={item.Data.model.jobOrder.state.background}
                                foreground={item.Data.model.jobOrder.state.foreground}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={45}
                    title={TextResources.JobOrder.JobOrderLogicalStateTitle}
                    sorter={sortString((d) => this.getJobOrderLogicalStateLabel(d.jobOrder.state.logicalState))}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.state.logicalState}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderLogicalStateTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.state.logicalState}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.jobOrder.state.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderJobOrderLogicalStateItemForFilter(item.jobOrder.state.logicalState)
                                    }
                                    onSelectionChange={this.onJobOrderLogicalStateSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>
                                {this.getJobOrderLogicalStateLabel(item.Data.model.jobOrder.state.logicalState)}
                            </span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={5}
                    title={TextResources.JobOrder.CustomerTitle}
                    sorter={sortString((d) => d.jobOrder.customer.formattedName)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.customer.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.CustomerTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.customersSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.customersSearchField}
                                            label={TextResources.JobOrder.CustomerFilter}
                                            placeholder={TextResources.JobOrder.CustomerFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>{item.Data.model.jobOrder.customer.formattedName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={6}
                    title={TextResources.JobOrder.ResponsibleTitle}
                    sorter={sortString((d) => d.jobOrder.responsibleName)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.responsibleName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ResponsibleTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.responsibleName}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.jobOrder.responsibleName}
                                    onSelectionChange={this.onResponsibleSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>{item.Data.model.jobOrder.responsibleName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={7}
                    title={TextResources.JobOrder.AdministrativeResponsibleTitle}
                    sorter={sortString((d) => d.jobOrder.administrativeResponsibleName)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.jobOrder.administrativeResponsibleName
                    }
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.AdministrativeResponsibleTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) =>
                                        item.jobOrder.administrativeResponsibleName
                                    }
                                    itemLabelGetter={(item: WorkflowAnalysis) =>
                                        item.jobOrder.administrativeResponsibleName
                                    }
                                    onSelectionChange={this.onAdministrativeResponsibleSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>{item.Data.model.jobOrder.administrativeResponsibleName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={8}
                    title={TextResources.JobOrder.CommercialResponsibleTitle}
                    sorter={sortString((d) => d.jobOrder.commercialResponsibleName)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.jobOrder.commercialResponsibleName
                    }
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.CommercialResponsibleTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.jobOrder.commercialResponsibleName}
                                    itemLabelGetter={(item: WorkflowAnalysis) =>
                                        item.jobOrder.commercialResponsibleName
                                    }
                                    onSelectionChange={this.onCommercialResponsibleSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>{item.Data.model.jobOrder.commercialResponsibleName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={9}
                    title={TextResources.JobOrder.NotesTitle}
                    sorter={sortString((d) => d.jobOrder.notes)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.NotesTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrderNotesSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.jobOrderNotesSearchField}
                                            label={TextResources.JobOrder.JobOrderNotesFilter}
                                            placeholder={TextResources.JobOrder.JobOrderNotesFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => <Html html={item.Data.model.jobOrder.notes} />}
                    </ColumnBody>
                </Column>
                <Column
                    id={10}
                    title={TextResources.JobOrder.AdministrativeNotesTitle}
                    sorter={sortString((d) => d.jobOrder.administrativeNotes)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.AdministrativeNotesTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrderAdministrativeNotesSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.jobOrderAdministrativeNotesSearchField}
                                            label={TextResources.JobOrder.JobOrderAdministrativeNotesFilter}
                                            placeholder={TextResources.JobOrder.JobOrderAdministrativeNotesFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <Html html={item.Data.model.jobOrder.administrativeNotes} />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={11}
                    title={TextResources.JobOrder.CommercialNotesTitle}
                    sorter={sortString((d) => d.jobOrder.commercialNotes)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.CommercialNotesTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrderCommercialNotesSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.jobOrderCommercialNotesSearchField}
                                            label={TextResources.JobOrder.JobOrderCommercialNotesFilter}
                                            placeholder={TextResources.JobOrder.JobOrderCommercialNotesFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <Html html={item.Data.model.jobOrder.commercialNotes} />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={12}
                    title={TextResources.JobOrder.WorkflowTitle}
                    sorter={sortString((d) => d.title)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.workflowTitleSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.workflowTitleSearchField}
                                            label={TextResources.JobOrder.WorkflowTitleFilter}
                                            placeholder={TextResources.JobOrder.WorkflowTitleFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <div className="flex-container">
                                {item.Data.model.id > 0 && (
                                    <>
                                        <div>
                                            <WorkflowEditorButton workflowId={item.Data.model.id} extraSmall />
                                        </div>
                                        <div>
                                            <WorkflowTasksEditorButton
                                                jobOrderId={item.Data.model.jobOrder.id}
                                                workflowId={item.Data.model.id}
                                                extraSmall
                                            />
                                        </div>
                                    </>
                                )}
                                <span>{item.Data.model.title}</span>
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={13}
                    title={TextResources.JobOrder.WorkflowCategoryTitle}
                    sorter={sortString((d) => d.title)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.category.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowCategoryTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.category.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.category.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderWorkflowCategoryItemForFilter(item.category)
                                    }
                                    onSelectionChange={this.onWorkflowCategorySelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <ItemWithIcon
                                label={item.Data.model.category.label}
                                icon={item.Data.model.category.icon}
                                background={item.Data.model.category.background}
                                foreground={item.Data.model.category.foreground}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={14}
                    title={TextResources.JobOrder.WorkflowStateTitle}
                    sorter={sortString((d) => d.title)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.state.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowStateTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.state.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.state.label}
                                    onSelectionChange={this.onWorkflowStateSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => <span>{item.Data.model.state.label}</span>}
                    </ColumnBody>
                </Column>
                <Column
                    id={46}
                    title={TextResources.JobOrder.WorkflowLogicalStateTitle}
                    sorter={sortString((d) => this.getWorkflowLogicalStateLabel(d.state.logicalState))}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.state.logicalState}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowLogicalStateTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.state.logicalState}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.state.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderWorkflowLogicalStateItemForFilter(item.state.logicalState)
                                    }
                                    onSelectionChange={this.onWorkflowLogicalStateSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <span>{this.getWorkflowLogicalStateLabel(item.Data.model.state.logicalState)}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={15}
                    title={TextResources.JobOrder.OutcomeTitle}
                    sorter={sortString((d) => d.outcome.label)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.outcome.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.OutcomeTitle}</span>
                                <TableFilter
                                    filterSource={this.props.workflows}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: WorkflowAnalysis) => item.outcome.id}
                                    itemLabelGetter={(item: WorkflowAnalysis) => item.outcome.label}
                                    itemRenderer={(item: WorkflowAnalysis) =>
                                        renderWorkflowOutcomeItemForFilter(item.outcome)
                                    }
                                    onSelectionChange={this.onOutcomeSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <ItemWithIcon
                                label={item.Data.model.outcome.label}
                                icon={item.Data.model.outcome.icon}
                                background={item.Data.model.outcome.background}
                                foreground={item.Data.model.outcome.foreground}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={16}
                    title={TextResources.JobOrder.StartDateTitle}
                    sorter={sortDate((d) => d.startDate)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.StartDateTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.workflowStartDateFromFilter() || !!this.workflowStartDateToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.WorkflowStartDateFilter}
                                            startDate={this.workflowStartDateFromFilter}
                                            endDate={this.workflowStartDateToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.startDate} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={17}
                    title={TextResources.JobOrder.EndDateTitle}
                    sorter={sortDate((d) => d.endDate)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.EndDateTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.workflowEndDateFromFilter() || !!this.workflowEndDateToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.WorkflowEndDateFilter}
                                            startDate={this.workflowEndDateFromFilter}
                                            endDate={this.workflowEndDateToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.endDate} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={18}
                    title={TextResources.JobOrder.WorkflowDescriptionTitle}
                    sorter={sortString((d) => d.description)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.id}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowDescriptionTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.workflowDescriptionSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.workflowDescriptionSearchField}
                                            label={TextResources.JobOrder.WorkflowDescriptionFilter}
                                            placeholder={TextResources.JobOrder.WorkflowDescriptionFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => <Html html={item.Data.model.description} />}
                    </ColumnBody>
                </Column>
                <Column
                    id={19}
                    title={TextResources.JobOrder.EstimatedWorkTitle}
                    tooltip={TextResources.JobOrder.EstimatedWorkTooltip}
                    sorter={sortNumber((d) => d.workInfo.estimatedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.workInfo.estimatedHours}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.EstimatedWorkTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.estimatedWorkMinValueFilter() !== null &&
                                            this.estimatedWorkMinValueFilter() !== undefined) ||
                                        (this.estimatedWorkMaxValueFilter() !== null &&
                                            this.estimatedWorkMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.EstimatedWorkTitle}
                                            minValue={this.estimatedWorkMinValueFilter}
                                            maxValue={this.estimatedWorkMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.estimatedHours}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={39}
                    title={TextResources.JobOrder.ProgressedEstimatedWorkTitle}
                    tooltip={TextResources.JobOrder.ProgressedEstimatedWorkTooltip}
                    sorter={sortNumber((d) => d.workInfo.progressedEstimatedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.progressedEstimatedHours
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ProgressedEstimatedWorkTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.progressedEstimatedWorkMinValueFilter() !== null &&
                                            this.progressedEstimatedWorkMinValueFilter() !== undefined) ||
                                        (this.progressedEstimatedWorkMaxValueFilter() !== null &&
                                            this.progressedEstimatedWorkMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ProgressedEstimatedWorkTitle}
                                            minValue={this.progressedEstimatedWorkMinValueFilter}
                                            maxValue={this.progressedEstimatedWorkMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.progressedEstimatedHours}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={40}
                    title={TextResources.JobOrder.RemainingEstimatedWorkTitle}
                    tooltip={TextResources.JobOrder.RemainingEstimatedWorkTooltip}
                    sorter={sortNumber((d) => d.workInfo.remainingEstimatedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.remainingEstimatedHours
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RemainingEstimatedWorkTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.remainingEstimatedWorkMinValueFilter() !== null &&
                                            this.remainingEstimatedWorkMinValueFilter() !== undefined) ||
                                        (this.remainingEstimatedWorkMaxValueFilter() !== null &&
                                            this.remainingEstimatedWorkMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RemainingEstimatedWorkTitle}
                                            minValue={this.remainingEstimatedWorkMinValueFilter}
                                            maxValue={this.remainingEstimatedWorkMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.progressedEstimatedHours}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={20}
                    title={TextResources.JobOrder.ReestimatedWorkWithWorkflowSpeedTitle}
                    tooltip={TextResources.JobOrder.ReestimatedWorkWithWorkflowSpeedTooltip}
                    sorter={sortNumber((d) => d.workInfo.estimatedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.reestimatedHoursWithWorkflowSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ReestimatedWorkWithWorkflowSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.reestimatedWorkWithWorkflowSpeedMinValueFilter() !== null &&
                                            this.reestimatedWorkWithWorkflowSpeedMinValueFilter() !== undefined) ||
                                        (this.reestimatedWorkWithWorkflowSpeedMaxValueFilter() !== null &&
                                            this.reestimatedWorkWithWorkflowSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ReestimatedWorkWithWorkflowSpeedTitle}
                                            minValue={this.reestimatedWorkWithWorkflowSpeedMinValueFilter}
                                            maxValue={this.reestimatedWorkWithWorkflowSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.reestimatedHoursWithWorkflowSpeed}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={21}
                    title={TextResources.JobOrder.ReestimatedWorkWithJobOrderSpeedTitle}
                    tooltip={TextResources.JobOrder.ReestimatedWorkWithJobOrderSpeedTooltip}
                    sorter={sortNumber((d) => d.workInfo.estimatedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.reestimatedHoursWithJobOrderSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ReestimatedWorkWithJobOrderSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.reestimatedWorkWithJobOrderSpeedMinValueFilter() !== null &&
                                            this.reestimatedWorkWithJobOrderSpeedMinValueFilter() !== undefined) ||
                                        (this.reestimatedWorkWithJobOrderSpeedMaxValueFilter() !== null &&
                                            this.reestimatedWorkWithJobOrderSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ReestimatedWorkWithJobOrderSpeedTitle}
                                            minValue={this.reestimatedWorkWithJobOrderSpeedMinValueFilter}
                                            maxValue={this.reestimatedWorkWithJobOrderSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.reestimatedHoursWithJobOrderSpeed}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={22}
                    title={TextResources.JobOrder.WorkedHoursTitle}
                    sorter={sortNumber((d) => d.workInfo.workedHours)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.workInfo.workedHours}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkedHoursTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.workedHoursMinValueFilter() !== null &&
                                            this.workedHoursMinValueFilter() !== undefined) ||
                                        (this.workedHoursMaxValueFilter() !== null &&
                                            this.workedHoursMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.WorkedHoursTitle}
                                            minValue={this.workedHoursMinValueFilter}
                                            maxValue={this.workedHoursMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.workedHours}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={23}
                    title={TextResources.JobOrder.RemainingHoursBasedOnWorkflowSpeedTitle}
                    tooltip={TextResources.JobOrder.RemainingHoursBasedOnWorkflowSpeedTooltip}
                    sorter={sortNumber((d) => d.workInfo.remainingHoursBasedOnWorkflowSpeed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.remainingHoursBasedOnWorkflowSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RemainingHoursBasedOnWorkflowSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.remainingHoursBasedOnWorkflowSpeedMinValueFilter() !== null &&
                                            this.remainingHoursBasedOnWorkflowSpeedMinValueFilter() !== undefined) ||
                                        (this.remainingHoursBasedOnWorkflowSpeedMaxValueFilter() !== null &&
                                            this.remainingHoursBasedOnWorkflowSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RemainingHoursBasedOnWorkflowSpeedTitle}
                                            minValue={this.remainingHoursBasedOnWorkflowSpeedMinValueFilter}
                                            maxValue={this.remainingHoursBasedOnWorkflowSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.remainingHoursBasedOnWorkflowSpeed}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={24}
                    title={TextResources.JobOrder.RemainingHoursBasedOnJobOrderSpeedTitle}
                    tooltip={TextResources.JobOrder.RemainingHoursBasedOnJobOrderSpeedTooltip}
                    sorter={sortNumber((d) => d.workInfo.remainingHoursBasedOnJobOrderSpeed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.workInfo.remainingHoursBasedOnJobOrderSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RemainingHoursBasedOnJobOrderSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.remainingHoursBasedOnJobOrderSpeedMinValueFilter() !== null &&
                                            this.remainingHoursBasedOnJobOrderSpeedMinValueFilter() !== undefined) ||
                                        (this.remainingHoursBasedOnJobOrderSpeedMaxValueFilter() !== null &&
                                            this.remainingHoursBasedOnJobOrderSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RemainingHoursBasedOnJobOrderSpeedTitle}
                                            minValue={this.remainingHoursBasedOnJobOrderSpeedMinValueFilter}
                                            maxValue={this.remainingHoursBasedOnJobOrderSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.workInfo.remainingHoursBasedOnJobOrderSpeed}
                                numberTextSuffix="hh"
                                format="0,0.00"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={25}
                    title={TextResources.JobOrder.WorkflowSpeedTitle}
                    sorter={sortNumber((d) => d.speed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.speed}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.WorkflowSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.workflowSpeedMinValueFilter() !== null &&
                                            this.workflowSpeedMinValueFilter() !== undefined) ||
                                        (this.workflowSpeedMaxValueFilter() !== null &&
                                            this.workflowSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.WorkflowSpeedTitle}
                                            minValue={this.workflowSpeedMinValueFilter}
                                            maxValue={this.workflowSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.speed} format="0,0.00" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={26}
                    title={TextResources.JobOrder.JobOrderSpeedTitle}
                    sorter={sortNumber((d) => d.jobOrder.speed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.jobOrder.speed}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.JobOrderSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.jobOrderSpeedMinValueFilter() !== null &&
                                            this.jobOrderSpeedMinValueFilter() !== undefined) ||
                                        (this.jobOrderSpeedMaxValueFilter() !== null &&
                                            this.jobOrderSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.JobOrderSpeedTitle}
                                            minValue={this.jobOrderSpeedMinValueFilter}
                                            maxValue={this.jobOrderSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.jobOrder.speed} format="0,0.00" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={27}
                    title={TextResources.JobOrder.BudgetFromCustomerOrdersTitle}
                    sorter={sortNumber((d) => d.economicalInfo.budgetFromCustomerOrders)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.economicalInfo.budgetFromCustomerOrders
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.BudgetFromCustomerOrdersTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.corBudgetMinValueFilter() !== null &&
                                            this.corBudgetMinValueFilter() !== undefined) ||
                                        (this.corBudgetMaxValueFilter() !== null &&
                                            this.corBudgetMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.BudgetFromCustomerOrdersTitle}
                                            minValue={this.corBudgetMinValueFilter}
                                            maxValue={this.corBudgetMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.economicalInfo.budgetFromCustomerOrders}
                                format="0,0.00 $"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={28}
                    title={TextResources.JobOrder.RequestedBudgetTitle}
                    sorter={sortNumber((d) => d.economicalInfo.requestedBudget)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.economicalInfo.requestedBudget}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RequestedBudgetTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.requestedBudgetMinValueFilter() !== null &&
                                            this.requestedBudgetMinValueFilter() !== undefined) ||
                                        (this.requestedBudgetMaxValueFilter() !== null &&
                                            this.requestedBudgetMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RequestedBudgetTitle}
                                            minValue={this.requestedBudgetMinValueFilter}
                                            maxValue={this.requestedBudgetMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.economicalInfo.requestedBudget} format="0,0.00 $" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={29}
                    title={TextResources.JobOrder.AuthorizedBudgetTitle}
                    sorter={sortNumber((d) => d.economicalInfo.approvedBudget)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.economicalInfo.approvedBudget}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.AuthorizedBudgetTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.approvedBudgetMinValueFilter() !== null &&
                                            this.approvedBudgetMinValueFilter() !== undefined) ||
                                        (this.approvedBudgetMaxValueFilter() !== null &&
                                            this.approvedBudgetMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.AuthorizedBudgetTitle}
                                            minValue={this.approvedBudgetMinValueFilter}
                                            maxValue={this.approvedBudgetMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.economicalInfo.approvedBudget} format="0,0.00 $" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={30}
                    title={TextResources.JobOrder.EstimatedCostTitle}
                    sorter={sortNumber((d) => d.economicalInfo.estimatedCosts)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.economicalInfo.estimatedCosts}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.EstimatedCostTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.estimatedCostsMinValueFilter() !== null &&
                                            this.estimatedCostsMinValueFilter() !== undefined) ||
                                        (this.estimatedCostsMaxValueFilter() !== null &&
                                            this.estimatedCostsMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.EstimatedCostTitle}
                                            minValue={this.estimatedCostsMinValueFilter}
                                            maxValue={this.estimatedCostsMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.economicalInfo.estimatedCosts} format="0,0.00 $" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={41}
                    title={TextResources.JobOrder.ProgressedEstimatedCostTitle}
                    sorter={sortNumber((d) => d.economicalInfo.progressedEstimatedCosts)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.economicalInfo.progressedEstimatedCosts
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ProgressedEstimatedCostTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.progressedEstimatedCostsMinValueFilter() !== null &&
                                            this.progressedEstimatedCostsMinValueFilter() !== undefined) ||
                                        (this.progressedEstimatedCostsMaxValueFilter() !== null &&
                                            this.progressedEstimatedCostsMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ProgressedEstimatedCostTitle}
                                            minValue={this.progressedEstimatedCostsMinValueFilter}
                                            maxValue={this.progressedEstimatedCostsMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.economicalInfo.progressedEstimatedCosts}
                                format="0,0.00 $"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={42}
                    title={TextResources.JobOrder.RemainingEstimatedCostTitle}
                    sorter={sortNumber((d) => d.economicalInfo.remainingEstimatedCosts)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.economicalInfo.remainingEstimatedCosts
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RemainingEstimatedCostTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.remainingEstimatedCostsMinValueFilter() !== null &&
                                            this.remainingEstimatedCostsMinValueFilter() !== undefined) ||
                                        (this.remainingEstimatedCostsMaxValueFilter() !== null &&
                                            this.remainingEstimatedCostsMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RemainingEstimatedCostTitle}
                                            minValue={this.remainingEstimatedCostsMinValueFilter}
                                            maxValue={this.remainingEstimatedCostsMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.economicalInfo.remainingEstimatedCosts}
                                format="0,0.00 $"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={31}
                    title={TextResources.JobOrder.ReestimatedCostWithWorkflowSpeedTitle}
                    tooltip={TextResources.JobOrder.ReestimatedCostWithWorkflowSpeedTooltip}
                    sorter={sortNumber((d) => d.economicalInfo.reestimatedCostsWithWorkflowSpeed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.economicalInfo.reestimatedCostsWithWorkflowSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ReestimatedCostWithWorkflowSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.reestimatedCostsWithWorkflowSpeedMinValueFilter() !== null &&
                                            this.reestimatedCostsWithWorkflowSpeedMinValueFilter() !== undefined) ||
                                        (this.reestimatedCostsWithWorkflowSpeedMaxValueFilter() !== null &&
                                            this.reestimatedCostsWithWorkflowSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ReestimatedCostWithWorkflowSpeedTitle}
                                            minValue={this.reestimatedCostsWithWorkflowSpeedMinValueFilter}
                                            maxValue={this.reestimatedCostsWithWorkflowSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.economicalInfo.reestimatedCostsWithWorkflowSpeed}
                                format="0,0.00 $"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={32}
                    title={TextResources.JobOrder.ReestimatedCostWithJobOrderSpeedTitle}
                    tooltip={TextResources.JobOrder.ReestimatedCostWithJobOrderSpeedTooltip}
                    sorter={sortNumber((d) => d.economicalInfo.reestimatedCostsWithJobOrderSpeed)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.economicalInfo.reestimatedCostsWithJobOrderSpeed
                    }
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.ReestimatedCostWithJobOrderSpeedTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.reestimatedCostsWithJobOrderSpeedMinValueFilter() !== null &&
                                            this.reestimatedCostsWithJobOrderSpeedMinValueFilter() !== undefined) ||
                                        (this.reestimatedCostsWithJobOrderSpeedMaxValueFilter() !== null &&
                                            this.reestimatedCostsWithJobOrderSpeedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.ReestimatedCostWithJobOrderSpeedTitle}
                                            minValue={this.reestimatedCostsWithJobOrderSpeedMinValueFilter}
                                            maxValue={this.reestimatedCostsWithJobOrderSpeedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText
                                value={item.Data.model.economicalInfo.reestimatedCostsWithJobOrderSpeed}
                                format="0,0.00 $"
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={33}
                    title={TextResources.JobOrder.NextMilestoneTitle}
                    sorter={sortDate((d) => d.nextMilestone)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.nextMilestone}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.NextMilestoneTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.nextMilestoneFromFilter() || !!this.nextMilestoneToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.NextMilestoneTitle}
                                            startDate={this.nextMilestoneFromFilter}
                                            endDate={this.nextMilestoneToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.nextMilestone} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={34}
                    title={TextResources.JobOrder.RemainingMilestonesTitle}
                    sorter={sortNumber((d) => d.remainingMilestones)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.remainingMilestones}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RemainingMilestonesTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.remainingMilestonesMinValueFilter() !== null &&
                                            this.remainingMilestonesMinValueFilter() !== undefined) ||
                                        (this.remainingMilestonesMaxValueFilter() !== null &&
                                            this.remainingMilestonesMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RemainingMilestonesTitle}
                                            minValue={this.remainingMilestonesMinValueFilter}
                                            maxValue={this.remainingMilestonesMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <NumericText value={item.Data.model.remainingMilestones} format="0,0" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={35}
                    title={TextResources.JobOrder.AllocationStatusTitle}
                    sorter={sortNumber((d) => d.allocationStatus?.allocationPercentage)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.allocationStatus?.allocationPercentage
                    }
                    className="text-center"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.AllocationStatusTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.allocationStatusMinValueFilter() !== null &&
                                            this.allocationStatusMinValueFilter() !== undefined) ||
                                        (this.allocationStatusMaxValueFilter() !== null &&
                                            this.allocationStatusMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.AllocationStatusTitle}
                                            minValue={this.allocationStatusMinValueFilter}
                                            maxValue={this.allocationStatusMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <AllocationBadge
                                workflowId={item.Data.model.id}
                                jobOrderId={item.Data.model.jobOrder.id}
                                allocationPercentage={item.Data.model.allocationStatus.allocationPercentage}
                                hasNotEstimatedElementsAllocated={
                                    item.Data.model.allocationStatus.hasNotEstimatedElementsAllocated
                                }
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={43}
                    title={TextResources.JobOrder.FirstAllocationDateTitle}
                    sorter={sortDate((d) => d.allocationStatus.firstAllocationDate)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.allocationStatus.firstAllocationDate
                    }
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.FirstAllocationDateTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.firstAllocationDateFromFilter() || !!this.firstAllocationDateToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.FirstAllocationDateTitle}
                                            startDate={this.firstAllocationDateFromFilter}
                                            endDate={this.firstAllocationDateToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.allocationStatus.firstAllocationDate} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={44}
                    title={TextResources.JobOrder.LastAllocationDateTitle}
                    sorter={sortDate((d) => d.allocationStatus.lastAllocationDate)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.allocationStatus.lastAllocationDate
                    }
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.LastAllocationDateTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.lastAllocationDateFromFilter() || !!this.lastAllocationDateToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.LastAllocationDateTitle}
                                            startDate={this.lastAllocationDateFromFilter}
                                            endDate={this.lastAllocationDateToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.allocationStatus.lastAllocationDate} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={47}
                    title={TextResources.JobOrder.AllocationEndDateTitle}
                    tooltip={TextResources.JobOrder.AllocationEndDateTooltip}
                    sorter={sortDate((d) => d.allocationStatus.workEndDate)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) => item.Data.model.allocationStatus.workEndDate}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span title={TextResources.JobOrder.AllocationEndDateTooltip}>
                                    {TextResources.JobOrder.AllocationEndDateTitle}
                                </span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.workEndDateFromFilter() || !!this.workEndDateToFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.JobOrder.AllocationEndDateTooltip}
                                            startDate={this.workEndDateFromFilter}
                                            endDate={this.workEndDateToFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <DateTimeText dateTime={item.Data.model.allocationStatus.workEndDate} format="L" />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={36}
                    title={TextResources.JobOrder.RelatedDocumentsStatusTitle}
                    sorter={sortNumber((d) => d.relatedDocumentsStatus?.relatedDocuments)}
                    aggregateOn={(item: ITableItem<WorkflowAnalysis>) =>
                        item.Data.model.relatedDocumentsStatus?.relatedDocuments
                    }
                    className="text-center"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.JobOrder.RelatedDocumentsStatusTitle}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.relatedDocumentsStatusMinValueFilter() !== null &&
                                            this.relatedDocumentsStatusMinValueFilter() !== undefined) ||
                                        (this.relatedDocumentsStatusMaxValueFilter() !== null &&
                                            this.relatedDocumentsStatusMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.JobOrder.RelatedDocumentsStatusTitle}
                                            minValue={this.relatedDocumentsStatusMinValueFilter}
                                            maxValue={this.relatedDocumentsStatusMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <RelatedDocumentsBadge
                                workflowId={item.Data.model.id}
                                relatedDocuments={item.Data.model.relatedDocumentsStatus.relatedDocuments}
                                workflowMustBeRelatedToCustomerOrders={
                                    item.Data.model.relatedDocumentsStatus.workflowMustBeRelatedToCustomerOrders
                                }
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column id={37} title={TextResources.JobOrder.WorkflowAlertsTitle}>
                    <ColumnHeader>{() => <span>{TextResources.JobOrder.WorkflowAlertsTitle}</span>}</ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => <WorkflowAlerts alerts={item.Data.model.alerts} />}
                    </ColumnBody>
                </Column>
                <Column id={38} title={TextResources.JobOrder.JobOrderAlertsTitle}>
                    <ColumnHeader>{() => <span>{TextResources.JobOrder.JobOrderAlertsTitle}</span>}</ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WorkflowAnalysis>) => (
                            <JobOrderAlerts alerts={item.Data.model.jobOrder.alerts} />
                        )}
                    </ColumnBody>
                </Column>
            </Table>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkflowsTable);
}
