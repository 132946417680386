import * as ko from "knockout";
import { IProLifeDatesUtilities } from "../../interfaces/prolife-sdk/IProLifeUtilitiesLibrary";

export class DatesUtilities implements IProLifeDatesUtilities
{
    public DateToJsonString(date : Date) : string
    {
        if(!date)
            return null;

        /*var dateString : string = JSON.stringify(this.AddGmtTime(date)).replace(/\"/g, "");
        return this.FixJsonDate(dateString);*/
        return JSON.stringify(date);
    }

    public FixJsonDate(date : any) : any
    {
        if(date instanceof Date || date == null || date == undefined)
            return date;
        //return date.replace("Z", "");
        return date;
    }

    public AddGmtTime(date : Date) : Date
    {
        if(date == null || date == undefined)
            return date;

        /*var offsetInMinutesFromUtc : number = -1 * moment(date).zone();
        return moment(date).add("minutes", offsetInMinutesFromUtc).toDate();*/
        return date;
    }

    public SubtractGmtTime(date : Date) : Date
    {
        if(date == null || date == undefined)
            return date;

        /*var offsetInMinutesFromUtc : number = moment(date).zone();
        return moment(date).add("minutes", offsetInMinutesFromUtc).toDate();*/
        return date;
    }

}
