import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { Portlet, PortletBody, PortletHeader } from "../../../../../Components/Portlet";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import {
    IProvisioningService,
    IRequestForQuotation,
    IRequestForQuotationCustomerJobOrder,
    IRequestForQuotationForList,
} from "../../../../ProvisioningService";
import { Prompt, RouteParams } from "../../../../../Components/Routing";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IVatRegisters } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import {
    IDocumentCurrenciesEditorParams,
    IDocumentCurrencyViewModel,
} from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IException, IInvalidDocumentCurrenciesExcpetion } from "../../../../../Core/interfaces/IException";
import { IDocumentsService } from "../../../../../Invoices/DocumentsService";
import { Layout } from "../../../../../Components/Layouts";
import { TextInput } from "../../../../../Components/TextInput";
import { Select } from "../../../../../Components/Select";
import { SuppliersDataSource } from "../../../../../DataSources/SuppliersDataSource";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { CustomersDataSource, ICustomersDataSourceModel } from "../../../../../DataSources/CustomersDataSource";
import { JobOrdersDataSource } from "../../../../../DataSources/JobOrdersDataSource";
import { DropdownList } from "../../../../../Components/DropdownList";
import { RichTextInput } from "../../../../../Components/RichTextInput";
import { ICustomersService } from "../../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { BackButton } from "../../../../../Components/BackButton";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { ProxyDataSource } from "../../../../../DataSources/ProxyDataSource";
import { IIdGeneratorService } from "../../../../../ProlifeSdk/IdGeneratorService";
import { getStatesList } from "../RequestForQuotationStatesList";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

export type GeneralInfo = {
    id: number;
    stateId: number;
    supplierId: number;
    description: string;
    number: string;
};

type RequestForQuotationGeneralInfoProps = RouteParams<{
    requestId: number;
    hasChanges: ko.Observable<boolean>;
    onSave: (generalInfo: GeneralInfo) => Promise<void>;
    forwardRef?: (i: _RequestForQuotationGeneralInfo) => void;
}>;

type CustomerAndJobOrder = {
    Id: number;
    RequestForQuotationId: number;
    CustomerId: ko.Observable<number>;
    JobOrderId: ko.Observable<number>;
    CustomersDataSource: ProxyDataSource<CustomersDataSource, ICustomersDataSourceModel>;
    JobOrdersDataSource: JobOrdersDataSource;
};

export function RequestForQuotationGeneralInfo(props: RequestForQuotationGeneralInfoProps) {
    const C = require("./RequestForQuotationGeneralInfo")
        ._RequestForQuotationGeneralInfo as typeof _RequestForQuotationGeneralInfo;
    return <C {...props} />;
}

export class _RequestForQuotationGeneralInfo extends ComponentBase {
    static defaultProps: Partial<RequestForQuotationGeneralInfoProps> = {};

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService: IIdGeneratorService;

    Number: ko.Observable<string> = ko.observable();
    SupplierId: ko.Observable<number> = ko.observable();
    HasRows: ko.Observable<boolean> = ko.observable(true);
    StateId: ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    DescriptionTrigger: ko.Observable<boolean> = ko.observable(false).extend({ notify: "always" });
    CustomersAndJobOrders: ko.ObservableArray<CustomerAndJobOrder> = ko.observableArray();
    DateOfSending: ko.Observable<Date> = ko.observable();
    SendingResourceName: ko.Observable<string> = ko.observable();
    CreationDate: ko.Observable<Date> = ko.observable();
    CreationResourceName: ko.Observable<string> = ko.observable();
    LastModificationDate: ko.Observable<Date> = ko.observable();
    LastModificationResourceName: ko.Observable<string> = ko.observable();

    StatesList = getStatesList();

    SuppliersDataSource: SuppliersDataSource = new SuppliersDataSource();
    CustomersDataSource: CustomersDataSource = new CustomersDataSource();
    JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();

    constructor(private props: RequestForQuotationGeneralInfoProps) {
        super();
        this.CustomersDataSource.setReturnGroupedData(false);

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    async componentDidMount() {
        await this.load();

        this.disposeOnUnmount(this.SupplierId.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(this.StateId.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(this.Description.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(
            this.CustomersAndJobOrders.subscribe(() => this.props.hasChanges(true), this, "arrayChange")
        );
        this.disposeOnUnmount(this.SupplierId.subscribe(() => this.props.hasChanges(true)));
    }

    public async load() {
        const [requestForQuotation] = await this.provisioningService.GetRequestsForQuotationsByIds([
            this.props.requestId,
        ]);
        const customersAndJobOrders = await this.provisioningService.GetRequestForQuotationCustomersAndJobOrders(
            this.props.requestId
        );
        this.CustomersAndJobOrders(customersAndJobOrders.map((c) => this.createCustomerAndJobOrder(c)));
        this.loadFrom(requestForQuotation);
    }

    private createCustomerAndJobOrder(c: IRequestForQuotationCustomerJobOrder): CustomerAndJobOrder {
        const customerId = ko.observable(c.CustomerId);
        const jobOrderId = ko.observable(c.JobOrderId);

        this.disposeOnUnmount(customerId.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(jobOrderId.subscribe(() => this.props.hasChanges(true)));

        const jobOrdersDataSource = new JobOrdersDataSource();
        jobOrdersDataSource.setViewFilters(true, true, true);
        jobOrdersDataSource.setWorkFilters(true);
        jobOrdersDataSource.setShowClosed(false);

        return {
            Id: c.Id ?? this.idGeneratorService.getNextId(),
            RequestForQuotationId: c.RequestForQuotationId,
            CustomerId: customerId,
            JobOrderId: jobOrderId,
            CustomersDataSource: new ProxyDataSource(this.CustomersDataSource),
            JobOrdersDataSource: jobOrdersDataSource,
        };
    }

    private loadFrom(request: IRequestForQuotationForList) {
        this.Number(request.Number);
        this.SupplierId(request.SupplierId);
        this.StateId(request.StateId);
        this.Description(request.Description);
        this.DateOfSending(request.DateOfSending);
        this.SendingResourceName(request.SendingResourceName);
        this.CreationDate(request.CreationDate);
        this.CreationResourceName(request.CreationResourceName);
        this.LastModificationDate(request.LastModificationDate);
        this.LastModificationResourceName(request.LastModificationResourceName);

        this.DescriptionTrigger.valueHasMutated();
    }

    public async abortRequestEditing(): Promise<void> {
        this.props.navigator.navigateTo("/Provisioning/RequestsForQuotations");
    }

    public async deleteSelectedRequest(): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.DeleteRequestForQuotationMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm
        );

        if (!confirm) return;

        await this.provisioningService.DeleteRequestForQuotation(this.props.requestId);
        this.infoToastService.Success(TextResources.Provisioning.RequestForQuotationDeleted);

        this.props.hasChanges(false);
        this.props.navigator.navigateTo("/Provisioning/RequestsForQuotation");
    }

    public async sendRequestsForQuotations(): Promise<void> {
        if (this.props.hasChanges()) {
            await this.dialogsService.AlertAsync(
                "Attenzione, sono presenti modifiche non salvate. Salvare le modifiche prima di inviare la richiesta d'offerta!",
                "Modifiche non salvate"
            );
            return;
        }

        try {
            const requestForQuotationRegister = this.vatRegisters
                .getVatRegisters()
                .firstOrDefault((r) => r.TipoDocumento == ProlifeSdk.RequestForQuotationTypeId);
            if (!requestForQuotationRegister) {
                this.infoToastService.Error("Non è stato configurato alcun registro per le richieste d'offerta");
                return;
            }

            let savedRequest: IRequestForQuotation = null;
            let aborted = false;
            let documentCurrencies: IDocumentCurrencyViewModel[] = [];

            while (!savedRequest && !aborted) {
                try {
                    savedRequest = await this.provisioningService.SendRequestForQuotation(
                        this.props.requestId,
                        requestForQuotationRegister.IdRegistroIVA,
                        documentCurrencies.map((c) => c.getData())
                    );
                } catch (err) {
                    const ex: IException = err;
                    const currenciesError = ex as IInvalidDocumentCurrenciesExcpetion;

                    if (
                        !currenciesError ||
                        currenciesError.ExceptionCode != 55001 ||
                        !currenciesError.DocumentCurrencies
                    )
                        throw new Error(ex.Message);

                    documentCurrencies = [];
                    for (const documentCurrency of currenciesError.DocumentCurrencies)
                        documentCurrencies.push(
                            this.documentsService.DocumentCurrenciesFactory.createFromModel(documentCurrency)
                        );

                    const params: IDocumentCurrenciesEditorParams = {
                        DocumentCurrencies: documentCurrencies,
                        ReadOnly: false,
                        HeaderMessage: currenciesError.ExceptionMessage,
                    };

                    aborted = !(await this.documentsService.ShowDocumentCurrenciesEditingDialog(params, true));
                }
            }

            if (!savedRequest) return;

            this.loadFrom(savedRequest);
        } catch (e) {
            const ex: IException = e;
            this.infoToastService.Error(ex.Message);
        }
    }

    public createNewSupplier(): void {
        this.customersService.ui.showNewCustomerDialog(null, false, true);
    }

    public addCustomerAndJobOrderRow(): void {
        this.CustomersAndJobOrders.push(
            this.createCustomerAndJobOrder({
                CustomerId: -1,
                JobOrderId: -1,
                RequestForQuotationId: this.props.requestId,
            })
        );
    }

    public async removeCustomerAndJobOrderRow(row: CustomerAndJobOrder): Promise<void> {
        const response = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Provisioning.RemoveRequestForQuotationRow,
            ProlifeSdk.TextResources.Provisioning.Abort,
            ProlifeSdk.TextResources.Provisioning.Confirm
        );

        if (!response) return;

        this.CustomersAndJobOrders.remove(row);
        this.props.hasChanges(true);
    }

    public saveRequestsForQuotations() {
        return this.props.onSave({
            id: this.props.requestId,
            description: this.Description(),
            number: this.Number(),
            stateId: this.StateId(),
            supplierId: this.SupplierId(),
        });
    }

    render() {
        const _rfqgi = this;

        return ComponentUtils.bindTo(
            <div>
                <Layout.Grid rows={["1fr"]} columns={["1fr", "1fr"]} columnGap={"2rem"} rowGap={"2rem"}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet
                            flex={true}
                            noMargin={true}
                            collapsible={false}
                            style={{ height: "100%" }}
                            noOverflow={true}>
                            <Portlet.Header>
                                <BackButton onClick={this.abortRequestEditing.bind(this)} />
                                &nbsp;
                                <Portlet.Header.Default
                                    title={() =>
                                        this.props.requestId <= 0 ? "Nuova richiesta d'offerta" : "Richiesta d'offerta"
                                    }
                                    className="font-red-sunglo bold uppercase"
                                />
                            </Portlet.Header>
                            <Portlet.Actions>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-circle"
                                    onClick={this.deleteSelectedRequest.bind(this)}>
                                    <i className="fa fa-trash-o"></i>&nbsp; Elimina
                                </button>
                                &nbsp;
                                <button
                                    type="button"
                                    class="btn green btn-circle"
                                    onClick={this.sendRequestsForQuotations.bind(this)}>
                                    <i className="fa fa-send-o"></i>&nbsp; Invia
                                </button>
                                &nbsp;
                                <button
                                    type="button"
                                    class="btn btn-primary btn-circle"
                                    data-bind={{ asyncClick: _rfqgi.saveRequestsForQuotations.bind(_rfqgi) }}>
                                    <i className="fa fa-floppy-o"></i>&nbsp; Salva
                                </button>
                                <Prompt
                                    isBlocking={this.props.hasChanges}
                                    message="Attenzione! Sono presenti modifiche non salvate, continuando verranno perse!"
                                    confirm="Perdi modifiche"
                                    cancel="Annulla"
                                />
                            </Portlet.Actions>
                            <Portlet.Body>{() => this.renderRequestForQuotationHeaderForm()}</Portlet.Body>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={1}>
                        <Portlet
                            flex={true}
                            noMargin={true}
                            collapsible={false}
                            style={{ height: "100%" }}
                            noOverflow={true}>
                            <PortletHeader>
                                <Portlet.Header.Default
                                    title="Commesse Correlate"
                                    className="font-red-sunglo bold uppercase"
                                />
                            </PortletHeader>
                            <PortletBody>{() => this.renderCustomersAndJobOrdersTable()}</PortletBody>
                        </Portlet>
                    </Layout.Grid.Cell>
                </Layout.Grid>
            </div>,
            this,
            "_rfqgi"
        );
    }

    private renderRequestForQuotationHeaderForm() {
        const _rfqgi = this;

        return (
            <div class="form">
                <div class="row">
                    <TextInput value={this.Number} label="Numero" readonly className="col-md-3" />
                    <Select.WithLabel
                        containerClassName="col-md-9"
                        value={this.SupplierId}
                        label="Fornitore"
                        dataSource={this.SuppliersDataSource}
                        placeholder="Seleziona il fornitore"
                        allowClear
                        readOnly={this.HasRows}
                        addon={() => (
                            <button
                                type="button"
                                class="btn btn-primary"
                                data-bind={{ click: _rfqgi.createNewSupplier.bind(_rfqgi) }}>
                                <i class="fa fa-plus"></i>
                            </button>
                        )}
                    />
                </div>
                <div class="row">
                    <DropdownList
                        label="Stato"
                        containerClassName="col-md-3"
                        values={this.StatesList}
                        value={this.StateId}
                        textGetter={(i) => i.Label}
                        idGetter={(i) => i.Id}
                        valueGetter={(i) => i.Id.toString()}
                    />
                    <div class="col-md-9 form-group">
                        <ul class="request-for-quotation-info-list">
                            <li>
                                <span>Data d'invio: </span>
                                <span
                                    class="request-for-quotation-info"
                                    data-bind={{
                                        dateTimeText: _rfqgi.DateOfSending,
                                        dateTextNoValue: "La richiesta non è stata inviata",
                                    }}></span>
                                <span data-bind={{ visible: !!_rfqgi.DateOfSending() }}>
                                    {" "}
                                    da{" "}
                                    <span
                                        class="request-for-quotation-info"
                                        data-bind={{ text: _rfqgi.SendingResourceName }}></span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    Data creazione:{" "}
                                    <span
                                        class="request-for-quotation-info"
                                        data-bind={{
                                            dateText: _rfqgi.CreationDate,
                                            dateTextNoValue: "N/A",
                                        }}></span>{" "}
                                    da <span data-bind={{ text: _rfqgi.CreationResourceName }}></span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    Data ultima modifica:{" "}
                                    <span
                                        class="request-for-quotation-info"
                                        data-bind={{
                                            dateText: _rfqgi.LastModificationDate,
                                            dateTextNoValue: "N/A",
                                        }}></span>{" "}
                                    da <span data-bind={{ text: _rfqgi.LastModificationResourceName }}></span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <RichTextInput
                        label="Descrizione"
                        className="col-md-12"
                        value={this.Description}
                        trigger={this.DescriptionTrigger}
                        inline
                        inputStyle={{ maxHeight: "72px", overflow: "auto" }}
                    />
                </div>
            </div>
        );
    }

    private renderCustomersAndJobOrdersTable() {
        const _rfqgi = this;
        let customerAndJobOrder: IDataSourceModel<number, CustomerAndJobOrder>;

        const dataSource = Table.defaultDataSource(this.CustomersAndJobOrders, (cj) => ({
            id: cj.Id,
            title: "",
            model: cj,
        }));

        return (
            <Table compact editable fixedLayout systemScrollable dataSource={dataSource} rowAs="customerAndJobOrder">
                <Column title="Cliente" headerCssClasses="text-left">
                    <ColumnBody>
                        {(item: ITableItem<CustomerAndJobOrder>) => (
                            <Select
                                dataSource={item.Data.model.CustomersDataSource}
                                value={item.Data.model.CustomerId}
                                onSelect={(i) => item.Data.model.JobOrdersDataSource.setCustomerIds(i.id)}
                                placeholder="Seleziona un cliente..."
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Commessa" headerCssClasses="text-left">
                    <ColumnBody>
                        {(item: ITableItem<CustomerAndJobOrder>) => (
                            <Select
                                dataSource={item.Data.model.JobOrdersDataSource}
                                value={item.Data.model.JobOrderId}
                                onSelect={(i) => item.Data.model.CustomerId(i.model.CustomerId)}
                                placeholder="Seleziona una commessa..."
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column headerCssClasses="text-right actions-col" cssClasses="text-right actions-col">
                    <ColumnHeader>
                        <button
                            type="button"
                            class="btn btn-primary btn-xs"
                            title="Aggiungi"
                            data-bind={{
                                click: _rfqgi.addCustomerAndJobOrderRow.bind(_rfqgi),
                                tooltip: { placement: "left" },
                            }}>
                            <i class="fa fa-plus"></i>
                        </button>
                    </ColumnHeader>
                    <ColumnBody>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs"
                            title="Rimuovi"
                            data-bind={{
                                click: _rfqgi.removeCustomerAndJobOrderRow.bind(_rfqgi, customerAndJobOrder.model),
                                tooltip: { placement: "left" },
                            }}>
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </ColumnBody>
                </Column>
            </Table>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RequestForQuotationGeneralInfo);
}
