import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { BindTo } from "../../../../../Components/Bind";
import { JobOrderDetails as JobOrderDetailsComponent } from "../JobOrderDetails";
import { Right } from "../../../../../Core/Authorizations";

export class JobOrderDetailsFactory implements IJobOrderEditorPanelFactory {
    @Right("JobOrders_ViewJobOrderDetailsPopOver")
    private canShow: boolean;

    constructor(serviceLocator : IServiceLocator, public Color : string) {
    }

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderDetails(editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return this.canShow;
    }
}

class JobOrderDetails implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Details);
    TemplateUrl: string;
    TemplateName: string;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    constructor(private editor : IJobOrderEditor, public Color : string) {}

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
    }

    @Delay()
    async load(): Promise<void> {
        
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) {
    }

    OnWorkflowChanged(workflowId:number) {
    }

    OnWorkflowCreated(workflowId:number) {
    }

    OnWorkflowImportedFromTemplate(workflowId:number) {
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    render() {
        let vm : JobOrderDetails;

        return (
            <BindTo viewModel={this} as="vm">
                <div className="flex-container container-fluid" style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, padding: "15px" }}>
                    <JobOrderDetailsComponent jobOrderId={this.editor.JobOrderId} />
                </div>
            </BindTo>
        );
    }
}