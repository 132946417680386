import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IUserCharacter, IUserCharactersSettingsManager } from "../ProlifeSdk/interfaces/users/IUserCharacter";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export type IRolesDataSourceModel = IDataSourceModel<number, IUserCharacter>;

export class RolesDataSource extends BaseDataSource<IRolesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacters: IUserCharactersSettingsManager;

    private resourceType: number | null = null;
    private showResourceDefaultOption = false;

    public setResourceType(resType: number | null) {
        this.resourceType = resType;
    }

    public setShowResourceDefaultOption(value: boolean): void {
        this.showResourceDefaultOption = value;
    }

    getTitle(currentModel: IRolesDataSourceModel): string {
        return TextResources.ResourcesManager.UserCharacters;
    }

    async getData(
        currentModel: IRolesDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IRolesDataSourceModel[]> {
        const roles = this.userCharacters
            .getUserCharacters(this.resourceType)
            .filter((c) => TextFiltersUtilities.contains(c.Description, textFilter))
            .slice(skip, count)
            .map(this.createModelFor, this);

        const rolesModels = this.showResourceDefaultOption ? [this.createDefaultOption()] : [];
        return rolesModels.concat(roles);
    }

    async getById(currentModel: IRolesDataSourceModel, ids: number[]): Promise<IRolesDataSourceModel[]> {
        const roles = ids
            .map(this.userCharacters.getUserCharacterById, this.userCharacters)
            .filter((u) => !!u)
            .map(this.createModelFor, this);

        if (this.showResourceDefaultOption && ids.indexOf(-1) >= 0) roles.push(this.createDefaultOption());

        return roles;
    }

    private createModelFor(userCharacter: IUserCharacter): IRolesDataSourceModel {
        return {
            id: userCharacter.IdUserCharacter,
            title: userCharacter.Description,
            isLeaf: true,
            isGroup: false,
            model: userCharacter,
        };
    }

    private createDefaultOption(): IDataSourceModel<number, IUserCharacter> {
        return {
            id: -1,
            title: TextResources.ResourcesManager.ResourceDefaultOption,
            isGroup: false,
            isLeaf: true,
            model: null,
        };
    }
}
