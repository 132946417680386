import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/06/2016
 * Time: 15:41
 * To change this template use File | Settings | File Templates.
 */
import * as moment from "moment";
import { Moment } from "moment";
import { IPlannerGanttItem, IPlannerGanttLink, IPlannerGanttInterval, IPlannerGanttMarker } from "../../../interfaces/controls/gantt/PlannerGanttInterfaces";

export interface IPlannerGanttMonth {
    name: string;
    days: number;
}

export class PlannerGantt {
    public templateName  = "plannerGantt";
    public templateUrl = 'planning/templates';

    public StartDate : ko.Observable<Date> = ko.observable(moment().startOf('day').toDate());
    public ViewingStartDate : ko.Observable<Date> = ko.observable(moment().startOf('day').toDate()).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 200 } });
    public EndDate : ko.Observable<Date> = ko.observable(moment().add('months', 3).toDate());
    public ViewingEndDate : ko.Observable<Date> = ko.observable(moment().add('months', 3).toDate()).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 200 } });
    public StartDateOffsetFromSunday : ko.Observable<number> = ko.observable(0);

    public AllowDragAndDrop : ko.Observable<boolean> = ko.observable(true);

    public Months : ko.ObservableArray<IPlannerGanttMonth> = ko.observableArray();
    public Dates : ko.ObservableArray<Moment> = ko.observableArray();

    public DaysFromStartDate: ko.Observable<number> = ko.observable();

    public Items : ko.ObservableArray<IPlannerGanttItem> = ko.observableArray();
    public VisibleItems : ko.Computed<number>;
    public AllVisibleItems : ko.Computed<IPlannerGanttItem[]>;
    public SearchFilter : ko.Observable<string> = ko.observable().extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });
    public SearchInChildren : ko.Observable<boolean> = ko.observable(false);

    public DaySizeInPixels : ko.Observable<number> = ko.observable(20);
    public ItemHeight : ko.Observable<number> = ko.observable(30);
    public WeekEndDayColor : ko.Observable<string> = ko.observable('#F2DEDE');

    public BackgroundImage : ko.Observable<string> = ko.observable();

    public AllLinks : ko.Computed<IPlannerGanttLink[]>;

    private m_updateTimeout : number;
    private m_ganttItemsOpeningStatesMap: Map<string, boolean> = new Map();

    constructor() {
        this.StartDate.subscribe(this.computeDates.bind(this));
        this.EndDate.subscribe(this.computeDates.bind(this));

        this.AllLinks = ko.computed(() => {
            let allLinks = [];

            this.Items().forEach((i : IPlannerGanttItem) => {
                allLinks = allLinks.concat(i.Links());

                i.Children().forEach((c : IPlannerGanttItem) => {
                    allLinks = allLinks.concat(c.Links());

                    c.Children().forEach((sc : IPlannerGanttItem) => {
                        allLinks = allLinks.concat(sc.Links());
                    });
                });
            });

            return allLinks;
        });

        this.AllVisibleItems = ko.computed(() => {
            let items: IPlannerGanttItem[] = [];

            this.Items().forEach((i: IPlannerGanttItem) => {
                if (i.Visible()) {
                    items.push(i);
                }
            });

            for (let i = 0; i < items.length; i++) {
                if (items[i].IsExpanded())
                    items = items.concat(items[i].Children().filter((c: IPlannerGanttItem) => { return c.Visible(); }));
            }

            return items;
        });

        this.VisibleItems = ko.computed(() => {
            return this.AllVisibleItems().length;
        });

        let searchInterceptor = ko.computed(() => {
            this.SearchFilter();
            this.Items().forEach((i : IPlannerGanttItem) => {
                i.Search(this.SearchFilter(), this.SearchInChildren());
            });
        });

        let backgroundInterceptor = ko.computed(() => {
            let canvas = document.createElement("canvas");
            canvas.width = this.DaySizeInPixels() * 7;
            canvas.height = this.ItemHeight();
            canvas.style.imageRendering = "crisp-edges";

            let context = canvas.getContext("2d");
            context.translate(0.5, 0.5);
            
            let WeekEndDayColor = this.WeekEndDayColor();

            let dow = [1, 0, 0, 0, 0, 0, 1];
            for(let i = 0; i < 7; i++) {
                context.strokeStyle = "#DDDDDD";
                context.lineWidth = 1;
                
                context.rect((i * this.DaySizeInPixels()), 0, this.DaySizeInPixels(), this.ItemHeight());
                context.stroke();

                context.fillStyle = dow[i] == 1 ? WeekEndDayColor : "#FFFFFF";
                context.fillRect((i * this.DaySizeInPixels()), 0, this.DaySizeInPixels(), this.ItemHeight());
            }

            this.BackgroundImage("url(" + canvas.toDataURL() + ")");
        });

        this.computeDates();
    }

    public init(autoHeightParent  = "") {
        let demo, fixedTable;
        fixedTable = (el) => {
            if(el.length == 0)
                return;

            let $body, $header, $sidebar, $outer, $headerOuter, $sidebarOuter;
            $outer = $(el);
            $body = $(el).find('.fixedTable-body');
            $headerOuter = $(el).find('.fixedTable-header');
            $sidebar = $(el).find('.fixedTable-sidebar table');
            $sidebarOuter = $(el).find('.fixedTable-sidebar');
            $header = $(el).find('.fixedTable-header table');

            $($outer).resize(() => {
                $($headerOuter).css({'width': $(el).width() - $($sidebarOuter).width() - this.DaySizeInPixels(), 'margin-left': $($sidebarOuter).width() });
                $($body).css('width', $(el).width() -  $($sidebarOuter).width() - this.DaySizeInPixels());
                $($body).css('height', $(el).height() - $($headerOuter).height());
                $($sidebarOuter).css('height', $($body).height());

                this.ViewingStartDate(moment(this.StartDate()).add('hours', ($($body).scrollLeft() / this.DaySizeInPixels()) * 24).toDate());
                this.ViewingEndDate(moment(this.ViewingStartDate()).add('hours', ($($body).width() / this.DaySizeInPixels()) * 24).toDate());
                //this.alignProgressTexts(el, $body);
            });

            $($outer).trigger('resize');

            if(autoHeightParent) {
                let $resizeParent = el.closest(autoHeightParent);
                $resizeParent.resize(() => {
                    el.height($resizeParent.innerHeight() - this.ItemHeight());
                });

                $resizeParent.trigger('resize');
            }

            $($body).scroll(() => {
                $($sidebar).css('margin-top', -$($body).scrollTop());
                $($header).css('margin-left', -$($body).scrollLeft());

                this.ViewingStartDate(moment(this.StartDate()).add('hours', ($($body).scrollLeft() / this.DaySizeInPixels()) * 24).toDate());
                this.ViewingEndDate(moment(this.ViewingStartDate()).add('hours', ($($body).width() / this.DaySizeInPixels()) * 24).toDate());
                //this.alignProgressTexts(el, $body);
            });

            console.log("Align started");
            setInterval(this.alignProgressTexts.bind(this, el, $body), 1000);
        };
        if (!autoHeightParent)
            fixedTable($('.fixedTable'));
        else
            fixedTable($(autoHeightParent + ' .fixedTable'));
    }

    private alignProgressTexts(el, $body)
    {
        /*if(this.m_updateTimeout) {
            clearTimeout(this.m_updateTimeout);
        }

        this.m_updateTimeout = setTimeout(() => {*/
            let midScreen = ($body.width() / 2.0) + $body.scrollLeft();

            $(el).find('.progress-text').each(function() {
                    let $progressText = $(this);
                    let $workflow = $progressText.parent();
                    let workflowLeft = $workflow.position().left;
                    let workflowRight = ($workflow.position().left + $workflow.width());

                    if(workflowRight - ($progressText.width() / 2.0) < midScreen) {
                        $progressText.css({ 'position': 'absolute', left: 'auto', right: 0 });
                    } else if(workflowRight - ($progressText.width() / 2.0) > midScreen && workflowLeft + ($progressText.width() / 2.0) < midScreen) {
                        $progressText.css({ 'position': 'absolute', left: Math.round(midScreen - workflowLeft - ($progressText.width() / 2.0)) + 'px', right: 'auto' });
                    } else if(workflowRight - ($progressText.width() / 2.0) > midScreen) {
                        $progressText.css({ 'position': 'absolute', left: 0, right: 'auto' });
                    }
                });
        //}, 1000);
    }

    private computeDates() {
        let startDate = moment(this.StartDate()).startOf('day');
        let endDate = moment(this.EndDate()).startOf('day');

        let lastMonth = startDate.month();
        let lastMonthDays = 0;
        let lastMonthName = startDate.format("MMMM YYYY");

        let daysFromStartDate = 0;

        let months = [];
        let dates : Moment[] = [];
        for(let date = moment(startDate); date.valueOf() <= endDate.valueOf(); date.add('days', 1))
        {
            dates.push(moment(date));

            if(lastMonth != date.month()) {
                months.push({ name: lastMonthName, days: lastMonthDays });

                lastMonthName = date.format("MMMM YYYY");
                lastMonthDays = 0;
                lastMonth = date.month();
            }

            lastMonthDays++;

            if (date.valueOf() < moment().valueOf())
                daysFromStartDate++;
        }

        months.push({ name: lastMonthName, days: lastMonthDays });

        this.Months(months);
        this.Dates(dates);
        this.StartDateOffsetFromSunday(moment(this.StartDate()).day());
        this.DaysFromStartDate(daysFromStartDate);
    }

    public ExpandAll()
    {
        this.Items().forEach(i => i.IsExpanded(true));
    }

    public CollapseAll()
    {
        this.Items().forEach(i => i.IsExpanded(false));
    }

    private getSelectedItems() {
        let selectedItems = [];
        for(let i of this.Items())
        {
            if(i.Selected())
                selectedItems.push(i);

            for(let c of i.Children())
            {
                if(c.Selected())
                    selectedItems.push(c);
            }
        }
        return selectedItems;
    }

    private unselectAll() {
        for(let i of this.Items())
        {
            i.Selected(false);

            for(let c of i.Children())
            {
                c.Selected(false);
            }
        }
    }

    public Select(item : IPlannerGanttItem, event: MouseEvent) {
        let selectedItems = this.getSelectedItems();
        if(selectedItems.length == 1 && selectedItems[0] == item) {
            item.Selected(false);
        } else {
            this.unselectAll();
            item.Selected(true);
        }
    }

    public ScrollToLeft(item : IPlannerGanttItem) {
        let $body = $(".fixedTable-body");
        if(item.MultipleIntervals()) {
            let minDate = moment('2100-01-01');
            let found = null;
            item.Intervals().forEach((w : IPlannerGanttInterval) => {
                if(minDate > moment(w.StartDate())) {
                    minDate = moment(w.StartDate());
                    found = w;
                }
            });
            item.Markers().forEach((w : IPlannerGanttMarker) => {
                if(minDate > moment(w.Date())) {
                    minDate = moment(w.Date());
                    found = w;
                }
            });
            if(found) {
                $body.scrollLeft(Math.max((found.DaysFromStart() - 1) * this.DaySizeInPixels(), 0));
            }
        } else {
            $body.scrollLeft(Math.max((item.DaysFromStart() - 1) * this.DaySizeInPixels(), 0));
        }
    }

    public ScrollToRight(item : IPlannerGanttItem) {
        let $body = $(".fixedTable-body");
        if(item.MultipleIntervals()) {
            let maxDate = moment('2000-01-01');
            let found = null;
            item.Intervals().forEach((w : IPlannerGanttInterval) => {
                if(maxDate < moment(w.StartDate())) {
                    maxDate = moment(w.StartDate());
                    found = w;
                }
            });
            item.Markers().forEach((w : IPlannerGanttMarker) => {
                if(maxDate < moment(w.Date())) {
                    maxDate = moment(w.Date());
                    found = w;
                }
            });
            if(found) {
                $body.scrollLeft(Math.min((found.DaysFromStart() + found.Duration() + 1) * this.DaySizeInPixels() - $body.width() + 10, ($body).find(".background").outerWidth()));
            }
        } else {
            $body.scrollLeft(Math.min((item.DaysFromStart() + item.Duration() + 1) * this.DaySizeInPixels() - $body.width() + 10, ($body).find(".background").outerWidth()));
        }
    }

    public ScrollToTodayMarker(leaveDaysBefore: number): void {
        let $body = $(".fixedTable-body");

        let startDate = moment(this.StartDate());
        let today = moment().startOf('day');
        let days = today.diff(startDate, 'days');
        leaveDaysBefore = Math.min(days, leaveDaysBefore);
        let px = parseInt(($body).find(".today-marker").css('left')) - (leaveDaysBefore * this.DaySizeInPixels());
        $body.scrollLeft(px);
    }

    public HighlightInterval(startDate: Date, endDate: Date): void {
        let $body = $(".fixedTable-body");
        let $highlighter = $body.find(".interval-highlighter");
        let daysFromStart: number = moment(startDate).diff(moment(this.StartDate()), "days");
        let intervalDays: number = moment(endDate).diff(moment(startDate), "days") + 1;
        let highlighterWidth = intervalDays * this.DaySizeInPixels();
        let highlighterStartPosition = daysFromStart * this.DaySizeInPixels();
        $highlighter.css("left", highlighterStartPosition + 'px');
        $highlighter.css("width", highlighterWidth + 'px');
        $body.scrollLeft(highlighterStartPosition - 140);
    }

    public findItem(itemId : string) : IPlannerGanttItem {
        let items = this.Items();
        return this.internalFindItem(items, itemId);
    }

    private internalFindItem(items: IPlannerGanttItem[], itemId : string) : IPlannerGanttItem {
        for(let i = 0; i < items.length; i++) {
            let item : IPlannerGanttItem = items[i];
            if(item.UniqueId() == itemId)
                return item;

            let child : IPlannerGanttItem = this.internalFindItem(item.Children(), itemId);
            if(child)
                return child;
        }

        return undefined;
    }

    public getIndex(item : IPlannerGanttItem) : number {
        return this.AllVisibleItems().indexOf(item);
    }

    public getParent(item : IPlannerGanttItem) : IPlannerGanttItem {
        return this.internalGetParent(item, null, this.Items());
    }

    private internalGetParent(itemToFind : IPlannerGanttItem, parent : IPlannerGanttItem, items : IPlannerGanttItem[]) {
        for(let i = 0; i < items.length; i++) {
            let item : IPlannerGanttItem = items[i];
            if(item === itemToFind)
                return parent;

            let child : IPlannerGanttItem = this.internalGetParent(itemToFind, item, item.Children());
            if(child)
                return child;
        }

        return undefined;
    }

    public getDateFromPoint(x: number, y: number): Date {
        let background = $(".background");

        if (y > background.offset().top + background.height())
            return null;

        let datesRow = $(".dates-row");
        let rowY = datesRow.offset().top + datesRow.height() / 2;

        let elementsAtPoint = document.elementsFromPoint(x, rowY); // N.B. la funzione elementFromPoint forniva risultati errati in alcuni casi
        let validElements = elementsAtPoint.filter((e) => moment.isMoment(ko.dataFor(e)));
        let date: Date = validElements.length > 0 ? ko.dataFor(validElements[0]).toDate() : null;
        
        return date;
    }

    public saveGanttItemOpeningState(itemUniqueId: string, state: boolean): void {
        this.m_ganttItemsOpeningStatesMap.set(itemUniqueId, state);
    }

    public getGanttItemOpeningState(itemUniqueId: string): boolean {
        let state: boolean = this.m_ganttItemsOpeningStatesMap.get(itemUniqueId);
        return !!state;
    }

    public clearItemsOpeningStateCache(): void {
        this.m_ganttItemsOpeningStatesMap.clear();
    }
}