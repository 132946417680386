import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { CartsDataSource, ICartsDataSourceModel } from "../../../DataSources/CartsDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { WorkPoolsEditor } from "./WorkPoolsEditor";
import { IDataSourceListener, IDataSource } from "../../../DataSources/IDataSource";
import { IAllocationsService, IDraggedCartForList } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IManualAllocation, ITeamWorkableHoursAtDateFromWorkingHours, IManualAllocationWorkPoolRole } from "../../interfaces/IManualAllocation";
import moment = require("moment-timezone");

export interface IManualAllocationsEditor {
    ShowTeamName: ko.Observable<boolean>;
    ShowAllocationDate: ko.Observable<boolean>;

    setDate(date: Date, confirmRequiredIfHasChanges?: boolean): Promise<boolean>;
    setCartIdFilter(cartId: number): void;
    addAllocation(cartId?: number): void;
    addNewCartIdInList(cartId: number): void;
    removeNewCartIdFromList(lastCartId: number): void;
    removeAllocation(allocation: IManualAllocationViewModel, removeFromServer?: boolean): void;
    hasChanges(): boolean;
    validate(): boolean;
    save(): Promise<void>;
    canChangeDate(): Promise<boolean>;
}

export interface IManualAllocationViewModel {
    Date: ko.Observable<Date>;
    CartId: ko.Observable<number>;
    CartOrder: ko.Observable<number>;
    HasChanges: ko.Computed<boolean>;

    WorkPoolsEditor: WorkPoolsEditor;
    CartName: string;

    remove(): void;
    getData(): IManualAllocation;
    getAllocationId(): number;
    addWorkPool(): void;
}

interface ITotalHoursPerRole {
    Role: string;
    HoursAmount: number;
}

@DetectClassChanges
export class ManualAllocationsEditor implements IManualAllocationsEditor {
    public TeamName: ko.Observable<string> = ko.observable();
    public Date: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public ManualAllocations: ko.ObservableArray<IManualAllocationViewModel> = ko.observableArray([]);
    public isChanged: ko.Observable<number> = ko.observable(0);
    
    public ShowTeamName: ko.Observable<boolean> = ko.observable(true);
    public ShowAllocationDate: ko.Observable<boolean> = ko.observable(true);

    public TotalHoursPerRole: ko.Computed<ITotalHoursPerRole[]>;

    public AllowedMimeTypes: string[] = ['application/prolife-cart'];

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService!: IAllocationsService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;
    
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    
    private addedCartsIdsList: number[] = [];
    private teamWorkableHours: ko.Observable<ITeamWorkableHoursAtDateFromWorkingHours> = ko.observable();
    private nextCartIndex = 1000;
    private cartId: number;

    private preventLoading = false;

    constructor(private teamId: number) {
        this.Date.subscribe(() => {
            this.load();
        });

        this.TotalHoursPerRole = ko.computed(() => {
            const allocations = this.ManualAllocations();
            const hoursPerRoleMap: { [roleId: number]: ITotalHoursPerRole } = {};

            for (const allocation of allocations) {
                const workPools = allocation.WorkPoolsEditor.WorkPools();

                for (const workPool of workPools) {
                    const roles = workPool.Roles().filter((r => r.IsSelected()));

                    roles.forEach((r) => {
                        if (!hoursPerRoleMap[r.RoleId])
                            hoursPerRoleMap[r.RoleId] = { Role: r.Description, HoursAmount: 0 };
        
                        hoursPerRoleMap[r.RoleId].HoursAmount += workPool.HoursAmount();
                    });
                }
            }

            const result = [];
            for (const roleId in hoursPerRoleMap) {
                result.push(hoursPerRoleMap[roleId]);
            }

            return result;
        });
    }
    
    public async setDate(date: Date, confirmRequiredIfHasChanges = true): Promise<boolean> {
        if (!confirmRequiredIfHasChanges || !this.hasChanges()) {
            
            this.preventLoading = true;
            this.Date(date);
            this.preventLoading = false;

            await this.load();
            return true;
        }
        const result: boolean = await this.canChangeDate();
        if (result) {
            this.preventLoading = true;
            this.Date(date);
            this.preventLoading = false;
            
            await this.load();
        }
        
        return result;
    }
    
    public setCartIdFilter(cartId: number): void {
        this.cartId = cartId;
    }
    
    public hasChanges(): boolean {
        let hasChanges = !!this.isChanged();
        this.ManualAllocations().forEach((a) => {
            hasChanges = hasChanges || a.HasChanges();
        });
        return hasChanges;
    }
    
    public canChangeDate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.hasChanges()) {
                resolve(true);
                return;
            }

            this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessage, ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessageCancel, ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessageConfirm, (confirm: boolean) => {
                resolve(confirm);
            });
        });
    }
    
    public onCartDropped(dataTransfer: DataTransfer): void {
        if (dataTransfer.types.indexOf("application/prolife-cart") < 0)
            return;

        const draggedCart: IDraggedCartForList = JSON.parse(dataTransfer.getData("application/prolife-cart"));

        if (this.userInfo.getCurrentCompanyGuid() !== draggedCart.CompanyGuid) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        this.addAllocation(draggedCart.CartId);
    }

    public async addAllocation(cartId: number = null): Promise<void> {
        if (cartId) {
            this.addNewCartIdInList(cartId);
            const manualAllocations: IManualAllocationViewModel[] = this.ManualAllocations();
            const match = manualAllocations.filter((m) => m.CartId() === cartId);
            if (match.length > 0)
                return;
        }

        const vm = await this.createManualAllocationViewModel(this.createManualAllocationModel(cartId));
        vm.addWorkPool();

        this.ManualAllocations.push(vm);
    }

    public addNewCartIdInList(cartId: number): void {
        if (this.addedCartsIdsList.indexOf(cartId) < 0)
            this.addedCartsIdsList.push(cartId);
    }

    public removeNewCartIdFromList(cartId: number): void {
        let addedAllocationIndex = -1;
        for (let index = 0; index < this.addedCartsIdsList.length; index++) {
            if (this.addedCartsIdsList[index] === cartId) {
                addedAllocationIndex = index;
                break;
            }
        }
        if (addedAllocationIndex > -1)
            this.addedCartsIdsList.splice(addedAllocationIndex, 1);
    }

    public async removeAllocation(allocation: IManualAllocationViewModel): Promise<void> {
        const confirm: boolean = await this.confirmRemoving();
        if (!confirm)
            return;
        
        this.localRemoveAllocation(allocation);
    }

    public validate(): boolean {
        /* this.TotalHoursPerOperationalUnit().forEach((ou) => {
            let message: string = "";

            if (ou.OverWork())
                message += String.format(ProlifeSdk.TextResources.Allocations.OperationalUnitDailyWorkExcededMessage, ou.OperationalUnit()) + "</br>";

            if (!!message) {
            this.infoToastService.Warning(message);
            return false;
        }
        }); */

        const allocations = this.ManualAllocations();
        
        if (allocations.firstOrDefault(a => !a.CartId()) != null) {
            this.infoToastService.Warning(TextResources.Allocations.CartSelectionRequiredOnManualAllocations);
            return false;
        }
            
        let message = "";
        for (const allocation of allocations) {
            if (allocation.WorkPoolsEditor.WorkPools().length === 0) {
                this.infoToastService.Warning(String.format(TextResources.Allocations.WorkPoolsRequiredOnAllocation, allocation.CartName));
                return false;
            }

            if (allocation.WorkPoolsEditor.WorkPools().firstOrDefault(wp => wp.HoursAmount() <= 0))
                message += String.format(TextResources.Allocations.InvalidWorkPoolHoursAmount, allocation.CartName) + "<br/>";
        }

        if (message) {
            this.infoToastService.Warning(message);
            return false;
        }

        message = "";
        for (const allocation of allocations) {
            if (allocation.WorkPoolsEditor.WorkPools().firstOrDefault(wp => !wp.Roles().firstOrDefault(r => r.IsSelected())))
                message += String.format(TextResources.Allocations.InvalidWorkPoolRolesSelection, allocation.CartName) + "<br/>";
        }

        if (message) {
            this.infoToastService.Warning(message);
            return false;
        }

        return true;
    }

    public async save(): Promise<void> {
        if (!this.hasChanges())
            return;

        const allocations = this.ManualAllocations();
        const date = this.Date();

        if (!date) {
            this.infoToastService.Error(TextResources.Allocations.MissingManualAllocationDate);
            return;
        }

        if (moment(date).startOf('day').diff(moment().startOf("day"), "days") > 365 * 2) {
            this.infoToastService.Error(TextResources.Allocations.InvalidManualAllocationDate);
            return;
        }

        const data = allocations.map((m) => m.getData());
        
        try {
            await this.allocationsService.createOrUpdateManualAllocations(data, this.teamId, this.Date());
            this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.SaveManualAllocationsSuccessMessage);
        } catch (e) {
            console.log(e);
        }
    }

    public dispose(): void { }

    private confirmRemoving(): Promise<boolean> {
        return new Promise((resolve) => {
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ConfirmManualAllocationRemovingMessage, ProlifeSdk.TextResources.Allocations.ConfirmManualAllocationRemovingCancel, ProlifeSdk.TextResources.Allocations.ConfirmManualAllocationRemovingConfirm, (confirm: boolean) => resolve(confirm));
        });
    }

    private async load(): Promise<void> {
        if (this.preventLoading)
            return;

        try {
            const teamInfo: ITeamWorkableHoursAtDateFromWorkingHours = await this.allocationsService.getTeamWorkableHoursAtDateFromWorkingHours(this.teamId, this.Date());

            this.TeamName(teamInfo.TeamName);
            this.teamWorkableHours(teamInfo);
            await this.loadAllocationsInfo();

            this.isChanged(0);
        } catch(e) {
            console.log(e);
        }
    }

    private loadAllocationsInfo(): Promise<void> {
        return this.loadManualAllocations();
    }

    private async loadManualAllocations(): Promise<void> {
        try {
            const manualAllocations: IManualAllocation[] = await this.allocationsService.getManualAllocations(this.teamId, this.Date(), this.cartId);
            
            if (manualAllocations.length === 0) {
                this.ManualAllocations([]);
                return;
            }

            const manualAllocationsAwaiters : Promise<ManualAllocation>[] = [];
            manualAllocations.forEach((m: IManualAllocation) => manualAllocationsAwaiters.push(this.createManualAllocationViewModel(m)));

            const manualAllocationsVM = await Promise.all(manualAllocationsAwaiters);
            this.ManualAllocations(manualAllocationsVM);
        } catch(e) {
            console.log(e);
        }
    }

    private createManualAllocationModel(cartId: number = null): IManualAllocation {
        const newAllocation: IManualAllocation = {
            AllocationId: this.allocationsService.GenerateNextId(),
            TeamId: this.teamId,
            Date: this.Date(),
            CartId: cartId,
            CartOrder: this.nextCartIndex++,
            Label: null,
            WorkPools: []
        };

        return newAllocation;
    }

    private async createManualAllocationViewModel(manualAllocation: IManualAllocation): Promise<ManualAllocation> {
        const vm = new ManualAllocation(manualAllocation, this);
        await vm.loadWorkPools();
        return vm;
    }

    private localRemoveAllocation(allocation: IManualAllocationViewModel): void {
        this.ManualAllocations.remove(allocation);
        this.removeNewCartIdFromList(allocation.CartId());
    }
}

class ManualAllocation implements IManualAllocationViewModel, IDataSourceListener {
    @DetectChanges
    public CartId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public CartOrder: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Date: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public Label : ko.Observable<string> = ko.observable();

    public HasChanges: ko.Computed<boolean>;
    public CanAddWorkPool: ko.Computed<boolean>;
    public CanChangeCart: ko.Computed<boolean>;

    public isChanged: ko.Observable<number> = ko.observable(0);

    public WorkPoolsEditor: WorkPoolsEditor;
    public CartsDataSource: CartsDataSource;

    public get CartName(): string {
        return this.m_cartName;
    }

    @LazyImport(nameof<ICartsService>())
    private cartsService: ICartsService;

    private m_cartName: string;
    private m_cartRoles: IManualAllocationWorkPoolRole[] = [];

    constructor(private manualAllocation: IManualAllocation, private editor: IManualAllocationsEditor) {
        this.CartsDataSource = new CartsDataSource();
        this.CartsDataSource.setShowClosedCarts(false);
        this.CartsDataSource.setShowAllocatedCarts(true);

        this.WorkPoolsEditor = new WorkPoolsEditor([]);

        this.CartId(this.manualAllocation.CartId);
        this.Date(this.manualAllocation.Date);
        this.Label(this.manualAllocation.Label);

        let lastCartId: number = this.manualAllocation.CartId;
        this.CartId.subscribe((cartId: number) => {
            if (!this.manualAllocation.AllocationId && !cartId) {
                this.editor.removeNewCartIdFromList(lastCartId);
                lastCartId = cartId;
                return;
            }

            if (!this.manualAllocation.AllocationId && !!cartId) {
                this.editor.addNewCartIdInList(cartId);
                lastCartId = cartId;
            }

            this.loadCartRoles(cartId);
        });

        this.CanAddWorkPool = ko.computed(() => {
            return !!this.CartId();
        });

        this.CanChangeCart = ko.computed(() => {
            return this.WorkPoolsEditor.WorkPools().length === 0;
        });

        this.HasChanges = ko.computed(() => {
            return !!this.isChanged() || this.WorkPoolsEditor.WorkPools().filter(wp => wp.isChanged() > 0).length > 0;
        });

        this.isChanged(0);
    }
    
    public async loadWorkPools(): Promise<void> {
        await this.loadCartRoles(this.manualAllocation.CartId);

        if (!this.manualAllocation.AllocationId || this.manualAllocation.AllocationId <= 0)
            return;

        this.WorkPoolsEditor.loadWorkPools(this.manualAllocation.WorkPools);
    }

    public onItemSelected(sender: IDataSource, model: ICartsDataSourceModel): void {
        const cartTitle = model?.model?.Cart?.Title;
        this.m_cartName = cartTitle;
        this.Label(model?.model?.Cart?.Title);
    }
    
    public onItemDeselected(sender: IDataSource, model: ICartsDataSourceModel): void {
        const cartTitle = model?.model?.Cart?.Title;
        this.m_cartName = cartTitle;

        this.Label(model?.model?.Cart?.Title);
    }
    
    public remove(): void {
        this.editor.removeAllocation(this);
    }

    public getData(): IManualAllocation {
        const newData = Object.assign({}, this.manualAllocation);
        newData.CartId = this.CartId();
        newData.Label = this.Label();
        newData.WorkPools = this.WorkPoolsEditor.WorkPools().map(wp => wp.getData());
        return newData;
    }

    public getAllocationId(): number {
        return this.manualAllocation.AllocationId;
    }

    public dispose(): void {}

    public addWorkPool(): void {
        if (!this.CanAddWorkPool())
            return;

        this.WorkPoolsEditor.addWorkPool(this.m_cartRoles);
    }

    private async loadCartRoles(cartId: number): Promise<void> {
        this.m_cartRoles = !cartId ? [] : (await this.cartsService.getCartRolesInfo(cartId)).map(r => ({ RoleId: r.RoleId, RoleName: r.RoleName }));
        this.WorkPoolsEditor.setRoles(this.m_cartRoles);
    }
}