import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IScheduleService } from "../../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IReminderService } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { Deferred } from "../../../../Core/Deferred";

export class ScheduleRefUiForRemind {
    private remindService : IReminderService;
    private schedulesService : IScheduleService;

    public entityType: string = ProlifeSdk.ScheduleEntityTypeCode;
    public templateUrl : string = "reminder/templates/references";
    public templateName : string = "schedule";

    public schedule : IDropDownList;

    constructor(private serviceLocator) {
        this.remindService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.schedulesService = <IScheduleService>serviceLocator.findService(ProlifeSdk.ScheduleServiceType);
        this.schedule = this.schedulesService.getSchedulesDropDown();
        this.remindService.registerObjReferenceUi(this);
    }

    public initialize(key: IEntityRefKey) : Promise<void> {
        this.schedule.selectedId(key.EntityKeyId);
        return Promise.resolve<void>(undefined);
    }

    public getSelectedEntityKey(): IEntityRefKey{
        return {
            EntityKeyType : this.entityType,
            EntityKeyId : this.schedule.selectedId()
        }
    }

    public getSelectedEntityPathDescription(): Promise<string> {
        var def = new Deferred<string>();

        if(!this.schedule.selectedId())
            return def.resolve(null).promise();

        this.schedule.getSelected(null, (s) => {
            def.resolve(s.text || "N/A");
        });

        return def.promise();
    }
}