import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 11/01/2019
 * Time: 18:39
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { IRecipientCode } from "../ProlifeSdk/interfaces/customer/IRecipientCode";

export class CustomerRecipientCodes {
    init(element: any, valueAccessor: () => ko.Observable<IRecipientCode>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();

        var customersService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var instance = {};

        (<any>$(element)).typeahead({
            items: 100,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : CustomerRecipientCodes.findRecipientCodes.bind(null, instance, customersService, otherBindings),
            display: 'displayKey',
            limit: 50,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var recipientCode: IRecipientCode = item.recipientCode;
                    var isPreferred: boolean = recipientCode.OrganizationalUnitsIds.indexOf(item.organizationalUnitId) > -1;
                    var element = "<div" + (isPreferred ? " style=\"margin-bottom: 10px; border-bottom: 1px solid #eef;\" " : "") + ">";
                    element += recipientCode.RecipientCode + (recipientCode.PEC ? " &ndash; " + recipientCode.PEC : "");
                    element += isPreferred ? "<br/><small><i class=\"fa fa-star-o\" style=\"margin-right: 5px; margin-bottom: 3px;\"></i>" + ProlifeSdk.TextResources.Invoices.PreferredRecipientCodeTitle + "</small>" : "";
                    element += recipientCode.Description ? "<br/><small>" + recipientCode.Description + "</small>" : "";
                    return element + "</div>";
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            const recipientCode : IRecipientCode = item.recipientCode;
            valueAccessor()(recipientCode);
            $(element).trigger("change");
            return item;
        }).on("typeahead:change", () => {
            let actualData = ko.unwrap(valueAccessor());
            if(!actualData) return;
            
            let inputValue = $(element).val();
            if(inputValue != actualData.RecipientCode)
                valueAccessor()(undefined);
        });
    }

    private static findRecipientCodes(_this : any, customersService : ICustomersService, otherBindings: any, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) {
        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        var customerId: number = !otherBindings.customerId ? null : ko.utils.unwrapObservable(otherBindings.customerId);
        var organizationalUnitId: number = !otherBindings.organizationalUnitId ? null : ko.utils.unwrapObservable(otherBindings.organizationalUnitId);

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            customersService.getCustomerRecipientCodes(customerId)
                .then((recipientCodes: IRecipientCode[]) => {

                    for (var i = 0; i < recipientCodes.length; i++) {
                        if (recipientCodes[i].OrganizationalUnitsIds.indexOf(organizationalUnitId) >= 0) {
                            var code = recipientCodes.splice(i, 1);
                            recipientCodes.unshift(code[0]);
                            i++;
                        }
                    }

                    return asyncProcess(CustomerRecipientCodes.transformCodes(recipientCodes, organizationalUnitId));
                });
        }, 500);
    }

    private static transformCodes(recipientCodes: IRecipientCode[], organizationalUnitId: number): any[] {
        return recipientCodes.map((rc) => CustomerRecipientCodes.transformCode(rc, organizationalUnitId));
    }

    private static transformCode(recipientCode: IRecipientCode, organizationalUnitId: number) {
        return {
            id: recipientCode.Id,
            displayKey: recipientCode.RecipientCode,
            recipientCode: recipientCode,
            organizationalUnitId: organizationalUnitId,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.recipientCode.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.recipientCode, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.recipientCode, arguments);
            }
        }
    }
}

ko.bindingHandlers["recipientCodes"] = new CustomerRecipientCodes();