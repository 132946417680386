/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 18/03/13
 * Time: 16.14
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    ITodoListWorkflowForList,
    ITodoListWorkflow,
} from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import {
    IWarehousesService,
    IWarehouseWithStockInfo,
    IWarehouseAddress,
    IWarehouse,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrderForHint, IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

export class InventoryWarehouse {
    @LazyImport(nameof<IWarehousesService>())
    private warehouseService: IWarehousesService;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    Deleted: ko.Observable<boolean> = ko.observable(false);

    Name: ko.Observable<string> = ko.observable();
    Code: ko.Observable<string> = ko.observable();
    CanEditCode: ko.Observable<boolean> = ko.observable();
    EditCode: ko.Observable<boolean> = ko.observable();
    CodePreview: ko.Observable<string> = ko.observable();

    Customer: ko.Observable<string> = ko.observable();
    FullAddress: ko.Observable<string> = ko.observable();

    CustomerId: ko.Observable<string> = ko.observable();
    Address: ko.Observable<string> = ko.observable();
    CAP: ko.Observable<string> = ko.observable();
    City: ko.Observable<string> = ko.observable();
    Province: ko.Observable<string> = ko.observable();
    State: ko.Observable<string> = ko.observable();
    Phone: ko.Observable<string> = ko.observable();
    FAX: ko.Observable<string> = ko.observable();
    EMail: ko.Observable<string> = ko.observable();
    Municipality: ko.Observable<string> = ko.observable();
    IsDefault: ko.Observable<boolean> = ko.observable(false);

    JobOrderId: ko.Observable<number> = ko.observable();
    WorkflowId: ko.Observable<number> = ko.observable();

    WasDefault: ko.Observable<boolean> = ko.observable(false);
    DisableDefaultFlag: ko.Observable<boolean> = ko.observable(false);

    private TotalArticles: ko.Observable<number> = ko.observable(0);
    private TotalStock: ko.Observable<number> = ko.observable(0);

    private lastTimeout: ReturnType<typeof setTimeout>;

    private lastCustomerId: number = null;
    private lastJobOrderId: number = null;

    private validation: IValidator<InventoryWarehouse>;

    constructor(public warehouse: IWarehouseWithStockInfo) {
        this.load(warehouse);
        this.CanEditCode(this.authorizationService.isAuthorized("Warehouse_EditWarehouseCode"));

        this.CustomerId.subscribe(this.handleCustomerChange.bind(this));

        this.JobOrderId.subscribe(this.handleJobOrderChange.bind(this));
        this.WorkflowId.subscribe(this.handleWorkflowChange.bind(this));

        this.validation = this.validationService
            .createValidator<InventoryWarehouse>()
            .isNotNullOrUndefinedOrWhiteSpace((i) => i.Name(), TextResources.Warehouse.WarehouseNameRequired)
            .isNotNullOrUndefined(
                (i) => i.JobOrderId(),
                TextResources.Warehouse.WarehouseJobOrderRequired,
                () => !!this.CustomerId()
            );
    }

    private handleCustomerChange(customerId: string) {
        if (parseInt(customerId) == this.lastCustomerId) return;

        this.JobOrderId(null);
        this.WorkflowId(null);
        this.IsDefault(false);

        this.HandleIsDefaultFlagEnabling();

        this.lastCustomerId = parseInt(customerId);
    }

    private async handleJobOrderChange(jobOrderId: number): Promise<void> {
        if (jobOrderId == this.lastJobOrderId) return;
        this.lastJobOrderId = this.JobOrderId();

        this.WorkflowId(null);
        this.IsDefault(false);
        this.HandleIsDefaultFlagEnabling();

        if ((this.Address() && this.Address().trim().length > 0) || !jobOrderId) return;
        await this.loadAddressFromJobOrder(jobOrderId);
    }

    private handleWorkflowChange(): void {
        this.IsDefault(false);
        this.HandleIsDefaultFlagEnabling();
    }

    private async loadAddressFromJobOrder(jobOrderId: number): Promise<void> {
        try {
            const address: IWarehouseAddress = await this.warehouseService.GetJobOrderAddressToAssignToNewWarehouse(
                jobOrderId
            );

            if (!address) return;

            this.Address(address.Address);
            this.CAP(address.CAP);
            this.City(address.City);
            this.Municipality(address.Municipality);
            this.Province(address.Province);
            this.State(address.State);
        } catch (error) {
            console.error(error);
            this.infoToastService.Error(TextResources.Warehouse.ErrorLoadingJobOrderAddress);
        }
    }

    public load(warehouse?: IWarehouseWithStockInfo) {
        if (!warehouse) return;
        this.warehouse = warehouse;

        this.Deleted(warehouse.Deleted);

        this.Name(warehouse.Name);
        this.Code(warehouse.Code);
        this.Customer(
            !warehouse.CustomerId
                ? ProlifeSdk.TextResources.Warehouse.InternalWarehouse
                : ProlifeSdk.TextResources.Warehouse.LoadingShort
        );
        this.FullAddress(this.buildAddress());

        this.lastCustomerId = warehouse.CustomerId;
        this.CustomerId(warehouse.CustomerId?.toString());

        this.lastJobOrderId = warehouse.JobOrderId;
        this.JobOrderId(warehouse.JobOrderId);

        this.WorkflowId(warehouse.WorkflowId);

        this.Address(warehouse.Address);
        this.CAP(warehouse.CAP);
        this.City(warehouse.City);
        this.Province(warehouse.Province);
        this.State(warehouse.State);
        this.Phone(warehouse.Phone);
        this.FAX(warehouse.FAX);
        this.EMail(warehouse.EMail);
        this.Municipality(warehouse.Municipality);
        this.IsDefault(warehouse.IsDefault || false);
        this.WasDefault(warehouse.IsDefault || false);
        this.TotalArticles(warehouse.TotalArticles || 0);
        this.TotalStock(warehouse.TotalStock || 0);

        setTimeout(() => this.HandleIsDefaultFlagEnabling(), 100);

        if (!warehouse.CustomerId) return;

        this.customersService
            .getCustomerById(warehouse.CustomerId)
            .then((customer: ICustomer) => {
                if (!customer) {
                    this.Customer("N/A");
                    return;
                }
                this.Customer(customer.FormattedContactName);

                if (!warehouse.Id || warehouse.Id <= 0) {
                    this.loadAddressFromJobOrder(warehouse.JobOrderId);
                }
            })
            .catch(() => this.Customer("N/A"));
    }

    private HandleIsDefaultFlagEnabling() {
        this.DisableDefaultFlag(!!this.CustomerId() || !!this.JobOrderId() || !!this.WorkflowId());
    }

    private buildAddress() {
        let address = this.warehouse.Address ? this.warehouse.Address + " - " : "";
        address += this.warehouse.City ? this.warehouse.City + " " : "";
        address += this.warehouse.CAP ? this.warehouse.CAP + " " : "";
        address += this.warehouse.Municipality ? this.warehouse.Municipality + " " : "";
        address += this.warehouse.Province ? "(" + this.warehouse.Province + ")" : "";
        address += this.warehouse.State ? " - " + this.warehouse.State : "";

        return address;
    }

    public getValidation() {
        return this.validation;
    }

    public isChanged() {
        return !Object.identical(this.getData(), this.warehouse);
    }

    public getData(): IWarehouse {
        const warehouse = <IWarehouse>$.extend({}, this.warehouse);

        warehouse.Name = this.Name();
        warehouse.Code = this.Code();
        warehouse.CustomerId = this.CustomerId() ? parseInt(this.CustomerId()) : null;
        warehouse.Address = this.Address();
        warehouse.CAP = this.CAP();
        warehouse.City = this.City();
        warehouse.Province = this.Province();
        warehouse.State = this.State();
        warehouse.Phone = this.Phone();
        warehouse.FAX = this.FAX();
        warehouse.EMail = this.EMail();
        warehouse.Municipality = this.Municipality();
        warehouse.IsDefault = this.IsDefault();
        warehouse.JobOrderId = this.JobOrderId();
        warehouse.WorkflowId = this.WorkflowId();

        return warehouse;
    }

    public async previewCode(): Promise<string> {
        const code = await this.warehouseService.PreviewCode(this.getData());
        this.CodePreview(code);
        return code;
    }

    public SearchJobOrders(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            const customerId: number = parseInt(<any>this.CustomerId());
            this.jobOrderService.hintSearch(query.term, null, isNaN(customerId) ? null : customerId).then((data) => {
                query.callback({
                    results: data.map((jobOrder: IJobOrderForHint) => {
                        return {
                            id: jobOrder.Id,
                            text: jobOrder.Name,
                        };
                    }),
                });
            });
        }, 500);
    }

    public GetJobOrder(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id) && id > 0) {
            this.jobOrderService.get(id).then((jobOrder: IJobOrder) =>
                callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name,
                })
            );
        }
    }

    public SearchWorkflows(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            const jobOrderId: number = parseInt(<any>this.JobOrderId());
            this.todoListService.GetWorkflowsForList(jobOrderId, query.term).then((data) => {
                query.callback({
                    results: data.map((workflow: ITodoListWorkflowForList) => {
                        return {
                            id: workflow.Id,
                            text: workflow.Title,
                        };
                    }),
                });
            });
        }, 500);
    }

    public GetWorkflow(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id) && id > 0) {
            this.todoListService.GetWorkflow(id).then((workflow: ITodoListWorkflow) =>
                callback({
                    id: workflow.Id,
                    text: workflow.Title,
                })
            );
        }
    }
}
