/* eslint-disable no-var */
//ATTENZIONE : Non inserire niente in questo file se non le chiavi altrimenti il parser di generazione potrebbe non funzionare

export const TextResources = {
    Agenda: {
        AgendaApplicationName: "Agenda",
        Agenda: "Agenda",
        AgendaSettingsGroup: "K - Agenda",
        CategoriesSettings: "K.010 Categorie agenda e stati appuntamento",
        UserConfig: "Impostazioni",
        EventCanceledMotivationSettings: "K.020 Motivazioni di annullamento appuntamento",
        CreateAgenda: "Nuova agenda",
        ModifyAgenda: "Modifica agenda",
        CreateFolder: "Crea categoria",
        ModifyFolder: "Modifica categoria",
        CreateAgendaEvent: "Nuovo appuntamento",
        CreateFestivity: "Nuova festività",
        ModifyAgendaEvent: "Modifica appuntamento",
        ModifyFestivity: "Modifica festività",
        AgendasFolders: "Categorie",
        AgendasMenu: "Agende",
        ClosingDays: "Giorni di chiusura",
        SearchPlaceHolder: "Cerca...",
        AgendaEditingDialogTitleForInsert: "Nuova agenda",
        AgendaEditingDialogTitleForEdit: "Modifica agenda",
        AgendaEventEditingDialogTitleForInsert: "Nuovo appuntamento",
        AgendaFestivityEditingDialogTitleForInsert: "Nuova festività",
        AgendaEventEditingDialogTitleForEdit: "Modifica appuntamento",
        AgendaFestivityEditingDialogTitleForEdit: "Modifica festività",
        FolderEditingDialogTitleForInsert: "Nuova categoria",
        FolderEditingDialogTitleForEdit: "Modifica categoria",
        InsertFolderSuccess: "Categoria creata correttamente",
        ModifyFolderSuccess: "Categoria modificata correttamente",
        InsertFailed: "Inserimento non riuscito",
        DeleteFolderConfirmMessage: "Sei sicuro di voler eliminare questa categoria?",
        DeleteAgendaConfirmMessage: "Sei sicuro di voler eliminare questa agenda?",
        DeleteTimeTableMessage: "Sei sicuro di voler eliminare questo orario?",
        DeleteTimeSlotMessage: "Sei sicuro di voler eliminare questa fascia oraria?",
        CancelButton: "Annulla",
        ConfirmButton: "Conferma",
        DeleteFailed: "Cancellazione non riuscita",
        FolderTitleRequired: "Inserire un nome per la categoria",
        GetFoldersError: "Impossibile scaricare la lista categoria",
        CannotAddTimetableVariationMessage:
            "Non è possibile inserire una variazione di orario perché non è stato trovato un orario in vigore. Configurare un orario per continuare.",
        CannotAddTimetableVariationLabel: "Errore inserimento variazione d'orario",
        InsertAgendaWithoutTimetableMessage: "L'agenda non ha nessun orario valido in vigore. Salvare comunque?",
        InsertTimetableWithoutTimeSlotsMessage:
            "L'orario in vigore non ha nessuna fascia oraria configurata. Salvare comunque?",
        AgendaTitleRequired: "Inserire un titolo per l'agenda",
        AgendaFolderRequired: "Inserire la categoria di appartenenza dell'agenda",
        AgendaResourceRequired: "Associare almeno una risorsa all'agenda",
        InsertAgendaSuccess: "Agenda creata correttamente",
        ModifyAgendSuccess: "Agenda modificata correttamente",
        GetAgendasError: "Impossibile scaricare la lista delle agende",
        InvalidTimetable:
            "L'orario in vigore non è valido. Assicurarsi che una data d'inizio sia configurata e che la data di fine, se presente, non sia precedente alla data d'inizio. Controllare che gli orari di fine configurati sulle fasce orarie siano posteriori alle relative ore d'inizio.",
        InvalidTimetableAlertLabel: "Errore configurazione orario",
        TimetablesInEditingAlert:
            "ATTENZIONE!! Uno o più orari risultano ancora in modifica. Applicare le modifiche per proseguire.",
        TimetablesInEditingAlertLabel: "Modifiche orario non confermate",
        CategoriesEditorTitle: "Categorie agenda e stati appuntamento",
        EventCanceledMotivationsEditorTitle: "Motivazioni per cancellazione appuntamento",
        AddClosingDaysError: "Si è verificato un errore durante l'inserimento dei giorni di chiusura.",
        MissingRequiredData: "ATTENZIONE!! Dati mancanti!",
        DeleteClosingDaysError: "Si è verificato un errore, impossibile eliminare i giorni di chiusura.",
        InsertClosingDaysSuccess: "Nuovi giorni di chiusura inseriti correttamente",
        DeleteClosingDaysSuccess: "Giorni di chiusura eliminati correttamente",
        UpdateClosingDaysSuccess: "Giorni di chiusura aggiornati correttamente",
        UpdateClosingDaysFailed: "Si è verificato un errore, impossibile aggiornare i giorni di chiusura",
        InvalidEndDate: "ATTENZIONE! La data di fine non può essere precedente alla data d'inizio",
        DeleteFolderSuccess: "Categoria eliminata",

        FestivitiesAgendas: "Configura festività",
        Agendas: "Configura agende",
        FestivitiesMenu: "Festività",
        GetFestivitiesAgendasError: "Si è verificato un errore, impossibile caricare l'agenda delle festività",
        GetFestivitiesError: "Si è verificato un errore, impossibile caricare le festività",
        DeleteFestivityMessage: "Sei sicuro di voler cancellare questa festività?",
        DeleteFestivityError: "Si è verificato un errore durante la cancellazione della festività.",
        DeleteFestivitySuccess: "Festività cancellata correttamente.",
        DeleteAgendaSuccess: "Agenda eliminata correttamente",
        EventTitleRequired: "Inserire un titolo",
        EventCategoryRequired: "Inserire lo stato dell'appuntamento",
        FestivityInsertSuccess: "Festività creata correttamente",
        FestivityModifySuccess: "Festività modificata correttamente",
        EventDeleteSuccess: "Appuntamento cancellato correttamente",
        DeleteEventMessage: "Sei sicuro di voler cancellare questo appuntamento?",
        InsertFestivityError: "Si è verificato un errore durante l'inserimnento della festività.",
        InsertEventSuccess: "Appuntamento creato correttamente.",
        ModifyEventSuccess: "Appuntamento modificato correttamente.",
        GetFolderError: "Si è verificato un errore, impossibile caricare i dati della categoria di agenda",
        GetAgendaError: "Si è verificato un errore, impossibile caricare i dati dell'agenda",
        GetEventError: "Si è verificato un errore, impossibile caricare i dati dell'appuntamento",
        LoadAgendaDetailsError: "Si è verificato un errore, impossibile caricare i dati dell'agenda",
        ConfirmEventError: "Si è verificato un errore, impossibile confermare l'appuntamento",
        CancelEventError: "Si è verificato un errore, impossibile annullare l'appuntamento",
        MotivationRequired: "Inserire la motivazione per cui l'appuntamento è stato annullato",
        UserConfigDialogTitle: "Configurazioni utente",
        UserConfigurationDialogError: "Si è verificato un errore durante l'apertura della dialog di configurazione",
        LoadConfigurationsError: "Si è verificato un errore durante il caricamento delle configurazioni",
        SaveUserConfigError: "Si è verificato un errore durante il salvataggio della configurazione",
        SaveUserConfigurationSuccess: "Configurazione salvata correttamente",
        LoadAgendaConfigurationError:
            "Si è verificato un errore durante il caricamento delle configurazioni dell'agenda",
        GetAvailableTimeslotsError:
            "Si è verificato un errore, impossibile caricare gli orari disponibili per gli appuntamenti",
        TimeslotForEventRequired: "Selezionare un orario per l'appuntamento",
        GetCustomersError: "Si è verificato un errore, impossibile caricare i clienti",
        LoadEventCustomersError:
            "Si è verificato un errore, impossibile caricare i clienti partecipanti all'appuntamento",
        EventsHintSearchError: "Si è verificato un errore durante la ricerca degli appuntamenti",
        EventsAdvancedFilterDialogTitle: "Filtro avanzato",
        GetWaitingListError: "Si è verificato un errore, impossibile caricare la lista di attesa",
        CloseEditingMode: "Termina modifiche",
        EnableEditingMode: "Modifica",
        RecoveryError: "Si è verificato un errore, impossibile ripristinare l'elemento",
        RecoverySuccess: "Ripristino eseguito correttamente",
        GetFestivitiesAndClosingDaysError:
            "Si è verificato un errore, impossibile caricare le festività e i giorni di chiusura",
        GetAgendaEventsError: "Si è verificato un errore, impossibile caricare gli appuntamenti dell'agenda",
        ConfirmEventOutOfTimetableMessage: "L'appuntamento non rispetta gli orari definiti per l'agenda. Continuare?",
        MoveEventToAgendaError: "Si è verificato un errore, impossibile spostare l'appuntamento nella nuova agenda",
        SetEventCategoryError: "Si è verificato un errore, impossibile impostare la categoria dell'appuntamento",
        MoveCategoryError: "Si è verificato un errore, impossibile riordinare le categorie",
        MoveMotivationError: "Si è verificato un errore, impossibile riordinare le motivazioni",
        RemoveCategoryError: "Si è verificato un errore, impossibile rimuovere la categoria",
        UpdateCategoryError: "Si è verificato un errore, impossibile aggiornare la categoria",
        CreateCategoryError: "Si è verificato un errore, impossibile aggiungere una categoria",
        CannotDeleteCategory:
            "ATTENZIONE!! Non è possibile cancellare questa categoria perché ogni stato logico deve avere almeno una categoria associata. Per cancellare, assegnare lo stato logico ad un altra categoria",
        CannotDeleteCategoryLabel: "Eliminazione categoria",
        CannotDeleteFestivityCategory: "ATTENZIONE!! Questa categoria non può essere rimossa.",
        CreateMotivationError: "Si è verificato un errore, impossibile aggiungere una motivazione",
        UpdateMotivationError: "Si è verificato un errore, impossibile aggiornare la motivazione",
        RemoveMotivationError: "Si è verificato un errore, impossibile rimuovere la motivazione",
        GetServiceRootUrlError:
            "Si è verificato un errore, impossibile impostare la root base dei servizi. La funzionalità di esportazione dati non sarà disponibile.",
        PlannedEventsDialogTitle: "Appuntamenti pianificati",
        PlannedEventsDialogText:
            "Attenzione! Nel periodo scelto ({startDate} - {endDate}) sono pianificati i seguenti appuntamenti, pertanto non è possibile completare l'operazione richiesta.",
        PlannedEventsDialogTextWithTimeslots:
            "Attenzione! Nel periodo scelto ({0} - {1}, fascia oraria: {2}) sono pianificati i seguenti appuntamenti, pertanto non è possibile completare l'operazione richiesta.",
        DeleteTimeSlotError:
            "Si è verificato un errore durante la verifica della presenza di appuntamenti pianificati nella fascia oraria selezionata. Impossibile eliminare.",
        PlannedEventsDialogNullEndDate: "In poi",
        AgendaFuturePlannedEventsText:
            "Attenzione! L'agenda ha i seguenti appuntamenti pianificati per il futuro. Sicuro di volerla cancellare?",
        FolderFuturePlannedEventsText:
            "Attenzione! Le agende di questa categoria hanno ancora eventi futuri pianificati, pertanto non è possibile cancellarla.",
        TimetablesOverlapMessage:
            "Attenzione! Non è possibile creare l'orario perché si accavalla con un orario più vecchio.",
        TimetablesOverlapLabel: "Impossibile creare il nuovo orario",
        GetAgendaPlannedEventsSinceDateError:
            "Si è verificato un errore, impossibile verificare la presenza di appuntamenti pianificati dopo la data d'inizio scelta per il nuovo orario",
        PlannedEventsSinceDateConfirmMessage:
            "Attenzione! Sono presenti appuntamenti pianificati dopo la data d'inizio del nuovo orario. Creando l'orario potrebbero crearsi delle incongruenze. Continuare?",
        AllocableEventError:
            'Attenzione! Se il flag "Occupato" è abilitato deve essere selezionata almeno una risorsa.',
        MaxContemporaryEventsNumberExceeded:
            "ATTENZIONE! Il numero massimo di appuntamenti per l'orario specificato è stato raggiunto, impossibile continuare.",
        CategoryNameCannotBeEmpty: "Il nome di categoria non può essere vuoto",
        NotAvailableSlot: "Non ci sono orari liberi per prendere l'appuntamento nel giorno selezionato.",
        LooseChangesMessage: "ATTENZIONE!! Ci sono modifiche non salvate. Continuare?",
        SaveEventOnPastDateMessage: "ATTENZIONE!! Stai salvando l'appuntamento su una data già passata. Continuare?",
        EditAgendaTimetableAlertLabel: "Modifica orario",
        PlannedEventsOnIntervalAlertMessage:
            "ATTENZIONE! Ci sono appuntamento salvati nel periodo selezionato, impossibile modificare l'orario.",
        EventOutOfTimetableAlertMessage:
            "ATTENZIONE! L'appuntamento è stato posizionato su un giorno o orario non disponibile oppure la durata dell'appuntamento non è compatibile con la durata dello slot orario scelto, impossibile continuare.",
        EventBookingAlertLabel: "Prenotazione appuntamenti",
        CannotChengeAgendaConfigurationMessage:
            "ATTENZIONE! Non è possibile modificare la configurazione dell'agenda perché sono presenti appuntamenti pianificati e potrebbero non essere compatibili con la nuova configurazione.",
        CannotChengeAgendaConfigurationAlert: "Configurazione orario agenda",
        CheckExistingEventsError: "Impossibile verificare la presenza di appuntamenti pianificati per l'agenda.",
        InvalidEventMove:
            "ATTENZIONE! Spostamento non valido. Utilizzare l'interfaccia di modifica per cambiare data e ora dell'evento.",
        ChangeTimetableDurationAlertLabel: "Modifica durata orario",
        ChangeTimetableDurationAlertMessage:
            "ATTENZIONE!! Ci sono appuntamenti all'interno di questo intervallo date: {start} - {end}. Non è possibile selezionare un intervallo più piccolo.",
        SelectTimetable: "Selezionare un orario di partenza",
        SelectTimetableDialogTitle: "Selezione orario di base per variazione",
        TimetableVariationDateLimitError:
            "ATTENZIONE! Le date delle variazioni di orario non possono sforare le date dell'orario scelto come orario di base.",
        TimetableStartDateRequiredError: "Inserire una data d'inizio per l'orario",
        CustomersSelectionRequiredError: "Selezionare almeno un invitato per l'appuntamento.",
        FullTimeslotSelected:
            "ATTENZIONE!! L'orario selezionato non è disponibile perché il numero masimo di appuntamenti è stato raggiunto.",
        EventsTimeOverlapConfirm:
            "L'orario scelto per l'appuntamento si accavalla con l'orario di un altro appuntamento. Continuare?",
        EventsWithInvalidStartEndMessage:
            "Non è stato possibile spostare i seguenti appuntamenti perché l'orario selezionato non è disponibile: {0}",
        InvalidEventsMovementMessage:
            "Non è stato possibile spostare i seguenti appuntamenti perché non sono compatibili con l'agenda di destinazione: {0} Per spostarli è necessario editarli.",
        ConfirmEventMovingFromWaitingListToAgendaWithoutWaitingList:
            "ATTENZIONE!! L'agenda sulla quale è stato spostato l'appuntamento non ha una lista di attesa. Vuoi modificare l'appuntamento per salvarlo su questa agenda?",
        MissingRightsToPlanEvents:
            "Non si dispone dei diritti necessari per la pianificazione di appuntamenti di agenda",
        CannotPlanDueToMissingRightsOrOutOfCompetenceEvent:
            "Non si dispone dei diritti necessari per la pianificazione di appuntamenti di agenda o l'appuntamento non è di propria competenza. Impossibile eseguire l'operazione.",
        ConfirmEventMovingOrResizingWithOverlap:
            "ATTENZIONE!! L'appuntamento modificato si accavalla con altri appuntamenti. Continuare?",
        EventCanceledMotivationTypeRequired: "Almeno uno dei due flag deve essere abilitato",

        ToListViewButton: "Lista",
        ToAgendaViewButton: "Agenda",

        TodayButton: "Oggi",
        MonthButton: "Mese",
        WeekButton: "Settimana",
        DayButton: "Giorno",
        ListButton: "Lista",

        PlannedEventState: "Non confermato",
        ConfirmedEventState: "Confermato",
        WaitingListEventState: "In lista di attesa",
        AbortedEventState: "Annullato",
        LogicalStateNotSet: "Non impostato",
        CategoryDefaultLabel: "Categoria",
        MotivationDefaultLabel: "Motivo",

        AgendaInsertMessage: "L'agenda {0} è stata creata dall'utente {1}",
        AgendaUpdateMessage: "L'agenda {0} è stata modificata dall'utente {1}",
        AgendaDeleteMessage: "L'agenda {0} è stata eliminata dall'utente {1}",

        FolderInsertMessage: "La categoria di agende {0} è stata aggiunta dall'utente {1}",
        FolderUpdateMessage: "La categoria di agende {0} è stata modificata dall'utente {1}",
        FolderDeleteMessage: "La categoria di agende {0} è stata cancellata dall'utente {1}",

        AgendaEventInsertMessage: "L'appuntamento {0} è stato creato dall'utente {1}",
        AgendaEventUpdateMessage: "L'appuntamento {0} è stato modificato dall'utente {1}",
        AgendaEventDeleteMessage: "L'appuntamento {0} è stato cancellato dall'utente {1}",

        AgendaFestivityInsertMessage: "La festività {0} è stata creata dall'utente {1}",
        AgendaFestivityUpdateMessage: "La festività {0} è stata modificata dall'utente {1}",
        AgendaFestivityDeleteMessage: "La festività {0} è stata cancellata dall'utente {1}",

        LogoutMessage:
            "Effettuanto il Logout verrà disabilitata la sincronizzazione su questa agenda! Sei sicuro di voler continuare?",
        LogoutMessageConfirm: "Effettua il Logout",
        LogoutMessageCancel: "Annulla",
        FreeTimeslotsBadge:
            "Agenda configurata con orari (rosso: l'agenda non ha slot orari liberi - arancione: l'agenda ha slot orari liberi - verde: l'agenda ha slot orari liberi solamente oltre il numero di giorni configurato in \"Allerta riempimento agenda\" a partire da oggi)",

        TimetablesValidationError:
            "L'orario configurato dal {0} al {1} non è valido. Assicurarsi che una data d'inizio sia configurata e che la data di fine, se presente, non sia precedente alla data d'inizio. Controllare che gli orari di fine configurati sulle fasce orarie siano posteriori alle relative ore d'inizio.",
        TimetablesVariationValidationError:
            "La variazione di orario configurata dal {0} al {1} non è valida. Assicurarsi che una data d'inizio sia configurata e che la data di fine, se presente, non sia precedente alla data d'inizio. Controllare che gli orari di fine configurati sulle fasce orarie siano posteriori alle relative ore d'inizio.",
        TimetablesValidationErrorTitle: "Errore agenda",
    },
    Allocations: {
        Allocations: "Allocazioni",
        ResourceGroup: "Gruppi",
        TeamManager: "Gestione Team",
        Team: "Team",
        Teams: "Teams",
        Cart: "Carrello",
        ResourceAlreadyInTeam: "La risorsa è già presente nel team!",
        HumanResource: "Risorsa",
        GroupResource: "Gruppo",
        MaterialResource: "Materiale",
        RangeIntersecting:
            "Attenzione! Il range di date selezionato interseca con uno o più dei range già presenti in allocazione",
        RangeNotIntersecting:
            "Attenzione! Il range di date selezionato non interseca con nessun range già presente in allocazione",
        NoStartDateSelected: "Selezionare una data di inizio del periodo",
        NoEndDateSelected: "Selezionare una data di fine del periodo",
        NoAmountSelected: "Inserire una percentuale di allocazione",
        NoHoursSelected: "Inserire le ore di allocazione nel tabellino",
        ConfirmAllocationDelete: "Sei sicuro di voler eliminare l'allocazione?",
        DoNotConfirmAllocationDelete: "Non Eliminare",
        DoConfirmAllocationDelete: "Elimina l'Allocazione",
        ConfirmResourceDelete: "Sei sicuro di voler rimuovere la risorsa dal team?",
        DoNotConfirmResourceDelete: "Non rimuovere",
        DoConfirmResourceDelete: "Rimuovi la Risorsa",
        ConfirmTeamDelete: "Sei sicuro di voler eliminare il team?",
        DoNotConfirmTeamDelete: "Non Eliminare",
        DoConfirmTeamDelete: "Elimina il Team",
        CannotDeleteTeam:
            "Impossibile eliminare il team perchè sono presenti dei carrelli allocati. Per eliminare il team è necessario deallocare i carrelli",
        CartsMenuTitle: "Carrelli",
        CartStatus: "Stato carrello",

        ConfirmCartDeletingMessage: "Confermi di voler cancellare definitivamente il carrello?",
        ConfirmCartDeleting: "Cancella",
        CancelCartDeleting: "Annulla",
        MissingRequiredCartData:
            "I dati del carrello sono incompleti. Titolo e colore sono obbligatori. Nel caso di allocazione manuale, è necessario specificare anche la data.",
        InvalidCartStartDate:
            "La data d'inizio del carrello non può essere impostata oltre ai due anni a partire dalla data odierna.",
        InvalidLinksDelayValue:
            "Il ritardo specificato per i vincoli non è valido. Inserire un valore maggiore o uguale di zero.",
        CanNotAllocateWorkflow: "Non è possibile allocare il flusso perché presenta tasks già allocati",
        StateLabelRequired: "L'etichetta dello stato è obbligatoria",
        CanNotDeleteCartStatus: "Impossibile eliminare l'etichetta perché utilizzata sui carrelli",

        GetCartElementDataError: "Errore durante il download dei dati dal server",
        DeleteCartError: "Si è verificato un errore sul server, impossibile eliminare il carrello",
        SaveCartContentError: "Si è verificato un errore sul server, impossibile salvare il contenuto del carrello",
        DeleteCartContentError: "Si è verificato un errore, impossibile eliminare il contenuto dal carrello",
        InsertCartError: "Si è verificato un errore, impossibile creare il nuovo carrello",
        UpdateCartError: "Si è verificato un errore, impossibile aggiornare il carrello",
        GetCartDataError: "Si è verificato un errore, impossibile inserire il carrello in lista",
        DeallocateCartError: "Impossibile deallocare il carrello a causa di un errore imprevisto",
        AllocationError: "Si è verificato un errore, impossibile allocare il carrello",
        GetCartOpUnitsInfoError:
            "Si è verificato un errore, impossibile scaricare le informazioni sulle unità operative",
        GetAllocatedCartsError: "Si è verificato un errore, impossibile caricare la lista dei carrelli allocati",
        GetCartContentError: "ERRORE: nessun contenuto trovato per il carrello",
        GetTeamProgressError: "Si è verificato un errore, impossibile caricare i progressi del team",
        AddElementToCartError: "Si è verificato un errore, impossibile aggiungere l'elemento al carrello",
        RemoveElementFromCartError: "Si è verificato un errore, impossibile eliminare l'elemento dal carrello",
        AllocateCartWithUnestimatedTasksError: "Si è verificato un errore, impossibile allocare il carrello",
        AllocateUnestimatedTasksMessage: "ATTENZIONE: l'attività selezionata non è stimata. Allocare comunque?",
        AllocateWorkflowWithUnestimatedTasksMessage:
            "ATTENZIONE: il piano di lavoro selezionato contiene attività non stimate. Allocare comunque?",
        AddCartError: "Si è verificato un errore, impossibile inserire il nuovo carrello",
        EditCartError: "Si è verificato un errore, impossibile modificare il carrello",

        EditCartDilaogAddTitle: "Nuovo carrello",
        EditCartDialogEditTitle: "Modifica carrello",
        CartStatusSettingsTitle: "Stati del carrello",

        CanNotAllocateClosedCarts:
            "Non è possibile allocare un carrello chiuso. Prima di allocare, modificare lo stato del carrello",
        CanNotAllocateClosedCartsDialogLabel: "Allocazione carrello chiuso",
        CanNotAllocateEmptyCarts:
            "Non è possibile allocare un carrello senza attività lavorabili. Prima di allocare, inserire almeno una attività lavorabile o un piano contenente attività lavorabili nel carrello. Il carrello è stato comunque creato ed è disponibile nella lista carrelli (se non è presente, provare ad aggiornare la pagina). N.B.: sono considerate attività lavorabili le attività che non sono completate, sospese o eliminate; se il carrello è configurato per ignorare le attività in backlog, queste verranno considerate come NON lavorabili.",
        CanNotAllocateEmptyCartsDialogLabel: "Allocazione carrello vuoto",

        MissedOpUnitsOnTeamAlert:
            "ATTENZIONE: nel team non sono presenti risorse per le seguenti unità operative, necessarie ad effettuare il lavoro del carrello: #opUnits# Continuare?",
        MissedOpUnitsOnTeamAlertOpUnitName: "{0} - dal carrello {1}",
        MissedOpUnitsOnTeamCancel: "Annulla",
        MissedOpUnitsOnTeamConfirm: "Continua",

        MissedRolesOnTeamAlert:
            "ATTENZIONE: nel team non sono presenti risorse per le seguenti mansioni, necessari ad effettuare il lavoro del carrello: {0} Continuare?",
        MissedRolesOnTeamAlertRoleName: "{0} - dal carrello {1}",
        MissedRolesOnTeamCancel: "Annulla",
        MissedRolesOnTeamConfirm: "Continua",

        ManualAllocationType: "Allocazione manuale",
        AutoAllocationType: "Allocazione automatica",

        DiscardCartChangesMessage:
            "ATTENZIONE!! Le modifiche andranno perse. Prima di selezionare un altro carrello fare click su salva.",
        DiscardCartChangesCancel: "Annulla",
        DiscardCartChangesConfirm: "Continua e perdi le modifiche",
        ShowAllocatedElementsOnMenu: "Visualizza elementi già allocati",
        HideAllocatedElementsOnMenu: "Nascondi elementi già allocati",

        AddUnestimatedElementToCartMessage:
            "ATTENZIONE: l'elemento che stai inserendo nel carrello non ha una stima o contiene elementi che non hanno una stima. Vuoi effettuare comunque l'inserimento?",
        AddUnestimatedElementToCartCancel: "Annulla",
        AddUnestimatedElementToCartConfirm: "Inserisci",

        AllocateCartWithUnestimatedTasksMessage:
            "ATTENZIONE: il carrello che stai allocando contiene elementi che non hanno una stima. Vuoi effettuare comunque l'allocazione?",
        AllocateCartWithUnestimatedTasksCancel: "Annulla",
        AllocateCartWithUnestimatedTasksConfirm: "Alloca",
        AutoCartTitle: "Carrello",

        EnableTheoreticalAllocationsView: "Allocazioni teoriche",
        EnableRealAllocationsView: "Allocazioni reali",

        ResourceReallocationDialogTitle: "Allocazione avanzata",
        ResourceReallocationDialogFirstTitlePart: "Risorsa",
        ResourceReallocationDialogSecondTitlePart: "Unità operativa",
        ResourceReallocationDialogThirdTitlePart: "Team",
        PercentageEditingMode: "Percentuale",
        HoursEditingMode: "Ore",
        ServiceOrderEditingMode: "Ore per giorno",
        IntervalEditingMode: "Editing manuale per intervallo",
        GetIntervalsError: "Si è verificato un errore, impossibile scaricare gli intervalli di allocazione",
        AllocationIntervalRequired: "Inserire la data d'inizio e fine allocazione",
        GetServiceOrderForIntervalError: "Si è verificato un errore, impossibile scaricare l'ordine di servizio",
        ResourceRangeIntersectionError:
            "ATTENZIONE! Il range selezionato interseca con uno o più range già allocati per la risorsa sul team",
        StartDateTooSmall: "La data d'inizio allocazione non può essere inferiore alla data di oggi",
        CanNotOverAllocate:
            "ATTENZIONE! La risorsa risulta sovrallocata, impossibile proseguire. La sovrallocazione può essere effettuata solamenete in modalità di editing manuale dei singoli intervalli",

        LooseAllocationWarning: "ATTENZIONE! Le modifiche effettuate verrano perse. Continuare?",
        InvalidRange: "La data di fine non può essere precedente alla data d'inizio",
        InvalidResourceAllocationStartDate:
            "La data d'inizio dell'allocazione della risorsa sul team non può essere impostata oltre ai due anni a partire dalla data odierna.",
        InvalidResourceAllocationEndDate:
            "La data di fine dell'allocazione della risorsa sul team non può essere impostata oltre ai due anni a partire dalla data odierna.",
        OverAllocationsWarning: "ATTENZIONE! La risorsa risulta sovrallocata in almeno un intervallo. Continuare?",
        CancelButton: "Annulla",
        ConfirmButton: "Conferma",
        OpUnitsResourcesAllocatedHoursError:
            "Si è verificato un errore, impossibile scaricare il totale di ore collezionate dal team per unità operativa",

        PendingIntervalApplicationMessage:
            "ATTENZIONE: l'intervallo di date è stato modificato ma non è stato applicato. Continuare?",
        PendingIntervalApplicationConfirm: "Continua",
        PendingIntervalApplicationCancel: "Annulla",

        CalculatingAllocationsLoading: "Calcolo le allocazioni...",
        RefreshCartInfoError: "Si è verificato un errore, impossibile aggiornare i dati del carrello",
        ResetDataMessage: "Riportare i dati allo stato iniziale? (Tutte le operazioni effettuate andranno perse)",
        PendingAllocationApplicationError:
            'Le modifiche non sono state applicate! Cliccare su "Applica" in inserimento valori prima di procedere.',
        PendingAllocationAlert: "ATTENZIONE!!",

        MissingServiceOrderOnResourceAllocationRangeCreation:
            "Non è possibile allocare la risorsa perché il suo ordine di servizio non copre tutto il periodo specificato.",
        SaveTeamsError: "Si è verificato un errore, impossibile salvare",
        DeleteCartSuccess: "Carrello eliminato correttamente",

        ChangesNotSaved: "Sono presenti modifiche non salvate, sei sicuro di voler uscire e perdere le modifiche?",
        ChangesNotSavedCancel: "Non uscire",
        ChangesNotSavedConfirm: "Chiudi e perdi le modifiche",

        CanNotAllocateWorkflowPartiallyAllocatedMessage:
            "Non è possibile allocare il piano di lavoro perché contiene attività già allocate singolarmente.",
        CanNotAllocateWorkflowPartiallyAllocatedLabel: "ATTENZIONE!!",
        CanNotAllocateElementAlreadyAllocated:
            "Non è possibile allocare l'attività/piano di lavoro perché già allocata/o",

        CartCreationError: "Si è verificato un problema durante la creazione del carrello",
        SaveCartSuccess: "Allocazione automatica salvata con successo",
        CartSelectionRequired: "Selezionare un carrello",
        CartSelectionRequiredOnManualAllocations: "Una o più allocazioni non hanno un carrello selezionato",
        WorkPoolsRequiredOnAllocation: "L'allocazione del carrello {0} non ha nessun lavoro configurato",
        EmptyManualAllocationsList: "Nessuna allocazione da salvare",
        InvalidWorkPoolHoursAmount:
            "L'allocazione per il carrello {0} ha un valore di lavoro inferiore o uguale a zero",
        InvalidWorkPoolRolesSelection: "L'allocazione per il carrello {0} non ha nessuna mansione selezionata",
        AllocateExistingCartDiaog: "Seleziona un carrello",

        WrongTeamPositionValue: "Il valore di posizione del team deve essere maggiore di zero",

        ManualAllocationButtonLabel: "Mod. Manuale",
        AutomaticAllocationButtonLabel: "Mod. Gestita",
        CartsListTitle: "Carrelli",

        ManualAllocationsDialog: "Allocazione manuale carrelli",

        ConfirmManualAllocationRemovingMessage: "Sei sicuro di voler eliminare questa allocazione?",
        ConfirmManualAllocationRemovingCancel: "Annulla",
        ConfirmManualAllocationRemovingConfirm: "Conferma",

        InvalidOperationalUnitHoursAmountOnManualAllocation:
            "Le ore inserite non possono superare le ore lavorabili dal team.",
        OperationalUnitDailyWorkExcededMessage: "Il lavoro assegnato all'unità operativa {0} supera le ore lavorabili.",

        ManualAllocationsUnsavedDataMessage: "Ci sono modifiche non salvate. Continuare?",
        ManualAllocationsUnsavedDataMessageCancel: "Annulla",
        ManualAllocationsUnsavedDataMessageConfirm: "Continua senza salvare",
        SaveManualAllocationsSuccessMessage: "Allocazioni manuali slavate con successo.",
        AllocationStartDateRequired: "Inserire la data di allocazione.",

        GenericErrorMessage: "Si è verificato un errore, imprevisto, contattare l'assistenza.",
        ActivitiesDatesInfoDialogTitle: "Dettaglio marcatori",

        CartWorkEndDateDialogTitle: "Modifica data di fine lavoro del carrello",

        CartWithoutWorkEndDatePrompt: "Il carrello {0} non ha una data di fine lavoro impostata.",
        CartWithWorkEndDatePrompt: "Il carrello {0} ha una data di fine lavoro impostata al {1}.",

        UpdateCartWorkDateError:
            "Si è verificato un errore imprevisto, contattare l'assistenza. Ci scusiamo per il disagio.",

        ActivityStartMarkerText: "Inizio attività/piano di lavoro",
        ActivityEndMarkerText: "Fine attività/piano di lavoro",
        CartEndMarkerText: "Fine carrello",
        NotWorkedManualAllocationMarkerText: "Allocazione manuale non lavorata perché il carrello è già terminato",
        MilestoneMarkerText: "Milestone",

        InvalidDropForCompany: "Non è possibile spostare elementi tra due aziende diverse.",
        CartInfoConfigSaved: "Configurazione salvata correttamente.",

        TeamsPopoverTitle: "Elenco teams",

        AllocateCartsOnTeamError:
            "Si è verificato un errore imprevisto durante l'allocazione, contattare l'assistenza. Ci scusiamo per il disagio.",
        AllocationPercentageNotAvailable: "N/D",

        EmptyAllocationsMenuDetailsList: "Nessun lavoro stimato",
        Role: "Mansione",
        CartName: "Carrello",
        CartNameAlt: "Nome Carrello",
        CartTitlePlaceholder: "Inserire il titolo del carrello",
        CartColor: "Colore",
        UseDefaultIgnoreBacklogSetting: "Usa impostazione predefinita",
        IgnoreBacklogActivities: "Escludi attività in Backlog",
        AllocationMode: "Modalità di allocazione",
        ManualAllocationStart: "Inzio allocaz. manuale",
        ReestimatedRemainingWork: "Lav. residuo",
        ReestimatedWork: "Stimato",

        ReestimatedRemainingWorkByJobOrderSpeed: "Stima il lavoro residuo in base alla velocità di commessa",
        ReestimatedRemainingWorkByWorkflowSpeed: "Stima il lavoro residuo in base alla velocità di piano",
        TaskEstimatedWork: "Usa la stima delle attività",

        JobOrderSpeedReestimatedWorkTitle: "Rist. C",
        WorkflowSpeedReestimatedWorkTitle: "Rist. P",

        JobOrderSpeedRemainingWorkTitle: "Residuo C",
        WorkflowSpeedRemainingWorkTitle: "Residuo P",

        IncompleteAllocationMarker:
            "Problemi sulla lavorazione del carrello (incompatibilità team/carrello, carrello vuoto, ecc...)",
        IncompleteAllocationInfoTitle: "Dettagli allocazione incompleta",
        IncompleteAllocationOperationalUnitInfo: "Informazioni allocazione",
        IncompleteAllocationCartHoursInfo: "Monte ore nel carrello",
        IncompleteAllocationPlannedWorkInfo: "Ore pianificate",
        IncompleteAllocationPlannedWorkOnOtherTeamsInfo: "Ore pianificate su altri team",

        ClickForIncompleteAllocationInfo: "Clicca per maggiori informazioni",
        MissingRoleOnCart: "Sul team non ci sono risorse per lavorare questa mansione.",
        NotEnoughtOperationalUnitOnCart:
            "Le risorse sul team non sono sufficienti a svolgere il lavoro allocato. Aggiungere capacità produttiva al team per questa unità operativa.",
        SharedCartNotFinished:
            "Il carrello è stato lavorato anche da altri team, ma non è stato completato. Verificare che i team abbiano risorse sufficienti per lavorare il carrello (il lavoro teorico non viene considerato per il completamento del carrello).",
        CartIsEmpty: "Il carrello è vuoto.",
        CartHasUnestimatedWorkOnly: "Il carrello contiene solamente attività non stimate.",
        CartIsNotWorkableByTheTeam:
            "Il carrello non è lavorabile perché il team non ha le risorse necessarie a svolgere le mansioni previste dalle attività contenute nel carrello.",
        CartWorkedByOtherTeam: "Il carrello è già stato lavorato.",

        CartWithPriority: "Carrello con priorità",
        CartWithLinks: "Carrello con vincoli",
        CartWithPriorityAlt: "Prioritario",
        EmptyTeamCartsAlllocation: "Nessun carrello",

        PlannedWorkIncludeTheoreticalWork: "Questo lavoro contiene lavoro teorico",
        GenericGanttSearch: "Ricerca generica",
        JobOrderGanttSearch: "Ricerca per commessa",
        GenericGanttSearchPlaceholder: "Cerca in team/carrelli...",
        JobOrderGanttSearchPlaceholder: "Cerca commessa...",
        EmptyPlannedResourcesList: "Nessuna risorsa allocata",

        AllocationStartDate: "Data inizio allocazione",
        RealAllocationEndDate: "Data fine allocazione effettiva",
        TheoreticalAllocationEndDate: "Data fine allocazione teorica",
        EmptyWorkflowTeamsList: "Nessun team trovato",

        MissingManualAllocationDate: "Inserire la data dell'allocazione manuale.",
        InvalidManualAllocationDate:
            "La data dell'allocazione manuale non può essere impostata oltre ai due anni a partire dalla data odierna.",
        SharedCart: "Carrello condiviso",

        EffectiveDate: "Inizio:",
        FirstAllocatedDate: "Inizio allocazione:",
        LastAllocatedDate: "Fine:",
        OnGoingActivity: "In lavorazione oggi",

        WorkEndDate: "Data fine lavoro",
        DateSelectionPlaceholder: "Seleziona la data...",
        RemainingWorkAllocationMode: "Mod. calcolo lavoro residuo",
        CartResponsibles: "Responsabili carrello",
        CartLinks: "Vincoli",
        CartCircularDependencyDetectedTitle: "ATTENZIONE!",
        CartCircularDependencyDetected: "Riscontrata una dipendenza circolare tra carrelli, impossibile salvare.",
        LinksDelayLabel: "Ritardo",
        LinksDelayTooltip:
            "Indica il ritardo, in giorni, con cui il carrello deve partire dopo che tutti i suoi vincoli sono stati completati.",

        CartElementColumnTitle: "Piano/attività",
        ActionsColumnTitle: "Azioni",

        GoToJobOrder: "Vai alla commessa",
        EditCartElement: "Apri l'editor delle attività",

        CartContentFilterPlaceholder: "Cerca in titolo attività/piano/commessa",
        SaveAndClose: "Salva e chiudi",
        SaveAndReopen: "Salva",
        WorkflowColumnTitle: "Piano di lavoro",
        JobOrderColumnTitle: "Commessa",
        UserCharacterColumnTitle: "Mansione",
        EstimatedWorkColumnTitle: "Stimato",
        ReestimatedWorkColumnTitle: "Ristimato",
        WorkColumnTitle: "Lavorato",
        ReorderCartContentError:
            "Si è verificato un errore imprevisto durante l'operazione di ordinamento: operazione non riuscita.",
        DeleteCartConfirmMessage: "Sei sicuro di voler eliminare il carrello selezionato?",
        EditCartDialogDropZoneMessage: "Aggiungi al carrello",
        CartWorkInfoLastUpdate: "Dati aggiornati al",
        CartWorkInfoLastUpdateNotAvailable: "Dati aggiornati non disponibili",
        CartWorkInfoHelpTitle:
            "La colonna evidenziata indica quale valore viene utilizzato per calcolare l'allocazione del carrello",
        AllocationStartColumnTitle: "Inizio all.",
        AllocationEndColumnTitle: "Fine all.",
        TheoricalAllocationStartColumnTitle: "Inizio all. teo.",
        TheoricalAllocationEndColumnTitle: "Fine all. teo.",
        ShowCartAdvancedOptions: "Avanzate",
        CartTabTitle: "Carrello",
        AllocationsTabTitle: "Allocazioni",
        AllocateCartOnTeamTooltip: "Alloca il carrello sul questo team.",
        AllocateCartOnTeam: "Alloca carrello",
        ShowAallTeams: "Mostra tutti i team",
        AllocateCartOnTeamMessage: "Allocare il carrello sul team {0}?",
        DeallocateCartFromTeamMessage: "Deallocare il carrello dal team {0}?",

        MissedRolesOnTeamAlertAlt:
            "Nel team {0} non sono presenti risorse per le seguenti mansioni, necessari ad effettuare il lavoro del carrello: {0}.",
        MissingRolesOnTeamsAlertMessage:
            "ATTENZIONE!! Nessuno dei team su cui il carrello è allocato ha le risorse necessarie per svolgere il lavoro che si sta inserendo:{0}Continuare?",

        CartSavedSuccessfully: "Carrello salvato con successo.",
        AutoallocationStarted: "Processo di auto-allocazione avviato....",
        AutoallocationEnded: "Processo di auto-allocazione terminato!",
        AllocatedWorkflows: "Piani allocati: ",
        AutoallocationDialogTitle: "Allocazione piani di lavoro",

        ManageActivityBackloglabel: "Gestione backlog attività",
        ActivityBacklogSystemDefault: "Usa impostazione da default di sistema",
        ActivityIgnoreBacklog: "Escludi le attività in backlog",
        ActivityUseBacklog: "Includi le attività in backlog",
    },
    FileRepository: {
        FileRepository: "Archivio Documentale",
        Cancel: "Annulla",
        Delete: "Elimina",
        InsertFolderName: "Inserisci il nome della cartella da creare",
        InsertFolderToRenameName: "Inserisci il nuovo nome della cartella",
        InsertFileToRenameName: "Inserisci il nuovo nome del file",
        RenameFile: "Rinomina File",
        FileNameCannotContainsChars: "Il nome del file non può contenere il carattere '/'",
        FolderNameCannotContainsChars: "Il nome della cartella non può contenere il carattere '/'",
        RenameFolder: "Rinomina Cartella",
        CreateFolder: "Crea Cartella",
        NewFolder: "Nuova Cartella",
        SureCancelSharing: "Sei sicuro di voler revocare questa condivisione?",
        SureCancelSharingConfirm: "Revoca la Condivisione",
        SureDelete: "Sei sicuro di voler eliminare ",
        SureDeleteFile: "il file '{0}'",
        SureDeleteFiles: "i {0} files selezionati",
        SureDeleteAnd: " e ",
        SureDeleteFolder: "la cartella '{0}' e tutti i files e cartelle contenuti",
        SureDeleteFolders: "le {0} cartelle selezionate e tutti i files e cartelle contenuti",
        SureCancelImageChanges:
            "Cancellando l'operazione si perderanno tutte le modifiche apportate all'immagine!\nSei sicuro di voler continuare?",
        SureCancelImageChangesConfirm: "Continua",
        SureDeleteTag: "Sei sicuro di voler eliminare l'etichetta e rimuoverla da tutti i file?",
        SureDeleteTagConfirm: "Rimuovi etichetta",
        FileNotCopiedForInsufficientSpace:
            "Impossibile effetuare la copia dei file perchè lo spazio disponibile non è sufficiente!",
        FileNotUploadForInsufficientSpace:
            "Impossibile caricare il file {0} perchè non è disponibile spazio sufficiente nell'archivio documentale",
        FileAlreadyExistsAlert:
            "Attenzione: Esiste già un file con lo stesso nome!&lt;br/&gt;Il file verrà caricato come nuova versione di quello esistente, si desidera continuare?",
        CancelUpload: "Annulla Caricamento",
        UploadAsNewVersion: "Carica come Nuova Versione",
        CopySuccess: "La copia è andata a buon fine!",
        UploadSuccess: "Il file {0} è stato caricato correttamente!",
        ErrorDuringFileMoving: "Si è verificato un errore durante lo spostamento dei file:\n{0}",
        ErrorDuringUpload: "Il caricamento del file {0} non è riuscito!",
        ErrorDuringFileCopy: "Si è verificato un errore durante la copia dei file:\n{0}",
        ErrorDuringFileRename: "Si è verificato un errore durante la rinomina del file:\n{0}",
        Name: "Nome",
        Contains: "Contiene",
        //Type : "Tipo",
        ModifiedFrom: "Modificato dal",
        ModifiedTo: "Modificato entro il",
        Extension: "Estensione",
        Shared: "Condiviso",
        SharedYes: "Si",
        SharedYesDisplay: "E' condiviso",
        Tag: "Etichetta",
        Loading: "Caricamento in corso...",
        CreateTag: "Crea l'etichetta {0}...",
        FileNameContains: "Il nome del file contiene",
        AtLeastOneShare: "almeno una condivisione",
        //Shared: "Condiviso",
        Type: "Tipologia",
        //Extension: "Estensione",
        FileContains: "Il file contiene",
        ModifiedSince: "Modificato dal",
        ModifiedWithin: "Modificato entro il",
        ResizeImage: "Ridimensiona Immagine",
        ModifyImage: "Modifica Immagine",
        ProLifeDocument: "ProLife%20-%20Documento",
        ProLifeDocuments: "ProLife%20-%20Documenti",
        UploadOverwriteMsg:
            "Nella cartella di destinazione esiste già un file con questo nome. Si desidera creare una nuova versione del file?",
        UploadOverwriteMsgCancel: "Annulla caricamento",
        UploadOverwriteMsgConfirm: "Crea nuova versione",
        UploadError:
            "Si è verificato un errore durante l'upload del file {0}. Verificare di non aver sforato la quota disco.",
        PleaseProvideAFileName: "Inserire un nome file prima di continuare",
        FileNotFound: "Il file non esiste nell'archivio documentale.",
    },
    Assets: {
        Assets: "Cespiti",
    },
    Blog: {
        Message: "Messaggio",
        FilterEvents: "Filtra eventi",
        HiPriority: "Alta",
        ConfirmUnload: "Le modifiche non salvate andranno perse. Continuare?",
        Work: "Lavoro",
        Purchase: "Acquisti",
        Mail: "Mail",
        Call: "Chiamata",
        Meeting: "Riunione",
        Decision: "Decisione",
        Blog: "Appunti di lavoro",
        Text: "Testo",
        Integer: "Intero",
        Decimal: "Decimale",
        Date: "Data",
        TrueFalse: "Vero/Falso",
        True: "Vero",
        False: "Falso",
        ReferencedHoursChangedMsg: "Non è possibile apportare modifiche a ore già importate in documenti",
        DuplicatedResource: "Risorsa duplicata su più righe",
        RequiredObject: "Oggetto obbligatorio",
        WorkInputTitle: "Inserimento ore",
        ToDoListTitle: "Aggiornamento dei flussi di lavoro",
        ToDo: "Da fare",
        Completed: "Completato",
        SystemInputTitle: "Sistema",
        SelectResource: "Selezionare la risorsa per tutti gli intervalli",
        InvalidInterval: "Intervallo orario non valido",
        SelectType: "Selezionare la tipologia oraria per tutte le righe",
        SelectRole: "Selezionare la mansione per tutte le righe",
        InsertHours: "Inserire le ore lavorate per tutte le righe",
        PhoneCallTitle: "Chiamata telefonica",
        MilestoneTitle: "Decisione",
        MeetingTitle: "Riunione",
        MaterialsTitle: "Acquisti",
        ExpensesTitle: "Note spese",
        Save: "Salva",
        Cancel: "Annulla",
        SALReferenceWarning: "ATTENZIONE: L'acquisto è referenziato da un SAL. Proseguire comunque?",
        UnsavedCloseWarning: "Sono stati fatti dei cambiamenti. Chiudere senza salvare?",
        UnsavedCloseCancel: "Continua modifiche",
        UnsavedCloseConfirm: "Chiudi senza salvare",
        Errors: "Attenzione: sono stati riscontrati i seguenti errori: ",
        Saving: "Salvataggio in corso",
        EmailTitle: "E-Mail",
        LoadMissingObjectWarning: "Per poter caricare e-mail è necessario selezionare la commessa di riferimento",
        EmailDialogTitle: "Posta Elettronica",
        InvalidDrop:
            "Il drag & drop da Outlook non è supportato.\nPer caricare una mail da Outlook è necessario prima salvarla in formato TXT e poi effettuare il drag & drop del file salvato.",
        Jobs: "Commesse",
        NoJobs: "Nessuna commessa",
        MailEventName: "E-Mail",
        MailSubject: "ProLife - Tracciamento e-mail in data {0}",
        MailSubjectInBody: "Oggetto%20mail:%20{0}%0A",
        Senders: "Mittenti",
        Recipients: "Destinatari",
        CCs: "In copia",
        BCCs: "In copia nascosti",
        Description: "{0} n° {1} del {2}",
        CallTitle: "Chiamata",
        CallSubject: "ProLife - Chiamata telefonica in data {0}",
        CallTime: "Durata:%20{0} (min)%0A",
        Participants: "Partecipanti",
        WorkTitle: "Lavoro",
        WorkSubject: "ProLife - Ore lavorate in data {0}",
        DeleteEventMsg:
            "Sei sicuro di voler eliminare l'evento? Questa operazione potrebbe rendere inconsistenti i dati sensibili della commessa qualora le ore di lavoro fossero state importate in qualche documento.",
        DeleteEventCancel: "Non eliminare",
        DeleteEventConfirm: "Elimina",
        WorkBody: "%0AAlla%20data%20in%20oggetto%20sono%20state%20registrate%20le%20seguenti%20ore%20lavorative:%0A%0A",
        WorkBodyHourLine:
            "{0}%20nella%20mansione%20di%20{1}%20dalle%20{2}%20alle%20{3},%20Ore%20di%20pausa:%20{4},%20Fatturabile:{5},%20Fatturato:%20{6})%0A",
        Yes: "Si",
        No: "No",
        SystemBlogTitle: "Sistema",
        SystemBlogBody: "Modulo%20di%20origine:%20{0}%0A",
        SystemBlogBodyHyperlink: "Collegamento:%20{0}%0A",
        MilestoneBlogTitle: "Decisione",
        MeetingBlogTitle: "Riunione",
        MeetingBlogSubject: "ProLife - Riunione in data {0}",
        MeetingBlogBody: "Durata:%20{0} (min)%0A",
        //MaterialsTitle: "Acquisti",
        MaterialsSubject: "ProLife - Acquisto in data {0}",
        DeleteMaterialsMsg:
            "Sei sicuro di voler eliminare l'evento? Questa operazione potrebbe rendere inconsistenti i dati sensibili della commessa qualora gli acquisti fossero stati referenziati in qualche documento.",
        DeleteMaterialsCancel: "Cancel",
        DeleteMaterialsConfirm: "Elimina",
        MaterialsBodyLine1: "Prezzo%20di%20acquisto:%20{0}%0A",
        MaterialsBodyLine2: "Prezzo%20di%20vendita:%20{0}%0A",
        MaterialsBodyLine3: "Fatturabile:%20{0}%0A",
        MaterialsBodyLine4: "Fatturato:%20{0}%0A",
        //Purchase: "Acquisto",
        Purchases: "Acquisti",
        NewPurchase: "Nuovo Acquisto",
        HandlePurchase: "Movimenta Acquisto",
        PrintSelectionMsg: "Si desidera stampare il dettaglio dei singoli eventi?",
        PrintSelectionList: "Stampa elenco",
        PrintSelectionDetails: "Stampa dettagli",
        NoEventSelected: "Selezionare almeno un evento",
        LockMsg: "Sei sicuro di voler cambiare lo stato di blocco degli eventi selezionati?",
        LockMsgCancel: "Non cambiare",
        LockMsgConfirm: "Cambia",
        LoadingEvents: "Caricamento eventi in corso...",
        WorkedHours: "Ore lavorate",
        UnknownRole: "<Mansione sconosciuta>",
        PleaseSelectAJobOrder: "Seleziona almeno una commessa prima di creare l'evento",
        Events: "Appunti di lavoro",
        Event: "Appunto di lavoro",

        EntityFieldNameEventTitle: "Titolo",
        EntityFieldNameEventDate: "Data evento",

        OnOrMoreTasksAreNotReady:
            "Una o più attività hanno alcuni prerequisiti non completati e non sono pronti, sei sicuro di voler continuare?",
        DoNotContinue: "Annulla",
        Continue: "Continua",

        PurchasesExport: "Esportazione Acquisti",

        BlogBookletPageTitle: "Appunti",
        BlogBookletModifyTitle: "Modifica",

        AdvancedJobOrderFilterTitle: "Filtro Avanzato",
        OtherFilter: "Filtri generali",

        PurchasesLockedTasksError:
            "Non è possibile inserire o modificare gli acquisti perché le seguenti attività risultano bloccate o il piano in cui sono contenute ha un esito che impedisce l'inserimento o la modifica di acquisti:<br/>",
        PurchasesLockedTasksErrorRow: "{0} ({1})<br/>",
        PurchasesLockedTasksErrorTitle: "Inserimento acquisti",

        GoToBlog: "Vai agli appunti di lavoro",

        BlogEventSubject: "Oggetto dell'evento",
        BlogEventTitlePlaceholder: "Oggetto dell'evento",
        BlogEventTags: "Etichette",
        BlogEventTagsPlaceholder: "Etichette...",
        BlogEventauthorizedUsers: "Utenti autorizzati",
        BlogEventauthorizedUsersPlaceholder: "Utenti autorizzati...",
        BlogEventPriority: "Priorità",
        BlogEventStatus: "Stato",
        BlogEventOnQuality: "In qualità",
        BlogEventIsAdministrative: "Amministrazione",
        BlogEventIsCommercial: "Commerciale",
        BlogEventIsTechnical: "Tecnico",
        BlogEventSignature: "Firma",

        MailEventsGeneratorTitle: "Carica e-mail",
        MailsMenuTitle: "Mail da gestire",
        MailEventsGeneratorSelectedAvtivitiesTitle: "Attività selezionate",
        MailBodyPlaceholder: "Contenuto della mail",
        MailDateLabel: "Data/ora",
        MailDatePlaceholder: "Data/ora",
        MailSubjectLabel: "Oggetto della mail",
        MailSubjectPlaceholder: "Oggetto della mail",
        MailSenders: "Da",
        MailSendersPlaceholder: "Mittenti...",
        MailRecipients: "A",
        MailRecipientsPlaceholder: "Destinatari...",
        MailCcRecipients: "Cc",
        MailCcRecipientsPlaceholder: "Destinatari...",
        MailBccRecipients: "Bcc",
        MailBccRecipientsPlaceholder: "Destinatari...",

        DeleteMailMsg: "Sei sicuro di voler cancellare la mail?",
        AbortChangesAndContinue: "ATTENZIONE!! Eventuali modifiche verranno perse. Continuare?",
        LockMailFields:
            "Selezione multipla delle mail attivata. Clicca per passare alla selezione singola e sbloccare i campi di inserimento relativi alla mail.",
        UnlockMailFields:
            "Selezione singola delle mail attivata. Clicca per passare alla selezione multipla e bloccare i campi di inserimento relativi alla mail.",

        MultipleMails: "Valori multipli",
        MailsToManageTooltip: "Hai {0} mail da gestire",

        WorkflowStatusAfterSaveDialogHelpText:
            "A questo evento degli appunti sono stati associati alcuni eventi dei flussi di lavoro di commessa. Prima di proseguire con il salvataggio è possibile modificare lo stato degli eventi del flusso associati.",
        ActivityWithAmountProgressMode: "Attività ad avanzamento in base alla qauntità. Impossibile editare lo stato.",

        MissingBlogEventSubject: "Inserire il titolo dell'evento.",
        MissingBlogEventMail: "Inserire la data dell'evento.",
        MissingSelectedMails: "Selezionare almeno una mail.",
        MissingSelectedJobOrders: "Selezionare almeno una commessa.",
    },
    Contracts: {
        Contracts: "Contratti e Ordini",
    },
    Core: {
        All: "Tutto",
        Loading: "Caricamento in corso...",
        Close: "Chiudi",
        Save: "Salva",
        DayLabel: "Giorno",
        MonthLabel: "Mese",
        YearLabel: "Anno",
        Year2DigitsLabel: "Anno (2 cifre)",
        ConfirmEditEntityCodeConfiguration:
            "Attenzione! La modifica delle impostazione dei codici può comportare problemi di duplicazione, assicurarsi che le modifiche non creino duplicati prima di continuare!",
        ConfirmEditEntityCodeConfigurationOk: "Continua",
        ConfirmEditEntityCodeConfigurationCancel: "Annulla",
        ErrorSavingEntityCodeGeneratorConfig: "Si è verificato un errore durante il salvataggio della configurazione",
        CompanyIBANs: "IBAN Azienda",
        CompanyABIs: "ABI Azienda",
        CompanyCABNs: "CAB Azienda",
        Download: "Scarica",
        MailTemplatesSettingsEditor: "Editor dei template e-mail",
        MailTemplateSettingsEditorSender: "Mittente: ",
        MailTemplateNameLabel: "Nome template",
        MailTemplateNamePlaceholder: "Nome template",
        MailTemplateBlogEventTitleLabel: "Titolo evento in appunti di lavoro",
        MailTemplateBlogEventTitlePlaceholder: "Titolo evento in appunti di lavoro",
        MailTemplateObjectLabel: "Oggetto",
        MailTemplateObjectPlaceholder: "Oggetto",
        MailTemplateBodyLabel: "Corpo",
        MailTemplateBodyPlaceholder: "Corpo",
        MailTemplatesListTitle: "Lista template",
        DocumentMailTemplatesPlaceholdersListTitle: "Placeholder disponibili",
        MailTemplatesListEmptyMessage: "Nessun template disponibile",
        FullContactName: "Nome completo contatto",
        DocumentProtocol: "Protocollo del documento",
        DocumentNumber: "Numero del documento",
        PlaceholderCopiedToClipboard: "Placeholder {0} copiato negli appunti",
        DocumentDate: "Data del documento",
        DocumentLabel: "Etichetta del documento",
        MailTemplatePendingChanges: "ATTENZIONE! Ci sono modifiche non salvate. Continuare perdendo le modifiche?",
        MailTemplateNoSmtpConfigured: "Nessun account smtp configurato",
        DeleteMailTemplateAlert: "Sei sicuro di voler cancellare il template?",
        MissingMailTemplateName: "Inserire il nome del template",
        MissingMailTemplateSubject: "Inserire l'oggetto del template",
        MissingMailTemplateBlogEventTitle: "Inserire il titolo dell'evento in appunti di lavoro",
        MissingMailTemplateBody: "Inserire il contenuto del template",

        MailTemplateSaved: "Template salvato",
    },
    Customers: {
        CustomerURL: "Rubrica/{0}",
        AddressBookURL: "Rubrica",
        AddressBook: "Rubrica",
        AddressBookMetadata: "Metadati Rubrica",
        NewContact: "Nuovo contatto",
        Contacts: "Contatti",
        UONotAccessible: "UO non accessibile",
        Text: "Testo",
        Labels: "Etichette",
        Resources: "Risorse",
        ResourceNotAccessible: "Risorsa non accessibile",
        UONoName: "U.O. senza nome",
        NewUO: "Nuova U.O.",
        NoABI: "NO ABI",
        NoCAB: "NO CAB",
        Others: "Altri",
        InvalidPECFormat: "Formato non valido per il campo PEC",
        NoCompanyName: "Ragione sociale assente",
        NoName: "Nome assente",
        NoSurname: "Cognome assente",
        NoVAT: "Partita IVA assente",
        InvalidVAT: "Partita IVA non corretta",
        NoSSN: "Codice fiscale assente",
        InvalidSSN: "Codice fiscale non corretto",
        InvalidAddress: "Indirizzo non completo",
        Phone: "telefono",
        Fax: "fax",
        Email: "e-mail",
        Pec: "PEC",
        Web: "web",
        New: "nuovo",
        InvalidData: "Possibile dato non valido nell'elemento {0} (Gruppo: {1})",
        CancelMsg:
            "Sono presenti delle modifiche non salvate, continuando le modifiche effettuate verranno perse. Sei sicuro di voler continuare?",
        CancelNo: "Continua a modificare il contatto",
        CancelConfirm: "Annulla le modifiche effettuate",
        SaveMsg:
            "Attenzione! Prima di procedere si consiglia di verificare:<br> - {0}<br>Si desidera continuare con il salvataggio del contatto?",
        SaveNo: "Ritorna alla scheda contatto",
        SaveConfirm: "Salva comunque",
        Saving: "Salvataggio in corso...",
        CustomerSaved: "Contatto salvato con successo!",
        SaveError: "Si è verificato un errore durante il salvataggio del contatto!",
        OperationDone: "Operazione effettuata",
        ContactBlocked: "Contatto segnalato",
        ContactUnblocked: "Rimossa segnalazione contatto",
        CustomerDeleteMsg: "Sei sicuro di voler eliminare il contatto?",
        CustomerDeleteCancel: "Torna alla scheda contatto",
        CustomerDeleteConfirm: "Elimina il contatto",
        CustomerDeleted: "Contatto eliminato con successo!",
        CustomerDeleteError: "Si è verificato un errore durante l'eliminazione del contatto!",
        Unblocked: "Non Segnalato",
        BlockContact: "Segnala contatto",
        ReportModelsFolder: "Modelli Report",
        ContactsExport: "Esporta Contatti",
        ExportColumnInfo: "Colonne Disponibili",

        PositiveValueRequired: "Inserire un valore maggiore o uguale a zero",
        TrustValueError: "Il valore del fido comporta un saldo negativo, impossibile impostare il nuovo valore",
        TrustStartDateRequired: "La data d'inizio validità del fido è obbilgatoria",
        ConfirmNegativeTrustBalanceMessage: "ATTENZIONE!! Il saldo del fido andrà in negativo. Continuare?",
        ConfirmTrustPeriodChangesMessage:
            "Confermando le modifiche i seguenti documenti non potranno essere associati a nessun fido perché non rientrano nei nuovi intervalli di date specificati. Continuare?",
        CancelButton: "Annulla",
        ConfirmButton: "Conferma",
        CanNotEditTrustPeriod:
            "ATTENZIONE!! Non è possibile modificare il periodo di validità del fido perché sono presenti documenti che rimarrebbero al di fuori del periodo impostato",
        TrustsGeneralSettingsLabel: "D.020 Impostazioni fidi",
        TrustUpdateDelaysSettingsLabel: "D.030 Ritardi aggiornamento saldo del fido per tipologia di pagamento",
        CustomersTrust: "Fido clienti",
        TrustsSettingsTitle: "Impostazioni generali",
        TrustsSettingsUpdateSuccess: "Impostazioni salvate correttamente",
        TrustUpdateDelaySavingSuccess: "Impostazioni salvate correttamente",
        TrustsDelaysEditingPanelTitle: "Ritardi aggiornamento saldo del fido per tipologia di pagamento",
        ConfirmTrustDeletionMessage: "Sicuro di voler cancellare questo fido?",
        TrustValueRequired: "Inserire un valore per il fido",
        PositiveTrustValueRequired: "Il valore del fido deve essere un valore maggiore o uguale a zero",
        TrustsIntersectionsError:
            "ATTENZIONE! Sono presenti fidi che si accavallano. Non è possibile avere più di un fido attivo per un determinato periodo",
        DocumentsOutOfTrustsRangesDialogTitle: "Elenco documenti",
        TrustsValidationErrorsTitle: "Errori in fase di validazione fido",
        CanNotApproveExtraTrustDialogMessage:
            "Non è possibile salvare le modifiche perché comportano un extra fido dovuto a saldo negativo.",
        ApproveExtraTrustDialogMessage:
            "Le modifiche apportate comportano un extra fido dovuto a saldo negativo. Confermare comunque le modifiche?",
        TrustOverflowErrorLabel: "Saldo fido negativo",
        TrustValidationMessageLabel: "Errori di validazione del fido",
        TrustManagementDisabled: "Gestione del fido disattivata per il cliente",
        ActualTrustNotConfigured: "Non è stato configurato un fido in vigore ad oggi",
        ResidualToBePaidTitle: "Importo della fattura non gestito in scadenze",
        Expire: "Scadenza",

        CustomerDefaultValuesError: "Errori nei default di cliente",

        PECRequired:
            "La PEC è obbligatoria se la fatturazione elettronica è abilitata per il cliente e il campo Codice destinatario è uguale a '0000000'",
        RecipientCodeRequired:
            "Il Codice destinatario è obbligatorio se la fatturazione elettronica è abilitata per il cliente",
        RecipientCodeLengthError: "Il Codice destinatario {0} è errato. Deve avere una lunghezza di {1} caratteri",

        RecipientCodeWarning: "Nessun Codice destinatario inserito per la fatturazione elettronica",
        DuplicateRecipientCodeError:
            "Sono presenti dei codici destinatario duplicati, rimuovere le duplicazioni prima di salvare",
        PECWarning: "PEC mancante per la fatturazione elettronica",

        DuplicatedRecipientCode: "Codice destinatario già presente",
        EmptyRecipientCodeNotAllowed: "Il codice destinatario non può essere vuoto",

        CustomersSettingsGroup: "D. Clienti",
        CustomersGroups: "D.010 Gruppi Clienti",

        LettersOfAttempts: "Lettere d'intenti",
        LetterOfAttemptInvoices: "Fatture",
        LetterOfAttemptValidity: "Valida dal {0} al {1} - Data Agenzia Entrate: {2}",
        LetterOfAttemptInvoiceName: "Fattura nr. {0} del {1}, protocollo {2}",
        LetterOfAttemptPendingChanges:
            "ATTENZIONE!! Le modifiche fatte alla lettera d'intenti non sono state applicate. Continuare comunque?",

        InvalidLetterOfAttemptAmount:
            'La lettera d\'intenti "{0}" ha un valore errato (deve essere maggiore o uguale a zero)',
        InvalidLetterOfAttemptStartDate: 'La lettera d\'intenti "{0}" ha una data di inizio validità errata',
        InvalidLetterOfAttemptDueDate: 'La lettera d\'intenti "{0}" ha una data di fine validità errata',
        InvalidLetterOfAttemptValidity:
            "La lettera d'intenti \"{0}\" ha una data di fine validità precedente alla data d'inizio",
        MissingLetterOfAttemptProtocol: "Numero di protocollo mancante per la lettera d'intento",
        InvalidLetterOfAttemptProtocolNumber:
            'Il numero di protocollo {0} non è nel formato previsto. Deve essere composto da una prima parte di 17 caratteri e una seconda parte di 6 caratteri, separate dal caratter "-" o dal caratter "/".',
        MissingLetterOfAttemptDate:
            "Data Agenzia Entrate mancante: per le lettere d'intento è necessario specificare la data riportata sulla ricevuta telematica dell'Agenzia delle Entrate, necessaria per l'emissione delle fatture elettroniche.",

        SelectALetterOfAttempt: "Seleziona una lettera d'intento",

        CustomerABIs: "ABI cliente",
        CustomerCABs: "CAB cliente",
        CustomerIBANs: "IBAN cliente",
        IsCustomerRequired: "Il contatto deve essere impostato come cliente se non è attivo il flag fornitore.",
        IsSupplierRequired: "Il contatto deve essere impostato come fornitore se non è attivo il flag cliente.",
        ExternalAccountingCustomerCode: "Cod. cliente",
        ExternalAccountingSupplierCode: "Cod. fornitore",

        CustomerPaymentExtensions: "Proroghe Scadenze",
        NewCustomerPaymentExtension: "Nuovo",
        SaveCustomerPaymentExtension: "Salva",
        CustomerPaymentExtensionPaymentDate: "Data (gg/mm)",
        CustomerPaymentExtensionDaysNumber: "Proroga (gg)",
        CustomerPaymentExtensionActions: "Azioni",
        EmptyCustomerPaymentExtensionsList: "Nessuna proroga di pagamento configurata",

        CustomerPaymentExtensionTitle: "Proroga in data {0}",
        AllCustomersPaymentExtensionsDialogTitle: "Proroghe di pagamento",
        Customer: "Cliente",
        StartDateFilter: "Dal",
        EndDateFilter: "Al",
        CustomerVatNumber: "P. IVA",
        CustomerTaxNumber: "Cod. fis.",

        MissingAddress:
            "Indirizzo mancante. Verificare di aver compilato correttamente gli indirizzi delle unità organizzative.",
        MissingBankName:
            "Nome agenzia bancaria mancante. Verificare di aver compilato correttamente le agenzie bancarie delle unità organizzative.",
        MissingABI:
            "ABI mancante. Verificare di aver compilato correttamente le agenzie bancarie delle unità organizzative.",
        MissingCAB:
            "CAB mancante. Verificare di aver compilato correttamente le agenzie bancarie delle unità organizzative.",

        InvalidCustomerId: "Id cliente non valido",
        CustomerNotFound: "Cliente non trovato",
        OrganizationalUnitsRequired: "Inserire almeno un'unità organizzativa",
        TrustsDatesOverlap: "Gli intervalli di date definiti per il fido si accavallano.",
        TrustsBalanceError: "Il saldo del fido è in negativo",
        TrustInvalidValueError: "Il fido {id} ha un valore di fido negativo",
        TrustEndDateError: "Il fido ha una data di fine precedente alla data d'inizio",
        InvalidTaxCode: "P.IVA non valida",
        InvalidFiscalCode: "Codice fiscale non valido",
        InvalidNonPAElectronicInvoicingRecipientCode: "Il Codice destinatario deve essere di 7 caratteri",
        InvalidPAElectronicInvoicingRecipientCode: "Il Codice destinatario deve essere di 6 caratteri",
        InvalidMetadataId: "Errore nei default dei metadati: specificare un metadato per ogni riga presente.",
        ContactNotAccessible:
            "Non è possibile accedere al contatto selezionato perché è privato ed è stato creato da un altro utente.",
        DeleteUsedLettersOfAttempts:
            "Le seguenti lettere d''intenti non possono essere eliminate perché utilizzate in alcune fatture: {0}",
        InvalidLettersOfAttemptsRange:
            "Le modifiche apportate alle seguenti lettere d''intenti non sono valide perché ci sono fatture emesse al di fuori del periodo di validità: {0}",
        InvalidLettersOfAttemptsAmount:
            "Le modifiche apportate alle seguenti lettere d''intenti non sono valide perché il valore non copre il totale righe delle fatture collegate: {0}",
        MissingMailRecipientEmail: "Il valore selezionato come indirizzo email non è valido",
        MailRecipientEmailInvalid: "L'indirizzo email {0} inserito nei default di protocollo non è valido",
        MissingResourceAssociation: "L'utente non ha una risorsa associata, impossibile salvare il contatto.",
    },
    Desktop: {
        ObjectAlreadyInUse: "L'oggetto è già utilizzato da un altro utente",
        FileOperationError: "Si è verificato un errore durante {0} del file {1}",
        FileDownload: "il download",
        FileUpload: "l'upload",
        UserHasCreated: "L'utente {0} ha creato{1}{2}",
        UserHasModified: "L'utente {0} ha modificato{1}{2}",
        UserHasDeleted: "L'utente {0} ha cancellato{1}{2}",
        ArticleMale: " un ",
        ArticleFemale: " una ",
        ExplicitPrice: "Prezzo Esplicito",
        January: "Gennaio",
        February: "Febbraio",
        March: "Marzo",
        April: "Aprile",
        May: "Maggio",
        June: "Giugno",
        July: "Luglio",
        August: "Agosto",
        September: "Settembre",
        October: "Ottobre",
        November: "Novembre",
        December: "Dicembre",
        Sunday: "Domenica",
        Monday: "Lunedì",
        Tuesday: "Martedì",
        Wednesday: "Mercoledì",
        Thursday: "Giovedì",
        Friday: "Venerdì",
        Saturday: "Sabato",
        Jan: "Gen",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        MayShort: "Mag",
        Jun: "Giu",
        Jul: "Lug",
        Aug: "Ago",
        Sep: "Sete",
        Oct: "Ott",
        Nov: "Nov",
        Dec: "Dic",
        Sun: "Do",
        Mon: "Lu",
        Tue: "Ma",
        Wed: "Me",
        Thu: "Gi",
        Fri: "Ve",
        Sat: "Sa",
        SunShort: "Dom",
        MonShort: "Lun",
        TueShort: "Mar",
        WedShort: "Mer",
        ThuShort: "Gio",
        FriShort: "Ven",
        SatShort: "Sat",
        NextMonth: "Mese successivo",
        PreviousMonth: "Mese precedente",
        Yes: "Si",
        No: "No",
        AdministrativeDocuments: "Documenti Amministrativi",
        Blog: "Appunti di lavoro",
        Orders: "Commesse",
        Projects: "Progetti",
        ContractsAndOrders: "Contratti e Ordini",
        Agenda: "Scadenzario",
        FileRepository: "Archivio Documentale",
        HoursList: "Inserimento Ore",
        ToDoList: "Todo List Personale",
        Warehouse: "Magazzino",
        Expenses: "Note spese",
        TaskBoard: "Task Board",
        FlowModelEditor: "Editor dei modelli di flusso",
        Settings: "Impostazioni",
        AddressBook: "Rubrica",
        Records: "Protocollo",
        Assets: "Cespiti",
        Reports: "Reports",
        Desktop: "Desktop",
        Reminder: "Promemoria",
        ResourcesManager: "Risorse",
        ProLife: "ProLife",
        SwitchCompanyWarning:
            "Attenzione: cambiando la compagnia si verrà reindirizzati alla home page!\r\nSei sicuro di voler continuare?",
        SwitchCompanyCancel: "Rimani su questa pagina",
        SwitchCompanyConfirm: "Cambia Compagnia",
        BackupDatabase: "Backup database di {0}",
        MissingUsername: "Inserire l'utente",
        MissingPassword: "Inserire la password",
        SystemMessage: "Messaggio di sistema",
        ChangePassword: "Cambia la password",
        PasswordsNotMatching: "La nuova password non corrisponde a quella inserita nel campo di conferma",
        UpdatingPassword: "Aggiornamento password in corso...",
        PasswordChanged: "Password reimpostata correttamente",
        ReportedHours: "Ore rendicontate",
        WatchWarning: "Attenzione! La data selezionata è posteriore alla data del documento che è il {0}!",
        NoWorkSheetSelected: "Seleziona almeno una redicontazione ore da importare!",
        ImportWorkSheetsWarning: "Una o più rendicontazioni ore sono state ignorate perchè precedentemente importate",
        RoleNotFound: "(Mansione non trovata)",
        StartDate: "Dal {0}",
        EndDate: " al {0}",
        StartWorkDate: "Da inizio lavori",
        EndDateToday: " ad oggi",
        CallsRights: " - Diritti di chiamata",
        Purchases: "Acquisti",
        PurchasesWizardTitle: "Ricavi da Acquisti in Appunti di Lavoro",
        PurchasesTypes: "F.030 Tipologie Acquisti",
        NoPurchasesSelected: "Seleziona almeno un acquisto da importare!",
        ImportPurchasesWarning: "Uno o più acquisti sono stati ignorati perchè precedentemente importati",
        Hours: "Ore",
        TextAndDate: "Testi",
        ConnectionError: "ATTENZIONE : Si è verificato un errore di connessione durante la chiamata al server",
        ExpiredSession: "Sessione scaduta",
        MaintenanceMode: "ATTENZIONE! Il sistema è in manutenzione, si verrà rediretti alla schermata di login!",
        Customers: "Clienti",
        ChangelogDialogTitle: "Changelog versione {0}",
        SaveChangelogReadingStatusError: "Si è verificato un errore, impossibile registrare la lettura del changelog",

        ConnectionSlow: "Connessione Lenta",
        Reconnecting: "Riconnessione in corso...",
        Connected: "Connesso",
        NotConnected: "Disconnesso",
        Connecting: "Connessione in corso...",
        ConfirmEntitiesSearch:
            "Attenzione!! Stai cercando di visualizzare {0} righe, l'operazione potrebbe richiedere alcuni minuti. Continuare?",
        SupportDialogTitle: "Assistenza",
        TimeZone: "Fuso orario",

        UnauthorizedForMobileTitle: "ATTENZIONE!!",
        UnauthorizedForMobileMessage: "Non abilitato all'uso da mobile.",

        PasswordsMatch: "Le password coincidono",
        PasswordsDoNotMatch: "Le password non coincidono",
        MailTemplatesSettingsManagerLabel: "E.035 Modelli email",
        MailTemplateUsedBy: "Non è possibile eliminare il template perché utilizzato nel sistema.",
        CustomTilesError: "Errore durante il caricamento delle applicazioni esterne",
        DesktopSettingsError: "Errore durante il caricamento delle impostazioni del desktop",
    },
    Expenses: {
        TempFileRepositoryPath: "/Note Spese/Nota_spese_{0}/",
        ExpenseFileRepositoryPath: "/Note Spese/{0}/{1}/{2}/",

        Expenses: "Note spese",
        ExpensesSettingsGroup: "H. Note spese",
        NewExpense: "Nuova nota spese",
        DeleteMsg: "Sei sicuro di voler eliminare la nota spese?",
        DeleteMsgCancel: "Non eliminare",
        DeleteMsgConfirm: "Elimina",
        InvalidInterval: "L'intervallo di date specificato non è valido",
        MissingResourceError: "Selezionare una risorsa",
        MissingFromDateError: "Selezionare la data della trasferta (Dal).",
        MissingExpenseStatusError: "Selezionare lo stato della nota spese.",
        SelectProject: "Selezionare il progetto su tutte le righe delle commesse.",
        ModifyExpense: "Modifica Nota Spese",
        CloseMsg: "Tutte le modifiche apportate alla Nota Spese andranno perse. Chiudere la nota spese?",
        CloseMsgCancel: "Continua modifiche",
        CloseMsgConfirm: "Chiudi",
        OnSaveWarnings: "Attenzione! {0}",
        OnSaveWarningsCancel: "Annulla",
        OnSaveWarningsConfirm: "Salva comunque",
        ExpenseSaved: "Nota Spese salvata con successo",
        SaveError:
            "Attenzione! Si è verificato un errore durante il salvataggio della Nota Spese.\nContattare l'assistenza!",
        SaveErrorTitle: "Errore durante il Salvataggio",

        FromDatePlaceholder: "Dal",
        ToDatePlaceholder: "Al",

        Attachments: "Allegati",
        AttachmentsActions: "Azioni",
        AttachmentsManagerTitle: "Gestione allegati",
        EmptyAttachmentsList: "Nessun file allegato",
        AttachFile: "Allega documento",
        DeleteSelectedAttachments: "Elimina selezionati",
        DownloadAttachment: "Scarica allegato",
        Version: "Versione {0}",

        CostRowTypeSettingsManagerLabel: "Tipologie di costo",
        ExpenseDuration: "{0} gg",

        ExpenseNumber: "Numero",
        InsertResource: "Inseirisci la risorsa",
        ResourceFieldLabel: "Risorsa",
        FromFieldLabel: "Dal",
        ToFieldLabel: "Al",
        Place: "Luogo",
        Motive: "Motivo",
        Notes: "Note",
        InsertNotes: "Inserisci le note...",
        JobOrder: "Commessa",
        Workflow: "Piano di lavoro",
        Task: "Attività",
        Percentage: "Percentuale",
        Add: "Aggiungi",
        MissingProjects: "Nessuna commessa assegnata",
        Remove: "Rimuovi",

        CostRowDescription: "Descrizione",
        CostRowDescriptionPlaceholder: "Descrizione",
        Amount: "Q.tà",
        UnitPrice: "P. unitario",
        Discount: "Sconto",
        NetPrice: "P. netto",
        CostRowType: "Tipologia",
        CostRowPurchaseType: "Tipologia acquisto",
        IsSustainedByResource: "Sost. da risorsa",

        SelectActivities: "Seleziona attività",
        CostValue: "Costo",
        Currency: "Valuta",

        PurchaseTypeFieldPlaceholder: "Tipologia di acquisto...",
        JobOrderFieldPlaceholder: "Commessa...",
        WorkflowFieldPlaceholder: "Piano di lavoro...",
        TaskFieldPlaceholder: "Attività...",
        TotalCost: "Tot.",
        CostRowTotalCost: "Tot.",
        ProjectsCosts: "Costi",
        ProjectsExpectedRevenues: "Ricavi attesi",
        ProjectsTotalPercentage: "Tot. %:",
        ProjectsTotalCosts: "Tot. costi:",
        ProjectsTotalExpectedRevenues: "Tot. ricavi attesi:",

        ExpenseStatusesSettingsManager: "H.010 Stati nota spese",
        ExpenseStatusesEditorTitle: "Stati nota spese",

        ExpenseStatusLabel: "Etichetta",
        ExpenseStatusLabelPlaceholder: "Etichetta stato",

        ExpenseLogicalStatus: "Stato logico",
        ExpenseLogicalStatusPlaceholder: "Stato logico...",

        DraftLogicalStatus: "In stesura (bozza)",
        ApprovedLogicalStatus: "Approvata",
        RefundedLogicalStatus: "Rimborsata al dipendente",

        SaveSuccess: "Salvataggio riuscito",
        DeleteSuccess: "Eliminazione riuscita",
        Billable: "Fatturabile",

        ExpenseStatusFieldLabel: "Stato",
        ExpenseStatusPlaceholedr: "Stato nota spese...",
        NotDefaultCurrency: "Questo valore è espresso in una valuta diversa da quella di sistema.",

        DuplicatedJobOrdersError:
            "Non è possibile selezionare più volte la stessa commessa. Controllare le righe di ripartizione dei costi.",

        MotiveNotProvided: "Il motivo della trasferta non è stato inserito.",
        CostRowsNotProvided: "Nessuna riga di costo insertita.",
        MissingPurchaseType: "Selezionare la tipologia di acquisto su tutte le righe di costo.",
        MissingCostRowDescription: "Inserire una descrizione per ogni riga di costo.",
        JobOrderRowsNotProvided: "Nessuna commessa inserita.",

        GeneralDataSectionTitle: "Dati generali",
        CostsSectionTitle: "Costi",
        JobOrdersSectionTitle: "Commesse",

        DeleteExpenseRequest: "Sei sicuro di voler eliminare questa nota spese?",
        Markup: "Variazione costo unitario",

        GenerateExpectedRevenueRow: "Genera ricavo atteso",
        SetMarkupTitle: "Imposta ricarico",

        AddTaskButton: "Attività",
        AddWorkflowButton: "Piano",

        NewTask: "Crea nuova attività",
        NewTaskFromTask: "Copia attività",
        NewTaskFromTemplate: "Crea attività da template",

        NewWorkflow: "Crea nuovo piano",
        NewWorkflowFromWorkflow: "Copia piano",
        NewWorkflowFromTemplate: "Crea piano da template",

        JobOrderSelectionRequired: "Selezionare una commessa",
        ExpenseDateLabel: "Dal {0} al {1}",
    },
    Reminder: {
        ApplicationName: "Promemoria",
        ReminderURL: "Promemoria",
        ExpiredAreaTitle: "Completati",
        ComingAreaTitle: "Da completare",
        RemindEditMaskDialogTitle: "Modifica promemoria",
        RemindRelateObjDialogTitle: "Collega promemoria ad oggetto",

        RemindPriorityLow: "Bassa",
        RemindPriorityMedium: "Media",
        RemindPriorityHigh: "Alta",

        CreateOrUpdateRemindSuccessMessage: "Promemoria salvato con successo",
        CreateOrUpdateRemindErrorMessage: "Si è verificato un problema durante il salvataggio del promemoria",

        RemindSaveValidationInvalidTitle: "Inserire un titolo valido",
        RemindSaveValidationInvalidExpireDate: "Inserire una scadenza valida",

        DeleteRemindWarning: "Sei sicuro di voler eliminare il promemoria?",
        DeleteRemindWarningCancel: "Non eliminare il promemoria",
        DeleteRemindWarningConfirm: "Elimina il promemoria",
        RemindDeleted: "Promemoria eliminato con successo",
        RemindDeleteError: "Si è verificato un errore durante l'eliminazione del promemoria",

        MoreOfMaxCount: "Più di 199",
    },
    ResourcesManager: {
        ApplicationName: "Risorse",
        ResourcesManagerURL_Resources: "Risorse",
        ResourcesManagerURL_Groups: "GruppiDiRisorse",
        ResourcesManagerURL_Roles: "Mansioni",
        ResourcesManagerURL_OperationalUnit: "UnitaOperative",
        ResourcesManagerURL_CalRights: "DirittiChiamata",
        ResourcesManagerURL_WorkTimeCategories: "TipologieOrarie",

        WorkTimeCategoriesAreaTitle: "Tipologie Orarie",
        WorkTimeCategoriesInvalidName: "Inserire un nome valido",
        WorkTimeCategoryUpdated: "Tipologia oraria salvata",
        WorkTimeCategoryUpdateError: "Si è verificato un errore durante il salvataggio della tipologia oraria",
        WorkTimeCategoryDeleted: "Tipologia oraria eliminata",
        WorkTimeCategoryDeleteError: "Si è verificato un errore durante l'eliminazione della tipologia oraria",
        WorkTimeCategoriesNewCategory: "Nuova tipologia oraria",
        WorkTimeCategoryDeleteWarning: "Sei sicuro di voler eliminare la tipologia oraria?",
        WorkTimeCategoryDeleteWarningCancel: "Non eliminare",
        WorkTimeCategoryDeleteWarningConfirm: "Elimina la tipologia oraria",
        WorkTimeCategoryRestored: "Tipologia oraria ripristinata",
        WorkTimeCategoryRestoredError: "Si è verificato un errore durante il ripristino della tipologia oraria",

        CallRightTypesAreaTitle: "Diritti di chiamata",
        CallRightTypesInvalidName: "Inserire un nome valido",
        CallRightTypesUpdated: "Tipo DDC salvato",
        CallRightTypesUpdateError: "Si è verificato un errore durante il salvataggio del tipo doi DDC",
        CallRightTypesDeleted: "Tipo di DDC eliminato",
        CallRightTypesDeleteError: "Si è verificato un errore durante l'eliminazione del tipo di DDC",
        CallRightTypesNewCallRightType: "Nuovo tipo di diritto di chiamata",
        CallRightTypesDeleteWarning: "Sei sicuro di voler eliminare il diritto di chiamata?",
        CallRightTypesDeleteWarningCancel: "Non eliminare",
        CallRightTypesDeleteWarningConfirm: "Elimina il diritto di chiamata",
        CallRightTypesRestored: "Diritto di chiamata ripristinato",
        CallRightTypesRestoredError: "Si è verificato un errore durante il ripristino del diritto di chiamata",

        OperationalUnits: "Unità operative",
        OperationalUnitsAreaTitle: "Unità operative",
        OperationalUnitsNewOperationalUnit: "Nuova unità operativa",
        OperationalUnitsInvalidName: "Inserire un nome valido",
        OperationalUnitsInvalidStartDate: "Inserire una data di inizio valida",
        OperationalUnitsInvalidDatesRange: "Inserire un arange di date valido",
        OperationalUnitsUpdated: "Unità operativa salvata",
        OperationalUnitsUpdateError: "Si è verificato un errore durante il salvataggio dell'unità operativa",
        OperationalUnitsDeleted: "Unità operativa eliminata",
        OperationalUnitsDeleteError: "Si è verificato un errore durante l'eliminazione dell'unità operativa",
        OperationalUnitsInvalidCosts: "Alcuni periodi di costo non sono corretti",
        OperationalUnitsCostsCollisions: "I periodi di costo non possono sovrapporsi",
        OperationalUnitsDeleteWarning: "Sei sicuro di voler eliminare l'unità operativa?",
        OperationalUnitsDeleteWarningCancel: "Non eliminare",
        OperationalUnitsDeleteWarningConfirm: "Elimina l'unità operativa",
        OperationalUnitsRestored: "Unità operativa ripristinata",
        OperationalUnitsRestoredError: "Si è verificato un errore durante il ripristino della unità operativa",

        ResourcesRolesAreaTitle: "Mansioni",
        ResourcesRolesNewOperationalUnit: "Nuova mansione",
        ResourcesRolesInvalidName: "Inserire un nome valido",
        ResourcesRolesUpdated: "Mansione salvata",
        ResourcesRolesUpdateError: "Si è verificato un errore durante il salvataggio della mansione",
        ResourcesRolesDeleted: "Mansione eliminata",
        ResourcesRolesDeleteError: "Si è verificato un errore durante l'eliminazione della mansione",
        ResourcesRolesDeleteWarning: "Sei sicuro di voler eliminare la mansione?",
        ResourcesRolesDeleteWarningCancel: "Non eliminare",
        ResourcesRolesDeleteWarningConfirm: "Elimina la mansione",
        ResourcesRolesRestored: "Mansione ripristinata",
        ResourcesRolesRestoredError: "Si è verificato un errore durante il ripristino della mansione",
        ResourcesNotConfiguredOrdersWarning:
            "ATTENZIONE : Alcuni ordini di servizio non hanno nessuna ora configurata o nessuna ora di lavoro annua stimata. Proseguire comunque?",
        ResourcesNotConfiguredOrdersWarningCancel: "Annulla il salvataggio",
        ResourcesNotConfiguredOrdersWarningConfirm: "Prosegui comunque",

        ResourcesAreaTitle: "Risorse",
        ResourcesNewResource: "Nuova risorsa",
        ResourcesInvalidName: "Inserire un nome valido",
        ResourcesInvalidServiceOrders:
            "Non è possibile avere più di un ordine di servizio per la stessa Unità Operativa nello stesso periodo",
        ResourcesUpdated: "Risorsa salvata",
        ResourcesUpdateError: "Si è verificato un errore durante il salvataggio della risorsa",
        ResourcesDeleted: "Risorsa eliminata",
        ResourcesDeleteError: "Si è verificato un errore durante l'eliminazione della risorsa",
        ResourceWithoutName: "Risorsa senza nome",
        ResourcesDeleteWarning: "Sei sicuro di voler eliminare la risorsa?",
        ResourcesDeleteWarningCancel: "Non eliminare",
        ResourcesDeleteWarningConfirm: "Elimina la risorsa",
        ResourcesRestored: "Risorsa ripristinata",
        ResourcesRestoredError: "Si è verificato un errore durante il ripristino della risorsa",

        ResourceCostsDialogTitle: "Costi risorsa",

        InvalidResourcesCostRanges: "Gli intervalli di date inseriti non possono accavallarsi",
        InvalidStartDateForCostRange: "Specificare una data di inizio valida",

        ServiceOrderInvalidFromDateError: "Inserire una data di inizio valida",
        ServiceOrderInvalidOperationalUnitError: "Selezionare una unità operativa",
        ServiceOrderInvalidTitleError: "Inserire un titolo",

        DeleteServiceOrderWarning: "Sei sicuro di voler eliminare l'ordine di servizio?",
        DeleteServiceOrderWarningCancel: "Non eliminare l'ordine di servizio",
        DeleteServiceOrderWarningConfirm: "Elimina l'ordine di servizio",
        ServiceOrderNewTitle: "Nuovo ordine di servizio",

        RestoreResourceDataWarning: "Sei sicuro di voler ripristinare i valori originali?",
        RestoreResourceDataWarningCancel: "Continua a modificare",
        RestoreResourceDataWarningConfirm: "Ripristina i valori originali",

        ResourcesGroupsAreaTitle: "Gruppi di risorse",
        ResourceGroupNewName: "Nuovo gruppo di risorse",
        ResourceGroupInvalidName: "Inserire un nome valido",
        ResourcesGroupsDeleteWarning: "Sei sicuro di voler eliminare il gruppo di risorse?",
        ResourcesGroupsDeleteWarningCancel: "Non eliminare",
        ResourcesGroupsDeleteWarningConfirm: "Elimina gruppo di risorse",
        ResourceGroupRestored: "Gruppo di risorse ripristinato",
        ResourceGroupRestoredError: "Si è verificato un errore durante il ripristino del gruppo di risorse",
        ResourceGroupUpdated: "Gruppo salvato",
        ResourceGroupUpdateError: "Si è verificato un errore durante il salvataggio del gruppo",
        ResourceGroupDeleted: "Gruppo eliminato",
        ResourceGroupDeleteError: "Si è verificato un errore durante l'eliminazione del gruppo",

        HoursNotValidCheckReportTitle:
            "Sono state rilevate delle incoerenze tra la configurazione della risorsa e le seguenti ore lavorate",

        ResourcesOperationalUnitRequired: "Selezionare l'unità operativa",

        ServiceOrderInvalidWorkingHoursError: "Inserire le fasce orarie di lavoro della risorsa",
        StandardWorkingHoursNotDefined: "Nessun orario standard definito per l'unità operativa selezionata",
        ConfirmLostChangesOnResourceEdit:
            "ATTENZIONE! Continuando si perderanno le modifiche apportate alla risorsa! Continuare?",
        ConfirmLostChangesOnResourceEditCancel: "Annulla",
        ConfirmLostChangesOnResourceEditConfirm: "Continua e perdi le modifiche",

        UserCharacters: "Mansioni",
        HumanResources: "Risorse Umane",
        MaterialResources: "Risorse Materiali",
        SearchResources: "Cerca risorse...",
        Deleted: "Eliminati",
        Disabled: "Disabilitati",
        New: "Nuovo",
        NoConfiguredResource: "Nessuna risorsa configurata",
        ResourceName: "Nome",
        ResourceNamePlaceholder: "Inserire un nome...",
        ResourceDescription: "Descrizione",
        ResourceDescriptionPlaceholder: "Inserire una descrizione...",
        ResourceSurname: "Cognome",
        ResourceSurnamePlaceholder: "Inserire il cognome...",
        ResourceSerialNumber: "Matricola",
        ResourceSerialNumberPlaceholder: "Inserire la matricola...",
        ResourceDefaultRole: "Mansione predefinita",
        ResourceDefaultRolePlaceholder: "Seleziona...",
        ResourceUser: "Utente",
        ResourceUserPlaceholder: "Seleziona...",
        ResourceDisabled: "Disabilitato",
        ResourceSave: "Salva",
        ResourceRestoreOriginalValues: "Ripristina valori iniziali",
        ResourceDelete: "Elimina",
        ResourceRestore: "Ripristina",

        OperationalUnit: "Unità operativa",
        OperationalUnitPlaceholder: "Seleziona...",

        ActiveServiceOrderOnDisabledOrDeletedResourceError:
            "Per poter disabilitare o eliminare una risorsa è necessario prima chiudere tutti i suoi ordini di servizio.",
        WorkTimeCategories: "Tipologie orarie",

        ResourceDefaultOption: "Default di risorsa",

        GroupsSearch: "Cerca gruppi...",
        DeletedGroups: "Eliminati",
        NewGroup: "Nuovo",
        GroupName: "Nome",
        GroupNamePlaceholder: "Inserire un nome...",
        GroupMembers: "Membri del gruppo",
        GroupMembersPlaceholder: "Utenti del gruppo...",
        GroupDescription: "Descrizione",
        Save: "Salva",
        Delete: "Elimina",
        Restore: "Ripristina",
        HoursApproval: "Approvazione ore lavorate",
        HoursApprovalGroupRole: "Descrizione ruolo",
        GroupDescriptionPlaceholder: "Descrizione gruppo...",
        HoursApprovalGroupRolePlaceholder: "Descrizione ruolo di approvazione ore...",
        HoursApprovalAuthorizedResourcesAndGroups: "Risorse autorizzate ad approvare o visualizzare le ore",

        HoursApprovalResource: "Risorsa/gruppo",
        HoursApprovalCanApprove: "Può approvare",
        HoursApprovalRemoveTooltip: "Rimuovi la risorsa",

        ServiceOrderTitleLabel: "Titolo",
        ServiceOrderTitlePlaceholder: "Inserire un titolo...",
        ServiceOrderFrom: "Dal (incluso)",
        ServiceOrderTo: "Al (escluso)",
        ServiceOrderTotalEstimatedWorkHours: "Ore di lavoro totali stimate",
        ServiceOrderMaxFlexiblePositive: "Max flessibilità positiva",
        ServiceOrderMaxFlexibleNegative: "Max flessibilità negativa",
        ServiceOrderTotalEstimatedWorkHoursToltip:
            "Se il valore è zero verrà calcolato un nummero di ore annue pari alla media oraria settimanale dell'ordine di servizio riportata su 365 giorni.",
        ServiceOrderTimeSlots: "Fasce orarie di lavoro",
        ServiceOrderDefaultWorkingTimeButton: "Carica orario standard",
        ServiceOrderDefaultWorkingTimeButtonTooltip: "Carica l'orario standard definito per l'unità operativa",
        ServiceOrderWorkingTimeNotDefined: "Orari di lavoro non definiti",
        ServiceOrderWorkingTimeslotNotDefined: "Orario non definito",
        ServiceOrderWorkingTimeslotStart: "Inizio",
        ServiceOrderWorkingTimeslotDuration: "Durata",
        ServiceOrderRolesSelectorTitle: "Seleziona le mansioni lavorabili su questo ordine di servizio",
        ServiceOrderRolesSelectorRole: "Mansione",
        ServiceOrderRolesSelectorMeanCost: "Costo Medio",
        ServiceOrderRolesSelectorDefaultRole: "Predefinita",
        ServiceOrderCostsSelectorTitle: "Seleziona i costi per questo ordine di servizio e specifica gli importi",
        ServiceOrderCostsSelectorDefaultCost: "Predefinito",
        ServiceOrderCostsSelectorWorktimeCategory: "Tipologia oraria",
        ServiceOrderCostsSelectorCanBeUsedForFlexibility: "Flessibilità",
        ServiceOrderCostsSelectorCost: "Costo",
        ServiceOrderCostsSelectorAnnualHours: "Ore annue",
        ServiceOrderCostsSelectorAnnualHoursBehaviours: "Azioni",
        ServiceOrderCostMaxAnnualHours: "Max.",
        ServiceOrderCostMinAnnualHours: "Min.",
        ServiceOrderCostMaxAnnualHoursPlaceholder: "Max.",
        ServiceOrderCostMinAnnualHoursPlaceholder: "Min.",
        ServiceOrderCostMaxAnnualHoursOffsetPlaceholder: "Offset.",
        ServiceOrderCostMaxAnnualHoursOffset: "Offset",

        SOCostAnnualHoursBehaviuorNoAction: "Nessuna azione",
        SOCostAnnualHoursBehaviuorShowResidual: "Mostra ore residue",
        SOCostAnnualHoursBehaviuorShowResidualAndAlert:
            "Mostra ore residue e avvisa l'utente al salvataggio delle ore lavorate",
        SOCostAnnualHoursBehaviuorShowResidualAndLock:
            "Mostra ore residue e impedisci il salvataggio delle ore lavorate",
        ServiceOrderNotes: "Note",
        ApprovalResourceAlreadySelected: "La risorsa selezionata è già in elenco.",
    },
    Invoices: {
        OpenDocumentsURL: "Documenti/Apri/{0}/{1}",
        OpenDocumentsURLv2: "Documenti/Apri/{0}/{1}",
        OpenDocumentsByIdURLv2: "Documenti/ApriId/{0}",
        Invoice: "Fattura",
        AccompanyingInvoice: "Fattura accompagnatoria",
        Invoices: "Fatture",
        AccompanyingInvoices: "Fatture accompagnatorie",
        CreditNotes: "Note di credito",
        PartialInvoices: "Fatture parziali",
        Delivery: "DDT",
        Deliveries: "DDT",
        Quote: "Preventivo",
        Quotes: "Preventivi",
        Unknown: "<Sconosciuto>",
        Unknowns: "<Sconosciuti>",
        AdministrativeDocuments: "Documenti Amministrativi",
        AdministrativeDocumentsSettingsGroup: "E. Documenti Amministrativi",
        DocumentsURL: "Documenti",
        NewDocumentURL: "Documenti/Nuovo/{0}",
        NewDocumentURLv2: "Documenti/Nuovo/{0}/{1}",
        NewDocumentJobOrderURL: "Documenti/Nuovo/{0}/JobOrder/{1}",
        NewDocumentJobOrderURLv2: "Documenti/Nuovo/{0}/{1}/JobOrder/{2}",
        NewDocumentForJobOrderURL: "Documenti/Nuovo/{0}/JobOrder/{1}",
        ContinueMsg: "Sei sicuro di voler continuare?",
        GenericDocument: "Documento generico",
        InvoiceMore: "Fattura accompagnatoria",
        CreditNote: "Nota di Credito",
        DDT: "DDT",
        Estimate: "Preventivo",
        OriginWarehouse: "Selezionare il magazzino di provenienza",
        DestinationWarehouse: "Selezionare il magazzino di destinazione",
        PaymentMode: "Inserire la modalità di pagamento",
        Expiration: "Inserire la scadenza",
        MissingStatus: "Inserire lo stato",
        DocumentStateChangedSuccess: "Stato del documento impostato correttamente",
        BoxNumber: "Inserire il numero di colli",
        Reason: "Inserire la causale",
        ReasonsForPayment: "Causali di Pagamento",
        LookAndFeel: "Inserire l'aspetto esteriore dei beni",
        ShipmentMode: "Inserire il mezzo di trasporto",
        CreditNoteShort: "Nota C.",
        Expirations: "Scad.",
        MailSubject: "Fattura%20ProLife",
        InvalidVATNumber:
            "E' stata selezionata una causale di trasferimento merce ma la partita iva del cliente non coincide con quella dell'azienda.",
        InvalidDeliveryState: "Attenzione! Non è stato alcuno stato logico per i DDT/Fatture, contattare l'assistenza!",
        CriticalError: "Errore Critico, contattare l'assistenza!",
        InsertStatus: "Inserire lo stato",
        WatchWarning: "Attenzione! La data selezionata è posteriore alla data del documento che è il {0}!",
        InvoiceName: "Fattura n° {0} del {1}",
        NoRowToImport: "Selezionare almeno una riga da importare nel documento!",
        ImportRowWarning:
            "Una o più delle righe selezionate sono già state importate in un altro documento.\nSei sicuro di voler importare le righe selezionate?",
        ImportRowWarningCancel: "Annulla",
        ImportRowWarningConfirm: "Importa comunque le righe",
        ImportRowWarningOnlyDescriptions: "Importa solo le descrizioni",
        ImportRowWarningImportWithUnitAmount: "Importa con q.tà unitaria",
        RowsAlreadyImported: "Alcune righe non sono state importate perchè già utilizzate in questo documento!",
        OrderBlockedBy: "Commessa bloccata da {0} con motivazione: {1}",
        InvoiceReference: "Rif. Fattura n.{0} del {1}",
        QuoteName: "Preventivo n° {0} del {1}",
        OrderNotFound: "(Commessa non trovata)",
        CustomerNotFound: "(Cliente non trovato)",
        DeliveryName: "DDT n° {0} del {1}",
        DeliveryReference: "Rif. DDT n.{0} del {1}",
        DataImport: "Importazione dati",
        QuoteDataImport: "Preventivo: Importazione dati",
        Groups: "Gruppi",
        RowsWithoutDescription: "Alcune righe non hanno una descrizione",
        DiscardedRows: "Alcune delle righe selezionate sono state scartate perchè erano già state aggiunte.",
        Overview: "Riepilogo",
        VATSelection: "Selezione IVA",
        VAT: "IVA",
        NoVATWarning: "Impostare il codice IVA su tutte le righe da importare",
        ModifyStatus: "Modifica Stato",
        Done: "Fine",
        Block: "Blocca",
        Unblock: "Sblocca",
        DeleteDocuments: "Elimina Selezionati",
        New: "Nuovo",
        InvoiceLetter: "F",
        DeliveryLetter: "D",
        QuoteLetter: "P",
        SelectCategory: "Selezionare almeno una categoria di contatti da esportare",
        SelectValidInterval: "Selezionare un intervallo di date valido",
        SelectProtocol: "Selezionare almeno un protocollo",
        ReportModelsURL: "Modelli Report",
        VATRegistries: "Registri IVA",
        Transfers: "Trasferimenti",
        UnknownSimple: "Sconosciuto",
        Sal: "Sal",
        WarehouseLoad: "Carichi di Magazzino",
        VendorOrders: "Ordini Fornitore",
        CustomerOrders: "Ordini Cliente",
        AllRegisters: "Tutti i registri",
        CustomerInvoices: "Fatture cliente",
        AllCustomers: "Tutti i clienti",
        Unprocessed: "Non gestito",
        Open: "Aperto",
        PartiallyInvoiced: "Parzialmente fatturato",
        Invoiced: "Fatturato",
        AllProtocols: "Tutti i protocolli",
        PortTypes: "E.150 Tipi Porto",
        DDTDeliveryReason: "E.110 Causale di trasporto DDT",
        DDTDeliveryReasons: "Causali di trasporto DDT",
        DDTCarriageTypes: "Tipi di porto",
        DeliveryLookAndFeel: "E.140 Aspetto beni",
        DeliveryType: "E.130 Tipo di trasporto",
        DeliveryStatus: "Stati DDT",
        Offset: "E.160 Contropartita",
        QuoteAndOrderStatus: "E.050 Stati Preventivo / Ordine fornitore",
        DocumentTypes: "Tipologie Documento",
        WitholdingTax: "E.100 Ritenute d'acconto",
        ProtocolNumbers: "E.020 Numeri di protocollo",
        QuoteValidity: "E.060 Validità preventivo",
        PaymentTypes: "E.070 Tipologie di Pagamento",
        VATCodes: "E.090 Codici IVA",
        InvoiceExpirations: "E.080 Scadenze di pagamento",
        Excercises: "E.010 Esercizi",
        LogicStateChangedMsg: "ATTENZIONE : Lo stato logico è cambiato. Sei sicuro di voler salvare comunque?",
        LogicStateChangedMsgCancel: "Non salvare",
        LogicStateChangedMsgConfirm: "Salva lo stato",
        StateSaved: "Stato salvato",
        DeleteStateMsg:
            "Sei sicuro di voler eliminare lo stato? Si ricorda che si tratta di una eliminazione logica e sarà possibile ripristinare in seguito lo stato.",
        DeleteStateMsgCancel: "Non eliminare",
        DeleteStateMsgConfirm: "Elimina lo stato",
        StateDeleted: "Stato eliminato",
        StateRestored: "Stato ripristinato",
        Rejected: "Rifiutato",
        Accepted: "Accettato",
        Draft: "Bozza",
        InsertDescription: "Inserire la descrizione",
        DeleteReasonMsg: "Sei sicuro di voler eliminare la causale?",
        Yes: "Si",
        No: "No",
        NoChangesToBeSaved: "Non sono presenti modifiche da salvare!",
        ChangesSaved: "Modifiche salvate con successo!",
        ErrorWhileSaving: "Si è verificato un errore nel salvataggio!",
        DeleteFiscalMsg: "Sei sicuro di voler eliminare l'esercizio selezionato?",
        FiscalDeleted: "Esercizio eliminato con successo!",
        ErrorWhileFiscalDelete: "Si è verificato un errore durante la cancellazione dell'esercizio!",
        Taxable: "Imponibile",
        NotTaxable: "Non imponibile",
        InvalidWitholdingFields:
            "Mappatura non valida. Verificare che non ci siano valori uguali per le ritenute d'acconto e che tutti i campi siano stati valorizzati.",
        SettingsSaved: "Impostazioni salvate con successo",
        BankReceipt: "Riba",
        BankTransfer: "Bonifico",
        AccountantSettings: "Impostazioni software di contabilità : Pagamenti e Scadenze",
        ExportCodesSaved: "Codici di esportazione salvati con successo",
        ExternalExportConfig: "Configurazioni di esportazione verso Software di contabilità",
        DeleteProtocolMsg: "Sei sicuro di voler eliminare il protocollo?",
        DocumentCreationSettings:
            "Configurazioni evento autogenerato in appunti di lavoro alla creazione di un documento",
        ExpirationChangesSettings:
            "Configurazioni evento autogenerato in appunti di lavoro al cambio di stato delle scadenze",
        InvalidFormat: "Formato non supportato",
        InvalidLogoSize: "L'immagine del logo deve avere una dimensione inferiore a 500 KByte",
        FileCreationSettings: "Configurazioni file autogenerato in archivio documentale al salvataggio di un documento",
        UnsavedChanges: "Sono state apportate delle modifiche che verranno perse.",
        KeedEditing: "Ritorna al documento",
        LooseChanges: "Continua e perdi le modifiche",
        Search: "Ricerca",
        Types: "Tipologie",
        UndefinedIBAN: "IBAN NON DEFINITO",
        HourlyResources: "Risorse orarie",
        ResourcesGroups: "Gruppi di risorse",
        UnavailableResource: "Risorsa non disponibile",
        UnnamedResource: "Risorsa senza nome",
        SelectedJobOrderTextResourcesAlertTitle: "ATTENZIONE!",
        SelectedJobOrderTextResourcesAlertMessage:
            "La commessa selezionata è stata segnalata dall'utente {0} con motivazione {1}. Prima di procedere con l'emissione di documenti si consiglia di effettuare eventuali verifiche.",
        VatServiceUnavailable: "Al momento non è possibile ottenere le informazioni sull'azienda, riprovare più tardi",
        VatServicePleaseRetry: "Il servizio è sovraccarico, riprovare",
        SchedulesDialogTitle: "Dialog scadenze documento",
        Export: "Esporta",
        DownloadTemplate: "Template",
        AdvancedFilter: "Filtro Avanzato",

        DaysNumberSinceDocumentIssueRequired:
            "Il numero di giorni dalla data di emissione del documento è obbligatorio",
        DocumentPricePartRequired: "La percentuale di importo del documento da pagare alla scadenza è obbligatorio",
        DocumentPriceThresholdRequired: "Il valore di soglia dell'importo del documento è obbligatorio",
        DayOfMonthRequired: 'Il giorno del mese è obbligatorio se l\'opzione "Giorno del mese" è abilitata',

        ConfirmSchedulesRebuildMessage: "Vuoi rigenerare le scadenze per il documento?",
        ConfirmSchedulesRebuildConfirmButton: "Si",
        ConfirmSchedulesRebuildDenyButton: "No",

        CustomerTrustAuthorizationTitle: "Richiesta autorizzazione fido",

        TrustAuthorizationRequestNotSentLabel: "Richiesta non inviata",
        TrustAuthorizationRequestSentLabel: "Richiesta inviata",
        TrustAuthorizationRequestIgnoredLabel: "Richiesta ignorata",
        TrustAuthorizationRequestDeniedLabel: "Richiesta rifiutata",
        TrustAuthorizationRequestOnEvaluationLabel: "Richiesta presa in carico",
        TrustAuthorizationRequestAcceptedLabel: "Richiesta accettata",
        TrustAuthorizationRequestSendingInProgressLabel: "Invio in corso",

        TrustAuthorizationOnEvaluationByOthers: "Le richiesta è stata presa in carico da un altro utente",
        TrustAuthorizationDenied: "La richiesta è stata negata",
        TrustAuthorizationRequestAborted: "La richiesta di autorizzazione fido è stata annullata dal richiedente",

        ConfirmCustomerTrustOverflow: "Attenzione! Il fido cliente verrà sforato di ##trust_value## €. Continuare?",
        TrustsAuthorizationsHistory: "Reg. autorizzazioni",
        TrustsAuthorizationsHistoryDialogTitle: "Storico autorizzazioni",

        InvoiceLabelRequired:
            'L\'etichetta delle fatture è obbligatoria se sul protocollo è abilitata la tipologia di documento "Fattura"',
        CreditNoteLabelRequired:
            'L\'etichetta delle note di credito è obbligatoria se sul protocollo è abilitata la tipologia di documento "Nota di credito"',
        AccompanyingInvoiceLabelRequired:
            'L\'etichetta delle fatture accompagnatorie è obbligatoria se sul protocollo è abilitata la tipologia di documento "Fattura accompagnatoria"',
        SalInvoiceLabelRequired:
            'L\'etichetta delle fatture in acconto è obbligatoria se sul protocollo è abilitata la tipologia di documento "Fattura in acconto"',
        DocumentLabelRequired: "L'etichetta del documento è obbligatoria",

        ConfirmScheduleCalculationSettingsNotComplete:
            "Attenzione! Le percentuali specificate non raggiungono il 100%, quindi l'importo totale del documento non sarà coperto dalle scadenze generate. Continuare?",

        ExpiresIntoNotesDefaultPrefix: "Scadenze: >>>",
        ExpiresIntoNotesDefaultSuffix: "<<<",

        MonthlyInvoicingWizard: "Fatt. mensile",
        MonthlyInvoicingWizardTitle: "Fatturazione massiva",
        MonthlyInvoicingWizardGroupingStepTitle: "Gruppi",
        MonthlyInvoicingWizardInvoicingFirstStepTitle: "Fatturazione",
        MonthlyInvoicingWizardInvoicingSecondStepTitle: "Riepilogo",
        MonthlyInvoicingWizardLog: "Log fatturazione mensile",
        MonthlyInvoicingWizardLogTitle: "Log fatturazione mensile",
        InvoicingOperationsLog: "Reg. fatturazione",
        LogsButtonLabel: "Reg. Operazioni",
        ReportsButtonLabel: "Riepilogo",
        DocumentName: "{0} {1} del {2}",
        WorkedHoursWorkPeriodString: "Dal {0} al {1} compresi",
        DdtsSelectionRequired: "Selezionare almeno un documento",
        InvalidDdtLabelForMonthlyInvoicing:
            "Non è possibile assegnare l'etichetta al documento. Controllare che cliente, commessa, modalità di pagamento e scadenza di pagamento siano compatibili con gli altri documenti a cui è assegnata l'etichetta",
        InvalidWorkedHoursLabelForMonthlyInvoicing:
            "Non è possibile assegnare l'etichetta alle ore a rendicontazione perché sono state lavorate su una commessa diversa da quella associata all'etichetta selezionata.",
        InvalidPartialInvoiceLabelForMonthlyInvoicing:
            "Non è possibile assegnare l'etichetta al documento. Controllare che cliente e modalità e scadenza di pagamento siano compatibili con gli altri documenti che hanno la stessa etichetta",
        InvalidDocumentsForLabelApplication:
            "Non è stato possibile applicare l'etichetta ai seguenti documenti: {0} Verificare che i documenti siano compatibili con il documento di partenza.",
        InvalidDocumentsForExpiryApplication:
            "Non è stato possibile applicare la scadenza di pagamento ai seguenti documenti: {0} Verificare che i documenti siano compatibili con il documento di partenza.",
        InvalidDocumentsForPaymentApplication:
            "Non è stato possibile applicare la modalità di pagamento ai seguenti documenti: {0} Verificare che i documenti siano compatibili con il documento di partenza.",
        VatRegisterRequiredForMonthlyInvoicing:
            "I seguenti documenti non hanno un protocollo di destinazione: {0} Impostare il protocollo per proseguire.",
        DocumentExpireRequiredForMonthlyInvoicing:
            "I seguenti documenti non hanno una scadenza di pagamento: {0} Impostare la scadenza di pagamento per proseguire.",
        DocumentPaymentRequiredForMonthlyInvoicing:
            "I seguenti documenti non hanno una modalità di pagamento: {0} Impostare la modalità di pagamento per proseguire.",
        MixedJobOrderInvoice: "Commesse miste",
        MonthlyInvoicingWizardError: "Si è verificato un errore durante la generazione dell'elenco dei documenti.",
        MonthlyInvoicingGenerationSuccess: "Generazione fatture terminata con successo",
        //MonthlyInvoicingGenerationWarnings: "Le seguenti fatture non sono state emesse: {0} Verificare il fido dei clienti.",
        MonthlyInvoicingGenerationWarnings:
            "Non è stato possibile generare alcuni documenti. Consultgare il log per maggiori dettagli.",
        MonthlyInvoicingGenerationError:
            "Si è verificato un errore durante la generazione. Impossibile produrre i documenti.",
        InvalidPaymentExpiryForMonthlyInvoicing:
            "Non è possibile assegnare la scadenza di pagamento al documento perché non è compatibile con le scadenze dei documenti che hanno la stessa etichetta",
        InvalidPaymentTypeForMonthlyInvoicing:
            "Non è possibile assegnare la modalità di pagamento al documento perché non è compatibile con le modalità di pagamento dei documenti che hanno la stessa etichetta",
        InvalidPaymentABIForMonthlyInvoicing:
            "Non è possibile assegnare il codice ABI al documento perché non è compatibile con i codici dei documenti che hanno la stessa etichetta",
        InvalidPaymentCABForMonthlyInvoicing:
            "Non è possibile assegnare il codice CAB al documento perché non è compatibile con i codici dei documenti che hanno la stessa etichetta",
        InvalidPaymentIBANForMonthlyInvoicing:
            "Non è possibile assegnare il codice IBAN al documento perché non è compatibile con i codici dei documenti che hanno la stessa etichetta",
        DocumentExpireRequiredForPartialInvoices:
            "Le fatture parziali con etichetta {0} non hanno una scadenza di pagamento",
        DocumentPaymentRequiredForPartialInvoices:
            "Le fatture parziali con etichetta {0} non hanno una modalità di pagamento",
        InvoicesGroupsWithInconsistentDataError:
            "Le fatture parziali con etichetta {0} presentano incongruenze relative a modalità e scadenze di pagamento",
        InconsistentPaymentData: "Valori incongruenti",
        ImportDdtsWarning: "Uno o più DDT sono stati ignorati perché precedentemente importati",
        TrustAutorization: "Autorizzazione Fido",
        TrustAutorizationMailSubject: "Autorizzazione Fido",

        MonthlyInvoicingErrorsDialogTitle: "Documenti non generati",
        MonthlyInvoicingErrorsDialogMessage: "Non è stato possibile generare i seguenti documenti.",

        MonthlyInvoicingGenerationQueuedState: "In coda",
        MonthlyInvoicingGenerationGeneratedState: "Generato",
        MonthlyInvoicingGenerationNotGeneratedForInvalidTrustBalanceState: "Non generato: fido cliente in negativo",
        MonthlyInvoicingGenerationNotGeneratedForUnexpectedError: "Non generato: errore server",
        MonthlyInvoicingLogMailAborted: "La mail non è stata inviata perché l'utente ha scelto di non inviarla",
        MonthlyInvoicingLogMailSendSuccess:
            "Effettuato un tentativo di invio mail a tutti gli indirizzi correttamente configurati per l'invio.",
        MonthlyInvoicingLogMailSendPartial:
            "Effettuato un tentativo di invio mail a tutti gli indirizzi correttamente configurati per l'invio ma alcuni hanno generato un errore.",
        MonthlyInvoicingLogMailSendError: "Si è verificato un errore; nessun tentativo di invio mail è stato fatto.",
        MonthlyInvoicingLogMailSendRefused: "L'utente ha annullato l'invio della mail.",
        MisssingLabels:
            "Una o più righe non hanno un'etichetta assegnata. E' possibile utilizzare la funzione di rigenerazione etichette oppure inserirle singolarmente.",

        CustomerProtocolDefaultValue: "Default di protocollo del cliente",
        JobOrderProtocolDefaultValue: "Default di protocollo della commessa",
        CustomerDefaultValue: "Default generale del cliente",
        JobOrderDefaultValue: "Default generale di commessa",
        LetterOfAttemptDefaultValue: "Lettere d'intento configurate sul cliente",

        ExpiriesExtensionsDialogTitle: "Proroghe su scadenze di pagamento",
        ExpiriesExtensionsDialogError: "Dati errati o mancanti sulle proroghe pagamenti",

        ExpiriesExtensionSaved: "Salvataggio riuscito",
        ExpiriesExtensionSavingError: "Si è verificato un errore durante il salvataggio",

        ScheduleCalculationInvalidTrhesholdValue:
            "L'importo della fattura deve essere positivo e maggiore di quello specificato per la rata precedente",
        ScheduleCalculationInvalidDaysNumberSinceDocumentIssueValue:
            "Il numero di giorni trascorsi dall'emissione del documento deve essere positivo e maggiore del valore specificato per la rata precedente",

        ConfirmLabelsRegeneration: "Vuoi rigenerare le etichette?",
        NoButton: "No",
        YesButton: "Si",
        MissingLabel: "Da applicare",

        CustomerWithPendingAuthorizationRequestText:
            "E' già presente una richiesta di autorizzazione per questo cliente in data {0} da parte di {1}. Attendere che il procedimento sia completo per avviare una nuova richiesta.",
        CustomerWithPendingAuthorizationRequestOnEvalText:
            "E' già presente una richiesta di autorizzazione per questo cliente in data {0} da parte di {1}. La richiesta è stata presa in carico da {2}. Attendere che il procedimento sia completo per avviare una nuova richiesta.",
        CustomerWithPendingAuthorizationRequestLabel: "Autorizzazione in corso...",

        MissingVatRegisterGroupLabel: "Etichetta del gruppo mancante sul registro {0}",
        MissingVatRegisterPosition: "Posizione del provider mancante sul registro {0}",

        InvoicesGroupLabel: "Fatture",
        DdtsGroupLabel: "DDT",
        EstimatesGroupLabel: "Preventivi",
        CustomerOrdersGroupLabel: "Ordini cliente",
        SalsGroupLabel: "Sal/Rendicontazioni",
        SupplierOrdersGroupLabel: "Ordini fornitore",
        WarehouseLoadsGroupLabel: "Carichi di magazzino",

        PositionIndexAlreadyInUse: "I registri {0} e {1} hanno lo stesso indice di posizione.",
        PositionIndexError: "Indice di posizione errato sul registro {0}. Usare un numero positivo.",
        DocumentNotFound: "Il documento cercato non esiste o è stato eliminato.",

        DeleteInvoiceFromMonthlyInvoicingList: "Sei sicuro di voler escludere questa fattura dalla lista?",
        DeleteInvoiceFromMonthlyInvoicingListCancel: "Annulla",
        DeleteInvoiceFromMonthlyInvoicingListConfirm: "Escludi",

        MP01_Cash: "contanti",
        MP02_BankCheck: "assegno",
        MP03_CashiersCheck: "assegno circolare",
        MP04_OperatingCash: "contanti presso Tesoreria",
        MP05_CreditTransfer: "bonifico",
        MP06_MoneyOrder: "vaglia cambiario",
        MP07_BankBulletin: "bollettino bancario",
        MP08_PaymentCard: "carta di pagamento",
        MP09_Rid: "RID",
        MP10_RidUtilities: "RID utenze",
        MP11_RidFast: "RID veloce",
        MP12_Riba: "RIBA",
        MP13_Mav: "MAV",
        MP14_StateTreasuryDischarge: "quietanza erario",
        MP15_GiroPayments: "giroconto su conti di contabilità speciale",
        MP16_BankDirectDebit: "domiciliazione bancaria",
        MP17_PostalDirectDebit: "domiciliazione postale",
        MP18_PostalBulletin: "bollettino di c/c postale",
        MP19_SepaDirectDebit: "SEPA Direct Debit",
        MP20_SepaDirectDebitCore: "SEPA Direct Debit CORE",
        MP21_SepaDirectDebitB2B: "SEPA Direct Debit B2B",
        MP22_RetentionOnAlreadyColectedAmounts: "Trattenuta su somme già riscosse",

        N1_ExcludedExArt15: "N1 - escluse ex art. 15",
        N2_NotSubject: "N2 - non soggette",
        N3_NotTaxable: "N3 - non imponibili",
        N4_VatExempt: "N4 - esenti",
        N5_MarginScheme: "N5 - regime del margine / IVA non esposta in fattura",
        N6_ReverseCharge:
            "N6 - inversione contabile (per le operazioni in reverse charge ovvero nei casi di autofatturazione per acquisti extra UE di servizi ovvero per importazioni di beni nei soli casi previsti)",
        N7_VatIncurred:
            "N7 - IVA assolta in altro stato UE (vendite a distanza ex art. 40 c. 3 e 4 e art. 41 c. 1 lett. b,  DL 331/93; prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-sexies lett. f, g, art. 74-sexies DPR 633/72)",

        ImmediateVatChargeability: "I - immediata",
        PostponedVatChargeability: "D - differita",
        SplitPaymentVatChargeability: "S - scissione pagamenti",

        ReasonForPaymentRequired: "Inserire la causale di pagamento per la ritenuta d'acconto",

        ChooseInvoiceExportMessage: "Si desidera esportare in Excel oppure per fattura elettronica?",
        ElectronicInvoicingOnExport: "Esportazione in corso...",

        ElectronicInvoicing: "Fatt. elettronica",
        ElectronicInvoicingDataSource: "Documenti",

        ElectronicInvoicingDuplicatedOnSelection:
            "Alcuni documenti non sono stati importati perché già selezionati in precedenza",
        ElectronicInvoicingDocumentsSelectionEmpty: "Selezionare almeno un documento",

        ElectronicInvoicingOperation: "Fatturazione elettronica",
        MonthlyInvoicingOperation: "Fatturazione massiva",
        PassiveInvoiceImportOperation: "Importazione Fatture Passive",
        MailSent: "Invio mail di cortesia",

        ElectronicInvoicingAccessDenied: "Non si dispone dei diritti per accedere al servizio",

        RelatedDocumentInfoLoaded: "Documenti collegati caricati.",
        RelatedDocumentInfoAlreadyLoaded:
            "Uno o più documenti erano già presenti in tabella e non sono stati aggiunti.",
        RelatedDocumentInfoEmptyRefsError: "Importare documenti prima di utilizzare questa funzionalità",

        ElectronicAndAccompanyingInvoiceEnabledMessage:
            "Attenzione!! Il sistema di fatturazione elettronica non prevede l'emissione di fatture accompagnatorie. La configurazione standard prevede l'emissione di fatture non accompagnatorie. Premendo OK la fattura accompagnatoria verrà disabilitata.",
        ElectronicAndAccompanyingInvoiceEnabledCancelButton: "Continua comunque",
        ElectronicAndAccompanyingInvoiceEnabledConfirmButton: "Ok",

        OnVatRegisterForPartialInvoicesChangesWarningMessage:
            "ATTENZIONE!! Sono già stati emessi documenti su questo protocollo. Cambiando ora questa impostazione potrebbero crearsi incongruenze nei dati del sistema. Continuare?",
        OnVatRegisterForPartialInvoicesChangesBlockingMessage:
            "ATTENZIONE!! Non è possibile effettuare questa operazione perché sono già stati emessi documenti su questo protocollo.",
        OnVatRegisterForPartialInvoicesChangesBlockingMessageLabel: "Operazione non consentita",
        OnVatRegisterForPartialInvoicesChangesBlockingMessageCancel: "Annulla",
        OnVatRegisterForPartialInvoicesChangesBlockingMessageConfirm: "Continua",

        OnVatRegisterInventoryAdjustmentChangesWarningMessage:
            "ATTENZIONE!! Sono già stati emessi documenti su questo protocollo. Cambiando ora questa impostazione potrebbero crearsi incongruenze nei dati del sistema. Continuare?",
        OnVatRegisterInventoryAdjustmentChangesBlockingMessage:
            "ATTENZIONE!! Non è possibile effettuare questa operazione perché sono già stati emessi documenti su questo protocollo.",
        OnVatRegisterInventoryAdjustmentChangesBlockingMessageLabel: "Operazione non consentita",
        OnVatRegisterInventoryAdjustmentChangesBlockingMessageCancel: "Annulla",
        OnVatRegisterInventoryAdjustmentChangesBlockingMessageConfirm: "Continua",

        ScheduleCalculationSettingsSaved: "Impostazioni salvate con successo.",

        StampDutyRequired: "Protocollo {0} - Il valore del bollo deve essere maggiore di zero se il bollo è abilitato.",
        SALInvoices: "Fatture in acconto",
        SALInvoice: "Fattura in acconto",
        SALInvoicesDataSourceTitle: "Chiusura fatture in acconto",

        PreferredRecipientCodeTitle: "Da unità organizzativa di commessa",

        SchedulesRebuildRequestTitle: "Generazione scadenze per la fattura",
        PublicAdministrationInvoiceWithoutSplitPaymentRows:
            "Nessun codice IVA con scissione dei pagamenti (split-payment) su fattura per Pubblica Amministrazione. Continuare?",
        PublicAdministrationInvoiceWithNoSplitPaymentRows:
            "Ci sono righe con IVA imponibile senza scissione dei pagamenti su fattura per Pubblica Amministrazione. Continuare?",
        PublicAdministrationRowsWithNoSplitPayment:
            "Tutte le righe con IVA Imponibile del documento dovrebbero essere soggette a scissione dei pagamenti (Split Payment). Continuare?",

        VatRegister: "Protocollo",

        BalanceSheet: "Conto patrimoniale",
        IncomeStatement: "Conto economico",
        Accounts: "Conti d'ordine",

        MissingLetterofAttemptOnInvoiceRow: "La riga {0} non ha una lattera d'intento assegnata",
        MissingLetterOfAttemptOnRows: 'Le righe "{0}" non hanno una lattera d\'intento assegnata',
        LetterOfAttemptOverflow:
            "Il residuo della lettera d'intento \"{0}\" non è sufficiente a coprire l'importo delle righe associate in fattura: residuo: {1}, totale righe associate: {2}",
        LetterOfAttemptOverflowEx:
            "Il residuo della lettera d'intento \"{0}\" non è sufficiente a coprire l'importo delle righe associate in fattura per {1} €",
        InvalidLetterOfAttemptSelected: 'La lettera d\'intento "{0}" non è valida alla data del documento',
        InvalidLettersOfAttemptSelected: 'Le lettere d\'intento "{0}" non sono valide alla data del documento',
        LetterOfAttemptNotFound:
            'La lettera d\'intento "{0}" non è stata trovata. Verificare che non sia scaduta o eliminata.',
        LettersOfAttemptNotFound:
            'Le lettere d\'intento "{0}" non sono stata trovate. Verificare che non siano scadute o eliminate.',

        GetLettersOfAttemptsError: "Si è verificato un errore durante la lettura delle lettere d'intento del cliente",
        LetterOfAttemptSelectorTitle: "Lettera d'intento",

        InvoiceNotesLettersOfAttemptsSectionStart: ">>> L.I.:",
        InvoiceNotesLetterOfAttemptDescription: "Lettera d'intento {0} del {1}",
        InvoiceNotesLettersOfAttemptsSectionEnd: "L.I. <<<",
        InvoiceNotesStampDutyIndication: "Bollo assolto ai sensi del Decreto MEF 17.06.2014 (articolo 6)",

        LetterOfAttemptOverflowOnCreditNote:
            "La lettera d'intenti \"{0}\" avrà un valore totale superiore rispetto all'originale: valore: {1}; valore dopo l'emissione del documento: {2}",
        ExpiryModeNotConfiguredAlert:
            "ATTENZIONE!! La scadenza di pagamento selezionata non ha una configurazione per il calcolo automatico delle scadenze, quindi le scadenze non verranno generate. Continuare?",

        ExportElectronicInvoice: "F. Elettr.",
        OtherDocumentActions: "Avanzate",

        DuplicateReferenceNumberFound:
            "Esiste già un documento con lo stesso numero di riferimento esterno, salvare ugualmente il documento?",
        DuplicateReferenceNumberFoundCancel: "Non salvare",
        DuplicateReferenceNumberFoundConfirm: "Salva",

        OverwriteResponsibles:
            "Vuoi sovrascrivere i responsabili amministrativo e commerciale con i dati della commessa?",

        DocumentNameForDataSource: "{0} n° {1} del {2} ({3})",
        DocumentRelatedWorkflows: "Piani di lavoro",

        DocumentRevisionNumbersSeggestions: "Numeri di revisione documento",

        AutoFromProtocolVersionNumber: "Genera dal progressivo di protocollo",
        AutoFromVersionNumber: "Genera versione e revisione",
        ManualInsertVersionNumber: "Inserisci manualmente",

        PrintOnlyDocumentProtocolNumber: "Stampa solo il numero di protocollo",
        PrintOnlyDocumentVersionNumber: "Stampa solo il numero di versione",
        PrintAllDocumentNumbers: "Stampa tutti i numeri",

        VersionNumberNotAvailable: "Nessun numero di versione",
        EstimatesSelectionRequired: "Selezionare almeno un preventivo",

        InvalidEstimateStateForImportIntoCustomerOrder:
            "Alcuni preventivi non sono stati importati perché non accettati. Solo preventivi in stato accettato possono essere importati in ordini cliente.",

        VersionNumberRequired: "Inserire il numero di versione",
        VersionRevisionRequired: "Inserire il numero di revisione",

        CurrenciesSettingsManagerLabel: "E.170 Valute",
        CurrenciesLabel: "Valute",
        Currency: "{0} ({1})",
        CurrencyLabel: "Valuta",
        CurrencyFormLabel: "Dati valuta",

        InvalidCurrencyCodeError: "Codice valuta mancante o errato (deve essere lungo 3 caratteri).",
        InvalidCurrencySymbolError: "Simbolo valuta mancante.",
        SaveCurrencySuccess: "Valuta salvata correttamente.",
        CurrencyDeleteConfirmMessage: "Sei sicuro di voler eliminare questa valuta?",
        CurrencyDeleteSuccess: "Valuta eliminata correttamente.",
        CurrencyPendingChangesMessage: "ATTENZIONE!! Le modifiche non salvate andranno perse. Continuare?",

        CurrencyCodeFormLabel: "Codice valuta",
        CurrencyCodeHelpText:
            "Il codice della valuta deve essere uno dei codici definiti dallo standard ISO 4217 alpha-3:201.",
        CurrencySymbolFormLabel: "Simbolo valuta",
        CurrencyNameFormLabel: "Descrizione",

        NewCurrencyButton: "Nuovo",
        DeleteCurrencyButton: "Elimina",
        SaveCurrencyButton: "Salva",

        CurrencyFractionalUnitFormLabel: "Unità base",
        CurrencyCountriesLabel: "Nazioni",

        CurrencyCountryAssociationError:
            "Errore nell'associazione tra nazioni e valute. Verificare di aver selezionato correttamente tutte le nazioni.",

        EmptyCurrencyCountriesAssociation: "Nessuna associazione tra valuta e nazioni.",

        DocumentCurrencyTableLabel: "Valuta del documento",
        DocumentCurrencyToDefaultCurrencyExchangeValue: "Valore di cambio",
        DocumentCurrencyToDefaultCurrencyExchangeValueForVat: "Valore di cambio per calcolo IVA",

        DocumentCurrencyToDefaultCurrencyExchangeValueLabel: "Cambio {0} -> {1}",
        DocumentCurrencyToDefaultCurrencyExchangeValueForVatLabel: "Cambio {0} -> {1}",

        DocumentCurrenciesManagerHelpText:
            'Il valore specificato nel campo "Valore di cambio" è il valore utilizzato per effettuare le operazioni di cambio sulle righe del documento, i totali e le scadenze di pagamento dove previste. Il valore specificato come "Valore di cambio per calcolo IVA" viene utilizzato solamente per le operazioni di cambio effettuate nel calcolo dell\'imposta. Entrambi i valori sono da intendersi come valore di cambio verso la valuta di sistema (€).',
        DocumentCurrenciesManagerOtherCurrenciesHelpText:
            "I valori specificati nella tabella seguente indicano i cambi da utilizzare per effettuare le conversioni da una qualunque valuta verso la valuta scelta come valuta di documento. Questi valori sono necessari nel caso si voglia importare nel documento del materiale in una valuta diversa da quella del documento stesso (per esempio, importazioni da wizard o copia tra documenti).",

        CurrencyToDocumentCurrencyExchangeValue: "Cambio verso valuta documento",
        EmptyCurrenciesList: "Nessuna valuta configurata",
        OtherCurrencies: "Altre valute",

        CurrencyExchangeNotConfigured: "Cambio valuta non impostato : {0}",
        CurrencySelectionRequired: "Selezionare la valuta!",
        CurrencyExchangeValueRequired: "Il valore di cambio della valuta {0} non è valido.",
        CurrencyExchangeValueForVatRequired:
            "Il valore di cambio per il calcolo IVA della valuta di documento non è valido.",
        InvalidCurrenciesAlert: "Una o più valute hanno dei valori errati.",

        MonthlyInvoicingDocumentCurrenciesErrors:
            "I cambi impostati sulla {0} {1} per la valuta {2} non sono corretti.",
        MonthlyInvoicingDocumentCurrenciesErrorsInvoice: "fattura",
        MonthlyInvoicingDocumentCurrenciesErrorsPartialInvoice: "fattura parziale",
        MonthlyInvoicingMissingCurrencyOnInvoice:
            "La fattura {0} non ha un cambio valido per la valuta {1} della fattura paziale {2}.",

        FastForwardButtonLabel: "Vai al riepilogo",
        FastForwardButtonForInvoiceOrDdt: "Vai alla selezione IVA",

        VersionNumberSuggestion: "Ultima revisione: {0} - {1}{2}",

        DocumentsMenuTitle: "Tipologie",
        DocumentMessrs: "Spett:",
        DocumentSearchCustomerPlaceholder: "Digita il nome dell'intestatario per ricercarlo...",
        DocumentSelectProvince: "Seleziona la Provincia...",
        DocumentSelectNation: "Seleziona la Nazione...",
        DocumentPhysicalPerson: "Pers. fisica",
        DocumentLegalPerson: "Pers. giuridica",
        DocumentFilterJobOrders: "Filtra commesse",
        DocumentShowAllCustomers: "Mostra tutti i clienti",
        DocumentElectronic: "Elettronica",
        DocumentWithStampDuty: "Con bollo",
        DocumentIncludeAttachments: "Includi gli allegati in F.E.",
        DocumentDownPayment: "Fattura in acconto",
        DocumentEnableSecondaryRecipients: "Abilita cointestatari",
        DocumentForPublicAdministration: "Fattura PA",
        DocumentEnableTaxReliefFlagLabel: "Agev. fiscali",
        DocumentCreditNote: "Nota di Credito",
        DocumentAccompanyingInvoice: "Fattura accompagnatoria",
        DocumentWithWithholdingTax: "Con ritenuta d'acconto",
        DocumentShowAmounts: "Mostra importi",
        DocumentDate: "Data:",
        DocumentOpenDocumentJournal: "Apri il giornale documentale",
        DocumentDestinationRecipient: "C. dest.",
        DocumentDestinationRecipientCode: "Cod. destinatario",
        DocumentDestinationRecipientPEC: "PEC",
        DocumentPayment: "Pagamento",
        DocumentLoadFromSystem: "Carica da sistema",
        DocumentSelect: "Seleziona...",
        DocumentMode: "Modalità:",
        DocumentIBAN: "IBAN:",
        DocumentABI: "ABI:",
        DocumentCAB: "CAB:",
        DocumentExpiry: "Scadenza:",
        DocumentExpiryPlaceholder: "Digita la scadenza...",
        DocumentJobOrder: "Commessa:",
        DocumentJobOrderPlaceholder: "Seleziona la commessa di riferimento",
        DocumentJobOrderBlocked: "La commessa è stata segnalata dall'utente",
        DocumentJobOrderWithMotivation: "con la motivazione",
        DocumentOutcome: "Esito:",
        DocumentOutcomePlaceholder: "Seleziona un esito...",
        DocumentCommercialResponsible: "Resp.Comm.:",
        DocumentAdministrativeResponsible: "Resp.Amm.:",
        DocumentSearchPlaceholder: "Cerca...",
        DocumentReferenceNumber: "Num. Rif.:",
        DocumentReferenceDate: "Data Rif.:",
        DocumentExternalReference: "Rif. Est.:",
        DocumentSourceWarehouse: "Magaz.prov.:",
        DocumentDestinationWarehouse: "Magaz.dest.:",
        DocumentSelectPlaceholder: "Seleziona...",
        DocumentAdminNotes: "Note Amministrative:",
        DocumentAdminNotesPlaceholder: "Inserire le note...",
        DocumentNotes: "Note:",
        DocumentNotesPlaceholder: "Inserire le note...",
        DocumentSearchInfo: "Per attivare la ricerca sulle righe digitare @",
        DocumentLastSearch: "Ultima ricerca:",
        DocumentLastSearchIn: "in",
        DocumentSecondaryRecipients: "Cointestatari",
        DocumentNoSecondaryRecipient: "Nessun cointestatario",
        DocumentVATCode: "P.IVA:",
        DocumentVATCodePlaceholder: "Partita IVA",
        DocumentTAXCode: "CF:",
        DocumentTAXCodePlaceholder: "Codice fiscale",
        DocumentAddress: "Indirizzo",
        DocumentPostalCode: "CAP",
        DocumentCity: "Località",
        DocumentMunicipality: "Comune",
        DocumentProvince: "Provincia",
        DocumentProvincePlaceholder: "Seleziona la Provincia...",
        DocumentState: "Nazione",
        DocumentStatePlaceholder: "Seleziona la Nazione...",
        DocumentTaxableTotal: "Totale Imponibile:",
        DocumentTaxTotal: "Totale Imposta:",
        DocumentNonTaxableTotal: "Totale non Imponibile:",
        DocumentTotal: "TOTALE",
        DocumentSplitPaymentInfo: "Fattura soggetta a scissione dei pagamenti ex art. 17-ter del DPR 633/72.",
        DocumentNetTotal: "NETTO A PAGARE:",
        DocumentWithholdingTax: "Ritenuta d'acconto",
        DocumentReasonForPayment: "Causale del pagamento:",
        DocumentFinalTotal: "TOTALE NETTO DA PAGARE:",
        DocumentStampDutyOf: "Bollo di ",
        DocumentStampDutyPlaceholder: "Bollo",
        DocumentStampDutyVirtuallyPaid: "assolto virtualmente",
        DocumentVatRowDescription: "Descrizione aliquota",
        DocumentVatRowNature: "Natura",
        DocumentVatRowChargeableVat: "Esigibilità",
        DocumentVatRowTaxableTotal: "Imponibile",
        DocumentVatRowTaxPercentage: "IVA",
        DocumentVatRowTax: "Imposta",
        DocumentVatRowTaxTotal: "Totale",
        DocumentTaxReliefTotalLabel: "Agevolazioni fiscali:",
        DocumentTotalWithTaxReliefTotalLabel: "TOTALE SCONTATO:",
        DocumentShowImportedDocumentsTable: "Mostra la tabella in stampa",
        DocumentRelatedDocuments: "Documenti correlati",
        DocumentRelatedDocumentsLoadImportedDocuments: "Carica documenti importati",
        DocumentRelatedDocumentsNumber: "Nr. documento",
        DocumentRelatedDocumentsDate: "Data",
        DocumentRelatedDocumentsType: "Tipo",
        DocumentRelatedDocumentsRowNumbers: "Nr. righe",
        DocumentRelatedDocumentsRowNumbersTooltip:
            "Indicare i numeri di riga delle righe della fattura che fanno riferimento al documento imortato, separati da punto e virgola.",
        DocumentRelatedDocumentsCIG: "CIG",
        DocumentRelatedDocumentsCUP: "CUP",
        DocumentRelatedDocumentsNoDocument: "Nessun documento correlato",
        DocumentRelatedDocumentsNotAvailable: "N/A",
        DocumentShippingInfoPackages: "Colli",
        DocumentShippingInfoCause: "Causale",
        DocumentShippingInfoAspect: "Aspetto esteriore dei beni",
        DocumentShippingInfoAspectPlaceholder: "Digita l'aspetto esteriore dei beni...",
        DocumentShippingInfoTransport: "Trasporto a cura",
        DocumentShippingInfoTransportPlaceholder: "Digita chi cura il trasporto...",
        DocumentShippingInfoCausePlaceholder: "Digita la cusale...",
        DocumentShippingInfoCarriage: "Porto",
        DocumentShippingInfoCarriagePlaceholder: "Digita il porto...",
        DocumentShippingInfoWeight: "Peso (kg)",
        DocumentShippingInfoTransportStart: "Inizio trasporto",
        DocumentShippingInfoTransportStartPlaceholder: "Seleziona...",
        DocumentShippingInfoTransporterSignature: "Firma conducente",
        DocumentShippingInfoRecipientSignature: "Firma destinatario",
        DocumentAttachments: "Allegati",
        DocumentAttachmentsAttach: "Allega",
        DocumentAttachmentsDelete: "Elimina",
        DocumentAttachmentsDownload: "Scarica",
        DocumentAttachmentsNoAttachment: "Nessun allegato presente",
        DocumentAttachmentsVersion: "Versione",
        DocumentAttachmentsIncludeInElectronicInvoicing: "Includi in fattura elettronica",
        DocumentDest: "Dest:",
        DocumentIDEM: "IDEM",
        DocumentRowNumber: "#",
        DocumentRowDescription: "Descrizione",
        DocumentRowAmount: "Q.tà",
        DocumentRowUoM: "U.d.M.",
        DocumentRowUnitPrice: "Pr.Un.",
        DocumentRowDiscounts: "Sconti",
        DocumentRowNetUnitPrice: "Pr.Un.Netto",
        DocumentRowVATCode: "C.IVA",
        DocumentRowTotalPrice: "Imp.Netto",
        DocumentRowBulkTotalPrice: "Importo",
        DocumentRowNewRow: "Nuova riga...",
        DocumentAutoPlaceholder: "Auto",
        DocumentVersionNumber: "Num.ver.",
        DocumentCIG: "CIG:",
        DocumentCUP: "CUP:",
        DocumentStatus: "Stato:",
        DocumentValidity: "Validità:",
        DocumentStatusPlaceholder: "Seleziona lo stato",
        DocumentValidityPlaceholder: "Seleziona la validità",
        DocumentDueDateChanges: "Questa data è stata modificata",
        DocumentShowDueDateChanges: "Visualizza lo storico delle modifiche",
        DocumentMethod: "Metodo:",
        DocumentPaymentPlan: "Pagamento a rate",
        DocumentSinglePayment: "Pagamento completo",
        DocumentPaymentLabel: "Pagamento:",
        DocumentBank: "Banca:",
        DocumentTrancheNumber: "Nr. rata",
        DocumentTrancheDescription: "Descrizione",
        DocumentTrancheDueDate: "Scadenza",
        DocumentTrancheTotal: "Importo",
        DocumentNoRelatedDocument: "Nessun documento correlato",
        DocumentPaymentMode: "Modalità di pagamento",
        DocumentSchedules: "Scadenze di pagamento",
        DocumentElectronicInvoice: "Fattura elettronica",
        LayoutSelectionMenuTitle: "Selezione Layout Documento",
        DocumentOffset: "Contropartita",
        DocumentWorkflows: "Piani",

        DocumentWizardTitle: "{0}: Importazione dati",
        DocumentWizardSourceSelectionInformation:
            "Questo primo passo della procedura di importazione dati permette di selezionare le fonti da cui importare i dati nel documento attuale.\r\nCliccando sulle voci seguenti è possibile attivare o disattivare l'importazione dalle singole fonti.",
        DocumentWizardFrom: "Dal",
        DocumentWizardTo: "Al",
        DocumentWizardShowOnlyApprovedHours: "Mostra solo ore approvate",
        DocumentWizardSearch: "Ricerca",
        DocumentWizardSearchPlaceholder: "Cerca...",
        DocumentWizardSelectPlaceholder: "Seleziona...",
        DocumentWizardWorkflow: "Piano",
        DocumentWizardWorkflowPlaceholder: "Filtra per piano...",
        DocumentWizardEditWorkflow: "Modifica Piano",
        DocumentWizardDetailed: "Dettagliate",
        DocumentWizardGrouped: "Raggruppate",
        DocumentWizardGroupedByResource: "Raggruppate per risorsa",
        DocumentWizardGroupedByRole: "Raggruppate per mansione",
        DocumentWizardShowAlreadyImportedHours: "Visualizza rendicontazioni già fatturate/importate",
        DocumentWizardClear: "Svuota",
        DocumentWizardNumber: "Numero",
        DocumentWizardDate: "Data",
        DocumentWizardResource: "Risorsa",
        DocumentWizardRole: "Mansione",
        DocumentWizardCallRight: "D.D.Chiamata",
        DocumentWizardWorkType: "Tipologia",
        DocumentWizardHours: "Ore",
        DocumentWizardNoWorkedHours: "Nessun rendiconto presente",
        DocumentWizardDescription: "Descrizione",
        DocumentWizardAmount: "Q.tà",
        DocumentWizardMultiplierAmount: "Qtà Molt.",
        DocumentWizardImportedAmount: "Q.tà importata",
        DocumentWizardAmountToImport: "Q.tà da importare",
        DocumentWizardUoM: "U.d.m.",
        DocumentWizardManHours: "h/u",
        DocumentWizardUnitPrice: "Prezzo Unit.",
        DocumentWizardUnitPriceShort: "Pr.Unit.",
        DocumentWizardAvailability: "Disponibilità",
        DocumentWizardReserved: "Impegnato",
        DocumentWizardMinimumStock: "Scorta Minima",
        DocumentWizardToOrder: "Da ordinare",
        DocumentWizardDiscount: "Sconto",
        DocumentWizardNetPrice: "Prezzo Netto",
        DocumentWizardNetPriceShort: "Pr.Netto",
        DocumentWizardNetUnitPrice: "Pr.Unit.Netto",
        DocumentWizardVAT: "IVA",
        DocumentWizardSellPrice: "Prezzo Vendita",
        DocumentWizardTotalPrice: "Totale",
        DocumentWizardTotalPriceShort: "Tot.",
        DocumentWizardRemove: "Rimuovi",
        DocumentWizardRemoveTextRows: "Rimuovi righe testuali (q.tà zero)",
        DocumentWizardWorkedHoursInfo:
            "Righe contenenti informazioni integrative. La rimozione potrebbe alterare i risultati delle analisi di commessa.",
        DocumentWizardGroupingInformation:
            "Questa pagina permette di manipolare i dati selezionati prima di importarli.\r\nModificando le righe sotto sar&agrave; possibile presentare un dettaglio diverso nel documento.",
        DocumentWizardNoRowAvailable: "Nessuna riga presente",
        DocumentWizardInsertADescription: "Inserire una descrizione...",
        DocumentWizardImportComposition: "Importa Composizione",
        DocumentWizardImportSingleComposition: "Importa Singola Composizione",
        DocumentWizardImportDecomposition: "Importa Scomposizione",
        DocumentWizardImportSingleDecomposition: "Importa Singola Scomposizione",
        DocumentWizardImportDecompositionClear: "Svuota",
        DocumentWizardViewImporterdPurchases: "Visualizza acquisti già importati",
        DocumentWizardNoPurchases: "Nessun acquisto presente",
        DocumentWizardInvoiceToAccompanyingInvoiceWarning:
            "ATTENZIONE : Si sta cercando di importare delle fatture su una fattura accompagnatoria. Tutte le eventuali variazioni delle quantità di riga apportate in seguito, potrebbero compromettere la validità dei dati di commessa. Si consiglia pertanto di importare le fatture solo se strettamente necessario.",
        DocumentWizardClosureStatus: "Stato di chiusura",
        DocumentWizardNoInvoice: "Nessuna fattura presente",
        DocumentWizardPartiallyClosed: "Chiuso parzialmente",
        DocumentWizardClosed: "Chiuso",
        DocumentWizardOpened: "Aperto",
        DocumentWizardNotAccepted: "Non accettato",
        DocumentWizardManuallyClosed: "Chiusa manualmente",
        DocumentWizardInDestinationDocument: "Presente nel documento di destinazione",
        DocumentWizardImportRows: "Importa righe",
        DocumentWizardSearchInvoicesWithNoJobOrder: "Cerca anche le fatture senza commessa",
        DocumentWizardJobOrder: "Commessa",
        DocumentWizardSelectJobOrder: "Seleziona la commessa di riferimento",
        DocumentWizardCustomer: "Cliente",
        DocumentWizardSupplier: "Fornitore",
        DocumentWizardStatus: "Stato",
        DocumentWizardApplyStateToEstimates: "Applica lo stato ai preventivi selezionati",
        DocumentWizardSearchEstimatedWithNoJobOrder: "Cerca anche i preventivi non associati ad una commessa",
        DocumentWizardShowNotAcceptedEstimates: "Mostra preventivi non accettati",
        DocumentWizardNoEstimate: "Nessun preventivo presente",
        DocumentWizardSearchDDTWithNoJobOrder: "Cerca anche i DDT senza commessa",
        DocumentWizardDDTToAccompanyingInvoiceWarning:
            "ATTENZIONE : Si sta cercando di importare dei DDT su una fattura accompagnatoria. Tutte le eventuali variazioni delle quantità di riga apportate in seguito, potrebbero compromettere la validità dei dati di commessa. Si consiglia pertanto di importare i DDT solo se strettamente necessario.",
        DocumentWizardNoDDTAvailable: "Nessun DDT presente",
        DocumentWizardWarehouseWorkflow: "Piano del Magazzino",
        DocumentWizardSearchArticleLabel: "Articolo",
        DocumentWizardSearchArticlePlaceholder: "Digita qui per ricercare un articolo...",
        DocumentWizardWarehouse: "Magazzino",
        DocumentWizardSearchOrdersWithNoJobOrder: "Cerca anche ordini non associati ad un progetto (Tutti i Clienti)",
        DocumentWizardShowReferenceToCustomerOrder: "Mostra il riferimento all'ordine cliente nel documento finale",
        DocumentWizardShowReferenceToEstimate: "Mostra il riferimento al preventivo nel documento finale",
        DocumentWizardShowExternalReference: "Mostra il riferimento esterno nel documento finale",
        DocumentWizardNoOrder: "Nessun ordine presente",
        DocumentWizardNoWarehouseLoad: "Nessun carico presente",
        DocumentWizardReferenceDate: "Data di riferimento",
        DocumentWizardTooManyArticlesWarning:
            "Attenzione! Si sta lavorando sul magazzino predefinto, l'inventario articoli non è stato caricato automaticamente perchè potrebbe richiedere alcuni minuti.\r\nPer caricare l'inventario premere il pulsante \"Ricarica Inventario\".",
        DocumentWizardImportSelected: "Importa Selezionati",
        DocumentWizardReloadStock: "Ricarica Inventario",
        DocumentWizardRemoveAll: "Rimuovi Tutto",
        DocumentWizardNoArticle: "Nessuna articolo presente",
        DocumentWizardShowOrdersFromAllSuppliers: "Mostra ordini di tutti i fornitori",
        DocumentWizardNoSAL: "Nessun SAL presente",
        DocumentWizardEstimatedExpenses: "Spese previste",
        DocumentWizardByType: "Per Tipologia",
        DocumentWizardByActivity: "Per Attività",
        DocumentWizardNoWork: "Nessuna lavorazione prevista",
        DocumentWizardWork: "Lavorazioni",
        DocumentWizardPurchases: "Acquisti",
        DocumentWizardArticle: "Articolo",
        DocumentWizardImportedRows: "Righe Importate",
        DocumentWizardDecompositionWithEstimate: "Scomposizione con Stima",
        DocumentWizardDecompositionWithEffective: "Scomposizione Reale",
        DocumentWizardCompositionWithEstimate: "Composizione con Stima",
        DocumentWizardCompositionWithEffective: "Composizione Reale",
        DocumentWizardVatCode: "Codice IVA",
        DocumentWizardSetVatCode: "Imposta",
        DocumentWizardPrintableVersion: "Versione stampabile",
        DocumentWizardReportInformation:
            "Questa pagina presenta un riepilogo delle scelte effettuate nei passi precedenti.\r\nLe seguenti righe verranno importate nella Fattura.",
        DocumentWizardCurrentDate: "Data corrente",

        NotManagedBillingStateLabel: "NG",
        NotManagedBillingStateTitle: "Non Gestito",

        NotBilledBillingStateLabel: "NF",
        NotBilledBillingStateTitle: "Non Fatturato",

        PartiallyBilledBillingStateLabel: "PF",
        PartiallyBilledBillingStateTitle: "Parzialmente Fatturato",

        BilledBillingStateLabel: "F",
        BilledBillingStateTitle: "Fatturato",

        OpenedDocumentsMenuTitle: "Documenti aperti",

        VatNatureCodeLabel: "{0} - {1}",
        VatRegulationCodeLabel: "{0} - {1}",
        WithholdingTaxTypeLabel: "{0} - {1}",

        ItalianCustomerNationality: "Clienti residenti in Italia",
        ForeignCustomerNationality: "Clienti non residenti in Italia",
        DocumentAttachmentsTotalSizeLimitMessage:
            "Gli allegati del documento superano la dimensione massima ({0}), di conseguenza non saranno allegati alla mail",
        DocumentAttachmentSizeLimitMessage:
            "I seguenti allegati superano la dimensione massima consentita ({0}), di conseguenza non saranno allegati alla mail:",
        DocumentAttachmentTooLargeRow: "{0}: {1}",
        ConfirmDocumentSendMailTitle: "Conferma invio mail",
        DocumentMailSendMissingPdfError: "Il PDF del documento non è ancora disponibile, riprovare più tardi.",
        DocumentMailSendMissingDocumentError: "Il documento richiesto non è stato trovato.",
        ConfirmDocumentSendMailPositiveMessage: "Vuoi inviare una e-mail di notifica al cliente?",
        ConfirmDocumentSendMailNegativeMessage:
            "Non è possibile effettuare l'invio della mail a causa dei seguenti problemi:",
        LastMailSentDateMessage: "Ultima mail inviata il ",
        MetadataLogicalTypeWithNoDataMessage:
            "Non sono stati trovati indirizzi e-mail associati alle seguenti tipologie:",
        DocumentMailSendRequested: "La procedura di invio e-mail è stata avviata correttamente.",
        PaymentTypeCodeLabel: "{0} - {1}",

        AlreadyEditingAnotherDocument:
            "Impossibile creare un nuovo documento perchè un altro documento è già in modifica. Chiudere prima il documento in modifica!",
        NoJobOrder: "Nessuna commessa selezionata",
        NoCustomer: "Nessun cliente selezionato",
        ElectronicInvoiceTypes: "Tipologie di fattura",
        ElectronicInvoiceTypeLabel: "{0} - {1}",
        ElectronicInvoiceTypeFieldLabel: "Tipologia",
        AmountFormulaPlaceholder: "Testo Misure",

        MissingRows: "Non è possibile salvare un documento senza righe. Aggiungere almeno una riga al documento!",

        LetterOfAttemptValidVatNotFound:
            "ATTENZIONE!! Il cliente ha una lettera d'intento valida alla data del documento, ma non è stato trovato nessun codice IVA configurato per lettera d'intento.",
        LetterOfAttemptVatCodeRequired:
            "ATTENZIONE!! Il cliente ha una lettera d'intento valida alla data del documento, ma è presente almeno una riga con un codice IVA non valido per lettera d'intento (codice IVA {0})",

        CreateWarehouseInspection: "Verifica di magazzino",
        WarehouseInspectionCreationSuccess: "Verifica di magazzino creata con successo",
        MetadatasSettingsManagerName: "E.040 Campi personalizzati del documento",
        MetadatasLoadingError: "Si è verificato un errore durante il caricamento dei metadati dei documenti (settings)",

        NewMetadataDefaultLabel: "Nuovo metadato",
        ConfirmMetadataDelete: "Sei sicuro di voler cancellare questo metadato?",

        TextMetadataLabel: "Testo",
        IntegerMetadataLabel: "Numero intero",
        DecimalMetadataLabel: "Numero con virgola",
        DateTimeMetadataLabel: "Data",
        ListMetadataLabel: "Lista",
        AddMetadata: "Nuovo metadato",

        MetadataLabelColumn: "Etichetta",
        MetadataValueTypeColumn: "Tipo dato",
        MetadataConfigurationColumn: "Configurazione",
        ShowMarkerOnAllocationsGantt: "Mostra un marker sul gant di allocazioni",
        PreloadOnDocument: "Precarica sul documento",

        DocumentResourceColumnTitle: "Risorsa",
        DocumentResourceHoursColumnTitle: "Nr. ore",
        DocumentResourceStartDateColumnTitle: "Inizio periodo",
        DocumentResourceEndDateColumnTitle: "Fine periodo",
        DocumentResourcesNotesColumnTitle: "Note",
        DocumentResourcesRatioTitle: "Peso",
        MissingMetadata: "Metadato mancante! Controllare di aver selezionato un metadato su ogni riga.",

        MissingResource: "Risorsa mancante! Controllare di aver selezionato una risorsa su ogni riga.",
        MissingResourceStartDate:
            "Data inizio mancante! Controllare di aver impostato la data d'inizio su ogni riga di risorsa.",
        MissingResourceEndDate:
            "Data fine mancante! Controllare di aver impostato la data di fine su ogni riga di risorsa.",
        InvalidResourceHoursAmount:
            "Numero di ore errato o mancante! Controllare di aver impostato un numero di ore maggiore di zero su ogni riga di risorsa.",
        LoadDefaultMetadatasTitle: "Carica e applica i metadati dalla catena dei default",

        SaveMetadatas: "Salva",
        SaveMetadatasSuccess: "Salvataggio riuscito",

        ShowResourcesNotesTooltip: "Espandi le note",

        DocumentResourcesHoursCalculator: "Calcolo ore",
        ResourceHoursAmountModeLabel: "Modalità",

        ResourceWeeklyHoursCalculation: "hh/sett.",
        ResourceMonthlyHoursCalculation: "hh/mese",
        ResourcePercentageHoursCalculation: "% su orario",
        ResourceHoursCalculationValue: "Valore",

        Apply: "Applica",
        InvalidResourceDates: "La data d'inizio periodo non può essere successiva alla data di fine.",
        DocumentResourceHoursAlert:
            "Il numero di ore impostato supera quello previsto dallo statino orario della risorsa",
        DocumentResourceHoursAlertTitle: "ATTENZIONE!!",

        ConfigureMetadataListValues: "Configura...",
        MetadatasListValuesEditorTitle: "Configurazione elenco valori",
        MetadataListValueLabelColumn: "Etichetta",
        MetadataListValueLabelPlaceholder: "Etichetta",
        MetadataListValueDeleteConfirm: "Sicuro di voler eliminare questo valore dalla lista?",
        MetadataListValuePlaceholder: "Seleziona...",

        OpenDocumentsWithMetadatasReport: "Riepilogo documenti",
        OpenAllocatedResourcesOnDocumentsReport: "Riepilogo risorse",
        OpenResourcesAssignmentsReport: "Riepilogo assegnazioni risorse",
        AllocatedResourcesOnDocumentsReportTitle: "Riepilogo risorse",
        OpenDocumentsWithMetadatasReportTitle: "Riepilogo documenti",
        DocumentNumberColumn: "Nr. doc.",
        DocumentVersionRevisionColumn: "Nr. ver./rev.",
        DocumentDateColumn: "Data doc.",
        RegisterColumn: "Registro",
        CustomerColumn: "Cliente",
        JobOrderColumn: "Commessa",
        ExternalReferenceColumn: "Riferimenti cliente",
        ExternalReferenceDescr: "Rif. est. {0}, data: {1}",
        PaymentColumn: "Mod. pagamento",
        ExpiryColumn: "Scad. pagamento",
        TaxableTotalColumn: "Tot. imponibile",
        VatColumn: "Imposta",
        NotTaxableTotalColumn: "Tot. non imponibile",
        TotalColumn: "Totale",
        AssignedToWorkflowsTotalColumn: "Totale assegnato a piani",
        WaitingToStartTotalColumn: "Totale assegnato a piani in attesa avvio lavori",
        InProgressTotalColumn: "Totale assegnato a piani in lavorazione",
        InProgressTotalCompletedColumn:
            "Totale assegnato a piani in lavorazione relativo all'avanzamento delle attività",
        CompletedTotalColumn: "Totale assegnato a piani completati",
        AssignmentToWorkflowsStateColumn: "Stato di assegnamento ai piani",

        DocumentNumberFilter: "Numero",
        DocumentVersionRevisionFilter: "Versione/revisione",
        DocumentDateFilter: "Periodo",
        DocumentRegisterFilter: "Registro",
        DocumentCustomerFilter: "Cliente",
        DocumentJobOrderFilter: "Commessa",
        DocumentJobOrderClassificationFilter: "Classificazione commessa",
        DocumentExternalReferenceFilter: "Riferimento esterno",
        DocumentPaymentFilter: "Mod. di pagamento",
        DocumentExpiryFilter: "Scad. di pagamento",
        TaxableTotalFilter: "Tot. imponibile",
        VatFilter: "Imposta",
        NotTaxableTotalFilter: "Tot. non imponibile",
        TotalFilter: "Totale",

        MetadataTextFilter: "Testo",
        MetadataNumberFilter: "Numero",
        DocsWithMetadataExport: "Esporta",

        DocumentWithMetadatasGeneralStartDateFilter: "Dal",
        DocumentWithMetadatasGeneralEndDateFilter: "Al",
        DocumentWithMetadatasGeneralProtocolFilter: "Protocollo",
        DocumentWithMetadatasGeneralClocingStatusFilter: "Stato documento",
        DocumentWithMetadatasGeneralCustomerFilter: "Cliente",
        DocumentWithMetadatasGeneralJobOrderFilter: "Commessa",
        DocumentWithMetadatasGeneralJobOrderTypeFilter: "Tipologia commessa",
        DocumentWithMetadatasGeneralJobOrderStatesFilter: "Stato commessa",
        DocumentWithMetadatasShowedResultsLimit: "(ATTENZIONE!! Vengono mostrati solamente i primi {0} risultati)",
        DocumentWithMetadatasAffectedRows: "Numero di risultati:",
        DocumentWithMetadatasHelpText: 'Clicca sul pulsante "Cerca"',
        AdministrativeResponsibleColumn: "Responsabile amministrativo",
        CommercialResponsibleColumn: "Responsabile commerciale",
        AdministrativeResponsibleFilter: "Responsabile amministrativo",
        CommercialResponsibleFilter: "Responsabile commerciale",
        SourceWarehouseColumn: "Magazzino di provenienza",
        SourceWarehouseFilter: "Magazzino di provenienza",
        DestinationWarehouseColumn: "Magazzino di destinazione",
        DestinationWarehouseFilter: "Magazzino di destinazione",
        OutcomeColumn: "Esito",
        OutcomeFilter: "Esito",
        StateColumn: "Stato",
        StateFilter: "Stato",
        CauseColumn: "Causale",
        CauseFilter: "Causale",
        DocumentMetadatasTableTitle: "Dati aggiuntivi",

        DocumentStateFiltersTitle: "Stato documento",

        InvoiceWithoutSchedules: "Preventivo rifiutato/Fattura senza scadenze di pagamento",
        InvoicePaid: "Fattura pagata",
        InvoicePartiallyPaid: "Fattura parzialmente pagata",
        InvoiceNotPaidAndExpired: "Fattura non ancora pagata oltre i termini di pagamento",
        InvoiceNotPaidAndSomeExpired: "Fattura non ancora pagata, alcuni termini di pagamento superati",
        InvoiceNotPaid: "Fattura non ancora pagata",
        InvoicePaidExpired: "Fattura pagata oltre i termini di pagamento",
        InvoicePartiallyPaidExpired: "Fattura parzialmente pagata, oltre i termini di pagamento",

        Closed: "Chiuso",
        NotManaged: "Non gestito",
        OnManaging: "In gestione",
        Gestito: "Gestito",

        MetadataPlaceholder: "Nome campo",

        ShowOrHideDeletedMetadataListValues: "Mostra/nascondi voci eliminate",
        AddMetadataListValues: "Aggiungi",
        RemoveMetadataListValues: "Elimina",
        RestoreMetadataListValues: "Ripristina",
        JobOrderClassification: "Classificazione commessa",

        PrintingInProgress: "Stampa in corso...",

        SavePrintTemplates: "Salva",
        PrintTemplateSaveError: "Si è verificato un errore durante il salvataggio dei modelli di stampe!",
        PrintTemplatesSettingsManagerName: "E.030 Modelli Stampe",
        PrintTemplatesSettingsManagerNameLong: "Gestione Modelli Stampe Documenti",
        PrintTemplateTitle: "Nome",
        PrintTemplateTitlePlaceholder: "Inserisci un nome per il modello di stampa",
        PrintTemplateNew: "Nuovo",
        PrintTemplateNoPrintTemplates: "Nessun modello di stampa definito",
        PrintTemplateEdit: "Modifica",
        PrintTemplateRemove: "Elimina",

        PrintTemplateDeleteMessage: "Sei sicuro di voler eliminare il modello di stampa?",
        PrintTemplateDeleteMessageCancel: "Annulla",
        PrintTemplateDeleteMessageConfirm: "Elimina Modello",

        PrintTemplateUnsavedChangesMessage:
            "Attenzione, sono presenti cambiamenti non salvati, procedendo le modifiche verranno perse!",
        PrintTemplateUnsavedChangesMessageCancel: "Annulla",
        PrintTemplateUnsavedChangesMessageConfirm: "Perdi modifiche",

        NegativeStocksDialogTitle: "Documenti amministrativi",

        Article: "Articolo",
        ReloadArticlesDescriptions: "Ricarica descrizioni articoli",
        StockAmount: "Q.tà",
        Warehouse: "Magazzino",
        MovementDate: "Data movimento",
        Protocol: "Protocollo",
        Document: "Documento",
        NegativeStocksAfterSaveMessage:
            "ATTENZIONE!! L'emissione di questo documento comporta uno stock di magazzino negativo per i seguenti articoli. Salvare comunque?",

        Resource: "Risorsa",
        From: "Dal",
        To: "Al",
        JobOrder: "Commessa",
        JobOrderType: "Tipologia commessa",
        HoursAmount: "Monte ore",
        WorkingHoursAmount: "Monte ore da orario risorsa",
        StandardWorkingHoursAmount: "Monte ore da orario standard",
        RelatedWorkflowsTooltip: "Q.tà: {0} - Tot. {1}",

        GetWarehouseEntitiesStocksInfoOnRowsError:
            "Impossibile leggere le informazioni relative alla quantità libera dell'articolo referenziato.",
        ArticleAvailableAmountTooltip: "Q.tà libera",
        ArticleAvailableAmount: "Q.tà libera",
        ArticleStockAmount: "Q.tà in stock",
        ArticleReservedAmount: "Q.tà riservata",
        ArticleOrderedAmount: "Q.tà ordinata",
        ArticleMinimumAmount: "Q.tà minima",
        ArticleStockInfo: "Giacenza articolo",
        ArticleStockInfoLegend: "Legenda",

        AvailableAmountGreatherThenMinimumAmount: "Q.tà libera > Q.tà minima",
        AvailableAmountLowerThenMinimumAmountButGreaterOrEqualThenZero: "Q.tà libera < Q.tà minima ma Q.tà libera >= 0",
        AvailableAmountLowerThenZeroButAvailableAmountPlusOrderedAmountGreatherOrEqualThenZero:
            "Q.tà libera < 0 e Q.tà libera + Q.tà ordinata >= 0",
        AvailableAmountPlusOrderedAmountLowerThenZero: "Q.tà libera + Q.tà ordinata < 0",

        PassiveInvoice: "Fattura Passiva",
        PassiveInvoices: "Fatture Passiva",
        PassiveInvoiceProtocols: "Protocolli Fatture Passive",
        PassiveInvoiceProtocolsShortLabel: "F. Passive",

        ImportPassiveInvoices: "Importazione Fatture Passive",

        ImportPassiveInvoicesWaiting: "Da Importare",
        ImportPassiveInvoicesUploading: "Caricamento",
        ImportPassiveInvoicesInProgress: "Importazione",
        ImportPassiveInvoicesDone: "Completato",
        ImportPassiveInvoicesError: "Errore",

        NotAssignedToWorkflowsState: "Non assegnato a piani",
        PartiallyAssignedToWorkflowsState: "Parzialmente assegnato a piani",
        FullyAssignedToWorkflowsState: "Completamente assegnato a piani",
        MaxedOutAssignedToWorkflowsState: "Sovrassegnato a piani",

        DocumentResourcesExportButton: "Esporta",

        InvoiceElectronicInvoiceTypeRequired:
            "Protocollo {0}: la tipologia di fattura elettronica per le fatture è obbligatoria.",
        AccompanyingInvoiceElectronicInvoiceTypeRequired:
            "Protocollo {0}: la tipologia di fattura elettronica per le fatture accompagnatorie è obbligatoria.",
        DownPaymentElectronicInvoiceTypeRequired:
            "Protocollo {0}: la tipologia di fattura elettronica per le fatture in acconto è obbligatoria.",
        CreditNoteElectronicInvoiceTypeRequired:
            "Protocollo {0}: la tipologia di fattura elettronica per le note di credito è obbligatoria.",
        AssignedResources: "Risorse assegnate",
        AssignedResourcesFilter: "Cerca risorse assegnate",

        TaxReliefTableTitle: "Agevolazioni fiscali - sconto in fattura",
        DocumentTaxReliefColumnTitle: "Agevolazione",
        TaxReliefPlaceholder: "Cerca agevolazione...",
        DocumentTaxReliefDiscountColumnTitle: "% agev.",
        DocumentTaxReliefDiscountPlaceholder: "% agev.",
        DocumentTaxReliefDocumentRowColumnTitle: "Riga doc.",
        DocumentTaxReliefDocumentRowPercentageColumnTitle: "% riga",
        DocumentTaxReliefDocumentRowPercentagePlaceholder: "% riga",
        DocumentTaxReliefTotalColumnTitle: "Tot.",
        TaxReliefDocumentRowPlaceholder: "Cerca riga...",

        RemoveTaxReliefMessage:
            "Rimuovere l'agevolazione fiscale e solamente l'associazione con la riga del documento?",
        RemoveTaxReliefButton: "Rimuovi l'agevolazione",
        RemoveTaxReliefDocumentRowButton: "Rimuovi associazione con riga",
        MissingTaxRelief: "Selezionare un'agevolazione fiscale",
        MissingTaxReliefDiscount: "Impostare uno sconto valido per l'agevolazione fiscale",
        MissingDocumentRowOnTaxRelief: "Selezionare una riga di documento per l'agevolazione fiscale",
        MissingDocumentRowTotalPercentageOnTaxRelief:
            "Impostare la percentuale di prezzo della riga su cui applicare lo sconto dell'agevolazione fiscale",

        AddAllRowsTooltip: "Aggiungi tutte le righe del documento.",
        AddRowTooltip: "Aggiungi una riga.",

        TaxReliefAutomationTitle: "Agevolazione fiscale",
        TaxReliefDiscountAutomationTitle: "% agevolazione",
        TaxReliefRowPercentageAutomationTitle: "% riga",

        DifferentCurrencyFromDefaultsAlert:
            "Attenzione! Durante la procedura di caricamento delle impostazioni predefinite sul documento ha individuato una valuta predefinita diversa da quella attualmente presente sul documento.\r\n" +
            "Il documento contiene gia delle righe e non è quindi possibile cambiare la valuta predefinita del documento in modo automatico.\r\n" +
            "Si desidera rimuovere tutte le righe del documento e cambiare la valuta oppure desidera ignorare il cambio di valuta e continuare?",
        IgnoreCurrencyFromDefaults: "Ignora il cambio di valuta",
        DropDocumentRowsAndApplyCurrencyFromDefaults: "Cancella le righe e cambia la valuta",

        DocumentAlreadyClosed: "Documento già importato",

        CalculateAmount: "Calcola quantità",
        CalculateUnitPrice: "Calcola prezzo untario",
        CalculateNetUnitPrice: "Calcola prezzo untario netto",
        CalculateDiscount: "Calcola sconto",

        RowTargetTotalPrice: "Imp. netto desiderato",

        RowValuesCalculatorTitle: "Calcola valori di riga",

        DocumentMetadatumEditNotAllowed:
            "Non si dispone dei diritti necessari per la gestione dei dati aggiuntivi di un documento.",
        InvalidMetadatumError:
            "I seguenti metadati non sono ammessi su questo documento e non sono stati copiati: <br /><br /> {0}",
        DocumentWizardOnlyPositiveStock:
            'Mostra solo articoli con quantità maggiore di zero (se stai lavorando sul magazzino predefinito, clicca sul pulsante "Ricarica inventario" per aggiornare la lista).',
        DocumentWizardStockAmountUnloading: "Scarico",
        DocumentWizardStockAmountAdjustment: "Rettifica",
        WarehouseArticle: "Articoli",
        WarehousePurchase: "Acquisti",
        DocumentWizardStockAmountUnloadingHelp:
            "La quantità specificata indica la quantità che si vuole movimentare. Tale quantità verrà riportata sulle righe del documento finale.",
        DocumentWizardStockAmountAdjustmentHelp:
            "La quantità specificata indica la quantità di stock finale che si vuole ottenere; il sistema calcolerà autometicamente il movimento necessario da eseguire per ottenere tale risultato. La quantità riportata sul documento finale sarà il valore calcolato dal sistema.\r\nN.B. i movimenti nulli non verranno riportati sul documento.",
        JobOrderAccessDenied: "Non si disponde dei diritti necessari per accedere alla commessa selezionata.",
        ActivitiesGroupingMode: "Suddivisione per attività/piano",

        GroupingModeNothing: "Nessuna suddivisione",
        GroupingModeWorkflow: "Suddivisione per piano di lavoro",
        GroupingModeActivity: "Suddivisione per attività",

        JobOrderWorkedHours: "Ore lavorate su commessa",

        LoadResourcesSettingsError: "Impossibile caricare le impostazioni per le risorse assegnate ai documenti",
        DocumentResourcesSettingsManagerLabel: "E.180 Assegnazioni risorse",
        SaveResourcesSettingsError: "Impossibile salvare le impostazioni per le risorse assegnate ai documenti",
        SaveResourcesSettingsSuccess: "Impostazioni salvate correttamente",
        ResourcesAssignmentSettingsTitle: "Impostazioni assegnazione risorse",
        ResourceAssignemntStartFrom: "Inizio assegnazione risorsa dal campo",
        ResourceAssignemntEndFrom: "Fine assegnazione risorsa dal campo",
        TotalHoursToAssignFrom: "Totale ore da assegnare dal campo",
        DataSourcePlaceholder: "Seleziona la sorgente dati...",
        DataSourceFieldPlaceholder: "Seleziona il campo...",
        TotalHoursConversionFactor: "Fattore di conversione ore",
        TotalHoursConversionFactorPlaceholder: "Fattore di conversione in ore",
        UseResourceServiceOrderForCalculations: "Usa l'ordine di servizio della risorsa per i calcoli",
        DefaultServiceOrderConfiguration: "Configurazione ordine di servizio standard",

        Monday: "Lunedì",
        Tuesday: "Martedì",
        Wednesday: "Mercoledì",
        Thursday: "Giovedì",
        Friday: "Venerdì",
        Saturday: "Sabato",
        Sunday: "Domenica",

        HoursPlaceholder: "Ore",
        DocumentResourceAssignmentsDetailsTitle: "Dettagli assegnazioni risorsa {0}",

        DetailedAssignmentsResourceColumn: "Risorsa",
        DetailedAssignmentsDocumentColumn: "Scheda",
        DetailedAssignmentsRowNumberColumn: "Riga",
        DetailedAssignmentsStartDateColumn: "Data inizio",
        DetailedAssignmentsEndDateColumn: "Data fine",
        DetailedAssignmentsHoursAmountColumn: "Totale ore",

        ResourceAssignemntsNotFound: "Nessuna assegnazione trovata per la risorsa selezionata.",
        DocumentResourceTotalHours: "Totale ore assegnate",
        DocumentResourceMaxAvailableHours: "Max. ore disponibili",
        DocumentResourceResidualAvailableHours: "Ore disponibili residue",
        DocumentHoursToAssign: "Ore da assegnare (da documento)",
        DocumentHoursToAssignConversionFactor: "Fattore di conversione ore",
        DocumentHoursAmountWithConversionFactor: "Ore da assegnare totali (hh doc. x fattore conv.)",
        DocumentResidualHoursAmountWithConversionFactor: "Ore da assegnare residue",
        AlreadyAssignedAmount: "Ore già assegnate",
        DetailedAssignmentsHoursInDetailsPeriodAmountColumn: "Totale ore nel periodo",

        ResourcesAssignmentsReportTitle: "Report assegnazioni risorse",
        Resources: "Risorse",
        ListHelpText: "Clicca su cerca...",
        EmptyAssignmentsList: "Nessuna assegnazione disponibile",

        FromRequired: "Specificare la data di inizio",
        ToRequired: "Specificare la data di fine",
        InvalidDateRange: "La data d'inizio deve essere precedente o uguale alla data di fine",
        DateDiffError: "Il periodo selezionato non deve superare i 2 anni",
        ResourcesAssignmentsReportTotalsTitle: "Totali",
        SaveProtocolError: "Si è verificato un errore durante il salvataggio del protocollo",

        ProtocolNotFound: "Protocollo non trovato",
        MissingProtocolName: "Nome protocollo mancante",
        InvalidDocumentLayout: "Layout documento non valido",
        InvalidDocumentType: "Tipo documento non valido",
        MissingInvoiceElectronicInvoiceType: "Tipo di fattura elettronica mancante",
        MissingDownPaymentElectronicInvoiceType: "Tipo di fattura di acconto elettronica mancante",
        MissingAccompanyingInvoiceElectronicInvoiceType: "Tipo di fattura accompagnatoria elettronica mancante",
        MissingCreditNoteElectronicInvoiceType: "Tipo di nota di credito elettronica mancante",
        MissingProtocolDefaultMetadataId: "Metadato protocollo mancante",
        DuplicatedProtocolDefaultMetadata: "Metadato protocollo duplicato",
        ProtocolAccessDenied: "Non si dispone dei diritti necessari per accedere al protocollo selezionato",
        MailRecipientEmailRequired: "Indirizzo email mancante",
        MailRecipientEmailInvalid: "L'indirizzo email {0} inserito nei default di protocollo non è valido",
        InvalidImageType: "Tipo di immagine non valido",
        MissingInvoiceTypes: "Sul protocollo {0} non è stata selezionata nessuna tipologia di documento.",
        DocumentsOnDisabledInvoiceType:
            "Sul protocollo {0} non è possibile disabilitare la tipologia di documento {1} perché sono già stati emessi documenti di quella tipologia.",
        MissingMailRecipientEmail: "Il valore selezionato come indirizzo email non è valido",

        LasMailSentDateColumn: "Data ultimo invio mail",
        LasMailSentDate: "Data ultimo invio mail",
        LasMailSentDateFilter: "Data ultimo invio mail",

        DocumentLastMailSentStatus: "Ultimo invio mail il {0} da {1}",
        DocumentLastMailSentSuccess: "Invio mail avvenuto con successo",
        DocumentLastMailSentPartial: "Invio mail parziale",
        DocumentMailLogTitle: "Log invio mail",
        DocumentMailLogEmptyResultMessage: "Nessun log di invio mail disponibile",
        DocumentMailLogRowTitle: "Mail inviata il {0} da {1}",
        SendMailLabel: "Invio mail:",
        IncludeDocumentAttachmentsLabel: "Allegati:",
        SaveAndSendMail: "Salva e invia",
        SendMail: "Invia",
        SaveAndNotSendMail: "Salva senza inviare",
        NotSendMail: "Non inviare",

        NoMailTemplateFoundMessage: "Nessun template e-mail configurato",
        NoValidMailFoundMessage: "Nessun indirizzo e-mail valido",

        CurrenciesManager: "Valute",
        DocumentEconomics: "Document economics",
        NoTransformationAvailable: "Nessun articolo compostito disponibile",
        DocumentWizardSetCurrentDate: "Imposta la data corrente come data di riferimento",
        DocumentWizardSetDocumentDate: "Imposta la data del documento come data di riferimento",
    },
    JobOrder: {
        JobOrder: "Commessa",
        Name: "Nome",
        Description: "Descrizione",
        Order: "Commessa",
        Orders: "Commesse",
        OrdersSettingsGroup: "B. Commesse",
        OrdersURL: "Commesse",
        SAL: "SAL",
        OpenSALURL: "Documenti/Apri/Sal/",
        NewSALURL: "Documenti/Nuovo/Sal",
        NewJobOrderURL: "Documenti/Nuovo/Sal/JobOrder/",
        NewOrder: "Nuova commessa",
        InsertCustomerVAT: "Inserire la partita iva del cliente",
        InsertCustomerSSN: "Inserire il codice fiscale del cliente",
        InvalidCustomerVAT: "La partita iva del cliente non è valida",
        InvalidCustomerSSN: "Il codice fiscale del cliente non è valido",
        Cancel: "Annulla",
        Confirm: "Conferma",
        Delete: "Elimina",
        Save: "Salva",
        Import: "Importa",
        ImportSelectOrder: "E' necessario selezionare una commessa prima di avviare l'importazione dati!",
        DeleteDocumentMsg: "Sei sicuro di voler eliminare questo documento?",
        DeleteDocumentMsgCancel: "Ritorna al Documento",
        DeleteDocumentMsgConfirm: "Elimina il Documento",
        ConfirmUnsavedChanges:
            "Sono state apportate delle modifiche, annullando verranno perse.\nSei sicuro di voler continuare?",
        ConfirmUnsavedChangesCancel: "Ritorna al Documento",
        ConfirmUnsavedChangesConfirm: "Anulla Modifiche",
        Tel: "tel.",
        Fax: "fax",
        Email: "e-mail:",
        Web: "web:",
        RegImpLucca: "Reg.imp Lucca",
        VAT: "p.iva:",
        IscrREA: "Iscr. R.E.A.",
        CompCap: "Cap.Soc.",
        NoRowToImport: "Selezionare almeno una riga da importare nel documento!",
        ImportRowWarning:
            "Una o più delle righe selezionate sono già state importate in un altro documento.\nSei sicuro di voler importare le righe selezionate?",
        ImportRowWarningCancel: "Annulla l'importazione",
        ImportRowWarningConfirm: "Importa comunque le righe",
        RowsAlreadyImported: "Alcune righe non sono state importate perchè già utilizzate in questo documento!",
        WatchWarning: "Attenzione! La data selezionata è posteriore alla data del documento che è il {0}!",
        SALName: "SAL n° {0} del {1}",
        SALDataImport: "SAL: Importazione dati",
        Overview: "Riepilogo",
        Groups: "Gruppi",
        RowsWithoutDescription: "Alcune righe non hanno una descrizione",
        DiscardedRows: "Alcune delle righe selezionate sono state scartate perchè erano già state aggiunte.",
        OrderStatus: "Stato commessa",
        Low: "Bassa",
        Normal: "Normale",
        Hi: "Alta",
        InTheBody: "A Corpo",
        Reporting: "Rendicontazione",
        NoInvoiceable: "Non Fatturabile",
        Title: "Titolo",
        Start: "Inizio",
        Expiration: "Scadenza",
        Duration: "Stima",
        DurationFormat: "{0} ore",
        System: "(Sistema)",
        //Billed: "Fatturate",
        ToBill: "da Fatt.",
        Progress: "Avanzamento",
        Priority: "Priorità",
        InQuality: "In Qualità",
        CreatedBy: "Creato da",
        EstimatedBudget: "Budget stimato",
        ExternalEstimatedBudget: "Budget a carico cliente",
        InternalEstimatedBudget: "Budget a carico interno",
        OtherEstimatedBudget: "Budget non specificato",
        EstimatedHours: "Budget Ore",
        PurchasesBudget: "Budget Acquisti",
        WarehouseBudget: "Budget Magazzino",
        Cost: "Costo",
        Revenue: "Ricavo",
        Billedm: "Fatturati",
        Workflow: "Flusso",
        WorkflowId: "IdFlusso",
        ReportingType: "Tipo Rendicontazione",
        Locked: "Bloccato",
        Actions: "Azioni",
        WorkedHours: "Ore Lavorate",
        BilledWorkedHours: "Ore Lavorate Fatturate",
        ToBillWorkedHours: "Ore Lavorate da Fatturare",
        PurchasesCost: "Costo Acquisti",
        PurchasesRevenue: "Ricavo Acquisti",
        BilledPurchases: "Acquisti Fatturati",
        ToBillPurchases: "Acquisti da Fatturare",
        ActivityUpdated: "Attività aggiornata con successo!",
        ActivityUpdateError: "Si è verificato un errore durante l'aggiornamento dell'attività",
        FolderDeleteMsg:
            "Attenzione! La cartella e tutte le attività contenute verrannno eliminate permanentemente, sei sicuro di voler eliminare la cartella?",
        FolderDeleteMsgCancel: "Annulla",
        FolderDeleteMsgConfirm: "Elimina la cartella",
        ActivityDeleteMsg:
            "Attenzione! L'attività verrà eliminata permanentemente, sei sicuro di voler eliminare l'attività?",
        ActivityDeleteMsgCancel: "Annulla",
        ActivityDeleteMsgConfirm: "Elimina l'attività",
        FolderDeleted: "Cartella eliminata con successo!",
        ActivityDeleted: "Attività eliminata con successo!",
        FolderDeleteError: "Impossibile eliminare la cartella",
        ActivityDeleteError: "Impossibile eliminare l'attività",
        Home: "Home",
        Notes: "Appunti",
        PasteAbove: "Incolla Sopra",
        PasteBelow: "Incolla Sotto",
        PasteAsChild: "Incolla come figlio",
        Cut: "Taglia",
        //Delete: "Delete",
        Creation: "Creazione",
        Creations: "Creazioni",
        AddAbove: "Aggiungi Sopra",
        AddBelow: "Aggiungi Sotto",
        AddChild: "Aggiungi figlio",
        Management: "Gestione",
        AssignSelectionToWorkflow: "Assegna selezione al Flusso",
        //Export: "Esportazione",
        Print: "Stampa",
        Export: "Esporta",
        ExportHint: "Esporta in Excel",
        UnableToAddNewActivity: "Impossibile aggiungere una nuova attività",
        NoActivitySelected: "Selezionare almeno una attività",
        PastNoActivitySelected: "Tagliare almeno una attività",
        UnableToMoveActivity: "Impossibile spostare le attività selezionate",
        CutNoActivitySelected: "Tagliare almeno una attività",
        NoActivityForWorkflow: "Selezionare almeno un'attività da assegnare al flusso",
        ActivityAssigned: "Le attività sono state assegnate al flusso '{0}'",
        UnableToAssignActivity: "Impossibile assegnare le attività al flusso",
        PrintActivity: "ProLifeNet - Stampa Attività",
        ActivityToday: "Attivita - Oggi",
        ActivitiesDeleted: "Le attività sono state eliminate",
        ActivitiesDeleteError:
            "Impossibile eliminare una o più delle attività selezionate. L'operazione è stata annullata.",
        SelectWorkflow: "Seleziona un Flusso",
        PleaseSelectWorkflow: "Seleziona un flusso per continuare",
        SALStatements: "SAL / Rendicontazioni",
        SALProtocol: "SAL / Rendicontazioni",
        SALProtocolShortLabel: "SAL",
        New: "Nuovo",
        ExcelExport: "Esporta Excel",
        Customer: "Cliente",
        Manager: "Responsabile",
        Type: "Tipologia",
        Status: "Stato",
        Billed: "Fatturato",
        WorkCost: "Costo Lavoro",
        Worked: "Lavorato",
        WorkEstimation: "Lavoro Stimato",
        WarehousesCount: "N.Magazzini",
        WorkflowsCount: "Tot.Flussi",
        ClosedWorkflows: "Flussi Chiusi",
        RequestEndDate: "Fine Richiesta",
        ReportModelsURL: "Modelli Report",
        All: "Tutti",
        MovedOrdersNotBilledDelivery: "Commesse movimentate e DDT non fatturati",
        DoNotLock: "Non segnalare",
        Activities: "Attività",
        SelectAnOrder: "Selezionare una commessa",
        ImportActivities: "Importazione attività",
        Importing: "Importazione in corso...",
        Unblocked: "Sbloccata",
        ActivityImportReport: "Report di importazione attività",
        NoCustomer: "Nessun cliente",
        AnalizeOrders: "Analisi commesse",
        OrdersNotQualityCompleted: "Commesse con incompletezze di qualità",
        Present: "Presenza",
        Absent: "Assenza",
        OrderTypes: "B.030 Tipologie commessa",
        TypeCodes: "B.010 Codici Commessa",
        Open: "Aperto",
        Closed: "Chiuso",
        OrdersStatus: "B.040 Stati Commesse",
        OrderCustomerLock: "B.050 Blocco Commessa/Cliente",
        UnableToRemoveType:
            "Non è stato possibile eliminare il tipo. Verificare che non esistano commesse di questo tipo.",
        InvalidDefaultOrderName: "Inserire un nome valido per il tipo di commessa predefinito.",
        DescriptionDeleteError: "Si è verificato un errore durante l'eliminazione della causale.",
        AddressBook: "Rubrica",
        Contact: "Contatto",
        Users: "Utenti",
        User: "Utente",
        GitCommit: "Git Commit",
        GitCommitMailSubject: "ProLife - Git Commit",
        JobOrderStatusChange: "Cambio Stato Progetto",
        JobOrderStatusChangeEventMailSubject: "ProLife - Cambio Stato Progetto",
        RequestDeleteMsg: "Sei sicuro di voler eliminare la richiesta?",
        RequestDeleteMsgCancel: "Non eliminare",
        RequestDeleteMsgConfirm: "Elimina",
        RequestDeleted: "Richiesta eliminata",
        RequestAuthorized: "Richiesta autorizzata",
        UnsavedChangesLostMsg: "ATTENZIONE : Tutte le modifiche non salvate andranno perse. Proseguire comunque?",
        UnsavedChangesLostMsgCancel: "Non proseguire",
        UnsavedChangesLostMsgConfirm: "Prosegui",
        RequestRevoked: "Richiesta revocata",
        InsertRequestTitle: "Inserire il titolo della richiesta",
        SelectAnActivity: "Selezionare almeno un'attività",
        RequestSaved: "Richiesta salvata con successo",
        WarehouseArticles: "Articoli di magazzino",
        HoursImport: "Importo ore",
        PurchasesImport: "Importo acquisti",
        WarehouseArticlesExpectedCosts: "Costi Attesi Articoli di Magazzino",
        WarehouseArticlesCosts: "Costo Articoli in Magazzino",
        BilledArticlesCosts: "Costo Articoli Fatturati",
        ExpectedRevenue: "Ricavi Attesi",
        WarehouseTurnover: "Fatturato di Magazzino",
        PurchasesCosts: "Costi Acquisti",
        HoursCosts: "Costo Lavoro",
        WorkedHoursExpectedRevenue: "Ricavi Attesi Lavorato",
        ExpectedWorkRevenue: "Ricavi Attesi Lavoro",
        PurchasesExpectedRevenue: "Ricavi Attesi Acquisti",
        PurchasesExpectedCost: "Costi Acquisti",
        OtherExpectedRevenue: "Altri Ricavi Attesi",
        OtherInvoiced: "Altri Fatturati",
        //BilledPurchases: 'Acquisti Fatturati',
        BilledHours: "Lavoro Fatturato",
        ExpectedWarehouseRevenue: "Ricavi di Magazzino Attesi",
        Costs: "Costi",
        BilledAmount: "Imponibile Fatturato",
        Turnover: "Fatturato",
        Unnamed: "<Senza nome>",
        OrderWithoutName: "Inserire il nome della commessa",
        OrderWithoutCustomer: "Inserire il cliente della commessa",

        ChangeCustomerMsg:
            "ATTENZIONE: Esistono dei magazzini associati alla commessa. Cambiando il cliente potrebbero verificarsi delle incongruenze. Si desidera proseguire comunque?",
        ChangeCustomerMsgCancel: "Non salvare",
        ChangeCustomerMsgConfirm: "Ignora e salva",

        ConfirmNoCustomer: "Sei sicuro di voler salvare la commessa senza cliente?",
        ConfirmNoCustomerCancel: "Annulla",
        ConfirmNoCustomerConfirm: "Continua",

        Saving: "Salvataggio in corso...",
        OrderDeleteMsg: "Sei sicuro di voler eliminare la commessa?",
        OrderDeleteMsgCancel: "Non eliminare",
        OrderDeleteMsgConfirm: "Elimina",
        OrderDeleted: "Eliminazione avvenuta con sucesso!",

        OrderUndeleteMsg: "Sei sicuro di voler ripristinare la commessa?",
        OrderUndeleteMsgCancel: "Non ripristinare",
        OrderUndeleteMsgConfirm: "Ripristina",
        OrderUndeleted: "Commessa ripristinata con successo!",

        OrderSaved: "Salvataggio avvenuto con sucesso!",

        Loading: "Caricamento in corso...",
        UnblockOrderMsg: "Sei sicuro di voler procedere con la rimozione manuale della segnalazione per la commessa?",
        UnblockOrderMsgCancel: "Non rimuovere la segnalazione",
        UnblockOrderMsgconfirm: "Rimuovi Segnalazione",
        LockOrder: "Segnala commessa",
        ExpectedRevenues: "Ricavi preventivati",
        QuoteName: "{0} N.{1} - {2}",
        CustomerOrderName: "{0} N.{1} - {2}",
        JobOrderEstimatedRevenuesSaved: "Salvataggio effettuato",
        Other: "Altro",
        WorkEntity: "Lavorazioni",
        Purchases: "Acquisti",
        Types: "Tipologie",
        StatusP: "Stati",
        OpenP: "Aperti",
        ClosedP: "Chiusi",
        //NoCustomer: "Nessun cliente",
        OrderLockedBy: "Comessa segnalata da {0} con motivazione: {1}",
        //Manager: "Responsabile",
        CustomerInitial: "C",
        ManagerInitial: "R",
        StatusInitial: "S",
        UpdateCompleted: "Aggiornamento effettuato",
        PleaseSelect: "Seleziona...",
        GoToPage: "Vai all pagina:",
        ShowRows: "Mostra righe:",
        Of: "di",
        Previous: "indietro",
        Next: "avanti",
        First: "primo",
        Last: "ultimo",
        Apply: "Applica",
        //Cancel: "Annulla",
        ClearFilter: "Resetta filtro",
        Advanced: "avanzato",
        Search: "Cerca:",
        InvalidEnteredValue: "Il valore inserito non è valido",
        NoDataToShow: "Nessun dato da visualizzare",
        SelectFilter: "Seleziona Filtro",
        Clear: "Pulisci",
        Today: "Oggi",
        SlickGridLoadErrorMessage:
            "I dati sono ancora in carimento e non è possibile impostare una proprietà o chiamare un metodo. E' necessario attendere la fine del bind dei dati. jqxDataTable genera l'evento 'bindingComplete' non appena il bind è completato.",
        GeneralSettings: "A.030 Impostazioni Generali",
        SureToResetGeneralSettingsChanges: "Sei sicuro di voler annullare le modifiche alle impostazioni generali?",
        DoNotResetGeneralSettings: "Non annullare",
        DoResetGeneralSettings: "Annulla le modifiche",
        GeneralSettingsSavedSuccessfully: "Impostazioni generali salvate con successo!",
        EstimatedWork: "Lavoro Stimato",
        ComputedEstimatedWork: "Lavoro Stimato Ricalcolato",
        EstimatedWorkCost: "Costo Lavoro",
        DoneWork: "Lavoro Svolto su Attività",
        AllDoneWork: "Lavoro Svolto",
        ClosedWork: "Lavoro Chiuso",
        Speed: "Velocità",
        Delay: "Ritardo",
        Forecast: "Previsione",
        PlannedForecast: "Previsione Pianificata",
        MeanWorkADay: "Ore lavorate al giorno",
        MeanWorkADayForecast: "Previsione ore lavorate al giorno",
        RemainingWork: "Lavoro Rimanente",
        WorkingResourceAllocation: "Allocazione risorsa",
        WorkflowEconomicsDialogTitle: "Configurazione della Ripartizione dei Ricavi",
        PleaseInsertDescriptionBeforeSaving: "Inserire una descrizione per tutte le righe prima di salvare",
        AvailableColumns: "Colonne Disponibili",

        EntityFieldNameTitle: "Titolo",
        EntityFieldNameStartDate: "Data inizio",
        EntityFieldNameExpireDate: "Data fine richiesta",

        EstimatedWarehouseCosts: "Costi di Magazzino",
        EstimatedCosts: "Costi stimati",

        GeneralInfo: "Informazioni Generali",
        CostsRevenues: "Costi/Ricavi",
        Performance: "Prestazioni",
        Documents: "Documenti",
        Details: "Dettagli",
        Projects: "Progetti",
        ActivitiesTree: "Albero delle Attività",
        Budget: "Richieste Budget",
        JobOrderTypesDialogTitle: "Gestione Tipologie Commessa",

        InvalidCharacterInJobOrderName: "Il carattere '/' non è ammesso nel nome commessa!",
        ImportFromProject: "Importa da Project",
        ImportInProgress: "Importazione in corso...",
        CreateNewFolder: "Crea nuova cartella",
        PleaseInsertTitleBeforeSaving: "Inserire il nome della cartella prima di salvare",

        NoJobOrderTypeSelected: "Selezionare la tipolgia prima di continuare",
        NoJobOrderStateSelected: "Selezionare lo stato prima di continuare",

        DocumentExpiries: "Scadenze",
        EditExpiryDialog: "Modifica data di scadenza",
        EditExpiryDialogReadOnlyMode: "Storico modifiche data di scadenza",
        DocumentExpiryVariationSaved: "Variazione Scadenza salvata con successo!",
        RowExpiryFromDocumentRow: "Data di scadenza inserita sulla riga del documento",

        UnableToMoveType: "Impossibile riordinare gli elementi!",

        ConfirmRectifyTaskReportingState:
            "Attezione! Questa operazione è irreversibile! Sei sicuro di voler modificare lo stato di rendicontazione delle ore inserite in base alla configurazione delle attività?",
        DoConfirmRectifyTaskReportingState: "Rettifica",
        DoNotConfirmRectifyTaskReportingState: "Non Rettificare",
        RectifyTaskReportingStateSucceded: "La rettifica delle ore è stata completata con successo",

        WorkHistory: "Storico Produzione",
        Legend: "Legenda",
        NoWorkflow: "Nessun Piano",
        NoTask: "Nessuna Attività",
        PlannedWorkForecast: "Lavoro Pianificato",
        TheoreticalWorkForecast: "Lavoro Teorico Rimanente",
        RemainedUnallocatedWorkForecast: "Lavoro Rimanente Non Allocato",

        ActivitiesText: "{0} attività aperte",
        WorkedHoursText: "{0} ore lavorate da fatturare",
        PurchasesText: "{0} acquisti da fatturare",
        DocumentsText: "{0} {1} da chiudere",
        UnpaidSchedulesText: "{0} scadenze di pagamento da chiudere",
        CancelledSchedulesText: "{0} scadenze di pagamento annullate",
        WarehousesText: "{0} magazzini non vuoti",
        CloseJobOrderWithOpenedActivities:
            "ATTENZIONE: la commessa ha {0}. Le attività in lavorazione verranno spostate in completate, mentre attività da fare o in backlog andranno in sospese. Continuare?",
        CloseJobOrderWithOpenedActivitiesCancel: "Annulla",
        CloseJobOrderWithOpenedActivitiesConfirm: "Chiudi la commessa",

        ProgressAvgLabel: "Avanzamento",
        RemainingHoursLabel: "Ore rimanenti",
        MultipleCarts: "Carrelli multipli",

        JobOrderDocumentOpenedState: "Aperto",
        JobOrderDocumentPartiallyClosedState: "Parzialmente chiuso",
        JobOrderDocumentClosedState: "Chiuso",

        DocumentDetails: "Dettagli Documento",

        AdministrativeReport: "Riepilogo Amministrativo",
        WarehouseCosts: "Costi Magazzino",

        WarehouseCostsWarehouse: "In magazzino di commessa {0}",
        WarehouseCostsDDT: "DDT {0} del {1} prot. {2}",
        WarehouseCostsDDTWithoutProtocol: "DDT {0} del {1}",
        WarehouseCostsInvoice: "Fattura Accompagnatoria {0} del {1} prot. {2}",
        WarehouseCostsInvoiceWithoutProtocol: "Fattura Accompagnatoria {0} del {1}",
        EstimatesCostsEstimate: "Preventivo {0} del {1}",
        CustomerOrdersCostsCustomerOrder: "Ordine Cliente {0} del {1}",
        DDTsCostsDDT: "DDT {0} del {1}",
        InvoicesCostsInvoice: "Fattura {0} del {1}",
        WarehouseCostsTotal: "Totale",
        WarehouseCostsTotalToImport: "Tot. da imp.in doc",
        WarehouseCostsAmount: "Quantità",
        WarehouseCostsValue: "Valore",
        WarehouseCostsImportedInDDT: "Imp. in DDT Cliente",

        JobOrderDefaultValuesError: "Errori nei default di commessa",

        ProductionActivitiesPrintDialog: "Stampa documento di produzione",
        WorkflowsSelectionRequired: "Selezionare almeno un piano di lavoro",
        DownloadProductionDocument: "Download del file in corso...",
        NewWorkflowDefaultTitle: "Nuovo piano",
        CannotMergeTasksError:
            "Impossibile eseguire l'operazione perché i task sono incompatibili. Verificare che le stime di lavoro, acquisti e articoli siano compatibili.",

        ConfirmPurchaseTypeRemoval: "Sei sicuro di voler eliminare la tipologia di acquisto?",
        ConfirmPurchaseTypeRemovalOk: "Elimina",
        ConfirmPurchaseTypeRemovalCancel: "Torna indietro",
        PurchaseTypeSaved: "Tipologia di Acquisto salvata",
        PurchaseTypeUnknown: "Tipologia di Acquisto sconosciuta",

        JobOrdersCostsReport: "Esportazione Costi Commessa",
        MetadataSettings: "B.020 Metadati Progetto",

        CannotMergeMultipleTasks: "ATTENZIONE!! Non è possibile unire più di due task contemporaneamente",
        TasksMergeSuccess: "Le due attività sono state unite correttamente",

        TasksMergeConfirmTitle: "Unione attività",
        TasksMergeConfirmText: "ATTENZIONE!! Vuoi davvero unire queste due attività?",

        InvalidCompanyOrJobOrder: "Non è possibile spostare attività tra commesse diverse o tra aziande diverse.",

        InvalidTaskWorkflowMovement:
            "Non è possibile spostare le attività tra piani tramite la funzione di ordinamento. Droppare l'attività sull'apposita area.",

        CostsPlanningPendingChangesMessage: "ATTENZIONE!! Ci sono modifiche non salvate. Continuare?",
        CostsPlanningPendingChangesOnWorkflowSnapshotEditingMessage:
            "ATTENZIONE!! Le modifiche non salvate andranno perse. Continuare?",

        CommercialNotes: "Note commerciali",
        TechnicalNotes: "Note tecniche",
        AdministrationNotes: "Note amministrative",

        ProjectWorkHours: "Ore imputate al progetto",
        ProjectPurchases: "Acquisti imputati al progetto",

        CostsPlanningMarkupPopoverTitle: "Applica ricarico",
        CostsPlanningDiscountPopoverTitle: "Applica sconto",

        DocumentRelatedWorkflowsResetMessage:
            "ATTENZIONE!! A seguito della modifica della commessa, i piani legati alle righe sono stati rimossi.",
        DocumentRelatedWorkflowsResetLabel: "Documenti amministrativi",

        ResponsibleProviderPlainTextResourceTitle:
            "La risorsa impostata non è stata selezionata dalle risorse di sistema, ma è stata inserita come testo semplice.",
        ResponsibleProviderSelectedResourceTitle: "La risorsa impostata è stata selezionata dalle risorse di sistema.",

        WorkflowsMustBeRelatedToCustomerOrders: "Sono presenti piani di lavoro da associare a ordini cliente.",

        JobOrderAdvancedExport: "Esportazione avanzata commesse",
        JobOrderAdvancedExportReferenceDate: "Data di riferimento",
        JobOrderAdvancedExportFirstHelpTextLine:
            "Tramite questo report si può ottenere un riepilogo dettagliato per piano delle commesse presenti nel sistema.",
        JobOrderAdvancedExportSecondHelpTextLine:
            'Inserire data di riferimento a cui effettuare il calcolo e poi premere il pulsante "Genera Report" per esportare i dati.',

        EstimatesAdministrativeReportTitle: "Righe preventivi",
        CustomerOrderAdministrativeReportTitle: "Righe ordini cliente",
        DDTsAdminstartiveReportTitle: "Righe DDT",
        PaymentsAdministrativeReportTitle: "Fatturazione pagamenti",
        InvoicesAdministrativeReportTitle: "Righe fatture",
        WorkedHoursCosts: "Costi Lavoro",

        CodeDuplicationError: "Il codice di commessa è già utilizzato.",

        EditTasksOnCOstsPlanningAlertMessage: "Prima di continuare è necessario salvare le modifiche.",
        EditTasksOnCOstsPlanningAlertCancel: "Annulla",
        EditTasksOnCOstsPlanningAlertConfirm: "Salva e continua",
        ExternalChangesOnTasksAlert:
            "Non è possibile apportare modifiche alle attività perché sono state modificate esternamente da altri utenti. Per poter proseguire è necessario aggiornare la lista delle attività.",

        GoToJobOrder: "Vai alla commessa",
        DetailedInfoTitle: "Dettagli Progetto {0}",
        DetailedInfoTitleSimple: "Dettagli commessa",

        SplashPageDetailsOrdersTab: "Ordini",
        SplashPageDetailsWorkflowsTab: "Piani",
        SplashPageDetailsAllocationsTab: "Allocazioni",

        OrderDetailsOrder: "Ordine",
        OrderDetailsRegister: "Registro",
        OrderDetailsReferences: "Riferimenti",
        OrderDetailsTotal: "Totale",
        OrderDetailsWaiting: "Attesa",
        OrderDetailsWaitingTitle: "In attesa di Lavorazione",
        OrderDetailsWorking: "Lavor.",
        OrderDetailsWorkingTitle: "In Lavorazione",
        OrderDetailsCompleted: "Compl.",
        OrderDetailsCompletedTitle: "Completato",
        OrderDetailsAssigned: "Ass.",
        OrderDetailsAssignedTitle: "Assegnato",
        OrderDetailsDelivered: "Cons.",
        OrderDetailsDeliveredTitle: "Consegnato",
        OrderDetailsBilled: "Fatt.",
        OrderDetailsBilledTitle: "Fatturato",

        WorkflowsDetailsWorkflow: "Piano",
        WorkflowsDetailsStatus: "Stato",
        WorkflowsDetailsWorked: "Lav.",
        WorkflowsDetailsWorkedTitle: "Lavorato",
        WorkflowsDetailsResidual: "Res.",
        WorkflowsDetailsSpeed: "Vel.",
        WorkflowsDetailsSpeedTitle: "Velocità",
        WorkflowsDetailsReestimatedResidual: "Res. Rist.",
        WorkflowsDetailsResidualTitle: "Residuo",
        WorkflowsDetailsReestimatedResidualTitle: "Residuo ristimato",
        WorkflowsDetailsProgress: "Avanz.",
        WorkflowsDetailsProgressTitle: "Avanzamento (Attività Chiuse/Attività Totali)",
        WorkflowsDetailsBudgetFromOrders: "B. Ord.",
        WorkflowsDetailsBudgetFromOrdersTitle: "Budget da Ordini",
        WorkflowsDetailsBudgetFromBudgetRequests: "B. Ric.",
        WorkflowsDetailsBudgetFromBudgetRequestsTitle: "Budget Richiesto / Budget Autorizzato",
        WorkflowsDetailsEstimatedWorkflowCost: "C. Stima",
        WorkflowsDetailsEstimatedWorkflowCostTitle: "Stima del costo del piano",
        WorkflowsDetailsPlanning: "Pian.",
        WorkflowsDetailsPlanningTitle: "Pianificazione",

        AllocationsDetailsResource: "Risorsa",
        AllocationsDetailsCart: "Carrello",
        AllocationsDetailsStart: "Inizio",
        AllocationsDetailsEnd: "Fine",
        AllocationsDetailsManualCartTitle: "Appuntamento",

        ConsegnatoAtLeastOneManuallyClosed: "Esiste almeno una riga di un documento con righe chiuse manualmente",
        FatturatoAtLeastOneManuallyClosed: "Esiste almeno una riga di un documento con righe chiuse manualmente",

        HoursReporting: "Rendicontazione ore",
        JobOrderTypeLabel: "Nome della Tipologia",
        JobOrderTypeIconLabel: "Icona",
        JobOrderTypeIconBackgroundLabel: "Sfondo",
        JobOrderTypeIconForegroundLabel: "Colore",
        JobOrderTypeIconPreviewLabel: "Anteprima",
        JobOrderTypePredefinedWorkflowsLabel: "Flussi Predefiniti",
        JobOrderTypeCodeLabel: "Codice Tipologia",
        JobOrderTypeCodeTooltip: "Codice utilizzato dal sistema di generazione dei codici commessa",
        JobOrderTypeCodePlaceholder: "Inserisci un codice tipologia...",
        JobOrderTypeHideFromListsLabel: "Nascondi dalle liste",
        Label: "Etichetta",
        JobOrderTypeDefaultResponsibles: "Risorse predefinite per la creazione di un nuovo progetto",

        ResponsibleLabel: "Responsabile",
        AdministrativeResponsibleLabel: "Resp. amministrativo",
        CommercialResponsibleLabel: "Resp. commerciale",
        AuthorizedResourcesLabel: "Risorse autorizzate",

        NotApplyResponsibles: "Ignora",
        NotApplyResponsiblesDescr: "non applicare i responsabili configurati sulla categoria di commessa",
        MergeResponsibles: "Unisci",
        MergeResponsiblesDescr:
            "unisci i responsabili configurati sulla categoria di commessa con quelli attualmente configurati sulla commessa (N.B.: le risorse autorizzate verranno unite, mentre i responsabili verranno applicati solo se non già configuarti)",
        ApplyResponsibles: "Applica",
        ApplyResponsiblesDescr:
            "applica i responsabili alla commessa (eventuali responsabili già configurati verranno sovrascritti)",

        JobOrderResponsiblesApplicationModesMessage:
            "Sulla categoria di commessa selezionata sono configurati dei responsabili predefiniti; scegliere l'operazione da effettuare:",

        ExpandAll: "Espandi tutto",
        CollapseAll: "Collassa tutto",

        DocTypeColumnTitle: "Tipo",
        DocNumberColumnTitle: "Numero",
        ProtocolColumnTitle: "Protocollo",
        DocDateColumnTitle: "Data",
        CustomerColumnTitle: "Intestatario",
        TaxableTotalColumnTitle: "Imponibile",
        NotTaxableTotalColumnTitle: "Non Imponibile",
        TotalColumnTitle: "Totale",
        ExternalReferenceNumberColumnTitle: "Numero Rif.",
        ExternalReferenceDateColumnTitle: "Data Rif.",
        ExternalReferenceColumnTitle: "Rif. Est.",

        NotAuthorizedLabel: "ACCESSO NEGATO",
        NotAuthorized: "Non si dispone dei diritti per l'accesso alla commessa richiesta.",

        WorkTimeCategorySelectPlaceholder: "Selezionare una tipologia oraria",
        RoleSelectPlaceholder: "Selezionare una mansione",
        JobOrderSelectPlaceholder: "Selezionare una commessa",
        MissingDefaultMetadata: "Errore nei default dei metadati: specificare un metadato per ogni riga presente.",
        ChangeNotAuthorized: "Non si dispone dei diritti per modificare la commessa.",
        DuplicatedCode: "Impossibile salvare la commessa, esiste già una commessa con il codice {0}!",
        MissingMailRecipientEmail: "Il valore selezionato come indirizzo email non è valido",
        InvalidMailRecipientEmail: "L'indirizzo email {0} inserito nei default di protocollo non è valido",
        CloseJobOrderActivitiesError:
            "La commessa è stata chiusa correttamente ma non è stato possibile chiudere le attività a causa dei seguenti errori: {0}",
        WorkflowOutcomePreventTasksEditing:
            "Non è possibile aggiornare le attività del piano {0} perché il suo esito è configurato per prevenire la modifica delle attività.",
        WorkflowsAnalysisTitle: "Analisi piani di lavoro",
        WorkflowsAnalysisJobOrderTypeLabel: "Tipologia commessa",
        WorkflowsAnalysisJobOrderStateLabel: "Stato commessa",
        WorkflowsAnalysisJobOrderClassificationLabel: "Classificazione commessa",
        WorkflowsAnalysisCustomersLabel: "Clienti",
        WorkflowsAnalysisWorkflowCategoriesLabel: "Categoria piano",
        WorkflowsAnalysisWorkflowStatesLabel: "Stati piano",
        WorkflowsAnalysisWorkflowOutcomesLabel: "Esiti piano",
        WorkflowsAnalysisExport: "Esporta",
        JobOrderTitle: "Commessa",
        JobOrderFilter: "Commessa...",
        JobOrderClassificationTitle: "Classificazione",
        CustomerTitle: "Cliente",
        CustomerFilter: "Cliente...",
        WorkflowDescriptionFilter: "Descrizione piano...",
        JobOrderNotesFilter: "Note commessa...",
        JobOrderAdministrativeNotesFilter: "Note amministrative...",
        JobOrderCommercialNotesFilter: "Note commerciali...",
        WorkflowTitleFilter: "Piano...",
        WorkflowStartDateFilter: "Data inizio piano...",
        WorkflowEndDateFilter: "Data fine piano...",
        ResponsibleTitle: "Responsabile",
        AdministrativeResponsibleTitle: "Resp. Amministrativo",
        CommercialResponsibleTitle: "Resp. Commerciale",
        JobOrderTypeTitle: "Tipologia commessa",
        JobOrderStateTitle: "Stato commessa",
        WorkflowTitle: "Piano di lavoro",
        WorkflowCategoryTitle: "Categoria piano",
        WorkflowStateTitle: "Stato piano",
        OutcomeTitle: "Esito piano",
        NotesTitle: "Note",
        AdministrativeNotesTitle: "Note amministrative",
        CommercialNotesTitle: "Note commerciali",
        StartDateTitle: "Data inizio",
        EndDateTitle: "Data fine",
        WorkflowDescriptionTitle: "Descrizione piano",
        EstimatedWorkTitle: "Lavoro stimato (ore)",
        ProgressedEstimatedWorkTitle: "Lavoro stimato avanzato (ore)",
        ProgressedEstimatedWorkTooltip:
            "Lavoro stimato avanzato sulle attività del piano di lavoro, calcolato moltiplicando la stima per l'avanzamento",
        RemainingEstimatedWorkTitle: "Lavoro stimato rimanente (ore)",
        RemainingEstimatedWorkTooltip:
            "Lavoro stimato rimanente sulle attività del piano di lavoro, calcolato sottraendo il lavoro avanzato dalla stima totale",
        EstimatedWorkTooltip: "Lavoro stimato sulle attività del piano di lavoro",
        ReestimatedWorkWithWorkflowSpeedTitle: "Lavoro stimato con velocità del piano",
        ReestimatedWorkWithWorkflowSpeedTooltip:
            "Lavoro stimato sulle attività del piano di lavoro, ristimato con la velocità del piano",
        ReestimatedWorkWithJobOrderSpeedTitle: "Lavoro stimato con velocità della commessa",
        ReestimatedWorkWithJobOrderSpeedTooltip:
            "Lavoro stimato sulle attività del piano di lavoro, ristimato con la velocità della commessa",
        WorkedHoursTitle: "Lavoro svolto (ore)",
        RemainingHoursBasedOnWorkflowSpeedTitle: "Lavoro rimanente con velocità del piano",
        RemainingHoursBasedOnWorkflowSpeedTooltip:
            "Lavoro rimanente sulle attività del piano di lavoro, ristimate utilizzando la velocità del piano di lavoro",
        RemainingHoursBasedOnJobOrderSpeedTitle: "Lavoro rimanente con velocità della commessa",
        RemainingHoursBasedOnJobOrderSpeedTooltip:
            "Lavoro rimanente sulle attività del piano di lavoro, ristimate utilizzando la velocità di commessa",
        WorkflowSpeedTitle: "Velocità del piano",
        JobOrderSpeedTitle: "Velocità della commessa",
        BudgetFromCustomerOrdersTitle: "Budget da ordini cliente",
        RequestedBudgetTitle: "Budget richiesto",
        AuthorizedBudgetTitle: "Budget autorizzato",
        EstimatedCostTitle: "Costo stimato",
        ProgressedEstimatedCostTitle: "Costo stimato avanzato",
        RemainingEstimatedCostTitle: "Costo stimato rimanente",
        NextMilestoneTitle: "Prossima milestone",
        RemainingMilestonesTitle: "Milestone rimanenti",
        AllocationStatusTitle: "Stato allocazione",
        FirstAllocationDateTitle: "Data inizio allocazione",
        LastAllocationDateTitle: "Data fine allocazione",
        AllocationEndDateTitle: "Data fine lavoro",
        AllocationEndDateTooltip:
            "Data impostata sul carrello come data di fine lavoro. Se le attività del piano sono distribuite in più carrelli, viene presa la data di fine maggiore.",
        RelatedDocumentsStatusTitle: "Documenti collegati",
        WorkflowAlertsTitle: "Avvisi del piano",
        JobOrderAlertsTitle: "Avvisi della commessa",
        ReestimatedCostWithWorkflowSpeedTitle: "Costo stimato con velocità del piano",
        ReestimatedCostWithWorkflowSpeedTooltip:
            "Costo stimato sulle attività del piano di lavoro in base alla velocità del piano",
        ReestimatedCostWithJobOrderSpeedTitle: "Costo stimato con velocità della commessa",
        ReestimatedCostWithJobOrderSpeedTooltip:
            "Costo stimato sulle attività del piano di lavoro in base alla velocità della commessa",
        DetailedBlogButtonTooltip: "Vai ad appunti di lavoro",
        WorkflowEditorButtonTooltip: "Modifica il piano di lavoro",
        WorkflowTasksEditorButtonTooltip: "Modifica le attività del piano di lavoro",
        ViewAllJobOrders: "Visualizza tutte le commesse",
        LogicalState: "Stato logico commessa",
        LogicalStateAll: "Tutte",
        LogicalStateOpened: "Aperte",
        LogicalStateClosed: "Chiuse",
        WorkflowsAnalysisResultsCount: "Risultati:",
        JobOrderLogicalStateTitle: "Stato logico commessa",
        WorkflowLogicalStateTitle: "Stato logico piano",
        WorkflowTodo: "Da fare",
        WorkflowInProgress: "In corso",
        WorkflowDone: "Completato",
        WorkflowVerified: "Verificato",
    },
    Planning: {
        PlanningAppCode: "Planning",
        ApplicationName: "Pianificazione",
        PlanningURL: "Planning",
        AvailableWorkPerDay: 'Forza lavoro "{0}"',
        Today: "Oggi",
        JobOrderEditingDialogTitle: 'Modifica Commessa "{0}"',
        WorkflowEditingDialogTitle: 'Modifica Flusso "{0}"',
        ShowGlobalEconomics: "Analisi Aziendale",
        ShowPlanning: "Pianificazione",
        ShowPerformance: "Performance",
        NoRightsToStartApplication: "Non si dispone dei diritti per avviare questa applicazione",

        JobOrdersPerformance: "Performance Commesse",
        CostsAndRevenuesExport: "Esporta Costi/Ricavi",

        Details: "Dettagli",

        Costs: "Costi",
        Revenues: "Ricavi",
        MarginProfit: "Guadagni",

        ExpectedCosts: "Costi attesi",
        ExpectedRevenues: "Ricavi attesi",
        RealCosts: "Costi effettivi",
        RealRevenues: "Ricavi effettivi",
        ExpectedMarginProfit: "Guadagni attesi",
        RealMarginProfit: "Guadagni effettivi",

        ExportCostsRevenues: "Esporta Costi/Ricavi",
        AnalisysPeriod: "Periodo di Analisi",
        SnapshotSelectorPlaceholder: "Seleziona uno snapshot...",
        Refresh: "Aggiorna",

        DocumentsDate: "Data documenti",
        DocumentsDatePeriodTooltip: "Calcolo ricavi sulla data dei documenti",

        WorkProgress: "Avanzamento Lavori",
        WorkProgressPeriodTooltip: "Calcolo ricavi sull'avanzamento da analisi cost-to-cost",

        ProgressWithRelateWorkflows: "Avanzamento con Attribuzione a Piani",
        ProgressWithRelateWorkflowsTooltip:
            "Calcolo ricavi sull'avanzamento da analisi cost-to-cost con attribuzione a piani e visualizzazione del ricavo non attribuito",

        Totals: "TOTALE",

        EnableDataEditing: "Abilita la modifica dei valori in tabella",
        DisableDataEditing: "Termina modifiche",
        CreateSnapshot: "Crea nuovo snapshot",
        EditSnapshot: "Modifica snapshot",

        SnapshotTitle: "Titolo",
        SnapshotTitlePlaceholder: "Titolo snapshot",

        NewSnapshot: "Nuovo snapshot",
        SelectSnapshot: "Seleziona snapshot...",
        SnapshotReferenceDate: "Data di riferimento",
        SnapshotReferenceDatePlaceholder: "Data...",
        InvalidReferenceDate:
            "La data di riferimento non può essere precedente alla data dello snapshot di riferimento.",
        LooseChangesAlert: "Eventuali modifiche verranno perse. Continuare?",
        DeleteSnapshotConfirm: "Sei sicuro di voler eliminare lo snapshot?",
        MissingSnapshotToEdit: "Selezionare lo snapshot da modificare",
        MissingParentSnapshot: "Selezionare lo snapshot di riferimento",
        MissingReferenceDate: "Selezionare la data di riferimento",
        MissingTitle: "Inserire un titolo per lo snapshot",
        RemoveRowMessage: "Sei sicuro di voler eliminare la riga?",
        SaveSnapshotSuccess: "Snpashot salvato.",
        SnapshotDeleted: "Snapshot eliminato.",
        CostsStatusLegend: "Legenda Stato Costi",
        CostsStatusLegendTitle: "Legenda Stato Costi",

        CostsStatusLegendGreenQuestionDescription:
            "L'indicatore di stato dei costi è verde con un punto di domanda se l'avanzamento è inferiore o uguale al 3% (caso in cui non è possibile calcolare un valore accurato).",
        CostsStatusLegendGreenCheckDescription:
            "L'indicatore di stato dei costi è verde con un segno di spunta se l'avanzamento è superiore al 3% e il rapporto della differenza tra costi effettivi e costi attesi rispetto ai costi attesi sulle attività chiuse, per ogni tipologia di costo, è inferiore al 5%.",
        CostsStatusLegendOrangeCheckDescription:
            "L'indicatore di stato dei costi è arancione con un segno di spunta se l'avanzamento è superiore al 3%, c'è almeno un rapporto della differenza tra costi effettivi e costi attesi rispetto ai costi attesi sulle attività chiuse che si trova tra il 5% (escluso) e il 15% e nessun rapporto supera il 15%.",
        CostsStatusLegendRedTimesDescription:
            "L'indicatore di stato dei costi è rosso con una X se l'avanzamento è superiore al 3% e non è presente nessuna stima di costo oppure almeno un rapporto della differenza tra costi effettivi e costi attesi rispetto ai costi attesi sulle attività chiuse è maggiore del 15%.",
    },
    ProlifeSdk: {
        AdvancedTimeEditorTitle: "Modifica Ore Avanzata",
        TimeZoneTitle: "Fuso Orario",
        DateTitle: "Data",
        BlogBaseMailBody: "Oggetto:$20{0}%0AData%20evento:%20{1}$0A{2}{3}",
        BlogBaseMailReferenceJobOrder: "Commessa%20di%20riferimento:%20{0}%0A",
        BlogBaseMailDescription: "Descrizione:%0A{0}%0A",
        BlogBaseMailSubject: "ProLife%20-%20Evento%20appunti%20di%20lavoro",
        BlogMailBody:
            "In%20Qualità:%20{0}%0AAmministrazione:%20{1}%0ACommerciale:%20{2}%0ATecnico:%20{3}%0APriorità:%20{4}%0AStato:%20{5}%0AFirmato:%20{6}%0A",
        BlogMailAttachments: "%0A%0ADocumenti%20Allegati:%0A",
        Yes: "Si",
        No: "No",
        New: "Nuovo",
        WarningEventAlreadySigned: "Attenzione! L'evento è già stato firmato!",
        InvalidOperation: "Operazione non valida",
        ConfirmChangeEventState: "Sei sicuro di voler cambiare lo stato di questo evento?",
        DoNotChangeState: "Annulla",
        ChangeState: "Cambia lo stato",
        UndefinedEvent: "Evento non definito",
        ConfirmDeleteEvent:
            "Sei sicuro di voler eliminare l'evento? Questa operazione potrebbe rendere inconsistenti i dati della commessa.",
        DoNotDeleteEvent: "Non eliminare",
        DeleteEvent: "Elimina",
        SelectReferenceJobOrderToUploadAttachments:
            "Per poter caricare nuovi file è necessario selezionare la commessa di riferimento",
        DefaultAttachmentUploadPathForJobOrder: "/Commesse/{0}/{1}",
        NoJobOrder: "Nessuna commessa",
        ConfirmAttachmentNewVersion:
            "Nella cartella di destinazione esiste già un file con questo nome. Si desidera creare una nuova versione del file?",
        DoNotAttachNewVersion: "Annulla caricamento",
        AttachNewVersion: "Crea nuova versione",
        ErrorUploadingAttachments:
            "Si è verificato un errore durante l'upload del file {0}. Verificare di non aver sforato la quota disco.",
        ConfirmDeleteComment: "Sei sicuro di voler rimuovere il commento?",
        DoNotDeleteComment: "Non eliminare",
        DeleteComment: "Elimina",
        NewComment: "Nuovo commento",
        Resources: "Risorse",
        AddressBook: "Rubrica",
        JobOrderLockedByWithMotivation: "Commessa bloccata da {0} con motivazione: {1}",
        Saving: "Salvataggio in corso...",
        Loading: "Caricamento in corso...",
        SelectJobOrder: "Inserire la commessa",
        Signature: "Firma",
        Done: "Fine",
        EventSignature: "Firma Evento",
        WarningNoSignature:
            "Attenzione! Non è stata inserita una firma. Firmare l'evento prima di procedere al salvataggio",
        WarningNoSignatureTitle: "Firma non inserita",
        Users: "Utenti",
        Document: "Documento",
        SubjectRequired: "Oggetto obbligatorio",
        DocumentEventMailSubject: "ProLife - Documento del {0}",
        CompactDetailDescription:
            "I campi della riga del documento amministrativo sono impaginati su riga singola. Vengono mostrati tutti i campi e ridotto il font.",
        CompactDetailTitle: "Layout dettagliato compatto",
        CompactDetailShortTitle: "Compatto",
        DetailedDetailDescription:
            "I campi della riga del documento amministrativo vengono impaginati su due righe. Sulla prima è presente solo il campo descrizione mentre sulla seconda sono presenti gli altri campi.",
        DetailedDetailTitle: "Layout dettagliato",
        DetailedDetailShortTitle: "Dettagliato",
        StandardDetailDescription:
            "I campi della riga del documento amministrativo sono impaginati su riga singola. Vengono mostrati solo i campi principali (Descrizione, Q.tà, Importo unitario netto, Codice IVA e Importo netto).",
        StandardDetailTitle: "Layout standard",
        StandardDetailShortTitle: "Standard",
        DocumentReferencesMap: "Mappa riferimenti documento",
        JobOrderNotFound: "(Commessa non trovata)",
        CustomerNotFound: "(Cliente non trovato)",
        PricesReview: "Verifica prezzi",
        LastBuyPrice: "Ul.Pr.Acq.",
        LastSupplierBuyPrice: "Ul.Pr.Acq. dal Fornitore",
        AvgBuyPrice: "Pr.Med.Acq.",
        AvgTodayBuyPrice: "Pr.Med.Acq. ad oggi",
        AvgSupplierBuyPrice: "Pr.Med.Acq. dal Fornitore",
        LastCustomerSellPrice: "Ul.Pr.Ven. al Cliente",
        LastSellPrice: "Ul.Pr.Ven.",
        InsertCustomerBusinessName: "Inserire la ragione sociale del cliente",
        InsertCustomerName: "Inserire il nome del cliente",
        InsertCustomerSurname: "Inserire il cognome del cliente",
        InsertAtLeastOneRow: "Inserire almeno una riga",
        DocumentSavedSuccessfully: "Documento salvato con successo!",
        Map: "Mappa",
        DefaultValues: "Val.Pred.",
        Edit: "Modifica",
        Print: "Stampa",
        ReviewPrices: "Ver.Prezzi",
        Close: "Chiudi",
        ConfirmCloseOpenRows: "Sei sicuro di voler chiudere manualmente tutte le right aperte?",
        DoNotCloseOpenRows: "Non chiudere",
        CloseOpenRows: "Chiudi",
        RowContentAnalisys: "Analisi dei contenuti di riga",
        InsertDocumentDate: "Inserire la data",
        UnknownProtocol: "(Protocollo sconosciuto)",
        Other: "(Altro)",
        DocAttachmentsSuffix: "_allegati",
        UnknownFiscalYear: "(Esercizio sconosciuto)",
        AdministrativeDocumentsFolderDefault: "/Documenti Amministrativi/{0}/{1}/{2}/",
        ConfirmAttachFile:
            "ATTENZIONE : Le seguenti informazioni sono necessarie a calcolare la corretta destinazione dell'allegato:<br><br><ul><li>Data</li><li>Numero</li></ul> <br>Continuando il file sarà allegato nella seguente cartella di destinazione : <br><br>{0}<br><br>Si consiglia di salvare <b>prima</b> il documento e <b>solo successivamente</b> di allegare il file.<br><br> Allegare comunque il file?",
        DoNotAttachFile: "Non allegare",
        DoAttachFile: "Allega comunque",
        ConfirmSaveWithWarnings: "Sono state rilevate le seguenti incoerenze:<br><br>{0}<br>Salvare comunque?",
        DoNotSave: "Non salvare",
        SaveAnyway: "Salva comunque",
        ErrorWhileSavingDocument: "Si è verificato un errore durante il salvataggio del documento!",
        DefaultDocumentFooter:
            '<p style="text-align:center"><strong>{0}</strong> - {1} - {2} {3} ({4})<br/>tel.{5} - fax {6} - e-mail: {7} - web: {8}<br/>P.iva: {9} - Reg. imp. {10} - Iscr. R.E.A. {11} - Cap. Soc. {12}</p>',
        DefaultDocumentFooter1: "{0} {1} - {2} {3} ({4}) - tel.{5} - fax. {6}",
        DefaultDocumentFooter2: "e-mail: {0} - web: {1} - Reg.imp {2}",
        DefaultDocumentFooter3: "p.iva: {0} - Iscr. R.E.A. {1} - Cap.Soc. {2}",
        AdministrativeNotes: "Note Amministrative",
        TextsAndDates: "Testi e date",
        SelectDataSources: "Seleziona sorgenti dati",
        SelectAtLeastOneDataSource: "Selezionare almeno una sorgente dati",
        FoundCustomers: "Clienti trovati",
        Suggestions: "Scelte indicate",
        InternalWarehouses: "Magazzini interni",
        Abort: "Annulla",
        Confirm: "Conferma",
        Delete: "Elimina",
        Download: "Scarica",
        Save: "Salva",
        Reset: "Resetta",
        Import: "Importa",
        Layout: "Layout",
        DocumentAttachmentsSizeOverMailLimit: "Attenzione!",
        ConfirmDeleteDocument: "Sei sicuro di voler eliminare questo documento?",
        DoNotDeleteDocument: "Ritorna al Documento",
        DeleteDocument: "Elimina il Documento",
        ConfirmLooseDocumentChanges:
            "Sono state apportate delle modifiche, annullando verranno perse.\nSei sicuro di voler continuare?",
        DoNotLooseDocumentChanges: "Ritorna al Documento",
        LooseDocumentChanges: "Annulla Modifiche",
        InsertCustomerVATCode: "Inserire la partita iva del cliente",
        CustomerVATCodeInvalid: "La partita iva del cliente non è valida",
        CustomerBusinessNameRequired: "Nome azienda del cliente obbligatorio",
        CustomerNameRequired: "Nome del cliente obbligatorio se non è specificato il cognome",
        CustomerSurnameRequired: "Cognome del cliente obbligatorio se non è specificato il cognome",
        CustomerNationRequired: "La Nazione del cliente è obbligatoria",
        InsertCustomerTaxCode: "Inserire il codice fiscale del cliente",
        InsertCustomerState: "Inserire la nazione del cliente",
        CustomerTaxCodeInvalid: "Il codice fiscale del cliente non è valido",
        InsertPaymentIBAN: "Inserire l'IBAN di pagamento",
        InsertPaymentABI: "Inserire l'ABI di pagamento",
        InsertPaymentCAB: "Inserire il CAB di pagamento",
        ReportColumnsInformations: "Informazioni Colonne Report",

        CustomerOrderDocumentType: "Ordine cliente",
        InvoiceDocumentType: "Fattura",
        DDTDocumentType: "DDT",

        PaymentInfoFromImportsDialogTitle: "Informazioni di pagamento dei documenti",
        PaymentInfoFromImportsDialogMessage:
            "I documenti importati hanno scadenze e/o modalità di pagamento non omogenee. Selezionare la modalità e la scadenza di pagamento da usare per il documento di destinazione:",
        PaymentInfoFromRefDocumentRowsNotFound:
            "Impossibile caricare modalità e scadenza di pagamento: nessun dato trovato",
        DocumentNumberRequiredIfOnEdit:
            "Il numero del documento è obbligatorio quando si è in modifica di un documento già salvato",

        InsertPaymentMode: "Inserire la modalità di pagamento",
        InsertExpiryType: "Inserire il tipo di scadenza del pagamento",
        InsertDocumentValidity: "Inserire il periodo di validità del documento",
        PaymentInfoLoadedFromImportsMessage: "Informazioni di pagamento caricate da: {0}.",
        PaymentInfoLoadedFromImportsEmptyDefaultsMessage: "Nessuna informazione di pagamento trovata.",
        PaymentInfoLoadedFromImportsMessageWithConsistentData:
            "Informazioni di pagamento caricate da: {0}.<br/><br/>Le informazioni dei documenti e i valori dei default di protocollo sono coerenti.",
        PaymentInfoLoadedFromImportsMessageWithConsistentDataButDifferentSources:
            "Modalità di pagamento caricata da: {0}.<br/><br/>Scadenza di pagamento caricata da: {1}<br/><br/>.Le informazioni dei documenti e i valori dei default di protocollo sono coerenti.",

        ChooseDocumentExportTitle: "Esportazione documento",
        ChooseDocumentExportMessage: "Si desidera esportare in Excel?",

        SecondaryRecipientsOnInvoiceWithLegalPerson:
            "ATTENZIONE!! Le fatture cointestate possono essere emesse solamente a persone fisiche, ma il destinatario selezionato è una persona giuridica. Continuare?",
        ConfirmSecondaryRecipient: "Continua",
        AbortSecondaryRecipient: "Annulla",
        RowsWithZeroAmountButReferencesMessage:
            "ATTENZIONE!! Sono presenti righe con quantità a zero che fanno riferimento ad altri oggetti (es. articoli di magazzino, acquisti, altri documenti ecc...). Salvando il documento i riferimenti andranno persi.",
        RowsWithZeroAmountButReferencesCancel: "Torna al documento",
        RowsWithZeroAmountButReferencesConfirm: "Salva comunque",

        SelectDocumentDate: "Selezionare la data del documento",
        DocumentsJournalDialogTitle: "Giornale documentale del {0}",

        ListComponentEmptyResult: "Nessun elemento disponibile",
        MenuComponentEmptyResult: "Nessun risultato",

        NotAvailable: "N/A",
        ExcelExporterError:
            "Si è verificato un errore imprevisto durante l'esportazione excel, contattare l'assistenza. Ci scusiamo per il disagio.",

        ClosedAmountOverflowMessage: "Valore importato {0} di {1}",
        SearchPlaceholder: "Cerca...",

        MultipleRelatedWorkflows: "Piani multipli",
        InvalidDocumentRelatedWorkflow:
            "Una o più righe non hanno un piano di lavoro selezionato. Selezionare un piano su tutte le righe o eliminare le righe vuote.",

        WarehouseMovementsChangesDialogTitle: "Salvataggio documento",

        Description: "Descrizione",
        Amount: "Quantità",
        UnitCost: "Costo unitario",
        Cost: "Costo",
        Markup: "Ricarico (%)",
        UnitPrice: "Prezzo unitario",
        Discount: "Sconto (%)",
        NetUnitPrice: "Prezzo unitario netto",
        Price: "Ricavo",

        Select2Placeholder: "Seleziona....",
        Apply: "Applica",
        Load: "Carica",

        SettingsheaderMessage: "Per applicare i cambiamenti, dopo aver salvato, ricaricare la pagina.",
        WorkflowOutcomeChangesAlert:
            "L'esito dei seguenti piani di lavoro è diverso da quello impostato sul documento. Scegli quale operazione effettuare (cliccando il pulsante chiudi verrà eseguita l'operazione di default):",
        WorkflowOutcomeChangesAlertTitle: "Modifiche agli esiti dei piani di lavoro",

        GenericError: "Si è verificato un errore imprevisto, contattare l'assistenza. Ci scusiamo per il disagio.",

        DocumentRelatedWorkflowWithLowerThanZeroAmountError:
            "Impossibile salvare il documento. Le seguenti righe hanno almeno un riferimento ad un piano con quantità inferiore a zero: {0}",
        EnableEditMode: "Abilita modifica",

        Default: "Default",
        EmptyTable: "Nessun elemento disponibile",
        ClearTable: "Svuota la tabella",

        KeyboardPanelSectionsTitle: "Sezioni",
        KeyboardPanelOpenedSection: "La sezione è aperta",
        KeyboardPanelClosedSection: "La sezione è chiusa",
        KeyboardPanelSaveConfiglabel: "Salva impostazioni",
        KeyboardPanelLoadConfiglabel: "Carica impostazioni",

        Actions: "Azioni",

        SelectAll: "Seleziona/deseleziona tutto",

        EmptyTableFilterItem: "(Vuoto)",

        SettingManagerReloadAlert: "Per applicare i cambiamenti, dopo aver salvato, ricaricare la pagina.",
        DefaultDocumentMetadatasLabel: "Impostazioni campi personalizzati",
        DefaultMetadataColumn: "Campo",
        DefaultMetadataConfigurationsColumn: "Configurazioni",
        DefaultMetadataTypeColumn: "Tipologia",
        DefaultMetadataValueColumn: "Valore",
        DefaultMetadataSelectorPlaceholder: "Seleziona...",
        RemoveMetadataConfirmMessage: "Sei sicuro di voler rimuovere il metadato?",
        MetadataError: "Errori nei default dei metadati ({0}): {1}",

        MissingMetadataError: "metadato mancante; selezionare un metadato su tutte le righe specificate.",

        TextInputPlaceholder: "Testo",
        IntNumericInputPlaceholder: "Numero intero",
        NumericInputPlaceholder: "Numero",
        DateTimeInputPlaceholder: "Data",

        DateRangePickerStartDateLabel: "Inizio",
        DateRangePickerStartDatePlaceholder: "Data...",
        DateRangePickerEndDateLabel: "Fine",
        DateRangePickerEndDatePlaceholder: "Data...",

        Sum: "Somma",
        Avg: "Media",
        Max: "Massimo",
        Min: "Minimo",
        Count: "Conteggio",
        DistinctCount: "Conteggio valori diversi",
        Column: "Colonna",
        Aggregator: "Aggregatore",
        TableColumnWidth: "Larghezza colonna",
        TableColumnHeight: "Altezza colonna",
        TableColumnWidthPlaceholder: "Automatica",
        TableColumnHeightPlaceholder: "Automatica",
        TableColumnTextColor: "Colore testo",
        TableColumnBackgroundColor: "Colore sfondo",
        TableColumnContentSettings: "Contenuto colonna",
        BreakText: "Testo a capo",

        MinValue: "Valore minimo",
        MaxValue: "Valore massimo",

        TableColumnsConfigurationTab: "Configurazione colonne",
        TableColumnsConfigurationsManagerTab: "Gestione configurazioni",

        NewTableConfiguration: "Nuova configurazione",
        EditTableConfiguration: "Modifica configurazione",
        EditTableConfigurations: "Modifica",
        TableNewConfigurationPlaceholder: "Titolo",
        TableFilters: "Filtra",
        SaveTableConfigForUser: "Salva",
        SaveTableConfigForAllUsers: "Salva per tutti",
        TableConfigurationMissingTitle: "Titolo mancante",
        DeleteTableConfigurationMessage: "Sei sicuro di voler cancellare questa configurazione?",
        TableConfigurationHelpText:
            'ATTENZIONE: al salvataggio viene "catturata" l\'attuale configurazione della tabella e salvata, quindi, se si è in modifica di una configurazione già slavata, questa verrà sovrascritta.',
        InvalidTableRowDrop: "Non è possibile droppare elementi provenienti da un'altra azienda.",

        AlertsLegendTitle: "Legenda avvisi",
        Attachments: "Allegati",
        NewAttachmentButton: "Nuovo",
        EmptyAttachmentsList: "Nessun file allegato",
        AttachDocument: "Allega documento",
        AttachTemplate: "Allega modello",
        DeleteSelectedAttachments: "Elimina selezionati",
        DownloadAttachment: "Scarica allegato",
        AttachmentTemplate: "MODELLO",
        AttachmentVersion: "Versione",

        Search: "Cerca",
        SearchAll: "Cerca tutto",
        SearchOnlyInList: "Cerca solo in questa lista",
        EmptyList: "Nessun risultato",

        MinimumInputLengthSingular: "Inserisci ancora {0} carattere",
        MinimumInputLengthPlural: "Inserisci ancora {0} caratteri",

        SelectOrDeselectMenuItem: "Seleziona/deseleziona l'elemento",
        UnallowedFilesDrop: "I seguenti file non sono supportati: {0}",

        EmptyTableFilterLabel: "(Vuoto)",
        ApplicationConfiguationSaveSuccess: "Configurazione salvata correttamente",

        ChangesForAllUsersConfigurationMessage:
            "ATTENZIONE!! Con questo salvataggio la configurazione non sarà più visibile agli altri utenti. Salvare comunque?",
        ChangesUserConfigurationMessage:
            "ATTENZIONE!! Con questo salvataggio renderai la configurazione visibile a tutti utenti. Salvare comunque?",

        Selected: "Selezionato",
        Unselected: "Non selezionato",

        TableColumnsManagement: "Gestione colonne",
        TableColumnsManagementWithConfiguration: "Gestione colonne (config. applicata: {0})",
        DropDefaultMessage: "Rilascia quì gli elementi.",
        Unlimited: "Nessun limite",
        NoValue: "Nessun valore",

        AwaitingForAuthentication: "In attesa di autenticazione...",

        SendDocumentMail: "Invio automatico",
        IncludeDocumentAttachmentsInMailSend: "Includi allegati al documento",
        MailTemplate: "Modello mail",
        MailTo: "Destinatari mail",
        MailCc: "Destinatari mail in copia",
        MailBcc: "Destinatari mail in copia nascosta",
        MailRecipientTypes: "Tipologia indirizzi mail",
        MailRecipientType: "Tipologia di indirizzo",
        FixedAddress: "Indirizzo mail fisso",

        AdministrativeContact: "Contatto amministrativo",
        CommercialContact: "Contatto commerciale",
        TechnicalContact: "Contatto tecnico",
        ElectronicInvoicingPEC: "PEC per la fatturazione elettronica",
        OtherContact1: "Altro contatto 1",
        OtherContact2: "Altro contatto 2",
        OtherContact3: "Altro contatto 3",
        OtherContact4: "Altro contatto 4",
        OtherContact5: "Altro contatto 5",

        MailRecipientTypePlaceholder: "Seleziona...",
        MailSendSettings: "Impostazioni invio mail",
        SystemMessage: "Messaggio di sistema",
        ServiceInMaintenanceMode: "Il servizio è attualmente in manutenzione. Riprovare più tardi.",
    },
    Reports: {
        Report: "Report",
        Reports: "Reports",
        ReportTypes: "Tipologie",
    },
    Schedule: {
        BillBook: "Scadenzario",
        BillBookSettingsGroup: "G. Scadenzario",
        Unpaid: "Non pagato",
        Paid: "Pagato",
        Canceled: "Annullato",
        DeadlineStates: "G.010 Stati Scadenze",
        Loading: "Caricamento in corso...",
        Saving: "Salvataggio in corso...",
        InvoicesWithExpiredMaturities: "Fatture con scadenze scadute",
        SelectInvoice: "Seleziona una fattura",
        UpcomingDeadlines: "Prossime scadenze",
        PaidInvoices: "Fatture pagate",
        InvoiceNumberOf: "Fattura n° {0} del {1}",
        ConfirmLooseChanges: "Tutte le modifiche non salvate andranno perse. Proseguire comunque?",
        DoNotLooseChanges: "Continua le modifiche",
        LooseChanges: "Chiudi comunque",
        ErrorWhileSaving: "Si è verificato un errore durante il salvataggio",
        InsertDeadlineTitle: "Inserire il titolo della scadenza {0} (almeno 1 carattere)",
        DeadlineAmountIsZero: "Importo della scadenza {0} ha valore 0.",
        InvalidDeadlineAmountForCreditNote:
            "Importo della scadenza {0} deve essere negativo. Le scadenze per le note di credito devono essere impostate in negativo.",
        InvalidDeadlineAmountForInvoice:
            "Importo della scadenza {0} deve essere positivi. Le scadenze delle fatture devono essere impostate in positivo.",
        InvalidEsxchangeValueForSchedule:
            "Il valore di cambio della scadenza {0} deve essere positivo. Non è possibile impostare cambi inferiori o uguai a zero.",
        InsertExpiryDate: "Inserire la data di scadenza {0}",
        InsertPaymentDate: "Inserire la data di pagamento {0}",
        PaymentDateIsBeforeExpiryDate: "La data di pagamento {0} è precedente alla data di scadenza",
        ExpiryDateIsBeforeInvoiceDate: "La data della scadenza {0} è precedente alla data di emissione della fattura",
        ExpiryDateIsAfterNextDeadline: "La data della scadenza {0} è successiva alla data della scadenza successiva",
        InvoiceAmountIsNotEqualToSum:
            "L'importo della fattura è diverso rispetto la somma degli importi delle scadenze",
        ErrorsAlert: "Attenzione prima di procedere correggere i seguenti problemi:<br><br> - {0}\r\n",
        WarningsAlert:
            "Attenzione prima di procedere si consiglia di verificare:<br><br> - {0}\r\nSi desidera continuare con il salvataggio dei dati?",
        WarningsAlertAbort: "Annulla",
        WarningsAlertContinue: "Conferma le modifiche",
        ScheduleDescription: "{0} ({1} {2}) {3}",
        ScheduleDescriptionPaidDay: " il {0}",
        ScheduleDescriptionExpired: " Scaduto",
        Notes: "Note",
        BillBookURL: "#/Scadenzario",
        BillBookForInvoiceURL: "#/Scadenzario/{0}",
        Schedule: "Scadenza",
        Schedules: "Scadenze",
        EntityFieldNameDescription: "Descrizione",
        EntityFieldNamePaymentMode: "Tipo pagamento",
        EntityFieldNameTotal: "Importo",
        EntityFieldNamePaymentDate: "Data pagamento",
        EntityFieldNameExpireDate: "Data scadenza",

        ScheduleStateSaveError: "Si è verificato un errore durante il salvataggio dello stato della scadenza.",

        PaymentIBANRequired: "Inserire l'IBAN di pagamento",
        PaymentABIRequired: "Inserire l'ABI di pagamento",
        PaymentCABRequired: "Inserire il CAB di pagamento",
        PaymentModeRequired: "Inserire la modalità di pagamento",
    },
    Settings: {
        SettingsDefaultUrl: "/#/Impostazioni",
        SettingsURL: "#/Impostazioni",
        InsertBankName: "Inseriere la descrizione agenzia",
        InsertABI: "Inserire l'ABI",
        InsertCAB: "Inserire il CAB",
        InsertCompanyName: "Inserire la ragione sociale dell'azienda",
        InsertCompanyAddress: "Inserire l'indirizzo dell'azienda",
        InsertVATCode: "Inserire la partita IVA",
        InsertPostalCode: "Inserire il CAP dell'azienda",
        InsertCity: "Inserire la città dell'azienda",
        InsertMunicipality: "Inserire il comune dell'azienda",
        InsertDistrict: "Inserire la provincia dell'azienda",
        InsertState: "Inserire la nazione dell'azienda",
        UnsupportedFormat: "Formato non supportato",
        LogoTooBig: "L'immagine del logo deve avere una dimensione inferiore a 500 KByte",
        CompanyInformationsUpdated: "I dati dell'azienda sono stati aggiornati!",
        CompanyInformations: "A.010 Dati Azienda",
        ConfirmLooseChanges:
            "Ci sono modifiche non salvate!\nContinuando si perderanno le modifiche effettuate!\nSei sicuro di voler continuare?",
        DoNotLooseChanges: "Non continuare",
        LooseChanges: "Continua",
        Settings: "Impostazioni",
        CompanySettingsGroup: "A. Azienda",
        Holidays: "A.020 Festività",

        CountriesLists: "Nazioni e Province",
        NationsSettings: "Elenco Nazioni",
        ProvincesSettings: "Elenco Province",

        RF01_Ordinary: "Ordinario",
        RF02_MinimumTaxPayer: "Contribuenti minimi (art.1, c.96-117, L. 244/07)",
        RF04_FarmingFishingAndRelatedActivities:
            "Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)",
        RF05_SaltAndTobacco: "Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)",
        RF06_MatchstickTrade: "Commercio fiammiferi (art.74, c.1, DPR  633/72)",
        RF07_PublishingIndustry: "Editoria (art.74, c.1, DPR  633/72)",
        RF08_PublicTelephonyServices: "Gestione servizi telefonia pubblica (art.74, c.1, DPR 633/72)",
        RF09_PublicTransportDocumentsReselling:
            "Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR  633/72)",
        RF10_EntertainmentGamesAndRelatedActivities:
            "Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)",
        RF11_TravelAgency: "Agenzie viaggi e turismo (art.74-ter, DPR 633/72)",
        RF12_Agritourism: "Agriturismo (art.5, c.2, L. 413/91)",
        RF13_DoorToDoorSales: "Vendite a domicilio (art.25-bis, c.6, DPR  600/73)",
        RF14_AntiqueSecondHandGoodsAndWorksOfArtReselling:
            "Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)",
        RF15_AntiqueSecondHandGoodsAndWorksOfArtAuctioningReselling:
            "Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)",
        RF16_CashAccountingPA: "IVA per cassa P.A. (art.6, c.5, DPR 633/72)",
        RF17_CashAccounting: "IVA per cassa (art. 32-bis, DL 83/2012)",
        RF18_Other: "Altro",
        RF19_FlatRateScheme: "Regime forfettario (art.1, c.54-89, L. 190/2014)",

        NewHoliday: "Nuovo",
        EditHoliday: "Modifica",
        AddHoliday: "Aggiungi",
        HolidaysSaved: "Salvataggio riuscito",
    },
    Todolist: {
        Alerts: "Avvisi: ",
        Status: "Stato",
        ClosedTasks: "Attività chiuse",
        SureToDeleteBudgetRequestCause: "Sei sicuro di voler eliminare la causale?",
        DoNotDeleteBudgetRequestCause: "Non eliminare",
        DeleteBudgetRequestCause: "Elimina",
        Audit: "Revisione",
        InternallyPaid: "A carico interno",
        CustomerPaid: "A carico cliente",
        NewCategory: "Nuova categoria",
        SureToDeleteCategory: "Sei sicuro di voler eliminare la categoria?",
        DoNotDeleteCategory: "Non eliminare",
        DeleteCategory: "Elimina",
        SureToDeleteState: "Sei sicuro di voler eliminare lo stato?",
        DoNotDeleteState: "Non eliminare",
        DeleteState: "Elimina",
        SureToDeleteOutcome: "Sei sicuro di voler eliminare l'esito?",
        DoNotDeleteOutcome: "Non eliminare",
        DeleteOutcome: "Elimina",
        WorkflowStateNotStarted: "Non iniziato",
        WorkflowStateInProgress: "In corso",
        WorkflowStateCompleted: "Completato",
        WorkflowStateVerified: "Verificato",
        WorkflowOutcomeNotSet: "Non impostato",
        WorkflowOutcomePositive: "Positivo",
        WorkflowOutcomeNegative: "Negativo",
        Task: "Attività",
        Tasks: "Piani e attività",
        TasksSettingsGroup: "C. Piani e attività",
        BudgetRequestCauses: "C.060 Causali richiesta budget",
        Quality: "Qualità",
        QAEvents: "Eventi qualità",
        WorkflowCategories: "C.030 Categorie flussi",
        WorkflowStates: "C.020 Stati Flussi",
        TaskStates: "Stati Attività",
        WorkflowOutcomes: "C.040 Esiti Flussi",
        TaskStateChangeMailSubject: "ProLife - Cambio di stato attività",
        TaskDataChangeMailSubject: "ProLife - Cambio dettagli attività",
        WorkflowModels: "Modelli di flusso",
        SearchWorkflows: "Cerca flussi",
        SearchWorkflowsPlaceholder: "Cerca flussi...",
        SearchTasks: "Cerca attività",
        SearchTasksPlaceholder: "Cerca attività...",
        Workflows: "Flussi di lavoro",
        CreateFromModel: "Crea da modello",
        SelectWorkflows: "Seleziona flussi",
        Edit: "Modifica",
        NewWorkflow: "Nuovo flusso",
        EndEditing: "Termina modifiche",
        BrowseWorkflows: "Naviga flussi",
        Backlog: "Backlog",
        ToDo: "Da fare",
        InProgress: "In lavorazione",
        Completed: "Completati",
        Verified: "Verificati",
        VerifiedSingular: "Verificato",
        Suspended: "Sospesi",
        SuspendedSingular: "Sospeso",
        DeletedColumn: "Eliminati",
        DeletedSingular: "Eliminato",
        NewTask: "Nuova attività",
        TaskChangeMessage: "L'attività {0} è stata {1} dall'utente {2}",
        Modified: "modificata",
        Inserted: "inserita",
        Deleted: "eliminata",
        TaskTitlesCopiedToClipboard: "Il titolo delle attività selezionate è stato copiato negli appunti",
        TaskTitleCopiedToClipboard: "Il titolo dell'attività selezionata è stato copiato negli appunti",
        InProgressWithProgress: "In lavorazione ({0}%)",
        CompletedSingular: "Completato",
        SelectJobOrder: "Seleziona la commessa",
        SelectWorkflow: "Selezionare il flusso",
        SelectAWorkflow: "Selezionare un flusso",
        HumanResources: "Risorse umane",
        ResourcesGroups: "Gruppi di risorse",
        WarningChangesWillBeLost: "ATTENZIONE : Le modifiche non salvate andranno perse. Continuare comunque?",
        DoNotLooseChanges: "Continua a modificare",
        ContinueAndLooseChanges: "Continua e perdi le modifiche",
        SureToDeleteTask: "Sei sicuro di voler eliminare l'attività?",
        CannotDeleteTaskWithBillableHours:
            "L'attività non può essere eliminata perché sono presenti delle ore a rendicontazione. Eliminare le ore a rendicontazione prima di eliminare l'attività",
        SureToDeleteTaskWithBillableHours:
            "L'attività ha delle ore a rendicontazione. Se la elimini queste ore non saranno fatturabili. Sei sicuro di voler eliminare l'attività?",
        SureToChangeTaskStateWithBillableHours:
            "Una o più attività hanno delle ore a rendicontazione. Se le elimini queste ore saranno comunque fatturabili. Sei sicuro di voler eliminare le attività?",
        DoNotDeleteTask: "Non eliminare",
        DeleteTask: "Elimina",
        TaskUpdated: "Attività aggiornata",
        TaskInserted: "Attivià inserita",
        Loading: "Caricamento in corso...",
        LumpSum: "A corpo",
        Reporting: "A rendicontazione",
        NonBillable: "Non fatturabile",
        InsertTaskTitle: "Inserire il titolo dell'attività",
        SavingInProgress: "Salvataggio in corso...",
        Tags: "Etichette",
        Tag: "Etichetta",
        ImportTemplate: 'Importazione modello "{0}"',
        ImportWorkflow: 'Importazione flusso "{0}"',
        SelectATemplate: "Selezionare un modello",
        TemplateImportSummary: "Report di importazione modello",
        WorkflowImportSummary: "Report di importazione flusso",
        EditWorkflowTemplate: "Modifica modello di flusso",
        NewWorkflowTemplate: "Nuovo modello di flusso",
        SureToDeleteTemplate: "Sei sicuro di voler eliminare il modello?",
        DoNotDeleteTemplate: "Non eliminare",
        DeleteTemplate: "Elimina",
        InsertTemplateName: "Inserire il nome del modello",
        TemplateSaved: "Modello salvato",
        ImportInProgress: "Importazione in corso...",
        SureToLooseTemplateChanges:
            "Sono state apportate delle modifiche non salvate al modello. Proseguendo tali modifiche andranno perse. Continuare comunque?",
        DoNotLooseTemplateChanges: "Annulla",
        LooseTemplateChanges: "Continua senza salvare",
        ReportTemplatesFolder: "/Modelli Report",
        NewWorkflowFromTemplate: "Crea piano da modello",
        NewWorkflowFromWorkflow: "Copia piano",
        SelectTemplate: "Selezionare il modello",
        InsertWorkflowTitle: "Inserire il titolo del flusso",
        SelectReferenceJobOrder: "Selezionare la commessa di riferimento",
        EditWorkflow: "Modifica flusso",
        CreateNewWorkflow: "Crea nuovo flusso",
        ConfirmDeleteWorkflow:
            "Eliminando il flusso saranno eliminate anche tutte le attività collegate. Vuoi eliminare il flusso comunque?",
        ConfirmDeleteWorkflowWithBillableHours:
            "Stai eliminando un flusso avente attività collegate con ore a rendicontazione. Eliminando il flusso saranno eliminate anche tutte le attività collegate e le ore non saranno fatturabili. Vuoi eliminare il flusso comunque?",
        DoNotDeleteWorkflow: "Non eliminare",
        DeleteWorkflow: "Elimina",
        PersonalTodoList: "Todo List Personale",
        TaskBoard: "Task Board",
        EditTask: "Modifica attività",
        TemplatesEditor: "Editor dei modelli di flusso",
        NewTemplate: "Nuovo modello",
        All: "Tutti",
        Workflow: "Flusso",
        Template: "Modello di flusso",
        Templates: "Modelli di flusso",
        WorkflowChangeMailSubject: "ProLife - Cambio di stato flusso",

        EntityFieldNameStartDate: "Data inizio",
        EntityFieldNameTitle: "Titolo",
        EntityFieldNameDuration: "Durata",
        EntityFieldNameExpireDate: "Scadenza",

        EntityFieldNameWorkflowTitle: "Titolo",
        EntityFieldNameWorkflowExpectedStart: "Inizio previsto",
        EntityFieldNameWorkflowExpectedEnd: "Fine prevista",

        SelectDefaultRole: "Selezionare una mansione predefinita per il flusso prima di continuare",
        SelectCategory: "Selezionare una categoria per il flusso prima di continuare",
        UOMHours: "h/u",
        UnknownRole: "Mansione Sconosciuta",
        SelectARowToImport: "Selezionare almeno una riga da importare",
        SomeRowsWereAlreadyImported: "Alcune righe sono già state importate in questo documento",
        UnknownTask: "Attività non trovata",
        TaskReference: 'Attività "{0}"',

        EstimatedPurchases: "Previsioni Acquisti",

        NoCategory: "Senza Categoria",

        LinkIsNotValid: "Il prerequisito non è completo, inserire almeno la commessa ed il flusso",
        LinkIsNotValidTitle: "Prerequisito incompleto",

        TasksToWarehouse: "Articoli da Attività",
        NoArticlesAvailable: "Non sono presenti articoli",

        TaskTransformsDialogTitle: "Trasformazioni Articoli",
        StateChangesSaved: "Date di riferimento salvate!",

        JobOrderIsLateStart: "Il progetto contiene attività in ritardo sulla data di inizio",
        JobOrderIsLateEnd: "Il progetto contiene attività in ritardo sulla data di fine",
        JobOrderTasksAreNotReady:
            "Ci sono delle attività precedenti non completate ed alcune attività non possono partire",
        JobOrderNeedsWarehouseArticles: "Alcune attività hanno bisogno di articoli di magazzino",
        JobOrderNeedsPurchases: "Alcune attività hanno bisogno di effettuare degli acquisti",
        JobOrderNeedsResources: "Alcune attività hanno bisogno di risorse",
        JobOrderHasBillableTasks: "Ci sono delle attività a Rendicontazione",
        JobOrderHasToBeBilledHours: "Sono presenti ore a Rendicontazione da Fatturare",
        JobOrderHasNotApprovedBudgetRequests: "Sono presenti richieste budget non approvate",
        JobOrderHasApprovedBudgetRequests: "Sono presenti richieste budget approvate",

        WorkflowIsLateStart: "Il piano è in ritardo o contiene attività in ritardo sulla data di inizio",
        WorkflowIsLateEnd: "Il piano è in ritardo o contiene attività in ritardo sulla data di fine",
        WorkflowTasksAreNotReady:
            "Ci sono delle attività precedenti non completate ed alcune attività non possono partire",
        WorkflowNeedsWarehouseArticles: "Alcune attività hanno bisogno di articoli di magazzino",
        WorkflowNeedsPurchases: "Alcune attività hanno bisogno di effettuare degli acquisti",
        WorkflowNeedsResources: "Alcune attività hanno bisogno di risorse",
        WorkflowNeedsMoreWarehouseArticles:
            "Non sono disponibili tutti gli articoli di magazzino necessari per le attività",
        WorkflowIsNotReady: "Ci sono delle attività precedenti non completate ed il piano non può partire",
        WorkflowHasBillableTasks: "Ci sono delle attività a Rendicontazione",
        WorkflowHasToBeBilledHours: "Sono presenti ore a Rendicontazione da Fatturare",
        WorkflowHasNotApprovedBudgetRequests: "Sono presenti richieste budget non approvate",
        WorkflowHasApprovedBudgetRequests: "Sono presenti richieste budget approvate",

        TaskIsNotReadyAlert: "Ci sono delle attività precedenti non completate e l'attività non può partire",
        TaskNeedsWarehouseArticles: "L'attività ha bisogno di articoli di magazzino",
        TaskNeedsPurchases: "L'attività ha bisogno di effettuare degli acquisti",
        TaskNeedsResources: "L'attività ha bisogno di risorse",
        TaskHasToBeBilledHours: "Sono presenti ore a Rendicontazione da Fatturare",
        TaskHasNotApprovedBudgetRequests: "Sono presenti richieste budget non approvate",
        TaskHasApprovedBudgetRequests: "Sono presenti richieste budget approvate",
        TaskHasNotEstimatedWork: "L'attività non ha una stima di lavoro",
        WorkflowHasNotEstimatedWork: "Il piano di lavoro contiene attività senza stima di lavoro",
        WorkflowMustBePlanned: "Il piano di lavoro deve essere pianificato",
        WorkflowHasActivitiesToBeReviewed: "Il piano di lavoro contiene attività da controllare",
        JobOrderHasNotEstimatedWork: "La commessa contiene attività senza stima di lavoro",
        TaskIsReporting: "L'attività è a rendicontazione",
        JobOrderHasWorkflowToBePlanned: "La commessa contiene piani di lavoro da pianificare",
        JobOrderHasActivitiesToBeReviewed: "La commessa contiene attività da controllare",

        TaskIsNotReady:
            "Alcuni predecessori dell'attività non sono completati, sei sicuro di voler cambiare stato all'attività?",
        DoNotMove: "Annulla",
        ContinueMove: "Cambia Stato",

        PlanningView: "Vai a Pianificazione",
        ProjectsView: "Vai a Progetti",

        JobOrdersNavigatorAdvFiltersDialogTitle: "Filtri avanzati",
        LoadSavedJobOrdersNavigatorAdvancedFilters: "Ricarica i filtri salvati",
        GetWorkflowCategoriesForFilterError:
            "Si è verificato un errore, impossibile caricare la lista delle categorie dei flussi",
        GetJobOrderNavigatorAdvancedFiltersError: "Si è verificato un errore, impossibile caricare i filtri salvati",
        ProjectsPageTitle: "Seleziona progetto",
        PlansPageTitle: "Seleziona piano",
        TaskboardPageTitle: "Taskboard",
        ModuleNotFoundError: "ERRORE! Impossibile trovare il modulo!",
        TaskSuccessfullyDeallocated: "L'attività è stata correttamente deallocata!",
        WorkflowSuccessfullyDeallocated: "Il piano è stato correttamente deallocato!",

        SelectReportingType: "Selezionare il tipo di rendicontazione",

        ConfirmMilestoneTaskWithoutEndDate: "Stai salvando un task Milestone senza una data di scadenza. Continuare?",
        ConfirmMilestoneTaskWithoutEndDateCancelBtn: "Annulla",
        ConfirmMilestoneTaskWithoutEndDateConfirmBtn: "Conferma",

        ActivitiesProgressWorkedHoursMode: "Avanz. x ore lavorate",
        ActivitiesProgressAmountMode: "Avanz. x quantità",

        MultipliersUpdateSuccess: "Quantità aggiornate",
        WorkflowActivitiesMultipliersManagerTitle: "Gestione quantità attività",

        WorkflowActivitiesMultipliersManagerPendingChangesMessage: "Ci sono modifiche non salvate. Continuare?",
        WorkflowActivitiesMultipliersManagerPendingChangesCancel: "Annulla",
        WorkflowActivitiesMultipliersManagerPendingChangesConfirm: "Continua senza salvare",

        CannotEditActivitiesMultipliers:
            "I task sono stati modificati da un altro utente e non possono essere salvati. Ricaricare i dati, apportare le modifiche e riprovare.",

        TaskBoardAdvancedFilterDialogTitle: "Filtri avanzati",
        WorkflowTypeCodes: "C.010 Codici Piani",

        InvalidWorkflowMultiplier: "Valore non consentito per il moltiplicatore. Inserire un numero maggiore di zero.",
        TaskEditorConfigurationSaved: "Configurazione salvata",
        TaskEditorConfigurationSavingError: "Si è verificato un errore durante il salvataggio della configurazione.",
        InvalidEstimatedBudgetRowsData:
            "ATTENZIONE!! Ci sono righe di spese previste senza una descrizione. Inserire i dati mancanti prima di salvare.",

        OrderRowNotFound: "ATTENZIONE!! La riga selezionata non è stata trovata.",
        CustomerOrderNameForDataSource: "Ordine n° {0} del {1} ({2})",

        InvalidWorkflowBudgetRows:
            "ATTENZIONE!! Ci sono righe ci spese previste errate o incomplete. Assicurarsi di aver impostato ordine cliente e riga del docuemnto e che la quantità sia maggiore o uguale a zero",

        SaveWorkflowBudgetRequestsError:
            "Si è verificato un errore durante il salvataggio delle spese previste. Gli altri dati del piano di lavoro sono stati salvati.",
        InvalidWorkflowAmountForBudget: "Il valore deve essere maggiore o uguale a zero.",

        CustomerOrderHasBeenDeleted: "Il documento è stato cancellato",
        CustomerOrderRowHasBeenDeleted: "La riga del documento è stata cancellata",
        JobOrderCostsPlanningTitle: "Pianificazione Costi",

        MilestoneWithoutEndDateWarning: "Milestone senza data di scadenza.",

        SaveTasksErrorLabel: "Errore nel salvataggio delle attività",

        SaveTasksWarningsMessage:
            "ATTENZIONE!! Sono stati riscontrati i seguenti problemi: <br/><br/> {0} <br/><br/> Continuare comunque?",

        GetArticleCostAndPriceError:
            "ATTENZIONE!! Si è verificato un errore imprevisto, impossibile caricare costo e prezzo dell'articolo",

        WorkflowPendingChangesMessage: "ATTENZIONE!! Ci sono modifiche non salvate. Continuare?",
        WorkflowPendingChangesMessageCancel: "Annulla",
        WorkflowPendingChangesMessageConfirm: "Continua",

        TaskCreation: "Creazione",

        IgnoreNotEstimatedTaskCheckMessage: "ATTENZIONE! L'attività non ha una stima di lavoro. Salvare comunque?",
        IgnoreNotEstimatedTaskCheckMessageCancel: "Stima l'attività",
        IgnoreNotEstimatedTaskCheckMessageConfirm: "Salva",

        WorkflowInitial: "W",

        WorkflowStateForMenu: "{0} ({1})",

        ConfirmJobOrderChangeOnWorkflowEditor:
            "ATTENZIONE!! Se cambi commessa i collegamenti con i documenti verranno persi. Continuare?",

        NewTaskFromTask: "Copia attività",
        NewTaskFromTemplate: "Crea attività da template",
        TaskTemplateNotFound: "Il modello di attività selezionato non è più disponibile.",

        EmptyTimeline: "Nessun elemento disponibile",
        WorkflowSnapshotsDataSource: "Snapshots di piano di lavoro",
        WorkflowSnapshotContentDataSource: "Contenuto dello snapshot del piano",
        SnapshotSelectionRequired: "Selezionare uno snapshot.",
        SnapshotLabelRequired: "Inserire un'etichetta per lo snapshot.",
        PendingSnapshotChangesConfirm: "ATTENZIONE!! Ci sono modifiche non salvate. Se continui perderai le modifiche.",
        WorkflowSnapshotsTimelineTitle: "Timeline",
        WorkflowSnapshotDataEditor: "Dati snapshot",
        SnapshotFormLabel: "Etichetta",
        SaveSnapshotButton: "Salva",
        DeleteSnapshotButton: "Elimina",
        NewSnapshotButton: "Nuovo",
        ApplySnapshotButton: "Applica snapshot",
        SnapshotContentTitle: "Contenuto dello snapshot",
        SnapshotDeleteConfirmMessage: "Sei sicuro di voler cancellare questo snapshot?",
        SaveSnapshotSuccess: "Snapshot salvato correttamente",
        DeleteSnapshotSuccess: "Snapshot eliminato correttamente",
        EmptyTaskSnapshotEstimatedBudgetRows: "Nessuna stima",
        ApplySnapshotMessage:
            "Tutte le attività del piano verranno modificate applicando i dati contenuti nello snapshot. Continuare?",
        SnapshotApplySuccess: "Snapshot applicato correttamente",

        Revenues: "Ricavi: ",
        Costs: "Costi: ",

        Work: "Lavoro: ",
        Purchases: "Acqusti: ",
        Warehouse: "Magazzino: ",

        NewWorkflowOutcomeButton: "Nuovo",

        WorkflowOutcomeEditorNameLabel: "Nome",
        WorkflowOutcomeEditorIconLabel: "Icona",
        WorkflowOutcomeEditorBackgroundLabel: "Sfondo",
        WorkflowOutcomeEditorForegroundLabel: "Colore",
        WorkflowOutcomeEditorPreviewLabel: "Anteprima",
        WorkflowOutcomeEditorStateLabel: "Stato",
        WorkflowOutcomeEditorPreventWarehouseUnload: "Impedisci scarico articoli",
        WorkflowOutcomeEditorPreventWarehouseLoad: "Impedisci carico articoli",
        WorkflowOutcomeEditorPreventTasksEditingAndInsert: "Impedisci inserimento e modifica attività",
        WorkflowOutcomeEditorPreventWorkedhoursEditingAndInsert: "Impedisci inserimento e modifica ore",
        WorkflowOutcomeEditorPreventPurchasesEditingAndInsert: "Impedisci inserimento e modifica acquisti",

        WorkflowActualOutcomeLabel: "Esito attuale",
        WorkflowNewOutcomeLabel: "Nuovo esito",

        WorkflowOutcomeChangesNotApplyChangesButton: "Non modificare gli esiti",
        WorkflowOutcomeChangesApplyChangesButton: "Modifica gli esiti",

        WorkflowOutcomeChangesNotApplyAction: "Salva il documento senza alterare gli esiti dei piani",
        WorkflowOutcomeChangesApplyAction: "Salva il documento e altera gli esiti dei piani",

        WorkflowMustBeRelatedToCustomerOrders: "Il piano deve essere associato ad un ordine cliente",

        HasPriorityWorkTitle: "E' presente del lavoro prioritario da svolgere",
        NewCartStartTitle: "Inizia un nuovo carrello",
        HasWorkToDoTitle: "Sono presenti attività da svolgere",
        HasManualWorkToDoTitle: "E' presente almeno un appuntamento",
        HasMilestoneTitle: "E' presente almeno una milestone",

        WorkWithoutLateTitle: "Il lavoro procede senza ritardi",
        WorkWithLateTitle: "Sei in ritardo rispetto alla data di fine lavoro stabilita",
        GG: "(gg)",
        PlannedResources: "Risorse Pianificate",

        MissingDefaultWarehouse:
            "ATTENZIONE!! Impossibile trovare il magazzino di default. Assicurarsi di aver configuarto correttamente i magazzini e riprovare.",
        DeleteWorkflowUnexpectedError:
            "Si è verificato un errore imprevisto durante la cancellazione del piano, contattare l'assistenza. Ci scusiamo per il disagio.",

        ConfirmWorkflowDeleteWithRelatedData:
            "ATTENZIONE!! Sono stati riscontrati i seguenti problemi: {0} Elimina? (Verranno eliminate anche eventuali attività e associazioni con documenti amministrativi)",
        WorkflowWithRelatedDocuments: "Sono presenti documenti legati al piano di lavoro",
        WorkflowWithWorkedHours: "Sono presenti attività su cui è stato svolto del lavoro",
        WorkflowRelatedEntitiesError: "Riferimenti al piano di lavoro",
        WorkflowRelatedEntitiesErrorDescription:
            "ATTENZIONE!! Non è possibile eliminare il piano di lavoro perché è associato ai seguenti elementi:",
        RelatedDocuments: "Documenti correlati",
        WorkedTasks: "Ore lavorate",
        BlogPurchases: "Acquisti",
        BudgetRequests: "Richieste di budget",
        Allocations: "Allocazioni",

        RelatedDocumentLabel: "{0} n. {1} del {2}",
        RelatedDocumentRowLabel: "{0} - {1}",

        EstimatedBudgetForTaskStatesSettingsManager: "C.050 Stati stime budget di attività",

        EstimatedBudgetForTaskStateLabel: "Nome",
        EstimatedBudgetForTaskStatePlaceholder: "Inserire una descrizione...",
        EstimatedBudgetForTaskStateIconLabel: "Icona",
        EstimatedBudgetForTaskStateBackgroundLabel: "Sfondo",
        EstimatedBudgetForTaskStateForegroundLabel: "Colore",
        EstimatedBudgetForTaskStatePreviewLabel: "Anteprima",
        NewEstimatedBudgetForTaskStateLabel: "Nuovo",

        DefaultEstimatedBudgetForTaskStateLabel: "Nuovo stato",
        EstimatedBudgetForTaskStateDeleteMessage: "Sei sicuro di voler eliminare questo elemento?",
        EstimatedBudgetForTaskStatePopover: "Seleziona lo stato",
        TasksExternalChangesAlert:
            "Una o più attività sono state modificate da un altro utente. Sovrascrivere le modifiche?",
        TaskExternalChangesAlert: "L'attività è stata modificata da un altro utente. Sovrascrivere le modifiche?",

        InvalidEstimatedBudgetRowDrop:
            "Impossibile eseguire l'operazione. La riga di stima non appartiene a questa attività o proviene da uan scheda del browser aperta sun un'altra azienda.",
        WorkflowRelatedDocumentPlacehloder: "Seleziona un documento...",
        WorkflowRelatedDocumentRowPlacehloder: "Seleziona una riga...",

        Outcome: "Esito",
        WorkflowDetails: "Dettagli",
        WorkflowLinks: "Prerequisiti",
        WorkflowRelatedDocuments: "Documenti collegati",
        JobOrder: "Progetto",
        WorkflowAlt: "Piano di lavoro",
        SnapshotsManagement: "Gestione snapshots",

        EmptyLinksTable: "Non sono presenti vincoli",
        Add: "Aggiungi",
        Remove: "Rimuovi",

        WorkedHoursHelpersSection: "Rendicontazione ore",
        WorkedHoursForcedData: 'Applica i dati in maniera "rigida"',
        HideAdminstrativeDataFromWorkedHours: "Nascondi i dati amministrativi da inserimento ore",
        HideAdminstrativeDataFromWorkedHoursTooltip:
            "Questa impostazione ha effetto solo se è stata configurara almeno una mansione o una tipologia oraria nelle tabelle sottostanti.",
        DDC: "DDC",
        WorkedHoursDefaultPlace: "Luogo",
        WorkedHoursDefaultTravelDistance: "Km viaggio",
        WorkedHoursDefaultRoles: "Mansioni",
        WorkedHoursDefaultWorkTimeCategories: "Tipologie orarie",

        Role: "Mansione",
        WorkTimeCategory: "Tipologia oraria",

        WorkedHoursHelpersEmptyRolesList:
            "Nessuna mansione configurata. In fase di inserimento ore la mansione verrà impostata secondo le regole standard.",
        WorkedHoursHelpersEmptyWorkTimeCategoriesList:
            "Nessuna tipologia oraria configurata. In fase di inserimento ore la tipologia oraria verrà impostata secondo le regole standard.",
        ReportingType: "Tipo di rendicontazione",

        ResourceDefaults: "Default di risorsa",

        UserMilestonesTooltip: "Mie milestone",
        ExpiredMilestone: "Milestone scaduta",
        ExpiredMilestones: "Milestone scadute:",
        MilestonesList: "Elenco milestones",
        MilestonesListSearch: "Cerca...",

        WorkflowOutcomePreventTasksEditing:
            "Non è possibile modificare l'attività perché il piano di lavoro ha un esito impostato che impedisce la modifica delle attività in esso contenute.",
        TaskNotFound: "L'attività richiesta non è stata trovata.",
        TasksNotFound: "Le attività richieste non sono state trovate.",
        MissingTaskTitle: "Inserire il titolo dell'attività",
        InvalidTaskMultiplier: "Il valore del moltiplicatore deve essere un numero maggiore di zero.",
        InvalidEstimatedBudgetRowEntityKeyId: "L'entità selezionata sulla riga di stima non è valida.",
        InvalidEstimatedBudgetRowType: "La tipologia di entità specificata sulla riga di stima non è valida.",
        InvalidTaskDuration: "La durata dell'attività deve essere un numero maggiore di zero.",
        TaskWasUpdatedByAnotherUser: "L'attività è stata modificata da un altro utente.",
        GenericError: "Si è verificato un errore imprevisto, contattare l'assistenza. Ci scusiamo per il disagio.",
        InvalidTaskLinkType: "Il tipo di vincolo selezionato non è valido.",
        InvalidTaskLinkReferenceId: "L'entità selezionata come vincolo non è valida.",
        InvalidTaskResourceId: "La risorsa selezionata non è valida.",
        InvalidTaskResourceType: "La tipologia di risorsa selezionata non è valida.",
        TaskDataChangesNotFound: "La riga di storico modifiche dell'attività non è stata trovata.",
        TaskStatusChangesNotFound: "La riga di storico modifiche dello stato dell'attività non è stata trovata.",
        TaskBoardStatusNotFound: "Lo stato dell'attività non è stato trovato.",
        TaskWorkTimeCategoriesNotFound: "Le tipologie orarie dell'attività non sono state trovate.",
        TaskRolesNotFound: "Le mansioni dell'attività non sono state trovate.",
        WorkflowMismatch: "Le attività selezionate non appartengono allo stesso piano di lavoro.",
        ErrorWhileSavingTask: "Si è verificato un errore durante il salvataggio dell'attività.",
        WorkflowNotFound: "Il piano di lavoro non è stato trovato.",
        WorkflowOutcomeNotFound: "L'esito del piano di lavoro non è stato trovato.",
        WorkflowJobOrderMismatch: "La commessa specificata nei tag dell'attività non corrisponde a quella del piano.",
        MissingTaskWorkEstimation: "Inserire la stima di lavoro dell'attività.",
        WorkflowStatusNotFound: "Lo stato del piano di lavoro non è stato trovato.",
        WorkflowStatusChangesNotFound:
            "La riga di storico modifiche dello stato del piano di lavoro non è stata trovata.",
        WorkflowSaved: "Piano di lavoro salvato con successo.",
        UserResourceNotFound: "La risorsa selezionata non è stata trovata.",
        WorkflowRelatedDocumentsBadgeTitle: "Documenti collegati",
        WorkflowRelatedDocumentsBadgeLegendGreenTitle:
            "Documenti collegati: il colore verde indica che il piano non richiede l'associazione con gli ordini cliente oppure che c'è almeno un ordine cliente associato al piano.",
        WorkflowRelatedDocumentsBadgeWithAlertTitle: "Documenti collegati: nessun collegamento ad ordine cliente",
        WorkflowRelatedDocumentsBadgeWithAlertLegendRedTitle:
            "Documenti collegati: il colore rosso indica che il piano richiede l'associazione con gli ordini cliente ma non c'è nessun documento collegato.",
        WorkflowRelatedDocumentsBadgeWithAlertLegendOrangeTitle:
            "Documenti collegati: il colore arancione indica che il piano richiede l'associazione con gli ordini cliente ma tra i documenti correlati al piano non c'è nessun ordine cliente.",
        WorkflowRelatedDocumentsPopOverTitle: "Documenti amministrativi collegati",

        WorkflowRelatedDocumentsDocTypeTitle: "Tipo documento",
        WorkflowRelatedDocumentsProtocolTitle: "Protocollo",
        WorkflowRelatedDocumentsDocNumberTitle: "Numero documento",
        WorkflowRelatedDocumentsDocDateTitle: "Data documento",
        WorkflowRelatedDocumentsDocCustomerTitle: "Cliente",
        WorkflowRelatedDocumentsDocRowNumberTitle: "Numero riga",
        WorkflowRelatedDocumentsAssignedAmountTitle: "Q.tà assegnata",
        WorkflowRelatedDocumentsAssignedPriceTitle: "Importo assegnato",

        WorkflowAllocationBadgeLegendRedTitle:
            "Allocazione: il piano è in un carrello ma contiene attività non stimate.",

        WorkflowAllocationBadgeLegendGreenTitle:
            "Allocazione: il piano è in un carrello e le attività contenute sono stimate.",

        TaskBlogEventsBadgeLegendRedTitle:
            "Eventi di blog: il colore rosso indica che l'attività non ha eventi di blog associati.",
        TaskBlogEventsBadgeLegendGreenTitle:
            "Eventi di blog: il colore verde indica che l'attività ha eventi di blog associati.",

        CustomerOrdersTitle: "Ordini cliente",
        OtherDocumentsTitle: "Altri documenti",
        MoveTasksError: "Si è verificato un errore durante lo spostamento delle attività.",

        TaskOrWorkflowNotFound: "L'attività o il piano di lavoro non sono stati trovati.",
        TaskEstimateChangesNotFound: "La riga di storico modifiche della stima dell'attività non è stata trovata.",
        DuplicatedTaskIdInChanges: "L'identificativo dell'attività è duplicato.",
        SettingNotFound: "L'impostazione non è stata trovata.",
        SettingInvalidValue: "Il valore dell'impostazione non è valido.",
        TaskFirstWorkingStateChangeNotFound:
            "La riga di storico modifiche dello stato di prima lavorazione non è stata trovata.",
        TaskLastClosedStateChangeNotFound: "La riga di storico modifiche dello stato di chiusura non è stata trovata.",
        WorkflowTemplateNotFound: "Il modello di piano di lavoro non è stato trovato.",
        InvalidWorkflowSourceTemplate: "Il modello di piano di lavoro di origine non è valido.",
        MissingTasks: "Selezionare almeno un'attività.",
        MissingWorkflowTitle: "Inserire il titolo del piano di lavoro.",
        WorkflowDefaultCategoryNotFound: "La categoria di default del piano di lavoro non è stata trovata.",
        WorkflowHasRelatedDocumentsOrWorkedHours: "Il piano di lavoro ha documenti collegati o ore lavorate.",
        WorkflowDefaultOutcomeNotFound: "L'esito di default del piano di lavoro non è stato trovato.",
        WorkflowAllocationStatusTitle: "Stato di allocazione",
    },
    Users: {
        ProfessionalFigure: "Figura professionale",
        ProfessionalFigures: "Figure professionali",
        Resource: "Risorsa",
        Resources: "Risorse",
        ResourcesGroup: "Gruppo di risorse",
        Role: "Mansione",
        Roles: "Mansioni",
        User: "Utente",
        Users: "Utenti",
        SureToDeleteResource: "Sei sicuro di voler eliminare la risorsa?",
        DoNotDeleteResource: "Non eliminare",
        DeleteResource: "Elimina",
        NewGroup: "Nuovo gruppo",
        New: "Nuovo",
        InsertGroupName: "Inserire il nome del gruppo",
        GroupSavedSuccessfully: "Gruppo salvato con successo",
        SureToDeleteGroup: "Sei sicuro di voler eliminare il gruppo?",
        DoNotDeleteGroup: "Non eliminare",
        DeleteGroup: "Elimina",
        GroupDeletedSuccessfully: "Gruppo eliminato con successo",
        NewRole: "Nuova mansione",
        SureToDeleteRole: "Sei sicuro di voler eliminare la mansione?",
        DoNotDeleteRole: "Non eliminare",
        DeleteRole: "Elimina",
        ResourcesRoles: "Risorse/Mansioni",
        HourlyResources: "Risorse orarie",
        ResourcesGroups: "Gruppi di risorse",
        UnavailableResource: "Risorsa non disponibile",
        UnnamedResource: "Risorsa senza nome",
        NewResource: "Nuova Risorsa",

        ConfirmResourcesCostsWithoutDefaultMessage:
            "ATTENZIONE!! Non è stato impostato un default. Continuare comunque?",
        ConfirmResourcesCostsWithoutDefaultCancel: "Annulla",
        ConfirmResourcesCostsWithoutDefaultConfirm: "Continua",

        PageReloadHelp: "Per applicare i cambiamenti, dopo aver salvato, ricaricare la pagina.",
        SearchGroupsPlaceholder: "Cerca gruppi...",
        Save: "Salva",
        Delete: "Elimina",

        GroupName: "Nome",
        GroupNamePlaceholder: "Inserisci il nome del gruppo...",

        GroupDescription: "Descrizione",
        GroupDescriptionPlaceholder: "Inserisci una descrizione...",

        GroupMembers: "Membri del gruppo",
        GroupMembersPlaceholder: "Utenti del gruppo...",
        HoursApprovalGroupsSelectorLabel: "Gruppi",
        HoursApprovalGroupsSelectorPlaceholder: "Cerca gruppi...",

        ResourcesSearchPlaceholder: "Cerca risorsa...",
    },
    Warehouse: {
        WarehouseArticles: "Articoli di magazzino",
        SelectSourceWarehouse: "Selezionare il magazzino di provenienza",
        CustomerOrder: "Ordine Cliente",
        CustomerOrders: "Ordini Cliente",
        New: "Nuovo",
        CustomerOrderProtocols: "Protocolli Ordini Cliente",
        CustomerOrderProtocolsShortLabel: "Ord. Cli.",
        ChangeState: "Modifica Stato",
        Done: "Fine",
        Lock: "Blocca",
        Export: "Esporta",
        Import: "Importa",
        Unlock: "Sblocca",
        Warehouse: "Magazzino",
        WarehouseSettingsGroup: "F. Magazzino",
        Warehouses: "Magazzini",
        SelectWarehouse: "Selezionare un magazzino",
        SelectAtLeastARowToImport: "Selezionare almeno una riga da importare nel documento!",
        OneOrMoreRowsWereAlreadyImported:
            "Una o più delle righe selezionate sono già state importate in un altro documento.\nSei sicuro di voler importare le righe selezionate?",
        AbortImport: "Annulla l'importazione",
        ImportAnyway: "Importa comunque le righe",
        SomeRowsWereNotImported: "Alcune righe non sono state importate perchè già utilizzate in questo documento!",
        WarningTheSelectedDateIsBiggerThanTheDocumentOne:
            "Attenzione! La data selezionata è posteriore alla data del documento che è il {0}!",
        JobOrderNotFound: "(Commessa non trovata)",
        CustomerNotFound: "(Cliente non trovato)",
        CustomerOrderNumberOf: "Ordine cliente n° {0} del {1}",
        WarehouseInventory: "Inventario magazzino",
        SupplierOrder: "Ordine Fornitore",
        SupplierOrders: "Ordini Fornitore",
        SupplierNotFound: "(Fornitore non trovato)",
        SupplierOrderNumberOf: "Ordine fornitore n° {0} del {1}",
        UnderStockArticles: "Da articoli sotto scorta",
        CustomerOrderAbb: "Ord.Cliente",
        SupplierOrderAbb: "Ord.Fornitore",
        WarehouseLoad: "Carico",
        WarehouseLoadAbb: "Car.Magazzino",
        WarehouseLoads: "Carichi",
        WarehouseLoadsExt: "Carichi di Magazzino",
        WarehouseLoadExt: "Carico di magazzino",
        WarehouseLoadsProtocols: "Protocolli Carichi di Magazzino",
        WarehouseLoadsProtocolsShortLabel: "Car. Magaz.",
        WarehouseLoadNumberOf: "Carico magazzino n° {0} del {1}",
        ReferenceToWaybillNumberOf: "Rif. DDT n.{0} del {1}",
        SelectState: "Selezionare lo stato del documento",
        SelectSupplierToImport: "Selezionare un fornitore prima di importare dati",
        SupplierOrdersProtocols: "Protocolli Ordini Fornitore",
        SupplierOrdersProtocolsShortLabel: "Ord. Forn.",
        InsertSupplierVATNumber: "Inserire la partita iva del fornitore",
        SupplierVATNumberInvalid: "La partita iva del fornitore non è valida",
        InsertSupplierTaxCode: "Inserire il codice fiscale del fornitore",
        SupplierTaxCodeInvalid: "Il codice fiscale del fornitore non è valido",
        WarehouseTransfers: "Trasferimenti di Magazzino",
        WarehouseTransfersProtocols: "Protocolli Trasferimenti di Magazzino",
        Groups: "Gruppi",
        SomeRowsHaveNoDescription: "Alcune righe non hanno una descrizione",
        Summary: "Riepilogo",
        CustomerOrderDataImport: "Ordine Cliente: Importazione dati",
        SupplierOrderDataImport: "Ordine Fornitore: Importazione dati",
        WarehouseLoadDataImport: "Carico di Magazzino: Importazione dati",
        WarehouseTransferDataImport: "Trasferimento: Importazione dati",
        SourceWarehouseWithName: "Magazzino di provenienza : {0}",
        //InventoryURL: "#/Magazzino/Magazzini/Inventario",
        ReferenceToDocument: "{0} N.{1} - {2}",
        NewReferenceToDocument: "{0} N.{1} - {2} - {3} {4} {5}",
        NewReferenceToDocumentReferenceNumberPart: "{0} Rif. Num. {1}",
        NewReferenceToDocumentReferenceDatePart: "{0} - Rif. data {1}",
        NewReferenceToDocumentWarehousePart: " - Magaz.: N/D",
        NewReferenceToDocumentSourceWarehousePart: " - Magaz. sorg.: {0}",
        NewReferenceToDocumentDestWarehousePart: " - Magaz. dest.: {0}",
        ProtocolsArticlesCosts: "Costi articoli su protocolli",
        NoJobOrder: "Nessuna commessa",
        OneDay: "1 Giorno",
        N_Days: "{0} Giorni",
        N_Weeks: "{0} Settimane",
        ArticleAnalisys: "Analisi articolo: {0} - {1}",
        Loading: "Caricamento in corso...",
        NewArticle: "Nuovo Articolo",
        NoChangesMade: "Non è stata apportata alcuna modifica all'articolo",
        NoChangesMadeToWarehouse: "Non è stata apportata alcuna modifica al magazzino",
        ArticleSavedSuccessfully: "Articolo salvato con successo!",
        ArticleWithCode: "Articolo {0}",
        ArticlesList: "Elenco Articoli",
        Catalogs: "Anagrafiche Articoli",
        LoadingShort: "Carico...",
        ArticleShortMarkup:
            '<table class="warehouse-articles-search-list"><tr><td>Codice:</td><td>{0}</td></tr><tr><td>EAN:</td><td>{1}</td></tr><tr><td>Descrizione:</td><td>{2}</td></tr></table>',
        ChangeDiscount: "Modifica Sconto",
        NewDiscount: "Nuovo sconto",
        AllCustomers: "Tutti",
        SavingWillLoseChangesToDiscount:
            "Sono presenti modifiche non salvate allo sconto, continuando si perderanno le modifiche.<br>Sei sicuro di voler continuare?",
        ContinueEditingDiscounts: "Continua le modifiche",
        CloseAndLooseChanges: "Chiudi e perdi le modifiche",
        DiscountSavedSuccessfully: "Sconto salvato con successo!",
        ErrorWhileSavingDiscount: "Si è verificato un errore durante il salvataggio dello sconto",
        SureToDeleteDiscount: "Sei sicuro di voler eliminare lo sconto?",
        DoNotDeleteDiscount: "Non eliminare lo sconto",
        DeleteDiscount: "Elimina lo sconto",
        DiscountDeletedSuccessfully: "Sconto eliminato con successo!",
        ErrorWhileDeletingDiscount: "Si è verificato un errore durante l'eliminazione dello sconto",
        CannotAddMoreThanADiscountInThisCategory: "Non è possibile aggiungere più di uno sconto in questa categoria!",
        General: "Generale",
        CustomersDiscounts: "Sconti Clienti",
        DiscountsForEveryone: "Sconti per tutti",
        SuppliersDiscounts: "Sconti Fornitori",
        IBANUndefined: "IBAN NON DEFINITO",
        Estimate: "Preventivo Cliente",
        EstimateSavedSuccessfully: "Preventivo salvato con successo!",
        ErrorWhileSavingEstimate: "Si è verificato un errore durante il salvataggio del preventivo!",
        SelectDestinationWarehouseForEstimate:
            "E' necessario selezionare un magazzino prima di poter effettuare il carico!",
        NoCustomerSelected: "Non è stato selezionato un cliente!",
        NoSupplierSelected: "Non è stato selezionato un fornitore!",
        NoArticleSelected: "Inserire almeno un articolo!",
        WarehouseSavedSuccessfully: "Magazzino salvato con successo!",
        WarehouseManagement: "Gestione Magazzini",

        SureToDeleteWarehouse: "Sei sicuro di vole eliminare il magazzino?",
        DoNotDelete: "Non eliminare",
        Delete: "Elimina",

        SureToUndeleteWarehouse: "Sei sicuro di vole ripristinare il magazzino?",
        DoNotUndelete: "Non ripristinare",
        Undelete: "Ripristina",

        InternalWarehouse: "Magazzino Interno",
        WarehouseInformations: "Informazioni magazzino",
        CatalogImportResult: "Risultato importazione listino",
        CatalogsImport: "Importazione Listini",
        ImportInProgress: "Importazione in corso...",
        MovementSavedSuccessfully: "Movimento salvato con successo!",
        ErrorWhileSavingMovement: "Si è verificato un errore durante il salvataggio del movimento!",
        WarehouseStocksAdjustment: "Rettifica delle Giacenze",
        WarehouseTransferFrom: "Trasferimento da Magazzino",
        NoDestinationWarehouseSelected: "Non è stato selezionato un magazzino di destinazione!",
        DestinationWarehouseMustBeDifferent: "Il magazzino di destinazione deve essere diverso da quello di origine!",
        StocksHistory: "Inventario storico",
        Movements: "Movimenti",
        Inventory: "Inventario",
        ErrorWhileSavingStockAdjustments: "Si è verificato un errore durante il salvataggio delle giacenze!",
        StockAdjustmentsForDay: "RETTIFICA MAGAZZINO {0}",
        ConfirmLooseStockAdjustmentsChanges:
            "Sono presenti modifiche alle giacenze non salvate. Continuando si perderanno i cambiamenti effettuati, sei sicuro di voler continuare?",
        Cancel: "Annulla",
        Save: "Salva",
        ContinueAndLooseChanges: "Prosegui e ignora le modifiche",
        MovementsSummary: "Riepilogo movimenti",
        DocumentSavedSuccessfully: "Documento salvato con successo!",
        ErrorWhileSavingDocument: "Si è verificato un errore durante il salvataggio del documento!",
        Management: "Gestione",
        Discounts: "Sconti",
        DiscountsCatalogs: "Gestione Scontistiche",
        Customers: "Clienti",
        Suppliers: "Fornitori",
        Manufacturer: "Produttore",
        Manufacturers: "Produttori",
        NewManufacturer: "Nuovo Produttore",
        Articles: "Articoli",
        Article: "Articolo",
        WarehouseManagementURL: "#/Magazzino/Magazzini/Gestione",
        StocksHistoryURL: "#/Magazzino/Magazzini/InventarioStorico",
        MovementsURL: "#/Magazzino/Magazzini/Movimenti",
        DiscountsCatalogsURL: "#/Magazzino/Sconti/Listini",
        CustomersDiscountsURL: "#/Magazzino/Sconti/Clienti",
        SuppliersDiscountsURL: "#/Magazzino/Sconti/Forni",
        CatalogsURL: "#/Magazzino/Articoli/Listini",
        ArticlesListURL: "#/Magazzino/Articoli/Elenco",
        InventoryURL: "#/Magazzino/Magazzini/Inventario",
        WarehouseURL: "#/Magazzino",
        SelectProtocol: "Seleziona protocollo...",
        InventoryAdjustment: "Rettifica di Magazzino",
        Transforms: "Trasformazioni",
        TransformsURL: "#/Magazzino/Articoli/Trasformazioni",
        CreateTransform: "Nuova trasformazione",
        EditTransform: "Modifica trasformazione",
        SelectAnArticle: "Seleziona un articolo...",
        SelectAManufacturer: "Seleziona un produttore...",
        ErrorWhileSavingTransforms: "Si è verificato un errore durante il salvataggio delle trasformazioni",
        SureToDeleteTransform: "Sei sicuro di voler eliminare le trasformazioni per questo articolo?",
        DoNotDeleteTransform: "Non eliminare",
        DeleteTransform: "Elimina",
        DefineAtLeastATransform: "Definisci almeno una trasformazione prima di salvare",
        ArticleTranformsDataImport: "Importazione Trasformazioni Articoli",
        EnterCodeBeforeSaving: "Immettere il codice prima di salvare!",
        WrongManufacturerCodeLength: "Il codice del produttore deve essere di tre caratteri!",
        EnterVatBeforeSaving: "Immettere la Partita Iva prima di salvare!",
        EnterDescriptionBeforeSaving: "Immettere descrizione produttore prima di salvare!",

        Description: "Descrizione",
        Amount: "Quantità",
        PossibleRecursion:
            "Attenzione! Non è possibile salvare la trasformazione perchè sono presenti righe che potrebbero causare un loop nella trasformazione!",
        WarehousesWhereArticleIsStockedDialogTitle: "Lista magazzini in cui è presente l'articolo",
        ErrorWhileRetrievingWarehousesData: "Si è verificato un errore durante la ricerca dei dati!",
        DuplicatedDiscountError: "Impossibile salvare: esiste già uno sconto per questo articolo",

        ReferenceToDocumentForCommentRow: "Rif. Ordine cliente N.{0} - {1}",
        ReferenceToEstimateForCommentRow: "Rif. Preventivo N.{0} - {1}",
        ExternalReferencesForCommentRowIncipit: "Rif. Cli.",
        ExternalReferencesForCommentRowRefNumber: "Num. Rif.: {0}",
        ExternalReferencesForCommentRowRefDate: "Data Rif.: {0}",
        ExternalReferencesForCommentRowExtRef: "Rif. Est.: {0}",

        TypeCodes: "F.010 Codici Magazzino",
        CodeLengthError: "Il codice non deve superare i 47 caratteri",

        NewDiscountCatalog: "Nuovo Scontistica",
        EditingDiscountCatalog: "Modifica Scontistica",
        DiscountFamilies: "Famiglie di Sconto",
        StatisticFamilies: "Famiglie Statistiche",
        OrderRows: "Righe ordine",

        PreferredSuppliers: "Fornitori Abituali",
        PreferredSuppliersURL: "#/Magazzino/Sconti/FornitoriAbituali",

        CatalogRequired: "Il campo 'Listino' è obbligatorio",
        ManufacturerRequired: "Il campo 'Produttore' è obbligatorio",
        ManufacturerCodeRequired: "Il 'Codice del produttore' è obbligatotio",
        CodeRequired: "Il campo 'Codice' è obbligatorio",

        CatalogsDataSourceTitle: "Listini",
        WarehouseLoadReasonsForShipmentSettingsManager: "E.120 Causali di trasporto carico di magazzino",
        WarehouseLoadReasonsForShipmentErrors:
            "ATTENZIONE!! Ci sono causali senza nome. Inserire tutti i nomi e riprovare.",
        WarehouseLoadResonsForShipmentSaveSuccess: "Salvataggio riuscito",
        WarehouseLoadResonsForShipmentSaveError: "Si è verificato un errore, contattare l'assistenza.",
        WarehouseNameRequired: "Inserire un nome per il magazzino",
        WarehouseJobOrderRequired: "Selezionare una commessa",

        ReasonForShipmentRequired: "Inserire una causale di trasporto",
        ReasonsForShipment: "Causali di trasporto",
        DeleteArticleErrorMessage:
            "Non è stato possibile eliminare l'articolo perché è utilizzato in uno o più documenti.",
        DeleteArticleErrorCancel: "Mostra i documenti",
        DeleteArticleErrorConfirm: "Ok",
        DocumentsReferencingArticleDialogTitle: "Documenti che importano l'articolo",
        DocumentReferencingArticleDataSource: "Documenti che importano l'articolo",

        DocumentReferencingArticleName: "{0} - Documento n° {1} del {2}",
        DeleteArticleSuccess: "Articolo eliminato correttamente",

        DeleteArticleConfirmMessage: "Sei sicuro di voler cancellare questo articolo?",
        DeletePurchaseConfirmMessage: "Sei sicuro di voler cancellare questo acquisto?",
        DeleteArticleCancel: "Annulla",
        DeleteArticleConfirm: "Conferma",

        AllReasonsWithNonUpdateMeanCostsDeletedAlert:
            "ATTENZIONE!! Stai eliminando l'ultima causale configurata per non influire sul calcolo dei costi medi degli articoli di magazzino. Senza causali di questo tipo non sarà possibile emettere rettifiche di magazzino sui protocolli specifici per le rettifiche. Continuare?",
        AllReasonsWithUpdateMeanCostsDeletedAlert:
            "ATTENZIONE!! Stai eliminando l'ultima causale configurata per influire sul calcolo dei costi medi degli articoli di magazzino. Continuare?",

        Back: "Indietro",
        Continue: "Continua",
        ErrorWhileSavingArticleAlias:
            "Si è verificato un errore durante il salvataggio dell'alias di articolo, contattare l'assistenza",
        ArticleAliasesSearch: "Ricerca Alias Articoli",
        ArticleAliasesManagement: "Gestione Alias Articoli",
        EditArticleAlias: "Modifica Alias Articolo",
        NewArticleAlias: "Nuovo Alias Articolo",
        ChooseAnArticle: "Seleziona un articolo...",
        ArticleCodeAlias: "Alias Codice Articolo",
        ArticleCodeAliasPlaceholder: "Inserisci un alias per il codice articolo",
        ArticleCodeAliasHelpText:
            "Utilizzare questo campo per modificare il codice articolo (esclusi i primi tre caratteri che rappresentano il codice produttore)",
        NewArticleCodePreview: "Anteprima nuovo Codice Articolo",
        ArticleAliasLooseChangesMessage:
            "Attenzione, le modifiche apportate all'alias verranno perse. Sei sicuro di voler continuare?",
        ArticleAliasDeleteConfirm: "Attenzione, sei sicuro di voler eliminare l'alias?",
        ArticleAliasDeleteError: "Si è verificato un errore eliminando l'alias. Contattare l'assistenza",
        ArticleAliasDeleteSuccess: "Alias eliminato con successo",
        ArticleAliasSaveSuccess: "Alias salvato con successo",

        ManufacturerAliasesSearch: "Ricerca Alias Produttori",
        ManufacturerAliasesManagement: "Gestione Alias Produttori",
        EditManufacturerAlias: "Modifica Alias Produttore",
        NewManufacturerAlias: "Nuovo Alias Produttore",
        SearchByCode: "Cerca per codice",
        ManufacturerCodeAlias: "Alias Prefisso Produttore",
        ManufacturerCodeAliasHelpText:
            "Utilizzare questo campo per alterare il codice produttore degli articolo (primi 3 o 4 caratteri)",
        ManufacturerCodeAliasPlaceholder: "Codice produttore non modificato",

        CustomerOrdersAdjustmentsDataSource: "Variazione ordine cliente",

        ManufacturerCodeAlreadyExists: "Esiste già un produttore con lo stesso codice.",

        ManufacturerAliasDetails: "Dettagli Alias Produttore",
        ArticleAliasDetails: "Dettagli Alias Articolo",

        WarehouseInspections: "Verifiche",
        WarehouseInspectionsTitle: "Verifiche di magazzino",
        WarehouseInspectionsURL: "#/Magazzino/Magazzini/Verifiche",
        NewInspectionButton: "Nuovo",
        WarehouseInspectionColumn: "Nome verifica",
        WarehouseInspectionNumberOrArticlesColumn: "Nr. articoli",
        WarehouseInspectionStatusColumn: "Stato",
        WarehouseInspectionCreationColumn: "Creazione",
        WarehouseInspectionLastModificationColumn: "Ultima mod.",
        WorkedByColumn: "Presa in carico",
        WarehouseInspectionDarftStatus: "Preparazione",
        WarehouseInspectionWorkableStatus: "Lavorazione",
        WarehouseInspectionClosedWithErrorsStatus: "Chiusa con errori",
        WarehouseInspectionClosedStatus: "Chiusa",
        WarehouseInspectionEditButton: "Modifica",
        WarehouseInspectionShowButton: "Visualizza",
        WarehouseInspectionDeleteButton: "Elimina",

        WorkedByWarehouseInspectionFilter: "Prese in carico",
        NotWorkedByWarehouseInspectionFilter: "Non prese in carico",

        NewInspectionTitle: "Nuova verifica",
        EditInspectionTitle: "Modifica verifica",

        InspectionTitle: "Titolo verifica",
        InspectionTitlePlaceholder: "Titolo",

        InspectionStatus: "Stato",
        InspectionPendingChangesMessage: "Ci sono modifiche non salvate. Se continui, le modifiche andranno perse.",
        DeleteInspectionMessage: "Sei sicuro di voler eliminare la verifica di magazzino?",

        InspectionImporterTitle: "Importa articoli da verificare",
        WarehouseArticlesImporterSourceSelectionInformation:
            "Questo primo passo della procedura di importazione dati permette di selezionare le fonti da cui importare i dati da elaborare.\r\nCliccando sulle voci seguenti è possibile attivare o disattivare l'importazione dalle singole fonti.",
        WarehouseInventoryDataSource: "Inventario di magazzino (magazzino rotativo)",
        RequirementsFromDocumentsDataSource: "Fabbisogni (ordini cliente/richieste di acquisto)",
        WarehouseLoadsRequirementsDataSource: "Carichi di magazzino",

        PeriodSinceLastWarehouseInspectionLabel: "Periodo trascorso dall'ultima verifica (gg)",
        PeriodSinceLastWarehouseInspectionHelp:
            "Prendi tutti gli articoli che non vengono verificati da almeno N giorni.",

        Customer: "Cliente",
        JobOrder: "Commessa",
        Document: "Documento",

        SelectedWarehouses: "Magazz. selezionati:",
        SelectedArticles: "Art. selez.",
        SelectedRequirements: "Fabb. selez.",
        WarehousesNumber: "Nr. magazzini:",
        OperationsNumber: "Nr. operazioni",

        RequestedAmount: "Q.tà",
        StockAmount: "Q.tà in stock",
        RequestedAmountExt: "Q.tà richiesta",
        ArticlesToBeReviewed: "Articoli da verificare",
        ChoosenArticlesForReview: "Articoli selezionati",

        InspectonOperation: "Operazione",
        StockCheck: "Ver. giacenza",
        WarehouseTransfer: "Trasf.",

        LastInspectionDate: "Data ultima verifica",
        LastInspectionDateFilterLabel: "Ultima verifica precedente al",
        LastInspectionDateFilterHelpText:
            "Mostra tutti gli articoli che non sono stati sottoposti a verifica e che non sono già stati selezionati in un'altra verifica di magazzino a partire dalla data selezionata. N.B.: la modifica di questo filtro comporta la perdita di eventuali selezioni.",
        LastInspectionDateFilterPlaceholder: "Data...",

        WarehouseInspectionSaveSuccess: "Salvataggio riuscito",
        DeleteWarehouseInspectionMessage: "Sei sicuro di voler eliminare questa verifica di magazzino?",
        DeleteWarehouseInspectionSuccess: "Eliminazione riuscita",

        MovementAmountColumn: "Q.tà movimentata",
        ActualAmountColumn: "Q.tà verificata",
        ActualAmountColumnTitle: "Q.tà in stock confermata dopo la verifica",
        ArticleStockColumn: "Q.tà in stock",
        ArticleStockColumnTitle: "Q.tà in stock prima della verifica",
        OperationStatusColumn: "Stato op.",
        CodesVerificationStatus: "Verifica codici",

        SourceWarehouseColumn: "Mag. sorgente",

        EditWorkedWarehouseInspection:
            "ATTENZIONE!! La verifica di magazzino che si sta per modificare è attualmente in lavorazione o è stata conclusa. Continuare?",
        DeleteWorkedWarehouseInspection:
            "ATTENZIONE!! La verifica di magazzino che si sta per eliminare è attualmente in lavorazione o è stata conclusa. Continuare?",

        WarehouseInspectionImportError:
            "ATTNZIONE!! Non è stato possibile salvare le righe importate nella verifica di magazzino; riprovare premendo il pulsante Salva. Se il problema persiste, contattare l'assistenza.",
        WarehouseInspectionImportErrorLabel: "Errore importazione",
        NewInspectionDefaultTitle: "Nuova verifica di magazzino",

        VerifiedArticleCodeTitle: "Verifica del codice articolo - {0}",
        VerifiedSourceWarehouseTitle: "Verifica del magazzino di provenienza - {0}",
        VerifiedDestinationWarehouseTitle: "Verifica del magazzino di destinazione - {0}",
        VerifiedArticleCodeOnDestinationDefaultWarehouseTitle:
            "Verifica del codice articolo su magazzino centrale (se il magazzino centrale è di destinazione) - {0}",

        VerificationNotExecute: "Non eseguita",
        WrongVerification: "Errore",
        RightVerification: "Corretta",

        InspectionOperationsErrorsAlert: "Alcune operazioni presentano dei problemi, verificare il dettaglio.",
        InspectionOperationStockAlertMessage:
            "ATTENZIONE!! Non si dispone di una quantità sufficiente per coprire la richiesta di questo articolo (disponibilità: {0}, richiesta: {1})",

        WarehouseInspectionsDestinationProtocolsSettingsManager:
            "F.020 Protocolli per la generazione dei documenti dalle verifiche di magazzino",

        InspectionsDestinationProtocolsLoadingError:
            "Si è verificato un errore durante il caricamento dei protocolli di destinazione dei documenti generati dalle verifiche di magazzino",
        SaveInspectionsDestinationProtocolsError: "Si è verificato un errore durente il salvataggio.",

        WarehouseTransferDestinationProtocol: "Protocollo per trasferimenti di magazzino",
        InventoryAdjustmentDestinationProtocol: "Protocollo per rettifiche di magazzino",

        InspectionProgressColumn: "Avanzamento",
        InspectionWaitingProgress: "In attesa di lavorazione",
        InspectionWorkingProgress: "In lavorazione (art. lavorati {0}/{1}))",
        InspectionDoneProgress: "Terminata",
        InspectionDocumentsGenerationErrorProgress: "Problemi durante la generazione dei documenti",
        InspectionDocumentsGenerationErrorMessage: "Errore durante la generazione dei documenti:",
        InspectionDocumentsGenerationSuccessMessage: "Generazione dei documenti completata con successo",
        GenerateWarehouseInspectionDocuments: "Genera i documenti",

        ConfirmDeleteInspectionRow: "Sei sicuro di voler cancellare questa operazione?",
        ConfirmDeleteInspectionRows: "Sei sicuro di voler cancellare tutte le operazioni su questo magazzino?",
        InspectionOperationSourceDocumentColumnTitle: "Documento",
        InspectionOperationSourceDocumentRegisterColumnTitle: "Registro",
        InspectionOperationSourceDocumentAmountColumnTitle: "Q.tà",
        InspectionOpearionsImportLoading: "Importazione in corso...",

        MaxShownArticlesNumberPerWarehousePlaceholder: "Max. articoli",
        MaxShownArticlesNumberPerWarehouseLabel: "Num. max. articoli",
        MaxShownArticlesNumberPerWarehouseHelpText:
            "Mostra al massimo tot articoli per magazzino. N.B.: la modifica di questo filtro comporta la perdita di eventuali selezioni.",
        WarehouseOperationAlreadyExists: "Questa operazione è già stata inserita",

        DuplicatedWarehouseInspectionOperationLabel: "Operazione duplicata",
        DuplicatedWarehouseInspectionOperation: "Questa oprazione è già stata inserita per questo magazzino.",
        InspectionArticlePlaceholder: "Articolo...",
        InspectionSourceWarehousePlaceholder: "Magazzino sorgente...",
        InspectionDestinationWarehousePlaceholder: "Magazzino destinazione...",
        ComfirmDestinationWarehouseChangesOnInspectionRow:
            "ATTENZIONE!! Le operazioni configurate per questo magazzino veranno rimosse. Continuare?",
        ClosedInspectionsDateFilterTitle: "Periodo d'interesse ispezioni chiuse",
        InspectionSourcesWarehousesColumn: "Magazz. Prov.",
        InspectionDestinationWarehousesColumn: "Magazz. Dest.",

        DeleteDiscountCatalogMessage:
            "Sei sicuro di voler cancellare questa scontistica? Dal momento della cancellazione, le scontistiche applicate per i clienti/fornitori saranno diverse da quelle utilizzate fino ad ora.",

        MetelImportWaiting: "In Attesa",
        MetelImportInProgress: "Importazione",
        MetelImportDone: "Completato",
        MetelImportError: "Errore",

        JobOrders: "Commesse",

        ShippingDate: "Data consegna entro il",
        DefaultSuppliers: "Fornitori predefiniti",
        ShippingDatePlaceholder: "Data consegna",
        AllCustomersPlaceholder: "Tutti i clienti",
        AllJobOrdersPlaceholder: "Tutti le commesse",
        AllWarehousesPlaceholder: "Tutti i magazzini",
        AllCustomerOrdersPlaceholder: "Tutti gli ordini cliente",
        AllManufacturersPlaceholder: "Tutti i produttori",
        AllSuppliersPlaceholder: "Tutti i fornitori",
        AllDiscountFamiliesPlaceholder: "Tutte le famiglie di sconto",
        AllCustomersJobOrdersPlaceholder: "Tutte le commesse dei clienti selezionati",
        AllCustomersWarehousesPlaceholder: "Tutti magazzini dei clienti selezionati",
        AllCustomersCustomerOrdersPlaceholder: "Tutti gli ordini dei clienti selezionati",
        AllJobOrdersWarehousesPlaceholder: "Tutti magazzini delle commesse selezionate",
        AllJobOrdersCustomerOrdersPlaceholder: "Tutti gli ordini delle commesse selezionate",

        CustomersSelectHelp:
            "Il filtro clienti influisce anche sugli elementi selezionabili nei filtri di commesse, magazzini e ordini. Selezionando uno o più clienti, i filtri di commesse, magazzini e ordini saranno limitati ai soli elementi relativi ai clienti selezionati. La modifica dei clienti selezionati comporta un reset dei filtri impostati per commesse, magazzini e ordini.",
        JobOrdersSelectHelp:
            "Il filtro commesse influisce anche sugli elementi selezionabili nei filtri di magazzini e ordini. Selezionando una o più commesse, i filtri di magazzini e ordini saranno limitati ai soli elementi relativi alle commesse selezionate. La modifica delle commesse selezionate comporta un reset dei filtri impostati per magazzini e ordini. Il filtro per commesse ha precedenza sul filtro clienti.",

        ArticleCode: "Codice",
        ArticleDescription: "Descrizione",
        ArticleType: "Tipologia",
        ArticleUoM: "UdM",
        ArticleDealerPrice: "Prezzo rivenditore",
        ArticleCustomerPrice: "Prezzo cliente",
        ArticlePosition: "Posizione",
        EditArticle: "Modifica",
        ArticleMovements: "Movimenti",
        DeleteArticle: "Elimina",
        ArticlesPaginationPrev: "Prec",
        ArticlesPaginationNext: "Succ",
        Search: "Cerca",
        SearchPlaceholder: "Cerca...",
        ManufacturerFilterPlaceholder: "Filtra per produttore...",
        CatalogFilterPlaceholder: "Filtra per listino...",
        ArticlesSwitchLabel: "Articoli",
        PurchasesSwitchLabel: "Acquisti",
        EditPurchaseModalTitle: "Modifica acquisto",
        PurchaseSavedSuccessfully: "Acquisto salvato con successo!",
        NoPurchaseChangesMade: "Non è stata apportata alcuna modifica all'acquisto",
        ErrorLoadingJobOrderAddress: "Si è verificato un errore durante il caricamento dell'indirizzo",
    },
    WorkedHours: {
        Select: "Seleziona...",
        CallRights: "Diritti di chiamata",
        CallRight: "Diritto di chiamata",
        CustomersHoursReport: "Report ore clienti",
        LockedMonthlyHoursInsertion: "Inserimento ore del mese bloccato",
        UnlockedMonthlyHoursInsertion: "Inserimento ore del mese sbloccato",
        FlexibilityImported: "Flessibilità importata",
        SureToDeleteBusinessManagegemntVariations:
            "Sei sicuro di voler eliminare le variazioni di gestione aziendale selezionate?",
        DoNotDelete: "Non eliminare",
        Delete: "Elimina",
        WorkedHoursReport: "Report ore lavorate",
        PreviousMonthFlexibilityImport: "Importazione flessibilità del mese precedente",
        ChooseJobOrderForFlexibilityImport: "Selezionare la commessa sulla quale imputare la flessibilità",
        SelectResource: "Selezionare una risorsa",
        SelectDate: "Selezionare una data",
        SelectWorktimeCategory: "Selezionare una tipologia oraria",
        SelectStartAndEndTime: "Impostare l'ora d'inizio e di fine dell'attività",
        SelectJobOrder: "Selezionare una commessa",
        SelectProjectJobOrder: "Selezionare una commessa/progetto",
        InsertHours: "Inserire le ore",
        SureToInsertHours: "Sei sicuro di voler inserire le ore?",
        DoNotInsert: "Non inserire",
        Insert: "Inserisci",
        HoursInserted: "Ore inserite",
        WorkedHoursAnomalies: "Anomalie ore lavorate",
        AnomaliesSearchRulesUpdated: "Regole di ricerca anomalie aggiornate",
        SpecifyValidDateInterval: "Specificare un intervallo di date valido",
        DateIntervalTooBig:
            "Attenzione : L'intervallo di date specificato supera i 90 giorni e l'analisi delle anomalie potrebbe risultare molto lenta. Proseguire?",
        DoNotProceed: "Non procedere",
        Proceed: "Procedi",
        InsertTitle: "Inserire il titolo",
        SelectWorkItem: "Selezionare il Work Item",
        WorkedOnJobOrders: "Lavorato su commesse",
        WorkedHoursExport: "Esportazione ore lavorate",
        ReportModelsFolder: "Modelli Report",
        Work: "Lavoro",
        NewCallRightType: "Nuovo tipo di diritto di chiamata",
        SureToDeleteCallRightType: "Sei sicuro di voler eliminare il tipo di diritto di chiamata?",
        NewWorkTimeCategory: "Nuova tipologia",
        SureToDeleteWorkTimeCategory: "Sei sicuro di voler eliminare la tipologia oraria?",
        ResourcesJobTypes: "Risorse/Mansioni",
        CallRightTypes: "Tipi diritto di chiamata",
        WorkTimeCategories: "Tipologie orarie",
        WorkSheetEventMailSubject: "ProLife - Statino giornaliero del {0}",
        WorkedHoursEventMailSubject: "ProLife - Ore lavorate il {0}",
        OnSite: "In sede",
        OffSite: "Fuori sede",
        Away: "In trasferta",
        Year: "Anno",
        SavingInProgress: "Salvataggio in corso...",
        WorkSheetSaved: "Statino salvato",
        SelectTask: "Selezionare un'attività",
        SelectRole: "Selezionere la mansione",
        SelectServiceOrder: "Selezionere l'ordine di servizio",
        WorkReporting: "Inserimento Ore",
        WorkedHours: "Ore lavorate",

        SelectActivityPageTitle: "Mostra attività  del giorno e preferite",
        ModifyActivityPageTitle: "Modifica",
        SuggestionsPageTitle: "Suggerimenti",
        NewActivityPageTitle: "Nuovo",
        WarningInsertProject: "Inserire un progetto prima di continuare",
        WarningDeleteProject: "ATTENZIONE: Stai eliminando la commessa",
        WarningDeleteActivity: "ATTENZIONE: Stai eliminando l'attivit&agrave",
        Ok: "OK",
        No: "NO",

        WorkedAmountsPanel: "Inserimento quantità",
        WorkedHoursPanel: "Inserimento ore",
        WorkedHoursByJobOrderPanel: "Ore per commessa",

        WorkedAmountRowChangesLossConfirmMessage: "Ci sono modifiche non salvate. Continuare?",
        WorkedAmountRowChangesLossConfirmButton: "Continua senza salvare",
        WorkedAmountRowChangesLossCancelButton: "Annulla",

        DeleteWorkedAmountRowConfirmMessage: "Sei sicuro di eliminare la riga?",
        DeleteWorkedAmountRowConfirmButton: "Elimina",
        DeleteWorkedAmountRowCancelButton: "Annulla",

        WorkedAmountSaveSuccess: "Salvataggio riuscito",
        TaskIdRequired: "Selezionare l'attività",

        HoursApproval: "Approvazione Ore",
        SuggestedWorkableElementsDataSourceTitle: "Suggerimenti",
        DateDataSourceTitle: "Anno",
        ApprovalSuccessful: "Approvazione ore completata con successo!",
        ApprovalConfirmation: "Procedendo verranno approvate tutte le ore selezionate! Sei sicuro di voler continuare?",
        ApprovalConfirmationCancel: "Non Approvare",
        ApprovalConfirmationOk: "Approva le ore",

        PendingChangesToSave: "ATTENZIONE!! Ci sono modifiche non salvate. Continuare?",
        PendingChangesToSaveCancel: "Annulla",
        PendingChangesToSaveConfirm: "Continua senza salvare",
        WorkedHoursSaveSuccess: "Salvataggio riuscito",

        DeleteWorkedHoursRowConfirmMessage: "Sei sicuro di eliminare le ore lavorate?",
        DeleteWorkedHoursRowConfirmButton: "Elimina",
        DeleteWorkedHoursRowCancelButton: "Annulla",

        ApprovalText: "{0} da {1} il {2}",
        Approved: "Approvato",
        Rejected: "Rifiutato",
        Unmanaged: "Non gestito",

        AlertBeforeApprovalMessage:
            "ATTENZIONE!! Le ore selezionate per l'approvazione sono attualmente in modifica. Per passare all'approvazione, terminare le modifiche.",
        AlertBeforeApprovalTitle: "Errore approvazione ore",

        WorkedHoursEditorDialog: "Inserimento ore",
        NoWorkLabel: "Nessuna Attività",

        InvalidWorkedHours: "Il numero di ore lavorate non può essere zero e non deve superare le ventiquattro ore",
        MissingBreakHours: "Il numero di ore di pausa deve essere maggiore o uguale a zero",
        DayApprovalConfirmation:
            "Sei sicuro di voler approvare tutte le ore del giorno? N.B. Le ore rifiutate e le ore già approvate non verranno modificate.",
        DayApprovalConfirmationCancel: "Annulla",
        DayApprovalConfirmationOk: "Conferma",

        MultipleInsertErrorsDialg: "Errori in inserimento multiplo",

        WorkedHoursOnClosedJobOrderMessage: "ATTENZIONE!! Stai inserendo ore su una commessa chiusa. Continuare?",
        WorkedHoursOnClosedJobOrderCancel: "Annulla",
        WorkedHoursOnClosedJobOrderConfirm: "Continua",
        ResourceFieldPlaceholder: "Digita per cercare una risorsa...",

        InvalidTaskSelectedLabel: "Attività non valida",
        InvalidTaskSelected:
            "L'attività selezionata non è valida perché non è stata impostata per l'avanzamento a quantità.",

        CannotGetSuggestedTimeForWorkedHours: "Impossibile leggere la data/ora suggerita per l'inserimento ore.",
        AdjustTaskReferenceDateMessage:
            "ATTENZIONE!! La data selezionata per l'inserimento ore è precedente alla data di creazione dell'attività. La data di riferimento dell'attività verrà spostata alla data di inserimento delle ore lavorate. Continuare?",
        AdjustTaskReferenceDateCancel: "No",
        AdjustTaskReferenceDateConfirm: "Si",

        ServiceOrderNotFound:
            "ATTENZIONE!! Non è stato trovato nessun ordine di servizio valido alla data/ora selezionata, quindi non è possibile inserire le ore. Controllare che gli ordini di servizio della risorsa siano configurati correttamente.",
        ServiceOrderNotFoundLabel: "Errore inserimento ore",

        ServiceOrderNotFoundOnJobOrder:
            "ATTENZIONE!! Non è stata trovana nessuna mansione valida per la commessa selezionata, quindi non è possibile inserire le ore. Controllare la configurazione delle mansioni sulla commessa.",
        ServiceOrderNotFoundOnJobOrderLabel: "Errore inserimento ore",

        WorkTimeCategoriesNotFound:
            "ATTENZIONE!! Non sono state trovate tipologie orarie valide per la risorsa selezionata, quindi non è possibile inserire le ore. Controllare che i costi della risorsa siano configurati correttamente.",
        WorkTimeCategoriesNotFoundLabel: "",

        Ordinary: "Ordinario",
        Extraordinary: "Straordinario",
        Absent: "Assente",

        HoursReferencingDocumentForAlertMessage: "{0} num. {1} del {2} (Prot. {3})",
        ConfirmSavingImportedInDocumentsHours: "Salvare comunque le modifiche alle ore lavorate?",
        ConfirmDeletingImportedInDocumentsHours: "Eliminare comunque le ore lavorate?",

        WorkedHoursLockedTitle: "L'inserimento ore su questo giorno è stato inibito",
        WorkedHoursHoursStatusTitle:
            "Grigio - Ore segnate = ore contratto&#10;Verde - Ore segnate > ore contratto (senza straordinario)&#10;Giallo - Ore segnate < ore contratto&#10;Rosso - Ore straordinario > 0",
        WorkedHoursApprovedHoursTitle: "Ore approvate",
        WorkedHoursRefusedHoursTitle: "Ore rifiutate",
        WorkedHoursToBeApprovedHoursTitle: "Ore da approvare",
        WorkedHoursFlexibilityIsPresent: "Ore di flessibilità",

        TotalHoursOfTheDay: "Totale giornaliero",
        MonthImbalance: "Flessibilità mensile",
        OvertimeHours: "Straordinario mensile",
        ShowMaterialResources: "Mostra le risorse materiali",
        ViewAll: "Visualizza tutto",
        ShowHiddenActivitiesAndResources: "Mostra attività e risorse nascoste",
        GoToActivityDetails: "Vai al dettaglio dell'attività",
        CreateActivity: "Crea nuova attività",
        ServiceOrder: "Ordine di Servizio",
        Role: "Mansione",
        WorkTimeCategory: "Tipo",
        Place: "Luogo",
        Km: "Km viaggio",
        Notes: "Note",
        By: "da",
        AtDate: "in data",
        ActivityState: "Stato Attività",
        AlreadyWorked: "Già lav. ",
        EstimatedHours: " su ore stimate",
        Progress: "Avanzamento",
        ProgressUnitOfMeasure: "h/u",
        ProgressShort: "Avanz.",
        Complete: "100 %",
        Residual: "o T. residuo",
        MultipleInsert: "Inserimento su più giorni",
        MultipleInsertHelp:
            "Questa modalità di funzionamento permette di inserire la stessa riga di ore lavorate su più giorni.",
        Enable: "Abilita",
        Restart: "Ricomincia",
        Save: "Salva",
        Timezone: "Fuso orario",
        SelectTimezone: "Selezione fuso orario",
        SelectTimezoneHelp:
            "Selezionare un fuso orario per cambiare il fuso in cui sono mostrate le ore inserite. Cliccare resetta per riportare le righe al fuso orario originale.",
        Reset: "Resetta",
        TimezoneAlert: "Lista visualizzata in un fuso orario diverso da quello del dato originale.",
        ApproveAllHoursOfTheDay: "Approva tutte le ore del giorno",
        SelectDays: "Seleziona i giorni dal menù",
        EmptyActivitiesList: "Nessuna attività inserita",
        ActivitiesListHelp: "In questo spazio vengono mostrate le ore inserite per il giorno selezionato.",
        ApprovalNotes: "Note approvazione:",
        Billable: "Fatturabile",
        CallRightLabel: "D.D.C.",
        EmptyMultipleInsertActivitiesList: "Nessuna attività da inserire",
        MultipleInsertActivitiesListHelp:
            "In questo spazio vengono mostrate le ore che verranno inserite al salvataggio. Per ogni giorno selezionato sul menù verrà creata una riga alla data selezionata e contenente i dati inseriti nella maschera sopra. Le righe si aggiornano automaticamente al cambiamento dei dati nella maschera.",

        Autoselection: "Autoselezione",
        InvalidRole: "Impossibile determinare la mansione",
        InvalidWorkTimeCategory: "Impossibile determinare la tipologia oraria",
        SelectTaskOrJobOrder: "Selezionare un'attività o un progetto",

        HoursApprovalTitle: "Approvazione Ore",
        HoursApprovalApproveButton: "Approva",
        HoursApprovalSelectAllDays: "Seleziona/deseleziona tutti i giorni",
        HoursApprovalResourceColumn: "Risorsa/Progetto/Attività",
        HoursApprovalApprovedTooltip: "Approvato",
        HoursApprovalRejectedTooltip: "Rifiutato",
        HoursApprovalUnmanagedTooltip: "Non gestito",

        UnlockMonthlyHoursInsertion: "Sblocca inserimento ore su {0}",
        UnlockMonthlyHoursInsertionTooltip: "Sblocca l'inserimento ore nel mese.",

        LockMonthlyHoursInsertion: "Blocca inserimento ore su {0}",
        LockMonthlyHoursInsertionTooltip: "Blocca l'inserimento ore nel mese.",

        HoursApprovalSummaryResource: "Risorsa",
        HoursApprovalSummaryResourceGroups: "Gruppi",
        HoursApprovalSummaryResourceTotalHours: "Ore totali",
        HoursApprovalSummaryTitle: "Approvazione ore",
        HoursApprovalSummaryTotalHours: "Totale ore:",
        HoursApprovalSummaryApproveButton: "Approva",
        HoursApprovalSummaryApprovalGroupName: "Gruppo",
        HoursApprovalSummaryApprovalGroupRole: "Ruolo approvazione",
        HoursApprovalSummaryApprovalGroupNotes: "Note",
        HoursApprovalSummaryApprovalGroupNotesPlaceholder: "Note di approvazione....",
        ShowApprovalModal: "Mostra approvazione ore",

        InsertedHoursDefaultListTitle: "Ore lavorate",
        MultipleInsertListTitle: "Ore da inserire",

        WorkedHoursRowCreated: "Riga ore creata",
        WorkedHoursRowModified: "Riga ore modificata",
        WorkedHoursRowToBeApproved: "Da approvare",
        WorkedHoursRowPartiallyApproved: "In fase di approvazione",
        WorkedHoursRowApproved: "Approvate",
        WorkedHoursRowRejected: "Rifiutate",
        WorkedHoursRowReset: "Firma rimossa",
        WorkedHoursRowUnmanaged: "Riga ore non gestita",
        WorkedHoursRowSignAdded: "Riga di firma aggiunta",
        WorkedHoursRowSignRemoved: "Riga di firma rimossa",
        EditWorkedHours: "Modifica",
        DeleteWorkedHours: "Elimina",

        StartDateTime: "Data/ora inizio",
        EndDateTime: "Data/ora fine",
        ApprovalState: "Stato di approvazione delle ore",

        ApproverNameColumn: "Firmatario",
        GroupColumn: "Gruppo",
        ApprovalRoleColumn: "Ruolo",
        ApprovalOutcomeColumn: "Esito",
        ApprovalNotesColumn: "Note approvazione",

        ApprovalDialogTitle: "Approvazione ore",
        MinAnnual: "Min:",
        MaxAnnual: "Max:",
        MinAnnualRemaining: "Min remaining:",
        MaxAnnualRemaining: "Max remaining:",
        CurrentApprovalStateTitle: "Stato attuale",
        ApprovalStateHistoryTitle: "Storico",
        HoursApprovalModalRoleColumn: "Ruolo approvazione",
        HoursApprovalModalGroupColumn: "Gruppo risorse",
        HoursApprovalModalApproverColumn: "Firmatario",
        HoursApprovalModalOutcomeColumn: "Esito",
        HoursApprovalModalDateColumn: "Data firma",
        HoursApprovalModalNotesColumn: "Note",
        HouraApprovalNotesPlaceholder: "Note approvazione",
        HoursApprovalNotesIconTooltip: "Sono presenti delle note.",

        ApproveDialogApproveButton: "Approva",
        ApproveDialogRejectButton: "Rifiuta",
        ApproveDialogRemoveStateButton: "Resetta",

        MinHoursBudgetAlertTooltip:
            "La risorsa non ha raggiunto il minimo di ore specificato per una o più tipologie orarie.",
        MaxHoursBudgetAlertTooltip:
            "La risorsa ha superato il numero di ore massimo specificato per una o più tipologie orarie.",

        WorkTimeCategoriesRemainingBudgetTitle: "Dettaglio budget ore per tipologia",
        ErrorBalanceExceeded: "Non è possibile salvare.",
        WarningBalanceExceeded: "Salvare comunque?",

        HoursApprovalResourceName: "Risorsa",
        HoursApprovalHours: "Ore lavorate",
        HoursApprovalRole: "Mansione",
        HoursApprovalWorktimeCategory: "Tipologia oraria",
        HoursApprovalActivity: "Attività",
        HoursApprovalJobOrder: "Commessa",

        InsertFlexibility: "Inserisci flessibilità",
        InsertWorkedHours: "Inserisci ore standard",

        SelectHoursToApprove:
            "Selezionare almeno una riga da approvare. Se non è disponibile nessuna riga, assicurarsi di avere l'abilitazione all'approvazione delle ore su almeno uno dei gruppi di risorse in cui la risorsa per la quale state approvando le ore è inserita.",
        SelectHoursToApproveTitle: "Approvazione ore",

        MinNotReachedMessage: "ATTENZIONE!! Non hai ancora raggiunto il minimo di ore per questa tipologia oraria.",
        MaxOverflowMessage: "ATTENZIONE!! Hai superato il numero di ore massimo per questa tipologia oraria.",
        FlexibilityMaxExceeded: "ATTENZIONE!! Hai superato il numero di ore massimo di flessibilità.",
        HoursReferencingDocumentMessage: "Le ore sono state importate in documenti amministrativi.",
        HoursReferencingDocumentAndNotAuthorizedMessage:
            "Le ore sono state importate in documenti amministrativi. Non si dispone dei diritti necessari per la modifica/cancellazione di ore importate in documenti.",
        TaskOrWorkflowBlocked:
            "Non è possibile modificare le ore lavorate perché l'attività su cui le ore erano inserite è bloccata o il piano in cui è contenuta ha un esito che impedisce la modifica delle ore lavorate.",
        TaskCreationReferenceDateBeforeOrSameOfDate:
            "Si sta tentando di inserire le ore prima della data di creazione del task.",
        WarningOrErrorForBalance: "Warning sui budget TBD",
        MaxFlexibilityExceeded: "Warning Flessibilità positiva / negativa superata TBD",
        HoursOverlappingError:
            "Impossibile inserire le ore: l'orario si accavalla con quello di altre ore lavorate nello stesso giorno.",
    },

    DurationComponente: {
        PositiveSignLabel: "Scarica",
        NegativeSignLabel: "Carica",
    },

    Survey: {
        SurveyApplication: "Configurazione Call Center",
        QuestionnaireStatus: "L.040 Stato questionario",
        QuestionnaireCategory: "L.030 Categoria questionario",
        CategoryInitial: "C",
        CampaignStatus: "L.010 Stato campagna",
        StatusInitial: "S",
        SampleStatus: "L.060 Stato campione",
        SampleTypes: "L.050 Tipologia di campione",
        Survey: "Questionario",
        QuestionnaireNotFound: "Questionario non trovato!",
        PeopleNotFound: "Contatto non trovato!",
        QuestionsNotFound: "Domande del questionario non trovate!",
        Loading: "Caricamento in corso...",
        SurveyUrl: "Survey",
        SampleUrl: "Sample",
        ConfirmUnsavedChanges:
            "Sono state apportate delle modifiche, annullando verranno perse.\nSei sicuro di voler continuare?",
        ConfirmUnsavedChangesCancel: "Ritorna alla Modifica",
        ConfirmUnsavedChangesConfirm: "Annulla Modifiche",
        DeleteSampleMsg: "Sei sicuro di voler eliminare questo campione?",
        DeleteSampleMsgCancel: "Ritorna alla Modifica",
        DeleteSampleMsgConfirm: "Elimina il campione",
        NewSample: "Nuovo campione",
        DownloadQuestionnaireError: "Si è verificato un errore sul server, impossibile scaricare il questionario!",
        DownloadQuestionsError:
            "Si è verificato un errore sul server, impossibile scaricare le domande del questionario!",
        DownloadSectionsAndQuestionsError:
            "Si è verificato un errore sul server, impossibile scaricare le sezioni e le domande del questionario!",
        DownloadSectionsError:
            "Si è verificato un errore sul server, impossibile scaricare le sezioni del questionario!",
        DownloadAnswersError: "Si è verificato un errore sul server, impossibile scaricare le risposte della domanda!",
        DownloadQuestionTypesError:
            "Si è verificato un errore sul server, impossibile scaricare le tipologie di domanda!",
        DownloadAnswerTypesError:
            "Si è verificato un errore sul server, impossibile scaricare le tipologie di risposta!",
        QuestionnairesEditor: "L. Questionari e sondaggi",
        Questionnaires: "Questionari",
        Campaigns: "Campagne",
        Samples: "Campioni",

        SurveyMenuTitle: "Applicazioni",
        OpenState: "Aperti",
        ClosedState: "Chiusi",

        QuestionnaireUrl: "Questionari",

        AbortQuestionnaireEdit: "Attenzione! Eventuali modifiche andranno perse. Continuare?",
        AbortQuestionnaireEditConfirm: "Continua e perdi le modifiche",
        AbortQuestionnaireEditCancel: "Annulla",

        NewQuestionnaire: "Nuovo questionario",
        NewQuestionnaireVersion: "Nuova versione",
        EditQuestionnaire: "Modifica questionario",
        AddQuestionnaireSection: "Nuova sezione",
        EditQuestionnaireSection: "Modifica sezione",

        DeleteQuestionnaire: "Vuoi cancellare il questionario?",
        DeleteQuestionnaireCancel: "Annulla",
        DeleteQuestionnaireConfirm: "Elimina",

        DeleteQuestion:
            "Sei sicuro di voler eliminare la domanda? Tutte le risposte inserite per questa domanda andranno perse.",
        DeleteQuestionCancel: "Annulla",
        DeleteQuestionConfirm: "Elimina",

        DeleteSection:
            "Sei sicuro di voler eliminare la sezione? Tutte le domande di questa sezione e le relative risposte inserite andranno perse.",
        DeleteSectionCancel: "Annulla",
        DeleteSectionConfirm: "Elimina",

        ImportExistingSamplesError: "Errore durante l'importazione. Consultare il log per maggiori dettagli.",
        ImportExcelFilesError: "Errore durante l'importazione dei file. Consultare il log per maggiori dettagli.",
        CleanSampleError: "Errore durante la pulizia del campione",
        WriteSampleLogError: "Errore durante la scrittura del log",
        SampleModifyError: "Errore durante la modifica del campione. Consultare il log per maggiori dettagli.",
        SampleModifySuccess: "Campione modificato con successo! Consultare il log per maggiori dettagli.",
        SampleCreationError: "Si è verificato un errore sul server, impossibile creare il campione",
        SampleCreationSuccess: "Campione creato con successo!",
        SampleDeleteError: "Si è verificato un errore sul server, impossibile eliminare il campione",
        SampleDeleteSuccess: "Campione eliminato con successo!",
        Failed: "Operazione non riuscita a causa di un errore sul server",

        Failure: "FAILURE",
        Success: "SUCCESS",

        SampleImportDialogTitle: "Importa campioni",
        CleanSampleDialogTitle: "Pulizia campione",
        DownloadQuestionnaireStatesError:
            "Si è verificato un errore sul server, impossibile scaricare gli stati del questionario!",

        LockQuestionnaireDialogTitle: "Blocca il questionario",
        UnlockQuestionnaireMessage: "Sei sicuro di voler sbloccare il questionario?",
        UnlockQuestionnaireCancel: "Annulla",
        UnlockQuestionnaireConfirm: "Sblocca",
        UnlockQuestionnaireError: "Si è verificato un errore sul server, impossibile sbloccare il questionario!",
        EditQuestionnaireDialogAddTitle: "Inserisci un nuovo questionario",
        EditQuestionnaireDialogEditTitle: "Modifica il questionario",
        EditSectionDialogAddTitle: "Inserisci una nuova sezione",
        EditSectionDialogEditTitle: "Modifica la sezione",
        SaveQuestionnaireError: "Si è verificato un errore sul server, impossibile inserire il questionario!",
        SectionNameRequired: "Inserire un nome per la sezione",
        QuestionnaireTitleRequired: "Inserire un titolo per il questionario",
        MissingQuestionData: "Inserire il testo della domanda ",
        MissingQuestionAnswers: "Inserire le risposte della domanda ",
        MissingAnswerData: "Dati mancanti nelle risposte della domanda  ",
        SaveFullQuestionnaireError: "Si è verificato un errore sul server, impossibile salvare il questionario",
        DeleteQuestionnaireError: "Si è verificato un errore sul server, impossibile eliminare il questionario",
        MissingQuestionnaireRequiredData:
            "Le domande del questionario sono incomplete. Verificare di aver insrito tutti i dati prima di salvare.",
        WithoutSectionSelectCaption: "Nessuna sezione selezionata",
        DownloadQuestionnaireCategoriesError:
            "Si è verificato un errore sul server, impossibile scaricare le categorie del questionario!",
        DisabledQuestionOrSection: "Questa domanda/sezione è disabilitata.",
        EnabledQuestionOrSection: "Questa domanda/sezione è abilitata.",
        ConfirmFilesToImportDelete: "Eliminare i file selezionati?",
        FilesToImportDeleteConfirmButton: "Elimina",
        FilesToImportDeleteCancelButton: "Annulla",
        ImportFromExistingSampleLogAction: "Importazione dal campione ",
        ImportFromExcelSampleLogAction: "Importazione dal file Excel ",
        ImportSingleContactLogAction: "Inserimento nuovo contatto nel campione - ID contatto: {0}",
        SampleTypesDownloadError:
            "Si è verificato un errore sul server, impossibile scaricare le tipologie di campione",
        EditSampleDialogAddTitle: "Nuovo campione",
        EditSampleDialogEditTitle: "Modifica campione",
        SampleTitleRequired: "Il nome del campione è obbligatorio",
        ImportActionSuccessTitle: "Operazione riuscita",
        ImportActionPartialTitle: "Importazione parziale. Consultare il log per maggiori dettagli",
        ImportActionErrorTitle: "Operazione non riuscita",
        SampleContentDialogTitle: "Elenco contatti del campione",
        GetSampleContentError: "Si è verificato un errore sul server, impossibile scaricare la lista dei contatti",
        PeopleDetailsDialogTitle: "Dettagli contatto",
        GetPeopleDetailsError: "Si è verificato un errore sul server, impossibile scaricare i dati del contatto",
        GetPeopleMetadataError:
            "Si è verificato un errore sul server, impossibile scaricare i dati aggiuntivi del contatto",
        PeopleMetadataBooleanTrueValue: "Vero",
        PeopleMetadataBooleanFalseValue: "Falso",
        AddPeopleDialogTitle: "Nuovo contatto",
        EditPeopleDialogTitle: "Modifica contatto",
        GetMetadataTypesError: "Si è verificato un errore, impossibile scaricare i tipi di metadato",
        MissingPeopleData: "Dati mancanti! Verificare di aver inserito tutti i dati richiesti e riprovare",
        AddPeopleError: "Si è verificato un errore sul server, impossibile inserire il contatto",
        UpdatePeopleError: "Si è verificato un errore sul server, impossibile aggiornare il contatto",
        SingleContactImport: "Inserimento nuovo contatto nel campione - ID contatto: ",

        CampaignUrl: "Campagne",
        NewCampaign: "Nuova campagna",
        GetCampaignTypesError: "Si è verificato un errore sul server, impossibile scaricare le tipologie di campagna",
        EditCampaignDialogAddTitle: "Nuova campagna",
        EditCampaignDialogEditTitle: "Modifica campagna",
        SaveCampaignError: "Si è verificato un errore sul server, impossibile salvare i dati della campagna",
        CampaignTitleRequired: "Il titolo della campagna è obbligatorio",
        CampaignStartDateRequired: "La data d'inizio della campagna è obbligatoria",
        InvalidCampaignDuration: "La data di fine della campagna deve essere successiva a quella d'inizio",
        CampaignQuestionnaireRequeired: "Selezionare un questionario",
        CampaignSampleRequeired: "Selezionare un campione",
        CampaignInvalidTimeIntervalsSetting:
            "Gli intervalli di giorni inseriti nelle regole, se specificati, devono essere maggiori di 0",
        GetCampaignDetailsError: "Si è verificato un errore sul server, impossibile scaricare i dati della campagna",
        EmptyDescription: "Nessuna descrizione disponibile",
        DeleteCampaignError: "Si è verificato un errore sul server, impossibile eliminare la campagna",

        UnsavedChanges: "Eventuali modifiche andranno perse. Continuare?",
        NewQuestionnaireVersionConfirmDialogText:
            "Questa funzione crea un nuovo questionario a partire da quello corrente. Continuare?",
        NewQuestionnaireVersionConfirmDialogConfirm: "Crea",
        NewQuestionnaireVersionConfirmDialogCancel: "Annulla",
        CreateNewVersionError:
            "Si è verificato un errore sul server, impossibile creare una nuova versione del questionario",
        GetRootServiceUrlError:
            "Si è verificato un errore sul server, impossibile determinare l'URL del servizio. Il download del template Excel non sarà disponibile",
        GetCampaignStatesError: "Si è verificato un errore sul server, impossibile scaricare gli stati della campagna",

        EditQuestionnaireWithAssociatedCampaignAlert:
            "ATTENZIONE!! Stai per modificare un questionario associato ad una o più campagne. La modifica potrebbe portare a risultati inconsistenti a fine campagna. Continuare?",
        EditSampleWithAssociatedCampaignAlert:
            "ATTENZIONE!! Stai per modificare un campione associato ad una o più campagne. La modifica potrebbe portare a risultati inconsistenti a fine campagna. Continuare?",
        EditStartedCampaignAlert:
            "ATTENZIONE!! Stai per modificare una campagna in corso o già conclusa. La modifica potrebbe portare a risultati inconsistenti. Continuare?",

        EditQuestionnaireError:
            "Si è verificato un errore sul server, impossibile accedere all'editor del questionario",
        EditSampleError: "Si è verificato un errore sul server, impossibile accedere all'editor del campione",
        EditCampaignError: "Si è verificato un errore sul server, impossibile accedere all'editor della campagna",

        GetPeopleSamplesError:
            "Si è verificato un errore, impossibile verificare in quali campioni è presente il contatto",

        AlertDialogTitle: "Trovate campagne associate",
        AlertDialogForCampaignTitle: "Modifica campagna avviata",
        EditCampaignAlertDialog: "Modifica campagna in corso",

        CanNotChangeSampleState:
            "ATTENZIONE!! Non è possibile modificare lo stato del campione perché associato ad almeno una campagna",
        CanNotChangeQuestionnaireState:
            "ATTENZIONE!! Non è possibile modificare lo stato del questionario perché associato ad almeno una campagna",
        CanNotDeleteQuestionnaire:
            "ATTENZIONE!! Non è possibile eliminare il questionario perché associato ad una o più campagne",
        CanNotDeleteSample: "ATTENZIONE!! Non è possibile eliminare il campione perché associato ad una o più campagne",

        HideButtonEnabled: "Nascosto",
        HideButtonDisabled: "Nascondi",

        DraftState: "Bozza",
        PlannedState: "Programmata/o",
        InProgressState: "In corso",
        OverState: "Terminata/o",
        EnableState: "Abilitato/a",
        DisabledState: "Disabilitato/a",

        CampaignTypes: "L.020 Tipologie di campagna",

        GetPeopleHistoryError:
            "Si è verificato un errore, impossibile scaricare la cronologia delle chiamate verso il contatto",
        GetCampaignResourcesError:
            "si è verificato un errore imprvisto, impossibile caricare le risorse assegnate alla campagna",

        NewInboundConfiguration: "Nuova configurazione",
        InboundNoSurveySelected: "E' necessario selezionare il questionario prima di continuare",
        InboundNoPhoneEntered: "Il campo Telefono Centralino è obbligatorio",
        InboundLoosingChanges:
            "ATTENZIONE! Le modifiche apportate non sono state salvate, continuando si perderanno le modifiche. Sei sicuro di voler continuare?",
        InboundLoosingChangesConfirm: "Perdi modifiche",
        InboundLoosingChangesCancel: "Annulla",
        InboundDeleteConfiguration: "Sei sicuro di voler eliminare la configurazione?",
        InboundDeleteConfigurationConfirm: "Elimina",
        InboundDeleteConfigurationCancel: "Annulla",

        DeletePeopleFromSampleConfirmMessage:
            "Sei sicuro di voler eliminare questo contatto dal campione? Il contatto non verrà più visualizzato nella coda delle chiamate.",
    },

    SurveyWizard: {
        SurveyWizardApplication: "Wizard questionari",
        SurveyWizard: "Wizard questionari",
        SurveyWizardUrl: "SurveyWizard",
        CampaignUrl: "Campagna",
        WizardUrl: "Wizard",
        QuestionnaireUrl: "Questionario",
        ResumedWizardUrl: "Resumed",

        CallResult: "L.070 Esito chiamata",

        WizardTestTitle: "Test del questionario",
        ReschedulesTitle: "Ripianifica l'intervista",
        FinishInterviewTitle: "Termina l'intervista",
        InterviewCompletedTitle: "Intervista completata",
        DisableQuestionConfirmDialogTitle: "Confermi di disabilitare la domanda?",
        DisableSectionConfirmDialogTitle: "Confermi di disabilitare la sezione?",
        CloseInterruptedInterviewDialogTitle: "Intervista non completata",

        DisableSectionPromptMessage:
            'ATTENZIONE!! La sezione "{section}" verrà disabilitata a seguito della variazione della risposta data. La sezione contiene domande alle quali si è già risposto e, confermando le modifiche, le risposte andranno perse. Continuare?',
        DisableQuestionPromptMessage:
            'ATTENZIONE!! La domanda "{question}" verrà disabilitata e le risposte a questa domanda andranno perse. Continuare?',

        ResumeInterruptedInterviewMessage:
            "È presente un'intervista non completata verso il contatto {contact}, numero di telefono {phone-number}. Riprendere l'intervista da dove si era rimasti?",
        ResumeInterruptedInterviewCancel: "Non continuare",
        ResumeInterruptedInterviewConfirm: "Riprendi",

        GetContactSelectionConfigurationError:
            "Si è verificato un errore sul server, impossibile scaricare la configurazione per la selezione del contatto da chiamare",
        GetCallsQueueError: "Si è verificato un errore, impossibile scaricare la coda delle chiamate",
        GetPeopleDataError: "Si è verificato un errore, impossibile scaricare i dati del contatto",
        GetCampaignDataError: "Si è verificato un errore, impossibile scaricare i dati della campagna",
        SaveWizardStepError: "Si è verificato un errore, impossibile salvare la risposta del questionario",
        GetTimeSlotsError: "Si è verificato un errore, impossibile scaricare le fasce orarie",
        GetMotivationsError: "Si è verificato un errore, impossibile scaricare le motivazioni",
        GetCallInQueueError: "Si è verificato un errore, impossibile scaricare i dati della chiamata da effettuare",
        StartNewCallError: "Impossibile prendere in carico la chiamata a causa di un errore imprevisto",
        FinishInterviewError: "Impossibile completare l'intervista a causa di un errore imprevisto",
        GetInterruptedInterviewError:
            "Impossibile verificare la presenza di interviste interrotte a causa di un errore imprevisto",
        ResetAnswerError: "Si è verificato un errore sul server, impossibile cancellare la risposta alla domanda",
        GetCallsInProgressNumberError:
            "Si è verificato un errore sul server, impossibile scaricare il numero di chiamate in corso",
        GetTerminatedCallsNumberError:
            "Si è verificato un errore sul server, impossibile scaricare il numero di chiamate terminate",
        GetNextContactToCallError:
            "Si è verificato un errore sul server, impossibile selezionare il prossimo contatto da chiamare",
        GetPreviousCallResultError:
            "Si è verificato un errore, impossibile verificare l'esito della chiamata precedente",
        GetContactsToCallNumberError:
            "Si è verificato un errore, impossibile verificare il numero di contatti ancora da chiamare",
        GetQueueDetailsError: "Si è verificato un errore, impossibile scaricare la coda delle chiamate",

        MissingRequiredData: "Attenzione! Tutti i campi sono obbligatori",

        ReschedulesCallSuccess: "L'intervista è stata ripianificata con successo",
        ReschedulesCallError: "Impossibile ripianificare l'intervista a causa di un errore imprevisto",
        FinishCallSuccess: "L'intervista è stata terminata con successo",
        FinishCallError: "Impossibile chiudere l'intervista a causa di un errore imprevisto",

        ContactSelectionRequered: "Selezionre il contatto da chiamare",

        WizardExit: "ATTENZIONE!! Stai per uscire dal wizard ed interrompere l'intervista. Continuare?",

        NotRespondState: "Non risponde",
        UnavailableState: "La persona non è disponibile al momento",
        WrongNumber: "Numero di telefono errato",
        MaxAttemptExceeded: "Numero massimo di tentativi superato",
        RefuseToRespond: "Si rifiuta di rispondere al questionario",
        SuccessfulInterview: "Intervista conclusa con successo",
        AbortedCall: "Chiamata abortita",
        ContinueInAnotherMoment: "Chiede di continuare in un altro momento",

        ToCallState: "Da chiamare",
        InProgressCallState: "Chiamata in corso",
        OverCallState: "Chiamata conclusa",

        QueueState: "L.080 Stato della chiamata",
        CanNotDeleteUsedData: "Non è possibile eliminare dati che sono attualmente in uso",

        NoQuestionnaireConfigured: "Nessun questionario configurato per la chiamata in ingresso!",
        ConfigurationMissing: "Configurazione mancante",
        Unknown: "Sconosciuto",

        CampaignCannotStart: "La campagna non può ancora essere avviata perché programmata per il {0}.",

        Attempt: "Tentativo",
        AttemptDate: "Data",
        AttemptResult: "Esito",
        AttemptResultLogicalStatus: "Stato logico",
        AttemptOperator: "Operatore",

        AttemptsDetail: "Dettaglio tentativi",
        ContactNameColumn: "Nome/Ragione sociale",
        ContactPhoneNumberColumn: "Telefono",
        NumberOfAttemptsColumn: "N° di tentativi",
        StatusColumn: "Stato",
        PlannedForColumn: "Pianificata per il",
        ReasonColumn: "Motivo",
        ReasonLogicalStatusColumn: "Stato logico",

        FirstCall: "Prima chiamata",
        ShowOnlyCallableContacts: "Mostra solo i contatti con stato chiamata non conclusa",
        PlannedForFilterTitle: "Pianificata nel periodo",
        ConatctSearchFilterLabel: "Cerca contatto",
        ConatctSearchFilterPlacehoder: "Nome, cognome o ragione sociale",
        AttemptsNumberFilter: "Numero di tentativi compreso tra",
        PhoneSearchFilterLabel: "Cerca telefono",
        PhoneSearchFilterPlacehoder: "Numero di telefono",
        ExportQueueToExcel: "Esporta in Excel",
    },

    FunctionPointComputation: {
        FunctionPointComputationApplication: "Conteggio Function Point",
        FunctionPointComputation: "Conteggio Function Point",
        FunctionPointComputationUrl: "FunctionPointComputation",

        FunctionPointComputationURL_Projects: "FunctionPointComputation/Progetti",
        FunctionPointComputationURL_Records: "FunctionPointComputation/Conteggi",

        FunctionPointProjectsName: "Progetti",
        FunctionPointRecordsName: "Conteggi",

        ProjectDialogTitle: "Nuovo Progetto",
        RecordDialogTitle: "Nuovo Conteggio",

        SaveSuccess: "Salvataggio riuscito",
        ProjectDeleteSuccess: "Progetto eliminato con successo",
        RecordDeleteSuccess: "Conteggio eliminato con successo",

        DeleteProject: "Sei sicuro di voler eliminare il progetto?",
        DeleteRecord: "Sei sicuro di voler eliminare il conteggio?",
        Confirm: "Conferma",
        Cancel: "Annulla",
    },

    Provisioning: {
        ApplicationName: "Rifornimenti",
        UrlNotFound: "L'applicazione richiesta non esiste",
        PanelFacoryPanelNotFoundError: "Impossibile trovare il pannello per l'id richiesto: {0}",
        PreOrders: "Pre-ordini",
        PreOrdersRows: "Righe pre-ordine",
        PreOrder: "Pre-ordine",
        PreOrderLabel: "Preordine nr. {0} del {1}",
        RequestsForQuotations: "Richieste d'offerta",
        RequestsForQuotation: "Richiesta d'offerta",
        RequestsForQuotationProtocols: "Protocolli Richieste d'Offerta",
        RequestsForQuotationProtocolsShortLabel: "R.D.O.",
        SupplierOrders: "Ordini fornitore",
        NewPreOrder: "Nuovo pre-ordine",
        NewRequestForQuotation: "Nuova richiesta d'offerta",
        RequirementsImporterTitle: "Importa fabbisogni",

        DraftPreOrder: "Bozza",
        OnWorkingPreOrder: "Aperto",
        ClosedPreOrder: "Chiuso",
        PartiallyManagedPreOrder: "In Gestione",
        UnknownPreOrderState: "N/A",

        RequirementSourceDescription: "Riga nr. {0} dell'ordine nr. {1} del {2}, prot. {3}",
        RequirementSourceDescriptionTopRow: "{0} N.{1} {2}",
        RequirementSourceDescriptionTopRowWithVersionNumber: "{0} N.{1} - N. ver. {2} {3}",
        RequirementSourceDescriptionBottomRow: "Riga {0}",
        OnlyArticles: "Solo articoli",
        OnlyPurchases: "Solo acquisti",

        PreOrderPendingRequestsMessage: "Ci sono modifiche al preordine non salvate. Continare?",
        Abort: "Annulla",
        Confirm: "Conferma",
        ChangeAmountConfirm:
            "Attenzione! Le righe delle richieste d'ordine collegate a questa riga hanno una quantità diversa da quella specificata. Si desidera aggiornare le righe delle richieste d'ordine con la nuova quantità specificata o mantenere invariata la quantità e modificarla soltanto sulla riga di preordine?",
        ChangeAmountOnBoth: "Aggiorna le Richieste d'Offerta",
        DoNotChangeRequestForQuotationAmount: "Cambia soltanto il Preordine",

        RemoveSourceRowFromPreOrderRowMessage:
            "ATTENZIONE!! La riga verrà rimossa dal preordine e sarà di nuovo disponibile in fase di importazione dei fabbisogni. Continuare?",
        RemoveSourceRowsFromPreOrderRowMessage:
            "ATTENZIONE!! La righe verranno rimosse dal preordine e saranno di nuovo disponibili in fase di importazione dei fabbisogni. Continuare?",
        RemoveSupplierFromPreOrderRowMessage:
            "ATTENZIONE!! Con la rimozione del fornitore verrà rimossa anche la riga della richiesta d'offerta collegata. Continuare?",
        DeletePreOrderMessage: "ATTENZIONE!! Eliminare definitivamente questo preordine?",
        DeleteRequestForQuotationMessage: "ATTENZIONE!! Eliminare definitivamente questa richiesta di offerta?",
        RemoveRequestForQuotationRow: "Vuoi rimuovere questa riga?",
        RemoveRequestForQuotationRows: "Vuoi rimuovere le righe selezionate?",

        RequestForQuotationDeleted: "Richiesta d'offerta eliminata correttamente",
        RequestForQuotationSaved: "Richiesta d'offerta salvata correttamente",

        PreOrderDeleted: "Preordine eliminato correttamente",
        PreOrderDeleteFailed: "Si è verificatoo un errore durante l'eliminazione del preordine",
        PreOrderRowDeleted: "Riga preordine eliminata correttamente",
        PreOrderSaved: "Preordine salvato correttamente",

        SupplierRemovedFromPreorderRow:
            "Fornitore rimosso correttamente dalla riga di preordine (il fornitore potrebbe essere ancora visibile nella lista perché una richiesta d'offerta è stata generata prima della cancellazione del fornitore dalla riga del preordine)",

        RequestForQuotationForList: "Richiesta d'offerta nr. {0} del {1} (Fornitore: {2})",
        RequestForQuotationWithNumberAndDate: "Richiesta d'offerta nr. {0} del {1}",

        RequestForQuotationNotSent: "Richiesta non inviata",
        RequestForQuotationSent: "Richiesta inviata il {0} da {1}",
        ChangeDeliveryDateMessage:
            "ATTENZIONE!! La data di consegna derivante dalla riga di preordine selezionata è più vicina rispetto a quella impostata sulla riga della richiesta d'offerta. Vuoi sostituire la data della richiesta d'offerta con quella del preordine?",

        MissingRequestForQuotationResponseAmount: "Nessuna quantità confermata",
        MissingRequestForQuotationResponsePrice: "Nessun prezzo confermato",
        MissingRequestForQuotationResponseDeliveryDate: "Nessuna data di consegna confermata",

        DeletePreOrderRowFailed: "Si è verificato un errore durante l'eliminazione della riga",
        FailedToSavePreOrderRow: "Si è verificato un errore durante il salvataggio della riga",
        FailedToSavePreOrder: "Si è verificato un errore durante il salvataggio del preordine",

        PreOrderRowStateChangedSuccessfully: "Righe di preordine spostate con successo!",
        NoPreOrderRowsSelected: "Nessuna riga di preordine selezionata",

        DeleteRequestForQuotationRowsConnectedToPreorder:
            "Sono presenti delle righe di richieste d'offerta collegate a questo preordine. Si desidera eliminare anche le righe delle richieste d'offerta?",
        KeepRequestForQuotationRows: "Mantieni le Righe",
        DeleteRequestForQuotationRows: "Elimina",

        NoRequestForQuotationRowsSelected: "Nessuna riga di richiesta d'offerta selezionata",
        NoSupplierOrderVatRegisterFound:
            "Nessun protocollo per Ordini Fornitore configurato!\r\nPer poter generare gli ordini fornitore è necessario configurare prima almeno un numero di protocollo nelle impostazioni",
        SupplierOrdersGenerationCompleted: "Ordini Fornitore generati con successo!",

        PurchaseRequest: "Richiesta d'Acquisto",
        PurchaseRequests: "Richieste d'Acquisto",
        PurchaseRequestProtocols: "Protocolli Richieste d'Acquisto",
        PurchaseRequestProtocolsShortLabel: "R.D.A.",

        RequestForQuotationRowInfo: "Informazioni aggiuntive",
        WarningTheSelectedDateIsBiggerThanTheDocumentOne:
            "Attenzione! La data selezionata è posteriore alla data del documento che è il {0}!",
        SupplierNotFound: "(Fornitore non trovato)",
        DocumentWizardNoRfqs: "Nessun documento presente.",
    },
    GDPR: {
        ApplicationName: "Gestione Dati",
        DataManagement: "J. Gestione Dati",
        DataManagementLabel: "Gestione Dati",
        TreatmentTypes: "J.020 Tipi di Trattamento",
        GDPR: "GDPR",
        Error: "Errore",
        Title: "Titolo",
        State: "Stato",
        Open: "Aperto",
        Closed: "Chiuso",
        Active: "Attiva",
        LockUnlockEvent: "Blocca/Sblocca Evento",
        EventIsLocked: "Questo evento è bloccato e non può essere modificato.",
        OtherPeopleEvent: "Utente non autorizzato a modificare eventi di terzi.",
        SystemEvent: "Questo evento è generato dal sistema e non può essere modificato.",
        TreatmentsSearchResults: "Risultati Ricerca Trattamenti",
        TreatmentDialogDetailTitle: "GDPR - Dettaglio Trattamento",
        TreatmentDialogNewTitle: "GDPR - Nuovo Trattamento",
        JobOrderTreatmentsTitle: "Gestione GDPR",
        JobOrderTreatmentsHeader: "Gestione Trattamenti GDPR",
        AddAJobOrder: "Inserisci una commessa...",
        AddAResource: "Inserisci una risorsa...",
        AddNewTreatment: "Nuovo Trattamento",
        AddSuccess: "Nuovo Trattamento aggiunto con successo",
        EditSuccess: "Trattamento modificato con successo",
        AddExistingTreatment: "Associa Trattamento",
        AddExistingButtonText: "Associa Trattamento...",
        AddNewButtonText: "Nuovo...",
        AddExistingSuccess: "Aggiunto alla lista dei Trattamenti",
        SelectTreatment: "Seleziona Trattamento da aggiungere:",
        DisconnectConfirmationMessage: "Sei sicuro di voler disconnettere questo Trattamento dalla commessa?",
        ConfirmDisconnect: "Disconnetti",
        CancelDisconnect: "Mantieni",
        SuccessDisconnect: "Operazione riuscita",
        Description: "Descrizione",
        Location: "Posizione",
        CreationDate: "Data Creazione",
        LastEditDate: "Data Ultima Modifica",
        CreatedBy: "Creato da",
        EditedBy: "Modificato da",
        Actions: "Azioni",
        DisconnectFromJobOrder: "Rimuovi dalla commessa",
        ErrorDateAddRemoveResources:
            "Errore nella sequenza di aggiunta/rimozione risorse. Controllare gli eventi e le risorse assegnate.",
        ErrorZeroTreatmentTypes: "Inserisci almeno un tipo di trattamento",
        ErrorNoTreatmentSelected: "Nessun Trattamento selezionato",
        ErrorNoResourceSelected: "Nessuna risorsa selezionata",
        ErrorNoTitle: "Inserisci un titolo",
        ErrorNoDescription: "Inserisci una descrizione",
        ErrorNoTreatmentType: "Inserisci un tipo di trattamento",
        ErrorNoLocation: "Inserisci una posizione",
        DeleteItemMessage: "Sei sicuro di voler eliminare questo elemento?",
        ConfirmDeleteItem: "Elimina",
        CancelDeleteItem: "Mantieni",
        JobOrderAlreadyInList: "Commessa già presente nel Trattamento.",
        SelectAJobOrder: "Seleziona una commessa...",
        AddJobOrder: "Aggiungi Commessa:",
        Name: "Nome",
        Delete: "Elimina",
        Add: "Aggiungi",
        ShowDescription: "Mostra Descrizione estesa",
        TreatmentInfo: "Dati Trattamento",
        DescriptionPlaceholder: "Inserisci una descrizione...",
        TitlePlaceholder: "Inserisci un titolo...",
        LocationPlaceholder: "Inserisci una posizione...",
        ShowHideResources: "Mostra/Nascondi risorse",
        ShowHideDetail: "Mostra/Nascondi dettagio",
        EnableEdit: "Abilita Modifica",
        SelectAResource: "Seleziona una risorsa...",
        Reset: "Reimposta",
        RelatedJobOrders: "Commesse Correlate",
        TreatmentsTable: "Tabella Trattamenti",
        Search: "Ricerca:",
        RelatedResources: "Risorse Correlate",
        Detail: "Dettaglio",
        IsSensitiveData: "Dati Sensibili",
        ResetSearchFields: "Reimposta campi ricerca",
        Save: "Salva",
        Cancel: "Annulla",
        Events: "Eventi",
        ContainsSensitiveData: "Il Trattamento riguarda anche dati sensibili",
        EventType: "Tipo di Evento",
        EventTypes: "J.010 Tipi di Evento",
        EventTypePlaceholder: "Seleziona un tipo di evento...",
        Date: "Data",
        Id: "Id",
        TreatmentType: "Tipo di Trattamento",
        TreatmentTypePersonalData: "Dati Personali",
        TreatmentTypeSensitiveData: "Dati Sensibili",
        TreatmentTypeOther: "Altro",
        LogicEventType: "Tipo Logico",
        LogicEventTypeOpen: "Inizio Gestione Dati",
        LogicEventTypeClose: "Fine Gestione Dati",
        LogicEventTypeRemoveResources: "Rimozione Risorse",
        LogicEventTypeDescriptive: "Descrittivo",
        TreatmentInUseAlert:
            "Questo tipo di trattamento è attualmente in uso e non è possibile cambiarne la descrizione.",
        EventInUseAlert: "Questo tipo di evento è attualmente in uso e non è possibile cambiarne il tipo logico.",
        TreatmentTypeIsDeletedAlert:
            "Questo tipo di trattamento verrà eliminato al salvataggio, ma può ancora essere ripristinato.",
        EventTypeIsDeletedAlert:
            "Questo tipo di evento verrà eliminato al salvataggio, ma può ancora essere ripristinato.",
        Restore: "Ripristina",
        ErrorDateOpenClose:
            "Errore nella sequenza di apertura/chiusura trattamento. Controllare le date degli eventi e il loro tipo.",
        ErrorNoLogicType: "Inserisci un tipo logico",
        Notes: "Note",
        ErrorResourceAlreadyInList: "Risorsa già presente nell'evento.",
        ErrorLessThanFourLogicTypes: "È necessario inserire almeno un tipo di evento per ciascuna tipologia logica.",
        Created: "Creato il",
        LastEdited: "Ultima modifica il",
        From: "Da",
    },
    Tickets: {
        ApplicationName: "Ticket",
        Ticket: "I. Ticket",
        ApplicationCode: "Tickets",
        Category: "Categoria",
        Categories: "I.010 Categorie",
        Origin: "Origine",
        Origins: "I.020 Origini",
        ClosingType: "Tipo di Chiusura",
        ClosingTypes: "I.030 Tipi di Chiusura",
        Author: "Autore",
        ErrorNoDescription: "Inserisci una descrizione",
        SelectAResource: "Seleziona una risorsa...",
        AddAResource: "Aggiungi una risorsa...",
        SelectACategory: "Seleziona una categoria...",
        SelectAnOrigin: "Seleziona un'origine...",
        SelectAJobOrder: "Seleziona una commessa",
        SelectAWorkflow: "Seleziona un piano",
        RelatedResources: "Risorse Correlate",
        DeleteItemMessage: "Sei sicuro di voler eliminare questo elemento?",
        ConfirmDeleteItem: "Elimina",
        CancelDeleteItem: "Mantieni",
        ErrorNoAuthor: "Inserisci un autore.",
        ErrorNoDate: "Inserisci una data.",
        ErrorZeroCategories: "Inserisci almeno una categoria.",
        ErrorZeroClosingTypes: "Inserisci almeno un tipo di chiusura.",
        ErrorZeroOrigins: "Inserisci almeno un'origine.",
        ErrorResourceAlreadyInList: "Risorsa già presente nell'elenco.",
        UpdateSuccess: "Modifica avvenuta con successo.",
        AddExistingSuccess: "Aggiunto alla lista delle categorie",
        AddExistingOriginSuccess: "Aggiunto alla lista delle origini",
        TicketSavedSuccessfully: "Ticket salvato con successo.",
        Date: "Data",
        Add: "Aggiungi",
        Delete: "Elimina",
        Save: "Salva",
        Notes: "Note",
        TitlePlaceholder: "Inserisci un titolo...",
        CategoryIsDeletedAlert: "Questa categoria verrà eliminata al salvataggio, ma può ancora essere ripristinata.",
        ClosingTypeIsDeletedAlert: "Questo tipo verrà eliminato al salvataggio, ma può ancora essere ripristinato.",
        OriginIsDeletedAlert: "Questa origine verrà eliminata al salvataggio, ma può ancora essere ripristinata.",
        AddNewTicket: "Nuovo Ticket",
        Open: "Aperto",
        Closed: "Chiuso",
        TicketsSearchResults: "Risultati Ricerca Ticket",
        ShowHideDetail: "Mostra/Nascondi Descrizione",
        Title: "Titolo",
        Description: "Descrizione",
        State: "Stato",
        CreationDate: "Data Creazione",
        CreatedBy: "Creato Da",
        LastEditDate: "Data Ultima Modifica",
        EditedBy: "Modificato Da",
        Actions: "Azioni",
        Detail: "Dettaglio",
    },
    Quotation: {
        ApplicationName: "Preventivazione",
        NewQuotation: "Nuovo",
    },
};
