import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 11/11/2016
 * Time: 14:46
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { ITodoListFolder, ITodoListActivityFolder } from "../../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListService } from "../../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../../../../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { IControlsEntityProvider } from "../../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../../../Core/Deferred";

export class CreateOrEditFolderDialog implements IDialog {
    templateName:string = "activitiesTreeFolderDialog";
    templateUrl:string = "joborder/templates/joborderdetail";
    title:string;
    modal: {
        close: (result?: any) => void;
    };

    public Title : ko.Observable<string> = ko.observable();
    public Duration : ko.Observable<number> = ko.observable(0);
    public StateFolder : ko.Observable<any> = ko.observable(-1);
    public Priority : ko.Observable<number> = ko.observable(1);
    public Description : ko.Observable<string> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public DueDate : ko.Observable<Date> = ko.observable();
    public ProgressAvg : ko.Observable<number> = ko.observable(0);
    public RelatedWorkflow : ko.Observable<number> = ko.observable();
    public InQuality : ko.Observable<boolean> = ko.observable(false);

    public Folders : ITodoListFolder[];
    public WorkflowProvider : IControlsEntityProvider;

    private ajaxService : IAjaxService;
    private todoListService : ITodoListService;
    private dialogsService : IDialogsService;
    private infoToastService : IInfoToastService;

    private jobOrderId : number;
    private parentId : number;
    private folder : ITodoListActivityFolder;

    constructor(private serviceLocator : IServiceLocator) {
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);

        this.WorkflowProvider = this.todoListService.GetWorkflowsEntitiesProvider();
        this.Folders = this.todoListService.GetTasksFolders().filter((f : ITodoListFolder) => { return !f.IsAggregateFolder; });

        this.StateFolder(this.Folders[0]);
    }

    close():void {
        this.modal.close(null);
    }

    action():void {
        if(!this.Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.JobOrder.PleaseInsertTitleBeforeSaving);
            return;
        }

        this.ajaxService.Post("TodoList-api", "ActivityTree/CreateOrUpdateFolder", {
            methodData: {
                jobOrderId: this.jobOrderId,
                parentId: this.parentId,
                folder: this.getData()
            }
        }).then((folderId : number) => {
            this.modal.close(folderId);
        });
    }

    private load(folder : ITodoListActivityFolder) {
        this.folder = folder;
        this.Title(folder.Title);
        this.Description(folder.Description);
        this.StartDate(folder.StartDate);
        this.DueDate(folder.DueDate);
        this.Duration(folder.Duration);
        this.InQuality(folder.InQuality);
        this.Priority(folder.Priority);
        this.ProgressAvg(folder.ProgressAvg);
        var matches = this.Folders.filter((f : ITodoListFolder) => { return f.Id == folder.Status; });
        this.StateFolder(matches.length > 0 ? matches[0] : this.Folders[0]);
        this.RelatedWorkflow(folder.OriginatingWorkflow);
    }

    private getData() : ITodoListActivityFolder {
        var data = <ITodoListActivityFolder> $.extend({}, this.folder);
        data.Title = this.Title();
        data.Description = this.Description();
        data.StartDate = this.StartDate();
        data.DueDate = this.DueDate();
        data.Duration = this.Duration();
        data.InQuality = this.InQuality();
        data.Priority = this.Priority();
        data.ProgressAvg = this.ProgressAvg();
        data.Status = (<ITodoListFolder>this.StateFolder()).Id;
        data.OriginatingWorkflow = parseInt(<any>this.RelatedWorkflow());

        return data;
    }

    public ShowCreateDialog(jobOrderId : number, parentId : number) : Promise<number> {
        this.title = ProlifeSdk.TextResources.JobOrder.CreateNewFolder;
        (<any>this.WorkflowProvider).SetJobOrder(jobOrderId);

        this.jobOrderId = jobOrderId;
        this.parentId = parentId;

        return this.dialogsService.ShowModal<number>(this, "", {}, this.templateUrl, this.templateName);
    }

    public ShowEditDialog(jobOrderId : number, parentId : number, folderId : number) : Promise<number> {
        this.title = ProlifeSdk.TextResources.JobOrder.CreateNewFolder;
        (<any>this.WorkflowProvider).SetJobOrder(jobOrderId);

        this.jobOrderId = jobOrderId;
        this.parentId = parentId;

        var def = new Deferred<number>();

        this.ajaxService.Post("TodoList-api", "ActivityTree/GetActivityFolder", {
            methodData: {
                folderId: folderId
            }
        }).then((folder : ITodoListActivityFolder) => {
            this.load(folder);
            this.dialogsService.ShowModal<number>(this, "", {}, this.templateUrl, this.templateName)
                .then((result : number) => {
                    def.resolve(result);
                })
                .catch(() => {
                    def.reject();
                })
        });

        return def.promise();
    }
}