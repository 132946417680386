import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 13/06/2018
 * Time: 18:04
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITrustAuthorizationProcessService } from "../../../TrustAuthorizationProcessService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationForHistory, IDocumentForTrustAuthorizationsHistory } from "../../../../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";

interface IDocumentForAuthorizationsHistoryViewModel {
    Authorizations: ko.ObservableArray<IAuthorizationForHistory>;
    Collapsed: ko.Observable<boolean>;
    loadAuthorizations(): void;
}

interface IRequestStatesMap {
    [state: string]: string;
}

export class TrustsAuthorizationsHistoryDialog implements IDialog {
    public templateName: string = "trusts-authorizations-history-dialog";
    public templateUrl: string = "invoices/templates/customertrust";
    public title: string = ProlifeSdk.TextResources.Invoices.TrustsAuthorizationsHistoryDialogTitle;

    public modal: { close: (result?: any) => void; };

    public Documents: ko.ObservableArray<IDocumentForAuthorizationsHistoryViewModel> = ko.observableArray([]);
    public EmptyHistory: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    private isLoading: boolean = false;

    constructor() {
        this.EmptyHistory = ko.computed(() => {
            return this.Documents().length == 0;
        });

        this.loadHistory();
    }

    public close(): void {
        this.modal.close(null);
    }

    public action(): void {
        this.close();
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "large", { noPrompt: true });
    }

    public loadNextBlock(): void {
        if (this.isLoading)
            return;

        this.isLoading = true;

        this.loadHistory()
            .then(() => setTimeout(() => { this.isLoading = false; }, 500))
            .catch(() => setTimeout(() => { this.isLoading = false; }, 500));
    }

    private loadHistory(): Promise<void | IDocumentForTrustAuthorizationsHistory[]> {
        return this.trustAuthorizationProcessService.GetPagedDocumentsForTrustAuthorizationsHistory(this.Documents().length, 30)
            .then((documents: IDocumentForTrustAuthorizationsHistory[]) => {
                var docs: IDocumentForAuthorizationsHistoryViewModel[] = this.Documents();
                docs = docs.concat(documents.map((a: IDocumentForTrustAuthorizationsHistory) => this.CreateDocumentViewModel(a)));
                this.Documents(docs);
            });
    }

    private CreateDocumentViewModel(document: IDocumentForTrustAuthorizationsHistory): IDocumentForAuthorizationsHistoryViewModel {
        return new DocumentForAuthorizationsHistoryViewModel(document);
    }
}

class DocumentForAuthorizationsHistoryViewModel implements IDocumentForAuthorizationsHistoryViewModel {
    public Authorizations: ko.ObservableArray<IAuthorizationForHistory> = ko.observableArray([]);
    public Collapsed: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;
    private requestStatesMap: IRequestStatesMap = {};

    constructor(public document: IDocumentForTrustAuthorizationsHistory) {
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestIgnored] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestIgnoredLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestDenied] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestDeniedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestOnEvaluation] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestOnEvaluationLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestAccepted] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAcceptedLabel;

        this.Collapsed(true);

        var collapsedStateChangesInterceptor = ko.computed(() => {
            if (!this.Collapsed())
                this.loadAuthorizations();
        });
    }

    public loadAuthorizations(): void {
        this.trustAuthorizationProcessService.GetAuthorizationsHistoryForDocument(this.document.DocumentId, this.document.DocumentType)
            .then((authorizations: IAuthorizationForHistory[]) => {
                this.Authorizations(authorizations);
            });
    }
}