import * as ko from "knockout";
export class UpperCaseValue {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let obs: ko.Observable<string> = valueAccessor();

        let subscription = obs.subscribe((value) => {
            obs((value || "").toUpperCase());
        });

        let bindings = {
            value: obs
        };

        ko.applyBindingsToNode(element, bindings, undefined);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
        });
    }
}

ko.bindingHandlers["upperCaseValue"] = new UpperCaseValue();