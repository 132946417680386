import * as Core from "../Core";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import {
    ChangePasswordResult,
    IAuthenticationService,
    IAuthenticationServiceConfiguration,
    ISystemMessage,
    JwtTokenInfo,
    LoginResult,
} from "../interfaces/IAuthenticationService";
import { IAjaxService, AjaxOptions, IAjaxServiceNew } from "../interfaces/IAjaxService";
import { IService } from "../interfaces/IService";
import { IAuthenticationServiceObserver } from "../interfaces/IAuthenticationServiceObserver";
import { ICompanyForUser, IUserForClient } from "../interfaces/IPrincipal";
import { LazyImport } from "../DependencyInjection";
import { toPascalCase } from "../utils/NamingConventions";
import { IDialogsService } from "../interfaces/IDialogsService";
import { RenewLoginDialog } from "./RenewLoginDialog";
import { IInfoToastService } from "../interfaces/IInfoToastService";

const JWT_LOCAL_STORAGE_KEY = "AUTH_TOKEN";
declare let Version: string;

class AuthenticationService implements IAuthenticationService {
    private _observers: IAuthenticationServiceObserver[];
    private _currentUser: IUserForClient;

    @LazyImport(nameof<IAjaxServiceNew>())
    private _ajaxServiceNew: IAjaxServiceNew;

    @LazyImport(nameof<IAjaxService>())
    private _ajaxService: IAjaxService;

    @LazyImport(nameof<IDialogsService>())
    private _dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    tokenRefreshLoop: ReturnType<typeof setInterval>;
    systemMessagesPoller: ReturnType<typeof setInterval>;

    private shownSystemMessageIds: number[] = [];
    private renewingRefreshToken = false;

    constructor(
        serviceLocator: IServiceLocator,
        private configuration: IAuthenticationServiceConfiguration = Core.configuration.authenticationConfig
    ) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IAuthenticationService>(), this);

        this._observers = [];
        this._currentUser = null;
    }

    async InitializeService() {
        this.renewingRefreshToken = false;
        this.tokenRefreshLoop = setInterval(() => {
            if (this.renewingRefreshToken) return;
            if (!this.getJwtTokenInfo()) return;

            if (this.isRenewTokenAlmostExpired()) {
                this.renewingRefreshToken = true;
                const renewLogin = new RenewLoginDialog({});
                this._dialogsService.ShowModal(renewLogin).then(() => {
                    this.renewingRefreshToken = false;
                });

                return;
            }

            if (this.isJwtTokenAlmostExpired())
                // Manca 1 minuto o meno alla scadenza del token
                this.renewToken();
        }, 5000);

        if (this.systemMessagesPoller) clearInterval(this.systemMessagesPoller);

        this.systemMessagesPoller = setInterval(() => {
            if (!this.getJwtTokenInfo() || this.renewingRefreshToken) return;

            this._ajaxService
                .Get<ISystemMessage[]>("Desktop-api", "Messages/GetMessages?v=" + Version, { background: true })
                .then((messages) => {
                    messages.forEach((message) => {
                        if (this.shownSystemMessageIds.indexOf(message.SystemMessageId) != -1) return;

                        this.infoToastService.Info(message.Message, ProlifeSdk.TextResources.Desktop.SystemMessage, {
                            tapToDismiss: true,
                            timeOut: 0,
                        });

                        this.shownSystemMessageIds.push(message.SystemMessageId);
                    });
                });
        }, 60000);
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getServiceType(): string {
        return ServiceTypes.Authentication;
    }

    isLoggedIn() {
        const tokenInfo = this.getJwtTokenInfo();
        if (tokenInfo === null) return false;
        return tokenInfo.refreshTokenExpiration.valueOf() > Date.now();
    }

    public async loginByToken() {
        if (this.isLoggedIn()) await this.onLoginSuccessful();
    }

    private isRenewTokenAlmostExpired(): boolean {
        const tokenInfo = this.getJwtTokenInfo();
        if (tokenInfo === null) return true;
        return tokenInfo.refreshTokenExpiration.valueOf() - Date.now().valueOf() < 60 * 60 * 1000; // 1 ora
    }

    public isJwtTokenAlmostExpired(): boolean {
        const tokenInfo = this.getJwtTokenInfo();
        if (tokenInfo === null) return true;
        return tokenInfo.tokenExpiration.valueOf() - Date.now().valueOf() < 60000;
    }

    getToken(): string {
        const tokenInfo = this.getJwtTokenInfo();
        if (tokenInfo === null) return null;
        return tokenInfo.token;
    }

    getJwtTokenInfo(): JwtTokenInfo {
        if (localStorage.getItem(JWT_LOCAL_STORAGE_KEY) === null) return null;
        return JSON.parseWithDate(localStorage.getItem(JWT_LOCAL_STORAGE_KEY));
    }

    setJwtTokenInfo(jwtTokenInfo: JwtTokenInfo) {
        if (jwtTokenInfo === null) {
            localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
            return;
        }
        localStorage.setItem(JWT_LOCAL_STORAGE_KEY, JSON.stringify(jwtTokenInfo));
    }

    async refreshSession(username: string, password: string) {
        const parameters: AjaxOptions = {};
        parameters.methodData = { username: username, password: password };

        try {
            const tokenInfo = await this._ajaxServiceNew.Post<JwtTokenInfo>(
                this.configuration.serviceName,
                this.configuration.loginMethodName,
                parameters
            );
            this.setJwtTokenInfo(tokenInfo);
            return true;
        } catch (e) {
            return false;
        }
    }

    async loginUser(username: string, password: string): Promise<LoginResult> {
        const parameters: AjaxOptions = {};
        parameters.methodData = { username: username, password: password };

        try {
            const tokenInfo = await this._ajaxServiceNew.Post<JwtTokenInfo & { error: string; message: string }>(
                this.configuration.serviceName,
                this.configuration.loginMethodName,
                parameters
            );
            switch (tokenInfo.error) {
                case ProlifeSdk.InactivityPeriodExceeded:
                    this.setJwtTokenInfo(null);
                    return { status: "inactivity-period-exceeded", message: tokenInfo.message };

                case ProlifeSdk.PasswordExpired:
                case ProlifeSdk.PasswordChangeRequired:
                    this.setJwtTokenInfo(null);
                    return { status: "password-expired", message: tokenInfo.message };

                case ProlifeSdk.InvalidCredentials:
                case ProlifeSdk.InvalidCompany:
                    this.setJwtTokenInfo(null);
                    return { status: "error", message: tokenInfo.message };

                case null:
                case undefined:
                    this.setJwtTokenInfo(tokenInfo);
                    this.onLoginSuccessful();
                    return { status: "ok" };

                default:
                    this.setJwtTokenInfo(null);
                    return { status: "error", message: tokenInfo.message };
            }
        } catch (e) {
            this.setJwtTokenInfo(null);
            return { status: "error", message: e.message };
        }
    }

    public async changePassword(
        username: string,
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    ): Promise<ChangePasswordResult> {
        const parameters: AjaxOptions = {};
        parameters.methodData = { username, oldPassword, newPassword, confirmPassword };

        try {
            const result = await this._ajaxServiceNew.Post<{ success: boolean; message?: string }>(
                this.configuration.serviceName,
                this.configuration.changePasswordMethodName,
                parameters
            );
            if (result.success) return { status: "ok" };
            else return { status: "error", message: result.message };
        } catch (e) {
            return {
                status: "error",
                message: "Si è verificato un errore imprevisto durante il cambio password, si prega di ritentare.",
            };
        }
    }

    private async onLoginSuccessful() {
        const data = await this._ajaxServiceNew.Get<IUserForClient>("a/user", "current", {});
        const pascalData = toPascalCase(data);
        this.userLoggedIn(pascalData);
    }

    public async renewToken(): Promise<boolean> {
        try {
            const actualTokenInfo = this.getJwtTokenInfo();

            const newTokenInfo = await this._ajaxServiceNew.Post<Pick<JwtTokenInfo, "token" | "tokenExpiration">>(
                this.configuration.serviceName,
                this.configuration.refreshTokenMethod,
                {
                    methodData: {
                        refreshToken: actualTokenInfo.refreshToken,
                        token: actualTokenInfo.token,
                    },
                    background: true,
                }
            );

            this.setJwtTokenInfo({
                ...actualTokenInfo,
                token: newTokenInfo.token,
                tokenExpiration: newTokenInfo.tokenExpiration,
            });
        } catch (error) {
            this.logoutUser();
            return false;
        }

        return true;
    }

    public async switchCompany(company: ICompanyForUser) {
        try {
            const result = await this._ajaxServiceNew.Post<JwtTokenInfo>("a/login", "switchCompany", {
                methodData: { CompanyGuid: company.CompanyGuid },
            });
            this.setJwtTokenInfo(result);
            window.location.reload();
        } catch {
            this.setJwtTokenInfo(null);
            window.location.reload();
        }
    }

    /*async loginUserBySessionToken(sessionToken: string): Promise<void> {
        const parameters: AjaxOptions = {};
        parameters.methodData = { sessionToken: sessionToken };

        try {
            const userInfo = await this._ajaxService.Post<IUserForClient>(
                this.configuration.serviceName,
                this.configuration.loginBySessionTokenMethodName,
                parameters
            );
            this.userLoggedIn(userInfo);
        } catch (e) {
            this.loginFailed(e.jqXHR, e.textStatus, e.errorThrown, e.options);
        }
    }*/

    logoutUser(): void {
        this.setJwtTokenInfo(null);
        window.location.reload();
        //window.location.href = this.configuration.serviceName + "/" + this.configuration.logoutMethodName;
        /* this._ajaxService.Post(this.configuration.serviceName, this.configuration.logoutMethodName, {})
                .then(this.userLoggedOut.bind(this)); */
    }

    getUser(): IUserForClient {
        return this._currentUser;
    }

    addObserver(observer: IAuthenticationServiceObserver): void {
        this._observers.push(observer);
    }

    removeObserver(observer: IAuthenticationServiceObserver): void {
        const index = this._observers.indexOf(observer);
        if (index == -1) return;
        this._observers = this._observers.splice(index, 1);
    }

    private userLoggedIn(data: IUserForClient) {
        this._currentUser = data;

        for (let i = 0; i < this._observers.length; i++) {
            this._observers[i].userLoggedIn(this._currentUser);
        }
    }
    private userLoggedOut() {
        for (let i = 0; i < this._observers.length; i++) {
            this._observers[i].userLoggedOut();
        }
    }
    private loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any) {
        for (let i = 0; i < this._observers.length; i++) {
            this._observers[i].loginFailed(jqXHR, textStatus, errorThrown, options);
        }
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new AuthenticationService(serviceLocator);
}
