import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IJobOrderBlockMotivation } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { IJobOrderForList } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IFileRepositoryService } from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IFileOrFolder } from "../../../../ProlifeSdk/interfaces/files/IFileOrFolder";

export class JobOrderForActivitiesReport {
    public isSelected: ko.Observable<boolean> = ko.observable(false);
    public selectedMotivationDescription: ko.Computed<string>;
    private selectedMotivation: ko.Observable<any> = ko.observable(null);
    public motivations: IJobOrderBlockMotivation[] = [];

    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    constructor(private jobOrder: IJobOrderForList) {
        this.motivations = [
            <IJobOrderBlockMotivation>{
                Id: -1,
                Description: ProlifeSdk.TextResources.JobOrder.DoNotLock,
                Deleted: false,
            },
        ].concat(this.motivationsSettings.getMotivations());

        this.selectedMotivationDescription = ko.computed(() => {
            return this.selectedMotivation()
                ? this.selectedMotivation().Description
                : ProlifeSdk.TextResources.JobOrder.DoNotLock;
        });
    }

    public setMotivation(m: IJobOrderBlockMotivation) {
        this.selectedMotivation(m);
    }

    public matchWithTextFilter(filter: string) {
        return this.jobOrder.Name && this.jobOrder.Name.toUpperCase().indexOf((filter || "").trim().toUpperCase()) > -1;
    }

    public switchSelection() {
        this.isSelected(!this.isSelected());
    }

    public getId() {
        return this.jobOrder.JobOrderId;
    }

    public getLockMotivationId() {
        return this.selectedMotivation() ? this.selectedMotivation().Id : -1;
    }
}

export class JobOrdersActivitiesReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private fileRepositoryService: IFileRepositoryService;
    private toastService: IInfoToastService;
    private sdkService: IProLifeSdkService;
    private entitiesService: IEntityProviderService;
    private jobOrderService: IJobOrderService;
    private authService: IAuthorizationService;
    private dialogsService: IDialogsService;

    Name: string = ProlifeSdk.TextResources.JobOrder.Activities;
    templateName = "report-list-item";
    templateUrl = "reports/templates";

    detailsTemplateName = "job-orders-activities";
    detailsTemplateUrl = "JobOrder/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    //Filtri
    public jobOrdersTextFilter: ko.Observable<string> = ko.observable().extend({ throttle: 500 });
    private jobOrders: ko.ObservableArray<JobOrderForActivitiesReport> = ko.observableArray([]);
    public filteredJobOrders: ko.Computed<JobOrderForActivitiesReport[]>;
    public selectedJobOrders: ko.Computed<JobOrderForActivitiesReport[]>;

    public userCanLockJobOrder: ko.Observable<boolean> = ko.observable(false);

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(groupId, 6);
        this.fileRepositoryService = <IFileRepositoryService>(
            serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.authService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);

        this.userCanLockJobOrder(this.authService.isAuthorized("JobOrders_CanLockOrUnlockJobOrders"));

        this.filteredJobOrders = ko.computed(() => {
            return this.jobOrders().filter((j) => {
                return j.matchWithTextFilter(this.jobOrdersTextFilter());
            });
        });

        this.selectedJobOrders = ko.computed(() => {
            return this.jobOrders().filter((j) => {
                return j.isSelected();
            });
        });
    }

    public ImportExcel() {
        const dialog = new JobOrderActivitiesImportDialog();
        this.dialogsService
            .ShowModal<boolean>(dialog, "", null, dialog.templateUrl, dialog.templateName)
            .then((result: boolean) => {
                if (result) this.loadJobOrders();
            });
    }

    public ExportAsExcel() {
        if (this.jobOrders().length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.JobOrder.SelectAnOrder);
            return;
        }

        this.fileRepositoryService
            .openAsDialog("/" + ProlifeSdk.TextResources.JobOrder.ReportModelsURL)
            .then((selectedFiles: IFileOrFolder[]) => {
                if (selectedFiles.length == 0) return;

                const template = selectedFiles[0];

                const url =
                    "JobOrder/ActivitiesReport/GenerateExcel?templateId=" +
                    template.Id +
                    "&jobOrders=" +
                    this.selectedJobOrders()
                        .map((j) => {
                            return j.getId();
                        })
                        .join("_") +
                    "&lockMotivations=" +
                    this.selectedJobOrders()
                        .map((j) => {
                            return j.getLockMotivationId();
                        })
                        .join("_");

                this.ajaxService.DownloadFileFromUrl(url, {});
            });
    }

    initialize() {
        //super.initialize();
        this.loadJobOrders();
    }

    loadJobOrders() {
        this.jobOrderService.GetJobOrdersList(-1, -1, -1, true, "", -1).then((jobOrders: IJobOrderForList[]) => {
            this.jobOrders(
                jobOrders.map((j) => {
                    return new JobOrderForActivitiesReport(j);
                })
            );
        });
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}

export class JobOrderActivitiesImportDialog implements IDialog {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    templateName = "job-order-activities-import-dialog";
    templateUrl = "joborder/templates/reports";
    title: string = ProlifeSdk.TextResources.JobOrder.ImportActivities;

    public selectedFiles: ko.ObservableArray<File> = ko.observableArray();

    public modal: { close: (result?: any) => void };

    constructor() {}

    dispose() {}

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        if (this.selectedFiles().length == 0) {
            this.close();
            return;
        }

        this.uploadFile()
            .then((r: IJobOrderActivitiesImportResult) => {
                const dialog = new JobOrderActivitiesImportResultDialog(r);
                setTimeout(() => {
                    this.dialogsService
                        .ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName)
                        .finally(() => {
                            this.modal.close(true);
                        });
                }, 1000);
            })
            .catch(() => {
                this.close();
            });
    }

    public setValues(item: any) {}

    public clearAndShow() {
        this.selectedFiles([]);
        this.dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
    }

    public uploadFile(): Promise<IJobOrderActivitiesImportResult> {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.JobOrder.Importing);
        const files = this.selectedFiles();
        const data = {};
        for (let i = 0; i < files.length; i++) {
            data["activities" + i] = files[i];
        }

        return this.ajaxService
            .Upload<IJobOrderActivitiesImportResult>("jobOrder-api", "ActivitiesImport", data)
            .finally(() => {
                this.desktopService.UnblockPageUI();
            });
    }
}

export interface IJobOrderActivitiesImportResult {
    Errors: string[];
    ImportedJobOrders: IJobOrderAfterActivitiesImport[];
}

export interface IJobOrderAfterActivitiesImport {
    Id: number;
    Title: string;
    Locked: boolean;
    LockMotivationId?: number;
    LockedBy?: number;
}

export class ImportedJobOrder {
    public selectedMotivationDescription: ko.Computed<string>;
    private selectedMotivation: ko.Observable<any> = ko.observable(null);
    public motivations: IJobOrderBlockMotivation[] = [];

    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    constructor(private jobOrder: IJobOrderAfterActivitiesImport) {
        this.motivations = [
            <IJobOrderBlockMotivation>{
                Id: -1,
                Description: ProlifeSdk.TextResources.JobOrder.Unblocked,
                Deleted: false,
            },
        ].concat(this.motivationsSettings.getMotivations());

        this.selectedMotivationDescription = ko.computed(() => {
            return this.selectedMotivation()
                ? this.selectedMotivation().Description
                : ProlifeSdk.TextResources.JobOrder.Unblocked;
        });

        const selectedMotivationMatches = this.motivations.filter((m: IJobOrderBlockMotivation) => {
            return m.Id == jobOrder.LockMotivationId;
        });
        this.selectedMotivation(
            selectedMotivationMatches.length > 0 ? selectedMotivationMatches[0] : this.motivations[0]
        );
    }

    public setMotivation(m: IJobOrderBlockMotivation) {
        this.selectedMotivation(m);
    }

    public getId() {
        return this.jobOrder.Id;
    }

    public getLockMotivationId() {
        return this.selectedMotivation() ? this.selectedMotivation().Id : -1;
    }
}

export class JobOrderActivitiesImportResultDialog implements IDialog {
    templateName = "job-order-activities-import-result-dialog";
    templateUrl = "joborder/templates/reports";
    title: string = ProlifeSdk.TextResources.JobOrder.ActivityImportReport;

    public modal: { close: (result?: any) => void };

    public jobOrders: ImportedJobOrder[] = [];

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;

    constructor(private result: IJobOrderActivitiesImportResult) {
        this.jobOrders = result.ImportedJobOrders.map((j: IJobOrderAfterActivitiesImport) => {
            return new ImportedJobOrder(j);
        });
    }

    dispose() {}

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        this.jobOrdersService
            .SetLockStatusForJobOrders(
                this.jobOrders.map((m: ImportedJobOrder) => {
                    return {
                        JobOrderId: m.getId(),
                        Locked: m.getLockMotivationId() > 0,
                        BlockCause: m.getLockMotivationId() > 0 ? m.getLockMotivationId() : null,
                    };
                })
            )
            .then(() => {
                this.modal.close();
            });
    }
}
