import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IWorkflowCategory } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowCategoriesSettingsManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export type WorkflowCategoryDataSourceModel = IDataSourceModel<number, IWorkflowCategory>;

export class WorkflowCategoriesDataSource extends BaseDataSource<WorkflowCategoryDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.WorkflowCategoriesType)
    private workflowCategories: IWorkflowCategoriesSettingsManager;

    getTitle(currentModel: WorkflowCategoryDataSourceModel): string {
        return TextResources.Todolist.WorkflowCategories;
    }

    async getData(
        currentModel: WorkflowCategoryDataSourceModel,
        textFilter: string,
        skip = 0,
        count = 100
    ): Promise<WorkflowCategoryDataSourceModel[]> {
        const categories = await this.workflowCategories.GetCategories();

        return categories
            .filter((c) => TextFiltersUtilities.contains(c.Name, textFilter))
            .slice(skip, count)
            .map(this.createModel, this);
    }

    private createModel(category: IWorkflowCategory): WorkflowCategoryDataSourceModel {
        return {
            id: category.Id,
            title: category.Name,
            isGroup: false,
            isLeaf: true,
            icon: {
                icon: category.Icon,
                background: category.Background,
                foreground: category.Foreground,
            },
            model: category,
        };
    }

    async getById(
        currentModel: WorkflowCategoryDataSourceModel,
        ids: (string | number)[]
    ): Promise<WorkflowCategoryDataSourceModel[]> {
        const categories = await this.workflowCategories.GetCategories();
        return categories.filter((c) => ids.includes(c.Id)).map(this.createModel, this);
    }
}
