import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import TsxForEach from "../../../../Components/ForEach";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IWorkedHoursWithApprovalStateForListHoursApprovalRows } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ApprovalRow } from "./ApprovalRow";

const styleSheet = jss.createStyleSheet({
    approvalStateTableHeader: {
        "& > div": {
            fontWeight: "bold",
            borderBottom: "1px solid #ddd",
            padding: "0px 5px !important"
        }
    }
});
const { classes } = styleSheet.attach();

type ApprovalStateTableProps = {
    approvalRows: ko.ObservableArray<IWorkedHoursWithApprovalStateForListHoursApprovalRows>|ko.Computed<IWorkedHoursWithApprovalStateForListHoursApprovalRows[]>;
}

export function ApprovalStateTable(props: ApprovalStateTableProps) {
    const C = require("./ApprovalStateTable")._ApprovalStateTable as typeof _ApprovalStateTable;
    return <C {...props} />;
}

export class _ApprovalStateTable {
    static defaultProps: Partial<ApprovalStateTableProps> = {
    }

    constructor(private props : ApprovalStateTableProps) {
        
    }
    
    render() {
        return  <>
                    <div className={ComponentUtils.classNames("row", "no-gutters", classes.approvalStateTableHeader)}>
                        <div className="col-md-2">
                            {TextResources.WorkedHours.ApproverNameColumn}
                        </div>
                        <div className="col-md-2">
                            {TextResources.WorkedHours.ApprovalRoleColumn}
                        </div>
                        <div className="col-md-2">
                            {TextResources.WorkedHours.GroupColumn}
                        </div>
                        <div className="col-md-2">
                            {TextResources.WorkedHours.ApprovalOutcomeColumn}
                        </div>
                        <div className="col-md-4">
                            {TextResources.WorkedHours.ApprovalNotesColumn}
                        </div>
                    </div>
                    <TsxForEach data={this.props.approvalRows}>
                        {(row) => <ApprovalRow 
                                    approverId={row.ApproverResourceId}
                                    group={row.ApprovalGroupLabel}
                                    role={row.ApprovalGroupRole}
                                    outcome={row.ApprovalState}
                                    notes={row.ApprovalNotes}
                                />
                        }
                    </TsxForEach>
                </>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ApprovalStateTable);
}