import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { PlannerViewModel } from "./PlannerViewModel";
import { PlannerGraph } from "./PlannerGraph";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { PlannerGanttItem, PlannerGanttItemMarker, PlannerGanttItemInterval, PlannerGanttAllocation } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { UiUtilities } from "../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IPlanningService, IPlanningJobOrder } from "../../../ProlifeSdk/interfaces/planning/IPlanningService";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

interface IDetailedAllocation
{
    WorkflowId : number;
    StartDate : Date;
    EndDate: Date;
    AllocatedWork: number;
}

export class PlanningViewModel implements INavigationMenuObserver
{
    public templateName = 'planningInternal';
    public templateUrl = 'planning/templates';

    public planner : PlannerViewModel;
    public categoriesMenu : INavigationMenu;
    public statesMenu : INavigationMenu;

    public plannerGraph : PlannerGraph;
    public plannerGantt : PlannerGantt;

    //public plannerGraphs : ko.ObservableArray<PlannerGraph> = ko.observableArray();

    private jobOrderService : IJobOrderService;
    private planningService : IPlanningService;

    //public ganttData : ko.ObservableArray<> = ko.observableArray().extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

    public StartDate : ko.Observable<Date> = ko.observable();
    public ViewingStartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public ViewingEndDate : ko.Observable<Date> = ko.observable();
    public JobOrderType : ko.Observable<number> = ko.observable();
    public JobOrderState : ko.Observable<number> = ko.observable();

    constructor(private serviceLocator : IServiceLocator)
    {
        this.jobOrderService = <IJobOrderService> this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.planningService = <IPlanningService> this.serviceLocator.findService(ProlifeSdk.PlanningServiceType);

        this.planner = new PlannerViewModel();
        this.plannerGantt = new PlannerGantt();

        this.categoriesMenu = this.jobOrderService.ui.getTypesNavigationMenu();
        this.categoriesMenu.setSingleSelectionStatus(true, true);
        this.categoriesMenu.addMenuObserver(this);

        this.statesMenu = this.jobOrderService.ui.getStatesNavigationMenu();
        this.statesMenu.setSingleSelectionStatus(true, true);
        this.statesMenu.addMenuObserver(this);

        this.StartDate = this.plannerGantt.StartDate;
        this.EndDate = this.plannerGantt.EndDate;
        this.ViewingStartDate = this.plannerGantt.ViewingStartDate;
        this.ViewingEndDate = this.plannerGantt.ViewingEndDate;

        this.plannerGraph = new PlannerGraph(serviceLocator, this.StartDate, this.EndDate, this.ViewingStartDate, this.ViewingEndDate);

        let interceptor = ko.computed(() => {
            this.refresh();
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });
    }

    public refresh() {
        /* let graphDef = this.planningService.getAvailableWorkPerPeriod()
            .then((availableWork : IAvailableWorkPerPeriodResult) => {
                let availableWorkData = availableWork.AvailableWorkPerPeriod.map(w => {
                    w.StartDate = moment(w.StartDate).toDate();
                    w.EndDate = moment(w.EndDate).toDate();
                    return w;
                });
                let plannedWorkData = availableWork.PlannedHours.map(w => {
                    w.Date = moment(w.Date).toDate();
                    return w;
                });

                let allOUs = [];
                let allOUsMapping = {};

                availableWorkData.map(w => {
                    return {
                        OperationalUnitId: w.OperationalUnitId,
                        OperationalUnitName: w.OperationalUnitName
                    }
                }).forEach(ou => {
                    if(allOUs.indexOf(ou.OperationalUnitId) < 0) {
                        allOUs.push(ou.OperationalUnitId);
                        allOUsMapping[ou.OperationalUnitId] = ou.OperationalUnitName;
                    }
                });

                let graphs = [];
                let updating = false;

                allOUs.forEach(ou => {
                    let newGraph = new PlannerGraph(this.serviceLocator, this.StartDate, this.EndDate, this.ViewingStartDate, this.ViewingEndDate);

                    newGraph.OrganizationalUnitId(ou);
                    newGraph.OrganizationalUnitName(allOUsMapping[ou]);
                    newGraph.AvailableWorkPerPeriod(availableWorkData.filter(w => w.OperationalUnitId == ou));
                    newGraph.PlannedWork(plannedWorkData.filter(w => w.FkOperationalUnit == ou));
                    newGraph.TheoreticalPlannedWork(availableWork.TheoreticalPlannedHours.filter(w => w.FkOperationalUnit == ou));

                    //newGraph.IsSelected.subscribe((selected) => {
                    //    if(updating) return;
                    //    updating = true;
                    //    graphs.forEach(g => g.IsSelected(false));
                    //    newGraph.IsSelected(selected);
                    //   updating = false;

                        //this.computeWorkRequirements(availableWorkData, jobOrdersData);
                    //});

                    graphs.push(newGraph);
                });

                this.plannerGraphs(graphs);
            }); */

        let maxGanttDate: Date = moment().startOf("day").toDate();

        let ganttDef = this.planningService.getJobOrders(this.JobOrderType(), this.JobOrderState(), this.StartDate(), this.EndDate.peek())
            .then((jobOrders : IPlanningJobOrder[]) => {
                let lastJobOrder : PlannerGanttItem = null;
                let lastWorkflow : PlannerGanttItem = null;
                let jobOrderInterval : PlannerGanttItemInterval = null;

                let allJobOrders : PlannerGanttItem[] = [];

                for(let row of jobOrders) {
                    if(lastJobOrder == null || lastJobOrder.Id() != row.JobOrderId) {
                        lastJobOrder = new PlannerGanttItem(this.StartDate, this.plannerGantt);
                        lastJobOrder.Id(row.JobOrderId);
                        lastJobOrder.Type("J");
                        lastJobOrder.Tag(row);
                        lastJobOrder.MultipleIntervals(true);
                        lastJobOrder.Title(row.JobOrderName);
                        lastJobOrder.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.75));
                        lastJobOrder.DisabledColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.5));
                        lastJobOrder.ProgressColor(row.Background);
                        lastJobOrder.SetAction(() => {
                            location.href = this.jobOrderService.getJobOrderUrl(row.JobOrderId);
                        });

                        jobOrderInterval = new PlannerGanttItemInterval(row.FromDate, row.ToDate, "", this.StartDate);
                        jobOrderInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.75));
                        jobOrderInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.75));
                        jobOrderInterval.FontColor(row.Foreground);
                        jobOrderInterval.ShowHistogram(false);
                        lastJobOrder.Intervals.push(jobOrderInterval);

                        allJobOrders.push(lastJobOrder);

                        lastWorkflow = null;
                    }

                    if(row.WorkflowId != null && (lastWorkflow == null || lastWorkflow.Id() != row.WorkflowId)) {
                        lastWorkflow = new PlannerGanttItem(this.StartDate, this.plannerGantt);
                        lastWorkflow.Id(row.WorkflowId);
                        lastWorkflow.Type("W");
                        lastWorkflow.Tag(row);
                        lastWorkflow.Title(row.WorkflowName);
                        lastWorkflow.MultipleIntervals(true);

                        lastJobOrder.Children.push(lastWorkflow);
                    }

                    if(!row.FromDate && row.WorkflowStartDate) {
                        let interval = new PlannerGanttItemInterval(row.WorkflowStartDate, row.WorkflowEndDate, "", this.StartDate);
                        interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0));
                        interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.5));
                        interval.FontColor(row.Foreground);
                        interval.ShowHistogram(false);
                        lastWorkflow.Intervals.push(interval);
                    } else if(row.FromDate) {
                        let interval = new PlannerGanttItemInterval(row.FromDate, row.ToDate, "", this.StartDate);
                        interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.5));
                        interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(row.Background), 0.5));
                        interval.FontColor(row.Foreground);
                        interval.ShowHistogram(false);
                        lastWorkflow.Intervals.push(interval);

                        jobOrderInterval.StartDate(this.minDate(jobOrderInterval.StartDate(), row.FromDate));
                        jobOrderInterval.EndDate(this.maxDate(jobOrderInterval.EndDate(), row.ToDate));
                    }

                    if(row.MilestoneDates) {
                        let dates : string[] = [];
                        if(typeof(row.MilestoneDates) === "string")
                            dates = row.MilestoneDates.split(';');
                        else if(Array.isArray(row.MilestoneDates))
                            dates = row.MilestoneDates;
                        else {
                            dates.push(moment(row.MilestoneDates).format());
                        }

                        for(let date of dates) {
                            let milestoneDate = moment(date).startOf('day').toDate();

                            let marker = new PlannerGanttItemMarker(milestoneDate, this.StartDate);
                            marker.Type("milestone");
                            marker.Id("Milestone");
                            marker.Tag(row);
                            marker.Color("red");

                            lastJobOrder.Markers.push(marker);

                            if(lastWorkflow)
                                lastWorkflow.Markers.push(marker);
                        }
                    }

                    maxGanttDate = (row.ToDate?.valueOf() || 0) > maxGanttDate.valueOf() ? row.ToDate : maxGanttDate;
                }

                this.plannerGantt.Items(allJobOrders);

                const ganttEndDate = this.plannerGantt.EndDate();
                const maxEndDate = moment(this.plannerGantt.StartDate()).add(2, "year").toDate();

                maxGanttDate = moment(maxGanttDate).add(1, "month").toDate();
                maxGanttDate = maxGanttDate.valueOf() < maxEndDate.valueOf() ? maxGanttDate : maxEndDate;

                this.plannerGantt.EndDate(maxGanttDate.valueOf() < ganttEndDate.valueOf() ? ganttEndDate : maxGanttDate);
                

                /*var newJobOrders = jobOrders.map((j) => {
                    var item = new PlannerGanttItem(this.serviceLocator, this.StartDate);
                    item.Id(j.JobOrderId);
                    item.Type("J");
                    item.Tag(j);
                    item.MultipleIntervals(true);

                    item.Title(j.JobOrderName);
                    item.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                    item.DisabledColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.75))
                    item.ProgressColor(j.Background);

                    if(j.StartDate) {
                        var marker = new PlannerGanttItemMarker(j.StartDate, this.StartDate);
                        marker.Id("StartDate");
                        marker.Tag(j);
                        marker.Color(j.Background);
                        marker.Type("arrowRight");
                        item.Markers.push(marker);
                    }
                    if(j.EndDate) {
                        var marker = new PlannerGanttItemMarker(j.EndDate, this.StartDate);
                        marker.Id("EndDate");
                        marker.Tag(j);
                        marker.Color(j.Background);
                        marker.Type("arrowLeft");
                        item.Markers.push(marker);
                    }

                    var intervals = [];

                    if(j.FirstRealAllocationDate && moment(j.LastRealAllocationDate) > this.getToday()) {
                        var realInterval = new PlannerGanttItemInterval(this.maxDate(j.FirstRealAllocationDate, this.getToday()), j.LastRealAllocationDate, j.TeamName, this.StartDate);
                        realInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                        realInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                        realInterval.FontColor(this.GetForegroundColor(j.Background, 0.5, "#FFFFFF"));
                        realInterval.ShowHistogram(false);
                        intervals.push(realInterval);

                        maxGanttDate = Math.max(maxGanttDate, moment(j.LastRealAllocationDate).valueOf());
                    }

                    if(j.FirstTheoreticalAllocationDate && moment(j.LastTheoreticalAllocationDate) > this.getToday()) {
                        var theoreticalInterval = new PlannerGanttItemInterval(this.maxDate(j.FirstTheoreticalAllocationDate, this.getToday()), j.LastTheoreticalAllocationDate, j.TeamName, this.StartDate);
                        theoreticalInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                        theoreticalInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                        theoreticalInterval.FontColor(this.GetForegroundColor(j.Background, 0.5, "#FFFFFF"));
                        theoreticalInterval.Style("no-estimate");
                        theoreticalInterval.ShowHistogram(false);
                        intervals.push(theoreticalInterval);

                        maxGanttDate = Math.max(maxGanttDate, moment(j.LastTheoreticalAllocationDate).valueOf());
                    }

                    item.Intervals(intervals);

                    var jobOrderItem = item;

                    item.Children(j.Workflows.map(w => {
                        var workflowItem = new PlannerGanttItem(this.serviceLocator, this.StartDate);
                        workflowItem.Id(w.WorkflowId);
                        workflowItem.Type("W");
                        workflowItem.Tag(w);
                        workflowItem.Title(w.WorkflowName);
                        workflowItem.MultipleIntervals(true);

                        var intervals = [];

                        if(w.FirstRealAllocationDate && moment(j.LastRealAllocationDate) > this.getToday()) {
                            var realInterval = new PlannerGanttItemInterval(this.maxDate(w.FirstRealAllocationDate, this.getToday()), w.LastRealAllocationDate, w.TeamName, this.StartDate);
                            realInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                            realInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                            realInterval.FontColor(this.GetForegroundColor(j.Background, 0.5, "#FFFFFF"));
                            realInterval.ShowHistogram(false);
                            intervals.push(realInterval);

                            maxGanttDate = Math.max(maxGanttDate, moment(j.LastRealAllocationDate).valueOf());
                        }

                        if(w.FirstTheoreticalAllocationDate && moment(j.LastTheoreticalAllocationDate) > this.getToday()) {
                            var theoreticalInterval = new PlannerGanttItemInterval(this.maxDate(w.FirstTheoreticalAllocationDate, this.getToday()), w.LastTheoreticalAllocationDate, w.TeamName, this.StartDate);
                            theoreticalInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                            theoreticalInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(j.Background), 0.5));
                            theoreticalInterval.FontColor(this.GetForegroundColor(j.Background, 0.5, "#FFFFFF"));
                            theoreticalInterval.Style("no-estimate");
                            theoreticalInterval.ShowHistogram(false);
                            intervals.push(theoreticalInterval);

                            maxGanttDate = Math.max(maxGanttDate, moment(j.LastTheoreticalAllocationDate).valueOf());
                        }

                        workflowItem.Intervals(intervals);

                        return workflowItem;
                    }));

                    return item;
                });

                this.plannerGantt.Items(newJobOrders);*/


            });

        /*$.when(ganttDef, graphDef)
            .then(() => {
                if(!this.plannerGraph.MaxEndDate())
                    return;
                    
                var maxDate = Math.max(maxGanttDate, this.plannerGraph.MaxEndDate().valueOf());
                this.EndDate(moment(maxDate).add('month', 1).toDate());
            })*/
    }

    notifyFilterResultIsChanged(filteredLeafs:INavigationMenuProvider[]) {
    }

    onSelectionChanged(selection:INavigationMenuProvider[], navigator:INavigationMenu) {
        if(navigator == this.categoriesMenu)
        {
            this.JobOrderType(selection.length == 0 ? -1 : selection[0].Id);
        }
        else if(navigator == this.statesMenu)
        {
            this.JobOrderState(selection.length == 0 ? -1 : (<any>selection[0]).state.IdJobOrderStateId);
        }
    }

    private getToday() {
        return moment().startOf('day');
    }

    private maxDate(a : any, b : any) {
        if(!a) return b;
        if(!b) return a;

        let aDate = moment(a).valueOf();
        let bDate = moment(b).valueOf();
        let maxDate = Math.max(aDate, bDate);
        return new Date(maxDate);
    }

    private minDate(a : any, b : any) {
        if(!a) return b;
        if(!b) return a;

        let aDate = moment(a).valueOf();
        let bDate = moment(b).valueOf();
        let maxDate = Math.min(aDate, bDate);
        return new Date(maxDate);
    }

    private hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1
        } : null;
    }

    private rgbToString(rgb : any, overrideAlpha? : number) {
        if(overrideAlpha != undefined || overrideAlpha != null) {
            rgb.a = overrideAlpha;
        }

        return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a +")";
    }

    public GetForegroundColor(color : string, alpha: number, backgroundColor: string) : string {
        let c = this.BlendColor(alpha, color, backgroundColor);
        let percievedBrightness = Math.round(Math.sqrt( 0.299*c.r*c.r + 0.587*c.g*c.g + 0.114*c.b*c.b ));

        if(percievedBrightness <= 128)
            return "#FFFFFF";
        return "#000000";
    }

    public BlendColor(alpha: number, source: string, dest: string): { r: number; g: number; b: number; } {
        let sourceRGB = UiUtilities.HexToRgb(source);
        let destRGB = UiUtilities.HexToRgb(dest);

        return {
            r: Math.round(alpha * (sourceRGB.r - destRGB.r) + destRGB.r),
            g: Math.round(alpha * (sourceRGB.g - destRGB.g) + destRGB.g),
            b: Math.round(alpha * (sourceRGB.b - destRGB.b) + destRGB.b)
        };
    }

    public GetColorWithOpacity(hexColor : string, opacity : number) : string {
        let rgb = UiUtilities.HexToRgb(hexColor);
        return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + opacity + ")";
    }

    /*private computeWorkRequirements(availableWork : IAvailableWorkPerPeriod[], jobOrders : IPlanningJobOrder[]) {
        var allOUs = [];
        availableWork.map(w => w.OperationalUnitId).forEach(ou => {
            if(allOUs.indexOf(ou) < 0)
                allOUs.push(ou);
        });

        var allWorkflows : IPlanningWorkflow[] = [];
        jobOrders.forEach(j => allWorkflows = allWorkflows.concat(j.Workflows));

        var someGraphWasSelected = false;

        allOUs.forEach(ou => {

            var toPlan = allWorkflows.map((w : IPlanningWorkflow) => {

                var estimatedWorkPerUO = (w.EstimatedWorkPerOU || "").split('|');
                var estimatedWorks = estimatedWorkPerUO.map(e => {
                    var splits = e.split(':');
                    return {
                        OrganizationalUnitId: parseInt(splits[0]),
                        EstimatedWork: parseFloat(splits[1])
                    };
                }).filter(ew => ew.OrganizationalUnitId == ou).map(ew => ew.EstimatedWork);
                var estimatedWork = estimatedWorks.length > 0 ? estimatedWorks[0] / w.Speed : 0;

                return {
                    WorkflowId: w.WorkflowId,
                    StartDate: moment(w.StartDate).toDate(),
                    EndDate: moment(w.EndDate).toDate(),
                    EstimatedWork: estimatedWork,
                    TimeToComplete: moment.duration(<any>moment(w.EndDate) - <any>moment(w.StartDate)).asDays(),
                    AllocatedWork: 0,
                    RemainingWork: estimatedWork,
                    RemainingTimeToComplete: moment.duration(<any>moment(w.EndDate) - <any>moment(w.StartDate)).asDays(),
                    ComputedWeight: 0
                };
            });

            toPlan.sort((a,b) => {
                if(a.EndDate > b.EndDate) return -1;
                if(a.EndDate < b.EndDate) return 1;
                return 0;
            });

            var allDates = availableWork
                .filter(w => w.OperationalUnitId == ou)
                .sort((a,b) => {
                    if(a.EndDate > b.EndDate) return -1;
                    if(a.EndDate < b.EndDate) return 1;
                    return 0;
                });

            var allocationSerie = [];
            var detailedAllocation : IDetailedAllocation[] = [];

            allDates.forEach(date => {
                var workflowsActiveToDate = toPlan
                    .filter(w => w.StartDate <= date.StartDate && w.EndDate >= date.StartDate && w.EstimatedWork > 0 && w.RemainingWork > 0)
                    .map((w) => { w.ComputedWeight = (w.RemainingTimeToComplete - 24) == 0 ? Number.POSITIVE_INFINITY : (w.RemainingWork / (w.RemainingTimeToComplete - 1)); return w; })
                    .sort((a,b) => {
                        if(a.ComputedWeight > b.ComputedWeight) return -1;
                        if(a.ComputedWeight < b.ComputedWeight) return 1;
                        return 0;
                    });

                var totalAllocatedWork = 0;
                var workflowsToWork = workflowsActiveToDate.filter(w => w.RemainingWork > 0);

                while(totalAllocatedWork < date.MeanWorkADay && workflowsToWork.length > 0) {
                    var selectedWorkflow = workflowsToWork.shift();
                    var allocatableWork = Math.min(date.MeanWorkADay - totalAllocatedWork, selectedWorkflow.RemainingWork);
                    var requiredWork = selectedWorkflow.RemainingWork / selectedWorkflow.RemainingTimeToComplete;

                    allocatableWork = Math.max(allocatableWork, requiredWork);

                    selectedWorkflow.AllocatedWork += allocatableWork;
                    selectedWorkflow.RemainingWork -= allocatableWork;
                    selectedWorkflow.RemainingTimeToComplete -= 1;

                    totalAllocatedWork += allocatableWork;

                    detailedAllocation.push({
                        WorkflowId: selectedWorkflow.WorkflowId,
                        StartDate: date.StartDate,
                        EndDate: date.EndDate,
                        AllocatedWork: allocatableWork
                    });
                }

                allocationSerie.push([
                    <any>moment(date.StartDate).valueOf(),
                    <any>totalAllocatedWork
                ]);
            });

            allocationSerie.reverse();

            var graphs = this.plannerGraphs().filter(g => g.OrganizationalUnitId() == ou);
            if(graphs.length > 0) {
                graphs[0].setAllocationSerie(allocationSerie);
                if(graphs[0].IsSelected()) {
                    this.setDetailedAllocations(detailedAllocation);
                    someGraphWasSelected = true;
                }
            }
        });

        if(!someGraphWasSelected)
            this.setDetailedAllocations([]);
    }*/

    private setDetailedAllocations(detailedAllocation : IDetailedAllocation[])
    {
        this.plannerGantt.Items().forEach((jobOrders : PlannerGanttItem) => {
            jobOrders.Children().forEach((workflow : PlannerGanttItem) => {
                let workflowId = workflow.Id();
                let allocations = detailedAllocation.filter(a => a.WorkflowId == workflowId);

                workflow.DetailedAllocations(allocations.map(a => {
                    let plannerGanttAllocation = new PlannerGanttAllocation(a.StartDate, a.EndDate, a.AllocatedWork, this.StartDate);
                    plannerGanttAllocation.Color("yellow");
                    return  plannerGanttAllocation;
                }));
            });
        });
    }

    private CreateUniqueIdForReference(reference : any) : string {
        if(reference.TaskId)
            return "T" + reference.TaskId;
        return "W" + reference.WorkflowId;
    }

    private CreateUniqueIdForLink(link : any) : string {
        return (link.Type == ProlifeSdk.JobOrderTaskEntityTypeCode ? "T" : "W") + link.ReferenceId;
    }
}

/*class JobOrderGanttEditingDialog implements IDialog
{
    templateName:string = "jobOrderGanttEditingDialog";
    templateUrl:string = "planning/templates";
    title:string;
    modal: {
        close: (result?: any) => void;
    };

    public Title : ko.Observable<string> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public RequestEndDate : ko.Observable<Date> = ko.observable();
    public CustomerName : ko.Observable<string> = ko.observable();

    public Move : ko.Observable<boolean> = ko.observable(false);
    public MoveAmount : ko.Observable<number> = ko.observable();

    close():void {
        this.modal.close();
    }

    action():void {
        if(!this.Move()) {
            this.item.StartDate(moment(this.StartDate()).startOf('day').toDate());
            this.item.EndDate(moment(this.EndDate()).startOf('day').toDate());
        } else {
            this.item.StartDate(moment(this.StartDate()).add('day', this.MoveAmount()).startOf('day').toDate());
            this.item.EndDate(moment(this.EndDate()).add('day', this.MoveAmount()).startOf('day').toDate());
        }
        this.item.Title(this.Title());

        if(this.RequestEndDate())
            this.jobOrder.RequestEndDate = moment(this.RequestEndDate()).startOf('day').toDate();
        else
            this.jobOrder.RequestEndDate = null;

        this.item.Save(this.Move());
        this.modal.close();
    }

    constructor(serviceLocator : IServiceLocator, private jobOrder : IPlanningJobOrder, private item : PlannerGanttItem.PlannerGanttItem) {
        this.title = String.format(ProlifeSdk.TextResources.Planning.JobOrderEditingDialogTitle, jobOrder.Name);

        this.Title(jobOrder.Name);
        this.StartDate(jobOrder.StartDate);
        this.EndDate(jobOrder.EndDate);
        this.RequestEndDate(jobOrder.RequestEndDate);
        this.CustomerName(jobOrder.FormattedContactName);
    }
}

class WorkflowGanttEditingDialog implements IDialog
{
    templateName:string = "workflowGanttEditingDialog";
    templateUrl:string = "planning/templates";
    title:string;
    modal: {
        close: (result?: any) => void;
    };

    public Title : ko.Observable<string> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();

    public Move : ko.Observable<boolean> = ko.observable(false);
    public MoveAmount : ko.Observable<number> = ko.observable();

    close():void {
        this.modal.close();
    }

    action():void {
        if(!this.Move()) {
            this.item.StartDate(moment(this.StartDate()).startOf('day').toDate());
            this.item.EndDate(moment(this.EndDate()).startOf('day').toDate());
        } else {
            this.item.StartDate(moment(this.StartDate()).add('day', this.MoveAmount()).startOf('day').toDate());
            this.item.EndDate(moment(this.EndDate()).add('day', this.MoveAmount()).startOf('day').toDate());
        }

        this.item.Title(this.Title());

        this.item.Save(this.Move());
        this.modal.close();
    }

    constructor(serviceLocator : IServiceLocator, private workflow : IPlanningWorkflow, private item : PlannerGanttItem.PlannerGanttItem) {
        this.title = String.format(ProlifeSdk.TextResources.Planning.WorkflowEditingDialogTitle, workflow.Title);

        this.Title(workflow.Title);
        this.StartDate(workflow.StartDate);
        this.EndDate(workflow.EndDate);
    }
}*/