import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IServiceOrderCostWithBudget } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { List, ListItem } from "../../../../Components/ListComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import TsxForEach from "../../../../Components/ForEach";
import { NumericText } from "../../../../Components/NumericText";

const styleSheet = jss.createStyleSheet({
    serviceOrdersBudgets: {
        "& .service-order-name": {
            borderBottom: "1px solid #ccc"
        }
    }
});
const { classes } = styleSheet.attach();

type WorkTimeCategoriesRemainingBudgetProps = {
    servicesOrderCost: ko.ObservableArray<IServiceOrderCostWithBudget>;
    hideServiceOrderNameIfOnlyOne?: boolean;
}

export function WorkTimeCategoriesRemainingBudget(props: WorkTimeCategoriesRemainingBudgetProps) {
    const C = require("./WorkTimeCategoriesRemainingBudget")._WorkTimeCategoriesRemainingBudget as typeof _WorkTimeCategoriesRemainingBudget;
    return <C {...props} />;
}

type ServiceOrderBudgets = {
    name: string;
    budgets: ko.ObservableArray<IServiceOrderCostWithBudget>;
}

export class _WorkTimeCategoriesRemainingBudget {
    static defaultProps: Partial<WorkTimeCategoriesRemainingBudgetProps> = {
        hideServiceOrderNameIfOnlyOne: false
    }
    
    private ServiceOrders : ko.Computed<ServiceOrderBudgets[]>;

    constructor(private props : WorkTimeCategoriesRemainingBudgetProps) {
       this.ServiceOrders = ko.computed(() => { 
            const orders = [];
            const ordersMap = this.props.servicesOrderCost().groupBy(c => c.ServiceOrderId);
            
            for (const orderId in ordersMap) {
                const rows = ordersMap[orderId];
                orders.push({
                    name: rows.firstOrDefault()?.ServiceOrderName,
                    budgets: ko.observableArray(rows)
                });
            }
            
            return orders;
        }).extend({ trackArrayChanges: true });
    }

    private workTimeCategoryCostFactory(workTymeCategoryCost: IServiceOrderCostWithBudget): IDataSourceModel<number, IServiceOrderCostWithBudget> {
        return {
            id: workTymeCategoryCost.WorkTimeCategoryId,
            title: workTymeCategoryCost.WorkTimeCategoryName,
            isGroup: false,
            isLeaf: true,
            model: workTymeCategoryCost
            
        };
    }

    renderListItem(item: ListItem<number, IServiceOrderCostWithBudget>){
        return  <div className="flex-container flex-vertical">
                    <div className="flex-container">
                        <div className="flex-1">
                            <h4 data-bind={{ text: item.Title }}></h4>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-1">
                            <span className="bold">{TextResources.WorkedHours.MinAnnual}</span>&nbsp;<NumericText value={item.Model.MinAnnualHoursAmount} format='0,0[.][00]' textIfNoValue={TextResources.ProlifeSdk.NotAvailable} />
                        </div>
                        <div className="flex-1">
                            <span className="bold">{TextResources.WorkedHours.MinAnnualRemaining}</span>&nbsp;<RenderMinValue remainingBudget={item.Model.MinRemainingBudget} totalBudget={item.Model.MinAnnualHoursAmount}></RenderMinValue>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-1">
                            <span className="bold">{TextResources.WorkedHours.MaxAnnual}</span>&nbsp;<NumericText value={item.Model.MaxAnnualHoursAmount} format='0,0[.][00]' textIfNoValue={TextResources.ProlifeSdk.NotAvailable} />
                        </div>
                        <div className="flex-1">
                            <span className="bold">{TextResources.WorkedHours.MaxAnnualRemaining}</span>&nbsp;<RenderMaxValue remainingBudget={item.Model.MaxRemainingBudget} totalBudget={item.Model.MaxAnnualHoursAmount}></RenderMaxValue>
                        </div>
                    </div>
                </div>;
    }
    
    render() {
        return ComponentUtils.bindTo((
            <TsxForEach data={this.ServiceOrders}>
                {(order: ServiceOrderBudgets) => (
                    <div className={ComponentUtils.classNames("row", classes.serviceOrdersBudgets)}>
                        <div className="col-md-12">
                            {!this.props.hideServiceOrderNameIfOnlyOne && <h3 className="service-order-name">{order.name}</h3>}
                            <List
                                dataSource={{ array: order.budgets, factory: this.workTimeCategoryCostFactory }}
                                itemRenderer={(item) => this.renderListItem(item)}
                                containerHeight="flex"
                            />
                        </div>
                    </div>
                )}
            </TsxForEach>
        ), this, "modal");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkTimeCategoriesRemainingBudget);
}


type RenderMinValueProps = {
    totalBudget: number; 
    remainingBudget: number;
    textIfNoValue?: string;
}

export function RenderMinValue(props: RenderMinValueProps) {
    const C = require("./WorkTimeCategoriesRemainingBudget")._RenderMinValue as typeof _RenderMinValue;
    return <C {...props} />;
}

export class _RenderMinValue {
    static defaultProps: Partial<RenderMinValueProps> = {
        textIfNoValue: TextResources.ProlifeSdk.NotAvailable
    }

    private color: string;
    private hasValue: boolean;
    private icon: React.ReactElement;
    
    constructor(private props : RenderMinValueProps) {
        this.color = "font-black";
        this.icon = <></>;

        this.hasValue = this.props.totalBudget !== undefined && this.props.totalBudget !== null;
        
        if (this.props.remainingBudget <= 0) {
            this.color = "font-green";
            this.icon = <i className="fa fa-check font-green"></i>
        }
        
        if (this.props.remainingBudget > 0) {
            this.color = "font-yellow";
            this.icon = <i className="fa fa-warning font-yellow"></i>
        }
    }
    
    render() {
        const min = this;
        return ComponentUtils.bindTo((
            <>
                {this.hasValue &&   <span className={min.color}>
                                        <NumericText value={min.props.remainingBudget} format='0,0[.][00]' />&nbsp;{this.icon}
                                    </span>
                }
                {!this.hasValue && this.props.textIfNoValue}
            </>
        ), this, "min");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(RenderMinValue);
}

type RenderMaxValueProps = {
    totalBudget: number; 
    remainingBudget: number;
    textIfNoValue?: string;
}

export function RenderMaxValue(props: RenderMaxValueProps) {
    const C = require("./WorkTimeCategoriesRemainingBudget")._RenderMaxValue as typeof _RenderMaxValue;
    return <C {...props} />;
}

export class _RenderMaxValue {
    static defaultProps: Partial<RenderMaxValueProps> = {
        textIfNoValue: TextResources.ProlifeSdk.NotAvailable
    }

    private hasValue: boolean;
    private color: string;
    private icon: React.ReactElement;
    
    constructor(private props : RenderMaxValueProps) {
        this.color = "font-black";
        this.icon = <></>;
        
        this.hasValue = this.props.totalBudget !== undefined && this.props.totalBudget !== null;

        if (this.props.remainingBudget >= 0) {
            this.color = "font-green";
            this.icon = <i className="fa fa-check font-green"></i>
        }
        
        if (this.props.remainingBudget < 0 ) {
            this.color = "font-red";
            this.icon = <i className="fa fa-warning font-red"></i>
        }
    }
    
    render() {
        const max = this;
        return ComponentUtils.bindTo((
            <>
                {this.hasValue &&   <span className={max.color}>
                                        <NumericText value={max.props.remainingBudget} format='0,0[.][00]' />&nbsp;{this.icon}
                                    </span>
                }
                {!this.hasValue && this.props.textIfNoValue}
            </>
        ), this, "max");
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(RenderMaxValue);
}