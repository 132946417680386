import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IReportVersion } from "../../interfaces/report/IReport";
import { IDesktopService } from "../../interfaces/desktop/IDesktopService";
import { IReportsService } from "../../interfaces/report/IReportsService";

export class ReportVersionForPrintMenu
{
    Title : string;
    Description : string;
    PdfSupport : boolean;
    ExcelSupport : boolean;

    constructor(private version : IReportVersion, private report : ProLifeReport)
    {
        this.Title = version.Title;
        this.Description = version.Description;
        this.PdfSupport = version.PdfSupport;
        this.ExcelSupport = version.ExcelSupport;
    }

    public ExportAsPdf()
    {
        this.report.ExportAsPdf(this.version.VersionId);
    }

    public ExportAsExcel(version : ReportVersionForPrintMenu)
    {
        this.report.ExportAsExcel(this.version.VersionId);
    }
}

export class ProLifeReport
{
    @LazyImport(nameof<IDesktopService>())
    protected desktopService : IDesktopService;
    @LazyImport(nameof<IReportsService>())
    protected reportsService : IReportsService;

    GroupId : number;
    Id : number;
    PrintTemplatesVersions : ko.ObservableArray<ReportVersionForPrintMenu> = ko.observableArray([]);

    RefreshFilterDataChanged : ko.Observable<boolean> = ko.observable(false);
    IsExportMenuVisible : ko.Observable<boolean> = ko.observable(false);

    constructor(groupId : number, reportId : number)
    {
        this.Id = reportId;
        this.GroupId = groupId;
    }

    public SwitchExportMenuVisibility()
    {
        setTimeout(() => {
            this.IsExportMenuVisible(!this.IsExportMenuVisible());
        },100);
    }

    public Refresh()
    {
        this.InputValidation().then((isInputValid : boolean) => {
            if(!isInputValid)
                return;

            this.desktopService.BlockPageUI(ProlifeSdk.TextResources.ProlifeSdk.Loading);

            this.RefreshReportData().finally(() => {
                this.desktopService.UnblockPageUI();
                this.RefreshFilterDataChanged(false);
            });
        });
    }

    public InputValidation() : Promise<boolean>
    {
        return Promise.resolve<boolean>(true);
    }

    public ExportAsPdf(versionId : number)
    {
        //Implementare nelle derivate
    }

    public ExportAsExcel(versionId : number)
    {
        //Implementare nelle derivate
    }

    public RefreshReportData() : Promise<any>
    {
        //Implementare nelle derivate
        return Promise.resolve<any>(undefined);
    }

    public NotifyFilterChange()
    {
        this.RefreshFilterDataChanged(true);
    }

    public initialize()
    {
        this.reportsService.GetReportVersions(this.GroupId, this.Id).then((versions : IReportVersion[]) => {
            this.PrintTemplatesVersions(versions.map((v : IReportVersion) => { return new ReportVersionForPrintMenu(v, this); }));
        });
    }
}
