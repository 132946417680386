import { DocumentMetadata } from "../../../ProlifeSdk/prolifesdk/documents/default-values/DocumentMetadata";
import { MetadataType } from "../enums/MetadataType";

export interface IDocumentFieldsReaderSource {
    Metadatas: ko.ObservableArray<DocumentMetadata>;
}

export type DataSourceType = "documents" | "documents-metadata";
type ValueGetter = (field: string) => any;

export class DocumentFieldsReader {
    private document: IDocumentFieldsReaderSource;

    private getters: Map<DataSourceType, ValueGetter> = new Map<DataSourceType, ValueGetter>();

    constructor(document: IDocumentFieldsReaderSource) {
        this.document = document;

        this.getters.set("documents", this.getDocumentValue.bind(this));
        this.getters.set("documents-metadata", this.getMetadataValue.bind(this));
    }

    public getValue(dataSource: DataSourceType, field: string): any {
        const getter = this.getters.get(dataSource);

        if (!getter) {
            throw new Error(`Data source '${dataSource}' not found`);
        }

        return getter(field);
    }

    private getDocumentValue(field: string): any {
        if (this.document[field] === undefined) return null;
        if (ko.isSubscribable(this.document[field])) return this.document[field]();
        return this.document[field];
    }

    private getMetadataValue(field: string): any {
        const metadataId = parseInt(field);

        if (isNaN(metadataId)) {
            throw new Error(`Invalid metadata id '${field}'`);
        }

        const metadata = this.document.Metadatas().firstOrDefault((m) => m.MetadataId() === metadataId);
        if (!metadata) return null;

        if (metadata.MetadataValueType() !== MetadataType.List) return metadata.MetadataValue();

        return {
            id: metadata.MetadataValue(),
            label: metadata.MetadataValueLabel(),
        };
    }
}
