import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/07/2017
 * Time: 20:11
 * To change this template use File | Settings | File Templates.
 */

import { Section } from "../Section";
import { QuestionForDetails } from "./QuestionForDetails";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { QuestionnaireDetailsViewModel } from "./QuestionnaireDetailsViewModel";
import { DescriptionPopOver, PopOverData } from "../../popover/DescriptionPopOver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISection } from "../../../../../ProlifeSdk/interfaces/survey/ISection";
import { IQuestion } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";

export class SectionForDetails extends Section {
    public templateName: string = "section-list-element-for-details";
    public templateUrl: string = "survey/templates/questionnaire";

    public PopOverData: PopOverData;
    public PopOverViewModel: DescriptionPopOver;

    public Questions: ko.ObservableArray<QuestionForDetails> = ko.observableArray([]);
    public Selected: ko.Observable<boolean> = ko.observable(false);
    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, section: ISection, private parent: QuestionnaireDetailsViewModel) {
        super(section);
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.PopOverData = new PopOverData(this.Description);
        this.PopOverViewModel = new DescriptionPopOver(this.PopOverData);

        this.surveyService.getSectionQuestions(section.Id)
            .then((questions: IQuestion[]) => {
                if (!questions || questions.length == 0) {
                    return;
                }
                this.Questions(questions.map((question: IQuestion) => {
                    var newQuestion = new QuestionForDetails(this.serviceLocator, question);
                    newQuestion.RegisterSelectionObserver(this.parent);
                    return newQuestion;
                }));
            }).catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
            });
    }

    public Select(): void {
        this.parent.DeselectSections();
        this.Selected(true);
    }
}