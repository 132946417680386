import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { INegativeStockValidationExceptionData } from "../../../Core/interfaces/IException";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../../Components/Layouts";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { ArticleInfo } from "../../../Provisioning/Provisioning/ui/panels/ArticleInfo";
import { RequirementSourceInfo } from "../../../Provisioning/Provisioning/ui/panels/RequirementSourceInfo";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type NegativeStockExceptionViewerProps = {
    exceptionData: INegativeStockValidationExceptionData[];
}


export class NegativeStockExceptionViewerDialog extends DialogComponentBase {
    static defaultProps: Partial<NegativeStockExceptionViewerProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : NegativeStockExceptionViewerProps) {
        super({ actionLabel: TextResources.ProlifeSdk.Confirm, closeLabel: TextResources.ProlifeSdk.Abort, className: "medium" });

        this.title(TextResources.Invoices.NegativeStocksDialogTitle);
    }
    
    close() {
        this.modal.close(false);
    }

    action() {
        this.modal.close(true);
    }

    show(): Promise<boolean> {
        return this.dialogsService.ShowModal(this);
    }
    
    renderBody() {
        return <NegativeStockExceptionViewer {...this.props} />;
    }
}

export function NegativeStockExceptionViewer(props: NegativeStockExceptionViewerProps) {
    const C = require("./NegativeStockExceptionViewer")._NegativeStockExceptionViewer as typeof _NegativeStockExceptionViewer;
    return <C {...props} />;
}

export class _NegativeStockExceptionViewer {
    static defaultProps: Partial<NegativeStockExceptionViewerProps> = {
    }

    constructor(private props : NegativeStockExceptionViewerProps) {
        
    }
    
    render() {
        const art: IDataSourceModel<number, INegativeStockValidationExceptionData> = null;

        return ComponentUtils.bindTo((
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]}>
                <Layout.Grid.Cell column={1} row={1} className="flex-vertical">
                    <h4>{TextResources.Invoices.NegativeStocksAfterSaveMessage}</h4>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={2} className="flex-vertical">
                    <Table 
                        dataSource={{ array: ko.observableArray(this.props.exceptionData), factory: Table.defaultFactory((item: INegativeStockValidationExceptionData) => ({ id: item.ArticleId, title: item.ArticleCode + ' ' + item.ArticleDescription })) }}
                        systemScrollable
                        rowAs="art"
                    >
                        <Column title={TextResources.Invoices.Article}>
                            <ColumnBody>
                                {(row: ITableItem<INegativeStockValidationExceptionData>) => <ArticleInfo code={row.Data.model.ArticleCode} description={row.Data.model.ArticleDescription} />}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.StockAmount}>
                            <ColumnBody>
                                {(row: ITableItem<INegativeStockValidationExceptionData>) => <span data-bind={{ numberText: art.model.StockAmount, format: '0,0.00[0000]' }}></span>}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.Warehouse}>
                            <ColumnBody>
                                {(row: ITableItem<INegativeStockValidationExceptionData>) => <span>{row.Data.model.Warehouse}</span>}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.MovementDate}>
                            <ColumnBody>
                                {(row: ITableItem<INegativeStockValidationExceptionData>) => <span data-bind={{ dateText: art.model.DocumentDate, customDateTimeFormat: 'L LT' }}></span>}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.Document}>
                            <ColumnBody>
                                {(row: ITableItem<INegativeStockValidationExceptionData>) => <RequirementSourceInfo docId={row.Data.model.DocumentId}
                                                                                                                    docNumber={row.Data.model.DocumentNumber}
                                                                                                                    docDate={row.Data.model.DocumentDate}
                                                                                                                    protocolName={row.Data.model.DocumentProtocolName}
                                                                                                                    rowNumber={null}
                                                                                                                    showBottomRow={false}
                                                                                                                     />}
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.Grid.Cell>
            </Layout.Grid>
        ), this, "exceptionViewer");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(NegativeStockExceptionViewer);
}