import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { WarehouseLoadEvent } from "../WarehouseLoadEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class WarehouseLoadEventDetailsEdit extends DocumentEventDetailsEdit
{
    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver,
                event : WarehouseLoadEvent)
    {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "f-icon-doc-text";
        this.title = ProlifeSdk.TextResources.Warehouse.WarehouseLoadExt;
        this.setValues(event);
    }

    public setValues(item : WarehouseLoadEvent) : void
    {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent
    {
        var event : ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.WarehouseEventType_WarehouseLoad;
        return event;
    }
}