import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Portlet } from "../../../Components/Portlet";
import jss from "jss";
import moment = require("moment");
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IHolidaysSettingsManager, IHoliday } from "./HolidaysSettingsManager";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";

const { classes } = jss.createStyleSheet({
    holidayEditor: {
        "& .editor": {
            borderBottom: '1px solid #eee',
            paddingBottom: '5px'
        },

        "& ul": {
            "& > li": {
                listStyle: 'none',
                borderBottom: '1px solid #eee',

                "& > div": {
                    display: 'flex'
                }
            }
        }
    }
}).attach();

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "holidays-editor": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IHolidaysEditorParams {

}

class Holiday {
    Day : ko.Observable<number> = ko.observable();
    Month : ko.Observable<number> = ko.observable();
    Year : ko.Observable<number> = ko.observable();

    IsPermanent : ko.Computed<boolean>;
    Formatted: ko.Computed<string>;

    public static FromDate(date : Date) : Holiday {
        let h = new Holiday();
        h.Day(date.getDate());
        h.Month(date.getMonth());
        h.Year(date.getFullYear());
        return h;
    }

    public static PermanentFromDate(date : Date) : Holiday {
        let h = new Holiday();
        h.Day(date.getDate());
        h.Month(date.getMonth());
        h.Year(-1);
        return h;
    }

    public static FromHoliday(holiday: IHoliday) : Holiday {
        let h = new Holiday();
        h.Year(holiday.year);
        h.Month(holiday.month);
        h.Day(holiday.day);

        return h;
    }

    constructor() {
        this.IsPermanent = ko.computed(() => this.Year() <= 0);
        this.Formatted = ko.computed(() => {
            if(this.IsPermanent())
                return this.Day() + " " + moment.months(this.Month());
            return moment(new Date(this.Year(), this.Month(), this.Day(), 0, 0, 0, 0)).format("LL");
        });
    }

    public equals(holiday: Holiday) : boolean {
        if(holiday === this) return true;
        if(this.Day() !== holiday.Day()) return false;
        if(this.Month() !== holiday.Month()) return false;
        if(this.Year() !== holiday.Year()) return false;
        return true;
    }

    getData(): IHoliday {
        return {
            year: this.Year(),
            month: this.Month(),
            day: this.Day(),
            IsPermanent: this.IsPermanent()
        };
    }
}

export class HolidaysEditor {
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.HolidaysSettingsType)
    private holidaysSettingsManager: IHolidaysSettingsManager;

    AnnualHolidays: ko.ObservableArray<Holiday> = ko.observableArray();
    AnnualHolidaysEditorVisible: ko.Observable<boolean> = ko.observable();

    PermanentHolidays: ko.ObservableArray<Holiday> = ko.observableArray();
    PermanentHolidaysEditorVisible: ko.Observable<boolean> = ko.observable();

    NewAnnualHolidayDate : ko.Observable<Date> = ko.observable(new Date());
    NewPermanentHolidayDate : ko.Observable<Date> = ko.observable(new Date());

    constructor(params : IHolidaysEditorParams) {
        this.loadHolidays();
    }

    public showPermanentHolidayEditor() {
        this.PermanentHolidaysEditorVisible(!this.PermanentHolidaysEditorVisible());
    }

    public showAnnualHolidayEditor() {
        this.AnnualHolidaysEditorVisible(!this.AnnualHolidaysEditorVisible());
    }

    public addPermanentHoliday() {
        let h = Holiday.PermanentFromDate(this.NewPermanentHolidayDate());

        if(this.PermanentHolidays().any(ph => ph.equals(h))) {
            this.infoToastService.Error("Impossibile aggiungere la festività perchè è già presente una festività alla stessa data");
            return;
        }

        this.PermanentHolidays.push(h);
    }

    public removeAnnualHoliday(holiday: Holiday) {
        this.AnnualHolidays.remove(holiday);
    }

    public removePermanentHoliday(holiday: Holiday) {
        this.PermanentHolidays.remove(holiday);
    }

    public addAnnualHoliday() {
        let h = Holiday.FromDate(this.NewAnnualHolidayDate());

        if(this.AnnualHolidays().any(ah => ah.equals(h))) {
            this.infoToastService.Error("Impossibile aggiungere la festività perchè è già presente una festività alla stessa data");
            return;
        }

        this.AnnualHolidays.push(h);
    }

    public async saveHolidays(): Promise<void> {
        let holidayToSave = this.AnnualHolidays().map(h => h.getData());
        holidayToSave = holidayToSave.concat(this.PermanentHolidays().map(h => h.getData()));

        try {
            await this.holidaysSettingsManager.saveHolidays(holidayToSave);
            this.infoToastService.Success(TextResources.Settings.HolidaysSaved);
        } catch (e) {
            
        }
    }

    public renderPermanentHolidaysActions() {
        let vm = this;

        return  <>
                    <a href="#" class="btn btn-circle btn-default" data-bind={{ click: vm.showPermanentHolidayEditor.bind(vm), css: { 'btn-default': !vm.PermanentHolidaysEditorVisible(), 'btn-warning': vm.PermanentHolidaysEditorVisible } }}><i class="fa fa-pencil"></i> {TextResources.Settings.EditHoliday} </a>
                </>;
    }
    
    public renderAnnualHolidaysActions() {
        let vm = this;
        
        return  <>
                    <a href="#" class="btn btn-circle btn-default" data-bind={{ click: vm.showAnnualHolidayEditor.bind(vm), css: { 'btn-default': !vm.AnnualHolidaysEditorVisible(), 'btn-warning': vm.AnnualHolidaysEditorVisible } }}><i class="fa fa-pencil"></i> {TextResources.Settings.EditHoliday} </a>
                </>;
    }

    render() {
        const vm = this;
        return ComponentUtils.bindTo((
                <div class="flex-container flex-vertical flex-full-height">
                    <div class="flex-container">
                        <div class="flex-1">
                            <h1>{TextResources.Settings.Holidays}</h1>
                        </div>
                        <div class="flex-container flex-child-center">
                            <button class="btn btn-circle btn-primary" data-bind={{ click: vm.saveHolidays }}>
                                <i class="fa fa-floppy-o"></i> {TextResources.ProlifeSdk.Save}
                            </button>
                        </div>
                    </div>
                    <div class="flex-container flex-full-height">
                        <div class="flex-1 flex-container">
                            <Portlet className={classes.holidayEditor} collapsible={false} actions={vm.renderPermanentHolidaysActions} icon="fa fa-calendar-o" portletTitle="Festività Permanenti">
                                <PermanentHolidayEditor></PermanentHolidayEditor>
                                <div data-bind={{ slimScroll: 'flex' }}>
                                    <ul data-bind={{ foreach: { data: vm.PermanentHolidays, as: 'holiday' }}}>
                                        <PermanentHolidayItem></PermanentHolidayItem>
                                    </ul>
                                </div>
                            </Portlet>
                        </div>
                        <div class="flex-1 flex-container">
                            <Portlet className={classes.holidayEditor} collapsible={false} actions={vm.renderAnnualHolidaysActions} icon="fa fa-calendar-o" portletTitle="Festività Annuali">
                                <AnnualHolidayEditor></AnnualHolidayEditor>
                                <ul data-bind={{ foreach: { data: vm.AnnualHolidays, as: 'holiday' }}}>
                                    <AnnualHolidayItem></AnnualHolidayItem>
                                </ul>
                            </Portlet>
                        </div>
                    </div>
                </div>
        ), this, 'vm');
    }

    private async loadHolidays(): Promise<void> {
        let holidays = this.holidaysSettingsManager.getHolidays();

        for (let holiday of holidays) {
            let h = Holiday.FromHoliday(holiday);

            let isPermanent = h.IsPermanent();

            if (isPermanent)
                this.PermanentHolidays.push(h);
            else
                this.AnnualHolidays.push(h);
        }
    }
}

function PermanentHolidayItem() {
    let holiday: Holiday;
    let vm: HolidaysEditor;

    return  <li>
                <div>
                    <div class="flex-1">
                        <h2 data-bind={{ text: holiday.Formatted }}></h2>
                    </div>
                    <div class="flex-container not-important flex-child-center" data-bind={{ visible: vm.PermanentHolidaysEditorVisible }}>
                        <button class="btn btn-sm btn-circle btn-danger" data-bind={{ click: vm.removePermanentHoliday.bind(vm, holiday) }}><i class="fa fa-trash-o"></i> Elimina </button>
                    </div>
                </div>
            </li>;
}

function AnnualHolidayItem() {
    var holiday: Holiday;
    let vm: HolidaysEditor;    

    return  <li>
                <div>
                    <div class="flex-1">
                        <h2 data-bind={{ text: holiday.Formatted }}></h2>
                    </div>
                    <div class="flex-container not-important flex-child-center" data-bind={{ visible: vm.AnnualHolidaysEditorVisible }}>
                        <button class="btn btn-sm btn-circle btn-danger" data-bind={{ click: vm.removeAnnualHoliday.bind(vm, holiday) }}><i class="fa fa-trash-o"></i> Elimina </button>
                    </div>
                </div>
            </li>;
}

function PermanentHolidayEditor() {
    let vm: HolidaysEditor;

    return  <div class="editor" data-bind={{ visible: vm.PermanentHolidaysEditorVisible }}>
                <div class="form-group">
                    <label>Nuova Festività Permanente</label>
                    <input type="text" class="form-control" data-bind={{ datePicker: vm.NewPermanentHolidayDate, customDateTimeFormat: 'DD MMMM' }}></input>
                </div>
                <div class="text-right">
                    <a href="#" class="btn btn-circle btn-primary btn-sm" data-bind={{ click: vm.addPermanentHoliday.bind(vm) }}><i class="fa fa-check"></i> {TextResources.Settings.AddHoliday} </a>
                </div>
            </div>;
}

function AnnualHolidayEditor() {
    let vm: HolidaysEditor;

    return  <div class="editor" data-bind={{ visible: vm.AnnualHolidaysEditorVisible }}>
                <div class="form-group">
                    <label>Nuova Festività Annuale</label>
                    <input type="text" class="form-control" data-bind={{ datePicker: vm.NewAnnualHolidayDate }}></input>
                </div>
                <div class="text-right">
                    <a href="#" class="btn btn-circle btn-primary btn-sm" data-bind={{ click: vm.addAnnualHoliday.bind(vm) }}><i class="fa fa-check"></i> {TextResources.Settings.AddHoliday} </a>
                </div>
            </div>;
}

ko.components.register("holidays-editor", {
    viewModel: {
        createViewModel: (params: IHolidaysEditorParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new HolidaysEditor(params);
            const $component = vm;
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <ko-bind data-bind={{ tsxtemplate: $component }}></ko-bind>
            ]);
            
            return vm;
        },
    },
    template: []
});