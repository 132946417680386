import * as ko from "knockout";
import { ComponentUtils, Param, ComponentParam } from "../Core/utils/ComponentUtils";

export interface IBaseInputParams<T> {
    Value : Param<T>;
    Label : Param<string>;
    Placeholder : Param<string>;
    HelpText : Param<string>;
    ReadOnly : Param<boolean>;
    MaxLength: Param<number>;
    UpperCase: Param<boolean>;
    AddonPosition : Param<string>;
    ValueUpdate : Param<string>;
    ShowEditButton : Param<boolean>;
    SelectOnFocus: Param<boolean>;
    HasFocus: Param<boolean>;
}

export abstract class BaseInput<T> {
    Value : ComponentParam<T>;
    Label : ComponentParam<string>;
    Placeholder : ComponentParam<string>;
    HelpText : ComponentParam<string>;
    ReadOnly : ComponentParam<boolean>;
    MaxLength: ComponentParam<number>;
    UpperCase: ComponentParam<boolean>;
    AddonPosition : ComponentParam<string>;
    ValueUpdate : ComponentParam<string>;
    ShowEditButton : ComponentParam<boolean>;
    SelectOnFocus: ComponentParam<boolean>;
    HasFocus: ComponentParam<boolean>;

    EditValue: ko.Observable<boolean> = ko.observable(false);

    ValueInterceptor : ko.Computed<T|string>;

    constructor(params : IBaseInputParams<T>) {
        this.Value = ComponentUtils.parseParameter(params.Value, null);
        this.Label = ComponentUtils.parseParameter(params.Label, null);
        this.Placeholder = ComponentUtils.parseParameter(params.Placeholder, null);
        this.HelpText = ComponentUtils.parseParameter(params.HelpText, null);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, null);
        this.MaxLength = ComponentUtils.parseParameter(params.MaxLength, -1);
        this.UpperCase = ComponentUtils.parseParameter(params.UpperCase, false);
        this.AddonPosition = ComponentUtils.parseParameter(params.AddonPosition,"right");
        this.ValueUpdate = ComponentUtils.parseParameter(params.ValueUpdate, "change");
        this.ShowEditButton = ComponentUtils.parseParameter(params.ShowEditButton, false);
        this.SelectOnFocus = ComponentUtils.parseParameter(params.SelectOnFocus, false);
        this.HasFocus = ComponentUtils.parseParameter(params.HasFocus, false);
    }

    protected abstract readValue(): string;
    protected abstract writeValue(value : T);

    protected initialize(): void {
        this.ValueInterceptor = ko.computed<T|string>({
            read: this.readValue.bind(this),
            write: this.writeValue.bind(this)
        })
    }
}