import { ServiceTypes } from "../enumerations/ServiceTypes";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { IService } from "../interfaces/IService";
import { IOPAService, IOPAServiceParams } from "../interfaces/IOPAService";

class OPAService implements IOPAService {
    private sammy : Sammy.Application;
    private lastLocation : string = "";
    private lastLocationChanged : string = "";

    private onChangeObservers : ((url:string) => void)[] = [];
    private beforeHandlers : (() => Promise<boolean>)[] = [];

    constructor(serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IOPAService>(), this);

        this.sammy = null;
    }

    public Initialize(callback? : () => void) : void {
        this.sammy = Sammy(callback);
        /*this.sammy.after(() => {
            this.lastLocation = this.sammy.getLocation();
        });*/
        //this.sammy.bind("location-changed", this.handleLocationChanged.bind(this));
        this.sammy.before((context) => {
            const desiredPath = context.path;
            const currentLocation = this.lastLocation;

            if(this.beforeHandlers.length === 0 || currentLocation == this.sammy.getLocation()) {
                this.handleLocationChanged();
                this.lastLocation = this.sammy.getLocation();
                return true;
            }

            const results : Promise<boolean>[] = [];
            for(const handler of this.beforeHandlers) {
                results.push(handler());
            }

            Promise.all(results)
                .then((values) => {
                    if(values.indexOf(false) != -1) {
                        this.sammy.setLocation(currentLocation);
                        this.lastLocation = currentLocation;
                    } else {
                        this.handleLocationChanged();
                        this.sammy.setLocation(desiredPath);
                        this.lastLocation = desiredPath;
                        this.sammy.runRoute('get', desiredPath);
                    }                    
                });

            return false;
        });
        this.handleLocationChanged();
    }

    private handleLocationChanged() {
        const newLocation = this.sammy.getLocation();
        if(this.lastLocationChanged == newLocation) return;

        this.lastLocationChanged = newLocation;
        this.onChangeObservers.forEach(c => c(newLocation));
    }

    public isInitialized() : boolean {
        return this.sammy != null;
    }

    public BeforeLeave(url : string, callback : () => boolean) {
        this.sammy.before({ except: { path: url }}, () => {
            const currentLocation = this.lastLocation;
            if(currentLocation == this.sammy.getLocation())
                return true;

            const context = { verb: 'get', path: currentLocation };
            if(this.sammy.contextMatchesOptions(context, url)) {
                if(!callback()) {
                    this.sammy.setLocation(currentLocation);
                    return false;
                }
            }
            return true;
        });
    }

    public BeforeLeaveAsync(callback: () => Promise<boolean>) {
        this.beforeHandlers.push(callback);
    }

    public RemoveBeforeLeaveAsync(callback: () => Promise<boolean>) {
        this.beforeHandlers.remove(callback);
    }

    public OnChange(callback: (url: string) => void) {
        this.onChangeObservers.push(callback);
        callback(this.lastLocationChanged);
    }

    public OffChange(callback: (url: string) => void) {
        this.onChangeObservers.remove(callback);
    }

    public NavigateTo(url: string): void {
        this.sammy.setLocation(url);
    }

    public Get(url:any, callback: (...params : IOPAServiceParams[]) => void) {
        this.sammy.get(url, callback);
    }

    public Post(url:any, callback:(...params : IOPAServiceParams[]) => void) {
        this.sammy.post(url, callback);
    }

    public getServiceType():string {
        return ServiceTypes.OPA;
    }

    public isOfType(serviceType:string) : boolean {
        return serviceType == this.getServiceType();
    }

    public Run(url?:string) {
        this.sammy.run(url);
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new OPAService(serviceLocator);
}