import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ProjectsControlsEntityProvider } from "./ProjectsControlsEntityProvider";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IProjectsService } from "../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IProject } from "../../../ProlifeSdk/interfaces/projects/IProject";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class ProjectsEntityProvider implements IEntityProvider<number, IProject>
{
	private projectsService : IProjectsService;

	constructor(private serviceLocator : IServiceLocator )
    {
		var entityProviderService : IEntityProviderService= <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
		entityProviderService.registerEntityProvider(this, this.getType());
		this.projectsService = <IProjectsService> serviceLocator.findService(ProlifeSdk.ProjectsServiceType);
	}

    getType() : string
    {
        return ProlifeSdk.ProjectEntityTypeCode;
    }

    getEntityTypeName() : string
    {
        return "Progetto";
    }

    getPkValue(item : IProject) : number
    {
        return item.Id;
    }

    getDisplayName(item : IProject) : string
    {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.projectsService.get(pkValue).then(
                (p : IProject) => {
                    def.resolve(p ? p.Name : "");
                }
            )
            .catch(() => { def.reject(); });

        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IProject[]>
    {
        return this.projectsService.hintSearch(stringFilter);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new ProjectsControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : any) : Promise<IProject>
    {
        return this.projectsService.get(pkValue);
    }
}