import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 15/02/13
 * Time: 13.02
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDDTAppearance, IDDTAppearances } from "../../../../ProlifeSdk/interfaces/invoice/settings/IDDTAppearances";

export interface IDDTAppearanceProvider {
    getData() : IDDTAppearance;
    update(appearance : IDDTAppearance) : void;
}

export class DDTAppearancesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IDDTAppearanceProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private ddtAppearancesManager : IDDTAppearances) {
        this.title = ddtAppearancesManager.getLabel();

        var appearances = ddtAppearancesManager.getDDTAppearances();
        appearances.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(appearance : IDDTAppearance) {
        this.elements.push(new DDTAppearanceViewModel(this, appearance));
    }

    public addNewSetting() {
        var appearanceViewModel = new DDTAppearanceViewModel(this, {
            Descrizione: this.newSettingName()
        });
        this.elements.push(appearanceViewModel);
        this.createOrUpdateEntry(appearanceViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IDDTAppearanceProvider) {
        var appearance = element.getData();
        this.ddtAppearancesManager.createOrUpdate(appearance)
            .then((updatedAppearance) => element.update(updatedAppearance));
    }

    public deleteEntry(element : IDDTAppearanceProvider) {
        this.elements.remove(element);
        var appearance = element.getData();
        this.ddtAppearancesManager.remove(appearance.Id);
    }
}

class DDTAppearanceViewModel implements IDDTAppearanceProvider {
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : DDTAppearancesEditingViewModel, private appearance : IDDTAppearance) {
        this.name(appearance.Descrizione);
        this.name.subscribe(this.onNameChanged.bind(this));
    }

    private onNameChanged(newValue : string) {
        if(!newValue) {
            this.container.deleteEntry(this);
        } else {
            this.container.createOrUpdateEntry(this);
        }
    }

    getData() : IDDTAppearance {
        var appearance : IDDTAppearance = <IDDTAppearance> $.extend({}, this.appearance);
        appearance.Descrizione = this.name();
        return appearance;
    }

    update(appearance : IDDTAppearance) : void {
        this.appearance = appearance;
        this.name(this.appearance.Descrizione);
    }
}