import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 31/08/2017
 * Time: 18:33
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { SurveyWizardViewModel } from "../../wizard/SurveyWizardViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";

export class WizardTestDialog implements IDialog {
    public templateName: string = "wizard-test-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs/";
    public title: string = ProlifeSdk.TextResources.SurveyWizard.WizardTestTitle;
    public modal: { close: (result?: any) => void;} ;

    public Wizard: ko.Observable<any> = ko.observable();

    constructor(private serviceLocator: IServiceLocator, queueId: number) {
        this.Wizard(new SurveyWizardViewModel(this.serviceLocator, queueId, null,ProlifeSdk.WizardTestMode));
    }

    close(): void {
        this.modal.close();
    }

    action(): void {

    }
}