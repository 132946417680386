import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderTypesEditingViewModelUI } from "./ui/JobOrderTypesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IJobOrderType } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderTypesObserver } from "../../interfaces/settings/IJobOrderTypesObserver";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { LazyImport } from "../../../Core/DependencyInjection";
import { JobOrderTypesEditingDialog } from "./ui/JobOrderTypesEditingDialog";

export class JobOrderTypesSettingsManager implements IJobOrderTypesSettingsManager
{
    
    private jobOrderTypes : IJobOrderType[];
    private observers : IJobOrderTypesObserver[] = [];

    @LazyImport(nameof<ISettingsService>())
    private settingsService : ISettingsService;
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.JobOrder.OrdersSettingsGroup);
    }

    getJobOrderTypes() : IJobOrderType[]
    {
        return this.jobOrderTypes || [];
    }

    getJobOrderTypeById(typeId : number) : IJobOrderType {
        var types : IJobOrderType[] = this.jobOrderTypes.filter((t : IJobOrderType) => t.Id == typeId);
        return types[0];
    }

    async load(): Promise<IJobOrderType[]>
    {
        try {
            let types = await this.ajaxService.Get<IJobOrderType[]>("JobOrder-api", "JobOrderType", {});
            this.jobOrderTypes = types;
        } catch(e) {
            console.error(e);
            this.jobOrderTypes = this.jobOrderTypes ?? [];
        }

        return this.jobOrderTypes;
    }

    getName(): string
    {
        return ProlifeSdk.JobOrderType;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.JobOrder.OrderTypes;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) || false;
    }

    getEditingUI(): IView
    {
        return new JobOrderTypesEditingViewModelUI();
    }

    showEditingDialog() {
        const dialog = new JobOrderTypesEditingDialog();
        dialog.show();
    }

    createOrUpdate(jobOrderType : IJobOrderType) : Promise<IJobOrderType>
    {
        if(jobOrderType.Id != null)
        {
            return this.ajaxService.Put<IJobOrderType>("JobOrder-api", "JobOrderType", { methodData: jobOrderType})
                .then(this.onJobOrderTypeChanged.bind(this));
        }

        return this.ajaxService.Post<IJobOrderType>("JobOrder-api", "JobOrderType", { methodData: jobOrderType })
            .then(this.onJobOrderTypeAdded.bind(this));
    }

    remove(id : number) : Promise<void>
    {
        return this.ajaxService.Delete<void>("JobOrder-api", "JobOrderType/" + id, {})
            .then(this.onJobOrderTypeDeleted.bind(this, id));

    }

    async changeOrder(movedId : number, neighbourId : number, before: boolean) : Promise<IJobOrderType> {
        try {
            let type = await this.ajaxService.Post<IJobOrderType>("JobOrder-api/JobOrderType", "UpdateOrder", {
                methodData : {
                    movedItemId : movedId,
                    neighbourId : neighbourId,
                    before : before
                }
            });
    
            this.onJobOrderTypeChanged(type);
    
            return type;
        } catch(e) {
            console.log(e);
        }

        return null;
    }

    getVisibleJobOrderTypes() : IJobOrderType[] {
        //return this.ajaxService.Post("JobOrder-api/JobOrderType", "GetVisibleJobOrderTypes", {});
        return this.jobOrderTypes.filter((jobOrderType: IJobOrderType) => { return !jobOrderType.HideFromLists; });
    }

    private getIndexOf(id : number) : number
    {
        var item = this.jobOrderTypes.filter((t : IJobOrderType) => t.Id == id)[0];
        return this.jobOrderTypes.indexOf(item);
    }

    private onJobOrderTypeChanged(jobOrderType : IJobOrderType)
    {
        var index : number = this.getIndexOf(jobOrderType.Id);
        this.jobOrderTypes[index] = jobOrderType;
        this.observers.forEach((obs) => obs.onJobOrderTypeChanged(jobOrderType));
        return jobOrderType;
    }

    private onJobOrderTypeAdded(jobOrderType : IJobOrderType)
    {
        this.jobOrderTypes.push(jobOrderType);
        this.observers.forEach((obs) => obs.onJobOrderTypeAdded(jobOrderType));
        return jobOrderType;
    }

    private onJobOrderTypeDeleted(id : number)
    {
        var index : number = this.getIndexOf(id);
        this.jobOrderTypes.splice(index, 1);
        this.observers.forEach((obs) => obs.onJobOrderTypeDeleted(id));
        return id;
    }
}
