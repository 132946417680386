import * as ko from "knockout";
import { INavigationMenuComponentActionsGroupItem, INavigationMenuComponentActionsItem, INavigationMenuComponentActionsGroup, INavigationMenuComponentActionFactory } from "./INavigationMenuComponent";

export class NavigationMenuComponentActionsGroup implements INavigationMenuComponentActionsGroupItem {
    public isSeparator: false;
    public isGroup: true;
    public icon : ko.Computed<string>;
    public text : ko.Computed<string>;
    public title : ko.Computed<string>;
    public visible: ko.Computed<boolean>;
    
    public actions: INavigationMenuComponentActionsItem[] = [];

    constructor(public actionInfo: INavigationMenuComponentActionsGroup, private actionsFactory: INavigationMenuComponentActionFactory) {
        this.isSeparator = this.actionInfo.isSeparator;
        this.isGroup = this.actionInfo.isGroup;

        this.icon = ko.computed(() => {
            if(typeof this.actionInfo.icon === 'function')
                return this.actionInfo.icon();
            return this.actionInfo.icon;
        });

        this.visible = ko.computed(() => {
            if(actionInfo.visible === undefined)
                return true;
            return actionInfo.visible();
        });

        this.title = ko.computed(() => {
            if(typeof this.actionInfo.title === 'function')
                return this.actionInfo.title();
            return this.actionInfo.title;
        });

        this.actions = this.actionInfo.actions.map(this.actionsFactory.createActionViewModel, this.actionsFactory);
    }
}