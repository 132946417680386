import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/08/2017
 * Time: 09:15
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelect2Query } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { IQuestionnaireEnvelope } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class QuestionnairesProvider {
    private surveyService: ISurveyService;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(private serviceLocator: IServiceLocator) {
        this.surveyService = <ISurveyService> this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
    }

    public GetQuestionnaires(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.surveyService.getLimitedQuestionnaires(query.term)
                .then((questionnaires: IQuestionnaireEnvelope[]) => {
                    query.callback({
                        results: questionnaires.map((q: IQuestionnaireEnvelope) => {
                            return {
                                id: q.Id,
                                text: q.Title + ' - Ver. ' + q.Version
                            }
                        })
                    });
                })
                .catch(() => {
                    query.callback({
                        results: []
                    });
                });
        }, 500);
    }

    public GetQuestionnaire(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {
        var id = parseInt(<string> $(element).val());
        if (!id)
            return;

        this.surveyService.getQuestionnaireEnvelope(id)
            .then((questionnaire: IQuestionnaireEnvelope) => {
                callback({
                    id: questionnaire.Id,
                    text: questionnaire.Title + ' - Ver. ' + questionnaire.Version
                });
            });
    }
}