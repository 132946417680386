import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 20/06/2017
 * Time: 17:18
 * To change this template use File | Settings | File Templates.
 */

import { IQuestionnaire } from "../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class Questionnaire {
    public Id: number;
    public Version: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();
    public StateDescription: ko.Observable<string> = ko.observable();
    public Locked: ko.Observable<boolean> = ko.observable();
    public CategoryId: ko.Observable<number> = ko.observable();
    public CategoryName: ko.Observable<string> = ko.observable();
    public CategoryDescription: ko.Observable<string> = ko.observable();

    public QuestionnaireIdentifier: ko.Computed<string>;

    constructor(questionnaire: IQuestionnaire) {
        this.Id = questionnaire.Id;
        this.Version(questionnaire.Version);
        this.Title(questionnaire.Title);
        this.Description(questionnaire.Description);
        this.CreationDate(questionnaire.CreationDate);
        this.CreatorId(questionnaire.CreatorId);
        this.ModifyDate(questionnaire.ModifyDate);
        this.ModifierId(questionnaire.ModifierId);
        this.Icon(questionnaire.Icon);
        this.Background(questionnaire.Background);
        this.Foreground(questionnaire.Foreground);
        this.StateId(questionnaire.StateId);
        this.StateDescription(questionnaire.StateDescription);
        this.Locked(questionnaire.Locked);
        this.CategoryId(questionnaire.CategoryId);
        this.CategoryName(questionnaire.CategoryName);
        this.CategoryDescription(questionnaire.CategoryDescription);

        this.QuestionnaireIdentifier = ko.computed(() => {
            return this.Title() + " - Ver. " + this.Version();
        });
    }

    public ToJSON(): IQuestionnaire {
        return <IQuestionnaire> {
            Id: this.Id,
            Version: this.Version(),
            Title: this.Title(),
            Description: this.Description(),
            CreationDate: this.CreationDate(),
            CreatorId: this.CreatorId(),
            ModifyDate: this.ModifyDate(),
            ModifierId: this.ModifierId(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            StateId: this.StateId(),
            StateDescription: this.StateDescription(),
            Locked: this.Locked(),
            CategoryId: this.CategoryId(),
            CategoryName: this.CategoryName(),
            CategoryDescription: this.CategoryDescription()
        };
    }
}