import AjaxService from "./services/AjaxService";
import AuthenticationService from "./services/AuthenticationService";
import LocalizationService from "./services/LocalizationService";
import AuthorizationService from "./services/AuthorizationService";
import OPAService from "./services/OPAService";
import DialogsService from "./services/DialogsService";
import InfoToastService from "./services/InfoToastService";
import FiltersEngine from "./searchEngine/FiltersEngine";
import { IConfig, IServiceFactory } from "./interfaces/IConfig";
import { IAjaxServiceConfiguration } from "./interfaces/IAjaxService";
import { IAuthenticationServiceConfiguration } from "./interfaces/IAuthenticationService";
import { ILocalizationServiceConfiguration } from "./interfaces/ILocalizationService";
import { IAuthorizationServiceConfiguration } from "./interfaces/IAuthorizationService";
import { IDialogsServiceConfiguration } from "./interfaces/IDialogsService";
import { InfoToastOptions } from "./interfaces/IInfoToastService";

export class CoreConfig implements IConfig {
    public ajaxConfig : IAjaxServiceConfiguration;
    public authenticationConfig : IAuthenticationServiceConfiguration;
    public localizationConfig : ILocalizationServiceConfiguration;
    public authorizationConfig : IAuthorizationServiceConfiguration;
    public dialogsConfig : IDialogsServiceConfiguration;
    public infoToastConfig : InfoToastOptions;
    public services : IServiceFactory[];

    constructor()
    {
        this.ajaxConfig = {
            serviceRoot: "/Service/",
            serviceSuffix: "",
            newServiceRoot: "/ServiceNew/",
            jsonp: false,
            defaultLoading: "Caricamento in corso..."
        };

        this.authenticationConfig = {
            serviceName : "a/Login",
            loginMethodName : "Login",
            refreshTokenMethod: "refresh-token",
            changePasswordMethodName: "change-password",
			loginBySessionTokenMethodName: "LoggedUserInfo",
            logoutMethodName : "Logout"
        };

        this.localizationConfig = {
            serviceName : "Login",
            getClientLabelsTranslationsMethodName : "GetClientLabelsTranslations"
        }

        this.authorizationConfig = {
            serviceName : "Desktop-api/Authorizations",
            loadRightsMethodName : ""
        };

        this.dialogsConfig = {
            defaultLocale : "it",
            defaultClose: "Chiudi",
            defaultSave: "Salva",
            defaultLoading: "Caricamento in corso..."
        };

        this.infoToastConfig = {
            tapToDismiss: true,
            toastClass: 'toast',
            containerId: 'toast-container',
            debug: false,
            fadeIn: 300,
            fadeOut: 1000,
            extendedTimeOut: 1000,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            },
            iconClass: 'toast-info',
            positionClass: 'toast-top-right',
            timeOut: 10000, // Set timeOut to 0 to make it sticky
            titleClass: 'toast-title',
            messageClass: 'toast-message'
        };

        this.services = [
            AjaxService,
            LocalizationService,
            AuthenticationService,
            AuthorizationService,
            OPAService,
            DialogsService,
            InfoToastService,
            FiltersEngine
        ];
    }
}