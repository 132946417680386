import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListActivity, EstimateBudgetRow } from "../../tasks-list/TodoListActivity";
import { TaskTransformsDialog } from "../../tasks-list/dialogs/TaskTransformsDialog";
import { TemplatesControlsEntityProvider } from "../../../entity-providers/TemplatesControlsEntityProvider";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoList, ITodoListElementsProvider, IEstimateBudgetRow } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListTemplateTask } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IArticlesService, IArticleTransformComponent, IArticle } from "../../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IBudgetRequestState, IBudgetRequestStatesSettingsManager } from "../../../../../ProlifeSdk/interfaces/todolist/IBudgetRequestStatesSettingsManager";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";

export class TodoListTemplateTask extends TodoListActivity implements IDataSourceListener
{
    public TemplatesProvider : TemplatesControlsEntityProvider;
    
    @LazyImport(nameof<IArticlesService>())
    private articlesService : IArticlesService;
    @LazyImportSettingManager(ProlifeSdk.BudgetRequestCauses)
    private causesManager : IBudgetRequestStatesSettingsManager;

    //Property dell'oggetto
    public TemplateId : ko.Observable<string> = ko.observable("");
    public TemplateIdNumber : ko.Computed<number>;

    public IsMilestone : ko.Observable<boolean> = ko.observable();

    public BudgetRequestCause : ko.Observable<number> = ko.observable();
    public BudgetRequestCauseName : ko.Computed<string>;
    public BudgetRequestCauses : IBudgetRequestState[] = [];
    public EstimatedBudget : ko.Observable<number> = ko.observable();

    public TotalEstimatedArticlesAmount : ko.Computed<number>;
    public TotalEstimatedWorkAmount : ko.Computed<number>;
    public TotalEstimatedPurchasesPrice : ko.Computed<number>;
    public CanEditDuration : ko.Computed<boolean>;
    
    public IsTask: ko.Observable<boolean> = ko.observable(true);

    private calculatingEstimatedBudgetRows = false;

    constructor(todolist : ITodoList,
                task : ITodoListTemplateTask,
                activityProvider : ITodoListElementsProvider)
    {
        super(todolist, task, activityProvider, true);
        
        if (this.canEditEstimatedBudgetForTaskState)
            this.estimatedBudgetRowColSpan -= 1;

        this.TemplatesProvider = new TemplatesControlsEntityProvider();

        this.BudgetRequestCauses = this.causesManager.get(false);

        this.collapsedTemplateName = "todo-list-template-task-collapsed";
        //this.expandedTemplateName = "template-task-details";
        this.expandedTemplateName = "task-details-tab";
        this.editTemplateName = "todo-list-task-update";
        this.editTemplateInnerName = "todo-list-template-task-edit";
        this.templateForListInTaskEditor = "todo-list-template-task-collapsed";

        this.TemplateIdNumber = ko.computed(() => {
            return parseInt(this.TemplateId());
        });

        this.ContainerIdField = this.TemplateIdNumber;

        this.BudgetRequestCauseName = ko.computed(() => {
            const matches : any[] = this.BudgetRequestCauses.filter((t) => { return t.Id == this.BudgetRequestCause(); })
            return matches.length == 0 ? null : matches[0].Description;
        });

        this.Duration.subscribe((duration: number) => {
            if (this.calculatingEstimatedBudgetRows)
                return;

            const estimatedWorkRows = this.EstimatedWorkRows() || [];
            if(estimatedWorkRows.length == 1) {
                const estimatedWorkRow = estimatedWorkRows[0];
                estimatedWorkRow.Amount(duration / this.Multiplier() );
            }
        });

        this.CanEditDuration = ko.computed(() => {
            return (this.EstimatedWorkRows() || []).length <= 1 && this.Multiplier() == 1;
        });

        this.TotalEstimatedWorkAmount = ko.computed(() => {
            this.calculatingEstimatedBudgetRows = true;

            let total  = 0;
            this.EstimatedWorkRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });

            this.Duration(total * this.Multiplier());

            this.calculatingEstimatedBudgetRows = false;
            return total;
        });

        this.TotalEstimatedArticlesAmount = ko.computed(() => {
            let total  = 0;
            this.EstimatedArticlesRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });
            return total;
        });

        this.TotalEstimatedPurchasesPrice = ko.computed(() => {
            let total  = 0;
            this.EstimatedPurchasesRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });
            return total;
        });

        this.LoadFromModel(task, true);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        // 23/20/2019 commentato perché non serve. La stessa cosa viene fatta sul submit del typeahead e non c'è bisogno di questa procedura.
        /*if(sender === this.PurchasesDataSource) {
            let purchaseModel = model as IPurchasesDataSourceModel;

            var newRow : IEstimateBudgetRow = {
                Description : this.NewPurchaseDescription(),
                EntityKeyId: purchaseModel.model.PurchaseTypeId,
                UnitPrice : purchaseModel.model.UnitPrice,
                Amount : 0,
                Price : 0,
                Type : ProlifeSdk.EstimatedPurchaseEntityTypeCode,
                Multiplier: this.Multiplier(),
                Cost: 0,
                UnitCost: 0,
                Discount: 0,
                Markup: 0,
                Formula: null
            };
            this.EstimatedPurchasesRows.push(new EstimateBudgetRow(newRow, null, this));
            this.NewPurchaseDescription(null);

            setTimeout(() => this.NewPurchaseDescription(null), 100);
        }*/
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    async canSelectItem?(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return true;
    }

    public AddNewPurchase(): void {
        if(!this.NewPurchaseDescription())
            return;

        const newRow : IEstimateBudgetRow = this.createEstimatedBudgetRowModel(ProlifeSdk.EstimatedPurchaseEntityTypeCode, this.Multiplier());
        newRow.EntityKeyId = 1; // TODO rivedere;
        newRow.Description = this.NewPurchaseDescription();

        this.EstimatedBudgetRows.push(new EstimateBudgetRow(newRow, null, this));
        this.NewPurchaseDescription(null);
    }

    public LoadFromModel(task : ITodoListTemplateTask, refreshAttachmentsDetailsData? : boolean)
    {
        super.LoadFromModel(task, refreshAttachmentsDetailsData);
        this.TemplateId(task.TemplateId ? task.TemplateId.toString() : "");
        this.BudgetRequestCause(task.BudgetRequestCause);

        this.IsMilestone(task.IsMilestone);

        const estimatedBudgetRows = this.task.EstimatedBudgetRows.map((r : IEstimateBudgetRow) => { return new EstimateBudgetRow(r, null, this); });
        estimatedBudgetRows.sort((a, b) => a.Order() - b.Order());
        this.EstimatedBudgetRows(estimatedBudgetRows);
    }

    public SomeIsChanged() : boolean
    {
        let isChanged : boolean = super.SomeIsChanged();
        isChanged = isChanged || this.task.TemplateId != (isNaN(parseInt(this.TemplateId())) ? null : parseInt(this.TemplateId()));
        isChanged = isChanged || this.task.BudgetRequestCause != this.BudgetRequestCause();

        const estimatedPurchasesRows = this.EstimatedPurchasesRows();
        const estimatedWorkRows = this.EstimatedWorkRows();
        const estimatedArticlesRows = this.EstimatedArticlesRows();

        const allRows: EstimateBudgetRow[] = estimatedPurchasesRows
            .concat(estimatedWorkRows)
            .concat(estimatedArticlesRows);

        const deletedRows = this.task.EstimatedBudgetRows.filter((r : IEstimateBudgetRow) => { allRows.map((r1) => { return r1.row.Id; }).indexOf(r.Id) == -1 });
        isChanged = isChanged || deletedRows.length > 0;
        isChanged = isChanged || allRows.length != this.task.EstimatedBudgetRows.length;
        allRows.forEach((r : EstimateBudgetRow) => {
            isChanged = isChanged || r.SomeIsChanged();
        });

        return isChanged;
    }

    public GetData()
    {
        const model = super.GetData() as ITodoListTemplateTask;
        model.TemplateId = isNaN(parseInt(this.TemplateId())) ? null : parseInt(this.TemplateId());
        model.BudgetRequestCause = this.BudgetRequestCause();

        model.IsMilestone = this.IsMilestone();

        const estimatedWorkRows : IEstimateBudgetRow[] = this.EstimatedWorkRows().map((r : EstimateBudgetRow) => { return r.GetData(this.Multiplier()); });
        const estimatedPurchasesRows : IEstimateBudgetRow[] = this.EstimatedPurchasesRows().map((r : EstimateBudgetRow) => { return r.GetData(this.Multiplier()); });
        const estimatedArticlesRows : IEstimateBudgetRow[] = this.EstimatedArticlesRows().map((r : EstimateBudgetRow) => { return r.GetData(this.Multiplier()); });

        model.EstimatedBudgetRows = estimatedPurchasesRows.concat(estimatedWorkRows).concat(estimatedArticlesRows);

        model.WorkedHoursDefaultRoles = this.WorkedHoursDefaultRoles().map(r => ({ TemplateId: this.Id, RoleId: r.RoleId(), Order: r.Order() }));
        model.WorkedHoursDefaultWorkTimeCategories = this.WorkedHoursDefaultWorkTimeCategories().map(w => ({ TemplateId: this.Id, WorkTimeCategoryId: w.WorkTimeCategoryId(), Order: w.Order() }));

        return model;
    }

    public OpenTransformsDialog(row : EstimateBudgetRow) {
        new TaskTransformsDialog(row.GetData(1))
            .ShowModal()
            .then((newRows : IArticleTransformComponent[]) => {
                
                if(newRows.length == 0)
                    return;
                
                this.RemoveEstimatedBudgetRow(row);

                newRows.forEach(r => {
                    this.articlesService.getArticleByArticleId(r.ComponentArticleId)
                        .then((article : IArticle) => {
                            const budgetRow : IEstimateBudgetRow = this.createEstimatedBudgetRowModel(ProlifeSdk.WarehouseArticleEntityTypeCode, this.Multiplier());
                            budgetRow.EntityKeyId = article.Id;
                            budgetRow.Description = r.ComponentCode + " " + r.ComponentDescription;
                            budgetRow.Amount = r.Amount;
                            this.EstimatedBudgetRows.push(new EstimateBudgetRow(budgetRow, null, this));
                        });
                });
            });
    }
}