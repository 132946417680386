import * as ko from "knockout";
import { CustomerBankViewModel } from "./CustomerBankViewModel";
import { ICustomerBank } from "../../../ProlifeSdk/interfaces/customer/ICustomerBank";
import { IValidation } from "../../../ProlifeSdk/ValidationService";
import { DetectChanges, DetectClassChanges } from "../../../Core/ChangeDetection";
import scrollIntoViewIfNeeded from  "scroll-into-view-if-needed";

@DetectClassChanges
export class CustomerBanksViewModel
{
    public hasBanks : ko.Computed<boolean>;
    @DetectChanges
    public AllBanks : ko.ObservableArray<CustomerBankViewModel> = ko.observableArray([]);

    public isChanged : ko.Observable<number> = ko.observable(0);
    
    private nextFakeId = -1;

    constructor()
    {
        this.hasBanks = ko.computed(() => { return this.AllBanks().length != 0; });
    }

    public load(banks : ICustomerBank[])
    {
        this.AllBanks([]);

        if (!banks) return;
        this.AllBanks(banks.map(this.createViewModelFor.bind(this)));

        this.isChanged(0);
    }

    private createViewModelFor(bank : ICustomerBank) : CustomerBankViewModel
    {
        const bankViewModel = new CustomerBankViewModel(bank);
        bankViewModel.OnBankChanged.Add(() => {
            this.isChanged.valueWillMutate();
            this.isChanged.valueHasMutated();
        });

        return bankViewModel;
    }

    public AddBank(contextViewModel: CustomerBanksViewModel, event: Event)
    {
        const newBank : ICustomerBank = {
            Name: null,
            ABI : null,
            CAB : null,
            IBAN : null,
            Id: this.nextFakeId--
        };


        const bank = this.createViewModelFor(newBank);
        this.AllBanks.push(bank);
        bank.HasFocus(true);

        const element = event.currentTarget as HTMLElement;
        scrollIntoViewIfNeeded(element, {
            scrollMode: 'if-needed',
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          });
    }

    public DeleteBank(bank : CustomerBankViewModel)
    {
        this.AllBanks.remove(bank);
    }

    public getData() : ICustomerBank[]
    {
        return this.AllBanks().map((bank) => bank.getData());
    }

    public GetValidation(): IValidation[] {
        let validation = [];

        const banks = this.AllBanks();

        for (const bank of banks) {
            validation = validation.concat(bank.GetValidation());
        }
        
        return validation;
    }

    public dispose(): void {

    }
}