import * as ko from "knockout";
﻿/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 09/05/2018
 * Time: 17:36
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import {Moment} from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { Attachment } from "../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../../../Users/HumanResourcesService";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ICustomerTrustViewModel } from "../../interfaces/ICustomerTrustViewModel";
import { IAttachmentsManagerPathProvider } from "../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { ITrustsService, ITrustsValidationResult } from "../../../ProlifeSdk/interfaces/customer/ITrustsService";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ICustomerTrust, ITrustAttachment } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { ITrustManager } from "../../interfaces/ITrustManager";
import { Deferred } from "../../../Core/Deferred";

export class CustomerTrustViewModel implements ICustomerTrustViewModel, IAttachmentsManagerPathProvider {
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public Value: ko.Observable<number> = ko.observable();
    public InsuranceTrust: ko.Observable<number> = ko.observable();
    public Note: ko.Observable<string> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable();

    public LastModifierName: ko.Observable<string> = ko.observable();
    public Expanded: ko.Observable<boolean> = ko.observable(false);
    public AutoStartDate: ko.Observable<boolean> = ko.observable(true);
    public AutoEndDate: ko.Observable<boolean> = ko.observable(true);

    public IsChanged: ko.Computed<boolean>;
    public IsActualTrust: ko.Computed<boolean>;
    public HasAttachments: ko.Computed<boolean>;

    public AttachmentsManager: ko.Observable<any> = ko.observable();

    private Id: number = 0;
    private CustomerId: number = 0;
    private lastValue: number = 0;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ITrustsService>())
    private trustsService: ITrustsService;
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesService: IHumanResourcesSettingsManager;

    constructor(private trust: ICustomerTrust, private trustsManager: ITrustManager) {
        this.PopulateTrustInfo(this.trust);

        this.HasAttachments = ko.computed(() => {
            if (!this.AttachmentsManager())
                return false;

            return this.AttachmentsManager().attachments().length > 0;
        });

        this.AttachmentsManager(this.fileRepositoryService.createAttachmentsManager(this));
        this.LoadAttachments();

        this.IsActualTrust = ko.computed(() => {
            var now: Moment = moment();
            return moment(this.StartDate()) <= now && moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()) > now;
        });

        var updating: boolean = false;


        this.AutoStartDate.subscribe((value: boolean) => {
            if (value)
                this.StartDate(moment(this.StartDate()).startOf("day").toDate());
        });

        this.AutoEndDate.subscribe((value: boolean) => {
            if (value)
                this.EndDate(moment(this.EndDate()).endOf("day").toDate());
        });

        this.Value.subscribe((value: number) => {
            if (updating)
                return;
            updating = true;
            this.OnTrustValueUpdate(value)
                .then((result: boolean) => {
                    if (result)
                        this.CommitValueChanges(this.Value());
                })
                .finally(() => {
                    updating = false;
                });
        });

        var lastInsuranceTrustValue = this.InsuranceTrust();
        this.InsuranceTrust.subscribe((value) => {
            if (value < 0) {
                this.InsuranceTrust(lastInsuranceTrustValue);
                return;
            }

            lastInsuranceTrustValue = value;
        });

        var lastStartDate: Date = this.StartDate();
        this.StartDate.subscribe((value: Date) => {
            if (updating)
                return;

            updating = true;

            if (moment(value) > moment(this.EndDate()))
                this.EndDate(value);

            /*this.OnTrustPeriodChanges()
                .then((confirmChanges: boolean) => {
                    if (!confirmChanges) {
                        this.StartDate(lastStartDate);
                        this.EndDate(lastEndDate);
                    }

                    lastStartDate = value;
                    lastEndDate = this.EndDate();
                })
                .finally(() => {
                    updating = false;
                });*/

            lastStartDate = value;
            lastEndDate = this.EndDate();

            updating = false;
        });

        var lastEndDate: Date = this.EndDate();
        this.EndDate.subscribe((value: Date) => {
            if (updating)
                return;

            updating = true;

            if (this.AutoEndDate() && !lastEndDate)
                value = moment(value).endOf("day").toDate();

            if (moment(value) < moment(this.StartDate()))
                this.EndDate(this.StartDate());

            /*this.OnTrustPeriodChanges()
                .then((confirmChanges: boolean) => {
                    if (!confirmChanges) {
                        this.StartDate(lastStartDate);
                        this.EndDate(lastEndDate);
                    }

                    this.EndDate(value);
                    lastStartDate = this.StartDate();
                    lastEndDate = value;
                })
                .finally(() => {
                    updating = false;
                });*/

            this.EndDate(value);
            lastStartDate = this.StartDate();
            lastEndDate = value;
            updating = false;
        });

        this.IsChanged = ko.computed(() => {
            return this.Value() != this.trust.Value
                || moment(this.StartDate()).valueOf() != moment(this.trust.StartDate).valueOf()
                || moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()).valueOf() != moment(!this.trust.EndDate ? <any>'2100-01-01' : this.trust.EndDate).valueOf()
                || this.InsuranceTrust() != this.trust.InsuranceTrust
                || this.Note() != this.trust.Note
                || this.Deleted()
                || !this.Id
                || this.Id <= 0;
        });
    }

    public async getDestinationPathForAttachments(): Promise<string> {
        return "";
    }

    public GetId(): number {
        return this.trust.Id;
    }

    public Intersect(trust: ICustomerTrustViewModel): boolean {
        return (moment(this.StartDate()).valueOf() <= moment(trust.StartDate()).valueOf() && moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()).valueOf() >= moment(trust.StartDate()).valueOf())
            || (moment(trust.StartDate()).valueOf() <= moment(this.StartDate()).valueOf() && moment(!trust.EndDate() ? <any>'2100-01-01' : trust.EndDate()).valueOf() >= moment(this.StartDate()).valueOf())
            || (moment(this.StartDate()).valueOf() <= moment(trust.StartDate()).valueOf() && moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()).valueOf() >= moment(!trust.EndDate() ? <any>'2100-01-01' : trust.EndDate()).valueOf())
            || (moment(trust.StartDate()).valueOf() <= moment(this.StartDate()).valueOf() && moment(!trust.EndDate() ? <any>'2100-01-01' : trust.EndDate()).valueOf() >= moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()).valueOf());
    }

    public Delete(): void {
        //TODO: ci sono restrizioni sull'eliminazione di un fido? Se ho documenti emessi nel periodo che sto eliminando, devo considerarlo come extra-fido?
        this.ConfirmTrustDeletion()
            .then((confirm: boolean) => {
                if (confirm)
                    this.Deleted(true);
            });
    }

    public GetData(): ICustomerTrust {
        return {
            Id: this.Id,
            CustomerId: this.CustomerId,
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            Value: this.Value(),
            CreatorId: this.trust.CreatorId,
            CreationDate: this.trust.CreationDate,
            ModifierId: this.trust.ModifierId,
            ModifyDate: this.trust.ModifyDate,
            InsuranceTrust: this.InsuranceTrust(),
            Note: this.Note(),
            Deleted: this.Deleted(),
            Attachments: this.AttachmentsManager().attachments().map((f: Attachment) => { return { Id: null, TrustId: this.Id, AttachmentGuid: f.id }; })
        };
    }

    public IsExpired(): boolean {
        return moment(!this.EndDate() ? <any>'2100-01-01' : this.EndDate()) < moment();
    }

    private LoadAttachments(): Promise<ITrustAttachment[]> {
        var def = new Deferred<ITrustAttachment[]>();

        this.trustsService.GetTrustAttachments(this.trust.Id)
            .then((attachments: ITrustAttachment[]) => {
                this.AttachmentsManager().loadAttachments(attachments.map(a => a.AttachmentGuid));
            });

        return def.promise();
    }

    private ConfirmTrustDeletion(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Customers.ConfirmTrustDeletionMessage,
            ProlifeSdk.TextResources.Customers.CancelButton,
            ProlifeSdk.TextResources.Customers.ConfirmButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private OnTrustPeriodChanges(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.ValidateTrust()
            .then((result: boolean) => {
                def.resolve(result);
            });

        return def.promise();
    }

    private OnTrustValueUpdate(value: number): Promise<boolean> {
        var def = new Deferred<boolean>();

        if (value < 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Customers.PositiveValueRequired);
            this.AbortValueChanges();
            return def.resolve(false).promise();
        }

        /*this.ValidateTrust()
            .then((result: boolean) => {
                def.resolve(result);
            });*/

        return def.promise();
    }

    private ValidateTrust(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.trustsService.ValidateTrusts([this.GetData()], this.CustomerId)
            .then((validationResult: ITrustsValidationResult) => {
                if (validationResult.Errors.length == 0) {
                    def.resolve(true);
                    return;
                }

                if (!validationResult.TrustsDataAreValid) {
                    this.ShowErrorsAlert(validationResult.Errors)
                        .then(() => {
                            def.resolve(false);
                        });
                    return;
                }

                if (!validationResult.TrustBalanceIsValid) {
                    if (!this.trustsManager.HasExtraTrustApprovingRights()) {
                        this.infoToastService.Warning(ProlifeSdk.TextResources.Customers.TrustValueError);
                        this.AbortValueChanges();
                        def.resolve(false);
                        return;
                    }

                    this.ConfirmNegativeBalance()
                        .then((confirm: boolean) => {
                            if (!confirm) {
                                this.AbortValueChanges();
                                def.resolve(false);
                                return;
                            }
                            def.resolve(true);
                        });
                }
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private ShowErrorsAlert(errors: string[]): Promise<boolean> {
        var def = new Deferred<boolean>();
        var message: string = '';
        errors.forEach((m: string) => { message = message + m + '</br>'; });

        this.dialogsService.Alert(
            message,
            ProlifeSdk.TextResources.Customers.TrustValidationMessageLabel,
            () => {
                def.resolve(false);
            }
        );

        return def.promise();
    }

    private ConfirmNegativeBalance(): Promise<boolean> {
        var def = new Deferred<boolean>();
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Customers.ConfirmNegativeTrustBalanceMessage,
            ProlifeSdk.TextResources.Customers.CancelButton,
            ProlifeSdk.TextResources.Customers.ConfirmButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );
        return def.promise();
    }

    private CommitValueChanges(newTrustValue: number): void {
        this.trustsManager.LoadBalance();
        this.lastValue = newTrustValue;
    }

    private AbortValueChanges(): void {
        this.Value(this.lastValue);
    }

    private PopulateTrustInfo(trust: ICustomerTrust): void {
        this.Id = trust.Id;
        this.CustomerId = trust.CustomerId;
        this.StartDate(trust.StartDate);
        this.EndDate(trust.EndDate);
        this.Value(trust.Value);
        this.InsuranceTrust(trust.InsuranceTrust);
        this.Note(trust.Note);
        this.Deleted(false);

        this.lastValue = trust.Value;

        if (trust.ModifierId > 0) {
            var resource: IHumanResource = this.resourcesService.getHumanResourceById(trust.ModifierId);
            this.LastModifierName(resource.Resource.Name + ' ' + resource.Resource.Surname);
        }
    }
}