import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 27/06/13
 * Time: 17.38
 * To change this template use File | Settings | File Templates.
 */

export class SingleClick {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var handler = valueAccessor()["handler"],
            params = valueAccessor()["params"],
            delay = 200,
            clickTimeout : ReturnType<typeof setTimeout>;

        $(element).click(function() {
            if(clickTimeout)
                clearTimeout(clickTimeout);
            else
            {
                clickTimeout = setTimeout(function()
                {
                    clickTimeout = null;
                    handler(params);
                }, delay);
            }
        });
    }
}

export class DblClk {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        /*$(element).on('dblclick', () => {
            valueAccessor().call(viewModel);
        });*/
        (<any>$(element)).hammer({ touchAction: 'pan-y'}).on('doubletap', () => {
            valueAccessor().call(viewModel);
        });
    }
}

export class DblClickEx {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let callback: () => void = valueAccessor();

        $(element).on("click", (evt: Event) => {
            let mouseEvent = evt as MouseEvent;
            if (mouseEvent.detail > 1) {
                callback.call(viewModel);
            }
        });
    }
}

declare var DocumentTouch;

export class ContextMenu {
    static isTouchEnabled() : boolean {
        if(('ontouchstart' in window) || (<any>window).DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }
        return false;
    }

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var selector = typeof options === "string" ? options : options.selector;
        var dataReceiver = typeof options === "string" ? ko.observable() : options.data;
        var canShow = allBindingsAccessor()["contextMenuCanShow"] || (() => true);

        var $target = $(selector);
        $target.on('click.context.data-api', (e : any) => {
            $target.removeClass("show");
            e.preventDefault();
            e.stopPropagation();
        });
        $('html').on('click.context.data-api', (e) => {
            if(!e.ctrlKey)
                $target.removeClass("show");
        });

        $(element).on('contextmenu', (e : any) => {
            if(!canShow()) {
                e.preventDefault();
                return;
            }

            dataReceiver(bindingContext.$data);

            var position = {
                top: 'auto',
                left: 'auto',
                right: 'auto',
                bottom: 'auto'
            };

            var totalBottom = (e.clientY + 10) + $target.outerHeight();
            var totalRight = (e.clientX + 10) + $target.outerWidth();

            if(totalBottom > $(window).height())
                position.bottom = ($(window).height() - (e.clientY + 10)) + 'px';
            else
                position.top = (e.clientY + 10) + 'px';

            if(totalRight > $(window).width())
                position.right = ($(window).width() - (e.clientX + 10)) + 'px';
            else
                position.left = (e.clientX + 10) + 'px';

            $target.css(position);
            $target.addClass("show");
            e.preventDefault();
        });

        if(ContextMenu.isTouchEnabled()) {
            (<any>$(element)).hammer({ touchAction: 'pan-y'}).on('hold', (e:any) => {
                dataReceiver(bindingContext.$data);

                var position = {
                    top: 'auto',
                    left: 'auto',
                    right: 'auto',
                    bottom: 'auto'
                };

                var totalBottom = (e.clientY + 10) + $target.outerHeight();
                var totalRight = (e.clientX + 10) + $target.outerWidth();

                if (totalBottom > $(window).height())
                    position.bottom = ($(window).height() - (e.gesture.center.pageY + 10)) + 'px';
                else
                    position.top = (e.gesture.center.pageY + 10) + 'px';

                if (totalRight > $(window).width())
                    position.right = ($(window).width() - (e.gesture.center.pageX + 10)) + 'px';
                else
                    position.left = (e.gesture.center.pageX + 10) + 'px';

                $target.css(position);
                $target.addClass("show");
            });
        }
    }
}

export class ContextDialogMenu {

    static isTouchEnabled() : boolean {
        return !!(('ontouchstart' in window) || (<any>window).DocumentTouch && document instanceof DocumentTouch);

    }

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var canShow = allBindingsAccessor()["contextDialogMenuCanShow"] || (() => true);

        $(element).on('contextmenu', (e : any) => {

            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();

            if(!canShow()) {
                return;
            }

            options();
        });

        (<any>$(element)).hammer({ touchAction: 'pan-y'}).on('hold', (e:any) => {

            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();

            options();
        });


    }
}

ko.bindingHandlers["dblClick"] = new DblClk();
ko.bindingHandlers["dblClickEx"] = new DblClickEx();
ko.bindingHandlers["singleClick"] = new SingleClick();
ko.bindingHandlers["contextMenu"] = new ContextMenu();
ko.bindingHandlers["contextDialogMenu"] = new ContextDialogMenu();