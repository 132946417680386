import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IProvisioningService, IRequestForQuotationValidForSupplierOrder } from "../../../ProvisioningService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IVatRegisters } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { LayoutColumn, LayoutWithHeader, LayoutHeader, LayoutContent, VerticalLayout } from "../../../../Components/Layouts";
import { Portlet, PortletActions, PortletBody } from "../../../../Components/Portlet";
import { ArrayDataSource, IArrayDataSourceModel } from "../../../../DataSources/ArrayDataSource";
import { ButtonGroup, Button } from "../../../../Components/ButtonGroup";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import jss from "jss";
import { ArticleInfo, PurchaseInfo } from "./ArticleInfo";
import { INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";

const { classes } = jss.createStyleSheet({
    suppliersOrdersPanel: {
        flex: 1,
        maxWidth: '100%',

        "& .list-notification-item-details": {
            cursor: 'pointer',

            "& > div.article": {
                borderLeft: "2px solid rgb(67, 181, 69)",
                margin: '-8px 0',
                padding: '8px 5px',
                marginTop: '-18px'
            },

            "& > div.purchase": {
                borderLeft: "2px solid rgb(86, 183, 247)",
                margin: '-8px 0',
                padding: '8px 5px',
            },

            "& .list-notification-item-time": {
                fontSize: '0.9em !important',
                lineHeight: '0.9em'
            }
        }
    }
}).attach();

type EntityInfo = {
    Id: number,
    Type: string,
    Code: string,
    Description: string
}

type SupplierInfo = {
    Id: number,
    Name: string
}

type SupplierOrdersPanelProps = {
    navBarActionManager: INavBarActionManager;
}
export class SupplierOrdersPanel implements IDataSourceListener {
    public GroupedByEntity : ko.Observable<boolean> = ko.observable(true);
    
    @LazyImport(nameof<IProvisioningService>())
    private provisioningService : IProvisioningService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters : IVatRegisters;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    AllRows : ko.ObservableArray<RequestForQuotationValidForSupplierOrder> = ko.observableArray().extend({ rateLimit: { timeout: 100, method: "notifyWhenChangesStop" }});
    AllEntitiesDataSource : ArrayDataSource<EntityInfo> = new ArrayDataSource();
    AllSuppliersDataSource : ArrayDataSource<SupplierInfo> = new ArrayDataSource();
    AllSelected : ko.Computed<boolean>;

    private data: RequestForQuotationValidForSupplierOrder[] = [];

    constructor(public props : SupplierOrdersPanelProps) {
        this.AllSelected = ko.computed({
            read: () => {
                const selectedCount = this.AllRows().filter(i => i.Selected()).length;
                return selectedCount === this.AllRows().length ? true : selectedCount === 0 ? false : undefined;
            },
            write: (value: boolean) => {
                for(const item of this.AllRows())
                    item.Selected(value);
            }
        });
    }

    componentDidMount() {
        this.load();
    }

    isPanel(panelId: number): boolean {
        return panelId === ProlifeSdk.SupplierOrdersPanelId;
    }

    SelectById(id: number): void {
        
    }

    async load() : Promise<void> {
        this.data = (await this.provisioningService.GetRequestForQuotationRowsValidForSupplierOrders()).map(this.createViewModelFor, this);

        const allEntities = this.data.map(d => ({ Id: d.EntityId, Type: d.Type(), Code: d.Code(), Description: d.Description() })).distinct();
        const orderedEntities = allEntities
            .filter(e => e.Type === 'WAR')
            .orderBy(e => e.Code)
            .concat(allEntities
                .filter(e => e.Type !== 'WAR')
                .orderBy(e => e.Description)
            );
        this.AllEntitiesDataSource.setData(...orderedEntities.map(this.createEntityDataModel, this));
        this.AllEntitiesDataSource.refresh();

        const allSuppliers = this.data.map(d => ({ Id: d.SupplierId, Name: d.SupplierName() })).distinct().orderBy(s => s.Name);
        this.AllSuppliersDataSource.setData(...allSuppliers.map(this.createSupplierDataModel, this));
        this.AllSuppliersDataSource.refresh();

        this.AllRows(this.data);
    }

    private createViewModelFor(row : IRequestForQuotationValidForSupplierOrder) : RequestForQuotationValidForSupplierOrder {
        return new RequestForQuotationValidForSupplierOrder(row);
    }

    public createSupplierDataModel(e : SupplierInfo) {
        return {
            id: e.Id,
            title: e.Name,
            isLeaf: true,
            isGroup: false,
            model: e
        }
    }

    public createEntityDataModel(e : EntityInfo) : IDataSourceModel<number, EntityInfo> {
        return {
            id: e.Id,
            title: e.Type === "WAR" ? e.Code : e.Description,
            subTitle: e.Type === "WAR" ? e.Description : undefined,
            isLeaf: true,
            isGroup: false,
            model: e
        }
    }

    public createRowDataModel(d : RequestForQuotationValidForSupplierOrder) {
        return {
            id: d.Id,
            title: d.Description(),
            isLeaf: true,
            isGroup: false,
            model: d
        };
    }

    onItemSelected(sender : IDataSource, model : IDataSourceModel) : void {
        if(sender === this.AllEntitiesDataSource && model) {
            const entityInfo = model.model as EntityInfo;
            this.AllRows(this.data.filter(d => d.EntityId === entityInfo.Id && d.Type() === entityInfo.Type))
        } else if(sender === this.AllSuppliersDataSource) {
            const supplierInfo = model.model as SupplierInfo;
            this.AllRows(this.data.filter(d => d.SupplierId === supplierInfo.Id))
        }
    }

    onItemDeselected(sender : IDataSource, model : IDataSourceModel) : void {
        this.AllRows(this.data);
    }

    public hasChanges(): boolean {
        return false;
    }

    public dispose(): void {
        
    }

    public GroupByEntity() {
        this.GroupedByEntity(true);
        this.AllEntitiesDataSource.selectByIds();
        this.AllSuppliersDataSource.selectByIds();
    }

    public GroupBySupplier() {
        this.GroupedByEntity(false);
        this.AllEntitiesDataSource.selectByIds();
        this.AllSuppliersDataSource.selectByIds();
    }

    public async CreateSupplierOrders() : Promise<void> {
        /*let selectedRows = this.allRows.filter(r => r.Selected());
        if(selectedRows.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Provisioning.NoRequestForQuotationRowsSelected);
            return;
        }

        let vatRegister = this.vatRegisters.getVatRegisters().firstOrDefault(r => r.TipoDocumento == ProlifeSdk.SupplierOrderTypeId);
        if(!vatRegister) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Provisioning.NoSupplierOrderVatRegisterFound);
            return;
        }

        try
        {
            await this.provisioningService.CreateSupplierOrders({
                Entities: selectedRows.map(r => { 
                    return {
                        RequestForQuotationRowId: r.Id,
                        SupplierId: r.SupplierId,
                        EntityId: r.EntityId,
                        EntityType: r.Type(),
                        Amount: r.Amount(),
                        EntityDescription: r.Description(),
                        UnitPrice: r.ResponsePrice(),
                        NetUnitPrice: r.ResponsePrice(),
                        ResponseDeliveryDate: r.ResponseDeliveryDate()
                    };
                }),
                RegisterId: vatRegister.IdRegistroIVA
            });

            this.infoToastService.Success(ProlifeSdk.TextResources.Provisioning.SupplierOrdersGenerationCompleted);

            await this.load();
        }
        catch(e)
        {
            let ex : IException = e;
            this.infoToastService.Error(ex.ExceptionMessage);
        }*/
    }

    render() {
        const panel : SupplierOrdersPanel = this;
        const classNames = ComponentUtils.classNames("flex-1", classes.suppliersOrdersPanel);

        return ComponentUtils.bindTo(
            <LayoutWithHeader className={classNames}>
                <LayoutHeader className="flex-child-center">
                    <h1 className="flex-1">Generazione Ordini Fornitore</h1>
                    <button type="button" class="btn btn-primary" data-bind={{ asyncClick: panel.CreateSupplierOrders.bind(panel) }}>
                        Crea Ordini Fornitore
                    </button>
                </LayoutHeader>
                <LayoutContent>
                    {this.renderContent()}
                </LayoutContent>
            </LayoutWithHeader>
        , this, "panel")
    }

    private renderAllRowsTable(dataSource : ko.ObservableArray<RequestForQuotationValidForSupplierOrder>, title: string) {
        const panel : SupplierOrdersPanel = this;
        let row : IArrayDataSourceModel<RequestForQuotationValidForSupplierOrder>;

        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<RequestForQuotationValidForSupplierOrder>();

        return  <Table id={17} dataSource={{ array: dataSource, factory: this.createRowDataModel }} rowAs="row" compact={true} editable={true} hideSelection={true} showColumnSelector={true} listener={this} title={title} scrollable={true}>
                    <Column id={18}>
                        <ColumnHeader>
                            <input type="checkbox" data-bind={{ checkbox: panel.AllSelected }} />
                        </ColumnHeader>
                        <ColumnBody>
                            {() => (
                                <div className="with-identifier">
                                    <span className="identifier" data-bind={{ style: { 'background-color': (row.model.Type() == 'WAR' ? '#43b545' : '#56b7f7') } }}></span>
                                    <input type="checkbox" data-bind={{ checkbox: row.model.Selected }} />
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column id={19} title="Nr." headerCssClasses="row-number" data-bind={{ numberText: row.model.Number, format: '0,0' }} sorter={sortString(v => v.Number())} />
                    <Column id={20} title="Articolo/Acquisto" headerCssClasses="art-code text-left" sorter={sortString(v => v.CodeOrDescription())}>
                        <ColumnBody>
                            {(item : ITableItem<RequestForQuotationValidForSupplierOrder>) => {
                                if(item.Data.model.IsArticle())
                                    return <ArticleInfo code={item.Data.model.Code} description={item.Data.model.Description} />
                                else
                                    return <PurchaseInfo code={item.Data.model.Code} description={item.Data.model.Description} />
                            }}
                        </ColumnBody>
                    </Column>
                    <Column id={21} title="Q.tà" headerCssClasses="text-right" cssClasses="amount text-right edit-field" sorter={sortNumber(v => v.Amount())}>
                        <input type="text" class="form-control input-sm text-right" data-bind={{ numberValue: row.model.Amount, selectOnFocus: {}, css: { 'bg-green': row.model.Amount() !== row.model.ResponseAmount() } }} />
                    </Column>
                    <Column id={22} title="U.d.m." headerCssClasses="unit-of-measure" cssClasses="unit-of-measure text-right" sorter={sortString(v => v.UoM())}>
                        <span data-bind={{ text: row.model.UoM }}></span>
                    </Column>
                    <Column id={23} title="Data cons." headerCssClasses="delivery" cssClasses="delivery text-right" sorter={sortDate(v => v.DeliveryDate())}>
                        <span data-bind={{ dateText: row.model.DeliveryDate }}></span>
                    </Column>
                    <Column id={24} title="Fornitore" headerCssClasses="preorder-col-th text-left" cssClasses="preorder-col-td-double text-ellipsis" sorter={sortString(v => v.SupplierName())}>
                        <span data-bind={{ text: row.model.SupplierName }}></span>
                    </Column>
                    <Column id={25} title="Q.tà conf." headerCssClasses="amount response-start" cssClasses="amount response-start text-right" sorter={sortNumber(v => v.ResponseAmount())}>
                        <span data-bind={{ numberText: row.model.ResponseAmount }}></span>
                    </Column>
                    <Column id={26} title="Prezzo conf." headerCssClasses="amount" cssClasses="amount text-right" sorter={sortNumber(v => v.ResponsePrice())}>
                        <span data-bind={{ moneyText: row.model.ResponsePrice }}></span>
                    </Column>
                    <Column id={27} title="Data cons. eff." headerCssClasses="delivery" cssClasses="delivery text-right" sorter={sortDate(v => v.ResponseDeliveryDate())}>
                        <span data-bind={{ dateText: row.model.ResponseDeliveryDate }}></span>
                    </Column>
                    <Column id={28} title="Data risp." headerCssClasses="delivery" cssClasses="delivery text-right" sorter={sortDate(v => v.ResponseDate())}>
                        <span data-bind={{ dateText: row.model.ResponseDate }}></span>
                    </Column>
                    <Column id={29} title="Fine val." headerCssClasses="delivery" cssClasses="delivery text-right" sorter={sortDate(v => v.DueDate())}>
                        <span data-bind={{ dateText: row.model.DueDate }}></span>
                    </Column>
                </Table>
    }

    private renderContent() {
        const panel : SupplierOrdersPanel = this;

        return  <VerticalLayout className="flex-1">
                    <LayoutColumn size={3}>
                        <Portlet portletTitle="Articoli" collapsible={false}>
                            <PortletActions>
                                <ButtonGroup circle={true} size="sm">
                                    <Button color="primary" data-bind={{ click: panel.GroupByEntity.bind(panel), clickBubble: false, css: { 'btn-primary': panel.GroupedByEntity, 'btn-default': !panel.GroupedByEntity() } }}>
                                        Entità
                                    </Button>
                                    <Button color="primary" data-bind={{ click: panel.GroupBySupplier.bind(panel), clickBubble: false, css: { 'btn-primary': !panel.GroupedByEntity(), 'btn-default': panel.GroupedByEntity } }}>
                                        Fornitori
                                    </Button>
                                </ButtonGroup>
                            </PortletActions>
                            <PortletBody>
                                {() => 
                                    <>
                                        <ko-bind data-bind={{ if: panel.GroupedByEntity }}>
                                            <list dataSource={() => "panel.AllEntitiesDataSource"} listener={() => "panel"}>
                                                <div data-bind="click: Select, css: { article: Model.Type === 'WAR', purchase: Model.Type !== 'WAR' }">
                                                    <div class="list-notification-item-title" data-bind="text: Title"></div>
                                                    <div class="list-notification-item-time" data-bind="text: Subtitle"></div>
                                                </div>
                                            </list>
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: panel.GroupedByEntity }}>
                                            <list dataSource={() => "panel.AllSuppliersDataSource"} listener={() => "panel"} />
                                        </ko-bind>
                                    </>
                                }
                            </PortletBody>
                        </Portlet>
                    </LayoutColumn>
                    <LayoutColumn size={9}>
                        {this.renderAllRowsTable(this.AllRows, "Righe da Richieste d'Offerta")}
                    </LayoutColumn>
                </VerticalLayout>
    }
}


export class RequestForQuotationValidForSupplierOrder {
    Selected : ko.Observable<boolean> = ko.observable(false);
    Type : ko.Observable<string> = ko.observable();
    Number : ko.Observable<string> = ko.observable();
    Code : ko.Observable<string> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UoM : ko.Observable<string> = ko.observable();
    SupplierName : ko.Observable<string> = ko.observable();
    DeliveryDate : ko.Observable<Date> = ko.observable();
    ResponseAmount : ko.Observable<number> = ko.observable();
    ResponsePrice : ko.Observable<number> = ko.observable();
    ResponseDeliveryDate : ko.Observable<Date> = ko.observable();
    ResponseDate : ko.Observable<Date> = ko.observable();
    DueDate : ko.Observable<Date> = ko.observable();

    CodeOrDescription : ko.Computed<string>;

    IsArticle : ko.Computed<boolean>;
    IsPurchase: ko.Computed<boolean>
    EntityId: number;
    SupplierId: number;
    Id: number;

    constructor(row : IRequestForQuotationValidForSupplierOrder) {
        this.Id = row.RequestForQuotationRowId;
        this.EntityId = row.EntityId;
        this.SupplierId = row.SupplierId;
        this.Type(row.EntityType);
        this.Number(row.RequestForQuotationNumber);
        this.Code(row.Code || "N/A");
        this.Description(row.EntityDescription);
        this.Amount(row.RequestForQuotationResponseAmount);
        this.UoM(row.UoM);
        this.SupplierName(row.SupplierForRequestName);
        this.DeliveryDate(row.RequestForQuotationDate);
        this.ResponseAmount(row.RequestForQuotationResponseAmount);
        this.ResponsePrice(row.RequestForQuotationResponsePrice);
        this.ResponseDeliveryDate(row.RequestForQuotationResponseDeliveryDate);
        this.ResponseDate(row.RequestForQuotationResponseDate);
        this.DueDate(row.DueDate);

        this.IsArticle = ko.computed(() => this.Type() == ProlifeSdk.WarehouseArticleEntityTypeCode);
        this.IsPurchase = ko.computed(() => this.Type() == ProlifeSdk.PurchasesEntityTypeCode);

        this.CodeOrDescription = ko.computed(() => {
            return this.IsArticle() ? this.Code() + " " + this.Description() : this.Description();
        });
    }
}