import { LazyImport } from "../../../../Core/DependencyInjection";
import { BaseDataSource } from "../../../../DataSources/BaseDataSource";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IGetResourceHoursApprovalGroupsRequest, IHoursApprovalGroup, IUsersService } from "../../../../Users/UsersService";

export type HoursApprovalGroupsDataSourceModel = IDataSourceModel<number, IHoursApprovalGroup>;

export class HoursApprovalGroupsDataSource extends BaseDataSource<HoursApprovalGroupsDataSourceModel> {
    @LazyImport(nameof<IUsersService>())
    private usersService: IUsersService;

    getTitle(): string {
        return "";
    }

    async getData(currentModel: HoursApprovalGroupsDataSourceModel, textFilter: string, skip: number, count: number): Promise<HoursApprovalGroupsDataSourceModel[]> {
        const approvalGroupsRequest: IGetResourceHoursApprovalGroupsRequest = {
            textFilter: textFilter,
            skip: skip,
            count: count
        };
        const groups = await this.usersService.GetResourceHoursApprovalGroups(approvalGroupsRequest);
        return groups.map(this.createModel, this);
    }
    
    async getById(currentModel: HoursApprovalGroupsDataSourceModel, ids: number[]): Promise<HoursApprovalGroupsDataSourceModel[]> {
        const groups = await this.usersService.GetResourceHoursApprovalGroupsByIds(ids, null);
        return groups.map(this.createModel, this);
    }

    private createModel(group: IHoursApprovalGroup): HoursApprovalGroupsDataSourceModel {
        return {
            id: group.GroupId,
            title: group.GroupName,
            subTitle: group.WorkedHoursApprovalRole,
            isGroup: false,
            isLeaf: true,
            model: group
        };
    }
}