import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { ITodoListTemplateForList } from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";

export interface ITemplateDataSourceModel extends IDataSourceModel<number, ITodoListTemplateForList> {

}

export class TemplatesDataSource extends BaseDataSource<ITemplateDataSourceModel> {
    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;

    constructor() {
        super();
    }

    public getTitle(currentModel: ITemplateDataSourceModel): string {
        return TextResources.Todolist.Templates;
    }
    
    public async getData(currentModel: ITemplateDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITemplateDataSourceModel[]> {
        let templates = await this.todolistService.GetTemplatesForList(textFilter, skip, count);
        return templates.map(this.createModelFor, this);
    }
    
    public async getById(currentModel: ITemplateDataSourceModel, ids: number[]): Promise<ITemplateDataSourceModel[]> {
        let template = await this.todolistService.GetTemplateForList(ids.firstOrDefault());
        return !template ? [] : [this.createModelFor(template)];
    }

    private createModelFor(template: ITodoListTemplateForList): ITemplateDataSourceModel {
        return {
            id: template.Id,
            isGroup: false,
            isLeaf: true,
            title: template.Title,
            model: template
        };
    }
}