import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { RequirementRowSourceBase } from "./RequirementRowSourceBase";
import { DetectChanges } from "../../../Core/ChangeDetection";
import { IRequirement, IPreOrderRowWithDetails } from "../../ProvisioningService";
import { IDetectChanges } from "../../../Core/interfaces/IDetectChanges";

export class RequirementRowBase<T extends RequirementRowSourceBase> implements IDetectChanges {
    public EntityId: number;
    public Type: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Description: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Amount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public UoM: ko.Observable<string> = ko.observable();
    public IsExpanded: ko.Observable<boolean> = ko.observable(false);
    public SortMode: ko.Observable<string> = ko.observable();
    public SortColumn: ko.Observable<string> = ko.observable();
    public Sources: ko.ObservableArray<T> = ko.observableArray([]);
    public isChanged: ko.Observable<number> = ko.observable(0);

    public Selected: ko.Computed<boolean>;
    public SelectedAmount: ko.Computed<number>;
    
    protected InStock: ko.Computed<number>;

    public get EntityCode() { return (this.requirement as IRequirement)?.EntityCode; }
    public get EntityDescription() { return (this.requirement as IRequirement)?.EntityDescription; }
    
    constructor(protected requirement: IRequirement | IPreOrderRowWithDetails) {
        this.EntityId = requirement.EntityId;
        this.Type(requirement.EntityType);
        this.Description(this.getDescription(requirement));
        this.Amount(0);
        this.UoM(requirement.UoM);

        this.isChanged(0);

        this.InStock = ko.computed(() => {
            let warehouses = {};
            let totalStock: number = 0;

            this.Sources().forEach((s) => {
                if (!warehouses[s.SourceWarehouse()]) {
                    warehouses[s.SourceWarehouse()] = true;
                    totalStock += (s.WarehouseStock() || 0);
                }
            });

            return totalStock;
        });
    }

    public switchExpandedState(): void {
        this.IsExpanded(!this.IsExpanded());
    }

    public dispose(): void {
        
    }

    protected getDescription(requirement: IRequirement | IPreOrderRowWithDetails): string {
        return requirement.EntityType === ProlifeSdk.EstimatedPurchaseEntityTypeCode 
            ? requirement.EntityDescription 
            : ((requirement.EntityDescription || "").indexOf((requirement.ArticleCode || "")) < 0 ?  (requirement.ArticleCode || "") + ' - ' + (requirement.EntityDescription || "") : requirement.EntityDescription);
    }
}