import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import {
    ComponentUtils,
    Param,
    ParamArray,
    ComponentParam,
    ComponentParamArray,
} from "../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";

let attributes = {
    Config: "config",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "document-action": {
                params?: {
                    Config: string;
                };

                config: IDocumentAction | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IDocumentAction {
    CanRun?: Param<boolean>;
    Visible?: Param<boolean>;

    ActionText?: string | Param<string>;
    Icon?: string | Param<string>;
    Class?: string | Param<string>;

    IsGroup?: boolean;
    Actions?: ParamArray<IDocumentAction>;

    Component?: () => Node[];
    Run?: (item: any, event: Event) => void;

    render?: () => React.ReactElement;
}

export interface IDocumentActionParams {
    Config: Param<IDocumentAction>;
}

export class DocumentAction {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    CanRun: ComponentParam<boolean>;
    Visible: ComponentParam<boolean>;

    ActionText: ComponentParam<string>;
    Icon: ComponentParam<string>;
    Class: ComponentParam<string>;

    IsGroup: ComponentParam<boolean>;
    Actions: ComponentParamArray<DocumentAction>;

    Component?: () => Node[];
    Run?: (item: any, event: Event) => Promise<void> | void;

    render?: () => React.ReactElement;

    constructor(params: IDocumentActionParams) {
        let configData = ComponentUtils.parseParameter(params.Config, null);
        if (!configData()) throw "Cannot initialize DocumentAction, Config is null";

        let config = configData();
        this.CanRun = ComponentUtils.parseParameter(config.CanRun, true);
        this.Visible = ComponentUtils.parseParameter(config.Visible, true);
        this.ActionText = ComponentUtils.parseParameter(config.ActionText, "(Senza Nome)");
        this.Icon = ComponentUtils.parseParameter(config.Icon, "");
        this.IsGroup = ComponentUtils.parseParameter(config.IsGroup, false);
        this.Class = ComponentUtils.parseParameter(config.Class, "blue");

        this.Actions = ComponentUtils.parseAndConvertParameterArray(
            config.Actions,
            [],
            this.createViewModel.bind(this)
        );
        this.Component = config.Component;

        if (this.Component) this.Run = this.showComponent.bind(this);
        else this.Run = config.Run;

        this.render = config.render;
    }

    private createViewModel(action: IDocumentAction): DocumentAction {
        return new DocumentAction({
            Config: ko.observable(action),
        });
    }

    private showComponent(event: Event): Promise<void> {
        return this.dialogsService.ShowPopoverComponentFromFactory(
            event.target as HTMLElement,
            "",
            this.Component.bind(this),
            "bottom"
        );
    }
}

ko.components.register("document-action", {
    viewModel: {
        createViewModel: (params: IDocumentActionParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new DocumentAction(params);
            const $data = vm;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <ko-ifnot data-bind="IsGroup">
                        <button
                            class="btn btn-sm"
                            data-bind="asyncClick: Run, disabled : !CanRun(), visible: Visible, css: Class"
                        >
                            <i class="fa" data-bind="css: Icon" style="margin-right: 5px;"></i>
                            <span class="btn-small-text" data-bind="text : ActionText"></span>
                        </button>
                    </ko-ifnot>
                    <ko-if data-bind="IsGroup">
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                data-bind="css: Class"
                            >
                                <i class="fa" data-bind="css: Icon"></i>&nbsp;
                                <span data-bind="text: ActionText"></span>&nbsp;
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu" data-bind="foreach: Actions">
                                <li>
                                    <ko-if data-bind="render">
                                        <ko-bind data-bind={{ tsxtemplate: $data }}></ko-bind>
                                    </ko-if>
                                    <ko-ifnot data-bind="render">
                                        <a
                                            href="#"
                                            data-bind="click: Run, visible: Visible, css: { disabled: !CanRun() }"
                                        >
                                            <i class="fa" data-bind="css: Icon"></i>{" "}
                                            <span data-bind="text: ActionText"></span>
                                        </a>
                                    </ko-ifnot>
                                </li>
                            </ul>
                        </div>
                    </ko-if>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
