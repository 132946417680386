import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/06/2017
 * Time: 10:56
 * To change this template use File | Settings | File Templates.
 */

import { Question } from "../Question";
import { AnswerForDetails } from "./AnswerForDetails";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelectable } from "../../../../../ProlifeSdk/interfaces/survey/ISelectable";
import { ISelectionObserver } from "../../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { IQuestion } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { IAnswer } from "../../../../../ProlifeSdk/interfaces/survey/IAnswer";

export class QuestionForDetails extends Question implements ISelectable {
    public Answers: ko.ObservableArray<AnswerForDetails> = ko.observableArray([]);
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public templateName: string = "question-generic";
    public templateUrl: string = "survey/templates/questionnaire";

    public IsSingleAnswerQuestion: ko.Computed<boolean>;
    public IsMultipleAnswerQuestion: ko.Computed<boolean>;
    public IsOpenAnswerQuestion: ko.Computed<boolean>;

    public HasNumericResult: ko.Computed<boolean>;
    public HasTextResult: ko.Computed<boolean>;
    public HasDateResult: ko.Computed<boolean>;
    public HasOpenAnswers: ko.Computed<boolean>;
    public WithoutAnswers: ko.Computed<boolean>;
    public HasAnswersWithGoTo: ko.Computed<boolean>;
    public HasAnswersWithEnable: ko.Computed<boolean>;

    public AnswersNumber: ko.Computed<number>;

    private surveyService: ISurveyService;

    private selectionObservers: ISelectionObserver[] = [];

    constructor(private serviceLocator: IServiceLocator, question: IQuestion) {
        super(question);
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.Selected(false);

        this.surveyService.getQuestionAnswers(question.Id)
            .then((answers: IAnswer[]) => {
                if (answers.length == 0)
                    return;
                var isUniqueAnswer = (answers.length == 1);
                this.Answers(answers.map((answer: IAnswer) => {
                    return new AnswerForDetails(answer, isUniqueAnswer);
                }));
            })
            .catch(() => {});

        this.AnswersNumber = ko.computed(() => {
            return this.Answers().length;
        });

        this.IsMultipleAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.MultipleAnswerQuestionType;
        });

        this.IsSingleAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.SingleAnswerQuestionType;
        });

        this.IsOpenAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.OpenAnswerQuestionType;
        });

        this.HasDateResult = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerDateField && this.AnswersNumber() > 0;
        });

        this.HasNumericResult = ko.computed(() => {
            return (this.AnswerTypeId() == ProlifeSdk.AnswerNumericField || this.AnswerTypeId() == ProlifeSdk.AnswerMoneyField) && this.AnswersNumber() > 0;
        });

        this.HasTextResult = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerTextField && this.AnswersNumber() > 0;
        });

        this.WithoutAnswers = ko.computed(() => {
            return this.AnswersNumber() == 0;
        });

        this.HasOpenAnswers = ko.computed(() => {
            return this.Answers().filter((a: AnswerForDetails) => {
                return a.IsOpen();
            }).length > 0;
        });

        this.HasAnswersWithEnable = ko.computed(() => {
            return this.Answers().filter((a: AnswerForDetails) => {
                return !!a.EnablePublicLabel();
            }).length > 0;
        });

        this.HasAnswersWithGoTo = ko.computed(() => {
            return this.Answers().filter((a: AnswerForDetails) => {
                return !!a.GoToPublicLabel();
            }).length > 0;
        });
    }

    public RegisterSelectionObserver(observer: ISelectionObserver) {
        this.selectionObservers.push(observer);
    }

    public NotifySelection() {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectQuestion(this);
        })
    }

    public NextQuestion(): void {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectNextQuestion(this);
        })
    }

    public PreviousQuestion(): void {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectPreviousQuestion(this);
        })
    }
}