import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { RouteParams } from "../../../../../Components/Routing";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { classNames, ComponentUtils, PropsWithChildren } from "../../../../../Core/utils/ComponentUtils";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { ArticleInfo, PurchaseInfo } from "../ArticleInfo";
import { RequestForQuotationResponseStateHelper } from "../../../entities/RequestForQuotation/Utils/RequestForQuotationResponseStateHelper";
import { CheckBox } from "../../../../../Components/Checkbox";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { IArrayDataSourceModel } from "../../../../../DataSources/ArrayDataSource";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import {
    IProvisioningService,
    IRequestForQuotationRow,
    IRequestForQuotationRowShipment,
    IRequestForQuotation_Type_Row,
} from "../../../../ProvisioningService";
import { _RequestForQuotationRowInfoPopover } from "./RequestForQuotationRowInfoPopover";
import { DateTimeInput } from "../../../../../Components/DateTimeInput";
import { NumberInput } from "../../../../../Components/NumberInput";
import { Select } from "../../../../../Components/Select";
import { TextInput } from "../../../../../Components/TextInput";
import { If } from "../../../../../Components/IfIfNotWith";
import { MoneyInput } from "../../../../../Components/MoneyInput";
import { RichTextInput } from "../../../../../Components/RichTextInput";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { IWarehouseWithStockInfo } from "../../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IIdGeneratorService } from "../../../../../ProlifeSdk/IdGeneratorService";
import { ProxyDataSource } from "../../../../../DataSources/ProxyDataSource";
import { WarehousesDataSource } from "../../../../../DataSources/WarehousesDataSource";
import { IWarehouseDataSourceModel } from "../../../../../DataSources/WarehousesDocumentDataSource";
import { RequestForQuotationRowShipmentEditor } from "./RequestForQuotationRowShipmentEditor";
import { AccordionSecondaryRow } from "../AccordionSecondaryRow";

const { classes } = jss
    .createStyleSheet({
        editor: {
            "& .editor-actions": {
                "& button:not(:last-child)": {
                    marginRight: "5px",
                },
            },

            "& .request-for-quotation-info-list": {
                listStyle: "none",
                padding: "0px",
                margin: "0px",

                "& .request-for-quotation-info": {
                    fontStyle: "italic",
                },
            },

            "& .request-title": {
                marginTop: "5px",
            },
        },

        requestForQuotationRowsTable: {
            "& .actions-col": {
                whiteSpace: "nowrap",
            },

            "&.editable > tbody > tr.verified > td": {
                backgroundColor: "#e0FFe0",
            },
        },

        caustomersAndJobOrderstable: {
            "& .actions-col": {
                width: "35px",
            },
        },
    })
    .attach();

export type RequestForQuotationRowsEditorProps = RouteParams<{
    requestId: number;
    hasChanges: ko.Observable<boolean>;
    forwardRef?: (ref: _RequestForQuotationRowsEditor) => void;
}>;

export function RequestForQuotationRowsEditor(props: RequestForQuotationRowsEditorProps) {
    const C = require("./RequestForQuotationRowsEditor")
        ._RequestForQuotationRowsEditor as typeof _RequestForQuotationRowsEditor;
    return <C {...props} />;
}

type RequestForQuotationRow = {
    Id: number;
    Verified: ko.Observable<boolean>;
    DetailsShown: ko.Observable<boolean>;
    PreOrderId: number;
    PreOrderRowId: number;
    Type: string;
    EntityCode: string;
    EntityDescription: string;

    Number: number;
    Amount: number;
    UoM: string;
    DeliveryDate: Date;
    ResponsePrice: ko.Observable<number>;
    ResponseAmount: ko.Computed<number>;
    HasMultipleShipments: ko.Computed<boolean>;
    ResponseDeliveryDate: ko.Observable<Date>;
    ResponseDate: ko.Observable<Date>;
    DueDate: ko.Observable<Date>;
    Note: ko.Observable<string>;

    ShipmentDates: ko.ObservableArray<RequestForQuotationRowShipmentDate>;

    toggleDetails(): void;
};

export type RequestForQuotationRowShipmentDate = {
    Id: number;
    ShipmentDate: ko.Observable<Date>;
    Amount: ko.Observable<number>;
    WarehouseId: ko.Observable<number>;
    Address: ko.Observable<string>;
    City: ko.Observable<string>;
    PostalCode: ko.Observable<string>;
    Province: ko.Observable<string>;
    State: ko.Observable<string>;

    CanDelete: boolean;
    ShowAddress: ko.Observable<boolean>;
    FullAddress: ko.Computed<string>;

    toggleAddress(): void;
    onWarehouseSelected(model: IWarehouseWithStockInfo): void;

    WarehousesDataSource: ProxyDataSource<WarehousesDataSource, IWarehouseDataSourceModel>;
};

export function createShipmentRow(
    row: IRequestForQuotationRowShipment,
    component: ComponentBase & {
        WarehousesDataSource: WarehousesDataSource;
        props: { hasChanges: ko.Observable<boolean> };
    }
): RequestForQuotationRowShipmentDate {
    const amount = ko.observable(row.Amount).extend({ notify: "always" });
    const address = ko.observable(row.Address);
    const postalCode = ko.observable(row.PostalCode);
    const city = ko.observable(row.City);
    const province = ko.observable(row.Province);
    const shipmentDate = ko.observable(row.ShipmentDate);
    const state = ko.observable(row.State);
    const showAddress = ko.observable(false);
    const warehouseId = ko.observable(row.Fkwarehouse);

    component.disposeOnUnmount(address.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(amount.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(city.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(postalCode.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(province.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(shipmentDate.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(state.subscribe(() => component.props.hasChanges(true)));
    component.disposeOnUnmount(warehouseId.subscribe(() => component.props.hasChanges(true)));

    return {
        Id: row.Id,
        Address: address,
        Amount: amount,
        CanDelete: !row.IsDefault,
        City: city,
        FullAddress: ko.computed(() => {
            return String.format(
                "{0} {1} {2} {3} {4}",
                address() || "",
                postalCode() || "",
                city() || "",
                province() ? `(${province()})` : "",
                state() || ""
            );
        }),
        PostalCode: postalCode,
        Province: province,
        ShipmentDate: shipmentDate,
        ShowAddress: showAddress,
        State: state,
        WarehouseId: warehouseId,
        WarehousesDataSource: new ProxyDataSource(component.WarehousesDataSource),

        toggleAddress: () => {
            showAddress(!showAddress());
        },

        onWarehouseSelected: (model: IWarehouseWithStockInfo) => {
            address(model.Address);
            postalCode(model.CAP);
            city(model.City);
            province(model.Province);
            state(model.State);
        },
    };
}

export class _RequestForQuotationRowsEditor extends ComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService: IIdGeneratorService;

    Rows: ko.ObservableArray<RequestForQuotationRow> = ko.observableArray();
    WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();

    constructor(public props: RequestForQuotationRowsEditorProps) {
        super();

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    componentDidMount() {
        this.load();
    }

    public async load() {
        const rows = await this.provisioningService.GetRequestForQuotationRows(this.props.requestId);
        this.Rows(rows.map(this.createRequestForQuotationRow, this));
    }

    private getShipments(shipments: RequestForQuotationRowShipmentDate[]) {
        const shipmentsData = [];
        for (const shipment of shipments) {
            shipmentsData.push({
                address: shipment.Address(),
                amount: shipment.Amount(),
                city: shipment.City(),
                fkWarehouse: shipment.WarehouseId(),
                isDefault: !shipment.CanDelete,
                postalCode: shipment.PostalCode(),
                province: shipment.Province(),
                shipmentDate: shipment.ShipmentDate(),
                state: shipment.State(),
            });
        }
        return shipmentsData;
    }

    public getRows(): IRequestForQuotation_Type_Row[] {
        const rows = [];
        for (const row of this.Rows()) {
            rows.push({
                id: row.Id,
                responsePrice: row.ResponsePrice(),
                responseAmount: row.ResponseAmount(),
                responseDeliveryDate: row.ResponseDeliveryDate(),
                responseDate: row.ResponseDate(),
                dueDate: row.DueDate(),
                verified: row.Verified(),
                notes: row.Note(),
                shipments: this.getShipments(row.ShipmentDates()),
            });
        }
        return rows;
    }

    private createRequestForQuotationRow(r: IRequestForQuotationRow): RequestForQuotationRow {
        const shipments = ko.observableArray<RequestForQuotationRowShipmentDate>(
            r.Shipments.map((s) => createShipmentRow(s, this))
        );
        const detailsShown = ko.observable(false);

        const dueDate = ko.observable(r.DueDate);
        const note = ko.observable(r.Note);

        const firstShipment = shipments()[0];
        const responseAmount = ko.computed({
            read: () => {
                return shipments().sum((s) => s.Amount());
            },
            write: (value: number) => {
                if (shipments().length > 1) return;
                firstShipment.Amount(value);
            },
        });
        const responseDate = ko.observable(r.ResponseDate);
        const responseDeliveryDate = firstShipment.ShipmentDate;
        const responsePrice = ko.observable(r.ResponsePrice);

        this.disposeOnUnmount(shipments.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(dueDate.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(note.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(responseAmount.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(responseDate.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(responseDeliveryDate.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(responsePrice.subscribe(() => this.props.hasChanges(true)));

        return {
            Id: r.Id,
            Amount: r.Amount,
            DeliveryDate: r.DeliveryDate,
            DueDate: dueDate,
            EntityCode: r.EntityCode,
            EntityDescription: r.EntityDescription,
            HasMultipleShipments: ko.computed(() => shipments().length > 1),
            Note: note,
            DetailsShown: detailsShown,
            Number: r.Number,
            PreOrderId: r.PreOrderId,
            PreOrderRowId: r.PreOrderRowId,
            ResponseAmount: responseAmount,
            ResponseDate: responseDate,
            ResponseDeliveryDate: responseDeliveryDate,
            ResponsePrice: responsePrice,
            ShipmentDates: shipments,
            Type: r.EntityType,
            UoM: r.UoM,
            Verified: ko.observable(r.Verified),
            toggleDetails: () => {
                detailsShown(!detailsShown());
            },
        };
    }

    private removeRows(...rowsToDelete: RequestForQuotationRow[]): void {
        for (const row of rowsToDelete) this.Rows.remove(row);
    }

    public async removeRow(row: RequestForQuotationRow): Promise<void> {
        const response = await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.RemoveRequestForQuotationRow,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm
        );

        if (!response) return;

        this.removeRows(row);
    }

    private showPreOrderRowInfo(row: RequestForQuotationRow, _, e: Event) {
        const popover = new _RequestForQuotationRowInfoPopover({
            preOrderId: row.PreOrderId,
            preOrderRowId: row.PreOrderRowId,
        });
        this.dialogsService.ShowPopoverComponent(e.currentTarget as HTMLElement, popover, "left");
    }

    render() {
        const _rfqre = this;
        let requestForQuotationRow: IDataSourceModel<number, RequestForQuotationRow>;

        const VerifiableRow = (props: PropsWithChildren) => (
            <tr data-bind={{ css: { verified: requestForQuotationRow.model.Verified } }}>{props.children}</tr>
        );

        const dataSource = Table.defaultDataSource(this.Rows, (i) => ({ id: i.Id, title: "", model: i }));

        return (
            <Table
                bindTo={{ _rfqre: this }}
                compact
                editable
                dataSource={dataSource}
                rowAs="requestForQuotationRow"
                systemScrollable
                fixedLayout
                className={classNames(classes.requestForQuotationRowsTable, "main-table")}
                style={{ margin: 0 }}
                components={{ row: (p) => <VerifiableRow {...p} /> }}>
                <Column>
                    <ColumnHeader style={{ width: "21px" }}>
                        {/* <input type="checkbox" data-bind={{ checkbox: _rfqre.SelectAllRows }}></input> */}
                    </ColumnHeader>
                    <ColumnBody>
                        <div className="with-identifier">
                            <span
                                class="identifier"
                                data-bind={{
                                    style: {
                                        "background-color":
                                            requestForQuotationRow.model.Type === "WAR" ? "#43b545" : "#56b7f7",
                                    },
                                }}></span>
                            <span
                                class="btn-link btn-xs"
                                title="Apri/Chiudi Dettagli"
                                data-bind={{
                                    click: requestForQuotationRow.model.toggleDetails.bind(
                                        requestForQuotationRow.model
                                    ),
                                }}>
                                <i
                                    class="fa"
                                    data-bind={{
                                        css: {
                                            "fa-angle-right": !requestForQuotationRow.model.DetailsShown(),
                                            "fa-angle-down": requestForQuotationRow.model.DetailsShown,
                                        },
                                    }}></i>
                            </span>
                        </div>
                    </ColumnBody>
                </Column>
                <Column title="Nr." style={{ width: "35px" }} className="text-center">
                    <span
                        className={"value"}
                        style={{ cursor: "pointer" }}
                        data-bind={{
                            numberText: requestForQuotationRow.model.Number,
                            format: "0,0",
                            click: requestForQuotationRow.model.toggleDetails.bind(requestForQuotationRow.model),
                        }}></span>
                </Column>
                <Column title="Articolo/Acquisto" cssClasses={"text-left text-ellipsis"} style={{ maxWidth: "200px" }}>
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <>
                                {item.Data.model.Type === "WAR" && (
                                    <div
                                        className="flex-1 flex-container"
                                        style={{ overflow: "hidden", cursor: "pointer" }}
                                        data-bind={{
                                            click: requestForQuotationRow.model.toggleDetails.bind(
                                                requestForQuotationRow.model
                                            ),
                                        }}>
                                        <ArticleInfo
                                            code={item.Data.model.EntityCode}
                                            description={item.Data.model.EntityDescription}
                                        />
                                    </div>
                                )}
                                {(item.Data.model.Type === "WPC" || item.Data.model.Type === "PRC") && (
                                    <div
                                        className="flex-1 flex-container"
                                        style={{ overflow: "hidden", cursor: "pointer" }}
                                        data-bind={{
                                            click: requestForQuotationRow.model.toggleDetails.bind(
                                                requestForQuotationRow.model
                                            ),
                                        }}>
                                        <PurchaseInfo
                                            code={item.Data.model.EntityCode}
                                            description={item.Data.model.EntityDescription}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Q.tà" className="text-right">
                    <span data-bind={{ numberText: requestForQuotationRow.model.Amount, selectOnFocus: {} }} />
                    &nbsp;
                    <span data-bind={{ text: requestForQuotationRow.model.UoM || "N/D" }} />
                </Column>
                <Column title="Data cons." tooltip="Data di Consegna Richiesta">
                    <span data-bind={{ dateText: requestForQuotationRow.model.DeliveryDate }} />
                </Column>
                <Column title="Prezzo conf." tooltip="Prezzo Confermato dal Fornitore" className="text-right">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <MoneyInput
                                simple
                                placeholder="Prezzo conf."
                                value={item.Data.model.ResponsePrice}
                                selectOnFocus
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Q.tà conf." tooltip="Quantità Confermata dal Fornitore" className="text-right">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <NumberInput
                                simple
                                placeholder="Q.tà conf."
                                value={item.Data.model.ResponseAmount}
                                readOnly={item.Data.model.HasMultipleShipments}
                                selectOnFocus
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Data cons. eff." tooltip="Data di Consegna Effettiva">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <DateTimeInput
                                allowClear
                                simple
                                dateonly
                                placeholder="Cons. eff."
                                value={item.Data.model.ResponseDeliveryDate}
                                readonly={item.Data.model.HasMultipleShipments}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Data risp." tooltip="Data di Risposta">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <DateTimeInput
                                allowClear
                                simple
                                dateonly
                                placeholder="Data risp."
                                value={item.Data.model.ResponseDate}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Fine val." tooltip="Data di Fine Validità">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <DateTimeInput
                                allowClear
                                simple
                                dateonly
                                placeholder="Data fine val."
                                value={item.Data.model.DueDate}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column id={43} style={{ width: "28px" }} className="text-center">
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <RequestForQuotationResponseStateHelper response={item.Data.model} />
                        )}
                    </ColumnBody>
                </Column>
                <Column title="Verificato" style={{ width: "80px", textAlign: "right" }}>
                    <ColumnBody>
                        {(item: ITableItem<RequestForQuotationRow>) => (
                            <CheckBox
                                simple
                                checked={item.Data.model.Verified}
                                switch
                                switchSize="mini"
                                switchLabels={{ onLabel: "SI", offLabel: "NO" }}
                                switchColors={{ onColor: "success", offColor: "default" }}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column headerCssClasses="actions-col" style={{ width: "75px" }}>
                    <ColumnBody>
                        {() => (
                            <div class="flex-container flex-child-center">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-xs btn-circle btn-icon-only"
                                    title="Visualizza informazioni aggiuntive"
                                    data-bind={{
                                        click: _rfqre.showPreOrderRowInfo.bind(_rfqre, requestForQuotationRow.model),
                                    }}>
                                    <i class="fa fa-info"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-xs btn-circle btn-icon-only"
                                    title="Rimuovi"
                                    data-bind={{
                                        click: _rfqre.removeRow.bind(_rfqre, requestForQuotationRow.model),
                                        tooltip: { placement: "left" },
                                    }}>
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <SecondaryRow if={() => "requestForQuotationRow.model.DetailsShown"}>
                    {(item) => this.renderSecondaryRow(item)}
                </SecondaryRow>
            </Table>
        );
    }

    private renderSecondaryRow(item: ITableItem<RequestForQuotationRow>) {
        let requestForQuotationRow: IArrayDataSourceModel<RequestForQuotationRow>;

        return (
            <td colSpan={13}>
                <div className="with-identifier">
                    <span
                        class="identifier"
                        data-bind={{
                            style: {
                                "background-color": requestForQuotationRow.model.Type === "WAR" ? "#43b545" : "#56b7f7",
                            },
                        }}></span>
                    <div class="flex-1 flex-container no-gutters">
                        <div style={{ width: "21px", borderRight: "1px solid #ddd" }}></div>
                        <div class="flex-1">
                            <AccordionSecondaryRow>
                                <AccordionSecondaryRow.Section
                                    label="Consegne"
                                    title="Apri/Chiudi Consegne"
                                    element={() => (
                                        <RequestForQuotationRowShipmentEditor
                                            shipments={item.Data.model.ShipmentDates}
                                            deliveryDate={item.Data.model.ResponseDeliveryDate}
                                            hasChanges={this.props.hasChanges}
                                        />
                                    )}
                                />
                                <AccordionSecondaryRow.Section
                                    label="Note"
                                    title="Apri/Chiudi Note"
                                    element={() => (
                                        <RichTextInput
                                            simple
                                            inline
                                            placeholder="Inserisci le note..."
                                            value={item.Data.model.Note}
                                            style={{ borderLeft: "none", borderTop: "none" }}
                                        />
                                    )}
                                />
                            </AccordionSecondaryRow>
                        </div>
                    </div>
                </div>
            </td>
        );
    }
}
