import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import {
    IProtocolDefaultValuesProvider,
    IProtocolSetting,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { ICustomer, IProtocolSettingsForCustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrder, IProtocolDefaultValueForJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class ProtocolDefaultValuesProviderByJobOrder implements IProtocolDefaultValuesProvider {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;

    public Priority: number;

    constructor() {
        this.Priority = 20;
    }

    async GetSettings(
        protocolId: string,
        customerId: number,
        organizationalUnitId: number,
        jobOrderId: number | string
    ): Promise<IProtocolSetting[]> {
        if (!jobOrderId || jobOrderId == "0") return [];

        const numericJobOrderId = parseInt(jobOrderId.toString());

        const jobOrder = await this.jobOrdersService.get(numericJobOrderId);
        return this.GetSettingsSync(protocolId, null, organizationalUnitId, jobOrder);
    }

    GetSettingsSync(
        protocolId: string,
        customer: ICustomer,
        organizationalUnitId: number,
        jobOrder: IJobOrder
    ): IProtocolSetting[] {
        if (!jobOrder) return [];

        const protocolMatches: IProtocolSettingsForCustomer[] = jobOrder
            ? jobOrder.ProtocolsSettings.filter((s: IProtocolSettingsForCustomer) => {
                  return s.DefaultValuesEnabled;
              })
            : [];

        const settings: IProtocolDefaultValueForJobOrder[] =
            jobOrder && protocolMatches.length > 0
                ? jobOrder.ProtocolsDefaultValues.filter((s: IProtocolSetting) => {
                      return s.ProtocolId == protocolId;
                  })
                : [];

        settings.forEach(
            (s: IProtocolDefaultValueForJobOrder) =>
                (s.Source = ProlifeSdk.TextResources.Invoices.JobOrderProtocolDefaultValue)
        );

        if (jobOrder.DefaultIvaMode) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.IvaSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.IvaSettingKey,
                    IntValue: jobOrder.DefaultIvaMode,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultIvaMode;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }

        if (jobOrder.DefaultCurrency) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.CurrencySettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.CurrencySettingKey,
                    IntValue: jobOrder.DefaultCurrency,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultCurrency;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }

        if (jobOrder.DefaultPaymentExpiryTypeId) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActiveExpireModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActiveExpireModeSettingKey,
                    IntValue: jobOrder.DefaultPaymentExpiryTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultPaymentExpiryTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }

        if (jobOrder.DefaultPaymentTypeId) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentModeSettingKey,
                    IntValue: jobOrder.DefaultPaymentTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });

                this.setDefaultPaymentAccountId(jobOrder, settings);
                this.setDefaultPaymentAbi(jobOrder, settings);
                this.setDefaultPaymentCab(jobOrder, settings);
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultPaymentTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;

                this.setDefaultPaymentAccountId(jobOrder, settings);
                this.setDefaultPaymentAbi(jobOrder, settings);
                this.setDefaultPaymentCab(jobOrder, settings);
            }
        }

        if (jobOrder.DefaultOffset) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PaymentOffsetSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PaymentOffsetSettingKey,
                    IntValue: jobOrder.DefaultOffset,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultOffset;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }

        if (jobOrder.DefaultOutcome) {
            const settingMatches: IProtocolDefaultValueForJobOrder[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.WorkflowOutcomeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.WorkflowOutcomeSettingKey,
                    IntValue: jobOrder.DefaultOutcome,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultOutcome;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }

        return settings;
    }

    private setDefaultPaymentAccountId(jobOrder: IJobOrder, settings: IProtocolDefaultValueForJobOrder[]) {
        if (jobOrder.DefaultPaymentAccountId) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentAccountSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentAccountSettingKey,
                    IntValue: jobOrder.DefaultPaymentAccountId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = jobOrder.DefaultPaymentAccountId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }
    }

    private setDefaultPaymentAbi(jobOrder: IJobOrder, settings: IProtocolDefaultValueForJobOrder[]) {
        if (jobOrder.DefaultPaymentABI) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentABISettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentABISettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: jobOrder.DefaultPaymentABI,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = jobOrder.DefaultPaymentABI;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }
    }

    private setDefaultPaymentCab(jobOrder: IJobOrder, settings: IProtocolDefaultValueForJobOrder[]) {
        if (jobOrder.DefaultPaymentCAB) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentCABSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentCABSettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: jobOrder.DefaultPaymentCAB,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = jobOrder.DefaultPaymentCAB;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.JobOrderDefaultValue;
            }
        }
    }
}
