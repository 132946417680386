import {
    IDefaultRoleNew,
    IDefaultWorkTimeCategoryNew,
    IEstimateBudgetRow,
    IEstimateBudgetRowNew,
    ITaskResource,
    ITodoListResourceNew,
    ITodoListTask,
    ITodoListTaskLink,
    ITodoListTaskLinkNew,
    ITodoListTaskNew,
    ITodoListTaskTag,
    ITodoListTaskTagNew,
} from "../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDefaultRole, IDefaultWorkTimeCategory } from "../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";

export class TodolistTaskMapper {
    public static toITodoListTask(source: ITodoListTaskNew): ITodoListTask {
        return {
            Id: source.id,
            Title: source.title,
            Status: source.status,
            Description: source.description,
            Duration: source.duration,
            Deleted: source.deleted,
            Priority: source.priority,
            InQuality: source.inQuality,
            ReportingType: source.reportingType,
            Optional: source.optional,
            HideFromSuggestions: source.hideFromSuggestions,
            ActivitiesProgressAmountMode: source.activitiesProgressAmountMode,
            Multiplier: source.multiplier,
            MultiplierUnitOfMeasure: source.multiplierUnitOfMeasure,
            HasAttachments: false, // sembrerebbe non essere utilizzato
            IsAllocated: false, // sembrerebbe non essere utilizzato
            IsEstimated: false, // sembrerebbe non essere utilizzato
            DefaultWorkPlace: source.defaultWorkPlace,
            DefaultTravelDistance: source.defaultTravelDistance,
            DefaultCallRight: source.defaultCallRight,
            WorkedHoursDefaultsStrictMode: source.workedHoursDefaultsStrictMode,
            HideAdministrativeFieldsOnWorkedHours: source.hideAdministrativeFieldsOnWorkedHours,
            StartDate: source.startDate,
            DueDate: source.dueDate,
            RelatedWorkFlow: source.relatedWorkFlow,
            ProgressAvg: source.progressAvg,
            TaskBoardStatus: source.taskBoardStatus,
            StateFolder: source.stateFolder,
            CreatedBy: null, // sembrerebbe non essere utilizzato
            Locked: false, // sembrerebbe non essere utilizzato
            BudgetRequestCause: source.budgetRequestCause,
            EstimatedBudget: source.estimatedBudget,
            RemainingWork: source.remainingWork,
            IsMilestone: source.isMilestone,
            SourceTemplateTaskId: source.sourceTemplateTaskId,
            LastModificationDate: source.lastModificationDate,

            Links: source.links.map((l) => TodolistTaskMapper.toITodoListTaskLink(l, source)),
            EstimatedBudgetRows: source.estimatedBudgetRows.map((e) =>
                TodolistTaskMapper.toIEstimateBudgetRow(e, source)
            ),
            Tags: source.tags.map((t) => TodolistTaskMapper.toITodoListTaskTag(t, source)),
            Resources: source.resources.map((r) => TodolistTaskMapper.toITaskResource(r, source)),
            WorkedHoursDefaultRoles: source.workedHoursDefaultRoles.map((r) =>
                TodolistTaskMapper.toIDefaultRole(r, source)
            ),
            WorkedHoursDefaultWorkTimeCategories: source.workedHoursDefaultWorkTimeCategories.map((c) =>
                TodolistTaskMapper.toIDefaultWorkTimeCategory(c, source)
            ),
            OperationalUnitsRemainingWork: [], // usato solo dal client
        };
    }

    public static toITodoListTaskLink(source: ITodoListTaskLinkNew, task: ITodoListTaskNew): ITodoListTaskLink {
        return {
            Id: source.id,
            TaskId: task.id,
            Type: source.type,
            ReferenceId: source.referenceId,
        };
    }

    public static toIEstimateBudgetRow(source: IEstimateBudgetRowNew, task: ITodoListTaskNew): IEstimateBudgetRow {
        return {
            Id: source.id,
            TaskId: task.id,
            Description: source.description,
            UnitPrice: source.unitPrice,
            Amount: source.amount,
            Price: source.price,
            Type: source.type,
            EntityKeyId: source.entityKeyId,
            Multiplier: source.multiplier,
            UnitCost: source.unitCost,
            Cost: source.cost,
            Markup: source.markup,
            Discount: source.discount,
            Formula: source.formula,
            NetUnitPrice: source.netUnitPrice,
            DiscountValues: source.discountValues,
            IsCompound: source.isCompound,
            FKState: source.fkState,
            Notes: source.notes,
            Order: source.order,
        };
    }

    public static toITodoListTaskTag(source: ITodoListTaskTagNew, task: ITodoListTaskNew): ITodoListTaskTag {
        return {
            TaskId: task.id,
            Type: source.type,
            Value: source.value,
            Field: source.field,
            DisplayName: source.displayName,
        };
    }

    public static toITaskResource(source: ITodoListResourceNew, task: ITodoListTaskNew): ITaskResource {
        return {
            TaskId: task.id,
            ElementType: source.elementType,
            ElementId: source.elementId,
        };
    }

    public static toIDefaultRole(source: IDefaultRoleNew, task: ITodoListTaskNew): IDefaultRole {
        return {
            RoleId: source.roleId,
            Order: source.order,
        };
    }

    public static toIDefaultWorkTimeCategory(
        source: IDefaultWorkTimeCategoryNew,
        task: ITodoListTaskNew
    ): IDefaultWorkTimeCategory {
        return {
            WorkTimeCategoryId: source.workTimeCategoryId,
            Order: source.order,
        };
    }
}
