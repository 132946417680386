import * as ko from "knockout";
/**
 * Created by d.bucchi on 15/06/2017.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { _WorkedHoursEditor } from "./editor/WorkedHoursEditor";
import { WorkSheetRow } from "./WorkSheetRow";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IHumanResource, IHumanResourceOrders } from "../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkSheetRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import { ITodoListTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkedHoursService, IPreferredActivityWithNote, IWorkPlace } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkTimeCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategory } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { IWorkableElement } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkableElement";
import { IJobOrderRolesPrices } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";

export class WorkSheetRowTemp
{
    workedHoursService: IWorkedHoursService;
    humanResourcesService: IHumanResourcesSettingsManager;
    workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    rolesManager: IUserCharactersSettingsManager;
    jobOrderService: IJobOrderService;
    dialogsService: IDialogsService;

    serverData : IWorkSheetRow = null;

    //Enumeratori
    WorkPlaces: ko.ObservableArray<IWorkPlace> = ko.observableArray([]);
    Roles: ko.ObservableArray<IUserCharacter> = ko.observableArray([]);
    WorkTimeCategories: ko.ObservableArray<IWorkTimeCategory> = ko.observableArray([]);
    JobOrderTypeCode  = "JOR";
    ProjectTypeCode  = "PRJ";
    WorkItemTypeCode  = "WIT";
    TaskTypeCode  = "TSK";
    JobOrderTaskTypeCode  = "JTK";

    //Campi
    Id : number = null;
    ResourceId: ko.Observable<number> = ko.observable();
    ResourceName: ko.Observable<string> = ko.observable();
    WorkDay : Date = null;
    WorkActivitiesContainerType: ko.Observable<string> = ko.observable();
    WorkActivitiesContainerId: ko.Observable<number> = ko.observable();
    WorkActivitiesContainerDescr : ko.Observable<string> = ko.observable();
    WorkActivityType: ko.Observable<string> = ko.observable();
    WorkActivityId: ko.Observable<number> = ko.observable();
    WorkActivityDescr: ko.Observable<string> = ko.observable();
    WorkActivityWorkflow : ko.Observable<any> = ko.observable();

    IsStarrable : ko.Computed<boolean>;
    ProjectId : ko.Computed<number>;
    JobOrderId: ko.Computed<number>;
    WorkItemId: ko.Computed<number>;
    TaskId: ko.Computed<number>;
    WorkPlaceId: ko.Observable<number> = ko.observable(0);
    WorkPlaceDescr : ko.Computed<string>;
    RoleId: ko.Observable<number> = ko.observable();
    RoleDescr: ko.Computed<string>;
    WorkTimeCategoryId: ko.Observable<number> = ko.observable();
    WorkTimeCategoryDescr: ko.Computed<string>;
    CallRight : ko.Observable<boolean> = ko.observable(false);
    BreakHours : ko.Observable<number> = ko.observable(0);
    TravelDistance : ko.Observable<number> = ko.observable(0);

    Billable : ko.Observable<boolean> = ko.observable(true);
    BillableInit : ko.Computed<boolean>;

    Progress : ko.Computed<number>;
    ProgressInHours : ko.Computed<number>;

    WorkedHours : ko.Observable<number> = ko.observable(0);

    EstimatedWork : ko.Observable<number> = ko.observable(0);
    EstimatedWorkInit : ko.Computed<number>;

    OrdinaryHours : ko.Observable<number> = ko.observable(0);
    OriginalOrdinaryHours : ko.Observable<number> = ko.observable(0);
    OriginalProgress : ko.Observable<number> = ko.observable(0);
    OriginalEstimatedWork : ko.Observable<number> = ko.observable(0);

    WorkedHoursMinusOriginal: ko.Computed<number>;

    Notes : ko.Observable<string> = ko.observable();

    //Flags
    IsDeleted : ko.Observable<boolean> = ko.observable(false);
    IsPreferred : ko.Observable<boolean> = ko.observable(false);
    IsInitializedFromPreferred : ko.Observable<boolean> = ko.observable(false);

    IsApproved: ko.Observable<boolean> = ko.observable();
    IsReadOnly: ko.Observable<boolean> = ko.observable();


    IsUncomplete : ko.Computed<boolean>;
    IsJobOrderSelected: ko.Computed<boolean>;
    IsProjectSelected: ko.Computed<boolean>;
    IsActivityContainerReadOnly: ko.Computed<boolean>;
    IsActivityReadOnly: ko.Computed<boolean>;
    IsWorkableActivitySelected: ko.Computed<boolean>;  //Vale true se Ã¨ stato selezionato un elemento sul quale si possono inserire le ore (Task, WI o Commessa)
    IsTaskSelected: ko.Computed<boolean>;

    WorkHasChanged: ko.Computed<boolean>;
    CanSetProgressInHours: ko.Computed<boolean>;
    CanEditEstimate : ko.Observable<boolean> = ko.observable(false);

    HasActivityContainerFieldFocus : ko.Observable<boolean> = ko.observable(false);
    HasActivityFieldFocus : ko.Observable<boolean> = ko.observable(false);
    HasNotesFieldFocus : ko.Observable<boolean> = ko.observable(false);

    PreferredActivities: ko.ObservableArray<IPreferredActivityWithNote> = ko.observableArray([]);
    SelectedActivityIsPreferred: ko.Computed<boolean>;

    IsDetailShowed: ko.Observable<boolean> = ko.observable(false);

    private resource: IHumanResource;
    resourceId: number;
    private initializing  = false;
    private progressInterceptor: ko.Computed<void>;

    WillSeeDetails: ko.Observable<boolean> = ko.observable(false);

    observers: any[] = [];
    SlideRightEnabled: ko.Observable<boolean> = ko.observable(true);
    SlideLeftEnabled: ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator: IServiceLocator, workSheet: _WorkedHoursEditor, workSheetRow: WorkSheetRow) {

        const settingsService: ISettingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.rolesManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
        this.workedHoursService = <IWorkedHoursService> serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        this.workTimeCategoriesManager = <IWorkTimeCategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        workSheetRow.Roles().forEach((role: IUserCharacter) => {
            this.Roles().push(role);
        });

        workSheetRow.WorkPlaces().forEach((workPlace) => {
            this.WorkPlaces().push(workPlace);
        });

        workSheetRow.WorkTimeCategories().forEach((workTimeCategory: IWorkTimeCategory) => {
            this.WorkTimeCategories().push(workTimeCategory);
        });

        workSheetRow.PreferredActivities().forEach((preferredActivity: IPreferredActivityWithNote) => {
            this.PreferredActivities().push(preferredActivity);
        });

        this.resourceId = workSheetRow.ResourceId();
        this.resource = this.humanResourcesService.getHumanResourceById(this.resourceId);
        this.ResourceName(this.humanResourcesService.getFullName(this.resource));

        this.WorkActivitiesContainerDescr(workSheetRow.WorkActivitiesContainerDescr());
        this.WorkActivitiesContainerType(workSheetRow.WorkActivitiesContainerType());
        this.WorkActivitiesContainerId(workSheetRow.WorkActivitiesContainerId())

        this.WorkActivityDescr(workSheetRow.WorkActivityDescr());
        this.WorkActivityWorkflow(workSheetRow.WorkActivityWorkflow());
        this.WorkActivityType(workSheetRow.WorkActivityType());
        this.WorkActivityId(workSheetRow.WorkActivityId());

        this.WorkedHours(workSheetRow.WorkedHours());
        this.OrdinaryHours(workSheetRow.OrdinaryHours());
        this.OriginalOrdinaryHours(workSheetRow.OriginalOrdinaryHours());
        this.EstimatedWork(workSheetRow.EstimatedWork());
        this.CanEditEstimate(workSheetRow.CanEditEstimate());

        this.WillSeeDetails(workSheetRow.WillSeeDetails());

        this.RoleId(workSheetRow.RoleId());

        this.WorkTimeCategoryId(workSheetRow.WorkTimeCategoryId());
        this.WorkPlaceId(workSheetRow.WorkPlaceId());
        this.Billable(workSheetRow.Billable());
        this.CallRight(workSheetRow.CallRight());
        this.TravelDistance(workSheetRow.TravelDistance());
        this.Notes(workSheetRow.Notes());

        this.Id = workSheetRow.Id;
        this.ResourceId(workSheetRow.ResourceId());
        this.ResourceName(workSheetRow.ResourceName());
        this.WorkDay = workSheetRow.WorkDay;
        this.BreakHours(workSheetRow.BreakHours());

        this.OriginalProgress(workSheetRow.OriginalProgress());
        this.OriginalEstimatedWork(workSheetRow.OriginalEstimatedWork());

        this.IsDeleted(workSheetRow.IsDeleted());
        this.IsPreferred(workSheetRow.IsPreferred());
        this.IsInitializedFromPreferred(workSheetRow.IsInitializedFromPreferred());

        this.HasActivityContainerFieldFocus(workSheetRow.HasActivityContainerFieldFocus());
        this.HasActivityFieldFocus(workSheetRow.HasActivityFieldFocus());
        this.HasNotesFieldFocus(workSheetRow.HasNotesFieldFocus());

        this.IsDetailShowed(workSheetRow.IsDetailShowed());

        this.IsApproved(workSheetRow.IsApproved());
        this.IsReadOnly(workSheetRow.IsReadOnly());

        this.WorkTimeCategoryId.subscribe((id) => {
            if (!id)
                return;

            const c: IWorkTimeCategory = this.workTimeCategoriesManager.getById(id);
            if (c && this.WorkTimeCategories().indexOf(c) == -1)
                this.WorkTimeCategories.push(c);
        });

        this.RoleId.subscribe((id) => {
            if (!id)
                return;

            const matches = this.rolesManager.getUserCharactersWithDeleted().filter((r: IUserCharacter) => {
                return r.IdUserCharacter == id;
            });
            const c: IUserCharacter = matches.length > 0 ? matches[0] : null;
            if (c && this.Roles().indexOf(c) == -1)
                this.Roles.push(c);

            //Al variare della mansione imposto la tipologia oraria predefinita
            if (c && c.DefaultWorkTimeCategory > 0 && this.isWorkTimeCategoryValidForWorkSheet(c.DefaultWorkTimeCategory))
                this.WorkTimeCategoryId(c.DefaultWorkTimeCategory);
        });

        this.JobOrderId = ko.computed(() => {
            return this.WorkActivitiesContainerType() == this.JobOrderTypeCode &&
                this.WorkActivitiesContainerId() ? this.WorkActivitiesContainerId() : null;
        });

        this.JobOrderId.subscribe(this.loadRolesIfNotInitializing.bind(this));

        this.IsJobOrderSelected = ko.computed(() => {
            return this.JobOrderId() != null;
        });

        this.TaskId = ko.computed(() => {
            return (this.WorkActivityType() == this.TaskTypeCode || this.WorkActivityType() == this.JobOrderTaskTypeCode) &&
                this.WorkActivityId() ? this.WorkActivityId() : null;
        });

        this.WorkItemId = ko.computed(() => {
            return this.WorkActivityType() == this.WorkItemTypeCode &&
                this.WorkActivityId() ? this.WorkActivityId() : null;
        });

        this.IsWorkableActivitySelected = ko.computed(() => {
            return this.IsJobOrderSelected() || !!this.WorkItemId() || !!this.TaskId();
        });

        this.IsWorkableActivitySelected.subscribe((value: boolean) => {
            if (value)
                this.HasNotesFieldFocus(value);
        });

        this.IsUncomplete = ko.computed(() => {
            return this.OrdinaryHours() == 0 ||
                this.WorkActivitiesContainerId() == null ||
                !this.IsWorkableActivitySelected();
        });

        this.WorkPlaceDescr = ko.computed(() => {
            if (this.WorkPlaceId() == null)
                return "";
            const matches = this.WorkPlaces().filter((p) => {
                return p.Id == this.WorkPlaceId();
            })
            return matches.length > 0 ? matches[0].Description : "";
        });

        this.RoleDescr = ko.computed(() => {
            if (this.RoleId() == null)
                return "";
            const matches = this.Roles().filter((p: IUserCharacter) => {
                return p.IdUserCharacter == this.RoleId();
            })
            return matches.length > 0 ? matches[0].Description : "";
        });

        this.WorkTimeCategoryDescr = ko.computed(() => {
            if (this.WorkTimeCategoryId() == null)
                return "";
            const matches = this.WorkTimeCategories().filter((c: IWorkTimeCategory) => {
                return c.Id == this.WorkTimeCategoryId();
            })
            return matches.length > 0 ? matches[0].Name : "";
        });

        this.ProjectId = ko.computed({
            read: () => {
                return this.WorkActivitiesContainerType() == this.ProjectTypeCode &&
                    this.WorkActivitiesContainerId() ? this.WorkActivitiesContainerId() : null;
            },

            write: (value) => {
                this.WorkActivitiesContainerType(this.ProjectTypeCode);
                this.WorkActivitiesContainerId(value);
            }
        });

        this.IsProjectSelected = ko.computed(() => {
            return this.ProjectId() != null;
        });

        this.IsActivityContainerReadOnly = ko.computed(() => {
            return this.WorkActivitiesContainerId() != null;
        });

        this.IsActivityReadOnly = ko.computed(() => {
            return this.WorkActivityId() != null;
        });

        this.IsProjectSelected.subscribe((value) => {
            if (value)
                this.HasActivityFieldFocus(value);
        });

        this.SelectedActivityIsPreferred = ko.computed(() => {

            return this.PreferredActivities().filter((pa: IPreferredActivityWithNote) => {
                return (this.TaskId() && pa.taskId == this.TaskId())
                    || (this.WorkItemId() && pa.workItemId == this.WorkItemId());
            }).length > 0;

        });

        this.SelectedActivityIsPreferred.subscribe((isPreferred) => {
            this.IsPreferred(isPreferred);
        });

        this.IsStarrable = ko.computed(() => {
            return this.WorkActivitiesContainerType() == this.ProjectTypeCode && !!this.WorkActivityId();
        });

        this.IsTaskSelected = ko.computed(() => {
            return this.WorkActivityType() == this.JobOrderTaskTypeCode && this.TaskId() > 0;
        });

        this.WorkHasChanged = ko.computed(() => {
            return this.OriginalOrdinaryHours() != this.OrdinaryHours();
        });

        this.CanSetProgressInHours = ko.computed(() => {
            return (this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours()) == 0;
        });

        this.WorkedHoursMinusOriginal = ko.computed(() => {
            return this.WorkedHours() - this.OriginalOrdinaryHours();
        });

        const hasUserSetProgressInHours: ko.Observable<boolean> = ko.observable(false);
        const hasProgressSetProgressInHours: ko.Observable<boolean> = ko.observable(true);
        const userSetProgressInHoursField: ko.Observable<number> = ko.observable(0);
        const progressSetProgressInHoursField: ko.Observable<number> = ko.observable(0);
        const progressInHoursField: ko.Observable<number> = ko.observable();

        this.progressInterceptor = ko.computed(() => {
            if (hasUserSetProgressInHours())
                progressInHoursField(userSetProgressInHoursField());
            else if (hasProgressSetProgressInHours())
                progressInHoursField(progressSetProgressInHoursField());
            else
                progressInHoursField(Math.max(0, this.EstimatedWork() - this.WorkedHours() - this.OrdinaryHours()));
        }, this);

        this.ProgressInHours = ko.computed({
            read: <() => number>progressInHoursField,
            write: (value: number) => {
                if (this.initializing) {
                    hasUserSetProgressInHours(false);
                    hasProgressSetProgressInHours(true);
                    progressSetProgressInHoursField(value);
                } else {
                    hasUserSetProgressInHours(true);
                    hasProgressSetProgressInHours(false);
                    userSetProgressInHoursField(value);
                }
            },
            owner: this
        });

        const progressField = ko.observable();
        const progressInterceptor = ko.computed(() => {
            const totalWork = (this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours());
            const totalEstimate = (totalWork + this.ProgressInHours());
            if (totalEstimate == 0)
                return; //100
            progressField(Math.min((totalWork / totalEstimate) * 100.0, 99.9));

            const progressInHours = Math.max(0, this.EstimatedWork() - this.WorkedHours() - this.OrdinaryHours());
            progressSetProgressInHoursField(progressInHours);
        }, this);

        this.Progress = ko.computed({
            read: <() => number>progressField,
            write: (value: number) => {
                let progressInHours = this.EstimatedWork();
                if (value == 100)
                    progressInHours = 0;
                else if (value > 0) {
                    const totalWork = (this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours());
                    progressInHours = ((totalWork) * (100 - value)) / value;
                }

                progressField(value);
                progressSetProgressInHoursField(progressInHours);
                hasProgressSetProgressInHours(true);
                hasUserSetProgressInHours(false);
            },
            deferEvaluation: true,
            owner: this
        }).extend({ rateLimit: 50 });

        this.BillableInit = ko.computed({
            read: <() => boolean>this.Billable,
            write: (value: boolean) => {
                if (!this.IsInitializedFromPreferred() && this.serverData && this.serverData.HoursId > 0 &&
                    this.serverData.ActivityContainerType == this.WorkActivitiesContainerType() && this.serverData.ActivityType == this.WorkActivityType() &&
                    this.serverData.JobOrderId == this.WorkActivitiesContainerId() && this.serverData.TaskId == this.WorkActivityId())
                    return;
                this.Billable(value);
            }
        });

        this.EstimatedWorkInit = ko.computed({
            read: <() => number>this.EstimatedWork,
            write: (value: number) => {
                this.EstimatedWork(value);
                if (this.WorkedHours() == 0 && this.OrdinaryHours() == 0 && this.ProgressInHours() == 0)
                    progressSetProgressInHoursField(value);
            },
            owner: this
        });

        this.IsTaskSelected.subscribe((v) => {
            if (v)
                this.Refresh();
        });

    }

    public registerObserver(observer: any) {
        this.observers.push(observer);
    }

    public Refresh() {
        if (this.IsTaskSelected()) {
            this.workedHoursService.GetWorkableElement(this.TaskId(), ProlifeSdk.JobOrderTaskEntityTypeCode)
                .then((we: IWorkableElement) => {
                    if (!we)
                        return;

                    this.WorkedHours(we.WorkedHours);
                    this.CanEditEstimate(we.CanEditEstimate);

                    const todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
                    todoListService.GetTaskById(this.TaskId())
                        .then((t: ITodoListTask) => {
                            const oldInitializing = this.initializing;
                            this.initializing = true;

                            this.OriginalEstimatedWork(t.Duration || 0);
                            this.OriginalProgress(t.ProgressAvg || 0);
                            this.ProgressInHours(t.RemainingWork || 0);

                            this.initializing = oldInitializing;
                        });
                });
        }
    }

    public GetIconForCurrentActivity() {
        return this.GetActivityTypeIcon(this.WorkActivityType());
    }

    public GetActivityTypeIcon(type: string) {
        let icon = null;
        icon = type == this.TaskTypeCode ? "fa-clipboard" : icon;
        icon = type == this.JobOrderTaskTypeCode ? "fa-clipboard" : icon;
        icon = type == this.WorkItemTypeCode ? "fa-wrench" : icon;
        return icon;
    }

    SwitchMarkAsDeleted() {
        this.IsDeleted(!this.IsDeleted());
    }

    OpenDialogForConfirmDeleteContainer(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.WorkedHours.WarningDeleteProject, ProlifeSdk.TextResources.WorkedHours.No,
            ProlifeSdk.TextResources.WorkedHours.Ok, (confirm: boolean) => {
                if (!confirm)
                    return;
                this.EditContainer();
            });
    }

    OpenDialogForConfirmDeleteActivity(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.WorkedHours.WarningDeleteActivity, ProlifeSdk.TextResources.WorkedHours.No,
            ProlifeSdk.TextResources.WorkedHours.Ok, (confirm: boolean) => {
                if (!confirm)
                    return;
                this.EditActivity();
            });
    }

    EditContainer()
    {
        this.WorkActivitiesContainerType(null);
        this.WorkActivitiesContainerId(null);
        this.WorkActivitiesContainerDescr(null);
        this.EditActivity();
        this.HasActivityContainerFieldFocus(true);
    }

    EditActivity()
    {
        this.WorkActivityType(null);
        this.WorkActivityId(null);
        this.WorkActivityDescr(null);
        this.WorkActivityWorkflow(null);
        this.HasActivityFieldFocus(true);
    }

    changeSeeDetails() {
        this.WillSeeDetails(!this.WillSeeDetails());
    }

    private isWorkTimeCategoryValidForWorkSheet(wtcId: number) {
        const validServiceOrderIds = this.resource.Orders.filter(o => o.FromDate <= this.WorkDay && (!o.ToDate || o.ToDate > this.WorkDay)).map(o => o.Id);
        const validWorkTimeCategoryIds = this.resource.OrdersSalaries.filter(s => validServiceOrderIds.indexOf(s.FkServiceOrder) != -1).map(s => s.FkWorkTimeCategoryId);
        return validWorkTimeCategoryIds.indexOf(wtcId) != -1;
    }

    private loadRolesIfNotInitializing(): Promise<void> {
        return this.initializing ? Promise.resolve<void>(undefined) : this.loadRoles();
    }

    private loadRoles(): Promise<void> {
        if (!this.JobOrderId()) {
            this.Roles([]);
            return Promise.resolve();
        }

        return this.jobOrderService.getRolesConfiguredOnJobOrder(this.JobOrderId()).then((jobOrderRoles: IJobOrderRolesPrices[]) => {
            const jobOrderRolesIds: number[] = jobOrderRoles.map((r: IJobOrderRolesPrices) => { return r.FkUserCharacterId; })

            const ouIds: number[] = this.resource.Orders.filter((o: IHumanResourceOrders) => {
                //Prendo solo gli ordini validi nella data dello statino
                return moment(o.FromDate).isSameOrBefore(moment(this.WorkDay)) && (!o.ToDate || moment(o.ToDate).isSameOrAfter(moment(this.WorkDay)))
            }).map((o: IHumanResourceOrders) => { return o.FkOperationalUnit; });

            const roles: IUserCharacter[] = this.rolesManager.getUserCharactersWithDeleted().filter((c: IUserCharacter) => {
                return ouIds.indexOf(c.FkOperationalUnit) > -1 && jobOrderRolesIds.indexOf(c.IdUserCharacter) > -1;
            });

            if (!this.RoleId() && roles.filter((r) => r.IdUserCharacter == this.resource.Resource.FkDefaultCharacter).length > 0)
                this.RoleId(this.resource.Resource.FkDefaultCharacter);

            this.Roles(roles);
        });
    }
}

