import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";

export class ValidatedValue {
    private static lastValue: string = "";

    @LazyImport(nameof<IInfoToastService>())
    private static infoToastService: IInfoToastService;

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let value = valueAccessor();

        ValidatedValue.lastValue = (value() || "");

        let valueBinding = {
            value: value
        }

        ko.applyBindingsToNode(element, valueBinding, undefined);

        let pattern = $(element).attr("pattern");
        if (pattern) {
            let regExp = new RegExp(pattern, "gm");
            let title = $(element).attr("title");
            $(element).attr("title", "");

            $(element).on("change", (e) => {
                let currentValue = $(element).val() as string;
                if (currentValue == ValidatedValue.lastValue)
                    return;

                if (!currentValue) {
                    ValidatedValue.lastValue = currentValue;
                    return;
                }

                let isValid = (currentValue.match(regExp) || []).length > 0;    
                if (!isValid) {
                    value(ValidatedValue.lastValue);
                    ValidatedValue.infoToastService.Error(title);
                    return;
                }

                ValidatedValue.lastValue = currentValue;
            });
        }
    }
}

ko.bindingHandlers["validatedValue"] = new ValidatedValue();