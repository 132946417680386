import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { BlogEventBase } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { HoursRecordEvent } from "./HoursRecordEvent";
import { ResourceHoursGroup } from "../EventsDetails/ResourceHoursGroup";
import { WorkInput } from "../EventsDetails/WorkInput";
import { IFinancialDataService } from "../../../ProlifeSdk/interfaces/financial-data/IFinancialDataService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IBlogTask } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { ITodoListTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../Core/Deferred";

export class WorkEvent extends BlogEvent implements IView
{
    protected financialDataService : IFinancialDataService;
    protected rolesService : IUserCharactersSettingsManager;
    protected todoListService : ITodoListService;

    public viewModel : any;
    public Duration : ko.Observable<number> = ko.observable();
	public Worker : ko.Observable<any> = ko.observable();
    public Role :ko.Observable<any> = ko.observable();
    
    private WorkedHours : ko.ObservableArray<HoursRecordEvent> = ko.observableArray([]);
    private WorkedHoursByResource : ko.ObservableArray<ResourceHoursGroup> = ko.observableArray([]);
    private selectedTasks : ko.ObservableArray<IBlogTask> = ko.observableArray([]);

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator,contextEventsObserver);
        this.financialDataService = <IFinancialDataService>serviceLocator.findService(ProlifeSdk.FinancialDataServiceType);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.rolesService = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.iconClass = "fa-clock-o";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.WorkTitle;
        this.templateName = "workEvent";

        this.Clear();
    }

    public BaseLoadItem(item : ILogEvent) {
        super.BaseLoadItem(item);
        this.RefreshWorkedHours();
    }

    private RefreshWorkedHours()
    {
        this.WorkedHours(<HoursRecordEvent[]> this.ChildrenEvents().filter(c => c.EventType() == ProlifeSdk.BlogEventType_WorkedHours));

        var groups = [];
        var resourcesIds = [];
        var workedHours = this.WorkedHours();

        workedHours
            .map((r) => { return parseInt(r.WorkerId()); })
            .forEach((id : number) => {
                if(resourcesIds.indexOf(id) == -1)
                {
                    var records : HoursRecordEvent [] = this.WorkedHours().filter((r1 : HoursRecordEvent) => { return parseInt(r1.WorkerId()) == id; });
                    var group = new ResourceHoursGroup(this.serviceLocator, records, this.contextEventsObserver, false, this.ReferenceDate, this.IdEvent(), this.selectedTasks, this.JobOrder);
                    groups.push(group);
                    resourcesIds.push(id);
                }
            });

        this.WorkedHoursByResource(groups);
    }

    public onChildDeleted(child: BlogEventBase)
    {
        super.onChildDeleted(child);
        this.RefreshWorkedHours();
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b + ProlifeSdk.TextResources.Blog.WorkBody;
            this.WorkedHours().forEach((h : HoursRecordEvent) => {

                var role : IUserCharacter = this.rolesService.getUserCharacterById(h.RoleId());

                body += String.format(ProlifeSdk.TextResources.Blog.WorkBodyHourLine, h.WorkerDescription(), (role ? role.Description : ""), moment(h.FromTime()).format('LT'),
                    moment(h.ToTime()).format('LT'), numeral( h.Breaks()).format("0.00"),
                        (h.Billable() ? ProlifeSdk.TextResources.Blog.Yes : ProlifeSdk.TextResources.Blog.No), (h.Billed() ? ProlifeSdk.TextResources.Blog.Yes : ProlifeSdk.TextResources.Blog.No));
            });
            def.resolve(body);
        })
        .catch(() => def.reject());
        return def.promise();
    }

    public GetMailSubject() : string
    {
        return String.format(ProlifeSdk.TextResources.Blog.WorkSubject, moment(this.ReferenceDate()).format("L"));
    }

    public deleteEvent()
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.Blog.DeleteEventMsg,
            ProlifeSdk.TextResources.Blog.DeleteEventCancel, ProlifeSdk.TextResources.Blog.DeleteEventConfirm, (dialogResult : boolean) => {
                if(dialogResult)
                    this.financialDataService.deleteWorkedHoursByEventId(this.IdEvent())
                        .then(() => {
                            this.contextEventsObserver.RefreshEvents();
                        });
            });
    }

    Clear()
    {
        super.Clear();
        this.Worker(null);
        this.Role(null);
    }


    getEditViewModel() : IEventBaseInput
    {
        return new WorkInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {
            if(item.TagName() == ProlifeSdk.TagWork_Duration){
                this.Duration(parseFloat(item.Value()));
            }
			if (item.TagName() == ProlifeSdk.TagWork_Worker){
				this.Worker(item);
			}
			if (item.TagName() == ProlifeSdk.TagWork_WorkerRole){
				this.Role(item);
			}
        });
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.WorkedHours().forEach((h : HoursRecordEvent) => {
            h.LoadDetails();
        });

        this.todoListService.GetTasksByIds(this.Tasks())
            .then((tasks : ITodoListTask[]) => {
                var tasksInfo = tasks.map((t : ITodoListTask) => {
                    return {
                        Id: t.Id,
                        Description: t.Title
                    }
                });

                this.selectedTasks(tasksInfo);
            });

        this.RefreshWorkedHours();
    }
}