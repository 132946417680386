import jsPDF from "jspdf";
import { ReportComponentWithChildren } from "../Components/ReportComponentWithChildren";
import { CentimetersToPDFUnit } from "../Utilities/UnitOfMeasureUtils";
import { ReportExporterProvider } from "./ReportExporterProvider";

export async function ExportChildren(doc: jsPDF, component: ReportComponentWithChildren, x: number, y: number) {
    doc.saveGraphicsState();

    if(x !== 0 || y !== 0) {
        const trans = doc.Matrix(1,0,0,1, CentimetersToPDFUnit(x), -CentimetersToPDFUnit(y));
        doc.setCurrentTransformationMatrix(trans);
    }

    for(let child of component.children()) {
        const exporter = ReportExporterProvider.createExporter(child);
        await exporter.export(doc, child);
    }

    doc.restoreGraphicsState();
}