import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICallRightTypesSettingsManager } from "../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../ProlifeSdk/interfaces/worked-hours/ICallRightType";

interface ICallRightTypeDescriptionOptions {
    callRightTypeId: number;
    defaultDescription: string;
    serviceLocator: IServiceLocator;
}

export class CallRightTypeDescription {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

		var interceptor = ko.computed(() => {
			var options: ICallRightTypeDescriptionOptions = valueAccessor();

			var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
			var callRightTypesSettingsManager : ICallRightTypesSettingsManager= <ICallRightTypesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.CallRightTypesSettingsManagerType);

			var element : ICallRightType = callRightTypesSettingsManager.getById(ko.utils.unwrapObservable(options.callRightTypeId));

			if (element)
				return element.Name;
			else
				return ko.utils.unwrapObservable(options.defaultDescription) || ProlifeSdk.TextResources.WorkedHours.Select;
		});

		ko.applyBindingsToNode(element, { text: interceptor }, undefined);
	}
}

ko.bindingHandlers["CallRightTypeDescription"] = new CallRightTypeDescription();
ko.virtualElements.allowedBindings["CallRightTypeDescription"] = true;

