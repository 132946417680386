import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IArrayDataSourceModel, IArrayDataSource } from "./ArrayDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { IDDTCause, IDDTCauses } from "../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";

export interface IDDTCausesDataSourceModel extends IDataSourceModel<number, IDDTCause> {

}

export class DDTCausesDataSource extends BaseDataSource<IDDTCausesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.DDTCause)
    private ddtCausesManager: IDDTCauses;

    private causesToBeShowedEvenIfDeleted: number[];
    private showDeleted: boolean;
    private showOnlySalesCauses: boolean;
    
    constructor() {
        super();
        this.causesToBeShowedEvenIfDeleted = [];
        this.showDeleted = false;
        this.showOnlySalesCauses = false;
    }

    public setShowDeleted(value: boolean): void {
        this.showDeleted = value;
    }

    public setCausesToBeShowedEvenIfDeleted(ids: number[]): void {
        this.causesToBeShowedEvenIfDeleted = ids;
    }

    public setShowOnlySalesCauses(value: boolean): void {
        this.showOnlySalesCauses = value;
    }
    
    public getTitle(currentModel: IDDTCausesDataSourceModel): string {
        return TextResources.Invoices.DDTDeliveryReasons;
    }

    public async getData(currentModel: IDDTCausesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDDTCausesDataSourceModel[]> {
        return this.ddtCausesManager
            .getDDTCauses()
            .filter(c => this.textFilterMatch(c, textFilter) && this.showDeletedMatch(c) && this.showOnlySalesCausesMatch(c))
            .slice(skip, count)
            .map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IDDTCausesDataSourceModel, ids: number[]): Promise<IDDTCausesDataSourceModel[]> {
        return this.ddtCausesManager
            .getDDTCauses()
            .filter(c => ids.indexOf(c.Id) >= 0)
            .map(this.createModelFor, this);
    }

    private createModelFor(cause: IDDTCause): IDDTCausesDataSourceModel {
        return {
            id: cause.Id,
            isGroup: false,
            isLeaf: true,
            title: cause.Descrizione,
            model: cause
        };
    }

    private textFilterMatch(reason: IDDTCause, textFilter: string): boolean {
        let words = (textFilter || "").toLowerCase().trim().split("");
        return words.filter(w => (reason.Descrizione || "").toLowerCase().indexOf(w) >= 0).length === words.length;
    }

    private showDeletedMatch(cause: IDDTCause): boolean {
        return this.showDeleted || this.causesToBeShowedEvenIfDeleted.indexOf(cause.Id) >= 0 || !cause.Eliminato;
    }

    private showOnlySalesCausesMatch(c: IDDTCause): boolean {
        return !this.showOnlySalesCauses || c.Tipologia != 3;
    }
}