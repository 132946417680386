import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { Tag } from "./Tag";
import { Attachment } from "./Attachment";
import * as moment from "moment";
import { IJobOrderService } from "../../interfaces/job-order/IJobOrderService";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IBlogService } from "../../interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../interfaces/desktop/IUserInfo";
import { ILogService } from "../../interfaces/desktop/ILogService";
import { ILogEvent } from "../../interfaces/ILogEvent";
import { IFileRepositoryService, IRefAndDocPair } from "../../interfaces/files/IFileRepositoryService";
import { IJobOrder } from "../../interfaces/job-order/IJobOrder";
import { Deferred } from "../../../Core/Deferred";

export class BlogEventBase {
    public templateName = "NOT USED";
    public templateUrl = "blog/Templates/events";
    public viewModel: any;
    public Subject: ko.Observable<string> = ko.observable();
    public IdEvent: ko.Observable<number> = ko.observable();
    public EventType: ko.Observable<string> = ko.observable();
    public Message: ko.Observable<string> = ko.observable();
    public WorkSheets: ko.Observable<any> = ko.observable();
    public EventTime: ko.Observable<Date> = ko.observable();
    public EventUser: ko.Observable<number> = ko.observable();
    public FkParentId: ko.Observable<number> = ko.observable();
    public ChildrenEvents: ko.ObservableArray<BlogEventBase> = ko.observableArray();
    public Comments: ko.Computed<BlogEventBase[]>;
    public ReferenceDate: ko.Observable<Date> = ko.observable();
    public CreatedBySystem: ko.Observable<boolean> = ko.observable();
    public Updatable: ko.Observable<boolean> = ko.observable(true); //Impostare a false per nascondere il pulsante modifica
    public Locked: ko.Observable<boolean> = ko.observable();
    public Tasks: ko.ObservableArray<number> = ko.observableArray([]);
    public JobOrder: ko.Observable<number> = ko.observable();
    public CustomMetaTags: ko.ObservableArray<Tag> = ko.observableArray();
    public AllowedUsersTags: ko.ObservableArray<Tag> = ko.observableArray();
    public Tags: ko.ObservableArray<Tag> = ko.observableArray();
    public Attachments: ko.ObservableArray<Attachment> = ko.observableArray();
    public IsSigned: ko.Observable<boolean> = ko.observable(false);
    public Signature: ko.Observable<string> = ko.observable();
    public Signers: ko.ObservableArray<Tag> = ko.observableArray([]);
    public RelatedTasks: ko.ObservableArray<string> = ko.observableArray([]);
    public originalValue: ILogEvent;
    public userService: IUserInfo;
    public logService: ILogService;
    public blogService: IBlogService;
    public jobOrderService: IJobOrderService;
    private fileRepository: IFileRepositoryService;
    public dialogService: IDialogsService;
    public authService: IAuthenticationService;
    public parent: BlogEventBase;

    public canBeDeleted: ko.Computed<boolean>;
    public isDeleteLocked: ko.Observable<boolean> = ko.observable(false);
    public isUserAuthorizedForDelete: ko.Observable<boolean> = ko.observable(false);

    public isSelected: ko.Observable<boolean> = ko.observable(false);
    public isSelectable: ko.Observable<boolean> = ko.observable(false);

    public tagsDetailsAreLoaded = false;

    public iconClass: string;
    public eventTypeName: string;

    private JobOrderName: ko.Observable<string> = ko.observable(); //Utilizzato nell'elenco eventi per mostrare il nome commessa. Da settare manualmente!

    constructor(protected serviceLocator: IServiceLocator, protected contextEventsObserver: IContextEventsObserver) {
        this.userService = <IUserInfo>serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.blogService = <IBlogService>serviceLocator.findService(ProlifeSdk.BlogServiceType);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.authService = <IAuthenticationService>serviceLocator.findService(ServiceTypes.Authentication);
        this.fileRepository = <IFileRepositoryService>(
            this.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.jobOrderService = <IJobOrderService>this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);

        this.iconClass = "default";
        this.eventTypeName = ProlifeSdk.TextResources.ProlifeSdk.UndefinedEvent;

        this.Comments = ko.computed(
            function () {
                const array = this.ChildrenEvents().filter((c) => c.EventType() == ProlifeSdk.BlogEventType_Comment);

                array.sort((a: BlogEventBase, b: BlogEventBase) => {
                    return <any>a.EventTime() - <any>b.EventTime();
                });
                return array;
            }.bind(this)
        );

        this.canBeDeleted = ko.computed(() => {
            return this.isUserAuthorizedForDelete() && !this.isDeleteLocked();
        });
    }

    public SetJobOrderName(jobOrderName: string) {
        this.JobOrderName(jobOrderName);
    }

    public GetMailBody(): Promise<string> {
        const def = new Deferred<string>();
        this.jobOrderService
            .get(this.JobOrder())
            .then((j: IJobOrder) => {
                const body = String.format(
                    ProlifeSdk.TextResources.ProlifeSdk.BlogBaseMailBody,
                    this.Subject(),
                    moment(this.ReferenceDate()).format("L"),
                    j ? String.format(ProlifeSdk.TextResources.ProlifeSdk.BlogBaseMailReferenceJobOrder, j.Name) : "",
                    j
                        ? String.format(
                              ProlifeSdk.TextResources.ProlifeSdk.BlogBaseMailDescription,
                              $(this.Message()).text()
                          )
                        : ""
                );
                def.resolve(body);
            })
            .catch(() => def.reject());
        return def.promise();
    }

    public GetMailSubject(): string {
        return ProlifeSdk.TextResources.ProlifeSdk.BlogBaseMailSubject;
    }

    public SendByMail() {
        const attachmentsIds: string[] = this.Attachments().map((a: Attachment) => a.id);
        this.fileRepository.createFilesReferencesToSendByMail(attachmentsIds).then((references: IRefAndDocPair[]) => {
            this.GetMailBody().then((b: string) => {
                let body = b + ProlifeSdk.TextResources.ProlifeSdk.BlogMailAttachments;
                body += references.length == 0 ? "%20-%20%0A" : "%0A";

                references.forEach((r: IRefAndDocPair) => {
                    body +=
                        this.Attachments().filter(
                            (f: Attachment) => f.id.toUpperCase() == r.DocumentGuid.toUpperCase()
                        )[0].fileName +
                        " -> " +
                        r.ReferenceUrl +
                        "%0A";
                });

                const mailReference = "mailto:?subject=" + this.GetMailSubject() + "&body=" + body;
                location.href = mailReference;
            });
        });
    }

    public SignEvent() {}

    public deleteEvent() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.ProlifeSdk.ConfirmDeleteEvent,
            ProlifeSdk.TextResources.ProlifeSdk.DoNotDeleteEvent,
            ProlifeSdk.TextResources.ProlifeSdk.DeleteEvent,
            (dialogResult: boolean) => {
                if (dialogResult)
                    this.logService.deleteEvent(this.IdEvent()).then(() => {
                        this.contextEventsObserver.RefreshEvents();
                    });
            }
        );
    }

    public Clear() {
        this.originalValue = <ILogEvent>{
            EventId: -1,
            TimeStamp: new Date(),
            ReferenceDate: new Date(),
            CreatedBySystem: false,
            Locked: false,
            EventType: null,
            ModuleName: null,
            Text: null,
            UserId: -1,
            EventData: null,
            Tags: [],
            Tasks: [],
        };

        this.IdEvent(0);
        this.Message("");
        this.WorkSheets([]);
        this.Tags([]);
        this.Locked(false);
        this.JobOrder(null);
        this.FkParentId(null);
        this.ReferenceDate(new Date());
        this.CreatedBySystem(false);
        this.Tasks([]);
        this.CustomMetaTags([]);
        this.AllowedUsersTags([]);
        this.Attachments([]);
        this.Subject("");
        this.IsSigned(false);
        this.Signature(null);
        this.Signers([]);
        this.RelatedTasks([]);
    }

    public loadTags() {
        this.Attachments([]);

        this.Tags().forEach((item) => {
            if (item.TagName() == ProlifeSdk.Tag_File) this.Attachments.push(new Attachment().loadFromTag(item));

            if (item.TagName() == ProlifeSdk.Tag_SignatureSigner) this.Signers.push(item);
        });
    }

    public onChildDeleted(child: BlogEventBase) {
        this.ChildrenEvents.remove(child);
    }

    public LoadItemFromObject(item: ILogEvent) {
        if (!item) return;

        this.BaseLoadItem(item);
        this.EventTime(item.TimeStamp);
        this.JobOrder(item.JobOrder);
        this.Tasks(item.Tasks ? item.Tasks : []);
        this.ReferenceDate(item.ReferenceDate);
        this.LoadLocalTags();
    }

    public LoadItemFromServerObject(item: ILogEvent) {
        if (!item) return;

        item.TimeStamp = moment(item.TimeStamp).toDate();
        item.ReferenceDate = moment(item.ReferenceDate).toDate();
        this.LoadItemFromObject(item);
    }

    public setParent(parent: BlogEventBase) {
        this.parent = parent;
    }

    public LoadLocalTags() {}

    public LoadDetails() {
        if (this.tagsDetailsAreLoaded || !this.originalValue.Tags) return;

        this.originalValue.Tags.forEach((item) => {
            const tag: Tag = new Tag();
            tag.LoadItemFromObject(item);
            this.Tags.push(tag);
        });

        this.Tags().forEach((item) => {
            if (item.TagName() == ProlifeSdk.Tag_CustomTag) this.CustomMetaTags.push(item);

            if (item.TagName() == ProlifeSdk.Tag_AllowedUser) this.AllowedUsersTags.push(item);

            this.Locked(
                item.TagName() == ProlifeSdk.Tag_Locked && item.Value().toString().toLowerCase() == "true"
                    ? true
                    : this.Locked()
            );
            this.IsSigned(
                item.TagName() == ProlifeSdk.Tag_IsSigned && item.Value().toString() == ProlifeSdk.Tag_IsSigned_true
                    ? true
                    : this.IsSigned()
            );
            this.Signature(item.TagName() == ProlifeSdk.Tag_Signature ? item.Value() : this.Signature());
        });

        this.LoadLocalTags();
        this.tagsDetailsAreLoaded = true;
    }

    public Print() {
        const article = $("[event-identifier='" + this.IdEvent() + "']");

        if (article.length == 0) return;

        const container = $("<div class='printable-blog-events-list'></div>");
        container.append($(article[0]).clone());

        const iFrame = container.find("iframe");
        iFrame.prop("srcdoc", this.Message() || "");

        const tmpNode = $("<div></div>");
        tmpNode.append(container);
        const htmlCode: string = tmpNode.html();
        localStorage.setItem("HtmlPrintViewer_BodyContent", htmlCode);
        window.open("./HtmlPrintViewer.html", "_blank");
    }

    public BaseLoadItem(item: ILogEvent) {
        this.originalValue = item;
        this.IdEvent(item.EventId);
        this.EventType(item.EventType);
        this.Message(item.Text);
        this.WorkSheets(item.WorkSheetsData);
        this.EventUser(item.UserId);
        this.FkParentId(item.FkParentId);
        this.CreatedBySystem(item.CreatedBySystem);
        this.Tasks(item.Tasks ? item.Tasks : []);
        this.JobOrder(item.JobOrder);
        this.Locked(item.Locked);
        this.RelatedTasks(item.RelatedTasks ? item.RelatedTasks.split("|") : []);

        this.ChildrenEvents([]);
        if (item.ChildrenEvents != null && item.ChildrenEvents.length > 0) {
            const allChildrenEvents = [];

            item.ChildrenEvents.forEach((child) => {
                const blogChild: BlogEventBase = <BlogEventBase>(
                    this.blogService.getUIForEvent(child, this.contextEventsObserver)
                );
                blogChild.setParent(this);
                blogChild.LoadItemFromServerObject(child);
                allChildrenEvents.push(blogChild);
            });

            this.ChildrenEvents(allChildrenEvents);
        }

        const authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.isUserAuthorizedForDelete(
            authorizationService.isAuthorized("Blog_EraseAllPosts") ||
                (<any>this.authService.getUser()).IdUser == item.UserId
        );
    }
}
