import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IWorkflowCategory } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkflowState, IWorkflowStatesSettingsManager } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";

export class BlogJobOrderAdvancedFilter implements IDialog {
    templateName: string = "jobOrderAdvancedFilterDialog";
    templateUrl: string = "blog/templates";
    title: string = ProlifeSdk.TextResources.Blog.AdvancedJobOrderFilterTitle;
    modal: {
        close: (result?: any) => void;
    };

    public Type : ko.Observable<number> = ko.observable(-1);
    public State : ko.Observable<number> = ko.observable(-1);
    public ProjectState : ko.Observable<number> = ko.observable(-1);

    public States : ko.ObservableArray<IWorkflowState> = ko.observableArray();
    public Types : ko.ObservableArray<IWorkflowCategory> = ko.observableArray();
    public ProjectStates : ko.ObservableArray<IJobOrderState> = ko.observableArray();

    constructor(serviceLocator : IServiceLocator, type : number, state: number, projectState : number) {
        var settingsManager = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var workflowStatesSM = <IWorkflowStatesSettingsManager>settingsManager.findSettingsManager(ProlifeSdk.WorkflowStates);
        var workflowTypesSM = <IWorkflowCategoriesSettingsManager>settingsManager.findSettingsManager(ProlifeSdk.WorkflowCategoriesType);
        var jobOrderStatesSM = <IJobOrderStateSettingsManager>settingsManager.findSettingsManager(ProlifeSdk.JobOrderState);

        this.States(workflowStatesSM.getStates(false));
        workflowTypesSM.GetCategories()
            .then((categories : IWorkflowCategory[]) => {
                this.Types(categories);
                this.Type(type < 0 ? undefined : type);
            });
        this.ProjectStates(jobOrderStatesSM.getJobOrderStates());

        this.State(state < 0 ? undefined : state);
        this.ProjectState(projectState < 0 ? undefined : projectState);
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        this.modal.close(true);
    }
}