import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/06/2017
 * Time: 10:56
 * To change this template use File | Settings | File Templates.
 */

import { Question } from "../Question";
import { AnswerForEdit } from "./AnswerForEdit";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { SectionForEdit } from "./SectionForEdit";
import { QuestionnaireEditViewModel } from "./QuestionnaireEditViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelectable } from "../../../../../ProlifeSdk/interfaces/survey/ISelectable";
import { ISortable } from "../../../../interfaces/ISortable";
import { IMovable } from "../../../../../ProlifeSdk/interfaces/survey/IMovable";
import { IQuestionType, IQuestion, IQuestionForEdit } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { IAnswerType, IAnswer } from "../../../../../ProlifeSdk/interfaces/survey/IAnswer";
import { ISelectionObserver } from "../../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { IQuestionMovementObserver } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionMovementObserver";

export class QuestionForEdit extends Question implements ISelectable, ISortable, IMovable {
    public templateName: string = "questions-list-element";
    public templateUrl: string = "survey/templates/questionnaire";
    public Selected: ko.Observable<boolean> = ko.observable(false);

    public Answers: ko.ObservableArray<AnswerForEdit> = ko.observableArray([]);
    public AnswersNumber: ko.Computed<number>;

    public QuestionTypes: ko.ObservableArray<IQuestionType> = ko.observableArray([]);
    public AnswerTypes: ko.ObservableArray<IAnswerType> = ko.observableArray([]);

    public IsNew: boolean;
    public Deleted: ko.Observable<boolean> = ko.observable(false);
    public ParentSectionForSelect: ko.Observable<number> = ko.observable();

    public NewAnswerText: ko.Observable<string> = ko.observable();

    public CanDelete: ko.Computed<boolean>;
    public CanRestore: ko.Computed<boolean>;

    public MissingRequiredData: ko.Computed<boolean>;
    public HasIncompleteAnswers: ko.Computed<boolean>;
    public IsIncomplete: ko.Computed<boolean>;
    public IsParentDeleted: ko.Computed<boolean>;

    private surveyService: ISurveyService;
    private dialogService: IDialogsService;
    private infoToastService: IInfoToastService;

    private selectionObservers: ISelectionObserver[] = [];
    private questionMovementObserver: IQuestionMovementObserver[] = [];

    constructor(private serviceLocator: IServiceLocator, question: IQuestion, isNew: boolean, public section: SectionForEdit, private Editor: QuestionnaireEditViewModel) {
        super(question);
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.Selected(false);
        this.IsNew = isNew;
        this.ParentSectionForSelect(this.section ? this.section.Id : null);

        if (!this.IsNew) {
            this.surveyService.getQuestionAnswers(question.Id)
                .then((answers: IAnswer[]) => {
                    if (answers.length == 0)
                        return;
                    this.Answers(answers.map((answer: IAnswer) => {
                        return new AnswerForEdit(answer, false, this);
                    }));
                })
                .catch(() => {});
        }

        this.surveyService.getQuestionTypes()
            .then((types: IQuestionType[]) => {
                if (types.length == 0)
                    return;
                this.QuestionTypes(types);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionTypesError);
            });

        this.surveyService.getAnswerTypes()
            .then((types: IAnswerType[]) => {
                if (types.length == 0)
                    return;
                this.AnswerTypes(types);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadAnswerTypesError);
            });

        this.AnswersNumber = ko.computed(() => {
            return this.Answers().filter((a: AnswerForEdit) => {
                return !a.Deleted();
            }).length;
        });

        this.IsParentDeleted = ko.computed(() => {
            return !this.section ? false : section.Deleted();
        });

        this.CanDelete = ko.computed(() => {
            return !this.IsParentDeleted() && !this.Deleted();
        });

        this.CanRestore = ko.computed(() => {
            return !this.IsParentDeleted() && this.Deleted();
        });

        this.HasIncompleteAnswers = ko.computed(() => {
            var result: boolean = false;
            this.Answers().forEach((a: AnswerForEdit) => {
                if (a.IsIncomplete())
                    result = true;
            });
            return result;
        });

        this.IsIncomplete = ko.computed(() => {
            return !this.Text();
        });

        this.MissingRequiredData = ko.computed(() => {
            return this.HasIncompleteAnswers() || this.IsIncomplete();
        });

        this.ParentSectionForSelect.subscribe((newParent: number) => {
            if (!this.section || (this.section && !this.section.Deleted()))
                this.ParentId(newParent);
        });

        this.ParentId.subscribe((newParent: number) => {
            if (!this.IsParentChanged(newParent, this.section))
                return;
            this.questionMovementObserver.forEach((o: IQuestionMovementObserver) => {
                o.moveQuestion(this.Id, this.section ? this.section.Id : null, newParent);
            });
        });

        this.AnswerTypeId.subscribe(this.OnAnswerTypeChange.bind(this));
    }

    private IsParentChanged(newParentId: number, oldParent: SectionForEdit): boolean {
        return !(!newParentId && !oldParent) || (newParentId != oldParent.Id);
    }

    private OnAnswerTypeChange(newType: number) {
        this.AnswerTypeName(this.AnswerTypes().filter((a: IAnswerType) => {
            return a.Id == newType;
        })[0].Description);

        this.propagateTypeToAnswers();
    }

    private propagateTypeToAnswers() {
        this.Answers().forEach((a: AnswerForEdit) => {
            a.AnswerTypeId(this.AnswerTypeId());
            a.AnswerTypeName(this.AnswerTypeName());
        });
    }

    public RefreshSelectedSection(option: any, optionData: any) {
        if (optionData.Value == this.ParentId())
            this.ParentSectionForSelect(this.ParentId());
    }

    public RegisterSelectionObserver(observer: ISelectionObserver) {
        this.selectionObservers.push(observer);
    }

    public RegisterQuestionMovementObserver(observer: IQuestionMovementObserver) {
        this.questionMovementObserver.push(observer);
    }

    public NotifySelection() {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectQuestion(this);
        })
    }

    public GetEditor(): QuestionnaireEditViewModel {
        return this.Editor;
    }

    public OnAnswerMoved(beforeId : number, movedId : number, afterId : number) {
        var actualPosition: number = this.getAnswerIndex(movedId);
        var previousAnswerPosition: number = this.getAnswerIndex(beforeId);
        var nextAnswerPosition: number = this.getAnswerIndex(afterId);
        this.updateIndexes(movedId, actualPosition, previousAnswerPosition, nextAnswerPosition, this.Answers());
    }

    private getAnswerIndex(id: number): number {
        var index: number = null;
        this.Answers().forEach((a: AnswerForEdit) => {
            if (a.Id == id)
                index = a.Index();
        });
        return index;
    }

    private updateIndexes(elementId: number, actualIndex: number, previousObjectIndex: number, nextObjectIndex: number, elements: ISortable[]): void {
        if (!previousObjectIndex && !nextObjectIndex)
            return;
        previousObjectIndex = !previousObjectIndex ? 0 : previousObjectIndex;
        nextObjectIndex = !nextObjectIndex ? 99999 : nextObjectIndex;
        if (actualIndex < previousObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() >= actualIndex && e.Index() <= previousObjectIndex && e.Id != elementId)
                    e.Index(e.Index() - 1);
                if (e.Id == elementId)
                    e.Index(previousObjectIndex);
            });
            return;
        }
        if (actualIndex > nextObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() <= actualIndex && e.Index() >= nextObjectIndex && e.Id != elementId)
                    e.Index(e.Index() + 1);
                if (e.Id == elementId)
                    e.Index(nextObjectIndex);
            });
        }
    }

    public AddNewAnswer() {
        if (!this.NewAnswerText())
            return;
        var newAnswer = <IAnswer> {
            Id: this.getAvailableAnswerId(),
            QuestionId: this.Id,
            Text: this.NewAnswerText(),
            GoToLabel: null,
            GoToId: null,
            EnableLabel: null,
            EnableId: null,
            Index: this.AnswersNumber() + 1,
            Description: null,
            Value: null,
            HideValue: false,
            CreationDate: null,
            CreatorId: null,
            ModifyDate: null,
            ModifierId: null,
            IsOpen: false,
            AnswerTypeId: this.AnswerTypeId(),
            AnswerTypeName: this.AnswerTypeName(),
            Deleted: false,
            PublicLabel: null,
            GoToPublicLabel: null,
            EnablePublicLabel: null,
            SectionGoToPublicLabel: null,
            SectionEnablePublicLabel: null
        };
        this.Answers.push(new AnswerForEdit(newAnswer, true, this));
        this.NewAnswerText(null);
    }

    public Delete() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Survey.DeleteQuestion,
            ProlifeSdk.TextResources.Survey.DeleteQuestionCancel,
            ProlifeSdk.TextResources.Survey.DeleteQuestionConfirm,
            (confirm) => {
                if (confirm)
                    this.deleteQuestion();
            }
        );
    }

    public Restore() {
        this.Deleted(false);
        this.propagateDeletedState(false);
    }

    public ToJSON(): IQuestionForEdit {
        var json = <IQuestionForEdit> super.ToJSON();
        json.Answers = this.Answers().map((a: AnswerForEdit) => {
            return a.ToJSON();
        });
        json.IsNew = this.IsNew;
        json.Deleted = this.Deleted();
        return json;
    }

    private getAvailableAnswerId() {
        return this.AnswersNumber() == 0 ? 1 : (Math.max.apply(Math, this.Answers().map((a: AnswerForEdit) => { return a.Id; }))) + 1;
    }

    public deleteQuestion(): void {
        this.Deleted(true);
        this.propagateDeletedState(true);
    }

    private propagateDeletedState(deleted: boolean): void {
        this.Answers().forEach((a: AnswerForEdit) => {
            deleted ? a.Delete() : a.Restore();
        });
    }
}