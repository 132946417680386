import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IExercise, IExercises } from "../../../../ProlifeSdk/interfaces/invoice/settings/IExercises";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";

export interface IExerciseProvider {
    Anno : ko.Observable<number>;
    Periodo : ko.Observable<number>;
    StartDay : ko.Observable<Date>;
    EndDay : ko.Observable<Date>;

    getData() : IExercise;
    update(exercise : IExercise) : void;
}

export class ExercisesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IExerciseProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    private dialogsService : IDialogsService;
    private infoToastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private exercisesManager : IExercises) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);

        this.title = exercisesManager.getLabel();

        var exercises = exercisesManager.getExercises();
        exercises.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(exercise : IExercise) {
        this.elements.push(new ExerciseViewModel(this.serviceLocator, this, exercise));
    }

    private getMaxEndDay() : Date
    {
        var maxEndDate = moment("1910-01-01");
        this.elements().forEach((exercise: IExerciseProvider) => {
            var currentEndDate = moment(exercise.EndDay());
            if(currentEndDate.diff(maxEndDate) > 0)
                maxEndDate = currentEndDate;
        })
        return maxEndDate.toDate();
    }

    private getMaxPeriodo(anno : number) : number
    {
        var maxPeriodo = 0;
        this.elements()
            .filter((exercise) => exercise.Anno() == anno)
            .forEach((exercise) => {
                if(exercise.Periodo() > maxPeriodo)
                    maxPeriodo = exercise.Periodo();
            });
        return maxPeriodo;
    }

    public createNew()
    {
        var startDay : Date = moment(this.getMaxEndDay())
            .add('days', 1)
            .startOf('hour')
            .toDate();

        var endDay : Date = moment(startDay)
            .add('years', 1)
            .subtract("days", 1)
            .startOf('hour')
            .toDate();

        var maxPeriodo = this.getMaxPeriodo(moment(endDay).year());

        var exerciseViewModel = new ExerciseViewModel(this.serviceLocator, this, {
            Anno: moment(endDay).year(),
            Periodo: maxPeriodo + 1,
            StartDay: startDay,
            EndDay: endDay
        });
        this.elements.unshift(exerciseViewModel);
        this.createOrUpdateEntry(exerciseViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IExerciseProvider) {
        var exercise = element.getData();
        this.exercisesManager.createOrUpdate(exercise)
            .then((updatedExercise) => element.update(updatedExercise));
    }

    public deleteEntry(element : IExerciseProvider) {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Invoices.DeleteFiscalMsg,
            ProlifeSdk.TextResources.Invoices.No, ProlifeSdk.TextResources.Invoices.Yes,
            this.onDeleteConfirm.bind(this, element));
    }

    private onDeleteConfirm(element : IExerciseProvider, result : boolean) {
        if(!result) return;

        var appearance = element.getData();
        this.exercisesManager.remove(appearance.Id)
            .then(this.onExerciseDeleted.bind(this, element))
            .catch(this.onExerciseDeleteFailed.bind(this));
    }

    private onExerciseDeleted(element : IExerciseProvider) {
        this.elements.remove(element);
        this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.FiscalDeleted);
    }

    private onExerciseDeleteFailed() {
        this.infoToastService.Error(ProlifeSdk.TextResources.Invoices.ErrorWhileFiscalDelete);
    }
}

class ExerciseViewModel implements IExerciseProvider
{
    private sdkService : IProLifeSdkService;

    Anno : ko.Observable<number> = ko.observable();
    Periodo : ko.Observable<number> = ko.observable();
    StartDay : ko.Observable<Date> = ko.observable();
    EndDay : ko.Observable<Date> = ko.observable();

    private updating : boolean = false;

    constructor(private serviceLocator : IServiceLocator, private container : ExercisesEditingViewModel, private exercise : IExercise)
    {
        this.update(exercise);

        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);

        this.EndDay.subscribe(this.onEndDayChanged.bind(this));
    }

    private onEndDayChanged(newValue : string) {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    getData() : IExercise
    {
        var exercise : IExercise = <IExercise> $.extend({}, this.exercise);
        exercise.Anno = this.Anno();
        exercise.Periodo = this.Periodo();
        exercise.StartDay = moment(this.StartDay()).startOf("day").toDate();
        exercise.EndDay = moment(this.EndDay()).endOf("day").toDate();
        return exercise;
    }

    update(exercise : IExercise) : void {
        this.updating = true;

        this.exercise = exercise;
        this.Anno(exercise.Anno);
        this.Periodo(exercise.Periodo);
        this.StartDay(moment(exercise.StartDay).toDate());
        this.EndDay(moment(exercise.EndDay).toDate());

        this.updating = false;
    }
}