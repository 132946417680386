import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IValidityType, IValidityTypes } from "../ProlifeSdk/interfaces/invoice/settings/IValidityTypes";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export type IDocumentValidityTypesDataSourceModel = IDataSourceModel<number, IValidityType>;

export class DocumentValidityTypesDataSource extends BaseDataSource<IDocumentValidityTypesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.ValidityTypes)
    private validityTypesSettingsManager: IValidityTypes;

    getTitle(): string {
        return this.validityTypesSettingsManager.getLabel();
    }

    async getData(
        currentModel: IDocumentValidityTypesDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDocumentValidityTypesDataSourceModel[]> {
        const types = this.validityTypesSettingsManager
            .getValidityTypes()
            .filter((t) => TextFiltersUtilities.contains(t.Descrizione, textFilter))
            .slice(skip, skip + count);
        return types.map(this.createModel, this);
    }

    async getById(
        currentModel: IDocumentValidityTypesDataSourceModel,
        ids: number[]
    ): Promise<IDocumentValidityTypesDataSourceModel[]> {
        const types = this.validityTypesSettingsManager.getValidityTypes().filter((t) => ids.indexOf(t.Id) >= 0);
        return types.map(this.createModel, this);
    }

    private createModel(type: IValidityType): IDocumentValidityTypesDataSourceModel {
        return {
            id: type.Id,
            title: type.Descrizione,
            isGroup: false,
            isLeaf: true,
            model: type,
        };
    }
}
