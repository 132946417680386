import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 14/03/13
 * Time: 11.41
 * To change this template use File | Settings | File Templates.
 */


import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class CustomDecimalProvider implements IEntityProvider<number, number> {

    constructor() {

    }

    getType() : string
    {
        return ProlifeSdk.TagType_Decimal;
    }

    getPkValue(item : number) : number
    {
        return item;
    }

    getDisplayName(item : number) : string
    {
        return item.toString();
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Blog.Decimal;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<any>
    {
        return Promise.resolve(pkValue);
    }

    hintSearch(stringFilter : string) : Promise<any[]>
    {
        return Promise.resolve<any[]>([]);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        //Da implementare se necessario
        return null;
    }

    getEntity(pkValue : any) : Promise<any>
    {
        //Da implementare !!!
        return Promise.resolve<any>(undefined);
    }
}