import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 17.56
 * To change this template use File | Settings | File Templates.
 */

export class DeleteOnEmpty {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        if($(element).attr('contenteditable')) {
            $(element).on('blur', () => {
                if($.trim(element.innerHTML.replace('<br>','')).length == 0) {
                    valueAccessor()();
                }
            })
        }
        else {
            $(element).on('change', () => {
                if((<string>$(element).val()).length == 0) {
                    valueAccessor()();
                }
            })
        }
    }
}

ko.bindingHandlers["deleteOnEmpty"] = new DeleteOnEmpty();