import * as ko from "knockout";
import { HomePage } from "./HomePage";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";

export class ReportsViewer
{
    public OpenReport : ko.Observable<any> = ko.observable();

    constructor()
    {
        this.showReport(new HomePage());
    }

    public showReport(report : IReport)
    {
        this.OpenReport(report.getViewModel());
    }
}