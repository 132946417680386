import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { NodeDetails } from "./NodeDetails";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IReferenceDetailsViewModelFactory, ISimpleEntitiesViewModel } from "../../../interfaces/invoice/IEntityRefInfo";
import { IEntityProviderService } from "../../../interfaces/IEntityProviderService";
import { INodeDetails, IDocumentReferencesMap, IReferenceForMap } from "../../../interfaces/invoice/IReferencesMap";

export class ReferencesMapViewer implements IDocumentReferencesMap
{
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService : IEntityProviderService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    public templateName : string = "references-map-dialog";
    public templateUrl : string = "prolifesdk/templates/documents/referencemap";
    public title : string = ProlifeSdk.TextResources.ProlifeSdk.DocumentReferencesMap;
    modal: { close: (result?: any) => void; };

    public TitleWithDocName : ko.Computed<string>;

    public References : ko.ObservableArray<IReferenceForMap> = ko.observableArray([]);

    public SourceNode : ko.Observable<any> = ko.observable();
    public AnalyzedNode : ko.Observable<any> = ko.observable();
    public DestinationNode : ko.Observable<any> = ko.observable();

    public FocusOnToolEnabled : ko.Observable<boolean> = ko.observable(false);

    public DocumentId : ko.Observable<number> = ko.observable(0);
    public DocumentType : ko.Observable<string> = ko.observable(null);

    public SourceWarehouseId : number;
    public CustomerId : number;

    constructor(private documentId : number,
                private documentEntityTypeCode : string,
                private sourceWarehouseId : number,
                private customerId : number)
    {
        this.SourceWarehouseId = sourceWarehouseId;
        this.CustomerId = customerId;

        this.TitleWithDocName = ko.computed(() => {
            var docName = this.AnalyzedNode() ? " " + this.AnalyzedNode().node.Description : "";
            return this.title + docName;
        });
        this.ReloadGraph(documentId, documentEntityTypeCode);
    }

    ReloadGraph(documentId : number, documentEntityTypeCode : string)
    {
        this.DocumentId(documentId);
        this.DocumentType(documentEntityTypeCode);
        this.SourceNode(null);
        this.AnalyzedNode(null);
        this.DestinationNode(null);

        this.documentsService.GetReferenceMap(documentId, documentEntityTypeCode).then((result : IReferenceForMap[]) => {
            this.References(result);

            this.documentsService.GetReferencesMapNodeDetails(documentId, documentEntityTypeCode).then((n : INodeDetails) => {
                this.AnalyzedNode(new NodeDetails(n, this));
            });
        });
    }

    SwitchFocusOnTool()
    {
        this.FocusOnToolEnabled(!this.FocusOnToolEnabled());
    }

    close(): void
    {
        this.modal.close();
    }

    action(): void
    {
        this.close();
    }

    OnSimpleEntitiesSourceSelected(entityType : string)
    {
        if(this.FocusOnToolEnabled())
            return;

        this.SourceNode(null);
        this.AnalyzedNode().UnselectAll();
        if(this.DestinationNode())
            this.DestinationNode().UnselectAll();

        var factory : IReferenceDetailsViewModelFactory = this.entitiesService.getReferenceDetailsViewModelFactory(entityType);

        if(factory == null)
            return;

        var simpleEntitiesVm : ISimpleEntitiesViewModel = factory.CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(this);

        if(simpleEntitiesVm == null)
            return;

        simpleEntitiesVm.LoadEntities(this.References()).then(() => {
            this.SourceNode(simpleEntitiesVm);
        });
    }

    OnSourceDocumentSelected(entityId : number, entityType : string)
    {
        if(this.FocusOnToolEnabled())
        {
            this.ReloadGraph(entityId, entityType);
            this.FocusOnToolEnabled(false);
            return;
        }

        this.documentsService.GetReferencesMapNodeDetails(entityId, entityType).then((n : INodeDetails) => {
            this.SourceNode(n ? new NodeDetails(n, this) : null);
            this.SourceNode().UnselectAll();
            this.AnalyzedNode().UnselectAll();
            if(this.DestinationNode())
                this.DestinationNode().UnselectAll();
        });
    }

    OnDestinationDocumentSelected(entityId : number, entityType : string)
    {
        if(this.FocusOnToolEnabled())
        {
            this.ReloadGraph(entityId, entityType);
            this.FocusOnToolEnabled(false);
            return;
        }

        this.documentsService.GetReferencesMapNodeDetails(entityId, entityType).then((n : INodeDetails) => {
            this.DestinationNode(n ? new NodeDetails(n, this) : null);
            if(this.SourceNode())
                this.SourceNode().UnselectAll();
            this.AnalyzedNode().UnselectAll();
            this.DestinationNode().UnselectAll();
        });
    }

    OnSubSelectionChanged(selectedElement : any, selectedSubElementId : number, selectedSubElementType : string)
    {
        if(selectedElement == this.SourceNode())
        {
            if(this.AnalyzedNode())
                this.AnalyzedNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);

            if(this.DestinationNode())
                this.DestinationNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);
        }

        if(selectedElement == this.AnalyzedNode())
        {
            if(this.SourceNode())
                this.SourceNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);

            if(this.DestinationNode())
                this.DestinationNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);
        }

        if(selectedElement == this.DestinationNode())
        {
            if(this.AnalyzedNode())
                this.AnalyzedNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);

            if(this.SourceNode())
                this.SourceNode().SelectRelatedRows(selectedSubElementId, selectedSubElementType);
        }
    }
}