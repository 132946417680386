import * as ko from "knockout";

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { SystemBlogEvent } from "../EventViewModels/SystemBlogEvent";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";

export class SystemInput extends BlogEventBaseInput
{
	public templateName : string  = "system-details";
	public title : string = ProlifeSdk.TextResources.Blog.SystemInputTitle;

    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver,
                event : SystemBlogEvent)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconName = "f-icon-cog";
        this.setValues(event);
    }

    public setValues(item : SystemBlogEvent) : void
    {
        super.setValues(item);
    }

    getBlogEvent(): ILogEvent
    {
        var localBlogEvent : ILogEvent = super.getBlogEvent();
        localBlogEvent.EventType = "Blog.System";
        return localBlogEvent;
    }

	public getValidationMessage() : string[]
    {
		var message : string[] = super.getValidationMessage();

		if (this.subject() == null || this.subject().trim() == "")
			message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

		return message;
	}
}