import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/08/2017
 * Time: 14:46
 * To change this template use File | Settings | File Templates.
 */

import { ICampaign } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";

export class Campaign {
    public Id: number;
    public Title: ko.Observable<string> = ko.observable();
    public TypeId: ko.Observable<number> = ko.observable();
    public TypeName: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();
    public StateDescription: ko.Observable<string> = ko.observable();
    public QuestionnaireId: ko.Observable<number> = ko.observable();
    public QuestionnaireVersion: ko.Observable<number> = ko.observable();
    public QuestionnaireTitle: ko.Observable<string> = ko.observable();
    public SampleId: ko.Observable<number> = ko.observable();
    public SampleTitle: ko.Observable<string> = ko.observable();
    public SampleSize: ko.Observable<number> = ko.observable();
    public GlobalTimeInterval: ko.Observable<number> = ko.observable();
    public CampaignClassTimeInterval: ko.Observable<number> = ko.observable();
    public Enabled: ko.Observable<boolean> = ko.observable();

    constructor(campaign: ICampaign) {
        this.Id = campaign.Id;
        this.Title(campaign.Title);
        this.TypeId(campaign.TypeId);
        this.TypeName(campaign.TypeName);
        this.Icon(campaign.Icon);
        this.Background(campaign.Background);
        this.Foreground(campaign.Foreground);
        this.StartDate(campaign.StartDate);
        this.EndDate(campaign.EndDate);
        this.CreationDate(campaign.CreationDate);
        this.CreatorId(campaign.CreatorId);
        this.ModifyDate(campaign.ModifyDate);
        this.ModifierId(campaign.ModifierId);
        this.Deleted(campaign.Deleted);
        this.Description(campaign.Description);
        this.StateId(campaign.StateId);
        this.StateDescription(campaign.StateDescription);
        this.QuestionnaireId(campaign.QuestionnaireId);
        this.QuestionnaireVersion(campaign.QuestionnaireVersion);
        this.QuestionnaireTitle(campaign.QuestionnaireTitle);
        this.SampleId(campaign.SampleId);
        this.SampleTitle(campaign.SampleTitle);
        this.SampleSize(campaign.SampleSize);
        this.GlobalTimeInterval(campaign.GlobalTimeInterval);
        this.CampaignClassTimeInterval(campaign.CampaignClassTimeInterval);
        this.Enabled(campaign.Enabled);
    }

    public toJSON(): ICampaign {
        return <ICampaign> {
            Id: this.Id,
            Title: this.Title(),
            TypeId: this.TypeId(),
            TypeName: this.TypeName(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            CreationDate: this.CreationDate(),
            CreatorId: this.CreatorId(),
            ModifyDate: this.ModifyDate(),
            ModifierId: this.ModifierId(),
            Deleted: this.Deleted(),
            Description: this.Description(),
            StateId: this.StateId(),
            StateDescription: this.StateDescription(),
            QuestionnaireId: this.QuestionnaireId(),
            QuestionnaireVersion: this.QuestionnaireVersion(),
            QuestionnaireTitle: this.QuestionnaireTitle(),
            SampleId: this.SampleId(),
            SampleTitle: this.SampleTitle(),
            SampleSize: this.SampleSize(),
            GlobalTimeInterval: this.GlobalTimeInterval(),
            CampaignClassTimeInterval: this.CampaignClassTimeInterval(),
            Enabled: this.Enabled()
        };
    }
}