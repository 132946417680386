import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { IWarehouseEntityStockInfo } from "../../DocumentsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../../Components/Layouts";

const styleSheet = jss.createStyleSheet({
    stockInfo: {
        "& .legend": {
            listStyle: "none",
            padding: 0,
            
            "& li": {
                padding: 0
            },

            "& .color-indicator": {
                display: "inline-block",
                width: "10px",
                height: "10px"
            }
        }
    }
});
const { classes } = styleSheet.attach();

type ArticleStockInfoPopoverProps = {
    stockInfo: IWarehouseEntityStockInfo;
}

export class ArticleStockInfoPopoverUI extends DialogComponentBase {
    static defaultProps: Partial<ArticleStockInfoPopoverProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : ArticleStockInfoPopoverProps) {
        super({ noPrompt: true, popover: true });

        this.title(TextResources.Invoices.ArticleStockInfo);
    }
    
    show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopoverComponent(element, this, "left");
    }

    action() {
        this.modal.close();
    }
    
    renderBody() {
        return <ArticleStockInfoPopover {...this.props} />;
    }
}

export function ArticleStockInfoPopover(props: ArticleStockInfoPopoverProps) {
    const C = require("./ArticleStockInfoPopover")._ArticleStockInfoPopover as typeof _ArticleStockInfoPopover;
    return <C {...props} />;
}

export class _ArticleStockInfoPopover {
    static defaultProps: Partial<ArticleStockInfoPopoverProps> = {
    }

    constructor(private props : ArticleStockInfoPopoverProps) {}
    
    render() {
        const stockInfo: IDataSourceModel<number, IWarehouseEntityStockInfo> = null;

        return ComponentUtils.bindTo((
            <Layout.Vertical className={classes.stockInfo}>
                <Table
                    dataSource={Table.defaultDataSource([this.props.stockInfo], (item) => ({ id: item.ArticleId, title: item.ArticleDescription }))}
                    rowAs="stockInfo"
                >
                    <Column title={TextResources.Invoices.ArticleAvailableAmount}>
                        <ColumnBody>
                            {(item: ITableItem<IWarehouseEntityStockInfo>) => <span data-bind={{ numberText: stockInfo.model.AvailableAmount, format: '0,0.00[0000]' }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Invoices.ArticleStockAmount}>
                        <ColumnBody>
                            {(item: ITableItem<IWarehouseEntityStockInfo>) => <span data-bind={{ numberText: stockInfo.model.StockAmount, format: '0,0.00[0000]' }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Invoices.ArticleReservedAmount}>
                        <ColumnBody>
                            {(item: ITableItem<IWarehouseEntityStockInfo>) => <span data-bind={{ numberText: stockInfo.model.ReservedAmount, format: '0,0.00[0000]' }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Invoices.ArticleOrderedAmount}>
                        <ColumnBody>
                            {(item: ITableItem<IWarehouseEntityStockInfo>) => <span data-bind={{ numberText: stockInfo.model.OrderedAmount, format: '0,0.00[0000]' }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Invoices.ArticleMinimumAmount}>
                        <ColumnBody>
                            {(item: ITableItem<IWarehouseEntityStockInfo>) => <span data-bind={{ numberText: stockInfo.model.MinimumAmount, format: '0,0.00[0000]' }}></span>}
                        </ColumnBody>
                    </Column>
                </Table>

                <div className="legend-wrapper">
                    <label>{TextResources.Invoices.ArticleStockInfoLegend}</label>
                    <ul className="legend">
                        <li>
                            <span className="color-indicator" style={{ backgroundColor: "#00ba01" }}></span>&nbsp;{TextResources.Invoices.AvailableAmountGreatherThenMinimumAmount}
                        </li>
                        <li>
                            <span className="color-indicator" style={{ backgroundColor: "#eded00" }}></span>&nbsp;{TextResources.Invoices.AvailableAmountLowerThenMinimumAmountButGreaterOrEqualThenZero}
                        </li>
                        <li>
                            <span className="color-indicator" style={{ backgroundColor: "#F90" }}></span>&nbsp;{TextResources.Invoices.AvailableAmountLowerThenZeroButAvailableAmountPlusOrderedAmountGreatherOrEqualThenZero}
                        </li>
                        <li>
                            <span className="color-indicator" style={{ backgroundColor: "#ee0000" }}></span>&nbsp;{TextResources.Invoices.AvailableAmountPlusOrderedAmountLowerThenZero}
                        </li>
                    </ul>
                </div>
            </Layout.Vertical>
        ), this.props, "stockInfo");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ArticleStockInfoPopover);
}