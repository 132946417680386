import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/03/2019
 * Time: 15:53
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { WorkedAmountRow } from "../WorkedHours/workedhours/ui/WorkedAmountRow";
import * as Core from "../Core/Core";
import { IWorkedHoursService } from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IWorkableElement } from "../ProlifeSdk/interfaces/worked-hours/IWorkableElement";

export class WorkActivitiesAmountsValue {
    init(element: any, valueAccessor: () => any): void
    {
        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        
        var instance = {};

        var row : WorkedAmountRow = ko.utils.unwrapObservable(valueAccessor().row);
        
        instance['row'] = row;
        instance['element'] = element;
        instance['resultset'] = ko.observableArray([]);
        instance['enterPressed'] = ko.observable(false);

        var iconsProvider = valueAccessor()["iconsProvider"];
        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var billableField = valueAccessor()["billableField"];
        var estimateField = valueAccessor()["estimateField"];
        var descrField = valueAccessor()["descrField"];
        var containerIdField = valueAccessor()["containerIdField"];
        var containerTypeField = valueAccessor()["containerTypeField"];
        var getClosedField = valueAccessor()["getClosedField"];
        var workedAmountField = valueAccessor()["workedAmountField"];
        var filterByActivityProgressMode = valueAccessor()["filterByActivityProgressMode"];

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : WorkActivitiesAmountsValue.findWorkActivitiesContainers.bind(null, instance, workableElementsService, getClosedField, containerIdField, containerTypeField, filterByActivityProgressMode),
            display: (json) => {
                var item = JSON.parse(json);
                return item.FullName;
            },
            limit: 1000,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var iconClass : string = row.GetActivityTypeIcon(item.TypeCode) || "fa-clipboard";
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\"" + (item.ActivitiesProgressAmountMode != ProlifeSdk.WorkedAmountMode ? 'class=\"alert-warning\"><i class=\"fa fa-ban\" style=\"margin-right: 5px;\"></i>' : '>') + "<span class='fa " + iconClass +  "'></span>&nbsp;" + item.FullName + " - (<small>" + item.TaskStatus + "</small>) <br/><small style=\"color: grey\">" + item.WorkflowTitle + " - (" + item.WorkflowStatus + ")</span></div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            return WorkActivitiesAmountsValue.setSelectedElement(row, item, element);
        });

        $(element).on("keydown", (event) => {
            var keycode = event.keyCode || event.which;

            if (keycode != 13)
                instance['enterPressed'](false);

            if(!event.shiftKey && keycode == 13) {
                if (instance['resultset'] && instance['resultset']().length == 1) {
                    WorkActivitiesAmountsValue.setSelectedElement(instance['row'], instance['resultset']()[0], element);
                    instance['enterPressed'](false);
                    return false;
                }

                if (!instance['resultset'] || instance['resultset']().length == 0)
                    instance['enterPressed'](true);
            }

            return true;
        });

        var lastContainerId = row.WorkActivitiesContainerId();
        var lastContainerType = row.WorkActivitiesContainerType();

        var interceptor = ko.computed(() => {
            var containerId = row.WorkActivitiesContainerId();
            var containerType = row.WorkActivitiesContainerType();

            if(containerId == lastContainerId && containerType == lastContainerType)
                return;

            var lastInput = (<any>$(element)).typeahead("val");
            (<any>$(element)).typeahead("val", "*_*");
            (<any>$(element)).typeahead("val", lastInput);
            lastContainerId = containerId;
            lastContainerType = containerType;
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var row : WorkedAmountRow = ko.utils.unwrapObservable(valueAccessor().row);

        var typeField = valueAccessor()["typeField"];
        var keyField = valueAccessor()["keyField"];
        var billableField = valueAccessor()["billableField"];
        var estimateField = valueAccessor()["estimateField"];
        var containerIdField = valueAccessor()["containerIdField"];
        var containerTypeField = valueAccessor()["containerTypeField"];
        var descrField = valueAccessor()["descrField"];
        var previousType = $(element).data("typeField");
        var previousId = $(element).data("keyField");
        var workedHoursField = valueAccessor()["workedHoursField"];
        var filterByActivityProgressMode = valueAccessor()["filterByActivityProgressMode"];

        if(row.WorkActivityType() == previousType && row.WorkActivityId() == previousId)
            return;

        var workableElementsService : IWorkedHoursService = <IWorkedHoursService>Core.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);

        if(!row.WorkActivityType() || !row.WorkActivityId())
        {
            (<any>$(element)).typeahead("val", "");
            row.WorkActivityDescr("");
            row.WorkActivityWorkflow("");
            return;
        }

        workableElementsService.GetWorkableElement(row.WorkActivityId(), row.WorkActivityType())
            .then((result : any) => {
                var item = result ? WorkActivitiesAmountsValue.transformItem(result) : null;
                var text =  item ? item.FullName : "";
                var containerId =  item ? item.ContainerId : null;
                var containerType =  item ? item.ContainerType : null;
                var activityWorkflow = item ? item.WorkflowTitle : "";

                (<any>$(element)).typeahead("val", text);

                row.WorkActivityDescr(text);
                row.WorkActivityWorkflow(activityWorkflow);
                row.WorkActivitiesContainerType(containerType);
                row.WorkActivitiesContainerId(containerId);
            });
    }

    private static setSelectedElement(row: WorkedAmountRow, item: any, element: HTMLElement): string {
        if (!item)
            return "";

        row.WorkActivityType(item.TypeCode);
        row.WorkActivityId(item.Id);
        row.WorkActivityDescr(item.FullName);
        row.WorkActivityWorkflow(item.WorkflowTitle);
        row.WorkActivitiesContainerId(item.ContainerId);
        row.WorkActivitiesContainerType(item.ContainerType);

        $(element).data("typeField", item.TypeCode);
        $(element).data("keyField", item.Id);

        return item.FullName;
    }

    private static findWorkActivitiesContainers(_this : any, findService : IWorkedHoursService, getClosedField : ko.Observable<boolean>,
                                                 containerIdField : any, containerTypeField : any, filterByActivityProgressMode: number,
                                                 query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any,
                                                 numberOfElementsLoaded? : number)
    {
        if(_this.lastTimeout)
            clearTimeout(_this.lastTimeout);

        var getClosed :boolean = getClosedField ? getClosedField() : true;

        var parsedQuery: string = WorkActivitiesAmountsValue.parseInput(query);
        _this.resultset([]);

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            findService.GetWorkableElements(false, false, true, true, getClosed, parsedQuery, numberOfElementsLoaded || 0, 50, -1, containerIdField(), containerTypeField(), filterByActivityProgressMode)
                .then((results : any[]) => {
                    _this.resultset(WorkActivitiesAmountsValue.transformItems(results));

                    if (results.length == 1 && _this.enterPressed()) {
                        WorkActivitiesAmountsValue.setSelectedElement(_this.row, _this.resultset()[0], _this.element);
                        _this.enterPressed(false);
                    }

                    return asyncProcess(WorkActivitiesAmountsValue.transformItems(results));
                });
        }, 500);
    }

    private static parseInput(query: string): string {
        var input = (query || "").trim();

        var regEx = /^((\d+)\s*-\s*)?(.*)$/gi;
        var execResult = regEx.exec(query);
        var searchFilter = execResult[3] || "";
        var firstChar = searchFilter.substring(0, searchFilter.length == 0 ? 0 : 1);
        var remainingString = searchFilter.length < 2 ? "" : searchFilter.substring(1);
        
        if (isNaN(parseInt(firstChar)) && !isNaN(parseInt(remainingString)))
            return remainingString;

        return input;
    }

    private static transformItems(items : IWorkableElement[]) : any[] {
        return items.map(WorkActivitiesAmountsValue.transformItem);
    }

    private static getTaskStatusDescription(taskStatus: number) : string {
        switch(taskStatus) {
            case -1:
                return ProlifeSdk.TextResources.Todolist.Backlog;
            case 0:
                return ProlifeSdk.TextResources.Todolist.ToDo;
            case 1:
                return ProlifeSdk.TextResources.Todolist.InProgress;
            case 2:
                return ProlifeSdk.TextResources.Todolist.CompletedSingular;
            case 3:
                return ProlifeSdk.TextResources.Todolist.VerifiedSingular;
            case 4:
                return ProlifeSdk.TextResources.Todolist.Suspended;
            case 5:
                return ProlifeSdk.TextResources.Todolist.DeletedColumn;
            default:
                return "";
        }
    }

    public static transformItem(item : IWorkableElement) : any {
        return {
            Id: item.KeyId,
            TypeCode: item.ElementType,
            FullName: item.Name,
            ProjectId : item.ProjectId,
            ContainerId : item.ContainerId,
            ContainerType : item.ContainerType,
            WorkedHours: item.WorkedHours,
            WorkflowId: item.WorkflowId,
            WorkflowTitle: item.WorkflowTitle,
            WorkflowStatus: item.WorkflowStatus,
            TaskStatus: WorkActivitiesAmountsValue.getTaskStatusDescription(item.TaskStatus),
            ActivitiesProgressAmountMode: item.ActivitiesProgressAmountMode,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["workActivitiesAmountsValue"] = new WorkActivitiesAmountsValue();