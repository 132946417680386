import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { EstimatesProvider } from "./invoices/providers/EstimatesProvider";
import { EstimatesDataSource } from "./invoices/documents/data-sources/EstimatesDataSource";
import { EstimateEvent } from "./invoices/ui/events/EstimateEvent";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IDocumentsService } from "./DocumentsService";
import { IEstimate, IFullEstimate, IEstimateRow, IEstimateForWizard } from "../ProlifeSdk/interfaces/invoice/IEstimate";
import { IEstimatesService, IEstimatesServiceObserver, IEstimateForList, IGetEstimatesForListRequest } from "../ProlifeSdk/interfaces/invoice/IEstimatesService";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, AjaxOptions } from "../Core/interfaces/IAjaxService";
import { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IService } from "../Core/interfaces/IService";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { IVatRegisters, IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Deferred } from "../Core/Deferred";

interface IBlogViewModels
{
    [type : string] : new (serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver) => BlogEventBase;
}

class EstimatesService extends ProLifeService implements IEstimatesService, IEstimatesServiceObserver, IEventUiProvider
{
    private ajaxService : IAjaxService;
    private documentsService : IDocumentsService;
    private observers : IEstimatesServiceObserver[] = [];
    private hasRegisteredDocumentProviders : boolean = false;
    private eventsViewModels : IBlogViewModels = {};
    private sdkService : IProLifeSdkService;

    constructor(private serviceLocator : IServiceLocator)
    {
        super(ProlifeSdk.InvoiceApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IEstimatesService>(), this);

        //Registro i settings
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }
    
    notifyEstimateChanges(estimate: IEstimate): void {
        this.onEstimateChanged(estimate);
    }

    InitializeService() {
        super.InitializeService();

        this.sdkService = <IProLifeSdkService> this.serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.documentsService = <IDocumentsService> this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        this.eventsViewModels[ProlifeSdk.InvoiceEventType_Estimate] = EstimateEvent;

        new EstimatesDataSource();
    }

    public getJobOrderEstimates(jobOrderId : number): Promise<IEstimate[]>
    {
        if(!jobOrderId)
            return Promise.reject<IEstimate[]>([])

        return this.ajaxService.Get("Documents-api", "Estimates/ByJobOrderId?jobOrderId=" + jobOrderId, { background: true });
    }

    public getUIForEvent(item : ILogEvent, contextEventsObserver : IContextEventsObserver) : IView
    {
        var type = this.eventsViewModels[item.EventType] || BlogEventBase;
        var event : BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView> event;
    }

    public OnServerInitializationDataLoaded(): void
    {
        if(this.hasRegisteredDocumentProviders)
            return;

        this.RegisterProtocols();
    }

    onSettingsUpdated(updateType : string) : void
    {
        if(updateType != ProlifeSdk.SettingsUpdateType_DocumentsProtocols)
            return;

        //Rimuovo i registri di questo tipo precedentemente registrati
        this.documentsService.getRegisteredDocumentProviders().slice(0).forEach((p : IDocumentsProvider) => {
            if(p instanceof EstimatesProvider)
                this.documentsService.unregisterDocumentsProvider(p);
        });

        //Aggiungo i nuovi registri
        this.RegisterProtocols();
    }

    private RegisterProtocols()
    {
        var vatRegisters = <IVatRegisters> this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        vatRegisters.getVatRegisters().forEach((vatRegister : IVatRegister) => {
            if(vatRegister.Stato < ProlifeSdk.HiddenProtocolState && vatRegister.TipoDocumento == 3)
                this.documentsService.registerDocumentsProvider(new EstimatesProvider(vatRegister));
        });
        this.hasRegisteredDocumentProviders = true;
    }

    addObserver(observer : IEstimatesServiceObserver) : void
    {
        this.observers.push(observer);
    }

    removeObserver(observer : IEstimatesServiceObserver) : void
    {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
            this.observers.splice(index, 1);
    }

    getServiceType(): string {
        return ProlifeSdk.EstimatesServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    createOrUpdate(estimate : IFullEstimate) : Promise<IEstimate> {
        var def: Deferred<IEstimate> = new Deferred<IEstimate>();

        var callback = this.onEstimateAdded.bind(this);
        
        if(estimate.Estimate.Id > 0)
            callback = this.onEstimateChanged.bind(this);

        this.ajaxService.Post("Documents-api", "Estimates", { methodData: estimate })
        .then((estimate: IEstimate) => {
            callback(estimate);
            def.resolve(estimate);
        })
        .catch((e) => {
            def.reject(e);
        });

        return def.promise();
    }

    remove(estimateId : number) : Promise<void>
    {
        var def: Deferred<void> = new Deferred<void>();

        this.ajaxService.Delete("Documents-api", "Estimates/" + estimateId, {})
            .then(() => {
                this.onEstimateDeleted(estimateId);
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    search(customerName : string, startDate : Date,  endDate : Date, locked?: boolean,
           unlocked?: boolean, customerId? : number, jobOrderId? : number, withoutJobOrder? : boolean) : Promise<IEstimate[]> {
        var parameters : AjaxOptions = {};
        parameters.methodData = {
            customerName : customerName,
            customerId : customerId,
            from : this.sdkService.Utilities.Dates.AddGmtTime(startDate),
            to : this.sdkService.Utilities.Dates.AddGmtTime(endDate),
            locked: locked,
            unlocked: unlocked,
            jobOrderId: jobOrderId,
            withoutJobOrder: withoutJobOrder
        };

        return this.ajaxService.Post("Documents-api", "Estimates/Search", parameters);
    }

    getDocumentById(documentId : number) : Promise<IEstimate> {
        if(!documentId) {
            return Promise.reject<IEstimate>(undefined);
        }

        return this.ajaxService.Get("Documents-api", "Estimates/" + documentId, {});
    }

    getDocumentRowsById(documentId : number) : Promise<IEstimateRow[]> {
        if(!documentId) {
            return Promise.reject<IEstimateRow[]>([])
        }

        return this.ajaxService.Post("Documents-api", "EstimatesRows/GetEstimateRows", {
            methodData: {
                DocumentId: documentId
            }
        });

        //return this.ajaxService.Get("Documents-api", "EstimatesRows/" + documentId, {});
    }

    setFreezeStatusByDocumentsIds(freeze : boolean, ids : number[]) : Promise<void>
    {
        if(!ids || ids.length == 0) {
            return Promise.reject<void>(undefined);
        }

        var def: Deferred<void> = new Deferred<void>();

        this.ajaxService.Post("Documents-api", "Estimates/SetFreezeStatus" , { methodData : { freezeStatus : freeze, ids: ids } })
            .then(() => {
                ids.forEach((id : number) => this.onEstimateLockStateChanged(id, freeze));
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    setSalIdOnDocuments(documentsIds : number[], salId: number): Promise<void>
    {
        if(!documentsIds || documentsIds.length == 0) {
            return Promise.reject<void>(undefined);
        }
        
        return this.ajaxService.Post("Documents-api/Estimates", "SetSalIdOnDocuments" , {
            methodData : { salId : salId, documentIds: documentsIds }
        });
    }

    getEstimatesByVatRegister(vatRegisterId : number, filter? : string) : Promise<IEstimate[]> {
        if(!vatRegisterId) {
            return Promise.reject<IEstimate[]>([])
        }

        var filterQuery = "&filter=" + (filter || "");

        return this.ajaxService.Get("Documents-api", "Estimates/ByVatRegister?vatRegisterId=" + vatRegisterId + filterQuery, { background: true });
    }

    getEstimatesPartByVatRegister(vatRegisterId : number, skip : number, count : number, filter? : string) : Promise<IEstimate[]>
    {
        if(!vatRegisterId)
            return Promise.reject<IEstimate[]>([])

        var filterQuery = "&skip=" + skip + "&count=" + count + "&filter=" + (filter || "");

        return this.ajaxService.Get("Documents-api", "Estimates/PartByVatRegister?vatRegisterId=" + vatRegisterId + filterQuery, { background: true });
    }

    GetEstimatesForWizard(textFilter : string,
                          getWithoutJobOrder : boolean,
                          jobOrderId : number,
                          getForAllCustomers : boolean,
                          getAllStates: boolean,
                          customerId : number,
                          from : Date, to : Date,
                          skip : number, count : number) : Promise<IEstimateForWizard[]>
    {
        return this.ajaxService.Post("Documents-api", "Estimates/GetEstimatesForWizard", {
            methodData: {
                TextFilter : textFilter,
                JobOrderId : jobOrderId,
                GetWithoutJobOrder : getWithoutJobOrder,
                CustomerId : customerId,
                GetForAllCustomers : getForAllCustomers,
                GetAllStates: getAllStates,
                From : this.sdkService.Utilities.Dates.AddGmtTime(from),
                To : this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip : skip,
                Count : count
            }
        });
    }

    GetJobOrderEstimatesForList(jobOrderId : number) : Promise<IEstimateForList[]>
    {
        return this.ajaxService.Get("Documents-api", "Estimates/GetJobOrderEstimatesForList?jobOrderId=" + jobOrderId, { background: true });
    }

    onEstimateAdded(estimate : IEstimate) : void
    {
        if(estimate)
            estimate.Numero = estimate.Numero ? estimate.Numero : "";
        this.observers.forEach((observer : IEstimatesServiceObserver) => observer.onEstimateAdded(estimate));
    }

    onEstimateChanged(estimate : IEstimate) : void
    {
        this.observers.forEach((observer : IEstimatesServiceObserver) => observer.onEstimateChanged(estimate));
    }

    onEstimateDeleted(estimateId : number) : void
    {
        this.observers.forEach((observer : IEstimatesServiceObserver) => observer.onEstimateDeleted(estimateId));
    }

    onEstimateLockStateChanged(estimateId : number, locked : boolean) : void
    {
        this.observers.forEach((observer : IEstimatesServiceObserver) => observer.onEstimateLockStateChanged(estimateId, locked));
    }

    getDocumentDetailUrl(documentId : number, registerId : number) : string
    {
        return '/#/Documenti/Apri/Est' + registerId + '/' + documentId;
    }

    getNewDocumentUrl(registerId : number) : string
    {
        return '/#/Documenti/Nuovo/Est' + registerId;
    }

    getNewDocumentForJobOrderUrl(registerId : number, jobOrderId : number) : string
    {
        return '/#/Documenti/Nuovo/Est' + registerId + '/JobOrder/' + jobOrderId;
    }

    GetEstimatesForList(request: IGetEstimatesForListRequest): Promise<IEstimateForList[]> {
        let result = this.ajaxService.Post<IEstimateForList[]>("Invoices-api/Estimates", "GetEstimatesForList", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new EstimatesService(serviceLocator);
}
