import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 18/03/13
 * Time: 15.04
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BasePagedList } from "../Utilities/BasePagedList";
import { CustomersProvider, ICustomersProvider } from "../../../../Desktop/utilities/CustomersProvider";
import { InventoryWarehouse } from "./InventoryWarehouse";
import { WarehouseBusinessInfoDialog } from "./WarehouseBusinessInfoDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IWarehousesServiceObserver,
    IWarehousesService,
    IWarehouse,
    IWarehouseWithStockInfo,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";

export class InventoryManagement
    extends BasePagedList
    implements IView, ICustomersProvider, IWarehousesServiceObserver
{
    templateName: string = "warehouse-inventory-management";
    templateUrl: string = "warehouse/templates";
    viewModel: any;

    public title: string = ProlifeSdk.TextResources.Warehouse.WarehouseManagement;
    public selectedCustomer: ko.Observable<number> = ko.observable();

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;

    private ShowAllExistingWarehouses: ko.Observable<boolean> = ko.observable(true);
    private ShowDeleted: ko.Observable<boolean> = ko.observable(false);

    public SectionId: number = 2;

    constructor() {
        super();
        this.viewModel = this;
        this.warehousesService.addObserver(this);
        new CustomersProvider(this);

        this.selectedCustomer.subscribe(this.loadRows.bind(this));
        this.ShowAllExistingWarehouses.subscribe(this.loadRows.bind(this));
        this.ShowDeleted.subscribe(this.loadRows.bind(this));
        this.loadRows();
    }

    dispose() {
        this.warehousesService.removeObserver(this);
    }

    public fetchRows(): Promise<IWarehouse[]> {
        return this.warehousesService.getWarehouses(
            this.searchFilter(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage,
            [this.selectedCustomer()],
            this.ShowAllExistingWarehouses(),
            this.ShowDeleted(),
            false,
            []
        );
    }

    public fetchRowsCount(): Promise<number> {
        return this.warehousesService.getWarehousesCount(
            this.searchFilter(),
            this.selectedCustomer(),
            this.ShowAllExistingWarehouses(),
            this.ShowDeleted()
        );
    }

    public createViewModelFor(warehouse: IWarehouseWithStockInfo): InventoryWarehouse {
        return new InventoryWarehouse(warehouse);
    }

    public createNew() {
        this.warehousesService.ui.showCreateWarehouseDialog(this.selectedCustomer());
    }

    public editWarehouse(warehouse: InventoryWarehouse) {
        this.warehousesService.ui.showEditWarehouseDialog(warehouse.getData());
    }

    public DeleteWarehouse(warehouse: InventoryWarehouse) {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Warehouse.SureToDeleteWarehouse,
            ProlifeSdk.TextResources.Warehouse.DoNotDelete,
            ProlifeSdk.TextResources.Warehouse.Delete,
            (confirm: boolean) => {
                if (!confirm) return;

                this.warehousesService.remove(warehouse.warehouse.Id).then(() => {
                    this.loadPage();
                });
            }
        );
    }

    public UndeleteWarehouse(warehouse: InventoryWarehouse) {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Warehouse.SureToUndeleteWarehouse,
            ProlifeSdk.TextResources.Warehouse.DoNotUndelete,
            ProlifeSdk.TextResources.Warehouse.Undelete,
            (confirm: boolean) => {
                if (!confirm) return;

                this.warehousesService.undelete(warehouse.warehouse.Id).then(() => {
                    this.loadPage();
                });
            }
        );
    }

    public ShowBusinessInfo(warehouse: InventoryWarehouse) {
        var dialog: WarehouseBusinessInfoDialog = new WarehouseBusinessInfoDialog(warehouse.warehouse.Id);
        this.dialogService.ShowModal<void>(dialog, null, null, dialog.templateUrl, dialog.templateName);
    }

    public OpenStock(warehouse: InventoryWarehouse) {
        window.open(this.warehousesService.GetWarehouseStockUrl(warehouse.warehouse.Id));
    }

    findCustomers(query: any) {}
    findCustomer(element, callback) {}

    onWarehouseChanged(warehouse: IWarehouse): void {
        this.loadRows();
    }

    onWarehouseAdded(warehouse: IWarehouse): void {
        this.loadRows();
    }

    onWarehouseDeleted(warehouseId: number): void {
        this.loadRows();
    }
}
