import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { Layout } from "../Layouts";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ArrayDataSource, IArrayDataSourceModel } from "../../DataSources/ArrayDataSource";
import {
    IApplicationConfiguration,
    IApplicationsConfigurationsService,
} from "../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IListItem, List, ListItem } from "../ListComponent";
import { TextInput } from "../TextInput";
import { Portlet } from "../Portlet";
import { If, IfNot } from "../IfIfNotWith";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { TableFooterAggregationMode } from "./TableFooterAggregationMode";
import { TableColumnsConfiguration } from "./TableComponent";
import { Right } from "../../Core/Authorizations";
import { CheckBox } from "../Checkbox";

export interface IColumnInfo {
    columnId: number;
    visible: ko.Observable<boolean>;
    order: number;
    hasFooterAggregator: boolean;
    footerAggregatorMode: ko.Observable<TableFooterAggregationMode>;
}

type TableConfigurationsManagerProps = {
    configurations: IApplicationConfiguration[];
    configurationType: number;
    moduleId: number;
    objectId: number;

    actualConfigurationProvider: () => TableColumnsConfiguration;
    onApplyConfiguration?: (configuration: IApplicationConfiguration) => void;
};

interface ITableConfigurationDataSourceModel extends IArrayDataSourceModel<IApplicationConfiguration> {
    Preferred: ko.Observable<boolean>;
}

export function TableConfigurationsManager(props: TableConfigurationsManagerProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./TableConfigurationsManager")._TableConfigurationsManager as typeof _TableConfigurationsManager;
    return <C {...props} />;
}

export class _TableConfigurationsManager {
    static defaultProps: Partial<TableConfigurationsManagerProps> = {
        configurations: [],
    };

    private ConfigurationsDataSource: ArrayDataSource<IApplicationConfiguration> = new ArrayDataSource();

    private EditingMode: ko.Observable<boolean> = ko.observable();
    private EditingForEveryoneConfig: ko.Observable<boolean> = ko.observable(false);

    @Right("Core_SaveTableConfigForAllUsers")
    private canEditForEveryone: boolean;
    private canEditForSelf = true;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private NewConfigurationTitle: ko.Observable<string> = ko.observable("");
    private EditorTitle: ko.Observable<string> = ko.observable("");
    private ShowEditor: ko.Observable<boolean> = ko.observable(false);

    private configurationsModels: ko.ObservableArray<ITableConfigurationDataSourceModel> = ko.observableArray([]);

    private currentPreferredConfig: IApplicationConfiguration;
    private configurationOnEditing: IApplicationConfiguration;

    private preferredConfigObserver: ko.Computed<void>;
    private loading = false;

    constructor(private props: TableConfigurationsManagerProps) {
        this.loadConfigurations();
        this.loadUserPreferredConfiguration();

        this.preferredConfigObserver = ko.computed(() => {
            const configModels = this.configurationsModels();
            const preferredConfigs = configModels.filter((c) => c.Preferred());

            if (this.loading) return;

            if (
                preferredConfigs.length === 1 &&
                preferredConfigs[0].model.ConfigurationId === this.currentPreferredConfig?.ConfigurationId
            )
                return;

            if (preferredConfigs.length === 0) {
                this.applicationsConfigurationsService
                    .RemoveUserPreferredConfiguration(this.userInfo.getIdUser(), this.props.objectId)
                    .catch((e) => console.error(e));
                return;
            }

            const oldPreferredConfig = preferredConfigs.firstOrDefault(
                (c) => c.model.ConfigurationId === this.currentPreferredConfig?.ConfigurationId
            );
            oldPreferredConfig?.Preferred(false);

            const newPreferredConfig = preferredConfigs.firstOrDefault((c) => c.Preferred());
            this.currentPreferredConfig = newPreferredConfig?.model;

            this.applicationsConfigurationsService
                .SaveUserPreferredConfiguration(
                    this.userInfo.getIdUser(),
                    newPreferredConfig?.model.ConfigurationId,
                    this.props.objectId
                )
                .catch((e) => console.error(e));
        });
    }

    private async loadUserPreferredConfiguration(): Promise<void> {
        this.loading = true;

        try {
            const preferredConfig =
                await this.applicationsConfigurationsService.GetUserPreferredApplicationConfiguration(
                    this.userInfo.getIdUser(),
                    this.props.objectId
                );
            if (!preferredConfig) return;

            this.currentPreferredConfig = preferredConfig;

            const config = this.configurationsModels().firstOrDefault(
                (c) => c.model.ConfigurationId === preferredConfig.ConfigurationId
            );
            if (config) config.Preferred(true);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private newConfiguration() {
        this.configurationOnEditing = {
            ConfigurationId: null,
            ConfigurationType: this.props.configurationType,
            ModuleId: this.props.moduleId,
            ObjectId: this.props.objectId,
            Title: "",
            UserID: !this.canEditForEveryone ? this.userInfo.getIdUser() : null,
        };

        this.EditorTitle(TextResources.ProlifeSdk.NewTableConfiguration);
        this.NewConfigurationTitle("");
        this.EditingForEveryoneConfig(!this.configurationOnEditing.UserID);
        this.ShowEditor(true);
    }

    private editConfiguration(configuration: IApplicationConfiguration): void {
        this.configurationOnEditing = configuration;
        this.EditorTitle(TextResources.ProlifeSdk.EditTableConfiguration);
        this.NewConfigurationTitle(configuration.Title);
        this.EditingForEveryoneConfig(!configuration.UserID);
        this.ShowEditor(true);
    }

    private async saveConfiguration(forAllUsers = false): Promise<void> {
        if (!this.NewConfigurationTitle()) {
            this.infoToastService.Error(TextResources.ProlifeSdk.TableConfigurationMissingTitle);
            return;
        }

        let confirm = true;
        if (!forAllUsers && !this.configurationOnEditing.UserID)
            confirm = await this.dialogsService.ConfirmAsync(
                TextResources.ProlifeSdk.ChangesForAllUsersConfigurationMessage,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );

        if (forAllUsers && !!this.configurationOnEditing.UserID)
            confirm = await this.dialogsService.ConfirmAsync(
                TextResources.ProlifeSdk.ChangesUserConfigurationMessage,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );

        if (!confirm) return;

        this.configurationOnEditing.Title = this.NewConfigurationTitle();
        if (!forAllUsers) this.configurationOnEditing.UserID = this.userInfo.getIdUser();
        else this.configurationOnEditing.UserID = null;

        this.configurationOnEditing.Configuration = JSON.stringify(this.props.actualConfigurationProvider());

        try {
            this.configurationOnEditing = await this.applicationsConfigurationsService.AddOrUpdateConfiguration(
                this.configurationOnEditing
            );
            const currentConfigurations = this.ConfigurationsDataSource.getAllData();
            const configurationToUpdate = currentConfigurations.firstOrDefault(
                (c) => c.id === this.configurationOnEditing.ConfigurationId
            );

            if (!configurationToUpdate) {
                const config: ITableConfigurationDataSourceModel = {
                    id: this.configurationOnEditing.ConfigurationId,
                    title: this.configurationOnEditing.Title,
                    isGroup: false,
                    isLeaf: true,
                    model: this.configurationOnEditing,
                    Preferred: ko.observable(false),
                };
                currentConfigurations.push(config);
                this.props.configurations.push(this.configurationOnEditing);
            } else {
                configurationToUpdate.title = this.configurationOnEditing.Title;
                configurationToUpdate.model = this.configurationOnEditing;
            }

            this.ConfigurationsDataSource.setData(...currentConfigurations);
            this.ConfigurationsDataSource.refresh();

            this.infoToastService.Success(TextResources.ProlifeSdk.ApplicationConfiguationSaveSuccess);
            this.abortEditing();
        } catch (e) {
            console.log(e);
        }
    }

    private loadConfigurations(): void {
        try {
            this.configurationsModels(
                this.props.configurations.map((c) => ({
                    id: c.ConfigurationId,
                    title: c.Title,
                    isGroup: false,
                    isLeaf: true,
                    model: c,
                    Preferred: ko.observable(false),
                }))
            );

            this.ConfigurationsDataSource.setData(...this.configurationsModels());
            this.ConfigurationsDataSource.refresh();
        } catch (e) {
            console.log(e);
        }
    }

    private async deleteConfiguration(configuration: IApplicationConfiguration): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.ProlifeSdk.DeleteTableConfigurationMessage,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        try {
            await this.applicationsConfigurationsService.DeleteApplicationConfiguration(configuration.ConfigurationId);
            const configurations = this.ConfigurationsDataSource.getAllData();
            configurations.remove(configurations.firstOrDefault((c) => c.id === configuration.ConfigurationId));
            this.props.configurations.remove(
                this.props.configurations.firstOrDefault((c) => c.ConfigurationId === configuration.ConfigurationId)
            );
            this.ConfigurationsDataSource.setData(...configurations);
            this.ConfigurationsDataSource.refresh();
        } catch (e) {
            console.log(e);
        }
    }

    private abortEditing(): void {
        this.ShowEditor(false);
        this.configurationOnEditing = undefined;
        this.EditorTitle("");
    }

    private toggleEditingMode() {
        this.EditingMode(!this.EditingMode());
    }

    private applyConfiguration(configuration: IApplicationConfiguration): void {
        if (this.props.onApplyConfiguration) this.props.onApplyConfiguration(configuration);
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const cm = this;
        let $data: IListItem<IApplicationConfiguration>;

        const listItemRenderer = (item: ListItem<number, IApplicationConfiguration>) => (
            <div className="flex-container">
                <div className="flex-fill">
                    <i
                        className="fa"
                        data-bind={{ css: { "fa-user": !!$data.Model.UserID, "fa-users": !$data.Model.UserID } }}
                    ></i>
                    &nbsp;<span data-bind={{ text: $data.Model.Title }}></span>
                </div>
                <div className="flex-container" style={{ alignItems: "center" }}>
                    <If condition={this.EditingMode}>
                        {() => (
                            <>
                                <ko-bind
                                    data-bind={{
                                        if: (cm.canEditForSelf && !!$data.Model.UserID) || cm.canEditForEveryone,
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-circle btn-xs"
                                        data-bind={{ asyncClick: cm.deleteConfiguration.bind(cm, $data.Model) }}
                                    >
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-circle btn-xs"
                                        data-bind={{
                                            click: cm.editConfiguration.bind(cm, $data.Model),
                                            visible:
                                                cm.EditingMode() &&
                                                (($data.Model.UserID > 0 && cm.canEditForSelf) ||
                                                    (!$data.Model.UserID && cm.canEditForEveryone)),
                                        }}
                                    >
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                </ko-bind>
                                <CheckBox
                                    checked={(item.dataSourceModel as ITableConfigurationDataSourceModel).Preferred}
                                    icon
                                    onIcon="fa fa-star"
                                    offIcon="fa fa-star-o"
                                    style={{ margin: 0, marginLeft: "auto", marginRight: "5px", fontSize: "1.5em" }}
                                />
                            </>
                        )}
                    </If>
                    <IfNot condition={this.EditingMode}>
                        {() => (
                            <button
                                type="button"
                                className="btn btn-primary btn-xs btn-circle"
                                data-bind={{ click: cm.applyConfiguration.bind(cm, $data.Model) }}
                            >
                                {TextResources.ProlifeSdk.Apply}
                            </button>
                        )}
                    </IfNot>
                </div>
            </div>
        );

        return ComponentUtils.bindTo(
            <Layout.WithHeader style={{ width: "1200px" }}>
                <Layout.WithHeader.Header>
                    <button
                        className="btn btn-primary btn-circle btn-icon-only"
                        title={TextResources.ProlifeSdk.NewTableConfiguration}
                        data-bind={{
                            click: cm.newConfiguration.bind(cm),
                            visible: cm.canEditForEveryone || cm.canEditForSelf,
                        }}
                    >
                        <i className="fa fa-plus"></i>
                    </button>
                    <button
                        className="btn btn-circle btn-icon-only"
                        title={TextResources.ProlifeSdk.EditTableConfigurations}
                        data-bind={{
                            click: cm.toggleEditingMode.bind(cm),
                            visible: cm.canEditForEveryone || cm.canEditForSelf,
                            css: { "btn-warning": cm.EditingMode, "btn-success": !cm.EditingMode() },
                        }}
                    >
                        <i className="fa fa-pencil"></i>
                    </button>
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content>
                    <If condition={this.ShowEditor}>
                        {() => (
                            <Portlet collapsible={false} initialCollapsed={false} flex={false}>
                                <Portlet.Header>
                                    <span data-bind={{ text: cm.EditorTitle }}></span>
                                </Portlet.Header>
                                <Portlet.Actions>
                                    <ko-bind
                                        data-bind={{
                                            if:
                                                (cm.canEditForSelf && !cm.EditingForEveryoneConfig()) ||
                                                cm.canEditForEveryone,
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn-circle"
                                            data-bind={{ asyncClick: () => cm.saveConfiguration() }}
                                        >
                                            <i className="fa fa-save"></i>&nbsp;
                                            {TextResources.ProlifeSdk.SaveTableConfigForUser}
                                        </button>
                                        &nbsp;
                                    </ko-bind>
                                    {this.canEditForEveryone && (
                                        <>
                                            <button
                                                className="btn btn-danger btn-circle"
                                                data-bind={{ asyncClick: () => cm.saveConfiguration(true) }}
                                            >
                                                <i className="fa fa-save"></i>&nbsp;
                                                {TextResources.ProlifeSdk.SaveTableConfigForAllUsers}
                                            </button>
                                            &nbsp;
                                        </>
                                    )}
                                    <button
                                        className="btn btn-default btn-circle"
                                        data-bind={{ asyncClick: cm.abortEditing.bind(cm) }}
                                    >
                                        <i className="fa fa-times"></i>
                                    </button>
                                </Portlet.Actions>
                                <Portlet.Body>
                                    {() => (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TextInput
                                                    value={this.NewConfigurationTitle}
                                                    placeholder={
                                                        TextResources.ProlifeSdk.TableNewConfigurationPlaceholder
                                                    }
                                                    selectOnFocus
                                                />
                                                <div className="alert alert-warning">
                                                    {TextResources.ProlifeSdk.TableConfigurationHelpText}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Portlet.Body>
                            </Portlet>
                        )}
                    </If>
                    <List
                        dataSource={this.ConfigurationsDataSource}
                        containerHeight="flex"
                        itemRenderer={listItemRenderer}
                        systemScrollable
                        scrollable={false}
                    />
                </Layout.WithHeader.Content>
            </Layout.WithHeader>,
            this,
            "cm"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(TableConfigurationsManager);
}
