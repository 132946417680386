import * as ko from "knockout";
import { IReferenceForMap } from "../../../../bindings";

export class NodeSubElement
{
    IsSelected : ko.Observable<boolean> = ko.observable(false);
    EntityType : string;
    EntityId : number;

    constructor(private node : any, entityId : number, entityType : string)
    {
        this.EntityType = entityType;
        this.EntityId = entityId;
    }

    OnClick()
    {
        this.node.OnSelectionChanged(this);
    }

    public IsDestinationFor(entityId : number, entityType : string, allReferences : IReferenceForMap[])
    {
        var matches: IReferenceForMap[] = allReferences.filter((r : IReferenceForMap) => { return r.SourceId == entityId && r.SourceEntityType == entityType });

        var result : boolean = false;
        matches.forEach((r : IReferenceForMap) => {
            result = result
                || (r.DestId == this.EntityId && r.DestEntityType == this.EntityType)
                || this.IsDestinationFor(r.DestId, r.DestEntityType, allReferences)
        });
        return result;
    }

    public IsSourceFor(entityId : number, entityType : string, allReferences : IReferenceForMap[])
    {
        var matches: IReferenceForMap[] = allReferences.filter((r : IReferenceForMap) => { return r.DestId == entityId && r.DestEntityType == entityType });

        var result : boolean = false;
        matches.forEach((r : IReferenceForMap) => {
            result = result
                || (r.SourceId == this.EntityId && r.SourceEntityType == this.EntityType)
                || this.IsSourceFor(r.SourceId, r.SourceEntityType, allReferences)
        });
        return result;
    }
}
