import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { BaseDocumentForWizard } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/BaseDocumentForWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { ISalRowForDocuments, ISalForWizard, ISalRow } from "../../../../ProlifeSdk/interfaces/sal/ISal";
import { ISalService } from "../../../../ProlifeSdk/interfaces/sal/ISalService";
import { IBaseDocumentRowForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentBuilderDocumentRelatedWorkflows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DocumentDataSourceDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/data-sources/DocumentDataSourceDataWizardStep";

export class SalsDataSource extends DocumentDataSourceDataWizardStep<ISalRowForDocuments, SalRow>
{
    DateFrom : ko.Observable<Date> = ko.observable();
    DateTo : ko.Observable<Date> = ko.observable(new Date());

    Sals : ko.ObservableArray<Sal> = ko.observableArray();

    ShowAlreadyImported : ko.Observable<boolean> = ko.observable(false);
    
    @LazyImport(nameof<ISalService>())
    private salService : ISalService;

    public SelectAllRows : ko.Computed<boolean>;
    private DisableDocumentsReload  = false;
    private referenceDate : Date;

    public WarningMessages : ko.Observable<string> = ko.observable();
    public ExpandedColumn: ko.Observable<number> = ko.observable(1);
    previousStepRows: IDocumentDataWizardRow[];

    constructor(private serviceLocator : IServiceLocator)
    {
        super();
        this.Title(TextResources.JobOrder.SAL);
        
        this.installWatches();

        this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.InvoiceEntityTypeCode,  
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode
        );

        this.SelectAllRows = ko.computed({
            read: () => {
                const documents = this.SelectedDocuments();
                let rowsCount = 0;
                let selectedRowsCount = 0;
                
                documents.forEach((e: Sal) => {
                    const allRows = e.Rows();
                    const selectedRows = allRows.filter((r : SalRow) => { return r.Selected(); })

                    rowsCount += allRows.length;
                    selectedRowsCount += selectedRows.length;
                });

                return rowsCount === selectedRowsCount ? true : selectedRowsCount === 0 ? false : undefined;
            },
            write: (selected : boolean) => {
                this.SelectedDocuments().forEach((e : Sal) => {
                    e.Rows().forEach((r : SalRow) => {
                        r.Selected(selected);
                    });
                });
            }
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Amount,
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: r.NetUnitPriceInDocumentCurrency,
                NetUnitPrice: r.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency),
                Order: 0,
                TotalPriceInDocumentCurrency: r.TotalPriceInDocumentCurrency,
                TotalPrice: r.TotalPriceInDocumentCurrency.ToEuro(documentCurrency),
                UnitPriceInDocumentCurrency: r.PriceInDocumentCurrency,
                UnitPrice: r.PriceInDocumentCurrency.ToEuro(documentCurrency),
                Description: r.Description,
                Discounts: r.Discounts,
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: this.getRelatedWorkflows(r),
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : ISalRowForDocuments) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        for(const sourceRow of r.Rows.filter(r => r.Amount != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType : ProlifeSdk.SalEntityTypeCode,
                SourceEntityKeyId : sourceRow.SalRowId,
                DestEntityType : this.initializationInfo.DocTypeCode,
                DestEntityKeyId : 0,
                Amount: sourceRow.Amount,
                UnitPrice : sourceRow.UnitPrice,
                Discounts : sourceRow.Discounts,
                NetUnitPrice: sourceRow.NetUnitPrice,
                NetPrice : sourceRow.NetPrice
            });
        }
        return refs;
    }

    protected getRelatedWorkflows(source : ISalRowForDocuments) : IDocumentBuilderDocumentRelatedWorkflows[] {
        const result : IDocumentBuilderDocumentRelatedWorkflows[] = [];

        for(const row of source.Rows) {
            for(const rw of row.RelatedWorkflows || []) {

                result.push({
                    Id : null,
                    WorkflowId : rw.WorkflowId,
                    DocumentId : null,
                    DocumentType: this.initializationInfo.DocTypeCode,
                    DocumentNumber: null,
                    DocumentDate: null,
                    DocumentProtocolId: null,
                    RowId: 0,
                    RowDescription: null,
                    RowAmount: source.Amount,
                    RowUoM: source.Udm,
                    RowPrice: source.NetUnitPrice,
                    WorkflowAmount: rw.WorkflowAmount,
                    ValidityStartDate: null,
                    WorkflowName: rw.WorkflowName
                });
            }
        }

        return result;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    protected override onImportSelectedRows(someDocumentsCannotBeImported: boolean) {}

    public async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        this.DisableDocumentsReload = true;
        await super.Initialize(initializationInfo);
        
        this.SelectedDocuments([]);
        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf('year').toDate());
        this.DateTo(this.referenceDate);
        this.DisableDocumentsReload = false;
        await this.loadSals();
    }

    public OnLoad()
    {

    }

    private installWatches()
    {
        this.DisableDocumentsReload = true;
        this.DateFrom.subscribe(this.loadSals.bind(this));
        this.DateTo.subscribe(() => {
            if(this.DateTo() > this.referenceDate) {
                this.WarningMessages(String.format(ProlifeSdk.TextResources.JobOrder.WatchWarning, moment(this.referenceDate).format("L")));
            } else {
                this.WarningMessages(undefined);
            }
            this.loadSals();
        });
        this.ShowAlreadyImported.subscribe(this.loadSals.bind(this));
        this.DisableDocumentsReload = false;
    }

    private async loadSals()
    {
        if(this.DisableDocumentsReload)
            return;

        const sals = await this.salService.GetSalsForWizard("",
            false, this.initializationInfo.JobOrderId, true, -1, this.DateFrom(), this.DateTo(),  0, 1000000)
        this.onSalsLoaded(sals);
    }

    private onSalsLoaded(sals : ISalForWizard[])
    {
        this.SelectedDocuments([]);
        this.Sals(sals.map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(sal : ISalForWizard) : Sal
    {
        return new Sal(this.serviceLocator, sal, this.Rows, this.initializationInfo.DocumentOriginatingRows, this.ShowAlreadyImported, this);
    }

    public removeRow(r : ISalRowForDocuments)
    {
        this.Rows.remove(r);
    }

    expandColumn(colId: number): void {
        this.ExpandedColumn(colId);
    }

    public async GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        const docsToImport : Sal[] = [];
        this.Rows().forEach((r : ISalRowForDocuments) => {
            r.Rows.forEach((dr : ISalRow) => {
                const matches : Sal[] = this.Sals().filter((e : Sal) => { return e.salData.Id == dr.SalId; });

                if(matches.length == 0 || docsToImport.indexOf(matches[0]) > -1)
                    return;

                docsToImport.push(matches[0]);
            });
        });


        return this.SelectedDocuments().map((d : Sal) => {
            return {
                Id : d.salData.Id,
                Name : String.format(ProlifeSdk.TextResources.JobOrder.SALName, d.Number(), moment(d.Date()).format("L")),
                EntityType : ProlifeSdk.SalEntityType
            }
        });
    }

    render() {
        let step : SalsDataSource;
        let sal: Sal;
        let row: SalRow;
        let outRow: ISalRowForDocuments;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 0px 15px;">
                <div class="row wizard-header">
                    <div class="col-md-7 wizard-header-block">
                        <div class="row">
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                            <div class="col-md-3">
                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateFrom}}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                            <div class="col-md-3">
                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateTo}}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <ko-bind data-bind={{ id: step.DocumentCurrenciesEnabled }}>
                                <div class="col-md-4" data-bind={{ with: step.initializationInfo.DocumentCurrenciesInfo }}>
                                <document-currencies-popover 
                                        readonly-document-currency={() => "LockDocumentCurrencyChanges"}
                                        readonly-exchange-value={() => "LockExchangeValueChanges"}
                                        readonly-exchange-valueForVat={() => "LockExchangeValueForVatChanges"}
                                        document-currencies={() => "DocumentCurrencies"}
                                        button-css-classes='btn btn-sm blue'
                                        popover-css-classes='currencies-manager-for-modal'
                                        button-text={TextResources.Invoices.CurrenciesLabel}></document-currencies-popover>
                                </div>
                            </ko-bind>
                        </div>
                    </div>
                    <div class="col-md-5 wizard-header-block">
                        <div class="alert alert-danger" style="margin-top: -8px; margin-bottom: -8px;" data-bind={{visible: !!step.WarningMessages(), text: step.WarningMessages}}>

                        </div>
                    </div>
                </div>

                <div class="row wizard-body">
                    <div class="wizard-body-column first-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 1, 'collapsed-column': step.ExpandedColumn() != 1 }, click: step.expandColumn.bind(step, 1)}}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-center closure-state-col">
                                            <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardClosureStatus}></i>
                                        </th>
                                        <th class="doc-number-col">{TextResources.Invoices.DocumentWizardNumber}</th>
                                        <th class="doc-date-col">{TextResources.Invoices.DocumentWizardDate}</th>
                                        <th class="text-right">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                        <th class="doc-date-col">{TextResources.Invoices.CurrencyLabel}</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        <div class="wizard-data-source-table-body">
                            <div data-bind={{slimScroll: 'auto'}}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                    <ko-bind data-bind={{if : step.Sals().length == 0 }}>
                                    <tr>
                                        <td colSpan={3} class="text-center">{TextResources.Invoices.DocumentWizardNoSAL}</td>
                                    </tr>
                                    </ko-bind>
                                    <ko-bind data-bind={{foreach: { data: step.Sals, as: 'sal' }}}>
                                    <tr data-bind={{click: sal.SwitchSelection, css : { active : sal.IsSelected }}}>
                                        <td class="text-center closure-state-col">
                                            <div class="label label-sm" data-bind={{css : { 'label-warning' : sal.ClosureStatus() == 1, 'label-danger': sal.ClosureStatus() == 0, 'label-success': sal.ClosureStatus() == 2 }, click: sal.openDocument.bind(sal), style: { cursor: 'pointer' }}}>
                                                <i class="fa fa-file-text" data-bind={`attr : { title :  sal.ClosureStatus() == 1 ? '${TextResources.Invoices.DocumentWizardPartiallyClosed}' : (sal.ClosureStatus() == 0 ? '${TextResources.Invoices.DocumentWizardClosed}' : '${TextResources.Invoices.DocumentWizardOpened}') }`}></i>
                                            </div>
                                        </td>
                                        <td class="doc-number-col">
                                            <ko-bind data-bind={{ text: sal.Number }}></ko-bind>
                                        </td>
                                        <td class="doc-date-col" data-bind={{dateText: sal.Date}}></td>
                                        <td class="text-right" data-bind={{moneyText: sal.Total, currencySymbol: sal.CurrencySymbol, documentCurrency: sal.DocumentCurrency}}></td>
                                        <td class="text-right" data-bind={{text: sal.CurrencyCode}}></td>
                                    </tr>
                                    </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="wizard-body-column second-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 2, 'collapsed-column': step.ExpandedColumn() != 2 }, style: { left: (step.ExpandedColumn() == 3 || step.ExpandedColumn() == 2 ? '25%' : '50%') }, click: step.expandColumn.bind(step, 2)}}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                            <input type="checkbox" data-bind={{checkbox : step.SelectAllRows}} />
                                        </th>
                                        <th class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                            <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                        </th>
                                        <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                        <th class="text-center closed-amount-col">{TextResources.Invoices.DocumentWizardImportedAmount}</th>
                                        <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmountToImport}</th>
                                        <th class="unit-of-measure-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardUoM}</th>
                                        <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                        <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        <div class="wizard-data-source-table-body">
                            <div data-bind={{slimScroll: 'auto'}}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                        <ko-bind data-bind={{if : step.SelectedDocuments().length == 0 }}>
                                        <tr>
                                            <td colSpan={8} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                        </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{ foreach : { data: step.SelectedDocuments, as: 'sal' }}}>
                                            <ko-bind data-bind={{ foreach : { data: sal.Rows, as: 'row' }}}>
                                                <tr data-bind={{css : { active : row.Selected }}}>
                                                    <td class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                        <input type="checkbox" data-bind={{checkbox : row.Selected}} />
                                                    </td>
                                                    <td class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                        <ko-bind data-bind={{if : row.AlreadyImportedInThisDocument }}>
                                                            <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                                        </ko-bind>
                                                    </td>
                                                    <td><span data-bind={{text: row.Description}}></span></td>
                                                    <ko-bind data-bind={{ifnot : row.ManuallyClosed }}>
                                                        <td class="text-right closed-amount-row" data-bind={{numberText : row.ClosedAmount}}></td>
                                                    </ko-bind>
                                                    <ko-bind data-bind={{if : row.ManuallyClosed }}>
                                                        <td class="text-center closed-amount-row">
                                                            <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardManuallyClosed}></i>
                                                        </td>
                                                    </ko-bind>
                                                    <td class="text-right amount-col" data-bind={{numberText: row.Amount}}></td>
                                                    <td class="unit-of-measure-col" data-bind={{text: row.UnitOfMeasure, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                    <td class="text-right unit-price-col" data-bind={{moneyText: row.NetUnitPrice, currencySymbol: sal.CurrencySymbol, documentCurrency: sal.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                    <td class="text-right total-price-col" data-bind={{moneyText: row.Total, currencySymbol: sal.CurrencySymbol, documentCurrency: sal.DocumentCurrency}}></td>
                                                </tr>
                                            </ko-bind>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="wizard-body-column third-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 3, 'collapsed-column': step.ExpandedColumn() != 3 }, click: step.expandColumn.bind(step, 3)}}>
                        <div class="text-center" data-bind={{visible: step.ExpandedColumn() == 3}}>
                            <button type="button" class="btn btn-sm btn-primary" data-bind={{click: step.importSelectedRows}}>{TextResources.Invoices.DocumentWizardImportRows}</button>
                            <button type="button" class="btn btn-sm btn-danger" data-bind={{click: step.ClearImportedRows}}>{TextResources.Invoices.DocumentWizardClear}</button>
                        </div>
                        <div class="wizard-body-column-with-actions" data-bind={{style: { top: step.ExpandedColumn() == 3 ? '37px' : '0px', bottom: step.ExpandedColumn() == 3 ? '0px' : '37px' } }}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                            <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                            <th class="action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardRemove}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                        <ko-bind data-bind={{if : step.Rows().length == 0 }}>
                                        <tr>
                                            <td colSpan={5} class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                        </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{foreach : { data: step.Rows, as: 'outRow' }}}>
                                        <tr>
                                            <td data-bind={{text: outRow.Description}}></td>
                                            <td class="text-right amount-col" data-bind={{numberText: outRow.Amount}}></td>
                                            <td class="text-right unit-price-col" data-bind={{moneyText: outRow.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                            <td class="text-right total-price-col" data-bind={{moneyText: outRow.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency}}></td>
                                            <td class="text-center action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>
                                                <a href="#" class="btn btn-xs red" data-bind={{click: step.removeRow.bind(step, outRow)}}>
                                                    <i class="fa fa-times"></i>
                                                    {TextResources.Invoices.DocumentWizardRemove}
                                                </a>
                                            </td>
                                        </tr>
                                        </ko-bind>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        , this, "step");
    }
}

class Sal extends BaseDocumentForWizard<SalRow>
{
    SelectAll : ko.Computed<boolean>;

    private rowsLoaded  = false;

    constructor(private serviceLocator : IServiceLocator, public salData : ISalForWizard, private RowsToImport : ko.ObservableArray<ISalRowForDocuments>,
                private previouslyImportedRows : IDocumentBuilderDocumentOriginatingRows[], private showAlreadyImportedTrigger, private rowsStorage : SalsDataSource)
    {
        super(salData, ProlifeSdk.SalEntityTypeCode);

        this.SelectAll = ko.computed({
            read: () => {
                const rows = this.Rows();
                return rows.filter((r : SalRow) => !r.Selected()).length == 0;
            },
            write: (selected : boolean) => {
                const rows = this.Rows();
                rows.forEach((r : SalRow) => r.Selected(selected));
            }
        });
    }

    public SwitchSelection()
    {
        this.IsSelected(!this.IsSelected());
        if(this.IsSelected())
        {
            this.loadRows().then(() => { this.rowsStorage.SelectedDocuments.push(this); });
        }
        else
            this.rowsStorage.SelectedDocuments.remove(this);
    }

    public loadRows()  : Promise<any>
    {
        if(this.rowsLoaded)
            return Promise.resolve();

        const salService : ISalService = this.serviceLocator.findService(ProlifeSdk.SalServiceType);
        return salService.getDocumentRowsById(this.salData.Id)
            .then(this.onRowsLoaded.bind(this))
            .catch(() => this.rowsLoaded = false);
    }

    private onRowsLoaded(rows : ISalRow[])
    {
        this.Rows(rows.map(this.createViewModelFor.bind(this)));
        this.rowsLoaded = true;
    }

    public doImportSelectedRows()
    {
        const selectedRows = this.Rows().filter((r : SalRow) => { return r.Selected(); });
        const rowsToImport = selectedRows.filter((r : SalRow) => !r.wasPreviouslyImportedInThisDocument() && !r.wasImportedDuringThisWizard());

        rowsToImport.forEach((r : SalRow) => {
            const row = r.createImportRow();

            const sourceDocumentCurrency = this.DocumentCurrency();

            if (this.rowsStorage.ApplyCurrenciesExchangeValues(row, sourceDocumentCurrency))
                this.RowsToImport.push(row);
        });
        
        return rowsToImport.length != selectedRows.length;
    }

    private createViewModelFor(salRow : ISalRow)
    {
        return new SalRow(salRow, this.RowsToImport, this.previouslyImportedRows);
    }

    public getId() : number
    {
        return this.salData.Id;
    }
}

class SalRow implements IBaseDocumentRowForWizard
{
    Selected : ko.Observable<boolean> = ko.observable(true);
    Description : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitOfMeasure : ko.Observable<string> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();

    AlreadyImportedInThisDocument : ko.Observable<boolean> = ko.observable(false);

    constructor(public salRow : ISalRow, private RowsToImport : ko.ObservableArray<ISalRowForDocuments>, private previouslyImportedRows : IDocumentBuilderDocumentOriginatingRows[])
    {
        const amount = Math.max(salRow.Amount - salRow.ClosedAmount, 0);

        this.Description(salRow.Description);
        this.Amount(amount);
        this.UnitOfMeasure(salRow.UnitOfMeasure);
        this.UnitPrice(salRow.UnitPriceInDocumentCurrency); // this.UnitPrice(row.UnitPrice);
        this.NetUnitPrice(salRow.NetUnitPriceInDocumentCurrency || 0); // this.NetUnitPrice(row.NetUnitPrice || 0);
        this.Total((salRow.NetUnitPriceInDocumentCurrency || 0) * (amount)); // this.Total((row.NetUnitPrice || 0) * (amount));
        this.ManuallyClosed(salRow.ManuallyClosed);
        this.ClosedAmount(salRow.ClosedAmount);

        this.AlreadyImportedInThisDocument(this.wasPreviouslyImportedInThisDocument());
    }

    public setAmountAndUpdateTotal(amount: number): void {
        this.Amount(amount);
        this.Total((amount) * (this.salRow.NetUnitPriceInDocumentCurrency || 0));
    }

    public isAlreadyImported() : boolean
    {
        return this.wasPreviouslyImportedInThisDocument() || this.wasImportedDuringThisWizard() || this.wasAlreadyImportedInADocument();
    }

    public wasPreviouslyImportedInThisDocument() : boolean {
        return this.previouslyImportedRows
            .filter((ref : IRefDocumentRow) => ref.SourceEntityKeyId == this.salRow.SalRowId && ref.SourceEntityType == ProlifeSdk.SalEntityTypeCode)
            .length > 0;
    }

    public wasAlreadyImportedInADocument() : boolean {
        const sign: number = this.salRow.Amount < 0 ? -1 : 1;
        return this.salRow.ReferencingRows.length > 0 && ((this.salRow.Amount - this.salRow.ClosedAmount) * sign <= 0);
    }

    public wasImportedDuringThisWizard() : boolean {
        return this.RowsToImport()
            .filter((r : ISalRowForDocuments) => r.Rows.filter((er : ISalRow) => er.SalRowId == this.salRow.SalRowId && er.EntityType == this.salRow.EntityType).length > 0)
            .length > 0
    }

    public createImportRow() : ISalRowForDocuments
    {
        const referencedRow: ISalRow = $.extend(true, {}, this.salRow);

        referencedRow.Amount = this.Amount();
        referencedRow.NetUnitPrice = this.NetUnitPrice();  // Viene convertito in Euro dal Sal
        referencedRow.NetPrice = this.Total();  // Viene convertito in Euro dal Sal
        referencedRow.NetUnitPriceInDocumentCurrency = this.NetUnitPrice();
        referencedRow.NetPriceInDocumentCurrency = this.Total();

        const salRow : ISalRowForDocuments = {
            isFullSal : false,
            Rows : [referencedRow],
            Description : this.Description(),
            Amount : this.Amount(),
            UnitPrice : this.UnitPrice(), // Viene convertito in Euro dal Sal
            PriceInDocumentCurrency : this.UnitPrice(), // Viene convertito in valuta documento dal Sal
            NetUnitPrice : this.NetUnitPrice(), // Viene convertito in Euro dal Sal
            NetUnitPriceInDocumentCurrency : this.NetUnitPrice(), // Viene convertito in valuta documento dal Sal
            Discounts : this.salRow.Discounts,
            Total : this.Total(), // Viene convertito in Euro dal Sal
            TotalPriceInDocumentCurrency : this.Total(), // Viene convertito in valuta documento dal Sal
            Udm : this.salRow.UnitOfMeasure,
            OffsetId: this.salRow.OffsetId,
            OffsetCode: this.salRow.OffsetCode,
        };

        return salRow;
    }

    public getId() : number
    {
        return this.salRow.SalRowId;
    }
}
