import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Table } from "../../../TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDataSourceColumn, IDataSourceInput } from "../../../../Desktop/DataSourcesService";
import { List, ListItem } from "../../../ListComponent";
import { Layout } from "../../../Layouts";

const styleSheet = jss.createStyleSheet({
    reportDataSourceInputViewer: {
        "&.list-notification-container .list-notification.list-notification-fit .list-notification-item": {
            borderLeft: '1px solid #e5e5e5',
            borderTop: '0px',
            borderRight: '1px solid #e5e5e5',
            borderBottom: '1px solid #e5e5e5',
            fontSize: '12px',
            padding: '0px 5px',

            "&:first-of-type": {
                borderTop: '1px solid #e5e5e5',
            },

            "& .list-notification-item-details": {
                "& .list-notification-item-title": {
                    margin: 0,
                },

                "& .list-notification-item-time": {
                    fontSize: '0.9em'
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDataSourceInputViewerProps = {
    inputDefinition: IDataSourceInput[];
}

export function ReportDataSourceInputViewer(props: ReportDataSourceInputViewerProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./ReportDataSourceInputViewer")._ReportDataSourceInputViewer as typeof _ReportDataSourceInputViewer;
    return <C {...props} />;
}

export class _ReportDataSourceInputViewer {
    static defaultProps: Partial<ReportDataSourceInputViewerProps> = {
    }

    constructor(private props : ReportDataSourceInputViewerProps) {
        
    }

    render() {
        const dataSource = Table.defaultDataSource(this.props.inputDefinition, (item) => ({ id: item.Name, title: "@" + item.Name, subTitle: item.Description, icon: { icon: "fa fa-thumb-tack" }, dragEnabled: true }));

        return  <Layout.ScrollContainer systemScrollable>
                    <div className="form-group">
                        <label className="control-label">Paramteri di ingresso</label>
                        <List 
                            scrollable={false} 
                            className={classNames("flex-1", classes.reportDataSourceInputViewer)} 
                            dataSource={dataSource}
                            sortable
                            onBeginDrag={(m, dt) => { dt.setData("application/report-input-parameter", JSON.stringify(m.model)) }}
                            itemRenderer={(item) => this.renderItem(item)} />
                        <div className="help-block">Trascina i parametri di ingresso su una colonna nella lista a destra per specificare il filtro</div>
                    </div>
                </Layout.ScrollContainer>
    }

    renderItem(item: ListItem<string, IDataSourceInput>): React.ReactElement {
        return  <div className="flex-container flex-child-center">
                    <div data-bind={{ style: { backgroundColor: item.Background } }}>
                        <i data-bind={{ css: item.Icon, style: { color: item.Foreground } }} />
                    </div>
                    <div className="flex-1">
                        <div class="list-notification-item-title" data-bind={{ text: item.Title }}></div>
                        <div class="list-notification-item-time" data-bind={{ text: item.Subtitle }}></div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDataSourceInputViewer);
}