import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 04/07/13
 * Time: 11.09
 * To change this template use File | Settings | File Templates.
 */

export class OpenPopover {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) {
        var $popover = $(valueAccessor());

        $(element).click((e) => {
            e.preventDefault();
            e.stopPropagation();

            if($popover.hasClass("in")) {
                $popover
                    .removeClass("in")
                    .on("webkitTransitionEnd.openPopover", () => {
                        $popover.css({ display: 'none' });
                        $popover.off("webkitTransitionEnd.openPopover");
                    })
                    .off("click.openPopover");

                $(document)
                    .off("click.openPopover.document");

            } else {
                var width = $popover.width();
                var top = $(element).outerHeight();
                var left = $(element).position().left + $(element).outerWidth() / 2;

                $popover
                    .on("click.openPopover", (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    })
                    .css({
                        top: top,
                        left: left - (width / 2),
                        display: 'block'
                    });
                $popover.addClass("in");

                $(document).on('click.openPopover.document', () => {
                    $popover
                        .removeClass("in")
                        .on("webkitTransitionEnd.openPopover", () => {
                            $popover.css({ display: 'none' });
                            $popover.off("webkitTransitionEnd.openPopover");
                        })
                        .off("click.openPopover");

                    $(document)
                        .off("click.openPopover.document");
                })
            }
        });
    }
}

ko.bindingHandlers["openPopover"] = new OpenPopover();