import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { DdtStatesEditingViewModel } from "./ui/DdtStatesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDdtStates, IDdtState, IDdtStatesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IDdtStates";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class DdtStatesSettingsManager implements IDdtStates
{
    private ajaxService : IAjaxService;

    private states : IDdtState[] = [];
    private observers : IDdtStatesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<IDdtState[]>
    {
        return this.ajaxService.Get<IDdtState[]>("Invoices-api", "DdtState?getDeleted=true", {})
            .then((states: IDdtState[]) => {
                this.states = states;
                return states;
            });
    }

    getName(): string {
        return ProlifeSdk.DdtStates;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Invoices.DeliveryStatus;
    }

    hasEditingUI(): boolean
    {
        return false;
    }

    getEditingUI(): IView {
        return {
            templateName: 'ddt-states',
            templateUrl: 'invoices/templates/settings',
            viewModel: new DdtStatesEditingViewModel(this.serviceLocator, this)
        };
    }

    getStates(getDeleted : boolean = false) : IDdtState[] {
        return this.states.filter((s : IDdtState) => {
            return getDeleted || s.Deleted == 0;
        });
    }

    getStateById(id : number) : IDdtState
    {
        var states = this.states.filter((s : IDdtState) => s.Id == id);
        if(states.length == 0) return null;
        return states[0];
    }

    createOrUpdate(state : IDdtState) : Promise<IDdtState> {
        return this.ajaxService.Post<IDdtState>("Invoices-api", "DdtState", { methodData: state})
            .then(this.onStateAdded.bind(this));
    }

    remove(id : number) : Promise<void>
    {
        if(!id) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService.Delete<void>("Invoices-api", "DdtState/" + id, {})
            .then(this.onStateDeleted.bind(this, id));
    }

    restore(id : number) : Promise<IDdtState>
    {
        return this.ajaxService.Post<IDdtState>("Invoices-api", "DdtState/Restore?id=" + id, {})
            .then(this.onStateAdded.bind(this));
    }

    addObserver(observer : IDdtStatesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IDdtStatesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onStateChanged(state : IDdtState) {
        this.load();
        this.observers.forEach((obs : IDdtStatesObserver) => obs.onStateChanged(state));
        return state;
    }

    private onStateAdded(state : IDdtState) {
        this.load();
        this.observers.forEach((obs : IDdtStatesObserver) => obs.onStateAdded(state));
        return state;
    }

    private onStateDeleted(id : number) {
        this.load();
        this.observers.forEach((obs : IDdtStatesObserver) => obs.onStateDeleted(id));
        return id;
    }
}