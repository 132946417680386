import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentNumberPrintChoices } from "../../../enums/DocumentNumberPrintChoices";

export interface IDocumentNumberPrintChoice {
    Id: number,
    Description: string;
}

export class NumberPrintChoicesProvider {
    public static getAvailableChoices(): IDocumentNumberPrintChoice[] {
        return [
            { Id: DocumentNumberPrintChoices.OnlyProtocolNumber, Description: ProlifeSdk.TextResources.Invoices.PrintOnlyDocumentProtocolNumber },
            { Id: DocumentNumberPrintChoices.OnlyVersionNumber, Description: ProlifeSdk.TextResources.Invoices.PrintOnlyDocumentVersionNumber },
            { Id: DocumentNumberPrintChoices.All, Description: ProlifeSdk.TextResources.Invoices.PrintAllDocumentNumbers }
        ];
    }
}