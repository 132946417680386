import * as React from "@abstraqt-dev/jsxknockout";
import { VerticalLayout, LayoutColumn } from "../../../../Components/Layouts";
import { PreOrdersList } from "./PreOrdersList";
import { PreOrdersEditor } from "./PreOrdersEditor";
import { INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";
import { Route, RouteParams, Routes } from "../../../../Components/Routing";

type PreOrdersPanelProps = RouteParams<{
    navBarActionManager: INavBarActionManager;
}>;
export class PreOrdersPanel {
    constructor(public props : PreOrdersPanelProps) {
    }

    render() {
        return  <VerticalLayout className="flex-1">
                    <LayoutColumn size={12}>
                        <Routes className="flex-container flex-full-height">
                            <Route path="/Provisioning/PreOrders" exact element={(p) => <PreOrdersList navBarActionManager={this.props.navBarActionManager} {...p} />} />
                            <Route path="/Provisioning/PreOrders/New" exact element={(props: RouteParams<{ preOrderId: string }>) => <PreOrdersEditor {...props} preOrderId={-1} navBarActionManager={this.props.navBarActionManager} />} />
                            <Route path="/Provisioning/PreOrders/:preOrderId" exact element={(props: RouteParams<{ preOrderId: string }>) => <PreOrdersEditor {...props} preOrderId={parseInt(props.preOrderId)} navBarActionManager={this.props.navBarActionManager} />} />
                        </Routes>
                    </LayoutColumn>
                </VerticalLayout>
    }
}