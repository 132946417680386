import * as ko from "knockout";
import { Record } from "../Entities/Record";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFunctionPointComputationService } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { IFunctionPointRecordViewModel } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecordViewModel";
import { IFunctionPointRecordObserver } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecordObserver";
import { IFunctionPointRecord } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecord";

export class FunctionPointRecordViewModel implements IFunctionPointRecordViewModel {
    public Id: number;
    public JobOrderId: ko.Observable<number> = ko.observable();
    public Name: ko.Observable<string> = ko.observable();
    public JobOrder: ko.Observable<string> = ko.observable();
    public Status: ko.Observable<number> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public RecordFunctionPoint: ko.Observable<number> = ko.observable();
    public CalculatedFunctionPoint: ko.Observable<number> = ko.observable();
    public FinalCountFunctionPoint: ko.Observable<number> = ko.observable();
    public DevelopmentStatus: ko.Observable<number> = ko.observable();
    public AdministrationStatus: ko.Observable<number> = ko.observable();
    public ProlifeCode: ko.Observable<number> = ko.observable();
    public AnalysisDate: ko.Observable<Date> = ko.observable();
    public TestDate: ko.Observable<Date> = ko.observable();
    public TestVerificationDate: ko.Observable<Date> = ko.observable();
    public MIPDate: ko.Observable<Date> = ko.observable();
    public MIPVerificationDate: ko.Observable<Date> = ko.observable();
    public SentToCountDate: ko.Observable<Date> = ko.observable();
    public CountDate: ko.Observable<Date> = ko.observable();
    public Revenue: ko.Observable<number> = ko.observable();
    public AlreadyBilled: ko.Observable<number> = ko.observable();
    public ToBeInvoiced: ko.Observable<number> = ko.observable();
    public DayWorkerCost: ko.Observable<number> = ko.observable();
    public WorkedDays: ko.Observable<number> = ko.observable();
    public Cost: ko.Observable<number> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();
    public Supplier: ko.Observable<string> = ko.observable();
    public RTI: ko.Observable<string> = ko.observable();
    public Contract: ko.Observable<string> = ko.observable();
    public Lot: ko.Observable<string> = ko.observable();
    public Manager: ko.Observable<number> = ko.observable();
    public Referent: ko.Observable<number> = ko.observable();
    public ProjectManager: ko.Observable<number> = ko.observable();

    public RecordsList: ko.ObservableArray<Record> = ko.observableArray([]);
    public ProjectsList: ko.ObservableArray<any> = ko.observableArray([]);

    public DevelopmentStatusList: ko.ObservableArray<any> = ko.observableArray([]);
    public AdministrationStatusList: ko.ObservableArray<any> = ko.observableArray([]);
    public ManagersList: ko.ObservableArray<any> = ko.observableArray([]);
    public ReferentsList: ko.ObservableArray<any> = ko.observableArray([]);
    public ProjectManagersList: ko.ObservableArray<any> = ko.observableArray([]);

    public SelectedProject: ko.Observable<number> = ko.observable();
    public SelectedDevelopmentStatus: ko.Observable<number> = ko.observable();
    public SelectedAdministrationStatus: ko.Observable<number> = ko.observable();
    public SelectedManager: ko.Observable<number> = ko.observable();
    public SelectedReferent: ko.Observable<number> = ko.observable();
    public SelectedProjectManager: ko.Observable<number> = ko.observable();

    private observers: IFunctionPointRecordObserver[] = [];

    private functionPointComputationService: IFunctionPointComputationService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;

    constructor(serviceLocator: IServiceLocator, record?: IFunctionPointRecord) {
        this.functionPointComputationService = <IFunctionPointComputationService> 
            serviceLocator.findService(ProlifeSdk.FunctionPointComputationServiceType);

        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        if (record != null)
            this.loadFromModel(record);

        this.loadList();
    }

    public viewRecord() {
        var editor = this.functionPointComputationService.openRecordEditor(this);
        editor.show();
    }

    public loadList() {
        this.ProjectsList.push({ Id: 0, Label: "Progetto1" });
        this.ProjectsList.push({ Id: 1, Label: "Progetto2" });

        this.DevelopmentStatusList.push({ Id: 0, Label: "In Attesa" });
        this.DevelopmentStatusList.push({ Id: 1, Label: "In Sviluppo" });

        this.AdministrationStatusList.push({ Id: 0, Label: "Da Controllare" });
        this.AdministrationStatusList.push({ Id: 1, Label: "Controllato" });

        this.ManagersList.push({ Id: 0, Label: "Franco" });
        this.ManagersList.push({ Id: 1, Label: "Giuseppe" });

        this.ReferentsList.push({ Id: 0, Label: "Tommaso" });
        this.ReferentsList.push({ Id: 1, Label: "Riccardo" });

        this.ProjectManagersList.push({ Id: 0, Label: "Nicola" }),
        this.ProjectManagersList.push({ Id: 1, Label: "Filippo" });
    }

    public createRecord() {
        var editor = this.functionPointComputationService.openRecordEditor();
        editor.show();
    }

    public loadFromModel(record: IFunctionPointRecord) {
        this.Id = record.Id;
        this.JobOrderId(record.JobOrderId);
        this.Name(record.Name);
        this.JobOrder(record.JobOrder);
        this.Status(record.Status);
        this.StartDate(record.StartDate);
        this.EndDate(record.EndDate);
        this.RecordFunctionPoint(record.RecordFunctionPoint);
        this.CalculatedFunctionPoint(record.CalculatedFunctionPoint);
        this.FinalCountFunctionPoint(record.FinalCountFunctionPoint);
        this.DevelopmentStatus(record.DevelopmentStatus);
        this.AdministrationStatus(record.AdministrationStatus);
        this.ProlifeCode(record.ProlifeCode);
        this.AnalysisDate(record.AnalysisDate);
        this.TestDate(record.TestDate);
        this.TestVerificationDate(record.TestVerificationDate);
        this.MIPDate(record.MIPDate);
        this.MIPVerificationDate(record.MIPVerificationDate);
        this.SentToCountDate(record.SentToCountDate);
        this.CountDate(record.CountDate);
        this.Revenue(record.Revenue);
        this.AlreadyBilled(record.AlreadyBilled);
        this.ToBeInvoiced(record.ToBeInvoiced);
        this.DayWorkerCost(record.DayWorkerCost);
        this.WorkedDays(record.WorkedDays); 
        this.Cost(record.Cost);
        this.Order(record.Order);
        this.Supplier(record.Supplier);
        this.RTI(record.RTI);
        this.Contract(record.Contract);
        this.Lot(record.Lot);
        this.Manager(record.Manager);
        this.Referent(record.Referent);
        this.ProjectManager(record.ProjectManager);
    }

    public getData(): IFunctionPointRecord {
        var record: IFunctionPointRecord = {
            Id: this.Id,
            JobOrderId: this.JobOrderId(),
            Name: this.Name(),
            JobOrder: this.JobOrder(),
            Status: this.Status(),
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            RecordFunctionPoint: this.RecordFunctionPoint(),
            CalculatedFunctionPoint: this.CalculatedFunctionPoint(),
            FinalCountFunctionPoint: this.FinalCountFunctionPoint(),
            DevelopmentStatus: this.DevelopmentStatus(),
            AdministrationStatus: this.AdministrationStatus(),
            ProlifeCode: this.ProlifeCode(),
            AnalysisDate: this.AnalysisDate(),
            TestDate: this.TestDate(),
            TestVerificationDate: this.TestVerificationDate(),
            MIPDate: this.MIPDate(),
            MIPVerificationDate: this.MIPVerificationDate(),
            SentToCountDate: this.SentToCountDate(),
            CountDate: this.CountDate(),
            Revenue: this.Revenue(),
            AlreadyBilled: this.AlreadyBilled(),
            ToBeInvoiced: this.ToBeInvoiced(),
            DayWorkerCost: this.DayWorkerCost(),
            WorkedDays: this.WorkedDays(),
            Cost: this.Cost(),
            Order: this.Order(),
            Supplier: this.Supplier(),
            RTI: this.RTI(),
            Contract: this.Contract(),
            Lot: this.Lot(),
            Manager: this.Manager(),
            Referent: this.Referent(),
            ProjectManager: this.ProjectManager()
        };

        return record;
    }

    public Save() {
        var record = this.getData();

        return this.functionPointComputationService.createOrUpdateRecord(record)
            .then((result) => {
                if (result) {
                    this.infoToastService.Success(ProlifeSdk.TextResources.FunctionPointComputation.SaveSuccess);
                    this.NotifyRecordUpdated();   
                }
            })
    }

    public Delete() {
        var record = this.getData();

        this.dialogService.Confirm(
            ProlifeSdk.TextResources.FunctionPointComputation.DeleteRecord,
            ProlifeSdk.TextResources.FunctionPointComputation.Cancel,
            ProlifeSdk.TextResources.FunctionPointComputation.Confirm,
            (confirm: boolean) => {
                this.functionPointComputationService.deleteRecord(record.Id)
                    .then(() => {
                        this.NotifyRecordDeleted();
                        this.infoToastService.Success(ProlifeSdk.TextResources.FunctionPointComputation.RecordDeleteSuccess);
            }); 
        });
    }

    public registerObserver(observer: IFunctionPointRecordObserver): void {
        if (this.observers.indexOf(observer) >= 0)
            return;
        this.observers.push(observer);
    }

    public unregisterObserver(observer: IFunctionPointRecordObserver): void {
        var index = this.observers.indexOf(observer);
        if (index <= 0)
            return;
        this.observers.splice(index, 1);
    }

    public NotifyRecordUpdated(): void {
        this.observers.forEach((observer: IFunctionPointRecordObserver) => {
            observer.onRecordUpdated();
        });
    }

    public NotifyRecordDeleted(): void {
        this.observers.forEach((observer: IFunctionPointRecordObserver) => {
            observer.onRecordDeleted();
        });
    }

    public NotifyRecordAdded(): void {
        this.observers.forEach((observer: IFunctionPointRecordObserver) => {
            observer.onRecordAdded();
        });
    }
}