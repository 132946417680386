import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IBudgetRequestState, IBudgetRequestStatesSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IBudgetRequestStatesSettingsManager";
import { IWorkflowState } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";

export interface IBudgetRequestCauseStateProvider {
    update(element : IBudgetRequestState);
    getData() : IBudgetRequestState;
    Id?: number;
}

export class BudgetRequestCausesSettingsViewModel {
    title : string;
    elements : ko.ObservableArray<IBudgetRequestCauseStateProvider> = ko.observableArray();

    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private manager : IBudgetRequestStatesSettingsManager)
    {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.title = manager.getLabel();
        this.reloadElements();
    }

    private reloadElements()
    {
        var elements = this.manager.get();
        this.elements(elements.map(this.createViewModelFor.bind(this)));
    }

    public OnElementMoved(beforeId, movedId, afterId)
    {
        var moved : IBudgetRequestCauseStateProvider[] = this.elements().filter((s) => { return s.Id == movedId; });
        var before : IBudgetRequestCauseStateProvider[] = this.elements().filter((s) => { return s.Id == beforeId; });
        var after : IBudgetRequestCauseStateProvider[]  = this.elements().filter((s) => { return s.Id == afterId; });

        if(before.length == 0 && after.length == 0)
            return;

        var offset : number = before.length == 0 || before[0].getData().Position > moved[0].getData().Position ? 0 : 1;

        var newPosition = before.length > 0 ? before[0].getData().Position + offset : 1;
        this.manager.move(movedId, newPosition).then(() => {
            this.reloadElements();
        });
    }

    private createViewModelFor(element : IBudgetRequestState)
    {
        return new BudgetRequestCauseState(this, element);
    }

    public createNew()
    {
        this.manager.createOrUpdate(<IBudgetRequestState>{
            Description : '',
            LogicalStatus : 0,
            Deleted : false,
            Position : -1
        }).then((e : IBudgetRequestState) => {
            var newEl = this.createViewModelFor(e);
            this.elements.push(newEl);
        });
    }

    public createOrUpdateEntry(element : IBudgetRequestCauseStateProvider)
    {
        var data = element.getData();
        this.manager.createOrUpdate(data)
            .then((s : IBudgetRequestState) => element.update(s));
    }

    public deleteEntry(state : IBudgetRequestCauseStateProvider)
    {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Todolist.SureToDeleteBudgetRequestCause, ProlifeSdk.TextResources.Todolist.DoNotDeleteBudgetRequestCause, ProlifeSdk.TextResources.Todolist.DeleteBudgetRequestCause, (confirm : boolean) => {
            if(!confirm)
                return;

            this.elements.remove(state);
            var data : IBudgetRequestState = state.getData();
            this.manager.remove(data.Id);
        });
    }
}

class BudgetRequestCauseState implements IBudgetRequestCauseStateProvider
{
    Id : number;
    Description : ko.Observable<string> = ko.observable();
    State : ko.Observable<number> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    StateName : ko.Observable<string> = ko.observable();
    StateNames : ko.ObservableArray<string> = ko.observableArray([
        //ProlifeSdk.TextResources.Todolist.Audit,
        ProlifeSdk.TextResources.Todolist.CustomerPaid,
        ProlifeSdk.TextResources.Todolist.InternallyPaid
    ]);

    private updating : boolean = false;

    constructor(private container : BudgetRequestCausesSettingsViewModel, private element : IBudgetRequestState)
    {
        this.update(element);
        this.Description.subscribe(this.onDataChanged.bind(this));
        this.State.subscribe(this.onDataChanged.bind(this));
    }

    public update(element : IBudgetRequestState)
    {
        this.updating = true;
        this.Id = element.Id;
        this.element = element;
        this.Description(element.Description);
        this.setState(element.LogicalStatus);

        this.updating = false;
    }

    public getData() : IWorkflowState
    {
        var newEl = <IWorkflowState> $.extend({}, this.element);
        newEl.Description = this.Description();
        newEl.LogicalStatus = this.State();
        return newEl;
    }

    private onDataChanged()
    {
        if(this.updating)
            return;

        this.container.createOrUpdateEntry(this);
    }

    public setState(stateId : number)
    {
        this.State(stateId);
        this.StateName(this.StateNames()[stateId]);
    }
}