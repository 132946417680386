import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ScheduleDetailViewModel } from "./ScheduleDetailViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";

export class InvoiceDetailsDialog implements IDialog
{
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    
    public templateName: string = "invoice-dialog";
    public templateUrl: string = "schedule/templates";
    public title: string;
    public modal : { close: (result?: any) => void; };

    constructor(public details : ScheduleDetailViewModel)
    {
        this.title = this.details ? String.format(ProlifeSdk.TextResources.Schedule.InvoiceNumberOf, details.invoice.invoice.Number, moment(details.invoice.invoice.Date).format("L")) : ProlifeSdk.TextResources.Invoices.SchedulesDialogTitle;
    }

    close(): void
    {
        if(this.details && this.details.SomeIsChanged())
        {
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Schedule.ConfirmLooseChanges, ProlifeSdk.TextResources.Schedule.DoNotLooseChanges, ProlifeSdk.TextResources.Schedule.LooseChanges,
                (result) => {
                    if(result)
                        this.CloseAfterConfirm();
                })
        }
        else
            this.CloseAfterConfirm();
    }

    private CloseAfterConfirm()
    {
        if (this.details)
            this.details.RevertChanges();
        this.modal.close();
    }

    action(): void
    {

    }
}
