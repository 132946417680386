/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 01/03/13
 * Time: 16.46
 * To change this template use File | Settings | File Templates.
 */

import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import { CreateNewArticleDialog } from "./CreateNewArticleDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ManufacturersDataSource } from "../../../../DataSources/ManufacturersDataSource";
import { CatalogsDataSource } from "../../../../DataSources/CatalogsDataSource";
import {
    IManufacturer,
    IManufacturersService,
} from "../../../../ProlifeSdk/interfaces/warehouse/IManufacturersService";
import { ICatalog, ICatalogsService } from "../../../../ProlifeSdk/interfaces/warehouse/ICatalogsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Select } from "../../../../Components/Select";
import { Right } from "../../../../Core/Authorizations";
import { SegmentedInput } from "../../../../Components/SegmentedInput";
import { If } from "../../../../Components/IfIfNotWith";
import { ArticlesManagement } from "./ArticlesManagement";
import { BasePagedList } from "../Utilities/BasePagedList";
import { PurchasesManagement } from "./PurchasesManagement";

export function WarehouseArticles() {
    const C = require("./WarehouseArticles")._WarehouseArticles as typeof _WarehouseArticles;
    return <C />;
}

export class _WarehouseArticles {
    public SearchFilter: ko.Observable<string> = ko.observable("");
    public manufacturers: ko.ObservableArray<IManufacturer> = ko.observableArray([]);
    public catalogs: ko.ObservableArray<ICatalog> = ko.observableArray([]);
    public SelectedManufacturer: ko.Observable<number> = ko.observable();
    public SelectedCatalog: ko.Observable<number> = ko.observable();

    public ManufacturersDataSource: ManufacturersDataSource;
    public CatalogsDataSource: CatalogsDataSource;

    public ArticlesSwitch: ko.Observable<"articles" | "purchases"> = ko.observable("articles");

    public ActualView: ko.Observable<BasePagedList | null> = ko.observable(null);
    public SomeFilterIsChanged: ko.Computed<boolean>;

    public SectionId = 6;

    @LazyImport(nameof<IManufacturersService>())
    private manufacturersService: IManufacturersService;
    @LazyImport(nameof<ICatalogsService>())
    private catalogsService: ICatalogsService;

    @Right("Warehouse_ViewNewPurchases")
    private canViewNewPurchases: boolean;

    constructor() {
        this.ManufacturersDataSource = new ManufacturersDataSource();
        this.CatalogsDataSource = new CatalogsDataSource();

        this.SomeFilterIsChanged = ko.computed(() => {
            return this.ActualView()?.SomeFilterIsChanged() || false;
        });

        this.SelectedManufacturer.subscribe(this.loadCatalogs.bind(this));
        this.ArticlesSwitch.subscribe((v) => {
            this.setupCatalogFilter(v);
        });

        this.loadManufacturers();
    }

    private async setupCatalogFilter(actualView: "articles" | "purchases") {
        this.SelectedManufacturer(actualView === "purchases" ? -1 : null);
        await this.loadCatalogs();
    }

    public loadRows() {
        this.ActualView()?.loadRows();
    }

    dispose() {}

    private loadManufacturers() {
        this.manufacturersService
            .SearchManufacturers("")
            .then((manufacturers: IManufacturer[]) => this.manufacturers(manufacturers));
    }

    private async loadCatalogs(): Promise<void> {
        this.CatalogsDataSource.setManufacturerId(this.SelectedManufacturer());

        if (!this.SelectedManufacturer()) {
            this.SelectedCatalog(null);
            return;
        }

        const catalogs: ICatalog[] = await this.catalogsService.getCatalogs(
            null,
            this.SelectedManufacturer(),
            0,
            100000
        );

        if (catalogs.length > 0) {
            this.SelectedCatalog(null);
            this.SelectedCatalog(catalogs[0].Id);
        }
    }

    render() {
        const wa = this;

        return ComponentUtils.bindTo(
            <>
                <h3 className="page-title">{TextResources.Warehouse.ArticlesList}</h3>
                <div className="page-bar" style={{ padding: "5px" }}>
                    <form
                        style="margin-right: 10px"
                        className="caption search input-icon pull-left"
                        data-bind={{ submit: wa.loadRows }}
                    >
                        <i className="fa fa-search"></i>
                        <input
                            className="form-control"
                            type="text"
                            placeholder={TextResources.Warehouse.SearchPlaceholder}
                            data-bind={{ value: wa.SearchFilter, valueUpdate: "afterkeydown" }}
                        />
                    </form>

                    <button
                        className="btn pull-left"
                        data-bind={{
                            click: wa.loadRows,
                            css: { "btn-warning": wa.SomeFilterIsChanged, default: !wa.SomeFilterIsChanged() },
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        <i className="fa fa-search"></i>&nbsp;{TextResources.Warehouse.Search}
                    </button>

                    <Select
                        style={{ width: "170px", marginRight: "10px" }}
                        className="pull-left"
                        dataSource={this.ManufacturersDataSource}
                        value={this.SelectedManufacturer}
                        allowClear
                        placeholder={TextResources.Warehouse.ManufacturerFilterPlaceholder}
                        readOnly={ko.computed(() => this.ArticlesSwitch() === "purchases")}
                    />
                    <Select
                        style={{ width: "170px" }}
                        className="pull-left"
                        dataSource={this.CatalogsDataSource}
                        value={this.SelectedCatalog}
                        allowClear
                        placeholder={TextResources.Warehouse.CatalogFilterPlaceholder}
                        readOnly={ko.computed(() => this.ArticlesSwitch() === "purchases")}
                    />

                    <div className="btn-group pull-right flex-container">
                        {this.canViewNewPurchases && (
                            <div style="margin-right: 20px;margin-top: 7px;">
                                <SegmentedInput
                                    simple
                                    options={[
                                        { text: TextResources.Warehouse.ArticlesSwitchLabel, value: "articles" },
                                        { text: TextResources.Warehouse.PurchasesSwitchLabel, value: "purchases" },
                                    ]}
                                    value={wa.ArticlesSwitch}
                                />
                            </div>
                        )}
                        <div style={{ width: "80px" }}>
                            <If condition={ko.computed(() => wa.ArticlesSwitch() === "articles")}>
                                {() => <WarehouseArticlesActions />}
                            </If>
                        </div>
                    </div>
                </div>

                <If condition={() => this.ArticlesSwitch() === "articles"}>
                    {() => (
                        <ArticlesManagement
                            searchFilter={this.SearchFilter}
                            selectedCatalog={this.SelectedCatalog}
                            forwardRef={this.ActualView}
                        />
                    )}
                </If>
                <If condition={() => this.ArticlesSwitch() === "purchases"}>
                    {() => (
                        <PurchasesManagement
                            searchFilter={this.SearchFilter}
                            selectedCatalog={this.SelectedCatalog}
                            forwardRef={this.ActualView}
                        />
                    )}
                </If>
            </>,
            this,
            "wa"
        );
    }
}

class WarehouseArticlesActions {
    public createNew() {
        const modal = new CreateNewArticleDialog();
        modal.loadAndShow();
    }

    render() {
        const aa = this;

        return ComponentUtils.bindTo(
            <>
                <button
                    className="btn grey-salt dropdown-toggle btn-fit-height"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="true"
                    data-delay="1000"
                >
                    Azioni
                    <i className="fa fa-angle-down"></i>
                </button>
                <ul className="dropdown-menu pull-right" role="menu">
                    <li>
                        <a href="#" data-bind={{ click: aa.createNew }}>
                            Nuovo articolo
                        </a>
                    </li>
                </ul>
            </>,
            this,
            "aa"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WarehouseArticles);
}
