import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import * as numeral from "numeral";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { TreeGrid,TreeGridStringColumn, TreeGridColumn } from "../../../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import { IJobOrderService, IJobOrderWarehouseCosts } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderEditor } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { IAdministrativeReportPage } from "../../../../../interfaces/IAdministrativeReportPage";
import { IFileRepositoryService } from "../../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { Deferred } from "../../../../../../Core/Deferred";

export class WarehouseCostsPage implements IAdministrativeReportPage {
    Title : ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.WarehouseCosts);
    TemplateUrl:string = "joborder/templates/joborderdetail/administrative-report";
    TemplateName:string = "warehouse-costs-page";
    Table : TreeGrid = new TreeGrid();

    ExporterId : string = "JobOrder/JobOrderAdministativeReportWarehouseCostsExport";

    SearchField : ko.Observable<string> = ko.observable();
    AmountFrom : ko.Observable<number> = ko.observable();
    AmountTo : ko.Observable<number> = ko.observable();
    ValueFrom : ko.Observable<number> = ko.observable();
    ValueTo : ko.Observable<number> = ko.observable();
    DateFrom : ko.Observable<Date> = ko.observable();
    DateTo : ko.Observable<Date> = ko.observable();
    Source : ko.Observable<string> = ko.observable();
    OnlyImported : ko.Observable<boolean> = ko.observable();
    OrderBy : ko.Observable<string> = ko.observable();
    OrderAscDesc : ko.Observable<string> = ko.observable();

    private columnMapping : string[] = [
        'SourceProtocol',
        'ArticleCode',
        'ArticleAmount',
        'ArticleValue',
        'SourceType'
    ];

    private expandCollapseCache : { [id: string] : boolean; } = {};

    private jobOrdersService : IJobOrderService;
    private fileRepositoryService : IFileRepositoryService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.jobOrdersService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.fileRepositoryService = <IFileRepositoryService> serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);

        let today = moment();
        this.DateFrom(moment(today).add(-1, "month").startOf("month").toDate());
        this.DateTo(today.endOf("day").toDate());

        this.Table.ShowActions(false);
        this.Table.AddColumn(new TreeGridStringColumn(ProlifeSdk.TextResources.Invoices.VatRegister, value => value.SourceProtocol === undefined ? '' : !value.SourceProtocol ? ProlifeSdk.TextResources.ProlifeSdk.NotAvailable : value.SourceProtocol, 100).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn(ProlifeSdk.TextResources.Warehouse.Article, value => value.ArticleCode, 100).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.WarehouseCostsAmount, value => value.Amount, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00[0]')).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.WarehouseCostsValue, value => value.Value, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00 $')).Align('right'));
        this.Table.AddColumn(new TreeGridColumn(ProlifeSdk.TextResources.JobOrder.WarehouseCostsImportedInDDT, value => value.ImportedInDDT === undefined ? '' : !!value.ImportedInDDT ? ProlifeSdk.TextResources.ProlifeSdk.Yes : ProlifeSdk.TextResources.ProlifeSdk.Yes, 200).Align('center'));

        this.Table.SortingModeChanged.Add((column : TreeGridColumn, sortMode : number) => {
            var index = this.Table.Columns().indexOf(column);
            if(index < 0 || sortMode == 0) {
                this.OrderBy(null);
                this.OrderAscDesc(null);
            } else {
                this.OrderBy(this.columnMapping[index]);
                this.OrderAscDesc(sortMode == 1 ? 'ASC' : 'DESC');
            }

            this.load();
        });

        ko.utils.observableBetween(this.AmountFrom, this.AmountTo);
        ko.utils.observableBetween(this.ValueFrom, this.ValueTo);
        ko.utils.observableBetween(this.DateFrom, this.DateTo);
    }

    dispose() {
    }

    public Search() {
        this.load();
    }

    public getExcelExportData() : any | null {
        return {
            JobOrderId: this.editor.JobOrderId,
            SearchField: this.SearchField(),
            DateFrom : this.DateFrom(),
            DateTo : this.DateTo(),
            AmountFrom : this.AmountFrom(),
            AmountTo : this.AmountTo(),
            ValueFrom : this.ValueFrom(),
            ValueTo : this.ValueTo(),
            Source: this.Source(),
            OnlyImported : this.OnlyImported(),
            OrderBy: this.OrderBy(),
            OrderAscDesc: this.OrderAscDesc()
        };
    }

    /*private OpenColumnsInfo() {
        var report = new ReportColumnsInfoDialog("JobOrder/JobOrderAdministativeReportWarehouseCostsExport", "AvailableColumns", "DefaultTemplate");
        this.dialogsService.ShowModal<void>(report, "large", { noPrompt: true });
    }

    public Export() {
        this.fileRepositoryService.openAsDialog("/" + ProlifeSdk.TextResources.JobOrder.ReportModelsURL)
            .then((selectedFiles : IFileOrFolder[]) => {
                if(selectedFiles.length == 0)
                    return;

                var template = selectedFiles[0];

                this.jobOrdersService.ExportWarehouseCosts(template.Id, this.editor.JobOrderId, this.SearchField(), this.AmountFrom(), this.AmountTo(), this.ValueFrom(), this.ValueTo(), this.DateFrom(), this.DateTo(), this.Source(), this.OnlyImported(), this.OrderBy(), this.OrderAscDesc());
            });
    }*/

    load(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.jobOrdersService.GetWarehouseCosts(this.editor.JobOrderId, this.SearchField(), this.AmountFrom(), this.AmountTo(), this.ValueFrom(), this.ValueTo(), this.DateFrom(), this.DateTo(), this.Source(), this.OnlyImported(), this.OrderBy(), this.OrderAscDesc())
            .then((costs : IJobOrderWarehouseCosts[]) => {
                this.Table.ClearItems();

                /*var lastSourceType = "", lastSourceId = 0;
                var lastItem = null;
                var total = 0;
                var totalToImportInDDT = 0;

                costs.forEach(c => { // TODO non gruppare per SourceType e SourceId
                    if(lastSourceType != c.SourceType || lastSourceId != c.SourceId) {
                        lastItem = this.Table.AddItem({}, this.getDescriptionString(c));
                        lastSourceType = c.SourceType;
                        lastSourceId = c.SourceId;

                        var existingValue = this.expandCollapseCache[c.SourceType + c.SourceId];
                        if(existingValue == undefined) {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = false;
                            existingValue = false;
                        }

                        lastItem.IsExpanded(existingValue);
                        lastItem.IsExpandedChanged.Add(isExpanded => {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = isExpanded;
                        });
                    }

                    lastItem.AddChild({ Amount: c.ArticleAmount, Value: c.ArticleValue, ImportedInDDT: c.SourceType == "DDT" }, c.ArticleCode).CanHaveChildren(false);

                    total += c.ArticleValue;
                    if(c.SourceName == "WHR")
                        totalToImportInDDT += c.ArticleValue
                });*/

                let totalAmount = 0;
                let totalAmountToImportInDDT = 0;
                let total = 0;
                let totalToImportInDDT = 0;

                costs.forEach(c => {
                    let model = {
                        SourceType: c.SourceType,
                        SourceId: c.SourceId,
                        SourceName: c.SourceName,
                        SourceDate: c.SourceDate,
                        SourceProtocol: c.SourceProtocol || null,
                        ArticleCode: c.ArticleCode,
                        Amount: c.ArticleAmount,
                        Value: c.ArticleValue,
                        ImportedInDDT: c.SourceType == "DDT"
                    };

                    let item = this.Table.AddItem(model, this.getDescriptionString(c));
                    item.CanHaveChildren(false);

                    totalAmount += (c.ArticleAmount || 0);
                    total += c.ArticleValue;
                    if (c.SourceName == "WHR") {
                        totalAmountToImportInDDT += (c.ArticleAmount || 0);
                        totalToImportInDDT += c.ArticleValue
                    }
                });

                this.Table.AddItem({ Amount: totalAmount, Value: total }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotal).CanHaveChildren(false);
                this.Table.AddItem({ Amount: totalAmountToImportInDDT, Value: totalToImportInDDT }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotalToImport).CanHaveChildren(false);

                def.resolve(true);
            });

        return def.promise();
    }

    private getDescriptionString(c : IJobOrderWarehouseCosts) : string {
        switch(c.SourceType) {
            case "WHR":
                return String.format(ProlifeSdk.TextResources.JobOrder.WarehouseCostsWarehouse, c.SourceName);
            case "DDT":
                return String.format(ProlifeSdk.TextResources.JobOrder.WarehouseCostsDDTWithoutProtocol, c.SourceName, moment(c.SourceDate).format("L"));
            case "DOA":
                return String.format(ProlifeSdk.TextResources.JobOrder.WarehouseCostsInvoiceWithoutProtocol, c.SourceName, moment(c.SourceDate).format("L"));
        }

        return "";
    }

    isDefault():boolean {
        return true;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    beforeShowPanel() {
    }
}