import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import moment = require("moment");
import TsxForEach from "../ForEach";
import { Select2 } from "../../Components/Select2Component";
import { ComponentUtils } from "../../Core/utils/ComponentUtils";
import { DetectClassChanges, DetectChanges } from "../../Core/ChangeDetection";
import { DocumentsDataSource } from "../../DataSources/DocumentsDataSource";
import { DocumentsRowsCommonDataDataSource } from "../../DataSources/DocumentsRowsCommonDataDataSource";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { Portlet, IPortletVM } from "../Portlet";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../DataSources/IDataSource";
import { ICommonRowData, IDocumentCommonData } from "../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWorkflowRelatedDocument, ITodoListService } from "../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDetectChanges } from "../../Core/interfaces/IDetectChanges";
import { IVatRegisters } from "../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ITableItem, Table } from "../TableComponent/TableComponent";
import { Column, ColumnBody, ColumnFooter } from "../TableComponent/CustomColumn";

export interface IRelatedDocumentsPerTypeComponent {
    DocumentsList: ko.ObservableArray<WorkflowRelatedDocument>;
    Collapsed: ko.Computed<boolean>;

    loadDocuments(documents: IWorkflowRelatedDocument[]);
    setJobOrderId(jobOrderId: number);
    setGetFullyAssignedRows(value: boolean);
    setGetAllJobOrders(value: boolean);

    hasChanges(): boolean;
    isValid(): boolean;
    isFullyAssignedRow(rowModel: ICommonRowData): boolean;
    getData(): IWorkflowRelatedDocument[];

    expand(): void;
    collapse(): void;
    switchCollapsedState(): void;

    scrollTo(): void;
}

export interface IRelatedDocumentsPerTypeComponentParams {
    title: string;
    icon?: string;
    documentType: string;

    forwardRef?: (component: IRelatedDocumentsPerTypeComponent) => void;
}

export class RelatedDocumentsPerTypeComponent implements IRelatedDocumentsPerTypeComponent {
    public Title: string;
    public Icon: string;
    public DocumentType: string;

    public DocumentsList: ko.ObservableArray<WorkflowRelatedDocument> = ko.observableArray([]);
    public Collapsed: ko.Computed<boolean>;
    public Portlet: ko.Observable<IPortletVM> = ko.observable();

    public ScrollTrigger: ko.Observable<boolean> = ko.observable(false);

    public TotalAssignedPrice: ko.Computed<number>;

    private actualJobOrderId: number = null;
    private actualGetAllJobOrders = false;
    private actualGetFullyAssignedRows = false;
    private initialRelatedDocuments: IWorkflowRelatedDocument[] = [];
    private initialPortletCollapsed = true;

    constructor(private props: IRelatedDocumentsPerTypeComponentParams) {
        this.Title = this.props.title;
        this.Icon = this.props.icon;
        this.DocumentType = this.props.documentType;

        this.Portlet.subscribe((p) => {
            if (this.initialPortletCollapsed) p.Collapsed(false);
        });

        this.TotalAssignedPrice = ko.computed(() => {
            let total = 0;

            (this.DocumentsList() || []).forEach((r) => {
                total += r.AssignedPrice();
            });

            return total;
        });

        this.Collapsed = ko.computed(() => {
            return this.Portlet()?.Collapsed() ?? this.initialPortletCollapsed;
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    public isFullyAssignedRow(rowModel: ICommonRowData): boolean {
        if (this.initialRelatedDocuments.length === 0)
            return (rowModel.AssignedAmountToWorkflows || 0) >= rowModel.Amount;

        const row = this.initialRelatedDocuments
            .filter((r) => r.RowId === rowModel.Id && r.DocumentType === rowModel.EntityType)
            .firstOrDefault();
        if (!row) return (rowModel.AssignedAmountToWorkflows || 0) >= rowModel.Amount;

        return (rowModel.AssignedAmountToWorkflows || 0) - row.WorkflowAmount >= rowModel.Amount;
    }

    public switchCollapsedState(): void {
        if (!this.Portlet()) {
            this.initialPortletCollapsed = !this.initialPortletCollapsed;
            return;
        }

        this.Portlet().switchCollapsedMode();
    }

    public expand(): void {
        if (!this.Portlet()) {
            this.initialPortletCollapsed = false;
            return;
        }

        this.Portlet().Collapsed(false);
    }

    public collapse(): void {
        if (!this.Portlet()) {
            this.initialPortletCollapsed = true;
            return;
        }

        this.Portlet().Collapsed(true);
    }

    public scrollTo(): void {
        this.ScrollTrigger(!this.ScrollTrigger());
    }

    public isValid(): boolean {
        return this.DocumentsList().filter((d) => !d.isValid()).length === 0;
    }

    public getData(): IWorkflowRelatedDocument[] {
        return this.DocumentsList().map((d) => d.getData());
    }

    public hasChanges(): boolean {
        return (
            this.initialRelatedDocuments.length != this.DocumentsList().length ||
            this.DocumentsList().filter((d) => d.isChanged() > 0).length > 0
        );
    }

    public setGetFullyAssignedRows(value: boolean) {
        this.actualGetFullyAssignedRows = value;
        this.DocumentsList().forEach((d) => d.setGetFullyAssignedRows(value));
    }

    public setGetAllJobOrders(value: boolean) {
        this.actualGetAllJobOrders = value;
        this.DocumentsList().forEach((d) => d.setGetAllJobOrders(value));
    }

    public setJobOrderId(jobOrderId: number) {
        this.actualJobOrderId = jobOrderId;
        this.DocumentsList().forEach((d) => d.setJobOrderId(jobOrderId));
    }

    public loadDocuments(documents: IWorkflowRelatedDocument[]) {
        this.initialRelatedDocuments = documents || [];
        this.DocumentsList((documents || []).map(this.createRelatedDocument, this));
    }

    public addRelatedDocument(): void {
        this.DocumentsList.push(this.createRelatedDocument(this.createEmptyRelatedDocument(this.DocumentType)));
    }

    public removeRelatedDocument(document: WorkflowRelatedDocument): void {
        document.dispose();
        this.DocumentsList.remove(document);
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const doc: IDataSourceModel<WorkflowRelatedDocument> = null;

        return ComponentUtils.bindTo(
            <div data-bind={{ scrollIntoView: vm.ScrollTrigger }}>
                <Portlet portletTitle={this.Title} collapsible={true} injectTo={this.Portlet}>
                    <Portlet.Body>
                        {() => (
                            <>
                                <div class="related-documents-actions">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-xs pull-right"
                                        data-bind={{ click: vm.addRelatedDocument.bind(vm) }}>
                                        <i class="fa fa-plus"></i>&nbsp;{TextResources.Todolist.Add}
                                    </button>
                                </div>
                                <Table
                                    dataSource={Table.defaultDataSource(this.DocumentsList, (doc) => ({
                                        id: doc.Id,
                                        title: doc.DocumentLabel(),
                                    }))}
                                    compact
                                    fixedLayout
                                    emptyResultMessage="Nessuna riga inserita"
                                    rowAs="doc">
                                    <Column title="Documento" style={{ width: "24%" }}>
                                        <ColumnBody>
                                            {(item: ITableItem<WorkflowRelatedDocument>) => (
                                                <Select2
                                                    value={item.Data.model.DocumentId}
                                                    dataSource={item.Data.model.DocumentsDataSource}
                                                    listener={item.Data.model}
                                                    inputClassName="input-sm"
                                                    placeholder={
                                                        TextResources.Todolist.WorkflowRelatedDocumentPlacehloder
                                                    }
                                                    allowClear></Select2>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Riga" style={{ width: "24%" }}>
                                        <ColumnBody>
                                            {(item: ITableItem<WorkflowRelatedDocument>) => (
                                                <Select2
                                                    value={item.Data.model.RowId}
                                                    dataSource={item.Data.model.DocumentsRowsDataSource}
                                                    listener={item.Data.model}
                                                    inputClassName="input-sm"
                                                    placeholder={
                                                        TextResources.Todolist.WorkflowRelatedDocumentRowPlacehloder
                                                    }
                                                    allowClear></Select2>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                    <Column
                                        title="Quantità"
                                        style={{ width: "9%" }}
                                        className="text-right"
                                        cssClasses="compact-table-readonly-field">
                                        <span data-bind={{ numberText: doc.model.RowAmount }}></span>&nbsp;(
                                        <span data-bind={{ numberText: doc.model.AlreadyAssignedAmount }}></span>)&nbsp;
                                        <span data-bind={{ numbertext: doc.model.RowUnitOfMeasure }}></span>
                                    </Column>
                                    <Column title="Asseganto al piano" style={{ width: "9%" }} className="text-right">
                                        <div class="input-icon left">
                                            <i
                                                class="fa fa-exclamation-circle"
                                                style={{ color: "red", marginTop: "8px" }}
                                                title="Hai superato la quantità totale di riga!"
                                                data-bind={{
                                                    tooltip: { placement: "right" },
                                                    visible: doc.model.RowAmountExceeded,
                                                }}></i>
                                            <input
                                                type="text"
                                                class="form-control input-sm number"
                                                data-bind={{
                                                    numberValue: doc.model.WorkflowAmount,
                                                    selectOnFocus: {},
                                                    hasFocus: doc.model.WorkflowAmountHasFocus,
                                                }}
                                            />
                                        </div>
                                    </Column>
                                    <Column
                                        title="Importo unitario"
                                        style={{ width: "9%" }}
                                        className="text-right"
                                        cssClasses="compact-table-readonly-field">
                                        <span data-bind={{ moneyText: doc.model.RowPrice }}></span>
                                    </Column>
                                    <Column title="Importo" style={{ width: "9%" }} className="text-right">
                                        <ColumnBody>
                                            {() => (
                                                <input
                                                    type="text"
                                                    class="form-control input-sm number"
                                                    data-bind={{
                                                        moneyValue: doc.model.AssignedPrice,
                                                        selectOnFocus: {},
                                                    }}></input>
                                            )}
                                        </ColumnBody>
                                        <ColumnFooter>
                                            {() => (
                                                <span
                                                    class="bold"
                                                    data-bind={{ moneyText: vm.TotalAssignedPrice }}></span>
                                            )}
                                        </ColumnFooter>
                                    </Column>
                                    <Column
                                        title="Inizio validità"
                                        style={{ width: "8%" }}
                                        className="text-right"
                                        cssClasses="compact-table-readonly-field">
                                        <span data-bind={{ dateText: doc.model.ValidityStartDate }} />
                                    </Column>
                                    <Column title="" style={{ width: "8%" }} className="text-right">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-xs"
                                            title="I dati del documento o della riga sono cambiati. Clicca per aggiornare."
                                            data-bind={{
                                                click: doc.model.loadUpdatedData.bind(doc),
                                                tooltip: {},
                                                visible: false,
                                            }}>
                                            <i class="fa fa-refresh"></i>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-xs"
                                            title="Elimina"
                                            data-bind={{ click: vm.removeRelatedDocument.bind(vm, doc), tooltip: {} }}>
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </Column>
                                </Table>
                            </>
                        )}
                    </Portlet.Body>
                </Portlet>
            </div>,
            this,
            "vm"
        );
    }

    private renderDocuments() {
        const vm: RelatedDocumentsPerTypeComponent = null;
        const doc: WorkflowRelatedDocument = null;

        return (
            <TsxForEach data={this.DocumentsList} as="doc">
                {(item: WorkflowRelatedDocument) => (
                    <tr>
                        <td class="edit-field"></td>
                        <td class="edit-field"></td>
                        <td class="number compact-table-readonly-field"></td>
                        <td class="edit-field"></td>
                        <td class="number compact-table-readonly-field"></td>
                        <td class="edit-field"></td>
                        <td class="number compact-table-readonly-field"></td>
                        <td class="actions-col edit-field text-right"></td>
                    </tr>
                )}
            </TsxForEach>
        );
    }

    private createRelatedDocument(document: IWorkflowRelatedDocument): WorkflowRelatedDocument {
        const vm = new WorkflowRelatedDocument(document, this);

        vm.setJobOrderId(this.actualJobOrderId);
        vm.setGetAllJobOrders(this.actualGetAllJobOrders);
        vm.setGetFullyAssignedRows(this.actualGetFullyAssignedRows);

        return vm;
    }

    private createEmptyRelatedDocument(docType: string): IWorkflowRelatedDocument {
        return {
            Id: null,
            WorkflowId: null,
            DocumentId: null,
            DocumentType: docType,
            DocumentNumber: null,
            DocumentDate: null,
            DocumentProtocolId: null,
            RowId: null,
            RowDescription: null,
            RowAmount: 0,
            RowUoM: null,
            RowPrice: 0,
            AlreadyAssignedAmount: 0,
            WorkflowAmount: 0,
            ValidityStartDate: null,
            DocumentHasBeenDeleted: false,
            RowHasBeenDeleted: false,
        } as IWorkflowRelatedDocument;
    }
}

@DetectClassChanges
export class WorkflowRelatedDocument implements IDataSourceListener, IDetectChanges {
    public Id: number;

    @DetectChanges
    public DocumentId: ko.Observable<number> = ko.observable();

    public get DocumentType(): string {
        return this.m_documentType;
    }

    @DetectChanges
    public DocumentNumber: ko.Observable<string> = ko.observable();
    @DetectChanges
    public DocumentDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public DocumentProtocolId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public RowId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public RowType: ko.Observable<string> = ko.observable();
    @DetectChanges
    public RowDescription: ko.Observable<string> = ko.observable();
    @DetectChanges
    public RowAmount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public RowUnitOfMeasure: ko.Observable<string> = ko.observable();
    @DetectChanges
    public RowPrice: ko.Observable<number> = ko.observable();

    public AlreadyAssignedAmount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public WorkflowAmount: ko.Observable<number> = ko.observable();
    public ValidityStartDate: ko.Observable<Date> = ko.observable();
    public AssignedPrice: ko.Observable<number> = ko.observable();
    public RowAmountExceeded: ko.Computed<boolean>;

    public DocumentHasBeenDeleted: ko.Observable<boolean> = ko.observable();
    public RowHasBeenDeleted: ko.Observable<boolean> = ko.observable();

    public WorkflowAmountHasFocus: ko.Observable<boolean> = ko.observable(false);

    public DocumentLabel: ko.Computed<string>;
    public DocumentOrRowDeletedMessage: ko.Computed<string>;
    public IsLastRow: ko.Computed<boolean>;
    public ShowChangesAlert: ko.Computed<boolean>;
    public ShowDeleteAlert: ko.Computed<boolean>;

    public DocumentsDataSource: DocumentsDataSource;
    public DocumentsRowsDataSource: DocumentsRowsCommonDataDataSource;

    public isChanged: ko.Observable<number> = ko.observable(0);

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegistersManager: IVatRegisters;

    private observablesSubscriptions: ko.Subscription[] = [];
    private oldAmount = 0;
    private m_documentType = "";

    private getFullyAssignedRows = false;
    private isCalculating = false;

    constructor(private relatedDocument: IWorkflowRelatedDocument, private editor: IRelatedDocumentsPerTypeComponent) {
        this.WorkflowAmount.subscribe((amount: number) => {
            if (this.isCalculating) return;

            this.isCalculating = true;

            this.AssignedPrice(amount * this.RowPrice());

            this.isCalculating = false;
        });

        this.AssignedPrice.subscribe((price: number) => {
            if (this.isCalculating) return;

            this.isCalculating = true;

            this.WorkflowAmount((price / (this.RowPrice() * this.RowAmount())) * this.RowAmount());

            this.isCalculating = false;
        });

        this.loadData(this.relatedDocument);

        this.DocumentsDataSource = new DocumentsDataSource();
        this.DocumentsDataSource.setEntityTypes([this.relatedDocument.DocumentType]);

        this.DocumentsRowsDataSource = new DocumentsRowsCommonDataDataSource();
        this.DocumentsRowsDataSource.setDocumentAndTypeId(
            this.relatedDocument.DocumentId,
            this.relatedDocument.DocumentType
        );
        this.DocumentsRowsDataSource.setGetTotallyAssignedOrdersRows(true);
        //this.DocumentsRowsDataSource.setDataFilter(this.filterRows.bind(this));

        this.ShowChangesAlert = ko.computed(() => {
            return false;
        });

        this.RowAmountExceeded = ko.computed(() => {
            return this.RowAmount() < this.WorkflowAmount() + this.AlreadyAssignedAmount();
        });

        this.DocumentLabel = ko.computed(() => {
            if (!this.DocumentNumber() || !this.DocumentDate() || !this.DocumentProtocolId()) return "";

            const vatRegister = this.vatRegistersManager.getVatRegisterById(this.DocumentProtocolId());

            return String.format(
                ProlifeSdk.TextResources.Todolist.CustomerOrderNameForDataSource,
                this.DocumentNumber(),
                moment(this.DocumentDate()).format("L"),
                vatRegister.NomeRegistroIVA
            );
        });

        this.DocumentOrRowDeletedMessage = ko.computed(() => {
            return this.DocumentHasBeenDeleted()
                ? ProlifeSdk.TextResources.Todolist.CustomerOrderHasBeenDeleted
                : this.RowHasBeenDeleted()
                ? ProlifeSdk.TextResources.Todolist.CustomerOrderRowHasBeenDeleted
                : "";
        });

        let updatingAmount = false;
        this.WorkflowAmount.subscribe((value: number) => {
            if (updatingAmount) return;

            updatingAmount = true;

            if (value < 0) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InvalidWorkflowAmountForBudget);
                this.WorkflowAmount(this.oldAmount);
                return;
            }

            this.oldAmount = value;
            updatingAmount = false;
        });

        this.WorkflowAmountHasFocus.subscribe((value: boolean) => {
            if (value && (!this.WorkflowAmount() || this.WorkflowAmount() <= 0))
                this.WorkflowAmount(Math.max(this.RowAmount() - this.AlreadyAssignedAmount(), 0));
        });
    }

    public dispose(): void {
        this.observablesSubscriptions.forEach((s) => s.dispose());
    }

    public setJobOrderId(jobOrderId: number): void {
        this.DocumentsDataSource.setJobOrderIds(jobOrderId);
    }

    public setGetFullyAssignedRows(value: boolean): void {
        this.getFullyAssignedRows = value;
    }

    public setGetAllJobOrders(value: boolean): void {
        this.DocumentsDataSource.setFilterByJobOrder(!value);
    }

    public async loadUpdatedData(): Promise<void> {
        if (!this.RowId()) return;

        const orderRow: IWorkflowRelatedDocument = await this.todoListService.GetUpdatedDataForWorkflowRelatedDocuments(
            this.relatedDocument.WorkflowId,
            this.RowId(),
            this.RowType()
        );

        if (!orderRow) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.OrderRowNotFound);
            return;
        }

        this.relatedDocument = orderRow;
        this.loadData(orderRow);
    }

    public isValid(): boolean {
        return !!this.DocumentId() && !!this.RowId() && this.WorkflowAmount() >= 0;
    }

    public getData(): IWorkflowRelatedDocument {
        const data: IWorkflowRelatedDocument = $.extend(true, {}, this.relatedDocument);

        data.DocumentId = !this.DocumentId() ? data.DocumentId : this.DocumentId();
        data.DocumentNumber = !this.DocumentNumber() ? data.DocumentNumber : this.DocumentNumber();
        data.DocumentDate = !this.DocumentDate() ? data.DocumentDate : this.DocumentDate();
        data.DocumentProtocolId = !this.DocumentProtocolId() ? data.DocumentProtocolId : this.DocumentProtocolId();

        data.RowId = !this.RowId() ? data.RowId : this.RowId();
        data.RowDescription = !this.RowDescription() ? data.RowDescription : this.RowDescription();
        data.RowAmount = !this.RowAmount() ? data.RowAmount : this.RowAmount();
        data.RowUoM = !this.RowUnitOfMeasure() ? data.RowUoM : this.RowUnitOfMeasure();
        data.RowPrice = !this.RowPrice() ? data.RowPrice : this.RowPrice();

        data.WorkflowAmount = this.WorkflowAmount();
        data.ValidityStartDate = this.ValidityStartDate();

        return data;
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.DocumentsDataSource) {
            const document = !model ? null : (model.model as IDocumentCommonData);
            this.DocumentNumber(!document ? null : document.DocumentNumber);
            this.DocumentDate(!document ? null : document.DocumentDate);
            this.ValidityStartDate(!document ? null : document.DocumentDate);
            this.DocumentProtocolId(!document ? null : document.ProtocolId);
            this.DocumentsRowsDataSource.setDocumentAndTypeId(
                !document ? null : document.Id,
                !document ? null : document.DocumentType
            );
            if (!document) this.loadRowDataFromDocumentRow(null);
        } else {
            const row = !model ? null : (model.model as ICommonRowData);
            this.loadRowDataFromDocumentRow(row);
        }
    }

    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.DocumentsDataSource) {
            this.DocumentNumber(null);
            this.DocumentDate(null);
            this.ValidityStartDate(null);
            this.DocumentProtocolId(null);
            this.DocumentsRowsDataSource.setDocumentAndTypeId(null, null);
            this.loadRowDataFromDocumentRow(null);
        } else {
            this.loadRowDataFromDocumentRow(null);
        }
    }

    private loadData(relatedDocument: IWorkflowRelatedDocument): void {
        this.Id = relatedDocument.Id;
        this.m_documentType = relatedDocument.DocumentType;

        this.DocumentId(relatedDocument.DocumentId);
        this.DocumentNumber(relatedDocument.DocumentNumber);
        this.DocumentDate(relatedDocument.DocumentDate);
        this.DocumentProtocolId(relatedDocument.DocumentProtocolId);

        this.RowId(relatedDocument.RowId);
        this.RowDescription(relatedDocument.RowDescription);
        this.RowAmount(relatedDocument.RowAmount);
        this.RowUnitOfMeasure(relatedDocument.RowUoM);
        this.RowPrice(relatedDocument.RowPrice);

        this.AlreadyAssignedAmount(relatedDocument.AlreadyAssignedAmount - this.relatedDocument.WorkflowAmount);
        this.WorkflowAmount(relatedDocument.WorkflowAmount);
        this.ValidityStartDate(relatedDocument.ValidityStartDate);

        this.DocumentHasBeenDeleted(relatedDocument.DocumentHasBeenDeleted);

        this.RowHasBeenDeleted(relatedDocument.RowHasBeenDeleted);

        this.oldAmount = relatedDocument.WorkflowAmount;

        this.isChanged(0);
    }

    private loadRowDataFromDocumentRow(documentRow: ICommonRowData): void {
        if (!documentRow) {
            this.RowId(null);
            this.RowDescription(null);
            this.RowAmount(0);
            this.RowUnitOfMeasure(null);
            this.RowPrice(0);
            this.AlreadyAssignedAmount(0);
        } else {
            this.RowDescription(documentRow.Description);
            this.RowAmount(documentRow.Amount);
            this.RowUnitOfMeasure(documentRow.Uom);
            this.RowPrice(documentRow.NetUnitPrice);
            this.AlreadyAssignedAmount(
                documentRow.AssignedAmountToWorkflows - this.relatedDocument.AlreadyAssignedAmount
            );
        }
    }

    private filterRows(model: IDataSourceModel<number, ICommonRowData>): boolean {
        const rowModel = model.model;
        const workflowsRelatedDocumentsRows: WorkflowRelatedDocument[] = this.editor.DocumentsList();

        let alreadyAssignedToCurrentWorkflow = false;
        const fullyAssigned: boolean = this.editor.isFullyAssignedRow(rowModel);

        for (const row of workflowsRelatedDocumentsRows) {
            if (row.RowId() === rowModel.Id) {
                alreadyAssignedToCurrentWorkflow = true;
                break;
            }
        }

        return !alreadyAssignedToCurrentWorkflow && (this.getFullyAssignedRows || fullyAssigned === false);
    }
}
