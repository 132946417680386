import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ComponentUtils, Param, ComponentParam } from "../../../Core/utils/ComponentUtils";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IExpireModes, IPaymentExtension } from "../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { DetectChanges, DetectClassChanges } from "../../../Core/ChangeDetection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IValidator, IValidationService, IValidation } from "../../../ProlifeSdk/ValidationService";

const attributes = {
    CustomerId: "customerId",
    Editable: "editable",
    InjectTo: "inject-to",
};

export interface ICustomerPaymentExtensionsEditor {
    HasChanges: ko.Computed<boolean>;

    Load(): Promise<void>;
    GetData(): IPaymentExtension[];
    Validate(): IValidation;
}

export interface ICustomerPaymentExtensionsEditorParams {
    CustomerId: Param<number>;
    Editable: Param<boolean>;

    InjectTo: Param<CustomerPaymentExtensionsEditor>;
}

@DetectClassChanges
export class CustomerPaymentExtensionsEditor implements ICustomerPaymentExtensionsEditor {
    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expiriesManager: IExpireModes;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    CustomerId: ComponentParam<number>;
    Editable: ComponentParam<boolean>;

    @DetectChanges
    public PaymentExtensions: ko.ObservableArray<PaymentExtension> = ko.observableArray([]);
    public isChanged: ko.Observable<number> = ko.observable(0);

    public HasChanges: ko.Computed<boolean>;

    private validator: IValidator<PaymentExtension>;

    private loading = false;

    constructor(params: ICustomerPaymentExtensionsEditorParams) {
        this.CustomerId = ComponentUtils.parseParameter(params.CustomerId, -1);
        this.Editable = ComponentUtils.parseParameter(params.Editable, false);

        this.Load();

        this.HasChanges = ko.computed(() => {
            return this.isChanged() !== 0 || this.PaymentExtensions().filter((e) => e.isChanged() !== 0).length > 0;
        });

        this.validator = this.validationService
            .createValidator<PaymentExtension>()
            .isTrue((p) => p.IsValid(), ProlifeSdk.TextResources.Invoices.ExpiriesExtensionsDialogError);

        const injectTo = ComponentUtils.parseParameter(params.InjectTo, null);
        if (injectTo) injectTo(this);
    }

    public async Load(): Promise<void> {
        this.loading = true;

        const paymentExtensions = await this.expiriesManager.GetPaymentExtensions(this.CustomerId());
        this.PaymentExtensions(paymentExtensions.map((e: IPaymentExtension) => new PaymentExtension(e, this)));

        this.isChanged(0);
        this.loading = false;
    }

    public NewExtension(): void {
        const newExtension: IPaymentExtension = {
            Id: -1,
            CustomerId: this.CustomerId(),
            ExtensionDate: null,
            ExtensionValue: null,
        };

        this.PaymentExtensions.push(new PaymentExtension(newExtension, this));
    }

    public RemoveExtension(extension: PaymentExtension): void {
        this.PaymentExtensions.remove(extension);
    }

    public Validate(): IValidation {
        const paymentExtensions = this.PaymentExtensions();

        for (const pe of paymentExtensions) {
            const validations = this.validator.validate(pe);
            const validation = validations.firstOrDefault();
            if (validation && !validation.valid) return validation;
        }

        return { valid: true, message: "" };
    }

    public IsValid(): boolean {
        const validationError = this.Validate();
        if (!validationError.valid) {
            this.infoToastService.Error(validationError.message);
            return false;
        }

        return true;
    }

    public GetData(): IPaymentExtension[] {
        return this.PaymentExtensions().map((e: PaymentExtension) => e.GetData());
    }

    public Save() {
        if (!this.IsValid()) return;

        const extensions: IPaymentExtension[] = this.GetData();
        this.expiriesManager
            .CreateOrUpdatePaymentExtensions(this.CustomerId(), extensions)
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ExpiriesExtensionSaved);
            })
            .catch(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ExpiriesExtensionSavingError);
            });
    }

    public dispose(): void {}
}

@DetectClassChanges
class PaymentExtension {
    @DetectChanges
    public ExtensionDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public ExtensionValue: ko.Observable<number> = ko.observable();
    public IsValid: ko.Computed<boolean>;

    public InvalidDate: ko.Computed<boolean>;
    public InvalidValue: ko.Computed<boolean>;

    public isChanged: ko.Observable<number> = ko.observable(0);

    constructor(private extension: IPaymentExtension, private editor: CustomerPaymentExtensionsEditor) {
        this.ExtensionDate(this.extension.ExtensionDate);
        this.ExtensionValue(this.extension.ExtensionValue);

        this.isChanged(0);

        this.InvalidDate = ko.computed(() => {
            return (
                !this.ExtensionDate() ||
                this.editor
                    .PaymentExtensions()
                    .filter(
                        (pe) =>
                            pe != this &&
                            !!pe.ExtensionDate() &&
                            pe.ExtensionDate().getDate() == this.ExtensionDate().getDate() &&
                            pe.ExtensionDate().getMonth() == this.ExtensionDate().getMonth()
                    ).length != 0
            );
        });

        this.InvalidValue = ko.computed(() => {
            return !this.ExtensionValue() || this.ExtensionValue() <= 0;
        });

        this.IsValid = ko.computed(() => {
            return !this.InvalidValue() && !this.InvalidDate();
        });
    }

    public Delete(): void {
        this.editor.RemoveExtension(this);
    }

    public GetData(): IPaymentExtension {
        return {
            Id: this.extension.Id,
            CustomerId: this.extension.CustomerId,
            ExtensionDate: this.ExtensionDate(),
            ExtensionValue: this.ExtensionValue(),
        };
    }

    public dispose(): void {}
}

ko.components.register("customer-payment-extensions-editor", {
    viewModel: {
        createViewModel: (
            params: ICustomerPaymentExtensionsEditorParams,
            componentInfo: ko.components.ComponentInfo
        ) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new CustomerPaymentExtensionsEditor(params);

            let pe: PaymentExtension;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div style="height: 100%; display: flex;" data-as={{ vm }}>
                    <div class="col-md-3">
                        <div style="width: 230px; top: 0; position: absolute;">
                            <ul class="ver-inline-menu tabbable margin-bottom-10">
                                <li class="active">
                                    <a href="#" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                                        <i class="fa fa-truck"></i>&nbsp;
                                        {TextResources.Customers.CustomerPaymentExtensions}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-9 compact-table extensions-container">
                        <div data-bind={{ if: vm.Editable }}>
                            <button
                                class="btn btn-circle btn-primary btn-sm pull-right"
                                data-bind={{ click: vm.NewExtension.bind(vm) }}>
                                <i class="fa fa-plus"></i>&nbsp;{TextResources.Customers.NewCustomerPaymentExtension}
                            </button>
                            {/* <button class="btn btn-circle btn-primary btn-sm pull-right" data-bind={{ click: vm.Save.bind(vm) }} style="margin-right: 5px">
                                <i class="fa fa-floppy-o"></i>&nbsp;{TextResources.Customers.SaveCustomerPaymentExtension}
                            </button> */}
                        </div>
                        <table class="table table-condensed">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        {TextResources.Customers.CustomerPaymentExtensionPaymentDate}
                                    </th>
                                    <th class="text-left">
                                        {TextResources.Customers.CustomerPaymentExtensionDaysNumber}
                                    </th>
                                    <ko-bind data-bind={{ if: vm.Editable }}>
                                        <th style="width: 50px">
                                            {TextResources.Customers.CustomerPaymentExtensionActions}
                                        </th>
                                    </ko-bind>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ foreach: { data: vm.PaymentExtensions, as: "pe" } }}>
                                    <tr>
                                        <td class="edit-field">
                                            <ko-bind data-bind={{ if: vm.Editable }}>
                                                <div style="position: relative;">
                                                    <input
                                                        type="text"
                                                        class="form-control input-sm"
                                                        data-bind={{
                                                            datePicker: pe.ExtensionDate,
                                                            customDateTimeFormat: "DD/MM",
                                                            dayViewHeaderFormat: "MMMM",
                                                            css: { "text-danger": pe.InvalidDate },
                                                        }}></input>
                                                </div>
                                            </ko-bind>
                                            <ko-bind data-bind={{ ifnot: vm.Editable }}>
                                                <span
                                                    class="form-control input-sm"
                                                    data-bind={{
                                                        dateText: pe.ExtensionDate,
                                                        customDateTimeFormat: "DD/MM",
                                                        dayViewHeaderFormat: "MMMM",
                                                    }}></span>
                                            </ko-bind>
                                        </td>
                                        <td class="edit-field">
                                            <ko-bind data-bind={{ if: vm.Editable }}>
                                                <input
                                                    type="text"
                                                    class="form-control input-sm"
                                                    data-bind={{
                                                        numberValue: pe.ExtensionValue,
                                                        format: "0,0",
                                                        css: { "text-danger": pe.InvalidValue },
                                                        valueUpdate: "afterkeydown",
                                                    }}></input>
                                            </ko-bind>
                                            <ko-bind data-bind={{ ifnot: vm.Editable }}>
                                                <span
                                                    class="form-control input-sm"
                                                    data-bind={{ numberText: pe.ExtensionValue, format: "0,0" }}></span>
                                            </ko-bind>
                                        </td>
                                        <ko-bind data-bind={{ if: vm.Editable }}>
                                            <td class="actions-col edit-field">
                                                <button
                                                    type="button"
                                                    class="btn btn-xs btn-danger"
                                                    data-bind={{ click: pe.Delete.bind(pe) }}>
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </td>
                                        </ko-bind>
                                    </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ if: vm.PaymentExtensions().length === 0 }}>
                                    <tr>
                                        <td colSpan={3} class="text-center edit-field">
                                            <h5>{TextResources.Customers.EmptyCustomerPaymentExtensionsList}</h5>
                                        </td>
                                    </tr>
                                </ko-bind>
                            </tbody>
                        </table>
                    </div>
                </div>,
            ]);

            return vm;
        },
    },
    template: [],
});
