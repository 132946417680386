import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { BindTo } from "../../../../Components/Bind";
import { LayoutContent, LayoutWithHeader, LayoutHeader } from "../../../../Components/Layouts";
import { IWarehouseSection } from "../WarehouseViewModel";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { WarehouseInspectionsDataSource, IWarehouseInspectionsDataSourceModel, WarehouseInspectionForList } from "../../../../DataSources/WarehouseInspectionsDataSource";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { IWarehouseInspectionsService } from "../../../WarehouseInspectionsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IException } from "../../../../Core/interfaces/IException";
import { CheckBox } from "../../../../Components/Checkbox";
import { WarehouseInspectionStatus } from "./Enums/WarehouseInspectionStatus";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DateRangeInput } from "../../../../Components/DateRangeInput";
import moment = require("moment");

let styleSheet = jss.createStyleSheet({
    inspections: {
        "& .header": {
            "& .page-bar": {
                padding: "5px",
                marginBottom: 0
            }
        }
    },
    inspectionsTable: {
        "&.table-advance": {
            "& > thead": {
                "& > tr": {
                    "& > th": {
                        backgroundColor: "#DDD",

                        "&.alerts-column": {
                            width: "50px",
                            maxWidth: "50px"
                        },

                        "& > .btn-group": {
                            "&.header-btn": {
                                "& > .btn": {
                                    backgroundColor: "#DDD",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#666"
                                }
                            }
                        },

                        "& .dropdown-menu": {
                            minWidth: "300px !important",

                            "& .closed-inspections-filter": {
                                borderTop: "1px solid #ddd",
                                marginTop: "20px"
                            }
                        }
                    }
                }
            },

            "& .source-warehouses, & .destination-warehouses": {
                maxWidth: "130px"
            }
        }
    }
});

const { classes } = styleSheet.attach();

export class WarehouseInspectionsUI implements IWarehouseSection {
    SectionId: number = 12;
    
    constructor() {

    }

    render() {
        return <WarehouseInspections />
    }
}

export function WarehouseInspections() {
    const C = require("./WarehouseInspections")._WarehouseInspections as typeof _WarehouseInspections;
    return <C />;
}

export class _WarehouseInspections implements IWarehouseSection {
    public SectionId: number = 12;
    public InspectionsDataSource: WarehouseInspectionsDataSource = new WarehouseInspectionsDataSource();

    public SearchFilter : ko.Observable<string> = ko.observable();

    public ShowOnDraftStatusInspections : ko.Observable<boolean> = ko.observable(true);
    public ShowOnWorkableStatusInspections : ko.Observable<boolean> = ko.observable(true);
    public ShowOnClosedWithErrorsStatusInspections : ko.Observable<boolean> = ko.observable(true);
    public ShowOnClosedStatusInspections : ko.Observable<boolean> = ko.observable(false);
    public ClosedInspectionsStartDate : ko.Observable<Date> = ko.observable();
    public ClosedInspectionsEndDate : ko.Observable<Date> = ko.observable();
    
    public ShowWorkedInspections : ko.Observable<boolean> = ko.observable(true);
    public ShowNotWorkedInspections : ko.Observable<boolean> = ko.observable(true);

    private subscriptions: ko.Subscription[] = [];

    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {}
    
    public componentDidMount() {
        this.initializeClosedInspectionsDateFilters();
        this.initializeSubscriptions();
        this.initializeDataSources();

        this.setStatusFiltersOnDataSourceAndRefresh();
    }

    public componentWillUnmount() {
        for (const subscription of this.subscriptions)
            subscription.dispose();
        
        this.subscriptions = [];
    }

    private initializeClosedInspectionsDateFilters() {
        const startDate = moment().startOf("day").startOf("week").add(-1, "weeks").toDate();
        this.ClosedInspectionsStartDate(startDate);
        this.ClosedInspectionsEndDate(null);
    }

    private initializeSubscriptions() {
        this.subscriptions.push(this.ShowOnDraftStatusInspections.subscribe(this.setStatusFiltersOnDataSourceAndRefresh.bind(this)));
        this.subscriptions.push(this.ShowOnWorkableStatusInspections.subscribe(this.setStatusFiltersOnDataSourceAndRefresh.bind(this)));
        this.subscriptions.push(this.ShowOnClosedWithErrorsStatusInspections.subscribe(this.setStatusFiltersOnDataSourceAndRefresh.bind(this)));
        this.subscriptions.push(this.ShowOnClosedStatusInspections.subscribe(this.setStatusFiltersOnDataSourceAndRefresh.bind(this)));

        this.subscriptions.push(this.ShowWorkedInspections.subscribe((value: boolean) => {
            this.InspectionsDataSource.setGetWorked(value);
            this.InspectionsDataSource.refresh();
        }));

        this.subscriptions.push(this.ShowNotWorkedInspections.subscribe((value: boolean) => {
            this.InspectionsDataSource.setGetNotWorked(value);
            this.InspectionsDataSource.refresh();
        }));
    }

    private initializeDataSources() {
        this.InspectionsDataSource.setGetWorked(this.ShowWorkedInspections());
        this.InspectionsDataSource.setGetNotWorked(this.ShowNotWorkedInspections());
        this.InspectionsDataSource.setClosedInspectionsStartDate(this.ClosedInspectionsStartDate());
        this.InspectionsDataSource.setClosedInspectionsEndDate(this.ClosedInspectionsEndDate());
    }

    public createWarehouseInspection(): void {
        location.href = TextResources.Warehouse.WarehouseInspectionsURL + "/-1";
    }

    public async editWarehouseInspection(inspection: WarehouseInspectionForList): Promise<void> {
        if (!inspection.isWorked() || await this.dialogsService.ConfirmAsync(TextResources.Warehouse.EditWorkedWarehouseInspection, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes))
            location.href = TextResources.Warehouse.WarehouseInspectionsURL + "/" + inspection.Id;
    }
    
    public async deleteWarehouseInspection(inspection: WarehouseInspectionForList): Promise<void> {
        let message = !inspection.isWorked() ? TextResources.Warehouse.DeleteWarehouseInspectionMessage : TextResources.Warehouse.DeleteWorkedWarehouseInspection;
        let confirm = await this.dialogsService.ConfirmAsync(message, TextResources.ProlifeSdk.Abort, TextResources.Warehouse.Continue);

        if (!confirm)
            return;

        try {
            await this.warehouseInspectionsService.DeleteWarehouseInspection(inspection.Id, null);
            this.infoToastService.Success(TextResources.Warehouse.DeleteWarehouseInspectionSuccess);
            this.InspectionsDataSource.refresh();
        } catch (e) {
            let ex = e as IException;
            this.infoToastService.Error(ex.ExceptionMessage);
        }
    }

    public async updateWarehouseInspectionsOrder(droppedItem: WarehouseInspectionForList, neighbourItem: WarehouseInspectionForList, before: boolean, droppedItemNewIndex: number): Promise<void> {
        try {
            await this.warehouseInspectionsService.UpdateWarehouseInspectionsOrder(droppedItem.Id, neighbourItem.Id, before, droppedItemNewIndex);
        } catch(e) {
            console.log(e);
        }
    }

    public render() {
        let inspections = this;
        let inspection: IWarehouseInspectionsDataSourceModel;

        return (
            <BindTo viewModel={this} as="inspections">
                <LayoutWithHeader className={classes.inspections}>
                    <LayoutHeader className="flex-vertical header">
                        <h3 className="page-title">{TextResources.Warehouse.WarehouseInspectionsTitle}</h3>
                        <div className="page-bar flex-container">
                            <div className="flex-fill">
                                <div className="caption search input-icon">
                                    <i className="fa fa-search"></i>
                                    <input className="form-control" type="text" placeholder={TextResources.ProlifeSdk.SearchPlaceholder} data-bind={{ value: inspections.SearchFilter, valueUpdate: 'afterkeydown' }} />
                                </div>
                            </div>

                            <div class="btn-group open">
                                <button type="button" className="btn grey-salt dropdown-toggle btn-fit-height" data-bind={{ click: inspections.createWarehouseInspection.bind(inspections) }}>
                                    <i className="fa fa-plus"></i>&nbsp;{TextResources.Warehouse.NewInspectionButton}
                                </button>
                            </div>
                        </div>
                    </LayoutHeader>
                    <LayoutContent noOverflow={true}>
                        <Table
                            dataSource={this.InspectionsDataSource} 
                            textFilter={this.SearchFilter} 
                            scrollable={true} 
                            className={classes.inspectionsTable + " table-advance"} 
                            rowAs="inspection"
                            rowsSortingValueGetter={(model) => model.Order}
                            rowsSortingValueSetter={(model, newOrder) => model.Order = newOrder}
                            onRowSorting={this.updateWarehouseInspectionsOrder.bind(this)}
                            >
                            <Column title={TextResources.Warehouse.WarehouseInspectionColumn}>
                                <span data-bind={{ text: inspection.model.Title }}></span>
                            </Column>
                            <Column title={TextResources.Warehouse.WarehouseInspectionNumberOrArticlesColumn}>
                                <span data-bind={{ text: inspection.model.TotalNumberOfArticles }}></span>
                            </Column>
                            <Column title={TextResources.Warehouse.InspectionSourcesWarehousesColumn} className="source-warehouses">
                                <div className="flex-container">
                                    <div className="flex-fill text-ellipsis">
                                        <span data-bind={{ text: inspection.model.SourceWarehouses }}></span>
                                    </div>
                                    <div className="flex-container">
                                        <span className="badge" data-bind={{ text: inspection.model.NumberOfSourceWarehouses, attr: { title: inspection.model.SourceWarehouses } }}></span>
                                    </div>
                                </div>
                            </Column>
                            <Column title={TextResources.Warehouse.InspectionDestinationWarehousesColumn} className="destination-warehouses">
                                <div className="flex-container">
                                    <div className="flex-fill text-ellipsis">
                                        <span data-bind={{ text: inspection.model.DestinationWarehouses }}></span>
                                    </div>
                                    <div className="flex-container">
                                        <span className="badge" data-bind={{ text: inspection.model.NumberOfDestinationWarehouses, attr: { title: inspection.model.DestinationWarehouses } }}></span>
                                    </div>
                                </div>
                            </Column>
                            <Column>
                                <ColumnHeader>
                                    {() =>  <div className="btn-group btn-group-sm ignore-sort header-btn" style={{ marginRight: "5px;" }}>
                                                <button className="btn btn-default dropdown" data-toggle="dropdown" aria-expanded="false">
                                                    {TextResources.Warehouse.WarehouseInspectionStatusColumn}&nbsp;<i className="fa fa-filter"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-checkboxes">
                                                    <CheckBox label={TextResources.Warehouse.WarehouseInspectionDarftStatus} checked={this.ShowOnDraftStatusInspections}></CheckBox>
                                                    <CheckBox label={TextResources.Warehouse.WarehouseInspectionWorkableStatus} checked={this.ShowOnWorkableStatusInspections}></CheckBox>
                                                    <CheckBox label={TextResources.Warehouse.WarehouseInspectionClosedWithErrorsStatus} checked={this.ShowOnClosedWithErrorsStatusInspections}></CheckBox>
                                                    <div className="closed-inspections-filter">
                                                        <CheckBox label={TextResources.Warehouse.WarehouseInspectionClosedStatus} checked={this.ShowOnClosedStatusInspections}></CheckBox>
                                                        <DateRangeInput
                                                            startDate={this.ClosedInspectionsStartDate}
                                                            endDate={this.ClosedInspectionsEndDate}
                                                            title={TextResources.Warehouse.ClosedInspectionsDateFilterTitle}
                                                            readOnly={() => !this.ShowOnClosedStatusInspections()} 
                                                            onDateRangeChanges={this.onClosedInspectionsDateRangeChanges.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </ColumnHeader>
                                <ColumnBody>
                                    <span data-bind={{ text: inspection.statusLabel }}></span>
                                </ColumnBody>
                            </Column>
                            <Column title={TextResources.Warehouse.WarehouseInspectionCreationColumn}>
                                <span data-bind={{ text: inspection.model.CreationUser }}></span>, <span data-bind={{ dateText: inspection.model.CreationDate }}></span>
                            </Column>
                            <Column title={TextResources.Warehouse.WarehouseInspectionLastModificationColumn}>
                                <span data-bind={{ text: inspection.model.LastModificationUser }}></span>, <span data-bind={{ dateText: inspection.model.LastModificationDate }}></span>
                            </Column>
                            <Column>
                                <ColumnHeader>
                                    {() =>  <div className="btn-group btn-group-sm ignore-sort header-btn" style={{ marginRight: "5px;" }}>
                                                <button className="btn btn-default dropdown" data-toggle="dropdown" aria-expanded="false">
                                                    {TextResources.Warehouse.WorkedByColumn}&nbsp;<i className="fa fa-filter"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-checkboxes">
                                                    <CheckBox label={TextResources.Warehouse.WorkedByWarehouseInspectionFilter} checked={this.ShowWorkedInspections}></CheckBox>
                                                    <CheckBox label={TextResources.Warehouse.NotWorkedByWarehouseInspectionFilter} checked={this.ShowNotWorkedInspections}></CheckBox>
                                                </div>
                                            </div>
                                    }
                                </ColumnHeader>
                                <ColumnBody>
                                    <span data-bind={{ if: !!inspection.model.WorkedBy }}>
                                        <span data-bind={{ text: inspection.model.WorkedByName }}></span>, <span data-bind={{ dateText: inspection.model.WorkStartDate }}></span>
                                    </span>
                                    <span data-bind={{ ifnot: !!inspection.model.WorkedBy }}>
                                        <span>{TextResources.ProlifeSdk.No}</span>
                                    </span>
                                </ColumnBody>
                            </Column>
                            <Column className="text-center alerts-column">
                                <span data-bind={{ attr: { title: inspection.inspectionProgresTitle } }}>
                                    <i class="fa" data-bind={{ css: { 'fa-exclamation-triangle': !!inspection.model.WorkedBy && !!inspection.model.LastDocumentsGenerationError && !inspection.model.DocumentsGenerated, 'fa-check': !!inspection.model.WorkedBy && inspection.model.DocumentsGenerated } }}></i>
                                </span>
                            </Column>
                            <Column className="text-right">
                                <button className="btn btn-xs yellow" data-bind={{ click: inspections.editWarehouseInspection.bind(inspections, inspection.model) }}>
                                    <i class="fa fa-pencil"></i> {TextResources.Warehouse.WarehouseInspectionEditButton}
                                </button>
                                <button className="btn btn-xs btn-danger" data-bind={{ click: inspections.deleteWarehouseInspection.bind(inspections, inspection.model) }}>
                                    <i class="fa fa-trash-o"></i> {TextResources.Warehouse.WarehouseInspectionDeleteButton}
                                </button>
                            </Column>
                        </Table>
                    </LayoutContent>
                </LayoutWithHeader>
            </BindTo>
        );
    }

    private onClosedInspectionsDateRangeChanges(from: Date, to: Date): void {
        this.InspectionsDataSource.setClosedInspectionsStartDate(from);
        this.InspectionsDataSource.setClosedInspectionsEndDate(to);
        this.InspectionsDataSource.refresh();
    }

    private setStatusFiltersOnDataSourceAndRefresh(): void {
        let filter = [];

        if (this.ShowOnDraftStatusInspections())
            filter.push(WarehouseInspectionStatus.Draft);

        if (this.ShowOnWorkableStatusInspections())
            filter.push(WarehouseInspectionStatus.Workable);
        
        if (this.ShowOnClosedStatusInspections())
            filter.push(WarehouseInspectionStatus.Closed);

        if (this.ShowOnClosedWithErrorsStatusInspections())
            filter.push(WarehouseInspectionStatus.ClosedWithErrors);

        this.InspectionsDataSource.setStatusFilter(filter);
        this.InspectionsDataSource.refresh();
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WarehouseInspections);
}