import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { ReportComponentWithPosition } from "../Components/ReportComponentWithPosition";
import { ReportComponent } from "../Components";

type PositionableContainerProps = {
    component: ReportComponentWithPosition & ReportComponent;
    children: () => React.ReactNode;
}

export function PositionableContainer(props: PositionableContainerProps) {
    const C = require("./PositionableContainer")._PositionableContainer as typeof _PositionableContainer;
    return <C {...props} />;
}

export class _PositionableContainer {
    static defaultProps: Partial<PositionableContainerProps> = {
    }

    constructor(private props : PositionableContainerProps) {
        
    }

    render() {
        const _positionable = this;
        

        return  ComponentUtils.bindTo(
            <div data-bind={{ style: { position: 'absolute', left: _positionable.props.component.x() + 'cm', top: _positionable.props.component.y() + 'cm', width: _positionable.props.component.width() + 'cm', height: _positionable.props.component.height() + 'cm' }}}>
                <div style={{ overflow: 'hidden', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, pointerEvents: 'none', userSelect: 'none' }}>
                    {this.props.children[0]()}
                </div>
            </div>
        , this, "_positionable");
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(PositionableContainer);
}