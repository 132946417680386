import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { StatesNavigationProvider } from "./StatesNavigationProvider";
import { IJobOrderService } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderMenuActionsManager } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderMenuActionsManager";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderStateSettingsManager } from "../../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderType } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { INavigationMenu } from "../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { IJobOrderState } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { INavigationFilters } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";
import { Deferred } from "../../../../../../Core/Deferred";

export class TypesNavigationProvider extends NavigationProvider
{
    private jobOrderStatesSettings : IJobOrderStateSettingsManager;
    private jobOrderService : IJobOrderService;
    private logicalState : number;
    private serviceLocator : IServiceLocator;
    private textFilter : string = null;
    private jobOrdersClickManager : IJobOrderMenuActionsManager;
    private customerId : number = null;

    public Icon : string;
    public Background : string;
    public Foreground: string;

    constructor(serviceLocator : IServiceLocator, logicalState : number, type : IJobOrderType)
    {
        super();
        this.serviceLocator = serviceLocator;
        this.logicalState = logicalState;
        this.templateName = "job-orders-folder-with-icon";
        this.templateUrl = "jobOrder/templates/navigation";
        this.Name = type.Description;
        this.Id = type.Id;
        this.Icon = type.Icon;
        this.Background = type.Background;
        this.Foreground = type.Foreground;
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.jobOrderStatesSettings = <IJobOrderStateSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
    }

    setCurrentNavigator(navigator : INavigationMenu)
    {
        super.setCurrentNavigator(navigator);
    }

    refreshItems() : Promise<void>
    {
        var deferred = new Deferred<void>();
        var states : StatesNavigationProvider[] = this.jobOrderStatesSettings.getJobOrderStates()
            .filter((s : IJobOrderState) => { return this.logicalState == -1 || this.logicalState == s.LogicalState; })
            .map((s : IJobOrderState) => { return new StatesNavigationProvider(this.serviceLocator, s); });

        states.forEach((p : StatesNavigationProvider) => {
            p.setCurrentNavigator(this.navigator());
        });

        /*var selection = (<NavigationMenu.NavigationMenu>this.navigator())
            .getSelectedProviders();

        var indexOfSelection = selection
            .map((p : JobOrderNavigationProvider.JobOrderNavigationProvider) => p.jobOrder.JobOrderId)
            .indexOf(jobOrder.JobOrderId);

        if(indexOfSelection > -1)
        {
            selection[indexOfSelection].removeFromSelection(false);
            provider.select(false);
        }*/


        this.Items(states);
        deferred.resolve();
        return deferred.promise();
    }

    public SetFilterInfoOnModel(f : INavigationFilters)
    {
        f.Type = this.Id;
    }
}

