import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/02/2016
 * Time: 15:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialog } from "../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";

interface IReportColumnInfo {
    Name : string;
    DataType : string;
}

export class ReportColumnsInfoDialog implements IDialog {

    templateName: string = "report-columns-informations";
    templateUrl: string = "prolifesdk/templates/reports";
    title: string = ProlifeSdk.TextResources.ProlifeSdk.ReportColumnsInformations;
    modal: {
        close: (result?: any) => void;
    };

    public Columns : ko.ObservableArray<IReportColumnInfo> = ko.observableArray();
    public CanDownloadTemplate : ko.Observable<boolean> = ko.observable(false);
    public TemplateDownloadUrl : ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor(serviceUrl : string, methodName : string, templateMethodName? : string) {
        this.ajaxService.Get<IReportColumnInfo[]>(serviceUrl, methodName, {})
            .then((data : IReportColumnInfo[]) => {
                this.Columns(data);
            });

        //this.CanDownloadTemplate(!!templateMethodName);
        this.TemplateDownloadUrl("Service/" + serviceUrl + "/" + templateMethodName);
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.modal.close();
    }
}