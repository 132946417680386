import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/06/2017
 * Time: 19:09
 * To change this template use File | Settings | File Templates.
 */

import { ISection } from "../../../../ProlifeSdk/interfaces/survey/ISection";

export class Section {
    public Id: number;
    public Name: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Enabled: ko.Observable<boolean> = ko.observable();
    public Index: ko.Observable<number> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public QuestionnareId: number;
    public PublicLabel: ko.Observable<string> = ko.observable();

    constructor(section: ISection) {
        this.Id = section.Id;
        this.Name(section.Name);
        this.Description(section.Description);
        this.Enabled(section.Enabled);
        this.Index(section.Index);
        this.CreationDate(section.CreationDate);
        this.CreatorId(section.CreatorId);
        this.ModifyDate(section.ModifyDate);
        this.ModifierId(section.ModifierId);
        this.Label(section.Label);
        this.QuestionnareId = section.QuestionnaireId;
        this.PublicLabel(section.PublicLabel);
    }

    public ToJSON(): ISection {
        return <ISection> {
            Id: this.Id,
            Name: this.Name(),
            Description: this.Description(),
            Enabled: this.Enabled(),
            Index: this.Index(),
            CreationDate: this.CreationDate(),
            CreatorId: this.CreatorId(),
            ModifyDate: this.ModifyDate(),
            ModifierId: this.ModifierId(),
            Label: this.Label(),
            QuestionnaireId: this.QuestionnareId,
            PublicLabel: this.PublicLabel()
        };
    }
}