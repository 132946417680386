import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { WorkTimeCategoriesEditingViewModel } from "./ui/WorkTimeCategoriesEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkTimeCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WorkTimeCategoriesSettingsManager implements IWorkTimeCategoriesSettingsManager
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    private categories: IWorkTimeCategory[];

    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.WorkedHours.ResourcesJobTypes);
    }

    load(): Promise<IWorkTimeCategory[]> {
        return this.ajaxService.Post<IWorkTimeCategory[]>("WorkedHours-api", "WorkTimeCategories/Get", {})
            .then((categories: IWorkTimeCategory[]) => this.categories = categories);
    }

    getName(): string {
        return ProlifeSdk.WorkTimeCategoriesSettingsServiceType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.WorkedHours.WorkTimeCategories;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) &&
            !this.modulesService.IsModuleEnabled(ProlifeSdk.ResourcesManagerApplicationCode);
    }

    getEditingUI(): IView {
        return {
            templateName: 'work-time-categories',
            templateUrl: 'workedhours/templates/settings',
            viewModel: new WorkTimeCategoriesEditingViewModel(this)
        };
    }

    public getAll(includeDeleted : boolean, resourceType  = -1): IWorkTimeCategory[]
    {
        let categories : IWorkTimeCategory[] = this.categories;

        if(resourceType > -1)
            categories = categories.filter((c : IWorkTimeCategory) => {
                return c.ResourceType == resourceType;
            });

        if(!includeDeleted)
            categories = categories.filter((c : IWorkTimeCategory) => {
                return c.Deleted == false;
            });

        return categories;
    }

    /***
     * Ritorna solo le categorie delle risorse umane
     * @param includeDeleted
     * @returns {_element[]}
     * @constructor
     */
    public GetAllForHumans(includeDeleted : boolean): IWorkTimeCategory[]
    {
        return this.getAll(includeDeleted).filter((c : IWorkTimeCategory) => {
            return c.ResourceType == 0;
        });
    }

    /***
     * Ritorna solo le categorie delle risorse materiali
     * @param includeDeleted
     * @returns {_element[]}
     * @constructor
     */
    public GetAllForMaterials(includeDeleted : boolean): IWorkTimeCategory[]
    {
        return this.getAll(includeDeleted).filter((c : IWorkTimeCategory) => {
            return c.ResourceType == 1;
        });
    }

    public getById(id : number) : IWorkTimeCategory
    {
        const matches : IWorkTimeCategory[] = this.categories.filter((i : IWorkTimeCategory) => {
            return i.Id == id;
        });
        return matches.length > 0 ? matches[0] : null;
    }

    public getDefault(resourceType  = -1) : IWorkTimeCategory
    {
        if(resourceType > -1)
        {
            const matches : IWorkTimeCategory[] = this.categories.filter((c : IWorkTimeCategory) => { return c.ResourceType == resourceType; });
            return matches.length > 0 ? matches[0] : null;
        }

        return this.categories[0];
    }

    public delete(category: IWorkTimeCategory): Promise<IWorkTimeCategory>
    {
        return this.ajaxService.Delete<IWorkTimeCategory>("WorkedHours-api", "WorkTimeCategories", { methodData: category })
            .then(this.onElementUpdated.bind(this));
    }

    public createOrUpdate(category: IWorkTimeCategory): Promise<IWorkTimeCategory>
    {
        return this.ajaxService.Post<IWorkTimeCategory>("WorkedHours-api", "WorkTimeCategories/CreateOrUpdate", { methodData: category })
            .then(this.onElementUpdated.bind(this));
    }

    private onElementUpdated(category : IWorkTimeCategory)
    {
        const matches = this.categories.filter((t : IWorkTimeCategory) => {
            return t.Id == category.Id;
        });

        if (matches.length == 0)
            this.categories.push(category);
        else
            this.categories[this.categories.indexOf(matches[0])] = category;

        return category;
    }

}
