import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentResourceAssignmentsDetails } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { DocumentResourceDetailedAssignmentExtended, ResourceAssignmentsTable } from "./ResourceAssignmentsTable";

type ResourceAssignmentsProps = {
    resourceAssignments: ko.MaybeObservable<DocumentResourceAssignmentsDetails>;
    from: ko.MaybeObservable<Date>;
    to: ko.MaybeObservable<Date>;
    showTitle?: boolean;
};

export class ResourceAssignments {
    static defaultProps: Partial<ResourceAssignmentsProps> = {
        showTitle: false,
    };

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    private resourceAssignments = ko.observableArray<DocumentResourceDetailedAssignmentExtended>([]);
    private resourceName: ko.Observable<string> = ko.observable<string>("");

    private subscriptions: ko.Subscription[] = [];

    constructor(private props: ResourceAssignmentsProps) {}

    componentDidMount() {
        if (ko.isSubscribable(this.props.resourceAssignments)) {
            this.subscriptions.push(this.props.resourceAssignments.subscribe(this.computeRows.bind(this)));
        } else {
            this.computeRows();
        }
    }

    private computeRows(): void {
        const resourceAssignments = ko.unwrap(this.props.resourceAssignments);
        if (!resourceAssignments) {
            this.resourceAssignments([]);
        } else {
            const resource = this.humanResourcesSettingsManager.getHumanResourceById(resourceAssignments.resourceId);
            const resourceName = this.humanResourcesSettingsManager.getFullName(resource);

            this.resourceName(resourceName);

            const data = resourceAssignments.assignments.map((a) => ({
                ...a,
                isTotalsRow: false,
                isMaxAvailableHoursRow: false,
                isResidualRow: false,
                label: resourceName,
            }));

            data.push(this.createTotalsRow(resourceAssignments, true, false, false));
            data.push(this.createTotalsRow(resourceAssignments, false, true, false));
            data.push(this.createTotalsRow(resourceAssignments, false, false, true));

            this.resourceAssignments(data);
        }
    }

    private createTotalsRow(
        hoursAmountPerMonth: DocumentResourceAssignmentsDetails,
        isTotals: boolean,
        isMaxAvailableHoursRow: boolean,
        isResidual: boolean
    ): DocumentResourceDetailedAssignmentExtended {
        return {
            id: 0,
            document: null,
            fkResource: null,
            hoursAmount: isTotals ? hoursAmountPerMonth.totalAssignedHours : null,
            startDate: null,
            endDate: null,
            notes: "",
            rowNumber: null,

            hoursMonthsDistribution: isTotals
                ? hoursAmountPerMonth.hoursAssignmentsPerMonth
                : isMaxAvailableHoursRow
                ? hoursAmountPerMonth.maxWorkingHoursPerMonth
                : hoursAmountPerMonth.residualHoursPerMonth,

            detailsFromDate: ko.unwrap(this.props.from),
            detailsToDate: ko.unwrap(this.props.to),
            workingHoursInAssignmentPeriod: null,
            workingHoursInDetailsPeriod: null,
            isTotalsRow: isTotals,
            isMaxAvailableHoursRow: isMaxAvailableHoursRow,
            isResidualRow: isResidual,

            totalAssignedHoursInDetailsPeriod: isTotals
                ? hoursAmountPerMonth.totalAssignedHoursInDetailsPeriod
                : isMaxAvailableHoursRow
                ? hoursAmountPerMonth.maxAvailableHoursInDetailsPeriod
                : hoursAmountPerMonth.residualHoursInDetailsPeriod,

            label: isTotals
                ? TextResources.Invoices.DocumentResourceTotalHours
                : isResidual
                ? TextResources.Invoices.DocumentResourceResidualAvailableHours
                : TextResources.Invoices.DocumentResourceMaxAvailableHours,
        };
    }

    render() {
        return ComponentUtils.bindTo(
            <ResourceAssignmentsTable
                resourceAssignments={this.resourceAssignments}
                from={this.props.from}
                to={this.props.to}
                title={this.props.showTitle ? this.resourceName : null}
            />,
            this
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(ResourceAssignments);
}
