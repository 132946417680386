import * as ko from "knockout";
import { Document } from "../../../../Invoices/invoices/documents/Document";
import { OffsetsCodesDataSource } from "../../../../DataSources/OffsetsCodesDataSource";
import { IDocumentOffsetLeaf } from "../../../../Invoices/OffsetsService";
import { IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class OffsetAutomationDialog implements IDialog {
    templateName: string = "offsetAutomation";
    templateUrl: string = "prolifesdk/templates/documents/automations";
    title: string = "Modifica Contropartite";
    modal: { close: (result?: any) => void; };

    public OffsetId: ko.Observable<number> = ko.observable();
    public OffsetCode: ko.Observable<string> = ko.observable();
    public OffsetsCodesDataSource: OffsetsCodesDataSource = new OffsetsCodesDataSource();

    constructor(private document : Document) {

    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.OffsetsCodesDataSource) {
            let offset = !model ? null : model.model as IDocumentOffsetLeaf;
            this.OffsetCode(!offset ? null : offset.Code);
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.OffsetsCodesDataSource) {
            this.OffsetCode(null);
        }
    }

    public setOffsets() {
        for(let row of this.document.Rows()) {
            row.OffsetsDataSource.selectByIds(this.OffsetId());
        }
        this.modal.close(true);
    }
}