import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss"
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IAllocationsService, ICartAllocationForTeam, ICartAllocationTeam, ICartAndTeamAssociation, IMissingRoleOnTeam, ITeamAllocationsForGantt } from "../../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { AllocationMode } from "../../../enums/AllocationMode";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { ICartsReallocationObserver, IChangesNotificationsService } from "../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { CheckBox } from "../../../../../Components/Checkbox";
import { AllocatedCart, CartAllocation } from "./AllocatedCart";
import { CartInfoDialog } from "../../CartInfoDialog";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { DateTimeText } from "../../../../../Components/DateTimeText";

const styleSheet = jss.createStyleSheet({
    "team-name": {
        alignItems: "center",
        "& span": {
            display: "inline-block"
        },
        "& .caret-wrapper": {
            width: "25px",
            textAlign: "center",
            fontSize: "14px"
        }
    },
    "name-column": {
        width: "550px !important",
        maxWidth: "550px !important",
        "& > div": {
            height: "100%",
            alignItems: "center"
        }
    },
    "period-column": {
        width: "calc(100% - 680px)",
    },
    "actions-column": {
        width: "100px",
        textAlign: "right"
    },
    "allocations-table": {
        marginTop: "2px !important",

        "& tr": {
            height: "30px !important",
            backgroundColor: "#fff !important",
            border: "0px !important",

            "&:not(.secondary-row)": {
                "&:hover": {
                    "& td": {
                        backgroundColor: "#d4e1fa !important"
                    }
                }
            }
        },
        "& td": {
            border: "0px !important",
            backgroundColor: "#fff !important"
        },
        "& > tbody": {
            "& > tr": {
                border: "1px solid #ddd !important"
            }
        }
    },
    "carts-table": {
        "& > tbody": {
            "& > tr": {
                "&:not(:last-child)": {
                    borderBottom: "1px solid #ddd !important"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type CartAllocationsProps = {
    cartId?: number;
    onCartInfoEdit?: () => void;
}

type TeamAllocations = {
    teamId: number;
    teamName: string;
    carts: ko.ObservableArray<CartAllocation>;
    showCarts: ko.Observable<boolean>;
}

export function CartAllocations(props: CartAllocationsProps) {
    const C = require("./CartAllocations")._CartAllocations as typeof _CartAllocations;
    return <C {...props} />;
}

export class _CartAllocations implements ICartsReallocationObserver {
    static defaultProps: Partial<CartAllocationsProps> = {
    }

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService : IAllocationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationService: IChangesNotificationsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    private Allocations: ko.ObservableArray<TeamAllocations> = ko.observableArray([]);
    private ShowAllTeams: ko.Observable<boolean> = ko.observable(false);
    private OpenedTeamsCache: { [teamId: number]: boolean } = {};

    private showAllInterceptor: ko.Subscription;

    constructor(private props : CartAllocationsProps) {}
    
    componentDidMount() {
        this.changesNotificationService.RegisterCartsReallocationObserver(this);
        this.loadAllocations();

        this.showAllInterceptor = this.ShowAllTeams.subscribe(() => this.loadAllocations());
    }

    componentWillUnmount() {
        this.changesNotificationService.UnregisterCartsReallocationObserver(this);
        this.showAllInterceptor.dispose();
    }

    OnCartsReallocation(teamId: number) {
        this.loadAllocations();
    }
    
    OnReallocation() {
        this.loadAllocations();
    }

    OnReallocationError(errorMessage: string) {
        this.infoToastService.Error(errorMessage);
    }

    private async loadAllocations(): Promise<void> {
        const promises: (Promise<ITeamAllocationsForGantt>)[] = [];

        const createCarts = (carts: ICartAllocationForTeam[], teamId: number) => {
            const result: CartAllocation[] = [];

            let cart: CartAllocation;
            for (const allocatedCart of carts) {
                if (!cart || allocatedCart.CartId !== cart.cartId || allocatedCart.AllocationMode !== cart.allocationMode) {
                    cart = result.find(c => c.cartId === allocatedCart.CartId && c.allocationMode === allocatedCart.AllocationMode);
                    if (!cart) {
                        const date = moment().isAfter(allocatedCart.Date) ? moment().toDate() : allocatedCart.Date;
                        cart = {
                            allocationId: allocatedCart.AllocationId,
                            teamId: teamId,
                            cartId: allocatedCart.CartId,
                            cartName: allocatedCart.CartName,
                            cartOrder: allocatedCart.CartOrder,
                            allocationMode: allocatedCart.AllocationMode,
                            startDate: date,
                            endDate: date,
                            HasPriority: ko.observable(allocatedCart.Priority === 1),
                            AllocatedOnTeamsNumber: ko.observable(allocatedCart.AllocatedOnTeamsNumber)
                        };

                        result.push(cart);
                    }
                }

                cart.endDate = allocatedCart.Date;
            }

            return result.sort((left, right) => left.cartOrder - right.cartOrder);
        };

        try {
            const teams: ICartAllocationTeam[] = await this.allocationsService.getAllocatedTeams(this.ShowAllTeams());
            const allocations: TeamAllocations[] = [];

            for (const team of teams)
                promises.push(this.allocationsService.getCartAllocationForTeam(team.TeamId));

            const results = await Promise.all(promises);
            let index = 0;
            for (const team of teams) {
                const carts = results[index];
                allocations.push({
                    teamId: team.TeamId,
                    teamName: team.Name,
                    showCarts: ko.observable(this.OpenedTeamsCache[team.TeamId] || false),
                    carts: ko.observableArray(createCarts(carts.Allocations, team.TeamId))
                });

                index++;
            }

            this.Allocations(allocations);
        } catch(e) {
            console.log(e);
        }
    }
    
    private async allocateCartOnTeam(teamId: number): Promise<void> {
        let confirm = true;
        
        const team = this.Allocations().find(t => t.teamId === teamId);
        confirm = await this.dialogsService.ConfirmAsync(String.format(TextResources.Allocations.AllocateCartOnTeamMessage, team?.teamName ?? ""), TextResources.ProlifeSdk.Abort, TextResources.ProlifeSdk.Confirm);

        if (!confirm)
            return;

        try {
            const missingRoles = await this.allocationsService.getMissingRolesByCartId(this.props.cartId, teamId);
            if (missingRoles.length > 0) {
                let units: string = missingRoles.map(o => { return o.RoleName; }).join("<br/>"); // TODO questo codice è duplicato in altri punti, SISTEMARE
                units = "<br/><br/>" + units + "<br/><br/>";
                const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
                confirm = await this.dialogsService.ConfirmAsync(message, TextResources.Allocations.MissedRolesOnTeamCancel, TextResources.Allocations.MissedRolesOnTeamConfirm);
            }

            if (confirm)
                await this.allocationsService.allocateCartOnTeam(teamId, this.props.cartId, AllocationMode.AutomaticAllocation);
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(TextResources.Allocations.AllocateCartsOnTeamError);
        }
    }
    
    /* private async deallocateCartFromTeam(cartId: number, teamId: number): Promise<void> {
        let confirm = true;
        
        const team = this.Allocations().find(t => t.teamId === teamId);
        confirm = await this.dialogsService.ConfirmAsync(String.format(TextResources.Allocations.DeallocateCartFromTeamMessage, team?.teamName ?? ""), TextResources.ProlifeSdk.Abort, TextResources.ProlifeSdk.Confirm);

        if (!confirm)
            return;

        try {
            await this.allocationsService.deallocateCart(teamId, cartId);
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(TextResources.Allocations.DeallocateCartError);
        }
    } */

    private async showCartInfoDialog(teamId: number, cartId: number = null): Promise<void> {
        const team = this.Allocations().find(t => t.teamId === teamId);
        const dialog = new CartInfoDialog(null, teamId, team?.teamName, cartId);
        await dialog.show();
        this.props.onCartInfoEdit && this.props.onCartInfoEdit();
    }

    private handleShowCartsClick(team: TeamAllocations): void {
        const state = !team.showCarts();
        team.showCarts(state);

        if (state)
            this.OpenedTeamsCache[team.teamId] = state;
        else
            delete this.OpenedTeamsCache[team.teamId];
    }

    private async handleCartsReordering(droppedItem: CartAllocation, neighbourItem: CartAllocation, before, newIndex: number): Promise<void> { // TODO questa funzionalità verrà rimpiazzata dal gantt
        try {
            if (droppedItem.teamId === neighbourItem.teamId)
                await this.allocationsService.reorderCartsAllocation(droppedItem.teamId, droppedItem.cartId, neighbourItem.cartId, before);
            else {
                const cartsToAllocate: ICartAndTeamAssociation[] = [
                    {
                        CartId: droppedItem.cartId,
                        TeamId: droppedItem.teamId,
                        AllocationId: droppedItem.allocationId
                    }
                ];

                const companyGuid = this.userInfo.getCurrentCompanyGuid();

                try {
                    await this.allocationsService.AllocateCartsAtPositionOnTeam(neighbourItem.teamId, cartsToAllocate, neighbourItem.cartId, before, companyGuid);
                } catch(e) {
                    if (e.ExceptionType === ProlifeSdk.ServerException_AllocateCartsAtPositionOnTeamValidationException) {
                        let units: string = e.MissingRolesOnTeam.map((o: IMissingRoleOnTeam) => { return String.format(TextResources.Allocations.MissedRolesOnTeamAlertRoleName, o.RoleName, o.CartTitle) }).join("<br/>");
                        units = "<br/><br/>" + units + "<br/><br/>";
                        const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
        
                        const confirm = await this.dialogsService.ConfirmAsync(message, TextResources.Allocations.MissedRolesOnTeamCancel, TextResources.Allocations.MissedRolesOnTeamConfirm);
        
                        if (confirm) {
                            try {
                                await this.allocationsService.AllocateCartsAtPositionOnTeam(neighbourItem.teamId, cartsToAllocate, neighbourItem.cartId, before, companyGuid, true);
                            } catch(e) {
                                console.error(e);
                                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartsOnTeamError);    
                            }
                        }
                    } else {
                        console.error(e);
                        this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartsOnTeamError);  
                    }
                }
            }
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(TextResources.Allocations.AllocateCartsOnTeamError);
        }
    }

    render() {
        const team: IDataSourceModel<number, TeamAllocations> = null;

        const createCartModel = (cart: CartAllocation) => {
            return {
                id: cart.cartId,
                title: cart.cartName,
                isGroup: false,
                isLeaf: true,
                model: cart,
                dragEnabled: cart.allocationMode === AllocationMode.AutomaticAllocation
            } as IDataSourceModel<number, CartAllocation>;
        }

        return  <Table
                    compact
                    systemScrollable
                    noHeader
                    dataSource={{ array: this.Allocations, factory: Table.defaultFactory(item => ({ id: item.teamId, title: item.teamName }))}}
                    rowAs="team"
                    className={classes["allocations-table"]}
                    headerActions={() => (
                        <>
                            {this.authorizationService.isAuthorized("Allocations_ShowAllTeams") && <CheckBox checked={this.ShowAllTeams} label={TextResources.Allocations.ShowAallTeams} />}
                        </>
                    )}
                >
                    <Column className={classes["name-column"]}>
                        <ColumnBody>
                            {(item: ITableItem<TeamAllocations>) => (
                                <div className={ComponentUtils.classNames("flex-container", classes["team-name"])}>
                                    <span className="caret-wrapper" onClick={() => this.handleShowCartsClick(item.Data.model)}>
                                        <i className="fa" data-bind={{ css: { 'fa-caret-down': team.model.showCarts, 'fa-caret-right': !team.model.showCarts() }}}></i>
                                    </span>
                                    <span className="flex-fill bold">{item.Data.model.teamName}</span>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column className={classes["actions-column"]}>
                        <ColumnBody>
                            {(item: ITableItem<TeamAllocations>) => (
                                <>
                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => this.allocateCartOnTeam(item.Data.model.teamId)} title={TextResources.Allocations.AllocateCartOnTeamTooltip}>
                                        <i className="fa fa-dropbox"></i>&nbsp;{TextResources.Allocations.AllocateCartOnTeam}
                                    </button>
                                    <button type="button" className="btn btn-link btn-xs" onClick={() => this.showCartInfoDialog(item.Data.model.teamId)}>
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                    <SecondaryRow if={() => "team.model.showCarts"} className="secondary-row">
                        {(item: ITableItem<TeamAllocations>) =>  (
                            <td colSpan={2}>
                                <Table
                                    compact
                                    noHeader
                                    dataSource={{ array: item.Data.model.carts, factory: createCartModel }}
                                    className={classes["carts-table"]}

                                    onRowSorting={this.handleCartsReordering.bind(this)}
                                    rowsSortingValueGetter={(item) => item.cartOrder}
                                    rowsSortingValueSetter={(item, value: number) => item.cartOrder = value}
                                    disableDropBetweenNotDraggableRows
                                    disableDropBeforeNotDraggableRowIfFirst
                                    disableDropAfterNotDraggableRowIfLast
                                    mimeType="application/prolife-cart-allocation"
                                >
                                    <Column className={classes["name-column"]}>
                                        <ColumnBody>
                                            {(cart: ITableItem<CartAllocation>) => <AllocatedCart cart={cart.Data.model} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column className={classes["period-column"]}>
                                        <ColumnBody>
                                            {(cart: ITableItem<CartAllocation>) => (
                                                <>
                                                    <DateTimeText dateTime={cart.Data.model.startDate} format="L"/>
                                                    &nbsp;
                                                    <i className="fa fa-arrow-right"></i>
                                                    &nbsp;
                                                    <DateTimeText dateTime={cart.Data.model.endDate} format="L"/>
                                                </>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                    <Column className={classes["actions-column"]}>
                                        <ColumnBody>
                                            {(cart: ITableItem<CartAllocation>) => (
                                                <>
                                                <button type="button" className="btn btn-link btn-xs" onClick={() => this.showCartInfoDialog(item.Data.model.teamId, cart.Data.model.cartId)}>
                                                    <i className="fa fa-pencil"></i>
                                                </button>
                                                {/* <button type="button" className="btn btn-danger btn-xs" onClick={() => this.deallocateCartFromTeam(cart.Data.model.cartId, item.Data.model.teamId)}>
                                                    <i className="fa fa-trash-o" title={TextResources.Allocations.AllocateCartOnTeamTooltip}></i>
                                                </button> */}
                                                </>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                </Table>
                            </td>
                        )}
                    </SecondaryRow>
                </Table>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CartAllocations);
}