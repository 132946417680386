import * as ko from "knockout";
import { ICustomerDiscountCatalog, IDiscountsCatalogsService } from "../Warehouse/DiscountsCatalogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";

export interface ICustomerDiscountCatalogsDataSourceModel extends IDataSourceModel<number, ICustomerDiscountCatalog> {

}

export interface ICustomerDiscountCatalogsDataSource extends IDataSource {

}

export interface IDraggedCustomerDiscountCatalog {
    CatalogId: number;
    CustomerId: number;
    CompanyGuid: string;
}

export interface IBaseCustomerDiscountCatalogsDataSourceObserver {
    onDataChanged() : void;
}

export abstract class BaseCustomerDiscountCatalogsDataSource implements ICustomerDiscountCatalogsDataSource {
    @LazyImport(nameof<IDiscountsCatalogsService>())
    protected discountsCatalogsService : IDiscountsCatalogsService;

    @LazyImport(nameof<IUserInfo>())
    protected userInfo: IUserInfo;

    protected customerId : number = -1;
    protected view: IDataSourceView;
    protected refreshRequested: boolean = false;

    private listeners : IBaseCustomerDiscountCatalogsDataSourceObserver[] = [];

    constructor(protected mimeType : string, protected isCustomer : boolean) {

    }

    public addObserver(observer : IBaseCustomerDiscountCatalogsDataSourceObserver) {
        this.listeners.push(observer);
    }

    public setCustomerId(customerId : number) {
        this.customerId = customerId;
    }

    abstract getTitle(currentModel: ICustomerDiscountCatalogsDataSourceModel): string;

    isGroupedData(currentModel: ICustomerDiscountCatalogsDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: ICustomerDiscountCatalogsDataSourceModel, b: ICustomerDiscountCatalogsDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    abstract getData(currentModel: ICustomerDiscountCatalogsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICustomerDiscountCatalogsDataSourceModel[]>;    
    abstract getById(currentModel: ICustomerDiscountCatalogsDataSourceModel, ids: number[]): Promise<ICustomerDiscountCatalogsDataSourceModel[]>;

    protected createModelFor(catalog : ICustomerDiscountCatalog) : ICustomerDiscountCatalogsDataSourceModel {
        return {
            id: catalog.Id,
            title: catalog.Name,
            subTitle: catalog.Description,
            isLeaf: true,
            isGroup: false,
            dragEnabled: true,
            model: catalog
        };
    }

    public refresh() {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;
        if(!this.view)
            return;

        if(this.refreshRequested)
            this.view.refresh();
            this.refreshRequested = false;
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [this.mimeType];
    }
    
    onItemBeginMove(model: ICustomerDiscountCatalogsDataSourceModel, dataTransfer: DataTransfer) {
        let catalog = model.model;

        let draggedCatalog : IDraggedCustomerDiscountCatalog = {
            CatalogId: catalog.Id,
            CustomerId: this.customerId,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };

        dataTransfer.setData("text/plain", catalog.Name);
        dataTransfer.setData(this.mimeType, JSON.stringify(draggedCatalog));
    }
    
    async onItemMoved(dataTransfer: DataTransfer, model: ICustomerDiscountCatalogsDataSourceModel, before: boolean) : Promise<void> {
        if(dataTransfer.types.indexOf(this.mimeType) >= 0 && model) {
            let droppedCatalog : IDraggedCustomerDiscountCatalog = JSON.parse(dataTransfer.getData(this.mimeType));
            this.moveCatalog(droppedCatalog, model, before);
        }
    }

    private moveCatalog(droppedCatalog : IDraggedCustomerDiscountCatalog, task : ICustomerDiscountCatalogsDataSourceModel, before : boolean) {
        if(this.userInfo.getCurrentCompanyGuid() != droppedCatalog.CompanyGuid)
            return;

        if(this.customerId != droppedCatalog.CustomerId)
            return;

        this.discountsCatalogsService.MoveCustomerDiscountCatalog({
            customerId: this.customerId, 
            catalogId: droppedCatalog.CatalogId, 
            destinationId: task.model.Id, 
            before: before, 
            isCustomer: this.isCustomer
        }).then(() => {
                if(this.view)
                    this.view.refreshImmediate();

                for(let listener of this.listeners) {
                    listener.onDataChanged();
                }
            });
    }
}

export class CustomerDiscountCatalogsDataSource extends BaseCustomerDiscountCatalogsDataSource {
    constructor() {
        super("application/prolife-customer-discount-catalog", true);
    }

    getTitle(currentModel: ICustomerDiscountCatalogsDataSourceModel): string {
        return "Scontistiche Clienti";
    }    

    async getData(currentModel: ICustomerDiscountCatalogsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICustomerDiscountCatalogsDataSourceModel[]> {
        let catalogs = await this.discountsCatalogsService.GetCustomerDiscountCatalogs(this.customerId, skip, count, true);
        return catalogs.map(this.createModelFor, this);
    }
    
    async getById(currentModel: ICustomerDiscountCatalogsDataSourceModel, ids: number[]): Promise<ICustomerDiscountCatalogsDataSourceModel[]> {
        let catalogs = await this.discountsCatalogsService.GetCustomerDiscountCatalogsByIds(ids);
        return catalogs.map(this.createModelFor, this);
    }
}

export class SupplierDiscountCatalogsDataSource extends BaseCustomerDiscountCatalogsDataSource {
    constructor() {
        super("application/prolife-supplier-discount-catalog", false);
    }

    getTitle(currentModel: ICustomerDiscountCatalogsDataSourceModel): string {
        return "Scontistiche Fornitori";
    }    

    async getData(currentModel: ICustomerDiscountCatalogsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICustomerDiscountCatalogsDataSourceModel[]> {
        let catalogs = await this.discountsCatalogsService.GetCustomerDiscountCatalogs(this.customerId, skip, count, false);
        return catalogs.map(this.createModelFor, this);
    }
    
    async getById(currentModel: ICustomerDiscountCatalogsDataSourceModel, ids: number[]): Promise<ICustomerDiscountCatalogsDataSourceModel[]> {
        let catalogs = await this.discountsCatalogsService.GetCustomerDiscountCatalogsByIds(ids);
        return catalogs.map(this.createModelFor, this);
    }
}