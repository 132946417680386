import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import {
    IWorkedHoursService,
    IWorkedOnJobOrder,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IJobOrderState } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class WorkedHoursByJobOrderTypeReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    Name: string = ProlifeSdk.TextResources.WorkedHours.WorkedOnJobOrders;
    templateName = "report-list-item";
    templateUrl = "reports/templates";
    private navigator: IReportsNavigator;
    viewModel: any;
    detailsTemplateName = "worked-hours-by-job-order-type-report";
    detailsTemplateUrl = "workedhours/templates/reports";

    public TextFilter: ko.Observable<string> = ko.observable("");
    public From: ko.Observable<Date> = ko.observable();
    public To: ko.Observable<Date> = ko.observable();

    private lastFrom: Date = null;
    private lastTo: Date = null;
    private lastText = "";
    private lastType: number = null;
    private lastState: number = null;

    public JobOrders: ko.ObservableArray<IWorkedOnJobOrder> = ko.observableArray([]);

    public Total: ko.Computed<number>;
    public TotalCost: ko.Computed<number>;
    public TotalRevenue: ko.Computed<number>;
    public TotalBilled: ko.Computed<number>;
    public TotalToBill: ko.Computed<number>;
    public TotalCostToBill: ko.Computed<number>;

    public State: ko.Observable<any> = ko.observable();
    public StateDescription: ko.Computed<string>;
    public States: IJobOrderState[];

    public Type: ko.Observable<any> = ko.observable();
    public TypeDescription: ko.Computed<string>;
    public Types: IJobOrderType[];

    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private statesManager: IJobOrderStateSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private typesManager: IJobOrderTypesSettingsManager;

    constructor(groupId: number) {
        super(groupId, 4);

        this.States = this.statesManager.getJobOrderStates();
        this.State(null);
        this.StateDescription = ko.computed(() => {
            return this.State() ? this.State().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });

        this.Types = this.typesManager.getJobOrderTypes();
        this.Type(null);
        this.TypeDescription = ko.computed(() => {
            return this.Type() ? this.Type().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });

        this.From.subscribe(this.NotifyFilterChange.bind(this));
        this.To.subscribe(this.NotifyFilterChange.bind(this));
        this.TextFilter.subscribe(this.NotifyFilterChange.bind(this));
        this.Type.subscribe(this.NotifyFilterChange.bind(this));
        this.State.subscribe(this.NotifyFilterChange.bind(this));

        this.TotalBilled = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.BilledHours;
            });
            return total;
        });

        this.TotalToBill = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.HoursToBill;
            });
            return total;
        });

        this.TotalCostToBill = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.CostToBill;
            });
            return total;
        });

        this.Total = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.WorkedHours;
            });
            return total;
        });

        this.TotalCost = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.Cost;
            });
            return total;
        });

        this.TotalRevenue = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((jo: IWorkedOnJobOrder) => {
                total += jo.Revenue;
            });
            return total;
        });
    }

    public ExportAsPdf(versionId: number) {
        const query = this.getQueryString(versionId);
        const url = "WorkedHours/WorkedOnJobOrdersReportPrint/GeneratePdf?" + query;

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private getQueryString(versionId: number): string {
        return (
            "versionId=" +
            versionId +
            "&text=" +
            this.lastText +
            "&from=" +
            encodeURIComponent(moment(this.lastFrom).format()) +
            "&to=" +
            encodeURIComponent(moment(this.lastTo).format()) +
            "&type=" +
            this.lastType +
            "&state=" +
            this.lastState
        );
    }

    public ExportAsExcel(versionId: number) {
        const query = this.getQueryString(versionId);
        const url = "WorkedHours/WorkedOnJobOrdersReportPrint/GenerateExcel?" + query;

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public RefreshReportData(): Promise<IWorkedOnJobOrder[]> {
        return this.workedHoursService
            .CalculateWorkedOnJobOrdersReport(
                this.TextFilter(),
                this.From(),
                this.To(),
                this.Type() ? this.Type().Id : null,
                this.State() ? this.State().IdJobOrderStateId : null
            )
            .then((jobOrdersHours: IWorkedOnJobOrder[]) => {
                this.JobOrders(jobOrdersHours);
                this.lastFrom = this.From();
                this.lastTo = this.To();
                this.lastText = this.TextFilter();
                this.lastType = this.Type() ? this.Type().Id : null;
                this.lastState = this.State() ? this.State().IdJobOrderStateId : null;
                return jobOrdersHours;
            });
    }

    initialize() {
        super.initialize();
        this.From(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.To(moment(new Date()).subtract("month", 1).endOf("month").toDate());
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}
