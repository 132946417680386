import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeSdk";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { IPrintTemplateSettingsManager } from "../PrintTemplateSettingsManager";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ReportDesignerDialog } from "../../../../Components/ReportDesigner/ReportDesignerDialog";
import { TextInput } from "../../../../Components/TextInput";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

const styleSheet = jss.createStyleSheet({
    printTemplateEditor: {
        position: "absolute",
        inset: "25px 20px 10px 15px",

        "& td > .form-group": {
            margin: "0 !important",
        },
    },
});
const { classes } = styleSheet.attach();

// eslint-disable-next-line @typescript-eslint/ban-types
type PrintTemplateEditorProps = {};

export function PrintTemplateEditor(props: PrintTemplateEditorProps) {
    const C = require("./PrintTemplateEditor")._PrintTemplateEditor as typeof _PrintTemplateEditor;
    return <C {...props} />;
}

type EditablePrintTemplate = {
    Id: number;
    Title: ko.Observable<string>;
    Template: ko.Observable<string>;
    InUse: boolean;
};

export class _PrintTemplateEditor {
    static defaultProps: Partial<PrintTemplateEditorProps> = {};

    @LazyImportSettingManager(nameof<IPrintTemplateSettingsManager>())
    private printTemplateSettingsManager: IPrintTemplateSettingsManager;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    printTemplates: ko.ObservableArray<EditablePrintTemplate> = ko.observableArray();

    constructor(private props: PrintTemplateEditorProps) {}

    componentDidMount() {
        this.reload();
    }

    async reload() {
        await this.printTemplateSettingsManager.load(); //Forzo l'aggiornamento del setting manager
        this.printTemplates(
            this.printTemplateSettingsManager.getPrintTemplates().map((pt) => ({
                Id: pt.id,
                Title: ko.observable(pt.title),
                Template: ko.observable(pt.template),
                InUse: pt.inUse,
            }))
        );
    }

    async createOrUpdatePrintTemplate() {
        const dialog = new ReportDesignerDialog({ configuration: null });
        const configuration = await dialog.show();

        this.printTemplates.push({
            Id: -1,
            Template: ko.observable(JSON.stringify(configuration)),
            Title: ko.observable("Nuova Configurazione"),
            InUse: false,
        });
    }

    async editPrintTemplate(printTemplate: EditablePrintTemplate) {
        const configuration = JSON.parse(printTemplate.Template());
        const dialog = new ReportDesignerDialog({ configuration });
        const newConfiguration = await dialog.show();
        printTemplate.Template(JSON.stringify(newConfiguration));
    }

    async removePrintTemplate(printTemplate: EditablePrintTemplate) {
        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.Invoices.PrintTemplateDeleteMessage,
                TextResources.Invoices.PrintTemplateDeleteMessageCancel,
                TextResources.Invoices.PrintTemplateDeleteMessageConfirm
            ))
        )
            return;

        this.printTemplates.remove(printTemplate);
    }

    async savePrintTemplates() {
        try {
            await this.printTemplateSettingsManager.savePrintTemplates(
                this.printTemplates().map((pt) => ({
                    id: pt.Id,
                    title: pt.Title(),
                    template: pt.Template(),
                }))
            );

            this.reload();
        } catch {
            this.infoToastService.Error(TextResources.Invoices.PrintTemplateSaveError);
        }
    }

    render() {
        const _pte = this;
        let pt: IDataSourceModel<number, EditablePrintTemplate>;

        return ComponentUtils.bindTo(
            <Layout.Grid className={classes.printTemplateEditor} columns={["1fr"]} rows={["80px", "1fr"]}>
                <Layout.Grid.Cell row={1} column={1} style={{ paddingTop: "30px" }}>
                    <h3 class="page-title">
                        <span>{TextResources.Invoices.PrintTemplatesSettingsManagerNameLong}</span>
                        <small
                            class="alert alert-danger"
                            style={{
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                textAlign: "center",
                                top: "-25px",
                                width: "500px",
                                color: "#a94442",
                            }}>
                            {TextResources.ProlifeSdk.SettingManagerReloadAlert}
                        </small>
                    </h3>
                    <div className="flex-fill text-right" style={{ marginRight: "10px" }}>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bind={{ asyncClick: _pte.savePrintTemplates.bind(_pte) }}>
                            <i className="fa fa-save"></i>&nbsp;{TextResources.Invoices.SavePrintTemplates}
                        </button>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <Table
                        compact
                        editable
                        dataSource={Table.defaultDataSource(this.printTemplates, (i) => ({
                            id: i.Id,
                            title: i.Title(),
                            model: i,
                        }))}
                        emptyResultMessage={TextResources.Invoices.PrintTemplateNoPrintTemplates}
                        rowAs="pt"
                        systemScrollable>
                        <Column title={TextResources.Invoices.PrintTemplateTitle}>
                            <ColumnBody>
                                {(item: ITableItem<EditablePrintTemplate>) => (
                                    <TextInput
                                        value={item.Data.model.Title}
                                        placeholder={TextResources.Invoices.PrintTemplateTitlePlaceholder}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column style={{ width: "200px" }} className="text-right">
                            <ColumnHeader style={{ verticalAlign: "top" }}>
                                <button
                                    className="btn btn-primary btn-circle btn-xs"
                                    data-bind={{ click: _pte.createOrUpdatePrintTemplate.bind(_pte) }}>
                                    <i className="fa fa-plus"></i>&nbsp;{TextResources.Invoices.PrintTemplateNew}
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                {() => (
                                    <>
                                        <button
                                            className="btn btn-primary btn-circle btn-xs"
                                            data-bind={{ asyncClick: _pte.editPrintTemplate.bind(_pte, pt.model) }}>
                                            <i className="fa fa-pencil"></i>&nbsp;
                                            {TextResources.Invoices.PrintTemplateEdit}
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn btn-danger btn-circle btn-xs"
                                            data-bind={{
                                                asyncClick: _pte.removePrintTemplate.bind(_pte, pt.model),
                                                disable: pt.model.InUse,
                                            }}>
                                            <i className="fa fa-trash-o"></i>&nbsp;
                                            {TextResources.Invoices.PrintTemplateRemove}
                                        </button>
                                    </>
                                )}
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "_pte"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PrintTemplateEditor);
}
