import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/07/2018
 * Time: 11:22
 * To change this template use File | Settings | File Templates.
 */

export class LabelsTypeaheadForMonthlyInvoicingWizard {
    init(element: any, valueAccessor: () => any): void {
        var labelField = valueAccessor()["labelField"];
        var sourceData = valueAccessor()["sourceData"];
        $(element).data("labelField", labelField());

        labelField.extend({ notify: 'always', rateLimit: 100 });

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            autoSelect: false,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source: sourceData,
            display: (json) => {
                var item = JSON.parse(json);
                return item.label;
            },
            limit: 50,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\"><span>";
                    element += item.label + "</span></div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            labelField(item.label);
            var label = labelField() == item.label ? item.label : labelField();
            $(element).data("labelField", label);
            return label;
        }).on("typeahead:active", (event) => {
            (<any>$(element)).typeahead("val", "****");
            (<any>$(element)).typeahead("val", labelField());
        });

        labelField.subscribe((label: string) => {
            (<any>$(element)).typeahead("val", label);
        });
    }
}

ko.bindingHandlers["labelsTypeaheadForMonthlyInvoicingWizard"] = new LabelsTypeaheadForMonthlyInvoicingWizard();