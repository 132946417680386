import * as ko from "knockout";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IPreferredSuppliersType {
    Id : number;
    FkManufacturer?: number;
    FkDiscountFamily?: number;
    FkStatisticFamily?: number;
    ArticleFilter?: string;
    FkSupplier : number;
}

export interface IPreferredSupplier {
    Id : number;
    FkManufacturer?: number;
    FkDiscountFamily?: number;
    FkStatisticFamily?: number;
    ArticleFilter?: string;
    Order?: number;
    FkSupplier : number;
}

export interface IPreferredSupplierSolution {
    Id : number;
    ArticleId?: number;
    Code?: string;
    Description?: string;
    FkSupplier : number;
    SupplierName?: string;
}

export interface IGetPreferredSuppliersRequest {
    fkManufacturer?: number;
    fkDiscountFamily?: number;
    fkStatisticFamily?: number;
    articleFilter?: string;
    fkSupplier?: number;
    skip?: number;
    count?: number;
}

export interface IPreferredSupplierForList {
    Id : number;
    FkManufacturer?: number;
    ManufacturerName?: string;
    FkDiscountFamily?: number;
    DiscountFamilyName?: string;
    FkStatisticFamily?: number;
    StatisticsFamilyName?: string;
    ArticleFilter?: string;
    Order?: number;
    FkSupplier : number;
    SupplierName?: string;
}

export interface IPreferredSuppliersService extends IService {
    CreateOrUpdatePreferredSupplier(preferredSupplier : IPreferredSuppliersType[] | null) : Promise<IPreferredSupplier>;
    MovePreferredSupplier(id : number | null, destinationId : number | null, before : boolean | null) : Promise<void>;
    RemovePreferredSupplier(id : number | null) : Promise<void>;
    ResolvePreferredSuppliers(textFilter : string | null, skip : number | null, count : number | null) : Promise<IPreferredSupplierSolution[]>;
    GetPreferredSuppliers(request : IGetPreferredSuppliersRequest) : Promise<IPreferredSupplierForList[]>;
    GetPreferredSuppliersByIds(ids : number[] | null) : Promise<IPreferredSupplierForList[]>;
    ApplyPreferredSuppliers(): Promise<void>;
}

@Service(nameof<IPreferredSuppliersService>())
class PreferredSuppliersService implements IPreferredSuppliersService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    CreateOrUpdatePreferredSupplier(preferredSupplier : IPreferredSuppliersType[] | null) : Promise<IPreferredSupplier> {
        return this.ajaxService.Post<IPreferredSupplier>("Warehouse-api/PreferredSuppliers", "CreateOrUpdatePreferredSupplier", {
            background: true,
            methodData: {
                preferredSupplier: preferredSupplier,
            }
        });
    }

    MovePreferredSupplier(id : number | null, destinationId : number | null, before : boolean | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/PreferredSuppliers", "MovePreferredSupplier", {
            background: true,
            methodData: {
                id: id,
                destinationId: destinationId,
                before: before,
            }
        });
    }

    RemovePreferredSupplier(id : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/PreferredSuppliers", "RemovePreferredSupplier", {
            background: true,
            methodData: {
                id: id,
            }
        });
    }

    ResolvePreferredSuppliers(textFilter : string | null, skip : number | null, count : number | null) : Promise<IPreferredSupplierSolution[]> {
        return this.ajaxService.Post<IPreferredSupplierSolution[]>("Warehouse-api/PreferredSuppliers", "ResolvePreferredSuppliers", {
            background: true,
            methodData: {
                textFilter: textFilter,
                skip: skip,
                count: count,
            }
        });
    }

    GetPreferredSuppliers(request : IGetPreferredSuppliersRequest) : Promise<IPreferredSupplierForList[]> {
        return this.ajaxService.Post<IPreferredSupplierForList[]>("Warehouse-api/PreferredSuppliers", "GetPreferredSuppliers", {
            background: true,
            methodData: request
        });
    }

    GetPreferredSuppliersByIds(ids: number[] | null): Promise<IPreferredSupplierForList[]> {
        return this.ajaxService.Post<IPreferredSupplierForList[]>("Warehouse-api/PreferredSuppliers", "GetPreferredSuppliersByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });
    }

    ApplyPreferredSuppliers(): Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/PreferredSuppliers", "ApplyPreferredSuppliers", {
            background: true,
            methodData: {
        	}
        });
    }
}

export default function Create() {
    return new PreferredSuppliersService();
}
