import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 31/07/2017
 * Time: 10:49
 * To change this template use File | Settings | File Templates.
 */

import { People } from "./People";
import { IPeople } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";

export class PeopleViewModel extends People {

    public Identifier: ko.Computed<string>;
    public FirstAddressLine: ko.Computed<string>;
    public SecondAddressLine: ko.Computed<string>;

    public ShowTelephone: ko.Computed<boolean>;
    public ShowAddress: ko.Computed<boolean>;
    public ShowDateOfBirth: ko.Computed<boolean>;
    public ShowEmail: ko.Computed<boolean>;
    public ShowPEC: ko.Computed<boolean>;
    public ShowCf: ko.Computed<boolean>;
    public ShowPIVA: ko.Computed<boolean>;
    public ShowGender: ko.Computed<boolean>;

    public ActualImportDate: ko.Computed<Date>;
    public ActualImporterId: ko.Computed<number>;

    constructor(people: IPeople) {
        super(people);

        this.ShowPIVA = ko.computed(() => {
            return !!this.PIVA();
        });

        this.ShowCf = ko.computed(() => {
            return !!this.FiscalCode();
        });

        this.Identifier = ko.computed(() => {
            return this.BusinessName() ? this.BusinessName() : this.Name() + ' ' + this.Surname();
        });

        this.FirstAddressLine = ko.computed(() => {
            return this.Address();
        });

        this.SecondAddressLine = ko.computed(() => {
            return (!this.CAP() ? '' : this.CAP())
                + (!this.City() ? '' : ' ' + this.City())
                + (!this.Municipality() ? '' : ', ' + this.Municipality())
                + (!this.Province() ? '' : ' (' + this.Province() + ')')
                + (!this.Country() ? '' : ' - ' + this.Country());
        });

        this.ShowTelephone = ko.computed(() => {
            return !!this.PhoneNumber();
        });

        this.ShowAddress = ko.computed(() => {
            return !!this.Address();
        });

        this.ShowDateOfBirth = ko.computed(() => {
            return !!this.DateOfBirth();
        });

        this.ShowEmail = ko.computed(() => {
            return !!this.Email();
        });

        this.ShowPEC = ko.computed(() => {
            return !!this.PEC();
        });

        this.ShowGender = ko.computed(() => {
            return !!this.Gender();
        });

        this.ActualImportDate = ko.computed(() => {
            return !this.ImportDate ? this.CreationDate : this.ImportDate;
        });

        this.ActualImporterId = ko.computed(() => {
            return !this.ImporterId ? this.CreatorId : this.ImporterId;
        });
    }
}