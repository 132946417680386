import * as React from "@abstraqt-dev/jsxknockout";
import { IDialogsService, IDialog } from "../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { DocumentRow } from "./DocumentRows";

export class LetterOfAttemptSelectionPopOver implements IDialog {
    
    public templateName: string;
    public templateUrl: string;
    public title: string = TextResources.Invoices.LetterOfAttemptSelectorTitle;
    public modal?: { close: (result?: any) => void; };
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public row: DocumentRow) {

    }

    public close(): void {
        this.modal.close();
    }
    
    public action(): void {
        this.modal.close();
    }

    public show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopover(element, this, "bottom");
    }

    renderTemplateNodes() {
        return [<div class="row" data-bind="with: row" style="width: 400px">
                    <div class="col-md-12">
                        <select2 params="DataSource: LettersOfAttemptsDataSource, Value: FKLetterOfAttempt, Listener: $data, AllowClear: true, Placeholder: 'Seleziona...'"></select2>
                    </div>
                </div>]
    }
}