export interface IArticleRequirement {
    ArticleId : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    RefsInfos?: IRefInfo[];
    WarehouseId : number;
    Warehouse : string;
    WarehouseStock? : number;
    DocumentId?: number;
    DocumentDate? : Date;
    DocumentNumber?: string;
    DocumentRegisterName?: string;
    CustomerId?: number;
    CustomerName?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    Amount : number;
    LastInspectionDate?: Date;

    SourceWarehouseId? : number;
    SourceWarehouseName? : string;
    SourceWarehouseStock? : number;
}

export interface IRefInfo {
    DocumentId: number;
    RefId: number;
    RefAmount: number;
    DocumentDate: Date;
    DocumentLabel: string;
    DocumentNumber: string;
    DocumentType: string;
    DocumentRegisterId: number;
    DocumentRegisterName: string;
}

export class ArticleRequirements {
    public WarehouseId: number;
    public Warehouse: string;
    public CustomerId: number;
    public Customer: string;
    public JobOrderId: number;
    public JobOrder: string;
    public LastInspectionDate: Date;

    public RequirementsInfo: ko.ObservableArray<ArticleRequirement> = ko.observableArray([]);

    public ShowRequirementsInfo: ko.Observable<boolean> = ko.observable(false);
    public SelectedRequirementsInfo: ko.Computed<ArticleRequirement[]>;
    public SelectedRequirementsInfoNumber: ko.Computed<number>;
    public Selected : ko.Computed<boolean>;

    constructor(article: IArticleRequirement) {
        this.WarehouseId = article.WarehouseId;
        this.Warehouse = article.Warehouse;
        this.CustomerId = article.CustomerId;
        this.Customer = article.CustomerName;
        this.JobOrderId = article.JobOrderId;
        this.JobOrder = article.JobOrderName;
        this.LastInspectionDate = article.LastInspectionDate;

        this.SelectedRequirementsInfo = ko.computed(() => {
            return this.RequirementsInfo().filter(w => w.Selected());
        });

        this.SelectedRequirementsInfoNumber = ko.computed(() => {
            return this.SelectedRequirementsInfo().length;
        });

        this.Selected = ko.computed({
            read: () => {
                let requirements = this.RequirementsInfo();
                let selectedRequirements = this.SelectedRequirementsInfo();

                if (selectedRequirements.length === 0)
                    return false;

                if (selectedRequirements.length !== requirements.length && selectedRequirements.length > 0)
                    return null;

                return true;
            },
            write: (value) => {
                let requirements = this.RequirementsInfo();
                for (let requirement of requirements)
                    requirement.Selected(value);
            }
        });
    }

    public addOrUpdateRequirement(articleRequirement: IArticleRequirement) {
        let requirements = this.RequirementsInfo();

        for (let req of requirements) {
            if (req.ArticleId === articleRequirement.ArticleId && req.DocumentId === articleRequirement.DocumentId) {
                req.merge(articleRequirement);
                return;
            }
        }

        this.RequirementsInfo.push(new ArticleRequirement(articleRequirement));
    }
}

export class ArticleRequirement {
    public ArticleId: number;
    public Article: string;
    public ArticleCode: string;
    public ArticleEanCode: string;
    public ArticleMefCode: string;
    
    public RequiredAmount: number;
    
    public DocumentId: number;
    public DocumentNumber: string;
    public DocumentDate: Date;
    public DocumentRegisterName: string;
    
    public Selected : ko.Observable<boolean> = ko.observable(false);

    private refsInfos: IRefInfo[] = [];

    constructor(private articleRequirement: IArticleRequirement) {
        this.ArticleId = articleRequirement.ArticleId;
        this.Article = articleRequirement.ArticleDescription;
        this.ArticleCode = articleRequirement.ArticleCode;
        this.ArticleEanCode = articleRequirement.EanCode;
        this.ArticleMefCode = articleRequirement.MefCode;
        this.RequiredAmount = articleRequirement.Amount;
        
        this.DocumentId = articleRequirement.DocumentId;
        this.DocumentNumber = articleRequirement.DocumentNumber;
        this.DocumentDate = articleRequirement.DocumentDate;
        this.DocumentRegisterName = articleRequirement.DocumentRegisterName;

        if (!!articleRequirement.RefsInfos)
            this.refsInfos = articleRequirement.RefsInfos.slice();
    }

    public merge(articleRequirement: IArticleRequirement): void {
        this.RequiredAmount += articleRequirement.Amount;
        this.refsInfos = this.refsInfos.concat(articleRequirement.RefsInfos.slice().filter(r => !!r));
    }

    public getData(): IArticleRequirement {
        let data = Object.assign({}, this.articleRequirement);
        data.Amount = this.RequiredAmount;
        data.RefsInfos = this.refsInfos;
        return data;
    }
}