import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { RefConverterBase, IRefDocumentRowWithUOM, IDocumentInfoForInlineRefProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IJobOrder, IJobOrderCallRightTypesPrice } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";

export class CallRightRefConverter extends RefConverterBase
{
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsManager : ICallRightTypesSettingsManager;

    constructor(activationCode : string, docInfo : IDocumentInfoForInlineRefProvider)
    {
        super(activationCode, ProlifeSdk.EstimatedCallRightTypeCode, docInfo);
    }

    public getReference(rowId : number, entity : IEntityRefKey, amount : number) : Promise<IRefDocumentRowWithUOM>
    {
        var def = new Deferred<IRefDocumentRowWithUOM>();

        var callRightType : ICallRightType = this.callRightsManager.getById(entity.EntityKeyId);

        var jobOrderId : number = this.DocumentInfo.JobOrderId && this.DocumentInfo.JobOrderId() > 0 ? this.DocumentInfo.JobOrderId() : -1;

        if(jobOrderId <= 0)
            return def.resolve(this.PrepareReference(rowId, entity, amount, callRightType.Price)).promise();

        this.jobOrderService.get(jobOrderId).then((j : IJobOrder) => {
            var crMatches : IJobOrderCallRightTypesPrice[] = j ? j.CallRightTypesPrices.filter((r : IJobOrderCallRightTypesPrice) => { return r.CallRightId == entity.EntityKeyId; }) : [];
            var crPrice = crMatches.length > 0 ? crMatches[0].Price : callRightType.Price;
            def.resolve(this.PrepareReference(rowId, entity, amount, crPrice));
        }).catch(() => { def.reject([]); });

        return def.promise();
    }

    private PrepareReference(rowId : number, entity : IEntityRefKey, amount : number, price : number) : IRefDocumentRowWithUOM
    {
        var ref = this.GetEmptyReference(rowId, entity, amount);
        ref.UnitPrice = price;
        ref.NetUnitPrice = price;
        ref.NetPrice = price * ref.Amount;
        return ref;
    }
}
