import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { ICustomersGroupsService, ICustomerGroup } from "../Customers/CustomersGroupsService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { ICustomerForList, ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { BaseDataSource } from "./BaseDataSource";

export interface ICustomersDataSourceModel extends IDataSourceModel<number, ICustomerForList | ICustomerGroup> {
    action?: {
        title: string;
        icon: string;
        action: (model: ICustomersDataSourceModel) => Promise<void>;
    };
}

export type ICustomersDataSource = IDataSource;

export class CustomersDataSource extends BaseDataSource<ICustomersDataSourceModel> implements ICustomersDataSource {
    view: IDataSourceView;

    private returnGroupedData = true;
    private getCustomers = true;
    private getSuppliers = true;

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;

    @LazyImport(nameof<ICustomersGroupsService>())
    private customersGroupsService: ICustomersGroupsService;

    private viewModelCreationCallback: (model: ICustomersDataSourceModel) => ICustomersDataSourceModel;

    getTitle(currentModel: ICustomersDataSourceModel): string {
        return ProlifeSdk.TextResources.Customers.Contacts;
    }

    isGroupedData(currentModel: ICustomersDataSourceModel, textFilter: string): boolean {
        return this.returnGroupedData && !currentModel;
    }

    areEqual(a: ICustomersDataSourceModel, b: ICustomersDataSourceModel): boolean {
        return !!a && !!b && a.id == b.id;
    }

    async getData(
        currentModel: ICustomersDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<ICustomersDataSourceModel[]> {
        if (this.returnGroupedData) {
            if (!currentModel) {
                if (skip > 0) return [];

                const noGroupArray: ICustomerGroup[] = [
                    {
                        Id: null,
                        IsDefault: false,
                        Name: "Nessun Gruppo",
                    },
                ];
                const allGroups = await this.customersGroupsService.GetCustomerGroups(null);
                return noGroupArray.concat(allGroups).map(this.createGroupModelFor, this);
            }

            const customers = await this.customersService.GetCustomersForListByGroup({
                groupId: currentModel.id,
                textFilter: textFilter,
                skip: skip,
                count: count,
                isCustomer: true,
            });
            return customers.map(this.createModelFor, this);
        } else {
            const customers = await this.customersService.GetCustomersForList(
                textFilter,
                skip,
                count,
                this.getCustomers,
                this.getSuppliers
            );
            return customers.map(this.createModelFor, this);
        }
    }

    async getById(currentModel: ICustomersDataSourceModel, ids: number[]): Promise<ICustomersDataSourceModel[]> {
        if (this.returnGroupedData) {
            if (currentModel == null) {
                return [];
            }

            const promises: Promise<ICustomerForList>[] = [];
            for (const id of ids) {
                promises.push(this.customersService.GetCustomerForList(id));
            }

            return Promise.all(promises).then((customers: ICustomerForList[]) => {
                return customers.map(this.createModelFor, this);
            });
        } else {
            const promises: Promise<ICustomerForList>[] = [];
            for (const id of ids) {
                promises.push(this.customersService.GetCustomerForList(id));
            }

            return Promise.all(promises).then((customers: ICustomerForList[]) => {
                return customers.map(this.createModelFor, this);
            });
        }
    }

    private createGroupModelFor(customer: ICustomerGroup): ICustomersDataSourceModel {
        let model: ICustomersDataSourceModel = {
            id: customer.Id,
            title: customer.Name,
            isLeaf: false,
            isGroup: true,
            model: customer,
        };

        if (this.viewModelCreationCallback) model = this.viewModelCreationCallback(model);

        return model;
    }

    private createModelFor(customer: ICustomerForList): ICustomersDataSourceModel {
        let model: ICustomersDataSourceModel = {
            id: customer.Id,
            title: customer.Name,
            subTitle: customer.NameForGroup,
            isLeaf: true,
            isGroup: false,
            icon: {
                icon: "icon-user",
            },
            model: customer,
        };

        if (this.viewModelCreationCallback) model = this.viewModelCreationCallback(model);

        return model;
    }

    setViewModelCreationCallback(callback: (model: ICustomersDataSourceModel) => ICustomersDataSourceModel) {
        this.viewModelCreationCallback = callback;
    }

    setView(view: IDataSourceView): void {
        this.view = view;
    }

    setReturnGroupedData(value: boolean): void {
        this.returnGroupedData = value;
    }

    setGetCustomers(value: boolean): void {
        this.getCustomers = value;
    }

    setGetSuppliers(value: boolean): void {
        this.getSuppliers = value;
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
