import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { JobOrdersDataSource } from "../../../DataSources/JobOrdersDataSource";
import { IJobOrderForTaskBoard } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { Layout } from "../../../Components/Layouts";
import { Portlet, PortletHeader, PortletBody } from "../../../Components/Portlet";
import { SelectMultiple } from "../../../Components/SelectMultiple";
import { TextInput } from "../../../Components/TextInput";
import { Statement } from "./Statement";

const styleSheet = jss.createStyleSheet({
    statementList: {
        "& > div": {
            padding: "15px",
        }
    },
    newStatementLayout: {
        paddingRight: "15px",
        marginTop: "30px",
    }
});
const { classes } = styleSheet.attach();

type StatementEditorProps = {
    statement: Statement;

    onStatementSaved: (statement: Statement) => void;
    onClose: () => void;
}

export function StatementEditor(props: StatementEditorProps) {
    const C = require("./StatementEditor")._StatementEditor as typeof _StatementEditor;
    return <C {...props} />;
}

export class _StatementEditor {
    static defaultProps: Partial<StatementEditorProps> = {
    }

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    jobOrders: ko.ObservableArray<IJobOrderForTaskBoard> = ko.observableArray();
    jobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    jobOrderIds: ko.ObservableArray<number> = ko.observableArray();

    Name: ko.Observable<string> = ko.observable();
    Description: ko.Observable<string> = ko.observable();

    constructor(private props : StatementEditorProps) {
        this.jobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.jobOrdersDataSource.setViewFilters(true, true, true);
    }

    private onStatementSaved() {

    }
    
    render() {
        return  <Layout.Grid className={classes.statementList} columns={["1fr"]} rows={["1fr"]} >
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet collapsible={false}>
                            <PortletHeader>
                                <Portlet.Header.Default title={"Inserisci scheda o edita scheda"} />
                            </PortletHeader>
                            <Portlet.Actions>
                                <button className="btn btn-circle btn-success " onClick={() => this.onStatementSaved()}>
                                    <i class="fa fa-floppy-o"></i>&nbsp;Salva
                                </button>
                                <button className="btn btn-circle btn-warning " onClick={() => this.props.onClose()}>
                                    <i class="fa fa-floppy-o"></i>&nbsp;Annulla
                                </button>
                            </Portlet.Actions>
                            <PortletBody>
                                {() => <Layout.Grid className={classes.newStatementLayout} columns={["1fr"]} rows={["auto", "auto", "auto"]} >
                                    <Layout.Grid.Cell column={1} row={1} >
                                        <TextInput className="flex-1" label={"Nome Scheda: "} placeholder={"Inserisci il nome"} value={this.Name} />
                                    </Layout.Grid.Cell>
                                    <Layout.Grid.Cell column={1} row={2} >
                                        <TextInput className="flex-1" label={"Descrizione: "} placeholder={"Inserisci descrizione"} value={this.Description} />
                                    </Layout.Grid.Cell>
                                    <Layout.Grid.Cell column={1} row={3} >
                                        <SelectMultiple.WithLabel 
                                            className="flex-1" 
                                            label="Selezionare la commessa" 
                                            dataSource={this.jobOrdersDataSource} 
                                            value={this.jobOrderIds} 
                                            onSelect={(item) => this.jobOrders.push(item.model)} 
                                            onDeselect={(item) => {
                                                const jobOrderToRemove = this.jobOrders().firstOrDefault(j => j.Id === item.model.Id);
                                                this.jobOrders.remove(jobOrderToRemove);
                                            }} />
                                    </Layout.Grid.Cell>
                                </Layout.Grid>}
                            </PortletBody>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={1}>
                        {/*  <FunctionPointComputationRequirementList requirements={this.props.infrastructure().Requirements} onRequirementSelected={this.onRequirementSelected} onNew={this.onNew} /> */}
                    </Layout.Grid.Cell>
                </Layout.Grid>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(StatementEditor);
}