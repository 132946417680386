import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderGeneralSettingsEditingViewModel } from "./ui/JobOrderGeneralSettingsEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IJobOrderGeneralSettingsManager, IJobOrderGeneralSettings } from "../../interfaces/settings/IJobOrderGeneralSettingsManager";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class JobOrderGeneralSettingsManager implements IJobOrderGeneralSettingsManager {
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    private cache : IJobOrderGeneralSettings;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Settings.CompanySettingsGroup);
    }
    
    load(): Promise<IJobOrderGeneralSettings> {
        return this.ajaxService.Get<IJobOrderGeneralSettings>("JobOrder-api", "JobOrderGeneralSettings", {})
            .then((data : IJobOrderGeneralSettings) => {
                this.cache = data;
                return data;
            });
    }

    getName():string {
        return ProlifeSdk.JobOrderGeneralSettings;
    }

    getLabel():string {
        return ProlifeSdk.TextResources.JobOrder.GeneralSettings;
    }

    hasEditingUI():boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) || false;
    }

    getEditingUI():IView {
        return {
            templateName: "jobOrderGeneralSettings",
            templateUrl: "jobOrder/templates/settings",
            viewModel: new JobOrderGeneralSettingsEditingViewModel(this)
        };
    }

    getShowJobOrderIdOnTitles(): boolean {
        return this.cache.showJobOrderIdOnTitles;
    }

    setShowJobOrderIdOnTitles(enabled: boolean): Promise<void> {
        const newValue : IJobOrderGeneralSettings = <IJobOrderGeneralSettings> $.extend({}, this.cache);
        newValue.showJobOrderIdOnTitles = enabled;

        return this.ajaxService.Post<void>("JobOrder-api", "JobOrderGeneralSettings", { methodData: newValue })
            .then(() => {
                this.cache = newValue;
            });
    }

    getShowTaskIdOnTitles(): boolean {
        return this.cache.showTaskIdOnTitles;
    }

    setShowTaskIdOnTitles(enabled: boolean): Promise<void> {
        const newValue : IJobOrderGeneralSettings = <IJobOrderGeneralSettings> $.extend({}, this.cache);
        newValue.showTaskIdOnTitles = enabled;

        return this.ajaxService.Post<void>("JobOrder-api", "JobOrderGeneralSettings", { methodData: newValue })
            .then(() => {
                this.cache = newValue;
            });
    }

    getCartsDefaultIgnoreBacklog() : boolean {
        return this.cache.cartsDefaultIgnoreBacklog;
    }

    getActivitiesProgressAmountMode(): number {
        return this.cache.activitiesProgressAmountMode;
    }

    getTaskboardStartMode(): number {
        return this.cache.taskboardStartMode;
    }

    getPlanningTaskboardMenuStartMode(): number {
        return this.cache.planningTaskboardMenuStartMode;
    }

    getCartWorkableHoursCalculationMode(): string {
        return this.cache.cartWorkableHoursCalculationMode;
    }

    getShowHiddenHobOrdersAndResourcesOnWorkedHours(): boolean {
        return this.cache.showHiddenJobOrdersAndResourcesOnWorkedHours;
    }

    getShowCartEditingFormOnActivityAllocation(): boolean {
        return this.cache.showCartEditingFormOnActivityAllocation;
    }

    getViewAllOnWorkedHoursEditor(): boolean {
        return this.cache.viewAllOnWorkedHoursEditor;
    }
    
    getEnableFlexibility(): boolean {
        return this.cache.enableFlexibility;
    }

    getDefaultFlexibilityRole(): number {
        return this.cache.defaultFlexibilityRole;
    }

    getDefaultFlexibilityJobOrder(): number {
        return this.cache.defaultFlexibilityJobOrder;
    }

    getDefaultFlexibilityWorkTimeCategory(): number {
        return this.cache.defaultFlexibilityWorkTimeCategory;
    }

    getIsDefaultFlexibilityRoleReadonly(): boolean {
        return this.cache.isDefaultFlexibilityRoleReadonly;
    }

    getShowJobOrderMetadataOnAutoCartNameCreation(): boolean {
        return this.cache.showJobOrderMetadataOnAutoCartNameCreation;
    }
    
    getShowJobOrderCodeOnAutoCartNameCreation(): boolean {
        return this.cache.showJobOrderCodeOnAutoCartNameCreation;
    }

    getShowWorkflowCodeOnAutoCartNameCreation(): boolean {
        return this.cache.showWorkflowCodeOnAutoCartNameCreation;
    }

    getShowWarehouseCodeOnTitle(): boolean {
        return this.cache.showWarehouseCodeOnTitle;
    }

    getShowWorkflowCodeOnTitle(): boolean {
        return this.cache.showWorkflowCodeOnTitle;
    }

    getSetFlagIsApprovedWorkedHours(): boolean {
        return this.cache.setFlagIsApprovedWorkedHours;
    }

    getBillableFlagOnWorkedHours(): boolean {
        return this.cache.billableFlagOnWorkedHours;
    }

    getShowAlertWhenNegativeStockOnDocumentSave(): boolean {
        return this.cache.showAlertWhenNegativeStockOnDocumentSave;
    }
 
    saveSettings(configuration: IJobOrderGeneralSettings) : Promise<void>
    {
        return this.ajaxService.Post<void>("JobOrder-api", "JobOrderGeneralSettings", { methodData: configuration })
            .then(() => {
                this.cache = configuration;
            });
    }
}