import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { Delay } from "../Decorators/Delay";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { Param, ComponentParam, ComponentUtils, PropsWithChildren } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { IDataSource, IDataSourceListener, IDataSourceView, IDataSourceModel } from "../DataSources/IDataSource";

const { classes } = jss.createStyleSheet({
    "prolife-typeahead": {
        "&.readonly": {
            "cursor": "not-allowed",
            "background-color": "#eeeeee !important"
        },

        "&.tt-input:disabled": {
            "background-color": "#f4f4f4 !important"
        }
    }

}).attach();

const attributes = {
    Placeholder: "placeholder",
    ReadOnly: "readOnly",

    Label: "label",
    Value: "value",
    Bindings: "bindings",
    Simple: "simple",

    DataSource: "dataSource",
    Listener: "listener",
    InjectTo: "injectTo",

    MaxLength: "maxLength"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "typeahead": {
                params?: {
                    Placeholder: string;
                    ReadOnly: string;
                    Label: string;
                    Value: string;
                    Bindings: string;
                    Simple: string;
                    DataSource: string;
                    Listener: string;
                    InjectTo: string;
                    MaxLength: string;
                } | string;
                
                "placeholder"?: string | (() => string);
                "readOnly"?: boolean | (() => string);
                "label"?: string | (() => string);
                "value"?: string | (() => string);
                "bindings"?: string | (() => string);
                "simple"?: boolean | (() => string);
                "dataSource"?: (() => string);
                "listener"?: (() => string);
                "injectTo"?: (() => string);
                "maxLength"?: number;
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ITypeaheadComponentParameters {
    Placeholder?: Param<string>;
    ReadOnly?: Param<boolean>;

    Label: Param<string>;
    Value: Param<any>;
    Bindings?: string;
    Simple?: Param<boolean>;

    DataSource: Param<IDataSource>;
    Listener?: Param<IDataSourceListener | IDataSourceListener[]>;
    InjectTo?: ko.Observable<ITypeaheadComponent>;

    MaxLength?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypeaheadComponent {}

class TypeaheadComponent implements IDataSourceView {
    Placeholder : ComponentParam<string>;
    Label : ComponentParam<string>;
    ReadOnly : ComponentParam<boolean>;
    Value : ComponentParam<any>;
    
    private dataSource : IDataSource;
    private listeners : IDataSourceListener[];
    private pageSize  = 50;

    constructor(params : ITypeaheadComponentParameters) {
        this.Placeholder = ComponentUtils.parseParameter(params.Placeholder, "");
        this.Value = ComponentUtils.parseParameter(params.Value, null);
        this.Label = ComponentUtils.parseParameter(params.Label, null);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        
        this.dataSource = ComponentUtils.parseParameter(params.DataSource, null)();
        const listeners = ComponentUtils.parseParameter(params.Listener, null)()
        this.listeners = Array.isArray(listeners) ? listeners : (listeners ? [listeners] : []);

        if(params.InjectTo) {
            params.InjectTo(this);
        }

        if(this.dataSource)
            this.dataSource.setView(this);
    }

    getPageSize(): number {
        return this.pageSize;
    }

    setPageSize(size: number): void {
        this.pageSize = size;
    }

    refresh(keepSelection?: boolean): void {
        
    }

    refreshImmediate(keepSelection?: boolean): void {
        
    }

    pushState(): void {
        
    }

    popState(): void {
        
    }

    async select(...models: IDataSourceModel<string | number, any, string | number, any>[]): Promise<void> {
        if(models.length > 1)
            throw new Error("TypeaheadComponent non supporta la selezione multipla");
        
        const realModel = await this.dataSource.getById(null, [models[0].id]);
        if(realModel.length == 0)
            return;
        this.onSelectionChange(realModel[0]);
    }

    navigateTo(...history: IDataSourceModel<string | number, any, string | number, any>[]): void {
        
    }

    @Delay()
    public getData(query : string, callback : (data : any[]) => void) : void {
        this.dataSource.getData(null, query, 0, 50)
            .then((results : IDataSourceModel[]) => {
                callback(results);
            }, () => {
                callback([]);
            });
    }

    public getTemplate(data : IDataSourceModel) : string {
        let template = `<div style="border-bottom: 1px solid #DDDDDD">
                            <table style="width: 100%; min-height: 32px">
                                <tbody>
                                    <tr>`;
        if(data.icon)
            template +=                 `<td rowspan="2" style="padding-right: 5px; vertical-align: top">
                                            <span style="background-color: ${data.icon.background}; color: ${data.icon.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px;" class="${data.icon.icon}"></span>
                                        </td>`;
        
        template +=                     `<td>${data.title}</td>
                                    </tr>`;
        if(data.subTitle)
            template +=             `<tr>
                                        <td><small class="text-muted">${data.subTitle}</span></td>
                                    </tr>`;
        template +=             `</tbody>
                            </table>
                        </div>`;
        return template;        
    }

    public getName(data : IDataSourceModel) : string {
        return data.title;
    }

    public onSelectionChange(model : IDataSourceModel) : void {
        this.Value(model.title);

        for (const listener of this.listeners) {
            listener.onItemSelected(this.dataSource, model);
        }
    }
}

type TypeaheadProps = PropsWithChildren<{
    dataSource: IDataSource;
    label?: string;
    value: ko.Observable<string>;
    listener?: IDataSourceListener;
    maxLength?: number;
    placeholder?: string;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean>;
    simple?: boolean;
}>;

export function Typeahead(props: TypeaheadProps) {
    const C = require("./TypeaheadComponent")._Typeahead as typeof _Typeahead;
    return <C {...props} />;
}
export class _Typeahead {
    private vm : TypeaheadComponent;

    constructor(private props: TypeaheadProps) {
        this.vm = new TypeaheadComponent({
            DataSource: props.dataSource,
            Label: props.label,
            Value: props.value,
            Listener: props.listener,
            MaxLength: props.maxLength,
            Placeholder: props.placeholder,
            ReadOnly: props.readonly,
            Simple: props.simple
        });
    }

    private renderInput() {
        const tac = this.vm;
        const className = ComponentUtils.classNames("form-control", classes["prolife-typeahead"]);
        return <input autoComplete="off" maxLength={this.props.maxLength} className={className} type="text" data-bind={{ value: tac.Value, typeahead: { source: tac.getData.bind(tac), display: tac.getName.bind(tac), suggestion: tac.getTemplate.bind(tac), onSelectionChange: tac.onSelectionChange.bind(tac) }, disable: tac.ReadOnly, attr: { readonly: tac.ReadOnly, placeholder: tac.Placeholder } }} />
    }

    private wrapInFormGroup(input: React.ReactElement) {
        const tac = this.vm;

        return  <div class="form-group">
                    <label class="control-label" data-bind={{ text: tac.Label, visible: !!tac.Label() }}></label>
                    {Array.isArray(this.props.children) && this.props.children.length > 0 ?
                        <div class="input-group" style="table-layout: fixed; width: 100%">
                            {input}
                            <span style="width: 42px" class="input-group-btn">
                                {this.props.children}
                            </span>
                        </div>
                    : input}
                </div>
    }

    render() {
        if(this.props.simple)
            return ComponentUtils.bindTo(this.renderInput(), this.vm, 'tac');

        return ComponentUtils.bindTo(this.wrapInFormGroup(this.renderInput()), this.vm, 'tac');
    }
}

ko.components.register('typeahead', {
    viewModel: {
        createViewModel: (params : ITypeaheadComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            const vm = new TypeaheadComponent(params);

            let templateFragment : string;

            if(params.Simple) {
                templateFragment = `<input autoComplete="off" class="form-control ${classes["prolife-typeahead"]}" type="text" data-bind="value: Value, typeahead: { source: $data.getData.bind($data), display: $data.getName.bind($data), suggestion: $data.getTemplate.bind($data), onSelectionChange: $data.onSelectionChange.bind($data) }, disable: ReadOnly, attr: { readonly: ReadOnly, placeholder: Placeholder${params.MaxLength ? ', maxlength: ' + params.MaxLength : ''} }${params.Bindings ? ', ' + params.Bindings : ""}">`;
            } else {            
                if(componentInfo.templateNodes.length > 0) {
                    templateFragment =  '<div class="form-group">' + 
                                            '<label class="control-label" data-bind="text: Label, visible: Label"></label>' + 
                                            '<div class="input-group" style="table-layout: fixed; width: 100%">' +
                                                `<input autoComplete="off" class="form-control ${classes["prolife-typeahead"]}" type="text" data-bind="value: Value, typeahead: { source: $data.getData.bind($data), display: $data.getName.bind($data), suggestion: $data.getTemplate.bind($data), onSelectionChange: $data.onSelectionChange.bind($data) }, disable: ReadOnly, attr: { readonly: ReadOnly, placeholder: Placeholder${params.MaxLength ? ', maxlength: ' + params.MaxLength : ''} }${params.Bindings ? ', ' + params.Bindings : ""}">` +
                                                '<span style="width: 42px" class="input-group-btn" data-bind="template: { nodes: $componentTemplateNodes, data: $parent }">' + 
                                                '</span>' +
                                            '</div>' +
                                        '</div>'
                } else {
                    templateFragment =  '<div class="form-group">' + 
                                            '<label class="control-label" data-bind="text: Label, visible: Label"></label>' + 
                                            `<input autoComplete="off" class="form-control ${classes["prolife-typeahead"]}" type="text" data-bind="value: Value, typeahead: { source: $data.getData.bind($data), display: $data.getName.bind($data), suggestion: $data.getTemplate.bind($data), onSelectionChange: $data.onSelectionChange.bind($data) }, disable: ReadOnly, attr: { readonly: ReadOnly, placeholder: Placeholder${params.MaxLength ? ', maxlength: ' + params.MaxLength : ''} }${params.Bindings ? ', ' + params.Bindings : ""}">` +
                                        '</div>'
                }
            }
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, ko.utils.parseHtmlFragment(templateFragment));
            
            return vm;
        }
    },
    template: []
});