import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ISelect2Provider } from "../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";

export class ContactsReferencesBase implements ISelect2Provider
{
    @LazyImport(nameof<ICustomersService>())
    public customerService : ICustomersService;
    public Values : ko.Observable<string> = ko.observable();

    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor() {}

    public OpenContact(contact)
    {
        var url = this.customerService.GetCustomerUrl(contact.contactId);
        window.open(url, "_blank");
    }

    public EscapeMarkup(markup) : string
    {
        if(markup.indexOf("escape-markup='true'") > -1)
            return markup;

        var replace_map = {
            '\\': '&#92;',
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&apos;',
            "/": '&#47;'
        };

        return (<any>$.fn.select2.defaults).escapeMarkup(markup);
    }

    private FormatSelection(data, container)
    {
        var url = this.customerService.GetCustomerUrl(data.id);
        return "<a escape-markup='true' href='" + url + "' target='_blank'>" + data.text + "</a>";
    }

    public Search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() =>
        {
            this.SearchContactsInternal(query);
        }, 500);
    }

    public SearchContactsInternal(query : any)
    {
        //Implementare nelle derivate...
    }

    public Init(element: JQuery, callback: (data: any) => void) : void
    {
        var values = <string> $(element).val();
        this.InitializeContactsInternal(values, callback);
    }

    protected InitializeContactsInternal(ids : string, callback: (data: any) => void) : void
    {
        //Implementare nelle derivate...
    }
}