import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 13/03/2018
 * Time: 13:03
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { Moment } from "moment";
import * as moment from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFullCalendar, IFullCalendarEvent, IFullCalendarViewObject, IFullCalendarEventsSource, IFullCalendarHeader, IFullCalendarViewMap, IFullCalendarSelectedInterval, IFullCalendarEventsDragObserver } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IEventHandler } from "../../../ProlifeSdk/interfaces/IEventHandler";
import { IFestivitiesService, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IAgendaTimetableViewModel } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { Deferred } from "../../../Core/Deferred";

export class FestivitiesCalendar implements IFullCalendar {
    public SelectedEvent: ko.Observable<IFullCalendarEvent> = ko.observable();
    public SelectedInterval: ko.Observable<IFullCalendarSelectedInterval> = ko.observable();

    public GetActualView: ko.Computed<IFullCalendarViewObject>;

    public OnEventAdded: IEventHandler;
    public OnEventUpdated: IEventHandler;
    public OnEventDeleted: IEventHandler;
    public OnEventsRefresh: IEventHandler;
    public OnChengeViewRequest: IEventHandler;
    public OnSetHeaderRequest: IEventHandler;
    public OnSetViewsOptionRequest: IEventHandler;
    public OnGoToDateRequest: IEventHandler;
    public OnSelectRequest: IEventHandler;
    public OnUnselectRequest: IEventHandler;
    public OnSetBusinessHoursRequest: IEventHandler;
    public OnSetSlotDurationRequest: IEventHandler;

    private ActualView: ko.Observable<IFullCalendarViewObject> = ko.observable();

    private festivitiesService: IFestivitiesService;
    private infoToastService: IInfoToastService;
    private dialogsService: IDialogsService;

    private static _self: IFullCalendar;

    constructor(private serviceLocator: IServiceLocator, private agenda: IFullCalendarEventsSource) {
        this.festivitiesService = <IFestivitiesService> this.serviceLocator.findService(ProlifeSdk.FestivitiesServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.OnEventAdded = new EventHandler();
        this.OnEventUpdated = new EventHandler();
        this.OnEventDeleted = new EventHandler();
        this.OnEventsRefresh = new EventHandler();
        this.OnChengeViewRequest = new EventHandler();
        this.OnSetHeaderRequest = new EventHandler();
        this.OnSetViewsOptionRequest = new EventHandler();
        this.OnGoToDateRequest = new EventHandler();
        this.OnSelectRequest = new EventHandler();
        this.OnUnselectRequest = new EventHandler();
        this.OnSetBusinessHoursRequest = new EventHandler();
        this.OnSetSlotDurationRequest = new EventHandler();

        this.GetActualView = ko.computed(() => {
            return this.ActualView();
        });

        FestivitiesCalendar._self = this;
    }

    public GetEvents(): IFestivity[] { return undefined; }

    public SelectEvent(event: IFestivity) {
        this.SelectedEvent(event);
    }

    public RenderEvent(newEvent: IFestivity) {
        this.OnEventAdded.Call(newEvent);
    }

    public UpdateEvent(updatedEvent: IFestivity) {
        var events: IFestivity[] = this.GetEvents().filter((e: IFestivity) => e.id == updatedEvent.id);
        if (events.length == 0)
            return;
        this.UpdateEventObject(events[0], updatedEvent);
        this.OnEventUpdated.Call(events[0]);
    }

    public RemoveEvent(eventId: number) {
        this.OnEventDeleted.Call(eventId);
    }

    public RefreshEvents() {
        this.OnEventsRefresh.Call();
    }

    public SetHeader(header: IFullCalendarHeader): void {
        this.OnSetHeaderRequest.Call(header);
    }

    public SetViewsOption(views: IFullCalendarViewMap) {
        this.OnSetViewsOptionRequest.Call(views);
    }

    public ChangeView(view: string): void {
        this.OnChengeViewRequest.Call(view);
    }

    public GoToDate(date: Moment): void {
        this.OnGoToDateRequest.Call(date);
    }

    public SetBusinessHours(businessHours: any): void {
        this.OnSetBusinessHoursRequest.Call(businessHours);
    }

    public SelectInterval(start: Moment, end: Moment = null): void {
        if (!end) {
            this.OnSelectRequest.Call(start);
            return;
        }
        this.OnSelectRequest.Call(start, end);
    }

    public ClearSelection():void {
        this.OnUnselectRequest.Call();
    }

    public SetSlotDuration(duration: string): void {
        this.OnSetSlotDurationRequest.Call(duration);
    }

    public IsInViewRange(start: Moment, end: Moment): boolean {
        return (this.ActualView().start <= start && this.ActualView().end > start) || (this.ActualView().start <= end && this.ActualView().end > end);
    }

    public OnDragStopCallback(event:IFullCalendarEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {}

    public OnDragStartCallback(event: IFullCalendarEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {}

    public OnViewRenderCallback(view: IFullCalendarViewObject, element: HTMLElement): void {
        this.ActualView(view);
    }

    public OnEventRenderCallback(event: IFestivity, element: JQuery<HTMLElement>, view: IFullCalendarViewObject): any {}

    public OnEventDestroyCallback(event: IFestivity, element: HTMLElement, view: IFullCalendarViewObject): void {}

    public OnEventDropCallback(event: IFestivity, delta: any, revertFunc: () => void, jsEvent: any, ui: JQuery, view: IFullCalendarViewObject): void {
        this.UpdateEventAfterMove(event)
            .catch(() => {
                revertFunc();
            });
    }

    public OnEventResizeCallback(event: IFestivity, delta: any, revertFunc: () => void, jsEvent: any, ui: JQuery, view: IFullCalendarViewObject): void {
        this.UpdateEventAfterMove(event)
            .catch(() => {
                revertFunc();
            });
    }

    public OnEventClickCallback(event: IFestivity, jsEvent: any, view: IFullCalendarViewObject): void {
        FestivitiesCalendar._self.SelectEvent(event);
        if (jsEvent.originalEvent.detail > 1) {
            (<any>FestivitiesCalendar._self).OnEventDblClick(event);
            return;
        }

        if ((<any>FestivitiesCalendar._self).JQuerySelectedEvent)
            (<any>FestivitiesCalendar._self).JQuerySelectedEvent.css('outline', '1px solid none');

        (<any>FestivitiesCalendar._self).JQuerySelectedEvent = $(this);
        $(this).css('outline', '1px solid red');
    }

    public OnDayRenderCallback(date: Moment, cell: JQuery): void {
        var day = date.day();

        var timeTable: IAgendaTimetableViewModel = this.agenda.GetTimetableForDay(date);
        if (!timeTable)
            return;
        if(!timeTable.IsWorkableDay(day)) {
            cell.css('background-color', '#eee');
        }
    }

    public OnDropCallback(date: any, jsEvent: any, ui: JQuery, resourceId: any): void { }

    public OnEventReceiveCallback(event: IFestivity): void { }

    public OnSelectCallback(start: Moment, end: Moment, jsEvent: any, view: IFullCalendarViewObject): void {
        start = start.local();
        end = end.local();

        this.SelectedInterval(<IFullCalendarSelectedInterval> {
            start: start.local(),
            end: end.local(),
            jsEvent: jsEvent,
            view: view
        });
    }

    public OnUnselectCallback(jsEvent: any, view: IFullCalendarViewObject): void {
        this.SelectedInterval(undefined);
    }

    public RegisterEventsDragObserver(observer: IFullCalendarEventsDragObserver): void {}

    public NotifyEventDragStart(event: IFestivity, jsEvent: any, view: IFullCalendarViewObject): void {}

    public NotifyEventDragStop(event: IFestivity, jsEvent: any, view: IFullCalendarViewObject): void {}

    public ModifyEvent(event: IFestivity): Promise<IFestivity> {
        var def = new Deferred<IFestivity>();
        this.ShowEditingDialog(event)
            .then((modifiedEvent: IFestivity) => {
                if (!modifiedEvent) {
                    def.resolve(null);
                    return;
                }

                if (<any>modifiedEvent.Deleted != ProlifeSdk.NotDeletedState || modifiedEvent.AgendaId != this.agenda.AgendaId()) {
                    this.RemoveEvent(event.id);
                    return;
                }

                this.UpdateEvent(modifiedEvent);
                def.resolve(modifiedEvent);
            });
        return def.promise();
    }

    private OnEventDblClick(event: IFestivity): void {
        if (event.editable)
            this.ModifyEvent(event);
    }

    private ShowEditingDialog(event: IFestivity): Promise<IFestivity> {
        return this.festivitiesService.ui.GetFestivityEditingDialog(event.id, this.agenda.AgendaId());
    }

    private UpdateEventObject(sourceEvent: IFestivity, updatedEvent: IFestivity): void {
        sourceEvent.id = updatedEvent.id;
        sourceEvent.title = updatedEvent.title;
        sourceEvent.allDay = updatedEvent.allDay;
        sourceEvent.start = moment(updatedEvent.start);
        sourceEvent.end = moment(updatedEvent.end);
        sourceEvent.color = updatedEvent.color;
        sourceEvent.AgendaId = updatedEvent.AgendaId;
        sourceEvent.CategoryId = updatedEvent.CategoryId;
        sourceEvent.Note = updatedEvent.Note;
    }

    private UpdateEventAfterMove(event: IFullCalendarEvent): Promise<void> {
        event.start = moment(event.start).local();
        event.end = !event.end ? null : moment(event.end).local();

        return this.festivitiesService.UpdateFestivityAfterMove(event.id, moment(event.start).toDate(), !event.end ? null : moment(event.end).toDate(), event.allDay);
    }
}