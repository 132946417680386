export class ProLifeClipboard {
    public static copy(value: string): void {
        let copyElement: HTMLTextAreaElement = document.createElement("textarea");
        copyElement.value = value;
        copyElement = document.body.appendChild(copyElement);
        copyElement.select();
        document.execCommand("copy");
        copyElement.remove();
    }
}
