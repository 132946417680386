import * as ko from "knockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { DocumentRow } from "../../../Invoices/invoices/documents/DocumentRows";
import { IInvoicesService } from "../../interfaces/invoice/IInvoicesService";
import {
    ILeafReferenceInfo,
    IRowKey,
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentReferencingRows,
} from "../../interfaces/invoice/IDocumentsService";
import {
    IEntityRefInfo,
    IReferenceDetailsViewModel,
    IReferenceDetailsViewModelFactory,
} from "../../interfaces/invoice/IEntityRefInfo";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";

export class ReferencesDetailsBalloon {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    public RowIsGroup: ko.Observable<boolean> = ko.observable(false);
    public IsVisible: ko.Observable<boolean> = ko.observable(false);
    public Groups: ko.ObservableArray<ReferencesDetailsBalloonGroup> = ko.observableArray([]);
    public ReferencerGroups: ko.ObservableArray<ReferencesDetailsBalloonGroup> = ko.observableArray([]);
    public CanBeClosed: ko.Observable<boolean> = ko.observable(false);
    public IsManuallyClosed: ko.Observable<boolean> = ko.observable(false);
    public Amount: ko.Observable<number> = ko.observable(0);
    public ClosedAmount: ko.Observable<number> = ko.observable(0);
    public Row: ko.Observable<any> = ko.observable();

    public LeafsGroups: ko.ObservableArray<LeafsGroupInfo> = ko.observableArray([]);

    public ClosedAmountOverflow: ko.Computed<boolean>;

    constructor(
        private customerObservableField: ko.Observable<number>,
        private sourceWarehouseObservableField: ko.Observable<number> = ko.observable(0)
    ) {
        this.ClosedAmountOverflow = ko.computed(() => {
            return this.ClosedAmount() > this.Amount();
        });
    }

    async LoadReferences(
        references: IDocumentBuilderDocumentOriginatingRows[],
        referencers: IDocumentBuilderDocumentReferencingRows[],
        row: DocumentRow
    ) {
        this.RowIsGroup(references.length > 1);
        this.Row(row);
        this.CanBeClosed(row.CanBeClosed());
        this.Amount(row.Amount());
        this.ClosedAmount(row.ClosedAmount());
        this.IsManuallyClosed(row.ManuallyClosed() && !row.IsAutomaticallyClosed());

        await this.LoadRefsOnCollection(references, this.Groups, false);
        await this.LoadRefsOnCollection(this.AdjustReferencers(referencers), this.ReferencerGroups, true);

        if (references.length > 0)
            await this.LoadLeafsInfo(references[0].DestEntityKeyId, references[0].DestEntityType);

        //Carico i dati dei riferimenti foglia della catena documentale
        this.IsVisible(true);
    }

    private AdjustReferencers(referencers: IDocumentBuilderDocumentReferencingRows[]) {
        const cloneCollection: IDocumentBuilderDocumentOriginatingRows[] = referencers.map(
            (r: IDocumentBuilderDocumentReferencingRows) => {
                return <IDocumentBuilderDocumentOriginatingRows>{
                    SourceEntityKeyId: r.DestEntityKeyId,
                    SourceEntityType: r.DestEntityType,
                    DestEntityKeyId: -1,
                    DestEntityType: null,
                    Amount: r.Amount,
                    WarehouseId: r.WarehouseId,
                };
            }
        );
        return cloneCollection;
    }

    private async LoadRefsOnCollection(
        ref: IDocumentBuilderDocumentOriginatingRows[],
        collection: ko.ObservableArray<ReferencesDetailsBalloonGroup>,
        isReferencerCollegtion: boolean
    ): Promise<void> {
        const referencedEntitiesDetails = await this.invoicesService.getReferencesDetails(ref);

        const groups = [];
        const groupsCodes: string[] = [];
        referencedEntitiesDetails.forEach((rd: IEntityRefInfo) => {
            if (groupsCodes.indexOf(rd.EntityType) == -1) groupsCodes.push(rd.EntityType);
        });

        groupsCodes.forEach((c) => {
            const groupEntitiesDetails = referencedEntitiesDetails.filter((ri) => ri.EntityType == c);
            const groupReferences = ref.filter((r1) => r1.SourceEntityType == c);
            groups.push(
                new ReferencesDetailsBalloonGroup(
                    c,
                    groupEntitiesDetails,
                    groupReferences,
                    this.customerObservableField(),
                    isReferencerCollegtion
                )
            );
        });
        collection(groups);
    }

    private LoadLeafsInfo(rowId: number, rowType: string): Promise<ILeafReferenceInfo[]> {
        this.LeafsGroups([]);

        if (!rowId) return Promise.resolve<ILeafReferenceInfo[]>([]);

        const rowKey: IRowKey = {
            EntityType: rowType,
            EntityKeyId: rowId,
        };

        return this.documentsService
            .GetLeafsReferencesInfoForRow(rowKey)
            .then((leafsEntitiesInfo: ILeafReferenceInfo[]) => {
                const groups = [];
                const groupsCodes: string[] = [];
                leafsEntitiesInfo.forEach((rd: ILeafReferenceInfo) => {
                    if (groupsCodes.indexOf(rd.EntityKeyType) == -1) groupsCodes.push(rd.EntityKeyType);
                });

                groupsCodes.forEach((c: string) => {
                    const leafsGroupInfo = leafsEntitiesInfo.filter((li: ILeafReferenceInfo) => {
                        return li.EntityKeyType == c;
                    });
                    groups.push(
                        new LeafsGroupInfo(
                            c,
                            leafsGroupInfo,
                            this.customerObservableField(),
                            this.sourceWarehouseObservableField()
                        )
                    );
                });
                this.LeafsGroups(groups);
                return leafsEntitiesInfo;
            });
    }

    SwitchVisibility() {
        this.IsVisible(!this.IsVisible());
    }
}

export class LeafsGroupInfo {
    Title: string;
    Entities: IReferenceDetailsViewModel[] = [];

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;

    constructor(entityType: string, leafEntitiesInfo: ILeafReferenceInfo[], customerId: number, warehouseId: number) {
        const factory: IReferenceDetailsViewModelFactory =
            this.entitiesService.getReferenceDetailsViewModelFactory(entityType);

        if (factory) {
            this.Entities = factory.CreateViewModelForReferencedLeafs(leafEntitiesInfo, warehouseId, customerId);
            this.Title = factory.GetEntityTypeDescription();
        }
    }
}

export class ReferencesDetailsBalloonGroup {
    Title: string;
    Entities: IReferenceDetailsViewModel[] = [];

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;

    constructor(
        entityType: string,
        referencedEntitiesDetails: IEntityRefInfo[],
        references: IDocumentBuilderDocumentOriginatingRows[],
        customerId: number,
        isReferencerGroup: boolean
    ) {
        const factory = this.entitiesService.getReferenceDetailsViewModelFactory(entityType);

        if (factory) {
            this.Entities = factory.CreateViewModelsFor(
                references,
                referencedEntitiesDetails,
                customerId,
                isReferencerGroup
            );
            this.Title = factory.GetEntityTypeDescription();
        }
    }
}
