import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { InvoicesByVatRegistersReport } from "../reports/InvoicesByVatRegistersReport";
import { InvoicesByCustomerReport } from "../reports/InvoicesByCustomerReport";
import { DdtsReport } from "../reports/DdtsReport";
import { ProtocolsDocumentsReport } from "../reports/ProtocolsDocumentsReport";
import { AccountingExport } from "../reports/AccountingExport";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class InvoicesReportsProvider implements IReportsProvider
{
    Id : number = 1;
    Name : string = ProlifeSdk.TextResources.Invoices.AdministrativeDocuments;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";
    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    private navigator : IReportsNavigator;
    serviceLocator : IServiceLocator;
    authorizationService : IAuthorizationService;

    constructor(serviceLocator : IServiceLocator)
    {
        this.serviceLocator = serviceLocator;
        this.authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.initialize();
    }

    initialize()
    {
        this.Reports([]);

        if(this.authorizationService.isAuthorized("Documents_VatRegistersReport"))
            this.Reports.push(new InvoicesByVatRegistersReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("Documents_CustomerInvoicesReport"))
            this.Reports.push(new InvoicesByCustomerReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("Documents_DDTsReport"))
            this.Reports.push(new DdtsReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("Documents_ProducedDocumentsReport"))
            this.Reports.push(new ProtocolsDocumentsReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("Documents_AccountingExportReport"))
            this.Reports.push(new AccountingExport(this.serviceLocator, this.Id));

        this.setCurrentNavigator(this.navigator);
    }

    getReportById(reportId : number) : IReport
    {
        var report : IReport = null;

        this.Reports().forEach(r => {
            report = r.Id == reportId ? r : report;
        })

        return report;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
        this.Reports().forEach((provider) => provider.setNavigator(navigator));
    }

    Open()
    {
        this.navigator.navigateTo(this);
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}


