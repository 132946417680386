import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { ComputedResourcesDataSource, ActiveResourcesByDate } from "../ComputedResourcesDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IGDPREventTypesSettingsManager } from "../../settings/GDPREventTypesSettingsManager";
import { Right } from "../../../Core/Authorizations";
import { IFullTreatmentEvents, IFullTreatmentEventsResources } from "../../GDPRService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ResourcesDataSource, IResourceDataSourceModel } from "../../../DataSources/ResourcesDataSource";
import  * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import ko = require("knockout");
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";

export class TreatmentEvent implements IDataSourceListener {
    Id : number;
    FkTreatment : number;
    Description : ko.Observable<string> = ko.observable("");
    public DescriptionTrigger: ko.Observable<any> = ko.observable();
    Title: ko.Observable<string> = ko.observable("");
    IsLocked: ko.Observable<boolean> = ko.observable(false);
    Date: ko.Observable<Date> = ko.observable();
    OriginalDate: Date;
    
    FkEventType: ko.Observable<number> = ko.observable();
    FkOriginalLogicEventType: number;
    FkLogicEventType: ko.Computed<number>;

    Location?: ko.Observable<string> = ko.observable("");
    CreationDate : Date;
    FkCreationUser : number;
    CreationUserFullName: string;
    LastEditDate : Date;
    FkLastEditUser : number;
    LastEditUserFullName: string;
    IsSystemEvent: boolean;
    Resources: ko.ObservableArray<TreatmentEventResource> = ko.observableArray([]);

    IsEdit: ko.Observable<boolean> = ko.observable(false);
    IsDisabled: ko.Computed<boolean>;
    HasError: ko.Observable<boolean> = ko.observable(false);

    public ResourcesDataSource: ComputedResourcesDataSource;
    IsDetailHidden: ko.Observable<boolean> = ko.observable(true);
    SelectedResourceId: ko.Observable<number> = ko.observable();
    SelectedResourceFullName: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImportSettingManager(nameof<IGDPREventTypesSettingsManager>())
    private GDPREventTypesSettingsManager: IGDPREventTypesSettingsManager;

    validator: IValidator<TreatmentEvent>;

    @Right("GDPR_CanWriteOpenCloseTreatmentEvents")
    private canWriteOpenCloseTreatmentEvents!: boolean;

    @Right("GDPR_CanWriteDescriptiveTreatmentEvents")
    private canWriteDescriptiveTreatmentEvents!: boolean;

    @Right("GDPR_CanLockEvents")
    private canLockEvents!: boolean;

    @Right("GDPR_CanWriteRemoveResourcesTreatmentEvents")
    private canWriteRemoveResourcesTreatmentEvents!: boolean;

    @Right("GDPR_CanModifyOtherPeopleEvents")
    private canModifyOtherPeopleEvents!: boolean;

    @Right("GDPR_CanAddResourcesInEvents")
    private canAddResourcesInEvents!: boolean;

    @Right("GDPR_CanRemoveResourcesInEvents")
    private canRemoveResourcesInEvents!: boolean;

    @Right("GDPR_CanAddRemoveYourself")
    private canAddRemoveYourself!: boolean;

    public CanWrite: ko.Computed<boolean>;
    public CanLockEvents: ko.Observable<boolean> = ko.observable(this.canLockEvents);
    public CanAddResources: ko.Observable<boolean> = ko.observable(this.canAddResourcesInEvents);
    public CanRemoveResources: ko.Observable<boolean> = ko.observable(this.canRemoveResourcesInEvents);
    public CanAddRemoveYourself: ko.Observable<boolean> = ko.observable(this.canAddRemoveYourself);
    public ShowOtherPeopleEventAlert: ko.Observable<boolean> = ko.observable();

    constructor(event: IFullTreatmentEvents, eventResources: IFullTreatmentEventsResources[] = null, activeResourcesByDate: ko.Computed<ActiveResourcesByDate[]>){
        this.Id = event.Id;
        if (event.Id < 0)
            this.IsDetailHidden(false);

        this.FkTreatment = event.FkTreatment;
        this.Title(event.Title);
        this.Description(event.Description);
        this.IsLocked(event.IsLocked);
        this.Date(event.Date);
        this.OriginalDate = event.Date;

        this.FkEventType(event.FkEventType);
        this.FkLogicEventType = ko.computed(() => this.GDPREventTypesSettingsManager.getEventTypesByIds([this.FkEventType()]).firstOrDefault()?.FkLogicEventType);
        this.FkOriginalLogicEventType = this.FkLogicEventType();

        this.Location(event.Location);
        this.CreationDate = event.CreationDate;
        this.FkCreationUser = event.FkCreationUser;
        this.CreationUserFullName = event.CreationUserFullName;
        this.LastEditDate = event.LastEditDate;
        this.FkLastEditUser = event.FkLastEditUser;
        this.LastEditUserFullName = event.LastEditUserFullName;
        this.IsSystemEvent = this.GDPREventTypesSettingsManager.checkIfSystemEvent(event.FkEventType);

        this.ResourcesDataSource = new ComputedResourcesDataSource(activeResourcesByDate);
        this.Date.subscribe(this.callback.bind(this));
        this.FkEventType.subscribe(this.callback.bind(this));

        this.ShowOtherPeopleEventAlert(this.Id > 0 && !this.canModifyOtherPeopleEvents && this.FkLastEditUser != this.userInfo.getIdUser());
        this.IsDisabled = ko.computed(() => !this.IsEdit());

        this.CanWrite = ko.computed(() => {
            let logicType = this.FkLogicEventType();
            return ((!this.IsLocked() || this.canLockEvents) &&
                    (this.Id < 0 || this.FkLastEditUser == this.userInfo.getIdUser() || this.canModifyOtherPeopleEvents) &&
                    ((logicType == 1 || logicType == 2) && this.canWriteOpenCloseTreatmentEvents) ||
                    (logicType == 3 && this.canWriteRemoveResourcesTreatmentEvents) ||
                    (logicType == 4 && this.canWriteDescriptiveTreatmentEvents));
        });
        
        this.validator = this.validationService.createValidator<TreatmentEvent>()
        //.isNotNullOrUndefinedOrWhiteSpace((e) => e.Location(), TextResources.GDPR.ErrorNoLocation)
                    .isNotNullOrUndefinedOrWhiteSpace((e) => e.Title(), TextResources.GDPR.ErrorNoTitle);

        if (eventResources) {
            let relevantResources = eventResources.filter((er) => er.FkEvent == event.Id);
            if (relevantResources.length > 0) {
                this.loadResources(relevantResources);
            }
        }
    }

    public callback() {
        if (!this.canAddResourcesInEvents && this.canAddRemoveYourself)
            this.ResourcesDataSource.setFilter(this.Date(), this.Id, this.FkLogicEventType() == 3, this.userInfo.getIdUser());
        else 
            this.ResourcesDataSource.setFilter(this.Date(), this.Id, this.FkLogicEventType() == 3);
    }

    async loadResources(relevantResources: IFullTreatmentEventsResources[]){
        let dataSource = new ResourcesDataSource();
        let fromDataSource = await dataSource.getById(null, relevantResources.map((er) => er.FkResource));
        let loadedResources = fromDataSource.map((x) => new TreatmentEventResource({
            FkEvent: this.Id, 
            FkResource: x.model.Resource.Id
        }, x.title));

        this.Resources.push(...loadedResources);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (!!model) {
            let resourceModel = model as IResourceDataSourceModel;
            this.SelectedResourceFullName(resourceModel.model.Resource.Name + ' ' + resourceModel.model.Resource.Surname);
            this.addResource();
            setTimeout(() => {
                this.ResourcesDataSource.select(...[]);
                this.SelectedResourceId(undefined);
                this.SelectedResourceFullName(undefined);
            }, 250);
        }
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }

    addResource(){
        if (!this.SelectedResourceId()){
            this.infoToastService.Error(TextResources.GDPR.SelectAResource);
            return;
        }

        if (this.Resources().any(r => r.FkResource() == this.SelectedResourceId())){
            this.infoToastService.Error(TextResources.GDPR.ErrorResourceAlreadyInList);
            return;
        }

        let er = new TreatmentEventResource({
            FkEvent: this.Id,
            FkResource: this.SelectedResourceId(),
            ResourceType: null
        }, this.SelectedResourceFullName());

        this.Resources.push(er);
    }

    deleteResource(resource: TreatmentEventResource){
        this.dialogsService.Confirm(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem, (result) => {
            if (result)
                this.Resources.remove(resource);
        });
    }

    toggleEdit() {
        this.IsEdit(!this.IsEdit());
    }

    toggleDetail() {
        this.IsDetailHidden(!this.IsDetailHidden());
    }

    toggleLock(){
        this.IsLocked(!this.IsLocked());
        if (this.IsLocked())
            this.IsEdit(false);
    }
}

export class TreatmentEventResource {
    FkEvent: number;
    FkResource: ko.Observable<number> = ko.observable();
    ResourceType: string = ProlifeSdk.HumanResources;
    ResourceFullName: string;
    HasError: ko.Observable<boolean> = ko.observable(false);
    CanBeRemoved: ko.Computed<boolean>;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImportSettingManager(nameof<IHumanResourcesSettingsManager>())
    private resourcesSettingsManager: IHumanResourcesSettingsManager;

    validator: IValidator<TreatmentEventResource>;

    @Right("GDPR_CanRemoveResourcesInEvents")
    private canRemoveResourcesInEvents: boolean;

    @Right("GDPR_CanAddRemoveYourself")
    private canAddRemoveYourself: boolean;

    constructor(eventResource: IFullTreatmentEventsResources, fullName: string = null){
        this.FkEvent = eventResource.FkEvent;
        this.FkResource(eventResource.FkResource);
        if (eventResource.ResourceType)
            this.ResourceType = eventResource.ResourceType;

        if (fullName){
            this.ResourceFullName = fullName;
        }

        this.CanBeRemoved = ko.computed(() => this.canRemoveResourcesInEvents || 
            (this.canAddRemoveYourself && this.FkResource() == this.resourcesSettingsManager.getHumanResourceByUserId(this.userInfo.getIdUser()).Resource.Id));

        this.validator = this.validationService.createValidator<TreatmentEventResource>()
            .isNotNullUndefinedOrZero((r) => r.FkResource(), TextResources.GDPR.ErrorNoResourceSelected);
    }
}