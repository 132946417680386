import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSourceModel } from "./IDataSource";
import { IVatNatureCode } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class VatNatureCodesDataSource extends BaseDataSource<IDataSourceModel<string, IVatNatureCode>> {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor() {
        super();
    }
    
    public getTitle(currentModel: IDataSourceModel<string, IVatNatureCode>): string {
        return "";
    }

    public async getData(currentModel: IDataSourceModel<string, IVatNatureCode>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<string, IVatNatureCode>[]> {
        let codes = await this.documentsService.GetVatNatureCodes(textFilter, skip, count);
        return codes.map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<string, IVatNatureCode>, ids: string[]): Promise<IDataSourceModel<string, IVatNatureCode>[]> {
        let codes = await this.documentsService.GetVatNatureCodesByIds(ids);
        return codes.map(this.createModel, this);
    }

    private createModel(code: IVatNatureCode): IDataSourceModel<string, IVatNatureCode> {
        return {
            id: code.Code,
            title: String.format(TextResources.Invoices.VatNatureCodeLabel, code.Code, code.Description),
            isGroup: false,
            isLeaf: true,
            model: code
        };
    }
}