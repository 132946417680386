import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IAccountingSoftwareRoute, IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IIvaModes, IIvaMode } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";

interface ILastRoutingConfiguration
{
    ProtocolCode : string;
    VatRoutes : IVatRoute[];
}

interface IVatRoute extends IAccountingSoftwareRoute
{
    VatCode : string;
    VatCodeForAccountingSoftware : string;
}

export class AccountingSoftwareRoutingDialog implements IDialog
{
    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private vatSettings : IIvaModes;

    modal: { close: (result?: any) => void; };
    public templateName : string = "accounting-software-routing";
    public templateUrl : string = "invoices/templates/settings";
    public title : string = ProlifeSdk.TextResources.Invoices.ExternalExportConfig;
    private someIsChanged : boolean = false;
    private lastAppliedConfiguration : ILastRoutingConfiguration;

    public AccountingSoftwareCodeForProtocol : ko.Observable<string> = ko.observable("");
    public VatRoutes : ko.ObservableArray<VatRoute> = ko.observableArray([]);
    private ProtocolName : string;

    constructor(private protocol : IVatRegister, private protocolNameField : ko.Observable<string>)
    {
        this.Initialize();
    }

    private Initialize()
    {
        var vats : IIvaMode[] = this.vatSettings.getIvaModes(true);

        this.lastAppliedConfiguration = {
            ProtocolCode : this.protocol.AccountingSoftwareCode,
            VatRoutes : vats.map((vm : IIvaMode) => {
                var matches : IAccountingSoftwareRoute[] = (this.protocol.AccountingSoftwareRoutes || []).filter((r : IAccountingSoftwareRoute) => { return r.FkVatCode == vm.IdTipoIVA});
                return {
                    ProtocolId: this.protocol ? this.protocol.IdRegistroIVA : 0,
                    FkVatCode: vm.IdTipoIVA,
                    AccountRegisterCode: matches.length == 0 ? null : matches[0].AccountRegisterCode,
                    VatCode : vm.CodiceIVA,
                    VatCodeForAccountingSoftware : vm.AccountingSoftwareCode
                }
            })
        };

        this.RestoreLastConfiguration();
    }

    private RestoreLastConfiguration()
    {
        this.AccountingSoftwareCodeForProtocol(this.lastAppliedConfiguration.ProtocolCode);
        this.VatRoutes(this.lastAppliedConfiguration.VatRoutes.map((r : IVatRoute) => { return new VatRoute(r); }));
    }

    private ApplyCurrentConfiguration()
    {
        this.lastAppliedConfiguration = {
            ProtocolCode : this.AccountingSoftwareCodeForProtocol(),
            VatRoutes : this.VatRoutes().map((r : VatRoute) => { return r.GetData(); })
        };
    }

    public SomeIsChanged()
    {
        return this.someIsChanged;
    }

    public GetRoutes() : IAccountingSoftwareRoute[]
    {
        return this.lastAppliedConfiguration.VatRoutes;
    }

    public GetProtocolCode() : string
    {
        return this.AccountingSoftwareCodeForProtocol();
    }

    action(): void
    {
        this.someIsChanged = true;
        this.ApplyCurrentConfiguration();
        this.modal.close();
    }

    close(): void
    {
        this.RestoreLastConfiguration();
        this.modal.close();
    }
}

export class VatRoute
{
    public AccountRegisterCode : ko.Observable<string> = ko.observable("");

    constructor(private r : IVatRoute)
    {
        this.AccountRegisterCode(r.AccountRegisterCode);
    }

    public GetData() : IVatRoute
    {
        return {
            ProtocolId: this.r.ProtocolId,
            FkVatCode: this.r.FkVatCode,
            AccountRegisterCode: this.AccountRegisterCode(),
            VatCode : this.r.VatCode,
            VatCodeForAccountingSoftware : this.r.VatCodeForAccountingSoftware
        }
    }
}