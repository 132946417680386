import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/03/2019
 * Time: 15:23
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITaskForTaskBoard, ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IWorkflowSelectorObserver,
    ITodoListWorkflow,
} from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowNavigator } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IWorkflowEditorResult } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IProductionActivitiesPrintDialog } from "../../../../ProlifeSdk/interfaces/job-order/IProductionActivitiesPrintDialog";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IWorkflowProvider } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowProvider";
import { ResponseError } from "../../../../Core/response/ResponseBase";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

interface ITaskForTaskboardWithCompanyGuid extends ITaskForTaskBoard {
    CompanyGuid?: string;
}

export class ProductionActivitiesPrintDialog
    implements IProductionActivitiesPrintDialog, IWorkflowSelectorObserver, IChangesNotificationsServiceObserver
{
    public templateName: string = "production-activities-print-dialog";
    public templateUrl: string = "joborder/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.JobOrder.ProductionActivitiesPrintDialog;

    public modal: { close: (result?: any) => void };

    public WorkflowSelector: IWorkflowNavigator;

    public Workflows: ko.ObservableArray<Workflow> = ko.observableArray([]);

    public AllowedMimeTypes: string[] = ["application/prolife-task", "application/prolife-tasks"];
    public TasksMergeConfirmRequested: boolean = true;

    @LazyImport(ServiceTypes.Dialogs)
    private dialogsService: IDialogsService;
    @LazyImport(ServiceTypes.InfoToast)
    private infoToastService: IInfoToastService;
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService: ITodoListService;
    @LazyImport(ProlifeSdk.ChangesNotificationsServiceType)
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    constructor(private serviceLocator: IServiceLocator, private jobOrderId: number, selectedWorkflow: number = null) {
        this.WorkflowSelector = this.todoListService.GetWorkflowNavigatorUI({ DisableTasksNavigation: true });
        this.WorkflowSelector.RegisterObserver(this);
        this.WorkflowSelector.setMultipleSelectionStatus(true);

        this.WorkflowSelector.Initialize([this.jobOrderId]).then(() => {
            if (selectedWorkflow != null) this.WorkflowSelector.SelectWorkflow(selectedWorkflow);
        });

        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
    }

    public close(): void {
        this.changesNotificationsService.RemoveObserver(this);
        this.modal.close(null);
    }

    public action(): void {
        const selectedWorkflows = this.WorkflowSelector.getSelectedProviders().map(
            (w: IWorkflowProvider) => w.WorkflowId
        );
        if (selectedWorkflows.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.JobOrder.WorkflowsSelectionRequired);
            return;
        }

        this.dialogsService.LockUI(ProlifeSdk.TextResources.JobOrder.DownloadProductionDocument);
        this.todoListService.PrintProductionActivitiesDocument(this.jobOrderId, selectedWorkflows).finally(() => {
            this.dialogsService.UnlockUI();
        });
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", null, this.templateUrl, this.templateName);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (changesInfo.Action == 2 && changesInfo.EntityCode === ProlifeSdk.WorkflowEntityTypeCode) {
            this.OnWorkflowDeleted(changesInfo.EntityKeyId);
            return false;
        }

        return false;
    }

    public OnWorkflowSelectionChanged(selection: number[]) {
        if (selection.length == 0) {
            this.Workflows([]);
            return;
        }

        const selectedWorkflows: IWorkflowProvider[] = <IWorkflowProvider[]>(
            this.WorkflowSelector.getSelectedProviders()
        );
        const workflowsList: Workflow[] = this.Workflows();

        for (let i = 0; i < workflowsList.length; i++) {
            if (
                selectedWorkflows.filter((w: IWorkflowProvider) => w.WorkflowId == workflowsList[i].WorkflowId)
                    .length == 0
            ) {
                workflowsList.splice(i, 1);
                i--;
            }
        }

        selectedWorkflows.forEach((wp: IWorkflowProvider) => {
            if (this.Workflows().filter((w: Workflow) => w.WorkflowId == wp.WorkflowId).length == 0) {
                workflowsList.push(new Workflow(this.serviceLocator, this.jobOrderId, wp, this));
            }
        });

        this.Workflows(workflowsList);
    }

    public OnWorkflowDeleted(workflowId: number) {
        const workflowsList: Workflow[] = this.Workflows();

        for (let i = 0; i < workflowsList.length; i++) {
            if (workflowsList[i].WorkflowId == workflowId) {
                const workflows = workflowsList.splice(i, 1);
                workflows[0].Dispose();
                break;
            }
        }

        this.Workflows(workflowsList);
    }

    public OnWorkflowChanged(workflowId: number) {
        // Non ho trovato nessun punto in cui questo metodo venga chiamato
    }

    public OnWorkflowCreated(workflowId: number) {
        // Non ho trovato nessun punto in cui questo metodo venga chiamato
    }

    public OnWorkflowImportedFromTemplate(workflowId: number) {
        // Non devo fare niente
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {
        this.Workflows().forEach((w: Workflow) => {
            if (w.WorkflowId != workflow.Id) return;

            w.UpdateFrom(workflow);
        });
    }

    public OnTaskDropped(dataTransfer: DataTransfer /*event, ui*/): void {
        /*var task: ITaskForTaskBoard = ui.draggable.data("task");

        this.todoListService.CreateNewWorkflowWithTasks(ProlifeSdk.TextResources.JobOrder.NewWorkflowDefaultTitle, [task.Id], this.jobOrderId)
            .then((workflow: ITodoListWorkflow) => {
                this.WorkflowSelector.SelectWorkflow(workflow.Id);
            });*/

        let droppedTasks: ITaskForTaskBoard[] = [];
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0) {
            droppedTasks = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
        } else {
            const task: ITaskForTaskBoard = JSON.parse(dataTransfer.getData("application/prolife-task"));
            droppedTasks.push(task);
        }

        if (!this.ValidateDrop(droppedTasks[0])) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.InvalidCompanyOrJobOrder);
            return;
        }

        this.todoListService
            .CreateNewWorkflowWithTasks(
                ProlifeSdk.TextResources.JobOrder.NewWorkflowDefaultTitle,
                droppedTasks.map((t) => t.Id),
                this.jobOrderId
            )
            .then((workflow: ITodoListWorkflow) => {
                this.WorkflowSelector.SelectWorkflow(workflow.Id);
            });
    }

    public async TasksMergeConfirm(): Promise<boolean> {
        if (!this.TasksMergeConfirmRequested) return true;

        const dialog = new TasksMergeConfirmDialog(this);
        const confirm: boolean = await this.dialogsService.ShowModal(
            dialog,
            "",
            null,
            dialog.templateUrl,
            dialog.templateName
        );

        return confirm;
    }

    public ValidateDrop(droppedTask: ITaskForTaskboardWithCompanyGuid): boolean {
        if (this.userInfo.getCurrentCompanyGuid() != droppedTask.CompanyGuid) return false;

        if (droppedTask.JobOrderId != this.jobOrderId) return false;

        return true;
    }
}

class Workflow implements IChangesNotificationsServiceObserver {
    public WorkflowId: number;
    public Title: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();

    public Tasks: ko.ObservableArray<Task> = ko.observableArray([]);
    public AllowedMimeTypes: string[] = ["application/prolife-task", "application/prolife-tasks"];

    public ReorderingFunctionEnabled: ko.Observable<boolean> = ko.observable(false);

    private selectedTasks: ko.ObservableArray<Task> = ko.observableArray([]);

    private todoListService: ITodoListService;
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImport(ServiceTypes.InfoToast)
    private infoToastService: IInfoToastService;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    constructor(
        private serviceLocator: IServiceLocator,
        public jobOrderId: number,
        workflow: IWorkflowProvider,
        private editor: ProductionActivitiesPrintDialog
    ) {
        this.todoListService = <ITodoListService>this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.changesNotificationsService = <IChangesNotificationsService>(
            this.serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType)
        );

        //d.collantoni: Notifica disabilitata in data 23/05/2023
        //this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.JobOrderTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);

        this.WorkflowId = workflow.WorkflowId;
        this.Title(workflow.Title());
        this.Icon(workflow.Icon());
        this.Background(workflow.Background());
        this.Foreground(workflow.Foreground());

        this.LoadTasks();
    }

    public UpdateFrom(workflow: ITodoListWorkflow): void {
        this.Title(workflow.Title);
    }

    public SwitchTasksListMode(): void {
        this.ReorderingFunctionEnabled(!this.ReorderingFunctionEnabled());
    }

    public async OnTaskDropped(dataTransfer: DataTransfer /*event, ui*/): Promise<void> {
        /*var task: ITaskForTaskBoard = ui.draggable.data("task");

        if (this.Tasks().filter((t: Task) => t.TaskId == task.Id).length != 0)
            return;

        this.todoListService.MoveTaskToWorkflow(task.Id, this.WorkflowId)
            .then(() => {
                setTimeout(() => { this.AddTask(task); }, 0);
            });*/

        let droppedTasks: ITaskForTaskBoard[] = [];
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0) {
            droppedTasks = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
        } else {
            const task: ITaskForTaskBoard = JSON.parse(dataTransfer.getData("application/prolife-task"));
            droppedTasks.push(task);
        }

        const tasksIds = this.Tasks().map((t) => t.TaskId);

        if (!this.editor.ValidateDrop(droppedTasks[0])) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.InvalidCompanyOrJobOrder);
            return;
        }

        const tasksToMove = droppedTasks.filter((t) => tasksIds.indexOf(t.Id) < 0);
        const tasksToMoveIds = tasksToMove.map((t) => t.Id);
        try {
            const response = await this.todoListService.MoveTasksToWorkflow(tasksToMoveIds, this.WorkflowId);
            if (response.succeeded) tasksToMove.forEach((task) => this.AddTask(task));
            else this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        } catch (e) {
            console.log(e);
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }

    public OnTaskMoved(dataTransfer: DataTransfer, task: Task, before: boolean) {
        let droppedTasks: ITaskForTaskBoard[] = [];

        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0) {
            droppedTasks = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
        } else if (dataTransfer.types.indexOf("application/prolife-task") >= 0) {
            const data = dataTransfer.getData("application/prolife-task");
            const droppedTask: ITaskForTaskBoard = JSON.parse(data);
            droppedTasks.push(droppedTask);
        }

        for (const draggedTask of droppedTasks) {
            if (!this.editor.ValidateDrop(draggedTask)) {
                this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.InvalidCompanyOrJobOrder);
                return;
            }

            if (this.WorkflowId != draggedTask.WorkflowId) {
                this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.InvalidTaskWorkflowMovement);
                return;
            }
        }

        for (const draggedTask of droppedTasks) {
            this.todoListService.MoveTask(draggedTask.Id, true, task.TaskId, true, before).then(() => {
                this.LoadTasks();
            });
        }
    }

    public OnBeginDrag(task: Task, dataTransfer: DataTransfer): void {
        let selectedTasks: ITaskForTaskboardWithCompanyGuid[] = this.selectedTasks().map((t) => t.GetData());
        if (selectedTasks.length == 0) selectedTasks.push(task.GetData());
        else if (selectedTasks.indexOf(task.GetData()) < 0) selectedTasks = [task.GetData()];

        const companyGuid = this.userInfo.getCurrentCompanyGuid();

        let taskTitles = "";
        for (const task of selectedTasks) {
            taskTitles += task.Title + "\n";
            task.CompanyGuid = companyGuid;
        }
        taskTitles = taskTitles.trim();

        dataTransfer.setData("text/plain", taskTitles);
        if (selectedTasks.length == 1)
            dataTransfer.setData("application/prolife-task", JSON.stringify(selectedTasks[0]));
        else dataTransfer.setData("application/prolife-tasks", JSON.stringify(selectedTasks));
    }

    public Edit(): void {
        this.todoListService.ShowEditWorkflowDialog(this.WorkflowId).then((result: IWorkflowEditorResult) => {
            this.UpdateFrom(result.Workflow);
        });
    }

    public SelectTask(task: Task, multiple: boolean = false) {
        const taskIndex = this.selectedTasks.indexOf(task);

        if (!multiple) {
            const wasMultiple = this.selectedTasks().length > 1;

            this.selectedTasks([]);
            this.Tasks().forEach((t) => t.IsSelected(false));
            task.IsSelected(taskIndex < 0 || wasMultiple);
            if (taskIndex < 0 || wasMultiple) this.selectedTasks.push(task);
            return;
        }

        if (taskIndex < 0) {
            task.IsSelected(true);
            this.selectedTasks.push(task);
        } else {
            task.IsSelected(false);
            this.selectedTasks.splice(taskIndex, 1);
        }
    }

    public Dispose(): void {
        this.changesNotificationsService.RemoveObserver(this);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean): Promise<boolean> {
        if (changesInfo.EntityCode == ProlifeSdk.TaskBoardTaskEntityTypeCode) {
            if (
                changesInfo.Action === 9 &&
                changesInfo.Changes.OldStatus.previousWorkflowId === this.WorkflowId &&
                changesInfo.Object.workflowId !== this.WorkflowId
            ) {
                const [task] = this.Tasks().filter((t: Task) => changesInfo.EntityKeyId == t.TaskId);
                if (!task) return false;

                this.Tasks.remove(task);
                return false;
            }

            if (changesInfo.Object.workflowId !== this.WorkflowId) return true;

            if (changesInfo.Object.activityProgressMode !== 1) {
                const [task] = this.Tasks().filter((t: Task) => changesInfo.EntityKeyId == t.TaskId);
                if (!task) return false;

                this.Tasks.remove(task);
                return false;
            }

            if (changesInfo.Action === 0) {
                const [task] = await this.todoListService.GetTasksForUserById(this.userInfo.getIdUser(), [
                    changesInfo.EntityKeyId,
                ]);
                if (!task) return false;

                this.AddTask(task);
                return false;
            }

            if (changesInfo.Action === 1 || changesInfo.Action === 8) {
                // 1 - update, 8 - update status
                const [existingTask] = this.Tasks().filter((t: Task) => changesInfo.EntityKeyId == t.TaskId);
                if (!existingTask) return false;

                const [task] = await this.todoListService.GetTasksForUserById(this.userInfo.getIdUser(), [
                    changesInfo.EntityKeyId,
                ]);
                if (!task) return false;

                existingTask.UpdateFrom(task);
                return false;
            }

            if (changesInfo.Action === 2) {
                const [task] = this.Tasks().filter((t: Task) => changesInfo.EntityKeyId == t.TaskId);
                if (!task) return false;

                this.Tasks.remove(task);
                return false;
            }
        }

        return true;
    }

    private LoadTasks(): void {
        const userInfoService: IUserInfo = <IUserInfo>this.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        const userId = userInfoService.getIdUser();

        const params = {
            textFilter: null,
            userId: userId,
            resourceId: null,
            workflows: [this.WorkflowId],
            statusIds: [-2],
            viewWorker: true,
            viewResponsible: true,
            skip: 0,
            count: 100000,
            viewAll: true,
            hideNoWork: false,
            destinationTaskBoardStatus: null,
            startDate: null,
            endDate: null,
            showClosed: true,
            getTasksIfNoFilter: false,
            getWorkflowsInWorkflows: true,
            searchOnWorkflowTitle: false,
        };

        this.todoListService.GetTasksForUser(params).then((tasks: ITaskForTaskBoard[]) => {
            this.Tasks(tasks.map((t: ITaskForTaskBoard) => new Task(this.serviceLocator, t, this, this.editor)));
        });
    }

    private AddTask(task: ITaskForTaskBoard): void {
        if (this.Tasks().filter((t) => t.TaskId == task.Id).length == 0)
            this.Tasks.push(new Task(this.serviceLocator, task, this, this.editor));
    }

    private RemoveTask(task: ITaskForTaskBoard): void {
        const tasks: Task[] = this.Tasks();

        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].TaskId == task.Id) {
                tasks.splice(i, 1);
                break;
            }
        }

        this.Tasks(tasks);
    }
}

class Task {
    public TaskId: number;
    public Title: ko.Observable<string> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public UnitOfMeasure: ko.Observable<string> = ko.observable();

    public IsSelected: ko.Observable<boolean> = ko.observable();
    public TaskJson: ko.Observable<string> = ko.observable();

    public Icon: ko.Computed<string>;

    public AllowedMimeTypes: string[] = ["application/prolife-task", "application/prolife-tasks"];

    private TaskBoardStatus: ko.Observable<number> = ko.observable();

    private infoToastService: IInfoToastService;
    private todoListService: ITodoListService;

    constructor(
        private serviceLocator: IServiceLocator,
        private task: ITaskForTaskBoard,
        private workflow: Workflow,
        private editor: ProductionActivitiesPrintDialog
    ) {
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.todoListService = <ITodoListService>this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.TaskId = this.task.Id;
        this.Title(this.task.Title);
        this.Amount(this.task.Multiplier);
        this.UnitOfMeasure(this.task.MultiplierUnitOfMeasure);
        this.TaskBoardStatus(this.task.TaskBoardStatus);

        this.IsSelected(false);
        this.TaskJson(JSON.stringify(this.task));

        this.Icon = ko.computed(() => {
            switch (this.TaskBoardStatus()) {
                case -1:
                    return "fa-inbox";
                case 0:
                    return "fa-tachometer";
                case 1:
                    return "fa-bolt";
                case 2:
                    return "fa-flag-checkered";
                case 3:
                    return "fa-check";
                case 4:
                    return "fa-clock-o";
                case 5:
                    return "fa-trash-o";
                default:
                    return "";
            }
        });
    }

    public Select(task: Task, evt: MouseEvent): void {
        const multiple = evt && (evt.ctrlKey || evt.metaKey);
        this.workflow.SelectTask(this, multiple);
    }

    public UpdateFrom(task: ITaskForTaskBoard): void {
        this.task = task;

        this.TaskId = this.task.Id;
        this.Title(this.task.Title);
        this.Amount(this.task.Multiplier);
        this.UnitOfMeasure(this.task.MultiplierUnitOfMeasure);

        this.TaskBoardStatus(this.task.TaskBoardStatus);

        this.TaskJson(JSON.stringify(this.task));
    }

    public async OnTaskDropped(dataTransfer: DataTransfer /*event, ui*/): Promise<void> {
        /*var task: ITaskForTaskBoard = ui.draggable.data("task");

        this.checkTasksCompatibility(task, this.task)
            .then((result: boolean) => {
                if (!result) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.JobOrder.CannotMergeTasksError);
                    return;
                }

                this.todoListService.MergeTasks(task.Id, this.task.Id)
                    ;
            });*/

        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.CannotMergeMultipleTasks);
            return;
        }

        const task: ITaskForTaskBoard = JSON.parse(dataTransfer.getData("application/prolife-task"));

        const canMerge: boolean = await this.editor.TasksMergeConfirm();
        if (!canMerge) return;

        if (task.Id === this.task.Id) return;

        if (!this.editor.ValidateDrop(task)) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.InvalidCompanyOrJobOrder);
            return;
        }

        const result: boolean = await this.checkTasksCompatibility(task, this.task);
        if (!result) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.JobOrder.CannotMergeTasksError);
            return;
        }

        await this.todoListService.MergeTasks(task.Id, this.task.Id);
        this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.TasksMergeSuccess);
    }

    public GetData(): ITaskForTaskBoard {
        return this.task;
    }

    private checkTasksCompatibility(sourceTask: ITaskForTaskBoard, targetTask: ITaskForTaskBoard): Promise<boolean> {
        return this.todoListService.CheckTasksCompatibilityForMerge(sourceTask.Id, targetTask.Id);
    }
}

class TasksMergeConfirmDialog implements IDialog {
    public templateName: string = "tasks-merge-confirm-dialog";
    public templateUrl: string = "joborder/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.JobOrder.TasksMergeConfirmTitle;

    public Prompt: ko.Observable<string> = ko.observable();

    public modal?: { close: (result?: any) => void };

    constructor(private editor: ProductionActivitiesPrintDialog) {
        this.Prompt(ProlifeSdk.TextResources.JobOrder.TasksMergeConfirmText);
    }

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        this.modal.close(true);
    }

    public persistentAction(): void {
        this.editor.TasksMergeConfirmRequested = false;
        this.modal.close(true);
    }
}
