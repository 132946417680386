import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/03/2019
 * Time: 11:57
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListFolder } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITaskStatusIconHelper } from "../../../../../ProlifeSdk/interfaces/todolist/ITaskStatusIconHelper";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";

export class TaskStatusIconHelper implements ITaskStatusIconHelper {
    public TaskStatusIcon: ko.Computed<string>;
    public TaskBoardStatus: ko.Observable<number> = ko.observable();
    public TaskStatusLabel: ko.Observable<string> = ko.observable();

    public Legend: ITodoListFolder[] = [];

    private todoListService: ITodoListService;

    constructor(private serviceLocator: IServiceLocator) {
        this.todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.Legend = this.todoListService.GetTasksFolders().filter((f) => f.Id != null);

        this.TaskStatusIcon = ko.computed(() => {
            let match = this.Legend.filter((f: ITodoListFolder) => f.Id == this.TaskBoardStatus());
            let status = match.length == 0 ? null : match[0];

            if (!status) {
                this.TaskStatusLabel(null);
                return "";
            }

            this.TaskStatusLabel(status.title);
            return status.icon;
        });
    }
}