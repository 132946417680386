import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { Proxy } from "../../../../../../../ProlifeSdk/prolifesdk/utils/Proxy";
import { ServiceTypes } from "../../../../../../../Core/enumerations/ServiceTypes";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { WorkflowProvider } from "./WorkflowProvider";
import { WorkflowEditDialog } from "../../../../workflows/WorkflowEditDialog";
import { ContextMenuDialog } from "../../../../../../../ProlifeSdk/prolifesdk/ContextMenuDialog";
import { WorkflowWithTasksProvider } from "./WorkflowWithTasksProvider";
import { IWorkflowOutcome } from "../../../../../../WorkflowOutcomesService";
import { IWorkflowOutcomeSettingsManager } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { ITodoListWorkflow, IWorkflowSelectorObserver, ITodoListWorkflowForList } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowNavigatorOptions } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IWorkflowEditorResult } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../../../Core/interfaces/IAuthorizationService";
import { ISettingsService } from "../../../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IChangesNotificationsService, IObjectChangesInfo } from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { INavigationMenuAction, INavigationMenuProvider } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { IProxy } from "../../../../../../../ProlifeSdk/interfaces/IProxy";
import { IWorkflowStatesSettingsManager, IWorkflowState } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { IWorkflowProvider } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowProvider";
import { Deferred } from "../../../../../../../Core/Deferred";

export class RootNavigationProvider extends WorkflowsNavigatorProvider {
    public EditMode: ko.Observable<boolean> = ko.observable(false);
    public Groups: ko.ObservableArray<WorkflowStateGroup> = ko.observableArray();

    private jobOrderIds: number[] = [];
    private changesNotificationsService: IChangesNotificationsService;

    private authorizationsService: IAuthorizationService;
    private SwitchSelectionModeAction: INavigationMenuAction;
    private CreateNewWorkflowAction: INavigationMenuAction;
    private CreateNewWorkflowFromWorkflowAction: INavigationMenuAction;
    private CreateNewWorkflowFromTemplateAction: INavigationMenuAction;

    private SwitchEditAction: INavigationMenuAction;
    private WorkflowSelectionEnabled: ko.Observable<boolean> = ko.observable(false);
    private outcomesSettings: IWorkflowOutcomeSettingsManager;

    private refreshItemsProxy: IProxy<void>;

    constructor(serviceLocator: IServiceLocator, protected navigatorOptions: IWorkflowNavigatorOptions) {
        super(serviceLocator);

        this.refreshItemsProxy = new Proxy("", this.internalRefreshItems, this, 0);

        this.authorizationsService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);

        const settingsManager = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.outcomesSettings = <IWorkflowOutcomeSettingsManager>settingsManager.findSettingsManager(ProlifeSdk.WorkflowOutcomes);

        this.HasMultipleSearchModes = true;

        this.SearchModes = [
            {
                Text: ProlifeSdk.TextResources.Todolist.SearchWorkflows,
                Placeholder: ProlifeSdk.TextResources.Todolist.SearchWorkflowsPlaceholder,
                MultiLevelSearch: false,
            },
            {
                Text: ProlifeSdk.TextResources.Todolist.SearchTasks,
                Placeholder: ProlifeSdk.TextResources.Todolist.SearchTasksPlaceholder,
                MultiLevelSearch: true,
            },
        ];

        this.CurrentSearchMode(this.SearchModes[1]);

        this.changesNotificationsService = <IChangesNotificationsService>serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);
        this.Name = ProlifeSdk.TextResources.Todolist.Workflows;
        this.AllowMultiLevelSearch = !this.navigatorOptions.DisableTasksNavigation;

        this.CreateNewWorkflowFromTemplateAction = {
            IsSeparator: false,
            Text: ko.observable(ProlifeSdk.TextResources.Todolist.CreateFromModel),
            Icon: ko.observable("fa fa-magic"),
            Action: this.CreateNewWorkflowFromTemplate.bind(this),
        };

        this.SwitchSelectionModeAction = {
            IsSeparator: false,
            Text: ko.observable(ProlifeSdk.TextResources.Todolist.SelectWorkflows),
            Icon: ko.observable("fa fa-location-arrow"),
            Action: this.SwitchEnableWorkflowSelection.bind(this),
        };

        this.SwitchEditAction = {
            IsSeparator: false,
            Text: ko.observable(ProlifeSdk.TextResources.Todolist.Edit),
            Icon: ko.observable("fa fa-pencil"),
            Action: this.SwitchEditMode.bind(this),
        };

        this.CreateNewWorkflowAction = {
            IsSeparator: false,
            Text: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflow),
            Icon: ko.observable("fa fa-plus"),
            Action: this.CreateNewWorkflow.bind(this),
        };

        this.CreateNewWorkflowFromWorkflowAction = {
            IsSeparator: false,
            Text: ko.observable(ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow),
            Icon: ko.observable("fa fa-copy"),
            Action: this.CreateNewWorkflowFromWorkflow.bind(this),
        };

        const settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const workflowStatesSettings = <IWorkflowStatesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.WorkflowStates);
        const states = workflowStatesSettings.getStates(false);
        this.Groups(
            states.map((s: IWorkflowState) => {
                return new WorkflowStateGroup(s.Description, s.Id, s.LogicalStatus != 1, this);
            })
        );

        this.PrepareMenuActions();
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        const newJobOrderId: number = changesInfo.Changes.NewStatus ? changesInfo.Changes.NewStatus.JobOrderId : null;
        const oldJobOrderId: number = changesInfo.Changes.OldStatus ? changesInfo.Changes.OldStatus.JobOrderId : null;

        if (changesInfo.Action == 2) {
            const providerMatching = this.Items().filter((p: IWorkflowProvider) => p.WorkflowId == changesInfo.EntityKeyId);
            if (providerMatching.length > 0) providerMatching[0].removeFromSelection(true);
        }

        if (this.jobOrderIds.indexOf(oldJobOrderId) > -1 || this.jobOrderIds.indexOf(newJobOrderId) > -1) this.refreshItems();
        return false;
    }

    public Dispose() {
        this.changesNotificationsService.RemoveObserver(this);
        this.Items().forEach((i: any) => {
            i.Dispose();
        });
        this.Items([]);
    }

    private SwitchEditMode() {
        this.EditMode(!this.EditMode());
        this.SwitchEditAction.Text(this.EditMode() ? ProlifeSdk.TextResources.Todolist.EndEditing : ProlifeSdk.TextResources.Todolist.Edit);
        this.SwitchEditAction.Icon(this.EditMode() ? "fa fa-undo" : "fa fa-pencil");
        this.PrepareMenuActions();
    }

    public OnWorkflowDeleted(workflowItem: WorkflowProvider) {
        this.Items.remove(workflowItem);

        if (this.navigator()) this.navigator().OnWorkflowDeleted(workflowItem.WorkflowId);
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {
        if (this.navigator()) this.navigator().OnWorkflowUpdate(workflow);
    }

    public OnWorkflowSelectionChanged() {
        const selectedIds: number[] = this.Items()
            .filter((i: any) => {
                return i.IsSelected();
            })
            .map((i: any) => {
                return i.WorkflowId;
            });

        if (this.navigator()) this.navigator().OnWorkflowSelectionChanged(selectedIds);
    }

    private PrepareMenuActions() {
        this.MenuActions([]);
        this.SecondMenuActions([]);

        //Nel caso di navigazione verso task aggiungo una action che mi permette di fare switch tra navigazione e selezione
        if (!this.navigatorOptions.DisableTasksNavigation && !this.navigatorOptions.DisableWorkflowSelection)
            this.SecondMenuActions.push(this.SwitchSelectionModeAction);

        if (this.jobOrderIds.length == 1 && this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode)) {
            if (this.MenuActions().length > 0) this.MenuActions.push({ IsSeparator: true });

            if (this.SecondMenuActions().length > 0) this.SecondMenuActions.push({ IsSeparator: true });

            if (!this.navigatorOptions.DisableWorkflowEditing) {
                this.SecondMenuActions.push(this.SwitchEditAction);

                if (!this.EditMode()) {
                    if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow")) this.MenuActions.push(this.CreateNewWorkflowAction);

                    if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow")) this.MenuActions.push(this.CreateNewWorkflowFromWorkflowAction);

                    if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"))
                        this.MenuActions.push(this.CreateNewWorkflowFromTemplateAction);
                }
            }
        }
    }

    public CreateNewWorkflowFromTemplate() {
        const jobOrderId = this.jobOrderIds.length == 1 ? this.jobOrderIds[0] : null;

        this.todoListService.ShowCreateWorkflowFormTemplateDialog(jobOrderId).then((result) => {
            if (!result) return;

            this.refreshItems().then(() => {
                this.navigator().observers.forEach((o: IWorkflowSelectorObserver) => {
                    o.OnWorkflowImportedFromTemplate(result.Id);
                });
            });
        });
    }

    public CreateNewWorkflow() {
        const notInitializedStates = this.outcomesSettings.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: this.jobOrderIds.length == 1 ? this.jobOrderIds[0] : null,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const vm: WorkflowEditDialog = new WorkflowEditDialog({ workflow: workflow });
        vm.ShowDialog().then((result: IWorkflowEditorResult) => {
            if (!result) return;

            this.refreshItems().then(() => {
                this.navigator().observers.forEach((o: IWorkflowSelectorObserver) => {
                    o.OnWorkflowImportedFromTemplate(result.Workflow.Id);
                });
            });
        });
    }

    CreateNewWorkflowFromWorkflow() {
        if (this.jobOrderIds.length == 0) return;

        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.jobOrderIds[0]);
    }

    SwitchEnableWorkflowSelection() {
        this.WorkflowSelectionEnabled(!this.WorkflowSelectionEnabled());
        this.SwitchSelectionModeAction.Text(
            this.WorkflowSelectionEnabled() ? ProlifeSdk.TextResources.Todolist.BrowseWorkflows : ProlifeSdk.TextResources.Todolist.SelectWorkflows
        );
        this.SwitchSelectionModeAction.Icon(this.WorkflowSelectionEnabled() ? "fa fa-level-down" : "fa fa-location-arrow");
    }

    openContextMenuDialog() {
        const dialog: ContextMenuDialog = new ContextMenuDialog(this.serviceLocator, this.MenuActions());
        dialog.ShowModal();
    }

    SetJobOrderIds(jobOrderIds: number[]) {
        this.jobOrderIds = jobOrderIds;
        this.PrepareMenuActions();
    }

    search(textFilter: string): Promise<void> {
        const resultDef = new Deferred<void>();

        const defs: Promise<any>[] = [];
        let results: any[] = [];
        this.Items().forEach((p) => {
            defs.push(p.search(textFilter));
        });

        Promise.all(defs).then(() => {
            results =
                this.AllowMultiLevelSearch && this.CurrentSearchMode().MultiLevelSearch
                    ? this.Items().map((p) => {
                          return { title: p.Name, results: p.SearchedItems() };
                      })
                    : this.Items().filter((t: WorkflowProvider) => {
                          return (textFilter || "").length == 0 || t.Title().toUpperCase().indexOf(textFilter.toUpperCase()) > -1;
                      });
            this.SearchedItems(results);
            resultDef.resolve();
        });

        return resultDef.promise();
    }

    protected CreateItemViewModel(t: ITodoListWorkflowForList, jobOrderId: number): INavigationMenuProvider {
        const p: INavigationMenuProvider = !this.navigatorOptions.DisableTasksNavigation
            ? new WorkflowWithTasksProvider(this.serviceLocator, t, this.WorkflowSelectionEnabled, jobOrderId, this, this.navigatorOptions)
            : new WorkflowProvider(this.serviceLocator, t, this);
        p.setCurrentNavigator(this.navigator());
        return p;
    }

    public RefreshWorkflow(id: number) {
        const matches = <any[]>this.Items().filter((o: any) => {
            return o.WorkflowId == id;
        });

        if (matches.length > 0) matches[0].RefreshFromServer();
    }

    refreshItems(): Promise<void> {
        return this.refreshItemsProxy.get();
    }

    private internalRefreshItems(): Promise<void> {
        const d = new Deferred<void>();

        const oldItems = this.Items();

        if (this.jobOrderIds.length == 0) {
            this.Items([]);
            d.resolve();
            return d.promise();
        }

        const jobOrdersDefs: Promise<void>[] = [];
        const workflowsItems = [];

        this.jobOrderIds.forEach((jobOrderId: number) => {
            const joDef = new Deferred<void>();
            jobOrdersDefs.push(joDef.promise());

            this.todoListService.GetWorkflowsForList(jobOrderId, "", false).then((workflows: ITodoListWorkflowForList[]) => {
                workflows.forEach((w: ITodoListWorkflowForList) => {
                    const oldItem: WorkflowProvider[] = <WorkflowProvider[]>oldItems.filter((o: any) => {
                        return o.WorkflowId == w.Id;
                    });
                    const p: INavigationMenuProvider = oldItem.length > 0 ? oldItem[0] : this.CreateItemViewModel(w, jobOrderId);
                    (<any>p).RefreshData(w);
                    workflowsItems.push(p);
                });

                //Dispongo gli items rimossi
                oldItems
                    .filter((i: WorkflowProvider) => {
                        return workflowsItems.indexOf(i) == -1;
                    })
                    .forEach((toDispose: WorkflowProvider) => {
                        toDispose.Dispose();
                    });

                this.Items(workflowsItems);
                joDef.resolve();
            });
        });

        Promise.all(jobOrdersDefs).then(() => {
            d.resolve();
        });

        return d.promise();
    }

    GetSelectedTasksIds(): number[] {
        if (this.navigatorOptions.DisableTasksNavigation) return [];

        const ids: number[] = [];
        this.Items().forEach((p: WorkflowWithTasksProvider) => {
            p.GetSelectedTasksIds().forEach((id) => {
                ids.push(id);
            });
        });
        return ids;
    }

    SetSelectedTasks(ids: number[]): Promise<void> {
        const def = new Deferred<void>();

        if (this.navigatorOptions.DisableTasksNavigation) return def.resolve().promise();

        const itemsDefs: Promise<any>[] = [];

        this.Items().forEach((p: WorkflowWithTasksProvider) => {
            itemsDefs.push(p.SetSelectedTasks(ids));
        });

        Promise.all(itemsDefs).then(() => {
            def.resolve();
        });
        return def.promise();
    }

    public GetNavigatorOptions(): IWorkflowNavigatorOptions {
        return this.navigatorOptions;
    }

    public GetWorkflowSelectionState(): ko.Observable<boolean> {
        return this.WorkflowSelectionEnabled;
    }
}

class WorkflowStateGroup {
    public Items: ko.Computed<WorkflowProvider[]>;
    public Title: ko.Computed<string>;
    public HasItems: ko.Computed<boolean>;
    public ItemsCount: ko.Computed<number>;
    public EditMode: ko.Observable<boolean>;
    public Collapsed: ko.Observable<boolean> = ko.observable(false);

    constructor(state: string, stateId: number, collapsed: boolean, provider: RootNavigationProvider) {
        this.Items = ko.computed(() => {
            return <WorkflowProvider[]>provider.Items().filter((i: WorkflowProvider) => i.StateId() == stateId);
        });

        this.ItemsCount = ko.computed(() => {
            return this.Items().length;
        });

        this.HasItems = ko.computed(() => {
            return this.ItemsCount() > 0;
        });

        this.Title = ko.computed(() => {
            return state + " (" + this.ItemsCount() + ")";
        });

        this.EditMode = provider.EditMode;

        this.Collapsed(collapsed);
    }

    public SwitchCollapsed() {
        this.Collapsed(!this.Collapsed());
    }
}
