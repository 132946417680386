import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { InvoicesReport } from "./InvoicesReport";
import { VatRegisterDocuments } from "./VatRegisterDocuments";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Deferred } from "../../../Core/Deferred";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";

export class DdtsReport extends InvoicesReport {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    defaultDescription: ko.Computed<string>;

    private AllRegisters: VatRegisterDocuments;

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(
            ProlifeSdk.TextResources.Invoices.Deliveries,
            "invoices/templates/reports",
            "ddt-report",
            groupId,
            3,
            "invoices-by-vat-register-report-filter",
            serviceLocator
        );

        const allRegs = {
            IdRegistroIVA: -1,
            NomeRegistroIVA: ProlifeSdk.TextResources.Invoices.AllProtocols,
        };
        this.AllRegisters = new VatRegisterDocuments(<IVatRegister>allRegs);
    }

    initialize() {
        this.commonInitialize();

        this.defaultDescription = ko.computed(() => {
            return this.selectedCategory() ? this.selectedCategory().register.NomeRegistroIVA : "";
        });
        this.loadRegisters();
        this.quickFilterByMonth(new Date().getMonth() + 1);

        this.selectedCategory().documents([]);
    }

    public ExportAsExcel(versionId: number) {
        if (!this.startDate() || !this.endDate() || !this.selectedCategory()) return;

        const registerId = this.selectedCategory().register.IdRegistroIVA;
        const url = "Invoices/DdtsReport/GenerateExcel?" + this.getQueryString(versionId, registerId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private getQueryString(versionId: number, registerId: number): string {
        return (
            "versionId=" +
            versionId +
            "&start=" +
            encodeURIComponent(moment(this.startDate()).format()) +
            "&end=" +
            encodeURIComponent(moment(this.endDate()).format()) +
            "&protocolId=" +
            registerId
        );
    }

    public ExportAsPdf(versionId: number) {
        if (!this.startDate() || !this.endDate() || !this.selectedCategory()) return;

        const registerId = this.selectedCategory().register.IdRegistroIVA;
        const url = "Invoices/DdtsReport/Generate?" + this.getQueryString(versionId, registerId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    loadRegisters() {
        const registers = [this.AllRegisters];
        this.vatRegistersService.getVatRegisters().forEach((r: IVatRegister) => {
            if (r.TipoDocumento == 1) registers.push(new VatRegisterDocuments(r));
        });

        this.categories(registers);
        this.selectedCategory(this.categories()[0]);
        this.Refresh();
    }

    getCategoryDescription(register: VatRegisterDocuments) {
        return register.register.NomeRegistroIVA;
    }

    onCategoryChanged(register: any) {
        this.selectedCategory(register);
    }

    clearRegisters() {
        this.categories().forEach((r) => {
            r.documents([]);
        });
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        this.clearRegisters();
        this.noResultsFound(true);

        if (!this.startDate() || !this.endDate()) return def.resolve().promise();

        const registerId = this.selectedCategory().register.IdRegistroIVA;
        this.invoicesService
            .getDdtsByProtocolAndDates(registerId, this.startDate(), this.endDate())
            .then(this.onLoadDocumentsSucceeded.bind(this))
            .catch(this.onLoadDocumentsFailed.bind(this))
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }
}
