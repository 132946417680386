import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../../Core/utils/DialogComponentBase";
import { PreOrdersDataSource } from "../../../../../DataSources/PreOrdersDataSource";
import { PreOrdersRowsDataSource } from "../../../../../DataSources/PreOrdersRowsDataSource";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type RequestForQuotationRowInfoPopoverProps = {
    preOrderId: number;
    preOrderRowId: number;
}

export function RequestForQuotationRowInfoPopover(props: RequestForQuotationRowInfoPopoverProps) {
    const C = require("./RequestForQuotationRowInfoPopover")._RequestForQuotationRowInfoPopover as typeof _RequestForQuotationRowInfoPopover;
    return <C {...props} />;
}

export class _RequestForQuotationRowInfoPopover extends DialogComponentBase {
    static defaultProps: Partial<RequestForQuotationRowInfoPopoverProps> = {
    }

    PreOrdersDataSource = new PreOrdersDataSource();
    PreOrdersRowsDataSource = new PreOrdersRowsDataSource();

    PreOrder : ko.Observable<string> = ko.observable();
    PreOrderRow : ko.Observable<string> = ko.observable();

    constructor(private props : RequestForQuotationRowInfoPopoverProps) {
        super({ popover: true });
        this.title(TextResources.Provisioning.RequestForQuotationRowInfo);
    }

    componentDidMount() {
        const load = async () => {
            const [preOrder] = await this.PreOrdersDataSource.getById(null, [this.props.preOrderId]);
            const [preOrderRow] = await this.PreOrdersRowsDataSource.getById(null, [this.props.preOrderRowId]);

            this.PreOrder(preOrder?.title);
            this.PreOrderRow(preOrderRow?.title);
        };
        load();
    }
    
    close() {
        this.modal.close(true);
    }
    
    renderBody() {
        const _rfqri = this;

        return  ComponentUtils.bindTo(
            <div className="form-horizontal">
                <div class="form-group">
                    <label class="col-md-3 control-label">Preordine</label>
                    <div class="col-md-9">
                        <a href={'#/Provisioning/PreOrders/' + _rfqri.props.preOrderId } class="form-control-static" data-bind={{ text: _rfqri.PreOrder }} />
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-md-3 control-label">Riga</label>
                    <div class="col-md-9">
                        <p class="form-control-static" data-bind={{ text: _rfqri.PreOrderRow }} />
                    </div>
                </div>
            </div>
        , this, "_rfqri");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RequestForQuotationRowInfoPopover);
}