import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/02/2019
 * Time: 10:28
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";

export class SchedulesRebuildRequestDialog implements IDialog {
    public templateName: string = "schedules-rebuild-request";
    public templateUrl: string = "invoices/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Invoices.SchedulesRebuildRequestTitle;

    public modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
    }

    public close(): void {
        this.modal.close(0);
    }

    public action(): void {

    }

    public show(): Promise<number> {
        return this.dialogsService.ShowModal<number>(this, null, null, this.templateUrl, this.templateName);
    }

    public closeWithValue(value: number): void {
        this.modal.close(value);
    }
}