import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    assignmentToWorkflowsState: {
        alignItems: "center",
        height: "26px", fontWeight: 300,

        "& .icon": {
            textAlign: "center",
            height: "18px",
            width: "18px",
            lineHeight: "18px"
        },

        "& .description": {
            height: "26px",
            lineHeight: "26px",

            "&.inline": {
                alignItems: "center"
            }
        }
    }
});
const { classes } = styleSheet.attach();

type AssignmentToWorkflowsStateProps = {
    state: number;
}

export function AssignmentToWorkflowsState(props: AssignmentToWorkflowsStateProps) {
    const C = require("./AssignmentToWorkflowsState")._AssignmentToWorkflowsState as typeof _AssignmentToWorkflowsState;
    return <C {...props} />;
}

export class _AssignmentToWorkflowsState {
    static defaultProps: Partial<AssignmentToWorkflowsStateProps> = {}

    private label: string;
    private stateColor: string;
    private foreColor: string;

    constructor(private props : AssignmentToWorkflowsStateProps) {
        this.label = this.getAssignmentToWorkflowsStateLabel();
        this.stateColor = this.getStateColor();
        this.foreColor = this.getForeColor();
    }

    private getAssignmentToWorkflowsStateLabel(): string {
        if (this.props.state === 0)
            return TextResources.Invoices.NotAssignedToWorkflowsState;

        if (this.props.state === 1)
            return TextResources.Invoices.PartiallyAssignedToWorkflowsState;
        
        if (this.props.state === 2)
            return TextResources.Invoices.FullyAssignedToWorkflowsState;

        if (this.props.state === 3)
            return TextResources.Invoices.MaxedOutAssignedToWorkflowsState;

        return "";
    }
    
    private getStateColor(): string {
        if (this.props.state === 0)
            return "transparent";

        if (this.props.state === 1)
            return "#ecbc29";

        if (this.props.state === 2)
            return "#45b6af";
        
        if (this.props.state === 3)
            return "red";

        return "";
    }

    private getForeColor(): string {
        if (this.props.state === 0)
            return "black";

        if (this.props.state === 1)
            return "white";

        if (this.props.state === 2)
            return "white";
        
        if (this.props.state === 3)
            return "white";

        return "";
    }

    render() {
        const classNames = ComponentUtils.classNames("flex-container", classes.assignmentToWorkflowsState);

        return  <div className={classNames}>
                    <div className="icon" style={{ backgroundColor: this.stateColor, color: this.foreColor }}></div>
                    <div className="flex-fill description inline">
                        {this.label}
                    </div>
                </div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AssignmentToWorkflowsState);
}