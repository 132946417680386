import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 16/02/2018
 * Time: 14:55
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { ICategoryEditingPanel, ICategoryEditingViewModel, IEventCategoryViewModel, ICategoryLogicalState, IFestivityCategoryViewModel, IFolderForSettingManagerViewModel } from "../../../../interfaces/ICategoriesSettingsManager";
import { ICategoriesSettingsManager } from "../../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { IEventCategory } from "../../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IFestivityCategory } from "../../../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IFolder } from "../../../../../ProlifeSdk/interfaces/agenda/IFolderService";

interface IPanelMap {
    [panel: string]: ICategoryEditingPanel;
}

export class CategoriesEditingViewModel implements ICategoryEditingViewModel {
    public Title: string = ProlifeSdk.TextResources.Agenda.CategoriesEditorTitle;

    public FoldersViewActive: ko.Observable<boolean> = ko.observable(true);
    public EventsViewActive: ko.Observable<boolean> = ko.observable(false);
    public FestivitiesViewActive: ko.Observable<boolean> = ko.observable(false);

    public EditingPanel: ko.Observable<any> = ko.observable();

    private PanelsMap: IPanelMap = {};

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ICategoriesSettingsManager) {
        this.PanelsMap["agendas"] = new AgendasFoldersEditingPanel(this.serviceLocator, this.settingsManager);
        this.PanelsMap["events"] = new EventsCategoryEditingPanel(this.serviceLocator, this.settingsManager);
        this.PanelsMap["festivities"] = new FestivitiesCategoryEditingPanel(this.serviceLocator, this.settingsManager);

        this.EditingPanel(this.PanelsMap["agendas"]);
    }

    public ShowFolders(): void {
        this.FoldersViewActive(true);
        this.EventsViewActive(false);
        this.FestivitiesViewActive(false);
        this.EditingPanel(this.PanelsMap["agendas"]);
    }

    public ShowEvents(): void {
        this.FoldersViewActive(false);
        this.EventsViewActive(true);
        this.FestivitiesViewActive(false);
        this.EditingPanel(this.PanelsMap["events"]);
    }

    public ShowFestivities(): void {
        this.FoldersViewActive(false);
        this.EventsViewActive(false);
        this.FestivitiesViewActive(true);
        this.EditingPanel(this.PanelsMap["festivities"]);
    }
}

class EventCategoryViewModel implements IEventCategoryViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public LogicalState: ko.Observable<number> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();

    public SelectedStateLabel: ko.Observable<string> = ko.observable();
    public LogicalStates: ICategoryLogicalState[] = [];

    private updating: boolean = false;
    private lastLabel: string = "";

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private category: IEventCategory, private editorPanel: ICategoryEditingPanel) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        //this.LogicalStates.push({ Id: ProlifeSdk.LogicalStateNotSet, Label: ProlifeSdk.TextResources.Agenda.LogicalStateNotSet });
        this.LogicalStates.push({ Id: ProlifeSdk.PlannedEventState, Label: ProlifeSdk.TextResources.Agenda.PlannedEventState });
        this.LogicalStates.push({ Id: ProlifeSdk.ConfirmedEventState, Label: ProlifeSdk.TextResources.Agenda.ConfirmedEventState });
        this.LogicalStates.push({ Id: ProlifeSdk.WaitingListEventState, Label: ProlifeSdk.TextResources.Agenda.WaitingListEventState });
        this.LogicalStates.push({ Id: ProlifeSdk.AbortedEventState, Label: ProlifeSdk.TextResources.Agenda.AbortedEventState });

        this.LogicalState.subscribe((stateId: number) => {
            var states = this.LogicalStates.filter((s: ICategoryLogicalState) => s.Id == stateId);
            this.SelectedStateLabel(states.length == 0 ? ProlifeSdk.TextResources.Agenda.LogicalStateNotSet : states[0].Label);
        });

        this.loadDataFromCategoryModel();

        this.Label.subscribe(this.OnDataChanged.bind(this));
        this.Color.subscribe(this.OnDataChanged.bind(this));
        this.LogicalState.subscribe(this.OnDataChanged.bind(this));
    }

    public SetLogicalState(stateId: number): void {
        this.LogicalState(stateId);
    }

    public Delete(): void {
        this.editorPanel.RemoveCategory(this.category);
    }

    public GetData(): IEventCategory {
        return {
            Id: this.Id(),
            Label: this.Label(),
            Color: this.Color(),
            Order: this.Order(),
            LogicalState: this.LogicalState()
        };
    }

    private OnDataChanged(): void {
        if (this.updating)
            return;

        this.updating = true;

        if (!this.Label()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.CategoryNameCannotBeEmpty);
            this.Label(this.lastLabel);
            this.updating = false;
            return;
        }

        this.editorPanel.UpdateCategory(this.GetData())
            .then((category: IEventCategory) => {
                this.category = category;
                this.lastLabel = this.Label();
            })
            .catch(() => {
                this.loadDataFromCategoryModel();
            })
            .finally(() => {
                this.updating = false;
            });
    }

    private loadDataFromCategoryModel(): void {
        this.Id(this.category.Id);
        this.Label(this.category.Label);
        this.Color(this.category.Color);
        this.LogicalState(this.category.LogicalState);
        this.Order(this.category.Order);

        this.lastLabel = this.category.Label;
    }
}

class FestivityCategoryViewModel implements IFestivityCategoryViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();

    public Locked: boolean;

    private lastLabel: string;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private category: IFestivityCategory, private editorPanel: ICategoryEditingPanel) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Id(this.category.Id);
        this.Label(this.category.Label);
        this.Color(this.category.Color);
        this.Order(this.category.Order);

        this.Locked = this.category.Locked;
        this.lastLabel = this.category.Label;

        var updating = false;
        this.Label.subscribe((newValue: string) => {
            if (!updating) {
                updating = true;
                if (!this.Label()) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.CategoryNameCannotBeEmpty);
                    this.Label(this.lastLabel);
                    updating = false;
                    return;
                }
                this.lastLabel = newValue;
                this.OnDataChanged();
                updating = false;
            }
        });
        this.Color.subscribe(this.OnDataChanged.bind(this));
    }

    public Delete(): void {
        this.editorPanel.RemoveCategory(this.category);
    }

    public GetData(): IFestivityCategory {
        return {
            Id: this.Id(),
            Label: this.Label(),
            Color: this.Color(),
            Order: this.Order(),
            Locked: this.Locked
        };
    }

    private OnDataChanged(): void {
        this.editorPanel.UpdateCategory(this.GetData())
            .then((category: IFestivityCategory) => {
                this.category = category;
            });
    }
}

class FolderForSettingManagerViewModel implements IFolderForSettingManagerViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Name: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public CreatedBy: ko.Observable<number> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public ModifiedBy: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable();
    public Expanded: ko.Observable<boolean> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();

    private updating: boolean = false;
    private lastLabel: string = "";
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private category: IFolder, private editorPanel: ICategoryEditingPanel) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Id(this.category.Id);
        this.Name(this.category.Name);
        this.Icon(this.category.Icon);
        this.Background(this.category.Background);
        this.Foreground(this.category.Foreground);
        this.CreatedBy(this.category.CreatedBy);
        this.CreationDate(this.category.CreationDate);
        this.ModifiedBy(this.category.ModifiedBy);
        this.ModifyDate(this.category.ModifyDate);
        this.Deleted(this.category.Deleted);
        this.Expanded(this.category.Expanded);
        this.Order(this.category.Order);

        this.lastLabel = this.Name();

        this.Name.subscribe(this.OnDataChanged.bind(this));
        this.Icon.subscribe(this.OnDataChanged.bind(this));
        this.Background.subscribe(this.OnDataChanged.bind(this));
        this.Foreground.subscribe(this.OnDataChanged.bind(this));
        this.Expanded.subscribe(this.OnDataChanged.bind(this));
    }

    public Delete(): void {
        this.editorPanel.RemoveCategory(this.category);
    }

    public GetData(): IFolder {
        return {
            Id: this.Id(),
            Name: this.Name(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            CreatedBy: this.CreatedBy(),
            CreationDate: this.CreationDate(),
            ModifiedBy: this.ModifiedBy(),
            ModifyDate: this.ModifyDate(),
            Deleted: this.Deleted(),
            Expanded: this.Expanded(),
            Order: this.Order()
        };
    }

    private OnDataChanged(): void {
        if (this.updating)
            return;

        this.updating = true;

        if (!this.Name()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.CategoryNameCannotBeEmpty);
            this.Name(this.lastLabel);
            this.updating = false;
            return;
        }

        this.editorPanel.UpdateCategory(this.GetData())
            .then((category: IFolder) => {
                this.category = category;
                this.lastLabel = this.Name();
                this.updating = false;
            });
    }
}

class AgendasFoldersEditingPanel implements ICategoryEditingPanel {
    public TemplateUrl: string = "agenda/templates/settings/categorieseditingpanels";
    public TemplateName: string = "folders-panel";
    public Categories: ko.ObservableArray<IFolderForSettingManagerViewModel> = ko.observableArray([]);

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ICategoriesSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.LoadCategories();
    }

    public CreateNewCategory(): void {
        this.settingsManager.addOrUpdateFolder(this.CreateFolder())
            .then((category: IFolder) => {
                this.Categories.push(this.CreateFolderViewModel(category));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CreateCategoryError);
            });
    }

    public UpdateCategory(category: IFolder): Promise<IFolder> {
        return this.settingsManager.addOrUpdateFolder(category)
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.UpdateCategoryError);
                return null;
            });
    }

    public RemoveCategory(category: IFolder): void {
        this.settingsManager.removeFolder(category.Id)
            .then((folders: IFolder[]) => {
                this.Categories(folders.map((f: IFolder) => { return this.CreateFolderViewModel(f); }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RemoveCategoryError);
            });
    }

    public OnCategoryMoved(beforeId: number, movedId: number, afterId: number): void {
        var moved : IFolderForSettingManagerViewModel[] = this.Categories().filter((s) => { return s.Id() == movedId; });
        var before : IFolderForSettingManagerViewModel[] = this.Categories().filter((s) => { return s.Id() == beforeId; });
        var after : IFolderForSettingManagerViewModel[]  = this.Categories().filter((s) => { return s.Id() == afterId; });

        if(before.length == 0 && after.length == 0)
            return;

        var offset : number = before.length == 0 || before[0].GetData().Order > moved[0].GetData().Order ? 0 : 1;

        var newPosition = before.length > 0 ? before[0].GetData().Order + offset : 1;
        this.settingsManager.moveFolder(movedId, newPosition)
            .then(() => {
                this.LoadCategories();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MoveCategoryError);
            });
    }

    private LoadCategories(): void {
        this.Categories(this.settingsManager.getFolders().map((c: IFolder) => { return this.CreateFolderViewModel(c); }));
    }

    private CreateFolder(): IFolder {
        return {
            Id: -1,
            Name: "",
            Icon: "icon-book-open",
            Background: "#000000",
            Foreground: "#FFFFFF",
            CreatedBy: null,
            CreationDate: null,
            ModifiedBy: null,
            ModifyDate: null,
            Deleted: false,
            Expanded: false,
            Order: this.Categories().length + 1
        };
    }

    private CreateFolderViewModel(category: IFolder): IFolderForSettingManagerViewModel {
        return new FolderForSettingManagerViewModel(this.serviceLocator, category, this);
    }
}

class EventsCategoryEditingPanel implements ICategoryEditingPanel {
    public TemplateUrl: string = "agenda/templates/settings/categorieseditingpanels";
    public TemplateName: string = "events-categories-panel";
    public Categories: ko.ObservableArray<IEventCategoryViewModel> = ko.observableArray([]);

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ICategoriesSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.LoadCategories();
    }

    public CreateNewCategory(): void {
        this.settingsManager.addOrUpdateCategory(this.CreateCategory())
            .then((category: IEventCategory) => {
                this.Categories.push(this.CreateCategoryViewModel(category));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CreateCategoryError);
            });
    }

    public UpdateCategory(category: IEventCategory): Promise<IEventCategory> {
        return this.settingsManager.addOrUpdateCategory(category)
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.UpdateCategoryError);
                return null;
            });
    }

    public RemoveCategory(category: IEventCategory): void {
        var logicalStatesCount = this.Categories().filter((c: IEventCategoryViewModel) => { return c.LogicalState() == category.LogicalState; }).length;

        if (logicalStatesCount < 2) {
            this.dialogsService.Alert(ProlifeSdk.TextResources.Agenda.CannotDeleteCategory, ProlifeSdk.TextResources.Agenda.CannotDeleteCategoryLabel, () => {});
            return;
        }

        this.settingsManager.removeCategory(category.Id)
            .then((categories: IEventCategory[]) => {
                this.Categories(categories.map((c: IEventCategory) => { return this.CreateCategoryViewModel(c); }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RemoveCategoryError);
            });
    }

    public OnCategoryMoved(beforeId: number, movedId: number, afterId: number): void {
        var moved : IEventCategoryViewModel[] = this.Categories().filter((s) => { return s.Id() == movedId; });
        var before : IEventCategoryViewModel[] = this.Categories().filter((s) => { return s.Id() == beforeId; });
        var after : IEventCategoryViewModel[]  = this.Categories().filter((s) => { return s.Id() == afterId; });

        if(before.length == 0 && after.length == 0)
            return;

        var offset : number = before.length == 0 || before[0].GetData().Order > moved[0].GetData().Order ? 0 : 1;

        var newPosition = before.length > 0 ? before[0].GetData().Order + offset : 1;
        this.settingsManager.moveEventCategory(movedId, newPosition)
            .then(() => {
                this.LoadCategories();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MoveCategoryError);
            });
    }

    private LoadCategories(): void {
        this.Categories(this.settingsManager.getCategories().map((c: IEventCategory) => { return this.CreateCategoryViewModel(c); }));
    }

    private CreateCategory(): IEventCategory {
        return {
            Id: -1,
            Label: ProlifeSdk.TextResources.Agenda.CategoryDefaultLabel,
            Color: "#000000",
            Order: -1,
            LogicalState: ProlifeSdk.PlannedEventState
        };
    }

    private CreateCategoryViewModel(category: IEventCategory): IEventCategoryViewModel {
        return new EventCategoryViewModel(this.serviceLocator, category, this);
    }
}

class FestivitiesCategoryEditingPanel implements ICategoryEditingPanel {
    public TemplateUrl: string = "agenda/templates/settings/categorieseditingpanels";
    public TemplateName: string = "festivities-categories-panel";

    public Categories: ko.ObservableArray<IFestivityCategoryViewModel> = ko.observableArray([]);

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ICategoriesSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.LoadCategories();
    }

    public CreateNewCategory(): void {
        this.settingsManager.addOrUpdateFestivityCategory(this.CreateCategory())
            .then((category: IFestivityCategory) => {
                this.Categories.push(this.CreateCategoryViewModel(category));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CreateCategoryError);
            });
    }

    public UpdateCategory(category: IFestivityCategory): Promise<IFestivityCategory> {
        return this.settingsManager.addOrUpdateFestivityCategory(category)
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.UpdateCategoryError);
                return null;
            });
    }

    public RemoveCategory(category: IFestivityCategory): void {
        if (category.Id == ProlifeSdk.FestivityCategoryId || category.Id == ProlifeSdk.ClosingDaysCategoryId) {
            this.dialogsService.Alert(ProlifeSdk.TextResources.Agenda.CannotDeleteFestivityCategory, ProlifeSdk.TextResources.Agenda.CannotDeleteCategoryLabel, () => {});
            return;
        }

        this.settingsManager.removeFestivityCategory(category.Id)
            .then((categories: IFestivityCategory[]) => {
                this.Categories(categories.map((c: IFestivityCategory) => { return this.CreateCategoryViewModel(c); }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RemoveCategoryError);
            });
    }

    public OnCategoryMoved(beforeId: number, movedId: number, afterId: number): void {
        var moved = this.Categories().filter((s) => { return s.Id() == movedId; });
        var before = this.Categories().filter((s) => { return s.Id() == beforeId; });
        var after  = this.Categories().filter((s) => { return s.Id() == afterId; });

        if(before.length == 0 && after.length == 0)
            return;

        var offset : number = before.length == 0 || before[0].GetData().Order > moved[0].GetData().Order ? 0 : 1;

        var newPosition = before.length > 0 ? before[0].GetData().Order + offset : 1;
        this.settingsManager.moveFestivityCategory(movedId, newPosition)
            .then(() => {
                this.LoadCategories();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MoveCategoryError);
            });
    }

    private LoadCategories(): void {
        this.Categories(this.settingsManager.getFestivitiesCategories().map((c: IFestivityCategory) => { return this.CreateCategoryViewModel(c); }));
    }

    private CreateCategoryViewModel(category: IFestivityCategory): IFestivityCategoryViewModel {
        return new FestivityCategoryViewModel(this.serviceLocator, category, this);
    }

    private CreateCategory(): IFestivityCategory {
        return {
            Id: -1,
            Label: ProlifeSdk.TextResources.Agenda.CategoryDefaultLabel,
            Color: "#000000",
            Order: -1,
            Locked: false
        };
    }
}