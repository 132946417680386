import * as parse from "color-parse";

type RGBAColor = {
    r: number,
    g: number,
    b: number,
    a: number
}

export function ParseColor(color): RGBAColor {
    const parsedColor = parse(color);
    return {
        r: parsedColor.values[0],
        g: parsedColor.values[1],
        b: parsedColor.values[2],
        a: parsedColor.alpha ?? 1
    }
}