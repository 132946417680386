import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ISettingsManager } from "../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { LazyImport } from "../../Core/DependencyInjection";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { Right } from "../../Core/Authorizations";
import { ITicketCategory, ITicketsService } from "../TicketsService";
import { TicketCategoriesSettingsComponent } from "./ui/TicketCategoriesSettingsComponent";

export interface ITicketCategoriesSettingsManager extends ISettingsManager {
    getTicketCategories(textFilter: string, showDeleted?: boolean, skip?: number, count?: number);
    getTicketCategoriesByIds(ids: number[]);
    insertOrUpdateTicketCategories(types: ITicketCategory[]);
}

export class TicketCategoriesSettingsManager implements ITicketCategoriesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    private ticketCategories: ITicketCategory[] = [];

    //@Right("Tickets_CanWriteTicketCategories")
    private canWriteTicketCategories: boolean = true;

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Tickets.Ticket);
    }

    public getTicketCategories(textFilter: string, showDeleted?: boolean, skip: number = 0, count: number = 1000) {
        return this.ticketCategories.filter(x => this.matchTextFilter(x, textFilter) && (showDeleted || x.Deleted === false)).slice(skip, skip + count);
    }

    public getTicketCategoriesByIds(ids: number[]) {
        return this.ticketCategories.filter(x => ids.any(i => i == x.Id));
    }

    public async insertOrUpdateTicketCategories(items: ITicketCategory[]){
        await this.ticketsService.InsertOrUpdateCategories(items);
        await this.load();
    }

    async load() {
        this.ticketCategories = await this.ticketsService.GetCategories(null, true, 0, null);
    }

    getName() {
        return nameof<ITicketCategoriesSettingsManager>();
    }

    getLabel() {
        return TextResources.Tickets.Categories;
    }

    hasEditingUI() {
        return this.canWriteTicketCategories;
    }

    getEditingUI() {
        return new TicketCategoriesSettingsComponent({TicketCategoriesSettingsManager: this});
    }

    private matchTextFilter(item: ITicketCategory, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (item.Title.toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}