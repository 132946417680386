import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 18:40
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { CategoriesProvider } from "./providers/CategoriesProvider";
import { UiUtilities } from "./utils/UiUtilities";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IFestivityViewModel } from "../../interfaces/IFestivityViewModel";
import { IFestivitiesService, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IFullCalendar, IFullCalendarViewObject, IFullCalendarSelectedInterval } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { Deferred } from "../../../Core/Deferred";

export class FestivityViewModel implements IFestivityViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public AgendaId: ko.Observable<number> = ko.observable();
    public CategoryId: ko.Observable<number> = ko.observable();
    public Note: ko.Observable<string> = ko.observable();
    public AllDay: ko.Observable<boolean> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public StartTime: ko.Observable<Date> = ko.observable();
    public EndTime: ko.Observable<Date> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public Deleted: ko.Observable<number> = ko.observable();

    public IsNew: ko.Computed<boolean>;

    public CategoriesProvider: CategoriesProvider;
    
    private lastStartTime: Date;
    private lastEndTime: Date;

    private festivitiesService: IFestivitiesService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private festivity: IFestivity, private calendar: IFullCalendar = null) {
        this.festivitiesService = <IFestivitiesService> this.serviceLocator.findService(ProlifeSdk.FestivitiesServiceCode);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.CategoriesProvider = new CategoriesProvider(this.serviceLocator);
        
        this.lastStartTime = this.festivity.start;
        this.lastEndTime = this.festivity.end;

        this.Id(this.festivity.id);
        this.Title(this.festivity.title);
        this.AgendaId(this.festivity.AgendaId);
        this.CategoryId(this.festivity.CategoryId);
        this.Note(this.festivity.Note);
        this.AllDay(this.festivity.allDay);
        this.StartDate(this.festivity.start);
        this.StartTime(this.festivity.start);
        this.EndDate(this.festivity.end);
        this.EndTime(this.festivity.end);
        this.Color(this.festivity.color);
        this.Deleted(this.festivity.Deleted);

        this.IsNew = ko.computed(() => {
            return this.Id() <= 0;
        });

        this.CategoryId.subscribe((newCategory: number) => {
            if (newCategory == ProlifeSdk.FestivityCategoryId)
                this.AllDay(true);
        });

        this.AllDay.subscribe((value: boolean) => {
            if (this.CategoryId() == ProlifeSdk.FestivityCategoryId) {
                this.AllDay(true);
                return;
            }

            if (value) {
                this.lastStartTime = this.StartTime();
                this.lastEndTime = this.EndTime();

                this.EndDate(this.StartDate());
                this.EndTime(this.StartTime());
                return;
            }

            this.StartTime(this.lastStartTime);
            this.EndTime(this.lastEndTime);
        });

        var EventDurationInterceptor = ko.computed(() => {
            if (!this.StartDate() || !this.StartTime() || !this.EndDate() || !this.EndTime())
                return;

            if (this.AllDay()) {
                this.EndDate(this.StartDate());
                return;
            }

            var startTime: number[] = (!this.StartTime() ? '00:00:00' : moment(this.StartTime()).format('HH:mm:ss')).split(':').map((t: string)=> parseInt(t));
            var startDateAndTime = moment(this.StartDate()).hours(startTime[0]).minutes(startTime[1]).seconds(startTime[2]);

            var endTime: number[] = (!this.EndTime() ? '00:00:00' : moment(this.EndTime()).format('HH:mm:ss')).split(':').map((t: string)=> parseInt(t));
            var endDateAndTime = moment(this.EndDate()).hours(endTime[0]).minutes(endTime[1]).seconds(endTime[2]);

            if (startDateAndTime > endDateAndTime) {
                this.EndDate(this.StartDate());
                this.EndTime(this.StartTime());

                this.lastStartTime = moment(this.StartTime()).toDate();
                this.lastEndTime = moment(this.EndTime()).toDate();
            }
        });

        this.SetEventDates();
    }

    public Delete(): Promise<boolean> {
        var def = new Deferred<boolean>();
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.DeleteFestivityMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.festivitiesService.Delete(this.Id())
                        .then(() => {
                            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.DeleteFestivitySuccess);
                            def.resolve(true);
                        })
                        .catch(() => {
                            this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.DeleteFestivityError);
                            def.reject();
                        });
                else
                    def.resolve(false);
            }
        );
        return def.promise();
    }

    public GetData(): IFestivity {
        this.Color(this.CategoriesProvider.GetCategoryColor(this.CategoryId(), true));

        return <IFestivity> {
            id: this.Id(),
            title: this.Title(),
            allDay: this.AllDay(),
            start: this.MergeDateAndTime(this.StartDate(), this.StartTime()),
            end: this.MergeDateAndTime(this.EndDate(), this.EndTime()),
            color: UiUtilities.GetColorWithOpacity(this.Color(), 0.6),
            AgendaId: this.AgendaId(),
            CategoryId: this.CategoryId(),
            Note: this.Note(),
            textColor: UiUtilities.GetForegroundColorForAlphaColor(this.Color(), 0.6, "#FFFFFF"),
            Deleted: this.Deleted(),
            editable: true,
            CreatedBy: this.festivity.CreatedBy,
            CreationDate: this.festivity.CreationDate,
            ModifiedBy: this.festivity.ModifiedBy,
            ModifyDate: this.festivity.ModifyDate
        };
    }

    private SetEventDates(): any {
        if (!this.IsNew())
            return;

        var start: moment.Moment = null;
        var end: moment.Moment = null;

        if (!!this.calendar) {
            var view: IFullCalendarViewObject = !this.calendar.SelectedInterval() ? this.calendar.GetActualView() : this.calendar.SelectedInterval().view;
            if (!!this.calendar.SelectedInterval()) {
                var interval: IFullCalendarSelectedInterval = this.calendar.SelectedInterval();
                var timeComponents: number[] = view.name == ProlifeSdk.CalendarMonthView ? moment().format("LT").split(':').map((o: string) => parseInt(o)) : interval.start.format("LT").split(':').map((o: string) => parseInt(o));
                start = interval.start.hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local();
            } else {
                var timeComponents: number[] = moment().format("LT").split(':').map((o: string) => parseInt(o));
                var paramDate = view.start > moment().startOf('day') ? view.start.hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local() : moment().hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).local();
                start = paramDate;
            }
        } else {
            start = moment();
        }

        end = moment(start).add('minutes', 30);

        this.StartDate(start.toDate());
        this.StartTime(start.toDate());
        this.EndDate(end.toDate());
        this.EndTime(end.toDate());

        this.lastStartTime = start.toDate();
        this.lastEndTime = end.toDate();
    }

    private MergeDateAndTime(date: Date, time: Date): Date {
        var timeComponents: number[] = moment(time).format("HH:mm").split(':').map((t: string) => parseInt(t));
        return moment(date).hours(timeComponents[0]).minutes(timeComponents[1]).seconds(0).toDate();
    }
}