import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as numeral from "numeral";
import { Template } from "../../Bindings/ForceBinding";
export type Param<T> = ko.Observable<T> | ko.Computed<T> | T | undefined;
export type ParamArray<T> = ko.ObservableArray<T> | ko.Computed<T[]> | undefined;

export type ComponentParam<T> = ko.Observable<T> | ko.Computed<T>;
export type ComponentParamArray<T> = ko.ObservableArray<T> | ko.Computed<T[]>;

const parentTypesTag = "__jsxknockout_parentTypes__";
const parentRefsTag = "__jsxknockout_parentRefs__";
const componentDidMountCalled = Symbol("__ComponentDidMountCalled");
const componentWillUnmountCalled = Symbol("__ComponentWillUnmountCalled");
const currentComponent = "__jsxknockout_currentComponent";

export class ComponentUtils {
    static observeAttribute<T>(element: Node, attributeName: string): Param<T> {
        if(element.nodeType == 8) //Se è un commento, non può avere attributi
            return undefined;
            
        const htmlElement = element as HTMLElement;

        const bindableValue = htmlElement.getAttribute("bind-" + attributeName);
        if(bindableValue) {
            return ComponentUtils.parseParameterValue<T>(bindableValue, element);
        }

        const hasAttribute = htmlElement.hasAttribute(attributeName);
        const attributeValue = htmlElement.getAttribute(attributeName);

        if (hasAttribute && attributeValue === "") // Se un attributo è definito, ma non ha un valore, lo considero come boolean
            return true as any;

        return ComponentUtils.parseAttributeValue(attributeValue);
    }

    static parseAttributeValue<T>(attributeValue: string): T {
        if (attributeValue === null || attributeValue === undefined)
            return attributeValue as unknown as T;

        if (attributeValue.toLowerCase() === 'false')
            return false as unknown as T;

        if (attributeValue.toLowerCase() === 'true')
            return true as unknown as T;

        const regexp = /^[\d,.]*$/gi;
        if (regexp.test(attributeValue))
            return  numeral(attributeValue).value() as unknown as T;

        return attributeValue as unknown as T;
    }

    static parseParameter<T>(value : T | Param<T>, defaultValue : T) : ComponentParam<T> {
        if(value == null || value == undefined)
            return ko.observable(defaultValue);
        if(ko.isComputed(value))
            return value;
        if(ko.isObservable(value))
            return value;
        /*if(typeof value === "function")
            return ko.pureComputed(value as (() => T));*/
        return ko.observable(value);
    }

    static parseParameterArray<T>(value : T[] | ParamArray<T>, defaultValue : T[]) : ComponentParamArray<T> {
        if(value == null || value == undefined)
            return ko.observableArray(defaultValue);
        if(ko.isComputed(value))
            return value;
        if(ko.isObservable(value))
            return value;
        /*if(typeof value === "function")
            return ko.pureComputed(value as (() => T[]));*/
        return ko.observableArray(value);
    }

    static parseAndConvertParameterArray<T,K>(value : T[] | ParamArray<T>, defaultValue : K[], converter?: (value : T) => K) : ComponentParamArray<K> {
        if(value == null || value == undefined)
            return ko.observableArray(defaultValue);
        if(ko.isComputed(value) || ko.isObservable(value) || typeof value === "function")
            return ko.computed(() => { return value().map(converter); });
        return ko.observableArray(value.map(converter));
    }

    static parseParameterWithContext<T>(value : T | string, context : ko.BindingContext) : T {
        if(typeof(value) === "string") {
            return new Function("$context","$element", "with($context){with($data||{}){return " + value + " }}")(context, null);
        }

        return value;
    }

    static parseParametersWithContext<T>(value : T | string, context : ko.BindingContext) : T {
        if(typeof(value) === "string") {
            return new Function("$context","$element", "with($context){with($data||{}){return{" + value + "}}}")(context, null);
        }

        return value;
    }

    static parseParameters<T>(value : T, element : Node) : T {
        if(typeof(value) === "string") {
            const context = ko.contextFor(element);
            return new Function("$context","$element", "with($context){with($data||{}){return{" + value + "}}}")(context, element);
        }

        return value;
    }

    static parseParameterValue<T>(value : string, element : Node) : ko.Observable<T> | ko.Computed<T> {
        if(typeof(value) === "string") {
            const context = ko.contextFor(element);
            const baseValue = new Function("$context","$element", "with($context){with($data||{}){ return " + value + " }}")(context, element);
            if(ko.isObservable(baseValue) || ko.isComputed(baseValue))
                return baseValue;

            const bindingString =  (<any>ko.expressionRewriting).preProcessBindings("__jsxknockout_value_: " + value, { valueAccessors: true });
            return ko.pureComputed(new Function("$context","$element", "with($context){with($data||{}){ return {" + bindingString + "}[\"__jsxknockout_value_\"]; }}")(context, element));
        }

        return value;
    }

    static handleAttributes(attributes: any, params: any, element: Node) {
        params = params || {};

        for (const attr in attributes) {
            if (attributes.hasOwnProperty(attr)) {
                if (params[attr] === undefined || params[attr] === null)
                    params[attr] = ComponentUtils.observeAttribute(element, attributes[attr]);
            }
        }
    }

    static OnDestroy(element : Node, callback: () => void) {
        const m = new MutationObserver(function(e) {
            if(e[0].removedNodes) {
                e[0].removedNodes.forEach(n => {
                    if(n.contains(element)) {
                        m.disconnect();
                        callback();
                        return;
                    }
                })
            }
        });
        m.observe(document.body, { childList: true });
    }

    static registerComponent<T extends { render() : Node; dispose() : void; }, P>(componentTagName: string, viewModel: new (params : P) => T, attributes?: { [K in keyof P]: string }) {
        ko.components.register(componentTagName, {
            viewModel: {
                createViewModel: (params : P, componentInfo: ko.components.ComponentInfo) => { 
                    ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
        
                    const vm = new viewModel(params);
                    
                    ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                        vm.render()
                    ]);
        
                    ko.utils.domNodeDisposal.addDisposeCallback(componentInfo.element, () => {
                        vm.dispose();
                    });
        
                    return vm;
                }
            },
            template: []
        });
    }

    static cloneNode(node: Node) : Node {
        if (typeof node === "string" || typeof node === "boolean")
            return node;

        const prefix = (jQuery as any).expando;
        const koPrefix = "__ko__";

        const isJqueryProp = (prop) => prop.indexOf(prefix) === 0;
        const isKoProp = (prop) => prop.indexOf(koPrefix) === 0;
        
        const clonedNode = node.cloneNode(false);
        for(const prop in node) {
            if(isJqueryProp(prop)) {
                const expando = node[prop];
                const { events, handle, ...rest } = expando;

                clonedNode[prop] = { ...rest };

                if(events) {
                    for(const type in events) {
                        for (let i = 0, l = events[type].length; i < l; i++) {
                            (jQuery.event as any).add( clonedNode, type, events[ type ][ i ] );
                        }
                    }
                }
            } else if(isKoProp(prop)) {
                clonedNode[prop] = node[prop];
            }
        }

        node.childNodes.forEach(child => {
            const clonedChild = ComponentUtils.cloneNode(child);
            clonedNode.appendChild(clonedChild);
        });

        return clonedNode;
    }

    static setBinding(node: React.ReactNode, bindings: { [binding: string]: any }) : React.ReactElement {
        const domNode = node as Node;

        if(domNode instanceof DocumentFragment) {
            domNode.childNodes.forEach(c => ComponentUtils.setBinding(c as React.ReactNode, bindings));
        } else {
            $.data(domNode as Node, "___ko_prolife_binding___", function($element, $context) { return bindings; });
        }
        return node as React.ReactElement;
    }

    static bindTo(node: Node, viewModel: any, as?: string, additionalProperties?: { [as: string]: unknown }) {
        if(node instanceof DocumentFragment) {
            node.childNodes.forEach(c => ComponentUtils.bindTo(c, viewModel, as, additionalProperties));
        } else {
            const currentViewModel = $.data(node, "___ko_prolife_viewModel___");
            if (!currentViewModel) {
                $.data(node, "___ko_prolife_viewModel___", viewModel);
                $.data(node, "___ko_prolife_additionalProperties___", additionalProperties);
                if (as)
                    $.data(node, "___ko_prolife_as___", as);
            }
        }

        return node as React.ReactNode;
    }

    static applyBindingsToNodeAndChildren(element : Node, bindingContext: ko.BindingContext) {
        const nodeIsElement = element.nodeType === 1;
        const bindingProvider = ko.bindingProvider.instance;

        if (element.nodeType !== 1 && element.nodeType !== 8)
            return;

        if (nodeIsElement)
            (ko.virtualElements as any).normaliseVirtualElementDomStructure(element);

        const viewModel = $.data(element, "___ko_prolife_viewModel___");
        const as =        $.data(element, "___ko_prolife_as___");

        let context = bindingContext;
        if (viewModel) {
            context = bindingContext.createChildContext(viewModel, as);
        }

        let bindings = {};
        if (nodeIsElement || bindingProvider.nodeHasBindings(element)) {
            bindings = bindingProvider.getBindingAccessors(element, context);
            const bindingResult = ko.applyBindingAccessorsToNode(element, bindings, context) as unknown as { shouldBindDescendants: boolean; bindingContextForDescendants: ko.BindingContext };
            context = bindingResult.bindingContextForDescendants;
        }

        if (context)
            ComponentUtils.applyBindingsToChildren(element, context);
    }

    static applyBindingsToChildren(element : Node, bindingContext: ko.BindingContext) {
        let nextInQueue = ko.virtualElements.firstChild(element);
        if (!nextInQueue) {
            (ko.bindingEvent as any).notify(element, (ko.bindingEvent as any).childrenComplete);
            return;
        }

        let currentChild = null;
        const bindingProvider = ko.bindingProvider.instance;
        const preprocessNode = bindingProvider.preprocessNode;

        if (preprocessNode) {
            while ((currentChild = nextInQueue)) {
                nextInQueue = ko.virtualElements.nextSibling(currentChild);
                preprocessNode.call(bindingProvider, currentChild);
            }

            nextInQueue = ko.virtualElements.firstChild(element);
        }

        while ((currentChild = nextInQueue)) {
            nextInQueue = ko.virtualElements.nextSibling(currentChild);
            ComponentUtils.applyBindingsToNodeAndChildren(currentChild, bindingContext);
        }

        (ko.bindingEvent as any).notify(element, (ko.bindingEvent as any).childrenComplete);

        /* for(let child of ko.virtualElements.childNodes(element)) {
            ComponentUtils.applyBindingsToNodeAndChildren(child, bindingContext);
        } */
    }

    static isOfType<T extends ComponentType>(node : React.ReactNode, type : ClassComponentConstructor<T> | FunctionalComponent) : node is React.ReactNode<T> {
        if(node instanceof DocumentFragment)
            return ko.utils.domData.get(node, "type") === type;
        return $.data(node as Node, "type") === type;//ko.utils.domData.get(node as Node, "type") === type;
    }

    static getComponent<T extends ComponentType>(node : React.ReactNode<T>) : T {
        if(node instanceof DocumentFragment)
            return ko.utils.domData.get(node, "ref");
        return $.data(node as Node, "ref");
    }
    static getParentComponents<T extends ComponentType>(node : React.ReactNode<T>) : T[] {
        if(node instanceof DocumentFragment)
            return ko.utils.domData.get(node, parentRefsTag) ?? [];
        return $.data(node as Node, parentRefsTag) ?? [];
    }

    static classNames(...classes: (string | string[] | { [className: string]: boolean })[]) : string {
        const finalClasses : string[] = [];

        for(const c of classes) {
            if(typeof c === "string")
                finalClasses.push(...c.split(' ').map(c => (c ?? "").trim()).filter(c => c !== ""));
            else if(typeof c === "object") {
                for(const key in c) {
                    if(!c.hasOwnProperty(key)) continue;
                    if(c[key])
                        finalClasses.push(key);
                }
            }
        }

        return finalClasses.distinct().join(' ');
    }

    static Children = {
        forEach: (children: React.ReactNode, predicate: (child : React.ReactNode) => void) => {
            const array = ComponentUtils.getArrayFromChildren(children);
            array.forEach(predicate);
        },

        count: (children: React.ReactNode) => {
            const array = ComponentUtils.getArrayFromChildren(children);
            return array.length;
        }
    }

    private static getArrayFromChildren(children: React.ReactNode) {
        let array : React.ReactNodeArray;
        if (Array.isArray(children))
            array = children.filter(c => c !== null && c !== undefined);
        else
            array = children === null || children === undefined ? [] : [children];

        return array;
    }

    static useGetter<T, TKey>() {
        return {
            getKey: (predicate: (item: T) => TKey) => {
                return (item) => predicate(item);
            },
            getLabel: (predicate: (item: T) => string) => {
                return (item) => predicate(item);
            }
        };
    }

    static useSorter<T>() {
        return {
            sortString: (predicate: (v: T) => string) => {
                return (a,b) => {
                    const aVal = predicate(a) ?? "";
                    const bVal = predicate(b) ?? "";
                    if(aVal > bVal)
                        return 1;
                    if(aVal < bVal)
                        return -1;
                    return 0;
                }
            },
            sortNumber: (predicate: (v: T) => number) => {
                return (a,b) => {
                    const aVal = predicate(a);
                    const bVal = predicate(b);
                    return (aVal ?? 0) - (bVal ?? 0);
                }
            },
            sortDate: (predicate: (v: T) => Date) => {
                return (a,b) => {
                    const aVal = predicate(a);
                    const bVal = predicate(b);
                    return (aVal?.valueOf() || 0) - (bVal?.valueOf() || 0);
                }
            }
        }
    } 

    static lazy(callback: () => Promise<React.ReactElement>) : React.ReactElement {
        const toRender = ko.observable<any>(() => React.createElement("div", {}, "Loading..."));
        callback().then((r) => toRender(() => r));
        return React.createElement(Template, { component: toRender });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    static getComputed<K, T extends Exclude<K, Function>>(value: T | ko.Observable<T> | ko.Computed<T> | (() => T)) : ko.Computed<T> {
        if(!ko.isSubscribable(value) && typeof value === "function") {
            return ko.computed(() => (value as (() => T))())
        } else if(ko.isSubscribable(value)) {
            return ko.computed(() => value())
        } else {
            return ko.computed(() => value);
        }
    }

    static getChildNodes(element: Node) {
        const allChildren = [...ko.virtualElements.childNodes(element)];
        const notRealChildren = [];

        for(const child of allChildren) {
            if(child.nodeType === Node.COMMENT_NODE) {
                const subChildren = ko.virtualElements.childNodes(child);
                notRealChildren.push(...subChildren);
            }
        }

        for(const childToRemove of notRealChildren) {
            allChildren.remove(childToRemove);
        }

        return allChildren;
    }

    

    static notifyWillUnmount(element: Node) {
        const parentComponents = ComponentUtils.getParentComponents(element as React.ReactNode);
        const component = ComponentUtils.getComponent(element as React.ReactNode);

        const childNodes = [...ComponentUtils.getChildNodes(element)];
        for(const child of childNodes.reverse()) {
            ComponentUtils.notifyWillUnmount(child);
        }

        if(component && component.componentWillUnmount && component[componentDidMountCalled] && !component[componentWillUnmountCalled]) {
            component._disposing && component._disposing(true);
            component[componentWillUnmountCalled] = true;
            component.componentWillUnmount();
        }

        for(const parentComponent of parentComponents.slice().reverse()) {
            if(parentComponent && parentComponent.componentWillUnmount && parentComponent[componentDidMountCalled] && !parentComponent[componentWillUnmountCalled]) {
                parentComponent._disposing && parentComponent._disposing(true);
                parentComponent[componentWillUnmountCalled] = true;
                parentComponent.componentWillUnmount();
            }
        }
    }

    static notifyDidMount(element: Node) {
        const context = ko.contextFor(element);
        
        const parentComponents = ComponentUtils.getParentComponents(element as React.ReactNode);
        const component = ComponentUtils.getComponent(element as React.ReactNode);

        const childNodes = [...ComponentUtils.getChildNodes(element)];
        for(const child of childNodes.reverse()) {
            ComponentUtils.notifyDidMount(child);
        }

        if(component && component.componentDidMount && !component[componentDidMountCalled] && !component[componentWillUnmountCalled]) {
            window[currentComponent] = component;

            component._disposing && component._disposing(false);
            component[componentDidMountCalled] = true;
            component.componentDidMount(context);

            window[currentComponent] = null;
        }

        for(const parentComponent of parentComponents.slice().reverse()) {
            if(parentComponent && parentComponent.componentDidMount && !parentComponent[componentDidMountCalled] && !parentComponent[componentWillUnmountCalled]) {
                window[currentComponent] = parentComponent;

                parentComponent._disposing && parentComponent._disposing(false);
                parentComponent[componentDidMountCalled] = true;
                parentComponent.componentDidMount(context);

                window[currentComponent] = null;
            }
        }
    }

    static createPortal(renderCallback: () => React.ReactElement) : Portal {
        const portal = React.createElement("div", { className: "__portal" }, React.createElement(Template, { component: renderCallback }));
        document.body.appendChild(portal);
        
        const context = new (ko as any).bindingContext({});
        ComponentUtils.applyBindingsToNodeAndChildren(portal, context);

        return {
            dispose: () => portal.remove()
        }
    }
}

export type Portal = {
    dispose: () => void;
};

export function reloadNow(component : { new(props?: any) : any } | ((props?:any) => React.ReactElement)) {
    if(!window.dependencyHandles[component.name]) {
        window.dependencyHandles[component.name] = ko.observable();
        (window.dependencyHandles[component.name] as any).tag = component.name;
    }
    window.dependencyHandles[component.name].valueHasMutated();
}

export function reload(component : { new(props?: any) : any }) {
    return () => {
        reloadNow(component);
    };
}

export function useEffect(callback: () => void, dependencies: (ko.Subscribable | (() => any))[], allowNoComponent: boolean = false) {
    const component : ComponentType = window[currentComponent];
    const disposeRule : ko.ComputedOptions = {};

    if(!allowNoComponent) {
        if(!component || !component._disposing)
            console.warn("useEffect called outside of a class component constructor or componentDidMount, may result in a memory leak!");
        else {
            disposeRule.disposeWhen = () => component._disposing();
        }
    }

    ko.computed(() => {
        for(let dep of dependencies) {
            if(!ko.isSubscribable(dep)) {
                dep = ko.computed(dep);
            }
            ko.computedContext.registerDependency(dep as ko.Subscribable);
        }

        ko.ignoreDependencies(callback);
    }, null, disposeRule);
}

type Context<T> = {
    Provider: (props: { value: T, children?: React.ReactElement }) => React.ReactElement;
};
type InternalContext<T> = Context<T> & { id: number, value: T, propName: string; };

let contextId = 1;
export function createContext<T>(initialValue: T) : Context<T> {
    const id = contextId++;
    const contextObj : any = {
        id,
        value: initialValue,
        propName: "__jsxknockout_context_" + id
    };
    contextObj.Provider = function(props: { value: T, children?: React.ReactElement }) {
        const applyContextToChild = (c : any) => {
            const comp = ComponentUtils.getComponent(c);
            if(comp) {
                if(comp.hasOwnProperty(contextObj.propName))
                    return;

                Object.defineProperty(comp, contextObj.propName, { value: contextObj });
            }

            ComponentUtils.Children.forEach(c.children, applyContextToChild);
        }

        ComponentUtils.Children.forEach(props.children, applyContextToChild);
        return props.children;
    }

    return contextObj;
}

export function useContext<T>(component: ComponentType, context: Context<T>) : T {
    const c = context as InternalContext<T>;
    if(!component.hasOwnProperty(c.propName))
        return c.value;
    return component[c.propName];
}

export type DisposeCallback = () => void;

export type ComponentType = { 
    render() : React.ReactNode; 
    componentDidMount?(context : ko.BindingContext);
    componentWillUnmount?();
    _disposing?: ko.Observable<boolean>;
};

export type ClassComponentConstructor<T extends ComponentType> = new (params?: any) => T;
export type FunctionalComponent = (params?: any) => React.ReactNode;
// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithChildren<P = {}> = {
    children?: React.ReactNode;
} & P;

export type BindingType = string | { [binding: string]: any; };
export const classNames = ComponentUtils.classNames;