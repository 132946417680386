import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ExpenseEventInput } from "./details/ExpenseEventInput";
import { PurchaseCostRow } from "../../../Blog/Blog/Purchases/PurchaseCostRow";
import { PurchaseRevenueRow } from "../../../Blog/Blog/Purchases/PurchaseRevenueRow";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IPurchase, IPurchaseRow } from "../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";

export class ExpenseEvent extends BlogEvent implements IView {
    public Amount : ko.Observable<number> = ko.observable();
    public SalesAmount : ko.Observable<number> = ko.observable();
    public SalId : ko.Observable<any> = ko.observable(null);
    public Billed : ko.Observable<boolean> = ko.observable(false);
    public Billable : ko.Observable<boolean> = ko.observable(true);

    public CostsRows : ko.ObservableArray<PurchaseCostRow> = ko.observableArray([]);
    public RevenuesRows : ko.ObservableArray<PurchaseRevenueRow> = ko.observableArray([]);

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        
        this.iconClass = "fa-taxi";
        this.eventTypeName = TextResources.Blog.ExpensesTitle;
        this.templateUrl = "expense/templates/events";
        this.templateName = "expense-event";

        this.Updatable(false);
        this.isDeleteLocked(true);

        this.Clear();
    }

    public Clear()
    {
        super.Clear();
        this.Amount(0);
        this.SalesAmount(0);
        this.SalId(null);
        this.Billed(false);
        this.Billable(true);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {
            if(item.TagName() == ProlifeSdk.TagMaterials_Amount){
                this.Amount(parseFloat(item.Value()));
            }
            if(item.TagName() == ProlifeSdk.TagMaterials_SalesAmount){
                this.SalesAmount(parseFloat(item.Value()));
            }

            this.Billed(item.TagName() != ProlifeSdk.Tag_Billed ? this.Billed() : item.Value().toLowerCase() == "true");
            this.Billable(item.TagName() == ProlifeSdk.TagWorkHours_Billable ? item.Value().toLowerCase() == ProlifeSdk.TagWork_Invoiced_true.toLowerCase() : this.Billable());
            this.SalId(item.TagName() == ProlifeSdk.SalEntityType && item.Value() != null && item.Value().length > 0 ? item.Value() : this.SalId());

        });
    }

    public LoadDetails()
    {
        super.LoadDetails();

        this.blogService.purchasesProvider.getByEventId(this.IdEvent())
            .then((p: IPurchase) => {
                if(!p)
                    return;

                this.CostsRows(p.CostsRows.map((r : IPurchaseRow) => new PurchaseCostRow(this.serviceLocator, r)));
                this.RevenuesRows(p.RevenuesRows.map((r : IPurchaseRow) => new PurchaseRevenueRow(this.serviceLocator, r)));
            });
    }

    public getEditViewModel(): IEventBaseInput
    {
        return new ExpenseEventInput(this.serviceLocator, this.contextEventsObserver, this);
    }
}