import * as ko from "knockout";
import { MergedReportComponentConstructor, ReportComponentConstructor } from "./ReportComponent";

type FontWeight = "normal" | "bold" | "light";
type FontStyle = "normal" | "italic";

export interface ReportComponentWithFont {
    fontColor: ko.Observable<string>;
    fontFamily: ko.Observable<string>;
    fontSize: ko.Observable<number>;
    fontWeight: ko.Observable<FontWeight>;
    fontStyle: ko.Observable<FontStyle>;
}

export function HasFont<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithFont, { fontcolor?: string, fontFamily?: string, fontSize?: number, fontWeight?: FontWeight, fontStyle?: FontStyle }> {
    return class ReportComponentWithFont extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "Font"];

            const { fontColor, fontFamily, fontSize, fontWeight, fontStyle } = args[0];
            this.fontColor(fontColor ?? "#000000");
            this.fontFamily(fontFamily ?? "Arial");
            this.fontSize(fontSize ?? 10);
            this.fontWeight(fontWeight ?? "normal");
            this.fontStyle(fontStyle ?? "normal");
        }

        getData() {
            const data = super.getData();
            return {
                ...data,
                fontColor: this.fontColor(),
                fontFamily: this.fontFamily(),
                fontSize: this.fontSize(),
                fontWeight: this.fontWeight(),
                fontStyle: this.fontStyle(),
            }
        }

        fontColor: ko.Observable<string> = ko.observable();
        fontFamily: ko.Observable<string> = ko.observable();
        fontSize: ko.Observable<number> = ko.observable();
        fontWeight: ko.Observable<FontWeight> = ko.observable();
        fontStyle: ko.Observable<FontStyle> = ko.observable();
    }
}