import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IWarehousesService, IWarehouseBusinessInfo } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class WarehouseBusinessInfoDialog implements IDialog
{
    title: string = null;
    templateName: string = "warehouse-business-info-dialog";
    templateUrl: string = "warehouse/templates";

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehouseService : IWarehousesService;
    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService : IDesktopService;

    modal : { close: (result?: any) => void; };

    SellPrice : ko.Observable<number> = ko.observable(0);
    CostPrice : ko.Observable<number> = ko.observable(0);

    constructor(private warehouseId : number)
    {
        this.title = ProlifeSdk.TextResources.Warehouse.WarehouseInformations;
        this.Refresh();
    }

    Refresh()
    {
        var loadingId : string = this.desktopService.StartSaving(ProlifeSdk.TextResources.Warehouse.Loading);
        this.warehouseService.GetWarehouseBusinessInfo(this.warehouseId).then((bi : IWarehouseBusinessInfo) => {
            this.SellPrice(bi.SellValue);
            this.CostPrice(bi.CostValue);
        })
        .finally(() => { this.desktopService.EndSaving(loadingId); });
    }

    close(): void
    {
        this.modal.close();
    }

    action(): void
    {
        this.modal.close();
    }
}
