import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 14/02/13
 * Time: 14.28
 * To change this template use File | Settings | File Templates.
 */

export class ScrollToIndex {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var value = valueAccessor(), allBindings = allBindingsAccessor();
        var index = ko.utils.unwrapObservable(value);

        if (index != null) {
            $(element).children().each(function(i, targetElement) {
                if (i != index)
                    return;
                var targetTop = $(targetElement).position().top;
                $(element).offsetParent().animate({
                    scrollTop: targetTop
                }, 400, function() {
                    //animation complete
                })
            });
        }
    }
}

ko.bindingHandlers["scrollToIndex"] = new ScrollToIndex();