import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/02/13
 * Time: 11.58
 * To change this template use File | Settings | File Templates.
 */

import { WarehouseMenu } from "./WarehouseMenu";
import { FunctionalComponent } from "../../../Core/utils/ComponentUtils";

export interface IWarehouseSection {
    SectionId: number;

    dispose?(): void;
    render?: FunctionalComponent;
}

export class WarehouseViewModel {
    templateUrl : string = "warehouse/templates";

    public menu : WarehouseMenu;
    public warehouseSection : ko.Observable<IWarehouseSection> = ko.observable();

    constructor(section? : IWarehouseSection)
    {
        this.menu = new WarehouseMenu(section.SectionId);

        if (section)
            this.warehouseSection(section);
    }

    goHome()
    {
        location.href = "#/";
    }

    dispose()
    {
        var section = this.warehouseSection();
        if (section && section.dispose)
            section.dispose();
    }
}