import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { IPurchaseTypeSettingManager } from "../../../SettingManagers/PurchaseTypeSettingManager";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IExtendedEstimateBudgetRow } from "./ActivitiesDataSource";
import { IDocumentBuilderDocumentRelatedWorkflows } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IActivitiesForDocuments,
    IActivitiesSourceForDocuments,
} from "../../../ProlifeSdk/interfaces/todolist/IActivitiesForDocuments";
import { IEstimateBudgetRow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IArticlesService, IArticle } from "../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDiscountsService } from "../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";

export class ActivitiesRowGroup implements IActivitiesForDocuments {
    ArticleId: ko.Observable<number> = ko.observable();

    Description: ko.Observable<string> = ko.observable();
    AmountFormula: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable();
    UnitPrice: ko.Observable<number> = ko.observable();
    Discounts: ko.Observable<string> = ko.observable();
    NetUnitPrice: ko.Observable<number> = ko.observable();
    Total: ko.Observable<number> = ko.observable();
    UOM: ko.Observable<string> = ko.observable();

    CatalogId: ko.Observable<number> = ko.observable();

    Sources: IActivitiesSourceForDocuments[] = [];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[] = [];

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacterService: IUserCharactersSettingsManager;

    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;

    @LazyImport(nameof<IDiscountsService>())
    private discountsService: IDiscountsService;

    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesSettingManager: IPurchaseTypeSettingManager;

    constructor(public rows: IExtendedEstimateBudgetRow[], private useCosts: boolean = false) {
        let deferSourcesGeneration = false;

        const row = rows[0];
        let totalAmount = 0;
        let totalPrice = 0;

        this.rows.forEach((r) => {
            totalAmount += r.Amount * r.Multiplier;
        });

        if (rows.length == 1) {
            this.Amount(totalAmount);
            this.UnitPrice(this.useCosts ? row.UnitCost : row.UnitPrice);
            this.Discounts(this.useCosts ? null : row.DiscountValues);
            this.NetUnitPrice(
                (this.useCosts ? row.UnitCost : row.UnitPrice) *
                    (this.useCosts ? 1 : this.discountsService.calculateDiscount(row.DiscountValues))
            );

            totalPrice = this.NetUnitPrice() * totalAmount;
        } else {
            this.AmountFormula(
                numeral(1).format("0,0.00[0]") +
                    " * " +
                    "sum([RowAmount] * [RowMultiplier] * " +
                    (this.useCosts ? "[RowUnitCost]" : "[RowUnitPrice]") +
                    (this.useCosts ? "" : " * [Discount]") +
                    " €"
            );
            this.Amount(1);

            totalPrice = this.rows.sum(
                (r) =>
                    r.Amount *
                    r.Multiplier *
                    (this.useCosts ? r.UnitCost : r.UnitPrice) *
                    (this.useCosts ? 1 : this.discountsService.calculateDiscount(r.DiscountValues))
            );

            this.UnitPrice(totalPrice);
            this.NetUnitPrice(totalPrice);
            this.Discounts(undefined);
        }

        this.Total(totalPrice);

        if (row.Type == ProlifeSdk.EstimatedWorkEntityTypeCode) {
            this.Description(this.userCharacterService.getUserCharacterById(row.EntityKeyId).Description);
            this.UOM(ProlifeSdk.TextResources.Todolist.UOMHours);
        } else if (row.Type == ProlifeSdk.WarehouseArticleEntityTypeCode) {
            deferSourcesGeneration = true;
            this.Description(ProlifeSdk.TextResources.Core.Loading);

            this.articlesService.getArticleByCatalogRowId(row.EntityKeyId).then((a: IArticle) => {
                this.ArticleId(a.ArticleId);
                this.Description(a.Code + " " + a.Description);
                this.CatalogId(a.CatalogId);
                this.UOM(a.UOM);

                this.generateSources();
            });
        } else if (row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
            const purchaseType = this.purchaseTypesSettingManager.getById(row.EntityKeyId);
            if (!purchaseType) this.Description(TextResources.JobOrder.PurchaseTypeUnknown);
            else this.Description(purchaseType.Title);
        } else {
            this.Description(row.Description);
            this.UOM("");
        }

        for (const r of rows) {
            this.RelatedWorkflows.push({
                Id: -1,
                RowAmount: totalAmount,
                RowPrice: this.Total(),
                RowDescription: this.Description(),
                RowUoM: this.UOM(),
                RowId: null,
                WorkflowAmount: (r.Amount * r.Multiplier) / totalAmount,
                WorkflowId: r.WorkflowId,
                WorkflowName: null,
                DocumentDate: null,
                DocumentId: null,
                DocumentNumber: null,
                DocumentProtocolId: null,
                DocumentType: null,
            });
        }

        if (!deferSourcesGeneration) this.generateSources();
    }

    private generateSources() {
        this.Sources = this.rows.map(this.generateSource, this);
    }

    private generateSource(row: IEstimateBudgetRow): IActivitiesSourceForDocuments {
        return {
            Description: row.Description,
            Amount: row.Amount * row.Multiplier,
            UnitPrice: this.useCosts ? row.UnitCost : row.UnitPrice,
            Discounts: null,
            NetUnitPrice: this.useCosts ? row.UnitCost : row.UnitPrice,
            UOM: this.UOM(),
            Total: (this.useCosts ? row.UnitCost : row.UnitPrice) * row.Amount * row.Multiplier,

            EntityKeyId: row.Type == ProlifeSdk.WarehouseArticleEntityTypeCode ? this.ArticleId() : row.EntityKeyId,
            EntityType: row.Type,
            CatalogId: this.CatalogId(),

            EstimateBudgetRowId: row.Id,
            TaskId: row.TaskId,
        };
    }

    public references(row: IEstimateBudgetRow): boolean {
        return this.rows.filter((r) => r.Type == row.Type && r.Id == row.Id).length > 0;
    }
}
