import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { _EventClassificationInput } from "../../../ProlifeSdk/prolifesdk/blog/EventClassificationInput";
import { Tag } from "../../../ProlifeSdk/prolifesdk/blog/Tag";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITemplatesNavigator } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IGenericEventConfigurationsUi,
    IGenericEventConfigurations,
} from "../../../ProlifeSdk/interfaces/blog/IGenericEventConfigurationsUi";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { IEventTag, ITag } from "../../../ProlifeSdk/interfaces/ITag";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class GenericEventConfigurationsUI implements IGenericEventConfigurationsUi, INavigationMenuObserver {
    private todoListService: ITodoListService;
    private desktopService: IDesktopService;
    public templateName: string = "GenericEventConfigurationsUI";
    public templateUrl: string = "blog/templates/settings";
    viewModel: any;

    private Classification: _EventClassificationInput;
    public WorkflowActivities: ITemplatesNavigator;

    constructor(private serviceLocator: IServiceLocator) {
        this.todoListService = <ITodoListService>this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.desktopService = <IDesktopService>this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        this.Classification = new _EventClassificationInput({});

        this.WorkflowActivities = this.todoListService.GetTemplatesNavigatorUI();
        this.WorkflowActivities.setMultipleSelectionStatus(true);
        this.WorkflowActivities.setMobileStatus(this.desktopService.isMobileBrowser());
        this.WorkflowActivities.addMenuObserver(this);
        this.WorkflowActivities.clearSelection(false);
    }

    public Initialize(settings: IGenericEventConfigurations) {
        this.Classification.setValues(
            settings.Priority,
            settings.Status,
            settings.CustomMetaTags,
            settings.AllowedUsersTags,
            settings.InQuality,
            settings.IsAdministration,
            settings.IsCommercial,
            settings.IsTechnical,
            false,
            null
        );

        this.WorkflowActivities.Initialize().then(() => {
            this.WorkflowActivities.clearSelection(false);
            this.WorkflowActivities.SetSelectedTasks(settings.Tasks);
        });
    }

    public GetConfigurations(): IGenericEventConfigurations {
        var customMetaTags: IEventTag[] = this.Classification.tags.getElement().map((t: ITag) => {
            var tag = new Tag();
            tag.LoadItemFromObject(t);
            return tag;
        });

        var allowedUsers: IEventTag[] = this.Classification.AllowedUsers.getElement().map((t: ITag) => {
            var tag = new Tag();
            tag.LoadItemFromObject(t);
            return tag;
        });

        return <IGenericEventConfigurations>{
            Priority: this.Classification.priority(),
            Status: this.Classification.status(),
            CustomMetaTags: customMetaTags,
            AllowedUsersTags: allowedUsers,
            InQuality: this.Classification.inQuality(),
            IsAdministration: this.Classification.isAdministration(),
            IsCommercial: this.Classification.isCommercial(),
            IsTechnical: this.Classification.isTechnical(),
            Tasks: this.WorkflowActivities.GetSelectedTasksIds(),
        };
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {}

    onSelectionChanged(selection: INavigationMenuProvider[]) {
        this.Classification.inQuality(this.Classification.inQuality() || selection.length > 0);
        this.Classification.disableInQualityFlag(selection.length > 0);
    }
}
