import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { SelectableMovementArticle } from "../../ui/Movements/SelectableMovementArticle";
import { SelectableDetailedMovementArticle } from "../../ui/Movements/SelectableDetailedMovementArticle";
import {
    IDiscountsCatalogsService,
    IResolvedCustomerDiscountCatalogArticleWithCatalog,
} from "../../../DiscountsCatalogsService";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IDocumentBuilderDocumentOriginatingRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import {
    IStockService,
    IStockArticle,
    GetUnderStockArticlesRequest,
} from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { IDiscountsService, IDiscountsManager } from "../../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { classNames, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { SelectMultiple } from "../../../../Components/SelectMultiple";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { Layout } from "../../../../Components/Layouts";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ICustomerForList } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IJobOrderForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { WarehousesDataSource } from "../../../../DataSources/WarehousesDataSource";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import { DocumentsDataSource } from "../../../../DataSources/DocumentsDataSource";
import { ManufacturersDataSource } from "../../../../DataSources/ManufacturersDataSource";
import { DiscountFamiliesDataSource } from "../../../../DataSources/DiscountFamiliesDataSource";
import { TextInput } from "../../../../Components/TextInput";

const styleSheet = jss.createStyleSheet({
    filters: {
        "&.flex-container": {
            "&.flex-vertical": {
                "&:not(.no-gutters)": {
                    "& > *:not(:last-child)": {
                        "&:not(.form-group)": {
                            marginRight: "-15px",
                        },
                    },
                },
            },
        },

        "& .root-filters": {
            backgroundColor: "#f5f5f5",
        },

        "& .fa": {
            "&.help": {
                marginLeft: "10px",
            },
        },
    },
});
const { classes } = styleSheet.attach();

export class WarehouseUnderStockArticlesDataSource extends DocumentDataWizardStep<SelectableDetailedMovementArticle> {
    UnderStockArticles: ko.ObservableArray<SelectableDetailedMovementArticle> = ko.observableArray([]);
    AllSelected: ko.Computed<boolean>;
    SelectedArticles: ko.Computed<SelectableDetailedMovementArticle[]>;

    private SelectedCustomers: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrders: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedWarehouses: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedDocuments: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedManufacturers: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedSuppliers: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedDiscountFamilies: ko.ObservableArray<number> = ko.observableArray([]);
    private ShippingDate: ko.Observable<Date> = ko.observable(null);
    private SearchText: ko.Observable<string> = ko.observable("");

    private CustomersSelectPlaceholder: ko.Observable<string> = ko.observable(
        TextResources.Warehouse.AllCustomersPlaceholder
    );

    private JobOrdersSelectPlaceholder: ko.Observable<string> = ko.observable(
        TextResources.Warehouse.AllJobOrdersPlaceholder
    );

    private WarehousesSelectPlaceholder: ko.Observable<string> = ko.observable(
        TextResources.Warehouse.AllWarehousesPlaceholder
    );

    private CustomerOrdersSelectPlaceholder: ko.Observable<string> = ko.observable(
        TextResources.Warehouse.AllCustomerOrdersPlaceholder
    );

    private customersDataSource: CustomersDataSource = new CustomersDataSource();
    private jobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    private warehousesDataSource: WarehousesDataSource = new WarehousesDataSource();
    private documentsDataSource: DocumentsDataSource = new DocumentsDataSource();
    private manufacturersDataSource: ManufacturersDataSource = new ManufacturersDataSource();
    private suppliersDataSource: CustomersDataSource = new CustomersDataSource();
    private discountFamiliesDataSource: DiscountFamiliesDataSource = new DiscountFamiliesDataSource();

    previousStepRows: IDocumentDataWizardRow[];

    private _isInitialized = false;
    private _textFilterTimer: NodeJS.Timeout;
    private _filtersChangesTimer: NodeJS.Timeout;
    private _filtersChangesInterceptor: ko.Computed<void>;

    @LazyImport(nameof<IStockService>())
    private stockService: IStockService;
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    constructor() {
        super();

        this.Title(TextResources.Warehouse.UnderStockArticles);

        this.customersDataSource.setReturnGroupedData(false);
        this.customersDataSource.setGetSuppliers(false);

        this.jobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.jobOrdersDataSource.setShowClosed(true);
        this.jobOrdersDataSource.setViewFilters(true, true, true);
        this.jobOrdersDataSource.setWorkFilters(true);
        this.jobOrdersDataSource.setResourceId(this.humanResourcesSettingsManager.getLoggedResourceId());

        this.warehousesDataSource.setGetAllWarehouses(true);
        this.warehousesDataSource.setShowOnlyJobOrderWarehouses(false);
        this.warehousesDataSource.setGetDeleted(false);

        this.documentsDataSource.setFilterByJobOrder(false);
        this.documentsDataSource.setEntityTypes([ProlifeSdk.CustomerOrderEntityTypeCode]);

        this.suppliersDataSource.setReturnGroupedData(false);
        this.suppliersDataSource.setGetCustomers(false);

        this.SelectedArticles = ko.computed(() => {
            return this.UnderStockArticles().filter((r: SelectableMovementArticle) => r.Selected());
        });

        this.AllSelected = ko.computed({
            read: function () {
                return this.SelectedArticles().length == this.UnderStockArticles().length;
            },
            write: function (selected: boolean) {
                this.UnderStockArticles().forEach((r: SelectableMovementArticle) => r.Selected(selected));
            },
            owner: this,
        });

        this.registerEvents();

        this.documentsService.registerDataWizardStep(this, ProlifeSdk.SupplierOrderEntityTypeCode);
    }

    private registerEvents() {
        this._filtersChangesInterceptor = ko.computed(() => {
            this.SelectedCustomers();
            this.SelectedJobOrders();
            this.SelectedWarehouses();
            this.SelectedDocuments();
            this.SelectedManufacturers();
            this.SelectedSuppliers();
            this.SelectedDiscountFamilies();
            this.ShippingDate();

            this.onFiltersChanged();
        });

        this.SearchText.subscribe(this.onSearchTextChanged, this);
    }

    private onFiltersChanged() {
        if (!this._isInitialized) return;

        if (this._filtersChangesTimer) clearTimeout(this._filtersChangesTimer);

        this._filtersChangesTimer = setTimeout(() => {
            this.loadUnderStocks();
        }, 50);
    }

    private onSearchTextChanged() {
        if (this._textFilterTimer) {
            clearTimeout(this._textFilterTimer);
        }

        this._textFilterTimer = setTimeout(() => {
            this.loadUnderStocks();
        }, 500);
    }

    private createGetUnderStockRequest(): GetUnderStockArticlesRequest {
        return {
            customersIds: this.SelectedCustomers(),
            jobOrdersIds: this.SelectedJobOrders(),
            warehouseIds: this.SelectedWarehouses(),
            customerOrdersIds: this.SelectedDocuments(),
            manufacturersIds: this.SelectedManufacturers(),
            defaultSuppliersIds: this.SelectedSuppliers(),
            discountFamiliesIds: this.SelectedDiscountFamilies(),
            documentsShippingDate: this.ShippingDate(),
            articlesSearchQuery: this.SearchText(),
            skip: 0,
            count: 1000, // La vecchia funzione non era paginata. Per ora la lascio come era prima.
        };
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        this._isInitialized = false;

        await super.Initialize(initializationInfo);
        this.resetFilters();
        this._isInitialized = true;

        await this.loadUnderStocks();
    }

    private resetFilters() {
        this.SelectedCustomers([]);
        this.SelectedJobOrders([]);
        this.SelectedWarehouses([]);
        this.SelectedDocuments([]);
        this.SelectedManufacturers([]);
        this.SelectedSuppliers([]);
        this.SelectedDiscountFamilies([]);
        this.ShippingDate(null);
        this.SearchText("");
    }

    private async loadUnderStocks(): Promise<void> {
        this.UnderStockArticles([]);

        const articles = await this.stockService.getUnderStockArticles(this.createGetUnderStockRequest());

        const ids = articles.map((a) => a.ArticleId);
        const discounts = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticles({
            customerId: this.initializationInfo.CustomerId,
            isCustomer: false,
            overrideGroupId: null,
            referenceDate: this.initializationInfo.DocumentDate,
            warehouseId: this.initializationInfo.SourceWarehouseId,
            articleIds: ids,
        });

        const allArticles = [];
        for (const article of articles) {
            const discount = discounts.firstOrDefault((d) => d.ArticleId == article.ArticleId);

            allArticles.push(this.createViewModelFor(article, discount));
        }

        this.UnderStockArticles(allArticles);
    }

    public CanShow(): boolean {
        return this.authorizationService.isAuthorized("Warehouse_Articles");
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: null,
                    Amount: r.Amount(),
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: (r.NetUnitPrice() ?? 0).ToCurrency(documentCurrency),
                    NetUnitPrice: r.NetUnitPrice(),
                    Order: 0,
                    TotalPriceInDocumentCurrency: (r.Price() ?? 0).ToCurrency(documentCurrency),
                    TotalPrice: r.Price(),
                    UnitPriceInDocumentCurrency: (r.UnitPrice() ?? 0).ToCurrency(documentCurrency),
                    UnitPrice: r.UnitPrice(),
                    Description: r.Description(),
                    Discounts: r.Discounts(),
                    UoM: r.UOM(),
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r: SelectableMovementArticle): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        refs.push({
            RefId: 0,
            DocumentId: 0,
            SourceEntityType: r.EntityType,
            SourceEntityKeyId: r.getArticleId(),
            DestEntityType: this.initializationInfo.DocTypeCode,
            DestEntityKeyId: 0,
            CatalogId: r.CatalogId(),
            Amount: r.Amount(),
            UnitPrice: r.UnitPrice(),
            Discounts: r.Discounts(),
            NetUnitPrice: r.NetUnitPrice(),
            NetPrice: r.Price(),
            WarehouseId: this.initializationInfo.SourceWarehouseId,
        });
        return refs;
    }

    private createViewModelFor(
        stockArticle: IStockArticle,
        discount: IResolvedCustomerDiscountCatalogArticleWithCatalog
    ): SelectableMovementArticle {
        const article = new SelectableDetailedMovementArticle(
            stockArticle,
            ProlifeSdk.WarehouseArticleEntityTypeCode, // TODO per ora vengono gestiti solo gli articoli. Nel caso vadano gestiti anche gli acquisti, modificare di conseguenza
            false,
            0,
            true
        );
        article.setDefaultValues(discount);
        return article;
    }

    public createDiscountManager(discountsService: IDiscountsService, customerId: number): IDiscountsManager {
        return customerId && customerId > 0 ? discountsService.getDiscountsManagerBySupplierId(customerId) : null;
    }

    public removeAll() {
        this.Rows().forEach((r) => {
            r.setDefaultValues(undefined);
            this.UnderStockArticles.push(r);
        });
        this.Rows([]);
    }

    public addSelectedRows() {
        const selectedRows = this.SelectedArticles();
        selectedRows.forEach((r) => {
            this.Rows.push(r);
            this.UnderStockArticles.remove(r);
        });
    }

    public removeSelectedRows() {
        const selectedRows = this.SelectedArticles();
        selectedRows.forEach(this.removeArticle.bind(this));
    }

    public removeArticle(article: SelectableDetailedMovementArticle) {
        article.setDefaultValues(undefined);
        this.Rows.remove(article);
        this.UnderStockArticles.push(article);
    }

    public onCustomerSelected(customer: IDataSourceModel<number, ICustomerForList>) {
        this.onCustomersSelectionChanged();
    }

    public onCustomerDeselected(customer: IDataSourceModel<number, ICustomerForList>) {
        this.onCustomersSelectionChanged();
    }

    private onCustomersSelectionChanged() {
        const selectedCustomers = this.SelectedCustomers();
        this.jobOrdersDataSource.setCustomerIds(...selectedCustomers);
        this.SelectedJobOrders([]);

        this.warehousesDataSource.setCustomerIds(...selectedCustomers);
        this.warehousesDataSource.setJobOrderIds();
        this.warehousesDataSource.setGetAllWarehouses(selectedCustomers.length === 0);
        this.SelectedWarehouses([]);

        this.documentsDataSource.setCustomerIds(...selectedCustomers);
        this.documentsDataSource.setJobOrderIds();
        this.documentsDataSource.setFilterByJobOrder(false);
        this.SelectedDocuments([]);

        const selectedJobOrders = this.SelectedJobOrders();
        this.setPlaceholders(selectedJobOrders, selectedCustomers);
    }

    public onJobOrderSelected(jobOrder: IDataSourceModel<number, IJobOrderForTaskBoard>) {
        const selectedCustomers = this.SelectedCustomers();
        if (selectedCustomers.length === 0) this.SelectedCustomers([jobOrder.model.CustomerId]);

        this.onJobOrderSelectionChanged();
    }

    public onJobOrderDeselected(jobOrder: IDataSourceModel<number, IJobOrderForTaskBoard>) {
        this.onJobOrderSelectionChanged();
    }

    private onJobOrderSelectionChanged() {
        const selectedJobOrders = this.SelectedJobOrders();
        this.warehousesDataSource.setJobOrderIds(...selectedJobOrders);
        this.warehousesDataSource.setGetAllWarehouses(selectedJobOrders.length === 0);
        this.SelectedWarehouses([]);

        this.documentsDataSource.setJobOrderIds(...selectedJobOrders);
        this.documentsDataSource.setFilterByJobOrder(selectedJobOrders.length > 0);
        this.SelectedDocuments([]);

        const selectedCustomers = this.SelectedCustomers();
        this.setPlaceholders(selectedJobOrders, selectedCustomers);
    }

    private setPlaceholders(selectedJobOrders: number[], selectedCustomers: number[]) {
        this.JobOrdersSelectPlaceholder(
            selectedCustomers.length > 0
                ? TextResources.Warehouse.AllCustomersJobOrdersPlaceholder
                : TextResources.Warehouse.AllJobOrdersPlaceholder
        );

        this.WarehousesSelectPlaceholder(
            selectedJobOrders.length > 0
                ? TextResources.Warehouse.AllJobOrdersWarehousesPlaceholder
                : selectedCustomers.length > 0
                ? TextResources.Warehouse.AllCustomersWarehousesPlaceholder
                : TextResources.Warehouse.AllWarehousesPlaceholder
        );

        this.CustomerOrdersSelectPlaceholder(
            selectedJobOrders.length > 0
                ? TextResources.Warehouse.AllJobOrdersCustomerOrdersPlaceholder
                : selectedCustomers.length > 0
                ? TextResources.Warehouse.AllCustomersCustomerOrdersPlaceholder
                : TextResources.Warehouse.AllCustomerOrdersPlaceholder
        );
    }

    render() {
        let step: WarehouseUnderStockArticlesDataSource;
        let row: SelectableDetailedMovementArticle;
        let outRow: SelectableDetailedMovementArticle;

        return ComponentUtils.bindTo(
            <Layout.WithHeader>
                <Layout.WithHeader.Header className={classNames(classes.filters, "flex-vertical")}>
                    <div className="row root-filters">
                        <div className="col-md-6">
                            <SelectMultiple.WithLabel
                                dataSource={this.customersDataSource}
                                value={this.SelectedCustomers}
                                label={
                                    <label className="control-label">
                                        {TextResources.Warehouse.Customers}
                                        <i
                                            className="fa fa-question-circle help"
                                            title={TextResources.Warehouse.CustomersSelectHelp}
                                        ></i>
                                    </label>
                                }
                                placeholder={this.CustomersSelectPlaceholder}
                                allowClear
                                onSelect={this.onCustomerSelected.bind(this)}
                                onDeselect={this.onCustomerDeselected.bind(this)}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectMultiple.WithLabel
                                dataSource={this.jobOrdersDataSource}
                                value={this.SelectedJobOrders}
                                label={
                                    <label className="control-label">
                                        {TextResources.Warehouse.JobOrders}
                                        <i
                                            className="fa fa-question-circle help"
                                            title={TextResources.Warehouse.JobOrdersSelectHelp}
                                        ></i>
                                    </label>
                                }
                                placeholder={this.JobOrdersSelectPlaceholder}
                                allowClear
                                onSelect={this.onJobOrderSelected.bind(this)}
                                onDeselect={this.onJobOrderDeselected.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <SelectMultiple.WithLabel
                                dataSource={this.warehousesDataSource}
                                value={this.SelectedWarehouses}
                                label={TextResources.Warehouse.Warehouses}
                                placeholder={this.WarehousesSelectPlaceholder}
                                allowClear
                            />
                        </div>
                        <div className="col-md-4">
                            <SelectMultiple.WithLabel
                                dataSource={this.documentsDataSource}
                                value={this.SelectedDocuments}
                                label={TextResources.Warehouse.CustomerOrders}
                                placeholder={this.CustomerOrdersSelectPlaceholder}
                                allowClear
                            />
                        </div>
                        <div className="col-md-4">
                            <DateTimeInput
                                label={TextResources.Warehouse.ShippingDate}
                                value={this.ShippingDate}
                                placeholder={TextResources.Warehouse.ShippingDatePlaceholder}
                                allowClear
                                dateonly
                                parent=".modal-body"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <SelectMultiple.WithLabel
                                dataSource={this.manufacturersDataSource}
                                value={this.SelectedManufacturers}
                                label={TextResources.Warehouse.Manufacturers}
                                placeholder={TextResources.Warehouse.AllManufacturersPlaceholder}
                                allowClear
                            />
                        </div>
                        <div className="col-md-4">
                            <SelectMultiple.WithLabel
                                dataSource={this.suppliersDataSource}
                                value={this.SelectedSuppliers}
                                label={TextResources.Warehouse.DefaultSuppliers}
                                placeholder={TextResources.Warehouse.AllSuppliersPlaceholder}
                                allowClear
                            />
                        </div>
                        <div className="col-md-4">
                            <SelectMultiple.WithLabel
                                dataSource={this.discountFamiliesDataSource}
                                value={this.SelectedDiscountFamilies}
                                label={TextResources.Warehouse.DiscountFamilies}
                                placeholder={TextResources.Warehouse.AllDiscountFamiliesPlaceholder}
                                allowClear
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 flex-container">
                            <div className="flex-1" style={{ marginRight: "15px" }}>
                                <TextInput
                                    label={TextResources.Invoices.DocumentWizardSearchArticleLabel}
                                    placeholder={TextResources.Invoices.DocumentWizardSearchArticlePlaceholder}
                                    value={this.SearchText}
                                    valueUpdate="afterkeydown"
                                />
                            </div>
                            <div
                                className="flex-1"
                                style={{
                                    marginLeft: "15px",
                                    alignSelf: "end",
                                    paddingBottom: "15px",
                                    textAlign: "center",
                                }}
                            >
                                <button
                                    style={{ marginRight: "15px" }}
                                    type="button"
                                    className="btn btn-success"
                                    data-bind={{ click: step.addSelectedRows }}
                                >
                                    {TextResources.Invoices.DocumentWizardImportSelected}
                                </button>
                                <button type="button" className="btn btn-danger" data-bind={{ click: step.removeAll }}>
                                    {TextResources.Invoices.DocumentWizardRemoveAll}
                                </button>
                            </div>
                        </div>
                    </div>
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content>
                    <div className="row" style="height: 100%">
                        <div className="col-md-6 under-stock-data-source-table">
                            <table
                                className="table table-striped table-bordered table-advance table-hover"
                                style="margin-bottom: 0px !important;"
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center" style="width: 40px">
                                            <div className="checker">
                                                <span data-bind={{ css: { checked: step.AllSelected } }}>
                                                    <input type="checkbox" data-bind={{ checked: step.AllSelected }} />
                                                </span>
                                            </div>
                                        </th>
                                        <th className="text-left">
                                            {TextResources.Invoices.DocumentWizardDescription}
                                        </th>
                                        <th className="text-center" style="width: 120px">
                                            {TextResources.Invoices.DocumentWizardAvailability}
                                        </th>
                                        <th className="text-center" style="width: 120px">
                                            {TextResources.Invoices.DocumentWizardReserved}
                                        </th>
                                        <th className="text-center" style="width: 120px">
                                            {TextResources.Invoices.DocumentWizardMinimumStock}
                                        </th>
                                        <th className="text-center" style="width: 120px">
                                            {TextResources.Invoices.DocumentWizardToOrder}
                                        </th>
                                        <th className="text-center" style="width: 55px">
                                            {TextResources.Invoices.DocumentWizardUoM}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="height: 0px !important;"></tbody>
                            </table>

                            <div className="table-body-wrapper">
                                <div data-bind={{ slimScroll: "auto" }}>
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                        <thead style="height: 0px !important"></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if: step.UnderStockArticles().length == 0 }}>
                                                <tr>
                                                    <td colSpan={5} className="text-center">
                                                        {TextResources.Invoices.DocumentWizardNoArticle}
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind
                                                data-bind={{
                                                    foreach: { data: step.UnderStockArticles, as: "row" },
                                                }}
                                            >
                                                <tr data-bind={{ css: { active: row.Selected } }}>
                                                    <td className="text-center" style="width: 40px">
                                                        <div className="checker">
                                                            <span data-bind={{ css: { checked: row.Selected } }}>
                                                                <input
                                                                    type="checkbox"
                                                                    data-bind={{ checked: row.Selected }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="text-left"
                                                        data-bind={{ text: row.Description }}
                                                    ></td>
                                                    <td className="text-center" style="width: 120px">
                                                        <span data-bind={{ numberText: row.Available }}></span>
                                                    </td>
                                                    <td className="text-center" style="width: 120px">
                                                        <span data-bind={{ numberText: row.Reserved }}></span>
                                                    </td>
                                                    <td
                                                        className="text-right"
                                                        style="width: 120px"
                                                        data-bind={{ numberText: row.UnderStock }}
                                                    ></td>
                                                    <td
                                                        className="text-right"
                                                        style="width: 120px"
                                                        data-bind={{ numberText: row.Amount }}
                                                    ></td>
                                                    <td
                                                        className="text-center"
                                                        style="width: 55px"
                                                        data-bind={{ text: row.UOM }}
                                                    ></td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 under-stock-data-source-table">
                            <table className="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-left">
                                            {TextResources.Invoices.DocumentWizardDescription}
                                        </th>
                                        <th className="text-center" style="width : 120px">
                                            {TextResources.Invoices.DocumentWizardAvailability}
                                        </th>
                                        <th className="text-center" style="width : 120px">
                                            {TextResources.Invoices.DocumentWizardReserved}
                                        </th>
                                        <th className="text-center" style="width : 120px">
                                            {TextResources.Invoices.DocumentWizardMinimumStock}
                                        </th>
                                        <th className="text-right" style="width : 120px">
                                            {TextResources.Invoices.DocumentWizardAmount}
                                        </th>
                                        <th className="text-center" style="width : 75px">
                                            {TextResources.Invoices.DocumentWizardUoM}
                                        </th>
                                        <th className="text-center" style="width : 120px">
                                            {TextResources.Invoices.DocumentWizardRemove}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="height: 0px !important"></tbody>
                            </table>

                            <div className="table-body-wrapper">
                                <div data-bind={{ slimScroll: "auto" }}>
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                        <thead style="height: 0px !important"></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if: step.Rows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={7} style="text-align : center">
                                                        {TextResources.Invoices.DocumentWizardNoRowAvailable}
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: { data: step.Rows, as: "outRow" } }}>
                                                <tr>
                                                    <td data-bind={{ text: outRow.Description }}></td>
                                                    <td className="text-center" style="width : 120px">
                                                        <span data-bind={{ numberText: outRow.Available }}></span>
                                                    </td>
                                                    <td className="text-center" style="width : 120px">
                                                        <span data-bind={{ numberText: outRow.Reserved }}></span>
                                                    </td>
                                                    <td className="text-center" style="width : 120px">
                                                        <span data-bind={{ numberText: outRow.UnderStock }}></span>
                                                    </td>
                                                    <td style="width : 120px">
                                                        <input
                                                            type="text"
                                                            className="form-control input-sm text-center"
                                                            data-bind={{
                                                                numberValue: outRow.Amount,
                                                                selectOnFocus: {},
                                                            }}
                                                        />
                                                    </td>
                                                    <td style="width : 75px">
                                                        <input
                                                            type="text"
                                                            className="form-control input-sm text-center"
                                                            data-bind={{ value: outRow.UOM, selectOnFocus: {} }}
                                                        />
                                                    </td>
                                                    <td className="text-center" style="width : 120px">
                                                        <a
                                                            href="#"
                                                            className="btn btn-xs red"
                                                            data-bind={{
                                                                click: step.removeArticle.bind(step, outRow),
                                                            }}
                                                        >
                                                            <i className="fa fa-times"></i>
                                                            {TextResources.Invoices.DocumentWizardRemove}
                                                        </a>
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.WithHeader.Content>
            </Layout.WithHeader>,
            this,
            "step"
        );
    }
}
