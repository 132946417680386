import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import {
    ComponentUtils,
    ComponentParamArray,
    ParamArray,
    Param,
    ComponentParam,
} from "../../../Core/utils/ComponentUtils";
import jss from "jss";
import { TextResources } from "../../ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { IDocumentCurrencyViewModel } from "../../interfaces/invoice/IDocumentsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";

const { classes } = jss
    .createStyleSheet({
        documentCurrenciesManager: {
            width: "100%",

            "& .help-icon": {
                "font-size": "18px",
                "margin-left": "10px",
            },

            "& .actions": {
                padding: "0px",
                height: "34px",
                "justify-content": "center",
                "align-items": "center",
            },

            "& .fields": {
                "padding-left": "0px",
            },

            "& .table-row": {
                margin: "0",
                "padding-top": "5px",
                "border-bottom": "1px solid #ddd",

                "& .form-group": {
                    "margin-bottom": "5px",
                },
            },
        },
    })
    .attach();

let attributes = {
    DocumentCurrencies: "document-currencies",
    ReadOnly: "readonly",
    ShowEditButtonForExchangeValues: "show-edit-button-for-exchange-values",
    HeaderMessage: "header-message",
    InjectTo: "inject-to",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "document-currencies-manager": {
                params?: {
                    DocumentCurrencies: IDocumentCurrencyViewModel[];
                    ReadOnly: boolean;
                    ShowEditButtonForExchangeValues: boolean;
                    HeaderMessage: string;
                    InjectTo: IDocumentCurrenciesManager;
                };

                "document-currencies": IDocumentCurrencyViewModel[] | (() => string);
                readonly: boolean | (() => string);
                "show-edit-button-for-exchange-values": boolean | (() => string);
                "header-message": string | (() => string);
                "inject-to": IDocumentCurrenciesManager | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IDocumentCurrenciesManagerParams {
    DocumentCurrencies: ParamArray<IDocumentCurrencyViewModel>;
    ReadOnly: Param<boolean>;
    ShowEditButtonForExchangeValues: Param<boolean>;
    HeaderMessage: Param<string>;
    InjectTo: Param<IDocumentCurrenciesManager>;
}

export interface IDocumentCurrenciesManager {
    addCurrency(): void;
    removeCurrency(currency: IDocumentCurrencyViewModel): void;
    validate(): boolean;
}

export class DocumentCurrenciesManager implements IDocumentCurrenciesManager {
    public DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;
    public OtherCurrencies: ko.Computed<IDocumentCurrencyViewModel[]>;

    public HeaderMessage: ComponentParam<string>;
    public ReadOnly: ComponentParam<boolean>;
    public ShowEditButtonForExchangeValues: ComponentParam<boolean>;
    public DocumentCurrencies: ComponentParamArray<IDocumentCurrencyViewModel>;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(params: IDocumentCurrenciesManagerParams) {
        this.HeaderMessage = ComponentUtils.parseParameter(params.HeaderMessage, undefined);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.DocumentCurrencies = ComponentUtils.parseParameterArray(params.DocumentCurrencies, []);
        this.ShowEditButtonForExchangeValues = ComponentUtils.parseParameter(
            params.ShowEditButtonForExchangeValues,
            true
        );

        this.DocumentCurrency = ko.computed(() => {
            return this.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
        });

        this.OtherCurrencies = ko.computed(() => {
            return this.DocumentCurrencies().filter((c) => !c.IsDocumentCurrency());
        });

        let injectTo = ComponentUtils.parseParameter(params.InjectTo, undefined);
        if (injectTo) injectTo(this);
    }

    public addCurrency(): void {
        let currencies = this.DocumentCurrencies();
        currencies.push(this.createNewCurrency());
        this.DocumentCurrencies(currencies);
    }

    public removeCurrency(currency: IDocumentCurrencyViewModel): void {
        let currencies = this.DocumentCurrencies();
        let index = currencies.indexOf(currency);

        if (index < 0) return;

        currencies.splice(index, 1);

        this.DocumentCurrencies(currencies);
    }

    public validate(): boolean {
        let currencies = this.DocumentCurrencies();

        for (let currency of currencies) {
            if (!currency.CurrencyId()) {
                this.infoToastService.Warning(TextResources.Invoices.CurrencySelectionRequired);
                return false;
            }

            if (!currency.ExchangeValue()) {
                this.infoToastService.Warning(
                    String.format(
                        TextResources.Invoices.CurrencyExchangeValueRequired,
                        currency.Currency().CodeISO4217alpha3
                    )
                );
                return false;
            }

            if (currency.IsDocumentCurrency() && !currency.ExchangeValueForVat()) {
                this.infoToastService.Warning(TextResources.Invoices.CurrencyExchangeValueForVatRequired);
                return false;
            }
        }

        return true;
    }

    private createNewCurrency(): IDocumentCurrencyViewModel {
        return this.documentsService.DocumentCurrenciesFactory.create(null, null);
    }
}

ko.components.register("document-currencies-manager", {
    viewModel: {
        createViewModel: (params: IDocumentCurrenciesManagerParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new DocumentCurrenciesManager(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={classes.documentCurrenciesManager}>
                    <ko-with data-bind="HeaderMessage">
                        <div class="row" style="margin-bottom: 15px">
                            <div class="col-md-12" data-bind="text: $data"></div>
                        </div>
                    </ko-with>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="bold">
                                {TextResources.Invoices.DocumentCurrencyTableLabel}
                                <i
                                    class="fa fa-info-circle help-icon"
                                    title={TextResources.Invoices.DocumentCurrenciesManagerHelpText}
                                ></i>
                            </label>
                        </div>
                    </div>
                    <div class="row table-row">
                        <div class="col-md-11 fields">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="control-label">{TextResources.Invoices.CurrencyLabel}</label>
                                </div>
                                <div class="col-md-4">
                                    <label class="control-label">
                                        {TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValue}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <label class="control-label">
                                        {TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValueForVat}
                                    </label>
                                </div>
                            </div>
                            <ko-with data-bind="DocumentCurrency">
                                <currency-input
                                    currency={() => "CurrencyId"}
                                    exchange-value={() => "ExchangeValue"}
                                    exchange-value-for-vat={() => "ExchangeValueForVat"}
                                    is-document-currency={() => "IsDocumentCurrency"}
                                    show-edit-button-for-exchange-values={() =>
                                        "$parent.ShowEditButtonForExchangeValues"
                                    }
                                    listeners={() => "[$data]"}
                                    readonly={() => "$parent.ReadOnly"}
                                    show-labels={false}
                                ></currency-input>
                            </ko-with>
                        </div>
                        <div class="col-md-11 actions"></div>
                    </div>
                    <div class="row" style="margin-top: 15px">
                        <div class="col-md-12">
                            <label class="bold">
                                {TextResources.Invoices.OtherCurrencies}
                                <i
                                    class="fa fa-info-circle help-icon"
                                    title={TextResources.Invoices.DocumentCurrenciesManagerOtherCurrenciesHelpText}
                                ></i>
                            </label>
                        </div>
                    </div>
                    <div class="row table-row">
                        <div class="col-md-11 fields">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="control-label">{TextResources.Invoices.CurrencyLabel}</label>
                                </div>
                                <div class="col-md-4">
                                    <label class="control-label">
                                        {TextResources.Invoices.CurrencyToDocumentCurrencyExchangeValue}
                                    </label>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                        </div>
                        <div class="col-md-1 actions text-center flex-container" data-bind="ifnot: ReadOnly">
                            <button class="btn btn-primary btn-circle btn-xs" data-bind="click: addCurrency">
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <ko-if data-bind="OtherCurrencies().length === 0">
                        <div class="row">
                            <div class="col-md-12 text-center">{TextResources.Invoices.EmptyCurrenciesList}</div>
                        </div>
                    </ko-if>
                    <ko-foreach data-bind="OtherCurrencies">
                        <div class="row table-row">
                            <div class="col-md-11 fields">
                                <currency-input
                                    currency={() => "CurrencyId"}
                                    exchange-value={() => "ExchangeValue"}
                                    exchange-value-for-vat={() => "ExchangeValueForVat"}
                                    is-document-currency={() => "IsDocumentCurrency"}
                                    show-edit-button-for-exchange-values={() =>
                                        "$parent.ShowEditButtonForExchangeValues"
                                    }
                                    listeners={() => "[$data]"}
                                    readonly={() => "$parent.ReadOnly"}
                                    show-labels={false}
                                ></currency-input>
                            </div>
                            <div
                                class="col-md-1 actions text-center flex-container"
                                data-bind="ifnot: $parent.ReadOnly"
                            >
                                <button
                                    class="btn btn-danger btn-circle btn-xs"
                                    data-bind="click: $parent.removeCurrency.bind($parent, $data)"
                                >
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </div>
                        </div>
                    </ko-foreach>
                </div>,
            ]);

            return vm;
        },
    },
    template: [],
});
