import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { EstimateDefaultValuesSettingsUi } from "../ui/documents/default-values/EstimateDefaultValuesSettingsUi";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class EstimatesProvider extends DocumentsProvider
{
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;
    
    constructor(private vatRegister : IVatRegister)
    {
        super(ProlifeSdk.EstimateEntityTypeCode);

        this.ProviderId = "Est";
        this.CanEdit = vatRegister.Stato == ProlifeSdk.EnabledProtocolState;

        this.Id = vatRegister.IdRegistroIVA;
        this.Name(vatRegister.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(vatRegister.NuovoLabel);

        this.CanCreateNew(vatRegister.Stato == ProlifeSdk.EnabledProtocolState && this.authorizationService.isAuthorized("Documents_Estimates"));

        this.ProviderId = "Est" + this.Id;
        this.HasDefaultValuesSettingsUi = true;
        this.DefaultSearchProvider = vatRegister.DefaultSearchProvider;
    }

    public GetDocumentGroupLabel(): string {
        return this.vatRegister.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.vatRegister.PositionInList;
    }

    GetDefaultValuesSettingsUi() {
        return new EstimateDefaultValuesSettingsUi(this.vatRegister, this.ProviderId);
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        return [{
            DocumentType: ProlifeSdk.EstimateEntityTypeCode,
            NewLabel: this.vatRegister.NuovoLabel
        }];
    }
}
