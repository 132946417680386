import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { RichTextInput } from "../../../Components/RichTextInput";

type QuotationDescriptionDialogProps = {
    description: ko.Observable<string>; 
}

export function QuotationDescription(props: QuotationDescriptionDialogProps & { forwardRef: (e : _QuotationDescription) => void; }) {
    const C = require("./QuotationDescriptionDialog")._QuotationDescription as typeof _QuotationDescription;
        return <C {...props} />;
}

export class _QuotationDescription {
    private description : ko.Observable<string> = ko.observable();

    constructor(private props : QuotationDescriptionDialogProps & { forwardRef: (e : _QuotationDescription) => void; }) {
        this.description(this.props.description());
        this.props.forwardRef(this);
    }

    getDescription() {
        return this.description();
    }

    render() {
        return <div className="row">
            <div className="col-md-12">
                <RichTextInput value={this.description} resizable height={580} />
            </div>
        </div>;
    }
}

export class QuotationDescriptionDialog extends DialogComponentBase {
    static defaultProps: Partial<QuotationDescriptionDialogProps> = {
    }

    editor : _QuotationDescription;

    constructor(private props : QuotationDescriptionDialogProps) {
        super({ className: "large" });

        this.title("Modifica Descrizione");
    }
    
    action() {
        this.modal.close(this.editor.getDescription());
    }
    
    renderBody() {
        return <QuotationDescription forwardRef={(e) => this.editor = e} {...this.props} />;  
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationDescriptionDialog);
}