import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 10/09/2018
 * Time: 09:49
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { MonthlyInvoicingDataRow } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/MonthlyInvoicingDataRow";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { BaseDataSourceForMonthlyInvoicing } from "../../../../Invoices/invoices/documents/wizard/data-sources/BaseDataSourceForMonthlyInvoicing";
import { WorkflowsForSelectListDataSource } from "../../../../DataSources/WorkflowsForSelectListDataSource";
import { TasksDataSource } from "../../../../DataSources/TasksDataSource";
import { ICurrenciesSettingsManager } from "../../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDataSourceForMonthlyInvoicing, IMonthlyInvoicingDataSource, IMonthlyInvoicingDataSourceBaseFilters, IMonthlyInvoicingOptions, ICustomerForMonthlyInvoicing, IJobOrderForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IPurchaseForMonthlyInvoicingWizard } from "../../../../ProlifeSdk/interfaces/desktop/IPurchaseForMonthlyInvoicingWizard";
import { ILabelInfo, IEntityToImportInfoForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IDDTToImportForMonthlyInvoicingInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDDTToImportForMonthlyInvoicingInfo";
import { IEntityForMonthlyInvoicingTree, IPurchasesEnvelopeForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IPurchaseToImportForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/financial-data/IPurchasesForDocuments";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IPurchasesService, IGetPurchasesForMonthlyInvoicingRequest, IPurchaseForMonthlyInvoicingWithCurrency, IPurchaseForMonthlyInvoicing } from "../../../../Blog/interfaces/IPurchasesService";
import { IPurchaseForMonthlyInvoicingViewModel } from "../../../interfaces/IPurchaseForMonthlyInvoicing";
import { Deferred } from "../../../../Core/Deferred";

export class PurchasesDataSourceForMonthlyInvoicing extends BaseDataSourceForMonthlyInvoicing implements IDataSourceForMonthlyInvoicing {
    public Purchases: ko.ObservableArray<IPurchaseForMonthlyInvoicingViewModel> = ko.observableArray();
    public RowsToImport: ko.ObservableArray<IPurchaseForMonthlyInvoicingViewModel> = ko.observableArray();
    public ShowBilled: ko.Observable<boolean> = ko.observable(false);

    public SelectAll: ko.Computed<boolean>;
    public Total: ko.Computed<number>;

    public WorkflowId: ko.Observable<number> = ko.observable();
    public TaskId: ko.Observable<number> = ko.observable();
    
    public WorkflowsDataSource: WorkflowsForSelectListDataSource;
    public TasksDataSource: TasksDataSource;

    private wizard: IMonthlyInvoicingDataSource;
    
    @LazyImport(nameof<IPurchasesService>())
    private purchasesService: IPurchasesService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;
    
    constructor() {
        super("purchases-step-for-monthly-invoicing", "desktop/templates/wizard/data-sources", ProlifeSdk.TextResources.Desktop.Purchases);

        this.registerSupportedDestinationDocumentTypeCodes();
        this.documentsService.registerDocumentDataSourceForMonthlyInvoicing(this);

        const userId = this.userInfoService.getIdUser();
        const resourceId = this.resourcesManager.getLoggedResourceId();

        this.WorkflowsDataSource = new WorkflowsForSelectListDataSource();
        this.WorkflowsDataSource.setResourceId(resourceId);
        this.WorkflowsDataSource.setViewAll(true);
        this.WorkflowsDataSource.setViewResponsible(true);
        this.WorkflowsDataSource.setViewWorker(true);
        
        this.TasksDataSource = new TasksDataSource();
        this.TasksDataSource.setUserId(userId);
        this.TasksDataSource.setResourceId(resourceId);
        this.TasksDataSource.setBypassCanViewAllForJobOrderTasksPlanning(true);
        this.TasksDataSource.setGetTasksIfNoFilter(true);
        this.TasksDataSource.setGetWorkflowsInWorkflows(false);
        this.TasksDataSource.setShowClosed(true);
        this.TasksDataSource.setViewAll(true);
        
        this.JobOrder.subscribe((id) => {
            this.WorkflowsDataSource.setJobOrderId(id);
            this.TasksDataSource.setJobOrderId(id);
        });

        this.WorkflowId.subscribe((id) => {
            this.TasksDataSource.setWorkflows([id]);
        });

        this.installWatches();

        this.SelectAll = ko.computed({
            read: () => {
                var purchases = this.Purchases();
                return purchases.filter((r : PurchaseForMonthlyInvoicing) => !r.Selected()).length == 0;
            },
            write: (selected : boolean) => {
                var purchases = this.Purchases();
                purchases.forEach((r : PurchaseForMonthlyInvoicing) => r.Selected(selected));
            }
        });

        this.Total = ko.computed(() =>{
            var total = 0;
            this.Rows().forEach((r : IPurchaseToImportForMonthlyInvoicing) => total += r.NetPrice);
            return total;
        });
    }
    
    public Initialize()
    {
        this.DisableDocumentsReload = true;
        
        super.Initialize();
        this.ShowBilled(false);

        this.DisableDocumentsReload = false;

        if (this.loadRowsAfterInitialize)
            this.reloadPurchases();
    }

    public InitializeFilters(filters: IMonthlyInvoicingDataSourceBaseFilters): void {
        super.InitializeFilters(filters);
        this.reloadPurchases();
    }

    public SetDataSourceOptions(monthlyInvoicingOptions: IMonthlyInvoicingOptions): void {}

    public SetWizard(wizard: IMonthlyInvoicingDataSource): void {
        this.wizard = wizard;
    }

    public async GetDocumentsToImportInfo(): Promise<IDocumentToImportInfo[]> {
        var result: IPurchaseForMonthlyInvoicingWizard[] = [];
        var rows: IPurchaseToImportForMonthlyInvoicing[] = this.Rows();

        rows.forEach((r: IPurchaseToImportForMonthlyInvoicing) => {
            result.push(new PurchaseForGrouping(r, this.wizard));
        });

        return result;
    }

    private installWatches()
    {
        this.DisableDocumentsReload = true;

        this.DateFrom.subscribe(() => {
            if(!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateTo(moment(this.DateFrom()).endOf("day").toDate());

            this.reloadPurchases();
        });

        this.DateTo.subscribe(() => {
            if(!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateFrom(moment(this.DateTo()).startOf("day").toDate());

            this.reloadPurchases();
        });

        this.Customer.subscribe(this.reloadPurchases.bind(this));
        this.JobOrder.subscribe(this.reloadPurchases.bind(this));
        this.JobOrderType.subscribe(this.reloadPurchases.bind(this));
        this.WorkflowId.subscribe(this.reloadPurchases.bind(this));
        this.TaskId.subscribe(this.reloadPurchases.bind(this));

        this.ShowBilled.subscribe(this.reloadPurchases.bind(this));

        this.DisableDocumentsReload = false;
    }

    public loadPurchases(): void {
        if (this.DisableDocumentsReload)
            return;

        this.DisableDocumentsReload = true;

        const request: IGetPurchasesForMonthlyInvoicingRequest = {
            JobOrderId: this.JobOrder(),
            JobOrderType: this.JobOrderType(),
            CustomerId: this.Customer(),
            Billed: this.ShowBilled(),
            From: this.DateFrom(),
            To: this.DateTo(),
            WorkflowId: this.WorkflowId(),
            TaskId: this.TaskId()
        };

        this.purchasesService.GetPurchasesForMonthlyInvoicingCount(request)
            .then((count: number) => {
                if (count < 1000) {
                    this.purchasesService.GetPurchasesForMonthlyInvoicing(request)
                        .then(this.onPurchasesLoaded.bind(this))
                        .finally(() => {
                            this.DisableDocumentsReload = false;
                        });
                    return;
                }

                this.confirmRowsSearch(count)
                    .then((confirm: boolean) => {
                        if (confirm) {
                            this.purchasesService.GetPurchasesForMonthlyInvoicing(request)
                                .then(this.onPurchasesLoaded.bind(this))
                                .finally(() => {
                                    this.DisableDocumentsReload = false;
                                });

                            return;
                        }

                        this.DisableDocumentsReload = false
                    });
            })
            .catch(() => {
                this.DisableDocumentsReload = false
            });
    }

    private confirmRowsSearch(rows: number): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            String.format(ProlifeSdk.TextResources.Desktop.ConfirmEntitiesSearch, rows),
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private reloadPurchases()
    {
        if (this.DisableDocumentsReload)
            return;

        this.Purchases([]);
        this.loadPurchases();
    }

    public importDetailed()
    {
        this.filterRows().forEach(this.addDetailedRow.bind(this));
    }

    public importAggregated()
    {
        this.aggregateByJobOrderId(this.filterRows()).forEach((purchases: IPurchaseForMonthlyInvoicingWithCurrency[]) => {
            this.addAggregatedRow(purchases);
        });
    }

    public EmptiesImportList(): void {
        this.Rows([]);
    }

    private aggregateByJobOrderId(rows: IPurchaseForMonthlyInvoicing[]): IPurchaseForMonthlyInvoicing[][]
    {
        return rows.reduce((prev: any, item: IPurchaseForMonthlyInvoicing) => {
            if (prev[item.JobOrderId])
                prev[item.JobOrderId].push(item);
            else
                prev[item.JobOrderId] = [item];
            return prev;
        }, []);
    }

    private filterRows(): IPurchaseForMonthlyInvoicingWithCurrency[]
    {
        var selectedPurchases = this.Purchases().filter((r: IPurchaseForMonthlyInvoicingViewModel) => r.Selected());
        if(selectedPurchases.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.NoPurchasesSelected);
            return [];
        }

        var notImportedPurchases: IPurchaseForMonthlyInvoicingWithCurrency[] = selectedPurchases
            .map((r: IPurchaseForMonthlyInvoicingViewModel) => r.getPurchaseToImport())
            .filter((p: IPurchaseForMonthlyInvoicingWithCurrency) => !this.wasUsedDuringThisWizard(p));

        if(notImportedPurchases.length != selectedPurchases.length) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.ImportPurchasesWarning);
        }

        return notImportedPurchases;
    }

    private addDetailedRow(purchase: IPurchaseForMonthlyInvoicingWithCurrency)
    {
        var purchases: IPurchaseToImportForMonthlyInvoicing = {
            Purchases: [purchase],
            Description: moment(purchase.PurchaseDate).format("L") + " - " + purchase.Description,
            CustomerId: purchase.CustomerId,
            JobOrderId: purchase.JobOrderId,
            JobOrderName: purchase.JobOrderName,
            Amount: purchase.Amount,
            NetUnitPrice: purchase.NetMoney,
            NetPrice: purchase.TotalMoney,
            CurrencyCode: purchase.CurrencyCode,
            CurrencySymbol: purchase.CurrencySymbol
        };

        this.Rows.push(purchases);
    }

    private addAggregatedRow(purchases: IPurchaseForMonthlyInvoicingWithCurrency[])
    {
        var totalSellPrice: number = purchases.reduce((total: number, purchase: IPurchaseForMonthlyInvoicing) => total + purchase.TotalMoney, 0);
        var description: string = ProlifeSdk.TextResources.Desktop.Purchases + " ";
        description += this.DateFrom() ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L")) : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo() ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L")) : ProlifeSdk.TextResources.Desktop.EndDateToday;

        var purchaseToImport: IPurchaseToImportForMonthlyInvoicing = {
            Purchases: purchases,
            Description: description,
            CustomerId: purchases.reduce((acc: number, current) => { acc = !acc? current.CustomerId : acc; return acc; }, null),
            JobOrderId: purchases.reduce((acc: number, current) => { acc = !acc? current.JobOrderId : acc; return acc; }, null),
            JobOrderName: purchases[0].JobOrderName,
            Amount: 1,
            NetUnitPrice: totalSellPrice,
            NetPrice: totalSellPrice,
            CurrencyCode: purchases[0].CurrencyCode, // N.B. per il momento gli acquisti sono sempre in Euro, quindi posso aggregare senza problemi. In futuro questa logica andrà rivista nel caso venag introdotta la possibilità di fare acquisti in valuta diversa da Euro
            CurrencySymbol: purchases[0].CurrencySymbol
        };

        this.Rows.push(purchaseToImport);
    }

    public removeRow(row: IPurchaseToImportForMonthlyInvoicing)
    {
        this.Rows.remove(row);
    }

    private wasUsedDuringThisWizard(purchase: IPurchaseForMonthlyInvoicing): boolean
    {
        return this.Rows().filter((r: IPurchaseToImportForMonthlyInvoicing) => r.Purchases.filter((p: IPurchaseForMonthlyInvoicing) => purchase.PurchaseId == p.PurchaseId).length > 0).length > 0;
    }

    private async onPurchasesLoaded(purchases: IPurchaseForMonthlyInvoicing[]): Promise<void>
    {
        await this.loadJobOrdersAndCustomersData(purchases);
        this.Purchases(purchases.map(this.createViewModelFor.bind(this)));
        this.SelectAll(true);
    }
    
    private loadJobOrdersAndCustomersData(purchases: IPurchaseForMonthlyInvoicing[]): Promise<[void, void]> {
        const jobOrdersToLoad: number[] = [];
        const customersToLoad: number[] = [];

        for (const purchase of purchases) {
            if (purchase.JobOrderId)
                jobOrdersToLoad.push(purchase.JobOrderId);

            if (purchase.CustomerId)
                customersToLoad.push(purchase.CustomerId);
        }

        return Promise.all([this.wizard.LoadJobOrdersIntoCache(jobOrdersToLoad), this.wizard.LoadCustomersIntoCache(customersToLoad)]);
    }

    private createViewModelFor(purchase: IPurchaseForMonthlyInvoicing): IPurchaseForMonthlyInvoicingViewModel
    {
        return new PurchaseForMonthlyInvoicing(purchase);
    }

    private registerSupportedDestinationDocumentTypeCodes(): void {
        this.SupportedDestinationDocumentsTypesCodes.push(ProlifeSdk.InvoiceEntityTypeCode);
    }
}

class PurchaseForMonthlyInvoicing implements IPurchaseForMonthlyInvoicingViewModel
{
    public Date: ko.Observable<Date> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public WorkflowTitle: ko.Observable<string> = ko.observable();
    public TaskTitle: ko.Observable<string> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public UnitPrice: ko.Observable<number> = ko.observable();
    public Discount: ko.Observable<string> = ko.observable();
    public NetPrice: ko.Observable<number> = ko.observable();
    public PurchasePrice: ko.Observable<number> = ko.observable();
    public SellPrice: ko.Observable<number> = ko.observable();
    public Billed: ko.Observable<boolean> = ko.observable();
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public CurrencyCode: ko.Observable<string> = ko.observable();

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(private purchase: IPurchaseForMonthlyInvoicing) {
        this.Date(moment(this.purchase.PurchaseDate).toDate());
        this.Description(this.purchase.Description);
        this.JobOrderName(this.purchase.JobOrderName);
        this.WorkflowTitle(this.purchase.WorkflowTitle);
        this.TaskTitle(this.purchase.TaskTitle);

        this.Amount(this.purchase.Amount);
        this.UnitPrice(this.purchase.UnitMoney);
        this.Discount(this.purchase.Discount);
        this.NetPrice(this.purchase.NetMoney);

        this.PurchasePrice(this.purchase.TotalMoney);
        this.SellPrice(this.purchase.SellPrice);
        this.Billed(this.purchase.Billed);

        const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
        this.CurrencySymbol(defaultCurrency.Symbol);
        this.CurrencyCode(defaultCurrency.CodeISO4217alpha3);
    }

    public getPurchaseToImport(): IPurchaseForMonthlyInvoicingWithCurrency
    {
        return {
            PurchaseId: this.purchase.PurchaseId,
            EntityType: this.purchase.EntityType,
            EventId: this.purchase.EventId,
            PurchaseDate: this.Date(),
            JobOrderId: this.purchase.JobOrderId,
            JobOrderName: this.JobOrderName(),
            CustomerId: this.purchase.CustomerId,
            CustomerName: this.purchase.CustomerName,
            Billable: this.purchase.Billable,
            Billed: this.Billed(),
            Description: this.Description(),
            Amount: this.Amount(),
            NetMoney: this.NetPrice(),
            UnitMoney: this.UnitPrice(),
            Discount: this.Discount(),
            TotalMoney: this.PurchasePrice(),
            SellPrice: this.SellPrice(),
            WorkflowId: this.purchase.WorkflowId,
            TaskId: this.purchase.TaskId,
            WorkflowTitle: this.purchase.WorkflowTitle,
            TaskTitle: this.purchase.TaskTitle,
            CurrencyCode: this.CurrencyCode(),
            CurrencySymbol: this.CurrencySymbol()
        };
    }
}

class PurchaseForGrouping extends MonthlyInvoicingDataRow implements IPurchaseForMonthlyInvoicingWizard {
    public templateName: string = "purchase-for-grouping";
    public templateUrl: string = "desktop/templates/wizard/data-sources";

    public Amount: ko.Observable<number> = ko.observable();

    public CustomerId: ko.Observable<number> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public PaymentTypeId: ko.Observable<number> = ko.observable();
    public PaymentType: ko.Observable<string> = ko.observable();
    public ExpiryTypeId: ko.Observable<number> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();

    private customer: ICustomerForMonthlyInvoicing;
    private jobOrder: IJobOrderForMonthlyInvoicing;

    constructor(public purchase: IPurchaseToImportForMonthlyInvoicing, private wizard: IMonthlyInvoicingDataSource) {
        super();

        this.Id = this.purchase.Purchases.length == 1 ? this.purchase.Purchases[0].PurchaseId : null;
        this.EntityType = ProlifeSdk.PurchasesEntityTypeCode;
        this.Name = this.purchase.Description;

        this.JobOrderId(this.purchase.Purchases[0].JobOrderId);
        this.JobOrderName(this.purchase.JobOrderName);
        this.CustomerId(this.purchase.Purchases[0].CustomerId);
        this.CustomerName(this.purchase.Purchases[0].CustomerName);

        this.PaymentTypeId(null);
        this.PaymentType(null);
        this.ExpiryTypeId(null);
        this.ExpiryType(null);

        this.Amount(this.purchase.Amount);
        this.Total(this.purchase.NetPrice);

        this.CurrencyCode(this.purchase.CurrencyCode);
        this.CurrencySymbol(this.purchase.CurrencySymbol);

        this.DocumentCurrencies.push(this.createDocumentCurrencyFromDefaultCurrency(this.Id, this.EntityType));

        this.TotalInDefaultCurrency = ko.computed(() => {
            return this.Total();
        });
    }
    
    public GetLabelInfo(): ILabelInfo {
        return {
            EntityType: this.EntityType,
            Label: this.LabelForInvoiceGrouping(),
            CustomerId: this.CustomerId(),
            JobOrder: this.JobOrderId(),
            PaymentMode: null,
            ExpiryMode: null,
            NumberOfItemsWithLabel: 0
        };
    }

    public VerifyDocumentCompatibility(labelInfo: ILabelInfo): boolean {
        return this.JobOrderId() == labelInfo.JobOrder
            && this.CustomerId() == labelInfo.CustomerId;
    }

    public ApplyLabelToSelectedEntities(): void {
        if (!this.storage())
            return;

        var invalidSelections: string[] = [];

        this.storage().SelectedEntities().forEach((entity: IEntityToImportInfoForMonthlyInvoicing) => {
            if (!entity.VerifyDocumentCompatibility(this.GetLabelInfo())) {
                var name: string = entity.Name;
                if (entity.EntityType == ProlifeSdk.DdtEntityTypeCode)
                    name += " - " + (<IDDTToImportForMonthlyInvoicingInfo>entity).ProtocolName();

                invalidSelections.push(name);
                return;
            }

            entity.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        });

        if (invalidSelections.length > 0) {
            var message: string = "<br/>";
            invalidSelections.forEach((m: string) => message += m + "<br/>");
            message += "<br/>";
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Invoices.InvalidDocumentsForLabelApplication, message));
        }
    }

    public async GetCopy(): Promise<IEntityToImportInfoForMonthlyInvoicing> {
        var wh = new PurchaseForGrouping(this.purchase, this.wizard);
        wh.customer = this.customer;
        wh.jobOrder = this.jobOrder;
        wh.storage(null);
        wh.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        wh.CustomerName(this.CustomerName());
        return wh;
    }

    public GetDocumentForMonthlyInvoicingTree(importedDocuments: IEntityForMonthlyInvoicingTree[], documentLabel: string = null): IEntityForMonthlyInvoicingTree {
        return {
            EntityId: this.Id,
            EntityType: this.EntityType,
            DocumentLabel: documentLabel === null ? this.Name : documentLabel,
            VatRegisterId: null,
            CustomerId: this.CustomerId(),
            CustomerName: this.CustomerName(),
            JobOrderId: this.JobOrderId(),
            ExpiryTypeId: this.ExpiryTypeId(),
            ExpiryType: this.ExpiryType(),
            PaymentTypeId: this.PaymentTypeId(),
            PaymentType: this.PaymentType(),
            PaymentIBAN: null,
            PaymentABI: null,
            PaymentCAB: null,
            DocumentCurrencies: this.DocumentCurrencies().map((c) => c.getData()),
            IsPartialInvoice: false,
            ImportedDocuments: importedDocuments,
            WorkedHoursRow: null,
            PurchasesRow: this.createPurchaseRowForMonthlyInvoicingTree()
        };
    }

    private createPurchaseRowForMonthlyInvoicingTree(): IPurchasesEnvelopeForMonthlyInvoicing {
        return {
            PurchasesIds: this.purchase.Purchases.map((p: IPurchaseForMonthlyInvoicing) => p.PurchaseId),
            AggregationType: this.purchase.Purchases.length == 1 ? ProlifeSdk.DetailedPurchases : ProlifeSdk.AggregatedPurchases
        };
    }
}