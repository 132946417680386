import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ExpenseEvent } from "../ExpenseEvent";
import { PurchaseRevenueRow } from "../../../../Blog/Blog/Purchases/PurchaseRevenueRow";
import { PurchaseCostRow } from "../../../../Blog/Blog/Purchases/PurchaseCostRow";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IPurchase, IPurchaseRow } from "../../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IPurchasesService, ILockedActivity } from "../../../../Blog/interfaces/IPurchasesService";
import { ILogEvent } from "../../../../ProlifeSdk/interfaces/ILogEvent";
import { ITag } from "../../../../ProlifeSdk/interfaces/ITag";
import { Deferred } from "../../../../Core/Deferred";

export class ExpenseEventInput  extends BlogEventBaseInput {

	public templateName: string = "purchase-details";
    public title: string = ProlifeSdk.TextResources.Blog.ExpensesTitle;
    
    public importo: ko.Computed<number>;
    public salesAmount: ko.Computed<number>;
    
    public billed: ko.Observable<boolean> = ko.observable(false);
    public billable: ko.Observable<boolean> = ko.observable(false);
    public SalId: ko.Observable<any> = ko.observable(null);
    public NetImportVariation: ko.Observable<number> = ko.observable(0);
    public costsRows: ko.ObservableArray<PurchaseCostRow> = ko.observableArray([]);
    public revenuesRows: ko.ObservableArray<PurchaseRevenueRow> = ko.observableArray([]);

    public purchase: IPurchase = null;
    public isReferencedBySal: boolean = false;

    @LazyImport(nameof<IPurchasesService>())
    private purchasesService: IPurchasesService;
    @LazyImport(nameof<IBlogService>())
    private blogService: IBlogService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver, private event: ExpenseEvent)
    {
        super(serviceLocator, contextEventsObserver);
        this.iconName = "f-icon-basket-1";

        this.billable.subscribe((v) => {
            if(!v)
                this.billed(false);
        });

        this.setValues(event);

        this.importo = ko.computed(() => {
            var totalCosts = 0;
            this.costsRows().forEach((r : PurchaseCostRow) => {
                totalCosts += r.TotalCost();
            });
            return totalCosts;
        });

        this.salesAmount = ko.computed(() => {
            var totalRevenues = 0;
            this.revenuesRows().forEach((r : PurchaseRevenueRow) => {
                totalRevenues += r.TotalPrice();
            });
            return totalRevenues;
        });
    }

    public setValues(item: ExpenseEvent) : void
    {
        super.setValues(item);
        //this.importo(item.Amount());
        //this.salesAmount(item.SalesAmount());
        this.SalId(item.SalId());
        this.billed(item.Billed());
        this.billable(item.Billable());

        this.loadRows(item);
    }

    private loadRows(item: ExpenseEvent)
    {
        this.blogService.purchasesProvider.getByEventId(item.IdEvent())
            .then((p : IPurchase) => {
                if(!p)
                    return;

                var referencedRows = p.RevenuesRows.filter((r : IPurchaseRow) => r.ReferencingRows.filter((r : IRefDocumentRow) => r.DestEntityType == ProlifeSdk.SalEntityTypeCode).length > 0);
                this.isReferencedBySal = referencedRows.length > 0;

                this.purchase = p;

                this.costsRows(p.CostsRows.map((r : IPurchaseRow) => new PurchaseCostRow(this.serviceLocator, r, this.selectedTasksObservable)));
                this.revenuesRows(p.RevenuesRows.map((r : IPurchaseRow) => new PurchaseRevenueRow(this.serviceLocator, r, this.selectedTasksObservable)));
            });
    }

    getBlogEvent(): ILogEvent
    {
        var localBlogEvent : ILogEvent = super.getBlogEvent();
        localBlogEvent.Tags.push( {TagName : ProlifeSdk.Tag_Billed,
            Value : this.billed() ? ProlifeSdk.TagWork_Invoiced_true : ProlifeSdk.TagWork_Invoiced_false,
            TagTypeId : ProlifeSdk.TagType_Bool});

        localBlogEvent.Tags.push(<ITag> {TagName : ProlifeSdk.TagMaterials_Amount,
            Value : this.importo(),
            TagTypeId : ProlifeSdk.TagType_Decimal});

        localBlogEvent.Tags.push(<ITag> {TagName : ProlifeSdk.TagMaterials_SalesAmount,
            Value : this.salesAmount(),
            TagTypeId : ProlifeSdk.TagType_Decimal});

        localBlogEvent.Tags.push({
            TagName : ProlifeSdk.SalEntityType,
            Value : this.SalId(),
            TagTypeId : ProlifeSdk.SalEntityType
        });
        localBlogEvent.Tags.push({
            TagName : ProlifeSdk.TagWorkHours_Billable,
            Value : this.billable() ? ProlifeSdk.TagWork_Invoiced_true : ProlifeSdk.TagWork_Invoiced_false,
            TagTypeId : ProlifeSdk.TagType_Bool
        });
        localBlogEvent.EventType = "Blog.Materials";
        return localBlogEvent;
    }

	public getValidationMessage() : string[]
    {
		var message : string[] = super.getValidationMessage();

		if (this.subject() == null || this.subject().trim() == "")
			message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

		return message;
	}

    private saveAfterCheck(def : Deferred<ILogEvent | ILogEvent[]>)
    {
        let tasksIds: number[] = this.costsRows().map((r: PurchaseCostRow) => r.AttachedTask() ? r.AttachedTask().Id : 0);
        tasksIds = tasksIds.concat(this.revenuesRows().map((r: PurchaseRevenueRow) => r.AttachedTask() ? r.AttachedTask().Id : 0));

        this.purchasesService.GetLockedActivitiesForEvent(this.pkEvent(), tasksIds.filter(i => i != 0))
            .then((lockedTasks: ILockedActivity[]) => {
                if (lockedTasks.length > 0) {
                    let message: string = ProlifeSdk.TextResources.Blog.PurchasesLockedTasksError;

                    lockedTasks.forEach((t) => {
                        message += String.format(ProlifeSdk.TextResources.Blog.PurchasesLockedTasksErrorRow, t.Title, t.WorkflowTitle);
                    });

                    this.dialogsService.Alert(message, ProlifeSdk.TextResources.Blog.PurchasesLockedTasksErrorTitle, () => { def.reject(); });
                    return;
                }
                
                super.save()
                    .then((data: any) =>
                    {
                        var event : ILogEvent = Object.prototype.toString.call(data) === '[object Array]' ? data[0] : data;

                        this.purchase = this.purchase != null ? this.purchase : {
                            EventId : -1,
                            /*JobOrderId : null,
                            PurchaseDate : JSON.stringify(moment(new Date())).replace(/"/g, ""),
                            Description : null,
                            PurchasePrice : 0,
                            SellPrice : 0,
                            SalId : null,
                            Billed : false,
                            Billable : false,*/
                            CostsRows: [],
                            RevenuesRows: []
                        };

                        this.purchase.EventId = event.EventId;
                        /*this.purchase.JobOrderId = this.commessaId();
                        this.purchase.PurchaseDate = JSON.stringify(moment(this.eventDate())).replace(/"/g, "");
                        this.purchase.Description = this.subject();
                        this.purchase.PurchasePrice = this.importo();
                        this.purchase.SellPrice = this.salesAmount();
                        this.purchase.Billed = this.billed();
                        this.purchase.Billable = this.billable();*/

                        this.purchase.CostsRows = this.costsRows().map((r : PurchaseCostRow) => r.toPurchaseRow(event.EventId, this.commessaId(), this.eventDate()));
                        this.purchase.RevenuesRows = this.revenuesRows().map((r : PurchaseRevenueRow) => r.toPurchaseRow(event.EventId, this.commessaId(), this.eventDate()));


                        this.blogService.purchasesProvider.save(this.purchase)
                            .finally(() => { def.resolve(data);});
                    })
                    .catch(() => {
                        def.reject();
                    });
            })
            .catch(() => {
                def.reject();
            });
    }

    public save() : Promise<ILogEvent | ILogEvent[]>
    {
        var def = new Deferred<ILogEvent | ILogEvent[]>();

        if(this.isReferencedBySal)
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Blog.SALReferenceWarning, ProlifeSdk.TextResources.Blog.Cancel, ProlifeSdk.TextResources.Blog.Save,
                (confirm) => {
                    if(confirm)
                        this.saveAfterCheck(def);
                    else
                        def.reject();
                });
        else
            this.saveAfterCheck(def);

        return def.promise();
    }

    public addCostsRow()
    {
        this.costsRows.push(new PurchaseCostRow(this.serviceLocator, undefined, this.selectedTasksObservable));
    }

    public addRevenuesRow()
    {
        this.revenuesRows.push(new PurchaseRevenueRow(this.serviceLocator, undefined, this.selectedTasksObservable));
    }

    public removeCostsRow(row : PurchaseCostRow)
    {
        this.costsRows.remove(row);
    }

    public removeRevenuesRow(row : PurchaseRevenueRow)
    {
        this.revenuesRows.remove(row);
    }

    public cloneAsRevenew(row : PurchaseCostRow)
    {
        var clone = row.cloneAsRevenew(this.NetImportVariation() / 100.0);
        this.revenuesRows.push(clone);
    }

    public cloneAsCost(row : PurchaseRevenueRow)
    {
        var clone = row.cloneAsCost(this.NetImportVariation() / 100.0);
        this.costsRows.push(clone);
    }

    public cloneAllAsRevenue() {
        this.costsRows().forEach(r => {
            this.revenuesRows.push(r.cloneAsRevenew(this.NetImportVariation() / 100.0));
        });
    }

    public cloneAllAsCost() {
        this.revenuesRows().forEach(r => {
            this.costsRows.push(r.cloneAsCost(this.NetImportVariation() / 100.0));
        })
    }
}