import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 17/12/2015
 * Time: 17:13
 * To change this template use File | Settings | File Templates.
 */

export class Copy {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var functionToCall = valueAccessor();

        let randomKey = Math.floor(Math.random() * 1000000);
        let running = false;

        let handler = (evt : JQuery.TriggeredEvent) => {
            if(!$.contains(element, document.activeElement))
                return;

            evt.stopPropagation();

            if(running)
                return;

            running = true;

            functionToCall();

            running = false;
        };

        $(document).on('copy.' + randomKey, handler);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(document).off('copy.' + randomKey);
        });
    }
}

ko.bindingHandlers['copy'] = new Copy();