import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { Questionnaire } from "../Questionnaire";
import { SectionForDetails } from "./SectionForDetails";
import { QuestionForDetails } from "./QuestionForDetails";
import { SurveyPanelViewModel } from "../../SurveyPanelViewModel";
import { AlertDialog } from "../../alert-dialog/AlertDialog";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelectionObserver } from "../../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { IQuestionListObject } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionListObject";
import { IQuestionnaire, IAssignedCampaigns } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IQuestion } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { ISection } from "../../../../../ProlifeSdk/interfaces/survey/ISection";

export class QuestionnaireDetailsViewModel implements ISelectionObserver {
    public templateUrl:string = "survey/templates/questionnaire";
    public templateName: string = "questionnaire-details";

    public Questionnaire: ko.Observable<any> = ko.observable();
    public Questions: ko.ObservableArray<QuestionForDetails> = ko.observableArray([]);
    public SelectedQuestion: ko.Observable<any> = ko.observable();
    private SelectedQuestionIndex: ko.Observable<number> = ko.observable();
    public Sections: ko.ObservableArray<SectionForDetails> = ko.observableArray([]);

    public QuestionsNumber: ko.Computed<number>;
    public SectionsNumber: ko.Computed<number>;
    public QuestionsList: ko.Computed<IQuestionListObject[]>;
    public IsQuestionnaireLocked: ko.Computed<boolean>;

    private surveyService: ISurveyService;
    private dialogService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor (private serviceLocator: IServiceLocator, questionnaireId: number, private parent: SurveyPanelViewModel){
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.surveyService.getQuestionnaireData(questionnaireId)
            .then((questionnaire: IQuestionnaire) => {
                if (!questionnaire) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
                    return;
                }
                this.Questionnaire(new Questionnaire(questionnaire));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionnaireError);
            });

        this.surveyService.getQuestionsWithoutSection(questionnaireId)
            .then((questions: IQuestion[]) => {
                if (!questions || questions.length == 0) {
                    return;
                }
                this.Questions(questions.map((question: IQuestion) => {
                    var newQuestion = new QuestionForDetails(this.serviceLocator, question);
                    newQuestion.RegisterSelectionObserver(this);
                    return newQuestion;
                }));
            }).catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
            });

        this.surveyService.getQuestionnaireSections(questionnaireId)
            .then((sections: ISection[]) => {
                if (sections.length == 0)
                    return;
                this.Sections(sections.map((s: ISection) => {
                    var sectionViewModel: SectionForDetails = new SectionForDetails(this.serviceLocator, s, this);
                    return sectionViewModel;
                }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadSectionsError);
            });

        this.IsQuestionnaireLocked = ko.computed(() => {
            if (!this.Questionnaire())
                return false;
            return this.Questionnaire().Locked();
        });

        this.QuestionsList = ko.computed(() => {
            var questions: IQuestionListObject[] = [];
            this.Questions().forEach((q: QuestionForDetails) => {
                questions.push(<IQuestionListObject> {
                    Data: q,
                    IsSection: false
                })
            });
            this.Sections().forEach((s: SectionForDetails) => {
                questions.push(<IQuestionListObject> {
                    Data: s,
                    IsSection: true
                })
                s.Questions().forEach((sq: QuestionForDetails) => {
                    questions.push(<IQuestionListObject> {
                        Data: sq,
                        IsSection: false
                    });
                });
            });
            return questions;
        });

        this.QuestionsNumber = ko.computed(() => {
            return this.QuestionsList().filter((o: IQuestionListObject) => {
                return !o.IsSection;
            }).length;
        });

        this.SectionsNumber = ko.computed(() => {
            return this.Sections().length;
        });
    }

    public selectQuestion(question: QuestionForDetails) {
        this.Questions().forEach((q: QuestionForDetails) => {
            q.Selected(false);
        });
        this.Sections().forEach((s: SectionForDetails) => {
            s.Questions().forEach((q: QuestionForDetails) => {
                q.Selected(false);
            });
        });
        question.Selected(true);
        this.SelectedQuestion(question);
        this.QuestionsList().forEach((q: IQuestionListObject, index: number) => {
            if (q.Data.Id == question.Id)
                this.SelectedQuestionIndex(index);
        });
    }

    public selectPreviousQuestion(question: QuestionForDetails): void {
        var questionIndex: number = null;
        this.QuestionsList().forEach((q: IQuestionListObject, index: number) => {
            if (q.Data.Id == question.Id && !q.IsSection)
                questionIndex = index - 1;
        });

        if (questionIndex == null || !this.QuestionsList()[questionIndex])
            return;

        if (this.QuestionsList()[questionIndex].IsSection)
            questionIndex--;

        if (!this.QuestionsList()[questionIndex])
            questionIndex = this.SelectedQuestionIndex();

        this.selectQuestion(this.QuestionsList()[questionIndex].Data);
    }

    public selectNextQuestion(question: QuestionForDetails): void {
        var questionIndex: number = null;
        this.QuestionsList().forEach((q: IQuestionListObject, index: number) => {
            if (q.Data.Id == question.Id && !q.IsSection)
                questionIndex = index + 1;
        });

        if (questionIndex == null || !this.QuestionsList()[questionIndex])
            return;

        if (this.QuestionsList()[questionIndex].IsSection)
            questionIndex++;

        if (!this.QuestionsList()[questionIndex])
            questionIndex = this.SelectedQuestionIndex();

        this.selectQuestion(this.QuestionsList()[questionIndex].Data);
    }

    public DeselectSections(): void {
        this.Sections().forEach((s: SectionForDetails) => {
            s.Selected(false);
        });
    }

    public selectSection(section: ISection) {

    }

    public BackToSplash(): void {
        this.parent.Reset = false;
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    public Edit(): void {
        this.surveyService.assignedToStartedCampaigns(this.Questionnaire().Id)
            .then((assignedToStartedCampaigns: IAssignedCampaigns[]) => {
                if (assignedToStartedCampaigns.length > 0) {
                    var alertDialog = new AlertDialog(assignedToStartedCampaigns, ProlifeSdk.TextResources.Survey.EditQuestionnaireWithAssociatedCampaignAlert);
                    this.dialogService.ShowModal<boolean>(alertDialog, null, null, "survey/templates/dialogs", "alert-dialog")
                        .then((confirm: boolean) => {
                            if (confirm)
                                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + this.Questionnaire().Id + "/Edit";
                        });
                    return;
                }
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + this.Questionnaire().Id + "/Edit";
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.EditQuestionnaireError);
            });
    }

    public CreateNewVersion(): void {
        this.surveyService.newQuestionnaireVersion(this.Questionnaire().Id)
            .then((newQuestionnaireId: number) => {
                this.parent.Reset = false;
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + newQuestionnaireId + "/Edit";
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.CreateNewVersionError);
            });
    }

    dispose(){

    }
}