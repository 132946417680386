import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { Document } from "./Document";
import { classNames, ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { IDocumentBuilderDocumentTaxRelief } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { DocumentRow } from "./DocumentRows";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Column, ColumnBody, ColumnHeader } from "../../../Components/TableComponent/CustomColumn";
import { Select } from "../../../Components/Select";
import { TaxReliefDataSource, TaxReliefDataSourceModel } from "../../../DataSources/TaxReliefDataSource";
import { PercentageInput } from "../../../Components/PercentageInputComponent";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IfNot } from "../../../Components/IfIfNotWith";
import { TaxReliefAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/TaxReliefAutomationDialog";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { TaxReliefDiscountAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/TaxReliefDiscountAutomationDialog";
import { TaxReliefRowPercentageAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/TaxReliefRowPercentageAutomationDialog";

export interface IDocumentTaxRelief {
    TaxReliefId: number;
    TaxReliefLabel: string;
    TaxReliefDescription: string;
    TaxReliefDiscount: number;
    DocumentRowTotalPercentage: number;
    TotalDiscount: number;
    TotalDiscountInDocumentCurrency: number;

    DocumentRow: DocumentRow;
}

export class DocumentTaxRelief {
    public TaxReliefId : ko.Observable<number> = ko.observable();
    public TaxReliefLabel : ko.Observable<string> = ko.observable();
    public TaxReliefDescription : ko.Observable<string> = ko.observable();
    public TaxReliefDiscount : ko.Observable<number> = ko.observable();
    public DocumentRowTotalPercentage: ko.Observable<number> = ko.observable();
    public DocumentRowId: ko.Observable<number> = ko.observable();
    public TotalDiscountInDocumentCurrency: ko.Observable<number> = ko.observable();
    public TotalDiscount: ko.Observable<number> = ko.observable();

    public DocumentRow: ko.Observable<DocumentRow> = ko.observable();
    public DocumentRowCurrency : ko.Computed<string>;
    
    private validator: IValidator<DocumentTaxRelief>;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    constructor(private taxRelief: IDocumentTaxRelief) {
        this.TaxReliefId(this.taxRelief.TaxReliefId);
        this.TaxReliefLabel(this.taxRelief.TaxReliefLabel);
        this.TaxReliefDescription(this.taxRelief.TaxReliefDescription);
        this.TaxReliefDiscount(this.taxRelief.TaxReliefDiscount);
        this.DocumentRowTotalPercentage(this.taxRelief.DocumentRowTotalPercentage);
        this.DocumentRowId(this.taxRelief.DocumentRow?.Id());
        this.DocumentRow(this.taxRelief.DocumentRow);
        this.TotalDiscount(this.taxRelief.TotalDiscount);
        this.TotalDiscountInDocumentCurrency(this.taxRelief.TotalDiscountInDocumentCurrency);
        
        this.DocumentRowCurrency = ko.computed(() => {
            return this.DocumentRow()?.Currency() || "";
        });
        
        this.configureValidation();
    }

    private configureValidation(): void {
        this.validator = this.validationService.createValidator<DocumentTaxRelief>();

        this.validator
                .isNotNullUndefinedOrLessOrEqualZero(t => t.TaxReliefId(), TextResources.Invoices.MissingTaxRelief)
                .isNotNullOrUndefined(t => t.TaxReliefDiscount(), TextResources.Invoices.MissingTaxReliefDiscount)
                .isNotNullOrUndefined(t => t.DocumentRowId(), TextResources.Invoices.MissingDocumentRowOnTaxRelief)
                .isNotNullOrUndefined(t => t.DocumentRowTotalPercentage(), TextResources.Invoices.MissingDocumentRowTotalPercentageOnTaxRelief);
    }

    public onTaxReliefSelected(taxRelief: TaxReliefDataSourceModel): void {
        this.TaxReliefLabel(taxRelief.model.Label);
        this.TaxReliefDescription(taxRelief.model.Description);
        this.TaxReliefDiscount(taxRelief.model.Discount);
    }
    
    public onTaxReliefDeselected(taxRelief: TaxReliefDataSourceModel): void {
        this.TaxReliefLabel(null);
        this.TaxReliefDescription(null);
        this.TaxReliefDiscount(0);
    }

    public onDocumentRowSelected(docRow: IDataSourceModel<number, DocumentRow>): void {
        this.DocumentRow(docRow.model);
    }
    
    public onDocumentRowDeselected(docRow: IDataSourceModel<number, DocumentRow>): void {
        this.DocumentRow(null);
    }

    validateAndShowInfoToast() {
        return this.validator.validateAndShowInfoToast(this);
    }

    getData(): IDocumentBuilderDocumentTaxRelief {
        return {
            FKDocument: null,
            FKDocumentRow: this.DocumentRow().Id(),
            FKTaxRelief: this.TaxReliefId(),
            TaxReliefLabel: this.TaxReliefLabel(),
            TaxReliefDescription: this.TaxReliefDescription(),
            TaxReliefDiscount: this.TaxReliefDiscount(),
            TotalDiscount: this.TotalDiscount(),
            TotalDiscountInDocumentCurrency: this.TotalDiscountInDocumentCurrency(),
            DocumentRowTotalPercentage: this.DocumentRowTotalPercentage()
        };
    }
}

const styleSheet = jss.createStyleSheet({
    taxRelief: {
        "& .table-wrapper": {
            "& .flex-container": {
                overflow: "unset !important"
            }
        },
        "& table": {
            "& thead": {
                "& th": {
                    "&.hours-amount-header": {
                        paddingRight: "20px"
                    },

                    "& .btn.btn-xs.btn-invoice": {
                        width: '15px',
                        height: '15px',
                        fontSize: '9px',
                        display: 'inline',
                        marginTop: '-3px',

                        "& > i.fa": {
                            fontSize: '9px',
                            lineHeight: '11px',
                            marginLeft: '-2px',
                        }
                    }
                }
            },

            "& .actions-column": {
                width: "70px",
                textAlign: "right",

                "&.hidden": {
                    display: "none !important"
                }
            },
            
            "& th.actions-column": {
                "& button:last-child": {
                    margin: 0
                }
            },

            "& .tax-relief-percentage-column": {
                width: "100px"
            },
            
            "& .tax-relief-total-column": {
                width: "100px"
            },

            "& tbody": {
                "& .form-control": {
                    height: "30px !important"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

interface IDocumentRowDataSourceModel extends IDataSourceModel<number, DocumentRow> {
    computedTitle: ko.Computed<string>;
}

type TaxReliefEditorProps = {
    taxRelief: ko.ObservableArray<DocumentTaxRelief>;
    documentRows: ko.ObservableArray<DocumentRow>;
    documentCurrencySymbol: ko.Observable<string>;
    readOnly?: ko.Observable<boolean>|ko.Computed<boolean>;
    className?: string;

    onTaxReliefChange: () => void;
}

export function DocumentTaxReliefEditor() {
    let vm: Document;

    return  <ko-bind data-bind={{ if: vm.CanHaveTaxRelief() && vm.ShowTaxReliefSection() }}>
                <ko-bind data-bind={{ tsxtemplate: vm.TaxReliefEditorUI }}></ko-bind>    
            </ko-bind>;
}

export class TaxReliefEditorUI {
    constructor(private props: TaxReliefEditorProps) {

    }

    render() {
        return <TaxReliefEditor {...this.props} />;
    }
}

export function TaxReliefEditor(props: TaxReliefEditorProps) {
    const C = require("./DocumentTaxReliefEditor")._TaxReliefEditor as typeof _TaxReliefEditor;
    return <C {...props} />;
}

export class _TaxReliefEditor {
    static defaultProps: Partial<TaxReliefEditorProps> = {
    }

    private taxReliefDataSource: TaxReliefDataSource;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private canEditDocumentRowPercentageOnTaxRelief: boolean;
    private canEditTaxReliefPercentage: boolean;

    constructor(private props : TaxReliefEditorProps) {
        this.taxReliefDataSource = new TaxReliefDataSource();
        this.canEditDocumentRowPercentageOnTaxRelief = this.authorizationService.isAuthorized("Documents_EditDocumentRowPercentageOnTaxRelief");
        this.canEditTaxReliefPercentage = this.authorizationService.isAuthorized("Documents_EditTaxReliefPercentage");
    }
    
    private modelsFactory(taxRelief: DocumentTaxRelief): IDataSourceModel<number, DocumentTaxRelief> {
        return {
            id: taxRelief.TaxReliefId(),
            title: "",
            isGroup: false,
            isLeaf: true,
            model: taxRelief
        };
    }

    private addTaxRelief(): DocumentTaxRelief {
        const taxReliefRow = new DocumentTaxRelief({
            TaxReliefId: null,
            TaxReliefLabel: "",
            TaxReliefDescription: "",
            TaxReliefDiscount: 0,
            DocumentRowTotalPercentage: 100,
            TotalDiscount: 0,
            TotalDiscountInDocumentCurrency: 0,
            DocumentRow: null
        });

        this.props.taxRelief.push(taxReliefRow);

        return taxReliefRow;
    }

    private async removeTaxRelief(taxRelief: IDataSourceModel<number, DocumentTaxRelief>): Promise<void> {
        this.props.taxRelief.remove(taxRelief.model);
    }

    private selectAllRows(): void {
        const docRows = this.props.documentRows();
        const taxRelief = this.props.taxRelief();

        for (const docRow of docRows) {
            if (!taxRelief.firstOrDefault(r => r.DocumentRowId() === docRow.Id())) {
                const docRowModel = {
                    id: docRow.Id(),
                    title: docRow.Description(),
                    isGroup: false,
                    isLeaf: true,
                    model: docRow
                };

                const notAssignedTaxRelief = taxRelief.firstOrDefault(r => !r.DocumentRowId());
                if (notAssignedTaxRelief) {
                    notAssignedTaxRelief.DocumentRowId(docRow.Id());
                    notAssignedTaxRelief.DocumentRowTotalPercentage(100);
                    notAssignedTaxRelief.onDocumentRowSelected(docRowModel);
                } else {
                    const newTaxReliefRow = this.addTaxRelief();
                    newTaxReliefRow.DocumentRowId(docRow.Id());
                    newTaxReliefRow.onDocumentRowSelected(docRowModel);
                }
            }
        }

        this.props.onTaxReliefChange && this.props.onTaxReliefChange();
    }

    public async showTaxReliefAutomation(item : unknown, event : Event) : Promise<void> {
        const dialog = new TaxReliefAutomationDialog({ taxRelief: this.props.taxRelief });
        try {
            await this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, dialog, "bottom");
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        } catch (e) {
            console.log(e);
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        }
    }
    
    public async showTaxReliefDiscountAutomation(item : unknown, event : Event) : Promise<void> {
        const dialog = new TaxReliefDiscountAutomationDialog({ taxRelief: this.props.taxRelief });
        try {
            await this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, dialog, "bottom");
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        } catch (e) {
            console.log(e);
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        }
    }
    
    public async showDocumentRowPercentageAutomation(item : unknown, event : Event) : Promise<void> {
        const dialog = new TaxReliefRowPercentageAutomationDialog({ taxRelief: this.props.taxRelief });
        try {
            await this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, dialog, "bottom");
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        } catch (e) {
            console.log(e);
            this.props.onTaxReliefChange && this.props.onTaxReliefChange();
        }
    }

    render() {
        const taxReliefEditor = this;
        const taxRelief: IDataSourceModel<number, DocumentTaxRelief> = null;
        const cssClasses = classNames("row tax-relief no-gutters", classes.taxRelief, this.props.className);

        const createDocumentRowDataSourceModel = (docRow: DocumentRow) => {
            return {
                id: docRow.Id(),
                title: docRow.Index() + " - " + docRow.Description(),
                isGroup: false,
                isLeaf: true,
                model: docRow,
                computedTitle: ko.computed(() => docRow.Index() + " - " + docRow.Description())
            };
        };

        const renderTaxReliefSelectedItem = (item: TaxReliefDataSourceModel) => {
            return  <>
                        {item.icon && <span className={ComponentUtils.classNames("list-item-icon", item.icon.icon)} style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}></span>}
                        <div className="list-item-content">
                            <div className="list-item-title" data-bind={{ text: item.model.Description }}></div>
                            {item.model.Label && <small className="list-item-subtitle text-muted">{item.model.Label}</small>}
                        </div>
                        {item.secondaryIcon && <span className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)} style={{ backgroundColor: item.secondaryIcon.background, color: item.secondaryIcon.foreground }}></span>}
                    </>;
        }
        
        const renderDocumentRowSelectedItem = (item: IDocumentRowDataSourceModel) => {
            return  <>
                        {item.icon && <span className={ComponentUtils.classNames("list-item-icon", item.icon.icon)} style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}></span>}
                        <div className="list-item-content">
                            <div className="list-item-title" data-bind={{ text: item.computedTitle }}></div>
                            {item.subTitle && <small className="list-item-subtitle text-muted">{item.subTitle}</small>}
                        </div>
                        {item.secondaryIcon && <span className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)} style={{ backgroundColor: item.secondaryIcon.background, color: item.secondaryIcon.foreground }}></span>}
                    </>;
        }

        const renderDocumentRowItem = (item: IDocumentRowDataSourceModel) => {
            return  <>
                        {item.icon && <span className={ComponentUtils.classNames("list-item-icon", item.icon.icon)} style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}></span>}
                        <div className="list-item-content">
                            <div className="list-item-title" data-bind={{ text: item.computedTitle }}></div>
                            {item.subTitle && <small className="list-item-subtitle text-muted">{item.subTitle}</small>}
                        </div>
                        {item.secondaryIcon && <span className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)} style={{ backgroundColor: item.secondaryIcon.background, color: item.secondaryIcon.foreground }}></span>}
                    </>
        }

        const actionsColunmClasses = ComponentUtils.classNames("actions-column", {
            "hidden": this.props.readOnly()
        });

        return ComponentUtils.bindTo((
            <div className={cssClasses}>
                <div className="col-md-12 table-wrapper">
                    <Table 
                        dataSource={{ array: this.props.taxRelief, factory: this.modelsFactory.bind(this) }}
                        fixedLayout
                        compact 
                        className="table-advance" 
                        rowAs="taxRelief" 
                        title={TextResources.Invoices.TaxReliefTableTitle}
                        id={ProlifeSdk.DocumentTaxReliefTable}
                        >
                        <Column title={TextResources.Invoices.DocumentTaxReliefColumnTitle}>
                            <ColumnHeader>
                                {() =>  <>
                                            {TextResources.Invoices.DocumentTaxReliefColumnTitle}&nbsp;
                                            <IfNot condition={this.props.readOnly}>
                                                {() =>  <button class="btn btn-primary btn-xs btn-invoice" data-bind={{ asyncClick: taxReliefEditor.showTaxReliefAutomation.bind(taxReliefEditor) }}>
                                                            <i class="fa fa-chevron-down"></i>
                                                        </button>
                                                }
                                            </IfNot>
                                        </>
                                }
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) => <Select
                                    dataSource={this.taxReliefDataSource}
                                    value={item.Data.model.TaxReliefId}
                                    placeholder={TextResources.Invoices.TaxReliefPlaceholder}
                                    allowClear={true}
                                    readOnly={this.props.readOnly}
                                    onSelect={(taxRelief) => {
                                        item.Data.model.onTaxReliefSelected(taxRelief);
                                        this.props.onTaxReliefChange && this.props.onTaxReliefChange();
                                    }}
                                    onDeselect={(taxRelief) => {
                                        item.Data.model.onTaxReliefDeselected(taxRelief);
                                        this.props.onTaxReliefChange && this.props.onTaxReliefChange();
                                    }}

                                    renderSelectedItem={renderTaxReliefSelectedItem}
                                />}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.DocumentTaxReliefDiscountColumnTitle} className="tax-relief-percentage-column text-right">
                            <ColumnHeader>
                                {() =>  <>
                                            {TextResources.Invoices.DocumentTaxReliefDiscountColumnTitle}&nbsp;
                                            {this.canEditTaxReliefPercentage && (
                                                <IfNot condition={this.props.readOnly}>
                                                    {() =>  <button class="btn btn-primary btn-xs btn-invoice" data-bind={{ asyncClick: taxReliefEditor.showTaxReliefDiscountAutomation.bind(taxReliefEditor) }}>
                                                                <i class="fa fa-chevron-down"></i>
                                                            </button>
                                                    }
                                                </IfNot>
                                            )}
                                        </>
                                }
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) => (
                                    <>
                                        {this.canEditTaxReliefPercentage && <PercentageInput
                                                                                value={item.Data.model.TaxReliefDiscount}
                                                                                readonly={this.props.readOnly}
                                                                                placeholder={TextResources.Invoices.DocumentTaxReliefDiscountPlaceholder}
                                                                                selectOnFocus

                                                                                onValueChange={() => this.props.onTaxReliefChange && this.props.onTaxReliefChange()}
                                                                            />}
                                        {!this.canEditTaxReliefPercentage && <span data-bind={{ percentageText: taxRelief.model.TaxReliefDiscount }}></span>}
                                    </>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.DocumentTaxReliefDocumentRowColumnTitle}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) =>   <Select
                                                                                dataSource={{ array: this.props.documentRows, factory: createDocumentRowDataSourceModel }}
                                                                                value={item.Data.model.DocumentRowId}
                                                                                placeholder={TextResources.Invoices.TaxReliefDocumentRowPlaceholder}
                                                                                allowClear={true}
                                                                                readOnly={this.props.readOnly}
                                                                                onSelect={(docRow) => {
                                                                                    item.Data.model.onDocumentRowSelected(docRow);
                                                                                    this.props.onTaxReliefChange && this.props.onTaxReliefChange();
                                                                                }}
                                                                                onDeselect={(docRow) => {
                                                                                    item.Data.model.onDocumentRowDeselected(docRow);
                                                                                    this.props.onTaxReliefChange && this.props.onTaxReliefChange();
                                                                                }}

                                                                                renderItem={renderDocumentRowItem}
                                                                                renderSelectedItem={renderDocumentRowSelectedItem}
                                                                            />}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.DocumentTaxReliefDocumentRowPercentageColumnTitle} className="tax-relief-percentage-column text-right">
                            <ColumnHeader>
                                {() => (
                                    <>
                                        {TextResources.Invoices.DocumentTaxReliefDocumentRowPercentageColumnTitle}&nbsp;
                                        {this.canEditDocumentRowPercentageOnTaxRelief && (
                                            <IfNot condition={this.props.readOnly}>
                                                {() =>  <button class="btn btn-primary btn-xs btn-invoice" data-bind={{ asyncClick: taxReliefEditor.showDocumentRowPercentageAutomation.bind(taxReliefEditor) }}>
                                                            <i class="fa fa-chevron-down"></i>
                                                        </button>
                                                }
                                            </IfNot>
                                        )}
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) => (
                                    <>
                                        {this.canEditDocumentRowPercentageOnTaxRelief && <PercentageInput
                                                                                            value={item.Data.model.DocumentRowTotalPercentage}
                                                                                            readonly={this.props.readOnly}
                                                                                            placeholder={TextResources.Invoices.DocumentTaxReliefDocumentRowPercentagePlaceholder}
                                                                                            selectOnFocus

                                                                                            onValueChange={() => this.props.onTaxReliefChange && this.props.onTaxReliefChange()}
                                                                                        />}
                                        {!this.canEditDocumentRowPercentageOnTaxRelief && <span data-bind={{ percentageText: taxRelief.model.DocumentRowTotalPercentage }}></span>}
                                    </>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.DocumentTaxReliefTotalColumnTitle} className="tax-relief-percentage-column text-right">
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) => <span data-bind={{ moneyTextEx: taxRelief.model.TotalDiscountInDocumentCurrency, currencySymbol: taxReliefEditor.props.documentCurrencySymbol }}></span>}
                            </ColumnBody>
                        </Column>
                        <Column title={""} className={actionsColunmClasses} headerCssClasses={actionsColunmClasses}>
                            <ColumnHeader>
                                {() => (
                                    <IfNot condition={this.props.readOnly}>
                                        {() => (
                                            <>
                                                <button class="btn btn-primary btn-xs" data-bind={{ asyncClick: taxReliefEditor.selectAllRows.bind(taxReliefEditor) }} title={TextResources.Invoices.AddAllRowsTooltip}>
                                                    <i class="fa fa-list"></i>
                                                </button>
                                                <button type="button" className="btn btn-primary btn-xs" data-bind={{ click: taxReliefEditor.addTaxRelief.bind(taxReliefEditor) }} title={TextResources.Invoices.AddRowTooltip}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </>
                                        )}
                                    </IfNot>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<DocumentTaxRelief>) => (
                                    <IfNot condition={this.props.readOnly}>
                                        {() => <button type="button" className="btn btn-danger btn-xs" data-bind={{ click: taxReliefEditor.removeTaxRelief.bind(taxReliefEditor, taxRelief) }}><i className="fa fa-trash-o"></i></button>}
                                    </IfNot>
                                )}
                            </ColumnBody>
                        </Column>
                    </Table>
                </div>
            </div>
        ), this, "taxReliefEditor");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(TaxReliefEditor);
}