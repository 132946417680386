import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { _ReportDesigner } from "./ReportDesigner";
import { ReportComponent, ReportPage } from "./Components";
import jss from "jss";
import { ReportComponentWithPosition } from "./Components/ReportComponentWithPosition";
import { CentimetersToPixels, PixelsToCentimeters } from "./Utilities/UnitOfMeasureUtils";
import { CanHaveChildren, ReportComponentWithChildren } from "./Components/ReportComponentWithChildren";
import TsxForEach from "../ForEach";
import { If } from "../IfIfNotWith";
import { ReportComponentProvider } from "./Components/ReportComponentProvider";
import { ReportComponentWithVisibility } from "./Components/ReportComponentWithVisibility";

const handleSize = 6;
const handleOffset = 3;

const styleSheet = jss.createStyleSheet({
    reportNewSelectionOverlay: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255,255,255,0.001)",

        "& > .component-overlay, & > .selection-overlay": {
            outline: "1px dotted #FF9900",
            pointerEvents: "auto",
            position: "absolute",
            left: "var(--x)",
            top: "var(--y)",
            width: "var(--w)",
            height: "var(--h)",

            "&.ignore-selection": {
                pointerEvents: "none",
            },

            "&:hover": {
                outline: "1px solid #FF9900",
            },
        },

        "& > .component-overlay": {
            "& > .min-overlay": {
                position: "absolute",
                left: 0,
                top: 0,
                pointerEvents: "none",
                userSelect: "none",

                "&.horizontal, &.both": {
                    borderRight: "1px dashed #ff000060",
                },

                "&.vertical, &.both": {
                    borderBottom: "1px dashed #ff000060",
                },
            },

            "& > .max-overlay": {
                position: "absolute",
                left: 0,
                top: 0,
                pointerEvents: "none",
                userSelect: "none",

                "&.horizontal, &.both": {
                    borderRight: "1px dashed #00ff0060",
                },

                "&.vertical, &.both": {
                    border: "1px dashed #00ff0060",
                    backgroundColor: "#00ff0010",
                },
            },

            "&.visibility-bound": {
                outline: "1px dotted #0072ff",
                background:
                    "repeating-linear-gradient(-45deg, transparent, transparent 10px, #0072ff29 10px, #0072ff29 20px)",
                //boxShadow: 'inset 0 0 5px 0px #0072ff',

                "&:hover": {
                    outline: "1px solid #0072ff",
                },

                "&:hover:before": {
                    width: "12px",
                    height: "12px",
                    top: "0px",
                    left: "-20px",
                    content: '"\\f06e"',
                    position: "absolute",
                    backgroundColor: "#c7defb",
                    fontFamily: "FontAwesome",
                    lineHeight: "12px",
                    fontSize: "12px",
                    outline: "1px solid #0072ff",
                    color: "#000000",
                },
            },
        },

        "& > .selection-overlay": {
            outline: "1px solid #FF9900",

            "&.visibility-bound": {
                outline: "1px solid #0072ff",

                "&:hover": {
                    outline: "1px solid #0072ff",
                },

                "&:before": {
                    width: "12px",
                    height: "12px",
                    top: "0px",
                    left: "-20px",
                    content: '"\\f06e"',
                    position: "absolute",
                    backgroundColor: "#c7defb",
                    fontFamily: "FontAwesome",
                    lineHeight: "12px",
                    fontSize: "12px",
                    outline: "1px solid #0072ff",
                    color: "#000000",
                },

                "& .handle": {
                    outline: "1px solid #0072ff",
                },
            },

            "& .hidden-handle": {
                display: "block",
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.0001)",
                pointerEvents: "auto",
                cursor: "move",

                "&.left": {
                    left: -(handleSize / 2),
                    top: 0,
                    bottom: 0,
                    width: handleSize,
                },

                "&.right": {
                    right: -(handleSize / 2),
                    top: 0,
                    bottom: 0,
                    width: handleSize,
                },

                "&.top": {
                    top: -(handleSize / 2),
                    left: 0,
                    right: 0,
                    height: handleSize,
                },

                "&.bottom": {
                    bottom: -(handleSize / 2),
                    left: 0,
                    right: 0,
                    height: handleSize,
                },
            },

            "& .handle": {
                display: "block",
                position: "absolute",
                width: handleSize,
                height: handleSize,
                backgroundColor: "#FFFFFF",
                outline: "1px solid #FF9900",
                pointerEvents: "auto",

                "&.tl-handle": {
                    top: -handleOffset,
                    left: -handleOffset,
                    cursor: "nwse-resize",
                },

                "&.tm-handle": {
                    top: -handleOffset,
                    left: `calc(50% - ${handleOffset}px)`,
                    cursor: "ns-resize",
                },

                "&.tr-handle": {
                    top: -handleOffset,
                    right: -handleOffset,
                    cursor: "nesw-resize",
                },

                "&.mr-handle": {
                    right: -handleOffset,
                    top: `calc(50% - ${handleOffset}px)`,
                    cursor: "ew-resize",
                },

                "&.br-handle": {
                    bottom: -handleOffset,
                    right: -handleOffset,
                    cursor: "nwse-resize",
                },

                "&.bm-handle": {
                    bottom: -handleOffset,
                    left: `calc(50% - ${handleOffset}px)`,
                    cursor: "ns-resize",
                },

                "&.bl-handle": {
                    bottom: -handleOffset,
                    left: -handleOffset,
                    cursor: "nesw-resize",
                },

                "&.ml-handle": {
                    left: -handleOffset,
                    top: `calc(50% - ${handleOffset}px)`,
                    cursor: "ew-resize",
                },

                "&.move-handle": {
                    left: 16,
                    top: -24,
                    cursor: "move",
                    width: 19,
                    height: 19,
                    lineHeight: "15px",
                    padding: 3,
                    backgroundColor: "#FF9900",

                    "& > i": {
                        color: "white",
                        fontSize: "13px",
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type ReportSelectionOverlayProps = {
    components: ko.ObservableArray<ReportComponent>;
    reportDesigner: _ReportDesigner;
    disabled?: ko.Observable<boolean> | (() => boolean);
    onEdit?: (component: ReportComponent) => void;
};

export function ReportSelectionOverlay(props: ReportSelectionOverlayProps) {
    const C = require("./ReportSelectionOverlay")._ReportSelectionOverlay as typeof _ReportSelectionOverlay;
    return <C {...props} />;
}

export class _ReportSelectionOverlay {
    static defaultProps: Partial<ReportSelectionOverlayProps> = {};

    private originalWidth: number;
    private originalHeight: number;
    private originalX: number;
    private originalY: number;
    private startX: number;
    private startY: number;
    private resizeRight: boolean;
    private resizeLeft: boolean;
    private resizeUp: boolean;
    private resizeDown: boolean;
    private move: boolean;
    private ignoreNextClick: boolean;

    allChildren: ko.Computed<(ReportComponent & ReportComponentWithPosition)[]>;
    selectedComponent: ko.Computed<ReportComponent & ReportComponentWithPosition & ReportComponentWithVisibility>;
    pageDiv: HTMLDivElement;

    constructor(private props: ReportSelectionOverlayProps) {
        if (!this.props.disabled) this.props.disabled = ko.observable(false);
        props.disabled = ComponentUtils.getComputed(props.disabled);

        this.allChildren = ko
            .computed(() => {
                function* recursiveGetChildren(
                    children: ReportComponent[]
                ): Generator<ReportComponent & ReportComponentWithPosition> {
                    for (const child of children)
                        for (const subChild of recursiveGetSelfAndChildren(child)) yield subChild;
                }

                function* recursiveGetSelfAndChildren(
                    component: ReportComponent
                ): Generator<ReportComponent & ReportComponentWithPosition> {
                    yield component as unknown as ReportComponent & ReportComponentWithPosition;

                    if (CanHaveChildren(component)) {
                        for (const child of component.children()) {
                            for (const subChild of recursiveGetSelfAndChildren(child)) yield subChild;
                        }
                    }
                }

                return Array.from(recursiveGetChildren(this.props.components()));
            })
            .extend({ trackArrayChanges: true });

        this.selectedComponent = ko.computed(() => {
            const component = this.props.reportDesigner.selectedComponent();
            if (!component) return null;

            for (const myComponent of this.props.components()) {
                if (myComponent === component || component.isChildOf(myComponent))
                    return component as unknown as ReportComponent &
                        ReportComponentWithPosition &
                        ReportComponentWithVisibility;
            }

            return null;
        });
    }

    private boundOnMouseMove = this.onMouseMove.bind(this);
    private boundOnMouseUp = this.onMouseUp.bind(this);
    private boundOnKeyUp = this.onKeyUp.bind(this);
    private boundOnClick = this.onClick.bind(this);
    private boundOnCopy = this.onCopy.bind(this);
    private boundOnPaste = this.onPaste.bind(this);
    private boundOnCut = this.onCut.bind(this);

    componentDidMount() {
        console.log("ReportSelectionOverlay events attached");
        document.addEventListener("mousemove", this.boundOnMouseMove);
        document.addEventListener("mouseup", this.boundOnMouseUp);
        document.addEventListener("keyup", this.boundOnKeyUp);
        document.addEventListener("click", this.boundOnClick);
        document.addEventListener("copy", this.boundOnCopy);
        document.addEventListener("paste", this.boundOnPaste);
        document.addEventListener("cut", this.boundOnCut);
    }

    componentWillUnmount() {
        console.log("ReportSelectionOverlay events detached");
        document.removeEventListener("mousemove", this.boundOnMouseMove);
        document.removeEventListener("mouseup", this.boundOnMouseUp);
        document.removeEventListener("keyup", this.boundOnKeyUp);
        document.removeEventListener("click", this.boundOnClick);
        document.removeEventListener("copy", this.boundOnCopy);
        document.removeEventListener("paste", this.boundOnPaste);
        document.removeEventListener("cut", this.boundOnCut);
    }

    private onKeyUp(e: KeyboardEvent) {
        if (this.props.disabled()) return false;

        if (
            e.code === "Delete" &&
            ($(document.activeElement).hasClass("selection-overlay") || document.activeElement === document.body)
        ) {
            const component = this.props.reportDesigner.selectedComponent();
            if (!component || component instanceof ReportPage) return false;
            component.parent().children.remove(component);
            this.props.reportDesigner.selectedComponent(undefined);
        }

        return false;
    }

    private onCopy(e: Event) {
        if (this.props.disabled()) return;

        const inPage =
            $(document.activeElement).hasClass("selection-overlay") || document.activeElement === document.body;
        if (!inPage) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if (!component || component instanceof ReportPage) return;

        this.props.reportDesigner.setClipboard(JSON.stringify(component.getData()));
    }

    private onCut(e: Event) {
        if (this.props.disabled()) return;

        const inPage =
            $(document.activeElement).hasClass("selection-overlay") || document.activeElement === document.body;
        if (!inPage) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if (!component || component instanceof ReportPage) return;

        this.props.reportDesigner.setClipboard(JSON.stringify(component.getData()));

        component.parent().children.remove(component);
        this.props.reportDesigner.selectedComponent(undefined);
    }

    private onPaste(e: Event) {
        if (this.props.disabled()) return;

        const inPage =
            $(document.activeElement).hasClass("selection-overlay") || document.activeElement === document.body;
        if (!inPage) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if (!component || component.features.indexOf("Children") === -1) return;

        const copied = this.props.reportDesigner.getClipboard();
        const parsed = JSON.parseWithDate(copied);

        const pastedComponent = ReportComponentProvider.createComponent(parsed);

        const componentWithChildren = component as ReportComponentWithChildren & ReportComponent;
        componentWithChildren.children.push(pastedComponent);
    }

    private saveState(evt: MouseEvent) {
        const component = this.selectedComponent();

        this.originalX = CentimetersToPixels(component.x());
        this.originalY = CentimetersToPixels(component.y());
        this.originalWidth = CentimetersToPixels(component.width());
        this.originalHeight = CentimetersToPixels(component.height());
        this.startX = evt.pageX;
        this.startY = evt.pageY;
    }

    private startResizeRight(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeRight = true;
    }

    private startResizeLeft(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeLeft = true;
    }

    private startResizeUpLeft(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeUp = true;
        this.resizeLeft = true;
    }

    private startResizeUpRight(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeUp = true;
        this.resizeRight = true;
    }

    private startResizeUp(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeUp = true;
    }

    private startResizeDown(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeDown = true;
    }

    private startResizeDownLeft(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeDown = true;
        this.resizeLeft = true;
    }

    private startResizeDownRight(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.resizeDown = true;
        this.resizeRight = true;
    }

    private startMove(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.saveState(evt);
        this.move = true;
    }

    private onMouseUp(evt: MouseEvent) {
        if (this.props.disabled()) return false;

        if (this.resizeRight || this.resizeLeft || this.resizeUp || this.resizeDown || this.move) {
            evt.preventDefault();
            evt.stopPropagation();
            this.ignoreNextClick = true;
        }

        this.resizeRight = false;
        this.resizeLeft = false;
        this.resizeUp = false;
        this.resizeDown = false;
        this.move = false;

        return false;
    }

    private getComponentAt(e: MouseEvent) {
        const pagePosition = this.pageDiv.getBoundingClientRect();
        const pX = pagePosition.left + window.scrollX;
        const pY = pagePosition.top + window.scrollY;

        const x = PixelsToCentimeters(e.x - pX);
        const y = PixelsToCentimeters(e.y - pY);
        const reversedChildren = this.allChildren().slice().reverse();

        for (const child of reversedChildren) {
            if (
                child.realX() <= x &&
                child.realX() + child.width() >= x &&
                child.realY() <= y &&
                child.realY() + child.height() >= y
            ) {
                return child;
            }
        }

        return null;
    }

    private onClick(e: MouseEvent & { currentTarget: EventTarget & HTMLDivElement }) {
        if (this.props.disabled()) return false;

        if ($(e.target).closest(".report-designer-design-area").length === 0)
            //Non ho cliccato sul report designer
            return false;

        if ($(e.target).hasClass("report-designer-design-area")) {
            //Ho cliccato la zona grigia
            this.props.reportDesigner.selectedComponent(null);
            return false;
        }

        if ($(e.target).closest(this.pageDiv).length === 0)
            //Non ho cliccato dentro la mia area
            return false;

        e.preventDefault();
        e.stopPropagation();

        if (this.resizeUp || this.resizeDown || this.resizeLeft || this.resizeRight || this.ignoreNextClick) {
            this.ignoreNextClick = false;
            return false;
        }

        const child = this.getComponentAt(e);
        if (this.props.reportDesigner.selectedComponent() !== child) this.props.reportDesigner.selectedComponent(child);

        if (e.detail === 2) {
            this.props.onEdit && this.props.onEdit(child);
        }

        return false;
    }

    private onMouseMove(evt: MouseEvent) {
        if (this.props.disabled()) return false;

        if (this.resizeRight || this.resizeLeft || this.resizeUp || this.resizeDown || this.move) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const positionable = this.selectedComponent();

        if (this.resizeRight) {
            const deltaX = evt.pageX - this.startX;
            const newWidthInPixels = Math.max(this.originalWidth + deltaX, 0);
            positionable.width(PixelsToCentimeters(newWidthInPixels));
        }

        if (this.resizeLeft) {
            const deltaX = this.startX - evt.pageX;
            const newWidthInPixels = Math.max(this.originalWidth + deltaX, 0);
            const newXInPixels = this.originalX + this.originalWidth - newWidthInPixels;
            positionable.x(PixelsToCentimeters(newXInPixels));
            positionable.width(PixelsToCentimeters(newWidthInPixels));
        }

        if (this.resizeDown) {
            const deltaY = evt.pageY - this.startY;
            const newHeightInPixels = Math.max(this.originalHeight + deltaY, 0);
            positionable.height(PixelsToCentimeters(newHeightInPixels));
        }

        if (this.resizeUp) {
            const deltaY = this.startY - evt.pageY;
            const newHeightInPixels = Math.max(this.originalHeight + deltaY, 0);
            const newYInPixels = this.originalY + this.originalHeight - newHeightInPixels;
            positionable.y(PixelsToCentimeters(newYInPixels));
            positionable.height(PixelsToCentimeters(newHeightInPixels));
        }

        if (this.move) {
            const deltaX = evt.pageX - this.startX;
            const deltaY = evt.pageY - this.startY;
            const newXInPixels = this.originalX + deltaX;
            const newYInPixels = this.originalY + deltaY;
            positionable.x(PixelsToCentimeters(newXInPixels));
            positionable.y(PixelsToCentimeters(newYInPixels));
        }

        return false;
    }

    render() {
        let rso = this;
        let component: ReportComponent & ReportComponentWithPosition & ReportComponentWithVisibility;

        return ComponentUtils.bindTo(
            <div
                ref={(d) => (this.pageDiv = d)}
                className={classes.reportNewSelectionOverlay}
                data-bind={{ hidden: rso.props.disabled }}
                //onClick={(e) => this.onClick(e)}
            >
                <TsxForEach data={this.allChildren} as="component">
                    {() => (
                        <div
                            className="component-overlay"
                            data-bind={{
                                style: {
                                    "--x": component.realX() + "cm",
                                    "--y": component.realY() + "cm",
                                    "--w": component.width() + "cm",
                                    "--h": component.height() + "cm",
                                },
                                css: { "visibility-bound": component.boundVisible && component.boundVisible.bound },
                            }}>
                            <div
                                className="min-overlay"
                                data-bind={{
                                    css: component.autoSize,
                                    visible: component.autoSize() !== "none" && rso.selectedComponent() === component,
                                    style: {
                                        width: component.realMinWidth() + "cm",
                                        height: component.realMinHeight() + "cm",
                                    },
                                }}></div>
                            <div
                                className="max-overlay"
                                data-bind={{
                                    css: component.autoSize,
                                    visible: component.autoSize() !== "none" && rso.selectedComponent() === component,
                                    style: {
                                        width: component.realMaxWidth() + "cm",
                                        height: component.realMaxHeight() + "cm",
                                    },
                                }}></div>
                        </div>
                    )}
                </TsxForEach>
                <If condition={() => !!rso.selectedComponent() && rso.selectedComponent().hasFeature("Position")}>
                    {() => (
                        <div
                            className="selection-overlay"
                            tabIndex={-1}
                            data-bind={{
                                style: {
                                    "--x": rso.selectedComponent().realX() + "cm",
                                    "--y": rso.selectedComponent().realY() + "cm",
                                    "--w": rso.selectedComponent().width() + "cm",
                                    "--h": rso.selectedComponent().height() + "cm",
                                },
                                css: {
                                    "visibility-bound":
                                        rso.selectedComponent().boundVisible &&
                                        rso.selectedComponent().boundVisible.bound,
                                },
                            }}>
                            <div className="hidden-handle left" onMouseDown={(evt) => this.startMove(evt)}></div>
                            <div className="hidden-handle top" onMouseDown={(evt) => this.startMove(evt)}></div>
                            <div className="hidden-handle right" onMouseDown={(evt) => this.startMove(evt)}></div>
                            <div className="hidden-handle bottom" onMouseDown={(evt) => this.startMove(evt)}></div>

                            <div className="handle tl-handle" onMouseDown={(evt) => this.startResizeUpLeft(evt)}></div>
                            <div className="handle tm-handle" onMouseDown={(evt) => this.startResizeUp(evt)}></div>
                            <div className="handle tr-handle" onMouseDown={(evt) => this.startResizeUpRight(evt)}></div>
                            <div className="handle mr-handle" onMouseDown={(evt) => this.startResizeRight(evt)}></div>
                            <div
                                className="handle br-handle"
                                onMouseDown={(evt) => this.startResizeDownRight(evt)}></div>
                            <div className="handle bm-handle" onMouseDown={(evt) => this.startResizeDown(evt)}></div>
                            <div
                                className="handle bl-handle"
                                onMouseDown={(evt) => this.startResizeDownLeft(evt)}></div>
                            <div className="handle ml-handle" onMouseDown={(evt) => this.startResizeLeft(evt)}></div>
                        </div>
                    )}
                </If>
            </div>,
            this,
            "rso"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportSelectionOverlay);
}
