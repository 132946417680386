import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, Portal, reloadNow } from "../Core/utils/ComponentUtils";
import { isObservableArrayDataSource, ObservableArrayDataSource } from "../DataSources/ObservableArrayDataSource";
import { IDataSource, IDataSourceModel } from "../DataSources/IDataSource";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";
import { TextInput, _TextInput } from "./TextInput";
import { Delay } from "../Decorators/Delay";
import { If, IfNot, With } from "./IfIfNotWith";
import { Layout } from "./Layouts";
import { ActivityIndicator } from "./ActivityIndicator";
import TsxForEach from "./ForEach";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    selectMultiple: {
        display: "flex",
        flex: 1,
        cursor: "default",
        minHeight: "34px",
        height: "auto",

        "&.disabled": {
            cursor: "not-allowed",
            backgroundColor: "#eeeeee",

            "& .selected-item": {
                "& > .list-item": {
                    cursor: "not-allowed",
                },
            },
        },

        "& > .placeholder": {
            color: "#888888",
            marginRight: "auto",
        },

        "& > .selected-item": {
            flex: 1,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            overflow: "hidden",
            margin: "-3px 0",

            "& > .list-item": {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                margin: "1px 2px",
                border: "1px solid #aaa",
                padding: "0 5px",
                backgroundColor: "#e5e5e5",
                overflow: "hidden",

                "& > .remove-item": {
                    marginLeft: "5px",
                    color: "grey",

                    "&:hover": {
                        color: "black",
                    },

                    "&:before": {
                        content: "'\\D7'",
                    },
                },

                "& .list-item-icon, & .list-item-secondary-icon": {
                    textAlign: "center",
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    lineHeight: "18px",
                },

                "& .list-item-content": {
                    lineHeight: "12px",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                },

                "& .list-item-title": {
                    fontSize: "12px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },

                "& .list-item-subtitle": {
                    fontSize: "10px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },

                "& .list-item-icon": {
                    marginRight: "5px",
                },

                "& .list-item-secondary-icon": {
                    marginLeft: "5px",
                },
            },
        },

        "& > .clear-button": {
            marginLeft: "auto",
            fontSize: "1.3em",
            lineHeight: "1.2em",
            color: "grey",
            paddingLeft: "10px",
            alignSelf: "flex-start",

            "&:hover": {
                color: "black",
            },

            "&:before": {
                display: "block",
                width: 10,
                height: 10,
                cursor: "pointer",
                content: "'\\D7'",
            },
        },

        "& > .dropdown-icon": {
            marginRight: "-8px",
            marginTop: "-5px",
            marginBottom: "-5px",
            marginLeft: "5px",
            paddingLeft: "3px",
            lineHeight: "25px",

            "& > i.fa": {
                fontSize: "10px",
                lineHeight: "10px",
            },
        },
    },
    selectMultipleDropdown: {
        position: "fixed",
        background: "white",
        border: "1px solid #e5e5e5",
        padding: "3px",
        zIndex: 10,

        "&.down": {
            borderTop: "none",
        },
        "&.up": {
            borderBottom: "none",
        },

        "& > .items": {
            marginTop: "3px",
            height: "255px",
            display: "flex",

            "& > .loader": {
                minHeight: "34px",
            },

            "& > .no-results": {
                color: "#888",
                textAlign: "center",
                fontSize: "13px",
                marginTop: "10px",
            },

            "& .minimum-input-length": {
                padding: "3px 7px 4px",
                background: "#f4f4f4",
            },

            "& > .list-item": {
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
                padding: "5px",

                "&.group": {
                    "& > .list-item-content": {
                        "& > .list-item-title": {
                            fontWeight: 800,
                            fontSize: "14px",
                        },
                    },
                },

                "&.children": {
                    paddingLeft: "25px",
                },

                "&.active": {
                    backgroundColor: "#2474f6",
                    color: "white",

                    "& .list-item-subtitle": {
                        color: "#e5e5e5",
                    },
                },

                "&:hover": {
                    backgroundColor: "#2474f6",
                    color: "white",

                    "& .list-item-subtitle": {
                        color: "#e5e5e5",
                    },
                },

                "& .list-item-icon, & .list-item-secondary-icon": {
                    textAlign: "center",
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    lineHeight: "18px",
                },

                "& .list-item-title": {
                    fontSize: "13px",
                },

                "& .list-item-subtitle": {
                    fontSize: "11px",
                },

                "& .list-item-icon": {
                    marginRight: "5px",
                },

                "& .list-item-secondary-icon": {
                    marginLeft: "5px",
                },
            },
        },
    },
    selectMultipleDropdownBackdrop: {
        position: "fixed",
        inset: 0,
    },
});
const { classes } = styleSheet.attach();

export type SelectMultipleProps<I extends number | string, T> = {
    placeholder?: ko.MaybeSubscribable<string>;
    searchPlaceholder?: string;
    value: ko.ObservableArray<I> | ko.Computed<I[]>;
    readOnly?: ko.Observable<boolean> | ko.Computed<boolean>;
    allowClear?: boolean;
    dataSource: ObservableArrayDataSource<T, I> | IDataSource<I, T>;
    style?: CSSProperties;
    className?: string;
    renderSelectedItem?: (item: IDataSourceModel<I, T>) => React.ReactElement;
    renderItem?: (item: IDataSourceModel<I, T>) => React.ReactElement;
    renderNoResults?: () => React.ReactElement;
    noResultsMessage?: string;
    minimumInputLength?: number;
    hideSelectedDataFromMenu?: boolean;

    onSelect?: (item: IDataSourceModel<I, T>) => void;
    onDeselect?: (item: IDataSourceModel<I, T>) => void;
    beforeSelect?: (item: IDataSourceModel<I, T>) => Promise<boolean>;
};

export function SelectMultiple<I extends number | string, T>(props: SelectMultipleProps<I, T>) {
    const C = require("./SelectMultiple")._SelectMultiple as typeof _SelectMultiple;
    return <C {...props} />;
}

export class _SelectMultiple<I extends number | string, T> {
    static defaultProps: Partial<SelectMultipleProps<any, any>> = {
        placeholder: "",
        searchPlaceholder: "Cerca...",
        allowClear: false,
        noResultsMessage: "Nessun risultato",
        className: "",
        minimumInputLength: 0,
        hideSelectedDataFromMenu: true,
    };

    loading: ko.Observable<boolean> = ko.observable();
    remainingInputCharsToStartSearch: ko.Observable<number> = ko.observable();
    showMinimumInputLengthMessage: ko.Computed<boolean>;
    minimumInputLengthMessage: ko.Computed<string>;
    hasValue: ko.Computed<boolean>;
    showClearButton: ko.Computed<boolean>;
    isClosed: ko.Observable<boolean> = ko.observable(true);
    hasGroupedData: ko.Observable<boolean> = ko.observable(false);
    selectedItems: ko.ObservableArray<IDataSourceModel<I, T>> = ko.observableArray();
    activeItem: ko.Observable<IDataSourceModel<I, T>> = ko.observable();
    items: ko.ObservableArray<IDataSourceModel<I, T>> = ko.observableArray();
    filter: ko.Observable<string> = ko.observable("");

    dropdownSize: ko.Observable<{ x: number; y: number; width: number; height: number }> = ko.observable({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    dropdownStyle: ko.Observable<string> = ko.observable("down");
    dropdownZIndex: ko.Observable<number> = ko.observable(0);

    private mainDiv: HTMLDivElement;
    private filterSubscription: ko.Subscription;
    private valueSubscription: ko.Subscription;
    private readOnlySubscription: ko.Subscription;
    private textInput: _TextInput;

    keyDownHandler: (e: KeyboardEvent) => void;
    positionUpdater: number;
    portal: Portal;

    constructor(private props: SelectMultipleProps<I, T>) {
        if (!this.props.readOnly) this.props.readOnly = ko.observable(false);

        this.hasValue = ko.computed(
            () => this.props.value() !== undefined && this.props.value() !== null && this.props.value().length > 0
        );

        this.showClearButton = ko.computed(() => this.props.allowClear && this.hasValue());
        this.showMinimumInputLengthMessage = ko.computed(
            () => this.props.minimumInputLength > 0 && (this.filter() ?? "").length < this.props.minimumInputLength
        );

        this.minimumInputLengthMessage = ko.computed(() =>
            this.remainingInputCharsToStartSearch() === 1
                ? String.format(
                      TextResources.ProlifeSdk.MinimumInputLengthSingular,
                      this.remainingInputCharsToStartSearch()
                  )
                : String.format(
                      TextResources.ProlifeSdk.MinimumInputLengthPlural,
                      this.remainingInputCharsToStartSearch()
                  )
        );
        this.remainingInputCharsToStartSearch(this.props.minimumInputLength);

        this.filterSubscription = this.filter.subscribe(() => this.reloadBecauseFilterHasChanged(), "change");
        this.valueSubscription = this.props.value.subscribe(() => this.selectItemsByIds(this.props.value()), "change");
        this.readOnlySubscription = this.props.readOnly.subscribe(() => {
            if (this.mustClose()) this.openClose();
        });

        const getIndexOfActiveItem = () => this.items().indexOf(this.activeItem());
        const fixIndex = (index: number) => Math.max(0, Math.min(index, this.items().length - 1));
        const setActiveItem = (index: number) => this.activeItem(this.items().slice(fixIndex(index))[0]);

        this.keyDownHandler = (e: KeyboardEvent) => {
            if (this.isClosed()) return;
            if (this.loading()) return;

            if (e.key === "ArrowUp") {
                const index = getIndexOfActiveItem();
                if (index <= 0) return;

                e.preventDefault();
                setActiveItem(index - 1);
                this.textInput.blur();
            } else if (e.key === "ArrowDown") {
                const index = getIndexOfActiveItem();
                if (index === this.items().length - 1) return;

                e.preventDefault();
                setActiveItem(index + 1);
                this.textInput.blur();
            } else if (e.key === "Home" && this.activeItem()) {
                if (this.items().length === 0) return;

                e.preventDefault();
                setActiveItem(0);
            } else if (e.key === "End" && this.activeItem()) {
                if (this.items().length === 0) return;

                e.preventDefault();
                setActiveItem(this.items().length - 1); //Last item
            } else if (e.key === "PageDown" && this.activeItem()) {
                const index = this.items().indexOf(this.activeItem());
                if (index === this.items().length - 1) return;

                e.preventDefault();
                setActiveItem(index + 10);
            } else if (e.key === "PageUp" && this.activeItem()) {
                const index = this.items().indexOf(this.activeItem());
                if (index <= 0) return;

                e.preventDefault();
                setActiveItem(index - 10);
            } else if (e.key === "Enter" && this.activeItem()) {
                this.selectItem(this.activeItem());
            } else if (e.key === "Escape" && !this.isClosed()) {
                this.openClose();
            }
        };

        document.addEventListener("keydown", this.keyDownHandler, true);
    }

    @Delay()
    private reloadBecauseFilterHasChanged() {
        if (!this.isClosed()) this.loadItems();
    }

    componentDidMount() {
        if (this.props.value()) this.selectItemsByIds(this.props.value());

        this.createPortal();
    }

    private createPortal() {
        const sel = this;

        this.portal = ComponentUtils.createPortal(
            () =>
                ComponentUtils.bindTo(
                    <IfNot condition={sel.isClosed}>
                        {() => (
                            <>
                                <div
                                    className={classes.selectMultipleDropdownBackdrop}
                                    data-bind={{
                                        event: { mousedown: sel.openClose.bind(sel) },
                                        style: { zIndex: sel.dropdownZIndex },
                                    }}
                                ></div>
                                <div
                                    className={classes.selectMultipleDropdown}
                                    data-bind={{
                                        click: () => {},
                                        clickBubble: false,
                                        style: {
                                            zIndex: sel.dropdownZIndex,
                                            left: sel.dropdownSize().x,
                                            top: sel.dropdownSize().y,
                                            width: sel.dropdownSize().width,
                                            height: sel.dropdownSize().height,
                                        },
                                        css: sel.dropdownStyle,
                                    }}
                                >
                                    <div className="filter">
                                        <TextInput
                                            forwardRef={(ti) => (this.textInput = ti)}
                                            simple
                                            placeholder={this.props.searchPlaceholder}
                                            rightIcon="fa fa-search"
                                            value={this.filter}
                                            valueUpdate="afterkeydown"
                                            canClear
                                        />
                                    </div>
                                    <Layout.ScrollContainer className="items" systemScrollable>
                                        <If
                                            condition={() =>
                                                this.showMinimumInputLengthMessage() && this.items().length === 0
                                            }
                                        >
                                            {() => this.renderMinimumInputLengthMessage()}
                                        </If>
                                        <If condition={() => this.loading() && !this.showMinimumInputLengthMessage()}>
                                            {() => <ActivityIndicator className="loader" />}
                                        </If>
                                        <If
                                            condition={() =>
                                                !this.loading() &&
                                                this.items().length === 0 &&
                                                !this.showMinimumInputLengthMessage()
                                            }
                                        >
                                            {() => this.renderNoResults()}
                                        </If>
                                        <TsxForEach data={this.items} as="item">
                                            {(item) => this.renderItem(item)}
                                        </TsxForEach>
                                    </Layout.ScrollContainer>
                                </div>
                            </>
                        )}
                    </IfNot>,
                    sel,
                    "sel"
                ) as React.ReactElement
        );
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.positionUpdater);

        this.filterSubscription.dispose();
        this.filterSubscription = null;

        this.valueSubscription.dispose();
        this.valueSubscription = null;

        this.mainDiv = null;

        this.portal.dispose();
        this.portal = null;

        document.removeEventListener("keydown", this.keyDownHandler);
    }

    private updateDropdownPosition() {
        if (this.mainDiv) {
            const dropDownHeight = 300;
            let dropdownStyle = "down";
            let node: HTMLElement = this.mainDiv.offsetParent as HTMLElement;
            let x = this.mainDiv.offsetLeft;
            let y = this.mainDiv.offsetTop;
            while (!!node && node !== document.body) {
                const compStyle = window.getComputedStyle(node);
                x += node.offsetLeft + parseFloat(compStyle.borderLeftWidth ?? "0") - node.scrollLeft;
                y += node.offsetTop + parseFloat(compStyle.borderTopWidth ?? "0") - node.scrollTop;

                node = node.offsetParent as HTMLElement;
            }

            const relativeParent = document.body;
            const bodyRect = relativeParent.getBoundingClientRect();
            const zIndex = $(this.mainDiv)
                .parents()
                .toArray()
                .reduce((p, c) => {
                    const s = getComputedStyle(c);
                    return (s.zIndex === "auto" ? 0 : parseInt(s.zIndex)) + p;
                }, 0);
            const { width, height } = this.mainDiv.getBoundingClientRect();

            if (y + height + dropDownHeight < bodyRect.height) y += height;
            else {
                y -= dropDownHeight;
                dropdownStyle = "up";
            }

            const lastSize = this.dropdownSize();
            if (
                lastSize.x != x ||
                lastSize.y != y ||
                lastSize.width != width ||
                this.dropdownStyle() !== dropdownStyle
            ) {
                this.dropdownSize({ x, y, width, height: dropDownHeight });
                this.dropdownStyle(dropdownStyle);
            }

            if (this.dropdownZIndex() !== zIndex) {
                this.dropdownZIndex(zIndex);
            }
        }

        if (!this.isClosed()) requestAnimationFrame(this.updateDropdownPosition.bind(this));
    }

    private openClose(event?: MouseEvent) {
        if (this.canNotOpen()) return;

        this.updateDropdownPosition();

        this.isClosed(!this.isClosed());

        if (!this.isClosed()) {
            this.positionUpdater = requestAnimationFrame(this.updateDropdownPosition.bind(this));

            this.loadItems();

            this.textInput?.focus();
        } else {
            cancelAnimationFrame(this.positionUpdater);
            this.filter("");
        }
    }

    private mustClose() {
        return this.props.readOnly() && !this.isClosed();
    }

    private canNotOpen() {
        return this.props.readOnly() && this.isClosed();
    }

    private async loadItems() {
        if (this.loading()) return;

        this.loading(true);
        this.items([]);
        this.activeItem(undefined);

        try {
            const textFilter = this.filter() ?? "";
            this.remainingInputCharsToStartSearch(Math.max(this.props.minimumInputLength - textFilter.length, 0));

            if (this.props.minimumInputLength === undefined || textFilter.length >= this.props.minimumInputLength) {
                let items = await this.getMenuData(textFilter);
                items = this.filterSelectedItemsIfRequired(items);
                this.items(items);
            }
        } finally {
            this.loading(false);
        }
    }

    private filterSelectedItemsIfRequired(items: IDataSourceModel<any, any>[]): IDataSourceModel<any, any>[] {
        const selectedItemsIds = this.selectedItems().map((i) => i.id);
        return this.props.hideSelectedDataFromMenu
            ? items.filter((item) => selectedItemsIds.indexOf(item.id) < 0)
            : items;
    }

    private async getMenuData(textFilter: string): Promise<IDataSourceModel<any, any>[]> {
        if (isObservableArrayDataSource(this.props.dataSource)) {
            return this.getItems();
        } else {
            const isGroupedData = this.props.dataSource.isGroupedData(null, textFilter);
            this.hasGroupedData(isGroupedData);
            const results = !isGroupedData
                ? await this.props.dataSource.getData(null, textFilter, 0, 50)
                : await this.loadGroupedData();

            return results;
        }
    }

    private async loadGroupedData() {
        const dataSource = this.props.dataSource as IDataSource;
        const groups = await dataSource.getData(null, this.filter(), 0, 50);

        const promises = [];
        for (const group of groups) {
            if (group.isGroup) promises.push(dataSource.getData(group, this.filter(), 0, 50));
            else promises.push(new Promise<IDataSourceModel<any, any>[] | undefined>((resolve) => resolve(undefined)));
        }

        let orderedData: IDataSourceModel<any, any>[] = [];
        const results: IDataSourceModel<any, any>[][] = await Promise.all(promises);

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];

            if (!group.isGroup) {
                orderedData.push(group);
                continue;
            }

            const groupChildren = results[i];
            if (groupChildren.length === 0) continue;

            orderedData.push(group);
            orderedData = orderedData.concat(groupChildren);
        }

        return orderedData;
    }

    private getItems() {
        const { array, factory } = this.props.dataSource as ObservableArrayDataSource<T, I>;
        return array().map((i) => factory(i));
    }

    private async clear() {
        if (this.props.readOnly()) return;

        if (this.props.allowClear) {
            const selectedItems = this.selectedItems();
            this.selectItemsByIds([]);

            if (!this.props.onDeselect) return;

            for (const item of selectedItems) this.props.onDeselect(item);
        }
    }

    private async selectItemsByIds(ids: I[]) {
        const selectedIds = this.selectedItems().map((i) => i.id);
        if (selectedIds.length === ids.length && selectedIds.every((i) => ids.indexOf(i) >= 0))
            // Gli array sono equivalenti, non aggiorno
            return;

        if (ids.length === 0) {
            this.internalSelectItems([]);
            return;
        }

        let items: IDataSourceModel<I, T>[];
        if (isObservableArrayDataSource(this.props.dataSource)) {
            items = this.getItems().filter((i) => ids.indexOf(i.id) !== -1);
        } else {
            items = await this.props.dataSource.getById(null, ids);
        }

        this.internalSelectItems(items);
    }

    private internalSelectItems(items: IDataSourceModel<I, T>[]) {
        //Devono essere effettuati in questo ordine per evitare che venga fatta una chiamata di troppo al data source
        this.selectedItems(items);
        this.props.value(items.map((i) => i.id));
        this.isClosed(true);
        this.filter("");
    }

    private async selectItem(item: IDataSourceModel<I, T>) {
        if (this.props.beforeSelect) {
            if (!(await this.props.beforeSelect(item))) return;
        }

        this.internalSelectItems([...this.selectedItems(), item]);

        if (this.props.onSelect && item) {
            this.props.onSelect(item);
        }
    }

    private removeItem(item: IDataSourceModel<I, T>) {
        if (this.props.readOnly()) return;

        const remainingItems = this.selectedItems().slice();
        remainingItems.remove(item);
        this.internalSelectItems(remainingItems);

        this.props.onDeselect && this.props.onDeselect(item);
    }

    private renderMinimumInputLengthMessage() {
        const sel = this;

        return (
            <div className="minimum-input-length">
                <span data-bind={{ text: sel.minimumInputLengthMessage }}></span>
            </div>
        );
    }

    private renderNoResults() {
        if (this.props.renderNoResults) return <div className="no-results">{this.props.renderNoResults()}</div>;

        return <div className="no-results">{this.props.noResultsMessage}</div>;
    }

    private renderSelectedItem(): React.ReactElement {
        const sel = this;
        if (this.props.renderSelectedItem)
            return (
                <div className="selected-item">
                    <TsxForEach data={this.selectedItems} as="selectedItem">
                        {(selectedItem) => (
                            <div className="list-item">
                                <div className="list-item-content">{this.props.renderSelectedItem(selectedItem)}</div>
                                <span
                                    className="remove-item"
                                    data-bind={{
                                        click: sel.removeItem.bind(sel, selectedItem),
                                        clickBubble: false,
                                        visible: !sel.props.readOnly(),
                                    }}
                                ></span>
                            </div>
                        )}
                    </TsxForEach>
                </div>
            );

        const renderDefaultSelectedItem = (selectedItem: IDataSourceModel<I, T>) => {
            return (
                <div className="list-item">
                    <div className="list-item-content">
                        <div className="list-item-title">{selectedItem.title}</div>
                    </div>
                    <span
                        className="remove-item"
                        data-bind={{
                            click: sel.removeItem.bind(sel, selectedItem),
                            clickBubble: false,
                            visible: !sel.props.readOnly(),
                        }}
                    ></span>
                </div>
            );
        };

        return (
            <div className="selected-item">
                <TsxForEach data={this.selectedItems} as="selectedItem">
                    {(item) => renderDefaultSelectedItem(item)}
                </TsxForEach>
            </div>
        );
    }

    private renderItem(item: IDataSourceModel<I, T>): React.ReactElement {
        const sel = this;

        if (this.props.renderItem)
            return (
                <div
                    className="list-item"
                    data-bind={{
                        click: sel.selectItem.bind(sel, item),
                        clickBubble: false,
                        css: { active: sel.activeItem() === item },
                        scrollIntoViewIfNeeded: () => sel.activeItem() === item,
                    }}
                >
                    {this.props.renderItem(item)}
                </div>
            );

        return item.isGroup ? this.renderGroupItem(item) : this.renderListItem(item);
    }

    private renderGroupItem(item: IDataSourceModel<I, T>): React.ReactElement {
        return (
            <div className="list-item group">
                {item.icon && (
                    <span
                        className={ComponentUtils.classNames("list-item-icon", item.icon.icon)}
                        style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}
                    ></span>
                )}
                <div className="list-item-content">
                    <div className="list-item-title">{item.title}</div>
                </div>
            </div>
        );
    }

    private renderListItem(item: IDataSourceModel<I, T>): React.ReactElement {
        const sel = this;

        return (
            <div
                className="list-item"
                data-bind={{
                    click: sel.selectItem.bind(sel, item),
                    clickBubble: false,
                    css: { active: sel.activeItem() === item, children: sel.hasGroupedData },
                    scrollIntoViewIfNeeded: () => sel.activeItem() === item,
                }}
            >
                {item.icon && (
                    <span
                        className={ComponentUtils.classNames("list-item-icon", item.icon.icon)}
                        style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}
                    ></span>
                )}
                <div className="list-item-content">
                    <div className="list-item-title">{item.title}</div>
                    {item.subTitle && <small className="list-item-subtitle text-muted">{item.subTitle}</small>}
                </div>
                {item.secondaryIcon && (
                    <span
                        className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)}
                        style={{
                            backgroundColor: item.secondaryIcon.background,
                            color: item.secondaryIcon.foreground,
                        }}
                    ></span>
                )}
            </div>
        );
    }

    render() {
        const sel = this;

        return ComponentUtils.bindTo(
            <div
                tabIndex={0}
                className={ComponentUtils.classNames("form-control", classes.selectMultiple, this.props.className)}
                data-bind={{
                    click: sel.openClose,
                    css: { disabled: !!sel.props.readOnly() },
                    ref: (d) => (sel.mainDiv = d),
                }}
                style={this.props.style}
            >
                <span
                    className="placeholder text-ellipsis"
                    data-bind={{ text: sel.props.placeholder, hidden: sel.hasValue }}
                ></span>
                <With data={sel.selectedItems} as="item">
                    {() => this.renderSelectedItem()}
                </With>
                <div
                    className="clear-button"
                    data-bind={{
                        visible: sel.showClearButton() && !sel.props.readOnly(),
                        click: sel.clear.bind(sel),
                        clickBubble: false,
                    }}
                ></div>
                <div className="dropdown-icon">
                    <i
                        className="fa"
                        data-bind={{ css: { "fa-chevron-down": sel.isClosed, "fa-chevron-up": !sel.isClosed() } }}
                    />
                </div>
            </div>,
            this,
            "sel"
        );
    }
}

export namespace SelectMultiple {
    export function WithLabel<I extends number | string, T>(
        props: SelectMultipleProps<I, T> & { label: string | React.ReactElement }
    ) {
        const { className, ...rest } = props;
        return (
            <div className={ComponentUtils.classNames("form-group", className)}>
                {typeof props.label === "string" ? <label className="control-label">{props.label}</label> : props.label}
                <SelectMultiple {...rest} />
            </div>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(SelectMultiple);
}
