import * as ko from "knockout";
import * as moment from "moment";
import { ExpiredSchedulesViewModel } from "./ExpiredSchedulesViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IInvoiceForSchedule } from "../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IScheduleService } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IScheduleReport } from "../../../ProlifeSdk/interfaces/schedule/IScheduleReport";
import { IExpiredSchedules } from "../../../ProlifeSdk/interfaces/schedule/IExpiredSchedules";

export class ScheduleReportViewModel {

	@LazyImport(nameof<IInfoToastService>())
	private infoToastService : IInfoToastService;
	@LazyImport(nameof<IScheduleService>())
	private schedulesService : IScheduleService;

	Id :  number;
	StartDay: Date;
	EndDay : Date;
	Anno:  number;
	Periodo :  number;
	NumeroFatture :  number;
	TotaleFatture :  number;
	TotalePagato :  number;
	TotaleNonPagato :  number;
	TotaleNonPagatoScaduto :  number;
	MediaRitardo : number;
	MaxRitardo :  number;

	expiredSchedules : ko.ObservableArray<ExpiredSchedulesViewModel> = ko.observableArray([]);


	constructor(report : IScheduleReport, invoice : IInvoiceForSchedule) {

		this.Id = report.Id;

		this.StartDay = moment(report.StartDay).toDate();
		this.EndDay = moment(report.EndDay).toDate();
		this.Anno = report.Anno;
		this.Periodo = report.Periodo;
		this.NumeroFatture = report.NumeroFatture;
		this.TotaleFatture = report.TotaleFatture;
		this.TotalePagato = report.TotalePagato;
		this.TotaleNonPagato = report.TotaleNonPagato;
		this.TotaleNonPagatoScaduto = report.TotaleNonPagatoScaduto;
		this.MediaRitardo = report.MediaRitardo;
		this.MaxRitardo = report.MaxRitardo;

		this.schedulesService.getFattureInScadenzaPerEsercizio(invoice.CustomerId, report.Id)
			.then(this.onScheduledLoaded.bind(this))
			.catch(this.onFailed.bind(this));
	}

	onFailed(jqXHR: JQueryXHR) : void{
		this.infoToastService.Error(jqXHR.statusText);
	}

	onScheduledLoaded(expiredSchedules : IExpiredSchedules[]){
		this.expiredSchedules(expiredSchedules.map((schedule : IExpiredSchedules) => new ExpiredSchedulesViewModel(schedule)));
	}
}