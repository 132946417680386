import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 17:17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { NavigationProvider } from "../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { AgendasNavigationProvider } from "./AgendasNavigationProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IAgendasNavigationMenuRootProvider } from "../../../../interfaces/IAgendaNavigationMenu";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IAgendaService, IAgenda } from "../../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ICategoriesSettingsManager } from "../../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { IFolder } from "../../../../../ProlifeSdk/interfaces/agenda/IFolderService";
import { Deferred } from "../../../../../Core/Deferred";

export class RootNavigationProvider extends NavigationProvider implements IAgendasNavigationMenuRootProvider {
    public SearchPlaceholder: string;
    public Title: ko.Observable<string> = ko.observable();
    public Droppable: ko.Observable<boolean> = ko.observable(false);

    public Groups : ko.Computed<AgendasGroupViewModel[]>;

    private settingsService: ISettingsService;
    private infoToastService: IInfoToastService;
    private agendasService: IAgendaService;

    private foldersSettingManager: ICategoriesSettingsManager;

    //private folders: KnockoutObservableArray = ko.observableArray([]);

    constructor(private serviceLocator: IServiceLocator) {
        super();
        this.settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);

        this.foldersSettingManager = <ICategoriesSettingsManager> this.settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);

        this.Name = ProlifeSdk.TextResources.Agenda.AgendasMenu;
        this.Title(this.Name);
        this.AllowMultiLevelSearch = true;
        this.SearchPlaceholder = ProlifeSdk.TextResources.Agenda.SearchPlaceHolder;

        this.Groups = ko.computed(() => {
            this.Items();
            //this.folders();
            var folders: IFolder[] = this.foldersSettingManager.getFolders();
            return folders.map((f: IFolder) => { return new AgendasGroupViewModel(f, this); });
        });
    }

    public SelectProviderById(providerId : number) {
        var providers = <AgendasNavigationProvider[]> this.Items().filter(p => p.Id == providerId);
        if(providers.length > 0) {
            providers[0].open(providers[0]);
        }
    }

    public refreshItems(): Promise<void> {
        var deferred = new Deferred<void>();

        this.agendasService.GetAllAgendas(this.navigator().ShowDeletedElements())
            .then((agendas: IAgenda[]) => {
                if (!agendas)
                    return;
                this.Items(agendas.map((a: IAgenda) => { return this.CreateAgendasNavigationProvider(a); }));
                this.SearchedItems(this.Items());
                deferred.resolve();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendasError);
                deferred.reject();
            });

        return deferred.promise();
    }

    public search(textFilter : string) : Promise<void>
    {
        var deferred = new Deferred<void>();

        this.refreshItems()
            .then(() => {
                if (!textFilter) {
                    this.SearchedItems(this.Items().map((a: AgendasNavigationProvider) => { return a; }));
                    deferred.resolve();
                    return;
                }

                var words = textFilter.trim().split(' ');
                words = words.filter(w => w.trim().length > 0).map(w => w.trim().toLowerCase());

                this.SearchedItems(this.Items().filter((a : AgendasNavigationProvider) => {
                    var foundWords = words.filter(w => (a.Title()).toLowerCase().indexOf(w) > -1);
                    return foundWords.length == words.length;
                }));
                deferred.resolve();
            })
            .catch(() => {
                this.SearchedItems([]);
                deferred.reject()
            });

        return deferred.promise();
    }

    public insertAgenda(agenda: IAgenda): void {
        var agendasProvider = new AgendasNavigationProvider(this.serviceLocator, agenda);
        agendasProvider.setCurrentNavigator(this.navigator());

        var items: AgendasNavigationProvider[] = <AgendasNavigationProvider[]> this.Items();
        var done: boolean = false;
        for (var i = 0; i < items.length; i++) {
            if (items[i].Title() > agenda.Name) {
                items.splice(i, 0, agendasProvider);
                done = true;
                break;
            }
        }

        if (!done)
            items.push(agendasProvider);

        this.Items(items);
    }

    public updateAgenda(agenda: IAgenda): void {
        this.Items().forEach((a: AgendasNavigationProvider) => {
            if (a.Id != agenda.Id)
                return;

            a.UpdateFromModel(agenda);
        });
    }

    public removeAgenda(agendaId: number, folderId: number): void {
        var agendas = this.Items();

        for (var i = 0; i < agendas.length; i++) {
            if (agendas[i].Id == agendaId) {
                agendas.splice(i, 1);
                break;
            }
        }

        this.Items(agendas);
    }

    private CreateAgendasNavigationProvider(agenda: IAgenda): AgendasNavigationProvider {
        var provider = new AgendasNavigationProvider(this.serviceLocator, agenda);
        provider.setCurrentNavigator(this.navigator());
        return provider;
    }
}

class AgendasGroupViewModel {
    public Title: ko.Observable<string> = ko.observable();
    public Collapsed: ko.Observable<boolean> = ko.observable();
    public HasItems : ko.Computed<boolean>;
    public ItemsCount: ko.Computed<number>;
    public Items : ko.Computed<AgendasNavigationProvider[]>;

    constructor(private group: IFolder, private provider: RootNavigationProvider) {
        this.Title(this.group.Name);
        this.Collapsed(!this.group.Expanded);

        this.Items = ko.computed(() => {
            var groupItems: AgendasNavigationProvider[] = [];
            var allItems: AgendasNavigationProvider[] = <AgendasNavigationProvider[]> provider.SearchedItems();

            for ( var i = 0; i < allItems.length; i++) {
                if (allItems[i].FolderId() == this.group.Id)
                    groupItems.push(allItems[i]);
            }

            return  groupItems;
        });

        this.ItemsCount = ko.computed(() => {
            return this.Items().length;
        });

        this.HasItems = ko.computed(() => {
            return this.ItemsCount() > 0;
        });
    }

    public SwitchCollapsed() {
        this.Collapsed(!this.Collapsed());
    }
}