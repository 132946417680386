import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Layout } from "../../../Components/Layouts";
import { BackButton } from "../../../Components/BackButton";
import { Portlet } from "../../../Components/Portlet";
import { QuotationCategoriesList, QuotationCategoryEditor } from ".";
import { If } from "../../../Components/IfIfNotWith";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IQuotationService, IQuotationCategory } from "../../QuotationService";

type QuotationCategoriesEditorProps = {
    quotationId: ko.Observable<number> | ko.Computed<number> | (() => number);
    onClose: () => void;
}

export function QuotationCategoriesEditor(props : QuotationCategoriesEditorProps) {
    const C = require("./QuotationCategoriesEditor")._QuotationCategoriesEditor as typeof _QuotationCategoriesEditor;
    return <C {...props} />
}

export class _QuotationCategoriesEditor extends React.Component<QuotationCategoriesEditorProps> {
    category : ko.Observable<IQuotationCategory> = ko.observable();
    categories : ko.ObservableArray<IQuotationCategory> = ko.observableArray();

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IQuotationService>())
    private quotationService : IQuotationService;

    componentDidMount() {
        useEffect(() => {
            if(!this.props.quotationId()) return;

            const loadCategories = async () => {
                this.categories(await this.quotationService.getCategories(this.props.quotationId()));
                this.onNewCategory();
            };

            loadCategories();
        }, [this.props.quotationId])
        
    }

    private onNewCategory() {
        this.category({ 
            Id: this.idGeneratorService.getNextId(), 
            FKQuotation: this.props.quotationId(), 
            Name: "", 
            Type: 1, 
            Values: [] 
        });
    } 

    render() {
        const onAddOrUpdateCategory = async (c : IQuotationCategory) => {
            const newCategory = await this.quotationService.createOrUpdateCategory(c)
            const existingCategory = this.categories().find(ec => ec.Id === newCategory.Id);
            if(existingCategory) {
                this.categories.replace(existingCategory, newCategory);
                this.category(newCategory);
            } else {
                this.categories.push(newCategory);
                this.onNewCategory();
            }
        };

        const onRemoveCategory = async (c : IQuotationCategory) => {
            await this.quotationService.removeCategory(c.Id);
            const existingCategory = this.categories().find(ec => ec.Id === c.Id);
            if(existingCategory)
                this.categories.remove(existingCategory);
        };

        return  <Layout.Grid columns={["1fr", "2fr"]} rows={["120px", "10fr"]}>
                    <Layout.Grid.Cell column={"1/3"} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <BackButton onClick={this.props.onClose} style={{ marginRight: '20px' }} />
                                <Portlet.Header.Default title="Gestione Categorie" className="font-red-sunglo bold uppercase" />
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <QuotationCategoriesList onNew={() => this.onNewCategory()} categories={this.categories} selectedCategory={this.category} onCategorySelected={c => this.category(c)} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={2}>
                        <If condition={this.category}>
                            {() => <QuotationCategoryEditor category={this.category} onSave={onAddOrUpdateCategory} onDelete={onRemoveCategory} />}
                        </If>
                    </Layout.Grid.Cell>
                </Layout.Grid>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationCategoriesEditor);
}