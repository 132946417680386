
import ko = require("knockout");
import jss from "jss";import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../../../../Core/utils/ComponentUtils";
import { WizardDialogStepBase } from "../../../../../../ProlifeSdk/prolifesdk/WizardDialogStepBase";
import { IWarehouseInspectionWizardArticleInfo, IWarehouseInspectionsService } from "../../../../../WarehouseInspectionsService";
import { ArticleRequirements } from "./ArticleRequirements";
import { LazyImport } from "../../../../../../Core/DependencyInjection";
import { BindTo } from "../../../../../../Components/Bind";
import { VerticalLayout, LayoutRow, LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../../../Components/Layouts";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { SourceArticlesTable, SourceArticlesTableFilters } from "./SourceArticlesTable";
import { IWarehouseArticlesImporterForInspectionInitializationInfo } from "../WarehouseArticlesImporterForInspection";
import { SelectedArticlesInfoTable } from "./SelectedArticlesInfoTable";
import { SelectedArticleInfo } from "./SelectedArticleInfo";

const { classes } = jss.createStyleSheet({
    section: {
        "& .title": {
            fontSize: "18px",
            fontWeight: "400"
        }
    }
}).attach();

export function WarehouseArticlesImporterDataSourceBase(props: IWarehouseArticlesImporterForInspectionInitializationInfo) {
    const C = require("./WarehouseArticlesImporterDataSourceBase")._WarehouseArticlesImporterDataSourceBase as typeof _WarehouseArticlesImporterDataSourceBase;
    return <C {...props} />;
}

export abstract class _WarehouseArticlesImporterDataSourceBase extends WizardDialogStepBase<IWarehouseInspectionWizardArticleInfo[], IWarehouseInspectionWizardArticleInfo[]> {
    public Articles: ko.ObservableArray<ArticleRequirements> = ko.observableArray([]);
    public ChoosenArticles: ko.ObservableArray<SelectedArticleInfo> = ko.observableArray([]);

    @LazyImport(nameof<IWarehouseInspectionsService>())
    protected warehouseInspectionsService: IWarehouseInspectionsService;
    
    protected previousStepArticles: IWarehouseInspectionWizardArticleInfo[] = [];

    protected showLastInspectionDateColumn: boolean = false;
    protected showDocumentColumn: boolean = false;
    protected showAmountColumnOnSelectedArticles: boolean = false;
    protected sourcesFromWarehouseStocks: boolean = false;
    protected defaultLastInspectionDateFilter: Date = null;
    protected defaultMaxShownArticlesNumberFilter: number = null;

    constructor(protected props: IWarehouseArticlesImporterForInspectionInitializationInfo) {
        super(); 
    }

    public async Initialize(): Promise<void> {
        await this.loadArticles();
    };

    public OnShow(previousStepResult: IWarehouseInspectionWizardArticleInfo[]): void {
        this.previousStepArticles = previousStepResult || [];
    }
    
    public OnNext(): IWarehouseInspectionWizardArticleInfo[] {
        let articles = this.ChoosenArticles();
        let data = [];
        for (let article of articles)
            data = data.concat(article.getData());

        return this.previousStepArticles.concat(data);
    }

    public emptySelectedArticlesList(): void {
        this.ChoosenArticles([]);
    }

    public abstract importSelectedArticles(): void;

    protected abstract loadArticles(): Promise<void>;

    protected abstract onFiltersChanges(filters: SourceArticlesTableFilters): Promise<void>;

    public render(): React.ReactNode {
        let dataSource: _WarehouseArticlesImporterDataSourceBase;

        return (
            <BindTo viewModel={this} as="dataSource">
                <VerticalLayout>
                    <LayoutRow>
                        <LayoutWithHeader className={classes.section}>
                            <LayoutHeader>
                                <div className="flex-fill">
                                    <span className="title">{TextResources.Warehouse.ArticlesToBeReviewed}</span>
                                </div>
                                <button type="button" className="btn btn-sm btn-primary" data-bind={{ click: dataSource.importSelectedArticles.bind(dataSource) }}>
                                    {TextResources.ProlifeSdk.Import}
                                </button>
                            </LayoutHeader>
                            <LayoutContent noOverflow={true}>
                                <SourceArticlesTable
                                    articles={this.Articles}
                                    showLastInspectionDateColumn={this.showLastInspectionDateColumn}
                                    showDocumentColumn={this.showDocumentColumn}
                                    sourceFromStocks={this.sourcesFromWarehouseStocks}
                                    defaultDateFilter={this.defaultLastInspectionDateFilter}
                                    defaultMaxShownArticlesFilter={this.defaultMaxShownArticlesNumberFilter}
                                    onFiltersChanges={this.onFiltersChanges.bind(this)}
                                />
                            </LayoutContent>
                        </LayoutWithHeader>
                    </LayoutRow>
                    <LayoutRow>
                        <LayoutWithHeader className={classes.section}>
                            <LayoutHeader>
                                <div className="flex-fill">
                                    <span className="title">{TextResources.Warehouse.ChoosenArticlesForReview}</span>
                                </div>
                                <button type="button" className="btn btn-sm btn-danger" data-bind={{ click: dataSource.emptySelectedArticlesList.bind(dataSource) }}>
                                    {TextResources.ProlifeSdk.ClearTable}
                                </button>
                            </LayoutHeader>
                            <LayoutContent noOverflow={true}>
                                <SelectedArticlesInfoTable operationsInfo={this.ChoosenArticles} showAmountColumn={this.showAmountColumnOnSelectedArticles} />
                            </LayoutContent>
                        </LayoutWithHeader>
                    </LayoutRow>
                </VerticalLayout>
            </BindTo>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WarehouseArticlesImporterDataSourceBase);
}