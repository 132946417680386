import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IProtocolType, IVatRegisterEditingContainer } from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { PurchaseRequestProtocolSettings } from "./PurchaseRequestProtocolSettings";
import { Right } from "../../../Core/Authorizations";

export class PurchaseRequestProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.PurchaseRequestTypeId;
    public DocumentTypeName: string = TextResources.Provisioning.PurchaseRequest;
    public DocumentTypeLabelForSettings: string = TextResources.Provisioning.PurchaseRequestProtocols;
    public DocumentTypeShortLabelForSettings: string = TextResources.Provisioning.PurchaseRequestProtocolsShortLabel;

    HasCustomTemplate = true;

    @Right("Provisioning_PurchaseRequest")
    private canAddProtocolRight: boolean;

    constructor() {}

    canAddProtocols(): boolean {
        return this.canAddProtocolRight;
    }

    createSettingsViewModel(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister
    ): PurchaseRequestProtocolSettings {
        return new PurchaseRequestProtocolSettings(container, vatRegister);
    }
}
