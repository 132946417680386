import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { LazyImport } from "../../Core/DependencyInjection";

export class AssetsApplication implements IApplication
{
    public templateName : string;
    public templateUrl : string;
    public templateData : any;
    public tileColor : string = 'tile-red';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Assets";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Assets.Assets;
    }

    getIcon(): string
    {
        return "icon-screen-desktop";
    }
    
    registerRoutes() : void
    {
    }

    getApplicationRoute() : string
    {
        return "http://vecchiaintranet.ab/cespiti/ricerca.aspx";
    }

    onGoingDown() : void
    {
        if(this.templateData)
            this.templateData.dispose();
    }

    private start()
    {
    }
}