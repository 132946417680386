import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CartWorkableHoursCalculationModes } from "../enums/CartWorkableHoursCalculationModes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TaskboardOpeningMode } from "../enums/TaskboardOpeningMode";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    IJobOrderGeneralSettingsManager,
    IJobOrderGeneralSettings,
} from "../../../interfaces/settings/IJobOrderGeneralSettingsManager";
import { PlanningTaskboardMenuMode } from "../enums/PlanningTaskboardMenuMode";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { JobOrderSelect } from "./JobOrderSelect";
import { RoleSelect } from "./RoleSelect";
import { WorkTimeCategorySelect } from "./WorkTimeCategorySelect";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { RolesDataSource } from "../../../../DataSources/RolesDataSource";
import { WorkTimeCategoriesDataSource } from "../../../../DataSources/WorkTimeCategoriesDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";

export class JobOrderGeneralSettingsEditingViewModel {
    public title: string;
    public ShowJobOrderIdOnTitles: ko.Observable<boolean> = ko.observable();
    public ShowTaskIdOnTitles: ko.Observable<boolean> = ko.observable();
    public CartsDefaultIgnoreBacklog: ko.Observable<boolean> = ko.observable();
    public ActivitiesProgressAmountMode: ko.Observable<number> = ko.observable();
    public TaskboardStartMode: ko.Observable<TaskboardOpeningMode> = ko.observable(TaskboardOpeningMode.Projects);
    public PlanningTaskboardMenuStartMode: ko.Observable<PlanningTaskboardMenuMode> = ko.observable(
        PlanningTaskboardMenuMode.Calendar
    );
    public CartWorkableHoursCalculationMode: ko.Observable<CartWorkableHoursCalculationModes> = ko.observable(
        CartWorkableHoursCalculationModes.JobOrderSpeed
    );
    public ShowHiddenJobOrdersAndResourcesOnWorkedHours: ko.Observable<boolean> = ko.observable();
    public ShowCartEditingFormOnActivityAllocation: ko.Observable<boolean> = ko.observable(false);
    public ViewAllOnWorkedHoursEditor: ko.Observable<boolean> = ko.observable();
    public BillableFlagOnWorkedHours: ko.Observable<boolean> = ko.observable();
    public ShowWorkflowCodeOnTitle: ko.Observable<boolean> = ko.observable();
    public ShowWarehouseCodeOnTitle: ko.Observable<boolean> = ko.observable();
    public EnableFlexibility: ko.Observable<boolean> = ko.observable();
    public DefaultFlexibilityRole: ko.Observable<number> = ko.observable();
    public DefaultFlexibilityJobOrder: ko.Observable<number> = ko.observable();
    public DefaultFlexibilityWorkTimeCategory: ko.Observable<number> = ko.observable();
    public IsDefaultFlexibilityRoleReadonly: ko.Observable<boolean> = ko.observable(false);

    public JobOrderSelect: JobOrderSelect;
    public RoleSelect: RoleSelect;
    public WorkTimeCategorySelect: WorkTimeCategorySelect;

    public JobOrderSelectDataSource: JobOrdersDataSource;
    public RoleSelectDataSource: RolesDataSource;
    public WorkTimeCategorySelectDataSource: WorkTimeCategoriesDataSource;

    public ShowJobOrderMetadataOnAutoCartNameCreation: ko.Observable<boolean> = ko.observable();
    public ShowJobOrderCodeOnAutoCartNameCreation: ko.Observable<boolean> = ko.observable();
    public ShowWorkflowCodeOnAutoCartNameCreation: ko.Observable<boolean> = ko.observable();

    public SetFlagIsApprovedWorkedHours: ko.Observable<boolean> = ko.observable();
    public ShowAlertWhenNegativeStockOnDocumentSave: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private settingsManager: IJobOrderGeneralSettingsManager) {
        this.title = settingsManager.getLabel();

        this.JobOrderSelectDataSource = new JobOrdersDataSource();
        this.RoleSelectDataSource = new RolesDataSource();
        this.WorkTimeCategorySelectDataSource = new WorkTimeCategoriesDataSource();

        this.WorkTimeCategorySelectDataSource.setResourceType(0); // Human
        this.RoleSelectDataSource.setResourceType(0); // Human
        this.WorkTimeCategorySelectDataSource.setShowResourceDefaultOption(false);
        this.RoleSelectDataSource.setShowResourceDefaultOption(false);

        this.JobOrderSelect = new JobOrderSelect({
            dataSource: this.JobOrderSelectDataSource,
            value: this.DefaultFlexibilityJobOrder,
            placeholder: TextResources.JobOrder.JobOrderSelectPlaceholder,
            allowClear: true,
        });

        this.RoleSelect = new RoleSelect({
            dataSource: this.RoleSelectDataSource,
            value: this.DefaultFlexibilityRole,
            placeholder: TextResources.JobOrder.RoleSelectPlaceholder,
            readOnly: this.IsDefaultFlexibilityRoleReadonly,
            onSelect: (item: IDataSourceModel<number, IUserCharacter>) => {
                this.DefaultFlexibilityWorkTimeCategory(item.model.DefaultWorkTimeCategory);
            },
            onDeselect: () => {
                this.DefaultFlexibilityWorkTimeCategory(null);
            },
            allowClear: true,
        });

        this.WorkTimeCategorySelect = new WorkTimeCategorySelect({
            dataSource: this.WorkTimeCategorySelectDataSource,
            value: this.DefaultFlexibilityWorkTimeCategory,
            readOnly: ko.observable(true),
            placeholder: TextResources.JobOrder.WorkTimeCategorySelectPlaceholder,
        });

        this.reloadSettings();
    }

    public save() {
        const config: IJobOrderGeneralSettings = {
            showJobOrderIdOnTitles: this.ShowJobOrderIdOnTitles(),
            showTaskIdOnTitles: this.ShowTaskIdOnTitles(),
            cartsDefaultIgnoreBacklog: this.CartsDefaultIgnoreBacklog(),
            activitiesProgressAmountMode: this.ActivitiesProgressAmountMode(),
            taskboardStartMode: this.TaskboardStartMode(),
            planningTaskboardMenuStartMode: this.PlanningTaskboardMenuStartMode(),
            cartWorkableHoursCalculationMode: this.CartWorkableHoursCalculationMode(),
            showHiddenJobOrdersAndResourcesOnWorkedHours: this.ShowHiddenJobOrdersAndResourcesOnWorkedHours(),
            showCartEditingFormOnActivityAllocation: this.ShowCartEditingFormOnActivityAllocation(),
            viewAllOnWorkedHoursEditor: this.ViewAllOnWorkedHoursEditor(),
            enableFlexibility: this.EnableFlexibility(),
            defaultFlexibilityRole: this.DefaultFlexibilityRole(),
            defaultFlexibilityJobOrder: this.DefaultFlexibilityJobOrder(),
            defaultFlexibilityWorkTimeCategory: this.DefaultFlexibilityWorkTimeCategory(),
            isDefaultFlexibilityRoleReadonly: this.IsDefaultFlexibilityRoleReadonly(),
            showJobOrderMetadataOnAutoCartNameCreation: this.ShowJobOrderMetadataOnAutoCartNameCreation(),
            showJobOrderCodeOnAutoCartNameCreation: this.ShowJobOrderCodeOnAutoCartNameCreation(),
            showWorkflowCodeOnAutoCartNameCreation: this.ShowWorkflowCodeOnAutoCartNameCreation(),
            setFlagIsApprovedWorkedHours: this.SetFlagIsApprovedWorkedHours(),
            billableFlagOnWorkedHours: this.BillableFlagOnWorkedHours(),
            showWorkflowCodeOnTitle: this.ShowWorkflowCodeOnTitle(),
            showWarehouseCodeOnTitle: this.ShowWarehouseCodeOnTitle(),
            showAlertWhenNegativeStockOnDocumentSave: this.ShowAlertWhenNegativeStockOnDocumentSave(),
        };

        this.settingsManager.saveSettings(config).then(() => {
            this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.GeneralSettingsSavedSuccessfully);
        });
    }

    public changeActivitiesProgressAmountModeState(value: number): void {
        this.ActivitiesProgressAmountMode(value);
    }

    public changeTaskboardStartModeState(value: number): void {
        this.TaskboardStartMode(value);
    }

    public changePlanningTaskboardMenuStartMode(value: number): void {
        this.PlanningTaskboardMenuStartMode(value);
    }

    public cancel() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.JobOrder.SureToResetGeneralSettingsChanges,
            ProlifeSdk.TextResources.JobOrder.DoNotResetGeneralSettings,
            ProlifeSdk.TextResources.JobOrder.DoResetGeneralSettings,
            this.onConfirmCancel.bind(this)
        );
    }

    private onConfirmCancel(result: boolean) {
        if (!result) return;

        this.reloadSettings();
    }

    private reloadSettings() {
        this.ShowJobOrderIdOnTitles(this.settingsManager.getShowJobOrderIdOnTitles());
        this.ShowTaskIdOnTitles(this.settingsManager.getShowTaskIdOnTitles());
        this.CartsDefaultIgnoreBacklog(this.settingsManager.getCartsDefaultIgnoreBacklog());
        this.ActivitiesProgressAmountMode(this.settingsManager.getActivitiesProgressAmountMode());
        this.TaskboardStartMode(this.settingsManager.getTaskboardStartMode());
        this.PlanningTaskboardMenuStartMode(this.settingsManager.getPlanningTaskboardMenuStartMode());
        this.CartWorkableHoursCalculationMode(
            this.settingsManager.getCartWorkableHoursCalculationMode() as CartWorkableHoursCalculationModes
        );
        this.ShowHiddenJobOrdersAndResourcesOnWorkedHours(
            this.settingsManager.getShowHiddenHobOrdersAndResourcesOnWorkedHours()
        );
        this.ShowCartEditingFormOnActivityAllocation(this.settingsManager.getShowCartEditingFormOnActivityAllocation());
        this.ViewAllOnWorkedHoursEditor(this.settingsManager.getViewAllOnWorkedHoursEditor());
        this.EnableFlexibility(this.settingsManager.getEnableFlexibility());
        this.DefaultFlexibilityRole(this.settingsManager.getDefaultFlexibilityRole());
        this.DefaultFlexibilityJobOrder(this.settingsManager.getDefaultFlexibilityJobOrder());
        this.DefaultFlexibilityWorkTimeCategory(this.settingsManager.getDefaultFlexibilityWorkTimeCategory());
        this.IsDefaultFlexibilityRoleReadonly(this.settingsManager.getIsDefaultFlexibilityRoleReadonly());
        this.ShowJobOrderMetadataOnAutoCartNameCreation(
            this.settingsManager.getShowJobOrderMetadataOnAutoCartNameCreation()
        );
        this.ShowJobOrderCodeOnAutoCartNameCreation(this.settingsManager.getShowJobOrderCodeOnAutoCartNameCreation());
        this.ShowWorkflowCodeOnAutoCartNameCreation(this.settingsManager.getShowWorkflowCodeOnAutoCartNameCreation());
        this.ShowWorkflowCodeOnTitle(this.settingsManager.getShowWorkflowCodeOnTitle());
        this.ShowWarehouseCodeOnTitle(this.settingsManager.getShowWarehouseCodeOnTitle());
        this.SetFlagIsApprovedWorkedHours(this.settingsManager.getSetFlagIsApprovedWorkedHours());
        this.ShowAlertWhenNegativeStockOnDocumentSave(
            this.settingsManager.getShowAlertWhenNegativeStockOnDocumentSave()
        );
        this.BillableFlagOnWorkedHours(this.settingsManager.getBillableFlagOnWorkedHours());
    }
}
