import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
    iconPreview: {}
});
const { classes } = styleSheet.attach();

type IconPreviewProps = {
    icon: ko.Observable<string>;
    background: ko.Observable<string>;
    foreground: ko.Observable<string>;
    label?: string;
}

export function IconPreview(props: IconPreviewProps) {
    const C = require("./IconPreview")._IconPreview as typeof _IconPreview;
    return <C {...props} />;
}

export class _IconPreview {
    static defaultProps: Partial<IconPreviewProps> = {
    }

    constructor(private props : IconPreviewProps) {
        
    }
    
    render() {
        const iconPreview = this;

        return ComponentUtils.bindTo((
            <div className={classes.iconPreview}>
                {this.props.label && <label>{this.props.label}</label>}
                <div>
                    <div style={{ padding: "8px", marginTop: "-6px", float: "left", background: "#ffffff" }}>
                        <div className="btn btn-primary btn-lg" data-bind={{ style: { background: iconPreview.props.background, color: iconPreview.props.foreground } }} style={{ minHeight: "30px", minWidth: "35px", textAlign: "center", padding: 0, margin: "2px", lineHeight: "30px" }}>
                            <i style={{ color: "inherit" }} data-bind={{ css: iconPreview.props.icon }}></i>
                        </div>
                    </div>
                    <div style={{ padding: "8px", marginTop: "-6px", float: "left", background: "#1F1F1F" }}>
                        <div className="btn btn-primary btn-lg" data-bind={{ style: { background: iconPreview.props.background, color: iconPreview.props.foreground } }} style={{ minHeight: "30px", minWidth: "35px", textAlign: "center", padding: 0, margin: "2px", lineHeight: "30px" }}>
                            <i style={{ color: "inherit" }} data-bind={{ css: iconPreview.props.icon }}></i>
                        </div>
                    </div>
                </div>
            </div>
        ), this, "iconPreview");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(IconPreview);
}