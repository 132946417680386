import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { IWorkedHoursRowForList } from "../../../interfaces/IWorkedHoursEditor";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IHoursApproval_Type, IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { WorkedHoursRowForList } from "./WorkedHoursRowForList";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../Components/Layouts";
import { Right } from "../../../../Core/Authorizations";
import { HoursApprovalState as HoursApprovalStateEnum } from "../../enums/HoursApprovalState";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import { HoursApprovalState } from "./HoursApprovalState";
import { WorkPlace } from "./WorkPlace";
import { ApprovalStateTable } from "./ApprovalStateTable";
import { IHoursApprovalGroup } from "../../../../Users/UsersService";
import { HoursApprovalStateUtilities } from "../approval/HoursApprovalStateUtilities";

export interface IWorkedHoursEditorList {
    canApproveHours: ko.Subscribable<boolean>;
    timezone: ko.Observable<string>;
    activities: ko.ObservableArray<IWorkedHoursRowForList>;
    activitiesForMultipleInsert: ko.ObservableArray<IWorkedHoursRowForList>;
    multipleInsertEnabled: ko.Observable<boolean>;
    resourceApprovalGroups: ko.Subscribable<IHoursApprovalGroup[]>;
    insertPerJobOrder?: boolean;
    
    isLoading: () => boolean;

    onWorkedHoursMassiveApproval: () => Promise<void>;
}

export class WorkedHoursEditorList {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;

    public Title: ko.Computed<string>;

    public Activities: ko.ObservableArray<IWorkedHoursRowForList>;
    public ActivitiesForMultipleInsert: ko.ObservableArray<IWorkedHoursRowForList>;
    public MultipleInsertEnabled: ko.Observable<boolean>;

    public SelectedTimeZone: ko.Observable<string>;
    public ShowTimeZoneSelectorPopover: ko.Observable<boolean> = ko.observable(false);

    private ListShowedWithDifferentTimezone: ko.Computed<boolean>;
    private resettingActivitiesListTimeZones = false;
    
    @Right("WorkedHours_viewEditRole")
    private canViewRole: boolean;
    @Right("WorkedHours_viewEditWorkTimeCategory")
    private canViewWorkTimeCategory: boolean;
    @Right("WorkedHours_viewEditBillableAndCallRightFlag")
    private canViewBillableAndCallRightFlag: boolean;
    @Right("WorkedHours_viewEditWorkPlace")
    private canViewWorkPlace: boolean;
    @Right("WorkedHours_viewEditTravelDistance")
    private canViewTravelDistance: boolean;

    private baseColumnsNumber = 6;
    private columnsNumber = 6;

    constructor(private props: IWorkedHoursEditorList) {
        this.Activities = this.props.activities;
        this.ActivitiesForMultipleInsert = this.props.activitiesForMultipleInsert;
        this.MultipleInsertEnabled = this.props.multipleInsertEnabled;
        this.SelectedTimeZone = this.props.timezone;

        this.Title = ko.computed(() => {
            return this.MultipleInsertEnabled() ? TextResources.WorkedHours.MultipleInsertListTitle : TextResources.WorkedHours.InsertedHoursDefaultListTitle;
        });

        this.SelectedTimeZone.subscribe((timezone: string) => {
            if (this.props.isLoading())
                return;

            if (!timezone) {
                this.resetActivitiesListTimeZones();
                return;
            }

            const activities = this.Activities();
            const activitiesForMultipleInsert = this.ActivitiesForMultipleInsert();
            
            activities.forEach((a) => {
                a.WorkDateTimeZone(timezone);
                a.EndTimeTimeZone(timezone);
            });

            activitiesForMultipleInsert.forEach((a) => {
                a.WorkDateTimeZone(timezone);
                a.EndTimeTimeZone(timezone);
            });

            this.Activities([]);
            this.Activities(activities);
            this.ActivitiesForMultipleInsert([]);
            this.ActivitiesForMultipleInsert(activitiesForMultipleInsert);
        });

        this.ListShowedWithDifferentTimezone = ko.computed(() => {
            const activities = this.MultipleInsertEnabled() ? this.ActivitiesForMultipleInsert() : this.Activities();

            return activities.filter((a) => a.ShowedTimezoneDifferentFromOriginalData()).length > 0;
        });

        this.calculateAndSetColumnsNumber();
    }

    componentDidMount(){
    }

    componentWillUnmount(){
    }
    
    private calculateAndSetColumnsNumber() {
        this.columnsNumber = this.baseColumnsNumber;
        this.columnsNumber += (this.canViewRole ? 1 : 0);
        this.columnsNumber += (this.canViewWorkTimeCategory ? 1 : 0);
        this.columnsNumber += (this.canViewWorkPlace ? 1 : 0);
        this.columnsNumber += (this.canViewTravelDistance ? 1 : 0);
        this.columnsNumber += (this.canViewBillableAndCallRightFlag ? 2 : 0);
    }

    public async approveCurrentDayWorkedHours(): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(TextResources.WorkedHours.DayApprovalConfirmation, TextResources.WorkedHours.DayApprovalConfirmationCancel, TextResources.WorkedHours.DayApprovalConfirmationOk);

        if (!confirm)
            return;
        
        const hoursToApproveIds = this.Activities().filter((w) => {
            const state = w.getApprovalState();
            return HoursApprovalStateUtilities.isUnmanagedWorkedHoursRow(state) || state === HoursApprovalStateEnum.PartiallyApproved;
        }).map((w) => w.HoursId);

        const approvalGroups = this.props.resourceApprovalGroups().filter((g: IHoursApprovalGroup) => g.CanApprove);

        const hoursApproval: IHoursApproval_Type[] = hoursToApproveIds.selectMultiple(h => {
            const rows: IHoursApproval_Type[] = approvalGroups.map((g: IHoursApprovalGroup) => ({
                HoursId: h,
                ApprovalGroupId: g.GroupId,
                ApprovalNotes: null,
                ApprovalState: HoursApprovalStateEnum.Approved
            }));

            return rows;
        });

        try {
            await this.workedHoursService.SetApprovalState(hoursApproval, null, null);
        } catch(e) {
            console.log(e);
        }

        if (this.props.onWorkedHoursMassiveApproval)
            this.props.onWorkedHoursMassiveApproval();
    }

    public switchTimeZoneSelectorPopover(): void {
        this.ShowTimeZoneSelectorPopover(!this.ShowTimeZoneSelectorPopover());
    }

    public resetActivitiesListTimeZones(): void {
        if (this.resettingActivitiesListTimeZones)
            return;

        this.resettingActivitiesListTimeZones = true;

        this.SelectedTimeZone(undefined);

        const activities = this.Activities();
        const activitiesForMultipleInsert = this.ActivitiesForMultipleInsert();

        activities.forEach((a) => a.resetTimezones());
        activitiesForMultipleInsert.forEach((a) => a.resetTimezones());

        this.Activities(activities);
        this.ActivitiesForMultipleInsert(activitiesForMultipleInsert);

        this.Activities.valueHasMutated();
        this.ActivitiesForMultipleInsert.valueHasMutated();

        this.resettingActivitiesListTimeZones = false;
    }

    private createWorkedHoursRowModel(row: WorkedHoursRowForList): IDataSourceModel<number, WorkedHoursRowForList> {
        return {
            id: row.HoursId,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: row
        };
    }

    private emptyActivitiesListTemplate() {
        const forMultipleInsert = this.MultipleInsertEnabled();
        return (
            <>
                {!forMultipleInsert && (
                    <div className="text-center" style={{ padding: "15px 0px", whiteSpace: "normal" }}>
                        <span style={{ fontSize: "18px" }}>{TextResources.WorkedHours.EmptyActivitiesList}</span><br/>
                        <span style={{ fontSize: "14px" }}>{TextResources.WorkedHours.ActivitiesListHelp}</span>
                    </div>)
                }

                {forMultipleInsert && (
                    <div className="text-center" style={{ padding: "15px 0px", whiteSpace: "normal" }}>
                        <span style={{ fontSize: "18px" }}>{TextResources.WorkedHours.EmptyMultipleInsertActivitiesList}</span><br/>
				        <span style={{ fontSize: "14px" }}>{TextResources.WorkedHours.MultipleInsertActivitiesListHelp}</span>
                    </div>)
                }
            </>
        );
    }

    private renderTableForMultipleInsert(){
        const hours: ko.ObservableArray<IWorkedHoursRowForList> = this.ActivitiesForMultipleInsert;
        let workedHoursRow: IDataSourceModel<number, WorkedHoursRowForList>;
        return (
            (
                <Table
                compact={true}
                scrollable={true}
                fixedLayout={true}
                striped
                dataSource={{ array: hours, factory: this.createWorkedHoursRowModel.bind(this) }}
                rowAs="workedHoursRow"
                components={{ emptyTableTemplate: this.emptyActivitiesListTemplate.bind(this)}}
                >
                    <Column style={{ width: "2%" }} className="text-center">
                        <ColumnBody>
                            {(item: ITableItem<WorkedHoursRowForList>) => <HoursApprovalState state={item.Data.model.ApprovalState}  />}
                        </ColumnBody>
                    </Column>
                    <Column style={{ width: "15%", whiteSpace: "normal" }}>
                        {!this.props.insertPerJobOrder && <span data-bind={{ text: workedHoursRow.model.JobOrderName }}></span>}
                        {this.props.insertPerJobOrder && <span data-bind={{ text: workedHoursRow.model.ResourceName }}></span>}
                    </Column>
                    <Column style={{ width: "16%", whiteSpace: "normal" }}>
                        <div data-bind={{ text: workedHoursRow.model.TaskName, attr: { title: workedHoursRow.model.TaskName } }}></div>
                        <div>
                            <small className="text-muted" data-bind={{ text: workedHoursRow.model.WorkflowName, attr: { title: workedHoursRow.model.WorkflowName } }}></small>
                        </div>
                    </Column>
                    <Column style={{ width: "5%" }}>
                        <div className="text-center" title="Ore lavorate">
                            <span style={{ display: "block" }} data-bind={{ timeTextFromNumber: workedHoursRow.model.OrdinaryHours, timeTextShowSeconds: true }}></span>
                            <i className="fa fa-clock-o"></i>
                        </div>
                    </Column>
                    <Column style={{ width: "5%" }}>
                        <div className="text-center" title="Pausa">
                            <span style={{ display: "block" }} data-bind={{ timeTextFromNumber: workedHoursRow.model.BreakHours, timeTextShowSeconds: true }}></span>
                            <i className="fa fa-coffee"></i>
                        </div>
                    </Column>
                    <Column style={{ width: "12%", whiteSpace: "nowrap" }}>
                        <div title={TextResources.WorkedHours.StartDateTime}>
                            <i className="fa fa-flash"></i><small><span style={{ marginLeft: "14px" }} data-bind={{ text: workedHoursRow.model.WorkDateText }}></span></small>
                        </div>
                        <div title={TextResources.WorkedHours.EndDateTime}>
                            <i className="fa fa-flag-checkered"></i><small><span style={{ marginLeft: "7px" }} data-bind={{ text: workedHoursRow.model.EndTimeText }}></span></small>
                        </div>
                    </Column>
                    {this.canViewRole &&    <Column style={{ width: "8%", whiteSpace: "normal" }}>
                                                <span data-bind={{ text: workedHoursRow.model.RoleName }}></span>
                                            </Column>
                    }
                    {this.canViewWorkTimeCategory &&    <Column style={{ width: "8%", whiteSpace: "normal" }}>
                                                            <span data-bind={{ text: workedHoursRow.model.WorkTimeCategoryName }}></span>
                                                        </Column>
                    }
                    {this.canViewBillableAndCallRightFlag &&    <Column style={{ width: "2%" }} className="text-center">
                                                                    <i title="Fatturabile" className="fa" data-bind={{ css: { 'fa-eur': workedHoursRow.model.Billable } }}></i>
                                                                </Column>
                    }
                    {this.canViewBillableAndCallRightFlag &&    <Column style={{ width: "2%" }} className="text-center">
                                                                    <i title="Diritto di Chiamata" className="fa" data-bind={{ css: { 'fa-bullhorn': workedHoursRow.model.CallRight } }}></i>
                                                                </Column>
                    }
                    {this.canViewWorkPlace &&   <Column style={{ width: "5%", whiteSpace: "normal" }}>
                                                    <ColumnBody>
                                                        {(item: ITableItem<WorkedHoursRowForList>) => <WorkPlace workPlaceId={item.Data.model.WorkPlaceId()} />}
                                                    </ColumnBody>
                                                </Column>
                    }
                    {this.canViewTravelDistance &&  <Column  style={{ width: "5%", whiteSpace: "normal" }}>
                                                        <span data-bind={{ numberText: workedHoursRow.model.TravelDistance, numberTextSuffix: 'Km' }}></span>
                                                    </Column>
                    }
                    <SecondaryRow visible={() => "!!workedHoursRow.model.Description()"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => (
                            <>
                                <td colSpan={this.columnsNumber} style={{ borderTop: "0px" }}>
                                    <span style={{ display: "block", marginBottom: "5px", borderBottom: "1px solid lightskyblue" }}>{TextResources.WorkedHours.Notes}</span>
                                    <span data-bind={{ text: workedHoursRow.model.Description }}></span>
                                </td>
                                <td></td>
                            </>
                        )}
                    </SecondaryRow>
                    <SecondaryRow visible={() => "!!workedHoursRow.model.ApprovalRowsWithOutcome().length > 0"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => (
                            <>
                                <td colSpan={this.columnsNumber} style={{ paddingBottom: "5px" }}>
                                    <div style={{ border: "1px dashed red" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span style={{ display: "block", marginBottom: "5px", borderBottom: "1px solid lightcoral" }}>{TextResources.WorkedHours.ApprovalState}</span>
                                            </div>
                                        </div>
                                        <ApprovalStateTable approvalRows={item.Data.model.ApprovalRowsWithOutcome} />
                                    </div>
                                </td>
                                <td></td>
                            </>
                        )}
                    </SecondaryRow>
                    <SecondaryRow visible={() => "!!workedHoursRow.model.workedHours.ApprovalNote || !!workedHoursRow.model.workedHours.Description"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => <td colSpan={this.columnsNumber} style={{ height: "5px", borderTop: "0px" }}></td>}
                    </SecondaryRow>
                </Table>
            )
        )
    }

    private renderTableForSingleInsert(){
        const hours: ko.ObservableArray<IWorkedHoursRowForList> = this.Activities;
        let workedHoursRow: IDataSourceModel<number, WorkedHoursRowForList>;
        return (
            <Table
                compact={true}
                scrollable={true}
                fixedLayout={true}
                striped
                dataSource={{ array: hours, factory: this.createWorkedHoursRowModel.bind(this) }}
                rowAs="workedHoursRow"
                components={{ emptyTableTemplate: this.emptyActivitiesListTemplate.bind(this)}}
                >
                    
                    <Column style={{ width: "3%" }} className="text-center">
                        <ColumnBody>
                            {(item: ITableItem<WorkedHoursRowForList>) => 
                            <>
                            <div className="with-identifier full-height" >
                                <div className="identifier" style={{backgroundColor: "#3a9893"}} data-bind={{visible: workedHoursRow.model.IsFlexibility}}></div>
                                <i className="fa " style={{margin: "5px"}} data-bind={{visible: workedHoursRow.model.IsFlexibility, css: {"fa-toggle-up font-green": workedHoursRow.model.OrdinaryHours() >= 0, "fa-toggle-down font-red": workedHoursRow.model.OrdinaryHours() < 0}}}></i>

                                <HoursApprovalState state={item.Data.model.ApprovalState}  />
                            </div>
                            
                            </>}
                        </ColumnBody>
                    </Column>
                    <Column style={{ width: "15%", whiteSpace: "normal" }}>
                        {!this.props.insertPerJobOrder && <span data-bind={{ text: workedHoursRow.model.JobOrderName }}></span>}
                        {this.props.insertPerJobOrder && <span data-bind={{ text: workedHoursRow.model.ResourceName }}></span>}
                    </Column>
                    <Column style={{ width: "16%", whiteSpace: "normal" }}>
                        <div data-bind={{ text: workedHoursRow.model.TaskName, attr: { title: workedHoursRow.model.TaskName } }}></div>
                        <div>
                            <small className="text-muted" data-bind={{ text: workedHoursRow.model.WorkflowName, attr: { title: workedHoursRow.model.WorkflowName } }}></small>
                        </div>
                    </Column>
                    <Column style={{ width: "5%" }}>
                        <div className="text-center" title="Ore lavorate">
                            <span style={{ display: "block" }} data-bind={{ timeTextFromNumber: workedHoursRow.model.OrdinaryHours, timeTextAlwaysPositive: true, timeTextShowSeconds: true }}></span>
                            <i className="fa fa-clock-o"></i>
                        </div>
                    </Column>
                    <Column style={{ width: "5%" }}>
                        <div className="text-center" title="Pausa">
                            <span style={{ display: "block" }} data-bind={{ timeTextFromNumber: workedHoursRow.model.BreakHours, timeTextShowSeconds: true }}></span>
                            <i className="fa fa-coffee"></i>
                        </div>
                    </Column>
                    <Column style={{ width: "12%", whiteSpace: "nowrap" }}>
                        <div title={TextResources.WorkedHours.StartDateTime}>
                            <i className="fa fa-flash"></i><small><span style={{ marginLeft: "14px" }} data-bind={{ text: workedHoursRow.model.WorkDateText }}></span></small>
                        </div>
                        <div title={TextResources.WorkedHours.EndDateTime}>
                            <i className="fa fa-flag-checkered"></i><small><span style={{ marginLeft: "7px" }} data-bind={{ text: workedHoursRow.model.EndTimeText }}></span></small>
                        </div>
                    </Column>
                    {this.canViewRole &&    <Column style={{ width: "8%", whiteSpace: "normal" }}>
                                                <span data-bind={{ text: workedHoursRow.model.RoleName }}></span>
                                            </Column>
                    }
                    {this.canViewWorkTimeCategory &&    <Column style={{ width: "8%", whiteSpace: "normal" }}>
                                                            <span data-bind={{ text: workedHoursRow.model.WorkTimeCategoryName }}></span>
                                                        </Column>
                    }
                    {this.canViewBillableAndCallRightFlag &&    <Column style={{ width: "2%" }} className="text-center">
                                                                    <i title="Fatturabile" className="fa" data-bind={{ css: { 'fa-eur': workedHoursRow.model.Billable } }}></i>
                                                                </Column>
                    }
                    {this.canViewBillableAndCallRightFlag &&    <Column style={{ width: "2%" }} className="text-center">
                                                                    <i title="Diritto di Chiamata" className="fa" data-bind={{ css: { 'fa-bullhorn': workedHoursRow.model.CallRight } }}></i>
                                                                </Column>
                    }
                    {this.canViewWorkPlace &&   <Column style={{ width: "5%", whiteSpace: "normal" }}>
                                                    <ColumnBody>
                                                        {(item: ITableItem<WorkedHoursRowForList>) => <WorkPlace workPlaceId={item.Data.model.WorkPlaceId()} />}
                                                    </ColumnBody>
                                                </Column>
                    }
                    {this.canViewTravelDistance &&  <Column  style={{ width: "5%", whiteSpace: "normal" }}>
                                                        <span data-bind={{ numberText: workedHoursRow.model.TravelDistance, numberTextSuffix: 'Km' }}></span>
                                                    </Column>
                    }
                    <Column style={{ width: "5%", paddingTop: "3px", paddingBottom: "3px" }} className="text-right" >
                        <div style={{ marginBottom: "3px" }}>
                            <button type="button" className="btn btn-xs" style={{ marginRight: "0px" }} data-bind={{ click: workedHoursRow.model.edit.bind(workedHoursRow.model), enable: workedHoursRow.model.CanEdit, css: { 'btn-default': !workedHoursRow.model.CanEdit(), 'btn-primary': workedHoursRow.model.CanEdit } }} title={TextResources.WorkedHours.EditWorkedHours}>
                                <i className="fa fa-edit"></i>
                            </button>&nbsp;
                            <button type="button" className="btn btn-xs" style={{ marginRight: "0px" }} data-bind={{ click: workedHoursRow.model.Delete.bind(workedHoursRow.model), enable: workedHoursRow.model.CanEdit, css: { 'btn-default': !workedHoursRow.model.CanEdit(), 'btn-danger': workedHoursRow.model.CanEdit } }} title={TextResources.WorkedHours.DeleteWorkedHours}>
                                <i className="fa fa-trash-o"></i>
                            </button>
                        </div>
                        <button type="button" className="btn btn-primary btn-xs" style={{ marginRight: "0px", width: "47px", }} data-bind={{ click: workedHoursRow.model.approve.bind(workedHoursRow.model) }} title={TextResources.WorkedHours.ShowApprovalModal}>
                            <i className="fa fa-legal"></i>
                        </button>
                    </Column>
                    <SecondaryRow visible={() => "!!workedHoursRow.model.Description()"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => (
                            <>
                                <td colSpan={this.columnsNumber} style={{ borderTop: "0px" }}>
                                    <span style={{ display: "block", marginBottom: "5px", borderBottom: "1px solid lightskyblue" }}>{TextResources.WorkedHours.Notes}</span>
                                    <span data-bind={{ text: workedHoursRow.model.Description }}></span>
                                </td>
                                <td></td>
                            </>
                        )}
                    </SecondaryRow>
                    <SecondaryRow visible={() => "!!workedHoursRow.model.ApprovalRowsWithOutcome().length > 0"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => (
                            <>
                                <td colSpan={this.columnsNumber} style={{ paddingBottom: "5px" }}>
                                    <div style={{ border: "1px dashed red" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span style={{ display: "block", marginBottom: "5px", borderBottom: "1px solid lightcoral" }}>{TextResources.WorkedHours.ApprovalState}</span>
                                            </div>
                                        </div>
                                        <ApprovalStateTable approvalRows={item.Data.model.ApprovalRowsWithOutcome} />
                                    </div>
                                </td>
                                <td></td>
                            </>
                        )}
                    </SecondaryRow>
                    <SecondaryRow visible={() => "!!workedHoursRow.model.workedHours.ApprovalNote || !!workedHoursRow.model.workedHours.Description"}>
                        {(item: ITableItem<WorkedHoursRowForList>) => <td colSpan={this.columnsNumber} style={{ height: "5px", borderTop: "0px" }}></td>}
                    </SecondaryRow>
                </Table>
        )
    }

    private renderList() {
        
        
        return (
            <>
                <If condition={this.MultipleInsertEnabled}>
                    {() => (
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ fontSize: "20px" }}>
                                    <i className="fa fa-arrow-left" style={{ fontSize: "20px" }}></i>&nbsp;
                                    <span>{TextResources.WorkedHours.SelectDays}</span>
                                </div>
                            </div>
                            {this.renderTableForMultipleInsert()}
                        </>
                    )}
                </If>
                <IfNot condition={this.MultipleInsertEnabled} >
                    {() => this.renderTableForSingleInsert()}
                </IfNot>
            </>
        );
    }

    public render() {
        let list: WorkedHoursEditorList;

        return ComponentUtils.bindTo((
            <LayoutWithHeader noOverflow={true}>
                <LayoutHeader className="flex-vertical day-workedhours">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <h3 className="day-workedhours-title" data-bind={{ text: list.Title }}></h3>
                                </div>
                                <div className="col-md-6" style={{ paddingTop: "7px" }}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div style={{ position: "relative", width: "130px" }}>
                                                <label style={{ lineHeight: "34px", marginRight: "10px" }}>{TextResources.WorkedHours.Timezone}</label>
                                                <button type="button" className="btn btn-primary" data-bind={{ click: list.switchTimeZoneSelectorPopover }}>
                                                    <i className="fa fa-globe"></i>
                                                </button>

                                                <div className="popover left inline-block" style={{ right: "55px", top: "0px", width: "400px", left: "auto", display: "none" }} data-bind={{ visible: list.ShowTimeZoneSelectorPopover }}>
                                                    <div className="arrow" style={{ top: "16px" }}></div>
                                                    <h3 className="popover-title">{TextResources.WorkedHours.SelectTimezone}</h3>
                                                    <div className="popover-content">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                {TextResources.WorkedHours.SelectTimezoneHelp}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <timezone-picker style={{ position: "relative", top: "9px" }} params="TimeZone: SelectedTimeZone, Nullable: true, Placeholder: 'Seleziona una timezone...'"></timezone-picker>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 text-right">
                                                                <button className="btn btn-default btn-xs" data-bind={{ click: list.resetActivitiesListTimeZones.bind(list) }}>{TextResources.WorkedHours.Reset}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="alert alert-warning" style={{ padding: "8px", margin: "0px" }} data-bind={{ visible: list.ListShowedWithDifferentTimezone }}>
                                                <i className="fa fa-exclamation-triangle"></i> {TextResources.WorkedHours.TimezoneAlert}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-right" style={{ paddingTop: "8px" }}>
                                    <If condition={this.props.canApproveHours}>
                                        {() => (
                                            <button type="button" className="btn btn-primary" data-bind={{ click: list.approveCurrentDayWorkedHours.bind(list) }}>
                                                <i className="fa fa-legal"></i>
                                                {TextResources.WorkedHours.ApproveAllHoursOfTheDay}
                                            </button>
                                        )}
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutHeader>
                <LayoutContent noOverflow={true}>
                    {this.renderList()}
                </LayoutContent>
            </LayoutWithHeader>
            ), this, "list");
    }
}