import { BaseDataSource } from "../../DataSources/BaseDataSource";
import { IHumanResource } from "../../Users/HumanResourcesService";
import { IDataSourceModel } from "../../DataSources/IDataSource";
import { ResourcesDataSource } from "../../DataSources/ResourcesDataSource";
import { Right } from "../../Core/Authorizations";

export type ComputedResourcesDataSourceModel = IDataSourceModel<number, IHumanResource>;
export type ActiveResourcesByDate = {Id: number, Date: Date, Resources: IHumanResource[]};

export class ComputedResourcesDataSource extends BaseDataSource<ComputedResourcesDataSourceModel> {
    private date: Date;
    private eventId: number;
    private resourcesDataSource: ResourcesDataSource = new ResourcesDataSource();
    private forDeletion: boolean;
    private userId: number;

    constructor(private activeResourcesByDate: ko.Computed<ActiveResourcesByDate[]>){
        super();
    }

    getTitle(currentModel: ComputedResourcesDataSourceModel): string {
         return "Active Resources By Date"
    }

    setFilter(date: Date, id: number, forDeletion: boolean, userId: number = null){
        this.date = date;
        this.eventId = id;
        this.forDeletion = forDeletion;
        this.userId = userId;
    }

    async getData(currentModel: ComputedResourcesDataSourceModel, textFilter: string, skip: number = 0, count: number = 5000): Promise<ComputedResourcesDataSourceModel[]> {
        if (this.forDeletion) {
            let activeResourcesByDate = this.activeResourcesByDate().filter(ar => ar.Date <= this.date && this.eventId != ar.Id).orderByDesc(ar => ar.Date.toJSON() + ("000000000000" + ar.Id).slice(-12)).firstOrDefault();
            return activeResourcesByDate.Resources.filter(x => this.matchTextFilter(x, textFilter) && (this.userId == null || x.Resource.FkUser == this.userId)).slice(skip, skip + count).map(x => ({
                id: x.Resource.Id,
                isGroup: false,
                isLeaf: true,
                title: x.Resource.Name + (x.Resource.Surname ? ' ' + x.Resource.Surname : ''),
                isHumanResource: x.Resource.ResourceType == 0,
                isMaterialResource: x.Resource.ResourceType == 1,
		    	model: x
            }));
        }
        else {
            return this.resourcesDataSource.getData(null, textFilter, skip, count);
        }
    }

    getById(currentModel: ComputedResourcesDataSourceModel, ids: (string | number)[]): Promise<ComputedResourcesDataSourceModel[]> {
        return this.resourcesDataSource.getById(null, ids);
    }

    private matchTextFilter(item: IHumanResource, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if ((item.Resource.Name + (item.Resource.Surname ? ' ' + item.Resource.Surname : '')).toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}