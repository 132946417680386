import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CallRightTypesEditingViewModel } from "./ui/CallRightTypesEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICallRightTypesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class CallRightTypesSettingsManager implements ICallRightTypesSettingsManager
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    private types : ICallRightType[];

    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.WorkedHours.ResourcesJobTypes);
    }

    load(): Promise<ICallRightType[]> {
        return this.ajaxService.Get<ICallRightType[]>("WorkedHours-api", "CallRightTypes", {})
            .then((types: ICallRightType[]) => this.types = types);
    }

    getName(): string {
        return ProlifeSdk.CallRightTypesSettingsManagerType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.WorkedHours.CallRightTypes;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) &&
            !this.modulesService.IsModuleEnabled(ProlifeSdk.ResourcesManagerApplicationCode);
    }

    getEditingUI(): IView {
        return {
            templateName: 'call-right-types',
            templateUrl: 'workedhours/templates/settings',
            viewModel: new CallRightTypesEditingViewModel(this)
        };
    }

    public getAll(includeDeleted : boolean, resourceType : number = -1): ICallRightType[]
    {
        var types : ICallRightType[] = this.types;

        if(resourceType > -1)
            types = types.filter((c : ICallRightType) => {
                return c.ResourceType == resourceType;
            });

        if(!includeDeleted)
            types = types.filter((c : ICallRightType) => {
                return c.Deleted == false;
            });

        return types;
    }

    public getById(id : number) : ICallRightType
    {
        var matches : ICallRightType[] = this.types.filter((i : ICallRightType) => {
            return i.Id == id;
        });
        return matches.length > 0 ? matches[0] : null;
    }

    public delete(type: ICallRightType): Promise<void>
    {
        return this.ajaxService.Delete<void>("WorkedHours-api", "CallRightTypes", { methodData: type })
            .then(this.onTypeDeleted.bind(this, type));
    }

    public getDefault(resourceType : number = -1) : ICallRightType
    {
        var types : ICallRightType[] = this.types.filter((t : ICallRightType) => { return !t.Deleted; });

        if(resourceType > -1)
            types = types.filter((c : ICallRightType) => { return c.ResourceType == resourceType; });

        return types.length > 0 ? types[0] : null;
    }

    public createOrUpdate(type: ICallRightType): Promise<ICallRightType>
    {
        return this.ajaxService.Post<ICallRightType>("WorkedHours-api", "CallRightTypes", { methodData: type })
            .then(this.onTypeAddedOrUpdated.bind(this));
    }

    private onTypeAddedOrUpdated(type : ICallRightType)
    {
        var matches = this.types.filter((t : ICallRightType) => {
            return t.Id == type.Id;
        });

        if(matches.length == 0)
            this.types.push(type);
        else
            this.types[this.types.indexOf(matches[0])] = type;
    }

    private onTypeDeleted(type : ICallRightType)
    {
        var matches = this.types.filter((t : ICallRightType) => {
            return t.Id == type.Id;
        });

        if(matches.length == 0)
            return;
        let index = this.types.indexOf(matches[0]);

        this.types.splice(index, 1);
    }
}
