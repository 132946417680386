import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { CustomStringProvider } from "./Blog/EntityProviders/CustomStringProvider";
import { CustomDateTimeProvider } from "./Blog/EntityProviders/CustomDateTimeProvider";
import { CustomIntProvider } from "./Blog/EntityProviders/CustomIntProvider";
import { CustomBoolProvider } from "./Blog/EntityProviders/CustomBoolProvider";
import { CustomDecimalProvider } from "./Blog/EntityProviders/CustomDecimalProvider";
import { CallBlogEvent } from "./Blog/EventViewModels/CallBlogEvent";
import { MailBlogEvent } from "./Blog/EventViewModels/MailBlogEvent";
import { CommentBlogEvent } from "../ProlifeSdk/prolifesdk/blog/CommentBlogEvent";
import { MeetingBlogEvent } from "./Blog/EventViewModels/MeetingBlogEvent";
import { MilestoneBlogEvent } from "./Blog/EventViewModels/MilestoneBlogEvent";
import { SystemBlogEvent } from "./Blog/EventViewModels/SystemBlogEvent";
import { WorkEvent } from "./Blog/EventViewModels/WorkEvent";
import { HoursRecordEvent } from "./Blog/EventViewModels/HoursRecordEvent";
import { MaterialsEvent } from "./Blog/EventViewModels/MaterialsEvent";
import { WorkedHoursFinancialDataProvider } from "./Blog/FinancialDataProviders/WorkedHoursFinancialDataProvider";
import { PurchasesFinancialDataProvider } from "./Blog/FinancialDataProviders/PurchasesFinancialDataProvider";
import { BlogApplication } from "./Blog/BlogApplication";
import { WorkedHoursRefDetailsViewModelFactory } from "./Blog/providers/refs-details-factories/WorkedHoursRefDetailsViewModelFactory";
import { PurchaseRefDetailsViewModelFactory } from "./Blog/providers/refs-details-factories/PurchaseRefDetailsViewModelFactory";
import { BlogEventRefUiForRemind } from "./Blog/ui/reminds-refs/BlogEventRefUiForRemind";
import { BlogEventTypesDropDownList } from "./Blog/ui/dropdownlist/BlogEventTypesDropDownList";
import { BlogEventsDropDownList } from "./Blog/ui/dropdownlist/BlogEventsDropDownList";
import { GenericEventConfigurationsUI } from "./Blog/settings/GenericEventConfigurationsUI";
import { BlogReportsProvider } from "./Blog/Reports/BlogReportsProvider";
import { LazyImport } from "../Core/DependencyInjection";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IReminderService, IRemindReferenceObjFieldType } from "../ProlifeSdk/interfaces/reminder/IReminderService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IBlogService, IBlogEventForList } from "../ProlifeSdk/interfaces/blog/IBlogService";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IFiltersEngine } from "../Core/interfaces/IFiltersEngine";
import { ISettingsServiceObserver } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { ILogService } from "../ProlifeSdk/interfaces/desktop/ILogService";
import { IReportsService } from "../ProlifeSdk/interfaces/report/IReportsService";
import { ISearchContext } from "../Core/interfaces/ISearchContext";
import { IDropDownList } from "../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { ILogFilter } from "../ProlifeSdk/interfaces/ILogFilter";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { ISearchResult } from "../Core/interfaces/ISearchResult";
import { IWordFound } from "../Core/interfaces/IWordFound";
import { IGenericEventConfigurationsUi } from "../ProlifeSdk/interfaces/blog/IGenericEventConfigurationsUi";
import { Deferred } from "../Core/Deferred";

interface BlogViewModels {
    [type: string]: new (
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver
    ) => BlogEventBase;
}

export class BlogService extends ProLifeService implements IBlogService, ISettingsServiceObserver {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<ILogService>())
    private logService: ILogService;
    @LazyImport(nameof<IFiltersEngine>())
    private filtersEngine: IFiltersEngine;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService: IEntityProviderService;
    @LazyImport(nameof<IReportsService>())
    private reportsService: IReportsService;
    @LazyImport(nameof<IReminderService>())
    private remindService: IReminderService;

    private searchContext: ISearchContext;
    private contextEventsObserver: IContextEventsObserver;

    //public ui : IBlogServiceUIProvider;
    public application: BlogApplication;

    private viewModels: BlogViewModels = {};
    public workedHoursProvider: WorkedHoursFinancialDataProvider;
    public purchasesProvider: PurchasesFinancialDataProvider;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.BlogApplicationCode);

        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IBlogService>(), this);
    }

    InitializeService() {
        super.InitializeService();

        this.searchContext = this.filtersEngine.CreateContext();
        this.searchContext.addHtmlStringFilter("Text", ProlifeSdk.TextResources.Blog.Message);
        this.searchContext.addArrayFilter("Tags", "CustomTag", "TagName", "CustomTag", "Value");
        this.searchContext.addArrayFilter("Tags", "CustomTag", "TagTypeId", "string", "Value");

        this.entityProviderService.registerEntityProvider(new CustomStringProvider(), ProlifeSdk.TagType_String);
        this.entityProviderService.registerEntityProvider(new CustomDateTimeProvider(), ProlifeSdk.TagType_DateTime);
        this.entityProviderService.registerEntityProvider(new CustomIntProvider(), ProlifeSdk.TagType_Int);
        this.entityProviderService.registerEntityProvider(new CustomBoolProvider(), ProlifeSdk.TagType_Bool);
        this.entityProviderService.registerEntityProvider(new CustomDecimalProvider(), ProlifeSdk.TagType_Decimal);

        this.viewModels[ProlifeSdk.BlogEventType_PhoneCall] = CallBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Mail] = MailBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Comment] = CommentBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Meeting] = MeetingBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Milestone] = MilestoneBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_System] = SystemBlogEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Work] = WorkEvent;
        this.viewModels[ProlifeSdk.BlogEventType_WorkedHours] = HoursRecordEvent;
        this.viewModels[ProlifeSdk.BlogEventType_Materials] = MaterialsEvent;

        this.entityProviderService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.BlogEventEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Blog.Event,
            PluralEntityName: ProlifeSdk.TextResources.Blog.Events,
            EntityIsMale: false,
            CanBeRelatedToRemind: true,
        });

        this.workedHoursProvider = new WorkedHoursFinancialDataProvider(this.serviceLocator);
        this.purchasesProvider = new PurchasesFinancialDataProvider(this.serviceLocator);

        //this.ui = new BlogServiceUIProvider(serviceLocator);
        this.application = new BlogApplication(this.serviceLocator);

        this.entityProviderService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.WorkedHoursEntityTypeCode,
            new WorkedHoursRefDetailsViewModelFactory(this.serviceLocator)
        );

        this.entityProviderService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.PurchasesEntityTypeCode,
            new PurchaseRefDetailsViewModelFactory()
        );

        new BlogEventRefUiForRemind(this.serviceLocator);

        var fields: IRemindReferenceObjFieldType[] = [];
        fields.push({
            Id: 1,
            Description: ProlifeSdk.TextResources.Blog.EntityFieldNameEventTitle,
            IsForTitle: true,
            IsForExpireDate: false,
        });
        fields.push({
            Id: 2,
            Description: ProlifeSdk.TextResources.Blog.EntityFieldNameEventDate,
            IsForTitle: false,
            IsForExpireDate: true,
        });
        this.remindService.registerObjReferenceFields(ProlifeSdk.BlogEventEntityTypeCode, fields);
    }

    getBlogEventTypesDropDown(): IDropDownList {
        return new BlogEventTypesDropDownList(this.serviceLocator);
    }

    getBlogEventsDropDown(jobOrderIdGetter: () => number, eventTypeGetter: () => string): IDropDownList {
        return new BlogEventsDropDownList(this.serviceLocator, jobOrderIdGetter, eventTypeGetter);
    }

    setContextEventsObserver(contextEventsObserver: IContextEventsObserver) {
        this.contextEventsObserver = contextEventsObserver;
    }

    getServiceType(): string {
        return ProlifeSdk.BlogServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    searchEventsForList(jobOrderId: number, eventType: string, textFilter: string): Promise<IBlogEventForList[]> {
        return this.ajaxService.Post("Blog-api/EventsForList", "Search", {
            methodData: {
                TextFilter: textFilter,
                JobOrderId: jobOrderId,
                EventType: eventType,
            },
        });
    }

    getEventForList(id: number): Promise<IBlogEventForList> {
        return this.ajaxService.Get("Blog-api/EventsForList", "GetEvent?id=" + id, {});
    }

    GetJobOrdersForEventsLinkedToTask(taskId: number): Promise<number[]> {
        return this.ajaxService.Post("Blog-api/Task", "GetJobOrdersForEventsLinkedToTask", {
            methodData: {
                TaskId: taskId,
            },
        });
    }

    detailedSearch(logFilters: ILogFilter[], skip: number, count: number): Promise<IView[]> {
        let def = new Deferred<IView[]>();

        this.logService
            .detailedSearch(logFilters, skip, count)
            .then((events: ILogEvent[]) => {
                def.resolve((events || []).map((e: ILogEvent) => this.getEventUI(e)));
            })
            .catch(() => {
                def.reject();
                console.log("i should be never called");
            });

        return def.promise();
    }

    detailedSearchOnJobOrders(
        logFilters: ILogFilter[],
        skip: number,
        count: number,
        jobOrdersIds: number[]
    ): Promise<IView[]> {
        let def = new Deferred<IView[]>();

        this.logService
            .detailedSearchOnJobOrders(logFilters, skip, count, jobOrdersIds)
            .then((events: ILogEvent[]) => {
                def.resolve((events || []).map((e: ILogEvent) => this.getEventUI(e)));
            })
            .catch(() => {
                def.reject();
                console.log("i should be never called");
            });

        return def.promise();
    }

    hintSearch(filter: string): Promise<string[]> {
        let def = new Deferred<string[]>();

        this.logService
            .hintSearch(filter)
            .then((events: ILogEvent[]) => {
                // return (events || []).map((e: IBlogEvent) => this.getEventUI(e));

                this.searchContext.items = events;
                var result: ISearchResult = this.filtersEngine.Search(this.searchContext, filter);
                def.resolve(result.wordsFound.map((word: IWordFound) => word.word));
            })
            .catch(() => {
                //console.log('i should be never called');
                def.reject();
            });

        return def.promise();
    }

    getEventUI(event: ILogEvent): IView {
        var service: IEventUiProvider = <IEventUiProvider>this.serviceLocator.findService(event.ModuleName);
        return service.getUIForEvent(event, this.contextEventsObserver);
    }

    getUIForEvent(item: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        var type = this.viewModels[item.EventType] || BlogEventBase;

        var event: BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView>event;
    }

    openBlog(jobOrderId: number) {
        location.href = "/#/WorkBook/" + jobOrderId;
    }

    public GetBlogUrl(): string {
        return "/#/WorkBook";
    }

    public GetUrlForTasksFilter(jobOrdersIds: number[], tasksIds: number[]) {
        var jobOrders: string = "";
        var tasks: string = "";
        jobOrdersIds.forEach((id: number) => {
            jobOrders += id.toString() + "_";
        });
        tasksIds.forEach((id: number) => {
            tasks += id.toString() + "_";
        });

        jobOrders = jobOrders.substring(0, jobOrders.length - 1);
        tasks = tasks.substring(0, tasks.length - 1);

        return "/#/WorkBook/JobOrders/" + jobOrders + "/Tasks/" + tasks;
    }

    openBlogInNewWindow(jobOrderId: number, taskId: number = null) {
        if (!taskId) {
            window.open("/#/WorkBook/" + jobOrderId, "_blank");
            return;
        }

        let url = this.GetUrlForTasksFilter([jobOrderId], [taskId]);
        window.open(url, "_blank");
    }

    public GetGenericEventConfigurationsUI(): IGenericEventConfigurationsUi {
        return new GenericEventConfigurationsUI(this.serviceLocator);
    }

    onSettingsLoaded(): void {
        super.onSettingsLoaded();

        this.reportsService.registerReportsProvider(new BlogReportsProvider(this.serviceLocator));
    }

    onSettingsUpdated(updateType: string): void {
        super.onSettingsUpdated(updateType);
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new BlogService(serviceLocator);
}
