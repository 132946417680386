import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import {
    IJobOrderService,
    IJobOrderOrdersDetails,
    IJobOrderWorkflowsDetails,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { WorkflowStatus } from "./WorkflowStatusEnum";
import { SplashPageWorkflowsDetails } from "./SplashPageWorkflowsDetailsComponent";
import { DocumentClosingStatusIndicator } from "../../../../Components/DocumentClosingStatusIndicator";

export interface ISplashPageOrderDetailsParams {
    jobOrderId: number;
}

export class SplashPageOrderDetails {
    JobOrderId: number;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    Orders: ko.ObservableArray<JobOrderOrderDetail> = ko.observableArray([]);
    Loading: ko.Observable<boolean> = ko.observable();

    constructor(private props: ISplashPageOrderDetailsParams) {
        this.JobOrderId = this.props.jobOrderId ?? -1;
    }

    componentDidMount() {
        console.log("Orders ComponentDidMount");

        this.load();
    }

    public render() {
        let orderDetail: IDataSourceModel<string, JobOrderOrderDetail>;

        let { sortString, sortNumber } = ComponentUtils.useSorter<JobOrderOrderDetail>();

        return ComponentUtils.bindTo(
            <Table
                className={classes.ordersDetails}
                scrollable={true}
                bordered={true}
                compact={true}
                showLoadingIndicator={this.Loading}
                dataSource={{ array: this.Orders, factory: this.createJobOrderDetailModel.bind(this) }}
                rowAs="orderDetail">
                <Column title={TextResources.JobOrder.OrderDetailsOrder} sorter={sortString((r) => r.NumberAndDate())}>
                    <ColumnBody>
                        {(item: ITableItem<JobOrderOrderDetail>) => (
                            <div className="flex-container" style={{ alignItems: "center" }}>
                                <div className="flex-fill">
                                    <DocumentClosingStatusIndicator
                                        documentId={item.Data.model.DocumentId}
                                        documentType={ProlifeSdk.CustomerOrderEntityTypeCode}
                                        protocolId={item.Data.model.RegisterId()}
                                        documentCauseLogicType={null}
                                        documentClosingState={item.Data.model.State()}
                                        documentState={item.Data.model.FKEstimateState()}
                                        className="btn-xs"
                                        content={<i className="fa fa-file-text"></i>}
                                    />
                                    <span data-bind={{ text: orderDetail.model.NumberAndDate }}></span>
                                </div>
                                <button
                                    className="btn btn-primary btn-xs pull-right"
                                    data-bind={{ click: orderDetail.model.Open.bind(orderDetail.model) }}>
                                    <i className="fa fa-reply"></i>
                                </button>
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsRegister}
                    sorter={sortString((r) => r.RegisterName())}>
                    <span data-bind={{ text: orderDetail.model.RegisterName }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsReferences}
                    sorter={sortString((r) => r.ReferenceNumber())}>
                    <span data-bind={{ text: orderDetail.model.ReferenceNumber }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsTotal}
                    sorter={sortNumber((r) => r.OrderTotal())}
                    className="text-right">
                    <span data-bind={{ moneyText: orderDetail.model.OrderTotal }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsWaiting}
                    tooltip={TextResources.JobOrder.OrderDetailsWaitingTitle}
                    sorter={sortNumber((r) => r.Waiting())}
                    className={classes.workflowInfoSection + " text-right"}>
                    <div className="flex-container">
                        <div className="flex-fill ">
                            <span data-bind={{ percentageText: orderDetail.model.Waiting }}></span>&nbsp;(
                            <span
                                data-bind={{
                                    numberText: orderDetail.model.TodoWorkflows,
                                    format: "0",
                                    textIfNoValue: "0",
                                }}></span>
                            )
                        </div>
                        <button
                            className="btn btn-primary btn-xs"
                            data-bind={{ toggle: orderDetail.model.ShowInWaitWorkflows }}>
                            <i
                                className="fa"
                                data-bind={{
                                    css: {
                                        "fa-chevron-down": !orderDetail.model.ShowInWaitWorkflows(),
                                        "fa-chevron-up": orderDetail.model.ShowInWaitWorkflows,
                                    },
                                }}></i>
                        </button>
                    </div>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsWorking}
                    tooltip={TextResources.JobOrder.OrderDetailsWorkingTitle}
                    sorter={sortNumber((r) => r.Working())}
                    className={classes.workflowInfoSection + " text-right"}>
                    <div className="flex-container">
                        <div className="flex-fill">
                            <span data-bind={{ percentageText: orderDetail.model.Working }}></span>&nbsp;(
                            <span
                                data-bind={{
                                    numberText: orderDetail.model.InProgressWorkflows,
                                    format: "0",
                                    textIfNoValue: "0",
                                }}></span>
                            )
                        </div>
                        <button
                            className="btn btn-primary btn-xs"
                            data-bind={{ toggle: orderDetail.model.ShowInProgressWorkflows }}>
                            <i
                                className="fa"
                                data-bind={{
                                    css: {
                                        "fa-chevron-down": !orderDetail.model.ShowInProgressWorkflows(),
                                        "fa-chevron-up": orderDetail.model.ShowInProgressWorkflows,
                                    },
                                }}></i>
                        </button>
                    </div>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsCompleted}
                    tooltip={TextResources.JobOrder.OrderDetailsCompletedTitle}
                    sorter={sortNumber((r) => r.Completed())}
                    className={classes.workflowInfoSection + " text-right"}>
                    <div className="flex-container">
                        <div className="flex-fill">
                            <span data-bind={{ percentageText: orderDetail.model.Completed }}></span>&nbsp;(
                            <span
                                data-bind={{
                                    numberText: orderDetail.model.DoneWorkflows,
                                    format: "0",
                                    textIfNoValue: "0",
                                }}></span>
                            )
                        </div>
                        <button
                            className="btn btn-primary btn-xs"
                            data-bind={{ toggle: orderDetail.model.ShowCompletedWorkflows }}>
                            <i
                                className="fa"
                                data-bind={{
                                    css: {
                                        "fa-chevron-down": !orderDetail.model.ShowCompletedWorkflows(),
                                        "fa-chevron-up": orderDetail.model.ShowCompletedWorkflows,
                                    },
                                }}></i>
                        </button>
                    </div>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsAssigned}
                    tooltip={TextResources.JobOrder.OrderDetailsAssignedTitle}
                    sorter={sortNumber((r) => r.Assigned())}
                    className={classes.billingInfoSection + " text-right"}>
                    <span data-bind={{ percentageText: orderDetail.model.Assigned }}></span>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsDelivered}
                    tooltip={TextResources.JobOrder.OrderDetailsDeliveredTitle}
                    sorter={sortNumber((r) => r.Shipped())}
                    className={classes.billingInfoSection + " text-right"}>
                    <span data-bind={{ percentageText: orderDetail.model.Shipped }}></span>
                    <ko-bind data-bind={{ if: orderDetail.model.ConsegnatoAtLeastOneManuallyClosed }}>
                        <i
                            className="fa fa-warning"
                            title={TextResources.JobOrder.ConsegnatoAtLeastOneManuallyClosed}></i>
                    </ko-bind>
                </Column>
                <Column
                    title={TextResources.JobOrder.OrderDetailsBilled}
                    tooltip={TextResources.JobOrder.OrderDetailsBilledTitle}
                    sorter={sortNumber((r) => r.Billed())}
                    className={classes.billingInfoSection + " text-right"}>
                    <span className="space-between">
                        <span data-bind={{ percentageText: orderDetail.model.Billed }}></span>
                        <i
                            className="fa fa-warning"
                            title={TextResources.JobOrder.FatturatoAtLeastOneManuallyClosed}></i>
                    </span>

                    <ko-bind data-bind={{ if: orderDetail.model.FatturatoAtLeastOneManuallyClosed }}>
                        <i
                            className="fa fa-warning"
                            title={TextResources.JobOrder.FatturatoAtLeastOneManuallyClosed}></i>
                    </ko-bind>
                </Column>
                <SecondaryRow visible={() => "orderDetail.model.ShowInWaitWorkflows"} cssClasses={classes.secondaryRow}>
                    {(item: ITableItem<JobOrderOrderDetail>) =>
                        this.renderRelatedWorkflows(
                            WorkflowStatus.InWait,
                            item.Data.model.DocumentId,
                            item.Data.model.ShowInWaitWorkflows
                        )
                    }
                </SecondaryRow>
                <SecondaryRow
                    visible={() => "orderDetail.model.ShowInProgressWorkflows"}
                    cssClasses={classes.secondaryRow}>
                    {(item: ITableItem<JobOrderOrderDetail>) =>
                        this.renderRelatedWorkflows(
                            WorkflowStatus.InProgress,
                            item.Data.model.DocumentId,
                            item.Data.model.ShowInProgressWorkflows
                        )
                    }
                </SecondaryRow>
                <SecondaryRow
                    visible={() => "orderDetail.model.ShowCompletedWorkflows"}
                    cssClasses={classes.secondaryRow}>
                    {(item: ITableItem<JobOrderOrderDetail>) =>
                        this.renderRelatedWorkflows(
                            WorkflowStatus.Completed,
                            item.Data.model.DocumentId,
                            item.Data.model.ShowCompletedWorkflows
                        )
                    }
                </SecondaryRow>
            </Table>,
            this
        );
    }

    private renderRelatedWorkflows(
        workflowsStatus: WorkflowStatus,
        customerOrderId: number,
        loadTrigger: ko.Subscribable<boolean>
    ) {
        return (
            <td colSpan={10}>
                <SplashPageWorkflowsDetails
                    jobOrderId={this.JobOrderId}
                    workflowStatus={workflowsStatus}
                    customerOrderId={customerOrderId}
                    loadTrigger={loadTrigger}
                    scrollable={false}></SplashPageWorkflowsDetails>
            </td>
        );
    }

    private async load(): Promise<void> {
        this.Loading(true);

        this.Orders((await this.jobOrderService.GetOrdersDetails(this.JobOrderId)).map(this.createViewModelFor, this));

        this.Loading(false);
    }

    private createViewModelFor(data: IJobOrderOrdersDetails): JobOrderOrderDetail {
        return new JobOrderOrderDetail(data, this.JobOrderId);
    }

    private createJobOrderDetailModel(detail: JobOrderOrderDetail): IDataSourceModel<string, JobOrderOrderDetail> {
        return {
            id: detail.NumberAndDate(),
            title: detail.NumberAndDate(),
            isGroup: false,
            isLeaf: true,
            model: detail,
        };
    }
}

export class JobOrderOrderDetail {
    State: ko.Observable<number> = ko.observable();
    NumberAndDate: ko.Observable<string> = ko.observable();
    RegisterId: ko.Observable<number> = ko.observable();
    RegisterName: ko.Observable<string> = ko.observable();
    ReferenceNumber: ko.Computed<string>;
    FKEstimateState: ko.Observable<number> = ko.observable();
    //OrderUrl: ko.Observable<string> = ko.observable();
    Waiting: ko.Observable<number> = ko.observable();
    Working: ko.Observable<number> = ko.observable();
    Completed: ko.Observable<number> = ko.observable();
    Shipped: ko.Observable<number> = ko.observable();
    Billed: ko.Observable<number> = ko.observable();
    Assigned: ko.Observable<number> = ko.observable();
    OrderTotal: ko.Observable<number> = ko.observable();
    ConsegnatoAtLeastOneManuallyClosed: ko.Observable<boolean> = ko.observable(false);
    FatturatoAtLeastOneManuallyClosed: ko.Observable<boolean> = ko.observable(false);

    ShowInWaitWorkflows: ko.Observable<boolean> = ko.observable(false);
    ShowInProgressWorkflows: ko.Observable<boolean> = ko.observable(false);
    ShowCompletedWorkflows: ko.Observable<boolean> = ko.observable(false);

    InWaitWorkflows: ko.ObservableArray<IJobOrderWorkflowsDetails> = ko.observableArray([]);

    TodoWorkflows: ko.Observable<number> = ko.observable();
    InProgressWorkflows: ko.Observable<number> = ko.observable();
    DoneWorkflows: ko.Observable<number> = ko.observable();

    get DocumentId(): number {
        return this.data.DocumentId;
    }

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor(private data: IJobOrderOrdersDetails, private jobOrderId: number) {
        this.State(data.State);
        this.NumberAndDate(data.Numero + " del " + moment(data.Data).format("L"));
        this.ReferenceNumber = ko.computed(() => {
            let formatted = "";
            if (data.ReferenceNumber) formatted += data.ReferenceNumber + " ";
            if (data.ReferenceDate) formatted += moment(data.ReferenceDate).format("L");
            return formatted;
        });
        this.RegisterId(data.FkRegister);
        this.RegisterName(data.NomeRegistroIVA);
        //this.OrderUrl(this.documentsService.GetOpenDocumentURL(ProlifeSdk.CustomerOrderEntityTypeCode, data.DocumentId));
        //this.OrderUrl(this.ordersService.getCustomerOrderDetailUrl(data.DocumentId, data.FkRegister));
        this.Waiting(data.InAttesaDiAvvioLavori * 100);
        this.Working(data.InLavorazione * 100);
        this.Completed(data.Completato * 100);
        this.Shipped(data.Consegnato * 100);
        this.Billed(data.Fatturato * 100);
        this.Assigned(data.AssegnatoAPiani * 100);
        this.OrderTotal(data.TotaleOrdine || 0);
        this.ConsegnatoAtLeastOneManuallyClosed(data.ConsegnatoAtLeastOneManuallyClosed > 0);
        this.FatturatoAtLeastOneManuallyClosed(data.FatturatoAtLeastOneManuallyClosed > 0);
        this.FKEstimateState(data.FKEstimateState);
        this.TodoWorkflows(data.TodoWorkflows);
        this.InProgressWorkflows(data.InProgressWorkflows);
        this.DoneWorkflows(data.DoneWorkflows);

        this.ShowInWaitWorkflows.subscribe((value: boolean) => {
            if (!value) {
                this.InWaitWorkflows([]);
                return;
            }

            this.ShowInProgressWorkflows(false);
            this.ShowCompletedWorkflows(false);
        });

        this.ShowInProgressWorkflows.subscribe((value: boolean) => {
            if (!value) return;

            this.ShowInWaitWorkflows(false);
            this.ShowCompletedWorkflows(false);
        });

        this.ShowCompletedWorkflows.subscribe((value: boolean) => {
            if (!value) return;

            this.ShowInWaitWorkflows(false);
            this.ShowInProgressWorkflows(false);
        });
    }

    public Open(): void {
        this.documentsService.OpenDocumentOverlay(ProlifeSdk.CustomerOrderEntityTypeCode, this.data.DocumentId);
    }

    public async loadAndShowInWaitWorkflows(): Promise<void> {
        var details = await this.jobOrderService.GetOrdersWorkflowsDetails(
            this.jobOrderId,
            WorkflowStatus.InWait,
            this.DocumentId
        );
        this.InWaitWorkflows(details);
        this.ShowInWaitWorkflows(true);
    }
}

const styles = {
    ordersDetails: {
        "&.compact > tbody > tr > td:not(.form) > .pr_table_column_body > span:not(.badge).space-between": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
        },
    },

    workflowInfoSection: {
        backgroundColor: "#daf6f6",
    },

    billingInfoSection: {
        backgroundColor: "antiquewhite",
    },

    secondaryRow: {
        "& > td": {
            padding: "5px !important",
            backgroundColor: "#daf6f6",
        },
    },

    documentStateIcon: {
        '&[data-state="0"]': {
            backgroundColor: "transparent",
            color: "black",
        },
        '&[data-state="1"]': {
            backgroundColor: "#46c72f",
            color: "white",
        },
        '&[data-state="2"]': {
            backgroundImage: "linear-gradient(to bottom, #46c72f 0, #ecbc29 100%) !important",
            color: "white",
        },
        '&[data-state="3"]': {
            backgroundColor: "#f3565d",
            color: "white",
        },
        '&[data-state="4"]': {
            backgroundImage: "linear-gradient(to bottom, #ecbc29 0, #f3565d 100%) !important",
            color: "white",
        },
        '&[data-state="5"]': {
            backgroundColor: "#ecbc29",
            color: "white",
        },
        '&[data-state="6"]': {
            backgroundImage: "linear-gradient(to bottom, #46c72f 0, #f3565d 100%) !important",
            color: "white",
        },
        '&[data-state="7"]': {
            backgroundImage: "linear-gradient(to bottom, #46c72f 0, #ecbc29 50%, #f3565d 100%) !important",
            color: "white",
        },
    },
};

const { classes } = jss.createStyleSheet(styles).attach();
