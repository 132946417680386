import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/08/2017
 * Time: 14:38
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { Campaign } from "../Campaign";
import { CampaignViewModel } from "../CampaignViewModel";
import { ITodoListResource } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ICampaignService } from "../../../../../ProlifeSdk/interfaces/survey/ICampaignService";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ICampaign } from "../../../../../ProlifeSdk/interfaces/survey/ICampaign";

export class EditCampaignDialog implements IDialog {
    public templateName: string = "add-or-update-campaign";
    public templateUrl: string = "survey/templates/campaign/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.EditCampaignDialogEditTitle;
    public modal: { close: (result?: any) => void; };

    public Campaign: ko.Observable<any> = ko.observable();

    private UpdateMode: boolean;
    private OriginalData: Campaign;
    private campaignService: ICampaignService;
    private infoToastService : IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, campaign: Campaign, campaignResources: ITodoListResource[]) {
        this.campaignService = <ICampaignService> this.serviceLocator.findService(ProlifeSdk.CampaignServiceType);
        this.infoToastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.OriginalData = campaign;

        if (!campaign) {
            this.UpdateMode = false;
            this.title = ProlifeSdk.TextResources.Survey.EditCampaignDialogAddTitle;
            this.Campaign(this.newEmptyCampaign());
        }

        if (campaign) {
            this.UpdateMode = true;
            this.Campaign(new CampaignViewModel(this.serviceLocator, campaign.toJSON(), campaignResources));
        }
    }

    action(): void {
        if (!this.validateData())
            return;

        this.save()
            .then((campaign: ICampaign) => {
                this.dispose();
                this.modal.close(new Campaign(campaign));
                return;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.SaveCampaignError);
            });
    }

    close(): void {
        this.dispose();
        if (this.UpdateMode) {
            this.modal.close(this.OriginalData);
            return;
        }
        this.modal.close(null);
    }

    private validateData(): boolean {
        if (!this.Campaign().Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignTitleRequired);
            return false;
        }

        if (!this.Campaign().StartDate()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignStartDateRequired);
            return false;
        }

        var validDuration: boolean = this.validateDuration();

        if (!validDuration) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.InvalidCampaignDuration);
            return false;
        }

        if (!this.Campaign().SelectedQuestionnaire()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignQuestionnaireRequeired);
            return false;
        }

        if (!this.Campaign().SelectedSample()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignSampleRequeired);
            return false;
        }

        var validSettings: boolean = this.validateSettings();

        return validSettings;
    }

    private validateSettings(): boolean {
        if (this.Campaign().GlobalTimeInterval() && this.Campaign().GlobalTimeInterval() <= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignInvalidTimeIntervalsSetting);
            return false;
        }

        if (this.Campaign().CampaignClassTimeInterval() && this.Campaign().CampaignClassTimeInterval() <= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.CampaignInvalidTimeIntervalsSetting);
            return false;
        }

        return true;
    }

    private validateDuration(): boolean {
        if (!this.Campaign().EndDate())
            return true;

        var startDate: any = moment(this.Campaign().StartDate());
        var endDate: any = moment(this.Campaign().EndDate());

        if (endDate.isSameOrBefore(startDate))
            return false;

        return true;
    }

    private save(): Promise<ICampaign> {
        if (!this.OriginalData)
            return this.campaignService.addCampaign(this.Campaign().toJSON(), this.Campaign().Resources.GetResources());
        
        return this.campaignService.updateCampaign(this.Campaign().toJSON(), this.Campaign().Resources.GetResources());
    }

    private dispose(): void {
        this.Campaign().dispose();
    }

    private newEmptyCampaign(): CampaignViewModel {
        return new CampaignViewModel(
            this.serviceLocator,
            <ICampaign> {
                Id: null,
                Title: null,
                TypeId: null,
                TypeName: null,
                Icon: null,
                Background: null,
                Foreground: null,
                StartDate: null,
                EndDate: null,
                CreationDate: null,
                CreatorId: null,
                ModifyDate: null,
                ModifierId: null,
                Deleted: null,
                Description: null,
                StateId: 1,
                StateDescription: null,
                QuestionnaireId: null,
                QuestionnaireVersion: null,
                QuestionnaireTitle: null,
                SampleId: null,
                SampleTitle: null,
                SampleSize: null,
                GlobalTimeInterval: null,
                CampaignClassTimeInterval: null,
                Enabled: false
            },
            []
        );
    }
}