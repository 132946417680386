import * as React from "@abstraqt-dev/jsxknockout";
import { WizardDialogStepBase } from "../../../../../ProlifeSdk/prolifesdk/WizardDialogStepBase";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { WizardDialogBase } from "../../../../../ProlifeSdk/prolifesdk/WizardDialogBase";
import { BindTo } from "../../../../../Components/Bind";
import { WarehouseInventoryDataSource } from "./Sources/WarehouseInventoryDataSource";
import { RequirementsFromDocumentsDataSource } from "./Sources/RequirementsFromDocumentsDataSource";
import { IWarehouseInspectionWizardArticleInfo } from "../../../../WarehouseInspectionsService";
import { IWarehouseArticlesImporterForInspectionInitializationInfo } from "./WarehouseArticlesImporterForInspection";
import { WarehouseLoadsRequirementsDataSource } from "./Sources/WarehouseLoadsRequirementsDataSource";

// TODO questa classe è praticamente uguale a SourcesSelectionDocumentDataWizardStep. Mettere a comune il codice.

export class WarehouseArticlesImporterSourceSelectionStep extends WizardDialogStepBase<IWarehouseInspectionWizardArticleInfo[], IWarehouseInspectionWizardArticleInfo[]> {
    public DataSources : ko.ObservableArray<WizardDialogStepBase<IWarehouseInspectionWizardArticleInfo[], IWarehouseInspectionWizardArticleInfo[]>> = ko.observableArray([]);

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private wizard: WizardDialogBase, private initializationInfo: IWarehouseArticlesImporterForInspectionInitializationInfo) {
        super();

        this.Title(TextResources.ProlifeSdk.SelectDataSources);
    }

    public async Initialize() : Promise<void> {

        let steps = [];
        
        steps.push(new WarehouseInventoryDataSource(this.initializationInfo));
        steps.push(new RequirementsFromDocumentsDataSource(this.initializationInfo));
        /* steps.push(new WarehouseLoadsRequirementsDataSource(this.initializationInfo)); */

        steps.forEach(s => s.IsSelected(false));

        for (let step of steps) {
            await step.Initialize();
        }

        this.DataSources(steps);
    }

    public CanGoNext() : boolean {
        var selected = this.DataSources()
            .filter(s => s.IsSelected());

        if (selected.length == 0)
        {
            this.infoToastService.Warning(TextResources.ProlifeSdk.SelectAtLeastOneDataSource);
            return false;
        }

        return true;
    }

    public OnShow(previousStepResult: IWarehouseInspectionWizardArticleInfo[]): void {
        
    }
    
    public OnNext(): IWarehouseInspectionWizardArticleInfo[] {
        var selected = this.DataSources()
            .filter(s => s.IsSelected());

        this.wizard.SetDataSources(selected);
        return [];
    }
    
    public render(): React.ReactNode {
        let step: WarehouseArticlesImporterSourceSelectionStep;
        let source: WizardDialogStepBase;

        return (
            <BindTo viewModel={this} as="step">
                <div style={{ padding: "20px" }}>
                    <div className="row">
                        <pre>
                            {TextResources.Warehouse.WarehouseArticlesImporterSourceSelectionInformation}
                        </pre>
                        <br />
                        <br />
                    </div>
                    <div className="row">
                        <ul className="list-group" data-bind={{foreach: { data: step.DataSources, as: 'source' }}}>
                            <li className="list-group-item" style="cursor : pointer" data-bind={{text : source.Title, toggle : source.IsSelected, toggleActiveClass : 'list-group-item-success', visible: source.IsVisible}}></li>
                        </ul>
                    </div>
                </div>
            </BindTo>
        );
    }
}