import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IJobOrderBlockMotivation } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";

export interface IJobOrderBlockMotivationProvider
{
    getData() : IJobOrderBlockMotivation;
    update(m : IJobOrderBlockMotivation) : void;
}

export class JobOrderBlockMotivationsEditingViewModel
{
    title : string;
    elements : ko.ObservableArray<IJobOrderBlockMotivationProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();
    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private jobOrderBlockMotivationsManager : IJobOrderBlockMotivationsSettingsManager)
    {
        this.title = jobOrderBlockMotivationsManager.getLabel();
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        jobOrderBlockMotivationsManager.getMotivations()
            .forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(m : IJobOrderBlockMotivation)
    {
        this.elements.push(new JobOrderBlockMotivationViewModel(this.serviceLocator, this, m));
    }

    public addNewSetting()
    {
        var vm = new JobOrderBlockMotivationViewModel(this.serviceLocator, this, {
            Description: this.newSettingName(),
            Deleted : false
        });
        this.elements.push(vm);
        this.createOrUpdateEntry(vm);
        this.newSettingName("");
    }

    public setIsSelectedNewSetting()
    {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IJobOrderBlockMotivationProvider)
    {
        var m = element.getData();
        this.jobOrderBlockMotivationsManager.createOrUpdate(m)
            .then((updated) => element.update(updated));
    }

    public deleteEntry(element : IJobOrderBlockMotivationProvider)
    {
        var m = element.getData();
        this.jobOrderBlockMotivationsManager.remove(m.Id)
            .then(() => {
                this.elements.remove(element);
            })
            .catch(() => { this.toastService.Error(ProlifeSdk.TextResources.JobOrder.DescriptionDeleteError); });
    }
}


class JobOrderBlockMotivationViewModel implements IJobOrderBlockMotivationProvider
{
    private toastService : IInfoToastService;

    Description : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    private currentDescription : string;

    constructor(private serviceLocator, private container : JobOrderBlockMotivationsEditingViewModel, private motivation : IJobOrderBlockMotivation)
    {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.Description(motivation.Description);
        this.currentDescription = motivation.Description;
        this.Description.subscribe(this.onNameChanged.bind(this));
    }

    private onNameChanged(newValue : string)
    {
        if(this.currentDescription == newValue)
            return;

        if(!newValue)
            this.container.deleteEntry(this);
        else
            this.container.createOrUpdateEntry(this);
    }

    getData() : IJobOrderBlockMotivation
    {
        var m : IJobOrderBlockMotivation = <IJobOrderBlockMotivation> $.extend({}, this.motivation);
        m.Description = this.Description();
        return m;
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    update(m : IJobOrderBlockMotivation) : void
    {
        this.updating = true;
        this.motivation = m;
        this.Description(m.Description);
        this.currentDescription = m.Description;
        this.updating = false;
    }
}
