import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ICampaignState, ICampaignLogicalState } from "../../../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ICampaignStateSettingManager } from "../../../../../interfaces/ICampaignStateSettingManager";

export interface ICampaignStateProvider {
	getData() : ICampaignState;
	update(campaignState : ICampaignState) : void;
    withError: ko.Observable<boolean>;
}

export class CampaignStateEditingViewModel{
	title : string;
	elements : ko.ObservableArray<ICampaignStateProvider> = ko.observableArray();
	newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);

    public LogicalStates: ko.ObservableArray<ICampaignLogicalState> = ko.observableArray([]);
    public NewState: ko.Observable<string> = ko.observable();

    private infoToastService: IInfoToastService;

	constructor(private serviceLocator : IServiceLocator, private campaignStateManager: ICampaignStateSettingManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
		this.title = campaignStateManager.getLabel();

        this.LogicalStates(this.campaignStateManager.getCampaignLogicalState());

		this.reloadElements();
	}

    private reloadElements() {
        this.elements([]);
        this.campaignStateManager.load();
        var campaignStates = this.campaignStateManager.getCampaignStates();
        campaignStates.forEach(this.createViewModelFor.bind(this));
    }

	private createViewModelFor(campaignState: ICampaignState) {
		this.elements.push(new CampaignStateViewModel(this, campaignState, this.campaignStateManager.getCampaignLogicalState()));
	}

	public addNewSetting() {
        if (!this.NewState())
            return;
		var campaignStateViewModel = new CampaignStateViewModel(this, {
            Id: null,
			Label: this.NewState(),
			StateId: this.campaignStateManager.getCampaignStates()[0].Id
		}, this.campaignStateManager.getCampaignLogicalState());
		this.elements.push(campaignStateViewModel);
		this.createOrUpdateEntry(campaignStateViewModel);

		this.NewState("");
	}

	public setIsSelectedNewSetting() {
		this.newSettingHasFocus(true);
	}

	public createOrUpdateEntry(element: ICampaignStateProvider) {
		var campaignState = element.getData();
		this.campaignStateManager.addOrUpdate(campaignState)
			.then((updatedCampaignState) => element.update(updatedCampaignState));
	}

	public deleteEntry(element: ICampaignStateProvider) {
		var campaignState = element.getData();
		this.campaignStateManager.remove(campaignState.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            });
	}
}


class CampaignStateViewModel implements ICampaignStateProvider {
    Id: ko.Observable<number> = ko.observable();
	Label : ko.Observable<string> = ko.observable();
	LogicalState : ko.Observable<number> = ko.observable();
    SelectedLogicalState : ko.Observable<string> = ko.observable();
	hasFocus : ko.Observable<boolean> = ko.observable(false);

    public withError: ko.Observable<boolean> = ko.observable(false);
	private updating : boolean = false;

	constructor(private container : CampaignStateEditingViewModel, private campaignState : ICampaignState, public campaignLogicalState : ICampaignLogicalState[]) {
        this.Id(campaignState.Id);
		this.Label(campaignState.Label);
		this.LogicalState(campaignState.StateId);

        this.setInitialState();

		this.Label.subscribe(this.onNameChanged.bind(this));
		this.LogicalState.subscribe(this.onDataChanged.bind(this));
	}

    private setInitialState(): void {
        this.SelectedLogicalState(this.campaignLogicalState.filter((s: ICampaignLogicalState) => {
            return s.Id == this.LogicalState();
        })[0].Description);
    }

    public SetLogicalState(state: ICampaignLogicalState): void {
        this.LogicalState(state.Id);
        this.SelectedLogicalState(state.Description);
    }

	private onNameChanged(newValue : string) {
        this.withError(false);
        this.container.createOrUpdateEntry(this);
	}

	getData() : ICampaignState {
		var campaignState: ICampaignState = <ICampaignState> $.extend({}, this.campaignState);
        campaignState.Id = this.Id();
        campaignState.Label = this.Label();
        campaignState.StateId = this.LogicalState();
		return campaignState;
	}

	private onDataChanged(newValue : string) {
		if(this.updating) return;
		this.container.createOrUpdateEntry(this);
	}

	update(campaignState : ICampaignState) : void {
		this.updating = true;
		this.campaignState = campaignState;
        this.Id(campaignState.Id);
		this.Label(campaignState.Label);
		this.LogicalState(campaignState.StateId);

		this.updating = false;
	}
}