import * as ko from "knockout";
export class ArrayUtils {}

declare global {
    interface Array<T> {
        firstOrDefault(predicate? : (value : T) => boolean) : T | null;
        lastOrDefault(predicate? : (value : T) => boolean) : T | null;
        any(predicate : (value : T) => boolean) : boolean;
        
        selectMultiple<K>(predicate : (value : T) => K[]) : K[];
        max<K>(predicate : (value: T) => K) : K;
        min<K>(predicate : (value: T) => K) : K;
        sum(predicate : (value: T) => number) : number;
        ofType<R extends T>(type : { new(...args: any[]): R}) : R[];
        
        orderBy(predicate : (value: T) => number) : Array<T>;
        orderBy(predicate : (value: T) => string) : Array<T>;
        orderBy(predicate : (value: T) => Date) : Array<T>;
        
        orderByDesc(predicate : (value: T) => number) : Array<T>;
        orderByDesc(predicate : (value: T) => string) : Array<T>;
        orderByDesc(predicate : (value: T) => Date) : Array<T>;

        toMap(keySelector: (value : T) => string) : { [key: string] : T };

        remove(item: T): T | null;

        distinct(): Array<T>;
        distinctValues(): Array<T>;
        distinctBy<K>(getter: (T) => K): Array<T>;

        groupBy(keySelector: (value : T) => string) : { [key: string] : T[] };
        groupBy(keySelector: (value : T) => number) : { [key: number] : T[] };
    }
}

Object.defineProperty(Array.prototype, "firstOrDefault", {
    value: function(predicate? : (value : any) => boolean) {
        let filteredArray = this;

        if(predicate) {
            filteredArray = this.filter(predicate);
        }

        return filteredArray.length > 0 ? filteredArray[0] : null;
    }
});

Object.defineProperty(Array.prototype, "lastOrDefault", {
    value: function(predicate? : (value : any) => boolean) {
        let lastElem = this.length > 0 ? this[this.length - 1] : null;

        if (predicate) {
            for (let i = this.length - 1; i >= 0; i--) {
                const elem = this[i];
                if (predicate(elem)) {
                    lastElem = elem;
                    break;
                }
            }
        }

        return lastElem;
    }
});

//selectMultiple<K>(predicate : (value : T) => K[]) : K[]
Object.defineProperty(Array.prototype, "selectMultiple", {
    value: function(predicate : (value : any) => any[]) {
        let result = [];

        for(const item of this) {
            result = result.concat(predicate(item));
        }

        return result;
    }
});

Object.defineProperty(Array.prototype, "ofType", {
    value: function(type) {
        return this.filter(i => i instanceof type);
    }
});

Object.defineProperty(Array.prototype, "max", {
    value: function(callback : (value : any) => any) {
        let maxValue = null;
        
        this.forEach((i) => {
            const newValue = callback(i);
            if(newValue > maxValue)
                maxValue = newValue;
        });

        return maxValue;
    }
});

Object.defineProperty(Array.prototype, "min", {
    value: function(callback : (value : any) => any) {
        let minValue = null;
        
        this.forEach((i) => {
            const newValue = callback(i);
            if(newValue < minValue)
                minValue = newValue;
        });

        return minValue;
    }
});

Object.defineProperty(Array.prototype, "sum", {
    value: function(callback : (value : any) => number) {
        let sumValue = null;
        
        this.forEach((i : any) => {
            let newValue = callback(i);
            sumValue += newValue || 0;
        });

        return sumValue;
    }
});

//orderBy<K>(predicate : (value: T) => K) : Array<T>;
Object.defineProperty(Array.prototype, "orderBy", {
    value: function(predicate : (value : any) => any) {
        let clone = (this as any[]).slice();
        clone.sort((a,b) => {
            let va = predicate(a);
            let vb = predicate(b);
            if(va < vb)
                return -1;
            if(va > vb)
                return 1;
            return 0;
        });
        return clone;
    }
})

//orderByDesc<K>(predicate : (value: T) => K) : Array<T>;
Object.defineProperty(Array.prototype, "orderByDesc", {
    value: function(predicate : (value : any) => any) {
        let clone = (this as any[]).slice();
        clone.sort((a,b) => {
            let va = predicate(a);
            let vb = predicate(b);
            if(va < vb)
                return 1;
            if(va > vb)
                return -1;
            return 0;
        });
        return clone;
    }
})

Object.defineProperty(Array.prototype, "toMap", {
    value: function(keySelector : (value : any) => any) {
        let valuesMap = {};
        for(let value of this) {
            let key = keySelector(value);
            if(!valuesMap[key])
                valuesMap[key] = [];
            valuesMap[key].push(value);
        }
        return valuesMap;
    }
})

Object.defineProperty(Array.prototype, "any", {
    value: function(predicate : (value : any) => boolean) {
        for(let value of this) {
            let result = predicate(value);
            if(result)
                return true;
        }
        return false;
    }
})

//distinct(): Array<T>
Object.defineProperty(Array.prototype, "distinct", {
    value: function() {
        if(this.length > 0 && typeof this[0] === "object") {
            let set = new Set();
            let serialized = this.map(o => JSON.stringify(o));
            let result = [];
            let index = 0;
            for(let obj of serialized) {
                if(!set.has(obj)) {
                    set.add(obj);
                    result.push(this[index]);
                }
                index++;
            }
            return result;
        }

        return Array.from(new Set(this));
    }
});

Object.defineProperty(Array.prototype, "groupBy", {
    value: function(keySelector : (value : any) => string | number) {
        let values = {};

        for(let value of this) {
            let key = keySelector(value);

            if(!values[key]) {
                values[key] = [value];
            } else {
                values[key].push(value);
            }
        }
        
        return values;
    }
});

Object.defineProperty(Array.prototype, "remove", {
    value: function(item? : (value : any) => boolean) {
        let itemIndex = -1;

        if (!item)
            throw new Error("Missing item argument");

        itemIndex = this.indexOf(item);

        if (itemIndex < 0)
            return null;

        return this.splice(itemIndex, 1);
    }
});

Object.defineProperty(Array.prototype, "distinctBy", {
    value: function(getter: (item: any) => any) {
        let map = new Map();
        let values = [];

        for (let value of this) {
            let fieldVaue = getter(value);

            if (!map.has(fieldVaue)) {
                map.set(fieldVaue, null);
                values.push(value);
            }
        }

        map.clear();
        map = undefined;
        
        return values;
    }
});

Object.defineProperty(Array.prototype, "distinctValues", {
    value: function() {
        let map = new Map();
        let values = [];

        for (let value of this) {
            if(!map.has(value)) {
                map.set(value, value);
                values.push(value);
            }
        }
        
        map.clear();
        map = undefined;

        return values;
    }
});