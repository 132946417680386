import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import {
    IProtocolDefaultValuesProvider,
    IProtocolSetting,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import {
    ICustomer,
    IOrganizationalUnit,
    IProtocolSettingsForCustomer,
    IProtocolDefaultValueForCustomer,
} from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class ProtocolDefaultValuesProviderByCustomer implements IProtocolDefaultValuesProvider {
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    public Priority: number;

    constructor() {
        this.Priority = 10;
    }

    async GetSettings(
        protocolId: string,
        customerId: number,
        organizationalUnitId: number,
        jobOrderId: number
    ): Promise<IProtocolSetting[]> {
        if (!customerId || <any>customerId == "" || <any>customerId == "0") return null;

        const customer = await this.customersService.getCustomerById(customerId);
        if (!customer) return null;

        return this.GetSettingsSync(protocolId, customer, organizationalUnitId, null);
    }

    GetSettingsSync(
        protocolId: string,
        customer: ICustomer,
        organizationalUnitId: number,
        jobOrder: IJobOrder
    ): IProtocolSetting[] {
        if (!customer) return [];

        let uo: IOrganizationalUnit = null;
        const defaultMatches = customer.OrganizationalUnits.filter((uo: IOrganizationalUnit) => {
            return uo.IsDefault;
        });
        uo = defaultMatches.length > 0 ? defaultMatches[0] : null;
        const requiredUoMatches = customer.OrganizationalUnits.filter((uo: IOrganizationalUnit) => {
            return uo.Id == organizationalUnitId;
        });
        uo = organizationalUnitId && requiredUoMatches.length > 0 ? requiredUoMatches[0] : uo;

        const protocolMatches: IProtocolSettingsForCustomer[] = uo.ProtocolsSettings.filter(
            (s: IProtocolSettingsForCustomer) => {
                return s.DefaultValuesEnabled;
            }
        );

        let settings: IProtocolDefaultValueForCustomer[] =
            uo && protocolMatches.length > 0
                ? uo.ProtocolsDefaultValues.filter((s: IProtocolSetting) => {
                      return s.ProtocolId == protocolId;
                  })
                : null;

        if (settings)
            settings.forEach(
                (s: IProtocolDefaultValueForCustomer) =>
                    (s.Source = ProlifeSdk.TextResources.Invoices.CustomerProtocolDefaultValue)
            );

        if (customer.DefaultIvaMode) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.IvaSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.IvaSettingKey,
                    IntValue: customer.DefaultIvaMode,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultIvaMode;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        if (customer.DefaultCurrency) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.CurrencySettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.CurrencySettingKey,
                    IntValue: customer.DefaultCurrency,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultCurrency;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        if (customer.DefaultPaymentExpiryTypeId) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActiveExpireModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActiveExpireModeSettingKey,
                    IntValue: customer.DefaultPaymentExpiryTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPaymentExpiryTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        if (customer.DefaultPassivePaymentExpiryTypeId) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PassiveExpireModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PassiveExpireModeSettingKey,
                    IntValue: customer.DefaultPassivePaymentExpiryTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPassivePaymentExpiryTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        if (customer.DefaultPaymentTypeId) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentModeSettingKey,
                    IntValue: customer.DefaultPaymentTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });

                this.setDefaultPaymentAccountId(customer, settings);
                this.setDefaultPaymentAbi(customer, settings);
                this.setDefaultPaymentCab(customer, settings);
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPaymentTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;

                this.setDefaultPaymentAccountId(customer, settings);
                this.setDefaultPaymentAbi(customer, settings);
                this.setDefaultPaymentCab(customer, settings);
            }
        }

        if (customer.DefaultPassivePaymentTypeId) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PassivePaymentModeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PassivePaymentModeSettingKey,
                    IntValue: customer.DefaultPassivePaymentTypeId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });

                this.setDefaultPassivePaymentAccountId(customer, settings);
                this.setDefaultPassivePaymentAbi(customer, settings);
                this.setDefaultPassivePaymentCab(customer, settings);
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPassivePaymentTypeId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;

                this.setDefaultPassivePaymentAccountId(customer, settings);
                this.setDefaultPassivePaymentAbi(customer, settings);
                this.setDefaultPassivePaymentCab(customer, settings);
            }
        }

        if (customer.DefaultOffset) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PaymentOffsetSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PaymentOffsetSettingKey,
                    IntValue: customer.DefaultOffset,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultOffset;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        if (customer.DefaultOutcome) {
            if (!settings) settings = [];

            const settingMatches: IProtocolDefaultValueForCustomer[] = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.WorkflowOutcomeSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.WorkflowOutcomeSettingKey,
                    IntValue: customer.DefaultOutcome,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultOutcome;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }

        settings = settings || [];

        return settings;
    }

    private setDefaultPaymentAccountId(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPaymentAccountId) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentAccountSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentAccountSettingKey,
                    IntValue: customer.DefaultPaymentAccountId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPaymentAccountId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }

    private setDefaultPassivePaymentAccountId(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPassivePaymentAccountId) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PassivePaymentAccountSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PassivePaymentAccountSettingKey,
                    IntValue: customer.DefaultPassivePaymentAccountId,
                    DecimalValue: null,
                    StringValue: null,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].IntValue) {
                settingMatches[0].IntValue = customer.DefaultPassivePaymentAccountId;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }

    private setDefaultPaymentAbi(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPaymentABI) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentABISettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentABISettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: customer.DefaultPaymentABI,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = customer.DefaultPaymentABI;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }

    private setDefaultPassivePaymentAbi(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPassivePaymentABI) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PassivePaymentABISettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PassivePaymentABISettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: customer.DefaultPassivePaymentABI,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = customer.DefaultPassivePaymentABI;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }

    private setDefaultPaymentCab(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPaymentCAB) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.ActivePaymentCABSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.ActivePaymentCABSettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: customer.DefaultPaymentCAB,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = customer.DefaultPaymentCAB;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }

    private setDefaultPassivePaymentCab(customer: ICustomer, settings: IProtocolDefaultValueForCustomer[]) {
        if (customer.DefaultPassivePaymentCAB) {
            const settingMatches = settings.filter(
                (s: IProtocolSetting) => s.SettingKey == ProlifeSdk.PassivePaymentCABSettingKey
            );

            if (settingMatches.length == 0) {
                settings.push({
                    ProtocolId: null,
                    SettingKey: ProlifeSdk.PassivePaymentCABSettingKey,
                    IntValue: null,
                    DecimalValue: null,
                    StringValue: customer.DefaultPassivePaymentCAB,
                    DateValue: null,
                    Source: ProlifeSdk.TextResources.Invoices.CustomerDefaultValue,
                });
            } else if (!settingMatches[0].StringValue) {
                settingMatches[0].StringValue = customer.DefaultPassivePaymentCAB;
                settingMatches[0].Source = ProlifeSdk.TextResources.Invoices.CustomerDefaultValue;
            }
        }
    }
}
