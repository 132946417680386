import { IDataSourceModel } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { IGDPRService, IGDPREventType, ITreatmentType } from "../GDPR/GDPRService";
import { ITreatmentTypesSettingsManager } from "../GDPR/settings/TreatmentTypesSettingsManager";

export interface ITreatmentTypesDataSourceModel extends IDataSourceModel<number, ITreatmentType> {

}

export class TreatmentTypesDataSource extends BaseDataSource<ITreatmentTypesDataSourceModel> {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    @LazyImportSettingManager(nameof<ITreatmentTypesSettingsManager>())
    private TreatmentTypesSettingsManager: ITreatmentTypesSettingsManager;

    private showDeleted: boolean;

    setShowDeleted(showDeleted: boolean){
        this.showDeleted = showDeleted;
    }

    getTitle(currentModel: ITreatmentTypesDataSourceModel): string {
        return "Treatment Types";
    }

    async getData(currentModel: ITreatmentTypesDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITreatmentTypesDataSourceModel[]> {
        let allTreatments = await this.TreatmentTypesSettingsManager.getTreatmentTypes(textFilter, this.showDeleted, skip, count);
        return allTreatments.map(this.createModelFor, this);
    }

    async getById(currentModel: ITreatmentTypesDataSourceModel, ids: number[]): Promise<ITreatmentTypesDataSourceModel[]> {
        let allTreatments = await this.TreatmentTypesSettingsManager.getTreatmentTypesByIds(ids);
        return allTreatments.map(this.createModelFor, this);
    }

    private createModelFor(treatmentType: ITreatmentType): ITreatmentTypesDataSourceModel {
        return {
            id: treatmentType.Id,
            title: treatmentType.Name,
            isLeaf: true,
            isGroup: false,
            model: treatmentType
        };
    }
}