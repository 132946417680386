import { LazyImport } from "../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CustomersGroupsEditingViewModel } from "./ui/CustomersGroupsEditingViewModel";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomersGroupsSettingsService extends ISettingsManager {

}

export class CustomersGroupsSettingsManager implements ICustomersGroupsSettingsService {
    @LazyImport(ProlifeSdk.SettingsServiceType)
    private settingsService : ISettingsService;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Customers.CustomersSettingsGroup);
    }

    load(): Promise<any> {
        return Promise.resolve();
    }    
    
    getName(): string {
        return "CustomersGroupsSettingsManager";
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Customers.CustomersGroups;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return new CustomersGroupsEditingViewModel();
    }
}