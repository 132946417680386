import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { IJobOrderState } from "../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IDataSourceModel } from "./IDataSource";
import { IJobOrderStateSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export type IJobOrderStatesDataSourceModel = IDataSourceModel<number, IJobOrderState>;

export enum JobOrderLogicalStates {
    Opened = 0,
    Closed = 1,
}

export class JobOrderStatesDataSource extends BaseDataSource<IJobOrderStatesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStateSettingsManager: IJobOrderStateSettingsManager;

    private logicalState: JobOrderLogicalStates | null = null;

    getTitle(currentModel: IJobOrderStatesDataSourceModel): string {
        return "";
    }

    setLogicalState(logicalState: JobOrderLogicalStates) {
        this.logicalState = logicalState;
    }

    async getData(
        currentModel: IJobOrderStatesDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IJobOrderStatesDataSourceModel[]> {
        const states = this.jobOrderStateSettingsManager.getJobOrderStates();
        return states
            .filter(
                (s) =>
                    TextFiltersUtilities.contains(s.Description, textFilter) &&
                    (this.logicalState === null || s.LogicalState === this.logicalState)
            )
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(
        currentModel: IJobOrderStatesDataSourceModel,
        ids: number[]
    ): Promise<IJobOrderStatesDataSourceModel[]> {
        const states = this.jobOrderStateSettingsManager.getJobOrderStates();
        return states.filter((s) => ids.indexOf(s.IdJobOrderStateId) >= 0).map(this.createModel, this);
    }

    private createModel(state: IJobOrderState): IJobOrderStatesDataSourceModel {
        return {
            id: state.IdJobOrderStateId,
            title: state.Description,
            isGroup: false,
            isLeaf: true,
            icon: {
                icon: state.Icon,
                background: state.Background,
                foreground: state.Foreground,
            },
            model: state,
        };
    }
}
