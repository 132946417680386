import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTaskTags } from "./TodoListTaskTags";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IHumanResourcesService, IHumanResource } from "../../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ITodoListTaskTag } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import {
    IResourcesGroupsSettingsManager,
    IResourcesGroup,
} from "../../../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

export class TodoListTaskHumanResourceTags extends TodoListTaskTags {
    @LazyImport(ProlifeSdk.HumanResourcesServiceType)
    private humanResourcesService: IHumanResourcesService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private resourcesGroupsSettingsManager: IResourcesGroupsSettingsManager;

    private lastTimeout: ReturnType<typeof setTimeout>;

    public ElementsIdsAndTypes: ko.ObservableArray<{ Id: number; Type: string }> = ko.observableArray([]);

    constructor(fieldToManage, private supportGroups: boolean = false) {
        super(ProlifeSdk.HumanResources, fieldToManage);
    }

    public SearchTags(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            const defs: Promise<any>[] = [];
            const result = { results: [] };

            const resourcesDef = this.humanResourcesService
                .GetHumanResources(query.term)
                .then((resources: IHumanResource[]) => {
                    const group = {
                        text: ProlifeSdk.TextResources.Todolist.HumanResources,
                        children: [],
                    };

                    group.children = resources
                        .filter((r: IHumanResource) => {
                            return !r.Resource.Deleted;
                        })
                        .map((r: IHumanResource) => {
                            return {
                                id: "R_" + r.Resource.Id,
                                text: this.humanResourcesService.getFullName(r),
                            };
                        });

                    if (group.children.length > 0) result.results.push(group);
                });
            defs.push(resourcesDef);

            if (this.supportGroups) {
                const groupsDef: Promise<IResourcesGroup[]> = this.humanResourcesService
                    .GetResourcesGroups(query.term)
                    .then((resourcesGroups: IResourcesGroup[]) => {
                        const group = {
                            text: ProlifeSdk.TextResources.Todolist.ResourcesGroups,
                            children: [],
                        };

                        group.children = resourcesGroups.map((r: IResourcesGroup) => {
                            return {
                                id: "G_" + r.Id,
                                text: r.Name,
                            };
                        });

                        if (group.children.length > 0) result.results.push(group);

                        return resourcesGroups;
                    });
                defs.push(groupsDef);
            }

            Promise.all(defs).then(() => {
                query.callback(result);
            });
        }, 500);
    }

    public LoadFromModel(tags: ITodoListTaskTag[]) {
        super.LoadFromModel(tags);

        const idsAndTypes: any[] = this.ExtractTagsByField(tags).map((t: ITodoListTaskTag) => {
            return { Id: parseInt(t.Value), Type: t.Type == ProlifeSdk.HumanResources ? "R" : "G" };
        });
        this.ElementsIdsAndTypes(idsAndTypes);

        const value = this.ExtractTagsByField(tags)
            .map((t: ITodoListTaskTag) => {
                return (t.Type == ProlifeSdk.HumanResources ? "R_" : "G_") + t.Value;
            })
            .join("|");
        this.TagsValue(value);
    }

    public SetTagsOnCollection(list: ITodoListTaskTag[]) {
        this.ExtractTagsByField(list).forEach((t1: ITodoListTaskTag) => {
            return list.splice(list.indexOf(t1), 1);
        });

        if (this.TagsValue().length == 0) return [];

        const tags: string[] = this.TagsValue().split("|");

        tags.map((serializedTag: string) => {
            const type = serializedTag.split("_")[0];
            const id = serializedTag.split("_")[1];
            const tag: ITodoListTaskTag = this.FormatAsTag(id);
            tag.Type = type == "R" ? ProlifeSdk.HumanResources : ProlifeSdk.ResourcesGroups;
            return tag;
        }).forEach((t) => {
            list.push(t);
        });

        return [];
    }

    public GetStartTagsForListControl(element, callback) {
        const values = <string>$(element).val();
        if (values !== "") {
            const result: any[] = [];
            const tags: string[] = values.split("|");
            tags.forEach((serializedTag: string) => {
                const type = serializedTag.split("_")[0];
                const id = serializedTag.split("_")[1];

                const description =
                    type == "R"
                        ? this.humanResourcesSettingsManager.getFullName(
                              this.humanResourcesSettingsManager.getHumanResourceById(parseInt(id))
                          )
                        : this.resourcesGroupsSettingsManager.GetGroupById(parseInt(id)).Name;
                result.push({ id: serializedTag, text: description });
            });
            callback(result);
        }
    }
}
