import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 07/02/13
 * Time: 12.06
 * To change this template use File | Settings | File Templates.
 */

import { IAccount } from "../../../../ProlifeSdk/interfaces/settings/IAccount";

export class AccountEditingViewModel {
    IBAN : ko.Observable<string> = ko.observable();
    Description : ko.Observable<string> = ko.observable();

    public originalValue : IAccount;
    public isChanged : ko.Computed<boolean>;

    constructor(private account? : IAccount)
    {
        this.originalValue = account;

        if(!account) return;

        this.isChanged = ko.computed(() => {
            var isChanged = false;
            isChanged = isChanged || ((this.originalValue ? this.originalValue.IBAN : null) != this.IBAN());
            return isChanged;
        });

        this.IBAN(account.IBAN);
        this.Description(account.Description || "");
    }

    public getAccount() : IAccount {
        var account : IAccount = <IAccount> $.extend({}, this.account);

        account.IBAN = this.IBAN();
        account.Description = this.Description();

        return account;
    }
}