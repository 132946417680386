import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { NumberInput } from "./NumberInput";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

type RangeInputProps = {
    title?: string;
    className?: string;
    format?: string;
    minValue: ko.Observable<number>;
    maxValue: ko.Observable<number>;
};

export function RangeInput(props: RangeInputProps) {
    const C = require("./RangeInput")._RangeInput as typeof _RangeInput;
    return <C {...props} />;
}

export class _RangeInput {
    static defaultProps: Partial<RangeInputProps> = {
        format: "0,0",
        className: "",
    };

    private subscriptions: ko.Subscription[] = [];

    constructor(private props: RangeInputProps) {
        if (!this.props.minValue) this.props.minValue = ko.observable();
        if (!this.props.maxValue) this.props.maxValue = ko.observable();
    }

    componentDidMount() {
        this.subscriptions.push(this.props.minValue.subscribe(this.onMinValueChanges.bind(this)));
        this.subscriptions.push(this.props.maxValue.subscribe(this.onMaxValueChanges.bind(this)));
    }

    componentWillUnmount() {
        for (let sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];
    }

    render() {
        return ComponentUtils.bindTo(
            <div className={"row " + this.props.className}>
                <div className="col-md-12">
                    {this.props.title && <label>{this.props.title}</label>}
                    <div className="row">
                        <div className="col-md-6">
                            <NumberInput
                                value={this.props.minValue}
                                format={this.props.format}
                                nullable
                                selectOnFocus
                                placeholder={TextResources.ProlifeSdk.MinValue}
                            />
                        </div>
                        <div className="col-md-6">
                            <NumberInput
                                value={this.props.maxValue}
                                format={this.props.format}
                                nullable
                                selectOnFocus
                                placeholder={TextResources.ProlifeSdk.MaxValue}
                            />
                        </div>
                    </div>
                </div>
            </div>,
            this
        );
    }

    private onMinValueChanges(minValue: number): void {
        if (minValue === undefined || minValue === null) return;

        if (minValue > this.props.maxValue()) this.props.maxValue(minValue);
    }

    private onMaxValueChanges(maxValue: number): void {
        if (maxValue === undefined || maxValue === null) return;

        if (maxValue < this.props.minValue()) this.props.minValue(maxValue);
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(RangeInput);
}
