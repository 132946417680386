import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentCauseLogicType } from "../../enums/DocumentCauseLogicType";
import { Document, IDocumentConfiguration } from "../Document";

export class DDTConfiguration implements IDocumentConfiguration {
    DocumentType: string = ProlifeSdk.DdtEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(true);

        document.DDTCausesDataSource.setShowOnlySalesCauses(false);

        document.CanHaveDestinationRecipient(true);
        document.CanBeAnElectronicInvoice(false);
        document.CanHaveStampDuty(false);
        document.CanBeDownPayment(false);
        document.CanHaveSecondaryRecipients(false); //TODO: Boh?
        document.CanBeForPublicAdministration(false);
        document.CanHaveWithholdingTax(false);
        document.CanHideAmounts(true);
        document.CanSelectCustomers(true);
        document.CanSelectSuppliers(false);
        document.CanHaveRelatedDocuments(false);
        document.CanHaveSourceWarehouse(document.IsWarehouseEnabled);
        document.CauseLogicType.subscribe((value) => {
            const enabled = value === DocumentCauseLogicType.Transfer;
            document.CanHaveDestinationWarehouse(enabled && document.IsWarehouseEnabled);
        });
        document.CanHaveShippingInformation(true);
        document.CanHaveCause(false); // Questo serve per mostrare SOLO il campo causale sui carichi di magazzino. Per DDT e fattura accompagnatoria si deve settare CanHaveShippingInformation
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(false);
        document.CanHaveTaxReport(true);
        document.CanReferenceArticles(true);
        document.CanHaveVAT(true);
        document.CanCloseRows(true);
        document.CanHaveLetterOfAttempts(false);
        document.CanViewOldLetterOfAttempts(false);
        document.CanChangeDiscountCatalog(true);
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(false);
        document.CanEditRevisionNumber(false);
        document.CanHaveSchedules(false);
        document.CanExportElectronicInvoices(false);
        document.CanHaveState(false);
        document.CanSelectClosedLettersOfAttempt(false);
        document.CanHaveCIGCUP(false);
        document.MustCheckTrust(true);
        document.IsCustomerDocument(true);
        document.ShowVersionNumbersSuggestions(false);
        document.CanHaveMetadatas(document.MetadatasEnabled);
        document.RecipientPosition("right");

        document.ExcelExporterId("Invoices/DocumentExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.NuovoLabel);
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    print(documentId: number) {
        this.dialogsService.OpenUrlAsDialog("/Invoices/Print/Index/" + documentId, "Stampa DDT");
        /*var popup = window.open("about:blank", "_blank");
        popup.location.href = "/Service/Invoices/Print/Index/" + documentId;*/
    }
}
