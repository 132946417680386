import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { EditorFor } from "./ReportEditorProvider";
import { ReportComponentWithFlow } from "../Components/ReportFlowPanel";
import { SegmentedInput } from "../../SegmentedInput";

type ReportFlowEditorProps = {
    component: ReportComponentWithFlow;
}

export function ReportFlowEditor(props: ReportFlowEditorProps) {
    const C = require("./ReportFlowEditor")._ReportFlowEditor as typeof _ReportFlowEditor;
    return <C {...props} />;
}

@EditorFor("Flow")
export class _ReportFlowEditor {
    static defaultProps: Partial<ReportFlowEditorProps> = {
    }

    constructor(private props : ReportFlowEditorProps) {
        
    }
    
    render() {
        const { component } = this.props;

        return  <div className="editor-group">
                    <span className="editor-group-title">Layout</span>
                    <div className="editor-group-body">
                        <div className="row">
                            <div className="col-md-12">
                            <SegmentedInput label="Flusso" value={component.flowDirection} options={[{ icon: "fa fa-long-arrow-right", value: "leftToRight" }, { icon: "fa fa-long-arrow-left", value: "rightToLeft" }, { icon: "fa fa-long-arrow-down", value: "topToBottom",}, { icon: "fa fa-long-arrow-up", value: "bottomToTop" }]} />  
                            </div>
                        </div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportFlowEditor);
}