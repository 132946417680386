import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { MetadataType } from "../../../../Invoices/invoices/enums/MetadataType";
import {
    DocumentMetadatasDataSource,
    IDocumentMetadatasDataSourceModel,
} from "../../../../DataSources/DocumentMetadatasDataSource";
import { IDataSourceListener, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IFullMetadata, IMetadataMetadataListValues } from "../../../../Invoices/MetadataService";
import { If } from "../../../../Components/IfIfNotWith";
import { TextInput } from "../../../../Components/TextInput";
import { TextResources } from "../../../ProlifeTextResources";
import { NumberInput } from "../../../../Components/NumberInput";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import { CheckBox } from "../../../../Components/Checkbox";
import { IValidationService, IValidator } from "../../../ValidationService";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IMetadataSettingsManager } from "../../../../Invoices/invoices/settings/MetadataSettingsManager";
import { ProxyDataSource } from "../../../../DataSources/ProxyDataSource";
import { Select } from "../../../../Components/Select";
import { BaseDataSource } from "../../../../DataSources/BaseDataSource";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";

type MetadataValueInputRenderer = (readOnly: ko.Subscribable<boolean>) => React.ReactNode;
type MetadataValueInputRendererDictionary = { [type: string]: MetadataValueInputRenderer };

export interface IDocumentMetadata {
    Id: number;
    Order?: number;

    MetadataId: number;
    MetadataValue: number;
    MetadataListValues: IMetadataListValue[];
    MetadataValueType: MetadataType;
    ShowMarkerOnAllocationsGantt: boolean;
    PreloadOnDocument?: boolean;
}

export interface IMetadataListValue {
    Id: number;
    Label: string;
}

export class DocumentMetadata implements IDataSourceListener {
    Id: number;
    MetadataId: ko.Observable<number> = ko.observable();
    MetadataLabel: ko.Observable<string> = ko.observable();
    MetadataValue: ko.Observable<any> = ko.observable();
    MetadataValueLabel: ko.Observable<string> = ko.observable();
    MetadataValueType: ko.Observable<MetadataType> = ko.observable();
    MetadataListValues: MetadataListValuesDataSource = new MetadataListValuesDataSource();
    ShowMarkerOnAllocationsGantt: ko.Observable<boolean> = ko.observable();
    PreloadOnDocument: ko.Observable<boolean> = ko.observable(false);

    Order: ko.Observable<number> = ko.observable();

    SelectedMetadataChanged: ko.Observable<boolean> = ko.observable(true);

    MetadatasDataSource: ProxyDataSource<DocumentMetadatasDataSource, IDocumentMetadatasDataSourceModel>;

    Type: ko.Computed<string>;

    private inputRenderer: MetadataValueInputRendererDictionary = {};
    private validator: IValidator<DocumentMetadata>;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    @LazyImportSettingManager(nameof<IMetadataSettingsManager>())
    private metadatasSettingsManager: IMetadataSettingsManager;

    constructor(metadatasDataSource: DocumentMetadatasDataSource, metadata: IDocumentMetadata = null) {
        this.MetadatasDataSource = new ProxyDataSource(metadatasDataSource);

        this.Id = metadata?.Id;

        this.MetadataListValues.setMetadataId(metadata?.MetadataId);

        this.MetadataId(metadata?.MetadataId);
        this.MetadataValue(metadata?.MetadataValue);
        this.MetadataValueType(metadata?.MetadataValueType);
        this.ShowMarkerOnAllocationsGantt(metadata?.ShowMarkerOnAllocationsGantt ?? false);
        this.PreloadOnDocument(metadata?.PreloadOnDocument ?? false);
        this.Order(metadata?.Order);

        this.loadMetadataLabel();

        this.inputRenderer[MetadataType.Text] = this.renderTextInput.bind(this);
        this.inputRenderer[MetadataType.Integer] = this.renderIntInput.bind(this);
        this.inputRenderer[MetadataType.Decimal] = this.renderDecimalInput.bind(this);
        this.inputRenderer[MetadataType.DateTime] = this.renderDateTimeInput.bind(this);
        this.inputRenderer[MetadataType.Boolean] = this.renderBooleanInput.bind(this);
        this.inputRenderer[MetadataType.List] = this.renderListInput.bind(this);

        this.Type = ko.computed(() => {
            const type = this.MetadataValueType();
            if (!type) return "";

            return this.metadatasSettingsManager.getValueTypeLabel(type);
        });

        this.validator = this.validationService.createValidator<DocumentMetadata>();
        this.validator.isNotNullOrUndefined((m) => m.MetadataId(), TextResources.Invoices.MissingMetadata);
    }

    public initializeFrom(metadata: DocumentMetadata): void {
        this.Id = metadata.Id;

        this.MetadataListValues.setMetadataId(metadata.MetadataId());

        this.MetadataId(metadata.MetadataId());
        this.MetadataValue(metadata.MetadataValue());
        this.MetadataValueType(metadata.MetadataValueType());
        this.ShowMarkerOnAllocationsGantt(metadata.ShowMarkerOnAllocationsGantt());
        this.PreloadOnDocument(metadata.PreloadOnDocument());
        this.Order(metadata.Order());

        this.loadMetadataLabel();
    }

    private loadMetadataLabel() {
        const metadataId = this.MetadataId();
        if (!metadataId) return;

        const metadata = this.metadatasSettingsManager.getMetadata(metadataId);
        this.MetadataLabel(metadata?.Label);
    }

    onItemSelected(sender: DocumentMetadatasDataSource, model: IDocumentMetadatasDataSourceModel): void {
        this.SelectedMetadataChanged(false);

        if (!model) this.clear();
        else this.setData(model.model);

        this.SelectedMetadataChanged(true);
    }

    onItemDeselected(sender: DocumentMetadatasDataSource, model: IDocumentMetadatasDataSourceModel): void {
        this.SelectedMetadataChanged(false);
        this.clear();
        this.SelectedMetadataChanged(true);
    }

    validateAndShowInfoToast() {
        return this.validator.validateAndShowInfoToast(this);
    }

    renderValueInput(readOnly: ko.Subscribable<boolean>) {
        return (
            <If condition={() => !!this.MetadataValueType()}>
                {() => this.inputRenderer[this.MetadataValueType()](readOnly) as React.ReactElement}
            </If>
        );
    }

    private setData(metadata: IFullMetadata) {
        this.MetadataValue(null);
        this.MetadataValueLabel(null);
        this.MetadataValueType(metadata.ValueType as MetadataType);
        this.ShowMarkerOnAllocationsGantt(metadata.ShowMarkerOnAllocationsGantt);
        this.MetadataListValues.setMetadataId(metadata.Id);
    }

    private clear() {
        this.MetadataValue(null);
        this.MetadataValueLabel(null);
        this.MetadataValueType(null);
        this.ShowMarkerOnAllocationsGantt(false);
        this.PreloadOnDocument(false);
        this.MetadataListValues.setMetadataId(null);
    }

    private renderTextInput(readOnly: ko.Observable<boolean>) {
        return (
            <TextInput
                value={this.MetadataValue}
                placeholder={TextResources.ProlifeSdk.TextInputPlaceholder}
                readonly={readOnly}
                simple
                selectOnFocus
            />
        );
    }

    private renderIntInput(readOnly: ko.Observable<boolean>) {
        return (
            <NumberInput
                value={this.MetadataValue}
                placeholder={TextResources.ProlifeSdk.IntNumericInputPlaceholder}
                readOnly={readOnly}
                format="0,0"
                nullable
                simple
                selectOnFocus
            />
        );
    }

    private renderDecimalInput(readOnly: ko.Observable<boolean>) {
        return (
            <NumberInput
                value={this.MetadataValue}
                placeholder={TextResources.ProlifeSdk.NumericInputPlaceholder}
                readOnly={readOnly}
                nullable
                simple
                selectOnFocus
            />
        );
    }

    private renderDateTimeInput(readOnly: ko.Observable<boolean>) {
        return (
            <DateTimeInput
                value={this.MetadataValue}
                placeholder={TextResources.ProlifeSdk.DateTimeInputPlaceholder}
                readonly={readOnly}
                simple
                allowClear
                dateonly
            />
        );
    }

    private renderBooleanInput(readOnly: ko.Observable<boolean>) {
        return <CheckBox checked={this.MetadataValue} readOnly={readOnly} label="" />;
    }

    private renderListInput(readOnly: ko.Observable<boolean>) {
        return (
            <Select
                value={this.MetadataValue}
                dataSource={this.MetadataListValues}
                readOnly={readOnly}
                placeholder={TextResources.Invoices.MetadataListValuePlaceholder}
                className="metadata-list-value-selector"
                allowClear
                onSelect={(value) => this.MetadataValueLabel(value.model.Label)}
                onDeselect={() => this.MetadataValueLabel(null)}
            />
        );
    }
}

class MetadataListValuesDataSource extends BaseDataSource<IDataSourceModel<number, IMetadataListValue>> {
    private metadataId: number;

    @LazyImportSettingManager(nameof<IMetadataSettingsManager>())
    private metadatasSettingsManager: IMetadataSettingsManager;

    getTitle(currentModel: IDataSourceModel<number, IMetadataListValue>): string {
        return "";
    }

    async getData(
        currentModel: IDataSourceModel<number, IMetadataListValue>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<number, IMetadataListValue>[]> {
        const metadata = this.metadatasSettingsManager.getMetadata(this.metadataId);
        return (
            metadata?.ListValues.filter((lv) => TextFiltersUtilities.contains(lv.Label, textFilter)).map(
                this.createMetadataListValueDataSourceModel,
                this
            ) ?? []
        ).slice(skip, skip + count);
    }

    async getById(
        currentModel: IDataSourceModel<number, IMetadataListValue>,
        ids: number[]
    ): Promise<IDataSourceModel<number, IMetadataListValue>[]> {
        const metadata = this.metadatasSettingsManager.getMetadata(this.metadataId, true);
        const value = metadata?.ListValues.firstOrDefault((lv) => lv.Id === ids.firstOrDefault());
        return !value ? [] : [this.createMetadataListValueDataSourceModel(value)];
    }

    setMetadataId(id: number): void {
        this.metadataId = id;
    }

    private createMetadataListValueDataSourceModel(
        listValue: IMetadataMetadataListValues
    ): IDataSourceModel<number, IMetadataListValue> {
        return {
            id: listValue.Id,
            title: listValue.Label,
            isGroup: false,
            isLeaf: true,
            model: listValue,
        };
    }
}
