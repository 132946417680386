import * as ko from "knockout";
import * as moment from "moment";
import { Moment } from "moment";

export class DatePicker
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        (<any>window).moment.locale((<any>window).simpleCurrentCulture);

        var options = allBindingsAccessor();
        var initialValue = ko.utils.unwrapObservable(valueAccessor());
        var icon = null;
        var anchor = options.datePickerParent || null;

        var format = allBindingsAccessor()["customDateTimeFormat"] || "L";
        var dayViewHeaderFormat = allBindingsAccessor()["dayViewHeaderFormat"] || "MMMM YYYY";

        if(options.datePickerShowAsIcon) {
            anchor = $("<span style=\"position: relative; top: 20px; left: -20px\"></span>")
            icon = $("<span style=\"position: relative;\" class='" + options.datePickerShowAsIcon + "'></span>");
            icon.on('click', () => {
                var picker = (<any>$(element)).data("DateTimePicker");
                picker.date(null);
                picker.show();
            });
            icon.append(anchor);
            $(element).after(icon);
        }

        (<any>$(element)).datetimepicker({
            //autoclose: true,
            format: format,
            extraFormats: [format/*, 'YYYY-MM-DD[T]HH:mm:ssZ'*/],
            locale: (<any>moment).locale(),
            showTodayButton: true,
            dayViewHeaderFormat: dayViewHeaderFormat,
            showClose: options.dateTimeKeepInvalid || false,
            defaultDate: options.dateTimeKeepInvalid ? <any>"" : <any>(moment(initialValue).isValid() ? <any>moment(initialValue) : false),
            keepInvalid: options.dateTimeKeepInvalid || false,
            widgetParent: anchor,
            useCurrent : false
        });

        ko.utils.registerEventHandler(element, "dp.change", (ev : Event & { date: Moment }) => {
            var modelDate = ko.utils.unwrapObservable(valueAccessor());

            if(!ev.date) {
                valueAccessor()(options.datePickerValueAsString ? "" : null);
            }
            else if(modelDate == null || (ev.date.valueOf() != modelDate.valueOf()))
                valueAccessor()(options.datePickerValueAsString ? moment(ev.date).format("L") : ev.date.toDate());
        });

        var picker = $(element).data("DateTimePicker");

        if(options.onPickerShow)
            (<any>$(element)).on('dp.show', options.onPickerShow.bind(viewModel, picker));

        if(options.onPickerUpdate)
            (<any>$(element)).on('dp.update', options.onPickerUpdate.bind(viewModel, picker));
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = allBindingsAccessor();
        if(options.datePickerWriteOnly)
            return;

        var value = ko.utils.unwrapObservable(valueAccessor());
        if (!value)
            return;

        var picker = $(element).data("DateTimePicker");
        if(picker) {
            picker.date(value);
        }
    }
}

export class DateTimePicker
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        let options = allBindingsAccessor();
        options.format = options.format ?? "L LT";


        let initialValue = ko.utils.unwrapObservable(valueAccessor());
        if (!initialValue) {
            initialValue = moment().toDate();
            valueAccessor()(options.datePickerValueAsString ? <any>moment(initialValue).format(options.format) : moment(initialValue).toDate());
        }

        if (!!options.parent) {
            //widgetOptions.widgetParent = parent;

            $(element).on('dp.show', function (e) {
                console.log("NullableDatePicker opened", this);

                let datepicker = $('body').find('.bootstrap-datetimepicker-widget:last'),
                    position = datepicker.offset();
            
                // move datepicker to the exact same place it was but attached to body
                datepicker.appendTo("body");

                if(datepicker.hasClass("top"))
                    position.top -= 85;

                datepicker.css({
                    position: 'absolute',
                    top: position.top,
                    bottom: 'auto',
                    left: position.left,
                    right: 'auto',
                    zIndex: 1000000
                });
            
                // if datepicker is wider than the thing it is attached to then move it so the centers line up
                /*if (parentPos.left + parentWid < position.left + width) {
                    var newLeft = parentPos.left;
                    newLeft += parentWid / 2;
                    newLeft -= width / 2;
                    datepicker.css({left: newLeft});
                }*/
            })
        }

        (<any>$(element)).datetimepicker({
            //autoclose: true,
            format: options.format,
            locale: (<any>moment).locale(),
            widgetPositioning: { horizontal: 'auto', vertical: 'auto' },
            showTodayButton: true,
            showClear: true,
            defaultDate: initialValue,
            useCurrent : false
        });

        ko.utils.registerEventHandler(element, "dp.change", (ev : Event & { date: Moment, oldDate: Date }) => {
            var modelDate = ko.utils.unwrapObservable(valueAccessor());

            if(!ev.date) {
                if (options.datePickerValueAsString)
                    valueAccessor()(!ev.oldDate ? "" : moment(ev.oldDate).format(options.format));
                else
                    valueAccessor()(!ev.oldDate ? null : moment(ev.oldDate).toDate());
            }
            else if(modelDate == null || (ev.date.valueOf() != modelDate.valueOf()))
                valueAccessor()(options.datePickerValueAsString ? moment(ev.date).format(options.format) : ev.date.toDate());
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = allBindingsAccessor();
        if(options.datePickerWriteOnly)
            return;

        var picker = $(element).data("DateTimePicker");
        if(!picker)
            return;

        var value = ko.utils.unwrapObservable(valueAccessor());
        /*if (!value) {

         return;
         }*/

        picker.date(value);
    }
}

ko.bindingHandlers["datePicker"] = ko.bindingHandlers["datepicker"] = new DatePicker();
ko.bindingHandlers["dateTimePicker"] = ko.bindingHandlers["datetimepicker"] = new DateTimePicker();