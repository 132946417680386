import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../interfaces/desktop/IChangesNotificationsService";
import { IEntitiesLockService, ILockableItem } from "../../../interfaces/desktop/IEntitiesLockService";
import { Deferred } from "../../../../Core/Deferred";

export class EntitiesListLockManager implements IChangesNotificationsServiceObserver {
    @LazyImport(nameof<IEntitiesLockService>())
    private lockService: IEntitiesLockService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    private LockedEntitiesIds: number[] = [];
    private IsInitialized: boolean = false;

    constructor(private itemsList: ko.ObservableArray<ILockableItem>, private entityType: string) {
        itemsList.subscribe(this.RefreshItemsLockStatus.bind(this));
    }

    public WhenIsReady(): Promise<void> {
        var def = new Deferred<void>();

        if (this.IsInitialized) return def.resolve().promise();

        this.lockService.GetLockedEntitiesIds(this.entityType).then((lockedIds: number[]) => {
            this.LockedEntitiesIds = lockedIds;
            this.IsInitialized = true;
            this.RefreshItemsLockStatus();

            //Inizio l'ascolto sugli eventi di lock!
            this.changesNotificationsService.ObserveNotificationsFor(this.entityType, this);
            def.resolve();
        });

        return def.promise();
    }

    public Dispose() {
        //E' importante invocarla altrimenti l'elemento rimane sempre in ascolto di notifiche
        this.changesNotificationsService.RemoveObserver(this);
        this.IsInitialized = false;
        this.LockedEntitiesIds = [];
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (sendByMe || (changesInfo.Action != 3 && changesInfo.Action != 4)) return false;

        if (changesInfo.Action == 3 && this.LockedEntitiesIds.indexOf(changesInfo.EntityKeyId) == -1) this.LockedEntitiesIds.push(changesInfo.EntityKeyId);
        else if (changesInfo.Action == 4 && this.LockedEntitiesIds.indexOf(changesInfo.EntityKeyId) > -1)
            this.LockedEntitiesIds.splice(this.LockedEntitiesIds.indexOf(changesInfo.EntityKeyId), 1);

        this.RefreshItemsLockStatus();
        return false;
    }

    private RefreshItemsLockStatus() {
        this.itemsList().forEach((i: ILockableItem) => {
            var mustBeLocked: boolean = this.LockedEntitiesIds.indexOf(i.ItemKey) > -1;

            if (mustBeLocked && !i.IsItemLocked()) i.IsItemLocked(true);

            if (!mustBeLocked && i.IsItemLocked()) i.IsItemLocked(false);
        });
    }
}
