import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import TsxForEach from "./ForEach";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { IFileRepositoryService } from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { LazyImport } from "../Core/DependencyInjection";
import { IFileOrFolder } from "../ProlifeSdk/interfaces/files/IFileOrFolder";
import { If, IfNot } from "./IfIfNotWith";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { Portlet } from "./Portlet";
import { IAjaxServiceNew } from "../Core/interfaces/IAjaxService";

const { classes } = jss.createStyleSheet({
    fileContainer : {
        "&.file-drop-activated:not(.readonly)": {
            outline: "1px dashed #AAA"
        }
    }
}).attach();

type FileContainerProps = {
    files: ko.ObservableArray<string>;
    readOnly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean>;
    className?: string;
    collapsible?: boolean;
    initialCollapsed?: boolean;
}

export function FileContainer(props: FileContainerProps) {
    const C = require("./FileContainer")._FileContainer as typeof FileContainer;
    return <C {...props} />;
}

export class _FileContainer {
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService : IFileRepositoryService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew : IAjaxServiceNew;

    public filesData: ko.ObservableArray<IFileOrFolder> = ko.observableArray();

    public IsExpanded: ko.Observable<boolean> = ko.observable(false);

    public ReadOnly: ko.Computed<boolean>;

    static defaultProps: Partial<FileContainerProps> = {
        readOnly: false,
        collapsible: false,
        initialCollapsed: false
    }

    constructor(private props : FileContainerProps) {
        this.loadData();
        this.ReadOnly = ComponentUtils.getComputed(this.props.readOnly);
        this.IsExpanded(!this.props.initialCollapsed);
    }

    async loadData(){
        let data = await this.fileRepositoryService.getFilesDetails(this.props.files());
        this.filesData(data);
    }

    getDownloadUrl(item: IFileOrFolder): string {
        return this.fileRepositoryService.getDownloadUrl(item, true);
    }

    download(item: IFileOrFolder) {
        this.ajaxServiceNew.DownloadFileFromUrl(this.fileRepositoryService.getDownloadUrl(item, true), {});
    }

    toggleExpand(){
        this.IsExpanded(!this.IsExpanded());
    }

    async addNew(){
        let files = await this.fileRepositoryService.openAsDialog("/");
        this.filesData.push(...files);
        this.props.files.push(...files.map(f => f.Id));
    }

    remove(item: IFileOrFolder){
        this.dialogsService.Confirm(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem, (result) => {
            if (result) {
                this.props.files.remove(item.Id);
                this.filesData.remove(item);
            }
        });
    }

    async uploadFiles(file: File){
        if (!this.ReadOnly()){
            let result = await this.fileRepositoryService.upload("/", file, file.name);
            if (result.UploadSuccess){
                this.filesData.push(await this.fileRepositoryService.getFileDetails(result.FileId));
                this.props.files.push(result.FileId);
            }
        }
    }

    renderFilePreview(item: IFileOrFolder){
        const previewStyle = this.fileRepositoryService.getPreviewStyle(item);
        return <a className={this.IsExpanded() ? "" : "flex-container"} onClick={() => this.download(item)}>
                <If condition={() => item.HasPreview}>
                    {() => {
                        let previewUrl = this.fileRepositoryService.getPreviewUrl(item);
                        return <span className={"icon " + previewStyle} style={{backgroundImage: "url('" + previewUrl + "')"}}></span>;
                    }}
                </If>
                <IfNot condition={() => item.HasPreview}>
                    {() => (
                        <span className={"icon " + previewStyle}></span>
                    )}
                </IfNot>
                </a>;
    }
    
    render() {
        const fileContainer = this;

        return ComponentUtils.bindTo((
            
            <div className="flex-container flex-vertical flex-1">
                <div className="row no-gutters">
                    <div className="col-md-12">
                        <div className="flex-container">
                        <button className="btn btn-xs btn-transparent" style={{margin: "0"}} data-bind={{click: fileContainer.toggleExpand.bind(fileContainer)}}>
                            <i className="fa" data-bind={{ css: { 'fa-caret-right': !fileContainer.IsExpanded(), 'fa-caret-down': fileContainer.IsExpanded() }}}></i>
                        </button>
                        <span className="flex-container flex-child-center" style={{marginRight: "10px"}}>{TextResources.ProlifeSdk.Attachments}</span>
                            <IfNot condition={fileContainer.IsExpanded}>
                                {() => (
                                    <TsxForEach data={fileContainer.filesData} as={"item"}>
                                        {(item: IFileOrFolder) => (
                                            <span className="flex-container" style={{margin: "5px 5px 5px 0", height: "30px"}}>
                                                {this.renderFilePreview(item)}
                                                <span className="flex-container flex-child-center" data-bind={{text: item.Name}}></span>
                                            </span>
                                        )}
                                    </TsxForEach>
                                )}
                            </IfNot>
                            <If condition={() => fileContainer.IsExpanded() && !fileContainer.ReadOnly()}>
                                {() => <button className="btn btn-primary btn-sm btn-circle" style={{margin: "0", marginLeft: "auto"}} data-bind={{click: fileContainer.addNew.bind(fileContainer)}}><i className="fa fa-plus"></i></button>}
                            </If>
                        </div>
                    </div>
                </div>
                <If condition={fileContainer.IsExpanded}>
                    {() => (
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{minHeight: "50px"}} className={ComponentUtils.classNames(classes.fileContainer, "listview")} data-bind={{fileDrop: fileContainer.uploadFiles.bind(fileContainer), css: {"readonly": fileContainer.ReadOnly}}}>
                                <TsxForEach data={fileContainer.filesData} as={"item"}>
                                    {(item: IFileOrFolder) => (
                                        <div className="list">
                                            <div className="list-content">
                                                {this.renderFilePreview(item)}
                                                <div className="data">
                                                    <span className="list-title">
                                                        {item.Name}
                                                    </span>
                                                    <span className="list-remark">
                                                        <span data-bind={{calendarDateTimeText: item.ModifiedDate}}/>
                                                        &nbsp;
                                                        <span data-bind={{userText: item.ModifiedBy}}/>
                                                    </span>
                                                    <IfNot condition={() => item.IsFolder}>
                                                        {() => (
                                                            <span className="list-remark">
                                                                <span data-bind={{numberText: item.Size, format: '0,0[.]0 b'}}/>
                                                                &nbsp;
                                                                <span data-bind={{text: ("Versione: " + item.Version)}}/>
                                                            </span>
                                                        )}
                                                    </IfNot>
                                                    <ko-bind data-bind={{ifnot: fileContainer.ReadOnly}}>
                                                        <span style={{margin: "0"}} className="btn btn-xs btn-circle btn-danger pull-right" data-bind={{click: fileContainer.remove.bind(fileContainer, item)}}><i className="fa fa-unlink"></i></span>
                                                    </ko-bind>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </TsxForEach>
                                </div>
                            </div>
                        </div>
                    )}
                </If>
            </div>
        ), this, "fileContainer");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(FileContainer);
}