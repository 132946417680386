import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { LetterOfAttempt, LettersOfAttemptsDataSource } from "../../../DataSources/LettersOfAttemptsDataSource";
import { LetterOfAttemptsInvoicesDataSource } from "../../../DataSources/LetterOfAttemptInvoicesDataSource";
import { IListComponent } from "../../../Components/ListComponent";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { ILetterOfAttemptInvoice, ILetterOfAttemptForEditor } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";

export class LettersOfAttemptsManager implements IDataSourceListener {
    public ShowClosed: ko.Observable<boolean> = ko.observable();
    
    public LettersOfAttemptsDataSource: LettersOfAttemptsDataSource;
    public LetterOfAttemptInvoicesDataSource: LetterOfAttemptsInvoicesDataSource;

    public LettersOfAttemptsList: ko.Observable<IListComponent<number, LetterOfAttempt>> = ko.observable();
    public LetterOfAttemptInvoicesList: ko.Observable<IListComponent<number, ILetterOfAttemptInvoice>> = ko.observable();
    
    public EmptyList: ko.Computed<boolean>;
    public EmptyInvoicesList: ko.Computed<boolean>;
    public EmptyInvoicesListMessage: ko.Computed<string>;
    
    public InvoicesTotal: ko.Observable<number> = ko.observable(0);
    
    private InvoicesTotalInterceptor: ko.Computed<void>;
    private SelectedLetterOfAttemptId: ko.Observable<number> = ko.observable();

    private pendingLettersOfAttemptListRefresh = false;
    private pendingLetterOfAttemptInvoicesListRefresh = false;

    constructor(private customerId: number) {
        this.LettersOfAttemptsDataSource = new LettersOfAttemptsDataSource();
        this.LettersOfAttemptsDataSource.setCustomerId(this.customerId);
        this.LettersOfAttemptsDataSource.setReferenceDate(moment().startOf("day").toDate());
        this.LettersOfAttemptsDataSource.setShowClosed(false);
        this.LettersOfAttemptsDataSource.setShowOnlyValidAtReferenceDate(false);

        this.LetterOfAttemptInvoicesDataSource = new LetterOfAttemptsInvoicesDataSource();
        
        this.ShowClosed.subscribe((value: boolean) => {
            this.LettersOfAttemptsDataSource.setShowClosed(value);
            if (!this.LettersOfAttemptsList()) {
                this.pendingLettersOfAttemptListRefresh = true;
            } else {
                this.LettersOfAttemptsList().refresh();
            }
        });

        this.LettersOfAttemptsList.subscribe((list: IListComponent<number, LetterOfAttempt>) => {
            if (this.pendingLettersOfAttemptListRefresh) {
                this.pendingLettersOfAttemptListRefresh = false;
                list.refresh();
            }
        });

        this.LetterOfAttemptInvoicesList.subscribe((list: IListComponent<number, ILetterOfAttemptInvoice>) => {
            if (this.pendingLetterOfAttemptInvoicesListRefresh) {
                this.pendingLetterOfAttemptInvoicesListRefresh = false;
                list.refresh();
            }
        });

        this.EmptyList = ko.computed(() => {
            return !this.LettersOfAttemptsList() || this.LettersOfAttemptsList().ItemsCount() === 0;

        });
        
        this.EmptyInvoicesList = ko.computed(() => {
            return !this.LetterOfAttemptInvoicesList() || this.LetterOfAttemptInvoicesList().ItemsCount() === 0;

        });

        this.EmptyInvoicesListMessage = ko.computed(() => {
            return !this.SelectedLetterOfAttemptId() ? ProlifeSdk.TextResources.Customers.SelectALetterOfAttempt : (this.EmptyInvoicesList() ? ProlifeSdk.TextResources.ProlifeSdk.ListComponentEmptyResult : "");
        });

        this.InvoicesTotalInterceptor = ko.computed(() => {
            if (!this.LetterOfAttemptInvoicesList() || !this.LetterOfAttemptInvoicesList().Items()) {
                this.InvoicesTotal(0);
                return;
            }

            this.updateInvoicesTotal();
        });

        this.ShowClosed(false);
    }

    public getModifiedLetters(): ILetterOfAttemptForEditor[] {
        return this.LettersOfAttemptsDataSource.getModifiedData().map((l) => l.getData());
    }

    public createNew(): void {
        this.LettersOfAttemptsDataSource.createNew(this.customerId);
    }

    public getValidationWarnings(): string[] {
        let warnings: string[] = [];

        const list = this.LettersOfAttemptsList();
        if (!list)
            return warnings;

        list.Items()
            .forEach((l) => {
                const warning = l.dataSourceModel.viewModel.getValidationWarnings();
                
                if (warning.length === 0)
                    return;
                
                warnings = warnings.concat(warning);
            });

        return warnings;
    }

    public isValid(): boolean {
        let isValid = true;

        this.LettersOfAttemptsDataSource
            .getModifiedData()
            .forEach((l) => {
                isValid = isValid && l.validate();
            });

        return isValid;
    }

    public refresh(): void {
        this.LettersOfAttemptsDataSource.reset();
    }

    public hasChanges(): boolean {
        return this.LettersOfAttemptsDataSource.getModifiedData().length > 0;
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.LettersOfAttemptsDataSource) {
            const letterId: number = !model ? null : model.id as number;
            this.SelectedLetterOfAttemptId(letterId);

            this.LetterOfAttemptInvoicesDataSource.setLetterOfAttemptId(letterId);
            
            if (!this.LetterOfAttemptInvoicesList()) {
                this.pendingLetterOfAttemptInvoicesListRefresh = true;
            } else {
                this.LetterOfAttemptInvoicesList().refresh();
            }
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.LettersOfAttemptsDataSource) {
            this.SelectedLetterOfAttemptId(undefined);
            this.LetterOfAttemptInvoicesDataSource.setLetterOfAttemptId(undefined);
            
            if (!this.LetterOfAttemptInvoicesList()) {
                this.pendingLetterOfAttemptInvoicesListRefresh = true;
            } else {
                this.LetterOfAttemptInvoicesList().refresh();
            }
        }
    }

    private async updateInvoicesTotal(): Promise<void> {
        const letters = await this.LetterOfAttemptInvoicesDataSource.getData(null, null);
        this.InvoicesTotal(letters.sum(l => (l.model.InvoicePriceForLetterOfAttempt || 0)));
    }
}