import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 15/06/2017
 * Time: 16:17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { AnswerForWizard } from "./AnswerForWizard";
import { Question } from "./Question";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    ISurveyWizardQuestion,
    ISurveyWizardAnswer,
    ISurveyWizardService,
    ISurveyWizard,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ISelectionObserver } from "../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { IQuestion } from "../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { IAnswerForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerForWizard";

export class QuestionForWizard extends Question implements ISurveyWizardQuestion {
    public Answers: ko.ObservableArray<ISurveyWizardAnswer> = ko.observableArray([]);
    public AnswersNumber: ko.Computed<number>;

    public Selected: ko.Observable<boolean> = ko.observable();
    public Saved: ko.Observable<boolean> = ko.observable(false);

    private initialState: boolean;

    public SelectedAnswers: ko.Computed<ISurveyWizardAnswer[]>;
    public CanGoNext: ko.Computed<boolean>;

    public IsSingleAnswerQuestion: ko.Computed<boolean>;
    public IsMultipleAnswerQuestion: ko.Computed<boolean>;
    public IsOpenAnswerQuestion: ko.Computed<boolean>;

    public HasNumericResult: ko.Computed<boolean>;
    public HasTextResult: ko.Computed<boolean>;
    public HasDateResult: ko.Computed<boolean>;
    public HasOpenAnswers: ko.Computed<boolean>;
    public WithoutAnswers: ko.Computed<boolean>;
    public HasAnswersWithGoTo: ko.Computed<boolean>;
    public HasAnswersWithEnable: ko.Computed<boolean>;
    public Scroll: ko.Computed<boolean>;

    public EnableScroll = true;
    public DoScroll: ko.Observable<boolean> = ko.observable(false);

    private selectionObservers: ISelectionObserver[] = [];

    private surveyWizardService: ISurveyWizardService;
    private infoToastService: IInfoToastService;

    private campaignId: number;
    private peopleId: number;

    constructor(private serviceLocator: IServiceLocator, private question: IQuestion, private wizard: ISurveyWizard) {
        super(question);
        this.surveyWizardService = <ISurveyWizardService>(
            this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType)
        );
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Selected(false);

        this.initialState = question.Enabled;

        this.campaignId = this.wizard.resume ? this.wizard.Campaign().Id : null;
        this.peopleId = this.wizard.resume ? this.wizard.People().Id : null;

        this.SelectedAnswers = ko.computed(() => {
            return this.Answers().filter((a: ISurveyWizardAnswer) => a.Checked());
        });

        this.AnswersNumber = ko.computed(() => {
            return this.Answers().length;
        });

        this.WithoutAnswers = ko.computed(() => {
            return this.AnswersNumber() == 0;
        });

        this.CanGoNext = ko.computed(() => {
            return (this.SelectedAnswers().length != 0 && this.answersAreComplete()) || this.WithoutAnswers();
        });

        this.IsMultipleAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.MultipleAnswerQuestionType;
        });

        this.IsSingleAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.SingleAnswerQuestionType;
        });

        this.IsOpenAnswerQuestion = ko.computed(() => {
            return this.TypeId() == ProlifeSdk.OpenAnswerQuestionType;
        });

        this.HasDateResult = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerDateField && this.AnswersNumber() > 0;
        });

        this.HasNumericResult = ko.computed(() => {
            return (
                (this.AnswerTypeId() == ProlifeSdk.AnswerNumericField ||
                    this.AnswerTypeId() == ProlifeSdk.AnswerMoneyField) &&
                this.AnswersNumber() > 0
            );
        });

        this.HasTextResult = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerTextField && this.AnswersNumber() > 0;
        });

        this.HasOpenAnswers = ko.computed(() => {
            return (
                this.Answers().filter((a: ISurveyWizardAnswer) => {
                    return a.IsOpen();
                }).length > 0
            );
        });

        this.HasAnswersWithEnable = ko.computed(() => {
            return (
                this.Answers().filter((a: ISurveyWizardAnswer) => {
                    return !!a.EnablePublicLabel();
                }).length > 0
            );
        });

        this.HasAnswersWithGoTo = ko.computed(() => {
            return (
                this.Answers().filter((a: ISurveyWizardAnswer) => {
                    return !!a.GoToPublicLabel();
                }).length > 0
            );
        });

        this.Scroll = ko.computed(() => {
            return this.DoScroll() && this.EnableScroll;
        });
    }

    private answersAreComplete(): boolean {
        let complete = true;
        this.SelectedAnswers().forEach((a: ISurveyWizardAnswer) => {
            if (!a.IsComplete()) complete = false;
        });
        return complete;
    }

    public UncheckSingleAnswers(selectedAnswerId: number): void {
        this.Answers().forEach((a: ISurveyWizardAnswer) => {
            if (a.Id != selectedAnswerId) a.Checked(false);
        });
    }

    public RegisterSelectionObserver(observer: ISelectionObserver) {
        this.selectionObservers.push(observer);
    }

    public NotifySelection() {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectQuestion(this);
        });
    }

    public NextQuestion(): void {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectNextQuestion(this);
        });
    }

    public PreviousQuestion(): void {
        this.selectionObservers.forEach((observer: ISelectionObserver) => {
            observer.selectPreviousQuestion(this);
        });
    }

    public ResetState(): void {
        this.Enabled(this.initialState);
        this.Saved(false);
    }

    public LoadAnswers(): Promise<IAnswerForWizard[]> {
        return this.surveyWizardService
            .getQuestionAnswersForWizard(
                this.question.Id,
                !!this.wizard.resume,
                this.wizard.Questionnaire().Id,
                this.campaignId,
                this.peopleId
            )
            .then((answers: IAnswerForWizard[]) => {
                if (answers.length == 0) return [];
                const isUniqueAnswer = answers.length == 1;
                this.Answers(
                    answers.map((answer: IAnswerForWizard) => {
                        return new AnswerForWizard(this.serviceLocator, answer, isUniqueAnswer, this, this.wizard);
                    })
                );
                this.SelectDefaultAnswer();
                return answers;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadAnswersError);
                return [];
            });
    }

    private SelectDefaultAnswer(): void {
        if (this.SelectedAnswers().length != 0) {
            this.Saved(true);
            return;
        }
        if (this.AnswersNumber() == 1 && this.IsSingleAnswerQuestion() && this.SelectedAnswers().length == 0)
            this.Answers()[0].Checked(true);
    }

    public Select(): void {
        if (this.Saved() || (!!this.wizard.LastQuestion && this.wizard.LastQuestion.Id == this.Id)) {
            this.EnableScroll = false;
            this.wizard.selectQuestion(this);
        }
    }
}
