import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { IMonthlyHour, IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { ISelectableDate } from "./IDate";
import { HourApprovalViewModel } from "./HourApprovalViewModel";
import { JobOrderApprovalViewModel } from "./JobOrderApprovalViewModel";
import { _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";
import { HoursContainer } from "./HoursContainer";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");

export class ResourceApprovalViewModel extends HoursContainer<JobOrderApprovalViewModel> {
    public hasMaxAnnualHoursAmountOverflow: boolean;
    public hasMinAnnualHoursAmountNotReached: boolean;

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;

    constructor(hour: IMonthlyHour, private dates: ISelectableDate[], panel: _HoursApprovalPanelViewModel) {
        super(panel, null);

        this.id = hour.ResourceId;
        this.type = ProlifeSdk.HumanResourceEntityTypeCode;
        this.name = hour.ResourceName;
        this.hasMinAnnualHoursAmountNotReached = hour.HasMinAnnualHoursAmountNotReached;
        this.hasMaxAnnualHoursAmountOverflow = hour.HasMaxAnnualHoursAmountOverflow;

        for (const date of this.dates) {
            const approval = new HourApprovalViewModel({
                id: this.id,
                date: date.Date,
                isHoliday: date.IsHoliday,
                resourceId: this.id
            }, date, this, this.panel);

            this.Hours.push(approval);
        }
    }

    public initialize(): void {
        super.initialize();

        const key = this.getKeyForCache();

        const collapsedValue = this.panel.getCollapsedStateFromCache(key);
        if (collapsedValue != undefined)
            this.Collapsed(collapsedValue);

        const selectedValue = this.panel.getRowSelectionStateFromCache(key);
        this.Selected(selectedValue);

        this.Collapsed.subscribe((value: boolean) => {
            if (!this.id)
                return;

            this.panel.cacheCollapsedState(this.getKeyForCache(), value);
        });

        this.Selected.subscribe((value: boolean) => {
            if (!this.id || this.panel.IsLoading())
                return;

            this.panel.cacheRowSelection(this.getKeyForCache(), value);
        });
    }

    private async showWorkTimeCategoriesRemainingBudgetInfo(): Promise<void> {
        const from = this.dates.min((d) => d.Date.valueOf());
        const to = this.dates.max((d) => d.Date.valueOf());
        await this.workedHoursService.ShowResourceServiceOrdersSalariesBudgetsStatusDialog(this.id, moment(from).toDate(), moment(to).toDate());
    }

    alerts() {
        return  <>
                    {this.hasMinAnnualHoursAmountNotReached && (
                        <span className="min-hours-alert" onClick={(e) => { this.showWorkTimeCategoriesRemainingBudgetInfo(); e.stopPropagation(); }} title={TextResources.WorkedHours.MinHoursBudgetAlertTooltip}>
                            <i className="fa fa-exclamation-triangle"></i>
                        </span>
                    )}
                    {this.hasMaxAnnualHoursAmountOverflow && (
                        <span className="max-hours-alert" onClick={(e) => { this.showWorkTimeCategoriesRemainingBudgetInfo(); e.stopPropagation(); }} title={TextResources.WorkedHours.MaxHoursBudgetAlertTooltip}>
                            <i className="fa fa-exclamation-circle"></i>
                        </span>
                    )}
                </>;
    }
}
