import * as ko from "knockout";
/// <reference path="../../interfaces/settings/IJobOrderBlockMotivationsObserver.ts" />

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { JobOrderBlockMotivationsEditingViewModel } from "./ui/JobOrderBlockMotivationsEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IJobOrderBlockMotivation } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";
import { IJobOrderBlockMotivationsObserver } from "../../interfaces/settings/IJobOrderBlockMotivationsObserver";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class JobOrderBlockMotivationsSettingsManager implements IJobOrderBlockMotivationsSettingsManager
{
    private ajaxService : IAjaxService;
    private modulesService : IModulesService;
    private motivations : IJobOrderBlockMotivation[];
    private observers : IJobOrderBlockMotivationsObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.JobOrder.OrdersSettingsGroup);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    getMotivations() : IJobOrderBlockMotivation[]
    {
        return this.motivations || [];
    }

    getMotivationById(id : number) : Promise<IJobOrderBlockMotivation> {
        var types : IJobOrderBlockMotivation[] = this.motivations.filter((t : IJobOrderBlockMotivation) => t.Id == id);

        if(types.length > 0)
            return Promise.resolve<IJobOrderBlockMotivation>(types[0]);

        //Nel caso non trovi l'elemento in cache lo chiedo al server
        return this.ajaxService.Get("JobOrder-api", "BlockMotivations/GetBlockMotivationById/" + id, {});
    }

    load(): Promise<IJobOrderBlockMotivation[]>
    {
        return this.ajaxService.Get<IJobOrderBlockMotivation[]>("JobOrder-api", "BlockMotivations", {})
            .then((motivations: IJobOrderBlockMotivation[]) => this.motivations = motivations);
    }

    getName(): string
    {
        return ProlifeSdk.JobOrderBlockMotivation;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.JobOrder.OrderCustomerLock;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) || false;
    }

    getEditingUI(): IView
    {
        return {
            templateName: "job-order-block-motivations",
            templateUrl: "jobOrder/templates/settings",
            viewModel: new JobOrderBlockMotivationsEditingViewModel(this.serviceLocator, this)
        };
    }

    createOrUpdate(motivation : IJobOrderBlockMotivation) : Promise<IJobOrderBlockMotivation>
    {
        return this.ajaxService.Post<IJobOrderBlockMotivation>("JobOrder-api", "BlockMotivations", { methodData: motivation })
            .then((m: IJobOrderBlockMotivation) => {
                if(motivation.Id != null)
                    this.onMotivationChanged(m);
                else
                    this.onMotivationAdded(m);
                return m;
            });
    }

    remove(id : number) : Promise<void>
    {
        return this.ajaxService.Delete<void>("JobOrder-api", "BlockMotivations/" + id, {})
            .then(this.onMotivationDeleted.bind(this, id));

    }

    private getIndexOf(id : number) : number
    {
        var item = this.motivations.filter((t : IJobOrderBlockMotivation) => t.Id == id)[0];
        return this.motivations.indexOf(item);
    }

    private onMotivationChanged(m : IJobOrderBlockMotivation)
    {
        var index : number = this.getIndexOf(m.Id);
        this.motivations[index] = m;
        this.observers.forEach((obs) => obs.onChanged(m));
    }

    private onMotivationAdded(m : IJobOrderBlockMotivation)
    {
        this.motivations.push(m);
        this.observers.forEach((obs) => obs.onAdded(m));
    }

    private onMotivationDeleted(id : number)
    {
        var index : number = this.getIndexOf(id);
        this.motivations.splice(index, 1);
        this.observers.forEach((obs) => obs.onDeleted(id));
    }
}

