import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { CheckBox } from "../Checkbox";
import { ListItem, List } from "../ListComponent";
import { Table } from "../TableComponent/TableComponent";
import { IPrintTemplate, IPrintTemplateSettingsManager, IPrintTemplateWithPreferred } from "../../Invoices/invoices/settings/PrintTemplateSettingsManager";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IVatRegisters } from "../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

const styleSheet = jss.createStyleSheet({
    reportPrintSelector: {
        border: '1px solid #e5e5e5',
        minWidth: '400px',

        "& .list-notification-item-details": {
            paddingLeft: '10px',
            paddingRight: '10px'
        },

        "&.list-notification-container .list-notification .list-notification-item:last-child": {
            borderBottom: '1px solid #e5e5e5',
        }
    }
});
const { classes } = styleSheet.attach();

type ReportPrintSelectorProps = {
    registerId: number;
    documentId: number;
    onPrint: (templateId: number) => Promise<void>;
}

export function ReportPrintSelector(props: ReportPrintSelectorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./ReportPrintSelector")._ReportPrintSelector as typeof _ReportPrintSelector;
    return <C {...props} />;
}

export class _ReportPrintSelector {
    static defaultProps: Partial<ReportPrintSelectorProps> = {
    }

    @LazyImportSettingManager(nameof<IPrintTemplateSettingsManager>())
    private printTemplateSettingsManager : IPrintTemplateSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters : IVatRegisters;

    printTemplates : ko.ObservableArray<IPrintTemplateWithPreferred> = ko.observableArray();

    constructor(private props : ReportPrintSelectorProps) {
        
    }
    
    componentDidMount() {
        const vatRegisterInfo = this.vatRegisters.getVatRegisterById(this.props.registerId);
        if(vatRegisterInfo.printTemplates.length === 0)
            return;

        const allPrintTemplates = this.printTemplateSettingsManager.getPrintTemplates().filter(pt => vatRegisterInfo.printTemplates.indexOf(pt.id) !== -1);
        this.printTemplates(allPrintTemplates.map(p => ({
            ...p,
            preferred: p.id === vatRegisterInfo.preferredPrintTemplateId
        })));
    }

    renderItem(item: ListItem<number, IPrintTemplate>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _rps = this;
        return  <div className="flex-container flex-child-center">
                    <span>{item.Title()}</span>
                    <button className="btn btn-xs btn-circle blue" style={{ marginLeft: 'auto' }} onClick={() => this.props.onPrint(item.Model.id)}>
                        <i className="fa fa-print" />&nbsp;{TextResources.ProlifeSdk.Print}
                    </button>
                </div>;
    }

    render() {
        return <List 
                    containerHeight="180px"
                    scrollable={false}
                    systemScrollable
                    className={classNames(classes.reportPrintSelector, "flex-1")} 
                    dataSource={Table.defaultDataSource(this.printTemplates, (i) => ({ id: i.id, title: i.title, model: i }))} 
                    emptyResultMessage="Nessun modello di stampa configurato"
                    itemRenderer={this.renderItem.bind(this)}/>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportPrintSelector);
}