import { IArticlePurchase } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import {
    IPurchaseTypesDataSourceModel,
    PurchaseTypesDataSource,
} from "../../../../DataSources/PurchaseTypesDataSource";
import { IDataSourceListener } from "../../../../DataSources/IDataSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ICatalogsService } from "../../../../ProlifeSdk/interfaces/warehouse/ICatalogsService";

export class Purchase implements IDataSourceListener {
    @LazyImport(nameof<ICatalogsService>())
    private catalogsService: ICatalogsService;

    get id(): number {
        return this.purchase.id;
    }

    CatalogName: ko.Observable<string> = ko.observable("");
    Code: ko.Observable<string> = ko.observable("");
    Description: ko.Observable<string> = ko.observable("");
    FKPurchaseType: ko.Observable<number> = ko.observable(null);
    PurchaseType: ko.Observable<string> = ko.observable("");
    Uom: ko.Observable<string> = ko.observable("");
    DealerPrice: ko.Observable<number | null> = ko.observable(null);
    CustomerPrice: ko.Observable<number | null> = ko.observable(null);
    MeanCost: ko.Observable<number | null> = ko.observable(null);
    Currency: ko.Observable<string> = ko.observable("");

    public PurchaseTypesDataSource: PurchaseTypesDataSource = new PurchaseTypesDataSource();

    constructor(private purchase: IArticlePurchase) {
        this.loadData(purchase);
        this.loadCatalogName();
    }

    private loadData(purchase: IArticlePurchase) {
        this.Code(purchase.code);
        this.Description(purchase.description);
        this.FKPurchaseType(purchase.fkPurchaseType);
        this.PurchaseType(purchase.purchaseType);
        this.Uom(purchase.uom);
        this.DealerPrice(purchase.dealerPrice);
        this.CustomerPrice(purchase.customerPrice);
        this.MeanCost(purchase.meanCost);
        this.Currency(purchase.currency);
    }

    private async loadCatalogName() {
        const catalogs = await this.catalogsService.getCatalogs("", -1, 0, 1);
        const catalog = catalogs[0];

        this.CatalogName(catalog.Description);
    }

    onItemSelected(_: PurchaseTypesDataSource, model: IPurchaseTypesDataSourceModel): void {
        this.PurchaseType(model.model.Title);
    }

    onItemDeselected(_: PurchaseTypesDataSource, model: IPurchaseTypesDataSourceModel): void {
        this.PurchaseType("");
    }

    public isChanged(): boolean {
        const currentData = this.getData();
        return (
            this.purchase.description !== currentData.description ||
            this.purchase.fkPurchaseType !== currentData.fkPurchaseType ||
            this.purchase.uom !== currentData.uom ||
            this.purchase.dealerPrice !== currentData.dealerPrice ||
            this.purchase.customerPrice !== currentData.customerPrice ||
            this.purchase.currency !== currentData.currency
        );
    }

    public getData(): IArticlePurchase {
        return {
            ...this.purchase,
            description: this.Description(),
            fkPurchaseType: this.FKPurchaseType(),
            purchaseType: this.PurchaseType(),
            uom: this.Uom(),
            dealerPrice: this.DealerPrice() ?? 0,
            customerPrice: this.CustomerPrice() ?? 0,
            currency: this.Currency(),
        };
    }

    dispose() {}
}
