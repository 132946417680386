import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { serviceLocator } from "../../Core/Core";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { LoginScreen } from "./LoginScreen";
import * as React from '@abstraqt-dev/jsxknockout';
import { IApplicationContextAction, IApplicationEmbeddedField, IComponentApplication, INavBarActionManager } from "../interfaces/IApplication";
import { ObservableArray, Observable } from "knockout";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { MaybeObservable } from "knockout";

class LoginManager implements IComponentApplication, INavBarActionManager
{
    contextActions?: ObservableArray<IApplicationContextAction>;
    navBarActions?: ObservableArray<INavBarAction>;
    embeddedFields?: ObservableArray<IApplicationEmbeddedField<any>>;
    canShowSidebar: Observable<boolean> = ko.observable(false);
    tileColor?: string;
    systemHeader: unknown = undefined;
    
    public application: ko.Observable<LoginManager> = ko.observable();

    async handleLoginIfNeeded() {
        const authService = serviceLocator.findService<IAuthenticationService>(nameof<IAuthenticationService>());
        if(!authService.isLoggedIn()) {
            authService.setJwtTokenInfo(null); //Cancelliamo tutte le informazioni di login
            await this.doLogin();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFileDrop(filesList: File[], evt: MouseEvent) {
        //No actions
    }

    onGoingDown(): void {
        //No actions
    }

    addNavBarAction(name: MaybeObservable<string> | (() => string), icon: MaybeObservable<string> | (() => string), canRun: MaybeObservable<boolean> | (() => boolean), run: () => void): INavBarAction {
        //No nav bar support
        return undefined;
    }
    removeNavBarAction(action: INavBarAction): void {
        //No nav bar support
    }
    clearNavBarAction(): void {
        //No nav bar support
    }

    doLogin(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            //Temporary never resolve
            this.application(this);

            ko.applyBindings(this);
        });
    }

    getName(): string {
        return "Login"
    }

    getIcon(): string {
        return "fa fa-sign-in";
    }

    getApplicationCode(): string {
        return "Login";
    }

    registerRoutes(): void {
        //No routes
    }

    getApplicationRoute(): string {
        return "/Login";
    }

    getComponent(): ReactNode {
        return <LoginScreen />
    }
}

const loginManager = new LoginManager();
export default loginManager;