import { ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IJobOrderWithActivities } from "../../../MailsService";

export class JobOrder {
    get Id(): number {
        return this._id;
    };
    
    get Name(): string {
        return this._name;
    };

    SelectedActivities: ko.ObservableArray<ITaskForTaskBoard> = ko.observableArray([]);

    constructor(private _id: number, private _name: string) {}

    addActivity(activity: ITaskForTaskBoard) {
        this.SelectedActivities.push(activity);
    }
    
    removeActivity(activity: ITaskForTaskBoard) {
        let activities = this.SelectedActivities();
        let activityIndex = this.findActivityIndex(activity, activities);
        if (activityIndex < 0)
            return;

        this.SelectedActivities.valueWillMutate();
        activities.splice(activityIndex, 1);
        this.SelectedActivities.valueHasMutated();
    }

    private findActivityIndex(activity: ITaskForTaskBoard, activitiesList: ITaskForTaskBoard[]): number {
        let activityIndex = -1;
        for (let i = 0; i < activitiesList.length; i++) {
            const iterationActivity = activitiesList[i];
            if (iterationActivity.Id === activity.Id) {
                activityIndex = i;
                break;
            }
        }
        return activityIndex;
    }

    public getData(): IJobOrderWithActivities {
        return {
            Id: this.Id,
            Name: this.Name,
            Activities: this.SelectedActivities().map(a => a.Id)
        }
    }
}