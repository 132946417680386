import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { classNames, ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    csl: {
        width: "500px",

        "& .legend-row": {
            borderBottom: "1px solid #e5e5e5",
            paddingBottom: "5px",

            "& .element-wrapper": {
                width: "50px",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type LegendRowProps = {
    element: React.ReactElement;
    description: string;
};

function LegendRow(props: LegendRowProps) {
    const { element, description } = props;
    return (
        <div className="flex-container legend-row">
            <div className="flex-container element-wrapper">{element}</div>
            <div className="description-wrapper flex-1">{description}</div>
        </div>
    );
}

export class CostsStatusLegend extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        super({ popover: true });
        this.title(TextResources.Planning.CostsStatusLegendTitle);
    }

    show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopoverComponent(element, this, "right");
    }

    action() {
        this.modal.close();
    }

    renderBody() {
        return ComponentUtils.bindTo(
            <div className={classNames("row", classes.csl)}>
                <div className="col-md-12">
                    <LegendRow
                        element={
                            <div className="btn btn-success">
                                <i className="fa fa-question-circle"></i>
                            </div>
                        }
                        description={TextResources.Planning.CostsStatusLegendGreenQuestionDescription}
                    />
                    <LegendRow
                        element={
                            <div className="btn btn-success">
                                <i className="fa fa-check-circle"></i>
                            </div>
                        }
                        description={TextResources.Planning.CostsStatusLegendGreenCheckDescription}
                    />
                    <LegendRow
                        element={
                            <div className="btn btn-warning">
                                <i className="fa fa-check-circle"></i>
                            </div>
                        }
                        description={TextResources.Planning.CostsStatusLegendOrangeCheckDescription}
                    />
                    <LegendRow
                        element={
                            <div className="btn btn-danger">
                                <i className="fa fa-times-circle"></i>
                            </div>
                        }
                        description={TextResources.Planning.CostsStatusLegendRedTimesDescription}
                    />
                </div>
            </div>,
            this,
            "csl"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CostsStatusLegend);
}
