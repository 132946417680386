import * as React from "@abstraqt-dev/jsxknockout";
import { PreOrdersPanel } from "./panels/PreOrdersPanel";
import { RequestsForQuotationsPanel } from "./panels/RequestsForQuotationsPanel";
import { Application } from "../../../Components/Application";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { INavBarActionManager } from "../../../Desktop/interfaces/IApplication";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { SupplierOrdersPanel } from "./panels/SupplierOrdersPanel";
import { Routes, Route } from "../../../Components/Routing";
import { LinksMenu } from "../../../Components/Routing/LinksMenu";
import { Link } from "../../../Components/Routing/Link";

type IProvisiongParams = {
    navBarActionManager: INavBarActionManager;
}

export class Provisioning {
    constructor(private props : IProvisiongParams) {
        
    }

    render() {
        return <Application>
                    <Application.LeftMenu>
                        <LinksMenu>
                            <Link path="/Provisioning/PreOrders" title={TextResources.Provisioning.PreOrders} icon="fa fa-list-alt" />
                            <Link path="/Provisioning/RequestsForQuotations" title={TextResources.Provisioning.RequestsForQuotations} icon="fa fa-question" />
                            <Link path="/Provisioning/SupplierOrders" title={TextResources.Provisioning.SupplierOrders} icon="fa fa-truck" />
                        </LinksMenu>
                    </Application.LeftMenu>
                    <Application.Content>
                        <Routes>
                            <Route path="/Provisioning/PreOrders" element={(p) => <PreOrdersPanel {...p} navBarActionManager={this.props.navBarActionManager} />} />
                            <Route path="/Provisioning/RequestsForQuotations" element={() => <RequestsForQuotationsPanel navBarActionManager={this.props.navBarActionManager} />} />
                            <Route path="/Provisioning/SupplierOrders" element={() => <SupplierOrdersPanel navBarActionManager={this.props.navBarActionManager} />} />
                        </Routes>
                    </Application.Content>
                </Application>
    }
}

if(module.hot) {
    module.hot.accept();
    //module.hot.dispose(() => styleSheet.detach());
    reloadNow(Provisioning);
}