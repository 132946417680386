/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 05/03/13
 * Time: 12.11
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Article } from "./Article";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IArticlesService, IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class CreateNewArticleDialog implements IDialog {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;

    title: string = ProlifeSdk.TextResources.Warehouse.NewArticle;
    templateName = "warehouse-new-article-dialog";
    templateUrl = "warehouse/templates";

    modal: { close: (result?: any) => void };

    public newArticle: Article;
    public CanEditArticleId: ko.Observable<boolean> = ko.observable(true);
    public EditCode: ko.Observable<boolean> = ko.observable(false);

    private LoadPriceAvg: ko.Observable<number> = ko.observable(0);
    private WarehouseId = -1;

    constructor(readOnly = false) {
        this.templateName = readOnly ? "warehouse-new-article-readonly-dialog" : this.templateName;
    }

    close(): void {
        this.modal && this.modal.close(false);
    }

    SetWarehouse(warehouseId: number) {
        this.WarehouseId = warehouseId;
    }

    action(): void {
        const validation = this.newArticle.getValidation();
        if (!validation.validateAndShowInfoToast(this.newArticle)) {
            return;
        }

        if (!this.newArticle.isChanged()) {
            this.infoToast.Info(ProlifeSdk.TextResources.Warehouse.NoChangesMade);
            this.close();
            return;
        }

        this.articlesService.createOrUpdate(this.newArticle.getData()).then(this.onSaveSuccess.bind(this));
    }

    private onSaveSuccess() {
        this.infoToast.Success(ProlifeSdk.TextResources.Warehouse.ArticleSavedSuccessfully);
        this.close();
    }

    public loadAndShow(article?: IArticle) {
        this.newArticle = new Article(
            article || {
                Code: "",
                PriceMultiplier: 1,
                Currency: "EUR",
                UOM: "PCE",
                State: 3,
                LeadTime: "1",
                MinOrderQt: 1,
                MaxOrderQt: 999999,
                OrderQtStep: 1,
                BoxQuantity: 1,
                EanCode: "0000000000000",
            }
        );

        this.title = article
            ? String.format(ProlifeSdk.TextResources.Warehouse.ArticleWithCode, this.newArticle.Code())
            : ProlifeSdk.TextResources.Warehouse.NewArticle;

        this.CanEditArticleId(!article);
        this.EditCode(false);

        if (article) this.LoadBusinessInfo(article);

        this.dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
    }

    LoadBusinessInfo(article: IArticle) {
        this.articlesService.GetLoadPriceAvg(article.ArticleId, this.WarehouseId).then((p: number) => {
            this.LoadPriceAvg(p || 0);
        });
    }
}
