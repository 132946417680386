import { BaseDataSource } from "./BaseDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IDataSourceModel } from "./IDataSource";
import { IExpireMode, IExpireModes } from "../ProlifeSdk/interfaces/invoice/settings/IExpireModes";

export type IExpiriesDataSourceModel = IDataSourceModel<number, IExpireMode>

export class ExpiriesDataSource extends BaseDataSource<IExpiriesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    private expireModes : IExpireModes;

    private viewDeleted: boolean;

    getTitle(currentModel: IExpiriesDataSourceModel): string {
        return this.expireModes.getLabel();
    }

    async getData(currentModel: IExpiriesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IExpiriesDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let expireModes = this.expireModes.getExpireModes(this.viewDeleted);
        
        return expireModes
            .filter(d => splitFilters.filter(w => d.Descrizione.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: IExpiriesDataSourceModel, ids: (string | number)[]): Promise<IExpiriesDataSourceModel[]> {
        return this.expireModes.getExpireModes(true)
            .filter(p => ids.indexOf(p.IdTipoScadenza) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IExpireMode): IExpiriesDataSourceModel {
        return {
            id: model.IdTipoScadenza,
            title: model.Descrizione,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}