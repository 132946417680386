import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "./ImportDocumentDataWizard";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentBuilderDocumentRelatedWorkflows, IDocumentBuilderDocumentRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

interface IImportableDocumentDataWizardRow extends IDocumentDataWizardRow {
    Imported : ko.Observable<boolean>;
    SourceRows: IImportableDocumentDataWizardRow[];
    SelectedForRemove: ko.Observable<boolean>;
}

export class ImportDocumentDataWizardGroupingStep extends DocumentDataWizardStep {

    private sourceRows : ko.ObservableArray<IImportableDocumentDataWizardRow> = ko.observableArray([]);
    
    public FinalTotal : ko.Computed<number>; // Questo è in valuta documento
    public FinalTotalInDefaultCurrency : ko.Computed<number>;
    public ShowFinalTotalInDefaultCurrency : ko.Computed<boolean>;
    public SelectAll : ko.Computed<boolean>;
    public SelectAllGroups : ko.Computed<boolean>;

    private previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Invoices.Groups);
        this.IsSelected(true);
        this.IsVisible(false);
        this.CanBeFastForwarded = true;
    }

    async Initialize(initializationInfo : IWizardInitializationInfo) : Promise<void> {
        await super.Initialize(initializationInfo);

        this.FinalTotal = ko.computed(() => {
            let totalPrice = 0;

            this.ProcessedRows().forEach(r => {
                totalPrice += r.Row.TotalPrice();
            });

            return totalPrice;
        });

        this.FinalTotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
            const total = this.FinalTotal();

            if (!documentCurrency)
                return total;

            return total.ToEuro(documentCurrency);
        });

        this.ShowFinalTotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

            if (!documentCurrency)
                return false;

            const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            return defaultCurrency.CodeISO4217alpha3 != documentCurrency.Currency().CodeISO4217alpha3;
        });

        this.SelectAll = ko.computed({
            read: () => {
                const sources = this.sourceRows();
                return sources.filter(r => !r.IsSelected() && r.Row.Amount() !== 0).length == 0;
            },
            write: (selected : boolean) => {
                const sources = this.sourceRows();
                sources.forEach(r => { if (r.Row.Amount() !== 0) r.IsSelected(selected); });
            }
        });

        this.SelectAllGroups = ko.computed({
            read: () => {
                const sources = this.ProcessedRows();
                return sources.filter((r: IImportableDocumentDataWizardRow) => !r.SelectedForRemove()).length == 0;
            },
            write: (selected : boolean) => {
                const sources = this.ProcessedRows();
                sources.forEach((r: IImportableDocumentDataWizardRow) => r.SelectedForRemove(selected));
            }
        });
    }

    RemoveSelectedGroups()
    {
        const selection = this.ProcessedRows().filter((r: IImportableDocumentDataWizardRow) => r.SelectedForRemove());
        selection.forEach(r => {
            r.SourceRows.forEach((sr : IImportableDocumentDataWizardRow) => {
                sr.Imported(false);
                sr.SelectedForRemove(false);
            });
            this.ProcessedRows.remove(r);
        });
    }

    RemoveTextRows() {
        const selection = this.ProcessedRows().filter((r: IImportableDocumentDataWizardRow) => r.SelectedForRemove());
        selection.forEach(r => {
            if (r.Row.Amount() == 0) {
                r.SourceRows.forEach((sr : IImportableDocumentDataWizardRow) => {
                    sr.Imported(false);
                    sr.SelectedForRemove(false);
                });
                this.ProcessedRows.remove(r);
            }
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]) {
        const rows = previousStepRows.map(r => Object.assign({}, r, { Imported: ko.observable(false), SelectedForRemove: ko.observable(false) }) as IImportableDocumentDataWizardRow );
        rows.forEach(r => r.IsSelected(!r.Row.Amount()));

        this.sourceRows(rows);
        this.ProcessedRows([]);
    }

    CanGoNext() : boolean {
        const withoutDescriptionRows = this.ProcessedRows().filter(p => (p.Row.Description() || "").trim().length == 0);

        if(withoutDescriptionRows.length > 0) {
            this.infoToastService.Warning(TextResources.Invoices.RowsWithoutDescription);
            return false;
        }

        return true;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const processedRows = this.ProcessedRows();
        if(processedRows.length == 0) {
            this.ProcessedRows(this.sourceRows());
        }

        return this.ProcessedRows();
    }

    private FilterSelection() : IImportableDocumentDataWizardRow[]
    {
        const selection = this.sourceRows().filter(r => r.IsSelected());
        const result =  selection.filter(r => !r.Imported());

        if (selection.length != result.length)
            this.infoToastService.Warning(TextResources.Invoices.DiscardedRows);

        return result;
    }

    public ProcessSelectionDetailed()
    {
        this.ProcessSelectionDetailedInternal(this.FilterSelection());
    }

    private ProcessSelectionDetailedInternal(selection : IImportableDocumentDataWizardRow[])
    {
        selection.forEach(r => {
            r.SourceRows = selection.map(s => s);
            r.Imported(true);
            r.SelectedForRemove(true);
            this.ProcessedRows.push(r);
        });
        this.SelectAll(false);
    }

    public ProcessSelectionGrouped()
    {
        const selection = this.FilterSelection();

        if(selection.length == 0)
            return;

        // Importa automaticamente le righe a quantità zero come singole righe. Le righe a quantità zero NON deveono essere messe in un gruppo.
        const rowsWithoutAmount = selection.filter(r => !r.Row.Amount() && !r.Imported());
        this.ProcessSelectionDetailedInternal(rowsWithoutAmount);

        // Rimuovo le righe con quantità zero dalle righe selzionate in modo da poter gestire correttamente le righe a quantità diversa da zero (se c'è una sola riga con q.tà diversa da zero non si deve fare un gruppo)
        rowsWithoutAmount.forEach(r => selection.remove(r));

        if (selection.length == 1)
        {
            this.ProcessSelectionDetailedInternal(selection);
            return;
        }

        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const processedRowData = {
            Id: 0,
            AmountFormula: null,
            FKDocument: 0,
            Description: "",
            Amount: 1,
            ClosedAmount: 0,
            EntityType: this.initializationInfo.DocTypeCode,
            FKCurrency: documentCurrency.CurrencyId(),
            Currency: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrencySymbol(),
            ManuallyClosed: false,
            NetUnitPrice: 0,
            NetUnitPriceInDocumentCurrency: 0,
            Order: 0,
            TotalPrice: 0,
            TotalPriceInDocumentCurrency: 0,
            UnitPrice: 0,
            UnitPriceInDocumentCurrency: 0,
            Discounts: null,
        };

        const processedRowOriginatingRows: IDocumentBuilderDocumentOriginatingRows[] = [];
        
        let firstRow = true;
        let offsetId = null;
        let offsetCode = null;
        let multipleOffsets = false;

        selection.forEach(r => {
            processedRowData.UnitPriceInDocumentCurrency += r.Row.TotalPriceInDocumentCurrency();
            processedRowData.NetUnitPriceInDocumentCurrency += r.Row.TotalPriceInDocumentCurrency();
            processedRowData.TotalPriceInDocumentCurrency += r.Row.TotalPriceInDocumentCurrency();
            
            if (!multipleOffsets) {
                if(firstRow || !offsetId) {
                    offsetId = r.Row.FKOffset;
                    offsetCode = r.Row.OffsetCode;
                    firstRow = false;
                } else if(offsetId != null && r.Row.FKOffset != offsetId) {
                    //Ho trovato una contropartita diversa, non posso applicare una contropartita al gruppo, quindi resetto tutto
                    offsetId = null;
                    offsetCode = null;
                    multipleOffsets = true;
                }
            }

            r.OriginatingRows.filter(wr => wr.Amount != 0).forEach(wr => {
                processedRowOriginatingRows.push(wr);
            });

            r.Imported(true);
        });

        this.ApplyCurrencyExchangeToRow(processedRowData);

        const processed : IImportableDocumentDataWizardRow = {
            Row: new ProcessedRow(processedRowData, documentCurrency),
            IsSelected: ko.observable(false),
            SelectedForRemove: ko.observable(true),
            Imported: ko.observable(true),
            OriginatingRows: [],
            SourceRows: selection,
            RelatedWorkflows: this.getRelatedWorkflows(selection)
        };

        processed.OriginatingRows = processedRowOriginatingRows;

        if (!multipleOffsets) {
            processed.Row.FKOffset(offsetId);
            processed.Row.OffsetCode(offsetCode);
        }

        this.ProcessedRows.push(processed);
        this.SelectAll(false);
    }

    private ApplyCurrencyExchangeToRow(processed: IDocumentBuilderDocumentRows): void {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        processed.UnitPrice = processed.UnitPriceInDocumentCurrency.ToEuro(documentCurrency);
        processed.NetUnitPrice = processed.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency);
        processed.TotalPrice = processed.TotalPriceInDocumentCurrency.ToEuro(documentCurrency);
    }

    private getRelatedWorkflows(selection : IDocumentDataWizardRow[]) : IDocumentBuilderDocumentRelatedWorkflows[] {
        const workflows = {};
        const workflowIds = [];
        const total = selection.sum(r => r.Row.TotalPriceInDocumentCurrency());

        for (const row of selection) {
            for (const rw of row.RelatedWorkflows) {
                const eurPerWorkflow = rw.WorkflowAmount * row.Row.NetUnitPrice();

                if(workflowIds.indexOf(rw.WorkflowId) == -1) {
                    workflows[rw.WorkflowId] = 0;
                    workflowIds.push(rw.WorkflowId);
                }
                
                workflows[rw.WorkflowId] += eurPerWorkflow;
            }
        }

        const result : IDocumentBuilderDocumentRelatedWorkflows[] = [];
        for (const id of workflowIds) {
            const w = workflows[id];

            result.push({
                Id : null,
                WorkflowId : id,
                DocumentId : null,
                DocumentType: ProlifeSdk.InvoiceEntityTypeCode,
                DocumentNumber: null,
                DocumentDate: null,
                DocumentProtocolId: null,
                RowId: 0,
                RowDescription: null,
                RowAmount: 1,
                RowUoM: "",
                RowPrice: total,
                WorkflowAmount: Math.max(0, w / total),
                ValidityStartDate: null,
                WorkflowName: null
            });
        }

        return result;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    render() {
        let step : ImportDocumentDataWizardGroupingStep;
        let row : IImportableDocumentDataWizardRow;
        let data: ProcessedRow;
        let outRow: IImportableDocumentDataWizardRow;
        let outData : ProcessedRow;

        return ComponentUtils.bindTo(
            <div style="padding : 20px">
                <div className="row">
                    <pre>
                        {TextResources.Invoices.DocumentWizardGroupingInformation}
                    </pre>
                    <br />
                    <br />
                </div>
                
                <div className="row" style="margin-bottom : 20px">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-primary" style={{ marginRight: "5px" }} data-bind={{ click: step.ProcessSelectionDetailed }}>{TextResources.Invoices.DocumentWizardDetailed}</button>
                        <button type="button" className="btn btn-primary" data-bind={{ click: step.ProcessSelectionGrouped }}>{TextResources.Invoices.DocumentWizardGrouped}</button>
                        <button type="button" className="btn btn-danger pull-right" data-bind={{ click: step.RemoveSelectedGroups }}>{TextResources.Invoices.DocumentWizardRemove}</button>
                        <button type="button" className="btn btn-danger pull-right" style={{ marginRight: "5px" }} data-bind={{ click: step.RemoveTextRows }}>{TextResources.Invoices.DocumentWizardRemoveTextRows}</button>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-md-6" style="overflow : auto">
                        <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th style="text-align: center">
                                        <div className="checker">
                                            <span data-bind={{ css : { checked : step.SelectAll } }}>
                                                <input type="checkbox" data-bind={{ checked : step.SelectAll }} />
                                            </span>
                                        </div>
                                    </th>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th className="text-right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th className="text-right" style="width: 90px;">{TextResources.Invoices.DocumentWizardUnitPrice}</th>
                                    <th className="text-right" style="width: 90px;">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if: step.sourceRows().length == 0 }}>
                                <tr>
                                    <td colSpan={5} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.sourceRows, as: 'row' }}}>
                                    <tr data-bind={{ css : { danger : row.Imported } }}>
                                        <td style="text-align: center">
                                            <div className="checker" data-bind={{ css: { "disabled": !row.Row.Amount() } }}>
                                                <span data-bind={{ css : { checked : row.IsSelected } }}>
                                                    <input type="checkbox" data-bind={{ checked : row.IsSelected, disable: !row.Row.Amount() }} />
                                                </span>
                                            </div>
                                        </td>
                                        <ko-bind data-bind={{ with: row.Row, as: 'data' }}>
                                            <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" data-bind={{ text : data.Description }}></td>
                                            <td style="text-align: right; width: 120px;" data-bind={{ numberText : data.Amount }}></td>
                                            <td style="text-align: right; width: 120px;" data-bind={{ moneyText: data.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                            <td style="text-align: right; width: 120px;" data-bind={{ moneyTextEx: data.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                        </ko-bind>
                                    </tr>
                                </ko-bind>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6" style="overflow : auto">

                        <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th style="width: 50%;">{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th className="text-right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th className="text-right">{TextResources.Invoices.DocumentWizardUnitPrice}</th>
                                    <th className="text-right" >{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                    <th className="text-center" style="width: 40px">
                                        <div className="checker">
                                            <span data-bind={{ css : { checked : step.SelectAllGroups } }}>
                                                <input type="checkbox" data-bind={{ checked : step.SelectAllGroups }} />
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if: step.ProcessedRows().length == 0 }}>
                                <tr>
                                    <td colSpan={5} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.ProcessedRows, as: 'outRow' }}}>
                                <tr>
                                    <ko-bind data-bind={{ with: outRow.Row, as: 'outData' }}>
                                        <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                            <input className="form-control input-sm" style="width : 100%" placeholder={TextResources.Invoices.DocumentWizardInsertADescription} type="text" data-bind={{ value: outData.Description, selectOnFocus: {} }} />
                                        </td>
                                        <td style="text-align: right">
                                            <input type="text" className="form-control input-sm" data-bind={{ numberValue: outData.Amount }} />
                                        </td>
                                        <td style="text-align: right" data-bind={{ moneyText: outData.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                        <td style="text-align: right" data-bind={{ moneyTextEx: outData.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                    </ko-bind>
                                    <td style="text-align: center;">
                                        <div className="checker">
                                            <span data-bind={{ css : { checked : outRow.SelectedForRemove } }}>
                                                <input type="checkbox" data-bind={{ checked : outRow.SelectedForRemove }} />
                                            </span>
                                        </div>
                                    </td>

                                </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ if: step.ProcessedRows().length > 0 }}>
                                    <tr>
                                        <td colSpan={2} style="text-align: left"><strong>{TextResources.Invoices.DocumentWizardTotalPrice}</strong></td>
                                        <td colSpan={2} style="text-align: right">
                                            <span data-bind={{ visible: step.ShowFinalTotalInDefaultCurrency }}>(<span data-bind={{ moneyTextEx: step.FinalTotalInDefaultCurrency }}></span>)</span> <span data-bind={{ moneyTextEx: step.FinalTotal, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </ko-bind>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        , this, "step");
    }

}