import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { TasksUtils } from "../Todolist/Todolist/ui/utils/TasksUtils";
import {
    INavigationMenuComponentModel,
    INavigationMenuComponentDataSource,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import {
    ITaskForTaskBoard,
    IWorkflowForTaskBoard,
    ITodoListService,
    IGetJobOrderWorkflowsForUserRequest,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import {
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import {
    IWorkflowStatesSettingsManager,
    IWorkflowState,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";

export interface IWorkflowsAndTasksDataSourceModel extends INavigationMenuComponentModel {
    id: number;
    isWorkflowGroup: boolean;
    isWorkflow: boolean;
    isTask: boolean;

    model: ITaskForTaskBoard | IWorkflowForTaskBoard | null;
}

export type IWorkflowsAndTasksDataSource = INavigationMenuComponentDataSource;

interface IWorkflowsAndTasksDataSourceConfig {
    jobOrderIds: number[];
    workflowsCache: IWorkflowForTaskBoard[];
    workflowsCacheClearRequested: boolean;
    lastTextFilter: string;
}

export class WorkflowsAndTasksDataSource implements IWorkflowsAndTasksDataSource {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService!: IChangesNotificationsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStateSettingsManager!: IWorkflowStatesSettingsManager;

    private jobOrderIds: number[] = [];
    private workflowsCache: IWorkflowForTaskBoard[] = null;
    private workflowsCacheClearRequested = false;
    private lastTextFilter: string = null;
    private view: IDataSourceView;

    //State stack
    private pushStateRequired = false;
    private stateStack: IWorkflowsAndTasksDataSourceConfig[] = [];

    //Selection
    private selectionRequested = false;
    private requestedTaskId: number[] = [];

    //Search Options
    private recursiveSearch = false;

    //Advanced search
    private contentTextFilter: string = null;

    private notificationsEnabled = false;

    private viewAll = true;

    constructor() {}

    refresh() {
        if (!this.view) return;

        this.workflowsCache = null;
        this.workflowsCacheClearRequested = true;
        this.view.refresh(true);
    }

    enableNotifications(enabled: boolean) {
        if (this.notificationsEnabled == enabled) return;

        if (enabled) {
            this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
        } else this.changesNotificationsService.RemoveObserver(this);

        this.notificationsEnabled = enabled;
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sentByMe: boolean) {
        if (changesInfo.EntityCode == ProlifeSdk.WorkflowEntityTypeCode) {
            const jobOrderId = changesInfo.Changes.OldStatus
                ? changesInfo.Changes.OldStatus.JobOrderId
                : changesInfo.Changes.NewStatus.JobOrderId;
            if (this.jobOrderIds.indexOf(jobOrderId) != -1) this.refresh();
        }
        return false;
    }

    pushState() {
        if (!this.view) {
            this.pushStateRequired = true;
        }

        this.view.pushState();

        this.stateStack.push({
            jobOrderIds: this.jobOrderIds.slice(),
            workflowsCache: this.workflowsCache ? this.workflowsCache.slice() : null,
            workflowsCacheClearRequested: this.workflowsCacheClearRequested,
            lastTextFilter: this.lastTextFilter,
        });
    }

    popState() {
        this.view.popState();

        if (this.stateStack.length > 0) {
            const oldState = this.stateStack.pop();
            this.jobOrderIds = oldState.jobOrderIds;
            this.workflowsCache = oldState.workflowsCache;
            this.workflowsCacheClearRequested = oldState.workflowsCacheClearRequested;
            this.lastTextFilter = oldState.lastTextFilter;
        }
    }

    selectTasks(tasksIds: number[]) {
        if (!this.view) {
            this.selectionRequested = true;
            this.requestedTaskId = tasksIds.slice();
            return;
        }

        this.view.select(
            ...tasksIds.map((id) => {
                return {
                    id: id,
                    isGroup: false,
                    isLeaf: true,
                    title: "",
                    isWorkflowGroup: false,
                    isWorkflow: false,
                    isTask: true,
                };
            })
        );
    }

    setJobOrders(jobOrderIds: number[]) {
        this.jobOrderIds = jobOrderIds.slice();
        this.workflowsCache = null;
        this.workflowsCacheClearRequested = true;

        if (this.view) this.view.refresh();
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if (this.pushStateRequired) {
            this.view.pushState();
            this.pushStateRequired = false;
        }

        if (this.selectionRequested) {
            this.selectTasks(this.requestedTaskId);
            this.selectionRequested = false;
        }

        this.refresh();
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): void {}

    setRecursiveSearch(enabled: boolean) {
        this.recursiveSearch = enabled;
    }

    getTitle(currentModel: IDataSourceModel): string {
        if (currentModel == null) return ProlifeSdk.TextResources.Todolist.Workflows;
        return currentModel.title;
    }

    isGroupedData(currentModel: IWorkflowsAndTasksDataSourceModel, textFilter: string): boolean {
        if (!currentModel) return true;
        return false;
    }

    areEqual(a: IWorkflowsAndTasksDataSourceModel, b: IWorkflowsAndTasksDataSourceModel): boolean {
        return (
            a === b ||
            (!!a &&
                !!b &&
                a.id === b.id &&
                a.isWorkflowGroup === b.isWorkflowGroup &&
                a.isWorkflow === b.isWorkflow &&
                a.isTask === b.isTask)
        );
    }

    async getData(
        currentModel: IWorkflowsAndTasksDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWorkflowsAndTasksDataSourceModel[]> {
        if (this.recursiveSearch && textFilter) {
            return this.handleRecursiveSearch(currentModel, textFilter, skip, count);
        }

        console.log(
            "currentModel: ",
            currentModel,
            "textFilter: ",
            textFilter,
            "skip: ",
            skip,
            "count: ",
            count,
            "jobOrders:",
            this.jobOrderIds
        );
        if (skip != 0) return [];

        if (this.lastTextFilter != textFilter || (currentModel && currentModel.isWorkflow)) {
            console.log("Clearing cache because filter changed or a workflow was selected");
            this.workflowsCache = null;
            this.workflowsCacheClearRequested = true;
            this.lastTextFilter = textFilter;
        }

        if (currentModel === null) {
            const states = this.workflowStateSettingsManager.getStates(false);

            while (this.workflowsCacheClearRequested) {
                console.log("Querying server for workflows...");
                this.workflowsCacheClearRequested = false;

                const params: IGetJobOrderWorkflowsForUserRequest = {
                    userId: null,
                    jobOrderIds: this.jobOrderIds,
                    viewWorker: true,
                    viewResponsible: true,
                    categoryIds: [],
                    viewAll: this.viewAll,
                    hideNoWork: false,
                    destinationTaskBoardStatus: null,
                    startDate: null,
                    endDate: null,
                    textFilter: textFilter,
                    contentTextFilter: this.contentTextFilter,
                    filterWithContent: false,
                };

                const workflows = await this.todoListService.GetJobOrderWorkflowsForUser(params);
                this.workflowsCache = workflows;

                if (this.workflowsCacheClearRequested) {
                    console.log("Workflow Cache Clear requested while query was in progress, discarding cache");
                    this.workflowsCache = [];
                }

                console.log("Query finished: ", this.workflowsCache);
            }

            const usedStates = states.map((s) => {
                return {
                    state: s,
                    count: this.workflowsCache.filter((w) => w.StateId == s.Id).length,
                };
            });

            console.log("Counting workflows per state: ", usedStates);
            return this.createModelsForStates(usedStates);
        } else if (currentModel.isWorkflowGroup) {
            console.log("Retrieving workflows in state ", currentModel.id, " from cache");
            const workflowsInGroup = this.workflowsCache.filter((w) => w.StateId == currentModel.id);
            console.log("Workflows: ", workflowsInGroup);

            return this.createModelsForWorkflows(workflowsInGroup);
        } else if (currentModel.isWorkflow) {
            console.log("Querying server for tasks in workflow ", currentModel.id, ": ", currentModel.title);

            const params = {
                textFilter: textFilter,
                userId: null,
                resourceId: null,
                workflows: [currentModel.id],
                statusIds: [],
                viewWorker: true,
                viewResponsible: true,
                skip: 0,
                count: 1000000,
                viewAll: this.viewAll,
                hideNoWork: false,
                destinationTaskBoardStatus: null,
                startDate: null,
                endDate: null,
                activityProgressMode: null,
                showClosed: true,
                getTasksIfNoFilter: false,
                getWorkflowsInWorkflows: true,
                searchOnWorkflowTitle: false,
            };

            const tasks = await this.todoListService.GetTasksForUser(params);
            console.log("Tasks: ", tasks);

            return this.createModelsForTasks(tasks, currentModel);
        }

        return null;
    }

    async handleRecursiveSearch(
        currentModel: IWorkflowsAndTasksDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWorkflowsAndTasksDataSourceModel[]> {
        if (skip != 0) return [];

        if (currentModel == null) {
            const params: IGetJobOrderWorkflowsForUserRequest = {
                userId: null,
                jobOrderIds: this.jobOrderIds,
                viewWorker: true,
                viewResponsible: true,
                categoryIds: [],
                viewAll: this.viewAll,
                hideNoWork: false,
                destinationTaskBoardStatus: null,
                startDate: null,
                endDate: null,
                textFilter: textFilter,
                contentTextFilter: this.contentTextFilter,
                filterWithContent: false,
            };

            const workflows = await this.todoListService.GetJobOrderWorkflowsForUser(params);
            return workflows.map((w) => {
                return {
                    id: w.Id,
                    title: w.Title,
                    isGroup: true,
                    isLeaf: false,
                    isWorkflowGroup: true,
                    isWorkflow: false,
                    isTask: false,
                    collapsed: false,
                    model: null,
                };
            });
        } else {
            const params = {
                textFilter: textFilter,
                userId: null,
                resourceId: null,
                workflows: [currentModel.id],
                statusIds: null,
                viewWorker: true,
                viewResponsible: true,
                skip: 0,
                count: 1000000,
                viewAll: this.viewAll,
                hideNoWork: false,
                destinationTaskBoardStatus: null,
                startDate: null,
                endDate: null,
                activityProgressMode: 0,
                showClosed: true,
                getTasksIfNoFilter: false,
                getWorkflowsInWorkflows: true,
                searchOnWorkflowTitle: false,
            };

            const tasks = await this.todoListService.GetTasksForUser(params);

            return this.createModelsForTasks(tasks, currentModel);
        }
    }

    createModelsForTasks(tasks: ITaskForTaskBoard[], parent: IDataSourceModel): IWorkflowsAndTasksDataSourceModel[] {
        return tasks.map((t) => {
            const task: IWorkflowsAndTasksDataSourceModel = {
                id: t.Id,
                isGroup: false,
                isLeaf: true,
                title: t.Title,
                isWorkflowGroup: false,
                isWorkflow: false,
                isTask: true,
                icon: TasksUtils.getTaskStatusIcon(t.TaskBoardStatus),
                subTitle:
                    ProlifeSdk.TextResources.Todolist.Status + ": " + TasksUtils.getTaskStatusName(t.TaskBoardStatus),
                badge: [
                    {
                        text: t.BlogEventsCount.toString(),
                        cssClass: t.BlogEventsCount == 0 ? "badge-danger" : "badge-success",
                    },
                ],
                alerts: {
                    label: ProlifeSdk.TextResources.Todolist.Alerts,
                    icons: TasksUtils.getAlertsForTask(t),
                },
                model: t,
                parent:
                    parent ||
                    ({
                        id: t.WorkflowId,
                        title: t.WorkflowTitle,
                        isGroup: false,
                        isLeaf: false,
                        isWorkflowGroup: false,
                        isWorkflow: true,
                        isTask: false,
                        collapsed: false,
                    } as any),
            };

            if (t.IsAllocated) {
                task.iconsList = [
                    {
                        icon: "fa fa-shopping-cart",
                        background: t.HasEstimatedWork ? "#45b6af" : "#f3565d",
                        foreground: "#ffffff",
                    },
                ];
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
                task.secondaryAction = {
                    icon: {
                        icon: "fa fa-pencil",
                        background: "#ecbc29",
                        foreground: "white",
                    },
                    action: () => {
                        this.todoListService.ShowEditTaskDialog(t.Id, { initialViewAll: false }).then((result) => {
                            if (!result) return;
                            this.refresh();
                        });
                    },
                };
            }

            return task;
        });
    }

    createModelsForWorkflows(workflowsInGroup: IWorkflowForTaskBoard[]): IWorkflowsAndTasksDataSourceModel[] {
        return workflowsInGroup.map((w) => {
            const item: IWorkflowsAndTasksDataSourceModel = {
                id: w.Id,
                isGroup: false,
                isLeaf: false,
                title: w.Title,
                isWorkflowGroup: false,
                isWorkflow: true,
                isTask: false,
                icon: {
                    icon: w.Icon,
                    background: w.Background,
                    foreground: w.Foreground,
                },
                progressBar: {
                    progress: ((w.TasksCount - w.NotCompleteTasksCount) / w.TasksCount) * 100,
                },
                subTitle:
                    ProlifeSdk.TextResources.Todolist.ClosedTasks +
                    ": " +
                    (w.TasksCount - w.NotCompleteTasksCount) +
                    "/" +
                    w.TasksCount,
                model: w,
                alerts: {
                    label: ProlifeSdk.TextResources.Todolist.Alerts,
                    icons: WorkflowsUtils.getAlertsForWorkflow(w),
                },
                secondaryIcon: {
                    icon: w.OutcomeIcon,
                    background: w.OutcomeBackground,
                    foreground: w.OutcomeForeground,
                },
                showSelectAllChildren: true,
            };

            item.badge = [];

            if ((w.AllocationPercentage || 0) > 0 || w.HasNotEstimatedElementsAllocated) {
                item.badge.push(WorkflowsUtils.getAllocationBadge(w, this.dialogsService));
            }

            if (w.RelatedDocuments > 0 || w.WorkflowMustBeRelatedToCustomerOrders) {
                item.badge.push(WorkflowsUtils.getRelatedDocumentsBadge(w));
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
                item.secondaryAction = {
                    icon: {
                        icon: "fa fa-pencil",
                        background: "#ecbc29",
                        foreground: "white",
                    },
                    action: () => {
                        this.todoListService.ShowEditWorkflowDialog(w.Id).then((result) => {
                            if (!result) return;
                            this.refresh();
                        });
                    },
                };
            }

            return item;
        });
    }

    createModelsForStates(
        statesWithCount: { state: IWorkflowState; count: number }[]
    ): IWorkflowsAndTasksDataSourceModel[] {
        return statesWithCount
            .filter((s) => s.count > 0)
            .map((s) => {
                return {
                    id: s.state.Id,
                    isGroup: true,
                    isLeaf: false,
                    title: s.state.Description + " (" + s.count + ")",
                    collapsed: false,
                    isWorkflowGroup: true,
                    isWorkflow: false,
                    isTask: false,
                    model: null,
                };
            });
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        const tasks = await this.todoListService.GetTasksForUserById(null, ids);
        return this.createModelsForTasks(tasks, null);
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setViewAll(value: boolean): void {
        this.viewAll = value;
    }
}
