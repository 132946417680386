import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { StocksServiceUI } from "./warehouse/ui/StocksServiceUI";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    IStockService,
    IStocksUI,
    IStockArticle,
    IStockTransaction,
    IStockMovement,
    ISearchStockResult,
    ISearchStockResultV2,
    GetUnderStockArticlesRequest,
    IStockArticleV2,
} from "../ProlifeSdk/interfaces/warehouse/IStockService";
import { LazyImport } from "../Core/DependencyInjection";
import { ResponseData } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";

class StockService implements IStockService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    ui: IStocksUI;

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IStockService>(), this);
    }

    InitializeService() {
        this.ui = new StocksServiceUI(this.serviceLocator);
    }

    CalculateArticleStatus(stock: number, reserved: number, ordered: number, minimumAmount: number): number {
        if (stock - reserved > minimumAmount) return 0; //Verde
        else if (stock - reserved >= 0) return 1; //Giallo
        else if (stock - reserved + ordered >= 0) return 2; //Arancio

        return 3;
    }

    getServiceType(): string {
        return ProlifeSdk.StockServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getStockByArticleId(warehouseId: number, articleId: number): Promise<number> {
        if (!articleId || !warehouseId) {
            return Promise.reject<number>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "Stock/" + articleId + "?warehouseId=" + warehouseId, {
            background: true,
        });
    }

    getStocks(
        filter: string,
        skip: number,
        count: number,
        warehouseId?: number,
        onlyInStock = false
    ): Promise<IStockArticle[]> {
        warehouseId = warehouseId || null;

        if (!filter) {
            return this.ajaxService.Get(
                "Warehouse-api",
                "Stock/Search?filter=&skip=" +
                    skip +
                    "&count=" +
                    count +
                    "&warehouseId=" +
                    warehouseId +
                    "&onlyInStock=" +
                    onlyInStock,
                {}
            );
        }

        return this.ajaxService.Get(
            "Warehouse-api",
            "Stock/Search?filter=" +
                encodeURI($.trim(filter)) +
                "&skip=" +
                skip +
                "&count=" +
                count +
                "&warehouseId=" +
                warehouseId +
                "&onlyInStock=" +
                onlyInStock,
            {}
        );
    }

    getStocksCount(filter: string, warehouseId?: number, onlyInStock = false): Promise<number> {
        warehouseId = warehouseId || null;

        return this.ajaxService.Get(
            "Warehouse-api",
            "Stock/Count?filter=" +
                encodeURI($.trim(filter)) +
                "&warehouseId=" +
                warehouseId +
                "&onlyInStock=" +
                onlyInStock,
            {}
        );
    }

    async getUnderStockArticles(request: GetUnderStockArticlesRequest): Promise<IStockArticle[]> {
        const response: ResponseData<IStockArticleV2[]> = await this.ajaxServiceNew.Post("w/stock", "underStock", {
            methodData: request,
        });

        if (!response.succeeded) {
            // TODO gestione errore
            return [];
        }

        return toPascalCase(response.data);
    }

    createStockTransaction(stockTransaction: IStockTransaction): Promise<IStockTransaction> {
        if (stockTransaction.Id) {
            return Promise.reject<IStockTransaction>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "StockTransaction", {
            methodData: stockTransaction,
        });
    }

    changeTransaction(stockTransaction: IStockTransaction): Promise<IStockTransaction> {
        if (!stockTransaction.Id) {
            return Promise.reject<IStockTransaction>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "StockTransaction", {
            methodData: stockTransaction,
        });
    }

    adjustStock(transactionId: number, articleId: number, newStock: number): Promise<void> {
        return this.ajaxService.Post("Warehouse-api", "Movement", {
            methodData: { StockTransactionId: transactionId, ArticleId: articleId, Amount: newStock },
        });
    }

    adjustMinimumStock(transactionId: number, articleId: number, newMinimumStock: number): Promise<void> {
        return this.ajaxService.Post("Warehouse-api", "Movement/Minimum", {
            methodData: { StockTransactionId: transactionId, ArticleId: articleId, MinimumAmount: newMinimumStock },
        });
    }

    fillTransaction(transactionId: number, actions: any[]): Promise<void> {
        return this.ajaxService.Post("Warehouse-api", "Movement/Fill", {
            methodData: { StockTransactionId: transactionId, Actions: actions },
        });
    }

    createOrUpdateMovement(movement: IStockMovement): Promise<IStockMovement> {
        return this.ajaxService.Put("Warehouse-api", "Movement", {
            methodData: movement,
            background: true,
        });
    }

    removeMovement(movementId: number): Promise<void> {
        return this.ajaxService.Delete("Warehouse-api", "Movement/" + movementId, { background: true });
    }

    commitStockTransaction(transactionId: number): Promise<void> {
        return this.ajaxService.Put("Warehouse-api", "StockTransaction/" + transactionId, {});
    }

    rollbackStockTransaction(transactionId: number): Promise<void> {
        return this.ajaxService.Delete("Warehouse-api", "StockTransaction/" + transactionId, {});
    }

    getStockTransactions(
        filter: string,
        skip: number,
        count: number,
        warehouseId: number,
        loads: boolean,
        unloads: boolean,
        transfers: boolean,
        adjustments: boolean
    ): Promise<IStockTransaction[]> {
        if (!warehouseId) {
            return Promise.reject<IStockTransaction[]>(undefined);
        }

        var query =
            "&skip=" +
            skip +
            "&count=" +
            count +
            "&warehouseId=" +
            warehouseId +
            "&loads=" +
            loads +
            "&unloads=" +
            unloads +
            "&transfers=" +
            transfers +
            "&adjustments=" +
            adjustments;

        if (!filter) {
            return this.ajaxService.Get("Warehouse-api", "StockTransaction/Search?filter=" + query, {});
        }

        return this.ajaxService.Get(
            "Warehouse-api",
            "StockTransaction/Search?filter=" + encodeURI($.trim(filter)) + query,
            {}
        );
    }

    getStockTransactionsCount(
        filter: string,
        warehouseId: number,
        loads: boolean,
        unloads: boolean,
        transfers: boolean,
        adjustments: boolean
    ): Promise<number> {
        if (!warehouseId) {
            return Promise.reject<number>(undefined);
        }

        var query =
            "&warehouseId=" +
            warehouseId +
            "&loads=" +
            loads +
            "&unloads=" +
            unloads +
            "&transfers=" +
            transfers +
            "&adjustments=" +
            adjustments;

        if (!filter) {
            return this.ajaxService.Get("Warehouse-api", "StockTransaction/Count?filter=" + query, {});
        }

        return this.ajaxService.Get(
            "Warehouse-api",
            "StockTransaction/Count?filter=" + encodeURI($.trim(filter)) + query,
            {}
        );
    }

    getStockTransactionById(transactionId: number, skip: number, count: number): Promise<IStockArticle[]> {
        return this.ajaxService.Get(
            "Warehouse-api",
            "Movement?transactionId=" + transactionId + "&skip=" + skip + "&count=" + count,
            {}
        );
    }

    getStockTransactionCountById(transactionId: number): Promise<number> {
        return this.ajaxService.Get("Warehouse-api", "Movement/Count?transactionId=" + transactionId, {});
    }

    getHistoricalStocks(
        date: Date,
        filter: string,
        skip: number,
        count: number,
        warehouseId?: number,
        onlyInStock = false
    ): Promise<ISearchStockResult> {
        warehouseId = warehouseId || null;

        return this.ajaxService.Post("Warehouse-api", "HistoricalStock/Search", {
            methodData: {
                TextFilter: filter,
                Skip: skip,
                Count: count,
                WarehouseId: warehouseId,
                OnlyInStock: onlyInStock,
                Date: date,
            },
            background: true,
        });
    }

    getHistoricalStocksV2(
        date: Date,
        filter: string,
        skip: number,
        count: number,
        warehouseId?: number,
        onlyInStock = false,
        onlyPositiveStock = false,
        entityTypes = [ProlifeSdk.WarehouseArticleEntityTypeCode]
    ): Promise<ISearchStockResultV2> {
        warehouseId = warehouseId || null;

        return this.ajaxServiceNew.Post("w/HistoricalStock", "Search", {
            methodData: {
                textFilter: filter,
                skip: skip,
                count: count,
                warehouseId: warehouseId,
                onlyInStock: onlyInStock,
                date: date,
                onlyPositiveStock: onlyPositiveStock,
                entityTypes: entityTypes,
            },
            background: true,
        });
    }

    getHistoricalStocksCount(date: Date, filter: string, warehouseId?: number, onlyInStock = false): Promise<number> {
        warehouseId = warehouseId || null;

        return this.ajaxService.Post("Warehouse-api", "HistoricalStock/Count", {
            methodData: {
                TextFilter: filter,
                Skip: 0,
                Count: 0,
                WarehouseId: warehouseId,
                OnlyInStock: onlyInStock,
                Date: date,
            },
            background: true,
        });
    }

    getStockMovementsIntoPeriod(
        from: Date,
        to: Date,
        filter: string,
        skip: number,
        count: number,
        warehouseId?: number
    ): Promise<IStockMovement[]> {
        warehouseId = warehouseId || null;

        return this.ajaxService.Post("Warehouse-api", "StockMovementsIntoPeriod/Search", {
            methodData: {
                TextFilter: filter,
                Skip: skip,
                Count: count,
                WarehouseId: warehouseId,
                From: from,
                To: to,
            },
            background: true,
        });
    }

    getStockMovementsIntoPeriodCount(from: Date, to: Date, filter: string, warehouseId?: number): Promise<number> {
        warehouseId = warehouseId || null;

        return this.ajaxService.Post("Warehouse-api", "StockMovementsIntoPeriod/Count", {
            methodData: {
                TextFilter: filter,
                Skip: 0,
                Count: 0,
                WarehouseId: warehouseId,
                From: from,
                To: to,
            },
            background: true,
        });
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new StockService(serviceLocator);
}
