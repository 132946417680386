import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";

export class UserCharactersEditingViewModel
{
    title : string;
    elements : ko.ObservableArray<CharacterViewModel> = ko.observableArray();

    private dialogService : IDialogsService;
    private callRightTypesSettingsManager : ICallRightTypesSettingsManager;
    private workTimeCategoriesSettingsManager : IWorkTimeCategoriesSettingsManager;

    public HumanResourcesCharacters : ko.Computed<CharacterViewModel[]>;
    public MaterialResourcesCharacters : ko.Computed<CharacterViewModel[]>;

    constructor(private serviceLocator : IServiceLocator, private charactersManager : IUserCharactersSettingsManager)
    {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.callRightTypesSettingsManager = <ICallRightTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.CallRightTypesSettingsManagerType);
        this.workTimeCategoriesSettingsManager = <IWorkTimeCategoriesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType);
        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        this.title = charactersManager.getLabel();
        charactersManager.getUserCharacters()
            .forEach(this.createViewModelFor.bind(this));

        this.HumanResourcesCharacters = ko.computed(() => {
            return this.elements().filter((c : CharacterViewModel) => { return c.ResourceType == 0; });
        });

        this.MaterialResourcesCharacters = ko.computed(() => {
            return this.elements().filter((c : CharacterViewModel) => { return c.ResourceType != 0; });
        });
    }

    private createViewModelFor(character : IUserCharacter)
    {
        var characterVm = new CharacterViewModel(this.serviceLocator, this, character);
        this.elements.push(characterVm);
    }

    public createOrUpdateEntry(element : CharacterViewModel)
    {
        var character : IUserCharacter = element.getData();
        this.charactersManager.createOrUpdate(character)
            .then((updatedcharacter) => { element.update(updatedcharacter); });
    }

    public AddNewHumanResourcesCharacter()
    {

        this.AddNewCharacter({
            IdUserCharacter : -1,
            Description: ProlifeSdk.TextResources.Users.NewRole,
            Salary : 0,
            Cost : 0,
            UseCostInsteadOfMediumCost : false,
            IsDefault : 0,
            DefaultCallRightType : -1,
            DefaultWorkTimeCategory : -1,
            ResourceType : 0,
            Code : "",
            FkOperationalUnit : 1
        });
    }

    public AddNewMaterialResourcesCharacter()
    {
        this.AddNewCharacter({
            IdUserCharacter : -1,
            Description: ProlifeSdk.TextResources.Users.NewRole,
            Salary : 0,
            Cost : 0,
            UseCostInsteadOfMediumCost : false,
            IsDefault : 0,
            DefaultCallRightType : -1,
            DefaultWorkTimeCategory : -1,
            ResourceType : 1,
            Code : ""
        });
    }

    private AddNewCharacter(character : IUserCharacter)
    {
        var defaultCallRightType : ICallRightType = this.callRightTypesSettingsManager.getDefault(character.ResourceType);
        var defaultWorkTimeCategory : IWorkTimeCategory = this.workTimeCategoriesSettingsManager.getDefault(character.ResourceType);

        character.DefaultCallRightType = defaultCallRightType ? defaultCallRightType.Id : null;
        character.DefaultWorkTimeCategory = defaultWorkTimeCategory ? defaultWorkTimeCategory.Id : null;

        var resourceViewModel : CharacterViewModel = new CharacterViewModel(this.serviceLocator, this, character);
        this.elements.push(resourceViewModel);
        this.createOrUpdateEntry(resourceViewModel);
    }

    public deleteEntry(element : CharacterViewModel)
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.Users.SureToDeleteRole, ProlifeSdk.TextResources.Users.DoNotDeleteRole, ProlifeSdk.TextResources.Users.DeleteRole, (dialogResult) => {
            if(dialogResult)
            {
                this.elements.remove(element);
                var character : IUserCharacter = element.getData();
                this.charactersManager.remove(character.IdUserCharacter);
            }
        })
    }
}

export class CharacterViewModel
{
    private sdkService : IProLifeSdkService;
    public Id : number;
    public Description : ko.Observable<string> = ko.observable("");
    public Salary : ko.Observable<number> = ko.observable(0);
    public Code : ko.Observable<string> = ko.observable("");
    public DefaultCallRightType : ko.Observable<number> = ko.observable(0);
    public DefaultWorkTimeCategory : ko.Observable<number> = ko.observable(0);

    private updating : boolean = false;

    public ResourceType : number;

    WorkTimeCategories : IWorkTimeCategory[];
    CallRightTypes : ICallRightType[];

    constructor(private serviceLocator : IServiceLocator, private container : UserCharactersEditingViewModel, private character : IUserCharacter)
    {
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);

        var code : string = character.IdUserCharacter ? this.sdkService.Utilities.Numbers.ZeroPad(character.IdUserCharacter.toString(), 5) : "";

        this.Id = character.IdUserCharacter;
        this.Description(character.Description);
        this.Code(this.GetCode(character));
        this.Salary(character.Salary);
        this.DefaultCallRightType(character.DefaultCallRightType);
        this.DefaultWorkTimeCategory(character.DefaultWorkTimeCategory);
        this.ResourceType = character.ResourceType;

        this.Description.subscribe(this.onDataChanged.bind(this));
        this.Salary.subscribe(this.onDataChanged.bind(this));
        this.DefaultCallRightType.subscribe(this.onDataChanged.bind(this));
        this.DefaultWorkTimeCategory.subscribe(this.onDataChanged.bind(this));

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var workTimeCatManager : IWorkTimeCategoriesSettingsManager = <IWorkTimeCategoriesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType);
        var callRightTypesManager : ICallRightTypesSettingsManager = <ICallRightTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.CallRightTypesSettingsManagerType);
        this.WorkTimeCategories = workTimeCatManager.getAll(false).filter((w : IWorkTimeCategory) => { return w.ResourceType == this.ResourceType; });
        this.CallRightTypes = callRightTypesManager.getAll(false, this.ResourceType);
    }

    private GetCode(character : IUserCharacter)
    {
        var docService : IDocumentsService = <IDocumentsService>this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        var activationCode : string = docService.getRowReferencesGeneratorFactoryForEntity(ProlifeSdk.EstimatedWorkEntityTypeCode).ActivationCode;
        var code : string = character.IdUserCharacter ? activationCode + this.sdkService.Utilities.Numbers.ZeroPad(character.IdUserCharacter.toString(), 5) : "";
        return code;
    }

    public Delete()
    {
        this.container.deleteEntry(this);
    }

    private onDataChanged()
    {
        if(this.updating)
            return;

        this.container.createOrUpdateEntry(this);
    }

    getData() : IUserCharacter
    {
        var character : IUserCharacter = <IUserCharacter> $.extend({}, this.character);
        character.Description = this.Description();
        character.Salary = this.Salary();
        character.DefaultCallRightType = this.DefaultCallRightType();
        character.DefaultWorkTimeCategory = this.DefaultWorkTimeCategory();
        return character;
    }

    update(character : IUserCharacter) : void
    {
        this.updating = true;

        this.character = character;
        this.Description(character.Description);
        this.Code(this.GetCode(character));
        this.Salary(character.Salary);
        this.DefaultCallRightType(character.DefaultCallRightType);
        this.DefaultWorkTimeCategory(character.DefaultWorkTimeCategory);
        this.updating = false;
    }

    setWorkTimeCategory(c : IWorkTimeCategory)
    {
        this.DefaultWorkTimeCategory(c.Id);
    }

    setCallRightType(t : ICallRightType)
    {
        this.DefaultCallRightType(t.Id);
    }
}
