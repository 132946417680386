import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IFileRepositoryService } from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";

export class JobOrdersCostsReport extends ProLifeReport implements IReport, IReportViewModel {
    viewModel: any;
    Name: string = ProlifeSdk.TextResources.JobOrder.JobOrdersCostsReport;
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";
    detailsTemplateName: string = "jobOrderCostsReport";
    detailsTemplateUrl: string = "joborder/templates/reports";
    navigator: IReportsNavigator;

    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();

    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private fileRepositoryService: IFileRepositoryService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(groupId: number) {
        super(groupId, 7);
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    initialize() {
        this.StartDate(moment().startOf("year").toDate());
        this.EndDate(moment().endOf("year").toDate());
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }

    public getExcelExportData(): any | null {
        return {
            startDate: this.StartDate(),
            endDate: this.EndDate(),
        };
    }
}
