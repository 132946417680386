import * as ko from "knockout";
import { ReferencesMapConnector } from "./ReferencesMapConnector";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService, IEntityDescriptor } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { INodeDetails, IReferenceForMap } from "../../../ProlifeSdk/interfaces/invoice/IReferencesMap";

var MapNodeSimpleEntityId : string = "SIMPLE_ENTITY";

export class ReferencesMapNode
{
    private entitiesService : IEntityProviderService;

    public IsSelected : boolean;
    public IsAnalyzedNode : boolean;
    public IsSource : boolean;
    public IsDestination : boolean;
    public Identifier : string;
    public SourceConnectors : ReferencesMapConnector[];
    public EntityDescriptor : IEntityDescriptor;
    public EntityId : any; //TODO da rivedere il modo in cui viene utilizzato
    public EntityType : string;
    public NodeDetails : INodeDetails;

    public IsSimpleEntitiesSource : boolean;

    constructor(private serviceLocator : IServiceLocator, private documentId : number, private documentType : string, allReferences : IReferenceForMap[], isAnalyzed : boolean, isSource : boolean, isDestination : boolean,
                private simpleEntitiesSourceClickHandler : (entityType : string) => {},
                private sourceClickHandler : (entityId : number, entityType : string) => {},
                private destinationClickHandler : (entityId : number, entityType : string) => {})
    {
        this.IsSimpleEntitiesSource = documentId == null;
        this.EntityId = documentId || MapNodeSimpleEntityId;
        this.EntityType = documentType;
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.EntityDescriptor = this.entitiesService.GetEntityDescriptor(documentType) || <IEntityDescriptor>{
            EntityCode : documentType,
            EntityName : ProlifeSdk.TextResources.Invoices.Unknown,
            PluralEntityName : ProlifeSdk.TextResources.Invoices.Unknowns
        };
        this.Identifier = ReferencesMapNode.GetIdentifier(this.EntityId, this.EntityType);
        this.IsSelected = isAnalyzed;
        this.IsAnalyzedNode = isAnalyzed;
        this.IsSource = isSource;
        this.IsDestination = isDestination;
        this.CreateConnectors(allReferences);
    }

    public LoadDetails(allNodesDetails : INodeDetails[])
    {
        var matches = allNodesDetails.filter((n : INodeDetails) => { return n.NodeId == this.EntityId && n.NodeType == this.EntityType; });
        this.NodeDetails = matches.length > 0 ? matches[0] : null;
    }

    public OnNodeClicked()
    {
        if(this.IsSource && this.IsSimpleEntitiesSource && this.simpleEntitiesSourceClickHandler)
            this.simpleEntitiesSourceClickHandler(this.EntityType);
        else if(this.IsSource && this.sourceClickHandler)
            this.sourceClickHandler(this.EntityId, this.EntityType);
        else if(this.IsDestination && this.destinationClickHandler)
            this.destinationClickHandler(this.EntityId, this.EntityType);
    }

    private CreateConnectors(allReferences : IReferenceForMap[])
    {
        this.SourceConnectors = [];
        allReferences.forEach((r : IReferenceForMap) => {
            if(r.DestDocId != this.EntityId || r.DestEntityType != this.EntityType)
                return;
            var matches = this.SourceConnectors.filter((c : ReferencesMapConnector) => { return c.sourceId == r.SourceDocId && c.sourceEntityType == r.SourceEntityType; });
            var connector = matches.length > 0 ? matches[0] : new ReferencesMapConnector(r.SourceDocId, r.SourceEntityType, this.EntityId, this.EntityType, allReferences);

            if(matches.length == 0)
                this.SourceConnectors.push(connector);
        });
    }

    public static GetIdentifierFromReferenceSource(r : IReferenceForMap)
    {
        return ReferencesMapNode.GetIdentifier(r.SourceDocId, r.SourceEntityType);
    }

    public static GetIdentifierFromReferenceDest(r : IReferenceForMap)
    {
        return ReferencesMapNode.GetIdentifier(r.DestDocId, r.DestEntityType);
    }

    public static GetIdentifier(entityId : number, entityType : string) : string
    {
        return (entityId || MapNodeSimpleEntityId) + "_" + entityType;
    }
}