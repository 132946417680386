import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 30/06/2017
 * Time: 12:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { SectionForEdit } from "../SectionForEdit";
import { QuestionnaireEditViewModel } from "../QuestionnaireEditViewModel";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ISectionForEdit, ISection } from "../../../../../../ProlifeSdk/interfaces/survey/ISection";

export class SectionEditDialog implements IDialog {
    public templateName: string = "edit-section-dialog";
    public templateUrl: string = "survey/templates/questionnaire/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.EditSectionDialogAddTitle;
    public modal: { close: (result?: any) => void; };

    public Section: ko.Observable<any> = ko.observable();
    public InsertMode: boolean = true;

    private OriginalData: ISectionForEdit = null;

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, section: SectionForEdit, private parent: QuestionnaireEditViewModel) {
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        if (!section) {
            this.Section(this.newSection());
        }

        if (section) {
            this.title = ProlifeSdk.TextResources.Survey.EditSectionDialogEditTitle;
            this.InsertMode = false;
            this.Section(section);
            this.OriginalData = section.ToJSON();
        }
    }

    action(): void {
        if (!this.Section().Name()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.SectionNameRequired);
            return;
        }
        if (!this.Section().Id) {
            this.Section().Id = this.parent.getAvailableSectionId();
            this.parent.Sections.push(this.Section());
            this.parent.NewSectionId += 1;
        }
        this.modal.close();
    }

    close(): void {
        if (!!this.OriginalData)
            this.resetChanges();
        this.modal.close();
    }

    public newSection(): SectionForEdit {
        var section = new SectionForEdit(
            this.serviceLocator,
                <ISection>  {
                    Id: null,
                    Name: null,
                    Description: null,
                    Enabled: true,
                    Index: this.parent.SectionsNumber() + 1,
                    CreationDate: new Date,
                    CreatorId: null,
                    ModifyDate: new Date,
                    ModifierId: null,
                    Label: this.calculateLabel(),
                    QuestionnaireId: this.parent.Questionnaire().Id,
                    Deleted: false,
                    PublicLabel: null
                },
                true,
                this.parent
            );
        section.RegisterSelectionObserver(this.parent);
        return section;
    }

    private calculateLabel(): string {
        var level: number = Math.floor(this.parent.NewSectionId / 26);
        var charCode: number = (this.parent.NewSectionId % 26) + 64;
        var label: string = level > 0 ? String.fromCharCode(level + 64) : "";
        label += String.fromCharCode(charCode);
        return label;
    }

    private resetChanges(): void {
        this.Section().Name(this.OriginalData.Name);
        this.Section().Description(this.OriginalData.Description);
        this.Section().Enabled(this.OriginalData.Enabled);
        this.Section().Index(this.OriginalData.Index);
        this.Section().CreationDate(this.OriginalData.CreationDate);
        this.Section().CreatorId(this.OriginalData.CreatorId);
        this.Section().ModifyDate(this.OriginalData.ModifyDate);
        this.Section().ModifierId(this.OriginalData.ModifierId);
        this.Section().Label(this.OriginalData.Label);
        this.Section().Deleted(this.OriginalData.Deleted);
    }
}