import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowNavigator } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { ITodoListWorkflowForList } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { INavigationMenuObserver } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { IWorkflowRevenue } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowRevenue";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class WorkflowEconomicsDialog implements IDialog, INavigationMenuObserver {
    templateName = "workflow-economics-dialog";
    templateUrl = "joborder/templates/joborderdetail/workflow-economics";
    title: string = ProlifeSdk.TextResources.JobOrder.WorkflowEconomicsDialogTitle;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    private ActivitiesBar: IWorkflowNavigator;

    public SelectedEconomics: ko.Observable<WorkflowEconomics> = ko.observable();
    private CachedEconomics: ko.ObservableArray<WorkflowEconomics> = ko.observableArray([]);

    modal: {
        close: (result?: any) => void;
    };

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        const allRevenues = this.getData();

        const invalidRows = allRevenues.filter((r) => (r.Description || "").length == 0);
        if (invalidRows.length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.JobOrder.PleaseInsertDescriptionBeforeSaving);
            return;
        }

        this.todoListService.SaveWorkflowsRevenues(this.jobOrderId, allRevenues).then(() => {
            this.modal.close(true);
        });
    }

    constructor(private jobOrderId: number, totalEstimateRevenues: number, totalRevenues: number) {
        this.ActivitiesBar = this.todoListService.GetWorkflowNavigatorUI({
            DisableWorkflowSelection: false,
            DisableWorkflowEditing: true,
            DisableTaskEditing: true,
            DisableTasksNavigation: true,
        });

        this.ActivitiesBar.setMultipleSelectionStatus(false);
        this.ActivitiesBar.setSingleSelectionStatus(true, true);
        this.ActivitiesBar.addMenuObserver(this);

        this.ActivitiesBar.Initialize([jobOrderId]);

        this.todoListService.GetWorkflowsForList(jobOrderId, null).then((ws: ITodoListWorkflowForList[]) => {
            const economics = ws.map(
                (w: ITodoListWorkflowForList) => new WorkflowEconomics(w.Id, totalEstimateRevenues, totalRevenues)
            );
            this.CachedEconomics(economics);
        });
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {}

    onSelectionChanged(selection: INavigationMenuProvider[], navigator: INavigationMenu) {
        if (selection.length == 0) this.SelectedEconomics(null);
        else this.SelectedEconomics(this.findCachedEconomics((<any>selection[0]).WorkflowId));
    }

    private findCachedEconomics(workflowId: number) {
        const cachedEconomics = this.CachedEconomics().filter((r: WorkflowEconomics) => r.workflowId == workflowId);
        if (cachedEconomics.length > 0) return cachedEconomics[0];
        return null;
    }

    public getData(): IWorkflowRevenue[] {
        let allRevenues: IWorkflowRevenue[] = [];
        this.CachedEconomics().forEach((e: WorkflowEconomics) => (allRevenues = allRevenues.concat(e.getData())));

        allRevenues.forEach((r: IWorkflowRevenue) => {
            r.JobOrderId = this.jobOrderId;
        });

        return allRevenues;
    }
}

class WorkflowEconomics {
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;

    public EstimatedRevenuesRows: ko.ObservableArray<WorkflowEconomicsRow> = ko.observableArray([]);
    public RevenuesRows: ko.ObservableArray<WorkflowEconomicsRow> = ko.observableArray([]);

    public TotalEstimatedRevenues: ko.Computed<number>;
    public TotalRevenues: ko.Computed<number>;

    constructor(public workflowId: number, public totalEstimateRevenues: number, public totalRevenues: number) {
        this.todoListService.GetWorkflowsRevenuesForWorkflow(workflowId).then((r: IWorkflowRevenue[]) => {
            const estimatedRevenues = r.filter((wr) => wr.IsEstimatedRevenue);
            const revenues = r.filter((wr) => !wr.IsEstimatedRevenue);

            this.EstimatedRevenuesRows(
                estimatedRevenues.map((wr) => new WorkflowEconomicsRow(this.modulesService, wr))
            );
            this.RevenuesRows(revenues.map((wr) => new WorkflowEconomicsRow(this.modulesService, wr)));
        });

        this.TotalEstimatedRevenues = ko.computed(() => {
            let total = 0;

            this.EstimatedRevenuesRows().forEach((r: WorkflowEconomicsRow) => {
                total += r.Amount();
            });

            return total;
        });

        this.TotalRevenues = ko.computed(() => {
            let total = 0;

            this.RevenuesRows().forEach((r: WorkflowEconomicsRow) => {
                total += r.Amount();
            });

            return total;
        });
    }

    public NewEstimatedRevenue() {
        this.EstimatedRevenuesRows.push(new WorkflowEconomicsRow(this.modulesService));
    }

    public NewRevenue() {
        this.RevenuesRows.push(new WorkflowEconomicsRow(this.modulesService));
    }

    public RemoveEstimatedRevenuesRow(row: WorkflowEconomicsRow) {
        this.EstimatedRevenuesRows.remove(row);
    }

    public RemoveRevenuesRow(row: WorkflowEconomicsRow) {
        this.RevenuesRows.remove(row);
    }

    public getData(): IWorkflowRevenue[] {
        const allRows: IWorkflowRevenue[] = [];

        this.EstimatedRevenuesRows().forEach((r: WorkflowEconomicsRow) => {
            const row = r.getData();
            row.WorkflowId = this.workflowId;
            row.IsEstimatedRevenue = true;
            allRows.push(row);
        });

        this.RevenuesRows().forEach((r: WorkflowEconomicsRow) => {
            const row = r.getData();
            row.WorkflowId = this.workflowId;
            row.IsEstimatedRevenue = false;
            allRows.push(row);
        });

        return allRows;
    }
}

class WorkflowEconomicsRow {
    public Description: ko.Observable<string> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public SubType: ko.Observable<string> = ko.observable();

    public SubTypes: any[] = [
        { Id: "OTH", Description: ProlifeSdk.TextResources.JobOrder.Other },
        { Id: ProlifeSdk.EstimatedWorkEntityTypeCode, Description: ProlifeSdk.TextResources.JobOrder.WorkEntity },
        { Id: ProlifeSdk.EstimatedPurchaseEntityTypeCode, Description: ProlifeSdk.TextResources.JobOrder.Purchases },
    ];

    constructor(private modulesService: IModulesService, private row?: IWorkflowRevenue) {
        if (this.modulesService.IsModuleEnabled(ProlifeSdk.WarehouseApplicationCode))
            this.SubTypes.push({ Id: "WAR", Description: ProlifeSdk.TextResources.JobOrder.WarehouseArticles });

        if (row) {
            this.Description(row.Description);
            this.Amount(row.Amount);
            this.SubType(row.Type);
        } else {
            this.SubType("OTH");
        }
    }

    public getData(): IWorkflowRevenue {
        return {
            Id: this.row ? this.row.Id : -1,
            JobOrderId: -1,
            WorkflowId: -1,
            IsEstimatedRevenue: false,
            Type: this.SubType(),
            Description: this.Description(),
            Amount: this.Amount(),
        };
    }
}
