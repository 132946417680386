import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 17/07/2017
 * Time: 09:26
 * To change this template use File | Settings | File Templates.
 */

import { ISample } from "../../../../ProlifeSdk/interfaces/survey/ISample";

export class Sample {
    public Id: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Size: ko.Observable<number> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();
    public StateName: ko.Observable<string> = ko.observable();
    public Locked: ko.Observable<boolean> = ko.observable();
    public Visible: ko.Observable<boolean> = ko.observable();
    public TypeId: ko.Observable<number> = ko.observable();
    public TypeName: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public PublicId: ko.Observable<string> = ko.observable();

    constructor(public currentSample: ISample) {
        this.Load(currentSample);
    }

    public Load(sample : ISample)
    {
        this.Id(sample.Id);
        this.Title(sample.Title);
        this.Description(sample.Description);
        this.Size(sample.Size);
        this.CreatorId(sample.CreatorId);
        this.CreationDate(sample.CreationDate);
        this.ModifierId(sample.ModifierId);
        this.ModifyDate(sample.ModifyDate);
        this.StateId(sample.StateId);
        this.StateName(sample.StateName);
        this.Locked(sample.Locked);
        this.Visible(sample.Visible);
        this.TypeId(sample.TypeId);
        this.TypeName(sample.TypeName);
        this.Icon(sample.Icon);
        this.Background(sample.Background);
        this.Foreground(sample.Foreground);
        this.PublicId(sample.PublicId);
    }

    public ToJSON(): ISample {
        return <ISample> {
            Id: this.Id(),
            Title: this.Title(),
            Description: this.Description(),
            Size: this.Size(),
            CreatorId: this.CreatorId(),
            CreationDate: this.CreationDate(),
            ModifierId: this.ModifierId(),
            ModifyDate: this.ModifyDate(),
            StateId: this.StateId(),
            StateName: this.StateName(),
            Locked: this.Locked(),
            Visible: this.Visible(),
            TypeId: this.TypeId(),
            TypeName: this.TypeName(),
            Icon: this.Icon(),
            Background: this.Background(),
            Foreground: this.Foreground(),
            PublicId: this.PublicId()
        }
    }
}