import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

export function DocumentSecondaryRecipients() {
    return  <ko-if data-bind="CanHaveSecondaryRecipients">
                <div class="invoice-secondary-recipients" data-bind="visible: SecondaryRecipientsEnabled">
                    <table class="table table-condensed no-border table-advance">
                        <thead>
                        <tr>
                            <th>
                                {TextResources.Invoices.DocumentSecondaryRecipients}
                                <div class="pull-right">
                                    <button type="button" class="btn btn-primary btn-xs" data-bind="click: AddSecondaryRecipient">
                                        <span class="fa-stack" style="height: 1.4em;">
                                            <i class="fa fa-stack-2x fa-user" style="font-size: 1.1em;"></i>
                                            <i class="fa fa-inverse fa-plus fa-stack-1x" style="top: 6px; left: 11px; font-size: 0.8em"></i>
                                        </span>
                                    </button>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr data-bind="visible: SecondaryRecipients().length == 0">
                            <td class="text-center">{TextResources.Invoices.DocumentNoSecondaryRecipient}</td>
                        </tr>
                        <ko-foreach data-bind="SecondaryRecipients">
                            <tr>
                                <td>
                                    <ko-if data-bind="CustomerIsLocked">
                                        <div class="customer-is-locked"></div>
                                    </ko-if>
                                    <div class="invoice-secondary-recipient-row">
                                        <span style="width: 11px; height: 11px; background-color: red; margin-right: 5px;"></span>
                                        <span class="secondary-recipient-name">
                                            <ko-if data-bind="LegalPerson">
                                                <input 
                                                    autoComplete="off"
                                                    style="width: 100%;" 
                                                    type="text" 
                                                    class="input-block-level" 
                                                    placeholder={TextResources.Invoices.DocumentSearchCustomerPlaceholder} data-bind="contactsValue: CustomerId, value: CustomerBusinessName, forSuppliers: ForSuppliers, forCustomers: ForCustomers"/>
                                            </ko-if>
                                            <ko-ifnot data-bind="LegalPerson">
                                                <div style="height: 18px; position: relative;">
                                                    <span style="position: absolute; width: 95px">
                                                        <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentSearchCustomerPlaceholder} data-bind="contactsValue: CustomerId, value: CustomerName, forSuppliers: ForSuppliers, forCustomers: ForCustomers, alternativeDisplayKey: 'Nome'" />
                                                    </span>
                                                    <span style="position: absolute; width: 95px; right: 0px">
                                                        <input autoComplete="off" type="text" class="input-block-level" data-bind="value: CustomerSurname" />
                                                    </span>
                                                </div>
                                            </ko-ifnot>
                                        </span>
                                        <span style="width: 185px">
                                            <span style="margin: 0 5px">{TextResources.Invoices.DocumentVATCode}</span>
                                            <span>
                                                <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentVATCodePlaceholder} data-bind="value: CustomerPIVA"/>
                                            </span>
                                        </span>
                                        <span style="width: 185px">
                                            <span style="margin: 0 5px">{TextResources.Invoices.DocumentTAXCode}</span>
                                            <span>
                                                <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentTAXCodePlaceholder} data-bind="value: CustomerCF"/>
                                            </span>
                                        </span>
                                        <span>
                                            <div> {TextResources.Invoices.DocumentLegalPerson}
                                                <div class="checker">
                                                    <span data-bind="css : { checked: LegalPerson }">
                                                        <input type="checkbox" class="secondary-recipient-legal-person" data-bind="checked : LegalPerson"/>
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="invoice-secondary-recipient-row">
                                        <span style="width: 182px; margin-left: 19px">
                                            <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentAddress} data-bind="value: CustomerAddress"/>
                                        </span>
                                        <span style="width: 40px">
                                            <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentPostalCode} data-bind="value: CustomerCAP"/>
                                        </span>
                                        <span>
                                            <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentCity} data-bind="value: CustomerCity"/>
                                        </span>
                                        <span>
                                            <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentMunicipality} data-bind="value: CustomerMunicipality"/>
                                        </span>
                                        <span style="width: 60px; position: relative; top: -1px;" data-bind="with: ProvincesProvider">
                                            <ko-if data-bind="SelectedNationCode() != 'IT'">
                                                <input autoComplete="off" type="text" class="input-block-level" placeholder={TextResources.Invoices.DocumentProvince} data-bind="value: SelectedProvinceCode"/>
                                            </ko-if>
                                            <ko-ifnot data-bind="SelectedNationCode() != 'IT'">
                                                <input 
                                                    type="hidden" 
                                                    class="form-control" 
                                                    data-bind={`select2: { value: SelectedProvinceCode, query: $data.Search.bind($data), initSelection: $data.Init.bind($data), allowClear: true, placeholder: '${TextResources.Invoices.DocumentProvincePlaceholder}@T.Label("Seleziona la Provincia...")', multiple: false, minimumInputLength: 1, formatResult: $data.GetFormattedNameForList.bind($data), formatSelection: $data.GetFormattedCodeForSelection.bind($data), escapeMarkup: function (m) { return m; }, dropdownCss: { width: '200px' }, containerCssClass: 'select2-container-for-documents' }`} />
                                            </ko-ifnot>
                                        </span>
                                        <span style="width: 60px; position: relative; top: -1px;" data-bind="with: NationsProvider">
                                            <input type="hidden" class="form-control" data-bind={`select2: { value: SelectedNationCode, query: $data.Search.bind($data), initSelection: $data.Init.bind($data), allowClear: true, placeholder: '${TextResources.Invoices.DocumentStatePlaceholder}', multiple: false, minimumInputLength: 1, formatResult: $data.GetFormattedNameForList.bind($data), formatSelection: $data.GetFormattedCodeForSelection.bind($data), escapeMarkup: function (m) { return m; }, dropdownCss: { width: '200px' }, containerCssClass: 'select2-container-for-documents' }`} />
                                        </span>
                                        <span style="width: 54px" class="pull-right">
                                            <ko-if data-bind="CustomerId">
                                                <a class="btn grey-cascade btn-xs" data-bind="attr : { href : CustomerUrl, target: '_blank' }">
                                                    <i class="fa fa-search-plus"></i>
                                                </a>
                                            </ko-if>
                                            <button class="btn btn-danger btn-xs pull-right" type="button" data-bind="click: Delete">
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </ko-foreach>
                        </tbody>
                    </table>
                </div>
            </ko-if>;
}

export function DocumentSecondaryRecipientsReadOnly() {
    return  <ko-if data-bind="CanHaveSecondaryRecipients">
                <div class="invoice-secondary-recipients" data-bind="visible: SecondaryRecipientsEnabled">
                    <table class="table table-condensed no-border table-advance">
                        <thead>
                        <tr>
                            <th>
                                {TextResources.Invoices.DocumentSecondaryRecipients}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr data-bind="visible: SecondaryRecipients().length == 0">
                            <td class="text-center">{TextResources.Invoices.DocumentNoSecondaryRecipient}</td>
                        </tr>
                        <ko-foreach data-bind="SecondaryRecipients">
                        <tr>
                            <td>
                                <ko-if  data-bind="CustomerIsLocked">
                                <div class="customer-is-locked"></div>
                                </ko-if>
                                <div class="invoice-secondary-recipient-row">
                                    <span style="width: 11px; height: 11px; background-color: red; margin-right: 5px;"></span>
                                    <a data-bind="attr: { href: CustomerUrl, target: '_blank' }"><span style="width: 300px" data-bind="text: (LegalPerson() ? CustomerBusinessName() : CustomerName() + ' ' + CustomerSurname())"></span></a>
                                    <span class="bold">{TextResources.Invoices.DocumentTAXCode}</span>&nbsp;<span style="width: 150px" data-bind="text: (!CustomerCF() ? 'N/A' : CustomerCF())"></span>
                                    <span class="bold">{TextResources.Invoices.DocumentVATCode}</span>&nbsp;<span style="width: 150px" data-bind="text: (!CustomerPIVA() ? 'N/A' : CustomerPIVA())"></span>
                                </div>
                                <div class="invoice-secondary-recipient-row">
                                    <span style="margin-left: 20px;" data-bind="text: FormattedAddress"></span>
                                </div>
                            </td>
                        </tr>
                        </ko-foreach>
                        </tbody>
                    </table>
                </div>
            </ko-if>;
}