import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 15/02/13
 * Time: 13.02
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDdtCauseUsage, IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDDTCause, IDDTCauseCategory, IDDTCauses } from "../../../../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";

export interface IDDTCauseProvider {
    isChanged(): boolean;
    getData() : IDDTCause;
    update(appearance : IDDTCause) : void;
}

export class DDTCausesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IDDTCauseProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    categories : IDDTCauseCategory[];
    
    private deletedElements : IDDTCause[] = [];

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast : IInfoToastService;

    constructor(private ddtCausesManager : IDDTCauses) {
        this.title = ddtCausesManager.getLabel();

        this.refreshList();
    }

    private createViewModelFor(cause : IDDTCause) {
        return new DDTCauseViewModel(this, cause);
    }

    public addNewSetting() {
        var causeViewModel = new DDTCauseViewModel(this, {
            Descrizione: this.newSettingName(),
            Tipologia: 1,
            StatoChiusuraInizialeDoc: 0,
            UpdateWarehouseStocks: true,
            UpdateArticlesMeanCostsAndPrices: true
        });

        causeViewModel.IsLocked(false);

        this.elements.push(causeViewModel);
        this.newSettingName("");
    }

    public deleteEntry(element : IDDTCauseProvider) {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Invoices.DeleteReasonMsg, ProlifeSdk.TextResources.Invoices.No, ProlifeSdk.TextResources.Invoices.Yes, this.onDeleteConfirm.bind(this, element));
    }

    private onDeleteConfirm(element : IDDTCauseProvider, result : boolean) {
        if(!result) return;
        this.elements.remove(element);
        var cause = element.getData();
        if(cause.Id > 0)
            this.deletedElements.push(cause);
    }

    public updateAll(){
        var deletePromises = this.deletedElements
            .map((cause) => this.ddtCausesManager.remove(cause.Id)) || [];

        var updatePromises = this.elements()
            .filter((causeVM) => causeVM.isChanged())
            .map((causeVM) => this.ddtCausesManager.createOrUpdate(causeVM.getData())) || [];

        var allPromises: Promise<any>[] = deletePromises.concat(<Promise<any>[]>updatePromises);
        if(allPromises.length == 0) {
            this.infoToast.Info(ProlifeSdk.TextResources.Invoices.NoChangesToBeSaved);
            return;
        }

        Promise.all(allPromises)
            .then(this.onUpdateCompleted.bind(this))
            .catch(this.onUpdateFailed.bind(this));
    }

    private onUpdateCompleted() {
        this.infoToast.Success(ProlifeSdk.TextResources.Invoices.ChangesSaved);

        this.ddtCausesManager.load()
            .then(this.refreshList.bind(this));
    }

    private onUpdateFailed() {
        this.infoToast.Error(ProlifeSdk.TextResources.Invoices.ErrorWhileSaving);
    }

    public refreshList() {
        this.elements.removeAll();
        this.deletedElements = [];

        this.categories = this.ddtCausesManager.getDDTCauseCategories();
        var causes = this.ddtCausesManager.getDDTCauses();

        this.invoicesService.GetDdtsCausesUsage()
            .then((causesUsage: IDdtCauseUsage[]) => {
                causes.forEach((c) => {
                    let causeVm = this.createViewModelFor(c);
                    let usageMatch = causesUsage.filter((u) => u.CauseId === c.Id);
                    causeVm.IsLocked(usageMatch.length > 0 && usageMatch[0].DocumentsCount > 0);
                    this.elements.push(causeVm);
                });
            });
    }

    public getCategoryById(categoryId : number) : IDDTCauseCategory {
        return this.categories.filter((cat) => cat.Id == categoryId)[0];
    }
}

class DDTCauseViewModel implements IDDTCauseProvider {
    name : ko.Observable<string> = ko.observable();
    category: ko.Observable<string> = ko.observable();
    StatoChiusuraInizialeDoc : ko.Observable<boolean> = ko.observable(false);
    UpdateWarehouseStocks: ko.Observable<boolean> = ko.observable(true);
    UpdateArticlesMeanCostsAndPrices: ko.Observable<boolean> = ko.observable(true);
    IsLocked: ko.Observable<boolean> = ko.observable(true);

    LockSwitches: ko.Observable<boolean> = ko.observable(true);

    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);

    public DisableUpdateArticlesMeanCostsAndPrices: ko.Computed<boolean>;
    public DisableUpdateWarehousesStocks: ko.Computed<boolean>;

    private categoryId : number;
    

    constructor(private container : DDTCausesEditingViewModel, private cause : IDDTCause) {
        this.LockSwitches(false);

        this.name(cause.Descrizione);

        var category = container.getCategoryById(cause.Tipologia);
        this.setCategory(category);

        this.StatoChiusuraInizialeDoc(cause.StatoChiusuraInizialeDoc != 0);
        this.UpdateWarehouseStocks(cause.UpdateWarehouseStocks);
        this.UpdateArticlesMeanCostsAndPrices(cause.UpdateArticlesMeanCostsAndPrices);

        this.UpdateWarehouseStocks.subscribe((v) => {
            if (!v) {
                this.UpdateArticlesMeanCostsAndPrices(false);
            }
        });

        this.DisableUpdateArticlesMeanCostsAndPrices = ko.computed(() => {
            return this.LockSwitches() && (!this.UpdateWarehouseStocks() || this.IsLocked());
        });

        this.DisableUpdateWarehousesStocks = ko.computed(() => {
            this.category();
            return this.LockSwitches() && (this.IsLocked() || this.categoryId === ProlifeSdk.DDTCausalTransferType);
        });

        // HACK per far settare correttamente il valore degli switch in interfaccia
        setTimeout(() => this.LockSwitches(true), 100);
    }

    getData() : IDDTCause {
        var cause : IDDTCause = <IDDTCause> $.extend({}, this.cause);
        cause.Descrizione = this.name();
        cause.Tipologia = this.categoryId;
        cause.StatoChiusuraInizialeDoc = this.StatoChiusuraInizialeDoc() ? 1 : 0;
        cause.UpdateWarehouseStocks = this.UpdateWarehouseStocks();
        cause.UpdateArticlesMeanCostsAndPrices = this.UpdateArticlesMeanCostsAndPrices();
        return cause;
    }

    update(cause : IDDTCause) : void {
        this.LockSwitches(false);

        this.cause = cause;
        this.name(this.cause.Descrizione);
        this.UpdateWarehouseStocks(cause.UpdateWarehouseStocks);
        this.UpdateArticlesMeanCostsAndPrices(cause.UpdateArticlesMeanCostsAndPrices);

        this.LockSwitches(true);
    }

    setCategory(category : IDDTCauseCategory) {
        this.categoryId = category.Id;

        if (this.categoryId === ProlifeSdk.DDTCausalTransferType)
            this.UpdateWarehouseStocks(true);

        this.category(category.Descrizione);
    }

    toggleStatoChiusura() {
        this.StatoChiusuraInizialeDoc(!this.StatoChiusuraInizialeDoc());
    }

    isChanged() : boolean {
        if(!this.cause.Id || this.cause.Id == 0)
            return true;
        return !(<any>Object).identical(this.cause, this.getData());
    }
}