import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TaskForTaskBoard } from "./TaskBoardColumn";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ITaskBoard } from "./TaskBoard";
import { TodoListTasksProvider } from "../tasks-list/providers/TodoListTasksProvider";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ITodoListService, ITaskForTaskBoard, ITaskDialogOptions } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

const attributes = {};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "taskboard-header": {
                params?: {};
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITaskBoardHeaderPopoverParams {}

export class TaskBoardHeaderPopover implements ITaskBoard, IChangesNotificationsServiceObserver {
    Tasks: ko.ObservableArray<TaskForTaskBoard> = ko.observableArray();

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResources: IHumanResourcesSettingsManager;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    selectedTasks: ko.ObservableArray<TaskForTaskBoard> = ko.observableArray();
    selectedTask: ko.Computed<TaskForTaskBoard>;

    ResourceId: ko.Observable<number> = ko.observable();
    CanEdit: ko.Observable<boolean> = ko.observable(true);
    tasksProvider: TodoListTasksProvider = new TodoListTasksProvider();

    ViewAll: ko.Observable<boolean> = ko.observable(false);
    UserId: ko.Observable<number> = ko.observable();
    disposed = false;

    constructor(params: ITaskBoardHeaderPopoverParams) {
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);

        this.selectedTask = ko.computed(() => {
            const tasks = this.selectedTasks();
            return tasks.length == 0 ? null : tasks[0];
        });

        this.CanEdit(this.authorizationService.isAuthorized("TaskBoard_EditTask"));

        this.UserId(this.userInfo.getIdUser());
        const humanResource = this.humanResources.getHumanResourceByUserId(this.UserId());
        this.ResourceId(humanResource.Resource.Id);

        this.load();
    }

    dispose() {
        if (!this.disposed) {
            this.changesNotificationsService.RemoveObserver(this);
            this.disposed = true;
        }
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean): Promise<boolean> {
        const isTask = !changesInfo.Object.Task ? changesInfo.Object.IsTask : changesInfo.Object.Task.IsTask;
        const taskId = changesInfo.EntityKeyId;

        if (!isTask) return false;

        if (changesInfo.Action == 0)
            //Task aggiunto, non mi interessa
            return false;

        //if(this.Tasks().firstOrDefault(t => t.task.Id == taskId))
        await this.load();

        return false;
    }

    private async load(): Promise<void> {
        const tasks = await this.todoListService.GetLastTasksFromBarCodeRegistry("00000000-0000-0000-0000-000000000000", this.userInfo.getIdUser());
        this.Tasks(tasks.map(this.createViewModelFor, this));
    }

    private createViewModelFor(taskForTaskboard: ITaskForTaskBoard): TaskForTaskBoard {
        return new TaskForTaskBoard(taskForTaskboard, null, this);
    }

    SelectTask(t: TaskForTaskBoard, evt: MouseEvent) {
        if (this.selectedTask() && (!evt || (!evt.ctrlKey && !evt.metaKey))) {
            for (const task of this.selectedTasks()) {
                task.IsSelected(false);
            }
            this.selectedTasks([]);
        }

        this.selectedTasks.push(t);
        t.IsSelected(true);
    }

    StatePlusOne(status: number) {}

    OpenDialogForChangeState() {}

    OpenDialogForChangePlan() {}

    EditSelectedTask() {
        const taskId = this.selectedTask().task.Id;
        const options: ITaskDialogOptions = {
            initialViewAll: this.ViewAll(),
            userId: this.UserId(),
            showClosedJobOrders: false,
            tasksProvider: this.tasksProvider,
            workedHoursData: { resourceId: this.ResourceId(), date: null },
        };

        this.todoListService.ShowEditTaskDialog(taskId, options);
    }

    DeleteTask() {}
}

const { classes } = jss
    .createStyleSheet({
        taskboardHeader: {
            listStyle: "none",
            paddingLeft: 0,

            "& .taskboard-task": {
                "& .btn.btn-default.taskboard-alert-icon": {
                    padding: "0 2px 0 2px",
                },
            },
        },
    })
    .attach();

ko.components.register("taskboard-header", {
    viewModel: {
        createViewModel: (params: ITaskBoardHeaderPopoverParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new TaskBoardHeaderPopover(params);

            ComponentUtils.OnDestroy(componentInfo.element, vm.dispose.bind(vm));

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div data-bind="slimScroll: '320px'">
                    <ul class={classes.taskboardHeader} data-bind="foreach: Tasks">
                        <li
                            class="taskboard-task"
                            tabIndex={-1}
                            data-bind="css : { selected : IsSelected, 'milestone' : task.IsMilestone, 'workflow' : !task.IsTask }, click : Select, attr : { 'taskId' : task.Id, 'isTask' : task.IsTask.toString() }"
                        >
                            <div class="task-body">
                                <div class="task-header">
                                    <div data-bind="text: task.JobOrderName, attr: { title: task.JobOrderName }" class="text-ellipsis"></div>
                                </div>

                                <div
                                    class="task-priority"
                                    data-bind="style: { background : !task.IsTask ? 'green' : (task.Priority == 0 ? '#4b8df8' : (task.Priority == 1 ? '#ecbc29' : '#d64635') ) }"
                                ></div>

                                <span
                                    class="btn btn-warning btn-xs pull-right"
                                    title="Allegati"
                                    data-bind="visible: task.AttachmentsCount > 0, click: ShowAttachments"
                                    style="line-height: 0.5; margin-top: 8px; margin-right: 4px;"
                                >
                                    <i class="fa fa-paperclip" style="font-size: 10px; color: black;"></i>
                                </span>
                                <span
                                    class="btn btn-default taskboard-alert-icon"
                                    data-bind="visible: task.IsAllocated, tooltip: { container: 'body' }"
                                    title="L'attività è allocata"
                                >
                                    <i class="fa fa-shopping-cart" style="color: grey"></i>
                                </span>
                                <span
                                    class="btn btn-default taskboard-alert-icon"
                                    data-bind="visible: task.Optional, tooltip: { container: 'body' }"
                                    title="L'attività è facoltativa"
                                >
                                    <i class="fa fa-question-circle" style="color: grey"></i>
                                </span>
                                <span
                                    class="btn btn-default taskboard-alert-icon"
                                    data-bind="visible: task.Locked, tooltip: { container: 'body' }"
                                    title="L'attività è bloccata"
                                >
                                    <i class="fa fa-lock" style="color: grey"></i>
                                </span>
                                <span
                                    class="btn btn-default taskboard-alert-icon bg-red milstone"
                                    data-bind="visible: task.IsMilestone, tooltip: { container: 'body' }"
                                    title="L'attività è una Milestone"
                                >
                                    <i class="fa fa-star"></i>
                                </span>
                                <span
                                    class="btn btn-default taskboard-alert-icon"
                                    data-bind="visible: task.ReportingType == 1, tooltip: { container: 'body' }"
                                    title="L'attività è a Rendicontazione"
                                >
                                    <i class="fa fa-euro" style="color: green"></i>
                                </span>

                                <span class="task-title" data-bind="text : task.Title"></span>

                                <span class="task-title text-primary" title="E' presente una descrizione estesa" data-bind="visible: task.Description">
                                    <i class="fa fa-exclamation-circle"></i>
                                </span>

                                <div class="task-title" data-bind="visible: ShowingAttachments" style="margin-top: 10px;">
                                    <ko-if data-bind="Loading">
                                        <div>Caricamento...</div>
                                    </ko-if>
                                    <ko-ifnot data-bind="Loading">
                                        <ko-foreach data-bind="Attachments">
                                            <a
                                                class="label label-primary label-sm"
                                                href="#"
                                                data-bind="text: FileName, click: Open"
                                                style="line-height: 20px;"
                                            ></a>
                                            <br />
                                        </ko-foreach>
                                    </ko-ifnot>
                                </div>

                                <div class="task-footer">
                                    <div style="overflow: auto">
                                        <div
                                            class="pull-left"
                                            data-bind="attr : { title : 'Data di scadenza' + (task.ExpireDate ? ' (' + moment(task.ExpireDate).format('L') + ')' : '') }, style: { 'color' : task.IsLateEnd ? 'red' : 'black' }"
                                        >
                                            <i class="fa fa-calendar"></i>
                                            <span data-bind="text: ShortExpireDate"></span>
                                        </div>

                                        <div class="pull-right" data-bind="visible: task.IsTask">
                                            <span data-bind="attr : { title : 'Ore lavorate (Ore stimate)' }, style: { 'color' : task.WorkedHours > task.EstimatedDuration ? 'red' : 'black' }">
                                                <span data-bind="numberText : task.WorkedHours"></span>
                                                &nbsp;(<span data-bind="numberText : task.EstimatedDuration"></span>)
                                            </span>
                                            &nbsp;
                                            <button
                                                class=" btn btn-xs btn-primary"
                                                style="line-height: 0.5;"
                                                data-bind="click: InsertWorkedHoursOnTask"
                                                title="Inserimento Ore"
                                            >
                                                <i class="fa fa-clock-o" style="font-size: 10px;"></i>
                                            </button>
                                            &nbsp;
                                            <ko-if data-bind="$parent.CanEdit">
                                                <button
                                                    class="btn btn-primary btn-xs"
                                                    data-bind="attr: { title: 'Modifica' }, click: Edit, visible: task.IsTask"
                                                    style="line-height: 0.5;"
                                                >
                                                    <i class="fa fa-pencil" style="font-size: 10px"></i>
                                                </button>
                                            </ko-if>
                                        </div>
                                    </div>
                                    <div style="overflow: auto" class="text-right margin-top-3" data-bind="visible: task.IsTask">
                                        <button
                                            class="btn btn-xs btn-primary"
                                            style="line-height: 0.5;"
                                            data-bind="asyncClick: StartWork, enable: CanPlay"
                                            title="Avvia Lavorazione"
                                        >
                                            <i class="fa fa-play" style="font-size: 10px;"></i>
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-xs btn-primary"
                                            style="line-height: 0.5;"
                                            data-bind="asyncClick: PauseWork, enable: CanPause"
                                            title="Inizia Pausa"
                                        >
                                            <i class="fa fa-pause" style="font-size: 10px;"></i>
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-xs btn-primary"
                                            style="line-height: 0.5;"
                                            data-bind="asyncClick: EndWork, enable: CanStop"
                                            title="Termina Lavorazione"
                                        >
                                            <i class="fa fa-stop" style="font-size: 10px;"></i>
                                        </button>
                                    </div>
                                    <div
                                        style="overflow: auto; margin-top: 5px"
                                        data-bind="visible: task.IsLateStart || !task.IsReady || !task.HasWarehouse || !task.HasPurchases || !task.HasResources || task.HoursToBeBilled || task.HasNotApprovedBudgetRequests || task.HasApprovedBudgetRequests || !task.HasEstimatedWork"
                                    >
                                        <span class="pull-left" style="margin-right: 5px; line-height: 22px">
                                            Avvisi:{" "}
                                        </span>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.IsLateStart, tooltip: { container: 'body' }"
                                            title="L'attività è in ritardo sulla data di partenza"
                                        >
                                            <i class="icon-hourglass" style="color: red"></i>
                                        </div>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: !task.IsReady, tooltip: { container: 'body' }"
                                            title="Ci sono delle attività precedenti non completate e l'attività non può partire"
                                        >
                                            <i class="fa fa-exclamation-circle" style="color: red"></i>
                                        </div>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: !task.HasWarehouse, tooltip: { container: 'body' }"
                                            title="L'attività ha bisogno di articoli di magazzino"
                                        >
                                            <i class="fa fa-shopping-cart" style="color: red"></i>
                                        </div>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: !task.HasPurchases, tooltip: { container: 'body' }"
                                            title="L'attività ha bisogno di effettuare degli acquisti"
                                        >
                                            <i class="fa fa-euro" style="color: red"></i>
                                        </div>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: !task.HasResources, tooltip: { container: 'body' }"
                                            title="L'attività ha bisogno di risorse"
                                        >
                                            <i class="fa fa-users" style="color: red"></i>
                                        </div>
                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.HoursToBeBilled > 0, tooltip: { container: 'body' }"
                                            title="Sono presenti ore a Rendicontazione da Fatturare"
                                        >
                                            <i class="fa fa-warning" style="color: red"></i>
                                        </div>

                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.HasNotApprovedBudgetRequests, tooltip: { container: 'body' }"
                                            title="Sono presenti richieste budget non approvate"
                                        >
                                            <i class="icon-wallet" style="color: red"></i>
                                        </div>

                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.HasApprovedBudgetRequests, tooltip: { container: 'body' }"
                                            title="Sono presenti richieste budget approvate"
                                        >
                                            <i class="icon-wallet" style="color: green"></i>
                                        </div>

                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: !task.HasEstimatedWork, tooltip: { container: 'body' }"
                                            title="L'attività non ha stima di lavoro"
                                        >
                                            <i class="fa fa-terminal" style="color: red"></i>
                                        </div>

                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.WorkflowMustBePlanned, tooltip: { container: 'body' }"
                                            title="Il piano di lavoro deve essere pianificato"
                                        >
                                            <i class="fa fa-warning" style="color: orange"></i>
                                        </div>

                                        <div
                                            class="btn btn-default taskboard-alert-icon pull-left"
                                            style="margin-right: 5px"
                                            data-bind="visible: task.ActivitiesMustBeReviewed, tooltip: { container: 'body' }"
                                            title="Il piano di lavoro contiene attività da controllare"
                                        >
                                            <i class="fa fa-bookmark" style="color: red"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>,
            ]);

            return vm;
        },
    },
    template: [],
});
