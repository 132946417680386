import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, PropsWithChildren, reloadNow } from "../../Core/utils/ComponentUtils";
import { LinkProps, _Link } from "./Link";
import { LazyImport } from "../../Core/DependencyInjection";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import Route = require("route-parser");

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type LinksMenuProps = PropsWithChildren<{
    className?: string;
    title?: string;
}>;

export function LinksMenu(props: LinksMenuProps) {
    const C = require("./LinksMenu")._LinksMenu as typeof _LinksMenu;
    return <C {...props} />;
}

type LinkPropsWithRoute = LinkProps & {
    route: Route;
}

export class _LinksMenu {
    static defaultProps: Partial<LinksMenuProps> = {
    }

    links: LinkPropsWithRoute[] = [];

    @LazyImport(nameof<IOPAService>())
    private opaService : IOPAService;

    private currentUrl : ko.Observable<string> = ko.observable("");
    private onLocationChange: (url: string) => void;

    constructor(private props : LinksMenuProps) {
        ComponentUtils.Children.forEach(props.children, c => {
            const link = ComponentUtils.getComponent<_Link>(c);
            this.links.push({
                ...link.props,
                route: new Route(link.props.path + (!link.props.exact ? "(*any)" : "")),
            });
        });

        this.onLocationChange = this.handleLocationChange.bind(this);
    }

    componentDidMount() {
        this.opaService.OnChange(this.onLocationChange);
    }

    componentWillUnmount() {
        this.opaService.OffChange(this.onLocationChange);
    }

    private handleLocationChange(url: string) {
        this.currentUrl(url);
    }
    
    render() {
        const wrapperClassName = ComponentUtils.classNames("page-quick-sidebar-wrapper", this.props.className);
        const navigatorClassName = ComponentUtils.classNames("page-quick-sidebar-chat-users", "navigator");
        
        return  <div className="page-sidebar">
                    <div className={wrapperClassName}>
                        <div className="page-quick-sidebar">
                            <div className="page-quick-sidebar-chat">
                                <div className={navigatorClassName}>
                                    {this.renderHeader()}
                                    {this.renderBody()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }

    renderBody() {
        return  <div className="navigator-scrollable-container" style={{ top: '60px' }} data-bind={{ lightSlimScroll: null }}>
                    {this.links.length === 0 && <h4 className="list-heading"></h4>}
                    {this.renderList()}
                </div>
    }

    renderHeader() {
        return  <header className="navigator-header">
                    <div className="flex-container">
                        <h3 className="list-heading" style={{ maxWidth: '202px', minWidth: 'inherit' }}>{this.props.title}</h3>
                    </div>
                </header>
    }

    renderList() {
        return  <ul className="feeds list-items">
                    {this.links.map(this.renderItem, this)}
                </ul>
    }

    private renderItem(link: LinkPropsWithRoute) {
        const _item = {
            selected: ko.computed(() => {
                return !!link.route.match(this.currentUrl().replace("/#", ""));
            }),
            onClick: () => {
                this.opaService.NavigateTo("/#" + link.path.replace("/#", "").replace("#", ""));
            }
        }

        return  ComponentUtils.bindTo(
            <li data-bind={{ click: _item.onClick, css : { 'selected': _item.selected } }}>
                <div className="col1">
                    <div className="cont">
                        <div className="cont-col1">
                            {link.icon && 
                                <div className="btn btn-primary btn-lg" style={{ background: link.iconBackground, color: link.iconForeground, minWidth: '30px', minHeight: '30px', textAlign: 'center', lineHeight: '30px', padding: 0 }}>
                                    <i className={link.icon}></i>
                                </div>}
                        </div>
                        <div className="cont-col2">
                            <div className={classNames("desc", { 'without-icon': !link.icon })}>
                                <div>{link.title}</div>
                                {link.subTitle && 
                                    <div className="label label-sm menu-item-subtitle">
                                        <span>{link.subTitle}</span>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        , _item, "_item");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(LinksMenu);
}