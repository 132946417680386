import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 16/08/2018
 * Time: 17:18
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { CategoriesProvider } from "../providers/CategoriesProvider";
import { EventCanceledMotivationsProvider } from "../providers/EventCanceledMotivationsProvider";
import { IException } from "../../../../Core/interfaces/IException";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IGoogleUser, IGoogleCalendar, IGoogleCalendarService } from "../../../../ProlifeSdk/interfaces/agenda/IGoogleCalendarService";
import { IAgendaService, IAgenda, IAgendaSync } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

export class GoogleCalendarSyncDialog implements IDialog {
    public templateName: string = "google-calendar-sync-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = "Sincronizzazione Google Calendar";
    modal: { close: (result?: any) => void; };

    private googleUser : IGoogleUser;
    public NotLoggedIn : ko.Observable<boolean> = ko.observable();
    public FullName : ko.Observable<string> = ko.observable();
    public Picture : ko.Observable<string> = ko.observable();
    public Calendars : ko.ObservableArray<IGoogleCalendar> = ko.observableArray([]);
    public Calendar : ko.Observable<string> = ko.observable();
    public MaxYearsToSynchronize : ko.Observable<number> = ko.observable(1);
    public MinSynchronizationDate : ko.Observable<Date> = ko.observable();
    public TimeSlotSyncMode : ko.Observable<number> = ko.observable(1);

    public AreTimeslotEnabled : ko.Observable<boolean> = ko.observable();

    public EventCanceledMotivationsProvider : EventCanceledMotivationsProvider;
    public DefaultCanceledMotivationId : ko.Observable<number> = ko.observable();

    public CategoriesProvider : CategoriesProvider;
    public DefaultState : ko.Observable<number> = ko.observable();

    public MonoDirectional : ko.Computed<boolean>;
    public BiDirectional : ko.Computed<boolean>;
    public SyncMode : ko.Observable<string> = ko.observable("Bi");

    private googleCalendarService : IGoogleCalendarService;
    private agendaService : IAgendaService;
    private changesNotificationsService : IChangesNotificationsService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private agendaId : number) {
        this.googleCalendarService = <IGoogleCalendarService> serviceLocator.findService(ProlifeSdk.GoogleCalendarServiceType);
        this.agendaService = <IAgendaService> serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.changesNotificationsService = <IChangesNotificationsService> serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        this.CategoriesProvider = new CategoriesProvider(this.serviceLocator);
        this.EventCanceledMotivationsProvider = new EventCanceledMotivationsProvider(this.serviceLocator);

        this.changesNotificationsService.RegisterEventHandler("AgendaAuthenticationCompleted", this.OnAgendaAuthenticationComplated.bind(this));
        this.agendaService.GetAgenda(this.agendaId)
            .then((agenda : IAgenda) => {
                if(!agenda) return;

                this.AreTimeslotEnabled(agenda.TimeslotsModeEnabled);
            });

        this.MonoDirectional = ko.computed(() => {
            return this.SyncMode() == "Mono";
        });
        this.BiDirectional = ko.computed(() => {
            return this.SyncMode() == "Bi";
        });

        this.MaxYearsToSynchronize.subscribe((value : number) => {
            if(value < 1) this.MaxYearsToSynchronize(1);
            if(value > 5) this.MaxYearsToSynchronize(5);
        });

        this.Login(false);
    }

    close():void {
        this.modal.close(false);
    }

    action():void {
        if(this.NotLoggedIn()) {
            return;
        }

        if(!this.Calendar()) {
            this.agendaService.DeleteAgendaSync(this.agendaId)
                .then(() => {
                    this.modal.close(true);
                })
            return;
        }

        var agendaSync : IAgendaSync = {
            AgendaId: this.agendaId,
            UserId: 0, //Sovrascritto lato server
            ProviderId: "ProLife.GoogleCalendarPlugin",
            CalendarId: this.Calendar(),
            BiDirectional: this.BiDirectional(),
            ChannelId: null,
            ChannelExpiration: null,
            ChannelResourceId: null,
            MaxYearsToSynchronize: this.MaxYearsToSynchronize(),
            MinSynchronizationDate: this.MinSynchronizationDate(),
            LastSyncToken: null,
            MustSync: true,
            TimeSlotSyncMode: this.TimeSlotSyncMode(),
            DefaultCanceledMotivationId: this.AreTimeslotEnabled() ? this.DefaultCanceledMotivationId() : null,
            DefaultState: this.AreTimeslotEnabled() ? this.DefaultState() : null
        };

        this.agendaService.InsertOrUpdateAgendaSync(agendaSync)
            .then(() => {
                this.modal.close(true);
            })
    }

    private OnAgendaAuthenticationComplated(agendaId : number) {
        if(this.agendaId == agendaId)
            this.Login(false);
    }

    LoadCalendars() {
        return this.googleCalendarService.getCalendars(this.agendaId)
            .then((calendars : IGoogleCalendar[]) => {
                this.Calendars(calendars);
            });
    }

    LoadAgendaSync() {
        this.agendaService.GetAgendaSync(this.agendaId)
            .then((agendaSync : IAgendaSync) => {
                if(!agendaSync)
                    return;

                this.Calendar(agendaSync.CalendarId);
                this.SyncMode(agendaSync.BiDirectional ? "Bi" : "Mono");
                this.MaxYearsToSynchronize(agendaSync.MaxYearsToSynchronize);
                this.MinSynchronizationDate(agendaSync.MinSynchronizationDate);
                this.TimeSlotSyncMode(agendaSync.TimeSlotSyncMode);
                this.DefaultCanceledMotivationId(agendaSync.DefaultCanceledMotivationId);
                this.DefaultState(agendaSync.DefaultState);
            });
    }

    public Login(doLogin : boolean = true) {
        this.googleCalendarService.getUserDetails(this.agendaId, true)
            .then((user : IGoogleUser) => {
                this.googleUser = user;
                this.NotLoggedIn(false);
                this.FullName(user.name);
                this.Picture(user.picture);

                this.LoadCalendars()
                    .then(() => {
                        this.LoadAgendaSync();
                    });
            })
            .catch((exception: IException) => {
                this.NotLoggedIn(true);

                if(doLogin) {
                    var deserializedError = JSON.parse(exception.ExceptionMessage || "null");
                    if(deserializedError.ExceptionType == "ProLife.Core.Exceptions.ProLifeExternalAuthenticationException") {
                        var loginUrl = deserializedError.ExceptionMessage;
                        //location.href = loginUrl;
                        window.open(loginUrl, "_blank");
                    }
                }
            });
    }

    public Logout() {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Agenda.LogoutMessage, ProlifeSdk.TextResources.Agenda.LogoutMessageCancel, ProlifeSdk.TextResources.Agenda.LogoutMessageConfirm, (result : boolean) => {
            if(!result)
                return;

            this.googleCalendarService.Logout(this.agendaId)
                .then(() => {
                    this.Login(false);
                });
        });
    }

    public ShowModal() {
        this.dialogsService.ShowModal<void>(this);
    }
}