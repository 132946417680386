import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { WarehouseLoadReasonsForShipmentSettingsEditor } from "./ui/WarehouseLoadReasonsForShipmentSettingsEditor";
import { IWarehouseLoadReasonForShipment, IOrdersService } from "../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IException } from "../../../Core/interfaces/IException";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface IWarehouseLoadReasonsForShipmentSettingsManager extends ISettingsManager {
    getReasonsForShipment(showDeleted?: boolean): IWarehouseLoadReasonForShipment[];
    getReasonForShipment(reasonId: number): IWarehouseLoadReasonForShipment;
    getReasonForShipmentByName(name: string): IWarehouseLoadReasonForShipment;

    insertOrUpdateReasonsForShipment(reasons: IWarehouseLoadReasonForShipment[]): Promise<IWarehouseLoadReasonForShipment[]>;
    deleteReasonsForShipment(reasonsIds: number[]): Promise<void>
}

export class WarehouseLoadReasonsForShipmentSettingsManager implements IWarehouseLoadReasonsForShipmentSettingsManager {
    private reasonsForShipment: Array<IWarehouseLoadReasonForShipment> = new Array<IWarehouseLoadReasonForShipment>();
    
    @LazyImport(nameof<IOrdersService>())
    private ordersService: IOrdersService;

    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }
    
    public load(): Promise<IWarehouseLoadReasonForShipment[]> {
        return this.ordersService.GetWarehouseLoadReasonsForShipment(true)
            .then((reasons: IWarehouseLoadReasonForShipment[]) => {
                this.reasonsForShipment = reasons;
                return reasons;
            });
    }
    
    public getName(): string {
        return ProlifeSdk.WarehouseLoadReasonsForShipmentSettingsManager;
    }
    
    public getLabel(): string {
        return ProlifeSdk.TextResources.Warehouse.WarehouseLoadReasonsForShipmentSettingsManager;
    }
    
    public hasEditingUI(): boolean {
        return true;
    }
    
    public getEditingUI(): IView {
        return {
            templateName: "warehouse-load-reasons-for-shipment-settings",
            templateUrl: "warehouse/templates/settings",
            viewModel: new WarehouseLoadReasonsForShipmentSettingsEditor(this)
        };
    }

    public getReasonsForShipment(showDeleted = false): IWarehouseLoadReasonForShipment[] {
        return this.reasonsForShipment.filter((r) => showDeleted === true || r.Deleted === false);
    }

    public getReasonForShipment(reasonId: number): IWarehouseLoadReasonForShipment {
        return this.reasonsForShipment.firstOrDefault((r) => r.Id === reasonId);
    }

    public getReasonForShipmentByName(name: string): IWarehouseLoadReasonForShipment {
        return this.reasonsForShipment.firstOrDefault((r) => (name || "").trim() === (r.Description || "").trim());
    }

    public async insertOrUpdateReasonsForShipment(reasons: IWarehouseLoadReasonForShipment[]): Promise<IWarehouseLoadReasonForShipment[]> {
        try {
            await this.internalInsertOrUpdate(reasons);
            return this.reasonsForShipment;
        } catch (err) {
            const exception = err as IException;

            if (exception.ExceptionType === ProlifeSdk.ServerException_ProLifeValidationException) {
                const message = exception.ExceptionCode === 50000 ? ProlifeSdk.TextResources.Warehouse.AllReasonsWithUpdateMeanCostsDeletedAlert : ProlifeSdk.TextResources.Warehouse.AllReasonsWithNonUpdateMeanCostsDeletedAlert;
                const skipCheck = await this.dialogsService.ConfirmAsync(message, ProlifeSdk.TextResources.ProlifeSdk.No, ProlifeSdk.TextResources.ProlifeSdk.Yes);

                if (skipCheck) {
                    await this.internalInsertOrUpdate(reasons, true);
                    return this.reasonsForShipment;
                } else {
                    return reasons;            
                }
            }
        }

        return reasons;
    }

    public async deleteReasonsForShipment(reasonsIds: number[], skipValidityCheck = false): Promise<void> {
        return this.ordersService.DeleteWarehouseLoadReasonsForShipment(reasonsIds, skipValidityCheck);
    }

    private async internalInsertOrUpdate(reasons: IWarehouseLoadReasonForShipment[], skipCheck = false): Promise<void> {
        const updatedReasons = await this.ordersService.InsertOrUpdateWarehouseLoadReasonForShipment(reasons, skipCheck);
        this.reasonsForShipment = updatedReasons;
    }
}