import * as ko from "knockout";
import * as Core from "../Core";
import * as React from "@abstraqt-dev/jsxknockout";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import jss from 'jss';
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { IDialogComponent, IDialogsService, IDialogsServiceConfiguration, IDialog, ShowModalOptions, IPopoverComponentInfo, PopoverPosition, PopoverInfo } from "../interfaces/IDialogsService";
import { IService } from "../interfaces/IService";
import { Deferred } from "../Deferred";
import { DialogComponentBase } from "../utils/DialogComponentBase";
import { ComponentUtils } from "../utils/ComponentUtils";
import { Template } from "../../Bindings/ForceBinding";
import { useService } from "../DependencyInjection";
import { IAjaxService } from "../interfaces/IAjaxService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

const { classes } = jss.createStyleSheet({
    popover: { 
        zIndex: 10050
    }
}).attach();

($.fn.popover as any).Constructor.prototype.reposition = function () {
    var $tip = this.tip()
    var boundingBox = this.$element[0].getBoundingClientRect();

    if(this.oldBoundingBox && this.oldBoundingBox.left == boundingBox.left && this.oldBoundingBox.top == boundingBox.top && this.oldBoundingBox.right == boundingBox.right && this.oldBoundingBox.bottom == boundingBox.bottom)
        return;

    var placement = typeof this.options.placement === 'function' ? this.options.placement.call(this, $tip[0], this.$element[0]) : this.options.placement

    var autoToken = /\s?auto?\s?/i
    var autoPlace = autoToken.test(placement)
    if (autoPlace) placement = placement.replace(autoToken, '') || 'top'
    
    var pos          = this.getPosition()
    var actualWidth  = $tip[0].offsetWidth
    var actualHeight = $tip[0].offsetHeight

    if (autoPlace) {
        var orgPlacement = placement
        var viewportDim = this.getPosition(this.$viewport)

        placement = placement == 'bottom' && pos.bottom + actualHeight > viewportDim.bottom ? 'top'    :
                    placement == 'top'    && pos.top    - actualHeight < viewportDim.top    ? 'bottom' :
                    placement == 'right'  && pos.right  + actualWidth  > viewportDim.width  ? 'left'   :
                    placement == 'left'   && pos.left   - actualWidth  < viewportDim.left   ? 'right'  :
                    placement

        $tip
            .removeClass(orgPlacement)
            .addClass(placement)
    }

    var calculatedOffset = this.getCalculatedOffset(placement, pos, actualWidth, actualHeight)

    this.applyPlacement(calculatedOffset, placement);

    this.oldBoundingBox = boundingBox;
}

function isDialogComponent(value : any) : value is IDialogComponent {
    return value instanceof DialogComponentBase;
}

function isPopoverComponent(value : any) : value is DialogComponentBase {
    return value instanceof DialogComponentBase;
}

function isPopoverInfo(value: unknown) : value is PopoverInfo {
    return value != null && value.hasOwnProperty("content") && (typeof value["content"] === "function");
}

class DialogsService implements IDialogsService {

    private dialogTemplate : string =
        '<div class="modal-header">' +
        '    <button type="button" class="close" data-bind="click : close"></button>' +
        '    <h4 class="modal-title" data-bind="text: title"></h4>' +
        '</div>' +
        '<div class="modal-body" data-bind="template : {name : templateName, templateUrl: templateUrl }">' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <a href="#" class="btn btn-default" data-bind="click: close">##Close##</a>' +
        '    <a href="#" class="btn btn-primary" data-bind="click: action">##Save##</a>' +
        '</div>';

    private dialogTemplateWithNodes : string =
        `<div class="modal-header">
            <button type="button" class="close" data-bind="click : close"></button>
            <h4 class="modal-title" data-bind="text: title"></h4>
        </div>
        <div class="modal-body" data-bind="template: { nodes: renderTemplateNodes(), afterRender : function() { $('.modal.container').each(function(i, e){ $(e).modal('layout'); });  } }">
        </div>
        <div class="modal-footer">
            <a href="#" class="btn btn-default" data-bind="click: close">##Close##</a>
            <a href="#" class="btn btn-primary" data-bind="click: action">##Save##</a>
        </div>`;

    private pendingBlocks : number = 0;
    private lockUiRequest: ReturnType<typeof setTimeout>;

    constructor(serviceLocator : IServiceLocator, private configuration : IDialogsServiceConfiguration = Core.configuration.dialogsConfig) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IDialogsService>(), this);
        bootbox.setDefaults({ locale : configuration.defaultLocale, className : "bootstrap" });
    }

    Alert(message : string, label : string, callback : () => void) : void {

        bootbox.alert({
            title : label,
            message : message,
            callback : callback
        });
	}

    Confirm(message : string, cancel : string, confirm : string, callback : (result : boolean) => void) : void {
        bootbox.confirm({
            message : message,
            callback : callback,
            buttons : {
                confirm : {
                    label : confirm,
                    className : "btn-success"
                },
                cancel : {
                    label : cancel,
                    className : "btn-default"
                }
            }
        });
	}

    Prompt(message : string, cancel : string, confirm : string, callback : (result : string) => void, defaultValue : string) : void {
        (bootbox as any).prompt({
            title: message,
            buttons : {
                cancel: { label: cancel },
                confirm: { label: confirm}
            },
            callback: callback,
            value: defaultValue
        });
    }
    
    AlertAsync(message : string, label : string) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.Alert(message, label, () => resolve());
        });
    }

    ConfirmAsync(message : string, cancel: string, confirm: string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.Confirm(message, cancel, confirm, (result : boolean) => resolve(result));
        });
    }

    PromptAsync(message : string, cancel : string, confirm : string, defaultValue : string) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.Prompt(message, cancel, confirm, () => resolve(), defaultValue);
        });
    }

    Dialog(options : BootboxDialogOptions<string>) {
        bootbox.dialog(options);
	}

    ShowModal<T>(...params: any[]) : Promise<T> {
        if(isDialogComponent(params[0])) {
            return this.ShowDialogComponent<T>(params[0]);
        }
        return this.ShowModalOld<T>(params[0], params[1], params[2], params[3], params[4]);
    }

    ShowDialogComponent<T>(dialog : IDialogComponent) : Promise<T> {
        let deferredModalResult = new Deferred<T>();

        //let nodes = dialog.render();
        let element = <div className={dialog.classes} data-keyboard="false"><Template component={dialog} /></div>

        dialog.modal = {
            close: (result? : any) => {
                if(typeof result !== "undefined") {
                    deferredModalResult.resolve(result);
                } else {
                    deferredModalResult.reject();
                }

                $(element).modal('hide');
                ko.removeNode(element);
            }
        };

        let context = new (ko as any).bindingContext(dialog, undefined, undefined, undefined);
        $(document.body).append(element);
        ComponentUtils.applyBindingsToNodeAndChildren(element, context);

        if(dialog.componentDidMount)
            dialog.componentDidMount(context);

        $(element).on('escapeClose', function(e) {
            e.preventDefault();
            dialog.close();
        }).on('hidden', function(){
            ko.removeNode(element);
        }).modal({ backdrop: 'static' });

        return deferredModalResult
            .promise();
    }

    ShowModalOld<T>(viewModel : IDialog, additionalCssClasses? : string, options? : ShowModalOptions, templateUrl? : string, templateName? : string) : Promise<T> {
        var deferredModalResult = new Deferred<T>();
        options = options || {};

        var exterior = $("<div class='modal' data-keyboard='false'><div class='modal-dialog'><div class='modal-content'></div></div></div>");

        try
        {
            viewModel.modal = {
                close: (result? : any) => {
                    if(typeof result !== "undefined") {
                        deferredModalResult.resolve(result);
                    } else {
                        deferredModalResult.reject();
                    }

                    exterior.modal('hide');
                    ko.removeNode(exterior[0]);
                }
            };

            var fade = options.fade ? "fade " : "";

            exterior.addClass(fade);
            exterior.addClass(additionalCssClasses || "");

            if(options.modalDialogStyles) {
                exterior.find(".modal-dialog").css(options.modalDialogStyles);
            }

            var template = (viewModel.renderTemplateNodes ? this.dialogTemplateWithNodes : this.dialogTemplate).replace("##Close##", options.closeText || this.configuration.defaultClose).replace("##Save##", options.saveText || this.configuration.defaultSave);

            if(templateUrl && templateName)
                template = "<!-- ko template : { name : '" + templateName + "', templateUrl : '" + templateUrl + "', afterRender : function() { $('.modal.container').each(function(i, e){ $(e).modal('layout'); });  } }--><!-- /ko -->";

            var interior = exterior.find(".modal-content");
            interior.html(template);

            $(document.body).append(exterior);
            ko.applyBindings(viewModel, exterior[0]);

            if(options.noPrompt) {
                exterior.find(".modal-footer .btn.btn-primary").hide();
            }

            var ui : any = exterior;

            ui.on('escapeClose', function(e) {
                e.preventDefault();
                viewModel.close();
            });
            ui.on('hidden', function(){
                ko.removeNode(exterior[0]);
            });
            ui.modal({ backdrop: 'static' });
            //ui.modal('show');
        }
        catch(ex)
        {
            console.error(ex);
            return deferredModalResult.reject().promise();
        }

        return deferredModalResult.promise();
    }

    ShowModalComponent<T>(componentInfo : IPopoverComponentInfo, additionalCssClasses? : string, options?: ShowModalOptions) : Promise<T> {
        let dialog : IDialog = $.extend({}, componentInfo.model, {
            templateName: "",
            templateUrl: "",
            title: componentInfo.title,
            renderTemplateNodes: () => [React.createElement(componentInfo.componentName, { params: "OnClose: modal.close" + (componentInfo.params ? ", " + componentInfo.params : "") } as any)]
        });

        if (!dialog.action)
            dialog.action = () => {};

        if (!dialog.close)
            dialog.close = () => dialog.modal.close(null);

        return this.ShowModal(dialog, additionalCssClasses, options);
    }

    ShowPopoverComponent<T>(...params: any[]) : Promise<T> {
        if(isPopoverComponent(params[1])) {
            return this.ShowPopoverComponentNew<T>(params[0], params[1], params[2], params[3], params[4], params[5]);
        } else if(isPopoverInfo(params[1])) {
            //Assumo sia una funzione di rendering che ritorna nodi react
            return this.ShowPopoverComponentRender(params[0], params[1], params[2], params[3], params[4], params[5]);
        }
        return this.ShowPopoverComponentOld<T>(params[0], params[1], params[2], params[3], params[4], params[5]);
    }

    ShowPopoverComponentRender<T>(element : HTMLElement, popoverContent : PopoverInfo, placement : "left" | "top" | "right" | "bottom" | "auto", viewport?: string | HTMLElement, additionalCssClasses? : string, container?: string) : Promise<T> {
        const deferredModalResult = new Deferred<T>();
        const context = ko.contextFor(element);
        const finalContent : PopoverInfo = {
            header: () => <div className="popover-title flex-container flex-child-center">
                {popoverContent.header ? popoverContent.header() : <span style={{ marginRight: '10px' }}>{popoverContent.title}</span>}
                <button className="btn btn-danger btn-circle btn-xs popover-close" style={{ marginLeft: 'auto' }} onClick={close}>&times;&nbsp;{TextResources.ProlifeSdk.Close}</button>
            </div>,
            content: popoverContent.content
        }

        const popoverContext = context.createChildContext(finalContent);
        
        let closed = false;
        let interval: ReturnType<typeof setTimeout> = $(element).data("bs.popover.interval");

        const close = () => {
            clearInterval(interval);
            $(element).popover('destroy');
            $(element).data("bs.popover", undefined);

            deferredModalResult.resolve();

            closed = true;
        }

        if($(element).data("bs.popover")) {
            close();
            return deferredModalResult.promise();
        }

        if(!viewport) {

            let classes = [".modal-body", ".page-content"];

            let jViewport;
            for(let className of classes){
                jViewport = $(element).closest(className);
                if(jViewport.length>0){
                    break;
                }
            }
                
            viewport = jViewport.length > 0 ? jViewport[0] : undefined;
        }

        container = container || "body";

        $(element)
            .on('inserted.bs.popover', () => {
                let popoverElement = $(element).data('bs.popover').$tip;
                popoverElement.addClass(additionalCssClasses);
                popoverElement.addClass(classes.popover);
                
                let [content] = popoverElement.find(".popover-content");
                let nodes = <Template component={finalContent.content} />
                $(content).append(nodes);
                ComponentUtils.applyBindingsToNodeAndChildren(popoverElement[0], popoverContext);

                interval = setInterval(() => {
                    $(element).popover("reposition");
                }, 100);

                $(element).data("bs.popover.interval", interval);
            }).on("hidden.bs.popover", () => {
                let popoverElement = $(element).data('bs.popover').$tip;
                let [content] = popoverElement.find(".popover-content");
                ko.cleanNode(popoverElement); //Rimuovo i binding
                ko.virtualElements.emptyNode(content); //Rimuovo il contenuto
            }).popover({
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-header" data-bind="tsxtemplate: $data.header"></div><div class="popover-content"></div></div>',
                trigger: 'manual',
                placement: placement.split(' ').length > 1 ? placement : "auto " + placement,
                html: true,
                title: " ",
                container: container,
                viewport: viewport,
                extendedTimeOut: 0,
                sanitizeFn: (content) => content
            } as any).popover('show');

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if(!closed)
                close();
        });

        return deferredModalResult.promise();
    }

    ShowPopoverComponentNew<T>(element : HTMLElement, popover : IDialogComponent, placement : "left" | "top" | "right" | "bottom" | "auto", viewport?: string | HTMLElement, additionalCssClasses? : string, container?: string) : Promise<T> {
        let deferredModalResult = new Deferred<T>();
        let context = ko.contextFor(element);
        let popoverContext = context.createChildContext(popover);

        let closed = false;
        let interval : ReturnType<typeof setTimeout>;
        popover.modal = {
            close: (result? : any) => {
                clearInterval(interval);
                $(element).popover('destroy');

                if(typeof result !== "undefined") {
                    deferredModalResult.resolve(result);
                } else {
                    deferredModalResult.reject();
                }

                if(popover.componentWillUnmount)
                    popover.componentWillUnmount(popoverContext);

                closed = true;
            }
        };

        if($(element).data("bs.popover")) {
            popover.close();
        }

        if(!viewport) {

            let classes = [".modal-body", ".page-content"];

            let jViewport;
            for(let className of classes){
                jViewport = $(element).closest(className);
                if(jViewport.length>0){
                    break;
                }
            }
                
            viewport = jViewport.length > 0 ? jViewport[0] : undefined;
        }

        container = container || "body";

        $(element)
            .on('inserted.bs.popover', () => {
                let popoverElement = $(element).data('bs.popover').$tip;
                popoverElement.addClass(additionalCssClasses);
                popoverElement.addClass(classes.popover);
                
                let [content] = popoverElement.find(".popover-content");
                let nodes = <Template component={popover} />
                $(content).append(nodes);
                ComponentUtils.applyBindingsToNodeAndChildren(popoverElement[0], popoverContext);

                if(popover.componentDidMount)
                    setTimeout(() => {
                        popover.componentDidMount(popoverContext);
                    }, 0);

                interval = setInterval(() => {
                    $(element).popover("reposition");
                }, 100);
            }).on("hidden.bs.popover", () => {
                let popoverElement = $(element).data('bs.popover').$tip;
                let [content] = popoverElement.find(".popover-content");
                ko.cleanNode(popoverElement); //Rimuovo i binding
                ko.virtualElements.emptyNode(content); //Rimuovo il contenuto
            }).popover({
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-header" data-bind="tsxtemplate: $data.renderHeader.bind($data)"></div><div class="popover-content"></div></div>',
                trigger: 'manual',
                placement: placement.split(' ').length > 1 ? placement : "auto " + placement,
                html: true,
                title: " ",
                container: container,
                viewport: viewport,
                extendedTimeOut: 0,
                sanitizeFn: (content) => content
            } as any).popover('show');

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if(!closed)
                popover.close();
        });

        return deferredModalResult.promise();
    }

    ShowPopoverComponentOld<T>(element : HTMLElement, componentInfo : IPopoverComponentInfo, placement : "left" | "top" | "right" | "bottom" | "auto", viewport?: string | HTMLElement, additionalCssClasses? : string, container?: string) : Promise<T> {
        let dialog : IDialog = $.extend({}, componentInfo.model, {
            templateName: "",
            templateUrl: "",
            title: componentInfo.title,
            renderTemplateNodes: () => [React.createElement(componentInfo.componentName, { params: "OnClose: modal.close" + (componentInfo.params ? ", " + componentInfo.params : "") } as any)]
        });

        if (!dialog.action)
            dialog.action = () => {};

        if (!dialog.close)
            dialog.close = () => dialog.modal.close(null);

        return this.ShowPopover(element, dialog, placement, viewport, additionalCssClasses, container);
    }

    ShowPopoverComponentFromFactory<T>(element : HTMLElement, title: string, componentFactory: () => Node[], placement : PopoverPosition, viewport?: string | HTMLElement, additionalCssClasses? : string, container?: string) : Promise<T>
    {
        let dialog : IDialog = {
            templateName: "",
            templateUrl: "",
            title: title,
            action: null,
            close: null,
            renderTemplateNodes: componentFactory
        };

        if (!dialog.action)
            dialog.action = () => {};

        if (!dialog.close)
            dialog.close = () => dialog.modal.close(null);

        return this.ShowPopover(element, dialog, placement, viewport, additionalCssClasses, container);
    }

    ShowPopover<T>(element : HTMLElement, viewModel : IDialog, placement : "left" | "top" | "right" | "bottom" | "auto", viewport?: string | HTMLElement, additionalCssClasses? : string, container?: string) : Promise<T> {
        return new Promise<T>((resolve, reject) => {
            try
            {
                let closing = false;

                viewModel.modal = {
                    close: (result? : any) => {
                        if(closing) return;
                        closing = true;

                        let interval = $(element).data("bs.popover.interval");
                        clearInterval(interval);
                        $(element).popover('destroy');
                        $(element).data("bs.popover", undefined);
                        
                        if(typeof result !== "undefined") {
                            resolve(result);
                        } else {
                            reject();
                        }
                    }
                };

                if($(element).data("bs.popover")) {
                    viewModel.close();
                    reject();
                    return;
                }

                if(!viewport) {

                    let classes = [".modal-body", ".page-content"];

                    let jViewport;

                    for(let className of classes){
                        jViewport = $(element).closest(className);
                        if(jViewport.length>0){
                            break;
                        }
                    }
                        
                    viewport = jViewport.length > 0 ? jViewport[0] : undefined;
                }

                container = container || "body";

                $(element)
                    .on('inserted.bs.popover', () => {
                        let popoverElement = $(element).data('bs.popover').$tip;
                        //let popoverElement = $(element).next('.popover');
                        popoverElement.addClass(additionalCssClasses);
                        popoverElement.addClass(classes.popover);
                        popoverElement.find(".popover-title").attr("data-bind", "text: title");

                        if(viewModel.renderTemplateNodes)
                            popoverElement.find(".popover-content").attr("data-bind", "template: { nodes: renderTemplateNodes() }");
                        else
                            popoverElement.find(".popover-content").attr("data-bind", "template: { name: templateName, templateUrl: templateUrl }");

                        let interval = setInterval(() => {
                            $(element).popover("reposition");
                        }, 100);

                        $(element).data("bs.popover.interval", interval);

                        ko.applyBindings(viewModel, popoverElement[0]);
                    }).on("hidden.bs.popover", () => {
                        let popoverElement = $(element).data('bs.popover').$tip;
                        ko.cleanNode(popoverElement);
                    }).popover({
                        template: '<div class="popover" role="tooltip"><div class="arrow"></div><button class="btn btn-danger btn-circle btn-xs popover-close" style="position: absolute; right: 5px; top: 6px; margin: 0" data-bind="click: close">&times;&nbsp;Chiudi</button><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
                        trigger: 'manual',
                        placement: placement.split(' ').length > 1 ? placement : "auto " + placement,
                        html: true,
                        title: " ",
                        container: container,
                        viewport: viewport,
                        extendedTimeOut: 0,
                        sanitizeFn: (content) => content
                    } as any).popover('show');

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    viewModel.close();
                });
            }
            catch(ex)
            {
                console.error(ex);
                reject();
                return;
            }
        });
    }

    HideAll() : void {
        bootbox.hideAll();
	}

    SetLocale(locale : string) : void {
        //bootbox.setDefaults({ locale : locale });
	}

    public getServiceType() : string {
        return ServiceTypes.Dialogs;
    }

    public isOfType(serviceType:string) : boolean {
        return serviceType == this.getServiceType();
    }

    private doLockUI(message: string) {
        $.blockUI({ 
            baseZ : 20000, 
            message: '<h3>' + message + '</h3>', 
            css: { 
                backgroundColor: 'rgb(203, 137, 15)', 
                color: '#fff', 
                border : '0', 
                padding : '20px'
            },
            bindEvents: false,
            showOverlay: true
        });
    }

    LockUI(message?: string, immediate: boolean = false) : void {
        this.pendingBlocks = this.pendingBlocks + 1;

        if (!message)
            message = this.configuration.defaultLoading;

        if(this.pendingBlocks == 1) {
            if(immediate) {
                this.doLockUI(message);
            } else {
                this.lockUiRequest = setTimeout(() => {
                    this.doLockUI(message);
                }, 500);
            }
        }
    }

    UnlockUI() : void {
        this.pendingBlocks = this.pendingBlocks > 0 ? this.pendingBlocks - 1 : 0;

        if(this.pendingBlocks == 0) {
            clearTimeout(this.lockUiRequest);
            $.unblockUI();
        }
    }

    async OpenUrlAsDialog(url: string, title: string) : Promise<void> {
        
        var ajaxService = useService<IAjaxService>(nameof<IAjaxService>());
        const { blob, fileName } = await ajaxService.DownloadFromUrl(url, { background: false });

        class SimpleDialog extends DialogComponentBase {
            dataUrl: string;

            constructor() {
                super({ className: "fullscreen", noPrompt: true });

                this.title(title);
                const file = new File([blob], fileName, { type: blob.type });
                this.dataUrl = URL.createObjectURL(file);
            }

            componentWillUnmount() {
                if(this.dataUrl) {
                    URL.revokeObjectURL(this.dataUrl);
                    this.dataUrl = null;
                }
            }

            renderFooter() {
                return  <>
                    <a className="btn btn-primary" href={this.dataUrl} download={fileName}>
                        <i className="fa fa-download"/>
                        &nbsp;{TextResources.Core.Download}
                    </a>
                    <a href="#" class="btn btn-default" data-bind="click: close">{this.params.closeLabel ?? TextResources.ProlifeSdk.Close}</a>
                </>;
            }

            renderBody() {
                return <iframe src={this.dataUrl} style={{ position: 'absolute', inset: 0, width: '100%', height: '100%', border: 0 }}></iframe>
            }
        }

        return this.ShowDialogComponent(new SimpleDialog());
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new DialogsService(serviceLocator);
}