import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextInput } from "../../../Components/TextInput";
import { IQuotationCategoryValue, IQuotationCategory } from "../../QuotationService";

type QuotationCategoryListValuesEditorProps = {
    ref?: (e : _QuotationCategoryListValuesEditor) => void;
    category: ko.Observable<IQuotationCategory>;
}

export function QuotationCategoryListValuesEditor(props: QuotationCategoryListValuesEditorProps) {
    const C = require("./QuotationCategoryListValuesEditor")._QuotationCategoryListValuesEditor as typeof _QuotationCategoryListValuesEditor;
    return <C {...props} />;
}

type CategoryListValue = Omit<IQuotationCategoryValue, "Value"> & {
    Value: ko.Observable<string>;
}

export class _QuotationCategoryListValuesEditor {
    static defaultProps: Partial<QuotationCategoryListValuesEditorProps> = {
    }

    values : ko.ObservableArray<CategoryListValue> = ko.observableArray();

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    constructor(private props : QuotationCategoryListValuesEditorProps) {
        
    }

    componentDidMount() {
        if(this.props.ref)
            this.props.ref(this);

        useEffect(() => {
            const cat = this.props.category();
            if(!cat) return;
            
            this.values(cat.Values.map(v => ({
                ...v,
                Value: ko.observable(v.Value)
            })))
        }, [this.props.category])
    }

    public getData() : IQuotationCategoryValue[] {
        return this.values().map(v => ({
            ...v,
            Value: v.Value()
        }));
    }

    private addNewValue() {
        this.values.push({
            Id: this.idGeneratorService.getNextId(),
            FKCategory: this.props.category().Id,
            Value: ko.observable()
        })
    }
    
    private removeValue(value : CategoryListValue) {
        this.values.remove(value);
    }
    
    render() {
        const factory = (item: CategoryListValue) => ({
            id: item.Id,
            title: item.Value(),
            isLeaf: true,
            isGroup: false,
            model: item
        });

        let item: IDataSourceModel<number, CategoryListValue>;

        return <>
            <Table scrollable compact dataSource={{ array: this.values, factory: factory }} rowAs="item" verticalAlign="middle" emptyResultMessage="Nessun valore specificato">
                <Column title="Valori" className=" no-border">
                    <ColumnBody>
                        {(item : ITableItem<CategoryListValue>) => <TextInput simple placeholder="Inserisci un valore..." value={item.Data.model.Value} />}
                    </ColumnBody>
                </Column>
                <Column style={{ width: 50 }} className="text-right">
                    <ColumnHeader style={{ verticalAlign: 'middle' }}>
                        <button className="btn btn-circle btn-xs blue" onClick={() => this.addNewValue()}><i className="fa fa-plus" />&nbsp;Nuovo</button>
                    </ColumnHeader>
                    <ColumnBody>
                        {(item : ITableItem<CategoryListValue>) => <button className="btn btn-circle btn-icon-only btn-xs red" onClick={() => this.removeValue(item.Data.model)}><i className="fa fa-trash-o" /></button>}
                    </ColumnBody>
                </Column>
            </Table>
        </>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationCategoryListValuesEditor);
}