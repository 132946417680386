import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 24/01/2018
 * Time: 10:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IChangelog } from "../../ProlifeSdk/interfaces/desktop/IChangelog";

export class ChangelogDialog implements IDialog {
    public templateName: string = "changelog-dialog";
    public templateUrl: string = "desktop/templates";
    public title: string = ProlifeSdk.TextResources.Desktop.ChangelogDialogTitle;

    modal: { close: (result?: any) => void; };

    private desktopService: IDesktopService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private changelog: IChangelog) {
        this.desktopService = <IDesktopService> this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.title = this.title.replace("{0}", this.changelog.Version);
    }

    close(): void {
        this.action();
    }

    action(): void {
        this.desktopService.SaveChangelogReadingStatus(this.changelog.Version)
            .then(() => {
                this.modal.close(null);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Desktop.SaveChangelogReadingStatusError);
                this.modal.close(null);
            });
    }

    public ShowDialog(): Promise<void> {
        var dialogsService: IDialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        return dialogsService.ShowModal<void>(this, "large", { noPrompt: true });
    }
}