import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Document } from "./Document";
import { SendMailOptions } from "./SendMailOptions";

function DocumentDateAndNumberFieldsReadOnly() {
    let doc: Document;

    return (
        <>
            <div class="control-group flex-container">
                <label htmlFor="date" style="width: auto;">
                    {TextResources.Invoices.DocumentDate}
                    <input
                        type="checkbox"
                        style="left: -20px; top: 10px;"
                        data-bind="visible: CanCopy, checked: DateSelected"
                    />
                </label>
                <span data-bind={{ dateText: doc.Date, customDateTimeFormat: "L" }}></span>
            </div>
            <div class="control-group flex-container flex-1" data-as={{ doc }}>
                <div
                    class="flex-container"
                    style="max-width: 260px"
                    data-bind={{ css: { striped: doc.ProtocolNumberNotPrinted } }}
                >
                    <label class="invoice-number">
                        <span data-bind={{ text: doc.DocumentTypeLabel }}></span>&nbsp;
                    </label>
                    <span data-bind={{ text: doc.NumberPrefix() || "" }}></span>
                    <span class="document-number" data-bind={{ text: doc.VatRegisterNumber }}></span>
                    <span data-bind={{ text: doc.NumberSuffix() || "" }}></span>
                </div>

                <DocumentVersionFieldsReadOnly></DocumentVersionFieldsReadOnly>
            </div>
        </>
    );
}

function DocumentDateAndNumberFieldsNonReadOnly() {
    let doc: Document;

    return (
        <>
            <div class="control-group flex-container">
                <label htmlFor="date" style="width: auto;">
                    {TextResources.Invoices.DocumentDate}
                </label>
                <input
                    autoComplete="off"
                    type="text"
                    id="date"
                    data-bind="datePicker: $data.Date, customDateTimeFormat: 'L'"
                />
                <a
                    href="#"
                    title={TextResources.Invoices.DocumentOpenDocumentJournal}
                    class="btn btn-xs btn-primary margin-left-5"
                    data-bind="click: openDocumentsJournalAtDate, tooltip: {}"
                >
                    <i class="fa fa-clock-o"></i>
                </a>
            </div>
            <div class="control-group flex-container flex-1" data-as={{ doc }}>
                <div
                    class="flex-container"
                    style="max-width: 260px"
                    data-bind={{ css: { striped: doc.ProtocolNumberNotPrinted } }}
                >
                    <label class="invoice-number">
                        <span data-bind={{ text: doc.DocumentTypeLabel }}></span>&nbsp;
                    </label>
                    <span data-bind={{ text: doc.NumberPrefix() || "" }}></span>
                    <input
                        autoComplete="off"
                        class="document-number"
                        type="text"
                        id="number"
                        placeholder="Auto"
                        data-bind={{ nullableNumberValue: doc.VatRegisterNumber, format: "0,0" }}
                    />
                    <span data-bind={{ text: doc.NumberSuffix() || "" }}></span>
                </div>
                <ko-bind data-bind={{ if: doc.CanHaveVersion }}>
                    <div class="flex-container" style="margin-left: auto">
                        <select
                            style="width: 100%"
                            data-bind={{
                                options: doc.NumberPrintChoices,
                                optionsText: "Description",
                                optionsValue: "Id",
                                value: doc.NumberPrintChoice,
                            }}
                        ></select>
                    </div>
                </ko-bind>
            </div>
        </>
    );
}

function DocumentDateAndNumberFields() {
    return (
        <>
            <ko-if data-bind="ReadOnly">
                <DocumentDateAndNumberFieldsReadOnly></DocumentDateAndNumberFieldsReadOnly>
            </ko-if>
            <ko-ifnot data-bind="ReadOnly">
                <DocumentDateAndNumberFieldsNonReadOnly></DocumentDateAndNumberFieldsNonReadOnly>
            </ko-ifnot>
        </>
    );
}

function DocumentVersionFieldsReadOnly() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveVersion }}>
            <div class="control-group" data-bind={{ css: { striped: doc.ProtocolVersionNotPrinted } }}>
                <label>
                    {TextResources.Invoices.DocumentVersionNumber}
                    <input type="checkbox" data-bind={{ visible: doc.CanCopy, checked: doc.VersionNumberSelected }} />
                </label>
                <span data-bind={{ text: doc.VersionRevisionNumberPrefix }}></span>
                <span class="version-number" data-bind={{ text: doc.VersionNumber }}></span>
                <span data-bind={{ text: doc.VersionRevisionNumberSeparator }}></span>
                <span class="version-number" data-bind={{ text: doc.VersionRevision }}></span>
                <span data-bind={{ text: doc.VersionRevisionNumberSuffix }}></span>
            </div>
        </ko-bind>
    );
}

function DocumentVersionFieldsNonReadOnly() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveVersion }}>
            <div class="row flex-container">
                <div class="control-group flex-container flex-fill">
                    <label htmlFor="versionGenerationModes">{TextResources.Invoices.DocumentVersionNumber}</label>
                    <select
                        id="versionGenerationModes"
                        data-bind={{
                            options: doc.VersionNumberGenerationModes,
                            optionsText: "Description",
                            optionsValue: "Id",
                            value: doc.VersionNumberGenerationMode,
                        }}
                    ></select>
                </div>
                <div
                    class="control-group flex-container flex-fill flex-end"
                    data-bind={{ css: { striped: doc.ProtocolVersionNotPrinted } }}
                >
                    <span data-bind={{ text: doc.VersionRevisionNumberPrefix }}></span>
                    <ko-bind data-bind={{ ifnot: doc.ShowVersionNumbersSuggestions }}>
                        <input
                            autoComplete="off"
                            type="text"
                            class="version-number"
                            data-bind={{ nullableNumberValue: doc.VersionNumber, format: "0,0" }}
                            placeholder={TextResources.Invoices.DocumentAutoPlaceholder}
                        />
                    </ko-bind>
                    <ko-bind data-bind={{ if: doc.ShowVersionNumbersSuggestions }}>
                        <typeahead
                            class="version-number"
                            simple={true}
                            dataSource={() => "DocumentVersionNumbersDataSource"}
                            value={() => "VersionNumber"}
                            listener={() => "$data"}
                            placeholder={TextResources.Invoices.DocumentAutoPlaceholder}
                            bindings="numbersOnly"
                        ></typeahead>
                    </ko-bind>
                    <span data-bind={{ text: doc.VersionRevisionNumberSeparator }}></span>
                    <input
                        autoComplete="off"
                        type="text"
                        class="version-revision"
                        data-bind={{
                            nullableNumberValue: doc.VersionRevision,
                            format: "0,0",
                            enable: doc.CanEditRevisionNumber,
                        }}
                        placeholder={TextResources.Invoices.DocumentAutoPlaceholder}
                    />
                    <span data-bind={{ text: doc.VersionRevisionNumberSuffix }}></span>
                </div>
            </div>
        </ko-bind>
    );
}

function DocumentVersionFields() {
    return (
        <>
            <ko-ifnot data-bind="ReadOnly">
                <DocumentVersionFieldsNonReadOnly />
            </ko-ifnot>
        </>
    );
}

function DocumentElectronicInvoicingFieldsReadOnly() {
    let doc: Document;

    return (
        <ko-bind data-bind={{ if: doc.ElectronicInvoice }}>
            <div class="control-group flex-container">
                <label style="width: auto; white-space: nowrap">
                    {TextResources.Invoices.DocumentDestinationRecipient}
                </label>
                <span style="width: 60px" data-bind={{ text: doc.RecipientCode }} />

                <span class="recipient-code-pec" data-bind="visible: ShowRecipientCodePECField">
                    <span data-bind={{ text: doc.RecipientCodePEC }} />
                </span>
            </div>
        </ko-bind>
    );
}

function DocumentElectronicInvoicingFieldsNonReadOnly() {
    let doc: Document;

    return (
        <ko-bind data-bind={{ if: doc.ElectronicInvoice }}>
            <div class="control-group flex-container">
                <label style="width: auto; white-space: nowrap">
                    {TextResources.Invoices.DocumentDestinationRecipient}
                </label>
                <input
                    autoComplete="off"
                    type="text"
                    style="width: 60px"
                    placeholder={TextResources.Invoices.DocumentDestinationRecipientCode}
                    data-bind={{
                        value: doc.RecipientCode,
                        recipientCodes: doc.RecipientCodeData,
                        customerId: doc.Recipient.Id,
                        organizationalUnitId: doc.FKOrganizationalUnit,
                    }}
                />

                <span class="recipient-code-pec" data-bind="visible: ShowRecipientCodePECField">
                    <input
                        autoComplete="off"
                        type="text"
                        placeholder={TextResources.Invoices.DocumentDestinationRecipientPEC}
                        data-bind="value: RecipientCodePEC"
                    />
                </span>
            </div>
        </ko-bind>
    );
}

function DocumentElectronicInvoicingFields() {
    let doc: Document;

    return (
        <>
            <ko-bind data-bind={{ if: doc.ReadOnly }}>
                <DocumentElectronicInvoicingFieldsReadOnly></DocumentElectronicInvoicingFieldsReadOnly>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                <DocumentElectronicInvoicingFieldsNonReadOnly></DocumentElectronicInvoicingFieldsNonReadOnly>
            </ko-bind>
        </>
    );
}

function DocumentElectronicInvoiceTypeField() {
    let doc: Document;

    return (
        <>
            <ko-bind data-bind={{ if: doc.CanBeAnElectronicInvoice() && doc.ElectronicInvoice() }}>
                <div class="row single-field striped">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.ElectronicInvoiceTypeFieldLabel}</label>
                        <select2
                            dataSource={() => "doc.ElectronicInvoiceTypesDataSource"}
                            value={() => "doc.ElectronicInvoiceTypeCode"}
                            allowClear={true}
                            simple={true}
                            noFormControl={true}
                            readOnly={() => "doc.ReadOnly"}
                            styles="width: 16.5cm"
                        ></select2>
                    </div>
                </div>
            </ko-bind>
        </>
    );
}

function PaymentSelectionReadOnly() {
    let doc: Document;

    return (
        <div class="row single-field">
            <div class="col-md-4" style="padding-left: 0">
                <div class="control-group flex-container flex-child-center">
                    <label>
                        {TextResources.Invoices.DocumentMode}
                        <input
                            type="checkbox"
                            style="left: -20px; top: 10px;"
                            data-bind={{ visible: doc.CanCopy, checked: doc.PaymentSelected }}
                        />
                    </label>
                    <select2
                        readOnly={true}
                        styles="width: 100px"
                        simple={true}
                        noFormControl={true}
                        allowClear={true}
                        value={() => "FKPaymentType"}
                        dataSource={() => "PaymentModesDataSource"}
                        placeholder={TextResources.Invoices.DocumentSelect}
                        listener={() => "$data"}
                    ></select2>
                </div>
            </div>

            <ko-if data-bind="Payment">
                <ko-if data-bind="Payment().AssociaBanca == 2">
                    <div class="col-md-8">
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentIBAN}</label>
                            <span
                                class="flex-1"
                                style="position: relative; top: 1px;"
                                data-bind={{ text: doc.PaymentIBAN }}
                            ></span>
                        </div>
                    </div>
                </ko-if>

                <ko-if data-bind="Payment().AssociaBanca == 1">
                    <div class="col-md-8 flex-container">
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentABI}</label>
                            <span
                                class="flex-1"
                                style="position: relative; top: 1px;"
                                data-bind={{ text: doc.PaymentABI }}
                            ></span>
                        </div>
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentCAB}</label>
                            <span
                                class="flex-1"
                                style="position: relative; top: 1px;"
                                data-bind={{ text: doc.PaymentCAB }}
                            ></span>
                        </div>
                    </div>
                </ko-if>
            </ko-if>
        </div>
    );
}

function PaymentSelectionNonReadOnly() {
    return (
        <div class="row single-field">
            <div class="col-md-4" style="padding-left: 0">
                <div class="control-group flex-container flex-child-center">
                    <label>{TextResources.Invoices.DocumentMode}</label>
                    <select2
                        styles="width: 100px"
                        simple={true}
                        noFormControl={true}
                        allowClear={true}
                        value={() => "FKPaymentType"}
                        dataSource={() => "PaymentModesDataSource"}
                        placeholder={TextResources.Invoices.DocumentSelect}
                        listener={() => "$data"}
                    ></select2>
                </div>
            </div>

            <ko-if data-bind="Payment">
                <ko-if data-bind="Payment().AssociaBanca == 2">
                    <div class="col-md-8">
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentIBAN}</label>
                            <ko-if data-bind="SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentIBAN"}
                                    dataSource={() => "CompanyIBANDataSource"}
                                    listener={() => "$data"}
                                    style="position: relative; top: 1px;"
                                ></typeahead>
                            </ko-if>
                            <ko-if data-bind="!SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentIBAN"}
                                    dataSource={() => "CustomerIBANDataSource"}
                                    listener={() => "$data"}
                                    style="position: relative; top: 1px;"
                                ></typeahead>
                            </ko-if>
                        </div>
                    </div>
                </ko-if>

                <ko-if data-bind="Payment().AssociaBanca == 1">
                    <div class="col-md-8 flex-container">
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentABI}</label>
                            <ko-if data-bind="SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentABI"}
                                    dataSource={() => "CustomerABIDataSource"}
                                    listener={() => "$data"}
                                    maxLength={5}
                                ></typeahead>
                            </ko-if>
                            <ko-if data-bind="!SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentABI"}
                                    dataSource={() => "CompanyABIDataSource"}
                                    listener={() => "$data"}
                                    maxLength={5}
                                ></typeahead>
                            </ko-if>
                        </div>
                        <div class="control-group flex-container">
                            <label class="flex-0">{TextResources.Invoices.DocumentCAB}</label>
                            <ko-if data-bind="SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentCAB"}
                                    dataSource={() => "CustomerCABDataSource"}
                                    listener={() => "$data"}
                                    maxLength={5}
                                ></typeahead>
                            </ko-if>
                            <ko-if data-bind="!SalesCicle() && CustomerIsChanged()">
                                <typeahead
                                    class="flex-1"
                                    simple={true}
                                    value={() => "PaymentCAB"}
                                    dataSource={() => "CompanyCABDataSource"}
                                    listener={() => "$data"}
                                    maxLength={5}
                                ></typeahead>
                            </ko-if>
                        </div>
                    </div>
                </ko-if>
            </ko-if>
        </div>
    );
}

function PaymentExpiryReadOnly() {
    let doc: Document;

    return (
        <div class="row single-field" style={{ marginTop: "5px" }}>
            <div class="control-group flex-container flex-child-center">
                <label>
                    {TextResources.Invoices.DocumentExpiry}
                    <input
                        type="checkbox"
                        style="left: -20px; top: 10px;"
                        data-bind={{ visible: doc.CanCopy, checked: doc.ExpirySelected }}
                    />
                </label>
                <span class="flex-1" data-bind={{ text: doc.ExpiryType }}></span>
                <i
                    class="fa fa-font"
                    data-bind={{ visible: (doc.FKExpiryType() <= 0 || !doc.FKExpiryType()) && !!doc.ExpiryType() }}
                ></i>
            </div>
        </div>
    );
}

function PaymentExpiryNonReadOnly() {
    let doc: Document;

    return (
        <div class="row single-field" style={{ marginTop: "5px" }}>
            <div class="control-group flex-container">
                <label>{TextResources.Invoices.DocumentExpiry}</label>
                <typeahead
                    class="flex-1"
                    simple={true}
                    value={() => "ExpiryType"}
                    dataSource={() => "ExpiriesDataSource"}
                    listener={() => "$data"}
                ></typeahead>
                <input
                    type="hidden"
                    data-bind="datePicker: ExpiryType, datePickerWriteOnly: true, datePickerValueAsString: true, datePickerShowAsIcon: 'calendar fa fa-calendar', dateTimeKeepInvalid: true"
                />
                <i
                    class="fa fa-font"
                    style={{ marginTop: "3px" }}
                    data-bind={{ visible: (doc.FKExpiryType() <= 0 || !doc.FKExpiryType()) && !!doc.ExpiryType() }}
                ></i>
            </div>
        </div>
    );
}

function PaymentInformationReadOnly() {
    let doc: Document;
    return (
        <fieldset>
            <legend>{TextResources.Invoices.DocumentPayment}</legend>
            <div class="col-md-12">
                <PaymentSelectionReadOnly></PaymentSelectionReadOnly>
                <PaymentExpiryReadOnly></PaymentExpiryReadOnly>
            </div>
        </fieldset>
    );
}

function PaymentInformationNonReadOnly() {
    let doc: Document;
    return (
        <fieldset>
            <legend>{TextResources.Invoices.DocumentPayment}</legend>
            <div class="col-md-12">
                <div
                    class="pull-right"
                    style="margin-top: -21px; margin-right: -13px;"
                    data-bind="asyncClick: LoadPaymentInfoFromReferences"
                >
                    <a class="btn btn-primary btn-xs">
                        <i class="fa fa-database"></i>
                        {TextResources.Invoices.DocumentLoadFromSystem}
                    </a>
                </div>

                <PaymentSelectionNonReadOnly></PaymentSelectionNonReadOnly>
                <PaymentExpiryNonReadOnly></PaymentExpiryNonReadOnly>
            </div>
        </fieldset>
    );
}

function JobOrderInformation() {
    let doc: Document;
    return (
        <div
            class="row single-field striped"
            data-bind="css : { 'locked-job-order' : (JobOrder() && JobOrder().Locked) }"
        >
            <div class="control-group">
                <label class="without-dot">
                    {TextResources.Invoices.DocumentJobOrder}
                    <input
                        type="checkbox"
                        style="left: -20px; top: 10px;"
                        data-bind={{ visible: doc.CanCopy, checked: doc.JobOrderSelected }}
                    />
                </label>
                <select2
                    readOnly={() => "JobOrderReadOnly"}
                    simple={true}
                    allowClear={true}
                    noFormControl={true}
                    value={() => "FKJobOrder"}
                    dataSource={() => "JobOrdersDataSource"}
                    placeholder={TextResources.Invoices.DocumentJobOrderPlaceholder}
                    listener={() => "$data"}
                    styles="width: 13.5cm"
                ></select2>
                <button
                    class="btn btn-primary btn-sm margin-left-5"
                    title={TextResources.JobOrder.DetailedInfoTitleSimple}
                    data-bind={{ enable: !!doc.FKJobOrder(), click: doc.openJobOrderDetailedInfo.bind(doc) }}
                >
                    <i class="fa fa-info-circle"></i>
                </button>
                <button
                    class="btn btn-primary btn-sm margin-left-5"
                    title={TextResources.JobOrder.GoToJobOrder}
                    data-bind={{ enable: !!doc.FKJobOrder(), click: doc.OpenJobOrder.bind(doc) }}
                >
                    <i class="fa fa-reply"></i>
                </button>
                <button
                    class="btn btn-primary btn-sm margin-left-5"
                    title={TextResources.Blog.GoToBlog}
                    data-bind={{ enable: !!doc.FKJobOrder(), click: doc.OpenBlog.bind(doc) }}
                >
                    <i class="fa fa-comments"></i>
                </button>
            </div>

            <ko-if data-bind="JobOrder() && JobOrder().Locked">
                <ko-with data-bind="JobOrder">
                    <div class="locked-job-order-message">
                        {TextResources.Invoices.DocumentJobOrderBlocked}{" "}
                        <span data-bind="text : LockedByDescription"></span>{" "}
                        {TextResources.Invoices.DocumentJobOrderWithMotivation}{" "}
                        <span data-bind="text : LockMotivationDescription"></span>
                    </div>
                </ko-with>
            </ko-if>
        </div>
    );
}

function ResponsiblesInformationReadOnly() {
    let doc: Document;
    return (
        <>
            <div class="row single-field striped">
                <div class="control-group flex-container flex-child-center" style="width: 15.5cm">
                    <label class="without-dot">{TextResources.Invoices.DocumentCommercialResponsible}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "CommercialResourcesDataSource"}
                        value={() => "FKCommercialResponsible"}
                        listener={() => "$data"}
                        placeholder={TextResources.Invoices.DocumentSearchPlaceholder}
                        styles="width: 6.75cm"
                    ></select2>

                    <label class="without-dot">{TextResources.Invoices.DocumentAdministrativeResponsible}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "AdministrativeResourcesDataSource"}
                        value={() => "FKAdministrativeResponsible"}
                        listener={() => "$data"}
                        placeholder={TextResources.Invoices.DocumentSearchPlaceholder}
                        styles="width: 6.75cm"
                    ></select2>
                </div>
            </div>
            <div class="row single-field striped">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentOutcome}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "OutcomesDataSource"}
                        value={() => "FKOutcome"}
                        styles="width: 16.5cm"
                        placeholder={TextResources.Invoices.DocumentOutcomePlaceholder}
                    ></select2>
                </div>
            </div>

            <div class="row single-field">
                <div class="control-group flex-container">
                    <div className="flex-container flex-1">
                        <label class="without-dot">{TextResources.Invoices.DocumentReferenceNumber}</label>
                        <span
                            class="half-row"
                            style="display : inline-block;width : 4cm"
                            data-bind={{ text: doc.ReferenceNumber }}
                        ></span>
                    </div>
                    <div className="flex-container">
                        <label class="without-dot">{TextResources.Invoices.DocumentReferenceDate}</label>
                        <span
                            class="half-row"
                            style="display : inline-block;width : 3cm"
                            data-bind={{ dateText: doc.ReferenceDate }}
                        ></span>
                    </div>
                    <div className="flex-container flex-1">
                        <label class="without-dot">{TextResources.Invoices.DocumentExternalReference}</label>
                        <span
                            class="half-row"
                            style="display : inline-block;width : 4cm"
                            data-bind={{ text: doc.ExternalReference }}
                        ></span>
                    </div>
                </div>
            </div>
        </>
    );
}

function ResponsiblesInformationNonReadOnly() {
    let doc: Document;
    return (
        <>
            <div class="row single-field striped">
                <div class="control-group flex-container flex-child-center" style="width: 15.5cm">
                    <label class="without-dot">{TextResources.Invoices.DocumentCommercialResponsible}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "CommercialResourcesDataSource"}
                        value={() => "FKCommercialResponsible"}
                        listener={() => "$data"}
                        placeholder={TextResources.Invoices.DocumentSearchPlaceholder}
                        styles="width: 6.75cm"
                    ></select2>

                    <label class="without-dot">{TextResources.Invoices.DocumentAdministrativeResponsible}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "AdministrativeResourcesDataSource"}
                        value={() => "FKAdministrativeResponsible"}
                        listener={() => "$data"}
                        placeholder={TextResources.Invoices.DocumentSearchPlaceholder}
                        styles="width: 6.75cm"
                    ></select2>
                </div>
            </div>
            <div class="row single-field striped">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentOutcome}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        simple={true}
                        allowClear={true}
                        noFormControl={true}
                        dataSource={() => "OutcomesDataSource"}
                        value={() => "FKOutcome"}
                        styles="width: 16.5cm"
                        placeholder={TextResources.Invoices.DocumentOutcomePlaceholder}
                    ></select2>
                </div>
            </div>

            <div class="row single-field">
                <div class="control-group flex-container flex-1">
                    <div className="flex-container">
                        <label class="without-dot">{TextResources.Invoices.DocumentReferenceNumber}</label>
                        <input
                            autoComplete="off"
                            type="text"
                            class="half-row form-control"
                            style="display: inline-block; width: 4cm"
                            data-bind={{ value: doc.ReferenceNumber }}
                        />
                    </div>
                    <div className="flex-container">
                        <label class="without-dot">{TextResources.Invoices.DocumentReferenceDate}</label>
                        <input
                            autoComplete="off"
                            type="text"
                            class="half-row form-control"
                            style="display: inline-block; width: 3cm"
                            data-bind={{ nullableDatePicker: doc.ReferenceDate }}
                        />
                    </div>
                    <div className="flex-container">
                        <label class="without-dot">{TextResources.Invoices.DocumentExternalReference}</label>
                        <input
                            autoComplete="off"
                            type="text"
                            class="half-row form-control"
                            style="display: inline-block; width: 4cm"
                            data-bind={{ value: doc.ExternalReference }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

function WarehouseInformation() {
    let doc: Document;

    return (
        <>
            <ko-bind data-bind={{ if: doc.CanHaveSourceWarehouse() && doc.IsWarehouseEnabled }}>
                <div class="row single-field striped">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentSourceWarehouse}</label>
                        <select2
                            readOnly={() => "BlockSourceWarehouse"}
                            value={() => "SourceWarehouseId"}
                            dataSource={() => "SourceWarehousesDocumentDataSource"}
                            simple={true}
                            noFormControl={true}
                            styles="width: 16.5cm"
                            placeholder={TextResources.Invoices.DocumentSelectPlaceholder}
                            listener={() => "$data"}
                        ></select2>
                    </div>
                </div>
            </ko-bind>

            <ko-bind data-bind={{ if: doc.CanHaveDestinationWarehouse() && doc.IsWarehouseEnabled }}>
                <div class="row single-field striped">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentDestinationWarehouse}</label>
                        <select2
                            readOnly={() => "BlockDestinationWarehouse"}
                            value={() => "WarehouseId"}
                            dataSource={() => "DestinationWarehousesDocumentDataSource"}
                            simple={true}
                            noFormControl={true}
                            styles="width: 16.5cm"
                            placeholder={TextResources.Invoices.DocumentSelectPlaceholder}
                            listener={() => "$data"}
                        ></select2>
                    </div>
                </div>
            </ko-bind>
        </>
    );
}

function AdminNotesReadOnly() {
    let doc: Document;

    return (
        <>
            <ko-bind data-bind={{ if: !!doc.AdministrativeNotes() }}>
                <div class="row single-field striped">
                    <div class="control-group">
                        <label class="without-dot" style="width:100%; margin-bottom: 0; text-align: left">
                            {TextResources.Invoices.DocumentAdminNotes}
                        </label>
                        <div style="background: white; margin: 20px; margin-top: 5px; border: 1px solid lightgray;">
                            <span data-bind={{ html: doc.AdministrativeNotes }}></span>
                        </div>
                    </div>
                </div>
            </ko-bind>
        </>
    );
}

function AdminNotesNonReadOnly() {
    let doc: Document;

    return (
        <>
            <div class="row single-field striped">
                <div class="control-group">
                    <label class="without-dot" style="width:100%; margin-bottom: 0; text-align: left">
                        {TextResources.Invoices.DocumentAdminNotes}
                    </label>
                    <div style="background: white; margin: 20px; margin-top: 5px; border: 1px solid lightgray;">
                        <textarea
                            placeholder={TextResources.Invoices.DocumentAdminNotesPlaceholder}
                            data-bind="htmlEditor : AdministrativeNotes, htmlEditorTrigger: AdministrativeNotesTrigger, htmlEditorToolbarConfig : 'supportedByReportsTools', htmlEditorOptions : { inlineMode : true, fontSize_sizes : '9pt;10pt;11pt;12pt;13pt;14pt;15pt;16pt;17pt;18pt;19pt;20pt;21pt;22pt;23pt;24pt', language : 'it', resize_dir : 'vertical', scayt_sLang: 'it_IT', height : 100 }"
                        ></textarea>
                    </div>
                </div>
            </div>
        </>
    );
}

function CIGCUPReadOnly() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveCIGCUP }}>
            <div class="row cig-cup-row">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentCIG}</label>
                    <span
                        class="half-row"
                        style="display: inline-block; width: 3cm"
                        data-bind={{ text: doc.CIG }}
                    ></span>

                    <label class="without-dot">{TextResources.Invoices.DocumentCUP}</label>
                    <span
                        class="half-row"
                        style="display: inline-block; width: 3cm"
                        data-bind={{ text: doc.CUP }}
                    ></span>
                </div>
            </div>
        </ko-bind>
    );
}

function CIGCUPNonReadOnly() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveCIGCUP }}>
            <div class="row cig-cup-row">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentCIG}</label>
                    <input
                        autoComplete="off"
                        type="text"
                        class="half-row form-control"
                        style="display: inline-block; width: 3cm"
                        data-bind={{ value: doc.CIG }}
                    />

                    <label class="without-dot">{TextResources.Invoices.DocumentCUP}</label>
                    <input
                        autoComplete="off"
                        type="text"
                        class="half-row form-control"
                        style="display: inline-block; width: 3cm"
                        data-bind={{ value: doc.CUP }}
                    />
                </div>
            </div>
        </ko-bind>
    );
}

function State() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveState }}>
            <div class="row single-field striped">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentStatus}</label>
                    <select2
                        readOnly={() => "ReadOnly() && !StateFieldUnlocked()"}
                        value={() => "FKState"}
                        dataSource={() => "EstimateStatesDataSource"}
                        listener={() => "$data"}
                        simple={true}
                        allowClear={false}
                        placeholder={TextResources.Invoices.DocumentStatusPlaceholder}
                        inputCss="full-row"
                        noFormControl={true}
                        styles="width: 15.5cm"
                    ></select2>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm margin-left-5"
                        data-bind={{
                            toggle: doc.StateFieldUnlocked,
                            enable: doc.ReadOnly() && !doc.StateFieldUnlocked(),
                        }}
                    >
                        <i class="fa fa-unlock"></i>
                    </button>
                </div>
            </div>
        </ko-bind>
    );
}

function Validity() {
    let doc: Document;
    return (
        <ko-bind data-bind={{ if: doc.CanHaveValidity }}>
            <div class="row single-field">
                <div class="control-group">
                    <label class="without-dot">{TextResources.Invoices.DocumentValidity}</label>
                    <select2
                        readOnly={() => "ReadOnly"}
                        value={() => "FKValidityType"}
                        dataSource={() => "DocumentValidityTypesDataSource"}
                        listener={() => "$data"}
                        simple={true}
                        allowClear={true}
                        placeholder={TextResources.Invoices.DocumentValidityPlaceholder}
                        inputCss="full-row"
                        noFormControl={true}
                        styles="width: 16.5cm"
                    ></select2>
                </div>
            </div>
        </ko-bind>
    );
}

export function DocumentInformationReadOnly(props: { document: Document }) {
    let doc: Document;
    const { document } = props;

    return (
        <div class="invoice-informations bootstrap" data-as={{ doc }}>
            <div class="row flex-container">
                <DocumentDateAndNumberFieldsReadOnly />
                <DocumentElectronicInvoicingFieldsReadOnly />
            </div>

            <DocumentElectronicInvoiceTypeField />

            <div
                class="row document-payment-group"
                style="margin-top: 10px"
                data-bind={{ hidden: doc.CanBeAnElectronicInvoice }}
            >
                <PaymentInformationReadOnly />
            </div>

            <SendMailOptions
                documentId={document.DocumentId}
                automaticMailSend={document.SendDocumentMail}
                includeDocumentAttachments={document.IncludeDocumentAttachmentsInMail}
                lastSentMail={document.LastMailSentStatus}
            />
            <JobOrderInformation />
            <ResponsiblesInformationReadOnly />
            <CIGCUPReadOnly />
            <State />
            <Validity />

            <ko-bind data-bind={{ if: doc.ArticlesHintSearchEnabledActionVisibility }}>
                <WarehouseInformation />
            </ko-bind>

            <AdminNotesReadOnly />
        </div>
    );
}

export function DocumentInformationNonReadOnly(props: { document: Document }) {
    let doc: Document;
    const { document } = props;

    return (
        <>
            <div class="invoice-informations bootstrap" data-as={{ doc }}>
                <div class="row flex-container">
                    <DocumentDateAndNumberFields />
                    <DocumentElectronicInvoicingFields />
                </div>
                <DocumentVersionFields />

                <DocumentElectronicInvoiceTypeField />

                <div class="row document-payment-group" style="margin-top: 10px">
                    <PaymentInformationNonReadOnly />
                </div>

                <SendMailOptions
                    documentId={document.DocumentId}
                    automaticMailSend={document.SendDocumentMail}
                    includeDocumentAttachments={document.IncludeDocumentAttachmentsInMail}
                    lastSentMail={document.LastMailSentStatus}
                />
                <JobOrderInformation />
                <ResponsiblesInformationNonReadOnly />
                <CIGCUPNonReadOnly />
                <State />
                <Validity />

                <ko-bind data-bind={{ if: doc.ArticlesHintSearchEnabledActionVisibility }}>
                    <WarehouseInformation />
                </ko-bind>

                <AdminNotesNonReadOnly />
            </div>
        </>
    );
}
