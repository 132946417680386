import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { CustomerOrderDefaultValuesSettingsUi } from "../../ui/Documents/default-values/CustomerOrderDefaultValuesSettingsUi";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class CustomerOrdersProvider extends DocumentsProvider
{
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private protocol : IVatRegister)
    {
        super(ProlifeSdk.CustomerOrderEntityTypeCode);

        this.CanEdit = protocol.Stato == ProlifeSdk.EnabledProtocolState;
        this.ProviderId = "Cor";

        this.Id = protocol.IdRegistroIVA;
        this.Name(protocol.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(protocol.NuovoLabel);

        this.CanCreateNew(protocol.Stato == ProlifeSdk.EnabledProtocolState && this.authorizationService.isAuthorized("Warehouse_CustomerOrder"));
        this.CanView(this.CanCreateNew() || (protocol.Stato != ProlifeSdk.HiddenProtocolState && this.authorizationService.isAuthorized("Warehouse_ViewCustomerOrder")));

        this.ProviderId = "Cor" + this.Id;
        this.HasDefaultValuesSettingsUi = true;
        this.DefaultSearchProvider = protocol.DefaultSearchProvider;
    }

    GetDocumentGroupLabel(): string {
        return this.protocol.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.protocol.PositionInList;
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        return [{
            DocumentType: ProlifeSdk.CustomerOrderEntityTypeCode,
            NewLabel: this.protocol.NuovoLabel
        }];
    }

    GetDefaultValuesSettingsUi() {
        return new CustomerOrderDefaultValuesSettingsUi(this.protocol, this.ProviderId);
    }
}
