/* eslint-disable @typescript-eslint/no-this-alias */
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { classNames, ComponentUtils, Portal, reloadNow } from "../Core/utils/ComponentUtils";
import { If, IfNot, With } from "./IfIfNotWith";
import { IDataSource, IDataSourceModel } from "../DataSources/IDataSource";
import { isObservableArrayDataSource, ObservableArrayDataSource } from "../DataSources/ObservableArrayDataSource";
import { TextInput, _TextInput } from "./TextInput";
import TsxForEach from "./ForEach";
import { Layout } from "./Layouts";
import { ActivityIndicator } from "./ActivityIndicator";
import { Delay } from "../Decorators/Delay";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    select: {
        display: "flex",
        flex: 1,
        cursor: "default",

        "table.table.compact.editable &": {
            padding: "4px 12px",
            height: "28px",
            margin: "0px",
            borderTop: "none",
            borderBottom: "none",
            borderLeft: "none",
            width: "calc(100%)",
        },

        "&.disabled": {
            cursor: "not-allowed",
            backgroundColor: "#eeeeee",

            "& .selected-item": {
                "&.list-item": {
                    cursor: "not-allowed",
                },
            },
        },

        "& > .placeholder": {
            color: "#888888",
            marginRight: "auto",
        },

        "& > .selected-item": {
            flex: 1,
            overflow: "hidden",

            "&.list-item": {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                margin: "-3px 0",

                "& .list-item-icon, & .list-item-secondary-icon": {
                    textAlign: "center",
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    lineHeight: "18px",
                },

                "& .list-item-content": {
                    lineHeight: "12px",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                },

                "& .list-item-title": {
                    fontSize: "12px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },

                "& .list-item-subtitle": {
                    fontSize: "10px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },

                "& .list-item-icon": {
                    marginRight: "5px",
                },

                "& .list-item-secondary-icon": {
                    marginLeft: "5px",
                },
            },
        },

        "& > .clear-button": {
            marginLeft: "auto",
            fontSize: "1.3em",
            lineHeight: "1.2em",
            color: "grey",
            paddingLeft: "10px",

            "&:hover": {
                color: "black",
            },

            "&:before": {
                display: "block",
                width: 10,
                height: 10,
                cursor: "pointer",
                content: "'\\D7'",
            },
        },

        "& > .dropdown-icon": {
            marginRight: "-8px",
            marginTop: "-5px",
            marginBottom: "-5px",
            marginLeft: "5px",
            paddingLeft: "3px",
            lineHeight: "25px",

            "& > i.fa": {
                fontSize: "10px",
                lineHeight: "10px",
            },
        },
    },
    selectDropdown: {
        position: "fixed",
        background: "white",
        border: "1px solid #e5e5e5",
        padding: "3px",
        zIndex: 10,

        "&.down": {
            borderTop: "none",
        },
        "&.up": {
            borderBottom: "none",
        },

        "& > .items": {
            marginTop: "3px",
            height: "255px",
            display: "flex",

            "& > .loader": {
                minHeight: "34px",
            },

            "& > .no-results": {
                color: "#888",
                textAlign: "center",
                fontSize: "13px",
                marginTop: "10px",
            },

            "& .minimum-input-length": {
                padding: "3px 7px 4px",
                background: "#f4f4f4",
            },

            "& > .list-item": {
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
                padding: "5px",

                "&.group": {
                    "& > .list-item-content": {
                        "& > .list-item-title": {
                            fontWeight: 800,
                            fontSize: "14px",
                        },
                    },
                },

                "&.children": {
                    paddingLeft: "25px",
                },

                "&.active": {
                    backgroundColor: "#2474f6",
                    color: "white",

                    "& .list-item-subtitle": {
                        color: "#e5e5e5",
                    },
                },

                "&:hover": {
                    backgroundColor: "#2474f6",
                    color: "white",

                    "& .list-item-subtitle": {
                        color: "#e5e5e5",
                    },
                },

                "& .list-item-icon, & .list-item-secondary-icon": {
                    textAlign: "center",
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                    minHeight: "18px",
                    minWidth: "18px",
                    lineHeight: "18px",
                },

                "& .list-item-title": {
                    fontSize: "13px",
                },

                "& .list-item-subtitle": {
                    fontSize: "11px",
                },

                "& .list-item-icon": {
                    marginRight: "5px",
                },

                "& .list-item-secondary-icon": {
                    marginLeft: "5px",
                },
            },
        },
    },
    selectDropdownBackdrop: {
        position: "fixed",
        inset: 0,
    },
    selectWithLabel: {
        "& .input-group": {
            display: "flex",
            flexWrap: "none",

            "& .form-control": {
                display: "flex",
            },
            "& .input-group-btn": {
                display: "flex",
                width: "38px",
            },
        },
    },
});
const { classes } = styleSheet.attach();

export type SelectProps<I extends number | string, T> = {
    placeholder?: string | ko.Subscribable<string>;
    searchPlaceholder?: string;
    initialValue?: I;
    value?: ko.Observable<I> | ko.Computed<I>;
    readOnly?: ko.Observable<boolean> | ko.Computed<boolean>;
    allowClear?: boolean;
    dataSource: ObservableArrayDataSource<T, I> | IDataSource<I, T>;
    style?: CSSProperties;
    className?: string;
    renderSelectedItem?: (item: IDataSourceModel<I, T>) => React.ReactElement;
    renderItem?: (item: IDataSourceModel<I, T>) => React.ReactElement;
    renderNoResults?: () => React.ReactElement;
    noResultsMessage?: string;
    minimumInputLength?: number;
    hideSelectedDataFromMenu?: boolean;

    onSelect?: (item: IDataSourceModel<I, T>) => void;
    onDeselect?: (item: IDataSourceModel<I, T>) => void;
    beforeSelect?: (item: IDataSourceModel<I, T>) => Promise<boolean>;
};

export function Select<I extends number | string, T>(props: SelectProps<I, T>) {
    const C = require("./Select")._Select as typeof _Select;
    return <C {...props} />;
}

export class _Select<I extends number | string, T> {
    static defaultProps: Partial<SelectProps<any, any>> = {
        placeholder: "",
        searchPlaceholder: "Cerca...",
        allowClear: false,
        noResultsMessage: "Nessun risultato",
        className: "",
        minimumInputLength: 0,
        hideSelectedDataFromMenu: true,
    };

    loading: ko.Observable<boolean> = ko.observable();
    remainingInputCharsToStartSearch: ko.Observable<number> = ko.observable();
    showMinimumInputLengthMessage: ko.Computed<boolean>;
    minimumInputLengthMessage: ko.Computed<string>;
    hasValue: ko.Computed<boolean>;
    showClearButton: ko.Computed<boolean>;
    isClosed: ko.Observable<boolean> = ko.observable(true);
    hasGroupedData: ko.Observable<boolean> = ko.observable(false);
    selectedItem: ko.Observable<IDataSourceModel<I, T>> = ko.observable();
    activeItem: ko.Observable<IDataSourceModel<I, T>> = ko.observable();
    items: ko.ObservableArray<IDataSourceModel<I, T>> = ko.observableArray();
    filter: ko.Observable<string> = ko.observable("");

    dropdownSize: ko.Observable<{ x: number; y: number; width: number; height: number }> = ko.observable({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    dropdownStyle: ko.Observable<string> = ko.observable("down");
    dropdownZIndex: ko.Observable<number> = ko.observable(0);

    private mainDiv: HTMLDivElement;
    private filterSubscription: ko.Subscription;
    private valueSubscription: ko.Subscription;
    private readOnlySubscription: ko.Subscription;
    private textInput: _TextInput;

    keyDownHandler: (e: KeyboardEvent) => void;
    positionUpdater: number;
    portal: Portal;

    constructor(private props: SelectProps<I, T>) {
        if (!this.props.readOnly) this.props.readOnly = ko.observable(false);
        if (!this.props.value) this.props.value = ko.observable();

        if (this.props.initialValue) this.props.value(this.props.initialValue);

        this.hasValue = ko.computed(() => this.props.value() !== undefined && this.props.value() !== null);
        this.showClearButton = ko.computed(() => this.props.allowClear && this.hasValue());
        this.showMinimumInputLengthMessage = ko.computed(
            () => this.props.minimumInputLength > 0 && (this.filter() ?? "").length < this.props.minimumInputLength
        );
        this.minimumInputLengthMessage = ko.computed(() =>
            this.remainingInputCharsToStartSearch() === 1
                ? String.format(
                      TextResources.ProlifeSdk.MinimumInputLengthSingular,
                      this.remainingInputCharsToStartSearch()
                  )
                : String.format(
                      TextResources.ProlifeSdk.MinimumInputLengthPlural,
                      this.remainingInputCharsToStartSearch()
                  )
        );
        this.remainingInputCharsToStartSearch(this.props.minimumInputLength);

        this.filterSubscription = this.filter.subscribe(() => this.reloadBecauseFilterHasChanged(), "change");
        this.valueSubscription = this.props.value.subscribe(() => this.selectItemById(this.props.value()), "change");
        this.readOnlySubscription = this.props.readOnly.subscribe(() => {
            if (this.mustClose()) this.openClose();
        });

        const getIndexOfActiveItem = () => this.items().indexOf(this.activeItem());
        const fixIndex = (index: number) => Math.max(0, Math.min(index, this.items().length - 1));
        const setActiveItem = (index: number) => this.activeItem(this.items().slice(fixIndex(index))[0]);

        this.keyDownHandler = (e: KeyboardEvent) => {
            if (this.isClosed()) return;
            if (this.loading()) return;

            if (e.key === "ArrowUp") {
                const index = getIndexOfActiveItem();
                if (index <= 0) return;

                e.preventDefault();
                setActiveItem(index - 1);
                this.textInput.blur();
            } else if (e.key === "ArrowDown") {
                const index = getIndexOfActiveItem();
                if (index === this.items().length - 1) return;

                e.preventDefault();
                setActiveItem(index + 1);
                this.textInput.blur();
            } else if (e.key === "Home" && this.activeItem()) {
                if (this.items().length === 0) return;

                e.preventDefault();
                setActiveItem(0);
            } else if (e.key === "End" && this.activeItem()) {
                if (this.items().length === 0) return;

                e.preventDefault();
                setActiveItem(this.items().length - 1); //Last item
            } else if (e.key === "PageDown" && this.activeItem()) {
                const index = this.items().indexOf(this.activeItem());
                if (index === this.items().length - 1) return;

                e.preventDefault();
                setActiveItem(index + 10);
            } else if (e.key === "PageUp" && this.activeItem()) {
                const index = this.items().indexOf(this.activeItem());
                if (index <= 0) return;

                e.preventDefault();
                setActiveItem(index - 10);
            } else if (e.key === "Enter" && this.activeItem()) {
                this.selectItem(this.activeItem());
            } else if (e.key === "Escape" && !this.isClosed()) {
                this.openClose();
            }
        };

        document.addEventListener("keydown", this.keyDownHandler, true);
    }

    @Delay()
    private reloadBecauseFilterHasChanged() {
        if (!this.isClosed()) this.loadItems();
    }

    componentDidMount() {
        if (this.props.value()) this.selectItemById(this.props.value());

        this.createPortal();
    }

    private createPortal() {
        const sel = this;

        this.portal = ComponentUtils.createPortal(
            () =>
                ComponentUtils.bindTo(
                    <IfNot condition={sel.isClosed}>
                        {() => (
                            <>
                                <div
                                    className={classes.selectDropdownBackdrop}
                                    data-bind={{
                                        event: { mousedown: sel.openClose.bind(sel) },
                                        style: { zIndex: sel.dropdownZIndex },
                                    }}
                                ></div>
                                <div
                                    className={classes.selectDropdown}
                                    data-bind={{
                                        click: () => {},
                                        clickBubble: false,
                                        style: {
                                            zIndex: sel.dropdownZIndex,
                                            left: sel.dropdownSize().x,
                                            top: sel.dropdownSize().y,
                                            width: sel.dropdownSize().width,
                                            height: sel.dropdownSize().height,
                                        },
                                        css: sel.dropdownStyle,
                                    }}
                                >
                                    <div className="filter">
                                        <TextInput
                                            forwardRef={(ti) => (this.textInput = ti)}
                                            simple
                                            placeholder={this.props.searchPlaceholder}
                                            rightIcon="fa fa-search"
                                            value={this.filter}
                                            valueUpdate="afterkeydown"
                                            canClear
                                        />
                                    </div>
                                    <Layout.ScrollContainer className="items" systemScrollable>
                                        <If
                                            condition={() =>
                                                this.showMinimumInputLengthMessage() && this.items().length === 0
                                            }
                                        >
                                            {() => this.renderMinimumInputLenghtMessage()}
                                        </If>
                                        <If condition={() => this.loading() && !this.showMinimumInputLengthMessage()}>
                                            {() => <ActivityIndicator className="loader" />}
                                        </If>
                                        <If
                                            condition={() =>
                                                !this.loading() &&
                                                this.items().length === 0 &&
                                                !this.showMinimumInputLengthMessage()
                                            }
                                        >
                                            {() => this.renderNoResults()}
                                        </If>
                                        <TsxForEach data={this.items} as="item">
                                            {(item) => this.renderItem(item)}
                                        </TsxForEach>
                                    </Layout.ScrollContainer>
                                </div>
                            </>
                        )}
                    </IfNot>,
                    sel,
                    "sel"
                ) as React.ReactElement
        );
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.positionUpdater);

        this.filterSubscription.dispose();
        this.filterSubscription = null;

        this.valueSubscription.dispose();
        this.valueSubscription = null;

        this.readOnlySubscription.dispose();
        this.readOnlySubscription = null;

        this.mainDiv = null;

        this.portal.dispose();
        this.portal = null;

        document.removeEventListener("keydown", this.keyDownHandler);
    }

    private updateDropdownPosition() {
        if (this.mainDiv) {
            const dropDownHeight = 300;
            let dropdownStyle = "down";

            let node: HTMLElement = this.mainDiv.offsetParent as HTMLElement;
            let x = this.mainDiv.offsetLeft;
            let y = this.mainDiv.offsetTop;
            while (!!node && node !== document.body) {
                const compStyle = window.getComputedStyle(node);
                x += node.offsetLeft + parseFloat(compStyle.borderLeftWidth ?? "0") - node.scrollLeft;
                y += node.offsetTop + parseFloat(compStyle.borderTopWidth ?? "0") - node.scrollTop;

                node = node.offsetParent as HTMLElement;
            }

            const relativeParent = document.body;
            const bodyRect = relativeParent.getBoundingClientRect();
            const zIndex = $(this.mainDiv)
                .parents()
                .toArray()
                .reduce((p, c) => {
                    const s = getComputedStyle(c);
                    return (s.zIndex === "auto" ? 0 : parseInt(s.zIndex)) + p;
                }, 10);
            const { width, height } = this.mainDiv.getBoundingClientRect();

            if (y + height + dropDownHeight < bodyRect.height) y += height;
            else {
                y -= dropDownHeight;
                dropdownStyle = "up";
            }

            const lastSize = this.dropdownSize();
            if (
                lastSize.x != x ||
                lastSize.y != y ||
                lastSize.width != width ||
                this.dropdownStyle() !== dropdownStyle
            ) {
                this.dropdownSize({ x, y, width, height: dropDownHeight });
                this.dropdownStyle(dropdownStyle);
            }

            if (this.dropdownZIndex() !== zIndex) {
                this.dropdownZIndex(zIndex);
            }
        }

        if (!this.isClosed()) requestAnimationFrame(this.updateDropdownPosition.bind(this));
    }

    private openClose(event?: MouseEvent) {
        if (this.canNotOpen()) return;

        this.updateDropdownPosition();

        this.isClosed(!this.isClosed());

        if (!this.isClosed()) {
            this.positionUpdater = requestAnimationFrame(this.updateDropdownPosition.bind(this));

            this.loadItems();

            this.textInput?.focus();
        } else {
            cancelAnimationFrame(this.positionUpdater);
            this.filter("");
        }
    }

    private mustClose() {
        return this.props.readOnly() && !this.isClosed();
    }

    private canNotOpen() {
        return this.props.readOnly() && this.isClosed();
    }

    private async loadItems() {
        if (this.loading()) return;

        this.loading(true);
        this.items([]);
        this.activeItem(undefined);

        try {
            const textFilter = this.filter() ?? "";
            this.remainingInputCharsToStartSearch(Math.max(this.props.minimumInputLength - textFilter.length, 0));

            if (textFilter.length >= this.props.minimumInputLength) {
                if (isObservableArrayDataSource(this.props.dataSource)) {
                    this.items(this.getItems());
                } else {
                    const isGroupedData = this.props.dataSource.isGroupedData(null, textFilter);
                    this.hasGroupedData(isGroupedData);
                    const results = !isGroupedData
                        ? await this.props.dataSource.getData(null, textFilter, 0, 50)
                        : await this.loadGroupedData();

                    const items = this.filterSelectedItemIfRequired(results);
                    this.items(items);
                }
            }
        } finally {
            this.loading(false);
        }
    }

    private filterSelectedItemIfRequired(items: IDataSourceModel<any, any>[]): IDataSourceModel<any, any>[] {
        const selectedItemId = this.selectedItem()?.id;
        return this.props.hideSelectedDataFromMenu ? items.filter((item) => selectedItemId !== item.id) : items;
    }

    private async loadGroupedData() {
        const dataSource = this.props.dataSource as IDataSource;
        const groups = await dataSource.getData(null, this.filter(), 0, 50);

        const promises = [];
        for (const group of groups) {
            if (group.isGroup) promises.push(dataSource.getData(group, this.filter(), 0, 50));
            else promises.push(new Promise<void>((resolve) => resolve()));
        }

        let orderedData = [];
        const results = await Promise.all<IDataSourceModel[][]>(promises);

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];

            if (!group.isGroup) {
                orderedData.push(group);
                continue;
            }

            const groupChildren = results[i];
            if (groupChildren.length === 0) continue;

            orderedData.push(group);
            orderedData = orderedData.concat(groupChildren);
        }

        return orderedData;
    }

    private getItems() {
        const { array, factory } = this.props.dataSource as ObservableArrayDataSource<T, I>;
        return array().map((i) => factory(i));
    }

    private async clear() {
        if (this.props.readOnly()) return;

        if (this.props.allowClear) {
            this.selectItem(undefined);
        }
    }

    private async selectItemById(id: I) {
        if (!id) {
            this.internalSelectItem(undefined);
            return;
        }

        if (id === this.selectedItem()?.id) return; //E' gia selezionato

        let item: IDataSourceModel<I, T>;
        if (isObservableArrayDataSource(this.props.dataSource)) {
            item = this.getItems().firstOrDefault((i) => i.id === id);
        } else {
            const result = await this.props.dataSource.getById(null, [id]);
            item = result[0];
        }

        if (!item) {
            this.internalSelectItem(undefined);
        } else {
            this.internalSelectItem(item);
        }
    }

    private internalSelectItem(item: IDataSourceModel<I, T>) {
        //Devono essere effettuati in questo ordine per evitare che venga fatta una chiamata di troppo al data source
        this.selectedItem(item);
        this.props.value(item?.id);
        this.isClosed(true);
        this.filter("");
    }

    private async selectItem(item: IDataSourceModel<I, T>) {
        if (this.props.beforeSelect) {
            if (!(await this.props.beforeSelect(item))) return;
        }

        if (this.props.onDeselect && this.selectedItem()) {
            this.props.onDeselect(this.selectedItem());
        }

        this.internalSelectItem(item);

        if (this.props.onSelect && this.selectedItem()) {
            this.props.onSelect(item);
        }
    }

    private renderMinimumInputLenghtMessage() {
        const sel = this;

        return (
            <div className="minimum-input-length">
                <span data-bind={{ text: sel.minimumInputLengthMessage }}></span>
            </div>
        );
    }

    private renderNoResults() {
        if (this.props.renderNoResults) return <div className="no-results">{this.props.renderNoResults()}</div>;

        return <div className="no-results">{this.props.noResultsMessage}</div>;
    }

    private renderSelectedItem(): React.ReactElement {
        const item = this.selectedItem();

        if (this.props.renderSelectedItem)
            return <div className="selected-item list-item">{this.props.renderSelectedItem(item)}</div>;

        return (
            <div className="selected-item list-item">
                {item.icon && (
                    <span
                        className={ComponentUtils.classNames("list-item-icon", item.icon.icon)}
                        style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}
                    ></span>
                )}
                <div className="list-item-content">
                    <div className="list-item-title">{item.title}</div>
                    {item.subTitle && <small className="list-item-subtitle text-muted">{item.subTitle}</small>}
                </div>
                {item.secondaryIcon && (
                    <span
                        className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)}
                        style={{
                            backgroundColor: item.secondaryIcon.background,
                            color: item.secondaryIcon.foreground,
                        }}
                    ></span>
                )}
            </div>
        );
    }

    private renderItem(item: IDataSourceModel<I, T>): React.ReactElement {
        const sel = this;

        if (this.props.renderItem)
            return (
                <div
                    className="list-item"
                    data-bind={{
                        click: sel.selectItem.bind(sel, item),
                        clickBubble: false,
                        css: { active: sel.activeItem() === item },
                        scrollIntoViewIfNeeded: () => sel.activeItem() === item,
                    }}
                >
                    {this.props.renderItem(item)}
                </div>
            );

        return item.isGroup ? this.renderGroupItem(item) : this.renderListItem(item);
    }

    private renderGroupItem(item: IDataSourceModel<I, T>): React.ReactElement {
        return (
            <div className="list-item group">
                {item.icon && (
                    <span
                        className={ComponentUtils.classNames("list-item-icon", item.icon.icon)}
                        style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}
                    ></span>
                )}
                <div className="list-item-content">
                    <div className="list-item-title">{item.title}</div>
                </div>
            </div>
        );
    }

    private renderListItem(item: IDataSourceModel<I, T>): React.ReactElement {
        const sel = this;

        return (
            <div
                className="list-item"
                data-bind={{
                    click: sel.selectItem.bind(sel, item),
                    clickBubble: false,
                    css: { active: sel.activeItem() === item, children: sel.hasGroupedData },
                    scrollIntoViewIfNeeded: () => sel.activeItem() === item,
                }}
            >
                {item.icon && (
                    <span
                        className={ComponentUtils.classNames("list-item-icon", item.icon.icon)}
                        style={{ backgroundColor: item.icon.background, color: item.icon.foreground }}
                    ></span>
                )}
                <div className="list-item-content">
                    <div className="list-item-title">{item.title}</div>
                    {item.subTitle && <small className="list-item-subtitle text-muted">{item.subTitle}</small>}
                </div>
                {item.secondaryIcon && (
                    <span
                        className={ComponentUtils.classNames("list-item-secondary-icon", item.secondaryIcon.icon)}
                        style={{
                            backgroundColor: item.secondaryIcon.background,
                            color: item.secondaryIcon.foreground,
                        }}
                    ></span>
                )}
            </div>
        );
    }

    render() {
        const sel = this;

        return ComponentUtils.bindTo(
            <div
                tabIndex={0}
                className={ComponentUtils.classNames(
                    "form-control prolife-select",
                    classes.select,
                    this.props.className
                )}
                data-bind={{
                    click: sel.openClose,
                    css: { disabled: !!sel.props.readOnly() },
                    ref: (d) => (sel.mainDiv = d),
                }}
                style={this.props.style}
            >
                <span
                    className="placeholder text-ellipsis"
                    data-bind={{ text: sel.props.placeholder, hidden: sel.hasValue }}
                ></span>
                <With data={sel.selectedItem} as="item">
                    {() => this.renderSelectedItem()}
                </With>
                <div
                    className="clear-button"
                    data-bind={{
                        visible: sel.showClearButton() && !sel.props.readOnly(),
                        click: sel.clear.bind(sel),
                        clickBubble: false,
                    }}
                ></div>
                <div className="dropdown-icon">
                    <i
                        className="fa"
                        data-bind={{ css: { "fa-chevron-down": sel.isClosed, "fa-chevron-up": !sel.isClosed() } }}
                    />
                </div>
            </div>,
            this,
            "sel"
        );
    }
}

export namespace Select {
    export function WithLabel<I extends number | string, T>(
        props: SelectProps<I, T> & { label: string; containerClassName?: string; addon?: () => React.ReactElement }
    ) {
        return (
            <div className={classNames("form-group", props.containerClassName, classes.selectWithLabel)}>
                <label className="control-label">{props.label}</label>
                {props.addon ? (
                    <div className="input-group">
                        <Select {...props} />
                        <span className="input-group-btn">{props.addon()}</span>
                    </div>
                ) : (
                    <Select {...props} />
                )}
            </div>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(Select);
    reloadNow(Select.WithLabel);
}
