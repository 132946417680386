import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TodoListTask } from "../tasks-list/providers/TodoListTask";
import { IEstimatedBudgetDetails, EstimateBudgetRow } from "../tasks-list/TodoListActivity";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { WarehouseArticlesDataSource } from "../../../../DataSources/WarehouseArticlesDataSource";
import { ProxyDataSource } from "../../../../DataSources/ProxyDataSource";
import { CachedDataSource } from "../../../../DataSources/CachedDataSource";
import { ITodoListService, ITaskDialogOptions } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { ITodoListTask, IEstimateBudgetRow } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkflowActivitiesMultipliersManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowActivitiesMultipliersManager";
import { ITaskStatusIconHelper } from "../../../../ProlifeSdk/interfaces/todolist/ITaskStatusIconHelper";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IDataSource } from "../../../../DataSources/IDataSource";
import { IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IException } from "../../../../Core/interfaces/IException";
import { ResponseBase, ResponseData, ResponseError } from "../../../../Core/response/ResponseBase";

export class WorkflowActivitiesMultipliersManager implements IWorkflowActivitiesMultipliersManager {
    public templateName = "workflow-activities-multipliers-manager-dialog";
    public templateUrl = "todolist/templates/workflows";
    public title: string = ProlifeSdk.TextResources.Todolist.WorkflowActivitiesMultipliersManagerTitle;
    public modal: { close: (result?: any) => void };

    public WorkflowWithActivities: ko.Observable<WorkflowWithActivitiesViewModel> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private workflow: ITodoListWorkflow) {
        this.WorkflowWithActivities(new WorkflowWithActivitiesViewModel(this.workflow, ProlifeSdk.WorkedAmountMode));
        this.WorkflowWithActivities().load();
    }

    public close(): void {
        if (this.WorkflowWithActivities() && this.WorkflowWithActivities().hasChanges()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Todolist.WorkflowActivitiesMultipliersManagerPendingChangesMessage,
                ProlifeSdk.TextResources.Todolist.WorkflowActivitiesMultipliersManagerPendingChangesCancel,
                ProlifeSdk.TextResources.Todolist.WorkflowActivitiesMultipliersManagerPendingChangesConfirm,
                (confirm: boolean) => {
                    if (confirm) {
                        this.WorkflowWithActivities().dispose();
                        this.modal.close(this.workflow);
                    }
                }
            );

            return;
        }

        this.WorkflowWithActivities().dispose();
        this.modal.close(this.workflow);
    }

    public action(): void {
        this.WorkflowWithActivities()
            .save()
            .then((updatedWorkflow: ITodoListWorkflow) => {
                this.modal.close(this.workflow);
            });
    }

    public show(): Promise<ITodoListWorkflow> {
        return this.dialogsService.ShowModal<ITodoListWorkflow>(this, "fullscreen", null);
    }
}

export class WorkflowWithActivitiesViewModel implements IChangesNotificationsServiceObserver {
    public WorkflowTitle: ko.Observable<string> = ko.observable();
    public TasksList: ko.ObservableArray<ActivityAmountViewModel> = ko.observableArray([]);

    public AmountForMassiveUpdate: ko.Observable<number> = ko.observable();
    public AmountUnitOfMeasureForMassiveUpdate: ko.Observable<string> = ko.observable();
    public UpdateWorkflow: ko.Observable<boolean> = ko.observable(true);

    public NotFound: ko.Observable<boolean> = ko.observable(false);
    public IconHelper: ITaskStatusIconHelper;

    public EstimatedWorkTotalCosts: ko.Computed<number>;
    public EstimatedWorkTotalRevenues: ko.Computed<number>;
    public EstimatedWorkTotalRevenuesWithoutDiscounts: ko.Computed<number>;
    public EstimatedWorkMarkupAvg: ko.Computed<number>;
    public EstimatedWorkDiscountAvg: ko.Computed<number>;
    public EstimatedWorkRealMarkup: ko.Computed<number>;

    public EstimatedPurchasesTotalCosts: ko.Computed<number>;
    public EstimatedPurchasesTotalRevenues: ko.Computed<number>;
    public EstimatedPurchasesTotalRevenuesWithoutDiscounts: ko.Computed<number>;
    public EstimatedPurchasesMarkupAvg: ko.Computed<number>;
    public EstimatedPurchasesDiscountAvg: ko.Computed<number>;
    public EstimatedPurchasesRealMarkup: ko.Computed<number>;

    public EstimatedArticlesTotalCosts: ko.Computed<number>;
    public EstimatedArticlesTotalRevenues: ko.Computed<number>;
    public EstimatedArticlesTotalRevenuesWithoutDiscounts: ko.Computed<number>;
    public EstimatedArticlesMarkupAvg: ko.Computed<number>;
    public EstimatedArticlesDiscountAvg: ko.Computed<number>;
    public EstimatedArticlesRealMarkup: ko.Computed<number>;

    public ShowDetails: ko.Observable<boolean> = ko.observable(false);
    public RowsDetailsEnabled: ko.Observable<boolean> = ko.observable(false);
    public ShowUserCharactersDetails: ko.Observable<boolean> = ko.observable(false);
    public ShowPurchasesTypesDetails: ko.Observable<boolean> = ko.observable(false);

    public UserCharactersDetails: ko.Computed<IEstimatedBudgetDetails[]>;
    public PurchasesTypesDetails: ko.Computed<IEstimatedBudgetDetails[]>;

    public TotalCosts: ko.Computed<number>;
    public TotalRevenues: ko.Computed<number>;
    public TotalRevenuesWithoutDiscounts: ko.Computed<number>;
    public RealMarkup: ko.Computed<number>;
    public MarkupAvg: ko.Computed<number>;
    public DiscountAvg: ko.Computed<number>;

    private filterByActivitiesProgressMode: number = null;
    private showClosedJobOrdersOnTasks = false;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private WarehouseArticlesDataSource: IDataSource<number, IArticle> = new CachedDataSource(
        new WarehouseArticlesDataSource()
    );

    constructor(private workflow: ITodoListWorkflow, activitiesProgressMode: number = null) {
        this.filterByActivitiesProgressMode = activitiesProgressMode;

        //d.collantoni: Notifica disabilitata in data 23/05/2023
        //this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.JobOrderTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);

        this.IconHelper = this.todolistService.GetTaskStatusIconHelper();

        this.TasksList.subscribe((tl) => {
            tl.forEach((t) => t.SetShowClosedJobOrders(this.showClosedJobOrdersOnTasks));
        });

        this.EstimatedWorkTotalCosts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedWorkTotalCosts() || 0));

            return tot;
        });

        this.EstimatedWorkTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedWorkTotalRevenues() || 0));

            return tot;
        });

        this.EstimatedWorkTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedWorkTotalRevenuesWithoutDiscounts() || 0));

            return tot;
        });

        this.EstimatedWorkMarkupAvg = ko.computed(() => {
            let cost: number = this.EstimatedWorkTotalCosts();

            if (this.EstimatedWorkTotalRevenuesWithoutDiscounts() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;
            return (this.EstimatedWorkTotalRevenuesWithoutDiscounts() / cost - 1) * 100;
        });

        this.EstimatedWorkRealMarkup = ko.computed(() => {
            let cost = this.EstimatedWorkTotalCosts();

            if (this.EstimatedWorkTotalRevenues() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;
            return (this.EstimatedWorkTotalRevenues() / cost - 1) * 100;
        });

        this.EstimatedWorkDiscountAvg = ko.computed(() => {
            const revenues: number = this.EstimatedWorkTotalRevenues();
            let revenuesWithoutDiscounts: number = this.EstimatedWorkTotalRevenuesWithoutDiscounts();

            if (revenuesWithoutDiscounts == 0 && revenues == 0) return 0;

            revenuesWithoutDiscounts = !revenuesWithoutDiscounts ? 1 : revenuesWithoutDiscounts;
            return (1 - revenues / revenuesWithoutDiscounts) * 100;
        });

        this.EstimatedPurchasesTotalCosts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedPurchasesTotalCosts() || 0));

            return tot;
        });

        this.EstimatedPurchasesTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedPurchasesTotalRevenues() || 0));

            return tot;
        });

        this.EstimatedPurchasesTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedPurchasesTotalRevenuesWithoutDiscounts() || 0));

            return tot;
        });

        this.EstimatedPurchasesMarkupAvg = ko.computed(() => {
            let cost: number = this.EstimatedPurchasesTotalCosts();

            if (this.EstimatedPurchasesTotalRevenuesWithoutDiscounts() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;
            return (this.EstimatedPurchasesTotalRevenuesWithoutDiscounts() / cost - 1) * 100;
        });

        this.EstimatedPurchasesRealMarkup = ko.computed(() => {
            let cost = this.EstimatedPurchasesTotalCosts();

            if (this.EstimatedPurchasesTotalRevenues() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;
            return (this.EstimatedPurchasesTotalRevenues() / cost - 1) * 100;
        });

        this.EstimatedPurchasesDiscountAvg = ko.computed(() => {
            const revenues: number = this.EstimatedPurchasesTotalRevenues();
            let revenuesWithoutDiscounts: number = this.EstimatedPurchasesTotalRevenuesWithoutDiscounts();

            if (revenuesWithoutDiscounts == 0 && revenues == 0) return 0;

            revenuesWithoutDiscounts = !revenuesWithoutDiscounts ? 1 : revenuesWithoutDiscounts;
            return (1 - revenues / revenuesWithoutDiscounts) * 100;
        });

        this.EstimatedArticlesTotalCosts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedArticlesTotalCosts() || 0));

            return tot;
        });

        this.EstimatedArticlesTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedArticlesTotalRevenues() || 0));

            return tot;
        });

        this.EstimatedArticlesTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.EstimatedArticlesTotalRevenuesWithoutDiscounts() || 0));

            return tot;
        });

        this.EstimatedArticlesMarkupAvg = ko.computed(() => {
            let cost: number = this.EstimatedArticlesTotalCosts();

            if (this.EstimatedArticlesTotalRevenuesWithoutDiscounts() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;
            return (this.EstimatedArticlesTotalRevenuesWithoutDiscounts() / cost - 1) * 100;
        });

        this.EstimatedArticlesRealMarkup = ko.computed(() => {
            let cost = this.EstimatedArticlesTotalCosts();

            if (this.EstimatedArticlesTotalRevenues() == 0 && cost == 0) return 0;

            cost = !cost ? 1 : cost;

            return (this.EstimatedArticlesTotalRevenues() / cost - 1) * 100;
        });

        this.EstimatedArticlesDiscountAvg = ko.computed(() => {
            const revenues: number = this.EstimatedArticlesTotalRevenues();
            let revenuesWithoutDiscounts: number = this.EstimatedArticlesTotalRevenuesWithoutDiscounts();

            if (revenuesWithoutDiscounts == 0 && revenues == 0) return 0;

            revenuesWithoutDiscounts = !revenuesWithoutDiscounts ? 1 : revenuesWithoutDiscounts;
            return (1 - revenues / revenuesWithoutDiscounts) * 100;
        });

        this.TotalCosts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.TotalCosts() || 0));

            return tot;
        });

        this.TotalRevenues = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.TotalRevenues() || 0));

            return tot;
        });

        this.TotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.TasksList().forEach((t) => (tot += t.TotalRevenuesWithoutDiscounts() || 0));

            return tot;
        });

        this.RealMarkup = ko.computed(() => {
            return this.TotalCosts() === 0 ? 0 : (this.TotalRevenues() / this.TotalCosts() - 1) * 100;
        });

        this.MarkupAvg = ko.computed(() => {
            return this.TotalCosts() === 0 ? 0 : (this.TotalRevenuesWithoutDiscounts() / this.TotalCosts() - 1) * 100;
        });

        this.DiscountAvg = ko.computed(() => {
            return this.TotalRevenuesWithoutDiscounts() === 0
                ? 0
                : (1 - this.TotalRevenues() / this.TotalRevenuesWithoutDiscounts()) * 100;
        });

        this.UserCharactersDetails = ko.computed(() => {
            const charactersMap = {};
            const resultList = [];

            this.TasksList().forEach((t) => {
                t.UserCharactersDetails().forEach((w) => {
                    const characterId = w.Id;
                    if (!characterId) return;

                    if (!charactersMap[characterId]) {
                        const character: IEstimatedBudgetDetails = $.extend(true, {}, w);

                        charactersMap[characterId] = character;
                        resultList.push(character);
                    } else {
                        const character = charactersMap[characterId];
                        this.updateEstimatedBudgetDetails(character, w);
                    }
                });
            });

            return resultList;
        });

        this.PurchasesTypesDetails = ko.computed(() => {
            const typesMap = {};
            const resultList = [];

            this.TasksList().forEach((t) => {
                t.PurchasesTypesDetails().forEach((p) => {
                    const typeId = p.Id;
                    if (!typeId) return;

                    if (!typesMap[typeId]) {
                        const type: IEstimatedBudgetDetails = $.extend(true, {}, p);

                        typesMap[typeId] = type;
                        resultList.push(type);
                    } else {
                        const type = typesMap[typeId];
                        this.updateEstimatedBudgetDetails(type, p);
                    }
                });
            });

            return resultList;
        });
    }

    public getExcelExportData() {
        return {
            jobOrderId: this.workflow.JobOrderId,
            workflows: [this.workflow.Id],
        };
    }

    public async applyNewMarkup(
        placement: "top" | "right" | "bottom" | "left",
        target: "all" | "EPC" | "EWK" | "WAR",
        entityId: number | ko.Observable<number>,
        initialValue: number,
        item: any,
        evt: MouseEvent
    ): Promise<void> {
        const val = ko.utils.unwrapObservable(initialValue);

        const popover = new MarkupOrDiscountDialog(
            val,
            ProlifeSdk.TextResources.JobOrder.CostsPlanningMarkupPopoverTitle
        );
        const markup = await popover.showPopOver(evt.currentTarget as HTMLElement, placement);

        setTimeout(() => {
            // HACK: impostato un timeout per impedire uno schianto in bootstrap alla chiusura del popover (probabilmente cerca di distruggere il popover quando è già stato distrutto per via dell'aggiornamento scatenato da knockout). Trovare una soluzione al problema.
            this.TasksList().forEach((t) => {
                if (target === "all" || target === ProlifeSdk.EstimatedWorkEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedWorkRows()
                        : t.EstimatedWorkRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.Markup(markup);
                    });
                }

                if (target === "all" || target === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedPurchasesRows()
                        : t.EstimatedPurchasesRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.Markup(markup);
                    });
                }

                if (target === "all" || target === ProlifeSdk.WarehouseArticleEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedArticlesRows()
                        : t.EstimatedArticlesRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.Markup(markup);
                    });
                }
            });
        }, 200);
    }

    public async applyNewDiscount(
        placement: "top" | "right" | "bottom" | "left",
        target: "all" | "EPC" | "EWK" | "WAR",
        entityId: number | ko.Observable<number>,
        initialValue: number,
        item: any,
        evt: MouseEvent
    ): Promise<void> {
        const val = ko.utils.unwrapObservable(initialValue);

        const popover = new MarkupOrDiscountDialog(
            val,
            ProlifeSdk.TextResources.JobOrder.CostsPlanningDiscountPopoverTitle
        );
        const discount = await popover.showPopOver(evt.currentTarget as HTMLElement, placement);

        const discountValue = numeral((discount || 0) / 100).format("0.[########]%");

        setTimeout(() => {
            // HACK: impostato un timeout per impedire uno schianto in bootstrap alla chiusura del popover (probabilmente cerca di distruggere il popover quando è già stato distrutto per via dell'aggiornamento scatenato da knockout). Trovare una soluzione al problema.
            this.TasksList().forEach((t) => {
                if (target === "all" || target === ProlifeSdk.EstimatedWorkEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedWorkRows()
                        : t.EstimatedWorkRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.DiscountValues(discountValue);
                    });
                }

                if (target === "all" || target === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedPurchasesRows()
                        : t.EstimatedPurchasesRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.DiscountValues(discountValue);
                    });
                }

                if (target === "all" || target === ProlifeSdk.WarehouseArticleEntityTypeCode) {
                    const rows = !entityId
                        ? t.EstimatedArticlesRows()
                        : t.EstimatedArticlesRows().filter((r) => r.EntityKeyId() === entityId);
                    rows.forEach((w) => {
                        w.DiscountValues(discountValue);
                    });
                }
            });
        }, 200);
    }

    public async resetActivitiesCostsAndPrices(): Promise<void> {
        const promises: Promise<any>[] = [];

        this.TasksList().forEach((t) => promises.push(t.resetEstimatesCostsAndPrices()));
        await Promise.all(promises);
    }

    public setActivitiesProgressMode(mode: number): void {
        this.filterByActivitiesProgressMode = mode;
    }

    public setShowClosedJobOrdersOnTasks(value: boolean) {
        this.showClosedJobOrdersOnTasks = value;
        this.TasksList().forEach((t) => t.SetShowClosedJobOrders(value));
    }

    public hasChanges(): boolean {
        return this.TasksList().filter((t: ActivityAmountViewModel) => t.SomeIsChanged()).length > 0;
    }

    public applyAmountToAll(): void {
        this.TasksList().forEach((t) => {
            if (t.CanEditTask()) {
                t.Multiplier(this.AmountForMassiveUpdate());
                t.MultiplierUnitOfMeasure(this.AmountUnitOfMeasureForMassiveUpdate());
            }
        });
    }

    public applyProportionalAmountToAll(): void {
        const ratio = this.AmountForMassiveUpdate() / (this.workflow.Multiplier || 1);
        this.TasksList().forEach((t) => {
            if (t.CanEditTask()) {
                t.Multiplier(t.Multiplier() * ratio);
                t.MultiplierUnitOfMeasure(this.AmountUnitOfMeasureForMassiveUpdate());
            }
        });
    }

    public applyTaskAmountToSubsequentTasks(startTask: ActivityAmountViewModel): void {
        const selectedTaskIndex: number = this.TasksList().indexOf(startTask);
        if (selectedTaskIndex < 0) return;

        for (let i = selectedTaskIndex; i < this.TasksList().length; i++) {
            if (this.TasksList()[i].CanEditTask()) {
                this.TasksList()[i].Multiplier(startTask.Multiplier());
                this.TasksList()[i].MultiplierUnitOfMeasure(startTask.MultiplierUnitOfMeasure());
            }
        }
    }

    public async save(): Promise<ITodoListWorkflow> {
        /*let externalChanges: ActivityAmountViewModel[] = this.TasksList().filter((t) => t.IsModifiedFromOtherUser());

        if (externalChanges.length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.CannotEditActivitiesMultipliers);
            return Promise.resolve<ITodoListWorkflow>(null);
        }*/

        const fatalErrors: string[] = [];
        const warnings: string[] = [];

        this.TasksList().forEach((t) => {
            if (t.ReportingType() == null || t.ReportingType() == undefined || isNaN(t.ReportingType()))
                fatalErrors.push(t.Title() + " - " + ProlifeSdk.TextResources.Todolist.SelectReportingType);

            if (t.IsMilestone() && !t.DueDate())
                warnings.push(t.Title() + " - " + ProlifeSdk.TextResources.Todolist.MilestoneWithoutEndDateWarning);
        });

        if (fatalErrors.length > 0) {
            const error: string = fatalErrors.join("<br/>");
            await this.dialogsService.AlertAsync(error, ProlifeSdk.TextResources.Todolist.SaveTasksErrorLabel);
            return null;
        }

        if (warnings.length > 0) {
            const warning: string = warnings.join("<br/>");
            const confirm = await this.dialogsService.ConfirmAsync(
                String.format(ProlifeSdk.TextResources.Todolist.SaveTasksWarningsMessage, warning),
                ProlifeSdk.TextResources.JobOrder.Cancel,
                ProlifeSdk.TextResources.JobOrder.Confirm
            );
            if (!confirm) return null;
        }

        let forceSaving = false;
        let ignoreNotEstimatedTasks = false;
        let shouldRetry = true;
        let saved = false;

        while (!saved && shouldRetry) {
            try {
                const response = await this.internalSave(forceSaving, ignoreNotEstimatedTasks);
                if (response.succeeded) {
                    saved = true;
                } else {
                    [forceSaving, ignoreNotEstimatedTasks, shouldRetry] = await this.handleErrors(response.errors);
                }
            } catch (e) {
                const err = e as ResponseBase;
                [forceSaving, ignoreNotEstimatedTasks, shouldRetry] = await this.handleErrors(err.errors);
            }
        }

        if (saved) {
            if (this.UpdateWorkflow()) {
                this.workflow.Multiplier = this.AmountForMassiveUpdate();
                this.workflow.MultiplierUoM = this.AmountUnitOfMeasureForMassiveUpdate();
                await this.todolistService.InsertOrUpdateWorkflow(this.workflow);
            }

            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.MultipliersUpdateSuccess);
            this.loadTasks();
        }

        return this.workflow;
    }

    private async internalSave(forceSaving = false, ignoreNotEstimatedTasks = false): Promise<ResponseBase> {
        const tasks: ITodoListTask[] = this.TasksList().map((t) => t.getData());
        return await this.todolistService.SaveActivitiesMultipliers(tasks, forceSaving, ignoreNotEstimatedTasks);
    }

    private async handleErrors(
        errors: ResponseError[],
        forceSaving = false,
        ignoreNotEstimatedTasks = false
    ): Promise<[boolean, boolean, boolean]> {
        const taskUpdatedByOthers = errors.some((e) => e.code == "TaskWasUpdatedByAnotherUser");
        if (taskUpdatedByOthers && !forceSaving) {
            const confirm = await this.ConfirmOverwriteOtherUsersChanges();
            return [confirm, ignoreNotEstimatedTasks, confirm];
        }

        const notEstimatedTasks = errors.some((e) => e.code == "MissingTaskWorkEstimation");
        if (notEstimatedTasks && !ignoreNotEstimatedTasks) {
            const confirm = await this.ConfirmSavingNotEstimatedTask();
            return [forceSaving, confirm, confirm];
        }

        const errorMessage = errors.map((e) => TextResources.Todolist[e.code]).join("<br/>");
        this.infoToastService.Error(errorMessage);

        return [false, false, false];
    }

    private async ConfirmOverwriteOtherUsersChanges(): Promise<boolean> {
        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Todolist.TaskExternalChangesAlert,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );

        return confirm;
    }

    private async ConfirmSavingNotEstimatedTask(): Promise<boolean> {
        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessage,
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessageCancel,
            TextResources.Todolist.IgnoreNotEstimatedTaskCheckMessageConfirm
        );

        return confirm;
    }

    public async manageWorkflowSnapshots(): Promise<void> {
        if (this.hasChanges()) {
            const confirm = await this.dialogsService.ConfirmAsync(
                TextResources.JobOrder.CostsPlanningPendingChangesOnWorkflowSnapshotEditingMessage,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );
            if (!confirm) return;
        }

        try {
            await this.todolistService.ShowEditWorkflowSnapshotsDialog(this.workflow.Id);
        } catch (e) {
            console.error(e);
        }

        this.loadTasks();
    }

    public setWorkflowAndReload(workflow: ITodoListWorkflow) {
        this.workflow = workflow;
        this.load();
    }

    public dispose(): void {
        this.changesNotificationsService.RemoveObserver(this);
    }

    public reloadTasks(): void {
        this.loadTasks();
    }

    public load(): Promise<ITodoListTask[]> {
        if (!this.workflow) this.NotFound(true);
        else {
            this.AmountForMassiveUpdate(this.workflow.Multiplier);
            this.AmountUnitOfMeasureForMassiveUpdate(this.workflow.MultiplierUoM);
            this.WorkflowTitle(this.workflow.Title);
        }

        return this.loadTasks();
    }

    public switchShowUserCharactersDetails(): void {
        this.ShowUserCharactersDetails(!this.ShowUserCharactersDetails());
    }

    public switchShowPurchasesTypesDetails(): void {
        this.ShowPurchasesTypesDetails(!this.ShowPurchasesTypesDetails());
    }

    public switchShowDetails(): void {
        this.ShowDetails(!this.ShowDetails());
    }

    public switchRowsDetailsEnabled(): void {
        this.RowsDetailsEnabled(!this.RowsDetailsEnabled());
    }

    private loadTasks(): Promise<ITodoListTask[]> {
        this.TasksList([]);

        return this.todolistService
            .GetTasks(
                [],
                [],
                [!this.workflow ? -1 : this.workflow.Id],
                null,
                -1,
                0,
                10000,
                this.filterByActivitiesProgressMode
            )
            .then((tasks: ITodoListTask[]) => {
                this.TasksList(tasks.filter((t) => t.TaskBoardStatus <= 3).map((t) => this.createActivityViewModel(t)));
                return tasks;
            });
    }

    private onSaveSuccessful(): void {
        this.loadTasks();
    }

    private createActivityViewModel(task: ITodoListTask): ActivityAmountViewModel {
        return new ActivityAmountViewModel(task, this, this.WarehouseArticlesDataSource);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean): Promise<boolean> {
        let workflowId: number = null;
        let taskId: number = null;

        if (changesInfo.EntityCode == ProlifeSdk.TaskBoardTaskEntityTypeCode) {
            taskId = changesInfo.EntityKeyId;
            workflowId = changesInfo.Object.WorkflowId;
        }

        //d.collantoni: Notifica disabilitata in data 23/05/2023
        /*if (changesInfo.EntityCode == ProlifeSdk.JobOrderTaskEntityTypeCode) {
            taskId = changesInfo.Object.Task.Id;
            workflowId = changesInfo.Object.Task.RelatedWorkflow || changesInfo.Object.Task.RelatedWorkFlow;
        }*/

        if (workflowId != this.workflow.Id) return false;

        const tasksMatch: ActivityAmountViewModel[] = this.TasksList().filter((t) => t.TaskId == taskId);

        tasksMatch.forEach((t) => t.IsModifiedFromOtherUser(true));
        return false;
    }

    public async canInsertOrUpdateTask(): Promise<boolean> {
        /*let externalChanges: ActivityAmountViewModel[] = this.TasksList().filter((t) => t.IsModifiedFromOtherUser());
        if (externalChanges.length > 0) {
            this.infoToastService.Warning(TextResources.JobOrder.ExternalChangesOnTasksAlert);
            return false;
        }*/

        if (!this.hasChanges()) return true;

        const saveAndContinue = await this.dialogsService.ConfirmAsync(
            TextResources.JobOrder.EditTasksOnCOstsPlanningAlertMessage,
            TextResources.JobOrder.EditTasksOnCOstsPlanningAlertCancel,
            TextResources.JobOrder.EditTasksOnCOstsPlanningAlertConfirm
        );
        if (!saveAndContinue) return false;

        await this.save();
        return true;
    }

    public async createNewTask(): Promise<void> {
        // TODO questo e i due metodi che seguono sono praticamente uguali, fare refactoring
        if (!this.workflow) {
            this.infoToastService.Error(TextResources.JobOrder.SelectWorkflow);
            return;
        }

        const canContinue = await this.canInsertOrUpdateTask();
        if (!canContinue) return;

        const someTasksSaved = await this.todolistService.ShowCreateNewTaskDialog(
            this.workflow.JobOrderId,
            this.workflow.Id,
            this.getTaskDialogOptions()
        );
        if (someTasksSaved) this.reloadTasks();
    }

    public async createNewTaskFromTask(): Promise<void> {
        if (!this.workflow) {
            this.infoToastService.Error(TextResources.JobOrder.SelectWorkflow);
            return;
        }

        const canContinue = await this.canInsertOrUpdateTask();
        if (!canContinue) return;

        const someTasksSaved = await this.todolistService.ShowCopyTaskDialog(
            this.workflow.Id,
            this.workflow.JobOrderId,
            this.getTaskDialogOptions()
        );
        if (someTasksSaved) this.reloadTasks();
    }

    public async createNewTaskFromTemplate(): Promise<void> {
        if (!this.workflow) {
            this.infoToastService.Error(TextResources.JobOrder.SelectWorkflow);
            return;
        }

        const canContinue = await this.canInsertOrUpdateTask();
        if (!canContinue) return;

        const someTasksSaved = await this.todolistService.ShowCreateTaskFromTemplateDialog(
            this.workflow.Id,
            this.workflow.JobOrderId,
            this.getTaskDialogOptions()
        );

        if (someTasksSaved) this.reloadTasks();
    }

    private getTaskDialogOptions(): ITaskDialogOptions {
        const options: ITaskDialogOptions = {
            initialViewAll: true,
            showClosedJobOrders: this.showClosedJobOrdersOnTasks,
        };

        return options;
    }

    private createEstimatedBudgetDetails(estimatedBudgetRow: EstimateBudgetRow): IEstimatedBudgetDetails {
        const type: IEstimatedBudgetDetails = {
            Id: estimatedBudgetRow.EntityKeyId(),
            Description:
                estimatedBudgetRow.row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode
                    ? estimatedBudgetRow.RoleName()
                    : estimatedBudgetRow.PurchaseTypeTitle(),
            Cost: estimatedBudgetRow.Cost(),
            Price: estimatedBudgetRow.Price(),
            RealMarkup: estimatedBudgetRow.Markup(),
            PriceWithoutDiscounts: estimatedBudgetRow.UnitPrice() * estimatedBudgetRow.Amount(),
            DiscountAvg: 0,
            MarkupAvg: 0,
        };

        return type;
    }

    private updateEstimatedBudgetDetails(target: IEstimatedBudgetDetails, source: IEstimatedBudgetDetails): void {
        target.Cost += source.Cost;
        target.Price += source.Price;
        target.PriceWithoutDiscounts += source.PriceWithoutDiscounts;

        target.RealMarkup = (target.Price / (!target.Cost ? 1 : target.Cost) - 1) * 100;
        target.MarkupAvg = (target.PriceWithoutDiscounts / (!target.Cost ? 1 : target.Cost) - 1) * 100;
        target.DiscountAvg =
            (1 - target.Price / (!target.PriceWithoutDiscounts ? 1 : target.PriceWithoutDiscounts)) * 100;
    }
}

class ActivityAmountViewModel extends TodoListTask {
    public TaskId: number;

    public IsModifiedFromOtherUser: ko.Observable<boolean> = ko.observable(false);

    public IconHelper: ITaskStatusIconHelper;

    public WorkedAmount: ko.Computed<number>;
    public CanEditTask: ko.Computed<boolean>;

    private OriginalMultiplier: ko.Observable<number> = ko.observable();
    private OriginalProgress: ko.Observable<number> = ko.observable();

    private CachedWarehouseArticlesDataSource: IDataSource<number, IArticle>;

    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todolistService: ITodoListService;

    constructor(
        public task: ITodoListTask,
        private editor: WorkflowWithActivitiesViewModel,
        externalDataSource: IDataSource<number, IArticle> = null
    ) {
        super(null, task, null, 0, false, false);

        this.CachedWarehouseArticlesDataSource =
            externalDataSource ?? new CachedDataSource(new WarehouseArticlesDataSource());

        this.IconHelper = this.todolistService.GetTaskStatusIconHelper();

        this.TaskId = this.task.Id;

        this.OriginalProgress(this.task.ProgressAvg);
        this.OriginalMultiplier(this.task.Multiplier);

        this.IconHelper.TaskBoardStatus(this.task.TaskBoardStatus);

        this.CanEditTask = ko.computed(() => {
            return this.StateFolder() && this.StateFolder().Id < 2;
        });

        this.WorkedAmount = ko.computed({
            read: () => {
                return this.prolifeSdkService.Utilities.Numbers.Round(
                    (this.OriginalProgress() / 100) * this.OriginalMultiplier(),
                    3
                );
            },
            write: (value) => {},
        });

        this.Multiplier.subscribe((newValue: number) => {
            if (newValue == 0) {
                this.Multiplier(this.WorkedAmount());
                return;
            }

            this.ProgressAvg((this.WorkedAmount() / newValue) * 100);
        });

        this.ShowTotals(false);

        this.LoadFromModel(task);
    }

    protected createEstimatedBudgetRow(row: IEstimateBudgetRow) {
        const ebr = new EstimateBudgetRow(row, this.JobOrdersTagsManager.JobOrderId, this, true);
        ebr.WarehouseArticlesDataSource = new ProxyDataSource(this.CachedWarehouseArticlesDataSource);
        ebr.Load();
        return ebr;
    }

    public async openTaskEditor(): Promise<boolean> {
        const canContinue = await this.editor.canInsertOrUpdateTask();
        if (!canContinue) return false;

        const someTaskSaved = await this.todolistService.ShowEditTaskDialog(this.TaskId, {
            initialViewAll: true,
            showClosedJobOrders: this.ShowClosedJobOrders(),
        });
        if (someTaskSaved) await this.editor.reloadTasks();

        return true;
    }

    public getData(): ITodoListTask {
        return super.GetData();
    }

    public applyTaskAmountToSubsequentTasks(): void {
        this.editor.applyTaskAmountToSubsequentTasks(this);
    }
}

class MarkupOrDiscountDialog implements IDialog {
    public templateName = "costs-planning-markup-dialog";
    public templateUrl = "joborder/templates/dialogs";
    public title = "";

    public modal: { close: (result?: any) => void };

    public MarkupOrDiscountValue: ko.Observable<number> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(initialMarkup = 0, title) {
        this.title = title;
        this.MarkupOrDiscountValue(initialMarkup);
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        this.modal.close(this.MarkupOrDiscountValue() || 0);
    }

    public showPopOver(element: HTMLElement, placement: "left" | "right" | "bottom" | "top"): Promise<number> {
        return this.dialogsService.ShowPopover(element, this, placement);
    }
}
