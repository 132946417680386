import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as numeral from "numeral";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { IWorkflowsDataSourceModel } from "./WorkflowsDataSource";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import {
    ITodoListService,
    IWorkflowForTaskBoard,
    IGetCartWorkflowsForResourceRequest,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IWorkflowStatesSettingsManager,
    IWorkflowState,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { IDialogsService } from "../Core/interfaces/IDialogsService";

export class CartsWorkflowsDataSource implements IDataSource {
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService!: ITodoListService;
    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStateSettingsManager!: IWorkflowStatesSettingsManager;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private cartId: number = null;
    private userId: number = null;
    private categoryFilter = -1;
    private viewWorker = true;
    private viewResponsible = true;
    private viewAll = false;
    private showJobOrderName = false;

    private lastTextFilter: string = null;
    private invalidateCache = true;
    private workflowsCache: IWorkflowForTaskBoard[] = [];
    private view: IDataSourceView = null;

    public setCartId(cartId: number): void {
        this.cartId = cartId;
        this.invalidateCache = true;
    }

    public setUserId(userId: number): void {
        this.userId = userId;
        this.invalidateCache = true;
    }

    public setViewFilters(viewWorker: boolean, viewResponsible: boolean, viewAll: boolean) {
        this.viewWorker = viewWorker;
        this.viewResponsible = viewResponsible;
        this.viewAll = viewAll;
        this.invalidateCache = true;
    }

    public setCategoryFilter(categoryId: number): void {
        this.categoryFilter = categoryId;
        this.invalidateCache = true;
    }

    public setShowJobOrderName(value: boolean): void {
        this.showJobOrderName = value;
    }

    public refresh() {
        if (!this.view) return;

        this.workflowsCache = [];
        this.invalidateCache = true;
        this.view.refresh(true);
    }

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return "Piani di lavoro";
    }

    isGroupedData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string
    ): boolean {
        return currentModel === null;
    }

    areEqual(
        a: IDataSourceModel<string | number, any, string | number, any>,
        b: IDataSourceModel<string | number, any, string | number, any>
    ): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    async getData(
        currentModel: IWorkflowsDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        if (!this.cartId) return [];

        while (this.invalidateCache) {
            this.invalidateCache = false;
            const request: IGetCartWorkflowsForResourceRequest = {
                userId: this.userId,
                cartId: this.cartId,
                viewWorker: this.viewWorker,
                viewResponsible: this.viewResponsible,
                categoryId: this.categoryFilter,
                viewAll: this.viewAll,
                hideNoWork: false,
            };

            this.workflowsCache = await this.todoListService.GetCartWorkflowsForResource(request);
        }

        if (currentModel === null) {
            const states = this.workflowStateSettingsManager.getStates(false);

            const usedStates = states.map((s) => {
                return {
                    state: s,
                    count: this.workflowsCache.filter((w) => w.StateId == s.Id).length,
                };
            });

            return this.createModelsForStates(usedStates).slice(skip, skip + count);
        } else if (currentModel.isWorkflowGroup) {
            const workflowsInGroup = this.workflowsCache.filter((w) => w.StateId == currentModel.id);

            const splittedTextFilter = (textFilter || "")
                .toLowerCase()
                .split(" ")
                .filter((s) => s !== "");
            const filteredWorkflows = workflowsInGroup
                .filter(
                    (w) =>
                        splittedTextFilter.length == 0 ||
                        splittedTextFilter.filter((t) => w.Title.toLowerCase().indexOf(t) != -1).length ===
                            splittedTextFilter.length
                )
                .slice(skip, skip + count);

            return this.createModelsForWorkflows(filteredWorkflows);
        }

        return [];
    }

    async getById(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        ids: (string | number)[]
    ): Promise<IDataSourceModel<string | number, any, string | number, any>[]> {
        return [];
    }

    setView(view: IDataSourceView): void {
        this.view = view;
        this.refresh();
    }

    public getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-task", "application/prolife-tasks"];
    }

    public onItemBeginMove(model: IDataSourceModel, dataTransfer: DataTransfer) {}

    async onItemMoved(dataTransfer: DataTransfer, model: IWorkflowsDataSourceModel, before: boolean): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0 && model) {
            const tasks: IDraggedTask[] = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
            this.moveTasks(tasks, model);
        } else if (dataTransfer.types.indexOf("application/prolife-task") >= 0 && model) {
            const task: IDraggedTask = JSON.parse(dataTransfer.getData("application/prolife-task"));
            this.moveTasks([task], model);
        }
    }

    private moveTasks(tasks: IDraggedTask[], destinationWorkflow: IWorkflowsDataSourceModel) {
        const task = tasks[0];

        if (task.CompanyGuid != this.userInfo.getCurrentCompanyGuid()) return;

        if (task.WorkflowId == destinationWorkflow.id) return;

        if (task.JobOrderId != destinationWorkflow.model.JobOrderId) return;

        this.todoListService.MoveTasksToWorkflow(
            tasks.map((t) => t.TaskId),
            destinationWorkflow.id
        );
    }

    private createModelsForStates(
        statesWithCount: { state: IWorkflowState; count: number }[]
    ): IWorkflowsDataSourceModel[] {
        const workStatusUsed: boolean =
            statesWithCount.filter((s) => s.state.LogicalStatus === 1 && s.count > 0).length > 0;

        return statesWithCount
            .filter((s) => s.count > 0)
            .map((s) => {
                return {
                    id: s.state.Id,
                    isGroup: true,
                    isLeaf: false,
                    title: s.state.Description + " (" + s.count + ")",
                    collapsed: workStatusUsed ? s.state.LogicalStatus != 1 : s.state.LogicalStatus != 0,
                    isWorkflowGroup: true,
                    isWorkflow: false,
                    model: null,
                };
            });
    }

    private createModelsForWorkflows(workflowsInGroup: IWorkflowForTaskBoard[]): IWorkflowsDataSourceModel[] {
        return workflowsInGroup.map((w) => {
            const workflow: IWorkflowsDataSourceModel = {
                id: w.Id,
                isGroup: false,
                isLeaf: true,
                title: w.Title,
                isWorkflowGroup: false,
                isWorkflow: true,
                icon: {
                    icon: w.Icon,
                    background: w.Background,
                    foreground: w.Foreground,
                },
                progressBar: {
                    progress: ((w.TasksCount - w.NotCompleteTasksCount) / w.TasksCount) * 100,
                },
                subTitle:
                    (this.showJobOrderName ? w.JobOrderName + "<br/><br/>" : "") +
                    ProlifeSdk.TextResources.Todolist.ClosedTasks +
                    ": " +
                    (w.TasksCount - w.NotCompleteTasksCount) +
                    "/" +
                    w.TasksCount,
                model: w,
                secondaryAction: {
                    icon: {
                        icon: "fa fa-pencil",
                        background: "#ecbc29",
                        foreground: "white",
                    },
                    action: () => {
                        this.todoListService.ShowEditWorkflowDialog(w.Id).then((result) => {
                            if (!result) return;
                            this.refresh();
                        });
                    },
                },
                alerts: {
                    label: ProlifeSdk.TextResources.Todolist.Alerts,
                    icons: WorkflowsUtils.getAlertsForWorkflow(w),
                },
            };

            workflow.badge = [];

            if ((w.AllocationPercentage || 0) > 0 || w.HasNotEstimatedElementsAllocated) {
                workflow.badge.push(WorkflowsUtils.getAllocationBadge(w, this.dialogsService));
            }

            if (w.RelatedDocuments > 0 || w.WorkflowMustBeRelatedToCustomerOrders) {
                workflow.badge.push(WorkflowsUtils.getRelatedDocumentsBadge(w));
            }

            return workflow;
        });
    }
}
