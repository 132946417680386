import * as ko from "knockout";
declare var CKEDITOR;

export class HtmlEditor
{
    init(element: HTMLTextAreaElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        let toolbarConfigurations = {
            supportedByReportsTools : [
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'BlockQuote' ] },
                { name: 'lists', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'font', items: [ 'Styles', 'Font', 'FontSize', 'TextColor' ] }
            ]
        }

        let toolbarConfigKey : string = allBindingsAccessor()["htmlEditorToolbarConfig"];
        let options = allBindingsAccessor()["htmlEditorOptions"] || { language : 'it', resize_dir : 'vertical', scayt_sLang: 'it_IT', height : 100 };
        let readonly = allBindingsAccessor()["readonly"];
        let firstLoad = true;
        
        if (toolbarConfigKey)
            options.toolbar = toolbarConfigurations[toolbarConfigKey];

        CKEDITOR.env.isCompatible = true;

        (<any>$(element)).ckeditor(options);

        let editor = (<any>$(element)).ckeditorGet();
        editor.setData(valueAccessor()());

        /*if(options.fitParent) {
            const observer = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if(entry.contentBoxSize) {
                        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
                        const contentBoxSize : ResizeObserverSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                        
                        editor.resize("100%", contentBoxSize.blockSize);
                    } else {
                        editor.resize("100%", entry.contentRect.height);
                    }
                  }
            });

            $(element).parents()
            observer.observe(element.parentElement);
        }*/

        let subscriptions = [];

        let valueUpdate = ko.utils.unwrapObservable(allBindingsAccessor()["valueUpdate"]);
        if(!valueUpdate) {
            editor.on("change", function(){
                if (firstLoad) {
                    firstLoad = false;
                    return;
                }
                valueAccessor()(editor.getData());
            });
        } else if(valueUpdate == "blur") {
            editor.on("blur", () => {
                valueAccessor()(editor.getData());
            });
        }

        if (allBindingsAccessor().htmlEditorTrigger) {
            let sub = allBindingsAccessor().htmlEditorTrigger.subscribe(() => {
                editor.setData(valueAccessor()());
            });

            subscriptions.push(sub);
        }

        editor.on('instanceReady', function(ev) {
            if (readonly) {
                let initialValue = ko.utils.unwrapObservable(readonly);
                editor.setReadOnly(initialValue);
    
                if (ko.isSubscribable(readonly))
                    subscriptions.push(readonly.subscribe((val: boolean) => { editor.setReadOnly(val); }));
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function ()
        {
            try
            {
                subscriptions.forEach((s) => s.dispose());
                editor.destroy(true);
            }
            catch
            {
                
            }
        });
    }

    /*update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void
    {
        var editor = (<any>$(element)).ckeditorGet();
        editor.setData(valueAccessor()());
    }*/
}

ko.bindingHandlers["htmlEditor"] = new HtmlEditor();