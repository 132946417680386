import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { Attachment } from "../ProlifeSdk/prolifesdk/blog/Attachment";
import { LazyImport } from "../Core/DependencyInjection";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import {
    IFileRepositoryService,
    IFileUploadResult,
    IAttachmentUploadStatus,
} from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../ProlifeSdk/interfaces/files/IFileOrFolder";
import { IPortletProps, Portlet } from "./Portlet";
import { If, IfNot } from "./IfIfNotWith";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import TsxForEach from "./ForEach";

const styleSheet = jss.createStyleSheet({
    attachmentsManager: {
        "& .listview": {
            minHeight: "80px",
            padding: "10px 0",

            "&.drop-disabled": {
                cursor: "not-allowed",
            },

            "& .empty-list": {
                margin: "0",
                paddingTop: "20px",
                pointerEvents: "none",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type AttachmentsManagerProps = {
    attachments?: ko.ObservableArray<Attachment>;
    lastVersionAttachments?: ko.ObservableArray<Attachment>;
    basePath?: ko.MaybeSubscribable;
    collapsible?: boolean;
    className?: string;
    lightPortlet?: boolean;
    readOnly?: ko.Subscribable<boolean>;
    disableFileRepositoryModal?: boolean;
    emptyListText?: ko.MaybeSubscribable;

    groupActions?: boolean;
    enableTemplates?: boolean;

    jobOrderId?: number;

    onAttachmentsDownload?: () => void;
    onAttachmentsRemoved?: () => void;
    onAttachmentsRemove?: () => void;
    onAttachmentsAdded?: () => void;
    onAttachmentsAdd?: () => void;
};

export function AttachmentsManager(props: AttachmentsManagerProps) {
    const C = require("./AttachmentsManager")._AttachmentsManager as typeof _AttachmentsManager;
    return <C {...props} />;
}

export class _AttachmentsManager {
    static defaultProps: Partial<AttachmentsManagerProps> = {
        collapsible: false,
        className: "",
        lightPortlet: false,
        groupActions: false,
        enableTemplates: false,
        disableFileRepositoryModal: false,
        emptyListText: TextResources.ProlifeSdk.EmptyAttachmentsList,
    };

    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private fileRepositoryService: IFileRepositoryService;

    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public Attachments: ko.ObservableArray<Attachment>; //Allegati ad una specifica versione
    public LastVersionAttachments: ko.ObservableArray<Attachment> = ko.observableArray([]); //Allegati che sono sempre aggiornati all'ultima versione
    public ShowAttachmentsActions: ko.Observable<boolean> = ko.observable(false);

    public NumberOfAttachments: ko.Computed<number>;
    public IsSelectedAttachments: ko.Computed<boolean>;

    public DropDisabled: ko.Computed<boolean>;

    private SelectedAttachments: ko.Computed<Attachment[]>;
    private ShowAttachmentsAsList: ko.Observable<boolean> = ko.observable(false);

    private portletVM: ko.Observable<Portlet> = ko.observable();

    constructor(private props: AttachmentsManagerProps) {
        this.initializeDefaultObservableProps();

        this.Attachments = this.props.attachments;

        this.SelectedAttachments = ko.computed(() => {
            const attachments: Attachment[] = this.Attachments().filter((a: Attachment) => a.selected());
            const lastVersionAttachments: Attachment[] = this.LastVersionAttachments().filter((a: Attachment) =>
                a.selected()
            );
            return attachments.concat(lastVersionAttachments);
        });

        this.NumberOfAttachments = ko.computed(() => {
            return this.Attachments().length + this.LastVersionAttachments().length;
        });

        this.IsSelectedAttachments = ko.computed(() => {
            return this.SelectedAttachments().length > 0;
        });

        this.DropDisabled = ko.computed(() => {
            return this.props.readOnly();
        });
    }

    private initializeDefaultObservableProps() {
        if (!this.props.attachments) this.props.attachments = ko.observableArray([]);

        if (!this.props.lastVersionAttachments) this.props.lastVersionAttachments = ko.observableArray([]);

        if (!this.props.basePath) this.props.basePath = ko.observable();

        if (!this.props.readOnly) this.props.readOnly = ko.observable(false);
    }

    public async uploadFile(file: File): Promise<void> {
        const status: IAttachmentUploadStatus = await this.fileRepositoryService.checkStatusForTaskAttachmentUpload(
            this.props.jobOrderId,
            file.name
        );
        if (status.AvailableSpace < file.size) {
            this.infoToastService.Error(
                String.format(ProlifeSdk.TextResources.FileRepository.FileNotUploadForInsufficientSpace, file.name)
            );
            return;
        }

        let confirm = true;
        if (status.AlreadyExists)
            confirm = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.FileRepository.UploadOverwriteMsg,
                ProlifeSdk.TextResources.FileRepository.CancelUpload,
                ProlifeSdk.TextResources.FileRepository.UploadAsNewVersion
            );

        if (!confirm) return;

        const path = (this.getBasePath() ?? status.PathForUpload).replace(/\\/g, "/");
        const uploadResult = await this.uploadFileAfterChecks(file, path);
        const uploadedFileInfo: IFileOrFolder = await this.fileRepositoryService.getFileDetails(uploadResult.FileId);
        this.addAttachments([uploadedFileInfo]);
    }

    private async uploadFileAfterChecks(file: File, path: string): Promise<IFileUploadResult> {
        try {
            const uploadResult = await this.fileRepositoryService.upload(path, file, file.name);
            this.infoToastService.Success(
                String.format(ProlifeSdk.TextResources.FileRepository.UploadSuccess, file.name)
            );
            return uploadResult;
        } catch (e) {
            this.infoToastService.Error(String.format(ProlifeSdk.TextResources.FileRepository.UploadError, file.name));
        }

        return null;
    }

    public async loadFilesThumbnails(): Promise<IFileOrFolder[]> {
        const attachmentsIds: string[] = this.Attachments().map((a: Attachment) => {
            return a.id;
        });
        const lastVersionAttachmentsIds: string[] = this.LastVersionAttachments().map((a: Attachment) => {
            return a.id;
        });

        try {
            const files: IFileOrFolder[] = await this.fileRepositoryService.getFilesDetails(
                attachmentsIds.concat(lastVersionAttachmentsIds)
            );

            const allAttachments = this.Attachments().concat(this.LastVersionAttachments());
            for (const attachment of allAttachments) {
                const matches: IFileOrFolder[] = files.filter(
                    (file: IFileOrFolder) => file != null && file.Id == attachment.id
                );
                if (matches.length > 0) attachment.loadFromFile(matches[0]);
            }

            return files;
        } catch (e) {
            console.error(e);
        }

        return [];
    }

    public async selectAndAddAttachments(): Promise<void> {
        if (this.props.onAttachmentsAdd) this.props.onAttachmentsAdd();

        const destinationPath: string = await this.prepareDestinationFolder();
        const filesToAdd: IFileOrFolder[] = await this.fileRepositoryService.openAsDialog(destinationPath);
        this.addAttachments(filesToAdd);

        if (this.props.onAttachmentsAdded) this.props.onAttachmentsAdded();
    }

    private addAttachments(filesOrFolders: IFileOrFolder[]) {
        const alreadyPresentAttachmentsIds = this.Attachments().map((a: Attachment) => {
            return a.id;
        });
        const newAttachments = filesOrFolders
            .filter((f: IFileOrFolder) => {
                return alreadyPresentAttachmentsIds.indexOf(f.Id) < 0;
            })
            .map((file: IFileOrFolder) => {
                return new Attachment().loadFromFile(file);
            });
        newAttachments.forEach((a: Attachment) => {
            this.Attachments.push(a);
        });
    }

    async selectAndAddLastVersionAttachments(): Promise<void> {
        if (this.props.onAttachmentsAdd) this.props.onAttachmentsAdd();

        const destinationPath: string = await this.prepareDestinationFolder();
        const filesToAdd: IFileOrFolder[] = await this.fileRepositoryService.openAsDialog(destinationPath);
        const alreadyPresentAttachmentsIds = this.LastVersionAttachments().map((a: Attachment) => {
            return a.id;
        });
        const newAttachments = filesToAdd
            .filter((f: IFileOrFolder) => {
                return alreadyPresentAttachmentsIds.indexOf(f.Id) < 0;
            })
            .map((file: IFileOrFolder) => {
                return new Attachment().loadFromFile(file);
            });
        newAttachments.forEach((a: Attachment) => {
            this.LastVersionAttachments.push(a);
        });

        if (this.props.onAttachmentsAdded) this.props.onAttachmentsAdded();
    }

    private async prepareDestinationFolder(): Promise<string> {
        const status: IAttachmentUploadStatus = await this.fileRepositoryService.checkStatusForTaskAttachmentUpload(
            this.props.jobOrderId,
            ""
        );
        const destinationPath = (this.getBasePath() ?? status.PathForUpload).replace(/\\/g, "/");

        const directoryExists = await this.fileRepositoryService.ExistsDirectory(destinationPath);
        if (!directoryExists) await this.fileRepositoryService.CreateDirectory(destinationPath);

        return destinationPath;
    }

    private getBasePath(): string {
        return ko.isSubscribable(this.props.basePath) ? this.props.basePath() : this.props.basePath;
    }

    public downloadAttachments() {
        if (this.props.onAttachmentsDownload) this.props.onAttachmentsDownload();

        const fileToDownload: Attachment = this.SelectedAttachments()[0];

        if (this.Attachments().indexOf(fileToDownload) > -1) fileToDownload.download();
        else fileToDownload.downloadLastVersion();
    }

    public removeSelectedAttachments() {
        if (this.props.onAttachmentsRemove) this.props.onAttachmentsRemove();

        this.SelectedAttachments().forEach((a: Attachment) => {
            if (this.Attachments().indexOf(a) > -1) this.Attachments.remove(a);
            else if (this.LastVersionAttachments().indexOf(a) > -1) this.LastVersionAttachments.remove(a);
        });

        if (this.props.onAttachmentsRemoved) this.props.onAttachmentsRemoved();
    }

    public switchAttachmentsView() {
        this.ShowAttachmentsAsList(!this.ShowAttachmentsAsList());
    }

    public switchAttachmentsActionsState() {
        this.ShowAttachmentsActions(!this.ShowAttachmentsActions());
    }

    public render() {
        const attachmentsManager = this;

        const classNames = ComponentUtils.classNames(classes.attachmentsManager, this.props.className);

        const portletProps: IPortletProps = {
            noBorder: !this.props.lightPortlet,
            collapsible: this.props.collapsible,
            initialCollapsed: false,
            className: ComponentUtils.classNames({
                light: this.props.lightPortlet,
                box: !this.props.lightPortlet,
                blue: !this.props.lightPortlet,
            }),
        };

        return ComponentUtils.bindTo(
            <div className={classNames}>
                <Portlet {...portletProps} forwardRef={(portlet) => this.portletVM(portlet)}>
                    <Portlet.Header>
                        <Portlet.Header.Default title={TextResources.ProlifeSdk.Attachments} />
                    </Portlet.Header>
                    <Portlet.Actions>
                        <If
                            condition={() =>
                                this.portletVM() &&
                                !this.portletVM().Collapsed() &&
                                (!this.props.readOnly() ||
                                    this.Attachments().length > 0 ||
                                    this.LastVersionAttachments().length > 0)
                            }>
                            {() => this.renderActions()}
                        </If>
                    </Portlet.Actions>
                    <Portlet.Body>
                        {() => (
                            <div
                                className="listview"
                                data-bind={{
                                    fileDrop: attachmentsManager.uploadFile.bind(attachmentsManager),
                                    dropDisabled: attachmentsManager.DropDisabled,
                                    css: { "drop-disabled": attachmentsManager.DropDisabled },
                                }}>
                                <h4
                                    className="empty-list text-center"
                                    data-bind={{
                                        text: attachmentsManager.props.emptyListText,
                                        visible:
                                            attachmentsManager.Attachments().length === 0 &&
                                            attachmentsManager.LastVersionAttachments().length === 0,
                                    }}></h4>
                                <TsxForEach data={attachmentsManager.Attachments} as="attachment">
                                    {(attachment) => this.renderAttachment(attachment)}
                                </TsxForEach>
                                <TsxForEach data={attachmentsManager.LastVersionAttachments} as="attachment">
                                    {(attachment) => this.renderAttachment(attachment, true)}
                                </TsxForEach>
                            </div>
                        )}
                    </Portlet.Body>
                </Portlet>
            </div>,
            this,
            "attachmentsManager"
        );
    }

    private renderActions(): React.ReactElement {
        if (this.props.groupActions) return this.renderGroupedActions();

        return this.renderInlineActions();
    }

    private renderInlineActions(): React.ReactElement {
        const attachmentsManager = this;

        return (
            <>
                {!this.props.disableFileRepositoryModal && (
                    <IfNot condition={this.props.readOnly}>
                        {() => (
                            <>
                                <button
                                    className="btn btn-sm default"
                                    data-bind={{
                                        click: attachmentsManager.selectAndAddAttachments.bind(attachmentsManager),
                                    }}>
                                    <i className="fa fa-plus"></i>&nbsp;{TextResources.ProlifeSdk.NewAttachmentButton}
                                </button>
                                <button
                                    className="btn default btn-sm"
                                    data-bind={{
                                        visible: attachmentsManager.SelectedAttachments().length > 0,
                                        click: attachmentsManager.removeSelectedAttachments.bind(attachmentsManager),
                                    }}>
                                    <i className="fa fa-trash-o"></i>&nbsp;{TextResources.ProlifeSdk.Delete}
                                </button>
                            </>
                        )}
                    </IfNot>
                )}
                <button
                    className="btn default btn-sm"
                    data-bind={{
                        visible: attachmentsManager.SelectedAttachments().length == 1,
                        click: attachmentsManager.downloadAttachments.bind(attachmentsManager),
                    }}>
                    <i className="fa fa-download"></i>&nbsp;{TextResources.ProlifeSdk.Download}
                </button>
            </>
        );
    }

    private renderGroupedActions(): React.ReactElement {
        const attachmentsManager = this;

        return (
            <div class="btn-group">
                <button
                    type="button"
                    className="btn btn-default dropdown-toggle btn-fit-height"
                    data-toggle="dropdown"
                    data-close-others="true"
                    data-delay="1000"
                    data-bind={{
                        click: attachmentsManager.switchAttachmentsActionsState.bind(attachmentsManager),
                        clickBubble: false,
                    }}>
                    {TextResources.ProlifeSdk.Actions}&nbsp;<i className="fa fa-angle-down"></i>
                </button>
                <ul
                    className="dropdown-menu pull-right"
                    role="menu"
                    data-bind={{ style: { display: attachmentsManager.ShowAttachmentsActions() ? "block" : "none" } }}>
                    <IfNot condition={this.props.readOnly}>
                        {() => (
                            <>
                                {!this.props.disableFileRepositoryModal && (
                                    <li>
                                        <a
                                            href="javascript:void(0);"
                                            data-bind={{
                                                click: attachmentsManager.selectAndAddAttachments.bind(
                                                    attachmentsManager
                                                ),
                                                clickBubble: false,
                                            }}>
                                            <i className="fa fa-paperclip"></i>&nbsp;
                                            {TextResources.ProlifeSdk.AttachDocument}
                                        </a>
                                    </li>
                                )}
                                {!this.props.disableFileRepositoryModal && this.props.enableTemplates && (
                                    <li>
                                        <a
                                            href="javascript:void(0);"
                                            data-bind={{
                                                click: attachmentsManager.selectAndAddLastVersionAttachments.bind(
                                                    attachmentsManager
                                                ),
                                                clickBubble: false,
                                            }}>
                                            <i className="fa fa-file-code-o"></i>&nbsp;
                                            {TextResources.ProlifeSdk.AttachTemplate}
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <a
                                        href="javascript:void(0);"
                                        data-bind={{
                                            click: attachmentsManager.removeSelectedAttachments.bind(
                                                attachmentsManager
                                            ),
                                            clickBubble: false,
                                            visible: attachmentsManager.SelectedAttachments().length > 0,
                                        }}>
                                        <i className="fa fa-trash-o"></i>&nbsp;
                                        {TextResources.ProlifeSdk.DeleteSelectedAttachments}
                                    </a>
                                </li>
                            </>
                        )}
                    </IfNot>
                    <li>
                        <a
                            href="javascript:void(0);"
                            data-bind={{
                                click: attachmentsManager.downloadAttachments.bind(attachmentsManager),
                                clickBubble: false,
                                visible: attachmentsManager.SelectedAttachments().length === 1,
                            }}>
                            <i className="fa fa-download"></i>&nbsp;{TextResources.ProlifeSdk.DownloadAttachment}
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    private renderAttachment(attachment: Attachment, template = false) {
        return (
            <a
                href="javascript:void(0);"
                className="list multi-select"
                data-bind={{
                    click: attachment.switchSelection.bind(attachment),
                    css: { selected: attachment.selected },
                    dblClickEx: attachment.downloadVersion.bind(attachment),
                }}>
                <div className="list-content">
                    <div
                        className="icon file"
                        data-bind={{ css: attachment.previewClasses, attr: { style: attachment.previewStyle } }}></div>
                    <div className="data">
                        <span className="list-title" data-bind={{ text: attachment.fileName }}></span>
                        <span className="list-remark" data-bind={{ visible: !!attachment.fileVersion }}>
                            {template
                                ? TextResources.ProlifeSdk.AttachmentTemplate
                                : TextResources.ProlifeSdk.AttachmentVersion}
                            &nbsp;<span data-bind={{ text: attachment.fileVersion() }}></span>
                        </span>
                    </div>
                </div>
            </a>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AttachmentsManager);
}
