import * as ko from "knockout";

type RefSetter = ko.Observable<HTMLElement> | ko.Computed<HTMLElement> | ((element: HTMLElement) => void);

export class Ref implements ko.BindingHandler {
    init(element: any, valueAccessor: () => RefSetter, allBindings: ko.AllBindings) : void {
        const accessor = valueAccessor();
        accessor(element);
    }
}

ko.bindingHandlers["ref"] = new Ref();