import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { ICallAttemptDetails } from "../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";
import { IQueueDetailsForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueElement";

export class QueueDetailsElement implements ILockableItem {
    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

	public Name: ko.Observable<string> = ko.observable();
	public Surname: ko.Observable<string> = ko.observable();
	public BusinessName: ko.Observable<string> = ko.observable();
	public PhoneNumber: ko.Observable<string> = ko.observable();
	public Label: ko.Observable<string> = ko.observable();
	public DateTimeToCall: ko.Observable<Date> = ko.observable();
	public NumberOfAttempts: ko.Observable<number> = ko.observable();
	
	public ContactIdentifier: ko.Computed<string>;
	public ShowAttemptsDetails: ko.Computed<boolean>;

	private attemptsDetails: ICallAttemptDetails[] = [];
	
	@LazyImport(nameof<ISurveyWizardService>())
	private surveyWizardService: ISurveyWizardService;

	constructor(queue: IQueueDetailsForWizard) {
        this.ItemKey = queue.Id;
		this.Name(queue.Name);
		this.Surname(queue.Surname);
		this.BusinessName(queue.BusinessName);
		this.PhoneNumber(queue.PhoneNumber);
		this.Label(queue.Label);
		this.DateTimeToCall(queue.DateTimeToCall);
		this.NumberOfAttempts(queue.NumberOfAttempts);

		this.attemptsDetails = (queue.AttemptsDetails || []);
		
		this.ContactIdentifier = ko.computed(() => {
			return this.BusinessName() ? this.BusinessName() : this.Name() + ' ' + this.Surname();
		});

        this.ShowAttemptsDetails = ko.computed(() => {
            return this.NumberOfAttempts() > 0;
        });
	}

	public async ShowAttemptsPopOver(item: QueueDetailsElement, evt: MouseEvent): Promise<void> {
		return this.surveyWizardService.showAttemptsPopOver(this.attemptsDetails, evt);	
	}
}