import * as ko from "knockout";

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUsersSettingsManager, IIUserObserver } from "../../../ProlifeSdk/interfaces/users/IUsersSettingsManager";
import { IUser } from "../../../ProlifeSdk/interfaces/users/IUser";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class UsersSettingsManager implements IUsersSettingsManager
{
    private ajaxService : IAjaxService;
    private companyUsers : IUser[];
	private observers : IIUserObserver[] = [];

    constructor(private serviceLocator : IServiceLocator){
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Users.Resources);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.companyUsers = [];

    }

    load(): Promise<IUser[]> {
        return this.ajaxService.Post<IUser[]>("Users-api", "Users/DetailedSearch", {})
            .then((users) => this.companyUsers = users)
    }

    getName(): string {
        return ProlifeSdk.CompanyUsers;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Users.Users;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
		return null;
    }

    public getUsers() : IUser[] {
        return this.companyUsers;
    }

    public getLoggedUser() : IUser
    {
        var authService : IAuthenticationService = <IAuthenticationService>this.serviceLocator.findService(ServiceTypes.Authentication);
        return this.getUserFrom((<any>authService.getUser()).IdUser);
    }

    public getUserFrom(idUser : number) : IUser {
        var retValues : IUser[] = this.companyUsers.filter((user) => user.IdUser == idUser);
        if (retValues != null && retValues.length == 1)
            return retValues[0];

        return null;
    }

	createOrUpdate(user : IUser) : Promise<IUser> {
		if(user.IdUser > 0) {
			return this.ajaxService.Put<IUser>("Users", "Users", { methodData: user})
				.then(this.onUsersChanged.bind(this));
		}

		return this.ajaxService.Post<IUser>("Users-api", "Users", { methodData: user})
			.then(this.onUsersAdded.bind(this));
	}

	remove(IdUser : number) : Promise<void> {
		return this.ajaxService.Delete<void>("Users", "Users/" + IdUser, {})
			.then(this.onUsersDeleted.bind(this, IdUser));
	}

	private onUsersChanged(user : IUser) {
		var index : number = this.getIndexOfUser(user.IdUser);
		this.companyUsers[index] = user;

		this.observers.forEach((obs) => obs.onUserChanged(user));
	}

	private onUsersAdded(user : IUser) {
		this.companyUsers.push(user);

		this.observers.forEach((obs) => obs.onUserAdded(user));
	}

	private onUsersDeleted(IdUser : number) {
		var index : number = this.getIndexOfUser(IdUser);
		this.companyUsers.splice(index, 1);

		this.observers.forEach((obs) => obs.onUserDeleted(IdUser));
	}

	private getIndexOfUser(IdUser : number) : number {
		var item = this.companyUsers.filter((user : IUser) => user.IdUser == IdUser)[0];
		return this.companyUsers.indexOf(item);
	}

}
