import { IJobOrderEditorPanelFactory, IJobOrderEditorPanel, IJobOrderEditor } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import ko = require("knockout");
import { IDataSourceModel, IDataSource } from "../../../../../DataSources/IDataSource";
import { TreatmentsDataSource, ITreatmentsDataSourceModel } from "../../../../../DataSources/TreatmentsDataSource";
import { ComponentUtils } from "../../../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../../Components/Layouts";
import * as React from "@abstraqt-dev/jsxknockout"
import { IGDPRService, ITreatment } from "../../../../../GDPR/GDPRService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { Table } from "../../../../../Components/TableComponent/TableComponent";
import jss from "jss";
import { Column } from "../../../../../Components/TableComponent/CustomColumn";
import { DetectClassChanges, DetectChanges } from "../../../../../Core/ChangeDetection";
import { IDetectChanges } from "../../../../../Core/interfaces/IDetectChanges";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { GDPRTreatmentDialog } from "../../../../../GDPR/GDPR/GDPRTreatmentDialog";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { DialogComponentBase } from "../../../../../Core/utils/DialogComponentBase";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { If, IfNot } from "../../../../../Components/IfIfNotWith";
import { GDPRTreatmentComponent } from "../../../../../GDPR/GDPR/GDPRTreatmentComponent";
import { Right } from "../../../../../Core/Authorizations";

const { classes } = jss.createStyleSheet({
    gdprTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class JobOrderTreatmentsFactory implements IJobOrderEditorPanelFactory {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    @Right("GDPR_CanViewTreatmentsInJobOrder")
    private canViewTreatmentsInJobOrder: boolean;
    
    constructor(serviceLocator: IServiceLocator, public Color: string){

    }

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderGDPRTreatments(editor, this.Color);
    }
    hasRequiredModules(): boolean {
        return true;
    }
    hasAuthorization(): boolean {
        // return this.authorizationService.isAuthorized("GDPR_CanViewObjectsInJobOrder");
        return this.canViewTreatmentsInJobOrder;
    }

}

class JobOrderGDPRTreatments implements IJobOrderEditorPanel {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @Right("GDPR_CanWriteTreatmentsInJobOrder")
    private canWriteTreatmentsInJobOrder: boolean;

    @Right("GDPR_CanInsertOrUpdateTreatments")
    private canInsertOrUpdateTreatments: boolean;

    Title: ko.Observable<string> = ko.observable(TextResources.GDPR.DataManagementLabel);
    TemplateUrl: string;
    TemplateName: string;

    TableDataSource: TreatmentsDataSource = new TreatmentsDataSource();

    IsSearchMode: ko.Observable<boolean> = ko.observable(true);
    SelectedId: ko.Observable<number> = ko.observable();

    childComponent: GDPRTreatmentComponent;

    constructor(private editor : IJobOrderEditor, public Color : string) {}
    
    canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }
    
    async load(): Promise<void> {
        this.TableDataSource.setJobOrderId(this.editor.JobOrderId);
        this.TableDataSource.refresh();
    }

    async beforeChangePanel(skipPendingChangesCheck?: boolean): Promise<boolean> {
        return true;
    }

    async beforeShowPanel(): Promise<void> {
        await this.load();
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }

    async deleteTreatmentJobOrder(treatment: ITreatment){
        let result = await this.dialogsService.ConfirmAsync(TextResources.GDPR.DisconnectConfirmationMessage, TextResources.GDPR.ConfirmDisconnect, TextResources.GDPR.CancelDisconnect);
        if (result){
            try {
                await this.GDPRService.DeleteTreatmentJobOrder(treatment.Id, this.editor.JobOrderId, this.userInfo.getIdUser());
                this.infoToastService.Success(TextResources.GDPR.SuccessDisconnect);
                this.TableDataSource.refresh();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    async openAssignNewTreatment() {
        this.SelectedId(null);
        this.IsSearchMode(false);
        // let dialog = new GDPRTreatmentDialog(null, { FkJobOrder: this.editor.JobOrderId, Name: this.editor.JobOrder().jobOrder.Name });
        // let result = await dialog.showModal();

        // if (result) {
        //     this.infoToastService.Success(TextResources.GDPR.AddSuccess);
        //     this.TableDataSource.refresh();
        // }
    }

    async openAssignExistingTreatment() {
        let dialog = new AssignExistingTreatmentDialog(this.editor.JobOrderId)
        let result = await dialog.showModal();

        if (result) {
            this.infoToastService.Success(TextResources.GDPR.AddExistingSuccess);
            this.TableDataSource.refresh();
        }
    }

    async saveAll(){
        try {
            let result = await this.childComponent.saveAll();
    
            if (result) {
                this.infoToastService.Success(TextResources.GDPR.AddExistingSuccess);
                this.TableDataSource.refresh();
            }
        } catch (err) {
            this.infoToastService.Error(err.message);
        }
    }

    cancelEdit(){
        this.IsSearchMode(true);
    }

    openDetail(id: number){
        this.SelectedId(id);
        this.IsSearchMode(false);
    }

    render() {
        let vm = this;
        let jobOrderTreatments: IDataSourceModel<number, ITreatment>;

        return ComponentUtils.bindTo(
            <div style={{position: "absolute", inset: "0", padding: "15px"}}>
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-6">
                        <h2>{TextResources.GDPR.DataManagement}</h2>
                    </div>
                    <div className="col-md-6 text-right" style="margin-top: 20px">
                        <div data-bind={{if: vm.IsSearchMode}}>
                            <ko-bind data-bind={{if: (vm.canWriteTreatmentsInJobOrder || vm.canInsertOrUpdateTreatments)}}>
                            <button className="btn btn-primary" style={{ marginRight: '5px' }} data-bind={{click: vm.openAssignExistingTreatment.bind(vm)}}>
                                <i className="fa fa-link"></i> <span>{TextResources.GDPR.AddExistingButtonText}</span>
                            </button>
                            </ko-bind>
                            <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments}}>
                            <button className="btn btn-primary" data-bind={{click: vm.openAssignNewTreatment.bind(vm)}}>
                                <i className="fa fa-plus"></i> <span>{TextResources.GDPR.AddNewButtonText}</span>
                            </button>
                            </ko-bind>
                        </div>
                        <div data-bind={{ifnot: vm.IsSearchMode}}>
                            <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments}}>
                            <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}} style={{ marginRight: '5px' }}>
                                <i className="fa fa-save"></i> {TextResources.GDPR.Save}
                            </button>
                            </ko-bind>
                            <button className="btn btn-warning" data-bind={{click: vm.cancelEdit.bind(vm)}}>
                                <i className="fa fa-times"></i> {TextResources.GDPR.Cancel}
                            </button>
                        </div>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <If condition={vm.IsSearchMode}>
                        {() => (
                            <>
                                <Table rowAs="jobOrderTreatments" compact={true} fixedLayout={true} scrollable={true} dataSource={this.TableDataSource} className={ComponentUtils.classNames("fixed-height", classes.gdprTable)}>
                                    <Column title={TextResources.GDPR.Title} style={{width: "200px"}}>
                                        <div style={{width: "100%"}} className="text-ellipsis" data-bind={{text: jobOrderTreatments.model.Title, attr: {title: jobOrderTreatments.model.Title}}}></div>
                                    </Column>
                                    <Column title={TextResources.GDPR.Description}>
                                        <div style="width: 100%" className="text-ellipsis" data-bind={{text: $(jobOrderTreatments.model.Description).text()}}></div>
                                    </Column>
                                    <Column title={TextResources.GDPR.CreationDate} data-bind={{dateText: jobOrderTreatments.model.CreationDate}}/>
                                    <Column title={TextResources.GDPR.CreatedBy} data-bind={{text: jobOrderTreatments.model.CreationUserFullName}}/>
                                    <Column title={TextResources.GDPR.LastEditDate} data-bind={{dateText: jobOrderTreatments.model.LastEditDate}}/>
                                    <Column title={TextResources.GDPR.EditedBy} data-bind={{text: jobOrderTreatments.model.LastEditUserFullName}}/>
                                    <Column title={TextResources.GDPR.Actions} headerCssClasses="actions-col">
                                        {/* <button className="btn btn-primary btn-xs" title="Dettaglio..." data-bind={{click: vm.openDetail.bind(vm, $data.model.Id)}}>
                                            <i className="fa fa-search"></i>
                                        </button> */}
                                        <button className="btn btn-primary btn-xs" title={TextResources.GDPR.Detail + "..."} data-bind={{click: vm.openDetail.bind(vm, jobOrderTreatments.model.Id)}}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <ko-bind data-bind={{if: (vm.canWriteTreatmentsInJobOrder || vm.canInsertOrUpdateTreatments)}}>
                                        <button className="btn btn-danger btn-xs" title={TextResources.GDPR.DisconnectFromJobOrder} data-bind={{click: vm.deleteTreatmentJobOrder.bind(vm, jobOrderTreatments.model)}}>
                                            <i className="fa fa-unlink"></i>
                                        </button>
                                        </ko-bind>
                                    </Column>
                                </Table>
                            </>
                        )}
                    </If>
                    <IfNot condition={vm.IsSearchMode}>
                    {() => { 
                                let GdprTreatmentComponent = require("../../../../../GDPR/GDPR/GDPRTreatmentComponent").GDPRTreatmentComponent as typeof GDPRTreatmentComponent; 
                                return <GdprTreatmentComponent ref={(c) => this.childComponent = c} treatmentId={vm.SelectedId()} fromJobOrder={{FkJobOrder: this.editor.JobOrderId, Name: this.editor.JobOrder().jobOrder.Name}}/>
                            }}
                    </IfNot>
                </LayoutContent>
            </LayoutWithHeader>
            </div>, this, "vm")
    }
    
    isDefaultOnNew(): boolean {
        return false;
    }
    isDefault(): boolean {
        return false;
    }
    
    dispose() {
    }
}

class AssignExistingTreatmentDialog extends DialogComponentBase {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    SelectedTreatmentId: ko.Observable<number> = ko.observable();
    treatmentsDataSource: TreatmentsDataSource;

    constructor(private jobOrderId: number){
        super({});
        this.title(TextResources.GDPR.AddExistingTreatment);
        this.treatmentsDataSource = new TreatmentsDataSource();
        this.treatmentsDataSource.setJobOrderIdToExclude(jobOrderId);
        this.treatmentsDataSource.refresh();
    }

    showModal(){
        return this.dialogsService.ShowModal(this);
    }

    async action(){
        if (!this.SelectedTreatmentId()) {
            this.infoToastService.Error(TextResources.GDPR.ErrorNoTreatmentSelected);
        } else {
            try {
                await this.GDPRService.InsertTreatmentJobOrder(this.SelectedTreatmentId(), this.jobOrderId, this.userInfo.getIdUser());
                this.modal.close(true);
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    renderBody() {
        let vm = this;

        return ComponentUtils.bindTo(
            <div>
                <select2 value={() => "SelectedTreatmentId"} dataSource={() => "treatmentsDataSource"} label={TextResources.GDPR.SelectTreatment}/>
            </div>, this, "vm");
    }

}