import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { ReportComponent } from "./Components";
import { If } from "../IfIfNotWith";
import { ReportEditorProvider } from "./Editors";
import jss from "jss"
import { Layout } from "../Layouts";
import { _ReportDesigner } from "./ReportDesigner";

const styleSheet = jss.createStyleSheet({
    reportDesignerEditorBar: {
        borderRight: '1px solid #ddd',
        padding: 0,
        margin: 0,
        width: '250px',

        "& .editor-group": {
            
            "& > .editor-group-title": {
                backgroundColor: "#ddd",
                fontSize: '12px',
                display: 'block',
                padding: 5
            },

            "& > .editor-group-body": {
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 5,

                "& .row": {
                    marginLeft: -5,
                    marginRight: -5,

                    "& .col-xs-1, & .col-sm-1, & .col-md-1, & .col-lg-1, & .col-xs-2, & .col-sm-2, & .col-md-2, & .col-lg-2, & .col-xs-3, & .col-sm-3, & .col-md-3, & .col-lg-3, & .col-xs-4, & .col-sm-4, & .col-md-4, & .col-lg-4, & .col-xs-5, & .col-sm-5, & .col-md-5, & .col-lg-5, & .col-xs-6, & .col-sm-6, & .col-md-6, & .col-lg-6, & .col-xs-7, & .col-sm-7, & .col-md-7, & .col-lg-7, & .col-xs-8, & .col-sm-8, & .col-md-8, & .col-lg-8, & .col-xs-9, & .col-sm-9, & .col-md-9, & .col-lg-9, & .col-xs-10, & .col-sm-10, & .col-md-10, & .col-lg-10, & .col-xs-11, & .col-sm-11, & .col-md-11, & .col-lg-11, & .col-xs-12, & .col-sm-12, & .col-md-12, & .col-lg-12": {
                        paddingLeft: 5,
                        paddingRight: 5
                    }
                },

                "& > label": {
                    fontSize: '12px',
                },

                "& .form-group": {
                    marginBottom: 2,

                    "& .control-label": {
                        fontSize: '12px',
                        marginBottom: '2px',
                        fontWeight: 600
                    },

                    "& .form-control": {
                        height: '24px',
                        fontSize: '12px',
                        paddingLeft: '5px',
                        paddingRight: '5px',

                        "& .placeholder": {
                            marginTop: '-5px',
                            marginNottom: '-5px',
                            lineHeight: '20px',
                        },

                        "& .dropdown-icon": {
                            lineHeight: '20px',
                            marginRight: '-3px',
                        },
                        
                        "& .clear-button": {
                            lineHeight: '0.7em',
                        },

                        "& .selected-item.list-item": {
                            margin: '-5px 0',

                            "& > .list-item-content": {
                                lineHeight: '10px',
                            }
                        }
                    },

                    "& .btn": {
                        fontSize: '12px'
                    }
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDesignerEditorBarProps = {
    component: ko.Observable<ReportComponent>;
    reportDesigner: _ReportDesigner;
}

export function ReportDesignerEditorBar(props: ReportDesignerEditorBarProps) {
    const C = require("./ReportDesignerEditorBar")._ReportDesignerEditorBar as typeof _ReportDesignerEditorBar;
    return <C {...props} />;
}

export class _ReportDesignerEditorBar {
    static defaultProps: Partial<ReportDesignerEditorBarProps> = {
    }

    constructor(private props : ReportDesignerEditorBarProps) {
        
    }

    componentDidMount() {
        
    }

    private renderEditor() {
        const comp = this.props.component();
        const editors = ReportEditorProvider.createEditors(comp);

        return <>
            {editors.map(E => <E component={comp} reportDesigner={this.props.reportDesigner} />)}
        </>
    }
    
    render() {
        return <Layout.ScrollContainer systemScrollable verticalOnly className={classes.reportDesignerEditorBar}>
                <If condition={this.props.component}>
                    {() => this.renderEditor()}
                </If>
        </Layout.ScrollContainer>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDesignerEditorBar);
}