import * as ko from "knockout";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { SurveyApplication } from "./Survey/SurveyApplication";
import { QuestionnaireStateSettingManager } from "./Survey/ui/questionnaires/settings/QuestionnaireStateSettingManager";
import { QuestionnaireCategorySettingManager } from "./Survey/ui/questionnaires/settings/QuestionnaireCategorySettingManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ISurveyService, IInboundConfiguredSurvey, IInboundConfiguredSurveyWithQuestionnaireName } from "../ProlifeSdk/interfaces/survey/ISurveyService";
import { IQuestionnaire, IQuestionnaireSplashPageFilters, IQuestionnaireState, IStructuredQuestionnaire, IQuestionnaireEnvelope, IAssignedCampaigns } from "../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IQuestion, IQuestionType } from "../ProlifeSdk/interfaces/survey/IQuestion";
import { IAnswer, IAnswerType } from "../ProlifeSdk/interfaces/survey/IAnswer";
import { ISection } from "../ProlifeSdk/interfaces/survey/ISection";
import { ICategory } from "../Invoices/invoices/settings/ui/IvaModesEditingViewModel";

export class SurveyService extends ProLifeService implements ISurveyService {
    private ajaxService: IAjaxService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ISurveyService>(), this);

        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        super.InitializeService();

        new QuestionnaireStateSettingManager(this.serviceLocator);
        new QuestionnaireCategorySettingManager(this.serviceLocator);
        new SurveyApplication(this.serviceLocator);
    }

    getServiceType() : string
    {
        return ProlifeSdk.SurveyServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getQuestionnaireData(questionnaireId: number): Promise<IQuestionnaire> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi","GetQuestionnaireData", {
            methodData: {
                QuestionnaireId: questionnaireId
            }
        });
    }

    getQuestionsWithoutSection(questionnaireId: number): Promise<IQuestion[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi","GetQuestionsWithoutSection", {
            methodData: {
                QuestionnaireId: questionnaireId
            }
        });
    }

    getQuestionAnswers(questionId: number): Promise<IAnswer[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi","GetQuestionAnswers", {
            methodData: {
                QuestionId: questionId
            }
        })
    }

    getQuestionnaireListForSplash(filters: IQuestionnaireSplashPageFilters, skip: number, count: number): Promise<IQuestionnaire[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireListForSplash", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count
            }
        });
    }

    getQuestionnaireListForSplashCount(filters: any): Promise<number> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireListForSplashCount", {
            methodData: {
                Filters: filters
            }
        });
    }

    getQuestionnaireSections(questionnaireId: number): Promise<ISection[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireSections", {
            methodData: {
                QuestionnaireId: questionnaireId
            }
        });
    }

    getSectionQuestions(sectionId: number): Promise<IQuestion[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetSectionQuestions", {
            methodData: {
                SectionId: sectionId
            }
        });
    }

    getQuestionTypes() : Promise<IQuestionType[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionTypes", {});
    }

    getAnswerTypes() : Promise<IAnswerType[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetAnswerTypes", {});
    }

    addQuestionnaire(questionnaire: IQuestionnaire) : Promise<IQuestionnaire> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "AddQuestionnaire", {
            methodData: {
                QuestionnaireData: questionnaire
            }
        });
    }

    getAvailableQuestionId(questionnaireId: number) : Promise<number> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetAvailableQuestionId", {
            methodData: {
                QuestionnaireId: questionnaireId
            }
        });
    }

    getQuestionnaireStates(): Promise<IQuestionnaireState[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireStates", {});
    }

    getQuestionnaireStateById(id: number): Promise<IQuestionnaireState> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireStateById", {
            methodData: {
                Id: id
            }
        })
    }

    saveFullQuestionnaire(data: IStructuredQuestionnaire): Promise<number> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "SaveFullQuestionnaire", {
            methodData: {
                Questionnaire: data.Questionnaire,
                Sections: data.Sections,
                Questions: data.Questions
            }
        });
    }

    deleteQuestionnaire(questinnaireId: number): Promise<number> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "DeleteQuestionnaire", {
            methodData: {
                QuestionnaireId: questinnaireId
            }
        });
    }

    getQuestionnaireCategories(): Promise<ICategory[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireCategories", {});
    }

    getQuestionnaireCategoryById(id: number): Promise<ICategory> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireCategoryById", {
            methodData: {
                Id: id
            }
        })
    }

    getLimitedQuestionnaires(filter: string): Promise<IQuestionnaireEnvelope[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetLimitedQuestionnaires", {
            methodData: {
                Filter: filter
            },
            background: true
        });
    }

    getQuestionnaireEnvelope(id: number): Promise<IQuestionnaireEnvelope> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "GetQuestionnaireEnvelope", {
            methodData: {
                Id: id
            },
            background: true
        });
    }

    newQuestionnaireVersion(baseQuestionnaireId: number): Promise<number> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "NewQuestionnaireVersion", {
            methodData: {
                BaseQuestionnaireId: baseQuestionnaireId
            }
        })
    }

    assignedToStartedCampaigns(questionnaireId: number): Promise<IAssignedCampaigns[]> {
        return this.ajaxService.Post("Survey-api/QuestionnaireApi", "AssignedToStartedCampaigns", {
            methodData: {
                QuestionnaireId: questionnaireId
            }
        });
    }

    getConfiguredInboundSurveys() : Promise<IInboundConfiguredSurvey[]> {
        return this.ajaxService.Post("Survey-api/InboundApi", "GetConfiguredInboundSurveys", {});
    }

    getConfiguredInboundSurveysWithQuestionnaireName() : Promise<IInboundConfiguredSurveyWithQuestionnaireName[]> {
        return this.ajaxService.Post("Survey-api/InboundApi", "GetConfiguredInboundSurveysWithQuestionnaireName", {});
    }

    getConfiguredInboundSurvey(configId : number) : Promise<IInboundConfiguredSurvey> {
        return this.ajaxService.Post("Survey-api/InboundApi", "GetConfiguredInboundSurvey", {
            methodData : {
                Id: configId
            }
        });
    }

    createOrUpdateConfiguredInboundSurvey(id : number, surveyId : number, phoneNumber : string, externalUrl : string, agendaId : number, disabled : boolean) : Promise<IInboundConfiguredSurvey> {
        return this.ajaxService.Post("Survey-api/InboundApi", "CreateOrUpdateSurveyConfiguration", {
            methodData: {
                Id: id,
                FkQuestionnaire: surveyId,
                PhoneNumber: phoneNumber,
                ExternalURL: externalUrl,
                FkAgenda: agendaId,
                Disabled: disabled
            }
        });
    }

    deleteInboundConfiguration(id : number) : Promise<void> {
        return this.ajaxService.Post("Survey-api/InboundApi", "DeleteInboundConfiguration", {
            methodData: {
                Id: id
            }
        });
    }

    getInboundQuestionnairesForCalledPhoneNumber(calledPhoneNumber : string) : Promise<IQuestionnaireEnvelope[]> {
        return this.ajaxService.Post("Survey-api/InboundApi", "GetInboundQuestionnairesForCalledPhoneNumber", {
            methodData: {
                CalledPhoneNumber: calledPhoneNumber
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return new SurveyService(serviceLocator);
}