import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 22/04/13
 * Time: 10.21
 * To change this template use File | Settings | File Templates.
 *//**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 09/04/13
 * Time: 15.22
 * To change this template use File | Settings | File Templates.
 */

function cloneNodes(nodesArray, shouldCleanNodes) {
    for (var i = 0, j = nodesArray.length, newNodesArray = []; i < j; i++) {
        var clonedNode = nodesArray[i].cloneNode(true);
        newNodesArray.push(shouldCleanNodes ? ko.cleanNode(clonedNode) : clonedNode);
    }
    return newNodesArray;
}

function emptyDomNode(domNode) {
    while (domNode.firstChild) {
        ko.removeNode(domNode.firstChild);
    }
}

export class Navigation {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        ko.utils.domData.set(element, "__invoices_navigationData", {
            lastViewCount: 0
        });
        return { 'controlsDescendantBindings': true };
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        var navigationData : any = ko.utils.domData.get(element, "__invoices_navigationData"),
            dataValue : any[] = ko.utils.unwrapObservable(valueAccessor());

        if(navigationData.lastViewCount && navigationData.lastView) {
            var lastView = navigationData.lastView;
            lastView.forEach(ko.removeNode);

            /*if(dataValue.length > navigationData.lastViewCount) {
                $(lastView)
                    .filter(function() { return this instanceof HTMLElement })
                    .transition({ x: -$(element).width() }, 250, 'ease', () => {
                        lastView.forEach(ko.removeNode);
                    });
            } else {
                $(lastView)
                    .filter(function() { return this instanceof HTMLElement })
                    .transition({ x: $(element).width() }, 250, 'ease', () => {
                        lastView.forEach(ko.removeNode);
                    });
            }*/

            navigationData.lastView = undefined;
        }

        if(!navigationData.template) {
            navigationData.template = cloneNodes(ko.virtualElements.childNodes(element), true);
            emptyDomNode(element);
        }

        var newView = cloneNodes(navigationData.template, true);
        for (var i = 0, j = newView.length; i < j; i++)
            element.appendChild(newView[i]);

        newView.forEach((newViewElement) => {
            ko.applyBindingsToNode(newViewElement, null, dataValue[dataValue.length - 1]);
        });

        navigationData.lastView = newView;

        /*if(dataValue.length > navigationData.lastViewCount && navigationData.lastViewCount > 0) {
            $(newView)
                .filter(function() { return this instanceof HTMLElement })
                .transition({ x: $(element).width() }, 0)
                .transition({ x: 0 }, 250, 'ease');
        } else if(navigationData.lastViewCount != 0) {
            $(newView)
                .filter(function() { return this instanceof HTMLElement })
                .transition({ x: -$(element).width() }, 0)
                .transition({ x: 0 }, 250, 'ease');
        }*/

        navigationData.lastViewCount = dataValue.length;
    }
}

ko.bindingHandlers["navigation"] = new Navigation();