import * as ko from "knockout";
import * as NumbersFormatters from "../../../Desktop/utilities/NumbersFormatters";
import { ScheduleReportViewModel } from "./ScheduleReportViewModel";
import { LightInvoicesWithSchedulesViewModel } from "./LightInvoicesWithSchedulesViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IScheduleService } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IScheduleReport } from "../../../ProlifeSdk/interfaces/schedule/IScheduleReport";

export class ScheduleDetailViewModel
{
	@LazyImport(nameof<ICustomersService>())
	private customersService : ICustomersService;
	@LazyImport(nameof<IInfoToastService>())
	private infoToastService : IInfoToastService;
	@LazyImport(nameof<IScheduleService>())
	private schedulesService: IScheduleService;
	
	public templateName : string = 'schedule_details';
	
	public customerDetail : ko.Observable<any> = ko.observable();
	public customerMetadata : ko.Observable<any> = ko.observable();

	public scheduleReport : ko.ObservableArray<ScheduleReportViewModel> = ko.observableArray([]);
	public TotaleScaduto : ko.Computed<number>;
	public TotaleNonScaduto : ko.Computed<number>;

	constructor(public invoice : LightInvoicesWithSchedulesViewModel)
    {
		if(this.invoice.invoice.CustomerId)
        {
            this.schedulesService.getScheduleReportExercisesForCustomer(this.invoice.invoice.CustomerId)
                .then(this.onReportsLoaded.bind(this))
                .catch(this.onFailed.bind(this));


            this.customersService.ui.getCustomerPreview(this.invoice.invoice.CustomerId)
                .then(this.onCustomerPreviewLoaded.bind(this))
                .catch(this.onFailed.bind(this));
        }

		this.TotaleScaduto = ko.computed(() => {

			var totale = 0;
			var reports = this.scheduleReport();

			for(var i = 0; i < reports.length; i++) {
				totale += reports[i].TotaleNonPagatoScaduto;
			}

			return NumbersFormatters.roundTo(totale,2);
		});

		this.TotaleNonScaduto = ko.computed(() => {

			var totale = 0;
			var reports = this.scheduleReport();

			for(var i = 0; i < reports.length; i++) {
				totale += reports[i].TotaleNonPagato;
			}

			return NumbersFormatters.roundTo(totale,2);
		});

	}

    SomeIsChanged() : boolean
    {
        return this.invoice.SomeIsChanged();
    }

    RevertChanges()
    {
        this.invoice.CancelEditSchedule();
    }

    OpenCustomerDetails()
    {
        var url : string = this.customersService.GetCustomerUrl(this.invoice.invoice.CustomerId);
        window.open(url, "_blank");
    }

	onFailed(jqXHR: JQueryXHR) : void{
		this.infoToastService.Error(jqXHR.statusText);
	}

	onCustomerPreviewLoaded(customer : IView){
		this.customerDetail(customer.viewModel);
	}

	onCustomerMetadataLoaded(customer : IView){
		this.customerMetadata(customer);
	}

	onReportsLoaded(reports : IScheduleReport[]){
		this.scheduleReport(reports.map((report : IScheduleReport) => new ScheduleReportViewModel(report, this.invoice.invoice)));
	}
}