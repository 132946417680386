import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IActivitiesDatesInfoDialog, IActivitiesDatesInfoWithMarker } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";

export class ActivitiesDatesInfoDialog implements IActivitiesDatesInfoDialog {
    public templateName = "activities-dates-info-dialog";
    public templateUrl = "allocations/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Allocations.ActivitiesDatesInfoDialogTitle;
    
    public modal?: { close: (result?: any) => void; };
    
    public ActivitiesDatesInfo: ko.ObservableArray<ActivityInfo> = ko.observableArray([]);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private activitiesDatesInfo: IActivitiesDatesInfoWithMarker[]) {
        this.ActivitiesDatesInfo(this.activitiesDatesInfo.map((a) => new ActivityInfo(a)));
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this, "medium", { noPrompt: true });
    }
    
    public close(): void {
        this.modal.close(true);
    }
    
    public action(): void {
        this.modal.close(true);
    }
}

class ActivityInfo {
    public MarkerType: ko.Observable<string> = ko.observable();
    public MarkerColor: ko.Observable<string> = ko.observable();
    public StartMarker: ko.Observable<boolean> = ko.observable();
    public EndMarker: ko.Observable<boolean> = ko.observable();
    public IsMilestone: ko.Observable<boolean> = ko.observable();
    public ElementId: ko.Observable<number> = ko.observable();
    public IsTask: ko.Observable<boolean> = ko.observable();
    public CartId: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public DueDate: ko.Observable<Date> = ko.observable();
    public IsNotWorkedManualAllocation: ko.Observable<boolean> = ko.observable();
    public IncompleteAllocationMarker: ko.Observable<boolean> = ko.observable();
    public IsDocumentMetadata: ko.Observable<boolean> = ko.observable();

    public TooltipText: ko.Computed<string>;

    @LazyImport(nameof<ITodoListService>())
    private todolistService : ITodoListService;

    constructor(private activityInfo: IActivitiesDatesInfoWithMarker) {
        this.MarkerType(this.activityInfo.MarkerType);
        this.MarkerColor(this.activityInfo.MarkerColor);
        this.StartMarker(this.activityInfo.StartMarker);
        this.EndMarker(this.activityInfo.EndMarker);
        this.IsMilestone(this.activityInfo.IsMilestone);
        this.ElementId(this.activityInfo.ElementId);
        this.IsTask(this.activityInfo.IsTask);
        this.CartId(this.activityInfo.CartId);
        this.Title(this.activityInfo.Title);
        this.StartDate(this.activityInfo.StartDate);
        this.DueDate(this.activityInfo.DueDate);
        this.IsNotWorkedManualAllocation(this.activityInfo.IsNotWorkedManualAllocation);
        this.IncompleteAllocationMarker(this.activityInfo.IncompleteAllocationMarker);
        this.IsDocumentMetadata(this.activityInfo.IsDocumentMetadata);

        this.TooltipText = ko.computed(() => {
            this.StartMarker();
            this.EndMarker();
            this.IncompleteAllocationMarker();
            this.ElementId();
            this.IsNotWorkedManualAllocation();
            this.IsDocumentMetadata();

            if (this.IsDocumentMetadata()) {
                return this.activityInfo.DocumentMetadataLabel;
            }

            if (this.StartMarker()) {
                return ProlifeSdk.TextResources.Allocations.ActivityStartMarkerText;
            }

            if (!this.EndMarker()) {
                if (this.IncompleteAllocationMarker()) {
                    return TextResources.Allocations.IncompleteAllocationMarker;
                }
                
                return ProlifeSdk.TextResources.Allocations.MilestoneMarkerText;
            }
            
            if (!this.ElementId()) {
                if (this.IsNotWorkedManualAllocation() === null) {
                    return ProlifeSdk.TextResources.Allocations.CartEndMarkerText;
                } else {
                    return ProlifeSdk.TextResources.Allocations.NotWorkedManualAllocationMarkerText;
                }
            } else {
                return ProlifeSdk.TextResources.Allocations.ActivityEndMarkerText;
            }
        });
    }

    public async showActivityEditor(): Promise<void> {
        if (!this.IsTask())
            return;

        await this.todolistService.ShowEditTaskDialog(this.ElementId());
    }
}