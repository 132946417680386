import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IWorkedHoursRowForList } from "../../../interfaces/IWorkedHoursEditor";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { ResponseError } from "../../../../Core/response/ResponseBase";

export interface IMultipleInsertError {
    row: IWorkedHoursRowForList;
    errors: ResponseError[];
}

type Error = {
    row: IWorkedHoursRowForList;
    error: string;
};

const messagesMap = new Map<string, string>([
    ["TaskBlocked", ProlifeSdk.TextResources.WorkedHours.TaskOrWorkflowBlocked],
    ["TaskCreationReferenceDateBeforeOrSameOfDate", ProlifeSdk.TextResources.WorkedHours.TaskCreationReferenceDateBeforeOrSameOfDate],
    ["WarningOrErrorForBalance", ProlifeSdk.TextResources.WorkedHours.WarningOrErrorForBalance],
    ["MaxFlexibilityExceeded", ProlifeSdk.TextResources.WorkedHours.MaxFlexibilityExceeded],
    ["ReferencedInDocuments", ProlifeSdk.TextResources.WorkedHours.HoursReferencingDocumentMessage],
    ["ReferencedInDocumentsAndNotAuthorized", ProlifeSdk.TextResources.WorkedHours.HoursReferencingDocumentAndNotAuthorizedMessage],
]);

export class MultipleErrorsDialog implements IDialog {
    public templateName: string = "multiple-insert-errors-dialog";
    public templateUrl: string = "workedhours/templates/worksheet";
    public title: string = ProlifeSdk.TextResources.WorkedHours.MultipleInsertErrorsDialg;
    public modal?: { close: (result?: any) => void };

    @LazyImport(ServiceTypes.Dialogs)
    private dialogsService!: IDialogsService;

    public errorsList: Error[] = [];

    constructor(errors: IMultipleInsertError[]) {
        errors.forEach((error) => {
            error.errors.forEach((e) => {
                const message = messagesMap.get(e.code);
                if (message) {
                    this.errorsList.push({
                        row: error.row,
                        error: message,
                    });
                }
            });
        });
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        throw new Error("Method not implemented.");
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this, null, { noPrompt: true });
    }
}
