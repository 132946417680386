import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICatalogsService } from "../ProlifeSdk/interfaces/warehouse/ICatalogsService";

export class CatalogText {
    init(element: any, valueAccessor: () => any): void {
        CatalogText.refreshContent(element, valueAccessor()());
    }

    update(element: any, valueAccessor: () => any): void {
        CatalogText.refreshContent(element, valueAccessor()());
    }

    private static async refreshContent(element: any, id: number) {
        $(element).text("");

        if (id == null || id == undefined) return;

        const catalogService = Core.serviceLocator.findService<ICatalogsService>(ProlifeSdk.CatalogsServiceType);
        const c = await catalogService.getCatalogById(id);
        if (c) $(element).text(c.Description);
    }
}

ko.bindingHandlers["catalogText"] = new CatalogText();
