import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { GDPRApplication } from "./GDPR/GDPRApplication";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { GDPREventTypesSettingsManager } from "./settings/GDPREventTypesSettingsManager";
import { TreatmentTypesSettingsManager } from "./settings/TreatmentTypesSettingsManager";

export interface ITYP_Treatment {
    Id?: number;
    Title : string;
    Description?: string;
    FkTreatmentType : number;
}

export interface ITYP_Event {
    Id?: number;
    IsLocked: boolean;
    Title: string;
    Description?: string;
    Date : Date;
    Location?: string;
    FkEventType : number;
}

export interface ITYP_EventResource {
    FkEvent : number;
    FkResource : number;
    ResourceType?: string;
}

export interface IInsertOrUpdateTreatmentRequest {
    fkUser?: number;
    treatment?: ITYP_Treatment[];
    jobOrdersIds?: number[];
    events?: ITYP_Event[];
    eventsResources?: ITYP_EventResource[];
}

export interface IInsertTreatmentJobOrderResponse {
}

export interface IFullTreatmentJobOrders {
    FkJobOrder : number;
    Name?: string;
}

export interface IFullTreatmentEvents {
    Id : number;
    FkTreatment : number;
    Title: string;
    Description?: string;
    IsLocked: boolean;
    Date : Date;
    FkEventType : number;
    Location?: string;
    CreationDate : Date;
    CreationUserFullName?: string;
    FkCreationUser : number;
    LastEditDate : Date;
    LastEditUserFullName?: string;
    FkLastEditUser : number;
}

export interface IFullTreatmentEventsResources {
    FkEvent : number;
    ResourceType?: string;
    FkResource : number;
}

export interface IGetTreatmentsRequest {
    textFilter?: string;
    jobOrdersIds?: number[];
    resourcesIds?: number[];
    jobOrderIdToExclude?: number;
    skip?: number;
    count?: number;
}

export interface ITreatment {
    Id : number;
    Title : string;
    Description : string;
    FkTreatmentType : number;
    IsOpen : boolean;
    CreationDate : Date;
    CreationUserFullName?: string;
    FkCreationUser : number;
    LastEditDate : Date;
    LastEditUserFullName?: string;
    FkLastEditUser : number;
}

export interface IFullTreatment {
    Id : number;
    Title : string;
    Description?: string;
    IsOpen: boolean;
    FkTreatmentType : number;
    CreationDate : Date;
    CreationUserFullName?: string;
    FkCreationUser : number;
    LastEditDate : Date;
    LastEditUserFullName?: string;
    FkLastEditUser : number;
}

export interface IFullTreatmentData {
    Treatment : IFullTreatment;
    JobOrders : IFullTreatmentJobOrders[];
    Resources: IFullTreatmentResources[];
    Events : IFullTreatmentEvents[];
    EventsResources : IFullTreatmentEventsResources[];
}

export interface IFullTreatmentResources {
    FkResource: number;
    FullName: string;
    ResourceType: string;
    IsActive: boolean;
}

export interface IGDPREventType {
    Id : number;
    FkLogicEventType : number;
    Description?: string;
    IsInUse?: number;
    Deleted : boolean;
    IsSystemType?: boolean;
}

export interface IGetEventTypesRequest {
    textFilter?: string;
    logicEventTypeId?: number;
    showDeleted?: boolean;
    skip?: number;
    count?: number;
}

export interface ITYP_EventType {
    Id?: number;
    FkLogicEventType : number;
    Description?: string;
    Deleted : boolean;
}

export interface ITreatmentType {
    Id : number;
    Name: string;
    Deleted: boolean;
    IsInUse?: number;
}

export interface ITYP_TreatmentType {
    Id : number;
    Name: string;
    Deleted: boolean;
}

export interface IGDPRService extends IService {
    GetEventTypesByIds(ids : number[] | null) : Promise<IGDPREventType[]>;
    InsertOrUpdateTreatment(request : IInsertOrUpdateTreatmentRequest) : Promise<void>;
    DeleteTreatment(id : number | null, fkUser : number | null) : Promise<void>;
    DeleteTreatmentJobOrder(fkTreatment : number | null, fkJobOrder : number | null, fkUser : number | null) : Promise<void>;
    InsertTreatmentJobOrder(fkTreatment : number | null, fkJobOrder : number | null, fkUser : number | null) : Promise<IInsertTreatmentJobOrderResponse[]>;
    GetTreatments(request : IGetTreatmentsRequest) : Promise<ITreatment[]>;
    GetTreatmentsByIds(ids : number[] | null) : Promise<ITreatment[]>;
    GetFullTreatmentData(id : number | null) : Promise<IFullTreatmentData>;
    GetEventTypes(request : IGetEventTypesRequest) : Promise<IGDPREventType[]>;
    InsertOrUpdateEventTypes(eventTypes : ITYP_EventType[] | null) : Promise<void>;
    EventTypesSettingsManager : GDPREventTypesSettingsManager;
    TreatmentTypesSettingsManager: TreatmentTypesSettingsManager;
    GetTreatmentTypes(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITreatmentType[]>;
    GetTreatmentTypesByIds(ids : number[] | null) : Promise<ITreatmentType[]>;
    InsertOrUpdateTreatmentTypes(treatmentTypes : ITYP_TreatmentType[] | null) : Promise<void>;
}

@Service(nameof<IGDPRService>())
class GDPRService implements IGDPRService {
    private application : GDPRApplication;

    EventTypesSettingsManager : GDPREventTypesSettingsManager;
    TreatmentTypesSettingsManager: TreatmentTypesSettingsManager;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    InitializeService() {
        this.EventTypesSettingsManager = new GDPREventTypesSettingsManager();
        this.TreatmentTypesSettingsManager = new TreatmentTypesSettingsManager();
        this.application = new GDPRApplication();
    }

    getServiceType() : string {
        return ProlifeSdk.GDPRServiceType;
    }

    isOfType(serviceType : string) : boolean {
        return serviceType == this.getServiceType();
    }

    GetEventTypesByIds(ids : number[] | null) : Promise<IGDPREventType[]> {
        let result = this.ajaxService.Post<IGDPREventType[]>("GDPR-api/GDPR", "GetEventTypesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }, 
        });



        return result;
    }

    InsertOrUpdateTreatment(request : IInsertOrUpdateTreatmentRequest) : Promise<void> {
        let result = this.ajaxService.Post<void>("GDPR-api/GDPR", "InsertOrUpdateTreatment", {
            background: true,
            methodData: request
        });



        return result;
    }

    DeleteTreatment(id : number | null, fkUser : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("GDPR-api/GDPR", "DeleteTreatment", {
            background: true,
            methodData: {
                id: id,
                fkUser: fkUser,
            }
        });



        return result;
    }

    DeleteTreatmentJobOrder(fkTreatment : number | null, fkJobOrder : number | null, fkUser : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("GDPR-api/GDPR", "DeleteTreatmentJobOrder", {
            background: true,
            methodData: {
                fkTreatment: fkTreatment,
                fkJobOrder: fkJobOrder,
                fkUser: fkUser,
            }
        });



        return result;
    }

    InsertTreatmentJobOrder(fkTreatment : number | null, fkJobOrder : number | null, fkUser : number | null) : Promise<IInsertTreatmentJobOrderResponse[]> {
        let result = this.ajaxService.Post<IInsertTreatmentJobOrderResponse[]>("GDPR-api/GDPR", "InsertTreatmentJobOrder", {
            background: true,
            methodData: {
                fkTreatment: fkTreatment,
                fkJobOrder: fkJobOrder,
                fkUser: fkUser,
            }
        });



        return result;
    }

    GetTreatments(request : IGetTreatmentsRequest) : Promise<ITreatment[]> {
        let result = this.ajaxService.Post<ITreatment[]>("GDPR-api/GDPR", "GetTreatments", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetTreatmentsByIds(ids : number[] | null) : Promise<ITreatment[]> {
        let result = this.ajaxService.Post<ITreatment[]>("GDPR-api/GDPR", "GetTreatmentsByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetFullTreatmentData(id : number | null) : Promise<IFullTreatmentData> {
        let result = this.ajaxService.Post<IFullTreatmentData>("GDPR-api/GDPR", "GetFullTreatmentData", {
            background: true,
            methodData: {
                id: id,
            }
        });



        return result;
    }

    GetEventTypes(request : IGetEventTypesRequest) : Promise<IGDPREventType[]> {
        let result = this.ajaxService.Post<IGDPREventType[]>("GDPR-api/GDPR", "GetEventTypes", {
            background: true,
            methodData: request
        });



        return result;
    }

    InsertOrUpdateEventTypes(eventTypes : ITYP_EventType[] | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("GDPR-api/GDPR", "InsertOrUpdateEventTypes", {
            background: true,
            methodData: {
                eventTypes: eventTypes,
            }
        });



        return result;
    }

    GetTreatmentTypes(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITreatmentType[]> {
        let result = this.ajaxService.Post<ITreatmentType[]>("GDPR-api/GDPR", "GetTreatmentTypes", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showDeleted: showDeleted,
                skip: skip,
                count: count,
            }
        });



        return result;
    }

    GetTreatmentTypesByIds(ids : number[] | null) : Promise<ITreatmentType[]> {
        let result = this.ajaxService.Post<ITreatmentType[]>("GDPR-api/GDPR", "GetTreatmentTypesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    InsertOrUpdateTreatmentTypes(treatmentTypes: ITreatmentType[] | null): Promise<void> {
        let result = this.ajaxService.Post<void>("GDPR-api/GDPR", "InsertOrUpdateTreatmentTypes", {
            background: true,
            methodData: {
        		treatmentTypes: treatmentTypes,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IGDPRService>());
}
