import * as ko from "knockout";
import { LazyImport } from "../../Core/DependencyInjection";
import { IExcelExportConfiguration, IExcelExportService } from "../../ProlifeSdk/ExcelExportService";
import { ExcelExportConfigurationsDataSource } from "../../DataSources/ExcelExportConfigurationsDataSource";
import { Attachment } from "../../ProlifeSdk/prolifesdk/blog/Attachment";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ReportColumnsInfoDialog } from "../../ProlifeSdk/prolifesdk/reports/ReportColumnsInfoDialog";
import { Right } from "../../Core/Authorizations";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam, classNames } from "../../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IFileRepositoryService } from "../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../../ProlifeSdk/interfaces/files/IFileOrFolder";

const attributes = {
    Position: "position",
    Viewport: "viewport",
    ExporterId: "exporterId",
    ExporterMethod: "exporterMethod",
    ExporterController: "exporterController",
    DefaultTemplate: "defaultTemplate",
    DataProvider: "dataProvider",
    CssClasses: "cssClasses",
    Title: "title",
    PopoverClasses: "popoverClasses",
    PopoverContainer: "popoverContainer",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "excel-exporter": {
                params?: {
                    Position: string;
                    Viewport?: string;
                    ExporterId?: string;
                    ExporterMethod?: string;
                    ExporterController?: string;
                    DefaultTemplate?: string;
                    DataProvider?: string;
                    CssClasses?: string;
                    Title?: string;
                    PopoverClasses?: string;
                    PopoverContainer?: string;
                    As?: string;
                };

                position?: ("left" | "top" | "right" | "bottom") | (() => string);
                viewport?: string | (() => string);
                exporterId?: string | (() => string);
                exporterMethod?: string | (() => string);
                exporterController?: string | (() => string);
                defaultTemplate?: string | (() => string);
                dataProvider?: () => string;
                cssClasses?: string | (() => string);
                title?: string | (() => string);
                popoverClasses?: string | (() => string);
                popoverContainer?: string | (() => string);
                as?: string | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IExcelExporterComponentParameters {
    Position?: Param<"left" | "top" | "right" | "bottom">;
    Viewport?: Param<string | HTMLElement>;
    ExporterId: Param<string>;
    ExporterMethod: Param<string>;
    ExporterController?: Param<string>;
    DefaultTemplate?: Param<string>;
    DataProvider?: Param<any>;
    CssClasses?: Param<string>;
    Title?: Param<string>;
    PopoverClasses?: Param<string>;
    PopoverContainer?: Param<string>;
    As?: Param<string>;
}

class _ExcelExporterComponent implements IDialog {
    templateName = "";
    templateUrl = "";
    title = "Esportazione Excel";
    modal: { close: (result?: any) => void };

    Options: ko.ObservableArray<IExcelExportConfiguration> = ko.observableArray();

    NewConfigurationId: ko.Observable<number> = ko.observable();
    NewConfigurationVisible: ko.Observable<boolean> = ko.observable();
    NewConfigurationLabel: ko.Observable<string> = ko.observable();
    NewConfigurationModelId: ko.Observable<string> = ko.observable();
    NewConfigurationModel: ko.Observable<Attachment> = ko.observable();
    NewConfigurationCanSaveForEveryone: ko.Observable<boolean> = ko.observable(true);
    NewConfigurationCanSaveForSelf: ko.Observable<boolean> = ko.observable(true);

    EditingMode: ko.Observable<boolean> = ko.observable(false);

    @Right("Desktop_CanEditExportConfigurationsForEveryone")
    CanEditForEveryone: boolean;

    @Right("Desktop_CanEditExportConfigurationsForSelf")
    CanEditForSelf: boolean;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;

    @LazyImport(nameof<IExcelExportService>())
    private excelExportService: IExcelExportService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    ExcelExportConfigurationsDataSource: ExcelExportConfigurationsDataSource =
        new ExcelExportConfigurationsDataSource();

    private DefaultTemplate: ComponentParam<string>;

    Title: ComponentParam<string>;
    CssClasses: ComponentParam<string>;
    PopoverClasses: ComponentParam<string>;
    PopoverContainer: ComponentParam<string>;
    As: ComponentParam<string>;
    Position: ComponentParam<"left" | "top" | "right" | "bottom">;
    Viewport: ComponentParam<string | HTMLElement>;
    DataProvider: ComponentParam<any>;
    ExporterId: ComponentParam<string>;
    ExporterMethod: ComponentParam<string>;
    ExporterController: ComponentParam<string>;

    constructor(params: IExcelExporterComponentParameters) {
        this.Position = ComponentUtils.parseParameter(params.Position, "bottom");
        this.Viewport = ComponentUtils.parseParameter(params.Viewport, null);
        this.DataProvider = ComponentUtils.parseParameter(params.DataProvider, {});
        this.ExporterId = ComponentUtils.parseParameter(params.ExporterId, "");
        this.ExporterMethod = ComponentUtils.parseParameter(params.ExporterMethod, "");
        this.ExporterController = ComponentUtils.parseParameter(params.ExporterController, "");
        this.DefaultTemplate = ComponentUtils.parseParameter(params.DefaultTemplate, "DefaultTemplate");
        this.CssClasses = ComponentUtils.parseParameter(
            params.CssClasses,
            !params.As || params.As === "button" ? "btn btn-primary" : ""
        );
        this.Title = ComponentUtils.parseParameter(params.Title, "");
        this.PopoverClasses = ComponentUtils.parseParameter(params.PopoverClasses, "");
        this.PopoverContainer = ComponentUtils.parseParameter(params.PopoverContainer, "body");
        this.As = ComponentUtils.parseParameter(params.As, "button");

        this.ExcelExportConfigurationsDataSource.setExporterId(this.ExporterId());

        this.ExporterId.subscribe((newExporterId) => {
            this.ExcelExportConfigurationsDataSource.setExporterId(newExporterId);
        });

        this.NewConfigurationModelId.subscribe((id) => {
            if (!id) {
                this.NewConfigurationModel(undefined);
                return;
            }

            const attachment = new Attachment();
            attachment.id = this.NewConfigurationModelId();
            attachment.refresh().then(() => {
                this.NewConfigurationModel(attachment);
            });
        });
    }

    public ShowExportConfigurations(item: any, event: Event): Promise<void> {
        return this.dialogsService.ShowPopover(
            event.currentTarget as HTMLElement,
            this,
            this.Position(),
            this.Viewport(),
            this.PopoverClasses(),
            this.PopoverContainer()
        );
    }

    public NewConfiguration() {
        this.NewConfigurationId(undefined);
        this.NewConfigurationLabel(undefined);
        this.NewConfigurationModelId(undefined);
        this.NewConfigurationCanSaveForEveryone(true);
        this.NewConfigurationCanSaveForSelf(true);
        this.NewConfigurationVisible(true);
    }

    public ToggleEditingMode() {
        this.EditingMode(!this.EditingMode());
    }

    public async DeleteConfiguration(configuration: IExcelExportConfiguration): Promise<void> {
        const result = await this.dialogsService.ConfirmAsync(
            "Sei sicuro di voler eliminare questa configurazione?",
            "Annulla",
            "Elimina"
        );
        if (!result) return;

        this.excelExportService.RemoveExcelExportConfiguration(configuration.Id);
        this.ExcelExportConfigurationsDataSource.refresh();
    }

    public ModifyConfiguration(configuration: IExcelExportConfiguration) {
        this.NewConfigurationId(configuration.Id);
        this.NewConfigurationLabel(configuration.Label);
        this.NewConfigurationModelId(configuration.FileId);
        this.NewConfigurationCanSaveForEveryone(!configuration.LoginId);
        this.NewConfigurationCanSaveForSelf(!!configuration.LoginId);
        this.NewConfigurationVisible(true);
    }

    public async DoExport(configuration: IExcelExportConfiguration): Promise<void> {
        let data = this.DataProvider();
        if (!data) return;

        data = typeof data === "function" ? data() : data;

        data.templateId = configuration.FileId;

        try {
            await this.ajaxService.Download(this.getController(), this.ExporterMethod(), {
                methodData: data,
                background: true,
            });
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.ProlifeSdk.ExcelExporterError);
        }
    }

    public BrowseFileRepository() {
        this.fileRepositoryService
            .openAsDialog(ProlifeSdk.TextResources.Customers.ReportModelsFolder + "/")
            .then((selectedFiles: IFileOrFolder[]) => {
                if (selectedFiles.length > 0) {
                    this.NewConfigurationModelId(selectedFiles[0].Id);
                }
            });
    }

    public SaveNewConfigurationForUser(): Promise<void> {
        return this.saveConfiguration(this.userInfo.getIdUser());
    }

    public SaveNewConfigurationForEveryone(): Promise<void> {
        return this.saveConfiguration(null);
    }

    public async DownloadDefaultTemplate(): Promise<void> {
        await this.ajaxService.Download(this.getController(), this.DefaultTemplate(), { background: true });
    }

    public ShowAvailableColumns(): Promise<void> {
        const dialog = new ReportColumnsInfoDialog(this.ExporterId(), "AvailableColumns");
        return this.dialogsService.ShowModal<void>(dialog, "large", { noPrompt: true });
    }

    private getController(): string {
        return this.ExporterController() ? this.ExporterController() : this.ExporterId();
    }

    private async saveConfiguration(userId: number | null): Promise<void> {
        if (String.isNullOrUndefinedOrWhiteSpace(this.NewConfigurationLabel())) {
            this.infoToastService.Warning("Inserire una etichetta prima di salvare la configurazione");
            return;
        }

        if (!this.NewConfigurationModelId()) {
            this.infoToastService.Warning("Selezionare un modello prima di salvare la configurazione");
            return;
        }

        try {
            await this.excelExportService.InsertOrUpdateExcelExportConfiguration({
                id: this.NewConfigurationId(),
                exporterId: this.ExporterId(),
                loginId: userId,
                label: this.NewConfigurationLabel(),
                fileId: this.NewConfigurationModelId(),
            });

            this.NewConfigurationId(undefined);
            this.NewConfigurationLabel(undefined);
            this.NewConfigurationModelId(undefined);
            this.NewConfigurationCanSaveForEveryone(true);
            this.NewConfigurationCanSaveForSelf(true);
            this.NewConfigurationVisible(false);
            this.ExcelExportConfigurationsDataSource.refresh();
        } catch {
            this.infoToastService.Error("Si è verificato un errore durante il salvataggio della configurazione!");
        }
    }

    public CancelNewConfiguration() {
        this.NewConfigurationVisible(false);
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        this.modal.close(true);
    }

    public renderTemplateNodes(): Node[] {
        return [
            <>
                <div style="width: 550px" class="container-fluid">
                    <div class="row">
                        <button
                            class="btn btn-primary btn-circle btn-icon-only pull-right"
                            title="Scarica Modello"
                            data-bind="tooltip, asyncClick: DownloadDefaultTemplate"
                        >
                            <i class="fa fa-download"></i>
                        </button>
                        <button
                            class="btn btn-success btn-circle btn-icon-only pull-right"
                            title="Colonne Disponibili"
                            data-bind="tooltip, asyncClick: ShowAvailableColumns"
                            style="width: 35px; margin-right: 5px"
                        >
                            <i class="fa fa-info"></i>
                        </button>
                        <button
                            class="btn btn-primary btn-circle btn-icon-only"
                            title="Nuova Configurazione"
                            data-bind="tooltip, click: NewConfiguration, visible: CanEditForEveryone || CanEditForSelf"
                        >
                            <i class="fa fa-plus"></i>
                        </button>
                        <button
                            class="btn btn-circle btn-icon-only"
                            title="Modifica"
                            data-bind="tooltip, click: ToggleEditingMode, visible: CanEditForEveryone || CanEditForSelf, css: { 'btn-warning': EditingMode, 'btn-success': !EditingMode() }"
                        >
                            <i class="fa fa-pencil"></i>
                        </button>
                    </div>
                    <div
                        class="portlet light row"
                        data-bind="visible: NewConfigurationVisible"
                        style="border: 1px solid lightgray; margin-top: 15px"
                    >
                        <div class="portlet-title">
                            <div class="caption">
                                <i class="icon-speech"></i>
                                <span class="caption-subject bold uppercase"> Nuova Configurazione</span>
                            </div>
                            <div class="actions">
                                <a
                                    href="#"
                                    class="btn btn-circle btn-primary"
                                    data-bind="asyncClick: SaveNewConfigurationForUser, visible: CanEditForSelf && NewConfigurationCanSaveForSelf()"
                                >
                                    <i class="fa fa-floppy-o"></i>&nbsp; Salva
                                </a>
                                <a
                                    href="#"
                                    class="btn btn-circle btn-danger"
                                    data-bind="asyncClick: SaveNewConfigurationForEveryone, visible: CanEditForEveryone && NewConfigurationCanSaveForEveryone()"
                                >
                                    <i class="fa fa-floppy-o"></i>&nbsp; Salva per Tutti
                                </a>
                                <a
                                    href="#"
                                    class="btn btn-circle btn-icon-only btn-default"
                                    title="Annulla"
                                    data-bind="tooltip, click: CancelNewConfiguration"
                                >
                                    <i class="fa fa-times"></i>
                                </a>
                            </div>
                        </div>
                        <div class="portlet-body">
                            <div class="form-horizontal">
                                <div class="form-group">
                                    <label class="control-label col-md-3">Etichetta</label>
                                    <div class="col-md-9">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Inserisci una etichetta..."
                                            data-bind="value: NewConfigurationLabel"
                                        />
                                    </div>
                                </div>
                                <div class="form-group margin-bottom-0">
                                    <label class="control-label col-md-3">Modello</label>
                                    <div class="col-md-7">
                                        <ko-if data-bind="NewConfigurationModel">
                                            <div class="listview" data-bind="with: NewConfigurationModel">
                                                <a href="#" class="list" data-bind="click : downloadVersion">
                                                    <div class="list-content">
                                                        <div
                                                            class="icon file"
                                                            data-bind="css : previewClasses, attr : { style : previewStyle }"
                                                        ></div>
                                                        <div class="data"></div>
                                                        <span class="list-title" data-bind="text : fileName"></span>
                                                        <span
                                                            class="list-remark"
                                                            data-bind="visible : fileVersion, text : 'Versione' + fileVersion()"
                                                        ></span>
                                                    </div>
                                                </a>
                                            </div>
                                        </ko-if>
                                        <ko-ifnot data-bind="NewConfigurationModel">
                                            <div class="listview">
                                                <a href="#" class="list">
                                                    <div class="list-content">
                                                        <div class="icon file not-found"></div>
                                                        <div class="data"></div>
                                                        <span class="list-title">Nessun modello selezionato</span>
                                                        <span class="list-remark"></span>
                                                    </div>
                                                </a>
                                            </div>
                                        </ko-ifnot>
                                    </div>
                                    <div class="col-md-2">
                                        <button
                                            class="btn btn-primary btn-circle btn-icon-only pull-right"
                                            title="Scegli Modello"
                                            data-bind="tooltip, click: BrowseFileRepository"
                                        >
                                            <i class="fa fa-file-excel-o"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top-10">
                        <list params="DataSource: ExcelExportConfigurationsDataSource, Sortable: false, ContainerHeight: '300px', AllowNoSelection: true, EmptyResultDefaultMessage: 'Nessuna configurazione trovata'">
                            <div class="list-notification-item-title">
                                <i
                                    class="list-notification-item-title-icon"
                                    data-bind="css: Icon, style: { 'background-color': Background, 'color': Foreground }"
                                ></i>
                                <span class="list-notification-item-title-text" data-bind="text: Title"></span>
                                <div class="list-notification-item-title-actions">
                                    <a
                                        href="#"
                                        class="btn pull-right btn-danger btn-circle btn-xs"
                                        style="margin-right: 5px; min-width: 0px"
                                        data-bind="asyncClick: $parents[1].DeleteConfiguration.bind($parents[1], Model), visible: $parents[1].EditingMode() && ((Model.LoginId > 0 && $parents[1].CanEditForSelf) || (!Model.LoginId && $parents[1].CanEditForEveryone))"
                                    >
                                        <i class="fa fa-trash-o"></i>
                                    </a>
                                    <a
                                        href="#"
                                        class="btn pull-right btn-warning btn-circle btn-xs"
                                        style="margin-right: 5px; min-width: 0px"
                                        data-bind="click: $parents[1].ModifyConfiguration.bind($parents[1], Model), visible: $parents[1].EditingMode() && ((Model.LoginId > 0 && $parents[1].CanEditForSelf) || (!Model.LoginId && $parents[1].CanEditForEveryone))"
                                    >
                                        <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                        href="#"
                                        class="btn pull-right btn-primary btn-circle btn-xs"
                                        style="margin-right: 5px"
                                        data-bind="asyncClick: $parents[1].DoExport.bind($parents[1], Model)"
                                    >
                                        <i class="fa fa-file-excel-o"></i>
                                        Esporta
                                    </a>
                                </div>
                            </div>
                        </list>
                    </div>
                </div>
            </>,
        ];
    }
}

function getKnockoutRootComponent(as: string) {
    const defaultComponent = (
        <button data-bind="asyncClick: ShowExportConfigurations, css: CssClasses, template: { nodes: $componentTemplateNodes }, attr: { title: Title }"></button>
    );

    switch (as) {
        case "button":
            return defaultComponent;
        case "a":
            return (
                <a data-bind="asyncClick: ShowExportConfigurations, css: CssClasses, template: { nodes: $componentTemplateNodes }, attr: { title: Title }"></a>
            );
        default:
            return defaultComponent;
    }
}

ko.components.register("excel-exporter", {
    viewModel: {
        createViewModel: (params: IExcelExporterComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            if (!params.ExporterId) console.error(componentInfo.element, "ExporterId parameter is required");
            if (!params.ExporterMethod) console.error(componentInfo.element, "ExporterMethod parameter is required");

            const vm = new _ExcelExporterComponent(params);

            const nodes = getKnockoutRootComponent(vm.As());

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [nodes]);

            return vm;
        },
    },
    template: [],
});

type ExcelExporterButtonProps = {
    exporterId: string;
    exporterMethod: string;

    position?: "left" | "top" | "right" | "bottom";
    viewport?: string | HTMLElement;
    exporterController?: string;
    defaultTemplate?: string;
    dataProvider?: () => any;
    className?: string;
    title?: string;
    popoverClasses?: string;
    popoverContainer?: string;

    children?: React.ReactNode;

    as?: string;
};

function getRootComponent(props: ExcelExporterButtonProps, _eeb: _ExcelExporterComponent) {
    const defaultComponent = (
        <button
            className={classNames("btn", "btn-primary", props.className)}
            title={props.title}
            data-bind={{ asyncClick: _eeb.ShowExportConfigurations.bind(_eeb) }}
        >
            {props.children}
        </button>
    );

    switch (props.as) {
        case "button":
            return defaultComponent;
        case "a":
            return (
                <a
                    className={classNames(props.className)}
                    title={props.title}
                    data-bind={{ asyncClick: _eeb.ShowExportConfigurations.bind(_eeb) }}
                >
                    {props.children}
                </a>
            );
        default:
            return defaultComponent;
    }
}

export function ExcelExporterButton(props: ExcelExporterButtonProps) {
    const _eeb = new _ExcelExporterComponent({
        ExporterId: props.exporterId,
        ExporterMethod: props.exporterMethod,
        Position: props.position,
        Viewport: props.viewport,
        ExporterController: props.exporterController,
        DefaultTemplate: props.defaultTemplate,
        DataProvider: props.dataProvider,
        CssClasses: props.className,
        Title: props.title,
        PopoverClasses: props.popoverClasses,
        PopoverContainer: props.popoverContainer,
        As: props.as,
    });

    return ComponentUtils.bindTo(getRootComponent(props, _eeb), _eeb, "_eeb") as React.ReactElement;
}
