import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { DefaultTemplateSettingsViewModel } from "./ui/DefaultTemplateSettingsViewModel";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTemplateTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IDefaultTemplateSettingsManager } from "../../../ProlifeSdk/interfaces/todolist/IDefaultTemplateSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class DefaultTemplateSettingsManager implements IDefaultTemplateSettingsManager
{
    private ajaxService : IAjaxService;
    private tasks : ITodoListTemplateTask[] = [];
    private todoListService : ITodoListService;
    private modulesService : IModulesService;

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.Quality);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);
    }

    load(): Promise<void>
    {
        var def = new Deferred<void>();

        this.ajaxService.Post<ITodoListTemplateTask[]>("Todolist-api", "DefaultTemplate/GetTasks", {
            methodData : {},
            background : true
        })
        .then((tasks : ITodoListTemplateTask[]) => { this.tasks = tasks; })
        .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    GetTasks() : ITodoListTemplateTask[]
    {
        return this.tasks;
    }

    getName(): string
    {
        return ProlifeSdk.DefaultTemplateType;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Todolist.QAEvents;
    }

    hasEditingUI(): boolean
    {
        //Devo mostrare la GUI di configurazione del template di default solo se è disabilitato il designer dei template ed è abilitata
        //una delle applicazioni che usano gli eventi di qualità
        return !this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) &&
            (this.modulesService.IsModuleEnabled(ProlifeSdk.BlogApplicationCode) ||
                this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode));
    }

    getEditingUI(): IView
    {
        return {
            templateName: 'default-template-settings',
            templateUrl: 'todolist/templates/settings',
            viewModel: new DefaultTemplateSettingsViewModel(this.serviceLocator, this)
        };
    }

    CreateOrUpdateTask(task : ITodoListTemplateTask) : Promise<ITodoListTemplateTask>
    {
        return this.ajaxService.Post<ITodoListTemplateTask>("Todolist-api", "DefaultTemplate/CreateOrUpdateTask", {
            methodData : task,
            background : true
        })
        .then((t : ITodoListTemplateTask) => { this.tasks.push(t); return t; });
    }

    DeleteTask(taskId : number) : Promise<void>
    {
        return this.ajaxService.Delete<void>("Todolist-api/DefaultTemplate", "" + taskId, {})
            .then(() => {
                var matches : ITodoListTemplateTask[] = this.tasks.filter((t : ITodoListTemplateTask) => { return t.Id == taskId; });

                if(matches.length == 0)
                    return;

                this.tasks.splice(this.tasks.indexOf(matches[0]), 1);
            });
    }

    MoveTask(taskId : number, afterTaskId : number, afterOrBefore : boolean) : Promise<void>
    {
        return this.todoListService.MoveTemplateTask(taskId, afterTaskId, afterOrBefore)
            .then(() => {
                this.load();
            });
    }

}