import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/08/2017
 * Time: 13:05
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Campaign } from "./Campaign";
import { ICampaign } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";

export class CampaignForDetails extends Campaign {
    public ShowEndDate: ko.Computed<boolean>;
    public ShowSampleDetails: ko.Computed<string>;
    public ShowQuestionnaireDetails: ko.Computed<string>;
    public WithoutRules: ko.Computed<boolean>;

    public ShowGlobalTimeIntervalRule: ko.Computed<boolean>;
    public ShowCampaignClassTimeIntervalRule: ko.Computed<boolean>;

    constructor(campaign: ICampaign) {
        super(campaign);

        this.ShowEndDate = ko.computed(() => {
            return !!this.EndDate();
        });

        this.ShowQuestionnaireDetails = ko.computed(() => {
            return "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/" + this.QuestionnaireId();
        });

        this.ShowSampleDetails = ko.computed(() => {
            return "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.SampleId();
        });

        this.ShowGlobalTimeIntervalRule = ko.computed(() => {
            return !!this.GlobalTimeInterval();
        });

        this.ShowCampaignClassTimeIntervalRule = ko.computed(() => {
            return !!this.CampaignClassTimeInterval();
        });

        this.WithoutRules = ko.computed(() => {
            return !this.GlobalTimeInterval() && !this.CampaignClassTimeInterval();
        });
    }
}