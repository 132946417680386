import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 11:42
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { FestivitiesAgendasMenu } from "./navigation/FestivitiesAgendasMenu";
import { EventsSearchViewModel } from "./EventsSearchViewModel";
import { FestivitiesAgendaViewModel } from "./FestivitiesAgendaViewModel";
import { UserConfigurationDialog } from "./dialogs/UserConfigurationDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IFestivitiesService, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IAgendaNavigationMenuObserver } from "../../interfaces/IAgendaNavigationMenu";
import {
    IChangesNotificationsServiceObserver,
    IObjectChangesInfo,
} from "../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IFullCalendarEvent } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { LazyImport } from "../../../Core/DependencyInjection";

export class FestivitiesAgenda implements IAgendaNavigationMenuObserver, IChangesNotificationsServiceObserver {
    public NavigationMenu: FestivitiesAgendasMenu;
    public EventsSearchViewModel: EventsSearchViewModel;

    public SelectedFolder: ko.Observable<any> = ko.observable();
    public SelectedAgenda: ko.Observable<FestivitiesAgendaViewModel> = ko.observable();
    public SelectedEvent: ko.Computed<IFullCalendarEvent>;

    public IsSearching: ko.Computed<boolean>;
    public ShowListButton: ko.Computed<boolean>;
    public ShowCalendarButton: ko.Computed<boolean>;

    public CalendarViewActive: ko.Observable<boolean> = ko.observable(true);
    public ListViewActive: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IFestivitiesService>())
    private festivitiesService: IFestivitiesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator) {
        this.NavigationMenu = new FestivitiesAgendasMenu(this.serviceLocator);
        this.EventsSearchViewModel = null;
        this.NavigationMenu.addMenuObserver(this);
        this.NavigationMenu.singleSelectionEnabled(true);
        this.NavigationMenu.refreshItems();

        this.SelectedEvent = ko.computed(() => {
            if (!this.SelectedAgenda()) return undefined;

            if (!this.SelectedAgenda().Calendar().SelectedEvent()) return undefined;

            return this.SelectedAgenda().Calendar().SelectedEvent();
        });

        this.IsSearching = ko.computed(() => {
            return false;
        });

        this.ShowListButton = ko.computed(() => {
            return false;
        });

        this.ShowCalendarButton = ko.computed(() => {
            return false;
        });
    }

    ShowCalendarView(): void {
        this.ListViewActive(false);
        this.CalendarViewActive(true);
    }

    ShowListView(): void {
        this.ListViewActive(true);
        this.CalendarViewActive(false);
    }

    public OnFolderSelectionChanged(provider: INavigationMenuProvider): void {}

    public OnFolderDeleted(provider: INavigationMenuProvider): void {}

    public notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {}

    OnEventDroppedOnAgenda(event: IFullCalendarEvent, agendaId: number) {}

    public AddFestivity(): void {
        this.festivitiesService.ui
            .GetFestivityEditingDialog(null, this.SelectedAgenda().AgendaId())
            .then((newEvent: IFestivity) => {
                if (!newEvent) return;
                if (newEvent.AgendaId == this.SelectedAgenda().AgendaId())
                    this.SelectedAgenda().RenderEventOnCalendar(newEvent);
            });
        return;
    }

    public EditSelectedFestivity(): void {
        this.festivitiesService.ui
            .GetFestivityEditingDialog(this.SelectedEvent().id, this.SelectedAgenda().AgendaId())
            .then((modifiedEvent: IFestivity) => {
                if (!modifiedEvent) return;

                if (
                    <any>modifiedEvent.Deleted != ProlifeSdk.NotDeletedState ||
                    modifiedEvent.AgendaId != this.SelectedAgenda().AgendaId()
                ) {
                    this.SelectedAgenda().RemoveEventFromCalendar(this.SelectedEvent().id);
                    return;
                }

                this.SelectedAgenda().UpdateEventOnCalendar(modifiedEvent);
            });
    }

    public onSelectionChanged(selection: INavigationMenuProvider[], navigator: INavigationMenu) {
        if (selection.length == 0) {
            this.SelectedAgenda(undefined);
            return;
        }

        this.SelectedAgenda(<FestivitiesAgendaViewModel>selection[0]);
    }

    public ShowUserConfigDialog(): void {
        var dialog = new UserConfigurationDialog(this.serviceLocator);
        dialog.Show();
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (sendByMe) return false;

        if (changesInfo.Action == 0) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().RenderEventOnCalendar(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaEventInsertMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return false;
        }

        if (changesInfo.Action == 1) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().UpdateEventOnCalendar(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaEventUpdateMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return false;
        }

        if (changesInfo.Action == 2) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().RemoveEventFromCalendar(changesInfo.EntityKeyId);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaEventDeleteMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return false;
        }

        return false;
    }

    public dispose() {
        this.NavigationMenu = undefined;
    }
}
