import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, classNames, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { SplashPageDetailsDialog } from "./splash-page/SplashPageDetailsComponent";

type JobOrderDetailsButtonProps = {
    jobOrderId: number;
    jobOrderTitle: string;
    extraSmall?: boolean;
};

export function JobOrderDetailsButton(props: JobOrderDetailsButtonProps) {
    const C = require("./JobOrderDetailsButton")._JobOrderDetailsButton as typeof _JobOrderDetailsButton;
    return <C {...props} />;
}

export class _JobOrderDetailsButton {
    static defaultProps: Partial<JobOrderDetailsButtonProps> = {};

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props: JobOrderDetailsButtonProps) {}

    private async openInfo(): Promise<void> {
        if (!this.props.jobOrderId) return;

        return this.dialogsService.ShowModal(
            new SplashPageDetailsDialog({
                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, this.props.jobOrderTitle),
                JobOrderId: this.props.jobOrderId,
                className: "large",
            })
        );
    }

    render() {
        const jdb = this;

        return ComponentUtils.bindTo(
            <button
                type="button"
                className={classNames("btn btn-primary", { "btn-xs": this.props.extraSmall })}
                title={TextResources.JobOrder.DetailedInfoTitleSimple}
                data-bind={{
                    enable: !!jdb.props.jobOrderId,
                    click: jdb.openInfo.bind(jdb),
                }}
            >
                <i className="fa fa-info-circle"></i>
            </button>,
            this,
            "jdb"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(JobOrderDetailsButton);
}
