import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/08/2017
 * Time: 09:26
 * To change this template use File | Settings | File Templates.
 */

import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { SampleStateSettingManager } from "./Survey/ui/samples/settings/SampleStateSettingManager";
import { SampleTypeSettingManager } from "./Survey/ui/samples/settings/SampleTypeSettingManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ISampleService } from "../ProlifeSdk/interfaces/survey/ISampleService";
import { IPeopleSearchFilter, IPeopleForList } from "../ProlifeSdk/interfaces/survey/IPeopleService";
import { ISampleSplashPageFilters, ISampleForSplash, ISample, ISampleImportFilters, ISampleForImport, ISampleLog, IExcelFileUploadResult, ISampleType, ISampleEnvelope } from "../ProlifeSdk/interfaces/survey/ISample";
import { ISampleState } from "../ProlifeSdk/interfaces/survey/ISampleState";
import { IAssignedCampaigns } from "../ProlifeSdk/interfaces/survey/IQuestionnaire";

class SampleService extends ProLifeService implements ISampleService {
    private ajaxService: IAjaxService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ISampleService>(), this);

        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        super.InitializeService();
        
        new SampleStateSettingManager(this.serviceLocator);
        new SampleTypeSettingManager(this.serviceLocator);
    }

    getServiceType() : string
    {
        return ProlifeSdk.SampleServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getSamplesForSplash(filters : ISampleSplashPageFilters, skip : number, count : number, background : boolean = true) : Promise<ISampleForSplash[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSamplesForSplash", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count
            },
            background: background
        });
    }

    getSamplesForSplashCount(filters: any, background?: boolean): Promise<number> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSamplesForSplashCount", {
            methodData: {
                Filters: filters
            },
            background: background
        });
    }

    getSampleDetails(sampleId : number, background : boolean = true) : Promise<ISample> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleDetails", {
            methodData: {
                SampleId : sampleId
            },
            background: background
        });
    }

    sampleLogicalDelete(sampleId : number, background : boolean = true) : Promise<number> {
        return this.ajaxService.Post("Survey-api/SampleApi", "SampleLogicalDelete", {
            methodData : {
                SampleId : sampleId
            },
            background : background
        });
    }

    newSample(sample: ISample) : Promise<number> {
        return this.ajaxService.Post("Survey-api/SampleApi", "NewSample", {
            methodData: {
                Sample: sample
            }
        });
    }

    getSamplesForImport(sampleId: number, filters: ISampleImportFilters, background : boolean = false) : Promise<ISampleForImport[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSamplesForImport", {
            methodData: {
                SampleId: sampleId,
                Filters: filters
            },
            background: background
        });
    }

    getSampleLogActions(sampleId : number, background : boolean = true) : Promise<ISampleLog[]>
    {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleLogActions", {
            methodData : {
                SampleId : sampleId
            }, background : background
        });
    }

    getSampleStates() : Promise<ISampleState[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleStates", {});
    }

    saveSample(sample: ISample, existingSampleToImport: ISampleForImport[], filesToImport: IExcelFileUploadResult[], singleContactsToImport: number[], background: boolean = true): Promise<number>
    {
        return this.ajaxService.Post("Survey-api/SampleApi", "SaveSample", {
            methodData: {
                Sample: sample,
                ExistingSamplesToImport: existingSampleToImport,
                FilesToImport: filesToImport,
                SingleContactsToImport: singleContactsToImport
            },
            background : background
        });
    }

    getSampleTypes(): Promise<ISampleType[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleTypes", {});
    }

    getSampleContent(sampleId: number, filters: IPeopleSearchFilter, skip: number, count: number): Promise<IPeopleForList[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleContent", {
            methodData: {
                SampleId: sampleId,
                Filters: filters,
                Skip: skip,
                Count: count
            },
            background: true
        });
    }

    addInSample(sampleId: number, peopleIds: number[]): Promise<void> {
        return this.ajaxService.Post("Survey-api/SampleApi", "AddInSample", {
            methodData: {
                SampleId: sampleId,
                PeopleIds: peopleIds
            }
        })
    }

    getLimitedSamples(filter: string): Promise<ISampleEnvelope[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetLimitedSamples", {
            methodData: {
                Filter: filter
            },
            background: true
        });
    }

    getSampleEnvelope(id: number): Promise<ISampleEnvelope> {
        return this.ajaxService.Post("Survey-api/SampleApi", "GetSampleEnvelope", {
            methodData: {
                Id: id
            },
            background: true
        });
    }

    assignedToStartedCampaigns(sampleId: number): Promise<IAssignedCampaigns[]> {
        return this.ajaxService.Post("Survey-api/SampleApi", "AssignedToStartedCampaigns", {
            methodData: {
                SampleId: sampleId
            }
        });
    }

    DeleteContactFromSample(peopleId: number | null, sampleId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Survey-api/SampleApi", "DeleteContactFromSample", {
            background: true,
            methodData: {
        		peopleId: peopleId,
        		sampleId: sampleId,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return new SampleService(serviceLocator);
}