import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { DocumentForReports } from "./DocumentForReports";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { VatRegisterDocuments } from "./VatRegisterDocuments";
import { IInvoicesService } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IVatRegisters, IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class InvoicesReport extends ProLifeReport implements IReport, IReportViewModel
{
    invoicesService : IInvoicesService;
    settingsService : ISettingsService;
    desktopService : IDesktopService;

    Name : string = null;
    templateName : string = "report-list-item";
    templateUrl : string = "reports/templates";
    detailsTemplateName : string = null;
    detailsTemplateUrl : string = null;
    private navigator : IReportsNavigator;
    viewModel : any;

    showMonths : ko.Observable<boolean> = ko.observable(true);
    noResultsFound : ko.Observable<boolean> = ko.observable(true);
    selectedYear : ko.Observable<number> = ko.observable();
    selectedPeriod : ko.Observable<any> = ko.observable();
    selectedMonth : ko.Observable<any> = ko.observable();
    yearsForQuickFilters : ko.ObservableArray<number> = ko.observableArray([]);
    startDate : ko.Observable<Date> = ko.observable();
    endDate : ko.Observable<any> = ko.observable();
    filterTemplate : ko.Observable<string> = ko.observable();
    categories : ko.ObservableArray<VatRegisterDocuments> = ko.observableArray([]);
    vatRegistersService : IVatRegisters;
    selectedCategory : ko.Observable<any> = ko.observable();
    allRegisters : IVatRegister[];

    constructor(name : string, detailsTemplateUrl : string, detailsTemplateName : string, groupId : number, id : number, filterTemplate : string, serviceLocator : IServiceLocator)
    {
        super(groupId, id);
        this.Name = name;
        this.detailsTemplateName = detailsTemplateName;
        this.detailsTemplateUrl = detailsTemplateUrl;
        this.filterTemplate(filterTemplate);

        this.invoicesService = <IInvoicesService>serviceLocator.findService(ProlifeSdk.InvoicesServiceType);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.settingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.vatRegistersService = <IVatRegisters>this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);

        var currentYear = new Date().getFullYear();
        for(var i=4;i>=0;i--)
        {
            this.yearsForQuickFilters.push(currentYear - i);
        }

        this.startDate.subscribe(this.NotifyFilterChange.bind(this));
        this.endDate.subscribe(this.NotifyFilterChange.bind(this));
        this.selectedCategory.subscribe(this.NotifyFilterChange.bind(this));
    }

    commonInitialize()
    {
        super.initialize();
        this.allRegisters = this.vatRegistersService.getVatRegisters();
        this.selectedYear(new Date().getFullYear());
        this.selectedCategory(null);
    }

    initialize()
    {
        //Customize into derivated types...
        this.commonInitialize();
    }

    createExcel()
    {
        //Customize into derivated types...
    }

    print()
    {
        //Customize into derivated types...
    }

    reloadByDates()
    {
        this.selectedYear(null);
        this.selectedPeriod(null);
        this.selectedMonth(null);
    }

    quickFilterByYear(year)
    {
        this.selectedYear(year);
        this.selectedPeriod(null);
        this.selectedMonth(null);

        var start = moment(new Date());
        start.year(this.selectedYear());
        start.month(0);
        start.date(1);

        var end = moment(new Date());
        end.year(this.selectedYear());
        end.month(11);
        end.date(31);

        this.startDate(start.toDate());
        this.endDate(end.toDate());
    }

    quickFilterByPeriod(period)
    {
        if(!this.selectedYear() || this.selectedPeriod() == period)
            return;

        this.selectedPeriod(period);

        var startMonth = 1;
        startMonth = period == 2 ? 4 : startMonth;
        startMonth = period == 3 ? 7 : startMonth;
        startMonth = period == 4 ? 10 : startMonth;

        var endMonth = 3;
        endMonth = period == 2 ? 6 : endMonth;
        endMonth = period == 3 ? 9 : endMonth;
        endMonth = period == 4 ? 12 : endMonth;

        var start = moment(new Date());
        start.year(this.selectedYear());
        start.month(startMonth-1);
        start.date(1);

        var end = moment(new Date());
        end.year(this.selectedYear());
        end.month(endMonth-1);
        end.date(1);
        end.add("months", 1);
        end.subtract("days", 1);

        this.startDate(start.toDate());
        this.endDate(end.toDate());
    }

    quickFilterByMonth(month)
    {
        if(!this.selectedYear() || this.selectedMonth() == month)
            return;

        this.selectedMonth(month);

        var start = moment(new Date());
        start.year(this.selectedYear());
        start.month(month-1);
        start.date(1);

        var end = moment(new Date());
        end.year(this.selectedYear());
        end.month(month-1);
        end.date(1);
        end.add("months", 1);
        end.subtract("days", 1);

        this.startDate(start.toDate());
        this.endDate(end.toDate());
    }

    changeQuickFilter()
    {
        this.selectedPeriod(null);
        this.selectedMonth(null);
        this.showMonths(!this.showMonths());
    }

    Open()
    {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel
    {
        return this;
    }

    setNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
    }

    onLoadDocumentsSucceeded(data)
    {
        var self = this;
        this.selectedCategory().documents([]);
        data.forEach(d => {
            self.noResultsFound(false);

            var documentRegister : IVatRegister = null;

            self.allRegisters.forEach((r : IVatRegister) =>
            {
                documentRegister = r.IdRegistroIVA == d.FKRegistro ? r : documentRegister;
            });

            var docForReport = new DocumentForReports(d, documentRegister);

            self.selectedCategory().documents.push(docForReport);
            self.selectedCategory().selection(true);
            self.selectedCategory().changeSelectionForAll();
        });
    }

    onLoadDocumentsFailed()
    {
    }
}
