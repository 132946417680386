import * as WebUtilities from "../utils/WebUtilities";
import { InstantiableObject, IServiceLocator } from "../interfaces/IServiceLocator";
import { IWebUtilities } from "../interfaces/IWebUtilities";
import { IService } from "../interfaces/IService";

interface IServiceCache {
    construct : InstantiableObject;
    singleton : any;
}

export class ServiceLocator implements IServiceLocator
{
    private registeredServices : { [serviceName : string] : IServiceCache[] } = {};

    public WebUtils : IWebUtilities;

    constructor()
    {
        this.WebUtils = new WebUtilities.WebUtilities();

        this.registerServiceInternal(nameof<IServiceLocator>(), {
            construct: null,
            singleton: this
        });
    }

    public create<T>(constructor : { new (...args : any[]) : T }) : T {
        return new constructor();
    }

    public findService<T>(serviceName: string) : T {
        let services = this.registeredServices[serviceName];
        if(!services)
            return undefined;
        
        if(services.length > 1)
            throw "Multiple services registered as '" + serviceName + "'";

        if(services.length == 0)
            return undefined;

        if(!services[0].singleton) {
            services[0].singleton = new services[0].construct();
        }
        
        return services[0].singleton;
    }

    public findServices<T>(serviceName : string) : T[] {
        let services = this.registeredServices[serviceName];
        if(!services)
            return [];

        let instances : any[] = [];
        services.forEach((service : IServiceCache) => {
            if(!service.singleton) {
                service.singleton = new service.construct();
            }
            instances.push(service.singleton);
        });
        
        return instances;
    }

    public registerService(serviceNames : string[], constructor : InstantiableObject, createNow : boolean = false) : void {
        let serviceRegistration = {
            construct: constructor,
            singleton: null
        };

        serviceNames.forEach(serviceName => this.registerServiceInternal(serviceName, serviceRegistration));

        if(createNow)
            serviceRegistration.singleton = new constructor();
    }

    /* @deprecated */
    public registerServiceInstance(service: IService): boolean {
        let serviceName = service.getServiceType();
        let serviceRegistration = {
            construct: null,
            singleton: service
        };

        this.registerServiceInternal(serviceName, serviceRegistration);
        return true;
    }

    public registerServiceInstanceWithName(serviceName: string, service: IService): boolean {
        let serviceRegistration = {
            construct: null,
            singleton: service
        };

        this.registerServiceInternal(serviceName, serviceRegistration);
        return true;
    }

    public unregisterService(service: IService): boolean {
        const serviceName = service.getServiceType();
        var services = this.registeredServices[serviceName];
        
        if(!services) 
            return false;

        let servicesToRemove = services.filter((s : IServiceCache) => s.construct == null && s.singleton === service);
        servicesToRemove.forEach(s => {
            let index = services.indexOf(s);
            if(index == -1)
                return;

            services.splice(index, 1);
        });

        if(services.length == 0)
            delete this.registeredServices[serviceName];
        return true;
    }

    private registerServiceInternal(serviceName : string, serviceRegistration : IServiceCache) {
        let services = this.registeredServices[serviceName];

        if(!services)
            services = this.registeredServices[serviceName] = [];

        services.push(serviceRegistration);
    }
}