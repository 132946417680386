import * as ko from "knockout";
import { RequirementSource } from "./RequirementSource";
import moment = require("moment");
import { RequirementRowBase } from "../RequirementRowBase";
import { IRequirement } from "../../../ProvisioningService";

export class Requirement extends RequirementRowBase<RequirementSource> {
    public SortMode: ko.Observable<string> = ko.observable();
    public SortColumn: ko.Observable<string> = ko.observable();

    public RequiredAmount: ko.Computed<number>;
    public EarlierDeliveryDate: ko.Computed<Date>;
    
    constructor(requirement: IRequirement) {
        super(requirement);

        this.Selected = ko.computed({
            read: () => {
                return this.Sources().filter(s => s.Selected() && s.MatchFilters()).length == this.Sources().filter((s) => s.MatchFilters()).length;
            },
            write: (value: boolean) => {
                this.Sources().forEach(s => s.Selected(value && s.MatchFilters()));
            }
        });
        
        this.RequiredAmount = ko.computed(() => {
            return this.Amount() - this.InStock();
        });

        this.Selected(false);
        
        this.SelectedAmount = ko.computed(() => {
            let sum = 0;

            this.Sources().forEach((s) => {
                if (s.Selected())
                    sum += s.Amount();
            });

            let warehouses = {};
            let selectedStock: number = 0;

            this.Sources().forEach((s) => {
                if (!warehouses[s.SourceWarehouse()] && s.Selected()) {
                    warehouses[s.SourceWarehouse()] = true;
                    selectedStock += (s.WarehouseStock() || 0);
                }
            });

            return sum - selectedStock;
        });

        this.EarlierDeliveryDate = ko.computed(() => {
            let sources = this.Sources();

            if (sources.length === 0)
                return null;

            let minDate = !sources[0].CustomerOrderRowDeliveryDate() ? null : moment(sources[0].CustomerOrderRowDeliveryDate());

            for (let i = 1; i < sources.length; i++) {
                if (!sources[i].CustomerOrderRowDeliveryDate())
                    continue;

                if (!minDate)
                    minDate = moment(sources[i].CustomerOrderRowDeliveryDate());
                else {
                    let currentDate = moment(sources[i].CustomerOrderRowDeliveryDate());
                    minDate = minDate.isBefore(currentDate) ? minDate : currentDate
                }
            }

            return !minDate ? null : minDate.toDate();
        });
    }

    public addSource(requirement: IRequirement): void {
        this.Sources.push(new RequirementSource(requirement));
        this.Amount((this.Amount() || 0) + (requirement.Amount || 0));
    }

    public sort(fieldName: string, sortMode: string): void {
        this.SortColumn(fieldName);
        this.SortMode(sortMode);

        let requirements = this.Sources();
        let sign = sortMode == 'asc' ? 1 : -1;
        requirements.sort((ra, rb) => {
            if (ra[fieldName]() < rb[fieldName]())
                return -1 * sign;

            if (ra[fieldName]() > rb[fieldName]())
                return 1 * sign;

            return 0;
        });

        this.Sources(requirements);
    }

    public hasRowsForWarehouse(warehouseFilter: string): boolean {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if (this.contains(source.SourceWarehouse(), warehouseFilter)) {
                source.MatchWarehouseFilter(true);
                match = true;
                continue;
            }

            source.MatchWarehouseFilter(false);
        }

        return match;
    }

    public hasRowsForCustomer(customerFilter: string): boolean {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if (this.contains(source.CustomerName(), customerFilter)) {
                source.MatchCustomerFilter(true);
                match = true;
                continue;
            }

            source.MatchCustomerFilter(false);
        }

        return match;
    }

    public hasRowsForJobOrder(jobOrderFilter: string): boolean {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if (this.contains(source.JobOrderName(), jobOrderFilter)) {
                source.MatchJobOrderFilter(true);
                match = true;
                continue;
            }

            source.MatchJobOrderFilter(false);
        }

        return match;
    }

    public hasRowsForDocumentNumber(documentNumberFilter: string): boolean {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if (this.contains(source.CustomerOrderNumber(), documentNumberFilter)) {
                source.MatchOrderNumberFilter(true);
                match = true;
                continue;
            }

            source.MatchOrderNumberFilter(false);
        }

        return match;
    }

    public hasRowsForProtocol(protocolFilter: string): boolean {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if (this.contains(source.Protocol(), protocolFilter)) {
                source.MatchProtocolFilter(true);
                match = true;
                continue;
            }

            source.MatchOrderNumberFilter(false);
        }

        return match;
    }

    public hasRowsInRange(fromFilter: Date, toFilter: Date): unknown {
        let sources = this.Sources();
        let match: boolean = false;

        for (let source of sources) {
            if ((!fromFilter || !moment(fromFilter).isValid() || moment(fromFilter) <= moment(source.CustomerOrderDate())) && (!toFilter || !moment(toFilter).isValid() || moment(toFilter) >= moment(source.CustomerOrderDate()))) {   
                source.MatchDateRangeFilter(true);
                match = true;
                continue;
            }

            source.MatchDateRangeFilter(false);
        }

        return match;
    }

    private contains(value: string, searchQuery: string): boolean {
        if (!searchQuery)
            return true;

        value = (value || "").toLowerCase();

        let words = searchQuery.trim().split(' ').filter(w => !!w);
        for (let word of words) {
            if (value.indexOf(word.toLowerCase()) < 0)
                return false;
        }

        return true;
    }
}