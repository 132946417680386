import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/06/2018
 * Time: 09:13
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SalProtocolSettings } from "./SalProtocolSettings";
import { IProtocolType, IVatRegisterEditingContainer } from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Right } from "../../../Core/Authorizations";

export class SalProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.SalTypeId;
    public DocumentTypeName: string = ProlifeSdk.TextResources.JobOrder.SALStatements;
    public DocumentTypeLabelForSettings: string = ProlifeSdk.TextResources.JobOrder.SALProtocol;
    public DocumentTypeShortLabelForSettings: string = ProlifeSdk.TextResources.JobOrder.SALProtocolShortLabel;
    public HasCustomTemplate = true;

    @Right("Documents_Invoices")
    private canAddProtocolRight: boolean;

    constructor() {}

    canAddProtocols(): boolean {
        return this.canAddProtocolRight;
    }

    createSettingsViewModel(container: IVatRegisterEditingContainer, vatRegister: IVatRegister): any {
        return new SalProtocolSettings(container, vatRegister);
    }
}
