import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { INation, INationsSettingsManager } from "../ProlifeSdk/interfaces/settings/INationsSettingsManager";

export class NationsDataSource extends BaseDataSource<IDataSourceModel<number, INation>> {
    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    private nationsSettingsManager: INationsSettingsManager;

    public getTitle(currentModel: IDataSourceModel<number, INation>): string {
        return this.nationsSettingsManager.getLabel();
    }
    
    public async getData(currentModel: IDataSourceModel<number, INation>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, INation>[]> {
        let nations = this.nationsSettingsManager.getNationsList();
        return nations
            .filter(n => this.matchTextFilter(n, textFilter))
            .sort((a, b) => a.Alpha2Code < b.Alpha2Code ? -1 : a.Alpha2Code > b.Alpha2Code ? 1 : 0)
            .slice(skip, skip + count)
            .map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<number, INation>, ids: number[]): Promise<IDataSourceModel<number, INation>[]> {
        let nations = this.nationsSettingsManager.getNationsList().filter(n => ids.indexOf(n.Id) >= 0);
        return nations.map(this.createModel, this);
    }

    private createModel(nation: INation): IDataSourceModel<number, INation> {
        return {
            id: nation.Id,
            isGroup: false,
            isLeaf: true,
            title: nation.Alpha2Code,
            subTitle: nation.Name,
            model: nation
        };
    }

    private matchTextFilter(nation: INation, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ");
        let match: boolean = true;
        
        for (let word of words) {
            let lowerWord = word.toLowerCase();
            if (!(nation.Name.toLowerCase().indexOf(lowerWord) >= 0) && !(nation.Alpha2Code.toLowerCase().indexOf(lowerWord) >= 0))
                match = false;
        }

        return match;
    }
}