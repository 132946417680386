import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { WarehousesProvider } from "../Utilities/WarehousesProvider";
import { CustomersProvider } from "../../../../Desktop/utilities/CustomersProvider";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IArticlesService, IArticleDocumentMovement } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IVatRegisters } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class ArticleDetailsDialog implements IDialog
{
    title: string = null;
    templateName: string = "warehouse-article-details-dialog";
    templateUrl: string = "warehouse/templates";

    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService : IArticlesService;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService : IDesktopService;

    modal : { close: (result?: any) => void; };

    From : ko.Observable<Date> = ko.observable();
    To : ko.Observable<Date> = ko.observable();
    WarehouseId: ko.Observable<number> = ko.observable();
    CustomerId: ko.Observable<number> = ko.observable();

    DocumentsGroups : ko.ObservableArray<ArticleDocumentsGroup> = ko.observableArray([]);

    constructor(private articleId : number, articleCode: string, description : string, customerId: number = null, warehouseId: number = null)
    {
        this.title = String.format(ProlifeSdk.TextResources.Warehouse.ArticleAnalisys, articleCode, description);

        new CustomersProvider(this);
        new WarehousesProvider(this);

        this.CustomerId(customerId);
        this.WarehouseId(warehouseId);

        var from = moment(new Date());
        from.subtract("month", 3);
        from.startOf("month");
        this.From(from.toDate());
        this.To(new Date());
        this.Refresh();
    }

    close(): void
    {
        this.modal.close();
    }

    action(): void
    {
        this.modal.close();
    }

    Refresh()
    {
        var loadingId = this.desktopService.StartSaving(ProlifeSdk.TextResources.Warehouse.Loading);
        this.DocumentsGroups([]);
        this.articlesService.GetArticleDocumentsIntoPeriod(this.articleId, this.WarehouseId(), this.From(), this.To()).then((movements : IArticleDocumentMovement[]) => {
            var groups: ArticleDocumentsGroup[] = [];
            movements.forEach((m : IArticleDocumentMovement) => {
                var matches = groups.filter((g : ArticleDocumentsGroup) => { return g.EntityType == m.DocumentRowEntityType && g.RegisterId == m.RegisterId; });
                var group = matches.length > 0 ? matches[0] : new ArticleDocumentsGroup(m.DocumentRowEntityType, m.RegisterId, groups);
                group.AddRow(m);
            });
            this.DocumentsGroups(groups);
            this.desktopService.EndSaving(loadingId);
        });
    }

    findWarehouses(query : any) {}
    findWarehouse(element, callback) {}
    findCustomers(query : any) {}
    findCustomer(element, callback) {}

    public onCustomerSelected(e, newValue) {
        this.WarehouseId(undefined);
    }
}

export class ArticleDocumentsGroup
{
    public EntityType : string;
    public Documents : ko.ObservableArray<ArticleDocument> = ko.observableArray([]);
    public Title : string;
    public Collapsed : ko.Observable<boolean> = ko.observable(false);
    public RegisterId : number;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private registersManager : IVatRegisters;

    constructor(entityType : string, registerId : number, groupsCollection : ArticleDocumentsGroup[])
    {
        this.EntityType = entityType;
        this.RegisterId = registerId;
        if(entityType == ProlifeSdk.SalEntityTypeCode)
            this.Title = ProlifeSdk.TextResources.JobOrder.SAL;
        else
            this.Title = this.registersManager.getVatRegisterById(registerId).NomeRegistroIVA; //this.entitiesService.GetEntityDescriptor(entityType).PluralEntityName;
        groupsCollection.push(this);
    }

    public SwitchCollapsed()
    {
        this.Collapsed(!this.Collapsed());
    }

    public AddRow(row : IArticleDocumentMovement)
    {
        var matches = this.Documents().filter((d : ArticleDocument) => { return d.DocumentId == row.DocumentId; });
        var title = String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocument, 
            this.Title, 
            row.Number, 
            moment(row.Date).format("L"), 
            row.Customer, 
            (row.ReferenceNumber ? String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocumentReferenceNumberPart, " - ", row.ReferenceNumber) : ""), 
            (row.ReferenceDate ? String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocumentReferenceDatePart, (!row.ReferenceNumber ? " - " : ""), moment(row.ReferenceDate).format("L")) : ""));

        if (!row.SourceWarehouse && !row.DestinationWarehouse) {
            title += String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocumentWarehousePart);
        } else {
            if (row.SourceWarehouse)
                title += String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocumentSourceWarehousePart, row.SourceWarehouse);

            if (row.DestinationWarehouse)
                title += String.format(ProlifeSdk.TextResources.Warehouse.NewReferenceToDocumentDestWarehousePart, row.DestinationWarehouse);
        }

        var document = matches.length > 0 ? matches[0] : new ArticleDocument(this.EntityType, row.DocumentId, row.RegisterId, title, this.Documents);
        document.AddRow(row);
    }
}

export class ArticleDocument
{
    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    public DocumentId : number;
    public RegisterId : number;
    public Rows : ko.ObservableArray<IArticleDocumentMovement> = ko.observableArray([]);
    public Title : string = null;

    constructor(private entityType : string, documentId : number, registerId : number, title : string, documentsCollection : ko.ObservableArray<ArticleDocument>)
    {
        this.Title = title;
        this.DocumentId = documentId;
        this.RegisterId = registerId;
        documentsCollection.push(this);
    }

    public AddRow(row : IArticleDocumentMovement)
    {
        this.Rows.push(row);
    }

    public OpenDocumentDetail()
    {
        this.documentsService.OpenDocumentOverlay(this.entityType, this.DocumentId);
    }
}