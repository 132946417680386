import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../interfaces/desktop/IChangesNotificationsService";

export class EntityNotificationsManager implements IChangesNotificationsServiceObserver {
    private changesNotificationsService: IChangesNotificationsService;
    private infoToast: IInfoToastService;

    private updateCallback: (d: IObjectChangesInfo) => void;
    private deleteCallback: (d: IObjectChangesInfo) => void;
    private updateMessage: string = null;
    private deleteMessage: string = null;
    private skipMyUpdateMessage: boolean = true;
    private skipMyDeleteMessage: boolean = true;

    constructor(serviceLocator: IServiceLocator, private entityCode: string, private entityKeyId: number) {
        this.infoToast = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.changesNotificationsService = <IChangesNotificationsService>(
            serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType)
        );
        this.changesNotificationsService.ObserveNotificationsFor(entityCode, this);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (changesInfo.EntityCode != this.entityCode || changesInfo.EntityKeyId != this.entityKeyId) return false;

        if (changesInfo.Action == 2 && this.deleteCallback && (!sendByMe || !this.skipMyDeleteMessage)) {
            if (this.deleteMessage) this.infoToast.Info(this.FormatMessage(this.deleteMessage, changesInfo));
            this.deleteCallback(changesInfo);
        } else if (changesInfo.Action == 1 && this.updateCallback && (!sendByMe || !this.skipMyUpdateMessage)) {
            if (this.updateMessage) this.infoToast.Info(this.FormatMessage(this.updateMessage, changesInfo));
            this.updateCallback(changesInfo);
        }
        return false;
    }

    private FormatMessage(msg: string, changesInfo: IObjectChangesInfo): string {
        return msg.replace("$$USER$$", changesInfo.UserFullName);
    }

    public Dispose() {
        this.changesNotificationsService.RemoveObserver(this);
    }

    public OnUpdate(
        callback: (d: IObjectChangesInfo) => void,
        skipMine: boolean = true,
        message: string = null
    ): EntityNotificationsManager {
        this.updateCallback = callback;
        this.updateMessage = message;
        this.skipMyUpdateMessage = skipMine;
        return this;
    }

    public OnDelete(
        callback: (d: IObjectChangesInfo) => void,
        skipMine: boolean = true,
        message: string = null
    ): EntityNotificationsManager {
        this.deleteCallback = callback;
        this.deleteMessage = message;
        this.skipMyDeleteMessage = skipMine;
        return this;
    }
}
