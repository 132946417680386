import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ICustomersGroupsService, ICustomerGroup } from "../../../CustomersGroupsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";

export class CustomersGroupsEditingViewModel implements IView {
    public title: string = ProlifeSdk.TextResources.Customers.CustomersGroups;
    
    templateName: string = "customersGroupsEditor";
    templateUrl: string = "Customers/Templates/Settings";
    viewModel: any;

    @LazyImport(nameof<ICustomersGroupsService>())
    private customersGroupsService : ICustomersGroupsService;
    
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    public Groups : ko.ObservableArray<CustomerGroupViewModel> = ko.observableArray();
    public NewGroupName : ko.Observable<string> = ko.observable("");

    constructor() {
        this.viewModel = this;

        this.load();
    }

    async load() : Promise<void> {
        let groups = await this.customersGroupsService.GetCustomerGroups(null);
        this.Groups(groups.map(this.createViewModelFor, this));
    }

    private createViewModelFor(group : ICustomerGroup) : CustomerGroupViewModel {
        return new CustomerGroupViewModel(this, group);
    }

    public async newGroup() : Promise<void> {
        let newGroupName = (this.NewGroupName() || "").trim();
        this.NewGroupName("");

        if(newGroupName.length > 0) {
            await this.customersGroupsService.CreateOrUpdateGroup(0, newGroupName);
            await this.load();
        }
    }

    public async Save(oldName : string, newName: string, group : CustomerGroupViewModel) : Promise<void> {
        try
        {
            if(newName.trim().length > 0) {
                await this.customersGroupsService.CreateOrUpdateGroup(group.getId(), newName.trim());
            } else {
                await this.customersGroupsService.DeleteGroup(group.getId());
                this.Groups.remove(group);
            }
        }
        catch(ex)
        {
            this.infoToastService.Error(ex.ExceptionMessage);
            group.resetName(oldName);
        }
    }
}

export class CustomerGroupViewModel {
    Name : ko.Observable<string> = ko.observable();
    IsDefault : ko.Observable<boolean> = ko.observable();

    private disableAutoSave : boolean = false;

    constructor(private editor : CustomersGroupsEditingViewModel, private group? : ICustomerGroup) {
        if(!this.group) {
            this.group = {
                Id: 0,
                Name: "",
                IsDefault: false
            };
        }

        if(this.group) {
            this.Name(this.group.Name);
            this.IsDefault(this.group.IsDefault);
        }

        this.Name.subscribeChanged(this.onNameChanged.bind(this));
    }

    private onNameChanged(oldName : string, newName: string) {
        if(oldName == newName || this.disableAutoSave)
            return;

        this.editor.Save(oldName, newName, this);
    }

    resetName(name: string) {
        this.disableAutoSave = true;
        this.Name(name);
        this.disableAutoSave = false;
    }

    getId(): number {
        return this.group.Id;
    }
}