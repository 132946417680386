import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 30/06/2017
 * Time: 11:28
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { Questionnaire } from "../../Questionnaire";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISettingsService } from "../../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IQuestionnaireState, IQuestionnaire } from "../../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IQuestionnaireStateSettingManager } from "../../../../../interfaces/IQuestionnaireStateSettingManager";
import { IQuestionnaireCategorySettingManager } from "../../../../../interfaces/IQuestionnaireCategorySettingManager";
import { ICategory } from "../../../../../interfaces/ICategory";

export class QuestionnaireEditDialog implements IDialog {
    public templateName: string = "add-questionnaire-dialog";
    public templateUrl: string = "survey/templates/questionnaire/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.EditQuestionnaireDialogAddTitle;
    public modal: { close: (result?: any) => void; };

    public Questionnaire: ko.Observable<any> = ko.observable();
    public QuestionnaireStates: ko.ObservableArray<IQuestionnaireState> = ko.observableArray([]);
    public QuestionnaireCategories: ko.ObservableArray<ICategory> = ko.observableArray([]);

    public InsertMode: boolean = true;

    public IsNew: ko.Computed<boolean>;

    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;

    private OriginalData: IQuestionnaire = null;

    private statesManager: IQuestionnaireStateSettingManager;
    private categoryManager: IQuestionnaireCategorySettingManager;

    constructor(private serviceLocator: IServiceLocator, questionnaire: Questionnaire) {
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <IQuestionnaireStateSettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireState);
        this.categoryManager = <IQuestionnaireCategorySettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireCategory);

        if (!questionnaire) {
            this.Questionnaire(this.newEmptyQuestionnaire());
        }

        if (questionnaire) {
            this.title = ProlifeSdk.TextResources.Survey.EditQuestionnaireDialogEditTitle;
            this.Questionnaire(questionnaire);
            this.OriginalData = questionnaire.ToJSON();
            this.InsertMode = false;
        }

        var states: IQuestionnaireState[] = this.statesManager.getQuestionnaireStates();
        this.QuestionnaireStates(states);
        if (!this.Questionnaire().Id && states.length > 0) {
            this.Questionnaire().StateId(states[0].Id);
            this.Questionnaire().StateDescription(states[0].Description);
            this.Questionnaire().Locked(states[0].Locked);
        }
        if (this.Questionnaire().Id)
            this.Questionnaire().StateId(this.OriginalData.StateId);

        var categories: ICategory[] = this.categoryManager.getQuestionnaireCategories();
        this.QuestionnaireCategories(categories);
        if (!this.Questionnaire().Id && categories.length > 0) {
            this.Questionnaire().CategoryId(categories[0].Id);
            this.Questionnaire().CategoryName(categories[0].Name);
            this.Questionnaire().Icon(categories[0].Icon);
            this.Questionnaire().Background(categories[0].Background);
            this.Questionnaire().Foreground(categories[0].Foreground);
        }
        if (this.Questionnaire().Id)
            this.Questionnaire().CategoryId(this.OriginalData.CategoryId);

        this.IsNew = ko.computed(() => {
            let questionnaire = this.Questionnaire();
            return (questionnaire?.Id || 0) <= 0;
        });
    }

    action(): void {
        if (!this.Questionnaire().Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionnaireTitleRequired);
            return;
        }

        if (this.Questionnaire().Id) {
            this.OnQuestionnaireStateChange(this.Questionnaire().StateId());
            this.OnCategoryChange(this.Questionnaire().CategoryId());
            this.modal.close(this.Questionnaire());
            return;
        }
        
        this.surveyService.addQuestionnaire(this.Questionnaire().ToJSON())
            .then((questionnaire: IQuestionnaire) => {
                this.modal.close(new Questionnaire(questionnaire));
                return;
            })
            .catch(() =>{
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.SaveQuestionnaireError);
                return;
            });
    }

    close(): void {
        if (!this.InsertMode)
            this.resetChanges();
        this.modal.close();
    }

    private OnQuestionnaireStateChange(newState: number) {
        if (!newState) {
            this.Questionnaire().Locked(null);
            this.Questionnaire().StateDescription(null);
            return;
        }
        this.QuestionnaireStates().forEach((s: IQuestionnaireState) => {
            if(s.Id == newState) {
                this.Questionnaire().Locked(s.Locked);
                this.Questionnaire().StateDescription(s.Description);
            }
        });
    }

    private OnCategoryChange(newCategory: number) {
        if (this.QuestionnaireCategories().length > 0) {
            var category: ICategory = this.QuestionnaireCategories().filter((c: ICategory) => {
                return c.Id == newCategory;
            })[0];
            this.Questionnaire().CategoryName(category.Name);
            this.Questionnaire().CategoryDescription(category.Description);
            this.Questionnaire().Icon(category.Icon);
            this.Questionnaire().Background(category.Background);
            this.Questionnaire().Foreground(category.Foreground);
        }
    }

    private newEmptyQuestionnaire() {
        return new Questionnaire(
            <IQuestionnaire> {
                Id: null,
                Version: null,
                Title: "",
                Description: "",
                CreationDate: null,
                CreatorId: 0,
                ModifyDate: null,
                ModifierId: 0,
                Deleted: false,
                Icon: null,
                Background: null,
                Foreground: null,
                StateId: null,
                StateDescription: null,
                Locked: false,
                CategoryId: null,
                CategoryName: null,
                CategoryDescription: null
            }
        );
    }

    private resetChanges(): void {
        this.Questionnaire().Title(this.OriginalData.Title);
        this.Questionnaire().Description(this.OriginalData.Description);
        this.Questionnaire().CreationDate(this.OriginalData.CreationDate);
        this.Questionnaire().CreatorId(this.OriginalData.CreatorId);
        this.Questionnaire().ModifyDate(this.OriginalData.ModifyDate);
        this.Questionnaire().ModifierId(this.OriginalData.ModifierId);
        this.Questionnaire().Icon(this.OriginalData.Icon);
        this.Questionnaire().Background(this.OriginalData.Background);
        this.Questionnaire().Foreground(this.OriginalData.Foreground);
        this.Questionnaire().StateId(this.OriginalData.StateId);
        this.Questionnaire().StateDescription(this.OriginalData.StateDescription);
        this.Questionnaire().Locked(this.OriginalData.Locked);
        this.Questionnaire().CategoryId(this.OriginalData.CategoryId);
        this.Questionnaire().CategoryName(this.OriginalData.CategoryName);
        this.Questionnaire().CategoryDescription(this.OriginalData.CategoryDescription);
    }
}