import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { EditorFor } from "./ReportEditorProvider";
import { ReportComponent } from "../Components/ReportComponent";
import { ReportEditorProps } from "./ReportEditor";
import { ReportComponentDataSource, ReportComponentWithDataSource } from "../Components/ReportComponentWithDataSource";
import TsxForEach from "../../ForEach";
import { DataSourceItem } from "./DataSourceEditor/DataSourceItem";
import { If } from "../../IfIfNotWith";
import { DataSourceEditingDialog } from "./DataSourceEditor/DataSourceEditingDialog";
import { ReportDataSourceInput } from "./DataSourceEditor/ReportDataSourceInput";

const styleSheet = jss.createStyleSheet({
    dataSourceEditor: {
        "& .toolbar": {
            backgroundColor: "#eee",
            minHeight: '28px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },

        "& .list-group-item": {
            padding: '5px'
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDataSourceEditorProps = {
    component: ReportComponentWithDataSource & ReportComponent;
} & ReportEditorProps;

export function ReportDataSourceEditor(props: ReportDataSourceEditorProps) {
    const C = require("./ReportDataSourceEditor")._ReportDataSourceEditor as typeof _ReportDataSourceEditor;
    return <C {...props} />;
}

@EditorFor("DataSource")
export class _ReportDataSourceEditor {
    static defaultProps: Partial<ReportDataSourceEditorProps> = {
    }

    constructor(private props : ReportDataSourceEditorProps) {
        
    }

    private addDataSource() {
        const dialog = new DataSourceEditingDialog({
            inputDefinition: this.props.reportDesigner.getInputDefinition()
        });
        dialog.show()
            .then((dataSource) => {
                if(dataSource.id < 0) {
                    var nextId = this.props.component.dataSources().max(ds => ds.getId()) + 1;
                    dataSource.id = nextId;
                }

                this.props.component.dataSources.push(new ReportComponentDataSource(dataSource));
            })
    }

    private deleteDataSource(item: ReportComponentDataSource) {
        this.props.component.dataSources.remove(item);
    }
    
    render() {
        const { component } = this.props;

        return  <div class={ComponentUtils.classNames("editor-group", classes.dataSourceEditor)}>
                    <span class="editor-group-title">Fonti Dati</span>
                    <div className="editor-group-body" style={{ paddingTop: '5px'}}>
                        <div className="toolbar flex-container flex-child-center">
                            <button className="btn btn-primary btn-xs btn-circle" onClick={() => this.addDataSource()}>
                                <i className="fa fa-plus"></i>&nbsp;Aggiungi
                            </button>
                        </div>
                        <div className="list-group">
                            <TsxForEach data={component.dataSources} as="_dataSource">
                                {(item) => <DataSourceItem dataSource={item} onDelete={() => this.deleteDataSource(item)} />}
                            </TsxForEach>
                            <If condition={() => component.dataSources().length === 0}>
                                {() => <a href="javascript:void(0)" className="list-group-item">Nessuna Fonte Dati</a>}
                            </If>
                        </div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDataSourceEditor);
}