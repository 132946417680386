import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { Deferred } from "../../../../Core/Deferred";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class JobOrderForReport {
    Id: number;
    Name: string;
    Selected: ko.Observable<boolean> = ko.observable(false);
    Context: JobOrdersAnalysis;
    CustomerName: ko.Observable<any> = ko.observable(null);

    constructor(jsonData, private context: JobOrdersAnalysis) {
        this.Id = jsonData.JobOrderId;
        this.Name = jsonData.Name;
        this.Context = context;

        this.CustomerName(
            !jsonData.RagioneSociale ? ProlifeSdk.TextResources.JobOrder.NoCustomer : jsonData.RagioneSociale
        );

        /*if(jsonData.CustomerId != null)
            customersService.getCustomer(jsonData.CustomerId).then((c : ICustomer) => {
                this.CustomerName(c == null || c.RagioneSociale == null ? "Nessun cliente" : c.RagioneSociale);
            });*/
    }

    ChangeSelection() {
        this.Selected(!this.Selected());
        this.context.NotifyFilterChange();
    }
}

export class JobOrderAnalysis {
    Name: string;
    Invoices: number;
    Costs: number;
    Mlc: number;
    Tol = 0;
    Gu = 0;
    Ugu = 0;
    JobOrderId: number;

    constructor(jsonData) {
        this.JobOrderId = jsonData.JobOrderId;
        this.Name = jsonData.JobOrderName;
        this.Invoices = jsonData.InvoicesTotal;
        this.Costs = jsonData.TotalCosts;
        this.Tol = jsonData.WorkedHours;
        this.Mlc = this.Invoices - this.Costs;
        this.Gu = this.Tol / 8;
        this.Ugu = jsonData.Ugu;
    }
}

export class JobOrdersAnalysis extends ProLifeReport implements IReport, IReportViewModel {
    private jobOrderService: IJobOrderService;
    public customerSearchService: IControlsEntityProvider;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    Name: string = ProlifeSdk.TextResources.JobOrder.AnalizeOrders;
    templateName = "report-list-item";
    templateUrl = "reports/templates";
    detailsTemplateName = "job-orders-analysis";
    detailsTemplateUrl = "jobOrder/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    private alreadyLoadedJobOrders = 0;
    private isLoading: ko.Observable<boolean> = ko.observable(false);

    CustomerId: ko.Observable<number> = ko.observable();
    SearchFilter: ko.Observable<string> = ko.observable().extend({ throttle: 500 });
    JobOrders: ko.ObservableArray<JobOrderForReport> = ko.observableArray();
    ShowClosed: ko.Observable<boolean> = ko.observable(false);

    JobOrdersAnalysis: ko.ObservableArray<JobOrderAnalysis> = ko.observableArray([]);

    TotalInvoices: ko.Computed<number>;
    TotalCosts: ko.Computed<number>;
    TotalMlc: ko.Computed<number>;
    TotalTol: ko.Computed<number>;
    TotalGu: ko.Computed<number>;
    TotalUgu: ko.Computed<number>;

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(groupId, 3);

        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.customerSearchService = entityProviderService
            .getEntityProvider(ProlifeSdk.CustomerEntityType)
            .getControlsProvider();

        this.CustomerId.subscribe(this.ClearJobOrdersListAndRefresh.bind(this));
        this.ShowClosed.subscribe(this.ClearJobOrdersListAndRefresh.bind(this));
        this.SearchFilter.subscribe(this.ClearJobOrdersListAndRefresh.bind(this));

        this.TotalInvoices = ko.computed(() => {
            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Invoices;
            });
            return total;
        });

        this.TotalCosts = ko.computed(() => {
            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Costs;
            });
            return total;
        });

        this.TotalMlc = ko.computed(() => {
            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Mlc;
            });
            return total;
        });

        this.TotalTol = ko.computed(() => {
            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Tol;
            });
            return total;
        });

        this.TotalGu = ko.computed(() => {
            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Gu;
            });
            return total;
        });

        this.TotalUgu = ko.computed(() => {
            if (this.JobOrdersAnalysis().length == 0) return 0;

            let total = 0;
            this.JobOrdersAnalysis().forEach((a) => {
                total += a.Ugu;
            });
            total = total / this.JobOrdersAnalysis().length;
            return total;
        });
    }

    RemoveAnalysis(analysis) {
        this.JobOrdersAnalysis.remove(analysis);
        const matches = this.JobOrders().filter((j) => {
            return j.Id == analysis.JobOrderId;
        });
        if (matches.length > 0) matches[0].Selected(false);
    }

    SwitchSelectionForAll() {
        if (this.isLoading()) return;

        const selectedIds = this.JobOrders()
            .filter((j) => {
                return j.Selected();
            })
            .map((j) => {
                return j.Id;
            });
        this.JobOrders([]);
        this.LoadJobOrders(true).then(() => {
            const unselected = this.JobOrders().filter((j) => {
                return selectedIds.indexOf(j.Id) == -1;
            });

            this.JobOrders().forEach((j) => {
                j.Selected(unselected.length > 0);
            });
        });
        this.NotifyFilterChange();
    }

    SwitchClosedVisibility() {
        if (this.isLoading()) return;

        this.ShowClosed(!this.ShowClosed());
    }

    private ClearJobOrdersListAndRefresh() {
        const selectedIds = this.JobOrdersAnalysis().map((j) => {
            return j.JobOrderId;
        });
        this.JobOrders([]);
        this.alreadyLoadedJobOrders = 0;
        this.LoadJobOrders(false).then(() => {
            this.JobOrders().forEach((j) => {
                if (selectedIds.indexOf(j.Id) > -1) j.Selected(true);
            });
        });
    }

    private GetNextJobOrdersListPart() {
        const selectedIds = this.JobOrdersAnalysis().map((j) => {
            return j.JobOrderId;
        });
        this.LoadJobOrders(false).then(() => {
            this.JobOrders().forEach((j) => {
                if (selectedIds.indexOf(j.Id) > -1) j.Selected(true);
            });
        });
    }

    private LoadJobOrders(loadAll: boolean): Promise<void> {
        const def = new Deferred<void>();

        if (this.isLoading()) return def.resolve().promise();

        const logicalStates = [0];
        if (this.ShowClosed()) logicalStates.push(1);

        if (loadAll) this.alreadyLoadedJobOrders = 0;

        this.isLoading(true);

        this.jobOrderService
            .GetJobOrdersList(-1, 1, 0, false, this.SearchFilter(), this.CustomerId())
            .then((jobOrders) => {
                this.alreadyLoadedJobOrders += jobOrders.length;

                jobOrders.forEach((j) => {
                    const matches = this.JobOrders().filter((j1) => {
                        return j1.Id == j.JobOrderId;
                    });
                    if (matches.length > 0) return;

                    this.JobOrders.push(new JobOrderForReport(j, this));
                });
                def.resolve();
            })
            .catch(() => {
                def.reject([]);
            })
            .finally(() => {
                this.isLoading(false);
            });

        /*this.jobOrderService.detailedSearch([<IJobOrderFilter>{ HintSearch : this.SearchFilter(), LogicalStateIds : logicalStates, CustomerId : this.CustomerId(),TypesIds : [1] }],
                this.alreadyLoadedJobOrders, loadAll ? -1 : 50).then((jobOrders) => {
            this.isLoading(false);
            this.alreadyLoadedJobOrders += jobOrders.length;

            jobOrders.forEach((j) => {
                var matches = this.JobOrders().filter((j1) => { return j1.Id == j.JobOrderId; });
                if(matches.length > 0)
                    return;

                this.JobOrders.push(new JobOrderForReport(this.serviceLocator, j, this));
            });
            def.resolve([]);
        });*/

        return def.promise();
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        const analysis = [];

        const selectedIds: number[] = this.JobOrders()
            .filter((j) => {
                return j.Selected();
            })
            .map((j) => {
                return j.Id;
            });
        this.JobOrdersAnalysis().forEach((a) => {
            const matches = this.JobOrders().filter((j) => {
                return j.Id == a.JobOrderId;
            });
            if (matches.length == 0) selectedIds.push(a.JobOrderId);
        });

        this.jobOrderService
            .getJobOrdersAnalysis(selectedIds)
            .then((jobOrdersAnalysis) => {
                jobOrdersAnalysis.forEach((a) => {
                    analysis.push(new JobOrderAnalysis(a));
                });

                this.JobOrdersAnalysis(analysis);
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    initialize() {
        super.initialize();
        this.JobOrders([]);
        this.GetNextJobOrdersListPart();
    }

    public ExportAsPdf(versionId: number) {
        let ids = "";
        const selectedIds: number[] = this.JobOrdersAnalysis().map((j) => {
            return j.JobOrderId;
        });
        selectedIds.forEach((c: number) => {
            if (ids.length > 0) ids += "_";
            ids += c.toString();
        });

        const url: string = "JobOrder/JobOrdersAnalysisReportPrint/GeneratePdf?versionId=" + versionId + "&ids=" + ids;

        this.ajaxService.DownloadFileFromUrl(encodeURI(url), {});
    }

    public ExportAsExcel(versionId: number) {
        let ids = "";
        const selectedIds: number[] = this.JobOrdersAnalysis().map((j) => {
            return j.JobOrderId;
        });

        selectedIds.forEach((c: number) => {
            if (ids.length > 0) ids += "_";
            ids += c.toString();
        });

        const url: string =
            "JobOrder/JobOrdersAnalysisReportPrint/GenerateExcel?versionId=" + versionId + "&ids=" + ids;

        this.ajaxService.DownloadFileFromUrl(encodeURI(url), {});
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}
