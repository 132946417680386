import * as ko from "knockout";
/*export interface IArrayDataSourceModel<T> extends IDataSourceModel<number, T> {

}*/

import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";

export type TitleGetterCallback<T> = (currentModel: IArrayDataSourceModel<T>) => string;

export type IArrayDataSourceModel<T> = IDataSourceModel<number, T>;

export interface IArrayDataSource<T> extends IDataSource<number, T> {
    setTitleGetter?(getter: TitleGetterCallback<T>): void;
}

export interface IArrayDataSourceDraggedItem<T> {
    sourceDataSourceId: number;
    model: IArrayDataSourceModel<T>
}

export interface IArrayDataSourceListener<T> {
    onItemAdded?(sender : IArrayDataSource<T>, model : IArrayDataSourceModel<T>) : void;
    onItemMoved?(sender : IArrayDataSource<T>, model : IArrayDataSourceModel<T>) : Promise<void>;
}

export class ArrayDataSource<T> extends BaseDataSource<IArrayDataSourceModel<T>, number> implements IArrayDataSource<T> {
    private data : IArrayDataSourceModel<T>[] = [];
    private mimeTypes : string[] = [];
    private defaultMimeType: string;
    private id : number = Math.random() * 1000000;
    private listeners : IArrayDataSourceListener<T>[] = [];

    private titleGetter: TitleGetterCallback<T> = (currentModel) => "";

    addListener(listener : IArrayDataSourceListener<T>) {
        this.listeners.push(listener);
    }

    setTitleGetter(getter: TitleGetterCallback<T>): void {
        if (!getter)
            return;

        this.titleGetter = getter;
    }

    getTitle(currentModel: IArrayDataSourceModel<T>): string {
        return this.titleGetter(currentModel);
    }    
    
    isGroupedData(currentModel: IArrayDataSourceModel<T>, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IArrayDataSourceModel<T>, b: IArrayDataSourceModel<T>): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IArrayDataSourceModel<T>, textFilter: string, skip: number, count: number): Promise<IArrayDataSourceModel<T>[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);

        return this.data
            .filter(d => splitFilters.filter(w => d.title.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, Math.min(skip + count, this.data.length));
    }
    
    async getById(currentModel: IArrayDataSourceModel<T>, ids: number[]): Promise<IArrayDataSourceModel<T>[]> {
        return this.data
            .filter(d => ids.indexOf(d.id) != -1);
    }

    setData(...items: IArrayDataSourceModel<T>[]) {
        this.data = items.slice();
    }

    /**
     * Returns a copy of the internal data
     */
    getAllData() {
        return this.data.slice();
    }

    addItem(model : IArrayDataSourceModel<T>): void {
        this.data.push(model);
        this.refresh();
    }

    removeItem(model : IArrayDataSourceModel<T>) {
        let index = this.data.findIndex(d => this.areEqual(d, model));
        if(index == -1) return;

        this.data.splice(index, 1);
        this.refresh();
    }

    setSupportedDropMimeTypes(...mimeTypes : string[]) {
        this.mimeTypes = mimeTypes.slice();
    }

    setDefaultMimeType(mimeType : string) {
        this.defaultMimeType = mimeType;
    }

    getDefaultMimeType() {
        return this.defaultMimeType;
    }
    
    getSupportedDropMimeTypes(): string[] {
        return this.mimeTypes;
    }
    
    onItemBeginMove(model: IArrayDataSourceModel<T>, dataTransfer: DataTransfer) {
        let dragged : IArrayDataSourceDraggedItem<T> = {
            sourceDataSourceId: this.id,
            model: model
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData(this.defaultMimeType, JSON.stringify(dragged));
        dataTransfer.dropEffect = "move";
    }
    
    async onItemMoved(dataTransfer: DataTransfer, neighbourItem: IArrayDataSourceModel<T>, before: boolean) : Promise<void> {
        if(dataTransfer.types.indexOf(this.defaultMimeType) >= 0) {
            let droppedItem = <IArrayDataSourceDraggedItem<T>>JSON.parse(dataTransfer.getData(this.defaultMimeType));
            
            let modelIndex = this.data.indexOf(neighbourItem);
            let newIndex = before ? modelIndex : modelIndex + 1;

            if(droppedItem.sourceDataSourceId == this.id) { //Riordinamento
                let oldIndex = this.data.findIndex(d => this.areEqual(d, droppedItem.model));
                let newIndex = before ? modelIndex : modelIndex + 1;

                let cutOut = this.data.splice(oldIndex, 1)[0];
                this.data.splice(newIndex, 0, cutOut);
                
                this.notifyItemMoved(droppedItem.model);
                
            } else {
                if(this.data.filter(d => this.areEqual(d, droppedItem.model)).length != 0) {
                    dataTransfer.dropEffect = "none";
                    return;
                }
    
                this.data.splice(newIndex, 0, droppedItem.model);
                this.notifyItemAdded(droppedItem.model);
            }

            this.refresh();
        }
    }

    private notifyItemAdded(model : IArrayDataSourceModel<T>) {
        for(let listener of this.listeners)
            if(listener.onItemAdded) listener.onItemAdded(this, model);
    }

    private notifyItemMoved(model : IArrayDataSourceModel<T>) {
        for(let listener of this.listeners)
            if(listener.onItemMoved) listener.onItemMoved(this, model);
    }
}