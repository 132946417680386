import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IBaseDocumentForWizard, IBaseDocumentRowForWizard } from "../../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { DocumentDataWizardStep } from "../DocumentDataWizardStep";
import { IDocumentDataWizardRow } from "../ImportDocumentDataWizard";
import { RowsImportModeDialog } from "./RowsImportModeDialog";
import { DocumentImportModes } from "./DocumentImportModes";

export abstract class DocumentDataSourceDataWizardStep<T = IDocumentDataWizardRow, K extends IBaseDocumentRowForWizard = IBaseDocumentRowForWizard> extends DocumentDataWizardStep<T> {
    public SelectedDocuments : ko.ObservableArray<IBaseDocumentForWizard<K>> = ko.observableArray([]);

    constructor() {
        super();
    }

    private GetSelectedRows() : K[]
    {
        return this.SelectedDocuments()
            .selectMultiple(d => d.Rows())
            .filter(r => r.Selected());
    }

    public async importSelectedRows(): Promise<void>
    {
        const selectedRows : K[] = this.GetSelectedRows();

        if (selectedRows.length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.NoRowToImport);
            return;
        }

        const alreadyImportedRows = selectedRows.filter((r: K) => (r.wasAlreadyImportedInADocument() && !r.wasPreviouslyImportedInThisDocument()) || r.ManuallyClosed());

        let importMode = 1;
        if (alreadyImportedRows.length > 0) {
            const modal = new RowsImportModeDialog({});
            importMode = await this.dialogsService.ShowModal(modal);
        }

        if (importMode === DocumentImportModes.NoImport)
            return;

        if (importMode === DocumentImportModes.UnitAmount) {
            for (const row of alreadyImportedRows) {
                row.setAmountAndUpdateTotal(1);
            }
        }

        const selectedDocuments = this.SelectedDocuments();
        await this.ManageCurrenciesForImportedDocuments(selectedDocuments);
        
        let someRowsAlreadyImported  = false;
        const importableDocuments = selectedDocuments.filter(d => d.canBeImported());
        importableDocuments.forEach((doc: IBaseDocumentForWizard<K>) => { someRowsAlreadyImported = doc.doImportSelectedRows() || someRowsAlreadyImported;});

        if (someRowsAlreadyImported)
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.RowsAlreadyImported);

        const someDocumentsCannotBeImported = importableDocuments.length !== selectedDocuments.length;

        this.onImportSelectedRows(someDocumentsCannotBeImported);
    }

    protected abstract onImportSelectedRows(someDocumentsCannotBeImported: boolean): void;
}