import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/05/2018
 * Time: 18:33
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import { Moment } from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { CustomerViewModel } from "./CustomerViewModel";
import { CustomerTrustViewModel } from "./CustomerTrustViewModel";
import { TrustMovementViewModel } from "./TrustMovementViewModel";
import { TrustManagerErrorsDialog } from "./dialogs/TrustManagerErrorsDialog";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import {
    ITrustMovementsService,
    ITrustMovementDocumentInfo,
} from "../../../ProlifeSdk/interfaces/customer/ITrustMovementsService";
import { ITrustsService, ITrustsValidationResult } from "../../../ProlifeSdk/interfaces/customer/ITrustsService";
import { ITrustManager } from "../../interfaces/ITrustManager";
import { ICustomerTrustViewModel } from "../../interfaces/ICustomerTrustViewModel";
import { ITrustsSettingsManager } from "../../../ProlifeSdk/interfaces/customer/ITrustsSettingsManager";
import { ICustomerTrust } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { Deferred } from "../../../Core/Deferred";

export class TrustManager implements ITrustManager {
    public Trusts: ko.ObservableArray<CustomerTrustViewModel> = ko.observableArray([]);
    public Movements: ko.ObservableArray<TrustMovementViewModel> = ko.observableArray([]);
    public HasExtraTrustApprovingRights: ko.Observable<boolean> = ko.observable();
    public Balance: ko.Observable<number> = ko.observable();
    public StartDocumentsEmissionDateForTrustMovements: ko.Observable<Date> = ko.observable();
    public StartDocumentsEmissionDateForTrustMovementsFromGeneralSettings: ko.Observable<boolean> = ko.observable();

    public HasIllimitatedTrust: ko.Observable<boolean> = ko.observable(false);

    public ShowExpiredTrusts: ko.Observable<boolean> = ko.observable(false);

    public VisibleTrusts: ko.Computed<ICustomerTrustViewModel[]>;
    public CanShowViewMode: ko.Computed<boolean>;
    public CanShowEditMode: ko.Computed<boolean>;
    public HasChanges: ko.Computed<boolean>;
    public Editable: ko.Observable<boolean> = ko.observable(false);
    public ShowLoadMoreMovements: ko.Observable<boolean> = ko.observable(true);
    public IsLoadingMovements: ko.Observable<boolean> = ko.observable(false);

    public ShowLoadMoreTrusts: ko.Observable<boolean> = ko.observable(true);
    public IsLoadingTrusts: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    @LazyImport(nameof<ITrustMovementsService>())
    private trustMovementsService: ITrustMovementsService;
    @LazyImport(nameof<ITrustsService>())
    private trustsService: ITrustsService;

    @LazyImportSettingManager(ProlifeSdk.TrustsSettingsManager)
    private trustsSettings: ITrustsSettingsManager;

    private isLoading = false;
    private lastTemporaryId = 0;

    constructor(private customer: CustomerViewModel) {
        this.HasExtraTrustApprovingRights(this.authorizationsService.isAuthorized("Customers_CanApproveExtraTrust"));

        this.CanShowViewMode = ko.computed(() => {
            return !this.Editable() && this.authorizationsService.isAuthorized("Customers_CanViewCustomerTrust");
        });

        this.CanShowEditMode = ko.computed(() => {
            return this.Editable() && this.authorizationsService.isAuthorized("Customers_CanEditCustomerTrust");
        });

        this.ShowExpiredTrusts.subscribe(() => {
            this.ShowLoadMoreTrusts(true);
        });

        this.VisibleTrusts = ko.computed(() => {
            const trusts: ICustomerTrustViewModel[] = this.Trusts();
            const visibleTrusts: ICustomerTrustViewModel[] = [];

            for (let i = 0; i < trusts.length; i++) {
                if (!trusts[i].Deleted() && (this.ShowExpiredTrusts() || !trusts[i].IsExpired()))
                    visibleTrusts.push(trusts[i]);
            }
            return visibleTrusts;
        });

        this.HasChanges = ko.computed(() => {
            const lengthVariations =
                this.Trusts().length != this.Trusts().filter((t: ICustomerTrustViewModel) => !t.Deleted()).length;
            const changedTrusts: ICustomerTrustViewModel[] = [];

            const trusts: ICustomerTrustViewModel[] = this.Trusts();

            for (let i = 0; i < trusts.length; i++) {
                if (trusts[i].IsChanged()) changedTrusts.push(trusts[i]);
            }

            return lengthVariations || changedTrusts.length > 0;
        });
    }

    public Initialize(): void {
        if (!this.isLoading) {
            this.isLoading = true;
            this.Trusts([]);
            this.Movements([]);

            this.StartDocumentsEmissionDateForTrustMovementsFromGeneralSettings(
                !this.customer.StartDocumentsEmissionDateForTrustMovements()
            );
            if (!this.customer.StartDocumentsEmissionDateForTrustMovements())
                this.customer.StartDocumentsEmissionDateForTrustMovements(
                    this.trustsSettings.GetStartDocumentsEmissionDateForTrustMovements()
                );

            this.StartDocumentsEmissionDateForTrustMovements(
                this.customer.StartDocumentsEmissionDateForTrustMovements()
            );

            const defs: Promise<void>[] = [];
            defs.push(this.LoadTrusts());
            defs.push(this.LoadTrustMovements());
            defs.push(this.LoadBalance());

            Promise.all(defs).finally(() => {
                this.isLoading = false;
            });
        }
    }

    public LoadTrustMovements(): Promise<void> {
        const def = new Deferred<void>();

        if (this.IsLoadingMovements()) return def.resolve().promise();

        this.IsLoadingMovements(true);

        this.trustMovementsService
            .GetPagedTrustsMovements(this.customer.IdCliente(), this.Movements().length, 30)
            .then((movements: ITrustMovementDocumentInfo[]) => {
                this.Movements(
                    this.Movements().concat(
                        movements.map((m: ITrustMovementDocumentInfo) => this.CreateTrustMovementViewModel(m))
                    )
                );
                this.ShowLoadMoreMovements(movements.length > 0);
            })
            .finally(() => {
                setTimeout(() => {
                    this.IsLoadingMovements(false);
                    def.resolve();
                }, 500);
            });

        return def.promise();
    }

    public LoadTrusts(): Promise<void> {
        const def = new Deferred<void>();

        if (this.IsLoadingTrusts()) return def.resolve().promise();

        this.IsLoadingTrusts(true);

        if (
            !this.ShowExpiredTrusts() &&
            this.Trusts().length > 0 &&
            this.Trusts()[this.Trusts().length - 1].IsExpired()
        ) {
            this.IsLoadingTrusts(false);
            this.ShowLoadMoreTrusts(false);
            return def.resolve().promise();
        }

        this.trustsService
            .GetPagedTrusts(this.customer.IdCliente(), this.Trusts().length, 30)
            .then((trusts: ICustomerTrust[]) => {
                this.Trusts(
                    this.Trusts().concat(trusts.map((t: ICustomerTrust) => this.CreateCustomerTrustViewModel(t)))
                );
                this.ShowLoadMoreTrusts(trusts.length > 0);
            })
            .finally(() => {
                setTimeout(() => {
                    this.IsLoadingTrusts(false);
                    def.resolve();
                }, 500);
            });

        return def.promise();
    }

    public NewTrust(): void {
        this.Trusts.unshift(this.CreateCustomerTrustViewModel(this.CreateEmptyTrust()));
    }

    public SetEditMode(editable: boolean): void {
        this.Editable(editable);
    }

    public GetData(): ICustomerTrust[] {
        return this.Trusts()
            .filter((t) => t.IsChanged())
            .map((t: ICustomerTrustViewModel) => t.GetData());
    }

    public IsValid(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.trustsService
            .ValidateTrusts(this.GetData(), this.customer.IdCliente())
            .then((validationResult: ITrustsValidationResult) => {
                if (validationResult.Errors.length == 0) {
                    def.resolve(true);
                    return;
                }

                if (!validationResult.TrustsDataAreValid) {
                    this.ShowErrorsAlert(validationResult.Errors).then(() => {
                        def.resolve(false);
                    });
                    return;
                }

                if (!validationResult.TrustBalanceIsValid)
                    this.ShowTrustOverflowAlert().then((result: boolean) => {
                        def.resolve(result);
                    });
            });

        return def.promise();
    }

    public LoadBalance(): Promise<void> {
        const def = new Deferred<void>();

        Promise.all([this.GetActualTrust(), this.trustsService.GetBalance(this.customer.IdCliente())])
            .then(([trust, balance]) => {
                if (!trust && this.trustsSettings.GetConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrustSetting())
                    this.HasIllimitatedTrust(true);

                this.Balance(balance);

                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    public GetActualTrust(): Promise<ICustomerTrust> {
        const def = new Deferred<ICustomerTrust>();

        const trusts: ICustomerTrustViewModel[] = this.VisibleTrusts().filter((t: ICustomerTrustViewModel) => {
            return t.IsActualTrust();
        });
        if (trusts.length > 0) return def.resolve(trusts[0].GetData()).promise();

        this.customersService.GetActualTrust(this.customer.IdCliente()).then((trust: ICustomerTrust) => {
            def.resolve(trust);
        });

        return def.promise();
    }

    public GetValidationWarnings(): Promise<string[]> {
        const def = new Deferred<string[]>();
        const alerts: string[] = [];

        this.GetActualTrust().then((trust: ICustomerTrust) => {
            if (!trust) alerts.push(ProlifeSdk.TextResources.Customers.ActualTrustNotConfigured);

            def.resolve(alerts);
        });

        return def.promise();
    }

    public ShowAlertIfActualTrustNotConfigured(): boolean {
        return this.trustsSettings.GetShowAlertOnSaveIfTrustIsNotConfigured();
    }

    private ShowErrorsAlert(errors: string[]): Promise<boolean> {
        const def = new Deferred<boolean>();
        let message = "";
        errors.forEach((m: string) => {
            message = message + m + "</br>";
        });

        this.dialogsService.Alert(message, ProlifeSdk.TextResources.Customers.TrustValidationMessageLabel, () => {
            def.resolve(false);
        });

        return def.promise();
    }

    private ShowTrustOverflowAlert(): Promise<boolean> {
        const def = new Deferred<boolean>();

        if (!this.HasExtraTrustApprovingRights()) {
            this.ShowExtraTrustAlert(
                ProlifeSdk.TextResources.Customers.CanNotApproveExtraTrustDialogMessage,
                true
            ).then(() => {
                def.resolve(false);
            });
            return def.promise();
        }

        this.ShowExtraTrustAlert(ProlifeSdk.TextResources.Customers.ApproveExtraTrustDialogMessage, false).then(
            (result: boolean) => {
                def.resolve(result);
            }
        );

        return def.promise();
    }

    private ShowExtraTrustAlert(promptMessage, locking = false): Promise<boolean> {
        const def = new Deferred<boolean>();
        if (!locking) {
            this.dialogsService.Confirm(
                promptMessage,
                ProlifeSdk.TextResources.Customers.CancelButton,
                ProlifeSdk.TextResources.Customers.ConfirmButton,
                (confirm: boolean) => {
                    def.resolve(confirm);
                }
            );
            return def.promise();
        }

        this.dialogsService.Alert(promptMessage, ProlifeSdk.TextResources.Customers.TrustOverflowErrorLabel, () => {
            def.resolve(false);
        });

        return def.promise();
    }

    private ShowValidationErrors(trustsIntersections: ICustomerTrust[]): Promise<boolean> {
        const dialog = new TrustManagerErrorsDialog(trustsIntersections);
        return this.dialogsService.ShowModal<boolean>(dialog, "large", { noPrompt: true });
    }

    private GetTrustsIntersections(): ICustomerTrust[] {
        let trusts: ICustomerTrustViewModel[] = this.VisibleTrusts().filter((t: ICustomerTrustViewModel) =>
            t.IsChanged()
        );

        //Limito il controllo sull'accavallamento dei fidi solamente a quei fidi che hanno una data di fine maggiore o uguale alla data d'inizio del fido che inizia prima tra quelli modificati
        let minDate: Moment = moment("2100-01-01");
        trusts.forEach((t: ICustomerTrustViewModel) => {
            if (moment(t.StartDate()) < minDate) minDate = moment(t.StartDate());
        });
        trusts = this.VisibleTrusts().filter(
            (t: ICustomerTrustViewModel) =>
                moment(!t.EndDate() ? <any>"2100-01-01" : t.EndDate()).valueOf() >= minDate.valueOf()
        );

        return this.InternalGetTrustsIntersections(trusts);
    }

    private InternalGetTrustsIntersections(trusts: ICustomerTrustViewModel[]): ICustomerTrust[] {
        const intersections: ICustomerTrust[] = [];

        for (let i = 0; i < trusts.length - 1; i++) {
            for (let j = i + 1; j < trusts.length; j++) {
                if (trusts[i].Intersect(trusts[j])) {
                    var trust = trusts[i].GetData();
                    if (intersections.filter((t) => trust.Id == t.Id).length == 0) intersections.push(trust);
                    trust = trusts[j].GetData();
                    if (intersections.filter((t) => trust.Id == t.Id).length == 0) intersections.push(trust);
                }
            }
        }

        return intersections;
    }

    private CreateTrustMovementViewModel(movement: ITrustMovementDocumentInfo): TrustMovementViewModel {
        return new TrustMovementViewModel(movement);
    }

    private CreateCustomerTrustViewModel(trust: ICustomerTrust): CustomerTrustViewModel {
        return new CustomerTrustViewModel(trust, this);
    }

    private GetFirstAvailableDate(): Date {
        const trusts: ICustomerTrustViewModel[] = this.VisibleTrusts();

        if (trusts.length == 0) return moment().startOf("day").toDate();

        let maxEndDate: Moment = moment("1900-01-01").endOf("day");

        trusts.forEach((t: ICustomerTrustViewModel) => {
            const trustEndDate = moment(!t.EndDate() ? t.StartDate() : t.EndDate());
            if (trustEndDate > maxEndDate) maxEndDate = trustEndDate;
        });

        return maxEndDate.add("milliseconds", 1).toDate();
    }

    private CreateEmptyTrust(): ICustomerTrust {
        return {
            Id: this.lastTemporaryId--,
            CustomerId: this.customer.IdCliente(),
            StartDate: this.GetFirstAvailableDate(),
            EndDate: null,
            CreatorId: null,
            CreationDate: null,
            ModifierId: null,
            ModifyDate: null,
            Value: 0,
            InsuranceTrust: 0,
            Note: null,
            //Balance: 0
        };
    }
}
