import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { BackButton } from "../../../Components/BackButton";
import { Layout } from "../../../Components/Layouts";
import { Portlet, PortletActions, PortletBody, PortletHeader } from "../../../Components/Portlet";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { IFunctionPointComputationService, IInfrastructure, IInfrastructureEnvelope, IInfrastructureRequirement, IInfrastructureRequirementEnvelope } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { FunctionPointComputationRequirementEditor } from "./FunctionPointComputationRequirementEditor";
import { FunctionPointComputationRequirementList } from "./FunctionPointComputationRequirementList";
import { Requirement } from "./Requirement";
import { List } from "../../../Components/ListComponent";
import { Table } from "../../../Components/TableComponent/TableComponent";


const styleSheet = jss.createStyleSheet({
    featureEditor: {
        "& > div": {
            padding: "15px",
        }
    }
}).attach();

const { classes } = styleSheet;

type RequirementEditorProps = {
    onBack: () => void;

    infrastructureId: number;
}

type IInfrastructureRequirementEx = IInfrastructureRequirementEnvelope & {
    // Aggiungere proprietà che vengono create nel componente
}

export function RequirementEditor(props: RequirementEditorProps) {
    const C = require("./RequirementEditor")._RequirementEditor as typeof _RequirementEditor;
    return <C {...props} />;
}

export class _RequirementEditor {
    static defaultProps: Partial<RequirementEditorProps> = {
    }


    @LazyImport(nameof<IFunctionPointComputationService>())
    private functionPointComputationService: IFunctionPointComputationService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    infrastructure: IInfrastructure;
    requirements: ko.ObservableArray<IInfrastructureRequirementEx> = ko.observableArray();
    requirement: ko.Observable<Requirement> = ko.observable();

    constructor(private props: RequirementEditorProps) {

    }

    componentDidiMount() {
        this.loadRequirements();
    }

    private async loadRequirements() {
        const infrastructure = await this.functionPointComputationService.getInfrastructureEnvelopeById(this.props.infrastructureId);
        const requirements = await this.functionPointComputationService.getRequirements(this.props.infrastructureId);
        this.requirements(requirements.map(this.createRequirement, this));
    }

    createRequirement(requirement: IInfrastructureRequirement): IInfrastructureRequirementEx {
        return {
            ...requirement,
        }
    }

    onBackButtonClick() {
        this.props.onBack();
    }

    onNewRequirement() {
        //this.requirement(new Requirement(-1));
    }

    addRequirement(r: IInfrastructureRequirement) {
        let existingFeature = this.requirements().findIndex((rq) => rq.Id === r.Id);
        if (existingFeature >= 0) {
            var oldFeature = this.requirements()[existingFeature];
            this.requirements.replace(oldFeature, r);
        }
        else {
            this.requirements.push(r);
        }
    }

    async removeRequirement(r: IInfrastructureRequirement): Promise<void> {
/*         let confirm: boolean = await this.dialogsService.ConfirmAsync("Desideri eliminare il requisito: " + r.requirementId + " ?", "Annulla", "Conferma")
        if (confirm) {
            this.requirements.remove(r);
            this.infoToastService.Success("Requisito eliminato con successo"); 
        }*/
    }

    render() {
            let dataSource = Table.defaultDataSource( this.requirements, (r)=>({ id: r.Id, title: r.RequirementId, subTitle: r.RequirementDescription}));
        return <Layout.Grid className={classes.featureEditor} columns={["1fr", "2fr"]} rows={["auto", "1fr"]}>
            <Layout.Grid.Cell column={"1/3"} row={1}>
                <Portlet collapsible={false}>
                    <Portlet.Header>
                        <BackButton style={{ marginRight: "20px" }} onClick={() => this.onBackButtonClick()} />
                        <Portlet.Header.Default title={"Gestione Requisiti"} />
                    </Portlet.Header>
                </Portlet>
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={1} row={2}>
        <Portlet collapsible={false}>
                    <PortletHeader>
                        <PortletHeader.Default title={"Requisiti"}></PortletHeader.Default>
                    </PortletHeader>
                    <PortletActions>
                        <button className={"btn btn-circle btn-icon-only btn-default"} onClick={this.onNewRequirement}>
                            <i className={"fa fa-plus"}/>
                        </button>
                    </PortletActions>
                    <PortletBody>
                        {()=> <List dataSource={dataSource} scrollable/>}
                    </PortletBody>
                </Portlet>    
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={2} row={2} >
                <Layout.ScrollContainer>
                    <FunctionPointComputationRequirementEditor requirement={this.requirement} onSave={this.addRequirement.bind(this)} onDelete={this.removeRequirement.bind(this)} />
                </Layout.ScrollContainer>
            </Layout.Grid.Cell>
        </Layout.Grid>
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(RequirementEditor);
}