import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/12/2018
 * Time: 12:04
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { INationsProvider, INationsSettingsManager, INation } from "../../interfaces/settings/INationsSettingsManager";
import { ISelect2Query, ISelect2Result } from "../../interfaces/prolife-sdk/providers/ISelect2Provider";

export class NationsProvider implements INationsProvider {
    public Values: ko.Observable<string> = ko.observable();
    public SelectedNationCode: ko.Observable<any> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    private nationsSettingsManager: INationsSettingsManager;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor() {
    }

    Init(element, callback) {
        var alpha2Code: string = <string> $(element).val();
        if (!!alpha2Code) {
            var nation = this.nationsSettingsManager.getNationByAplpha2Code(alpha2Code);
            if (!nation){
                callback(null);
                return;
            }
            callback({ id: nation.Alpha2Code, text: nation.Name })
        }
    }

    Search(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.internalSearch(query);
        }, 500);
    }

    EscapeMarkup(markup) {
        return markup;
    }

    public GetFormattedNameForList(data: any): string {
        return "<div style=\"display: inline-block;\"><span>" + data.id + "</span>&nbsp;-&nbsp;<span>" + data.text + "</span></div>";
    }

    public GetFormattedNameForSelection(data: any): string {
        return "<span>" + data.id + "</span>&nbsp;-&nbsp;<span>" + data.text + "</span>";
    }

    public GetFormattedCodeForSelection(data: any): string {
        return "<span>" + data.id + "</span>";
    }

    private internalSearch(query: ISelect2Query) {
        var matches: INation[] = !query.term ?
            this.nationsSettingsManager.getNationsList() :
            this.nationsSettingsManager.getNationsList().filter((n) => n.Alpha2Code.toLocaleLowerCase().indexOf(query.term.toLocaleLowerCase()) > -1 || n.Name.toLocaleLowerCase().indexOf(query.term.toLocaleLowerCase()) > -1);

        if (!!query.term) {
            for (var i = 0; i < matches.length; i++) {
                if (matches[i].Alpha2Code.toLocaleLowerCase().indexOf(query.term.toLocaleLowerCase()) > -1 || query.term.toLocaleLowerCase().indexOf(matches[i].Alpha2Code.toLocaleLowerCase()) > -1) {
                    var elem = matches.splice(i, 1);
                    matches.unshift(elem[0]);
                    i++;
                }
            }
        }

        query.callback({ results: matches.map((n) => <ISelect2Result> { id: n.Alpha2Code, text: n.Name }) });
    }
}