import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { IHumanResource, IHumanResourceOrders, IHumanResourceOrdersWorkingHours, IHumanResourceOrdersSalaries } from "../../../HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../HumanResourcesSettingsManager";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IUser } from "../../../../ProlifeSdk/interfaces/users/IUser";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IUsersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUsersSettingsManager";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";

export interface IHumanResourceProvider
{
    IsSelected: ko.Observable<boolean>;

    getData() : IHumanResource;
    update(resource : IHumanResource) : void;
}

export interface IUserForHumanResourceViewModel {
    user: IUser;
    available: ko.Observable<boolean>;    
}

export class HumanResourcesEditingViewModel
{
    title : string;
    elements : ko.ObservableArray<IHumanResourceProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();
    private mappedUsers : number[];

    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;

    HumanResources : ko.Computed<IHumanResourceProvider[]>;
    MaterialResources : ko.Computed<IHumanResourceProvider[]>;

    HumanResourcesTextFilter : ko.Observable<string> = ko.observable("");
    MaterialResourcesTextFilter : ko.Observable<string> = ko.observable("");
    ShowDisabled : ko.Observable<boolean> = ko.observable(false);

    public SelectedResource : ko.Observable<any> = ko.observable();
    public SearchingHumanResources : ko.Observable<boolean> = ko.observable(true);

    constructor(private resourcesManager : IHumanResourcesSettingsManager)
    {
        this.title = resourcesManager.getLabel();
        const resources : IHumanResource[] = resourcesManager.getHumanResources().filter((r : IHumanResource) => !r.Resource.Deleted);

        this.elements.subscribe(() => {
            this.synchronizeAvailableUsers();
        });

        resources.forEach(this.createViewModelFor.bind(this));

        this.HumanResources = ko.computed(() => {
            return this.elements().filter((r : HumanResourceViewModel) => { return r.ResourceType == 0 && (this.ShowDisabled() || !r.Disabled());});
        });

        this.MaterialResources = ko.computed(() => {
            return this.elements().filter((r : HumanResourceViewModel) => { return r.ResourceType != 0 && (this.ShowDisabled() || !r.Disabled());});
        });

        this.SearchingHumanResources.subscribe(() => {
            this.HumanResources().forEach(r => r.IsSelected(false));
            this.MaterialResources().forEach(r => r.IsSelected(false));
            this.SelectedResource(undefined);
        });
    }

    private synchronizeAvailableUsers()
    {
        this.mappedUsers = [];
        this.elements().forEach((e : HumanResourceViewModel) => { if(this.mappedUsers.indexOf(e.FkUser()) == -1) { this.mappedUsers.push(e.FkUser());} });
        this.elements().forEach((e : HumanResourceViewModel) => { e.synchronizeAvailableUsers(this.mappedUsers)});
    }

    private createViewModelFor(resource : IHumanResource)
    {
        const filterField : ko.Observable<string> = resource.Resource.ResourceType == 0 ? this.HumanResourcesTextFilter : this.MaterialResourcesTextFilter;
        const resourceVm = new HumanResourceViewModel(this, resource, filterField);
        this.elements.push(resourceVm);
    }

    public CreateHumanResource()
    {
        this.CreateResource({
            Resource: {
                Id: -1,
                Name: this.newSettingName(),
                Surname: this.newSettingName(),
                FkUser : null,
                FkDefaultCharacter : null,
                ResourceType : 0,
                Deleted : false,
                Disabled : false,
                ShowPreferred : false,
                SerialNumber: null,
            },
            Orders : [this.getFakeOrder()],
            OrdersSalaries : [],
            OrdersRoles: [],
            OrdersWorkingHours: this.createFakeWorkingHours()
        }, this.HumanResourcesTextFilter);
    }

    private getFakeOrder(){
        const order : IHumanResourceOrders = {
            Id: -1,
            FromDate: moment("01/01/1980", "MM-DD-YYYY").toDate(),
            ToDate: null,
            FkResource: null,
            FkOperationalUnit: 1,
            Title: "Ordine predefinito",
            HoursMonday: 8,
            HoursTuesday: 8,
            HoursWednesday: 8,
            HoursThursday: 8,
            HoursFriday: 8,
            HoursSaturday: 0,
            HoursSunday: 0,
            EstimatedHoursToWork : 40,
            MaxFlexiblePositive : null,
            MaxFlexibleNegative : null
        };
        return order;
    }

    private createFakeWorkingHours(): IHumanResourceOrdersWorkingHours[] {
        return [
            this.createFakeWorkingHour(-1, 1, '09:00', '13:00', 4),
            this.createFakeWorkingHour(-1, 1, '14:30', '18:30', 4),
            this.createFakeWorkingHour(-1, 2, '09:00', '13:00', 4),
            this.createFakeWorkingHour(-1, 2, '14:30', '18:30', 4),
            this.createFakeWorkingHour(-1, 3, '09:00', '13:00', 4),
            this.createFakeWorkingHour(-1, 3, '14:30', '18:30', 4),
            this.createFakeWorkingHour(-1, 4, '09:00', '13:00', 4),
            this.createFakeWorkingHour(-1, 4, '14:30', '18:30', 4),
            this.createFakeWorkingHour(-1, 5, '09:00', '13:00', 4),
            this.createFakeWorkingHour(-1, 5, '14:30', '18:30', 4)
        ];
    }

    private createFakeWorkingHour(serviceOrderid: number, dayOfWeek: number, start: string, end: string, duration: number): IHumanResourceOrdersWorkingHours {
        return <IHumanResourceOrdersWorkingHours> {
            Id: -1,
            ServiceOrderId: serviceOrderid,
            DayOfWeek: dayOfWeek,
            Start: start,
            End: end,
            Duration: duration
        };
    }

    public CreateMaterialResource()
    {
        this.CreateResource({
            Resource: {
                Id: -1,
                Name: this.newSettingName(),
                Surname: this.newSettingName(),
                FkUser : null,
                FkDefaultCharacter : null,
                ResourceType : 1,
                Deleted : false,
                Disabled : false,
                ShowPreferred : false,
                SerialNumber: null,
            },
            Orders : [this.getFakeOrder()],
            OrdersSalaries : [],
            OrdersRoles: [],
            OrdersWorkingHours: this.createFakeWorkingHours()
        }, this.MaterialResourcesTextFilter);
    }

    private CreateResource(resource : IHumanResource, filterField : ko.Observable<string>)
    {
        const resourceViewModel : HumanResourceViewModel = new HumanResourceViewModel(this, resource, filterField);
        this.elements.push(resourceViewModel);
        this.createOrUpdateEntry(resourceViewModel);
        this.newSettingName("");
    }

    ShowDetails(resource : any)
    {
        this.HumanResources().forEach(r => r.IsSelected(false));
        this.MaterialResources().forEach(r => r.IsSelected(false));
        resource.IsSelected(true);
        this.SelectedResource(resource);
    }

    public setIsSelectedNewSetting()
    {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IHumanResourceProvider)
    {
        const resource : IHumanResource = element.getData();
        this.synchronizeAvailableUsers();
        this.resourcesManager.createOrUpdate(resource)
            .then((updateduser) => { element.update(updateduser); });
    }

    public deleteEntry(element : IHumanResourceProvider)
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.Users.SureToDeleteResource, ProlifeSdk.TextResources.Users.DoNotDeleteResource, ProlifeSdk.TextResources.Users.DeleteResource, (dialogResult) => {
            if(dialogResult)
            {
                this.elements.remove(element);
                const resource : IHumanResource = element.getData();
                this.resourcesManager.remove(resource.Resource.Id);
            }
        })
    }
}

class HumanResourceViewModel implements IHumanResourceProvider
{
    fkCharacterUser : ko.Observable<number> = ko.observable();
    userCharacters : IUserCharacter[];
    users : ko.ObservableArray<IUserForHumanResourceViewModel> = ko.observableArray([]);

    Name : ko.Observable<string> = ko.observable();
    Surname : ko.Observable<string> = ko.observable();
    FkUser : ko.Observable<number> = ko.observable();
    Disabled : ko.Observable<boolean> = ko.observable();
    Salaries : ko.ObservableArray<SalaryViewModel> = ko.observableArray([]);
    ShowPreferred : ko.Observable<boolean> = ko.observable();
    SerialNumber: ko.Observable<string> = ko.observable();

    public ResourceType : number;
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    public IsVisible : ko.Computed<boolean>;
    public IsSelected : ko.Observable<boolean> = ko.observable(false);
    public IsHumanResource : ko.Observable<boolean> = ko.observable(true);

    private Resource : ko.Observable<any> = ko.observable(null);

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager: IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CompanyUsers)
    private usersSettingsManager: IUsersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager : IWorkTimeCategoriesSettingsManager;

    constructor(private container : HumanResourcesEditingViewModel, private resource : IHumanResource, private filterField : ko.Observable<string>)
    {
        this.userCharacters = this.userCharactersSettingsManager.getUserCharacters(resource.Resource.ResourceType);
        
        this.usersSettingsManager.getUsers().forEach((u : IUser) => {
            this.users.push({ user : u, available : ko.observable(true)})
        });
        
        this.IsHumanResource(resource.Resource.ResourceType == 0);

        this.update(resource);
        this.fkCharacterUser.subscribe(this.onDataChanged.bind(this));
        this.FkUser.subscribe(this.onDataChanged.bind(this));
        this.Name.subscribe(this.onDataChanged.bind(this));
        this.Surname.subscribe(this.onDataChanged.bind(this));
        this.Disabled.subscribe(this.onDataChanged.bind(this));
        this.ShowPreferred.subscribe(this.onDataChanged.bind(this));
        this.SerialNumber.subscribe(this.onDataChanged.bind(this));

        this.IsVisible = ko.computed(() => {
            const fullName : string =  (this.Resource().Name || "").toUpperCase() + (this.Resource().Surname || "").toUpperCase();
            return !this.Resource().Id || fullName.indexOf((this.filterField() || "").toUpperCase().trim()) > -1;
        });
    }

    switchDisabled()
    {
        this.Disabled(!this.Disabled());
    }

    synchronizeAvailableUsers(mappedUsers : number[])
    {
        this.users().forEach((u : any) => {
            u.available(mappedUsers.indexOf(u.user.IdUser) == -1 || u.user.IdUser == this.FkUser());
        });
    }

    public deleteResource()
    {
        this.container.deleteEntry(this);
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    private setCharacter(character : IUserCharacter) : void
    {
        this.fkCharacterUser(character.IdUserCharacter);
    }

    private setUser(userInfo : any) : void
    {
        if(userInfo.available())
            this.FkUser(userInfo.user.IdUser);
    }

    private resetUser()
    {
        this.FkUser(null);
    }

    getData() : IHumanResource
    {
        const resource : IHumanResource = <IHumanResource> $.extend({}, this.resource);
        resource.Resource.Name = this.Name();
        resource.Resource.Surname = this.Surname();
        resource.Resource.FkUser = this.FkUser();
        resource.Resource.FkDefaultCharacter = this.fkCharacterUser();
        resource.Resource.Disabled = this.Disabled();
        resource.Resource.ShowPreferred = this.ShowPreferred();
        resource.Resource.SerialNumber = this.SerialNumber();

        resource.Orders = this.resource.Orders;

        resource.OrdersSalaries = this.Salaries().map((s : SalaryViewModel) => {
            const salaryData : IHumanResourceOrdersSalaries = s.getData();
            //TODO: rivedere
            //salaryData.FkServiceOrder = resource.Resource.Id;
            return salaryData;
        });

        return resource;
    }

    update(resource : IHumanResource) : void
    {
        this.updating = true;
        this.Resource(resource);
        this.resource = resource;
        this.ResourceType = resource.Resource.ResourceType;
        this.Name(resource.Resource.Name);
        this.Surname(resource.Resource.Surname);
        this.FkUser(resource.Resource.FkUser);
        this.Disabled(resource.Resource.Disabled);
        this.ShowPreferred(resource.Resource.ShowPreferred);
        this.SerialNumber(resource.Resource.SerialNumber);
        this.fkCharacterUser(resource.Resource.FkDefaultCharacter);
        this.updating = false;

        const salaries = [];
        this.workTimeCategoriesManager
            .getAll(false)
            .filter((c : IWorkTimeCategory) => {
                return c.ResourceType == this.resource.Resource.ResourceType;
            }).forEach((c : IWorkTimeCategory) => {
                salaries.push(new SalaryViewModel(c, resource.OrdersSalaries, this.onDataChanged.bind(this)));
            });
        this.Salaries(salaries);
    }
}

export class SalaryViewModel
{
    HourSalary : ko.Observable<number> = ko.observable(0);
    WorkTimeCategory : IWorkTimeCategory;
    private data : IHumanResourceOrdersSalaries = null;

    constructor(workTimeCategory: IWorkTimeCategory, allSalaries: IHumanResourceOrdersSalaries[], private changeHandler: any)
    {
        this.WorkTimeCategory = workTimeCategory;
        const matches = allSalaries.filter(s => {
            return s.FkWorkTimeCategoryId == workTimeCategory.Id;
        });
        this.data = matches.length == 0 ? null : matches[0];
        this.HourSalary(matches.length == 0 ? workTimeCategory.DefaultHourSalary : this.data.HourSalary);
        this.HourSalary.subscribe(changeHandler);
    }

    getData() : IHumanResourceOrdersSalaries
    {
        const data : IHumanResourceOrdersSalaries = this.data || {
            FkServiceOrder: 0,
            FkWorkTimeCategoryId : this.WorkTimeCategory.Id,
            HourSalary : 0,
            IsDefault: false
        };

        data.HourSalary = this.HourSalary();
        return data;
    }
}