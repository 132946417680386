import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../Core/utils/ComponentUtils";
import { ICurrenciesSettingsManager } from "../Invoices/invoices/settings/CurrenciesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../Core/DependencyInjection";
import { CurrenciesDataSource } from "../DataSources/CurrenciesDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import jss from "jss";
import { CurrencyUtils } from "../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../DataSources/IDataSource";
import { PopoverPosition, IDialogsService, IPopoverComponentInfo } from "../Core/interfaces/IDialogsService";
import { ICurrencyWithCountriesIds } from "../Invoices/interfaces/ICurrenciesService";

const { classes } = jss.createStyleSheet({
    "money-input": {
        "& .readonly-currency": {
            color: "black",
            border: "none",

            "&:hover": {
                "background-color": "white",
                cursor: "default"
            }
        },

        "& .fa-warning.red": {
            color: "white"
        }
    }
}).attach();

let attributes = {
    Value: "value",
    ReadOnly: "readOnly",
    Currency: "currency",
    ExchangeValue: "exchange-value",
    EditableCurrency: "editable-currency",
    Label: "label",
    ButtonSize: "button-size",
    PopoverPlacement: "popover-placement",
    PopoverViewport: "popover-viewport",
    PopoverCssClasses: "popover-css-classes",
    PopoverContainer: "popover-container",

    AlertIfNotDefaultCurrency: "alert-if-not-default-currency",

    CanEditCurrency: "can-edit-currency"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "money-input": {
                params?: {
                    Value: string,
                    ReadOnly?: string,
                    Currency: string,
                    ExchangeValue: string,
                    EditableCurrency?: string;
                    Label?: string,
                    ButtonSize?: string,
                    PopoverPlacement?: string,
                    PopoverViewport?: string,
                    PopoverCssClasses?: string,
                    PopoverContainer?: string,
                    AlertIfNotDefaultCurrency?: string,
                    CanEditCurrency?: string
                };
                
                value: number | (() => string);
                readOnly?: boolean | (() => string);
                currency: number | (() => string);
                "exchange-value": number | (() => string);
                "editable-currency"?: boolean | (() => string);
                label?: string | (() => string);
                "button-size"?: string | (() => string);
                "popover-placement"?: string | (() => string);
                "popover-viewport"?: string | (() => string);
                "popover-css-classes"?: string | (() => string);
                "popover-container"?: string | (() => string);
                "alert-if-not-default-currency"?: boolean | (() => string);
                "can-edit-currency"?: boolean | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export type ButtonSize = "default" | "medium" | "small";

export interface IMoneyInputComponentParams {
    Value: Param<number>;
    ReadOnly: Param<boolean>;
    Currency: Param<number>;
    ExchangeValue: Param<number>;
    EditableCurrency: Param<boolean>;
    Label: Param<string>;
    ButtonSize: Param<string>;
    PopoverPlacement: Param<PopoverPosition>;
    PopoverViewport: Param<string>;
    PopoverCssClasses: Param<string>;
    PopoverContainer: Param<string>;
    AlertIfNotDefaultCurrency: Param<boolean>;
    CanEditCurrency: Param<boolean>;
}

export class MoneyInputComponent implements IDataSourceListener {
    public Value: ComponentParam<number>;
    public ReadOnly: ComponentParam<boolean>;
    public Currency: ComponentParam<number>;
    public ExchangeValue: ComponentParam<number>;
    public EditableCurrency: ComponentParam<boolean>;
    public Label: ComponentParam<string>;
    public ButtonSize: ComponentParam<string>;
    public PopoverPlacement: ComponentParam<PopoverPosition>;
    public PopoverViewport: ComponentParam<string>;
    public PopoverCssClasses: ComponentParam<string>;
    public PopoverContainer: ComponentParam<string>;
    public AlertIfNotDefaultCurrency: ComponentParam<boolean>;
    public CanEditCurrency: ComponentParam<boolean>;

    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public CurrencyFormat: ko.Observable<string> = ko.observable();

    public ShowIcon: ko.Computed<boolean>;
    public InputCssClasses: ko.Computed<string>;
    public EnableCurrencyEditing: ko.Computed<boolean>;

    public CurrenciesDataSource: CurrenciesDataSource = new CurrenciesDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(params : IMoneyInputComponentParams) {
        this.CurrenciesDataSource.returnDeletedCurrencies(false);

        let initialCurrency = this.currenciesSettingsManager.getDefaultCurrency();

        this.Value = ComponentUtils.parseParameter(params.Value, 0);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.Currency = ComponentUtils.parseParameter(params.Currency, initialCurrency.Id);
        this.ExchangeValue = ComponentUtils.parseParameter(params.ExchangeValue, 1);
        this.EditableCurrency = ComponentUtils.parseParameter(params.EditableCurrency, true);
        this.Label = ComponentUtils.parseParameter(params.Label, undefined);
        this.ButtonSize = ComponentUtils.parseParameter(params.ButtonSize, "default");
        this.PopoverPlacement = ComponentUtils.parseParameter(params.PopoverPlacement, "auto");
        this.PopoverViewport = ComponentUtils.parseParameter(params.PopoverViewport, "body");
        this.PopoverCssClasses = ComponentUtils.parseParameter(params.PopoverCssClasses, "");
        this.PopoverContainer = ComponentUtils.parseParameter(params.PopoverContainer, "body");
        this.AlertIfNotDefaultCurrency = ComponentUtils.parseParameter(params.AlertIfNotDefaultCurrency, false);
        this.CanEditCurrency = ComponentUtils.parseParameter(params.CanEditCurrency, false);

        let currentCurrency = this.Currency();

        if (currentCurrency !== initialCurrency.Id)
            initialCurrency = this.currenciesSettingsManager.getCurrencyById(currentCurrency);
    
        this.CurrencySymbol(initialCurrency.Symbol);
        
        this.setCurrencyFormat(initialCurrency.Id);

        this.ShowIcon = ko.computed(() => {
            let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            return this.Currency() !== defaultCurrency?.Id;
        });

        this.InputCssClasses = ko.computed(() => {
            return this.ShowIcon() ? "bg-red" : "";
        });

        this.EnableCurrencyEditing = ko.computed(() => {
            return this.EditableCurrency() && this.CanEditCurrency() && !this.ReadOnly();
        });
    }

    public async selectCurrency(item : any, event : Event): Promise<void> {
        let popoverInfo: IPopoverComponentInfo = {
            componentName: "currency-input-popover",
            title: TextResources.Expenses.Currency,
            model: {
                Currency: this.Currency,
                ExchangeValue: this.ExchangeValue,
                Listeners: [this]
            },
            params: "Currency: Currency, ExchangeValue: ExchangeValue, Listeners: Listeners"
        };

        return this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, popoverInfo, this.PopoverPlacement(), this.PopoverViewport(), this.PopoverCssClasses(), this.PopoverContainer());
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.Currency(model?.id);
        this.CurrencySymbol(model?.model?.Symbol);
        this.setCurrencyFormat(model?.id);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.Currency(null);
        this.ExchangeValue(1);
        this.CurrencySymbol(null);
    }

    private setCurrencyFormat(currencyId: number): void {
        if (!currencyId) {
            this.CurrencyFormat("0,0.00");
            return;
        }

        let format = CurrencyUtils.getCurrencyFormat(currencyId);
        this.CurrencyFormat(format);
    }
}

ko.components.register("money-input", {
    viewModel: {
        createViewModel: (params: IMoneyInputComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new MoneyInputComponent(params);
            
            let templateFragment = [
                <numeric-input class={classes["money-input"]} value={() => "Value"} label={() => "Label"} placeholder={TextResources.Expenses.CostValue} icon="fa-warning red" show-icon={() => "ShowIcon"} css-classes={() => "InputCssClasses"} icon-title={TextResources.Expenses.NotDefaultCurrency} format={() => "CurrencyFormat"} readOnly={() => "ReadOnly"}>
                    <span class="input-group-btn">
                        <ko-if data-bind="$parent.EnableCurrencyEditing">
                            <button type="button" class="btn btn-primary" data-bind="click: $parent.selectCurrency.bind($parent), css: { 'btn-xs': $parent.ButtonSize() === 'small', 'btn-sm': $parent.ButtonSize() === 'medium' }">
                                <span data-bind="text: $parent.CurrencySymbol"></span>
                            </button>
                        </ko-if>
                        <ko-ifnot data-bind="$parent.EnableCurrencyEditing">
                            <span class="btn btn-default readonly-currency" data-bind="css: { 'btn-xs': $parent.ButtonSize() === 'small', 'btn-sm': $parent.ButtonSize() === 'medium' }">
                                <span data-bind="text: $parent.CurrencySymbol"></span>
                            </span>
                        </ko-ifnot>
                    </span>
                </numeric-input>
            ];

            ko.virtualElements.setDomNodeChildren(componentInfo.element, templateFragment);
            
            return vm;
        },
    },
    template: []
});