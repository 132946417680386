import * as ko from "knockout";
export class IdValue {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let underlyingObservable = valueAccessor();

        if (!ko.isObservable(underlyingObservable))
            throw new Error("IdValue binding must be used with observable");

        let interceptor = ko.computed({
            read: () => {
                let value = underlyingObservable();
                return value === null || value === undefined ? "" : value.toString();
            },
            write: (value: string) => {
                if(value === "" || value === null || value === undefined)
                    underlyingObservable(null);
                else {
                    let parsedId = parseInt(value);
                    if (isNaN(parsedId))
                        underlyingObservable(value)
                    else
                        underlyingObservable(parsedId);
                }
            }
        });

        let bindings = { value: interceptor };

        ko.applyBindingsToNode(element, bindings, undefined);
    }
}

ko.bindingHandlers["idValue"] = new IdValue();