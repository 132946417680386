import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 04/07/13
 * Time: 12.11
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IFileRepositoryService } from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ITagHintEx } from "../FileRepository/interfaces/ITagHintEx";
import { ITagHint } from "../ProlifeSdk/interfaces/files/ITagHint";

export class TagHintSearch {
    public fileRepository : IFileRepositoryService;
    public lastTimeout : ReturnType<typeof setTimeout>;

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) {
        var instance = {
            fileRepository : <IFileRepositoryService> Core.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        };

        (<any>$(element)).typeahead({
            items: 10,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source: TagHintSearch.getSearchHints.bind(null, instance),
            limit: 10,
            display: (json) => {
                var tag = <ITagHintEx> JSON.parse(json);
                return tag.Name;
            },
            templates : {
                suggestion : function(json) {
                    var tag = <ITagHintEx> JSON.parse(json);
                    return '<div><i class="fa fa-tag tag c' + tag.Color + '"></i>&nbsp;' + tag.Name + "</div>";
                }
            }
        }).on("typeahead:selected", (event, json) => {
            var tag = <ITagHintEx> JSON.parse(json);
            valueAccessor().call(viewModel, tag);
            return tag.Name;
        });
    }

    private static getSearchHints(_this : TagHintSearch, query : string, process : (items : any[]) => any, asyncProcess : (items : any[]) => any) {
        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(() => {
            _this.fileRepository.getTagHints(query)
                .then((tags : ITagHint[]) => {
                    if(tags.length == 0) {
                        asyncProcess([TagHintSearch.getCreateNewTagItem(query)])
                        return;
                    }
                    asyncProcess(tags.map(TagHintSearch.toFileRepositoryTag))
                })
                .catch(() => process([]));
        }, 500);
    }

    private static getCreateNewTagItem(query : string) : ITagHintEx {
        return <ITagHintEx> {
            Name: String.format(ProlifeSdk.TextResources.FileRepository.CreateTag, query),
            Color: <any>'',
            IsCreateNew: true,
            Administration: false,
            Commercial: false,
            Technical: false,
            Value: query,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.Name.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.Name, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.Name, arguments);
            }
        };
    }

    private static toFileRepositoryTag(tag : ITagHint) : ITagHintEx {
        return <ITagHintEx> $.extend(tag, {
            IsCreateNew: false,
            Value: "",
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.Name.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.Name, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.Name, arguments);
            }
        });
    }
}

ko.bindingHandlers["tagHintSearch"] = new TagHintSearch();