import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 26/02/13
 * Time: 15.17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class CustomStringProvider implements IEntityProvider<string, string> {
    constructor() {

    }

    getType() : string
    {
        return ProlifeSdk.TagType_String;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Blog.Text;
    }

    getPkValue(item : any) : any
    {
        return item;
    }

    getDisplayName(item : string) : string
    {
        return item;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<any>
    {
        return Promise.resolve(pkValue);
    }

    hintSearch(stringFilter : string) : Promise<any[]>
    {
        return Promise.resolve<any[]>([]);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        //Da implementare se necessario
        return null;
    }

    getEntity(pkValue : any) : Promise<any>
    {
        return Promise.resolve<any>(pkValue);
    }
}