import * as ko from "knockout";
import { Document } from "../../../../Invoices/invoices/documents/Document";
import { VatTypesDataSource, IVatTypesDataSourceModel } from "../../../../DataSources/VatTypesDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class VatCodeAutomationDialog implements IDialog, IDataSourceListener {
    templateName = "vatCodeAutomation";
    templateUrl = "prolifesdk/templates/documents/automations";
    title = "Modifica Codici IVA";
    modal: { close: (result?: any) => void };

    FKVatCode: ko.Observable<number> = ko.observable();
    VatCode: ko.Observable<string> = ko.observable();

    VatTypesDataSource: VatTypesDataSource = new VatTypesDataSource();

    constructor(private document: Document) {}

    onItemSelected(sender: IDataSource, model: IVatTypesDataSourceModel): void {
        if (!model) return;
        this.VatCode(model.model.CodiceIVA);
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    close(): void {
        this.modal.close();
    }

    action(): void {}

    public async setVatCodes() {
        const vatCode = this.FKVatCode();

        if (vatCode) {
            for (const row of this.document.Rows()) {
                await row.setVat(vatCode);
            }
        }

        this.modal.close(true);
    }
}
