import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { Resource, ServiceOrder } from "./Resource";

export class ServiceOrderSplitEditor extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    private validator: IValidator<ServiceOrderSplitEditor>;

    StartDate: Date;
    EndDate: Date;
    SplitDate: ko.Observable<Date> = ko.observable();

    constructor(private serviceOrder: ServiceOrder, private resource: Resource) {
        super({});

        this.title("Divisione Ordine di Servizio");

        this.StartDate = serviceOrder.FromDate();
        this.EndDate = serviceOrder.ToDate();

        if(this.EndDate && new Date() >= this.EndDate)
            this.SplitDate(this.EndDate);
        else
            this.SplitDate(new Date());

        this.validator = this.validationService.createValidator<ServiceOrderSplitEditor>()
            .isNotNullOrUndefined(s => s.SplitDate(), "Selezionare una data di divisione")
            .isTrue(s => s.SplitDate() > s.StartDate, "La data di divisione deve essere successiva alla data di inizio")
            .isTrue(s => !s.EndDate || s.SplitDate() < s.EndDate, "La data di divisione deve essere precedente alla data di fine");
    }

    ShowModal() {
        return this.dialogsService.ShowModal(this);
    }

    action() {
        if(!this.validator.validateAndShowInfoToast(this))
            return;

        let newId = this.resource.newServiceOrderId();
        var newServiceOrder : ServiceOrder = this.serviceOrder.clone(newId);
        this.serviceOrder.ToDate(this.SplitDate());
        
        newServiceOrder.FromDate(this.SplitDate());
        newServiceOrder.SplitFrom = this.serviceOrder.Id();

        this.resource.addServiceOrder(newServiceOrder);

        this.modal.close(true);
    }
    
    renderBody() {
        let $data : ServiceOrderSplitEditor;

        return <>
            <div className="form-group">
                <label>Dal (incluso)</label>
                <span class="form-control" data-bind={{ dateText : $data.StartDate, customDateTimeFormat : 'DD/MM/YYYY HH:mm', attr: { 'disabled': true } }} />
            </div>
            <div className="form-group">
                <label>Data di Divisione</label>
                <input class="form-control" data-bind={{ datepicker : $data.SplitDate, customDateTimeFormat : 'DD/MM/YYYY HH:mm' }} type="text" />
            </div>
            <div className="form-group">
                <label>A (escluso)</label>
                <span class="form-control" data-bind={{ dateText : $data.EndDate, customDateTimeFormat : 'DD/MM/YYYY HH:mm', dateTextNoValue: 'Nessuna data di fine impostata', attr: { 'disabled': true } }} />
            </div>
        </>;
    }

}