/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 19/09/2017
 * Time: 10:54
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { TeamViewModel } from "./TeamViewModel";
import { TeamAllocationViewModel } from "./TeamAllocationViewModel";
import { ResourceOperationalUnitViewModel } from "./ResourceOperationalUnitViewModel";
import { PlannerGanttItem, PlannerGanttItemInterval, PlannerGanttItemMarker } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import * as moment from "moment";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { Moment } from "moment";
import { ITeamAllocationViewModel, IResourceOperationalUnitViewModel } from "./TeamsManager";
import { UiUtilities } from "../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IAllocationsService, ITeamAllocationsForGantt, ICartAllocationForTeam } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { Deferred } from "../../../Core/Deferred";

export class CartsAllocationsRenderer {
    private allocationsService: IAllocationsService;

    constructor(private serviceLocator : IServiceLocator, private Gantt : PlannerGantt, private resourceId: number, private opUnitId: number) {
        this.allocationsService = <IAllocationsService> this.serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);
    }

    public render(team : TeamViewModel) {
        var allocations: ITeamAllocationViewModel[] = team.Allocations().filter((a: TeamAllocationViewModel) => { return a.ResourceId() == this.resourceId; });
        if (allocations.length == 0)
            return;

        var opUnit: IResourceOperationalUnitViewModel[] = allocations[0].OperationalUnits().filter((ou: ResourceOperationalUnitViewModel) => { return ou.OperationalUnit.Id == this.opUnitId; });
        if (opUnit.length == 0)
            return;

        var newTeam = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
        newTeam.Color("#FF8080");
        newTeam.ProgressColor("#FF8080");
        newTeam.Progress(0);
        newTeam.Tag(team);
        newTeam.Id(team.Id());
        newTeam.Title(team.Name());
        newTeam.StartDate(team.StartDate() ? team.StartDate().toDate() : undefined);
        newTeam.EndDate(team.EndDate() ? team.EndDate().toDate() : undefined);
        this.renderAllocations(team, newTeam);

        team.Name.subscribe((n) => newTeam.Title(n));
        team.StartDate.subscribe((d) => newTeam.StartDate(d ? d.toDate() : undefined));
        team.EndDate.subscribe((d) => newTeam.EndDate(d ? d.toDate() : undefined));


        this.Gantt.Items.push(newTeam);
    }

    public reamove(team : TeamViewModel) {
        var foundItem = this.Gantt.Items().filter((i : PlannerGanttItem) => i.Tag() == team);
        foundItem.forEach(i => {
            this.Gantt.Items.remove(i);
        });
    }

    private renderAllocations(team : TeamViewModel, parent : PlannerGanttItem) {
        var deferreds = [];
        var maxDate : Moment = moment();

        var def = new Deferred();
        deferreds.push(def);

        this.allocationsService.getCartAllocationForTeam(team.Id())
            .then((allocationsInfo : ITeamAllocationsForGantt) => {
                this.renderTeamAllocations(parent, allocationsInfo.Allocations);

                parent.Intervals().forEach((i : PlannerGanttItemInterval) => {
                    if(maxDate < moment(i.EndDate()))
                        maxDate = moment(i.EndDate());
                });

                def.resolve();
            });
    }

    private renderTeamAllocations(item : PlannerGanttItem, allocations : ICartAllocationForTeam[]) {
        if (allocations && allocations.length > 0)
            this.setGanttStartDate(allocations[0].Date);

        var bigIntervals = [];
        var intervals = [];
        var children = [];

        var markers = [];
        var markersForParent = [];

        var lastCartId = -1;
        var lastCartTitle = "";
        var lastAllocationId = -1;
        var lastAllocationType = -1;
        var lastHoursType = - 1;
        var lastColor = "#000000";
        var minDate = moment("2100-01-01");
        var maxDate = moment("2000-01-01");

        allocations.forEach((alloc) => {
            if(lastAllocationId != alloc.AllocationId) {
                if(lastAllocationId != -1) {
                    var child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
                    child.Title(lastCartTitle);
                    child.Tag(this);
                    child.Id(lastCartId);
                    child.MultipleIntervals(true);
                    child.CanHaveChildren(false);
                    child.Intervals(intervals);
                    intervals = [];

                    child.Markers(markers);
                    markers = [];

                    children.push(child);

                    var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                    interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                    interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                    interval.Id(lastAllocationId);
                    if (lastAllocationType == ProlifeSdk.TheoreticalAllocation) {
                        interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                        interval.Style("no-estimate");
                    }
                    bigIntervals.push(interval);
                }

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
                lastAllocationId = alloc.AllocationId;
                lastColor = alloc.Color;
                lastCartId = alloc.CartId;
                lastCartTitle = alloc.CartName;
            }

            minDate = minDate > moment(alloc.Date) ? moment(alloc.Date) : minDate;
            maxDate = maxDate < moment(alloc.Date) ? moment(alloc.Date) : maxDate;
            lastAllocationType = alloc.AllocationType;

            if (lastHoursType != -1 && alloc.HoursType != lastHoursType) {
                var tmpMinDate = moment(maxDate);
                var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.add('days', -1).toDate(), lastCartTitle, this.Gantt.StartDate);
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                interval.Id(lastAllocationId);
                if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                    interval.Style("no-estimate");
                }
                bigIntervals.push(interval);

                minDate = tmpMinDate;
                maxDate = moment("2000-01-01");
            }

            lastHoursType = alloc.HoursType;

            var interval = new PlannerGanttItemInterval(alloc.Date, alloc.Date, alloc.HoursAmount, this.Gantt.StartDate);
            interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
            interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
            interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
            if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                interval.Style("no-estimate");
            }
            intervals.push(interval);

            if (alloc.IncompleteAllocation || alloc.CartIsEmpty || alloc.CartHasUnestimatedWorkOnly || alloc.CartIsNotWorkableByTheTeam) {
                var incompleteAllocationMarker = new PlannerGanttItemMarker(alloc.Date, this.Gantt.StartDate);
                incompleteAllocationMarker.Id(alloc.Id);
                incompleteAllocationMarker.Tag(this);
                incompleteAllocationMarker.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                incompleteAllocationMarker.Type("ball");
                markers.push(incompleteAllocationMarker);

                markersForParent.push(incompleteAllocationMarker);

                var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                interval.Id(lastAllocationId);
                if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                    interval.Style("no-estimate");
                }
                bigIntervals.push(interval);

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
            }
        });

        var child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
        child.Title(lastCartTitle);
        child.Tag(this);
        child.Id(lastCartId);
        child.MultipleIntervals(true);
        child.CanHaveChildren(false);
        child.Intervals(intervals);

        child.Markers(markers);
        markers = [];

        children.push(child);

        var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
        interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
        interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
        interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
        interval.Id(lastAllocationId);
        if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
            interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
            interval.Style("no-estimate");
        }

        bigIntervals.push(interval);

        item.Intervals(bigIntervals);
        item.Children(children);
        item.Markers(markersForParent);
    }

    private setGanttStartDate(startDate: Date): void {
        var date = moment(startDate);
        var ganttDate = moment(this.Gantt.StartDate());
        if (ganttDate > date)
            this.Gantt.StartDate(date.startOf('day').toDate());
    }
}