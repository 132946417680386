import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 17/10/2017
 * Time: 11:18
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { PlannerGanttItem, PlannerGanttItemMarker, PlannerGanttItemInterval } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import * as moment from "moment";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { LazyImport } from "../../../Core/DependencyInjection";
import { AllocationStartType } from "../enums/AllocationStartType";
import { AllocationMode } from "../enums/AllocationMode";
import { UiUtilities } from "../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IAllocationsService, ICartAllocationForTeamWithStartDate, ITeamAllocationsForGantt } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService, ShowModalOptions, IDialog } from "../../../Core/interfaces/IDialogsService";
import { IPlannerGanttItem } from "../../../ProlifeSdk/interfaces/controls/gantt/PlannerGanttInterfaces";

export class AllocationsGanttForTeam {
    public Gantt : PlannerGantt;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService : IAllocationsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private teamId: number, private teamName: string) {
        this.Gantt = new PlannerGantt();
        this.Gantt.AllowDragAndDrop(false);

        this.Load();
    }

    public Load(): void {
        var item = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
        item.Title(this.teamName);
        item.Tag(this);
        item.Id(this.teamId);
        item.MultipleIntervals(true);
        item.CanHaveChildren(true);
        this.Gantt.Items([]);
        this.Gantt.Items.push(item);

        this.allocationsService.getCartAllocationForTeamWithAllocationStartDate(this.teamId)
            .then(this.renderTeamAllocations.bind(this, item));
    }

    public ShowLegend() {
        var legendDialog = new LegendDialog();
        var options : ShowModalOptions = {
            noPrompt: true
        }
        this.dialogsService.ShowModal<void>(legendDialog, "", options);
    }

    private setGanttStartDate(startDate: Date): void {
        var date = moment(startDate);
        var ganttDate = moment(this.Gantt.StartDate());
        if (ganttDate > date)
            this.Gantt.StartDate(date.startOf('day').toDate());
    }

    private renderTeamAllocations(item : PlannerGanttItem, allocations : ICartAllocationForTeamWithStartDate[]) {
        if (allocations && allocations.length > 0)
            this.setGanttStartDate(allocations[0].Date);

        var bigIntervals = [];
        var intervals = [];
        var children = [];
        var markers = [];
        var incompleteAllocationMarkers = [];

        var lastCartId = -1;
        var lastCartTitle = "";
        var lastAllocationId = -1;
        var lastAllocationType = -1;
        var lastHoursType = -1;
        var lastColor = "#000000";
        var minDate = moment("2100-01-01");
        var maxDate = moment("2000-01-01");
        var allocationStartDate = null;
        var allocationStartType = -1;

        let lastAllocationMode = -1;
        let manualAllocationsMap: { [cartId: number]: PlannerGanttItem} = {};
        let automaticAllocationsMap: { [cartId: number]: PlannerGanttItem} = {};

        allocations.forEach((alloc) => {
            if(lastAllocationId != alloc.AllocationId) {
                if(lastAllocationId != -1) {
                    let child;
                    let childAlreadyExists: boolean = true;
                    if (lastAllocationMode === AllocationMode.AutomaticAllocation) {
                        child = automaticAllocationsMap[lastCartId];
                        if (!child) {
                            childAlreadyExists = false;
                            child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
                            child.Icon("fa fa-dropbox");
                            automaticAllocationsMap[lastCartId] = child;
                        }
                    }
                    else {
                        child = manualAllocationsMap[lastCartId];
                        if (!child) {
                            childAlreadyExists = false;
                            child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
                            child.Icon("fa fa-calendar");
                            manualAllocationsMap[lastCartId] = child;
                        }
                    }

                    child.Title(lastCartTitle);
                    child.Tag(this);
                    child.Id(lastCartId);
                    child.MultipleIntervals(true);
                    child.CanHaveChildren(false);
                    child.Intervals((child.Intervals() || []).concat(intervals));
                    intervals = [];

                    if (allocationStartType == AllocationStartType.FixedDate && lastAllocationMode !== AllocationMode.ManualAllocation) {
                        var marker = new PlannerGanttItemMarker(allocationStartDate, this.Gantt.StartDate);
                        marker.Id(child.Id());
                        marker.Tag(this);
                        marker.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                        marker.Type("square");
                        child.Markers.push(marker);

                        var markerForParent = new PlannerGanttItemMarker(allocationStartDate, this.Gantt.StartDate);
                        markerForParent.Id(child.Id());
                        markerForParent.Tag(this);
                        markerForParent.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                        markerForParent.Type("diamond");
                        markers.push(markerForParent);
                    }

                    incompleteAllocationMarkers.forEach((m) => {
                        child.Markers.push(m);
                    });
                    incompleteAllocationMarkers = [];

                    if (!childAlreadyExists)
                        children.push(child);

                    var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                    interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                    interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                    interval.Id(lastAllocationId);
                    if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                        interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                        interval.Style("no-estimate");
                    }
                    bigIntervals.push(interval);
                }

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
                lastAllocationId = alloc.AllocationId;
                lastAllocationMode = alloc.AllocationMode;
                lastColor = alloc.Color;
                lastCartId = alloc.CartId;
                lastCartTitle = alloc.CartName;
                allocationStartDate = alloc.StartDate;
                allocationStartType = alloc.AllocationStartType;
            }

            minDate = minDate > moment(alloc.Date) ? moment(alloc.Date) : minDate;
            maxDate = maxDate < moment(alloc.Date) ? moment(alloc.Date) : maxDate;
            lastAllocationType = alloc.AllocationType;

            if (lastHoursType != -1 && alloc.HoursType != lastHoursType) {
                var tmpMinDate = moment(maxDate);
                var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.add('days', -1).toDate(), lastCartTitle, this.Gantt.StartDate);
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                interval.Id(lastAllocationId);
                if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                    interval.Style("no-estimate");
                }
                bigIntervals.push(interval);

                minDate = tmpMinDate;
                maxDate = moment("2000-01-01");
            }

            lastHoursType = alloc.HoursType;

            if (alloc.HoursAmount > 0) {
                var interval = new PlannerGanttItemInterval(alloc.Date, alloc.Date, alloc.HoursAmount, this.Gantt.StartDate);
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                    interval.Style("no-estimate");
                }
                intervals.push(interval);
            }
            
            if (alloc.IncompleteAllocation || alloc.CartIsEmpty || alloc.CartHasUnestimatedWorkOnly || alloc.CartIsNotWorkableByTheTeam) {
                var incompleteAllocationMarker = new PlannerGanttItemMarker(alloc.Date, this.Gantt.StartDate);
                incompleteAllocationMarker.Id(alloc.Id);
                incompleteAllocationMarker.Tag(this);
                incompleteAllocationMarker.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                incompleteAllocationMarker.Type("ball");
                incompleteAllocationMarkers.push(incompleteAllocationMarker);

                markers.push(incompleteAllocationMarker);

                var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
                interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
                interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
                interval.Id(lastAllocationId);
                if (alloc.AllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                    interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                    interval.Style("no-estimate");
                }
                bigIntervals.push(interval);

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
            }
        });

        let child;
        let childAlreadyExists: boolean = true;
        if (lastAllocationMode === AllocationMode.AutomaticAllocation) {
            child = automaticAllocationsMap[lastCartId];
            if (!child) {
                childAlreadyExists = false;
                child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
                child.Icon("fa fa-dropbox");
                automaticAllocationsMap[lastCartId] = child;
            }
        }
        else {
            child = manualAllocationsMap[lastCartId];
            if (!child) {
                childAlreadyExists = false;
                child = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
                child.Icon("fa fa-calendar");
                manualAllocationsMap[lastCartId] = child;
            }
        }

        child.Title(lastCartTitle);
        child.Tag(this);
        child.Id(lastCartId);
        child.MultipleIntervals(true);
        child.CanHaveChildren(false);
        child.Intervals((child.Intervals() || []).concat(intervals));

        if (allocationStartType == AllocationStartType.FixedDate && lastAllocationMode !== AllocationMode.ManualAllocation) {
            var marker = new PlannerGanttItemMarker(allocationStartDate, this.Gantt.StartDate);
            marker.Id(child.Id());
            marker.Tag(this);
            marker.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
            marker.Type("square");
            child.Markers.push(marker);

            var markerForParent = new PlannerGanttItemMarker(allocationStartDate, this.Gantt.StartDate);
            markerForParent.Id(child.Id());
            markerForParent.Tag(this);
            markerForParent.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
            markerForParent.Type("diamond");
            markers.push(markerForParent);
        }

        incompleteAllocationMarkers.forEach((m) => {
            child.Markers.push(m);
        });
        incompleteAllocationMarkers = [];

        if (!childAlreadyExists)
            children.push(child);

        var interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, this.Gantt.StartDate);
        interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
        interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
        interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
        interval.Id(lastAllocationId);
        if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
            interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
            interval.Style("no-estimate");
        }
        bigIntervals.push(interval);

        item.Intervals(bigIntervals);
        item.Children(children);
        item.Markers(markers);
    }

    public UpdateTeam(teamId : number) {
        var foundTeams : IPlannerGanttItem[] = this.Gantt.Items().filter(t => t.Id() == teamId);
        if(foundTeams.length == 0)
            return;

        var team : IPlannerGanttItem = foundTeams[0];

        this.allocationsService.getCartAllocationForTeam(teamId)
            .then((allocations: ITeamAllocationsForGantt) => {
                this.renderTeamAllocations(team as PlannerGanttItem, allocations.Allocations as ICartAllocationForTeamWithStartDate[]);
            });
    }
}

class LegendDialog implements IDialog {
    templateName:string = "allocations-gantt-for-team-legend";
    templateUrl:string = "allocations/templates/dialogs";
    title:string = ProlifeSdk.TextResources.JobOrder.Legend;
    modal: {
        close: (result?: any) => void;
    };

    close():void {
        this.modal.close();
    }

    action():void {
        this.modal.close();
    }
}