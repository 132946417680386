import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { IWarehouseWithStockInfo } from "../../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ProxyDataSource } from "../../../../../DataSources/ProxyDataSource";
import { WarehousesDataSource } from "../../../../../DataSources/WarehousesDataSource";
import { IWarehouseDataSourceModel } from "../../../../../DataSources/WarehousesDocumentDataSource";
import { IRequestForQuotationRowShipment } from "../../../../ProvisioningService";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { TextInput } from "../../../../../Components/TextInput";
import { DateTimeInput } from "../../../../../Components/DateTimeInput";
import { NumberInput } from "../../../../../Components/NumberInput";
import { Select } from "../../../../../Components/Select";
import { IIdGeneratorService } from "../../../../../ProlifeSdk/IdGeneratorService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { createShipmentRow } from "./RequestForQuotationRowsEditor";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type RequestForQuotationRowShipmentEditorProps = {
    deliveryDate: ko.Subscribable<Date>;
    shipments: ko.ObservableArray<RequestForQuotationRowShipmentDate>;
    hasChanges: ko.Observable<boolean>;
}

export function RequestForQuotationRowShipmentEditor(props: RequestForQuotationRowShipmentEditorProps) {
    const C = require("./RequestForQuotationRowShipmentEditor")._RequestForQuotationRowShipmentEditor as typeof _RequestForQuotationRowShipmentEditor;
    return <C {...props} />;
}

export type RequestForQuotationRowShipmentDate = {
    Id: number;
    ShipmentDate: ko.Observable<Date>;
    Amount: ko.Observable<number>;
    WarehouseId: ko.Observable<number>;
    Address: ko.Observable<string>;
    City: ko.Observable<string>;
    PostalCode: ko.Observable<string>;
    Province: ko.Observable<string>;
    State: ko.Observable<string>;

    CanDelete: boolean;
    ShowAddress: ko.Observable<boolean>;
    FullAddress : ko.Computed<string>;

    toggleAddress() : void;
    onWarehouseSelected(model: IWarehouseWithStockInfo): void;

    WarehousesDataSource: ProxyDataSource<WarehousesDataSource, IWarehouseDataSourceModel>;
};

export class _RequestForQuotationRowShipmentEditor extends ComponentBase {
    static defaultProps: Partial<RequestForQuotationRowShipmentEditorProps> = {
    }

    WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();
    
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    constructor(public props : RequestForQuotationRowShipmentEditorProps) {
        super();
    }

    componentDidMount() {
        this.disposeOnUnmount(this.props.shipments.subscribe(() => this.props.hasChanges(true)));
    }

    render() {
        const shipment : IDataSourceModel<number, RequestForQuotationRowShipmentDate> = null;
        const dataSource = Table.defaultDataSource(this.props.shipments, (d) => ({ id: d.Id, title: "" }))

        const vm = {
            addShipment: () => {
                const s = createShipmentRow({
                    Id: this.idGeneratorService.getNextId(),
                    Address: null,
                    Amount: 0,
                    City: null,
                    FkrequestForQuotationRow: -1,
                    Fkwarehouse: null,
                    IsDefault: false,
                    PostalCode: null,
                    Province: null,
                    ShipmentDate: this.props.deliveryDate(),
                    State: null
                }, this)
                this.props.shipments.push(s);
            },

            removeShipment: (shipment: RequestForQuotationRowShipmentDate) => {
                this.props.shipments.remove(shipment);
            }
        };
        
        return  <Table bindTo={{ vm }} dataSource={dataSource} fixedLayout compact editable rowAs="shipment" style={{ margin: 0 }}>
                    <Column style={{ width: '18px', padding: 0, borderRight: '1px solid #ddd' }}>
                        <span class="btn-link btn-xs" title="Apri/Chiudi Indirizzo" data-bind={{ click: shipment.model.toggleAddress.bind(shipment.model) }}>
                            <i class="fa" data-bind={{ css: { 'fa-angle-right': !shipment.model.ShowAddress(), 'fa-angle-down': shipment.model.ShowAddress } }}></i>
                        </span>
                    </Column>
                    <Column title="Data Consegna" style={{ width: '200px' }}>
                        <ColumnBody>
                            {(item: ITableItem<RequestForQuotationRowShipmentDate>) => <DateTimeInput placeholder="Cons. eff." dateonly simple value={item.Data.model.ShipmentDate} parent=".main-table" />}
                        </ColumnBody>
                    </Column>
                    <Column title="Quantità" style={{ width: '200px' }} className="text-right">
                        <ColumnBody>
                            {(item: ITableItem<RequestForQuotationRowShipmentDate>) => <NumberInput format="0.00" simple value={item.Data.model.Amount} selectOnFocus />}
                        </ColumnBody>
                    </Column>
                    <Column title="Magazzino" style={{ width: '300px' }}>
                        <ColumnBody>
                            {(item: ITableItem<RequestForQuotationRowShipmentDate>) => <Select
                                placeholder="Seleziona il magazzino di consegna..."
                                dataSource={item.Data.model.WarehousesDataSource}
                                value={item.Data.model.WarehouseId}
                                onSelect={(i) => item.Data.model.onWarehouseSelected(i.model)} />}
                        </ColumnBody>
                    </Column>
                    <Column title="Indirizzo">
                        <ColumnBody>
                            {(item: ITableItem<RequestForQuotationRowShipmentDate>) => <TextInput readonly placeholder="Indirizzo di consegna" value={item.Data.model.FullAddress} />}
                        </ColumnBody>
                    </Column>
                    <Column style={{ width: '100px', textAlign: 'right' }}>
                        <ColumnHeader>
                            <button className="btn btn-primary btn-circle btn-xs btn-icon-only" style={{ marginBottom: '3px' }} data-bind={{ click: vm.addShipment.bind(vm) }}>
                                <i className="fa fa-plus" />&nbsp;Nuovo
                            </button>
                        </ColumnHeader>
                        <ColumnBody>
                            {() => <button className="btn btn-danger btn-circle btn-xs btn-icon-only" data-bind={{ click: vm.removeShipment.bind(vm, shipment.model), visible: shipment.model.CanDelete }}>
                                                    <i className="fa fa-trash-o" />&nbsp;Elimina
                                                </button>}
                        </ColumnBody>
                    </Column>
                    <SecondaryRow if={() => "shipment.model.ShowAddress"}>
                        {(item: ITableItem<RequestForQuotationRowShipmentDate>) => <>
                            <td style={{ borderRight: '1px solid #ddd' }}></td>
                            <td colSpan={4} style={{ padding: 0 }}>
                                <table className="table table-condensed inner-table compact editable">
                                    <thead>
                                        <tr>
                                            <th>Indirizzo</th>
                                            <th>CAP</th>
                                            <th>Città</th>
                                            <th>Provincia</th>
                                            <th>Stato</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><TextInput placeholder="Indirizzo..." value={item.Data.model.Address} /></td>
                                            <td><TextInput placeholder="CAP..." value={item.Data.model.PostalCode} /></td>
                                            <td><TextInput placeholder="Città..." value={item.Data.model.City} /></td>
                                            <td><TextInput placeholder="Provincia..." value={item.Data.model.Province} /></td>
                                            <td><TextInput placeholder="Stato..." value={item.Data.model.State} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </>}
                    </SecondaryRow>
                </Table>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RequestForQuotationRowShipmentEditor);
}