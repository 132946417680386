import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export interface IRequestForQuotationStatus {
    Id: number;
    Label: string;
}

export function getStatesList() : IRequestForQuotationStatus[] {
    return [
        { Id: 1, Label: TextResources.Provisioning.OnWorkingPreOrder },
        { Id: 2, Label: TextResources.Provisioning.ClosedPreOrder },
        { Id: 3, Label: TextResources.Provisioning.PartiallyManagedPreOrder }
    ];
}