import * as ko from "knockout";
import * as numeral from "numeral";

import { CurrencyUtils } from "../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { KnockoutType } from "@abstraqt-dev/jsxknockout";
import { IDocumentCurrencyViewModel } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

declare global {
    interface KnockoutDataBindsAttributes {
        moneyText?: KnockoutType<number>;
        extendedMoneyFormat?: KnockoutType<boolean>;
        currencySymbol?: KnockoutType<string>;
        documentCurrency?: KnockoutType<IDocumentCurrencyViewModel>;
    }
}

export class MoneyTextEx {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var extendedMoneyFormat = allBindingsAccessor()["extendedMoneyFormat"] == true;
        let currencySymbol = allBindingsAccessor()["currencySymbol"];
        let documentCurrency = allBindingsAccessor()["documentCurrency"];

        let underlyingCurrency = ko.utils.unwrapObservable(documentCurrency);

        var interceptor = ko.computed({
            read: function() {
                let format = MoneyTextEx.createFormatString(extendedMoneyFormat, underlyingCurrency);
                let formattedValue = numeral(ko.utils.unwrapObservable(underlyingObservable) || 0).format(format);

                if (currencySymbol)
                    formattedValue = MoneyTextEx.replaceCurrencySymbol(formattedValue, ko.utils.unwrapObservable(currencySymbol));

                return formattedValue;
            },
            write: function(newValue: string) {
                if(!newValue)
                    newValue = "0";

                let parts = newValue.split(" "); // Separa il numero dal simbolo della valuta perché in alcuni simboli è presente il carattere '.' e questo crea problemi nel codice seguente
                newValue = parts[0];

                var components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if(components.length > 1)
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);

                var valueToWrite = numeral(newValue).value();
                //if(valueToWrite != newValue)
                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
    
    public  static replaceCurrencySymbol(value: string, newCurrencySymbol: string): string {
        let partial = value.substring(0, value.length - 1);
        return partial + newCurrencySymbol;
    }

    public static createFormatString(extendedMoneyFormat: boolean, documentCurrency: IDocumentCurrencyViewModel): string {
        let format = extendedMoneyFormat ? '0,0[.]0000 $' : '0,0[.]00 $';

        if (!extendedMoneyFormat && documentCurrency) {
            let numberOfDecimals = CurrencyUtils.getNumberOfDecimalsForCurrency(documentCurrency);
            format = "0,0";

            let decimals = "";

            while (numberOfDecimals > 0) {
                decimals += "0";
                numberOfDecimals--;
            }

            format = (!decimals ? format : format + "[.]" + decimals) + " $";
        }

        return format;
    }
}

export class MoneyValueEx {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var extendedMoneyFormat = allBindingsAccessor()["extendedMoneyFormat"] == true;
        let currencySymbol = allBindingsAccessor()["currencySymbol"];
        let documentCurrency = allBindingsAccessor()["documentCurrency"] as IDocumentCurrencyViewModel;
        
        var interceptor = ko.computed({
            read: function() {
                let format = MoneyTextEx.createFormatString(extendedMoneyFormat, documentCurrency);
                let formattedValue = numeral(ko.utils.unwrapObservable(underlyingObservable) || 0).format(format);

                if (currencySymbol)
                    formattedValue = MoneyTextEx.replaceCurrencySymbol(formattedValue, ko.utils.unwrapObservable(currencySymbol));

                return formattedValue;
            },
            write: function(newValue: string) {
                if(!newValue)
                    newValue = "0";

                let parts = newValue.split(" "); // Separa il numero dal simbolo della valuta perché in alcuni simboli è presente il carattere '.' e questo crea problemi nel codice seguente
                newValue = parts[0];

                var components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if(components.length > 1)
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);

                var valueToWrite = numeral(newValue).value();
                //if(valueToWrite != newValue)
                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

ko.bindingHandlers["moneyTextEx"] = new MoneyTextEx();
ko.bindingHandlers["moneyValueEx"] = new MoneyValueEx();