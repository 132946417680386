import * as ko from "knockout";
import { IWordFound } from "../../interfaces/IWordFound";

export class WordFound implements IWordFound {
    word: string;
    selected: ko.Observable<boolean>;

    constructor()
    {
        this.word = null;
        this.selected = ko.observable(false);
    }
}

