import * as ko from "knockout";
import { RequirementRowSourceBase } from "../RequirementRowSourceBase";
import { IRequirement } from "../../../ProvisioningService";

export class RequirementSource extends RequirementRowSourceBase {
    public MatchWarehouseFilter: ko.Observable<boolean> = ko.observable(true);
    public MatchCustomerFilter: ko.Observable<boolean> = ko.observable(true);
    public MatchJobOrderFilter: ko.Observable<boolean> = ko.observable(true);
    public MatchOrderNumberFilter: ko.Observable<boolean> = ko.observable(true);
    public MatchProtocolFilter: ko.Observable<boolean> = ko.observable(true);
    public MatchDateRangeFilter: ko.Observable<boolean> = ko.observable(true);

    public MatchFilters: ko.Computed<boolean>;

    constructor(protected requirement: IRequirement) {
        super(requirement);

        this.Selected(false);
        
        this.MatchFilters = ko.computed(() => {
            return this.MatchWarehouseFilter()
                && this.MatchCustomerFilter()
                && this.MatchJobOrderFilter()
                && this.MatchOrderNumberFilter()
                && this.MatchProtocolFilter()
                && this.MatchDateRangeFilter();
        });
    }
}