import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BasePagedList } from "../Utilities/BasePagedList";
import { StockArticle } from "./StockArticle";
import { Article } from "../Articles/Article";
import { CreateNewArticleDialog } from "../Articles/CreateNewArticleDialog";
import { ArticleWarehousesStockedInDialog } from "../Articles/ArticleWarehousesStockedInDialog";
import { ArticleDetailsDialog } from "../Articles/ArticleDetailsDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IStockService, IStockArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import jss from "jss";
import { Layout } from "../../../../Components/Layouts";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

const styleSheet = jss.createStyleSheet({
    articlesStockManagement: {
        "& table.table > thead": {
            "& > tr": {
                position: "sticky",
                top: 0,

                "& > th": {
                    fontSize: "0.9em",
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type ArticlesStockManagementProps = {
    searchFilter: ko.Observable<string>;
    warehouseId: ko.Observable<number>;
    customerId: ko.Observable<number>;
    showAllMoved: ko.Observable<boolean>;
    forwardRef?: (list: BasePagedList) => void;
};

export function ArticlesStockManagement(props: ArticlesStockManagementProps) {
    const C = require("./ArticlesStockManagement")._ArticlesStockManagement as typeof _ArticlesStockManagement;
    return <C {...props} />;
}

export class _ArticlesStockManagement extends BasePagedList {
    static defaultProps = {};

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(ProlifeSdk.StockServiceType)
    private stockService: IStockService;

    constructor(public props: ArticlesStockManagementProps) {
        super(props.searchFilter);

        this.props.warehouseId.subscribe(() => this.loadRows());
        this.props.showAllMoved.subscribe(() => this.loadRows());

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    componentDidMount() {
        this.loadRows();
    }

    public ShowArticleInfo(article: Article) {
        const dialog = new CreateNewArticleDialog(true);
        dialog.SetWarehouse(this.props.warehouseId());
        dialog.loadAndShow(article.getData());
    }

    public ShowArticleDetails(article: Article) {
        const dialog = new ArticleDetailsDialog(
            article.RealArticleId(),
            article.Code(),
            article.Description(),
            this.props.customerId(),
            this.props.warehouseId()
        );
        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    public ExportExcel() {
        const url =
            "Warehouse/StockReport/GenerateExcel?warehouseId=" +
            this.props.warehouseId() +
            "&showOnlyStocked=" +
            this.props.showAllMoved() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public Print() {
        const url =
            "Warehouse/StockReport/GeneratePdf?warehouseId=" +
            this.props.warehouseId() +
            "&showOnlyStocked=" +
            this.props.showAllMoved() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public ShowStatusLegend(article: StockArticle) {
        const vm: IDialog = new ArticleStockStatusLegend();
        this.dialogService.ShowModal<void>(vm, null, {}, vm.templateUrl, vm.templateName);
    }

    public ShowWhereIsStocked(article: Article) {
        const whereisStockedDialog = new ArticleWarehousesStockedInDialog(article.RealArticleId());
        this.dialogService.ShowModal<void>(whereisStockedDialog, "", { noPrompt: true });
    }

    public async fetchRows(): Promise<IStockArticle[]> {
        if (!this.props.warehouseId()) return [];

        return this.stockService.getStocks(
            this.searchFilter(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage,
            this.props.warehouseId(),
            this.props.showAllMoved()
        );
    }

    public async fetchRowsCount(): Promise<number> {
        if (!this.props.warehouseId()) return 0;

        return this.stockService.getStocksCount(
            this.searchFilter(),
            this.props.warehouseId(),
            this.props.showAllMoved()
        );
    }

    public createViewModelFor(stockArticle: IStockArticle): StockArticle {
        return new StockArticle(stockArticle);
    }

    render() {
        const asm = this;
        let page: number;
        return ComponentUtils.bindTo(
            <>
                <Layout.ScrollContainer
                    systemScrollable
                    verticalOnly
                    className={classes.articlesStockManagement}
                    style={{
                        position: "absolute",
                        top: "120px",
                        bottom: "60px",
                        left: "20px",
                        right: "20px",
                        overflowY: "auto",
                    }}
                >
                    <table className="table table-advance">
                        <thead>
                            <tr>
                                <th>Codice</th>
                                <th>Descrizione</th>
                                <th>Tipologia</th>
                                <th className="text-right">Q.ta Libera</th>
                                <th className="text-right">Quantit&agrave;</th>
                                <th className="text-right">Totale</th>
                                <th className="text-right">Ordinati</th>
                                <th className="text-right">Riservati</th>
                                <th className="text-right">Tot. Riservati</th>
                                <th>UdM</th>
                                <th className="text-right">Min.Qt.</th>
                                <th className="text-right">Posizione</th>
                                <th className="text-right">Costo Medio</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ko-bind data-bind={{ if: asm.Loading }}>
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        <h3>Caricamento articoli in corso...</h3>
                                    </td>
                                </tr>
                            </ko-bind>
                            <ko-bind data-bind={{ foreach: asm.rows }}>
                                <tr>
                                    <td data-bind="text: Code"></td>
                                    <td data-bind="text: Description"></td>
                                    <td data-bind="text: PurchaseType"></td>
                                    <td className="text-right" data-bind="numberText: Free"></td>
                                    <td className="text-right" data-bind="numberText: StockAmount"></td>
                                    <td className="text-right" data-bind="numberText: TotalStockAmount"></td>
                                    <td className="text-right" data-bind="numberText: Orders"></td>
                                    <td className="text-right" data-bind="numberText: Reservations"></td>
                                    <td className="text-right" data-bind="numberText: TotalReservations"></td>
                                    <td data-bind="text: UOM"></td>
                                    <td className="text-right" data-bind="numberText: MinimumAmount"></td>
                                    <td className="text-right" data-bind="text: WarehousePosition"></td>
                                    <td className="text-right" data-bind="moneyText: Cost"></td>
                                    <td style="width : 30px; text-align : center">
                                        <i
                                            className="fa"
                                            data-bind="css : { 'fa-flag' : State() == 1, 'fa-warning' : State() == 3, 'fa-ban': State() == 9 }"
                                        ></i>
                                    </td>
                                    <td style="text-align:right; white-space: nowrap">
                                        <a
                                            href="#"
                                            className="btn btn-xs article-status-on-warehouse default"
                                            data-bind="attr : { 'status-for-warehouse' : StockStatus }, click : $parent.ShowStatusLegend.bind($parent)"
                                            title="Stato"
                                        >
                                            <i className="fa fa-cubes"></i>
                                        </a>
                                        <a
                                            href="#"
                                            className="btn btn-xs btn-primary"
                                            data-bind="click : $parent.ShowWhereIsStocked.bind($parent, $data)"
                                            title="Magazzini su cui l'articolo è stato movimentato"
                                        >
                                            <i className="fa fa-database"></i>
                                        </a>
                                        <a
                                            href="#"
                                            className="btn btn-xs btn-primary"
                                            data-bind="click : $parent.ShowArticleDetails.bind($parent)"
                                            title="Documenti che utilizzano l'articolo"
                                        >
                                            <i className="fa fa-eye"></i>
                                        </a>
                                        <a
                                            href="#"
                                            className="btn btn-xs default"
                                            data-bind="click : $parent.ShowArticleInfo.bind($parent, $data)"
                                            title="Dettagli articolo"
                                        >
                                            <i className="fa fa-info-circle"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ko-bind>
                        </tbody>
                    </table>
                </Layout.ScrollContainer>

                <div style={{ position: "absolute", bottom: 0, left: "20px" }}>
                    <ul className="pagination">
                        <li data-bind={{ css: { disabled: asm.isOnFirstPage() || asm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: asm.goToPrevPage }}>
                                &larr; Prec
                            </a>
                        </li>

                        <ko-bind data-bind={{ foreach: { data: asm.pages, as: "page" } }}>
                            <li
                                data-bind={{
                                    css: { disabled: asm.SomeFilterIsChanged(), active: asm.selectedPage() == page },
                                }}
                            >
                                <a href="#" data-bind={{ text: page + 1, click: asm.goToPage.bind(asm, page) }}></a>
                            </li>
                        </ko-bind>

                        <li data-bind={{ css: { disabled: asm.isOnLastPage() || asm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: asm.goToNextPage }}>
                                Succ &rarr;
                            </a>
                        </li>
                    </ul>
                </div>
            </>,
            this,
            "asm"
        );
    }
}

export class ArticleStockStatusLegend implements IDialog {
    public templateName: string = "article-stock-status-legend-dialog";
    public templateUrl: string = "warehouse/templates";
    title: string;
    modal: {
        close: (result?: any) => void;
    };

    constructor() {}

    close(): void {
        this.modal.close();
    }

    action(): void {}
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ArticlesStockManagement);
}
