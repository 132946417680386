import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import jss from "jss";

const styleSheet = jss.createStyleSheet({
    "@global": {
        ".tempus-dominus-widget": {
            width: "29rem !important",
        },
    },
});
const { classes } = styleSheet.attach();

type DateTimeInputProps = {
    label?: ko.MaybeObservable<string>;
    helpText?: ko.MaybeObservable<string>;
    placeholder?: ko.MaybeSubscribable<string>;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean> | (() => boolean);
    value?: ko.Observable<Date> | ko.Computed<Date>;
    allowClear?: boolean;
    simple?: boolean;
    noIcon?: boolean;
    dateonly?: boolean;
    parent?: string;
    className?: string;
    onChange?: (d: Date) => void;

    onFocus?: (element: HTMLInputElement) => void;
    onBlur?: (element: HTMLInputElement) => void;
};

export function DateTimeInput(props: DateTimeInputProps) {
    const C = require("./DateTimeInput")._DateTimeInput as typeof _DateTimeInput;
    return <C {...props} />;
}

export class _DateTimeInput {
    static defaultProps: Partial<DateTimeInputProps> = {
        allowClear: false,
        noIcon: false,
        dateonly: false,
        placeholder: "",
    };

    private valueChangeSubscription: ko.Subscription;

    private hasFocus: ko.Observable<boolean> = ko.observable(false);
    private inputRef: HTMLInputElement = null;

    constructor(private props: DateTimeInputProps) {
        if (!this.props.value) this.props.value = ko.observable();

        if (
            !ko.isObservable(this.props.readonly) &&
            !ko.isComputed(this.props.readonly) &&
            typeof this.props.readonly === "function"
        )
            this.props.readonly = ko.computed(this.props.readonly);

        this.valueChangeSubscription = this.props.value.subscribe(() => {
            this.props.onChange && this.props.onChange(this.props.value());
        });

        if (this.props.onFocus) this.hasFocus.subscribe((value) => value && this.props.onFocus(this.inputRef));
        if (this.props.onBlur) this.hasFocus.subscribe((value) => !value && this.props.onBlur(this.inputRef));
    }

    componentWillUnmount() {
        this.valueChangeSubscription.dispose();
    }

    private renderLabel() {
        const ti = this;
        const labelClasses = ComponentUtils.classNames("control-label");

        if (ko.isObservable(ti.props.label))
            return (
                <ko-bind data-bind={{ if: !!ti.props.label() }}>
                    {ti.props.label() === "" ? (
                        <label className={labelClasses}>&nbsp;</label>
                    ) : (
                        <label className={labelClasses} data-bind={{ text: ti.props.label }}></label>
                    )}
                </ko-bind>
            );

        return this.props.label ? (
            <label className={labelClasses}>{ti.props.label}</label>
        ) : (
            <label className={labelClasses}>&nbsp;</label>
        );
    }

    private renderInput() {
        const dti = this;
        const className = ComponentUtils.classNames("form-control", this.props.className);

        const wrapWithIcon = (input: React.ReactElement) => {
            if (this.props.noIcon) return input;

            return (
                <div className={ComponentUtils.classNames("input-icon right", this.props.className)}>
                    <i className="fa fa-calendar"></i>
                    {input}
                </div>
            );
        };

        return wrapWithIcon(
            <input
                className={className}
                type="text"
                data-bind={{
                    disable: dti.props.readonly,
                    newDateValue: {
                        value: dti.props.value,
                        dateOnly: dti.props.dateonly,
                        initialDate: dti.props.value,
                        allowClear: dti.props.allowClear,
                        parent: dti.props.parent,
                    },
                    attr: { placeholder: dti.props.placeholder },
                    hasFocus: dti.hasFocus,
                }}
                ref={(r) => (this.inputRef = r)}
            />
        );
    }

    private renderHelp() {
        const ti = this;

        if (ko.isObservable(ti.props.helpText))
            return (
                <ko-bind data-bind={{ if: !!ti.props.helpText() }}>
                    <span class="help-block" data-bind={{ text: ti.props.helpText }}></span>
                </ko-bind>
            );

        return ti.props.helpText && <span className="help-block">{ti.props.helpText}</span>;
    }

    render() {
        if (this.props.simple) {
            return ComponentUtils.bindTo(this.renderInput(), this, "dti");
        }

        return ComponentUtils.bindTo(
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderHelp()}
            </div>,
            this,
            "dti"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DateTimeInput);
}
