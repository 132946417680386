import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 05/06/13
 * Time: 11.06
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IFinancialDataProvider } from "../../../ProlifeSdk/interfaces/financial-data/IFinancialDataProvider";
import { IWorkSheetRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import { IFinancialDataService } from "../../../ProlifeSdk/interfaces/financial-data/IFinancialDataService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ISearchFilter } from "../../../ProlifeSdk/interfaces/ISearchFilter";


export class WorkedHoursFinancialDataProvider implements IFinancialDataProvider
{
    private logService : ILogService;
    private ajaxService : IAjaxService;
    private sdkService : IProLifeSdkService;

    constructor(serviceLocator : IServiceLocator)
    {
        var financialService = <IFinancialDataService>serviceLocator.findService(ProlifeSdk.FinancialDataServiceType);
        financialService.registerProvider(this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.sdkService = <IProLifeSdkService> serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
    }

    deleteByEventId(eventId : number) : Promise<void>
    {
        return this.ajaxService.Delete("Blog-api", "WorkedHours/" + eventId, {});
    }

    getType() : string
    {
        return ProlifeSdk.FinancialDataWorkedHoursProviderType;
    }

    getByWorkEventId(eventId : number)
    {
        return this.ajaxService.Get("Blog-api/WorkedHours", "GetByWorkEventId?id=" + eventId, {})
            ;
    }

    GetReferencerDocsForHours(hoursId : number) : Promise<any> //Interfaccia per il tipo ReferencerDocForHour[] non esiste
    {
        return this.ajaxService.Get("Blog-api/WorkedHours", "GetReferencerDocsForHours?hoursId=" + hoursId, {})
            ;
    }

    getData(skip : number, count: number, jobOrderId : number, filters : ISearchFilter[] = []) : Promise<IWorkSheetRow[]>
    {
        filters.push({
            Tags : [{
                ValueTypeId : ProlifeSdk.JobOrderEntityType,
                Values : [jobOrderId.toString()]
            }]
        });

        filters.forEach((f : ISearchFilter) => {
            f.From = this.sdkService.Utilities.Dates.AddGmtTime(f.From),
                f.To = this.sdkService.Utilities.Dates.AddGmtTime(f.To)
        });

        return this.ajaxService.Post("Blog-api/WorkedHours", "Search", {
            methodData: { skip : skip, count : count, LogFilters : filters }
        });
    }

    getDataForMonthlyInvoicing(jobOrderId: number, jobOrderType: number, customerId: number, billed: boolean, from: Date, to: Date): Promise<any[]> {
        return Promise.resolve<any[]>([]);
    }

    getDataForMonthlyInvoicingCount(jobOrderId: number, jobOrderType: number, customerId: number, billed: boolean, from: Date, to: Date): Promise<number> {
        return Promise.resolve<number>(0);
    }

    setSalId(ids : number[], salId: number) : Promise<void> {
        return this.ajaxService.Post("Blog-api/WorkedHours", "SetSalId", {
            methodData: { workedHoursIds: ids, salId: salId }
        });
    }

    save(workedHoursRecord : IWorkSheetRow[]) : Promise<IWorkSheetRow[]>
    {
        return this.ajaxService.Post("Blog-api", "WorkedHours", {
            methodData: { Records : workedHoursRecord }
        });
    }

    checkConsistency(workedHoursRecord : IWorkSheetRow[])
    {
        return this.ajaxService.Post("Blog-api", "WorkedHours/CheckConsistency", {
            methodData: { Records : workedHoursRecord }
        });
    }
}