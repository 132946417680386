import * as ko from "knockout";
import { MergedReportComponentConstructor, ReportComponent, ReportComponentConstructor } from "./ReportComponent";

export type ReportComponentWithDataSourceId = {
    dataSourceId: ko.Observable<number>;
} & ReportComponent;

type ReportComponentWithDataSourceIdProps = {
    dataSourceId?: number;
}

export function HasDataSourceId<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithDataSourceId, ReportComponentWithDataSourceIdProps> {
    return class ReportComponentWithDataSourceId extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "DataSourceId"];

            const { dataSourceId } = args[0] as ReportComponentWithDataSourceIdProps
            this.dataSourceId(dataSourceId);
        }

        getData() {
            const data = super.getData();
            return {
                ...data,
                dataSourceId: this.dataSourceId()
            }
        }

        dataSourceId: ko.Observable<number> = ko.observable();
    }
}