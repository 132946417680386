/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 03/10/2017
 * Time: 11:40
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import "./AllocationsGanttComponent";
import { Moment } from "moment";
import { CartsFactory } from "../utilities/CartsFactory";
import { PlannerGanttItem, PlannerGanttItemInterval, PlannerGanttItemMarker } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { CartForList } from "./CartForList";
import { CartInfoDialog } from "./CartInfoDialog";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { AllocationMode } from "../enums/AllocationMode";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../../DataSources/JobOrdersDataSource";
import { UiUtilities } from "../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IDataSourceListener, IDataSource } from "../../../DataSources/IDataSource";
import { IAllocationsService, IAllocationWithJobOrderActivities, ICartAllocationTeam, ITeamAllocationsForGantt, IActivitiesDatesInfoWithMarker, IActivitiesDatesInfo, IDraggedAllocation, IDraggedCart, IMissingRoleOnTeam, IDraggedCartForList, IMissingRole, ICartAllocation } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDraggedTask, IDraggedWorkflow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IPlannerGanttItem, IPlannerGanttInterval, IPlannerGanttMarker } from "../../../ProlifeSdk/interfaces/controls/gantt/PlannerGanttInterfaces";
import { IDialogsService, ShowModalOptions, IDialog } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICartsFactory } from "../../interfaces/ICartsFactory";
import { ICartsManager } from "../../interfaces/ICartsManager";
import { ICart } from "../../../ProlifeSdk/interfaces/allocations/ICart";
import { Deferred } from "../../../Core/Deferred";
import { IncompleteAllocationInfo } from "./dialogs/IncompleteAllocationInfo";
import { CartLinksPopOver } from "./CartLinksPopOver";
import { CartContentDialog } from "./dialogs/CartContentDialog";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { TeamsPopover } from "./dialogs/TeamsPopover";

export class AllocationsGantt extends PlannerGantt implements IDataSourceListener {
    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService! : IDesktopService;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    public AllowCartsDrag: ko.Observable<boolean> = ko.observable();
    public AllocationMode: ko.Observable<number> = ko.observable();

    public ShowAllTeams: ko.Observable<boolean> = ko.observable(false);
    public CanSeeButtonShowAllTeams: ko.Observable<boolean> = ko.observable(false);


    public AllowedMimeTypes: string[] = ['application/prolife-gantt-cart', 'application/prolife-gantt-carts'];
    public AllowedGanttMimeTypes: string[] = ['application/prolife-cart', 'application/prolife-task', 'application/prolife-workflow', 'application/prolife-team-cart-allocation' ];
    public SelectedCartsItems: ko.Computed<IPlannerGanttItem[]>;

    // -- Gestione opzioni di ricerca (da rivedere  non dovrebbero stare qui secondo me)
    public GenericSearchSelected: ko.Observable<boolean> = ko.observable(false);
    public JobOrderSearchSelected: ko.Observable<boolean> = ko.observable(true);

    public JobOrdersDataSource: JobOrdersDataSource;
    public SelectedJobOrder: ko.Observable<number> = ko.observable();

    public SelectGenericSearchOption(): void {
        this.SelectedJobOrder(null);

        this.GenericSearchSelected(true);
        this.JobOrderSearchSelected(false);
    }

    public SelectJobOrderSearchOption(): void {
        this.GenericSearchSelected(false);
        this.JobOrderSearchSelected(true);
    }
    // --

    private CartsFactory: ICartsFactory;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService : IAllocationsService;
    @LazyImport(nameof<ICartsService>())
    private cartsService: ICartsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, private cartsManager : ICartsManager) {
        super();

        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.CanSeeButtonShowAllTeams(this.authorizationService.isAuthorized("Allocations_ShowAllTeams"));

        this.VisibleItems = ko.computed(() => {
            return this.AllVisibleItems().length + this.Items().filter(i => i.Visible() && i.IsExpanded() && i.Children().length === 0).length;
        });

        this.AllowCartsDrag(true);
        this.AllowDragAndDrop(false);
        this.DaySizeInPixels(60);
        this.WeekEndDayColor('#fff2f2');
        this.SearchInChildren(true);

        this.ShowAllTeams.subscribe(() => {
            this.RefreshGantt();
        })

        this.CartsFactory = new CartsFactory();

        this.AllocationMode.subscribe((mode: number) => {
            this.AllowCartsDrag(mode === AllocationMode.AutomaticAllocation);
        });

        this.SelectedCartsItems = ko.computed(() => {
            const items = this.Items() || [];
            let allSelectedCarts = [];
            items.filter((i) => { 
                const selectedCarts = i.Children().filter((ci) => ci.Selected());
                allSelectedCarts = allSelectedCarts.concat(selectedCarts);
            });

            return allSelectedCarts;
        });

        this.Load();

        if(localStorage.getItem('AllocationsGanttTutorial1') || this.desktopService.isMobileBrowser())
            return;

        setTimeout(() => this.allocationsService.startTutorial(), 5000);
    }

    public SwitchShowAllTeams(){
        this.ShowAllTeams(!this.ShowAllTeams())
    }
    
    public onItemSelected(sender: IDataSource, model: IJobOrderDataSourceModel): void {
        if (!model?.id)
            this.resetJobOrdersFilterToGantt();
        else
            this.applyJobOrderFilterToGantt(model.id);
    }
    
    private async applyJobOrderFilterToGantt(jobOrderId: number): Promise<void> {
        const allocations = await this.allocationsService.SearchAllocationsByJobOrderId(jobOrderId);
        const ganttItems = this.Items();

        for (const teamItem of ganttItems) {
            let hideTeam = true;
            this.applyJobOrderFilterOnGanttIntervals(teamItem, allocations);

            const cartsItems = teamItem.Children();

            for (const cart of cartsItems) {
                const allocationId = cart.Metadata["AllocationId"];

                if (!allocations.firstOrDefault(a => a.AllocationId === allocationId)) {
                    cart.Disabled(true);
                    this.applyJobOrderFilterOnGanttIntervals(cart, allocations);
                    continue;
                }

                cart.Disabled(false);
                hideTeam = false;
            }

            teamItem.Disabled(hideTeam);
        }
    }

    private applyJobOrderFilterOnGanttIntervals(ganttItem: IPlannerGanttItem, allocationsWithJobOrderActivities: IAllocationWithJobOrderActivities[]): void {
        const intervals = ganttItem.Intervals();
        for (const interval of intervals) {
            if (!allocationsWithJobOrderActivities.firstOrDefault(a => a.AllocationId === interval.Id()))
                interval.makeTransparent();
        }
    }
    
    private resetJobOrdersFilterToGantt(): void {
        const ganttItems = this.Items();

        for (const teamItem of ganttItems) {
            teamItem.Visible(true);
            teamItem.Disabled(false);
            this.resetIntervalsColor(teamItem);

            const cartsItems = teamItem.Children();
            for (const cart of cartsItems) {
                cart.Visible(true);
                cart.Disabled(false);
                this.resetIntervalsColor(cart);
            }
        }
    }

    private resetIntervalsColor(ganttItem: IPlannerGanttItem): void {
        const intervals = ganttItem.Intervals();
        for (const interval of intervals)
            interval.restoreColor();
    }
    
    public onItemDeselected(sender: IDataSource, model: IJobOrderDataSourceModel): void {
        
    }

    public init(autoHeightParent = "") {
        const fixedTable = (el) => {
            if(el.length == 0)
                return;

            let $body, $header, $sidebar, $outer, $headerOuter, $sidebarOuter;
            $outer = $(el);
            $body = $(el).find('.fixedTable-body');
            $headerOuter = $(el).find('.fixedTable-header');
            $sidebar = $(el).find('.fixedTable-sidebar > div.items');
            $sidebarOuter = $(el).find('.fixedTable-sidebar');
            $header = $(el).find('.fixedTable-header table');

            $($body).scroll(() => {
                $($sidebar).css('margin-top', -$($body).scrollTop());
                $($header).css('margin-left', -$($body).scrollLeft());

                this.ViewingStartDate(moment(this.StartDate()).add('hours', ($($body).scrollLeft() / this.DaySizeInPixels()) * 24).toDate());
                this.ViewingEndDate(moment(this.ViewingStartDate()).add('hours', ($($body).width() / this.DaySizeInPixels()) * 24).toDate());
            });
        };
        
        fixedTable($('.fixedTable'));
    }

    public Load() {
        this.allocationsService.getAllocatedTeams(this.ShowAllTeams())
            .then((teams : ICartAllocationTeam[]) => {
                let maxDate : Moment = moment();
                const deferreds : Promise<any>[] = [];

                teams.forEach((t) => {
                    const item = new PlannerGanttItem(this.StartDate, this);
                    item.Title(t.Name);
                    item.Tag(this);
                    item.Id(t.TeamId);
                    item.Metadata["TeamId"] = t.TeamId.toString();
                    item.loadOpeningState();
                    item.SetEditingDialog(async () => {
                        const dialog = new CartInfoDialog(this.serviceLocator, t.TeamId, t.Name, null);
                        await dialog.show();
                        this.cartsManager.RefreshMenu();
                    });

                    item.MultipleIntervals(true);
                    item.CanHaveChildren(true);

                    this.Items.push(item);

                    const def = new Deferred();
                    deferreds.push(def.promise());

                    this.allocationsService.getCartAllocationForTeam(t.TeamId)
                        .then((allocationsInfo : ITeamAllocationsForGantt) => {
                            this.renderTeamAllocations(item, allocationsInfo);

                            item.Intervals().forEach((i : PlannerGanttItemInterval) => {
                                if(maxDate < moment(i.EndDate()))
                                    maxDate = moment(i.EndDate());
                            });

                            def.resolve();
                        });
                });

                Promise.all(deferreds)
                    .then(() => {
                        maxDate = maxDate.add('months', 3);
                        this.EndDate(maxDate.toDate());

                        setTimeout(() => {
                            this.ScrollToTodayMarker(2);
                            const jobOrderId = this.SelectedJobOrder();
                            if (jobOrderId)
                                this.applyJobOrderFilterToGantt(jobOrderId);
                        }, 0);
                    });
            });
    }

    public ShowLegend():void {
        const legendDialog = new LegendDialog();
        const options : ShowModalOptions = {
            noPrompt: true
        };
        this.dialogsService.ShowModal<void>(legendDialog, "", options);
    }

    public async ShowCartLinksPopOver(ganttItem: PlannerGanttItem, evt: PointerEvent): Promise<void> {
        const cartLinks = await this.cartsService.GetCartsLinksWithInfo(ganttItem.Metadata["CartId"] as number);

        const popover = new CartLinksPopOver({ cartLinks: cartLinks });
        return this.dialogsService.ShowPopoverComponent(evt.target as HTMLElement, popover, "right");
    }

    public ShowTour(): void {
        this.allocationsService.startTutorial();
    }


    private setGanttStartDate(startDate: Date): void {
        const date = moment(startDate);
        const ganttDate = moment(this.StartDate());
        if (ganttDate > date)
            this.StartDate(date.startOf('day').toDate());
    }

    private renderTeamAllocations(item : PlannerGanttItem, allocationsInfo : ITeamAllocationsForGantt) {
        const allocations = allocationsInfo.Allocations;
        const activitiesDates = allocationsInfo.ActivitiesDatesInfos;

        if (allocations && allocations.length > 0)
            this.setGanttStartDate(allocations[0].Date);

        const bigIntervals : PlannerGanttItemInterval[] = [];
        let intervals = [];
        const children: AllocationsPlannerGanttItem[] = [];

        let markers: PlannerGanttItemMarker[] = [];
        let markersForParent: PlannerGanttItemMarker[] = [];

        let lastTeamId = -1;
        let lastCartId = -1;
        let lastPriority = -1;
        let lastHasLinks = false;
        let lastHasUnfulfilledLinks = false;
        let lastCartOrder = -1;
        let lastCartTitle = "";
        let lastAllocationId = -1;
        let lastAllocationType = -1;
        let lastHoursType = - 1;
        let lastHoursAmountStartDate = -1;
        let lastHoursAmountEndDate = -1;
        let lastColor = "#000000";
        let minDate = moment("2100-01-01");
        let maxDate = moment("2000-01-01");

        let lastAllocationMode = -1;
        let lastAllocatedOnTeamsNumber = -1;
        const manualAllocationsMap: { [cartId: number]: AllocationsPlannerGanttItem } = {};
        const automaticAllocationsMap: { [cartId: number]: AllocationsPlannerGanttItem } = {};

        allocations.forEach((alloc) => {
            if (lastAllocationId != alloc.AllocationId || lastAllocationType != alloc.AllocationType) {
                //Primo ciclo lo salta la prima volta
                if (lastAllocationId != -1) {
                    let child: AllocationsPlannerGanttItem;
                    let childAlreadyExists = true;
                    if (lastAllocationMode === AllocationMode.AutomaticAllocation) {
                        child = automaticAllocationsMap[lastCartId];
                        if (!child) {
                            childAlreadyExists = false;
                            child = new AllocationsPlannerGanttItem(this, lastCartId, lastCartOrder, lastAllocationMode);
                            child.Icon("fa fa-dropbox");
                            child.Metadata["AllocatedOnTeamsNumber"] = lastAllocatedOnTeamsNumber;
                            automaticAllocationsMap[lastCartId] = child;
                        }
                    }
                    else {
                        child = manualAllocationsMap[lastCartId];
                        if (!child) {
                            childAlreadyExists = false;
                            child = new AllocationsPlannerGanttItem(this, lastCartId, lastCartOrder, lastAllocationMode);
                            child.Icon("fa fa-calendar");
                            child.Metadata["AllocatedOnTeamsNumber"] = lastAllocatedOnTeamsNumber;
                            manualAllocationsMap[lastCartId] = child;
                        }
                    }

                    child.Title(lastCartTitle);
                    child.Tag(this);
                    child.Id(lastCartId);
                    child.MultipleIntervals(true);
                    child.CanHaveChildren(false);
                    child.Intervals((child.Intervals() || []).concat(intervals));
                    child.Metadata["TeamId"] = item.Id();
                    child.Metadata["CartId"] = lastCartId;
                    child.Metadata["AllocationId"] = lastAllocationId;
                    child.Metadata["AllocationMode"] = lastAllocationMode;
                    child.Metadata["AllocationPriority"] = lastPriority;
                    child.Metadata["HasLinks"] = lastHasLinks;
                    child.Metadata["HasUnfulfilledLinks"] = lastHasUnfulfilledLinks;
                    child.Metadata["CartOrder"] = lastCartOrder;

                    child.loadOpeningState();

                    intervals = [];

                    child.SetEditingDialog(async () => {
                        const dialog = new CartInfoDialog(this.serviceLocator, item.Id(), item.Title(), child.Id());
                        await dialog.show();
                        this.cartsManager.RefreshMenu();
                    });

                    // Prendo i marker dalle activitiesInfo
                    const activitiesMarkers = this.extractMarkersFromActivitiesInfo(activitiesDates, lastAllocationId, lastColor);
                    markers = markers.concat(activitiesMarkers);
                    markersForParent = markersForParent.concat(activitiesMarkers);

                    child.Markers(markers);
                    markers = [];

                    if (!childAlreadyExists)
                        children.push(child);

                    AllocationsGantt.createBigInterval(this.StartDate, minDate, maxDate, lastCartTitle, lastColor, lastAllocationId, lastTeamId, lastCartId, lastHoursAmountStartDate, lastHoursAmountEndDate, lastAllocationType, lastHoursType, bigIntervals);
                }

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
                lastAllocationId = alloc.AllocationId;
                lastAllocationMode = alloc.AllocationMode;
                lastColor = alloc.Color;
                lastCartId = alloc.CartId;
                lastPriority = alloc.Priority;
                lastHasLinks = alloc.HasLinks;
                lastHasUnfulfilledLinks = alloc.HasUnfulfilledLinks;
                lastTeamId = item.Id();
                lastCartTitle = alloc.CartName;
                lastAllocatedOnTeamsNumber = alloc.AllocatedOnTeamsNumber;
                lastCartOrder = alloc.CartOrder;
                lastAllocationType = alloc.AllocationType;
            }

            minDate = minDate > moment(alloc.Date) ? moment(alloc.Date) : minDate;
            maxDate = maxDate < moment(alloc.Date) ? moment(alloc.Date) : maxDate;
            
            if (minDate.valueOf() == maxDate.valueOf()) {
                lastHoursAmountStartDate = alloc.HoursAmount;
            }
            lastHoursAmountEndDate = alloc.HoursAmount;

            if (!alloc.Id)
                return;

            //La prima volta salta questo ciclo perchè lastHoursType non viene settato
            if (lastHoursType != -1 && alloc.HoursType != lastHoursType) {
                const tmpMinDate = moment(maxDate);
                AllocationsGantt.createBigInterval(this.StartDate, minDate, maxDate.add('days', -1), lastCartTitle, lastColor, lastAllocationId, lastTeamId, lastCartId, lastHoursAmountStartDate, lastHoursAmountEndDate, lastAllocationType, lastHoursType, bigIntervals);

                minDate = tmpMinDate;
                maxDate = tmpMinDate;
            }

            lastHoursType = alloc.HoursType;

            const interval = new PlannerGanttItemInterval(alloc.Date, alloc.Date, alloc.HoursAmount, this.StartDate);
            interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
            interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
            interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
            if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
                interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
                interval.Style("no-estimate");
            }
            intervals.push(interval);

            //allocazione non completa
            if (alloc.IncompleteAllocation || alloc.CartIsEmpty || alloc.CartHasUnestimatedWorkOnly || alloc.CartIsNotWorkableByTheTeam) {
                const incompleteAllocationMarker = new IncompleteAllocationPlannerGanttItemMarker(alloc.Date, this.StartDate);
                incompleteAllocationMarker.Id(alloc.Id);
                const markerColor = UiUtilities.GetColorWithOpacity(lastColor, 0.5);
                
                const markerInfo: IActivitiesDatesInfoWithMarker = {
                    AllocationId: alloc.AllocationId,
                    CartId: alloc.CartId,
                    Title: alloc.CartName,
                    DueDate: alloc.Date,
                    MarkerType: "ball",
                    MarkerColor: markerColor,
                    MarkerDate: alloc.Date,
                    StartMarker: false,
                    EndMarker: false,
                    IsNotWorkedManualAllocation: false,
                    Milestone: false,
                    IsTask: false,
                    IsMilestone: false,
                    IncompleteAllocationMarker: true
                };
                incompleteAllocationMarker.Tag(markerInfo);
                
                incompleteAllocationMarker.Color(markerColor);
                incompleteAllocationMarker.Type("ball");
                
                incompleteAllocationMarker.AllocationType = lastAllocationType;
                incompleteAllocationMarker.Title(TextResources.Allocations.ClickForIncompleteAllocationInfo);

                markers.push(incompleteAllocationMarker);
                this.createOrUpdateIncompleteAllocationsGroupMarker(markersForParent, incompleteAllocationMarker);
    
                AllocationsGantt.createBigInterval(this.StartDate, minDate, maxDate, lastCartTitle, lastColor, lastAllocationId, lastTeamId, lastCartId, lastHoursAmountStartDate, lastHoursAmountEndDate, lastAllocationType, lastHoursType, bigIntervals);

                minDate = moment("2100-01-01");
                maxDate = moment("2000-01-01");
            }
        });

        if (allocations.length > 0) {
            let child;
            let childAlreadyExists = true;
            if (lastAllocationMode === AllocationMode.AutomaticAllocation) {
                child = automaticAllocationsMap[lastCartId];
                if (!child) {
                    childAlreadyExists = false;
                    child = new AllocationsPlannerGanttItem(this, lastCartId, lastCartOrder, lastAllocationMode);
                    child.Icon("fa fa-dropbox");
                    child.Metadata["AllocatedOnTeamsNumber"] = lastAllocatedOnTeamsNumber;
                    automaticAllocationsMap[lastCartId] = child;
                }
            }
            else {
                child = manualAllocationsMap[lastCartId];
                if (!child) {
                    childAlreadyExists = false;
                    child = new AllocationsPlannerGanttItem(this, lastCartId, lastCartOrder, lastAllocationMode);
                    child.Icon("fa fa-calendar");
                    child.Metadata["AllocatedOnTeamsNumber"] = lastAllocatedOnTeamsNumber;
                    manualAllocationsMap[lastCartId] = child;
                }
            }

            child.Title(lastCartTitle);
            child.Tag(this);
            child.Id(lastCartId);
            child.MultipleIntervals(true);
            child.CanHaveChildren(false);
            child.Intervals((child.Intervals() || []).concat(intervals));
            child.Metadata["TeamId"] = item.Id();
            child.Metadata["CartId"] = lastCartId;
            child.Metadata["AllocationId"] = lastAllocationId;
            child.Metadata["AllocationMode"] = lastAllocationMode;
            child.Metadata["AllocationPriority"] = lastPriority;
            child.Metadata["HasLinks"] = lastHasLinks;
            child.Metadata["HasUnfulfilledLinks"] = lastHasUnfulfilledLinks;
            
            child.loadOpeningState();
            
            child.SetEditingDialog(async () => {
                const dialog = new CartInfoDialog(this.serviceLocator, item.Id(), item.Title(), child.Id());
                await dialog.show();
                this.cartsManager.RefreshMenu();
            });

            const activitiesMarkers = this.extractMarkersFromActivitiesInfo(activitiesDates, lastAllocationId, lastColor);
            markers = markers.concat(activitiesMarkers);
            markersForParent = markersForParent.concat(activitiesMarkers);

            child.Markers(markers);
            markers = [];

            if (!childAlreadyExists)
                children.push(child);

            AllocationsGantt.createBigInterval(this.StartDate, minDate, maxDate, lastCartTitle, lastColor, lastAllocationId, lastTeamId, lastCartId, lastHoursAmountStartDate, lastHoursAmountEndDate, lastAllocationType, lastHoursType, bigIntervals);
        }

        children.sort((a, b) => {
            if (a.AllocationMode > b.AllocationMode) {
                return -1;
            } else {
                if (a.AllocationMode < b.AllocationMode) {
                    return 1;
                } else {
                    return a.CartOrder - b.CartOrder;
                }
            }
        });

        item.Intervals(bigIntervals.filter(i => i.Duration() >= 0));
        item.Children(children);
        item.Markers(markersForParent);
    }
    
    private createOrUpdateIncompleteAllocationsGroupMarker(markersForParent: PlannerGanttItemMarker[], incompleteAllocationMarker: IncompleteAllocationPlannerGanttItemMarker): void {
        const existingMarker = markersForParent.firstOrDefault(this.incompleteAllocationMarkerAlreadyExists.bind(this, incompleteAllocationMarker.Date()));
        if (!existingMarker) {
            const markerForParent = new IncompleteAllocationPlannerGanttItemMarker(incompleteAllocationMarker.Date(), this.StartDate);

            markerForParent.Id(incompleteAllocationMarker.Id());
            markerForParent.Color(incompleteAllocationMarker.Color());
            markerForParent.Type(incompleteAllocationMarker.Type());
            markerForParent.AllocationType = incompleteAllocationMarker.AllocationType;
            markerForParent.Title(incompleteAllocationMarker.Title());
            markerForParent.Tag([incompleteAllocationMarker.Tag()]);
            
            markersForParent.push(markerForParent);
            return;
        }

        (existingMarker.Tag() as IActivitiesDatesInfoWithMarker[]).push(incompleteAllocationMarker.Tag());
    }

    private incompleteAllocationMarkerAlreadyExists(date: Date, marker: PlannerGanttItemMarker): boolean {
        let markerInfo = marker.Tag();

        if (!Array.isArray(markerInfo))
            return false;

        markerInfo = markerInfo as IActivitiesDatesInfoWithMarker[];

        return !!markerInfo?.firstOrDefault(i => i.IncompleteAllocationMarker && moment(i.MarkerDate).isSame(moment(date)));
    }

    public static createBigInterval(ganttStartDate: ko.Observable<Date>, minDate: moment.Moment, maxDate: moment.Moment, lastCartTitle: string, lastColor: string, lastAllocationId: number, lastTeamId: number, lastCartId: number, lastHoursAmountStartDate: number, lastHoursAmountEndDate: number, lastAllocationType: number, lastHoursType: number, bigIntervals: PlannerGanttItemInterval[]): void {
        minDate = moment(minDate.toDate());
        maxDate = moment(maxDate.toDate());
        const intervalsToEdit = bigIntervals.filter(x => moment(x.EndDate()).startOf("day").isSame(minDate.startOf("day")));

        const hoursInDay = intervalsToEdit.sum(i => i.Tag().HoursAmountEndDate) + lastHoursAmountStartDate;

        let allocatedHoursInDay = 0; 
        for (let i = 0; i < intervalsToEdit.length; i++) {
            const interval = intervalsToEdit[i];
            const newEndDate = moment(interval.EndDate()).startOf("day");
            const numWorkedHour = allocatedHoursInDay + interval.Tag().HoursAmountEndDate;
            const workedHoursAndHoursToWorkOnDayRatio = 24 * numWorkedHour / hoursInDay;

            newEndDate.add("hours", workedHoursAndHoursToWorkOnDayRatio);

            interval.EndDate(newEndDate.toDate());

            if (moment(interval.EndDate()).startOf("day").isSame(moment(interval.StartDate()).startOf("day"))) {
                const hoursForIntervalStart = 24 * allocatedHoursInDay / hoursInDay;
                interval.StartDate(moment(interval.StartDate()).startOf("day").add("hours", hoursForIntervalStart).toDate());
            }

            allocatedHoursInDay += interval.Tag().HoursAmountEndDate
        }

        const hoursForLastIntervalStart = 24 * allocatedHoursInDay / hoursInDay;
        minDate.startOf("day").add("hours", hoursForLastIntervalStart);
        maxDate.endOf("day");
        
        const interval = new PlannerGanttItemInterval(minDate.toDate(), maxDate.toDate(), lastCartTitle, ganttStartDate);
        interval.OnlyFullDays(false);
        interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.5));
        interval.BorderColor(UiUtilities.GetColorWithOpacity(lastColor, 0.8));
        interval.FontColor(UiUtilities.GetForegroundColor(lastColor));
        interval.Id(lastAllocationId);
        interval.Tag({ TeamId: lastTeamId, CartId: lastCartId, HoursAmountStartDate: lastHoursAmountStartDate, HoursAmountEndDate: lastHoursAmountEndDate });
        if (lastAllocationType == ProlifeSdk.TheoreticalAllocation || lastHoursType == ProlifeSdk.RealWorkedHoursType) {
            interval.Color(UiUtilities.GetColorWithOpacity(lastColor, 0.2));
            interval.Style("no-estimate");
        }

        bigIntervals.push(interval);
    }

    private extractMarkersFromActivitiesInfo(activitiesDates: IActivitiesDatesInfo[], allocationId: number, cartColor: string): PlannerGanttItemMarker[] {
        const markers: PlannerGanttItemMarker[] = [];
        const allocationActivities = activitiesDates.filter((a) => a.AllocationId === allocationId);
        
        allocationActivities.forEach((a) => {
            if (a.IsDocumentMetadata) {
                const info = a as IActivitiesDatesInfoWithMarker;
                const marker = new PlannerGanttItemMarker(info.DueDate, this.StartDate);
                marker.Id(info.ElementId);
                marker.Title(info.DocumentMetadataLabel);
                marker.Tag(info);
                marker.Color(cartColor);
                marker.Type("milestone");
                info.MarkerType = marker.Type();
                info.MarkerColor = marker.Color();
                info.StartMarker = false;
                info.EndMarker = false;
                info.Milestone = false;

                markers.push(marker);

                return;
            }

            if (a.IsMilestone) {
                const info = a as IActivitiesDatesInfoWithMarker;
                const marker = new PlannerGanttItemMarker(info.DueDate, this.StartDate);
                marker.Id(info.ElementId);
                marker.Tag(info);
                marker.Color(cartColor);
                marker.Type("milestone");
                info.MarkerType = marker.Type();
                info.MarkerColor = marker.Color();
                info.StartMarker = false;
                info.EndMarker = false;
                info.Milestone = true;

                markers.push(marker);
                return;
            }

            if (!a.ElementId) {
                const info = a as IActivitiesDatesInfoWithMarker;
                const marker = new PlannerGanttItemMarker(info.DueDate, this.StartDate);
                marker.Id(info.CartId);
                marker.Tag(info);
                marker.Color("black");
                info.MarkerColor = marker.Color();

                info.StartMarker = false;
                info.EndMarker = true;
                info.Milestone = false;

                markers.push(marker);

                if (a.IsNotWorkedManualAllocation === null) {
                    marker.Type("arrowDown");
                    info.MarkerType = marker.Type();
                } else {
                    marker.Type("alertMarker");
                    info.MarkerType = marker.Type();
                }

                return;
            }

            if (a.StartDate) {
                const info = a as IActivitiesDatesInfoWithMarker;
                const marker = new PlannerGanttItemMarker(info.StartDate, this.StartDate);
                marker.Id(info.ElementId);
                marker.Tag(info);
                marker.Color("rgba(164, 171, 164, 1)");
                marker.Type("arrowRight");
                info.MarkerType = marker.Type();
                info.MarkerColor = marker.Color();
                info.StartMarker = true;
                info.EndMarker = false;
                info.Milestone = false;

                markers.push(marker);
            }

            if (a.DueDate) {
                const info = $.extend(true, {}, a) as IActivitiesDatesInfoWithMarker;
                const marker = new PlannerGanttItemMarker(info.DueDate, this.StartDate);
                marker.Id(info.ElementId);
                marker.Tag(info);
                marker.Color("rgba(164, 171, 164, 1)");
                marker.Type("arrowLeft");
                info.MarkerType = marker.Type();
                info.MarkerColor = marker.Color();
                info.StartMarker = false;
                info.EndMarker = true;
                info.Milestone = false;

                markers.push(marker);
            }
        });

        return markers;
    }

    public Select(item: AllocationsPlannerGanttItem, event: MouseEvent): void {
        if (!event.ctrlKey && !event.metaKey) {
            super.Select(item, event);
            this.doSingleSelectionActions(item, event);
            return;
        }

        this.doMultipleSelectionActions(item, event);
    }
    
    public OnAllocationDrag(allocation: IPlannerGanttInterval, dataTransfer: DataTransfer): void {
        const allocationTag = allocation.Tag();
        const draggedAllocation: IDraggedAllocation = { 
            AllocationId: allocation.Id(),
            TeamId: allocationTag ? allocationTag.TeamId : null,
            CartId: allocationTag ? allocationTag.CartId : null,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };

        dataTransfer.setData("application/prolife-team-cart-allocation", JSON.stringify(draggedAllocation));
    }

    public OnBeginDrag(cart: IPlannerGanttItem, dataTransfer: DataTransfer): void {
        const selectedCarts = this.SelectedCartsItems();
        const draggedCarts = [];

        for (const selectedCart of selectedCarts) {
            draggedCarts.push(this.createDraggedCart(selectedCart));
        }

        if (selectedCarts.indexOf(cart) < 0)
            draggedCarts.push(this.createDraggedCart(cart));

        dataTransfer.setData("text/plain", draggedCarts.join("\r\n"));
        if (draggedCarts.length === 1)
            dataTransfer.setData("application/prolife-gantt-cart", JSON.stringify(draggedCarts[0]));
        else
            dataTransfer.setData("application/prolife-gantt-carts", JSON.stringify(draggedCarts));
    }

    public async OnAllocatedCartMovedOnTeam(teamItem: PlannerGanttItem, dataTransfer: DataTransfer, event: DragEvent): Promise<void> {
        await this.OnAllocatedCartMoved(dataTransfer, teamItem, true);
    }

    public async OnAllocatedCartMoved(dataTransfer: DataTransfer, cart: IPlannerGanttItem, before: boolean): Promise<void> {
        let draggedCarts: IDraggedCart[] = [];

        if (dataTransfer.types.indexOf("application/prolife-gantt-cart") >= 0)
            draggedCarts.push(JSON.parse(dataTransfer.getData("application/prolife-gantt-cart")));
        else {
            if (dataTransfer.types.indexOf("application/prolife-gantt-carts") >= 0)
                draggedCarts = JSON.parse(dataTransfer.getData("application/prolife-gantt-carts"));
            else {
                console.error("Nessun elemento trovato per i tipi 'application/prolife-gantt-cart' e 'application/prolife-gantt-carts'");
                return;
            }
        }

        const companyGuid = this.userInfo.getCurrentCompanyGuid();

        if (draggedCarts.filter((c) => c.CompanyGuid != companyGuid).length > 0) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        const destTeamId: number = cart.Metadata["TeamId"] as number;
        const neighbourId: number = cart.Metadata["CartId"] as number;

        if (draggedCarts.length === 1) {
            const draggedCart = draggedCarts[0];

            if (neighbourId === draggedCart.CartId)
                return;

            /*if (destTeamId === draggedCart.TeamId) { // N.B. Non supporta lo spostamento di carrelli legati ad allocazioni manuali (appuntamenti)
                this.allocationsService.reorderCartsAllocation(draggedCart.TeamId, draggedCart.CartId, neighbourId, before)
                    .catch(() => {
                        this.RefreshGantt();
                    });
                return;
            }*/
        }

        const cartsToInsert = [];

        for (const cart of draggedCarts) {
            cartsToInsert.push({
                AllocationId: cart.AllocationId,
                CartId: cart.CartId,
                TeamId: cart.TeamId
            });
        }
        
        try {
            await this.allocationsService.AllocateCartsAtPositionOnTeam(destTeamId, cartsToInsert, neighbourId, before, companyGuid);
        } catch(e) {
            if (e.ExceptionType === ProlifeSdk.ServerException_AllocateCartsAtPositionOnTeamValidationException) {
                let units: string = e.MissingRolesOnTeam.map((o: IMissingRoleOnTeam) => { return String.format(TextResources.Allocations.MissedRolesOnTeamAlertRoleName, o.RoleName, o.CartTitle) }).join("<br/>");
                units = "<br/><br/>" + units + "<br/><br/>";
                const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);

                const confirm = await this.dialogsService.ConfirmAsync(message, TextResources.Allocations.MissedRolesOnTeamCancel, TextResources.Allocations.MissedRolesOnTeamConfirm);

                if (confirm) {
                    try {
                        this.allocationsService.AllocateCartsAtPositionOnTeam(destTeamId, cartsToInsert, neighbourId, before, companyGuid, true);
                    } catch(e) {
                        console.error(e);
                        this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartsOnTeamError);    
                    }
                }
            } else {
                console.error(e);
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartsOnTeamError);  
            }
        }
    }

    public async OnCartDropped(item : PlannerGanttItem, dataTransfer: DataTransfer, event: MouseEvent): Promise<void> {
        const teamId = item.Id();
        const date = this.getDateFromPoint(event.pageX, event.pageY);
        
        const isDraggingTask = dataTransfer.types.indexOf("application/prolife-task") >= 0;
        const isDraggingWorkflow = dataTransfer.types.indexOf("application/prolife-workflow") >= 0;

        if (isDraggingTask || isDraggingWorkflow) {
            const task: IDraggedTask = isDraggingTask ? JSON.parse(dataTransfer.getData("application/prolife-task")) : null;
            const workflow: IDraggedWorkflow = isDraggingWorkflow ? JSON.parse(dataTransfer.getData("application/prolife-workflow")) : null;

            if (this.userInfo.getCurrentCompanyGuid() !== (task ? task.CompanyGuid : workflow.CompanyGuid)) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
                return;
            }

            const elementId = task ? task.TaskId : workflow.WorkflowId;
            const isTask = task ? task.IsTask : workflow.IsTask;

            try {
                const result: boolean = await this.allocationsService.checkForUnestimatedTasks(elementId, isTask);
                if (result) {
                    const confirm: boolean = await this.dialogsService.ConfirmAsync(
                        (isTask ? ProlifeSdk.TextResources.Allocations.AllocateUnestimatedTasksMessage : ProlifeSdk.TextResources.Allocations.AllocateWorkflowWithUnestimatedTasksMessage),
                        ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksCancel,
                        ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksConfirm,
                    );

                    if (confirm)
                        this.doAllocationByElement(elementId, isTask, teamId, date);

                    return;
                }

                this.doAllocationByElement(elementId, isTask, teamId, date);    
            } catch(e) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksError);
            }

            return;
        }

        if (dataTransfer.types.indexOf("application/prolife-cart") >= 0) {
            const cart: IDraggedCartForList = JSON.parse(dataTransfer.getData("application/prolife-cart"));

            if (this.userInfo.getCurrentCompanyGuid() !== cart.CompanyGuid) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
                return;
            }

            try {
                const result: boolean = await this.allocationsService.checkForUnestimatedTasksForCart(cart.CartId)
                if (!result) {
                    this.checkForMissingOpUnitsByCart(teamId, cart.CartId, date);
                    return;
                }

                const confirm: boolean = await this.dialogsService.ConfirmAsync(
                    ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksMessage,
                    ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksCancel,
                    ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksConfirm
                );

                if (confirm)
                    this.checkForMissingOpUnitsByCart(teamId, cart.CartId, date);
            } catch(e) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocateCartWithUnestimatedTasksError);
            }

            return;
        }
        
        if (dataTransfer.types.indexOf("application/prolife-team-cart-allocation") >= 0) {
            const allocation: IDraggedAllocation = JSON.parse(dataTransfer.getData("application/prolife-team-cart-allocation"));

            if (this.userInfo.getCurrentCompanyGuid() !== allocation.CompanyGuid) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
                return;
            }

            try {
                const roles: IMissingRole[] = await this.allocationsService.getMissingRolesByAllocationId(allocation.AllocationId, teamId)
                if (roles && roles.length > 0) {
                    let units = roles.map(o => { return o.RoleName; }).join("<br/>");
                    units = "<br/><br/>" + units + "<br/><br/>";
                    const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
                    const confirm: boolean = await this.dialogsService.ConfirmAsync(
                        message,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamCancel,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamConfirm
                    );

                    if (confirm)
                        this.moveAllocationToTeam(allocation.AllocationId, teamId);
                    return;
                }

                this.moveAllocationToTeam(allocation.AllocationId, teamId);
            } catch(e) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
            }
        }
    }

    private createDraggedCart(cartItem: IPlannerGanttItem): IDraggedCart {
        return {
            AllocationId: cartItem.Metadata["AllocationId"] as number,
            CartId: cartItem.Metadata["CartId"] as number,
            TeamId: cartItem.Metadata["TeamId"] as number,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };
    }

    private doSingleSelectionActions(item: AllocationsPlannerGanttItem, event: MouseEvent) {
        if (event.pageX == undefined || event.pageY == undefined)
            return;
            
        const date = this.getDateFromPoint(event.pageX, event.pageY);

        if (this.AllocationMode() !== AllocationMode.ManualAllocation) {
            const markers: IPlannerGanttMarker[] = item.getMarkersAtDate(date);

            if (markers.length === 0)
                return;

            const activitiesInfo: IActivitiesDatesInfoWithMarker[] = [];
            
            markers.forEach((m) => {
                const info = !Array.isArray(m.Tag()) ? [m.Tag()] as IActivitiesDatesInfoWithMarker[] : m.Tag() as IActivitiesDatesInfoWithMarker[];
                
                for (const i of info) {
                    if (!i.AllocationId || activitiesInfo.filter((ii) => ii == i || (ii.AllocationId === i.AllocationId && ii.IncompleteAllocationMarker && ii.IncompleteAllocationMarker === i.IncompleteAllocationMarker)).length > 0)
                        continue;
                
                    activitiesInfo.push(i);
                }
            });

            this.showActivitiesInfo(activitiesInfo);

            return;
        }

        const teamId = item.Metadata["TeamId"] as number;
        if (!teamId)
            return;

        const dialog = this.allocationsService.getManualAllocationDialog(teamId, date, null);
        dialog
            .show()
            .then(() => {
                this.allocationsService.triggerNextTutorialStep("manual-allocation-editor-closed");
            });
    }

    private doMultipleSelectionActions(item: AllocationsPlannerGanttItem, event: MouseEvent) {
        this.Items().forEach((i : IPlannerGanttItem) => {
            i.Selected(false);

            i.Children().forEach((c : IPlannerGanttItem) => {
                if (item == c)
                    c.Selected(!c.Selected());
            });
        });
    }

    private doAllocationByElement(elementId: number, isTask: boolean, teamId: number, date: Date): void {
        this.allocationsService.getMissingRolesByElement(elementId, isTask, teamId)
            .then((roles: IMissingRole[]) => {
                if (roles && roles.length > 0) {
                    let units: string = roles.map(o => { return o.RoleName; }).join("<br/>");
                    units = "<br/><br/>" + units + "<br/><br/>";
                    const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
                    this.dialogsService.Confirm(
                        message,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamCancel,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamConfirm,
                        (confirm: boolean) => {
                            if (confirm) {
                                this.createCartFromDroppedElement(elementId, isTask, teamId, date);
                            }
                        }
                    );
                    return;
                }
                
                this.createCartFromDroppedElement(elementId, isTask, teamId, date);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
            });
    }

    private async createCartFromDroppedElement(elementId: number, isTask: boolean, teamId: number, date: Date): Promise<void> {
        let newCart: ICart = null;

        try {
            newCart = await this.CartsFactory.createCartForElementAllocation(elementId, isTask, this.cartsManager.ShowCartEditingFormOnActivityAllocation());
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.CartCreationError);
            return;
        }

        if (!newCart)
            return;

        await this.cartsService.manageCartWorkEndDate(newCart.Id, elementId, isTask);
        await this.allocateDroppedCart(newCart.Id, teamId, date);
    }

    private moveAllocationToTeam(allocationId: number, teamId: number): void {
        this.allocationsService.getAllocation(allocationId)
            .then((allocation: ICartAllocation) => {
                this.allocationsService.deallocateCart(allocation.TeamId, allocation.CartId)
                    .then(() => {
                        this.allocateDroppedCart(allocation.CartId, teamId, null);
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
                    });
            });
    }

    private checkForMissingOpUnitsByCart(teamId: number, cartId: number, date: Date): void {
        this.allocationsService.getMissingRolesByCartId(cartId, teamId)
            .then((roles: IMissingRole[]) => {
                if (roles && roles.length > 0) {
                    let units: string = roles.map(o => { return o.RoleName; }).join("<br/>");
                    units = "<br/><br/>" + units + "<br/><br/>";
                    const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
                    this.dialogsService.Confirm(
                        message,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamCancel,
                        ProlifeSdk.TextResources.Allocations.MissedRolesOnTeamConfirm,
                        (confirm: boolean) => {
                            if (confirm)
                                this.allocateDroppedCart(cartId, teamId, date);
                        }
                    );
                    return;
                }
                this.allocateDroppedCart(cartId, teamId, date);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
            });
    }

    private async allocateDroppedCart(cartId: number, teamId: number, date: Date): Promise<void> {
        const empty = await this.cartsService.cartIsEmpty(cartId);
        if (empty)
            return this.dialogsService.AlertAsync(ProlifeSdk.TextResources.Allocations.CanNotAllocateEmptyCarts, ProlifeSdk.TextResources.Allocations.CanNotAllocateEmptyCartsDialogLabel);
        
        const state = await this.cartsService.getCartLogicalState(cartId);    
        if (state == ProlifeSdk.CartClosedStatus)
            return this.dialogsService.AlertAsync(ProlifeSdk.TextResources.Allocations.CanNotAllocateClosedCarts, ProlifeSdk.TextResources.Allocations.CanNotAllocateClosedCartsDialogLabel);
        
        if (this.AllocationMode() === AllocationMode.ManualAllocation) {
            const dialog = this.allocationsService.getManualAllocationDialog(teamId, date, cartId);
            await dialog.show();

            this.allocationsService.triggerNextTutorialStep("manual-allocation-editor-closed");
            return;
        }
        
        this.cartsManager.AllocateCartOnTeam(cartId, teamId);
    }

    public AllocateCart(cart : CartForList, teamId : number) {
        const foundTeams : IPlannerGanttItem[] = this.Items().filter(t => t.Id() == teamId);
        if(foundTeams.length == 0)
            return;

        this.allocationsService.allocateCartOnTeam(teamId, cart.Id, this.AllocationMode())
            .then(() => {
                cart.Deallocated(false);
            });
    }

    public UpdateTeam(teamId : number) {
        const foundTeams : IPlannerGanttItem[] = this.Items().filter(t => t.Id() == teamId);
        if(foundTeams.length == 0)
            return;

        const team : IPlannerGanttItem = foundTeams[0];

        this.allocationsService.getCartAllocationForTeam(teamId)
            .then(this.renderTeamAllocations.bind(this, team));
    }

    public RefreshGantt(): void {
        this.Items([]);
        this.Load();
    }

    private showActivitiesInfo(activitiesInfo: IActivitiesDatesInfo[]) {
        const dialog = this.allocationsService.getActivitiesDatesInfoDialog(activitiesInfo);
        dialog.show();
    }
}

class LegendDialog implements IDialog {
    templateName = "allocations-gantt-legend";
    templateUrl = "allocations/templates/dialogs";
    title:string = ProlifeSdk.TextResources.JobOrder.Legend;
    modal: {
        close: (result?: any) => void;
    };

    close():void {
        this.modal.close();
    }

    action():void {
        this.modal.close();
    }
}

class AllocationsPlannerGanttItem extends PlannerGanttItem {
    constructor(private allocationsGantt: AllocationsGantt, private cartId: number, public CartOrder: number, public AllocationMode) {
        super(allocationsGantt.StartDate, allocationsGantt);
    }

    public async showTeamsPopOver(item: PlannerGanttItem, event: MouseEvent): Promise<void> {
        const allocatedOnTeamsNumber = this.Metadata["AllocatedOnTeamsNumber"] as number;
        if (!allocatedOnTeamsNumber || allocatedOnTeamsNumber < 2)
            return;
        const popover = new TeamsPopover(this.cartId);

        await this.m_dialogsService.ShowPopover(event.currentTarget as HTMLElement, popover, 'right');
    }

    public async showCartContent(): Promise<void> {
        const cartContentDialog = new CartContentDialog({ cartId: this.cartId });
        return this.m_dialogsService.ShowModal(cartContentDialog);
    }
}

class IncompleteAllocationPlannerGanttItemMarker extends PlannerGanttItemMarker {
    public AllocationType: number;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(date: Date, periodStart: ko.Observable<Date>) {
        super(date, periodStart);
        
        this.OnClickCallback = this.showMarkerInfo.bind(this);
    }

    private showMarkerInfo(): void {
        const tag = this.Tag();
        const info = Array.isArray(tag) ? tag : [tag];
        const allocationsIds = (info as IActivitiesDatesInfoWithMarker[]).map(i => i.AllocationId);

        const dialog = new IncompleteAllocationInfo({
            allocationsIds: allocationsIds,
            allocationType: this.AllocationType
        });

        this.dialogsService.ShowModal(dialog);
    }
}