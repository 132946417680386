import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { HoursApprovalStateUtilities } from "../approval/HoursApprovalStateUtilities";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type HoursApprovalRowStateProps = {
    state: HoursApprovalState;
    hideLabel?: boolean;
}

export function HoursApprovalRowState(props: HoursApprovalRowStateProps) {
    const C = require("./HoursApprovalRowState")._HoursApprovalRowState as typeof _HoursApprovalRowState;
    return <C {...props} />;
}

export class _HoursApprovalRowState {
    static defaultProps: Partial<HoursApprovalRowStateProps> = {
        hideLabel: false
    }

    private icon: string;
    private label: string;
    private tooltip: string;

    constructor(private props : HoursApprovalRowStateProps) {
        this.icon = HoursApprovalStateUtilities.getIconFor(this.props.state);
        this.label = HoursApprovalStateUtilities.getLabelFor(this.props.state);
        this.tooltip = HoursApprovalStateUtilities.getTooltipFor(this.props.state);
    }
    
    render() {
        return  <span title={this.tooltip}>
                    {this.icon && <i className={"fa " + this.icon}></i>}
                    {!this.props.hideLabel && this.label}
                </span>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(HoursApprovalRowState);
}