import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { IDataSourceModel } from "./IDataSource";
import { ILightInvoicesWithSchedules } from "../ProlifeSdk/interfaces/schedule/ILightInvoicesWithSchedules";
import { IScheduleService, IInvoiceAndSchedules } from "../ProlifeSdk/interfaces/schedule/IScheduleService";


export type ISchedulesDataSourceModel = IDataSourceModel<number, ILightInvoicesWithSchedules>

export enum SchedulesFilterMode {
    WithoutSchedules = 0,
    WithAtLeastOneExpiredSchedule = 1,
    WithSchedulesToPay = 2,
    WithOnlyClosedSchedules = 3
}

export enum SchedulesOrderBy {
    DocumentDate = 0,
    FirstOpenScheduleDate = 1,
    CustomerName = 2
}

export class SchedulesDataSource extends BaseDataSource<ISchedulesDataSourceModel> {
    @LazyImport(nameof<IScheduleService>())
    private scheduleService : IScheduleService;

    private filterMode: number = SchedulesFilterMode.WithAtLeastOneExpiredSchedule;
    private customerId: number;
    private orderBy = 0;
    private startDate: Date;
    private endDate: Date;

    public setFilterMode(filterMode : SchedulesFilterMode) {
        this.filterMode = filterMode;
        this.refresh();
    }

    public setCustomerId(customerId : number) {
        this.customerId = customerId;
        this.refresh();
    }

    public setOrderBy(orderBy : SchedulesOrderBy) {
        this.orderBy = orderBy;
        this.refresh();
    }

    public setStartDate(startDate : Date) {
        this.startDate = startDate;
        this.refresh();
    }

    public setEndDate(endDate : Date) {
        this.endDate = endDate;
        this.refresh();
    }

    getTitle(currentModel: ISchedulesDataSourceModel): string {
        return TextResources.Schedule.Schedules;
    }

    async getData(currentModel: ISchedulesDataSourceModel, textFilter: string, skip: number, count: number): Promise<ISchedulesDataSourceModel[]> {
        let schedules = await this.scheduleService.GetSchedules({
            textFilter: textFilter,
            skip: skip,
            count: count,
            filterMode: this.filterMode,
            customerId: this.customerId,
            orderBy: this.orderBy,
            startDate: this.startDate,
            endDate: this.endDate
        });

        return this.createDataModels(schedules);
    }

    private createDataModels(schedules: IInvoiceAndSchedules[]) {
        let allInvoices: ISchedulesDataSourceModel[] = [];
        let lastInvoiceId = null;
        let lastInvoice: ISchedulesDataSourceModel = null;
        for (let schedule of schedules) {
            if (lastInvoiceId != schedule.Id) {
                lastInvoiceId = schedule.Id;
                lastInvoice = this.createInvoiceFromScheduleRow(schedule);
                allInvoices.push(lastInvoice);
            }
            lastInvoice.model.Schedules.push({
                DataPagamento: schedule.DataPagamento,
                DataScadenza: schedule.DataScadenza,
                Descrizione: schedule.Descrizione,
                FKFattura: schedule.Id,
                FKTipoPagamento: schedule.FKTipoPagamento,
                PaymentBankName: schedule.PaymentBankName,
                PaymentIBAN: schedule.PaymentIBAN,
                PaymentABI: schedule.PaymentABI,
                PaymentCAB: schedule.PaymentCAB,
                Importo: schedule.Importo,
                Nota: schedule.Nota,
                PriceInDocumentCurrency: schedule.PriceInDocumentCurrency,
                Stato: schedule.Stato,
                TipoPagamento: schedule.TipoPagamento,
                IdScadenza: schedule.IdScadenza,
                CurrencyExchangeValue: schedule.CurrencyExchangeValue,
                CurrencyId: schedule.CurrencyId
            });
        }
        return allInvoices;
    }

    private createInvoiceFromScheduleRow(schedule: IInvoiceAndSchedules): ISchedulesDataSourceModel {
        return {
            id: schedule.Id,
            title: schedule.ProtocolName + " N°" + schedule.Number + " del " + moment(schedule.Date).format("L"),
            subTitle: schedule.CustomerName,
            isGroup: false,
            isLeaf: true,
            model: {
                Invoice: {
                    Id: schedule.Id,
                    CurrencyExchangeValue: schedule.CurrencyExchangeValue,
                    CurrencyId: schedule.CurrencyId,
                    CurrencySymbol: schedule.CurrencySymbol,
                    CustomerId: schedule.CustomerId,
                    DdtBillingLogicalStatus: schedule.DdtBillingLogicalStatus,
                    ExpireMode: schedule.ExpireMode,
                    ExpireModeId: schedule.ExpireModeId,
                    IsApproved: 0,
                    Locked: schedule.Locked,
                    NotaCredito: schedule.NotaCredito,
                    PaymentModeDescription: schedule.PaymentModeDescription,
                    PaymentModeId: schedule.PaymentModeId,
                    ProtocolId: schedule.ProtocolId,
                    RegisterId: schedule.ProtocolId,
                    SalInvoice: 0,
                    TipoDocumentoFiscale: 0,
                    ClosureStatus: 0,
                    CustomerName: schedule.CustomerName,
                    Date: schedule.Date,
                    EstimateStateId: schedule.EstimateStateId,
                    Number: schedule.Number,
                    Total: schedule.Total,
                    WithHoldingTax: schedule.WithHoldingTax
                },
                Schedules: []
            }
        };
    }

    async getById(currentModel: ISchedulesDataSourceModel, ids: number[]): Promise<ISchedulesDataSourceModel[]> {
        let schedules = await this.scheduleService.GetSchedulesByInvoiceIds(ids);

        return this.createDataModels(schedules);
    }

}