import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/06/13
 * Time: 15.02
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { FileRepositoryMainViewModel } from "./FileRepositoryMainViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IFileOrFolder } from "../../ProlifeSdk/interfaces/files/IFileOrFolder";

export class FileRepositoryApplication implements IApplication {
    public templateName : string = 'file-repository';
    public templateUrl : string = 'fileRepository/Templates';
    public templateData : any;
    public tileColor : string = 'tile-orange';

    private applicationsService : IApplicationsService;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "FileRepository";
    }

    getName(): string {
        return ProlifeSdk.TextResources.FileRepository.FileRepository;
    }

    getIcon(): string {
        return "icon-folder-alt";
    }

    registerRoutes() : void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get(/\#\/FileRepository\/(.*)/, (context) => {
            var fromDate = context.params['fromDate'] ? moment(context.params['fromDate']).toDate() : undefined;
            var toDate = context.params['toDate'] ? moment(context.params['toDate']).toDate() : undefined;
            var name = context.params['name'];
            var extension = context.params['extension'];
            var modifiedBy = parseInt(context.params['modifiedBy']);
            var contentType = context.params['contentType'];
            var contents = context.params['contents'];
            var tags = context.params['tags'] ? JSON.parse(context.params['tags']) : [];
            var shared = context.params['shared'] ? context.params['shared'].toLowerCase() == "true" : false;

            this.start(context.params['splat'][0], fromDate, toDate, name, extension, modifiedBy, contentType, contents, tags, shared);
        });
        opaService.Get("#/FileRepository", (context) => this.start());
    }

    getApplicationRoute() : string {
        return "#/FileRepository";
    }

    onGoingDown(): void {
        if(this.templateData) {
            this.templateData.dispose();
            this.templateData = null;
        }
    }

    private start(path? : string, fromDate? : Date, toDate? : Date, name? : string, extension? : string, modifiedBy? : number, contentType? : string, contents? : string, tags?: number[], shared? : boolean) {
        if(!this.templateData)
            this.templateData = new FileRepositoryMainViewModel(this.serviceLocator);
        this.templateData.navigateTo(path || "/", fromDate, toDate, name, extension, modifiedBy, contentType, contents, tags, shared);

        this.applicationsService.startApplication(this);
    }

    public OpenAsDialog(currentPath : string) : Promise<IFileOrFolder[]> {
        var dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        var dialog = new FileRepositoryMainViewModel(this.serviceLocator, true);
        dialog.navigateTo(currentPath);

        return dialogsService.ShowModal<IFileOrFolder[]>(dialog, "fullscreen", undefined, "fileRepository/templates", "file-repository-dialog");
    }
}