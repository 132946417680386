import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IDataSourceModel } from "../IDataSource";

export class TagsDataSourceUtils {
    public static createFreeTextModel(text: string): IDataSourceModel {
        const tag = {
            TagName : "",
            TagTypeId : ProlifeSdk.TagType_String,
            Value : text
        };

        return {
            id: JSON.stringify(tag),
            title: text,
            isGroup: false,
            isLeaf: true,
            model: text
        };
    }
}