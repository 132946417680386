import * as React from "@abstraqt-dev/jsxknockout";
import { LayoutWithHeader, LayoutHeader, LayoutContent, Layout } from "../../../../Components/Layouts";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { RouteParams } from "../../../../Components/Routing";
import { PreOrderGeneralInfo, PreOrderInfo } from "./PreOrderEditor/PreOrderGeneralInfo";
import { PreOrderRowsEditor, _PreOrderRowsEditor } from "./PreOrderEditor/PreOrderRowsEditor";
import { INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";
import ko = require("knockout");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IPreOrder, IProvisioningService, ISavePreOrderRequest } from "../../../ProvisioningService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    preordersEditor: {
        "& .preorder-data": {
            "& .preorder-actions": {
                "& > button": {
                    "&:not(:last-child)": {
                        marginRight: "5px"
                    }
                }
            },

            "& .notes-label": {
                "& *:not(:last-child)": {
                    display: "inline-block",
                    marginRight: "5px"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

export type IPreOrderEditorProps = RouteParams<{
    preOrderId: number;
    navBarActionManager: INavBarActionManager;
}>;

export function PreOrdersEditor(props: IPreOrderEditorProps) {
    const C = require("./PreOrdersEditor")._PreOrdersEditor as typeof _PreOrdersEditor;
    return <C {...props} />;
}
export class _PreOrdersEditor {
    hasChanges: ko.Observable<boolean> = ko.observable();
    margin : ko.Observable<number> = ko.observable();
    editor: _PreOrderRowsEditor;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    constructor(private props: IPreOrderEditorProps) {
    }

    private async handleSave(preOrderInfo: PreOrderInfo) {
        const rows = this.editor.getRows();

        const preOrder : ISavePreOrderRequest = {
            Notes: preOrderInfo.Notes,
            StatusId: preOrderInfo.StatusId,
            Rows: rows,
            Margin: preOrderInfo.Margin
        };

        try
        {
            await this.provisioningService.SavePreOrder(this.props.preOrderId, preOrder);
            this.hasChanges(false);

            this.infoToastService.Success(TextResources.Provisioning.PreOrderSaved);
        }
        catch
        {
            this.infoToastService.Error(TextResources.Provisioning.FailedToSavePreOrder);
        }
    }

    render() {
        return  ComponentUtils.bindTo(
            <Layout.Grid rows={["auto", "1fr"]} columns={["1fr"]}>
                <Layout.Grid.Cell row={1} column={1}>
                    <PreOrderGeneralInfo {...this.props} onSave={this.handleSave.bind(this)} hasChanges={this.hasChanges} margin={this.margin} />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <PreOrderRowsEditor {...this.props} hasChanges={this.hasChanges} margin={this.margin} forwardRef={e => this.editor = e} />
                </Layout.Grid.Cell>
            </Layout.Grid>
        , this, 'editor') as React.ReactElement;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PreOrdersEditor);
}