import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { ICustomerBank } from "../ProlifeSdk/interfaces/customer/ICustomerBank";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";

export type CustomerABIDataSourceModel = IDataSourceModel<string, ICustomerBank>

export class CustomerABIDataSource extends BaseDataSource<CustomerABIDataSourceModel> {
    private customer : ICustomer;
    private ouId: number;

    public setCustomer(customer : ICustomer, ouId?: number) {
        this.customer = customer;
        this.ouId = ouId;
    }

    getTitle(currentModel: CustomerABIDataSourceModel): string {
        return TextResources.Customers.CustomerABIs;
    }

    private getAllCustomerBanks() : ICustomerBank[] {
        const banks = [];
        for(const ou of this.customer.OrganizationalUnits) {
            if(this.ouId && ou.Id !== this.ouId)
                continue;

            for(const bank of ou.Banche) {
                banks.push(bank);
            }
        }
        return banks;
    }

    async getData(currentModel: CustomerABIDataSourceModel, textFilter: string, skip: number, count: number): Promise<CustomerABIDataSourceModel[]> {
        if (!this.customer) return [];

        if (textFilter === undefined || textFilter === null || textFilter === "")
            return this.getAllCustomerBanks().slice(skip, count).map(this.createModel, this);

        const splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        return this.getAllCustomerBanks()
            .filter(d => splitFilters.filter(w => d.ABI.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: CustomerABIDataSourceModel, ids: (string | number)[]): Promise<CustomerABIDataSourceModel[]> {
        if(!this.customer) return [];
        
        return this.getAllCustomerBanks()
            .filter(p => ids.indexOf(p.ABI) >= 0)
            .map(this.createModel, this);
    }

    private createModel(bank : ICustomerBank) : CustomerABIDataSourceModel {
        return {
            id: bank.ABI,
            title: bank.ABI,
            subTitle: bank.Name,
            isLeaf: true,
            isGroup: false,
            model: bank
        }
    }

}