import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { PlanningViewModel } from "./ui/PlanningViewModel";
import { BudgetViewerViewModel } from "./ui/BudgetViewerViewModel";
import { PerformanceViewModel } from "./ui/PerformanceViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { LazyImport } from "../../Core/DependencyInjection";

export class PlanningApplication implements IApplication
{
    public templateName : string = 'planning';
    public templateUrl : string = 'planning/templates';
    public templateData : ko.Observable<any> = ko.observable();
    public tileColor : string = 'tile-green';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);
    public navBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);
    
    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return ProlifeSdk.TextResources.Planning.PlanningAppCode;
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Planning.ApplicationName;
    }

    getIcon(): string
    {
        return "fa fa-tasks";
    }

    registerRoutes() : void
    {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/" + ProlifeSdk.TextResources.Planning.PlanningURL, (context) => this.start());
    }

    getApplicationRoute() : string
    {
        return "#/" + ProlifeSdk.TextResources.Planning.PlanningURL;
    }

    onGoingDown() : void
    {
        if (this.templateData()) {
            if (this.templateData().dispose)
                this.templateData().dispose();
        }
    }

    private start()
    {
        this.onGoingDown();

        var viewModel = null;
        if(this.authorizationService.isAuthorized("Planning_BudgetViewer"))
            viewModel = new BudgetViewerViewModel();
        else if(this.authorizationService.isAuthorized("Planning_PlannerGantt"))
            viewModel = new PlanningViewModel(this.serviceLocator);
        else if(this.authorizationService.isAuthorized("Planning_Performance"))
            viewModel = new PerformanceViewModel();
        else {
            this.infoToastService.Error(ProlifeSdk.TextResources.Planning.NoRightsToStartApplication);
            return;
        }

        this.templateData(viewModel);

        this.navBarActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Planning.ShowGlobalEconomics),
            CanRun : ko.computed(() => this.authorizationService.isAuthorized("Planning_BudgetViewer")),
            Icon: ko.observable("fa-bar-chart-o"),
            Right: true,
            Run: () => {
                this.onGoingDown();
                this.templateData(new BudgetViewerViewModel());
            }
        },{
            Name: ko.observable(ProlifeSdk.TextResources.Planning.ShowPlanning),
            CanRun : ko.computed(() => this.authorizationService.isAuthorized("Planning_PlannerGantt")),
            Icon: ko.observable("fa-tasks"),
            Right: true,
            Run: () => {
                this.onGoingDown();
                this.templateData(new PlanningViewModel(this.serviceLocator));
            }
        },{
            Name: ko.observable(ProlifeSdk.TextResources.Planning.ShowPerformance),
            CanRun : ko.computed(() => this.authorizationService.isAuthorized("Planning_Performance")),
            Icon: ko.observable("icon-speedometer"),
            Right: true,
            Run: () => {
                this.onGoingDown();
                this.templateData(new PerformanceViewModel());
            }
        }]);

        this.applicationsService.startApplication(this);
    }
}