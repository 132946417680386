import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { CustomersDataSource } from "../../../DataSources/CustomersDataSource";
import { useEffect, ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import jss from "jss";
import { Layout } from "../../../Components/Layouts";
import { Portlet } from "../../../Components/Portlet";
import { TextInput } from "../../../Components/TextInput";
import { Select2 } from "../../../Components/Select2Component";
import { RichTextInput } from "../../../Components/RichTextInput";
import { If, IfNot } from "../../../Components/IfIfNotWith";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../Components/TableComponent/SecondaryRow";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { QuotationRowEditorDialog } from "./QuotationRowEditor";
import { BackButton } from "../../../Components/BackButton";
import {
    IQuotationRow,
    IQuotationCategory,
    IQuotationService,
    IQuotationRowCategoryValue,
    IQuotationRowEntity,
} from "../../QuotationService";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { QuotationForList } from "../QuotationEditor";
import { JobOrdersDataSource } from "../../../DataSources/JobOrdersDataSource";

const { classes } = jss
    .createStyleSheet({
        quotationEditor: {
            "& .btn-back": {
                border: "2px solid rgb(102, 102, 102)",
                background: "none",
                padding: "6px 9px",

                "&:hover": {
                    borderColor: "black",
                },

                "& > i.fa": {
                    fontSize: "16px",
                },
            },

            "& .row-details": {
                "& td, & th": {
                    backgroundColor: "#f5f5f5 !important",
                },
            },
        },
    })
    .attach();

type QuotationInfoEditorProps = {
    quotation: ko.Observable<QuotationForList> | ko.Computed<QuotationForList>;
    onClose: () => void;
};

type ExpandableQuotationRow = Omit<IQuotationRow, "Categories"> & {
    expanded: ko.Observable<boolean>;
    toggleExpand: () => void;
    Categories: { [catId: number]: IQuotationRowCategoryValue };
};

export function QuotationInfoEditor(props: QuotationInfoEditorProps) {
    const C = require("./QuotationInfoEditor")._QuotationInfoEditor as typeof _QuotationInfoEditor;
    return <C {...props} />;
}

export class _QuotationInfoEditor extends React.Component<QuotationInfoEditorProps> implements IDataSourceListener {
    Name: ko.Observable<string> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    CustomerId: ko.Observable<number> = ko.observable();
    JobOrderId: ko.Observable<number> = ko.observable<number>();
    CustomerName: ko.Observable<string> = ko.observable();
    JobOrderName: ko.Observable<string> = ko.observable();
    Rows: ko.ObservableArray<ExpandableQuotationRow> = ko.observableArray();

    SubTitle: ko.Computed<string> = ko.computed(
        () => this.CustomerName() + " - " + (this.JobOrderName() || "Nessuna Commessa")
    );

    private categories: ko.ObservableArray<IQuotationCategory> = ko.observableArray();
    private loaded: ko.Observable<boolean> = ko.observable(false);

    isEditing: ko.Observable<boolean> = ko.observable(false);
    CustomersDataSource = new CustomersDataSource();
    JobOrdersDataSource = new JobOrdersDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IQuotationService>())
    private quotationService: IQuotationService;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService: IIdGeneratorService;

    constructor(props: QuotationInfoEditorProps) {
        super(props);

        this.CustomersDataSource.setReturnGroupedData(false);
        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setShowClosed(false);

        useEffect(() => {
            if (!props.quotation()) return;

            this.loaded(false);

            const quot = props.quotation();
            this.Name(quot.Name);
            this.Description(quot.Description);
            this.CustomerId(quot.FKCustomer);
            this.CustomerName(quot.CustomerName);
            this.JobOrderId = quot.FKJobOrder ?? ko.observable<number>();
            this.JobOrderName(quot.JobOrderName);

            const loadQuotation = async () => {
                this.categories(await this.quotationService.getCategories(props.quotation().Id));

                if (props.quotation().Id <= 0) this.Rows([]);
                else {
                    const quot = await this.quotationService.getQuotation(props.quotation().Id);
                    this.Rows(quot.Rows.map(this.createModel, this));
                }

                this.loaded(true);
            };

            loadQuotation();
        }, [props.quotation]);
    }

    private createModel(row: IQuotationRow): ExpandableQuotationRow {
        let expanded = ko.observable(false);
        const { Categories, ...other } = row;
        return {
            ...other,
            Categories: this.mapCategories(row),
            expanded,
            toggleExpand: () => expanded(!expanded()),
        };
    }

    onItemSelected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {
        if (sender === this.CustomersDataSource) {
            this.CustomerName(model?.title);
        } else if (sender === this.JobOrdersDataSource) {
            this.JobOrderName(model?.title);
        }
    }

    onItemDeselected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {}

    private goBack() {
        this.props.onClose();
    }

    public switchEditing() {
        this.isEditing(!this.isEditing());
    }

    public async newRow() {
        let row = await this.dialogsService.ShowModal<IQuotationRow>(
            new QuotationRowEditorDialog({
                row: {
                    Id: this.idGeneratorService.getNextId(),
                    Description: "",
                    Amount: 0,
                    UnitCost: 0,
                    TotalCost: 0,
                    Margin: 0,
                    UnitPrice: 0,
                    Discount: null,
                    NetUnitPrice: 0,
                    TotalPrice: 0,
                    FKParent: this.props.quotation().Id,
                    Entities: [],
                    Categories: [],
                },
                categories: this.categories(),
                customerId: this.CustomerId(),
                jobOrderId: this.JobOrderId,
            })
        );

        if (row) {
            this.Rows.push(this.createModel(row));
        }
    }

    public async editRow(row: IQuotationRow) {
        let newRow = await this.dialogsService.ShowModal<IQuotationRow>(
            new QuotationRowEditorDialog({
                row: row,
                customerId: this.CustomerId(),
                jobOrderId: this.JobOrderId,
                categories: this.categories(),
            })
        );

        if (newRow) {
            const existingRow = this.Rows().find((r) => r.Id === row.Id);
            this.Rows.replace(existingRow, this.createModel(newRow));
        }
    }

    private mapCategories(row: IQuotationRow) {
        let catMap: { [catId: number]: IQuotationRowCategoryValue } = {};

        for (let c of this.categories()) {
            catMap[c.Id] = row.Categories.find((rc) => rc.FKCategory === c.Id) ?? {
                FKCategory: c.Id,
                FKQuotationRow: row.Id,
                Type: 1,
                Values: [],
            };
        }

        return catMap;
    }

    private createRowModel(row: ExpandableQuotationRow) {
        return {
            id: row.Id,
            title: row.Description,
            isLeaf: true,
            isGroup: false,
            model: row,
        };
    }

    private createEntityRowModel(row: IQuotationRowEntity) {
        return {
            id: row.Id,
            title: row.Description,
            isLeaf: true,
            isGroup: false,
            model: row,
        };
    }

    render(): React.ReactElement {
        let qie = this;
        let row: IDataSourceModel<number, ExpandableQuotationRow>;
        let ref: IDataSourceModel<number, ExpandableQuotationRow>;

        const layoutClasses = ComponentUtils.classNames(classes.quotationEditor);

        const { sortString, sortNumber } = ComponentUtils.useSorter<ExpandableQuotationRow>();

        return ComponentUtils.bindTo(
            <Layout.Grid className={layoutClasses} columns={["1fr"]} rows={["auto", "10fr"]} autoSizeMode="max">
                <Layout.Grid.Cell column={1} row={1}>
                    <If condition={this.isEditing}>{() => this.renderEditingForm()}</If>
                    <IfNot condition={this.isEditing}>{() => this.renderViewForm()}</IfNot>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={2}>
                    <Layout.ScrollContainer>
                        <If condition={this.loaded}>
                            {() => (
                                <Table
                                    id={5}
                                    showColumnSelector
                                    title="Righe Preventivo"
                                    className="fixed-height"
                                    dataSource={{ array: this.Rows, factory: this.createRowModel }}
                                    scrollable
                                    compact
                                    rowAs="row">
                                    <Column id={6} style={{ width: "35px" }}>
                                        <button
                                            className="btn btn-transparent btn-xs"
                                            data-bind={{ click: row.model.toggleExpand }}>
                                            <i
                                                className="fa"
                                                data-bind={{
                                                    css: {
                                                        "fa-caret-right": !row.model.expanded(),
                                                        "fa-caret-down": row.model.expanded,
                                                    },
                                                }}></i>
                                        </button>
                                    </Column>
                                    <Column
                                        id={7}
                                        title="Descrizione"
                                        data-bind={{ text: row.model.Description }}
                                        sorter={sortString((r) => r.Description)}
                                    />
                                    {this.categories().map((c) => (
                                        <Column
                                            title={c.Name}
                                            data-bind={`text: row.model.Categories[${c.Id}].ValueString`}
                                            sorter={sortString((r) => r.Categories[c.Id].ValueString)}
                                        />
                                    ))}
                                    <Column
                                        id={8}
                                        title="Quantità"
                                        className="text-right"
                                        data-bind={{ numberText: row.model.Amount }}
                                        sorter={sortNumber((r) => r.Amount)}
                                        style={{ width: 100 }}
                                    />
                                    <Column
                                        id={9}
                                        title="Costo U."
                                        className="text-right"
                                        data-bind={{ moneyText: row.model.UnitCost }}
                                        sorter={sortNumber((r) => r.UnitCost)}
                                        style={{ width: 100 }}
                                    />
                                    <Column
                                        id={10}
                                        title="Costo"
                                        className="text-right"
                                        data-bind={{ moneyText: row.model.TotalCost }}
                                        sorter={sortNumber((r) => r.TotalCost)}
                                        style={{ width: 100 }}
                                    />
                                    <Column
                                        id={11}
                                        title="Magine"
                                        className="text-right"
                                        data-bind={{ percentageText: row.model.Margin }}
                                        sorter={sortNumber((r) => r.Margin)}
                                        style={{ width: 100 }}
                                    />
                                    <Column
                                        id={12}
                                        title="P.Unit."
                                        className="text-right"
                                        data-bind={{ moneyText: row.model.UnitPrice }}
                                        sorter={sortNumber((r) => r.UnitPrice)}
                                        style={{ width: 120 }}
                                    />
                                    <Column
                                        id={13}
                                        title="Sconto"
                                        className="text-right"
                                        data-bind={{ discountText: row.model.Discount }}
                                        sorter={sortString((r) => r.Discount)}
                                        style={{ width: 120 }}
                                    />
                                    <Column
                                        id={14}
                                        title="P.N.Unit."
                                        className="text-right"
                                        data-bind={{ moneyText: row.model.NetUnitPrice }}
                                        sorter={sortNumber((r) => r.NetUnitPrice)}
                                        style={{ width: 120 }}
                                    />
                                    <Column
                                        id={15}
                                        title="Totale"
                                        className="text-right"
                                        data-bind={{ moneyText: row.model.TotalPrice }}
                                        sorter={sortNumber((r) => r.TotalPrice)}
                                        style={{ width: 120 }}
                                    />
                                    <Column id={16} className="text-right" style={{ width: "150px" }}>
                                        <ColumnHeader style={{ verticalAlign: "middle" }}>
                                            <button
                                                className="btn btn-primary btn-circle btn-xs"
                                                data-bind={{ click: qie.newRow.bind(qie) }}>
                                                <i className="fa fa-plus" />
                                                &nbsp;Nuovo
                                            </button>
                                        </ColumnHeader>
                                        <ColumnBody>
                                            {(item) => (
                                                <>
                                                    <button
                                                        className="btn btn-primary btn-circle btn-xs"
                                                        data-bind={{ click: qie.editRow.bind(qie, row.model) }}>
                                                        <i className="fa fa-pencil" />
                                                        &nbsp;Modifica
                                                    </button>
                                                    <button className="btn btn-danger btn-circle btn-icon-only btn-xs">
                                                        <i className="fa fa-trash-o" />
                                                    </button>
                                                </>
                                            )}
                                        </ColumnBody>
                                    </Column>
                                    <SecondaryRow if={() => "row.model.expanded"} className="row-details">
                                        {(item: ITableItem<ExpandableQuotationRow>) => (
                                            <td
                                                colSpan={10 + this.categories().length}
                                                style={{
                                                    paddingLeft: 35,
                                                    paddingRight: 0,
                                                    backgroundColor: "#EEEEEE",
                                                }}>
                                                <Table
                                                    className="fixed-height"
                                                    dataSource={{
                                                        array: ko.observableArray(item.Data.model.Entities),
                                                        factory: this.createEntityRowModel,
                                                    }}
                                                    compact
                                                    rowAs="ref">
                                                    <Column title="Oggetto" sorter={sortString((r) => r.Description)}>
                                                        <Column.WithIdentifier
                                                            data-bind={{ style: { backgroundColor: "red" } }}>
                                                            <span data-bind={{ text: ref.model.Description }}></span>
                                                        </Column.WithIdentifier>
                                                    </Column>
                                                    <Column
                                                        title="Quantità"
                                                        className="text-right"
                                                        data-bind={{ numberText: ref.model.Amount }}
                                                        sorter={sortNumber((r) => r.Amount)}
                                                        style={{ width: 100 }}
                                                    />
                                                    <Column
                                                        title="Costo U."
                                                        className="text-right"
                                                        data-bind={{ moneyText: ref.model.UnitCost }}
                                                        sorter={sortNumber((r) => r.UnitCost)}
                                                        style={{ width: 100 }}
                                                    />
                                                    <Column
                                                        title="Costo"
                                                        className="text-right"
                                                        data-bind={{ moneyText: ref.model.TotalCost }}
                                                        sorter={sortNumber((r) => r.TotalCost)}
                                                        style={{ width: 100 }}
                                                    />
                                                    <Column
                                                        title="Magine"
                                                        className="text-right"
                                                        data-bind={{ percentageText: ref.model.Margin }}
                                                        sorter={sortNumber((r) => r.Margin)}
                                                        style={{ width: 100 }}
                                                    />
                                                    <Column
                                                        title="P.Unit."
                                                        className="text-right"
                                                        data-bind={{ moneyText: ref.model.UnitPrice }}
                                                        sorter={sortNumber((r) => r.UnitPrice)}
                                                        style={{ width: 120 }}
                                                    />
                                                    <Column
                                                        title="Sconto"
                                                        className="text-right"
                                                        data-bind={{ discountText: ref.model.Discount }}
                                                        sorter={sortString((r) => r.Discount)}
                                                        style={{ width: 120 }}
                                                    />
                                                    <Column
                                                        title="P.N.Unit."
                                                        className="text-right"
                                                        data-bind={{ moneyText: ref.model.NetUnitPrice }}
                                                        sorter={sortNumber((r) => r.NetUnitPrice)}
                                                        style={{ width: 120 }}
                                                    />
                                                    <Column
                                                        title="Totale"
                                                        className="text-right"
                                                        data-bind={{ moneyText: ref.model.TotalPrice }}
                                                        sorter={sortNumber((r) => r.TotalPrice)}
                                                        style={{ width: 120 }}
                                                    />
                                                </Table>
                                            </td>
                                        )}
                                    </SecondaryRow>
                                </Table>
                            )}
                        </If>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "qie"
        ) as React.ReactElement;
    }

    renderEditingForm() {
        let qie = this;

        return (
            <Portlet collapsible={false}>
                <Portlet.Header>
                    <BackButton onClick={this.goBack.bind(this)} style={{ marginRight: "20px" }} />
                    <Portlet.Header.Default
                        title="Informazioni Preventivo"
                        className="font-red-sunglo bold uppercase"
                    />
                </Portlet.Header>
                <Portlet.Actions>
                    <button
                        className="btn btn-circle btn-primary "
                        data-bind={{ click: qie.switchEditing.bind(qie), clickBubble: false }}>
                        <i class="fa fa-floppy-o"></i>&nbsp;Salva
                    </button>
                </Portlet.Actions>
                <Portlet.Body>
                    {() => (
                        <div className="form">
                            <form>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextInput label="Nome" value={this.Name} />
                                        </div>
                                        <div className="col-md-6">
                                            <Select2
                                                readonly
                                                label="Cliente"
                                                placeholder="Seleziona un cliente..."
                                                dataSource={this.CustomersDataSource}
                                                value={this.CustomerId}
                                                listener={this}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-offset-6 col-md-6">
                                            <Select2
                                                label="Commessa"
                                                placeholder="Seleziona una commessa..."
                                                dataSource={this.JobOrdersDataSource}
                                                value={this.JobOrderId}
                                                listener={this}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <RichTextInput label="Descrizione" value={this.Description} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </Portlet.Body>
            </Portlet>
        );
    }

    renderViewForm() {
        let qie = this;

        return (
            <Portlet initialCollapsed collapsible={() => !!this.Description()}>
                <Portlet.Header>
                    <button
                        className="btn btn-circle btn-icon-only btn-back"
                        data-bind={{ click: qie.goBack.bind(qie) }}
                        style={{ marginRight: "20px", display: "inline-block" }}>
                        <i class="fa fa-arrow-left"></i>
                    </button>
                    <Portlet.Header.Default
                        title={() => this.Name() ?? "Nuovo Preventivo"}
                        subTitle={this.SubTitle}
                        className="font-red-sunglo bold uppercase"
                    />
                </Portlet.Header>
                <Portlet.Actions>
                    <button
                        className="btn btn-circle btn-primary "
                        data-bind={{ click: qie.switchEditing.bind(qie), clickBubble: false }}>
                        <i class="fa fa-pencil"></i>&nbsp;Modifica
                    </button>
                </Portlet.Actions>
                <Portlet.Body>
                    {() => (
                        <iframe
                            className="form-control-static"
                            data-bind={{
                                iframeContent: qie.Description,
                                iframeContentOptions: { maxHeight: "300px", scrolling: true },
                            }}
                            style={{ border: 0, width: "100%" }}></iframe>
                    )}
                </Portlet.Body>
            </Portlet>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(QuotationInfoEditor);
}
