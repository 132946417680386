import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IProjectsService } from "../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IProject } from "../../../ProlifeSdk/interfaces/projects/IProject";

export class ProjectsControlsEntityProvider implements IControlsEntityProvider
{
    private projectsService : IProjectsService;
    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor(private serviceLocator : IServiceLocator){
        this.projectsService = <IProjectsService> serviceLocator.findService(ProlifeSdk.ProjectsServiceType);
    }

    public findEntities(query : any) {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.projectsService.hintSearch(query.term)
                .then((data) => {
                    query.callback({
                        results: data.map((project : IProject) => {
                            return {
                                id: project.Id,
                                text: project.Name
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback) {
        var id = parseInt(<string> $(element).val());
        if(!isNaN(id) && id > 0) {
            this.projectsService.get(id)
                .then((project : IProject) => callback({
                    id: project.Id,
                    text: project.Name
                }));
        }
    }
}