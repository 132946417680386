import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { BaseDocumentForWizard } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/BaseDocumentForWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ICustomerOrderRowForDocuments, ICustomerOrderForWizard, ICustomerOrderRow } from "../../../../ProlifeSdk/interfaces/warehouse/ICustomerOrder";
import { IBaseDocumentRowForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IOrdersService } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentBuilderDocumentRelatedWorkflows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DocumentDataSourceDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/data-sources/DocumentDataSourceDataWizardStep";

export class WarehouseCustomerOrderDataSource extends DocumentDataSourceDataWizardStep<ICustomerOrderRowForDocuments, CustomerOrderRow> {
    public JobOrderName : ko.Observable<string> = ko.observable();
    public CustomerName : ko.Observable<string> = ko.observable();
    public WithNoJobOrder : ko.Observable<boolean> = ko.observable(false);
    public CustomerOrders : ko.ObservableArray<CustomerOrder> = ko.observableArray();
    
    public ShowCustomerOrderReferenceRow: ko.Observable<boolean> = ko.observable(false);
    public ShowExternalReferencesRow: ko.Observable<boolean> = ko.observable(false);

    public Total : ko.Computed<number>;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;
    @LazyImport(nameof<IOrdersService>())
    private ordersService : IOrdersService;
    
    public DateFrom : ko.Observable<Date> = ko.observable();
    public DateTo : ko.Observable<Date> = ko.observable();
    public SelectAllRows : ko.Computed<boolean>;
    private DisableDocumentsReload  = false;
    private referenceDate : Date;

    public WarningMessages : ko.Observable<string> = ko.observable();
    public ExpandedColumn: ko.Observable<number> = ko.observable(1);
    previousStepRows: IDocumentDataWizardRow[];

    constructor()
    {
        super();
        this.Title(TextResources.Warehouse.CustomerOrders);
        
        this.installWatches();

        this.Total = ko.computed(() => {
            let result = 0;
            this.Rows().forEach((r : ICustomerOrderRowForDocuments) => {
                result += (r.TotaleRiga || 0);
            });
            return result;
        });

        this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.SalEntityTypeCode,
            ProlifeSdk.InvoiceEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.CreditNoteEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode  
        );

        this.SelectAllRows = ko.computed({
            read: () => {
                const documents = this.SelectedDocuments();
                let rowsCount = 0;
                let selectedRowsCount = 0;
                
                documents.forEach((e: CustomerOrder) => {
                    const allRows = e.Rows();
                    const selectedRows = allRows.filter((r : CustomerOrderRow) => { return r.Selected(); })

                    rowsCount += allRows.length;
                    selectedRowsCount += selectedRows.length;
                });

                return rowsCount === selectedRowsCount ? true : selectedRowsCount === 0 ? false : undefined;
            },
            write: (selected : boolean) => {
                this.SelectedDocuments().forEach((e : CustomerOrder) => {
                    e.Rows().forEach((r : CustomerOrderRow) => {
                        r.Selected(selected);
                    });
                });
            }
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }
    
    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Quantita,
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: r.NetUnitPriceInDocumentCurrency,
                NetUnitPrice: r.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency),
                Order: 0,
                TotalPriceInDocumentCurrency: r.TotalPriceInDocumentCurrency,
                TotalPrice: r.TotalPriceInDocumentCurrency.ToEuro(documentCurrency),
                UnitPriceInDocumentCurrency: r.PriceInDocumentCurrency,
                UnitPrice: r.PriceInDocumentCurrency.ToEuro(documentCurrency),
                Description: r.Descrizione,
                Discounts: r.Discounts,
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: this.getRelatedWorkflows(r),
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : ICustomerOrderRowForDocuments) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        for(const sourceRow of r.Rows.filter(r => r.Quantita != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType : ProlifeSdk.CustomerOrderEntityTypeCode,
                SourceEntityKeyId : sourceRow.IdRigaOrdineCliente,
                DestEntityType : this.initializationInfo.DocTypeCode,
                DestEntityKeyId : 0,
                Amount: sourceRow.Quantita,
                UnitPrice : sourceRow.Importo,
                Discounts : sourceRow.Discounts,
                NetUnitPrice: sourceRow.NetUnitPrice,
                NetPrice : sourceRow.TotaleRiga
            });
        }
        return refs;
    }

    protected getRelatedWorkflows(source : ICustomerOrderRowForDocuments) : IDocumentBuilderDocumentRelatedWorkflows[] {
        const result : IDocumentBuilderDocumentRelatedWorkflows[] = [];

        for(const row of source.Rows) {
            for(const rw of row.RelatedWorkflows || []) {

                result.push({
                    Id : null,
                    WorkflowId : rw.WorkflowId,
                    DocumentId : null,
                    DocumentType: this.initializationInfo.DocTypeCode,
                    DocumentNumber: null,
                    DocumentDate: null,
                    DocumentProtocolId: null,
                    RowId: 0,
                    RowDescription: null,
                    RowAmount: source.Quantita,
                    RowUoM: source.Udm,
                    RowPrice: source.NetUnitPrice,
                    WorkflowAmount: rw.WorkflowAmount,
                    ValidityStartDate: null,
                    WorkflowName: rw.WorkflowName
                });
            }
        }

        return result;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return this.authorizationService.isAuthorized("Warehouse_CustomerOrder") || this.authorizationService.isAuthorized("Warehouse_ViewCustomerOrder");
    }

    protected override onImportSelectedRows(someDocumentsCannotBeImported: boolean) {}

    public async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        this.DisableDocumentsReload = true;
        await super.Initialize(initializationInfo);
        this.SelectedDocuments([]);
        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf('year').toDate());
        this.DateTo(this.referenceDate);

        this.loadDefaultsFromProtocol();

        this.loadJobOrderName();
        this.loadCustomerName();
        this.DisableDocumentsReload = false;
        this.loadCustomerOrders();
    }
    
    private async loadDefaultsFromProtocol(): Promise<void> {
        const defaults = await this.documentsService.ComputeDefaultsForDocument(this.initializationInfo.DestinationDocumentProtocol.IdRegistroIVA, this.initializationInfo.CustomerId, this.initializationInfo.JobOrderId, this.initializationInfo.DocTypeCode);
        this.ShowCustomerOrderReferenceRow(defaults.ShowCustomerOrderReferenceOnFinalDocument ?? false);
        this.ShowExternalReferencesRow(defaults.ShowExternalReferenceOnFinalDocument ?? false);
    }

    private installWatches()
    {
        this.DisableDocumentsReload = true;
        this.WithNoJobOrder.subscribe(this.loadCustomerOrders.bind(this));
        this.DateFrom.subscribe(this.loadCustomerOrders.bind(this));
        this.DateTo.subscribe(() => {
            if(this.DateTo() > this.referenceDate) {
                this.WarningMessages(String.format(ProlifeSdk.TextResources.Warehouse.WarningTheSelectedDateIsBiggerThanTheDocumentOne, moment(this.referenceDate).format("L")));
            } else {
                this.WarningMessages(undefined);
            }
            this.loadCustomerOrders();
        });
        this.DisableDocumentsReload = false;
    }

    private loadJobOrderName()
    {
        this.jobOrderService.get(this.initializationInfo.JobOrderId)
            .then((jobOrder : IJobOrder) => this.JobOrderName(jobOrder?.Name) )
            .catch(() => this.JobOrderName(ProlifeSdk.TextResources.Warehouse.JobOrderNotFound));
    }

    private loadCustomerName()
    {
        this.customersService.getCustomerById(this.initializationInfo.CustomerId)
            .then((customer : ICustomer) => this.CustomerName(customer?.FormattedContactName) )
            .catch(() => this.CustomerName(ProlifeSdk.TextResources.Warehouse.CustomerNotFound));
    }

    private loadCustomerOrders()
    {
        if(this.DisableDocumentsReload)
            return;

        this.ordersService.GetCustomerOrdersForWizard("",
            this.WithNoJobOrder(), this.initializationInfo.JobOrderId, true, this.initializationInfo.CustomerId, this.DateFrom(), this.DateTo(),  0, 1000000)
            .then(this.customerOrdersLoaded.bind(this));
    }

    private customerOrdersLoaded(customerOrders : ICustomerOrderForWizard[])
    {
        this.SelectedDocuments([]);
        this.CustomerOrders(customerOrders
            .filter((c : ICustomerOrderForWizard) => { return this.initializationInfo.SourceWarehouseNotSupported || c.SourceWarehouseId == this.initializationInfo.SourceWarehouseId; })
            .map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(customerOrder : ICustomerOrderForWizard) : CustomerOrder
    {
        return new CustomerOrder(customerOrder, this, this.initializationInfo.DocumentOriginatingRows);
    }

    public removeRow(row : any)
    {
        this.Rows.remove(row);
    }

    expandColumn(colId: number): void {
        this.ExpandedColumn(colId);
    }

    public async GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        const docsToImport : CustomerOrder[] = [];
        this.Rows().forEach((r : ICustomerOrderRowForDocuments) => {
            r.Rows.forEach((dr : ICustomerOrderRow) => {
                const matches : CustomerOrder[] = this.CustomerOrders().filter((e : CustomerOrder) => { return e.customerOrder.Id == dr.FkOrdineCliente; });

                if(matches.length == 0 || docsToImport.indexOf(matches[0]) > -1)
                    return;

                docsToImport.push(matches[0]);
            });
        });


        return this.SelectedDocuments().map((d : CustomerOrder) => {
            return {
                Id : d.customerOrder.Id,
                Name : String.format(ProlifeSdk.TextResources.Warehouse.CustomerOrderNumberOf, d.Number(), moment(d.Date()).format("L")),
                EntityType : ProlifeSdk.CustomerOrderEntityTypeCode
            }
        });
    }

    render() {
        let step : WarehouseCustomerOrderDataSource;
        let cor: CustomerOrder;
        let corRow: CustomerOrderRow;
        let outRow: ICustomerOrderRowForDocuments;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 0px 15px;">
                <div class="row wizard-header">
                    <div class="col-md-12" style="height: 100%;">
                        <div class="row" style="height: 70px">
                            <div class="col-md-7 wizard-header-block">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <label class="col-md-2 control-label">{TextResources.Invoices.DocumentWizardJobOrder}</label>
                                            <div class="col-md-4 form-control-static text-ellipsis" data-bind={{text: step.JobOrderName, attr: { title: step.JobOrderName }, tooltip: { placement: 'right' }}}></div>
                                            <label class="col-md-2 control-label">{TextResources.Invoices.DocumentWizardCustomer}</label>
                                            <div class="col-md-4 form-control-static text-ellipsis" data-bind={{text: step.CustomerName, attr: { title: step.CustomerName }, tooltip: { placement: 'right' }}}></div>
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                                            <div class="col-md-4">
                                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateFrom}}>
                                                    <input class="form-control form-control-inline date-picker" type="text" />
                                                    <span class="input-group-addon">
                                                        <span class="fa fa-calendar"></span>
                                                    </span>
                                                </div>
                                            </div>
                                            <label class="col-md-2 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                                            <div class="col-md-4">
                                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateTo}}>
                                                    <input class="form-control form-control-inline date-picker" type="text" />
                                                    <span class="input-group-addon">
                                                        <span class="fa fa-calendar"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-12 form-control-static">
                                                <input type="checkbox" data-bind={{checkbox : step.WithNoJobOrder}} />
                                                {TextResources.Invoices.DocumentWizardSearchOrdersWithNoJobOrder}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 wizard-header-block" style="overflow-y: auto">
                                <div class="alert alert-danger" style="margin-bottom: 10px" data-bind={{visible: !!step.WarningMessages(), text: step.WarningMessages}}>

                                </div>
                            </div>
                        </div>
                        <div class="row" style="padding-top: 10px">
                            <div class="col-md-5">
                                <label style="font-size: 13px;">
                                    <input type="checkbox" data-bind={{checkbox: step.ShowCustomerOrderReferenceRow}}></input> {TextResources.Invoices.DocumentWizardShowReferenceToCustomerOrder}
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label style="font-size: 13px">
                                    <input type="checkbox" data-bind={{checkbox: step.ShowExternalReferencesRow}}></input> {TextResources.Invoices.DocumentWizardShowExternalReference}
                                </label>
                            </div>
                            <ko-bind data-bind={{ if: step.DocumentCurrenciesEnabled }}>
                                <div class="col-md-3" data-bind={{with: step.initializationInfo.DocumentCurrenciesInfo}}>
                                <document-currencies-popover 
                                        readonly-document-currency={() => "LockDocumentCurrencyChanges"}
                                        readonly-exchange-value={() => "LockExchangeValueChanges"}
                                        readonly-exchange-valueForVat={() => "LockExchangeValueForVatChanges"}
                                        document-currencies={() => "DocumentCurrencies"}
                                        button-css-classes='btn btn-sm blue'
                                        popover-css-classes='currencies-manager-for-modal'
                                        button-text={TextResources.Invoices.CurrenciesLabel}></document-currencies-popover>
                                </div>
                            </ko-bind>
                        </div>
                    </div>
                </div>

                    <div class="row wizard-body">
                        <div class="wizard-body-column first-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 1, 'collapsed-column': step.ExpandedColumn() != 1 }, click: step.expandColumn.bind(step, 1)}}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center closure-state-col">
                                                <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardClosureStatus}></i>
                                            </th>
                                            <th class="amount-col">{TextResources.Invoices.DocumentWizardNumber}</th>
                                            <th class="doc-date-col">{TextResources.Invoices.DocumentWizardDate}</th>
                                            <th class="text-right">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                            <th class="text-right">{TextResources.Invoices.CurrencyLabel}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if : step.CustomerOrders().length == 0 }}>
                                                <tr>
                                                    <td colSpan={4} class="text-center">{TextResources.Invoices.DocumentWizardNoOrder}</td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: { data: step.CustomerOrders, as: 'cor' }}}>
                                                <tr data-bind={{click: cor.SwitchSelection, css : { active : cor.IsSelected }}}>
                                                    <td class="text-center closure-state-col">
                                                        <div class="label label-sm" data-bind={{css : { 'label-warning' : cor.ClosureStatus() == 1, 'label-danger': cor.ClosureStatus() == 0, 'label-success': cor.ClosureStatus() == 2 }, click: cor.openDocument.bind(cor), style: { cursor: 'pointer' }}}>
                                                            <i class="fa fa-file-text" data-bind={`attr : { title :  ClosureStatus() == 1 ? '${TextResources.Invoices.DocumentWizardPartiallyClosed}' : (ClosureStatus() == 0 ? '${TextResources.Invoices.DocumentWizardClosed}' : '${TextResources.Invoices.DocumentWizardOpened}') }`}></i>
                                                        </div>
                                                    </td>
                                                    <td class="doc-number-col" data-bind={{text: cor.Number}}></td>
                                                    <td class="doc-date-col" data-bind={{dateText: cor.Date}}></td>
                                                    <td class="text-right" data-bind={{moneyText: cor.Total, currencySymbol: cor.CurrencySymbol, documentCurrency: cor.DocumentCurrency}}></td>
                                                    <td class="text-right" data-bind={{text: cor.CurrencyCode}}></td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="wizard-body-column second-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 2, 'collapsed-column': step.ExpandedColumn() != 2 }, style: { left: (step.ExpandedColumn() == 3 || step.ExpandedColumn() == 2 ? '25%' : '50%') }, click: step.expandColumn.bind(step, 2)}}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                <input type="checkbox" data-bind={{checkbox : step.SelectAllRows}} />
                                            </th>
                                            <th class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                            </th>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-center closed-amount-col">{TextResources.Invoices.DocumentWizardImportedAmount}</th>
                                            <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmountToImport}</th>
                                            <th class="unit-of-measure-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardUoM}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                            <th class="discount-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardDiscount}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                            <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if : step.SelectedDocuments().length == 0 }}>
                                            <tr>
                                                <td colSpan={10} class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                            </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach : { data: step.SelectedDocuments, as: 'cor' }}}>
                                                <ko-bind data-bind={{ foreach : { data: cor.Rows, as: 'corRow' }}}>
                                                    <tr data-bind={{css : { active : corRow.Selected }}}>
                                                        <td class="selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                            <input type="checkbox" data-bind={{checkbox : corRow.Selected}} />
                                                        </td>
                                                        <td class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                            <ko-bind data-bind={{ if : corRow.AlreadyImportedInThisDocument }}>
                                                                <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                                            </ko-bind>
                                                        </td>
                                                        <td><span data-bind={{text: corRow.Description}}></span></td>
                                                        <ko-bind data-bind={{ ifnot : corRow.ManuallyClosed }}>
                                                            <td class="text-right closed-amount-col" data-bind={{numberText : corRow.ClosedAmount}}></td>
                                                        </ko-bind>
                                                        <ko-bind data-bind={{ if : corRow.ManuallyClosed }}>
                                                            <td class="text-center closed-amount-col">
                                                                <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardManuallyClosed}></i>
                                                            </td>
                                                        </ko-bind>
                                                        <td class="text-right amount-col" data-bind={{numberText: corRow.Amount}}></td>
                                                        <td class="unit-of-measure-col" data-bind={{text: corRow.Udm, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: corRow.UnitPrice, currencySymbol: cor.CurrencySymbol, documentCurrency: cor.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="discount-col" data-bind={{discountText: corRow.Discounts, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: corRow.NetUnitPrice, currencySymbol: cor.CurrencySymbol, documentCurrency: cor.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right total-price-col" data-bind={{moneyText: corRow.Total, currencySymbol: cor.CurrencySymbol, documentCurrency: cor.DocumentCurrency}}></td>
                                                    </tr>
                                                </ko-bind>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="wizard-body-column third-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 3, 'collapsed-column': step.ExpandedColumn() != 3 }, click: step.expandColumn.bind(step, 3)}}>
                            <div class="text-center"  data-bind={{visible: step.ExpandedColumn() == 3}}>
                                <button type="button" class="btn btn-sm btn-primary" data-bind={{click: step.importSelectedRows}}>{TextResources.Invoices.DocumentWizardImportRows}</button>
                                <button type="button" class="btn btn-sm btn-danger" data-bind={{click: step.ClearImportedRows}}>{TextResources.Invoices.DocumentWizardClear}</button>
                            </div>
                            <div class="wizard-body-column-with-actions" data-bind={{style: { top: step.ExpandedColumn() == 3 ? '37px' : '0px', bottom: step.ExpandedColumn() == 3 ? '0px' : '37px' } }}>
                                <div class="wizard-data-source-table-header">
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead>
                                            <tr>
                                                <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                                <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmount}</th>
                                                <th class="unit-of-measure-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardUoM}</th>
                                                <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                                <th class="discount-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardDiscount}</th>
                                                <th class="text-price unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                                <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                                <th class="action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardRemove}</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                                <div class="wizard-data-source-table-body">
                                    <div data-bind={{slimScroll: 'auto'}}>
                                        <table class="table table-striped table-bordered table-advance table-hover">
                                            <thead></thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.Rows().length == 0 }}>
                                                    <tr>
                                                        <td colSpan={8} class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                                    </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.Rows, as: 'outRow' }}}>
                                                    <tr>
                                                        <td data-bind={{text: outRow.Descrizione}}></td>
                                                        <td class="text-right amount-col" data-bind={{numberText: outRow.Quantita}}></td>
                                                        <td class="unit-of-measure-col" data-bind={{text: outRow.Udm, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right" data-bind={{moneyText: outRow.PriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="discount-col" data-bind={{discountText: outRow.Discounts, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: outRow.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right total-price-col" data-bind={{moneyText: outRow.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency}}></td>
                                                        <td class="text-center action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>
                                                            <a href="#" class="btn btn-xs red" data-bind={{click: step.removeRow.bind(step, outRow)}}>
                                                                <i class="fa fa-times"></i>
                                                                {TextResources.Invoices.DocumentWizardRemove}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        , this, "step");
    }
}

class CustomerOrder extends BaseDocumentForWizard<CustomerOrderRow>
{
    private rowsLoaded  = false;

    @LazyImport(nameof<IOrdersService>())
    private ordersService: IOrdersService;

    constructor(public customerOrder : ICustomerOrderForWizard, private rowsStorage : WarehouseCustomerOrderDataSource, private previouslyImportedRows : IDocumentBuilderDocumentOriginatingRows[])
    {
        super(customerOrder, ProlifeSdk.CustomerOrderEntityTypeCode)
    }

    public SwitchSelection()
    {
        this.IsSelected(!this.IsSelected());
        if(this.IsSelected())
            this.loadRows().then(() => { this.rowsStorage.SelectedDocuments.push(this); });
        else
            this.rowsStorage.SelectedDocuments.remove(this);
    }

    public loadRows() : Promise<any>
    {
        if(this.rowsLoaded)
            return Promise.resolve();

        return this.ordersService.getCustomerOrderRowsById(this.customerOrder.Id)
            .then(this.onRowsLoaded.bind(this))
            .catch(() => this.rowsLoaded = false);


    }

    private onRowsLoaded(rows : ICustomerOrderRow[])
    {
        this.Rows(rows.map(this.createViewModelFor.bind(this)));
        this.rowsLoaded = true;
    }

    private createViewModelFor(row : ICustomerOrderRow) : CustomerOrderRow
    {
        return new CustomerOrderRow(row, this.rowsStorage, this.previouslyImportedRows);
    }

    public doImportSelectedRows()
    {
        const selectedRows = this.Rows().filter((r : CustomerOrderRow) => { return r.Selected(); });
        const rowsToImport = selectedRows.filter((r : CustomerOrderRow) => !r.wasPreviouslyImportedInThisDocument() && !r.wasImportedDuringThisWizard());

        if (this.rowsStorage.ShowCustomerOrderReferenceRow() && rowsToImport.length > 0) {
            const docRefRow: ICustomerOrderRowForDocuments = this.getDocumentReferenceCommentRow();
            if (this.rowsStorage.Rows().filter((r) => r.Descrizione == docRefRow.Descrizione).length == 0)
                this.rowsStorage.Rows.push(docRefRow);
        }

        if (this.rowsStorage.ShowExternalReferencesRow()) {
            const extRefRow: ICustomerOrderRowForDocuments = this.getExternalReferencesCommentRow();
            if (this.rowsStorage.Rows().filter((r) => r.Descrizione == extRefRow.Descrizione).length == 0)
                this.rowsStorage.Rows.push(extRefRow);
        }

        rowsToImport.forEach((r : CustomerOrderRow) => {
            const row = r.createImportRow();
            
            const sourceDocumentCurrency = this.DocumentCurrency();

            if (this.rowsStorage.ApplyCurrenciesExchangeValues(row, sourceDocumentCurrency))
                this.rowsStorage.Rows.push(row);
        });

        return rowsToImport.length != selectedRows.length;
    }

    private getExternalReferencesCommentRow(): ICustomerOrderRowForDocuments {
        let refs = "";

        if (this.customerOrder.ReferenceNumber)
            refs += String.format(ProlifeSdk.TextResources.Warehouse.ExternalReferencesForCommentRowRefNumber, this.customerOrder.ReferenceNumber.toString());

        if (this.customerOrder.ReferenceDate) {
            if (refs)
                refs += " - ";

            refs += String.format(ProlifeSdk.TextResources.Warehouse.ExternalReferencesForCommentRowRefDate, moment(this.customerOrder.ReferenceDate).format("L"));
        }

        if (this.customerOrder.ExternalReference) {
            if (refs)
                refs += " - ";

            refs += String.format(ProlifeSdk.TextResources.Warehouse.ExternalReferencesForCommentRowExtRef, this.customerOrder.ExternalReference);
        }

        const row = this.getDocumentReferenceCommentRow();
        row.Descrizione = ProlifeSdk.TextResources.Warehouse.ExternalReferencesForCommentRowIncipit + " " + refs;
        return row;
    }

    private getDocumentReferenceCommentRow() : ICustomerOrderRowForDocuments
    {
        return {
            Descrizione: String.format(ProlifeSdk.TextResources.Warehouse.ReferenceToDocumentForCommentRow, this.customerOrder.Number, moment(this.customerOrder.Date).format("L")),
            Quantita: 0,
            Importo: 0,
            PriceInDocumentCurrency: 0,
            TotaleRiga: 0,
            TotalPriceInDocumentCurrency: 0,
            NetUnitPrice: 0,
            NetUnitPriceInDocumentCurrency: 0,
            Udm : null,
            Discounts : null,
            Rows: []
        };
    }
}

class CustomerOrderRow implements IBaseDocumentRowForWizard
{
    Selected : ko.Observable<boolean> = ko.observable(true);
    RowNumber : ko.Observable<number> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Discounts : ko.Observable<string> = ko.observable();
    Udm : ko.Observable<string> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();

    AlreadyImportedInThisDocument : ko.Observable<boolean> = ko.observable(false);

    constructor(public row : ICustomerOrderRow, private rowsStorage : WarehouseCustomerOrderDataSource, private destinationDocumentRowsReferences : IDocumentBuilderDocumentOriginatingRows[])
    {
        const amount = Math.max(row.Quantita - row.ClosedAmount, 0);

        this.RowNumber(row.IndiceRiga);
        this.Description(row.Descrizione);
        this.Amount(amount);
        this.UnitPrice(row.PriceInDocumentCurrency); // this.UnitPrice(row.Importo);
        this.Total((amount) * (row.NetUnitPriceInDocumentCurrency || 0)); // this.Total((amount) * (row.NetUnitPrice || 0));
        this.Discounts(row.Discounts);
        this.Udm(row.Udm);
        this.NetUnitPrice(row.NetUnitPriceInDocumentCurrency || 0); // this.NetUnitPrice(row.NetUnitPrice || 0);
        this.ManuallyClosed(row.ManuallyClosed);
        this.ClosedAmount(row.ClosedAmount);

        this.AlreadyImportedInThisDocument(this.wasPreviouslyImportedInThisDocument());
    }

    public setAmountAndUpdateTotal(amount: number): void {
        this.Amount(amount);
        this.Total((amount) * (this.row.NetUnitPriceInDocumentCurrency || 0));
    }

    public isAlreadyImported() : boolean
    {
        return this.wasPreviouslyImportedInThisDocument() || this.wasImportedDuringThisWizard() || this.wasAlreadyImportedInADocument();
    }

    public wasPreviouslyImportedInThisDocument() : boolean {
        return this.destinationDocumentRowsReferences
            .filter((ref : IRefDocumentRow) => ref.SourceEntityKeyId == this.row.IdRigaOrdineCliente && ref.SourceEntityType == ProlifeSdk.CustomerOrderEntityTypeCode)
            .length > 0;
    }

    public wasAlreadyImportedInADocument() : boolean {
        const sign: number = this.row.Quantita < 0 ? -1 : 1;
        return this.row.ReferencingRows.length > 0 && ((this.row.Quantita - this.row.ClosedAmount) * sign <= 0);
    }

    public wasImportedDuringThisWizard() : boolean {
        return this.rowsStorage.Rows()
            .filter((r : ICustomerOrderRowForDocuments) => r.Rows.filter((er : ICustomerOrderRow) => er.IdRigaOrdineCliente == this.row.IdRigaOrdineCliente && er.EntityType == this.row.EntityType).length > 0)
            .length > 0
    }

    public createImportRow() : ICustomerOrderRowForDocuments
    {
        const referencedRow: ICustomerOrderRow = $.extend(true, {}, this.row);

        referencedRow.Quantita = this.Amount();
        referencedRow.TotaleRiga = this.Total(); // Viene convertito in Euro dall'ordine cliente
        referencedRow.NetUnitPrice = this.NetUnitPrice(); // Viene convertito in Euro dall'ordine cliente
        referencedRow.TotalPriceInDocumentCurrency = this.Total();
        referencedRow.NetUnitPriceInDocumentCurrency = this.NetUnitPrice();

        return {
            Descrizione: this.Description(),
            Quantita: this.Amount(),
            Importo: this.UnitPrice(), // Viene convertito in Euro dall'ordine cliente
            PriceInDocumentCurrency: this.UnitPrice(), // Viene convertito in valuta documento dall'ordine cliente
            TotaleRiga: this.Total(), // Viene convertito in Euro dall'ordine cliente
            TotalPriceInDocumentCurrency: this.Total(), // Viene convertito in valuta documento dall'ordine cliente
            NetUnitPrice: this.NetUnitPrice(), // Viene convertito in Euro dall'ordine cliente
            NetUnitPriceInDocumentCurrency: this.NetUnitPrice(), // Viene convertito in valuta documento dall'ordine cliente
            Discounts: this.Discounts(),
            Udm: this.Udm(),
            OffsetId: this.row.OffsetId,
            OffsetCode: this.row.OffsetCode,

            Rows: [referencedRow]
        };
    }
}