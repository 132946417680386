import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 16/06/2017
 * Time: 19:03
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { NavigationProvider } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { SurveyNavigationProvider } from "./providers/SurveyNavigationProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISurveyNavigationMenuObserver } from "../../../../ProlifeSdk/interfaces/survey/ISurveyNavigationMenuObserver";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationFilters } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class RootNavigationProvider extends NavigationProvider {
    constructor(serviceLocator: IServiceLocator) {
        super();
        this.Name = ProlifeSdk.TextResources.Survey.SurveyMenuTitle;
        this.AllowMultiLevelSearch = true;

        this.Items.push(new SurveyNavigationProvider(serviceLocator, ProlifeSdk.TextResources.Survey.Campaigns, "fa fa-phone"));
        this.Items.push(new SurveyNavigationProvider(serviceLocator, ProlifeSdk.TextResources.Survey.Samples, "fa fa-users"));
        this.Items.push(new SurveyNavigationProvider(serviceLocator, ProlifeSdk.TextResources.Survey.Questionnaires, "fa fa-pencil-square-o"));
    }
}

export class SurveyNavigationMenu extends NavigationMenu {
    public StopOnTypeLevel : boolean = true;
    public SelectedType : ko.Observable<number> = ko.observable(-1);

    private typeObservers : ISurveyNavigationMenuObserver[] = [];

    constructor(serviceLocator: IServiceLocator) {
        super(serviceLocator, new RootNavigationProvider(serviceLocator));
        this.templateUrl = "Survey/Templates/Navigation";
        this.templateName = "applications-navigator";
        this.setDefaultMenuLocation();
    }

    private setDefaultMenuLocation()
    {
        this.MenuProvidersStack([this.RootMenuProvider]);
    }

    navigateTo(provider : INavigationMenuProvider)
    {
        super.navigateTo(provider);
        this.NotifyChangesOnFilters();
    }

    private NotifyChangesOnFilters()
    {
        this.typeObservers.forEach((o : ISurveyNavigationMenuObserver) => {o.OnNavigationFilterIsChanged(this.GetFiltersFromActualPosition()); });
    }

    public GetFiltersFromActualPosition() : INavigationFilters
    {
        var filters : INavigationFilters = {
            LogicalState : -1,
            Type : -1,
            State : -1
        };

        this.MenuProvidersStack().forEach((p) => {
            if((<any>p).SetFilterInfoOnModel)
                (<any>p).SetFilterInfoOnModel(filters);
        } );

        this.selectedProviders.forEach((p) => {
            if((<any>p).SetFilterInfoOnModel)
                (<any>p).SetFilterInfoOnModel(filters);
        });

        return filters;
    }

    GoBack()
    {
        super.GoBack();
        this.clearSelection(false);
        this.NotifyChangesOnFilters();
    }

    selectProvider(provider : INavigationMenuProvider, notifyToObservers : boolean = true)
    {
        super.selectProvider(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    removeProviderFromSelection(provider: INavigationMenuProvider, notifyToObservers : boolean = true)
    {
        super.removeProviderFromSelection(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    RegisterSurveyApplicationsObserver(o : ISurveyNavigationMenuObserver)
    {
        this.typeObservers.push(o);
    }

    notifySelectionChange() {
        this.typeObservers.forEach((o: ISurveyNavigationMenuObserver) => {
            o.OnMenuSelectionChange();
        });
    }
}