import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IReferenceDetailsViewModelFactory, ISimpleEntitiesViewModel, IReferenceDetailsViewModel, IEntityRefInfo } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentReferencesMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IReferenceForMap } from "../../../../bindings";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";

export class CallRightRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    public UrlProvider : (docId : number, regId : number) => string;

    constructor()
    {
    }

    public CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(referenceMap : IDocumentReferencesMap) : ISimpleEntitiesViewModel
    {
        //Implementare se necessario
        return new CallRightsEntitiesViewModel(referenceMap);
    }

    public CreateViewModelForReferencedLeafs(referencedLeafsInfo : ILeafReferenceInfo[], warehouseId : number, customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r : ILeafReferenceInfo) => {
            viewModels.push(new CallRightLeafRefDetailsViewModel(r));
        });
        return viewModels;
    }

    public CreateViewModelsFor(references : IRefDocumentRow[], details : IEntityRefInfo[], customerId: number, isReferencer : boolean) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        references.forEach((r : IRefDocumentRow) => {
            var matches = details.filter((d : IEntityRefInfo) => { return d.EntityKeyId == r.SourceEntityKeyId; });
            viewModels.push(new CallRightRefDetailsViewModel(customerId, r, matches[0], isReferencer));
        });
        return viewModels;
    }

    public GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.WorkedHours.CallRights;
    }
}

export class CallRightRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    public Title : string;
    public templateName : string;
    public templateUrl : string;

    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    @LazyImport(nameof<IDocumentsService>())
    private docService : IDocumentsService;

    constructor(private entityKeyId : number, private description : string)
    {
        this.templateUrl = "workedhours/templates/documents/referencemap/referencedetails";

        var activationCode : string = this.docService.getRowReferencesGeneratorFactoryForEntity(ProlifeSdk.EstimatedCallRightTypeCode).ActivationCode;
        this.Title = activationCode + (this.sdkService.Utilities.Numbers.ZeroPad(entityKeyId.toString(), 5)) + " - " + (description || "");
    }

    public ShowDetails()
    {
        window.open(ProlifeSdk.TextResources.Settings.SettingsDefaultUrl);
    }
}

export class CallRightLeafRefDetailsViewModel extends CallRightRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(reference : ILeafReferenceInfo)
    {
        super(reference.EntityKeyId, reference.Description);
        this.templateName = "call-right-leaf";
    }
}

export class CallRightRefDetailsViewModel extends CallRightRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(customerId: number, reference : IRefDocumentRow, details : IEntityRefInfo, isReferencer : boolean)
    {
        super(reference.SourceEntityKeyId, details.Description);
        this.templateName = "call-right";
    }
}

export class CallRightsEntitiesViewModel implements ISimpleEntitiesViewModel
{
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsManager : ICallRightTypesSettingsManager;

    public CallRights : ko.ObservableArray<CallRightForReferenceMap> = ko.observableArray([]);
    EntityType : string = ProlifeSdk.EstimatedCallRightTypeCode;
    templateName : string = "documents-map-viewer-call-rights";
    templateUrl : string = "workedhours/templates/documents/referencemap";

    constructor(private referenceMap : IDocumentReferencesMap)
    {
    }

    public LoadEntities(references : IReferenceForMap[]) : Promise<void>
    {
        var entitiesIds : number[] = references
            .filter((r : IReferenceForMap) => { return r.SourceEntityType == this.EntityType; })
            .map((r : IReferenceForMap) => { return r.SourceId; });

        var callRights : CallRightForReferenceMap[] = this.callRightsManager.getAll(true)
            .filter((r : ICallRightType) => { return entitiesIds.indexOf(r.Id) > -1; })
            .map((r : ICallRightType) => {
                return new CallRightForReferenceMap(r, this);
            });

        this.CallRights(callRights);
        return Promise.resolve<void>(undefined);
    }

    public OnSelectionChanged(selected : CallRightForReferenceMap)
    {
        this.CallRights().forEach((a : CallRightForReferenceMap) => {
            a.IsSelected(a == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.callRight.Id, this.EntityType);
    }

    public SelectRelatedRows(entityId : number, entityType : string)
    {
        this.CallRights().forEach((r: CallRightForReferenceMap) => {
            r.IsSelected(r.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }
}

export class CallRightForReferenceMap extends NodeSubElement
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public callRight : ICallRightType, private container : CallRightsEntitiesViewModel)
    {
        super(container, callRight.Id, container.EntityType);
    }
}