import { IDataSourceModel } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { ITicketCategory, ITicketsService } from "../Tickets/TicketsService";
import { ITicketCategoriesSettingsManager } from "../Tickets/settings/TicketCategoriesSettingsManager";

export interface ITicketCategoriesDataSourceModel extends IDataSourceModel<number, ITicketCategory> {

}

export class TicketCategoriesDataSource extends BaseDataSource<ITicketCategoriesDataSourceModel> {
    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    @LazyImportSettingManager(nameof<ITicketCategoriesSettingsManager>())
    private TicketCategoriesSettingsManager: ITicketCategoriesSettingsManager;

    private showDeleted: boolean;

    setShowDeleted(showDeleted: boolean){
        this.showDeleted = showDeleted;
    }

    getTitle(currentModel: ITicketCategoriesDataSourceModel): string {
        return "Ticket Categories";
    }

    async getData(currentModel: ITicketCategoriesDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITicketCategoriesDataSourceModel[]> {
        let categories = await this.TicketCategoriesSettingsManager.getTicketCategories(textFilter, this.showDeleted, skip, count);
        return categories.map(this.createModelFor, this);
    }

    async getById(currentModel: ITicketCategoriesDataSourceModel, ids: number[]): Promise<ITicketCategoriesDataSourceModel[]> {
        let categories = await this.TicketCategoriesSettingsManager.getTicketCategoriesByIds(ids);
        return categories.map(this.createModelFor, this);
    }

    private createModelFor(category: ITicketCategory): ITicketCategoriesDataSourceModel {
        return {
            id: category.Id,
            title: category.Title,
            isLeaf: true,
            isGroup: false,
            model: category
        };
    }
}