import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 15:10
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { QueueWithManualContactSelection } from "../queue/QueueWithManualContactSelection";
import { QueueWithAutomaticContactSelection } from "../queue/QueueWithAutomaticContactSelection";
import { QuestionnaireIdentifierViewModel } from "../questionnaires/QuestionnaireIdentifierViewModel";
import { SampleIdentifierViewModel } from "../samples/SampleIdentifierViewModel";
import { WizardTestDialog } from "../queue/dialogs/WizardTestDialog";
import { CloseInterruptedInterviewDialog } from "../queue/dialogs/CloseInterruptedInterviewDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ICampaignIdentifier } from "../../../../ProlifeSdk/interfaces/survey-wizard/ICampaignIdentifier";
import { IInterruptedInterviewInfo } from "../../../../ProlifeSdk/interfaces/survey-wizard/IInterruptedInterviewInfo";

export class CampaignViewModel {
    public templateName: string = "next-contact-selection";
    public templateUrl: string = "surveywizard/templates/";

    public Title: ko.Observable<string> = ko.observable();
    public Type: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();

    public Questionnaire: ko.Observable<any> = ko.observable();
    public Sample: ko.Observable<any> = ko.observable();

    public CallsQueueViewModel: ko.Observable<any> = ko.observable();

    private surveyWizardService: ISurveyWizardService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private campaignId: number) {
        this.surveyWizardService = <ISurveyWizardService> this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.surveyWizardService.getCampaignData(campaignId)
            .then((campaign: ICampaignIdentifier) => {
                this.Title(campaign.Title);
                this.Type(campaign.Type);
                this.Description(campaign.Description);
                this.Questionnaire(new QuestionnaireIdentifierViewModel(campaign.Questionnaire));
                this.Sample(new SampleIdentifierViewModel(campaign.Sample));

                this.getConfiguration();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetCampaignDataError);
            });

        this.surveyWizardService.getInterruptedInterviewForUser(this.campaignId)
            .then((interview: IInterruptedInterviewInfo) => {
                if (!interview)
                    return;
                var closeInterruptedInterview = new CloseInterruptedInterviewDialog(interview, this.campaignId);
                this.dialogService.ShowModal<void>(closeInterruptedInterview, null, null, "surveywizard/templates/wizard/dialogs", "close-interrupted-interview-dialog");
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetInterruptedInterviewError);
            });
    }

    private getConfiguration() {
        this.surveyWizardService.getContactSelectionConfiguration()
            .then((mode: number) => {
                this.CallsQueueViewModel(this.CreateViewModelFor(mode));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetContactSelectionConfigurationError);
            });
    }

    private CreateViewModelFor(mode: number): any {
        if (mode == ProlifeSdk.ManualContactSelectionForWizard)
            return new QueueWithManualContactSelection(this.serviceLocator, this.campaignId);
        if (mode == ProlifeSdk.AutomaticContactSelectionForWizard)
            return new QueueWithAutomaticContactSelection(this.campaignId);
    }

    public BackToSplash(): void {
        location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl;
    }

    public LaunchWizardTest(): void {
        if (!this.CallsQueueViewModel() || !this.CallsQueueViewModel().SelectedContact()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.ContactSelectionRequered);
            return;
        }
        var wizardTestDialog = new WizardTestDialog(this.serviceLocator, this.CallsQueueViewModel().SelectedContact().QueueId);
        this.dialogService.ShowModal<void>(wizardTestDialog, "fullscreen", null, "surveywizard/templates/wizard/dialogs/", "wizard-test-dialog");
    }

    public dispose(): void {
        this.Questionnaire(null);
        this.Sample(null);
        this.CallsQueueViewModel().dispose();
    }
}