import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/02/2018
 * Time: 14:32
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { FolderViewModel } from "../FolderViewModel";
import * as moment from "moment";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IFolderService, IFolder } from "../../../../ProlifeSdk/interfaces/agenda/IFolderService";
import { IFolderViewModel } from "../../../interfaces/IFolder";
import { Deferred } from "../../../../Core/Deferred";

export class FolderEditingDialog implements IDialog {
    public templateName: string = "folder-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = "";

    public FolderForEditing: ko.Observable<any> = ko.observable();

    public ShowSaveButton: ko.Computed<boolean>;
    public ShowDeleteButton: ko.Computed<boolean>;
    public ShowRecoveryButton: ko.Computed<boolean>;

    modal: { close: (result?: any) => void; };

    private folderService: IFolderService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private folderId: number) {
        this.folderService = <IFolderService> this.serviceLocator.findService(ProlifeSdk.FolderServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        if (!folderId) {
            this.FolderForEditing(this.CreateEmptyFolderViewModel());
            this.title = ProlifeSdk.TextResources.Agenda.FolderEditingDialogTitleForInsert;
        } else {
            this.title = ProlifeSdk.TextResources.Agenda.FolderEditingDialogTitleForEdit;
            this.folderService.GetFolder(this.folderId)
                .then((folder: IFolder) => {
                    if (!folder)
                        return;
                    this.FolderForEditing(this.CreateFolderViewModel(folder));
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFolderError);
                });
        }

        this.ShowSaveButton = ko.computed(() => {
            if (!this.FolderForEditing())
                return false;

            return this.FolderForEditing().Deleted() == false;
        });

        this.ShowRecoveryButton = ko.computed(() => {
            if (!this.FolderForEditing())
                return false;

            return this.FolderForEditing().Deleted() == true;
        });

        this.ShowDeleteButton = ko.computed(() => {
            if (!this.FolderForEditing())
                return false;
            return !this.ShowRecoveryButton() && !this.FolderForEditing().IsNew();
        });
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        this.Save()
            .then((folder: IFolder) => {
                if (this.FolderForEditing().Id <= 0)
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.InsertFolderSuccess);
                else
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.ModifyFolderSuccess);
                this.FolderForEditing().FolderId(folder.Id);
                this.modal.close(this.FolderForEditing().GetData());
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.InsertFailed);
            });
    }

    deleteFolder(): void {
        this.FolderForEditing().Delete()
            .then((deleted: boolean) => {
                if (deleted) {
                    this.FolderForEditing().Deleted(true);
                    this.modal.close(this.FolderForEditing().GetData());
                }
            });
    }

    recovery(): void {
        this.FolderForEditing().Deleted(false);

        this.Save()
            .then((folder: IFolder) => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.RecoverySuccess);
                this.modal.close(folder);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RecoveryError);
            });

    }

    private Save(): Promise<IFolder> {
        var def = new Deferred<IFolder>();

        if (!this.FolderForEditing().Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.FolderTitleRequired);
            return def.reject().promise();
        }

        var folderData = this.FolderForEditing().GetData();
        this.folderService.CreateOrUpdate(folderData)
            .then((folder: IFolder) => {
                def.resolve(folder);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private CreateFolderViewModel(folder: IFolder): IFolderViewModel {
        return new FolderViewModel(this.serviceLocator, folder);
    }

    private CreateEmptyFolderViewModel(): IFolderViewModel {
        var today = moment().toDate();
        var folder: IFolder = <IFolder> {
            Id: -1,
            Name: null,
            Icon: '',
            Background: '#FFFFFF',
            Foreground: '#000000',
            CreatedBy: -1,
            CreationDate: today,
            ModifiedBy: -1,
            ModifyDate: today,
            Deleted: false,
            Expanded: false,
            Order: -1
        };

        return new FolderViewModel(this.serviceLocator, folder);
    }
}