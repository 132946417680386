import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { CustomersMetadataTypes } from "./customers/CustomersMetadataTypes";
import { CustomersApplication } from "./customers/CustomersApplication";
import { CustomerEntityProvider } from "./customers/EntityProviders/CustomerEntityProvider";
import { CustomersUI } from "./customers/CustomersUI";
import { ProtocolDefaultValuesProviderByCustomer } from "./customers/documents/providers/ProtocolDefaultValuesProviderByCustomer";
import { TrustUpdateDelaysOnPaymentsSettingsManager } from "./customers/settings/TrustUpdateDelaysOnPaymentsSettingsManager";
import { TrustsSettingsManager } from "./customers/settings/TrustsSettingsManager";
import { ResponsibleReference } from "./customers/ui/ResponsibleReference";
import "./customers/ui/CustomerAliasesEditor";
import "./customers/ui/CustomerPaymentExtensionsEditor";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IService } from "../Core/interfaces/IService";
import {
    ICustomersService,
    ICustomerForList,
    IGetCustomersForListByGroupRequest,
    ICustomerForListWithGroup,
    ISupplierForList,
    ILetterOfAttemptInvoice,
    ILetterOfAttemptForEditor,
    ILetterOfAttempt,
    IGetLettersOfAttemptsRequest,
    IGetCustomersForDocumentsRequest,
    ICustomerForDocuments,
    ICustomerFiscalInfo,
    ICustomerPaymentExtension,
    IGetAllCustomersPaymentExtensionsRequest,
    MetadataLogicalType,
    MetadataLogicalTypeSource,
} from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IAuthorizationServiceObserver } from "../Core/interfaces/IAuthorizationServiceObserver";
import { ICustomersUI } from "../ProlifeSdk/interfaces/customer/ICustomersUI";
import { ICustomersServiceObserver } from "../ProlifeSdk/interfaces/customer/ICustomersServiceObserver";
import { ISelect2ResponsibleProvider } from "../ProlifeSdk/interfaces/customer/providers/ISelect2ResponsibleProvider";
import {
    ICustomer,
    ICustomerHint,
    IContactForDiscountList,
    IContactUoForList,
    IFullCustomer,
    ICustomerTrust,
} from "../ProlifeSdk/interfaces/customer/ICustomer";
import { IRecipientCode } from "../ProlifeSdk/interfaces/customer/IRecipientCode";
import { INewCustomer } from "../ProlifeSdk/interfaces/customer/INewCustomer";
import { ResponseBase, ResponseData, ResponseError } from "../Core/response/ResponseBase";
import { LazyImport } from "../Core/DependencyInjection";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { CustomerMapper } from "./CustomerMapper";

class CustomersService implements ICustomersService, ISettingsServiceObserver, IAuthorizationServiceObserver {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public ui: ICustomersUI;
    private application: CustomersApplication;
    private observers: ICustomersServiceObserver[] = [];

    private nextFakeId = 0;
    private customerMapper: CustomerMapper;
    private errorHandler: { [code: string]: (...args: unknown[]) => void };

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ICustomersService>(), this);

        this.customerMapper = new CustomerMapper();
    }

    InitializeService() {
        new CustomerEntityProvider(this.serviceLocator);
        new CustomersMetadataTypes(this.serviceLocator);

        this.settingsService.addObserver(this);
        this.authorizationsService.addObserver(this);

        this.setupErrorHandler();

        this.ui = new CustomersUI();
        this.application = new CustomersApplication(this.serviceLocator);
    }

    private setupErrorHandler() {
        this.errorHandler = {
            InvalidCustomerId: () => this.infoToastService.Error(TextResources.Customers.InvalidCustomerId),
            CustomerNotFound: () => this.infoToastService.Error(TextResources.Customers.CustomerNotFound),
            MissingMailRecipientEmail: () =>
                this.infoToastService.Error(TextResources.Customers.MissingMailRecipientEmail),
            MailRecipientEmailInvalid: (mail: string) =>
                this.infoToastService.Error(String.format(TextResources.Customers.MailRecipientEmailInvalid, mail)),
            OrganizationalUnitsRequired: () =>
                this.infoToastService.Error(TextResources.Customers.OrganizationalUnitsRequired),
            TrustsDatesOverlap: () => this.infoToastService.Error(TextResources.Customers.TrustsDatesOverlap),
            TrustsBalanceError: () => this.infoToastService.Error(TextResources.Customers.TrustsBalanceError),
            TrustValueError: (trustId: number) =>
                this.infoToastService.Error(String.format(TextResources.Customers.TrustInvalidValueError, trustId)),
            TrustEndDateError: () => this.infoToastService.Error(TextResources.Customers.TrustEndDateError),
            InvalidTaxCode: () => this.infoToastService.Error(TextResources.Customers.InvalidTaxCode),
            InvalidFiscalCode: () => this.infoToastService.Error(TextResources.Customers.InvalidFiscalCode),
            InvalidNonPAElectronicInvoicingRecipientCode: () =>
                this.infoToastService.Error(TextResources.Customers.InvalidNonPAElectronicInvoicingRecipientCode),
            InvalidPAElectronicInvoicingRecipientCode: () =>
                this.infoToastService.Error(TextResources.Customers.InvalidPAElectronicInvoicingRecipientCode),
            InvalidMetadataId: () => this.infoToastService.Error(TextResources.Customers.InvalidMetadataId),
            ContactNotAccessible: () => this.infoToastService.Error(TextResources.Customers.ContactNotAccessible),
            DeleteUsedLettersOfAttempts: (lettersOfAttempts: string[]) =>
                this.infoToastService.Error(
                    String.format(TextResources.Customers.DeleteUsedLettersOfAttempts, lettersOfAttempts.join(", "))
                ),
            InvalidLettersOfAttemptsRange: (lettersOfAttempts: string[]) =>
                this.infoToastService.Error(
                    String.format(TextResources.Customers.InvalidLettersOfAttemptsRange, lettersOfAttempts.join(", "))
                ),
            InvalidLettersOfAttemptsAmount: (lettersOfAttempts: string[]) =>
                this.infoToastService.Error(
                    String.format(TextResources.Customers.InvalidLettersOfAttemptsAmount, lettersOfAttempts.join(", "))
                ),
            MissingResourceAssociation: () =>
                this.infoToastService.Error(TextResources.Customers.MissingResourceAssociation),
            GenericError: () => this.infoToastService.Error(TextResources.ProlifeSdk.GenericError),
        };
    }

    async searchMetadataLogicalTypes(
        category: number,
        textFilter: string,
        dataSources: MetadataLogicalTypeSource[],
        skip: number,
        count: number
    ): Promise<MetadataLogicalType[]> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseData<MetadataLogicalType[]>>(
                "c/metadataLogicalType",
                category + "/search",
                {
                    methodData: {
                        textFilter: textFilter,
                        dataSources: dataSources,
                        skip: skip,
                        count: count,
                    },
                }
            );

            if (!response.succeeded) {
                this.handleResponseErrors(response.errors);
            }

            return response.data;
        } catch (error) {
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
            console.error(error);
            return [];
        }
    }

    async getMetadataLogicalTypes(addressTypes: number[]): Promise<MetadataLogicalType[]> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseData<MetadataLogicalType[]>>(
                "c/metadataLogicalType",
                "getByIds",
                {
                    methodData: {
                        ids: addressTypes,
                    },
                }
            );

            if (!response.succeeded) {
                this.handleResponseErrors(response.errors);
            }

            return response.data;
        } catch (error) {
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
            console.error(error);
            return [];
        }
    }

    GetCustomerUrl(customerId: number) {
        if (!customerId) return null;
        return "#/" + String.format(ProlifeSdk.TextResources.Customers.CustomerURL, customerId);
    }

    onSettingsLoaded() {
        this.documentsService.getRegisteredDocumentProviders().forEach((p: IDocumentsProvider) => {
            p.RegisterDefaultValuesProvider(new ProtocolDefaultValuesProviderByCustomer());
        });
    }

    authorizationLoaded(rights: any): void {
        if (
            this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled") &&
            !this.settingsService.findSettingsManager(ProlifeSdk.TrustsSettingsManager)
        ) {
            new TrustsSettingsManager(this.serviceLocator).load();
            new TrustUpdateDelaysOnPaymentsSettingsManager(this.serviceLocator).load();
        }
    }

    onSettingsUpdated(updateType: string) {}

    getServiceType(): string {
        return ProlifeSdk.CustomersServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public GetSelect2ResponsibleProvider(): ISelect2ResponsibleProvider {
        return new ResponsibleReference();
    }

    public GetContactName(customer: ICustomer): string {
        let peopleName = "";
        peopleName +=
            !customer.Qualification || customer.Qualification.trim().length == 0 ? "" : customer.Qualification + " ";
        peopleName += !customer.Nome || customer.Nome.trim().length == 0 ? "" : customer.Nome + " ";
        peopleName += !customer.Cognome || customer.Cognome.trim().length == 0 ? "" : customer.Cognome + " ";
        let name = customer.IsCompany ? customer.RagioneSociale : peopleName;
        return name.trim();
    }

    getAllCustomers(filter?: string, customers?: boolean, suppliers?: boolean): Promise<ICustomer[]> {
        return this.ajaxService.Post("Customers-api/Customers", "DetailedSearch", {
            methodData: {
                IsCustomer: customers,
                IsSupplier: suppliers,
                HintSearch: filter,
            },
            background: true,
        });
    }

    getAllCustomersLimited(
        filter?: string,
        customers?: boolean,
        suppliers?: boolean,
        showAllCustomers?: boolean
    ): Promise<ICustomer[]> {
        return this.ajaxService.Post("Customers-api/Customers", "LimitedDetailedSearch", {
            methodData: {
                IsCustomer: customers,
                IsSupplier: suppliers,
                ShowAllCustomers: showAllCustomers,
                HintSearch: filter,
            },
            background: true,
        });
    }

    getAllCustomersEnvelope(filter?: string, customers?: boolean, suppliers?: boolean): Promise<ICustomer[]> {
        return this.ajaxService.Post("Customers-api/Customers", "EnvelopeDetailedSearch", {
            methodData: {
                IsCustomer: customers,
                IsSupplier: suppliers,
                HintSearch: filter,
            },
            background: true,
        });
    }

    SetCustomerBlockStatus(
        customerId: number,
        locked: boolean,
        lockJobOrders: boolean,
        lockMotivation?: number
    ): Promise<ICustomer> {
        return this.ajaxService
            .Post<ICustomer>("Customers-api/Customers", "SetCustomerBlockStatus", {
                methodData: {
                    CustomerId: customerId,
                    Locked: locked,
                    BlockMotivationId: lockMotivation,
                    BlockAllJobOrders: lockJobOrders,
                },
                background: true,
            })
            .then(this.notifyCustomerChange.bind(this));
    }

    hintSearch(query: string): Promise<ICustomerHint[]> {
        return this.ajaxService.Get("Customers-api/Customers", "HintSearch?query=" + encodeURIComponent(query), {
            background: true,
        });
    }

    SearchTags(textFilter: string, count: number): Promise<string[]> {
        return this.ajaxService.Post<string[]>("Customers-api/ContactTags", "SearchTags", {
            methodData: {
                TextFilter: textFilter,
                Count: count,
            },
        });
    }

    GetContactsForWarehouseDiscountsList(
        textFilter: string,
        skip: number,
        count: number,
        excludeWithouthDiscounts: boolean,
        isCustomer: boolean,
        isSupplier: boolean
    ): Promise<IContactForDiscountList[]> {
        return this.ajaxService.Post<IContactForDiscountList[]>(
            "Customers-api/Customers",
            "GetContactsForWarehouseDiscountsList",
            {
                methodData: {
                    IsCustomer: isCustomer,
                    IsSupplier: isSupplier,
                    HintSearch: textFilter,
                    Skip: skip,
                    Count: count,
                    ExcludeWithouthDiscounts: excludeWithouthDiscounts,
                },
            }
        );
    }

    GetContactsCountForDiscountsList(
        textFilter: string,
        excludeWithouthDiscounts: boolean,
        isCustomer: boolean,
        isSupplier: boolean
    ): Promise<number> {
        return this.ajaxService.Post<number>("Customers-api/Customers", "GetContactsCountForDiscountsList", {
            methodData: {
                IsCustomer: isCustomer,
                IsSupplier: isSupplier,
                HintSearch: textFilter,
                ExcludeWithouthDiscounts: excludeWithouthDiscounts,
            },
        });
    }

    GetCustomersForList(
        textFilter: string,
        skip: number,
        count: number,
        getCustomers = true,
        getSuppliers = true,
        getItalianCustomers = true,
        getForeignCustomers = true
    ): Promise<ICustomerForList[]> {
        return this.ajaxService.Post("Customers-api/Customers", "GetCustomersForList", {
            methodData: {
                IsCustomer: getCustomers,
                IsSupplier: getSuppliers,
                GetItalianCustomers: getItalianCustomers,
                GetForeignCustomers: getForeignCustomers,
                HintSearch: textFilter,
                Skip: skip,
                Count: count,
            },
            background: true,
        });
    }

    GetContactsUoForList(textFilter: string, onlyCompanies: boolean): Promise<IContactUoForList[]> {
        return this.ajaxService.Post("Customers-api/Customers", "GeContactsUoForList", {
            methodData: {
                IsCompany: onlyCompanies,
                IsCustomer: false,
                IsSupplier: false,
                HintSearch: textFilter,
                Skip: null,
                Count: -1,
            },
        });
    }

    GetContactUoForList(id: number): Promise<IContactUoForList> {
        return this.ajaxService.Post("Customers-api/Customers", "GetContactUoForList", {
            methodData: {
                Id: id,
            },
        });
    }

    GetCustomerForList(customerId: number): Promise<ICustomerForList> {
        return this.ajaxService.Post("Customers-api/Customers", "GetCustomerForList", {
            methodData: {
                Id: customerId,
            },
            background: true,
        });
    }

    GetCustomersCount(textFilter: string): Promise<number> {
        return this.ajaxService.Post("Customers-api/Customers", "GetCustomersCount", {
            methodData: {
                IsCustomer: false,
                IsSupplier: false,
                HintSearch: textFilter,
                Skip: 0,
                Count: 0,
            },
            background: true,
        });
    }

    getCustomerById(customerId: number): Promise<ICustomer> {
        if (!customerId) {
            return Promise.resolve(null);
        }

        return this.ajaxService.Get("Customers-api", "Customers/" + customerId, {});
    }

    async getCustomer(customerId: number): Promise<ICustomer> {
        try {
            const getResponse = await this.ajaxServiceNew.Get<ResponseData<INewCustomer>>(
                "c/customers",
                customerId ? customerId.toString() : "-1",
                {}
            );

            if (!getResponse.succeeded) {
                this.handleResponseErrors(getResponse.errors);
                return null;
            }

            const customer = this.customerMapper.toICustomer(getResponse.data);
            return customer;
        } catch (error) {
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
            console.error(error);
            return null;
        }
    }

    private handleResponseErrors(errors: ResponseError[]) {
        for (const error of errors) {
            this.errorHandler[error.code]?.(error.metadata);
        }
    }

    GetCustomerByVatNumber(vatNumber: string): Promise<ICustomer> {
        if (!vatNumber) return Promise.resolve<ICustomer>(undefined);

        return this.ajaxService.Get("Customers-api", "Customers/ByVatNumber?vatNumber=" + vatNumber, {});
    }

    GetCustomersByPhoneNumber(phoneNumber: string): Promise<ICustomer[]> {
        if (!phoneNumber) return Promise.resolve<ICustomer[]>([]);

        return this.ajaxService.Post("Customers-api", "Customers/ByPhoneNumber", {
            methodData: {
                PhoneNumber: phoneNumber,
            },
        });
    }

    async saveCustomer(customer: IFullCustomer): Promise<ICustomer> {
        const customerToSave = this.customerMapper.toINewCustomer(customer);
        const isNew = !customerToSave.id || customerToSave.id < 0;

        try {
            const postResponse = !isNew
                ? await this.ajaxServiceNew.Put<ResponseData<INewCustomer>>("c/customers", "", {
                      methodData: {
                          customer: customerToSave,
                          customerGroup: customer.CustomerGroup,
                      },
                  })
                : await this.ajaxServiceNew.Post<ResponseData<INewCustomer>>("c/customers", "", {
                      methodData: {
                          customer: customerToSave,
                          customerGroup: customer.CustomerGroup,
                      },
                  });

            if (!postResponse.succeeded) {
                throw postResponse;
            }

            const savedCustomer = this.customerMapper.toICustomer(postResponse.data);

            if (isNew) {
                this.notifyCustomerAdded(savedCustomer);
            } else {
                this.notifyCustomerChange(savedCustomer);
            }

            return savedCustomer;
        } catch (error) {
            const err = error as ResponseBase;
            this.handleResponseErrors(err.errors);
            throw error;
        }
    }

    GetActualTrust(customerId: number): Promise<ICustomerTrust> {
        return this.ajaxService.Post<ICustomerTrust>("Customers-api/Customers", "GetActualTrust", {
            methodData: {
                CustomerId: customerId,
            },
        });
    }

    deleteCustomer(customerId: number): Promise<number> {
        return this.ajaxService
            .Delete<number>("Customers-api/Customers", "" + customerId, {})
            .then(this.notifyCustomerDeleted.bind(this, customerId));
    }

    addObserver(observer: ICustomersServiceObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: ICustomersServiceObserver): void {
        let index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    getCustomerRecipientCodes(customerId: number): Promise<IRecipientCode[]> {
        return this.ajaxService.Post<IRecipientCode[]>("Customers-api/RecipientCodes", "GetCustomerRecipientCodes", {
            methodData: {
                CustomerId: customerId,
            },
        });
    }

    private notifyCustomerChange(customer: ICustomer): ICustomer {
        this.observers.forEach((observer) => observer.onCustomerChanged(customer));
        return customer;
    }

    private notifyCustomerAdded(customer: ICustomer): ICustomer {
        this.observers.forEach((observer) => observer.onCustomerAdded(customer));
        return customer;
    }

    private notifyCustomerDeleted(customerId: number): number {
        this.observers.forEach((observer) => observer.onCustomerDeleted(customerId));
        return customerId;
    }

    public generateFakeId(): number {
        return this.nextFakeId++;
    }

    GetCustomersForListByGroup(request: IGetCustomersForListByGroupRequest): Promise<ICustomerForListWithGroup[]> {
        return this.ajaxService.Post<ICustomerForListWithGroup[]>(
            "Customers-api/Customers",
            "GetCustomersForListByGroup",
            {
                methodData: request,
            }
        );
    }

    GetSuppliersForList(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<ISupplierForList[]> {
        return this.ajaxService.Post<ISupplierForList[]>("Customers-api/Customers", "GetSuppliersForList", {
            background: true,
            methodData: {
                textFilter: textFilter,
                skip: skip,
                count: count,
            },
        });
    }

    GetSuppliersForListByIds(ids: number[] | null): Promise<ISupplierForList[]> {
        return this.ajaxService.Post<ISupplierForList[]>("Customers-api/Customers", "GetSuppliersForListByIds", {
            background: true,
            methodData: {
                ids: ids,
            },
        });
    }

    GetLetterOfAttemptInvoices(
        LetterOfAttemptId: number | null,
        Skip: number | null,
        Count: number | null
    ): Promise<ILetterOfAttemptInvoice[]> {
        return this.ajaxService.Post<ILetterOfAttemptInvoice[]>(
            "Customers-api/Customers",
            "GetLetterOfAttemptInvoices",
            {
                background: true,
                methodData: {
                    LetterOfAttemptId: LetterOfAttemptId,
                    Skip: Skip,
                    Count: Count,
                },
            }
        );
    }

    GetLetterOfAttemptInvoicesByIds(Ids: number[] | null): Promise<ILetterOfAttemptInvoice[]> {
        return this.ajaxService.Post<ILetterOfAttemptInvoice[]>(
            "Customers-api/Customers",
            "GetLetterOfAttemptInvoicesByIds",
            {
                background: true,
                methodData: {
                    Ids: Ids,
                },
            }
        );
    }

    InsertOrUpdateLettersOfAttempts(LettersOfAttempts: ILetterOfAttemptForEditor[] | null): Promise<void> {
        return this.ajaxService.Post<void>("Customers-api/Customers", "InsertOrUpdateLettersOfAttempts", {
            background: true,
            methodData: {
                LettersOfAttempts: LettersOfAttempts,
            },
        });
    }

    GetCustomersForDocuments(request: IGetCustomersForDocumentsRequest): Promise<ICustomerForDocuments[]> {
        let result = this.ajaxService.Post<ICustomerForDocuments[]>(
            "Customers-api/Customers",
            "GetCustomersForDocuments",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetCustomerByTaxCodeOrVatNumber(taxCode: string | null, vatNumber: string | null): Promise<ICustomerFiscalInfo[]> {
        let result = this.ajaxService.Post<ICustomerFiscalInfo[]>(
            "Customers-api/Customers",
            "GetCustomerByTaxCodeOrVatNumber",
            {
                background: true,
                methodData: {
                    taxCode: taxCode,
                    vatNumber: vatNumber,
                },
            }
        );

        return result;
    }

    GetAllCustomersPaymentExtensionsByIds(ids: number[] | null): Promise<ICustomerPaymentExtension[]> {
        let result = this.ajaxService.Post<ICustomerPaymentExtension[]>(
            "Customers-api/PaymentExtensions",
            "GetAllCustomersPaymentExtensionsByIds",
            {
                background: true,
                methodData: {
                    ids: ids,
                },
            }
        );

        return result;
    }

    GetAllCustomersPaymentExtensions(
        request: IGetAllCustomersPaymentExtensionsRequest
    ): Promise<ICustomerPaymentExtension[]> {
        let result = this.ajaxService.Post<ICustomerPaymentExtension[]>(
            "Customers-api/PaymentExtensions",
            "GetAllCustomersPaymentExtensions",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetLettersOfAttempts(request: IGetLettersOfAttemptsRequest): Promise<ILetterOfAttempt[]> {
        let result = this.ajaxService.Post<ILetterOfAttempt[]>("Customers-api/Customers", "GetLettersOfAttempts", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetLettersOfAttemptsByIds(Ids: number[] | null): Promise<ILetterOfAttempt[]> {
        let result = this.ajaxService.Post<ILetterOfAttempt[]>("Customers-api/Customers", "GetLettersOfAttemptsByIds", {
            background: true,
            methodData: {
                Ids: Ids,
            },
        });

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new CustomersService(serviceLocator);
}
