import { IEstimatedBudgetForTaskStatesService, IEstimatedBudgetForTaskState, IEstimatedBudgetForTaskState_Type } from "./interfaces/IEstimatedBudgetForTaskStatesService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IService } from "../Core/interfaces/IService";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { EstimatedBudgetForTaskStatesSettingsManager } from "./Todolist/settings/EstimatedBudgetForTaskStatesSettingsManager";

@Service(nameof<IEstimatedBudgetForTaskStatesService>())
class EstimatedBudgetForTaskStatesService implements IEstimatedBudgetForTaskStatesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    constructor() {

    }

    InitializeService() {
        new EstimatedBudgetForTaskStatesSettingsManager();
    }

    GetEstimatedBudgetForTaskStates(textFilter: string | null, showDeleted: boolean | null, skip: number | null, count: number | null): Promise<IEstimatedBudgetForTaskState[]> {
        let result = this.ajaxService.Post<IEstimatedBudgetForTaskState[]>("Todolist-api/EstimatedBudgetForTaskStates", "GetEstimatedBudgetForTaskStates", {
            background: true,
            methodData: {
        		textFilter: textFilter,
        		showDeleted: showDeleted,
        		skip: skip,
        		count: count,
        	}
        });



        return result;
    }

    CreateOrUpdateEstimatedBudgetForTaskStates(states: IEstimatedBudgetForTaskState_Type[] | null): Promise<IEstimatedBudgetForTaskState[]> {
        let result = this.ajaxService.Post<IEstimatedBudgetForTaskState[]>("Todolist-api/EstimatedBudgetForTaskStates", "CreateOrUpdateEstimatedBudgetForTaskStates", {
            background: true,
            methodData: {
        		states: states,
        	}
        });



        return result;
    }

    GetEstimatedBudgetForTaskStatesByIds(ids: number[] | null): Promise<IEstimatedBudgetForTaskState[]> {
        let result = this.ajaxService.Post<IEstimatedBudgetForTaskState[]>("Todolist-api/EstimatedBudgetForTaskStates", "GetEstimatedBudgetForTaskStatesByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });



        return result;
    }

    MoveEstimatedBudgetForTaskState(stateId: number | null, position: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Todolist-api/EstimatedBudgetForTaskStates", "MoveEstimatedBudgetForTaskState", {
            background: true,
            methodData: {
        		stateId: stateId,
        		position: position,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IEstimatedBudgetForTaskStatesService>());
}