import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 01/09/2017
 * Time: 17:01
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { PlannerGantt } from "../../../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { PlannerGanttItem, PlannerGanttItemInterval, PlannerGanttItemMarker } from "../../../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { UiUtilities } from "../../../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IJobOrderService, IWorkHistoryRange, IWorkHistoryWorkflows, IAllocationDates, IWorkHistoryTasks, IWorkHistoryTaskWorkStates, IWorkHistoryWork, IResourceAllocation } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, ShowModalOptions, IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IPlannerGanttInterval } from "../../../../../ProlifeSdk/interfaces/controls/gantt/PlannerGanttInterfaces";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkflowStatesSettingsManager, IWorkflowState } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { Deferred } from "../../../../../Core/Deferred";

export class JobOrderWorkHistoryFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderWorkHistory(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewWorkHistory");
    }
}

class JobOrderWorkHistory implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.WorkHistory);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "workHistoryGantt";
    Color:string;

    public Gantt : PlannerGantt;
    private jobOrderService : IJobOrderService;
    private todoListService : ITodoListService;
    private dialogsService : IDialogsService;

    public StartDate : ko.Observable<Date> = ko.observable();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, color : string) {
        this.jobOrderService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        this.Color = color;
        this.Gantt = new PlannerGantt();
        this.Gantt.AllowDragAndDrop(false);
        this.StartDate(moment().subtract('year', 2).startOf('day').toDate());
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
    }

    private getMaxDate(firstDate: Date, secondDate: Date): Date {
        if (moment(firstDate).valueOf() < moment(secondDate).valueOf())
            return secondDate;
        if (moment(firstDate).valueOf() > moment(secondDate).valueOf())
            return firstDate;
        return firstDate;
    }

    @Delay()
    load(): Promise<boolean> {
        const defs: Promise<any>[] = [];
        const def = new Deferred<boolean>();

        defs.push(this.jobOrderService.GetWorkHistoryRange(this.editor.JobOrderId)
            .then((range : IWorkHistoryRange) => {
                this.StartDate(range.StartDate);
                this.Gantt.StartDate(range.StartDate);
                const endDate = moment(range.EndDate).add(10, 'days').toDate();
                this.Gantt.EndDate(endDate);

                defs.push(this.jobOrderService.GetWorkHistoryWorkflows(this.editor.JobOrderId)
                    .then((workflows : IWorkHistoryWorkflows[]) => {
                        const items = [];
                        const workflowStatesItems: PlannerGanttItem[] = this.createWorkflowStatesItems();
                        let lastItem : PlannerGanttItem = null;
                        let lastWorkflowState: number = null;
                        workflows.forEach((w : IWorkHistoryWorkflows) => {
                            if(!lastItem || lastItem.Id() != w.Id) {
                                const workflowItem = new PlannerGanttItem(this.StartDate, this.Gantt);

                                defs.push(this.jobOrderService.GetWorkflowAllocationDates(w.Id)
                                    .then((allocationDates: IAllocationDates) => {
                                        if (allocationDates) {
                                            if (allocationDates.LastRealAllocationDay && moment(allocationDates.LastRealAllocationDay).startOf('day') >= moment().startOf('day')) {
                                                var startDate = this.getMaxDate(moment().startOf('day').toDate(), allocationDates.FirstRealAllocationDay);
                                                var value: string = this.getValueForWorkflowAllocationInterval(allocationDates);
                                                var interval = new AllocationInterval(startDate, allocationDates.LastRealAllocationDay, value, this.StartDate);
                                                interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.5));
                                                interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                                interval.IsDirectAllocation(!!allocationDates.DirectAllocation);
                                                interval.IsCompletelyAllocated(!!allocationDates.IsCompletelyAllocated);
                                                interval.IsWorkflow(true);
                                                interval.FontColor(w.Foreground);
                                                //interval.Style("no-estimate");
                                                interval.TooltipText(this.getTooltipValueForWorkflowAllocationInterval(allocationDates));
                                                interval.Tag(true);
                                                workflowItem.Intervals.push(interval);
                                            }

                                            if (allocationDates.LastTheoreticalAllocationDay  && moment(allocationDates.LastTheoreticalAllocationDay).startOf('day') >= moment().startOf('day')) {
                                                var startDate = allocationDates.LastRealAllocationDay ? allocationDates.FirstTheoreticalAllocationDay :
                                                    this.getMaxDate(moment().startOf('day').toDate(), allocationDates.FirstTheoreticalAllocationDay);
                                                var value: string = this.getValueForWorkflowAllocationInterval(allocationDates);
                                                var interval = new AllocationInterval(startDate, allocationDates.LastTheoreticalAllocationDay, value, this.StartDate);
                                                interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                                interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.5));
                                                interval.IsDirectAllocation(!!allocationDates.DirectAllocation);
                                                interval.IsCompletelyAllocated(!!allocationDates.IsCompletelyAllocated);
                                                interval.IsWorkflow(true);
                                                interval.FontColor(w.Foreground);
                                                //interval.Style("future-theoretical-allocation-info no-estimate");
                                                interval.Style("future-theoretical-allocation-info");
                                                interval.TooltipText(this.getTooltipValueForWorkflowAllocationInterval(allocationDates));
                                                interval.Tag(true);
                                                workflowItem.Intervals.push(interval);
                                            }

                                            this.createWorkflowStatesIntervals(workflowStatesItems);
                                        }
                                    }));

                                workflowItem.Title(w.Title || ProlifeSdk.TextResources.JobOrder.NoWorkflow);
                                workflowItem.MultipleIntervals(true);
                                workflowItem.Id(w.Id);
                                workflowItem.SetEditingDialog(() => {
                                    return this.todoListService.ShowEditWorkflowDialog(w.Id);
                                });

                                if(w.StartDate) {
                                    const startDateMarker = new PlannerGanttItemMarker(w.StartDate, this.StartDate);
                                    startDateMarker.Color(w.Background);
                                    startDateMarker.Type("arrowRight");
                                    workflowItem.Markers.push(startDateMarker);
                                }
                                if(w.EndDate) {
                                    const dueDateMarker = new PlannerGanttItemMarker(w.EndDate, this.StartDate);
                                    dueDateMarker.Color(w.Background);
                                    dueDateMarker.Type("arrowLeft");
                                    workflowItem.Markers.push(dueDateMarker);
                                }

                                workflowItem.OnChildrenNeeded((callback : (children : PlannerGanttItem[]) => void) => {
                                    Promise.all([
                                        this.jobOrderService.GetWorkHistoryTasks(w.Id), 
                                        this.jobOrderService.GetWorkHistoryTaskWorkStates(w.Id)
                                    ]).then(([tasks, workStates]) => {
                                            const taskItems = [];
                                            let lastTask : PlannerGanttItem = null;
                                            tasks.forEach((t : IWorkHistoryTasks) => {
                                                if(!lastTask || lastTask.Id() != t.Id) {
                                                    const taskItem = new PlannerGanttItem(this.StartDate, this.Gantt);
                                                    taskItem.Title(t.Title || ProlifeSdk.TextResources.JobOrder.NoTask);
                                                    taskItem.MultipleIntervals(true);
                                                    taskItem.Id(t.Id);
                                                    taskItem.SetEditingDialog(() => {
                                                        return this.todoListService.ShowEditTaskDialog(t.Id);
                                                    });

                                                    if(t.StartDate) {
                                                        const startDateMarker = new PlannerGanttItemMarker(t.StartDate, this.StartDate);
                                                        startDateMarker.Color(w.Background);
                                                        startDateMarker.Type("arrowRight");
                                                        taskItem.Markers.push(startDateMarker);
                                                    }
                                                    if(t.DueDate) {
                                                        const dueDateMarker = new PlannerGanttItemMarker(t.DueDate, this.StartDate);
                                                        dueDateMarker.Color(w.Background);
                                                        dueDateMarker.Type("arrowLeft");
                                                        taskItem.Markers.push(dueDateMarker);
                                                    }

                                                    if (t.LastRealAllocationDay && moment(t.LastRealAllocationDay).startOf('day') >= moment().startOf('day')) {
                                                        var startDate = this.getMaxDate(moment().startOf('day').toDate(), t.FirstRealAllocationDay);
                                                        var value: string = this.getValueForTaskAllocationInterval(t);
                                                        var interval = new AllocationInterval(startDate, t.LastRealAllocationDay, value, this.StartDate);
                                                        interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.75));
                                                        interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                                        //interval.Style("no-estimate");
                                                        interval.FontColor(w.Foreground);
                                                        interval.IsDirectAllocation(!!t.DirectAllocation);
                                                        interval.TooltipText(this.getTooltipValueForTaskAllocationInterval(t));
                                                        interval.Tag(true);
                                                        taskItem.Intervals.push(interval);
                                                    }

                                                    if (t.LastTheoreticalAllocationDay  && moment(t.LastTheoreticalAllocationDay).startOf('day') >= moment().startOf('day')) {
                                                        var startDate = t.LastRealAllocationDay ? t.FirstTheoreticalAllocationDay :
                                                            this.getMaxDate(moment().startOf('day').toDate(), t.FirstTheoreticalAllocationDay);
                                                        var value: string = this.getValueForTaskAllocationInterval(t);
                                                        var interval = new AllocationInterval(startDate, t.LastTheoreticalAllocationDay, value, this.StartDate);
                                                        interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                                        interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.5));
                                                        //interval.Style("future-theoretical-allocation-info no-estimate");
                                                        interval.Style("future-theoretical-allocation-info");
                                                        interval.FontColor(w.Foreground);
                                                        interval.IsDirectAllocation(!!t.DirectAllocation);
                                                        interval.TooltipText(this.getTooltipValueForTaskAllocationInterval(t));
                                                        interval.Tag(true);
                                                        taskItem.Intervals.push(interval);
                                                    }

                                                    taskItem.OnChildrenNeeded((callback : (children : PlannerGanttItem[]) => void) => {
                                                        this.jobOrderService.GetWorkHistoryWork(t.Id)
                                                            .then((work : IWorkHistoryWork[]) => {
                                                                const workItems = [];
                                                                let lastWork : PlannerGanttItem = null;
                                                                work.forEach((h : IWorkHistoryWork) => {
                                                                    if(!lastWork || lastWork.Id() != h.Id) {
                                                                        const workItem = new PlannerGanttItem(this.StartDate, this.Gantt);
                                                                        workItem.Title(h.FullName);
                                                                        workItem.MultipleIntervals(true);
                                                                        workItem.Id(h.Id);

                                                                        const startDate = moment().startOf('day').toDate();
                                                                        this.jobOrderService.GetResourceAllocationRanges(h.Id, startDate, this.Gantt.EndDate())
                                                                            .then((ranges: IResourceAllocation[]) => {
                                                                                ranges.forEach((r: IResourceAllocation) => {
                                                                                    const value: string = r.TeamName + " - " + r.Amount + "%";
                                                                                    const interval = new AllocationInterval(r.StartDate, r.EndDate, value, this.StartDate);
                                                                                    interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 1));
                                                                                    interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 1));
                                                                                    //interval.Style("no-estimate");
                                                                                    interval.FontColor(w.Foreground);
                                                                                    interval.TooltipText(value);
                                                                                    workItem.Intervals.push(interval);
                                                                                });

                                                                            });

                                                                        workItems.push(workItem);
                                                                        lastWork = workItem;
                                                                    }

                                                                    if (h.WorkDay) {
                                                                        const workInterval = new AllocationInterval(h.WorkDay, h.WorkDay, h.Hours, this.StartDate);
                                                                        workInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 1));
                                                                        workInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 1));
                                                                        workInterval.FontColor(w.Foreground);
                                                                        lastWork.Intervals.push(workInterval);
                                                                    }
                                                                });

                                                                callback(workItems);
                                                            });
                                                    });

                                                    const stateChanges = workStates.filter(w => w.Id == t.Id);
                                                    let index = 0;
                                                    let lastNonWorkIndex = -1;
                                                    stateChanges.forEach(ws => {
                                                        if(!ws.IsWorking)
                                                            lastNonWorkIndex = index;
                                                        index++;
                                                    });

                                                    index = 0;
                                                    stateChanges.forEach(ws => {
                                                        const marker = new PlannerGanttItemMarker(ws.Day, this.StartDate);
                                                        if(index == lastNonWorkIndex)
                                                            marker.Color("red");
                                                        else
                                                            marker.Color("black");
                                                        marker.Type(ws.IsWorking ? "working" : "nonWorking");
                                                        taskItem.Markers.push(marker);
                                                        index++;
                                                    });

                                                    taskItems.push(taskItem);
                                                    lastTask = taskItem;
                                                }

                                                if (t.WorkDay) {
                                                    const taskInterval = new AllocationInterval(t.WorkDay, t.WorkDay, t.TotalHours, this.StartDate);
                                                    taskInterval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.75));
                                                    taskInterval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                                    taskInterval.FontColor(w.Foreground);
                                                    lastTask.Intervals.push(taskInterval);
                                                }
                                            });

                                            callback(taskItems);
                                        });
                                });

                                lastWorkflowState = w.WorkflowStateId;
                                lastItem = workflowItem;

                                if (workflowItem.Id() < 0)
                                    items.push(workflowItem);
                                else {
                                    const item: PlannerGanttItem = workflowStatesItems.filter((i: PlannerGanttItem) => { return i.Id() == lastWorkflowState; })[0];
                                    item.Children.push(workflowItem);
                                }
                            }

                            if (w.WorkDay) { //N.B. se il flusso non ha ore lavorate nel passato la worday è null e l'interbvallo non deve essere creato!!!
                                const interval = new AllocationInterval(w.WorkDay, w.WorkDay, w.TotalHours, this.StartDate);
                                interval.Color(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.5));
                                interval.BorderColor(UiUtilities.RgbToString(UiUtilities.HexToRgb(w.Background), 0.25));
                                interval.FontColor(w.Foreground);
                                lastItem.Intervals.push(interval);
                            }
                        });

                        this.createWorkflowStatesIntervals(workflowStatesItems);

                        const nonEmptyWorkflowStatesItems = workflowStatesItems.filter((i : PlannerGanttItem) => i.Children().length > 0);

                        this.Gantt.Items(items.concat(nonEmptyWorkflowStatesItems));
                    }));
            }));
        Promise.all(defs)
            .finally(() => {
                def.resolve(true);
            });

        return def.promise();
    }

    private createWorkflowStatesItems(): PlannerGanttItem[] {
        const ganttItems: PlannerGanttItem[] = [];
        const settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const workflowStatesSettingManager: IWorkflowStatesSettingsManager = <IWorkflowStatesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.WorkflowStates);
        const states: IWorkflowState[] = workflowStatesSettingManager.getStates(false);
        states.sort((a, b) => - (b.Position - a.Position) );
        states.forEach((s: IWorkflowState) => {
            const newItem = new PlannerGanttItem(this.Gantt.StartDate, this.Gantt);
            newItem.Id(s.Id);
            newItem.Title(s.Description);
            newItem.MultipleIntervals(true);
            newItem.Children([]);
            ganttItems.push(newItem);
        });

        return ganttItems;
    }

    private createWorkflowStatesIntervals(items: PlannerGanttItem[]): void {
        items.forEach((i: PlannerGanttItem) => {
            let allIntervals: IPlannerGanttInterval[] = [];
            const intervals: AllocationInterval[] = [];

            i.Children().forEach((c: PlannerGanttItem) => {
                allIntervals = allIntervals.concat(c.Intervals());
            });

            allIntervals.sort((a : AllocationInterval, b : AllocationInterval) => moment(a.StartDate()).diff(moment(b.StartDate()), 'days'));
            let firstDate : AllocationInterval = null;
            let sum = 0;

            let minDate = moment("2100-01-01");
            let maxDate = moment("1900-01-01");

            allIntervals.forEach((int: AllocationInterval) => {
                if (!firstDate || moment(firstDate.StartDate()).valueOf() != moment(int.StartDate()).valueOf()) {
                    if (!!firstDate && !int.Tag()) {
                        const interval = new AllocationInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.StartDate);
                        interval.Color(this.getStateColor(i.Id()));
                        interval.BorderColor("rgba(0, 0, 0, 0.40)");
                        interval.FontColor("#FFFFFF");
                        intervals.push(interval);
                    }

                    if (!int.Tag()) {
                        firstDate = int;
                        sum = 0;
                    }
                }
                if (!int.Tag())
                    sum = sum + int.Value();

                if (int.Tag()) {
                    minDate = moment(int.StartDate()) < minDate ? moment(int.StartDate()) : minDate;
                    maxDate = moment(int.EndDate()) > maxDate ? moment(int.EndDate()) : maxDate;
                }
            });
            if (firstDate) {
                var interval = new AllocationInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.StartDate);
                interval.Color(this.getStateColor(i.Id()));
                interval.BorderColor("rgba(0, 0, 0, 0.40)");
                interval.FontColor("#FFFFFF");
                intervals.push(interval);
            }

            if (maxDate >= minDate) {
                var interval = new AllocationInterval(minDate.toDate(), maxDate.toDate(), ProlifeSdk.TextResources.JobOrder.MultipleCarts, this.Gantt.StartDate);
                interval.Color(this.getStateColor(i.Id()));
                //interval.Style("no-estimate");
                interval.BorderColor("rgba(0, 0, 0, 0.40)");
                interval.FontColor("#FFFFFF");
                intervals.push(interval);
            }

            i.Intervals(intervals);
        });
    }

    private getStateColor(stateId: number): string {
        switch (stateId) {
            case 1:
                return "#ddd";
            case 2:
                return "rgb(83, 147, 89)";
            case 3:
                return "rgb(0, 128, 0)";
            case 4:
                return "#96b9f1";
            case 6:
                return "#FF0000";
            default:
                return "#FFFF00"
        }
    }

    getValueForTaskAllocationInterval(task: IWorkHistoryTasks): string {
        let value: string;
        value = task.DirectAllocation == 1 ? task.CartName : "";
        value = value + '&nbsp;&nbsp; - &nbsp;&nbsp;' + ProlifeSdk.TextResources.JobOrder.ProgressAvgLabel + ': ' + task.ProgressAvg + '%' + '&nbsp;&nbsp; - &nbsp;&nbsp;'
            + ProlifeSdk.TextResources.JobOrder.RemainingHoursLabel + ': ' + task.RemainingWork;
        return value;
    }

    getTooltipValueForTaskAllocationInterval(task: IWorkHistoryTasks): string {
        let value: string;
        value = task.DirectAllocation == 1 ? task.CartName + ' - ' : '<i class="fa fa-arrow-up" ></i> - ';
        value = value + ProlifeSdk.TextResources.JobOrder.ProgressAvgLabel + ': ' + task.ProgressAvg + '%' + ' - '
            + ProlifeSdk.TextResources.JobOrder.RemainingHoursLabel + ': ' + task.RemainingWork;
        return value;
    }

    getValueForWorkflowAllocationInterval(workflowDates: IAllocationDates): string {
        let value: string;
        value = workflowDates.DirectAllocation == 1 ? workflowDates.CartName : "";
        value = value + '&nbsp;&nbsp; - &nbsp;&nbsp;' + ProlifeSdk.TextResources.JobOrder.ProgressAvgLabel + ": "
            + (workflowDates.WorkedHours == 0 || workflowDates.ReestimatedHours == 0 ? 'N/D' : workflowDates.ProgressAvg + '%') + '&nbsp;&nbsp; - &nbsp;&nbsp;'
            + ProlifeSdk.TextResources.JobOrder.RemainingHoursLabel + ": " + (workflowDates.WorkedHours == 0 || workflowDates.ReestimatedHours == 0 ? 'N/D' : workflowDates.RemainingWork.toString());
        return value;
    }

    getTooltipValueForWorkflowAllocationInterval(workflowDates: IAllocationDates): string {
        let value: string;
        value = workflowDates.DirectAllocation == 1 ? workflowDates.CartName + ' - ' :
            (workflowDates.IsCompletelyAllocated ?
                '<i class="fa fa-arrow-down"></i><i class="fa fa-arrow-down"></i> - ' :
                '<i class="fa fa-arrow-down"></i><i class="fa fa-times"></i> - ');
        value = value + ProlifeSdk.TextResources.JobOrder.ProgressAvgLabel + ": "
            + (workflowDates.WorkedHours == 0 || workflowDates.ReestimatedHours == 0 ? 'N/D' : workflowDates.ProgressAvg + '%') + ' - '
            + ProlifeSdk.TextResources.JobOrder.RemainingHoursLabel + ": " + (workflowDates.WorkedHours == 0 || workflowDates.ReestimatedHours == 0 ? 'N/D' : workflowDates.RemainingWork.toString());
        return value;
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    OnWorkflowSelectionChanged(selection:number[]) {
        this.load();
    }

    OnWorkflowDeleted(workflowId:number) {
    }

    OnWorkflowChanged(workflowId:number) {
    }

    OnWorkflowCreated(workflowId:number) {
    }

    OnWorkflowImportedFromTemplate(workflowId:number) {
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    private hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1
        } : null;
    }

    private rgbToString(rgb : any, overrideAlpha? : number) {
        if(overrideAlpha != undefined || overrideAlpha != null) {
            rgb.a = overrideAlpha;
        }

        return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a +")";
    }

    public ShowLegend() {
        const legendDialog = new LegendDialog();
        const options : ShowModalOptions = {
            noPrompt: true
        }
        this.dialogsService.ShowModal<void>(legendDialog, "", options);
    }
}

class LegendDialog implements IDialog {
    templateName = "workHistoryLegend";
    templateUrl = "joborder/templates/joborderdetail";
    title:string = ProlifeSdk.TextResources.JobOrder.Legend;
    modal: {
        close: (result?: any) => void;
    };

    close():void {
        this.modal.close();
    }

    action():void {
        this.modal.close();
    }

}

class AllocationInterval extends PlannerGanttItemInterval {
    public IsDirectAllocation: ko.Observable<boolean> = ko.observable(true);
    public IsWorkflow: ko.Observable<boolean> = ko.observable(false);
    public IsCompletelyAllocated: ko.Observable<boolean> = ko.observable(true);

    public TooltipText: ko.Observable<string> = ko.observable("");

    constructor(startDate: Date, endDate: Date, value: any, periodStart: ko.Observable<Date>) {
        super(startDate, endDate, value, periodStart);
    }
}