import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { Application } from "../../Components/Application";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { ArrayDataSource } from "../../DataSources/ArrayDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../DataSources/IDataSource";
import { FeatureEditor } from "./Features/FeatureEditor";
import { GeneralContainerEditor } from "./GeneralContainerEditor";
import { RequirementEditor } from "./Requirements/RequirementEditor";
import { StatementsList } from "./Statements/StatementsList";
import { Template } from "../../Bindings/ForceBinding";

type FunctionPointComputationEditorProps = {
    onBack: ()=> void;

    infrastructureId: number;
}

export function InfrastructureEditor(props: FunctionPointComputationEditorProps) {
    const C = require("./InfrastructureEditor")._InfrastructureEditor as typeof _InfrastructureEditor;
    return <C {...props} />;
}

export class _InfrastructureEditor implements IDataSourceListener{
    static defaultProps: Partial<FunctionPointComputationEditorProps> = {
    }

    navigationMenuDataSource = new ArrayDataSource();
    selectedPage: ko.Observable<()=> React.ReactNode> = ko.observable();
    infrastructureId: ko.Observable<number> = ko.observable();

    constructor(private props : FunctionPointComputationEditorProps) { 
        this.infrastructureId(props.infrastructureId);
        this.updateMenu();
    }

    private updateMenu() {
        if(this.infrastructureId() <= 0) {
            this.navigationMenuDataSource.setData(
                this.createNavigationMenuItem(1, "Generale", () => <GeneralContainerEditor 
                    infrastructureId={this.infrastructureId()} 
                    onBack={this.props.onBack}
                    onSave={this.onGeneralSave.bind(this)}/>
                )
            );
        } else {
            this.navigationMenuDataSource.setData(
                this.createNavigationMenuItem(1, "Generale", () => <GeneralContainerEditor 
                    infrastructureId={this.infrastructureId()} 
                    onBack={this.props.onBack}
                    onSave={this.onGeneralSave.bind(this)}/>
                ),
                this.createNavigationMenuItem(2, "Gestione schede", () => <StatementsList 
                    infrastructureId={this.infrastructureId()} 
                    onBack={this.props.onBack} />
                ),
                this.createNavigationMenuItem(3, "Requisiti", () => <RequirementEditor
                    infrastructureId={this.infrastructureId()}
                    onBack={this.props.onBack} />
                ),
                this.createNavigationMenuItem(4, "Funzioni", () => <FeatureEditor 
                    infrastructureId={this.infrastructureId()}
                    onBack={this.props.onBack}/>
                )
            )
        }
        
        this.navigationMenuDataSource.selectByIds(1);
    }

    private onGeneralSave(infrastructureId : number) {
        this.infrastructureId(infrastructureId);
        this.updateMenu();
    }

    private createNavigationMenuItem(id: number, title: string, model: () => React.ReactNode) {
        return {
            id,
            isGroup: false,
            isLeaf: true,
            title,
            model
        };
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void{
        if(sender === this.navigationMenuDataSource)
            this.selectedPage(model.model);
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void{

    }

    render() {
        return <Application>
                    <Application.LeftMenu>
                        <NavigationMenu allowSearch={false} dataSource={this.navigationMenuDataSource} listener={this}/>
                    </Application.LeftMenu>
                    <Application.Content>
                        <Template component={this.selectedPage} />
                    </Application.Content>
                </Application>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(InfrastructureEditor);
}