import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ValidityTypesEditingViewModel } from "./ui/ValidityTypesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IValidityTypes, IValidityType, IValidityTypesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IValidityTypes";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { LazyImport } from "../../../Core/DependencyInjection";

export class ValidityTypesSettingsManager implements IValidityTypes {
    private validityTypes : IValidityType[] = [];
    private observers : IValidityTypesObserver[] = [];

    @LazyImport(nameof<ISettingsService>())
    private settingsService : ISettingsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator) {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }

    async load(): Promise<IValidityType[]> {
        const validityTypes: IValidityType[] = await this.ajaxService.Get("Invoices-api", "ValidityType", {});
        this.validityTypes = validityTypes;
        return validityTypes.slice();
    }

    getName(): string {
        return ProlifeSdk.ValidityTypes;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.QuoteValidity;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: 'setting',
            templateUrl: 'settings/templates',
            viewModel: new ValidityTypesEditingViewModel(this.serviceLocator, this)
        };
    }

    getValidityTypes() : IValidityType[] {
        return this.validityTypes;
    }

    getValidityTypeIdByName(validityTypeName : string) : number {
        const validities = this.validityTypes.filter((validity : IValidityType) => validity.Descrizione == validityTypeName);
        if(validities.length == 0) return null;
        return validities[0].Id;
    }

    async createOrUpdate(validityType : IValidityType) : Promise<IValidityType> {
        let updatedValidityType: IValidityType;

        if (validityType.Id > 0) {
            updatedValidityType = await this.ajaxService.Put("Invoices-api", "ValidityType", { methodData: validityType});
            this.onValidityTypeChanged(updatedValidityType);
            
        } else {
            updatedValidityType = await this.ajaxService.Post("Invoices-api", "ValidityType", { methodData: validityType});
            this.onValidityTypeAdded(updatedValidityType);
        }

        return updatedValidityType;
    }

    async remove(validityTypeId : number) : Promise<void> {
        await this.ajaxService.Delete("Invoices-api", "ValidityType/" + validityTypeId, {});
        this.onValidityTypeDeleted(validityTypeId);
    }

    addObserver(observer : IValidityTypesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IValidityTypesObserver) : void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    private onValidityTypeChanged(validityType : IValidityType) {
        this.load();
        this.observers.forEach((obs) => obs.onValidityTypeChanged(validityType));
    }

    private onValidityTypeAdded(validityType : IValidityType) {
        this.load();
        this.observers.forEach((obs) => obs.onValidityTypeAdded(validityType));
    }

    private onValidityTypeDeleted(validityTypeId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onValidityTypeDeleted(validityTypeId));
    }
}