const dpi = 96;

export function CentimetersToPixels(cm: number): number {
    const inches = cm / 2.54;
    const pixels = inches * dpi;
    return pixels;
}

export function PixelsToCentimeters(pixels: number): number {
    const inches = pixels / dpi;
    const cm = inches * 2.54;
    return cm;
}

export function PointsToCentimeters(points: number) : number {
    const inches = points / 72;
    const cm = inches * 2.54;
    return cm;
}

export function CentimetersToPDFUnit(cm: number) : number {
    const inches = cm / 2.54;
    return inches * 72;
}