import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IJobOrderService } from "../ProlifeSdk/interfaces/job-order/IJobOrderService";

export class JobOrderDisplayValue {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options = valueAccessor();
        var jobOrderService : IJobOrderService = <IJobOrderService> Core.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        var JobOrderText : ko.Observable<string> = ko.observable("");

        jobOrderService.GetNameById(options.id).then((name : string) => {
            JobOrderText(name);
        });

        ko.applyBindingsToNode(element, { text: JobOrderText }, undefined);
    }
}

ko.bindingHandlers["jobOrderDisplayValue"] = new JobOrderDisplayValue();
ko.virtualElements.allowedBindings["jobOrderDisplayValue"] = true;