import * as ko from "knockout";
import { KnockoutType } from "@abstraqt-dev/jsxknockout";

declare global {
    interface KnockoutDataBindsAttributes {
        checkbox?: KnockoutType<boolean>;
    }
}

export class Checkbox {
    init(element: HTMLInputElement, valueAccessor: () => ko.Observable<boolean>, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) : void | { controlsDescendantBindings: boolean; } {
        let accessor = valueAccessor();
        if(!ko.isWriteableObservable(accessor))
            return;

        $(element).on("change", () => {
            accessor(element.checked);
        }).on("click", (ev) => {
            ev.stopPropagation();
        });
    }

    update(element: HTMLInputElement, valueAccessor: () => ko.Observable<boolean>, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) : void | { controlsDescendantBindings: boolean; } {
        let value = ko.unwrap(valueAccessor());

        if(value) {
            element.checked = true;
            element.indeterminate = false;
        } else if(value === null || value === undefined) {
            element.checked = true;
            element.indeterminate = true;
        } else {
            element.checked = false;
            element.indeterminate = false;
        }
    }
}

ko.bindingHandlers["checkbox"] = new Checkbox();