import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk";
import { WizardStep } from "./WizardStep";
import { LazyImport } from "../../Core/DependencyInjection";
import { IDocumentsService } from "../../Invoices/DocumentsService";
import { IDocumentDataSource } from "../interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../interfaces/invoice/wizard/IWizardInitializationInfo";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";

export class ImportDataWizardSelectSourcesStep extends WizardStep
{
    private Sources : Source[];

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    constructor(destinationDocumentTypeCode : string, private DataSourcesDestinationCollection : ko.ObservableArray<IDocumentDataSource>, protected initializationInfo : IWizardInitializationInfo)
    {
        super("import-data-wizard-select-sources-step", "prolifesdk/templates/wizard/steps", ProlifeSdk.TextResources.ProlifeSdk.SelectDataSources, initializationInfo);
        
        this.Sources = this.documentsService.getDataSourcesForDocumentType(destinationDocumentTypeCode)
            .filter((s : IDocumentDataSource) => { return s.CheckInitializationInfoSupport(initializationInfo); })
            .map((s : IDocumentDataSource) => this.createSourceViewModel(s));
    }

    private createSourceViewModel(s : IDocumentDataSource)
    {
        return new Source(s);
    }

    public BeforeShow()
    {
        this.DataSourcesDestinationCollection([]);
    }

    public BeforeNext() : boolean
    {
        var selected : IDocumentDataSource[] = this.Sources
            .filter((s : Source) => s.IsSelected())
            .map((s : Source) => s.source);

        if(selected.length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.ProlifeSdk.SelectAtLeastOneDataSource);
            return false;
        }

        this.DataSourcesDestinationCollection(selected);
        return true;
    }
}

class Source
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public source : IDocumentDataSource)
    {
    }
}