import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IPurchasesService, IGetPurchasesForMonthlyInvoicingCountRequest, ILockedActivity, IGetPurchasesForMonthlyInvoicingRequest, IPurchaseForMonthlyInvoicing } from "./interfaces/IPurchasesService";
import { IService } from "../Core/interfaces/IService";

export class PurchasesService implements IPurchasesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService:IAjaxService;

    constructor(serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstanceWithName(nameof<IPurchasesService>(), this)
    }

    getServiceType() : string {
        return nameof<IPurchasesService>();
    }
    
    isOfType(serviceType: string): boolean {
        return this.getServiceType() === serviceType;
    }

    GetPurchasesForMonthlyInvoicingCount(request: IGetPurchasesForMonthlyInvoicingCountRequest): Promise<number> {
        return this.ajaxService.Post<number>("Blog-api/Purchases", "GetPurchasesForMonthlyInvoicingCount", {
            background: true,
            methodData: request
        });
    }

    GetLockedActivitiesForEvent(eventId: number, tasksIds: number[]): Promise<ILockedActivity[]> {
        return this.ajaxService.Post<ILockedActivity[]>("Blog-api/Purchases", "GetLockedActivitiesForEvent", {
            methodData: {
                EventId: eventId,
                TasksIds: tasksIds
            }
        });
    }

    GetPurchasesForMonthlyInvoicing(request: IGetPurchasesForMonthlyInvoicingRequest): Promise<IPurchaseForMonthlyInvoicing[]> {
        let result = this.ajaxService.Post<IPurchaseForMonthlyInvoicing[]>("Blog-api/Purchases", "GetPurchasesForMonthlyInvoicing", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new PurchasesService(serviceLocator);
}
