import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, PropsWithChildren, reloadNow } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { If, IfNot } from "./IfIfNotWith";

const { classes } = jss
    .createStyleSheet({
        portlet: {
            overflow: "hidden",

            "&.no-margin": {
                marginBottom: 0,
            },

            "& .portlet-body.no-overflow": {
                overflow: "hidden",
            },

            "&.light.bordered.no-body, &.light.bordered.collapsed": {
                "& > .portlet-title": {
                    border: "none",
                },
            },

            "& .portlet-title": {
                marginBottom: "0px !important",

                "& > .actions": {
                    "& > .btn": {
                        "&.btn-icon-only": {
                            padding: "5px 8px 3px 8px",
                        },
                        "&:not(:last-child)": {
                            marginRight: "3px",
                        },
                    },
                },
            },
        },
    })
    .attach();

export interface IPortletProps {
    className?: string;
    icon?: string;
    portletTitle?: string;
    collapsible?: ko.MaybeSubscribable<boolean> | (() => boolean);
    initialCollapsed?: boolean;
    children?: React.ReactNode;
    flex?: boolean;
    noOverflow?: boolean;
    noMargin?: boolean;
    noBorder?: boolean;
    injectTo?: ko.Observable<IPortletVM>;
    forwardRef?: (p: Portlet) => void;
    style?: React.CSSProperties;
    actions?: () => ReactNode;
}

export interface IPortletVM {
    Collapsible: ko.Subscribable<boolean>;
    Collapsed: ko.Observable<boolean>;
    switchCollapsedMode();
}

export function PortletHeaderContent(props: {
    icon?: string;
    className?: string;
    title: ko.MaybeObservable<string> | ko.MaybeComputed<string> | (() => string);
    subTitle?: ko.MaybeObservable<string> | ko.MaybeComputed<string> | (() => string);
}) {
    const titleClasses = ComponentUtils.classNames("caption-subject", props.className);
    if (!ko.isSubscribable(props.title) && typeof props.title === "function") props.title = ko.computed(props.title);
    if (!ko.isSubscribable(props.subTitle) && typeof props.subTitle === "function")
        props.subTitle = ko.computed(props.subTitle);

    return ComponentUtils.bindTo(
        <>
            {props.icon && <i className={props.icon}></i>}
            <span className={titleClasses} data-bind={{ text: props.title }}></span>
            {props.subTitle && (
                <>
                    &nbsp;<span className="caption-helper" data-bind={{ text: props.subTitle }}></span>
                </>
            )}
        </>,
        props,
        "props"
    ) as React.ReactElement;
}

export class PortletHeader {
    static Default = PortletHeaderContent;

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(private props: PropsWithChildren<{}>) {}

    renderHeader() {
        return <>{this.props.children}</>;
    }

    render() {
        return <></>;
    }
}

export class PortletActions {
    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(private props: PropsWithChildren<{}>) {}

    renderActions(portletProps: IPortletProps, collapsible: ko.Subscribable<boolean>) {
        if (Array.isArray(this.props.children) && this.props.children.length === 0) return <></>;

        let classes = ComponentUtils.classNames("actions", {
            "margin-right-10": ko.unwrap(collapsible),
        });

        return <div class={classes}>{this.props.children}</div>;
    }

    render(): React.ReactElement {
        return <div></div>;
    }
}

export class PortletTools {
    constructor(private props: { children: () => React.ReactElement }) {}

    renderTools(portletProps: IPortletProps, collapsible: ko.Subscribable<boolean>) {
        let childFunction = null;
        if (Array.isArray(this.props.children) && this.props.children.length > 0)
            childFunction = this.props.children[0];
        else if (typeof this.props.children === "function") childFunction = this.props.children;
        else {
            console.log("this.props.children = " + this.props.children);
            throw "Che mi hai passato?";
        }

        let portletVM: Portlet;
        return (
            <div class="tools">
                {childFunction()}
                <If condition={collapsible}>
                    {() => (
                        <a
                            href="javascript:void(0)"
                            class="collapse"
                            data-bind={{
                                click: portletVM.switchCollapsedMode.bind(portletVM),
                                clickBubble: false,
                                css: { expand: portletVM.Collapsed, collapse: !portletVM.Collapsed() },
                            }}></a>
                    )}
                </If>
            </div>
        );
    }

    render(): React.ReactElement {
        return <></>;
    }
}

export class PortletBody {
    constructor(private props: { children: () => React.ReactElement }) {}

    renderBody(portletProps: IPortletProps, collapsed: ko.Observable<boolean>) {
        let bodyClasses = ComponentUtils.classNames("portlet-body", {
            "flex-container": portletProps.flex,
            "flex-1": portletProps.flex,
            "flex-vertical": portletProps.flex,
            "not-important": portletProps.flex,
            "no-overflow": portletProps.noOverflow,
        });

        let childFunction = null;
        if (Array.isArray(this.props.children) && this.props.children.length > 0)
            childFunction = this.props.children[0];
        else if (typeof this.props.children === "function") childFunction = this.props.children;
        else {
            console.log("this.props.children = " + this.props.children);
            throw "Che mi hai passato?";
        }

        return (
            <div class={bodyClasses}>
                <IfNot condition={collapsed}>{childFunction}</IfNot>
            </div>
        );
    }

    render() {
        return <></>;
    }
}
export class Portlet {
    static Header = PortletHeader;
    static Body = PortletBody;
    static Actions = PortletActions;
    static Tools = PortletTools;

    Collapsible: ko.Subscribable<boolean>;
    Collapsed: ko.Observable<boolean> = ko.observable(false);

    static defaultProps = {
        flex: true,
        noBorder: false,
        className: "light",
        initialCollapsed: false,
    };

    constructor(private props: IPortletProps) {
        if (!this.props.collapsible) this.props.collapsible = ko.observable(true);

        if (!ko.isSubscribable(props.collapsible) && typeof props.collapsible === "function")
            this.Collapsible = ko.computed(props.collapsible);
        else if (!ko.isSubscribable(props.collapsible)) this.Collapsible = ko.observable(props.collapsible);
        else this.Collapsible = props.collapsible;
        this.Collapsed(props.initialCollapsed);

        if (props.injectTo) props.injectTo(this);

        if (props.forwardRef) props.forwardRef(this);
    }

    switchCollapsedMode() {
        if (!this.Collapsible()) return;
        this.Collapsed(!this.Collapsed());
    }

    private renderActions() {
        if (!this.props.actions) return <></>;

        let classes = ComponentUtils.classNames("actions", {
            "margin-right-10": this.Collapsible(),
        });

        return <div className={classes}>{this.props.actions()}</div>;
    }

    render() {
        let portletClasses = ComponentUtils.classNames(
            this.props.className,
            classes.portlet,
            "portlet",
            "task-selection",
            {
                "flex-container": this.props.flex,
                "flex-1": this.props.flex,
                "flex-vertical": this.props.flex,
                "no-margin": this.props.noMargin,
                bordered: !this.props.noBorder,
            }
        );

        let header,
            actions: PortletActions,
            tools: PortletTools,
            body: PortletBody,
            otherNodes = [];

        ComponentUtils.Children.forEach(this.props.children as unknown as ReactNode, (c) => {
            if (ComponentUtils.isOfType(c, PortletHeader)) header = ComponentUtils.getComponent<PortletHeader>(c);
            else if (ComponentUtils.isOfType(c, PortletActions))
                actions = ComponentUtils.getComponent<PortletActions>(c);
            else if (ComponentUtils.isOfType(c, PortletTools)) tools = ComponentUtils.getComponent<PortletTools>(c);
            else if (ComponentUtils.isOfType(c, PortletBody)) body = ComponentUtils.getComponent<PortletBody>(c);
            else otherNodes.push(c);
        });

        if (otherNodes.length > 0 && body) {
            throw "Cannot have both PortletBody and non portlet nodes!";
        }

        if (!body) {
            body = new PortletBody({ children: () => <>{otherNodes}</> });
            portletClasses = ComponentUtils.classNames(portletClasses, {
                "no-body": otherNodes.length === 0,
            });
        }

        if (!tools) {
            tools = new PortletTools({ children: () => <></> });
        }

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const portletVM = this;
        return ComponentUtils.bindTo(
            <div
                class={portletClasses}
                style={this.props.style}
                data-bind={{ css: { collapsed: portletVM.Collapsed } }}>
                <div class="portlet-title" data-bind={{ click: portletVM.switchCollapsedMode.bind(portletVM) }}>
                    <div class="caption">{header?.renderHeader() ?? this.renderHeader()}</div>

                    {tools.renderTools(this.props, this.Collapsible)}
                    {actions?.renderActions(this.props, this.Collapsible) ?? this.renderActions()}
                </div>

                {body.renderBody(this.props, this.Collapsed)}
            </div>,
            this,
            "portletVM"
        );
    }

    renderHeader() {
        return (
            <>
                {this.props.icon && <i class={this.props.icon}></i>}
                {this.props.portletTitle}
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(Portlet);
}
