import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IResourceDataSourceModel, ResourcesDataSource } from "../../../DataSources/ResourcesDataSource";
import { Select } from "../../../Components/Select";
import { ProxyDataSource } from "../../../DataSources/ProxyDataSource";
import { SelectMultiple } from "../../../Components/SelectMultiple";
import { ResourcesAndGroupsDataSource } from "../../../DataSources/ResourcesAndGroupsDataSource";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";

const styleSheet = jss.createStyleSheet({
    responsiblesPanel: {},
});
const { classes } = styleSheet.attach();

type JobOrderResponsiblesPanelProps = {
    responsableUserId: ko.Observable<number>;
    administrativeResponsibleId: ko.Observable<number | string>;
    commercialResponsibleId: ko.Observable<number | string>;

    administrativeResponsibleName?: ko.Observable<string>;
    commercialResponsibleName?: ko.Observable<string>;

    authorizedResources: ko.ObservableArray<string>;

    forwardRef?: (component: _JobOrderResponsiblesPanel) => void;
};

export class ResponsiblesPanelUI {
    constructor(private props: JobOrderResponsiblesPanelProps) {}

    render() {
        return <JobOrderResponsiblesPanel {...this.props} />;
    }
}

export function JobOrderResponsiblesPanel(props: JobOrderResponsiblesPanelProps) {
    const C = require("./JobOrderResponsiblesPanel")._JobOrderResponsiblesPanel as typeof _JobOrderResponsiblesPanel;
    return <C {...props} />;
}

export class _JobOrderResponsiblesPanel {
    static defaultProps: Partial<JobOrderResponsiblesPanelProps> = {};

    public ResponsiblesDataSource: ResourcesDataSource = new ResourcesDataSource();
    public AdministrativeAndCommercialResponsiblesDataSource: ResourcesDataSource = new ResourcesDataSource();
    public AuthorizedResourcesDataSource: ResourcesAndGroupsDataSource = new ResourcesAndGroupsDataSource();

    public AdministrativeResponsiblesDataSource: ProxyDataSource<ResourcesDataSource, IResourceDataSourceModel>;
    public CommercialResponsiblesDataSource: ProxyDataSource<ResourcesDataSource, IResourceDataSourceModel>;

    public AdministrativeResponsibleSelectedFromResources: ko.Computed<boolean>;
    public AdministrativeResponsibleSelectionDescription: ko.Computed<string>;
    public CommercialResponsibleSelectedFromResources: ko.Computed<boolean>;
    public CommercialResponsibleSelectionDescription: ko.Computed<string>;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    constructor(private props: JobOrderResponsiblesPanelProps) {
        this.ResponsiblesDataSource.setGetHumanResources(true);
        this.ResponsiblesDataSource.setGetMaterialResources(false);
        this.ResponsiblesDataSource.setIncludeDeletedResources(false);
        this.ResponsiblesDataSource.setIncludeDisabledResources(false);

        this.AuthorizedResourcesDataSource.setIncludeGroups(true);

        this.AdministrativeAndCommercialResponsiblesDataSource.setAllowFreeText(true);
        this.AdministrativeAndCommercialResponsiblesDataSource.setGetMaterialResources(false);

        this.AdministrativeResponsiblesDataSource = new ProxyDataSource(
            this.AdministrativeAndCommercialResponsiblesDataSource
        );
        this.CommercialResponsiblesDataSource = new ProxyDataSource(
            this.AdministrativeAndCommercialResponsiblesDataSource
        );

        this.AdministrativeResponsibleSelectedFromResources = ko.computed(() => {
            return typeof this.props.administrativeResponsibleId() === "number";
        });

        this.AdministrativeResponsibleSelectionDescription = ko.computed(() => {
            return this.AdministrativeResponsibleSelectedFromResources()
                ? TextResources.JobOrder.ResponsibleProviderSelectedResourceTitle
                : TextResources.JobOrder.ResponsibleProviderPlainTextResourceTitle;
        });

        this.CommercialResponsibleSelectedFromResources = ko.computed(() => {
            return typeof this.props.commercialResponsibleId() === "number";
        });

        this.CommercialResponsibleSelectionDescription = ko.computed(() => {
            return this.CommercialResponsibleSelectedFromResources()
                ? TextResources.JobOrder.ResponsibleProviderSelectedResourceTitle
                : TextResources.JobOrder.ResponsibleProviderPlainTextResourceTitle;
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    componentDidMount() {
        this.loadAdministrativeResponsibleName();
        this.loadCommercialResponsibleName();
    }

    loadAdministrativeResponsibleName() {
        if (this.props.administrativeResponsibleName) {
            const administrativeResponsibleId = this.props.administrativeResponsibleId();
            if (administrativeResponsibleId) {
                if (typeof administrativeResponsibleId === "number") {
                    const resource = this.humanResourcesSettingsManager.getHumanResourceById(
                        administrativeResponsibleId as number
                    );
                    this.props.administrativeResponsibleName(this.humanResourcesSettingsManager.getFullName(resource));
                } else {
                    this.props.administrativeResponsibleName(administrativeResponsibleId);
                }
            } else {
                this.props.administrativeResponsibleName(null);
            }
        }
    }

    loadCommercialResponsibleName() {
        if (this.props.commercialResponsibleName) {
            const commercialResponsibleId = this.props.commercialResponsibleId();
            if (commercialResponsibleId) {
                if (typeof commercialResponsibleId === "number") {
                    const resource = this.humanResourcesSettingsManager.getHumanResourceById(
                        commercialResponsibleId as number
                    );
                    this.props.commercialResponsibleName(this.humanResourcesSettingsManager.getFullName(resource));
                } else {
                    this.props.commercialResponsibleName(commercialResponsibleId);
                }
            } else {
                this.props.commercialResponsibleName(null);
            }
        }
    }

    onAdministrativeResponsibleSelected(model: IDataSourceModel): void {
        if (!this.props.administrativeResponsibleName) return;

        if (typeof model.id === "string") this.props.administrativeResponsibleName(model.id as unknown as string);
        else this.props.administrativeResponsibleName(model?.title);
    }

    onCommercialResponsibleSelected(model: IDataSourceModel): void {
        if (!this.props.commercialResponsibleName) return;

        if (typeof model.id === "string") this.props.commercialResponsibleName(model.id as unknown as string);
        else this.props.commercialResponsibleName(model?.title);
    }

    onAdministrativeResponsibleDeselected(model: IDataSourceModel): void {
        if (this.props.administrativeResponsibleName) {
            this.props.administrativeResponsibleName(undefined);
        } else if (this.props.commercialResponsibleName) {
            this.props.commercialResponsibleName(undefined);
        }
    }

    onCommercialResponsibleDeselected(model: IDataSourceModel): void {
        if (this.props.commercialResponsibleName) {
            this.props.commercialResponsibleName(undefined);
        }
    }

    render() {
        const responsiblesPanel = this;
        const classNames = ComponentUtils.classNames(classes.responsiblesPanel, "row");

        return ComponentUtils.bindTo(
            <div className={classNames}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <Select.WithLabel
                                value={this.props.responsableUserId}
                                dataSource={this.ResponsiblesDataSource}
                                label={TextResources.JobOrder.ResponsibleLabel}
                                placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                allowClear
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label class="control-label">
                                    {TextResources.JobOrder.AdministrativeResponsibleLabel}
                                    <i
                                        class="fa"
                                        data-bind={{
                                            css: {
                                                "fa-users":
                                                    responsiblesPanel.AdministrativeResponsibleSelectedFromResources,
                                                "fa-font":
                                                    !responsiblesPanel.AdministrativeResponsibleSelectedFromResources(),
                                            },
                                            visible: !!responsiblesPanel.props.administrativeResponsibleId(),
                                            attr: {
                                                title: responsiblesPanel.AdministrativeResponsibleSelectionDescription(),
                                            },
                                        }}></i>
                                </label>
                                <Select
                                    value={this.props.administrativeResponsibleId}
                                    dataSource={this.AdministrativeResponsiblesDataSource}
                                    placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                    onSelect={this.onAdministrativeResponsibleSelected.bind(this)}
                                    onDeselect={this.onAdministrativeResponsibleDeselected.bind(this)}
                                    allowClear
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label class="control-label">
                                    {TextResources.JobOrder.CommercialResponsibleLabel}
                                    <i
                                        class="fa"
                                        data-bind={{
                                            css: {
                                                "fa-users":
                                                    responsiblesPanel.CommercialResponsibleSelectedFromResources,
                                                "fa-font":
                                                    !responsiblesPanel.CommercialResponsibleSelectedFromResources(),
                                            },
                                            visible: !!responsiblesPanel.props.commercialResponsibleId(),
                                            attr: {
                                                title: responsiblesPanel.CommercialResponsibleSelectionDescription(),
                                            },
                                        }}></i>
                                </label>
                                <Select
                                    value={this.props.commercialResponsibleId}
                                    dataSource={this.CommercialResponsiblesDataSource}
                                    placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                    onSelect={this.onCommercialResponsibleSelected.bind(this)}
                                    onDeselect={this.onCommercialResponsibleDeselected.bind(this)}
                                    allowClear
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <SelectMultiple.WithLabel
                                label={TextResources.JobOrder.AuthorizedResourcesLabel}
                                value={this.props.authorizedResources}
                                dataSource={this.AuthorizedResourcesDataSource}
                                placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                allowClear
                            />
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "responsiblesPanel"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderResponsiblesPanel);
}
