import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRemindObjReferenceUi, IReminderService } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { Deferred } from "../../../../Core/Deferred";

export class WorkflowRefUiForRemind implements IRemindObjReferenceUi{
    private remindService : IReminderService;
    private jobOrderService : IJobOrderService;
    private todoListService : ITodoListService;

    public entityType: string = ProlifeSdk.WorkflowEntityTypeCode;
    public templateUrl : string = "reminder/templates/references";
    public templateName : string = "workflow";

    public jobOrder : IDropDownList;
    public workflow : IDropDownList;

    constructor(private serviceLocator) {
        this.remindService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.jobOrder = this.jobOrderService.getJobOrdersDropDown();
        this.workflow = this.todoListService.getWorkflowsDropDown(() => { return this.jobOrder.selectedId();})
        this.remindService.registerObjReferenceUi(this);

        this.jobOrder.selectedId.subscribe(() => { this.workflow.selectedId(null); });
    }

    public initialize(key: IEntityRefKey) : Promise<void>{
        var def = new Deferred<void>();

        this.jobOrder.selectedId(null);

        if(!key.EntityKeyId)
            return def.resolve().promise();

        this.todoListService.GetWorkflow(key.EntityKeyId).then((workflow : ITodoListWorkflow) => {
            this.jobOrder.selectedId(workflow.JobOrderId);
            this.workflow.selectedId(workflow.Id);
            def.resolve();
        });

        return def.promise();
    }

    public getSelectedEntityKey(): IEntityRefKey{
        return {
            EntityKeyType : this.entityType,
            EntityKeyId : this.workflow.selectedId()
        }
    }

    public getSelectedEntityPathDescription(): Promise<string> {
        var def = new Deferred<string>();

        if(!this.workflow.selectedId())
            return def.resolve(null).promise();

        this.jobOrder.getSelected(null, (jobOrder) => {
            this.workflow.getSelected(null, (workflow) => {
                def.resolve((jobOrder.text || "N/A") + " > " + (workflow.text || "N/A"));
            });
        });

        return def.promise();
    }

}