import { Select, SelectProps } from "../../../../Components/Select";
import * as React from "@abstraqt-dev/jsxknockout";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";

export class WorkTimeCategorySelect {
    constructor(private props: SelectProps<number, IWorkTimeCategory>) {

    }

    render() {
        return <Select {...this.props}  />
    }
}



