import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../Core/utils/ComponentUtils";

export default function TsxForEach<T = any>(props: { data?: ko.ObservableArray<T> | ko.Computed<T[]>; children: (item : T) => React.ReactElement; as?: string; }) : React.ReactElement {
    return ComponentUtils.setBinding(<ko-bind data-bind={{ noop: 0 }}></ko-bind>, { tsxForEach: { data: props.data, template: props.children, as: props.as }});
}

class ForEachBindingHandler implements ko.BindingHandler {
    init(element: HTMLElement, valueAccessor: () => { data: ko.ObservableArray; template: ((item : any) => React.ReactElement)[]; as: string; }, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) : ko.BindingHandlerControlsDescendant {
        let options = valueAccessor();
        let [template] = options.template;

        let cachesNodes : Node[][] = [];
        
        let renderItems = function(changes: ko.utils.ArrayChanges) {
            let oldIndexes = [];
            for(let index = 0; index < cachesNodes.length; index++)
                oldIndexes.push(index);

            for(let change of changes) {
                if(change.status === "added") {
                    let renderedItem = template(change.value) as Node;
                    let newNode = ComponentUtils.bindTo(renderedItem, change.value, options.as) as Node;

                    let newNodes: Node[];
                    if(newNode instanceof DocumentFragment)
                        newNodes = [...ko.virtualElements.childNodes(newNode)];
                    else
                        newNodes = [newNode];

                    let lastNode = cachesNodes[change.index - 1]?.lastOrDefault();
                    cachesNodes.splice(change.index, 0, newNodes);
                    for(let i = change.index; i < oldIndexes.length; i++)
                        oldIndexes[i] = oldIndexes[i] + 1;

                    
                    for(let n of newNodes) {
                        ko.virtualElements.insertAfter(element, n, lastNode);
                        lastNode = n;
                    }

                    for(let n of newNodes) {
                        ComponentUtils.applyBindingsToNodeAndChildren(n, bindingContext);
                    }

                    for(const n of newNodes)
                        ko.ignoreDependencies(() => ComponentUtils.notifyDidMount(n));

                } else if(change.status === "deleted") {
                    let nodes = cachesNodes[oldIndexes[change.index]];
                    
                    for(let n of nodes) {
                        ko.ignoreDependencies(() => ComponentUtils.notifyWillUnmount(n));

                        if (n.nodeType === 8) {
                            let nodesToRemove = [...ko.virtualElements.childNodes(n)];
                            for (let cn of nodesToRemove)
                                ko.removeNode(cn);
                        }

                        ko.removeNode(n);
                    }
                    
                    cachesNodes.splice(oldIndexes[change.index], 1);
                    for(let i = change.index; i < oldIndexes.length; i++)
                        oldIndexes[i] = oldIndexes[i] - 1;
                }
            }
        }
        
        let subscription = options.data.subscribe((changes) => {
            renderItems(changes);
        }, null, "arrayChange").disposeWhenNodeIsRemoved(element);

        renderItems(ko.utils.compareArrays([], options.data()));

        return { controlsDescendantBindings: true };
    }
}

ko.bindingHandlers["tsxForEach"] = new ForEachBindingHandler();
ko.virtualElements.allowedBindings["tsxForEach"] = true;