import * as ko from "knockout";
import { INavigationMenuComponentItem, INavigationMenuComponentModel } from "./INavigationMenuComponent";
import { IDataSource, IDataSourceModel } from "../../DataSources/IDataSource";
import { NavigationMenuComponent } from "../NavigationMenuComponent";

export class NavigationMenuComponentItem implements INavigationMenuComponentItem {
    public isGroup: boolean;

    public Selected: ko.Observable<boolean> = ko.observable();
    public ChildrenSelected : ko.Observable<boolean> = ko.observable();
    public AllChildrenSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public model: INavigationMenuComponentModel, protected navigator: NavigationMenuComponent, protected dataSource : IDataSource) {
        this.isGroup = model.isGroup;
    }
    
    public click(bypassSelectLeafsOnlyCheck: boolean = false): void {
        if (this.navigator.isMultipleSelection()) {
            if (this.Selected() && (this.model.isLeaf || (this.model.canSelectEvenIfNotLeaf && !bypassSelectLeafsOnlyCheck)))
                this.navigator.onItemDeselected(this.model);
            else
                this.navigator.onItemSelected(this.model, bypassSelectLeafsOnlyCheck);
        } else {
            this.navigator.onItemSelected(this.model);
        }
    }

    public secondaryActionClick(): void {
        if (!this.model.secondaryAction)
            return;

        this.model.secondaryAction.action(this.model);
    }

    public async manageAllChildrenSelection(): Promise<void> {
        if(!this.dataSource) return;
        
        if (!this.ChildrenSelected()) {
            try {
                let children = await this.dataSource.getData(this.model, null, 0, 100000);
                for (let child of children)
                    this.navigator.onItemSelected(child);

                this.ChildrenSelected(true);
                this.AllChildrenSelected(true);
            } catch(e) {
    
            }
        } else {
            let selectedChildren = this.navigator.getActualSelection().filter(s => this.dataSource.areEqual(s.parent, this.model));
            for (let child of selectedChildren)
                this.navigator.onItemDeselected(child);
            this.ChildrenSelected(false);
            this.AllChildrenSelected(false);
        }
    }
}