import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "./ImportDocumentDataWizard";
import { VatTypesDataSource, IVatTypesDataSourceModel } from "../../../../DataSources/VatTypesDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ICustomersService, IGetLettersOfAttemptsRequest, ILetterOfAttempt } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IIvaModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

export class ImportDocumentDataWizardIvaSelectionStep extends DocumentDataWizardStep implements IDataSourceListener
{
    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModes : IIvaModes;

    private requireLetterOfAttemptValidVat: boolean = false;
    private letterOfAttemptValidVatNotFound: boolean = true;

    DefaultFKVatCode : ko.Observable<number> = ko.observable();
    DefaultVatCode : ko.Observable<string> = ko.observable();
    RowsToImport : ko.ObservableArray<IDocumentDataWizardRow> = ko.observableArray();

    VatTypesDataSource: VatTypesDataSource = new VatTypesDataSource();

    SelectAll : ko.Computed<boolean>;
    
    constructor()
    {
        super();
        this.Title(TextResources.Invoices.VATSelection);
        this.IsVisible(false);
        
        this.SelectAll = ko.computed({
            read: () => {
                var rows = this.RowsToImport();
                return rows.filter(r => !r.IsSelected()).length == 0;
            },
            write: (selected : boolean) => {
                this.RowsToImport().forEach(r => r.IsSelected(selected));
            }
        });

        this.SelectAll(false);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(sender == this.VatTypesDataSource) {
            const vatCode = model as IVatTypesDataSourceModel;
            this.DefaultVatCode(vatCode?.model?.CodiceIVA);
        }
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    setVatType() {
        this.RowsToImport()
            .filter(r => r.IsSelected())
            .forEach(r => {
                r.Row.FKVatCode(this.DefaultFKVatCode());
                r.Row.VatCode(this.DefaultVatCode());
            });
        this.SelectAll(false);
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.RowsToImport(previousStepRows);

        const fkVatCode = this.initializationInfo.DefaultFKVatCode;
        const vatCode = this.initializationInfo.DefaultVatCode;

        if (!this.initializationInfo.CustomerId) {
            this.setVatOnRows(fkVatCode, vatCode);
            this.SelectAll(true);
            return;
        }

        const request: IGetLettersOfAttemptsRequest = {
            CustomerId: this.initializationInfo.CustomerId,
            ReferenceDate: this.initializationInfo.DocumentDate,
            OnlyValidAtReferenceDate: true,
            ShowClosed: false,
            Skip: 0,
            Count: 1
        };

        this.customersService.GetLettersOfAttempts(request)
            .then((lettersOfAttempt: ILetterOfAttempt[]) => {
                if (lettersOfAttempt.length === 0) {
                    this.setVatOnRows(fkVatCode, vatCode);
                } else {
                    this.requireLetterOfAttemptValidVat = true;

                    const vat = this.ivaModes.getIvaModes().firstOrDefault(i => i.RequireLetterOfAttempt);
                    if (vat) {
                        this.letterOfAttemptValidVatNotFound = false;
                        this.VatTypesDataSource.selectByIds(vat.IdTipoIVA).then(() => this.setVatType());
                    } else {
                        this.setVatOnRows(fkVatCode, vatCode);
                    }
                }

                this.SelectAll(true);
            })
            .catch(() => {
                this.infoToastService.Error(TextResources.Invoices.GetLettersOfAttemptsError);
            });
    }

    private setVatOnRows(fkVatCode: number, vatCode: string): void {
        this.RowsToImport()
            .filter((r) => !r.Row.FKVatCode() || r.Row.FKVatCode() < 0)
            .forEach(r => {
                r.Row.FKVatCode(fkVatCode);
                r.Row.VatCode(vatCode);
            });
    }

    GetWarnings(): string[] {
        if (this.requireLetterOfAttemptValidVat) {
            if (this.letterOfAttemptValidVatNotFound) {
                return [TextResources.Invoices.LetterOfAttemptValidVatNotFound];
            }

            const invalidRow = this.RowsToImport().firstOrDefault((r) => {
                const vatId = r.Row.FKVatCode();
                const vatCode = this.ivaModes.getIvaModes().firstOrDefault(i => i.IdTipoIVA === vatId);
                return !vatCode?.RequireLetterOfAttempt;
            });

            if (invalidRow)
                return [String.format(TextResources.Invoices.LetterOfAttemptVatCodeRequired, invalidRow.Row.VatCode())];
        }

        return [];
    }

    CanGoNext(): boolean {
        if (this.RowsToImport().filter(r => !r.Row.FKVatCode()).length > 0)
        {
            this.toastService.Warning(TextResources.Invoices.NoVATWarning);
            return false;
        }

        return true;
    }

    OnNext(): IDocumentDataWizardRow[] {
        return this.RowsToImport();
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        await super.Initialize(initializationInfo);
        this.IsSelected(initializationInfo.CanHaveVat);
        this.VatTypesDataSource.selectByIds(this.initializationInfo.DefaultFKVatCode);
    }

    render() {
        let step : ImportDocumentDataWizardIvaSelectionStep;
        let rowToImport : IDocumentDataWizardRow;
        let row : ProcessedRow;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 20px;">
                <div class="row" style="margin-bottom : 20px">
                    <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardVatCode}</label>
                    <div class="col-md-10">
                        <select2
                            simple={true}
                            value={() => "DefaultFKVatCode"}
                            dataSource={() => "VatTypesDataSource"}
                            listener={() => "$data"}></select2>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn btn-primary" data-bind={{ click: step.setVatType }}>{TextResources.Invoices.DocumentWizardSetVatCode}</button>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        
                        <table class="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th style="text-align: center">
                                        <div class="checker">
                                            <span data-bind={{ css : { checked : step.SelectAll } }}>
                                                <input type="checkbox" data-bind={{ checked : step.SelectAll }} />
                                            </span>
                                        </div>
                                    </th>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                    <th style="text-align : center">{TextResources.Invoices.DocumentWizardVAT}</th>
                                    <th style="text-align : right">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if : step.RowsToImport().length == 0 }}>
                                    <tr>
                                        <td colSpan={6} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                    </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.RowsToImport, as: 'rowToImport' }}}>
                                    <ko-bind data-bind={{ with : rowToImport.Row, as: 'row' }}>
                                        <tr data-bind={{ css : { 'info': !!row.FKVatCode() } }}>
                                            <td style="text-align : center">
                                                <div class="checker">
                                                    <span data-bind={{ css : { checked : rowToImport.IsSelected } }}>
                                                        <input type="checkbox" data-bind={{ checked : rowToImport.IsSelected }} />
                                                    </span>
                                                </div>
                                            </td>
                                            <td data-bind={{ text: row.Description }}></td>
                                            <td style="text-align : right" data-bind={{ numberText: row.Amount }}></td>
                                            <td style="text-align : right" data-bind={{ moneyText: row.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                            <td style="text-align : center" data-bind={{ text: row.VatCode }}></td>
                                            <td style="text-align : right" data-bind={{ moneyText: row.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency }}></td>
                                        </tr>
                                    </ko-bind>
                                </ko-bind>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        , this, "step");
    }
}