import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { IDocumentsProvider } from "../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { findService } from "../../Core/DependencyInjection";
import { IDocumentsService } from "../DocumentsService";
import jss from "jss";

const { classes } = jss.createStyleSheet({
    createNewDocumentButton: {
        '& div.dropdown-menu': {
            '& > ul': {
                padding: 0,
                margin: 0,

                '& li': {
                    listStyle: 'none',

                    '&.group': {

                        '& > a': {
                            cursor: 'default',
                            fontWeight: 'bold',
                        },

                        '& > a:hover': {
                            backgroundColor: 'transparent',
                        }
                    }
                }
            }
        }
    }
}).attach();

type Action = {
    name: string;
    icon: string;
    isGroup: boolean;
    registerId?: number;
    documentType?: string;
}

function getActions() {
    let documentsService = findService<IDocumentsService>(nameof<IDocumentsService>());
    var providers: IDocumentsProvider[] = documentsService.getRegisteredDocumentProviders();

    providers = providers.sort((provA: IDocumentsProvider, provB: IDocumentsProvider) => {
        const provAGroupLabel = (provA.GetDocumentGroupLabel() || "").trim().toLowerCase();
        const provBGroupLabel = (provB.GetDocumentGroupLabel() || "").trim().toLowerCase();

        if (provAGroupLabel < provBGroupLabel)
            return -1;

        if (provAGroupLabel > provBGroupLabel)
            return 1;

        if (provA.GetProviderPositionForMenu() < provB.GetProviderPositionForMenu())
            return -1;

        if (provA.GetProviderPositionForMenu() > provB.GetProviderPositionForMenu())
            return 1;

        return 0;
    });
    
    var lastGroup: string = "";
    var contextActions: Action[] = [];
    providers.forEach((p: IDocumentsProvider) => {
        if (contextActions.length == 0 || lastGroup.toLowerCase() != p.GetDocumentGroupLabel().trim().toLowerCase()) {
            var enabledProviders: number = providers.filter((ep: IDocumentsProvider) => (ep.GetDocumentGroupLabel() || "").trim().toLowerCase() == (p.GetDocumentGroupLabel() || "").trim().toLowerCase() && ep.CanCreateNew()).length;
            if (enabledProviders > 0) {
                lastGroup = (p.GetDocumentGroupLabel() || "").trim();
                contextActions.push({
                    name: lastGroup,
                    icon: "",
                    isGroup: true,
                });
            }
        }

        if(p.CanCreateNew()) {
            let documentTypes = p.GetDocumentTypes();
            let firstDocumentType = documentTypes[0].DocumentType;
            
            if(firstDocumentType === "DOC" || firstDocumentType === "DOA" || firstDocumentType === "NDC") {
                contextActions.push({
                    name: "📒 " + p.Name(),
                    icon: "",
                    isGroup: true
                });

                for(let documentType of documentTypes) {
                    contextActions.push({
                        name: "📝 " + documentType.NewLabel,
                        icon: "margin-left-20",
                        isGroup: false,
                        registerId: p.Id,
                        documentType: documentType.DocumentType,
                    });
                }
            }
            else {
                contextActions.push({
                    name: "📝 " + p.Name(),
                    icon: "margin-left-20",
                    isGroup: false,
                    registerId: p.Id,
                    documentType: documentTypes[0].DocumentType,
                });
            }
        }
    });

    return contextActions;
}

export function CreateNewDocumentButton(props: { onNewDocument: (registerId : number, documentType: string) => void}) {
    let actions = getActions();

    let onClick = (e: Event, action : Action) => {
        e.preventDefault();

        if(action.isGroup)
            return;

        props.onNewDocument(action.registerId, action.documentType);
    };

    return (
        <div className={"btn-group " + classes.createNewDocumentButton}>
            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
            <i className="fa fa-plus"></i>&nbsp;Nuovo&nbsp;<i className="fa fa-angle-down"></i>
            </button>
            <div className="dropdown-menu" role="menu">
                <ul data-bind={{ slimScroll: '300px' }}>
                    {actions.map(a => 
                        <li onClick={(e) => onClick(e, a)} className={a.isGroup ? "group" : ""}>
                            <a href="javascript:;">
                                {a.icon && <><i class={a.icon}></i>&nbsp;</>}
                                {a.name}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}