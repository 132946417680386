import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { List } from "../../../Components/ListComponent";
import { Portlet } from "../../../Components/Portlet";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { Feature } from "./Feature";

type FunctionPointComputationFeaturesListProps = {
    selectedFeature?: ko.Observable<Feature>;
    onFeatureSelected: (f: Feature)=> void;
    features: ko.ObservableArray<Feature>;
    onNew: ()=> void;
}

export function FunctionPointComputationFeaturesList(props: FunctionPointComputationFeaturesListProps) {
    const C = require("./FunctionPointComputationFeaturesList")._FunctionPointComputationFeaturesList as typeof _FunctionPointComputationFeaturesList;
    return <C {...props} />;
}

export class _FunctionPointComputationFeaturesList implements IDataSourceListener{
    static defaultProps: Partial<FunctionPointComputationFeaturesListProps> = {
    }

    featuresList: ko.Observable<List<number, Feature>> = ko.observable();

    constructor(private props : FunctionPointComputationFeaturesListProps) {
        
    }
    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        this.props.onFeatureSelected(model?.model)
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }
    
    render() {
        const dataSource = Table.defaultDataSource(this.props.features, (f)=> ({ id: f.Id, title: f.featureId, subTitle: f.featureName }));
        return <Portlet collapsible={false}>
                    <Portlet.Header>
                        <Portlet.Header.Default title="Funzionalità"/>
                    </Portlet.Header>
                    <Portlet.Actions>
                        <button className={"btn btn-circle btn-icon-only btn-default"} onClick={this.props.onNew}>
                            <i className={"fa fa-plus"}/>
                        </button>
                    </Portlet.Actions>
                    <Portlet.Body>
                        {()=> <List dataSource={dataSource} listener={this}/>}
                    </Portlet.Body>                    
                </Portlet>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(FunctionPointComputationFeaturesList);
}