import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { EventsGroup } from "./EventsGroup";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IJobOrderForTaskBoard } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";
import { ILogFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";

export class EventsViewModel
{
	private infoToastService : IInfoToastService;
	private blogService : IBlogService;
	private desktopService : IDesktopService;
    private dialogService : IDialogsService;
    private logService : ILogService;
	private filters : ILogFilter[];

    public eventsOrderedByDate : ko.Computed<EventsGroup[]>;
    private eventsGroups : ko.ObservableArray<EventsGroup> = ko.observableArray([]);
    private numberOfToSkip : number = 0;
    public isSelectable : ko.Observable<boolean> = ko.observable(false);
    private Loading : ko.Observable<boolean> = ko.observable(false);
    private endOfListReached : boolean = false;

	constructor(private serviceLocator : IServiceLocator, private contextEventsObserver : IContextEventsObserver,
        private JobOrdersObservableField : ko.ObservableArray<IJobOrderForTaskBoard>)
    {
		this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
		this.blogService = <IBlogService> this.serviceLocator.findService(ProlifeSdk.BlogServiceType);
		this.blogService.setContextEventsObserver(contextEventsObserver);
        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.logService = <ILogService> serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.desktopService = <IDesktopService> serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        this.eventsOrderedByDate = ko.computed(function()
        {
            var array = this.eventsGroups();
            array.sort(function(a, b){
                return b.numberDay - a.numberDay;
            });

            return array;

        }.bind(this));
	}

    public setLockStatusForEvents(locked : boolean)
    {
        var allSelected = this.getAllSelectedEvents();

        if(allSelected.length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.NoEventSelected);
            return;
        }

        this.dialogService.Confirm(ProlifeSdk.TextResources.Blog.LockMsg, ProlifeSdk.TextResources.Blog.LockMsgCancel, ProlifeSdk.TextResources.Blog.LockMsgConfirm, (result : boolean) => {
            if (!result)
                return;

            var selectedIds = [];
            allSelected.forEach((e : any) => {

                e.Locked(locked);
                e.originalValue.Locked = locked;
                selectedIds.push(e.IdEvent());
            });

            this.logService.changeLockStatus(selectedIds, locked);
        });
    }

    private getAllSelectedEvents() : BlogEvent[]
    {
        var allSelected = [];
        this.eventsGroups().forEach(g => {
            g.events().forEach(e => {
                if(e.isSelected())
                    allSelected.push(e);
            });
        });
        return allSelected;
    }

    public GetAllEvents() : BlogEvent[]
    {
        var allEvents = [];
        this.eventsGroups().forEach(g => {
            g.events().forEach(e => {
                allEvents.push(e);
            });
        });
        return allEvents;
    }

    public switchSelectability()
    {
        this.isSelectable(!this.isSelectable());
        this.eventsGroups().forEach(g => {
            g.events().forEach(e => {
                e.switchSelectability();
            });
        });
    }

	public LoadNextEvents()
    {
		this.RefreshEvents(this.numberOfToSkip, 30);
	}

	public LoadEvents(filters : ILogFilter[])
    {
		this.filters = filters;

        this.filters.push({
            HintSearch : null,
            From : null,
            To : null,
            UserId : null,
            Tags : null,
            FkParentId : null,
            ExcludeCreatedBySystem : null,
            EventTypeIds : null,
            NotHasParent : true
        });

        this.eventsGroups([]);
        this.numberOfToSkip = 0;
        this.endOfListReached = false;

		this.RefreshEvents();
	}

	private RefreshEvents(skip : number = 0, count : number = 30): void
    {
        if (this.Loading() || this.endOfListReached)
            return;

        this.Loading(true);
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Blog.LoadingEvents);
        var jobOrdersIds : number[] = this.JobOrdersObservableField().map((j : IJobOrderForTaskBoard) => { return j.Id; });
		this.blogService.detailedSearchOnJobOrders(this.filters, skip, count, jobOrdersIds)
			.then(this.PushItems.bind(this))
			.catch(this.LoadEventsFailed.bind(this))
            .finally(() => {
                this.desktopService.UnblockPageUI();
                this.Loading(false);
            });
	}

	private LoadEventsFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any)
    {
		this.infoToastService.Error(jqXHR.statusText);
	}

	private PushItems(events : BlogEvent[])
    {
        this.endOfListReached = events.length == 0;
        this.numberOfToSkip += events.length;

		events.map((item : BlogEvent) => {

            if(item.FkParentId() == null && item.ReferenceDate() != null)
            {
                item.isSelectable(this.isSelectable());

                //Nel caso di commessa multipla setto il nome commessa sugli eventi
                if(this.JobOrdersObservableField().length > 1)
                {
                    var jobOrderId : number = item.JobOrder();
                    var matches = this.JobOrdersObservableField().filter((j : IJobOrderForTaskBoard) => { return j.Id == jobOrderId; });
                    item.SetJobOrderName(matches.length > 0 ? matches[0].Name : null);
                }

                var existsGroupFor : boolean = false;
                this.eventsGroups().forEach((g) => {
                    existsGroupFor = existsGroupFor || g.addIfMatch(item);
                });

                if(!existsGroupFor)
                    this.eventsGroups.push(new EventsGroup(item.ReferenceDate(), [item]));
            }
		});
	}
}