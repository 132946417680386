import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IDDTPorto, IDDTPortiSettingsManager } from "../ProlifeSdk/interfaces/invoice/settings/IDDTPortiSettingsManager";

export interface ICarriagesDataSourceModel extends IDataSourceModel<number, IDDTPorto> {

}

export class CarriagesDataSource extends BaseDataSource<ICarriagesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.DDTPorti)
    private ddtPorts : IDDTPortiSettingsManager;

    getTitle(currentModel: ICarriagesDataSourceModel): string {
        return TextResources.Invoices.PortTypes;
    }

    async getData(currentModel: ICarriagesDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICarriagesDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let appearances = this.ddtPorts.getDDTPorti();
        
        return appearances
            .filter(d => !d.Deleted && splitFilters.filter(w => d.Description.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: ICarriagesDataSourceModel, ids: (string | number)[]): Promise<ICarriagesDataSourceModel[]> {
        return this.ddtPorts.getDDTPorti()
            .filter(p => ids.indexOf(p.Id) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IDDTPorto): ICarriagesDataSourceModel {
        return {
            id: model.Id,
            title: model.Description,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}