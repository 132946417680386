import * as React from "@abstraqt-dev/jsxknockout"
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { GDPREventTypesDataSource } from "../../../DataSources/GDPREventTypesDataSource";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { IGDPREventType } from "../../GDPRService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { GDPRLogicEventTypes, IGDPRLogicEventType, IGDPREventTypesSettingsManager } from "../GDPREventTypesSettingsManager";
import ko = require("knockout");
import { ArrayDataSource } from "../../../DataSources/ArrayDataSource";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import jss from "jss";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";

const { classes } = jss.createStyleSheet({
    gdprTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class GDPREventType {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    public validator: IValidator<GDPREventType>;

    Id: number;
    FkLogicEventType: ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    IsInUse: ko.Computed<boolean>;
    Deleted: ko.Observable<boolean> = ko.observable(false);

    constructor(eventType: IGDPREventType){
        this.Id = eventType.Id;
        this.FkLogicEventType(eventType.FkLogicEventType);
        this.Description(eventType.Description);
        this.IsInUse = ko.computed(() => eventType.IsInUse > 0);
        this.Deleted(eventType.Deleted);

        this.validator = this.validationService.createValidator<GDPREventType>()
        .isNotNullOrUndefinedOrWhiteSpace((d) => d.Description(), TextResources.GDPR.ErrorNoDescription)
        .isNotNullUndefinedOrZero((d) => d.FkLogicEventType(), TextResources.GDPR.ErrorNoLogicType);
    }
}

export interface IGDPREventTypesSettingsComponentProps {
    EventTypesSettingsManager: IGDPREventTypesSettingsManager;
}

export class GDPREventTypesSettingsComponent {
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    GDPREventTypes: ko.ObservableArray<GDPREventType> = ko.observableArray();

    LogicEventTypes: ko.ObservableArray<IGDPRLogicEventType> = ko.observableArray(GDPRLogicEventTypes);

    constructor(private props: IGDPREventTypesSettingsComponentProps){
        this.loadData();
    }

    loadData(){
        this.GDPREventTypes(this.props.EventTypesSettingsManager.getEventTypes(null, false).map(x => new GDPREventType(x)));
    }

    addNewEventType(){
        this.GDPREventTypes.push(new GDPREventType({
            Id: this.idGeneratorService.getNextId(),
            Deleted: false,
            FkLogicEventType: null,
            Description: null,
            IsInUse: 0
        }));
    }

    async deleteEventType(item: GDPREventType){
        let result = await this.dialogsService.ConfirmAsync(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem);
        if (result) {
            if (item.Id < 0)
                this.GDPREventTypes.remove(item);
            else
                item.Deleted(true);
        }
    }

    restoreEventType(item: GDPREventType){
        item.Deleted(false);
    }

    async saveAll(){
        let isValid = true;
        for (let eventType of this.GDPREventTypes()){
            if (!eventType.validator.validateAndShowInfoToast(eventType)){
                isValid = false;
                break;
            }
        }

        if (Object.keys(this.GDPREventTypes().filter(et => !et.Deleted()).groupBy(et => et.FkLogicEventType())).length < 4) {
            this.infoToastService.Error(TextResources.GDPR.ErrorLessThanFourLogicTypes);
            isValid = false;
        }

        if (isValid) {
            try {
                await this.props.EventTypesSettingsManager.insertOrUpdateEventTypes(this.GDPREventTypes().map(x => ({ 
                    Id: x.Id,
                    FkLogicEventType: x.FkLogicEventType(),
                    Description: x.Description(),
                    Deleted : x.Deleted()
                })));

                this.infoToastService.Success(TextResources.GDPR.AddExistingSuccess);
                this.loadData();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    createEventTypeModel(eventType : GDPREventType) {
        return {
            id: eventType.Id,
            title: eventType.Description(),
            isLeaf: true,
            isGroup: false,
            model: eventType
        }
    }

    render() {
        let vm = this;
        let eventTypes: IDataSourceModel<number, GDPREventType>;

        return ComponentUtils.bindTo(
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-10">
                        <h1>{TextResources.GDPR.EventTypes}</h1>
                    </div>
                    <div className="col-md-2 text-right" style="margin-top: 20px">
                        <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}}>
                            <i className="fa fa-save"></i> {TextResources.GDPR.Save}
                        </button>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <Table rowAs="eventTypes" dataSource={{array: vm.GDPREventTypes, factory: vm.createEventTypeModel }} compact={true} editable={true} scrollable={true} className={classes.gdprTable}>
                        <Column title={TextResources.GDPR.Notes}>
                            <span data-bind={{if: eventTypes.model.IsInUse}}>
                                <i className="fa fa-exclamation-triangle" title={TextResources.GDPR.EventInUseAlert}></i>
                            </span>
                            <span data-bind={{if: eventTypes.model.Deleted}}>
                                <i className="fa fa-trash-o" title={TextResources.GDPR.EventTypeIsDeletedAlert}></i>
                            </span>
                        </Column>
                        <Column title={TextResources.GDPR.Description} cssClasses="noMargin">
                            <text-input value={() => "eventTypes.model.Description"} readOnly={() => "eventTypes.model.Deleted"} placeholder={TextResources.GDPR.DescriptionPlaceholder}/>
                        </Column>
                        <Column title={TextResources.GDPR.LogicEventType} cssClasses="noMargin">
                            <select className="form-control" data-bind={{ disable: (eventTypes.model.IsInUse() || eventTypes.model.Deleted()), options: vm.LogicEventTypes, optionsValue: 'Id', optionsText: 'Name', value: eventTypes.model.FkLogicEventType }}></select>
                        </Column>
                        <Column title={TextResources.GDPR.Actions}>
                            <ColumnHeader>
                                <button className="btn btn-primary btn-xs" data-bind={{click: vm.addNewEventType.bind(vm)}}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <div data-bind={{ifnot: eventTypes.model.Deleted}}>
                                    <button className="btn btn-danger btn-xs" data-bind={{click: vm.deleteEventType.bind(vm, eventTypes.model)}}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                                <div data-bind={{if: eventTypes.model.Deleted}}>
                                    <button className="btn btn-success btn-xs" data-bind={{click: vm.restoreEventType.bind(vm, eventTypes.model)}}>
                                        <i className="fa fa-recycle"></i>
                                    </button>
                                </div>
                            </ColumnBody>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>, this, "vm");
    }
}