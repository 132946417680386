import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { ReportEditorProps } from "./ReportEditor";
import { ColorInput } from "../../ColorInput";
import { EditorFor } from "./ReportEditorProvider";
import { ReportComponentWithFont } from "../Components/ReportComponentWithFont";
import { TextInput } from "../../TextInput";
import { NumberInput } from "../../NumberInput";
import { ButtonToolbar } from "../../ButtonToolbar";

type ReportFontEditorProps = {
    component: ReportComponentWithFont;
} & ReportEditorProps;

export function ReportFontEditor(props: ReportFontEditorProps) {
    const C = require("./ReportFontEditor")._ReportFontEditor as typeof _ReportFontEditor;
    return <C {...props} />;
}

@EditorFor("Font")
class _ReportFontEditor {
    static defaultProps: Partial<ReportFontEditorProps> = {};

    constructor(private props: ReportFontEditorProps) {}

    render() {
        const { component } = this.props;

        return (
            <div class="editor-group">
                <span class="editor-group-title">Carattere</span>
                <div className="editor-group-body">
                    <div className="row">
                        <div className="col-md-4">
                            <ColorInput size="xs" label="Colore" allowAlpha value={component.fontColor} />
                        </div>
                        <div className="col-md-4">
                            <ButtonToolbar
                                label="Stile"
                                options={[
                                    {
                                        icon: "fa fa-bold",
                                        active: ko.computed(() => component.fontWeight() === "bold"),
                                        onClick: () =>
                                            component.fontWeight() === "bold"
                                                ? component.fontWeight("normal")
                                                : component.fontWeight("bold"),
                                    },
                                    {
                                        icon: "fa fa-italic",
                                        active: ko.computed(() => component.fontStyle() === "italic"),
                                        onClick: () =>
                                            component.fontStyle() === "italic"
                                                ? component.fontStyle("normal")
                                                : component.fontStyle("italic"),
                                    },
                                ]}
                                itemStyle={{ paddingLeft: "9px", paddingRight: "9px", paddingTop: "3px" }}
                            />
                        </div>
                        <div className="col-md-4">
                            <NumberInput selectOnFocus label="Dimensione" suffix="pt" value={component.fontSize} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextInput
                                selectOnFocus
                                label="Carattere"
                                placeholder="Carattere predefinito"
                                value={component.fontFamily}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(ReportFontEditor);
}
