import * as ko from "knockout";
import { LazyImport, Service } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IWorkflowOutcomeType {
    Id?: number;
    Description?: string;
    LogicalStatus : number;
    Deleted : boolean;
    Position : number;
    Icon?: string;
    Background?: string;
    Foreground?: string;
    PreventArticlesUnload : boolean;
    PreventArticlesLoad : boolean;
    PreventWorkedHoursCreationOrChange : boolean;
    PreventPurchasesCreationOrChange : boolean;
    PreventTasksEditing : boolean;
    MoveWorkflowToState?: number;
}

export interface IWorkflowOutcome {
    Id : number;
    Description?: string;
    LogicalStatus : number;
    Deleted : boolean;
    Position : number;
    Icon : string;
    Background : string;
    Foreground : string;
    PreventArticlesUnload : boolean;
    PreventArticlesLoad : boolean;
    PreventWorkedHoursCreationOrChange : boolean;
    PreventPurchasesCreationOrChange : boolean;
    PreventTasksEditing : boolean;
    MoveWorkflowToState?: number;
}

export interface IWorkflowOutcomesService extends IService {
    InsertOrUpdateWorkflowOutcomes(outcomes : IWorkflowOutcomeType[] | null) : Promise<IWorkflowOutcome[]>;
    DeleteWorkflowOutcomes(ids : number[] | null) : Promise<void>;
    GetWorkflowOutcomesByIds(ids : number[] | null) : Promise<IWorkflowOutcome[]>;
    GetWorkflowOutcomes() : Promise<IWorkflowOutcome[]>;
    MoveWorkflowOutcome(outcomeId: number | null, position: number | null): Promise<void>;
}

@Service(nameof<IWorkflowOutcomesService>())
export class WorkflowOutcomesService implements IWorkflowOutcomesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    InsertOrUpdateWorkflowOutcomes(outcomes : IWorkflowOutcomeType[] | null) : Promise<IWorkflowOutcome[]> {
        let result = this.ajaxService.Post<IWorkflowOutcome[]>("Todolist-api/WorkflowOutcomes", "InsertOrUpdateWorkflowOutcomes", {
            background: true,
            methodData: {
                outcomes: outcomes,
            }
        });



        return result;
    }

    DeleteWorkflowOutcomes(ids : number[] | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Todolist-api/WorkflowOutcomes", "DeleteWorkflowOutcomes", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetWorkflowOutcomesByIds(ids : number[] | null) : Promise<IWorkflowOutcome[]> {
        let result = this.ajaxService.Post<IWorkflowOutcome[]>("Todolist-api/WorkflowOutcomes", "GetWorkflowOutcomesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetWorkflowOutcomes(): Promise<IWorkflowOutcome[]> {
        let result = this.ajaxService.Post<IWorkflowOutcome[]>("Todolist-api/WorkflowOutcomes", "GetWorkflowOutcomes", {
            background: true,
            methodData: {
        	}
        });



        return result;
    }

    MoveWorkflowOutcome(outcomeId: number | null, position: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Todolist-api/WorkflowOutcomes", "MoveWorkflowOutcome", {
            background: true,
            methodData: {
        		outcomeId: outcomeId,
        		position: position,
        	}
        });



        return result;
    }
}

export default function Create() : IWorkflowOutcomesService {
    return new WorkflowOutcomesService();
}
