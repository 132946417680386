import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { WorkTimeCategoriesRemainingBudget } from "../editor/WorkTimeCategoriesRemainingBudget";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IGetResourceServiceOrdersSalariesBudgetsRequest, IServiceOrderCostWithBudget, IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { LazyImport } from "../../../../Core/DependencyInjection";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type WorkTimeCategoriesRemainingBudgetDialogProps = {
    resourceId: number;
    from: Date;
    to: Date;
}

/*

usando i parametri in ingresso, il resource id e l'orderId
prendere tutte le tipologie con una soglia annuale, mostrarla in forma tabellare e calcolare il residuo
*/
export class WorkTimeCategoriesRemainingBudgetDialog extends DialogComponentBase {
    static defaultProps: Partial<WorkTimeCategoriesRemainingBudgetDialogProps> = {
    }

    private rows: ko.ObservableArray<IServiceOrderCostWithBudget> = ko.observableArray([]);
    
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;

    constructor(private props : WorkTimeCategoriesRemainingBudgetDialogProps) {
        super({ noPrompt: true });

        this.title(TextResources.WorkedHours.WorkTimeCategoriesRemainingBudgetTitle);
    }

    componentDidMount() {
        this.load();
    }

    private async load(): Promise<void> {
        try {
            const request : IGetResourceServiceOrdersSalariesBudgetsRequest = {
                resourceId: this.props.resourceId,
                from: this.props.from,
                to: this.props.to
            };
            const rows = await this.workedHoursService.GetResourceServiceOrdersSalariesBudgets(request);
            this.rows(rows);
        } catch (e) {
            console.log(e);
        }
    }

    action() {
        this.modal.close();
    }

    renderBody() {
        return ComponentUtils.bindTo((
            <div className={"row "}>
                <div className="col-md-12">
                   <WorkTimeCategoriesRemainingBudget servicesOrderCost={this.rows}></WorkTimeCategoriesRemainingBudget>
                </div>
            </div>
        ), this, "modal");
    }
}

export type ErrorsAndWarnings = {
    Errors: IServiceOrderCostWithBudget[];
    Warnings: IServiceOrderCostWithBudget[];
}

type WorkTimeCategoriesRemainingBudgetWithListDialogProps = {
    data: ErrorsAndWarnings;
    showSave: boolean;
}

export class WorkTimeCategoriesRemainingBudgetWithListDialog extends DialogComponentBase {
    static defaultProps: Partial<WorkTimeCategoriesRemainingBudgetWithListDialogProps> = {
    }

    private showMinNotReachedMessage = false;
    private showMaxOverflowMessage = false;

    constructor(private props : WorkTimeCategoriesRemainingBudgetWithListDialogProps) {
        super({noPrompt: !props.showSave});

        this.title(TextResources.WorkedHours.WorkTimeCategoriesRemainingBudgetTitle);
        this.showMinNotReachedMessage = !!this.props.data.Errors.firstOrDefault(e => e.MinRemainingBudget > 0) || !!this.props.data.Warnings.firstOrDefault(e => e.MinRemainingBudget > 0);
        this.showMaxOverflowMessage = !!this.props.data.Errors.firstOrDefault(e => e.MaxRemainingBudget < 0) || !!this.props.data.Warnings.firstOrDefault(e => e.MaxRemainingBudget < 0);
    }

    componentDidMount() {
    }

    action() {
        this.modal.close(true);
    }

    public override close(): void {
        this.modal.close(false);
    }

    renderBody() {
        return ComponentUtils.bindTo((
           <>
                <div className={"row "}>
                    <div className="col-md-12">
                        {this.showMinNotReachedMessage && <>{TextResources.WorkedHours.MinNotReachedMessage}<br /></>}
                        {this.showMaxOverflowMessage && <>{TextResources.WorkedHours.MaxOverflowMessage}<br /></>}
                        {this.showMaxOverflowMessage ? (this.props.showSave ? TextResources.WorkedHours.WarningBalanceExceeded : TextResources.WorkedHours.ErrorBalanceExceeded) : ""}
                    </div>
                </div>
                <div className={"row "}>
                    <div className="col-md-12">
                        <WorkTimeCategoriesRemainingBudget hideServiceOrderNameIfOnlyOne={true} servicesOrderCost={ko.observableArray(this.props.data.Errors.concat(this.props.data.Warnings))}></WorkTimeCategoriesRemainingBudget>
                    </div>
                </div>
            </>
        ), this, "modal");
    }
}