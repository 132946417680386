import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";

type NumberInputProps = {
    simple?: boolean;
    value: ko.Observable<number> | ko.Computed<number>;
    label?: string;
    selectOnFocus?: boolean;
    format?: string;
    nullable?: boolean;
    readOnly?: ko.Observable<boolean> | ko.Computed<boolean> | boolean;
    placeholder?: string;
    helpText?: ko.MaybeObservable;
    className?: string;
    suffix?: string;
    minValue?: number;

    onFocus?: (element: HTMLInputElement) => void;
    onBlur?: (element: HTMLInputElement) => void;
};

export class NumberInput {
    static defaultProps = {
        simple: false,
        label: " ",
        selectOnFocus: false,
        format: "0,0.00[0]",
        nullable: false,
        readOnly: false,
    };

    internalValue: ko.Computed<number>;

    private hasFocus = ko.observable(false);
    private inputRef: HTMLInputElement = null;

    constructor(private props: NumberInputProps) {
        this.internalValue = ko
            .computed({
                read: props.value,
                write: (value: number) => {
                    if (props.minValue != null && value < props.minValue) value = props.minValue;
                    this.props.value(value);
                },
            })
            .extend({ notify: "always" });

        if (props.onFocus) this.hasFocus.subscribe((value) => value && props.onFocus(this.inputRef));
        if (props.onBlur) this.hasFocus.subscribe((value) => !value && props.onBlur(this.inputRef));
    }

    render() {
        let ni = this;

        let bindings =
            (this.props.nullable ? "nullableNumberValue" : "numberValue") +
            ": internalValue, format: ni.props.format, disable: ni.props.readOnly, numberValueSuffix: ni.props.suffix, hasFocus: ni.hasFocus";

        if (this.props.selectOnFocus) bindings += ", selectOnFocus: {}";

        if (this.props.simple) {
            const classeNames = ComponentUtils.classNames("form-control text-right", this.props.className);
            return ComponentUtils.bindTo(
                <input
                    className={classeNames}
                    type="text"
                    data-bind={bindings}
                    placeholder={this.props.placeholder}
                    ref={(i) => (this.inputRef = i)}></input>,
                this,
                "ni"
            );
        }

        const classeNames = ComponentUtils.classNames("form-group", this.props.className);
        return ComponentUtils.bindTo(
            <div className={classeNames}>
                <label className="control-label">{this.props.label}</label>
                <input
                    className="form-control text-right"
                    type="text"
                    data-bind={bindings}
                    placeholder={this.props.placeholder}
                    ref={(i) => (this.inputRef = i)}></input>
                {this.renderHelp()}
            </div>,
            this,
            "ni"
        );
    }

    private renderHelp() {
        let ti = this;

        if (ko.isObservable(ti.props.helpText))
            return (
                <ko-bind data-bind={{ if: !!ti.props.helpText() }}>
                    <span class="help-block" data-bind={{ text: ti.props.helpText }}></span>
                </ko-bind>
            );

        return ti.props.helpText && <span className="help-block">{ti.props.helpText}</span>;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(NumberInput);
}
