import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 17:08
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ISampleType } from "../../../../../../ProlifeSdk/interfaces/survey/ISample";
import { ISampleTypeSettingManager } from "../../../../../interfaces/ISampleTypeSettingManager";

export interface ISampleTypesProvider
{
    getData() : ISampleType;
    update(sampleType : ISampleType) : void;
    withError: ko.Observable<boolean>;
}

export class SampleTypeEditingViewModel {
    title : string;
    elements : ko.ObservableArray<ISampleTypesProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    NewType : ko.Observable<string> = ko.observable();
    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private sampleTypesManager : ISampleTypeSettingManager)
    {
        this.title = sampleTypesManager.getLabel();
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        sampleTypesManager.getSampleTypes()
            .forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(sampleType : ISampleType)
    {
        this.elements.push(new SampleTypeViewModel(this.serviceLocator, this, sampleType));
    }

    public addNewSetting()
    {
        var vm = new SampleTypeViewModel(this.serviceLocator, this, {
            Id: null,
            Name: this.NewType(),
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF"
        });
        this.elements.push(vm);
        this.createOrUpdateEntry(vm);
        this.NewType("");
    }

    public setIsSelectedNewSetting()
    {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : ISampleTypesProvider)
    {
        var sampleType = element.getData();
        this.sampleTypesManager.addOrUpdate(sampleType)
            .then((updated) => element.update(updated));
    }

    public deleteEntry(element : ISampleTypesProvider)
    {
        var sampleType = element.getData();
        this.sampleTypesManager.remove(sampleType.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.toastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            })
            .catch(() => { });
    }

    private reloadElements() {
        this.elements([]);
        this.sampleTypesManager.load()
            .then(() => {
                this.sampleTypesManager.getSampleTypes()
                    .forEach(this.createViewModelFor.bind(this));
            });
    }
}


class SampleTypeViewModel implements ISampleTypesProvider
{
    private toastService : IInfoToastService;

    Id: ko.Observable<number> = ko.observable();
    Name : ko.Observable<string> = ko.observable();
    Icon: ko.Observable<string> = ko.observable();
    Background: ko.Observable<string> = ko.observable();
    Foreground: ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    public withError: ko.Observable<boolean> = ko.observable(false);

    private updating : boolean = false;

    private currentDescription : string;

    constructor(private serviceLocator, private container : SampleTypeEditingViewModel, private sampleType : ISampleType)
    {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        this.update(sampleType);

        this.Name.subscribe(this.onNameChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
    }

    private onNameChanged(newValue : string)
    {
        if(this.currentDescription == newValue)
            return;

        this.withError(false);
        if(!newValue)
        {
            this.container.deleteEntry(this);
        }
        else
            this.container.createOrUpdateEntry(this);
    }

    getData() : ISampleType
    {
        var sampleType : ISampleType = <ISampleType> $.extend({}, this.sampleType);
        sampleType.Name = this.Name();
        sampleType.Icon = this.Icon();
        sampleType.Background = this.Background();
        sampleType.Foreground = this.Foreground();
        return sampleType;
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating) return;
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    update(sampleType : ISampleType) : void
    {
        this.updating = true;

        this.sampleType = sampleType;
        this.Id(sampleType.Id);
        this.Name(sampleType.Name);
        this.Icon(sampleType.Icon);
        this.Background(sampleType.Background);
        this.Foreground(sampleType.Foreground);

        this.currentDescription = sampleType.Name;
        this.updating = false;
    }
}