import * as ko from "knockout";
import { MovementArticle } from "./MovementArticle";
import { IResolvedCustomerDiscountCatalogArticleWithCatalog } from "../../../DiscountsCatalogsService";
import { IStockArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";

// TODO fare template in modo da non dover fare il cast a IStockArticle

export class SelectableMovementArticle extends MovementArticle<IStockArticle> {
    Selected: ko.Observable<boolean> = ko.observable(false);
    EntityType = "";

    constructor(
        stockArticle: IStockArticle,
        entityType: string,
        manageTransaction: boolean,
        transactionId = 0,
        forSupplier = false,
        forInventoryAdjustment = false
    ) {
        super(stockArticle, manageTransaction, transactionId, forSupplier, forInventoryAdjustment);

        this.EntityType = entityType;
    }

    public setDefaultValues(discount: IResolvedCustomerDiscountCatalogArticleWithCatalog) {
        this.Amount(this.article.MinimumAmount - this.article.StockAmount);
        super.setDefaultValues(discount);
    }
}
