import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../Core/utils/ComponentUtils";

export interface ITabNavComponentParams {
    className?: string;
    style?: CSSProperties;
    children: React.ReactNode;
}

export interface ITabNavPage {
    Title: ko.Observable<string>;
    Visible: ko.MaybeSubscribable;
    render() : React.ReactNode;
}

export interface ITabNavPageParams {
    title: string;
    visible?: ko.MaybeSubscribable;
    default?: boolean;
    children: () => React.ReactNode;
}

export class TabNavPage implements ITabNavPage {
    static defaultProps: Partial<ITabNavPageParams> = {
        visible: true
    }

    Title : ko.Observable<string> = ko.observable();
    Visible : ko.MaybeSubscribable;
    get default() { return this.props.default }

    constructor(private props : ITabNavPageParams) {
        this.Title(props.title);
        this.Visible = this.props.visible;
    }
    
    componentDidMount() {
        console.log("TabNavPage ComponentDidMount", this.props.title);
    }

    render() {
        return <></>;
    }
    
    getComponent() {
        return this.props.children[0]();
    }
}

export class TabNav {
    static defaultProps: Partial<ITabNavComponentParams> = {
        className: ""
    }

    static Page = TabNavPage;

    Pages: ko.ObservableArray<TabNavPage> = ko.observableArray();
    SelectedPage : ko.Observable<TabNavPage> = ko.observable();
    
    constructor(private props : ITabNavComponentParams) {
        ComponentUtils.Children.forEach(props.children, c => {
            if(ComponentUtils.isOfType(c, TabNavPage))
                this.Pages.push(ComponentUtils.getComponent<TabNavPage>(c));
        });

        this.SelectedPage(this.Pages().find(p => p.default) ?? this.Pages()[0]);
    }

    componentDidMount() {
        console.log("TabNav ComponentDidMount");
    }

    SelectPage(page: TabNavPage) {
        this.SelectedPage(page);
    }

    render() {
        const classes = ComponentUtils.classNames("tabbable-custom", ...this.props.className.split(" "));
        
        if(!this.props.style)
            this.props.style = {};

        if(!this.props.style.marginBottom)
            this.props.style.marginBottom = '0px';

        let tabnav: TabNav;
        let page: ITabNavPage;
        let selPage: ITabNavPage;

        const nodes =   <div className={classes} style={this.props.style}>
                            <ul className="nav nav-tabs" data-bind={{ foreach: { data: tabnav.Pages, as: 'page' } }}>
                                <li data-bind={{ css: { active: tabnav.SelectedPage() == page }, visible: page.Visible }}>
                                    <a href="#" data-bind={{ attr: { 'aria-expanded': tabnav.SelectedPage() == page }, click: tabnav.SelectPage.bind(tabnav, page) }}>
                                        <ko-bind data-bind={{ text: page.Title }}></ko-bind>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content flex-container flex-fill flex-vertical" style={{ overflow: "hidden" }}>
                                <ko-bind data-bind={{ with: tabnav.SelectedPage, as: 'selPage' }}>
                                    <div className="tab-pane active flex-container flex-full-height flex-vertical" style={{ overflow: "hidden" }} data-bind={{ tsxtemplate: selPage }}>
                                        
                                    </div>
                                </ko-bind>
                            </div>
                        </div>;

        return ComponentUtils.bindTo(nodes, this, "tabnav");
    }
}