import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    IManufacturersService,
    IManufacturer,
    IManufacturer_Type,
} from "../ProlifeSdk/interfaces/warehouse/IManufacturersService";
import { LazyImport } from "../Core/DependencyInjection";

class ManufacturersService implements IManufacturersService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    constructor(serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IManufacturersService>(), this);
    }

    getServiceType(): string {
        return ProlifeSdk.ManufacturersServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    SearchManufacturers(filter: string | null): Promise<IManufacturer[]> {
        return this.ajaxService.Post<IManufacturer[]>("Warehouse-api/Manufacturers", "SearchManufacturers", {
            background: true,
            methodData: {
                filter: filter,
            },
        });
    }

    SearchManufacturersCount(filter: string | null): Promise<number> {
        return this.ajaxService.Post<number>("Warehouse-api/Manufacturers", "SearchManufacturersCount", {
            background: true,
            methodData: {
                filter: filter,
            },
        });
    }

    RemoveManufacturer(id: number | null): Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/Manufacturers", "RemoveManufacturer", {
            background: true,
            methodData: {
                id: id,
            },
        });
    }

    GetManufacturersByIds(ids: number[] | null): Promise<IManufacturer[]> {
        return this.ajaxService.Post<IManufacturer[]>("Warehouse-api/Manufacturers", "GetManufacturersByIds", {
            background: true,
            methodData: {
                ids: ids,
            },
        });
    }

    CreateOrUpdateManufacturer(manufacturers: IManufacturer_Type[] | null): Promise<IManufacturer[]> {
        let result = this.ajaxService.Post<IManufacturer[]>(
            "Warehouse-api/Manufacturers",
            "CreateOrUpdateManufacturer",
            {
                background: true,
                methodData: {
                    manufacturers: manufacturers,
                },
            }
        );

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ManufacturersService(serviceLocator);
}
