import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 15:24
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IQueueState, ILogicalQueueState } from "../../../../../ProlifeSdk/interfaces/survey-wizard/IQueueState";
import { IQueueStateSettingManager } from "../../../../interfaces/IQueueStateSettingManager";

export interface IQueueStateProvider {
    getData() : IQueueState;
    update(queueState : IQueueState) : void;
    withError: ko.Observable<boolean>;
}

export class QueueStateEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IQueueStateProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);

    public LogicalStates: ko.ObservableArray<ILogicalQueueState> = ko.observableArray([]);
    public NewState: ko.Observable<string> = ko.observable();

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private queueStateManager: IQueueStateSettingManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.title = queueStateManager.getLabel();

        this.LogicalStates(this.queueStateManager.getLogicalQueueStates());

        this.reloadElements();
    }

    private reloadElements() {
        this.elements([]);
        this.queueStateManager.load();
        var queueStates = this.queueStateManager.getQueueStates();
        queueStates.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(queueState: IQueueState) {
        this.elements.push(new QueueStateViewModel(this, queueState, this.queueStateManager.getLogicalQueueStates()));
    }

    public addNewSetting() {
        if (!this.NewState())
            return;
        var queueStateViewModel = new QueueStateViewModel(this, {
            Id: null,
            Label: this.NewState(),
            StateId: this.queueStateManager.getQueueStates()[0].Id
        }, this.queueStateManager.getLogicalQueueStates());
        this.elements.push(queueStateViewModel);
        this.createOrUpdateEntry(queueStateViewModel);

        this.NewState("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element: IQueueStateProvider) {
        var queueState = element.getData();
        this.queueStateManager.addOrUpdate(queueState)
            .then((updatedQueueState) => element.update(updatedQueueState));
    }

    public deleteEntry(element: IQueueStateProvider) {
        var queueState = element.getData();
        this.queueStateManager.remove(queueState.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            });
    }
}


class QueueStateViewModel implements IQueueStateProvider {
    Id: ko.Observable<number> = ko.observable();
    Label : ko.Observable<string> = ko.observable();
    LogicalState : ko.Observable<number> = ko.observable();
    SelectedLogicalState : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    public withError: ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    constructor(private container : QueueStateEditingViewModel, private queueState : IQueueState, public queueLogicalState : ILogicalQueueState[]) {
        this.Id(queueState.Id);
        this.Label(queueState.Label);
        this.LogicalState(queueState.StateId);

        this.setInitialState();

        this.Label.subscribe(this.onNameChanged.bind(this));
        this.LogicalState.subscribe(this.onDataChanged.bind(this));
    }

    private setInitialState(): void {
        this.SelectedLogicalState(this.queueLogicalState.filter((s: ILogicalQueueState) => {
            return s.Id == this.LogicalState();
        })[0].Description);
    }

    public SetLogicalState(state: ILogicalQueueState): void {
        this.LogicalState(state.Id);
        this.SelectedLogicalState(state.Description);
    }

    private onNameChanged(newValue : string) {
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    getData() : IQueueState {
        var queueState: IQueueState = <IQueueState> $.extend({}, this.queueState);
        queueState.Id = this.Id();
        queueState.Label = this.Label();
        queueState.StateId = this.LogicalState();
        return queueState;
    }

    private onDataChanged(newValue : string) {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    update(queueState : IQueueState) : void {
        this.updating = true;
        this.queueState = queueState;
        this.Id(queueState.Id);
        this.Label(queueState.Label);
        this.LogicalState(queueState.StateId);

        this.updating = false;
    }
}