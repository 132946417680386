import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { ICustomerPaymentExtension, ICustomersService, IGetAllCustomersPaymentExtensionsRequest } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { LazyImport } from "../Core/DependencyInjection";
import { DiscountFamiliesDataSource } from "./DiscountFamiliesDataSource";
import moment = require("moment");
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface ICustomersPaymentExtensionsDataSourceModel extends IDataSourceModel<number, ICustomerPaymentExtension> {}

export class CustomersPaymentExtensionsDataSource extends BaseDataSource<ICustomersPaymentExtensionsDataSourceModel> {
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    private customerId: number;
    private startDate: Date;
    private endDate: Date;

    public getTitle(currentModel: ICustomersPaymentExtensionsDataSourceModel): string {
        return "";
    }
    
    public async getData(currentModel: ICustomersPaymentExtensionsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICustomersPaymentExtensionsDataSourceModel[]> {
        let request: IGetAllCustomersPaymentExtensionsRequest = {
            customerId: this.customerId,
            startDate: this.startDate,
            endDate: this.endDate,
            skip: skip,
            count: count
        };

        let paymentExtensions = await this.customersService.GetAllCustomersPaymentExtensions(request);
        return paymentExtensions.map(this.createModel, this);
    }
    
    public async getById(currentModel: ICustomersPaymentExtensionsDataSourceModel, ids: number[]): Promise<ICustomersPaymentExtensionsDataSourceModel[]> {
        let paymentExtensions = await this.customersService.GetAllCustomersPaymentExtensionsByIds(ids);
        return paymentExtensions.map(this.createModel, this);
    }

    public setCustomerIdFilter(id: number): void {
        this.customerId = id;
    }

    public setStartDateFilter(date: Date): void {
        this.startDate = date;
    }

    public setEndDateFilter(date: Date): void {
        this.endDate = date;
    }

    private createModel(paymentExtension: ICustomerPaymentExtension): ICustomersPaymentExtensionsDataSourceModel {
        return {
            id: paymentExtension.PaymentExtensionId,
            title: String.format(TextResources.Customers.CustomerPaymentExtensionTitle, moment(paymentExtension.ExtensionDate).format("dd/MM")),
            isGroup: false,
            isLeaf: true,
            model: paymentExtension
        };
    }
}