import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/02/2018
 * Time: 10:49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { AgendaEventViewModel } from "../AgendaEventViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IEventsService, IAgendaEventForEditingEx } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IAgendaService, IAgendaResource } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IAgendaEventViewModel } from "../../../interfaces/IAgendaEvent";
import { IFullCalendar } from "../../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { Deferred } from "../../../../Core/Deferred";

export class AgendaEventEditingDialog implements IDialog {
    templateName = "agenda-event-dialog";
    templateUrl = "agenda/templates/dialogs";
    title = "";

    public EventForEditing: ko.Observable<IAgendaEventViewModel> = ko.observable();

    public ShowSaveButton: ko.Computed<boolean>;
    public ShowDeleteButton: ko.Computed<boolean>;
    public ShowRecoveryButton: ko.Computed<boolean>;

    modal: { close: (result?: any) => void; };

    private eventsService: IEventsService;
    private agendasService: IAgendaService;
    private infoToastService: IInfoToastService;
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private event: IAgendaEventForEditingEx, private agendaId: number, private calendar: IFullCalendar = null) {
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        if (!this.event) {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaEventEditingDialogTitleForInsert;
            this.CreateEmptyEventViewModel()
                .then((event: IAgendaEventViewModel) => {
                    this.EventForEditing(event);
                });
        } else {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaEventEditingDialogTitleForEdit;
            this.EventForEditing(this.CreateEventViewModel(event));
        }

        this.ShowSaveButton = ko.computed(() => {
            if (!this.EventForEditing())
                return false;

            return !this.EventForEditing().IsAborted() && (!this.EventForEditing().IsReadOnly() || (this.EventForEditing().CanChangeEventState() && this.EventForEditing().AgendaTimeslotsModeEnabled()));
        });

        this.ShowRecoveryButton = ko.computed(() => {
            if (!this.EventForEditing())
                return false;

            return this.EventForEditing().IsAborted() && !this.EventForEditing().IsReadOnly() && (this.EventForEditing().CanChangeEventState());
        });

        this.ShowDeleteButton = ko.computed(() => {
            if (!this.EventForEditing())
                return false;

            return !this.ShowRecoveryButton() && !this.EventForEditing().IsNew() && !this.EventForEditing().IsReadOnly() && this.EventForEditing().CanDeleteEvents();
        });
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        this.Save()
            .then((eventId: number) => {
                if (this.EventForEditing().Id() <= 0) {
                    this.EventForEditing().Id(eventId);
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.InsertEventSuccess);
                } else {
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.ModifyEventSuccess);
                }
                const eventData: IAgendaEventForEditingEx = this.EventForEditing().GetData();
                this.modal.close(eventData);
            })
            .catch((showErrorMessage: boolean) => {
                if (showErrorMessage)
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.InsertFailed);
            });
    }

    deleteEvent(): void {
        this.EventForEditing().Delete()
            .then((deleted: boolean) => {
                if (deleted) {
                    this.EventForEditing().Deleted(ProlifeSdk.DeletedState);
                    this.modal.close(this.EventForEditing().GetData());
                }
            });
    }

    recovery(): void {
        this.Save()
            .then((eventId: number) => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.RecoverySuccess);
                const eventData: IAgendaEventForEditingEx = this.EventForEditing().GetData();
                this.modal.close(eventData);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RecoveryError);
            });
    }

    private Save(): Promise<number> {
        const def = new Deferred<number>();

        this.EventForEditing().DataAreValid()
            .then((valid: boolean) => {
                if (!valid) {
                    def.reject(false);
                    return;
                }

                if (this.EventForEditing().AgendaTimeslotsModeEnabled()) {
                    this.VerifySelectedTimeslotAvailability()
                        .then((valid: boolean) => {
                            if (!valid) {
                                this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.FullTimeslotSelected);
                                def.reject();
                            }
                            const eventData: IAgendaEventForEditingEx = this.EventForEditing().GetData();

                            this.eventsService.CreateOrUpdate(eventData)
                                .then((eventId: number) => {
                                    def.resolve(eventId);
                                })
                                .catch(() => {
                                    def.reject(true);
                                });
                        });

                    return;
                }

                this.VerifyEventsOverlap()
                    .then((overlap: boolean) => {
                        if (overlap) {
                            this.confirmEventsOverlap()
                                .then((confirm: boolean) => {
                                    if (confirm) {
                                        const eventData: IAgendaEventForEditingEx = this.EventForEditing().GetData();
                                        eventData.CategoryId = null;

                                        this.eventsService.CreateOrUpdate(eventData)
                                            .then((eventId: number) => {
                                                def.resolve(eventId);
                                            })
                                            .catch(() => {
                                                def.reject(true);
                                            });
                                    }
                                });
                            return;
                        }

                        const eventData: IAgendaEventForEditingEx = this.EventForEditing().GetData();
                        eventData.CategoryId = null;

                        this.eventsService.CreateOrUpdate(eventData)
                            .then((eventId: number) => {
                                def.resolve(eventId);
                            })
                            .catch(() => {
                                def.reject(true);
                            });
                    });
            });

        return def.promise();
    }

    private confirmEventsOverlap(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.EventsTimeOverlapConfirm,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                return def.resolve(confirm);
            });

        return def.promise();
    }

    private VerifySelectedTimeslotAvailability(): Promise<boolean> {
        const eventStartDateAndTime: Date = this.EventForEditing().GetEventStartDateAndTime();
        const eventEndDateAndTime: Date = this.EventForEditing().GetEventEndDateAndTime();
        const eventId: number = this.EventForEditing().Id();
        const agendaId: number = this.EventForEditing().AgendaId();

        return this.eventsService.VerifyTimeslotAvailability(eventId, agendaId, eventStartDateAndTime, eventEndDateAndTime);
    }

    private VerifyEventsOverlap(): Promise<boolean> {
        const eventStartDateAndTime: Date = this.EventForEditing().GetEventStartDateAndTime();
        const eventEndDateAndTime: Date = this.EventForEditing().GetEventEndDateAndTime();
        const eventId: number = this.EventForEditing().Id();
        const agendaId: number = this.EventForEditing().AgendaId();

        return this.eventsService.VerifyEventsOverlap(eventId, agendaId, eventStartDateAndTime, eventEndDateAndTime);
    }

    private CreateEmptyEventViewModel(): Promise<IAgendaEventViewModel> {
        const def = new Deferred<IAgendaEventViewModel>();
        this.agendasService.GetAgendasResources(this.agendaId, false)
            .then((resources: IAgendaResource[]) => {
                const event: IAgendaEventForEditingEx = {
                    id: -1,
                    title: '',
                    start: null,
                    end: null,
                    allDay: false,
                    color: '',
                    AgendaId: this.agendaId,
                    CategoryId: null,
                    EventPlace: '',
                    Note: '',
                    CanceledMotivationId: null,
                    CustomersIds: [],
                    Resources: resources.map((r: IAgendaResource) => { return { EventId: -1, ElementType: r.ElementType, ElementId: r.ElementId } }),
                    Deleted: ProlifeSdk.NotDeletedState,
                    Tags: [],
                    Allocable: false,
                    PreEventAllocatedHours: 0,
                    PostEventAllocatedHours: 0,
                    IsFromBookingPortal: false,
                    CreatedBy: null,
                    CreationDate: null,
                    ModifiedBy: null,
                    ModifyDate: null,
                    EventAbortedMotivationId: null,
                    EventSourceProvider: null,
                    EventCalendarOnProvider: null,
                    EventIdOnProvider: null
                };
                def.resolve(this.CreateEventViewModel(event));
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();

    }

    private CreateEventViewModel(event: IAgendaEventForEditingEx): IAgendaEventViewModel {
        if (this.agendaId)
            event.AgendaId = this.agendaId;
        return new AgendaEventViewModel(this.serviceLocator, event, this.calendar);
    }
}