import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { ChangelogDialog } from "./ChangelogDialog";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { SubContextMenuDialog } from "./SubContextMenuDialog";
import { INavTabPage } from "../../Components/NavTabComponent";
import { LazyImport } from "../../Core/DependencyInjection";
import "./OperationsProgressComponent";
import "../../Invoices/invoices/ui/TrustAuthorizationHeaderPopover";
import "../../Todolist/Todolist/ui/task-board/TaskBoardHeaderPopover";
import "./SystemHeaderPopover";
import { OperationsProgressViewModel, TrustAuthorizationRequestsManager } from "../DesktopService";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { ISettingsServiceObserver, ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IApplicationStarter, IApplicationsService } from "../interfaces/IApplicationsService";
import { IApplicationHost } from "../interfaces/IApplicationHost";
import { IDesktopService, IDownloadProgress } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IPaymentPortalService } from "../PaymentPortalService";
import { IUserInfoObserver } from "../../ProlifeSdk/interfaces/desktop/IUserInfoObserver";
import { ISystemHeader } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import {
    IApplicationContextAction,
    IApplicationNavBarAction,
    IApplicationEmbeddedField,
    IApplicationBase,
} from "../interfaces/IApplication";
import { IChangelog } from "../../ProlifeSdk/interfaces/desktop/IChangelog";
import { IView } from "../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../Core/Deferred";
import { MailsToManageIndicatorUI } from "../../Blog/Blog/Dialog/MailEventsGenerator/MailsToManageIndicator";
import { UserMilestonesUI } from "../../Components/UserMilestones/UserMilestonesComponent";
import { ICompanyForUser } from "../../Core/interfaces/IPrincipal";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";

export class SystemHeader implements IUserInfoObserver, ISettingsServiceObserver, ISystemHeader {
    public ApplicationName: ko.Observable<string> = ko.observable("");
    public UserName: ko.Observable<string> = ko.observable("");
    public Companies: ko.ObservableArray<ICompanyForUser> = ko.observableArray([]);
    public CurrentCompany: ko.Observable<ICompanyForUser> = ko.observable();

    public MailToManageIndicator: MailsToManageIndicatorUI;
    public UserMilestones: UserMilestonesUI;

    private DownloadingBackup: ko.Observable<boolean> = ko.observable(false);
    private BackupDownloadDef: Deferred<void>;
    private BackupInProgressGuid: string = null;

    private currentApplication: ko.Observable<IApplicationBase> = ko.observable();

    public firstGroupApplications: ko.Computed<IApplicationStarter[]>;
    public secondGroupApplications: ko.Computed<IApplicationStarter[]>;
    public thirdGroupApplications: ko.Computed<IApplicationStarter[]>;
    public allApplications: ko.Computed<IApplicationStarter[]>;

    public hasContextActions: ko.Computed<boolean>;
    public contextActions: ko.Computed<IApplicationContextAction[]>;

    public hasNavBarActions: ko.Computed<boolean>;
    public navBarActions: ko.Computed<IApplicationNavBarAction[]>;
    public leftNavBarActions: ko.Computed<IApplicationNavBarAction[]>;
    public rightNavBarActions: ko.Computed<IApplicationNavBarAction[]>;

    public hasEmbeddedFields: ko.Computed<boolean>;
    public embeddedFields: ko.Computed<IApplicationEmbeddedField[]>;

    public sidebarVisible: ko.Observable<boolean> = ko.observable(true);
    public canShowSidebar: ko.Computed<boolean>;
    public operationsProgress: ko.Observable<any> = ko.observable();
    public trustAuthorizationRequests: ko.Observable<any> = ko.observable();

    public FilteredCompanies: ko.Computed<ICompanyForUser[]>;
    public CompaniesFilter: ko.Observable<string> = ko.observable().extend({ rateLimit: 500 });

    public ExtraInfoPages: ko.ObservableArray<INavTabPage> = ko.observableArray();
    public ExtraInfoBadgeCounter: ko.Computed<number>;

    /* Template da caricare nel menù contestuale */
    public contextMenuTemplateViewModel: ko.Observable<any> = ko.observable();

    public LeftMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    public RightMenuEnabled: ko.Observable<boolean> = ko.observable(false);

    public ConnectionStatus: ko.Observable<string> = ko.observable("not-connected");
    public ConnectionStatusString: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.Desktop.NotConnected);

    @LazyImport(nameof<IApplicationsService>())
    public applicationsService: IApplicationsService;
    @LazyImport(nameof<IApplicationHost>())
    public applicationHostService: IApplicationHost;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;
    @LazyImport(nameof<IPaymentPortalService>())
    private paymentPortalService: IPaymentPortalService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        this.userInfoService.addObserver(this);
        this.settingsService.addObserver(this);

        this.operationsProgress(this.desktopService.GetOperationsProgressVM());
        this.trustAuthorizationRequests(this.desktopService.GetTrustAuthorizationRequestsVM());

        this.firstGroupApplications = ko.computed(
            this.applicationsService.getFirstGroupApplications.bind(this.applicationsService),
            this,
            { deferEvaluation: true }
        );
        this.secondGroupApplications = ko.computed(
            this.applicationsService.getSecondGroupApplications.bind(this.applicationsService),
            this,
            { deferEvaluation: true }
        );
        this.thirdGroupApplications = ko.computed(
            this.applicationsService.getThirdGroupApplications.bind(this.applicationsService),
            this,
            { deferEvaluation: true }
        );
        this.allApplications = ko.computed(() => {
            const firstGroupVisibles = this.firstGroupApplications().filter((a) => {
                return a.visible();
            });
            const secondGroupVisibles = this.secondGroupApplications().filter((a) => {
                return a.visible();
            });
            const thirdGroupVisibles = this.thirdGroupApplications().filter((a) => {
                return a.visible();
            });

            return firstGroupVisibles.concat(secondGroupVisibles).concat(thirdGroupVisibles);
        });

        this.hasContextActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.contextActions) return false;
            return application.contextActions().length > 0;
        });

        this.contextActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.contextActions) return [];
            return application.contextActions();
        });

        this.hasNavBarActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.navBarActions) return false;
            return (application.navBarActions() || []).length > 0;
        });

        this.navBarActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.navBarActions) return [];
            return application.navBarActions() || [];
        });

        this.leftNavBarActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.navBarActions) return [];
            return (application.navBarActions() || []).filter((a) => !a.Right);
        });

        this.rightNavBarActions = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.navBarActions) return [];
            return (application.navBarActions() || []).filter((a) => a.Right);
        });

        this.hasEmbeddedFields = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.embeddedFields) return false;
            return (application.embeddedFields() || []).length > 0;
        });

        this.embeddedFields = ko.computed(() => {
            const application = this.currentApplication();
            if (!application || !application.embeddedFields) return [];
            return application.embeddedFields() || [];
        });

        this.sidebarVisible.subscribe((visible: boolean) => {
            if (visible) $("body").addClass("page-quick-sidebar-open");
            else $("body").removeClass("page-quick-sidebar-open");
        });

        this.canShowSidebar = ko.computed(() => {
            const application = this.currentApplication();
            if (!application) {
                this.sidebarVisible(false);
                return false;
            }
            const result = (application.canShowSidebar || ko.observable(false))();
            if (!result) {
                this.sidebarVisible(false);
                return false;
            }
            this.sidebarVisible(true);
            return result;
        });

        this.FilteredCompanies = ko.computed(() => {
            const filter = this.CompaniesFilter() || "";
            if (filter.length == 0) return this.Companies();

            return this.Companies().filter((c) => {
                return c.CompanyName.toLowerCase().indexOf(filter.toLowerCase()) != -1;
            });
        });

        this.setContextMenu(null);

        this.desktopService.GetChangelog().then((changelog: IChangelog) => {
            if (!changelog) return;
            const changelogDialog = new ChangelogDialog(this.serviceLocator, changelog);
            changelogDialog.ShowDialog();
        });

        this.ExtraInfoBadgeCounter = ko.computed(() => {
            const pages = this.ExtraInfoPages();
            let totalCount = 0;

            for (const page of pages) {
                if (page.badgeCounter) totalCount += page.badgeCounter();
            }

            return totalCount;
        });

        this.MailToManageIndicator = new MailsToManageIndicatorUI({});
        this.UserMilestones = new UserMilestonesUI();
    }

    public ShowSystemHeaderPopover(viewModel: SystemHeader, element: MouseEvent) {
        return this.dialogsService.ShowPopoverComponent(
            element.currentTarget as HTMLElement,
            {
                componentName: "system-header-popover",
                model: {
                    ExtraInfoPages: this.ExtraInfoPages,
                },
                title: "Notifiche",
                params: "Pages: ExtraInfoPages",
            },
            "bottom",
            "body"
        );
    }

    public Initialize() {
        const connection = this.desktopService.SignalrConnection.getHubConnection();
        //this.backupHub = connection.createHubProxy("BackupDbHub");
        connection.on("NotifyBackupProgress", this.OnBackupProgressNotification.bind(this));

        if (this.authorizationsService.isAuthorized("TaskBoard_EnablePlayStopActionsOnTasks")) {
            this.ExtraInfoPages.push({
                title: "Attività",
                componentName: "taskboard-header",
                badgeCounter: ko.computed(() => 0),
            });
        }

        this.ExtraInfoPages.push({
            title: "Fido",
            componentName: "trust-authorization-header",
            badgeCounter: ko.computed(() => {
                const vm: TrustAuthorizationRequestsManager = this.desktopService.GetTrustAuthorizationRequestsVM();
                return vm.TotalPendingRequests();
            }),
        });

        this.ExtraInfoPages.push({
            title: "Trasferimenti",
            componentName: "operations-progress",
            badgeCounter: ko.computed(() => {
                const opvm: OperationsProgressViewModel = this.desktopService.GetOperationsProgressVM();
                return opvm.NumberOfOperationsInProgress();
            }),
        });
    }

    onSettingsLoaded(): void {
        const companies = this.userInfoService.getCompanies().sort((a, b) => {
            if (a.CompanyName < b.CompanyName) return -1;
            if (a.CompanyName > b.CompanyName) return 1;
            return 0;
        });

        this.UserName(this.userInfoService.getUserName());
        this.Companies(companies);
        this.CurrentCompany(this.userInfoService.getCurrentCompany());
    }

    onSettingsUpdated(updateType: string): void {}

    onCompanySwitched(company: ICompanyForUser): void {
        this.CurrentCompany(company);
    }

    public switchSidebarVisibility() {
        if (this.canShowSidebar()) this.sidebarVisible(!this.sidebarVisible());
    }

    public setSidebarVisibility(value: boolean): void {
        if (this.canShowSidebar()) this.sidebarVisible(value);
    }

    public switchCompany(company: ICompanyForUser) {
        if (location.hash != "#" && location.hash != "#/") {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Desktop.SwitchCompanyWarning,
                ProlifeSdk.TextResources.Desktop.SwitchCompanyCancel,
                ProlifeSdk.TextResources.Desktop.SwitchCompanyConfirm,
                (result: boolean) => {
                    if (!result) return;
                    location.href = "#/";
                    this.authenticationService.switchCompany(company);
                }
            );
        } else {
            this.authenticationService.switchCompany(company);
        }
    }

    goHome() {
        location.href = "#/";
        this.applicationHostService.SetSideMenuEnabled(true, false);
        this.LeftMenuEnabled(true);
        this.RightMenuEnabled(false);
    }

    public ShowChangePasswordDialog() {
        const dialogsService: IDialogsService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        const changePswVm = new ChangePasswordDialog();
        dialogsService.ShowModal<void>(
            changePswVm,
            "change-password-dialog",
            null,
            changePswVm.templateUrl,
            changePswVm.templateName
        );
    }

    public Logout() {
        this.authenticationService.logoutUser();
    }

    public async OpenPaymentPortal() {
        try {
            const url = await this.paymentPortalService.getPaymentPortalUrl();
            window.open(url, "_blank");
        } catch {
            await this.dialogsService.AlertAsync(
                "Impossibile accedere al portale pagamenti, riprovare più tardi",
                "Errore portale pagamenti"
            );
        }
    }

    public StartBackup() {
        if (this.DownloadingBackup()) return;

        this.BackupDownloadDef = new Deferred<void>();
        const desktopService = this.serviceLocator.findService<IDesktopService>(ProlifeSdk.DesktopServiceType);
        desktopService.AddDownloadNotification(
            String.format(ProlifeSdk.TextResources.Desktop.BackupDatabase, ProlifeSdk.TextResources.Desktop.ProLife),
            this.BackupDownloadDef.promise()
        );

        this.DownloadingBackup(true);
        desktopService.StartBackup(); /*.then((backupGuid : string) => {
            this.BackupInProgressGuid = backupGuid;
            this.backupHub.invoke("startBackup", backupGuid);
        });*/
    }

    private OnBackupProgressNotification(progress: IDownloadProgress) {
        this.BackupDownloadDef.notify({ percentComplete: progress.Progress / 100 });

        if (progress.Completed) {
            this.DownloadBackup();
            this.BackupDownloadDef.resolve();
        }
    }

    private DownloadBackup() {
        this.DownloadingBackup(false);
        this.ajaxService.DownloadFileFromUrl(
            "Desktop-api/Backup/DownloadBackup?backupGuid=" + this.BackupInProgressGuid,
            {}
        );
    }

    public OpenNewTab() {
        window.open("./#");
    }

    public setCurrentApplication(application: IApplicationBase) {
        this.currentApplication(application);
        this.ApplicationName(application.getName());
    }

    setMenuEnabled(LeftMenuEnabled: ko.Observable<boolean>, RightMenuEnabled: ko.Observable<boolean>) {
        this.LeftMenuEnabled = LeftMenuEnabled;
        this.RightMenuEnabled = RightMenuEnabled;
    }

    public setContextMenu(contextMenuViewModel: IView) {
        if (!contextMenuViewModel) return;
        this.contextMenuTemplateViewModel(contextMenuViewModel);
    }

    openSubContextMenu() {
        const subContextMenuDialog = new SubContextMenuDialog(
            this.serviceLocator,
            this.contextActions,
            "sub-context-menu-dialog",
            "Desktop/Templates",
            ""
        );
        subContextMenuDialog.ShowModal();
    }

    NotifyConnectionSlow(): void {
        this.ConnectionStatus("connection-slow");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.ConnectionSlow);
    }

    NotifyReconnecting(): void {
        this.ConnectionStatus("reconnecting");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.Reconnecting);
    }

    NotifyReconnected(): void {
        this.ConnectionStatus("connected");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.Connected);
    }

    NotifyConnectionLost(): void {
        this.ConnectionStatus("not-connected");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.NotConnected);
    }

    NotifyConnecting(): void {
        this.ConnectionStatus("connecting");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.Connecting);
    }

    NotifyConnected(): void {
        this.ConnectionStatus("connected");
        this.ConnectionStatusString(ProlifeSdk.TextResources.Desktop.Connected);
    }

    ShowSupportDialog() {
        const dialog = new SupportDialog(this.serviceLocator);
        dialog.show();
    }
}

class SupportDialog implements IDialog {
    templateName = "support-dialog";
    templateUrl = "desktop/templates";
    title: string = ProlifeSdk.TextResources.Desktop.SupportDialogTitle;

    modal: { close: (result?: any) => void };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {}

    show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, undefined, { noPrompt: true });
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.modal.close();
    }
}
