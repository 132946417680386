import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { HoursApprovalGroupsDataSource } from "./HoursApprovalGroupsDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { SelectMultiple } from "../../../../Components/SelectMultiple";

type HoursApprovalGroupsSelectorProps = {
    value: ko.ObservableArray<number>;
    noLabel?: boolean;

    readOnly?: ko.Observable<boolean> | ko.Computed<boolean>;
}

export function HoursApprovalGroupsSelector(props: HoursApprovalGroupsSelectorProps) {
    const C = require("./HoursApprovalGroupsSelector")._HoursApprovalGroupsSelector as typeof _HoursApprovalGroupsSelector;
    return <C {...props} />;
}

export class _HoursApprovalGroupsSelector {
    static defaultProps: Partial<HoursApprovalGroupsSelectorProps> = {
        noLabel: false
    }

    private DataSource: HoursApprovalGroupsDataSource;

    constructor(private props : HoursApprovalGroupsSelectorProps) {
        this.DataSource = new HoursApprovalGroupsDataSource();
    }
    
    render() {
        const props = {
            dataSource: this.DataSource,
            placeholder: TextResources.Users.HoursApprovalGroupsSelectorPlaceholder,
            value: this.props.value
        }

        if (this.props.noLabel) {
            return <SelectMultiple {...props} />;
        }

        return <SelectMultiple.WithLabel {...props} label={TextResources.Users.HoursApprovalGroupsSelectorLabel} />;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(HoursApprovalGroupsSelector);
}