import * as ko from "knockout";
import { IDataSourceModel } from "./IDataSource";

export type ObservableArrayDataSource<T, I = string|number> = { array: ko.ObservableArray<T> | ko.Computed<T[]>; factory: (item: T) => IDataSourceModel<I, T>; };

export function isObservableArrayDataSource<T, I = string|number>(value : any) : value is ObservableArrayDataSource<T, I> {
    return value !== null && value !== undefined && typeof value === "object" && (ko.isObservableArray(value.array) || ko.isComputed(value.array)) && typeof value.factory === "function";
}

export function synchronizeItems<T, I = string|number, K = IDataSourceModel<I, T>>(localArray: ko.ObservableArray<K>, dataSource: ObservableArrayDataSource<T>, adapter?: (item: T) => K) {
    //let itemFactory = (item : T) => this.createTableItemViewModel(dataSource.factory(item));
    if(!adapter)
        adapter = (item: T) => dataSource.factory(item) as unknown as K;

    if(!dataSource.array.hasOwnProperty("compareArrayOptions")) {
        console.warn("L'array passato alla synchronizeItems non è stato esteso con \"trackArrayChanges\"! Il sistema non sarà in grado di sincronizzare i cambiamenti agli array!");
    }
    
    dataSource.array.subscribe((changes) => {
        localArray.valueWillMutate();
        let actualItems = localArray();
        let oldIndexes = [];
        for(let index = 0; index < actualItems.length; index++)
            oldIndexes.push(index);
        let oldItems = actualItems.slice();

        for(let change of changes) {
            if(change.status === "added") {
                if(change.moved !== undefined) {
                    actualItems[change.index] = oldItems[change.moved];
                } else {
                    actualItems.splice(change.index, 0, adapter(change.value));
                    for(let i = change.index; i < oldIndexes.length; i++)
                        oldIndexes[i] = oldIndexes[i] + 1;
                }
            } else if(change.status === "deleted") {
                if(change.moved !== undefined) {
                    actualItems[change.moved] = oldItems[change.index];
                } else {
                    actualItems.splice(oldIndexes[change.index], 1);
                    for(let i = change.index; i < oldIndexes.length; i++)
                        oldIndexes[i] = oldIndexes[i] - 1;
                }
            }
        }

        localArray.valueHasMutated();
    }, null, "arrayChange");

    localArray((dataSource.array() || []).map(adapter));
}