import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer, IContactTag } from "../../../ProlifeSdk/interfaces/customer/ICustomer";

export class ContactTagsManager
{
    @LazyImport(nameof<ICustomersService>())
    private customerService : ICustomersService;

    private lastTimeout : ReturnType<typeof setTimeout>;
    public Tags : ko.Observable<string> = ko.observable("");
    public Labels : ko.ObservableArray<string> = ko.observableArray([]);

    constructor(private customer: ICustomer) {}

    public Initialize(tags : IContactTag[])
    {
        var labels : string[] = tags.map((t : IContactTag) => { return t.Value});
        this.Labels(labels);
        this.Tags(labels.join("|"));
    }

    public GetData()
    {
        return this.Tags().split("|")
            .filter((t : string) => { return t.trim().length > 0 })
            .map((t : string) => {
                return <IContactTag>{
                    CustomerId : this.customer ? this.customer.IdCliente : -1,
                    Value : t
                }
            })
    }

    private Search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() =>
        {
            var data = { results: [] };

            this.customerService.SearchTags(query.term, 30).then((labels : string[]) => {
                //Stringa di ricerca
                var groupResult = {
                    text : ProlifeSdk.TextResources.Customers.Text,
                    children : [{
                        id : query.term,
                        text : query.term
                    }]
                }

                if((query.term || "").trim().length > 0)
                    data.results.push(groupResult);

                groupResult = {
                    text : ProlifeSdk.TextResources.Customers.Labels,
                    children : labels.map((l : string) => {
                            return { id : l, text : l }
                        })
                };

                if(labels.length > 0)
                    data.results.push(groupResult);

                query.callback(data);
            });
        }, 500);
    }

    private InitializeList(element, callback)
    {
        var values = <string> $(element).val();
        var labels : string [] = values.split("|");

        var result : any[] = labels.map((l : string) => { return {
            id : l,
            text : l
        }});
        callback(result);
    }
}
