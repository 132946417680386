import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, reloadNow, useEffect } from "../../../../Core/utils/ComponentUtils";
import { Table } from "../../../TableComponent/TableComponent";
import { Layout } from "../../../Layouts";
import { List, ListItem } from "../../../ListComponent";
import { IDataSourceColumn, IDataSourceDefinition, IDataSourceInput } from "../../../../Desktop/DataSourcesService";
import { With } from "../../../IfIfNotWith";
import { ObservableArrayDataSource } from "../../../../DataSources/ObservableArrayDataSource";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";

const styleSheet = jss.createStyleSheet({
    reportDataSourceColumnViewer: {
        "&.list-notification-container .list-notification.list-notification-fit .list-notification-item": {
            borderLeft: '1px solid #e5e5e5',
            borderTop: '0px',
            borderRight: '1px solid #e5e5e5',
            borderBottom: '1px solid #e5e5e5',
            fontSize: '12px',
            padding: '0px 5px',

            "&:first-of-type": {
                borderTop: '1px solid #e5e5e5',
            },

            "& .list-notification-item-details": {
                "& > .filtered": {
                    backgroundColor: "#ff990040",
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                },

                "& .list-notification-item-title": {
                    margin: 0,
                },

                "& .list-notification-item-time": {
                    fontSize: '0.9em'
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDataSourceColumnsViewerProps = {
    dataSource: ko.Observable<IDataSourceDefinition>;
    onColumnAssociated?: (column: IDataSourceColumn, inputParameter: IDataSourceInput) => void;
}

type FilterableDataSourceColumn = IDataSourceColumn & { filteredBy?: ko.Observable<string> };

export function ReportDataSourceColumnsViewer(props: ReportDataSourceColumnsViewerProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./ReportDataSourceColumnsViewer")._ReportDataSourceColumnsViewer as typeof _ReportDataSourceColumnsViewer;
    return <C {...props} />;
}

export class _ReportDataSourceColumnsViewer {
    static defaultProps: Partial<ReportDataSourceColumnsViewerProps> = {
    }
    dataSource: ko.Observable<ObservableArrayDataSource<FilterableDataSourceColumn, string>> = ko.observable();

    constructor(private props : ReportDataSourceColumnsViewerProps) {
        useEffect(() => {
            const ds = this.props.dataSource();
            if(!ds) {
                this.dataSource(undefined);
                return;
            }

            const columns : FilterableDataSourceColumn[] = ds.Columns.map(item => ({ 
                ...item,
                filteredBy: ko.observable<string>()
            }));

            const dataSource = Table.defaultDataSource(columns, (item) => ({
                id: item.Name, 
                title: item.Name,
                subTitle: item.Description, 
                icon: { 
                    icon: this.getIcon(item) 
                }
            }));

            this.dataSource(dataSource);
        }, [this.props.dataSource]);
    }

    getIcon(column: IDataSourceColumn) : string {
        switch(column.Type)
        {
            case "String":
                return "fa fa-font";
            case "Int32":
                return "fa fa-slack";
            case "Int32Key":
                return "fa fa-key";
            case "Money":
                return "fa fa-money";
            case "MoneyEuro":
                return "fa fa-euro";
            case "DateTimeOffset":
                return "fa fa-calendar";
            case "Boolean":
                return "fa fa-check";
            default:
                return "fa fa-columns";
        }
    }
    
    render() {
        return  <>
                    <label className="control-label">Colonne disponibili</label>
                    <With data={this.dataSource}>
                        {() => this.renderColumns()}
                    </With>
                </>;
    }

    renderColumns(): React.ReactElement {
        

        return <Layout.ScrollContainer systemScrollable>
                    <List 
                        scrollable={false} 
                        className={classNames("flex-1", classes.reportDataSourceColumnViewer)} 
                        dataSource={this.dataSource()} 
                        allowedMimeTypes={["application/report-input-parameter"]}
                        onDrop={(dt, m, before) => this.handleDrop(dt, m)}
                        onlyDropOver
                        itemRenderer={(item) => this.renderItem(item)} />
                </Layout.ScrollContainer>
    }

    handleDrop(dt: DataTransfer, m: IDataSourceModel<string, FilterableDataSourceColumn, string | number, any>): void {
        const droppedInputParameter : IDataSourceInput = JSON.parse(dt.getData("application/report-input-parameter"));

        this.dataSource().array().forEach(c => c.filteredBy(undefined));
        m.model.filteredBy("@" + droppedInputParameter.Name)

        if(this.props.onColumnAssociated)
            this.props.onColumnAssociated(m.model, droppedInputParameter);
    }

    renderItem(item: ListItem<string, FilterableDataSourceColumn>): React.ReactElement {
        return  <div className="flex-container flex-child-center" data-bind={{ css: { 'filtered': !!item.Model.filteredBy() } }}>
                    <div data-bind={{ style: { backgroundColor: item.Background } }}>
                        <i data-bind={{ css: item.Icon, style: { color: item.Foreground } }} />
                    </div>
                    <div className="flex-1">
                        <div class="list-notification-item-title">
                            <span data-bind={{ text: item.Title }}></span>
                            <span style={{ marginLeft: 'auto' }} data-bind={{ text: item.Model.filteredBy }}></span>
                        </div>
                        <div class="list-notification-item-time" style={{ whiteSpace: 'pre-wrap' }} data-bind={{ text: item.Subtitle }}></div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDataSourceColumnsViewer);
}