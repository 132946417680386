import * as ko from "knockout";
import { ICurrenciesSettingsManager } from "../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../../Core/DependencyInjection";
import { TextResources } from "../ProlifeTextResources";
import { CurrencyUtils } from "./utils/CurrencyUtils";
import { IDocumentsService } from "../../Invoices/DocumentsService";
import { IBaseRowForDocument, IBaseDocumentForWizard } from "../interfaces/invoice/IInvoice";
import { ISalRowForDocuments, ISalRow } from "../interfaces/sal/ISal";
import { IDocumentDataSource, IDocumentsDataMapper, IDocumentCurrencyViewModel, IDocumentCurrency } from "../interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo, IDocumentCurrenciesInfoForWizard } from "../interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow, IDocumentRow } from "../interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../interfaces/invoice/IDocumentImportDataWizardStep";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";

export class DocumentDataSource implements IDocumentDataSource
{
    public Rows : ko.ObservableArray<any> = ko.observableArray([]);
    public mappers : IDocumentsDataMapper[] = [];
    public SupportedDestinationDocumentsTypesCodes : string[] = [];
    public destinationDocumentRowsReferences : IRefDocumentRow[];
    public initializationInfo : IWizardInitializationInfo;

    public DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;
    public DocumentCurrencySymbol: ko.Computed<string>;

    protected documentCurrenciesInfo: ko.Observable<IDocumentCurrenciesInfoForWizard> = ko.observable();

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    protected currenciesSettingsManager: ICurrenciesSettingsManager;
    @LazyImport(nameof<IDocumentsService>())
    protected documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService: IInfoToastService;
    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationsService: IAuthorizationService;

    constructor(public templateName : string, public templateUrl : string, public title: string, public viewModel : any = null)
    {
        if(!viewModel)
            this.viewModel = this;

        this.DocumentCurrency = ko.computed(() => {
            let currenciesInfo = this.documentCurrenciesInfo();

            if (!currenciesInfo)
                return null;
            
            return currenciesInfo.DocumentCurrencies().firstOrDefault(c => c.IsDocumentCurrency());
        });

        this.DocumentCurrencySymbol = ko.computed(() => {
            let currenciesInfo = this.documentCurrenciesInfo();

            if (!currenciesInfo)
                return null;
            
            return currenciesInfo.DocumentCurrencySymbol();
        });
    }

    public GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        //Implementare nelle derivate dove vengono selezionati documenti da importare
        return new Promise((resolve) => resolve([]));
    }

    public CheckInitializationInfoSupport(initializationInfo : IWizardInitializationInfo)
    {
        //Serve per verificare se il data source è valido sul set di dati di inizializzazione (se non lo è il data source viene skippato)
        //Implementare nelle specializzazioni
        return true;
    }

    public GetMapperFor(destinationTypeCode : string) : IDocumentsDataMapper
    {
        var mappers = this.mappers.filter((m : IDocumentsDataMapper) => m.DestinationEntityTypeCode == destinationTypeCode);
        return mappers.length > 0 ? mappers[0] : null;
    }

    public RegisterDestinationMapper(mapper : IDocumentsDataMapper)
    {
        this.mappers.push(mapper);
        this.SupportedDestinationDocumentsTypesCodes.push(mapper.DestinationEntityTypeCode);
    }

    public GetData(destinationTypeCode : string) : any[]
    {
        var mappers : IDocumentsDataMapper[] = this.mappers.filter((m : IDocumentsDataMapper) => m.DestinationEntityTypeCode == destinationTypeCode);
        return mappers.length > 0 ? this.Rows().map((r) => mappers[0].convertData(r, this.documentCurrenciesInfo()?.DocumentCurrencies())) : [];
    }

    public Initialize(initializationInfo: IWizardInitializationInfo, destinationDocumentRowsReferences : IRefDocumentRow[])
    {
        //Estendere nelle specializzazioni
        this.Rows([]);
        this.initializationInfo = initializationInfo;
        this.destinationDocumentRowsReferences = destinationDocumentRowsReferences;

        this.documentCurrenciesInfo(this.initializationInfo.DocumentCurrenciesInfo);
    }

    public Validate() : boolean
    {
        //Implementare nelle specializzazioni se necessario
        return true;
    }

    public HasAuthorization() : boolean
    {
        return true;
    }

    public ClearImportedRows(): void {
        this.Rows([]);
    }

    public GetCurrencyByCode(currencyCode: string): IDocumentCurrencyViewModel {
        return this.documentCurrenciesInfo().DocumentCurrencies().firstOrDefault((c) => c.Currency().CodeISO4217alpha3 === currencyCode);
    }

    public ApplyCurrenciesExchangeValues(row: IBaseRowForDocument | ISalRowForDocuments, sourceDocumentCurrency: IDocumentCurrencyViewModel): boolean {
        let destDocumentCurrency = this.DocumentCurrency();

        let destDocumentCurrencyCode = destDocumentCurrency.Currency().CodeISO4217alpha3;
        let sourceDocumentCurrencyCode = sourceDocumentCurrency.Currency().CodeISO4217alpha3;

        if (destDocumentCurrencyCode === sourceDocumentCurrencyCode)
            return true;

        let sourceToDestDocumentCurrencyExchange = this.GetCurrencyByCode(sourceDocumentCurrencyCode);

        if (!sourceToDestDocumentCurrencyExchange) {
            this.infoToastService.Warning(String.format(TextResources.Invoices.CurrencyExchangeNotConfigured, sourceDocumentCurrencyCode));
            return false;
        }

        row.Rows.forEach((r: IDocumentRow | ISalRow) => {
            let dRow = r as IDocumentRow // Nel caso di documenti diversi dal Sal
            if (dRow.Importo !== undefined) {
                let priceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.PriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                let netUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                let totalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.TotalPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                
                dRow.Importo = CurrencyUtils.applyCurrencyExchange(priceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                dRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(netUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                dRow.TotaleRiga = CurrencyUtils.applyCurrencyExchange(totalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                return;
            }

            let sRow = r as ISalRow; // Nel caso di sal
            let priceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.UnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
            let netUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
            let totalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.NetPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento

            sRow.UnitPrice = CurrencyUtils.applyCurrencyExchange(priceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(netUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetPrice = CurrencyUtils.applyCurrencyExchange(totalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        });

        row.PriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento
        row.NetUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento
        row.TotalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento

        let dRow = row as IBaseRowForDocument;
        if (dRow.Importo !== undefined) {
            dRow.Importo = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            dRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            dRow.TotaleRiga = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        } else {
            let sRow = row as ISalRowForDocuments;
            sRow.UnitPrice = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.Total = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        }

        return true;
    }

    protected async ManageCurrenciesForImportedDocuments(documents: IBaseDocumentForWizard[]): Promise<void> {
        let currenciesMap = {};
        documents
            .filter((d) => d.Rows().filter(r => r.Selected()).length > 0)
            .forEach((fd) => currenciesMap[fd.CurrencyCode()] = fd.CurrencyCode());

        let documentCurrenciesInfo = this.documentCurrenciesInfo();
        let configuredCurrencies = documentCurrenciesInfo.DocumentCurrencies();
        let missingCurrenciesCounter = 0;
        for (let currencyCode in currenciesMap) {
            if (configuredCurrencies.filter((c) => c.Currency().CodeISO4217alpha3 === currencyCode).length === 0) {
                let documentCurrency = this.createDocumentCurrencyViewModel(currencyCode);
                documentCurrenciesInfo.DocumentCurrencies.push(documentCurrency);
                missingCurrenciesCounter++;
            }
        }

        if (missingCurrenciesCounter === 0)
            return;

        await this.dialogsService.ShowModalComponent({
            componentName: "document-currencies-manager",
            model: {
                DocumentCurrencies: documentCurrenciesInfo.DocumentCurrencies,
                ReadOnly: documentCurrenciesInfo.ReadOnly,
                CurrenciesEditor: ko.observable(),

                close: function() {
                    let editor = this.CurrenciesEditor();

                    if (!editor)
                        return;

                    if (editor.validate())
                        this.modal.close(null);
                }
            },
            title: TextResources.Invoices.CurrenciesLabel,
            params: "DocumentCurrencies: DocumentCurrencies, ReadOnly: ReadOnly, InjectTo: CurrenciesEditor"
        }, undefined, { saveText: TextResources.ProlifeSdk.Apply, noPrompt: true });
    }

    private createDocumentCurrencyViewModel(currencyCode: string): IDocumentCurrencyViewModel {
        let model = this.createDocumentCurrencyModel(currencyCode);
        return this.documentsService.DocumentCurrenciesFactory.createFromModel(model);
    }

    private createDocumentCurrencyModel(currencyCode: string): IDocumentCurrency {
        let currencyModel = this.documentsService.DocumentCurrenciesFactory.createModel(null, this.initializationInfo.DocTypeCode);
        currencyModel.ExchangeValue = null;
        currencyModel.DocumentCurrency = false;

        let currency = this.currenciesSettingsManager.getCurrencyByCode(currencyCode);
        currencyModel.CurrencyId = currency.Id;

        return currencyModel;
    }
}