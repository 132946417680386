import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { ICustomersService, ICustomerForList } from "../ProlifeSdk/interfaces/customer/ICustomersService";

export class UngroupedCustomersDataSource implements IDataSource {
    private getCustomers: boolean = true;
    private getSuppliers: boolean = true;
    
    private getItalianCustomers: boolean = true;
    private getForeignCustomers: boolean = true;

    private view: IDataSourceView;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    constructor() {

    }

    public getTitle(currentModel: IDataSourceModel<number, ICustomerForList>): string {
        return ProlifeSdk.TextResources.Customers.Contacts;
    }

    public async getData(currentModel: IDataSourceModel<number, ICustomerForList>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, ICustomerForList>[]> {
        let customers = await this.customersService.GetCustomersForList(textFilter, skip, count, this.getCustomers, this.getSuppliers, this.getItalianCustomers, this.getForeignCustomers);
        return customers.map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IDataSourceModel<number, ICustomerForList>, ids: number[]): Promise<IDataSourceModel<number, ICustomerForList>[]> {
        let customer = await this.customersService.GetCustomerForList(ids.firstOrDefault());
        return !customer ? [] : [this.createModelFor(customer)];
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setGetCustomers(value: boolean): void {
        this.getCustomers = value;
    }

    public setGetSuppliers(value: boolean): void {
        this.getSuppliers = value;
    }

    public setGetItalianCustomers(value: boolean): void {
        this.getItalianCustomers = value;
    }

    public setGetForeignCustomers(value: boolean): void {
        this.getForeignCustomers = value;
    }
    
    public isGroupedData(currentModel: IDataSourceModel<number, ICustomerForList>, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IDataSourceModel<number, ICustomerForList>, b: IDataSourceModel<number, ICustomerForList>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(customer: ICustomerForList): IDataSourceModel<number, ICustomerForList> {
        return {
            id: customer.Id,
            title: customer.Name,
            isGroup: false,
            isLeaf: true,
            model: customer
        }
    }
}