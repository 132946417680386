import * as ko from "knockout";
/**
 * Created by d.bucchi on 09/06/2017.
 */
export class TransitionInOut {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var pageName = allBindingsAccessor()["transitionInOutPage"];

        var model = ko.utils.unwrapObservable(valueAccessor());
        if (model.isVisible(pageName))
            $(element).show();
        else
            $(element).hide();

        model.observeChanges(pageName, TransitionInOut.transition.bind(null, element, pageName, model));
    }

    static transition(element: any, pageName: string, model: any, visible: boolean): void {
        var width = $(element).width();
        if (model.toNextPage(pageName)) {
            if (visible) {
                $(element).show();
                $(element).css("transition", "");
                $(element).css("transform", "translate(" + width + "px, 0px)");

                setTimeout(() => {
                        $(element).css("transition", "0.5s");
                        $(element).css("transform", "translate(0px, 0px)");

                        setTimeout(() => {
                                $(element).css("transition", "");
                            }, 500);
                    }, 0);
            } else {
                $(element).css("transition", "0.5s");
                $(element).css("transform", "translate(-" + width + "px, 0px)");
                setTimeout(() => {
                        $(element).css("transition", "");
                        $(element).hide();
                    }, 500);
            }
        } else if(!model.toNextPage(pageName)){
            if (visible) {
                $(element).show();
                $(element).css("transition", "");
                $(element).css("transform", "translate(-" + width + "px, 0px)");

                setTimeout(() => {
                    $(element).css("transition", "0.5s");
                    $(element).css("transform", "translate(0px, 0px)");

                    setTimeout(() => {
                        $(element).css("transition", "");
                    }, 500);
                }, 0);
            } else {
                $(element).css("transition", "0.5s");
                $(element).css("transform", "translate(" + width + "px, 0px)");
                setTimeout(() => {
                    $(element).css("transition", "");
                    $(element).hide();
                }, 500);
            }
        }
    }
}

ko.bindingHandlers["transitionInOut"] = new TransitionInOut();