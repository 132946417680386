import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Right } from "../../../../Core/Authorizations";
import { IHumanResourceForList } from "../../../../Users/HumanResourcesService";
import { HumanResourcesDataSource } from "../../../../DataSources/HumanResourcesDataSource";
import "./Resource";
import jss from "jss";
import { IResourceListener, Resource } from "./Resource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";

const { classes } = jss.createStyleSheet({
    TypedResourceManager: {
        "& .resource-item": {
            marginTop: '-10px',
            marginBottom: '-10px'
        }
    }
}).attach();

let attributes = {
    ResourceType: "resourceType"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "typed-resource-manager": {
               params?: {
                   ResourceType: string;
               };
               "resourceType": number | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ITypedResourceManagerParams {
    ResourceType: Param<number>;
}

export class TypedResourceManager implements IDataSourceListener, IResourceListener {
    TextFilter: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });
    ShowDeleted : ko.Observable<boolean> = ko.observable();
    ShowDisabled : ko.Observable<boolean> = ko.observable();

    SelectedResource : ko.Observable<IHumanResourceForList> = ko.observable();
    SelectedResourceViewModel : ko.Observable<Resource> = ko.observable();

    HumanResourcesDataSource: HumanResourcesDataSource = new HumanResourcesDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager : IHumanResourcesSettingsManager;

    @Right("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager")
    CanShowDeleted : boolean;
    resourceType: number;

    constructor(params: ITypedResourceManagerParams) {
        this.resourceType = ComponentUtils.parseParameter(params.ResourceType, 0)()
        this.HumanResourcesDataSource.setResourceType(this.resourceType);

        let firstLoad = true;
        const searchFlagsInterceptor = ko.computed(() => {
            this.HumanResourcesDataSource.setShowDeleted(this.ShowDeleted());
            this.HumanResourcesDataSource.setShowDisabled(this.ShowDisabled());
            
            if(!firstLoad)
                this.HumanResourcesDataSource.refresh();

            firstLoad = false;
        });

        this.CreateNew();
    }

    OnResourceChanged(sender: Resource): void {
        this.HumanResourcesDataSource.refresh(true);
        this.humanResourcesSettingsManager.load();
    }

    OnResourceDeleted(sender: Resource): void {
        this.HumanResourcesDataSource.refresh(true);
        this.humanResourcesSettingsManager.load();
    }

    async canSelectItem?(sender : IDataSource, model : IDataSourceModel): Promise<boolean> {
        let currentResource = this.SelectedResourceViewModel();
        if(currentResource && currentResource.IsChanged()) {
            return await this.dialogsService.ConfirmAsync(TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit, TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel, TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm);
        }
        return true;
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        this.SelectedResource(model?.model);
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    public CreateNew() {
        this.SelectedResource({
            Id: -1,
            Deleted: false,
            Disabled: false,
            FkDefaultCharacter: null,
            ResourceType: this.resourceType,
            ShowPreferred: false,
            FkUser: null,
            Name: "",
            SerialNumber: "",
            Surname: ""
        })
    }
}

ko.components.register("typed-resource-manager", {
    viewModel: {
        createViewModel: (params: ITypedResourceManagerParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new TypedResourceManager(params);
            let Model: IHumanResourceForList;
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={classes.TypedResourceManager + " row"} data-as={{vm}}>
                    <div class="col-md-4">
                        <div class="portlet box red">
                            <div class="portlet-title">
                                <div class="caption search">
                                    <div class="input-icon input-inline input-icon-small input-transparent">
                                        <i class="fa fa-search"></i>
                                        <input placeholder={TextResources.ResourcesManager.SearchResources} type="text" class="form-control input-sm" data-bind={{ value: vm.TextFilter, valueUpdate: 'afterkeydown'}} />
                                    </div>
                                </div>
                                <div class="actions flex-container">
                                    <ko-bind data-bind={{ if: vm.CanShowDeleted }}>
                                        <a href="#" class="btn default btn-sm" data-bind={{ toggle: vm.ShowDeleted, css : { 'default' : !vm.ShowDeleted(), 'btn-primary' : vm.ShowDeleted } }}>
                                            <i class="fa fa-times"></i>&nbsp;
                                            {TextResources.ResourcesManager.Deleted}
                                        </a>
                                    </ko-bind>
                                    <a href="#" class="btn default btn-sm" data-bind={{ toggle: vm.ShowDisabled, css : { 'default' : !vm.ShowDisabled(), 'btn-primary' : vm.ShowDisabled } }}>
                                        <i class="fa fa-ban"></i>&nbsp;
                                        {TextResources.ResourcesManager.Disabled}
                                    </a>
    
                                    <a href="#" class="btn default btn-sm" data-bind={{ click: vm.CreateNew }}>
                                        <i class="fa fa-plus"></i>&nbsp;
                                        {TextResources.ResourcesManager.New}
                                    </a>
                                </div>
                            </div>
                            <div class="portlet-body" style="padding: 0">
                                <list dataSource={() => "vm.HumanResourcesDataSource"} listener={() => "$data"} sortable={false} allowNoSelection={true} containerHeight="700px" textFilter={() => "vm.TextFilter"} emptyResultDefaultMessage={TextResources.ResourcesManager.NoConfiguredResource}>
                                    <a class="list-group-item resource-item" href="#" data-bind="click: Select">
                                        <h4 data-bind="text: Title, style : { 'text-decoration' : Model.Disabled || Model.Deleted ? 'line-through' : 'none', 'color' :  Model.Deleted ? 'red' : 'auto', 'font-weight' :  Model.Deleted ? 'bold' : 'auto' }"></h4>
                                    </a>
                                </list>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8" data-bind={{ with: vm.SelectedResource }}>
                        <resource-editor human-resource={() => "vm.SelectedResource"} listener={() => "vm"} injectTo={() => "vm.SelectedResourceViewModel"}></resource-editor>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});

