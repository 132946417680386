import { gql } from "@apollo/client/core";
import { DataSourceConfiguration } from "../Components/ReportDesigner/Editors/DataSourceEditor/DataSourceEditingDialog";
import { LazyImport, Service, useService } from "../Core/DependencyInjection";
import { IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    GetAllQueryTypes,
    GetAllQueryTypesQuery,
    GetAllQueryTypesQueryVariables,
    GetTypeSchema,
    GetTypeSchemaQuery,
    GetTypeSchemaQueryVariables,
    __TypeKind,
} from "../generated/graphql";

export interface IDataSourceInput {
    Name: string;
    Description: string;
    EntityType: string;
    DataType: string;
}

export interface IDataSourceInputWithValue extends IDataSourceInput {
    Value: any;
}
export interface IDataSourceColumn {
    Name: string;
    Description: string;
    Type: "Int32" | "Int32Key" | "String" | "Boolean" | "DateTimeOffset" | "Money" | "MoneyEuro" | "Unknown";
    Size: number;
    Nullable: boolean;
}

export interface IDataSourceDefinition {
    Id: number;
    Name: string;
    Description: string;
    Columns: IDataSourceColumn[];
}

export interface IDataSourcesService extends IService {
    getDataSources(inputParameters: IDataSourceInput[]) : IDataSourceDefinition[];
    getRow(
        dataSource: DataSourceConfiguration,
        ...inputParameters: Pick<IDataSourceInputWithValue, "Name" | "Value">[]
    ): Promise<any>;
}

const getType = (fieldName: string, type: string) => {
    const isKey =
        fieldName.toLowerCase().startsWith("fk") ||
        fieldName.toLowerCase().startsWith("id") ||
        fieldName.toLowerCase().endsWith("id");
    const inCurrency = fieldName.toLowerCase().indexOf("indocumentcurrency") !== -1;
    switch (type) {
        case "Int":
            return isKey ? "Int32Key" : "Int32";
        case "String":
            return "String";
        case "Boolean":
            return "Boolean";
        case "DateTime":
            return "DateTimeOffset";
        case "Decimal":
            return inCurrency ? "Money" : "MoneyEuro";
        default:
            return "Unknown";
    }
};

@Service(nameof<IDataSourcesService>())
class DataSourcesService implements IDataSourcesService {
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxService : IAjaxServiceNew;

    private dataSources: IDataSourceDefinition[];

    getServiceType() : string {
        return nameof<IDataSourcesService>();
    }

    isOfType(serviceType: string) : boolean {
        return serviceType === this.getServiceType();
    }

    private async loadDataSources() {
        const queryTypes = await this.ajaxService.Query<GetAllQueryTypesQuery, GetAllQueryTypesQueryVariables>({
            query: GetAllQueryTypes,
            variables: {},
        });

        const dataSources = [];
        let index = 1;

        for(const type of queryTypes.data.__schema.queryType.fields) {
            const dataSource : IDataSourceDefinition = {
                Columns: [],
                Description: type.description ?? "Descrizione non disponibile",
                Id: index++,
                Name: type.name,
            };

            if (!type.type.fields || type.type.fields.length === 0) continue;

            const typeSchema = await this.ajaxService.Query<GetTypeSchemaQuery, GetTypeSchemaQueryVariables>({
                query: GetTypeSchema,
                variables: { type: type.type.fields[0].type.ofType.name },
            });

            if (!typeSchema.data.__type.fields) continue;

            for(const field of typeSchema.data.__type.fields) {
                dataSource.Columns.push({
                    Name: field.name,
                    Description: field.description ?? "Descrizione non disponibile",
                    Nullable: field.type.kind !== __TypeKind.NonNull,
                    Size: null,
                    Type: getType(field.name, field.type.name ?? field.type.ofType.name),
                });
            }

            dataSources.push(dataSource);
        }

        this.dataSources = dataSources;
    }

    InitializeService() : void {
        this.loadDataSources();
    }

    getDataSources(inputParameters: IDataSourceInput[]) : IDataSourceDefinition[] {
        return this.dataSources.slice();
    }

    async getRow(
        dataSource: DataSourceConfiguration,
        ...inputParameters: Pick<IDataSourceInputWithValue, "Name" | "Value">[]
    ): Promise<any> {
        const foundDataSource = this.dataSources.firstOrDefault((d) => d.Name === dataSource.name);
        const allColumns = foundDataSource.Columns.map((c) => c.Name + "\r\n");
        const filters =
            inputParameters.length === 0
                ? ""
                : "(where: { " + inputParameters.map((i) => `${i.Name}: { eq: ${i.Value} }`) + "})";
        
        const fetchQuery = gql(`
            query {
                ${dataSource.name}${filters} {
                    ${allColumns}
                }
            }
        `);

        const result = await this.ajaxService.Query<any, any>({
            query: fetchQuery,
            variables: {},
        });

        return result.data.items[0];
    }
}

export default function Create() : IService {
    return useService(nameof<IDataSourcesService>());
}