import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { Layout } from "../Layouts";
import { ReportDesignerEditorBar } from "./ReportDesignerEditorBar";
import { ReportComponent, ReportPage } from "./Components";
import TsxForEach from "../ForEach";
import { ReportDesignerToolbar } from "./ReportDesignerToolbar";
import { ReportSectionRenderer } from "./Renderers";
import jsPDF from "jspdf";
import { ReportExporterProvider } from "./Exporters";
import { ReportDesignerOutline } from "./ReportDesignerOutline";
import { ReportSection, ReportSectionProps } from "./Components/ReportSection";
import { ReportDataSourceInput } from "./Editors/DataSourceEditor/ReportDataSourceInput";
import { IDataSourceInput } from "../../Desktop/DataSourcesService";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

type ReportDesignerProps = {
    configuration?: { sections: ReportSectionProps[] },
    inputDefinition: IDataSourceInput[];
    style?: CSSProperties;
    canImport?: boolean;
    canSaveToFile?: boolean;
    forwardRef?: (rd: _ReportDesigner) => void;
}

export function ReportDesigner(props: ReportDesignerProps) {
    const C = require("./ReportDesigner")._ReportDesigner as typeof _ReportDesigner;
    return <C {...props} />;
}

type EditListener = (pageParent: ReportPage) => void;
export class _ReportDesigner {
    static defaultProps: Partial<ReportDesignerProps> = {
        configuration: { sections: [{ type: nameof<ReportSection>(), id: -1, name: "Sezione 1", children: [{ type: nameof<ReportPage>(), name: "Pagina 1", id: -1, x: 0, y: 0, width: 21, height: 29.7 }] }] },
        canImport: false,
        canSaveToFile: false
    }

    selectedComponent : ko.Observable<ReportComponent> = ko.observable();
    sections: ko.ObservableArray<ReportSection> = ko.observableArray();
    private editListeners: WeakMap<ReportComponent, EditListener[]> = new WeakMap();

    private clipboard: string;

    constructor(private props : ReportDesignerProps) {
        if(!props.configuration || !props.configuration.sections || props.configuration.sections.length === 0)
            props.configuration = Object.assign({}, _ReportDesigner.defaultProps.configuration);

        this.load(props.configuration);

        if(props.forwardRef)
            props.forwardRef(this);
    }

    setClipboard(value: string) {
        this.clipboard = value;
    }

    getClipboard() {
        return this.clipboard;
    }

    getInputDefinition(): IDataSourceInput[] {
        return this.props.inputDefinition;
    }

    async exportToPdf() {
        const doc = new jsPDF("p", "cm", "a4", true);
        doc.deletePage(1);

        for(let page of this.sections()) {
            const exporter = ReportExporterProvider.createExporter(page);
            await exporter.export(doc, page);
        }
        
        doc.save("testPDF.pdf");
    }

    getData() {
        return {
            sections: this.sections().map(p => p.getData())
        }
    }

    load(data: { sections: ReportSectionProps[] }) {
        const sections : ReportSection[] = [];
        
        for(const sectionData of data.sections) {
            sections.push(new ReportSection(sectionData));
        }

        this.selectedComponent(undefined);
        this.sections(sections);
    }

    getChildrenNames() {
        const allNames = [];

        for(const section of this.sections()) {
            allNames.push(...section.getChildrenNames());
        }

        return allNames;
    }

    public on(event: "edit", component: ReportComponent, listener: EditListener) {
        if(!this.editListeners.has(component))
            this.editListeners.set(component, []);
        const listeners = this.editListeners.get(component);
        listeners.push(listener);
        this.editListeners.set(component, listeners);
    }

    private onEdit(component: ReportComponent, page: ReportPage) {
        if(!this.editListeners.has(component))
            return;
        const listeners = this.editListeners.get(component);
        for(const listener of listeners)
            listener(page);
    }
    
    render() {
        return <Layout.Grid columns={["auto", "1fr", "auto"]} rows={["50px", "1fr"]} style={this.props.style}>
            <Layout.Grid.Cell column={"2/4"} row={1}>
                <ReportDesignerToolbar designer={this} canImport={this.props.canImport} canSaveToFile={this.props.canSaveToFile} />
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={1} row={"1/3"}>
                <ReportDesignerEditorBar component={this.selectedComponent} reportDesigner={this} />
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={"2/3"} row={2}>
                <Layout.ScrollContainer className="report-designer-design-area no-gutters" style={{ backgroundColor: '#888888', padding: '0px' }} systemScrollable>
                    <TsxForEach data={this.sections}>
                        {(section) => <ReportSectionRenderer section={section} reportDesigner={this} onEdit={(c, p) => this.onEdit(c, p)} />}
                    </TsxForEach>
                </Layout.ScrollContainer>
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={3} row={"2/3"}>
                <ReportDesignerOutline reportDesigner={this} />
            </Layout.Grid.Cell>
        </Layout.Grid>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportDesigner);
}