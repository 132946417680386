import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/08/2017
 * Time: 16:46
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IQuestionnaireCategorySettingManager } from "../../../../interfaces/IQuestionnaireCategorySettingManager";
import { ICategory } from "../../../../interfaces/ICategory";

export class QuestionnaireCategoriesControlsEntityProvider implements IControlsEntityProvider {
    private categoryManager: IQuestionnaireCategorySettingManager;
    private lastTimeout: number;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.categoryManager = <IQuestionnaireCategorySettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireCategory);
    }

    public findEntities(query : any)
    {
        var searchFilter : string = query.term || "";

        var categories: ICategory[] = this.categoryManager.getQuestionnaireCategories()
        var filteredCategories: ICategory[] = categories.filter((s: ICategory) => {
            s.Description.toUpperCase().indexOf(searchFilter.trim().toUpperCase()) > -1;
        });

        query.callback({
            results: filteredCategories.map((s : ICategory) => {
                return {
                    id: s.Id,
                    text: s.Description
                };
            })
        });
    }

    public findEntity(element, callback) {
        var id = parseInt(<string> $(element).val());
        if(!isNaN(id) && id > 0)
        {
            var c: ICategory = this.categoryManager.getQuestionnaireCategoryById(id);

            callback({
                id: c.Id,
                text: c.Description
            });
        }
    }
}