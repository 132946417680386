import jsPDF, { GState } from "jspdf";
import { Deferred } from "../../../Core/Deferred";
import { useService } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ReportComponentWithBackground } from "../Components/ReportComponentWithBackground";
import { ParseColor } from "../Utilities/ColorUtils";
import { CentimetersToPixels } from "../Utilities/UnitOfMeasureUtils";

function drawImageContain(ctx: CanvasRenderingContext2D, img: HTMLImageElement, containerWidth: number, containerHeight: number) {
    const contentWidth = img.width as number;
    const contentHeight = img.height as number;

    const contentRatio = contentWidth / contentHeight;
    const containerRatio = containerWidth / containerHeight;
    let resultHeight: number;
    let resultWidth: number;
    
    if (contentRatio < containerRatio) {
        resultHeight = containerHeight;
        resultWidth = containerHeight * contentRatio;
    }
    else {
        resultWidth = containerWidth;
        resultHeight = containerWidth / contentRatio;
    }

    const newCanvas = document.createElement("canvas");
    newCanvas.width = resultWidth;
    newCanvas.height = resultHeight;
    const newCtx = newCanvas.getContext("2d");
    newCtx.drawImage(img, 0, 0, contentWidth, contentHeight, 0, 0, resultWidth, resultHeight);
    
    const patt = ctx.createPattern(newCanvas, "repeat");
    ctx.fillStyle = patt;
    ctx.fillRect(0, 0, containerWidth, containerHeight);
}

function drawImageProp(ctx: CanvasRenderingContext2D, img: CanvasImageSource, x: number = 0, y: number = 0, w: number = ctx.canvas.width, h: number = ctx.canvas.height, offsetX: number = 0.5, offsetY: number = 0.5) {

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    let iw = img.width as number,
        ih = img.height as number,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx: number, cy: number, cw: number, ch: number, ar = 1;

    // decide which gap to fill    
    if (Math.round(nw) < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && Math.round(nh) < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}

export async function ExportBackground(doc: jsPDF, component: ReportComponentWithBackground, x: number, y: number, w: number, h: number) : Promise<void> {
    if(component.backgroundImage()) {
        doc.saveGraphicsState();

        const def = new Deferred();
        const img = new Image();
        img.onload = () => {
            def.resolve();
        }
        img.src = component.backgroundImageUrl();
        await def.promise();

        const cW = CentimetersToPixels(w);
        const cH = CentimetersToPixels(h);

        const canv = document.createElement("canvas");
        canv.width = cW;
        canv.height = cH;
        const context = canv.getContext("2d");

        if(component.backgroundSize() === "auto") {
            const patt = context.createPattern(img, "repeat");
            context.fillStyle = patt;
            context.fillRect(0, 0, cW, cH);
        } else if(component.backgroundSize() === "contain") {
            drawImageContain(context, img, cW, cH);
        } else if(component.backgroundSize() === "cover") {
            drawImageProp(context, img, 0, 0, cW, cH, 0, 0);
        }

        doc.addImage(canv, "JPEG", x, y, w, h, component.backgroundImage().Id);

        doc.restoreGraphicsState();
    } else if(component.backgroundColor()) {
        doc.saveGraphicsState();
        const color = ParseColor(component.backgroundColor());
        doc.setGState(new GState({ opacity: color.a }))
        doc.setFillColor(color.r, color.g, color.b).rect(x, y, w, h, "F");
        doc.restoreGraphicsState();
    }
}