import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import * as moment from "moment";

type DocumentExternalReferenceProps = {
    externalReference: string;
    referenceNumber: string;
    referenceDate: Date;
}

export class DocumentExternalReferenceInfo {
    static defaultProps: Partial<DocumentExternalReferenceProps> = {
    }

    constructor(private props : DocumentExternalReferenceProps) {
        
    }
    
    render() {
        return (
            <div style={{ width: "100%" }}>
                <span>{this.props.referenceNumber ?? TextResources.ProlifeSdk.NotAvailable}</span>
                <small style={{ display: 'block', marginTop: '-5px' }} className="text-muted text-ellipsis">
                    {String.format(TextResources.Invoices.ExternalReferenceDescr, (this.props.externalReference ?? TextResources.ProlifeSdk.NotAvailable), (!this.props.referenceDate ? TextResources.ProlifeSdk.NotAvailable : moment(this.props.referenceDate).format("L")))}
                </small>
            </div>
        );
    }
}