import * as ProlifeSdk from "./ProlifeSdk";
import { DatesUtilities } from "./prolifesdk/utils/DatesUtilities";
import { NumbersUtilities } from "./prolifesdk/utils/NumbersUtilities";
import { CanvasUtilities } from "./prolifesdk/utils/CanvasUtilities";
import { ListsUtilities } from "./prolifesdk/utils/ListsUtilities";
import { StringUtilities } from "./prolifesdk/utils/StringUtilities";
import { ProLifeUiFactory } from "./prolifesdk/ProLifeUiFactory";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IService } from "../Core/interfaces/IService";
import { IProLifeSdkService, IProLifeTextTranslation } from "./interfaces/prolife-sdk/IProlifeSdkService";
import { IProLifeUtilitiesLibrary } from "./interfaces/prolife-sdk/IProLifeUtilitiesLibrary";
import { IProLifeUiFactory } from "./interfaces/prolife-sdk/IProLifeUiFactory";

export class ProlifeSdkService implements IProLifeSdkService
{
    public Utilities : IProLifeUtilitiesLibrary;
    public Ui: IProLifeUiFactory;

    constructor(serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IProLifeSdkService>(), this);
        
        this.Utilities = <IProLifeUtilitiesLibrary>{
            Dates : new DatesUtilities(),
            Numbers : new NumbersUtilities(),
            HtmlCanvas : new CanvasUtilities(),
            Lists : new ListsUtilities(),
            String : new StringUtilities()
        };

        this.Ui = new ProLifeUiFactory();

        /*var localizationService : ILocalizationService = <ILocalizationService> serviceLocator.findService(ServiceTypes.Localization);
        localizationService.getClientLabelsTranslations()
            .then((translations : IProLifeTextTranslation[]) => {
                this.LoadTextTranslations(translations);
            });*/
    }

    public getServiceType(): string
    {
        return ProlifeSdk.ProlifeSdkServiceType;
    }

    public isOfType(serviceType: string): boolean
    {
        return this.getServiceType() == serviceType;
    }

    public LoadTextTranslations(translations : IProLifeTextTranslation[]) : void
    {
        for(var module in ProlifeSdk.TextResources)
        {
            for(var key in ProlifeSdk.TextResources[module])
            {
                var matches : IProLifeTextTranslation[] = translations.filter((t : IProLifeTextTranslation) => { return t.ViewPath == module.toString() && t.Key == key.toString(); });

                if(matches.length > 0)
                    ProlifeSdk.TextResources[module][key] = matches[0].Value;
            }
        }

    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ProlifeSdkService(serviceLocator);
}

