import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "./ImportDocumentDataWizard";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IDiscountsCatalogsService,
    IResolvedCustomerDiscountCatalogArticleWithCatalog,
    DiscountCatalogRowMode,
} from "../../../../Warehouse/DiscountsCatalogsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import {
    IArticlesService,
    IArticleTransformComponent,
    IArticleRow,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { IDiscountsService } from "../../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import {
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRelatedWorkflows,
} from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class ImportDocumentDataWizardTransformStep extends DocumentDataWizardStep {
    CanImportRows: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IArticlesService>())
    protected articlesService: IArticlesService;
    @LazyImport(nameof<IDiscountsCatalogsService>())
    protected discountsCatalogsService: IDiscountsCatalogsService;
    @LazyImport(nameof<IDiscountsService>())
    protected discountsService: IDiscountsService;

    private noTransformsRows: IDocumentDataWizardRow[] = [];

    constructor() {
        super();
        this.Title(TextResources.Warehouse.Transforms);
        this.IsVisible(false);
        this.IsSelected(true);
        this.CanBeFastForwarded = true;
    }

    async OnShow(previousStepRows: IDocumentDataWizardRow[]): Promise<void> {
        await this.initializeRows(previousStepRows);
    }

    private async initializeRows(previousStepRows: IDocumentDataWizardRow[]): Promise<void> {
        const transformRows = [];

        for (const row of previousStepRows) {
            if (await this.isTransformRow(row)) {
                transformRows.push(row);
            } else {
                this.noTransformsRows.push(row);
            }
        }

        this.Rows(transformRows);
        this.clear();
    }

    private async isTransformRow(row: IDocumentDataWizardRow): Promise<boolean> {
        if (!this.isArticleRow(row)) return false;

        const articleId = this.getArticleIdFromRow(row);
        const transform = await this.articlesService.GetArticleTransforms(articleId);

        return !!transform;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const articlesRows = !this.ProcessedRows().length ? this.Rows() : this.ProcessedRows();
        return articlesRows.concat(this.noTransformsRows);
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    private clear() {
        this.ProcessedRows([]);
        this.CanImportRows(true);
    }

    private async importRows(
        components: IArticleTransformComponent[],
        construction: boolean,
        multiplier: number,
        recursive: boolean
    ) {
        const articleIds = components.map((c) => c.ComponentArticleId);
        const articles = await this.articlesService.GetArticlesByIds(articleIds);

        const rows = [];

        for (const r of articles) {
            const component = components.firstOrDefault((c) => c.ComponentArticleId == r.ArticleId);

            if (recursive) {
                const transform = await this.articlesService.GetArticleTransforms(component.ComponentArticleId);

                if (transform) {
                    this.importRows(
                        construction ? transform.Construction : transform.Destruction,
                        construction,
                        multiplier * component.Amount,
                        recursive
                    );
                } else {
                    const newRow = Object.assign({}, r) as unknown as IArticleRow;
                    const discounts = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
                        articleId: r.ArticleId,
                        customerId: this.initializationInfo.CustomerId,
                        isCustomer: this.initializationInfo.IsCustomer,
                        overrideGroupId: this.initializationInfo.OverrideDiscountGroupId,
                        referenceDate: this.initializationInfo.DocumentDate,
                        warehouseId: this.initializationInfo.SourceWarehouseId,
                    });

                    newRow.Amount = component.Amount * multiplier;
                    newRow.Discounts = this.computeDiscount(discounts);

                    rows.push(newRow);
                }
            } else {
                const newRow = Object.assign({}, r) as unknown as IArticleRow;
                const discounts = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
                    articleId: r.ArticleId,
                    customerId: this.initializationInfo.CustomerId,
                    isCustomer: this.initializationInfo.IsCustomer,
                    overrideGroupId: this.initializationInfo.OverrideDiscountGroupId,
                    referenceDate: this.initializationInfo.DocumentDate,
                    warehouseId: this.initializationInfo.SourceWarehouseId,
                });

                newRow.Amount = component.Amount * multiplier;
                newRow.Discounts = this.computeDiscount(discounts);

                rows.push(newRow);
            }
        }

        this.mergeRows(rows);
    }

    private computeDiscount(result: IResolvedCustomerDiscountCatalogArticleWithCatalog) {
        if (DiscountCatalogRowMode.getDiscountSign(result.Mode) > 0) {
            //E' un ricarico quindi non mostro il ricarico nel documento
            return undefined;
        } else {
            const discountString = (
                (result.Discount0 > 0 ? result.Discount0 + "% " : "") +
                (result.Discount1 > 0 ? result.Discount1 + "% " : "") +
                (result.Discount2 > 0 ? result.Discount2 + "% " : "") +
                (result.Discount3 > 0 ? result.Discount3 + "% " : "") +
                (result.Discount4 > 0 ? result.Discount4 + "% " : "")
            ).trim();

            return discountString;
        }
    }

    public async importSelectedConstructionRows() {
        this.CanImportRows(false);

        for (const row of this.Rows()) {
            if (this.isArticleRow(row)) {
                const articleId = this.getArticleIdFromRow(row);
                const transform = await this.articlesService.GetArticleTransforms(articleId);
                if (transform) this.importRows(transform.Construction, true, row.Row.Amount(), true);
                else this.mergeDocumentRow(row);
            } else {
                this.mergeDocumentRow(row);
            }
        }
    }

    public async importSingleConstructionRows() {
        this.CanImportRows(false);

        for (const row of this.Rows()) {
            if (this.isArticleRow(row)) {
                const articleId = this.getArticleIdFromRow(row);
                const transform = await this.articlesService.GetArticleTransforms(articleId);
                if (transform) this.importRows(transform.Construction, true, row.Row.Amount(), false);
                else this.mergeDocumentRow(row);
            } else {
                this.mergeDocumentRow(row);
            }
        }
    }

    private mergeDocumentRow(row: IDocumentDataWizardRow) {
        if (this.isArticleRow(row)) {
            const articleId = this.getArticleIdFromRow(row);
            const existingRows = this.ProcessedRows().filter(this.findByArticleId.bind(this, articleId));
            if (existingRows.length == 0) this.ProcessedRows.push(this.createDestinationRowFromDocumentRow(row));
            else this.mergeWithExistingRow(this.getDataRow(existingRows[0]), row.Row.Amount());
        } else {
            this.ProcessedRows.push(this.createDestinationRowFromDocumentRow(row));
        }
    }

    private mergeRows(articles: IArticleRow[]) {
        articles.forEach((a: IArticleRow) => {
            const filteredRows = this.ProcessedRows().filter(this.findByArticleId.bind(this, a.ArticleId));
            if (filteredRows.length == 0) {
                this.ProcessedRows.push(this.createDestinationRow(a));
            } else {
                const firstExistingRow = filteredRows[0];
                this.mergeWithExistingRow(this.getDataRow(firstExistingRow), a.Amount);
            }
        });
    }

    protected getDataRow(processedRow: any): IDocumentDataWizardRow {
        return processedRow;
    }

    private mergeWithExistingRow(existingRow: IDocumentDataWizardRow, newAmount: number) {
        const finalAmount = existingRow.Row.Amount() + newAmount;

        const artReference = existingRow.OriginatingRows[0];
        artReference.Amount = finalAmount;
        artReference.NetPrice = finalAmount * artReference.NetUnitPrice;

        existingRow.Row.Amount(finalAmount);
        existingRow.Row.TotalPrice(artReference.NetPrice);
        existingRow.Row.TotalPriceInDocumentCurrency(artReference.NetPrice);
    }

    protected createDestinationRow(row: IArticleRow): IDocumentDataWizardRow {
        const articleReference: IDocumentBuilderDocumentOriginatingRows = {
            RefId: this.documentsService.getFakeId(),
            SourceEntityKeyId: row.ArticleId,
            SourceEntityType: ProlifeSdk.WarehouseArticleEntityTypeCode,
            DestEntityKeyId: null,
            DestEntityType: this.initializationInfo.DocTypeCode,
            CatalogId: row.CatalogId,
            Amount: row.Amount,
            UnitPrice: row.CustomerPrice,
            Discounts: row.Discounts,
            NetUnitPrice: row.CustomerPrice * this.discountsService.calculateDiscount(row.Discounts),
            NetPrice: row.CustomerPrice * this.discountsService.calculateDiscount(row.Discounts) * row.Amount,
            WarehouseId: this.initializationInfo.SourceWarehouseId,
            DocumentId: null,
        };

        return {
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: null,
                    FKDocument: null,
                    Description: row.Code + " " + row.Description,
                    Amount: row.Amount,
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency().CurrencyId(),
                    Currency: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrencySymbol(),
                    ManuallyClosed: false,
                    NetUnitPrice: articleReference.NetUnitPrice,
                    NetUnitPriceInDocumentCurrency: articleReference.NetUnitPrice,
                    Order: 0,
                    TotalPrice: articleReference.NetPrice,
                    TotalPriceInDocumentCurrency: articleReference.NetPrice,
                    UnitPrice: row.CustomerPrice,
                    UnitPriceInDocumentCurrency: row.CustomerPrice,
                    Discounts: row.Discounts,
                },
                this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency()
            ),
            IsSelected: ko.observable(false),
            OriginatingRows: [articleReference],
            SourceRows: [],
            RelatedWorkflows: [],
        };
    }

    protected createDestinationRowFromDocumentRow(row: IDocumentDataWizardRow): IDocumentDataWizardRow {
        const newRefs = row.OriginatingRows.map((ref) => {
            const newRef = Object.assign({}, ref) as IDocumentBuilderDocumentOriginatingRows;
            newRef.RefId = this.documentsService.getFakeId();
            newRef.DestEntityKeyId = null;
            newRef.DestEntityType = this.initializationInfo.DocTypeCode;
            newRef.DocumentId = null;
            return newRef;
        });

        const newRow = new ProcessedRow(
            {
                Id: null,
                AmountFormula: null,
                FKDocument: null,
                Description: row.Row.Description(),
                Amount: row.Row.Amount(),
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency().CurrencyId(),
                Currency: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrencySymbol(),
                ManuallyClosed: false,
                NetUnitPrice: row.Row.NetUnitPrice(),
                NetUnitPriceInDocumentCurrency: row.Row.NetUnitPriceInDocumentCurrency(),
                Order: 0,
                TotalPrice: row.Row.TotalPrice(),
                TotalPriceInDocumentCurrency: row.Row.TotalPriceInDocumentCurrency(),
                UnitPrice: row.Row.UnitPrice(),
                UnitPriceInDocumentCurrency: row.Row.UnitPriceInDocumentCurrency(),
                Discounts: row.Row.Discounts(),
            },
            this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency()
        );

        const newRelatedWorkflows = row.RelatedWorkflows.map((rw) => {
            const newRw = Object.assign({}, rw) as IDocumentBuilderDocumentRelatedWorkflows;

            newRw.Id = this.documentsService.getFakeId();
            newRw.DocumentId = null;
            newRw.DocumentDate = this.initializationInfo.DocumentDate;
            newRw.DocumentNumber = null;
            newRw.DocumentProtocolId = this.initializationInfo.DestinationDocumentProtocol?.IdRegistroIVA;
            newRw.RowId = null;
            newRw.RowDescription = newRow.Description();

            return newRw;
        });

        return {
            Row: newRow,
            IsSelected: ko.observable(false),
            OriginatingRows: newRefs,
            SourceRows: [row],
            RelatedWorkflows: newRelatedWorkflows,
        };
    }

    private findByArticleId(rowArticleId: number, processedRow: any): boolean {
        let articleId = -1;
        const row = this.getDataRow(processedRow);

        if (this.isArticleRow(row)) {
            articleId = this.getArticleIdFromRow(row);
        }

        return rowArticleId == articleId;
    }

    private isArticleRow(row: IDocumentDataWizardRow): boolean {
        return (
            row.OriginatingRows.length == 1 &&
            row.OriginatingRows[0].SourceEntityType == ProlifeSdk.WarehouseArticleEntityTypeCode
        );
    }

    private getArticleIdFromRow(row: IDocumentDataWizardRow): number {
        return row.OriginatingRows[0].SourceEntityKeyId;
    }

    render() {
        let step: ImportDocumentDataWizardTransformStep;
        let row: IDocumentDataWizardRow;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 20px;">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <button
                                    class="btn btn-sm btn-primary pull-right"
                                    data-bind={{
                                        click: step.importSelectedConstructionRows,
                                        enable: step.CanImportRows,
                                    }}
                                >
                                    {TextResources.Invoices.DocumentWizardImportDecomposition}
                                </button>
                                <button
                                    class="btn btn-sm btn-primary pull-right"
                                    style="margin-right: 5px"
                                    data-bind={{ click: step.importSingleConstructionRows, enable: step.CanImportRows }}
                                >
                                    {TextResources.Invoices.DocumentWizardImportSingleDecomposition}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-striped table-condensed">
                                    <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th>{TextResources.Invoices.DocumentWizardAmount}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ko-if data-bind="Rows().length === 0">
                                            <tr>
                                                <td colSpan={2}>{TextResources.Invoices.NoTransformationAvailable}</td>
                                            </tr>
                                        </ko-if>
                                        <ko-foreach data-bind="{ data: Rows, as: 'row' }">
                                            <tr>
                                                <td>
                                                    <span
                                                        class="form-control"
                                                        data-bind={{ text: row.Row.Description }}
                                                    ></span>
                                                </td>
                                                <td>
                                                    <span
                                                        class="form-control"
                                                        data-bind={{ numberText: row.Row.Amount }}
                                                    ></span>
                                                </td>
                                            </tr>
                                        </ko-foreach>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <button
                                    class="btn btn-sm btn-danger pull-right"
                                    data-bind={{
                                        click: step.clear.bind(step),
                                    }}
                                >
                                    {TextResources.Invoices.DocumentWizardImportDecompositionClear}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-striped table-condensed">
                                    <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th>{TextResources.Invoices.DocumentWizardAmount}</th>
                                        </tr>
                                    </thead>
                                    <tbody data-bind="foreach: { data: ProcessedRows, as: 'row' }">
                                        <tr>
                                            <td>
                                                <span
                                                    class="form-control"
                                                    data-bind={{ text: row.Row.Description }}
                                                ></span>
                                            </td>
                                            <td>
                                                <span
                                                    class="form-control"
                                                    data-bind={{ numberText: row.Row.Amount }}
                                                ></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "step"
        );
    }
}
