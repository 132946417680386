import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IHumanResourcesService, IHumanResource } from "../Users/HumanResourcesService";
import { IDataSourceModel, IDataSourceIcon } from "./IDataSource";

export interface IResourceDataSourceModel extends IDataSourceModel<number, IHumanResource> {
    isHumanResource: boolean;
    isMaterialResource: boolean;
}

export class ResourcesDataSource extends BaseDataSource<IResourceDataSourceModel, number, IHumanResource> {
    @LazyImport(ProlifeSdk.HumanResourcesServiceType)
    private resourcesService!: IHumanResourcesService;

    private includeDeletedResources = false;
    private includeDisabledResources = false;
    private withServiceOrderAtDate: Date = null;
    private getHuman = true;
    private getMaterials = false;
    private allowFreeText = false;

    public static FREE_TEXT = 1;
    public static RESOURCE = 2;

    private icon: IDataSourceIcon;

    public static isModel(obj: any): obj is IResourceDataSourceModel {
        return obj;
    }

    setAllowFreeText(enabled: boolean) {
        this.allowFreeText = enabled;
    }

    setIcon(icon: IDataSourceIcon) {
        this.icon = icon;
    }

    isGroupedData(currentModel: IResourceDataSourceModel, textFilter: string): boolean {
        return this.allowFreeText;
    }

    getTitle(currentModel: IDataSourceModel): string {
        return TextResources.ResourcesManager.ResourcesAreaTitle;
    }

    public async getData(
        currentModel: IResourceDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IResourceDataSourceModel[]> {
        if (this.allowFreeText) {
            if (!currentModel) {
                if (!textFilter)
                    return [this.createGroupModel(ResourcesDataSource.RESOURCE, TextResources.Customers.Resources)];
                return [
                    this.createGroupModel(ResourcesDataSource.FREE_TEXT, TextResources.Customers.Text),
                    this.createGroupModel(ResourcesDataSource.RESOURCE, TextResources.Customers.Resources),
                ];
            }

            if (currentModel.isGroup && currentModel.id == ResourcesDataSource.FREE_TEXT) {
                return [this.createFreeTextModel(textFilter)];
            } else if (currentModel.isGroup && currentModel.id == ResourcesDataSource.RESOURCE) {
                const resources = await this.resourcesService.GetHumanResources(
                    textFilter,
                    this.includeDeletedResources,
                    this.includeDisabledResources,
                    this.getMaterials,
                    this.withServiceOrderAtDate,
                    skip,
                    count
                );
                return resources.map((r) => this.createResourceDataSourceModel(r));
            } else {
                return [];
            }
        } else {
            const resources = await this.resourcesService.GetHumanResources(
                textFilter,
                this.includeDeletedResources,
                this.includeDisabledResources,
                this.getMaterials,
                this.withServiceOrderAtDate,
                skip,
                count
            );
            return resources.map((r) => this.createResourceDataSourceModel(r));
        }
    }

    public async getById(
        currentModel: IResourceDataSourceModel,
        ids: (number | string)[]
    ): Promise<IResourceDataSourceModel[]> {
        const resources: IHumanResource[] = [];
        for (const id of ids) {
            if (typeof id === "string") {
                resources.push({
                    Resource: {
                        Id: id as unknown as number,
                        Name: id,
                        Surname: null,
                        ResourceType: -1,
                        Deleted: null,
                        Disabled: null,
                        ShowPreferred: null,
                        SerialNumber: null,
                        FkDefaultCharacter: null,
                        FkUser: null,
                    },
                    Orders: [],
                    OrdersRoles: [],
                    OrdersSalaries: [],
                    OrdersWorkingHours: [],
                });
            } else {
                resources.push(await this.resourcesService.GetHumanResource(id));
            }
        }

        return resources.filter((r) => !!r.Resource).map(this.createResourceDataSourceModel, this);
    }

    public setIncludeDeletedResources(value: boolean): void {
        this.includeDeletedResources = value;
    }

    public setIncludeDisabledResources(value: boolean): void {
        this.includeDisabledResources = value;
    }

    public setDateValidityForServiceOrders(value: Date): void {
        this.withServiceOrderAtDate = value;
    }

    public setGetHumanResources(value: boolean): void {
        this.getHuman = value;
    }

    public setGetMaterialResources(value: boolean): void {
        this.getMaterials = value;
    }

    private createResourceDataSourceModel(resource: IHumanResource): IResourceDataSourceModel {
        return {
            id: resource.Resource.Id,
            isGroup: false,
            isLeaf: true,
            title: resource.Resource.Name + (resource.Resource.Surname ? " " + resource.Resource.Surname : ""),
            isHumanResource: resource.Resource.ResourceType == 0,
            isMaterialResource: resource.Resource.ResourceType == 1,
            icon: this.icon,
            model: resource,
        };
    }

    private createGroupModel(id: number, label: string): IResourceDataSourceModel {
        return {
            id: id,
            isGroup: true,
            isLeaf: false,
            title: label,
            isHumanResource: false,
            isMaterialResource: false,
        };
    }

    private createFreeTextModel(label: string): IResourceDataSourceModel {
        return {
            id: label as unknown as number,
            isGroup: false,
            isLeaf: true,
            title: label,
            isHumanResource: false,
            isMaterialResource: false,
        };
    }
}
