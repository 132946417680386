import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IArrayDataSource, IArrayDataSourceModel } from "./ArrayDataSource";
import { LazyImportSettingManager, LazyImport } from "../Core/DependencyInjection";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import {
    IWorkflowStatesSettingsManager,
    IWorkflowState,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";

const mimeType = "application/prolife-workflow-state";

export interface IDraggedState {
    Id: number;
    CompanyGuid: string;
}

export class WorkflowStatesDataSource implements IArrayDataSource<WorkflowState> {
    private view: IDataSourceView;

    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStatesManager: IWorkflowStatesSettingsManager;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    refreshRequested = false;

    public setView(view: IDataSourceView): void {
        this.view = view;

        if (this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }

    refresh(): any {
        if (!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }

    public getTitle(currentModel: IArrayDataSourceModel<WorkflowState>): string {
        return ProlifeSdk.TextResources.Todolist.WorkflowStates;
    }

    public async getData(
        currentModel: IArrayDataSourceModel<WorkflowState>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IArrayDataSourceModel<WorkflowState>[]> {
        return this.workflowStatesManager
            .getStates(false)
            .filter((c) => this.textFilterMatch(c, textFilter))
            .slice(skip, count)
            .map(this.createModelFor, this);
    }

    public async getById(
        currentModel: IArrayDataSourceModel<WorkflowState>,
        ids: number[]
    ): Promise<IArrayDataSourceModel<WorkflowState>[]> {
        return this.workflowStatesManager.getStateByIds(ids).map(this.createModelFor, this);
    }

    public getSupportedDropMimeTypes(): string[] {
        return [mimeType];
    }

    public onItemBeginMove(model: IDataSourceModel<number, WorkflowState>, dataTransfer: DataTransfer): void {
        const draggedState: IDraggedState = {
            Id: model.id,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData(mimeType, JSON.stringify(draggedState));
    }

    public async onItemMoved(
        dataTransfer: DataTransfer,
        model: IDataSourceModel<number, WorkflowState>,
        before: boolean
    ): Promise<void> {
        if (dataTransfer.types.indexOf(mimeType) >= 0 && model) {
            const droppedState = JSON.parse(dataTransfer.getData(mimeType)) as IDraggedState;

            if (droppedState.CompanyGuid == this.userInfo.getCurrentCompanyGuid()) {
                await this.workflowStatesManager.moveState(
                    droppedState.Id,
                    before ? model.model.Position : model.model.Position + 1
                );
                this.refresh();
            }
        }
    }

    public isGroupedData(currentModel: IArrayDataSourceModel<WorkflowState>, textFilter: string): boolean {
        return false;
    }

    public areEqual(a: IArrayDataSourceModel<WorkflowState>, b: IArrayDataSourceModel<WorkflowState>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(workflowState: IWorkflowState): IArrayDataSourceModel<WorkflowState> {
        return {
            id: workflowState.Id,
            isGroup: false,
            isLeaf: true,
            title: workflowState.Description,
            dragEnabled: true,
            model: new WorkflowState(workflowState, this),
        };
    }

    private textFilterMatch(carriage: IWorkflowState, textFilter: string): boolean {
        const words = (textFilter || "").toLowerCase().trim().split("");
        return words.filter((w) => (carriage.Description || "").toLowerCase().indexOf(w) >= 0).length === words.length;
    }
}

export class WorkflowState {
    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStatesSettingsManager: IWorkflowStatesSettingsManager;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    Id: number;
    Description: ko.Observable<string> = ko.observable();
    StateId: ko.Observable<number> = ko.observable();
    Saving: ko.Observable<boolean> = ko.observable();
    Position: number;

    MoveNonClosedTasks: ko.Observable<boolean> = ko.observable();
    MoveNonClosedTasksToState: ko.Observable<number> = ko.observable();

    MoveClosedTasks: ko.Observable<boolean> = ko.observable();
    MoveClosedTasksToState: ko.Observable<number> = ko.observable();

    States: ko.ObservableArray<{ Id: number; Title: string }> = ko.observableArray([
        { Id: 0, Title: ProlifeSdk.TextResources.Todolist.WorkflowStateNotStarted },
        { Id: 1, Title: ProlifeSdk.TextResources.Todolist.WorkflowStateInProgress },
        { Id: 2, Title: ProlifeSdk.TextResources.Todolist.WorkflowStateCompleted },
        { Id: 3, Title: ProlifeSdk.TextResources.Todolist.WorkflowStateVerified },
    ]);

    private updating = false;

    constructor(private state: IWorkflowState, private dataSource: WorkflowStatesDataSource) {
        this.load(state);

        this.Description.subscribe(this.onDataChanged.bind(this));
        this.StateId.subscribe(this.onDataChanged.bind(this));
        this.MoveNonClosedTasks.subscribe(this.onDataChanged.bind(this));
        this.MoveNonClosedTasksToState.subscribe(this.onDataChanged.bind(this));
        this.MoveClosedTasks.subscribe(this.onDataChanged.bind(this));
        this.MoveClosedTasksToState.subscribe(this.onDataChanged.bind(this));
    }

    load(state: IWorkflowState) {
        this.updating = true;

        this.Id = state.Id;
        this.Description(state.Description);
        this.StateId(state.LogicalStatus);
        this.MoveNonClosedTasks(state.MoveNonClosedTasks);
        this.MoveNonClosedTasksToState(state.MoveNonClosedTasksToState);
        this.MoveClosedTasks(state.MoveClosedTasks);
        this.MoveClosedTasksToState(state.MoveClosedTasksToState);
        this.Position = state.Position;

        this.updating = false;
    }

    private async onDataChanged() {
        if (this.updating) return;

        this.Saving(true);

        try {
            await this.workflowStatesSettingsManager.createOrUpdate(this.getData());
        } catch {
            this.infoToastService.Error("Si è verificato un errore durante il salvataggio dello Stato");
        } finally {
            this.Saving(false);
        }
    }

    public getData(): IWorkflowState {
        const state = $.extend({}, this.state);
        state.Description = this.Description();
        state.LogicalStatus = this.StateId();
        state.MoveNonClosedTasks = this.MoveNonClosedTasks();
        state.MoveNonClosedTasksToState = this.MoveNonClosedTasksToState();
        state.MoveClosedTasks = this.MoveClosedTasks();
        state.MoveClosedTasksToState = this.MoveClosedTasksToState();
        return state;
    }

    public async markDeleted(): Promise<void> {
        const result = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Todolist.SureToDeleteState,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteOutcome,
            ProlifeSdk.TextResources.Todolist.DeleteOutcome
        );
        if (!result) return;

        this.workflowStatesSettingsManager.remove(this.Id).then(() => this.dataSource.refresh());
    }
}
