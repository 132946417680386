import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ActivitiesRow } from "./ActivitiesRow";
import { ActivitiesRowGroup } from "./ActivitiesRowGroup";
import { LazyImport } from "../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRelatedWorkflows,
} from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IActivitiesForDocuments,
    IActivitiesSourceForDocuments,
} from "../../../ProlifeSdk/interfaces/todolist/IActivitiesForDocuments";
import { IWizardInitializationInfo } from "../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IEstimateBudgetRow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import {
    ITodoListService,
    ITaskEstimatedBudgetRowWithNames,
} from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDiscountsService, IDiscountsManager } from "../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { ComponentUtils, PropsWithChildren } from "../../../Core/utils/ComponentUtils";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { IDataSourceListener, IDataSourceModel } from "../../../DataSources/IDataSource";
import { EntityInfo } from "../../../Provisioning/Provisioning/ui/panels/ArticleInfo";
import { NavigationMenu } from "../../../Components/NavigationMenu";
import {
    IWorkflowsAndTasksDataSourceModel,
    WorkflowsAndTasksDataSource,
} from "../../../DataSources/WorkflowsAndTasksDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
} from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { Delay } from "../../../Decorators/Delay";
import { AlertsLegendUI } from "../../../Components/AlertsLegend";

const { classes } = jss
    .createStyleSheet({
        "menu-wrapper": {
            "& .page-sidebar": {
                position: "absolute !important",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
                margin: 0,

                "& .page-quick-sidebar-wrapper": {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
        },
    })
    .attach();

export interface IExtendedEstimateBudgetRow extends ITaskEstimatedBudgetRowWithNames {
    Selected: ko.Observable<boolean>;
    Imported: ko.Computed<boolean>;
    TypeLabel: string;

    switchSelectionState?(): void;
}

export class ActivitiesDataSource
    extends DocumentDataWizardStep<IActivitiesForDocuments>
    implements IDataSourceListener
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDiscountsService>())
    private discountsService: IDiscountsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    private discountManager: IDiscountsManager;

    public AllEntities: ko.ObservableArray<IExtendedEstimateBudgetRow> = ko.observableArray();
    VisibleEntities: ko.Computed<IExtendedEstimateBudgetRow[]>;

    public AllSelected: ko.Computed<boolean>;
    ShowArticles: ko.Observable<boolean> = ko.observable(true);
    ShowPurchases: ko.Observable<boolean> = ko.observable(true);
    ShowWork: ko.Observable<boolean> = ko.observable(true);

    CanShowArticles: ko.Observable<boolean> = ko.observable(true);
    CanShowPurchases: ko.Observable<boolean> = ko.observable(true);
    CanShowWork: ko.Observable<boolean> = ko.observable(true);

    private selectedTaskIds: number[] = [];
    public SelectedRows: ko.Computed<IExtendedEstimateBudgetRow[]>;
    previousStepRows: IDocumentDataWizardRow[];

    public UseCosts: ko.Observable<boolean> = ko.observable(false);
    WorkflowsAndTasksDataSource: WorkflowsAndTasksDataSource = new WorkflowsAndTasksDataSource();

    private navigationMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private selectedTasks: number[] = [];

    constructor() {
        super();
        this.Title(TextResources.Todolist.Tasks);

        this.SelectedRows = ko.computed(() => {
            return this.AllEntities().filter((e) => e.Selected());
        });

        this.VisibleEntities = ko
            .computed(() => {
                const showArticles = this.ShowArticles() && this.CanShowArticles();
                const showPurchases = this.ShowPurchases() && this.CanShowPurchases();
                const showWork = this.ShowWork() && this.CanShowWork();

                return this.AllEntities().filter((e) => {
                    if (e.Type === ProlifeSdk.WarehouseArticleEntityTypeCode && showArticles) return true;
                    if (e.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode && showPurchases) return true;
                    if (e.Type === ProlifeSdk.EstimatedWorkEntityTypeCode && showWork) return true;
                    return false;
                });
            })
            .extend({ trackArrayChanges: true });

        this.AllSelected = ko.computed({
            read: () => {
                const selectedCount = this.VisibleEntities().filter((e) => e.Selected()).length;
                if (selectedCount === 0) return false;
                if (selectedCount === this.VisibleEntities().length) return true;
                return undefined;
            },
            write: (value) => {
                for (const entity of this.VisibleEntities()) entity.Selected(value);
            },
        });

        this.navigationMenu.subscribe((menu) => {
            this.configureMenu(menu);
        });

        this.RegisterDataSource();
    }

    public RegisterDataSource() {
        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.EstimateEntityTypeCode,
            ProlifeSdk.CustomerOrderEntityTypeCode,
            ProlifeSdk.SupplierOrderEntityTypeCode,
            ProlifeSdk.WarehouseLoadEntityTypeCode,
            ProlifeSdk.PurchaseRequestEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode,
            ProlifeSdk.PassiveInvoiceEntityTypeCode
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        const sign = this.calculateSign();

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: r.AmountFormula(),
                    Amount: r.Amount() * sign,
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: (r.NetUnitPrice() ?? 0).ToCurrency(documentCurrency),
                    NetUnitPrice: r.NetUnitPrice(),
                    Order: 0,
                    TotalPriceInDocumentCurrency: (r.Total() ?? 0).ToCurrency(documentCurrency) * sign,
                    TotalPrice: r.Total() * sign,
                    UnitPriceInDocumentCurrency: (r.UnitPrice() ?? 0).ToCurrency(documentCurrency),
                    UnitPrice: r.UnitPrice(),
                    Description: r.Description(),
                    Discounts: r.Discounts(),
                    UoM: r.UOM(),
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: r.RelatedWorkflows,
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r: IActivitiesForDocuments): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        for (const source of r.Sources.filter((r) => r.Amount != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType: source.EntityType,
                SourceEntityKeyId: source.EntityKeyId,
                DestEntityType: this.initializationInfo.DocTypeCode,
                DestEntityKeyId: 0,
                CatalogId: source.CatalogId,
                Amount: source.Amount,
                UnitPrice: source.UnitPrice,
                Discounts: source.Discounts,
                NetUnitPrice: source.NetUnitPrice,
                NetPrice: source.Total,
                WarehouseId: this.initializationInfo.SourceWarehouseId,
                EstimatedBudgetForTaskId: source.EstimateBudgetRowId,
                TaskId: source.TaskId,
            });
        }
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return (
            initializationInfo.DocTypeCode !== ProlifeSdk.WarehouseLoadEntityTypeCode ||
            initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol
        );
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);

        this.WorkflowsAndTasksDataSource.setJobOrders([initializationInfo.JobOrderId]);
        this.discountManager = this.discountsService.getDiscountsManagerByCustomerId(initializationInfo.CustomerId);

        this.selectedTaskIds = [];
        this.CanShowWork(initializationInfo.DocTypeCode !== ProlifeSdk.PurchaseRequestEntityTypeCode);
        this.AllEntities([]);

        this.UseCosts(this.mustUseCosts());
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {}

    private wasAlreadyImportedInDocument(estimatedRowId: number): boolean {
        return (this.initializationInfo.DocumentOriginatingRows || []).any(
            (r) => r.EstimatedBudgetForTaskId === estimatedRowId
        );
    }

    private diffTaskSelection() {
        const actualSelectedTasks = this.selectedTaskIds;
        const actualLoadedDataTasks = this.AllEntities()
            .map((e) => e.TaskId)
            .distinct();

        const tasksToAdd: number[] = [];
        const tasksToRemove: number[] = [];
        const unchangedTasks: number[] = [];

        for (const newTaskId of actualSelectedTasks) {
            if (actualLoadedDataTasks.indexOf(newTaskId) === -1)
                //E' un nuovo task aggiunto alla lista
                tasksToAdd.push(newTaskId);
            else unchangedTasks.push(newTaskId);
        }

        for (const oldTaskId of actualLoadedDataTasks) {
            if (actualSelectedTasks.indexOf(oldTaskId) === -1)
                //Questo task non è più nella lista dei selezionati, deve essere rimosso
                tasksToRemove.push(oldTaskId);
            else if (unchangedTasks.indexOf(oldTaskId) === -1)
                //Non ho ancora aggiunto questo task alla lista dei non modificati, ce lo aggiungo adesso
                unchangedTasks.push(oldTaskId);
        }

        return {
            tasksToAdd,
            tasksToRemove,
            unchangedTasks,
        };
    }

    configureMenu(navigator: INavigationMenuComponent) {
        navigator.enableSelectAllChildren();

        const showLegendAction: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-info-circle",
            text: "",
            title: TextResources.ProlifeSdk.AlertsLegendTitle,
            activeClass: "",
            defaultClass: "btn-transparent",
            active: () => false,
            canExecute: () => true,
            action: () => {
                const component = new AlertsLegendUI();
                component.show();
            },
        };

        navigator.registerAction(showLegendAction);

        const showingSecondaryActions = ko.observable(false);

        const action = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            actions: [
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.initializationInfo.JobOrderId);
                    },
                },
            ],
        };

        if (
            this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
        )
            navigator.registerAction(action);

        if (
            this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_EditTask")
        ) {
            const startEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                visible: () => !showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(true);
                    navigator.showSecondaryAction(true);
                },
            };

            const endEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                visible: () => showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(false);
                    navigator.showSecondaryAction(false);
                },
            };

            navigator.registerAction(startEditAction);
            navigator.registerAction(endEditAction);
        }

        navigator.registerSearchAction({
            text: "Cerca tutto",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(true),
            icon: "",
            isGroup: false,
            isSeparator: false,
            isDefault: true,
        });

        navigator.registerSearchAction({
            text: "Cerca solo in questa lista",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(false),
            icon: "",
            isGroup: false,
            isSeparator: false,
        });

        navigator.setMultipleSelection({
            keepSelection: true,
            multipleSelection: true,
            selectLeafsOnly: true,
        });

        this.WorkflowsAndTasksDataSource.setRecursiveSearch(true);
    }

    onItemSelected(sender: WorkflowsAndTasksDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        const selectedTaskId = model?.id;
        const selection = this.selectedTaskIds.slice();
        if (selectedTaskId) selection.push(selectedTaskId);
        this.onSelectionChanged(selection);
    }

    onItemDeselected(sender: WorkflowsAndTasksDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        const selection = this.selectedTaskIds.slice();
        selection.remove(model?.id);
        this.onSelectionChanged(selection);
    }

    async onSelectionChanged(selection: number[]) {
        this.selectedTaskIds = selection;
        await this.loadTasksEstimatedBudgets();
    }

    @Delay(100)
    private async loadTasksEstimatedBudgets(): Promise<void> {
        const { tasksToAdd, tasksToRemove } = this.diffTaskSelection();

        const actualEntities = this.AllEntities();
        this.AllEntities.valueWillMutate();

        for (const taskToRemove of tasksToRemove) {
            const entitiesToRemove = actualEntities.filter((e) => e.TaskId === taskToRemove);
            for (const entityToRemove of entitiesToRemove) {
                actualEntities.remove(entityToRemove);
            }
        }

        try {
            const estimatedBudgetRows = await this.todoListService.GetTasksEstimatedRowsByTaskIds(tasksToAdd);

            for (const row of estimatedBudgetRows) {
                const alreadyImported = this.wasAlreadyImportedInDocument(row.Id);

                actualEntities.push({
                    ...row,
                    Selected: ko.observable(false),
                    Imported: ko.computed(() => {
                        if (alreadyImported) return true;

                        const imported = this.Rows().any((a: IActivitiesForDocuments) => {
                            return a.references(row);
                        });
                        return imported;
                    }),
                    TypeLabel:
                        row.Type === ProlifeSdk.WarehouseArticleEntityTypeCode
                            ? TextResources.Warehouse.Article
                            : row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode
                            ? TextResources.Blog.Purchase
                            : row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode
                            ? TextResources.WorkedHours.Work
                            : "Sconosciuto",
                });
            }
        } catch {
            this.infoToastService.Error("Si è verificato un errore durante il caricamento delle stime delle attività");
        }

        this.AllEntities.valueHasMutated();
    }

    public removeRow(row: IActivitiesForDocuments) {
        this.Rows.remove(row);
    }

    public RemoveAll() {
        this.Rows([]);
    }

    private GetSelectedRows(): IExtendedEstimateBudgetRow[] {
        const selectedRows = this.SelectedRows();

        if (selectedRows.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectARowToImport);
            return [];
        }

        const atLeastOneRowWasAlreadyImported = selectedRows.any((r) => r.Imported());

        if (atLeastOneRowWasAlreadyImported) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SomeRowsWereAlreadyImported);
        }

        return selectedRows.filter((r) => !r.Imported());
    }

    public ImportSelection() {
        const selectedRows = this.GetSelectedRows();
        const allRows = this.Rows.peek();
        this.Rows.valueWillMutate();

        for (const row of selectedRows) {
            allRows.push(new ActivitiesRow(this.discountManager, row, this.UseCosts()));
        }

        this.Rows.valueHasMutated();
    }

    public ImportGroupByTask() {
        const selectedRows = this.GetSelectedRows();
        const allRows = this.Rows.peek();
        this.Rows.valueWillMutate();

        const groupedByTask = selectedRows.groupBy((r) => r.TaskId);
        for (const taskId in groupedByTask) {
            if (!groupedByTask.hasOwnProperty(taskId)) continue;

            const rows = groupedByTask[taskId];
            allRows.push(
                new ActivitiesTextRow(
                    String.format(
                        ProlifeSdk.TextResources.Todolist.TaskReference,
                        rows[0].WorkflowName + " > " + rows[0].TaskName
                    )
                )
            );

            for (const row of rows) {
                allRows.push(new ActivitiesRow(this.discountManager, row, this.UseCosts()));
            }
        }

        this.Rows.valueHasMutated();
    }

    public ImportGroupByType() {
        const selectedRows = this.GetSelectedRows();
        const allRows = this.Rows.peek();
        this.Rows.valueWillMutate();

        const groupedByType = selectedRows.groupBy((r) => r.Type);
        for (const type in groupedByType) {
            if (!groupedByType.hasOwnProperty(type)) continue;

            const rows = groupedByType[type];
            const groupedByEntityKey = rows.groupBy((r) => r.EntityKeyId);

            for (const key in groupedByEntityKey) {
                if (!groupedByEntityKey.hasOwnProperty(key)) continue;
                const entities = groupedByEntityKey[key];
                allRows.push(new ActivitiesRowGroup(entities, this.UseCosts()));
            }
        }

        this.Rows.valueHasMutated();
    }

    public createEntityDataModel(d: IExtendedEstimateBudgetRow) {
        return {
            id: d.Id,
            title: d.Description,
            isLeaf: true,
            isGroup: false,
            model: d,
        };
    }

    static nextId = -1;
    public createRowDataModel(d: IActivitiesForDocuments) {
        return {
            id: ActivitiesDataSource.nextId--,
            title: d.Description(),
            isLeaf: true,
            isGroup: false,
            model: d,
        };
    }

    private calculateSign(): number {
        return this.initializationInfo.DocTypeCode === ProlifeSdk.WarehouseLoadEntityTypeCode &&
            this.initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol
            ? -1
            : 1;
    }

    private mustUseCosts(): boolean {
        return (
            [
                ProlifeSdk.WarehouseLoadEntityTypeCode,
                ProlifeSdk.SupplierOrderEntityTypeCode,
                ProlifeSdk.PurchaseRequestEntityTypeCode,
            ].indexOf(this.initializationInfo.DocTypeCode) >= 0
        );
    }

    render() {
        let step: ActivitiesDataSource;
        let entity: IDataSourceModel<number, IExtendedEstimateBudgetRow>;
        let row: IDataSourceModel<number, IActivitiesForDocuments>;

        const HighlightedTableRowComponent = (props: PropsWithChildren<unknown>) => {
            return <tr data-bind={{ css: { success: entity.model.Imported } }}>{props.children}</tr>;
        };

        const { sortString, sortNumber } = ComponentUtils.useSorter<IExtendedEstimateBudgetRow>();

        return ComponentUtils.bindTo(
            <>
                <div class="activities-wizard-data-source">
                    <div class="row" style="height: 100%; overflow-y: hidden">
                        <div class="col-md-6 flex-container flex-vertical flex-full-height">
                            <div style={{ borderBottom: "1px solid #eee", padding: "5px 15px" }}>
                                <span style="font-size: 18px; font-weight: 400;">
                                    {TextResources.Invoices.DocumentWizardEstimatedExpenses}
                                </span>
                                <div class="pull-right">
                                    <a
                                        href="#"
                                        class="btn btn-primary btn-sm"
                                        data-bind={{ click: step.ImportGroupByType }}
                                    >
                                        {TextResources.Invoices.DocumentWizardByType}
                                    </a>
                                    <a
                                        href="#"
                                        class="btn btn-primary btn-sm"
                                        style="margin-left: 5px;"
                                        data-bind={{ click: step.ImportGroupByTask }}
                                    >
                                        {TextResources.Invoices.DocumentWizardByActivity}
                                    </a>
                                    <a
                                        href="#"
                                        class="btn btn-primary btn-sm"
                                        style="margin-left: 5px;"
                                        data-bind={{ click: step.ImportSelection }}
                                    >
                                        {TextResources.Invoices.DocumentWizardDetailed}
                                    </a>
                                </div>
                            </div>
                            <Table
                                dataSource={{ array: this.VisibleEntities, factory: this.createEntityDataModel }}
                                className={classes["table-entities"]}
                                rowAs="entity"
                                scrollable={true}
                                compact={true}
                                bordered={true}
                                hideSelection={true}
                                components={{ row: HighlightedTableRowComponent }}
                            >
                                <Column>
                                    <ColumnHeader>
                                        <input type="checkbox" data-bind={{ checkbox: step.AllSelected }} />
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {() => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                    height: "100%",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        left: "0px",
                                                        top: "0px",
                                                        bottom: "0px",
                                                        width: "3px",
                                                    }}
                                                    data-bind={{
                                                        style: {
                                                            "background-color":
                                                                entity.model.Type == "WAR"
                                                                    ? "#43b545"
                                                                    : entity.model.Type === "EPC"
                                                                    ? "#56b7f7"
                                                                    : "#ff893e",
                                                        },
                                                    }}
                                                ></span>
                                                <input
                                                    type="checkbox"
                                                    data-bind={{ checkbox: entity.model.Selected }}
                                                />
                                            </div>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column
                                    title="Tipologia"
                                    headerCssClasses="text-left"
                                    sorter={sortString((v) => v.TypeLabel)}
                                >
                                    <ColumnHeader>
                                        <div
                                            className="btn-group btn-group-sm ignore-sort header-btn"
                                            style={{ marginRight: "5px" }}
                                        >
                                            <button className="btn btn-default dropdown" data-toggle="dropdown">
                                                Tipologie&nbsp;<i class="fa fa-angle-down"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-checkboxes">
                                                <label data-bind={{ visible: step.CanShowArticles }}>
                                                    <div class="checker">
                                                        <span data-bind={{ css: { checked: step.ShowArticles } }}>
                                                            <input
                                                                type="checkbox"
                                                                data-bind={{ checkbox: step.ShowArticles }}
                                                            />
                                                        </span>
                                                    </div>
                                                    {TextResources.Warehouse.Article}
                                                </label>
                                                <label data-bind={{ visible: step.CanShowPurchases }}>
                                                    <div class="checker">
                                                        <span data-bind={{ css: { checked: step.ShowPurchases } }}>
                                                            <input
                                                                type="checkbox"
                                                                data-bind={{ checkbox: step.ShowPurchases }}
                                                            />
                                                        </span>
                                                    </div>
                                                    {TextResources.Blog.Purchase}
                                                </label>
                                                <label data-bind={{ visible: step.CanShowWork }}>
                                                    <div class="checker">
                                                        <span data-bind={{ css: { checked: step.ShowWork } }}>
                                                            <input
                                                                type="checkbox"
                                                                data-bind={{ checkbox: step.ShowWork }}
                                                            />
                                                        </span>
                                                    </div>
                                                    {TextResources.WorkedHours.Work}
                                                </label>
                                            </div>
                                        </div>
                                    </ColumnHeader>
                                    <ColumnBody>
                                        <span data-bind={{ text: entity.model.TypeLabel }}></span>
                                    </ColumnBody>
                                </Column>
                                <Column
                                    title="Articolo/Acquisto/Lavoro"
                                    headerCssClasses="text-left"
                                    sorter={sortString((v) => v.EntityCode)}
                                >
                                    <ColumnBody>
                                        {(item: ITableItem<IExtendedEstimateBudgetRow>) => (
                                            <EntityInfo
                                                type={item.Data.model.Type}
                                                code={item.Data.model.EntityCode}
                                                rowDescription={item.Data.model.Description}
                                                description={item.Data.model.EntityDescription}
                                            />
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column
                                    title={TextResources.Invoices.DocumentWizardMultiplierAmount}
                                    sorter={sortNumber((v) => v.Multiplier)}
                                >
                                    <span
                                        data-bind={{
                                            numberText: entity.model.Multiplier,
                                            numberTextSuffix: entity.model.MultiplierUnitOfMeasure,
                                        }}
                                    ></span>
                                </Column>
                                <Column
                                    title={TextResources.Invoices.DocumentWizardAmount}
                                    sorter={sortNumber((v) => v.Amount)}
                                >
                                    <span
                                        data-bind={{
                                            numberText: entity.model.Amount,
                                            numberTextSuffix: entity.model.AmountUnitOfMeasure,
                                        }}
                                    ></span>
                                </Column>
                                <Column
                                    title={TextResources.Invoices.DocumentWizardUnitPriceShort}
                                    sorter={sortNumber((v) => (this.UseCosts() ? v.UnitCost : v.UnitPrice))}
                                >
                                    <span
                                        data-bind={{
                                            moneyText: step.UseCosts() ? entity.model.UnitCost : entity.model.UnitPrice,
                                        }}
                                    ></span>
                                </Column>
                                <Column
                                    title={TextResources.Invoices.DocumentWizardTotalPrice}
                                    sorter={sortNumber((v) => (this.UseCosts() ? v.Cost : v.Price))}
                                >
                                    <span
                                        data-bind={{
                                            moneyText: step.UseCosts() ? entity.model.Cost : entity.model.Price,
                                        }}
                                    ></span>
                                </Column>
                            </Table>
                        </div>
                        <div
                            class="col-md-6 flex-container flex-vertical flex-full-height"
                            style={{ borderLeft: "1px solid #ddd" }}
                        >
                            <div style={{ borderBottom: "1px solid #eee", padding: "5px 15px" }}>
                                <span style="font-size: 18px; font-weight: 400;">
                                    {TextResources.Invoices.DocumentWizardImportedRows}
                                </span>
                                <div class="pull-right">
                                    <a href="#" class="btn btn-danger btn-sm" data-bind={{ click: step.RemoveAll }}>
                                        {TextResources.Invoices.DocumentWizardRemoveAll}
                                    </a>
                                </div>
                            </div>
                            <Table
                                dataSource={{ array: this.Rows, factory: this.createRowDataModel }}
                                rowAs="row"
                                scrollable={true}
                                compact={true}
                                bordered={true}
                                hideSelection={true}
                            >
                                <Column
                                    title={TextResources.Invoices.DocumentWizardDescription}
                                    className="text-left text-ellipsis"
                                    style={{ maxWidth: "200px" }}
                                >
                                    <span data-bind={{ text: row.model.Description }}></span>
                                </Column>
                                <Column title={TextResources.Invoices.DocumentWizardAmount}>
                                    <span
                                        data-bind={{
                                            numberText: row.model.Amount,
                                            visible: row.model.Amount() > 0,
                                        }}
                                    ></span>
                                </Column>
                                <Column title={TextResources.Invoices.DocumentWizardUnitPriceShort}>
                                    <span
                                        data-bind={{
                                            moneyText: row.model.UnitPrice,
                                            visible: row.model.Amount() > 0,
                                        }}
                                    ></span>
                                </Column>
                                <Column title={TextResources.Invoices.DocumentWizardDiscount}>
                                    <span
                                        data-bind={{
                                            text: row.model.Discounts,
                                            visible: row.model.Amount() > 0,
                                        }}
                                    ></span>
                                </Column>
                                <Column title={TextResources.Invoices.DocumentWizardNetPriceShort}>
                                    <span
                                        data-bind={{
                                            moneyText: row.model.NetUnitPrice,
                                            visible: row.model.Amount() > 0,
                                        }}
                                    ></span>
                                </Column>
                                <Column title={TextResources.Invoices.DocumentWizardTotalPriceShort}>
                                    <span
                                        data-bind={{
                                            moneyText: row.model.Total,
                                            visible: row.model.Amount() > 0,
                                        }}
                                    ></span>
                                </Column>
                                <Column>
                                    <button
                                        class="btn btn-xs btn-icon-only btn-circle red"
                                        title={TextResources.Invoices.DocumentWizardRemove}
                                        data-bind={{ click: step.removeRow.bind(step, row.model) }}
                                    >
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </Column>
                            </Table>
                        </div>
                    </div>
                </div>
                <div
                    className={"page-quick-sidebar-wrapper " + classes["menu-wrapper"]}
                    style="position: absolute; bottom: 0; top : 0; right : 0; width : 270px; border-left: 1px solid rgb(219, 219, 219);"
                >
                    <NavigationMenu
                        dataSource={this.WorkflowsAndTasksDataSource}
                        listener={this}
                        forwardRef={(menu) => this.navigationMenu(menu)}
                    ></NavigationMenu>
                </div>
            </>,
            this,
            "step"
        );
    }
}

class ActivitiesTextRow implements IActivitiesForDocuments {
    Description: ko.Observable<string> = ko.observable();
    AmountFormula: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable(0);
    UnitPrice: ko.Observable<number> = ko.observable(0);
    Discounts: ko.Observable<string> = ko.observable();
    NetUnitPrice: ko.Observable<number> = ko.observable(0);
    UOM: ko.Observable<string> = ko.observable();

    Total: ko.Observable<number> = ko.observable(0);

    Sources: IActivitiesSourceForDocuments[] = [];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[] = [];

    constructor(description: string) {
        this.Description(description ?? ProlifeSdk.TextResources.Todolist.UnknownTask);
    }

    public references(row: IEstimateBudgetRow): boolean {
        return false;
    }
}
