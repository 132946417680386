import jsPDF from "jspdf";
import { ReportComponent } from "../Components";

export interface ReportExporter {
    export(doc: jsPDF, component: ReportComponent) : Promise<void>;
}

export interface ConstructableReportExporter {
    new () : ReportExporter;
}

type ReportExporterMap = {
    [componentType: string] : ConstructableReportExporter;
}

class _ReportExporterProvider {
    private exporters : ReportExporterMap = {};

    public registerExporter(componentType: string, exporterFactory : ConstructableReportExporter) {
        if(this.exporters[componentType])
            console.warn(`An exporter for component "${componentType}" has already been registered`);

        this.exporters[componentType] = exporterFactory;
    }

    public createExporter(component : ReportComponent) : ReportExporter {
        if(!this.exporters[component.type])
            throw `No exporter is registered for component "${component.type}"`;

        return new this.exporters[component.type];
    }
}

export const ReportExporterProvider = new _ReportExporterProvider();

export function ExporterFor(componentType : string) {
    return (constructor : (new () => ReportExporter)) => {
        ReportExporterProvider.registerExporter(componentType, constructor);
    }
}