import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";
import { IEventTag, ITag } from "../../interfaces/ITag";
import { IEntityProvider } from "../../interfaces/IEntityProvider";

export class Tag implements IEventTag {
    public TagName: ko.Observable<string> = ko.observable();
    public TagTypeId: ko.Observable<string> = ko.observable();
    public Value: ko.Observable<string> = ko.observable();

    public displayName: ko.Observable<string> = ko.observable();

    @LazyImport(ProlifeSdk.EntityProviderServiceType)
    private entityProviderService: IEntityProviderService;

    constructor() {}

    public async LoadItemFromObject(item: ITag): Promise<string> {
        if (!item) return undefined;

        this.TagName(item.TagName);
        this.Value(item.Value);
        this.TagTypeId(item.TagTypeId);

        if (
            item.TagTypeId == ProlifeSdk.TagType_DocumentReference ||
            item.TagTypeId == ProlifeSdk.TagType_TaskBoardStatus ||
            item.TagTypeId == ProlifeSdk.TagType_WorkflowStatus
        ) {
            this.displayName(item.DisplayName);
            return undefined;
        }

        const service: IEntityProvider = <IEntityProvider>this.entityProviderService.getEntityProvider(item.TagTypeId);
        if (!service) {
            return await new Promise((resolve) => {
                setTimeout(function () {
                    resolve("");
                }, 10);
            });
        }

        const displayName: string = await service.getDisplayNameForKeyValue(item.Value);

        this.displayName(displayName);
        return displayName;
    }

    private Clear() {
        this.TagName("");
        this.Value("");
        this.TagTypeId("");
    }

    public getTag(): ITag {
        return <ITag>{
            TagName: this.TagName(),
            Value: this.Value(),
            TagTypeId: this.TagTypeId(),
        };
    }
}
