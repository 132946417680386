import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 04/05/2018
 * Time: 17:20
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ITrustsSettingsEditingViewModel } from "../../../interfaces/ITrustsSettingsEditingViewModel";
import { ITrustsSettingsManager, ITrustGeneralSettings } from "../../../../ProlifeSdk/interfaces/customer/ITrustsSettingsManager";

export class TrustsSettingsEditingViewModel implements ITrustsSettingsEditingViewModel {
    public title: string = ProlifeSdk.TextResources.Customers.TrustsSettingsTitle;

    public TrustEnabledByDefault: ko.Observable<boolean> = ko.observable();
    public ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust: ko.Observable<string> = ko.observable();
    public TrustLimitOnPeriodWithoutConfiguredTrust: ko.Observable<number> = ko.observable();
    public ShowAlertOnSaveIfTrustIsNotConfigured: ko.Observable<boolean> = ko.observable();
    public StartDocumentsEmissionDateForTrustMovements: ko.Observable<Date> = ko.observable();

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ITrustsSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.LoadSettings(this.settingsManager.GetSettings());
    }

    public Save(): void {
        this.settingsManager.UpdateSettings(this.GetData())
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Customers.TrustsSettingsUpdateSuccess)
            });
    }

    public Cancel(): void {
        this.LoadSettings(this.settingsManager.GetSettings());
    }

    public GetData(): ITrustGeneralSettings {
        return {
            TrustLimitOnPeriodWithoutConfiguredTrust: this.TrustLimitOnPeriodWithoutConfiguredTrust(),
            TrustEnabledByDefault: this.TrustEnabledByDefault(),
            ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust: this.ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust() == "1" ? true : false,
            ShowAlertOnSaveIfTrustIsNotConfigured: this.ShowAlertOnSaveIfTrustIsNotConfigured(),
            StartDocumentsEmissionDateForTrustMovements: this.StartDocumentsEmissionDateForTrustMovements()
        };
    }

    private LoadSettings(settings: ITrustGeneralSettings): void {
        this.TrustEnabledByDefault(settings.TrustEnabledByDefault);
        this.TrustLimitOnPeriodWithoutConfiguredTrust(settings.TrustLimitOnPeriodWithoutConfiguredTrust);
        this.ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust(settings.ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust ? "1" : "0");
        this.ShowAlertOnSaveIfTrustIsNotConfigured(settings.ShowAlertOnSaveIfTrustIsNotConfigured);
        this.StartDocumentsEmissionDateForTrustMovements(settings.StartDocumentsEmissionDateForTrustMovements);
    }
}