import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 07/02/13
 * Time: 11.55
 * To change this template use File | Settings | File Templates.
 */

import { BankEditingViewModel } from "./BankEditingViewModel";
import { IBank } from "../../../../ProlifeSdk/interfaces/settings/IBank";

export class BanksEditingViewModel {
    public banks: ko.ObservableArray<BankEditingViewModel> = ko.observableArray();
    private originalValues: IBank[] = [];
    public isChanged: ko.Computed<boolean>;

    constructor() {
        this.isChanged = ko.computed(() => {
            var isChanged = this.originalValues.length != this.banks().length ||
                this.banks().map((b: BankEditingViewModel) => b.originalValue)
                    .filter((b:IBank) => {
                        this.originalValues.indexOf(b) == -1
                    })
                    .length > 0;

            return isChanged || this.banks().filter((b: BankEditingViewModel) => b.isChanged()).length > 0;
        });
    }

    public reload(banks:IBank[]) {
        this.originalValues = banks;
        this.banks(banks.map(this.createViewModelFor.bind(this)));

        if ((this.banks() || []).length > 0) {
            this.SelectBank(this.banks()[0]);
        }
    }

    public update(banks:IBank[]) {
        banks.length = 0;
        this.banks().forEach((bankVM) => banks.push(bankVM.getBank()));
    }

    private createViewModelFor(bank:IBank) {
        return new BankEditingViewModel(bank);
    }

    public CreateBank() {
        var newBank = new BankEditingViewModel();
        this.banks.push(newBank);

        this.SelectBank(newBank);
    }

    public DeleteBank(bank:BankEditingViewModel) {
        this.banks.remove(bank);

        if(this.banks().length > 0) {
            this.SelectBank(this.banks()[0]);
        }
    }

    public SelectBank(bank:BankEditingViewModel) {
        this.banks().forEach(b => b.Selected(false));
        bank.Selected(true);
    }
}