import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { WizardDialogBase } from "../../../../../ProlifeSdk/prolifesdk/WizardDialogBase";
import { WarehouseArticlesImporterSourceSelectionStep } from "./WarehouseArticlesImporterSourceSelectionStep";
import { IWarehouseInspectionWizardArticleInfo } from "../../../../WarehouseInspectionsService";

export interface IWarehouseArticlesImporterForInspectionInitializationInfo {
    WarehouseInspectionId: number;
}

export class WarehouseArticlesImporterForInspection extends WizardDialogBase<IWarehouseInspectionWizardArticleInfo[]> {
    
    constructor(private initializationInfo: IWarehouseArticlesImporterForInspectionInitializationInfo) {
        super({ actionLabel: TextResources.ProlifeSdk.Import });
        this.title(TextResources.Warehouse.InspectionImporterTitle);

        let sourcesSelectionStep = new WarehouseArticlesImporterSourceSelectionStep(this, this.initializationInfo);
        sourcesSelectionStep.Initialize();

        this.sourcesSelectionStep = sourcesSelectionStep;
        this.Steps.push(sourcesSelectionStep);
        this.CurrentStep(sourcesSelectionStep);
    }

    public async action(): Promise<void> {
        let currentStep = this.CurrentStep();
        let allRows = await Promise.resolve(currentStep.OnNext());
        
        this.modal.close(this.sortAdnDistinctRows(allRows));
    }

    private sortAdnDistinctRows(inputRows: IWarehouseInspectionWizardArticleInfo[]): IWarehouseInspectionWizardArticleInfo[] {
        let result = [];

        inputRows.sort(this.compareOperations.bind(this));

        let lastRow: IWarehouseInspectionWizardArticleInfo;

        // TODO Rivedere questa logica, in alcuni casi potrebbe essere necessario fare un merge delle righe e non semplicemente scartarle, dipende dal tipo di operazione
        for (let row of inputRows) {
            if (!lastRow || !(lastRow.DestinationWarehouseId === row.DestinationWarehouseId && lastRow.SourceWarehouseId === row.SourceWarehouseId && lastRow.ArticleId === row.ArticleId && lastRow.OperationType === row.OperationType)) {
                result.push(row);
                lastRow = row;
            }
        }

        return result;
    }

    private compareOperations(o1: IWarehouseInspectionWizardArticleInfo, o2: IWarehouseInspectionWizardArticleInfo): number {
        if (o1.DestinationWarehouse < o2.DestinationWarehouse)
            return -1;
        else if (o1.DestinationWarehouse > o2.DestinationWarehouse)
            return 1;
        else if (o1.DestinationWarehouseId < o2.DestinationWarehouseId)
            return -1;
        else if (o1.DestinationWarehouseId > o2.DestinationWarehouseId)
            return 1;
        else if (o1.ArticleCode < o2.ArticleCode)
            return -1;
        else if (o1.ArticleCode > o2.ArticleCode)
            return 1;
        else if (o1.SourceWarehouseId < o2.SourceWarehouseId)
            return -1;
        else if (o1.SourceWarehouseId > o2.SourceWarehouseId)
            return 1;
        else if (o1.OperationType > o2.OperationType)
            return -1;
        else if (o1.OperationType < o2.OperationType)
            return 1;
        else return 0;
    }
}