import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import { classNames, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { ProgressBar } from "../../../../Components/ProgressBar";

const styleSheet = jss.createStyleSheet({
    'auto-alloc-modal': {
        fontSize: '14px',

        '& .icon': {
            marginTop: '15px',
            "& i": {
                fontSize: '36px',

                "&.ok": {
                    color: "green"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type AutoallocationDialogProps = {
    queuedWorkflows: number[];
}

type AutoallocationMessage = {
    companyGuid: string;
    workflowId: number;
}

export class AutoallocationDialog extends DialogComponentBase {
    static defaultProps: Partial<AutoallocationDialogProps> = {
    }

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNoticiationService : IChangesNotificationsService;

    private NumberOfAllocatedWorkflows: ko.Observable<number> = ko.observable(0);
    private Progress: ko.Computed<number>;
    private IsWorking: ko.Computed<boolean>;

    constructor(private props: AutoallocationDialogProps) {
        super({ noPrompt: true });

        this.title(TextResources.Allocations.AutoallocationDialogTitle);

        this.changesNoticiationService.RegisterEventHandler("NotifyAutoallocation", this.onAutoallocation.bind(this));
        this.IsWorking = ko.computed(() => this.NumberOfAllocatedWorkflows() < this.props.queuedWorkflows.length);
        this.Progress = ko.computed(() => {
            if (!this.props.queuedWorkflows.length)
                return 100;

            return (this.NumberOfAllocatedWorkflows() / this.props.queuedWorkflows.length) * 100});
    }
    
    private onAutoallocation(event: AutoallocationMessage) {
        if (this.props.queuedWorkflows.includes(event.workflowId))
            this.NumberOfAllocatedWorkflows(this.NumberOfAllocatedWorkflows() + 1);
    }

    action() {
        this.changesNoticiationService.UnregisterEventHandler("NotifyAutoallocation", this.onAutoallocation.bind(this));
        this.modal.close();
    }
    
    renderBody() {
        const m = this;

        return  ComponentUtils.bindTo((
            <div className={classNames("row", classes["auto-alloc-modal"])}>
                <div className="col-md-12 text-center">
                    <If condition={this.IsWorking}>{() => <>{TextResources.Allocations.AutoallocationStarted}</>}</If>
                    <IfNot condition={this.IsWorking}>{() => <>{TextResources.Allocations.AutoallocationEnded}</>}</IfNot>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span>{TextResources.Allocations.AllocatedWorkflows}</span>&nbsp;
                            <span data-bind={{ numberText: m.NumberOfAllocatedWorkflows, format: '0,0' }}></span>/<span data-bind={{ numberText: m.props.queuedWorkflows.length, format: '0,0' }}></span>
                            <ProgressBar progress={this.Progress} />
                            <div className="icon">
                                <If condition={this.IsWorking}>{() => <><i className="fa fa-spinner fa-spin"></i></>}</If>
                                <IfNot condition={this.IsWorking}>{() => <><i className="fa fa-check ok"></i></>}</IfNot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ), this, "m");
    }
}