import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as numeral from "numeral";
import moment = require("moment");
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { ICartRoleInfo } from "../../../../../ProlifeSdk/interfaces/allocations/ICart";
import { If, IfNot } from "../../../../../Components/IfIfNotWith";
import { DateTimeText } from "../../../../../Components/DateTimeText";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { NumericText } from "../../../../../Components/NumericText";
import { CartWorkableHoursCalculationModes } from "../../../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { TableFooterAggregationMode } from "../../../../../Components/TableComponent/TableFooterAggregationMode";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { ICartsService } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsService";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type CartWorkInfoProps = {
    cartId: number;
    workableHoursCalculationMode: ko.Observable<CartWorkableHoursCalculationModes>;
    className?: string;
}

export function CartWorkInfo(props: CartWorkInfoProps) {
    const C = require("./CartWorkInfo")._CartWorkInfo as typeof _CartWorkInfo;
    return <C {...props} />;
}

export class _CartWorkInfo {
    static defaultProps: Partial<CartWorkInfoProps> = {
        className: ""
    }

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;

    public RolesInfo: ko.ObservableArray<ICartRoleInfo> = ko.observableArray([]);

    public ReestimatedColumnTitle: ko.Computed<string>;
    public RemainingWorkColumnTitle: ko.Computed<string>;
    public UsingReestimatedWork: ko.Computed<boolean>;
    public UsingTaskEstimateWork: ko.Computed<boolean>;
    public LastPerformanceUpdate: ko.Computed<Date>;

    constructor(private props : CartWorkInfoProps) {
        this.UsingReestimatedWork = ko.computed(() => {
            return this.props.workableHoursCalculationMode() !== CartWorkableHoursCalculationModes.TaskEstimatedWork;
        });

        this.UsingTaskEstimateWork = ko.computed(() => {
            return !this.UsingReestimatedWork();
        });

        this.ReestimatedColumnTitle = ko.computed(() => {
            return this.props.workableHoursCalculationMode() === CartWorkableHoursCalculationModes.WorkflowSpeed ? TextResources.Allocations.WorkflowSpeedReestimatedWorkTitle : TextResources.Allocations.JobOrderSpeedReestimatedWorkTitle;
        });

        this.RemainingWorkColumnTitle = ko.computed(() => {
            return this.props.workableHoursCalculationMode() === CartWorkableHoursCalculationModes.WorkflowSpeed ? TextResources.Allocations.WorkflowSpeedRemainingWorkTitle : TextResources.Allocations.JobOrderSpeedRemainingWorkTitle;
        });

        this.LastPerformanceUpdate = ko.computed(() => {
            let lastPerformanceUpdate = null;
            this.RolesInfo().forEach(r => moment(r.LastPerformanceUpdate).isAfter(lastPerformanceUpdate ?? "2000-01-01") && (lastPerformanceUpdate = moment(r.LastPerformanceUpdate)));
            return lastPerformanceUpdate?.toDate();
        });
    }

    componentDidMount() {
        this.loadWorkInfo();
    }

    componentWillUnmount() {
        this.UsingTaskEstimateWork.dispose();
        this.UsingReestimatedWork.dispose();
        this.RemainingWorkColumnTitle.dispose();
        this.ReestimatedColumnTitle.dispose();
    }

    private async loadWorkInfo(): Promise<void> {
        if (this.props.cartId > 0) {
            const rolesInfo = await this.cartsService.getCartRolesInfo(this.props.cartId);
            this.RolesInfo(rolesInfo);
        }
    }
    
    render() {
        const cartWorkInfo = this;

        return  ComponentUtils.bindTo((
            <div className={this.props.className}>
                <div className="row">
                    <div className="col-md-12">
                        <Table
                            dataSource={{ array: this.RolesInfo, factory: Table.defaultFactory(i => ({ id: i.RoleId, title: i.RoleName }))}}
                            compact
                            systemScrollable
                            enableAggregators
                            fixedLayout
                            rowAs="role"
                        >
                            <Column title={TextResources.Allocations.UserCharacterColumnTitle} style={{ width: "12%" }}>
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <span>{item.Data.model.RoleName}</span>}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.EstimatedWorkColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                data-bind={{ css: { 'highlight': cartWorkInfo.UsingTaskEstimateWork }}}
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.EstimatedHours}
                                defaultAggregation={TableFooterAggregationMode.Sum}
                                aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00[00]")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <NumericText value={item.Data.model.EstimatedHours} format="0,0.00[00]" />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={""}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                data-bind={{ css: { 'highlight': cartWorkInfo.UsingReestimatedWork }}}
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.ReestimatedHours}
                                defaultAggregation={TableFooterAggregationMode.Sum}
                                aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00[00]")}
                            >
                                <ColumnHeader>
                                    {() => <span data-bind={{ text: cartWorkInfo.ReestimatedColumnTitle }}></span>}
                                </ColumnHeader>
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <NumericText value={item.Data.model.ReestimatedHours} format="0,0.00[00]" />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.WorkColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.WorkedHours}
                                defaultAggregation={TableFooterAggregationMode.Sum}
                                aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00[00]")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <NumericText value={item.Data.model.WorkedHours} format="0,0.00[00]" />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.AllocationStartColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.AllocationStartDate}
                                defaultAggregation={TableFooterAggregationMode.Min}
                                aggregationFormatter={(value: Date) => !value ? TextResources.ProlifeSdk.NotAvailable : moment(value).format("L")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <DateTimeText dateTime={item.Data.model.AllocationStartDate} format="L" dateTextNoValue={TextResources.ProlifeSdk.NotAvailable} />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.AllocationEndColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.AllocationEndDate}
                                defaultAggregation={TableFooterAggregationMode.Max}
                                aggregationFormatter={(value: Date) => !value ? "TextResources.ProlifeSdk.NotAvailable" : moment(value).format("L")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <DateTimeText dateTime={item.Data.model.AllocationEndDate} format="L" dateTextNoValue={TextResources.ProlifeSdk.NotAvailable} />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.TheoricalAllocationStartColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.TheoreticalAllocationStartDate}
                                defaultAggregation={TableFooterAggregationMode.Min}
                                aggregationFormatter={(value: Date) => !value ? TextResources.ProlifeSdk.NotAvailable : moment(value).format("L")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <DateTimeText dateTime={item.Data.model.TheoreticalAllocationStartDate} format="L" dateTextNoValue={TextResources.ProlifeSdk.NotAvailable} />}
                                </ColumnBody>
                            </Column>
                            <Column
                                title={TextResources.Allocations.TheoricalAllocationEndColumnTitle}
                                style={{ width: "12.5%" }}
                                className="text-center"
                                aggregateOn={(item: ITableItem<ICartRoleInfo>) => item.Data.model.TheoreticalAllocationEndDate}
                                defaultAggregation={TableFooterAggregationMode.Max}
                                aggregationFormatter={(value: Date) => !value ? TextResources.ProlifeSdk.NotAvailable : moment(value).format("L")}
                            >
                                <ColumnBody>
                                    {(item: ITableItem<ICartRoleInfo>) => <DateTimeText dateTime={item.Data.model.TheoreticalAllocationEndDate} format="L" dateTextNoValue={TextResources.ProlifeSdk.NotAvailable} />}
                                </ColumnBody>
                            </Column>
                        </Table>
                    </div>
                </div>
            </div>
        ), this, "cartWorkInfo");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CartWorkInfo);
}