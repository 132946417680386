import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 12:33
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { AgendaApplication } from "./Agenda/AgendaApplication";
import { EventCanceledMotivationsSettingsManager } from "./Agenda/ui/settings/EventCanceledMotivationsSettingsManager";
import { AgendasUIProvider } from "./Agenda/ui/AgendasUIProvider";
import { AgendaEntityProvider } from "./Agenda/ui/entityProviders/AgendaEntityProvider";
import { AgendasProvider } from "./Agenda/ui/providers/AgendasProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IAgendaService, IAgendasUIProvider, IAgendasProvider, IAgenda, IAgendasDetails, IAgendasDetailsForMenu, IDetailedAgenda, IAgendaEnvelope, IAgendaConfiguration, IEventsNumbersOverview, IAgendaResource, IAgendaSync } from "../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IWaitingListEvent, IEventOnDatesIntervalDetails } from "../ProlifeSdk/interfaces/agenda/IEventsService";
import { ISearchFilter } from "../ProlifeSdk/interfaces/ISearchFilter";
import { IHasEventsOutOfDatesResult } from "./interfaces/IAgenda";

class AgendaService extends ProLifeService implements IAgendaService {
    public ui: IAgendasUIProvider;

    private lastId = 0;
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IAgendaService>(), this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.ui = new AgendasUIProvider(this.serviceLocator);
    }

    InitializeService() {
        super.InitializeService();
        
        new EventCanceledMotivationsSettingsManager(this.serviceLocator);
        new AgendaEntityProvider(this.serviceLocator);
        new AgendaApplication(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.AgendaServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetAgendaEditorUrl(agendaId : number) : string {
        return "#/Agenda/" + agendaId;
    }

    CreateAgendasProvider() : IAgendasProvider {
        return new AgendasProvider(this.serviceLocator);
    }

    GetAgenda(agendaId: number): Promise<IAgenda> {
        return this.ajaxService.Post<IAgenda>("Agenda-api", "Agendas/GetAgenda", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GetAgendas(folderId: number, showDeletedElements: boolean = false): Promise<IAgenda[]> {
        return this.ajaxService.Get<IAgenda[]>("Agenda-api", "Agendas?folderId=" + folderId + "&showDeletedElements=" + showDeletedElements, {});
    }

    GetAllAgendas(showDeletedElements: boolean): Promise<IAgenda[]> {
        return this.ajaxService.Post<IAgenda[]>("Agenda-api", "Agendas/GetAllAgendas", {
            methodData: {
                ShowDeletedElements: showDeletedElements
            }
        });
    }

    GetAgendasDetails(agendaId: number, async: boolean = true): Promise<IAgendasDetails> {
        return this.ajaxService.Post<IAgendasDetails>("Agenda-api", "Agendas/GetAgendasDetails", {
            methodData: {
                AgendaId: agendaId
            },
            async: async
        });
    }

    GetAgendasDetailsForMenu(agendaId: number): Promise<IAgendasDetailsForMenu> {
        return this.ajaxService.Post<IAgendasDetailsForMenu>("Agenda-api", "Agendas/GetAgendasDetailsForMenu", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    CreateOrUpdate(agenda: IDetailedAgenda): Promise<IDetailedAgenda> {
        return this.ajaxService.Post<IDetailedAgenda>("Agenda-api", "Agendas", {
            methodData: agenda
        });
    }

    Delete(agendaId: number): Promise<void> {
        return this.ajaxService.Delete<void>("Agenda-api", "Agendas?agendaId=" + agendaId, {});
    }

    Recovery(agendaId: number): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Agendas/Recovery", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GetAgendasEnvelopesLimitedList(filter: string): Promise<IAgendaEnvelope[]> {
        return this.ajaxService.Post<IAgendaEnvelope[]>("Agenda-api", "Agendas/GetAgendasEnvelopesLimitedList", {
            methodData: {
                Filter: filter
            }
        });
    }

    GetAgendasEnvelopes(filter: string): Promise<IAgendaEnvelope[]> {
        return this.ajaxService.Post<IAgendaEnvelope[]>("Agenda-api", "Agendas/GetAgendasEnvelopes", {
            methodData: {
                Filter: filter
            }
        });
    }

    GetAgendaEnvelope(agendaId: number): Promise<IAgendaEnvelope> {
        return this.ajaxService.Post<IAgendaEnvelope>("Agenda-api","Agendas/GetAgendaEnvelope", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GetAgendaConfiguration(agendaId: number): Promise<IAgendaConfiguration> {
        return this.ajaxService.Post<IAgendaConfiguration>("Agenda-api", "Agendas/GetAgendaConfiguration", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GetWaitingList(agendaId: number, filters: ISearchFilter[]): Promise<IWaitingListEvent[]> {
        return this.ajaxService.Post<IWaitingListEvent[]>("Agenda-api", "Agendas/GetWaitingList", {
            methodData: {
                AgendaId: agendaId,
                Filters: filters
            }
        });
    }

    InsertEventInWaitingList(eventId: number, canceledMotivationId: number): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Agendas/InsertEventInWaitingList", {
            methodData: {
                EventId: eventId,
                CanceledMotivationId: canceledMotivationId
            }
        });
    }

    GetServiceRootURL(): Promise<string> {
        return this.ajaxService.Post<string>("Agenda-api", "Agendas/GetServiceRootURL", {});
    }

    GetAgendaFutureEvents(agendaId: number): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Agendas/GetAgendaFutureEvents", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GetAgendaPlannedEventsSinceDate(agendaId: number, since: Date): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Agendas/GetAgendaPlannedEventsSinceDate", {
            methodData: {
                AgendaId: agendaId,
                Since: since
            }
        });
    }

    GetAgendaPlannedEventsOnInterval(agendaId: number, start: Date, end: Date): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Agendas/GetAgendaPlannedEventsOnInterval", {
            methodData: {
                AgendaId: agendaId,
                Start: start,
                End: end
            }
        });
    }

    GetAgendaPlannedEventsOnTimetable(timetableId: number): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Agendas/GetAgendaPlannedEventsOnTimetable", {
            methodData: {
                TimetableId: timetableId
            }
        });
    }

    GetEventsNumbersOverview(agendaId: number): Promise<IEventsNumbersOverview> {
        return this.ajaxService.Post<IEventsNumbersOverview>("Agenda-api", "Agendas/GetEventsNumbersOverview", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    HasPlannedEvents(agendaId: number): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Agendas/HasPlannedEvents", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    HasEventsOnTimeslot(timeslotId: number, agendaId: number, timetableId: number): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Agendas/HasEventsOnTimeslot", {
            methodData: {
                TimeslotId: timeslotId,
                AgendaId: agendaId,
                TimetableId: timetableId
            }
        });
    }

    HasEventsOutOfDates(agendaId: number, timetableId: number, start: Date, end: Date): Promise<IHasEventsOutOfDatesResult> {
        return this.ajaxService.Post<IHasEventsOutOfDatesResult>("Agenda-api", "Agendas/HasEventsOutOfDates", {
            methodData: {
                AgendaId: agendaId,
                TimetableId: timetableId,
                StartDate: start,
                EndDate: end
            }
        });
    }

    GetAgendasResources(agendaId: number, async: boolean = true):Promise<IAgendaResource[]> {
        return this.ajaxService.Post<IAgendaResource[]>("Agenda-api", "Agendas/GetAgendasResources", {
            methodData: {
                AgendaId: agendaId
            },
            async: async
        });
    }

    GetResourcesLabels(agendaId: number): Promise<string[]> {
        return this.ajaxService.Post<string[]>("Agenda-api", "Agendas/GetResourcesLabels", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    IsAgendaRelatedToLoggedUser(agendaId: number): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Agendas/IsAgendaRelatedToLoggedUser", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    GenerateTemporaryId(): number {
        return --this.lastId;
    }

    InsertOrUpdateAgendaSync(agendaSync : IAgendaSync) : Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Agendas/InsertOrUpdateAgendaSync", {
            methodData: agendaSync
        });
    }

    GetAgendaSync(agendaId : number) : Promise<IAgendaSync> {
        return this.ajaxService.Post<IAgendaSync>("Agenda-api", "Agendas/GetAgendaSync", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }

    DeleteAgendaSync(agendaId : number) : Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Agendas/DeleteAgendaSync", {
            methodData: {
                AgendaId: agendaId
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new AgendaService(serviceLocator);
}