import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { InvoiceEvent } from "../InvoiceEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class InvoiceEventDetailsEdit extends DocumentEventDetailsEdit
{
    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver, event : InvoiceEvent)
    {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "f-icon-doc-text";
        this.title = ProlifeSdk.TextResources.Invoices.Invoice;
        this.setValues(event);
    }

    public setValues(item : InvoiceEvent) : void
    {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent
    {
        var event : ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.InvoiceEventType_Invoice;
        return event;
    }
}