export enum ServiceTypes {
    Authentication = "Authentication",
    Localization = "Localization",
    Ajax = "Ajax",
    Authorization = "Authorization",
    Search = "Search",
    OPA = "OPA",
    Dialogs = "Dialogs",
    InfoToast = "InfoToast",
    AdvancedSearch = "AdvancedSearch"
}