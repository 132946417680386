import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import * as moment from "moment";
import { Moment } from "moment";
import { DateTimeUtilities } from "./utils/DateTimeUtilities";
import { PlannedEventsAlertDialog } from "./dialogs/PlannedEventsAlertDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAgendaTimetableViewModel, IAgendaTimeslotViewModel, IAgendaService, IAgendaTimetable, IAgendaTimeslot } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IAgendaViewModel, IHasEventsOutOfDatesResult } from "../../interfaces/IAgenda";
import { IEventOnDatesIntervalDetails, IEventsService } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";

export class AgendaTimetableViewModel implements IAgendaTimetableViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Timeslots: ko.ObservableArray<IAgendaTimeslotViewModel> = ko.observableArray();
    public ObserveFestivities: ko.Observable<boolean> = ko.observable(false);
    public IsVariation: ko.Observable<boolean> = ko.observable();
    public InEditing: ko.Observable<boolean> = ko.observable(false);
    public Deleted: ko.Observable<boolean> = ko.observable(false);
    public Visible: ko.Observable<boolean> = ko.observable(false);

    public NoTimeSlotsConfigured: ko.Computed<boolean>;
    public HasChanges: ko.Computed<boolean>;
    public IsNew: ko.Computed<boolean>;

    public WeekDays: DayColumn[] = [];

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private agendasService: IAgendaService;

    private oldStartDate: Date;
    private oldEndDate: Date;
    private oldDescription: string;
    private oldTimeslots: IAgendaTimeslotViewModel[] = [];

    private originalStartDate: Date;
    private originalEndDate: Date;

    private lastStartDate: Date;
    private lastEndDate: Date;

    constructor(private serviceLocator: IServiceLocator, private timeTable: IAgendaTimetable, private agenda: IAgendaViewModel) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);

        this.setOriginalDates();

        this.Id(this.timeTable.Id);
        this.StartDate(this.timeTable.StartDate ? moment(this.timeTable.StartDate).toDate() : null);
        this.EndDate(this.timeTable.EndDate ? moment(this.timeTable.EndDate).toDate() : null);
        this.Description(this.timeTable.Description);
        this.Timeslots(this.timeTable.Timeslots.map((ts: IAgendaTimeslot) => { return this.CreateViewModelForAgendaTimeSlot(ts); }));
        this.ObserveFestivities(this.timeTable.ObserveFestivities);
        this.IsVariation(this.timeTable.IsVariation);
        this.Visible(!this.timeTable.EndDate || moment(this.timeTable.EndDate) >= moment().startOf("day"));

        var weekStartDay: number = moment().startOf("week").day();

        this.WeekDays.push(new DayColumn(weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay, this));
        this.WeekDays.push(new DayColumn(++weekStartDay > 6 ? 0 : ++weekStartDay, this));

        this.NoTimeSlotsConfigured = ko.computed(() => {
            return this.Timeslots().filter((ts: IAgendaTimeslotViewModel) => { return !ts.Deleted(); }).length == 0;
        });

        this.HasChanges = ko.computed(() => {
            var hasModifiedTimeslots = false;
            var timeslots: IAgendaTimeslotViewModel[] = this.Timeslots();
            for (var i = 0; i < timeslots.length; i++) {
                if (timeslots[i].HasChanges()) {
                    hasModifiedTimeslots = true;
                    break;
                }
            }

            return moment(this.StartDate()).valueOf() != moment(this.timeTable.StartDate).valueOf()
                || moment(!<any>this.EndDate() ? '2100-01-01' : <any>this.EndDate()).valueOf() != moment(!<any>this.timeTable.EndDate ? '2100-01-01' : <any>this.timeTable.EndDate).valueOf()
                || this.Description() != this.timeTable.Description
                || this.Timeslots().length != this.timeTable.Timeslots.length
                || hasModifiedTimeslots;
        });

        this.IsNew = ko.computed(() => {
            return this.Id() <= 0;
        });

        this.lastStartDate = this.StartDate();
        var editingDates: boolean = false;
        this.StartDate.subscribe((value: Date) => {
            if (moment(!<any>value ? '2100-01-01' : <any>value).valueOf() == moment(!<any>this.lastStartDate ? '2100-01-01' : <any>this.lastStartDate).valueOf())
                return;

            if (!editingDates) {
                editingDates = true;

                if (moment(!<any>value ? '2100-01-01' : <any>value) < moment(!<any>this.originalStartDate ? '2100-01-01' : <any>this.originalStartDate) && this.IsVariation()) {
                    this.StartDate(this.originalStartDate);
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.TimetableVariationDateLimitError);
                    editingDates = false;
                    return;
                }

                this.agendasService.HasEventsOutOfDates(this.agenda.AgendaId(), this.Id(), value, this.EndDate())
                    .then((minimumInterval: IHasEventsOutOfDatesResult) => {
                        if (minimumInterval.HasEvents) {
                            this.StartDate(this.lastStartDate);
                            this.ShowAlertOnEventOutOfDates(minimumInterval);
                            return;
                        }
                        this.lastStartDate = value;
                        this.RefreshTimeslotsState();
                    })
                    .finally(() => {
                        editingDates = false;
                    });
            }
        });

        this.lastEndDate = this.EndDate();
        this.EndDate.subscribe((value: Date) => {
            if (moment(!<any>value ? '2100-01-01' : <any>value).valueOf() == moment(!<any>this.lastEndDate ? '2100-01-01' : <any>this.lastEndDate).valueOf())
                return;

            if (!editingDates) {
                editingDates = true;

                if (moment(!<any>value ? '2100-01-01' : <any>value) > moment(!<any>this.originalEndDate ? '2100-01-01' : <any>this.originalEndDate) && this.IsVariation()) {
                    this.EndDate(this.originalEndDate);
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.TimetableVariationDateLimitError);
                    editingDates = false;
                    return;
                }

                this.agendasService.HasEventsOutOfDates(this.agenda.AgendaId(), this.Id(), this.StartDate(), value)
                    .then((minimumInterval: IHasEventsOutOfDatesResult) => {
                        if (minimumInterval.HasEvents) {
                            this.EndDate(this.lastEndDate);
                            this.ShowAlertOnEventOutOfDates(minimumInterval);
                            return;
                        }
                        this.lastEndDate = value;
                        this.RefreshTimeslotsState();
                    })
                    .finally(() => {
                        editingDates = false;
                    });
            }
        });
    }
    
    private setOriginalDates(): void {
        let baseTimeTable = this.findBaseTimeTable();

        this.originalStartDate = baseTimeTable?.StartDate();
        this.originalEndDate = baseTimeTable?.EndDate();
    }
    
    private findBaseTimeTable(): IAgendaTimetableViewModel {
        const timetables = this.agenda.Timetables();
        const startDate = moment(this.timeTable.StartDate);
        const endDate = moment(this.timeTable.EndDate);

        return timetables.firstOrDefault(t => {
            const tStartDate = moment(t.StartDate() ?? "1900-01-01 00:00:00 +01:00");
            const tEndDate = moment(t.EndDate() ?? "2100-01-01 00:00:00 +01:00");

            return startDate.isSameOrAfter(tStartDate) && endDate.isSameOrBefore(tEndDate);
        });
    }

    public Edit(): void {
        this.SaveState();
        this.InEditing(true);
    }

    public Delete(): void {
        this.CheckEventsOnInterval()
            .then((events: IEventOnDatesIntervalDetails[]) => {
                if (events.length != 0)
                    return;

                this.dialogsService.Confirm(
                    ProlifeSdk.TextResources.Agenda.DeleteTimeTableMessage,
                    ProlifeSdk.TextResources.Agenda.CancelButton,
                    ProlifeSdk.TextResources.Agenda.ConfirmButton,
                    (confirm: boolean) => {
                        if (confirm)
                            this.Deleted(true);
                    }
                );
            });
    }

    public Abort(): void {
        this.RevertChanges();
        this.InEditing(false);
    }

    public Confirm(): void {
        if (!this.StartDate()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.TimetableStartDateRequiredError);
            return;
        }

        if (!this.IsVariation()) {
            this.SaveTimetable();
            return;
        }
        this.SaveTimetableVariation();
    }

    public IsValid(): boolean {
        var isValid: boolean = true;

        if (!this.StartDate() || !this.HasValidPeriod())
            return false;

        this.Timeslots().forEach((ts: IAgendaTimeslotViewModel) => {
            if (!ts.IsValid())
                isValid = false;
        });

        return isValid;
    }
    
    private HasValidPeriod(): boolean {
        let startDate = moment(this.StartDate() || "1900-01-01 00:00:00 +01:00");
        let endDate = moment(this.EndDate() || "2100-01-01 00:00:00 +01:00");

        return startDate.isSameOrBefore(endDate);
    }

    public GetCopy(asVariation: boolean): IAgendaTimetableViewModel {
        var timeTable: IAgendaTimetable = this.GetData();
        timeTable.IsVariation = asVariation;
        return new AgendaTimetableViewModel(this.serviceLocator, timeTable, this.agenda);
    }

    public GetData(): IAgendaTimetable {
        var timeTable: IAgendaTimetable = {
            Id: this.Id(),
            AgendaId: this.timeTable.AgendaId,
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            Description: this.Description(),
            Timeslots: this.Timeslots().filter((ts: IAgendaTimeslotViewModel) => { return !ts.Deleted(); }).map((ts: IAgendaTimeslotViewModel) => { return ts.GetData(); }),
            IsVariation: this.timeTable.IsVariation,
            ObserveFestivities: this.ObserveFestivities(),
            HasChanges: this.HasChanges()
        };
        return timeTable;
    }

    public AddTimeSlot(weekDay: number): void {
        var interval: any = this.GetIntervalForNewTimeslot(weekDay);
        var timeSlot: IAgendaTimeslot = {
            Id: this.agendasService.GenerateTemporaryId(),
            TimetableId: this.Id(),
            StartTime: interval.start,
            EndTime: interval.end,
            DayOfWeek: weekDay,
            EventsAvgDuration: 15,
            ContemporaryEventsNumber: 1,
            IsEditable: true,
            HasChanges: false
        };
        this.Timeslots.push(this.CreateViewModelForAgendaTimeSlot(timeSlot));
    }

    public IsWorkableDay(day: number): boolean {
        return this.Timeslots().filter((ts: IAgendaTimeslotViewModel) => { return ts.DayOfWeek() == day; }).length > 0;
    }

    public IsValidOnPeriod(periodStart: Moment, periodEnd: Moment): boolean {
        return (moment(this.StartDate()) <= periodStart && (!this.EndDate() || moment(this.EndDate()) >= periodStart))
            || (moment(this.StartDate()) <= periodEnd && (!this.EndDate() || moment(this.EndDate()) >= periodEnd))
            || (moment(this.StartDate()) >= periodStart && (!this.EndDate() || moment(this.EndDate()) <= periodEnd));
    }

    public GetTimeslotsForDay(date: Date): IAgendaTimeslotViewModel[] {
        var day = moment(date).day();
        return this.Timeslots().filter((t: IAgendaTimeslotViewModel) => { return t.DayOfWeek() == day; });
    }

    public Intersect(timetable: IAgendaTimetableViewModel): boolean {
        return this.Id() != timetable.Id()
            && (moment(timetable.StartDate()) >= moment(this.StartDate()) && (!this.EndDate() || moment(timetable.StartDate()) < moment(this.EndDate())))
            || (!timetable.EndDate() && moment(timetable.EndDate()) > moment(this.StartDate()));
    }

    private GetIntervalForNewTimeslot(weekDay: number): any {
        var start: Moment = moment().hours(9).minutes(0).seconds(0).milliseconds(0);
        var end: Moment = moment().hours(13).minutes(0).seconds(0).milliseconds(0);

        var timeslotsForDay: IAgendaTimeslotViewModel[] = this.Timeslots().filter((ts: IAgendaTimeslotViewModel) => { return ts.DayOfWeek() == weekDay; });

        if (timeslotsForDay.length == 0)
            return { start: start.toDate(), end: end.toDate() };

        var timeslot: IAgendaTimeslotViewModel = timeslotsForDay[timeslotsForDay.length - 1];

        start = moment(timeslot.EndTime()).add("hours", 1);
        end = moment(start).add("hours", 4);

        return { start: start.toDate(), end: end.toDate() };
    }

    private RefreshTimeslotsState() {
        this.Timeslots().forEach((t: IAgendaTimeslotViewModel) => {
            t.CheckEditableStatus();
        });
    }

    private ShowAlertOnEventOutOfDates(interval: IHasEventsOutOfDatesResult): void {
        var message: string = ProlifeSdk.TextResources.Agenda.ChangeTimetableDurationAlertMessage.replace("{start}", moment(interval.Start).format("l")).replace("{end}", moment(interval.End).format("l"));

        this.dialogsService.Alert(
            message,
            ProlifeSdk.TextResources.Agenda.ChangeTimetableDurationAlertLabel,
            () => {}
        );
    }

    private CreateViewModelForAgendaTimeSlot(timeSlot: IAgendaTimeslot): IAgendaTimeslotViewModel {
        return new TimeSlotViewModel(this.serviceLocator, timeSlot, this, this.agenda);
    }

    private SaveState(): void {
        this.oldStartDate = this.StartDate();
        this.oldEndDate = this.EndDate();
        this.oldDescription = this.Description();
        this.oldTimeslots = this.Timeslots().map((ts: IAgendaTimeslotViewModel) => {  return ts.GetCopy(); });
    }

    private RevertChanges(): void {
        this.lastStartDate = this.oldStartDate;
        this.lastEndDate = this.oldEndDate;

        this.StartDate(this.oldStartDate);
        this.EndDate(this.oldEndDate);
        this.Description(this.oldDescription);
        this.Timeslots(this.oldTimeslots);

        if (!this.oldStartDate && !this.oldEndDate && !this.oldDescription && this.oldTimeslots.length == 0)
            !this.IsVariation() ? this.agenda.Timetables.remove(this) : this.agenda.TimetablesVariations.remove(this);

    }

    private SaveTimetable(): void {
        var intersect = false;
        this.agenda.Timetables().forEach((t: IAgendaTimetableViewModel) => {
            if (!t.Deleted() && t.Intersect(this))
                intersect = true;
        });

        if (intersect) {
            this.dialogsService.Alert(ProlifeSdk.TextResources.Agenda.TimetablesOverlapMessage, ProlifeSdk.TextResources.Agenda.TimetablesOverlapLabel, () => {});
            return;
        }

        if (!this.IsNew()) {
            this.CheckEventsOutOfDatesAndSave();
            return;
        }

        this.SaveAndCloseEditing();
    }

    private SaveTimetableVariation(): void {
        if (!this.IsNew()) {
            this.CheckEventsOutOfDatesAndSave();
            return;
        }

        this.CheckEventsOnInterval()
            .then((events: IEventOnDatesIntervalDetails[]) => {
                if (events.length == 0)
                    this.SaveAndCloseEditing();
            });
    }

    private CheckEventsOnInterval(): Promise<IEventOnDatesIntervalDetails[]> {
        return this.agendasService.GetAgendaPlannedEventsOnInterval(this.agenda.AgendaId(), moment(this.StartDate()).toDate(), moment(!this.EndDate() ? moment('2100-01-01').toDate() : this.EndDate()).toDate())
            .then((events: IEventOnDatesIntervalDetails[]) => {
                if (events.length > 0) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.PlannedEventsOnIntervalAlertMessage);
                }
                return events;
            });
    }

    private CheckEventsOutOfDatesAndSave(): void {
        if (moment(this.StartDate()).valueOf() != moment(this.oldStartDate).valueOf() || moment(!this.EndDate() ? moment('2100-01-01').toDate() : this.EndDate()).valueOf() != moment(!this.oldEndDate ? moment('2100-01-01').toDate() : this.oldStartDate).valueOf()) {
            this.agendasService.HasEventsOutOfDates(this.agenda.AgendaId(), this.Id(), moment(this.StartDate()).toDate(), !this.EndDate() ? null : moment(this.EndDate()).toDate())
                .then((interval: IHasEventsOutOfDatesResult) => {
                    if (!interval.HasEvents) {
                        this.SaveAndCloseEditing();
                        return;
                    }

                    this.ShowAlertOnEventOutOfDates(interval);
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendaPlannedEventsSinceDateError);
                });
            return;
        }

        this.SaveAndCloseEditing();
    }

    private SaveAndCloseEditing(): void {
        this.SaveState();
        this.InEditing(false);
        this.Visible(!this.EndDate() || moment(this.EndDate()) >= moment().startOf("day"));
    }
}

export class TimeSlotViewModel implements IAgendaTimeslotViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public StartTime: ko.Observable<any> = ko.observable();
    public EndTime: ko.Observable<any> = ko.observable();
    public DayOfWeek: ko.Observable<number> = ko.observable();
    public EventsAvgDuration: ko.Observable<number> = ko.observable();
    public ContemporaryEventsNumber: ko.Observable<number> = ko.observable();

    public Deleted: ko.Observable<boolean> = ko.observable(false);
    public IsEditable: ko.Observable<boolean> = ko.observable(true);

    public HasChanges: ko.Computed<boolean>;

    private DateTimeUtilities: DateTimeUtilities;

    private eventsService: IEventsService;
    private agendasService: IAgendaService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private timeSlot: IAgendaTimeslot, private timetable: IAgendaTimetableViewModel, private agenda: IAgendaViewModel) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);

        this.DateTimeUtilities = new DateTimeUtilities();

        this.Id(this.timeSlot.Id);
        this.DayOfWeek(this.timeSlot.DayOfWeek);
        this.EventsAvgDuration(this.timeSlot.EventsAvgDuration);
        this.ContemporaryEventsNumber(this.timeSlot.ContemporaryEventsNumber);

        this.StartTime(moment(this.timeSlot.StartTime).local().toDate());
        this.EndTime(moment(this.timeSlot.EndTime).local().toDate());

        this.CheckEditableStatus();

        var startUpdating = false;
        this.StartTime.subscribe((value: any) => {
            if (!startUpdating) {
                startUpdating = true;
                var start: Moment = moment(value);
                var end: Moment = moment(this.EndTime());

                if (moment(start).startOf('day').valueOf() != moment(end).startOf('day').valueOf()) {
                    var time: number[] = end.format("LT").split(":").map((t: string) => parseInt(t));
                    end = moment(start).hours(time[0]).minutes(time[1]).seconds(0);
                    this.EndTime(end.toDate());
                }

                if (start > end)
                    this.EndTime(value);
                startUpdating = false;
            }
        });

        var endUpdating = false;
        this.EndTime.subscribe((value) => {
            if (!endUpdating) {
                endUpdating = true;
                var start: Moment = moment(this.StartTime());
                var end: Moment = moment(value);

                if (moment(start).startOf('day').valueOf() != moment(end).startOf('day').valueOf()) {
                    var time: number[] = start.format("LT").split(":").map((t: string) => parseInt(t));
                    start = moment(end).hours(time[0]).minutes(time[1]).seconds(0);
                    this.StartTime(start.toDate());
                }

                if (start > end)
                    this.EndTime(this.StartTime());

                this.AdjustEndTime(this.EventsAvgDuration());
                endUpdating = false;
            }
        });

        var durationUpdating: boolean = false;
        this.EventsAvgDuration.subscribe((duration: number) => {
            if (!durationUpdating) {
                durationUpdating = true;
                this.AdjustEndTime(duration);
                durationUpdating = false;
            }
        });

        this.HasChanges = ko.computed(() => {
            return this.DayOfWeek() != this.timeSlot.DayOfWeek
                || this.EventsAvgDuration() != this.timeSlot.EventsAvgDuration
                || this.ContemporaryEventsNumber() != this.timeSlot.ContemporaryEventsNumber
                || moment(this.StartTime()).local().format("LT") != moment(this.timeSlot.StartTime).local().format("LT")
                || moment(this.EndTime()).local().format("LT") != moment(this.timeSlot.EndTime).local().format("LT")
        });
    }
    
    public IsValid(): boolean {
        let startTime = this.StartTime();
        let endTime = this.EndTime();

        return !!startTime 
            && !!endTime
            && moment(moment(startTime).format('LT'), 'HH:mm').isSameOrBefore(moment(moment(endTime).format('LT'), 'HH:mm'));
    }

    public Delete(): void {
        if (this.agenda.TimeslotsModeEnabled()) {
            var startDate = moment(this.timetable.StartDate()) > moment() ? moment(this.timetable.StartDate()).startOf("day").toDate() : moment().startOf("day").toDate();
            var endDate = !this.timetable.EndDate() ? null : moment(this.timetable.EndDate()).endOf("day").toDate();
            var startTime = moment(this.StartTime()).toDate();
            var endTime = moment(this.EndTime()).toDate();

            this.eventsService.GetEventsOnDatesAndTimesIntervals(this.agenda.AgendaId(), startDate, endDate, startTime, endTime)
                .then((events: IEventOnDatesIntervalDetails[]) => {
                    if (events.length == 0) {
                        this.InternalDelete();
                        return;
                    }

                    let message = String.format(ProlifeSdk.TextResources.Agenda.PlannedEventsDialogTextWithTimeslots,
                        moment(startDate).format("DD/MM/YYYY HH:mm"),
                        !endDate ? ProlifeSdk.TextResources.Agenda.PlannedEventsDialogNullEndDate : moment(endDate).format("DD/MM/YYYY HH:mm"),
                        startTime + ' - ' + endTime);

                    let plannedEventsDialog = new PlannedEventsAlertDialog(this.serviceLocator, events, message);
                    plannedEventsDialog.Show();
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.DeleteTimeSlotError);
                });
            return;
        }

        this.InternalDelete();
    }

    public GetCopy(): IAgendaTimeslotViewModel {
        var copy: IAgendaTimeslotViewModel = new TimeSlotViewModel(this.serviceLocator, this.GetData(), this.timetable, this.agenda);
        return copy;
    }

    public GetData(): IAgendaTimeslot {
        var timeSlot: IAgendaTimeslot = {
            Id: this.Id(),
            TimetableId: this.timeSlot.TimetableId,
            StartTime: this.StartTime(),
            EndTime: this.EndTime(),
            DayOfWeek: this.DayOfWeek(),
            EventsAvgDuration: this.EventsAvgDuration(),
            ContemporaryEventsNumber: this.ContemporaryEventsNumber(),
            IsEditable: this.IsEditable(),
            HasChanges: this.HasChanges()
        };
        return timeSlot;
    }

    public IsTimeslotRespected(eventStart: Date, eventEnd: Date, checkOnEventDuration: boolean = false): boolean {
        var duration: number = moment(eventEnd).diff(moment(eventStart), 'minutes');
        return moment(eventStart).day() == this.DayOfWeek() && moment(eventEnd).day() == this.DayOfWeek()
            && moment(moment(eventStart).format('LT'), 'HH:mm') >= moment(this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(moment(eventStart), this.StartTime()), 'HH:mm')
            && moment(moment(eventEnd).format('LT'), 'HH:mm') <= moment(this.DateTimeUtilities.GetTimeslotHourWithDaylightSavingTimeCheck(moment(eventStart), moment(this.EndTime())), 'HH:mm')
            && (!checkOnEventDuration || duration <= this.EventsAvgDuration());
    }

    public CheckEditableStatus(): Promise<boolean> {
        return this.agendasService.HasEventsOnTimeslot(this.Id(), this.agenda.AgendaId(), this.timetable.Id())
            .then((hasEvents: boolean) => {
                this.IsEditable(!hasEvents);
                return hasEvents;
            });
    }

    private AdjustEndTime(duration: number): void {
        var res = moment(moment(this.EndTime()).format("LT"), "HH:mm").diff(moment(moment(this.StartTime()).format("LT"), "HH:mm"), "minutes") % duration;
        this.EndTime(moment(this.EndTime()).add("minutes", -res).toDate());
    }

    private InternalDelete(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.DeleteTimeSlotMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.Deleted(true);

            }
        );
    }
}

export class DayColumn {
    public ColumnTitle: ko.Observable<string> = ko.observable();

    public ColumnTimeSlots: ko.Computed<IAgendaTimeslotViewModel[]>;
    public NoTimeSlotsConfigured: ko.Computed<boolean>;

    constructor(private columnDay: number, private TimeTable: IAgendaTimetableViewModel) {
        var weekStart: number = moment().startOf("week").day();
        var columnDayForTitle = this.columnDay - weekStart;

        this.ColumnTitle((<any>moment()).weekday(columnDayForTitle).format('ddd').toLocaleUpperCase());

        this.ColumnTimeSlots = ko.computed(() => {
            return this.TimeTable.Timeslots().filter((ts: IAgendaTimeslotViewModel) => { return ts.DayOfWeek() == this.columnDay && !ts.Deleted(); });
        });

        this.NoTimeSlotsConfigured = ko.computed(() => {
            return this.ColumnTimeSlots().length == 0;
        });
    }

    public AddTimeSlot(): void {
        this.TimeTable.AddTimeSlot(this.columnDay);
    }
}