import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { NumberInput } from "../../NumberInput";
import { ReportComponentWithPosition } from "../Components/ReportComponentWithPosition";
import { EditorFor } from "./ReportEditorProvider";
import { SegmentedInput } from "../../SegmentedInput";

type ReportPositionEditorProps = {
    component: ReportComponentWithPosition;
};

export function ReportPositionEditor(props: ReportPositionEditorProps) {
    const C = require("./ReportPositionEditor")._ReportPositionEditor as typeof _ReportPositionEditor;
    return <C {...props} />;
}

@EditorFor("Position")
export class _ReportPositionEditor {
    static defaultProps: Partial<ReportPositionEditorProps> = {
    }

    minWidthReadOnly : ko.Computed<boolean>;
    minHeightReadOnly : ko.Computed<boolean>;

    constructor(private props : ReportPositionEditorProps) {
        this.minWidthReadOnly = ko.computed(() => {
            return props.component.autoSize() === "none" || props.component.autoSize() === "vertical";
        })

        this.minHeightReadOnly = ko.computed(() => {
            return props.component.autoSize() === "none" || props.component.autoSize() === "horizontal";
        })
    }
    
    render() {
        const { component } = this.props;

        return  <div className="editor-group">
                    <span className="editor-group-title">Posizione</span>
                    <div className="editor-group-body">
                        <div className="row">
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="X" suffix="cm" value={component.x} />
                            </div>
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Y" suffix="cm" value={component.y} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Larg." suffix="cm" minValue={0} value={component.width} />
                            </div>
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Alt." suffix="cm" minValue={0} value={component.height} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <SegmentedInput label="Dimensione Automatica" options={[{ text: "Nessuna", value: "none" },{ text: "Orizz.", value: "horizontal" },{ text: "Vert.", value: "vertical" },{ text: "Auto", value: "both" }]} value={component.autoSize} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Larg.Min." suffix="cm" minValue={0} value={component.minWidth} readOnly={this.minWidthReadOnly} />
                            </div>
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Larg.Max." suffix="cm" minValue={0} value={component.maxWidth} readOnly={this.minWidthReadOnly} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Alt.Min." suffix="cm" minValue={0} value={component.minHeight} readOnly={this.minHeightReadOnly} />
                            </div>
                            <div className="col-md-6">
                                <NumberInput selectOnFocus label="Alt.Max." suffix="cm" minValue={0} value={component.maxHeight} readOnly={this.minHeightReadOnly} />
                            </div>
                        </div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportPositionEditor);
}