import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { Deferred } from "../Core/Deferred";

export abstract class BaseDataSource<T extends IDataSourceModel<K, M>, K extends (string | number) = (string | number), M = any> implements IDataSource<K,M> {
    protected view: IDataSourceView;
    protected refreshRequested = false;
    protected selectRequested = false;
    protected selectRequest: T[] = null;
    protected deferredSelect: Deferred<void>;

    abstract getTitle(currentModel: T): string;
    
    isGroupedData(currentModel: T, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: T, b: T): boolean {
        return (!a && !b) || (!!a && !!b && a.id == b.id);
    }
    
    abstract getData(currentModel: T, textFilter: string, skip: number, count: number): Promise<T[]>;
    abstract getById(currentModel: T, ids: (number | string)[]): Promise<T[]>;
    
    protected notifyModelCreated(currentModel: T, newModel: T) {
        if(!this.view)
            return;
        this.view.notifyModelCreated && this.view.notifyModelCreated(currentModel, newModel);
    }

    protected notifyModelChanged(currentModel: T, changedModel: T) {
        if(!this.view)
            return;
        this.view.notifyModelChanged && this.view.notifyModelChanged(currentModel, changedModel);
    }

    protected notifyModelDeleted(currentModel: T, deletedModel: T) {
        if(!this.view)
            return;
        this.view.notifyModelDeleted && this.view.notifyModelDeleted(currentModel, deletedModel);
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }

        if(this.selectRequested) {
            this.selectRequested = false;
            this.select(...this.selectRequest);
        }
    }

    refresh(keepSelection?: boolean) {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh(keepSelection);
    }

    select(...items: T[]) : Promise<void> {
        if(!this.deferredSelect)
            this.deferredSelect = new Deferred<void>();

        if(!this.view) {
            this.selectRequested = true;
            this.selectRequest = items.slice();
            return this.deferredSelect.promise();
        }

        this.view.select(...items)
            .then(() => {
                this.deferredSelect.resolve();
                this.deferredSelect = null;
            })
            .catch(() => {
                if (this.deferredSelect)
                    this.deferredSelect.reject();
                
                this.deferredSelect = null;
            });

        return this.deferredSelect.promise();
    }

    selectByIds(...ids: K[]) {
        return this.select(...ids.map(id => (<T>{ id: id, title: "", isLeaf: true, isGroup: false, model: null })));
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}