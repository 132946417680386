import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Tag } from "../../../../ProlifeSdk/prolifesdk/blog/Tag";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISearchResultsProvider } from "../../../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { IEntityProvider } from "../../../../ProlifeSdk/interfaces/IEntityProvider";
import { ITag } from "../../../../ProlifeSdk/interfaces/ITag";
import { ICustomer, ICustomerHint } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class ContactList implements ISearchResultsProvider {
    private customerEntityProvider: IEntityProvider<number, ICustomerHint | ICustomer>;
    private humanResourcesProvider: IEntityProvider<number, IHumanResource>;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService: IEntityProviderService;

    private lastTimeout: ReturnType<typeof setTimeout>;
    private partecipantiTags: ko.Observable<string> = ko.observable(); //Solo stringhe
    private tagsName: string;

    constructor(tagsName: string) {
        this.tagsName = tagsName;
        this.customerEntityProvider = this.entityProviderService.getEntityProvider(ProlifeSdk.CustomerEntityType);
        this.humanResourcesProvider = this.entityProviderService.getEntityProvider(ProlifeSdk.HumanResources);
    }

    private search(searchFilter: string, callback: (data: any) => void) {
        const data = { results: [] };

        const promise = this.humanResourcesProvider
            .hintSearch(searchFilter)
            .then((items: IHumanResource[]) => {
                const displayname: string = searchFilter;
                const tag: ITag = <ITag>{
                    TagName: this.tagsName,
                    TagTypeId: ProlifeSdk.TagType_String,
                    Value: displayname,
                };
                data.results.unshift({
                    id: JSON.stringify(tag) + "#" + displayname,
                    text: displayname,
                });

                if (items.length == 0) return;

                const groupResult = {
                    text: ProlifeSdk.TextResources.ProlifeSdk.Resources,
                    children: [],
                };
                data.results.push(groupResult);

                items.forEach((item: IHumanResource) => {
                    const tag: ITag = <ITag>{
                        TagName: this.tagsName,
                        TagTypeId: this.humanResourcesProvider.getType(),
                        Value: this.humanResourcesProvider.getPkValue(item),
                    };
                    const displayname: string = item.Resource.Name + " " + item.Resource.Surname;

                    groupResult.children.push({
                        id: JSON.stringify(tag) + "#" + displayname,
                        text: displayname,
                    });
                });
            })
            .catch(this.LoadCustomersFailed.bind(this));

        const promise2 = this.customerEntityProvider
            .hintSearch(searchFilter)
            .then((items: ICustomerHint[]) => {
                if (items.length == 0) return;

                const groupResult = {
                    //id: 'Header:Commessa',
                    text: ProlifeSdk.TextResources.ProlifeSdk.AddressBook,
                    children: [],
                };
                data.results.push(groupResult);

                items.forEach((item: ICustomerHint) => {
                    const tag: ITag = <ITag>{
                        TagName: this.tagsName,
                        TagTypeId: this.customerEntityProvider.getType(),
                        Value: this.customerEntityProvider.getPkValue(item),
                    };
                    const displayname: string = item.FormattedContactName;

                    groupResult.children.push({
                        id: JSON.stringify(tag) + "#" + displayname,
                        text: displayname,
                    });
                });
            })
            .catch(this.LoadCustomersFailed.bind(this));

        Promise.all([promise, promise2]).then(() => callback(data));
    }

    private searchById(serializedItem: string) {
        const result: any[] = [];
        const tags: string[] = serializedItem.split("|");
        tags.forEach((serializedTag: string) => {
            const tagParts: string[] = serializedTag.split("#");
            const tag = JSON.parse(tagParts[0]) as ITag;
            const displayValue = tagParts[1] ?? tag.DisplayName ?? tag.Value;
            result.push({ id: serializedTag, text: displayValue });
        });

        return result;
    }

    private LoadCustomersFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any) {
        this.infoToastService.Error(jqXHR.statusText);
    }

    public findSearchMatches(query: any) {
        if (this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.search(query.term, query.callback);
        }, 500);
    }

    public findSearchMatch(element, callback) {
        const id = <string>$(element).val();
        if (id !== "") {
            const result = this.searchById(id);
            callback(result);
        }
    }

    public addElements(elements: Tag[]) {
        const result = elements
            .map((tag: Tag) => {
                return JSON.stringify(tag.getTag()) + "#" + tag.displayName();
            })
            .join("|");
        if (this.partecipantiTags().length > 0) this.partecipantiTags(this.partecipantiTags() + "|" + result);
        else this.partecipantiTags(result);
    }

    public getElement(): ITag[] {
        if (this.partecipantiTags().length == 0) return [];

        const tags: string[] = this.partecipantiTags().split("|");

        return tags.map((serializedTag: string) => {
            return JSON.parse(serializedTag.split("#")[0]);
        });
    }

    public clear() {
        return this.partecipantiTags("");
    }
}
