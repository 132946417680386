/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/10/2017
 * Time: 08:43
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { CartWorkableHoursCalculationModes } from "../../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { ICartLink, ICartsService } from "../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ICart, ICartResource } from "../../../../ProlifeSdk/interfaces/allocations/ICart";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { CartWorkInfo } from "./editCartDialog/CartWorkInfo";
import { TabNav, TabNavPage } from "../../../../Components/TabNavComponent";
import { CartAllocations } from "./editCartDialog/CartAllocations";
import { CartEditor, _CartEditor } from "./editCartDialog/CartEditor";

const { classes } = jss.createStyleSheet({
    "element-title": {
        "& span": {
            display: "inline-block",

            "&.title-icon": {
                width: "30px",
                textAlign: "center"
            }
        }
    },

    "cart-work-info": {
        maxHeight: "180px"
    }
}).attach();

export type CartEditorResult = {
    cart: ICart;
    action: "REOPEN"|"SAVE"|"CLOSE"|"DELETE";
}

export type EditCartDialogProps = {
    cart?: ICart;
    resources: ICartResource[];
    links: ICartLink[];
    forceSaving?: boolean;
}

export class EditCartDialog extends DialogComponentBase {
    static defaultProps: Partial<EditCartDialogProps> = {
        resources: [],
        links: [],
        forceSaving: false
    }

    public EditingMode: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private editor: _CartDialogEditor;
    
    constructor(private props: EditCartDialogProps) {
        super({ className: (!props.cart || !props.cart.Id || props.cart.Id <= 0) ? "large" : "fullscreen" });
        this.title(this.props.cart?.Title ?? TextResources.Allocations.EditCartDilaogAddTitle);

        this.EditingMode = ko.computed(() => {
            return !!this.props.cart && !!this.props.cart.Id && this.props.cart.Id > 0;
        });
    }

    action() {
        this.editor
            .action()
            .then((cart: ICart) => {
                if (cart)
                    this.modal.close({ cart: cart, action: "SAVE" });
            });
    }

    close() {
        let cart = null;

        if (this.props.cart && this.props.cart.Id && this.props.cart.Id > 0)
            cart = this.editor.close();

        this.modal.close({ cart: cart, action: "CLOSE" });
    }

    async saveAndReopen() {
        const cart: ICart = await this.editor.action();
        if (cart)
            this.modal.close({ cart: cart, action: "REOPEN" });
    }

    async deleteCart(): Promise<void> {
        if (!this.editor)
            return;

        const deleteSucceded = await this.editor.deleteCart();
        if (deleteSucceded)
            this.modal.close({ cart: null, action: "DELETE" });
    }

    show(): Promise<CartEditorResult> {
        return this.dialogsService.ShowModal(this);
    }

    renderHeader() {
        const editCartDialog = this;

        return ComponentUtils.bindTo((
            <>
                {!this.props.forceSaving && <button type="button" class="close" data-bind={{  click: editCartDialog.close.bind(editCartDialog) }}></button>}
                <h4 class="modal-title" data-bind={{ text: editCartDialog.title }}></h4>
            </>
        ), this, "editCartDialog") as React.ReactElement;
    }

    renderBody() {
        return  <CartDialogEditor
                    {...this.props}
                    forwardRef={(editor) => this.editor = editor}
                    onCartNameChanged={(cartName) => this.title(cartName)}
                />;
    }

    renderFooter() {
        const editCartDialog = this;

        return ComponentUtils.bindTo((
            <>
                <a href="#" className="btn btn-danger" data-bind={{ click: editCartDialog.deleteCart.bind(editCartDialog), visible: editCartDialog.EditingMode }}>{TextResources.ProlifeSdk.Delete}</a>
                {!this.props.forceSaving && <a href="#" className="btn btn-default" data-bind={{ click: editCartDialog.close.bind(editCartDialog) }}>{TextResources.ProlifeSdk.Close}</a>}
                <a href="#" className="btn btn-success" data-bind={{ click: editCartDialog.saveAndReopen.bind(editCartDialog), visible: !editCartDialog.EditingMode() }}>{TextResources.Allocations.SaveAndReopen}</a>
                <a href="#" className="btn btn-primary" data-bind={{ click: editCartDialog.action.bind(editCartDialog), visible: !editCartDialog.EditingMode() }}>{TextResources.Allocations.SaveAndClose}</a>
            </>
        ), this, "editCartDialog") as React.ReactElement;
    }
}

type CartDialogEditorProps = EditCartDialogProps & {
    forwardRef?: (editor: _CartDialogEditor) => void;
    onCartNameChanged?: (cartName: string) => void;
}

export function CartDialogEditor(props: CartDialogEditorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./EditCartDialog")._CartDialogEditor as typeof _CartDialogEditor;
    return <C {...props} />;
}

export class _CartDialogEditor {
    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    public CartWorkableHoursCalculationMode: ko.Observable<CartWorkableHoursCalculationModes> = ko.observable(CartWorkableHoursCalculationModes.JobOrderSpeed);

    private editorRef: _CartEditor;

    constructor(private props: CartDialogEditorProps) {
        this.props.forwardRef && this.props.forwardRef(this);
    }

    close(): ICart {
        const cartData: ICart = this.editorRef.getCartData();
        return cartData;
    }

    async action(): Promise<ICart> {
        return await this.editorRef.save();
    }
    
    async deleteCart(): Promise<boolean> {
        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Allocations.ConfirmCartDeletingMessage,
            TextResources.Allocations.CancelCartDeleting,
            TextResources.Allocations.ConfirmCartDeleting);
            
        if (confirm) {
            try {
                await this.cartsService.deleteCart(this.props.cart.Id);
                this.infoToastService.Success(TextResources.Allocations.DeleteCartSuccess);
                return true;
            } catch {
                this.infoToastService.Error(TextResources.Allocations.DeleteCartError);
                return false;
            }
        }

        return false;
    }

    render() {
        const isNew = !this.props.cart || !this.props.cart.Id || this.props.cart.Id <= 0;

        const rows = ["1fr"];
        if (!isNew)
            rows.unshift("min-content");

        const CartDataEditor =  () =>   <CartEditor
                                            cart={this.props.cart}
                                            resources={this.props.resources}
                                            links={this.props.links}
                                            forceSaving={this.props.forceSaving}
                                            forwardRef={(editor) => this.editorRef = editor}
                                            onCartNameChanged={this.props.onCartNameChanged}
                                            onCartWorkableHoursCalculationModeChange={(mode) => this.CartWorkableHoursCalculationMode(mode)}
                                        />;

        return  <Layout.Grid rows={rows} columns={["1fr"]} style={{ height: "100%" }}>
                    {!isNew && (
                        <Layout.Grid.Cell row={1} column={1}>
                            <CartWorkInfo
                                cartId={this.props.cart.Id}
                                workableHoursCalculationMode={this.CartWorkableHoursCalculationMode}
                                className={classes["cart-work-info"]}
                            />
                        </Layout.Grid.Cell>
                    )}
                    <Layout.Grid.Cell row={isNew ? 1 : 2} column={1} className="flex-vertical">
                        {isNew && <CartDataEditor />
                        }
                        {!isNew && (
                            <TabNav className="flex-fill">
                                <TabNavPage title={TextResources.Allocations.CartTabTitle}>
                                    {() => <CartDataEditor />}
                                </TabNavPage>
                                <TabNavPage title={TextResources.Allocations.AllocationsTabTitle}>
                                    {() =>  <CartAllocations
                                                cartId={this.props.cart?.Id}
                                                onCartInfoEdit={() => this.editorRef.refreshMenu()}
                                            />
                                    }
                                </TabNavPage>
                            </TabNav>
                        )}
                    </Layout.Grid.Cell>
                </Layout.Grid>;
    }
}