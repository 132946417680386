import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { SalDefaultValuesSettingsUi } from "../ui/documents/default-values/SalDefaultValuesSettingsUi";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class SalDocumentsProvider extends DocumentsProvider
{
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private protocol : IVatRegister)
    {
        super(ProlifeSdk.SalEntityTypeCode);

        this.Id = protocol.IdRegistroIVA;
        this.Name(protocol.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(protocol.NuovoLabel);
        this.ProviderId = "Sal" + this.Id;
		this.CanEdit = protocol.Stato == ProlifeSdk.EnabledProtocolState;

        this.HasDefaultValuesSettingsUi = true;
        this.CanCreateNew(protocol.Stato == ProlifeSdk.EnabledProtocolState && this.authorizationService.isAuthorized("Documents_Invoices"));
    }

    GetDocumentGroupLabel(): string {
        return this.protocol.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.protocol.PositionInList;
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        return [{
            DocumentType: ProlifeSdk.SalEntityTypeCode,
            NewLabel: this.protocol.NuovoLabel
        }];
    }

    GetDefaultValuesSettingsUi() {
        return new SalDefaultValuesSettingsUi(this.protocol, this.ProviderId);
    }
}