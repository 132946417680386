import * as ko from "knockout";
/**
 * Created by d.collantoni on 30/05/2019.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderEntityCodeGeneratorEditingViewModel } from "./ui/JobOrderEntityCodeGeneratorEditingViewModel";
import { EntityCodeGeneratorSettingsManager } from "../../../ProlifeSdk/prolifesdk/settings/EntityCodeGeneratorSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class JobOrderEntityCodeGeneratorSettingsManager extends EntityCodeGeneratorSettingsManager
{
    constructor(serviceLocator : IServiceLocator) {
        super(serviceLocator, ProlifeSdk.JobOrderEntityTypeCode);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.JobOrder.OrdersSettingsGroup);
    }

    getName(): string {
        return ProlifeSdk.JobOrderCodeGeneratorType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.JobOrder.TypeCodes;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI():IView {
        return new JobOrderEntityCodeGeneratorEditingViewModel(this.serviceLocator, this);
    }
}
