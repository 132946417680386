import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/08/2017
 * Time: 09:16
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISampleService } from "../../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { ISelect2Query } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { ISampleEnvelope } from "../../../../../ProlifeSdk/interfaces/survey/ISample";

export class SamplesProvider {
    private sampleService: ISampleService;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(private serviceLocator: IServiceLocator) {
        this.sampleService = <ISampleService> this.serviceLocator.findService(ProlifeSdk.SampleServiceType);
    }

    public GetSamples(query: ISelect2Query) {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.sampleService.getLimitedSamples(query.term)
                .then((samples: ISampleEnvelope[]) => {
                    query.callback({
                        results: samples.map((s: ISampleEnvelope) => {
                            return {
                                id: s.Id,
                                text: s.Title
                            }
                        })
                    });
                })
                .catch(() => {
                    query.callback({
                        results: []
                    });
                });
        }, 500);
    }

    public GetSample(element: HTMLElement, callback: (result: { id: number; text: string; }) => void) {
        var id = parseInt(<string>$(element).val());
        if (!id)
            return;

        this.sampleService.getSampleEnvelope(id)
            .then((sample: ISampleEnvelope) => {
                callback({
                    id: sample.Id,
                    text: sample.Title
                });
            });
    }
}