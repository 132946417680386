import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { IDocumentsService, SentMail } from "../../DocumentsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { List } from "../../../Components/ListComponent";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { Layout } from "../../../Components/Layouts";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import moment = require("moment");

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type MailLogModalProps = {
    documentId: number;
};

export class MailLogModal extends DialogComponentBase {
    static defaultProps: Partial<MailLogModalProps> = {};

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private log: ko.ObservableArray<SentMail> = ko.observableArray([]);

    constructor(private props: MailLogModalProps) {
        super({ noPrompt: true });
        this.title(TextResources.Invoices.DocumentMailLogTitle);
        this.loadLog();
    }

    private async loadLog(): Promise<void> {
        const log = await this.documentsService.getMailSentLog(this.props.documentId);
        this.log(log);
    }

    action() {
        this.modal.close();
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return (
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]}>
                <LogHeader />
                <List
                    containerHeight={"600px"}
                    systemScrollable
                    emptyResultMessage={TextResources.Invoices.DocumentMailLogEmptyResultMessage}
                    dataSource={Table.defaultDataSource(this.log, (item) => ({
                        id: item.documentId,
                        title: String.format(
                            TextResources.Invoices.DocumentMailLogRowTitle,
                            moment(item.sentAt).format("L LTS"),
                            item.sentBy
                        ),
                    }))}
                />
            </Layout.Grid>
        );
    }
}

class LogHeader {
    render() {
        return <div></div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MailLogModal);
}
