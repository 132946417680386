import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as numeral from "numeral";
import jss from "jss"
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { DocumentRow } from "./DocumentRows";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { DiscountsUtilities } from "../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources"
import { MoneyInput } from "../../../Components/MoneyInput";
import { DocumentLayouts } from "./DocumentLayouts";

const styleSheet = jss.createStyleSheet({
    calculator: {
        width: "250px",

        "& .row-total": {
            marginBottom: "15px"
        },

        "& button": {
            width: "100%",
            textAlign: "left"
        }
    }
});
const { classes } = styleSheet.attach();

type RowValuesCalculatorProps = {
    row: DocumentRow;
    documentLayout: DocumentLayouts;

    onAmountCalculated?: (amount: number) => void;
    onUnitPriceCalculated?: (unitPrice: number) => void;
    onNetUnitPriceCalculated?: (netUnitPrice: number) => void;
    onDiscountCalculated?: (discount: string) => void;
}

export class RowValuesCalculator extends DialogComponentBase {
    static defaultProps: Partial<RowValuesCalculatorProps> = {
    }

    private RowFinalPrice : ko.Observable<number> = ko.observable(0);

    Amount : ko.Observable<number> = ko.observable(0);
    UnitPrice : ko.Observable<number> = ko.observable(0);
    Discount : ko.Observable<number> = ko.observable(0);
    DiscountValue : ko.Observable<string> = ko.observable("0%");
    NetUnitPrice : ko.Observable<number> = ko.observable(0);

    private subscriptions: ko.Subscription[] = [];

    constructor(private props : RowValuesCalculatorProps) {
        super({ popover: true });

        this.title(TextResources.Invoices.RowValuesCalculatorTitle);
    }

    componentDidMount() {
        this.subscriptions.push(this.RowFinalPrice.subscribe(this.updateCalculations.bind(this)));
        this.subscriptions.push(this.props.row.TotalPriceInDocumentCurrency.subscribe(this.updateCalculations.bind(this)));

        this.RowFinalPrice(this.props.row.TotalPriceInDocumentCurrency());
    }
    
    componentWillUnmount() {
        this.subscriptions.forEach(s => s.dispose());
        this.subscriptions = [];
    }

    action() {
        this.modal.close();
    }

    reloadRowFinalPrice(): void {
        this.RowFinalPrice(this.props.row.TotalPriceInDocumentCurrency());
    }

    private updateCalculationsOnFinalPriceChanges(finalPrice: number): void {
        this.calculateAmount(finalPrice);
        this.calculateUnitPrice(finalPrice);
        this.calculateNetUnitPrice(finalPrice);
        this.calculateDiscount(finalPrice);
    }

    private updateCalculations(): void {
        this.calculateAmount(this.RowFinalPrice());
        this.calculateUnitPrice(this.RowFinalPrice());
        this.calculateNetUnitPrice(this.RowFinalPrice());
        this.calculateDiscount(this.RowFinalPrice());
    }

    notifyAmount(): void {
        const amount = this.Amount();
        this.props.onAmountCalculated && this.props.onAmountCalculated(amount);
    }

    private calculateAmount(finalPrice: number): void {
        const requestedPrice = finalPrice;
        const actualUnitPrice = this.props.row.NetUnitPriceInDocumentCurrency();
        const amount = !actualUnitPrice ? 0 : requestedPrice / actualUnitPrice;
        this.Amount(amount);
    }
    
    notifyUnitPrice(): void {
        const unitPrice = this.UnitPrice();
        this.props.onUnitPriceCalculated && this.props.onUnitPriceCalculated(unitPrice);
    }
    
    private calculateUnitPrice(finalPrice: number): void {
        const requestedPrice = finalPrice;
        const actualAmount = this.props.row.Amount();

        const actualDiscount = this.props.row.Discounts();
        const discounts = DiscountsUtilities.calculateDiscount(actualDiscount);
        
        const unitPrice = !actualAmount ? 0 : requestedPrice / (actualAmount * discounts);
        this.UnitPrice(unitPrice);
    }

    notifyNetUnitPrice(): void {
        const netUnitPrice = this.NetUnitPrice();
        this.props.onNetUnitPriceCalculated && this.props.onNetUnitPriceCalculated(netUnitPrice);
    }
    
    private calculateNetUnitPrice(finalPrice: number): void {
        const requestedPrice = finalPrice;
        const actualAmount = this.props.row.Amount();
        const netUnitPrice = !actualAmount ? 0 : requestedPrice / actualAmount;

        this.NetUnitPrice(netUnitPrice);
    }

    notifyDiscount(): void {
        const discount = this.DiscountValue();
        this.props.onDiscountCalculated && this.props.onDiscountCalculated(discount);
    }

    private calculateDiscount(finalPrice: number): void {
        const requestedPrice = finalPrice;
        const amount = this.props.row.Amount();
        const unitPrice = this.props.row.UnitPriceInDocumentCurrency();

        const denominator = amount * unitPrice;

        if (!denominator) {
            this.Discount(0);
            this.DiscountValue("0%");
        } else {
            const discount = 1 - (requestedPrice / denominator);
            this.Discount(discount);
            this.DiscountValue(numeral(discount).format("0.[########]%"));
        }
    }
    
    renderBody() {
        const calculator = this;

        return ComponentUtils.bindTo((
            <div className={"row " + classes.calculator}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-group row-total">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary" data-bind={{ click: calculator.reloadRowFinalPrice.bind(calculator) }}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </span>
                                <MoneyInput
                                    value={this.RowFinalPrice}
                                    placeholder={TextResources.Invoices.RowTargetTotalPrice}
                                    selectOnFocus
                                    simple
                                    updateAfterKeyDown

                                    onChange={this.updateCalculationsOnFinalPriceChanges.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <button className="btn btn-primary" data-bind={{ click: calculator.notifyAmount.bind(calculator) }}>
                                {TextResources.Invoices.DocumentRowAmount} = <span data-bind={{ numberText: calculator.Amount, format: '0,0.00[000000]' }}></span>
                            </button>
                        </div>
                    </div>
                    {this.props.documentLayout !== DocumentLayouts.Standard && (
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <button className="btn btn-primary" data-bind={{ click: calculator.notifyUnitPrice.bind(calculator) }}>
                                    {TextResources.Invoices.DocumentRowUnitPrice} = <span data-bind={{ moneyTextEx: calculator.UnitPrice, extendedMoneyFormat: true, maxNumberOfDecimals: 8, currencySymbol: calculator.props.row.Currency }}></span>
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <button className="btn btn-primary" data-bind={{ click: calculator.notifyDiscount.bind(calculator), disable: calculator.Discount() < 0 || calculator.Discount() > 1 }}>
                                {TextResources.Invoices.DocumentRowDiscounts} = <span data-bind={{ discountText: calculator.DiscountValue, showNegativeValues: true }}></span>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <button className="btn btn-primary" data-bind={{ click: calculator.notifyNetUnitPrice.bind(calculator) }}>
                                {TextResources.Invoices.DocumentRowNetUnitPrice} = <span data-bind={{ moneyTextEx: calculator.NetUnitPrice, extendedMoneyFormat: true, maxNumberOfDecimals: 8, currencySymbol: calculator.props.row.Currency }}></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ), this, "calculator");
    }
}