import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Document, IDocumentConfiguration } from "../Document";

export class SupplierOrderConfiguration implements IDocumentConfiguration {
    DocumentType: string = ProlifeSdk.SupplierOrderEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(false);

        document.DDTCausesDataSource.setShowOnlySalesCauses(true);

        document.CanHaveDestinationRecipient(false);
        document.CanBeAnElectronicInvoice(false);
        document.CanHaveStampDuty(false);
        document.CanBeDownPayment(false);
        document.CanSetCustomerFromJobOrder(false);
        document.CanHaveSecondaryRecipients(false);
        document.CanBeForPublicAdministration(false);
        document.CanHaveWithholdingTax(false);
        document.CanHideAmounts(false);
        document.CanSelectCustomers(false);
        document.CanSelectSuppliers(true);
        document.CanHaveRelatedDocuments(false);
        document.CanHaveSourceWarehouse(false);
        document.CanHaveDestinationWarehouse(false);
        document.CanHaveShippingInformation(false);
        document.CanHaveCause(false);
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(false);
        document.CanHaveTaxReport(false);
        document.CanReferenceArticles(true);
        document.CanHaveVAT(false); //TODO: Perchè?
        document.CanCloseRows(true);
        document.CanHaveLetterOfAttempts(false);
        document.CanViewOldLetterOfAttempts(false);
        document.CanChangeDiscountCatalog(true);
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(false);
        document.CanEditRevisionNumber(false);
        document.CanHaveSchedules(false);
        document.CanExportElectronicInvoices(false);
        document.CanHaveState(true);
        document.CanSelectClosedLettersOfAttempt(false);
        document.CanHaveCIGCUP(false);
        document.MustCheckTrust(false);
        document.IsCustomerDocument(false);
        document.ShowVersionNumbersSuggestions(false);
        document.CanHaveMetadatas(document.MetadatasEnabled);
        document.RecipientPosition("left");

        document.ExcelExporterId("Warehouse/SupplierOrderExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.NuovoLabel);
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    print(documentId: number) {
        this.dialogsService.OpenUrlAsDialog("/Warehouse/Print/SupplierOrder/" + documentId, "Stampa Ordine");
        /*var popup = window.open("about:blank", "_blank");
        popup.location.href = "/Service/Invoices/Print/Index/" + documentId;*/
    }
}
