import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 20/11/2018
 * Time: 12:12
 * To change this template use File | Settings | File Templates.
 */

export class SelectIfHasFocus {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var hasFocus: ko.Observable<boolean> = valueAccessor();

        hasFocus.subscribe((value: boolean) => {
            if (value)
                $(element).select();
        });
    }
}

ko.bindingHandlers["selectIfHasFocus"] = new SelectIfHasFocus();