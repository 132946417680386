import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { IOperationalUnit, IOperationalUnitsSettingsManager } from "../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";

export interface IOperationalUnitsDataSourceModel extends IDataSourceModel<number, IOperationalUnit> {

}

export class OperationalUnitsDataSource extends BaseDataSource<IOperationalUnitsDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.OperationalUnitsSettingsServiceType)
    private ouSettings: IOperationalUnitsSettingsManager;

    private showDeleted: boolean = false;

    public setShowDeleted(enabled: boolean) {
        this.showDeleted = enabled;
    }

    getTitle(currentModel: IOperationalUnitsDataSourceModel): string {
        return TextResources.ResourcesManager.OperationalUnits;
    }

    async getData(currentModel: IOperationalUnitsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IOperationalUnitsDataSourceModel[]> {
        let words = (textFilter || "").toLowerCase().trim().split(" ");

        return this.ouSettings.getAll(this.showDeleted)
            .filter(ou => !words.any(w => ou.Name.toLowerCase().indexOf(w) == -1))
            .slice(skip, skip + count)
            .map(this.createModelFor, this);
    }
    
    async getById(currentModel: IOperationalUnitsDataSourceModel, ids: number[]): Promise<IOperationalUnitsDataSourceModel[]> {
        return this.ouSettings.getAll(true)
            .filter(ou => ids.indexOf(ou.Id) != -1)
            .map(this.createModelFor, this);
    }

    private createModelFor(ou : IOperationalUnit) : IOperationalUnitsDataSourceModel {
        return {
            id: ou.Id,
            title: ou.Name,
            isLeaf: true,
            isGroup: false,
            model: ou
        };
    }
}