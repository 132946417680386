import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IOperationalUnitsSettingsManager, IOperationalUnit } from "../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IOperationalUnitWorkingHours } from "../../../ProlifeSdk/interfaces/users/IHumanResource";
import { LazyImport } from "../../../Core/DependencyInjection";

export class OperationalUnitsSettingsManager implements IOperationalUnitsSettingsManager
{
    private units : IOperationalUnit[];
    
    @LazyImport(nameof<ISettingsService>())
    private settingsService : ISettingsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    
    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.ResourcesManager.OperationalUnits);
    }

    load(): Promise<IOperationalUnit[]> {
        return this.ajaxService.Get<IOperationalUnit[]>("ResourcesManager-api", "OperationalUnits", {})
            .then((units: IOperationalUnit[]) => this.units = units);
    }

    getName(): string {
        return ProlifeSdk.OperationalUnitsSettingsServiceType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.ResourcesManager.OperationalUnits;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return null;
    }

    public getAll(includeDeleted : boolean): IOperationalUnit[]
    {
        var units : IOperationalUnit[] = this.units;

        if(!includeDeleted)
            units = units.filter((c : IOperationalUnit) => {
                return c.Deleted == false;
            });

        return units;
    }

    public getById(id : number) : IOperationalUnit
    {
        var matches : IOperationalUnit[] = this.units.filter((i : IOperationalUnit) => {
            return i.Id == id;
        });
        return matches.length > 0 ? matches[0] : null;
    }

    public getDefault() : IOperationalUnit
    {
        return this.units[0];
    }

    public delete(unit: IOperationalUnit): Promise<IOperationalUnit>
    {
        return this.ajaxService.Delete<IOperationalUnit>("ResourcesManager-api", "OperationalUnits", { methodData: unit })
            .then(this.onElementUpdated.bind(this));
    }

    public createOrUpdate(unit: IOperationalUnit): Promise<IOperationalUnit>
    {
        return this.ajaxService.Post<IOperationalUnit>("ResourcesManager-api", "OperationalUnits", { methodData: unit })
            .then(this.onElementUpdated.bind(this));
    }

    saveOperationalUnitStandardWorkingHours(unitId: number, workingHours: IOperationalUnitWorkingHours[]): Promise<IOperationalUnitWorkingHours[]> {
        return this.ajaxService.Post("ResourcesManager-api/OperationalUnits", "SaveOperationalUnitStandardWorkingHours", {
            methodData: {
                OperationalUnitId: unitId,
                WorkingHours: workingHours
            }
        });
    }

    getOperationalUnitStandardWorkingHours(unitId: number): Promise<IOperationalUnitWorkingHours[]> {
        return this.ajaxService.Post("ResourcesManager-api/OperationalUnits", "GetOperationalUnitStandardWorkingHours", {
            methodData: {
                OperationalUnitId: unitId
            }
        });
    }

    private onElementUpdated(unit : IOperationalUnit)
    {
        var matches = this.units.filter((t : IOperationalUnit) => {
            return t.Id == unit.Id;
        });

        if(matches.length == 0)
            this.units.push(unit);
        else
            this.units[this.units.indexOf(matches[0])] = unit;

        return unit;
    }
}
