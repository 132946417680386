import { BaseDataSource } from "./BaseDataSource";
import { IHumanResourceForList, IHumanResourcesService } from "../Users/HumanResourcesService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";

export type IHumanResourcesDataSourceModel = IDataSourceModel<number, IHumanResourceForList>

export class HumanResourcesDataSource extends BaseDataSource<IHumanResourcesDataSourceModel> {

    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService: IHumanResourcesService;

    private resourceType : number = null;
    private showDeleted  = false;
    private showDisabled  = false;

    public setResourceType(resType: number) {
        this.resourceType = resType;
    }

    public setShowDeleted(enabled: boolean) {
        this.showDeleted = enabled;
    }

    public setShowDisabled(enabled: boolean) {
        this.showDisabled = enabled;
    }

    getTitle(currentModel: IHumanResourcesDataSourceModel): string {
        return TextResources.ResourcesManager.HumanResources;
    }

    async getData(currentModel: IHumanResourcesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IHumanResourcesDataSourceModel[]> {
        const resources = await this.humanResourcesService.GetHumanResourcesForList({
            textFilter: textFilter,
            resourceType: this.resourceType,
            showDeleted: this.showDeleted,
            showDisabled: this.showDisabled,
            skip: skip,
            count: count
        });

        return resources.map(this.createModelFor, this);
    }

    async getById(currentModel: IHumanResourcesDataSourceModel, ids: number[]): Promise<IHumanResourcesDataSourceModel[]> {
        const resources = await this.humanResourcesService.GetHumanResourcesForListByIds(ids);
        return resources.map(this.createModelFor, this);
    }

    private createModelFor(resource: IHumanResourceForList): IHumanResourcesDataSourceModel {
        return {
            id: resource.Id,
            title: this.humanResourcesService.getFullName(resource),
            isLeaf: true,
            isGroup: false,
            model: resource
        }
    }
}