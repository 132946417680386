import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { Attachment } from "../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IException } from "../../../Core/interfaces/IException";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IAttachmentsManager, IAttachment, IAttachmentsManagerPathProvider } from "../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../../../ProlifeSdk/interfaces/files/IFileOrFolder";

export class AttachmentsManager implements IAttachmentsManager
{
    @LazyImport(nameof<IFileRepositoryService>())
    protected fileRepositoryService : IFileRepositoryService;
    @LazyImport(nameof<IDesktopService>())
    protected desktopService : IDesktopService;
    @LazyImport(nameof<IDialogsService>())
    protected dialogsService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService : IInfoToastService;

    public attachments : ko.ObservableArray<IAttachment> = ko.observableArray([]);
    public selectedAttachments : ko.Computed<IAttachment[]>;

    constructor(private pathProvider : IAttachmentsManagerPathProvider)
    {
        this.selectedAttachments = ko.computed(() => {
            return this.attachments().filter((a : Attachment) => a.selected());
        });
    }

    public downloadSelectedAttachment()
    {
        this.selectedAttachments()[0].download();
    }

    public removeSelectedAttachments()
    {
        this.selectedAttachments().forEach((a : Attachment) => {
            this.attachments.remove(a);
        });
    }

    public async loadAttachments(attachmentsIds : string[]) : Promise<void>
    {
        let files : IFileOrFolder[] = await this.fileRepositoryService.getFilesDetails(attachmentsIds)
        let newAttachments : Attachment[] = files ? files.map((file : IFileOrFolder) => new Attachment().loadFromFile(file)) : [];
                this.attachments(newAttachments);
    }

    public async attachFromRepository(): Promise<void>
    {
        try {
            let destinationPath: string = await this.prepareDestinationFolder();

            let selectedFiles: IFileOrFolder[] = await this.fileRepositoryService.openAsDialog(destinationPath);
            let alreadyPresentAttachmentsIds = this.attachments().map((a : Attachment) => a.id);
            let newAttachments = selectedFiles
                            .filter((f : IFileOrFolder) => alreadyPresentAttachmentsIds.indexOf(f.Id) < 0)
                            .map((file : IFileOrFolder) => new Attachment().loadFromFile(file));
            
                        newAttachments.forEach((a : Attachment) => { this.attachments.push(a); });
        } catch (e) {
            if (!e) // TODO è un hack per gestire il fatto che quando si chiude un dialog viene fatta una reject alla quale non viene passato nessun parametro, quindi e è undefined
                return;

            let ex = e as IException;
            this.infoToastService.Error(ex.Message);
        }
    }

    public async uploadAndAttach(file: File): Promise<void>
    {
        try {
            let destinationPath: string = await this.pathProvider.getDestinationPathForAttachments();
            if((destinationPath || "").trim() == "")
                return;

            let directoryExists: boolean = await this.fileRepositoryService.ExistsDirectory(destinationPath);
            if (!directoryExists) {
                await this.fileRepositoryService.CreateDirectory(destinationPath);
            }

            let files: IFileOrFolder[] = await this.fileRepositoryService.browse(destinationPath);
            let filesMatches: IFileOrFolder[] = files.filter((f : IFileOrFolder) => { return f.Name.toUpperCase() == file.name.toUpperCase() });

            let confirm = true;
            if (filesMatches.length != 0) {
                confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.FileRepository.UploadOverwriteMsg, ProlifeSdk.TextResources.FileRepository.UploadOverwriteMsgCancel, ProlifeSdk.TextResources.FileRepository.UploadOverwriteMsgConfirm);
            }

                            if(!confirm)
                return;

            let uploadDef = this.fileRepositoryService.upload(destinationPath, file, file.name);
            this.desktopService.AddUploadNotification(file.name, uploadDef);
            uploadDef
                .then((result : any) => {
                    if(!result.UploadSuccess)
                    {
                        this.infoToastService.Error(String.format(ProlifeSdk.TextResources.FileRepository.UploadError, file.name));
                        return;
                    }

                    this.fileRepositoryService.getFileDetails(result.FileId).then((fileOrFolder : IFileOrFolder) => {
                        this.attachments.push(new Attachment().loadFromFile(fileOrFolder));
                    });
                });
        } catch (e) {
            if (!e) // TODO è un hack per gestire il fatto che quando si chiude un dialog viene fatta una reject alla quale non viene passato nessun parametro, quindi e è undefined
                return;

            let ex = e as IException;
            this.infoToastService.Error(ex.Message);
        }
    }

    protected async prepareDestinationFolder(): Promise<string> {
        let destinationPath: string = await this.pathProvider.getDestinationPathForAttachments();
        if ((destinationPath || "").trim() == "")
                destinationPath = "/";
        
        let directoryExists: boolean = await this.fileRepositoryService.ExistsDirectory(destinationPath);
        if (!directoryExists) {
            await this.fileRepositoryService.CreateDirectory(destinationPath);
        }

        return destinationPath;
    }
}
