import { IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { DefaultValuesSettingsUi } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";

export class CustomerOrderDefaultValuesSettingsUi extends DefaultValuesSettingsUi {
    constructor(protected protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);
        this.templateUrl = "warehouse/templates/defaultvalues";
        this.templateName = "customer-order-default-values";

        this.ShowAbiAndCabFields(false);
    }
}
