import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { SupplierOrderEventDetailsEdit } from "./details/SupplierOrderEventDetailsEdit";
import { ISupplierOrder, ISupplierOrderRow } from "../../../../ProlifeSdk/interfaces/warehouse/ISupplierOrder";
import { IOrdersService } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class SupplierOrderEvent extends DocumentEvent implements IView
{
    private ordersService : IOrdersService;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.SupplierOrderEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.Warehouse.SupplierOrderAbb;
        this.ordersService = <IOrdersService>serviceLocator.findService(ProlifeSdk.OrdersServiceType);
    }

    getEditViewModel() : IEventBaseInput
    {
        return new SupplierOrderEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.LoadDocumentInfo();
    }

    public LoadDocumentInfo() : Promise<void>
    {
        var def = new Deferred<void>();
        this.ordersService.getSupplierOrderById(this.DocumentId()).then((d : ISupplierOrder) => {
            if(!d)
                return;

            this.Total(d.TotaleOrdine);

            this.ordersService.getSupplierOrderRowsById(this.DocumentId()).then((r : ISupplierOrderRow[]) => {
                this.NumberOfRows(r ? r.length : 0);
            });
        }).finally(() => {
            def.resolve();
        });
        return def.promise();
    }
}