import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ResourceManagerArea } from "./ResourceManagerArea";
import "./ResourceManager";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
export class Resources extends ResourceManagerArea {
    constructor(mainLayout : IResourcesManagerViewModel) {
        super(ProlifeSdk.TextResources.ResourcesManager.ResourcesAreaTitle, "fa-user", mainLayout,
            null, null, ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Resources, "resource-manager");
    }
}

/*export class ResourcesSection {

    public title : string;

    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService : IHumanResourcesService;

    private list : ko.ObservableArray<ResourceForList> = ko.observableArray([]);
    public selected : ko.Observable<any> = ko.observable(null);
    public textFilter : ko.Observable<string> = ko.observable("");
    public showDisabled : ko.Observable<boolean> = ko.observable(false);
    public showDeleted : ko.Observable<boolean> = ko.observable(false);

    @Right("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager")
    public canShowDeleted: boolean;

    constructor(private serviceLocator : IServiceLocator, private forHuman : boolean) {
        this.createNew();
    }

    public updateElementInLists(model : IHumanResource) {
        this.removeElementFromLists(model.Id);
        this.list.push(new ResourceForList(model, this, this.serviceLocator));
    }

    public removeElementFromLists(id : number) {
        var updated = this.list().filter((c : ResourceForList) => { return c.model.Id == id; });

        if(updated.length > 0)
            this.list.remove(updated[0]);
    }

    public edit(model : IHumanResourceForList){
        this.selected(new ResourceEditMask(this.serviceLocator, model, this));
    }

    public createNew() {
        var emptyModel : IHumanResource = {
            Resource: {
                Id: -1,
                Name: "",
                Surname: "",
                ResourceType: this.forHuman ? 0 : 1,
                Deleted : false,
                Disabled : false,
                ShowPreferred : true,
                SerialNumber: null,
                FkUser: null,
                FkDefaultCharacter: null
            },
            Orders : [],
            OrdersWorkingHours: [],
            OrdersRoles: [],
            OrdersSalaries: []
        };
        this.edit(emptyModel);
    }

    public async init() {
        let resources = await this.humanResourcesService.GetHumanResourcesForList({
            textFilter: this.textFilter(),
            resourceType: this.forHuman ? 0 : 1,
            showDeleted: this.showDeleted(),
            showDisabled: this.showDisabled()
        });

        this.list(resources.map(this.createViewModelFor, this));
    }

    private createViewModelFor(resource : IHumanResourceForList) : ResourceForList {
        return new ResourceForList(resource, this);
    }
}

export class ResourceEditMask {
    @LazyImport(nameof<IHumanResourcesService>())
    private humanResources: IHumanResourcesService;

    private sdkService : IProLifeSdkService;
    private resourcesSettingsService : IHumanResourcesSettingsManager;
    private toastService : IInfoToastService;
    private dialogsService : IDialogsService;

    public originalName : ko.Observable<string> = ko.observable("");
    public name : ko.Observable<string> = ko.observable("");
    public surname : ko.Observable<string> = ko.observable("");
    public user : UsersDropDownList;
    public defaultCharacter : UserCharactersDropDownList;
    public disabled : ko.Observable<boolean> = ko.observable(false);
    public deleted : ko.Observable<boolean> = ko.observable(false);
    public showPreferred : ko.Observable<boolean> = ko.observable(false);
    public serialNumber: ko.Observable<string> = ko.observable();
    public ordersManager : ServiceOrdersManager;
    public costsManager : CostsManager;

    public isNew : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator, public model : IHumanResource, private parent : ResourcesSection){
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.resourcesSettingsService = <IHumanResourcesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.HumanResources);

        this.user = new UsersDropDownList(serviceLocator);
        this.defaultCharacter = new UserCharactersDropDownList(serviceLocator, model.Resource.ResourceType);

        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.toastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.ordersManager = new ServiceOrdersManager(serviceLocator, model);
        this.costsManager = new CostsManager(serviceLocator);
        this.updateData(model);
    }

    private updateData(model : IHumanResource) {
        this.model = model;
        this.name(model.Resource.Name);
        this.surname(model.Resource.Surname);
        this.user.selectedId(model.Resource.FkUser);
        this.defaultCharacter.selectedId(model.Resource.FkDefaultCharacter);
        this.showPreferred(model.Resource.ShowPreferred);
        this.serialNumber(model.Resource.SerialNumber);
        this.disabled(model.Resource.Disabled);
        this.deleted(model.Resource.Deleted);
        this.isNew(model.Resource.Id <= 0);
        this.costsManager.initialize(model);
        this.ordersManager.initialize(model);

        var originalName = this.isNew() ? ProlifeSdk.TextResources.ResourcesManager.ResourcesNewResource : ((model.Resource.Name || "") + " " + (model.Resource.Surname || "") + (!model.Resource.Id || model.Resource.Id <= 0 ? '' : ' (Id: ' + model.Resource.Id +  ')')).trim();
        this.originalName(originalName.length == 0 ? "<" + ProlifeSdk.TextResources.ResourcesManager.ResourceWithoutName + ">" : originalName);
    }

    public restoreOriginalValues(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.ResourcesManager.RestoreResourceDataWarning,
            ProlifeSdk.TextResources.ResourcesManager.RestoreResourceDataWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.RestoreResourceDataWarningConfirm, (confirm : boolean) => {
                if(confirm){
                    this.model = this.resourcesSettingsService.getHumanResourceById(this.model.Resource.Id);
                    this.updateData(this.model);
                }
            });
    }

    public hasChanges(): boolean {
        var newModel: IHumanResource = <IHumanResource> $.extend({}, this.model);

        newModel.Resource.Name = this.name();
        newModel.Resource.Surname = this.surname();
        newModel.Resource.FkDefaultCharacter = !this.defaultCharacter.selectedId() ? null : parseInt(this.defaultCharacter.selectedId().toString());
        newModel.Resource.FkUser = !this.user.selectedId() ? null : parseInt(this.user.selectedId().toString());
        newModel.Resource.Disabled = this.disabled();
        newModel.Resource.Deleted = this.deleted();
        newModel.Resource.ShowPreferred = this.showPreferred();
        newModel.Resource.SerialNumber = this.serialNumber();
        newModel.Resource.Salaries = this.costsManager.getData();
        newModel.Resource.Orders = this.ordersManager.getData();

        this.model.Resource.FkDefaultCharacter = !this.model.Resource.FkDefaultCharacter ? null : this.model.Resource.FkDefaultCharacter;

        this.model.Salaries.sort((a, b) => { return moment(b.FromDate).valueOf() - moment(a.FromDate).valueOf() });

        return !(<any>Object).identical(newModel, this.model, true);
    }

    public save(){
        if(this.name().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.ResourcesInvalidName);
            return;
        }

        if(!this.ordersManager.validate())
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.ResourcesInvalidServiceOrders);
            return;
        }

        if(!this.ordersManager.checkAlert())
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.ResourcesManager.ResourcesNotConfiguredOrdersWarning,
                ProlifeSdk.TextResources.ResourcesManager.ResourcesNotConfiguredOrdersWarningCancel,
                ProlifeSdk.TextResources.ResourcesManager.ResourcesNotConfiguredOrdersWarningConfirm, (confirm : boolean) => {
                    if(confirm)
                        this.saveAfterChecks();
                });
        else
            this.saveAfterChecks();
    }

    private async saveAfterChecks() {

        this.model.Resource.Name = this.name();
        this.model.Resource.Surname = this.surname();
        this.model.Resource.FkDefaultCharacter = this.defaultCharacter.selectedId();
        this.model.Resource.FkUser = this.user.selectedId();
        this.model.Resource.Disabled = this.disabled();
        this.model.Resource.Deleted = this.deleted();
        this.model.Resource.ShowPreferred = this.showPreferred();
        this.model.Resource.SerialNumber = this.serialNumber();
        this.model.Salaries = this.costsManager.getData();
        this.model.Orders = this.ordersManager.getData();

        let invalidHours = await this.resourcesSettingsService.validateHoursRespectNewResourceConfiguration(this.model);    
        if(invalidHours.length != 0) {
            //Sono state rilevate incoerenze sulle ore precedentemente inserite. Mostro il report.
            let invalidHoursDialog = new InvalidHoursReportDialog(invalidHours);
            return invalidHoursDialog.showModal();
        }

        try
        {
            let c = await this.resourcesSettingsService.createOrUpdate(this.model);
            for(let order of this.ordersManager.orders()) {
                this.humanResources.InsertOrUpdateServiceOrderCharacters(order.order.Id, order.SelectedRoles().map())
            }
            this.toastService.Success(TextResources.ResourcesManager.ResourcesUpdated);
            this.updateData(c);
            this.parent.updateElementInLists(c);
        }
        catch
        {
            this.toastService.Error(TextResources.ResourcesManager.ResourcesUpdateError);
        }
    }

    public restoreItem() {
        this.model.Resource.Deleted = false;

        this.resourcesSettingsService.createOrUpdate(this.model)
            .then((c : IHumanResource) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourcesRestored);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            }).catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourcesRestoredError);
            });
    }

    public deleteItem(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.ResourcesManager.ResourcesDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesDeleteWarningConfirm, (confirm : boolean) => {
                if(!confirm)
                    return;

                this.resourcesSettingsService.remove(this.model.Resource.Id)
                    .then(() => {
                        this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourcesDeleted);
                        this.model.Resource.Deleted = true;
                        this.parent.updateElementInLists(this.model);
                        this.parent.createNew();
                    }).catch(() => {
                        this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourcesDeleteError);
                    });
            });
    }
}

export class ResourceForList {

    public isSelected : ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public model : IHumanResourceForList, private parent : ResourcesSection){
        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().model.Id == this.model.Id;
        });
    }

    public showDetails(){
        if (!this.parent.selected()) {
            this.parent.edit(this.model);
            return;
        }

        if (this.parent.selected().hasChanges()) {
            this.ShowConfirmOnLostChanges()
                .then((confirm: boolean) => {
                    if (confirm)
                        this.parent.edit(this.model);
                });
            return;
        }

        this.parent.edit(this.model);
    }

    public renderResourceName(){
        var name = ((this.model.Resource.Name || "") + " " + (this.model.Resource.Surname || "")).trim();
        return name.length == 0 ? "<" + ProlifeSdk.TextResources.ResourcesManager.ResourceWithoutName + ">" : name;
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}*/