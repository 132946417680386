import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ResourcesManagerApplication } from "./resourcesmanager/ResourcesManagerApplication";
import { OperationalUnitsSettingsManager } from "./resourcesmanager/settings/OperationalUnitsSettingsManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IResourcesManagerService } from "../ProlifeSdk/interfaces/resourcesmanager/IResourcesManagerService";

class ResourcesManagerService implements IResourcesManagerService
{
    private application : ResourcesManagerApplication;
    private ajaxService : IAjaxService;

    private lastTemporaryId = 0;

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IResourcesManagerService>(), this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.application = new ResourcesManagerApplication();
        new OperationalUnitsSettingsManager();
    }

    getServiceType(): string
    {
        return ProlifeSdk.ResourcesManagerServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GenerateNextId() : number {
        return --this.lastTemporaryId;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ResourcesManagerService(serviceLocator);
}
