import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Right } from "../../../Core/Authorizations";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IWarehouseInspectionsService, IWarehouseInspectionDestinationProtocol } from "../../WarehouseInspectionsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { WarehouseInspectionsDestinationProtocolsEditorUI } from "./ui/WarehouseInspectionsDestinationProtocolsEditor";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";

export interface IWarehouseInspectionsDestinationProtocolsSettingsManager extends ISettingsManager {
    getDestinationProtocols(): IWarehouseInspectionDestinationProtocol[];
    createOrUpdateDestinationProtocols(protocols: IWarehouseInspectionDestinationProtocol[]): Promise<IWarehouseInspectionDestinationProtocol[]>;
}

export class WarehouseInspectionsDestinationProtocolsSettingsManager implements IWarehouseInspectionsDestinationProtocolsSettingsManager {
    @Right("Warehouse_CanEditWarehouseInspectionsDestinationProtocols")
    private canEditDestinationProtocols: boolean;

    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private protocols: IWarehouseInspectionDestinationProtocol[] = [];

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Warehouse.WarehouseSettingsGroup);
    }
    
    public async load(): Promise<void> {
        try {
            this.protocols = await this.warehouseInspectionsService.GetWarehouseInspectionsDestinationProtocols();
        } catch(e) {
            console.error(TextResources.Warehouse.InspectionsDestinationProtocolsLoadingError);
        }
    }
    
    public getName(): string {
        return nameof<IWarehouseInspectionsDestinationProtocolsSettingsManager>();
    }
    
    public getLabel(): string {
        return TextResources.Warehouse.WarehouseInspectionsDestinationProtocolsSettingsManager;
    }
    
    public hasEditingUI(): boolean {
        return this.canEditDestinationProtocols;
    }
    
    public getEditingUI(): IView {
        return new WarehouseInspectionsDestinationProtocolsEditorUI();
    }

    public getDestinationProtocols(): IWarehouseInspectionDestinationProtocol[] {
        return this.protocols;
    }

    public async createOrUpdateDestinationProtocols(protocols: IWarehouseInspectionDestinationProtocol[]): Promise<IWarehouseInspectionDestinationProtocol[]> {
        try {
            let savedProtocols = await this.warehouseInspectionsService.CreateOrUpdateWarehouseInspectionsDestinationProtocols(protocols);
            this.protocols = savedProtocols;
            return savedProtocols;
        } catch(e) {
            this.infoToastService.Error(TextResources.Warehouse.SaveInspectionsDestinationProtocolsError);
            return protocols;
        }
    }
}