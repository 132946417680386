import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSourceModel } from "./IDataSource";
import { IWithholdingTaxType } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class WithholdingTaxTypesDataSource extends BaseDataSource<IDataSourceModel<string, IWithholdingTaxType>> {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    public getTitle(currentModel: IDataSourceModel<string, IWithholdingTaxType>): string {
        return "";
    }
    
    public async getData(currentModel: IDataSourceModel<string, IWithholdingTaxType>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<string, IWithholdingTaxType>[]> {
        let types = await this.documentsService.GetWithholdingTaxTypes(textFilter, skip, count);
        return types.map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<string, IWithholdingTaxType>, ids: string[]): Promise<IDataSourceModel<string, IWithholdingTaxType>[]> {
        let types = await this.documentsService.GetWithholdingTaxTypesByIds(ids);
        return types.map(this.createModel, this);
    }
    
    private createModel(type: IWithholdingTaxType): IDataSourceModel<string, IWithholdingTaxType> {
        return {
            id: type.Code,
            title: String.format(TextResources.Invoices.WithholdingTaxTypeLabel, type.Code, type.Description),
            isGroup: false,
            isLeaf: true,
            model: type
        };
    }
}