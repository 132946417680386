import { IResolvedCustomerDiscountCatalogArticleSuggestion } from "../../../Warehouse/DiscountsCatalogsService";
import { DiscountCatalogSuggestionsForArticleDataSource } from "../../../DataSources/DiscountCatalogSuggestionsForArticleDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IDialog } from "../../../Core/interfaces/IDialogsService";

export class DocumentRowDiscountCatalogSelectorDialog implements IDialog, IDataSourceListener {
    templateName = "discountCatalogSelector";
    templateUrl = "prolifesdk/templates/documents";
    title = "Selezione Scontistica";

    modal: { close: (result?: IResolvedCustomerDiscountCatalogArticleSuggestion) => void; };

    DiscountCatalogSuggestionsForArticleDataSource: DiscountCatalogSuggestionsForArticleDataSource = new DiscountCatalogSuggestionsForArticleDataSource();

    constructor(private articleId : number, private customerId : number, private isCustomer : boolean, private warehouseId : number, private documentDate : Date) {
        this.DiscountCatalogSuggestionsForArticleDataSource.setFilter({
            articleId: this.articleId, 
            customerId: this.customerId, 
            isCustomer: this.isCustomer,
            warehouseId: this.warehouseId,
            referenceDate: this.documentDate
        });
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        this.modal.close(model.model);
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        this.modal.close();
    }
}


