import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import {
    ISmtpConfiguration,
    ISmtpConfigurationService,
} from "../ProlifeSdk/interfaces/desktop/ISmtpConfigurationService";
import { ResponseData, ResponseError } from "../Core/response/ResponseBase";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IService } from "../Core/interfaces/IService";

export class SmtpConfigurationService implements ISmtpConfigurationService {
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ISmtpConfigurationService>(), this);
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getServiceType(): string {
        return ProlifeSdk.SmtpConfigurationServiceType;
    }

    async get(): Promise<ISmtpConfiguration> {
        let res = null;
        try {
            const smtpResponse = await this.ajaxServiceNew.Get<ResponseData<ISmtpConfiguration>>(
                "cc/SmtpConfiguration",
                "",
                {
                    methodData: {},
                }
            );
            if (!smtpResponse.succeeded) {
                this.handleResponseErrors(smtpResponse.errors);
            } else {
                res = smtpResponse.data;
            }
        } catch (e) {
            this.handleResponseErrors(e.errors);
        }
        return res;
    }

    private handleResponseErrors(errors: ResponseError[]): void {
        let message = "";

        for (const error of errors) {
            if (error.code in TextResources.Desktop) message += TextResources.Desktop[error.code] + "<br />";
        }

        if (message.length === 0) this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        else this.infoToastService.Error(message);
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new SmtpConfigurationService(serviceLocator);
}
