import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/06/2017
 * Time: 18:58
 * To change this template use File | Settings | File Templates.
 */

import { QuestionnaireEditViewModel } from "./QuestionnaireEditViewModel";
import { QuestionForEdit } from "./QuestionForEdit";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISortable } from "../../../../interfaces/ISortable";
import { IQuestionForEdit } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";

export class QuestionsWithoutSectionForEdit implements ISortable {
    public templateName: string = "questions-without-section-list";
    public templateUrl: string = "survey/templates/questionnaire";

    public Questions: ko.ObservableArray<QuestionForEdit>;

    public NewQuestionText: ko.Observable<string> = ko.observable();

    public Id: number = 0;
    public Index: ko.Observable<number> = ko.observable(0);

    public QuestionsNumber: ko.Computed<number>;

    constructor(questions: ko.ObservableArray<QuestionForEdit>, private serviceLocator: IServiceLocator, private parent: QuestionnaireEditViewModel) {
        this.Questions = questions;

        this.QuestionsNumber = ko.computed(() => {
            return this.Questions().filter((q: QuestionForEdit) => {
                return !q.Deleted();
            }).length;
        });
    }

    public OnQuestionMoved(beforeId : number, movedId : number, afterId : number) {
        this.parent.OnQuestionMoved(beforeId, movedId, afterId);
    }

    public AddNewQuestion() {
        if (!this.NewQuestionText())
            return;
        var newQuestion = <IQuestionForEdit> {
            Id: this.getAvailableQuestionId(),
            ParentId: null,
            ParentLabel: null,
            Text: this.NewQuestionText(),
            TypeId: 1,
            TypeName: null,
            Description: null,
            Label: this.parent.NewQuestionId.toString(),
            Index: this.QuestionsNumber() + 1,
            Enabled: false,
            CreationDate: null,
            CreatorId: null,
            ModifyDate: null,
            ModifierId: null,
            QuestionnaireId: this.parent.Questionnaire().Id,
            AnswerTypeId: this.parent.AnswerTypes()[0].Id,
            AnswerTypeName: this.parent.AnswerTypes()[0].Description,
            Answers: [],
            IsNew: true,
            Deleted: false,
            PublicLabel: null
        };
        this.parent.NewQuestionId += 1;
        var questionViewModel: QuestionForEdit = new QuestionForEdit(this.serviceLocator, newQuestion, true, null, this.parent);
        questionViewModel.RegisterSelectionObserver(this.parent);
        questionViewModel.RegisterQuestionMovementObserver(this.parent);
        this.Questions.push(questionViewModel);
        this.NewQuestionText(null);
    }

    private getAvailableQuestionId(): number {
        return this.QuestionsNumber() == 0 ? 1 : (Math.max.apply(Math, this.Questions().map((q: QuestionForEdit) => { return q.Id; }))) + 1;
    }
}