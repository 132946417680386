import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { WorkflowsForSelectListDataSource } from "../../../DataSources/WorkflowsForSelectListDataSource";
import { CurrencyUtils } from "../utils/CurrencyUtils";
import { DocumentRow } from "../../../Invoices/invoices/documents/DocumentRows";
import { Document } from "../../../Invoices/invoices/documents/Document";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IDocumentCurrencyViewModel } from "../../interfaces/invoice/IDocumentsService";
import { IDocumentRelatedWorkflow } from "../../interfaces/invoice/IDocumentRow";
import { IWorkflowForSelectList } from "../../interfaces/todolist/ITodoListService";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../interfaces/desktop/IUserInfo";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";

abstract class BaseRelatedWorkflowsPopover {
    public RelatedWorkflows: ko.ObservableArray<DocumentRelatedWorkflow> = ko.observableArray([]);

    public TotalAssigned: ko.Computed<number>;

    constructor() {
        this.TotalAssigned = ko.computed(() => {
            let total: number = 0;

            this.RelatedWorkflows().forEach(w => total += w.WorkflowAmount());

            return total;
        });
    }

    public Add(): void {
        this.RelatedWorkflows.push(this.createViewModelFor());
    }

    public Remove(relatedWorkflow: DocumentRelatedWorkflow): void {
        let index = this.RelatedWorkflows.indexOf(relatedWorkflow);

        if (index >= 0)
            this.RelatedWorkflows.splice(index, 1);
    }

    protected abstract createViewModelFor(relatedWorkflow?: IDocumentRelatedWorkflow): DocumentRelatedWorkflow;
}

export class RelatedWorkflowsPopOver extends BaseRelatedWorkflowsPopover implements IDialog {
    public templateName: string = "";
    public templateUrl: string = "";
    public title: string = ProlifeSdk.TextResources.Invoices.DocumentRelatedWorkflows;
    
    public modal: { close: (result?: any) => void; };

    public ReadOnly: ko.Observable<boolean> = ko.observable();

    public RowDescription: ko.Observable<string> = ko.observable();
    public RowAmount: ko.Observable<number> = ko.observable();
    public UnitOfMeasure: ko.Observable<string> = ko.observable();
    
    public RowPrice: ko.Observable<number> = ko.observable();

    public TotalAssignedPrice: ko.Computed<number>;
    public RowAmountExceeded: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastsService: IInfoToastService;

    constructor(private row: DocumentRow, public documentCurrency: IDocumentCurrencyViewModel, readonly: boolean = false) {
        super();

        this.ReadOnly(readonly);

        this.RowDescription(this.row.Description());
        this.RowAmount(this.row.Amount());
        this.UnitOfMeasure(this.row.UoM());
        this.RowPrice(this.row.TotalPrice());

        this.loadFromModels(this.row.RelatedWorkflows());

        this.TotalAssignedPrice = ko.computed(() => {
            let total = 0;
            let workflows = this.RelatedWorkflows();

            for (let workflow of workflows) {
                total += workflow.WorkflowPrice();
            }

            return total;
        });

        this.RowAmountExceeded = ko.computed(() => {
            return this.TotalAssigned() > this.RowAmount();
        });
    }

    public close(): void {
        let relatedWorkflows = this.RelatedWorkflows();

        for (let workflow of relatedWorkflows) {
            if (!workflow.WorkflowId()) {
                this.infoToastsService.Warning(ProlifeSdk.TextResources.ProlifeSdk.InvalidDocumentRelatedWorkflow);
                return;
            }
        }

        let newRelatedWorkflowsData = relatedWorkflows.map(w => w.getData());
        this.row.RelatedWorkflows(newRelatedWorkflowsData);
        this.modal.close();
    }
    
    public action(): void {
        this.close();
    }

    public show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopover(element, this, "left");
    }

    private loadFromModels(relatedWorkflows: IDocumentRelatedWorkflow[]) {
        this.RelatedWorkflows(relatedWorkflows.map(this.createViewModelFor, this));
    }

    protected createViewModelFor(relatedWorkflow: IDocumentRelatedWorkflow = null): DocumentRelatedWorkflow {
        let netUnitPrice = this.row.NetUnitPrice();
        let finalTotal = this.row.TotalPrice();

        return new DocumentRelatedWorkflow(relatedWorkflow || this.createEmptyRelatedWorkflow(), this.row.Document.FKJobOrder(), netUnitPrice, finalTotal, this.row.Amount(), this);
    }

    private createEmptyRelatedWorkflow(): IDocumentRelatedWorkflow {
        return {
            Id : null,
            WorkflowId : this.row.GetDefaultWorkflowId(),
            DocumentId : null,
            DocumentType: this.row.Document.DocumentType(),
            DocumentNumber: null,
            DocumentDate: null,
            DocumentProtocolId: null,
            RowId: this.row.Id(),
            RowDescription: null,
            RowAmount: this.row.Amount(),
            RowUoM: this.row.UoM(),
            RowPrice: CurrencyUtils.applyCurrencyExchange(this.row.NetUnitPrice(), this.documentCurrency),
            WorkflowAmount: Math.max(0, this.row.Amount() - this.TotalAssigned()),
            ValidityStartDate: null,
            AlreadyAssignedAmount: null,
            DocumentHasBeenDeleted: null,
            RowHasBeenDeleted: null,
            WorkflowName: null
        };
    }

    public renderTemplateNodes() {
        return [<div class="row" style="max-width: 730px">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10" style="padding-bottom: 10px">
                            <span data-bind="text: RowDescription"></span>
                        </div>
                        <div class="col-md-2" style="padding-bottom: 10px">
                            <button type="button" class="btn btn-circle btn-primary btn-xs pull-right" data-bind="click: Add, visible: !ReadOnly()">Aggiungi</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 compact-table">
                            <table class="table table-condensed estimated-costs" style="width: 700px">
                                <thead>
                                <tr>
                                    <th style="width: 46%; text-align: left">Piano</th>
                                    <th style="width: 22%">Quantità assegnata</th>
                                    <th style="width: 22%">Importo assegnato</th>
                                    <th style="width: 10%"></th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr data-bind="visible: RelatedWorkflows().length === 0">
                                        <td colSpan={4} style="text-align: center">
                                            Nessun piano di lavoro
                                        </td>
                                    </tr>
                                    <ko-foreach data-bind="RelatedWorkflows">
                                    <tr>
                                        <td class="edit-field">
                                            <ko-ifnot data-bind="$parent.ReadOnly">
                                                <select2 params="Value: WorkflowId, DataSource: WorkflowsDataSource, AllowClear: true, Listener: $data, CssClass: 'input-sm'"></select2>
                                            </ko-ifnot>
                                            <ko-if data-bind="$parent.ReadOnly">
                                                <span data-bind="text: WorkflowName"></span>
                                            </ko-if>
                                        </td>
                                        <td class="text-right edit-field">
                                            <ko-ifnot data-bind="$parent.ReadOnly">
                                                <input type="text" class="form-control input-sm text-right" data-bind="numberValue: WorkflowAmount, selectOnFocus: {}" />
                                            </ko-ifnot>
                                            <ko-if data-bind="$parent.ReadOnly">
                                                <span data-bind="numberText: WorkflowAmount"></span>
                                            </ko-if>
                                        </td>
                                        <td class="text-right edit-field">
                                            <ko-ifnot data-bind="$parent.ReadOnly">
                                                <input type="text" class="form-control input-sm text-right" data-bind="moneyValue: WorkflowPrice, selectOnFocus: {}" />
                                            </ko-ifnot>
                                            <ko-if data-bind="$parent.ReadOnly">
                                                <span data-bind="moneyText: WorkflowPrice"></span>
                                            </ko-if>
                                        </td>
                                        <td class="edit-field actions-col">
                                            <button type="button" class="btn btn-circle btn-danger btn-xs pull-right" data-bind="click: $parent.Remove.bind($parent, $data), visible: !$parent.ReadOnly()">
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </ko-foreach>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Tot.</td>
                                    <td class="text-right"><span data-bind="numberText: TotalAssigned, style: { color: (RowAmountExceeded() ? 'red' : 'black') }"></span>/<span data-bind="numberText: RowAmount"></span> <span data-bind="text: UnitOfMeasure"></span></td>
                                    <td class="text-right"><span data-bind="moneyText: TotalAssignedPrice, style: { color: (RowAmountExceeded() ? 'red' : 'black') }"></span>/<span data-bind="moneyText: RowPrice"></span></td>
                                    <td></td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
        </div>];
    }
}

class DocumentRelatedWorkflow implements IDataSourceListener {
    public WorkflowId: ko.Observable<number> = ko.observable();
    public WorkflowName: ko.Observable<string> = ko.observable();
    public WorkflowAmount: ko.Observable<number> = ko.observable();
    public WorkflowPrice: ko.Observable<number> = ko.observable();

    public WorkflowsDataSource: WorkflowsForSelectListDataSource;
    
    private selectedWorkflow: IWorkflowForSelectList;
    private isCalculating: boolean = false;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;

    constructor(private relatedWorkflow: IDocumentRelatedWorkflow, private jobOrderId: number, private rowNetUnitPrice: number, private rowTotalPrice: number, private rowAmount: number, private popOver: BaseRelatedWorkflowsPopover) {
        this.WorkflowAmount.subscribe((amount: number) => {
            if (this.isCalculating)
                return;

            this.isCalculating = true;

            if (this.rowNetUnitPrice) {
                this.WorkflowPrice(amount * (this.rowNetUnitPrice || 0));
            }

            this.isCalculating = false;
        });

        this.WorkflowPrice.subscribe((price: number) => {
            if (this.isCalculating)
                return;

            this.isCalculating = true;

            if (this.rowTotalPrice) {
                let amount = (price / this.rowTotalPrice) * this.rowAmount;
                this.WorkflowAmount(amount);
            }

            this.isCalculating = false;
        });

        this.WorkflowId(this.relatedWorkflow.WorkflowId);
        this.WorkflowName(this.relatedWorkflow.WorkflowName);
        this.WorkflowAmount(this.relatedWorkflow.WorkflowAmount);

        let userId = this.userInfo.getIdUser();
        let resource = this.resourcesManager.getHumanResourceByUserId(userId);

        this.WorkflowsDataSource = new WorkflowsForSelectListDataSource();
        this.WorkflowsDataSource.setJobOrderId(jobOrderId);
        this.WorkflowsDataSource.setViewAll(true);
        this.WorkflowsDataSource.setViewResponsible(true);
        this.WorkflowsDataSource.setViewWorker(true);
        this.WorkflowsDataSource.setForceJobOrderFilter(true);
        this.WorkflowsDataSource.setResourceId(resource?.Resource?.Id);
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, IWorkflowForSelectList>): void {
        if (model && model.model)
            this.selectedWorkflow = model.model
        else
            this.selectedWorkflow = null;
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, IWorkflowForSelectList>): void {
        this.selectedWorkflow = null;
    }

    public getData(): IDocumentRelatedWorkflow {
        let data = $.extend(true, {}, this.relatedWorkflow) as IDocumentRelatedWorkflow;

        data.WorkflowId = this.WorkflowId();
        data.WorkflowAmount = this.WorkflowAmount();
        data.WorkflowName = this.selectedWorkflow ? this.selectedWorkflow.Title : data.WorkflowName;

        return data;
    }
}

export class RelatedWorkflowsAutomationPopOver extends BaseRelatedWorkflowsPopover implements IDialog {
    public templateName: string;
    public templateUrl: string;
    public title: string = ProlifeSdk.TextResources.Invoices.DocumentRelatedWorkflows;
    
    public modal?: { close: (result?: any) => void; };

    constructor(private document: Document) {
        super();
    }

    public close(): void {
        this.modal.close();
    }
    
    public action(): void {
        let relatedWorkflows = this.RelatedWorkflows().map(r => r.getData());

        this.document.Rows().forEach(r => {
            let relatedWorkflowsForRow = relatedWorkflows.map(rw => {
                let workflow = $.extend(true, {}, rw);
                workflow.RowId = r.Id();
                workflow.RowAmount = r.Amount();
                workflow.RowDescription = r.Description();
                workflow.RowUoM = r.UoM();
                workflow.RowPrice = r.NetUnitPrice();
                workflow.WorkflowAmount = ((r.Amount() || 0) * rw.WorkflowAmount) / 100;

                return workflow;
            });

            r.RelatedWorkflows(relatedWorkflowsForRow);
        });
    }

    protected createViewModelFor(relatedWorkflow?: IDocumentRelatedWorkflow): DocumentRelatedWorkflow {
        return new DocumentRelatedWorkflow(this.createEmptyRelatedWorkflow(), this.document.FKJobOrder(), null, null, null, this);
    }

    private createEmptyRelatedWorkflow(): IDocumentRelatedWorkflow {
        return {
            Id : null,
            WorkflowId : null,
            DocumentId : this.document.DocumentId(),
            DocumentType: this.document.DocumentType(),
            DocumentNumber: this.document.Number(),
            DocumentDate: this.document.Date(),
            DocumentProtocolId: null,
            RowId: null,
            RowDescription: null,
            RowAmount: null,
            RowUoM: null,
            RowPrice: null,
            WorkflowAmount: 0,
            ValidityStartDate: this.document.Date(),
            AlreadyAssignedAmount: null,
            DocumentHasBeenDeleted: null,
            RowHasBeenDeleted: null,
            WorkflowName: null
        };
    }

    public renderTemplateNodes() : Node[] {
        return [<div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12" style="padding-bottom: 10px">
                        <button type="button" class="btn btn-circle btn-primary btn-xs pull-right" data-bind="click: Add">Aggiungi</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 compact-table">
                        <table class="table table-condensed estimated-costs" style="width: 600px">
                            <thead>
                            <tr>
                                <th style="width: 68%; text-align: left">Piano</th>
                                <th style="width: 22%">Quantità assegnata</th>
                                <th style="width: 10%"></th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr data-bind="visible: RelatedWorkflows().length === 0">
                                    <td colSpan={3} style="text-align: center">
                                        Nessun piano di lavoro
                                    </td>
                                </tr>
                                <ko-foreach data-bind="RelatedWorkflows">
                                <tr>
                                    <td class="edit-field">
                                        <select2 class="select2-container-for-documents" params="Value: WorkflowId, DataSource: WorkflowsDataSource, AllowClear: true, Listener: $data, CssClass: 'input-sm'"></select2>
                                    </td>
                                    <td class="text-right edit-field">
                                        <input type="text" class="form-control input-sm text-right" data-bind="percentageValue: WorkflowAmount, selectOnFocus: {}" />
                                    </td>
                                    <td class="edit-field actions-col">
                                        <button type="button" class="btn btn-circle btn-danger btn-xs pull-right" data-bind="click: $parent.Remove.bind($parent, $data)">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                                </ko-foreach>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="padding-top: 10px">
                        <button type="button" class="btn btn-xs btn-primary btn-circle" data-bind="click: action">Applica a tutte le righe</button>
                    </div>
                </div>
            </div>
        </div>];
    }
}