import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Attachment } from "../../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { Tag } from "../../../../ProlifeSdk/prolifesdk/blog/Tag";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITodoListTaskTag } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    IFileRepositoryService,
    IAttachmentUploadStatus,
} from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../../../../ProlifeSdk/interfaces/files/IFileOrFolder";
import { Deferred } from "../../../../Core/Deferred";

export interface IAttachmentsManagerObserver {
    onAttachmentsDownload(): void;
    onAttachmentsRemoved(): void;
    onAttachmentsRemove(): void;
    onAttachmentsAdded(): void;
    onAttachmentsAdd(): void;
}

export class ActivityAttachmentsManager {
    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private fileRepositoryService: IFileRepositoryService;

    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public attachments: ko.ObservableArray<Attachment> = ko.observableArray([]); //Allegati ad una specifica versione
    public lastVersionAttachments: ko.ObservableArray<Attachment> = ko.observableArray([]); //Allegati che sono sempre aggiornati all'ultima versione

    public SelectedAttachments: ko.Computed<Attachment[]>;

    private ShowAttachmentsAsList: ko.Observable<boolean> = ko.observable(false);
    private AttachmentsTemplateName: ko.Computed<string>;
    public NumberOfAttachments: ko.Computed<number>;
    private jobOrderId = -1;

    public IsSelectedAttachments: ko.Computed<boolean>;

    private observers: IAttachmentsManagerObserver[] = [];

    constructor() {
        this.SelectedAttachments = ko.computed(() => {
            const attachments: Attachment[] = this.attachments().filter((a: Attachment) => a.selected());
            const lastVersionAttachments: Attachment[] = this.lastVersionAttachments().filter((a: Attachment) =>
                a.selected()
            );
            return attachments.concat(lastVersionAttachments);
        });

        this.AttachmentsTemplateName = ko.computed(() => {
            return this.ShowAttachmentsAsList() ? "list" : "icons";
        });

        this.NumberOfAttachments = ko.computed(() => {
            return this.attachments().length + this.lastVersionAttachments().length;
        });

        this.IsSelectedAttachments = ko.computed(() => {
            return this.SelectedAttachments().length > 0;
        });
    }

    public async uploadFile(file: File): Promise<void> {
        try {
            const status: IAttachmentUploadStatus = await this.fileRepositoryService.checkStatusForTaskAttachmentUpload(
                this.jobOrderId,
                file.name
            );

            if (status.AvailableSpace < file.size) {
                this.infoToastService.Error(
                    String.format(ProlifeSdk.TextResources.FileRepository.FileNotUploadForInsufficientSpace, file.name)
                );
                return;
            }

            let confirm = true;

            if (status.AlreadyExists) {
                confirm = await this.dialogService.ConfirmAsync(
                    ProlifeSdk.TextResources.FileRepository.UploadOverwriteMsg,
                    ProlifeSdk.TextResources.FileRepository.CancelUpload,
                    ProlifeSdk.TextResources.FileRepository.UploadAsNewVersion
                );
            } else {
                const folderExists = await this.fileRepositoryService.ExistsDirectory(status.PathForUpload);
                if (!folderExists) await this.fileRepositoryService.CreateDirectory(status.PathForUpload);
            }

            if (confirm) this.uploadFileAfterChecks(file, status.PathForUpload);
        } catch (e) {
            console.log(e);
        }
    }

    public registerObserver(observer: IAttachmentsManagerObserver): void {
        if (this.observers.indexOf(observer) < 0) this.observers.push(observer);
    }

    public unregisterObserver(observer: IAttachmentsManagerObserver): void {
        const oIndex = this.observers.indexOf(observer);

        if (oIndex < 0) return;

        this.observers.splice(oIndex, 1);
    }

    private uploadFileAfterChecks(file: File, path: string) {
        this.fileRepositoryService
            .upload(path, file, file.name)
            .then((result: any) => {
                const fileId = result.FileId;
                this.infoToastService.Success(
                    String.format(ProlifeSdk.TextResources.FileRepository.UploadSuccess, file.name)
                );

                this.fileRepositoryService.getFileDetails(fileId).then((fileOrFolder: IFileOrFolder) => {
                    this.addAttachment([fileOrFolder]);
                });
            })
            .catch(() => {
                this.infoToastService.Error(
                    String.format(ProlifeSdk.TextResources.FileRepository.UploadError, file.name)
                );
            });
    }

    public setJobOrder(jobOrderId: number) {
        this.jobOrderId = jobOrderId;
    }

    public LoadFilesThumbnails(): Promise<IFileOrFolder[]> {
        const attachmentsIds: string[] = this.attachments().map((a: Attachment) => {
            return a.id;
        });
        const lastVersionAttachmentsIds: string[] = this.lastVersionAttachments().map((a: Attachment) => {
            return a.id;
        });

        return this.fileRepositoryService
            .getFilesDetails(attachmentsIds.concat(lastVersionAttachmentsIds))
            .then((files: IFileOrFolder[]) => {
                this.attachments()
                    .concat(this.lastVersionAttachments())
                    .forEach((a: Attachment) => {
                        const matches: IFileOrFolder[] = files.filter(
                            (file: IFileOrFolder) => file != null && file.Id == a.id
                        );
                        if (matches.length > 0) a.loadFromFile(matches[0]);
                    });
                return files;
            });
    }

    public LoadFromTags(tags: ITodoListTaskTag[]) {
        this.jobOrderId = -1;

        const attachments: Attachment[] = [];
        const lastVersionAttachments: Attachment[] = [];
        tags.filter((t: ITodoListTaskTag) => {
            return t.Type == ProlifeSdk.TagType_DocumentReference || t.Type == ProlifeSdk.TagType_LastVersionReference;
        }).forEach((t: ITodoListTaskTag) => {
            const tag: Tag = new Tag();
            tag.TagName(t.Field);
            tag.TagTypeId(t.Type);
            tag.Value(t.Value);
            tag.displayName(t.DisplayName);
            const a: Attachment = new Attachment().loadFromTag(tag);

            const collection: Attachment[] =
                t.Type == ProlifeSdk.TagType_DocumentReference ? attachments : lastVersionAttachments;
            collection.push(a);
        });
        this.attachments(attachments);
        this.lastVersionAttachments(lastVersionAttachments);
    }

    public SetTagsOnCollection(tags: ITodoListTaskTag[]) {
        this.ExtractTagsByType(tags).forEach((t1: ITodoListTaskTag) => {
            return tags.splice(tags.indexOf(t1), 1);
        });

        this.attachments()
            .map((a: Attachment) => {
                return {
                    Field: ProlifeSdk.Tag_File,
                    Type: ProlifeSdk.TagType_DocumentReference,
                    Value: a.id,
                    DisplayName: a.fileName,
                };
            })
            .forEach((tag: ITodoListTaskTag) => {
                tags.push(tag);
            });

        this.lastVersionAttachments()
            .map((a: Attachment) => {
                return {
                    Field: ProlifeSdk.Tag_File,
                    Type: ProlifeSdk.TagType_LastVersionReference,
                    Value: a.id,
                    DisplayName: a.fileName,
                };
            })
            .forEach((tag: ITodoListTaskTag) => {
                tags.push(tag);
            });
    }

    public ExtractTagsByType(tags: ITodoListTaskTag[]) {
        return tags.filter((t: ITodoListTaskTag) => {
            return t.Type == ProlifeSdk.TagType_DocumentReference || t.Type == ProlifeSdk.TagType_LastVersionReference;
        });
    }

    SwitchAttachmentsView() {
        this.ShowAttachmentsAsList(!this.ShowAttachmentsAsList());
    }

    private prepareDestinationFolder(): Promise<string> {
        const pathPreparationDef = new Deferred<string>();

        this.fileRepositoryService
            .checkStatusForTaskAttachmentUpload(this.jobOrderId, "")
            .then((status: IAttachmentUploadStatus) => {
                const destinationPath = status.PathForUpload.replace(/\\/g, "/");

                this.fileRepositoryService.ExistsDirectory(destinationPath).then((result: boolean) => {
                    if (!result) {
                        this.fileRepositoryService
                            .CreateDirectory(destinationPath)
                            .then(() => {
                                pathPreparationDef.resolve(destinationPath);
                            })
                            .catch(() => {
                                pathPreparationDef.reject([]);
                            });
                    } else pathPreparationDef.resolve(destinationPath);
                });
            });

        return pathPreparationDef.promise();
    }

    AddAttachments() {
        this.observers.forEach((o) => o.onAttachmentsAdd());

        this.prepareDestinationFolder().then((destinationPath: string) => {
            this.fileRepositoryService.openAsDialog(destinationPath).then((selectedFiles: IFileOrFolder[]) => {
                this.addAttachment(selectedFiles);

                this.observers.forEach((o) => o.onAttachmentsAdded());
            });
        });
    }

    private addAttachment(filesOrFolders: IFileOrFolder[]) {
        const alreadyPresentAttachmentsIds = this.attachments().map((a: Attachment) => {
            return a.id;
        });
        const newAttachments = filesOrFolders
            .filter((f: IFileOrFolder) => {
                return alreadyPresentAttachmentsIds.indexOf(f.Id) < 0;
            })
            .map((file: IFileOrFolder) => {
                return new Attachment().loadFromFile(file);
            });
        newAttachments.forEach((a: Attachment) => {
            this.attachments.push(a);
        });
    }

    AddLastVersionAttachments() {
        this.observers.forEach((o) => o.onAttachmentsAdd());

        this.prepareDestinationFolder().then((destinationPath: string) => {
            this.fileRepositoryService.openAsDialog(destinationPath).then((selectedFiles: IFileOrFolder[]) => {
                const alreadyPresentAttachmentsIds = this.lastVersionAttachments().map((a: Attachment) => {
                    return a.id;
                });
                const newAttachments = selectedFiles
                    .filter((f: IFileOrFolder) => {
                        return alreadyPresentAttachmentsIds.indexOf(f.Id) < 0;
                    })
                    .map((file: IFileOrFolder) => {
                        return new Attachment().loadFromFile(file);
                    });
                newAttachments.forEach((a: Attachment) => {
                    this.lastVersionAttachments.push(a);
                });

                this.observers.forEach((o) => o.onAttachmentsAdded());
            });
        });
    }

    DownloadAttachments() {
        this.observers.forEach((o) => o.onAttachmentsDownload());

        const fileToDownload: Attachment = this.SelectedAttachments()[0];

        if (this.attachments().indexOf(fileToDownload) > -1) fileToDownload.download();
        else fileToDownload.downloadLastVersion();
    }

    RemoveSelectedAttachments() {
        this.observers.forEach((o) => o.onAttachmentsRemove());

        this.SelectedAttachments().forEach((a: Attachment) => {
            if (this.attachments().indexOf(a) > -1) this.attachments.remove(a);
            else if (this.lastVersionAttachments().indexOf(a) > -1) this.lastVersionAttachments.remove(a);
        });

        this.observers.forEach((o) => o.onAttachmentsRemoved());
    }
}
