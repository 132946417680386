import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { SupplierOrderEvent } from "../SupplierOrderEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class SupplierOrderEventDetailsEdit extends DocumentEventDetailsEdit
{
    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver, event : SupplierOrderEvent)
    {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "f-icon-doc-text";
        this.title = ProlifeSdk.TextResources.Warehouse.SupplierOrder;
        this.setValues(event);
    }

    public setValues(item : SupplierOrderEvent) : void
    {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent
    {
        var event : ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.WarehouseEventType_SupplierOrder;
        return event;
    }
}