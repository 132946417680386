import * as ko from "knockout";
import { LazyImport } from "../../Core/DependencyInjection";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { ISettingsServiceObserver, ISettingsService } from "../interfaces/settings/ISettingsService";
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver";
import { IModulesService } from "../interfaces/desktop/IModulesService";

/***
 * Questa classe base definisce il ciclo di vita dei servizi di ProLife.
 */
export abstract class ProLifeService implements ISettingsServiceObserver, IAuthorizationServiceObserver
{
    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationService : IAuthorizationService;

    @LazyImport(nameof<ISettingsService>())
    protected settingsService : ISettingsService;

    @LazyImport(nameof<IModulesService>())
    protected modulesService : IModulesService;

    public ApplicationCode : string;
    
    private AuthorizationsAreLoaded : ko.Observable<boolean> = ko.observable(false);
    private SettingsAreLoaded : ko.Observable<boolean> = ko.observable(false);
    private SettingsAndAuthorizationsAreLoaded : ko.Computed<boolean>;

    constructor(applicationCode : string)
    {
        this.ApplicationCode = applicationCode;
        
        this.SettingsAndAuthorizationsAreLoaded = ko.computed(() => {
            return this.AuthorizationsAreLoaded() && this.SettingsAreLoaded();
        });

        this.SettingsAndAuthorizationsAreLoaded.subscribe((value) => {
            if(value)
                this.OnServerInitializationDataLoaded();
        });
    }

    public InitializeService()
    {
        this.authorizationService.addObserver(this);
        this.settingsService.addObserver(this);
        //Implementare nelle derivate. Richiamato in seguito alla creazione di tutti i servizi.
    }

    public OnServerInitializationDataLoaded()
    {
        //Implementare nelle derivate. Scatenato quando i settings e le autorizzazioni sono caricate.
    }

    public IsServiceEnabled() : boolean
    {
        return this.modulesService.IsModuleEnabled(this.ApplicationCode);

        //ATTENZIONE : Per ora si stabilisce la disponibilità del servizio in base all'autorizzazione sull'avvio dell'applicazione.
        //Sarebbe più corretto discriminare in base all'abilitazione dei moduli.
        //return this.authorizationService.isAuthorized(this.ApplicationCode + "_Start");
    }

    public onSettingsUpdated(updateType: string)
    {
        if(this.SettingsAreLoaded()) {
            this.SettingsAreLoaded(false);
            this.SettingsAreLoaded(true);
        }
    }

    public onSettingsLoaded() : void
    {
        if(!this.SettingsAreLoaded())
            this.SettingsAreLoaded(true);
    }

    public authorizationLoaded(rights: any)
    {
        if(!this.AuthorizationsAreLoaded())
            this.AuthorizationsAreLoaded(true);
    }
}