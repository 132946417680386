import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ITodoListResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITaskResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IControlsMultipleEntitiesProvider, IItemIdWithType } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class ResourcesAndGroupsManager
{
    @LazyImport(ProlifeSdk.EntityProviderServiceType)
    private entitiesProviderService : IEntityProviderService;
    public resourcesProvider : IControlsMultipleEntitiesProvider;

    public Resources : ko.Observable<string> = ko.observable("");
    public ResourcesIdsAndTypes : ko.ObservableArray<ITodoListResource> = ko.observableArray([]);

    private originalResources : string;

    constructor()
    {
        this.resourcesProvider = this.entitiesProviderService
            .GetMultipleEntitiesProviderForControls([ProlifeSdk.HumanResources, ProlifeSdk.ResourcesGroups], false, false);
    }

    public LoadResources(resources : ITodoListResource[])
    {
        this.ResourcesIdsAndTypes(resources);

        const resourcesString : string = this.resourcesProvider.formatItemsForControlValueField(resources.map((r : ITodoListResource) => {
            return <IItemIdWithType>{
                Id : r.ElementId,
                Type : r.ElementType
            };
        }));
        this.Resources(resourcesString);
        this.originalResources = resourcesString;
    }

    public IsChanged() : boolean
    {
        return this.originalResources != this.Resources();
    }

    public GetResources() : ITodoListResource[]
    {
        return this.resourcesProvider.extractItemsAndTypesFromStringValue(this.Resources()).map((i : IItemIdWithType) => {
            return <ITaskResource>{
                ElementType : i.Type,
                ElementId : i.Id
            }
        });
    }
}
