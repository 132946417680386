import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { GeneralInfo, RequestForQuotationGeneralInfo, _RequestForQuotationGeneralInfo } from "./RequestForQuotationEditor/RequestForQuotationGeneralInfo";
import { RouteParams } from "../../../../Components/Routing";
import { Layout } from "../../../../Components/Layouts";
import { RequestForQuotationRowsEditor, _RequestForQuotationRowsEditor } from "./RequestForQuotationEditor/RequestForQuotationRowsEditor";
import jss from "jss";
import { LazyImport, useService } from "../../../../Core/DependencyInjection";
import { IProvisioningService } from "../../../ProvisioningService";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";


const styleSheet = jss.createStyleSheet({
    editor: {
        "& .editor-actions": {
            "& button:not(:last-child)": {
                marginRight: "5px"
            }
        },

        "& .request-for-quotation-info-list": {
            listStyle: "none",
            padding: "0px",
            margin: "0px",
        
            "& .request-for-quotation-info": {
                fontStyle: "italic",
            }
        },

        "& .request-title": {
            marginTop: "5px"
        }
    },

    requestForQuotationRowsTable : {
        "& .actions-col": {
            whiteSpace: "nowrap"
        },

        "&.editable > tbody > tr.verified > td": {
            backgroundColor: "#e0FFe0"
        }
    },

    caustomersAndJobOrderstable : {
        "& .actions-col": {
            width: "35px"
        }
    }
});
const { classes } = styleSheet.attach();

export type RequestForQuotationEditorProps = RouteParams<{
    requestId: number;
}>

export function RequestForQuotationEditor(props: RequestForQuotationEditorProps) {
    const C = require("./RequestForQuotationEditor")._RequestForQuotationEditor as typeof _RequestForQuotationEditor;
    return <C {...props} />
}

export class _RequestForQuotationEditor {
    private hasChanges : ko.Observable<boolean> = ko.observable(false);
    private editor : _RequestForQuotationRowsEditor;
    private info : _RequestForQuotationGeneralInfo;
    private onRequestForQuotationChangedHandle: number;
    
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props : RequestForQuotationEditorProps) {
    }

    componentDidMount() {
        this.onRequestForQuotationChangedHandle = this.changesNotificationsService.RegisterEventHandler("OnRequestForQuotationChanged", async (data: { requestId: number }) => {
            if(data.requestId !== this.props.requestId) return;

            if(this.hasChanges()) {
                if(!await this.dialogsService.ConfirmAsync("Attenzione! Un altro utente ha appena salvato delle modifiche a questa richiesta d'offerta, si desidera caricare le nuove modifiche?\r\nSe si decide di caricare la nuova versione della richiesta d'offerta, le modifiche apportate verranno perse!", "Non Caricare", "Carica Modifiche"))
                    return;
            }

            await this.info.load();
            await this.editor.load();
            this.hasChanges(false);
        });
    }

    componentWillUnmount() {
        this.changesNotificationsService.UnregisterEventHandler("OnRequestForQuotationChanged", this.onRequestForQuotationChangedHandle);
    }

    public async save(info: GeneralInfo) {
        const rows = this.editor.getRows();
        const provisioningService = useService<IProvisioningService>(nameof<IProvisioningService>());

        this.hasChanges(false);
        try
        {
            await provisioningService.SaveRequestForQuotation({
                ...info,
                rows
            });
        }
        catch
        {
            this.hasChanges(true);
        }
    }

    render() {
        return  <Layout.Grid columns={["1fr"]} rows={["340px", "1fr"]} rowGap={"2rem"} className={classes.editor} style={{ maxHeight: '100%' }}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <RequestForQuotationGeneralInfo requestId={this.props.requestId} navigator={this.props.navigator} hasChanges={this.hasChanges} onSave={(i) => this.save(i)} forwardRef={i => this.info = i} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <RequestForQuotationRowsEditor requestId={this.props.requestId} navigator={this.props.navigator} hasChanges={this.hasChanges} forwardRef={e => this.editor = e} />
                    </Layout.Grid.Cell>
                </Layout.Grid>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RequestForQuotationEditor);
}