import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils, classNames } from "../Core/utils/ComponentUtils";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";
import jss from "jss";

const styleSheet = jss.createStyleSheet({
    richTextInput: {
        scrollbarWidth: "thin",
        scrollbarColor: "#333 transparent",
        whiteSpace: "normal",

        "& ::-webkit-scrollbar": {
            width: "10px",
            height: "10px",
        },

        "& ::-webkit-scrollbar-track": {
            background: "transparent",
        },

        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: "#333",
            borderRadius: "20px",
            border: "4px solid transparent",
            backgroundClip: "content-box",
        },
    },
});
const { classes } = styleSheet.attach();

type RichTextInputProps = {
    value: ko.Observable<string>;
    inline?: boolean;
    simple?: boolean;
    label?: string;
    trigger?: ko.Subscribable<any>;
    resizable?: boolean;
    height?: number | string;
    placeholder?: ko.MaybeSubscribable<string>;
    toolbarConfig?: string;
    fontSizes?: string;
    readonly?: ko.MaybeSubscribable<boolean>;
    autoGrow?: boolean;
    className?: string;
    inputClassName?: string;
    inputStyle?: CSSProperties;
    style?: CSSProperties;
};

export function RichTextInput(props: RichTextInputProps) {
    const C = require("./RichTextInput")._RichTextInput as typeof _RichTextInput;
    return <C {...props} />;
}

export class _RichTextInput {
    static defaultProps = {
        inline: false,
        simple: false,
        label: " ",
        resizable: false,
        autoGrow: false,
    };

    options: any = {};

    constructor(private props: RichTextInputProps) {
        if (!this.props.trigger) this.props.trigger = ko.observable();
        if (!this.props.readonly) this.props.readonly = ko.observable(false);

        this.options.inlineMode = props.inline;
        this.options.resize_enabled = props.resizable;
        this.options.resize_dir = "vertical";
        this.options.toolbarCanCollapse = true;

        if (props.height) this.options.height = props.height;

        if (props.fontSizes) this.options.fontSize_sizes = props.fontSizes;

        if (props.autoGrow) this.options.fitParent = true;
    }

    render() {
        return ComponentUtils.bindTo(this.renderInternal(), this, "rti");
    }

    renderInternal() {
        if (this.props.simple) return this.renderTextArea();

        return (
            <div className={classNames("form-group", this.props.className)} style={this.props.style}>
                <label className="control-label">{this.props.label}</label>
                {this.renderTextArea()}
            </div>
        );
    }

    renderTextArea() {
        const rti = this;
        const finalStyle = {
            ...this.props.style,
            minHeight: "34px",
            height: "auto",
        };

        if (this.props.inline)
            return (
                <div className={classNames("form-control", classes.richTextInput)} style={finalStyle}>
                    <div
                        className={this.props.inputClassName}
                        style={this.props.inputStyle}
                        data-bind={{
                            readonly: rti.props.readonly,
                            htmlEditor: rti.props.value,
                            htmlEditorOptions: rti.options,
                            htmlEditorTrigger: rti.props.trigger,
                            htmlEditorToolbarConfig: rti.props.toolbarConfig,
                            attr: { placeholder: rti.props.placeholder },
                        }}></div>
                </div>
            );

        return (
            <textarea
                className={classNames("form-control", this.props.inputClassName)}
                data-bind={{
                    readonly: rti.props.readonly,
                    htmlEditor: rti.props.value,
                    htmlEditorOptions: rti.options,
                    htmlEditorTrigger: rti.props.trigger,
                    htmlEditorToolbarConfig: rti.props.toolbarConfig,
                    attr: { placeholder: rti.props.placeholder },
                }}></textarea>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RichTextInput);
}
