import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { ICustomerBank } from "../ProlifeSdk/interfaces/customer/ICustomerBank";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";

export type CustomerCABDataSourceModel = IDataSourceModel<string, ICustomerBank>

export class CustomerCABDataSource extends BaseDataSource<CustomerCABDataSourceModel> {
    private customer : ICustomer;

    public setCustomer(customer : ICustomer) {
        this.customer = customer;
    }

    getTitle(currentModel: CustomerCABDataSourceModel): string {
        return TextResources.Customers.CustomerABIs;
    }

    private getAllCustomerBanks() : ICustomerBank[] {
        const banks = [];
        for(const ou of this.customer.OrganizationalUnits) {
            for(const bank of ou.Banche) {
                banks.push(bank);
            }
        }
        return banks;
    }

    async getData(currentModel: CustomerCABDataSourceModel, textFilter: string, skip: number, count: number): Promise<CustomerCABDataSourceModel[]> {
        if(!this.customer) return [];
        
        if(textFilter === undefined || textFilter === null || textFilter === "")
            return this.getAllCustomerBanks().slice(skip, count).map(this.createModel, this);

        const splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        return this.getAllCustomerBanks()
            .filter(d => splitFilters.filter(w => d.CAB.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: CustomerCABDataSourceModel, ids: (string | number)[]): Promise<CustomerCABDataSourceModel[]> {
        if(!this.customer) return [];
        
        return this.getAllCustomerBanks()
            .filter(p => ids.indexOf(p.CAB) >= 0)
            .map(this.createModel, this);
    }

    private createModel(bank : ICustomerBank) : CustomerCABDataSourceModel {
        return {
            id: bank.CAB,
            title: bank.CAB,
            subTitle: bank.Name,
            isLeaf: true,
            isGroup: false,
            model: bank
        }
    }

}