import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { DocumentDataSource } from "../../../../../ProlifeSdk/prolifesdk/DocumentDataSource";
import { LazyImportSettingManager, LazyImport } from "../../../../../Core/DependencyInjection";
import { JobOrdersDataSource } from "../../../../../DataSources/JobOrdersDataSource";
import { UngroupedCustomersDataSource } from "../../../../../DataSources/UngroupedCustomersDataSource";
import { IMonthlyInvoicingDataSourceBaseFilters } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IJobOrderType } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderTypesSettingsManager } from "../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";

export abstract class BaseDataSourceForMonthlyInvoicing extends DocumentDataSource {
    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();
    public JobOrder: ko.Observable<number> = ko.observable();
    public Customer: ko.Observable<number> = ko.observable(null);
    public JobOrderType: ko.Observable<number> = ko.observable(null);

    public JobOrdersDataSource: JobOrdersDataSource;
    public CustomersDataSource: UngroupedCustomersDataSource;

    protected DisableDocumentsReload: boolean = false;

    protected jobOrderTypes: ko.ObservableArray<IJobOrderType> = ko.observableArray([]);

    @LazyImport(nameof<IUserInfo>())
    protected userInfoService: IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    protected jobOrderTypesManager: IJobOrderTypesSettingsManager;
    
    protected loadRowsAfterInitialize: boolean = true;

    constructor(public templateName : string, public templateUrl : string, public title: string, public viewModel : any = null) {
        super(templateName, templateUrl, title, viewModel);

        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.JobOrdersDataSource.setUserId(this.userInfoService.getIdUser());
        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);
        
        this.CustomersDataSource = new UngroupedCustomersDataSource();
    }

    public Initialize(): void {
        super.Initialize(<IWizardInitializationInfo> {}, []);
        
        this.DateFrom(moment().add("month", -1).startOf('month').toDate());
        this.DateTo(moment().add("month", -1).endOf('month').toDate());
        this.JobOrder(null);
        this.JobOrderType(null);
        this.Customer(null);

        this.jobOrderTypes(this.jobOrderTypesManager.getVisibleJobOrderTypes());
    }

    public InitializeFilters(filters: IMonthlyInvoicingDataSourceBaseFilters): void {
        this.DisableDocumentsReload = true;

        this.DateFrom(filters.From);
        this.DateTo(filters.To);
        this.JobOrder(filters.JobOrderId);
        this.JobOrderType(filters.JobOrderType);
        this.Customer(filters.CustomerId);

        this.DisableDocumentsReload = false;
    }
    
    public SetLoadRowsAfterInitialize(value: boolean): void {
        this.loadRowsAfterInitialize = value;
    }

    public GetFilters(): IMonthlyInvoicingDataSourceBaseFilters {
        return {
            From: this.DateFrom(),
            To: this.DateTo(),
            JobOrderId: this.JobOrder(),
            JobOrderType: this.JobOrderType(),
            CustomerId: this.Customer()
        }
    }
}