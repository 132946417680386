import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { ArrayDataSource, IArrayDataSourceModel } from "../../../DataSources/ArrayDataSource";
import { IExpenseStatusesSettingsManager } from "./ExpenseStatusesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { ExpenseLogicalStatus } from "../enums/ExpenseLogicalStatus";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Column } from "../../../Components/TableComponent/CustomColumn";
import jss from "jss";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IExpenseService, IExpenseStatus } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";

const { classes } = jss.createStyleSheet({
    "expense-statuses-editor": {
        "& .header-bar": {
            "margin-bottom": "10px",

            "& .add-button": {
                "margin-right": "10px"
            },
        },
        
        "& .table": {
            "& .form-group": {
                margin: "0px"
            },

            "& .actions-col": {
                width: "60px"
            }
        }
    }
}).attach();

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "expense-statuses-editor": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IExpenseStatusesEditorParams {

}

export class ExpenseStatusesEditor {
    public title: string = TextResources.Expenses.ExpenseStatusesEditorTitle;

    public StatusesDataSource: ArrayDataSource<ExpenseStatus> = new ArrayDataSource();
    public LogicalStatusesDataSource: ArrayDataSource<ExpenseLogicalStatus> = new ArrayDataSource();

    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImportSettingManager(nameof<IExpenseStatusesSettingsManager>())
    private expenseStatusesSettingsManager: IExpenseStatusesSettingsManager;

    constructor(params : IExpenseStatusesEditorParams) {
        this.loadLogicalStatuses();
        this.loadStatuses();
    }

    public addStatus(): void {
        let newStatus: IExpenseStatus = {
            Id: this.expenseService.GetTemporaryId(),
            Label: null,
            LogicalStatus: ExpenseLogicalStatus.Draft,
            Deleted: false
        };

        let currentStatuses = this.StatusesDataSource.getAllData();
        currentStatuses.push({
            id: newStatus.Id,
            title: null,
            isGroup: false,
            isLeaf: true,
            model: new ExpenseStatus(newStatus)
        });

        this.StatusesDataSource.setData(...currentStatuses);
        this.StatusesDataSource.refresh();
    }

    public async saveStatuses(): Promise<void> {
        let items = this.StatusesDataSource.getAllData();
        let modifiedStatuses: IExpenseStatus[] = items.filter(s => s.model.isChanged() !== 0).map(s => s.model.getData());

        if (modifiedStatuses.length === 0)
            return;

        await this.expenseStatusesSettingsManager.insertOrUpdateStatuses(modifiedStatuses);
        this.loadStatuses();
        this.StatusesDataSource.refresh();

        this.infoToastService.Success(TextResources.Expenses.SaveSuccess);
    }

    public async deleteStatus(status: ExpenseStatus): Promise<void> {
        let statusModel = status.getData();
        statusModel.Deleted = true;

        let statuses = this.StatusesDataSource.getAllData();
        for (let index = 0; index < statuses.length; index++) {
            let iterationStatus = statuses[index];
            if (iterationStatus.id === statusModel.Id) {
                statuses.splice(index, 1);
                break;
            }
        }

        if (!!statusModel.Id && statusModel.Id > 0)
            await this.expenseStatusesSettingsManager.insertOrUpdateStatuses([statusModel]);

        this.StatusesDataSource.setData(...statuses);
        this.StatusesDataSource.refresh();

        this.infoToastService.Success(TextResources.Expenses.DeleteSuccess);
    }

    public addExpenseStatusButton(): React.ReactNode {
        let $component: ExpenseStatusesEditor;

        return (
            <button class="btn btn-primary" data-bind={{ click: $component.addStatus.bind($component)}}>
                <i class="fa fa-plus"></i>
            </button>
        );
    }

    private loadStatuses(): void {
        this.StatusesDataSource.setData(...this.createDataSourceModels());
    }

    private loadLogicalStatuses() {
        let ls: IArrayDataSourceModel<ExpenseLogicalStatus>[] = [];
        ls.push({
            id: ExpenseLogicalStatus.Draft,
            title: TextResources.Expenses.DraftLogicalStatus,
            isLeaf: true,
            isGroup: false,
            model: ExpenseLogicalStatus.Draft
        });
        ls.push({
            id: ExpenseLogicalStatus.Approved,
            title: TextResources.Expenses.ApprovedLogicalStatus,
            isLeaf: true,
            isGroup: false,
            model: ExpenseLogicalStatus.Approved
        });
        ls.push({
            id: ExpenseLogicalStatus.Refunded,
            title: TextResources.Expenses.RefundedLogicalStatus,
            isLeaf: true,
            isGroup: false,
            model: ExpenseLogicalStatus.Refunded
        });
        this.LogicalStatusesDataSource.setData(...ls);
    }

    private createDataSourceModels(): IArrayDataSourceModel<ExpenseStatus>[] {
        let models: IArrayDataSourceModel<ExpenseStatus>[] = [];

        let statuses = this.expenseStatusesSettingsManager.getStatuses();
        for (let status of statuses) {
            models.push({
                id: status.Id,
                title: status.Label,
                isGroup: false,
                isLeaf: true,
                model: new ExpenseStatus(status)
            });
        }

        return models;
    }
}

@DetectClassChanges
class ExpenseStatus {
    public get Id(): number {
        return this.status.Id;
    }
    
    @DetectChanges
    public Label : ko.Observable<string> = ko.observable();
    @DetectChanges
    public LogicalStatus : ko.Observable<ExpenseLogicalStatus> = ko.observable();
    
    public isChanged : ko.Observable<number> = ko.observable(0);

    constructor(private status: IExpenseStatus) {
        this.Label(this.status?.Label);
        this.LogicalStatus(this.status?.LogicalStatus);

        if (!this.status || (this.status.Id || 0) <= 0)
            this.isChanged(1);
    }

    public getData(): IExpenseStatus {
        this.status.Label = this.Label();
        this.status.LogicalStatus = this.LogicalStatus();

        return this.status;
    }

    public dispose(): void {
        
    }
}

ko.components.register("expense-statuses-editor", {
    viewModel: {
        createViewModel: (params: IExpenseStatusesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new ExpenseStatusesEditor(params);
            
            let model: ExpenseStatus;
            let $component: ExpenseStatusesEditor;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={"flex-container flex-vertical " + classes["expense-statuses-editor"]} style="height: 100%" data-as={{ vm }}>
                    <h3 class="page-title">
                        <span data-bind={{ text: vm.title }}></span>
                    </h3>
                    <div class="flex-container flex-fill flex-vertical">
                        <div class="header-bar">
                            <button class="btn btn-primary pull-right add-button" data-bind={{ click: vm.saveStatuses.bind(vm) }}>
                                <i class="fa fa-save"></i>
                            </button>
                        </div>
                        <div class="flex-container flex-vertical flex-fill">
                            <Table dataSource={vm.StatusesDataSource}>
                                <Column title={TextResources.Expenses.ExpenseStatusLabel}>
                                    <text-input value={() => "model.Label"} placeholder={TextResources.Expenses.ExpenseStatusLabelPlaceholder}></text-input>
                                </Column>
                                <Column title={TextResources.Expenses.ExpenseLogicalStatus}>
                                    <select2 value={() => "model.LogicalStatus"} dataSource={() => "$component.LogicalStatusesDataSource"} placeholder={TextResources.Expenses.ExpenseLogicalStatusPlaceholder} allowClear={true} simple={true}></select2>
                                </Column>
                                <Column title="" cssClasses="text-right actions-col" headerNodesProvider= {() => vm.addExpenseStatusButton()}>
                                    <button class="btn btn-danger" data-bind={{ click: $component.deleteStatus.bind($component, model) }}>
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </Column>
                            </Table>
                        </div>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});