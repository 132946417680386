import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 13:31
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { QueueStateEditingViewModel } from "./ui/QueueStateEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IQueueStateSettingManager } from "../../../interfaces/IQueueStateSettingManager";
import { IQueueState, ILogicalQueueState } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueState";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";

export class QueueStateSettingManager implements IQueueStateSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private queueStates: IQueueState[] = [];
    private logicalQueueStates: ILogicalQueueState[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        this.logicalQueueStates.push({Id: 1, Description: ProlifeSdk.TextResources.SurveyWizard.ToCallState});
        this.logicalQueueStates.push({Id: 2, Description: ProlifeSdk.TextResources.SurveyWizard.InProgressCallState});
        this.logicalQueueStates.push({Id: 3, Description: ProlifeSdk.TextResources.SurveyWizard.OverCallState});
    }

    getQueueStates(): IQueueState[] {
        return this.queueStates;
    }

    getQueueStateById(id: number): IQueueState {
        var states: IQueueState[] = this.queueStates.filter((s: IQueueState) => { return s.Id == id });
        return states[0];
    }

    getLogicalQueueStates(): ILogicalQueueState[] {
        return this.logicalQueueStates;
    }

    load(): Promise<IQueueState[]> {
        return this.ajaxService.Post<IQueueState[]>("SurveyWizard-api/SurveyWizardApi", "GetQueueStates", {})
            .then((states: IQueueState[]) => { this.queueStates = states; return states; });
    }

    getName(): string {
        return ProlifeSdk.QueueState;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.SurveyWizard.QueueState;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyWizardApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "queue-state",
            templateUrl: "surveywizard/templates/settings",
            viewModel: new QueueStateEditingViewModel(this.serviceLocator, this)
        };
    }

    addOrUpdate(state: IQueueState): Promise<IQueueState> {
        return this.ajaxService.Post<IQueueState>("SurveyWizard-api/SurveyWizardApi", "AddOrUpdateQueueState", {
            methodData: {
                State: state
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("SurveyWizard-api/SurveyWizardApi", "RemoveQueueState", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(updatedState: IQueueState): void {
        var found: boolean = false;

        this.queueStates.forEach((s: IQueueState) => {
            if (s.Id != updatedState.Id)
                return;

            found = true;

            s.Id = updatedState.Id;
            s.StateId = updatedState.StateId;
            s.Label = updatedState.Label;
        });

        if (!found)
            this.queueStates.push(updatedState);
    }

    private removeState(id: number): void {
        for (var i = 0; i < this.queueStates.length; i++) {
            if (this.queueStates[i].Id == id) {
                this.queueStates.splice(i, 1);
                break;
            }
        }
    }
}