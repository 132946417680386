import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/02/2018
 * Time: 17:23
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { UiUtilities } from "./utils/UiUtilities";
import * as moment from "moment";
import { Moment } from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../../Core/interfaces/IAuthenticationService";
import { IFullCalendar, IFullCalendarViewObject, IFullCalendarEventsDragObserver, IFullCalendarEventsSource, IFullCalendarHeader, IFullCalendarViewMap, IFullCalendarSelectedInterval } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IAgendaEvent, IEventsService } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IEventHandler } from "../../../ProlifeSdk/interfaces/IEventHandler";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IApplicationsConfigurationsService, IApplicationConfiguration } from "../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IAgendaTimetableViewModel } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { Deferred } from "../../../Core/Deferred";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class Calendar implements IFullCalendar {
    public SelectedEvent: ko.Observable<IAgendaEvent> = ko.observable();
    public SelectedInterval: ko.Observable<IFullCalendarSelectedInterval> = ko.observable();

    public GetActualView: ko.Computed<IFullCalendarViewObject>;

    public OnEventAdded: IEventHandler;
    public OnEventUpdated: IEventHandler;
    public OnEventDeleted: IEventHandler;
    public OnEventsRefresh: IEventHandler;
    public OnChengeViewRequest: IEventHandler;
    public OnSetHeaderRequest: IEventHandler;
    public OnSetViewsOptionRequest: IEventHandler;
    public OnGoToDateRequest: IEventHandler;
    public OnSelectRequest: IEventHandler;
    public OnUnselectRequest: IEventHandler;
    public OnSetBusinessHoursRequest: IEventHandler;
    public OnSetSlotDurationRequest: IEventHandler;

    public JQuerySelectedEvent: JQuery;

    private ActualView: ko.Observable<IFullCalendarViewObject> = ko.observable();

    private eventsService: IEventsService;
    private infoToastService: IInfoToastService;
    private dialogsService: IDialogsService;
    private modulesService: IModulesService;
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    private authenticationService: IAuthenticationService;

    private eventsDragObservers: IFullCalendarEventsDragObserver[] = [];

    private static _self: IFullCalendar;

    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator, private agenda: IFullCalendarEventsSource) {
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.applicationsConfigurationsService = <IApplicationsConfigurationsService> this.serviceLocator.findService(ProlifeSdk.ApplicationsConfigurationsServiceCode);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.authenticationService = <IAuthenticationService> this.serviceLocator.findService(ServiceTypes.Authentication);

        this.OnEventAdded = new EventHandler();
        this.OnEventUpdated = new EventHandler();
        this.OnEventDeleted = new EventHandler();
        this.OnEventsRefresh = new EventHandler();
        this.OnChengeViewRequest = new EventHandler();
        this.OnSetHeaderRequest = new EventHandler();
        this.OnSetViewsOptionRequest = new EventHandler();
        this.OnGoToDateRequest = new EventHandler();
        this.OnSelectRequest = new EventHandler();
        this.OnUnselectRequest = new EventHandler();
        this.OnSetBusinessHoursRequest = new EventHandler();
        this.OnSetSlotDurationRequest = new EventHandler();

        this.GetActualView = ko.computed(() => {
            return this.ActualView();
        });

        Calendar._self = this;
    }

    public GetEvents(): IAgendaEvent[] { return undefined; }

    public SelectEvent(event: IAgendaEvent) {
        this.SelectedEvent(event);
    }

    public RenderEvent(newEvent: IAgendaEvent) {
        this.OnEventAdded.Call(newEvent);
    }

    public UpdateEvent(updatedEvent: IAgendaEvent) {
        const events: IAgendaEvent[] = this.GetEvents().filter((e: IAgendaEvent) => e.id == updatedEvent.id);
        if (events.length == 0)
            return;

        const eventToUpdate = events[0];

        this.UpdateEventObject(eventToUpdate, updatedEvent);
        this.OnEventUpdated.Call(eventToUpdate);
    }

    public RemoveEvent(eventId: number) {
        this.OnEventDeleted.Call(eventId);
    }

    public RefreshEvents() {
        this.OnEventsRefresh.Call();
    }

    public SetHeader(header: IFullCalendarHeader): void {
        this.OnSetHeaderRequest.Call(header);
    }

    public SetViewsOption(views: IFullCalendarViewMap) {
        this.OnSetViewsOptionRequest.Call(views);
    }

    public ChangeView(view: string): void {
        this.OnChengeViewRequest.Call(view);
    }

    public GoToDate(date: Moment): void {
        this.OnGoToDateRequest.Call(date);
    }

    public SetBusinessHours(businessHours: any): void {
        this.OnSetBusinessHoursRequest.Call(businessHours);
    }

    public SelectInterval(start: Moment, end: Moment = null): void {
        if (!end) {
            this.OnSelectRequest.Call(start);
            return;
        }
        this.OnSelectRequest.Call(start, end);
    }

    public ClearSelection(): void {
        this.OnUnselectRequest.Call();
    }

    public SetSlotDuration(duration: string): void {
        this.OnSetSlotDurationRequest.Call(duration);
    }

    public IsInViewRange(start: Moment, end: Moment): boolean {
        return (this.ActualView().start <= start && this.ActualView().end > start) || (this.ActualView().start <= end && this.ActualView().end > end);
    }

    public AddEvent(): Promise<IAgendaEvent> {
        const def: Deferred<IAgendaEvent> = new Deferred<IAgendaEvent>();

        if (!this.authorizationService.isAuthorized("Agenda_CreateEvents")) {
            def.resolve(null);
            return def.promise();
        }

        this.ShowEditingDialog()
            .then((modifiedEvent: IAgendaEvent) => {
                if (!modifiedEvent) {
                    def.resolve(null);
                    return;
                }

                this.SetEventId(modifiedEvent);

                if (!this.EventHasBeenMoved(modifiedEvent))
                    this.RenderEvent(modifiedEvent);

                def.resolve(modifiedEvent);
            });

        return def.promise();
    }

    public ModifyEvent(event: IAgendaEvent, loadFromDb: boolean = true): Promise<IAgendaEvent> {
        const def = new Deferred<IAgendaEvent>();

        if (!event.editable)
            return def.resolve(null).promise();

        this.ShowEditingDialog(event, loadFromDb)
            .then((modifiedEvent: IAgendaEvent) => {
                if (!modifiedEvent) {
                    def.resolve(null);
                    return;
                }

                this.SetEventId(modifiedEvent);

                if (this.EventHasBeenDeleted(modifiedEvent) || this.EventHasBeenMoved(modifiedEvent)) {
                    this.RemoveEvent(event.id);
                    def.resolve(modifiedEvent);
                    return;
                }

                this.UpdateEvent(modifiedEvent);
                def.resolve(modifiedEvent);
            });

        return def.promise();
    }

    public OnDragStartCallback(event: IAgendaEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {
        this.NotifyEventDragStart(event, jsEvent, view, this);
    }

    public OnDragStopCallback(event: IAgendaEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {
        this.NotifyEventDragStop(event, jsEvent, view, this);
    }

    public OnViewRenderCallback(view: IFullCalendarViewObject, element: HTMLElement): void {
        this.ActualView(view);

        if (view.name == ProlifeSdk.CalendarMonthView) {
            this.SetBusinessHours(false);
            return;
        }

        this.SetBusinessHours(this.agenda.GetBusinessHours(moment(view.start).local(), moment(view.end).add("days", -1).local()));
    }

    public OnEventRenderCallback(event: IAgendaEvent, element: JQuery<HTMLElement>, view: IFullCalendarViewObject): any {}

    public OnEventDestroyCallback(event: IAgendaEvent, element: HTMLElement, view: IFullCalendarViewObject): void {}

    public OnEventDropCallback(event: IAgendaEvent, delta: any, revertFunc: () => void, jsEvent: any, ui: JQuery, view: IFullCalendarViewObject): void {
        this.UpdateEventAfterDropOrResize(event, revertFunc);
    }

    public OnEventResizeCallback(event: IAgendaEvent, delta: any, revertFunc: () => void, jsEvent: any, ui: JQuery, view: IFullCalendarViewObject): void {
        this.UpdateEventAfterDropOrResize(event, revertFunc);
    }

    public OnEventClickCallback(event: IAgendaEvent, jsEvent: any, view: IFullCalendarViewObject): void {
        Calendar._self.SelectEvent(event);
        if (jsEvent.originalEvent.detail > 1) {
            (<any>Calendar._self).OnEventDblClick(event);
            return;
        }

        if ((<any>Calendar._self).JQuerySelectedEvent)
            (<any>Calendar._self).JQuerySelectedEvent.css('outline', '1px solid none');

        (<any>Calendar._self).JQuerySelectedEvent = $(this);
        $(this).css('outline', '1px solid red');
    }

    public OnDayRenderCallback(date: Moment, cell: JQuery): void {
        const day = date.day();

        const timeTable: IAgendaTimetableViewModel = this.agenda.GetTimetableForDay(date);
        if (!timeTable)
            return;
        if(!timeTable.IsWorkableDay(day)) {
            cell.css('background-color', '#eee');
        }
    }

    public OnDropCallback(date: Moment, jsEvent: any, ui: JQuery, view: IFullCalendarViewObject, resourceId: any): void {}

    public OnEventReceiveCallback(event: IAgendaEvent): void {
        event.start = moment(event.start).local();
        event.end = moment(event.end).local();

        this.CanMoveEvent(event)
            .then((result: boolean) => {
                if (!result) {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CannotPlanDueToMissingRightsOrOutOfCompetenceEvent);
                    this.RemoveEvent(event.id);
                    return;
                }

                this.internalOnEventReceive(event);
            })
            .catch(() => {
                this.RemoveEvent(event.id);
            });
    }

    private internalOnEventReceive(event: IAgendaEvent): void {
        const originalCategory = event.CategoryId;
        this.SetEventColor(event, originalCategory);

        this.LoadUserConfigurations()
            .then((preferredCategory: number) => {
                if (!preferredCategory || !this.agenda.TimeslotsModeEnabled())
                    return;
                event.CategoryId = preferredCategory;
                this.SetEventColor(event, preferredCategory);
            })
            .finally(() => {
                if (this.agenda.TimeslotsModeEnabled()) {
                    if (!this.agenda.IsTimetableRespected(event.start, event.end, event.allDay, false)) {
                        this.dialogsService.Alert(
                            ProlifeSdk.TextResources.Agenda.EventOutOfTimetableAlertMessage,
                            ProlifeSdk.TextResources.Agenda.EventBookingAlertLabel,
                            () => { this.RemoveEvent(event.id); }
                        );
                        return;
                    }

                    if (!this.agenda.IsMaxNumberOfEventsForTimeRespected(event.start, event.id)) {
                        this.dialogsService.Alert(
                            ProlifeSdk.TextResources.Agenda.MaxContemporaryEventsNumberExceeded,
                            ProlifeSdk.TextResources.Agenda.EventBookingAlertLabel,
                            () => { this.RemoveEvent(event.id); }
                        );
                        return;
                    }
                }
                this.ModifyEvent(event, false)
                    .then((modifiedEvent: IAgendaEvent) => {
                        if (!modifiedEvent) {
                            event.CategoryId = originalCategory;
                            this.SetEventColor(event, originalCategory);
                            this.RemoveEvent(event.id);
                            return;
                        }

                        this.agenda.OnEventModified(modifiedEvent)
                    });
            });
    }

    public OnSelectCallback(start: Moment, end: Moment, jsEvent: any, view: IFullCalendarViewObject): void {
        start = start.local();
        end = end.local();

        if (this.agenda.TimeslotsModeEnabled()) {
            const timetable: IAgendaTimetableViewModel = this.agenda.GetTimetableForDay(start);
            const day = start.day();

            if (timetable) {
                if (end.diff(start, 'days') > 1 || !timetable.IsWorkableDay(day)) {
                    this.ClearSelection();
                    return;
                }

                if (view.name != ProlifeSdk.CalendarMonthView) {
                    if (!this.agenda.IsTimetableRespected(start, end, false) || !this.agenda.IsMaxNumberOfEventsForTimeRespected(start)) {
                        this.ClearSelection();
                        return;
                    }
                }
            }
        }

        if (jsEvent.originalEvent.detail > 1) {
            this.AddEvent();
        }

        this.SelectedInterval(<IFullCalendarSelectedInterval> {
            start: start,
            end: end,
            jsEvent: jsEvent,
            view: view
        });
    }

    public OnUnselectCallback(jsEvent: any, view: IFullCalendarViewObject): void {
        this.SelectedInterval(undefined);
    }

    public RegisterEventsDragObserver(observer: IFullCalendarEventsDragObserver): void {
        this.eventsDragObservers.push(observer);
    }

    public NotifyEventDragStart(event: IAgendaEvent, jsEvent: any, view: IFullCalendarViewObject, sourceCalendar: IFullCalendar): void {
        this.eventsDragObservers.forEach((o: IFullCalendarEventsDragObserver) => o.OnEventDragStart(event, jsEvent, view, sourceCalendar));
    }

    public NotifyEventDragStop(event: IAgendaEvent, jsEvent: any, view: IFullCalendarViewObject, sourceCalendar: IFullCalendar): void {
        this.eventsDragObservers.forEach((o: IFullCalendarEventsDragObserver) => o.OnEventDragStop(event, jsEvent, view, sourceCalendar));
    }

    private EventHasBeenDeleted(event: IAgendaEvent): boolean {
        return event.Deleted != ProlifeSdk.NotDeletedState;
    }

    private EventHasBeenMoved(event: IAgendaEvent): boolean {
        const categoryLogicalState: number = this.GetEventCategoryLogicalState(event.CategoryId);
        return event.AgendaId != this.agenda.AgendaId() || categoryLogicalState == ProlifeSdk.WaitingListEventState || categoryLogicalState == ProlifeSdk.AbortedEventState
    }

    private GetEventCategoryLogicalState(categoryId: number): number {
        const settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const categoriesSettings = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);

        return categoriesSettings.getCategoryById(categoryId).LogicalState;
    }

    private SetEventId(event: IAgendaEvent): void {
        event.id = 'E-' + event.id;
    }

    private SetEventColor(event: IAgendaEvent, categoryId: number): void {
        const color = !categoryId ? ProlifeSdk.AgendaEventDefaultColor : this.GetCategoryColor(categoryId);
        event.color = UiUtilities.GetColorWithOpacity(color, 0.6);
        event.textColor = UiUtilities.GetForegroundColorForAlphaColor(color, 0.6, "#FFFFFF");
    }

    private OnEventDblClick(event: IAgendaEvent): void {
        if (event.editable)
            this.ModifyEvent(event)
                .then((modifiedEvent: IAgendaEvent) => {
                    if (!modifiedEvent)
                        return;

                    this.agenda.OnEventModified(modifiedEvent);
                });
    }

    private ShowEditingDialog(event: IAgendaEvent = null, loadFromDb: boolean = true): Promise<IAgendaEvent[] | IAgendaEvent> {
        if (loadFromDb)
            return this.eventsService.ui.GetEventEditingDialog(!event ? null : event.id, this.agenda.AgendaId(), this);

        return this.eventsService.ui.GetEventEditingDialogByEventObject(event, this.agenda.AgendaId(), this);
    }

    private UpdateEventObject(sourceEvent: IAgendaEvent, updatedEvent: IAgendaEvent): void {
        sourceEvent.id = updatedEvent.id;
        sourceEvent.title = updatedEvent.title;
        sourceEvent.allDay = updatedEvent.allDay;
        sourceEvent.start = moment(updatedEvent.start);
        sourceEvent.end = moment(updatedEvent.end);
        sourceEvent.color = updatedEvent.color;
        sourceEvent.EventPlace = updatedEvent.EventPlace;
        sourceEvent.AgendaId = updatedEvent.AgendaId;
        sourceEvent.CategoryId = updatedEvent.CategoryId;
        sourceEvent.Note = updatedEvent.Note;
    }

    private UpdateEventAfterMove(event: IAgendaEvent): Promise<void> {
        const def = new Deferred<void>();
        event.start = moment(event.start).local();
        event.end = !event.end ? null : moment(event.end).local();

        const eventId = event.id.split("-").length > 1 ? parseInt(event.id.split("-")[1]) : parseInt(event.id);
        const categoryId = this.agenda.TimeslotsModeEnabled() ? event.CategoryId : null;
        this.eventsService.UpdateEventAfterMove(eventId, moment(event.start).toDate(), !event.end ? null : moment(event.end).toDate(), event.allDay, categoryId)
            .then(() => {
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private UpdateEventAfterDropOrResize(event: IAgendaEvent, revertFunc: () => void) {
        event.start = event.start.local();
        event.end = !event.end ? null : event.end.local();

        this.CanDropOrResizeEvent(event)
            .then((result: boolean) => {
                if (!result) {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CannotPlanDueToMissingRightsOrOutOfCompetenceEvent);
                    revertFunc();
                    return;
                }

                this.internalEventUpdateAfterDropOrResize(event, revertFunc);
            })
            .catch(() => {
                revertFunc();
            });
    }

    private internalEventUpdateAfterDropOrResize(event: IAgendaEvent, revertFunc: () => void): void {
        if (this.agenda.TimeslotsModeEnabled()) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.InvalidEventMove,
                ProlifeSdk.TextResources.Agenda.EventBookingAlertLabel,
                () => { revertFunc(); }
            );
            return;
        }

        if (!this.agenda.IsTimetableRespected(event.start, event.end, event.allDay, true)) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.EventOutOfTimetableAlertMessage,
                ProlifeSdk.TextResources.Agenda.EventBookingAlertLabel,
                () => {
                    revertFunc();
                }
            );
            return;
        }

        if (!this.agenda.IsMaxNumberOfEventsForTimeRespected(event.start, event.id)) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.MaxContemporaryEventsNumberExceeded,
                ProlifeSdk.TextResources.Agenda.EventBookingAlertLabel,
                () => {
                    revertFunc();
                }
            );
            return;
        }


        if (this.agenda.TimeslotsModeEnabled()) {
            this.LoadUserConfigurations()
                .then((preferredCategory: number) => {
                    if (preferredCategory)
                        event.CategoryId = preferredCategory;

                    this.UpdateEventAfterMove(event)
                        .then(() => {
                            this.RefreshEvents();
                        })
                        .catch(() => {
                            revertFunc();
                        });
                });
            return;
        }

        const eventStart: Moment = moment(event.start).local();
        const eventEnd: Moment = !event.end ? null : moment(event.end).local();
        const eventId: number = event.id.split("-").length > 1 ? parseInt(event.id.split("-")[1]) : parseInt(event.id);

        this.eventsService.VerifyEventsOverlap(eventId, event.AgendaId, eventStart.toDate(), !eventEnd ? null : eventEnd.toDate())
            .then((overlap: boolean) => {
                if (overlap) {
                    this.ConfirmEventMovingOrResizing()
                        .then((confirm: boolean) => {
                            if (confirm) {
                                this.UpdateEventAfterMove(event)
                                    .then(() => {
                                        this.RefreshEvents();
                                    })
                                    .catch(() => {
                                        revertFunc();
                                    });
                            } else {
                                revertFunc();
                            }
                        });

                    return;
                }

                this.UpdateEventAfterMove(event)
                    .then(() => {
                        this.RefreshEvents();
                    })
                    .catch(() => {
                        revertFunc();
                    });
            })
            .catch(() => {
                revertFunc();
            });
    }

    private ConfirmEventMovingOrResizing(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.ConfirmEventMovingOrResizingWithOverlap,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private LoadUserConfigurations(): Promise<number> {
        const def = new Deferred<number>();

        this.modulesService.getModuleId(ProlifeSdk.AgendaApplication)
            .then((moduleId: number) => {
                if (!moduleId)
                    return;

                this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.UserPredefinedCategoryConfigurationType, this.userInfo.getIdUser(), moduleId, null)
                    .then((config: IApplicationConfiguration[]) => {
                        if (!config || !config[0] || !config[0].Configuration)
                            def.resolve(null);

                        def.resolve(JSON.parse(config[0].Configuration));
                    })
                    .catch(() => {
                        def.reject();
                    });
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private GetCategoryColor(categoryId: number): string {
        const settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const categoriesSettings: ICategoriesSettingsManager = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);
        return categoriesSettings.getCategoryById(categoryId).Color;
    }

    private CanDropOrResizeEvent(event: IAgendaEvent): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.eventsService.IsEventRelatedToLoggedUser(this.ExtractEventId(event.id))
            .then((result: boolean) => {
                def.resolve(this.authorizationService.isAuthorized("Agenda_EditAllEvents")
                    || (this.authorizationService.isAuthorized("Agenda_EditRelatedEvents") && result)
                    || event.CreatedBy == (<any>this.authenticationService.getUser()).IdUser
                    || (this.authorizationService.isAuthorized("Agenda_ManageEventsFromBookingPortal") && event.IsFromBookingPortal));
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private CanMoveEvent(event: IAgendaEvent): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.CanDropOrResizeEvent(event)
            .then((result: boolean) => {
                def.resolve(this.authorizationService.isAuthorized("Agenda_PlanEvents")
                    && result);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private ExtractEventId(eventId: any): number {
        if (!eventId)
            return null;
        return typeof(eventId) == "number" ? eventId : parseInt(eventId.split("-")[1]);
    }
}