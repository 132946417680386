import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { SystemInput } from "../EventsDetails/SystemInput";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../Core/Deferred";

export class SystemBlogEvent extends BlogEvent implements IView
{
    public viewModel : any;

    public Module : ko.Observable<number> = ko.observable();
    public Hyperlink : ko.Observable<number> = ko.observable();

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconClass = "fa-cog";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.SystemBlogTitle;
        this.templateName = "systemEvent";
        this.Clear();
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            body += String.format(ProlifeSdk.TextResources.Blog.SystemBlogBody, this.Module());

            if(this.Hyperlink())
                body += String.format(ProlifeSdk.TextResources.Blog.SystemBlogBodyHyperlink, this.Hyperlink());

            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    getEditViewModel() : IEventBaseInput
    {
        return new SystemInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    Clear()
    {
        super.Clear();
        this.Module(null);
        this.Hyperlink(null);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {

            this.Module(item.TagName() != ProlifeSdk.TagSystem_Module ? this.Module() : parseFloat(item.Value()));
            this.Hyperlink(item.TagName() != ProlifeSdk.TagSystem_Hyperlink ? this.Hyperlink() : parseFloat(item.Value()));

        });
    }
}