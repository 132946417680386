/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2016
 * Time: 10:53
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { NavigationProvider } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { LogicalStatesWithCounterNavigationProvider } from "./providers/splash-page-menu-providers/LogicalStatesWithCounterNavigationProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import {
    IStateAndTypeMenuObserver,
    INavigationFilters,
    INavigationMultipleFilters,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class RootNavigationProvider extends NavigationProvider {
    constructor(serviceLocator: IServiceLocator) {
        super();
        this.Name = ProlifeSdk.TextResources.JobOrder.StatusP;
        this.AllowMultiLevelSearch = true;

        this.Items.push(
            new LogicalStatesWithCounterNavigationProvider(serviceLocator, 0, ProlifeSdk.TextResources.JobOrder.OpenP)
        );
        this.Items.push(
            new LogicalStatesWithCounterNavigationProvider(serviceLocator, 1, ProlifeSdk.TextResources.JobOrder.ClosedP)
        );
    }
}

export class StateWithCounterSelectionMenu extends NavigationMenu {
    public StopOnTypeLevel: boolean = true;
    public SelectedType: ko.Observable<number> = ko.observable(-1);

    private typeObservers: IStateAndTypeMenuObserver[] = [];

    constructor(serviceLocator: IServiceLocator) {
        super(serviceLocator, new RootNavigationProvider(serviceLocator));
        this.templateUrl = "jobOrder/Templates/navigation";
        this.templateName = "state-and-type-navigator";
        this.setDefaultMenuLocation();
    }

    private setDefaultMenuLocation() {
        this.MenuProvidersStack([this.RootMenuProvider, this.RootMenuProvider.Items()[0]]);
    }

    navigateTo(provider: INavigationMenuProvider) {
        super.navigateTo(provider);
        this.NotifyChangesOnFilters();
    }

    private NotifyChangesOnFilters() {
        this.typeObservers.forEach((o: IStateAndTypeMenuObserver) => {
            o.OnNavigationFilterIsChanged(this.GetFiltersFromActualPosition());
        });
    }

    public GetFiltersFromActualPosition(): INavigationFilters {
        const filters: INavigationFilters = {
            LogicalState: -1,
            Type: -1,
            State: -1,
        };

        this.MenuProvidersStack().forEach((p) => {
            if (p.SetFilterInfoOnModel) p.SetFilterInfoOnModel(filters);
        });

        this.selectedProviders.forEach((p) => {
            if (p.SetFilterInfoOnModel) p.SetFilterInfoOnModel(filters);
        });

        return filters;
    }

    public GetMultipleFiltersFromActualPosition(): INavigationMultipleFilters {
        const filters: INavigationMultipleFilters = {
            LogicalState: -1,
            Types: [],
            States: [],
        };

        this.MenuProvidersStack().forEach((p) => {
            if (p.SetMultipleFilterInfoOnModel) p.SetMultipleFilterInfoOnModel(filters);
        });

        this.selectedProviders.forEach((p) => {
            if (p.SetMultipleFilterInfoOnModel) p.SetMultipleFilterInfoOnModel(filters);
        });

        return filters;
    }

    GoBack() {
        super.GoBack();
        this.clearSelection(false);
        this.NotifyChangesOnFilters();
    }

    selectProvider(provider: INavigationMenuProvider, notifyToObservers: boolean = true) {
        super.selectProvider(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    removeProviderFromSelection(provider: INavigationMenuProvider, notifyToObservers: boolean = true) {
        super.removeProviderFromSelection(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    RegisterStateAndTypeObserver(o: IStateAndTypeMenuObserver) {
        this.typeObservers.push(o);
    }
}
