import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 13:31
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionnaireCategoryEditingViewModel } from "./ui/QuestionnaireCategoryEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IQuestionnaireCategorySettingManager } from "../../../../interfaces/IQuestionnaireCategorySettingManager";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";
import { ICategory } from "../../../../interfaces/ICategory";

export class QuestionnaireCategorySettingManager implements IQuestionnaireCategorySettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private categories: ICategory[];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }

    getQuestionnaireCategories(): ICategory[] {
        return this.categories;
    }

    getQuestionnaireCategoryById(id: number): ICategory {
        var categories: ICategory[] = this.categories.filter((t: ICategory) => { return t.Id == id });
        return categories[0];
    }

    load(): Promise<ICategory[]> {
        return this.ajaxService.Post<ICategory[]>("Survey-api/QuestionnaireApi", "GetQuestionnaireCategories", {})
            .then((categories: ICategory[]) => { this.categories = categories; return categories; });
    }

    getName(): string {
        return ProlifeSdk.QuestionnaireCategory;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.QuestionnaireCategory;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "questionnaire-category",
            templateUrl: "survey/templates/questionnaire/settings",
            viewModel: new QuestionnaireCategoryEditingViewModel(this.serviceLocator, this)
        };
    }

    addOrUpdate(category: ICategory): Promise<ICategory> {
        return this.ajaxService.Post<ICategory>("Survey-api/QuestionnaireApi", "AddOrUpdateQuestionnaireCategory", {
            methodData: {
                Category: category
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/QuestionnaireApi", "RemoveQuestionnaireCategory", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: ICategory): void {
        var found: boolean = false;

        this.categories.forEach((s: ICategory) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Description = state.Description;
            s.Name = state.Name;
            s.Icon = state.Icon;
            s.Background = state.Background;
            s.Foreground = state.Foreground;

            found = true;
        });

        if (!found)
            this.categories.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.categories.length; i++) {
            if (this.categories[i].Id == id) {
                this.categories.splice(i, 1);
                break;
            }
        }
    }
}