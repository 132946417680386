import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { NavigationMenuComponentGroup } from "./NavigationMenuComponent/NavigationMenuComponentGroup";
import { NavigationMenuComponentItem } from "./NavigationMenuComponent/NavigationMenuComponentItem";
import { NavigationMenuComponentActionSeparator } from "./NavigationMenuComponent/NavigationMenuComponentActionSeparator";
import { NavigationMenuComponentAction } from "./NavigationMenuComponent/NavigationMenuComponentAction";
import { NavigationMenuComponentActionsGroup } from "./NavigationMenuComponent/NavigationMenuComponentActionsGroup";
import { NavigationMenuComponentSearchActionItem } from "./NavigationMenuComponent/NavigationMenuComponentSearchActionItem";
import {
    INavigationMenuComponentModel,
    INavigationMenuComponent,
    INavigationMenuComponentItemFactory,
    INavigationMenuComponentActionFactory,
    INavigationMenuComponentItem,
    INavigationMenuComponentActionsItem,
    INavigationMenuComponentSearchActionItem,
    INavigationMenuComponentTemplatesProvider,
    INavigationMenuComponentDataSource,
    INavigationMenuComponentParameters,
    INavigationMenuComponentMultipleSelectionConfig,
    INavigationMenuComponentActionBase,
    INavigationMenuComponentSearchAction,
    INavigationMenuComponentActionSeparator,
    INavigationMenuComponentActionsGroup,
    INavigationMenuComponentAction,
} from "./NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceListener, IDataSourceModel } from "../DataSources/IDataSource";
import {
    isObservableArrayDataSource,
    ObservableArrayDataSource,
    synchronizeItems,
} from "../DataSources/ObservableArrayDataSource";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const csss = require("./NavigationMenuComponent/css/NavigationMenuComponent.scss");

interface INavigationMenuComponentConfig {
    textFilter: string;
    multipleSelection: boolean;
    selectLeafsOnly: boolean;
    selectionDisabled: boolean;
    allowNoSelection: boolean;
    navigationHistory: INavigationMenuComponentModel[];
    selectedItems: INavigationMenuComponentModel[];
    previousSelectedItems: INavigationMenuComponentModel[];
}

export class NavigationMenuComponent
    implements INavigationMenuComponent, INavigationMenuComponentItemFactory, INavigationMenuComponentActionFactory
{
    public Items: ko.ObservableArray<INavigationMenuComponentItem> = ko.observableArray();
    public MenuActions: ko.ObservableArray<INavigationMenuComponentActionsItem> = ko.observableArray([]);
    public SearchOptions: ko.ObservableArray<INavigationMenuComponentSearchActionItem> = ko.observableArray([]);

    public SearchPlaceholder: string = ProlifeSdk.TextResources.ProlifeSdk.SearchPlaceholder;
    public EmptyListPlaceholder: string = ProlifeSdk.TextResources.ProlifeSdk.MenuComponentEmptyResult;

    public TextFilter: ko.Observable<string> = ko.observable(); //.extend({ rateLimit: { timeout: 1000, method: 'notifyWhenChangesStop' } });
    public SearchAllowed: ko.Observable<boolean> = ko.observable(true);
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public IsLoading: ko.Observable<boolean> = ko.observable(false);
    public HasLoadedData: ko.Observable<boolean> = ko.observable(false);
    public IsGroupedDataSet: ko.Observable<boolean> = ko.observable(false);
    public ShowSecondaryAction: ko.Observable<boolean> = ko.observable(false);
    public ShowSelectAllChildren: ko.Observable<boolean> = ko.observable(false);
    public SelectLeafsOnly: ko.Observable<boolean> = ko.observable(true);
    public AdvancedSearchFilterAction: ko.Observable<INavigationMenuComponentActionBase> = ko.observable();

    public ValidMimeTypes: string[];

    public CanGoBack: ko.Computed<boolean>;
    public Title: ko.Computed<string>;
    public HasItems: ko.Computed<boolean>;
    public HasActions: ko.Computed<boolean>;
    public HasSearchOptions: ko.Computed<boolean>;

    public useWhiteTheme = false;
    public WrapperCssClasses = "";

    public TemplatesProvider: INavigationMenuComponentTemplatesProvider;

    private title: string;
    private multipleSelection = false;
    private selectionDisabled = false;
    private allowNoSelection = false;
    private pageSize = 30;
    private dataSource: INavigationMenuComponentDataSource;
    private listeners: IDataSourceListener[] = [];

    private navigationHistory: ko.ObservableArray<INavigationMenuComponentModel> = ko.observableArray([]);
    private currentItem: ko.Computed<INavigationMenuComponentModel>;

    private selectedItems: INavigationMenuComponentModel[] = [];
    private previousSelectedItems: INavigationMenuComponentModel[] = [];
    private refreshRequested = false;

    //State Stack
    private stateStack: INavigationMenuComponentConfig[] = [];
    private lockRefreshOnFilterChanges = false;
    private dataSourceSubscrption: ko.Subscription;

    TopOffset: ko.Computed<string>;

    constructor(private options: INavigationMenuComponentParameters) {
        this.dataSource = this.handleDataSource(options.DataSource);
        this.title = this.dataSource?.getTitle(null) ?? "";
        this.ShowLoadMore(!!this.dataSource);
        this.ValidMimeTypes = this.dataSource?.getSupportedDropMimeTypes() ?? [];
        this.listeners = !options.Listeners
            ? []
            : Array.isArray(options.Listeners)
            ? options.Listeners
            : [options.Listeners];
        this.SearchAllowed(!options.DisableSearch);
        this.selectionDisabled = options.DisableSelection || false;
        this.allowNoSelection = options.AllowNoSelection || false;

        this.useWhiteTheme = options.UseWhiteTheme || this.useWhiteTheme;
        this.WrapperCssClasses = options.WrapperCssClasses || "";

        this.TemplatesProvider = ko.unwrap(options.TemplatesProvider) || undefined;

        this.CanGoBack = ko.computed(() => this.navigationHistory().length != 0);
        this.HasActions = ko.computed(() => this.MenuActions().length > 0);
        this.HasSearchOptions = ko.computed(() => this.SearchOptions().filter((o) => o.Visible()).length > 0);

        this.currentItem = ko.computed(() => {
            const history = this.navigationHistory();
            const item = history[history.length - 1];
            return item || null;
        });

        this.Title = ko.computed(() => {
            let dataSource = null;
            if (ko.isSubscribable(options.DataSource)) dataSource = options.DataSource();
            else if (isObservableArrayDataSource(options.DataSource)) return "";
            else dataSource = options.DataSource;

            return dataSource?.getTitle(this.currentItem()) ?? "";
        });
        this.HasItems = ko.computed(() => this.Items().length > 0);

        if (options.InjectTo) options.InjectTo(this);

        let lastTimeout: ReturnType<typeof setTimeout> = null;
        this.TextFilter.subscribe(() => {
            if (this.lockRefreshOnFilterChanges) return;

            if (lastTimeout) clearTimeout(lastTimeout);

            lastTimeout = setTimeout(() => {
                this.refresh();
            }, 1000);
        });

        this.TopOffset = ko.computed(() => {
            let top = 60;
            if (this.HasSearchOptions() || this.SearchAllowed()) top += 40;
            if (this.MenuActions().filter((ma: any) => ma.visible()).length > 0) top += 32;
            return top + "px";
        });

        //La chiamata alla setView deve stare qui, altrimenti si potrebbe fare accesso alla classe prima della fine del costruttore
        if (this.dataSource) this.dataSource.setView(this);

        this.refresh();
    }

    private handleDataSource(
        dataSource: ko.MaybeSubscribable<INavigationMenuComponentDataSource> | ObservableArrayDataSource<unknown>
    ): INavigationMenuComponentDataSource {
        if (!ko.isSubscribable(dataSource)) {
            if (isObservableArrayDataSource(dataSource)) {
                synchronizeItems(
                    this.Items,
                    dataSource,
                    (i: IDataSourceModel) => new NavigationMenuComponentItem(dataSource.factory(i), this, undefined)
                );
                return undefined;
            }

            return dataSource;
        }

        this.dataSourceSubscrption = dataSource.subscribe((newDataSource) => {
            this.dataSource = newDataSource;
            this.dataSource.setView(this);
            this.deselectAll();
            this.reset();
        });

        return dataSource();
    }

    enableSelectAllChildren(): void {
        this.ShowSelectAllChildren(true);
    }

    reset() {
        this.navigationHistory([]);
        this.listeners.forEach((l) => {
            if (l.onNavigate) this.notifyHistoryChanges(l, ...this.navigationHistory());
        });
        this.refreshImmediate();
    }

    notifyModelCreated(parent: IDataSourceModel, model: IDataSourceModel): void {
        if (this.areEqual(parent, this.currentItem())) this.refresh(true);
    }

    notifyModelChanged(parent: IDataSourceModel, model: IDataSourceModel): void {
        if (this.areEqual(parent, this.currentItem())) {
            const existingModel = this.Items().firstOrDefault((i) => this.areEqual(i.model, model));
            if (!existingModel) return;

            const newItem = this.createViewModelFor(model);
            newItem.Selected(existingModel.Selected());
            this.Items.replace(existingModel, newItem);
        }
    }

    notifyModelDeleted(parent: IDataSourceModel, model: IDataSourceModel): void {
        if (this.dataSource?.areEqual(parent, this.currentItem())) {
            const existingModel = this.Items().firstOrDefault((i) => this.areEqual(i.model, model));
            if (!existingModel) return;

            this.Items.remove(existingModel);
        }
    }

    getActualSelection() {
        return (this.selectedItems ?? []).slice();
    }

    public async OnItemDropped(droppedOn: IDataSourceModel, dataTransfer: DataTransfer): Promise<void> {
        if (this.dataSource?.onItemMoved) return this.dataSource.onItemMoved(dataTransfer, droppedOn, null);
    }

    public OnItemDragged(draggedItem: IDataSourceModel, dataTransfer: DataTransfer) {
        if (this.dataSource?.onItemBeginMove) this.dataSource.onItemBeginMove(draggedItem, dataTransfer);
    }

    public pushState() {
        this.stateStack.push({
            textFilter: this.TextFilter(),
            multipleSelection: this.multipleSelection,
            selectLeafsOnly: this.SelectLeafsOnly(),
            selectionDisabled: this.selectionDisabled,
            allowNoSelection: this.allowNoSelection,
            navigationHistory: this.navigationHistory().slice(),
            selectedItems: this.selectedItems.slice(),
            previousSelectedItems: this.previousSelectedItems.slice(),
        });
    }

    public popState(notifyListeners = true) {
        if (this.stateStack.length > 0) {
            const oldState = this.stateStack.pop();

            this.multipleSelection = oldState.multipleSelection;
            this.SelectLeafsOnly(oldState.selectLeafsOnly);
            this.selectionDisabled = oldState.selectionDisabled;
            this.allowNoSelection = oldState.allowNoSelection;
            this.previousSelectedItems = oldState.previousSelectedItems;

            this.navigationHistory(oldState.navigationHistory);

            this.listeners.forEach((l) => {
                if (l.onNavigate) this.notifyHistoryChanges(l, ...this.navigationHistory());
            });

            this.TextFilter(oldState.textFilter);

            this.refresh();
            this.selectEx(notifyListeners, ...oldState.selectedItems);
        }
    }

    public showSecondaryAction(enabled: boolean) {
        this.ShowSecondaryAction(enabled);
    }

    public goBack(): void {
        if (this.TextFilter()) {
            this.TextFilter("");
            return;
        }

        this.navigationHistory.pop();

        this.listeners.forEach((l) => {
            if (l.onNavigate) this.notifyHistoryChanges(l, ...this.navigationHistory());
        });

        this.refresh();
    }

    public refresh(keepSelection = false): void {
        this.refreshImmediate(keepSelection);
    }

    public refreshImmediate(keepSelection = false): void {
        if (!this.dataSource) return;

        if (this.IsLoading()) {
            //console.log("Refresh requested");
            this.refreshRequested = true;
        } else {
            //console.log("Refreshing");
            this.Items([]);
            this.HasLoadedData(false);
            this.ShowLoadMore(true);

            if (keepSelection) this.internalSelect(false, this.selectedItems);
        }
    }

    /**
     * @returns true is there are more items to load, false otherwise
     */
    public async loadNextPage(): Promise<boolean> {
        if (this.IsLoading() || !this.dataSource) return false;

        //console.log("Loading Next Page");

        this.IsLoading(true);
        this.HasLoadedData(true);
        this.ShowLoadMore(false);

        //console.log("Loading started");

        const currentModel = this.currentItem();

        //console.log("Got currentItem: ", currentModel);

        const isGroupedDataset = this.dataSource?.isGroupedData(currentModel, this.TextFilter()) ?? false;

        //console.log("Got isGrouped: ", isGroupedDataset);

        this.IsGroupedDataSet(isGroupedDataset);

        //console.log("Getting data from dataSource...");

        try {
            const models =
                (await this.dataSource?.getData(
                    currentModel,
                    this.TextFilter(),
                    this.Items().length,
                    isGroupedDataset ? 10000000 : this.pageSize
                )) ?? [];
            if (this.refreshRequested) {
                //console.log("Aborting Load because Refresh was Requested");
                this.refreshRequested = false;
                this.IsLoading(false);
                this.refresh();

                //console.log("Exiting");
                return false;
            }

            if (models.length == 0) {
                //console.log("No data found.");
                this.IsLoading(false);
                this.HasLoadedData(true);
                this.ShowLoadMore(false);

                //console.log("Exiting");
                return false;
            }

            //console.log("Creating viewModels...");
            const viewModels = models.map(this.createViewModelFor, this);
            //console.log("ViewModels: ", viewModels);
            this.Items(this.Items().concat(viewModels));

            //console.log("ViewModels added to list");

            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(true);

            //console.log("Exiting");
            return true;
        } catch (e) {
            //console.log("Unhandled exception in LoadNextPage: ", e);

            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(false);

            //console.log("Exiting");
            return false;
        }
    }

    public isMultipleSelection(): boolean {
        return this.multipleSelection;
    }

    public setMultipleSelection(options: INavigationMenuComponentMultipleSelectionConfig): void {
        const wasMultipleSelection = this.multipleSelection;

        if (options.multipleSelection && !wasMultipleSelection) {
            this.pushState();

            this.multipleSelection = options.multipleSelection;
            this.SelectLeafsOnly(options.selectLeafsOnly);

            if (!options.keepSelection) this.clearItemsSelection();
            return;
        }

        this.multipleSelection = options.multipleSelection;
        this.SelectLeafsOnly(options.selectLeafsOnly);

        const notifyListeners = wasMultipleSelection && !this.multipleSelection;
        if (wasMultipleSelection && !this.multipleSelection) this.popState(notifyListeners);
    }

    public setSelectionDisabled(value: boolean): void {
        this.selectionDisabled = value;
    }

    public setSearchAllowed(value: boolean): void {
        this.SearchAllowed(value);
    }

    public setPageSize(size: number): void {
        this.pageSize = size;
    }

    public getTextFilter(): string {
        return this.TextFilter();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public navigateTo(...history: INavigationMenuComponentModel[]): void {
        if (this.isSameNavigationPath(history)) return;

        this.navigationHistory(history.slice());

        this.listeners.forEach((l) => {
            if (l.onNavigate) this.notifyHistoryChanges(l, ...this.navigationHistory());
        });

        this.refresh();
    }

    public select(...models: INavigationMenuComponentModel[]): Promise<void> {
        return this.selectEx(true, ...models);
    }

    public selectEx(notifyListeners: boolean, ...models: INavigationMenuComponentModel[]): Promise<void> {
        return this.dataSource
            .getById(
                this.currentItem(),
                models.map((m) => m.id)
            )
            .then((newModels) => {
                this.internalSelect(notifyListeners, newModels);
            });
    }

    public registerAction(action: INavigationMenuComponentActionBase): INavigationMenuComponent {
        if (
            this.MenuActions()
                .map((a) => a.actionInfo)
                .indexOf(action) < 0
        )
            this.MenuActions.push(this.createActionViewModel(action));

        return this;
    }

    public unregisterAction(action: INavigationMenuComponentActionBase): INavigationMenuComponent {
        const index = this.MenuActions()
            .map((a) => a.actionInfo)
            .indexOf(action);

        if (index >= 0) this.MenuActions.splice(index, 1);

        return this;
    }

    public clearActions(): INavigationMenuComponent {
        this.MenuActions([]);
        return this;
    }

    public registerSearchAction(action: INavigationMenuComponentSearchAction): INavigationMenuComponent {
        if (
            this.SearchOptions()
                .map((o) => o.actionInfo)
                .indexOf(action) < 0
        )
            this.SearchOptions.push(this.createSearchActionViewModel(action));

        return this;
    }

    public unregisterSearchAction(action: INavigationMenuComponentSearchAction): INavigationMenuComponent {
        const index = this.SearchOptions()
            .map((o) => o.actionInfo)
            .indexOf(action);

        if (index >= 0) this.SearchOptions.splice(index, 1);

        return this;
    }

    public clearSearchActions(option: INavigationMenuComponentSearchAction): INavigationMenuComponent {
        this.SearchOptions([]);
        return this;
    }

    public setAdvancedFilterAction(action: INavigationMenuComponentActionBase): INavigationMenuComponent {
        this.AdvancedSearchFilterAction(this.createActionViewModel(action));
        return this;
    }

    public onItemSelected(model: INavigationMenuComponentModel, bypassSelectLeafsOnlyCheck = false): void {
        if ((bypassSelectLeafsOnlyCheck || this.SelectLeafsOnly()) && !model.isLeaf) {
            this.navigationHistory.push(model);

            this.listeners.forEach((l) => {
                if (l.onNavigate) this.notifyHistoryChanges(l, ...this.navigationHistory());
            });

            this.lockRefreshOnFilterChanges = true; // Blocca il refresh al cambio del text filter. Viene resettata dalla subscribe sulla text filter
            this.TextFilter(null);
            this.lockRefreshOnFilterChanges = false;

            this.refresh(true);
        } else {
            const calls: Promise<boolean>[] = [];

            this.listeners.forEach((l) => {
                if (!l.canSelectItem) {
                    calls.push(new Promise<boolean>((resolve, reject) => resolve(true)));
                    return;
                }

                calls.push(l.canSelectItem(this.dataSource, model));
            });

            Promise.all(calls).then((values: boolean[]) => {
                if (values.filter((v) => !v).length > 0) return;

                if (this.selectionDisabled) {
                    this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));

                    if (this.options.onSelect) this.options.onSelect(model);

                    return;
                }

                if (this.multipleSelection) {
                    const item = this.getItemFromModel(model);
                    if (item) item.Selected(true);
                    this.selectedItems.push(model);
                    this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));

                    if (this.options.onSelect) this.options.onSelect(model);

                    return;
                }

                this.deselectAll();
                for (const selectedItem of this.selectedItems) {
                    this.listeners.forEach((l) => this.notifyOnItemDeselected(l, selectedItem));

                    if (this.options.onDeselect) this.options.onDeselect(selectedItem);
                }

                const oldSelection = this.selectedItems[0];
                this.selectedItems = [];

                if (this.areEqual(oldSelection, model) && this.allowNoSelection) return;

                this.selectedItems.push(model);
                this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));

                if (this.options.onSelect) this.options.onSelect(model);

                const item = this.getItemFromModel(model);
                if (item) item.Selected(true);
            });
        }
    }

    public hasCustomTemplateForModel(model: INavigationMenuComponentModel): boolean {
        if (!this.TemplatesProvider) return false;

        return this.TemplatesProvider.hasTemplateFor(this.dataSource, model);
    }

    public getCustomTemplateForModel(model: INavigationMenuComponentModel): Node[] {
        if (!this.TemplatesProvider) return null;

        return [this.TemplatesProvider.templatesProvider(this.dataSource, model)];
    }

    private getItemFromModel(model: INavigationMenuComponentModel): INavigationMenuComponentItem {
        return this.findItem(this.Items(), model);
    }

    private findItem(
        items: INavigationMenuComponentItem[],
        model: INavigationMenuComponentModel
    ): INavigationMenuComponentItem {
        for (const item of items) {
            if (item instanceof NavigationMenuComponentGroup) {
                const found = this.findItem(item.Items(), model);
                if (found) return found;
            }

            if (this.areEqual(item.model, model)) return item;
        }

        return null;
    }

    private areEqual(a: INavigationMenuComponentModel, b: INavigationMenuComponentModel): boolean {
        if (!this.dataSource)
            return a == b || (!!a && !!b && a.id === b.id && a.isLeaf === b.isLeaf && a.isGroup === b.isGroup);

        return this.dataSource.areEqual(a, b);
    }

    private deselectAll() {
        this.deselectAllItems(this.Items());
    }

    private deselectAllItems(items: INavigationMenuComponentItem[]) {
        for (const item of items) {
            if (item.isGroup && item instanceof NavigationMenuComponentGroup) {
                this.deselectAllItems(item.Items());
            }

            item.Selected(false);
        }
    }

    public onItemDeselected(model: INavigationMenuComponentModel): void {
        this.listeners.forEach((l) => this.notifyOnItemDeselected(l, model));

        if (this.options.onDeselect) this.options.onDeselect(model);

        const item = this.getItemFromModel(model);
        item?.Selected(false);

        if (!this.SelectLeafsOnly()) this.deselectSelectedChildren(model);

        if (this.multipleSelection) {
            this.removeItemFromSelectedItemsList(model);
            return;
        }

        this.selectedItems = [];
    }

    private deselectSelectedChildren(model: INavigationMenuComponentModel) {
        const actualSelection = this.selectedItems.slice();
        const selectedChildren = actualSelection.filter(
            (m) => !!(m.parents ?? []).firstOrDefault((p) => this.areEqual(p, model))
        );
        for (let i = 0; i < selectedChildren.length; i++) {
            const children = selectedChildren[i];
            const item = this.getItemFromModel(children);
            if (item && item.Selected()) this.onItemDeselected(item.model);
            else this.removeItemFromSelectedItemsList(children);
        }
    }

    public selectSearchOption(option: INavigationMenuComponentSearchActionItem): void {
        this.SearchOptions().forEach((o) => o.Selected(false));
        option.Selected(true);
    }

    public createViewModelFor(model: INavigationMenuComponentModel): INavigationMenuComponentItem {
        const viewModel = model.isGroup
            ? new NavigationMenuComponentGroup(model, this, this, this.dataSource)
            : new NavigationMenuComponentItem(model, this, this.dataSource);

        // Seleziono l'item se il suo model è nella lista dei selezionati
        // e, nel caso in cui sia abilitata la selezione ache di elementi non foglia,
        // se l'elemento non è una foglia e può essere selezionato
        const itemIsSelected = this.selectedItems.filter((s) => this.areEqual(s, model));
        const selectedChildren = this.selectedItems.filter(
            (s) => !!(s.parents ?? []).firstOrDefault((p) => this.areEqual(p, model)) || this.areEqual(s.parent, model)
        );
        if (
            itemIsSelected.length > 0 ||
            (selectedChildren.length > 0 && model.canSelectEvenIfNotLeaf && !this.SelectLeafsOnly() && !model.isLeaf)
        ) {
            viewModel.Selected(true);
        }

        // Evidenzio l'item se ha elementi figli selezionati e se l'impostazione di selezione dei figli è abilitata
        // e, nel caso in cui sia abilitata la selezione ache di elementi non foglia,
        // se l'elemento non è una foglia e non può essere selezionato
        if (
            selectedChildren.length > 0 &&
            !model.isLeaf &&
            this.ShowSelectAllChildren() &&
            model.showSelectAllChildren
        ) {
            viewModel.ChildrenSelected(true);
        }

        return viewModel;
    }

    public createActionViewModel(action: INavigationMenuComponentActionBase): INavigationMenuComponentActionsItem {
        if (action.isSeparator)
            return new NavigationMenuComponentActionSeparator(action as INavigationMenuComponentActionSeparator);

        if (action.isGroup)
            return new NavigationMenuComponentActionsGroup(action as INavigationMenuComponentActionsGroup, this);

        return new NavigationMenuComponentAction(action as INavigationMenuComponentAction, this);
    }

    public dispose(): void {
        if (this.dataSourceSubscrption) this.dataSourceSubscrption.dispose();
    }

    private internalSelect(notifyListeners: boolean, models: INavigationMenuComponentModel[]): Promise<void> {
        if (!this.multipleSelection && models.length > 1) {
            throw new Error("Cannot select multiple items in a single selection navigation menu");
        }

        if (notifyListeners) {
            for (const item of this.selectedItems) {
                this.listeners.forEach((l) => this.notifyOnItemDeselected(l, item));
            }
        }

        this.selectedItems = models;

        if (notifyListeners) {
            for (const item of this.selectedItems) {
                this.listeners.forEach((l) => this.notifyOnItemSelected(l, item));
            }
        }

        if (this.HasLoadedData() && !this.ShowLoadMore()) {
            //Selezionare ORA gli item
            this.selectMultipleItems(models, 0, false);
            return Promise.resolve();
        }

        if (this.selectedItems.length === 0) {
            this.clearItemsSelection();
        }
        /*
        //Forzo il caricamento dei dati ORA, e poi faccio la selezione
        let previousShowLoadMore = this.ShowLoadMore();
        this.ShowLoadMore(false); //Disabilitiamo il caricamento automatico

        return this.internalSelectMultipleItems(models, previousShowLoadMore, notifyListeners);*/

        return Promise.resolve();
    }

    private isSameNavigationPath(history: INavigationMenuComponentModel[]): boolean {
        if (history.length != this.navigationHistory().length) return false;

        const actualHistory = this.navigationHistory();
        let samePath = true;

        for (let i = 0; i < history.length; i++) {
            if (actualHistory[i] == undefined) {
                samePath = false;
                break;
            }

            if (!this.areEqual(history[i], actualHistory[i])) {
                samePath = false;
                break;
            }
        }

        return samePath;
    }

    private clearItemsSelection() {
        this.Items().forEach((i) => i.Selected(false));
    }

    private async internalSelectMultipleItems(
        models: INavigationMenuComponentModel[],
        previousShowLoadMore: boolean,
        notifyListeners = true
    ): Promise<void> {
        let result = false;
        let previousItemsCount = 0;
        models = this.selectMultipleItems(models, previousItemsCount, notifyListeners);

        if (models.length == 0) {
            this.ShowLoadMore(previousShowLoadMore);
            return Promise.resolve();
        }

        do {
            result = await this.loadNextPage();
            models = this.selectMultipleItems(models, previousItemsCount, notifyListeners);
            previousItemsCount = this.Items().length;

            if (models.length == 0) break;
        } while (result);

        this.ShowLoadMore(result);
    }

    /** @returns un array contentente i models per i quali non è stato trovato un items corrispondente e quindi che non sono stati selezionati */
    private selectMultipleItems(
        models: INavigationMenuComponentModel[],
        startFrom = 0,
        notifyListeners = true
    ): INavigationMenuComponentModel[] {
        const items = this.Items().slice(startFrom);

        for (const item of items) {
            const wasSelected = item.Selected();
            item.Selected(false);

            if (wasSelected && notifyListeners)
                this.listeners.forEach((l) => this.notifyOnItemDeselected(l, item.model));
        }

        const notSelectedModels: INavigationMenuComponentModel[] = [];

        for (const model of models) {
            let found = false;
            items.forEach((i) => {
                if (i.isGroup && i instanceof NavigationMenuComponentGroup) {
                    const itemsToSelect = i.Items().filter((m) => this.areEqual(model, m.model));
                    itemsToSelect.forEach((i) => {
                        i.Selected(true);
                        if (notifyListeners) this.onItemSelected(i.model);
                        found = true;
                    });
                } else {
                    if (!this.areEqual(model, i.model)) return;

                    i.Selected(true);

                    if (notifyListeners) this.onItemSelected(i.model);

                    found = true;
                }
            });

            if (!found) notSelectedModels.push(model);
        }

        return notSelectedModels;
    }

    private createSearchActionViewModel(
        action: INavigationMenuComponentSearchAction
    ): INavigationMenuComponentSearchActionItem {
        return new NavigationMenuComponentSearchActionItem(action, this);
    }

    private notifyHistoryChanges(listener: IDataSourceListener, ...history: INavigationMenuComponentModel[]): void {
        try {
            listener.onNavigate(this.dataSource, ...history);
        } catch (e) {
            (console.error || console.log).call(console, e.stack || e);
        }
    }

    private notifyOnItemSelected(listener: IDataSourceListener, model: INavigationMenuComponentModel) {
        try {
            listener.onItemSelected(this.dataSource, model);
        } catch (e) {
            (console.error || console.log).call(console, e.stack || e);
        }
    }

    private notifyOnItemDeselected(listener: IDataSourceListener, model: INavigationMenuComponentModel) {
        try {
            listener.onItemDeselected(this.dataSource, model);
        } catch (e) {
            (console.error || console.log).call(console, e.stack || e);
        }
    }

    private removeItemFromSelectedItemsList(model: INavigationMenuComponentModel) {
        let itemIndex = -1;
        this.selectedItems.forEach((i, index) => {
            if (!this.areEqual(i.model, model)) return;

            itemIndex = index;
        });

        if (itemIndex < 0) return;

        this.selectedItems.splice(itemIndex, 1);
    }
}

ko.components.register("navigation-menu", {
    viewModel: {
        createViewModel: (params: INavigationMenuComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            const vm = new NavigationMenuComponent(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="page-quick-sidebar-wrapper" data-bind="css: WrapperCssClasses">
                    <div class="page-quick-sidebar">
                        <div class="page-quick-sidebar-chat">
                            <div
                                class="page-quick-sidebar-chat-users navigator prolife-nav-menu"
                                data-bind="css: { 'white-style': useWhiteTheme }">
                                <header class="navigator-header">
                                    <div className="actions">
                                        <ko-foreach data-bind="MenuActions">
                                            <ko-if data-bind="isGroup && visible()">
                                                <div class="btn-group" data-bind="attr: { title: title }">
                                                    <button
                                                        class="btn btn-primary dropdown-toggle"
                                                        data-toggle="dropdown">
                                                        <i data-bind="css: icon"></i>
                                                    </button>
                                                    <ul class="dropdown-menu pull-right">
                                                        <ko-foreach data-bind="actions">
                                                            <ko-if data-bind="isSeparator">
                                                                <li style="border-bottom: 1px solid;"></li>
                                                            </ko-if>
                                                            <ko-if data-bind="!isSeparator && visible()">
                                                                <li data-bind="css: { 'selected': active, disabled: !canExecute() }">
                                                                    <a
                                                                        href="#"
                                                                        data-bind="click : click, attr: { disabled: !canExecute() }">
                                                                        <i data-bind="css : icon"></i>
                                                                        <ko-text data-bind="text"></ko-text>
                                                                    </a>
                                                                </li>
                                                            </ko-if>
                                                        </ko-foreach>
                                                    </ul>
                                                </div>
                                            </ko-if>
                                            <ko-if data-bind="!isGroup && visible()">
                                                <button
                                                    class="btn"
                                                    data-bind="click: click, css: (active() ? activeClass() : defaultClass()), attr: { title: title }">
                                                    <i data-bind="css: icon"></i>
                                                    <ko-text data-bind="text"></ko-text>
                                                </button>
                                            </ko-if>
                                        </ko-foreach>
                                    </div>
                                    <div className="flex-container">
                                        <button
                                            class="btn btn-circle btn-default pull-left"
                                            data-bind="visible: CanGoBack, click: goBack">
                                            <i class="fa fa-arrow-left"></i>
                                        </button>
                                        <h3
                                            class="list-heading pull-left"
                                            data-bind="text: Title, style: { 'max-width': '202px', 'min-width': MenuActions().length == 1 ? '161px' : 'inherit' }"></h3>
                                    </div>
                                </header>

                                <div
                                    class="sidebar-search-wrapper navigator-search-wrapper"
                                    data-bind="visible: SearchAllowed">
                                    <form
                                        class="sidebar-search flex-container"
                                        onSubmit={() => false}
                                        style="margin: 10px 5px 10px 10px; align-items: center">
                                        <a class="remove" href="javascript:;"></a>
                                        <div class="input-group flex-fill">
                                            <input
                                                class="form-control"
                                                type="text"
                                                data-bind="value: TextFilter, valueUpdate: 'afterkeydown', attr: { placeholder: SearchPlaceholder }"
                                            />
                                            <span
                                                class="input-group-btn"
                                                style={{ width: "22px", textAlign: "center" }}>
                                                <a class="btn submit">
                                                    <i class="icon-magnifier"></i>
                                                </a>
                                            </span>
                                        </div>
                                        <ko-if data-bind="HasSearchOptions">
                                            <div class="btn-group">
                                                <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                    <i class="fa fa-angle-down"></i>
                                                </button>
                                                <ul class="dropdown-menu pull-right">
                                                    <ko-foreach data-bind="SearchOptions">
                                                        <li>
                                                            <a href="#" data-bind="click : click">
                                                                <i
                                                                    class="fa"
                                                                    style="width : 20px"
                                                                    data-bind="css : { 'fa-check' : Selected }"></i>
                                                                <span data-bind="text : actionInfo.text"></span>
                                                            </a>
                                                        </li>
                                                    </ko-foreach>
                                                </ul>
                                            </div>
                                        </ko-if>
                                        <ko-bind data-bind="with: AdvancedSearchFilterAction, as: 'advancedSearchFilter'">
                                            <button
                                                className="btn"
                                                data-bind="click: advancedSearchFilter.click.bind(advancedSearchFilter), css: (advancedSearchFilter.active() ? advancedSearchFilter.activeClass() : advancedSearchFilter.defaultClass()), attr: { title: advancedSearchFilter.actionInfo.title }, enable: advancedSearchFilter.canExecute">
                                                <i data-bind="css: advancedSearchFilter.icon"></i>
                                                <ko-text data-bind="text"></ko-text>
                                            </button>
                                        </ko-bind>
                                    </form>
                                </div>

                                <ko-ifnot data-bind="useWhiteTheme">
                                    <div
                                        class="navigator-scrollable-container"
                                        style="top: 92px"
                                        data-bind="style: { top: TopOffset }, lightSlimScroll">
                                        <h4
                                            class="list-heading"
                                            data-bind="visible: !HasItems() && !IsLoading() && HasLoadedData(), text: EmptyListPlaceholder"></h4>
                                        <ko-if data-bind="IsGroupedDataSet">
                                            <ko-foreach data-bind="Items">
                                                <div>
                                                    <h3
                                                        class="list-heading prolife"
                                                        data-bind="click: click, tooltip: { placement: 'bottom' }, attr: { 'data-original-title': model.title }">
                                                        <span data-bind="text : model.title"></span>
                                                        <button
                                                            class="btn btn-transparent btn-sm pull-left"
                                                            style="margin-top: -6px; color: white; width: 32px">
                                                            <i
                                                                class="fa fa-angle-down"
                                                                data-bind="visible: !Collapsed()"></i>
                                                            <i
                                                                class="fa fa-angle-right"
                                                                data-bind="visible: Collapsed"></i>
                                                        </button>
                                                    </h3>
                                                    <h4
                                                        class="list-heading"
                                                        data-bind="visible: !HasItems() && !IsLoading() && HasLoadedData(), text: $parent.EmptyListPlaceholder"></h4>
                                                    <ul class="feeds list-items" data-bind="if : !Collapsed()">
                                                        <ko-foreach data-bind="Items">
                                                            <li data-bind="click: click, css : { 'selected': Selected, 'has-selected-children': ChildrenSelected }, droppableEx : { allowedMimeTypes : $component.ValidMimeTypes, hoverClass : 'droppable-hover', onDrop : $component.OnItemDropped.bind($component, $data.model) }, draggableEx: { CanDrag: $data.model.dragEnabled, OnDrag: $component.OnItemDragged.bind($component, $data.model) }, with: model">
                                                                <ko-if data-bind="$component.hasCustomTemplateForModel($data)">
                                                                    <ko-template data-bind="{ nodes: $component.getCustomTemplateForModel($data) }"></ko-template>
                                                                </ko-if>
                                                                <ko-ifnot data-bind="$component.hasCustomTemplateForModel($data)">
                                                                    <ko-if data-bind="$data.badge">
                                                                        <ko-foreach data-bind="badge">
                                                                            <ko-if data-bind="$data.action">
                                                                                <div class="date">
                                                                                    <button
                                                                                        class="badge pull-right"
                                                                                        style="border: none;"
                                                                                        data-bind="text: $data.text, css : $data.cssClass, asyncClick: $data.action.bind($data), attr: { title: $data.title }"></button>
                                                                                </div>
                                                                            </ko-if>
                                                                            <ko-ifnot data-bind="$data.action">
                                                                                <div class="date">
                                                                                    <span
                                                                                        class="badge pull-right"
                                                                                        data-bind="text: $data.text, css : $data.cssClass, attr: { title: $data.title }"></span>
                                                                                </div>
                                                                            </ko-ifnot>
                                                                        </ko-foreach>
                                                                    </ko-if>
                                                                    <div class="col1">
                                                                        <div class="cont">
                                                                            <div class="cont-col1">
                                                                                <ko-if data-bind="$data.icon">
                                                                                    <div
                                                                                        class="btn btn-primary btn-lg"
                                                                                        data-bind="style: { background: icon.background, color: icon.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                                                                        <i data-bind="css: icon.icon"></i>
                                                                                    </div>
                                                                                </ko-if>
                                                                                <div style="position: absolute">
                                                                                    <ko-if data-bind="$data.iconsList && !$component.ShowSecondaryAction()">
                                                                                        <ko-foreach data-bind="$data.iconsList">
                                                                                            <div
                                                                                                class="btn btn-primary btn-lg"
                                                                                                data-bind="style: { background: $data.background, color: $data.foreground }"
                                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: block; margin-top: 5px">
                                                                                                <i data-bind="css: $data.icon"></i>
                                                                                            </div>
                                                                                        </ko-foreach>
                                                                                    </ko-if>
                                                                                    <ko-bind data-bind="if: $component.ShowSelectAllChildren() && $data.showSelectAllChildren && !$component.ShowSecondaryAction()">
                                                                                        <br />
                                                                                        <a
                                                                                            class="workflow-item-with-task-checkbox"
                                                                                            data-bind="click: $parent.manageAllChildrenSelection.bind($parent), clickBubble: false">
                                                                                            <i
                                                                                                class="fa fa-square-o fa-lg"
                                                                                                data-bind="visible: !$parent.AllChildrenSelected() && !$parent.ChildrenSelected()"></i>
                                                                                            <i
                                                                                                class="fa fa-square fa-lg"
                                                                                                data-bind="visible: !$parent.AllChildrenSelected() && $parent.ChildrenSelected()"></i>
                                                                                            <i
                                                                                                class="fa fa-check-square fa-lg"
                                                                                                data-bind="visible: $parent.AllChildrenSelected"></i>
                                                                                        </a>
                                                                                    </ko-bind>
                                                                                    <br />

                                                                                    <ko-if data-bind="$data.secondaryAction && $component.ShowSecondaryAction()">
                                                                                        <ko-with data-bind="$data.secondaryAction">
                                                                                            <div
                                                                                                class="btn btn-warning btn-lg"
                                                                                                data-bind="click: action, clickBubble: false, with: icon, style: { background: icon.background, color: icon.foreground }"
                                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; margin-top: 5px; position: absolute">
                                                                                                <i class="fa fa-pencil"></i>
                                                                                            </div>
                                                                                        </ko-with>
                                                                                    </ko-if>
                                                                                </div>
                                                                            </div>
                                                                            <div class="cont-col2">
                                                                                <div
                                                                                    class="desc"
                                                                                    data-bind="css: { 'without-icon': !$data.icon }">
                                                                                    <div data-bind="text: title"></div>
                                                                                    <ko-if data-bind="$data.subTitle">
                                                                                        <div
                                                                                            class="label label-sm menu-item-subtitle"
                                                                                            data-bind="visible: subTitle">
                                                                                            <span data-bind="html: subTitle"></span>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.progressBar">
                                                                                        <div
                                                                                            class="progress"
                                                                                            style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block"
                                                                                            data-bind="with: progressBar">
                                                                                            <div
                                                                                                class="progress-bar progress-bar-success"
                                                                                                style="min-width: 0"
                                                                                                role="progressbar"
                                                                                                aria-valuemin={0}
                                                                                                aria-valuemax={100}
                                                                                                data-bind="attr : { 'aria-valuenow' : progress }, style : { width : progress + '%' }"></div>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.secondaryIcon">
                                                                                        <div
                                                                                            class="btn btn-primary btn-lg"
                                                                                            data-bind="style: { background: secondaryIcon.background, color: secondaryIcon.foreground }"
                                                                                            style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                                                                            <i data-bind="css: secondaryIcon.icon"></i>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.alerts">
                                                                                        <div data-bind="with: alerts">
                                                                                            <span
                                                                                                class="pull-left"
                                                                                                style="margin-right: 5px; line-height: 22px"
                                                                                                data-bind="text: label"></span>
                                                                                            <ko-foreach data-bind="icons">
                                                                                                <div
                                                                                                    class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                                                                    style="margin-right: 5px"
                                                                                                    data-bind="attr: { title: tooltip }, tooltip: {}, with: icon">
                                                                                                    <i
                                                                                                        class=""
                                                                                                        data-bind="css: icon, style: { color: foreground }"></i>
                                                                                                </div>
                                                                                            </ko-foreach>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                </div>
                                                                                <ko-if data-bind="$data.details">
                                                                                    <div
                                                                                        class="desc"
                                                                                        style="margin: 0px; padding: 0px; max-width: 100%"
                                                                                        data-bind="component: { name: details.componentName, params: details.model }"></div>
                                                                                </ko-if>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ko-ifnot>
                                                            </li>
                                                        </ko-foreach>
                                                        <ko-if data-bind="ShowLoadMore">
                                                            <li
                                                                class="load-more-elements"
                                                                data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></li>
                                                        </ko-if>
                                                        <li class="text-center" data-bind="visible: IsLoading">
                                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </ko-foreach>
                                            <ko-if data-bind="ShowLoadMore">
                                                <div
                                                    class="load-more-elements"
                                                    data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></div>
                                            </ko-if>
                                            <div class="text-center" data-bind="visible: IsLoading">
                                                <i class="fa fa-circle-o-notch fa-spin"></i>
                                            </div>
                                        </ko-if>
                                        <ko-ifnot data-bind="IsGroupedDataSet">
                                            <ul class="feeds list-items">
                                                <ko-foreach data-bind="Items">
                                                    <li data-bind="click: click, css : { 'selected': Selected, 'has-selected-children': ChildrenSelected }, droppableEx : { allowedMimeType : $component.ValidMimeTypes, hoverClass : 'droppable-hover', onDrop : $component.OnItemDropped.bind($component, $data.model) }, draggableEx: { CanDrag: $data.model.dragEnabled, OnDrag: $component.OnItemDragged.bind($component, $data.model) }, with: model">
                                                        <ko-if data-bind="$component.hasCustomTemplateForModel($data)">
                                                            <ko-template data-bind="{ nodes: $component.getCustomTemplateForModel($data) }"></ko-template>
                                                        </ko-if>
                                                        <ko-ifnot data-bind="$component.hasCustomTemplateForModel($data)">
                                                            <ko-if data-bind="$data.badge">
                                                                <ko-foreach data-bind="$data.badge">
                                                                    <ko-if data-bind="$data.action">
                                                                        <div
                                                                            class="date"
                                                                            style="position: absolute; right: 8px;">
                                                                            <button
                                                                                class="badge pull-right"
                                                                                style="border: none;"
                                                                                data-bind="text: $data.text, css : $data.cssClass, asyncClick: $data.action.bind($data), attr: { title: $data.title }"></button>
                                                                        </div>
                                                                    </ko-if>
                                                                    <ko-ifnot data-bind="$data.action">
                                                                        <div
                                                                            class="date"
                                                                            style="position: absolute; right: 8px;">
                                                                            <span
                                                                                class="badge pull-right"
                                                                                data-bind="text: $data.text, css : $data.cssClass, attr: { title: $data.title }"></span>
                                                                        </div>
                                                                    </ko-ifnot>
                                                                </ko-foreach>
                                                            </ko-if>
                                                            <div class="col1">
                                                                <div class="cont">
                                                                    <div class="cont-col1">
                                                                        <ko-if data-bind="$data.icon">
                                                                            <div
                                                                                class="btn btn-primary btn-lg"
                                                                                data-bind="style: { background: icon.background, color: icon.foreground }"
                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                                                                <i data-bind="css: icon.icon"></i>
                                                                            </div>
                                                                        </ko-if>
                                                                        <div style="position: absolute">
                                                                            <ko-if data-bind="$data.iconsList && !$component.ShowSecondaryAction()">
                                                                                <ko-foreach data-bind="$data.iconsList">
                                                                                    <div
                                                                                        class="btn btn-primary btn-lg"
                                                                                        data-bind="style: { background: $data.background, color: $data.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: block; margin-top: 5px">
                                                                                        <i data-bind="css: $data.icon"></i>
                                                                                    </div>
                                                                                </ko-foreach>
                                                                            </ko-if>
                                                                            <ko-bind data-bind="if: $component.ShowSelectAllChildren() && $data.showSelectAllChildren && !$component.ShowSecondaryAction()">
                                                                                <br />
                                                                                <a
                                                                                    class="workflow-item-with-task-checkbox"
                                                                                    data-bind="click: $parent.manageAllChildrenSelection.bind($parent), clickBubble: false">
                                                                                    <i
                                                                                        class="fa fa-square-o fa-lg"
                                                                                        data-bind="visible: !$parent.AllChildrenSelected() && !$parent.ChildrenSelected()"></i>
                                                                                    <i
                                                                                        class="fa fa-square fa-lg"
                                                                                        data-bind="visible: !$parent.AllChildrenSelected() && $parent.ChildrenSelected()"></i>
                                                                                    <i
                                                                                        class="fa fa-check-square fa-lg"
                                                                                        data-bind="visible: $parent.AllChildrenSelected"></i>
                                                                                </a>
                                                                            </ko-bind>
                                                                            <br />

                                                                            <ko-if data-bind="$data.secondaryAction && $component.ShowSecondaryAction()">
                                                                                <ko-with data-bind="$data.secondaryAction">
                                                                                    <div
                                                                                        class="btn btn-warning btn-lg"
                                                                                        data-bind="click: action, clickBubble: false, style: { background: icon.background, color: icon.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; margin-top: 5px; position: absolute">
                                                                                        <i
                                                                                            class="fa"
                                                                                            data-bind="css: icon.icon"></i>
                                                                                    </div>
                                                                                </ko-with>
                                                                            </ko-if>
                                                                        </div>
                                                                    </div>
                                                                    <div class="cont-col2">
                                                                        <div
                                                                            class="desc"
                                                                            data-bind="css: { 'without-icon': !$data.icon }">
                                                                            <div data-bind="text: title"></div>
                                                                            <ko-if data-bind="$data.subTitle">
                                                                                <ko-ifnot data-bind="$data.multilineSubtitle">
                                                                                    <div
                                                                                        class="label label-sm menu-item-subtitle"
                                                                                        data-bind="visible: subTitle">
                                                                                        <span data-bind="html: subTitle"></span>
                                                                                    </div>
                                                                                </ko-ifnot>
                                                                                <ko-if data-bind="$data.multilineSubtitle">
                                                                                    <div
                                                                                        class="label label-sm multiline-label"
                                                                                        data-bind="visible: subTitle, foreach: subTitle">
                                                                                        <span data-bind="text: $data"></span>
                                                                                    </div>
                                                                                </ko-if>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.progressBar">
                                                                                <div
                                                                                    class="progress"
                                                                                    style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block"
                                                                                    data-bind="with: progressBar">
                                                                                    <div
                                                                                        class="progress-bar progress-bar-success"
                                                                                        style="min-width: 0"
                                                                                        role="progressbar"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        data-bind="attr : { 'aria-valuenow' : progress }, style : { width : progress + '%' }"></div>
                                                                                </div>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.secondaryIcon">
                                                                                <div
                                                                                    class="btn btn-primary btn-lg"
                                                                                    data-bind="style: { background: secondaryIcon.background, color: secondaryIcon.foreground }"
                                                                                    style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                                                                    <i data-bind="css: secondaryIcon.icon"></i>
                                                                                </div>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.alerts">
                                                                                <div data-bind="with: alerts">
                                                                                    <span
                                                                                        class="pull-left"
                                                                                        style="margin-right: 5px; line-height: 22px"
                                                                                        data-bind="text: label"></span>
                                                                                    <ko-foreach data-bind="icons">
                                                                                        <div
                                                                                            class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                                                            style="margin-right: 5px"
                                                                                            data-bind="attr: { title: tooltip }, tooltip: {}, with: icon">
                                                                                            <i
                                                                                                class=""
                                                                                                data-bind="css: icon, style: { color: foreground }"></i>
                                                                                        </div>
                                                                                    </ko-foreach>
                                                                                </div>
                                                                            </ko-if>
                                                                        </div>
                                                                        <ko-if data-bind="$data.details">
                                                                            <div
                                                                                class="desc"
                                                                                style="margin: 0px; padding: 0px; max-width: 100%"
                                                                                data-bind="component: { name: details.componentName, params: details.model }"></div>
                                                                        </ko-if>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ko-ifnot>
                                                    </li>
                                                </ko-foreach>
                                                <ko-if data-bind="ShowLoadMore">
                                                    <li
                                                        class="load-more-elements"
                                                        data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></li>
                                                </ko-if>
                                                <li class="text-center" data-bind="visible: IsLoading">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </li>
                                            </ul>
                                        </ko-ifnot>
                                    </div>
                                </ko-ifnot>
                                <ko-if data-bind="useWhiteTheme">
                                    <div
                                        class="navigator-scrollable-container"
                                        style="top: 92px"
                                        data-bind="style: { top: TopOffset }, slimScroll">
                                        <h4
                                            class="list-heading"
                                            data-bind="visible: !HasItems() && !IsLoading() && HasLoadedData(), text: EmptyListPlaceholder"></h4>
                                        <ko-if data-bind="IsGroupedDataSet">
                                            <ko-foreach data-bind="Items">
                                                <div>
                                                    <h3
                                                        class="list-heading prolife"
                                                        data-bind="click: click, tooltip: { placement: 'bottom' }, attr: { 'data-original-title': model.title }">
                                                        <span data-bind="text : model.title"></span>
                                                        <button
                                                            class="btn btn-transparent btn-sm pull-left"
                                                            style="margin-top: -6px; color: white; width: 32px">
                                                            <i
                                                                class="fa fa-angle-down"
                                                                data-bind="visible: !Collapsed()"></i>
                                                            <i
                                                                class="fa fa-angle-right"
                                                                data-bind="visible: Collapsed"></i>
                                                        </button>
                                                    </h3>
                                                    <h4
                                                        class="list-heading"
                                                        data-bind="visible: !HasItems() && !IsLoading() && HasLoadedData(), text: $parent.EmptyListPlaceholder"></h4>
                                                    <ul class="feeds list-items" data-bind="if : !Collapsed()">
                                                        <ko-foreach data-bind="Items">
                                                            <li data-bind="click: click, css : { 'selected': Selected }, droppableEx : { allowedMimeType : $component.ValidMimeTypes, hoverClass : 'droppable-hover', onDrop : $component.OnItemDropped.bind($component, $data.model) }, draggableEx: { CanDrag: $data.model.dragEnabled, OnDrag: $component.OnItemDragged.bind($component, $data.model) }, with: model">
                                                                <ko-if data-bind="$component.hasCustomTemplateForModel($data)">
                                                                    <ko-template data-bind="{ nodes: $component.getCustomTemplateForModel($data) }"></ko-template>
                                                                </ko-if>
                                                                <ko-ifnot data-bind="$component.hasCustomTemplateForModel($data)">
                                                                    <ko-if data-bind="$data.badge">
                                                                        <ko-foreach data-bind="$data.badge">
                                                                            <div class="date">
                                                                                <span
                                                                                    class="badge pull-right"
                                                                                    data-bind="text: $data.text, css : $data.cssClass, attr: { title: $data.title }"></span>
                                                                            </div>
                                                                        </ko-foreach>
                                                                    </ko-if>
                                                                    <div class="col1">
                                                                        <div class="cont">
                                                                            <div class="cont-col1">
                                                                                <ko-if data-bind="$data.icon">
                                                                                    <div
                                                                                        class="btn btn-primary btn-lg"
                                                                                        data-bind="style: { background: icon.background, color: icon.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                                                                        <i data-bind="css: icon.icon"></i>
                                                                                    </div>
                                                                                </ko-if>
                                                                                <div style="position: absolute">
                                                                                    <ko-if data-bind="$data.iconsList && !$component.ShowSecondaryAction()">
                                                                                        <ko-foreach data-bind="$data.iconsList">
                                                                                            <div
                                                                                                class="btn btn-primary btn-lg"
                                                                                                data-bind="style: { background: $data.background, color: $data.foreground }"
                                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: block; margin-top: 5px">
                                                                                                <i data-bind="css: $data.icon"></i>
                                                                                            </div>
                                                                                        </ko-foreach>
                                                                                    </ko-if>
                                                                                    <ko-bind data-bind="if: $component.ShowSelectAllChildren() && $data.showSelectAllChildren && !$component.ShowSecondaryAction()">
                                                                                        <br />
                                                                                        <a
                                                                                            class="workflow-item-with-task-checkbox"
                                                                                            data-bind="click: $parent.manageAllChildrenSelection.bind($parent), clickBubble: false">
                                                                                            <i
                                                                                                class="fa fa-square-o fa-lg"
                                                                                                data-bind="visible: !$parent.AllChildrenSelected() && !$parent.ChildrenSelected()"></i>
                                                                                            <i
                                                                                                class="fa fa-square fa-lg"
                                                                                                data-bind="visible: !$parent.AllChildrenSelected() && $parent.ChildrenSelected()"></i>
                                                                                            <i
                                                                                                class="fa fa-check-square fa-lg"
                                                                                                data-bind="visible: $parent.AllChildrenSelected"></i>
                                                                                        </a>
                                                                                    </ko-bind>
                                                                                    <br />

                                                                                    <ko-if data-bind="$data.secondaryAction && $component.ShowSecondaryAction()">
                                                                                        <ko-with data-bind="$data.secondaryAction">
                                                                                            <div
                                                                                                class="btn btn-warning btn-lg"
                                                                                                data-bind="click: action, clickBubble: false, style: { background: icon.background, color: icon.foreground }"
                                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; margin-top: 5px; position: absolute">
                                                                                                <i class="fa fa-pencil"></i>
                                                                                            </div>
                                                                                        </ko-with>
                                                                                    </ko-if>
                                                                                </div>
                                                                            </div>
                                                                            <div class="cont-col2">
                                                                                <div
                                                                                    class="desc"
                                                                                    data-bind="css: { 'without-icon': !$data.icon }">
                                                                                    <div data-bind="text: title"></div>
                                                                                    <ko-if data-bind="$data.subTitle">
                                                                                        <div
                                                                                            class="label label-sm menu-item-subtitle"
                                                                                            data-bind="visible: subTitle">
                                                                                            <span data-bind="text: subTitle"></span>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.progressBar">
                                                                                        <div
                                                                                            class="progress"
                                                                                            style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block"
                                                                                            data-bind="with: progressBar">
                                                                                            <div
                                                                                                class="progress-bar progress-bar-success"
                                                                                                style="min-width: 0"
                                                                                                role="progressbar"
                                                                                                aria-valuemin={0}
                                                                                                aria-valuemax={100}
                                                                                                data-bind="attr : { 'aria-valuenow' : progress }, style : { width : progress + '%' }"></div>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.secondaryIcon">
                                                                                        <div
                                                                                            class="btn btn-primary btn-lg"
                                                                                            data-bind="style: { background: secondaryIcon.background, color: secondaryIcon.foreground }"
                                                                                            style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                                                                            <i data-bind="css: secondaryIcon.icon"></i>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                    <ko-if data-bind="$data.alerts">
                                                                                        <div data-bind="with: alerts">
                                                                                            <span
                                                                                                class="pull-left"
                                                                                                style="margin-right: 5px; line-height: 22px"
                                                                                                data-bind="text: label"></span>
                                                                                            <ko-foreach data-bind="icons">
                                                                                                <div
                                                                                                    class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                                                                    style="margin-right: 5px"
                                                                                                    data-bind="attr: { title: tooltip }, tooltip: {}, with: icon">
                                                                                                    <i
                                                                                                        class=""
                                                                                                        data-bind="css: icon, style: { color: foreground }"></i>
                                                                                                </div>
                                                                                            </ko-foreach>
                                                                                        </div>
                                                                                    </ko-if>
                                                                                </div>
                                                                                <ko-if data-bind="$data.details">
                                                                                    <div
                                                                                        class="desc"
                                                                                        style="margin: 0px; padding: 0px; max-width: 100%"
                                                                                        data-bind="component: { name: details.componentName, params: details.model }"></div>
                                                                                </ko-if>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ko-ifnot>
                                                            </li>
                                                        </ko-foreach>
                                                        <ko-if data-bind="ShowLoadMore">
                                                            <li
                                                                class="load-more-elements"
                                                                data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></li>
                                                        </ko-if>
                                                        <li class="text-center" data-bind="visible: IsLoading">
                                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </ko-foreach>
                                            <ko-if data-bind="ShowLoadMore">
                                                <div
                                                    class="load-more-elements"
                                                    data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></div>
                                            </ko-if>
                                            <div class="text-center" data-bind="visible: IsLoading">
                                                <i class="fa fa-circle-o-notch fa-spin"></i>
                                            </div>
                                        </ko-if>
                                        <ko-ifnot data-bind="IsGroupedDataSet">
                                            <ul class="feeds list-items">
                                                <ko-foreach data-bind="Items">
                                                    <li data-bind="click: click, css : { 'selected': Selected, 'has-selected-children': ChildrenSelected }, droppableEx : { allowedMimeType : $component.ValidMimeTypes, hoverClass : 'droppable-hover', onDrop : $component.OnItemDropped.bind($component, $data.model) }, draggableEx: { CanDrag: $data.model.dragEnabled, OnDrag: $component.OnItemDragged.bind($component, $data.model) }, with: model">
                                                        <ko-if data-bind="$component.hasCustomTemplateForModel($data)">
                                                            <ko-template data-bind="{ nodes: $component.getCustomTemplateForModel($data) }"></ko-template>
                                                        </ko-if>
                                                        <ko-ifnot data-bind="$component.hasCustomTemplateForModel($data)">
                                                            <ko-if data-bind="$data.badge">
                                                                <ko-foreach data-bind="$data.badge">
                                                                    <div
                                                                        class="date"
                                                                        style="position: absolute; right: 8px;">
                                                                        <span
                                                                            class="badge pull-right"
                                                                            data-bind="text: $data.text, css : $data.cssClass, attr: { title: $data.title }"></span>
                                                                    </div>
                                                                </ko-foreach>
                                                            </ko-if>
                                                            <div class="col1">
                                                                <div class="cont">
                                                                    <div class="cont-col1">
                                                                        <ko-if data-bind="$data.icon">
                                                                            <div
                                                                                class="btn btn-primary btn-lg"
                                                                                data-bind="style: { background: icon.background, color: icon.foreground }"
                                                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                                                                <i data-bind="css: icon.icon"></i>
                                                                            </div>
                                                                        </ko-if>
                                                                        <div style="position: absolute">
                                                                            <ko-if data-bind="$data.iconsList && !$component.ShowSecondaryAction()">
                                                                                <ko-foreach data-bind="$data.iconsList">
                                                                                    <div
                                                                                        class="btn btn-primary btn-lg"
                                                                                        data-bind="style: { background: $data.background, color: $data.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: block; margin-top: 5px">
                                                                                        <i data-bind="css: $data.icon"></i>
                                                                                    </div>
                                                                                </ko-foreach>
                                                                            </ko-if>
                                                                            <ko-bind data-bind="if: $component.ShowSelectAllChildren() && $data.showSelectAllChildren && !$component.ShowSecondaryAction()">
                                                                                <br />
                                                                                <a
                                                                                    class="workflow-item-with-task-checkbox"
                                                                                    data-bind="click: $parent.manageAllChildrenSelection.bind($parent), clickBubble: false">
                                                                                    <i
                                                                                        class="fa fa-square-o fa-lg"
                                                                                        data-bind="visible: !$parent.AllChildrenSelected() && !$parent.ChildrenSelected()"></i>
                                                                                    <i
                                                                                        class="fa fa-square fa-lg"
                                                                                        data-bind="visible: !$parent.AllChildrenSelected() && $parent.ChildrenSelected()"></i>
                                                                                    <i
                                                                                        class="fa fa-check-square fa-lg"
                                                                                        data-bind="visible: $parent.AllChildrenSelected"></i>
                                                                                </a>
                                                                            </ko-bind>
                                                                            <br />

                                                                            <ko-if data-bind="$data.secondaryAction && $component.ShowSecondaryAction()">
                                                                                <ko-with data-bind="$data.secondaryAction">
                                                                                    <div
                                                                                        class="btn btn-warning btn-lg"
                                                                                        data-bind="click: action, clickBubble: false, style: { background: icon.background, color: icon.foreground }"
                                                                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; margin-top: 5px; position: absolute">
                                                                                        <i
                                                                                            class="fa"
                                                                                            data-bind="css: icon.icon"></i>
                                                                                    </div>
                                                                                </ko-with>
                                                                            </ko-if>
                                                                        </div>
                                                                    </div>
                                                                    <div class="cont-col2">
                                                                        <div
                                                                            class="desc"
                                                                            data-bind="css: { 'without-icon': !$data.icon }">
                                                                            <div data-bind="text: title"></div>
                                                                            <ko-if data-bind="$data.subTitle">
                                                                                <ko-ifnot data-bind="$data.multilineSubtitle">
                                                                                    <div
                                                                                        class="label label-sm menu-item-subtitle"
                                                                                        data-bind="visible: subTitle">
                                                                                        <span data-bind="text: subTitle"></span>
                                                                                    </div>
                                                                                </ko-ifnot>
                                                                                <ko-if data-bind="$data.multilineSubtitle">
                                                                                    <div
                                                                                        class="label label-sm multiline-label"
                                                                                        data-bind="visible: subTitle, foreach: subTitle">
                                                                                        <span data-bind="text: $data"></span>
                                                                                    </div>
                                                                                </ko-if>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.progressBar">
                                                                                <div
                                                                                    class="progress"
                                                                                    style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block"
                                                                                    data-bind="with: progressBar">
                                                                                    <div
                                                                                        class="progress-bar progress-bar-success"
                                                                                        style="min-width: 0"
                                                                                        role="progressbar"
                                                                                        aria-valuemin={0}
                                                                                        aria-valuemax={100}
                                                                                        data-bind="attr : { 'aria-valuenow' : progress }, style : { width : progress + '%' }"></div>
                                                                                </div>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.secondaryIcon">
                                                                                <div
                                                                                    class="btn btn-primary btn-lg"
                                                                                    data-bind="style: { background: secondaryIcon.background, color: secondaryIcon.foreground }"
                                                                                    style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                                                                    <i data-bind="css: secondaryIcon.icon"></i>
                                                                                </div>
                                                                            </ko-if>
                                                                            <ko-if data-bind="$data.alerts">
                                                                                <div data-bind="with: alerts">
                                                                                    <span
                                                                                        class="pull-left"
                                                                                        style="margin-right: 5px; line-height: 22px"
                                                                                        data-bind="text: label"></span>
                                                                                    <ko-foreach data-bind="icons">
                                                                                        <div
                                                                                            class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                                                            style="margin-right: 5px"
                                                                                            data-bind="attr: { title: tooltip }, tooltip: {}, with: icon">
                                                                                            <i
                                                                                                class=""
                                                                                                data-bind="css: icon, style: { color: foreground }"></i>
                                                                                        </div>
                                                                                    </ko-foreach>
                                                                                </div>
                                                                            </ko-if>
                                                                        </div>
                                                                        <ko-if data-bind="$data.details">
                                                                            <div
                                                                                class="desc"
                                                                                style="margin: 0px; padding: 0px; max-width: 100%"
                                                                                data-bind="component: { name: details.componentName, params: details.model }"></div>
                                                                        </ko-if>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ko-ifnot>
                                                    </li>
                                                </ko-foreach>
                                                <ko-if data-bind="ShowLoadMore">
                                                    <li
                                                        class="load-more-elements"
                                                        data-bind="notifyWhenVisible: { rootSelector: '.navigator-scrollable-container', callback: $data.loadNextPage.bind($data) }"></li>
                                                </ko-if>
                                                <li class="text-center" data-bind="visible: IsLoading">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </li>
                                            </ul>
                                        </ko-ifnot>
                                    </div>
                                </ko-if>
                            </div>
                        </div>
                    </div>
                </div>,
            ]);

            return vm;
        },
    },
    template: [],
});
