import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ManualAllocationsEditor, IManualAllocationsEditor } from "../ManualAllocationsEditor";
import { CartsList } from "../CartsList";
import { AllocationMode } from "../../enums/AllocationMode";
import { IAllocationsService } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IManualAllocationDialog } from "../../../interfaces/IManualAllocationDialog";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ICartsManager } from "../../../interfaces/ICartsManager";

export class ManualAllocationDialog implements IManualAllocationDialog {
    public templateName = "manual-allocation-dialog";
    public templateUrl = "allocations/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Allocations.ManualAllocationsDialog;
    public modal?: { close: (result?: any) => void; };
    
    public Date: ko.Observable<Date> = ko.observable();
    public ManualAllocationsEditor: ko.Observable<IManualAllocationsEditor> = ko.observable();

    public DropScope: ko.Observable<string> = ko.observable();

    public CartsList: CartsList;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService!: IAllocationsService;

    constructor(private teamId: number, date: Date, cartId: number = null) {
        this.Date(date);

        this.ManualAllocationsEditor(new ManualAllocationsEditor(this.teamId));
        this.ManualAllocationsEditor()
            .setDate(date, false)
            .then(() => {
                if (cartId)
                    this.ManualAllocationsEditor().addAllocation(cartId);
            });

        this.DropScope("manual-allocations-dialog");

        this.CartsList = new CartsList();
        this.CartsList.DropScope("manual-allocations-dialog");
        this.CartsList.DragScope("manual-allocations-dialog");
        this.CartsList.AllocationMode(AllocationMode.ManualAllocation);
        this.CartsList.ShowClosedCarts(true);
        
        let lastDate: Date = date;
        let changingDate = false;
        this.Date.subscribe((date: Date) => {
            if (changingDate)
                return;

            changingDate = true;
            
            this.ManualAllocationsEditor()
                .setDate(date)
                .then((result: boolean) => {
                    if (result)
                        lastDate = date;
                    else
                        this.Date(lastDate);

                    changingDate = false;
                });
        });

        setTimeout(() => {
            this.allocationsService.triggerNextTutorialStep("manual-allocation-editor-opened");
        }, 0);
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this, "large");
    }
    
    public close(): void {
        if (!this.ManualAllocationsEditor().hasChanges()) {
            this.CartsList.Dispose();
            this.modal.close(false);
            return;
        }

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessage,
            ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessageCancel,
            ProlifeSdk.TextResources.Allocations.ManualAllocationsUnsavedDataMessageConfirm,
            (confirm: boolean) => {
                if (confirm) {
                    this.CartsList.Dispose();
                    this.modal.close(true);
                }
            });
    }

    public action(): void {
        if (!this.ManualAllocationsEditor().validate())
            return;

        this.ManualAllocationsEditor()
            .save()
            .then(() => {
                this.CartsList.Dispose();
                this.modal.close(true);
            })
            .catch(() => {});
    }
}