import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { IAccount } from "../ProlifeSdk/interfaces/settings/IAccount";
import { IBank } from "../ProlifeSdk/interfaces/settings/IBank";
import { ICompanySettingsManager } from "../ProlifeSdk/interfaces/settings/ICompanySettingsManager";

export interface ICompanyIBANDataSourceModel extends IDataSourceModel<string, IAccount> {
    bank: IBank;
}

export class CompanyIBANDataSource extends BaseDataSource<ICompanyIBANDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    public companySettingsManager: ICompanySettingsManager;

    getTitle(currentModel: ICompanyIBANDataSourceModel): string {
        return TextResources.Core.CompanyIBANs;
    }

    async getData(currentModel: ICompanyIBANDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICompanyIBANDataSourceModel[]> {
        const splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        const ibans = this.getAllIBANs();        
        return ibans
            .filter(d => splitFilters.filter(w => d.account.Description.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    private getAllIBANs() : { account: IAccount, bank: IBank}[] {
        const accounts : { account: IAccount, bank: IBank}[]= [];
        const company = this.companySettingsManager.get();

        for (const bank of company.Banks) {
            for (const account of bank.Accounts) {
                accounts.push({account, bank});
            }
        }
        return accounts;
    }

    createModel(model : { account: IAccount, bank: IBank }): ICompanyIBANDataSourceModel {
        return {
            id: model.account.IBAN,
            title: model.account.IBAN,
            subTitle: model.account.Description,
            isLeaf: true,
            isGroup: false,
            model: model.account,
            bank: model.bank
        }
    }

    async getById(currentModel: ICompanyIBANDataSourceModel, ids: string[]): Promise<ICompanyIBANDataSourceModel[]> {
        return this.getAllIBANs()
            .filter(p => ids.indexOf(p.account.IBAN) >= 0)
            .map(this.createModel, this);
    }

}