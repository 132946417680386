import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/09/2017
 * Time: 17:52
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IAssignedCampaigns } from "../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class AlertDialog implements IDialog {
    public templateName: string = "alert-dialog";
    public templateUrl: string = "survey/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.AlertDialogTitle;

    public modal: { close: (result?: any) => void; };

    public Message: ko.Observable<string> = ko.observable();

    public ShowAlert: ko.Computed<boolean>;
    public ShowWarning: ko.Computed<boolean>;

    constructor(assignedToStartedCampaigns: IAssignedCampaigns[], message: string) {
        this.Message(message);

        if (assignedToStartedCampaigns.length == 0)
            this.title = ProlifeSdk.TextResources.Survey.AlertDialogForCampaignTitle;

        this.ShowWarning = ko.computed(() => {
            var planned: IAssignedCampaigns[] = assignedToStartedCampaigns.filter((c: IAssignedCampaigns) => {
                return c.Id == ProlifeSdk.PlannedCampaignState;
            });
            return planned.length != 0 && planned[0].NumberOfCampaigns > 0;
        });

        this.ShowAlert = ko.computed(() => {
            var startedOrOver: IAssignedCampaigns[] = assignedToStartedCampaigns.filter((c: IAssignedCampaigns) => {
                return c.Id == ProlifeSdk.InProgressCampaignState || c.Id == ProlifeSdk.OverCampaignState;
            });
            return (startedOrOver.length > 0 || assignedToStartedCampaigns.length == 0);
        });
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        this.modal.close(true);
    }
}