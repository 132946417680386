import * as ko from "knockout";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";

export class AdministrativeReportUtils {
    public static billableAndBilledFormatter(value: boolean | number): string {
        if (value === undefined)
            return "";
        
        if (typeof(value) === "number")
            return value.toString();

        return value ? TextResources.ProlifeSdk.Yes : TextResources.ProlifeSdk.No;
    }
}