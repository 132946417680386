import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/09/2017
 * Time: 10:31
 * To change this template use File | Settings | File Templates.
 */

import { Answer } from "../Answer";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IAnswer } from "../../../../../ProlifeSdk/interfaces/survey/IAnswer";

export class AnswerForDetails extends Answer {
    public HaveDateField: ko.Computed<boolean>;
    public HaveNumberField: ko.Computed<boolean>;
    public HaveGenericTextField: ko.Computed<boolean>;

    public IsUniqueAnswer: ko.Observable<boolean> = ko.observable();

    public Enable: ko.Computed<string>;
    public GoTo: ko.Computed<string>;

    constructor(answer: IAnswer, isUniqueAnswer: boolean) {
        super(answer);

        this.IsUniqueAnswer(isUniqueAnswer && this.IsOpen());

        this.HaveDateField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerDateField;
        });

        this.HaveNumberField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerNumericField || this.AnswerTypeId() == ProlifeSdk.AnswerMoneyField;
        });

        this.HaveGenericTextField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerTextField;
        });

        this.Enable = ko.computed(() => {
            return this.EnablePublicLabel() || this.SectionEnablePublicLabel() || null;
        });

        this.GoTo = ko.computed(() => {
            return this.GoToPublicLabel() || this.SectionGoToPublicLabel() || null;
        });
    }
}