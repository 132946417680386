import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import {
    IMailTemplatesSettingsManager,
    MailTemplate,
} from "../../../../ProlifeSdk/interfaces/desktop/settings/IMailTemplatesSettingsManager";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { TextInput } from "../../../../Components/TextInput";
import { RichTextInput } from "../../../../Components/RichTextInput";
import { IListComponentModel, List } from "../../../../Components/ListComponent";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { ProLifeClipboard } from "../../../../Core/utils/ProLifeClipboard";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IValidation, IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import {
    DocumentMailPlaceholderDataSource,
    MailPlaceholder,
} from "../../../../DataSources/DocumentMailPlaceholdersDataSource";
import { ISmtpConfigurationService } from "../../../../ProlifeSdk/interfaces/desktop/ISmtpConfigurationService";

const styleSheet = jss.createStyleSheet({
    mte: {
        height: "100%",

        "& .header": {
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #ddd",

            "& .toolbar": {
                alignItems: "center",
                justifyContent: "flex-end",

                "& .btn": {
                    "& > .fa": {
                        marginRight: "5px",
                    },
                },
            },
        },

        "& .body": {
            paddingTop: "10px",

            "& .editor-panel": {
                "& .templates": {
                    backgroundColor: "#eee",
                    padding: "10px",

                    "& .list-notification-item": {
                        borderBottom: "1px solid #ccc",

                        "&:not(.selected)": {
                            background: "transparent",
                        },

                        "&:hover": {
                            background: "#bbb",
                        },
                    },
                },
                "& .editor": {
                    "& .form": {
                        height: "100%",

                        "& .form-body": {
                            height: "100%",

                            "& .cke": {
                                height: "calc(100% - 3px)",

                                "& .cke_inner": {
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",

                                    "& .cke_contents": {
                                        flex: "1",
                                    },
                                },
                            },
                        },
                    },
                },
                "& .placeholders": {
                    backgroundColor: "#ddd",
                    padding: "10px",

                    "& .list-notification-item": {
                        background: "transparent",
                        borderBottom: "1px solid #ccc",

                        "&.selected": {
                            background: "transparent",
                        },
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type MailTemplatesSettingsEditorProps = {
    settingsManager: IMailTemplatesSettingsManager;
    ref?: (vm: _MailTemplatesSettingsEditor) => void;
};

export function MailTemplatesSettingsEditor(props: MailTemplatesSettingsEditorProps) {
    const C = require("./MailTemplatesSettingsEditor")
        ._MailTemplatesSettingsEditor as typeof _MailTemplatesSettingsEditor;
    return <C {...props} />;
}

export class _MailTemplatesSettingsEditor {
    static defaultProps: Partial<MailTemplatesSettingsEditorProps> = {};

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    @LazyImport(nameof<ISmtpConfigurationService>())
    private smtpConfigurationService: ISmtpConfigurationService;

    MailTemplateName: ko.Observable<string | null> = ko.observable<string | null>();
    MailTemplateBlogEventTitle: ko.Observable<string | null> = ko.observable<string | null>();
    MailTemplateObject: ko.Observable<string | null> = ko.observable<string | null>();
    MailTemplateBody: ko.Observable<string | null> = ko.observable<string | null>();
    MailSenderEmail: ko.Observable<string | null> = ko.observable<string | null>();

    private MailTemplateBodyTrigger: ko.Observable<boolean> = ko.observable(true);

    AvailableTemplates: ko.ObservableArray<MailTemplate> = ko.observableArray<MailTemplate>([]);
    SelectedTemplate: ko.Observable<IListComponentModel<number, MailTemplate> | null> =
        ko.observable<IListComponentModel<number, MailTemplate> | null>(null);

    AvailablePlaceholders: DocumentMailPlaceholderDataSource = new DocumentMailPlaceholderDataSource();

    private validator: IValidator<MailTemplate> | null = null;
    private templatesList: List<number, MailTemplate> | null = null;
    private subscriptions: ko.Subscription[] = [];
    private hasChanges = false;

    constructor(private props: MailTemplatesSettingsEditorProps) {}

    componentDidMount() {
        this.initValidator();
        this.loadTemplates();
        this.initObservers();
        this.getSenderEmail();

        this.props.ref?.(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.dispose());
        this.props.ref?.(null);
    }

    public isChanged(): boolean {
        return this.hasChanges;
    }

    private initObservers(): void {
        this.subscriptions = [
            this.MailTemplateName.subscribe(() => (this.hasChanges = true)),
            this.MailTemplateBlogEventTitle.subscribe(() => (this.hasChanges = true)),
            this.MailTemplateObject.subscribe(() => (this.hasChanges = true)),
            this.MailTemplateBody.subscribe(() => (this.hasChanges = true)),
        ];
    }

    private initValidator(): void {
        this.validator = this.validationService.createValidator<MailTemplate>();

        this.validator
            .isNotNullOrUndefinedOrWhiteSpace((t) => t.name, TextResources.Core.MissingMailTemplateName)
            .isNotNullOrUndefinedOrWhiteSpace((t) => t.subject, TextResources.Core.MissingMailTemplateSubject)
            .isNotNullOrUndefinedOrWhiteSpace(
                (t) => t.blogEventTitle,
                TextResources.Core.MissingMailTemplateBlogEventTitle
            )
            .isNotNullOrUndefinedOrWhiteSpace((t) => t.body, TextResources.Core.MissingMailTemplateBody);
    }

    private loadTemplates(): void {
        const templates = this.props.settingsManager.get();
        this.AvailableTemplates(templates);
    }

    private async save(): Promise<void> {
        const template = this.SelectedTemplate();
        const isNew = !template;

        const data: MailTemplate = Object.assign({}, template?.model ?? { id: null }, {
            name: this.MailTemplateName(),
            subject: this.MailTemplateObject(),
            body: this.MailTemplateBody(),
            blogEventTitle: this.MailTemplateBlogEventTitle(),
        });

        const validationErrors = this.validator.validate(data).filter((r) => !r.valid);
        if (validationErrors.length > 0) {
            this.handleValidationErrors(validationErrors);
            return;
        }

        const response = isNew
            ? await this.props.settingsManager.create(data)
            : await this.props.settingsManager.update(data);

        if (response.succeeded) {
            this.infoToastService.Success(TextResources.Core.MailTemplateSaved);
            this.hasChanges = false;
            this.loadTemplates();
            this.templatesList?.select(response.data);
        }
    }

    private async delete(): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Core.DeleteMailTemplateAlert,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );

        if (confirm) {
            const template = this.SelectedTemplate();
            if (template) {
                const response = await this.props.settingsManager.delete(template.id);
                if (response.succeeded) {
                    this.loadTemplates();
                    this.clearForm();
                }
            }
        }
    }

    private async getSenderEmail() {
        const smtpSettings = await this.smtpConfigurationService.get();
        this.MailSenderEmail(smtpSettings?.username ?? TextResources.Core.MailTemplateNoSmtpConfigured);
    }

    private async new(): Promise<void> {
        const confirm =
            !this.hasChanges ||
            (await this.dialogsService.ConfirmAsync(
                TextResources.Core.MailTemplatePendingChanges,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            ));

        if (confirm) {
            this.templatesList?.clearSelection();
            this.SelectedTemplate(null);
            this.clearForm();
        }
    }

    private handleValidationErrors(errors: IValidation[]) {
        let message = "";

        for (const error of errors) {
            message += error.message + "<br />";
        }

        this.infoToastService.Error(message);
    }

    private async onTemplateSelected(item: IListComponentModel<number, MailTemplate>): Promise<void> {
        const confirm =
            !this.hasChanges ||
            (await this.dialogsService.ConfirmAsync(
                TextResources.Core.MailTemplatePendingChanges,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            ));

        if (confirm) {
            this.SelectedTemplate(item);
            this.loadForm(item.model);
        }
    }

    private loadForm(model: MailTemplate) {
        this.MailTemplateName(model.name);
        this.MailTemplateBlogEventTitle(model.blogEventTitle);
        this.MailTemplateObject(model.subject);
        this.MailTemplateBody(model.body);

        this.MailTemplateBodyTrigger.valueHasMutated();

        this.hasChanges = false;
    }

    private clearForm() {
        this.MailTemplateName(null);
        this.MailTemplateBlogEventTitle(null);
        this.MailTemplateObject(null);
        this.MailTemplateBody(null);

        this.MailTemplateBodyTrigger.valueHasMutated();

        this.hasChanges = false;
    }

    private onPlaceholderSelected(item: IListComponentModel<string, MailPlaceholder>): void {
        ProLifeClipboard.copy(item.model.value);
        this.infoToastService.Success(String.format(TextResources.Core.PlaceholderCopiedToClipboard, item.model.value));
    }

    render() {
        const mte = this;

        return ComponentUtils.bindTo(
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} className={classes.mte}>
                <Layout.Grid.Cell row={1} column={1} className="header">
                    <h3>{TextResources.Core.MailTemplatesSettingsEditor}</h3>
                    <h4>
                        {TextResources.Core.MailTemplateSettingsEditorSender}
                        <label
                            data-bind={{
                                text: mte.MailSenderEmail,
                            }}></label>
                    </h4>

                    <div className="flex-container toolbar">
                        <button type="button" className="btn btn-success" data-bind={{ asyncClick: mte.new.bind(mte) }}>
                            <i className="fa fa-plus"></i>
                            {TextResources.ProlifeSdk.New}
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-bind={{ asyncClick: mte.delete.bind(mte), enable: !!mte.SelectedTemplate() }}>
                            <i className="fa fa-trash-o"></i>
                            {TextResources.ProlifeSdk.Delete}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-bind={{ asyncClick: mte.save.bind(mte) }}>
                            <i className="fa fa-save"></i>
                            {TextResources.ProlifeSdk.Save}
                        </button>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1} className="flex-vertical body">
                    <Layout.Grid rows={["1fr"]} columns={["25%", "1fr", "20%"]} className="editor-panel">
                        <Layout.Grid.Cell row={1} column={1} className="flex-vertical templates">
                            <h4>{TextResources.Core.MailTemplatesListTitle}</h4>
                            <List
                                systemScrollable
                                dataSource={Table.defaultDataSource(this.AvailableTemplates, (t) => ({
                                    id: t.id,
                                    title: t.name,
                                }))}
                                emptyResultMessage={TextResources.Core.MailTemplatesListEmptyMessage}
                                containerHeight="100%"
                                onItemSelected={this.onTemplateSelected.bind(this)}
                                ref={(l) => (this.templatesList = l)}
                            />
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell row={1} column={2} className="flex-vertical editor">
                            <Layout.ScrollContainer systemScrollable>
                                <div className="form">
                                    <div className="form-body flex-container flex-vertical">
                                        <TextInput
                                            label={TextResources.Core.MailTemplateNameLabel}
                                            placeholder={TextResources.Core.MailTemplateNamePlaceholder}
                                            value={this.MailTemplateName}
                                        />
                                        <TextInput
                                            label={TextResources.Core.MailTemplateBlogEventTitleLabel}
                                            placeholder={TextResources.Core.MailTemplateBlogEventTitlePlaceholder}
                                            value={this.MailTemplateBlogEventTitle}
                                        />
                                        <TextInput
                                            label={TextResources.Core.MailTemplateObjectLabel}
                                            placeholder={TextResources.Core.MailTemplateObjectPlaceholder}
                                            value={this.MailTemplateObject}
                                        />
                                        <RichTextInput
                                            label={TextResources.Core.MailTemplateBodyLabel}
                                            placeholder={TextResources.Core.MailTemplateBodyPlaceholder}
                                            className="flex-fill"
                                            value={this.MailTemplateBody}
                                            trigger={this.MailTemplateBodyTrigger}
                                        />
                                    </div>
                                </div>
                            </Layout.ScrollContainer>
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell row={1} column={3} className="flex-vertical placeholders">
                            <h4>{TextResources.Core.DocumentMailTemplatesPlaceholdersListTitle}</h4>
                            <List
                                systemScrollable
                                dataSource={this.AvailablePlaceholders}
                                emptyResultMessage={TextResources.Core.MailTemplatesListEmptyMessage}
                                containerHeight="100%"
                                onItemSelected={this.onPlaceholderSelected.bind(this)}
                            />
                        </Layout.Grid.Cell>
                    </Layout.Grid>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "mte"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MailTemplatesSettingsEditor);
}
