import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../DocumentsService";
import { IDocumentsProvider } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";

export class AccountingExport extends ProLifeReport implements IReport, IReportViewModel {
    Name: string = "Esportazioni contabili";
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";

    detailsTemplateName: string = "accounting-export";
    detailsTemplateUrl: string = "invoices/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    public ContactsSection: ContactsAccountingExport;
    public DocumentSection: DocumentsAccountingExport;

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(groupId, 5);
        this.ContactsSection = new ContactsAccountingExport();
        this.DocumentSection = new DocumentsAccountingExport();
    }

    initialize() {
        super.initialize();
        this.ContactsSection.initialize();
        this.DocumentSection.initialize();
    }

    Open() {
        this.navigator.openReport(this);
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }

    getViewModel(): IReportViewModel {
        return this;
    }
}

export class ContactsAccountingExport {
    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService: IProLifeSdkService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private ExportCustomers: ko.Observable<boolean> = ko.observable(true);
    private ExportSupplier: ko.Observable<boolean> = ko.observable(true);
    private ExportOther: ko.Observable<boolean> = ko.observable(true);

    constructor() {}

    public ExportForGamma() {
        if (!this.ValidateInput()) return;

        const url =
            "Customers/GammaExport/ExportContacts?customers=" +
            this.ExportCustomers() +
            "&suppliers=" +
            this.ExportSupplier() +
            "&others=" +
            this.ExportOther();

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private ValidateInput(): boolean {
        if (!this.ExportCustomers() && !this.ExportSupplier() && !this.ExportOther()) {
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.SelectCategory);
            return false;
        }

        return true;
    }

    initialize() {
        this.ExportCustomers(true);
        this.ExportSupplier(true);
        this.ExportOther(true);
    }
}

export class DocumentsAccountingExport {
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;
    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService: IProLifeSdkService;
    @LazyImport(nameof<IDocumentsService>())
    private docsService: IDocumentsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private startDate: ko.Observable<Date> = ko.observable();
    private endDate: ko.Observable<any> = ko.observable();

    public Protocols: ko.ObservableArray<Protocol> = ko.observableArray([]);

    constructor() {}

    public ExportForGamma() {
        if (!this.ValidateInput()) return;

        const selectedProtocols: string = this.Protocols()
            .filter((p: Protocol) => {
                return p.Selected();
            })
            .map((p: Protocol) => {
                return p.Id.toString();
            })
            .join("_");

        const from: Date = this.sdkService.Utilities.Dates.AddGmtTime(this.startDate());
        const to: Date = this.sdkService.Utilities.Dates.AddGmtTime(this.endDate());

        const url =
            "Invoices/GammaExport/ExportVatDocuments?start=" +
            encodeURIComponent(moment(from).format()) +
            "&end=" +
            encodeURIComponent(moment(to).format()) +
            "&protocols=" +
            selectedProtocols;

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private ValidateInput(): boolean {
        if (!this.startDate() || !this.endDate() || moment(this.startDate()).isAfter(this.endDate())) {
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.SelectValidInterval);
            return false;
        }

        const selectedProtocols: string[] = this.Protocols()
            .filter((p: Protocol) => {
                return p.Selected();
            })
            .map((p: Protocol) => {
                return p.Id.toString();
            });

        if (selectedProtocols.length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.SelectProtocol);
            return false;
        }

        return true;
    }

    initialize() {
        this.Protocols(
            this.docsService
                .getRegisteredDocumentProviders()
                .filter((p: IDocumentsProvider) => {
                    return p.DocumentEntityCode == ProlifeSdk.InvoiceEntityTypeCode;
                })
                .map((p: IDocumentsProvider) => {
                    return new Protocol(p);
                })
        );
        this.startDate(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.endDate(moment(new Date()).subtract("month", 1).endOf("month").toDate());
    }
}

export class Protocol {
    public Selected: ko.Observable<boolean> = ko.observable(true);
    public Name: string;
    public Id: number;

    constructor(provider: IDocumentsProvider) {
        this.Name = provider.Name();
        this.Id = provider.Id;
    }
}
