import * as ko from "knockout";
import "overlayscrollbars";
import { ComponentUtils } from "../Core/utils/ComponentUtils";
const css = require("../../node_modules/overlayscrollbars/css/OverlayScrollbars.css");
const css1 = require("./SlimScroll/os-theme-thin-dark.css");
const css2 = require("./SlimScroll/os-theme-thin-light.css");

export class SlimScroll {
    public init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void
    {
        setTimeout(() => {
            let value = ko.utils.unwrapObservable(valueAccessor());
            if(value === 'flex') {
                $(element).addClass("flex-1");
            } else if(value) {
                var height : number | string = ko.utils.unwrapObservable(value) || "auto";

                if(height === 'auto') {
                    let actualParentHeight = $(element).parent().height() || 0;
                    height = actualParentHeight + "px";
                }

                $(element).css('height', height);
                $(element).css('max-height', height);
            }

            let classNames = ComponentUtils.classNames('os-theme-thin-dark', allBindingsAccessor.get("slimScrollClasses"));

            (<any>$(element)).overlayScrollbars({
                className: classNames,
                sizeAutoCapable: true,
                overflowBehavior: {
                    x: allBindingsAccessor.has("slimScrollVerticalOnly") ? "hidden" : "scroll",
                    y: "scroll"
                },
                scrollbars: {
                    autoHide: "leave"
                }
            });
        }, 0);
    }
}

export class LightSlimScroll {
    public init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        let value = valueAccessor();
        if(value) {
            var height : number | string = ko.utils.unwrapObservable(value);

            if (height !== 'auto')
                $(element).css('height', height);
        }

        (<any>$(element)).overlayScrollbars({
            className: 'os-theme-thin-light',
            scrollbars: {
                autoHide: "leave"
            }
        });
    }
}

ko.bindingHandlers["slimScroll"] = new SlimScroll();
ko.bindingHandlers["lightSlimScroll"] = new LightSlimScroll();
