import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment-timezone";
import * as React from "@abstraqt-dev/jsxknockout";
import { WorkSheetRow } from "../WorkSheetRow";
import { WorkSheetRowTemp } from "../WorkSheetRowTemp";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { WorkedHoursRowForList } from "./WorkedHoursRowForList";
import { WorkedHoursRowForEditing } from "./WorkedHoursRowForEditing";
import { IMultipleInsertError, MultipleErrorsDialog } from "../dialog/MultipleInsertErrors";
import { ResourcesDataSource, IResourceDataSourceModel } from "../../../../DataSources/ResourcesDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDataSource, IDataSourceModel, IDataSourceListener } from "../../../../DataSources/IDataSource";
import {
    IWorkedHoursEditor,
    IWorkedHoursRowForList,
    IWorkedHoursRowForEditing,
    IWorkedHoursEditorListener,
    IWorkedHoursEditorParameters,
} from "../../../interfaces/IWorkedHoursEditor";
import { ITodoListService, ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IPreferredActivityWithNote,
    IWorkedHoursService,
    ISuggestedWorkableElement,
    IWorkedHoursWithApprovalStateForListWorkedHours,
    IWorkedHoursWithApprovalStateForListHoursApprovalRows,
    IWorkedHoursForEditing,
    IServiceOrderCostWithBudget,
    IGetResourceServiceOrdersSalariesBudgetsRequest,
    IValidRolesForServiceOrder,
    IWorkedHoursWithApprovalStateForEditingWorkedHours,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Right } from "../../../../Core/Authorizations";
import { renderMobileEditor } from "./MobileEditorTemplate";
import { WorkedHoursEditorForm } from "./WorkedHoursEditorForm";
import { WorkedHoursEditorList } from "./WorkedHoursEditorList";
import { With } from "../../../../Components/IfIfNotWith";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../Components/Layouts";
import { MonthSyntheticDataPopover, IMonthSyntheticDataPopoverProps } from "../dialog/MonthSyntheticDataPopover";
import {
    IGetResourceHoursApprovalGroupsRequest,
    IHoursApprovalGroup,
    IUsersService,
} from "../../../../Users/UsersService";
import {
    ErrorsAndWarnings,
    WorkTimeCategoriesRemainingBudgetWithListDialog,
} from "../dialog/WorkTimeCategoriesRemainingBudgetDialog";
import { WorkTimeCategoriesRemainingBudget } from "./WorkTimeCategoriesRemainingBudget";
import { WorkFlexibilityButton } from "./WorkFlexibilityButton";
import { ResponseData, ResponseError } from "../../../../Core/response/ResponseBase";

type HandleSaveErrorResult = {
    abort: boolean;
    adjustTaskReferenceDates: boolean;
    forceSavingImportedInDocumentsHours: boolean;
    ignoreWarning: boolean;
};

type SaveHourResult = {
    abort: boolean;
    savedHour: IWorkedHoursForEditing | null;
    errors: ResponseError[] | null;
};

export function WorkedHoursEditor(props: IWorkedHoursEditorParameters) {
    const C = require("./WorkedHoursEditor")._WorkedHoursEditor as typeof _WorkedHoursEditor;
    return <C {...props} />;
}

export class _WorkedHoursEditor implements IWorkedHoursEditor, IDataSourceListener {
    ErrorsAndWarningsData: ko.Observable<ErrorsAndWarnings> = ko.observable();
    ShowBudgetWithListDialog: ko.Observable<boolean> = ko.observable(false);

    public get insertPerJobOrder(): boolean {
        return !!this.props.insertPerJobOrder;
    }

    public SelectedServiceOrderId: ko.Observable<number> = ko.observable();
    public CanEditDate: ko.Observable<boolean> = ko.observable();
    public EnableMultipleInsert: ko.Observable<boolean> = ko.observable();
    public WorkDay: ko.Observable<Date> = ko.observable();
    public ResourceId: ko.Observable<number> = ko.observable();
    public ResourceName: ko.Observable<string> = ko.observable();
    public RefreshResourceField: ko.Observable<boolean> = ko.observable(false);
    public MassiveInsertedActivities: ko.ObservableArray<WorkSheetRow> = ko.observableArray();
    public Activities: ko.ObservableArray<IWorkedHoursRowForList> = ko.observableArray();
    public ActivitiesForMultipleInsert: ko.ObservableArray<IWorkedHoursRowForList> = ko.observableArray();
    public BusinessManagementActivities: ko.ObservableArray<WorkSheetRow> = ko.observableArray();

    public HoursOnEdit: ko.Observable<IWorkedHoursRowForEditing> = ko.observable();

    public ReadOnlyActivities: ko.Computed<WorkSheetRow[]>;
    public selectedJobOrderId: ko.Computed<number>;
    public selectedTaskId: ko.Computed<number>;

    public NewActivityContainerType: ko.Observable<string> = ko.observable();
    public NewActivityContainerId: ko.Observable<number> = ko.observable();

    // TODO rendere visibile i fatto che il mese è bloccato e le ore non possono essere inserite/modificate
    public IsMonthLocked: ko.Observable<boolean> = ko.observable(false);

    public FormattedDate: ko.Computed<string>;
    public CompanyLogo: ko.Observable<unknown> = ko.observable(null);
    public ResourceFieldHasFocus: ko.Observable<boolean> = ko.observable(false);

    public CanEditOtherUsersWorkedHours: ko.Observable<boolean> = ko.observable(false);

    public PreferredActivities: ko.ObservableArray<IPreferredActivityWithNote> = ko.observableArray([]);

    public MonthlyImbalance: ko.Observable<number> = ko.observable(0);
    public MonthlyOvertime: ko.Observable<number> = ko.observable(0);
    public TotalOrdinaryHours: ko.Computed<number>;

    public MultipleInsertEnabled: ko.Observable<boolean> = ko.observable(false);
    public ShowMultipleInsertPopover: ko.Observable<boolean> = ko.observable(false);

    public ShowClosed: ko.Observable<boolean> = ko.observable(false);
    public ViewAll: ko.Observable<boolean> = ko.observable(false);
    public ShowMaterialResources: ko.Observable<boolean> = ko.observable(false);
    public ShowPreferred: ko.Observable<boolean> = ko.observable(true);

    public CanApproveHours: ko.Observable<boolean> = ko.observable(false);
    public ApprovalMenuOpen: ko.Observable<boolean> = ko.observable(false);

    public workSheetRowTemp: ko.Observable<WorkSheetRowTemp> = ko.observable();

    public IsChangedActivity: ko.Observable<boolean> = ko.observable(false);
    public hasPendingChanges: ko.Computed<boolean>;

    public TimeZoneSelectorTitle: string = ProlifeSdk.TextResources.ProlifeSdk.TimeZoneTitle;
    public SelectedTimeZone: ko.Observable<string> = ko.observable();

    public ResourcesDataSource: ResourcesDataSource;
    public IsResourceFieldReadOnly: ko.Computed<boolean>;

    public isEditFlexibility: ko.Observable<boolean> = ko.observable(false);

    private SelectedServiceOrder: ko.Observable<IValidRolesForServiceOrder> = ko.observable();

    private ResourceApprovalGroups: ko.ObservableArray<IHoursApprovalGroup> = ko.observableArray([]);

    /**
     * Per versione mobile
     */
    public SelectedActivity: ko.Observable<WorkSheetRow> = ko.observable();
    public HasChanges: ko.Computed<boolean>;

    public CanCreateActivities: ko.Observable<boolean> = ko.observable(false);

    // ---

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IUsersService>())
    private usersService: IUsersService;
    @LazyImport(nameof<IWorkedHoursService>())
    private _workedHours: IWorkedHoursService;

    private listeners: IWorkedHoursEditorListener[] = [];
    private isLoading = false;

    private servicesOrderCost: ko.ObservableArray<IServiceOrderCostWithBudget> = ko.observableArray([]);

    private multipleInsertListRefreshInterceptor: ko.Computed<void>;
    private lastConfirm: boolean | null = null;

    @Right("TaskBoard_CanViewAll")
    private TaskBoard_CanViewAll: boolean;
    @Right("TaskBoard_EditTask")
    private TaskBoard_EditTask: boolean;
    @Right("TaskBoard_InsertTask")
    private TaskBoard_InsertTask: boolean;
    @Right("JobOrders_EditTaskProgress")
    public JobOrders_EditTaskProgress: boolean;
    @Right("WorkedHours_InsertHoursOnClosedWorkableElements")
    private WorkedHours_InsertHoursOnClosedWorkableElements: boolean;

    constructor(private props: IWorkedHoursEditorParameters) {
        this.CanEditOtherUsersWorkedHours(
            this.authorizationService.isAuthorized("WorkedHours_CanEditOtherUsersWorkSheets")
        );
        this.CanCreateActivities(
            this.authorizationService.isAuthorized("TaskBoard_InsertTask") && !this.desktopService.isMobileBrowser()
        );

        const generalSettings: IJobOrderGeneralSettingsManager = this.settingsService.findSettingsManager(
            ProlifeSdk.JobOrderGeneralSettings
        ) as IJobOrderGeneralSettingsManager;

        this.ShowClosed(
            this.WorkedHours_InsertHoursOnClosedWorkableElements &&
                generalSettings.getShowHiddenHobOrdersAndResourcesOnWorkedHours()
        );
        this.ViewAll(this.TaskBoard_CanViewAll && generalSettings.getViewAllOnWorkedHoursEditor());

        this.EnableMultipleInsert(this.props.enableMultipleInsert ?? true);

        this.IsResourceFieldReadOnly = ko.computed(() => {
            return !this.CanEditOtherUsersWorkedHours();
        });

        this.ResourcesDataSource = new ResourcesDataSource();
        this.ResourcesDataSource.setGetMaterialResources(this.ShowMaterialResources());
        this.ResourcesDataSource.setIncludeDisabledResources(this.ShowClosed());
        this.ResourcesDataSource.setGetMaterialResources(this.ShowMaterialResources());

        this.listeners = Array.isArray(this.props.listeners) ? this.props.listeners : [this.props.listeners];

        this.selectedJobOrderId = ko.computed(() => {
            const hour = this.HoursOnEdit();
            return hour?.getJobOrderId() ?? -1;
        });

        this.selectedTaskId = ko.computed(() => {
            const hour = this.HoursOnEdit();
            return hour?.getTaskId() ?? -1;
        });

        this.WorkDay.subscribe((date: Date) => {
            this.ResourcesDataSource.setDateValidityForServiceOrders(date);

            if (this.CanEditDate() || this.props.editFromJobOrderPanel) {
                if (this.HoursOnEdit()) {
                    const timezone = moment.tz.guess();

                    this.HoursOnEdit().setWorkDateTimezone(timezone);
                    this.HoursOnEdit().setEndDateTimezone(timezone);

                    this.getSuggestedStartTimeForActivity()
                        .then((suggestedStartTime: Date) => {
                            this.HoursOnEdit().setWorkDate(suggestedStartTime);
                            this.HoursOnEdit().setEndTime(suggestedStartTime);
                        })
                        .catch(() => {
                            this.HoursOnEdit().setWorkDate(date);
                            this.HoursOnEdit().setEndTime(date);
                            this.infoToastService.Error(
                                ProlifeSdk.TextResources.WorkedHours.CannotGetSuggestedTimeForWorkedHours
                            );
                        })
                        .finally(() => {
                            if (!this.CanEditDate()) this.HoursOnEdit().isChanged(0);
                        });
                }

                this.loadWorkedHours();
            }
        });

        this.WorkDay(
            moment(this.props.date || new Date())
                .startOf("day")
                .toDate()
        );

        this.selectedJobOrderId.subscribe((jobOrderId: number) => {
            if (!this.props.editFromJobOrderPanel && !this.props.insertPerJobOrder) return;

            this.loadWorkedHours();
            const hoursOnEdit = this.HoursOnEdit();
            const taskId = hoursOnEdit?.getRequestedTaskId();
            this.listeners.forEach((l) =>
                l.onJobOrderSelected(jobOrderId, this.getWorkDay(), this.ResourceId() || -1, taskId > 0 ? taskId : null)
            );
        });

        this.ReadOnlyActivities = ko.computed(() => {
            const array = this.MassiveInsertedActivities();
            this.BusinessManagementActivities().forEach((a) => {
                array.push(a);
            });
            return array;
        });

        this.TotalOrdinaryHours = ko.computed(() => {
            let total = 0;
            const resourceId = this.ResourceId();

            this.Activities().forEach((a: IWorkedHoursRowForList) => {
                if (!a.IsFlexibility()) total += resourceId === a.ResourceId ? a.OrdinaryHours() : 0;
            });

            return total;
        });

        this.TotalOrdinaryHours.subscribe(() => {
            this.loadServiceOrderWithBudgetData();
        });

        this.FormattedDate = ko.computed(() => {
            if (!this.WorkDay()) return "-";
            return moment(this.WorkDay()).format("dddd L");
        });

        this.HasChanges = ko.computed(() => {
            return this.IsChangedActivity();
        });

        this.HasChanges.subscribe((value: boolean) => {
            if (this.props.onEditorChanges) this.props.onEditorChanges(value);
        });

        this.hasPendingChanges = ko.computed(() => {
            const rowHasChanges = this.HoursOnEdit()?.HasChanges();

            if (this.lastConfirm !== null) return !this.lastConfirm;

            return !!rowHasChanges;
        });

        this.hasPendingChanges.subscribe((value: boolean) => {
            if (this.props.onEditorChanges) this.props.onEditorChanges(value);
        });

        this.NewActivityContainerId.subscribe((id) => {
            if (id == null) return;

            this.AddNewActivity(this.NewActivityContainerType(), this.NewActivityContainerId());
            this.NewActivityContainerType(null);
            this.NewActivityContainerId(null);
        });

        this.HoursOnEdit.subscribe((row: IWorkedHoursRowForEditing) => {
            row.showClosedWorkableElements(this.ShowClosed(), this.ViewAll() || this.ShowClosed());
            row.viewAllElements(this.ViewAll(), this.ViewAll() || this.ShowClosed());
            this.isEditFlexibility(row.isInsertFlexibility());
        });

        this.ShowClosed.subscribe((value: boolean) => {
            this.ResourcesDataSource.setIncludeDisabledResources(value);

            const hourOnEdit = this.HoursOnEdit();
            if (!hourOnEdit) return;

            hourOnEdit.showClosedWorkableElements(value, value || this.ViewAll());
            this.listeners.forEach((l) => l.onShowClosedChanged(value, value || this.ViewAll()));
        });

        this.ViewAll.subscribe((value: boolean) => {
            const hourOnEdit = this.HoursOnEdit();

            if (!hourOnEdit) return;

            hourOnEdit.viewAllElements(value, value || this.ShowClosed());
            this.listeners.forEach((l) => l.onViewAllChanged(value, value || this.ShowClosed()));
        });

        this.ShowMaterialResources.subscribe((value: boolean) => {
            this.ResourcesDataSource.setGetMaterialResources(value);
        });

        this.multipleInsertListRefreshInterceptor = ko.computed(() => {
            if (!this.HoursOnEdit() || !this.MultipleInsertEnabled()) return;

            this.HoursOnEdit().HasChanges();
            this.HoursOnEdit().SelectedJobOrderChanges();
            this.HoursOnEdit().SelectedTaskChanges();

            const hourModel = this.HoursOnEdit().getData();

            const startTime = moment(hourModel.WorkDate);

            const activities = this.ActivitiesForMultipleInsert();
            for (const activity of activities) {
                const hourModelToLoad = $.extend(true, {}, hourModel);

                const workDate = moment(activity.WorkDate())
                    .hours(startTime.hours())
                    .minutes(startTime.minutes())
                    .seconds(startTime.seconds())
                    .milliseconds(startTime.milliseconds())
                    .toDate();

                hourModelToLoad.WorkDate = workDate;

                const endWorkDate = moment(workDate)
                    .add(hourModelToLoad.Hours, "hours")
                    .add(hourModelToLoad.BreakHours || 0, "hours")
                    .toDate();

                hourModelToLoad.EndTime = endWorkDate;

                activity.loadFromModel(hourModelToLoad, []);
                activity.WorkDateTimeZone(this.SelectedTimeZone());
                activity.EndTimeTimeZone(this.SelectedTimeZone());
            }

            this.ActivitiesForMultipleInsert([]);
            this.ActivitiesForMultipleInsert(activities);
        });

        this.CanEditDate(this.props.canEditDate || false);
        this.setResource(this.props.resourceId);
        if (this.props.canEditOtherUsersWorkedHours !== null && this.props.canEditOtherUsersWorkedHours !== undefined)
            this.setCanEditOtherUsersWorkedHours(this.props.canEditOtherUsersWorkedHours);

        this.loadWorkedHours()
            .then(() => {
                if (this.props.hoursId) this.editRow(this.props.hoursId);
                else this.addEmptyActivity(this.props.taskId, this.props.jobOrderId);
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public initialize(date: Date, resourceId: number, taskId: number = null, jobOrderId: number = null): void {
        this.setWorkDay(date);
        this.setResource(resourceId);
        this.loadWorkedHours().then(() => {
            if (!jobOrderId) jobOrderId = this.getRequestedJobOrderIfEditingByJobOrderPanel();

            this.addEmptyActivity(taskId, jobOrderId);
        });

        this.loadServiceOrderWithBudgetData();
    }

    private async loadServiceOrderWithBudgetData(): Promise<void> {
        try {
            const date = moment(this.WorkDay());
            const request: IGetResourceServiceOrdersSalariesBudgetsRequest = {
                resourceId: this.ResourceId(),
                from: date.startOf("month").toDate(),
                to: date.endOf("day").toDate(),
            };
            const serviceOrderWithBudget = await this._workedHours.GetResourceServiceOrdersSalariesBudgets(request);
            this.servicesOrderCost(serviceOrderWithBudget ?? []);
        } catch (error) {
            console.log(error);
        }
    }

    public setCanEditOtherUsersWorkedHours(canEdit: boolean): void {
        this.CanEditOtherUsersWorkedHours(canEdit);
    }

    public getSelectedResourceId(): number {
        return !this.ResourceId() ? -1 : this.ResourceId();
    }

    public setSelectedResourceId(resourceId: number): void {
        this.ResourceId(resourceId == -1 ? undefined : resourceId);
    }

    public getWorkDay(): Date {
        return this.WorkDay();
    }

    public setWorkDay(day: Date): void {
        this.WorkDay(moment(day).startOf("day").toDate());
    }

    public onItemSelected(
        sender: IDataSource<number, IResourceDataSourceModel>,
        model: IResourceDataSourceModel
    ): void {
        let id = model?.id;

        if (!id) id = -1;

        const hours = this.HoursOnEdit();
        if (hours) {
            hours.setResourceId(id);

            if (this.props.insertPerJobOrder || this.props.editFromJobOrderPanel) {
                this.getSuggestedStartTimeForActivity()
                    .then((suggestedStartTime: Date) => {
                        hours.setWorkDate(suggestedStartTime);
                        hours.setEndTime(suggestedStartTime);
                    })
                    .catch(() => {
                        this.infoToastService.Error(
                            ProlifeSdk.TextResources.WorkedHours.CannotGetSuggestedTimeForWorkedHours
                        );
                    });
            }
        }

        this.ResourceName(model?.title);

        this.listeners.forEach((l) => l.onResourceSelected(id, this.getWorkDay(), this.selectedJobOrderId()));
    }

    public onItemDeselected(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sender: IDataSource<number, IResourceDataSourceModel>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        model: IResourceDataSourceModel
        // eslint-disable-next-line @typescript-eslint/no-empty-function
    ): void {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        if ((this.props.insertPerJobOrder || this.props.editFromJobOrderPanel) && sender === this.ResourcesDataSource)
            return true;

        if (!this.HoursOnEdit() || (this.HoursOnEdit() && !this.HoursOnEdit().HasChanges())) return true;

        const confirm = await this.confirmIfHasChanges();
        return confirm;
    }

    public async loadWorkedHours(): Promise<void> {
        if (this.isLoading) return;

        this.isLoading = true;

        const locked: boolean = await this.workedHoursService.GetLockStatusForMonth(
            moment(this.WorkDay()).year(),
            moment(this.WorkDay()).month() + 1
        );

        this.IsMonthLocked(locked);

        let jobOrderId: number = this.props.jobOrderId;
        if (this.props.insertPerJobOrder) jobOrderId = this.selectedJobOrderId();

        const workedHours = await this.workedHoursService.GetWorkedHoursWithApprovalStateForResource(
            this.ResourceId(),
            this.WorkDay(),
            jobOrderId,
            this.props.editFromJobOrderPanel || this.props.insertPerJobOrder
        );
        this.Activities(
            workedHours.WorkedHours.map((wh: IWorkedHoursWithApprovalStateForListWorkedHours) =>
                this.createWorkedHoursRowForList(
                    wh,
                    workedHours.HoursApprovalRows.filter((a) => a.HoursId === wh.HoursId)
                )
            )
        );

        this.isLoading = false;
    }

    /* private async loadMonthSyntheticDataForResource(): Promise<void> {
        const monthData: IMonthSyntheticData = await this.workedHoursService.CalculateMonthSyntheticDataForResource(this.ResourceId(), moment(this.WorkDay()).year(), moment(this.WorkDay()).month() + 1)
        this.MonthlyImbalance(monthData.TotalImbalance);
        this.MonthlyOvertime(monthData.TotalExtraordinaryHours);
    } */

    public async editRow(hoursId: number): Promise<void> {
        const confirm = await this.confirmIfHasChanges();

        if (!confirm) return;

        if (this.HoursOnEdit()) this.HoursOnEdit().dispose();

        this.lastConfirm = null;

        const workedHoursToEdit = await this.workedHoursService.GetWorkedHoursWithApprovalStateForEditing(hoursId);
        const hoursToEdit = (workedHoursToEdit.WorkedHours.firstOrDefault() ?? {}) as IWorkedHoursForEditing;

        const viewModelToEdit = this.createWorkedHoursRowViewModel(hoursToEdit);

        if (this.props.insertPerJobOrder || this.props.editFromJobOrderPanel) this.ResourceId(hoursToEdit.ResourceId);

        viewModelToEdit.isInsertFlexibility(workedHoursToEdit.WorkedHours.firstOrDefault().EntityType === "FLX");

        this.HoursOnEdit(viewModelToEdit);

        viewModelToEdit.HoursHasFocus(true);

        this.listeners.forEach((l) =>
            l.setPageForMobile(
                ProlifeSdk.Page_ModifyActivity,
                ProlifeSdk.TextResources.WorkedHours.ModifyActivityPageTitle
            )
        );
    }

    public async editRowFromSuggestions(suggestion: ISuggestedWorkableElement): Promise<void> {
        this.lastConfirm = null;

        const emptyModel = this.getEmptyWorkedHoursRowForEdit();

        const suggestedStartTime: Date = await this.getSuggestedStartTimeForActivity();

        emptyModel.WorkDate = suggestedStartTime;
        emptyModel.EndTime = suggestedStartTime;
        emptyModel.TaskId = suggestion.TaskId;
        emptyModel.TaskTitle = suggestion.TaskName;
        emptyModel.ActivityType = "JTK";
        emptyModel.WorkflowId = suggestion.WorkflowId;
        emptyModel.WorkflowTitle = suggestion.WorkflowName;
        emptyModel.JobOrderId = suggestion.JobOrderId;
        emptyModel.JobOrderTitle = suggestion.JobOrderName;
        emptyModel.ActivityContainerType = "JOR";
        emptyModel.Description = suggestion.Note;
        emptyModel.ResourceId = this.ResourceId();
        emptyModel.Progress = suggestion.TaskProgressAvg;
        emptyModel.RemainingWork = suggestion.TaskRemainingWork;
        emptyModel.EstimatedWork = suggestion.TaskDuration;
        emptyModel.ActivityProgressMode = suggestion.ActivitiesProgressAmountMode;
        emptyModel.Billable = suggestion.Billable;
        emptyModel.WorkDateTimezone = moment.tz.guess();
        emptyModel.EndTimeTimezone = moment.tz.guess();
        emptyModel.CanEditEstimate = suggestion.CanEditEstimate;
        emptyModel.WorkedHours = suggestion.WorkedHours;
        emptyModel.HideAdministrativeData = suggestion.HideAdministrativeData;
        emptyModel.CallRight = suggestion.CallRight;
        emptyModel.WorkPlaceId = suggestion.WorkPlace;
        emptyModel.TravelDistance = suggestion.TravelDistance;
        emptyModel.HasDefaultRolesSpecified = suggestion.HasDefaultRolesSpecified;
        emptyModel.HasDefaultWorkTimeCategoriesSpecified = suggestion.HasDefaultWorkTimeCategoriesSpecified;

        if (this.HoursOnEdit()) this.HoursOnEdit().dispose();

        const viewModelToEdit = this.createWorkedHoursRowViewModel(emptyModel);
        this.HoursOnEdit(viewModelToEdit);

        viewModelToEdit.HoursHasFocus(true);

        this.listeners.forEach((l) =>
            l.setPageForMobile(
                ProlifeSdk.Page_ModifyActivity,
                ProlifeSdk.TextResources.WorkedHours.NewActivityPageTitle
            )
        );
    }

    public setMultipleInsertMode(): void {
        if (this.MultipleInsertEnabled()) {
            this.switchMultipleInsertMode();
            return;
        }

        this.ShowMultipleInsertPopover(!this.ShowMultipleInsertPopover());
    }

    public switchMultipleInsertMode(): void {
        this.ActivitiesForMultipleInsert([]);
        this.MultipleInsertEnabled(!this.MultipleInsertEnabled());
        this.ShowMultipleInsertPopover(false);
        this.listeners.forEach((l) => l.setMultipleInsertMode(this.MultipleInsertEnabled()));
    }

    public async save(): Promise<boolean> {
        const hoursOnEdit = this.HoursOnEdit();

        if (!hoursOnEdit) return Promise.resolve(false);

        hoursOnEdit.HoursHasFocus(false);

        if (!hoursOnEdit.validate()) return Promise.resolve(false);

        if (hoursOnEdit.selectedJobOrderIsClosed()) {
            const confirm = await this.dialogsService.ConfirmAsync(
                ProlifeSdk.TextResources.WorkedHours.WorkedHoursOnClosedJobOrderMessage,
                ProlifeSdk.TextResources.WorkedHours.WorkedHoursOnClosedJobOrderCancel,
                ProlifeSdk.TextResources.WorkedHours.WorkedHoursOnClosedJobOrderConfirm
            );
            if (!confirm) return false;
        }

        let insertResult = false;

        if (this.MultipleInsertEnabled()) {
            insertResult = await this.multipleSave();
        } else {
            insertResult = await this.singleSave();
        }

        if (insertResult) {
            if (this.props.insertPerJobOrder) this.ResourceId(null);

            const jobOrderId = this.props.insertPerJobOrder
                ? hoursOnEdit.getJobOrderId()
                : this.getRequestedJobOrderIfEditingByJobOrderPanel();
            this.addEmptyActivity(null, jobOrderId);
        }
        this.loadServiceOrderWithBudgetData();
        return true;
    }

    public getRequestedJobOrderIfEditingByJobOrderPanel(): number {
        return this.props.editFromJobOrderPanel ? this.props.jobOrderId : null;
    }

    private async confirmTaskReferenceDateAdjustment(): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.WorkedHours.AdjustTaskReferenceDateMessage,
            ProlifeSdk.TextResources.WorkedHours.AdjustTaskReferenceDateCancel,
            ProlifeSdk.TextResources.WorkedHours.AdjustTaskReferenceDateConfirm
        );
    }

    private showWorkTimeCategoriesRemainingBudgetWithListDialog(data: ErrorsAndWarnings): Promise<boolean> {
        const dialog = new WorkTimeCategoriesRemainingBudgetWithListDialog({
            data: data,
            showSave: data.Errors.length === 0,
        });
        return this.dialogsService.ShowModal(dialog);
    }

    private async singleSave(): Promise<boolean> {
        const hour = this.HoursOnEdit();
        const data = hour.getData();

        const saveResult = await this.trySaveHour(data, hour);
        if (saveResult.abort) return false;

        this.infoToastService.Success(ProlifeSdk.TextResources.WorkedHours.WorkedHoursSaveSuccess);

        if (data.HoursId == 0) this.onWorkedHoursInserted(saveResult.savedHour);
        else this.onWorkedHoursUpdated(saveResult.savedHour);

        return true;
    }

    private async trySaveHour(data: IWorkedHoursForEditing, hour: IWorkedHoursRowForEditing): Promise<SaveHourResult> {
        let savedHour: IWorkedHoursForEditing | null = null;
        let abort = false;
        let saveErrors: ResponseError[] = [];
        let adjustTaskReferenceDates = false;
        let forceSavingImportedInDocumentsHours = false;
        let ignoreWarning = false;

        while (!savedHour && !abort) {
            try {
                const saveResult = await this.workedHoursService.SaveHour(
                    this.ResourceId(),
                    data,
                    adjustTaskReferenceDates,
                    forceSavingImportedInDocumentsHours,
                    ignoreWarning,
                    hour.isInsertFlexibility()
                );

                if (!saveResult.succeeded) {
                    const handleSaveErrorsResult = await this.handleSaveErrors(saveResult);
                    abort = handleSaveErrorsResult.abort;
                    adjustTaskReferenceDates = handleSaveErrorsResult.adjustTaskReferenceDates;
                    forceSavingImportedInDocumentsHours = handleSaveErrorsResult.forceSavingImportedInDocumentsHours;
                    ignoreWarning = handleSaveErrorsResult.ignoreWarning;
                    saveErrors = saveResult.errors;
                } else {
                    savedHour = saveResult.data;
                }
            } catch (e) {
                const saveError = e as ResponseData<IWorkedHoursWithApprovalStateForEditingWorkedHours>;
                if (saveError.errors) {
                    const handleSaveErrorsResult = await this.handleSaveErrors(saveError);
                    abort = handleSaveErrorsResult.abort;
                    adjustTaskReferenceDates = handleSaveErrorsResult.adjustTaskReferenceDates;
                    forceSavingImportedInDocumentsHours = handleSaveErrorsResult.forceSavingImportedInDocumentsHours;
                    ignoreWarning = handleSaveErrorsResult.ignoreWarning;
                    saveErrors = saveError.errors;
                } else {
                    console.log(e);
                    abort = true;
                }
            }
        }

        return { abort, savedHour, errors: saveErrors };
    }

    private async handleSaveErrors(
        saveResult: ResponseData<IWorkedHoursWithApprovalStateForEditingWorkedHours>
    ): Promise<HandleSaveErrorResult> {
        let abort = false;
        let adjustTaskReferenceDates = false;
        let forceSavingImportedInDocumentsHours = false;
        let ignoreWarning = false;

        const allErrors = saveResult.errors ?? [];
        const taskBlocked = allErrors.find((e) => e.code === "TaskBlocked");
        const hoursOverlapping = allErrors.find((e) => e.code === "NotBeOverlappingWithExistingHours");
        const referenceDateBefore = allErrors.find((e) => e.code === "TaskCreationReferenceDateBeforeOrSameOfDate");
        const warningOrErrorForBalance = allErrors.find((e) => e.code === "WarningOrErrorForBalance");
        const maxFlexibilityExceeded = allErrors.find((e) => e.code === "MaxFlexibilityExceeded");
        const referencedInDocuments = allErrors.find(
            (e) => e.code === "ReferencedInDocuments" || e.code === "ReferencedInDocumentsAndNotAuthorized"
        );

        if (taskBlocked) {
            this.infoToastService.Error(TextResources.WorkedHours.TaskOrWorkflowBlocked);
            abort = true;
        } else if (hoursOverlapping) {
            this.infoToastService.Error(TextResources.WorkedHours.HoursOverlappingError);
            abort = true;
        } else if (referenceDateBefore) {
            adjustTaskReferenceDates = await this.confirmTaskReferenceDateAdjustment();
            if (!adjustTaskReferenceDates) {
                abort = true;
            }
        } else if (warningOrErrorForBalance) {
            ignoreWarning = await this.showWorkTimeCategoriesRemainingBudgetWithListDialog({
                Errors: warningOrErrorForBalance.metadata?.errors ?? [],
                Warnings: warningOrErrorForBalance.metadata?.warnings ?? [],
            });
            if (!ignoreWarning) {
                abort = true;
            }
        } else if (maxFlexibilityExceeded) {
            this.infoToastService.Error(TextResources.WorkedHours.FlexibilityMaxExceeded);
            abort = true;
        } else if (referencedInDocuments) {
            abort = !this.authorizationService.isAuthorized("WorkedHours_EditOrDeleteImportedInDocumentsWorkedHours");
            referencedInDocuments.metadata = referencedInDocuments.metadata ?? { referencingDocuments: [] };

            const documents: string[] = referencedInDocuments.metadata.referencingDocuments.map((r) =>
                String.format(
                    TextResources.WorkedHours.HoursReferencingDocumentForAlertMessage,
                    r.documentLabel,
                    r.documentNumber,
                    moment(r.documentDate).format("L"),
                    r.protocolName
                )
            );

            let message = TextResources.WorkedHours.HoursReferencingDocumentMessage;
            if (referencedInDocuments.code === "ReferencedInDocumentsAndNotAuthorized")
                message = TextResources.WorkedHours.HoursReferencingDocumentAndNotAuthorizedMessage;

            message += "<br/><br/>" + documents.join("<br/>");

            if (!abort) {
                message += "<br/><br/>" + TextResources.WorkedHours.ConfirmSavingImportedInDocumentsHours;
                forceSavingImportedInDocumentsHours = await this.dialogsService.ConfirmAsync(
                    message,
                    TextResources.ProlifeSdk.Abort,
                    TextResources.ProlifeSdk.Confirm
                );

                if (!forceSavingImportedInDocumentsHours) {
                    abort = true;
                }
            } else {
                await this.dialogsService.AlertAsync(message, TextResources.WorkedHours.WorkedHours);
            }
        } else {
            abort = true;
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }

        return {
            abort,
            adjustTaskReferenceDates,
            forceSavingImportedInDocumentsHours,
            ignoreWarning,
        };
    }

    private async multipleSave(): Promise<boolean> {
        const hour = this.HoursOnEdit();
        const data = this.HoursOnEdit().getData();
        data.HoursId = 0;

        const hoursToInsert: IWorkedHoursRowForList[] = this.ActivitiesForMultipleInsert();
        hoursToInsert.sort((a, b) => moment(a.WorkDate()).valueOf() - moment(b.WorkDate()).valueOf());

        const notInsertedRows: IMultipleInsertError[] = [];

        const startTime = moment(data.WorkDate);
        const endTime = moment(data.EndTime);

        for (const hours of hoursToInsert) {
            data.WorkDate = moment(hours.WorkDate())
                .hours(startTime.hours())
                .minutes(startTime.minutes())
                .seconds(startTime.seconds())
                .milliseconds(startTime.milliseconds())
                .toDate();

            data.EndTime = moment(hours.EndTime())
                .hours(endTime.hours())
                .minutes(endTime.minutes())
                .seconds(endTime.seconds())
                .milliseconds(endTime.milliseconds())
                .toDate();

            const saveHourResult = await this.trySaveHour(data, hour);
            if (saveHourResult.abort) {
                const referenceDateBefore = saveHourResult.errors?.find(
                    (e) => e.code === "TaskCreationReferenceDateBeforeOrSameOfDate"
                );
                if (referenceDateBefore) {
                    return false;
                } else {
                    notInsertedRows.push({
                        row: hours,
                        errors: saveHourResult.errors ?? [],
                    });
                }
            } else {
                data.OriginalEstimatedWork = saveHourResult.savedHour.EstimatedWork;
                data.OriginalProgress = saveHourResult.savedHour.Progress;
            }
        }

        if (notInsertedRows.length == 0)
            this.infoToastService.Success(ProlifeSdk.TextResources.WorkedHours.WorkedHoursSaveSuccess);
        else {
            const errorsDialog = new MultipleErrorsDialog(notInsertedRows);
            errorsDialog.show();
        }

        this.onMultipleHoursInserted();
        this.switchMultipleInsertMode();

        return true;
    }

    public onMultipleHoursInserted() {
        this.loadWorkedHours();
        this.listeners.forEach((l) => l.onMultipleWorkedHoursInserted());

        if (this.props.onMultipleWorkedHoursInserted) this.props.onMultipleWorkedHoursInserted();
    }

    public onWorkedHoursInserted(workedHours: IWorkedHoursForEditing): void {
        this.loadWorkedHours();
        this.listeners.forEach((l) => l.onWorkedHoursInserted(workedHours));

        if (this.props.onWorkedHoursInserted) this.props.onWorkedHoursInserted(workedHours);
    }

    public onWorkedHoursUpdated(workedHours: IWorkedHoursForEditing): void {
        this.loadWorkedHours();
        this.listeners.forEach((l) => l.onWorkedHoursUpdated(workedHours));

        if (this.props.onWorkedHoursUpdated) this.props.onWorkedHoursUpdated(workedHours);
    }

    public onWorkedHoursDeleted(hoursId: number): void {
        let hoursToRemoveIndex = -1;
        const activities = this.Activities();

        for (let i = 0; i < activities.length; i++) {
            if (activities[i].HoursId == hoursId) {
                hoursToRemoveIndex = i;
                break;
            }
        }

        if (hoursToRemoveIndex > -1) {
            activities.splice(hoursToRemoveIndex, 1);
            this.Activities(activities);
        }

        const hoursOnEdit = this.HoursOnEdit();
        if (hoursOnEdit && hoursOnEdit.getHoursId() == hoursId) {
            const jobOrderId = this.props.insertPerJobOrder
                ? hoursOnEdit.getJobOrderId()
                : this.getRequestedJobOrderIfEditingByJobOrderPanel();
            this.addEmptyActivity(null, jobOrderId);
        }

        this.listeners.forEach((l) => l.onWorkedHoursDeleted(hoursId));

        if (this.props.onWorkedHoursDeleted) this.props.onWorkedHoursDeleted(hoursId);
    }

    public onWorkedHoursApproval(hoursId: number): void {
        this.listeners.forEach((l) => l.onWorkedHoursApproval(hoursId));
        if (this.props.onWorkedHoursApproval) this.props.onWorkedHoursApproval(hoursId);

        this.loadWorkedHours();
    }

    public onWorkedHoursMassiveApproval(): void {
        if (this.props.onMassiveWorkedHoursApproval) this.props.onMassiveWorkedHoursApproval();

        this.listeners.forEach((l) => l.onMassiveWorkedHoursApproval());
        this.loadWorkedHours();
    }

    public onDaySelectedForMultipleInsert(selectedDate: Date): void {
        const hourModel = this.HoursOnEdit().getData();

        const date = moment(selectedDate);
        const activities = this.ActivitiesForMultipleInsert();
        const startTime = moment(hourModel.WorkDate);
        const endTime = moment(hourModel.EndTime);

        hourModel.WorkDate = date
            .hours(startTime.hours())
            .minutes(startTime.minutes())
            .seconds(startTime.seconds())
            .milliseconds(startTime.milliseconds())
            .toDate();
        hourModel.EndTime = date
            .hours(endTime.hours())
            .minutes(endTime.minutes())
            .seconds(endTime.seconds())
            .milliseconds(endTime.milliseconds())
            .toDate();

        activities.push(this.createWorkedHoursRowForList(hourModel, []));
        activities.sort((a, b) => moment(a.WorkDate()).valueOf() - moment(b.WorkDate()).valueOf());
        this.ActivitiesForMultipleInsert(activities);
    }

    public onDayDeselectedForMultipleInsert(date: Date): void {
        const activities = this.ActivitiesForMultipleInsert();

        let found = false;
        let activityIndex = -1;
        activities.forEach((a, index) => {
            const activityDate = moment(a.WorkDate()).startOf("day");
            const selectedDate = moment(date).startOf("day");

            if (selectedDate.isSame(activityDate)) {
                found = true;
                activityIndex = index;
            }
        });

        if (found) this.ActivitiesForMultipleInsert.splice(activityIndex, 1);
    }

    public async newRow(checkChanges = true): Promise<void> {
        if (checkChanges) {
            const confirm = await this.confirmIfHasChanges();

            if (!confirm) return;
        }

        const hoursOnEdit = this.HoursOnEdit();

        const jobOrderId = this.props.insertPerJobOrder
            ? hoursOnEdit?.getJobOrderId()
            : this.getRequestedJobOrderIfEditingByJobOrderPanel();

        if (this.props.insertPerJobOrder || this.props.editFromJobOrderPanel) this.ResourceId(null);

        await this.addEmptyActivity(null, jobOrderId);
    }

    public async addEmptyActivity(taskId: number = null, jobOrderId: number = null): Promise<void> {
        this.lastConfirm = null;

        const model = this.getEmptyWorkedHoursRowForEdit();
        model.TaskId = taskId;
        model.JobOrderId = jobOrderId;

        if (taskId) {
            const tasks = await this.todoListService.GetTasksForUserById(null, [taskId]);
            if (tasks.length > 0) {
                const task: ITaskForTaskBoard = tasks[0];

                model.WorkedHours = task.WorkedHours;
                model.OriginalEstimatedWork = task.EstimatedDuration || 0;
                model.Progress = task.Progress || 0;
                model.OriginalProgress = task.Progress || 0;
                model.RemainingWork = task.RemainingWork || 0;
                model.ActivityProgressMode = task.ActivityProgressMode;
                model.Billable = task.ReportingType === 1;
                model.EstimatedWork = task.EstimatedDuration || 0;
                model.HideAdministrativeData = task.HideAdministrativeFieldsOnWorkedHours || false;
                model.CallRight = task.DefaultCallRight || false;
                model.TravelDistance = task.DefaultTravelDistance || 0;
                model.WorkPlaceId = task.DefaultWorkPlace;
            }
        }

        const suggestedStartTime = await this.getSuggestedStartTimeForActivity();
        model.WorkDate = suggestedStartTime;
        model.EndTime = suggestedStartTime;
        model.WorkDateTimezone = moment.tz.guess();
        model.EndTimeTimezone = moment.tz.guess();

        if (this.HoursOnEdit()) this.HoursOnEdit().dispose();

        const vm = this.createWorkedHoursRowViewModel(model);
        this.HoursOnEdit(vm);

        if (!taskId && !jobOrderId) {
            vm.HoursHasFocus(false);
            vm.JobOrderHasFocus(true);
        } else {
            vm.JobOrderHasFocus(false);
            vm.HoursHasFocus(true);
        }
    }

    public async getSuggestedStartTimeForActivity(): Promise<Date> {
        const startOfDay = moment(this.WorkDay()).startOf("day").toDate();
        const endOfDay = moment(this.WorkDay()).endOf("day").toDate();
        let suggestedStartTime = await this.workedHoursService.SuggestStartTime(
            this.ResourceId(),
            startOfDay,
            endOfDay
        );

        if (!suggestedStartTime)
            suggestedStartTime = moment(this.WorkDay()).hours(8).minutes(0).seconds(0).milliseconds(0).toDate();

        const timezone = moment.tz.guess();
        const suggestion = moment.tz(suggestedStartTime, timezone).toDate();

        return suggestion;
    }

    public isHourInEditing(hoursId: number): boolean {
        return this.HoursOnEdit() && this.HoursOnEdit().getHoursId() == hoursId;
    }

    public async showMonthSyntheticDataForResource(viewModel: _WorkedHoursEditor, event: Event): Promise<void> {
        if (!this.ResourceId()) return;

        const props: IMonthSyntheticDataPopoverProps = {
            Title: (this.ResourceName() ?? "") + " - " + moment(this.WorkDay()).format("L"),
            TotalOrdinaryHours: this.TotalOrdinaryHours(),
            MonthlyImbalance: this.MonthlyImbalance(),
            MonthlyOvertime: this.MonthlyOvertime(),
        };

        return this.dialogsService.ShowPopoverComponent(
            event.target as HTMLElement,
            new MonthSyntheticDataPopover(props),
            "right",
            null,
            "month-synthetic-data"
        );
    }

    public CreateNewTask(): void {
        const hoursOnEdit = this.HoursOnEdit();
        let jobOrderId = 0;

        if (hoursOnEdit) jobOrderId = hoursOnEdit.getJobOrderId() || 0;

        this.todoListService.ShowCreateNewTaskDialog(jobOrderId, 0);
    }

    public SwitchApprovalMenu() {
        this.ApprovalMenuOpen(!this.ApprovalMenuOpen());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public SaveModifyActivity(item: IWorkedHoursRowForList) {
        throw new Error("Method not implemented.");
    }

    public SaveChanges() {
        const activity = this.SelectedActivity();
        activity.WorkActivitiesContainerDescr(this.workSheetRowTemp().WorkActivitiesContainerDescr());
        activity.WorkActivitiesContainerType(this.workSheetRowTemp().WorkActivitiesContainerType());
        activity.WorkActivitiesContainerId(this.workSheetRowTemp().WorkActivitiesContainerId());

        activity.WorkActivityDescr(this.workSheetRowTemp().WorkActivityDescr());
        activity.WorkActivityType(this.workSheetRowTemp().WorkActivityType());
        activity.WorkActivityId(this.workSheetRowTemp().WorkActivityId());

        activity.WorkedHours(this.workSheetRowTemp().WorkedHours());
        activity.OrdinaryHours(this.workSheetRowTemp().OrdinaryHours());
        activity.OriginalOrdinaryHours(this.workSheetRowTemp().OriginalOrdinaryHours());
        activity.EstimatedWork(this.workSheetRowTemp().EstimatedWork());
        activity.CanEditEstimate(this.workSheetRowTemp().CanEditEstimate());

        activity.WillSeeDetails(this.workSheetRowTemp().WillSeeDetails());

        activity.RoleId(this.workSheetRowTemp().RoleId());

        activity.WorkTimeCategoryId(this.workSheetRowTemp().WorkTimeCategoryId());
        activity.WorkPlaceId(this.workSheetRowTemp().WorkPlaceId());
        activity.Billable(this.workSheetRowTemp().Billable());
        activity.CallRight(this.workSheetRowTemp().CallRight());
        activity.TravelDistance(this.workSheetRowTemp().TravelDistance());
        activity.Notes(this.workSheetRowTemp().Notes());

        activity.Id = this.workSheetRowTemp().Id;
        activity.ResourceId(this.workSheetRowTemp().ResourceId());
        activity.ResourceName(this.workSheetRowTemp().ResourceName());
        activity.WorkDay = this.workSheetRowTemp().WorkDay;
        activity.BreakHours(this.workSheetRowTemp().BreakHours());

        activity.OriginalProgress(this.workSheetRowTemp().OriginalProgress());
        activity.OriginalEstimatedWork(this.workSheetRowTemp().OriginalEstimatedWork());

        activity.IsDeleted(this.workSheetRowTemp().IsDeleted());
        activity.IsPreferred(this.workSheetRowTemp().IsPreferred());
        activity.IsInitializedFromPreferred(this.workSheetRowTemp().IsInitializedFromPreferred());

        activity.HasActivityContainerFieldFocus(this.workSheetRowTemp().HasActivityContainerFieldFocus());
        activity.HasActivityFieldFocus(this.workSheetRowTemp().HasActivityFieldFocus());
        activity.HasNotesFieldFocus(this.workSheetRowTemp().HasNotesFieldFocus());

        activity.IsDetailShowed(this.workSheetRowTemp().IsDetailShowed());
    }

    public SaveActivity(): void {
        this.save().then((result: boolean) => {
            if (!result) return;

            this.listeners.forEach((l) => l.goBackForMobile());
        });
    }

    public NewActivity(): void {
        this.newRow().then(() => {
            this.listeners.forEach((l) =>
                l.setPageForMobile(
                    ProlifeSdk.Page_ModifyActivity,
                    ProlifeSdk.TextResources.WorkedHours.NewActivityPageTitle
                )
            );
        });
    }

    public ModifyActivity(item: WorkSheetRow) {
        this.GoToActivity(item);
        this.workSheetRowTemp(new WorkSheetRowTemp(null, this, this.SelectedActivity()));
    }

    public GoToActivity(item: WorkSheetRow) {
        this.SelectedActivity(item);
    }

    public GoBack() {
        this.confirmIfHasChanges().then((confirm: boolean) => {
            if (!confirm) return;

            this.listeners.forEach((l) => l.goBackForMobile());
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private AddNewActivity(activityContainerType: string, activityContainerId: number) {
        throw new Error("Method not implemented.");
    }

    private createWorkedHoursRowForList(
        wh: IWorkedHoursWithApprovalStateForListWorkedHours,
        approvalRows: IWorkedHoursWithApprovalStateForListHoursApprovalRows[]
    ): IWorkedHoursRowForList {
        return new WorkedHoursRowForList(wh, approvalRows, this);
    }

    private createWorkedHoursRowViewModel(workedHoursToEdit: IWorkedHoursForEditing): IWorkedHoursRowForEditing {
        const viewModelToEdit = new WorkedHoursRowForEditing(workedHoursToEdit, this.props.insertPerJobOrder);
        viewModelToEdit.showClosedWorkableElements(this.ShowClosed(), this.ViewAll() || this.ShowClosed());
        viewModelToEdit.setMonthLockingStatus(this.IsMonthLocked());
        viewModelToEdit.HoursHasFocus(false);
        return viewModelToEdit;
    }

    public async confirmIfHasChanges(): Promise<boolean> {
        if (!this.hasPendingChanges()) return true;

        const confirm = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.WorkedHours.PendingChangesToSave,
            ProlifeSdk.TextResources.WorkedHours.PendingChangesToSaveCancel,
            ProlifeSdk.TextResources.WorkedHours.PendingChangesToSaveConfirm
        );

        this.lastConfirm = confirm ? true : null;
        return confirm;
    }

    private async setResource(resourceId) {
        const resourcesSettings: IHumanResourcesSettingsManager = this.settingsService.findSettingsManager(
            ProlifeSdk.HumanResources
        ) as IHumanResourcesSettingsManager;
        const loggedResource: IHumanResource = resourcesSettings.getHumanResourceById(resourceId);
        this.setSelectedResourceId(loggedResource?.Resource?.Id);
        this.ResourceName((loggedResource?.Resource?.Name ?? "") + " " + (loggedResource?.Resource?.Surname ?? ""));
        this.ShowPreferred(true);

        try {
            const approvalGroupsRequest: IGetResourceHoursApprovalGroupsRequest = {
                textFilter: "",
            };
            const resourceApprovalGroups = await this.usersService.GetResourceHoursApprovalGroups(
                approvalGroupsRequest
            );
            this.ResourceApprovalGroups(resourceApprovalGroups);
            const hasApprovalRights = !!resourceApprovalGroups.firstOrDefault((g) => g.CanApprove);
            this.CanApproveHours(hasApprovalRights);
        } catch (e) {
            console.log(e);
            this.CanApproveHours(false);
        }
    }

    private getEmptyWorkedHoursRowForEdit(): IWorkedHoursForEditing {
        const date = moment(this.WorkDay()).toDate();
        const resourceId = this.props.insertPerJobOrder ? null : this.ResourceId();
        const generalSettings: IJobOrderGeneralSettingsManager = this.settingsService.findSettingsManager(
            ProlifeSdk.JobOrderGeneralSettings
        ) as IJobOrderGeneralSettingsManager;

        return {
            HoursId: 0,
            WorkEventId: 0,
            EventId: 0,
            JobOrderId: null,
            JobOrderTitle: null,
            WorkDate: date,
            WorkDateTimezone: "",
            Description: null,
            ResourceId: resourceId,
            ResourceName: null,
            RoleId: undefined,
            RoleName: null,
            From: null,
            To: null,
            BreakHours: 0,
            Billable: generalSettings.getBillableFlagOnWorkedHours(),
            SalId: null,
            Billed: false,
            ProjectId: null,
            WorkItemId: null,
            TaskId: null,
            TaskTitle: null,
            WorkflowId: null,
            WorkflowTitle: null,
            ActivityContainerType: "JOR",
            ActivityType: "JTK",
            WorkPlaceId: 0,
            Hours: 0,
            EntityType: null,
            WorkTimeCategoryId: undefined,
            WorkTimeCategoryName: null,
            TravelDistance: 0,
            CallRight: false,
            BusinessManagement: false,
            IsPreviousMonthImbalance: false,
            Cost: null,
            EndTime: date,
            EndTimeTimezone: "",
            FkServiceOrder: -1,
            EstimatedWork: 0,
            WorkedHours: 0,
            ActivityProgressMode: 0,
            Progress: 0,
            RemainingWork: 0,
            OriginalProgress: 0,
            OriginalWorkedHours: 0,
            OriginalEstimatedWork: 0,
            CanEditEstimate: false,
        };
    }

    private async handleFlexibilityInsertMode(isFlexibility: boolean) {
        await this.newRow(false);
        if (isFlexibility) {
            this.HoursOnEdit()?.setJobOrderAndBillableForFlexibility();
            this.HoursOnEdit()?.setSuggestedFlexibleHours();
        }

        this.HoursOnEdit()?.isInsertFlexibility(isFlexibility);
        this.isEditFlexibility(isFlexibility);
    }

    private renderHeaderCheckboxes() {
        let editor: _WorkedHoursEditor;

        const classes = ComponentUtils.classNames("flex-container flex-fill", {
            "  ": this.props.insertPerJobOrder,
            "": !this.props.editFromJobOrderPanel && !this.props.insertPerJobOrder,
            "col-md-8": this.props.editFromJobOrderPanel,
            " ": !this.props.insertPerJobOrder,
        });

        return (
            <div className={classes} style={{ justifyContent: "", alignItems: "center", margin: "10px" }}>
                <ul className="workedhours-checkbox-list">
                    <li>
                        <div className="checker">
                            <span data-bind={{ css: { checked: editor.ShowMaterialResources } }}>
                                <input type="checkbox" data-bind={{ checkbox: editor.ShowMaterialResources }} />
                            </span>
                        </div>{" "}
                        {TextResources.WorkedHours.ShowMaterialResources}
                    </li>
                    {this.WorkedHours_InsertHoursOnClosedWorkableElements && (
                        <li>
                            <div className="checker">
                                <span data-bind={{ css: { checked: editor.ShowClosed } }}>
                                    <input type="checkbox" data-bind={{ checkbox: editor.ShowClosed }} />
                                </span>
                            </div>{" "}
                            {TextResources.WorkedHours.ShowHiddenActivitiesAndResources}
                        </li>
                    )}
                    {this.TaskBoard_CanViewAll && !this.props.editFromJobOrderPanel && (
                        <li>
                            <div className="checker">
                                <span data-bind={{ css: { checked: editor.ViewAll } }}>
                                    <input type="checkbox" data-bind={{ checkbox: editor.ViewAll }} />
                                </span>
                            </div>{" "}
                            {TextResources.WorkedHours.ViewAll}
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    renderMobileVersion() {
        return ComponentUtils.bindTo(<>{renderMobileEditor(this.HoursOnEdit)}</>, this, "vm");
    }

    renderWorkedHoursHeader() {
        let editor: _WorkedHoursEditor;
        return (
            <>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            {!this.props.insertPerJobOrder && (
                                <select2
                                    className="resource-field"
                                    value={() => "editor.ResourceId"}
                                    placeholder={ProlifeSdk.TextResources.WorkedHours.ResourceFieldPlaceholder}
                                    dataSource={() => "editor.ResourcesDataSource"}
                                    readOnly={() => "editor.IsResourceFieldReadOnly"}
                                    listener={() => "editor"}
                                ></select2>
                            )}
                            {this.props.insertPerJobOrder && (
                                <With data={this.HoursOnEdit} as="hoursOnEdit">
                                    {() => (
                                        <div>
                                            <select2
                                                className="resource-field reduced-size"
                                                allowClear={true}
                                                placeholder={"Digita per ricercare una commessa..."}
                                                value={() => "hoursOnEdit.JobOrderId"}
                                                dataSource={() => "hoursOnEdit.JobOrderDataSource"}
                                                listener={() => "hoursOnEdit"}
                                                readOnly={() => "hoursOnEdit.IsReadOnly"}
                                                tabIndex={1}
                                                hasFocus={() => "hoursOnEdit.JobOrderHasFocus"}
                                            ></select2>
                                        </div>
                                    )}
                                </With>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">{this.renderHeaderCheckboxes()}</div>
                    </div>
                </div>
                <div className="col-md-4" style={{ overflow: "auto", height: "135px" }}>
                    <WorkTimeCategoriesRemainingBudget
                        servicesOrderCost={this.servicesOrderCost}
                        hideServiceOrderNameIfOnlyOne
                    />
                </div>
                <div
                    className="col-md-4 text-right padding-right-25 right-column"
                    data-bind={{ css: { editing: editor.CanEditDate } }}
                >
                    <div className="row">
                        <div className="col-md-12 work-day">
                            <ko-bind data-bind={{ ifnot: editor.CanEditDate }}>
                                <h3
                                    data-bind={{
                                        text: editor.FormattedDate,
                                        visible: !editor.MultipleInsertEnabled(),
                                    }}
                                ></h3>
                            </ko-bind>
                            <ko-bind data-bind={{ if: editor.CanEditDate }}>
                                <input
                                    className="form-control"
                                    data-bind={{
                                        datePicker: editor.WorkDay,
                                        customDateTimeFormat: "dddd DD/MM/YYYY",
                                        visible: !editor.MultipleInsertEnabled(),
                                    }}
                                />
                            </ko-bind>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {!this.props.insertPerJobOrder && (
                                <div className="row">
                                    <h3 className="col-md-9">{TextResources.WorkedHours.TotalHoursOfTheDay}</h3>
                                    <h2 className="col-md-3 text-right">
                                        <strong data-bind={{ timeTextFromNumber: editor.TotalOrdinaryHours }}></strong>
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <WorkFlexibilityButton
                                isEditFlexibility={this.isEditFlexibility}
                                checkChanged={() => {
                                    return this.confirmIfHasChanges();
                                }}
                                resourceId={this.ResourceId}
                                onInsertFlexibilityEnabledChange={this.handleFlexibilityInsertMode.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderJobOrdersHeader() {
        let editor: _WorkedHoursEditor;
        return (
            <>
                <ko-bind data-bind={{ ifnot: editor.CanEditDate }}>
                    <div className="col-md-4 text-center">
                        <h3 data-bind={{ text: editor.FormattedDate, visible: !editor.MultipleInsertEnabled() }}></h3>
                    </div>
                </ko-bind>
                <ko-bind data-bind={{ if: editor.CanEditDate }}>
                    <div className="col-md-4 text-center">
                        <input
                            className="form-control"
                            data-bind={{
                                datePicker: editor.WorkDay,
                                customDateTimeFormat: "dddd DD/MM/YYYY",
                                visible: !editor.MultipleInsertEnabled(),
                            }}
                        />
                    </div>
                </ko-bind>
                <div className="col-md-4"></div>
                <div className="col-md-4" style={{ overflow: "auto", height: "100px" }}>
                    <WorkFlexibilityButton
                        isEditFlexibility={this.isEditFlexibility}
                        checkChanged={() => {
                            return this.confirmIfHasChanges();
                        }}
                        resourceId={this.ResourceId}
                        onInsertFlexibilityEnabledChange={this.handleFlexibilityInsertMode.bind(this)}
                    />
                </div>
            </>
        );
    }

    renderDesktopVersion() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const editor: _WorkedHoursEditor = this;

        return ComponentUtils.bindTo(
            <LayoutWithHeader className="workedhours-editor">
                <LayoutHeader className="flex-vertical">
                    <div className="row workedhours-editor-header">
                        <div className="col-md-12">
                            <div className="row row-1 flex-container">
                                {this.props.editFromJobOrderPanel && this.renderJobOrdersHeader()}
                                {!this.props.editFromJobOrderPanel && this.renderWorkedHoursHeader()}
                            </div>
                        </div>
                    </div>
                </LayoutHeader>
                <LayoutContent className="workedhours-editor-body">
                    <WorkedHoursEditorForm
                        hours={this.HoursOnEdit}
                        canInsertTask={this.TaskBoard_InsertTask}
                        canEditTask={this.TaskBoard_EditTask}
                        canEditTaskProgress={this.JobOrders_EditTaskProgress}
                        editFromJobOrderPanel={this.props.editFromJobOrderPanel}
                        insertPerJobOrder={this.props.insertPerJobOrder}
                        onSubmit={() => this.save()}
                    />

                    <WorkedHoursEditorList
                        canApproveHours={this.CanApproveHours}
                        activities={this.Activities}
                        activitiesForMultipleInsert={this.ActivitiesForMultipleInsert}
                        multipleInsertEnabled={this.MultipleInsertEnabled}
                        insertPerJobOrder={this.props.insertPerJobOrder || this.props.editFromJobOrderPanel}
                        timezone={this.SelectedTimeZone}
                        resourceApprovalGroups={this.ResourceApprovalGroups}
                        isLoading={() => this.isLoading}
                        onWorkedHoursMassiveApproval={this.onWorkedHoursMassiveApproval.bind(this)}
                    />
                </LayoutContent>
            </LayoutWithHeader>,
            this,
            "editor"
        );
    }

    public render() {
        return this.desktopService.isMobileBrowser() ? this.renderMobileVersion() : this.renderDesktopVersion();
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WorkedHoursEditor);
}
