import { IDataSourceModel } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { ITicketClosingType, ITicketsService } from "../Tickets/TicketsService";
import { ITicketClosingTypesSettingsManager } from "../Tickets/settings/TicketClosingTypesSettingsManager";

export interface ITicketClosingTypesDataSourceModel extends IDataSourceModel<number, ITicketClosingType> {

}

export class TicketClosingTypesDataSource extends BaseDataSource<ITicketClosingTypesDataSourceModel> {
    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    @LazyImportSettingManager(nameof<ITicketClosingTypesSettingsManager>())
    private TicketClosingTypesSettingsManager: ITicketClosingTypesSettingsManager;

    private showDeleted: boolean;

    setShowDeleted(showDeleted: boolean){
        this.showDeleted = showDeleted;
    }

    getTitle(currentModel: ITicketClosingTypesDataSourceModel): string {
        return "Ticket Closing Types";
    }

    async getData(currentModel: ITicketClosingTypesDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITicketClosingTypesDataSourceModel[]> {
        let types = await this.TicketClosingTypesSettingsManager.getTicketClosingTypes(textFilter, this.showDeleted, skip, count);
        return types.map(this.createModelFor, this);
    }

    async getById(currentModel: ITicketClosingTypesDataSourceModel, ids: number[]): Promise<ITicketClosingTypesDataSourceModel[]> {
        let types = await this.TicketClosingTypesSettingsManager.getTicketClosingTypesByIds(ids);
        return types.map(this.createModelFor, this);
    }

    private createModelFor(type: ITicketClosingType): ITicketClosingTypesDataSourceModel {
        return {
            id: type.Id,
            title: type.Name,
            isLeaf: true,
            isGroup: false,
            model: type
        };
    }
}