import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/03/13
 * Time: 9.58
 * To change this template use File | Settings | File Templates.
 */

export class File {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        $(element).on("change", function() {
            const accessor = valueAccessor();
            if(ko.isObservableArray(accessor)) {
                accessor(Array.from(this.files));
            }
            else {
                var file = this.files[0];
                if(ko.isObservable(accessor))
                    accessor(file);
                else if(typeof accessor === "function") {
                    accessor(Array.from(this.files));
                }
            }
        })
    }
}

ko.bindingHandlers["file"] = new File();