import jss from "jss";
import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { ITreatmentType } from "../../GDPRService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ITreatmentTypesSettingsManager } from "../TreatmentTypesSettingsManager";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";

const { classes } = jss.createStyleSheet({
    gdprTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class TreatmentType {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    public validator: IValidator<TreatmentType>;

    Id: number;
    Name: ko.Observable<string> = ko.observable();
    Deleted: ko.Observable<boolean> = ko.observable();
    IsInUse: boolean;

    constructor(treatmentType: ITreatmentType){
        this.Id = treatmentType.Id;
        this.Name(treatmentType.Name);
        this.Deleted(treatmentType.Deleted);
        this.IsInUse = treatmentType.IsInUse ? true : false;

        this.validator = this.validationService.createValidator<TreatmentType>()
        .isNotNullOrUndefinedOrWhiteSpace((d) => d.Name(), TextResources.GDPR.ErrorNoDescription)
    }
}

export interface ITreatmentTypeSettingsComponentProps {
    TreatmentTypesSettingsManager: ITreatmentTypesSettingsManager;
}

export class TreatmentTypesSettingsComponent {
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    TreatmentTypes: ko.ObservableArray<TreatmentType> = ko.observableArray();

    constructor(private props: ITreatmentTypeSettingsComponentProps){
        this.loadData();
    }

    loadData(){
        this.TreatmentTypes(this.props.TreatmentTypesSettingsManager.getTreatmentTypes(null, false).map((x) => new TreatmentType(x)));
    }

    addNewTreatmentType(){
        this.TreatmentTypes.push(new TreatmentType({
            Id: this.idGeneratorService.getNextId(),
            Deleted: false,
            Name: null
        }));
    }

    async deleteTreatmentType(item: TreatmentType){
        let result = await this.dialogsService.ConfirmAsync(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem);
        if (result) {
            if (item.Id < 0)
                this.TreatmentTypes.remove(item);
            else
                item.Deleted(true);
        }
    }

    restoreTreatmentType(item: TreatmentType){
        item.Deleted(false);
    }

    async saveAll(){
        let isValid = true;
        for (let eventType of this.TreatmentTypes()){
            if (!eventType.validator.validateAndShowInfoToast(eventType)){
                isValid = false;
                break;
            }
        }

        if (this.TreatmentTypes().length < 1){
            this.infoToastService.Error(TextResources.GDPR.ErrorZeroTreatmentTypes);
            isValid = false;
        }

        if (isValid){
            try {
                await this.props.TreatmentTypesSettingsManager.insertOrUpdateTreatmentTypes(this.TreatmentTypes().map(x => ({ 
                    Id: x.Id,
                    Name: x.Name(),
                    Deleted : x.Deleted()
                })));
            
                this.infoToastService.Success(TextResources.GDPR.AddExistingSuccess);
                this.loadData();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    createTreatmentTypeModel(item : TreatmentType) {
        return {
            id: item.Id,
            title: item.Name(),
            isLeaf: true,
            isGroup: false,
            model: item
        }
    }

    render() {
        let vm = this;
        let treatmentTypes: IDataSourceModel<number, TreatmentType>;

        return ComponentUtils.bindTo(
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-10">
                        <h1>{TextResources.GDPR.TreatmentTypes}</h1>
                    </div>
                    <div className="col-md-2 text-right" style="margin-top: 20px">
                        <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}}>
                            <i className="fa fa-save"></i> {TextResources.GDPR.Save}
                        </button>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <Table rowAs="treatmentTypes" dataSource={{array: vm.TreatmentTypes, factory: vm.createTreatmentTypeModel }} compact={true} editable={true} scrollable={true} className={classes.gdprTable}>
                        <Column title={TextResources.GDPR.Notes}>
                            <span data-bind={{if: treatmentTypes.model.IsInUse}}>
                                <i className="fa fa-exclamation-triangle" title={TextResources.GDPR.TreatmentInUseAlert}></i>
                            </span>
                            <span data-bind={{if: treatmentTypes.model.Deleted}}>
                                <i className="fa fa-trash-o" title={TextResources.GDPR.TreatmentTypeIsDeletedAlert}></i>
                            </span>
                        </Column>
                        <Column title={TextResources.GDPR.Description} cssClasses="noMargin">
                            <text-input value={() => "treatmentTypes.model.Name"} readOnly={() => "(treatmentTypes.model.Deleted() || treatmentTypes.model.IsInUse)"} placeholder={TextResources.GDPR.DescriptionPlaceholder}/>
                        </Column>
                        <Column title={TextResources.GDPR.Actions}>
                            <ColumnHeader>
                                <button className="btn btn-primary btn-xs" data-bind={{click: vm.addNewTreatmentType.bind(vm)}}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <div data-bind={{ifnot: treatmentTypes.model.Deleted}}>
                                    <button className="btn btn-danger btn-xs" data-bind={{click: vm.deleteTreatmentType.bind(vm, treatmentTypes.model)}}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                                <div data-bind={{if: treatmentTypes.model.Deleted}}>
                                    <button className="btn btn-success btn-xs" data-bind={{click: vm.restoreTreatmentType.bind(vm, treatmentTypes.model)}}>
                                        <i className="fa fa-recycle"></i>
                                    </button>
                                </div>
                            </ColumnBody>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>, this, "vm");
    }
}   