import * as ko from "knockout";
import { useService } from "../../../Core/DependencyInjection";
import { IFileOrFolder } from "../../../ProlifeSdk/interfaces/files/IFileOrFolder";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { MergedReportComponentConstructor, ReportComponentConstructor } from "./ReportComponent";

type BackgroundSize = "contain" | "cover" | "auto";

export interface ReportComponentWithBackground {
    backgroundColor: ko.Observable<string>;
    backgroundImage: ko.Observable<IFileOrFolder>;
    backgroundImageUrl: ko.Computed<string>;
    backgroundImageCSSUrl: ko.Computed<string>;
    backgroundSize: ko.Observable<BackgroundSize>;
}

export type ReportComponentWithBackgroundProps = { backgroundColor?: string, backgroundImage?: IFileOrFolder, backgroundSize?: BackgroundSize }

export function HasBackground<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithBackground, ReportComponentWithBackgroundProps> {
    return class ReportComponentWithBackground extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "Background"];

            const { backgroundColor, backgroundImage, backgroundSize } = args[0] as ReportComponentWithBackgroundProps;
            this.backgroundColor(backgroundColor ?? "rgba(0,0,0,0)");
            this.backgroundImage(backgroundImage);
            this.backgroundSize(backgroundSize ?? "auto");

            const fileRep = useService<IFileRepositoryService>(nameof<IFileRepositoryService>());

            this.backgroundImageUrl = ko.computed(() => {
                const bgImage = this.backgroundImage();
                if(!bgImage) {
                    return undefined;
                } else {
                    return fileRep.getDownloadUrl(bgImage, true);
                }
            })

            this.backgroundImageCSSUrl = ko.computed(() => {
                const bgImage = this.backgroundImageUrl();
                if(!bgImage) {
                    return undefined;
                } else {
                    return "url(\"" + bgImage + "\")";
                }
            })
        }

        getData() {
            const data = super.getData();

            return {
                ...data,
                backgroundColor: this.backgroundColor(),
                backgroundImage: this.backgroundImage(),
                backgroundSize: this.backgroundSize()
            }
        }

        backgroundColor: ko.Observable<string> = ko.observable();
        backgroundImage: ko.Observable<IFileOrFolder> = ko.observable();
        backgroundImageUrl: ko.Computed<string>;
        backgroundImageCSSUrl: ko.Computed<string>;
        backgroundSize: ko.Observable<BackgroundSize> = ko.observable();
    }
}