import * as ko from "knockout";
/**
 * Created by d.collantoni on 30/05/2019.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { WarehouseEntityCodeGeneratorEditingViewModel } from "./ui/WarehouseEntityCodeGeneratorEditingViewModel";
import { EntityCodeGeneratorSettingsManager } from "../../../ProlifeSdk/prolifesdk/settings/EntityCodeGeneratorSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WarehouseEntityCodeGeneratorSettingsManager extends EntityCodeGeneratorSettingsManager
{
    constructor(serviceLocator : IServiceLocator) {
        super(serviceLocator, ProlifeSdk.WarehouseEntityTypeCode);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Warehouse.WarehouseSettingsGroup);
    }

    getName(): string {
        return ProlifeSdk.WarehouseCodeGeneratorType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Warehouse.TypeCodes;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return new WarehouseEntityCodeGeneratorEditingViewModel(this.serviceLocator, this);
    }
}
