import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 19/05/2017
 * Time: 12:53
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { TeamSelector } from "./TeamSelector";
import { TeamsGantt } from "./TeamsGantt";
import { TeamsManager, ITeamsManagerProvider, ITeamAllocationViewModel } from "./TeamsManager";
import { TeamsManagerProvider } from "./TeamsManagerProvider";
import { TeamViewModel } from "./TeamViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICartsManager } from "../../interfaces/ICartsManager";

export class TeamManagerDialog implements IDialog {
    templateName = "teamManager";
    templateUrl = "allocations/templates";
    title: string = ProlifeSdk.TextResources.Allocations.TeamManager;
    modal: {
        close: (result?: any) => void;
    };

    TeamsManagerProvider : ITeamsManagerProvider;
    TeamSelector : TeamSelector;
    Team : ko.Observable<any> = ko.observable();
    public TeamsGantt : TeamsGantt;

    private teamsManager : TeamsManager;

    private infoToastService: IInfoToastService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private cartsManager : ICartsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.TeamsManagerProvider = new TeamsManagerProvider();
        this.TeamSelector = new TeamSelector(this.TeamsManagerProvider);
        this.TeamsGantt = new TeamsGantt(this.TeamsManagerProvider, this.Team);

        this.TeamsManagerProvider.OnTeamSelected.Add((team : TeamViewModel) => this.Team(team));
        this.TeamsManagerProvider.OnTeamDeleted.Add((team : TeamViewModel) => {
            if(this.Team() == team)
                this.Team(undefined);
        });

        let lastSelectedTeamIndex = -1;
        this.TeamsManagerProvider.OnBeforeTeamsManagerChanged.Add(() => {
            if(!this.TeamsManagerProvider.HasTeamsManager())
                return;

            lastSelectedTeamIndex = this.TeamsManagerProvider.GetTeams().indexOf(this.Team());
        });
        this.TeamsManagerProvider.OnAfterTeamsManagerChanged.Add(() => {
            if(!this.TeamsManagerProvider.HasTeamsManager())
                return;

            if(lastSelectedTeamIndex != -1)
                this.Team(this.TeamsManagerProvider.GetTeams()[lastSelectedTeamIndex]);
        });

        const resourceInterceptor = ko.computed(() => {
            const team : TeamViewModel = this.Team();
            if(!team) return;

            const allocation : ITeamAllocationViewModel = team.SelectedAllocation();
            if(!allocation) return;

            this.TeamsGantt.Gantt.SearchFilter(allocation.ResourceName());
        });

        this.teamsManager = new TeamsManager(this.serviceLocator, cartsManager);
        this.TeamsManagerProvider.SetTeamsManger(this.teamsManager);
        this.TeamsManagerProvider.Load();
    }

    close(): void {
        if(this.teamsManager.ValuesHaveChanged()) {
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ChangesNotSaved, ProlifeSdk.TextResources.Allocations.ChangesNotSavedCancel, ProlifeSdk.TextResources.Allocations.ChangesNotSavedConfirm, (result : boolean) => {
                if(result)
                    this.modal.close(false);
            });
            return;
        }

        this.modal.close(false);
    }

    action(): void {
        this.TeamsManagerProvider.SaveChanges()
            .then(() => {
                this.modal.close(true);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.SaveTeamsError);
            });
    }

    public ShowDialog() : Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(this, "fullscreen minheight800 minwidth1280 no-scrollable-body");
    }

    public NewTeam() {
        const newTeam = this.TeamsManagerProvider.addNewTeam();
        this.TeamsManagerProvider.selectTeam(newTeam);
        //this.Team(newTeam);
    }
}