import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 29/07/13
 * Time: 18.34
 * To change this template use File | Settings | File Templates.
 */

import { ExpenseWithProjects } from "./ExpenseWithProjects";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IExpenseService, IGetExpensesWithProjectsRequest, IExpensesWithProjects } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class ExpensesViewModel
{
    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    
    public Filter : ko.Observable<string> = ko.observable("");
    public Expenses : ko.ObservableArray<ExpenseWithProjects> = ko.observableArray([]);

    private isLoading : ko.Observable<boolean> = ko.observable(false);
    private loadedCount : number = 0;
    private numberOfItemToLoad : number = 25;
    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor()
    {
        this.LoadPart();

        this.Filter.subscribe(() => {
            if (this.lastTimeout)
                clearTimeout(this.lastTimeout);
            
            this.lastTimeout = setTimeout(() => {
                this.Refresh();
            }, 1000);
        });
    }

    public async EditExpense(expense: ExpenseWithProjects): Promise<void>
    {
        await this.ShowExpenseEditor(expense?.Id);
    }

    public async DeleteExpense(expense: ExpenseWithProjects): Promise<void>
    {
        let confirm = await this.dialogsService.ConfirmAsync(TextResources.Expenses.DeleteExpenseRequest, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes)
        
        if (!confirm)
            return;
        
        await this.expenseService.DeleteExpense(expense.Id, this.userInfoService.getIdUser(), this.authorizationsService.isAuthorized("Expense_ExpensesAdministrativeEdit"), this.authorizationsService.isAuthorized("Expense_EditResourceExpenses"));
        await this.Refresh();
    }

    public GoHome()
    {
        location.href = "#/";
    }

    public async Refresh(): Promise<void> {
        this.loadedCount = 0;
        this.Expenses([]);

        await this.LoadPart();
    }

    public async LoadPart(): Promise<void>
    {
        if (this.isLoading())
            return;

        this.isLoading(true);
        
        let request: IGetExpensesWithProjectsRequest = {
            textFilter: this.Filter(),
            skip: this.loadedCount,
            count: this.numberOfItemToLoad
        };

        try {
            let expenses = await this.expenseService.GetExpensesWithProjects(request);
            this.OnExpensesLoaded(expenses);
        } catch(e) {
            console.error(e);
        } finally {
            this.isLoading(false);
        }
    }

    private OnExpensesLoaded(expenses: IExpensesWithProjects): void
    {
        for (let expense of expenses.Expenses) {
            let matches = this.Expenses().filter((e : ExpenseWithProjects) => e.Id == expense.Id);
            if (matches.length === 0) {
                let projects = expenses.Projects.filter(p => p.FkExpense === expense.Id);
                this.Expenses.push(new ExpenseWithProjects(expense, projects));
            }
        }

        this.loadedCount += expenses.Expenses.length;
    }

    public async CreateNew(): Promise<void>
    {
        await this.ShowExpenseEditor();
    }

    public dispose()
    {
        //To be implemented
    }

    private async ShowExpenseEditor(expenseId: number = null): Promise<void> {
        let modifiedExpense = await this.expenseService.ShowExpenseEditor(expenseId);

        if (!modifiedExpense)
            return;

        await this.Refresh();
    }
}