import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ParamArray, ComponentParamArray } from "../../Core/utils/ComponentUtils";
import { INavTabPage } from "../../Components/NavTabComponent";

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "system-header-popover": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ISystemHeaderPopoverParams {
    Pages : ParamArray<INavTabPage>;
}

export class SystemHeaderPopover {
    Pages : ComponentParamArray<INavTabPage>;

    constructor(params : ISystemHeaderPopoverParams) {
        this.Pages = ComponentUtils.parseParameterArray(params.Pages, []);
    }
}

ko.components.register("system-header-popover", {
    viewModel: {
        createViewModel: (params: ISystemHeaderPopoverParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new SystemHeaderPopover(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <nav-tab class="margin-bottom-0" width="350px" height="400px" params="Pages: Pages"></nav-tab>
            ]);
            
            return vm;
        },
    },
    template: []
});