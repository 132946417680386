import * as ko from "knockout";
import { WorkTimeCategories } from "./areas/WorkTimeCategories";
import { CallRightTypes } from "./areas/CallRightTypes";
import { OperationalUnits } from "./areas/OperationalUnits";
import { ResourcesRoles } from "./areas/ResourcesRoles";
import { Resources } from "./areas/Resources";
import { ResourcesGroups } from "./areas/ResourcesGroups";
import { ResourceManagerArea } from "./areas/ResourceManagerArea";
import { IResourcesManagerViewModel } from "../../interfaces/IResourcesManagerViewModel";

export class ResourcesManagerViewModel implements IResourcesManagerViewModel
{
    templateUrl = "resourcesmanager/templates";

    public selectedArea : ko.Observable<ResourceManagerArea> = ko.observable(null);
    public areas : ko.ObservableArray<ResourceManagerArea> = ko.observableArray([]);

    constructor(startSectionIndex : number)
    {
        this.areas.push(new Resources(this));
        this.areas.push(new ResourcesGroups(this));
        this.areas.push(new ResourcesRoles(this));
        this.areas.push(new OperationalUnits(this));
        this.areas.push(new CallRightTypes(this));
        this.areas.push(new WorkTimeCategories(this));

        this.areas()[startSectionIndex].show();
    }

    goHome()
    {
        location.href = "#/";
    }

    dispose()
    {
    }
}
