import * as ko from "knockout";
﻿import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IWarehouseLoadReasonsForShipmentSettingsManager } from "../Warehouse/warehouse/settings/WarehouseLoadReasonsForShipmentSettingsManager";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IWarehouseLoadReasonForShipment } from "../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { BaseDataSource } from "./BaseDataSource";

export interface IWarehouseLoadReasonForShipmentModel extends IDataSourceModel<number, IWarehouseLoadReasonForShipment> {

}

export class WarehouseLoadReasonsForShipmentDataSource extends BaseDataSource<IWarehouseLoadReasonForShipmentModel> {
    private reasonsToBeShowedEvenIfDeleted: number[];
    private forInventoryAdjustment: boolean;
    private showDeleted: boolean;
    
    @LazyImportSettingManager(ProlifeSdk.WarehouseLoadReasonsForShipmentSettingsManager)
    private reasonsForShipmentManager: IWarehouseLoadReasonsForShipmentSettingsManager;

    constructor() {
        super();

        this.reasonsToBeShowedEvenIfDeleted = [];
        this.showDeleted = false;
        this.forInventoryAdjustment = false;
    }
    
    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setShowDeleted(value: boolean): void {
        this.showDeleted = value;
    }

    public setGetForInventoryAdjustment(value: boolean) {
        this.forInventoryAdjustment = value;
    }

    public setReasonsToBeShowedEvenIfDeleted(ids: number[]): void {
        this.reasonsToBeShowedEvenIfDeleted = ids;
    }
    
    public getTitle(currentModel: IWarehouseLoadReasonForShipmentModel): string {
        return ProlifeSdk.TextResources.Warehouse.ReasonsForShipment;
    }
    
    public async getData(currentModel: IWarehouseLoadReasonForShipmentModel, textFilter: string, skip: number, count: number): Promise<IWarehouseLoadReasonForShipmentModel[]> {
        return this.reasonsForShipmentManager
            .getReasonsForShipment(true)
            .filter(r => this.textFilterMatch(r, textFilter) && this.forInventoryAdjustmentMatch(r) && this.showDeletedMatch(r))
            .slice(skip, count)
            .map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IWarehouseLoadReasonForShipmentModel, ids: number[]): Promise<IWarehouseLoadReasonForShipmentModel[]> {
        return this.reasonsForShipmentManager
            .getReasonsForShipment(true)
            .filter(r => ids.indexOf(r.Id) >= 0)
            .map(this.createModelFor, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
    
    public isGroupedData(currentModel: IWarehouseLoadReasonForShipmentModel, textFilter: string): boolean {
        return false;
    }

    public areEqual(a: IWarehouseLoadReasonForShipmentModel, b: IWarehouseLoadReasonForShipmentModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(reason: IWarehouseLoadReasonForShipment): IWarehouseLoadReasonForShipmentModel {
        return {
            id: reason.Id,
            isGroup: false,
            isLeaf: true,
            title: reason.Description,
            model: reason
        };
    }

    private textFilterMatch(reason: IWarehouseLoadReasonForShipment, textFilter: string): boolean {
        let words = (textFilter || "").toLowerCase().trim().split("");
        return words.filter(w => (reason.Description || "").toLowerCase().indexOf(w) >= 0).length === words.length;
    }

    private forInventoryAdjustmentMatch(reason: IWarehouseLoadReasonForShipment): boolean {
        return !this.forInventoryAdjustment || !reason.UpdateArticlesMeanCosts;
    }

    private showDeletedMatch(reason: IWarehouseLoadReasonForShipment): boolean {
        return this.showDeleted || this.reasonsToBeShowedEvenIfDeleted.indexOf(reason.Id) >= 0 || !reason.Deleted;
    }
}