import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { DiscountCatalogRowMode, IDiscountsCatalogsService, IDiscountCatalog, IDiscountCatalogRow } from "../../../../DiscountsCatalogsService";
import { DiscountsUtilities } from "../../Utilities/DiscountsUtilities";
import { ManufacturersDataSource } from "../../../../../DataSources/ManufacturersDataSource";
import { DiscountFamiliesDataSource } from "../../../../../DataSources/DiscountFamiliesDataSource";
import { StatisticsFamiliesDataSource } from "../../../../../DataSources/StatisticsFamiliesDataSource";
import { ArticlesDataSource } from "../../../../../DataSources/ArticlesDataSource";
import { DetectClassChanges, DetectChanges } from "../../../../../Core/ChangeDetection";
import { DiscountsDataSource, IDiscountsDataSourceModel } from "../../../../../DataSources/DiscountsDataSource";
import { SuppliersDataSource } from "../../../../../DataSources/SuppliersDataSource";
import { DiscountCatalogSolutionDataSource } from "../../../../../DataSources/DiscountCatalogSolutionDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IDetectChanges } from "../../../../../Core/interfaces/IDetectChanges";

@DetectClassChanges
export class DiscountCatalogEditDialog implements IDialog, IDataSourceListener, IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable(0);
    
    templateName: string = "discount-catalog-edit-dialog";
    templateUrl: string = "warehouse/templates/discounts";
    title: string;
    modal: { close: (result?: any) => void; };

    Id: ko.Observable<number> = ko.observable();

    @DetectChanges
    Name : ko.Observable<string> = ko.observable();
    
    @DetectChanges
    Description : ko.Observable<string> = ko.observable();
    
    @DetectChanges
    StartDate : ko.Observable<Date> = ko.observable();
    
    @DetectChanges
    EndDate : ko.Observable<Date> = ko.observable();
    
    @DetectChanges
    RevisionDate : ko.Observable<Date> = ko.observable();

    @DetectChanges
    ForCustomers : ko.Observable<boolean> = ko.observable();

    isNew : ko.Observable<boolean> = ko.observable(false);

    public ManufacturerId : ko.Observable<number> = ko.observable();
    public ManufacturersDataSource : ManufacturersDataSource = new ManufacturersDataSource();

    public DiscountFamilyId : ko.Observable<number> = ko.observable();
    public DiscountFamiliesDataSource : DiscountFamiliesDataSource = new DiscountFamiliesDataSource();

    public StatisticFamilyId : ko.Observable<number> = ko.observable();
    public StatisticsFamiliesDataSource : StatisticsFamiliesDataSource = new StatisticsFamiliesDataSource();

    public ArticleFilter : ko.Observable<string> = ko.observable();
    public ArticlesDataSource : ArticlesDataSource = new ArticlesDataSource();

    public DiscountMode : ko.Observable<DiscountCatalogRowMode> = ko.observable();

    public FilterExpanded : ko.Observable<boolean> = ko.observable(false);
    public FilterEnabled : ko.Observable<boolean> = ko.observable(false);

    DiscountsDataSource : DiscountsDataSource = new  DiscountsDataSource();
    SelectedDiscountRow : ko.Observable<BaseDiscountCatalogRow> = ko.observable();

    TestingDiscounts : ko.Observable<boolean> = ko.observable(false);
    TestingDiscountsDataSource : DiscountCatalogSolutionDataSource = new DiscountCatalogSolutionDataSource();
    TestingDiscountsFilter : ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService! : IDialogsService;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService! : IDiscountsCatalogsService;

    constructor(private catalog? : IDiscountCatalog) {
        this.title = ProlifeSdk.TextResources.Warehouse.EditingDiscountCatalog;

        if(!catalog) {
            this.title = ProlifeSdk.TextResources.Warehouse.NewDiscountCatalog;

            this.isNew(true);
            this.catalog = {
                Id: -1,
                Name: "",
                Description: "",
                StartValidity: new Date(),
                ForCustomers: true
            }
        }
        
        this.Id(this.catalog.Id);
        this.Name(this.catalog.Name);
        this.Description(this.catalog.Description);
        this.StartDate(this.catalog.StartValidity);
        this.EndDate(this.catalog.EndValidity);
        this.RevisionDate(this.catalog.RevisionDate);
        this.ForCustomers(this.catalog.ForCustomers);
        this.DiscountsDataSource.setDiscountCatalogId(this.catalog.Id);
        
        this.SelectedDiscountRow(new BaseDiscountCatalogRow(this));

        this.isChanged(this.isNew() ? 1 : 0);
    }

    dispose(): void {
    }

    close(): void {
        this.modal.close(false);
    }    

    async action(): Promise<void> {
        await this.Save();
        this.modal.close(true);
    }

    ShowModal() : Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(this, "fullscreen");
    }

    onItemSelected(sender: IDataSource, model: IDiscountsDataSourceModel): void {
        if(model)
            this.SelectedDiscountRow(new BaseDiscountCatalogRow(this, model.model));
        else
            this.SelectedDiscountRow(null);
    }

    onItemDeselected(sender: IDataSource, model: IDiscountsDataSourceModel): void {
        
    }

    applyFilter() {
        this.FilterEnabled(true);
        this.doApplyFilter();
    }

    private doApplyFilter() {
        this.DiscountsDataSource.setFilter({
            ManufacturerId: this.ManufacturerId(),
            DiscountFamilyId: this.DiscountFamilyId(),
            StatisticFamilyId: this.StatisticFamilyId(),
            ArticleFilter: this.ArticleFilter(),
            DiscountMode: this.DiscountMode()
        });
        this.DiscountsDataSource.refresh();
    }

    resetFilter() {
        this.FilterEnabled(false);
        this.ManufacturerId(null);
        this.DiscountFamilyId(null);
        this.StatisticFamilyId(null);
        this.ArticleFilter(null);
        this.DiscountMode(null);

        this.doApplyFilter();
    }

    public async newDiscountDetail() : Promise<void> {
        let result : boolean = true;
        if(this.SelectedDiscountRow() && this.SelectedDiscountRow().isChanged() > 0) {
            result = await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse! Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");           
        }

        if(result) {
            this.SelectedDiscountRow(new BaseDiscountCatalogRow(this));
            this.DiscountsDataSource.select();
        }
    }

    async canSelectItem(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): Promise<boolean> {
        if(this.SelectedDiscountRow() && this.SelectedDiscountRow().isChanged() > 0) {
            return await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse! Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");           
        }
        return true;
    }

    async Save(saveEditedRow : boolean = true) : Promise<void> {
        let newCatalog = await this.discountsCatalogsService.InsertOrUpdateDiscountCatalog(
            [{
                Id: this.Id(),
                Name: this.Name(),
                Description: this.Description(),
                StartValidity: this.StartDate(),
                EndValidity: this.EndDate(),
                RevisionDate: this.RevisionDate(),
                ForCustomers: this.ForCustomers()
            }]
        );

        this.catalog = newCatalog;
        this.Id(this.catalog.Id);
        this.isNew(false);
        this.DiscountsDataSource.setDiscountCatalogId(this.catalog.Id);

        if(saveEditedRow && this.SelectedDiscountRow() && this.SelectedDiscountRow().isChanged() > 0) {
            this.SelectedDiscountRow().Save();
        }
    }

    onRowChanged() {
        this.DiscountsDataSource.refresh();
    }

    async toggleTestingMode() : Promise<void> {
        this.TestingDiscounts(!this.TestingDiscounts());
        
        if(this.TestingDiscounts()) {
            this.TestingDiscountsDataSource.setCatalogId(this.catalog.Id);
            this.TestingDiscountsDataSource.refresh();
        }
    }
}

@DetectClassChanges
class BaseDiscountCatalogRow implements IDetectChanges {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService! : IDialogsService;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService! : IDiscountsCatalogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService! : IInfoToastService;

    public IsNew : ko.Observable<boolean> = ko.observable();
    public Deleted : ko.Observable<boolean> = ko.observable();

    @DetectChanges
    public ManufacturerId : ko.Observable<number> = ko.observable();
    public ManufacturersDataSource : ManufacturersDataSource = new ManufacturersDataSource();

    @DetectChanges
    public DiscountFamilyId : ko.Observable<number> = ko.observable();
    public DiscountFamiliesDataSource : DiscountFamiliesDataSource = new DiscountFamiliesDataSource();

    @DetectChanges
    public StatisticFamilyId : ko.Observable<number> = ko.observable();
    public StatisticsFamiliesDataSource : StatisticsFamiliesDataSource = new StatisticsFamiliesDataSource();

    @DetectChanges
    public ArticleFilter : ko.Observable<string> = ko.observable();
    public ArticlesDataSource : ArticlesDataSource = new ArticlesDataSource();

    @DetectChanges
    public Discount0 : ko.Observable<number> = ko.observable();
    @DetectChanges
    public Discount1 : ko.Observable<number> = ko.observable();
    @DetectChanges
    public Discount2 : ko.Observable<number> = ko.observable();
    @DetectChanges
    public Discount3 : ko.Observable<number> = ko.observable();
    @DetectChanges
    public Discount4 : ko.Observable<number> = ko.observable();

    @DetectChanges
    public DiscountMode : ko.Observable<DiscountCatalogRowMode> = ko.observable(0);
    public TotalDiscount : ko.Computed<number>;

    @DetectChanges
    public ExplicitPrice : ko.Observable<number> = ko.observable();

    @DetectChanges
    public SupplierId : ko.Observable<number> = ko.observable();
    public SuppliersDataSource : SuppliersDataSource = new SuppliersDataSource()

    public NeedsSupplier : ko.Computed<boolean>;
    public HasExplicitPrice : ko.Computed<boolean>;

    isChanged: ko.Observable<number> = ko.observable(0);

    dispose(): void {
        
    }

    constructor(private catalog: DiscountCatalogEditDialog, protected discount? : IDiscountCatalogRow) {
        this.IsNew(!discount || !discount.Id);

        this.NeedsSupplier = ko.computed(() => {
            return DiscountCatalogRowMode.requiresSupplier(this.DiscountMode());
        });

        this.HasExplicitPrice = ko.computed(() => {
            return this.ExplicitPrice() > 0;
        });

        this.TotalDiscount = ko.computed(() => {
            return DiscountsUtilities.calculateDiscountFromPercentages(
                this.Discount0(), 
                this.Discount1(),
                this.Discount2(),
                this.Discount3(),
                this.Discount4()
            ) * DiscountCatalogRowMode.getDiscountSign(this.DiscountMode())
        });

        if(discount) {
            this.ManufacturerId(discount.FkManufacturer);
            this.DiscountFamilyId(discount.FkDiscountFamily);
            this.StatisticFamilyId(discount.FkStatisticFamily);
            this.ArticleFilter(discount.ArticleFilter);

            this.Discount0(discount.Discount0);
            this.Discount1(discount.Discount1);
            this.Discount2(discount.Discount2);
            this.Discount3(discount.Discount3);
            this.Discount4(discount.Discount4);
            this.DiscountMode(discount.Mode);
            this.ExplicitPrice(discount.ExplicitPrice);

            this.SupplierId(discount.FkSupplier);
        }

        this.isChanged(0);
    }

    public async Save() : Promise<void> {
        if(this.catalog.isNew()) {
            await this.catalog.Save(false);
        }

        return this.discountsCatalogsService.InsertOrUpdateDiscountCatalogRow(
            [{
                Id: this.discount ? this.discount.Id : 0,
                FkDiscountCatalog: this.catalog.Id(),
                FkManufacturer: this.ManufacturerId(),
                FkDiscountFamily: this.DiscountFamilyId(),
                FkStatisticFamily: this.StatisticFamilyId(),
                ArticleFilter: this.ArticleFilter(),
                
                Discount0 : this.Discount0(),
                Discount1 : this.Discount1(),
                Discount2 : this.Discount2(),
                Discount3 : this.Discount3(),
                Discount4 : this.Discount4(),
                TotalDiscount: this.TotalDiscount(),

                ExplicitPrice : this.ExplicitPrice(),

                Mode: this.DiscountMode(),
                FkSupplier: this.SupplierId()
            }]
        ).then((newRow) => {
            this.discount = newRow;
            this.isChanged(0);
            this.IsNew(false);
            this.infoToastService.Success("Scontistica salvata con successo!");
            this.catalog.onRowChanged();
        }, () => {
            this.infoToastService.Error("Impossibile inserire la riga perchè esiste già una riga con lo stesso filtro in questa scontistica!");
        });
    }

    public async Remove() : Promise<void> {
        if(!await this.dialogsService.ConfirmAsync("Sei sicuro di voler eliminare questa scontistica?", "Annulla", "Elimina"))
            return;

        if(this.discount) {
            await this.discountsCatalogsService.DeleteDiscountCatalogRow(this.discount.Id);
            this.infoToastService.Success("Scontistica eliminata con successo!");
            this.catalog.onRowChanged();
        }
    }
}