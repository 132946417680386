import { IFullTicketReplies } from "../Tickets/TicketsService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export interface ITicketRepliesSidebarDataSourceModel extends IDataSourceModel<number, IFullTicketReplies> {

}

export class TicketRepliesSidebarDataSource extends BaseDataSource<ITicketRepliesSidebarDataSourceModel> {

    private replies: IFullTicketReplies[] = [];

    constructor(){
        super();
    }

    setReplies(items: IFullTicketReplies[]){
        this.replies = items;
    }

    getTitle(currentModel: ITicketRepliesSidebarDataSourceModel): string {
        return "Replies";
    }

    async getData(currentModel: ITicketRepliesSidebarDataSourceModel, textFilter: string, skip: number = 0, count: number = 100): Promise<ITicketRepliesSidebarDataSourceModel[]> {
        return this.replies.filter(x => this.matchTextFilter(x, textFilter))
                        .slice(skip, skip + count)
                        .map(x => this.createModelFor(x));
    }

    async getById(currentModel: ITicketRepliesSidebarDataSourceModel, ids: (string | number)[]): Promise<ITicketRepliesSidebarDataSourceModel[]> {
        return this.replies.filter(x => ids.any(i => x.Id == i))
                        .map(x => this.createModelFor(x));
    }

    private matchTextFilter(item: IFullTicketReplies, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (item.Text.toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }

    private createModelFor(reply : IFullTicketReplies) : ITicketRepliesSidebarDataSourceModel {
        return {
            id: reply.Id,
            title: reply.CreationUserFullName,
            icon: reply.IsPrivate 
                ? {
                icon: "icon-note",
                background: "#555555",
                foreground: "#FFFFFF"
            }
            : {
                icon: "icon-speech",
                background: "#4b8df8",
                foreground: "#FFFFFF"
            },
            subTitle: $(reply.Text).text().substring(0, 255) + ($(reply.Text).text().length > 255 ? "..." : ""),
            isLeaf: true,
            isGroup: false,
            model: reply
        }
    }
}