import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/10/2017
 * Time: 15:56
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { CartsStatesEditingViewModel } from "./ui/CartsStatesEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { ICartsStatesSettingManager } from "../../../../ProlifeSdk/interfaces/allocations/ICartsStatesSettingManager";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ICartStatus, ICartLogicalStatus } from "../../../../ProlifeSdk/interfaces/allocations/ICart";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";

export class CartsStatesSettingManager implements ICartsStatesSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private cartStatus: ICartStatus[] = [];
    private cartLogicalStatus: ICartLogicalStatus[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsSrevice: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsSrevice.registerSettingsManager(this, ProlifeSdk.TextResources.Allocations.Allocations);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);

        this.cartLogicalStatus.push(<ICartLogicalStatus> { Id: 1, Description: "Aperto"});
        this.cartLogicalStatus.push(<ICartLogicalStatus> { Id: 2, Description: "Chiuso"});
    }

    load(): Promise<ICartStatus[]> {
        return this.ajaxService.Post<ICartStatus[]>("Allocations-api/Carts", "GetCartsStatuses", {})
            .then((status: ICartStatus[]) => {
                this.cartStatus = status;
                return status;
            });
    }

    getName(): string {
        return ProlifeSdk.CartStatus;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Allocations.CartStatus;
    }

    hasEditingUI(): boolean {
        return false;//this.modulesService.IsModuleEnabled(ProlifeSdk.AllocationsApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "cart-states",
            templateUrl: "allocations/templates/settings",
            viewModel: new CartsStatesEditingViewModel(this.serviceLocator, this)
        };
    }

    getCartsLogicalStatuses(): ICartLogicalStatus[] {
        return this.cartLogicalStatus;
    }

    getCartsStatuses():ICartStatus[] {
        return this.cartStatus;
    }

    getCartsStatusById(id: number): ICartStatus {
        var s: ICartStatus[] = this.cartStatus.filter((s: ICartStatus) => { return s.Id == id });
        return s[0];
    }

    addOrUpdate(state: ICartStatus): Promise<ICartStatus> {
        return this.ajaxService.Post<ICartStatus>("Allocations-api/Carts", "AddOrUpdateCartStatus", {
            methodData: {
                Status: state
            }
        });
    }

    remove(id: number): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Allocations-api/Carts", "DeleteCartStatus", {
            methodData: {
                Id: id
            }
        });
    }
}