import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 09/05/2016
 * Time: 11:16
 * To change this template use File | Settings | File Templates.
 */

import { IJobOrderMenuActionsManager } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderMenuActionsManager";
import { IJobOrderNavigationMenuLeafProvider } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenuLeafProvider";

export class PlannerViewModel implements IJobOrderMenuActionsManager {

    constructor() {
    }

    jobOrderClicked(jobOrderProvider:IJobOrderNavigationMenuLeafProvider) {
    }

    allJobOrderSelected(jobOrderProviders:IJobOrderNavigationMenuLeafProvider[]) {
    }
}