import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IWorkTimeCategoriesSettingsManager } from "../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export class WorkTimeCategoriesDataSource extends BaseDataSource<IDataSourceModel<number, IWorkTimeCategory>> {
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesSettingsManager: IWorkTimeCategoriesSettingsManager;

    private resourceType = -1;
    private showResourceDefaultOption = false;

    public setResourceType(resType: number | null) {
        this.resourceType = resType ?? -1;
    }

    public setShowResourceDefaultOption(value: boolean): void {
        this.showResourceDefaultOption = value;
    }

    getTitle(currentModel: IDataSourceModel<number, IWorkTimeCategory>): string {
        return TextResources.ResourcesManager.WorkTimeCategories;
    }

    async getData(
        currentModel: IDataSourceModel<number, IWorkTimeCategory>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<number, IWorkTimeCategory>[]> {
        const categories = this.workTimeCategoriesSettingsManager
            .getAll(false, this.resourceType)
            .filter((c) => TextFiltersUtilities.contains(c.Name, textFilter))
            .slice(skip, skip + count);

        const categoriesModels = this.showResourceDefaultOption ? [this.createDefaultOption()] : [];
        return categoriesModels.concat(categories.map(this.createModel, this));
    }

    async getById(
        currentModel: IDataSourceModel<number, IWorkTimeCategory>,
        ids: number[]
    ): Promise<IDataSourceModel<number, IWorkTimeCategory>[]> {
        const categories = ids
            .map((id) => this.workTimeCategoriesSettingsManager.getById(id))
            .filter((c) => !!c)
            .map(this.createModel, this);

        if (this.showResourceDefaultOption && ids.indexOf(-1) >= 0) categories.push(this.createDefaultOption());

        return categories;
    }

    private createModel(category: IWorkTimeCategory): IDataSourceModel<number, IWorkTimeCategory> {
        return {
            id: category.Id,
            title: category.Name,
            isGroup: false,
            isLeaf: true,
            model: category,
        };
    }

    private createDefaultOption(): IDataSourceModel<number, IWorkTimeCategory> {
        return {
            id: -1,
            title: TextResources.ResourcesManager.ResourceDefaultOption,
            isGroup: false,
            isLeaf: true,
            model: null,
        };
    }
}
