import * as ko from "knockout";
import * as Core from "../Core/Core";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ITaskForTaskBoard } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask, IDraggedTemplateTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListTemplateTask } from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { FileDrop } from "./FileDrop";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/03/2019
 * Time: 12:13
 * To change this template use File | Settings | File Templates.
 */

export class SortableList {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var list = valueAccessor();
        var options = allBindingsAccessor()['sortableOptions'];

        var onDropCallback = options['onDropCallback'];

        options.onEnd = function(evt) {
            var item = evt.item;

            let id = $(item).data('id');
            var nextItem = $(item).nextAll(".sortable-elem");
            let nextItemId = $(nextItem).data('id');

            if (nextItemId == id) {
                $(nextItem).remove();
                nextItem = $(item).nextAll(".sortable-elem");
            }
                
            var beforeItem = $(item).prevAll(".sortable-elem");
            
            var beforeId = beforeItem ? $(beforeItem).data("id") : null;
            var nextId = nextItem ? $(nextItem).data("id") : null;
            var itemId = $(item).data("id");
            var sourceGroup = $(item).data("groupid");
            var destGroup = beforeItem && $(beforeItem).data("groupid") ? $(beforeItem).data("groupid") : (nextItem && $(nextItem).data("groupid") ? $(nextItem).data("groupid") : $(item).data("groupid"));

            onDropCallback(beforeId, itemId, nextId, sourceGroup, destGroup);
        }

        let sortable = new (<any>window).Sortable(element, options || {});
        //$(element).sortable(options || {});

        //TODO: Verificare che funzioni sta cosa, non mi risulta esista un evento "end" per il componente "sortable" di JQueryUI. L'evento giusto dovrebbe essere "stop" e viene correttamente usato dal binding handler "sortable"
        /*$(element).on("end", (event : any) => {
            
        });*/
    }
}

ko.bindingHandlers["sortableList"] = new SortableList();

class DraggableTaskBoardTask {
    init(element: any, valueAccessor: () => ITaskForTaskBoard | ko.Observable<ITaskForTaskBoard>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let task : ITaskForTaskBoard = ko.utils.unwrapObservable(valueAccessor());
        let userInfo : IUserInfo = <IUserInfo> Core.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        let canDrag = allBindingsAccessor()["canDrag"];
        if(canDrag === undefined)
            canDrag = true;

        let canDragValue = ko.utils.unwrapObservable(canDrag) || false;
        $(element).attr("draggable", canDragValue.toString());

        if(ko.isObservable(canDrag)) {
            canDrag.subscribe((canDrag) => {
                $(element).attr("draggable", canDrag.toString());
            });
        }

        $(element).on("dragstart", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            let draggedTask : IDraggedTask = {
                IsTask: task.IsTask,
                TaskId: task.Id,
                TaskBoardStatus: task.TaskBoardStatus,
                WorkflowId: task.WorkflowId,
                JobOrderId: task.JobOrderId,
                CompanyGuid: userInfo.getCurrentCompanyGuid()
            };

            dragEvent.dataTransfer.setData("text/plain", task.Title);
            dragEvent.dataTransfer.setData("application/prolife-task", JSON.stringify(draggedTask));
        });
    }
}

class DraggableTemplateTask {
    init(element: any, valueAccessor: () => ITodoListTemplateTask | ko.Observable<ITodoListTemplateTask>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let task : ITodoListTemplateTask = ko.utils.unwrapObservable(valueAccessor());
        let userInfo : IUserInfo = <IUserInfo> Core.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        let canDrag = allBindingsAccessor()["canDrag"];
        if(canDrag === undefined)
            canDrag = true;

        let canDragValue = ko.utils.unwrapObservable(canDrag) || false;
        $(element).attr("draggable", canDragValue.toString());

        if(ko.isObservable(canDrag)) {
            canDrag.subscribe((canDrag) => {
                $(element).attr("draggable", canDrag.toString());
            });
        }

        $(element).on("dragstart", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            let draggedTask : IDraggedTemplateTask = {
                TemplateTaskId: task.Id,
                TemplateId: task.TemplateId,
                CompanyGuid: userInfo.getCurrentCompanyGuid()
            };

            dragEvent.dataTransfer.setData("text/plain", task.Title);
            dragEvent.dataTransfer.setData("application/prolife-template-task", JSON.stringify(draggedTask));
        });
    }
}

interface IDraggableExOptions {
    CanDrag: boolean | ko.Observable<boolean> | ko.Computed<boolean>;
    OnDrag: (dataTransfer : DataTransfer) => void;
}

class DraggableEx {
    init(element: any, valueAccessor: () => IDraggableExOptions | ko.Observable<IDraggableExOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let options : IDraggableExOptions = ko.utils.unwrapObservable(valueAccessor());

        let canDrag = options.CanDrag || false;
        if(ko.isSubscribable(canDrag))
            canDrag.subscribe((newValue) => $(element).attr("draggable", newValue.toString()));

        let canDragValue = ko.utils.unwrapObservable(canDrag);
        $(element).attr("draggable", canDragValue.toString());

        /*let canDrag = options.CanDrag || false;
        DraggableEx.manageCanDragOption(element, canDrag);*/

        /*let bindings = {
            attr: {
                draggable: options.CanDrag
            }
        }

        ko.applyBindingsToNode(element, bindings, bindingContext);*/

        $(element).on("dragstart", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;
            options.OnDrag(dragEvent.dataTransfer);
        });
    }

    /*update(element: any, valueAccessor: () => IDraggableExOptions | ko.Observable<IDraggableExOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let options : IDraggableExOptions = ko.utils.unwrapObservable(valueAccessor());
        
        let canDrag = options.CanDrag || false;
        DraggableEx.manageCanDragOption(element, canDrag);
    }

    private static manageCanDragOption(element: HTMLElement, canDrag: boolean | ko.Observable<boolean> | ko.Computed<boolean>): void {
        if (ko.isComputed(canDrag) || ko.isObservable(canDrag)) {
            let initialValue = (canDrag() || false).toString();
            $(element).attr("draggable", initialValue.toString());

            let subscription = canDrag.subscribe(value => {
                $(element).attr("draggable", value.toString());
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscription.dispose();
            });
        } else {
            $(element).attr("draggable", canDrag.toString());
        }
    }*/
}

export type StringArrayFunc = () => string[];

interface DroppableTaskBoardTaskOptions {
    hoverClass: string;
    allowedMimeTypes: string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc;
    onDrop: (dataTransfer : DataTransfer, event?: MouseEvent) => void;
}

class DroppableEx {
    static getAllowedMimeTypes(allowedMimeTypes : string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc) : string[] {
        if(ko.isObservable(allowedMimeTypes) || ko.isComputed(allowedMimeTypes) || Array.isArray(allowedMimeTypes))
            return ko.utils.unwrapObservable(allowedMimeTypes);
        else if(typeof(allowedMimeTypes) === "function")
            return allowedMimeTypes();
        return [];
    }

    static hasAtLeastOneValidMimeType(types : readonly string[], allowedMimeTypes : string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc) : boolean {
        let allowedTypes = DroppableEx.getAllowedMimeTypes(allowedMimeTypes);
        for(let mimeType of types) {
            if(allowedTypes.indexOf(mimeType) != -1)
                return true;
        }
        return false;
    }

    init(element: any, valueAccessor: () => DroppableTaskBoardTaskOptions | ko.Observable<DroppableTaskBoardTaskOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let options : DroppableTaskBoardTaskOptions = ko.utils.unwrapObservable(valueAccessor());
        let timeout : ReturnType<typeof setTimeout>;

        let jqElement = $(element);

        jqElement.on("dragleave", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                if(timeout)
                    clearTimeout(timeout);
                timeout = setTimeout(() => {
                    $(element).removeClass(options.hoverClass);
                }, 50);
            }
        });

        jqElement.on("dragover", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                if(timeout)
                    clearTimeout(timeout);
                $(element).addClass(options.hoverClass);
                dragEvent.dataTransfer.dropEffect = "copy";
                evt.preventDefault();
                evt.stopPropagation();
            }
        });

        jqElement.on("drop", (evt: JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                evt.preventDefault();
                evt.stopPropagation();

                if(timeout)
                    clearTimeout(timeout);
                timeout = setTimeout(() => {
                    $(element).removeClass(options.hoverClass);
                }, 50);

                options.onDrop(dragEvent.dataTransfer, dragEvent);
            }
        });
    }
}

interface DroppableListOptions {
    hoverClass: string;
    hoverBeforeClass: string;
    hoverAfterClass: string;
    allowedMimeTypes: string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc;
    onlyDropOver: boolean;
    itemSelector: string;
    onDrop: (dataTransfer : DataTransfer, relativeTo: any | null, before: boolean) => void;
}

class DroppableListEx {
    init(element: any, valueAccessor: () => DroppableListOptions | ko.Observable<DroppableListOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let options : DroppableListOptions = ko.utils.unwrapObservable(valueAccessor());
        let backgroundTimeout : ReturnType<typeof setTimeout>;

        if(options.onlyDropOver) {
            $(element).on("dragover", "*", function(evt : JQuery.TriggeredEvent) {
                let dragEvent = evt.originalEvent as DragEvent;
                if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                    clearTimeout(backgroundTimeout);
                    backgroundTimeout = setTimeout(() => {
                        $(element).removeClass(options.hoverClass);
                        $(element).find(options.itemSelector)
                            .removeClass(options.hoverClass);
                    }, 100);
                    
                    let parent = $(this).hasClass(options.itemSelector) ? $(this) : $(this).closest(options.itemSelector);

                    $(element).find(options.itemSelector).not(parent)
                        .removeClass(options.hoverClass);

                    if(parent.length > 0) {
                        parent.addClass(options.hoverClass);
                    }

                    $(element).addClass(options.hoverClass);
                    dragEvent.dataTransfer.dropEffect = "copy";
                    evt.preventDefault();
                    evt.stopPropagation();
                }
            });

            $(element).on("drop", "*", function(evt) {
                let dragEvent = evt.originalEvent as DragEvent;
                if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                    evt.preventDefault();
                    evt.stopPropagation();
                    let vm : any = null;
                    let before : boolean = false;
    
                    let parent = $(this).hasClass(options.itemSelector) ? $(this) : $(this).closest(options.itemSelector);
                    if(parent.length > 0) {
                        vm = ko.dataFor(parent[0]);
                    }
    
                    options.onDrop(dragEvent.dataTransfer, vm, before);
                }
            });

            return;
        }

        $(element).on("dragover", "*", function(evt : JQuery.TriggeredEvent) {
            let dragEvent = evt.originalEvent as DragEvent;
            if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                clearTimeout(backgroundTimeout);
                backgroundTimeout = setTimeout(() => {
                    $(element).removeClass(options.hoverClass);
                    $(element).find(options.itemSelector)
                        .removeClass(options.hoverAfterClass)
                        .removeClass(options.hoverBeforeClass);
                }, 100);

                let parent = $(this).hasClass(options.itemSelector) ? $(this) : $(this).closest(options.itemSelector);

                $(element).find(options.itemSelector).not(parent)
                    .removeClass(options.hoverAfterClass)
                    .removeClass(options.hoverBeforeClass);

                if(parent.length > 0) {
                    let offset = parent.offset();
                    let mouseY = evt.pageY - offset.top;

                    if(mouseY < parent.outerHeight() / 2) {
                        parent.addClass(options.hoverBeforeClass);
                        parent.removeClass(options.hoverAfterClass);
                    } else {
                        parent.addClass(options.hoverAfterClass);
                        parent.removeClass(options.hoverBeforeClass);
                    }
                }
                
                $(element).addClass(options.hoverClass);
                dragEvent.dataTransfer.dropEffect = "copy";
                evt.preventDefault();
                evt.stopPropagation();
            }
        });

        $(element).on("drop", "*", function(evt) {
            let dragEvent = evt.originalEvent as DragEvent;
            if(DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                evt.preventDefault();
                evt.stopPropagation();
                let vm : any = null;
                let before : boolean = false;

                let parent = $(this).hasClass(options.itemSelector) ? $(this) : $(this).closest(options.itemSelector);
                if(parent.length > 0) {
                    vm = ko.dataFor(parent[0]);

                    let offset = parent.offset();
                    let mouseY = evt.pageY - offset.top;

                    before = mouseY < parent.outerHeight() / 2;
                }

                options.onDrop(dragEvent.dataTransfer, vm, before);
            }
        });
    }
}

interface ApplicationDropAreaOptions {
    hoverClass: string;
    allowedMimeTypes: string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc;
    ignoreEventsFrom: string[];
    allowedFileTypes: string[];
    onDrop: (filesList: File[], event?: MouseEvent) => void;
}

class ApplicationDropArea {
    init(element: any, valueAccessor: () => ApplicationDropAreaOptions | ko.Observable<ApplicationDropAreaOptions>, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let options : ApplicationDropAreaOptions = ko.utils.unwrapObservable(valueAccessor());
        let timeout : ReturnType<typeof setTimeout>;

        let jqElement = $(element);

        const ignoreEventsFrom = options.ignoreEventsFrom ?? [];
        const allowedFileTypes = options.allowedFileTypes ?? [];

        jqElement.on("dragleave", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if (FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                return;

            if (DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                if (timeout)
                    clearTimeout(timeout);
                timeout = setTimeout(() => {
                    $(element).removeClass(options.hoverClass);
                }, 50);
            }
        });

        jqElement.on("dragenter", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if (FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                return;

            if (DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                let jqTarget = $(evt.target);

                if (!jqTarget.hasClass("drop-area")) {
                    if (jqTarget.parents(".drop-area").length === 0)
                        $(element).addClass(options.hoverClass);
                } else {
                    if (timeout)
                        clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        $(element).removeClass(options.hoverClass);
                    }, 50);
                }
            }
        });

        jqElement.on("dragover", (evt : JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if (FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                return;

            if (DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                if (timeout)
                    clearTimeout(timeout);
                dragEvent.dataTransfer.dropEffect = "copy";
                evt.preventDefault();
                evt.stopPropagation();
            }
        });

        jqElement.on("drop", (evt: JQuery.TriggeredEvent) => {
            let dragEvent = evt.originalEvent as DragEvent;

            if (FileDrop.mustIgnoreEventsFrom(ignoreEventsFrom, evt.target))
                return;

            if (DroppableEx.hasAtLeastOneValidMimeType(dragEvent.dataTransfer.types, options.allowedMimeTypes)) {
                evt.preventDefault();
                evt.stopPropagation();

                if (timeout)
                    clearTimeout(timeout);
                timeout = setTimeout(() => {
                    $(element).removeClass(options.hoverClass);
                }, 50);

                let filesList = [];
                let unallowedFileNamesList = [];

                for (let i = 0; i < dragEvent.dataTransfer.files.length; i++) {
                    const file = dragEvent.dataTransfer.files[i];
                    if (options.allowedFileTypes.length === 0 || ApplicationDropArea.isAllowedFile(allowedFileTypes, file.name))
                        filesList.push(file);
                    else
                        unallowedFileNamesList.push(file.name);
                }

                if (unallowedFileNamesList.length > 0) {
                    const infoToastService = Core.serviceLocator.findService(nameof<IInfoToastService>()) as IInfoToastService;
                    infoToastService.Warning(String.format(TextResources.ProlifeSdk.UnallowedFilesDrop, '<br/>' + unallowedFileNamesList.join("<br/>")));
                }
                
                options.onDrop(filesList, dragEvent);
            }
        });
    }

    private static isAllowedFile(allowedFileTypes: string[], fileName: string): boolean {
        return !!allowedFileTypes.firstOrDefault(t => fileName.lastIndexOf(t) >= 0);
    }
}

ko.bindingHandlers["draggableTask"] = new DraggableTaskBoardTask();
ko.bindingHandlers["draggableTemplateTask"] = new DraggableTemplateTask();
ko.bindingHandlers["draggableEx"] = new DraggableEx();
ko.bindingHandlers["droppableEx"] = new DroppableEx();
ko.bindingHandlers["droppableListEx"] = new DroppableListEx();
ko.bindingHandlers["applicationDropArea"] = new ApplicationDropArea();