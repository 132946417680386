import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 15:14
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { AgendasMenu } from "./navigation/AgendasMenu";
import { AgendaViewModel } from "./AgendaViewModel";
import { UserConfigurationDialog } from "./dialogs/UserConfigurationDialog";
import { EventsSearchViewModel } from "./EventsSearchViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import {
    IAgendaEvent,
    IEventsService,
    IAgendaEventDescriptor,
    IAgendaEventForEditingEx,
} from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IAgendaService, IAgenda, IDetailedAgenda } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IAgendaNavigationMenuObserver } from "../../interfaces/IAgendaNavigationMenu";
import { IEventsSearchObserver } from "../../interfaces/IAgendaEvent";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { LazyImport } from "../../../Core/DependencyInjection";

export class Agenda
    implements IAgendaNavigationMenuObserver, IEventsSearchObserver, IChangesNotificationsServiceObserver
{
    public NavigationMenu: AgendasMenu;
    public EventsSearchViewModel: ko.Observable<any> = ko.observable();

    public SelectedAgenda: ko.Observable<AgendaViewModel> = ko.observable();
    public SelectedEvent: ko.Computed<IAgendaEvent>;

    public IsSearching: ko.Computed<boolean>;
    public ShowListButton: ko.Computed<boolean>;
    public ShowCalendarButton: ko.Computed<boolean>;

    public CalendarViewActive: ko.Observable<boolean> = ko.observable(true);
    public ListViewActive: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IAgendaService>())
    private agendasService: IAgendaService;
    @LazyImport(nameof<IEventsService>())
    private eventsService: IEventsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    constructor(private serviceLocator: IServiceLocator, private agendaId?: number) {
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.AgendaEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.AgendaEventEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.AgendaFestivityEntityTypeCode, this);
        this.changesNotificationsService.RegisterEventHandler(
            "AgendaSynchronizationCompleted",
            this.AgendaSynchronizationComplate.bind(this)
        );

        this.EventsSearchViewModel(new EventsSearchViewModel(this.serviceLocator, this));
        this.NavigationMenu = new AgendasMenu(this.serviceLocator);

        this.NavigationMenu.addMenuObserver(this);
        this.NavigationMenu.AddEventsMovingObserver(this);
        this.NavigationMenu.SetSearchedEventsEditor(this);
        this.NavigationMenu.singleSelectionEnabled(true);
        this.NavigationMenu.refreshItems().then(() => {
            if (agendaId) {
                setTimeout(() => {
                    this.NavigationMenu.SelectAgenda(agendaId);
                }, 1000);
            }
        });

        this.IsSearching = ko.computed(() => {
            if (!this.EventsSearchViewModel()) return false;

            return this.EventsSearchViewModel().IsSearching();
        });

        this.SelectedEvent = ko.computed(() => {
            if (!this.SelectedAgenda()) return undefined;

            if (!this.SelectedAgenda().Calendar().SelectedEvent()) return undefined;

            return this.SelectedAgenda().Calendar().SelectedEvent();
        });

        this.ShowListButton = ko.computed(() => {
            return true;
        });

        this.ShowCalendarButton = ko.computed(() => {
            return true;
        });

        var calendarSetupInterceptor = ko.computed(() => {
            if (!this.SelectedAgenda()) return;

            if (!this.SelectedAgenda().Calendar()) return;

            this.SelectedAgenda().Calendar().RegisterEventsDragObserver(this.NavigationMenu);
        });

        var agendaSelectionInterceptor = ko.computed(() => {
            if (!this.SelectedAgenda()) return;

            this.SelectedAgenda().SetAgendasEditor(this);
            if (this.IsSearching()) {
                if (!this.EventsSearchViewModel().LockFilters) this.EventsSearchViewModel().CrossAgendasEnabled(false);

                this.EventsSearchViewModel().DoSearch();
            }
        });
    }

    private AgendaSynchronizationComplate(agendaId: number) {
        if (!this.SelectedAgenda() && this.SelectedAgenda().AgendaId() != agendaId) return;

        this.SelectedAgenda().RefreshCalendar();
    }

    ShowCalendarView(): void {
        this.EventsSearchViewModel().LockFilters = true;

        this.EventsSearchViewModel().IsSearching(false);
        this.ListViewActive(false);
        this.CalendarViewActive(true);

        setTimeout(() => {
            this.EventsSearchViewModel().LockFilters = false;
        }, 200);
    }

    ShowListView(): void {
        this.EventsSearchViewModel().LockFilters = true;

        this.EventsSearchViewModel().IsSearching(true);
        this.ListViewActive(true);
        this.CalendarViewActive(false);
        this.EventsSearchViewModel().DoSearch();

        setTimeout(() => {
            this.EventsSearchViewModel().LockFilters = false;
        }, 200);
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {}

    onSelectionChanged(selection: INavigationMenuProvider[], navigator: INavigationMenu) {
        if (selection.length == 0) {
            this.SelectedAgenda(undefined);
            return;
        }

        this.agendasService
            .GetAgenda(selection[0].Id)
            .then((agenda: IAgenda) => {
                if (!agenda) return;
                var agendaViewModel = new AgendaViewModel(this.serviceLocator, agenda);
                agendaViewModel.LoadDetails().then(() => {
                    this.SelectedAgenda(agendaViewModel);
                });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendaError);
            });
    }

    UnselectAgendaForFilter(): void {
        if (!this.NavigationMenu) return;
        this.SelectedAgenda(undefined);
        this.NavigationMenu.UnselectAgendaForFilter();
    }

    RestoreAgendaSelectionForFilter(): void {
        if (!this.NavigationMenu) return;
        this.NavigationMenu.RestoreAgendaSelectionForFilter();
    }

    AddAgenda(): void {
        this.agendasService.ui
            .GetAgendasEditingDialog(this.SelectedAgenda() ? this.SelectedAgenda().FolderId() : null)
            .then((agenda: IDetailedAgenda) => {
                if (!agenda) return;
                this.NavigationMenu.CreateNewAgenda(agenda);
            });
    }

    EditSelectedAgenda(): void {
        this.agendasService.ui
            .GetAgendasEditingDialog(this.SelectedAgenda().FolderId(), this.SelectedAgenda().AgendaId())
            .then((agenda: IDetailedAgenda) => {
                if (!agenda) return;

                if (this.AgendaHasBeenDeleted(agenda) && !this.NavigationMenu.ShowDeletedElements()) {
                    this.NavigationMenu.RemoveAgenda(
                        this.SelectedAgenda().AgendaId(),
                        this.SelectedAgenda().FolderId()
                    );
                    this.SelectedAgenda(undefined);
                    return;
                }

                this.NavigationMenu.UpdateAgenda(agenda);
                this.SelectedAgenda().UpdateFromModel(agenda);
            });
    }

    AddEvent(): void {
        if (!this.SelectedAgenda()) return;

        this.SelectedAgenda()
            .Calendar()
            .AddEvent()
            .then((event: IAgendaEvent) => {
                this.SelectedAgenda().RefreshWaitingList();
                this.RefreshMenu();
            });
    }

    EditSelectedEvent(): void {
        if (!this.SelectedEvent()) return;

        this.SelectedAgenda()
            .Calendar()
            .ModifyEvent(this.SelectedEvent())
            .then((event: IAgendaEvent) => {
                this.SelectedAgenda().RefreshWaitingList();
                this.RefreshMenu();
            });
    }

    EditSearchedEvent(event: IAgendaEventDescriptor): void {
        this.eventsService.ui
            .GetEventEditingDialog(event.id, event.AgendaId)
            .then((modifiedEvent: IAgendaEventForEditingEx) => {
                if (!modifiedEvent) return;

                this.RefreshAll();
            });
    }

    NavigateToEvent(event: IAgendaEventDescriptor): void {
        // TODO Seleziona l'evento se è in waiting list, altrimenti apri l'agenda dell'evento
    }

    OnEventDroppedOnAgenda(event: IAgendaEvent, agendaId: number) {
        this.RefreshAll();
    }

    ShowUserConfigDialog(): void {
        var dialog = new UserConfigurationDialog(this.serviceLocator);
        dialog.Show();
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (sendByMe) return false;

        if (changesInfo.EntityCode == ProlifeSdk.AgendaEntityTypeCode) {
            this.OnAgendaChangesNotification(changesInfo);
            return false;
        }

        if (changesInfo.EntityCode == ProlifeSdk.AgendaEventEntityTypeCode) {
            this.OnAgendaEventChangesNotification(changesInfo);
            return false;
        }

        if (changesInfo.EntityCode == ProlifeSdk.AgendaFestivityEntityTypeCode) {
            this.OnAgendaFestivityChangesNotification(changesInfo);
            return false;
        }

        return false;
    }

    public RefreshAll(): void {
        this.RefreshMenu();
        if (this.IsSearching()) this.EventsSearchViewModel().search();

        if (!this.SelectedAgenda()) return;

        this.SelectedAgenda().RefreshWaitingList();
        this.SelectedAgenda().RefreshCalendar();
    }

    public RefreshMenu(): void {
        this.NavigationMenu.refreshItems().then(() => {
            let agenda = this.SelectedAgenda();

            if (!agenda) return;

            this.NavigationMenu.selectFirstProviderMatch(
                (provider: INavigationMenuProvider) => provider.Id == agenda.AgendaId(),
                false
            );
        });
    }

    public dispose() {
        this.NavigationMenu = undefined;
        this.EventsSearchViewModel(undefined);
        this.changesNotificationsService.RemoveObserver(this);
    }

    private AgendaHasBeenDeleted(agenda: IAgenda): boolean {
        return agenda.Deleted != ProlifeSdk.NotDeletedState;
    }

    private OnAgendaChangesNotification(changesInfo: IObjectChangesInfo) {
        if (changesInfo.Action == 0) {
            this.NavigationMenu.CreateNewAgenda(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaInsertMessage,
                    changesInfo.Object.Name,
                    changesInfo.UserFullName
                )
            );
            return;
        }

        if (changesInfo.Action == 1) {
            this.NavigationMenu.UpdateAgenda(changesInfo.Object);
            if (this.SelectedAgenda().AgendaId() == changesInfo.EntityKeyId)
                this.SelectedAgenda().UpdateFromModel(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaUpdateMessage,
                    changesInfo.Object.Name,
                    changesInfo.UserFullName
                )
            );
            return;
        }

        if (changesInfo.Action == 2) {
            this.NavigationMenu.RemoveAgenda(changesInfo.EntityKeyId, changesInfo.Object.FolderId);
            if (this.SelectedAgenda().AgendaId() == changesInfo.EntityKeyId) this.SelectedAgenda(undefined);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaDeleteMessage,
                    changesInfo.Object.Name,
                    changesInfo.UserFullName
                )
            );
            return;
        }
    }

    private OnAgendaEventChangesNotification(changesInfo: IObjectChangesInfo) {
        if (changesInfo.Action == 0) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().RenderEventOnCalendar(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaFestivityInsertMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return;
        }

        if (changesInfo.Action == 1) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().UpdateEventOnCalendar(changesInfo.Object);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaFestivityUpdateMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return;
        }

        if (changesInfo.Action == 2) {
            if (changesInfo.Object.AgendaId == this.SelectedAgenda().AgendaId())
                this.SelectedAgenda().RemoveEventFromCalendar(changesInfo.EntityKeyId);
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Agenda.AgendaFestivityDeleteMessage,
                    changesInfo.Object.title,
                    changesInfo.UserFullName
                )
            );
            return;
        }
    }

    private OnAgendaFestivityChangesNotification(changesInfo: IObjectChangesInfo) {
        // TODO
    }
}
