import * as ko from "knockout";
import { INotifyWhenVisibleOptions } from "../ProlifeSdk/interfaces/bindings/INotifyWhenVisibleOptions";

export class NotifyWhenVisible {
    private static counter: number = 0;

    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let actualCounter = ++NotifyWhenVisible.counter;

        //console.log("NotifyWhenVisible (" + actualCounter + ") Init", element);

        let options: INotifyWhenVisibleOptions = valueAccessor();
        let root = NotifyWhenVisible.findRootElement(element, options.rootSelector);

        let intersectionOptions: IntersectionObserverInit = {
            root: root,
            rootMargin: options.rootMargin || '0px',
            threshold: options.threshold || 0
        };

        let observer = new IntersectionObserver((entries, observer) => {
            //console.log("NotifyWhenVisible (" + actualCounter + ") Intersection observer", entries);

            if(entries.length == 0)
                return;

            //let entry = entries.filter((e) => e.intersectionRatio > 0);
            let entry = entries.filter((e) => {
                if (e.isIntersecting && e.intersectionRatio == 0)
                    console.log("NotifyWhenVisible (" + actualCounter + ") Intersection Ratio 0 con intersecting true");

                return e.isIntersecting;
            });
            
            if (entry.length == 0)
                return;

            //if(entry[0].intersectionRatio > 0)
                options
                    .callback()
                    .catch((e) => {
                        console.error("Unhandled exception in NotifyWhenVisibleCallback: ", e);
                    });

        }, intersectionOptions);
        
        //console.log("NotifyWhenVisible (" + actualCounter + ") Before Observe", element);
        observer.observe(element);
        //console.log("NotifyWhenVisible (" + actualCounter + ") After Observe", element);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            //console.log("NotifyWhenVisible (" + actualCounter + ") Unobserve", element);
            observer.unobserve(element);

            //console.log("NotifyWhenVisible (" + actualCounter + ") Disconnect", element);
            observer.disconnect();
        });
    }

    private static findRootElement(target: HTMLElement, selector: string): HTMLElement {
        if(!selector)
        //if (selector == '[parent]')
            return $(target).parent()[0] || null;

        return $(target).closest(selector)[0];//document.querySelector(selector);
    }
}

ko.bindingHandlers["notifyWhenVisible"] = new NotifyWhenVisible();