import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IPaymentModes, IPaymentAndExpireForExport, IPaymentMode } from "../../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { IExpireModes, IExpireMode } from "../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { ICompanySettingsManager } from "../../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IBank } from "../../../../ProlifeSdk/interfaces/settings/IBank";
import { IAccount } from "../../../../ProlifeSdk/interfaces/settings/IAccount";

export class PaymentAndExpireAccountingSoftwareMappingsDialog implements IDialog
{
    private infoToast : IInfoToastService;
    private paymentsSettings : IPaymentModes;
    private expireSettings : IExpireModes;
    private companySettings : ICompanySettingsManager;

    templateName: string = "payment-and-expire-accounting-sw-mappings";
    templateUrl: string = "invoices/templates/settings";
    title: string = ProlifeSdk.TextResources.Invoices.AccountantSettings;
    modal: { close: (result?: any) => void; };

    public Mappings : ko.ObservableArray<PaymentAndExpireMapping> = ko.observableArray([]);
    public ShowDeletedOptions : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.infoToast = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        var settings : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.paymentsSettings = <IPaymentModes>settings.findSettingsManager(ProlifeSdk.PaymentMode);
        this.expireSettings = <IExpireModes>settings.findSettingsManager(ProlifeSdk.ExpireModes);
        this.companySettings = <ICompanySettingsManager>settings.findSettingsManager(ProlifeSdk.CompanySettingsType);

        this.ExpandMappings();
        this.InitializeMappings();
    }

    private InitializeMappings()
    {
        this.paymentsSettings.GetMappingForAccountingSwExport().then((mappings : IPaymentAndExpireForExport[]) => {
            this.Mappings().forEach((m : PaymentAndExpireMapping) => {
                m.Initialize(mappings);
            })
        });
    }

    private ExpandMappings()
    {
        this.expireSettings.getExpireModes(true).forEach((e : IExpireMode) => {
            this.paymentsSettings.getPaymentModes(true).forEach((p : IPaymentMode) => {
                //Conto di default a null
                var accounts : string[] = [null];

                //Per le tipologie di pagamento che prevedono l'associazione ai conti aziendali
                //devo moltiplicare
                if(p.AssociaBanca == 2)
                    this.companySettings.get().Banks.forEach((b : IBank) => {
                        b.Accounts.forEach((a : IAccount) => {
                            if(accounts.indexOf(a.IBAN) == -1)
                                accounts.push(a.IBAN);
                        });
                    });

                accounts.forEach((a : string) => {
                    this.Mappings.push(new PaymentAndExpireMapping(p, e, a, this.ShowDeletedOptions));
                });
            });
        });
    }

    close(): void
    {
        this.modal.close();
    }

    action(): void
    {
        this.paymentsSettings.UpdateMappingForAccountingSwExport(this.Mappings().map((m : PaymentAndExpireMapping) => { return m.getData(); }))
            .then(() => {
                this.infoToast.Success(ProlifeSdk.TextResources.Invoices.ExportCodesSaved);
            });
    }
}

export class PaymentAndExpireMapping
{
    public AccountingSoftwareCode : ko.Observable<string> = ko.observable("");
    private model : IPaymentAndExpireForExport;
    public IsVisible : ko.Computed<boolean>;

    constructor(private payment : IPaymentMode, private expire : IExpireMode, private account : string, private showDeletedObservable : ko.Observable<boolean>)
    {
        this.IsVisible = ko.computed(() => {
            return this.showDeletedObservable() || (this.payment.Eliminato == 0 && this.expire.Eliminato == 0);
        });
    }

    public Initialize(mappings : IPaymentAndExpireForExport[])
    {
        var matches : IPaymentAndExpireForExport[] =
            mappings.filter((m : IPaymentAndExpireForExport) => {
            return  m.IdTipoPagamento == this.payment.IdTipoPagamento &&
                    m.IdTipoScadenza == this.expire.IdTipoScadenza &&
                    (m.Account || "") == (this.account || "");
        });

        this.AccountingSoftwareCode(matches.length == 0 ? "" : (matches[0].AccountingSoftwareCode || ""));
        this.model = matches.length > 0 ? matches[0] : <IPaymentAndExpireForExport> {
            IdTipoPagamento : this.payment.IdTipoPagamento,
            IdTipoScadenza : this.expire.IdTipoScadenza,
            Account : this.account
        };
    }

    public getData()
    {
        this.model.AccountingSoftwareCode = this.AccountingSoftwareCode();
        return this.model;
    }
}

