import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ScheduleStateSettingsManager } from "./schedule/settings/ScheduleStateSettingsManager";
import { ScheduleApplication } from "./schedule/ScheduleApplication";
import { InvoiceDetailsDialog } from "./schedule/ui/InvoiceDetailsDialog";
import { ScheduleDetailViewModel } from "./schedule/ui/ScheduleDetailViewModel";
import { LightInvoicesWithSchedulesViewModel } from "./schedule/ui/LightInvoicesWithSchedulesViewModel";
import { ScheduleViewModel } from "./schedule/ui/ScheduleViewModel";
import { SchedulesDropDownList } from "./schedule/ui/dropdownlist/SchedulesDropDownList";
import { ScheduleRefUiForRemind } from "./schedule/ui/reminds-refs/ScheduleRefUiForRemind";
import { LazyImport } from "../Core/DependencyInjection";
import { ILightInvoicesWithSchedules } from "../ProlifeSdk/interfaces/schedule/ILightInvoicesWithSchedules";
import { IScheduleService, ICurrencyInfoForSchedule, IScheduleForList, IScheduleState, IInvoiceAndSchedules, IGetSchedulesRequest } from "../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IReminderService, IRemindReferenceObjFieldType } from "../ProlifeSdk/interfaces/reminder/IReminderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, AjaxOptions } from "../Core/interfaces/IAjaxService";
import { IDialogsService, IDialog } from "../Core/interfaces/IDialogsService";
import { IService } from "../Core/interfaces/IService";
import { IDropDownList } from "../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { ISchedule } from "../ProlifeSdk/interfaces/schedule/ISchedule";
import { IScheduleReport } from "../ProlifeSdk/interfaces/schedule/IScheduleReport";
import { IExpiredSchedules } from "../ProlifeSdk/interfaces/schedule/IExpiredSchedules";
import { Deferred } from "../Core/Deferred";

class ScheduleService implements IScheduleService
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    
	private application : ScheduleApplication;

	constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IScheduleService>(), this);
	}

    InitializeService(){
        //Registro i settings
		new ScheduleStateSettingsManager(this.serviceLocator);


        var entitiesService : IEntityProviderService = <IEntityProviderService> this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode : ProlifeSdk.ScheduleEntityTypeCode,
            EntityName : ProlifeSdk.TextResources.Schedule.Schedule,
            PluralEntityName : ProlifeSdk.TextResources.Schedule.Schedules,
            EntityIsMale : false,
            CanBeRelatedToRemind : true
        });

		this.application = new ScheduleApplication(this.serviceLocator);

        new ScheduleRefUiForRemind(this.serviceLocator);

        var remindService : IReminderService = <IReminderService>this.serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        var fields : IRemindReferenceObjFieldType[] = [];
        fields.push({ Id : 1, Description : ProlifeSdk.TextResources.Schedule.EntityFieldNameDescription, IsForTitle : true, IsForExpireDate : false });
        fields.push({ Id : 2, Description : ProlifeSdk.TextResources.Schedule.EntityFieldNamePaymentMode, IsForTitle : true, IsForExpireDate : false });
        fields.push({ Id : 3, Description : ProlifeSdk.TextResources.Schedule.EntityFieldNameTotal, IsForTitle : false, IsForExpireDate : false });
        fields.push({ Id : 4, Description : ProlifeSdk.TextResources.Schedule.EntityFieldNamePaymentDate, IsForTitle : false, IsForExpireDate : false });
        fields.push({ Id : 5, Description : ProlifeSdk.TextResources.Schedule.EntityFieldNameExpireDate, IsForTitle : false, IsForExpireDate : true });
        remindService.registerObjReferenceFields(ProlifeSdk.ScheduleEntityTypeCode, fields);
    }

    getSchedulesDropDown() : IDropDownList {
        return new SchedulesDropDownList(this.serviceLocator);
    }

	getServiceType(): string
    {
		return ProlifeSdk.ScheduleServiceType;
	}

    GetInvoiceSchedulesDialog(invoiceId : number) : Promise<IDialog>
    {
        var def = new Deferred<InvoiceDetailsDialog>();
        this.GetInvoiceWithSchedule(invoiceId).then((invoiceWithSchedule : ILightInvoicesWithSchedules) => {
            var schedules = [];
            var invoice: LightInvoicesWithSchedulesViewModel = null;
            var details : ScheduleDetailViewModel = null;
            if (invoiceWithSchedule) {
                let currencyInfo: ICurrencyInfoForSchedule = { 
                    CurrencyId: invoiceWithSchedule.Invoice.CurrencyId,
                    CurrencySymbol: invoiceWithSchedule.Invoice.CurrencySymbol,
                    CurrencyExchangeValue: invoiceWithSchedule.Invoice.CurrencyExchangeValue
                };

                schedules = invoiceWithSchedule.Schedules.map((schedule : ISchedule) => new ScheduleViewModel(schedule, currencyInfo, invoiceWithSchedule.Customer));
                invoice = new LightInvoicesWithSchedulesViewModel(invoiceWithSchedule.Invoice, schedules, invoiceWithSchedule?.Customer);
                details = new ScheduleDetailViewModel(invoice);
            }

            var dialog = new InvoiceDetailsDialog(details);
            def.resolve(dialog);
        }).catch(() => { def.reject(); });

        return def.promise();
    }

    GetInvoiceWithSchedule(invoiceId : number) : Promise<ILightInvoicesWithSchedules>
    {
        return this.ajaxService.Get("Schedule-api/Invoices", "GetInvoiceWithSchedule?invoiceId=" + invoiceId, {});
    }

	isOfType(serviceType: string): boolean
    {
		return serviceType == this.getServiceType();
	}

	CreateSchedules(schedulesToCreate : ISchedule[]): Promise<ISchedule[]>
    {
		var parameters : AjaxOptions = {};
		parameters.methodData = { SchedulesToUpdate: schedulesToCreate};
		return this.ajaxService.Post("Schedule-api/Schedule", "CreateSchedules", parameters);
	}

	UpdateSchedules(scheduleToUpdate : ISchedule[], schedulesToRemove : ISchedule[]): Promise<ISchedule[]>
    {

		var parameters : AjaxOptions = {};
		parameters.methodData = { SchedulesToUpdate: scheduleToUpdate, SchedulesToRemove: schedulesToRemove};

		return this.ajaxService.Post("Schedule-api/Schedule", "EditSchedules", parameters);
	}

	SaveSchedule(schedule : ISchedule): Promise<ISchedule>
    {
		var parameters : AjaxOptions = {};
		parameters.methodData = schedule;
		return this.ajaxService.Post("Schedule-api/Schedule", "EditSchedule", parameters);
	}

    searchSchedulesForList(textFilter : string): Promise<IScheduleForList[]>
    {
        return this.ajaxService.Post("Schedule-api/Schedule", "SearchSchedulesForList", {
            methodData : {
                TextFilter : textFilter
            }
        });
    }

    getScheduleForList(id : number): Promise<IScheduleForList>
    {
        return this.ajaxService.Get("Schedule-api/Schedule", "GetScheduleForList?id=" + id, {
        });
    }


	getScheduleReportExercisesForCustomer(FKCliente : number): Promise<IScheduleReport[]>
    {
		var parameters : AjaxOptions = {};
		parameters.methodData = { customerId: FKCliente};
		return this.ajaxService.Post("Schedule/ScheduleUtils", "getScheduleReportExercisesForCustomer", parameters);
	}

	getFattureInScadenzaPerEsercizio(FKCliente : number, FKEsercizio : number): Promise<IExpiredSchedules[]>
    {
		var parameters : AjaxOptions = {};
		parameters.methodData = { customerId: FKCliente, esercizio: FKEsercizio};
		return this.ajaxService.Post("Schedule/ScheduleUtils", "getFattureInScadenzaPerEsercizio", parameters);
	}

    getScheduleLinkFor(invoiceId : number) : string
    {
        return String.format(ProlifeSdk.TextResources.Schedule.BillBookForInvoiceURL, invoiceId);
    }

    GetInvoicesWithOpenSchedulesIntoFuture(skip : number, count : number, textFilter : string, from : Date, to : Date): Promise<ILightInvoicesWithSchedules[]>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithOpenSchedulesIntoFuture", {
            methodData : {
                Skip : skip,
                Count : count,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }

    GetInvoicesWithExpiredSchedules(skip : number, count : number, textFilter : string, from : Date, to : Date): Promise<ILightInvoicesWithSchedules[]>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithExpiredSchedules", {
            methodData : {
                Skip : skip,
                Count : count,
                TextFilter : textFilter,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null
            }});
    }

    GetInvoicesWithoutSchedules(skip : number, count : number, textFilter : string, from : Date, to : Date): Promise<ILightInvoicesWithSchedules[]>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithoutSchedules", {
            methodData : {
                Skip : skip,
                Count : count,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }

    GetInvoicesWithClosedSchedules(skip : number, count : number, textFilter : string, from : Date, to : Date): Promise<ILightInvoicesWithSchedules[]>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithClosedSchedules", {
            methodData : {
                Skip : skip,
                Count : count,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }


    GetInvoicesWithOpenSchedulesIntoFutureCount(textFilter : string, from : Date, to : Date): Promise<number>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithOpenSchedulesIntoFutureCount", {
            methodData : {
                Skip : -1,
                Count : -1,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }

    GetInvoiceSchedules(invoiceId : number): Promise<ISchedule[]>
    {
        return this.ajaxService.Post("Schedule-api/Schedule", "InvoiceSchedules", {
            methodData : {
                InvoiceId : invoiceId
            }});
    }

    GetInvoicesWithExpiredSchedulesCount(textFilter : string, from : Date, to : Date): Promise<number>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithExpiredSchedulesCount", {
            methodData : {
                Skip : -1,
                Count : -1,
                TextFilter : textFilter,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null
            }});
    }

    GetInvoicesWithoutSchedulesCount(textFilter : string, from : Date, to : Date): Promise<number>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithoutSchedulesCount", {
            methodData : {
                Skip : -1,
                Count : -1,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }

    GetInvoicesWithClosedSchedulesCount(textFilter : string, from : Date, to : Date): Promise<number>
    {
        return this.ajaxService.Post("Schedule-api/Invoices", "GetInvoicesWithClosedSchedulesCount", {
            methodData : {
                Skip : -1,
                Count : -1,
                From : from ? (<any>moment(from)).toJSON() : null,
                To : to ? (<any>moment(to)).toJSON() : null,
                TextFilter : textFilter
            }});
    }

    GetScheduleStateById(id: number | null): Promise<IScheduleState[]> {
        return this.ajaxService.Post<IScheduleState[]>("Schedule-api/ScheduleState", "GetScheduleStateById", {
            background: true,
            methodData: {
        		id: id,
        	}
        });
    }

    InsertOrUpdateSchedulesStates(schedules: IScheduleState[] | null): Promise<IScheduleState[]> {
        return this.ajaxService.Post<IScheduleState[]>("Schedule-api/ScheduleState", "InsertOrUpdateSchedulesStates", {
            background: true,
            methodData: {
        		schedules: schedules,
        	}
        });
    }

    DeleteScheduleState(id: number | null): Promise<void> {
        return this.ajaxService.Post<void>("Schedule-api/ScheduleState", "DeleteScheduleState", {
            background: true,
            methodData: {
        		id: id,
        	}
        });
    }

    DeleteSchedulesForInvoice(InvoiceId: number | null): Promise<void> {
        return this.ajaxService.Post<void>("Schedules-api/Schedule", "DeleteSchedulesForInvoice", {
            background: true,
            methodData: {
        		InvoiceId: InvoiceId,
        	}
        });
    }

    GetSchedulesStates(): Promise<IScheduleState[]> {
        let result = this.ajaxService.Post<IScheduleState[]>("Schedule-api/ScheduleState", "GetSchedulesStates", {
            background: true,
            methodData: {
        	}
        });



        return result;
    }

    GetSchedulesByInvoiceIds(ids: number[] | null): Promise<IInvoiceAndSchedules[]> {
        let result = this.ajaxService.Post<IInvoiceAndSchedules[]>("Schedule-api/Invoices", "GetSchedulesByInvoiceIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });



        return result;
    }

    async ShowInvoiceSchedulesDialog(documentId: number, documentType: string) : Promise<void> {
        let invoiceWithSchedule = await this.GetInvoiceWithSchedule(documentId);
            
        var schedules = [];
        var invoice: LightInvoicesWithSchedulesViewModel = null;
        var details : ScheduleDetailViewModel = null;
        if (invoiceWithSchedule) {
            let currencyInfo: ICurrencyInfoForSchedule = { 
                CurrencyId: invoiceWithSchedule.Invoice.CurrencyId,
                CurrencySymbol: invoiceWithSchedule.Invoice.CurrencySymbol,
                CurrencyExchangeValue: invoiceWithSchedule.Invoice.CurrencyExchangeValue
            };

            schedules = invoiceWithSchedule.Schedules.map((schedule : ISchedule) => new ScheduleViewModel(schedule, currencyInfo, invoiceWithSchedule?.Customer));
            invoice = new LightInvoicesWithSchedulesViewModel(invoiceWithSchedule.Invoice, schedules, invoiceWithSchedule?.Customer);
            details = new ScheduleDetailViewModel(invoice);
        }

        var dialog = new InvoiceDetailsDialog(details);
        return this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
    }

    GetSchedules(request: IGetSchedulesRequest): Promise<IInvoiceAndSchedules[]> {
        let result = this.ajaxService.Post<IInvoiceAndSchedules[]>("Schedule-api/Invoices", "GetSchedules", {
            background: true,
            methodData: request
        });

        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new ScheduleService(serviceLocator);
}