import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ExpenseApplication } from "./expense/ExpenseApplication";
import { LazyImport } from "../Core/DependencyInjection";
import { ExpenseEditDialogComponent } from "./expense/ui/dialogs/ExpenseEditDialog";
import { ExpenseStatusesSettingsManager } from "./expense/settings/ExpenseStatusesSettingsManager";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import "./expense/settings/ExpenseStatusesEditor";
import "./expense/ui/dialogs/AttachmentsManagerPopoverComponent";
import { ExpenseEvent } from "./expense/events/ExpenseEvent";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IDialogsService, IPopoverComponentInfo } from "../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IExpenseService, IExtendedFullExpense, IExpenseCostRowType, ICostRowType_Type, IExpenseProject, IExpenseStatus, IExpenseStatus_Type, IGetExpenseCostRowsRequest, IExpenseCostRow, IGetExpensesRequest, IExpense, IGetExpensesWithProjectsRequest, IExpensesWithProjects, IExpenseEvent_Type, IExpenseEventsAndPurchases, IFullExpense, IInsertOrUpdateFullExpensesRequest } from "../ProlifeSdk/interfaces/expense/IExpenseService";
import { IService } from "../Core/interfaces/IService";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";

interface IBlogViewModels
{
    [type : string] : new (serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver) => BlogEventBase;
}

class ExpenseService implements IExpenseService, IEventUiProvider
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private eventsViewModels : IBlogViewModels = {};

    private nextTermporaryId: number;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IExpenseService>(), this);

        this.nextTermporaryId = -1;
    }
    
    InitializeService() {
        new ExpenseApplication(this.serviceLocator);
        new ExpenseStatusesSettingsManager();

        this.eventsViewModels[ProlifeSdk.ExpenseEventType_Expense] = ExpenseEvent;
    }

    getServiceType(): string
    {
        return ProlifeSdk.ExpenseServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getUIForEvent(event: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        let eventConstructor = this.eventsViewModels[event.EventType] || BlogEventBase;

        let eventInstance: BlogEventBase = new eventConstructor(this.serviceLocator, contextEventsObserver);
        eventInstance.LoadItemFromServerObject(event);
        return eventInstance;
    }

    GetTemporaryId(): number {
        return this.nextTermporaryId--;
    }

    GetGlobalTemporaryId(): Promise<number> {
        return this.ajaxService.Post<number>("Expense-api/Expense", "GetTemporaryId", {
            background: true
        });
    }

    ShowExpenseEditor(expenseId: number = null): Promise<IExtendedFullExpense> {
        return this.dialogsService.ShowModal<IExtendedFullExpense>(new ExpenseEditDialogComponent({
            expenseId
        }));
    }

    GetCostRowTypes(includeDeleted: boolean | null): Promise<IExpenseCostRowType[]> {
        let result = this.ajaxService.Post<IExpenseCostRowType[]>("Expense-api/ExpenseCostRowType", "GetCostRowTypes", {
            background: true,
            methodData: {
        		includeDeleted: includeDeleted,
        	}
        });



        return result;
    }

    InsertOrUpdateCostRowTypes(types: ICostRowType_Type[] | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Expense-api/ExpenseCostRowType", "InsertOrUpdateCostRowTypes", {
            background: true,
            methodData: {
        		types: types,
        	}
        });

        return result;
    }

    GetExpenseProjectsByIds(ids: number[] | null): Promise<IExpenseProject[]> {
        let result = this.ajaxService.Post<IExpenseProject[]>("Expense-api/ExpenseProjects", "GetExpenseProjectsByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });



        return result;
    }

    GetExpenseStatuses(textFilter: string | null, getDeleted: boolean | null, skip: number | null, count: number | null): Promise<IExpenseStatus[]> {
        let result = this.ajaxService.Post<IExpenseStatus[]>("Expense-api/ExpenseStatus", "GetExpenseStatuses", {
            background: true,
            methodData: {
        		textFilter: textFilter,
        		getDeleted: getDeleted,
        		skip: skip,
        		count: count,
        	}
        });



        return result;
    }

    InsertOrUpdateExpenseStatuses(statuses: IExpenseStatus_Type[] | null): Promise<IExpenseStatus[]> {
        let result = this.ajaxService.Post<IExpenseStatus[]>("Expense-api/ExpenseStatus", "InsertOrUpdateExpenseStatuses", {
            background: true,
            methodData: {
        		statuses: statuses,
        	}
        });



        return result;
    }

    GetExpenseProjects(expenseId: number | null, textFilter: string | null, skip: number | null, count: number | null): Promise<IExpenseProject[]> {
        let result = this.ajaxService.Post<IExpenseProject[]>("Expense-api/ExpenseProjects", "GetExpenseProjects", {
            background: true,
            methodData: {
        		expenseId: expenseId,
        		textFilter: textFilter,
        		skip: skip,
        		count: count,
        	}
        });



        return result;
    }

    GetExpenseCostRows(request: IGetExpenseCostRowsRequest): Promise<IExpenseCostRow[]> {
        let result = this.ajaxService.Post<IExpenseCostRow[]>("Expense-api/ExpenseProjects", "GetExpenseCostRows", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetExpenseCostRowsByIds(ids: number[] | null, canViewCompanyCosts: boolean | null): Promise<IExpenseCostRow[]> {
        let result = this.ajaxService.Post<IExpenseCostRow[]>("Expense-api/ExpenseProjects", "GetExpenseCostRowsByIds", {
            background: true,
            methodData: {
        		ids: ids,
        		canViewCompanyCosts: canViewCompanyCosts,
        	}
        });



        return result;
    }

    GetExpenses(request: IGetExpensesRequest): Promise<IExpense[]> {
        let result = this.ajaxService.Post<IExpense[]>("Expense-api/Expense", "GetExpenses", {
            background: true,
            methodData: request
        });



        return result;
    }

    DeleteExpense(expenseId: number | null, userId: number | null, expensesAdministrativeEdit: boolean | null, editResourceExpenses: boolean | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Expense-api/Expense", "DeleteExpense", {
            background: true,
            methodData: {
        		expenseId: expenseId,
        		userId: userId,
        		expensesAdministrativeEdit: expensesAdministrativeEdit,
        		editResourceExpenses: editResourceExpenses,
        	}
        });



        return result;
    }

    GetExpensesWithProjects(request: IGetExpensesWithProjectsRequest): Promise<IExpensesWithProjects> {
        let result = this.ajaxService.Post<IExpensesWithProjects>("Expense-api/Expense", "GetExpensesWithProjects", {
            background: true,
            methodData: request
        });



        return result;
    }

    ManageExpensesEvents(expensesEvents: IExpenseEvent_Type[] | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Expense-api/Expense", "ManageExpensesEvents", {
            background: true,
            methodData: {
        		expensesEvents: expensesEvents,
        	}
        });



        return result;
    }

    GetExpenseEventsAndPurchases(expenseIds: number[] | null): Promise<IExpenseEventsAndPurchases> {
        let result = this.ajaxService.Post<IExpenseEventsAndPurchases>("Expense-api/Expense", "GetExpenseEventsAndPurchases", {
            background: true,
            methodData: {
        		expenseIds: expenseIds,
        	}
        });



        return result;
    }

    GetFullExpensesByIds(expenseIds: number[] | null, userId: number | null, viewResourceExpenses: boolean | null, expensesAdministrativeEdit: boolean | null): Promise<IFullExpense> {
        let result = this.ajaxService.Post<IFullExpense>("Expense-api/Expense", "GetFullExpensesByIds", {
            background: true,
            methodData: {
        		expenseIds: expenseIds,
        		userId: userId,
        		viewResourceExpenses: viewResourceExpenses,
        		expensesAdministrativeEdit: expensesAdministrativeEdit,
        	}
        });



        return result;
    }

    InsertOrUpdateFullExpenses(request: IInsertOrUpdateFullExpensesRequest): Promise<IFullExpense> {
        let result = this.ajaxService.Post<IFullExpense>("Expense-api/Expense", "InsertOrUpdateFullExpenses", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ExpenseService(serviceLocator);
}
