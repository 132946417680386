import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import jss from "jss";
import { CustomTagList } from "./CustomTag";
import { EventUsersList } from "./EventUsersList";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IEventTag, ITag } from "../../interfaces/ITag";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../ProlifeTextResources";
import { SegmentedInput } from "../../../Components/SegmentedInput";
import { DropdownList } from "../../../Components/DropdownList";
import { CheckBox } from "../../../Components/Checkbox";
import { If } from "../../../Components/IfIfNotWith";
import { CustomTagsDataSource } from "../../../DataSources/CustomTagsDataSource";
import { SelectMultiple } from "../../../Components/SelectMultiple";

const styleSheet = jss.createStyleSheet({
    eventClassificationInput: {
        "& .priority": {
            "& .btn": {
                padding: "7px 0px",

                "&.blue, &:hover": {
                    backgroundColor: "#3276b1 !important",
                    borderColor: "#2a6496 !important",
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type EventClassificationInputProps = {
    forwardRef?: (component: _EventClassificationInput) => void;
};

export function EventClassificationInput(props: EventClassificationInputProps) {
    const C = require("./EventClassificationInput")._EventClassificationInput as typeof _EventClassificationInput;
    return <C {...props} />;
}

export class _EventClassificationInput {
    inQuality: ko.Observable<boolean> = ko.observable(false);
    isAdministration: ko.Observable<boolean> = ko.observable(false);
    isCommercial: ko.Observable<boolean> = ko.observable(false);
    isTechnical: ko.Observable<boolean> = ko.observable(false);
    isSigned: ko.Observable<boolean> = ko.observable(false);
    signatureImage: ko.Observable<string> = ko.observable();
    canSign: ko.Observable<boolean> = ko.observable(false);
    priority: ko.Observable<string> = ko.observable(ProlifeSdk.Tag_Priority_Normal);
    status: ko.Observable<string> = ko.observable(ProlifeSdk.Tag_State_Undefined);

    public CustomTags: ko.ObservableArray<string> = ko.observableArray([]);
    public TagsDataSource: CustomTagsDataSource = new CustomTagsDataSource();
    public AllowedUsersList: ko.ObservableArray<string> = ko.observableArray([]);
    public AllowedUsersDataSource: CustomTagsDataSource = new CustomTagsDataSource();

    public tags: CustomTagList;
    public AllowedUsers: EventUsersList;

    public priorityNormal = ProlifeSdk.Tag_Priority_Normal;
    public priorityLow = ProlifeSdk.Tag_Priority_Low;
    public priorityHigh = ProlifeSdk.Tag_Priority_High;

    public disableInQualityFlag: ko.Observable<boolean> = ko.observable(false);
    public signatureCanvas: ko.Observable<any> = ko.observable();

    public states: string[] = [
        ProlifeSdk.Tag_State_Undefined,
        ProlifeSdk.Tag_State_ToDo,
        ProlifeSdk.Tag_State_InProgress,
        ProlifeSdk.Tag_State_Done,
    ];

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    constructor(private props: EventClassificationInputProps) {
        this.TagsDataSource.setFreeTextEnabled(true);
        this.AllowedUsersDataSource.setEnabledProviders(ProlifeSdk.UsersEntityType);

        this.tags = new CustomTagList();
        this.AllowedUsers = new EventUsersList(ProlifeSdk.Tag_AllowedUser);
        this.isSigned.subscribe((value) => {
            if (value) this.inQuality(true);

            this.disableInQualityFlag(value);
        });
        this.signatureCanvas.subscribe(this.signatureCanvasAttached.bind(this));
        this.canSign(this.authorizationService.isAuthorized("Blog_LockUnlock"));

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    public setStatus(status: string) {
        this.status(status);
    }

    public setValues(
        priority: string,
        status: string,
        customMetaTags: IEventTag[],
        allowedUsersTags: IEventTag[],
        inQuality: boolean,
        isAdministration: boolean,
        isCommercial: boolean,
        isTechnical: boolean,
        isSigned: boolean,
        signature: string
    ) {
        this.priority(priority);
        this.inQuality(inQuality);
        this.isAdministration(isAdministration);
        this.isCommercial(isCommercial);
        this.isTechnical(isTechnical);
        this.isSigned(isSigned);
        this.signatureImage(signature);
        this.status(status);
        this.tags.clear();
        this.tags.addElements(customMetaTags);
        this.AllowedUsers.clear();
        this.AllowedUsers.addElements(allowedUsersTags);
    }

    private signatureCanvasAttached() {
        if (this.signatureImage()) {
            var canvas: fabric.Canvas = this.signatureCanvas();
            canvas.setBackgroundImage(this.signatureImage(), () => {
                canvas.renderAll();
            });
        }
    }

    public getTags(): ITag[] {
        var classificatonTags: ITag[] = [];

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_State,
            TagTypeId: ProlifeSdk.TagType_String,
            Value: this.status(),
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_Priority,
            TagTypeId: ProlifeSdk.TagType_String,
            Value: this.priority(),
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_TracingQuality,
            TagTypeId: ProlifeSdk.TagType_Bool,
            Value: this.inQuality() ? ProlifeSdk.Tag_TracingQuality_true : ProlifeSdk.Tag_TracingQuality_false,
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_IsAdministration,
            TagTypeId: ProlifeSdk.TagType_Bool,
            Value: this.isAdministration()
                ? ProlifeSdk.Tag_IsAdministration_true
                : ProlifeSdk.Tag_IsAdministration_false,
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_IsCommercial,
            TagTypeId: ProlifeSdk.TagType_Bool,
            Value: this.isCommercial() ? ProlifeSdk.Tag_IsCommercial_true : ProlifeSdk.Tag_IsCommercial_false,
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_IsTechnical,
            TagTypeId: ProlifeSdk.TagType_Bool,
            Value: this.isTechnical() ? ProlifeSdk.Tag_IsTechnical_true : ProlifeSdk.Tag_IsTechnical_false,
        });

        classificatonTags.push({
            TagName: ProlifeSdk.Tag_IsSigned,
            TagTypeId: ProlifeSdk.TagType_Bool,
            Value: this.isSigned() ? ProlifeSdk.Tag_IsSigned_true : ProlifeSdk.Tag_IsSigned_false,
        });

        if (this.isSigned()) {
            var canvas: fabric.Canvas = this.signatureCanvas();

            classificatonTags.push({
                TagName: ProlifeSdk.Tag_Signature,
                TagTypeId: ProlifeSdk.TagType_Image,
                Value: (canvas.toDataURL as any)("image/png", 0.8),
            });
        }

        this.tags.getElement().forEach((tag: ITag) => {
            classificatonTags.push(tag);
        });

        this.addCustomTagsToOutputList(this.CustomTags(), classificatonTags);

        this.AllowedUsers.getElement().forEach((tag: ITag) => {
            classificatonTags.push(tag);
        });

        this.addCustomTagsToOutputList(this.AllowedUsersList(), classificatonTags);

        return classificatonTags;
    }

    private addCustomTagsToOutputList(source: string[], target: ITag[]) {
        for (let customTag of source) {
            const tag = JSON.parse(customTag.split("#").firstOrDefault()) as ITag;
            target.push(tag);
        }
    }

    public toggleSignature(item, event: Event) {
        var element = $(event.currentTarget);
        element.removeClass("active");
        element.text(ProlifeSdk.TextResources.ProlifeSdk.Signature);

        var canvas: fabric.Canvas = this.signatureCanvas();
        canvas.isDrawingMode = !canvas.isDrawingMode;
        canvas.setDimensions({ width: $(window).width() * 0.6, height: $(window).height() * 0.3 });
        canvas.forEachObject((o) => (o.hasControls = o.hasBorders = o.selectable = false));
        canvas.selection = false;
        canvas.renderAll();

        if (canvas.isDrawingMode) {
            canvas.backgroundImage = undefined;
            canvas.forEachObject(canvas.remove.bind(canvas));
            element.addClass("active");
            element.text(ProlifeSdk.TextResources.ProlifeSdk.Done);
        }
    }

    render() {
        const classification = this;

        return ComponentUtils.bindTo(
            <div className={classes.eventClassificationInput}>
                <div className="row">
                    <div className="col-md-5">
                        <SelectMultiple.WithLabel
                            value={this.CustomTags}
                            dataSource={this.TagsDataSource}
                            label={TextResources.Blog.BlogEventTags}
                            placeholder={TextResources.ProlifeSdk.SearchPlaceholder}
                            allowClear
                            minimumInputLength={1}
                        />
                    </div>
                    <div className="col-md-2">
                        <div className="form-group priority">
                            <SegmentedInput
                                options={[
                                    { icon: "fa fa-arrows-h", value: ProlifeSdk.Tag_Priority_Normal },
                                    { icon: "fa fa-long-arrow-up", value: ProlifeSdk.Tag_Priority_High },
                                    { icon: "fa fa-long-arrow-down", value: ProlifeSdk.Tag_Priority_Low },
                                ]}
                                value={this.priority}
                                label={TextResources.Blog.BlogEventPriority}
                                size="md"
                                itemStyle={{
                                    width: "35px",
                                    color: "white",
                                    backgroundColor: "#428bca",
                                    borderColor: "#357ebd",
                                    boxShadow: "unset",
                                }}
                                noCircleBtn
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <DropdownList
                            label={TextResources.Blog.BlogEventStatus}
                            value={this.status}
                            values={this.states}
                            valueGetter={(s) => s}
                            textGetter={(s) => s}
                        />
                    </div>
                    <div className="col-md-2">
                        <CheckBox
                            label={TextResources.Blog.BlogEventOnQuality}
                            checked={this.inQuality}
                            readOnly={this.disableInQualityFlag}
                            switch
                            switchLabels={{
                                onLabel: TextResources.ProlifeSdk.Yes,
                                offLabel: TextResources.ProlifeSdk.No,
                            }}
                            simple={false}
                        />
                    </div>
                </div>
                <div className="row margin-top-10">
                    <div className="col-md-5">
                        <SelectMultiple.WithLabel
                            value={this.AllowedUsersList}
                            dataSource={this.AllowedUsersDataSource}
                            label={TextResources.Blog.BlogEventauthorizedUsers}
                            placeholder={TextResources.ProlifeSdk.SearchPlaceholder}
                            allowClear
                            minimumInputLength={1}
                        />
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-3">
                                <CheckBox
                                    label={TextResources.Blog.BlogEventIsAdministrative}
                                    checked={this.isAdministration}
                                    switch
                                    switchLabels={{
                                        onLabel: TextResources.ProlifeSdk.Yes,
                                        offLabel: TextResources.ProlifeSdk.No,
                                    }}
                                    simple={false}
                                />
                            </div>
                            <div className="col-md-3">
                                <CheckBox
                                    label={TextResources.Blog.BlogEventIsCommercial}
                                    checked={this.isCommercial}
                                    switch
                                    switchLabels={{
                                        onLabel: TextResources.ProlifeSdk.Yes,
                                        offLabel: TextResources.ProlifeSdk.No,
                                    }}
                                    simple={false}
                                />
                            </div>
                            <div className="col-md-3">
                                <CheckBox
                                    label={TextResources.Blog.BlogEventIsTechnical}
                                    checked={this.isTechnical}
                                    switch
                                    switchLabels={{
                                        onLabel: TextResources.ProlifeSdk.Yes,
                                        offLabel: TextResources.ProlifeSdk.No,
                                    }}
                                    simple={false}
                                />
                            </div>
                            <div className="col-md-3">
                                <If condition={this.canSign}>
                                    {() => (
                                        <CheckBox
                                            label={TextResources.Blog.BlogEventSignature}
                                            checked={this.isSigned}
                                            switch
                                            switchLabels={{
                                                onLabel: TextResources.ProlifeSdk.Yes,
                                                offLabel: TextResources.ProlifeSdk.No,
                                            }}
                                            simple={false}
                                        />
                                    )}
                                </If>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row margin-top-10">
                    <div className="col-md-12">
                        <div style="display: none">
                            <canvas
                                width="600"
                                height="150"
                                data-bind={{ fabric: classification.signatureCanvas }}
                            ></canvas>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "classification"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(EventClassificationInput);
}
