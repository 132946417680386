import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/07/2017
 * Time: 10:04
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ISampleLog } from "../../../../ProlifeSdk/interfaces/survey/ISample";

export class SampleLogViewModel implements ISampleLog {
    public Id: number;
    public Action: string;
    public ActionMaker: number;
    public ActionDate: Date;
    public ResultId: number;
    public ResultLabel: string;

    public ObservableResult: ko.Observable<number> = ko.observable();

    public Success: ko.Computed<boolean>;
    public Partial: ko.Computed<boolean>;
    public Error: ko.Computed<boolean>;

    public ResultTitle: ko.Computed<string>;

    constructor(log: ISampleLog) {
        this.Id = log.Id;
        this.Action = log.Action;
        this.ActionMaker = log.ActionMaker;
        this.ActionDate = log.ActionDate;
        this.ResultId = log.ResultId;
        this.ResultLabel = log.ResultLabel;

        this.ObservableResult(this.ResultId);

        this.Success = ko.computed(() => {
            return this.ObservableResult() == ProlifeSdk.ImportActionSuccess;
        });

        this.Partial = ko.computed(() => {
            return this.ObservableResult() == ProlifeSdk.ImportActionPartial;
        });
        this.Error = ko.computed(() => {
            return this.ObservableResult() == ProlifeSdk.ImportActionError;
        });

        this.ResultTitle = ko.computed(() => {
            return (this.Success() ? ProlifeSdk.TextResources.Survey.ImportActionSuccessTitle :
                (this.Partial() ? ProlifeSdk.TextResources.Survey.ImportActionPartialTitle : ProlifeSdk.TextResources.Survey.ImportActionErrorTitle));
        });
    }
}