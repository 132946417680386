import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IComponentApplication, IApplicationNavBarAction } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { IOPAService } from "../../Core/interfaces/IOPAService"
import { GDPRMainPage } from "./GDPRMainPage";
import * as React from "@abstraqt-dev/jsxknockout"
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { GDPRTreatmentDialog } from "./GDPRTreatmentDialog";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { Right } from "../../Core/Authorizations";
import { reloadNow } from "../../Core/utils/ComponentUtils";

export class GDPRApplication implements IComponentApplication
{
    public tileColor : string = 'tile-green';
    private editor : GDPRMainPage;

    public navBarActions: ko.ObservableArray<IApplicationNavBarAction> = ko.observableArray([]);
    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;

    @LazyImport(nameof<IOPAService>())
    private OPAService : IOPAService;
    
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    @Right("GDPR_CanInsertOrUpdateTreatments")
    private canInsertOrUpdateTreatments: boolean;

    constructor()
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return ProlifeSdk.GDPRApplicationCode;
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.GDPR.ApplicationName;
    }

    getIcon(): string
    {
        return "fa fa-legal";
    }

    registerRoutes() : void
    {
        this.OPAService.Get("#/GDPR", () => this.startApplication());
    }

    getApplicationRoute() : string
    {
        return "#/GDPR";
    }

    onGoingDown(): void {
        this.navBarActions([]);
    }

    getComponent(){
        let GdprMainPage = require("./GDPRMainPage").GDPRMainPage as typeof GDPRMainPage;
        return <GdprMainPage ref={e => this.editor = e} />
    }

    startApplication() {
        this.onGoingDown();

        this.navBarActions([{
            Name: TextResources.GDPR.AddNewTreatment, Icon: ko.observable("fa fa-plus"), CanRun: ko.observable(this.canInsertOrUpdateTreatments), Run: () => this.editor.goNewTreatment()
        }]);

        this.desktopService.SystemHeader.setSidebarVisibility(true);
        this.applicationsService.startApplication(this);
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(GDPRApplication);
}