import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";

export class ArticleTransformImportDialog extends DialogComponentBase {
    private selectedFiles: ko.ObservableArray<File> = ko.observableArray();

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    constructor() {
        super({});
        this.title("Importa Trasformazioni");
    }

    public show() {
        return this.dialogsService.ShowModal(this);
    }

    public uploadFile(): Promise<void> {
        this.desktopService.BlockPageUI(TextResources.Warehouse.ImportInProgress);
        const files = this.selectedFiles();
        const data = {};

        for (let i = 0; i < files.length; i++) {
            data["transforms" + i] = files[i];
        }

        return this.ajaxService.Upload<void>("Warehouse-api/Transforms", "Import", data).finally(() => {
            this.desktopService.UnblockPageUI();
        });
    }

    async action() {
        if (this.selectedFiles().length == 0) {
            this.modal.close(false);
            return;
        }

        this.uploadFile().then(() => {
            this.modal.close(true);
        });
    }

    async downloadTemplate() {
        return this.ajaxService.Download("../Downloads", "Importazione Trasformazioni.xlsx", {
            overrideMethod: "GET",
            overrideFileName: "Importazione Trasformazioni.xlsx",
        });
    }

    renderBody() {
        const $data = this;

        return (
            <div className="input-group fileinput-new" data-bind={{ fileInput: {} }}>
                <div className="form-control uneditable-input" data-trigger="fileinput">
                    <i className="fa fa-file fileinput-exists"></i>&nbsp;
                    <span className="fileinput-filename"></span>
                </div>
                <span className="input-group-addon btn default btn-file">
                    <span className="fileinput-new">Seleziona file</span>
                    <span className="fileinput-exists">Cambia file</span>
                    <input type="file" data-bind={{ file: $data.selectedFiles }} multiple />
                </span>
                <a href="#" className="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">
                    Rimuovi file
                </a>
            </div>
        );
    }

    renderFooter() {
        const $data = this;

        return (
            <>
                <button
                    type="button"
                    className="btn btn-success pull-left"
                    data-bind={{ asyncClick: $data.downloadTemplate }}
                >
                    <i className="fa fa-download"></i>&nbsp;Scarica Modello
                </button>
                <a href="#" class="btn btn-default" data-bind={{ click: $data.close }}>
                    {this.params.closeLabel ?? TextResources.ProlifeSdk.Close}
                </a>
                {!this.params.noPrompt && (
                    <a href="#" class="btn btn-primary" data-bind={{ click: $data.action }}>
                        {this.params.actionLabel ?? TextResources.ProlifeSdk.Save}
                    </a>
                )}
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(ArticleTransformImportDialog);
}
