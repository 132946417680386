import { Validator } from "../../../ProlifeSdk/ValidationService";

function isPartitaIva(val)
{
	var matches = val.match(/([A-Z]{2})([0-9 ]*)/i);
    if(matches && matches.length == 3) {
		if(matches[1].toUpperCase() != "IT")
			return true;
			
        val = matches[2];
    }

	var code = val.toString().substring(7,10);
	if (!(parseInt(code) > 0 && parseInt(code) <122))
		return false;

	var x = 0;
	var y = 0;
	var z = 0;
	var T = 0;

	for (let i = 0; i < val.length; i++) {
		if (((i + 1) % 2) == 0) {
			y = y + parseInt(val[i]);
			if (parseInt(val[i]) >= 5) {
				z = z + 1;
			}
		} else {
			x = x + parseInt(val[i]);
		}
	}
	T = (x + (2 * y) + z) % 10;
	return T == 0;
}

Validator.prototype["mustBePiva"] = function<T,V>(this: Validator<T>, selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean) {
    this.rules.push({
        validate: (value: T) => {
            if(onlyIf && !onlyIf(value))
                return this.ok();

            let val = selector(value);
            if(!isPartitaIva(val)) {
                return this.error(errorMessage ?? "Il valore deve essere una partita IVA valida: " + selector.toString());
            }
            return this.ok();
        }
    });
    return this;
};

declare module "../../../ProlifeSdk/ValidationService" {
    interface IValidator<T> {
        mustBePiva?(selector : (value : T) => string, errorMessage?: string, onlyIf?: () => boolean) : IValidator<T>;
    }
}