import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { ReportComponentDataSource } from "../../Components/ReportComponentWithDataSource";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type DataSourceItemProps = {
    dataSource: ReportComponentDataSource;
    onDelete: () => void;
}

export function DataSourceItem(props: DataSourceItemProps) {
    const C = require("./DataSourceItem")._DataSourceItem as typeof _DataSourceItem;
    return <C {...props} />;
}

export class _DataSourceItem {
    static defaultProps: Partial<DataSourceItemProps> = {
    }

    constructor(private props : DataSourceItemProps) {
        
    }
    
    render() {
        return <a href="javascript:void(0)" className="list-group-item">
            {this.props.dataSource.getData().name}
            <button className="btn btn-danger btn-xs btn-circle pull-right" onClick={() => this.props.onDelete()}>
                <i className="fa fa-trash-o"></i>
            </button>
        </a>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DataSourceItem);
}