import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IScheduleService, IScheduleForList } from "../../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";

export class SchedulesDropDownList
{
    private schedulesService : IScheduleService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    public selectedId : ko.Observable<number> = ko.observable();

    constructor(private serviceLocator : IServiceLocator)
    {
        this.schedulesService = <IScheduleService>serviceLocator.findService(ProlifeSdk.ScheduleServiceType);
    }

    public search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.schedulesService.searchSchedulesForList(query.term || "")
                .then((data: IScheduleForList[]) => {
                    query.callback({
                        results: data.map((s) =>
                        {
                            return {
                                id: s.Id,
                                text: s.Description
                            };
                        })
                    });
                });
        }, 500);
    }

    public getSelected(element, callback)
    {
        if(this.selectedId()) {
            this.schedulesService.getScheduleForList(this.selectedId())
                .then((s: IScheduleForList) => callback({
                    id: s.Id,
                    text: s.Description
                }));
        }
    }
}