import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 24/08/2017
 * Time: 16:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IQuestionnaireStateSettingManager } from "../../../../interfaces/IQuestionnaireStateSettingManager";
import { IQuestionnaireState } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class QuestionnaireStatesControlsEntityProvider implements IControlsEntityProvider {
    private statesManager: IQuestionnaireStateSettingManager;
    private lastTimeout: number;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <IQuestionnaireStateSettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireState);
    }

    public findEntities(query : any)
    {
        var searchFilter : string = query.term || "";

        var states: IQuestionnaireState[] = this.statesManager.getQuestionnaireStates();
        var filteredStates: IQuestionnaireState[] = states.filter((s: IQuestionnaireState) => {
            s.Description.toUpperCase().indexOf(searchFilter.trim().toUpperCase()) > -1;
        });

        query.callback({
            results: filteredStates.map((s : IQuestionnaireState) => {
                return {
                    id: s.Id,
                    text: s.Description
                };
            })
        });
    }

    public findEntity(element, callback) {
        var id = parseInt(<string> $(element).val());
        if(!isNaN(id) && id > 0)
        {
            var s: IQuestionnaireState = this.statesManager.getQuestionnaireStateById(id);

            callback({
                id: s.Id,
                text: s.Description
            });
        }
    }
}