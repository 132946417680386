import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { ExpensesViewModel } from "./ui/ExpensesViewModel";
import "./ui/dialogs/ExpenseEditDialog";
import "./ui/dialogs/ExpenseMarkupPopoverComponent";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication, IApplicationContextAction } from "../../Desktop/interfaces/IApplication";
import { LazyImport } from "../../Core/DependencyInjection";

export class ExpenseApplication implements IApplication
{
    public templateName : string = 'expenses';
    public templateUrl : string = 'expense/templates';
    public templateData : ExpensesViewModel;
    public tileColor : string = 'tile-red';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);
    public contextActions: ko.ObservableArray<IApplicationContextAction> = ko.observableArray([]);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Expense";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Expenses.Expenses;
    }

    getIcon(): string
    {
        return "icon-calculator";
    }

    registerRoutes() : void
    {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/Expense", (context) => this.start());
    }

    getApplicationRoute() : string
    {
        return "#/Expense";
    }

    onGoingDown() : void
    {
        this.templateData.dispose();
    }

    private start()
    {
        this.templateData = new ExpensesViewModel();

        this.contextActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Expenses.NewExpense),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.templateData.CreateNew.bind(this.templateData)
        }]);

        this.applicationsService.startApplication(this);
    }
}