import { IProLifeStringUtilities } from "../../interfaces/prolife-sdk/IProLifeUtilitiesLibrary";

export class StringUtilities implements IProLifeStringUtilities
{
    public Format(format : string, args : string[]) : string
    {
        for (let i = 0; i < args.length; i++)
        {
            const regEx = new RegExp("\\{" + (i) + "\\}", "gm");
            format = format.replace(regEx, args[i]);
        }

        return format;
    }

    public static Capitalize(value : string) : string {
        if(!value) return value;

        return value[0].toUpperCase() + value.substring(1);
    }

    public static isNullOrWhiteSpace(value : string) : boolean {
        if(value === null || value === undefined || value.trim() === "") return true;
        return false;
    }
}
