import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 11:43
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CallResultEditingViewModel } from "./ui/CallResultEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICallResultSettingManager } from "../../../interfaces/ICallResultSettingManager";
import { ICallResult, ILogicalCallResult } from "../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";

export class CallResultSettingManager implements ICallResultSettingManager {
    private ajaxService: IAjaxService;
    private modulesService: IModulesService;

    private results: ICallResult[] = [];
    private logicalResults: ILogicalCallResult[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService: ISettingsService = <ISettingsService>(
            this.serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);

        this.logicalResults.push(<ILogicalCallResult>{
            Id: 1,
            Description: ProlifeSdk.TextResources.SurveyWizard.NotRespondState,
            TypeId: 1,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 2,
            Description: ProlifeSdk.TextResources.SurveyWizard.UnavailableState,
            TypeId: 1,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 3,
            Description: ProlifeSdk.TextResources.SurveyWizard.WrongNumber,
            TypeId: 2,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 4,
            Description: ProlifeSdk.TextResources.SurveyWizard.MaxAttemptExceeded,
            TypeId: 3,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 5,
            Description: ProlifeSdk.TextResources.SurveyWizard.RefuseToRespond,
            TypeId: 2,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 6,
            Description: ProlifeSdk.TextResources.SurveyWizard.SuccessfulInterview,
            TypeId: 3,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 7,
            Description: ProlifeSdk.TextResources.SurveyWizard.AbortedCall,
            TypeId: 2,
        });
        this.logicalResults.push(<ILogicalCallResult>{
            Id: 8,
            Description: ProlifeSdk.TextResources.SurveyWizard.ContinueInAnotherMoment,
            TypeId: 1,
        });
    }

    load(): Promise<ICallResult[]> {
        return this.ajaxService
            .Post<ICallResult[]>("SurveyWizard-api/SurveyWizardApi", "GetAllMotivations", {})
            .then((results: ICallResult[]) => {
                this.results = results;
                return results;
            });
    }

    getName(): string {
        return ProlifeSdk.CallResult;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.SurveyWizard.CallResult;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyWizardApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "call-result",
            templateUrl: "surveywizard/templates/settings",
            viewModel: new CallResultEditingViewModel(this.serviceLocator, this),
        };
    }

    getLogicalCallResults(): ILogicalCallResult[] {
        return this.logicalResults;
    }

    getCallResults(type: number): ICallResult[] {
        return this.results.filter((r: ICallResult) => {
            return r.TypeId == type;
        });
    }

    getAllCallResults(): ICallResult[] {
        return this.results;
    }

    getCallResultById(id: number): ICallResult {
        const results: ICallResult[] = this.results.filter((r: ICallResult) => {
            r.Id == id;
        });
        return results[0];
    }

    addOrUpdate(state: ICallResult): Promise<ICallResult> {
        return this.ajaxService
            .Post<ICallResult>("SurveyWizard-api/SurveyWizardApi", "AddOrUpdateCallResult", {
                methodData: {
                    Label: state,
                },
            })
            .then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService
            .Post<number>("SurveyWizard-api/SurveyWizardApi", "RemoveCallResult", {
                methodData: {
                    Id: id,
                },
            })
            .then((i) => {
                this.removeState(id);
                return i;
            });
    }

    updateState(updatedState: ICallResult): void {
        let found = false;

        this.results.forEach((c: ICallResult) => {
            if (c.Id != updatedState.Id) return;

            found = true;

            c.Id = updatedState.Id;
            c.Label = updatedState.Label;
            c.CallResultId = updatedState.CallResultId;
            c.TypeId = updatedState.TypeId;
        });

        if (!found) this.results.push(updatedState);
    }

    removeState(stateId: number): void {
        for (let i = 0; i < this.results.length; i++) {
            if (this.results[i].Id == stateId) {
                this.results.splice(i, 1);
                break;
            }
        }
    }
}
