import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 11/06/2018
 * Time: 15:15
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITrustAuthorizationProcessService } from "../../../TrustAuthorizationProcessService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ITrustAuthorizationRequestDialog, ITrustAuthorizationRequest, ITrustAuthorizationResponse, IReplyToAuthorizationRequest } from "../../../../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";
import { Deferred } from "../../../../Core/Deferred";

export class TrustAuthorizationRequestDialog implements IDialog, ITrustAuthorizationRequestDialog {
    public templateName: string = "trust-authorization-request";
    public templateUrl: string = "invoices/templates/customertrust";
    public title: string = ProlifeSdk.TextResources.Invoices.CustomerTrustAuthorizationTitle;
    public modal: { close: (result?: any) => void; };

    public CustomerLink: string;
    public JobOrderLink: string;

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private deferredAction: Deferred<void> = null;
    private opened: boolean = false;

    constructor(public request: ITrustAuthorizationRequest, private pendingState: boolean) {
        this.CustomerLink = "#/" + ProlifeSdk.TextResources.Customers.CustomerURL.replace("{0}", this.request.CustomerId.toString());
        this.JobOrderLink = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + this.request.JobOrderId;
    }

    close(): void {
        this.deferredAction = new Deferred();
        this.trustAuthorizationProcessService.ReplyToAuthorizationRequest(this.GenerateResponse(ProlifeSdk.TrustAuthorizationRequestIgnored))
            .then((response: ITrustAuthorizationResponse) => {
                this.deferredAction.resolve();
                this.deferredAction = null;

                this.closeDialog();
            });
    }

    action(): void {
        this.deferredAction = new Deferred();
        this.trustAuthorizationProcessService.ReplyToAuthorizationRequest(this.GenerateResponse(ProlifeSdk.TrustAuthorizationRequestOnEvaluation))
            .then((response: ITrustAuthorizationResponse) => {
                if (response.Answer == ProlifeSdk.TrustAuthorizationRequestOnEvaluationByOther)
                    this.infoToastService.Info(ProlifeSdk.TextResources.Invoices.TrustAuthorizationOnEvaluationByOthers);

                this.deferredAction.resolve();
                this.deferredAction = null;

                this.closeDialog();
            });
    }

    denyAuthorization(response: ITrustAuthorizationResponse): void {
        this.deferredAction = new Deferred();
        this.trustAuthorizationProcessService.ReplyToAuthorizationRequest(this.GenerateResponse(ProlifeSdk.TrustAuthorizationRequestDenied))
            .then(() => {
                if (response.Answer == ProlifeSdk.TrustAuthorizationRequestOnEvaluationByOther)
                    this.infoToastService.Info(ProlifeSdk.TextResources.Invoices.TrustAuthorizationOnEvaluationByOthers);

                this.deferredAction.resolve();
                this.deferredAction = null;

                this.closeDialog();
            });
    }

    authorize(): void {
        this.deferredAction = new Deferred();
        this.trustAuthorizationProcessService.ReplyToAuthorizationRequest(this.GenerateResponse(ProlifeSdk.TrustAuthorizationRequestAccepted))
            .then((response: ITrustAuthorizationResponse) => {
                if (response.Answer == ProlifeSdk.TrustAuthorizationRequestOnEvaluationByOther)
                    this.infoToastService.Info(ProlifeSdk.TextResources.Invoices.TrustAuthorizationOnEvaluationByOthers);

                this.deferredAction.resolve();
                this.deferredAction = null;

                this.closeDialog();
            });
    }

    hide(): void {
        this.closeDialog();
    }

    showModal(): Promise<void> {
        this.opened = true;
        return this.dialogsService.ShowModal<void>(this, null, null, "invoices/templates/customertrust", "trust-authorization-request-dialog");
    }

    isOpened(): boolean {
        return this.opened;
    }

    onTrustAuthorizationResponse(response: ITrustAuthorizationResponse) {
        if (response.Answer == ProlifeSdk.TrustAuthorizationRequestIgnored && !response.SendByMe)
            return;

        if (!this.deferredAction) {
            this.closeDialog();
            return;
        }

        this.deferredAction.promise().then(() => {
            this.closeDialog();
        });
    }

    onTrustAuthorizationAbort() {
        if (this.opened) {
            this.infoToastService.Info(ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAborted);
            this.closeDialog();
        }
    }

    getRequest(): ITrustAuthorizationRequest {
        return this.request;
    }

    private GenerateResponse(answer: number): IReplyToAuthorizationRequest {
        return {
            AuthorizationRequest: this.request,
            Answer: answer
        };
    }

    private closeDialog(): void {
        this.opened = false;
        this.modal.close();
    }
}