/**
 * Created by m.buonaguidi on 15/06/2017.
 */

import * as ko from "knockout";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SectionForWizard } from "../wizard/SectionForWizard";
import { QuestionForWizard } from "../wizard/QuestionForWizard";
import { QuestionnaireIdentifierViewModel } from "../questionnaires/QuestionnaireIdentifierViewModel";
import { InterviewCompletedDialog } from "../wizard/dialogs/InterviewCompletedDialog";
import { DisableQuestionConfirmDialog } from "../wizard/dialogs/DisableQuestionConfirmDialog";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import {
    ISurveyWizard,
    ISurveyWizardSection,
    ISurveyWizardQuestion,
    ISurveyWizardService,
    ISurveyWizardAnswer,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ISurveyService, IInboundConfiguredSurvey } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IAgendaService } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IQuestionListObject } from "../../../../ProlifeSdk/interfaces/survey/IQuestionListObject";
import { ICustomerControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/customer/providers/ICustomerControlsEntityProvider";
import { IQuestionnaireIdentifier } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQuestionnaireIdentifier";
import { IQuestion } from "../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { ISection } from "../../../../ProlifeSdk/interfaces/survey/ISection";
import { IAnswerForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerForWizard";
import { IAnswerFromWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerFromWizard";
import { Deferred } from "../../../../Core/Deferred";

export class SurveyInboundWizardViewModel implements ISurveyWizard {
    public templateName = "wizard";
    public templateUrl = "surveywizard/templates/inbound";

    public Customer: ko.Observable<number> = ko.observable();
    public PossibleCustomers: ko.ObservableArray<ICustomer> = ko.observableArray();
    public Questionnaire: ko.Observable<QuestionnaireIdentifierViewModel> = ko.observable();
    public Sections: ko.ObservableArray<ISurveyWizardSection> = ko.observableArray([]);
    public Questions: ko.ObservableArray<ISurveyWizardQuestion> = ko.observableArray([]);

    public QuestionsList: ko.Computed<IQuestionListObject[]>;
    public CanReturnToPrevious: ko.Computed<boolean>;
    public HasAgenda: ko.Observable<boolean> = ko.observable();

    public DoActionForAllQuestions: ko.Observable<boolean> = ko.observable(false);

    private questionHistory: ko.ObservableArray<ISurveyWizardQuestion> = ko.observableArray([]);

    public SelectedQuestion: ko.Observable<any> = ko.observable();
    private SelectedQuestionIndex: ko.Observable<number> = ko.observable();
    public QuestionsNumber: ko.Computed<number>;
    public SectionsNumber: ko.Computed<number>;

    public CallingPhoneNumber: ko.Computed<string>;

    public LastQuestion: ISurveyWizardQuestion = null;
    private Finish = false;

    private surveyWizardService: ISurveyWizardService;
    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;
    private customersService: ICustomersService;

    public customerSearchService: ICustomerControlsEntityProvider;
    public ShowAllCustomers: ko.Observable<boolean> = ko.observable(false);

    //Properties non utilizzate
    public resume: string = null;
    public Campaign: ko.Observable<any> = ko.observable();
    public People: ko.Observable<any> = ko.observable();
    public Mode: ko.Observable<number> = ko.observable();
    //Fine Properties non utilizzate

    public ShowPrompt: boolean;

    constructor(
        private serviceLocator: IServiceLocator,
        private callId: number,
        private config: IInboundConfiguredSurvey,
        private phoneNumber: string
    ) {
        this.surveyWizardService = <ISurveyWizardService>(
            this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType)
        );
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.customersService = <ICustomersService>this.serviceLocator.findService(ProlifeSdk.CustomersServiceType);

        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        const customersProvider = entityProviderService.getEntityProvider(ProlifeSdk.CustomerEntityType);
        this.customerSearchService = <ICustomerControlsEntityProvider>customersProvider.getControlsProvider();
        this.customerSearchService.IsCustomer = true;

        this.ShowPrompt = true;

        this.HasAgenda(!!config.FkAgenda);

        this.CallingPhoneNumber = ko.computed(() => {
            return this.phoneNumber == "null" ? ProlifeSdk.TextResources.SurveyWizard.Unknown : this.phoneNumber;
        });

        this.QuestionsList = ko.computed(() => {
            const questions: IQuestionListObject[] = [];
            this.Questions().forEach((q: ISurveyWizardQuestion) => {
                questions.push(<IQuestionListObject>{
                    Data: q,
                    IsSection: false,
                });
            });
            this.Sections().forEach((s: ISurveyWizardSection) => {
                questions.push(<IQuestionListObject>{
                    Data: s,
                    IsSection: true,
                });
                s.Questions().forEach((sq: ISurveyWizardQuestion) => {
                    questions.push(<IQuestionListObject>{
                        Data: sq,
                        IsSection: false,
                    });
                });
            });
            return questions;
        });

        this.QuestionsNumber = ko.computed(() => {
            return this.QuestionsList().filter((o: IQuestionListObject) => {
                return !o.IsSection;
            }).length;
        });

        this.SectionsNumber = ko.computed(() => {
            return this.Sections().length;
        });

        this.CanReturnToPrevious = ko.computed(() => {
            return this.SelectedQuestionIndex() > 0;
        });

        this.Customer.subscribe(() => {
            this.surveyWizardService.updateInboundCall(this.callId, this.Customer());
        });

        this.inboundLoad();
    }

    public formatCustomerItem(customerItem) {
        return customerItem.locked ? "<i class='fa fa-lock'></i>  " + customerItem.text : customerItem.text;
    }

    private inboundLoad() {
        this.surveyWizardService
            .getQuestionnaireDataForWizard(this.config.FkQuestionnaire)
            .then((questionnaire: IQuestionnaireIdentifier) => {
                if (!questionnaire) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
                    return;
                }
                this.Questionnaire(new QuestionnaireIdentifierViewModel(questionnaire));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
            });

        this.loadPossibleCustomers();

        this.LoadQuestionnaire(this.config.FkQuestionnaire).then(() => {
            this.LoadAnswers().then(() => {
                if (this.QuestionsList().length > 0) {
                    const index: number = this.selectableQuestionIndex(0);
                    const question = this.QuestionsList()[index];
                    if (question) this.selectQuestion(<ISurveyWizardQuestion>question.Data);
                }
            });
        });
    }

    private loadPossibleCustomers() {
        this.customersService
            .GetCustomersByPhoneNumber(this.phoneNumber)
            .then((contacts: ICustomer[]) => {
                this.PossibleCustomers(contacts);
                this.Customer(contacts.length > 0 ? contacts[0].IdCliente : null);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetPeopleDataError);
            });
    }

    public CreateCustomer() {
        this.customersService.ui.showNewCustomerDialog(this.phoneNumber).then(() => {
            this.loadPossibleCustomers();
        });
    }

    private resumeWizardState(): void {
        let lastQuestionIndex = 0;
        for (let index = 0; index < this.QuestionsList().length; index++) {
            if (
                !this.QuestionsList()[index].IsSection &&
                (<ISurveyWizardQuestion>this.QuestionsList()[index].Data).Saved()
            ) {
                this.questionHistory.push(<ISurveyWizardQuestion>this.QuestionsList()[index].Data);
                const nextIndex = this.checkGoTo(<ISurveyWizardQuestion>this.QuestionsList()[index].Data);
                if (!nextIndex) {
                    lastQuestionIndex = index;
                    continue;
                }
                index = nextIndex - 1;
                lastQuestionIndex = index;
            }
        }
        lastQuestionIndex = this.selectableQuestionIndex(
            lastQuestionIndex == 0 ? lastQuestionIndex : lastQuestionIndex + 1
        );
        this.selectQuestion(<ISurveyWizardQuestion>this.QuestionsList()[lastQuestionIndex].Data);
    }

    public LoadQuestionnaire(questionnaireId: number): Promise<void> {
        const calls: Promise<any>[] = [];
        const def = new Deferred<void>();

        calls.push(
            this.surveyService
                .getQuestionsWithoutSection(questionnaireId)
                .then((questions: IQuestion[]) => {
                    if (!questions || questions.length == 0) {
                        return;
                    }
                    this.Questions(
                        questions.map((question: IQuestion) => {
                            const newQuestion = new QuestionForWizard(this.serviceLocator, question, this);
                            newQuestion.RegisterSelectionObserver(this);
                            return newQuestion;
                        })
                    );
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
                })
        );

        const sectionsDeferred = new Deferred();
        calls.push(sectionsDeferred.promise());

        this.surveyService
            .getQuestionnaireSections(questionnaireId)
            .then((sections: ISection[]) => {
                if (sections.length == 0) return;

                const sectionCalls: Promise<any>[] = [];

                this.Sections(
                    sections.map((s: ISection) => {
                        const sectionViewModel: SectionForWizard = new SectionForWizard(this.serviceLocator, s, this);
                        sectionCalls.push(sectionViewModel.LoadQuestions(s.Id));
                        return sectionViewModel;
                    })
                );

                Promise.all(sectionCalls)
                    .then(() => {
                        sectionsDeferred.resolve();
                    })
                    .catch(() => {
                        sectionsDeferred.reject();
                    });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadSectionsError);
                def.reject();
            });

        Promise.all(calls)
            .then(() => {
                if (this.QuestionsList().filter((q) => !q.IsSection).length == 0)
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionsNotFound);

                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    public LoadAnswers(): Promise<void> {
        const calls: Promise<IAnswerForWizard[]>[] = [];
        const def = new Deferred<void>();

        this.QuestionsList().forEach((q: IQuestionListObject) => {
            if (!q.IsSection) calls.push((<ISurveyWizardQuestion>q.Data).LoadAnswers());
        });

        Promise.all(calls)
            .then(() => {
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    public DoScroll(): void {
        if (this.SelectedQuestionIndex() == 0) {
            this.SelectedQuestion().DoScroll(true);
            this.SelectedQuestion().DoScroll(false);
            return;
        }
        this.QuestionsList()[this.SelectedQuestionIndex() - 1].Data.DoScroll(true);
        this.QuestionsList()[this.SelectedQuestionIndex() - 1].Data.DoScroll(false);
    }

    public FinishInterview(): void {
        /*var finishInterviewDialog = new FinishInterviewDialog.FinishInterviewDialog(this.serviceLocator, this.call);
        this.dialogService.ShowModal<boolean>(finishInterviewDialog, null, null, "surveywizard/templates/wizard/dialogs", "finish-interview-dialog")
            .then((result: boolean) => {
                if (!!result) {
                    this.ShowPrompt = false;
                    location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/" + this.Campaign().Id;
                }
            });*/

        location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/Inbound/Wait";
    }

    public NextStep(): void {
        this.SaveAnswer();
        this.DoScroll();
    }

    public PreviousStep(): void {
        this.selectPreviousQuestion();
        this.DoScroll();
    }

    public SaveAnswer(): void {
        this.saveStep()
            .then(() => {
                this.getNextQuestion();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.SaveWizardStepError);
            });
        return;
    }

    private getNextQuestion(): void {
        this.SelectedQuestion().Saved(true);
        this.questionHistory.push(this.SelectedQuestion());
        this.selectNextQuestion(this.SelectedQuestion());
        if (!this.Finish) {
            return;
        }
        this.finishInterview();
    }

    private finishInterview(): void {
        const completedInterviewDialog = new InterviewCompletedDialog();
        this.dialogService
            .ShowModal<boolean>(
                completedInterviewDialog,
                null,
                null,
                "surveywizard/templates/wizard/dialogs",
                "incoming-call-completed-dialog"
            )
            .then((finish: boolean) => {
                if (finish) {
                    this.ShowPrompt = false;
                    location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/Inbound/Wait";
                }
            });

        /*this.surveyWizardService.getQueueStateLabel(ProlifeSdk.SuccesfullInterviewState)
            .then((label: IQueueStateLabel) => {
                var resultCall: ICallResultData = <ICallResultData> {
                    Id: null,
                    CallsQueueId: this.call.Id,
                    CampaignId: this.call.CampaignId,
                    PeopleId: this.call.PeopleId,
                    AttemptNumber: this.call.AttemptNumber,
                    OperatorId: null,
                    CallDate: null,
                    ResultId: label.Id
                };

                this.surveyWizardService.saveCallState(null, true, resultCall)
                    .then(() => {
                        var completedInterviewDialog = new InterviewCompletedDialog.InterviewCompletedDialog();
                        this.dialogService.ShowModal<boolean>(completedInterviewDialog, null, null, "surveywizard/templates/wizard/dialogs", "interview-completed-dialog")
                            .then((finish: boolean) => {
                                if (finish) {
                                    this.ShowPrompt = false;
                                    location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/" + this.Campaign().Id;
                                }
                            });
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.FinishInterviewError);
                    });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.FinishInterviewError);
            });*/
    }

    public selectQuestion(question: ISurveyWizardQuestion): void {
        this.Questions().forEach((q: QuestionForWizard) => {
            q.Selected(false);
        });
        this.Sections().forEach((s: SectionForWizard) => {
            s.Questions().forEach((q: QuestionForWizard) => {
                q.Selected(false);
            });
        });
        question.Selected(true);
        question.EnableScroll = true;
        if (!this.isQuestionInHistory(question)) this.LastQuestion = question;
        this.SelectedQuestion(question);
        this.QuestionsList().forEach((q: IQuestionListObject, index: number) => {
            if (q.Data.Id == question.Id) this.SelectedQuestionIndex(index);
        });
    }

    private isQuestionInHistory(q: ISurveyWizardQuestion): boolean {
        let old = false;
        for (let i = 0; i < this.questionHistory().length; i++) {
            if (this.questionHistory()[i].Id == q.Id) {
                old = true;
                break;
            }
        }
        return old;
    }

    public selectPreviousQuestion(): void {
        if (this.questionHistory().length > 0) {
            for (let index = 0; index < this.questionHistory().length; index++) {
                if (this.questionHistory()[index].Id == this.SelectedQuestion().Id) {
                    if (index > 0) this.selectQuestion(this.questionHistory()[--index]);
                    return;
                }
            }
            this.selectQuestion(this.questionHistory()[this.questionHistory().length - 1]);
        }
    }

    private isSelectableQuestion(index: number): boolean {
        return !this.QuestionsList()[index].IsSection && this.QuestionsList()[index].Data.Enabled();
    }

    private skipSection(index: number): boolean {
        return this.QuestionsList()[index].IsSection && !this.QuestionsList()[index].Data.Enabled();
    }

    private selectableQuestionIndex(startIndex: number): number {
        let selectable = false;
        let skipSection = false;
        let questionIndex = startIndex;
        while (!selectable) {
            if (!this.QuestionsList()[questionIndex]) {
                this.Finish = true;
                return null;
            }

            if (this.QuestionsList()[questionIndex].IsSection) skipSection = this.skipSection(questionIndex);

            if (!skipSection) {
                selectable = this.isSelectableQuestion(questionIndex);
            }
            questionIndex += selectable ? 0 : 1;
        }
        return questionIndex;
    }

    public selectNextQuestion(question: ISurveyWizardQuestion): void {
        let questionIndex: number = null;
        for (let index = 0; index < this.QuestionsList().length; index++) {
            if (this.QuestionsList()[index].Data.Id == question.Id) {
                this.enableQuestionOrSection(this.SelectedQuestion());
                questionIndex = this.checkGoTo(this.SelectedQuestion());
                if (!questionIndex) questionIndex = this.selectableQuestionIndex(index + 1);
            }
        }
        if (!questionIndex) return;
        this.selectQuestion(<ISurveyWizardQuestion>this.QuestionsList()[questionIndex].Data);
    }

    private checkGoTo(question: ISurveyWizardQuestion): number {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers().filter((a: ISurveyWizardAnswer) => {
            return !!a.GoToLabel();
        });
        if (answers.length == 0) return null;
        const label = answers[0].GoToLabel();

        let questionIndex: number = null;
        this.QuestionsList().forEach((obj: IQuestionListObject, index: number) => {
            if (!obj.IsSection && obj.Data.Label() == label) questionIndex = index;
        });

        return questionIndex;
    }

    private enableQuestionOrSection(question: ISurveyWizardQuestion): void {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers().filter((a: ISurveyWizardAnswer) => {
            return !!a.EnableLabel();
        });
        answers.forEach((a: ISurveyWizardAnswer) => {
            this.QuestionsList().forEach((q: IQuestionListObject) => {
                if (q.Data.Label() == a.EnableLabel()) q.Data.Enabled(true);
            });
        });
    }

    public checkForDisablingQuestionWithAnswer(answer: ISurveyWizardAnswer): void {
        this.QuestionsList().forEach((obj: IQuestionListObject) => {
            if (obj.Data.Label() == answer.EnableLabel()) {
                this.checkIfHasAnswers(obj, answer);
            }
        });
    }

    private checkIfHasAnswers(object: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        if (!object.IsSection) {
            this.checkAnswersForSingleQuestion(object, answer);
            return;
        }
        this.checkAnswersForSectionQuestions(object, answer);
    }

    private checkAnswersForSectionQuestions(obj: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        const questionsWithAnswers: ISurveyWizardQuestion[] = (<ISurveyWizardSection>obj.Data)
            .Questions()
            .filter((q: ISurveyWizardQuestion) => {
                return q.SelectedAnswers().length > 0;
            });
        if (questionsWithAnswers.length == 0) {
            obj.Data.Enabled(false);
            return;
        }
        if (this.DoActionForAllQuestions()) {
            questionsWithAnswers.forEach((q: ISurveyWizardQuestion) => {
                this.resetQuestionAnswers(q);
            });
            obj.Data.Enabled(false);
            this.DoActionForAllQuestions(!this.DoActionForAllQuestions());
            return;
        }
        const confirmDialog = new DisableQuestionConfirmDialog(this, obj);
        this.dialogService
            .ShowModal<boolean>(
                confirmDialog,
                null,
                null,
                "surveywizard/templates/wizard/dialogs/",
                "disable-question-confirm-dialog"
            )
            .then((result: boolean) => {
                if (!result) {
                    this.abortAnswerChange(answer);
                } else {
                    this.resetQuestionAnswers(questionsWithAnswers[0]);
                    this.checkAnswersForSectionQuestions(obj, answer);
                }
            });
    }

    private checkAnswersForSingleQuestion(obj: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        if ((<ISurveyWizardQuestion>obj.Data).SelectedAnswers().length == 0) {
            return;
        }
        if (!this.DoActionForAllQuestions()) {
            const confirmDialog = new DisableQuestionConfirmDialog(this, obj);
            this.dialogService
                .ShowModal<boolean>(
                    confirmDialog,
                    null,
                    null,
                    "surveywizard/templates/wizard/dialogs/",
                    "disable-question-confirm-dialog"
                )
                .then((result: boolean) => {
                    if (!result) {
                        this.abortAnswerChange(answer);
                    } else {
                        this.resetQuestionAnswers(<ISurveyWizardQuestion>obj.Data);
                    }
                });
            return;
        }
        this.resetQuestionAnswers(<ISurveyWizardQuestion>obj.Data);
        this.DoActionForAllQuestions(!this.DoActionForAllQuestions());
    }

    private abortAnswerChange(answer: ISurveyWizardAnswer): void {
        answer.Checked(!answer.Checked());
        if (this.SelectedQuestion().IsSingleAnswerQuestion()) this.SelectedQuestion().UncheckSingleAnswers(answer.Id);
    }

    private resetQuestionAnswers(question: ISurveyWizardQuestion): void {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers();
        answers.forEach((a: ISurveyWizardAnswer) => {
            a.reset(this.Campaign().Id, this.Customer());
        });
        question.ResetState();
    }

    selectSection(section: any): void {}

    private saveStep(): Promise<void> {
        const selectedAnswers: IAnswerFromWizard[] = this.SelectedQuestion()
            .SelectedAnswers()
            .map((a: ISurveyWizardAnswer) => {
                return a.ToJSON();
            });

        return this.surveyWizardService.saveInboundWizardStep(this.callId, selectedAnswers);
    }

    public dispose() {}

    public openAgenda() {
        if (!this.config.FkAgenda) return;

        const agendaService = <IAgendaService>this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        const url = agendaService.GetAgendaEditorUrl(this.config.FkAgenda);
        window.open(url, "_blank");
    }
}
