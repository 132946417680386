import * as ko from "knockout";

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUsersSettingsManager } from "../ProlifeSdk/interfaces/users/IUsersSettingsManager";
import { IUser } from "../ProlifeSdk/interfaces/users/IUser";

export class UserText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var interceptor = ko.computed(() => {
            var userId = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var usersStateSettingsManager : IUsersSettingsManager = <IUsersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.CompanyUsers);

            var user : IUser = usersStateSettingsManager.getUserFrom(ko.utils.unwrapObservable(userId));

            if (user != null)
                return user.Nome  + " " + user.Cognome;

            return "";
        });


        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["userText"] = new UserText();
ko.virtualElements.allowedBindings["userText"] = true;