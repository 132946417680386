import * as ko from "knockout";
/**
 * Created by d.bucchi on 09/06/2017.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BlogEvent } from "./BlogEvent";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";

export class DetailsBlogDialog implements IDialog {
    private dialogsService: IDialogsService;

    public templateName: string;
    public templateUrl: string;
    public title: string;
    public parent: BlogEvent;
    modal: { close: (result?: any) => void; };

    private showed: boolean = false;
    private modalDeferred: Promise<void> = null;

    constructor(private serviceLocator: IServiceLocator, templateName: string, templateUrl: string, title: string = null, parent: BlogEvent = null) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.parent = parent;
        this.templateName = templateName;
        this.templateUrl = templateUrl;
        this.title = title || "";
    }

    public ShowModal(): Promise<void> {
        if (this.showed)
            return this.modalDeferred;

        this.showed = true;
        return this.modalDeferred = this.dialogsService.ShowModal<void>(this, "fullscreen", {}, this.templateUrl, this.templateName);
    }

    close(): void {
        if (this.parent != null || this.parent != undefined){
            this.modal.close();
            this.parent.clickOnEvent();
        }

    }

    action(): void {
        this.modal.close();
    }

    deleteEvent() {
        if (this.parent != null || this.parent != undefined){
            this.modal.close();
            this.parent.deleteEvent();
        }
    }

    signEvent() {
        if (this.parent != null || this.parent != undefined){
            this.modal.close();
            this.parent.SignEvent();
        }
    }

    editEvent() {
        if (this.parent != null || this.parent != undefined){
            this.modal.close();
            this.parent.EditEvent();
        }
    }
}
