import * as ko from "knockout";
import { IExcelExportConfiguration, IExcelExportService } from "../ProlifeSdk/ExcelExportService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";

export interface IExcelExportConfigurationsDataSourceModel extends IDataSourceModel<number, IExcelExportConfiguration> {

}

export interface IExcelExportConfigurationsDataSource extends IDataSource {

}

export class ExcelExportConfigurationsDataSource implements IExcelExportConfigurationsDataSource {
    @LazyImport(nameof<IExcelExportService>())
    private excelExportService : IExcelExportService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    private exporterId : string;
    private view: IDataSourceView;
    
    setExporterId(exporterId: string) {
        this.exporterId = exporterId;
    }

    getTitle(currentModel: IExcelExportConfigurationsDataSourceModel): string {
        return "Configurazioni Esportatore Excel";
    }    
    
    isGroupedData(currentModel: IExcelExportConfigurationsDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IExcelExportConfigurationsDataSourceModel, b: IExcelExportConfigurationsDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IExcelExportConfigurationsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IExcelExportConfigurationsDataSourceModel[]> {
        let configurations = await this.excelExportService.GetExcelExportConfigurations(this.userInfo.getIdUser(), this.exporterId);
        return configurations.map(this.createModelFor, this);
    }
    
    async getById(currentModel: IExcelExportConfigurationsDataSourceModel, ids: number[]): Promise<IExcelExportConfigurationsDataSourceModel[]> {
        let configurations = await this.excelExportService.GetExcelExportConfigurationsByIds(ids);
        return configurations.map(this.createModelFor, this);
    }

    private createModelFor(config : IExcelExportConfiguration) : IExcelExportConfigurationsDataSourceModel {
        return {
            id: config.Id,
            title: config.Label,
            subTitle: !config.LoginId ? "Configurazione Globale" : "Configurazione Personale",
            isLeaf: true,
            isGroup: false,
            icon: {
                icon: !config.LoginId ? "fa fa-users" : "fa fa-user"
            },
            model: config
        };
    }

    refresh() {
        if(this.view)
            this.view.refresh();
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}