import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { HumanWorkPeopleCosts } from "../HumanWorkPeopleCosts";
import { CostsAndRevenuesProvider } from "../../CostsAndRevenuesProvider";
import {
    IJobOrderEconomicsData,
    IJobOrderEconomicsArticlesCosts,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEconomicsProviderObserver } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrdersProvider";

export class CostsDashboard implements IEconomicsProviderObserver {
    private provider: CostsAndRevenuesProvider;

    public AllWorksHour: ko.Observable<number> = ko.observable(0);
    public AllOvertimeWorksHour: ko.Observable<number> = ko.observable(0);
    public WorksAmount: ko.Observable<number> = ko.observable(0);
    public Amount: ko.Observable<number> = ko.observable(0);
    public PurchasesCosts: ko.Observable<number> = ko.observable(0);
    public ArticlesCosts: ko.Observable<number> = ko.observable(0);
    public ArticlesAmount: ko.Observable<number> = ko.observable(0);
    public ArticlesCodesAmount: ko.Observable<number> = ko.observable(0);
    public ArticlesInWarehouseCosts: ko.Observable<number> = ko.observable(0);
    public CostChartdata: ko.Observable<any> = ko.observable({});

    public ShowHoursDetails: ko.Observable<boolean> = ko.observable(false);
    public ShowPurchasesDetails: ko.Observable<boolean> = ko.observable(false);
    public ShowEstimatedPurchasesDetails: ko.Observable<boolean> = ko.observable(false);
    public ShowEstimatedWorkDetails: ko.Observable<boolean> = ko.observable(false);
    public AreArticlesDetailsVisible: ko.Observable<boolean> = ko.observable(false);

    constructor(provider: CostsAndRevenuesProvider) {
        this.provider = provider;
        this.provider.addObserver(this);
    }

    onDataLoaded(): void {
        this.WorksAmount(this.calculateWorkAmount());
        this.AllWorksHour(this.calculateAllWorkHours());
        this.AllOvertimeWorksHour(this.calculateAllOvertimeHours());
        this.PurchasesCosts(this.calculatePurchaseCosts());
        this.ArticlesCosts(this.calculateArticlesCosts());
        this.ArticlesAmount(this.calculateArticlesAmount());
        this.ArticlesCodesAmount(this.calculateArticlesCodeCount());
        this.ArticlesInWarehouseCosts(this.calculateArticleWarehouseCosts());
        this.Amount(this.calculateTotalAmount());
        this.CostChartdata(this.calculateChartData());
    }

    private calculateWorkAmount() {
        let amount = 0;
        this.provider.HumanWorkCostsByPeople().forEach((c: HumanWorkPeopleCosts) => {
            amount = amount + c.amount();
        });
        return amount;
    }

    private calculateAllWorkHours() {
        let hours = 0;
        this.provider.HumanWorkCostsByPeople().forEach((c: HumanWorkPeopleCosts) => {
            hours = hours + <number>c.allHours();
        });

        return hours;
    }

    private calculateAllOvertimeHours() {
        let hours = 0;
        this.provider.HumanWorkCostsByPeople().forEach((c: HumanWorkPeopleCosts) => {
            hours = hours + <number>c.overtimeHours();
        });
        return hours;
    }

    private calculatePurchaseCosts() {
        const economicsData: IJobOrderEconomicsData = this.provider.EconomicsData();
        return economicsData?.PurchasesCosts || 0;
    }

    private calculateArticlesCosts() {
        let total = 0;
        this.provider.EconomicsArticlesCosts().forEach((a: IJobOrderEconomicsArticlesCosts) => {
            total += a.TotalCost;
        });
        return total;
    }

    private calculateArticlesAmount() {
        let total = 0;
        this.provider.EconomicsArticlesCosts().forEach((a: IJobOrderEconomicsArticlesCosts) => {
            total += a.Amount;
        });
        return total;
    }

    private calculateArticlesCodeCount() {
        return this.provider.EconomicsArticlesCosts().length;
    }

    private calculateArticleWarehouseCosts() {
        return this.provider.EconomicsData()?.ArticlesIntoWarehousesCosts || 0;
    }

    private calculateTotalAmount() {
        return this.WorksAmount() + this.PurchasesCosts() + this.ArticlesCosts();
    }

    private calculateChartData() {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                backgroundColor: "#FFFFFF",
                borderColor: "#606060",
                width: 200,
            },
            colors: ["#ff9e01", "#96d1de", "#b0de09"],
            credits: {
                enabled: false,
            },

            title: {
                text: null,
            },
            subtitle: {
                text: null,
            },
            tooltip: {
                animation: true,
                formatter: function () {
                    return this.point.name + ":<b>" + numeral(this.point.y / 100).format("0.00 %") + "</b>";
                },
                valueDecimals: 2,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    minSize: 100,
                    size: "100%",
                    center: [40, 10],
                },
            },
            series: [
                {
                    data: [
                        {
                            name: ProlifeSdk.TextResources.JobOrder.PurchasesImport,
                            y: this.Amount() ? (this.PurchasesCosts() / this.Amount()) * 100 : 33.3,
                        },
                        {
                            name: ProlifeSdk.TextResources.JobOrder.HoursImport,
                            y: this.Amount() ? (this.WorksAmount() / this.Amount()) * 100 : 33.4,
                        },
                        {
                            name: ProlifeSdk.TextResources.JobOrder.WarehouseArticles,
                            y: this.Amount() ? (this.ArticlesCosts() / this.Amount()) * 100 : 33.3,
                        },
                    ],
                    type: "pie",
                },
            ],
            navigation: {
                buttonOptions: {
                    symbolStroke: "#606060",
                    hoverSymbolStroke: "#606060",
                    theme: {
                        stroke: "#606060",
                    },
                },
            },
        };
    }

    public showDettagliImporto() {
        this.ShowHoursDetails(!this.ShowHoursDetails());
        if (this.ShowHoursDetails() && this.ShowEstimatedWorkDetails()) this.ShowEstimatedWorkDetails(false);
    }

    public showDettagliLavoroStimato() {
        this.ShowEstimatedWorkDetails(!this.ShowEstimatedWorkDetails());
        if (this.ShowHoursDetails() && this.ShowEstimatedWorkDetails()) this.ShowHoursDetails(false);
    }

    public showDettagliAcquisti() {
        this.ShowPurchasesDetails(!this.ShowPurchasesDetails());
        if (this.ShowEstimatedPurchasesDetails() && this.ShowPurchasesDetails())
            this.ShowEstimatedPurchasesDetails(false);
    }

    public showDettagliAcquistiStimati() {
        this.ShowEstimatedPurchasesDetails(!this.ShowEstimatedPurchasesDetails());
        if (this.ShowEstimatedPurchasesDetails() && this.ShowPurchasesDetails()) this.ShowPurchasesDetails(false);
    }

    public ShowArticlesDetails() {
        this.AreArticlesDetailsVisible(!this.AreArticlesDetailsVisible());
    }
}
