import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/03/2019
 * Time: 19:13
 * To change this template use File | Settings | File Templates.
 */

import { WorkedAmountRow } from "./WorkedAmountRow";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkedAmountRowForListViewModel, IWorkedAmountRowEditor } from "../../interfaces/IWorkedAmountRowViewModel";
import { IWorkedAmountRow, IWorkedAmountExtendedRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedAmountRow";
import { IHumanResource } from "../../../Users/HumanResourcesService";

export class WorkedAmountRowForListViewModel implements IWorkedAmountRowForListViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public TaskId: ko.Observable<number> = ko.observable();
    public Date: ko.Observable<Date> = ko.observable();
    public Resource: ko.Observable<IHumanResource> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public TaskTitle: ko.Observable<string> = ko.observable();
    public WorkedAmount: ko.Observable<number> = ko.observable();
    public WorkableAmount: ko.Observable<number> = ko.observable();
    public Progress: ko.Observable<number> = ko.observable();
    public UnitOfMeasure: ko.Observable<string> = ko.observable();
    public IsDeleted: ko.Observable<boolean> = ko.observable(false);
    public TaskBoardStatus: ko.Observable<number> = ko.observable();
    public IsTaskClosed: ko.Observable<boolean> = ko.observable();

    public WorkflowTitle: ko.Observable<string> = ko.observable();
    public JobOrderTitle: ko.Observable<string> = ko.observable();

    public NewWorkedAmount: ko.Observable<number> = ko.observable();
    public NewTotalWorkedAmount: ko.Computed<number>;

    public TaskStatusIcon: ko.Computed<string>;
    public ResourceName: ko.Computed<string>;


    public serverData: ko.Observable<any> = ko.observable();

    public editor: IWorkedAmountRowEditor;

    constructor(protected serviceLocator: IServiceLocator) {
        this.ResourceName = ko.computed(() => {
            const resource = this.Resource();
            if (!resource)
                return "";

            return resource.Resource.Name + ' ' + resource.Resource.Surname;
        });

        this.TaskStatusIcon = ko.computed(() => {
            switch (this.TaskBoardStatus()) {
                case -1:
                    return "fa-inbox";
                case 0:
                    return "fa-tachometer";
                case 1:
                    return "fa-bolt";
                case 2:
                    return "fa-flag-checkered";
                case 3:
                    return "fa-check";
                case 4:
                    return "fa-clock-o";
                case 5:
                    return "fa-trash-o";
                default:
                    return "";
            }
        });

        this.NewTotalWorkedAmount = ko.computed({
            read: () => {
                return this.WorkedAmount() + this.NewWorkedAmount();
            },
            write: (value: number) => {
                value = !value ? 0 : value;
                this.NewWorkedAmount(value - this.WorkedAmount());
            }
        });
    }

    public LoadFrom(workedAmountRow: IWorkedAmountRow): void {
        this.serverData(workedAmountRow);

        this.Id(workedAmountRow.Id);

        this.JobOrderId(workedAmountRow.JobOrderId);
        this.TaskId(workedAmountRow.TaskId);

        var oldWorkedAmount = ((workedAmountRow.Progress / 100) * workedAmountRow.WorkableAmount) - workedAmountRow.WorkedAmount;

        this.TaskTitle(workedAmountRow.TaskTitle);
        this.WorkableAmount(workedAmountRow.WorkableAmount);
        this.WorkedAmount(oldWorkedAmount);
        this.Progress(workedAmountRow.Progress);
        this.UnitOfMeasure(workedAmountRow.UnitOfMeasure);
        this.IsDeleted(workedAmountRow.TaskIsDeleted);
        this.TaskBoardStatus(workedAmountRow.TaskBoardStatus);

        this.Date(workedAmountRow.Date);

        var settingsManager: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var resourcesSettings: IHumanResourcesSettingsManager = <IHumanResourcesSettingsManager> settingsManager.findSettingsManager(ProlifeSdk.HumanResources);

        this.Resource(resourcesSettings.getHumanResourceById(workedAmountRow.ResourceId));

        this.NewWorkedAmount(workedAmountRow.WorkedAmount);

        this.WorkflowTitle(workedAmountRow.WorkflowTitle);
        this.JobOrderTitle(workedAmountRow.JobOrderTitle);
    }

    public GetData(): IWorkedAmountExtendedRow {
        var obj: IWorkedAmountExtendedRow = <IWorkedAmountExtendedRow> $.extend(true, {}, this.serverData());

        obj.WorkableAmount = this.WorkableAmount();
        obj.WorkedAmount = this.WorkedAmount();
        obj.Progress = this.Progress();
        obj.JobOrderId = this.JobOrderId();
        obj.TaskId = this.TaskId();

        return obj;
    }

    public Edit(): void {
        var rowForEditor: WorkedAmountRow = new WorkedAmountRow(this.serviceLocator);
        rowForEditor.RegisterEditor(this.editor);
        var rowData = this.GetData();
        rowData.WorkedAmount = this.NewWorkedAmount();
        rowForEditor.LoadFrom(rowData);
        this.editor.SetRowOnEdit(rowForEditor);
    }

    public RegisterEditor(editor: IWorkedAmountRowEditor): void {
        this.editor = editor;
    }

    public UnregisterEditor(editor: IWorkedAmountRowEditor): void {
        this.editor = undefined;
    }
}