import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IService } from "../Core/interfaces/IService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { TicketsApplication } from "./Tickets/TicketsApplication";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { TicketCategoriesSettingsManager } from "./settings/TicketCategoriesSettingsManager";
import { TicketClosingTypesSettingsManager } from "./settings/TicketClosingTypesSettingsManager";
import { TicketOriginsSettingsManager } from "./settings/TicketOriginsSettingsManager";

export interface IGetTicketsRequest {
    textFilter?: string;
    jobOrdersIds?: number[];
    resourcesIds?: number[];
    customersIds?: number[];
    skip?: number;
    count?: number;
}

export interface ITicket {
    Id : number;
    Author : string;
    Date : Date;
    Description : string;
    FkCategory : number;
    FkOrigin : number;
    FkClosingType : number;
    CreationDate : Date;
    CreationUserFullName : string;
    FkCreationUser : number;
    LastEditDate : Date;
    LastEditUserFullName : string;
    FkLastEditUser : number;
}

export interface ITicketCategory {
    Id : number;
    Title : string;
    Deleted?: boolean;
}

export interface ITicketClosingType {
    Id : number;
    Name : string;
    Deleted?: boolean;
}

export interface IFullTicket {
    Id : number;
    Author : string;
    Date : Date;
    Description : string;
    FkCategory : number;
    FkOrigin : number;
    FkClosingType?: number;
    CreationDate : Date;
    CreationUserFullName?: string;
    FkCreationUser : number;
    LastEditDate : Date;
    LastEditUserFullName?: string;
    FkLastEditUser : number;
}

export interface IFullTicketTasks {
    FkTicket : number;
    FkJobOrder : number;
    JobOrderName : string;
    FkWorkflow : number;
    FkTask?: number;
}

export interface IFullTicketResources {
    FkTicket : number;
    FkResource : number;
    ResourceType : string;
}

export interface IFullTicketTreatments {
    FkTicket : number;
    FkTreatment : number;
    Title : string;
}

export interface IFullTicketAttachments {
    FkTicket : number;
    AttachmentGUID : string;
}

export interface IFullTicketReplies {
    Id : number;
    FkTicket : number;
    Author : string;
    Date : Date;
    Text : string;
    CreationDate : Date;
    FkCreationUser : number;
    CreationUserFullName : string;
    LastEditDate : Date;
    FkLastEditUser : number;
    LastEditUserFullName : string;
    IsPrivate : boolean;
}

export interface IFullTicketRepliesAttachments {
    FkReply : number;
    AttachmentGUID : string;
}

export interface IFullTicketData {
    Ticket : IFullTicket;
    Tasks : IFullTicketTasks[];
    Resources : IFullTicketResources[];
    Treatments : IFullTicketTreatments[];
    Attachments : IFullTicketAttachments[];
    Replies : IFullTicketReplies[];
    RepliesAttachments : IFullTicketRepliesAttachments[];
}

export interface ITYP_Reply {
    Id : number;
    FkTicket : number;
    Author : string;
    Date : Date;
    Text?: string;
    IsPrivate : boolean;
}

export interface ITYP_Ticket {
    Id : number;
    Author : string;
    Date : Date;
    Description?: string;
    FkCategory : number;
    FkOrigin : number;
}

export interface ITYP_TicketTask {
    FkJobOrder : number;
    FkWorkflow : number;
    FkTask?: number;
}

export interface ITYP_TicketResource {
    FkResource : number;
    ResourceType?: string;
}

export interface IInsertOrUpdateTicketRequest {
    ticket : ITYP_Ticket[];
    fkUser : number;
    tasks : ITYP_TicketTask[];
    resources : ITYP_TicketResource[];
    attachments : string[];
    treatmentsIds : number[];
}

export interface ITicketOrigin {
    Id : number;
    Title: string;
    Deleted : boolean;
}

export interface ITicketsService extends IService {
    GetTickets(request : IGetTicketsRequest) : Promise<ITicket[]>;
    GetTicketsByIds(ids : number[] | null) : Promise<ITicket[]>;
    GetCategories(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketCategory[]>;
    GetCategoriesByIds(ids : number[] | null) : Promise<ITicketCategory[]>;
    InsertOrUpdateCategories(ticketCategories : ITicketCategory[] | null) : Promise<void>;
    GetClosingTypes(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketClosingType[]>;
    GetClosingTypesByIds(ids : number[] | null) : Promise<ITicketClosingType[]>;
    InsertOrUpdateClosingTypes(closingTypes : ITicketClosingType[] | null) : Promise<void>;
    InsertOrUpdateReply(reply : ITYP_Reply[] | null, attachments : string[] | null, fkUser : number | null) : Promise<void>;
    InsertOrUpdateTicket(request : IInsertOrUpdateTicketRequest) : Promise<void>;
    CloseTicket(id : number | null, fkClosingType : number | null, fkUser : number | null) : Promise<void>;
    GetFullTicketData(id : number | null, showPrivate : boolean | null) : Promise<IFullTicketData>;
    GetOrigins(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketOrigin[]>;
    GetOriginsByIds(ids : number[] | null) : Promise<ITicketOrigin[]>;
    InsertOrUpdateOrigins(ticketOrigins : ITicketOrigin[] | null) : Promise<void>;
    DeleteReply(id: number | null, fkUser: number | null): Promise<void>;
    DeleteTicket(id: number | null, fkUser: number | null): Promise<void>;
}

@Service(nameof<ITicketsService>())
export class TicketsService implements ITicketsService {
    private application : TicketsApplication;

    TicketCategoriesSettingsManager : TicketCategoriesSettingsManager;
    TicketClosingTypesSettingsManager : TicketClosingTypesSettingsManager;
    TicketOriginsSetttingsManager: TicketOriginsSettingsManager;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    InitializeService() {
        this.TicketCategoriesSettingsManager = new TicketCategoriesSettingsManager();
        this.TicketClosingTypesSettingsManager = new TicketClosingTypesSettingsManager();
        this.TicketOriginsSetttingsManager = new TicketOriginsSettingsManager();
        this.application = new TicketsApplication();
    }

    getServiceType() : string {
        return ProlifeSdk.TicketsServiceType;
    }

    isOfType(serviceType : string) : boolean {
        return serviceType == this.getServiceType();
    }

    GetTickets(request : IGetTicketsRequest) : Promise<ITicket[]> {
        let result = this.ajaxService.Post<ITicket[]>("Tickets-api/Tickets", "GetTickets", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetTicketsByIds(ids : number[] | null) : Promise<ITicket[]> {
        let result = this.ajaxService.Post<ITicket[]>("Tickets-api/Tickets", "GetTicketsByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetCategories(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketCategory[]> {
        let result = this.ajaxService.Post<ITicketCategory[]>("Tickets-api/Tickets", "GetCategories", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showDeleted: showDeleted,
                skip: skip,
                count: count,
            }
        });



        return result;
    }

    GetCategoriesByIds(ids : number[] | null) : Promise<ITicketCategory[]> {
        let result = this.ajaxService.Post<ITicketCategory[]>("Tickets-api/Tickets", "GetCategoriesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    InsertOrUpdateCategories(ticketCategories : ITicketCategory[] | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "InsertOrUpdateCategories", {
            background: true,
            methodData: {
                ticketCategories: ticketCategories,
            }
        });



        return result;
    }

    GetClosingTypes(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketClosingType[]> {
        let result = this.ajaxService.Post<ITicketClosingType[]>("Tickets-api/Tickets", "GetClosingTypes", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showDeleted: showDeleted,
                skip: skip,
                count: count,
            }
        });



        return result;
    }

    GetClosingTypesByIds(ids : number[] | null) : Promise<ITicketClosingType[]> {
        let result = this.ajaxService.Post<ITicketClosingType[]>("Tickets-api/Tickets", "GetClosingTypesByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    InsertOrUpdateClosingTypes(closingTypes : ITicketClosingType[] | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "InsertOrUpdateClosingTypes", {
            background: true,
            methodData: {
                closingTypes: closingTypes,
            }
        });



        return result;
    }

    InsertOrUpdateReply(reply : ITYP_Reply[] | null, attachments : string[] | null, fkUser : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "InsertOrUpdateReply", {
            background: true,
            methodData: {
                reply: reply,
                attachments: attachments,
                fkUser: fkUser,
            }
        });



        return result;
    }

    InsertOrUpdateTicket(request : IInsertOrUpdateTicketRequest) : Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "InsertOrUpdateTicket", {
            background: true,
            methodData: request
        });



        return result;
    }

    CloseTicket(id : number | null, fkClosingType : number | null, fkUser : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "CloseTicket", {
            background: true,
            methodData: {
                id: id,
                fkClosingType: fkClosingType,
                fkUser: fkUser,
            }
        });



        return result;
    }

    GetFullTicketData(id : number | null, showPrivate : boolean | null) : Promise<IFullTicketData> {
        let result = this.ajaxService.Post<IFullTicketData>("Tickets-api/Tickets", "GetFullTicketData", {
            background: true,
            methodData: {
                id: id,
                showPrivate: showPrivate,
            }
        });



        return result;
    }

    GetOrigins(textFilter : string | null, showDeleted : boolean | null, skip : number | null, count : number | null) : Promise<ITicketOrigin[]> {
        let result = this.ajaxService.Post<ITicketOrigin[]>("Tickets-api/Tickets", "GetOrigins", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showDeleted: showDeleted,
                skip: skip,
                count: count,
            }
        });



        return result;
    }

    GetOriginsByIds(ids : number[] | null) : Promise<ITicketOrigin[]> {
        let result = this.ajaxService.Post<ITicketOrigin[]>("Tickets-api/Tickets", "GetOriginsByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    InsertOrUpdateOrigins(ticketOrigins: ITicketOrigin[] | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "InsertOrUpdateOrigins", {
            background: true,
            methodData: {
        		ticketOrigins: ticketOrigins,
        	}
        });



        return result;
    }

    DeleteReply(id: number | null, fkUser: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "DeleteReply", {
            background: true,
            methodData: {
        		id: id,
        		fkUser: fkUser,
        	}
        });



        return result;
    }

    DeleteTicket(id: number | null, fkUser: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>("Tickets-api/Tickets", "DeleteTicket", {
            background: true,
            methodData: {
        		id: id,
        		fkUser: fkUser,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<ITicketsService>());
}
