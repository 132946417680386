import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { WorkedHoursMonthReportInsertConsole, ImportImbalanceDialog } from "../monthly-report/WorkedHoursMonthlyReport";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IHumanResourcesService, IHumanResource } from "../../../../Users/HumanResourcesService";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IWorkedHoursMonthlyReportByCharacterRecord } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursMonthlyReportRecord";
import { IJobOrderForHint, IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class ActivitiesByCharactersMonthlyReport extends ProLifeReport implements IReport, IReportViewModel {
    public customerSearchService: IControlsEntityProvider;
    private jobOrdersSearchService: CustomerJobOrdersControlsEntityProvider;

    Name: string = ProlifeSdk.TextResources.WorkedHours.CustomersHoursReport;
    templateName = "report-list-item";
    templateUrl = "reports/templates";
    detailsTemplateName = "activities-by-characters-monthly-report";
    detailsTemplateUrl = "workedhours/templates/reports";
    private navigator: IReportsNavigator;
    viewModel: any;

    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IHumanResourcesService>())
    resourcesService: IHumanResourcesService;
    @LazyImport(nameof<IInfoToastService>())
    toastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    dialogService: IDialogsService;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService: IEntityProviderService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesSettingsManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesSettingsManager: ICallRightTypesSettingsManager;

    CurrentYear: ko.Observable<number> = ko.observable(1);
    CurrentMonth: ko.Observable<number> = ko.observable(1); //0=Gennaio
    CustomerId: ko.Observable<number> = ko.observable();
    SelectedJobOrderId: ko.Observable<any> = ko.observable();
    CurrentMonthName: ko.Computed<string>;
    NumberOfDaysInMonth: ko.Computed<number[]>;
    Resources: ko.ObservableArray<ResourceMonthlyReportByCharacter> = ko.observableArray([]);

    WorkTimeCategories: IWorkTimeCategory[];
    CallRightCategories: ICallRightType[];
    Years: number[] = [];
    Months: any[] = [];
    IsMonthLocked: ko.Observable<boolean> = ko.observable(false);

    ShowVariations: ko.Observable<boolean> = ko.observable(true);
    ShowNotBillable: ko.Observable<boolean> = ko.observable(false);

    InsertConsole: WorkedHoursMonthReportInsertConsole;

    constructor(groupId: number) {
        super(groupId, 2);

        this.customerSearchService = this.entityProviderService
            .getEntityProvider(ProlifeSdk.CustomerEntityType)
            .getControlsProvider();

        this.jobOrdersSearchService = new CustomerJobOrdersControlsEntityProvider();

        this.WorkTimeCategories = this.workTimeCategoriesSettingsManager.getAll(true);
        this.CallRightCategories = this.callRightTypesSettingsManager
            .getAll(true)
            .sort((a: ICallRightType, b: ICallRightType) => {
                if ((a.Name ? a.Name : "").toUpperCase() < (b.Name ? b.Name : "").toUpperCase()) return -1;
                if ((a.Name ? a.Name : "").toUpperCase() > (b.Name ? b.Name : "").toUpperCase()) return 1;
                return 0;
            });

        this.CustomerId.subscribe((id: any) => {
            this.jobOrdersSearchService.SetCustomer(id == "" ? null : parseInt(id));
            const currentJobOrder = this.SelectedJobOrderId();
            this.SelectedJobOrderId(null);
        });

        this.NumberOfDaysInMonth = ko.computed(() => {
            const date = moment(new Date()).year(this.CurrentYear()).month(this.CurrentMonth());
            const daysArray = [];
            const numberOfDays = date.daysInMonth();
            for (let d = 1; d < numberOfDays + 1; d++) {
                date.date(d);
                daysArray.push({
                    Value: d,
                    ShortName: date.format("ddd"),
                });
            }
            return daysArray;
        });

        this.CurrentYear.subscribe(this.NotifyFilterChange.bind(this));
        this.CurrentMonth.subscribe(this.NotifyFilterChange.bind(this));
        this.CustomerId.subscribe(this.NotifyFilterChange.bind(this));
        this.SelectedJobOrderId.subscribe(this.NotifyFilterChange.bind(this));
        this.ShowNotBillable.subscribe(this.NotifyFilterChange.bind(this));
        this.ShowVariations.subscribe(this.NotifyFilterChange.bind(this));

        const currentYear = moment(new Date()).year();
        for (let y = currentYear; y > currentYear - 10; y--) {
            this.Years.push(y);
        }

        for (let m = 0; m < 12; m++) {
            this.Months.push({ Id: m, Name: moment().month(m).format("MMMM") });
        }

        this.CurrentMonthName = ko.computed(() => {
            const matches = this.Months.filter((m) => {
                return m.Id == this.CurrentMonth();
            });
            return matches.length == 0 ? "Seleziona..." : matches[0].Name;
        });
    }

    SwitchMonthLocked() {
        this.workedHoursService
            .SetLockStatusForMonth(this.CurrentYear(), this.CurrentMonth() + 1, !this.IsMonthLocked())
            .then(() => {
                this.IsMonthLocked(!this.IsMonthLocked());
                this.toastService.Success(
                    this.IsMonthLocked()
                        ? ProlifeSdk.TextResources.WorkedHours.LockedMonthlyHoursInsertion
                        : ProlifeSdk.TextResources.WorkedHours.UnlockedMonthlyHoursInsertion
                );
            });
    }

    ImportImbalanceFromPreviousMonth() {
        this.dialogService
            .ShowModal<void>(
                new ImportImbalanceDialog(this.CurrentYear(), this.CurrentMonth() + 1),
                null,
                null,
                "workedhours/templates/reports",
                "import-imbalance-dialog"
            )
            .then(() => {
                this.toastService.Success(ProlifeSdk.TextResources.WorkedHours.FlexibilityImported);
                this.Refresh();
            });
    }

    public ExportAsExcel(versionId: number) {
        const jobOrderId =
            !this.SelectedJobOrderId() || <any>this.SelectedJobOrderId() == "" ? -1 : this.SelectedJobOrderId();
        const customerId = !this.CustomerId() || <any>this.CustomerId() == "" ? -1 : this.CustomerId();

        const url =
            "WorkedHours/WorkedHoursMonthlyReportByCharacterPrint/GenerateExcel?versionId=" +
            versionId +
            "&DayOfMonth=" +
            encodeURIComponent(moment().year(this.CurrentYear()).month(this.CurrentMonth()).date(1).format()) +
            "&jobOrderId=" +
            jobOrderId +
            "&customerId=" +
            customerId +
            "&showNotBillable=" +
            (this.ShowNotBillable() ? 1 : 0);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    SwitchNotBillableVisibility() {
        this.ShowNotBillable(!this.ShowNotBillable());
    }

    SwitchVariationsVisibility() {
        this.ShowVariations(!this.ShowVariations());
    }

    SetYear(year: number) {
        this.CurrentYear(year);
    }

    GetDayName(day: number) {
        return moment(new Date()).year(this.CurrentYear()).month(this.CurrentMonth()).date(day).format("ddd");
    }

    SetMonth(month: number) {
        this.CurrentMonth(month);
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        if (this.CurrentMonth() == null || this.CurrentYear() == null) {
            this.Resources([]);
            return Promise.resolve();
        }

        const resourcesArray = [];
        this.workedHoursService
            .GetMonthlyHolidays(this.CurrentYear(), this.CurrentMonth() + 1)
            .then((holidays) => {
                this.resourcesService.GetHumanResources(null, true, true).then((resources: IHumanResource[]) => {
                    const jobOrderId = this.SelectedJobOrderId() ? parseInt(this.SelectedJobOrderId()) : null;
                    this.workedHoursService
                        .CalculateMonthlyReportByCharacter(
                            this.CurrentYear(),
                            this.CurrentMonth() + 1,
                            this.CustomerId(),
                            jobOrderId,
                            this.ShowNotBillable()
                        )
                        .then((monthlyRecords: IWorkedHoursMonthlyReportByCharacterRecord[]) => {
                            resources.sort((a: IHumanResource, b: IHumanResource) => {
                                if (
                                    (a.Resource.Surname ? a.Resource.Surname : "").toUpperCase() <
                                    (b.Resource.Surname ? b.Resource.Surname : "").toUpperCase()
                                )
                                    return -1;
                                if (
                                    (a.Resource.Surname ? a.Resource.Surname : "").toUpperCase() >
                                    (b.Resource.Surname ? b.Resource.Surname : "").toUpperCase()
                                )
                                    return 1;
                                return 0;
                            });

                            resources.forEach((resource: IHumanResource) => {
                                if (resource.Resource.Deleted) return;

                                const resourceRecords: IWorkedHoursMonthlyReportByCharacterRecord[] =
                                    monthlyRecords.filter((mr: IWorkedHoursMonthlyReportByCharacterRecord) => {
                                        return mr.ResourceId == resource.Resource.Id;
                                    });

                                if (resourceRecords.length == 0) return;

                                resourcesArray.push(
                                    new ResourceMonthlyReportByCharacter(
                                        resource,
                                        holidays,
                                        resourceRecords,
                                        this.NumberOfDaysInMonth(),
                                        this.CallRightCategories,
                                        this.ShowVariations(),
                                        this.CurrentYear(),
                                        this.CurrentMonth()
                                    )
                                );
                            });
                            this.Resources(resourcesArray);
                        });
                });
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    initialize() {
        super.initialize();
        const today = moment(new Date());
        this.CurrentYear(today.year());
        this.CurrentMonth(today.month());
        this.Refresh();
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}

class ResourceMonthlyReportByCharacter {
    Name: string;
    Roles: ko.ObservableArray<CharacterForReport> = ko.observableArray([]);
    CallRightCategories: ko.ObservableArray<CallRightCategoryForReportByCharacter> = ko.observableArray([]);
    Days: any[] = [];
    Total: number;
    Variation: number;
    TotalWithVariation: number;
    HasCalls: boolean;
    NumberOfCalls = 0;
    HasTravelDistance: boolean;
    TravelDistance = 0;

    constructor(
        resource: IHumanResource,
        holidays: any[],
        monthlyRecords: IWorkedHoursMonthlyReportByCharacterRecord[],
        numberOfDays: number[],
        callRightCategories: ICallRightType[],
        private showVariations: boolean,
        year: number,
        month: number
    ) {
        this.Name = resource.Resource.Surname + " " + resource.Resource.Name;
        this.Total = 0;
        this.Variation = 0;
        this.TotalWithVariation = 0;

        const resourceHolidays = holidays.filter((h) => {
            return h.resourceId == resource.Resource.Id;
        });
        const callRightCategoriesToShow = callRightCategories.filter((c: ICallRightType) => {
            return (
                monthlyRecords.filter((r: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    return r.CallRightType == c.Id;
                }).length > 0
            );
        });

        const rolesToShow = [];
        monthlyRecords.forEach((r: IWorkedHoursMonthlyReportByCharacterRecord) => {
            if (
                rolesToShow
                    .map((r) => {
                        return r.Id;
                    })
                    .indexOf(r.RoleId) == -1
            )
                rolesToShow.push({ Id: r.RoleId, Name: r.RoleName });
        });

        rolesToShow.forEach((r1) => {
            const roleRecords = monthlyRecords.filter((r: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return r.RoleId == r1.Id;
            });
            const role: CharacterForReport = new CharacterForReport(
                r1,
                numberOfDays,
                roleRecords,
                showVariations,
                resourceHolidays
            );
            this.Roles.push(role);
        });

        callRightCategoriesToShow.forEach((w: ICallRightType) => {
            const categoryRecords = monthlyRecords.filter((r: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return r.CallRightType == w.Id;
            });
            this.CallRightCategories.push(
                new CallRightCategoryForReportByCharacter(
                    w,
                    numberOfDays,
                    categoryRecords,
                    showVariations,
                    resource.Resource.Id,
                    year,
                    month,
                    resourceHolidays
                )
            );
        });

        numberOfDays.forEach((day: any) => {
            let dayTotalHours = 0;
            let dayVariationTotalHours = 0;
            const callRightMatches = monthlyRecords.filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return moment(record.WorkDate).date() == day.Value && record.CallRight;
            });

            this.NumberOfCalls += callRightMatches.length;
            this.HasCalls = this.HasCalls || callRightMatches.length > 0;

            let travelDistance = 0;
            monthlyRecords
                .filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    return moment(record.WorkDate).date() == day.Value;
                })
                .forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    travelDistance += record.TravelDistance;
                });

            this.TravelDistance += travelDistance;
            this.HasTravelDistance = this.TravelDistance > 0;

            const matchesWithoutVariation = monthlyRecords.filter(
                (record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    return moment(record.WorkDate).date() == day.Value && !record.BusinessManagement;
                }
            );

            matchesWithoutVariation.forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                dayTotalHours += record.Hours;
            });

            const matchesWithVariation = monthlyRecords.filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return moment(record.WorkDate).date() == day.Value && record.BusinessManagement;
            });

            matchesWithVariation.forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                dayVariationTotalHours += record.Hours;
            });

            this.Days.push({
                Value: dayTotalHours + (showVariations ? dayVariationTotalHours : 0),
                NumberOfCalls: callRightMatches.length,
                TravelDistance: travelDistance,
            });

            this.Total += dayTotalHours;
            this.Variation += dayVariationTotalHours;
            this.TotalWithVariation += dayTotalHours + dayVariationTotalHours;
        });
    }
}

class CallRightCategoryForReportByCharacter {
    NumberOfCalls = 0;
    Name: string;
    Days: any[] = [];

    constructor(
        private callRightType: ICallRightType,
        numberOfDays: number[],
        monthlyRecords: IWorkedHoursMonthlyReportByCharacterRecord[],
        private showVariations: boolean,
        private resourceId: number,
        private year: number,
        private month: number,
        holidays: any[]
    ) {
        this.NumberOfCalls = 0;
        this.Name = callRightType.Name;

        numberOfDays.forEach((day: any) => {
            let numberOfCalls = 0;

            const recordsForDay = monthlyRecords.filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return moment(record.WorkDate).date() == day.Value;
            });

            recordsForDay.forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                numberOfCalls += record.CallRight ? 1 : 0;
            });

            this.NumberOfCalls += numberOfCalls;

            const dayHolidays = holidays.filter((h) => {
                return h.day == day.Value;
            });

            this.Days.push({
                NumberOfCalls: numberOfCalls,
                Day: day,
                IsHoliday: dayHolidays.length > 0 && dayHolidays[0].isHoliday,
            });
        });
    }
}

class CharacterForReport {
    Name: string;
    Days: any[] = [];
    Total: number;
    Variation: number;
    TotalWithVariation: number;
    NumberOfCalls = 0;
    TravelDistance = 0;

    constructor(
        private role: any,
        numberOfDays: number[],
        monthlyRecords: IWorkedHoursMonthlyReportByCharacterRecord[],
        private showVariations: boolean,
        holidays: any[]
    ) {
        this.Name = role.Name;
        this.Total = 0;
        this.Variation = 0;
        this.TotalWithVariation = 0;

        numberOfDays.forEach((day: any) => {
            let hours = 0;
            let variationHours = 0;
            let hasVariations = false;

            const callRightMatches = monthlyRecords.filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                return moment(record.WorkDate).date() == day.Value && record.CallRight;
            });
            this.NumberOfCalls += callRightMatches.length;

            let travelDistance = 0;
            monthlyRecords
                .filter((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    return moment(record.WorkDate).date() == day.Value;
                })
                .forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                    travelDistance += record.TravelDistance;
                });

            this.TravelDistance += travelDistance;

            monthlyRecords.forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                if (moment(record.WorkDate).date() == day.Value && !record.BusinessManagement) hours += record.Hours;
            });

            monthlyRecords.forEach((record: IWorkedHoursMonthlyReportByCharacterRecord) => {
                if (moment(record.WorkDate).date() == day.Value && record.BusinessManagement) {
                    variationHours += record.Hours;
                    hasVariations = true;
                }
            });

            const dayHolidays = holidays.filter((h) => {
                return h.day == day.Value;
            });

            this.Days.push({
                Hours: hours + (showVariations ? variationHours : 0),
                HasVariations: showVariations && hasVariations,
                NumberOfCalls: callRightMatches.length,
                TravelDistance: travelDistance,
                Day: day,
                IsHoliday: dayHolidays.length > 0 && dayHolidays[0].isHoliday,
            });

            this.Total += hours;
            this.Variation += variationHours;
            this.TotalWithVariation += hours + variationHours;
        });
    }
}

class CustomerJobOrdersControlsEntityProvider implements IControlsEntityProvider {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    private lastTimeout: ReturnType<typeof setTimeout>;
    private selectedCustomerId: number;

    constructor() {}

    public findEntities(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.jobOrderService.hintSearch(query.term, null, this.selectedCustomerId).then((data) => {
                query.callback({
                    results: data.map((jobOrder: IJobOrderForHint) => {
                        return {
                            id: jobOrder.Id,
                            text: jobOrder.Name,
                        };
                    }),
                });
            });
        }, 500);
    }

    public findEntity(element, callback) {
        const id = $(element).val() as string;
        const numericId = parseInt(id);

        if (id !== "" && parseInt(id) > 0) {
            this.jobOrderService.get(numericId).then((jobOrder: IJobOrder) =>
                callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name,
                })
            );
        }
    }

    public SetCustomer(id: number) {
        this.selectedCustomerId = id;
    }
}
