import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IVatRegistersService, IVatRegister } from "../Invoices/VatRegistersService";
import { LazyImport } from "../Core/DependencyInjection";

export type IVatRegisterDataSourceModel = IDataSourceModel<number, IVatRegister>

export class VatRegistersDataSource extends BaseDataSource<IVatRegisterDataSourceModel> {
    private getOnlyInventoryAdjustmentRegisters = false;
    private documentType: string = null;
    private fiscalDocumentType: number = null;

    @LazyImport(nameof<IVatRegistersService>())
    private vatRegistersService: IVatRegistersService;

    public getTitle(currentModel: IVatRegisterDataSourceModel): string {
        return "";
    }
    
    public async getData(currentModel: IVatRegisterDataSourceModel, textFilter: string, skip: number, count: number): Promise<IVatRegisterDataSourceModel[]> {
        const vatRegisters = await this.vatRegistersService.GetVatRegisters({ textFilter: textFilter, fiscalDocumentType: this.fiscalDocumentType, getOnlyInventoryAdjustment: this.getOnlyInventoryAdjustmentRegisters, skip: skip, count: count });
        return vatRegisters.map(this.createModel, this);
    }
    
    public async getById(currentModel: IVatRegisterDataSourceModel, ids: number[]): Promise<IVatRegisterDataSourceModel[]> {
        const vatRegisters = await this.vatRegistersService.GetVatRegistersByIds(ids);
        return vatRegisters.map(this.createModel, this);
    }
    
    public getOnlyInventoryAdjustment(isInventoryAdjustment: boolean) {
        this.getOnlyInventoryAdjustmentRegisters = isInventoryAdjustment;
    }
    
    public setDocumentType(documentType: string, fiscalDocumentType: number) {
        this.documentType = documentType;
        this.fiscalDocumentType = fiscalDocumentType;
    }

    private createModel(vatRegister: IVatRegister): IVatRegisterDataSourceModel {
        return {
            id: vatRegister.IdRegistroIVA,
            title: vatRegister.NomeRegistroIVA,
            isGroup: false,
            isLeaf: true,
            model: vatRegister
        };
    }
}