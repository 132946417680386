import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { reloadNow, ComponentUtils, useEffect } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { TextInput } from "../../../Components/TextInput";
import { NumberInput } from "../../../Components/NumberInput";
import { MoneyInput } from "../../../Components/MoneyInput";
import { IQuotationRow, IQuotationCategory, QuotationBillOfMaterialsRowType, IQuotationRowEntity, IQuotationRowCategoryValue } from "../../QuotationService";
import { CheckBox } from "../../../Components/Checkbox";
import { DropdownList } from "../../../Components/DropdownList";
import { DateTimeInput } from "../../../Components/DateTimeInput";
import { Layout } from "../../../Components/Layouts";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnFooter } from "../../../Components/TableComponent/CustomColumn";
import { QuotationBillOfMaterialsRow, createQuotationBillOfMaterialsRow, getEntityColor } from "../QuotationBillOfMaterials";
import { PercentageInput } from "../../../Components/PercentageInputComponent";
import { DiscountInput } from "../../../Components/DiscountInput";
import { Select2 } from "../../../Components/Select2Component";
import { Typeahead } from "../../../Components/TypeaheadComponent";
import { ArticlesDataSource } from "../../../DataSources/ArticlesDataSource";
import { PurchasesDataSource } from "../../../DataSources/PurchasesDataSource";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { DiscountsUtilities } from "../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import numeral = require("numeral");

type QuotationRowEditorProps = {
    ref?: (e : QuotationRowEditor) => void;
    row: IQuotationRow;
    customerId: number;
    jobOrderId: ko.Observable<number>;
    categories: IQuotationCategory[];
}

type QuotationCategory = Omit<IQuotationCategory, "Value"> & {
    Value: ko.Observable<any>;
}
export class QuotationRowEditor extends React.Component<QuotationRowEditorProps> {
    mainRow: QuotationBillOfMaterialsRow;
    rows : ko.ObservableArray<QuotationBillOfMaterialsRow> = ko.observableArray();
    categories: ko.ObservableArray<QuotationCategory> = ko.observableArray();
    articlesDataSource : ArticlesDataSource = new ArticlesDataSource();
    purchasesDataSource: PurchasesDataSource = new PurchasesDataSource();
    roles: IUserCharacter[] = [];

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager : IUserCharactersSettingsManager;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    private validator : IValidator<QuotationRowEditor>;

    constructor(props : QuotationRowEditorProps) {
        super(props);

        this.roles = this.userCharactersSettingsManager.getUserCharacters();

        const { row } = props;
        this.mainRow = createQuotationBillOfMaterialsRow({
            Id: row.Id,
            Description: row.Description,
            Amount: row.Amount,
            UnitCost: row.UnitCost,
            TotalCost: row.TotalCost,
            Margin: row.Margin,
            UnitPrice: row.UnitPrice,
            Discount: row.Discount,
            NetUnitPrice: row.NetUnitPrice,
            TotalPrice: row.TotalPrice,
            EntityKeyId: null,
            FKParent: row.FKParent,
            Type: null
        }, this.props.customerId, this.props.jobOrderId)

        this.validator = this.validationService.createValidator<QuotationRowEditor>()
            .isNotNullOrUndefinedOrWhiteSpace(r => r.mainRow.description())
            .isTrue(r => r.mainRow.amount() !== 0)
            .isNotNullOrUndefined(r => r.mainRow.unitCost())
            .isNotNullOrUndefined(r => r.mainRow.totalCost())
            .isNotNullOrUndefined(r => r.mainRow.margin())
            .isNotNullOrUndefined(r => r.mainRow.unitPrice())
            .isNotNullOrUndefined(r => r.mainRow.totalPrice());

        for(let c of this.props.categories) {
            this.categories.push({
                ...c,
                Value: ko.observable(),
            })
        }

        useEffect(() => {
            const row = this.props.row;
            this.rows(row.Entities.map(e => createQuotationBillOfMaterialsRow(e, this.props.customerId, this.props.jobOrderId)));

        }, [])
    }

    private factory(row : QuotationBillOfMaterialsRow) {
        return {
            id: -1,
            title: "",
            isLeaf: true,
            isGroup: false,
            model: row
        }
    }

    removeRow(row: QuotationBillOfMaterialsRow): void {
        this.rows.remove(row);
    }

    getData() : IQuotationRow {
        return {
            Id: -1,
            Description: this.mainRow.description(),
            Amount: this.mainRow.amount(),
            UnitCost: this.mainRow.unitCost(),
            TotalCost: this.mainRow.totalCost(),
            Margin: this.mainRow.margin(),
            UnitPrice: this.mainRow.unitPrice(),
            Discount: this.mainRow.discount(),
            NetUnitPrice: this.mainRow.netUnitPrice(),
            TotalPrice: this.mainRow.totalPrice(),
            FKParent: this.mainRow.fkBillOfMaterials,
            Entities: this.getEntities(),
            Categories: this.getCategories()
        }
    }
    
    getEntities(): IQuotationRowEntity[] {
        return this.rows().map(r => ({
            Id: r.id,
            FKParent: r.fkBillOfMaterials,
            EntityKeyId: r.entityKeyId(),
            Type: r.type,
            Description: r.description(),
            Amount: r.amount(),
            UnitCost: r.unitCost(),
            TotalCost: r.totalCost(),
            Margin: r.margin(),
            UnitPrice: r.unitPrice(),
            Discount: r.discount(),
            NetUnitPrice: r.netUnitPrice(),
            TotalPrice: r.totalPrice(),
        }))
    }

    getCategories(): IQuotationRowCategoryValue[] {
        return this.categories().map(c => ({
            FKCategory: c.Id,
            FKQuotationRow: this.props.row.Id,
            Type: c.Type,
            Values: c.Values,
            ValueBoolean: c.Type === 3 ? c.Value() : undefined,
            ValueString: c.Type === 1 ? c.Value() : undefined,
            ValueDate: c.Type === 4 ? c.Value() : undefined,
            ValueNumber: c.Type === 2 || c.Type === 5 ? c.Value() : undefined
        }));
    }

    isValid() : boolean {
        return this.validator.validateAndShowInfoToast(this);
    }

    private renderCategory(c : QuotationCategory) {
        switch(c.Type)
        {
            case 1:
                return  <div className="row">
                    <div className="col-md-12">
                        <TextInput label={c.Name} value={c.Value} />
                    </div>
                </div>;

            case 2:
                return  <div className="row">
                    <div className="col-md-12">
                        <NumberInput label={c.Name} value={c.Value} />
                    </div>
                </div>;

            case 3:
                return  <div className="row">
                    <div className="col-md-12">
                        <CheckBox label={c.Name} checked={c.Value} simple={false} switch />
                    </div>
                </div>;

            case 4:
                return  <div className="row">
                    <div className="col-md-12">
                        <DateTimeInput label={c.Name} value={c.Value} placeholder="Seleziona una data..." />
                    </div>
                </div>;

            case 5:
                return  <div className="row">
                    <div className="col-md-12">
                        <DropdownList label={c.Name} value={c.Value} values={c.Values} textGetter={v => v.Value} idGetter={v => v.Id} valueGetter={v => v.Id.toString()} />
                    </div>
                </div>;

            default:
                return <></>;
        }
        
    }

    private addNew(type: QuotationBillOfMaterialsRowType) {
        const newRow = createQuotationBillOfMaterialsRow({
            Id: this.idGeneratorService.getNextId(),
            FKParent: this.props.row.FKParent,
            Type: type,
            EntityKeyId: null,
            Description: "",

            Amount: 0,
            UnitCost: 0,
            TotalCost: 0,
            Margin: 0,
            UnitPrice: 0,
            Discount: null,
            NetUnitPrice: 0,
            TotalPrice: 0
        }, this.props.customerId, this.props.jobOrderId);
        this.rows.push(newRow);
        return false;
    }

    private renderEditor(item: ITableItem<QuotationBillOfMaterialsRow>) {
        const model = item.Data.model;
        switch(model.type)
        {
            case "WAR":
                return <Select2 dataSource={this.articlesDataSource} value={model.entityKeyId} simple placeholder="Seleziona un articolo..." />
            case "EWK":
                return <DropdownList simple value={model.entityKeyId} values={this.roles} valueGetter={r => String(r.IdUserCharacter)} textGetter={r => r.Description} idGetter={r => r.IdUserCharacter} noSelectionPlaceholder="Seleziona un ruolo..." />
            case "EPC":
                return <Typeahead dataSource={this.purchasesDataSource} value={model.description} simple placeholder="Seleziona un acquisto..." />
            case "BOM":
                return <Select2 dataSource={this.articlesDataSource} value={model.entityKeyId} simple placeholder="Seleziona una distinta base..." />
            default:
                return <TextInput value={model.description} simple placeholder="Inserisci una descrizione..." />;
        }
    }

    private computeFromEntities() {
        const entities = this.rows();
        const totalAmount = entities.sum(e => e.amount());
        const totalPrice = entities.sum(e => e.totalPrice());
        const meanUnitCost = entities.sum(e => e.unitCost() * e.amount());
        const meanUnitPrice = entities.sum(e => e.unitPrice() * e.amount());
        const meanDiscount = totalAmount == 0 ? 0 : totalPrice / (meanUnitPrice * totalAmount);

        this.mainRow.unitCost(meanUnitCost);
        this.mainRow.unitPrice(meanUnitPrice);
        this.mainRow.discount(numeral((1 - meanDiscount) * 100).format("0.[##]") + "%");
    }
    
    render() {
        const headerActions = () => <div className="btn-group flex-container" style={{ justifyContent: 'flex-end'}}>
                                        <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("WAR"), color: 'white' }} onClick={() => this.addNew("WAR")}>
                                            <i className="fa fa-plus"/>&nbsp;Articolo
                                        </button>
                                        <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("EWK"), color: 'white' }} onClick={() => this.addNew("EWK")}>
                                            <i className="fa fa-plus"/>&nbsp;Lavorazione
                                        </button>
                                        <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("EPC"), color: 'white' }} onClick={() => this.addNew("EPC")}>
                                            <i className="fa fa-plus"/>&nbsp;Acquisto
                                        </button>
                                        <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("BOM"), color: 'white' }} onClick={() => this.addNew("BOM")}>
                                            <i className="fa fa-plus"/>&nbsp;Distinta Base
                                        </button>
                                    </div>

        return ComponentUtils.bindTo(
            <Layout.Grid columns={["1fr", "300px"]} rows={["150px", "1fr"]} noOverflow>
                <Layout.Grid.Cell column={1} row={1} className="flex-vertical">
                    <Table  title="Dettagli Riga" 
                        className="fixed-height"
                        verticalAlign="middle"
                        editable
                        compact
                        dataSource={{ array: ko.observableArray([this.mainRow]), factory: this.factory }} 
                        headerActions={() => <button className="btn btn-xs btn-circle yellow" onClick={() => this.computeFromEntities()}><i className="fa fa-refresh"></i>&nbsp;Calcola da Entità</button>}>
                        <Column title="Descrizione">
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <TextInput selectOnFocus simple value={item.Data.model.description} placeholder="Inserisci una descrizione..." />}
                            </ColumnBody>
                        </Column>
                        <Column title="Quantità" className="text-right" style={{ width: 80 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <NumberInput selectOnFocus simple value={item.Data.model.amount} />}
                            </ColumnBody>
                        </Column>
                        <Column title="Costo U." className="text-right" style={{ width: 100 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitCost} />}
                            </ColumnBody>
                        </Column>
                        <Column title="Costo" className="text-right" style={{ width: 100 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalCost} />}
                            </ColumnBody>
                        </Column>
                        <Column title="Magine" className="text-right" style={{ width: 80, borderLeft: '5px solid #6685c5' }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <PercentageInput selectOnFocus simple value={item.Data.model.margin} />}
                            </ColumnBody>
                        </Column>
                        <Column title="P.Unit." className="text-right" style={{ width: 100 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitPrice} />}
                            </ColumnBody>
                        </Column>
                        <Column title="Sconto" className="text-right" style={{ width: 80 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <DiscountInput selectOnFocus simple value={item.Data.model.discount} />}
                            </ColumnBody>
                        </Column>
                        <Column title="P.N.Unit." className="text-right" style={{ width: 100 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.netUnitPrice} />}
                            </ColumnBody>
                        </Column>
                        <Column title="Totale" className="text-right" style={{ width: 100 }}>
                            <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalPrice} />}
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={2} className="flex-vertical">
                    <Layout.ScrollContainer>
                        <Table title="Entità Collegate" className="fixed-height" verticalAlign="middle" editable compact scrollable dataSource={{ array: this.rows, factory: this.factory }} headerActions={headerActions} emptyResultMessage="Nessuna entità collegata">
                            <Column title="Oggetto">
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => 
                                        <Column.WithIdentifier style={{ backgroundColor: item.Data.model.identifierColor }}>
                                            {this.renderEditor(item)}
                                        </Column.WithIdentifier>
                                    }
                                </ColumnBody>
                            </Column>
                            <Column title="Quantità" className="text-right" style={{ width: 80 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <NumberInput selectOnFocus simple value={item.Data.model.amount} />}
                                </ColumnBody>
                            </Column>
                            <Column title="Costo U." className="text-right" style={{ width: 100 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitCost} />}
                                </ColumnBody>
                            </Column>
                            <Column title="Costo" className="text-right" style={{ width: 100 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalCost} />}
                                </ColumnBody>
                            </Column>
                            <Column title="Magine" className="text-right" style={{ width: 80, borderLeft: '5px solid #6685c5' }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <PercentageInput selectOnFocus simple value={item.Data.model.margin} />}
                                </ColumnBody>
                            </Column>
                            <Column title="P.Unit." className="text-right" style={{ width: 100 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitPrice} />}
                                </ColumnBody>
                            </Column>
                            <Column title="Sconto" className="text-right" style={{ width: 80 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <DiscountInput selectOnFocus simple value={item.Data.model.discount} />}
                                </ColumnBody>
                            </Column>
                            <Column title="P.N.Unit." className="text-right" style={{ width: 100 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.netUnitPrice} />}
                                </ColumnBody>
                            </Column>
                            <Column title="Totale" className="text-right" style={{ width: 100 }}>
                                <ColumnBody>
                                    {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalPrice} />}
                                </ColumnBody>
                            </Column>
                            <Column style={{ width: 115 }} className="text-right">
                                <ColumnBody>
                                {(item : ITableItem<QuotationBillOfMaterialsRow>) => <>
                                    {item.Data.model.type === "BOM" &&  <button className="btn btn-xs btn-circle blue">
                                                                            <i className="fa fa-search"></i>&nbsp;Dettagli
                                                                        </button>}
                                    <button className="btn btn-xs btn-icon-only btn-circle red" onClick={() => this.removeRow(item.Data.model)}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </>}
                                </ColumnBody>
                            </Column>
                        </Table>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={2} row={"1/3"} className="flex-vertical">
                    <div className="form">
                        <div className="form-body">
                            <h3>Categorie</h3>
                            {this.categories().length === 0 && <h4 className="text-center">Nessuna Categoria</h4>}
                            {this.categories().map(this.renderCategory, this)}
                        </div>
                    </div>
                </Layout.Grid.Cell>
            </Layout.Grid>
        , this, 'qre') as React.ReactElement;
    }
}

export class QuotationRowEditorDialog extends DialogComponentBase {
    private editor : QuotationRowEditor;

    constructor(private props : QuotationRowEditorProps) {
        super({
            className: "large",
            bodyClassName: "flex-container flex-vertical flex-1"
        });

        this.title("Riga Preventivo");
    }

    action() {
        if(!this.editor.isValid())
            return;

        //Save
        const dataToSave = this.editor.getData();

        this.modal.close(dataToSave);
    }

    renderBody() {
        let QRE = require("./QuotationRowEditor").QuotationRowEditor as typeof QuotationRowEditor;
        return <QRE ref={(e) => this.editor = e} customerId={this.props.customerId} jobOrderId={this.props.jobOrderId} row={this.props.row} categories={this.props.categories} />;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationRowEditor);
    reloadNow(QuotationRowEditorDialog);
}