import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import "./ListComponent/ListComponent.scss";
import { Delay } from "../Decorators/Delay";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { Param, ComponentParam, ComponentUtils } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { IDataSourceModel, IDataSource, IDataSourceListener, IDataSourceView } from "../DataSources/IDataSource";
import { ITemplate } from "../ProlifeSdk/interfaces/invoice/IRegisterDocument";
import TsxForEach from "./ForEach";
import {
    isObservableArrayDataSource,
    ObservableArrayDataSource,
    synchronizeItems,
} from "../DataSources/ObservableArrayDataSource";
import { If } from "./IfIfNotWith";
import { Layout } from "./Layouts";

const attributes = {
    Sortable: "sortable",
    AllowNoSelection: "allowNoSelection",
    DataSource: "dataSource",
    Listener: "listener",
    InjectTo: "injectTo",
    ContainerHeight: "containerHeight",
    TextFilter: "textFilter",
    EmptyResultDefaultMessage: "emptyResultDefaultMessage",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            list: {
                params?:
                    | {
                          Sortable?: string;
                          AllowNoSelection?: string;
                          DataSource?: string;
                          Listener?: string;
                          ContainerHeight?: string;
                          TextFilter?: string;
                          EmptyResultDefaultMessage?: string;
                      }
                    | string;

                sortable?: boolean | (() => string);
                allowNoSelection?: boolean | (() => string);
                dataSource?: () => string;
                listener?: () => string;
                injectTo?: () => string;
                containerHeight?: string | (() => string);
                textFilter?: () => string;
                emptyResultDefaultMessage?: string | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IListComponentModel<I extends number | string, T> extends IDataSourceModel<I, T> {
    action?: {
        title: string;
        icon: string;
        action: (model: IListComponentModel<I, T>) => Promise<void>;
    };
    viewModel?: any;
}

interface IListComponentItemFactory<I extends number | string, T> {
    createViewModelFor(model: IListComponentModel<I, T>): ListItem<I, T>;
}

interface IListComponentParameters<I extends number | string, T> {
    Sortable: Param<boolean>;
    AllowNoSelection?: Param<boolean>;
    MultipleSelection?: Param<boolean>;
    AllowedMimeTypes?: string[];
    OnlyDropOver?: boolean;

    DataSource: Param<IDataSource> | ObservableArrayDataSource<T>;
    Listener?: IDataSourceListener | IDataSourceListener[];
    InjectTo?: (l: IListComponent<I, T>) => void;
    OnBeginDrag?: (model: IDataSourceModel<I, T>, dataTransfer: DataTransfer) => void;
    OnDrop?: (dataTransfer: DataTransfer, model: IDataSourceModel<I, T>, before: boolean) => void;

    onItemSelected?: (item: IListComponentModel<I, T>) => void;
    onItemDeselected?: (item: IListComponentModel<I, T>) => void;

    ContainerHeight?: Param<string>;
    TextFilter?: Param<string>;

    EmptyResultDefaultMessage?: Param<string>;
}

export interface IListComponent<I extends number | string, T> extends IDataSourceView {
    TextFilter: ko.Observable<string>;
    Items: ko.ObservableArray<ListItem<I, T>>;
    ItemsCount: ko.Computed<number>;

    getTextFilter(): string;
}

export interface IListItem<T> {
    Model: T;
}

export class ListItem<I extends number | string, T> implements IListItem<T> {
    public Id: I;
    public Title: ko.Observable<string> = ko.observable();
    public Subtitle: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public Model: T;
    public IsGroup = false;

    public Collapsed: ko.Observable<boolean> = ko.observable(false);
    public HasAction: ko.Observable<boolean> = ko.observable(false);
    public ActionTitle: ko.Observable<string> = ko.observable();
    public ActionIcon: ko.Observable<string> = ko.observable();

    constructor(protected listComponent: ListComponent<I, T>, public dataSourceModel: IListComponentModel<I, T>) {
        this.Id = dataSourceModel.id;
        this.Title(dataSourceModel.title);
        this.Subtitle(dataSourceModel.subTitle);
        this.Icon(dataSourceModel.icon ? dataSourceModel.icon.icon : "");
        this.Background(dataSourceModel.icon ? dataSourceModel.icon.background : "initial");
        this.Foreground(dataSourceModel.icon ? dataSourceModel.icon.foreground : "initial");
        this.Model = dataSourceModel.model;
    }

    public Select() {
        this.listComponent.onItemSelected(this.dataSourceModel);
    }

    public switchCollapsed() {
        this.Collapsed(!this.Collapsed());
    }

    public async Action(): Promise<any> {
        return;
    }
}

class ListItemGroup<I extends number | string, T> extends ListItem<I, T> {
    public Items: ko.ObservableArray<ListItem<I, T>> = ko.observableArray();

    public IsLoading: ko.Observable<boolean> = ko.observable(false);
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public HasLoadedData: ko.Observable<boolean> = ko.observable(false);

    constructor(
        listComponent: ListComponent<I, T>,
        public dataSourceModel: IListComponentModel<I, T>,
        private factory: IListComponentItemFactory<I, T>,
        private dataSource: IDataSource
    ) {
        super(listComponent, dataSourceModel);
        this.IsGroup = true;

        if (this.dataSourceModel.action) {
            this.HasAction(true);
            this.ActionTitle(this.dataSourceModel.action.title);
            this.ActionIcon(this.dataSourceModel.action.icon);
        }
    }

    public switchCollapsed() {
        this.Collapsed(!this.Collapsed());
    }

    public async Action(): Promise<any> {
        if (!this.dataSourceModel.action) return;
        return this.dataSourceModel.action.action(this.dataSourceModel);
    }

    public async loadNextPage(): Promise<boolean> {
        if (this.IsLoading()) return false;

        this.IsLoading(true);
        this.HasLoadedData(true);
        this.ShowLoadMore(false);

        try {
            const models = await this.dataSource.getData(
                this.dataSourceModel,
                this.listComponent.getTextFilter(),
                this.Items().length,
                this.listComponent.getPageSize()
            );

            if (models.length == 0) {
                this.IsLoading(false);
                this.HasLoadedData(true);
                this.ShowLoadMore(false);
                return false;
            }

            const viewModels = models.map(this.factory.createViewModelFor, this.factory);
            this.Items(this.Items().concat(viewModels));

            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(true);

            return true;
        } catch (e) {
            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(false);
            return false;
        }
    }
}

interface IListComponentConfig<I extends number | string, T> {
    currentSelection: IListComponentModel<I, T>[];
}

class ListComponent<I extends number | string, T> implements IListComponent<I, T> {
    public Items: ko.ObservableArray<ListItem<I, T>> = ko.observableArray();
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public IsGroupedDataSet: ko.Observable<boolean> = ko.observable(false);
    public IsLoading: ko.Observable<boolean> = ko.observable(false);
    public HasLoadedData: ko.Observable<boolean> = ko.observable(false);
    public TextFilter: ko.Observable<string>;
    public ContainerHeight: ComponentParam<string>;

    public EmptyResultTemplate: ko.Observable<ITemplate> = ko.observable();
    public EmptyResult: ko.Computed<boolean>;

    public EmptyResultDefaultMessage: string = ProlifeSdk.TextResources.ProlifeSdk.ListComponentEmptyResult;
    public EmptyResultMessage: Param<string>;

    public Sortable: ComponentParam<boolean>;
    public ItemsCount: ko.Computed<number>;
    public AllowedMimeTypes: string[] = [];

    private dataSource: IDataSource;
    private listeners: IDataSourceListener[];

    private refreshRequested = false;
    private pageSize = 30;

    private currentSelection: IListComponentModel<I, T>[] = [];
    private stateStack: IListComponentConfig<I, T>[] = [];

    private selectionDisabled = false;
    private multipleSelection: ComponentParam<boolean>;
    private allowNoSelection: ComponentParam<boolean>;

    constructor(private params: IListComponentParameters<I, T>) {
        this.Sortable = ComponentUtils.parseParameter(params.Sortable, false);
        this.ContainerHeight = ComponentUtils.parseParameter(params.ContainerHeight, "700px");
        this.allowNoSelection = ComponentUtils.parseParameter(params.AllowNoSelection, true);
        this.multipleSelection = ComponentUtils.parseParameter(params.MultipleSelection, false);
        this.TextFilter = ComponentUtils.parseParameter(params.TextFilter, "") as ko.Observable;
        this.EmptyResultMessage = ComponentUtils.parseParameter(
            params.EmptyResultDefaultMessage,
            this.EmptyResultDefaultMessage
        );

        const maybeDataSource = this.params.DataSource;
        if (isObservableArrayDataSource<T, I>(maybeDataSource)) {
            this.HasLoadedData(true);
            this.ShowLoadMore(false);
            this.AllowedMimeTypes = params.AllowedMimeTypes ?? [];

            const observableArrayDataSource: ObservableArrayDataSource<T, I> = maybeDataSource;
            synchronizeItems(this.Items, observableArrayDataSource, (item) =>
                this.createViewModelFor(observableArrayDataSource.factory(item))
            );
        } else {
            this.dataSource = ko.unwrap(maybeDataSource as ko.Observable);
            this.AllowedMimeTypes = [
                ...(params.AllowedMimeTypes ?? []),
                ...this.dataSource.getSupportedDropMimeTypes(),
            ];
            this.dataSource.setView(this);
        }

        if (Array.isArray(this.params.Listener)) {
            this.listeners = this.params.Listener.map((l) => ko.unwrap(l));
        } else if (this.params.Listener) {
            this.listeners = [ko.unwrap(this.params.Listener)];
        } else {
            this.listeners = [];
        }

        this.EmptyResult = ko.computed(() => {
            return this.HasLoadedData() && this.Items() && this.Items().length == 0;
        });

        this.ItemsCount = ko.computed(() => {
            return !this.Items() ? 0 : this.Items().length;
        });

        if (params.InjectTo) params.InjectTo(this);

        this.TextFilter.subscribe(() => {
            this.refresh();
        });
    }

    @Delay()
    public refresh(keepSelection = false): void {
        this.refreshImmediate(keepSelection);
    }

    public refreshImmediate(keepSelection = false): void {
        if (this.IsLoading()) {
            console.log("Refresh requested");
            this.refreshRequested = true;
        } else {
            this.pushState();

            console.log("Refreshing");
            this.Items([]);
            this.HasLoadedData(false);
            this.ShowLoadMore(true);
        }
    }

    getTextFilter(): string {
        return this.TextFilter();
    }

    getPageSize(): number {
        return this.pageSize;
    }

    setPageSize(size: number): void {
        this.pageSize = size;
    }

    setEmptyResultTemplate(template: ITemplate): void {
        this.EmptyResultTemplate(template);
    }

    pushState(): void {
        this.stateStack.push({
            currentSelection: this.currentSelection.slice(),
        });
    }

    popState(): void {
        const config = this.stateStack.pop();
        this.select(...config.currentSelection);
    }

    public clearSelection() {
        const items = this.Items();
        for (const selectedItem of this.currentSelection) {
            const listItem = items.firstOrDefault((i) => this.areEqual(i.dataSourceModel, selectedItem));
            if (listItem) listItem.Selected(false);

            for (const listener of this.listeners) this.notifyOnItemDeselected(listener, selectedItem);
            if (this.params.onItemDeselected) this.params.onItemDeselected(selectedItem);
        }

        this.currentSelection = [];
    }

    async select(...models: IListComponentModel<I, T>[]): Promise<void> {
        let validModels: IListComponentModel<I, T>[];
        if (!this.dataSource) {
            validModels = models;
        } else {
            validModels = (await this.dataSource.getById(
                null,
                models.map((m) => m.id)
            )) as IListComponentModel<I, T>[];
        }

        for (const selectedItem of this.currentSelection) {
            for (const listener of this.listeners) this.notifyOnItemDeselected(listener, selectedItem);
            if (this.params.onItemDeselected) this.params.onItemDeselected(selectedItem);
        }

        this.currentSelection = validModels;

        for (const itemToSelect of this.currentSelection) {
            for (const listener of this.listeners) this.notifyOnItemSelected(listener, itemToSelect);
            if (this.params.onItemSelected) this.params.onItemSelected(itemToSelect);
        }

        if (this.HasLoadedData() && !this.ShowLoadMore()) {
            this.selectMultipleItems(validModels, 0, false);
        }
    }

    private selectMultipleItems(
        models: IListComponentModel<I, T>[],
        startFrom = 0,
        notifyListeners = true
    ): IListComponentModel<I, T>[] {
        const items = this.Items().slice(startFrom);

        for (const item of items) {
            const wasSelected = item.Selected();
            item.Selected(false);

            if (wasSelected && notifyListeners) {
                this.listeners.forEach((l) => this.notifyOnItemDeselected(l, item.dataSourceModel));
                if (this.params.onItemDeselected) this.params.onItemDeselected(item.dataSourceModel);
            }
        }

        const notSelectedModels: IListComponentModel<I, T>[] = [];

        for (const model of models) {
            let found = false;
            items.forEach((i) => {
                if (!this.areEqual(model, i.dataSourceModel)) return;

                i.Selected(true);

                if (notifyListeners) this.onItemSelected(i.dataSourceModel);

                found = true;
            });

            if (!found) notSelectedModels.push(model);
        }

        return notSelectedModels;
    }

    private notifyOnItemSelected(listener: IDataSourceListener, model: IListComponentModel<I, T>) {
        try {
            listener.onItemSelected(this.dataSource, model);
        } catch (e) {
            (console.error || console.log).call(console, e.stack || e);
        }
    }

    private notifyOnItemDeselected(listener: IDataSourceListener, model: IListComponentModel<I, T>) {
        try {
            listener.onItemDeselected(this.dataSource, model);
        } catch (e) {
            (console.error || console.log).call(console, e.stack || e);
        }
    }

    public onItemSelected(model: IListComponentModel<I, T>): void {
        const calls: Promise<boolean>[] = [];

        this.listeners.forEach((l) => {
            if (!l.canSelectItem) {
                calls.push(new Promise<boolean>((resolve, reject) => resolve(true)));
                return;
            }

            calls.push(l.canSelectItem(this.dataSource, model));
        });

        Promise.all(calls).then((values: boolean[]) => {
            if (values.filter((v) => !v).length > 0) return;

            if (this.selectionDisabled) {
                this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));
                if (this.params.onItemSelected) this.params.onItemSelected(model);
                return;
            }

            if (this.multipleSelection()) {
                const item = this.getItemFromModel(model);
                if (item) {
                    item.Selected(!item.Selected());
                    if (item.Selected()) {
                        this.currentSelection.push(model);
                        this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));
                        if (this.params.onItemSelected) this.params.onItemSelected(model);
                    } else {
                        this.removeModelFromCurrentSelection(model);
                        this.listeners.forEach((l) => this.notifyOnItemDeselected(l, model));
                        if (this.params.onItemDeselected) this.params.onItemDeselected(model);
                    }
                }
                return;
            }

            this.deselectAll();
            for (const selectedItem of this.currentSelection) {
                this.listeners.forEach((l) => this.notifyOnItemDeselected(l, selectedItem));
                if (this.params.onItemDeselected) this.params.onItemDeselected(selectedItem);
            }

            const oldSelection = this.currentSelection[0];
            this.currentSelection = [];

            if (this.areEqual(oldSelection, model) && this.allowNoSelection) return;

            this.currentSelection.push(model);
            this.listeners.forEach((l) => this.notifyOnItemSelected(l, model));
            if (this.params.onItemSelected) this.params.onItemSelected(model);

            const item = this.getItemFromModel(model);
            if (item) item.Selected(true);
        });
    }

    private removeModelFromCurrentSelection(model: IListComponentModel<I, T>) {
        for (let i = 0; i < this.currentSelection.length; i++) {
            const selectedModel = this.currentSelection[i];
            if (this.areEqual(selectedModel, model)) {
                this.currentSelection.splice(i, 1);
                break;
            }
        }
    }

    private getItemFromModel(model: IListComponentModel<I, T>): ListItem<I, T> {
        return this.findItem(this.Items(), model);
    }

    private findItem(items: ListItem<I, T>[], model: IListComponentModel<I, T>): ListItem<I, T> {
        for (const item of items) {
            if (item.IsGroup) {
                const group = item as ListItemGroup<I, T>;
                const found = this.findItem(group.Items(), model);
                if (!found) continue;
                return found;
            }

            if (this.areEqual(item.dataSourceModel, model)) return item;
        }

        return null;
    }

    private deselectAll() {
        this.deselectAllItems(this.Items());
    }

    private deselectAllItems(items: ListItem<I, T>[]) {
        for (const item of items) {
            if (item.IsGroup) {
                const group = item as ListItemGroup<I, T>;
                this.deselectAllItems(group.Items());
                continue;
            }

            item.Selected(false);
        }
    }

    navigateTo(...history: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    /**
     * @returns true if there are more items to load, false otherwise
     */
    public async loadNextPage(): Promise<boolean> {
        if (this.IsLoading()) return false;

        console.log("Loading Next Page");

        this.IsLoading(true);
        this.HasLoadedData(true);
        this.ShowLoadMore(false);

        try {
            const currentModel = null; //this.currentItem();
            const isGroupedDataset = this.dataSource.isGroupedData(currentModel, this.TextFilter());
            this.IsGroupedDataSet(isGroupedDataset);
            //let currentModel = null;
            //let isGroupedDataset = false;

            const requestedCount = isGroupedDataset ? 10000000 : this.pageSize;
            const models = await this.dataSource.getData(
                currentModel,
                this.TextFilter(),
                this.Items().length,
                requestedCount
            );
            if (this.refreshRequested) {
                console.log("Aborting Load because Refresh was Requested");
                this.refreshRequested = false;
                this.IsLoading(false);
                this.refresh();
                return false;
            }

            if (models.length == 0) {
                this.IsLoading(false);
                this.HasLoadedData(true);
                this.ShowLoadMore(false);
                return false;
            }

            const viewModels = models.map(this.createViewModelFor, this);
            this.Items(this.Items().concat(viewModels));

            this.IsLoading(false);
            this.HasLoadedData(true);

            if (models.length == requestedCount) this.ShowLoadMore(true);
            return true;
        } catch (e) {
            console.error(e);

            this.IsLoading(false);
            this.HasLoadedData(true);
            this.ShowLoadMore(false);

            return false;
        }
    }

    private areEqual(a: IListComponentModel<I, T>, b: IListComponentModel<I, T>) {
        if (!this.dataSource) return a === b;

        return this.dataSource.areEqual(a, b);
    }

    public createViewModelFor(model: IListComponentModel<I, T>): ListItem<I, T> {
        const viewModel = model.isGroup
            ? new ListItemGroup(this, model, this, this.dataSource)
            : new ListItem(this, model);

        //Autoseleziono l'item se il suo model è nella lista dei selezionati
        if (this.currentSelection.filter((i) => this.areEqual(i, model)).length > 0) {
            viewModel.Selected(true);
        }

        return viewModel;
    }

    public OnBeginDrag(model: IDataSourceModel<I, T>, dataTransfer: DataTransfer) {
        if (this.dataSource && this.dataSource.onItemBeginMove) this.dataSource.onItemBeginMove(model, dataTransfer);
        if (this.params.OnBeginDrag) this.params.OnBeginDrag(model, dataTransfer);
    }

    public async OnRowMoved(dataTransfer: DataTransfer, model: ListItem<I, T>, before: boolean): Promise<void> {
        if (this.dataSource && this.dataSource.onItemMoved)
            this.dataSource.onItemMoved(dataTransfer, model ? model.dataSourceModel : null, before);
        if (this.params.OnDrop) this.params.OnDrop(dataTransfer, model ? model.dataSourceModel : null, before);
    }
}

type ListProps<I extends number | string, T> = {
    dataSource: IDataSource<I, T> | ObservableArrayDataSource<T, I>;
    sortable?: boolean;
    scrollable?: boolean;
    systemScrollable?: boolean;
    allowNoSelection?: boolean;
    multipleSelection?: boolean;
    allowedMimeTypes?: string[];
    onlyDropOver?: boolean;
    onBeginDrag?: (model: IDataSourceModel<I, T>, dataTransfer: DataTransfer) => void;
    onDrop?: (dataTransfer: DataTransfer, model: IDataSourceModel<I, T>, before: boolean) => void;
    containerHeight?: number | string;
    className?: string;
    emptyResultMessage?: string;
    ref?: (l: List<I, T>) => void;
    listener?: IDataSourceListener;
    textFilter?: ko.Observable<string>;
    emptyResultRenderer?: () => React.ReactElement;
    itemRenderer?: (item: ListItem<I, T>) => React.ReactElement;
    listAdditionalClasses?: string;

    onItemSelected?: (item: IListComponentModel<I, T>) => void;
    onItemDeselected?: (item: IListComponentModel<I, T>) => void;
};

export class List<I extends number | string, T> {
    private vm: ListComponent<I, T>;

    static defaultProps: Partial<ListProps<any, any>> = {
        scrollable: true,
        systemScrollable: false,
        allowedMimeTypes: [],
    };

    constructor(private props: ListProps<I, T>) {
        this.vm = new ListComponent({
            DataSource: this.props.dataSource,
            Sortable: this.props.sortable,
            AllowNoSelection: this.props.allowNoSelection,
            AllowedMimeTypes: this.props.allowedMimeTypes,
            MultipleSelection: this.props.multipleSelection,
            OnlyDropOver: this.props.onlyDropOver,
            OnBeginDrag: this.props.onBeginDrag,
            OnDrop: this.props.onDrop,
            onItemSelected: this.props.onItemSelected,
            onItemDeselected: this.props.onItemDeselected,
            ContainerHeight: this.props.containerHeight?.toString(),
            EmptyResultDefaultMessage: this.props.emptyResultMessage,
            //InjectTo: props.ref,
            Listener: this.props.listener,
            TextFilter: this.props.textFilter,
        });

        if (this.props.ref) this.props.ref(this);
    }

    public select(...items: T[]) {
        const actualListItems = this.vm.Items();
        const itemsToSelect = [];
        for (const item of items) {
            const existingItem = actualListItems.find((i) => i.Model === item);
            if (!existingItem || existingItem.Selected()) continue;

            itemsToSelect.push(existingItem);
        }

        if (itemsToSelect.length === 0) return;

        this.vm.select(...itemsToSelect.map((i) => i.dataSourceModel));
    }

    public clearSelection() {
        this.vm.clearSelection();
    }

    private renderItemContent(item: ListItem<I, T>) {
        return (
            <>
                <div class="list-notification-item-title" data-bind={{ text: item.Title }}></div>
                <div class="list-notification-item-time" data-bind={{ text: item.Subtitle }}></div>
            </>
        );
    }

    private renderItem(item: ListItem<I, T>) {
        const lc = this.vm;

        const clickProp: any = {};
        if (!this.props.itemRenderer) clickProp.onClick = item.Select.bind(item);

        return (
            <li
                class="list-notification-item"
                {...clickProp}
                data-bind={{
                    css: { selected: item.Selected },
                    draggableEx: {
                        CanDrag: item.dataSourceModel.dragEnabled && lc.Sortable(),
                        OnDrag: lc.OnBeginDrag.bind(lc, item.dataSourceModel),
                    },
                }}>
                <div
                    class="list-notification-item-icon"
                    data-bind={{ visible: lc.Sortable }}
                    style={{ cursor: "grab" }}>
                    <i class="fa fa-bars"></i>
                </div>
                <div class="list-notification-item-details">
                    {this.props.itemRenderer ? this.props.itemRenderer(item) : this.renderItemContent(item)}
                </div>
            </li>
        );
    }

    private renderGroup(item: ListItemGroup<I, T>) {
        return (
            <>
                <li class="list-notification-item-group">
                    <div class="list-notification-item-details">
                        <div class="list-notification-item-title">
                            <span className="flex-1" data-bind={{ text: item.Title }}></span>
                            <ko-bind data-bind={{ if: item.HasAction }}>
                                <button class="btn btn-primary btn-xs btn-circle" data-bind={{ click: item.Action }}>
                                    <i data-bind={{ css: item.ActionIcon }}></i>
                                    <span data-bind={{ text: item.ActionTitle }}></span>
                                </button>
                            </ko-bind>
                            <i
                                class="fa"
                                style="align-self: center"
                                data-bind={{
                                    click: item.switchCollapsed,
                                    css: { "fa-angle-down": !item.Collapsed(), "fa-angle-up": item.Collapsed },
                                }}></i>
                        </div>
                    </div>
                </li>
                <ko-bind data-bind={{ ifnot: item.Collapsed }}>
                    <TsxForEach data={item.Items} as="item">
                        {(child) => this.renderItem(child)}
                    </TsxForEach>
                    <ko-bind data-bind={{ if: item.ShowLoadMore }}>
                        <li
                            class="list-notification-item"
                            data-bind={{
                                notifyWhenVisible: {
                                    rootSelector: ".list-notification-container",
                                    callback: item.loadNextPage.bind(item),
                                },
                            }}>
                            &nbsp;
                        </li>
                    </ko-bind>
                    <li class="list-notification-item" data-bind={{ visible: item.IsLoading }}>
                        <div class="list-notification-item-details text-center">
                            <i class="fa fa-circle-o-notch fa-spin"></i>
                        </div>
                    </li>
                </ko-bind>
            </>
        );
    }

    render() {
        const lc = this.vm;
        const styles: any = {};
        const listAdditionalClasses = ComponentUtils.classNames(
            "list-notification list-notification-fit",
            this.props.listAdditionalClasses
        );
        let bindings = `droppableListEx: { allowedMimeTypes: lc.AllowedMimeTypes, onlyDropOver: lc.params.OnlyDropOver , hoverClass: 'list-notification-drop-hover', hoverBeforeClass: 'list-notification-item-drop-before', hoverAfterClass: 'list-notification-item-drop-after', itemSelector: '.list-notification-item', onDrop: lc.OnRowMoved.bind(lc) }`;
        if (this.props.scrollable) bindings = `slimScroll: lc.ContainerHeight, ${bindings}`;

        if (this.props.systemScrollable) {
            styles.height = lc.ContainerHeight();
        }

        return ComponentUtils.bindTo(
            <Layout.ScrollContainer systemScrollable={this.props.systemScrollable} verticalOnly style={styles}>
                <div
                    className={ComponentUtils.classNames("list-notification-container", this.props.className)}
                    data-bind={bindings}>
                    <ul className={listAdditionalClasses}>
                        <If condition={lc.EmptyResult}>
                            {() =>
                                this.props.emptyResultRenderer ? (
                                    <li>{this.props.emptyResultRenderer()}</li>
                                ) : (
                                    <li
                                        data-bind={{ text: lc.EmptyResultMessage }}
                                        className="list-notification-empty-message"></li>
                                )
                            }
                        </If>
                        <TsxForEach data={this.vm.Items} as="item">
                            {(child) =>
                                child.IsGroup ? this.renderGroup(child as ListItemGroup<I, T>) : this.renderItem(child)
                            }
                        </TsxForEach>
                        <ko-bind data-bind={{ if: lc.ShowLoadMore }}>
                            <li
                                class="list-notification-item"
                                data-bind={{
                                    notifyWhenVisible: {
                                        rootSelector: ".list-notification-container",
                                        callback: lc.loadNextPage.bind(lc),
                                    },
                                }}>
                                &nbsp;
                            </li>
                        </ko-bind>
                        <If condition={lc.IsLoading}>
                            {() => (
                                <li class="list-notification-item">
                                    <div class="list-notification-item-details text-center">
                                        <i class="fa fa-circle-o-notch fa-spin"></i>
                                    </div>
                                </li>
                            )}
                        </If>
                    </ul>
                </div>
            </Layout.ScrollContainer>,
            this.vm,
            "lc"
        );
    }
}

ko.components.register("list", {
    viewModel: {
        createViewModel: (params: IListComponentParameters<any, any>, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new ListComponent<any, any>(params);

            let templateFragment: string;

            if (componentInfo.templateNodes.length > 0) {
                templateFragment = `<div class="list-notification-container" data-bind="slimScroll: ContainerHeight, droppableListEx: { allowedMimeTypes: $component.AllowedMimeTypes, onlyDropOver: $component.params.OnlyDropOver, hoverClass: 'list-notification-drop-hover', hoverBeforeClass: 'list-notification-item-drop-before', hoverAfterClass: 'list-notification-item-drop-after', itemSelector: '.list-notification-item', onDrop: $data.OnRowMoved.bind($data) }">
                                        <ul class="list-notification list-notification-fit">
                                            <!-- ko if: EmptyResultTemplate -->    
                                                <li data-bind="visible: EmptyResult, template: { name: EmptyResultTemplate().templateName, templateUrl: EmptyResultTemplate().templateUrl }"></li>
                                            <!-- /ko -->
                                            <!-- ko ifnot: EmptyResultTemplate -->    
                                                <li data-bind="visible: EmptyResult, text: EmptyResultMessage" class="list-notification-empty-message"></li>
                                            <!-- /ko -->
                                            <!-- ko foreach: Items -->
                                                <!-- ko if: IsGroup -->
                                                    <li href="javascript:;" class="list-notification-item-group">
                                                        <div class="list-notification-item-details">
                                                            <div class="list-notification-item-title">
                                                                <span style="flex: 1" data-bind="text: Title"></span>
                                                                <!-- ko if: HasAction -->
                                                                    <button class="btn btn-primary btn-xs btn-circle" data-bind="click: Action">
                                                                        <i data-bind="css: ActionIcon"></i>
                                                                        <span data-bind="text: ActionTitle"></span>
                                                                    </button>
                                                                <!-- /ko -->
                                                                <i class="fa" style="align-self: center" data-bind="click: switchCollapsed, css: { 'fa-angle-down': !Collapsed(), 'fa-angle-up': Collapsed }"></i>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <!-- ko ifnot: Collapsed -->
                                                        <!-- ko foreach: Items -->
                                                            <li href="javascript:;" class="list-notification-item" data-bind="css: { selected: Selected }, draggableEx: { CanDrag: dataSourceModel.dragEnabled && $component.Sortable(), OnDrag: $component.OnBeginDrag.bind($component, dataSourceModel) }">
                                                                <div class="list-notification-item-icon" data-bind="visible: $component.Sortable" style="cursor: grab">
                                                                    <i class="fa fa-bars"></i>
                                                                </div>
                                                                <div class="list-notification-item-details" data-bind="template: { nodes: $componentTemplateNodes }">
                                                                    
                                                                </div>
                                                            </li>
                                                        <!-- /ko -->
                                                        <!-- ko if: ShowLoadMore -->
                                                            <li href="javascript:;" class="list-notification-item" data-bind="notifyWhenVisible: { rootSelector: '.list-notification-container', callback: $data.loadNextPage.bind($data) }">
                                                                &nbsp;
                                                            </li>
                                                        <!-- /ko -->
                                                        <li href="javascript:;" class="list-notification-item" data-bind="visible: IsLoading">
                                                            <div class="list-notification-item-details text-center">
                                                                <i class="fa fa-circle-o-notch fa-spin"></i>
                                                            </div>
                                                        </li>
                                                    <!-- /ko -->
                                                <!-- /ko -->
                                                <!-- ko ifnot: IsGroup -->
                                                    <li href="javascript:;" class="list-notification-item" data-bind="css: { selected: Selected }, draggableEx: { CanDrag: dataSourceModel.dragEnabled && $component.Sortable(), OnDrag: $component.OnBeginDrag.bind($component, dataSourceModel) }">
                                                        <div class="list-notification-item-icon" data-bind="visible: $component.Sortable" style="cursor: grab">
                                                            <i class="fa fa-bars"></i>
                                                        </div>
                                                        <div class="list-notification-item-details" data-bind="template: { nodes: $componentTemplateNodes }">
                                                            
                                                        </div>
                                                    </li>
                                                <!-- /ko -->
                                            <!-- /ko -->
                                            <!-- ko if: ShowLoadMore -->
                                                <li href="javascript:;" class="list-notification-item" data-bind="notifyWhenVisible: { rootSelector: '.list-notification-container', callback: $data.loadNextPage.bind($data) }">
                                                    &nbsp;
                                                </li>
                                            <!-- /ko -->
                                            <li href="javascript:;" class="list-notification-item" data-bind="visible: IsLoading">
                                                <div class="list-notification-item-details text-center">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>`;
            } else {
                templateFragment = `<div class="list-notification-container" data-bind="slimScroll: ContainerHeight, droppableListEx: { allowedMimeTypes: $component.AllowedMimeTypes, onlyDropOver: $component.params.OnlyDropOver, hoverClass: 'list-notification-drop-hover', hoverBeforeClass: 'list-notification-item-drop-before', hoverAfterClass: 'list-notification-item-drop-after', itemSelector: '.list-notification-item', onDrop: $data.OnRowMoved.bind($data) }">
                                        <ul class="list-notification list-notification-fit">
                                            <!-- ko if: EmptyResultTemplate -->    
                                                <li data-bind="visible: EmptyResult, template: { name: EmptyResultTemplate().templateName, templateUrl: EmptyResultTemplate().templateUrl }"></li>
                                            <!-- /ko -->
                                            <!-- ko ifnot: EmptyResultTemplate -->    
                                                <li data-bind="visible: EmptyResult, text: EmptyResultMessage" class="list-notification-empty-message"></li>
                                            <!-- /ko -->
                                            <!-- ko foreach: Items -->
                                                <!-- ko if: IsGroup -->
                                                    <li href="javascript:;" class="list-notification-item-group">
                                                        <div class="list-notification-item-details">
                                                            <div class="list-notification-item-title">
                                                                <span style="flex: 1" data-bind="text: Title"></span>
                                                                <!-- ko if: HasAction -->
                                                                    <button class="btn btn-primary btn-xs btn-circle" data-bind="click: Action">
                                                                        <i data-bind="css: ActionIcon"></i>
                                                                        <span data-bind="text: ActionTitle"></span>
                                                                    </button>
                                                                <!-- /ko -->
                                                                <i class="fa" style="align-self: center" data-bind="click: switchCollapsed, css: { 'fa-angle-down': !Collapsed(), 'fa-angle-up': Collapsed }"></i>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <!-- ko ifnot: Collapsed -->
                                                        <!-- ko foreach: Items -->
                                                            <li href="javascript:;" class="list-notification-item" data-bind="click: Select, css: { selected: Selected }, draggableEx: { CanDrag: dataSourceModel.dragEnabled && $component.Sortable(), OnDrag: $component.OnBeginDrag.bind($component, dataSourceModel) }">
                                                                <div class="list-notification-item-icon" data-bind="visible: $component.Sortable" style="cursor: grab">
                                                                    <i class="fa fa-bars"></i>
                                                                </div>
                                                                <div class="list-notification-item-details">
                                                                    <div class="list-notification-item-title" data-bind="text: Title"></div>
                                                                    <div class="list-notification-item-time" data-bind="text: Subtitle"></div>
                                                                </div>
                                                            </li>
                                                        <!-- /ko -->
                                                        <!-- ko if: ShowLoadMore -->
                                                            <li href="javascript:;" class="list-notification-item" data-bind="notifyWhenVisible: { rootSelector: '.list-notification-container', callback: $data.loadNextPage.bind($data) }">
                                                                &nbsp;
                                                            </li>
                                                        <!-- /ko -->
                                                        <li href="javascript:;" class="list-notification-item" data-bind="visible: IsLoading">
                                                            <div class="list-notification-item-details text-center">
                                                                <i class="fa fa-circle-o-notch fa-spin"></i>
                                                            </div>
                                                        </li>
                                                    <!-- /ko -->
                                                <!-- /ko -->
                                                <!-- ko ifnot: IsGroup -->
                                                    <li href="javascript:;" class="list-notification-item" data-bind="click: Select, css: { selected: Selected }, draggableEx: { CanDrag: dataSourceModel.dragEnabled && $component.Sortable(), OnDrag: $component.OnBeginDrag.bind($component, dataSourceModel) }">
                                                        <div class="list-notification-item-icon" data-bind="visible: $component.Sortable" style="cursor: grab">
                                                            <i class="fa fa-bars"></i>
                                                        </div>
                                                        <div class="list-notification-item-details">
                                                            <div class="list-notification-item-title" data-bind="text: Title"></div>
                                                            <div class="list-notification-item-time" data-bind="text: Subtitle"></div>
                                                        </div>
                                                    </li>
                                                <!-- /ko -->
                                            <!-- /ko -->
                                            <!-- ko if: ShowLoadMore -->
                                                <li href="javascript:;" class="list-notification-item" data-bind="notifyWhenVisible: { rootSelector: '.list-notification-container', callback: $data.loadNextPage.bind($data) }">
                                                    &nbsp;
                                                </li>
                                            <!-- /ko -->
                                            <li href="javascript:;" class="list-notification-item" data-bind="visible: IsLoading">
                                                <div class="list-notification-item-details text-center">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>`;
            }
            ko.virtualElements.setDomNodeChildren(componentInfo.element, ko.utils.parseHtmlFragment(templateFragment));

            return vm;
        },
    },
    template: [],
});
