import moment = require("moment");
import { LazyImport, Service } from "../Core/DependencyInjection";
import { IService } from "../Core/interfaces/IService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { MailEventsGeneratorDialog } from "./Blog/Dialog/MailEventsGenerator/MailEventsGenerator";
import { IBlogMailData } from "../ProlifeSdk/interfaces/blog/IBlogService";
import { IAjaxService, UploadData } from "../Core/interfaces/IAjaxService";
import { PromiseWithProgress } from "../Core/Deferred";
import { ITag } from "../ProlifeSdk/interfaces/ITag";

export type TemporaryMailStorageChanges = {
    CompanyGuid: string;
    UserId: number;
    MailUserId: number;
}

export interface IEventBaseData {
    Subject: string;
    Date: Date;
    Tags: ITag[];
}

export interface IJobOrderWithActivities {
    Id: number;
    Name: string;
    Activities: number[];
}

export interface IExtendedBlogMailData extends IBlogMailData {
    Id : number;
    UserId : number;
    TemporaryStoragePath : string;
}

export interface IGetMailsFromTemporaryStorageRequest {
    userId : number;
    textFilter : string;
    skip : number;
    count : number;
}

export interface ITemporaryMailStorageAuthorizations {
    UserId?: number;
    AccessibleUsers : number[];
}

export interface ITemporaryMailStorageData {
    Id : number;
    UserId : number;
    MailDate : Date;
    TemporaryStoragePath : string;
    SerializedMailData : string;
}

export interface IMailEventsGeneratorData {
    SelectedMails: IExtendedBlogMailData[];
    SelectedActivities: IJobOrderWithActivities[];

    MailSubject: string;
    MailBody: string;
    MailDate: Date;
    MailSenders: ITag[];
    MailTo: ITag[];
    MailCc: ITag[];
    MailBcc: ITag[];
    MailAttachments: ITag[];

    EventBaseData: IEventBaseData;
}

export interface IMailsService extends IService {
    openMailEventsGenerator(filesToUpload?: File[]) : Promise<void>;

    GetMailsFromTemporaryStorage(request : IGetMailsFromTemporaryStorageRequest) : Promise<IExtendedBlogMailData[]>;
    GetMailsFromTemporaryStorageByIds(ids : number[]) : Promise<IExtendedBlogMailData[]>;
    GetTemporaryMailStorageAuthorizationsForUser(userId : number | null) : Promise<ITemporaryMailStorageAuthorizations>;
    RemoveMailsFromTemporaryStorage(ids : number[]) : Promise<void>;

    MailUploadOnTemporaryStorage(data : UploadData) : PromiseWithProgress<IExtendedBlogMailData>;
    GenerateMailBlogEvents(data: IMailEventsGeneratorData): Promise<void>;
    GetMailsToManageCountForUser(userId: number | null): Promise<number>;
}

@Service(nameof<IMailsService>())
class MailsService implements IMailsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor () { }
    
    async GetMailsFromTemporaryStorage(request : IGetMailsFromTemporaryStorageRequest) : Promise<IExtendedBlogMailData[]> {
        return this.ajaxService.Post<IExtendedBlogMailData[]>("Blog-api/Mail", "GetMailsFromTemporaryStorage", {
            methodData: request
        });
    }

    GetMailsFromTemporaryStorageByIds(ids: number[]): Promise<IExtendedBlogMailData[]> {
        let result = this.ajaxService.Post<IExtendedBlogMailData[]>("Blog-api/Mail", "GetMailsOnTemporaryStorageByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });

        return result;
    }

    RemoveMailsFromTemporaryStorage(ids : number[]) : Promise<void> {
        let result = this.ajaxService.Post<void>("Blog-api/Mail", "RemoveMailsFromTemporaryStorage", {
            background: true,
            methodData: {
                mailsIds: ids,
            }
        });

        return result;
    }

    MailUploadOnTemporaryStorage(data : UploadData) : PromiseWithProgress<IExtendedBlogMailData> {
        return this.ajaxService.Upload<IExtendedBlogMailData>("Blog-api/Mail", "MailUploadOnTemporaryStorage", data, false);
    }

    public async openMailEventsGenerator() : Promise<void> {
        const generator = new MailEventsGeneratorDialog();
        return generator.show();
    }

    GetTemporaryMailStorageAuthorizationsForUser(userId : number | null) : Promise<ITemporaryMailStorageAuthorizations> {
        let result = this.ajaxService.Post<ITemporaryMailStorageAuthorizations>("Blog-api/Mail", "GetTemporaryMailStorageAuthorizationsForUser", {
            background: true,
            methodData: {
                userId: userId,
            }
        });



        return result;
    }

    GenerateMailBlogEvents(data: IMailEventsGeneratorData): Promise<void> {
        return this.ajaxService.Post<void>("Blog-api/Mail", "GenerateMailBlogEvents", {
            methodData: data
        });
    }

    GetMailsToManageCountForUser(userId: number | null): Promise<number> {
        let result = this.ajaxService.Post<number>("Blog-api/Mail", "GetMailsToManageCountForUser", {
            background: true,
            methodData: {
        		userId: userId,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IMailsService>());
}
