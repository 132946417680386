import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { WorkedHoursApplication2019 } from "./workedhours/WorkedHoursApplication2019";
import { WorkTimeCategoriesSettingsManager } from "./workedhours/settings/WorkTimeCategoriesSettingsManager";
import { CallRightTypesSettingsManager } from "./workedhours/settings/CallRightTypesSettingsManager";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { WorkSheetEvent } from "./workedhours/ui/events/WorkSheetEvent";
import { WorkedHoursReportsProvider } from "./workedhours/reports/WorkedHoursReportsProvider";
import { CallRightRefDetailsViewModelFactory } from "./workedhours/documents/providers/CallRightRefDetailsViewModelFactory";
import { CallRightRefConverterFactory } from "./workedhours/documents/converters/CallRightRefConverterFactory";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { WorkedHoursEditorDialog } from "./workedhours/ui2019/dialog/WorkedHoursEditorDialog";
import { WorkedHoursEvent } from "./workedhours/ui/events/WorkedHoursEvent";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IEntityProviderService } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IWorkSheet, IWorkSheetRow } from "../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import {
    IWorkedHoursService,
    IMonthlyHoliday,
    IWorkedHoursInADay,
    IWorkedOnJobOrder,
    IWorkedHoursAnomaly,
    IPreferredActivityWithNote,
    IMonthSyntheticData,
    ISuggestedWorkableElement,
    IMonthlyHour,
    ISearchCountRequest,
    ISearchRequest,
    IExtendedWorkSheetRow,
    IValidRolesForTask,
    IGetSuggestedWorkableElementsRequest,
    IJobOrderDailyPlan,
    IMonthlyReportRecordForRemote,
    IWorkPlace,
    IHoursApproval_Type,
    IWorkedHoursWithApprovalStateForList,
    IWorkedHoursWithApprovalStateForEditing,
    IWorkedHoursWithApprovalStateForEditingWorkedHours,
    IServiceOrderCostWithBudget,
    IHoursApprovalRow,
    ICheckServiceOrderHoursBalanceIsInWarningOrError,
    IWorkedHoursWithApprovalStateForListWorkedHours,
    ILockedMonth,
    IGetResourceServiceOrdersSalariesBudgetsRequest,
    ICheckServiceOrderFlexibilityBalance,
} from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IReportsService } from "../ProlifeSdk/interfaces/report/IReportsService";
import { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IModulesService } from "../ProlifeSdk/interfaces/desktop/IModulesService";
import { IWorkableElement } from "../ProlifeSdk/interfaces/worked-hours/IWorkableElement";
import {
    IWorkedHoursMonthlyReportRecord,
    IWorkedHoursMonthlyReportByCharacterRecord,
} from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursMonthlyReportRecord";
import { IBusinessMovement } from "../ProlifeSdk/interfaces/worked-hours/IBusinessMovement";
import { IWorkedHoursAnomaliesRule } from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursAnomaliesRule";
import {
    IResourceDailyPlanEx,
    IMonthPlanKey,
    IDayPlanKey,
    IJobOrderMonthPlanKey,
} from "../ProlifeSdk/interfaces/worked-hours/IResourceDailyPlan";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { IWorkedAmountExtendedRow } from "../ProlifeSdk/interfaces/worked-hours/IWorkedAmountRow";
import { HoursApprovalDialog } from "./workedhours/ui2019/dialog/HoursApprovalDialog";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { WorkTimeCategoriesRemainingBudgetDialog } from "./workedhours/ui2019/dialog/WorkTimeCategoriesRemainingBudgetDialog";
import { HoursApprovalState } from "./workedhours/enums/HoursApprovalState";
import { ResponseBase, ResponseData } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";

interface IBlogViewModels {
    [type: string]: new (
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver
    ) => BlogEventBase;
}

@Service(nameof<IWorkedHoursService>(), ProlifeSdk.WorkedHoursServiceType)
class WorkedHoursService implements IWorkedHoursService, IEventUiProvider, ISettingsServiceObserver {
    @LazyImport(nameof<IModulesService>())
    private modulesService!: IModulesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService!: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew!: IAjaxServiceNew;
    @LazyImport(nameof<IReportsService>())
    private reportsService!: IReportsService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService!: IEntityProviderService;
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService!: IProLifeSdkService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService!: ISettingsService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService!: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private eventsViewModels: IBlogViewModels = {};

    //private application : WorkedHoursApplication;
    private application: WorkedHoursApplication2019;
    private nextFakeId = 0;

    InitializeService() {
        this.application = new WorkedHoursApplication2019();
        //this.application = new WorkedHoursApplication(this.serviceLocator);

        this.entitiesService.RegisterEntity({
            EntityCode: ProlifeSdk.WorkedHoursEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.WorkedHours.WorkedHours,
            PluralEntityName: ProlifeSdk.TextResources.WorkedHours.WorkedHours,
        });

        this.entitiesService.RegisterEntity({
            EntityCode: ProlifeSdk.EstimatedCallRightTypeCode,
            EntityName: ProlifeSdk.TextResources.WorkedHours.CallRight,
            PluralEntityName: ProlifeSdk.TextResources.WorkedHours.CallRights,
        });

        new WorkTimeCategoriesSettingsManager();
        new CallRightTypesSettingsManager();

        this.settingsService.addObserver(this);
        this.eventsViewModels[ProlifeSdk.WorkedHoursEventType_WorkSheet] = WorkSheetEvent;
        this.eventsViewModels[ProlifeSdk.WorkedHoursEventType_WorkedHours] = WorkedHoursEvent;

        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.EstimatedCallRightTypeCode,
            new CallRightRefDetailsViewModelFactory()
        );

        this.documentsService.registerRowReferencesGeneratorFactory(new CallRightRefConverterFactory());
    }

    public GetWorkSheetUrl(resourceId: number, workDate: Date): string {
        if (!workDate || !resourceId) return "#/WorkedHours";

        return "#/WorkedHours/" + moment(workDate).format("DDMMYYYY") + "/" + resourceId;
    }

    getServiceType(): string {
        return ProlifeSdk.WorkedHoursServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public GetMonthlyHolidays(year: number, month: number): Promise<IMonthlyHoliday[]> {
        return this.ajaxService.Post("WorkedHours-api", "MonthPlan/LoadMonthlyHolidays", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .startOf("day")
                    .toDate(),
                ResourceId: -1,
            },
        });
    }

    public GetWorkableElements(
        getJobOrders: boolean,
        getProjects: boolean,
        getWorkItems: boolean,
        getTasks: boolean,
        getClosed: boolean,
        filter: string,
        numberOfElementsToSkip: number,
        maxNumberOfElementsToGet: number,
        resourceId: number,
        containerId?: number,
        containerType?: string,
        filterByActivityProgressMode?: number
    ): Promise<IWorkableElement[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkableElements/HintSearch", {
            methodData: {
                Filter: filter,
                GetJobOrders: getJobOrders,
                GetProjects: getProjects,
                GetWorkItems: getWorkItems,
                GetTasks: getTasks,
                NumberOfElementsToSkip: numberOfElementsToSkip,
                MaxNumberOfElementsToGet: maxNumberOfElementsToGet,
                ContainerId: containerId,
                ContainerType: containerType,
                GetClosed: getClosed,
                FilterByActivityProgressMode: filterByActivityProgressMode,
                ResourceId: resourceId,
            },
            background: true,
        });
    }

    public GetWorkableElement(id: number, elementType: string): Promise<IWorkableElement> {
        return this.ajaxService.Post("WorkedHours-api", "WorkableElements/FindElement", {
            methodData: {
                Id: id,
                ElementType: elementType,
            },
        });
    }

    public GetWorkSheet(resourceId: number, day: Date): Promise<IWorkSheet> {
        if (!resourceId || !day) return Promise.resolve<IWorkSheet>(undefined);

        day = moment(day).startOf("day").toDate();

        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/FindWorkSheet", {
            methodData: {
                Day: day,
                ResourceId: resourceId,
            },
        });
    }

    public GetWorkSheetById(id: number): Promise<IWorkSheet> {
        return this.ajaxService.Get("WorkedHours-api", "WorkSheet?id=" + id, {});
    }

    public GetWorkedHoursByResourcesInWorkDay(
        workDay: Date,
        resourcesIds: number[],
        eventIdToIgnore: number
    ): Promise<IWorkedHoursInADay[]> {
        return this.ajaxService.Post("WorkedHours-api", "ResourceWorkedHours/GetWorkedHoursByResourcesInWorkDay", {
            methodData: {
                Resources: resourcesIds,
                Day: workDay,
                EventToIgnore: eventIdToIgnore,
            },
        });
    }

    public ImportPreviousMonthImbalance(year: number, month: number, jobOrderId: number): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursMonthlyReport/ImportPreviousMonthImbalance", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
                JobOrderId: jobOrderId,
            },
        });
    }

    public CalculateWorkedOnJobOrdersReport(
        textFilter: string,
        from: Date,
        to: Date,
        type: number,
        state: number
    ): Promise<IWorkedOnJobOrder[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedOnJobOrdersReport/CalculateReport", {
            methodData: {
                TextFilter: textFilter,
                From: from,
                To: to,
                Type: type,
                State: state,
            },
        });
    }

    public CalculateMonthlyReport(year: number, month: number): Promise<IWorkedHoursMonthlyReportRecord[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursMonthlyReport/CalculateMonthlyReport", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
            },
        });
    }

    public CalculateMonthlyReportByCharacter(
        year: number,
        month: number,
        customerId: number,
        jobOrderId: number,
        showNotBillable: boolean
    ): Promise<IWorkedHoursMonthlyReportByCharacterRecord[]> {
        return this.ajaxService.Post(
            "WorkedHours-api",
            "WorkedHoursMonthlyReportByCharacter/CalculateMonthlyReportByCharacter",
            {
                methodData: {
                    DayOfMonth: moment()
                        .year(year)
                        .month(month - 1)
                        .date(1)
                        .toDate(),
                    JobOrderId: jobOrderId,
                    CustomerId: customerId,
                    ShowNotBillable: showNotBillable,
                },
            }
        );
    }

    public GetMonthlyBusinessMovements(year: number, month: number): Promise<IBusinessMovement[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursMonthlyReport/GetMonthlyBusinessMovements", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
            },
        });
    }

    public GetWorkedHoursByIds(ids: number[]): Promise<IWorkSheetRow[]> {
        return this.ajaxService.Post("WorkedHours-api", "HourRecords/GetWorkedHoursByIds", { methodData: ids });
    }

    public DeleteBusinessMovements(movementsIds: number[]): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursMonthlyReport/DeleteBusinessMovements", {
            methodData: movementsIds,
        });
    }

    public GetLockStatusForMonth(year: number, month: number): Promise<boolean> {
        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/GetLockStatusForMonth", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
            },
        });
    }

    public SetLockStatusForMonth(year: number, month: number, locked: boolean): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/SetLockStatusForMonth", {
            methodData: {
                Date: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
                Locked: locked,
            },
        });
    }

    public GetAnomaliesRules(): Promise<IWorkedHoursAnomaliesRule[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursAnomalies/FindAllRules", {});
    }

    public CalculateAnomalies(from: Date, to: Date): Promise<IWorkedHoursAnomaly[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursAnomalies/CalculateAnomalies", {
            methodData: {
                From: from,
                To: to,
            },
        });
    }

    public SaveAnomaliesRules(rules: IWorkedHoursAnomaliesRule[]): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedHoursAnomalies/UpdateRules", { methodData: rules });
    }

    public SaveWorkSheet(sheet: IWorkSheet): Promise<IWorkSheet> {
        if (sheet) sheet.Day = this.sdkService.Utilities.Dates.AddGmtTime(moment(sheet.Day).startOf("day").toDate());

        return this.ajaxService.Post("WorkedHours-api", "WorkSheet", { methodData: sheet });
    }

    public UpdateWorkSheetWithBusinessManagementMovement(sheet: IWorkSheet): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/UpdateWorkSheetWithBusinessManagementMovement", {
            methodData: sheet,
        });
    }

    public GetResourceMonthPlan(year: number, month: number, resourceId: number): Promise<IResourceDailyPlanEx[]> {
        const request: IMonthPlanKey = {
            ResourceId: resourceId,
            Days: this.generateMonthPlanDates(year, month),
        };

        return this.ajaxService.Post("WorkedHours-api", "MonthPlan/FindElement", {
            methodData: request,
        });
    }

    public GetPreferredActivitiesForResource(resourceId: number, day: Date): Promise<IPreferredActivityWithNote[]> {
        return this.ajaxService.Post("WorkedHours-api", "PreferredActivities/FindPreferredActivitiesForResource", {
            methodData: {
                ResourceId: resourceId,
                Day: day,
            },
        });
    }

    public CalculateMonthSyntheticDataForResource(
        resourceId: number,
        year: number,
        month: number
    ): Promise<IMonthSyntheticData> {
        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/CalculateMonthSyntheticDataForResource", {
            methodData: {
                ResourceId: resourceId,
                StartOfMonth: moment()
                    .year(year)
                    .month(month - 1)
                    .date(1)
                    .toDate(),
            },
        });
    }

    public GetMassiveInsertedRowsByDateForResource(resourceId: number, day: Date): Promise<IWorkSheetRow[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkSheet/FindMassiveInsertedRowsByDateForResource", {
            methodData: {
                Day: day,
                ResourceId: resourceId,
            },
        });
    }

    public getUIForEvent(item: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        const type = this.eventsViewModels[item.EventType] || BlogEventBase;

        const event: BlogEventBase = new type(Core.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView>event;
    }

    LoadWorkedAmountsRows(
        jobOrdersIds: number[],
        workflowId: number,
        taskId: number,
        date: Date,
        showClosed: boolean,
        resourceId: number,
        skip: number,
        count: number
    ): Promise<IWorkedAmountExtendedRow[]> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedAmounts/LoadWorkedAmountsRows", {
            methodData: {
                JobOrdersIds: jobOrdersIds,
                WorkflowId: workflowId,
                TaskId: taskId,
                Date: date,
                ShowClosed: showClosed,
                ResourceId: resourceId,
                Skip: skip,
                Count: count,
            },
        });
    }

    SaveWorkedAmount(data: IWorkedAmountExtendedRow): Promise<IWorkedAmountExtendedRow> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedAmounts/SaveWorkedAmount", {
            methodData: {
                WorkedAmountRow: data,
            },
        });
    }

    DeleteWorkedAmount(data: IWorkedAmountExtendedRow): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api", "WorkedAmounts/DeleteWorkedAmount", {
            methodData: {
                WorkedAmountRow: data,
            },
        });
    }

    GetNextFakeId(): number {
        return this.nextFakeId--;
    }

    onSettingsLoaded(): void {
        if (this.modulesService.IsModuleEnabled(ProlifeSdk.WorkedHoursApplicationCode))
            this.reportsService.registerReportsProvider(new WorkedHoursReportsProvider());
    }

    onSettingsUpdated(updateType: string): void {}

    GetMonthlyHours(
        startDate: Date,
        endDate: Date,
        resourcesTextFilter: string,
        groups: number[]
    ): Promise<IMonthlyHour[]> {
        const intervals: IDayPlanKey[] = [];
        const end = moment(endDate).clone();
        const currentDate = moment(startDate).clone();

        while (currentDate < end) {
            const endOfDay = currentDate.clone().endOf("day");

            intervals.push({
                Id: currentDate.date(),
                StartOfDay: currentDate.toDate(),
                EndOfDay: endOfDay.toDate(),
            });

            currentDate.add(1, "day");
        }

        return this.ajaxService.Post<IMonthlyHour[]>("WorkedHours-api", "WorkSheet/GetMonthlyHours", {
            methodData: {
                Dates: intervals,
                ResourcesTextFilter: resourcesTextFilter ?? "",
                Groups: groups ?? [],
            },
        });
    }

    async SaveHour(
        resourceId: number,
        hour: IWorkedHoursWithApprovalStateForEditingWorkedHours,
        adjustTaskReferenceCreationDate = false,
        forceSavingImportedInDocumentsHours = false,
        ignoreServiceOrderWarnings = false,
        isFlexibility = false
    ): Promise<ResponseData<IWorkedHoursWithApprovalStateForEditingWorkedHours>> {
        const result = await this.ajaxServiceNew.Post<ResponseData>("h/workedHours", "upsert", {
            methodData: {
                ResourceId: resourceId,
                Hour: hour,
                AdjustTaskReferenceCreationDate: adjustTaskReferenceCreationDate,
                ForceSavingImportedInDocumentsHours: forceSavingImportedInDocumentsHours,
                IgnoreServiceOrderWarnings: ignoreServiceOrderWarnings,
                IsFlexibility: isFlexibility,
            },
        });

        result.data = toPascalCase(result.data);
        return result;
    }

    DeleteHour(hourId: number, forceSavingImportedInDocumentsHours = false): Promise<void> {
        return this.ajaxService.Post("WorkedHours-api/WorkSheet", "DeleteHour", {
            methodData: {
                HourId: hourId,
                ForceSavingImportedInDocumentsHours: forceSavingImportedInDocumentsHours,
            },
        });
    }

    SuggestStartTime(resourceId: number, startOfDay: Date, endOfDay: Date): Promise<Date> {
        return this.ajaxService.Post("WorkedHours-api/WorkSheet", "SuggestStartTime", {
            methodData: {
                ResourceId: resourceId,
                StartOfDay: startOfDay,
                EndOfDay: endOfDay,
            },
        });
    }

    ShowApprovalDialog(hours: IWorkedHoursWithApprovalStateForListWorkedHours): Promise<boolean> {
        const dialog = new HoursApprovalDialog({ hours: hours });
        return this.dialogsService.ShowModal(dialog);
    }

    ShowWorkedHoursEditorDialog(
        resourceId: number,
        workDate: Date,
        jobOrderId: number = null,
        taskId: number = null,
        hoursId: number = null,
        canEditDate = false
    ): Promise<boolean> {
        const dialog = new WorkedHoursEditorDialog(resourceId, workDate, jobOrderId, taskId, hoursId, canEditDate);
        return dialog.show();
    }

    GetValidRolesForTask(
        resourceId: number,
        taskId: number,
        jobOrderId: number,
        date: Date
    ): Promise<IValidRolesForTask> {
        const result = this.ajaxService.Post<IValidRolesForTask>(
            "WorkedHours-api/WorkedHoursRoles",
            "GetValidRolesForTask",
            {
                background: true,
                methodData: {
                    resourceId,
                    taskId,
                    jobOrderId,
                    date,
                },
            }
        );

        return result;
    }

    private generateMonthPlanDates(year: number, month: number): IDayPlanKey[] {
        const startDate = moment()
            .year(year)
            .month(month - 1)
            .date(1)
            .startOf("day");
        const currentDate = startDate.clone();

        const days = [];
        while (currentDate.month() == startDate.month()) {
            const endOfDay = currentDate.clone().endOf("day");

            days.push({
                Id: currentDate.date(),
                StartOfDay: currentDate.toDate(),
                EndOfDay: endOfDay.toDate(),
            });

            currentDate.add(1, "day");
        }

        return days;
    }

    GetMonthPlanForJobOrder(year: number, month: number, jobOrderId: number): Promise<IJobOrderDailyPlan[]> {
        const request: IJobOrderMonthPlanKey = {
            JobOrderId: jobOrderId,
            Days: this.generateMonthPlanDates(year, month),
        };

        const result = this.ajaxService.Post<IJobOrderDailyPlan[]>(
            "WorkedHours-api/MonthPlan",
            "GetMonthPlanForJobOrder",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetMonthlyReportForRemotePlugin(
        dayOfMonth: Date | null,
        includeEmptyRecords: boolean | null
    ): Promise<IMonthlyReportRecordForRemote[]> {
        const result = this.ajaxService.Post<IMonthlyReportRecordForRemote[]>(
            "WorkedHours-api/WorkedHoursMonthlyReport",
            "GetMonthlyReportForRemotePlugin",
            {
                background: true,
                methodData: {
                    dayOfMonth: dayOfMonth,
                    includeEmptyRecords: includeEmptyRecords,
                },
            }
        );

        return result;
    }

    getWorkPlaces(): IWorkPlace[] {
        return [
            { Id: 0, Description: ProlifeSdk.TextResources.WorkedHours.OnSite },
            { Id: 1, Description: ProlifeSdk.TextResources.WorkedHours.OffSite },
            { Id: 2, Description: ProlifeSdk.TextResources.WorkedHours.Away },
        ];
    }

    GetSuggestedWorkableElements(request: IGetSuggestedWorkableElementsRequest): Promise<ISuggestedWorkableElement[]> {
        let result = this.ajaxService.Post<ISuggestedWorkableElement[]>(
            "WorkedHours-api/WorkableElements",
            "GetSuggestedWorkableElements",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    InsertOrUpdateHoursApprovalRows(hoursIds: number[] | null): Promise<void> {
        let result = this.ajaxService.Post<void>(
            "WorkedHours-api/WorkedHoursApproval",
            "InsertOrUpdateHoursApprovalRows",
            {
                background: true,
                methodData: {
                    hoursIds: hoursIds,
                },
            }
        );

        return result;
    }

    Search(request: ISearchRequest): Promise<IExtendedWorkSheetRow[]> {
        let result = this.ajaxService.Post<IExtendedWorkSheetRow[]>("WorkedHours-api/HourRecords", "Search", {
            background: true,
            methodData: request,
        });

        return result;
    }

    SearchCount(request: ISearchCountRequest): Promise<number> {
        let result = this.ajaxService.Post<number>("WorkedHours-api/HourRecords", "SearchCount", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetServiceOrderCostWithBudget(
        ResourceId: number | null,
        FkServiceOrder: number | null,
        ShowAll: boolean | null
    ): Promise<IServiceOrderCostWithBudget[]> {
        let result = this.ajaxService.Post<IServiceOrderCostWithBudget[]>(
            "WorkedHours-api/WorkTimeCategories",
            "GetServiceOrderCostWithBudget",
            {
                background: true,
                methodData: {
                    ResourceId: ResourceId,
                    FkServiceOrder: FkServiceOrder,
                    ShowAll: ShowAll,
                },
            }
        );

        return result;
    }

    async ShowResourceServiceOrdersSalariesBudgetsStatusDialog(
        resourceId: number,
        from: Date,
        to: Date
    ): Promise<void> {
        const dialog = new WorkTimeCategoriesRemainingBudgetDialog({ resourceId: resourceId, from: from, to: to });
        return this.dialogsService.ShowModal(dialog);
    }

    GetLockStatusForMonths(): Promise<ILockedMonth[]> {
        let result = this.ajaxService.Post<ILockedMonth[]>("WorkedHours-api/WorkSheet", "GetLockStatusForMonths", {
            background: true,
            methodData: {},
        });

        return result;
    }

    GetResourceServiceOrdersSalariesBudgets(
        request: IGetResourceServiceOrdersSalariesBudgetsRequest
    ): Promise<IServiceOrderCostWithBudget[]> {
        let result = this.ajaxService.Post<IServiceOrderCostWithBudget[]>(
            "WorkedHours-api/WorkTimeCategories",
            "GetResourceServiceOrdersSalariesBudgets",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    InsertOrUpdateHoursApprovalRowsForWorkedHours(hoursIds: number[] | null, userId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>(
            "WorkedHours-api/WorkedHoursApproval",
            "InsertOrUpdateHoursApprovalRowsForWorkedHours",
            {
                background: true,
                methodData: {
                    hoursIds: hoursIds,
                    userId: userId,
                },
            }
        );

        return result;
    }

    SetApprovalState(
        hoursApprovalState: IHoursApproval_Type[] | null,
        canSignAll: boolean | null,
        userId: number | null
    ): Promise<void> {
        let result = this.ajaxService.Post<void>("WorkedHours-api/WorkedHoursApproval", "SetApprovalState", {
            background: true,
            methodData: {
                hoursApprovalState: hoursApprovalState,
                canSignAll: canSignAll,
                userId: userId,
            },
        });

        return result;
    }

    GetWorkedHoursWithApprovalStateForResource(
        ResourceId: number | null,
        Date: Date | null,
        JobOrderId: number | null,
        IgnoreResourceFilter: boolean | null
    ): Promise<IWorkedHoursWithApprovalStateForList> {
        let result = this.ajaxService.Post<IWorkedHoursWithApprovalStateForList>(
            "WorkedHours-api/WorkSheet",
            "GetWorkedHoursWithApprovalStateForResource",
            {
                background: true,
                methodData: {
                    ResourceId: ResourceId,
                    Date: Date,
                    JobOrderId: JobOrderId,
                    IgnoreResourceFilter: IgnoreResourceFilter,
                },
            }
        );

        return result;
    }

    GetWorkedHoursWithApprovalStateForEditing(
        HoursId: number | null
    ): Promise<IWorkedHoursWithApprovalStateForEditing> {
        let result = this.ajaxService.Post<IWorkedHoursWithApprovalStateForEditing>(
            "WorkedHours-api/WorkSheet",
            "GetWorkedHoursWithApprovalStateForEditing",
            {
                background: true,
                methodData: {
                    HoursId: HoursId,
                },
            }
        );

        return result;
    }

    GetHoursApprovalState(hoursId: number | null): Promise<IHoursApprovalRow[]> {
        let result = this.ajaxService.Post<IHoursApprovalRow[]>(
            "WorkedHours-api/WorkedHoursApproval",
            "GetHoursApprovalState",
            {
                background: true,
                methodData: {
                    hoursId: hoursId,
                },
            }
        );

        return result;
    }

    GetSuggestedFlexibilityHours(date: Date | null, serviceOrderId: number | null): Promise<number> {
        let result = this.ajaxService.Post<number>("WorkedHours-api/WorkSheet", "GetSuggestedFlexibilityHours", {
            background: true,
            methodData: {
                date: date,
                serviceOrderId: serviceOrderId,
            },
        });

        return result;
    }

    ThereIsAtLeastOneHourAsFlexibilityInserted(): Promise<boolean> {
        let result = this.ajaxService.Post<boolean>(
            "WorkedHours-api/WorkTimeCategories",
            "ThereIsAtLeastOneHourAsFlexibilityInserted",
            {
                background: true,
                methodData: {},
            }
        );

        return result;
    }

    CheckServiceOrderFlexibilityBalance(
        ResourceId: number | null,
        FkServiceOrder: number | null
    ): Promise<ICheckServiceOrderFlexibilityBalance> {
        let result = this.ajaxService.Post<ICheckServiceOrderFlexibilityBalance>(
            "WorkedHours-api/WorkTimeCategories",
            "CheckServiceOrderFlexibilityBalance",
            {
                background: true,
                methodData: {
                    ResourceId: ResourceId,
                    FkServiceOrder: FkServiceOrder,
                },
            }
        );

        return result;
    }

    CheckServiceOrderHoursBalanceIsInWarningOrError(
        ResourceId: number | null,
        FkServiceOrder: number | null,
        FKWorktimeCategory: number | null
    ): Promise<ICheckServiceOrderHoursBalanceIsInWarningOrError> {
        let result = this.ajaxService.Post<ICheckServiceOrderHoursBalanceIsInWarningOrError>(
            "WorkedHours-api/WorkTimeCategories",
            "CheckServiceOrderHoursBalanceIsInWarningOrError",
            {
                background: true,
                methodData: {
                    ResourceId: ResourceId,
                    FkServiceOrder: FkServiceOrder,
                    FKWorktimeCategory: FKWorktimeCategory,
                },
            }
        );

        return result;
    }

    RemoveHoursApprovalRowsForGroup(groupId: number | null, userId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>(
            "WorkedHours-api/WorkedHoursApproval",
            "RemoveHoursApprovalRowsForGroup",
            {
                background: true,
                methodData: {
                    groupId: groupId,
                    userId: userId,
                },
            }
        );

        return result;
    }

    InsertOrUpdateHoursApprovalRowsForResources(resources: number[] | null, userId: number | null): Promise<void> {
        let result = this.ajaxService.Post<void>(
            "WorkedHours-api/WorkedHoursApproval",
            "InsertOrUpdateHoursApprovalRowsForResources",
            {
                background: true,
                methodData: {
                    resources: resources,
                    userId: userId,
                },
            }
        );

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
    //return new WorkedHoursService(serviceLocator);
}
