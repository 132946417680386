import * as ko from "knockout";
﻿import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";

export class CallRightTypesEditingViewModel
{
    title : string;
    elements : ko.ObservableArray<CallRightTypeViewModel> = ko.observableArray();

    @LazyImport(nameof<IDialogsService>())
    private dialogService : IDialogsService;

    public HumanResourcesCallRightType : ko.Computed<CallRightTypeViewModel[]>;
    public MaterialResourcesCallRightType : ko.Computed<CallRightTypeViewModel[]>;

    constructor(private typesManager : ICallRightTypesSettingsManager)
    {
        this.title = typesManager.getLabel();
        typesManager.getAll(false)
            .forEach(this.createViewModelFor.bind(this));

        this.HumanResourcesCallRightType = ko.computed(() => {
            return this.elements().filter((c : CallRightTypeViewModel) => { return c.ResourceType == 0; });
        });

        this.MaterialResourcesCallRightType = ko.computed(() => {
            return this.elements().filter((c : CallRightTypeViewModel) => { return c.ResourceType != 0; });
        });
    }

    private createViewModelFor(type : ICallRightType)
    {
        var typeVm = new CallRightTypeViewModel(this, type);
        this.elements.push(typeVm);
    }

    public createOrUpdateEntry(element : CallRightTypeViewModel)
    {
        var type : ICallRightType = element.getData();
        this.typesManager.createOrUpdate(type)
            .then((updatedtype) => { element.update(updatedtype); });
    }

    public AddNewHumanResourcesType()
    {
        this.AddNewType({
            Id : -1,
            Name: ProlifeSdk.TextResources.WorkedHours.NewCallRightType,
            Price : 0,
            Cost : 0,
            Deleted : false,
            ResourceType : 0,
            Code : ""
        });
    }

    public AddNewMaterialResourcesType()
    {
        this.AddNewType({
            Id : -1,
            Name: ProlifeSdk.TextResources.WorkedHours.NewCallRightType,
            Price : 0,
            Cost : 0,
            Deleted : false,
            ResourceType : 1,
            Code : ""
        });
    }

    private AddNewType(type : ICallRightType)
    {
        var typeVm : CallRightTypeViewModel = new CallRightTypeViewModel(this, type);
        this.elements.push(typeVm);
        this.createOrUpdateEntry(typeVm);
    }

    public deleteEntry(element : CallRightTypeViewModel)
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.WorkedHours.SureToDeleteCallRightType, ProlifeSdk.TextResources.WorkedHours.DoNotDelete, ProlifeSdk.TextResources.WorkedHours.Delete, (dialogResult) => {
            if(dialogResult)
            {
                this.elements.remove(element);
                var type : ICallRightType = element.getData();
                this.typesManager.delete(type);
            }
        })
    }
}

export class CallRightTypeViewModel
{
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    public Id : number;
    public Name : ko.Observable<string> = ko.observable("");
    public Code : ko.Observable<string> = ko.observable("");
    public Price : ko.Observable<number> = ko.observable(0);
    public Cost : ko.Observable<number> = ko.observable(0);

    private updating : boolean = false;

    public ResourceType : number;

    constructor(private container : CallRightTypesEditingViewModel, private type : ICallRightType)
    {
        this.Id = type.Id;
        this.update(type);

        this.Name.subscribe(this.onDataChanged.bind(this));
        this.Price.subscribe(this.onDataChanged.bind(this));
        this.Cost.subscribe(this.onDataChanged.bind(this));
    }

    public Delete()
    {
        this.container.deleteEntry(this);
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating)
            return;

        this.container.createOrUpdateEntry(this);
    }

    getData() : ICallRightType
    {
        var type : ICallRightType = <ICallRightType> $.extend({}, this.type);
        type.Name = this.Name();
        type.Price = this.Price();
        type.Cost = this.Cost();
        return type;
    }

    update(type : ICallRightType) : void
    {
        var activationCode : string = this.documentsService.getRowReferencesGeneratorFactoryForEntity(ProlifeSdk.EstimatedCallRightTypeCode).ActivationCode;
        var code : string = type.Id ? activationCode + this.sdkService.Utilities.Numbers.ZeroPad(type.Id.toString(), 5) : "";

        this.updating = true;
        this.type = type;
        this.Name(type.Name);
        this.Code(code);
        this.Price(type.Price);
        this.Cost(type.Cost);
        this.ResourceType = type.ResourceType;
        this.updating = false;
    }
}