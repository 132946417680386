import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as Core from "../../Core/Core";
import { OperationsProgressViewModel } from "../DesktopService";
import jss from "jss";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";

const { classes } = jss.createStyleSheet({
    operationsProgress: {
        display: 'inherit',
        listStyle: 'none',
        paddingLeft: 0,

        '& > li > a': {
            display: 'block',
            clear: 'both',
            fontWeight: 300,
            lineHeight: '20px',
            whiteSpace: 'normal',
            fontSize: '13px',
            padding: '10px',
            backgroundColor: '#ffffff',
            borderBottom: '1px solid #f4f4f4 !important',
            textShadow: 'none',
            color: '#666',
            cursor: 'default',

            '&:hover': {
                textDecoration: 'none'
            },

            '& > .task': {
                marginBottom: '5px',

                '& > .desc': {
                    fontSize: '13px',
                    fontWeight: '300',
                },

                '& > .percent': {
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: "'Open Sans', sans-serif",
                    float: 'right',
                    display: 'inline-block'
                }
            },

            '& > .progress': {
                display: 'block',
                height: '11px',
                margin: '0px',
            }
        }
    }
}).attach();

ko.components.register("operations-progress", {
    viewModel: {
        createViewModel: (params: any, componentInfo: ko.components.ComponentInfo) => {
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <div data-bind="slimScroll: '330px'">
                        <div style="background-color: #EEE; padding: 5px;" data-bind="visible: UploadDownloadOperationsInProgress() == 0">
                            Nessuna operazione di trasferimento in corso
                        </div>
                        <ul class={classes.operationsProgress + " dropdown-menu-list"} data-bind="foreach: UploadDownloadOperationsInProgress">
                            <li>
                                <a href="javascript:;">
                                    <span class="task">
                                        <span class="desc" data-bind="text: Description"></span>
                                        <span class="percent" data-bind="percentageText: Progress"></span>
                                    </span>
                                    <span class="progress">
                                        <span data-bind="style : { 'width': Progress() + '%', 'aria-valuenow': Progress }" class="progress-bar progress-bar-success" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}>
                                            <span class="sr-only">
                                                <ko-text data-bind="Progress"></ko-text>% completato
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            ]);
            
            let desktopService : IDesktopService = Core.serviceLocator.findService(nameof<IDesktopService>());
            let vm : OperationsProgressViewModel = desktopService.GetOperationsProgressVM();

            //Aggiungere per Debug
            /*for(var i = 0; i < 10; i++) {
                let def = new Deferred();
                def.notify({ percentComplete : 0.45 });
                vm.StartDownload("pippo" + i + ".txt", def.promise());
            }*/
            return vm;
        },
    },
    template: []
});