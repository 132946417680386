import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import "../Components/WorkflowAllocationTeamsComponent/WorkflowAllocationTeamsComponent";
import {
    INavigationMenuComponentModel,
    INavigationMenuComponentDataSource,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import {
    ITaskForTaskBoard,
    ITodoListService,
    IGetJobOrderWorkflowsForUserRequest,
    IGetJobOrderWorkflowsForUserByIdsRequest,
    IWorkflowForTaskBoard,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IWorkflowStatesSettingsManager,
    IWorkflowState,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import {
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

export interface IWorkflowsDataSourceModel extends INavigationMenuComponentModel {
    id: number;
    isWorkflowGroup: boolean;
    isWorkflow: boolean;

    model: ITaskForTaskBoard | IWorkflowForTaskBoard | null;
}

export type IWorkflowsDataSource = INavigationMenuComponentDataSource;

interface IWorkflowsDataSourceConfig {
    jobOrderIds: number[];
    workflowsCache: IWorkflowForTaskBoard[];
    workflowsCacheClearRequested: boolean;
    lastTextFilter: string;
}

export class WorkflowsDataSource implements IWorkflowsDataSource {
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService!: ITodoListService;

    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStateSettingsManager!: IWorkflowStatesSettingsManager;

    @LazyImport(ProlifeSdk.ChangesNotificationsServiceType)
    private changesNotificationsService!: IChangesNotificationsService;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    private jobOrderIds: number[] = [];
    private workflowsCache: IWorkflowForTaskBoard[] = [];
    private workflowsCacheClearRequested = false;
    private lastTextFilter: string = null;
    view: IDataSourceView;

    //State stack
    private pushStateRequired = false;
    private stateStack: IWorkflowsDataSourceConfig[] = [];

    //Selection
    private selectionRequested = false;
    private requestedWorkflowId: number[] = [];

    //Search Options
    private recursiveSearch = false;

    //Advanced filters
    private filterWithContent = false;
    private contentTextFilter: string = null;
    private viewWorker = true;
    private viewResponsible = true;
    private viewAll = true;
    private showNoWork = false;
    private startDate: Date = null;
    private endDate: Date = null;
    private destinationStatus: number = null;
    private userId: number = null;
    private categoryFilter: number = null;
    private showJobOrderName = false;

    private notificationsEnabled = false;
    private groupedModeEnabled = true;
    private showClosesJobOrdersOnWorkflowEditor = false;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor() {}

    setUserId(userId: number) {
        this.userId = userId;
        this.workflowsCacheClearRequested = true;
    }

    setCategoryFilter(categoryId: number): void {
        this.categoryFilter = categoryId;
        this.workflowsCacheClearRequested = true;
    }

    setDateFilters(startDate: Date, endDate: Date, destinationStatus: number) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.destinationStatus = destinationStatus;
        this.workflowsCacheClearRequested = true;
    }

    setWorkFilters(showNoWork: boolean) {
        this.showNoWork = showNoWork;
        this.workflowsCacheClearRequested = true;
    }

    setViewFilters(viewWorker: boolean, viewResponsible: boolean, viewAll: boolean) {
        this.viewWorker = viewWorker;
        this.viewResponsible = viewResponsible;
        this.viewAll = viewAll;
        this.workflowsCacheClearRequested = true;
    }

    setFilterContents(filterWithContent: boolean, contentTextFilter: string) {
        this.filterWithContent = filterWithContent;
        this.contentTextFilter = contentTextFilter;
        this.workflowsCacheClearRequested = true;
    }

    setShowJobOrderName(value: boolean): void {
        this.showJobOrderName = value;
    }

    setGroupedModeEnabled(value: boolean): void {
        this.groupedModeEnabled = value;
    }

    setShowClosesJobOrdersOnWorkflowEditor(value: boolean): void {
        this.showClosesJobOrdersOnWorkflowEditor = value;
    }

    refresh() {
        if (!this.view) return;

        this.workflowsCache = [];
        this.workflowsCacheClearRequested = true;

        this.stateStack.forEach((s) => (s.workflowsCacheClearRequested = true));

        this.view.refresh(true);
    }

    enableNotifications(enabled: boolean) {
        if (this.notificationsEnabled == enabled) return;

        if (enabled) {
            this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
        } else this.changesNotificationsService.RemoveObserver(this);

        this.notificationsEnabled = enabled;
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sentByMe: boolean) {
        if (changesInfo.EntityCode == ProlifeSdk.WorkflowEntityTypeCode) {
            const jobOrderId = changesInfo.Changes.OldStatus
                ? changesInfo.Changes.OldStatus.JobOrderId
                : changesInfo.Changes.NewStatus.JobOrderId;
            if (this.jobOrderIds.indexOf(jobOrderId) != -1) this.refresh();
        }
        return false;
    }

    pushState() {
        if (!this.view) {
            this.pushStateRequired = true;
            return;
        }

        this.view.pushState();

        this.stateStack.push({
            jobOrderIds: this.jobOrderIds.slice(),
            workflowsCache: this.workflowsCache ? this.workflowsCache.slice() : [],
            workflowsCacheClearRequested: this.workflowsCacheClearRequested,
            lastTextFilter: this.lastTextFilter,
        });
    }

    popState() {
        if (this.view) this.view.popState();

        if (this.stateStack.length > 0) {
            const oldState = this.stateStack.pop();
            this.jobOrderIds = oldState.jobOrderIds;
            this.workflowsCache = oldState.workflowsCache;
            this.workflowsCacheClearRequested = true; //oldState.workflowsCacheClearRequested;
            this.lastTextFilter = oldState.lastTextFilter;
        }
    }

    selectWorkflows(workflowIds: number[]) {
        if (!this.view) {
            this.selectionRequested = true;
            this.requestedWorkflowId = workflowIds.slice();
            return;
        }

        this.view.select(
            ...workflowIds.map((id) => {
                return {
                    id: id,
                    isGroup: false,
                    isLeaf: true,
                    title: "",
                    isWorkflowGroup: false,
                    isWorkflow: true,
                };
            })
        );
    }

    setJobOrders(...jobOrderIds: number[]) {
        this.jobOrderIds = jobOrderIds.slice();
        this.workflowsCache = [];
        this.workflowsCacheClearRequested = true;

        if (this.view) this.view.refresh();
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if (this.pushStateRequired) {
            this.view.pushState();
            this.pushStateRequired = false;
        }

        if (this.selectionRequested) {
            this.selectWorkflows(this.requestedWorkflowId);
            this.selectionRequested = false;
        }

        this.refresh();
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        if (this.view) return this.view.select(...models);
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    setRecursiveSearch(enabled: boolean) {
        this.recursiveSearch = enabled;
    }

    getTitle(currentModel: IDataSourceModel): string {
        if (currentModel == null) return ProlifeSdk.TextResources.Todolist.Workflows;
        return currentModel.title;
    }

    isGroupedData(currentModel: IWorkflowsDataSourceModel, textFilter: string): boolean {
        if (!currentModel) return this.groupedModeEnabled;
        return false;
    }

    areEqual(a: IWorkflowsDataSourceModel, b: IWorkflowsDataSourceModel): boolean {
        return (
            a === b ||
            (!!a && !!b && a.id === b.id && a.isWorkflowGroup === b.isWorkflowGroup && a.isWorkflow === b.isWorkflow)
        );
    }

    async getData(
        currentModel: IWorkflowsDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWorkflowsDataSourceModel[]> {
        if (this.recursiveSearch && textFilter) {
            return this.handleRecursiveSearch(currentModel, textFilter, skip, count);
        }

        console.log(
            "currentModel: ",
            currentModel,
            "textFilter: ",
            textFilter,
            "skip: ",
            skip,
            "count: ",
            count,
            "jobOrders:",
            this.jobOrderIds
        );
        if (skip != 0) return [];

        if (this.lastTextFilter != textFilter || (currentModel && currentModel.isWorkflow)) {
            console.log("Clearing cache because filter changed or a workflow was selected");
            this.workflowsCache = [];
            this.workflowsCacheClearRequested = true;
            this.lastTextFilter = textFilter;
        }

        if (currentModel === null) {
            const states = this.workflowStateSettingsManager.getStates(false);

            while (this.workflowsCacheClearRequested) {
                console.log("Querying server for workflows...");
                this.workflowsCacheClearRequested = false;

                const params: IGetJobOrderWorkflowsForUserRequest = {
                    userId: this.userId,
                    jobOrderIds: this.jobOrderIds,
                    viewWorker: this.viewWorker,
                    viewResponsible: this.viewResponsible,
                    categoryIds: this.categoryFilter > 0 ? [this.categoryFilter] : [],
                    viewAll: this.viewAll,
                    hideNoWork: !this.showNoWork,
                    destinationTaskBoardStatus: this.destinationStatus,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    textFilter: textFilter,
                    contentTextFilter: this.contentTextFilter,
                    filterWithContent: this.filterWithContent,
                };

                const workflows = await this.todoListService.GetJobOrderWorkflowsForUser(params);
                this.workflowsCache = workflows;

                if (this.workflowsCacheClearRequested) {
                    console.log("Workflow Cache Clear requested while query was in progress, discarding cache");
                    this.workflowsCache = [];
                }

                console.log("Query finished: ", this.workflowsCache);
            }

            const usedStates = states.map((s) => {
                return {
                    state: s,
                    count: this.workflowsCache.filter((w) => w.StateId == s.Id).length,
                };
            });

            if (this.groupedModeEnabled) {
                console.log("Counting workflows per state: ", usedStates);
                return this.createModelsForStates(usedStates);
            } else {
                console.log("Retrieving workflows from cache.");
                return this.createModelsForWorkflows(this.workflowsCache);
            }
        } else if (currentModel.isWorkflowGroup) {
            console.log("Retrieving workflows in state ", currentModel.id, " from cache");
            const workflowsInGroup = this.workflowsCache.filter((w) => w.StateId == currentModel.id);
            console.log("Workflows: ", workflowsInGroup);

            return this.createModelsForWorkflows(workflowsInGroup);
        }

        return null;
    }

    async handleRecursiveSearch(
        currentModel: IWorkflowsDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWorkflowsDataSourceModel[]> {
        if (skip != 0) return [];

        const params: IGetJobOrderWorkflowsForUserRequest = {
            userId: this.userId,
            jobOrderIds: this.jobOrderIds,
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            categoryIds: this.categoryFilter > 0 ? [this.categoryFilter] : [],
            viewAll: this.viewAll,
            hideNoWork: !this.showNoWork,
            destinationTaskBoardStatus: this.destinationStatus,
            startDate: this.startDate,
            endDate: this.endDate,
            textFilter: textFilter,
            contentTextFilter: this.contentTextFilter,
            filterWithContent: this.filterWithContent,
        };

        const workflows = await this.todoListService.GetJobOrderWorkflowsForUser(params);

        return workflows.map((w) => {
            return {
                id: w.Id,
                title: w.Title,
                isGroup: true,
                isLeaf: false,
                isWorkflowGroup: true,
                isWorkflow: false,
                collapsed: false,
                model: null,
            };
        });
    }

    createModelsForWorkflows(workflowsInGroup: IWorkflowForTaskBoard[]): IWorkflowsDataSourceModel[] {
        return workflowsInGroup.map((w) => {
            const item: IWorkflowsDataSourceModel = {
                id: w.Id,
                isGroup: false,
                isLeaf: true,
                title: w.Title,
                isWorkflowGroup: false,
                isWorkflow: true,
                icon: {
                    icon: w.Icon,
                    background: w.Background,
                    foreground: w.Foreground,
                },
                progressBar: {
                    progress: ((w.TasksCount - w.NotCompleteTasksCount) / w.TasksCount) * 100,
                },
                subTitle:
                    (this.showJobOrderName ? w.JobOrderName + "\r\n" : "") +
                    ProlifeSdk.TextResources.Todolist.ClosedTasks +
                    ": " +
                    (w.TasksCount - w.NotCompleteTasksCount) +
                    "/" +
                    w.TasksCount,
                model: w,
                secondaryIcon: {
                    icon: w.OutcomeIcon,
                    background: w.OutcomeBackground,
                    foreground: w.OutcomeForeground,
                },
                alerts: {
                    label: ProlifeSdk.TextResources.Todolist.Alerts,
                    icons: WorkflowsUtils.getAlertsForWorkflow(w),
                },
            };

            item.badge = [];

            if ((w.AllocationPercentage || 0) > 0 || w.HasNotEstimatedElementsAllocated) {
                item.badge.push(WorkflowsUtils.getAllocationBadge(w, this.dialogsService));
            }

            if (w.RelatedDocuments > 0 || w.WorkflowMustBeRelatedToCustomerOrders) {
                item.badge.push(WorkflowsUtils.getRelatedDocumentsBadge(w));
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
                item.secondaryAction = {
                    icon: {
                        icon: "fa fa-pencil",
                        background: "#ecbc29",
                        foreground: "white",
                    },
                    action: () => {
                        this.todoListService
                            .ShowEditWorkflowDialog(w.Id, this.showClosesJobOrdersOnWorkflowEditor)
                            .then((result) => {
                                if (!result) return;
                                this.refresh();
                            });
                    },
                };
            }

            return item;
        });
    }

    createModelsForStates(statesWithCount: { state: IWorkflowState; count: number }[]): IWorkflowsDataSourceModel[] {
        const workStatusUsed: boolean =
            statesWithCount.filter((s) => s.state.LogicalStatus === 1 && s.count > 0).length > 0;

        return statesWithCount
            .filter((s) => s.count > 0)
            .map((s) => {
                return {
                    id: s.state.Id,
                    isGroup: true,
                    isLeaf: false,
                    title: s.state.Description + " (" + s.count + ")",
                    collapsed: workStatusUsed ? s.state.LogicalStatus != 1 : s.state.LogicalStatus != 0,
                    isWorkflowGroup: true,
                    isWorkflow: false,
                    model: null,
                };
            });
    }

    getTaskStatusName(status: number) {
        switch (status) {
            case -1:
                return ProlifeSdk.TextResources.Todolist.Backlog;
            case 0:
                return ProlifeSdk.TextResources.Todolist.ToDo;
            case 1:
                return ProlifeSdk.TextResources.Todolist.InProgress;
            case 2:
                return ProlifeSdk.TextResources.Todolist.Completed;
            case 3:
                return ProlifeSdk.TextResources.Todolist.VerifiedSingular;
            case 4:
                return ProlifeSdk.TextResources.Todolist.SuspendedSingular;
            case 5:
                return ProlifeSdk.TextResources.Todolist.DeletedSingular;
            default:
                return null;
        }
    }

    async getById(currentModel: IWorkflowsDataSourceModel, ids: number[]): Promise<IWorkflowsDataSourceModel[]> {
        const request: IGetJobOrderWorkflowsForUserByIdsRequest = {
            userId: this.userId,
            hideNoWork: !this.showNoWork,
            viewAll: this.viewAll,
            viewResponsible: this.viewResponsible,
            viewWorker: this.viewWorker,
            ids: ids,
        };

        const workflows = await this.todoListService.GetJobOrderWorkflowsForUserByIds(request);
        return this.createModelsForWorkflows(workflows);
    }

    public getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-task", "application/prolife-tasks"];
    }

    public onItemBeginMove(model: IDataSourceModel, dataTransfer: DataTransfer) {}

    async onItemMoved(dataTransfer: DataTransfer, model: IWorkflowsDataSourceModel, before: boolean): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0 && model) {
            const tasks: IDraggedTask[] = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
            this.moveTasks(tasks, model);
        } else if (dataTransfer.types.indexOf("application/prolife-task") >= 0 && model) {
            const task: IDraggedTask = JSON.parse(dataTransfer.getData("application/prolife-task"));
            this.moveTasks([task], model);
        }
    }

    private moveTasks(tasks: IDraggedTask[], destinationWorkflow: IWorkflowsDataSourceModel) {
        const task = tasks[0];

        if (task.CompanyGuid != this.userInfo.getCurrentCompanyGuid()) return;

        if (this.jobOrderIds.length == 0) return;

        if (task.WorkflowId == destinationWorkflow.id) return;

        if (task.JobOrderId != destinationWorkflow.model.JobOrderId) return;

        this.todoListService.MoveTasksToWorkflow(
            tasks.map((t) => t.TaskId),
            destinationWorkflow.id
        );
    }
}
