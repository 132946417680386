import * as ko from "knockout";

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { CustomerEditorViewModel } from "./ui/CustomerEditorViewModel";
import { CustomerViewModel } from "./ui/CustomerViewModel";
import { LazyImport } from "../../Core/DependencyInjection";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService, IDialog } from "../../Core/interfaces/IDialogsService";
import { ICustomersService } from "../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomersUI } from "../../ProlifeSdk/interfaces/customer/ICustomersUI";
import { IView } from "../../ProlifeSdk/interfaces/IView";
import { ICustomer } from "../../ProlifeSdk/interfaces/customer/ICustomer";
import { Deferred } from "../../Core/Deferred";

export class CustomersUI implements ICustomersUI {
	@LazyImport(nameof<ICustomersService>())
	private customersService :ICustomersService;
	@LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

	constructor() {}

	getCustomerPreview(customerId : number) : Promise<IView>
	{
		const def = new Deferred<IView>();

		this.customersService.getCustomer(customerId)
			.then((customer : ICustomer) => {
				const view : IView = {
					templateName: "customer_preview",
					templateUrl: "customers/templates",
					viewModel: new CustomerViewModel(customer)
				};
				def.resolve(view);
			}).catch(() => {
				console.log('i should be never called');
				def.reject();
			});

		return def.promise();
	}


	getCustomerMetadata(customerId : number) : Promise<IView>
	{
		const def = new Deferred<IView>();

		this.customersService.getCustomer(customerId)
			.then((customer : ICustomer) => {
				const view : IView = {
					templateName: "customer_metadata",
					templateUrl: "customers/templates",
					viewModel: new CustomerViewModel(customer)
				};
				def.resolve(view);
			})
			.catch(() => {
				def.reject();
				console.log('i should be never called');
			});

		return def.promise();
	}

	async showNewCustomerDialogWithData(newCustomer: ICustomer, phoneNumber?: string, fax?: string, mail?: string, pec?: string, forceEdit = false): Promise<ICustomer> {
		const dialog = new CustomerEditorViewModel(newCustomer, forceEdit);
		this.setupCustomerEditor(dialog, phoneNumber, newCustomer.IsCliente, newCustomer.IsFornitore);
		if (fax)
			dialog.AddPhoneNumber(TextResources.Customers.Fax, fax);

		if (mail)
			dialog.AddMail(TextResources.Customers.Email, mail);

		if (pec)
			dialog.AddMail(TextResources.Customers.Pec, pec);

		return this.dialogsService.ShowModal<ICustomer>(dialog, "fullscreen", {}, "customers/templates/customer", "create-new-dialog");
	}

    showNewCustomerDialog(phoneNumber?: string, isCustomer?: boolean, isSupplier?: boolean, compactView = false) : Promise<void> {
        const dialog = new CustomerEditorViewModel();
		this.setupCustomerEditor(dialog, phoneNumber, isCustomer, isSupplier, compactView);
		return this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, "customers/templates/customer", "create-new-dialog");
    }

    showEditCustomerDialog(customerId: number, compactView = false) : Promise<void> {
        const deferred = new Deferred<void>();

		this.customersService.getCustomerById(customerId)
			.then((customer: ICustomer) => {
				const dialog = new CustomerEditorViewModel(customer);
				dialog.SetCompactView(compactView);
				this.dialogsService.ShowModal<ICustomer>(dialog, "fullscreen", {}, "customers/templates/customer", "create-new-dialog")
					.then(() => deferred.resolve())
					.catch(() => deferred.reject());
			})
			.catch(() => deferred.reject());

        return deferred.promise();
    }

	private setupCustomerEditor(editor: CustomerEditorViewModel, phoneNumber?: string, isCustomer?: boolean, isSupplier?: boolean, compactView?: boolean): void {
		if (phoneNumber?.length > 0) {
			editor.disableAutoScrollOnMetadatas();
			editor.AddPhoneNumber(ProlifeSdk.TextResources.Customers.Phone, phoneNumber);
			editor.enableAutoScrollOnMetadatas();	
		}
		editor.SetSupplierField(isSupplier);
		editor.SetCustomerField(isCustomer);
		editor.SetCompactView(compactView);
	}
}