import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { ITodoListTemplateForList } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

export class TemplateProvider extends WorkflowsNavigatorProvider implements IChangesNotificationsServiceObserver {
    private changesNotificationsService: IChangesNotificationsService;
    public NumberOfActivities: ko.Observable<number> = ko.observable(0);
    public IsDefault: ko.Observable<boolean> = ko.observable(false);
    public Title: ko.Observable<string> = ko.observable("");

    constructor(serviceLocator: IServiceLocator, public template: ITodoListTemplateForList) {
        super(serviceLocator);
        this.changesNotificationsService = <IChangesNotificationsService>serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);

        this.Name = template.Title;
        this.templateName = "template-item";
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.IsLeaf = true;

        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TemplateTaskEntityTypeCode, this);
        this.RefreshData(template);
    }

    search(textFilter: string): Promise<void> {
        return Promise.resolve<void>(undefined);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        //Scarto le notifiche di modifica a task di altri template
        var oldTemplateId: number = changesInfo.Changes.OldStatus ? changesInfo.Changes.OldStatus.TemplateId : null;
        var newTemplateId: number = changesInfo.Changes.NewStatus ? changesInfo.Changes.NewStatus.TemplateId : null;

        if (oldTemplateId != this.template.Id && newTemplateId != this.template.Id) return false;

        this.todoListService.GetTemplateForList(this.template.Id).then((w: ITodoListTemplateForList) => {
            this.RefreshData(w);
        });

        return false;
    }

    public RefreshData(t: ITodoListTemplateForList): Promise<void> {
        this.NumberOfActivities(t.NumberOfTasks);
        this.IsDefault(t.IsDefault);
        this.Title(t.Title);
        return Promise.resolve<void>(undefined);
    }

    public Dispose() {
        this.changesNotificationsService.RemoveObserver(this);
    }
}
