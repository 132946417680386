/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/09/2017
 * Time: 11:32
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { CartsStatesSettingManager } from "./Allocations/ui/settings/CartsStatesSettingManager";
import { CartEditorResult, EditCartDialog } from "./Allocations/ui/dialogs/EditCartDialog";
import { CartWorkEndDateEditorDialog } from "./Allocations/ui/dialogs/CartWorkEndDateEditorDialog";
import { IJobOrdersMenuAdvancedFilters } from "../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import { IAllocatedCart } from "../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ICartsService, ICartAndActivityEndDates, ICartLink, ICartLinkWithInfo } from "../ProlifeSdk/interfaces/allocations/ICartsService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ICartWithContent, ICartContentWithRolesInfo, ICart, ICartResource, ICartContentDetails, ICartRoleInfo } from "../ProlifeSdk/interfaces/allocations/ICart";
import { LazyImport } from "../Core/DependencyInjection";
import { CartCircularDependenciesAlert, CartDependencies } from "./Allocations/ui/dialogs/CartCircularDependenciesAlert";
import { IDialogsService } from "../Core/interfaces/IDialogsService";

export class CartsService extends ProLifeService implements ICartsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.CartsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ICartsService>(), this)
    }
    
    InitializeService() {
        super.InitializeService();
        
        new CartsStatesSettingManager(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.CartsServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getCartsList(showClosedCarts: boolean, showAllocatedCarts: boolean, useMenuAdvancedFilters: boolean, advancedFilters: IJobOrdersMenuAdvancedFilters, textFilter: string, filterByIds?: number[]): Promise<ICartWithContent[]> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartsList", {
            background: true,
            methodData: {
                ShowClosedCarts: showClosedCarts,
                ShowAllocatedCarts: showAllocatedCarts,
                UseMenuAdvancedFilters: useMenuAdvancedFilters,
                AdvancedFilters: advancedFilters,
                TextFilter: textFilter,
                FilterByIds: filterByIds
            }
        });
    }

    addElementToCart(cartId: number, elementId: number, isTask: boolean, percentage: number): Promise<ICartContentWithRolesInfo> {
        return this.ajaxService.Post<ICartContentWithRolesInfo>("Allocations-api/Carts", "AddElementToCart", {
            methodData : {
                CartId: cartId,
                ElementId: elementId,
                IsTask: isTask,
                Percentage: percentage
            }
        });
    }

    addCart(cart: ICart, cartResources: ICartResource[], links: ICartLink[]): Promise<ICart> {
        return this.ajaxService.Post("Allocations-api/Carts", "AddCart", {
            methodData: {
                Cart: cart,
                Resources: cartResources,
                Links: links
            }
        });
    }

    updateCart(cart: ICart, cartResources: ICartResource[], links: ICartLink[]): Promise<ICart> {
        return this.ajaxService.Post("Allocations-api/Carts", "UpdateCart", {
            methodData: {
                Cart: cart,
                Resources: cartResources,
                Links: links
            }
        });
    }

    deleteCart(cartId: number): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "DeleteCart", {
            methodData: {
                CartId: cartId
            }
        });
    }

    saveCartContent(cartId: number, cartContent: ICartContentDetails[]): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "SaveCartContent", {
            methodData: {
                CartId: cartId,
                CartContent: cartContent
            }
        });
    }

    deleteElementFromCart(cartId: number, elementId: number, isTask: boolean): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "DeleteElementFormCart", {
            methodData: {
                CartId: cartId,
                ElementId: elementId,
                IsTask: isTask
            }
        });
    }

    getCartWithContent(cartId: number): Promise<ICartWithContent> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartWithContent", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    getCartLogicalState(cartId: number): Promise<number> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartLogicalState", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    cartIsEmpty(cartId: number): Promise<boolean> {
        return this.ajaxService.Post("Allocations-api/Carts", "CartIsEmpty", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    reorderCartContent(cartId: number, movedId: number, beforeId: number, afterId: number, newIndex: number): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "ReorderCartContent", {
            methodData: {
                CartId: cartId,
                MovedId: movedId,
                BeforeId: beforeId,
                AfterId: afterId,
                NewIndex: newIndex
            }
        });
    }

    getCartContentDetails(cartId: number, showClosedActivities: boolean, elementId?: number, isTask?: boolean): Promise<ICartContentWithRolesInfo[]> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartContentDetails", {
            background: true,
            methodData: {
                CartId: cartId,
                ElementId: elementId,
                IsTask: isTask,
                ShowClosedActivities: showClosedActivities
            }
        });
    }

    updateAllocatedCart(cart: IAllocatedCart): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "UpdateAllocatedCart", {
            methodData: {
                Cart: cart
            }
        });
    }

    getCartRolesInfo(cartId: number): Promise<ICartRoleInfo[]> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartRolesInfo", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    getCartResources(cartId: number): Promise<ICartResource[]> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartResources", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    getResourceForCart(): Promise<ICartResource> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetResourceForCart", {
            background: true
        });
    }

    addElementOnAllocatedCart(elementId: number, isTask: boolean, cartId: number, teamId: number): Promise<ICartContentWithRolesInfo> {
        return this.ajaxService.Post("Allocations-api/Carts", "AddElementOnAllocatedCart", {
            methodData: {
                ElementId: elementId,
                IsTask: isTask,
                CartId: cartId,
                TeamId: teamId
            }
        });
    }

    removeElementFromAllocatedCart(elementId: number, isTask: boolean, cartId: number, teamId: number): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "removeElementFromAllocatedCart", {
            methodData: {
                ElementId: elementId,
                IsTask: isTask,
                CartId: cartId,
                TeamId: teamId
            }
        });
    }

    getAutoCartName(elementId: number, isTask: boolean): Promise<string> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetAutoCartName", {
            background: true,
            methodData: {
                ElementId: elementId,
                IsTask: isTask
            }
        });
    }

    async openCartEditorDialog(cart?: ICart, elementsToAdd = [], forceSaving = false): Promise<ICart> {
        let resources: ICartResource[] = [];
        let links: ICartLink[] = [];

        if (!cart || !cart.Id || cart.Id < 0) {
            const defaultResource = await this.getResourceForCart();
            if (defaultResource)
                resources.push(defaultResource);
        } else {
            const [res, lks] = await this.getCartResourcesAndLinks(cart.Id);
            resources = res;
            links = lks;
        }

        let savedCart: ICart = null;

        const dialog = new EditCartDialog({ cart: cart, resources: resources, links: links, forceSaving: forceSaving});
        const editorResult: CartEditorResult = await dialog.show();

        savedCart = editorResult.cart;

        if (savedCart) {
            for (const element of elementsToAdd) {
                const [elementId, isTask] = element;
                const cartElement = await this.addElementToCart(savedCart.Id, elementId, isTask, 100);
                if (!cartElement) {
                    await this.dialogsService.AlertAsync(ProlifeSdk.TextResources.Allocations.CanNotAllocateElementAlreadyAllocated, ProlifeSdk.TextResources.Allocations.CanNotAllocateWorkflowPartiallyAllocatedLabel);
                }
            }
        }

        if (editorResult.action === "REOPEN") {
            const [res, lks] = await this.getCartResourcesAndLinks(savedCart.Id);
            const dialog = new EditCartDialog({ cart: editorResult.cart, resources: res, links: lks });
            const result: CartEditorResult = await dialog.show();

            savedCart = result.action === "CLOSE" ? editorResult.cart : result.cart;
        }
        
        return savedCart;
    }

    private async getCartResourcesAndLinks(cartId: number): Promise<[resources: ICartResource[], links: ICartLink[]]> {
        const promises: Promise<ICartResource[]|ICartLink[]>[] = [];
        promises.push(this.getCartResources(cartId));
        promises.push(this.GetCartLinks(cartId));

        const [cartResources, cartLinks] = await Promise.all(promises);

        const resources = (cartResources as ICartResource[]) ?? [];
        const links = (cartLinks as ICartLink[]) ?? [];

        return [resources, links];
    }

    getCartAndActivityEndDates(cartId: number, elementId: number, isTask: boolean): Promise<ICartAndActivityEndDates> {
        return this.ajaxService.Post("Allocations-api/Carts", "GetCartAndActivityEndDates", {
            methodData: {
                CartId: cartId,
                ElementId: elementId,
                IsTask: isTask
            }
        });
    }

    async manageCartWorkEndDate(cartId: number, elementId: number, isTask: boolean): Promise<Date> {
        const cartAndActivityEndDates: ICartAndActivityEndDates = await this.getCartAndActivityEndDates(cartId, elementId, isTask);
        if (!cartAndActivityEndDates || !cartAndActivityEndDates.ElementEndDate)
            return !cartAndActivityEndDates ? null : cartAndActivityEndDates.CartEndDate;

        const endDateEditorDialog = new CartWorkEndDateEditorDialog(cartAndActivityEndDates);
        return endDateEditorDialog.show();
    }

    updateCartWorkEndDate(cartId: number, workEndDate: Date): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Carts", "UpdateCartWorkEndDate", {
            methodData: {
                CartId: cartId,
                WorkEndDate: workEndDate
            }
        });
    }

    showCircularDependenciesAlert(dependencies: CartDependencies): Promise<void> {
        const alertDialog = new CartCircularDependenciesAlert({ dependencies: dependencies });
        return alertDialog.show();
    }

    GetCartsLinksWithInfo(cartId: number | null): Promise<ICartLinkWithInfo[]> {
        const result = this.ajaxService.Post<ICartLinkWithInfo[]>("Allocations-api/Carts", "GetCartsLinksWithInfo", {
            background: true,
            methodData: {
                cartId: cartId
            }
        });



        return result;
    }

    GetCartLinks(cartId: number | null): Promise<ICartLink[]> {
        const result = this.ajaxService.Post<ICartLink[]>("Allocations-api/Carts", "GetCartLinks", {
            background: true,
            methodData: {
                cartId: cartId
            }
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new CartsService(serviceLocator);
}