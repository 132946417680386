import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { WorkflowStatesDataSource } from "../../../../DataSources/WorkflowStatesDataSource";
import { TaskStatesDataSource } from "../../../../DataSources/TaskStatesDataSource";
import { IWorkflowStatesSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";

export class WorkflowStatesSettingsViewModel {
    title: string;

    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private statesManager: IWorkflowStatesSettingsManager;

    WorkflowStatesDataSource: WorkflowStatesDataSource = new WorkflowStatesDataSource();
    TaskStatesDataSource: TaskStatesDataSource = new TaskStatesDataSource();

    constructor() {
        this.title = this.statesManager.getLabel();
    }

    public createNew() {
        this.statesManager
            .createOrUpdate({
                Description: "",
                LogicalStatus: 0,
                Deleted: false,
                Position: -1,
                MoveClosedTasks: false,
                MoveClosedTasksToState: -1,
                MoveNonClosedTasks: false,
                MoveNonClosedTasksToState: -1,
            })
            .then(() => {
                this.WorkflowStatesDataSource.refresh();
            });
    }
}

ko.components.register("workflow-states-editor", {
    viewModel: {
        createViewModel: (params: any, componentInfo: ko.components.ComponentInfo) => {
            let vm = new WorkflowStatesSettingsViewModel();

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <h3 class="page-title">
                        <span data-bind="text: title"></span>
                        <small
                            class="alert alert-danger"
                            style="position: absolute; left: 60%; margin-left: -25%; text-align: center; top: 0px; width: 500px; color: #a94442;">
                            Per applicare i cambiamenti, dopo aver salvato, ricaricare la pagina.
                        </small>
                    </h3>

                    <button
                        class="btn btn-primary"
                        style="position: absolute; top : 25px; right : 20px"
                        data-bind="click : createNew">
                        <i class="fa fa-plus"></i>
                        &nbsp;Nuovo
                    </button>

                    <div style="position: absolute; top: 90px; right: 20px; bottom: 10px; left: 20px; overflow: hidden">
                        <list params="Sortable: true, AllowNoSelection: true, DataSource: WorkflowStatesDataSource, ContainerHeight: '100%'">
                            <div class="row" data-bind="with: Model" style="margin-right: 0; margin-left: 0">
                                <div class="col-md-11">
                                    <div class="col-md-9">
                                        <label>Nome</label>
                                        <input
                                            class="form-control"
                                            placeholder="Inserire una descrizione..."
                                            type="text"
                                            data-bind="value: Description"
                                        />
                                    </div>

                                    <div class="col-md-3">
                                        <label>Stato</label>
                                        <div>
                                            <select
                                                class="form-control"
                                                data-bind="value: StateId, options: States, optionsValue: 'Id', optionsText: 'Title'"></select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1">
                                    <label>&nbsp;</label>
                                    <div class="text-right">
                                        <i class="fa fa-circle-o-notch fa-spin" data-bind="visible: Saving"></i>
                                        <button
                                            class="btn btn-danger"
                                            style="margin-left: 10px"
                                            data-bind="click : markDeleted">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row" data-bind="with: Model" style="margin-right: 0; margin-left: 0">
                                <div class="col-md-11">
                                    <div class="col-md-3">
                                        <label>&nbsp;</label>
                                        <div style="display: flex; align-items: center; height: 34px;">
                                            <checkbox class="col-md-12" checked={() => "MoveNonClosedTasks"}>
                                                Cambia stato alle attività non completate
                                            </checkbox>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        {/*<select2 params="Label: 'Nuovo stato delle attività non completate', DataSource: $parents[2].TaskStatesDataSource, Value: MoveNonClosedTasksToState, ReadOnly: !MoveNonClosedTasks()"></select2>*/}
                                        <label>&nbsp;</label>
                                        <div>
                                            <select2 params="Simple: true, DataSource: $parents[2].TaskStatesDataSource, Value: MoveNonClosedTasksToState, ReadOnly: !MoveNonClosedTasks()"></select2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>&nbsp;</label>
                                        <div style="display: flex; align-items: center; height: 34px;">
                                            <checkbox class="col-md-12" checked={() => "MoveClosedTasks"}>
                                                Cambia stato alle attività completate
                                            </checkbox>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        {/*<select2 params="Label: 'Nuovo stato delle attività completate', DataSource: $parents[2].TaskStatesDataSource, Value: MoveClosedTasksToState, ReadOnly: !MoveClosedTasks()"></select2>*/}
                                        <label>&nbsp;</label>
                                        <div>
                                            <select2 params="Simple: true, DataSource: $parents[2].TaskStatesDataSource, Value: MoveClosedTasksToState, ReadOnly: !MoveClosedTasks()"></select2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </list>
                    </div>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
