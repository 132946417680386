import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import * as numeral from "numeral";
import jss from "jss";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import {
    IPlanningService,
    ICompanyAnalysis,
    IPlanningSnapshot,
    IPlanningSnapshotData,
    IPlanningSnapshot_Type,
    IPlanningSnapshotData_Type,
} from "../../../ProlifeSdk/interfaces/planning/IPlanningService";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { SplashPageDetailsDialog } from "../../../JobOrder/jobOrder/ui/splash-page/SplashPageDetailsComponent";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { ExcelExporterButton } from "../../../Components/ExcelExporterComponent/ExcelExporterComponent";
import { Layout } from "../../../Components/Layouts";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { If, IfNot } from "../../../Components/IfIfNotWith";
import { Select } from "../../../Components/Select";
import { PlanningSnapshotsDataSource } from "../../../DataSources/PlanningSnapshotsDataSource";
import { CheckBox } from "../../../Components/Checkbox";
import { TextInput } from "../../../Components/TextInput";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { MoneyInput } from "../../../Components/MoneyInput";
import { IJobOrderDataSourceModel, JobOrdersDataSource } from "../../../DataSources/JobOrdersDataSource";
import { IJobOrderForTaskBoard } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TableFooterAggregationMode } from "../../../Components/TableComponent/TableFooterAggregationMode";
import { CostsStatusLegend } from "./CostsStatusLegend";

const styleSheet = jss.createStyleSheet({
    budgetViewerDashboard: {
        "& .dashboard-stat .details input[type='text'].form-control.dashboard-input": {
            fontSize: "34px",
            padding: 0,
            background: "transparent",
            color: "white",
            fontFamily: '"Open Sans", sans-serif',
            fontWeight: 300,
            margin: 0,
            lineHeight: "12px",
            height: "46px",
            marginTop: "-10px",
            paddingRight: "8px",
            textAlign: "right",
        },

        "& .dashboard-stat .details .number .bootstrap-datetimepicker-widget": {
            color: "initial",
        },

        "& .table": {
            "& tr": {
                "& > th": {
                    minWidth: "200px",
                },
            },

            "&.editable": {
                "& tr": {
                    "& > th": {
                        "&.money": {
                            width: "100px",
                            minWidth: "100px",
                            maxWidth: "100px",
                        },

                        "&.job-order": {
                            "& .flex-container": {
                                width: "250px",
                                minWidth: "250px",
                                maxWidth: "250px",
                            },
                        },

                        "&.job-order-type-state": {
                            width: "100px",
                            minWidth: "100px",
                            maxWidth: "100px",
                        },

                        "&.actions": {
                            width: "50px",
                            minWidth: "50px",
                            maxWidth: "50px",

                            "& .btn": {
                                margin: "0px",
                            },
                        },
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

export type CompanyAnalysis = {
    JobOrderUrl: string;
    showJobOrderInfo: (jobOrder: ICompanyAnalysis) => Promise<void>;
    showWarnings: () => Promise<void>;

    warningIcon: ko.Computed<string>;

    Costs: number;
    EstimatedCosts: number;

    JobOrderIdObs: ko.Observable<number>;
    JobOrderStateObs: ko.Observable<number>;
    JobOrderTypeObs: ko.Observable<number>;
    JobOrderCustomerObs: ko.Observable<string>;
    TotalWorkCostsInPeriodObs: ko.Observable<number>;
    TotalPurchaseCostsInPeriodObs: ko.Observable<number>;
    TotalWarehouseCostsInPeriodObs: ko.Observable<number>;
    TotalRevenuesInPeriodObs: ko.Observable<number>;

    WorkCostsAbsoluteError: ko.Observable<number | null>;
    WorkCostsRelativeError: ko.Observable<number | null>;

    PurchaseCostsAbsoluteError: ko.Observable<number | null>;
    PurchaseCostsRelativeError: ko.Observable<number | null>;

    WarehouseCostsAbsoluteError: ko.Observable<number | null>;
    WarehouseCostsRelativeError: ko.Observable<number | null>;
} & ICompanyAnalysis;

export class BudgetViewerViewModel implements INavigationMenuObserver {
    public categoriesMenu: INavigationMenu;
    public statesMenu: INavigationMenu;

    public templateName = "budgetViewer";
    public templateUrl = "planning/templates";

    ExporterId = "Planning/PlanningExport";

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IPlanningService>())
    private planningService: IPlanningService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypes: IJobOrderTypesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStates: IJobOrderStateSettingsManager;

    public DetailsVisible: ko.Observable<boolean> = ko.observable(false);

    public WorkEarnings: ko.Observable<number> = ko.observable();
    public PurchasesEarnings: ko.Observable<number> = ko.observable();
    public WarehouseEarnings: ko.Observable<number> = ko.observable();
    public TotalEarnings: ko.Observable<number> = ko.observable();

    public WorkCosts: ko.Observable<number> = ko.observable();
    public PurchasesCosts: ko.Observable<number> = ko.observable();
    public WarehouseCosts: ko.Observable<number> = ko.observable();
    public TotalCosts: ko.Observable<number> = ko.observable();

    public WorkRevenues: ko.Observable<number> = ko.observable();
    public PurchasesRevenues: ko.Observable<number> = ko.observable();
    public WarehouseRevenues: ko.Observable<number> = ko.observable();
    public TotalRevenues: ko.Observable<number> = ko.observable();

    public OtherRevenues: ko.Observable<number> = ko.observable();
    public OtherEarnings: ko.Observable<number> = ko.observable();

    public TotalUnassignedTotalRevenues: ko.Observable<number> = ko.observable();

    public DetailedEconomics: ko.ObservableArray<CompanyAnalysis> = ko.observableArray();

    public JobOrderTypes: ko.ObservableArray<number> = ko.observableArray();
    public JobOrderStates: ko.ObservableArray<number> = ko.observableArray();
    public StartDate: ko.Observable<Date> = ko.observable(moment().startOf("year").toDate());
    public EndDate: ko.Observable<Date> = ko.observable(moment().toDate());
    public StrictlyToPeriod: ko.Observable<number> = ko.observable(0);
    public SnapshotId: ko.Observable<number> = ko.observable();
    public UseSnapshot: ko.Observable<boolean> = ko.observable(false);

    public DisableUseSnapshotFlag: ko.Observable<boolean> = ko.observable(false);
    public DisableSnapshotSelector: ko.Observable<boolean> = ko.observable(true);
    public DisableSnapshotTitle: ko.Computed<boolean>;
    public SnapshotOnEditId: ko.Observable<number> = ko.observable();
    public SnapshotOnEditTitle: ko.Observable<string> = ko.observable();
    private selectedParentSnapshot: IPlanningSnapshot = null;
    private endDateSubscription: ko.Subscription = null;
    private JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();

    public costsChartData: ko.Computed<Highcharts.Options>;
    public loading: ko.Observable<boolean> = ko.observable();

    public DataEditingEnabled: ko.Observable<boolean> = ko.observable(false);

    private PlanningSnapshotsDataSource: PlanningSnapshotsDataSource = new PlanningSnapshotsDataSource();
    private PlanningSnapshotsForEditingDataSource: PlanningSnapshotsDataSource = new PlanningSnapshotsDataSource();

    private unassignedRevenuesColumn: Column<CompanyAnalysis>;

    constructor() {
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.categoriesMenu = this.jobOrderService.ui.getTypesNavigationMenu();
        this.categoriesMenu.setSingleSelectionStatus(false, true);
        this.categoriesMenu.setMultipleSelectionStatus(true);
        this.categoriesMenu.addMenuObserver(this);

        this.statesMenu = this.jobOrderService.ui.getStatesNavigationMenu();
        this.statesMenu.setSingleSelectionStatus(false, true);
        this.statesMenu.setMultipleSelectionStatus(true);
        this.statesMenu.addMenuObserver(this);

        this.costsChartData = ko.computed(() => {
            this.TotalCosts();

            return this.generateNewPieGraph(
                ["#d83636", "#E35B5A", "#e57e7e"],
                [
                    [ProlifeSdk.TextResources.JobOrder.PurchasesExpectedCost, this.PurchasesCosts()],
                    [ProlifeSdk.TextResources.JobOrder.EstimatedWorkCost, this.WorkCosts()],
                    [ProlifeSdk.TextResources.JobOrder.EstimatedWarehouseCosts, this.WarehouseCosts()],
                ],
                ProlifeSdk.TextResources.JobOrder.EstimatedCosts
            );
        });

        this.DisableSnapshotTitle = ko.computed(() => {
            return !this.DisableSnapshotSelector() && !(this.SnapshotOnEditId() || this.SnapshotOnEditId() < 0);
        });
    }

    componentDidMount() {
        let originalEndDate = this.EndDate();
        let updating = false;

        this.endDateSubscription = this.EndDate.subscribe((date: Date) => {
            if (!this.DataEditingEnabled() || !this.selectedParentSnapshot || updating) return;

            updating = true;

            if (moment(date).isBefore(moment(this.selectedParentSnapshot.ReferenceDate))) {
                this.EndDate(originalEndDate);
                this.infoToastService.Warning(TextResources.Planning.InvalidReferenceDate);
            } else {
                originalEndDate = date;
            }

            updating = false;
        });
    }

    componentWillUnmount() {
        this.endDateSubscription.dispose();
        this.endDateSubscription = null;
    }

    private showJobOrderInfo(jobOrder: ICompanyAnalysis) {
        return this.dialogsService.ShowModal(
            new SplashPageDetailsDialog({
                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, jobOrder.JobOrderName),
                JobOrderId: jobOrder.JobOrderId,
            })
        );
    }

    private showWarnings(_jo: CompanyAnalysis, vm, e: Event): Promise<void> {
        return this.dialogsService.ShowPopoverComponent(
            e.currentTarget as HTMLElement,
            {
                title: "Dettagli",
                content: () =>
                    ComponentUtils.bindTo(
                        <div style={{ maxWidth: "600px" }}>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <div className="col-md-2">
                                    <label
                                        className="btn btn-sm"
                                        data-bind={{
                                            css: "btn-" + _jo.warningIcon(),
                                        }}
                                        style={{ margin: 0, marginRight: "auto" }}>
                                        <i
                                            className="fa fa-check-circle"
                                            data-bind={{
                                                css: {
                                                    "fa-check-circle":
                                                        _jo.TotalProgress > 0.03 && _jo.warningIcon() !== "danger",
                                                    "fa-question-circle": _jo.TotalProgress <= 0.03,
                                                    "fa-times-circle":
                                                        _jo.TotalProgress > 0.03 && _jo.warningIcon() === "danger",
                                                },
                                            }}></i>
                                    </label>
                                </div>
                                <div className="col-md-10" style={{ whiteSpace: "break-spaces" }}>
                                    {_jo.TotalProgress <= 0.03
                                        ? TextResources.Planning.CostsStatusLegendGreenQuestionDescription
                                        : _jo.warningIcon() === "success"
                                        ? TextResources.Planning.CostsStatusLegendGreenCheckDescription
                                        : _jo.warningIcon() === "danger"
                                        ? TextResources.Planning.CostsStatusLegendRedTimesDescription
                                        : TextResources.Planning.CostsStatusLegendOrangeCheckDescription}
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-md-5">Avanzamento</label>
                                <span
                                    className="col-md-7"
                                    data-bind={{
                                        percentageText: _jo.TotalProgress * 100,
                                        css: { "text-success": _jo.TotalProgress <= 0.03 },
                                    }}
                                    title={
                                        _jo.TotalProgress <= 0.03
                                            ? "Avanzamento <= 3%, impossibile determinare un valore accurato"
                                            : ""
                                    }></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-md-12"
                                    data-bind={{ css: { "text-success": _jo.TotalProgress <= 0.03 } }}>
                                    {_jo.TotalProgress <= 0.03
                                        ? "Avanzamento <= 3%, impossibile determinare un valore accurato"
                                        : ""}
                                </span>
                            </div>
                            <table className="table table-condensed table-striped">
                                <thead>
                                    <tr>
                                        <th>Categoria</th>
                                        <th
                                            className="text-right"
                                            title="Costi Effettivi dall'inizio del progetto a Fine Periodo">
                                            Costi Effettivi (FP)
                                        </th>
                                        <th
                                            className="text-right"
                                            title="Costi derivanti dalla parte completata delle stime calcolati a Fine Periodo">
                                            Stime Avanzate (FP)
                                        </th>
                                        <th
                                            className="text-right"
                                            title="Differenza, in valore assoluto, tra il costo totale e il costo stimato">
                                            Errore assoluto
                                        </th>
                                        <th className="text-right" title="Rapporto tra errore assoluto e costo stimato">
                                            Errore relativo
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Lavoro</td>
                                        <td className="text-right" data-bind={{ moneyText: _jo.TotalWorkCosts }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.EstimatedWorkCostsAtPeriodEnd }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.WorkCostsAbsoluteError }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ percentageText: _jo.WorkCostsRelativeError() * 100 }}></td>
                                    </tr>
                                    <tr>
                                        <td>Acquisti</td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.TotalPurchaseCosts }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.EstimatedPurchasesCostsAtPeriodEnd }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.PurchaseCostsAbsoluteError }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ percentageText: _jo.PurchaseCostsRelativeError() * 100 }}></td>
                                    </tr>
                                    <tr>
                                        <td>Magazzino</td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.TotalWarehouseCosts }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.EstimatedWarehouseCostsAtPeriodEnd }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{ moneyText: _jo.WarehouseCostsAbsoluteError }}></td>
                                        <td
                                            className="text-right"
                                            data-bind={{
                                                percentageText: _jo.WarehouseCostsRelativeError() * 100,
                                            }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>,
                        _jo,
                        "_jo"
                    ) as React.ReactElement,
            },
            "left"
        );
    }

    private load() {
        this.loading(true);
        this.DetailedEconomics([]);
        this.TotalCosts(0);
        this.TotalRevenues(0);
        this.TotalEarnings(0);

        this.WorkCosts(0);
        this.PurchasesCosts(0);
        this.WarehouseCosts(0);

        this.planningService
            .GetCompanyAnalysis({
                jobOrderCategoryIds: this.JobOrderTypes(),
                jobOrderStateIds: this.JobOrderStates(),
                startDate: this.StartDate(),
                endDate: this.EndDate(),
                strictlyToPeriod: this.StrictlyToPeriod(),
                snapshotId: this.UseSnapshot() ? this.SnapshotId() : 0,
            })
            .then((results: ICompanyAnalysis[]) => {
                let totalCosts = 0;
                let totalWorkCosts = 0;
                let totalPurchaseCosts = 0;
                let totalWarehouseCosts = 0;

                //let totalEstimatedCosts = 0;
                let totalRevenues = 0;
                let totalUnassignedRevenues = 0;

                const jobOrders: CompanyAnalysis[] = [];

                results.forEach((r) => {
                    const jo: CompanyAnalysis = this.createCompanyAnalysisModel(r);

                    totalCosts += jo.Costs;
                    totalWorkCosts += jo.TotalWorkCostsInPeriod;
                    totalPurchaseCosts += jo.TotalPurchasesCostsInPeriod;
                    totalWarehouseCosts += jo.TotalWarehouseCostsInPeriod;

                    //totalEstimatedCosts += jo.EstimatedCosts;

                    if (this.StrictlyToPeriod() === 2) {
                        totalRevenues += jo.TotalRevenuesInPeriod + jo.UnassignedTotalRevenues;
                    } else {
                        totalRevenues += jo.TotalRevenuesInPeriod;
                        totalUnassignedRevenues += jo.UnassignedTotalRevenues;
                    }

                    jobOrders.push(jo);
                });

                this.DetailedEconomics(jobOrders);

                this.TotalCosts(totalCosts);
                this.TotalRevenues(totalRevenues);
                this.TotalEarnings(totalRevenues - totalCosts);

                this.WorkCosts(totalWorkCosts);
                this.PurchasesCosts(totalPurchaseCosts);
                this.WarehouseCosts(totalWarehouseCosts);

                this.TotalUnassignedTotalRevenues(totalUnassignedRevenues);

                /*this.WorkRevenues(totalWorkRevenues);
                /*this.WorkRevenues(totalWorkRevenues);
                this.PurchasesRevenues(totalPurchaseRevenues);
                this.WarehouseRevenues(totalWarehouseRevenues);

                this.WorkEarnings(totalWorkRevenues - totalWorkCosts);
                this.PurchasesEarnings(totalPurchaseRevenues - totalPurchaseCosts);
                this.WarehouseEarnings(totalWarehouseRevenues - totalWarehouseCosts);

                this.OtherRevenues(totalOtherRevenues);
                this.OtherEarnings(totalOtherRevenues);*/

                this.unassignedRevenuesColumn.visible(this.StrictlyToPeriod() === 1);

                this.loading(false);
            });
    }

    private createCompanyAnalysisModel(data: ICompanyAnalysis) {
        const jo: CompanyAnalysis = {
            ...data,
            JobOrderUrl: this.jobOrderService.getJobOrderUrl(data.JobOrderId),
            showJobOrderInfo: this.showJobOrderInfo.bind(this, data),
            showWarnings: null,
            warningIcon: ko.computed(() => {
                if (data.TotalProgress <= 0.03) return "success";

                const jobOrderTotalEstimatedCosts =
                    data.EstimatedPurchasesCostsAtPeriodEnd +
                    data.EstimatedWarehouseCostsAtPeriodEnd +
                    data.EstimatedWorkCostsAtPeriodEnd;
                if (jobOrderTotalEstimatedCosts == 0) return "danger";

                const diffWork =
                    data.EstimatedWorkCostsAtPeriodEnd == 0
                        ? data.TotalWorkCosts / jobOrderTotalEstimatedCosts
                        : Math.abs(data.TotalWorkCosts - data.EstimatedWorkCostsAtPeriodEnd) /
                          data.EstimatedWorkCostsAtPeriodEnd;

                const diffPurch =
                    data.EstimatedPurchasesCostsAtPeriodEnd == 0
                        ? data.TotalPurchaseCosts / jobOrderTotalEstimatedCosts
                        : Math.abs(data.TotalPurchaseCosts - data.EstimatedPurchasesCostsAtPeriodEnd) /
                          data.EstimatedPurchasesCostsAtPeriodEnd;

                const diffWarehouse =
                    data.EstimatedWarehouseCostsAtPeriodEnd == 0
                        ? data.TotalWarehouseCosts / jobOrderTotalEstimatedCosts
                        : Math.abs(data.TotalWarehouseCosts - data.EstimatedWarehouseCostsAtPeriodEnd) /
                          data.EstimatedWarehouseCostsAtPeriodEnd;

                const warnings = [diffWork, diffPurch, diffWarehouse];

                const max = warnings.max((w) => w);
                if (max > 0.15) return "danger";
                if (max > 0.05) return "warning";
                return "success";
            }),

            WorkCostsAbsoluteError: ko.observable(Math.abs(data.TotalWorkCosts - data.EstimatedWorkCostsAtPeriodEnd)),
            WorkCostsRelativeError: ko.observable(
                data.EstimatedWorkCostsAtPeriodEnd == 0
                    ? null
                    : Math.abs(data.TotalWorkCosts - data.EstimatedWorkCostsAtPeriodEnd) /
                          data.EstimatedWorkCostsAtPeriodEnd
            ),

            PurchaseCostsAbsoluteError: ko.observable(
                Math.abs(data.TotalPurchaseCosts - data.EstimatedPurchasesCostsAtPeriodEnd)
            ),
            PurchaseCostsRelativeError: ko.observable(
                data.EstimatedPurchasesCostsAtPeriodEnd == 0
                    ? null
                    : Math.abs(data.TotalPurchaseCosts - data.EstimatedPurchasesCostsAtPeriodEnd) /
                          data.EstimatedPurchasesCostsAtPeriodEnd
            ),

            WarehouseCostsAbsoluteError: ko.observable(
                Math.abs(data.TotalWarehouseCosts - data.EstimatedWarehouseCostsAtPeriodEnd)
            ),
            WarehouseCostsRelativeError: ko.observable(
                data.EstimatedWarehouseCostsAtPeriodEnd == 0
                    ? null
                    : Math.abs(data.TotalWarehouseCosts - data.EstimatedWarehouseCostsAtPeriodEnd) /
                          data.EstimatedWarehouseCostsAtPeriodEnd
            ),

            Costs: data.TotalWorkCostsInPeriod + data.TotalPurchasesCostsInPeriod + data.TotalWarehouseCostsInPeriod,
            EstimatedCosts:
                data.EstimatedPurchasesCostsAtPeriodEnd +
                data.EstimatedWarehouseCostsAtPeriodEnd +
                data.EstimatedWorkCostsAtPeriodEnd,

            JobOrderIdObs: ko.observable(data.JobOrderId),
            JobOrderTypeObs: ko.observable(data.JobOrderType),
            JobOrderStateObs: ko.observable(data.JobOrderState),
            JobOrderCustomerObs: ko.observable(data.JobOrderCustomerName),
            TotalPurchaseCostsInPeriodObs: ko.observable(data.TotalPurchasesCostsInPeriod),
            TotalWarehouseCostsInPeriodObs: ko.observable(data.TotalWarehouseCostsInPeriod),
            TotalWorkCostsInPeriodObs: ko.observable(data.TotalWorkCostsInPeriod),
            TotalRevenuesInPeriodObs: ko.observable(data.TotalRevenuesInPeriod),
        };

        jo.showWarnings = this.showWarnings.bind(this, jo);

        return jo;
    }

    public Refresh() {
        this.load();
    }

    public ShowHideDetails() {
        this.DetailsVisible(!this.DetailsVisible());
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {
        // nothing to do
    }

    onSelectionChanged(selection: INavigationMenuProvider[], navigator: INavigationMenu) {
        type NavMenuProviderWithState = INavigationMenuProvider & { state: IJobOrderState };
        //const selected : NavMenuProviderWithState = selection[0] as unknown as NavMenuProviderWithState;

        if (navigator == this.categoriesMenu) {
            this.JobOrderTypes(selection.map((s) => s.Id));
            //this.JobOrderType(selection.length == 0 ? -1 : selected.Id);
        } else if (navigator == this.statesMenu) {
            this.JobOrderStates(selection.map((s: NavMenuProviderWithState) => s.state.IdJobOrderStateId));
            //this.JobOrderState(selection.length == 0 ? -1 : selected.state.IdJobOrderStateId);
        }

        //this.provider.refresh();
    }

    public getExcelExportData() {
        return {
            jobOrderCategoryIds: this.JobOrderTypes(),
            jobOrderStateIds: this.JobOrderStates(),
            startDate: this.StartDate(),
            endDate: this.EndDate(),
            strictlyToPeriod: this.StrictlyToPeriod(),
            snapshotId: this.UseSnapshot() ? this.SnapshotId() : 0,
        };
    }

    private generateNewPieGraph(colors: string[], seriesData: [string, number][], name: string): Highcharts.Options {
        const chart: Highcharts.Options = {
            chart: {
                type: "pie",
                options3d: {
                    enabled: true,
                    alpha: 45,
                },
            },
            colors: colors,
            title: {
                text: null,
            },
            subtitle: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    borderColor: "rgba(0,0,0,0.125)",
                    innerSize: 60,
                    depth: 15,
                    dataLabels: {
                        enabled: false,
                        crop: false,
                        overflow: "none",
                    },
                },
            },
            tooltip: {
                valueSuffix: " €",
                valueDecimals: 2,
            },
            series: [
                {
                    data: seriesData,
                    name: name,
                },
            ],
        };

        return chart;
    }

    public enableSnapshotCreation(): void {
        this.DataEditingEnabled(true);
        this.UseSnapshot(true);
        this.DisableUseSnapshotFlag(true);
        this.EndDate(null);
        this.DetailedEconomics([]);
    }

    public enableSnapshotEditing(): void {
        this.enableSnapshotCreation();
        this.DisableSnapshotSelector(false);
    }

    public disableSnapshotCreation(): void {
        this.DataEditingEnabled(false);
        this.UseSnapshot(false);
        this.DisableUseSnapshotFlag(false);
        if (!this.EndDate()) this.EndDate(moment().toDate());

        this.SnapshotId(null);
        this.SnapshotOnEditId(undefined);
        this.SnapshotOnEditTitle("");

        this.PlanningSnapshotsForEditingDataSource.selectByIds(undefined);

        this.DetailedEconomics([]);
    }

    public async showCostsStatusLegend(sender: any, event: Event): Promise<void> {
        const legend = new CostsStatusLegend();
        await legend.show(event.currentTarget as HTMLElement);
    }

    public disableSnapshotEditing(): void {
        this.disableSnapshotCreation();
        this.DisableSnapshotSelector(true);
    }

    public async saveSnapshot(): Promise<void> {
        const selectedSnapshotId = this.SnapshotOnEditId() || -1;
        if (!this.DisableSnapshotSelector() && selectedSnapshotId <= 0) {
            this.infoToastService.Warning(TextResources.Planning.MissingSnapshotToEdit);
            return;
        }

        const parentSnapshotId = this.SnapshotId() || 0;
        if (!parentSnapshotId) {
            this.infoToastService.Warning(TextResources.Planning.MissingParentSnapshot);
            return;
        }

        const referenceDate = this.EndDate();
        if (!referenceDate) {
            this.infoToastService.Warning(TextResources.Planning.MissingReferenceDate);
            return;
        }

        const title = this.SnapshotOnEditTitle();
        if (!title) {
            this.infoToastService.Warning(TextResources.Planning.MissingTitle);
            return;
        }

        const snapshot: IPlanningSnapshot_Type = {
            Id: selectedSnapshotId,
            Title: title,
            ReferenceDate: referenceDate,
            ParentSnapshot: parentSnapshotId,
        };

        const snapshotData: IPlanningSnapshotData_Type[] = this.DetailedEconomics().map((de) => ({
            FKSnapshot: selectedSnapshotId,
            FKJobOrder: de.JobOrderIdObs(),
            TotalWorkCosts: de.TotalWorkCostsInPeriodObs(),
            TotalPurchaseCosts: de.TotalPurchaseCostsInPeriodObs(),
            TotalWarehouseCosts: de.TotalWarehouseCostsInPeriodObs(),
            TotalRevenues: de.TotalRevenuesInPeriodObs(),
        }));

        try {
            const idMappings = await this.planningService.CreateOrUpdateSnapshot([snapshot], snapshotData);
            const newSnapshotId = idMappings[0].NewId;
            this.SnapshotOnEditId(newSnapshotId);

            this.infoToastService.Success(TextResources.Planning.SaveSnapshotSuccess);
        } catch (e) {
            console.log(e);
        }
    }

    public async deleteSnapshot(): Promise<void> {
        if (this.SnapshotOnEditId() <= 0) return;

        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Planning.DeleteSnapshotConfirm,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        try {
            await this.planningService.DeleteSnapshot(this.SnapshotOnEditId());

            this.SnapshotOnEditId(null);
            this.SnapshotOnEditTitle(null);
            this.DetailedEconomics([]);

            this.infoToastService.Success(TextResources.Planning.SnapshotDeleted);
        } catch (e) {
            console.log(e);
        }
    }

    private createCompanyAnalysisFrom(data: IPlanningSnapshotData = null): ICompanyAnalysis {
        return {
            JobOrderId: data?.FKJobOrder,
            JobOrderName: data?.JobOrderName,
            JobOrderType: data?.JobOrderType,
            JobOrderState: data?.JobOrderState,
            JobOrderCustomerName: data?.JobOrderCustomerName,
            TotalWorkCosts: 0,
            TotalPurchaseCosts: 0,
            TotalWarehouseCosts: 0,
            TotalProgress: 0,
            EstimatedWorkCostsAtPeriodEnd: 0,
            EstimatedPurchasesCostsAtPeriodEnd: 0,
            EstimatedWarehouseCostsAtPeriodEnd: 0,
            TotalRevenuesInPeriod: data?.TotalRevenues || 0,
            UnassignedTotalRevenues: 0,
            TotalWorkCostsInPeriod: data?.TotalWorkCosts || 0,
            TotalPurchasesCostsInPeriod: data?.TotalPurchaseCosts || 0,
            TotalWarehouseCostsInPeriod: data?.TotalWarehouseCosts || 0,
            RemainingCostsAtPeriodEnd: 0,
            WarehouseValueAtPeriodEnd: 0,
        };
    }

    public addRow(): void {
        this.DetailedEconomics.push(this.createCompanyAnalysisModel(this.createCompanyAnalysisFrom()));
    }

    public async removeRow(model: CompanyAnalysis): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Planning.RemoveRowMessage,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        this.DetailedEconomics.remove(model);
    }

    render() {
        const _bv = this;
        let row: IDataSourceModel<number, CompanyAnalysis>;

        const onSnapshotSelected = async (model: IDataSourceModel<number, IPlanningSnapshot>) => {
            this.SnapshotOnEditTitle(model.model.Title);
            this.EndDate(model.model.ReferenceDate);

            if (model.model.ParentSnapshot) {
                this.SnapshotId(model.model.ParentSnapshot);
                const snapshot = (
                    await this.PlanningSnapshotsDataSource.getById(null, [model.model.ParentSnapshot])
                ).firstOrDefault();
                this.selectedParentSnapshot = snapshot.model;
            }

            try {
                const snapshotData = await this.planningService.GetSnapshotData(model.model.Id);
                const elements = [];
                let totalCosts = 0;
                let totalRevenues = 0;

                for (const data of snapshotData) {
                    const analysis = this.createCompanyAnalysisModel(this.createCompanyAnalysisFrom(data));
                    elements.push(analysis);

                    totalCosts += data.TotalWorkCosts + data.TotalPurchaseCosts + data.TotalWarehouseCosts;
                    totalRevenues += data.TotalRevenues;
                }

                this.DetailedEconomics(elements);

                this.TotalCosts(totalCosts);
                this.TotalRevenues(totalRevenues);
                this.TotalEarnings(totalRevenues - totalCosts);
            } catch (e) {
                console.log(e);
            }
        };

        const onSnapshotDeselected = async () => {
            this.DetailedEconomics([]);
            this.SnapshotId(undefined);
            this.selectedParentSnapshot = null;
        };

        const onParentSnapshotSelected = (model: IDataSourceModel<number, IPlanningSnapshot>) => {
            this.selectedParentSnapshot = model.model;
        };

        const onParentSnapshotDeselected = () => {
            this.selectedParentSnapshot = null;
        };

        const renderHeaderActions = () => (
            <div className="flex-container flex-fill">
                <button
                    className="btn btn-success"
                    title={TextResources.Planning.CostsStatusLegend}
                    data-bind={{
                        asyncClick: _bv.showCostsStatusLegend.bind(_bv),
                    }}>
                    <i className="fa fa-info-circle"></i>
                </button>
                <IfNot condition={this.DataEditingEnabled}>
                    {() => (
                        <ExcelExporterButton
                            exporterId={this.ExporterId}
                            exporterMethod="GenerateExcel"
                            dataProvider={this.getExcelExportData.bind(this)}
                            position="left">
                            <i className="fa fa-download"></i>&nbsp;
                            {TextResources.Planning.ExportCostsRevenues}
                        </ExcelExporterButton>
                    )}
                </IfNot>
                <button
                    className="btn btn-primary"
                    title={TextResources.Planning.CreateSnapshot}
                    data-bind={{ click: _bv.enableSnapshotCreation.bind(_bv), visible: !_bv.DataEditingEnabled() }}>
                    <i className="fa fa-plus"></i>
                </button>
                <button
                    className="btn btn-primary"
                    title={TextResources.Planning.EditSnapshot}
                    data-bind={{ click: _bv.enableSnapshotEditing.bind(_bv), visible: !_bv.DataEditingEnabled() }}>
                    <i className="fa fa-pencil"></i>
                </button>
            </div>
        );

        const renderSnapshotTableHeaderActions = () => (
            <div className="flex-container flex-fill">
                <button
                    className="btn btn-success"
                    title={TextResources.Planning.CostsStatusLegend}
                    data-bind={{
                        asyncClick: _bv.showCostsStatusLegend.bind(_bv),
                    }}>
                    <i className="fa fa-info-circle"></i>
                </button>

                <button
                    className="btn btn-success"
                    title={TextResources.Planning.DisableDataEditing}
                    data-bind={{
                        click: _bv.disableSnapshotCreation.bind(_bv),
                        visible: _bv.DataEditingEnabled() && _bv.DisableSnapshotSelector(),
                    }}>
                    <i className="fa fa-undo"></i>
                </button>
                <button
                    className="btn btn-success"
                    title={TextResources.Planning.DisableDataEditing}
                    data-bind={{
                        click: _bv.disableSnapshotEditing.bind(_bv),
                        visible: _bv.DataEditingEnabled() && !_bv.DisableSnapshotSelector(),
                    }}>
                    <i className="fa fa-undo"></i>
                </button>

                <div style={{ width: "35%" }}>
                    <Select
                        dataSource={this.PlanningSnapshotsForEditingDataSource}
                        placeholder={ko.computed(() =>
                            this.DisableSnapshotSelector()
                                ? TextResources.Planning.NewSnapshot
                                : TextResources.Planning.SelectSnapshot
                        )}
                        value={this.SnapshotOnEditId}
                        allowClear
                        onSelect={onSnapshotSelected}
                        onDeselect={onSnapshotDeselected}
                        readOnly={this.DisableSnapshotSelector}
                    />
                </div>

                <div className="flex-fill">
                    <TextInput
                        simple
                        placeholder={TextResources.Planning.SnapshotTitlePlaceholder}
                        readonly={this.DisableSnapshotTitle}
                        value={this.SnapshotOnEditTitle}
                    />
                </div>

                <button
                    className="btn btn-primary"
                    data-bind={{ asyncClick: _bv.saveSnapshot.bind(_bv), visible: _bv.DataEditingEnabled }}>
                    <i className="fa fa-save"></i>
                </button>

                <button
                    className="btn btn-danger"
                    data-bind={{
                        asyncClick: _bv.deleteSnapshot.bind(_bv),
                        visible: _bv.DataEditingEnabled,
                        enable: _bv.SnapshotOnEditId() > 0,
                    }}>
                    <i className="fa fa-trash-o"></i>
                </button>
            </div>
        );

        const sorter = ComponentUtils.useSorter<CompanyAnalysis>();
        const totalsFormatter = (value: number) => numeral(value ?? 0).format("0,0.00 $");

        const renderStandardTable = () => {
            return (
                <Table
                    showLoadingIndicator={this.loading}
                    compact
                    systemScrollable
                    dataSource={Table.defaultDataSource(this.DetailedEconomics, (e) => ({
                        id: e.JobOrderId,
                        title: e.JobOrderName,
                        model: e,
                    }))}
                    rowAs="row"
                    headerActions={renderHeaderActions}
                    showColumnSelector
                    columnSelectorPosition="right"
                    useHoverEffect
                    enableAggregators
                    sortableColumns
                    selectableRows
                    selectRowsByCheckbox
                    id={ProlifeSdk.PlanningBudgetViewerTable}>
                    <Column
                        id={1}
                        title="Commessa"
                        sorter={sorter.sortString((p) => p.JobOrderName)}
                        aggregateOn={(item) => item.Data.model.JobOrderName}>
                        <ColumnBody>
                            {() => (
                                <div className="flex-container flex-child-center">
                                    <a
                                        target="blank"
                                        href="#"
                                        data-bind={{
                                            attr: { href: row.model.JobOrderUrl },
                                            text: row.model.JobOrderName,
                                        }}></a>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        style={{ marginLeft: "auto" }}
                                        data-bind={{ asyncClick: row.model.showJobOrderInfo.bind(row.model) }}>
                                        <i className="fa fa-info-circle"></i>
                                    </button>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={2}
                        title="Tipologia"
                        sorter={sorter.sortString(
                            (p) => this.jobOrderTypes.getJobOrderTypeById(p.JobOrderType)?.Description
                        )}
                        aggregateOn={(item) =>
                            this.jobOrderTypes.getJobOrderTypeById(item.Data.model.JobOrderType)?.Description || ""
                        }>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{ JobOrderTypeDisplayValue: { typeId: row.model.JobOrderType } }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={3}
                        title="Stato"
                        sorter={sorter.sortString(
                            (p) => this.jobOrderStates.getJobOrderStateById(p.JobOrderState)?.Description
                        )}
                        aggregateOn={(item) =>
                            this.jobOrderStates.getJobOrderStateById(item.Data.model.JobOrderState)?.Description || ""
                        }>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        JobOrderStateDisplayValue: { stateId: row.model.JobOrderState },
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={4}
                        title="Cliente"
                        sorter={sorter.sortString((p) => p.JobOrderCustomerName)}
                        aggregateOn={(item) => item.Data.model.JobOrderCustomerName}>
                        <ColumnBody>
                            {() => <span data-bind={{ text: row.model.JobOrderCustomerName }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={5}
                        title="Avanzamento"
                        className="text-right"
                        sorter={sorter.sortNumber((p) => p.TotalProgress)}
                        aggregateOn={(item) => item.Data.model.TotalProgress}
                        aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00%")}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        percentageText: row.model.TotalProgress * 100,
                                        format: "0.00%",
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={6}
                        title="Costi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.Costs)}
                        aggregateOn={(item) => item.Data.model.Costs}
                        aggregationFormatter={totalsFormatter}>
                        <ColumnBody>
                            {() => (
                                <div className="flex-container flex-child-center">
                                    <label
                                        className="btn btn-sm"
                                        data-bind={{
                                            css: "btn-" + row.model.warningIcon(),
                                            asyncClick: row.model.showWarnings.bind(row.model),
                                        }}
                                        style={{ margin: 0, marginRight: "auto" }}>
                                        <i
                                            className="fa fa-check-circle"
                                            data-bind={{
                                                css: {
                                                    "fa-check-circle":
                                                        row.model.TotalProgress > 0.03 &&
                                                        row.model.warningIcon() !== "danger",
                                                    "fa-question-circle": row.model.TotalProgress <= 0.03,
                                                    "fa-times-circle":
                                                        row.model.TotalProgress > 0.03 &&
                                                        row.model.warningIcon() === "danger",
                                                },
                                            }}></i>
                                    </label>
                                    <span
                                        style={{ marginLeft: "5px" }}
                                        data-bind={{
                                            moneyText: row.model.Costs,
                                            css: { "text-danger": row.model.Costs < 0 },
                                        }}></span>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={7}
                        title="Ricavi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalRevenuesInPeriod)}
                        aggregateOn={(item) => item.Data.model.TotalRevenuesInPeriod}
                        aggregationFormatter={totalsFormatter}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        moneyText: row.model.TotalRevenuesInPeriod,
                                        css: { "text-danger": row.model.TotalRevenuesInPeriod < 0 },
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={8}
                        title="Guadagni"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalRevenuesInPeriod - p.Costs)}
                        aggregateOn={(item) => item.Data.model.TotalRevenuesInPeriod - item.Data.model.Costs}
                        aggregationFormatter={totalsFormatter}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        moneyText: row.model.TotalRevenuesInPeriod - row.model.Costs,
                                        css: { "text-danger": row.model.TotalRevenuesInPeriod - row.model.Costs < 0 },
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={9}
                        title="Ricavi non assegnati"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.UnassignedTotalRevenues)}
                        aggregateOn={(item) => item.Data.model.UnassignedTotalRevenues}
                        aggregationFormatter={totalsFormatter}
                        forwardRef={(c) => (this.unassignedRevenuesColumn = c)}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.UnassignedTotalRevenues }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={10}
                        title="Costi lavoro"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalWorkCosts)}
                        aggregateOn={(item) => item.Data.model.TotalWorkCosts}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalWorkCosts }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={11}
                        title="Costi acquisti"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalPurchaseCosts)}
                        aggregateOn={(item) => item.Data.model.TotalPurchaseCosts}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalPurchaseCosts }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={12}
                        title="Costi magazzino"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalWarehouseCosts)}
                        aggregateOn={(item) => item.Data.model.TotalWarehouseCosts}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalWarehouseCosts }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={13}
                        title="Costi lavoro stimati a fine periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.EstimatedWorkCostsAtPeriodEnd)}
                        aggregateOn={(item) => item.Data.model.EstimatedWorkCostsAtPeriodEnd}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.EstimatedWorkCostsAtPeriodEnd }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={14}
                        title="Costi acquisti stimati a fine periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.EstimatedPurchasesCostsAtPeriodEnd)}
                        aggregateOn={(item) => item.Data.model.EstimatedPurchasesCostsAtPeriodEnd}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => (
                                <span data-bind={{ moneyText: row.model.EstimatedPurchasesCostsAtPeriodEnd }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={15}
                        title="Costi magazzino stimati a fine periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.EstimatedWarehouseCostsAtPeriodEnd)}
                        aggregateOn={(item) => item.Data.model.EstimatedWarehouseCostsAtPeriodEnd}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => (
                                <span data-bind={{ moneyText: row.model.EstimatedWarehouseCostsAtPeriodEnd }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={16}
                        title="Costi lavoro nel periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalWorkCostsInPeriod)}
                        aggregateOn={(item) => item.Data.model.TotalWorkCostsInPeriod}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalWorkCostsInPeriod }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={17}
                        title="Costi acquisti nel periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalPurchasesCostsInPeriod)}
                        aggregateOn={(item) => item.Data.model.TotalPurchasesCostsInPeriod}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalPurchasesCostsInPeriod }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={18}
                        title="Costi magazzino nel periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.TotalWarehouseCostsInPeriod)}
                        aggregateOn={(item) => item.Data.model.TotalWarehouseCostsInPeriod}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.TotalWarehouseCostsInPeriod }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={19}
                        title="Costi rimanenti a fine periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.RemainingCostsAtPeriodEnd)}
                        aggregateOn={(item) => item.Data.model.RemainingCostsAtPeriodEnd}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.RemainingCostsAtPeriodEnd }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={20}
                        title="Valore magazzino a fine periodo"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.WarehouseValueAtPeriodEnd)}
                        aggregateOn={(item) => item.Data.model.WarehouseValueAtPeriodEnd}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.WarehouseValueAtPeriodEnd }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={21}
                        title="Costi lavoro a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.WorkRealCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.WorkRealCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.WorkRealCostsAtDate }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={22}
                        title="Costi acquisti a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.PurchasesRealCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.PurchasesRealCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.PurchasesRealCostsAtDate }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={23}
                        title="Costi magazzino a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.WarehouseRealCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.WarehouseRealCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.WarehouseRealCostsAtDate }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={24}
                        title="Costi lavoro residui stimati a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.ExpectedWorkResidualCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.ExpectedWorkResidualCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.ExpectedWorkResidualCostsAtDate }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={25}
                        title="Costi acquisti residui stimati a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.ExpectedPurchasesResidualCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.ExpectedPurchasesResidualCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => (
                                <span data-bind={{ moneyText: row.model.ExpectedPurchasesResidualCostsAtDate }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={26}
                        title="Costi magazzino residui stimati a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.ExpectedWarehouseResidualCostsAtDate)}
                        aggregateOn={(item) => item.Data.model.ExpectedWarehouseResidualCostsAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => (
                                <span data-bind={{ moneyText: row.model.ExpectedWarehouseResidualCostsAtDate }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={27}
                        title="Ricavi da fatture (parte non chiusa) a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.InvoicesOpenedRevenuesAtDate)}
                        aggregateOn={(item) => item.Data.model.InvoicesOpenedRevenuesAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => <span data-bind={{ moneyText: row.model.InvoicesOpenedRevenuesAtDate }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={28}
                        title="Ricavi da DDT e ordini cliente (parte non chiusa) a oggi"
                        className="text-right bold"
                        sorter={sorter.sortNumber((p) => p.DDTsAncCustomerOrdersOpenedRevenuesAtDate)}
                        aggregateOn={(item) => item.Data.model.DDTsAncCustomerOrdersOpenedRevenuesAtDate}
                        aggregationFormatter={totalsFormatter}
                        visible={false}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        moneyText: row.model.DDTsAncCustomerOrdersOpenedRevenuesAtDate,
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                </Table>
            );
        };

        const onJobOrderSelect = (jobOrder: IJobOrderForTaskBoard, analysis: CompanyAnalysis) => {
            analysis.JobOrderId = jobOrder.Id;
            analysis.JobOrderName = jobOrder.Name;
            analysis.JobOrderCustomerName = jobOrder.CustomerName;
            analysis.JobOrderCustomerObs(jobOrder.CustomerName);
            analysis.JobOrderState = jobOrder.StateId;
            analysis.JobOrderStateObs(jobOrder.StateId);
            analysis.JobOrderType = jobOrder.Type;
            analysis.JobOrderTypeObs(jobOrder.Type);
        };

        const onJobOrderDeselect = (jobOrder: IJobOrderForTaskBoard, analysis: CompanyAnalysis) => {
            analysis.JobOrderId = null;
            analysis.JobOrderName = "";
            analysis.JobOrderCustomerName = "";
            analysis.JobOrderCustomerObs("");
            analysis.JobOrderState = null;
            analysis.JobOrderStateObs(null);
            analysis.JobOrderType = null;
            analysis.JobOrderTypeObs(null);
        };

        const updateTotals = () => {
            let totalCosts = 0;
            let totalRevenues = 0;

            for (const jo of this.DetailedEconomics()) {
                totalCosts +=
                    jo.TotalWorkCostsInPeriodObs() +
                    jo.TotalWarehouseCostsInPeriodObs() +
                    jo.TotalPurchaseCostsInPeriodObs();
                totalRevenues += jo.TotalRevenuesInPeriodObs();
            }

            this.TotalCosts(totalCosts);
            this.TotalRevenues(totalRevenues);
            this.TotalEarnings(totalRevenues - totalCosts);
        };

        const renderSnapshotEditableTable = () => {
            let row: IDataSourceModel<number, CompanyAnalysis>;
            return (
                <Table
                    showLoadingIndicator={this.loading}
                    compact
                    systemScrollable
                    dataSource={Table.defaultDataSource(this.DetailedEconomics, (e) => ({
                        id: e.JobOrderId,
                        title: e.JobOrderName,
                        model: e,
                    }))}
                    rowAs="row"
                    headerActions={renderSnapshotTableHeaderActions}
                    useHoverEffect
                    enableAggregators
                    className="editable"
                    selectRowsByCheckbox
                    selectableRows
                    id={ProlifeSdk.PlanningSnapshotTable}>
                    <Column
                        id={1}
                        title="Commessa"
                        className="job-order"
                        sorter={sorter.sortString((p) => p.JobOrderName)}
                        aggregateOn={(item) => item.Data.model.JobOrderName}>
                        <ColumnBody>
                            {(item: ITableItem<CompanyAnalysis>) => (
                                <div className="flex-container">
                                    <Select
                                        dataSource={this.JobOrdersDataSource}
                                        value={item.Data.model.JobOrderIdObs}
                                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                        allowClear
                                        onSelect={(jo: IJobOrderDataSourceModel) =>
                                            onJobOrderSelect(jo.model, item.Data.model)
                                        }
                                        onDeselect={(jo: IJobOrderDataSourceModel) =>
                                            onJobOrderDeselect(jo.model, item.Data.model)
                                        }
                                    />
                                    <button
                                        className="btn btn-primary btn-sm"
                                        style={{ marginLeft: "auto" }}
                                        data-bind={{ asyncClick: row.model.showJobOrderInfo.bind(row.model) }}>
                                        <i className="fa fa-info-circle"></i>
                                    </button>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={2}
                        title="Tipologia"
                        className="job-order-type-state"
                        sorter={sorter.sortString(
                            (p) => this.jobOrderTypes.getJobOrderTypeById(p.JobOrderTypeObs())?.Description
                        )}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        JobOrderTypeDisplayValue: { typeId: row.model.JobOrderTypeObs },
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={3}
                        title="Stato"
                        className="job-order-type-state"
                        sorter={sorter.sortString(
                            (p) => this.jobOrderStates.getJobOrderStateById(p.JobOrderStateObs())?.Description
                        )}>
                        <ColumnBody>
                            {() => (
                                <span
                                    data-bind={{
                                        JobOrderStateDisplayValue: { stateId: row.model.JobOrderStateObs },
                                    }}></span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column id={4} title="Cliente" sorter={sorter.sortString((p) => p.JobOrderCustomerObs())}>
                        <ColumnBody>
                            {() => <span data-bind={{ text: row.model.JobOrderCustomerObs }}></span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={10}
                        title="Costi lavoro"
                        className="text-right bold money"
                        sorter={sorter.sortNumber((p) => p.TotalWorkCostsInPeriodObs())}
                        aggregateOn={(item: ITableItem<CompanyAnalysis>) => item.Data.model.TotalWorkCostsInPeriodObs()}
                        aggregationFormatter={totalsFormatter}
                        defaultAggregation={TableFooterAggregationMode.Sum}>
                        <ColumnBody>
                            {(item: ITableItem<CompanyAnalysis>) => (
                                <MoneyInput
                                    value={item.Data.model.TotalWorkCostsInPeriodObs}
                                    simple
                                    onChange={(v) => updateTotals()}
                                />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={11}
                        title="Costi acquisti"
                        className="text-right bold money"
                        sorter={sorter.sortNumber((p) => p.TotalPurchaseCostsInPeriodObs())}
                        aggregateOn={(item: ITableItem<CompanyAnalysis>) =>
                            item.Data.model.TotalPurchaseCostsInPeriodObs()
                        }
                        aggregationFormatter={totalsFormatter}
                        defaultAggregation={TableFooterAggregationMode.Sum}>
                        <ColumnBody>
                            {(item: ITableItem<CompanyAnalysis>) => (
                                <MoneyInput
                                    value={item.Data.model.TotalPurchaseCostsInPeriodObs}
                                    simple
                                    onChange={(v) => updateTotals()}
                                />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={12}
                        title="Costi magazzino"
                        className="text-right bold money"
                        sorter={sorter.sortNumber((p) => p.TotalWarehouseCostsInPeriodObs())}
                        aggregateOn={(item: ITableItem<CompanyAnalysis>) =>
                            item.Data.model.TotalWarehouseCostsInPeriodObs()
                        }
                        aggregationFormatter={totalsFormatter}
                        defaultAggregation={TableFooterAggregationMode.Sum}>
                        <ColumnBody>
                            {(item: ITableItem<CompanyAnalysis>) => (
                                <MoneyInput value={item.Data.model.TotalWarehouseCostsInPeriodObs} simple />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={7}
                        title="Ricavi"
                        className="text-right bold money"
                        sorter={sorter.sortNumber((p) => p.TotalRevenuesInPeriodObs())}
                        aggregateOn={(item: ITableItem<CompanyAnalysis>) => item.Data.model.TotalRevenuesInPeriodObs()}
                        aggregationFormatter={totalsFormatter}
                        defaultAggregation={TableFooterAggregationMode.Sum}>
                        <ColumnBody>
                            {(item: ITableItem<CompanyAnalysis>) => (
                                <MoneyInput
                                    value={item.Data.model.TotalRevenuesInPeriodObs}
                                    simple
                                    onChange={(v) => updateTotals()}
                                />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title="Azioni" className="text-right actions">
                        <ColumnHeader>
                            {() => (
                                <button className="btn btn-primary btn-xs" data-bind={{ click: _bv.addRow.bind(_bv) }}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {() => (
                                <button
                                    className="btn btn-danger btn-xs"
                                    data-bind={{ asyncClick: _bv.removeRow.bind(_bv, row.model) }}>
                                    <i className="fa fa-trash-o"></i>
                                </button>
                            )}
                        </ColumnBody>
                    </Column>
                </Table>
            );
        };

        return ComponentUtils.bindTo(
            <Layout.Grid
                className={classes.budgetViewerDashboard}
                columns={["1fr"]}
                rows={["100px", "225px", "1fr"]}
                noOverflow
                style={{ position: "absolute", inset: "15px" }}>
                <Layout.Grid.Cell row={1} column={1}>
                    <div className="dashboard-stat green-haze flex-1">
                        <div className="visual"></div>
                        <div className="details" style={{ left: "15px", paddingLeft: "15px" }}>
                            <div className="row">
                                <div className="col-md-6 number" style={{ marginTop: "-5px" }}>
                                    <div className="flex-container">
                                        <CheckBox
                                            checked={this.UseSnapshot}
                                            label="Usa Snapshot"
                                            readOnly={this.DisableUseSnapshotFlag}
                                        />
                                        <span style={{ marginLeft: "auto" }}>
                                            {TextResources.Planning.AnalisysPeriod}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-5 number">
                                                <If condition={() => this.UseSnapshot()}>
                                                    {() => (
                                                        <Select
                                                            className="dashboard-input"
                                                            value={this.SnapshotId}
                                                            dataSource={this.PlanningSnapshotsDataSource}
                                                            placeholder={
                                                                TextResources.Planning.SnapshotSelectorPlaceholder
                                                            }
                                                            onSelect={onParentSnapshotSelected}
                                                            onDeselect={onParentSnapshotDeselected}
                                                        />
                                                    )}
                                                </If>
                                                <IfNot condition={() => this.UseSnapshot()}>
                                                    {() => (
                                                        <input
                                                            type="text"
                                                            className="form-control dashboard-input"
                                                            data-bind={{ datePicker: _bv.StartDate }}
                                                        />
                                                    )}
                                                </IfNot>
                                            </div>
                                            <div className="col-md-5 number">
                                                <ko-if data-bind="_bv.DataEditingEnabled">
                                                    <input
                                                        type="text"
                                                        className="form-control dashboard-input"
                                                        data-bind={{ nullableDatePicker: _bv.EndDate }}
                                                    />
                                                </ko-if>
                                                <ko-ifnot data-bind="_bv.DataEditingEnabled">
                                                    <input
                                                        type="text"
                                                        className="form-control dashboard-input"
                                                        data-bind={{ datePicker: _bv.EndDate }}
                                                    />
                                                </ko-ifnot>
                                            </div>
                                            <div
                                                className="col-md-2"
                                                style={{ marginTop: "20px", textAlign: "left", marginLeft: "-10px" }}>
                                                <button
                                                    className="btn btn-primary"
                                                    data-bind={{ click: _bv.Refresh.bind(_bv) }}>
                                                    <i className="fa fa-refresh"></i>
                                                    {TextResources.Planning.Refresh}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <div className="row flex-1">
                        <div className="col-md-4">
                            <div className="dashboard-stat red-intense" style={{ height: "200px" }}>
                                <div className="visual">
                                    <div
                                        id="smallChartDiv"
                                        style={{ height: "180px", width: "250px" }}
                                        data-bind={{ highChart: { data: _bv.costsChartData } }}></div>
                                </div>
                                <div className="details">
                                    <div className="number" data-bind={{ moneyText: _bv.TotalCosts }}></div>
                                    <div className="desc">{TextResources.Planning.Costs}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="dashboard-stat blue-madison"
                                style={{ height: "200px", position: "relative" }}>
                                <div className="visual">
                                    <i className="fa fa-clock-o fa-icon-medium"></i>
                                </div>
                                <div className="details">
                                    <div className="number" data-bind={{ moneyText: _bv.TotalRevenues }}></div>
                                    <div className="desc">{TextResources.Planning.ExpectedRevenues}</div>
                                </div>
                                <div
                                    className="flex-container flex-vertical"
                                    style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                                    <button
                                        className="btn btn-default btn-circle btn-xs"
                                        data-bind={{
                                            click: _bv.StrictlyToPeriod.bind(_bv, 0),
                                            css: { blue: _bv.StrictlyToPeriod() === 0 },
                                        }}
                                        title={TextResources.Planning.DocumentsDatePeriodTooltip}>
                                        {TextResources.Planning.DocumentsDate}
                                    </button>
                                    <button
                                        className="btn btn-default btn-circle btn-xs"
                                        data-bind={{
                                            click: _bv.StrictlyToPeriod.bind(_bv, 2),
                                            css: { blue: _bv.StrictlyToPeriod() === 2 },
                                        }}
                                        title={TextResources.Planning.WorkProgressPeriodTooltip}>
                                        {TextResources.Planning.WorkProgress}
                                    </button>
                                    <button
                                        className="btn btn-default btn-circle btn-xs"
                                        data-bind={{
                                            click: _bv.StrictlyToPeriod.bind(_bv, 1),
                                            css: { blue: _bv.StrictlyToPeriod() === 1 },
                                        }}
                                        title={TextResources.Planning.ProgressWithRelateWorkflowsTooltip}>
                                        {TextResources.Planning.ProgressWithRelateWorkflows}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dashboard-stat green-haze" style={{ height: "200px" }}>
                                <div className="visual">
                                    <i className="fa fa-money fa-icon-medium"></i>
                                </div>
                                <div className="details">
                                    <div className="number" data-bind={{ moneyText: _bv.TotalEarnings }}></div>
                                    <div className="desc">{TextResources.Planning.ExpectedMarginProfit}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={3} column={1} className="flex-vertical">
                    <IfNot condition={this.DataEditingEnabled}>{() => renderStandardTable()}</IfNot>
                    <If condition={this.DataEditingEnabled}>{() => renderSnapshotEditableTable()}</If>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "_bv"
        );
    }
}
