import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { Portlet } from "./PortletComponent";
import { LazyImport } from "../../Core/DependencyInjection";
import { IModulesService } from "../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IApplicationsConfigurationsService, IApplicationConfiguration } from "../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IApplicationHost } from "../../Desktop/interfaces/IApplicationHost";
import { ComponentUtils } from "../../Core/utils/ComponentUtils";

const { classes } = jss.createStyleSheet({
    "keyboard-panel": {
        width: "100%",
        "background": "aliceblue",

        "& .portlets-wrapper": {
            width: "calc(100% - 270px)",
            padding: "15px"
        },

        "& .keyboard-wrapper": {
            width: "270px",
            "background-color": "white",
            "margin-bottom": "0px",
            "border-left": "1px solid #ddd",

            "& .keyboard": {

                "& .collapse": {
                    padding: "0px"
                },

                "& .keyboard-title": {
                    "padding": "0px 10px",
                    "margin-top": "5px",
                    "border-bottom": "1px solid #ddd"
                },

                "& .navbar-nav": {
                    float: "none",

                    "& li": {
                        "width": "100%",
                        "padding": "10px 10px"
                    },

                    "& .actions-separator": {
                        "margin-top": "0px",
                        "border-bottom": "1px solid #ddd"
                    },

                    "& .btn-general-data": {
                        "background-color": "#e5f1fb"
                    },

                    "& .defaults-button": {
                        width: "100%"
                    },

                    "& .btn-open-close": {
                        height: "34px"
                    },

                    "& .btn-scroller": {
                        "width": "calc(100% - 32px)",
                        "text-align": "left",
                        "-ms-text-overflow": "ellipsis",
                        "-o-text-overflow": "ellipsis",
                        "text-overflow": "ellipsis",
                        "overflow": "hidden",
                        "white-space": "nowrap",

                        "& i": {
                            "margin-right": "10px"
                        }
                    }
                }
            }
        }
    }
}).attach();

interface IPortletConfiguration {
    name: string;
    opened: boolean;
}

export interface IKeyboardPanelComponentParams {
    cssClasses?: string;
    children?: ReactNode;
}

export interface IPortletDriver {
    Scroller: ko.Observable<boolean>;
    Opened: ko.Observable<boolean>;
}

export class KeyboardPanel {
    public Portlets: ko.ObservableArray<IPortletDriver> = ko.observableArray([]);

    @LazyImport(nameof<IModulesService>())
    protected modulesService : IModulesService;
    @LazyImport(ProlifeSdk.ApplicationsConfigurationsServiceCode)
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImport(nameof<IApplicationHost>())
    private applicationsHostService: IApplicationHost;

    private configuration: IApplicationConfiguration;

    constructor(private props : IKeyboardPanelComponentParams) {

    }

    componentDidMount() {
        this.loadSectionsSettings();
    }
    
    /*public addPortletConfiguration(portletName: string, portletConfig: IPortletDriver): void {
        let portlets = this.Portlets();
        portlets[portletName] = portletConfig;
        this.Portlets(portlets);
    }*/

    public scrollTo(portletName: string): void {
        this.Portlets()[portletName].Scroller(!this.Portlets()[portletName].Scroller());
    }

    public switchOpenedState(portletName: string): void {
        this.Portlets()[portletName].Opened(!this.Portlets()[portletName].Opened());
    }

    public async saveSectionsConfig(): Promise<void> {
        const portlets = this.Portlets();

        const configs = [];

        for (const portletName in portlets) {
            const portlet = portlets[portletName];
            configs.push({ name: portletName, opened: portlet.Opened() });
        }

        const configToBeSaved: IApplicationConfiguration = this.configuration ? $.extend(true, {}, this.configuration) : {
            ConfigurationId: null,
            Configuration: JSON.stringify(configs),
            ConfigurationType: ProlifeSdk.KeyboardPanelConfigurationType,
            UserID: this.userInfoService.getIdUser(),
            ModuleId: await this.modulesService.getModuleId(this.applicationsHostService.getCurrentApplicationCode())
        };
        
        if (!configToBeSaved.ModuleId) {
            return;
        }

        configToBeSaved.Configuration = JSON.stringify(configs);
        await this.applicationsConfigurationsService.AddOrUpdateConfiguration(configToBeSaved);
    }
    
    public async loadSectionsSettings(): Promise<void> {
        const moduleId: number = await this.modulesService.getModuleId(this.applicationsHostService.getCurrentApplicationCode());

        if (!moduleId)
            return;

        const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.KeyboardPanelConfigurationType, this.userInfoService.getIdUser(), moduleId, null);

        if (!configurations || !configurations[0] || !configurations[0].Configuration)
            return;

        this.configuration = configurations[0];
        const savedConfig: IPortletConfiguration[] = JSON.parse(this.configuration.Configuration);

        const portlets = this.Portlets();
        for (const config of savedConfig) {
            portlets[config.name].Opened(config.opened);
        }
    }

    private renderPortlets() {
        const childs = (Array.isArray(this.props.children) ? this.props.children : [this.props.children]) as React.ReactNode<Portlet>[];
        const portlets = [];
        const portletsMap = this.Portlets();

        for (const child of childs.filter(c => ComponentUtils.isOfType(c, Portlet))) {
            portlets.push(child);

            const c = ComponentUtils.getComponent<Portlet>(child);
            portletsMap[c.Name] = { Opened: ko.observable(true), Scroller: ko.observable(true) };
            
        }
        this.Portlets(portletsMap);
        
        return portlets;
    }

    private renderButtons() {
        const childs = (Array.isArray(this.props.children) ? this.props.children : [this.props.children]) as React.ReactNode<Portlet>[];
        const portlets = [];

        for (const child of childs.filter(c => ComponentUtils.isOfType(c, Portlet))) {
            const c = ComponentUtils.getComponent<Portlet>(child);
            portlets.push(c.renderButtons());
        }
        
        return portlets;
    }

    render() {
        
        const css = this.props.cssClasses || "";
        const nodes = (
            <div class={"flex-container flex-full-height " + css + " " + classes["keyboard-panel"]}>
                <div class="portlets-wrapper" data-bind="slimScroll: '100%'">
                    {this.renderPortlets()}
                </div>
                <div class="keyboard-wrapper">
                    <div class="navbar keyboard">
                        <div class="collapse navbar-collapse">
                            <h3 class="keyboard-title">{TextResources.ProlifeSdk.KeyboardPanelSectionsTitle}</h3>
                            <ul class="nav navbar-nav">
                                {this.renderButtons()}
                                <li class="actions-separator">
                                    &nbsp;
                                </li>
                                <li class="text-right">
                                    <button class="btn defaults-button" data-bind="click: $data.saveSectionsConfig.bind($data)">
                                        {TextResources.ProlifeSdk.KeyboardPanelSaveConfiglabel}
                                    </button>
                                </li>
                                <li class="text-right">
                                    <button class="btn defaults-button" data-bind="click: $data.loadSectionsSettings.bind($data)">
                                        {TextResources.ProlifeSdk.KeyboardPanelLoadConfiglabel}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );

        return ComponentUtils.bindTo(nodes, this);
    }
}