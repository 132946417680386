import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { EstimateEventDetailsEdit } from "./details/EstimateEventDetailsEdit";
import { IEstimatesService } from "../../../../ProlifeSdk/interfaces/invoice/IEstimatesService";
import { IEstimate, IEstimateRow } from "../../../../ProlifeSdk/interfaces/invoice/IEstimate";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class EstimateEvent extends DocumentEvent implements IView
{
    private estimatesService : IEstimatesService;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.EstimateEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.Invoices.Quote;
        this.estimatesService = <IEstimatesService>serviceLocator.findService(ProlifeSdk.EstimatesServiceType);
    }

    public getEditViewModel(): IEventBaseInput
    {
        return new EstimateEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.LoadDocumentInfo();
    }

    public LoadDocumentInfo(): Promise<void>
    {
        var def: Deferred<void> = new Deferred();
        
        this.estimatesService.getDocumentById(this.DocumentId())
            .then((d: IEstimate) => {
                if(!d)
                    return;

                this.Total(d.TotalePreventivo);

                this.estimatesService.getDocumentRowsById(this.DocumentId())
                    .then((r : IEstimateRow[]) => {
                        this.NumberOfRows(r ? r.length : 0);
                    });
            })
            .finally(() => {
                def.resolve();
            });
        
        return def.promise();
    }
}