import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { JobOrderStatesEntityProvider } from "../../entityProviders/JobOrderStatesEntityProvider";
import { TypeWithCounterSelectionMenu } from "../navigation/TypeWithCounterSelectionMenu";
import { StateWithCounterSelectionMenu } from "../navigation/StateWithCounterSelectionMenu";
import { SplashPageList } from "./SplashPageList";
import { SplashPageTableView } from "./SplashPageTableView";
import { SplashPageColumnsView } from "./SplashPageColumnsView";
import { WorkflowsSearchEntityProvider } from "../../../../Todolist/Todolist/entity-providers/WorkflowsSearchEntityProvider";
import { IJobOrdersSplashPageFilters } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import {
    IStateAndTypeMenuObserver,
    INavigationFilters,
    INavigationMultipleFilters,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { WorkflowsAnalysisModal } from "./WorkflowsAnalysis";

export class SplashPage implements IStateAndTypeMenuObserver {
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService: IEntityProviderService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    public templateUrl = "joborder/templates";
    public templateName = "splash-page";
    public navigationMenu: TypeWithCounterSelectionMenu;
    public stateNavigationMenu: StateWithCounterSelectionMenu;

    public SearchFilters: ko.Observable<string> = ko.observable("");
    public HintSearchProvider: IControlsCustomEntitiesProvider;

    public JobOrdersCount: ko.Observable<number> = ko.observable();

    public View: ko.Observable<any> = ko.observable();

    ExporterId = "JobOrder/AdvancedExport";
    ExporterMethod = "GenerateExcel";
    JobOrdersExporterId = "JobOrder/JobOrdersExport";
    ActivitiesExporterId = "JobOrder/JobOrdersActivitiesExport";

    constructor(private serviceLocator: IServiceLocator) {
        const hintSearchGroupsInfo: IHintSearchGroupInfo[] = [];
        hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.JobOrder.Customer,
            Provider: this.entitiesProviderService.getEntityProvider(ProlifeSdk.CustomerEntityType),
            GroupCode: "C",
        });

        hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.JobOrder.Manager,
            Provider: this.entitiesProviderService.getEntityProvider(ProlifeSdk.HumanResources),
            GroupCode: "R",
        });

        hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.JobOrder.Status,
            Provider: new JobOrderStatesEntityProvider(serviceLocator),
            GroupCode: "S",
        });

        hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.Todolist.Workflows,
            Provider: new WorkflowsSearchEntityProvider(),
            GroupCode: "W",
        });

        this.HintSearchProvider = this.entitiesProviderService.GetCustomEntitiesProviderForControls(
            hintSearchGroupsInfo,
            true,
            false
        );

        this.navigationMenu = new TypeWithCounterSelectionMenu(serviceLocator);
        this.navigationMenu.setSingleSelectionStatus(false, true);
        this.navigationMenu.multipleSelectionEnabled(true);
        this.navigationMenu.RegisterStateAndTypeObserver(this);

        this.stateNavigationMenu = new StateWithCounterSelectionMenu(serviceLocator);
        this.stateNavigationMenu.setSingleSelectionStatus(false, true);
        this.stateNavigationMenu.multipleSelectionEnabled(true);
        this.stateNavigationMenu.RegisterStateAndTypeObserver(this);

        this.SearchFilters.subscribe(this.Refresh.bind(this));

        this.showListView();
    }

    public async showWorkflowsAnalysis() {
        await this.dialogsService.ShowModal(new WorkflowsAnalysisModal());
    }

    public getJobOrdersExcelExportData(): any | null {
        return {
            filter: JSON.stringify(this.GetFilters()),
        };
    }

    public getActivitiesExcelExportData(): any | null {
        const { LogicalStateId, StateId, TypeId } = this.GetFilters();

        return {
            logicalStateId: LogicalStateId,
            stateId: StateId,
            typeId: TypeId,
        };
    }

    public GetExcelExporterData() {
        return {
            Date: new Date(),
        };
    }

    public dispose() {
        this.View().dispose();
    }

    public goHome(): void {
        location.href = "#/";
    }

    public newCommessa(): void {
        location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/-1";
    }

    public Initialize(): Promise<void> {
        return this.navigationMenu.refreshItems();
    }

    OnNavigationFilterIsChanged(filters: INavigationFilters) {
        this.Refresh();
    }

    public GetFilters(): IJobOrdersSplashPageFilters {
        const filters: IItemIdWithTypeAndGroupCode[] = this.HintSearchProvider.extractItemsAndTypesFromStringValue(
            this.SearchFilters()
        );

        const words: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "$$HINTWORD$$";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });

        const workflows: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => f.GroupCode == "W")
            .map((f: IItemIdWithTypeAndGroupCode) => f.Id);

        const responsiblesIds: number[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => f.GroupCode == "R")
            .map((f: IItemIdWithTypeAndGroupCode) => f.Id);

        const customersIds: number[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => f.GroupCode == "C")
            .map((f: IItemIdWithTypeAndGroupCode) => f.Id);

        const statesIds: number[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => f.GroupCode == "S")
            .map((f: IItemIdWithTypeAndGroupCode) => f.Id);

        /* const navigationFilters: INavigationFilters = this.navigationMenu.GetFiltersFromActualPosition();
        const statesFilters: INavigationFilters = this.stateNavigationMenu.GetFiltersFromActualPosition();

        navigationFilters.LogicalState = statesFilters.LogicalState;
        navigationFilters.State = statesFilters.State; */

        const navigationMultipleFilters: INavigationMultipleFilters =
            this.navigationMenu.GetMultipleFiltersFromActualPosition();
        const statesMultipleFilters: INavigationMultipleFilters =
            this.stateNavigationMenu.GetMultipleFiltersFromActualPosition();

        navigationMultipleFilters.LogicalState = statesMultipleFilters.LogicalState;
        navigationMultipleFilters.States = statesMultipleFilters.States;

        return {
            TextFilters: words,
            WorkflowsFilters: workflows,
            ResponsiblesIds: responsiblesIds,
            CustomersIds: customersIds,
            StatesIds: statesIds,

            TypeId: -1, //navigationFilters.Type,
            StateId: -1, //navigationFilters.State,

            LogicalStateId: navigationMultipleFilters.LogicalState,
            Types: navigationMultipleFilters.Types,
            States: navigationMultipleFilters.States,
        };
    }

    Refresh() {
        this.View().Refresh();
    }

    public showTableView() {
        this.switchView(new SplashPageTableView(this.serviceLocator, this));
    }

    public showListView() {
        this.switchView(new SplashPageList(this.serviceLocator, this));
    }

    public showColumnsView() {
        this.switchView(new SplashPageColumnsView(this.serviceLocator, this));
    }

    private switchView(newView) {
        if (this.View()) this.View().dispose();
        this.View(newView);
    }
}
