import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../../../../Core/utils/ComponentUtils";
import { IEstimatedBudgetForTaskState } from "../../../interfaces/IEstimatedBudgetForTaskStatesService";
import { EstimatedBudgetForTaskStatesDataSource, IEstimatedBudgetForTaskStatesDataSourceModel } from "../../../../DataSources/EstimatedBudgetForTaskStatesDataSource";
import { IDataSourceListener } from "../../../../DataSources/IDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

let attributes = {
    Value: "value",
    SelectedState: "selectedState"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "estimated-budget-for-task-state-popover": {
                params?: {
                    Value: string,
                    SelectedState?: string
                };
                
                value: number | (() => string);
                selectedState?: IEstimatedBudgetForTaskState | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IEstimatedBudgetForTaskStatePopoverParams {
    Value: Param<number>;
    SelectedState: Param<IEstimatedBudgetForTaskState>;
}

export class EstimatedBudgetForTaskStatePopover implements IDataSourceListener {
    Value: ComponentParam<number>;
    SelectedState: ComponentParam<IEstimatedBudgetForTaskState>;

    DataSource: EstimatedBudgetForTaskStatesDataSource = new EstimatedBudgetForTaskStatesDataSource();

    constructor(params : IEstimatedBudgetForTaskStatePopoverParams) {
        this.Value = ComponentUtils.parseParameter(params.Value, null);
        this.SelectedState = ComponentUtils.parseParameter(params.SelectedState, null);
    }
    
    onItemSelected(sender: EstimatedBudgetForTaskStatesDataSource, model: IEstimatedBudgetForTaskStatesDataSourceModel): void {
        let state = model?.model?.getData();
        
        this.Value(state?.Id);
        this.SelectedState(state);
    }
    
    onItemDeselected(sender: EstimatedBudgetForTaskStatesDataSource, model: IEstimatedBudgetForTaskStatesDataSourceModel): void {
        this.Value(null);
        this.SelectedState(null);
    }
}

ko.components.register("estimated-budget-for-task-state-popover", {
    viewModel: {
        createViewModel: (params: IEstimatedBudgetForTaskStatePopoverParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new EstimatedBudgetForTaskStatePopover(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div style="width: 300px">
                    <select2 value={() => "Value"} dataSource={() => "DataSource"} allowClear={true} simple={true} placeholder={TextResources.ProlifeSdk.Select2Placeholder} listener={() => "$data"}></select2>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});