import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import "./ui/WorkflowOutcomeSettingsViewModel";
import { IWorkflowOutcomesService, IWorkflowOutcome } from "../../WorkflowOutcomesService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IWorkflowOutcomeSettingsManager } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WorkflowOutcomeSettingsManager implements IWorkflowOutcomeSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IWorkflowOutcomesService>())
    private workflowOutcomeService : IWorkflowOutcomesService;
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    
    private outcomes : IWorkflowOutcome[] = [];

    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.TasksSettingsGroup);
    }

    load(): Promise<IWorkflowOutcome[]>
    {
        return this.workflowOutcomeService.GetWorkflowOutcomes()
            .then(outcomes => this.outcomes = outcomes);
    }

    getName(): string {
        return ProlifeSdk.WorkflowOutcomes;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Todolist.WorkflowOutcomes;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) ||
            this.modulesService.IsModuleEnabled(ProlifeSdk.PersonalTodolistApplicationCode);
    }

    getEditingUI(): IView {
        return { //Non usiamo più il viewmodel, adesso usiamo il componente
            component: 'workflow-outcomes-editor',
            templateName: "",
            templateUrl: '',
            viewModel: null//new WorkflowOutcomeSettingsViewModel(this.serviceLocator, this)
        };
    }

    getOutcomes(getDeleted : boolean = false, skip : number = 0, count : number = 1000000) : IWorkflowOutcome[] {
        let outcomes =  getDeleted ? this.outcomes : this.outcomes.filter((s) => { return !s.Deleted; });
        return outcomes.slice(skip, skip + count);
    }

    getOutcomeById(id : number) : IWorkflowOutcome
    {
        var outcomes = this.outcomes.filter((s : IWorkflowOutcome) => s.Id == id);
        if(outcomes.length == 0) return null;
        return outcomes[0];
    }

    getOutcomeByIds(ids : number[]) : IWorkflowOutcome[] {
        return this.outcomes.filter(o => ids.indexOf(o.Id) != -1);
    }

    async moveOutcome(outcomeId : number, newPosition : number) : Promise<void>
    {
        await this.workflowOutcomeService.MoveWorkflowOutcome(outcomeId, newPosition);
        await this.load();
    }

    async createOrUpdate(outcome : IWorkflowOutcome) : Promise<IWorkflowOutcome> {
        let outcomes = await this.workflowOutcomeService.InsertOrUpdateWorkflowOutcomes([outcome]);
        await this.load();
        return outcomes.length > 0 ? outcomes[0] : null;
    }

    async remove(id : number) : Promise<void>
    {
        if(!id) return;

        await this.workflowOutcomeService.DeleteWorkflowOutcomes([id]);
        await this.load();
    }
}