import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { TaskBoard, ITaskBoard } from "./TaskBoard";
import { ChangeStateDialog } from "../tasks-list/dialogs/ChangeStateDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IBarCodeScannerService } from "../../../../WorkedHours/BarCodeScannerService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ITodoListService, ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask, ITodoListTask, ITodoListTaskTag } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ITaskBoardAdvancedFilterDialogResult } from "../../../interfaces/ITaskBoardAdvancedFilterDialog";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IFileRepositoryService } from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../../../../ProlifeSdk/interfaces/files/IFileOrFolder";
import { IAjaxServiceNew } from "../../../../Core/interfaces/IAjaxService";

export class TaskBoardColumn {
    @LazyImport(nameof<IUserInfo>())
    private userInfo!: IUserInfo;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public UnfilterdTasks: ko.ObservableArray<TaskForTaskBoard> = ko.observableArray([]);
    public Tasks: ko.Computed<TaskForTaskBoard[]>;
    public isCollapsed: ko.Observable<boolean> = ko.observable(false);
    public columnWidth: ko.Observable<number> = ko.observable();
    public columnOffset: ko.Observable<number> = ko.observable();

    public DoneWork: ko.Computed<number>;
    public EstimatedWork: ko.Computed<number>;

    public SelectedState: ko.Observable<TaskBoardColumn> = ko.observable();
    public IsSelected: ko.Computed<boolean>;

    constructor(
        private taskBoard: TaskBoard,
        public status: number,
        public title: string,
        columnWidth: number,
        columnOffset: number,
        public icon: string,
        public iconColor: string,
        private dialog: ChangeStateDialog = null
    ) {
        this.columnWidth(columnWidth);
        this.columnOffset(columnOffset);

        this.Tasks = ko.computed(() => {
            const searchFilter = this.taskBoard.SearchFilter() || "";
            const filter = searchFilter.split(" ").filter((f) => (f || "").trim().length > 0);
            if (filter.length == 0) return this.UnfilterdTasks();
            return this.UnfilterdTasks().filter((t: TaskForTaskBoard) => t.MatchesFilter(filter));
        });

        this.DoneWork = ko.computed(() => {
            let totalWork = 0;
            this.Tasks().forEach((t: TaskForTaskBoard) => {
                totalWork += t.task.WorkedHours;
            });
            return totalWork;
        });

        this.EstimatedWork = ko.computed(() => {
            let estimatedWork = 0;
            this.Tasks().forEach((t: TaskForTaskBoard) => {
                estimatedWork += t.task.EstimatedDuration;
            });
            return estimatedWork;
        });

        this.IsSelected = ko.computed(() => {
            if (this.SelectedState()) return this.SelectedState().status == this.status;
            return false;
        });

        this.LoadTasks();
    }

    ContainsTask(taskId: number, isTask: boolean) {
        return this.UnfilterdTasks().some((t) => t.task.Id === taskId && t.task.IsTask === isTask);
    }

    public IsInThisState(): boolean {
        return this.dialog.TaskToChangeState().Status() == this.status;
    }

    public SetSelectedState(element) {
        this.SelectedState(element);
    }

    public MoveToState(taskId: number, isTask: boolean) {
        if (this.SelectedState())
            this.todoListService.MoveTaskOnTaskBoard(
                taskId,
                isTask,
                this.SelectedState().status,
                this.taskBoard.UserId(),
                this.taskBoard.selectedWorkflow().Id,
                null,
                null,
                null
            );
    }

    public Clear() {
        this.UnfilterdTasks([]);
    }

    public LoadTasks() {
        this.UnfilterdTasks([]);

        if (!this.taskBoard.selectedWorkflow()) {
            this.UnfilterdTasks([]);
            return;
        }

        const workflowId: number = this.taskBoard.selectedWorkflow().Id;

        const advancedFilters: ITaskBoardAdvancedFilterDialogResult = this.taskBoard.AdvancedFilter();

        const params = {
            textFilter: null,
            userId: this.taskBoard.UserId(),
            resourceId: null,
            workflows: [workflowId],
            statusIds: [this.status],
            viewWorker: this.taskBoard.ViewWorker(),
            viewResponsible: this.taskBoard.ViewResponsible(),
            skip: 0,
            count: 1000000,
            viewAll: this.taskBoard.ViewAll(),
            hideNoWork: this.taskBoard.HideNoWork(),
            destinationTaskBoardStatus: !advancedFilters ? null : advancedFilters.DestinationStatus,
            startDate: !advancedFilters ? null : advancedFilters.StartDate,
            endDate: !advancedFilters ? null : advancedFilters.EndDate,
            showClosed: true,
            getTasksIfNoFilter: false,
            getWorkflowsInWorkflows: true,
            searchOnWorkflowTitle: false,
        };

        this.todoListService.GetTasksForUser(params).then((tasks: ITaskForTaskBoard[]) => {
            const tasksVM = tasks.map((t: ITaskForTaskBoard) => {
                return new TaskForTaskBoard(t, this, this.taskBoard);
            });
            this.UnfilterdTasks(tasksVM);
        });
    }

    public SelectAllTasks(): void {
        this.Tasks().forEach((t) => {
            t.Select(this, { ctrlKey: true } as any);
        });
    }

    public RemoveTask(taskId: number, isTask: boolean) {
        const [task] = this.UnfilterdTasks().filter(
            (t: TaskForTaskBoard) => t.task.Id == taskId && t.task.IsTask == isTask
        );
        if (task) this.UnfilterdTasks.remove(task);
        return task;
    }

    public AddTask(task: ITaskForTaskBoard) {
        let indexOfNewTask = 0;

        this.UnfilterdTasks().forEach((t: TaskForTaskBoard) => {
            indexOfNewTask =
                t.task.PositionIndex < task.PositionIndex ? this.UnfilterdTasks().indexOf(t) + 1 : indexOfNewTask;
        });

        this.UnfilterdTasks.splice(indexOfNewTask, 0, new TaskForTaskBoard(task, this, this.taskBoard));
    }

    public OnTaskDropped(dataTransfer: DataTransfer, task: TaskForTaskBoard, before: boolean): void {
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0) {
            const draggedTasks: IDraggedTask[] = JSON.parse(dataTransfer.getData("application/prolife-tasks"));
            this.moveTasksState(draggedTasks, task, before);
        } else if (dataTransfer.types.indexOf("application/prolife-task") >= 0) {
            const data = dataTransfer.getData("application/prolife-task");
            const droppedTask: IDraggedTask = JSON.parse(data);
            this.moveTasksState([droppedTask], task, before);
        }
    }

    private async moveTasksState(droppedTasks: IDraggedTask[], task: TaskForTaskBoard, before: boolean): Promise<void> {
        const companyGuids = droppedTasks.map((t) => t.CompanyGuid).distinct();
        if (companyGuids.length !== 1 || this.userInfo.getCurrentCompanyGuid() !== companyGuids.firstOrDefault())
            return;

        const jobOrders = droppedTasks.map((t) => t.JobOrderId).distinct();
        if (
            jobOrders.length !== 1 ||
            !this.taskBoard.selectedJobOrder() ||
            jobOrders.firstOrDefault() != this.taskBoard.selectedJobOrder().Id
        )
            return;

        if (!this.taskBoard.selectedWorkflow()) return;

        if (!task && this.Tasks().length > 0) {
            task = this.Tasks()[this.Tasks().length - 1];
        }

        if (this.status < 4) {
            //Se non sono la colonna Sospesi/Eliminati
            await this.dropElements(droppedTasks, task, before);
            return;
        }

        const hasBillableHoursRequests = [];
        for (const dt of droppedTasks) hasBillableHoursRequests.push(this.hasBillableHours(dt.TaskId, dt.IsTask));

        const hasBillableHoursResults = await Promise.all(hasBillableHoursRequests);

        if (!hasBillableHoursResults.firstOrDefault((r) => r)) {
            await this.dropElements(droppedTasks, task, before);
            return;
        }

        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Todolist.SureToChangeTaskStateWithBillableHours,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
            ProlifeSdk.TextResources.Todolist.DeleteTask
        );

        if (!confirm) return;

        await this.dropElements(droppedTasks, task, before);
    }

    /*public OnTaskDropped(event, ui)
    {
        var taskId : number = parseInt(ui.draggable.attr("taskId"));
        var isTask : string = ui.draggable.attr("isTask");

        if(this.status < 4) { //Se non sono la colonna Sospesi/Eliminati
            this.dropElement(taskId, ui);
            return;
        }

        this.hasBillableHours(taskId, isTask)
            .then((result: boolean) => {
                if (!result) {
                    this.dropElement(taskId, ui);
                    return;
                }

                this.dialogsService.Confirm(
                    ProlifeSdk.TextResources.Todolist.SureToChangeTaskStateWithBillableHours,
                    ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
                    ProlifeSdk.TextResources.Todolist.DeleteTask,
                    (confirm: boolean) => {
                        if (!confirm)
                            return;

                        this.dropElement(taskId, ui);
                    }
                );
            });
    }*/

    public OnTaskMoved() {}

    private async dropElements(droppedTasks: IDraggedTask[], task: TaskForTaskBoard, before: boolean): Promise<void> {
        const destWorkflowId = this.taskBoard.selectedWorkflow() ? this.taskBoard.selectedWorkflow().Id : null;
        const destTaskId = task ? task.task.Id : null;
        const destTaskIsTask = task ? task.task.IsTask : null;
        const destBefore = task ? before : null;

        const oldStatus = droppedTasks.firstOrDefault()?.TaskBoardStatus;

        if (this.status == oldStatus) {
            await this.todoListService.MoveTasksOnTaskBoard(
                droppedTasks.map((t) => ({ taskId: t.TaskId, isTask: t.IsTask })),
                this.status,
                this.taskBoard.UserId(),
                destWorkflowId,
                destTaskId,
                destTaskIsTask,
                destBefore
            );
        } else {
            const response = await this.todoListService.TasksCanChangeState(droppedTasks.map((dt) => dt.TaskId));
            if (!response.succeeded) {
                this.infoToastService.Error(TextResources.Todolist.MoveTasksError);
                return;
            }

            let confirm = true;
            const blockedTasks = response.data.filter((t) => !t.canChangeState);
            if (blockedTasks.length > 0) {
                confirm = await this.dialogsService.ConfirmAsync(
                    ProlifeSdk.TextResources.Todolist.TaskIsNotReady,
                    ProlifeSdk.TextResources.Todolist.DoNotMove,
                    ProlifeSdk.TextResources.Todolist.ContinueMove
                );
            }

            if (!confirm) return;

            await this.todoListService.MoveTasksOnTaskBoard(
                droppedTasks.map((t) => ({ taskId: t.TaskId, isTask: t.IsTask })),
                this.status,
                this.taskBoard.UserId(),
                destWorkflowId,
                null,
                null,
                null
            ); //destTaskId, destTaskIsTask, destBefore);
        }
    }

    private hasBillableHours(elementId: number, isTask: boolean): Promise<boolean> {
        if (isTask) return this.todoListService.HasBillableHours(null, elementId, true);
        return this.todoListService.HasBillableHours(elementId, null, false);
    }

    public ValidateTaskDrop(el) {
        const vm: TaskForTaskBoard = ko.dataFor($(el)[0]);
        if (!vm.task.IsTask) return false;
        return parseInt($($($(el).parent()[0]).parent()[0]).attr("status")) != this.status;
    }

    public toggleCollapsedState() {
        this.isCollapsed(!this.isCollapsed());
        this.taskBoard.recalculateColumnSizes();
    }
}

class TaskAttachment {
    public FileName: ko.Observable<string> = ko.observable();
    public Url: ko.Observable<string> = ko.observable();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    constructor(fileName: string, url: string) {
        this.FileName(fileName);
        this.Url(url);
    }

    public Open() {
        this.ajaxServiceNew.DownloadFileFromUrl(this.Url(), { overrideMethod: "GET" });
    }
}

export class TaskForTaskBoard {
    @LazyImport(ProlifeSdk.WorkedHoursServiceType)
    private workedHoursService: IWorkedHoursService;

    @LazyImport(nameof<IBarCodeScannerService>())
    private barCodeScannerService: IBarCodeScannerService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public IsSelected: ko.Observable<boolean> = ko.observable(false);
    public ShowingAttachments: ko.Observable<boolean> = ko.observable(false);
    public Attachments: ko.ObservableArray<TaskAttachment> = ko.observableArray([]);
    public Loading: ko.Observable<boolean> = ko.observable(false);

    public ShortExpireDate: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    private attachmentsLoaded = false;

    slideToEnd = false;
    observers: any[] = [];
    LeftMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    RightMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    LeftToEndMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    RightToEndMenuEnabled: ko.Observable<boolean> = ko.observable(false);

    CanPlay: ko.Observable<boolean> = ko.observable();
    CanPause: ko.Observable<boolean> = ko.observable();
    CanStop: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    public dialogService: IDialogsService;

    constructor(public task: ITaskForTaskBoard, private column: TaskBoardColumn, private taskBoard: ITaskBoard) {
        const expireDate = moment(task.ExpireDate);
        if (expireDate.isValid()) {
            this.ShortExpireDate(expireDate.format("DD/MM"));
        }

        this.IsSelected(
            (this.taskBoard.selectedTasks() || []).filter(
                (t) => t.task.Id === this.task.Id && t.task.IsTask && this.task.IsTask
            ).length > 0
        );

        this.CanPlay(!task.LastActionType || task.LastActionType == "TEN" || task.LastActionType == "BST"); // Posso fare play se non ho mai avviato il task, se era precedentemente fermo o se ero in pausa
        this.CanPause(task.LastActionType == "TST" || task.LastActionType == "BEN"); // Posso fare pausa solo se il task è avviato o se ho gia finito una pausa
        this.CanStop(task.LastActionType == "TST" || task.LastActionType == "BST" || task.LastActionType == "BEN"); // Posso fare stop solo se il task è avviato, se è avviata una pausa o se è gia finita una pausa
    }

    public registerObserver(observer: any) {
        this.observers.push(observer);
    }

    public closeMenu() {
        this.observers.forEach((obs: () => void) => {
            obs();
        });
    }

    public Select(task, evt: MouseEvent) {
        this.taskBoard.SelectTask(this, evt);
    }

    public Edit() {
        this.taskBoard.SelectTask(this, null);
        this.taskBoard.EditSelectedTask();
    }

    public StateOnePlus() {
        this.taskBoard.SelectTask(this, null);
        this.taskBoard.StatePlusOne(this.column.status);
    }

    public DeleteTask() {
        this.taskBoard.SelectTask(this, null);
        this.taskBoard.DeleteTask();
    }

    public OpenDialogForChangeState() {
        this.taskBoard.SelectTask(this, null);
        this.taskBoard.OpenDialogForChangeState();
    }

    public OpenDialogForChangePlan() {
        this.taskBoard.SelectTask(this, null);
        this.taskBoard.OpenDialogForChangePlan();
    }

    public ShowAttachments() {
        this.ShowingAttachments(!this.ShowingAttachments());

        if (this.ShowingAttachments() && !this.attachmentsLoaded) {
            this.Loading(true);

            this.todoListService
                .GetTaskById(this.task.Id)
                .then((t: ITodoListTask) => {
                    const attachments = t.Tags.filter((tag: ITodoListTaskTag) => tag.Field == ProlifeSdk.Tag_File);
                    attachments.forEach((a: ITodoListTaskTag) => {
                        if (a.Type == ProlifeSdk.TagType_DocumentReference) {
                            this.Attachments.push(
                                new TaskAttachment(
                                    a.DisplayName,
                                    this.fileRepositoryService.GetVersionDownloadUrl(a.Value)
                                )
                            );
                        } else if (a.Type == ProlifeSdk.TagType_LastVersionReference) {
                            this.fileRepositoryService.GetLastVersion(a.Value).then((f: IFileOrFolder) => {
                                let url = "javascript:;";

                                if (!f) {
                                    this.infoToastService.Warning(TextResources.FileRepository.FileNotFound);
                                } else {
                                    url = this.fileRepositoryService.getDownloadUrl(f, false);
                                }

                                this.Attachments.push(new TaskAttachment(a.DisplayName, url));
                            });
                        }
                    });

                    this.attachmentsLoaded = true;
                })
                .finally(() => {
                    this.Loading(false);
                });
        }
    }

    public MatchesFilter(keywords: string[]): boolean {
        const matchesCount = keywords.filter(
            (f) =>
                this.task.Title.toLowerCase().indexOf(f.toLowerCase()) != -1 ||
                (this.task.Description || "").toLowerCase().indexOf(f.toLowerCase()) != -1
        );
        return matchesCount.length == keywords.length;
    }

    public InsertWorkedHoursOnTask(): void {
        const resourceId = this.taskBoard.ResourceId();
        this.workedHoursService.ShowWorkedHoursEditorDialog(
            resourceId,
            moment().toDate(),
            this.task.JobOrderId,
            this.task.Id,
            null,
            true
        );
    }

    public async StartWork(): Promise<void> {
        const request = {
            ActionDateTime: new Date(),
            AppGuid: "00000000-0000-0000-0000-000000000000",
            ResourceId: this.taskBoard.ResourceId(),
            TaskId: this.task.Id,
        };

        if (!this.task.LastActionType || this.task.LastActionType == "TEN")
            await this.barCodeScannerService.NotifyTaskStart(request);
        else if (this.task.LastActionType == "BST") await this.barCodeScannerService.NotifyBreakEnd(request);

        this.infoToastService.Success("Lavorazione avviata con successo!");
    }

    public async PauseWork(): Promise<void> {
        const request = {
            ActionDateTime: new Date(),
            AppGuid: "00000000-0000-0000-0000-000000000000",
            ResourceId: this.taskBoard.ResourceId(),
            TaskId: this.task.Id,
        };

        if (this.task.LastActionType == "TST" || this.task.LastActionType == "BEN")
            await this.barCodeScannerService.NotifyBreakStart(request);

        this.infoToastService.Success("Lavorazione messa in pausa con successo!");
    }

    public async EndWork(): Promise<void> {
        const request = {
            ActionDateTime: new Date(),
            AppGuid: "00000000-0000-0000-0000-000000000000",
            ResourceId: this.taskBoard.ResourceId(),
            TaskId: this.task.Id,
        };

        if (this.task.LastActionType == "TST" || this.task.LastActionType == "BST" || this.task.LastActionType == "BEN")
            await this.barCodeScannerService.NotifyTaskEnd(request);

        this.infoToastService.Success("Lavorazione terminata con successo!");
    }
}
