import * as ko from "knockout";
import { Document } from "../../../../Invoices/invoices/documents/Document";
import { DiscountsUtilities } from "../../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class DiscountAutomationDialog implements IDialog {
    templateName: string = "discountAutomation";
    templateUrl: string = "prolifesdk/templates/documents/automations";
    title: string = "Modifica Sconti";
    modal: { close: (result?: any) => void; };

    Discount : ko.Observable<string> = ko.observable();

    constructor(private document : Document) {

    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        
    }

    public setDiscounts() {
        for(let row of this.document.Rows())
            row.Discounts(this.Discount());
        this.modal.close(true);
    }

    public addDiscounts() {
        for(let row of this.document.Rows()) {
            if(!row.Discounts() || DiscountsUtilities.calculateDiscount(row.Discounts()) == 1)
                row.Discounts(this.Discount());
            else
                row.Discounts(row.Discounts() + " " + this.Discount());
        }
        this.modal.close(true);
    }
}