import * as Core from "../Core/Core";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IDocumentsService } from "./DocumentsService";
import { DdtDataSource } from "./invoices/documents/data-sources/DdtDataSource";
import { DdtDataSourceForMonthlyInvoicing } from "./invoices/documents/data-sources/DdtDataSourceForMonthlyInvoicing";
import { InvoicesDataSource } from "./invoices/documents/data-sources/InvoicesDataSource";
import { SALInvoicesDataSource } from "./invoices/documents/data-sources/SALInvoicesDataSource";
import { InvoiceApplication } from "./invoices/InvoiceApplication";
import { InvoicesReportsProvider } from "./invoices/providers/InvoicesReportsProvider";
import { DdtRefDetailsViewModelFactory } from "./invoices/providers/refs-details-factories/DdtRefDetailsViewModelFactory";
import { EstimateRefDetailsViewModelFactory } from "./invoices/providers/refs-details-factories/EstimateRefDetailsViewModelFactory";
import { InvoiceRefDetailsViewModelFactory } from "./invoices/providers/refs-details-factories/InvoiceRefDetailsViewModelFactory";
import { VatRegisterDocumentsProvider } from "./invoices/providers/VatRegisterDocumentsProvider";
import { ConveyancesSettingsManager } from "./invoices/settings/ConveyancesSettingsManager";
import { DDTAppearanceSettingsManager } from "./invoices/settings/DDTAppearanceSettingsManager";
import { DDTCauseSettingsManager } from "./invoices/settings/DDTCauseSettingsManager";
import { DDTPortiSettingsManager } from "./invoices/settings/DDTPortiSettingsManager";
import { DdtStatesSettingsManager } from "./invoices/settings/DdtStatesSettingsManager";
import { DocumentTypesSettingsManager } from "./invoices/settings/DocumentTypesSettingsManager";
import { EstimateStatesSettingsManager } from "./invoices/settings/EstimateStatesSettingsManager";
import { ExercisesSettingsManager } from "./invoices/settings/ExercisesSettingsManager";
import { ExpireModesSettingsManager } from "./invoices/settings/ExpireModesSettingsManager";
import { IvaModesSettingsManager } from "./invoices/settings/IvaModesSettingsManager";
import { PaymentModeSettingsManager } from "./invoices/settings/PaymentModeSettingsManager";
import { ValidityTypesSettingsManager } from "./invoices/settings/ValidityTypesSettingsManager";
import { VatRegistersSettingsManager } from "./invoices/settings/VatRegistersSettingsManager";
import { WitholdingTaxSettingsManager } from "./invoices/settings/WitholdingTaxSettingsManager";
import { DateFilterDialog } from "./invoices/ui/DateFilterDialog";
import { DdtEvent } from "./invoices/ui/events/DdtEvent";
import { InvoiceEvent } from "./invoices/ui/events/InvoiceEvent";
import { TrustAuthorizationEvent } from "./invoices/ui/events/TrustAuthorizationEvent";
import {
    IInvoicesService,
    IInvoicesServiceObserver,
    IEntity,
    IVatVerificationResult,
    IDdtCauseUsage,
    IGetInvoicesForListRequest,
    IInvoiceForList,
    IGetDdtsForMonthlyInvoicingWizardRequest,
    IDdtForMonthlyInvoicingWizard,
    IGetDocumentsForElectronicInvoicingRequest,
    IDocumentForElectronicInvoicing,
    IElectronicInvoiceType,
    IGetElectronicInvoiceTypesRequest,
    DefaultElectronicInvoiceTypes,
} from "../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IMonthlyInvoicingImportData } from "../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import {
    INotBilledDdt,
    IInvoice,
    IFullInvoice,
    IDdtForWizard,
    IInvoiceForWizard,
    IInvoiceRow,
    ITaxRow,
    IInvoiceForSchedule,
    IInvoicesDateFilter,
} from "../ProlifeSdk/interfaces/invoice/IInvoice";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IEntityRefInfo } from "../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IEntityForMonthlyInvoicingTree } from "../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, AjaxOptions } from "../Core/interfaces/IAjaxService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IReportsService } from "../ProlifeSdk/interfaces/report/IReportsService";
import { IService } from "../Core/interfaces/IService";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import {
    IMonthlyInvoicingLogRow,
    IMonthlyInvoicingEntityLog,
} from "../ProlifeSdk/interfaces/invoice/wizard/IMonthlyInvoicingLogRow";
import { IActivityLog } from "../ProlifeSdk/interfaces/invoice/IActivityLog";
import { IVatRegisters, IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IInvoiceSchedule } from "../ProlifeSdk/interfaces/invoice/IInvoiceSchedule";
import { IReasonForPayment } from "../ProlifeSdk/interfaces/invoice/IReasonForPayment";
import { IScheduleCalculationSetting } from "../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { Deferred } from "../Core/Deferred";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import {
    IEntityKey,
    IDocumentBuilderDocumentOriginatingRows,
} from "../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { PrintTemplateSettingsManager } from "./invoices/settings/PrintTemplateSettingsManager";
import { PassiveInvoiceProtocolType } from "./invoices/settings/PassiveInvoiceProtocolType";
import { PassiveInvoiceDocumentProvider } from "./invoices/documents/providers/PassiveInvoiceDocumentProvider";
import { ISettingsServiceObserver } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { DocumentResourcesSettingsManager } from "./invoices/settings/DocumentResourcesSettingsManager";

export interface IBlogViewModels {
    [type: string]: new (
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver
    ) => BlogEventBase;
}

class InvoicesService extends ProLifeService implements IInvoicesService, IEventUiProvider, ISettingsServiceObserver {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService: IProLifeSdkService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IReportsService>())
    private reportsService: IReportsService;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    private observers: IInvoicesServiceObserver[] = [];
    private hasRegisteredDocumentProviders = false;

    private eventsViewModels: IBlogViewModels = {};

    private electronicInvoicesDefaultTypes: DefaultElectronicInvoiceTypes;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.InvoiceApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IInvoicesService>(), this);
    }

    InitializeService() {
        super.InitializeService();

        //Registro i settings
        new DDTAppearanceSettingsManager(this.serviceLocator);
        new DDTPortiSettingsManager(this.serviceLocator);
        new DDTCauseSettingsManager();
        new PaymentModeSettingsManager(this.serviceLocator);
        new WitholdingTaxSettingsManager(this.serviceLocator);
        new ConveyancesSettingsManager(this.serviceLocator);
        new DocumentTypesSettingsManager(this.serviceLocator);
        new ExpireModesSettingsManager(this.serviceLocator);
        new IvaModesSettingsManager(this.serviceLocator);
        new ValidityTypesSettingsManager(this.serviceLocator);
        new ExercisesSettingsManager(this.serviceLocator);
        new VatRegistersSettingsManager();
        new EstimateStatesSettingsManager(this.serviceLocator);
        new DdtStatesSettingsManager(this.serviceLocator);
        new PrintTemplateSettingsManager();
        new DocumentResourcesSettingsManager();

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.InvoiceEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Invoices.Invoice,
            PluralEntityName: ProlifeSdk.TextResources.Invoices.Invoices,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Invoices.AccompanyingInvoice,
            PluralEntityName: ProlifeSdk.TextResources.Invoices.AccompanyingInvoices,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.CreditNoteEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Invoices.CreditNote,
            PluralEntityName: ProlifeSdk.TextResources.Invoices.CreditNotes,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.DdtEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Invoices.Delivery,
            PluralEntityName: ProlifeSdk.TextResources.Invoices.Deliveries,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.EstimateEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Invoices.Quote,
            PluralEntityName: ProlifeSdk.TextResources.Invoices.Quotes,
        });

        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.EstimateEntityTypeCode,
            new EstimateRefDetailsViewModelFactory()
        );
        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.DdtEntityTypeCode,
            new DdtRefDetailsViewModelFactory()
        );
        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.InvoiceEntityTypeCode,
            new InvoiceRefDetailsViewModelFactory()
        );
        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            new InvoiceRefDetailsViewModelFactory()
        );
        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.CreditNoteEntityTypeCode,
            new InvoiceRefDetailsViewModelFactory()
        );

        this.eventsViewModels[ProlifeSdk.InvoiceEventType_Invoice] = InvoiceEvent;
        this.eventsViewModels[ProlifeSdk.InvoiceEventType_Ddt] = DdtEvent;
        this.eventsViewModels[ProlifeSdk.InvoiceEventType_TrustAuthorization] = TrustAuthorizationEvent;

        this.loadElectronicInvoiceDefaultTypes();
    }

    private async loadElectronicInvoiceDefaultTypes(): Promise<void> {
        try {
            this.electronicInvoicesDefaultTypes = await this.ajaxService.Get(
                "Invoices-api/ElectronicInvoicing",
                "GetElectronicInvoiceDefaultTypes",
                {}
            );
        } catch (e) {
            console.error(e);
            this.electronicInvoicesDefaultTypes = {
                Invoice: null,
                AccompanyingInvoice: null,
                CreditNote: null,
                DownPayment: null,
            };
        }
    }

    public authorizationLoaded(rights: any): void {
        super.authorizationLoaded(rights);

        new DdtDataSource();
        new DdtDataSourceForMonthlyInvoicing();
        new InvoicesDataSource();
        new SALInvoicesDataSource();

        new InvoiceApplication();
    }

    public getUIForEvent(item: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        const type = this.eventsViewModels[item.EventType] || BlogEventBase;

        //TODO: Rimuovere il service locator da qui!!!
        const event: BlogEventBase = new type(Core.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView>event;
    }

    getReferencesDetails(references: IDocumentBuilderDocumentOriginatingRows[]): Promise<IEntityRefInfo[]> {
        const refs = references.map((r) => {
            return {
                EntityKeyId: r.SourceEntityKeyId,
                EntityKeyType: r.SourceEntityType,
                CatalogId: r.CatalogId,
            };
        });

        return this.ajaxService.Post("Documents-api/ReferencesInfo", "LoadReferencesInfo", {
            methodData: refs,
        });
    }

    addObserver(observer: IInvoicesServiceObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IInvoicesServiceObserver): void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    getServiceType(): string {
        return ProlifeSdk.InvoicesServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getInvoiceIds(): Promise<number[]> {
        const parameters: AjaxOptions = {};

        return this.ajaxService.Get("Invoices/Invoices", "getInvoiceIds", {});
    }

    filterInvoiceIds(invoiceIds?: number[]): Promise<number[]> {
        const parameters: AjaxOptions = {};
        parameters.methodData = { invoiceIds: invoiceIds };

        return this.ajaxService.Post("Invoices/Invoices", "filterInvoiceIds", parameters);
    }

    GetNotBilledDdts(onlyWithoutJobOrder: boolean, from: Date, to: Date): Promise<INotBilledDdt[]> {
        const parameters: AjaxOptions = {};
        parameters.methodData = {
            From: this.sdkService.Utilities.Dates.AddGmtTime(from),
            To: this.sdkService.Utilities.Dates.AddGmtTime(to),
            OnlyNotAssociatedToJobOrder: onlyWithoutJobOrder,
        };
        return this.ajaxService.Post("Invoices-api/NotBilledDdt", "SearchNotBilledDdt", parameters);
    }

    getInvoices(invoiceIds?: number[]): Promise<IInvoice[]> {
        const parameters: AjaxOptions = {};
        parameters.methodData = { invoiceIds: invoiceIds };

        return this.ajaxService.Post("Invoices/Invoices", "getInvoices", parameters);
    }

    createOrUpdate(invoice: IFullInvoice): Promise<IFullInvoice> {
        const def = new Deferred<IFullInvoice>();

        let callback = this.onInvoiceAdded.bind(this);
        if (invoice.Invoice.IdFattura > 0) callback = this.onInvoiceChanged.bind(this);

        invoice.Invoice.InizioTrasporto = this.sdkService.Utilities.Dates.AddGmtTime(invoice.Invoice.InizioTrasporto);

        this.ajaxService
            .Post("Documents-api", "Document", { methodData: invoice })
            .then((savedInvoice: IFullInvoice) => {
                /*if (savedInvoice.TrustAuthorization.Saved) {
                    callback(savedInvoice.Invoice);
                    def.resolve(savedInvoice);
                    return;
                }*/

                def.resolve(savedInvoice);
            })
            .catch((e) => {
                def.reject(e);
            });

        return def.promise();
    }

    remove(invoiceId: number): Promise<any> {
        return this.ajaxService.Delete("Documents-api", "Document/" + invoiceId, {}).then(() => {
            this.onInvoiceDeleted(invoiceId);
            return invoiceId;
        });
    }

    GetDdtForWizard(
        textFilter: string,
        getWithoutJobOrder: boolean,
        jobOrderId: number,
        getForAllCustomers: boolean,
        customerId: number,
        from: Date,
        to: Date,
        skip: number,
        count: number
    ): Promise<IDdtForWizard[]> {
        return this.ajaxService.Post("Documents-api", "Document/GetDdtForWizard", {
            methodData: {
                TextFilter: textFilter,
                JobOrderId: jobOrderId,
                GetWithoutJobOrder: getWithoutJobOrder,
                CustomerId: customerId,
                GetForAllCustomers: getForAllCustomers,
                From: this.sdkService.Utilities.Dates.AddGmtTime(from),
                To: this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip: skip,
                Count: count,
            },
        });
    }

    GetInvoiceForWizard(
        textFilter: string,
        getWithoutJobOrder: boolean,
        jobOrderId: number,
        getForAllCustomers: boolean,
        customerId: number,
        from: Date,
        to: Date,
        skip: number,
        count: number,
        bySALInvoicing: boolean
    ): Promise<IInvoiceForWizard[]> {
        return this.ajaxService.Post("Documents-api", "Document/GetInvoiceForWizard", {
            methodData: {
                TextFilter: textFilter,
                JobOrderId: jobOrderId,
                GetWithoutJobOrder: getWithoutJobOrder,
                CustomerId: customerId,
                GetForAllCustomers: getForAllCustomers,
                From: this.sdkService.Utilities.Dates.AddGmtTime(from),
                To: this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip: skip,
                Count: count,
                BySALInvoicing: bySALInvoicing,
            },
        });
    }

    GenerateMonthlyInvoicing(
        monthlyInvoicingData: IMonthlyInvoicingImportData
    ): Promise<IEntityForMonthlyInvoicingTree[]> {
        return this.ajaxService.Post("Documents-api", "Document/GenerateMonthlyInvoicing", {
            methodData: monthlyInvoicingData,
        });
    }

    GetMonthlyInvoicingLog(skip: number, count: number): Promise<IMonthlyInvoicingLogRow[]> {
        return this.ajaxService.Post("Documents-api", "Document/GetMonthlyInvoicingLog", {
            methodData: {
                Skip: skip,
                Count: count,
            },
        });
    }

    GetMonthlyInvoicingEntitiesLog(treeRoots: number[]): Promise<IMonthlyInvoicingEntityLog[]> {
        return this.ajaxService.Post("Documents-api", "Document/GetMonthlyInvoicingEntitiesLog", {
            methodData: {
                TreeRoots: treeRoots,
            },
        });
    }

    getDocumentById(documentId: number): Promise<IInvoice> {
        if (!documentId) {
            return Promise.reject<IInvoice>(undefined);
        }

        return this.ajaxService.Get("Documents-api", "Document/" + documentId, {});
    }

    GetDocumentLogs(docType: string, docId: number, jobOrderId: number): Promise<IActivityLog[]> {
        return this.ajaxService.Post("Documents-api", "DocumentsLog/GetDocumentLogs", {
            methodData: { DocumentId: docId, DocumentType: docType, JobOrderId: jobOrderId },
        });
    }

    getDocumentRowsById(documentId: number): Promise<IInvoiceRow[]> {
        if (!documentId) {
            return Promise.reject<IInvoiceRow[]>([]);
        }

        return this.ajaxService.Post("Documents-api", "DocumentRows/GetInvoiceOrDdtRows", {
            methodData: {
                DocumentId: documentId,
            },
        });

        //return this.ajaxService.Get("Documents-api", "DocumentRows/" + documentId, {});
    }

    getDocumentTaxRowsById(documentId: number): Promise<ITaxRow[]> {
        if (!documentId) {
            return Promise.reject<ITaxRow[]>([]);
        }

        return this.ajaxService.Get("Documents-api", "DocumentTaxRows/" + documentId, {});
    }

    setFreezeStatusByDocumentsIds(freezeStatus: boolean, ids: IEntity[]): Promise<void> {
        if (!ids || ids.length == 0) {
            return Promise.reject<void>(undefined);
        }

        const def = new Deferred<void>();

        this.ajaxService
            .Post("Documents-api/Document", "SetInvoicesFreezeStatus", {
                methodData: {
                    freezeStatus: freezeStatus,
                    invoiceIds: ids,
                },
            })
            .then(() => {
                ids.forEach((id: IEntity) => this.onInvoiceLockStateChanged(id, freezeStatus));
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    setSalIdOnDocuments(documentsIds: number[], salId: number): Promise<void> {
        if (!documentsIds || documentsIds.length == 0) {
            return Promise.reject<void>(undefined);
        }
        return this.ajaxService.Post("Documents-api/Document", "SetSalIdOnDocuments", {
            methodData: { salId: salId, documentIds: documentsIds },
        });
    }

    getInvoicesByVatRegisterAndDates(
        vatRegisterId: number,
        startDate: Date,
        endDate: Date,
        filter?: string
    ): Promise<IInvoice[]> {
        if (!vatRegisterId) {
            return Promise.reject<IInvoice[]>([]);
        }

        const parameters: AjaxOptions = {};
        parameters.methodData = { vatRegisterId: vatRegisterId, startDate: startDate, endDate: endDate };
        parameters.async = false;

        return this.ajaxService.Post("Invoices/VatRegisterReport", "GetInvoicesByVatRegisterAndDates", parameters);
    }

    getDdtsByProtocolAndDates(
        protocolId: number,
        startDate: Date,
        endDate: Date,
        filter?: string
    ): Promise<IInvoice[]> {
        if (!protocolId) {
            return Promise.reject<IInvoice[]>([]);
        }

        const parameters: AjaxOptions = {};
        parameters.methodData = { protocolId: protocolId, startDate: startDate, endDate: endDate };
        //parameters.async = false;

        return this.ajaxService.Post("Invoices/DdtsReport", "GetDdtsByProtocolAndDates", parameters);
    }

    getInvoicesByCustomerAndDates(
        customerId: number,
        startDate: Date,
        endDate: Date,
        filter?: string,
        documentType?: number
    ): Promise<IInvoice[]> {
        const parameters: AjaxOptions = {};
        parameters.methodData = {
            customerId: customerId,
            startDate: startDate,
            endDate: endDate,
            docType: documentType,
        };
        //parameters.async = false;

        return this.ajaxService.Post("Invoices/CustomerReport", "GetInvoicesByCustomerAndDates", parameters);
    }

    public OnServerInitializationDataLoaded(): void {
        this.reportsService.registerReportsProvider(new InvoicesReportsProvider(this.serviceLocator));

        if (this.hasRegisteredDocumentProviders) return;

        this.RegisterProtocols();
    }

    onSettingsUpdated(updateType: string): void {
        if (updateType != ProlifeSdk.SettingsUpdateType_DocumentsProtocols) return;

        //Rimuovo i registri di questo tipo precedentemente registrati
        this.documentsService
            .getRegisteredDocumentProviders()
            .slice(0)
            .forEach((p: IDocumentsProvider) => {
                if (p instanceof VatRegisterDocumentsProvider || p instanceof PassiveInvoiceDocumentProvider)
                    this.documentsService.unregisterDocumentsProvider(p);
            });

        this.vatRegisters.unregisterCustomProtocolType((cpt) => cpt instanceof PassiveInvoiceProtocolType);

        //Aggiungo i nuovi registri
        this.RegisterProtocols();
    }

    private RegisterProtocols() {
        if (
            this.authorizationService.isAuthorized("Documents_PassiveInvoices") ||
            this.authorizationService.isAuthorized("Documents_ViewPassiveInvoices")
        )
            this.vatRegisters.registerCustomProtocolType(new PassiveInvoiceProtocolType());

        this.vatRegisters.getVatRegisters().forEach((vatRegister: IVatRegister) => {
            if (vatRegister.Stato != ProlifeSdk.HiddenProtocolState && vatRegister.TipoDocumento < 3)
                this.documentsService.registerDocumentsProvider(new VatRegisterDocumentsProvider(vatRegister));
            else if (
                vatRegister.Stato < ProlifeSdk.HiddenProtocolState &&
                vatRegister.TipoDocumento == ProlifeSdk.PassiveInvoiceTypeId
            )
                this.documentsService.registerDocumentsProvider(new PassiveInvoiceDocumentProvider(vatRegister));
        });

        this.hasRegisteredDocumentProviders = true;
    }

    onInvoiceAdded(invoice: IInvoice): void {
        if (invoice) invoice.Numero = invoice.Numero ? invoice.Numero : "";
        this.observers.forEach((observer: IInvoicesServiceObserver) => observer.onInvoiceAdded(invoice));
    }

    onInvoiceChanged(invoice: IInvoice): void {
        this.observers.forEach((observer: IInvoicesServiceObserver) => observer.onInvoiceChanged(invoice));
    }

    onInvoiceDeleted(invoiceId: number): void {
        this.observers.forEach((observer: IInvoicesServiceObserver) => observer.onInvoiceDeleted(invoiceId));
    }

    onInvoiceLockStateChanged(invoiceId: IEntity, locked: boolean): void {
        this.observers.forEach((observer: IInvoicesServiceObserver) =>
            observer.onInvoiceLockStateChanged(invoiceId, locked)
        );
    }

    /*getDocumentDetailUrl(documentId : number, registerId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, "Vat" + registerId, documentId);
    }

    getNewDocumentUrl(registerId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.NewDocumentURL, "Vat" + registerId);
    }

    getNewDocumentUrlForJobOrder(registerId : number, jobOrderId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.NewDocumentJobOrderURL, "Vat" + registerId, jobOrderId);
    }*/

    GetInvoicesForSchedule(ids: number[]): Promise<IInvoiceForSchedule[]> {
        return this.ajaxService.Post("Invoices/Invoices", "GetInvoicesForSchedule", {
            methodData: {
                Ids: ids,
            },
            background: true,
        });
    }

    getMailForSend(id: number): Promise<string> {
        return this.ajaxService.Get("Documents-api", "InvoiceMail/" + id, { background: true });
    }

    VerifyVat(countryCode: string, vatNumber: string): Promise<IVatVerificationResult> {
        return this.ajaxService.Post("Documents-api", "VatVerify", {
            methodData: {
                CountryCode: countryCode,
                VatNumber: vatNumber,
            },
        });
    }

    ShowDateFilterDialog(
        startDate?: Date,
        endDate?: Date,
        searchOnDocumentsContent?: boolean
    ): Promise<IInvoicesDateFilter> {
        const dialog = new DateFilterDialog();

        if (startDate) dialog.StartDate(startDate);
        if (endDate) dialog.EndDate(endDate);
        if (searchOnDocumentsContent != null && searchOnDocumentsContent != undefined)
            dialog.SearchOnDocumentsContent(searchOnDocumentsContent);

        return this.dialogsService.ShowModal<IInvoicesDateFilter>(dialog);
    }

    HasSchedules(invoiceId: number): Promise<boolean> {
        return this.ajaxService.Post("Invoices-api/Document", "HasSchedules", {
            methodData: {
                InvoiceId: invoiceId,
            },
        });
    }

    GetInvoiceSchedules(invoiceId: number): Promise<IInvoiceSchedule[]> {
        return this.ajaxService.Post("Invoices-api/Document", "GetInvoiceSchedules", {
            methodData: {
                InvoiceId: invoiceId,
            },
        });
    }

    /*GetInvoiceOrDdtExpiryTypesFromRefDocumentRows(documentId: number, documentType: string): JQueryPromise {
        return this.ajaxService.Post("Invoices-api/Document", "GetInvoiceExpiryTypesFromRefDocumentRows", {
            methodData: {
                DocumentId: documentId,
                DocumentType: documentType
            }
        });
    }*/

    getReasonsForPayment(): Promise<IReasonForPayment[]> {
        return this.ajaxService.Get("Invoices-api", "ReasonsForPayment", {});
    }

    ExportDocumentForElectronicInvoicing(documentType: string, documentId: number): Promise<void> {
        const def = new Deferred<void>();

        this.ajaxService
            .Download("Invoices-api/ElectronicInvoicing", "ExportDocumentForElectronicInvoicing", {
                methodData: {
                    DocumentId: documentId,
                    DocumentType: documentType,
                },
            })
            .then(() => {
                this.onInvoiceLockStateChanged({ Id: documentId, EntityType: ProlifeSdk.InvoiceEntityTypeCode }, true);
                def.resolve();
            })
            .catch((e) => def.reject(e));

        return def.promise();
    }

    ExportDocumentsForElectronicInvoicing(documents: IEntityKey[]): Promise<void> {
        const def = new Deferred<void>();

        this.ajaxService
            .Download("Invoices-api/ElectronicInvoicing", "ExportDocumentsForElectronicInvoicing", {
                methodData: {
                    DocumentsToExport: documents,
                },
            })
            .then(() => {
                documents.forEach((documentKey: IEntityKey) => {
                    this.onInvoiceLockStateChanged(
                        { Id: documentKey.EntityKeyId, EntityType: documentKey.EntityKeyType },
                        true
                    );
                    def.resolve();
                });
            })
            .catch(() => def.reject());

        return def.promise();
    }

    async isExpiryModeConfigured(paymentDeadlineTypeId: number): Promise<boolean> {
        const configs = await this.ajaxService.Get<IScheduleCalculationSetting[]>(
            "Invoices-api",
            "ScheduleCalculationSetting?paymentDeadlineTypeId=" + paymentDeadlineTypeId,
            {}
        );
        return configs.length > 0;
    }

    GetDdtsCausesUsage(): Promise<IDdtCauseUsage[]> {
        return this.ajaxService.Post<IDdtCauseUsage[]>("Documents-api/DDTCause", "GetDdtsCausesUsage", {
            background: true,
            methodData: {},
        });
    }

    GetInvoicesForList(request: IGetInvoicesForListRequest): Promise<IInvoiceForList[]> {
        return this.ajaxService.Post<IInvoiceForList[]>("Invoices-api/Document", "GetInvoicesForList", {
            background: true,
            methodData: request,
        });
    }

    GetInvoiceIdByNumberAndDate(
        documentNumber: string | null,
        documentDate: Date | null,
        registerId: number | null
    ): Promise<number[]> {
        return this.ajaxService.Post<number[]>("Documents-api/Document", "GetInvoiceIdByNumberAndDate", {
            background: true,
            methodData: {
                documentNumber: documentNumber,
                documentDate: documentDate,
                registerId: registerId,
            },
        });
    }

    GetDdtsForMonthlyInvoicingWizard(
        request: IGetDdtsForMonthlyInvoicingWizardRequest
    ): Promise<IDdtForMonthlyInvoicingWizard[]> {
        const result = this.ajaxService.Post<IDdtForMonthlyInvoicingWizard[]>(
            "Invoices-api/Document",
            "GetDdtsForMonthlyInvoicingWizard",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetDocumentsForElectronicInvoicing(
        request: IGetDocumentsForElectronicInvoicingRequest
    ): Promise<IDocumentForElectronicInvoicing[]> {
        const result = this.ajaxService.Post<IDocumentForElectronicInvoicing[]>(
            "Invoices-api/ElectronicInvoicing",
            "GetDocumentsForElectronicInvoicing",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetElectronicInvoiceTypesByIds(ids: string[] | null): Promise<IElectronicInvoiceType[]> {
        const result = this.ajaxService.Post<IElectronicInvoiceType[]>(
            "Invoices-api/ElectronicInvoicing",
            "GetElectronicInvoiceTypesByIds",
            {
                background: true,
                methodData: {
                    ids: ids,
                },
            }
        );

        return result;
    }

    GetElectronicInvoiceTypes(request: IGetElectronicInvoiceTypesRequest): Promise<IElectronicInvoiceType[]> {
        const result = this.ajaxService.Post<IElectronicInvoiceType[]>(
            "Invoices-api/ElectronicInvoicing",
            "GetElectronicInvoiceTypes",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetElectronicInvoiceDefaultTypes(): DefaultElectronicInvoiceTypes {
        return this.electronicInvoicesDefaultTypes;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new InvoicesService(serviceLocator);
}
