import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsManager } from "../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../ProlifeSdk/interfaces/IView";

export interface IPurchaseTypeSettingManager extends ISettingsManager {
    load(): Promise<IPurchaseType[]>;
    getAll() : IPurchaseType[];
    getById(id : number) : IPurchaseType;
    createOrUpdate(type : IPurchaseType) : Promise<IPurchaseType>;
    remove(id : number) : Promise<void>;
}

export interface IPurchaseType {
    Id: number;
    Title: string;
    Default: boolean;
}

export class PurchaseTypeSettingManager implements IPurchaseTypeSettingManager {
    @LazyImport(ProlifeSdk.SettingsServiceType)
    private settingsService : ISettingsService;

    @LazyImport(ServiceTypes.Ajax)
    private ajaxService : IAjaxService;

    @LazyImport(ProlifeSdk.ModulesServiceType)
    private modulesService : IModulesService;

    private purchaseTypes: IPurchaseType[] = [];
    private indexedPurchaseTypes : { [id:number] : IPurchaseType } = {};
    
    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Warehouse.WarehouseSettingsGroup);
    }

    load(): Promise<IPurchaseType[]> {
        return this.ajaxService.Post<IPurchaseType[]>("JobOrder-api/PurchaseType", "GetAll", {})
            .then((types : IPurchaseType[]) => {
                this.purchaseTypes = types;
                this.indexedPurchaseTypes = {};
                for(let type of types) {
                    this.indexedPurchaseTypes[type.Id] = type;
                }
                return types;
            });
    }

    getName(): string {
        return ProlifeSdk.PurchasesTypesSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Desktop.PurchasesTypes;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName : "purchaseTypes",
            templateUrl : "jobOrder/templates/settings",
            viewModel : new PurchaseTypeSettingManagerEditor(this)
        }
    }

    getAll(): IPurchaseType[] {
        return this.purchaseTypes.slice(); //Clono la cache per evitare che qualcuno alteri l'array fuori
    }

    getById(id: number): IPurchaseType {
        return this.indexedPurchaseTypes[id];
    }

    createOrUpdate(type: IPurchaseType): Promise<IPurchaseType> {
        return this.ajaxService.Post<IPurchaseType>("JobOrder-api/PurchaseType", "CreateOrUpdate", {
            methodData: type
        }).then((newType : IPurchaseType) => {
            let index = -1;
            for(let purchaseType of this.purchaseTypes) {
                index++;
                if(purchaseType.Id === newType.Id) {
                    this.purchaseTypes[index] = newType;
                    break;
                }
            }

            this.indexedPurchaseTypes[newType.Id] = newType;
            return newType;
        });
    }

    remove(id : number) : Promise<void> {
        return this.ajaxService.Post<void>("JobOrder-api/PurchaseType", "Delete", {
            methodData: {
                Id: id
            }
        }).then(() => {
            let index = -1;
            for(let purchaseType of this.purchaseTypes) {
                index++;
                if(purchaseType.Id === id) {
                    this.purchaseTypes = this.purchaseTypes.splice(index, 1);
                    break;
                }
            }

            this.indexedPurchaseTypes[id] = undefined;
        });
    }
}

class PurchaseTypeEditor {
    @LazyImport(ServiceTypes.Dialogs)
    private dialogsService : IDialogsService;

    public Title : ko.Computed<string> = ko.utils.conditionedObservable<string>(null, v => v !== null && v !== undefined && v !== "");
    public Default : boolean;

    constructor(private purchaseType : IPurchaseType, private editor : PurchaseTypeSettingManagerEditor) {
        this.Title(purchaseType.Title);
        this.Default = purchaseType.Default;

        this.Title.subscribe((newValue) => {
            if(newValue == this.purchaseType.Title)
                return;

            this.editor.save(this)
                .then((purchaseType : IPurchaseType) => {
                    this.purchaseType = purchaseType;
                })
        });
    }

    getData(): IPurchaseType {
        return {
            Id: this.purchaseType.Id,
            Title: this.Title(),
            Default: this.purchaseType.Default
        }
    }

    public remove() {
        if(this.purchaseType.Default)
            return;

        this.dialogsService.Confirm(ProlifeSdk.TextResources.JobOrder.ConfirmPurchaseTypeRemoval, ProlifeSdk.TextResources.JobOrder.ConfirmPurchaseTypeRemovalCancel, ProlifeSdk.TextResources.JobOrder.ConfirmPurchaseTypeRemovalOk, (result : boolean) => {
            if(!result) return;
            this.editor.remove(this);
        });
    }

    public getId() : number {
        return this.purchaseType.Id;
    }
}

class PurchaseTypeSettingManagerEditor {
    @LazyImport(ServiceTypes.InfoToast)
    private infoToastService : IInfoToastService;

    public Types : ko.ObservableArray<PurchaseTypeEditor> = ko.observableArray<PurchaseTypeEditor>();

    constructor(private purchaseTypesSettingsManager : IPurchaseTypeSettingManager) {
        this.load();
    }

    private load() {
        this.Types(
            this.purchaseTypesSettingsManager.getAll().map(t => this.createViewModelFor(t))
        );
    }

    private createViewModelFor(purchaseType : IPurchaseType) : PurchaseTypeEditor {
        return new PurchaseTypeEditor(purchaseType, this);
    }

    public remove(purchaseType : PurchaseTypeEditor) {
        if(purchaseType.getId() <= 0) {
            this.Types.remove(purchaseType);
            return;
        }

        this.purchaseTypesSettingsManager.remove(purchaseType.getId())
            .then(() => {
                this.Types.remove(purchaseType);
            });
    }

    public createNew() {
        this.Types.push(this.createViewModelFor({
            Id: 0,
            Title: "",
            Default: false
        }));
    }

    public save(purchaseType : PurchaseTypeEditor) : Promise<IPurchaseType> {
        return this.purchaseTypesSettingsManager.createOrUpdate(purchaseType.getData())
            .then((t) => {
                this.infoToastService.Success(ProlifeSdk.TextResources.JobOrder.PurchaseTypeSaved)
                return t;
            });
    }
}