import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/06/2018
 * Time: 14:58
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk";
import { LazyImport } from "../../Core/DependencyInjection";
import { IDocumentExpiryViewModel, IDocumentPaymentViewModel, IDocumentPaymentInfo } from "../interfaces/invoice/IRegisterDocument";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";

export class PaymentInfoFromImportsDialog implements IDialog {
    public templateName: string = "payment-info-dialog";
    public templateUrl: string = "prolifesdk/templates/documents/dialogs";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.PaymentInfoFromImportsDialogTitle;
    public prompt: string = ProlifeSdk.TextResources.ProlifeSdk.PaymentInfoFromImportsDialogMessage;

    public Expiries: ko.ObservableArray<IDocumentExpiryViewModel> = ko.observableArray([]);
    public SelectedExpiry: ko.Observable<IDocumentExpiryViewModel> = ko.observable();

    public PaymentModes: ko.ObservableArray<IDocumentPaymentViewModel> = ko.observableArray([]);
    public SelectedPayment: ko.Observable<IDocumentPaymentViewModel> = ko.observable();

    public modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private paymentInfo: IDocumentPaymentInfo[]) {
        this.Expiries(this.paymentInfo.filter(pi => !!pi.ExpiryTypeId || !!pi.ExpiryType).map((e: IDocumentPaymentInfo) => { return new DocumentExpiryViewModel(e, this); }));
        this.PaymentModes(this.paymentInfo.filter(pi => !!pi.PaymentTypeId).map((e: IDocumentPaymentInfo) => { return new DocumentPaymentViewModel(e, this); }));
    }

    public close(): void {
        this.modal.close(null);
    }

    public action(): void {
        if (!this.SelectedExpiry() && !this.SelectedPayment()) {
            this.modal.close(null);
            return;
        }

        var expiry: IDocumentPaymentInfo = !this.SelectedExpiry() ? null : this.SelectedExpiry().paymentInfo;
        var payment: IDocumentPaymentInfo = !this.SelectedPayment() ? null : this.SelectedPayment().paymentInfo;

        this.modal.close({
            ExpiryTypeId: !expiry ? null : expiry.ExpiryTypeId,
            ExpiryType: !expiry ? null :  expiry.ExpiryType,
            PaymentTypeId: !payment ? null : payment.PaymentTypeId,
            PaymentType: !payment ? null : payment.PaymentType,
            PaymentIBAN: !payment ? null : payment.PaymentIBAN,
            PaymentABI: !payment ? null : payment.PaymentABI,
            PaymentCAB: !payment ? null : payment.PaymentCAB
        });
    }

    public Show(): Promise<IDocumentPaymentInfo> {
        return this.dialogsService.ShowModal<IDocumentPaymentInfo>(this, "large");
    }

    public SelectExpiry(expiry: IDocumentExpiryViewModel): void {
        this.Expiries().forEach((e) => e.Selected(false));
        expiry.Selected(!!this.SelectedExpiry() && this.SelectedExpiry().Is(expiry.paymentInfo) ? false : true);
        this.SelectedExpiry(!!this.SelectedExpiry() && this.SelectedExpiry().Is(expiry.paymentInfo) ? null: expiry);
    }

    public SelectPayment(payment: IDocumentPaymentViewModel): void {
        this.PaymentModes().forEach((e) => e.Selected(false));
        payment.Selected(!!this.SelectedPayment() && this.SelectedPayment().Is(payment.paymentInfo) ? false : true);
        this.SelectedPayment(!!this.SelectedPayment() && this.SelectedPayment().Is(payment.paymentInfo) ? null: payment);
    }
}

class DocumentExpiryViewModel implements IDocumentExpiryViewModel {
    public Source: ko.Observable<string> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();

    public Selected: ko.Observable<boolean> = ko.observable();

    constructor(public paymentInfo: IDocumentPaymentInfo, private editor: PaymentInfoFromImportsDialog) {
        this.Source(paymentInfo.DataSource);
        this.ExpiryType(this.paymentInfo.ExpiryType);
        this.Selected(false);
    }

    public Select(): void {
        this.editor.SelectExpiry(this);
    }

    public Is(paymentInfo: IDocumentPaymentInfo): boolean {
        return this.paymentInfo.DataSource == paymentInfo.DataSource;
    }
}

class DocumentPaymentViewModel implements IDocumentPaymentViewModel {
    public Source: ko.Observable<string> = ko.observable();
    public PaymentMode: ko.Observable<string> = ko.observable();

    public Selected: ko.Observable<boolean> = ko.observable();

    constructor(public paymentInfo: IDocumentPaymentInfo, private editor: PaymentInfoFromImportsDialog) {
        this.Source(this.paymentInfo.DataSource);
        this.PaymentMode(this.paymentInfo.PaymentType);
        this.Selected(false);
    }

    public Select(): void {
        this.editor.SelectPayment(this);
    }

    public Is(paymentInfo: IDocumentPaymentInfo): boolean {
        return this.paymentInfo.DataSource == paymentInfo.DataSource;
    }
}