import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam, useEffect } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentReferencesDialog } from "../../../ProlifeSdk/prolifesdk/documents/DocumentReferencesDialog";
import { ReferencesDetailsBalloon } from "../../../ProlifeSdk/prolifesdk/documents/ReferencesDetailsBalloon";
import { IDocumentRowHelperRow } from "../../../Bindings/DocumentRowHelper";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { VatTypesDataSource, IVatTypesDataSourceModel } from "../../../DataSources/VatTypesDataSource";
import { Document } from "./Document";
import { DiscountsUtilities } from "../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import { DueDateAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/DueDateAutomationDialog";
import { DiscountAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/DiscountAutomationDialog";
import { VatCodeAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/VatCodeAutomationDialog";
import { OffsetAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/OffsetAutomationDialog";
import { DocumentRowDiscountCatalogSelectorDialog } from "../../../ProlifeSdk/prolifesdk/documents/DocumentRowDiscountCatalogSelectorDialog";
import {
    IResolvedCustomerDiscountCatalogArticleSuggestion,
    IResolvedCustomerDiscountCatalogArticleWithCatalog,
    DiscountCatalogRowMode,
    IDiscountsCatalogsService,
} from "../../../Warehouse/DiscountsCatalogsService";
import { OffsetsCodesDataSource, IOffsetsCodesDataSourceModel } from "../../../DataSources/OffsetsCodesDataSource";
import { IDocumentsService, IRowRefMapping, IWarehouseEntityStockInfo } from "../../DocumentsService";
import { Task } from "../../../ProlifeSdk/prolifesdk/utils/Task";
import {
    RelatedWorkflowsPopOver,
    RelatedWorkflowsAutomationPopOver,
} from "../../../ProlifeSdk/prolifesdk/documents/RelatedWorkflowsPopOver";
import {
    LettersOfAttemptsDataSource,
    ILetterOfAttemptDataSourceModel,
} from "../../../DataSources/LettersOfAttemptsDataSource";
import { DueDateVariationAutomationDialog } from "../../../ProlifeSdk/prolifesdk/documents/automation/DueDateVariationAutomationDialog";
import { OffsetsTableReadOnly, OffsetsTableNonReadOnly } from "./DocumentOffsetsTable";
import { CurrencyUtils } from "../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import {
    IDocumentBuilderDocumentRows,
    IDocumentBuilderDocumentReferencingRows,
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRelatedWorkflows,
} from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    ISelectedEntityInlineInfo,
    IDocumentRelatedWorkflow,
} from "../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ILetterOfAttempt } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IIvaModes, IIvaMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { LetterOfAttemptSelectionPopOver } from "./LetterOfAttemptSelectionPopOver";
import { ArticleStockStatus } from "../../../Warehouse/enums/ArticleStockStatus";
import { ArticleStockInfoPopoverUI } from "./ArticleStockInfoPopover";
import { RowValuesCalculator } from "./RowValuesCalculator";

const csss = require("../../../Desktop/css/Documents.scss");

const attributes = {
    Document: "document",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "document-rows": {
                params?: {
                    Document: string;
                };

                document: Document | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export interface IDocumentRowsParams {
    Document: Param<Document>;
}

export class DocumentRows implements IDocumentRowHelperRow {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModes: IIvaModes;

    Document: ComponentParam<Document>;

    SynchronizePrices: ko.Computed;
    Description: ko.Observable<string> = ko.observable();
    AllRowsSelected: ko.Computed<boolean>;

    lastSelectedEntity: ISelectedEntityInlineInfo;
    lastSelectedEntityDescription: string;

    constructor(params: IDocumentRowsParams) {
        this.Document = ComponentUtils.parseParameter(params.Document, null);
        this.SynchronizePrices = this.Document().SynchronizePrices;

        this.AllRowsSelected = ko.computed({
            read: () =>
                !this.Document()
                    .Rows()
                    .any((r) => !r.IsSelected()),
            write: (value: boolean) => {
                this.Document()
                    .Rows()
                    .forEach((r) => r.IsSelected(value));
            },
        });

        useEffect(
            () => {
                this.Description(this.Document().RegisterCache?.DefaultSearchProvider);
            },
            [this.Document().FKRegister],
            true
        );
    }

    public async createNewRow(): Promise<void> {
        if (!this.isNewRowValid()) return;

        const newRow = await this.createNewRowModel();
        const r = await this.Document().createViewModel(newRow);
        if (this.lastSelectedEntity) {
            await r.OnEntitySelected(this.lastSelectedEntity, this.lastSelectedEntityDescription);
        } else {
            await Task.Delay(0); //Serve per rimandare di poco il reset della descrizione, altrimenti il Typeahead in interfaccia ci schiaccia il valore
        }

        this.Document().Rows.push(r);
        this.Description(this.Document().RegisterCache.DefaultSearchProvider);

        this.lastSelectedEntity = null;
        this.lastSelectedEntityDescription = null;
    }

    private async createNewRowModel(): Promise<IDocumentBuilderDocumentRows> {
        const document = this.Document();
        const documentCurrency = document.DocumentCurrency();

        const row = {
            AmountFormula: null,
            Id: this.Document().getNextId(),
            Amount: 0,
            ClosedAmount: 0,
            EntityType: document.DocumentType(),
            FKCurrency: documentCurrency.CurrencyId(),
            FKDocument: document.DocumentId(),
            FKVatCode: document.DefaultFKVatCode(),
            VatCode: document.DefaultVatCode(),
            ManuallyClosed: false,
            NetUnitPrice: 0,
            NetUnitPriceInDocumentCurrency: 0,
            Order: (document.Rows().max((r) => r.Order()) ?? 0) + 1,
            TotalPrice: 0,
            TotalPriceInDocumentCurrency: 0,
            UnitPrice: 0,
            UnitPriceInDocumentCurrency: 0,
            Currency: documentCurrency.Currency().Symbol, //TODO: Impostare la currency
            Description: this.Description(),
            Discounts: null,
            FKOffset: document.DefaultFKOffset(),
            OffsetCode: document.DefaultOffsetCode(),
        };

        if (
            document.CanHaveLetterOfAttempts() &&
            (await document.Recipient.hasValidLetterOfAttemptAtDate(
                document.Date(),
                document.CanSelectClosedLettersOfAttempt(),
                document.DocumentId(),
                document.DocumentType()
            ))
        ) {
            let vatMode = this.ivaModes.getIvaModes().firstOrDefault((i) => i.IdTipoIVA == row.FKVatCode);
            if (!vatMode.RequireLetterOfAttempt)
                vatMode = this.ivaModes.getIvaModes().firstOrDefault((i) => i.RequireLetterOfAttempt);

            if (vatMode) {
                row.FKVatCode = vatMode.IdTipoIVA;
                row.VatCode = vatMode.CodiceIVA;
            }
        }

        return row;
    }

    private isNewRowValid(): boolean {
        switch ((this.Description() ?? "").trim()) {
            case "@01":
            case "@02":
            case "@03":
            case "@04":
            case "@05":
            case "":
                return false;
        }

        return true;
    }

    public async OnEntitySelected(entity: ISelectedEntityInlineInfo | null, entityDescription: string): Promise<void> {
        this.lastSelectedEntity = entity;
        this.lastSelectedEntityDescription = entityDescription;
    }

    public ShowDueDateAutomation(item: unknown, event: Event): Promise<void> {
        const dialog = new DueDateAutomationDialog(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, dialog, "bottom");
    }

    public ShowDiscountAutomation(item: unknown, event: Event): Promise<void> {
        const dialog = new DiscountAutomationDialog(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, dialog, "bottom");
    }

    public ShowVatCodeAutomation(item: unknown, event: Event): Promise<void> {
        const dialog = new VatCodeAutomationDialog(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, dialog, "bottom");
    }

    public ShowOffsetAutomation(item: unknown, event: Event): Promise<void> {
        const dialog = new OffsetAutomationDialog(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, dialog, "left");
    }

    public ShowDueDateVariationAutomation(item: unknown, event: Event): Promise<void> {
        const dialog = new DueDateVariationAutomationDialog(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, dialog, "bottom");
    }

    public ShowRelatedWorkflowsAutomation(item: unknown, event: Event): Promise<void> {
        const popOver = new RelatedWorkflowsAutomationPopOver(this.Document());
        return this.dialogsService.ShowPopover(event.currentTarget as HTMLElement, popOver, "left");
    }
}

interface IDocumentRowParams {
    Document: Document;
    SynchronizePrices: Param<boolean>;
}

export class DocumentRow implements IDocumentRowHelperRow, IDataSourceListener {
    Document: Document;
    ShowAmounts: ko.Observable<boolean> = ko.observable();
    ShowVAT: ko.Observable<boolean> = ko.observable();
    SynchronizePrices: ComponentParam<boolean>;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;

    @LazyImport(nameof<IJobOrderService>())
    protected jobOrderService: IJobOrderService;

    @LazyImport(nameof<ITodoListService>())
    protected todolistService: ITodoListService;

    //---------------------------- CACHE -------------------------------
    VatTypeCache: ko.Observable<IIvaMode> = ko.observable();
    LetterOfAttemptCache: ko.Observable<ILetterOfAttempt> = ko.observable();

    //---------------------------- PROPERTIES --------------------------
    Id: ko.Observable<number> = ko.observable();
    AmountFormula: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable(0);
    Description: ko.Observable<string> = ko.observable();
    FKUoM: ko.Observable<number> = ko.observable();
    UoM: ko.Observable<string> = ko.observable();
    FKCurrency: ko.Observable<number> = ko.observable();
    Currency: ko.Observable<string> = ko.observable();
    UnitPrice: ko.Observable<number> = ko.observable(0);
    Discounts: ko.Observable<string> = ko.observable();
    NetUnitPrice: ko.Observable<number> = ko.observable(0);
    TotalPrice: ko.Observable<number> = ko.observable(0);
    Index: ko.Observable<number> = ko.observable();
    IndexForReorder: ko.Computed<number>;

    Order: ko.Observable<number> = ko.observable();
    FKVatCode: ko.Observable<number> = ko.observable();
    VatCode: ko.Observable<string> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    DueDate: ko.Observable<Date> = ko.observable();
    FKLetterOfAttempt: ko.Observable<number> = ko.observable();
    LetterOfAttempt: ko.Observable<string> = ko.observable();
    FKOffset: ko.Observable<number> = ko.observable();
    OffsetCode: ko.Observable<string> = ko.observable();

    UnitPriceInDocumentCurrency: ko.Observable<number> = ko.observable(0);
    NetUnitPriceInDocumentCurrency: ko.Observable<number> = ko.observable(0);
    TotalPriceInDocumentCurrency: ko.Observable<number> = ko.observable(0);

    ReferencingRows: ko.ObservableArray<IDocumentBuilderDocumentReferencingRows> = ko.observableArray();
    OriginatingRows: ko.ObservableArray<IDocumentBuilderDocumentOriginatingRows> = ko.observableArray();
    RelatedWorkflows: ko.ObservableArray<IDocumentRelatedWorkflow> = ko.observableArray([]);

    //----------------------------------- UTILITIES ---------------------------------
    HasAmount: ko.Computed<boolean>;
    HasPrice: ko.Computed<boolean>;
    HasReferences: ko.Computed<boolean>;
    HasRelatedWorkflows: ko.Computed<boolean>;
    HasDueDateChanges: ko.Observable<boolean> = ko.observable();
    CanBeClosed: ComponentParam<boolean>; //TODO: Alcuni tipi di documenti potrebbero non poter chiudere le righe
    CanBeClosedButIsOpen: ko.Computed<boolean>;
    IsAutomaticallyClosed: ko.Computed<boolean>;
    ClosedAmountOverflow: ko.Computed<boolean>;
    ClosedAmountOverflowMessage: ko.Computed<string>;
    CanCopy: ComponentParam<boolean>;
    IsLocked: ko.Computed<boolean>;
    ShouldShowLetterOfAttemptsSelector: ko.Computed<boolean>;
    IsSelected: ko.Observable<boolean> = ko.observable();

    TaxableTotal: ko.Computed<number>;
    TaxableTotalInEuro: ko.Computed<number>;
    TaxableTotalInEuroForVat: ko.Computed<number>;
    NonTaxableTotal: ko.Computed<number>;
    NonTaxableTotalInEuro: ko.Computed<number>;

    VatTypesDataSource: VatTypesDataSource = new VatTypesDataSource();
    OffsetsDataSource: OffsetsCodesDataSource = new OffsetsCodesDataSource();
    LettersOfAttemptsDataSource: LettersOfAttemptsDataSource = new LettersOfAttemptsDataSource();

    FormattedRelatedWorkflows: ko.Observable<string> = ko.observable();
    RelatedWorkflowsTooltipText: ko.Observable<string> = ko.observable();
    TotalAssignedToWorkflows: ko.Observable<number> = ko.observable();
    AmountAssignedToWorkflows: ko.Observable<number> = ko.observable();

    ReferencedArticleStocksInfo: ko.Observable<IWarehouseEntityStockInfo> = ko.observable();
    ReferencedArticleStocksInfoStatus: ko.Computed<ArticleStockStatus>;

    UsingCalculator: ko.Observable<boolean> = ko.observable(false);

    private RelatedWorkflowsObserver: ko.Computed<void>;

    private updatingPrices = false;
    private DefaultWorkflowId: number;
    private loading: boolean;

    constructor(
        private originalRow: IDocumentBuilderDocumentRows,
        referencingRows: IDocumentBuilderDocumentReferencingRows[],
        originatinRows: IDocumentBuilderDocumentOriginatingRows[],
        relatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[],
        params: IDocumentRowParams
    ) {
        this.Document = params.Document;
        this.ShowAmounts = this.Document.ShowAmounts;
        this.ShowVAT = this.Document.CanHaveVAT;
        this.CanBeClosed = this.Document.CanCloseRows;
        this.CanCopy = this.Document.CanCopy;
        this.SynchronizePrices = ComponentUtils.parseParameter(params.SynchronizePrices, true);

        this.ReferencingRows(referencingRows);
        this.OriginatingRows(originatinRows);
        this.RelatedWorkflows(relatedWorkflows);

        this.IndexForReorder = ko.computed({
            read: this.Index,
            write: (newIndex) => {
                this.Document.moveRowToIndex(this, newIndex);
            },
            owner: this,
        });

        this.HasAmount = ko.computed(() => {
            return !!this.Amount();
        });

        this.HasPrice = ko.computed(() => {
            return !!this.NetUnitPriceInDocumentCurrency();
        });

        this.HasReferences = ko.computed(() => {
            return this.OriginatingRows().length > 0 || this.ReferencingRows().length > 0;
        });

        this.HasRelatedWorkflows = ko.computed(() => {
            return (this.RelatedWorkflows() ?? []).length > 0;
        });

        this.IsAutomaticallyClosed = ko.computed(() => {
            return Math.abs(this.ClosedAmount()) >= Math.abs(this.Amount()) && !this.ManuallyClosed();
        });

        this.ClosedAmountOverflow = ko.computed(() => {
            return this.ClosedAmount() > this.Amount();
        });

        this.ClosedAmountOverflowMessage = ko.computed(() => {
            return String.format(
                TextResources.ProlifeSdk.ClosedAmountOverflowMessage,
                this.ClosedAmount() || "0",
                this.Amount() || "0"
            );
        });

        this.IsLocked = ko.computed(() => {
            return this.ReferencingRows().length > 0;
        });

        this.ShouldShowLetterOfAttemptsSelector = ko.computed(() => {
            const vatType = this.VatTypeCache();
            return this.Document.CanHaveLetterOfAttempts() && vatType && vatType.RequireLetterOfAttempt;
        });

        this.TaxableTotal = ko.computed(() => {
            const vatType = this.VatTypeCache();
            if (!vatType || vatType.Imponibile == 1) return 0;

            return this.TotalPriceInDocumentCurrency();
        });

        this.TaxableTotalInEuro = ko.computed(() => {
            const vatType = this.VatTypeCache();
            if (!vatType || vatType.Imponibile == 1) return 0;

            return this.TotalPrice();
        });

        this.TaxableTotalInEuroForVat = ko.computed(() => {
            const vatType = this.VatTypeCache();
            if (!vatType || vatType.Imponibile == 1) return 0;

            const documentCurrency = this.Document.DocumentCurrency();
            if (!documentCurrency) return 0;

            return CurrencyUtils.applyCurrencyExchange(
                this.TotalPriceInDocumentCurrency(),
                documentCurrency,
                true
            ).Round(2);
        });

        this.NonTaxableTotal = ko.computed(() => {
            const vatType = this.VatTypeCache();
            if (vatType && vatType.Imponibile == 0) return 0;

            return this.TotalPriceInDocumentCurrency();
        });

        this.NonTaxableTotalInEuro = ko.computed(() => {
            const vatType = this.VatTypeCache();
            if (vatType && vatType.Imponibile == 0) return 0;

            return this.TotalPrice();
        });

        this.CanBeClosedButIsOpen = ko.computed(() => {
            return this.CanBeClosed() && !this.IsAutomaticallyClosed() && !this.ManuallyClosed() && this.Amount() != 0;
        });

        this.RelatedWorkflowsObserver = ko.computed(() => {
            this.updateRelatedWorkflowsTotalsInfo();
        });

        this.ReferencedArticleStocksInfoStatus = ko.computed(() => {
            const stockInfo = this.ReferencedArticleStocksInfo();
            if (!stockInfo) return null;

            if (stockInfo.StockAmount - stockInfo.ReservedAmount > stockInfo.MinimumAmount)
                return ArticleStockStatus.AvailableAmountGreaterThenMinimumAmount;

            if (
                stockInfo.StockAmount - stockInfo.ReservedAmount < stockInfo.MinimumAmount &&
                stockInfo.StockAmount - stockInfo.ReservedAmount >= 0
            )
                return ArticleStockStatus.AvailableAmountLowerThenMinimumAmountButGreaterOrEqualThenZero;

            if (
                stockInfo.StockAmount - stockInfo.ReservedAmount < 0 &&
                stockInfo.StockAmount - stockInfo.ReservedAmount + stockInfo.OrderedAmount >= 0
            )
                return ArticleStockStatus.AvailableAmountLowerThenZeroButAvailableAmountPlusOrderedAmountGreatherOrEqualThenZero;

            if (stockInfo.StockAmount - stockInfo.ReservedAmount + stockInfo.OrderedAmount < 0)
                return ArticleStockStatus.AvailableAmountPlusOrderedAmountLowerThenZero;

            return null;
        });

        this.Amount.subscribe(this.updateTotals.bind(this, true, false));
        this.Discounts.subscribe(this.updateTotals.bind(this, false, false));
        this.UnitPriceInDocumentCurrency.subscribe(this.updateTotals.bind(this, false, false));
        this.NetUnitPriceInDocumentCurrency.subscribe(this.updateTotals.bind(this, false, true));

        this.LettersOfAttemptsDataSource.setShowClosed(this.Document.CanSelectClosedLettersOfAttempt());
        this.Document.Date.subscribe((value: Date) => {
            this.LettersOfAttemptsDataSource.setReferenceDate(value ?? new Date());
        });

        this.LettersOfAttemptsDataSource.setReferenceDate(this.Document.Date() ?? new Date());
    }

    public async showCalculator(target: any, evt: Event): Promise<void> {
        const calculator = new RowValuesCalculator({
            row: this,
            documentLayout: this.Document.LayoutId(),
            onAmountCalculated: (amount) => this.Amount(amount),
            onUnitPriceCalculated: (unitPrice) => this.UnitPriceInDocumentCurrency(unitPrice),
            onNetUnitPriceCalculated: (netUnitPrice) => this.NetUnitPriceInDocumentCurrency(netUnitPrice),
            onDiscountCalculated: (discount) => this.Discounts(discount),
        });

        this.UsingCalculator(true);
        try {
            await this.dialogsService.ShowPopoverComponent(
                evt.currentTarget as HTMLElement,
                calculator,
                "right",
                "body",
                "body"
            );
        } catch (e) {
            console.log(e);
        }
        this.UsingCalculator(false);
    }

    public async showArticleStockInfo(target: any, evt: Event): Promise<void> {
        const popover = new ArticleStockInfoPopoverUI({ stockInfo: this.ReferencedArticleStocksInfo() });
        return popover.show(evt.currentTarget as HTMLElement);
    }

    public updateRelatedWorkflowsTotalsInfo() {
        const relatedWorkflows = this.RelatedWorkflows() || [];
        this.FormattedRelatedWorkflows(this.createFormattedRelatedWorkflowsString(relatedWorkflows));
        const { amount, total } = this.calculateAmountAndTotalAssignedToWorkflows(relatedWorkflows);
        this.AmountAssignedToWorkflows(amount);
        this.TotalAssignedToWorkflows(total);
        const currency = this.Currency();
        this.RelatedWorkflowsTooltipText(
            String.format(
                TextResources.Invoices.RelatedWorkflowsTooltip,
                this.AmountAssignedToWorkflows(),
                numeral(this.TotalAssignedToWorkflows() ?? 0).format("0,0.00[0]") + " " + currency
            )
        );
    }

    private calculateAmountAndTotalAssignedToWorkflows(relatedWorkflows: IDocumentRelatedWorkflow[]): {
        amount: number;
        total: number;
    } {
        const result = { amount: 0, total: 0 };

        for (const rw of relatedWorkflows ?? []) {
            result.amount += rw.WorkflowAmount;
            result.total += result.amount * (this.NetUnitPriceInDocumentCurrency() ?? 0);
        }

        return result;
    }

    private createFormattedRelatedWorkflowsString(relatedWorkflows: IDocumentRelatedWorkflow[]): string {
        if (relatedWorkflows.length === 0) return TextResources.ProlifeSdk.NotAvailable;

        if (relatedWorkflows.length === 1) return this.RelatedWorkflows()[0].WorkflowName;

        return TextResources.ProlifeSdk.MultipleRelatedWorkflows;
    }

    private updateTotals(fromAmount: boolean, fromNetUnitPrice: boolean) {
        if (this.RelatedWorkflows.peek().length > 0) this.updateRelatedWorkflowsTotalsInfo();

        if (this.updatingPrices || this.loading) return;
        this.updatingPrices = true;

        if (!fromAmount) {
            //Se non è un update scatenato da Amount ricalcolo anche i prezzi unitari
            if (this.SynchronizePrices()) {
                //Se devo tenere sincronizzati i prezzi unitari
                const discounts = DiscountsUtilities.calculateDiscount(this.Discounts());
                let unitPrice = this.UnitPriceInDocumentCurrency();
                const netUnitPrice = this.NetUnitPriceInDocumentCurrency();

                if (!unitPrice)
                    // Se il prezzo unitario è zero lo schiaccio con il prezzo unitario netto, che è l'unico campo editabile dall'utente in questo caso, altrimenti non si riesce più ad inserire un prezzo diverso da zero
                    unitPrice = netUnitPrice;

                if (this.Discounts()) this.NetUnitPriceInDocumentCurrency(unitPrice * discounts);

                this.UnitPriceInDocumentCurrency(this.NetUnitPriceInDocumentCurrency());
                this.Discounts(undefined);
            } else {
                if (fromNetUnitPrice) {
                    //Se arrivo da una modifica al NetUnitPrice imposto un prezzo esplicito
                    this.Discounts(undefined);
                    if (this.NetUnitPriceInDocumentCurrency() > this.UnitPriceInDocumentCurrency())
                        this.UnitPriceInDocumentCurrency(this.NetUnitPriceInDocumentCurrency());
                } else {
                    const discounts = DiscountsUtilities.calculateDiscount(this.Discounts());
                    this.NetUnitPriceInDocumentCurrency(this.UnitPriceInDocumentCurrency() * discounts);
                }
            }
        }

        const documentCurrency = this.Document.DocumentCurrency();
        this.TotalPriceInDocumentCurrency(
            CurrencyUtils.applyCurrencyRoundingRules(
                this.NetUnitPriceInDocumentCurrency() * this.Amount(),
                documentCurrency
            )
        );

        this.UnitPrice(this.UnitPriceInDocumentCurrency().ToEuro(documentCurrency));
        this.NetUnitPrice(this.NetUnitPriceInDocumentCurrency().ToEuro(documentCurrency));
        this.TotalPrice(this.TotalPriceInDocumentCurrency().ToEuro(documentCurrency));

        this.updatingPrices = false;
    }

    onItemSelected(
        sender: IDataSource,
        model: IDataSourceModel<string | number, unknown, string | number, unknown>
    ): void {
        if (sender == this.VatTypesDataSource) {
            const vatTypeModel = model as IVatTypesDataSourceModel;
            this.VatTypeCache(vatTypeModel.model);

            if (this.loading) return;

            this.FKVatCode(vatTypeModel.id);
            this.VatCode(vatTypeModel.model.CodiceIVA);
        } else if (sender == this.OffsetsDataSource) {
            if (this.loading) return;

            const offset = model as IOffsetsCodesDataSourceModel;
            this.FKOffset(offset.id);
            this.OffsetCode(offset.model.Code);
        } else if (sender === this.LettersOfAttemptsDataSource) {
            if (this.loading) return;

            const loaModel = model as ILetterOfAttemptDataSourceModel;
            this.LetterOfAttempt(loaModel?.title);
            this.LetterOfAttemptCache(loaModel?.model);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    public async load() {
        this.loading = true;

        this.Id(this.originalRow.Id);
        this.AmountFormula(this.originalRow.AmountFormula);
        this.Amount(this.originalRow.Amount);
        this.Description(this.originalRow.Description);
        this.FKUoM(this.originalRow.FKUoM);
        this.UoM(this.originalRow.UoM);
        this.FKCurrency(this.originalRow.FKCurrency);
        this.Currency(this.originalRow.Currency);
        this.UnitPrice(this.originalRow.UnitPrice);
        this.Discounts(this.originalRow.Discounts);
        this.NetUnitPrice(this.originalRow.NetUnitPrice);
        this.TotalPrice(this.originalRow.TotalPrice);
        this.Index(this.originalRow.Index);
        this.Order(this.originalRow.Order);

        await this.setVat(this.originalRow.FKVatCode);

        this.ClosedAmount(this.originalRow.ClosedAmount);
        this.ManuallyClosed(this.originalRow.ManuallyClosed);
        this.DueDate(this.originalRow.DueDate);
        this.FKLetterOfAttempt(this.originalRow.FKLetterOfAttempt);
        this.LetterOfAttempt(this.originalRow.LetterOfAttemptDescription);
        await this.OffsetsDataSource.setSharedMode(true);
        this.FKOffset(this.originalRow.FKOffset);
        this.OffsetCode(this.originalRow.OffsetCode);
        //await this.OffsetsDataSource.selectByIds(this.originalRow.FKOffset);

        this.UnitPriceInDocumentCurrency(this.originalRow.UnitPriceInDocumentCurrency);
        this.NetUnitPriceInDocumentCurrency(this.originalRow.NetUnitPriceInDocumentCurrency);
        this.TotalPriceInDocumentCurrency(this.originalRow.TotalPriceInDocumentCurrency);

        this.loading = false;
    }

    public async setVat(vatId: number): Promise<void> {
        const vatCodes = await this.VatTypesDataSource.getById(null, [vatId]);

        const vatCode = vatCodes.length > 0 ? vatCodes[0].model : null;

        this.VatTypeCache(vatCode);
        this.FKVatCode(vatCode?.IdTipoIVA);
        this.VatCode(vatCode?.CodiceIVA);
    }

    public SwitchClosure() {
        this.ManuallyClosed(!this.ManuallyClosed());
    }

    public async OnEntitySelected(entity: ISelectedEntityInlineInfo | null, entityDescription: string): Promise<void> {
        const provider = this.Document.InlineRefProvider().firstOrDefault(
            (p) => p.ActivationCode == entity.ProviderActivationCode
        );

        this.Amount(this.Amount() || 1);

        const reference = await provider.getReference(this.Id(), entity.Entity, this.Amount());
        this.OriginatingRows([reference]);

        const documentCurrency = this.Document.DocumentCurrency();

        this.updatingPrices = true;

        this.Amount(reference.Amount);
        this.UoM(reference.UnitOfMeasure);
        this.UnitPrice(reference.UnitPrice);
        this.UnitPriceInDocumentCurrency(reference.UnitPrice.ToCurrency(documentCurrency, false));
        this.Discounts(reference.Discounts);
        if (!reference.Discounts) {
            this.NetUnitPrice(reference.NetUnitPrice);
            this.NetUnitPriceInDocumentCurrency(reference.NetUnitPrice.ToCurrency(documentCurrency, false));
        }

        this.updatingPrices = false;
        this.updateTotals(false, false);

        if (reference.SourceEntityType === ProlifeSdk.WarehouseArticleEntityTypeCode) this.loadArticleStocksInfo();
    }

    private async loadArticleStocksInfo() {
        if (!this.Document.CanViewArticleStockInfoOnRows) return;

        const warehouseId =
            this.Document.DocumentType() === ProlifeSdk.WarehouseLoadEntityTypeCode
                ? this.Document.WarehouseId()
                : this.Document.SourceWarehouseId();
        if (!warehouseId) return;

        try {
            const reference = this.OriginatingRows().firstOrDefault();
            const rowRefMapping: IRowRefMapping = {
                DestEntityKey: this.Id(),
                DestEntityType: this.Document.DocumentType(),
                SourceEntityKey: reference.SourceEntityKeyId,
                SourceEntityType: reference.SourceEntityType,
            };
            const articleStocksInfo = await this.documentsService.GetWarehouseEntitiesStocksInfoOnRows(
                [rowRefMapping],
                warehouseId
            );

            this.ReferencedArticleStocksInfo(articleStocksInfo.firstOrDefault());
        } catch (e) {
            this.infoToastService.Error(TextResources.Invoices.GetWarehouseEntitiesStocksInfoOnRowsError);
        }
    }

    public async showLetterOfAttemptSelectionPopUp(row: DocumentRow, evt: MouseEvent): Promise<void> {
        const popUp = new LetterOfAttemptSelectionPopOver(this);
        return popUp.show(evt.currentTarget as HTMLElement);
    }

    public canChangeDiscountCatalog(): boolean {
        return (
            this.Document.IsCustomerDocument() &&
            this.OriginatingRows.length == 1 &&
            this.OriginatingRows[0].SourceEntityType == ProlifeSdk.WarehouseArticleEntityTypeCode
        );
    }

    public async doChangePrice(customerGroupId: number): Promise<void> {
        const result = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
            articleId: this.OriginatingRows[0].SourceEntityKeyId,
            customerId: this.Document.Recipient.Id(),
            isCustomer: this.Document.IsCustomerDocument(),
            referenceDate: this.Document.Date(),
            warehouseId: this.Document.SourceWarehouseId(),
            overrideGroupId: customerGroupId,
        });

        this.internalChangePrice(result);
    }

    public async changeDiscountCatalog(item: unknown, event: Event): Promise<void> {
        if (this.OriginatingRows().length == 0) {
            this.infoToastService.Warning(
                "Impossibile cambiare la scontistica perchè la riga non fa riferimento a nessun articolo."
            );
            return;
        }

        if (this.OriginatingRows().length != 1) {
            this.infoToastService.Warning(
                "Impossibile cambiare la scontistica perchè il contenuto della riga è composto da più di un elemento."
            );
            return;
        }

        if (this.OriginatingRows()[0].SourceEntityType != "WAR") {
            this.infoToastService.Warning(
                "Impossibile cambiare la scontistica perchè il contenuto della riga non è un articolo di magazzino."
            );
            return;
        }

        const selector = new DocumentRowDiscountCatalogSelectorDialog(
            this.OriginatingRows()[0].SourceEntityKeyId,
            this.Document.Recipient.Id(),
            this.Document.CanSelectCustomers(),
            this.Document.SourceWarehouseId(),
            this.Document.Date()
        );
        const result = await this.dialogsService.ShowPopover<IResolvedCustomerDiscountCatalogArticleSuggestion>(
            event.currentTarget as HTMLElement,
            selector,
            "bottom"
        );
        if (!result) return;

        this.internalChangePrice(result);
    }

    private internalChangePrice(
        result: IResolvedCustomerDiscountCatalogArticleWithCatalog | IResolvedCustomerDiscountCatalogArticleSuggestion
    ) {
        const documentCurrency = this.Document.DocumentCurrency();

        const hasExplicitPrice = result.ExplicitPrice && result.ExplicitPrice > 0;
        const unitPrice = hasExplicitPrice ? result.ExplicitPrice : result.UnitPrice ?? 0;

        if (DiscountCatalogRowMode.getDiscountSign(result.Mode) > 0) {
            //E' un ricarico quindi non mostro il ricarico nel documento
            this.UnitPriceInDocumentCurrency(unitPrice.ToCurrency(documentCurrency));
            this.Discounts(undefined);
        } else {
            const discountString = (
                (result.Discount0 > 0 ? result.Discount0 + "% " : "") +
                (result.Discount1 > 0 ? result.Discount1 + "% " : "") +
                (result.Discount2 > 0 ? result.Discount2 + "% " : "") +
                (result.Discount3 > 0 ? result.Discount3 + "% " : "") +
                (result.Discount4 > 0 ? result.Discount4 + "% " : "")
            ).trim();

            this.UnitPriceInDocumentCurrency(unitPrice.ToCurrency(documentCurrency));
            this.Discounts(hasExplicitPrice ? undefined : discountString);
        }
    }

    public async ShowReferences() {
        const refsInfoBalloon = new ReferencesDetailsBalloon(
            this.Document.Recipient.Id,
            this.Document.SourceWarehouseId
        );
        refsInfoBalloon.LoadReferences(this.OriginatingRows(), this.ReferencingRows(), this);
        let vm = new DocumentReferencesDialog(refsInfoBalloon);
        return this.dialogsService.ShowModal<void>(vm, "fullscreen", {}, vm.templateUrl, vm.templateName);
    }

    public ViewDueDateChangeHistory() {
        this.jobOrderService
            .ShowEditExpireDialog(this.Id(), this.Document.DocumentType(), true)
            .then((result: Date) => {
                if (result) {
                    this.HasDueDateChanges(true);
                    this.DueDate(result);
                }
            });
    }

    public async showRelatedWorkflowsPopUp(row: DocumentRow, evt: MouseEvent): Promise<void> {
        const popUp = new RelatedWorkflowsPopOver(this, this.Document.DocumentCurrency());
        return popUp.show(evt.currentTarget as HTMLElement);
    }

    public async showReadonlyRelatedWorkflowsPopUp(row: DocumentRow, evt: MouseEvent): Promise<void> {
        const popUp = new RelatedWorkflowsPopOver(this, this.Document.DocumentCurrency(), true);
        return popUp.show(evt.currentTarget as HTMLElement);
    }

    public GetDefaultWorkflowId(): number {
        return this.DefaultWorkflowId;
    }

    public async setDefaultWorkflow(workflowId: number, createRelatedWorkflow: boolean): Promise<void> {
        this.DefaultWorkflowId = workflowId;

        if (!createRelatedWorkflow) return;

        if (this.RelatedWorkflows.length === 0 && !!workflowId) {
            const workflow: ITodoListWorkflow = await this.todolistService.GetWorkflow(workflowId);

            if (!workflow) return;

            this.RelatedWorkflows.push({
                Id: null,
                WorkflowId: workflowId,
                WorkflowName: workflow.Title,
                DocumentId: null,
                DocumentProtocolId: null,
                RowId: this.Id(),
                RowAmount: this.Amount(),
                RowPrice: this.NetUnitPrice(),
                DocumentType: this.Document.DocumentType(),
                RowDescription: this.Description(),
                RowUoM: this.UoM(),
                WorkflowAmount: this.Amount(),
            });
        }
    }

    public setCustomerId(customerId: number, onLoading = false): void {
        this.LettersOfAttemptsDataSource.setCustomerId(customerId);

        if (onLoading) return;

        this.FKLetterOfAttempt(null);
        this.LetterOfAttempt(null);
        this.LetterOfAttemptCache(null);
    }

    getData(): IDocumentBuilderDocumentRows {
        const row: IDocumentBuilderDocumentRows = {
            AmountFormula: this.AmountFormula(),
            Amount: this.Amount(),
            ClosedAmount: this.ClosedAmount(),
            EntityType: this.Document.DocumentType(),
            FKCurrency: this.FKCurrency(),
            FKDocument: this.Document.DocumentId(),
            Id: this.Id(),
            ManuallyClosed: this.ManuallyClosed(),
            NetUnitPrice: this.NetUnitPrice(),
            NetUnitPriceInDocumentCurrency: this.NetUnitPriceInDocumentCurrency(),
            Order: this.Order(),
            TotalPrice: this.TotalPrice(),
            TotalPriceInDocumentCurrency: this.TotalPriceInDocumentCurrency(),
            UnitPrice: this.UnitPrice(),
            UnitPriceInDocumentCurrency: this.UnitPriceInDocumentCurrency(),
            Currency: this.Currency(),
            Description: this.Description(),
            Discounts: this.Discounts(),
            DueDate: this.DueDate(),
            FKLetterOfAttempt: this.FKLetterOfAttempt(),
            FKOffset: this.FKOffset(),
            FKUoM: this.FKUoM(),
            FKVatCode: this.FKVatCode(),
            Index: this.Index(),
            LetterOfAttemptDescription: this.LetterOfAttempt(),
            OffsetCode: this.OffsetCode(),
            UoM: this.UoM(),
            VatCode: this.VatCode(),
        };
        return row;
    }
}

function renderArticleStockInfoButton() {
    let row: DocumentRow;
    let articleStockInfo: IWarehouseEntityStockInfo;

    return (
        <ko-bind data-bind={{ if: row.Document.CanViewArticleStockInfoOnRows }}>
            <ko-bind data-bind={{ with: row.ReferencedArticleStocksInfo, as: "articleStockInfo" }}>
                <button
                    type="button"
                    class="btn article-stock-info btn-xs"
                    style={{ fontSize: "9px" }}
                    data-bind={{
                        asyncClick: row.showArticleStockInfo.bind(row),
                        css: {
                            green: row.ReferencedArticleStocksInfoStatus() === 0,
                            yellow: row.ReferencedArticleStocksInfoStatus() === 1,
                            orange: row.ReferencedArticleStocksInfoStatus() === 2,
                            red: row.ReferencedArticleStocksInfoStatus() === 3,
                            grey:
                                row.ReferencedArticleStocksInfoStatus() !== 0 &&
                                row.ReferencedArticleStocksInfoStatus() !== 1 &&
                                row.ReferencedArticleStocksInfoStatus() !== 2 &&
                                row.ReferencedArticleStocksInfoStatus() !== 3,
                        },
                    }}
                    title={TextResources.Invoices.ArticleAvailableAmountTooltip}
                >
                    <span data-bind={{ numberText: articleStockInfo.AvailableAmount, format: "0,0[.][00]" }}></span>
                </button>
            </ko-bind>
        </ko-bind>
    );
}

function renderRowValuesCalculator() {
    let row: DocumentRow;

    return (
        <ko-ifnot data-bind="row.IsLocked">
            <button
                type="button"
                className="btn btn-primary btn-xs row-calculator"
                data-bind={{ asyncClick: row.showCalculator.bind(row) }}
            >
                <i
                    className="fa fa-building-o"
                    style={{ transform: "rotate(180deg)", position: "relative", top: "-1px" }}
                ></i>
            </button>
        </ko-ifnot>
    );
}

function StandardRowReadOnly() {
    let $data: unknown;
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ let: { rows: $data } }}>
            <table class="table table-condensed no-border table-advance" data-bind={{ with: rows.Document, as: "doc" }}>
                <thead>
                    <tr>
                        <th style="width: 3%">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 6%">
                            <i class="fa fa-chain"></i>
                        </th>
                        <th style="width: 3%">
                            <input
                                type="checkbox"
                                data-bind={{ visible: doc.CanCopy, checked: rows.AllRowsSelected }}
                            />
                            {TextResources.Invoices.DocumentRowNumber}
                        </th>
                        <th style="width: 43%;">{TextResources.Invoices.DocumentRowDescription}</th>
                        <th style="width: 8%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 13%; text-align: right">{TextResources.Invoices.DocumentRowNetUnitPrice}</th>
                        <ko-bind data-bind={{ if: doc.CanHaveVAT }}>
                            <th style="width: 8%; text-align: center">{TextResources.Invoices.DocumentRowVATCode}</th>
                        </ko-bind>
                        <th style="width: 13%; text-align: right">{TextResources.Invoices.DocumentRowTotalPrice}</th>
                    </tr>
                </thead>
                <tbody
                    data-bind={{
                        foreach: { data: doc.Rows, as: "row" },
                        documentsRowsResizeInterceptor: ".offsets-table-body",
                    }}
                >
                    <tr>
                        <td>
                            <ko-bind data-bind={{ if: row.HasAmount() && row.CanBeClosed() }}>
                                <ko-bind data-bind={{ if: row.ManuallyClosed }}>
                                    <i class="fa fa-lock"></i>
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: row.ManuallyClosed }}>
                                    <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                        <i class="fa fa-check" data-bind={{ visible: !row.ClosedAmountOverflow() }}></i>
                                        <i
                                            class="fa fa-arrow-up"
                                            data-bind={{
                                                visible: row.ClosedAmountOverflow,
                                                attr: { "data-original-title": row.ClosedAmountOverflowMessage },
                                                tooltip: {},
                                            }}
                                        ></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                        <span
                                            data-bind={{
                                                numberText: row.ClosedAmount,
                                                format: "0,0.00[000000]",
                                            }}
                                        ></span>
                                    </ko-bind>
                                </ko-bind>
                            </ko-bind>
                        </td>
                        <td>
                            <a
                                href="#"
                                class="btn blue btn-xs"
                                data-bind={{
                                    click: row.ShowReferences,
                                    visible: !doc.CanCopy() && row.HasReferences() && row.HasAmount(),
                                }}
                            >
                                <i class="fa fa-chain"></i>
                            </a>
                        </td>
                        <td>
                            <input
                                autoComplete="off"
                                type="checkbox"
                                data-bind={{ visible: row.CanCopy, checked: row.IsSelected }}
                            />
                            <ko-bind data-bind={{ text: row.Index }}></ko-bind>
                        </td>
                        <td data-bind={{ text: row.Description }} style="white-space: pre-wrap;"></td>
                        <td>
                            <div
                                class="text-center"
                                data-bind={{
                                    numberText: row.Amount,
                                    format: "0,0.00[000000]",
                                    attr: { "data-amount": row.Amount },
                                }}
                            ></div>
                        </td>
                        <td data-bind={{ visible: row.ShowAmounts }}>
                            <div
                                class="text-right"
                                data-bind={{
                                    moneyTextEx: row.NetUnitPriceInDocumentCurrency,
                                    extendedMoneyFormat: true,
                                    currencySymbol: row.Currency,
                                    attr: { "data-total": row.NetUnitPriceInDocumentCurrency },
                                    visible: row.HasAmount(),
                                }}
                            ></div>
                        </td>
                        <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                            <td>
                                <div
                                    class="text-center"
                                    data-bind={{ text: row.VatCode, visible: row.HasAmount() }}
                                ></div>
                            </td>
                        </ko-bind>
                        <td data-bind={{ visible: row.ShowAmounts() }}>
                            <div
                                class="text-right"
                                data-bind={{
                                    moneyTextEx: row.TotalPriceInDocumentCurrency,
                                    currencySymbol: row.Currency,
                                    visible: row.HasAmount() && row.HasPrice(),
                                }}
                            ></div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <OffsetsTableReadOnly></OffsetsTableReadOnly>
        </ko-bind>
    );
}

function StandardRowNonReadOnly() {
    let $component: DocumentRows;
    let $data: DocumentRow;
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ let: { rows: $component } }}>
            <table
                class="table table-condensed no-border table-advance"
                data-bind={{ with: rows.Document, as: "doc" }}
                style="table-layout: fixed"
            >
                <thead>
                    <tr>
                        <th style="width: 30px">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 30px"></th>
                        <th style="width: 30px">
                            <i class="fa fa-chain"></i>
                        </th>
                        <th style="width: 40px">{TextResources.Invoices.DocumentRowNumber}</th>
                        <th data-bind={{ style: { width: doc.ShowAmounts() ? "43%" : "76%" } }}>
                            {TextResources.Invoices.DocumentRowDescription}
                        </th>
                        <th style="width: 10%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowNetUnitPrice}
                        </th>
                        <ko-bind data-bind={{ if: doc.CanHaveVAT() && doc.ShowAmounts() }}>
                            <th style="width: 8%; text-align: center">
                                {TextResources.Invoices.DocumentRowVATCode}
                                <button
                                    class="btn btn-primary btn-xs btn-invoice"
                                    data-bind={{ asyncClick: rows.ShowVatCodeAutomation.bind(rows) }}
                                >
                                    <i class="fa fa-chevron-down"></i>
                                </button>
                            </th>
                        </ko-bind>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowTotalPrice}
                        </th>
                    </tr>
                </thead>
                <tbody data-bind={{ documentsRowsResizeInterceptor: ".offsets-table-body:first-of-type" }}>
                    <ko-bind data-bind={{ foreach: { data: doc.Rows, as: "row" } }}>
                        <tr data-bind={{ css: { "calculator-opened": row.UsingCalculator } }}>
                            <td>
                                <ko-bind
                                    data-bind={{ if: row.HasAmount() && row.CanBeClosed() && !row.ManuallyClosed() }}
                                >
                                    <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                        <i class="fa fa-check" data-bind={{ hidden: row.ClosedAmountOverflow }}></i>
                                        <i
                                            class="fa fa-arrow-up"
                                            data-bind={{
                                                visible: row.ClosedAmountOverflow,
                                                attr: { title: row.ClosedAmountOverflowMessage },
                                            }}
                                        ></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                        <span
                                            data-bind={{
                                                numberText: row.ClosedAmount,
                                                format: "0,0.00[000000]",
                                            }}
                                        ></span>
                                    </ko-bind>
                                </ko-bind>
                            </td>
                            <td>{renderManualClosingButton()}</td>
                            <td>
                                <a
                                    href="#"
                                    class="btn blue btn-xs"
                                    data-bind={{
                                        click: row.ShowReferences,
                                        visible: !row.CanCopy() && row.HasReferences() && row.HasAmount(),
                                    }}
                                >
                                    <i class="fa fa-chain"></i>
                                </a>
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center index"
                                    data-bind={{ value: row.IndexForReorder }}
                                ></input>
                            </td>
                            <td>
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    data-bind={{
                                        selectOnFocus: {},
                                        disable: row.IsLocked,
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $data,
                                        },
                                        autoHeight: true,
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center amount"
                                    style={{ width: "70%" }}
                                    data-bind={{
                                        disable: row.IsLocked,
                                        numberValue: row.Amount,
                                        format: "0,0.00[000000]",
                                        attr: { "data-amount": row.Amount },
                                        selectOnFocus: {},
                                    }}
                                />
                                {renderArticleStockInfoButton()}
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts }}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-right"
                                    data-bind={{
                                        style: { background: row.HasAmount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyValueEx: row.NetUnitPriceInDocumentCurrency,
                                        extendedMoneyFormat: true,
                                        maxNumberOfDecimals: 8,
                                        currencySymbol: row.Currency,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                        attr: {
                                            "data-total": row.NetUnitPriceInDocumentCurrency,
                                            "data-amount": row.Amount,
                                            readonly: !row.HasAmount() && !row.UsingCalculator(),
                                        },
                                    }}
                                />
                            </td>
                            <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                                <td>
                                    <div class="unit-price">
                                        <select2
                                            class="text-center"
                                            cssClass="document-control no-arrow"
                                            noFormControl={true}
                                            simple={true}
                                            allowClear={false}
                                            value={() => "FKVatCode"}
                                            dataSource={() => "VatTypesDataSource"}
                                            listener={() => "row"}
                                            readOnly={() => "IsLocked() || !HasAmount()"}
                                            placeholder="Codice IVA"
                                        ></select2>
                                        <button
                                            class="btn btn-primary change-discount-catalog"
                                            data-bind={{
                                                asyncClick: row.showLetterOfAttemptSelectionPopUp,
                                                visible: row.ShouldShowLetterOfAttemptsSelector,
                                            }}
                                        >
                                            <i class="fa fa-euro"></i>
                                        </button>
                                    </div>
                                </td>
                            </ko-bind>
                            <td data-bind={{ visible: row.ShowAmounts() }} style={{ position: "relative" }}>
                                <div
                                    class="text-right"
                                    data-bind={{
                                        style: { background: row.HasAmount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyTextEx: row.TotalPriceInDocumentCurrency,
                                        currencySymbol: row.Currency,
                                        visible: row.HasAmount() && row.HasPrice(),
                                    }}
                                ></div>
                                {renderRowValuesCalculator()}
                            </td>
                        </tr>
                    </ko-bind>
                    <tr>
                        <td colSpan={4}></td>
                        <td data-bind={{ attr: { colspan: doc.ShowAmounts() ? (doc.CanHaveVAT() ? "5" : "4") : "1" } }}>
                            <form action="#">
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    placeholder={TextResources.Invoices.DocumentRowNewRow}
                                    data-bind={{
                                        callOnEnterOrTab: {
                                            functionToCall: rows.createNewRow.bind(rows),
                                            jumpToLastFieldOfType: ".amount",
                                            ignoreEnter: true,
                                            selectOnFocus: {},
                                            submitOnLostFocus: true,
                                        },
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $component,
                                        },
                                        valueUpdate: "afterkeydown",
                                        autoHeight: true,
                                    }}
                                />
                            </form>
                        </td>
                    </tr>
                </tbody>
            </table>

            <OffsetsTableNonReadOnly></OffsetsTableNonReadOnly>
        </ko-bind>
    );
}

function StandardRow() {
    let rows: DocumentRows;

    return (
        <ko-bind data-as={{ rows }}>
            <ko-bind data-bind={{ if: rows.Document().ReadOnly }}>
                <StandardRowReadOnly></StandardRowReadOnly>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: rows.Document().ReadOnly }}>
                <StandardRowNonReadOnly></StandardRowNonReadOnly>
            </ko-bind>
        </ko-bind>
    );
}

function DetailedRowReadOnly() {
    let $data: unknown;
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ let: { rows: $data } }}>
            <table class="table table-condensed no-border table-advance" data-bind={{ with: rows.Document, as: "doc" }}>
                <thead>
                    <tr>
                        <th colSpan={2} style="width: 6%">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 6%">
                            <i class="fa fa-chain"></i>
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{
                                    hidden: doc.CanCopy,
                                    asyncClick: rows.ShowDueDateVariationAutomation.bind(rows),
                                }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 3%">
                            <input
                                type="checkbox"
                                data-bind={{ visible: doc.CanCopy, checked: rows.AllRowsSelected }}
                            />
                            {TextResources.Invoices.DocumentRowNumber}
                        </th>
                        <th>{TextResources.Invoices.DocumentRowDescription}</th>
                        <th style="width: 7%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 7%; text-align: center">{TextResources.Invoices.DocumentRowUoM}</th>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowUnitPrice}
                        </th>
                        <th style="width: 14%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowDiscounts}
                        </th>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowNetUnitPrice}
                        </th>
                        <ko-bind data-bind={{ if: doc.ShowAmounts() && doc.CanHaveVAT() }}>
                            <th style="width: 13%; text-align: right">{TextResources.Invoices.DocumentRowVATCode}</th>
                        </ko-bind>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowTotalPrice}
                        </th>
                    </tr>
                </thead>
                <tbody
                    data-bind={{
                        foreach: { data: doc.Rows, as: "row" },
                        documentsRowsResizeInterceptor: ".offsets-table-body",
                    }}
                >
                    <tr>
                        <td colSpan={2}>
                            <ko-bind data-bind={{ if: row.HasAmount() && row.CanBeClosed() }}>
                                <ko-bind data-bind={{ if: row.ManuallyClosed }}>
                                    <i class="fa fa-lock"></i>
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: row.ManuallyClosed }}>
                                    <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                        <i class="fa fa-check" data-bind={{ visible: !row.ClosedAmountOverflow() }}></i>
                                        <i
                                            class="fa fa-arrow-up"
                                            data-bind={{
                                                visible: row.ClosedAmountOverflow,
                                                attr: { "data-original-title": row.ClosedAmountOverflowMessage },
                                                tooltip: {},
                                            }}
                                        ></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                        <span
                                            data-bind={{
                                                numberText: row.ClosedAmount,
                                                format: "0,0.00[000000]",
                                            }}
                                        ></span>
                                    </ko-bind>
                                </ko-bind>
                            </ko-bind>
                        </td>
                        <td>
                            <a
                                href="#"
                                class="btn blue btn-xs"
                                data-bind={{
                                    click: row.ShowReferences,
                                    visible: !row.CanCopy() && row.HasReferences() && row.HasAmount(),
                                }}
                            >
                                <i class="fa fa-chain"></i>
                            </a>
                        </td>
                        <td>
                            <input type="checkbox" data-bind={{ visible: row.CanCopy, checked: row.IsSelected }} />
                            <ko-bind data-bind={{ text: row.Index }}></ko-bind>
                        </td>
                        <td colSpan={7} data-bind={{ text: row.Description }} style="white-space: pre-wrap;"></td>
                    </tr>
                    <ko-bind data-bind={{ if: row.HasAmount() }}>
                        <tr>
                            <td colSpan={4} style="white-space: nowrap">
                                <span class="text-right" data-bind={{ dateText: row.DueDate }}></span>
                                <i
                                    class="fa fa-exclamation-circle"
                                    data-bind={{ tooltip: {}, visible: row.HasDueDateChanges }}
                                    title={TextResources.Invoices.DocumentDueDateChanges}
                                ></i>
                                <a
                                    href="#"
                                    class="btn btn-default btn-xs"
                                    data-bind={{
                                        visible: !!(row.DueDate() || row.HasDueDateChanges()),
                                        click: row.ViewDueDateChangeHistory,
                                        tooltip: {},
                                    }}
                                    style="margin-left: 5px"
                                    title={TextResources.Invoices.DocumentShowDueDateChanges}
                                >
                                    <i class="fa fa-clock-o"></i>
                                </a>
                            </td>
                            <td
                                colSpan={1}
                                class="font-grey-gallery small text-ellipsis"
                                style="vertical-align: middle !important;"
                            ></td>
                            <td>
                                <div
                                    class="text-center"
                                    data-bind={{
                                        numberText: row.Amount,
                                        format: "0,0.00[000000]",
                                        attr: { "data-amount": row.Amount },
                                    }}
                                ></div>
                            </td>
                            <td>
                                <div class="text-center" data-bind={{ text: row.UoM }}></div>
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <div
                                    class="text-right"
                                    data-bind={{
                                        moneyTextEx: row.UnitPriceInDocumentCurrency,
                                        extendedMoneyFormat: true,
                                        currencySymbol: row.Currency,
                                        attr: { "data-total": row.UnitPriceInDocumentCurrency },
                                        visible: row.HasAmount(),
                                    }}
                                ></div>
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <div class="text-right" data-bind={{ text: row.Discounts }}></div>
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <div
                                    class="text-right"
                                    data-bind={{
                                        moneyTextEx: row.NetUnitPriceInDocumentCurrency,
                                        extendedMoneyFormat: true,
                                        currencySymbol: row.Currency,
                                        attr: { "data-total": row.NetUnitPriceInDocumentCurrency },
                                        visible: row.HasAmount(),
                                    }}
                                ></div>
                            </td>
                            <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                                <td>
                                    <div
                                        class="text-center"
                                        data-bind={{ text: row.VatCode, visible: row.HasAmount() }}
                                    ></div>
                                </td>
                            </ko-bind>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <div
                                    class="text-right "
                                    data-bind={{
                                        moneyTextEx: row.TotalPriceInDocumentCurrency,
                                        currencySymbol: row.Currency,
                                        visible: row.HasAmount() && row.HasPrice(),
                                    }}
                                ></div>
                            </td>
                        </tr>
                    </ko-bind>
                </tbody>
            </table>

            <OffsetsTableReadOnly></OffsetsTableReadOnly>
        </ko-bind>
    );
}

function DetailedRowNonReadOnly() {
    let $component: DocumentRows;
    let $data: DocumentRow;
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ let: { rows: $component } }}>
            <table
                class="table table-condensed no-border table-advance"
                data-bind={{ with: rows.Document, as: "doc" }}
                style="table-layout: fixed"
            >
                <thead>
                    <tr>
                        <th style="width: 30px">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 30px"></th>
                        <th style="width: 30px">
                            <i class="fa fa-chain"></i>
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{ asyncClick: rows.ShowDueDateAutomation.bind(rows) }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 40px">{TextResources.Invoices.DocumentRowNumber}</th>
                        <th data-bind={{ attr: { colspan: doc.ShowAmounts() ? "1" : "8" } }}>
                            {TextResources.Invoices.DocumentRowDescription}
                        </th>
                        <th style="width: 10%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 7%; text-align: center">{TextResources.Invoices.DocumentRowUoM}</th>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowUnitPrice}
                        </th>
                        <th style="width: 11%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowDiscounts}
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{ asyncClick: rows.ShowDiscountAutomation.bind(rows) }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowNetUnitPrice}
                        </th>
                        <ko-bind data-bind={{ if: doc.ShowAmounts() && doc.CanHaveVAT() }}>
                            <th style="width: 8%; text-align: center">
                                {TextResources.Invoices.DocumentRowVATCode}
                                <button
                                    class="btn btn-primary btn-xs btn-invoice"
                                    data-bind={{ asyncClick: rows.ShowVatCodeAutomation.bind(rows) }}
                                >
                                    <i class="fa fa-chevron-down"></i>
                                </button>
                            </th>
                        </ko-bind>
                        <th style="width: 13%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowTotalPrice}
                        </th>
                    </tr>
                </thead>
                <tbody data-bind={{ documentsRowsResizeInterceptor: ".offsets-table-body" }}>
                    <ko-bind data-bind={{ foreach: { data: doc.Rows, as: "row" } }}>
                        <tr>
                            <td>
                                <ko-bind
                                    data-bind={{ if: row.HasAmount() && row.CanBeClosed() && !row.ManuallyClosed() }}
                                >
                                    <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                        <i class="fa fa-check" data-bind={{ hidden: row.ClosedAmountOverflow }}></i>
                                        <i
                                            class="fa fa-arrow-up"
                                            data-bind={{
                                                visible: row.ClosedAmountOverflow,
                                                attr: { title: row.ClosedAmountOverflowMessage },
                                            }}
                                        ></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                        <span
                                            data-bind={{
                                                numberText: row.ClosedAmount,
                                                format: "0,0.00[000000]",
                                            }}
                                        ></span>
                                    </ko-bind>
                                </ko-bind>
                            </td>
                            <td>{renderManualClosingButton()}</td>
                            <td>
                                <a
                                    href="#"
                                    class="btn blue btn-xs"
                                    data-bind={{
                                        click: row.ShowReferences,
                                        visible: !row.CanCopy() && row.HasReferences() && row.HasAmount(),
                                    }}
                                >
                                    <i class="fa fa-chain"></i>
                                </a>
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center index"
                                    data-bind={{ value: row.IndexForReorder }}
                                ></input>
                            </td>
                            <td data-bind={{ attr: { colspan: row.ShowAmounts() && row.ShowVAT() ? "8" : "7" } }}>
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    data-bind={{
                                        selectOnFocus: {},
                                        disable: row.IsLocked,
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $data,
                                        },
                                        autoHeight: true,
                                    }}
                                />
                            </td>
                        </tr>
                        <tr data-bind={{ css: { "calculator-opened": row.UsingCalculator } }}>
                            <td colSpan={1}></td>
                            <td colSpan={3}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center duedate"
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        nullableDatePicker: row.DueDate,
                                        selectOnFocus: {},
                                        attr: { readonly: !row.HasAmount() },
                                    }}
                                />
                            </td>
                            <td
                                colSpan={1}
                                class="small"
                                style="vertical-align: middle !important;"
                                data-bind={{
                                    attr: { colspan: doc.ShowAmounts() ? "1" : doc.CanHaveVAT() ? "8" : "7" },
                                }}
                            ></td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center amount"
                                    style={{ width: "70%" }}
                                    data-bind={{
                                        disable: row.IsLocked,
                                        numberValue: row.Amount,
                                        format: "0,0.00[000000]",
                                        attr: { "data-amount": row.Amount },
                                        selectOnFocus: {},
                                    }}
                                />
                                {renderArticleStockInfoButton()}
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center unit-of-measurement"
                                    data-bind={{
                                        disable: row.IsLocked,
                                        value: row.UoM,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                    }}
                                />
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts }}>
                                <div class="unit-price">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="input-block-level text-right unit-price"
                                        data-bind={{
                                            style: { background: row.HasAmount() ? "white" : "lightgrey" },
                                            disable: row.IsLocked,
                                            moneyValueEx: row.UnitPriceInDocumentCurrency,
                                            extendedMoneyFormat: true,
                                            maxNumberOfDecimals: 8,
                                            currencySymbol: row.Currency,
                                            valueUpdate: "afterkeydown",
                                            selectOnFocus: {},
                                            attr: {
                                                "data-amount": row.Amount,
                                                readonly: !row.HasAmount() && !row.UsingCalculator(),
                                            },
                                        }}
                                    />
                                    <button
                                        class="btn btn-primary change-discount-catalog"
                                        data-bind={{
                                            asyncClick: row.changeDiscountCatalog,
                                            visible: doc.CanChangeDiscountCatalog(),
                                        }}
                                    >
                                        <i class="fa fa-euro"></i>
                                    </button>
                                </div>
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-right discounts"
                                    placeholder="Prezzo Esplicito"
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        discountValue: row.Discounts,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                        attr: {
                                            "data-amount": row.Amount,
                                            readonly: !row.HasAmount() && !row.UsingCalculator(),
                                        },
                                    }}
                                />
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-right"
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyValueEx: row.NetUnitPriceInDocumentCurrency,
                                        extendedMoneyFormat: true,
                                        maxNumberOfDecimals: 8,
                                        currencySymbol: row.Currency,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                        attr: {
                                            "data-total": row.NetUnitPriceInDocumentCurrency,
                                            "data-amount": row.Amount,
                                            readonly: !row.HasAmount() && !row.UsingCalculator(),
                                        },
                                    }}
                                />
                            </td>
                            <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                                <td>
                                    <div class="unit-price">
                                        <select2
                                            class="text-center"
                                            cssClass="document-control no-arrow"
                                            noFormControl={true}
                                            simple={true}
                                            allowClear={false}
                                            value={() => "FKVatCode"}
                                            dataSource={() => "VatTypesDataSource"}
                                            listener={() => "$data"}
                                            readOnly={() => "IsLocked() || !HasAmount()"}
                                            placeholder="Codice IVA"
                                        ></select2>
                                        <button
                                            class="btn btn-primary change-discount-catalog"
                                            data-bind={{
                                                asyncClick: row.showLetterOfAttemptSelectionPopUp,
                                                visible: row.ShouldShowLetterOfAttemptsSelector,
                                            }}
                                        >
                                            <i class="fa fa-euro"></i>
                                        </button>
                                    </div>
                                </td>
                            </ko-bind>
                            <td data-bind={{ visible: row.ShowAmounts() }} style={{ position: "relative" }}>
                                <div
                                    class="text-right "
                                    data-bind={{
                                        style: { background: row.HasAmount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyTextEx: row.TotalPriceInDocumentCurrency,
                                        currencySymbol: row.Currency,
                                        visible: row.HasAmount() && row.HasPrice(),
                                    }}
                                ></div>
                                {renderRowValuesCalculator()}
                            </td>
                        </tr>
                    </ko-bind>
                    <tr>
                        <td colSpan={4}></td>
                        <td data-bind={{ attr: { colspan: doc.ShowAmounts() && doc.CanHaveVAT() ? "8" : "7" } }}>
                            <form action="#">
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    placeholder={TextResources.Invoices.DocumentRowNewRow}
                                    data-bind={{
                                        callOnEnterOrTab: {
                                            functionToCall: rows.createNewRow.bind(rows),
                                            jumpToLastFieldOfType: ".amount",
                                            ignoreEnter: true,
                                            selectOnFocus: {},
                                            submitOnLostFocus: true,
                                        },
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $component,
                                        },
                                        valueUpdate: "afterkeydown",
                                        autoHeight: true,
                                    }}
                                />
                            </form>
                        </td>
                    </tr>
                </tbody>
            </table>

            <OffsetsTableNonReadOnly></OffsetsTableNonReadOnly>
        </ko-bind>
    );
}

function DetailedRow() {
    let rows: DocumentRows;

    return (
        <ko-bind data-as={{ rows }}>
            <ko-bind data-bind={{ if: rows.Document().ReadOnly }}>
                <DetailedRowReadOnly></DetailedRowReadOnly>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: rows.Document().ReadOnly }}>
                <DetailedRowNonReadOnly></DetailedRowNonReadOnly>
            </ko-bind>
        </ko-bind>
    );
}

function CompactDetailedRowReadOnly() {
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ with: rows.Document, as: "doc" }}>
            <table class="table table-condensed no-border compact-detailed table-advance">
                <thead>
                    <tr>
                        <th style="width: 6%">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 6%">
                            <i class="fa fa-chain"></i>
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{
                                    hidden: doc.CanCopy,
                                    asyncClick: rows.ShowDueDateVariationAutomation.bind(rows),
                                }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 3%">
                            <input
                                type="checkbox"
                                data-bind={{ visible: doc.CanCopy, checked: rows.AllRowsSelected }}
                            />
                            {TextResources.Invoices.DocumentRowNumber}
                        </th>
                        <th>{TextResources.Invoices.DocumentRowDescription}</th>
                        <th style="width: 7%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 5%; text-align: center">{TextResources.Invoices.DocumentRowUoM}</th>
                        <th style="width: 11%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowUnitPrice}
                        </th>
                        <th style="width: 9%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowDiscounts}
                        </th>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowNetUnitPrice}
                        </th>
                        <ko-bind data-bind={{ if: doc.ShowAmounts() && doc.CanHaveVAT() }}>
                            <th style="width: 8%; text-align: right">{TextResources.Invoices.DocumentRowVATCode}</th>
                        </ko-bind>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowTotalPrice}
                        </th>
                    </tr>
                </thead>
                <tbody
                    data-bind={{
                        foreach: { data: doc.Rows, as: "row" },
                        documentsRowsResizeInterceptor: ".offsets-table-body",
                    }}
                >
                    <tr>
                        <td>
                            <ko-bind data-bind={{ if: row.HasAmount() && row.CanBeClosed() }}>
                                <ko-bind data-bind={{ if: row.ManuallyClosed }}>
                                    <i class="fa fa-lock"></i>
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: row.ManuallyClosed }}>
                                    <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                        <i class="fa fa-check" data-bind={{ visible: !row.ClosedAmountOverflow() }}></i>
                                        <i
                                            class="fa fa-arrow-up"
                                            data-bind={{
                                                visible: row.ClosedAmountOverflow,
                                                attr: { "data-original-title": row.ClosedAmountOverflowMessage },
                                                tooltip: {},
                                            }}
                                        ></i>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                        <span
                                            data-bind={{
                                                numberText: row.ClosedAmount,
                                                format: "0,0.00[000000]",
                                            }}
                                        ></span>
                                    </ko-bind>
                                </ko-bind>
                            </ko-bind>
                        </td>
                        <td>
                            <a
                                href="#"
                                class="btn blue btn-xs"
                                data-bind={{
                                    click: row.ShowReferences,
                                    visible: !row.CanCopy() && row.HasReferences() && row.HasAmount(),
                                }}
                            >
                                <i class="fa fa-chain"></i>
                            </a>
                        </td>
                        <td>
                            <input type="checkbox" data-bind={{ visible: row.CanCopy, checked: row.IsSelected }} />
                            <ko-bind data-bind={{ text: row.Index }}></ko-bind>
                        </td>
                        <td data-bind={{ text: row.Description }} style="white-space: pre-wrap;"></td>
                        <td>
                            <div
                                class="text-center"
                                data-bind={{
                                    numberText: row.Amount,
                                    format: "0,0.00[000000]",
                                    attr: { "data-amount": row.Amount },
                                }}
                            ></div>
                        </td>
                        <td>
                            <div class="text-center" data-bind={{ text: row.UoM }}></div>
                        </td>
                        <td data-bind={{ visible: row.ShowAmounts() }}>
                            <div
                                class="text-right"
                                data-bind={{
                                    moneyTextEx: row.UnitPriceInDocumentCurrency,
                                    extendedMoneyFormat: true,
                                    currencySymbol: row.Currency,
                                    attr: { "data-total": row.UnitPriceInDocumentCurrency },
                                    visible: row.HasAmount(),
                                }}
                            ></div>
                        </td>
                        <td data-bind={{ visible: row.ShowAmounts() }}>
                            <div class="text-right" data-bind={{ text: row.Discounts }}></div>
                        </td>
                        <td data-bind={{ visible: row.ShowAmounts() }}>
                            <div
                                class="text-right"
                                data-bind={{
                                    moneyTextEx: row.NetUnitPriceInDocumentCurrency,
                                    extendedMoneyFormat: true,
                                    currencySymbol: row.Currency,
                                    attr: { "data-total": row.NetUnitPriceInDocumentCurrency },
                                    visible: row.HasAmount(),
                                }}
                            ></div>
                        </td>
                        <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                            <td>
                                <div
                                    class="text-center"
                                    data-bind={{ text: row.VatCode, visible: row.HasAmount() }}
                                ></div>
                            </td>
                        </ko-bind>
                        <td data-bind={{ visible: row.ShowAmounts() }}>
                            <div
                                class="text-right "
                                data-bind={{
                                    moneyTextEx: row.TotalPriceInDocumentCurrency,
                                    currencySymbol: row.Currency,
                                    visible: row.HasAmount() && row.HasPrice(),
                                }}
                            ></div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <OffsetsTableReadOnly></OffsetsTableReadOnly>
        </ko-bind>
    );
}

function CompactDetailedRowNonReadOnly() {
    let $component: DocumentRows;
    let $data: DocumentRow;
    let rows: DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ with: rows.Document, as: "doc" }}>
            <table class="table table-condensed no-border compact-detailed table-advance" style="table-layout: fixed">
                <thead>
                    <tr>
                        <th style="width: 30px">
                            <i class="fa fa-lock"></i>
                        </th>
                        <th style="width: 30px"></th>
                        <th style="width: 30px">
                            <i class="fa fa-chain"></i>
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{ asyncClick: rows.ShowDueDateAutomation.bind(rows) }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 40px">{TextResources.Invoices.DocumentRowNumber}</th>
                        <th data-bind={{ style: { width: doc.ShowAmounts() ? "auto" : "72%" } }}>
                            {TextResources.Invoices.DocumentRowDescription}
                        </th>
                        <th style="width: 7%; text-align: center">{TextResources.Invoices.DocumentRowAmount}</th>
                        <th style="width: 5%; text-align: center">{TextResources.Invoices.DocumentRowUoM}</th>
                        <th style="width: 11%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowUnitPrice}
                        </th>
                        <th style="width: 9%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowDiscounts}
                            <button
                                class="btn btn-primary btn-xs btn-invoice"
                                data-bind={{ asyncClick: rows.ShowDiscountAutomation.bind(rows) }}
                            >
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </th>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowNetUnitPrice}
                        </th>
                        <ko-bind data-bind={{ if: doc.ShowAmounts() && doc.CanHaveVAT() }}>
                            <th style="width: 8%; text-align: center">
                                {TextResources.Invoices.DocumentRowVATCode}
                                <button
                                    class="btn btn-primary btn-xs btn-invoice"
                                    data-bind={{ asyncClick: rows.ShowVatCodeAutomation.bind(rows) }}
                                >
                                    <i class="fa fa-chevron-down"></i>
                                </button>
                            </th>
                        </ko-bind>
                        <th style="width: 12%; text-align: right" data-bind={{ visible: doc.ShowAmounts }}>
                            {TextResources.Invoices.DocumentRowTotalPrice}
                        </th>
                    </tr>
                </thead>
                <tbody data-bind={{ documentsRowsResizeInterceptor: ".offsets-table-body" }}>
                    <ko-bind data-bind={{ foreach: { data: doc.Rows, as: "row" } }}>
                        <tr data-bind={{ css: { "calculator-opened": row.UsingCalculator } }}>
                            <td>
                                <ko-bind data-bind={{ if: row.HasAmount() && row.CanBeClosed() }}>
                                    <ko-bind data-bind={{ ifnot: row.ManuallyClosed }}>
                                        <ko-bind data-bind={{ if: row.IsAutomaticallyClosed }}>
                                            <i
                                                class="fa fa-check"
                                                data-bind={{ visible: !row.ClosedAmountOverflow() }}
                                            ></i>
                                            <i
                                                class="fa fa-arrow-up"
                                                data-bind={{
                                                    visible: row.ClosedAmountOverflow,
                                                    attr: { "data-original-title": row.ClosedAmountOverflowMessage },
                                                    tooltip: {},
                                                }}
                                            ></i>
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: row.IsAutomaticallyClosed }}>
                                            <span
                                                data-bind={{
                                                    numberText: row.ClosedAmount,
                                                    format: "0,0.00[000000]",
                                                }}
                                            ></span>
                                        </ko-bind>
                                    </ko-bind>
                                </ko-bind>
                            </td>
                            <td>{renderManualClosingButton()}</td>
                            <td>
                                <a
                                    href="#"
                                    class="btn blue btn-xs"
                                    data-bind={{
                                        click: row.ShowReferences,
                                        visible: !row.CanCopy() && row.HasReferences() && row.HasAmount(),
                                    }}
                                >
                                    <i class="fa fa-chain"></i>
                                </a>
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center index"
                                    data-bind={{ value: row.IndexForReorder }}
                                ></input>
                            </td>
                            <td>
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    data-bind={{
                                        selectOnFocus: {},
                                        disable: row.IsLocked,
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $data,
                                        },
                                        autoHeight: true,
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center amount"
                                    style={{ width: "70%" }}
                                    data-bind={{
                                        disable: row.IsLocked,
                                        numberValue: row.Amount,
                                        format: "0,0.00[000000]",
                                        attr: { "data-amount": row.Amount },
                                        selectOnFocus: {},
                                    }}
                                />
                                {renderArticleStockInfoButton()}
                            </td>
                            <td>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-center unit-of-measurement"
                                    data-bind={{
                                        disable: row.IsLocked,
                                        value: row.UoM,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                    }}
                                />
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <div class="unit-price">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="input-block-level text-right unit-price"
                                        data-bind={{
                                            style: { background: row.Amount() ? "white" : "lightgrey" },
                                            disable: row.IsLocked,
                                            moneyValueEx: row.UnitPriceInDocumentCurrency,
                                            maxNumberOfDecimals: 8,
                                            extendedMoneyFormat: true,
                                            currencySymbol: row.Currency,
                                            valueUpdate: "afterkeydown",
                                            selectOnFocus: {},
                                            attr: {
                                                "data-amount": row.Amount,
                                                readonly: !row.Amount() && !row.UsingCalculator(),
                                            },
                                        }}
                                    />
                                    <button
                                        class="btn btn-primary change-discount-catalog"
                                        data-bind={{
                                            asyncClick: row.changeDiscountCatalog,
                                            visible: doc.CanChangeDiscountCatalog,
                                        }}
                                    >
                                        <i class="fa fa-euro"></i>
                                    </button>
                                </div>
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-right discounts"
                                    placeholder="Prezzo Esplicito"
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        discountValue: row.Discounts,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                        attr: {
                                            "data-amount": row.Amount,
                                            readonly: !row.Amount() && !row.UsingCalculator(),
                                        },
                                    }}
                                />
                            </td>
                            <td data-bind={{ visible: row.ShowAmounts() }}>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="input-block-level text-right"
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyValueEx: row.NetUnitPriceInDocumentCurrency,
                                        extendedMoneyFormat: true,
                                        maxNumberOfDecimals: 8,
                                        currencySymbol: row.Currency,
                                        valueUpdate: "afterkeydown",
                                        selectOnFocus: {},
                                        attr: {
                                            "data-total": row.NetUnitPriceInDocumentCurrency,
                                            "data-amount": row.Amount,
                                            readonly: !row.Amount() && !row.UsingCalculator(),
                                        },
                                    }}
                                />
                            </td>
                            <ko-bind data-bind={{ if: row.ShowAmounts() && row.ShowVAT() }}>
                                <td>
                                    <div class="unit-price">
                                        <select2
                                            class="text-center"
                                            cssClass="document-control no-arrow"
                                            noFormControl={true}
                                            simple={true}
                                            allowClear={false}
                                            value={() => "FKVatCode"}
                                            dataSource={() => "VatTypesDataSource"}
                                            listener={() => "$data"}
                                            readOnly={() => "IsLocked() || !HasAmount()"}
                                            placeholder="Codice IVA"
                                        ></select2>
                                        <button
                                            class="btn btn-primary change-discount-catalog"
                                            data-bind={{
                                                asyncClick: row.showLetterOfAttemptSelectionPopUp,
                                                visible: row.ShouldShowLetterOfAttemptsSelector,
                                            }}
                                        >
                                            <i class="fa fa-euro"></i>
                                        </button>
                                    </div>
                                </td>
                            </ko-bind>
                            <td data-bind={{ visible: row.ShowAmounts() }} style={{ position: "relative" }}>
                                <div
                                    class="text-right "
                                    data-bind={{
                                        style: { background: row.Amount() ? "white" : "lightgrey" },
                                        disable: row.IsLocked,
                                        moneyTextEx: row.TotalPriceInDocumentCurrency,
                                        currencySymbol: row.Currency,
                                        visible: row.HasAmount() && row.NetUnitPriceInDocumentCurrency() != 0,
                                    }}
                                ></div>
                                {renderRowValuesCalculator()}
                            </td>
                        </tr>
                    </ko-bind>
                    <tr>
                        <td colSpan={4}></td>
                        <td data-bind={{ attr: { colspan: doc.ShowAmounts() && doc.CanHaveVAT() ? "8" : "7" } }}>
                            <form action="#">
                                <textarea
                                    maxLength={4000}
                                    class="input-block-level"
                                    placeholder={TextResources.Invoices.DocumentRowNewRow}
                                    data-bind={{
                                        callOnEnterOrTab: {
                                            functionToCall: rows.createNewRow.bind(rows),
                                            jumpToLastFieldOfType: ".amount",
                                            ignoreEnter: true,
                                            selectOnFocus: {},
                                            submitOnLostFocus: true,
                                        },
                                        documentRowHelper: {
                                            Listener: rows.Document,
                                            Document: rows.Document,
                                            Row: $component,
                                        },
                                        autoHeight: true,
                                    }}
                                />
                            </form>
                        </td>
                    </tr>
                </tbody>
            </table>

            <OffsetsTableNonReadOnly></OffsetsTableNonReadOnly>
        </ko-bind>
    );
}

function CompactDetailedRow() {
    let rows: DocumentRows;

    return (
        <ko-bind data-as={{ rows }}>
            <ko-bind data-bind={{ if: rows.Document().ReadOnly }}>
                <CompactDetailedRowReadOnly></CompactDetailedRowReadOnly>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: rows.Document().ReadOnly }}>
                <CompactDetailedRowNonReadOnly></CompactDetailedRowNonReadOnly>
            </ko-bind>
        </ko-bind>
    );
}

function renderManualClosingButton() {
    let row: DocumentRow;

    return (
        <ko-bind data-bind={{ if: row.HasAmount() && row.CanBeClosed() && !row.IsAutomaticallyClosed() }}>
            <a
                style="width : 22px;"
                href="#"
                class="btn btn-xs"
                data-bind={{ css: { red: row.ManuallyClosed, grey: !row.ManuallyClosed() }, click: row.SwitchClosure }}
            >
                <i
                    class="fa"
                    data-bind={{ css: { "fa-lock": !row.ManuallyClosed(), "fa-unlock": row.ManuallyClosed } }}
                ></i>
            </a>
        </ko-bind>
    );
}

ko.components.register("document-rows", {
    viewModel: {
        createViewModel: (params: IDocumentRowsParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new DocumentRows(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <ko-if data-bind="Document().LayoutId() == 1">
                        <StandardRow></StandardRow>
                    </ko-if>
                    <ko-if data-bind="Document().LayoutId() == 2">
                        <DetailedRow></DetailedRow>
                    </ko-if>
                    <ko-if data-bind="Document().LayoutId() == 3">
                        <CompactDetailedRow></CompactDetailedRow>
                    </ko-if>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
