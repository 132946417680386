import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IArticle, IArticlesService } from "../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class WarehouseArticlesDataSource implements IDataSource<number, IArticle> {
    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService: IArticlesService;

    private view: IDataSourceView;

    constructor() {}

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.WarehouseArticles;
    }

    isGroupedData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string
    ): boolean {
        return false;
    }

    areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    async getData(
        currentModel: IDataSourceModel<number, IArticle>,
        textFilter: string,
        skip = 0,
        count = 10000
    ): Promise<IDataSourceModel<number, IArticle>[]> {
        const articles: IArticle[] = await this.articlesService.getArticles(textFilter, null, skip, count);
        return this.createArticlesModels(articles);
    }

    async getById(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        ids: number[]
    ): Promise<IDataSourceModel<number, IArticle>[]> {
        const articles = await this.articlesService.getArticlesByCatalogRowIds(ids);
        return this.createArticlesModels(articles);
    }

    setView(view: IDataSourceView): void {
        this.view = view;
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }

    private createArticlesModels(articles: IArticle[]): IDataSourceModel<number, IArticle>[] {
        return articles.map((a: IArticle) => {
            return {
                id: a.Id,
                isGroup: false,
                isLeaf: false,
                title: a.Code + " " + a.Description,
                model: a,
            };
        });
    }
}
