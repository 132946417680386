import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IHumanResourcesService, IHumanResource } from "../Users/HumanResourcesService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";

export class ResourceValue {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        var humanResourcesService = <IHumanResourcesService>(
            Core.serviceLocator.findService(ProlifeSdk.HumanResourcesServiceType)
        );
        var instance: any = {};

        var otherBindings = allBindingsAccessor();
        var showDisabled = valueAccessor()["showDisabled"] || false;
        var fieldId = valueAccessor()["id"];
        var getMaterial = valueAccessor()["getMaterial"];
        var refreshTrigger = valueAccessor()["refreshTrigger"];
        var workDate = valueAccessor()["workDate"];

        if (workDate) instance.WorkDate = workDate;

        (<any>$(element))
            .typeahead(
                {
                    items: 1000,
                    minLength: 0,
                    menu: '<ul class="typeahead dropdown-menu"></ul>',
                    item: '<li><a href="#"></a></li>',
                },
                {
                    displayKey: "FullName",
                    limit: 1000,
                    source: ResourceValue.findResources.bind(
                        null,
                        instance,
                        humanResourcesService,
                        showDisabled,
                        getMaterial
                    ),
                    templates: {
                        suggestion: function (json) {
                            var item = JSON.parse(json);
                            return "<div>" + item.FullName + "</div>";
                        },
                    },
                }
            )
            .on("typeahead:selected", (event, json) => {
                var item = JSON.parse(json);
                fieldId(item.id);
                $(element).val(item.FullName);
                otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(item.FullName);
                return item.FullName;
            });

        //Gestione campo trigger per refreshare il testo in base alla selezione corrente
        if (refreshTrigger) {
            var triggerSubscription: ko.Subscription = refreshTrigger.subscribe(() => {
                ResourceValue.refresh(Core.serviceLocator, element, fieldId());
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                triggerSubscription.dispose();
            });
        }

        if (workDate && ko.isObservable(workDate)) {
            let workDateSubscription = workDate.subscribe(() => {
                let currentTypeaheadVal = (<any>$(element)).typeahead("val");
                (<any>$(element)).typeahead("val", "***");
                (<any>$(element)).typeahead("val", currentTypeaheadVal);
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                workDateSubscription.dispose();
            });
        }
    }

    private static refresh(serviceLocator: IServiceLocator, element: any, id: number) {
        var humanResourcesService: IHumanResourcesService = <IHumanResourcesService>(
            serviceLocator.findService(ProlifeSdk.HumanResourcesServiceType)
        );

        if (!id) {
            if (!(<any>$(element)).typeahead("val")) return;

            (<any>$(element)).typeahead("val", "");
            return;
        }

        humanResourcesService.GetHumanResource(id).then((resource: IHumanResource) => {
            var item = ResourceValue.transformResource(resource);
            (<any>$(element)).typeahead("val", item.FullName);
        });
    }

    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        var fieldId = valueAccessor()["id"];
        var value = fieldId();

        ResourceValue.refresh(Core.serviceLocator, element, value);
    }

    private static findResources(
        _this: any,
        humanResourcesService: IHumanResourcesService,
        showDisabled: boolean | ko.Observable<boolean>,
        getMaterial: boolean,
        query: string,
        process: (items: any[]) => any,
        asyncProcess: (items: any[]) => any
    ) {
        if (_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        let workDate = _this.WorkDate ? ko.utils.unwrapObservable(_this.WorkDate) : null;
        let includeDisabled = ko.isSubscribable(showDisabled) ? showDisabled() : showDisabled;

        _this.lastTimeout = setTimeout(function () {
            _this.lastTimeout = 0;
            humanResourcesService
                .GetHumanResources(query, false, includeDisabled, getMaterial, workDate)
                .then((resources: IHumanResource[]) => {
                    return asyncProcess(ResourceValue.transformResources(resources));
                });
        }, 500);
    }

    private static transformResources(articles: IHumanResource[]): any[] {
        return articles.map(ResourceValue.transformResource);
    }

    public static transformResource(resource: IHumanResource): any {
        return {
            id: resource.Resource.Id,
            FullName: resource.Resource.Name + " " + resource.Resource.Surname,
            toString: function () {
                return JSON.stringify(this);
            },
            toLowerCase: function () {
                return this.FullName.toLowerCase();
            },
            indexOf: function () {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function () {
                return String.prototype.replace.apply(this.FullName, arguments);
            },
        };
    }
}

ko.bindingHandlers["resourceValue"] = new ResourceValue();
