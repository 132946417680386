import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { IPurchaseTypeSettingManager } from "../../../SettingManagers/PurchaseTypeSettingManager";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { WorkflowsDataSource } from "../../../DataSources/WorkflowsDataSource";
import { TasksDataSource, ITasksDataSourceModel } from "../../../DataSources/TasksDataSource";
import { ICurrenciesSettingsManager } from "../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IDialogsService, IPopoverComponentInfo } from "../../../Core/interfaces/IDialogsService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IExpenseEventsAndPurchasesPurchases } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";

const { classes } = jss.createStyleSheet({
    "expense-markup-popover": {
        width: "250px"
    }
}).attach();

export class ExpenseProjectPurchase implements IDataSourceListener {
    public get Id(): number {
        return this.purchase?.PurchaseId;
    }

    public get purchaseTypeId(): number {
        return this.purchase?.PurchaseType;
    }

    public PurchaseType : ko.Observable<string> = ko.observable();
    public Description : ko.Observable<string> = ko.observable();
    public UnitCost : ko.Observable<number> = ko.observable(0);
    public NetCost : ko.Observable<number> = ko.observable(0);
    public Discount : ko.Observable<string> = ko.observable();
    public Billable : ko.Observable<boolean> = ko.observable();
    public WorkflowId: ko.Observable<number> = ko.observable();
    public TaskId: ko.Observable<number> = ko.observable();

    public Markup: ko.Observable<number> = ko.observable();

    public CurrencyId: ko.Observable<number> = ko.observable();
    public ExchangeValue: ko.Observable<number> = ko.observable();

    public WorkflowsDataSource: WorkflowsDataSource = new WorkflowsDataSource();
    public TasksDataSource: TasksDataSource = new TasksDataSource();

    private updatingCosts: boolean = false;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesManager: ICurrenciesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesManager: IPurchaseTypeSettingManager;

    constructor(private purchase: IExpenseEventsAndPurchasesPurchases) {
        let purchaseType = this.purchaseTypesManager.getById(this.purchase.PurchaseType);

        let userId = this.userInfo.getIdUser();

        this.WorkflowsDataSource.setUserId(userId);
        this.WorkflowsDataSource.setViewFilters(true, true, true);
        this.WorkflowsDataSource.setWorkFilters(true);
        this.WorkflowsDataSource.setJobOrders(this.purchase?.JobOrderId);

        this.TasksDataSource.setUserId(userId);
        this.TasksDataSource.setViewAll(true);
        this.TasksDataSource.setSearchOnWorkflowTitle(true);
        this.TasksDataSource.setShowClosed(true);
        this.TasksDataSource.setGetWorkflowsInWorkflows(false);
        this.TasksDataSource.setJobOrderId(this.purchase?.JobOrderId);

        let defaultCurrency = this.currenciesManager.getDefaultCurrency();

        this.CurrencyId(defaultCurrency?.Id);
        this.ExchangeValue(1);

        this.PurchaseType(purchaseType?.Title);
        this.Description(this.purchase?.Description);
        this.UnitCost(this.purchase?.UnitMoney);
        this.NetCost(this.purchase?.TotalMoney);
        this.Discount(this.purchase?.Discount);
        this.Billable(this.purchase?.Billable);
        this.TaskId(this.purchase?.TaskId);
        this.WorkflowId(this.purchase?.WorkflowId);

        this.UnitCost.subscribe((cost: number) => {
            let discount = this.Discount();
            let discountValue = !discount ? 0 : numeral(discount).value();
            this.NetCost(!discountValue ? cost : cost - (cost * (discountValue / 100)));
        });

        this.Discount.subscribe((discount: string) => {
            if (this.updatingCosts)
                return;

            this.updatingCosts = true;

            let discountValue = !discount ? 0 : numeral(discount).value() * 100;
            let cost = this.UnitCost();
            this.NetCost(!discountValue ? cost : cost - (cost * (discountValue / 100)));

            if (!discountValue)
                this.Discount(undefined);

            this.updatingCosts = false;
        });

        this.NetCost.subscribe((netCost: number) => {
            if (this.updatingCosts)
                return;

            this.updatingCosts = true;

            let unitCost = (this.UnitCost() || 0);
            if (netCost >= unitCost) {
                this.Discount(undefined);
            } else {
                this.Discount(numeral((netCost / unitCost)).format("0,0.00 %"));
            }

            this.updatingCosts = false;
        });
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.WorkflowsDataSource) {
            this.TasksDataSource.setWorkflows(!model ? [] : [model.id as number]);
            this.TaskId(null);
        } else {
            if (sender === this.TasksDataSource) {
                let taskModel = model as ITasksDataSourceModel;
                if (!!taskModel && !this.WorkflowId())
                    this.WorkflowId(taskModel?.model?.WorkflowId);
            }
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.WorkflowsDataSource) {
            this.TaskId(null);
        }
    }
    
    public setJobOrder(jobOrderId: number): void {
        let jobOrderIsChanged = this.purchase.JobOrderId !== jobOrderId;

        this.purchase.JobOrderId = jobOrderId;
        this.WorkflowsDataSource.setJobOrders(jobOrderId);
        this.TasksDataSource.setJobOrderId(jobOrderId);

        if (jobOrderIsChanged) {
            this.WorkflowId(null);
            this.TaskId(null);
        }
    }

    public async showMarkupPopover(item: any, event: Event): Promise<void> {
        let componentInfo: IPopoverComponentInfo = {
            componentName: "expense-markup-popover",
            title: TextResources.Expenses.Markup,
            model: {
                Markup: this.Markup,
            },
            params: "Markup: Markup"
        };

        return this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, componentInfo, "left", ".modal-body", classes["expense-markup-popover"], ".modal-body");
    }

    public getData(): IExpenseEventsAndPurchasesPurchases {
        this.purchase.Description = this.Description();
        this.purchase.RowDescription = this.purchase.Description;
        this.purchase.UnitMoney = this.UnitCost();
        this.purchase.Discount = this.Discount();
        this.purchase.NetMoney = this.NetCost();
        this.purchase.TotalMoney = this.NetCost();
        this.purchase.Billable = this.Billable();
        this.purchase.TaskId = this.TaskId();

        return this.purchase;
    }
}