import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { StatesNavigationProvider } from "./StatesNavigationProvider";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IJobOrderStateSettingsManager } from "../../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { ISettingsService } from "../../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderState } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { INavigationFilters } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class LogicalStatesNavigationProvider extends NavigationProvider
{
    private jobOrderStatesSettings : IJobOrderStateSettingsManager;

    public Icon: string = "fa fa-folder";
    public Background: string = "";
    public Foreground: string = "";

    constructor(private serviceLocator : IServiceLocator, public logicalState : number, title : string)
    {
        super();
        this.Name = title;
        this.templateName = "job-orders-folder";
        this.templateUrl = "jobOrder/templates/navigation";

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.jobOrderStatesSettings = <IJobOrderStateSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderState);

        var states : StatesNavigationProvider[] = this.jobOrderStatesSettings.getJobOrderStates()
            .filter((s : IJobOrderState) => { return this.logicalState == -1 || this.logicalState == s.LogicalState; })
            .map((s : IJobOrderState) => { return new StatesNavigationProvider(this.serviceLocator, s); });

        states.forEach((p : StatesNavigationProvider) => {
            p.setCurrentNavigator(this.navigator());
        });

        this.Items(states);

        /*var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var typesManager = <IJobOrderTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderType);
        var types : IJobOrderType[] = typesManager.getJobOrderTypes();

        var typesIds = [];
        types.forEach((t : IJobOrderType) => {
            typesIds.push(t.Id);
        });

        types.forEach((t : IJobOrderType) => {
            this.Items.push(new TypesNavigationProvider.TypesNavigationProvider(serviceLocator, logicalState, t.Description, <number>t.Id));
        });*/
    }

    public SetFilterInfoOnModel(f : INavigationFilters)
    {
        f.LogicalState = this.logicalState;
    }
}
