import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 30/08/2018
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ICurrenciesSettingsManager } from "../../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IDocumentCurrencyViewModel } from "../../../interfaces/invoice/IDocumentsService";
import {
    IEntityToImportInfoForMonthlyInvoicing,
    ILabelsMap,
    ILabelInfo,
} from "../../../interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IEntityForMonthlyInvoicingTree } from "../../../interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ICurrencyWithCountriesIds } from "../../../../Invoices/interfaces/ICurrenciesService";

export class MonthlyInvoicingDataRow implements IEntityToImportInfoForMonthlyInvoicing {
    public Id: number;
    public EntityType: string;
    public Name: string;
    public Total: ko.Observable<number> = ko.observable();

    public CustomerId: ko.Observable<number> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public PaymentTypeId: ko.Observable<number> = ko.observable();
    public PaymentType: ko.Observable<string> = ko.observable();
    public ExpiryTypeId: ko.Observable<number> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();

    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public CurrencyCode: ko.Observable<string> = ko.observable();

    public DocumentCurrencies: ko.ObservableArray<IDocumentCurrencyViewModel> = ko.observableArray([]);

    public Selected: ko.Observable<boolean> = ko.observable(false);
    public Collapsed: ko.Observable<boolean> = ko.observable(true);

    public LabelForInvoiceGrouping: ko.Observable<string> = ko.observable("");
    public storage: ko.Observable<any> = ko.observable(); // Quest'affare è un troiaio, DA RIVEDERE

    public TotalInDefaultCurrency: ko.Computed<number>;

    private lastValidLabel = "";
    private lockValidation = false;

    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService: IInfoToastService;
    @LazyImport(nameof<IDocumentsService>())
    protected documentsService: IDocumentsService;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    protected currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor() {
        this.LabelForInvoiceGrouping.subscribe(this.validateLabel.bind(this));
        this.Selected.subscribe(this.onSelectionChanges.bind(this));
    }

    public ApplyLabelForInvoicingGrouping(label: string): void {
        this.lockValidation = true;
        this.LabelForInvoiceGrouping(label);

        setTimeout(() => (this.lockValidation = false), 200); // HACK
    }

    public GenerateLabel(labelsMap: ILabelsMap, startingIteration = 0): void {
        this.lockValidation = true;

        let label = "";
        let charCode = 65;
        let charsIterations: number = startingIteration;

        while (!label) {
            for (let i = 0; i < charsIterations; i++) label += String.fromCharCode(65 + i);

            label += String.fromCharCode(charCode);
            label = label.toUpperCase();

            if (!labelsMap[label]) {
                labelsMap[label] = this.GetLabelInfo();
                labelsMap[label].NumberOfItemsWithLabel++;
                this.LabelForInvoiceGrouping(label);
                break;
            } else {
                if (this.VerifyDocumentCompatibility(labelsMap[label])) {
                    labelsMap[label].NumberOfItemsWithLabel++;
                    this.LabelForInvoiceGrouping(label);
                    break;
                }
            }

            if (charCode == 90) {
                charCode = 64;
                charsIterations++;
            }

            charCode++;
            label = "";
        }

        this.lastValidLabel = label;
        this.lockValidation = false;
    }

    public VerifyDocumentCompatibility(labelInfo: ILabelInfo): boolean {
        // Da implementare nelle derivate
        return undefined;
    }

    public GetLabelInfo(): ILabelInfo {
        // Da implementare nelle derivate
        return undefined;
    }

    public async GetCopy(): Promise<IEntityToImportInfoForMonthlyInvoicing> {
        // Da implementare nelle derivate
        return undefined;
    }

    public GetDocumentForMonthlyInvoicingTree(
        importedDocuments: IEntityForMonthlyInvoicingTree[],
        documentLabel: string = null
    ): IEntityForMonthlyInvoicingTree {
        // Da implementare nelle derivate
        return undefined;
    }

    public HasLabel(label: string): boolean {
        return (
            (!label ? "" : label.toUpperCase()) ==
            (!this.LabelForInvoiceGrouping() ? "" : this.LabelForInvoiceGrouping().toUpperCase())
        );
    }

    public SwitchSelection(): void {
        this.Selected(!this.Selected());
    }

    protected createDocumentCurrencyFromDefaultCurrency(
        entityId: number,
        entityType: string
    ): IDocumentCurrencyViewModel {
        const defaultCurrency: ICurrencyWithCountriesIds = this.currenciesSettingsManager.getDefaultCurrency();
        const model = this.documentsService.DocumentCurrenciesFactory.createModel(
            entityId,
            entityType,
            defaultCurrency.Id
        );
        model.DocumentCurrency = true;
        model.ExchangeValue = 1;
        model.ExchangeValueForVat = 1;
        return this.documentsService.DocumentCurrenciesFactory.createFromModel(model);
    }

    private onSelectionChanges(selected: boolean): void {
        if (!this.storage()) return;

        if (selected) {
            this.storage().SelectedEntities.push(this);
            return;
        }

        this.storage().SelectedEntities.remove(this);
    }

    private validateLabel(label: string): void {
        if (this.lockValidation) return;

        if (!this.storage()) {
            this.lastValidLabel = !label ? "" : label;
            return;
        }

        const tmpLabel: string = !label ? "" : label.toUpperCase();
        let isValidLabel = true;

        if (tmpLabel) {
            if (!this.storage().LabelsMap[tmpLabel]) {
                this.storage().LabelsMap[tmpLabel] = this.GetLabelInfo();
                this.storage().LabelsMap[tmpLabel].NumberOfItemsWithLabel++;
            } else {
                if (!this.VerifyDocumentCompatibility(this.storage().LabelsMap[tmpLabel])) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.InvalidDdtLabelForMonthlyInvoicing);
                    this.LabelForInvoiceGrouping(this.lastValidLabel);
                    isValidLabel = false;
                } else {
                    this.storage().LabelsMap[tmpLabel].NumberOfItemsWithLabel++;
                }
            }
        }

        if (isValidLabel) {
            if (this.lastValidLabel) {
                this.storage().LabelsMap[this.lastValidLabel.toUpperCase()].NumberOfItemsWithLabel--;

                if (this.storage().LabelsMap[this.lastValidLabel.toUpperCase()].NumberOfItemsWithLabel == 0)
                    delete this.storage().LabelsMap[this.lastValidLabel.toUpperCase()];
            }

            this.lastValidLabel = label;
        }
    }
}
