import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../Core/utils/ComponentUtils";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

type SwitchCustomLabels = {
    onLabel: string;
    offLabel: string;
};

export interface ICheckboxProps {
    label?: string | React.ReactElement;
    checked: ko.Observable<boolean> | ko.Computed<boolean>;
    readOnly?: ko.Observable<boolean> | ko.Computed<boolean>;
    simple?: boolean;
    switch?: boolean;
    switchSize?: "mini" | "small" | "normal" | "large";
    switchLabels?: SwitchCustomLabels;
    switchColors?: { onColor: string; offColor: string };
    style?: CSSProperties;
    icon?: boolean;
    onIcon?: string;
    offIcon?: string;
    labelCss?: string;
    reverse?: boolean;

    onChange?: (value: boolean) => void;
}

export class CheckBox {
    static defaultProps: Partial<ICheckboxProps> = {
        simple: true,
        icon: false,
        labelCss: "",
        reverse: false,
    };

    iconSwitcher = ko.computed(() => {
        if (this.props.checked()) return this.props.onIcon;
        return this.props.offIcon;
    });

    private onChangeSubscription: ko.Subscription;

    constructor(private props: ICheckboxProps) {
        if (!this.props.readOnly) this.props.readOnly = ko.observable(false);
    }

    componentDidMount(): void {
        if (this.props.onChange)
            this.onChangeSubscription = this.props.checked.subscribe((value) => this.props.onChange(value));
    }

    componentWillUnmount(): void {
        this.onChangeSubscription?.dispose();
        this.onChangeSubscription = undefined;
    }

    toggle() {
        if (this.props.readOnly()) return;
        this.props.checked(!this.props.checked());
    }

    render() {
        const checkbox = this;

        const wrapWithLabel = (checkbox: React.ReactElement) => {
            if (this.props.simple) return checkbox;

            return (
                <div className="form-group">
                    {this.props.switch ? (
                        <label className={this.props.labelCss}>{this.props.label}</label>
                    ) : (
                        <label>&nbsp;</label>
                    )}
                    <div className="checkbox-list">{checkbox}</div>
                </div>
            );
        };

        const inputOrIcon = () => {
            if (this.props.icon) {
                return (
                    <i
                        data-bind={{
                            class: checkbox.iconSwitcher,
                            css: { "font-blue": !checkbox.props.readOnly(), "font-grey": checkbox.props.readOnly },
                            click: checkbox.toggle.bind(checkbox),
                        }}
                        style={this.props.style}></i>
                );
            }

            return (
                <input
                    type="checkbox"
                    data-bind={{ checkbox: checkbox.props.checked, disable: checkbox.props.readOnly }}
                    style={this.props.style}
                />
            );
        };

        const checkboxOrSwitch = () => {
            if (this.props.switch) {
                let bindings: object = { onOffSwitch: checkbox.props.checked, disable: checkbox.props.readOnly };

                if (this.props.switchLabels) {
                    bindings = {
                        ...bindings,
                        onLabel: this.props.switchLabels.onLabel,
                        offLabel: this.props.switchLabels.offLabel,
                    };
                }

                if (this.props.switchColors) {
                    bindings = {
                        ...bindings,
                        onColor: this.props.switchColors.onColor,
                        offColor: this.props.switchColors.offColor,
                    };
                }

                if (this.props.switchSize) {
                    bindings = {
                        ...bindings,
                        switchSize: this.props.switchSize,
                    };
                }

                return ComponentUtils.setBinding(<input type="checkbox" data-bind={bindings} />, bindings);
            }

            if (!this.props.label) return inputOrIcon();

            return (
                <label className={this.props.labelCss}>
                    {this.props.reverse && (
                        <>
                            <span style={{ position: "relative", top: "-1px" }}>{this.props.label}</span>&nbsp;
                        </>
                    )}
                    {inputOrIcon()}
                    {!this.props.reverse && (
                        <>
                            &nbsp;<span style={{ position: "relative", top: "-1px" }}>{this.props.label}</span>
                        </>
                    )}
                </label>
            );
        };

        return ComponentUtils.bindTo(wrapWithLabel(checkboxOrSwitch()), this, "checkbox");
    }
}
