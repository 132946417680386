import { ReactElement, ReactNode } from "@abstraqt-dev/jsxknockout";

export abstract class ComponentBase
{
    private subscriptions: ko.Subscription[] = [];

    componentWillUnmount() {
        this.subscriptions.forEach(s => s.dispose());
    }

    public disposeOnUnmount(subscription: ko.Subscription) {
        this.subscriptions.push(subscription);
    }

    abstract render() : ReactNode | ReactElement;
}