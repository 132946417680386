import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ResourceManagerArea } from "./ResourceManagerArea";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { Deferred } from "../../../../Core/Deferred";

export class CallRightTypes extends ResourceManagerArea {
    public humanSection : CallRightTypesSection;
    public materialsSection : CallRightTypesSection;

    constructor(mainLayout : IResourcesManagerViewModel) {
        super(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesAreaTitle, "fa-automobile", mainLayout,
            "ResourcesManager/Templates/Areas/CallRightTypes", "main-panel", ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_CalRights);

        this.humanSection = new CallRightTypesSection(true);
        this.materialsSection = new CallRightTypesSection(false);
    }

    public show() {
        super.show();

        this.humanSection.init();
        this.materialsSection.init();
    }
}

export class CallRightTypesSection {

    public title : string;

    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsSettingsService : ICallRightTypesSettingsManager;

    private list : ko.ObservableArray<CallRightTypeForList> = ko.observableArray([]);
    public filteredList : ko.Computed<CallRightTypeForList[]>;
    public selected : ko.Observable<any> = ko.observable(null);
    public textFilter : ko.Observable<string> = ko.observable("");
    public showDeleted : ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ServiceTypes.Authorization)
    private authorizationsService: IAuthorizationService;

    public canShowDeleted: ko.Observable<boolean> = ko.observable(false);

    constructor(private forHuman : boolean) {
        let showDeletedRight = this.authorizationsService.isAuthorized("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager");
        this.canShowDeleted(showDeletedRight);

        this.filteredList = ko.computed(() => {
            return this.list()
                .filter((c : CallRightTypeForList) => { return c.model.ResourceType == (forHuman ? 0 : 1); })
                .filter((c : CallRightTypeForList) => { return !c.model.Deleted || this.showDeleted(); })
                .filter((c : CallRightTypeForList) => { return c.model.Name.toUpperCase().indexOf(this.textFilter().toUpperCase()) > -1; })
                .sort((a, b) =>{
                    if(a.model.Name < b.model.Name)
                        return -1;
                    if(a.model.Name > b.model.Name)
                        return 1;
                    return 0;
                });
        });

        this.createNew();
    }

    public updateElementInLists(model : ICallRightType) {
        this.removeElementFromLists(model.Id);
        this.list.push(new CallRightTypeForList(model, this));
    }

    public removeElementFromLists(id : number) {
        var updated = this.list().filter((c : CallRightTypeForList) => { return c.model.Id == id; });

        if(updated.length > 0)
            this.list.remove(updated[0]);
    }

    public edit(model : ICallRightType){
        this.selected(new CallRightTypeEditMask(model, this));
    }

    public createNew() {
        var emptyModel : ICallRightType = {
            Id: -1,
            Name: "",
            Price: 0,
            Cost: 0,
            Code: "",
            Deleted: false,
            ResourceType: this.forHuman ? 0 : 1
        };
        this.edit(emptyModel);
    }

    public init() {
        this.callRightsSettingsService.load().then(() => {
            this.list(this.callRightsSettingsService.getAll(true).map((c : ICallRightType) => { return new CallRightTypeForList(c, this); }));
        });
    }
}

export class CallRightTypeEditMask {
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsSettingsService : ICallRightTypesSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IDocumentsService>())
    private docService : IDocumentsService;

    public originalName : ko.Observable<string> = ko.observable("");
    public name : ko.Observable<string> = ko.observable("");
    public cost : ko.Observable<number> = ko.observable(0);
    public price : ko.Observable<number> = ko.observable(0);
    public isNew : ko.Observable<boolean> = ko.observable(false);
    public deleted : ko.Observable<boolean> = ko.observable(false);
    public code : ko.Observable<string> = ko.observable("");

    constructor(public model : ICallRightType, private parent : CallRightTypesSection){
        this.updateData(model);
    }

    private updateData(model : ICallRightType) {
        this.model = model;
        this.name(model.Name);
        this.cost(model.Cost);
        this.price(model.Price);
        this.deleted(model.Deleted);
        this.isNew(model.Id <= 0);

        this.originalName(this.isNew() ? ProlifeSdk.TextResources.ResourcesManager.CallRightTypesNewCallRightType : model.Name);

        var activationCode : string = this.docService.getRowReferencesGeneratorFactoryForEntity(ProlifeSdk.EstimatedCallRightTypeCode).ActivationCode;
        var code : string = model.Id > 0 ? activationCode + this.sdkService.Utilities.Numbers.ZeroPad(model.Id.toString(), 5) : "N.D.";
        this.code(code);
    }

    public save(){
        if(this.name().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesInvalidName);
            return;
        }

        this.model.Name = this.name();
        this.model.Cost = this.cost();
        this.model.Price = this.price();
        this.callRightsSettingsService.createOrUpdate(this.model)
            .then((c : ICallRightType) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesUpdated);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            }).catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesUpdateError);
            });
    }

    public hasChanges(): boolean {
        var newModel: ICallRightType = <ICallRightType> $.extend({}, this.model);

        newModel.Name = this.name();
        newModel.Cost = this.cost();
        newModel.Price = this.price();

        return !(<any>Object).identical(newModel, this.model);
    }

    public restoreItem(){
        this.model.Deleted = false;

        this.callRightsSettingsService.createOrUpdate(this.model)
            .then((c : ICallRightType) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesRestored);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            }).catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesRestoredError);
            });
    }

    public deleteItem(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.CallRightTypesDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.CallRightTypesDeleteWarningConfirm, (confirm : boolean) => {
                if(!confirm)
                    return;

                this.callRightsSettingsService.delete(this.model)
                    .then(() => {
                        this.model.Deleted = true;
                        this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesDeleted);
                        this.parent.updateElementInLists(this.model);
                        this.parent.createNew();
                    }).catch(() => {
                        this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.CallRightTypesDeleteError);
                    });
            });
    }
}

export class CallRightTypeForList {

    public isSelected : ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public model : ICallRightType, private parent : CallRightTypesSection){
        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().model.Id == this.model.Id;
        });
    }

    public showDetails(){
        if (!this.parent.selected()) {
            this.parent.edit(this.model);
            return;
        }

        if (this.parent.selected().hasChanges()) {
            this.ShowConfirmOnLostChanges()
                .then((confirm: boolean) => {
                    if (confirm)
                        this.parent.edit(this.model);
                });
            return;
        }

        this.parent.edit(this.model);
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}
