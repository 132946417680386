import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { WorkedHoursPanelViewModel } from "./ui2019/WorkedHoursPanelViewModel";
import { WorkedAmountsPanelViewModel } from "./ui2019/WorkedAmountsPanelViewModel";
import { LazyImport } from "../../Core/DependencyInjection";
import "../../Components/NavigationMenuComponent";
import { IHumanResource } from "../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { HoursApprovalRenderer } from "./ui2019/approval/HoursApprovalRenderer";

const csss = require('../css/workedhours.scss');

export class WorkedHoursApplication2019 implements IApplication
{
	public templateName = 'workedhours';
	public templateUrl = 'workedhours/templates';
	public templateData : ko.Observable<any> = ko.observable();
    public tileColor = 'tile-blue';

    public navBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;
    
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    @LazyImport(nameof<IOPAService>())
    private opaService : IOPAService;

    @LazyImport(nameof<ISettingsService>())
    private settingsService!: ISettingsService;

    @LazyImport(nameof<IServiceLocator>())
    private serviceLocator : IServiceLocator;

    private isStarted  = false;

	constructor()
    {
        this.applicationsService.registerApplication(this);
	}

    onGoingDown() : void {
        this.isStarted = false;
        this.templateData(undefined);
    }

    getApplicationCode() {
        return "WorkedHours";
    }

	getName(): string {
		return ProlifeSdk.TextResources.WorkedHours.WorkReporting;
	}

	getIcon(): string {
        return "icon-clock";
	}

	registerRoutes() : void {
        this.opaService.Get("#/WorkedHours/Approval/:date", (context) => {
            const day = parseInt(context.params["date"].substring(0, 2));
            const month = parseInt(context.params["date"].substring(2, 4));
            const year = parseInt(context.params["date"].substring(4, 8));

            const startDate = moment(new Date()).year(year).month(month-1).date(day).startOf('month').toDate();
            const endDate = moment(new Date()).year(year).month(month-1).date(day).endOf('month').toDate();
            this.startApproval(startDate, endDate);
        });
        this.opaService.Get("#/WorkedHours/Amounts/:date/:resourceId", (context) => {
            const day = parseInt(context.params["date"].substring(0, 2));
            const month = parseInt(context.params["date"].substring(2, 4));
            const year = parseInt(context.params["date"].substring(4, 8));
            const resourceId = parseInt(context.params["resourceId"]);

            const date = moment(new Date()).year(year).month(month-1).date(day).toDate();
            this.startWorkedAmounts(date, resourceId);
        });

        this.opaService.Get("#/WorkedHours/Amounts/:date", (context) => {
            const date = context.params["date"];
            const humanResourcesSettingsManager = this.settingsService.findSettingsManager(ProlifeSdk.HumanResources) as IHumanResourcesSettingsManager;
            const resourceId = humanResourcesSettingsManager.getLoggedResourceId();
            location.href = `#/WorkedHours/Amounts/${date}/${resourceId}`;
        });
        
        this.opaService.Get("#/WorkedHours/Approval", (context) => {
            location.href = "#/WorkedHours/Approval/" + moment().date(1).format("DDMMYYYY");
        });
        
        this.opaService.Get("#/WorkedHours/:date/JobOrder/:jobOrderId", (context) => {
            const day = parseInt(context.params["date"].substring(0, 2));
            const month = parseInt(context.params["date"].substring(2, 4));
            const year = parseInt(context.params["date"].substring(4, 8));
            const jobOrderId = parseInt(context.params["jobOrderId"]);

            const date = moment(new Date()).year(year).month(month-1).date(day).toDate();
            this.startWorkedHours(date, null, true, jobOrderId);
        });

        this.opaService.Get("#/WorkedHours/:date/JobOrder", (context) => {
            const day = parseInt(context.params["date"].substring(0, 2));
            const month = parseInt(context.params["date"].substring(2, 4));
            const year = parseInt(context.params["date"].substring(4, 8));
            
            const date = moment(new Date()).year(year).month(month-1).date(day).toDate();
            this.startWorkedHours(date, null, true, -1);
        });

        this.opaService.Get("#/WorkedHours/:date/:resourceId", (context) => {
            const day = parseInt(context.params["date"].substring(0, 2));
            const month = parseInt(context.params["date"].substring(2, 4));
            const year = parseInt(context.params["date"].substring(4, 8));
            const resourceId = parseInt(context.params["resourceId"]);

            const date = moment(new Date()).year(year).month(month-1).date(day).toDate();
            this.startWorkedHours(date, resourceId);
        });
        
        this.opaService.Get("#/WorkedHours/:date", (context) => {
            const date = context.params["date"];
            const humanResourcesSettingsManager = this.settingsService.findSettingsManager(ProlifeSdk.HumanResources) as IHumanResourcesSettingsManager;
            const resourceId = humanResourcesSettingsManager.getLoggedResourceId();
            location.href = `#/WorkedHours/${date}/${resourceId}`;
        });

        this.opaService.Get("#/WorkedHours", (context) => {
            const date = moment().format("DDMMYYYY");
            const humanResourcesSettingsManager = this.settingsService.findSettingsManager(ProlifeSdk.HumanResources) as IHumanResourcesSettingsManager;
            const resourceId = humanResourcesSettingsManager.getLoggedResourceId();
            location.href = `#/WorkedHours/${date}/${resourceId}`;
        });
	}

	getApplicationRoute() : string {
		return "#/WorkedHours";
	}

	private startWorkedHours(date : Date, resourceId : number, insertPerJobOrder = false, jobOrderId: number = null)
    {
        if(this.templateData() == null || !(this.templateData() instanceof WorkedHoursPanelViewModel) || insertPerJobOrder !== this.templateData().insertPerJobOrderMode) {
            this.templateData(new WorkedHoursPanelViewModel(date, resourceId, insertPerJobOrder, jobOrderId));
            this.canShowSidebar(true);
        }
        
        const workedHoursPanel = this.templateData() as WorkedHoursPanelViewModel;
        workedHoursPanel.refresh(date, resourceId, jobOrderId);
        
        this.desktopService.SystemHeader.setContextMenu({ templateName: "worksheet-right-menu", templateUrl: "WorkedHours/Templates/WorkSheet", viewModel: this.templateData() });

        if(!this.isStarted)
        {
            this.createNavbarActions();

            this.applicationsService.startApplication(this);
            this.isStarted = true;
        }
	}

    private startWorkedAmounts(date : Date, resourceId : number)
    {
        if(this.templateData() == null || !(this.templateData() instanceof WorkedAmountsPanelViewModel))
            this.templateData(new WorkedAmountsPanelViewModel(this.serviceLocator, date, resourceId));
        else
            this.templateData().LoadActivities();

        this.canShowSidebar(false);

        this.desktopService.SystemHeader.setContextMenu({ templateName: "worksheet-right-menu", templateUrl: "WorkedHours/Templates/WorkSheet", viewModel: this.templateData() });

        if(!this.isStarted)
        {
            this.createNavbarActions();

            this.applicationsService.startApplication(this);
            this.isStarted = true;
        }
    }

    private startApproval(startDate : Date, endDate : Date) {
        if(!this.templateData() || !(this.templateData() instanceof HoursApprovalRenderer)) {
            this.templateData(new HoursApprovalRenderer());
            this.canShowSidebar(false);
        }

        const vm = this.templateData() as HoursApprovalRenderer;
        vm.setInterval(startDate, endDate);

        if (!this.isStarted)
        {
            this.createNavbarActions();

            this.applicationsService.startApplication(this);
            this.isStarted = true;
        }
    }

    private createNavbarActions(): void {
        const actions : INavBarAction[] = [
            {
                Name: ko.computed(() => ProlifeSdk.TextResources.WorkedHours.WorkedHoursPanel),
                CanRun: ko.computed(() => { 
                    return this.authorizationsService.isAuthorized("WorkedHours_Start")
                        && !this.desktopService.isMobileBrowser();
                }),
                Icon: ko.observable(""),
                Right: true,
                Run: () => {
                    this.openWorkedHoursPanel();
                }
            },
            {
                Name: ko.computed(() => ProlifeSdk.TextResources.WorkedHours.WorkedHoursByJobOrderPanel),
                CanRun: ko.computed(() => { 
                    return this.authorizationsService.isAuthorized("WorkedHours_Start")
                        && this.authorizationsService.isAuthorized("WorkedHours_CanEditOtherUsersWorkSheets")
                        && !this.desktopService.isMobileBrowser();
                }),
                Icon: ko.observable(""),
                Right: true,
                Run: () => {
                    this.openWorkedHoursPerJobOrderPanel();
                }
            },
            {
                Name: ko.computed(() => ProlifeSdk.TextResources.WorkedHours.WorkedAmountsPanel),
                CanRun: ko.computed(() => {
                    return this.authorizationsService.isAuthorized("WorkedHours_OpenActivitiesProgressByAmountsEditor")
                        && !this.desktopService.isMobileBrowser();
                }),
                Icon: ko.observable(""),
                Right: true,
                Run: () => {
                    this.openWorkedAmountsPanel();
                }
            },
            {
                Name: ko.computed(() => ProlifeSdk.TextResources.WorkedHours.HoursApproval),
                CanRun: ko.computed(() => {
                    return this.authorizationsService.isAuthorized("WorkedHours_CanReviewHoursApproval")
                        && !this.desktopService.isMobileBrowser();
                }),
                Icon: ko.observable("fa fa-legal"),
                Right: true,
                Run: () => {
                    this.openHoursApprovalPanel();
                }
            }
        ];

        this.navBarActions(actions);
    }

    private openWorkedHoursPanel(): void {
        if (this.templateData() && this.templateData().dispose)
            this.templateData().dispose();

        const userInfoService = <IUserInfo> this.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        const settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const resourcesManager = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        const resource: IHumanResource = resourcesManager.getHumanResourceByUserId(userInfoService.getIdUser());

        location.href = "#/WorkedHours/" + moment().format("DDMMYYYY") + (!resource ? "" : "/" + resource.Resource.Id);
    }
    
    private openWorkedHoursPerJobOrderPanel(): void {
        const urlPart = "#/WorkedHours/" + moment().format("DDMMYYYY") + "/JobOrder";
        const actualUrl = location.href;

        if (actualUrl.indexOf(urlPart) >= 0)
            return;

        if (this.templateData() && this.templateData().dispose)
            this.templateData().dispose();

        location.href = urlPart + "/-1";
    }

    private openWorkedAmountsPanel(): void {
        if (this.templateData() && this.templateData().dispose)
            this.templateData().dispose();

        const userInfoService = <IUserInfo> this.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        const settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const resourcesManager = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        const resource: IHumanResource = resourcesManager.getHumanResourceByUserId(userInfoService.getIdUser());

        location.href = "#/WorkedHours/Amounts/" + moment().format("DDMMYYYY") + (!resource ? "" : "/" + resource.Resource.Id);
    }

    private openHoursApprovalPanel() : void {
        if (this.templateData() && this.templateData().dispose)
            this.templateData().dispose();

        location.href = "#/WorkedHours/Approval/" + moment().format("DDMMYYYY");
    }
}