import * as ko from "knockout";
/**
 * Created by g.adami on 20/06/2017.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { Sample } from "./Sample";
import { SampleEditDialog } from "./dialogs/SampleEditDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISample } from "../../../../ProlifeSdk/interfaces/survey/ISample";

export class SampleViewModel extends Sample
{
    public CanModify : ko.Computed<boolean>;
    public FormattedTitle: ko.Computed<string>;

    private dialogService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, public currentSample: ISample)
    {
        super(currentSample)
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.CanModify = ko.computed(() => {
            return true;
        });

        this.FormattedTitle = ko.computed(() => {
            return this.PublicId() + ' - ' + this.Title();
        });
    }

    public Edit(): void {
        var editDialog = new SampleEditDialog(this.serviceLocator, this);
        this.dialogService.ShowModal<number>(editDialog, null, null, "survey/templates/sample/dialogs", "edit-sample-dialog")
            .then((samples: number) => {
                if (!samples) {
                    this.rollbackChanges();
                    return;
                }
            });
    }

    private rollbackChanges(): void {
        this.Title(this.currentSample.Title);
        this.Description(this.currentSample.Description);
        this.TypeId(this.currentSample.TypeId);
        this.TypeName(this.currentSample.TypeName);
        this.Icon(this.currentSample.Icon);
        this.Background(this.currentSample.Background);
        this.Foreground(this.currentSample.Foreground);
    }
}
