import * as ko from "knockout";
import { IDocumentInfoForInlineRefProvider } from "./documents/converters/RefConverterBase";
import { IDocumentCurrencyViewModel } from "../interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../interfaces/invoice/wizard/IWizardInitializationInfo";
import { IEntityForMonthlyInvoicingTree } from "../interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import {
    IDocumentImportDataWizardStep,
    IDocumentToImportInfo,
} from "../interfaces/invoice/IDocumentImportDataWizardStep";

export class WizardStep implements IDocumentImportDataWizardStep {
    public IsLastStep: ko.Computed<boolean>;

    public DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;
    public DocumentCurrencySymbol: ko.Computed<string>;

    constructor(
        public templateName: string,
        public templateUrl: string,
        public Name: string,
        protected initializationInfo: IWizardInitializationInfo,
        public viewModel?: any
    ) {
        if (!viewModel) this.viewModel = this;

        this.IsLastStep = ko.computed(() => {
            //Da implementare nelle derivate
            return false;
        });

        this.DocumentCurrency = ko.computed(() => {
            return this.initializationInfo.DocumentCurrenciesInfo?.DocumentCurrencies().firstOrDefault((c) =>
                c.IsDocumentCurrency()
            );
        });

        this.DocumentCurrencySymbol = ko.computed(() => {
            return this.initializationInfo.DocumentCurrenciesInfo?.DocumentCurrencySymbol();
        });
    }

    public GetDocumentsToImportInfo(): Promise<IDocumentToImportInfo[]> {
        //Da implementare nelle specializzazioni dove è possibile importare dei documenti
        return new Promise((resolve) => resolve([]));
    }

    public getDocumentsToGenerate(): Promise<IEntityForMonthlyInvoicingTree[]> {
        //Da implementare nelle specializzazioni dove è possibile importare dei documenti
        return new Promise((resolve) => resolve([]));
    }

    public BeforeShow() {
        //Da implementare nelle specializzazioni
    }

    public BeforeNext(): boolean {
        //Da implementare nelle specializzazioni
        return true;
    }

    public BeforeBack(): boolean {
        //Da implementare nelle specializzazioni
        return true;
    }

    protected generateDocumentInfoForInlineRefProvider(): IDocumentInfoForInlineRefProvider {
        return {
            IsWarehouseTransfer: ko.observable(this.initializationInfo.IsWarehouseTransfer),
            CustomerId: ko.observable(this.initializationInfo.CustomerId),
            DestinationWarehouseId: ko.observable(undefined),
            DocTypeCode: this.initializationInfo.DocTypeCode,
            DocumentDate: ko.observable(this.initializationInfo.DocumentDate),
            JobOrderId: ko.observable(this.initializationInfo.JobOrderId),
            OverrideDiscountCatalog: null,
            SourceWarehouseId: ko.observable(this.initializationInfo.SourceWarehouseId),
            DocumentCurrencies: this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrencies,
        };
    }
}
