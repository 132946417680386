import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import * as moment from "moment";
import { BlogEvent } from "../../blog/BlogEvent";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ReferencesMapViewer } from "../references-map-viewer/ReferencesMapViewer";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IUsersService } from "../../../../Users/UsersService";
import { IInvoicesService } from "../../../interfaces/invoice/IInvoicesService";
import { IScheduleService } from "../../../interfaces/schedule/IScheduleService";
import { IContextEventsObserver } from "../../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../interfaces/prolife-sdk/IProlifeSdkService";
import { IDesktopService } from "../../../interfaces/desktop/IDesktopService";
import { IView } from "../../../interfaces/IView";
import { IActivityLogViewModel, IActivityLog } from "../../../interfaces/invoice/IActivityLog";
import { IFileRepositoryService } from "../../../interfaces/files/IFileRepositoryService";
import { IUser } from "../../../interfaces/users/IUser";
import { IFileOrFolder } from "../../../interfaces/files/IFileOrFolder";
import { Deferred } from "../../../../Core/Deferred";
import { IAjaxServiceNew } from "../../../../Core/interfaces/IAjaxService";

export class DocumentEvent extends BlogEvent implements IView {
    public viewModel: any;
    protected invoicesService: IInvoicesService;
    protected dialogsService: IDialogsService;

    public DocumentId: ko.Observable<number> = ko.observable();
    public DocumentTypeCode: ko.Observable<string> = ko.observable();
    public DocumentTypeDescription: ko.Observable<string> = ko.observable();

    //Info accessorie da non salvare nell'evento
    public NumberOfRows: ko.Observable<number> = ko.observable(0);
    public Total: ko.Observable<number> = ko.observable(0);
    public ActivitiesLog: ko.ObservableArray<IActivityLogViewModel> = ko.observableArray([]);

    public documentTypeCode: string; //impostare al costruttore delle derivate

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.invoicesService = <IInvoicesService>serviceLocator.findService(ProlifeSdk.InvoicesServiceType);
        this.templateName = "document";
        this.templateUrl = "prolifesdk/templates/events";
        this.eventTypeName = ProlifeSdk.TextResources.ProlifeSdk.Document;
        this.iconClass = "fa-file-text";
        this.Updatable(true);
    }

    Clear() {
        super.Clear();
        this.DocumentId(0);
        this.DocumentTypeCode(null);
        this.DocumentTypeDescription(null);

        this.Total(0);
        this.NumberOfRows(0);
        this.ActivitiesLog([]);
    }

    public ShowReferencesMap() {
        const vm: ReferencesMapViewer = new ReferencesMapViewer(this.DocumentId(), this.DocumentTypeCode(), -1, null);
        this.dialogsService.ShowModal<void>(vm, "fullscreen doc-references-map", null, vm.templateUrl, vm.templateName);
    }

    public ShowSchedules() {
        const schedulesService: IScheduleService = <IScheduleService>(
            this.serviceLocator.findService(ProlifeSdk.ScheduleServiceType)
        );
        schedulesService.GetInvoiceSchedulesDialog(this.DocumentId()).then((dialog: IDialog) => {
            this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
        });
    }

    public GetMailBody(): Promise<string> {
        const def = new Deferred<string>();

        super
            .GetMailBody()
            .then((b: string) => {
                const body = b;
                //Introdurre qui info aggiuntive...
                def.resolve(body);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    public GetMailSubject(): string {
        return String.format(
            ProlifeSdk.TextResources.ProlifeSdk.DocumentEventMailSubject,
            moment(this.ReferenceDate()).format("L")
        );
    }

    public LoadDetails() {
        super.LoadDetails();
        this.LoadDocumentLogs();
    }

    public LoadDocumentLogs(): Promise<void> {
        const def: Deferred<void> = new Deferred();
        this.invoicesService
            .GetDocumentLogs(this.documentTypeCode, this.DocumentId(), this.JobOrder())
            .then((logs: IActivityLog[]) => {
                logs.sort((a: IActivityLog, b: IActivityLog) => {
                    return moment(b.ModifiedDate).isBefore(a.ModifiedDate)
                        ? -1
                        : moment(b.ModifiedDate).isAfter(a.ModifiedDate)
                        ? 1
                        : 0;
                });

                this.ActivitiesLog(
                    logs.map((l: IActivityLog) => {
                        return new ActivityLog(l);
                    })
                );
            })
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    public LoadLocalTags() {
        super.loadTags();

        this.Tags().forEach((item) => {
            //Caricamento Tag di dettaglio
            this.DocumentId(item.TagName() != ProlifeSdk.Tag_DocumentId ? this.DocumentId() : parseInt(item.Value()));
            this.DocumentTypeCode(
                item.TagName() != ProlifeSdk.Tag_DocumentTypeCode ? this.DocumentTypeCode() : item.Value()
            );
            this.DocumentTypeDescription(
                item.TagName() != ProlifeSdk.Tag_DocumentTypeDescription ? this.DocumentTypeDescription() : item.Value()
            );
        });
    }
}

export class ActivityLog implements IActivityLogViewModel {
    public User: ko.Observable<string> = ko.observable("");
    public Date: ko.Observable<Date> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable(false);
    public Attachment: ko.Observable<any> = ko.observable();

    @LazyImport(ProlifeSdk.UsersServiceType)
    private usersService: IUsersService;

    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private repositoryService: IFileRepositoryService;

    @LazyImport(ProlifeSdk.ProlifeSdkServiceType)
    private sdkService: IProLifeSdkService;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    constructor(log: IActivityLog) {
        this.usersService.getUserFromKeyValue(log.ModifiedBy).then((u: IUser) => {
            this.User(this.usersService.GetFullName(u));
        });

        if (log.AttachmentId) {
            this.repositoryService.getFileDetails(log.AttachmentId).then((f: IFileOrFolder) => {
                this.Attachment(f);
            });
        }

        this.Date(this.sdkService.Utilities.Dates.FixJsonDate(log.ModifiedDate));
        this.Deleted(log.Deleted);
    }

    public DownloadAttachment(): void {
        if (!this.Attachment()) return;

        const url = this.repositoryService.GetVersionDownloadUrl(this.Attachment().Id);
        this.ajaxServiceNew.DownloadFileFromUrl(url, { overrideMethod: "GET" });
    }
}
