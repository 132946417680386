import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DocumentEvent } from "../../../../ProlifeSdk/prolifesdk/documents/events/DocumentEvent";
import { WarehouseLoadEventDetailsEdit } from "./details/WarehouseLoadEventDetailsEdit";
import { IWarehouseLoad, IWarehouseLoadRow } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehouseLoad";
import { IMovementService } from "../../../../ProlifeSdk/interfaces/warehouse/IMovementService";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../../Core/Deferred";

export class WarehouseLoadEvent extends DocumentEvent implements IView
{
    private movementsService : IMovementService;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.documentTypeCode = ProlifeSdk.WarehouseLoadEntityTypeCode;
        this.eventTypeName = ProlifeSdk.TextResources.Warehouse.WarehouseLoadAbb;
        this.movementsService = <IMovementService>serviceLocator.findService(ProlifeSdk.MovementServiceType);
    }

    getEditViewModel() : IEventBaseInput
    {
        return new WarehouseLoadEventDetailsEdit(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadDetails()
    {
        super.LoadDetails();
        this.LoadDocumentInfo();
    }

    public LoadDocumentInfo() : Promise<void>
    {
        var def = new Deferred<void>();
        this.movementsService.getWarehouseLoadById(this.DocumentId()).then((d : IWarehouseLoad) => {
            if(!d)
                return;

            this.Total(d.TotaleOrdine);

            this.movementsService.getWarehouseLoadRowsById(this.DocumentId()).then((r : IWarehouseLoadRow[]) => {
                this.NumberOfRows(r ? r.length : 0);
            });
        }).finally(() => {
            def.resolve();
        });
        return def.promise();
    }
}