import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import "./Todolist/settings/ui/EstimatedBudgetForTaskStatesEditor";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { WorkflowsNavigator } from "./Todolist/ui/navigation/workflows-navigator/WorkflowsNavigator";
import { TemplatesNavigator } from "./Todolist/ui/navigation/workflows-navigator/TemplatesNavigator";
import { TodoListWorkflowsControlsEntityProvider } from "./Todolist/providers/TodoListWorkflowsControlsEntityProvider";
import { TemplatesDesignerApplication } from "./Todolist/TemplatesDesignerApplication";
import { TaskBoardApplication } from "./Todolist/TaskBoardApplication";
import { DefaultTemplateSettingsManager } from "./Todolist/settings/DefaultTemplateSettingsManager";
import { WorkflowCategoriesSettingsManager } from "./Todolist/settings/WorkflowCategoriesSettingsManager";
import { WorkflowStatesSettingsManager } from "./Todolist/settings/WorkflowStatesSettingsManager";
import { WorkflowOutcomeSettingsManager } from "./Todolist/settings/WorkflowOutcomeSettingsManager";
import { BudgetRequestCausesSettingsManager } from "./Todolist/settings/BudgetRequestCausesSettingsManager";
import { TodoListTasksProvider } from "./Todolist/ui/tasks-list/providers/TodoListTasksProvider";
import { BlogEvent } from "../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { TaskStateChangeEvent } from "./Todolist/ui/events/TaskStateChangeEvent";
import { TaskDataChangeEvent } from "./Todolist/ui/events/TaskDataChangeEvent";
import { WorkflowStateChangeEvent } from "./Todolist/ui/events/WorkflowStateChangeEvent";
import { WorkflowsDropDownList } from "./Todolist/ui/dropdownlist/WorkflowsDropDownList";
import { JobOrderTaskDropDownList } from "./Todolist/ui/dropdownlist/JobOrderTaskDropDownList";
import { JobOrderTaskRefUiForRemind } from "./Todolist/ui/reminds-refs/JobOrderTaskRefUiForRemind";
import { WorkflowRefUiForRemind } from "./Todolist/ui/reminds-refs/WorkflowRefUiForRemind";
import { ActivitiesDataSource } from "./Todolist/data-sources/ActivitiesDataSource";
import { ActivitiesWarehouseDataSource } from "./Todolist/data-sources/ActivitiesWarehouseDataSource";
import { EstimatedPurchaseRefDetailsViewModelFactory } from "./Todolist/providers/ref-details-factories/EstimatedPurchaseRefDetailsViewModelFactory";
import { TodoListTaskJobOrderTags } from "./Todolist/ui/tasks-list/tags/TodoListTaskJobOrderTags";
import { TodoListTask } from "./Todolist/ui/tasks-list/providers/TodoListTask";
import { WorkflowActivitiesMultipliersManager } from "./Todolist/ui/workflows/WorkflowActivitiesMultipliersManager";
import { TaskStatusIconHelper } from "./Todolist/ui/tasks-list/providers/TaskStatusIconHelper";
import { TodoListFolder } from "./Todolist/ui/tasks-list/TodoListFolder";
import { WorkflowEntityCodeGeneratorSettingsManager } from "./Todolist/settings/WorkflowEntityCodeGeneratorSettingsManager";
import { ActivityDetailsDialog } from "./Todolist/ui/tasks-list/ActivityDetailsDialog";
import { TasksDataSource } from "../DataSources/TasksDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { WorkflowsSearchEntityProvider } from "./Todolist/entity-providers/WorkflowsSearchEntityProvider";
import { IWorkflowOutcomeSettingsManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IWorkflowOutcome } from "./WorkflowOutcomesService";
import { ElementsToBeOrderedFromActivitiesDataSource } from "./Todolist/data-sources/ElementsToBeOrderedFromActivitiesDataSource";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IReminderService, IRemindReferenceObjFieldType } from "../ProlifeSdk/interfaces/reminder/IReminderService";
import {
    ITodoListService,
    IWorkflowSectionsDefaultSettings,
    IWorkflowRelatedDocumentsSectionsDefaultSettings,
    ITaskBoardPlanningDayPlan,
    ITaskBoardPlanningCartInDayPlan,
    ITaskForTaskBoard,
    IBlogEventsRelatedToTask,
    ITaskInsertOrUpdateResponse,
    ITodoListTemplateTaskInsertResponse,
    ITodoListTaskWithBefore,
    ITodoListTemplateTaskWithBefore,
    IWorkAndPurchaseCosts,
    IWorkflowChange,
    ITaskDialogOptions,
    IApprovedBudgetHistory,
    ITodoListTaskAssignedBudgets,
    IWorkSpeedHistory,
    ICartForResource,
    IArticleCostAndPrice,
    IWorkflowForSelectList,
    IGetTasksForUserRequest,
    IGetCartTasksForResourceRequest,
    IWorkflowRelatedDocument,
    ISnapshot,
    ICreateOrUpdateSnapshotRequest,
    IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData,
    IApplySnapshotToWorkflowTasksResponse,
    IEstimatedCostsAndRevenuesAtSnapshotDate,
    IElementToBeOrdered,
    IGetWorkflowsForSelectListRequest,
    IGetCartWorkflowsForResourceRequest,
    IGetJobOrdersForUserRequest,
    IJobOrderForTaskBoard,
    IGetJobOrdersForUserByIdRequest,
    IWorkflowRelatedDocumentsAndWorkedHours,
    IWorkflowCategory_Type,
    IWorkflowCategory,
    IGetJobOrderWorkflowsForUserRequest,
    IGetJobOrderWorkflowsForUserByIdsRequest,
    ITaskEstimatedBudgetRowWithNames,
    IWorkflowForTaskBoard,
    IUserMilestone,
    WorkflowRelatedDocumentNew,
    TasksCanChangeStateResult,
    MovedTask,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import {
    ITodoListFolder,
    ITodoListTask,
    ITodoListTaskTag,
    ITodoListTaskForAllocations,
    ITodoListTaskTagsAndResources,
    ITodoListTaskChangeHistory,
    ITodoListTaskWorkHistory,
    IEstimateBudgetRow,
    ITodoListTaskForLimitedList,
    INewActivityDefaultData,
    ITaskResource,
} from "../ProlifeSdk/interfaces/todolist/ITodoList";
import {
    ITodoListTemplate,
    ITodoListTemplateTask,
    ITodoListTemplateForList,
    ITodoListTemplateBase,
} from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import {
    ITodoListWorkflow,
    ITodoListWorkflowForList,
    ITodolistWorkflowForAllocationsList,
    ITodoListWorkflowForLimitedList,
    InsertOrUpdateWorkflowResponse,
    ITodoListWorkflowNew,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { ITaskStatusIconHelper } from "../ProlifeSdk/interfaces/todolist/ITaskStatusIconHelper";
import { IJobOrdersMenuAdvancedFilters } from "../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import {
    IWorkflowNavigatorOptions,
    IWorkflowNavigator,
    ITemplatesNavigator,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IWorkflowActivitiesMultipliersManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowActivitiesMultipliersManager";
import { IWorkflowEditorResult } from "../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import {
    IApplicationsConfigurationsService,
    IApplicationConfiguration,
} from "../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IDropDownList } from "../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { IMonthPlanKey } from "../ProlifeSdk/interfaces/worked-hours/IResourceDailyPlan";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { IEstimatedWorkHistory } from "../ProlifeSdk/interfaces/todolist/IEstimatedWorkHistory";
import { IDoneWorkHistory } from "../ProlifeSdk/interfaces/todolist/IDoneWorkHistory";
import { IClosedWorkHistory } from "../ProlifeSdk/interfaces/todolist/IClosedWorkHistory";
import { IPerformanceDashboardInfo } from "../ProlifeSdk/interfaces/todolist/IPerformanceDashboardInfo";
import { IWorkingResource } from "../ProlifeSdk/interfaces/todolist/IWorkingResource";
import { IRemainingWorkHistory } from "../ProlifeSdk/interfaces/todolist/IRemainingWorkHistory";
import { IWorkingResourceAllocation } from "../ProlifeSdk/interfaces/todolist/IWorkingResourceAllocation";
import { IWorkflowRevenue } from "../ProlifeSdk/interfaces/todolist/IWorkflowRevenue";
import { IControlsEntityProvider } from "../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IUserCharacter } from "../ProlifeSdk/interfaces/users/IUserCharacter";
import { Deferred } from "../Core/Deferred";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { WorkflowEditDialog } from "./Todolist/ui/workflows/WorkflowEditDialog";
import { TemplateTasksDataSource } from "../DataSources/TemplateTasksDataSource";
import { TodoListTemplatesTasksProvider } from "./Todolist/ui/templates-designer/providers/TodoListTemplatesTasksProvider";
import { TodoListTemplateTask } from "./Todolist/ui/templates-designer/providers/TodoListTemplateTask";
import { TodoListActivity } from "./Todolist/ui/tasks-list/TodoListActivity";
import { IDesktopService } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ResponseBase, ResponseData, ResponseError } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

interface IBlogViewModels {
    [type: string]: new (
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver
    ) => BlogEventBase;
}

export const TODOLIST_SERVICE_ERRORS = [
    "ErrorWhileSavingWorkflow",
    "WorkflowStatusNotFound",
    "UserResourceNotFound",
    "TaskNotFound",
    "TaskOrWorkflowNotFound",
    "TasksNotFound",
    "MissingTaskTitle",
    "InvalidTaskMultiplier",
    "InvalidEstimatedBudgetRowEntityKeyId",
    "InvalidEstimatedBudgetRowType",
    "InvalidTaskDuration",
    "TaskWasUpdatedByAnotherUser",
    "InvalidTaskLinkType",
    "InvalidTaskLinkReferenceId",
    "InvalidTaskResourceId",
    "InvalidTaskResourceType",
    "TaskDataChangesNotFound",
    "TaskStatusChangesNotFound",
    "TaskEstimateChangesNotFound",
    "TaskBoardStatusNotFound",
    "TaskWorkTimeCategoriesNotFound",
    "TaskRolesNotFound",
    "WorkflowMismatch",
    "ErrorWhileSavingTask",
    "DuplicatedTaskIdInChanges",
    "SettingNotFound",
    "SettingInvalidValue",
    "TaskFirstWorkingStateChangeNotFound",
    "TaskLastClosedStateChangeNotFound",
    "WorkflowNotFound",
    "WorkflowOutcomeNotFound",
    "WorkflowOutcomePreventTasksEditing",
    "WorkflowJobOrderMismatch",
    "MissingTaskWorkEstimation",
    "WorkflowStatusChangesNotFound",
    "WorkflowTemplateNotFound",
    "InvalidWorkflowSourceTemplate",
    "MissingTasks",
    "MissingWorkflowTitle",
    "WorkflowDefaultCategoryNotFound",
    "WorkflowHasRelatedDocumentsOrWorkedHours",
    "WorkflowDefaultOutcomeNotFound",
];

type ErrorHandlers = {
    [key in (typeof TODOLIST_SERVICE_ERRORS)[number]]: ErrorHandler;
};

type ErrorHandler = (errorCode: string, errorData: unknown) => void;

export class TodoListService extends ProLifeService implements ITodoListService {
    @LazyImport(ProlifeSdk.ApplicationsConfigurationsServiceCode)
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(ProlifeSdk.SettingsServiceType)
    private settingsManager: ISettingsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;
    @LazyImport(nameof<IReminderService>())
    private remindService: IReminderService;

    private tasksFolders: ITodoListFolder[];
    private eventsViewModels: IBlogViewModels = {};

    private actualWorkflowSectionsConfiguration: IWorkflowSectionsDefaultSettings;
    private workflowSectionsConfigurationLoaded = false;
    private savedWorkflowSectionsConfiguration: IApplicationConfiguration;

    private actualWorkflowRelatedDocumentsSectionsConfiguration: IWorkflowRelatedDocumentsSectionsDefaultSettings;
    private workflowRelatedDocumentsConfigurationLoaded = false;
    private savedWorkflowRelatedDocumentsSectionsConfiguration: IApplicationConfiguration;

    private sectionsDefaultSettings: IWorkflowSectionsDefaultSettings = {
        WorkflowStateOpened: true,
        ResourcesOpened: false,
        AdvancedSettingsOpened: false,
    };

    private relatedDocumentsDefaultSettings: IWorkflowRelatedDocumentsSectionsDefaultSettings = {
        InvoicesOpened: false,
        DdtsOpened: false,
        EstimatesOpened: false,
        SalsOpened: false,
        CustomerOrdersOpened: false,
        SupplierOrdersOpened: false,
        WarehouseLoadsOpened: false,
    };

    private errorHandlers: ErrorHandlers = {};

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.TodolistApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ITodoListService>(), this);

        this.initializeErrorHandlers();
    }

    private initializeErrorHandlers() {
        this.errorHandlers = {
            ErrorWhileSavingWorkflow: () => this.infoToastService.Error(TextResources.ProlifeSdk.GenericError),
            WorkflowStatusNotFound: this.runDefaultErrorHandler.bind(this),
            UserResourceNotFound: this.runDefaultErrorHandler.bind(this),
            TaskNotFound: this.runDefaultErrorHandler.bind(this),
            TaskOrWorkflowNotFound: this.runDefaultErrorHandler.bind(this),
            TasksNotFound: this.runDefaultErrorHandler.bind(this),
            MissingTaskTitle: this.runDefaultErrorHandler.bind(this),
            InvalidTaskMultiplier: this.runDefaultErrorHandler.bind(this),
            InvalidEstimatedBudgetRowEntityKeyId: this.runDefaultErrorHandler.bind(this),
            InvalidEstimatedBudgetRowType: this.runDefaultErrorHandler.bind(this),
            InvalidTaskDuration: this.runDefaultErrorHandler.bind(this),
            TaskWasUpdatedByAnotherUser: this.runDefaultErrorHandler.bind(this),
            InvalidTaskLinkType: this.runDefaultErrorHandler.bind(this),
            InvalidTaskLinkReferenceId: this.runDefaultErrorHandler.bind(this),
            InvalidTaskResourceId: this.runDefaultErrorHandler.bind(this),
            InvalidTaskResourceType: this.runDefaultErrorHandler.bind(this),
            TaskDataChangesNotFound: this.runDefaultErrorHandler.bind(this),
            TaskStatusChangesNotFound: this.runDefaultErrorHandler.bind(this),
            TaskEstimateChangesNotFound: this.runDefaultErrorHandler.bind(this),
            TaskBoardStatusNotFound: this.runDefaultErrorHandler.bind(this),
            TaskWorkTimeCategoriesNotFound: this.runDefaultErrorHandler.bind(this),
            TaskRolesNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowMismatch: this.runDefaultErrorHandler.bind(this),
            ErrorWhileSavingTask: this.runDefaultErrorHandler.bind(this),
            DuplicatedTaskIdInChanges: this.runDefaultErrorHandler.bind(this),
            SettingNotFound: this.runDefaultErrorHandler.bind(this),
            SettingInvalidValue: this.runDefaultErrorHandler.bind(this),
            TaskFirstWorkingStateChangeNotFound: this.runDefaultErrorHandler.bind(this),
            TaskLastClosedStateChangeNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowOutcomeNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowOutcomePreventTasksEditing: this.runDefaultErrorHandler.bind(this),
            WorkflowJobOrderMismatch: this.runDefaultErrorHandler.bind(this),
            MissingTaskWorkEstimation: this.runDefaultErrorHandler.bind(this),
            WorkflowStatusChangesNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowTemplateNotFound: this.runDefaultErrorHandler.bind(this),
            InvalidWorkflowSourceTemplate: this.runDefaultErrorHandler.bind(this),
            MissingTasks: this.runDefaultErrorHandler.bind(this),
            MissingWorkflowTitle: this.runDefaultErrorHandler.bind(this),
            WorkflowDefaultCategoryNotFound: this.runDefaultErrorHandler.bind(this),
            WorkflowHasRelatedDocumentsOrWorkedHours: this.runDefaultErrorHandler.bind(this),
            WorkflowDefaultOutcomeNotFound: this.runDefaultErrorHandler.bind(this),
            GenericError: () => this.infoToastService.Error(TextResources.ProlifeSdk.GenericError),
        };
    }

    private runDefaultErrorHandler(errorCode: string): void {
        this.infoToastService.Error(TextResources.Todolist[errorCode]);
    }

    InitializeService() {
        super.InitializeService();

        new TemplatesDesignerApplication(this.serviceLocator);
        new TaskBoardApplication(this.serviceLocator);
        //new PersonalTodoListApplication.PersonalTodoListApplication(serviceLocator);
        new WorkflowEntityCodeGeneratorSettingsManager(this.serviceLocator);
        new DefaultTemplateSettingsManager(this.serviceLocator);
        new WorkflowCategoriesSettingsManager(this.serviceLocator);
        new WorkflowStatesSettingsManager(this.serviceLocator);
        new WorkflowOutcomeSettingsManager();
        new BudgetRequestCausesSettingsManager(this.serviceLocator);

        this.tasksFolders = [
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.All,
                "fa-check-square",
                "#428bca",
                "white",
                null,
                true
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.Backlog,
                "fa-inbox",
                "#c6c6c6",
                "black",
                -1,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.ToDo,
                "fa-tachometer",
                "#428bca",
                "white",
                0,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.InProgress,
                "fa-bolt",
                "#ecbc29",
                "black",
                1,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.Completed,
                "fa-flag-checkered",
                "#45b6af",
                "white",
                2,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.Verified,
                "fa-check",
                "#45b6af",
                "white",
                3,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.Suspended,
                "fa-clock-o",
                "#89c4f4",
                "black",
                4,
                false
            ),
            new TodoListFolder(
                this.serviceLocator,
                this,
                ProlifeSdk.TextResources.Todolist.DeletedColumn,
                "fa-trash-o",
                "#f3565d",
                "white",
                5,
                false
            ),
        ];

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.JobOrderTaskEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Todolist.Task,
            PluralEntityName: ProlifeSdk.TextResources.Todolist.Tasks,
            EntityIsMale: false,
            CanBeRelatedToRemind: true,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.TemplateTaskEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Todolist.Task,
            PluralEntityName: ProlifeSdk.TextResources.Todolist.Tasks,
            EntityIsMale: false,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.WorkflowEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Todolist.Workflow,
            PluralEntityName: ProlifeSdk.TextResources.Todolist.Workflows,
            EntityIsMale: true,
            CanBeRelatedToRemind: true,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.WorkflowTemplateEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Todolist.Template,
            PluralEntityName: ProlifeSdk.TextResources.Todolist.Templates,
            EntityIsMale: true,
        });

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.EstimatedPurchaseEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Todolist.EstimatedPurchases,
            PluralEntityName: ProlifeSdk.TextResources.Todolist.EstimatedPurchases,
            EntityIsMale: true,
        });

        this.eventsViewModels[ProlifeSdk.TaskStateChangeEventType] = TaskStateChangeEvent;
        this.eventsViewModels[ProlifeSdk.TaskDataChangeEventType] = TaskDataChangeEvent;
        this.eventsViewModels[ProlifeSdk.WorkflowStateChangeEventType] = WorkflowStateChangeEvent;

        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.EstimatedPurchaseEntityTypeCode,
            new EstimatedPurchaseRefDetailsViewModelFactory()
        );

        new JobOrderTaskRefUiForRemind(this.serviceLocator);
        new WorkflowRefUiForRemind(this.serviceLocator);

        let fields: IRemindReferenceObjFieldType[] = [];

        fields.push({
            Id: 1,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameStartDate,
            IsForTitle: false,
            IsForExpireDate: true,
        });

        fields.push({
            Id: 2,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameDuration,
            IsForTitle: false,
            IsForExpireDate: false,
        });

        fields.push({
            Id: 3,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameExpireDate,
            IsForTitle: false,
            IsForExpireDate: true,
        });

        fields.push({
            Id: 4,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameTitle,
            IsForTitle: true,
            IsForExpireDate: false,
        });

        this.remindService.registerObjReferenceFields(ProlifeSdk.JobOrderTaskEntityTypeCode, fields);

        fields = [];

        fields.push({
            Id: 1,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameWorkflowTitle,
            IsForTitle: true,
            IsForExpireDate: false,
        });

        fields.push({
            Id: 2,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameWorkflowExpectedStart,
            IsForTitle: false,
            IsForExpireDate: true,
        });

        fields.push({
            Id: 3,
            Description: ProlifeSdk.TextResources.Todolist.EntityFieldNameWorkflowExpectedEnd,
            IsForTitle: false,
            IsForExpireDate: true,
        });

        this.remindService.registerObjReferenceFields(ProlifeSdk.WorkflowEntityTypeCode, fields);

        this.actualWorkflowSectionsConfiguration = {
            WorkflowStateOpened: this.sectionsDefaultSettings.WorkflowStateOpened,
            AdvancedSettingsOpened: this.sectionsDefaultSettings.AdvancedSettingsOpened,
            ResourcesOpened: this.sectionsDefaultSettings.ResourcesOpened,
        };

        this.actualWorkflowRelatedDocumentsSectionsConfiguration = {
            InvoicesOpened: this.relatedDocumentsDefaultSettings.InvoicesOpened,
            DdtsOpened: this.relatedDocumentsDefaultSettings.DdtsOpened,
            EstimatesOpened: this.relatedDocumentsDefaultSettings.EstimatesOpened,
            SalsOpened: this.relatedDocumentsDefaultSettings.SalsOpened,
            CustomerOrdersOpened: this.relatedDocumentsDefaultSettings.CustomerOrdersOpened,
            SupplierOrdersOpened: this.relatedDocumentsDefaultSettings.SupplierOrdersOpened,
            WarehouseLoadsOpened: this.relatedDocumentsDefaultSettings.WarehouseLoadsOpened,
        };

        new WorkflowsSearchEntityProvider();
    }

    public OnServerInitializationDataLoaded() {
        new ActivitiesDataSource();
        new ActivitiesWarehouseDataSource();
        new ElementsToBeOrderedFromActivitiesDataSource();
    }

    /* TASKS EDITOR */
    async ShowCreateNewTaskDialog(
        jobOrderId: number,
        workflowId: number,
        options?: ITaskDialogOptions
    ): Promise<boolean> {
        const dialog = await this.prepareTaskEditingDialog(workflowId, jobOrderId, null, options);
        return dialog.ShowModal();
    }

    async ShowEditTaskDialog(taskId: number, options?: ITaskDialogOptions): Promise<boolean> {
        if (!options) options = {};

        options.tasksProvider = options.tasksProvider || new TodoListTasksProvider();

        try {
            const t: ITodoListTask = await this.GetTaskById(taskId);
            const task: TodoListTask = new TodoListTask(null, t, options.tasksProvider);
            const dialog = await this.prepareTaskEditingDialog(t.RelatedWorkFlow, null, task, options);
            return dialog.ShowModal();
        } catch (e) {
            console.log(e);
        }

        return false;
    }

    async ShowCopyTaskDialog(
        workflowId: number,
        jobOrderId: number,
        options: ITaskDialogOptions = null
    ): Promise<boolean> {
        const dialog = await this.prepareTaskEditingDialog(workflowId, jobOrderId, null, options, true);
        return dialog.ShowModal();
    }

    async ShowCreateTaskFromTemplateDialog(
        workflowId: number,
        jobOrderId: number,
        options: ITaskDialogOptions = null
    ): Promise<boolean> {
        const dialog = await this.prepareTaskEditingDialog(workflowId, jobOrderId, null, options, false, true);
        return dialog.ShowModal();
    }

    private async prepareTaskEditingDialog(
        workflowId: number,
        jobOrderId: number,
        activity: TodoListActivity = null,
        options: ITaskDialogOptions,
        taskCopy = false,
        fromTemplate = false
    ): Promise<ActivityDetailsDialog> {
        if (!options) options = {};

        options.tasksProvider = options.tasksProvider ?? new TodoListTasksProvider();
        const viewAll: boolean = options ? options.initialViewAll || false : false;
        const dataSource = new TasksDataSource();
        dataSource.setViewAll(viewAll);
        dataSource.enableNotifications(true);

        if (options.userId) dataSource.setUserId(options.userId);

        let defaultData = undefined;
        let activityProgressModeSuggestion = 0;
        let workflow: ITodoListWorkflow = null;

        const promises = [];
        promises.push(this.GetActivityProgressModeSuggestion(workflowId, jobOrderId));
        if (workflowId > 0) promises.push(this.GetWorkflow(workflowId));

        const results = await Promise.all(promises);
        activityProgressModeSuggestion = results[0] as number;
        workflow = results[1] as ITodoListWorkflow;
        defaultData = this.prepareNewTaskDefaultData([], jobOrderId, [], activityProgressModeSuggestion, workflow);

        const dialog: ActivityDetailsDialog = new ActivityDetailsDialog({
            activitiesProvider: options.tasksProvider,
            dataSource: dataSource,
            viewAll: viewAll,
            containerId: workflowId,
            defaultData: defaultData,
            showClosedJobOrders: options ? options.showClosedJobOrders : false,
            afterSaveCallback: (options && options.afterSaveCallback) || null,
            afterDeleteCallback: (options && options.afterDeleteCallback) || null,
            onCloseCallback: () => dataSource.enableNotifications(false),
            activity: activity,
            workedHoursData: !activity ? undefined : options?.workedHoursData,
            forTaskCopy: taskCopy,
            fromTemplate: fromTemplate,
            isMobileBrowser: this.desktopService.isMobileBrowser(),
        });

        return dialog;
    }

    private prepareNewTaskDefaultData(
        resources: ITaskResource[],
        jobOrderId: number,
        tags: ITodoListTaskTag[],
        activityProgressModeSuggestion: number,
        workflow: ITodoListWorkflow
    ) {
        jobOrderId = !jobOrderId || jobOrderId < 0 ? workflow?.JobOrderId : jobOrderId;

        const activityDefaultData: INewActivityDefaultData = {
            Resources: resources,
            Tags: !jobOrderId || jobOrderId < 0 ? tags : [TodoListTaskJobOrderTags.GetTag(jobOrderId.toString())],
            ActivitiesProgressAmountMode: activityProgressModeSuggestion,
        };

        this.applyContainerDefaults(activityDefaultData, workflow);
        return activityDefaultData;
    }
    /* TASKS EDITOR END */

    /* TASK TEMPLATES EDITOR */
    async ShowCreateTasktTemplateDialog(templateId: number, options: ITaskDialogOptions = null): Promise<void> {
        const dialog = await this.prepareTaskTemplateEditingDialog(templateId, null, options);
        await dialog.ShowModal();
    }

    async ShowEditTasktTemplateDialog(templateTaskId: number, options: ITaskDialogOptions = null): Promise<void> {
        if (!options) options = {};

        options.tasksProvider = options.tasksProvider || new TodoListTemplatesTasksProvider();

        try {
            const t: ITodoListTemplateTask = await this.GetTemplateTaskById(templateTaskId);
            const template: TodoListTemplateTask = new TodoListTemplateTask(null, t, options.tasksProvider);
            const dialog = await this.prepareTaskTemplateEditingDialog(t.TemplateId, template, options);
            await dialog.ShowModal();
        } catch (e) {
            console.log();
        }
    }

    private async prepareTaskTemplateEditingDialog(
        templateId: number,
        taskTemplate: TodoListTemplateTask,
        options: ITaskDialogOptions
    ) {
        if (!options) options = {};

        options.tasksProvider = options.tasksProvider ?? new TodoListTemplatesTasksProvider();
        const dataSource = new TemplateTasksDataSource();
        dataSource.enableNotifications(true);

        let defaultData = undefined;
        let activityProgressModeSuggestion = 0;
        let template: ITodoListTemplate = null;

        const promises = [];
        promises.push(this.GetActivityProgressModeSuggestion(templateId, null, true));
        if (templateId > 0) promises.push(this.GetTemplate(templateId));

        const results = await Promise.all(promises);
        activityProgressModeSuggestion = results[0] as number;
        template = results[1] as ITodoListTemplate;
        defaultData = this.prepareNewTaskTemplateDefaultData(template, activityProgressModeSuggestion);

        const dialog = new ActivityDetailsDialog({
            activitiesProvider: options.tasksProvider,
            dataSource: dataSource,
            viewAll: true,
            containerId: templateId,
            defaultData: defaultData,
            afterSaveCallback: (options && options.afterSaveCallback) || null,
            afterDeleteCallback: (options && options.afterDeleteCallback) || null,
            onCloseCallback: () => dataSource.enableNotifications(false),
            activity: taskTemplate,
            isTemplate: true,
        });

        return dialog;
    }

    private prepareNewTaskTemplateDefaultData(template: ITodoListTemplate, activityProgressModeSuggestion: number) {
        const activityDefaultData: INewActivityDefaultData = {
            Resources: [],
            Tags: [
                {
                    Type: "string",
                    Field: "SECURITYGROUPS",
                    Value: "000",
                },
            ],
        };

        activityDefaultData.ActivitiesProgressAmountMode = activityProgressModeSuggestion ?? 0;
        this.applyContainerDefaults(activityDefaultData, template);

        return activityDefaultData;
    }
    /* TASK TEMPLATES EDITOR END */

    /* WORKFLOWS EDITOR */
    ShowCreateWorkflowDialog(
        jobOrderId: number,
        showClosedJobOrdersOnSelector = false
    ): Promise<IWorkflowEditorResult> {
        const outcomesSettings = <IWorkflowOutcomeSettingsManager>(
            this.settingsManager.findSettingsManager(ProlifeSdk.WorkflowOutcomes)
        );

        const notInitializedStates = outcomesSettings.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: jobOrderId,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const dialog: WorkflowEditDialog = new WorkflowEditDialog({
            workflow: workflow,
            showClosedJobOrdersOnSelector: showClosedJobOrdersOnSelector,
        });
        return dialog.ShowDialog();
    }

    ShowEditWorkflowDialog(workflowId: number, showClosedJobOrdersOnSelector = false): Promise<IWorkflowEditorResult> {
        const def = new Deferred<IWorkflowEditorResult>();

        this.GetWorkflow(workflowId).then((w: ITodoListWorkflow) => {
            if (!w) {
                def.reject();
                return;
            }

            const dialog = new WorkflowEditDialog({
                workflow: w,
                showClosedJobOrdersOnSelector: showClosedJobOrdersOnSelector,
            });
            dialog
                .ShowDialog()
                .then((result: IWorkflowEditorResult) => {
                    def.resolve(result);
                })
                .catch(() => {
                    def.reject();
                });
        });

        return def.promise();
    }

    async ShowEditWorkflowSnapshotsDialog(workflowId: number): Promise<IWorkflowEditorResult> {
        const workflow = await this.GetWorkflow(workflowId);
        if (!workflow) return null;

        const dialog = new WorkflowEditDialog({ workflow: workflow, showSnapshotsEditorTab: true });
        return dialog.ShowDialog();
    }

    ShowCreateWorkflowFromWorkflowDialog(
        jobOrderId: number,
        showClosedJobOrdersOnSelector = false
    ): Promise<IWorkflowEditorResult> {
        const settingsManager = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const outcomesSettings = <IWorkflowOutcomeSettingsManager>(
            settingsManager.findSettingsManager(ProlifeSdk.WorkflowOutcomes)
        );

        const notInitializedStates = outcomesSettings.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: jobOrderId,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const dialog = new WorkflowEditDialog({
            workflow: workflow,
            showClosedJobOrdersOnSelector: showClosedJobOrdersOnSelector,
            copyFromWorkflowMode: true,
            jobOrderId: jobOrderId,
        });
        return dialog.ShowDialog();
    }

    async ShowCreateWorkflowFormTemplateDialog(
        jobOrderId: number,
        showClosedJobOrdersOnSelector = false
    ): Promise<ITodoListWorkflow> {
        const dialog: WorkflowEditDialog = new WorkflowEditDialog({
            workflow: null,
            showClosedJobOrdersOnSelector: showClosedJobOrdersOnSelector,
            jobOrderId: jobOrderId,
            importFromTemplateMode: true,
        });
        return (await dialog.ShowDialog())?.Workflow;
    }
    /* WORKFLOWS EDITOR END */

    private applyContainerDefaults(activityDefaultData: INewActivityDefaultData, container: ITodoListTemplateBase) {
        activityDefaultData.DefaultRoles = [];
        activityDefaultData.DefaultWorkTimeCategories = [];

        if (container) {
            activityDefaultData.ContainerId = container.Id;
            activityDefaultData.ReportingType = container.ReportingType;
            activityDefaultData.InQuality = container.InQuality;
            activityDefaultData.HideFromSuggestions = container.HideFromSuggestions;
            activityDefaultData.Multiplier = container.Multiplier;
            activityDefaultData.MultiplierUoM = container.MultiplierUoM;
            activityDefaultData.DefaultWorkPlace = container.DefaultWorkPlace;
            activityDefaultData.DefaultTravelDistance = container.DefaultTravelDistance;
            activityDefaultData.DefaultCallRight = container.DefaultCallRight;
            activityDefaultData.WorkedHoursDefaultsStrictMode = container.WorkedHoursDefaultsStrictMode;
            activityDefaultData.HideAdministrativeFieldsOnWorkedHours = container.HideAdministrativeFieldsOnWorkedHours;
            activityDefaultData.DefaultRoles =
                container.WorkedHoursDefaultRoles?.map((dr) => ({ RoleId: dr.RoleId, Order: dr.Order })) ?? [];
            activityDefaultData.DefaultWorkTimeCategories =
                container.WorkedHoursDefaultWorkTimeCategories?.map((dw) => ({
                    WorkTimeCategoryId: dw.WorkTimeCategoryId,
                    Order: dw.Order,
                })) ?? [];
        }
    }

    getWorkflowsDropDown(jobOrderIdGetter: () => number): IDropDownList {
        return new WorkflowsDropDownList(this.serviceLocator, jobOrderIdGetter);
    }

    getJobOrderTasksDropDown(workflowIdGetter: () => number): IDropDownList {
        return new JobOrderTaskDropDownList(this.serviceLocator, workflowIdGetter);
    }

    GetTasksFolders(): ITodoListFolder[] {
        return this.tasksFolders;
    }

    GetTaskStatusIconHelper(): ITaskStatusIconHelper {
        return new TaskStatusIconHelper(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.TodoListServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetMonthPlanForResource(resourceId: number, year: number, month: number): Promise<ITaskBoardPlanningDayPlan[]> {
        const startDate = moment()
            .year(year)
            .month(month - 1)
            .date(1)
            .startOf("day");
        const currentDate = startDate.clone();

        const request: IMonthPlanKey = {
            ResourceId: resourceId,
            Days: [],
        };

        while (currentDate.month() == startDate.month()) {
            const endOfDay = currentDate.clone().endOf("day");

            request.Days.push({
                Id: currentDate.date(),
                StartOfDay: currentDate.toDate(),
                EndOfDay: endOfDay.toDate(),
            });

            currentDate.add(1, "day");
        }

        return this.ajaxService.Post<ITaskBoardPlanningDayPlan[]>("Todolist-api/TaskBoard", "GetMonthPlanForResource", {
            methodData: request,
        });
    }

    async MoveTaskOnTaskBoard(
        taskId: number,
        isTask: boolean,
        newStatusId: number,
        userId: number,
        destWorkflowId: number | null,
        taskToMoveBeforeId: number | null,
        taskToMoveBeforeIsTask: boolean | null,
        before: boolean | null
    ): Promise<void> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("t/task", "moveTaskOnTaskBoard", {
                methodData: {
                    TaskId: taskId,
                    IsTask: isTask,
                    StatusId: newStatusId,
                    UserId: userId,
                    DestWorkflowId: destWorkflowId,
                    TaskToMoveBeforeId: taskToMoveBeforeId,
                    TaskToMoveBeforeIsTask: taskToMoveBeforeIsTask,
                    Before: before,
                },
            });

            if (!response.succeeded) {
                this.handleErrors(response.errors);
            }
        } catch (e) {
            const errors = e as ResponseBase;
            this.handleErrors(errors.errors);
        }
    }

    async MoveTasksOnTaskBoard(
        movedTasks: MovedTask[],
        newStatusId: number,
        userId: number,
        destWorkflowId: number | null,
        taskToMoveBeforeId: number | null,
        taskToMoveBeforeIsTask: boolean | null,
        before: boolean | null
    ): Promise<void> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("t/task", "moveTasksOnTaskBoard", {
                methodData: {
                    movedTasks: movedTasks,
                    statusId: newStatusId,
                    userId: userId,
                    destWorkflowId: destWorkflowId,
                    taskToMoveBeforeId: taskToMoveBeforeId,
                    taskToMoveBeforeIsTask: taskToMoveBeforeIsTask,
                    before: before,
                },
            });

            if (!response.succeeded) {
                this.handleErrors(response.errors);
            }
        } catch (e) {
            const errors = e as ResponseBase;
            this.handleErrors(errors.errors);
        }
    }

    MoveTaskToWorkflow(taskId: number, newWorkflowId: number): Promise<ResponseBase> {
        return this.MoveTasksToWorkflow([taskId], newWorkflowId);
    }

    async MoveTasksToWorkflow(taskIds: number[], newWorkflowId: number): Promise<ResponseBase> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("t/task", "moveTasksToWorkflow", {
                methodData: {
                    TaskIds: taskIds,
                    WorkflowId: newWorkflowId,
                },
            });

            if (!response.succeeded) this.handleErrors(response.errors);

            return response;
        } catch (e) {
            const errors = e as ResponseBase;
            this.handleErrors(errors.errors);

            throw errors;
        }
    }

    DeleteTask(taskId: number): Promise<ResponseBase> {
        return this.ajaxServiceNew.Delete("t/task", "" + taskId, {});
    }

    DeleteTemplateTask(taskId: number): Promise<void> {
        return this.ajaxService.Delete("Todolist-api/TemplatesTasks", "" + taskId, {});
    }

    DeleteWorkflow(workflowId: number, skipRelatedDocumentsAndWorkedHoursCheck = false): Promise<ResponseBase> {
        return this.ajaxServiceNew.Delete("t/workflow", "deleteWorkflow", {
            methodData: {
                WorkflowId: workflowId,
                SkipRelatedDocumentsAndWorkedHoursCheck: skipRelatedDocumentsAndWorkedHoursCheck,
            },
        });
    }

    DeleteTemplate(workflowId: number): Promise<void> {
        return this.ajaxService.Delete("Todolist-api/TodoListTemplates", "" + workflowId, {});
    }

    MoveTemplateTask(taskId: number, taskToMoveBeforeId: number, afterOrBefore: boolean): Promise<void> {
        return this.ajaxService.Post("Todolist-api", "TemplatesTasks/MoveTask", {
            methodData: {
                TaskId: taskId,
                MoveBeforeTaskId: taskToMoveBeforeId,
                AfterOrBefore: afterOrBefore,
            },
        });
    }

    GetNumberOfBlogEventsRelatedToTasks(ids: number[], background = false): Promise<IBlogEventsRelatedToTask[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetNumberOfBlogEventsRelatedToTasks", {
            methodData: ids,
            background: background,
        });
    }

    async MoveTask(
        taskId: number,
        taskIsTask: boolean,
        taskToMoveBeforeId: number,
        beforeIsTask: boolean,
        afterOrBefore: boolean
    ): Promise<void> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("t/task", "moveTask", {
                methodData: {
                    TaskId: taskId,
                    IsTask: taskIsTask,
                    MoveBeforeTaskId: taskToMoveBeforeId,
                    BeforeIsTask: beforeIsTask,
                    AfterOrBefore: afterOrBefore,
                },
            });

            if (!response.succeeded) {
                this.handleErrors(response.errors);
            }
        } catch (e) {
            const errors = e as ResponseBase;
            this.handleErrors(errors.errors);
        }
    }

    InsertOrUpdateTemplate(workflow: ITodoListTemplate): Promise<ITodoListTemplate> {
        return this.ajaxService.Post("Todolist-api", "TodoListTemplates/InsertOrUpdateTemplate", {
            methodData: workflow,
        });
    }

    CreateTemplateFromWorkflow(template: ITodoListTemplate, workflowId: number): Promise<ITodoListTemplate> {
        return this.ajaxService.Post("Todolist-api", "TodoListTemplates/CreateTemplateFromWorkflow", {
            methodData: {
                Template: template,
                WorkflowId: workflowId,
            },
        });
    }

    async InsertOrUpdateWorkflow(workflow: ITodoListWorkflow): Promise<ITodoListWorkflow> {
        let response: ResponseData<InsertOrUpdateWorkflowResponse> = null;
        try {
            if (!workflow.Id || workflow.Id <= 0) {
                response = await this.ajaxServiceNew.Post("t/workflow", "create", {
                    methodData: {
                        Workflow: workflow,
                    },
                });
            } else {
                response = await this.ajaxServiceNew.Put("t/workflow", "update", {
                    methodData: {
                        Workflow: workflow,
                    },
                });
            }
        } catch (e) {
            response = e;
        }

        if (!response.succeeded) {
            this.handleErrors(response.errors);
            return null;
        }

        const resultWorkflow = toPascalCase(response.data.workflow);
        this.infoToastService.Success(TextResources.Todolist.WorkflowSaved);
        return resultWorkflow;
    }

    PreviewWorkflowCode(workflow: ITodoListWorkflow): Promise<string> {
        return this.ajaxService.Post("Todolist-api", "Workflow/PreviewCode", {
            methodData: workflow,
            background: true,
        });
    }

    async CreateFromTemplate(workflow: ITodoListWorkflow): Promise<ITodoListWorkflow> {
        let response: ResponseData<ITodoListWorkflowNew> = null;

        try {
            response = await this.ajaxServiceNew.Post("t/workflow", "createFromTemplate", {
                methodData: workflow,
            });
        } catch (e) {
            response = e;
        }

        if (!response.succeeded) {
            this.handleErrors(response.errors);
            return null;
        }

        const resultWorkflow = toPascalCase(response.data);
        this.infoToastService.Success(TextResources.Todolist.WorkflowSaved);

        return resultWorkflow;
    }

    async CreateWorkflowFromWorkflow(
        workflow: ITodoListWorkflow,
        sourceWorkflowId: number
    ): Promise<ITodoListWorkflow> {
        let response: ResponseData<ITodoListWorkflowNew> = null;
        try {
            response = await this.ajaxServiceNew.Post("t/workflow", "createWorkflowFromWorkflow", {
                methodData: {
                    Workflow: workflow,
                    SourceWorkflowId: sourceWorkflowId,
                },
            });
        } catch (e) {
            response = e;
        }

        if (!response.succeeded) {
            this.handleErrors(response.errors);
            return null;
        }

        const resultWorkflow = toPascalCase(response.data);
        this.infoToastService.Success(TextResources.Todolist.WorkflowSaved);

        return resultWorkflow;
    }

    private handleErrors(errors: ResponseError[]): void {
        for (const err of errors) {
            if (this.errorHandlers[err.code]) {
                this.errorHandlers[err.code](err.code, err.metadata);
            } else {
                console.error(
                    "Unhandled error: " +
                        err.code +
                        "\r\nError handler is missing for error code: " +
                        err.code +
                        ". Default message will be shown."
                );
                this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
            }
        }
    }

    InsertOrUpdateTask(
        task: ITodoListTask,
        ignoreUnestimatedTaskCheck = false,
        overwriteOtherUsersChanges = false
    ): Promise<ResponseData<ITaskInsertOrUpdateResponse>> {
        const methodData = {
            Task: task,
            IgnoreUnestimatedTaskCheck: ignoreUnestimatedTaskCheck,
            OverwriteOtherUsersChanges: overwriteOtherUsersChanges,
        };

        if (task.Id > 0) {
            return this.ajaxServiceNew.Put("t/task/update", "", {
                methodData: methodData,
            });
        } else {
            return this.ajaxServiceNew.Post("t/task/create", "", {
                methodData: methodData,
            });
        }
    }

    InsertOrUpdateTemplateTask(task: ITodoListTemplateTask): Promise<ITodoListTemplateTaskInsertResponse> {
        return this.ajaxService.Post("Todolist-api", "TemplatesTasks/InsertOrUpdateTemplateTask", {
            methodData: task,
        });
    }

    GetTaskWithBefore(taskId: number, isTask: boolean, filters: any): Promise<ITodoListTaskWithBefore> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTaskWithBefore", {
            methodData: {
                TaskId: taskId,
                Filters: filters,
                IsTask: isTask,
            },
            background: true,
        });
    }

    GetTemplateTaskWithBefore(taskId: number, filters: any): Promise<ITodoListTemplateTaskWithBefore> {
        return this.ajaxService.Post("Todolist-api", "TemplatesTasks/GetTaskWithBefore", {
            methodData: {
                TaskId: taskId,
                Filters: filters,
            },
            background: true,
        });
    }

    GetNextTask(taskId: number, filters: any): Promise<ITodoListTask> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetNextTask", {
            methodData: {
                TaskId: taskId,
                Filters: filters,
            },
            background: true,
        });
    }

    GetNextTemplateTask(taskId: number, filters: any): Promise<ITodoListTemplateTask> {
        return this.ajaxService.Post("Todolist-api", "TemplatesTasks/GetNextTask", {
            methodData: {
                TaskId: taskId,
                Filters: filters,
            },
            background: true,
        });
    }

    GetTasksByIds(ids: number[]): Promise<ITodoListTask[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTasksByIds", {
            methodData: ids,
            background: true,
        });
    }

    async UpdateTasksStatus(tasksStatus: { Id: number; Status: number; ProgressAvg: number }[]): Promise<void> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("t/task", "updateTasksStatus", {
                methodData: tasksStatus,
                background: true,
            });

            if (!response.succeeded) {
                this.handleErrors(response.errors);
            }
        } catch (e) {
            const errors = e as ResponseBase;
            this.handleErrors(errors.errors);
        }
    }

    GetTasks(
        textFilters: string[],
        tagsFilter: ITodoListTaskTag[],
        workflowsIds: number[],
        statusFolder: number,
        resourcesGroup: number,
        skip: number,
        count: number,
        activitiesProgressMode: number = null
    ): Promise<ITodoListTask[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTasks", {
            methodData: {
                TextFilters: textFilters,
                StatusFolder: statusFolder,
                ResourcesGroup: resourcesGroup,
                Tags: tagsFilter,
                WorkflowsIds: workflowsIds,
                Skip: skip,
                Count: count,
                ActivitiesProgressMode: activitiesProgressMode,
            },
            background: true,
        });
    }

    GetTasksForAllocations(
        textFilter: string,
        workflowId: number,
        jobOrderId: number,
        advancedFilters: IJobOrdersMenuAdvancedFilters,
        skip: number,
        count: number,
        background = false
    ): Promise<ITodoListTaskForAllocations[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTasksForAllocations", {
            methodData: {
                TextFilter: textFilter,
                WorkflowId: workflowId,
                JobOrderId: jobOrderId,
                AdvancedFilters: advancedFilters,
                Skip: skip,
                Count: count,
            },
            background: background,
        });
    }

    GetTasksByIdsForAllocationsList(ids: number[], background = false): Promise<ITodoListTaskForAllocations[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTasksByIdsForAllocationsList", {
            methodData: {
                Ids: ids,
            },
            background: background,
        });
    }

    GetTasksAndWorkflows(
        textFilters: string[],
        tagsFilter: ITodoListTaskTag[],
        workflowsIds: number[],
        statusFolder: number,
        resourcesGroup: number,
        skip: number,
        count: number
    ): Promise<ITodoListTask[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetTasksAndWorkflows", {
            methodData: {
                TextFilters: textFilters,
                StatusFolder: statusFolder,
                ResourcesGroup: resourcesGroup,
                Tags: tagsFilter,
                WorkflowsIds: workflowsIds,
                Skip: skip,
                Count: count,
            },
            background: true,
        });
    }

    GetActivitiesTagsAndResources(activitiesIds: number[]): Promise<ITodoListTaskTagsAndResources[]> {
        return this.ajaxService.Post("Todolist-api", "TodoListTask/GetActivitiesTagsAndResources", {
            methodData: {
                ActivitiesIds: activitiesIds,
            },
        });
    }

    GetTemplatesTasks(
        textFilters: string[],
        tagsFilter: ITodoListTaskTag[],
        templateId: number,
        statusFolder: number,
        resourcesGroup: number,
        skip: number,
        count: number
    ): Promise<ITodoListTemplateTask[]> {
        return this.ajaxService.Post("Todolist-api", "TemplatesTasks/GetTasks", {
            methodData: {
                TextFilters: textFilters,
                StatusFolder: statusFolder,
                ResourcesGroup: resourcesGroup,
                Tags: tagsFilter,
                TemplateId: templateId,
                Skip: skip,
                Count: count,
            },
            background: true,
        });
    }

    GetTaskById(id: number, background = false): Promise<ITodoListTask> {
        return this.ajaxService.Get("Todolist-api", "TodoListTask/GetTaskById?id=" + id, { background: background });
    }

    GetTemplateTaskById(id: number): Promise<ITodoListTemplateTask> {
        return this.ajaxService.Get("Todolist-api", "TemplatesTasks/GetTemplateTaskById?id=" + id, {});
    }

    GetTemplate(id: number): Promise<ITodoListTemplate> {
        return this.ajaxService.Get("Todolist-api", "TodoListTemplates/GetTemplate?id=" + id, {});
    }

    GetWorkflow(id: number): Promise<ITodoListWorkflow> {
        if (!id) return Promise.resolve(null);

        return this.ajaxService.Get("Todolist-api", "Workflow/GetWorkflow?id=" + id, {});
    }

    GetWorkflowsForList(
        jobOrderId: number,
        textFilter: string,
        background = true
    ): Promise<ITodoListWorkflowForList[]> {
        return this.ajaxService.Post("Todolist-api/Workflow", "GetWorkflowsForList", {
            methodData: {
                JobOrderId: jobOrderId,
                TextFilter: textFilter,
            },
            background: background,
        });
    }

    GetWorkflowsForAllocations(
        jobOrderId: number,
        textFilter: string,
        advancedFilters: IJobOrdersMenuAdvancedFilters,
        background = true
    ): Promise<ITodolistWorkflowForAllocationsList[]> {
        return this.ajaxService.Post("Todolist-api/Workflow", "GetWorkflowsForAllocations", {
            methodData: {
                JobOrderId: jobOrderId,
                TextFilter: textFilter,
                AdvancedFilters: advancedFilters,
            },
            background: background,
        });
    }

    GetWorkflowForList(id: number): Promise<ITodoListWorkflowForList> {
        return this.ajaxService.Get("Todolist-api", "Workflow/GetWorkflowForList?id=" + id, {});
    }

    GetWorkflowForAllocationsList(id: number): Promise<ITodolistWorkflowForAllocationsList> {
        return this.ajaxService.Get("Todolist-api", "Workflow/GetWorkflowForAllocationsList?id=" + id, {});
    }

    GetTemplateForList(id: number): Promise<ITodoListTemplateForList> {
        return this.ajaxService.Get("Todolist-api", "TodoListTemplates/GetTemplateForList?id=" + id, {});
    }

    GetTemplatesForList(textFilter: string, skip: number, count: number): Promise<ITodoListTemplateForList[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTemplates", "GetTemplatesForList", {
            methodData: {
                TextFilter: textFilter,
                Skip: skip,
                Count: count,
            },
        });
    }

    SearchLabels(textFilter: string): Promise<string[]> {
        return this.ajaxService.Get(
            "Desktop-api",
            "TodoListTag/HintSearchLabels?textFilter=" + encodeURIComponent(textFilter),
            {
                methodData: textFilter,
                background: true,
            }
        );
    }

    SearchTemplatesLabels(textFilter: string): Promise<string[]> {
        return this.ajaxService.Get(
            "Desktop-api",
            "TemplateTag/HintSearchLabels?textFilter=" + encodeURIComponent(textFilter),
            {
                methodData: textFilter,
                background: true,
            }
        );
    }

    /* GetTodoListUI(options? : ITodoListOptions) : ITodoList
    {
        const tasksProvider : ITodoListElementsProvider = new TodoListTasksProvider();
        return tasksProvider.UI.GetTodoList(null, options);
    } */

    GetWorkflowNavigatorUI(navigatorOptions?: IWorkflowNavigatorOptions): IWorkflowNavigator {
        return new WorkflowsNavigator(this.serviceLocator, navigatorOptions || {});
    }

    /* GetJobOrderNavigatorUI() : IJobOrderNavigator
    {
        return new JobOrderNavigator(this.serviceLocator);
    } */

    GetTemplatesNavigatorUI(navigateTasks = true): ITemplatesNavigator {
        return new TemplatesNavigator(this.serviceLocator, navigateTasks);
    }

    GetWorkflowActivitiesMultipliersManagerUI(workflow: ITodoListWorkflow): IWorkflowActivitiesMultipliersManager {
        return new WorkflowActivitiesMultipliersManager(workflow);
    }

    getUIForEvent(item: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        const type = this.eventsViewModels[item.EventType] || BlogEvent;

        const event: BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView>event;
    }

    GetEstimatedWorkHistory(jobOrderId: number, workflowId: number): Promise<IEstimatedWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetEstimatedWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetComputedEstimatedWorkHistory(jobOrderId: number, workflowId: number): Promise<IEstimatedWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetComputedEstimatedWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetDoneWorkHistory(jobOrderId: number, workflowId: number): Promise<IDoneWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetDoneWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetAllDoneWorkHistory(jobOrderId: number, workflowId: number): Promise<IDoneWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetAllDoneWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetClosedWorkHistory(jobOrderId: number, workflowId: number): Promise<IClosedWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetClosedWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetPerformanceDashboardInfo(jobOrderId: number, workflowId: number): Promise<IPerformanceDashboardInfo> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetPerformanceDashboardInfo", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetWorkingResources(jobOrderId: number, workflowId: number): Promise<IWorkingResource[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetWorkingResources", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetRemainingWorkHistory(jobOrderId: number, workflowId: number): Promise<IRemainingWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetRemainingWorkHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetWorkingResourceAllocation(resourceId: number): Promise<IWorkingResourceAllocation[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetWorkingResourceAllocation", {
            methodData: { resourceId: resourceId },
            background: true,
        });
    }

    GetPerformanceUpdateState(jobOrderId: number): Promise<Date> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetLastUpdateDate", {
            methodData: { jobOrderId: jobOrderId },
            background: true,
        });
    }

    GetWorkflowsRevenues(jobOrderId: number): Promise<IWorkflowRevenue[]> {
        return this.ajaxService.Post("Todolist-api/WorkflowRevenues", "GetWorkflowsRevenues", {
            methodData: { JobOrderId: jobOrderId },
            background: true,
        });
    }

    GetWorkflowsRevenuesForWorkflow(workflowId: number): Promise<IWorkflowRevenue[]> {
        return this.ajaxService.Post("Todolist-api/WorkflowRevenues", "GetWorkflowsRevenuesForWorkflow", {
            methodData: { WorkflowId: workflowId },
            background: true,
        });
    }

    SaveWorkflowsRevenues(jobOrderId: number, revenuesRows: IWorkflowRevenue[]): Promise<void> {
        return this.ajaxService.Post("Todolist-api/WorkflowRevenues", "SaveWorkflowsRevenues", {
            methodData: { JobOrderId: jobOrderId, WorkflowRevenues: revenuesRows },
            background: false,
        });
    }

    GetTaskChangeHistory(taskId: number): Promise<ITodoListTaskChangeHistory[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetTaskChangeHistory", {
            methodData: { taskId: taskId },
            background: true,
        });
    }

    SaveTaskChangeHistory(changes: ITodoListTaskChangeHistory[]): Promise<ResponseBase> {
        return this.ajaxServiceNew.Post("t/task", "saveTaskChangeHistory", {
            methodData: changes,
            background: false,
        });
    }

    GetTaskWorkHistory(taskId: number): Promise<ITodoListTaskWorkHistory[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetTaskWorkHistory", {
            methodData: { taskId: taskId },
            background: true,
        });
    }

    GetWorkAndPurchaseCostsForTask(taskId: number): Promise<IWorkAndPurchaseCosts> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetWorkAndPurchaseCostsForTask", {
            methodData: { taskId: taskId },
            background: true,
        });
    }

    GetWorkflowChangeHistory(workflowId: number): Promise<IWorkflowChange[]> {
        return this.ajaxService.Post("Todolist-api/Workflow", "GetWorkflowChangeHistory", {
            methodData: { workflowId: workflowId },
            background: true,
        });
    }

    GetEstimatedPurchasesByIds(estimatedPurchasesIds: number[]): Promise<IEstimateBudgetRow[]> {
        return this.ajaxService.Post("Todolist-api/EstimatedPurchases", "GetEstimatedPurchasesByIds", {
            methodData: { estimatedPurchasesIds: estimatedPurchasesIds },
            background: true,
        });
    }

    GetWorkflowsEntitiesProvider(): IControlsEntityProvider {
        return new TodoListWorkflowsControlsEntityProvider();
    }

    GetApprovedBudgetHistory(jobOrderId: number, workflowId: number): Promise<IApprovedBudgetHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetApprovedBudgetHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    GetAssignedBudgets(taskId: number): Promise<ITodoListTaskAssignedBudgets[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetAssignedBudgets", {
            methodData: { taskId: taskId },
            background: true,
        });
    }

    GetWorkSpeedHistory(jobOrderId: number, workflowId: number): Promise<IWorkSpeedHistory[]> {
        return this.ajaxService.Post("Todolist-api/PerformanceDashboard", "GetWorkSpeedHistory", {
            methodData: { jobOrderId: jobOrderId, workflowId: workflowId },
            background: true,
        });
    }

    CanChangeState(taskId: number): Promise<boolean> {
        return this.ajaxService.Post("Todolist-api/TaskBoard", "CanChangeState", {
            methodData: { taskId: taskId },
            background: true,
        });
    }

    TasksCanChangeState(taskIds: number[]): Promise<ResponseData<TasksCanChangeStateResult[]>> {
        return this.ajaxServiceNew.Post("t/task", "canChangeState", { methodData: taskIds });
    }

    GetCartsForResource(
        loggedUserId: number,
        viewWorker: boolean,
        viewResponsible: boolean,
        categoryId: number,
        showClosed: boolean,
        hideNoWork: boolean,
        viewAll: boolean
    ): Promise<ICartForResource[]> {
        return this.ajaxService.Post("Todolist-api", "TaskBoard/GetCartsForResource", {
            methodData: {
                loggedUserId: loggedUserId,
                viewWorker: viewWorker,
                viewResponsible: viewResponsible,
                categoryId: categoryId,
                showClosed: showClosed,
                hideNoWork: hideNoWork,
                viewAll: viewAll,
            },
        });
    }

    HasBillableHours(workflowId: number, taskId: number, isTask: boolean): Promise<boolean> {
        return this.ajaxService.Post("Todolist-api/Taskboard", "HasBillableHours", {
            methodData: {
                WorkflowId: workflowId,
                TaskId: taskId,
                IsTask: isTask,
            },
        });
    }

    GetActivityProgressModeSuggestion(workflowId: number, jobOrderId: number, isTemplate = false): Promise<number> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetActivityProgressModeSuggestion", {
            methodData: {
                WorkflowId: workflowId,
                JobOrderId: jobOrderId,
                IsTemplate: isTemplate,
            },
        });
    }

    SaveActivitiesMultipliers(
        tasks: ITodoListTask[],
        forceSaving = false,
        ignoreNotEstimatedTasks = false
    ): Promise<ResponseBase> {
        return this.ajaxServiceNew.Post("t/task", "updateEstimatedCosts", {
            methodData: {
                TasksList: tasks,
                ForceSaving: forceSaving,
                IgnoreNotEstimatedTasks: ignoreNotEstimatedTasks,
            },
        });
    }

    PrintProductionActivitiesDocument(jobOrderId: number, selectedWorkflows: number[]): Promise<void> {
        return this.ajaxService.Download("Todolist-api/ProductionActivitiesDocument", "Print", {
            methodData: {
                JobOrderId: jobOrderId,
                WorkflowsToBePrinted: selectedWorkflows,
            },
        });
    }

    async CreateNewWorkflowWithTasks(
        title: string,
        tasksIds: number[],
        jobOrderId: number
    ): Promise<ITodoListWorkflow> {
        let response: ResponseData<ITodoListWorkflowNew> = null;

        try {
            response = await this.ajaxServiceNew.Post("t/workflow", "createNewWorkflowWithTasks", {
                methodData: {
                    Title: title,
                    TasksIds: tasksIds,
                    JobOrderId: jobOrderId,
                },
            });
        } catch (e) {
            response = e as ResponseData<ITodoListWorkflowNew>;
        }

        if (!response.succeeded) {
            this.handleErrors(response.errors);
            return null;
        }

        const resultWorkflow = toPascalCase(response.data);
        this.infoToastService.Success(TextResources.Todolist.WorkflowSaved);

        return resultWorkflow;
    }

    CheckTasksCompatibilityForMerge(sourceTaskId: number, targetTaskId: number): Promise<boolean> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "CheckTasksCompatibilityForMerge", {
            methodData: {
                SourceTaskId: sourceTaskId,
                TargetTaskId: targetTaskId,
            },
        });
    }

    MergeTasks(sourceTaskId: number, targetTaskId: number): Promise<void> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "MergeTasks", {
            methodData: {
                SourceTaskId: sourceTaskId,
                TargetTaskId: targetTaskId,
            },
        });
    }

    GetTasksLimitedList(
        textFilter: string,
        jobOrderId: number,
        workflowId: number,
        statusFolder: number,
        skip: number,
        count: number
    ): Promise<ITodoListTaskForLimitedList[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetTasksLimitedList", {
            methodData: {
                TextFilter: textFilter,
                JobOrderId: jobOrderId,
                WorkflowId: workflowId,
                StatusFolder: statusFolder,
                Skip: skip,
                Count: count,
            },
        });
    }

    GetWorkflowsForLimitedList(
        jobOrderId: number,
        textFilter: string,
        skip: number,
        count: number,
        background = true
    ): Promise<ITodoListWorkflowForLimitedList[]> {
        return this.ajaxService.Post("Todolist-api/Workflow", "GetWorkflowsForLimitedList", {
            methodData: {
                JobOrderId: jobOrderId,
                TextFilter: textFilter,
                Skip: skip,
                Count: count,
            },
            background: background,
        });
    }

    getRolesConfiguredOnTask(taskId: number): Promise<IUserCharacter[]> {
        return this.ajaxService.Post("Todolist-api/TodoListTask", "GetRolesConfiguredOnTask", {
            methodData: {
                TaskId: taskId,
            },
        });
    }

    async getWorkflowSectionsConfiguration(): Promise<IWorkflowSectionsDefaultSettings> {
        let configuration: IApplicationConfiguration;

        if (!this.workflowSectionsConfigurationLoaded) {
            const moduleId: number = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);

            if (!moduleId) return this.sectionsDefaultSettings;

            const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(
                ProlifeSdk.WorkflowDetailsDialogConfigType,
                this.userInfo.getIdUser(),
                moduleId,
                null
            );
            configuration = configurations && configurations.length > 0 ? configurations[0] : null;

            this.workflowSectionsConfigurationLoaded = true;

            if (!configuration || !configuration.Configuration) return this.actualWorkflowSectionsConfiguration;

            this.savedWorkflowSectionsConfiguration = configuration;
            const savedConfig: IWorkflowSectionsDefaultSettings = JSON.parse(configuration.Configuration);

            this.actualWorkflowSectionsConfiguration.WorkflowStateOpened = savedConfig.WorkflowStateOpened;
            this.actualWorkflowSectionsConfiguration.ResourcesOpened = savedConfig.ResourcesOpened;
            this.actualWorkflowSectionsConfiguration.AdvancedSettingsOpened = savedConfig.AdvancedSettingsOpened;
        }

        return this.actualWorkflowSectionsConfiguration;
    }

    async saveWorkflowSectionsConfiguration(config: IWorkflowSectionsDefaultSettings): Promise<void> {
        const configToBeSaved: IApplicationConfiguration = $.extend(
            true,
            {},
            this.savedWorkflowSectionsConfiguration
        ) as IApplicationConfiguration;
        configToBeSaved.Configuration = JSON.stringify(config);
        configToBeSaved.ConfigurationType = ProlifeSdk.WorkflowDetailsDialogConfigType;
        configToBeSaved.UserID = this.userInfo.getIdUser();

        if (!configToBeSaved.ModuleId) {
            const moduleId = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);
            if (!moduleId) return;

            configToBeSaved.ModuleId = moduleId;
        }

        await this.applicationsConfigurationsService.AddOrUpdateConfiguration(configToBeSaved);

        this.actualWorkflowSectionsConfiguration = config;
    }

    async saveWorkflowRelatedDocumentsSectionsConfiguration(
        config: IWorkflowRelatedDocumentsSectionsDefaultSettings
    ): Promise<void> {
        const configToBeSaved: IApplicationConfiguration = $.extend(
            true,
            {},
            this.savedWorkflowRelatedDocumentsSectionsConfiguration
        ) as IApplicationConfiguration;
        configToBeSaved.Configuration = JSON.stringify(config);
        configToBeSaved.ConfigurationType = ProlifeSdk.WorkflowRelatedDocumentsConfigType;
        configToBeSaved.UserID = this.userInfo.getIdUser();

        if (!configToBeSaved.ModuleId) {
            const moduleId = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);
            if (!moduleId) return;

            configToBeSaved.ModuleId = moduleId;
        }

        await this.applicationsConfigurationsService.AddOrUpdateConfiguration(configToBeSaved);

        this.actualWorkflowRelatedDocumentsSectionsConfiguration = config;
    }

    async getWorkflowRelatedDocumentsSectionsConfiguration(): Promise<IWorkflowRelatedDocumentsSectionsDefaultSettings> {
        let configuration: IApplicationConfiguration;

        if (!this.workflowRelatedDocumentsConfigurationLoaded) {
            const moduleId: number = await this.modulesService.getModuleId(ProlifeSdk.TaskBoardApplicationCode);

            if (!moduleId) return this.relatedDocumentsDefaultSettings;

            const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(
                ProlifeSdk.WorkflowRelatedDocumentsConfigType,
                this.userInfo.getIdUser(),
                moduleId,
                null
            );
            configuration = configurations && configurations.length > 0 ? configurations[0] : null;

            this.workflowRelatedDocumentsConfigurationLoaded = true;

            if (!configuration || !configuration.Configuration)
                return this.actualWorkflowRelatedDocumentsSectionsConfiguration;

            this.savedWorkflowRelatedDocumentsSectionsConfiguration = configuration;
            const savedConfig: IWorkflowRelatedDocumentsSectionsDefaultSettings = JSON.parse(
                configuration.Configuration
            );

            this.actualWorkflowRelatedDocumentsSectionsConfiguration.InvoicesOpened = savedConfig.InvoicesOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.DdtsOpened = savedConfig.DdtsOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.EstimatesOpened = savedConfig.EstimatesOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.SalsOpened = savedConfig.SalsOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.CustomerOrdersOpened =
                savedConfig.CustomerOrdersOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.SupplierOrdersOpened =
                savedConfig.SupplierOrdersOpened;
            this.actualWorkflowRelatedDocumentsSectionsConfiguration.WarehouseLoadsOpened =
                savedConfig.WarehouseLoadsOpened;
        }

        return this.actualWorkflowRelatedDocumentsSectionsConfiguration;
    }

    GetArticleCostAndPriceForTask(
        TaskJobOrderId: number | null,
        ArticleId: number | null,
        ReferenceDate: Date | null
    ): Promise<IArticleCostAndPrice> {
        return this.ajaxService.Post<IArticleCostAndPrice>(
            "Todolist-api/TodoListTask",
            "GetArticleCostAndPriceForTask",
            {
                background: true,
                methodData: {
                    TaskJobOrderId: TaskJobOrderId,
                    ArticleId: ArticleId,
                    ReferenceDate: ReferenceDate,
                },
            }
        );
    }

    IsTaskCreationReferenceDateBeforeOrSameOfDate(taskId: number | null, date: Date | null): Promise<boolean> {
        return this.ajaxService.Post<boolean>(
            "Todolist-api/TodoListTask",
            "IsTaskCreationReferenceDateBeforeOrSameOfDate",
            {
                background: true,
                methodData: {
                    taskId: taskId,
                    date: date,
                },
            }
        );
    }

    DeleteWorkflowCategory(id: number | null): Promise<void> {
        return this.ajaxService.Post<void>("Todolist-api/WorkflowCategories", "DeleteWorkflowCategory", {
            background: true,
            methodData: {
                id: id,
            },
        });
    }

    GetWorkflowsForSelectListByIds(ids: number[] | null): Promise<IWorkflowForSelectList[]> {
        return this.ajaxService.Post<IWorkflowForSelectList[]>(
            "Todolist-api/Workflow",
            "GetWorkflowsForSelectListByIds",
            {
                background: true,
                methodData: {
                    ids: ids,
                },
            }
        );
    }

    GetWorkflowRelatedDocuments(WorkflowId: number | null): Promise<IWorkflowRelatedDocument[]> {
        return this.ajaxService.Post<IWorkflowRelatedDocument[]>(
            "Todolist-api/WorkflowRelatedDocuments",
            "GetWorkflowRelatedDocuments",
            {
                background: true,
                methodData: {
                    WorkflowId: WorkflowId,
                },
            }
        );
    }

    GetWorkflowRelatedDocumentsNew(workflowId: number | null): Promise<WorkflowRelatedDocumentNew[]> {
        return this.ajaxServiceNew.Get<WorkflowRelatedDocumentNew[]>(
            "t/workflowRelatedDocuments",
            workflowId.toString(),
            {
                background: true,
            }
        );
    }

    InsertOrUpdateWorkflowRelatedDocuments(
        WorkflowId: number | null,
        RelatedDocuments: IWorkflowRelatedDocument[] | null
    ): Promise<void> {
        return this.ajaxService.Post<void>(
            "Todolist-api/WorkflowRelatedDocuments",
            "InsertOrUpdateWorkflowRelatedDocuments",
            {
                background: true,
                methodData: {
                    WorkflowId: WorkflowId,
                    RelatedDocuments: RelatedDocuments,
                },
            }
        );
    }

    GetUpdatedDataForWorkflowRelatedDocuments(
        WorkflowId: number | null,
        RowId: number | null,
        RowType: string | null
    ): Promise<IWorkflowRelatedDocument> {
        return this.ajaxService.Post<IWorkflowRelatedDocument>(
            "Todolist-api/WorkflowRelatedDocuments",
            "GetUpdatedDataForWorkflowRelatedDocuments",
            {
                background: true,
                methodData: {
                    WorkflowId: WorkflowId,
                    RowId: RowId,
                    RowType: RowType,
                },
            }
        );
    }

    CreateSnapshot(
        label: string | null,
        date: Date | null,
        workflowId: number | null,
        createdByResourceId: number | null
    ): Promise<ISnapshot> {
        return this.ajaxService.Post<ISnapshot>("Todolist-api/Workflow", "CreateSnapshot", {
            background: true,
            methodData: {
                label: label,
                date: date,
                workflowId: workflowId,
                createdByResourceId: createdByResourceId,
            },
        });
    }

    CreateOrUpdateSnapshot(request: ICreateOrUpdateSnapshotRequest): Promise<ISnapshot> {
        return this.ajaxService.Post<ISnapshot>("Todolist-api/WorkflowSnapshots", "CreateOrUpdateSnapshot", {
            background: true,
            methodData: request,
        });
    }

    DeleteSnapshot(id: number | null): Promise<void> {
        return this.ajaxService.Post<void>("Todolist-api/WorkflowSnapshots", "DeleteSnapshot", {
            background: true,
            methodData: {
                id: id,
            },
        });
    }

    GetWorkflowSnapshots(
        workflowId: number | null,
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<ISnapshot[]> {
        return this.ajaxService.Post<ISnapshot[]>("Todolist-api/WorkflowSnapshots", "GetWorkflowSnapshots", {
            background: true,
            methodData: {
                workflowId: workflowId,
                textFilter: textFilter,
                skip: skip,
                count: count,
            },
        });
    }

    GetWorkflowSnapshotsByIds(snapshotsIds: number[] | null): Promise<ISnapshot[]> {
        return this.ajaxService.Post<ISnapshot[]>("Todolist-api/WorkflowSnapshots", "GetWorkflowSnapshotsByIds", {
            background: true,
            methodData: {
                snapshotsIds: snapshotsIds,
            },
        });
    }

    ApplySnapshotToWorkflowTasks(
        snapshotId: number | null,
        userId: number | null
    ): Promise<IApplySnapshotToWorkflowTasksResponse[]> {
        return this.ajaxService.Post<IApplySnapshotToWorkflowTasksResponse[]>(
            "Todolist-api/WorkflowSnapshots",
            "ApplySnapshotToWorkflowTasks",
            {
                background: false,
                methodData: {
                    snapshotId: snapshotId,
                    userId: userId,
                },
            }
        );
    }

    GetSnapshotTasksEstimatedCostsAndRevenuesAtSnapshotDate(
        snapshotId: number | null
    ): Promise<IEstimatedCostsAndRevenuesAtSnapshotDate> {
        return this.ajaxService.Post<IEstimatedCostsAndRevenuesAtSnapshotDate>(
            "Todolist-api/WorkflowSnapshots",
            "GetSnapshotTasksEstimatedCostsAndRevenuesAtSnapshotDate",
            {
                background: true,
                methodData: {
                    snapshotId: snapshotId,
                },
            }
        );
    }

    GetElementsToBeOrderedFromActivities(
        tasksIds: number[] | null,
        workflowsIds: number[] | null
    ): Promise<IElementToBeOrdered[]> {
        const result = this.ajaxService.Post<IElementToBeOrdered[]>(
            "Todolist-api/TodoListTask",
            "GetElementsToBeOrderedFromActivities",
            {
                background: true,
                methodData: {
                    tasksIds: tasksIds,
                    workflowsIds: workflowsIds,
                },
            }
        );

        return result;
    }

    GetWorkflowsForSelectList(request: IGetWorkflowsForSelectListRequest): Promise<IWorkflowForSelectList[]> {
        const result = this.ajaxService.Post<IWorkflowForSelectList[]>(
            "Todolist-api/Workflow",
            "GetWorkflowsForSelectList",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetJobOrdersForUserById(request: IGetJobOrdersForUserByIdRequest): Promise<IJobOrderForTaskBoard[]> {
        const result = this.ajaxService.Post<IJobOrderForTaskBoard[]>(
            "Todolist-api/TaskBoard",
            "GetJobOrdersForUserById",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }
    GetLastTasksFromBarCodeRegistry(AppGuid: string | null, userId: number | null): Promise<ITaskForTaskBoard[]> {
        const result = this.ajaxService.Post<ITaskForTaskBoard[]>(
            "Todolist-api/TaskBoard",
            "GetLastTasksFromBarCodeRegistry",
            {
                background: true,
                methodData: {
                    AppGuid: AppGuid,
                    userId: userId,
                },
            }
        );

        return result;
    }

    GetWorkflowRelatedDocumentsAndWorkedHours(
        workflowId: number | null
    ): Promise<IWorkflowRelatedDocumentsAndWorkedHours> {
        const result = this.ajaxService.Post<IWorkflowRelatedDocumentsAndWorkedHours>(
            "Todolist-api/Workflow",
            "GetWorkflowRelatedDocumentsAndWorkedHours",
            {
                background: true,
                methodData: {
                    workflowId: workflowId,
                },
            }
        );

        return result;
    }

    InsertOrUpdateWorkflowCategories(categories: IWorkflowCategory_Type[] | null): Promise<IWorkflowCategory[]> {
        const result = this.ajaxService.Post<IWorkflowCategory[]>(
            "Todolist-api/WorkflowCategories",
            "InsertOrUpdateWorkflowCategories",
            {
                background: true,
                methodData: {
                    categories: categories,
                },
            }
        );

        return result;
    }

    GetWorkflowCategory(id: number | null): Promise<IWorkflowCategory> {
        const result = this.ajaxService.Post<IWorkflowCategory>(
            "Todolist-api/WorkflowCategories",
            "GetWorkflowCategory",
            {
                background: true,
                methodData: {
                    id: id,
                },
            }
        );

        return result;
    }

    GetWorkflowCategories(textFilter: string | null, getDeleted: boolean | null): Promise<IWorkflowCategory[]> {
        const result = this.ajaxService.Post<IWorkflowCategory[]>(
            "Todolist-api/WorkflowCategories",
            "GetWorkflowCategories",
            {
                background: true,
                methodData: {
                    textFilter: textFilter,
                    getDeleted: getDeleted,
                },
            }
        );

        return result;
    }

    GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData(
        snapshotId: number | null,
        skip: number | null,
        count: number | null,
        textFilter: string | null
    ): Promise<IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]> {
        const result = this.ajaxService.Post<IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]>(
            "Todolist-api/WorkflowSnapshots",
            "GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData",
            {
                background: true,
                methodData: {
                    snapshotId: snapshotId,
                    skip: skip,
                    count: count,
                    textFilter: textFilter,
                },
            }
        );

        return result;
    }

    GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksDataByTasksIds(
        snapshotId: number | null,
        tasksIds: number[] | null
    ): Promise<IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]> {
        const result = this.ajaxService.Post<IWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksData[]>(
            "Todolist-api/WorkflowSnapshots",
            "GetWorkflowSnapshotTasksEstimatedBudgetRowsWithTasksDataByTasksIds",
            {
                background: true,
                methodData: {
                    snapshotId: snapshotId,
                    tasksIds: tasksIds,
                },
            }
        );

        return result;
    }
    GetTasksEstimatedRowsByTaskIds(taskIds: number[] | null): Promise<ITaskEstimatedBudgetRowWithNames[]> {
        const result = this.ajaxService.Post<ITaskEstimatedBudgetRowWithNames[]>(
            "Todolist-api/TodoListTask/",
            "GetTasksEstimatedRowsByTaskIds",
            {
                background: true,
                methodData: {
                    taskIds: taskIds,
                },
            }
        );

        return result;
    }

    GetCartWorkflowsForResource(request: IGetCartWorkflowsForResourceRequest): Promise<IWorkflowForTaskBoard[]> {
        const result = this.ajaxService.Post<IWorkflowForTaskBoard[]>(
            "Todolist-api/TaskBoard",
            "GetCartWorkflowsForResource",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetDayDetailsForResource(resourceId: number | null, date: Date | null): Promise<ITaskBoardPlanningCartInDayPlan[]> {
        const result = this.ajaxService.Post<ITaskBoardPlanningCartInDayPlan[]>(
            "Todolist-api/TaskBoard",
            "GetDayDetailsForResource",
            {
                background: true,
                methodData: {
                    resourceId: resourceId,
                    date: date,
                },
            }
        );

        return result;
    }

    GetJobOrdersForUser(request: IGetJobOrdersForUserRequest): Promise<IJobOrderForTaskBoard[]> {
        const result = this.ajaxService.Post<IJobOrderForTaskBoard[]>("Todolist-api/TaskBoard", "GetJobOrdersForUser", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetJobOrderWorkflowsForUser(request: IGetJobOrderWorkflowsForUserRequest): Promise<IWorkflowForTaskBoard[]> {
        const result = this.ajaxService.Post<IWorkflowForTaskBoard[]>(
            "Todolist-api/TaskBoard",
            "GetJobOrderWorkflowsForUser",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetJobOrderWorkflowsForUserByIds(
        request: IGetJobOrderWorkflowsForUserByIdsRequest
    ): Promise<IWorkflowForTaskBoard[]> {
        const result = this.ajaxService.Post<IWorkflowForTaskBoard[]>(
            "Todolist-api/TaskBoard",
            "GetJobOrderWorkflowsForUserByIds",
            {
                background: true,
                methodData: request,
            }
        );

        return result;
    }

    GetReportingTypes() {
        return [
            { Id: 0, Description: ProlifeSdk.TextResources.Todolist.LumpSum },
            { Id: 1, Description: ProlifeSdk.TextResources.Todolist.Reporting },
            { Id: 2, Description: ProlifeSdk.TextResources.Todolist.NonBillable },
        ];
    }

    GetTasksForUser(request: IGetTasksForUserRequest): Promise<ITaskForTaskBoard[]> {
        const result = this.ajaxService.Post<ITaskForTaskBoard[]>("Todolist-api/TaskBoard", "GetTasksForUser", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetTasksForUserById(userId: number | null, ids: number[] | null): Promise<ITaskForTaskBoard[]> {
        const result = this.ajaxService.Post<ITaskForTaskBoard[]>("Todolist-api/TaskBoard", "GetTasksForUserById", {
            background: true,
            methodData: {
                userId: userId,
                ids: ids,
            },
        });

        return result;
    }

    GetTaskForUser(
        userId: number | null,
        taskId: number | null,
        viewAll: boolean | null
    ): Promise<ITaskForTaskBoard[]> {
        const result = this.ajaxService.Post<ITaskForTaskBoard[]>("Todolist-api/TaskBoard", "GetTaskForUser", {
            background: true,
            methodData: {
                userId: userId,
                taskId: taskId,
                viewAll: viewAll,
            },
        });

        return result;
    }

    GetCartTasksForResource(request: IGetCartTasksForResourceRequest): Promise<ITaskForTaskBoard[]> {
        const result = this.ajaxService.Post<ITaskForTaskBoard[]>("Todolist-api/TaskBoard", "GetCartTasksForResource", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetUserMilestones(userId: number | null, viewAll: boolean | null): Promise<IUserMilestone[]> {
        const result = this.ajaxService.Post<IUserMilestone[]>("Todolist-api/TodoListTask", "GetUserMilestones", {
            background: true,
            methodData: {
                userId: userId,
                viewAll: viewAll,
            },
        });

        return result;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new TodoListService(serviceLocator);
}
