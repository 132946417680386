import * as ko from "knockout";
import { LazyImport } from "../Core/DependencyInjection";
import { IDiscountsCatalogsService, IDiscountCatalogRow, IDiscountCatalogRowWithDescriptions, DiscountCatalogRowMode } from "../Warehouse/DiscountsCatalogsService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IDiscountsDataSourceModel extends IDataSourceModel<number, IDiscountCatalogRow> {

}

export interface IDiscountsDataSourceFilter {
    ManufacturerId?: number; 
    DiscountFamilyId?: number; 
    StatisticFamilyId?: number; 
    ArticleFilter?: string; 
    DiscountMode?: number;
}

export class DiscountsDataSource implements IDataSource {
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;
    private discountCatalogId: number;
    private filter : IDiscountsDataSourceFilter = {};
    private view: IDataSourceView;

    getTitle(currentModel: IDiscountsDataSourceModel): string {
        return "Dettagli Scontistiche";
    }    
    
    isGroupedData(currentModel: IDiscountsDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IDiscountsDataSourceModel, b: IDiscountsDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }

    setFilter(filter: IDiscountsDataSourceFilter) {
        this.filter = filter;
    }

    setDiscountCatalogId(discountCatalogId : number) {
        this.discountCatalogId = discountCatalogId;
    }

    async getData(currentModel: IDiscountsDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDiscountsDataSourceModel[]> {
        if(!this.discountCatalogId)
            return [];

        let catalogRows = await this.discountsCatalogsService.GetDiscountCatalogRows({
            discountCatalogId: this.discountCatalogId,
            fkManufacturer: this.filter.ManufacturerId,
            fkDiscountFamily: this.filter.DiscountFamilyId,
            fkStatisticFamily: this.filter.StatisticFamilyId,
            articleFilter: this.filter.ArticleFilter,
            mode: this.filter.DiscountMode,
            skip: skip,
            count: count,
        });

        return catalogRows
            .map(this.createModelFor, this);
    }

    private createModelFor(model: IDiscountCatalogRowWithDescriptions): IDiscountsDataSourceModel {
        //Aggiungo al volo la property ModeDescription che non arriva dal server
        Object.defineProperty(model, "ModeDescription", {
            value: DiscountCatalogRowMode.getDescription(model.Mode)
        });

        return {
            id: model.Id,
            title: "",
            isLeaf: true,
            isGroup: false,
            model: model
        };
    }

    async getById(currentModel: IDiscountsDataSourceModel, ids: (string | number)[]): Promise<IDiscountsDataSourceModel[]> {
        return [];
    }

    setView(view: IDataSourceView): void {
        this.view = view;
    }

    refresh() {
        if(this.view)
            this.view.refresh();
    }

    select(...models : IDiscountsDataSourceModel[]) {
        if(this.view)
            this.view.select(...models);
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}