import * as ko from "knockout";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { ICurrenciesSettingsManager } from "../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { CurrenciesDataSource } from "../../../DataSources/CurrenciesDataSource";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IDocumentCurrenciesFactory, IDocumentCurrencyViewModel, IDocumentCurrency } from "../../interfaces/invoice/IDocumentsService";
import { ICurrencyWithCountriesIds } from "../../../Invoices/interfaces/ICurrenciesService";

export class DocumentCurrenciesFactory implements IDocumentCurrenciesFactory {
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor() {}

    public create(documentId: number, documentType: string, currencyId: number = null): IDocumentCurrencyViewModel {
        return this.createFromModel(this.createModel(documentId, documentType, currencyId));
    }

    public createFromModel(model: IDocumentCurrency): IDocumentCurrencyViewModel {
        return new DocumentCurrency(model);
    }

    public createModel(documentId: number, documentType: string, currencyId: number = null): IDocumentCurrency {
        if (!currencyId) {
            let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            currencyId = defaultCurrency.Id;
        }

        let model: IDocumentCurrency = {
            Id: null,
            DocumentId: documentId,
            DocumentType: documentType,
            DocumentCurrency: false,
            CurrencyId: currencyId,
            ExchangeValue: 1,
            ExchangeValueForVat: 1
        };

        return model;
    }
}

@DetectClassChanges
class DocumentCurrency implements IDataSourceListener, IDocumentCurrencyViewModel {
    @DetectChanges
    public CurrencyId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public ExchangeValue: ko.Observable<number> = ko.observable();
    @DetectChanges
    public ExchangeValueForVat: ko.Observable<number> = ko.observable();

    public IsDocumentCurrency: ko.Observable<boolean> = ko.observable(false);

    public Currency: ko.Observable<ICurrencyWithCountriesIds> = ko.observable();

    public isChanged: ko.Observable<number> = ko.observable(0);

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;
    
    private CurrenciesDataSource: CurrenciesDataSource;
    private documentCurrency: IDocumentCurrency;
    
    constructor(documentCurrency: IDocumentCurrency) {
        this.CurrenciesDataSource = new CurrenciesDataSource();
        this.CurrenciesDataSource.returnDeletedCurrencies(false);

        this.loadFromModel(documentCurrency);
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.Currency(model ? model.model : undefined);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.Currency(undefined);
    }
    
    public loadFromModel(documentCurrency: IDocumentCurrency) {
        this.documentCurrency = documentCurrency;

        this.CurrencyId(this.documentCurrency.CurrencyId);
        this.ExchangeValue(this.documentCurrency.ExchangeValue);
        this.ExchangeValueForVat(this.documentCurrency.ExchangeValueForVat);

        this.isChanged(0);

        let currency = this.currenciesSettingsManager.getCurrencyById(this.documentCurrency.CurrencyId);
        this.Currency(currency);
        this.IsDocumentCurrency(this.documentCurrency.DocumentCurrency);
    }

    public getData(): IDocumentCurrency {
        let data = $.extend(true, {}, this.documentCurrency) as IDocumentCurrency;

        data.CurrencyId = this.CurrencyId();
        data.ExchangeValue = this.ExchangeValue();
        data.ExchangeValueForVat = this.ExchangeValueForVat();
        data.DocumentCurrency = this.IsDocumentCurrency();

        return data;
    }

    public dispose(): void {

    }
}