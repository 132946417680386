import { CurrencyUtils } from "./CurrencyUtils";
import { IDocumentCurrencyViewModel } from "../../interfaces/invoice/IDocumentsService";

export class KnockoutExtensions {}

declare global {
    interface Number {
        Round(numberOfDecimals: number) : number;
        ToCurrency(currency : IDocumentCurrencyViewModel, applyRoundingRules?: boolean) : number;
        ToEuro(currency : IDocumentCurrencyViewModel, applyRoundingRules?: boolean) : number;
    }
}


Object.defineProperty(Number.prototype, "Round", {
    value: function(numberOfDecimals : number) {
        if(numberOfDecimals == null || numberOfDecimals == undefined || this == null || this == undefined)
            return this;

        const result : number = this;
        let multiplier = 1;

        for(let i = 0; i< numberOfDecimals; i++)
        {
            multiplier = multiplier * 10;
        }

        return Math.round(result * multiplier) / multiplier;
    }
});

Object.defineProperty(Number.prototype, "ToCurrency", {
    value: function(currency : IDocumentCurrencyViewModel, applyRoundingRules = true) {
        const val = CurrencyUtils.applyCurrencyReverseExchange(this, currency);
        return applyRoundingRules ?  CurrencyUtils.applyCurrencyRoundingRules(val, currency) : val;
    }
});

Object.defineProperty(Number.prototype, "ToEuro", {
    value: function(currency : IDocumentCurrencyViewModel, applyRoundingRules = true) {
        const val = CurrencyUtils.applyCurrencyExchange(this, currency);
        return applyRoundingRules ? val.Round(2) : val;
    }
});


