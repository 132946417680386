import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderStateSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderLogicalState } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderLogicalState";

export class JobOrderStateDescription {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

		var interceptor = ko.computed(() => {
			var options = valueAccessor();

			var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
			var jobOrderStateSettingsManager : IJobOrderStateSettingsManager = <IJobOrderStateSettingsManager> settingsService.findSettingsManager(ProlifeSdk.JobOrderState);

			var elements = jobOrderStateSettingsManager.getJobOrderLogicalState().filter((elem : IJobOrderLogicalState)=> elem.IdJobOrderLogicalState== options.logicalState);

			return elements[0].Descrizione;
		});

		ko.applyBindingsToNode(element, { text: interceptor }, undefined);
	}
}

ko.bindingHandlers["JobOrderStateDescription"] = new JobOrderStateDescription();
ko.virtualElements.allowedBindings["JobOrderStateDescription"] = true;