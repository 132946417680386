import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderBlockMotivationsSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { IJobOrderBlockMotivation } from "../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";

export class JobOrderBlockMotivationDisplayValue {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var id = valueAccessor();
        var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var motivationsSettings :IJobOrderBlockMotivationsSettingsManager = <IJobOrderBlockMotivationsSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderBlockMotivation);

        var textObservable : ko.Observable<string> = ko.observable("");

        motivationsSettings.getMotivationById(id).then((m : IJobOrderBlockMotivation) => {
            textObservable(m.Description);
        });

        ko.applyBindingsToNode(element, { text: textObservable }, undefined);
    }
}

ko.bindingHandlers["jobOrderBlockMotivationDisplayValue"] = new JobOrderBlockMotivationDisplayValue();
ko.virtualElements.allowedBindings["jobOrderBlockMotivationDisplayValue"] = true;