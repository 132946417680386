import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ICustomersService, ICustomerForList } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { ICustomersViewer } from "../../interfaces/ICustomersViewer";
import { ICustomersServiceObserver } from "../../../ProlifeSdk/interfaces/customer/ICustomersServiceObserver";

function sortBy(field, reverse, primer) {
    const key = primer
        ? (x: any) => {
              primer(x[field]);
          }
        : (x: any) => {
              return x[field];
          };

    return function (a, b) {
        const A = key(a),
            B = key(b);
        return (A < B ? -1 : A > B ? 1 : 0) * [1, -1][+!!reverse];
    };
}

export class CustomerForListAdapter {
    private customerService: ICustomersService;

    constructor(serviceLocator: IServiceLocator) {
        this.customerService = <ICustomersService>serviceLocator.findService(ProlifeSdk.CustomersServiceType);
    }

    public FromCustomer(customer: ICustomer): ICustomerForList {
        return <ICustomerForList>{
            Id: customer.IdCliente,
            Name: this.customerService.GetContactName(customer),
            NameForGroup: customer.IsCompany ? customer.RagioneSociale : customer.Cognome,
            IsCustomer: customer.IsCliente,
            IsSupplier: customer.IsFornitore,
            IsPrivateContact: customer.IsPrivateContact,
            BlockedBy: customer.BlockedBy,
        };
    }
}

class GroupItem {
    public label: ko.Observable<string> = ko.observable();
    public IsCustomer: ko.Observable<boolean> = ko.observable(false);
    public IsSupplier: ko.Observable<boolean> = ko.observable(false);
    public IsCompany: ko.Observable<boolean> = ko.observable(false);
    public IsPrivateContact: ko.Observable<boolean> = ko.observable(false);
    public IsLocked: ko.Observable<boolean> = ko.observable(false);

    public selected: ko.Computed<boolean>;

    constructor(private customer: ICustomerForList, private customersViewer: ICustomersViewer) {
        this.selected = ko.computed(this.selectedChanged.bind(this));
        this.updateCustomer(customer);
    }

    public selectMe() {
        this.customersViewer.setSelectedCustomer(this.customer);
    }

    private selectedChanged() {
        return (
            this.customersViewer.selectedCustomer() &&
            this.customer &&
            this.customersViewer.selectedCustomer().Id == this.customer.Id
        );
    }

    public updateCustomer(customer: ICustomerForList) {
        if (this.customer.Id && this.customer.Id != customer.Id) return;
        this.customer = customer;
        this.label(customer.Name);
        this.IsCustomer(customer.IsCustomer);
        this.IsSupplier(customer.IsSupplier);
        this.IsCompany(customer.IsCompany);
        this.IsPrivateContact(customer.IsPrivateContact);
        this.IsLocked(customer.BlockedBy > 0);
    }

    public getCustomerId(): number {
        return this.customer.Id || 0;
    }
}

class LetterGroup implements ILetterGroup {
    public items: ko.ObservableArray<GroupItem> = ko.observableArray([]);
    public isEmpty: ko.Computed<boolean>;

    constructor(public key: string, public index: number, private customersViewer: ICustomersViewer) {
        this.isEmpty = ko.computed(() => {
            return this.items().length == 0;
        });
    }

    public addItem(customer: ICustomerForList) {
        this.items.push(new GroupItem(customer, this.customersViewer));
    }

    public updateItem(customer: ICustomerForList) {
        this.items().forEach((item) => item.updateCustomer(customer));
    }

    public removeItem(customerId: number) {
        const customers = this.items().filter((item) => item.getCustomerId() == customerId);
        this.items.removeAll(customers);
    }

    public sort() {
        this.items.sort(sortBy("label", false, (c) => c.label || "_NULL"));
    }

    public selectFirstItem(): void {
        const item: GroupItem = this.items()[0];
        if (item) item.selectMe();
    }
}

export interface ILetterGroup {
    items: ko.ObservableArray<GroupItem>;
    isEmpty: ko.Computed<boolean>;
    addItem(customer: ICustomerForList);
    updateItem(customer: ICustomerForList);
    removeItem(customerId: number);
    sort();
    selectFirstItem(): void;
}

export interface GroupMapEntry {
    [key: string]: ILetterGroup;
}

export class LetterGroupingViewModel implements ICustomersServiceObserver {
    private validKeys = "ABCDEFGHIJKLMNOPQRSTUVWXYZ#";
    public groups: ko.ObservableArray<ILetterGroup> = ko.observableArray([]);
    public groupsMap: GroupMapEntry = {};
    public selectedGroup: ko.Observable<any> = ko.observable();
    public selectedGroupIndex: ko.Computed<number>;
    public customersCount: ko.Observable<number> = ko.observable(0);

    private customersService: ICustomersService;

    private customersLoaded: number = 0;
    private customersToLoad: number = 30;
    private loadingCustomers: boolean = false;

    private EndOfListIsReached: boolean = false;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(
        private serviceLocator: IServiceLocator,
        private customersViewer: ICustomersViewer,
        private selectFirstOnLoad: boolean
    ) {
        this.selectedGroupIndex = ko.computed(() => {
            const group = this.selectedGroup() || { index: 0, isEmpty: ko.computed(() => true) };
            return group.index;
        });

        this.customersService = <ICustomersService>serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        this.customersService.addObserver(this);

        this.customersViewer.searchFilter.subscribe(() => {
            this.ClearCustomersAndLoadNext();
        });

        this.ClearCustomersAndLoadNext();
    }

    private buildGroups() {
        this.groups.removeAll();
        this.groupsMap = {};

        for (let i = 0; i < this.validKeys.length; i++) {
            const key = this.validKeys[i];
            this.createGroup(key);
        }
    }

    private ClearCustomersAndLoadNext() {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.customersLoaded = 0;
            this.EndOfListIsReached = false;
            const filter = this.customersViewer.searchFilter();
            this.customersService.GetCustomersCount(filter).then((count: number) => {
                this.customersCount(count);
                this.LoadNextCustomers(true);
            });
        }, 500);
    }

    public LoadNextCustomers(firstLoad: boolean) {
        if (this.loadingCustomers == true || this.EndOfListIsReached) return;

        this.loadingCustomers = true;
        const filter = this.customersViewer.searchFilter();
        this.customersService
            .GetCustomersForList(filter, this.customersLoaded, this.customersToLoad, false, false)
            .then((customers: ICustomerForList[]) => {
                this.EndOfListIsReached = customers.length == 0;
                this.OnCustomersBlockLoaded(customers, firstLoad);
            });
    }

    dispose() {
        this.customersService.removeObserver(this);
    }

    public OnCustomersBlockLoaded(customers: ICustomerForList[], firstLoad: boolean) {
        this.customersLoaded += customers.length;
        this.loadingCustomers = false;
        if (firstLoad) this.buildGroups();

        customers.forEach(this.putCustomerInGroup.bind(this));
        this.groups().forEach((g) => g.sort());

        if (firstLoad && this.selectFirstOnLoad) this.autoSelectFirstCustomerInFirstGroup();
    }

    private autoSelectFirstCustomerInFirstGroup() {
        this.selectedGroup(this.getFirstNonEmptyGroup());
        this.selectFirstCustomer();
    }

    private selectFirstCustomer() {
        const group: ILetterGroup = this.selectedGroup();
        if (!group) return;

        if (!group.isEmpty()) group.selectFirstItem();
        else this.autoSelectFirstCustomerInFirstGroup();
    }

    private getFirstNonEmptyGroup() {
        const nonEmptyGroups = this.groups().filter((group) => {
            return !group.isEmpty();
        });
        return nonEmptyGroups[0] || null; //{ index: 0, items: () => [], isEmpty: ko.computed(() => true) };
    }

    private createGroup(key: string): ILetterGroup {
        const group = new LetterGroup(key, this.groups().length, this.customersViewer);
        this.groups.push(group);
        this.groupsMap[key] = group;
        return group;
    }

    private putCustomerInGroup(customer: ICustomerForList) {
        const key = this.getCustomerKey(customer);
        const group = this.findOrCreateGroupForKey(key);
        group.addItem(customer);
        return group;
    }

    private getCustomerKey(customer: ICustomerForList): string {
        const firstLetterOrNumber =
            customer && customer.NameForGroup.trim().length > 0
                ? customer.NameForGroup.trim()[0].toUpperCase()
                : ProlifeSdk.TextResources.Customers.Others;
        if ($.isNumeric(firstLetterOrNumber)) return "#";
        return firstLetterOrNumber;
    }

    private findOrCreateGroupForKey(key: string): ILetterGroup {
        const group = this.groupsMap[key] || this.createGroup(key);
        return group;
    }

    private findOrCreateGroupForId(id: number): ILetterGroup {
        const groupsMatches = this.groups().filter((g: ILetterGroup) => {
            let customersMatches = g.items().filter((i: GroupItem) => {
                return i.getCustomerId() == id;
            });
            return customersMatches.length > 0;
        });
        return groupsMatches.length > 0 ? groupsMatches[0] : null;
    }

    public show(group: ILetterGroup) {
        this.selectedGroup(group);
    }

    onCustomerChanged(customer: ICustomer): void {
        const customerForList = new CustomerForListAdapter(this.serviceLocator).FromCustomer(customer);
        const newGroup = this.findOrCreateGroupForKey(this.getCustomerKey(customerForList));
        const oldGroup = this.findOrCreateGroupForId(customer.IdCliente);

        if (newGroup != oldGroup) {
            if (oldGroup) oldGroup.removeItem(customer.IdCliente);

            if (newGroup) newGroup.addItem(customerForList);
        } else if (newGroup != null) newGroup.updateItem(customerForList);
    }

    onCustomerAdded(customer: ICustomer): void {
        const customerForList = new CustomerForListAdapter(this.serviceLocator).FromCustomer(customer);
        const group = this.putCustomerInGroup(customerForList);
        this.selectedGroup(group);
        this.customersViewer.setSelectedCustomer(customerForList, true);
    }

    onCustomerDeleted(customerId: number): void {
        const oldSelectedCustomer: ICustomerForList = this.customersViewer.getSelectedCustomer();
        this.groups().forEach((group) => group.removeItem(customerId));

        if (oldSelectedCustomer.Id == customerId) this.selectFirstCustomer();
    }
}
