/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/06/2018
 * Time: 10:49
 * To change this template use File | Settings | File Templates.
 */

 import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { WizardDialog, IDocumentBuilderDocumentRowsWithReferences } from "../../../../ProlifeSdk/prolifesdk/WizardDialog";
import { WizardStep } from "../../../../ProlifeSdk/prolifesdk/WizardStep";
import { ImportMonthlyInvoicingDataWizardDataSourceStep } from "./steps/ImportMonthlyInvoicingDataWizardDataSourceStep";
import { ImportMonthlyInvoicingDataWizardGroupingStep } from "./steps/ImportMonthlyInvoicingDataWizardGroupingStep";
import { ImportMonthlyInvoicingDataWizardInvoicingStep } from "./steps/ImportMonthlyInvoicingDataWizardInvoicingStep";
import { ImportMonthlyInvoicingDataWizardInvoicingSummaryStep } from "./steps/ImportMonthlyInvoicingDataWizardInvoicingSummaryStep";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../DocumentsService";
import { IJobOrderRolePrice, IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IMonthlyInvoicingDataSource, IJobOrderForMonthlyInvoicing, ICustomerForMonthlyInvoicing, IMonthlyInvoicingImportData, IDataSourceForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { ICustomersDataForMonthlyInvoicing, IJobOrdersDataForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IEntityToImportInfoForMonthlyInvoicing } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IEntityForMonthlyInvoicingTree } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IDocumentImportDataWizardStep } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";

export class MonthlyInvoicingDataWizard extends WizardDialog implements IMonthlyInvoicingDataSource {
    public JobOrdersCache: Map<number, any> = new Map<number, IJobOrderForMonthlyInvoicing>();
    public CustomersCache: Map<number, any> = new Map<number, ICustomerForMonthlyInvoicing>();
    public RolesPrices: Map<number, IJobOrderRolePrice[]> = new Map<number, IJobOrderRolePrice[]>();

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    public ImportData: IMonthlyInvoicingImportData = {
        WithPartialInvoicingStep: false,
        AggregatesImportedDocumentsRows: false,
        ShowJobOrderReferenceForWorkedHoursRows: false,
        IncludePdfAsAttachmentOnElectronicInvoicingExport: true,
        InvoicesDate: moment().toDate(),
        Documents: []
    };

    constructor(initializationInfo : IWizardInitializationInfo, private entityTypeCode : string) {
        super("monthly-invoicing-wizard", "invoices/templates/wizard", ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardTitle, entityTypeCode, [], initializationInfo);
                
        this.IsLastStep = ko.computed(() => {
            return this.Steps().indexOf(this.CurrentStep()) == this.Steps().length - 1
                || (this.CurrentStep() && this.CurrentStep().IsLastStep && this.CurrentStep().IsLastStep());
        });

        this.initializeSteps();

        this.CurrentStep(this.Steps()[0]);
    }
    
    public back() : void
    {
        if(!this.CurrentStep().BeforeBack())
            return;

        if(!this.CanGoBack()) return;
        const currentStepIndex = this.Steps.indexOf(this.CurrentStep());

        const currentStep = this.Steps()[currentStepIndex];
        const nextStep : IDocumentImportDataWizardStep = this.Steps()[currentStepIndex - 1];
        
        if (nextStep instanceof ImportMonthlyInvoicingDataWizardDataSourceStep && currentStep instanceof ImportMonthlyInvoicingDataWizardDataSourceStep) {
            nextStep.InitializeFilters(currentStep.GetFilters());
        }

        this.CurrentStep(nextStep);
    }

    public next() : void
    {
        if(!this.CurrentStep().BeforeNext())
            return;

        if(this.IsLastStep())
            return;

        const currentStepIndex = this.Steps.indexOf(this.CurrentStep());
        this.CurrentStep().GetDocumentsToImportInfo().then((documents) => {
            this.documentsToImportInfo[currentStepIndex] = documents;
            const currentStep = this.Steps()[currentStepIndex];
            const nextStep : IDocumentImportDataWizardStep = this.Steps()[currentStepIndex + 1];

            if (nextStep instanceof ImportMonthlyInvoicingDataWizardDataSourceStep && currentStep instanceof ImportMonthlyInvoicingDataWizardDataSourceStep) {
                nextStep.SetLoadRowsAfterInitialize(false);
                nextStep.BeforeShow();
                nextStep.SetLoadRowsAfterInitialize(true);
                nextStep.InitializeFilters(currentStep.GetFilters());
            } else {
                nextStep.BeforeShow();
            }

            this.CurrentStep(nextStep);
        });
    }

    public close(): void {
        this.resetCachedData();
        super.close();
    }

    public async action(): Promise<void> {
        if (!this.CurrentStep().BeforeNext())
            return;

        this.resetCachedData();
        this.ImportData.Documents = await this.getDocumentsToGenerate();
        
        this.modal.close(this.ImportData);
    }

    public GetCustomSteps() : WizardStep[]
    {
        const steps: WizardStep[] = [];

        steps.push(new ImportMonthlyInvoicingDataWizardGroupingStep(this.entityTypeCode, this, this.initializationInfo));
        steps.push(new ImportMonthlyInvoicingDataWizardInvoicingStep(this.entityTypeCode, this, this.initializationInfo));

        if (this.authorizationsService.isAuthorized("Documents_InvoiceImport"))
            steps.push(new ImportMonthlyInvoicingDataWizardInvoicingSummaryStep(this, this.initializationInfo));

        return steps;
    }

    public OnDataSourcesSelected()
    {
        const steps = [];

        this.dataSources().forEach((s: IDataSourceForMonthlyInvoicing) => {
            steps.push(new ImportMonthlyInvoicingDataWizardDataSourceStep(s, this.initializationInfo, [], this));
        });

        if (this.dataSources().length > 0)
            this.GetCustomSteps().forEach((s : WizardStep) => {
                steps.push(s);
            });

        steps[0].BeforeShow();

        this.Steps(steps);
        this.documentsToImportInfo = [];
    }

    public GetEntitiesToImport(step: IDocumentImportDataWizardStep): IEntityToImportInfoForMonthlyInvoicing[][] {
        if (this.documentsToImportInfo.length == 0)
            return [];

        const stepIndex: number = this.Steps().indexOf(step);

        if (stepIndex < 0)
            return [];

        const info: IEntityToImportInfoForMonthlyInvoicing[][] = [];
        for (let i: number = 0; i < stepIndex; i++)
            info.push(this.documentsToImportInfo[i] as IEntityToImportInfoForMonthlyInvoicing[]);

        return info;
    }

    public Show(): Promise<IMonthlyInvoicingImportData> {
        return this.dialogsService.ShowModal<IMonthlyInvoicingImportData>(this, "fullscreen", null, this.dialogTemplateUrl, this.dialogTemplateName);
    }

    public async LoadRolesConfiguredOnJobOrders(jobOrders: number[]): Promise<void> {
        const distinctJobOrders: number[] = [];

        jobOrders.forEach((j) => {
            if (distinctJobOrders.indexOf(j) < 0)
                distinctJobOrders.push(j);
        });

        this.RolesPrices.clear();

        const roles = await this.jobOrdersService.GetRolesConfiguredOnJobOrders(distinctJobOrders);
        roles.forEach((r) => {
            let jRoles = this.RolesPrices.get(r.JobOrderId);

            if (!jRoles) {
                jRoles = [];
                this.RolesPrices.set(r.JobOrderId, jRoles);
            }

            jRoles.push(r);
        });
    }

    public async LoadJobOrdersIntoCache(jobOrdersToLoad: number[]): Promise<void> {
        const distinctJobOrdersToLoad: number[] = [];

        for (const jobOrderId of jobOrdersToLoad) {
            if (distinctJobOrdersToLoad.indexOf(jobOrderId) < 0 && !this.JobOrdersCache.has(jobOrderId))
                distinctJobOrdersToLoad.push(jobOrderId);
        }

        if (distinctJobOrdersToLoad.length == 0)
            return;

        const jobOrdersData = await this.documentsService.GetJobOrdersForMonthlyInvoicingWizard(distinctJobOrdersToLoad);

        this.createJobOrdersForCache(jobOrdersData);
    }

    public async LoadCustomersIntoCache(customersToLoad: number[]): Promise<void> {
        const distinctCustomersToLoad: number[] = [];

        for (const customerId of customersToLoad) {
            if (distinctCustomersToLoad.indexOf(customerId) < 0 && !this.CustomersCache.has(customerId))
                distinctCustomersToLoad.push(customerId);
        }

        if (distinctCustomersToLoad.length == 0)
            return;

        const customersData = await this.documentsService.GetCustomersForMonthlyInvoicingWizard(distinctCustomersToLoad);

        this.createCustomersForCache(customersData);
    }
    
    public getRowsToImport(): Promise<IDocumentBuilderDocumentRowsWithReferences[]> {
        return Promise.resolve([]);
    }

    private createCustomersForCache(customersData: ICustomersDataForMonthlyInvoicing) {
        for (const customer of customersData.Customers) {
            const customerForCache: ICustomerForMonthlyInvoicing = {
                IdCliente : customer.IdCliente,
                Name: customer.Name,
                Country: customer.Country,
                DefaultIvaMode: customer.DefaultIvaMode,
                DefaultCurrency: customer.DefaultCurrency,
                DefaultPaymentExpiryTypeId: customer.DefaultPaymentExpiryTypeId,
                DefaultPaymentTypeId: customer.DefaultPaymentTypeId,
                DefaultPaymentAccountId: customer.DefaultPaymentAccountId,
                DefaultPaymentABI: customer.DefaultPaymentABI,
                DefaultPaymentCAB: customer.DefaultPaymentCAB,
                DefaultOffset: customer.DefaultOffset,
                DefaultOutcome: customer.DefaultOutcome,

                OrganizationalUnits: customersData.OrganizationalUnits.map((ou) => {
                    return {
                        Id : ou.Id,
                        IsDefault : ou.IsDefault,
                        Description: ou.Description,
                        CustomerId : ou.CustomerId,

                        ProtocolsSettings: customersData.EnabledProtocolsDefaultValues.filter((p) => p.OrganizationalUnitId === ou.Id),
                        ProtocolsDefaultValues: customersData.ProtocolsDefaultValues.filter((p) => p.OrganizationalUnitId === ou.Id),

                        Banche: customersData.Banks.filter((b) => b.OrganizationalUnitId === ou.Id)
                    }
                })
            };

            this.CustomersCache.set(customer.IdCliente, customerForCache);
        }
    }
    
    private createJobOrdersForCache(jobOrdersData: IJobOrdersDataForMonthlyInvoicing) {
        for (const jobOrder of jobOrdersData.JobOrders) {
            const jobOrderForCache: IJobOrderForMonthlyInvoicing = {
                JobOrderId : jobOrder.JobOrderId,
                Name: jobOrder.Name,
                DefaultIvaMode: jobOrder.DefaultIvaMode,
                DefaultCurrency: jobOrder.DefaultCurrency,
                DefaultPaymentExpiryTypeId: jobOrder.DefaultPaymentExpiryTypeId,
                DefaultPaymentTypeId: jobOrder.DefaultPaymentTypeId,
                DefaultPaymentAccountId: jobOrder.DefaultPaymentAccountId,
                DefaultPaymentABI: jobOrder.DefaultPaymentABI,
                DefaultPaymentCAB: jobOrder.DefaultPaymentCAB,
                DefaultOffset: jobOrder.DefaultOffset,
                DefaultOutcome: jobOrder.DefaultOutcome,

                RolesPrices: jobOrdersData.RolesPrices.filter((r) => r.JobOrderId === jobOrder.JobOrderId),
                CallRightTypesPrices: jobOrdersData.CallRightsPrices.filter((c) => c.JobOrderId === jobOrder.JobOrderId),
                ProtocolsSettings: jobOrdersData.EnabledProtocolsDefaultValues.filter((c) => c.JobOrderId === jobOrder.JobOrderId),
                ProtocolsDefaultValues: jobOrdersData.ProtocolsDefaultValues.filter((c) => c.JobOrderId === jobOrder.JobOrderId),
            };

            this.JobOrdersCache.set(jobOrder.JobOrderId, jobOrderForCache);
        }
    }

    private initializeSteps(): void {
        this.Steps([]);

        const dataSources: any[] = this.documentsService.getDataSourcesForMonthlyInvoicing(this.entityTypeCode);

        this.dataSources(dataSources);
    }

    private resetCachedData(): void {
        this.JobOrdersCache.clear();
        this.CustomersCache.clear();
        this.RolesPrices.clear();
    }

    private getDocumentsToGenerate(): Promise<IEntityForMonthlyInvoicingTree[]> {
        return this.CurrentStep().getDocumentsToGenerate();
    }
}