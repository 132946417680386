import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { Tag } from "../../../../../ProlifeSdk/prolifesdk/blog/Tag";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IBlogService } from "../../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IGenericEventConfigurationsUi, IGenericEventConfigurations } from "../../../../../ProlifeSdk/interfaces/blog/IGenericEventConfigurationsUi";
import { IVatRegister, ITagForProtocol } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IEventTag, ITag } from "../../../../../ProlifeSdk/interfaces/ITag";
import { Deferred } from "../../../../../Core/Deferred";

export class EventSettingsForProtocolDialog implements IDialog
{
    @LazyImport(nameof<IBlogService>())
    private blogService : IBlogService;

    modal: { close: (result?: any) => void; };
    public templateName : string = "protocol-configurations-popup";
    public templateUrl : string = "invoices/templates/settings";

    private ui : IGenericEventConfigurationsUi;
    private lastAppliedConfiguration : IGenericEventConfigurations;
    private someIsChanged : boolean = false;

    constructor(private protocol : IVatRegister, private eventTypeForProtocol : number, public title : string)
    {
        this.ui = this.blogService.GetGenericEventConfigurationsUI();

        var tags : ITagForProtocol[] = protocol.DefaultBlogTags.filter((s : ITagForProtocol) => { return s.EventType == this.eventTypeForProtocol; });

        this.ConvertTagsToConfigurations(tags || []).then((configs : IGenericEventConfigurations) => {
            this.lastAppliedConfiguration = configs;
            this.ui.Initialize(configs);
        });
    }

    public SomeIsChanged()
    {
        return this.someIsChanged;
    }

    public SetTagsOnList(list : ITagForProtocol[])
    {
        var tagsToRemove : ITagForProtocol[] = list.filter((s : ITagForProtocol) => { return s.EventType == this.eventTypeForProtocol; });
        tagsToRemove.forEach((t : ITagForProtocol) => { list.splice(list.indexOf(t), 1) });
        this.GetData().forEach((t : ITagForProtocol) => { list.push(t); });
    }

    public GetData() : ITagForProtocol[]
    {
        var data : ITagForProtocol[] = [];
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_Priority, ProlifeSdk.TagType_String, this.lastAppliedConfiguration.Priority));
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_State, ProlifeSdk.TagType_String, this.lastAppliedConfiguration.Status));
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_TracingQuality, ProlifeSdk.TagType_Bool, this.lastAppliedConfiguration.InQuality ? ProlifeSdk.Tag_TracingQuality_true : ProlifeSdk.Tag_TracingQuality_false));
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_IsAdministration, ProlifeSdk.TagType_Bool, this.lastAppliedConfiguration.IsAdministration ? ProlifeSdk.Tag_TracingQuality_true : ProlifeSdk.Tag_TracingQuality_false));
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_IsCommercial, ProlifeSdk.TagType_Bool, this.lastAppliedConfiguration.IsCommercial ? ProlifeSdk.Tag_TracingQuality_true : ProlifeSdk.Tag_TracingQuality_false));
        data.push(this.GetTagForProtocol(ProlifeSdk.Tag_IsTechnical, ProlifeSdk.TagType_Bool, this.lastAppliedConfiguration.IsTechnical ? ProlifeSdk.Tag_TracingQuality_true : ProlifeSdk.Tag_TracingQuality_false));

        this.lastAppliedConfiguration.CustomMetaTags.forEach((t : IEventTag) => {
            data.push(this.GetTagForProtocol(ProlifeSdk.Tag_CustomTag, t.TagTypeId(), t.Value()));
        });

        this.lastAppliedConfiguration.AllowedUsersTags.forEach((t : IEventTag) => {
            data.push(this.GetTagForProtocol(ProlifeSdk.Tag_AllowedUser, t.TagTypeId(), t.Value()));
        });

        this.lastAppliedConfiguration.Tasks.forEach((id : number) => {
            data.push(this.GetTagForProtocol(ProlifeSdk.Tag_TodoListTask, ProlifeSdk.TagType_Int, id.toString()));
        });
        return data;
    }

    private GetTagForProtocol(tagName : string, tagType : string, value : string)
    {
        return <ITagForProtocol>{
            ProtocolId : this.protocol.IdRegistroIVA,
            Name: tagName,
            ValueTypeId: tagType,
            Value: value,
            ValueNumber: tagType == ProlifeSdk.TagType_Int ? parseInt(value) : null,
            ValueDate: null,
            DisplayName: null,
            EventType : this.eventTypeForProtocol
        }
    }

    private ConvertTagsToConfigurations(tags : ITagForProtocol[]) : Promise<IGenericEventConfigurations>
    {
        var def = new Deferred<IGenericEventConfigurations>();

        var configs : IGenericEventConfigurations = {
            Priority: ProlifeSdk.Tag_Priority_Normal,
            Status: ProlifeSdk.Tag_State_Done,
            InQuality: true,
            IsAdministration: true,
            IsCommercial: true,
            IsTechnical: false,
            CustomMetaTags: [],
            AllowedUsersTags: [],
            Tasks : []
        };

        var tagsDefs : Promise<string>[] = [];

        tags.forEach((t : ITagForProtocol) => {
            configs.Priority = t.Name == ProlifeSdk.Tag_Priority ? t.Value : configs.Priority;
            configs.Status = t.Name == ProlifeSdk.Tag_State ? t.Value : configs.Status;
            configs.InQuality = t.Name == ProlifeSdk.Tag_TracingQuality ? t.Value == ProlifeSdk.Tag_TracingQuality_true : configs.InQuality;
            configs.IsAdministration = t.Name == ProlifeSdk.Tag_IsAdministration ? t.Value == ProlifeSdk.Tag_TracingQuality_true : configs.IsAdministration;
            configs.IsCommercial = t.Name == ProlifeSdk.Tag_IsCommercial ? t.Value == ProlifeSdk.Tag_TracingQuality_true : configs.IsCommercial;
            configs.IsTechnical = t.Name == ProlifeSdk.Tag_IsTechnical ? t.Value == ProlifeSdk.Tag_TracingQuality_true : configs.IsTechnical;

            if(t.Name == ProlifeSdk.Tag_CustomTag)
                configs.CustomMetaTags.push(this.ConvertProtocolTagToTag(t, tagsDefs));

                if(t.Name == ProlifeSdk.Tag_AllowedUser)
                    configs.AllowedUsersTags.push(this.ConvertProtocolTagToTag(t, tagsDefs));

                if(t.Name == ProlifeSdk.Tag_TodoListTask)
                    configs.Tasks.push(parseInt(t.Value));
        });

        Promise.all(tagsDefs).then(() => {
            def.resolve(configs);
        }).catch(() => {
            def.reject([]);
        });

        return def.promise();
    }

    private ConvertProtocolTagToTag(t : ITagForProtocol, deferredArray : Promise<string>[]) : IEventTag
    {
        var tag : IEventTag = new Tag();
        var def = tag.LoadItemFromObject(<ITag>{
            TagName: t.Name,
            TagTypeId: t.ValueTypeId,
            Value: t.Value,
            DisplayName : t.DisplayName
        });
        if(deferredArray)
            deferredArray.push(def);
        return tag;
    }

    action(): void
    {
        this.someIsChanged = true;
        this.lastAppliedConfiguration = this.ui.GetConfigurations();
        this.modal.close();
    }

    close(): void
    {
        this.ui.Initialize(this.lastAppliedConfiguration);
        this.modal.close();
    }
}