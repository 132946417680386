import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 17.43
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SettingsGroupViewModel } from "./SettingsGroupViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISettingsViewModel } from "../../interfaces/ISettingsViewModel";
import { ISettingsGroup } from "../../../ProlifeSdk/interfaces/settings/ISettingsGroup";

export class SettingsMenu {
    public links : any[] = [];

    constructor(serviceLocator : IServiceLocator, private settings : ISettingsViewModel) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var settingsManagersGroups = settingsService.getSettingsManagersGroups();
        this.links = settingsManagersGroups.map(g => this.createViewModelFor(g)).orderBy(v => v.title);
    }

    dispose() {
        this.links = [];
    }

    private createViewModelFor(group : ISettingsGroup) : SettingsGroupViewModel {
        return new SettingsGroupViewModel(group, this.settings);
    }
}