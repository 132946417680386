import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ReferencesMapNode } from "./ReferencesMapNode";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";

declare var arbor: any;

export class ReferencesMapChartRenderer
{
    private sdkService : IProLifeSdkService;

    private canvas : any;
    private canvasCtx : any;
    private particleSystem : any;

    constructor(private flowChartContainer : any, private colorsOptions : any, private serviceLocator : IServiceLocator)
    {
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        $(window).resize(this.OnScreenResize.bind(this));
        this.canvas = $(flowChartContainer).children("canvas")[0];
        this.canvasCtx = this.canvas.getContext("2d");
        $(this.canvas).mousedown(this.OnClick.bind(this));
    }

    public init(system : any)
    {
        this.particleSystem = system;
        this.particleSystem.screenPadding(100);
        this.OnScreenResize();
    }

    public redraw()
    {
        this.canvasCtx.fillStyle = this.colorsOptions.background;
        this.canvasCtx.fillRect(0,0, this.canvas.width, this.canvas.height); //COLORO L'AREA INTERA
        this.particleSystem.eachEdge(this.RedrawArc.bind(this));
        this.particleSystem.eachNode(this.RedrawNode.bind(this));
    }

    private OnClick(e)
    {
        var pos = $(this.canvas).offset();
        var mousePosition = arbor.Point(e.pageX-pos.left, e.pageY-pos.top);
        var clickedNode = this.particleSystem.nearest(mousePosition);

        if (clickedNode && clickedNode.node !== null && !clickedNode.node.data.IsAnalyzedNode)
        {
            this.particleSystem.eachNode((n) => {
                n.data.IsSelected = n.data.IsAnalyzedNode || n.data.Identifier == clickedNode.node.data.Identifier || (n.data.IsSource != clickedNode.node.data.IsSource && n.data.IsSelected);
                if(n.data.IsSelected)
                    n.data.OnNodeClicked();
            });
        }

        this.redraw();
    }

    private OnScreenResize()
    {
        this.canvas.width = $(this.flowChartContainer).width();
        this.canvas.height = $(this.flowChartContainer).height();
        this.particleSystem.screenSize(this.canvas.width,this.canvas.height);
        this.redraw();
    }

    private RedrawArc(edge, pt1, pt2)
    {
        this.canvasCtx.lineWidth = 1;
        this.canvasCtx.strokeStyle = this.colorsOptions.arcs.default;
        this.canvasCtx.beginPath();
        this.canvasCtx.moveTo(pt1.x, pt1.y);
        this.canvasCtx.lineTo(pt2.x, pt2.y);
        this.canvasCtx.stroke();
        this.RedrawArrow(pt1, pt2);
    }

    private RedrawArrow(pt1, pt2)
    {
        var arcCenterX = pt1.x + (pt2.x-pt1.x)/2;
        var arcCenterY = pt1.y + (pt2.y-pt1.y)/2;
        var headlen = 10;
        var angle = Math.atan2(pt2.y-pt1.y,pt2.x-pt1.x);
        this.canvasCtx.fillStyle = this.colorsOptions.arcs.default;
        this.canvasCtx.beginPath();
        this.canvasCtx.moveTo(arcCenterX, arcCenterY);
        this.canvasCtx.lineTo(arcCenterX-headlen*Math.cos(angle-Math.PI/8),arcCenterY-headlen*Math.sin(angle-Math.PI/8));
        this.canvasCtx.lineTo(arcCenterX-headlen*Math.cos(angle+Math.PI/8),arcCenterY-headlen*Math.sin(angle+Math.PI/8));
        this.canvasCtx.fill();
    }

    private RedrawNode(node : any, pt : any)
    {
        var data : ReferencesMapNode = node.data;

        var nodeWidth = 140;
        var nodeHeight = 40;
        this.canvasCtx.beginPath();

        this.canvasCtx.fillStyle = this.GetNodeColor(data);
        this.canvasCtx.strokeStyle = this.GetNodeBorderColor(data);
        this.canvasCtx.lineWidth = data.IsSelected ? 7 : 3;

        if(data.IsSimpleEntitiesSource)
            this.sdkService.Utilities.HtmlCanvas.DrawRoundRect(this.canvasCtx, pt.x-nodeWidth/2, pt.y-nodeHeight/2, nodeWidth, nodeHeight, 15, true, true);
        else
        {
            this.canvasCtx.rect(pt.x-nodeWidth/2, pt.y-nodeHeight/2, nodeWidth, nodeHeight);
            this.canvasCtx.fill();
            this.canvasCtx.stroke();
        }

        /*this.canvasCtx.rect(pt.x-nodeWidth/2, pt.y-nodeHeight/2, nodeWidth, nodeHeight);
        this.canvasCtx.fill();
        this.canvasCtx.stroke();*/

        this.canvasCtx.fillStyle = "black";
        this.canvasCtx.font = 'italic 9px sans-serif';
        this.canvasCtx.fillText (data.IsSimpleEntitiesSource ? data.EntityDescriptor.PluralEntityName : data.EntityDescriptor.EntityName, pt.x-nodeWidth/2+5, pt.y-nodeHeight/2+12);
        this.canvasCtx.fillText (data.NodeDetails ? data.NodeDetails.Description : "", pt.x-nodeWidth/2+5, pt.y-nodeHeight/2+25);
    }

    private GetNodeBorderColor(node : ReferencesMapNode)
    {
        var color : string = "grey";
        color = node.IsAnalyzedNode && !node.IsSelected ? this.colorsOptions.nodes.borders.analyzed.default : color;
        color = node.IsAnalyzedNode && node.IsSelected ? this.colorsOptions.nodes.borders.analyzed.selected : color;
        color = node.IsSource && !node.IsSelected ? this.colorsOptions.nodes.borders.source.default : color;
        color = node.IsSource && node.IsSelected ? this.colorsOptions.nodes.borders.source.selected : color;
        color = node.IsDestination && !node.IsSelected ? this.colorsOptions.nodes.borders.destination.default : color;
        color = node.IsDestination && node.IsSelected ? this.colorsOptions.nodes.borders.destination.selected : color;
        return color;
    }

    private GetNodeColor(node : ReferencesMapNode)
    {
        var color : string = "grey";
        color = node.IsAnalyzedNode ? this.colorsOptions.nodes.analyzed : color;
        color = node.IsSource ? this.colorsOptions.nodes.source : color;
        color = node.IsDestination ? this.colorsOptions.nodes.destination : color;
        color = node.IsSimpleEntitiesSource ? this.colorsOptions.nodes.simpleEntities : color;
        return color;
    }
}
