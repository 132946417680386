import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { Layout } from "../../Components/Layouts";
import { Portlet } from "../../Components/Portlet";
import { BackButton } from "../../Components/BackButton";
import jss from "jss";
import { TextInput } from "../../Components/TextInput";
import { LazyImport } from "../../Core/DependencyInjection";
import { IFunctionPointComputationService } from "../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { IJobOrderForTaskBoard } from "../../ProlifeSdk/interfaces/todolist/ITodoListService";

const styleSheet = jss.createStyleSheet({
    containerEditor: {
        "& > div": {
            padding: "15px",
        }
    },
    newStatementLayout: {
        paddingRight: "15px",
        marginTop: "30px",
    }
});
const { classes } = styleSheet.attach();


type GeneralContainerEditorProps = {
    onBack: () => void,
    onSave: (infrastructureId : number) => void;
    infrastructureId: number;
}

export function GeneralContainerEditor(props: GeneralContainerEditorProps) {
    const C = require("./GeneralContainerEditor")._GeneralContainerEditor as typeof _GeneralContainerEditor;
    return <C {...props} />;
}

export class _GeneralContainerEditor {
    static defaultProps: Partial<GeneralContainerEditorProps> = {
    }

    @LazyImport(nameof<IFunctionPointComputationService>())
    private functionPointComputationService : IFunctionPointComputationService;

    Name : ko.Observable<string> = ko.observable();
    Description : ko.Observable<string> = ko.observable();

    constructor(private props : GeneralContainerEditorProps) {
        
    }

    componentDidMount() {
        this.loadInfrastructure();
    }

    private async loadInfrastructure() {
        const envelope = await this.functionPointComputationService.getInfrastructureById(this.props.infrastructureId);
        if(envelope != null) {
            this.Name(envelope.Title);
            this.Description(envelope.Description);
        } 
        
    }

    private async onSaveInfrastructure() {
        const savedInfrastructure = await this.functionPointComputationService.saveInfrastructure({
            Id: this.props.infrastructureId,
            Title: this.Name(),
            Description: this.Description()
        });
        this.props.onSave(savedInfrastructure.Id);
    }

    renderEditor() {
        let model: IJobOrderForTaskBoard;
        return  <Portlet collapsible={false}>
                    <Portlet.Header>
                        <Portlet.Header.Default title="Infrastruttura"/>
                    </Portlet.Header>
                    <Portlet.Actions>
                        <button className={"btn btn-success btn-circle btn-sm"} onClick={this.onSaveInfrastructure.bind(this)}>
                            <i className={"fa fa-floppy-o"} /> &nbsp;Salva
                        </button>
                    </Portlet.Actions>
                    <Portlet.Body>
                        {()=>         
                        <Layout.Grid className={classes.containerEditor} columns={["1fr","1fr"]} rows={["auto ","1fr"]}>
                            <Layout.Grid.Cell column={1} row={1}>
                                <TextInput className="flex-1" label="Nome" placeholder="Inserisci nome infrastruttura" value={this.Name} />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={1} row={2}>
                                <TextInput label="Descrizione breve" value={this.Description} className={"flex-1"}/> 
                            </Layout.Grid.Cell>
                            {/* <Layout.Grid.Cell column={2} row={2}>
                                <Table  dataSource={{array:this.jobOrders, factory: (item) => this.createModel(item)}} scrollable>
                                    <Column title="Progetti selezionati">
                                        <span data-bind={{text: model.Name}}/>                            
                                    </Column>
                                    <Column style={{width:"50px"}}>
                                        <ColumnBody>{(item: ITableItem<IJobOrderForTaskBoard>) => 
                                                <button className="btn btn-danger btn-circle btn-xs btn-icon-only" onClick={() => this.DeleteJobOrder(item.Data.model)}>
                                                    <i className="fa fa-trash-o"/>                                
                                                </button>}                                
                                        </ColumnBody>                            
                                    </Column>
                                </Table>
                            </Layout.Grid.Cell> */}
                        </Layout.Grid>}
                    </Portlet.Body>
                </Portlet>
    }
    
    render() {
        return  <Layout.Grid columns={["1fr"]} rows={["auto","1fr"]}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <BackButton style={{marginRight: "20px"}} onClick={()=> this.props.onBack()}/>
                                <Portlet.Header.Default title={()=> this.props.infrastructureId <= 0 ? "Aggiungi infrastruttura" : "Modifica infrastruttura"} />                                           
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2} >
                        {this.renderEditor()}
                    </Layout.Grid.Cell>
                </Layout.Grid> 
    
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(GeneralContainerEditor);
}