import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { WorkflowCategoriesSettingsViewModel } from "./ui/WorkflowCategoriesSettingsViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ITodoListService, IWorkflowCategory } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WorkflowCategoriesSettingsManager implements IWorkflowCategoriesSettingsManager {
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.TasksSettingsGroup);
    }

    load(): Promise<void> {
        return Promise.resolve<void>(undefined);
    }

    getCategory(id: number): Promise<IWorkflowCategory> {
        return this.todoListService.GetWorkflowCategory(id);
    }

    GetCategories(): Promise<IWorkflowCategory[]> {
        return this.todoListService.GetWorkflowCategories("", false);
    }

    getName(): string {
        return ProlifeSdk.WorkflowCategoriesType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Todolist.WorkflowCategories;
    }

    hasEditingUI(): boolean {
        return (
            this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) ||
            this.modulesService.IsModuleEnabled(ProlifeSdk.PersonalTodolistApplicationCode)
        );
    }

    getEditingUI(): IView {
        return {
            templateName: "workflow-categories-settings",
            templateUrl: "todolist/templates/settings",
            viewModel: new WorkflowCategoriesSettingsViewModel(this),
        };
    }

    async CreateOrUpdate(category: IWorkflowCategory): Promise<IWorkflowCategory> {
        return (await this.todoListService.InsertOrUpdateWorkflowCategories([category])).firstOrDefault();
    }

    Delete(categoryId: number): Promise<void> {
        return this.todoListService.DeleteWorkflowCategory(categoryId);
    }
}
