/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/02/13
 * Time: 12.51
 * To change this template use File | Settings | File Templates.
 */

import { PhoneNumberUtil } from "google-libphonenumber";

export namespace TelephoneNumber {

    export function unformatGSM(value) {
        return value;
    }

    export function formatGSM(value) {
        const instance = PhoneNumberUtil.getInstance();

        try {
            var parsedNumber = instance.parse(value || "", "IT");
            return instance.format(parsedNumber, "International") || value;
        }
        catch(e)
        {
            return value;
        }
    }

    export function CheckGSMNumber(GSMnumber,DefaultCountry) : string {

        var sMSISDN = "";
        var c;

        //if is date GSMNumber is not valid
        if (!GSMnumber || GSMnumber.length == 0) {return "";}

        for (c = 0; c < GSMnumber.length; c += 1){
            var ch = GSMnumber.charCodeAt(c);
            if (((ch >= 48) && (ch <= 57)) || (ch == 32) || (ch == 45) || (ch == 47) || ((ch == 43) && (c == 0))) {
                sMSISDN += GSMnumber.charAt(c);
            }
        }

        //cleaning GSMNumber string
        if (sMSISDN.substring(0,2) == "00") {
            sMSISDN = "+" + sMSISDN.substring(2);
        }

        var nospace = sMSISDN.replace(/ /g, '').replace(/[\t\f\v]/g, '');
        nospace = sMSISDN.replace('-', '').replace('\\','');
        if ((nospace.length < 7) || (nospace.length >= 17))
            return "";

        if (sMSISDN.substring(0,DefaultCountry.length) == DefaultCountry) {
            //'+valid MSISDN
            return GSMformat(DefaultCountry, sMSISDN.substring(DefaultCountry.length,sMSISDN.length));
        }

        if (sMSISDN.substring(0,1) == "+") {
            //'+ looking for internationalprefix
            for(c = 0; c < g_sPrefixCountries.length; c += 1){
                if (sMSISDN.substring(0,g_sPrefixCountries[c][0].length) == g_sPrefixCountries[c][0]) {
                    return GSMformat(g_sPrefixCountries[c][0],sMSISDN.substring(g_sPrefixCountries[c][0].length,sMSISDN.length));
                }
            }
            return "";
        } else {
            if ((DefaultCountry == "+39") && (sMSISDN.substring(0,2) == "39")) {
                //H3G
                if ((sMSISDN.length == 9) || (sMSISDN.length == 10)) {
                    return GSMformat(DefaultCountry ,sMSISDN);
                }

                //other operators
                if ((sMSISDN.length == 11) || (sMSISDN.length == 12) || (sMSISDN.length == 13)) {
                    return GSMformat(DefaultCountry ,sMSISDN.substring(DefaultCountry.length -1));
                }
                return "";
            } else {
                return GSMformat(DefaultCountry,sMSISDN);
            }
        }
    }

    export function GSMformat(DefaultCountry, sMSISDN) : string {
        if (DefaultCountry == "+39" && sMSISDN.substring(0,1) == "3")
        {
            return DefaultCountry + " " +  ConcatTelephonePart(sMSISDN.substring(0,3),sMSISDN.substring(3,sMSISDN.length));
        }


        if (DefaultCountry == "+39" && sMSISDN.substring(0,1) == "0")
            for (var c = 0; c < g_sPrefixItaly.length; c += 1){
                if (sMSISDN.substring(0,g_sPrefixItaly[c].length) == g_sPrefixItaly[c]) {
                    return DefaultCountry + " " +  ConcatTelephonePart(g_sPrefixItaly[c],sMSISDN.substring(g_sPrefixItaly[c].length,sMSISDN.length));
                }
            }

        return ConcatTelephonePart(DefaultCountry,sMSISDN);
    }

    export function ConcatTelephonePart(part1,part2) : string {
        if (part2.substring(0,1) == " " || part2.substring(0,1) == "-" ||part2.substring(0,1) == "/")
            return part1 + part2;

        return part1 + " " + part2;
    }

    //Tabelle
    var g_sPrefixItaly = new Array(
        "004191","010","011","0121","0122","0123","0124","0125",
        "0131","0141","0142","0143","0144","015","0161","0163",
        "0165","0166","0171","0172","0173","0174","0175","0182",
        "0183","0184","0185","0187","019","02","030","031","0321",
        "0322","0323","0324","0331","0332","0341","0342",
        "0343","0344","0345","0346","035","0362","0363","0364",
        "0365","0371","0372","0373","0374","0375","0376","0377",
        "0381","0382","0383","0384","0385","0386","039","040",
        "041","0421","0422","0423","0424","0425","0426","0427",
        "0428","0429","0431","0432","0433","0434","0435","0436",
        "0437","0438","0439","0442","0444","0445","045","0461",
        "0462","0463","0464","0465","0471","0472","0473","0474",
        "0481","049","050","051","0521","0522","0523","0524",
        "0525","0532","0533","0534","0535","0536","0541","0542",
        "0543","0544","0545","0546","0547","055","0564","0565",
        "0566","0571","0572","0573","0574","0575","0577","0578",
        "0583","0584","0585","0586","0587","0588","059","06",
        "070","071","0721","0722","0731","0732","0733","0734",
        "0735","0736","0737","0742","0743","0744","0746","075",
        "0761","0763","0765","0766","0771","0773","0774","0775",
        "0776","0781","0782","0783","0784","0785","0789","079",
        "080","081","0823","0824","0825","0827","0828","0831",
        "0832","0833","0835","0836","085","0861","0862","0863",
        "0864","0865","0871","0872","0873","0874","0875","0881",
        "0882","0883","0884","0885","089","090","091","0921",
        "0922","0923","0924","0925","0931","0932","0933","0934",
        "0935","0941","0942","095","0961","0962","0963","0964",
        "0965","0966","0967","0968","0971","0972","0973","0974",
        "0975","0976","0981","0982","0983","0984","0985","099");
}

var g_sPrefixCountries;
$.getJSON("/Content/json/PrefixCountries.json", function(data) {
    g_sPrefixCountries = data;
});