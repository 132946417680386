import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";
import { ICustomerBank } from "../ProlifeSdk/interfaces/customer/ICustomerBank";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export type CustomerIBANDataSourceModel = IDataSourceModel<string, ICustomerBank>;

export class CustomerIBANDataSource extends BaseDataSource<CustomerIBANDataSourceModel> {
    private customer: ICustomer;
    private ouId: number;

    getTitle(currentModel: CustomerIBANDataSourceModel): string {
        return TextResources.Customers.CustomerIBANs;
    }

    async getData(
        currentModel: CustomerIBANDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<CustomerIBANDataSourceModel[]> {
        if (!this.customer) return [];

        return this.getAllCustomerBanksWithIBAN()
            .filter((d) => TextFiltersUtilities.contains(d.IBAN, textFilter))
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: CustomerIBANDataSourceModel, ids: string[]): Promise<CustomerIBANDataSourceModel[]> {
        if (!this.customer) return [];

        return this.getAllCustomerBanksWithIBAN()
            .filter((p) => ids.indexOf(p.IBAN) >= 0)
            .map(this.createModel, this);
    }

    private createModel(bank: ICustomerBank): CustomerIBANDataSourceModel {
        return {
            id: bank.IBAN,
            title: bank.IBAN,
            subTitle: bank.Name,
            isLeaf: true,
            isGroup: false,
            model: bank,
        };
    }

    public setCustomer(customer: ICustomer, ouId?: number) {
        this.customer = customer;
        this.ouId = ouId;
    }

    private getAllCustomerBanksWithIBAN(): ICustomerBank[] {
        const banks = [];
        for (const ou of this.customer.OrganizationalUnits) {
            if (this.ouId && ou.Id !== this.ouId) continue;

            for (const bank of ou.Banche) {
                if ((bank.IBAN || "").length > 0) banks.push(bank);
            }
        }

        return banks;
    }
}
