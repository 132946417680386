import * as React from "@abstraqt-dev/jsxknockout";
import { DOMAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, PropsWithChildren } from "../Core/utils/ComponentUtils";

declare let Layout;

type ApplicationProps = {
    children?: React.ReactNode;
}

export class Application {
    constructor(private props : ApplicationProps) {
        
    }

    componentDidMount() {
        console.log("Application componentDidMount")
        setTimeout(() => Layout.fixContentHeight(), 0);
    }

    render() {
        return <>{this.props.children}</>
    }
}

export function _LeftMenu(props : DOMAttributes) {
    return (
        <div className="page-sidebar-wrapper">
            {props.children}
        </div>
    )
}

export function _Content(props : PropsWithChildren<{ noGutters?: boolean }>) {
    const classes = ComponentUtils.classNames("page-content", "flex-container", {
        'no-gutters': props.noGutters
    })

    return (
        <div className="page-content-wrapper">
            <div className={classes}>
                {props.children}
            </div>
        </div>
    )
}

export function _RightMenu(props : DOMAttributes) {
    return (
        <div className="page-quick-sidebar-wrapper">
            <div className="page-quick-sidebar">
                {props.children}
            </div>
        </div>
    )
}


export namespace Application {
    export const LeftMenu = _LeftMenu;
    export const RightMenu = _RightMenu;
    export const Content = _Content;
}