import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/06/2017
 * Time: 11:20
 * To change this template use File | Settings | File Templates.
 */

import { IAnswer } from "../../../../ProlifeSdk/interfaces/survey/IAnswer";

export class Answer {
    public Id: number;
    public QuestionId: number;
    public Text: ko.Observable<string> = ko.observable();
    public GoToLabel: ko.Observable<string> = ko.observable();
    public EnableLabel: ko.Observable<string> = ko.observable();
    public Index: ko.Observable<number> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Value: ko.Observable<string> = ko.observable();
    public HideValue: ko.Observable<boolean> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public IsOpen: ko.Observable<boolean> = ko.observable();
    public AnswerTypeId: ko.Observable<number> = ko.observable();
    public AnswerTypeName: ko.Observable<string> = ko.observable();
    public PublicLabel: ko.Observable<string> = ko.observable();
    public GoToPublicLabel: ko.Observable<string> = ko.observable();
    public EnablePublicLabel: ko.Observable<string> = ko.observable();
    public SectionGoToPublicLabel: ko.Observable<string> = ko.observable();
    public SectionEnablePublicLabel: ko.Observable<string> = ko.observable();

    constructor(answer: IAnswer) {
        this.Id = answer.Id;
        this.QuestionId = answer.QuestionId;
        this.Text(answer.Text);
        this.GoToLabel(answer.GoToLabel);
        this.EnableLabel(answer.EnableLabel);
        this.Index(answer.Index);
        this.Description(answer.Description);
        this.Value(answer.Value);
        this.HideValue(answer.HideValue);
        this.CreationDate(answer.CreationDate);
        this.ModifyDate(answer.ModifyDate);
        this.CreatorId(answer.CreatorId);
        this.ModifierId(answer.ModifierId);
        this.IsOpen(answer.IsOpen);
        this.AnswerTypeId(answer.AnswerTypeId);
        this.AnswerTypeName(answer.AnswerTypeName);
        this.PublicLabel(answer.PublicLabel);
        this.GoToPublicLabel(answer.GoToPublicLabel);
        this.EnablePublicLabel(answer.EnablePublicLabel);
        this.SectionGoToPublicLabel(answer.SectionGoToPublicLabel);
        this.SectionEnablePublicLabel(answer.SectionEnablePublicLabel);
    }

    public ToJSON(): IAnswer {
        return <IAnswer> {
            Id: this.Id,
            QuestionId: this.QuestionId,
            Text: this.Text(),
            GoToLabel: this.GoToLabel(),
            EnableLabel: this.EnableLabel(),
            Index: this.Index(),
            Description: this.Description(),
            Value: this.Value(),
            HideValue: this.HideValue(),
            CreationDate: this.CreationDate(),
            ModifyDate: this.ModifyDate(),
            CreatorId: this.CreatorId(),
            ModifierId: this.ModifierId(),
            IsOpen: this.IsOpen(),
            AnswerTypeId: this.AnswerTypeId(),
            AnswerTypeName: this.AnswerTypeName(),
            PublicLabel: this.PublicLabel(),
            GoToPublicLabel: this.GoToPublicLabel(),
            EnablePublicLabel: this.EnablePublicLabel(),
            SectionGoToPublicLabel: this.SectionGoToPublicLabel(),
            SectionEnablePublicLabel: this.SectionEnablePublicLabel()
        };
    }
}