import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/04/13
 * Time: 17.57
 * To change this template use File | Settings | File Templates.
 */

/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

/// DEPRECATO

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class SupplierValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();

        var customersService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var instance = {};

        (<any>$(element)).typeahead({
            source: SupplierValue.findSuppliers.bind(null, instance, customersService),
            items: 8,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>',
            highlighter: function(json) {
                var item = JSON.parse(json);
                var element = item.FullName ? "<b>" + item.FullName + "</b>" : "";
                element += (item.PIVA && item.PIVA.trim().length > 0) ? "<small>&nbsp;(" + item.PIVA + ")</small>" : "";
                element += (item.Address && item.Address.trim().length > 0) ? "<br/><small>" + item.Address + "</small>" : "";
                return element;
            },
            updater: function(json) {
                var item = JSON.parse(json);
                valueAccessor()(item.id);
                otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(item.FullName);
                return item.FullName;
            }
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var otherBindings = allBindingsAccessor();

        if(!newValue) {
            !otherBindings.value && $(element).val("");
            return;
        }
    }

    private static findSuppliers(_this : any, customersService : ICustomersService, query : string, process: (items : any[]) => any) {
        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            customersService.getAllCustomers(query, undefined, true)
                .then((customers : ICustomer[]) => {
                    return process(SupplierValue.transformSuppliers(customers));
                });
        }, 500);
    }

    private static transformSuppliers(customers : ICustomer[]) : any[] {
        return customers.map(SupplierValue.transformSupplier);
    }

    private static transformSupplier(customer : ICustomer) : any {
        return {
            id: customer.IdCliente,
            FullName: customer.FormattedContactName || "",
            PIVA: customer.PIVA,
            Address : customer.Indirizzo,
            Provincia: customer.Provincia,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["suppliersValue"] = new SupplierValue();