import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowWithActivitiesViewModel } from "../../../../../Todolist/Todolist/ui/workflows/WorkflowActivitiesMultipliersManager";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import {
    IJobOrderEditorPanelFactory,
    IJobOrderEditor,
    IJobOrderEditorPanel,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IWorkflowForTaskBoard } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { Deferred } from "../../../../../Core/Deferred";

export class JobOrderCostsPlanningFactory implements IJobOrderEditorPanelFactory {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor(public Color: string) {}

    public createPanel(_: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderCostsPlanning(this.Color, editor);
    }

    public hasRequiredModules(): boolean {
        return true;
    }

    public hasAuthorization(): boolean {
        return this.authorizationsService.isAuthorized("JobOrders_ViewCostsPlanning");
    }
}

class JobOrderCostsPlanning implements IJobOrderEditorPanel {
    public Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.Todolist.JobOrderCostsPlanningTitle);
    public TemplateUrl = "joborder/templates/joborderdetail";
    public TemplateName = "costs-planning";

    public WorkflowActivitiesEditor: WorkflowWithActivitiesViewModel;
    public SelectedWorkflow: ko.Observable<IWorkflowForTaskBoard> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    constructor(public Color: string, private editor: IJobOrderEditor) {}

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    public dispose() {
        this.WorkflowActivitiesEditor.dispose();
    }

    public load(): Promise<any> {
        if (!this.WorkflowActivitiesEditor) this.WorkflowActivitiesEditor = new WorkflowWithActivitiesViewModel(null);

        return this.WorkflowActivitiesEditor.load();
    }

    public isDefaultOnNew(): boolean {
        return false;
    }

    public isDefault(): boolean {
        return false;
    }

    public beforeChangePanel(): Promise<boolean> {
        const def: Deferred<boolean> = new Deferred();

        this.confirmIfHasPendingChanges().then((value: boolean) => {
            if (value) {
                this.SelectedWorkflow(null);
                this.WorkflowActivitiesEditor.setWorkflowAndReload(null);
            }

            def.resolve(value);
        });

        return def.promise();
    }

    public async beforeShowPanel(): Promise<void> {
        this.WorkflowActivitiesEditor = new WorkflowWithActivitiesViewModel(null);
        this.WorkflowActivitiesEditor.setShowClosedJobOrdersOnTasks(this.editor.IsJobOrderClosed());
        this.WorkflowActivitiesEditor.UpdateWorkflow(false);
        this.WorkflowActivitiesEditor.reloadTasks();
        this.desktopService.SystemHeader.setSidebarVisibility(true);
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (!model) {
            this.SelectedWorkflow(null);
            this.WorkflowActivitiesEditor.setWorkflowAndReload(null);
        } else {
            const workflow = model.model as IWorkflowForTaskBoard;
            this.SelectedWorkflow(workflow);

            // TODO quando verrà introdotta la funzionalità di aggiornamento del piano, come nel dialog di gestione quantità attività, dovrà essere caricato il piano dal server perché non ho tutti i dati a disposizione
            const workflowForEditor: ITodoListWorkflow = {
                Id: workflow.Id,
                Title: workflow.Title,
                Description: workflow.Title,
                Multiplier: workflow.Multiplier,
                MultiplierUoM: workflow.MultiplierUoM,
                JobOrderId: workflow.JobOrderId,
                Status: workflow.StateId,
                Code: null,
                OutcomeId: null,
                DefaultRoleId: null,
                ShownInWorkflowId: null,
                Links: [],
                Color: null,
                Resources: [],
                ActivitiesProgressAmountMode: null,
                ShowAlertOnUnestimatedTasks: false,
                WorkflowMustBePlanned: false,
                ActivitiesMustBeReviewed: false,
                WorkflowMustBeRelatedToCustomerOrders: false,
                WorkedHoursDefaultsStrictMode: false,
                HideAdministrativeFieldsOnWorkedHours: false,
            };

            this.WorkflowActivitiesEditor.setWorkflowAndReload(workflowForEditor);
        }
    }

    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        this.SelectedWorkflow(null);
        this.WorkflowActivitiesEditor.setWorkflowAndReload(null);
    }

    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return this.confirmIfHasPendingChanges();
    }

    private async confirmIfHasPendingChanges(): Promise<boolean> {
        const pendingChanges: boolean = this.WorkflowActivitiesEditor.hasChanges();

        if (!pendingChanges) return true;

        return this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.JobOrder.CostsPlanningPendingChangesMessage,
            ProlifeSdk.TextResources.JobOrder.Cancel,
            ProlifeSdk.TextResources.JobOrder.Confirm
        );
    }
}
