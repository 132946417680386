import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { DefaultValuesSettingsUi } from "../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";

export class PurchaseRequestDefaultValuesSettingUi extends DefaultValuesSettingsUi {
    constructor(protected protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);
        this.templateUrl = "provisioning/templates/defaultvalues";
        this.templateName = "purchase-request-default-values";

        this.ShowAbiAndCabFields(false);
    }
}
