import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { NavigationMenuComponent } from "./NavigationMenuComponent";
import { ComponentUtils } from "../Core/utils/ComponentUtils";
import { IDataSource, IDataSourceListener, IDataSourceIcon, IDataSourceModel } from "../DataSources/IDataSource";
import {
    INavigationMenuComponentActionsGroupItem,
    INavigationMenuComponentActionItem,
    INavigationMenuComponentActionSeparatorItem,
    INavigationMenuComponentSearchActionItem,
    INavigationMenuComponentItem,
    INavigationMenuComponentGroup,
    INavigationMenuComponentModel,
    INavigationMenuComponentAlert,
    INavigationMenuComponentTemplatesProvider,
    INavigationMenuComponent,
    INavigationMenuComponentBadge,
} from "./NavigationMenuComponent/INavigationMenuComponent";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { ObservableArrayDataSource } from "../DataSources/ObservableArrayDataSource";

const styleSheet = jss.createStyleSheet({
    "prolife-nav-menu": {
        "& .date": {
            "& button": {
                "&.badge": {
                    "&:not(last-child)": {
                        marginRight: "5px",
                    },
                },
            },
            "& span": {
                "&.badge": {
                    "&:not(last-child)": {
                        display: "inline-block",
                        marginRight: "5px",
                    },
                },
            },
        },
    },
    "prolife-workflow-progress-bar": {
        width: "140px",
        height: "10px",
        marginTop: "10px",
        marginBottom: "0",
        display: "inline-block",
    },
});
const { classes } = styleSheet.attach();

type NavigationMenuProps<T = unknown, I = string | number> = {
    dataSource: IDataSource | ObservableArrayDataSource<T, I>;
    allowSearch?: boolean;
    disableSelection?: boolean;
    allowNoSelection?: boolean;
    listener?: IDataSourceListener;
    wrapperClassName?: string;
    whiteTheme?: boolean;
    templatesProvider?: INavigationMenuComponentTemplatesProvider;
    forwardRef?: (component: INavigationMenuComponent) => void;

    onSelect?: (item: IDataSourceModel<I, T>) => void;
    onDeselect?: (item: IDataSourceModel<I, T>) => void;
};

export class NavigationMenu {
    public get component() {
        return this._component;
    }

    private _component: NavigationMenuComponent;

    static defaultProps = {
        allowSearch: true,
    };

    constructor(private props: NavigationMenuProps) {
        this._component = new NavigationMenuComponent({
            DataSource: props.dataSource,
            Listeners: props.listener,
            UseWhiteTheme: props.whiteTheme,
            WrapperCssClasses: props.wrapperClassName,
            DisableSearch: !props.allowSearch,
            DisableSelection: props.disableSelection,
            AllowNoSelection: props.allowNoSelection,
            TemplatesProvider: props.templatesProvider,
            InjectTo: props.forwardRef,
            onSelect: props.onSelect,
            onDeselect: props.onDeselect,
        });
    }

    render() {
        const wrapperClassName = ComponentUtils.classNames("page-quick-sidebar-wrapper", this.props.wrapperClassName);
        const navigatorClassName = ComponentUtils.classNames(
            "page-quick-sidebar-chat-users",
            "navigator",
            classes["prolife-nav-menu"],
            {
                "white-style": this.props.whiteTheme,
            }
        );

        return ComponentUtils.bindTo(
            <div className="page-sidebar">
                <div className={wrapperClassName}>
                    <div className="page-quick-sidebar">
                        <div className="page-quick-sidebar-chat">
                            <div className={navigatorClassName}>
                                {this.renderHeader()}
                                {this.renderSearch()}
                                {this.renderBody()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            this.component,
            "navigator"
        );
    }

    private renderNonSeparatorAction() {
        let action: INavigationMenuComponentActionItem;

        return (
            <ko-bind data-bind={{ if: !action.isSeparator && action.visible() }}>
                <li data-bind={{ css: { selected: action.active, disabled: !action.canExecute() } }}>
                    <a href="#" data-bind={{ click: action.click, attr: { disabled: !action.canExecute() } }}>
                        <i data-bind={{ css: action.icon }}></i>
                        <ko-bind data-bind={{ text: action.text }}></ko-bind>
                    </a>
                </li>
            </ko-bind>
        );
    }

    private renderGroupHeader() {
        let menuAction: INavigationMenuComponentActionsGroupItem;
        let action:
            | INavigationMenuComponentSearchActionItem
            | INavigationMenuComponentActionSeparatorItem
            | INavigationMenuComponentActionItem
            | INavigationMenuComponentActionsGroupItem;

        return (
            <div className="btn-group" data-bind={{ atr: { title: menuAction.actionInfo.title } }}>
                <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                    <i data-bind={{ css: menuAction.icon }}></i>
                </button>
                <ul
                    className="dropdown-menu pull-right"
                    data-bind={{ foreach: { data: menuAction.actions, as: "action" } }}>
                    <ko-bind data-bind={{ if: action.isSeparator }}>
                        <li style="border-bottom: 1px solid;"></li>
                    </ko-bind>
                    {this.renderNonSeparatorAction()}
                </ul>
            </div>
        );
    }

    private renderHeader() {
        const navigator = this.component;
        let menuAction: INavigationMenuComponentActionItem;

        return (
            <header className="navigator-header">
                <div className="actions">
                    <ko-bind data-bind={{ foreach: { data: navigator.MenuActions, as: "menuAction" } }}>
                        <ko-bind data-bind={{ if: menuAction.isGroup && menuAction.visible() }}>
                            {this.renderGroupHeader()}
                        </ko-bind>
                        <ko-bind data-bind={{ if: !menuAction.isGroup && menuAction.visible() }}>
                            <button
                                className="btn"
                                data-bind={{
                                    click: menuAction.click.bind(menuAction),
                                    css: menuAction.active() ? menuAction.activeClass() : menuAction.defaultClass(),
                                    attr: { title: menuAction.actionInfo.title },
                                    enable: menuAction.canExecute,
                                }}>
                                <i data-bind={{ css: menuAction.icon }}></i>
                                <ko-text data-bind="text"></ko-text>
                            </button>
                        </ko-bind>
                    </ko-bind>
                </div>
                <div className="flex-container">
                    <button
                        className="btn btn-circle btn-default"
                        data-bind={{ visible: navigator.CanGoBack, click: navigator.goBack }}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <h3
                        className="list-heading"
                        data-bind={{
                            text: navigator.Title,
                            style: {
                                "max-width": "202px",
                                "min-width": navigator.MenuActions().length == 1 ? "161px" : "inherit",
                            },
                        }}></h3>
                </div>
            </header>
        );
    }

    private renderSearch() {
        if (!this.props.allowSearch) return <></>;

        const navigator = this.component;
        let searchOption: INavigationMenuComponentSearchActionItem;
        let advancedSearchFilter: INavigationMenuComponentActionItem;

        return (
            <div className="sidebar-search-wrapper navigator-search-wrapper">
                <form
                    className="sidebar-search flex-container"
                    onSubmit={() => false}
                    style={{ margin: "10px 5px 10px 10px", alignItems: "center" }}>
                    <a className="remove" href="javascript:;"></a>
                    <div className="input-group flex-fill">
                        <input
                            className="form-control"
                            type="text"
                            data-bind={{
                                value: navigator.TextFilter,
                                valueUpdate: "afterkeydown",
                                attr: { placeholder: navigator.SearchPlaceholder },
                            }}
                        />
                        <span className="input-group-btn" style={{ width: "22px", textAlign: "center" }}>
                            <a className="btn submit">
                                <i className="icon-magnifier"></i>
                            </a>
                        </span>
                    </div>
                    <ko-bind data-bind={{ if: navigator.HasSearchOptions }}>
                        <div className="btn-group">
                            <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                <i className="fa fa-angle-down"></i>
                            </button>
                            <ul
                                className="dropdown-menu pull-right"
                                data-bind={{ foreach: { data: navigator.SearchOptions, as: "searchOption" } }}>
                                <li>
                                    <a href="#" data-bind={{ click: searchOption.click }}>
                                        <i
                                            className="fa"
                                            style="width : 20px"
                                            data-bind={{ css: { "fa-check": searchOption.Selected } }}></i>
                                        <span data-bind={{ text: searchOption.actionInfo.text }}></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ko-bind>
                    <ko-bind data-bind={{ with: navigator.AdvancedSearchFilterAction, as: "advancedSearchFilter" }}>
                        <button
                            className="btn"
                            data-bind={{
                                click: advancedSearchFilter.click.bind(advancedSearchFilter),
                                css: advancedSearchFilter.active()
                                    ? advancedSearchFilter.activeClass()
                                    : advancedSearchFilter.defaultClass(),
                                attr: { title: advancedSearchFilter.actionInfo.title },
                                enable: advancedSearchFilter.canExecute,
                            }}>
                            <i data-bind={{ css: advancedSearchFilter.icon }}></i>
                            <ko-text data-bind="text"></ko-text>
                        </button>
                    </ko-bind>
                </form>
            </div>
        );
    }

    private renderBody() {
        const navigator = this.component;

        if (this.props.whiteTheme) return this.renderWhiteThemeBody();

        return (
            <div
                className="navigator-scrollable-container"
                data-bind={{
                    lightSlimScroll: null,
                    droppableListEx: {
                        allowedMimeTypes: navigator.ValidMimeTypes,
                        onDrop: navigator.OnItemDropped.bind(navigator, null),
                        onlyDropOver: true,
                        hoverClass: "droppable-hover",
                        hoverBeforeClass: "",
                        hoverAfterClass: "",
                        itemSelector: "",
                    },
                    style: { top: navigator.TopOffset },
                }}>
                <h4
                    class="list-heading"
                    data-bind={{
                        visible: !navigator.HasItems() && !navigator.IsLoading() && navigator.HasLoadedData(),
                        text: navigator.EmptyListPlaceholder,
                    }}></h4>
                <ko-bind data-bind={{ if: navigator.IsGroupedDataSet }}>{this.renderGroupedList()}</ko-bind>
                <ko-bind data-bind={{ ifnot: navigator.IsGroupedDataSet }}>{this.renderList()}</ko-bind>
            </div>
        );
    }

    private renderGroupedList() {
        const navigator = this.component;
        let item: INavigationMenuComponentGroup;

        return (
            <>
                <ko-bind data-bind={{ foreach: { data: navigator.Items, as: "item" } }}>
                    <div>
                        <h3
                            class="list-heading prolife"
                            data-bind={{
                                click: item.click,
                                tooltip: { placement: "bottom" },
                                attr: { "data-original-title": item.model.title },
                            }}>
                            <span data-bind={{ text: item.model.title }}></span>
                            <button
                                class="btn btn-transparent btn-sm pull-left"
                                style={{ marginTop: "-6px", color: "white", width: "32px" }}>
                                <i
                                    class="fa fa-angle-down"
                                    data-bind={{
                                        css: { "fa-angle-down": !item.Collapsed(), "fa-angle-right": item.Collapsed },
                                    }}></i>
                            </button>
                        </h3>
                        <h4
                            class="list-heading"
                            data-bind={{
                                visible: !item.HasItems() && !item.IsLoading() && item.HasLoadedData(),
                                text: navigator.EmptyListPlaceholder,
                            }}></h4>
                        <ul class="feeds list-items" data-bind={{ if: !item.Collapsed() }}>
                            <ko-bind data-bind={{ foreach: { data: item.Items, as: "child" } }}>
                                {this.renderItem()}
                            </ko-bind>

                            <ko-bind data-bind={{ if: item.ShowLoadMore }}>
                                <li
                                    class="load-more-elements"
                                    data-bind={{
                                        notifyWhenVisible: {
                                            rootSelector: ".navigator-scrollable-container",
                                            callback: item.loadNextPage.bind(item),
                                        },
                                    }}></li>
                            </ko-bind>
                            <li class="text-center" data-bind={{ visible: item.IsLoading }}>
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                            </li>
                        </ul>
                    </div>
                </ko-bind>
                <ko-bind data-bind={{ if: navigator.ShowLoadMore }}>
                    <li
                        class="load-more-elements"
                        data-bind={{
                            notifyWhenVisible: {
                                rootSelector: ".navigator-scrollable-container",
                                callback: navigator.loadNextPage.bind(navigator),
                            },
                        }}></li>
                </ko-bind>
                <li
                    class="text-center"
                    style={{ listStyle: "none", margin: 0, padding: 0 }}
                    data-bind={{ visible: navigator.IsLoading }}>
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                </li>
            </>
        );
    }

    private renderList() {
        const navigator = this.component;

        return (
            <ul class="feeds list-items">
                <ko-bind data-bind={{ foreach: { data: navigator.Items, as: "child" } }}>{this.renderItem()}</ko-bind>

                <ko-bind data-bind={{ if: navigator.ShowLoadMore }}>
                    <li
                        class="load-more-elements"
                        data-bind={{
                            notifyWhenVisible: {
                                rootSelector: ".navigator-scrollable-container",
                                callback: navigator.loadNextPage.bind(navigator),
                            },
                        }}></li>
                </ko-bind>
                <li class="text-center" data-bind={{ visible: navigator.IsLoading }}>
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                </li>
            </ul>
        );
    }

    private renderItem() {
        const navigator = this.component;
        let child: INavigationMenuComponentItem;
        let m: INavigationMenuComponentModel;
        let icon: IDataSourceIcon;
        let alertIcon: INavigationMenuComponentAlert;
        let badge: INavigationMenuComponentBadge;

        return (
            <li
                data-bind={{
                    click: child.click.bind(child, !navigator.SelectLeafsOnly()),
                    css: { selected: child.Selected, "has-selected-children": child.ChildrenSelected },
                    droppableEx: {
                        allowedMimeTypes: navigator.ValidMimeTypes,
                        hoverClass: "droppable-hover",
                        onDrop: navigator.OnItemDropped.bind(navigator, child.model),
                    },
                    draggableEx: {
                        CanDrag: child.model.dragEnabled,
                        OnDrag: navigator.OnItemDragged.bind(navigator, child.model),
                    },
                    with: child.model,
                    as: "m",
                }}>
                <ko-bind data-bind={{ if: navigator.hasCustomTemplateForModel(m) }}>
                    <ko-bind data-bind={{ template: { nodes: navigator.getCustomTemplateForModel(m) } }}></ko-bind>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: navigator.hasCustomTemplateForModel(m) }}>
                    <ko-bind data-bind={{ if: !!m.badge }}>
                        <ko-bind data-bind={{ foreach: { data: m.badge, as: "badge" } }}>
                            <ko-bind data-bind={{ if: !!badge.action }}>
                                <div class="date">
                                    <button
                                        class="badge pull-right"
                                        style="border: none;"
                                        data-bind={{
                                            text: badge.text,
                                            css: badge.cssClass,
                                            asyncClick: badge.action.bind(m.badge),
                                            attr: { title: badge.title },
                                        }}></button>
                                </div>
                            </ko-bind>
                            <ko-bind data-bind={{ ifnot: !!badge.action }}>
                                <div class="date">
                                    <span
                                        class="badge pull-right"
                                        data-bind={{
                                            text: badge.text,
                                            css: badge.cssClass,
                                            attr: { title: badge.title },
                                        }}></span>
                                </div>
                            </ko-bind>
                        </ko-bind>
                    </ko-bind>
                    <div class="col1">
                        <div class="cont">
                            <div class="cont-col1">
                                <ko-bind data-bind={{ if: !!m.icon }}>
                                    <div
                                        class="btn btn-primary btn-lg"
                                        data-bind={{
                                            style: { background: m.icon.background, color: m.icon.foreground },
                                        }}
                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                        <i data-bind={{ css: m.icon.icon }}></i>
                                    </div>
                                </ko-bind>
                                <div style={{ position: "absolute" }}>
                                    <ko-bind data-bind={{ if: m.iconsList && !navigator.ShowSecondaryAction() }}>
                                        <ko-bind data-bind={{ foreach: { data: m.iconsList, as: "icon" } }}>
                                            <div
                                                class="btn btn-primary btn-lg"
                                                data-bind={{
                                                    style: { background: icon.background, color: icon.foreground },
                                                }}
                                                style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: block; margin-top: 5px">
                                                <i data-bind={{ css: icon.icon }}></i>
                                            </div>
                                        </ko-bind>
                                    </ko-bind>
                                    <ko-bind
                                        data-bind={{
                                            if:
                                                navigator.ShowSelectAllChildren() &&
                                                m.showSelectAllChildren &&
                                                !navigator.ShowSecondaryAction(),
                                        }}>
                                        <br />
                                        <a
                                            class="workflow-item-with-task-checkbox"
                                            data-bind={{
                                                click: child.manageAllChildrenSelection.bind(child),
                                                clickBubble: false,
                                            }}>
                                            <i
                                                class="fa fa-square-o fa-lg"
                                                data-bind={{
                                                    visible: !child.AllChildrenSelected() && !child.ChildrenSelected(),
                                                }}></i>
                                            <i
                                                class="fa fa-square fa-lg"
                                                data-bind={{
                                                    visible: !child.AllChildrenSelected() && child.ChildrenSelected(),
                                                }}></i>
                                            <i
                                                class="fa fa-check-square fa-lg"
                                                data-bind={{ visible: child.AllChildrenSelected }}></i>
                                        </a>
                                    </ko-bind>
                                    <ko-bind
                                        data-bind={{
                                            if:
                                                !navigator.SelectLeafsOnly() &&
                                                m.canSelectEvenIfNotLeaf &&
                                                !m.isLeaf &&
                                                !navigator.ShowSecondaryAction(),
                                        }}>
                                        <br />
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-xs menu-item-select-button"
                                            title={TextResources.ProlifeSdk.SelectOrDeselectMenuItem}
                                            data-bind={{
                                                click: child.click.bind(child, navigator.SelectLeafsOnly()),
                                                clickBubble: false,
                                            }}>
                                            <i
                                                className="fa"
                                                data-bind={{
                                                    css: { "fa-check": !child.Selected(), "fa-times": child.Selected },
                                                }}></i>
                                        </button>
                                    </ko-bind>
                                    <br />
                                    <ko-bind data-bind={{ if: m.secondaryAction && navigator.ShowSecondaryAction() }}>
                                        <div
                                            class="btn btn-warning btn-lg"
                                            data-bind={{
                                                click: m.secondaryAction.action.bind(m.secondaryAction, m),
                                                clickBubble: false,
                                                style: {
                                                    background: m.secondaryAction.icon.background,
                                                    color: m.secondaryAction.icon.foreground,
                                                },
                                            }}
                                            style={{
                                                minWidth: "30px",
                                                minHeight: "30px",
                                                textAlign: "center",
                                                lineHeight: "30px",
                                                padding: 0,
                                                marginTop: "5px",
                                                position: "absolute",
                                            }}>
                                            <i class="fa fa-pencil"></i>
                                        </div>
                                    </ko-bind>
                                </div>
                            </div>
                            <div class="cont-col2">
                                <div class="desc" data-bind={{ css: { "without-icon": !m.icon } }}>
                                    <div data-bind={{ text: m.title }}></div>
                                    <ko-bind data-bind={{ if: !!m.subTitle }}>
                                        <div class="label label-sm menu-item-subtitle">
                                            <span data-bind={{ html: m.subTitle }}></span>
                                        </div>
                                    </ko-bind>
                                    <ko-bind data-bind={{ if: !!m.progressBar }}>
                                        <div
                                            class="progress"
                                            style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block">
                                            <div
                                                class="progress-bar progress-bar-success"
                                                style="min-width: 0"
                                                role="progressbar"
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                data-bind={{
                                                    attr: { "aria-valuenow": m.progressBar.progress },
                                                    style: { width: m.progressBar.progress + "%" },
                                                }}></div>
                                        </div>
                                    </ko-bind>
                                    <ko-bind data-bind={{ if: !!m.secondaryIcon }}>
                                        <div
                                            class="btn btn-primary btn-lg"
                                            data-bind={{
                                                style: {
                                                    background: m.secondaryIcon.background,
                                                    color: m.secondaryIcon.foreground,
                                                },
                                            }}
                                            style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                            <i data-bind={{ css: m.secondaryIcon.icon }}></i>
                                        </div>
                                    </ko-bind>
                                    <ko-bind data-bind={{ if: !!m.alerts }}>
                                        <div>
                                            <span
                                                class="pull-left"
                                                style="margin-right: 5px; line-height: 22px"
                                                data-bind={{ text: m.alerts.label }}></span>
                                            <ko-bind data-bind={{ foreach: { data: m.alerts.icons, as: "alertIcon" } }}>
                                                <div
                                                    class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                    style="margin-right: 5px"
                                                    data-bind={{ attr: { title: alertIcon.tooltip }, tooltip: {} }}>
                                                    <i
                                                        data-bind={{
                                                            css: alertIcon.icon.icon,
                                                            style: { color: alertIcon.icon.foreground },
                                                        }}></i>
                                                </div>
                                            </ko-bind>
                                        </div>
                                    </ko-bind>
                                </div>
                                <ko-bind data-bind={{ if: !!m.details }}>
                                    <div
                                        class="desc"
                                        style="margin: 0px; padding: 0px; max-width: 100%"
                                        data-bind={{
                                            component: { name: m.details.componentName, params: m.details.model },
                                        }}></div>
                                </ko-bind>
                            </div>
                        </div>
                    </div>
                </ko-bind>
            </li>
        );
    }

    private renderWhiteThemeBody() {
        const navigator = this.component;

        return (
            <div
                className="navigator-scrollable-container"
                data-bind={{ style: { top: navigator.TopOffset }, slimScroll: {} }}>
                <h4
                    class="list-heading"
                    data-bind={{
                        visible: !navigator.HasItems() && !navigator.IsLoading() && navigator.HasLoadedData(),
                        text: navigator.EmptyListPlaceholder,
                    }}></h4>
                <ko-bind data-bind={{ if: navigator.IsGroupedDataSet }}>{this.renderGroupedList()}</ko-bind>
                <ko-bind data-bind={{ ifnot: navigator.IsGroupedDataSet }}>{this.renderList()}</ko-bind>
            </div>
        );
    }
}
