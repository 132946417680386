/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 20/02/13
 * Time: 11.47
 * To change this template use File | Settings | File Templates.
 */

import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { VatRegisterAdvancedSettings } from "./VatRegisterAdvancedSettings";
import { IDocumentsService } from "../../../../DocumentsService";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IDocumentsProvider } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import {
    IVatRegister,
    IVatRegisters,
    IVatRegisterImagesInfo,
    MissingInvoiceTypesError,
    DocumentsOnDisabledInvoiceTypeError,
    InvoiceKey,
} from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import {
    IVatRegisterEditingContainer,
    IProtocolType,
    IProtocolSettingsViewModel,
} from "../../../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IValidationService, IValidator, IValidation } from "../../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IProtocolDefaultValuesSettingsUi } from "../../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { IPrintTemplate, IPrintTemplateSettingsManager } from "../../PrintTemplateSettingsManager";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";
import { PrintTemplatesSelector } from "../PrintTemplatesSelector";
import { ElectronicInvoiceTypesDataSource } from "../../../../../DataSources/ElectronicInvoiceTypesDataSource";
import { IInvoicesService } from "../../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { SaveProtocolError } from "../../errors/SaveProtocolError";

interface IVatRegisterViewModelFactory {
    new (
        container: VatRegistersEditingViewModel,
        vatRegister: IVatRegister,
        registerPrintTemplates: number[],
        registerPreferredPrintTemplateId: number
    ): BaseVatRegisterViewModel;
}

interface IVatRegisterDocumentLabelsMap {
    [docType: string]: string;
}

interface IVatRegisterDocumentSalesCicleMap {
    [docType: string]: boolean;
}

interface IVatRegisterGroupsLabelsMap {
    [docType: string]: string;
}

export class VatRegistersEditingViewModel implements IVatRegisterEditingContainer {
    showPositionNumberConflictMessage = true;

    invoiceElements: ko.ObservableArray<InvoiceVatRegisterViewModel> = ko.observableArray();
    ddtElements: ko.ObservableArray<DDTVatRegisterViewModel> = ko.observableArray();
    preventiviElements: ko.ObservableArray<EstimateVatRegisterViewModel> = ko.observableArray();

    customProtocolsTypes: ko.ObservableArray<CustomTypeProtocolsViewModel> = ko.observableArray();

    CanEditIvaRegisters: ko.Observable<boolean> = ko.observable();
    CanEditDDTProtocols: ko.Observable<boolean> = ko.observable();
    CanEditEstimatesProtocols: ko.Observable<boolean> = ko.observable();

    private vatRegisters: ko.ObservableArray<BaseVatRegisterViewModel> = ko.observableArray([]);
    private deletedElements: BaseVatRegisterViewModel[] = [];

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    private viewModelFactory: IVatRegisterViewModelFactory[] = [
        InvoiceVatRegisterViewModel,
        DDTVatRegisterViewModel,
        EstimateVatRegisterViewModel,
        EstimateVatRegisterViewModel,
    ];

    private errorMessagesBuilders: { [errorCode: string]: (metadata: unknown) => string };

    newInvoiceVatRegister: ko.Observable<string> = ko.observable();
    newDDTVatRegister: ko.Observable<string> = ko.observable();
    newEstimateVatRegister: ko.Observable<string> = ko.observable();

    newInvoiceHasFocus: ko.Observable<boolean> = ko.observable();
    newDDTHasFocus: ko.Observable<boolean> = ko.observable();
    newEstimateHasFocus: ko.Observable<boolean> = ko.observable();

    private documentsLabelsMap: IVatRegisterDocumentLabelsMap = {};
    private documentsSalesCicleMap: IVatRegisterDocumentSalesCicleMap = {};
    private groupsLabelsMap: IVatRegisterGroupsLabelsMap = {};

    public invoicesScroller: ko.Observable<boolean> = ko.observable(false);
    public ddtsScroller: ko.Observable<boolean> = ko.observable(false);
    public estimatesScroller: ko.Observable<boolean> = ko.observable(false);

    constructor(private vatRegistersManager: IVatRegisters) {
        this.refreshLists();
        this.createLabelsMap();
        this.createErrorMessagesBuilders();
    }

    private createErrorMessagesBuilders() {
        this.errorMessagesBuilders = {};

        this.errorMessagesBuilders["GenericError"] = () => TextResources.ProlifeSdk.GenericError;
        this.errorMessagesBuilders["ProtocolNotFound"] = this.genericErrorMessage.bind(this, "ProtocolNotFound");
        this.errorMessagesBuilders["MissingProtocolName"] = this.genericErrorMessage.bind(this, "MissingProtocolName");
        this.errorMessagesBuilders["InvalidDocumentLayout"] = this.genericErrorMessage.bind(
            this,
            "InvalidDocumentLayout"
        );
        this.errorMessagesBuilders["InvalidDocumentType"] = this.genericErrorMessage.bind(this, "InvalidDocumentType");
        this.errorMessagesBuilders["MissingInvoiceElectronicInvoiceType"] = this.genericErrorMessage.bind(
            this,
            "MissingInvoiceElectronicInvoiceType"
        );
        this.errorMessagesBuilders["MissingAccompanyingInvoiceElectronicInvoiceType"] = this.genericErrorMessage.bind(
            this,
            "MissingAccompanyingInvoiceElectronicInvoiceType"
        );
        this.errorMessagesBuilders["MissingDownPaymentElectronicInvoiceType"] = this.genericErrorMessage.bind(
            this,
            "MissingDownPaymentElectronicInvoiceType"
        );
        this.errorMessagesBuilders["MissingCreditNoteElectronicInvoiceType"] = this.genericErrorMessage.bind(
            this,
            "MissingCreditNoteElectronicInvoiceType"
        );
        this.errorMessagesBuilders["MissingProtocolDefaultMetadataId"] = this.genericErrorMessage.bind(
            this,
            "MissingProtocolDefaultMetadataId"
        );
        this.errorMessagesBuilders["DuplicatedProtocolDefaultMetadata"] = this.genericErrorMessage.bind(
            this,
            "DuplicatedProtocolDefaultMetadata"
        );
        this.errorMessagesBuilders["ProtocolAccessDenied"] = this.genericErrorMessage.bind(
            this,
            "ProtocolAccessDenied"
        );
        this.errorMessagesBuilders["MailRecipientEmailRequired"] = this.genericErrorMessage.bind(
            this,
            "MailRecipientEmailRequired"
        );
        this.errorMessagesBuilders["MailRecipientEmailInvalid"] = this.genericErrorMessage.bind(
            this,
            "MailRecipientEmailInvalid"
        );
        this.errorMessagesBuilders["InvalidImageType"] = this.genericErrorMessage.bind(this, "InvalidImageType");
        this.errorMessagesBuilders["MissingInvoiceTypes"] = this.generateMissingInvoicesTypesSelectionError.bind(this);
        this.errorMessagesBuilders["DocumentsOnDisabledInvoiceType"] =
            this.generateDocumentsOnDisabledInvoiceTypeError.bind(this);
    }

    private genericErrorMessage(errorCode: string, metadata: unknown): string {
        return !metadata
            ? TextResources.Invoices[errorCode]
            : String.format(TextResources.Invoices[errorCode], metadata);
    }

    private generateMissingInvoicesTypesSelectionError(metadata: MissingInvoiceTypesError): string {
        return String.format(TextResources.Invoices.MissingInvoiceTypes, metadata.protocolName);
    }

    private generateDocumentsOnDisabledInvoiceTypeError(metadata: DocumentsOnDisabledInvoiceTypeError): string {
        const docType = this.generateDocumentTypeLabel(metadata.documentType);
        return String.format(TextResources.Invoices.DocumentsOnDisabledInvoiceType, metadata.protocolName, docType);
    }

    private generateDocumentTypeLabel(documentType: InvoiceKey) {
        switch (documentType.entityCode) {
            case "DOC":
                return documentType.downPayment ? TextResources.Invoices.SALInvoice : TextResources.Invoices.Invoice;
            case "DOA":
                return TextResources.Invoices.AccompanyingInvoice;
            case "NDC":
                return TextResources.Invoices.CreditNote;
            default:
                return "N/A";
        }
    }

    public scrollToInvoices() {
        this.invoicesScroller.valueHasMutated();
    }

    public scrollToDDTs() {
        this.ddtsScroller.valueHasMutated();
    }

    public scrollToEstimates() {
        this.estimatesScroller.valueHasMutated();
    }

    public remove(vatRegister: BaseVatRegisterViewModel) {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Invoices.DeleteProtocolMsg,
            ProlifeSdk.TextResources.Invoices.No,
            ProlifeSdk.TextResources.Invoices.Yes,
            (result) => {
                if (!result) return;
                this.deletedElements.push(vatRegister);
                this.vatRegisters.remove(vatRegister);
                this.getTypedArrayFor(vatRegister).remove(vatRegister);
            }
        );
    }

    public async updateAll(): Promise<void> {
        let errors: string[] = [];

        this.vatRegisters().forEach((v: BaseVatRegisterViewModel) => {
            const validationErrors = v
                .validate()
                .filter((v) => !v.valid)
                .map((v) => v.message);
            errors = errors.concat(validationErrors);
        });

        if (errors.length != 0) {
            this.infoToast.Warning(errors.join("<br/>"));
            return;
        }

        const vatRegistersToDelete = this.deletedElements.filter(
            (vatRegister: BaseVatRegisterViewModel) => !vatRegister.isNew()
        );
        const vatRegistersToUpdate = this.vatRegisters().filter((vatRegister: BaseVatRegisterViewModel) =>
            vatRegister.isChanged()
        );

        if (vatRegistersToDelete.length == 0 && vatRegistersToUpdate.length == 0) {
            this.infoToast.Info(ProlifeSdk.TextResources.Invoices.NoChangesToBeSaved);
            return;
        }

        const deletePromises = vatRegistersToDelete.map((vatRegister: BaseVatRegisterViewModel) =>
            this.vatRegistersManager.remove(vatRegister.getData().IdRegistroIVA)
        );

        const updatePromises = vatRegistersToUpdate.map((vatRegister: BaseVatRegisterViewModel) =>
            this.vatRegistersManager.createOrUpdate(
                vatRegister.getData(),
                vatRegister.getImagesInfo(),
                vatRegister.printTemplates().map((pt) => pt.id),
                vatRegister.preferredPrintTemplateId()
            )
        );

        try {
            await Promise.all(deletePromises.concat(updatePromises));
            this.onSaveCompleted();
        } catch (e) {
            this.infoToast.Error(this.createErrorMessage(e));
            return;
        }
    }

    private createErrorMessage(e: Error): string {
        if (e instanceof SaveProtocolError) {
            return e.errors.map((err) => this.errorMessagesBuilders[err.code](err.metadata)).join("<br/>");
        }

        return e.message;
    }

    getVatRegistersByGroupLabel(label: string): IVatRegister[] {
        return this.vatRegisters()
            .filter(
                (v: BaseVatRegisterViewModel) =>
                    (!v.GroupLabel() ? "" : v.GroupLabel()).toLocaleLowerCase().trim() ==
                    (!label ? "" : label).toLowerCase().trim()
            )
            .map((v: BaseVatRegisterViewModel) => v.getData())
            .sort((vatA: IVatRegister, vatB: IVatRegister) => vatA.PositionInList - vatB.PositionInList);
    }

    private onSaveCompleted() {
        this.showPositionNumberConflictMessage = false;
        this.infoToast.Success(ProlifeSdk.TextResources.Invoices.ChangesSaved);
        this.refreshLists();
    }

    public refreshLists() {
        this.vatRegisters.removeAll();
        this.deletedElements = [];

        const registers = this.vatRegistersManager.getVatRegisters();
        registers.forEach((r) => this.createViewModelFor(r, r.printTemplates, r.preferredPrintTemplateId));

        this.reloadTypedLists();

        this.showPositionNumberConflictMessage = true;
    }

    private createViewModelFor(
        vatRegister: IVatRegister,
        printTemplates: number[],
        preferredPrintTemplateId: number
    ): BaseVatRegisterViewModel {
        const constructor = this.viewModelFactory[vatRegister.TipoDocumento] || CustomProtocolViewModel;
        const viewModel: BaseVatRegisterViewModel = <BaseVatRegisterViewModel>(
            new constructor(this, vatRegister, printTemplates, preferredPrintTemplateId)
        );
        this.vatRegisters.push(viewModel);
        return viewModel;
    }

    private reloadTypedLists() {
        this.invoiceElements(
            <InvoiceVatRegisterViewModel[]>(
                this.getListByType(
                    (vatRegister: BaseVatRegisterViewModel) => vatRegister instanceof InvoiceVatRegisterViewModel
                )
            )
        );
        this.ddtElements(
            <DDTVatRegisterViewModel[]>(
                this.getListByType(
                    (vatRegister: BaseVatRegisterViewModel) => vatRegister instanceof DDTVatRegisterViewModel
                )
            )
        );
        this.preventiviElements(
            <EstimateVatRegisterViewModel[]>(
                this.getListByType(
                    (vatRegister: BaseVatRegisterViewModel) => vatRegister instanceof EstimateVatRegisterViewModel
                )
            )
        );

        const customRegisters = [];
        this.vatRegistersManager.getCustomProtocolTypes().forEach((t: IProtocolType) => {
            const protocols: CustomProtocolViewModel[] = <CustomProtocolViewModel[]>(
                this.getListByType(
                    (vatRegister: BaseVatRegisterViewModel) => vatRegister instanceof CustomProtocolViewModel
                ).filter((r: BaseVatRegisterViewModel) => r.DocumentType == t.DcoumentTypeId)
            );
            const customTypeViewModel: CustomTypeProtocolsViewModel = new CustomTypeProtocolsViewModel(t, this);
            customTypeViewModel.Protocols(protocols);
            customRegisters.push(customTypeViewModel);
        });
        this.customProtocolsTypes(customRegisters);

        this.CanEditIvaRegisters(this.authorizationService.isAuthorized("Documents_Invoices"));
        this.CanEditDDTProtocols(this.authorizationService.isAuthorized("Documents_DDTs"));
        this.CanEditEstimatesProtocols(this.authorizationService.isAuthorized("Documents_Estimates"));
    }

    private getListByType(filter: (vatRegister: BaseVatRegisterViewModel) => boolean): BaseVatRegisterViewModel[] {
        return this.vatRegisters()
            .filter(filter)
            .sort((left: BaseVatRegisterViewModel, right: BaseVatRegisterViewModel) => left.position - right.position);
    }

    public getPositionInTypedArray(vatRegister: BaseVatRegisterViewModel): number {
        const array = this.getTypedArrayFor(vatRegister);
        return array ? array.indexOf(vatRegister) : 0;
    }

    private getTypedArrayFor(vatRegister: BaseVatRegisterViewModel): ko.ObservableArray<BaseVatRegisterViewModel> {
        if (vatRegister instanceof InvoiceVatRegisterViewModel) return this.invoiceElements;
        if (vatRegister instanceof DDTVatRegisterViewModel) return this.ddtElements;
        if (vatRegister instanceof EstimateVatRegisterViewModel) return this.preventiviElements;
        let customRegister = null;
        this.customProtocolsTypes().forEach((t: CustomTypeProtocolsViewModel) => {
            customRegister = t.IsProtocolValidFor(<CustomProtocolViewModel>vatRegister) ? t.Protocols : customRegister;
        });
        return customRegister;
    }

    public createNewInvoiceVatRegister() {
        if (!this.newInvoiceVatRegister()) return;

        const vatRegister = this.createNewVatRegisterOfType(0);
        vatRegister.NomeRegistroIVA = this.newInvoiceVatRegister();

        const defaultElectronicInvoiceTypes = this.invoicesService.GetElectronicInvoiceDefaultTypes();

        vatRegister.InvoiceElectronicInvoiceType = defaultElectronicInvoiceTypes.Invoice;
        vatRegister.AccompanyingInvoiceElectronicInvoiceType = defaultElectronicInvoiceTypes.AccompanyingInvoice;
        vatRegister.CreditNoteElectronicInvoiceType = defaultElectronicInvoiceTypes.CreditNote;
        vatRegister.DownPaymentElectronicInvoiceType = defaultElectronicInvoiceTypes.DownPayment;

        this.addNewVatRegister(vatRegister);
        this.newInvoiceVatRegister("");
    }

    public createNewDDTVatRegister() {
        if (!this.newDDTVatRegister()) return;

        const vatRegister = this.createNewVatRegisterOfType(1);
        vatRegister.NomeRegistroIVA = this.newDDTVatRegister();
        this.addNewVatRegister(vatRegister);
        this.newDDTVatRegister("");
    }

    public createNewEstimateVatRegister() {
        if (!this.newEstimateVatRegister()) return;

        const vatRegister = this.createNewVatRegisterOfType(3);
        vatRegister.NomeRegistroIVA = this.newEstimateVatRegister();
        this.addNewVatRegister(vatRegister);
        this.newEstimateVatRegister("");
    }

    private addNewVatRegister(vatRegister: IVatRegister) {
        const vatRegisterVM = this.createViewModelFor(vatRegister, [], null);
        const typedArray = this.getTypedArrayFor(vatRegisterVM);

        vatRegisterVM.setInitialPositionInList((typedArray() || []).length + 1);
        typedArray.push(vatRegisterVM);
    }

    public createNewCustomProtocol(documentType: number, description: string): boolean {
        if (!description) return false;

        const vatRegister = this.createNewVatRegisterOfType(documentType);
        vatRegister.NomeRegistroIVA = description;
        this.addNewVatRegister(vatRegister);
        return true;
    }

    private createLabelsMap(): void {
        this.documentsLabelsMap["_" + ProlifeSdk.InvoiceOrDdtTypeId] = ProlifeSdk.TextResources.Invoices.Invoice;
        this.documentsLabelsMap["_" + ProlifeSdk.DdtTypeId] = ProlifeSdk.TextResources.Invoices.Delivery;
        this.documentsLabelsMap["_" + ProlifeSdk.EstimateTypeId] = ProlifeSdk.TextResources.Invoices.Quote;
        this.documentsLabelsMap["_" + ProlifeSdk.CustomerOrderTypeId] =
            ProlifeSdk.TextResources.Warehouse.CustomerOrder;
        this.documentsLabelsMap["_" + ProlifeSdk.SalTypeId] = ProlifeSdk.TextResources.Invoices.Sal;
        this.documentsLabelsMap["_" + ProlifeSdk.SupplierOrderTypeId] =
            ProlifeSdk.TextResources.Warehouse.SupplierOrder;
        this.documentsLabelsMap["_" + ProlifeSdk.WarehouseLoadTypeId] =
            ProlifeSdk.TextResources.Warehouse.WarehouseLoad;
        this.documentsLabelsMap["_" + ProlifeSdk.RequestForQuotationTypeId] =
            ProlifeSdk.TextResources.Provisioning.RequestsForQuotation;
        this.documentsLabelsMap["_" + ProlifeSdk.PurchaseRequestTypeId] =
            ProlifeSdk.TextResources.Provisioning.PurchaseRequest;
        this.documentsLabelsMap["_" + ProlifeSdk.PassiveInvoiceTypeId] =
            ProlifeSdk.TextResources.Invoices.PassiveInvoice;

        this.groupsLabelsMap["_" + ProlifeSdk.InvoiceOrDdtTypeId] =
            ProlifeSdk.TextResources.Invoices.InvoicesGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.DdtTypeId] = ProlifeSdk.TextResources.Invoices.DdtsGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.EstimateTypeId] = ProlifeSdk.TextResources.Invoices.EstimatesGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.CustomerOrderTypeId] =
            ProlifeSdk.TextResources.Invoices.CustomerOrdersGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.SalTypeId] = ProlifeSdk.TextResources.Invoices.SalsGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.SupplierOrderTypeId] =
            ProlifeSdk.TextResources.Invoices.SupplierOrdersGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.WarehouseLoadTypeId] =
            ProlifeSdk.TextResources.Invoices.WarehouseLoadsGroupLabel;
        this.groupsLabelsMap["_" + ProlifeSdk.RequestForQuotationTypeId] =
            ProlifeSdk.TextResources.Provisioning.RequestsForQuotations;
        this.groupsLabelsMap["_" + ProlifeSdk.PurchaseRequestTypeId] =
            ProlifeSdk.TextResources.Provisioning.PurchaseRequests;
        this.groupsLabelsMap["_" + ProlifeSdk.PassiveInvoiceTypeId] = ProlifeSdk.TextResources.Invoices.PassiveInvoices;

        this.documentsSalesCicleMap["_" + ProlifeSdk.InvoiceOrDdtTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.DdtTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.EstimateTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.CustomerOrderTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.SalTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.SupplierOrderTypeId] = false;
        this.documentsSalesCicleMap["_" + ProlifeSdk.WarehouseLoadTypeId] = false;
        this.documentsSalesCicleMap["_" + ProlifeSdk.RequestForQuotationTypeId] = false;
        this.documentsSalesCicleMap["_" + ProlifeSdk.PurchaseRequestTypeId] = true;
        this.documentsSalesCicleMap["_" + ProlifeSdk.PassiveInvoiceTypeId] = false;
    }

    private createNewVatRegisterOfType(tipoDocumento: number): IVatRegister {
        return {
            FKCompany: 1,
            NomeRegistroIVA: "",
            TipoDocumento: tipoDocumento,
            NuovoLabel: this.documentsLabelsMap["_" + tipoDocumento],
            Prefisso: "",
            Suffisso: "",
            Stato: 0,
            TipiFatture: "111",
            DefaultDocument: 0,
            DocumentoFiscale: 0,
            Posizione: 0,
            VisualizzaImporti: 0,
            ContributoSuCompenso: 0,
            ContributoSuImponibileIVA: 0,
            DefaultLayoutId: 1,
            AccountingSoftwareRoutes: [],
            PaymentAndExpireModeRequired: false,
            ValidityRequired: false,
            ImportCalculatedExpiriesIntoDocumentNotes: false,
            ExpiresIntoNotesPrefix: ProlifeSdk.TextResources.Invoices.ExpiresIntoNotesDefaultPrefix,
            ExpiresIntoNotesSuffix: ProlifeSdk.TextResources.Invoices.ExpiresIntoNotesDefaultSuffix,
            DefaultJobOrderFilteredByCustomer: false,
            DdtCanBeImportedToShippingInvoice: false,
            InvoiceCanBeImportedToInvoice: true,
            IsInventoryAdjustmentProtocol: false,
            CFRequired: true,
            DateSequenceRequired: true,
            InvoiceLabel: ProlifeSdk.TextResources.Invoices.Invoice,
            CreditNoteLabel: ProlifeSdk.TextResources.Invoices.CreditNoteShort,
            AccompanyingInvoiceLabel: ProlifeSdk.TextResources.Invoices.Invoice,
            InsertJobOrderIntoNotes: false,
            InsertOriginatingDocumentsIntoNotes: false,
            DefaultBlogTags: [],
            DefaultFileTags: [],
            ProtocolsDefaultValues: [],
            ProtocolsDefaultMetadatas: [],
            ForPartialInvoices: false,
            GroupLabel: this.groupsLabelsMap["_" + tipoDocumento],
            PositionInList: 1000,
            StampDutyValue: 0,
            StampDuty: false,
            EnableStampDuty: false,
            SalInvoiceLabel: ProlifeSdk.TextResources.Invoices.SALInvoices,
            ShowAllCustomersAndSuppliers: false,
            ShowOnlyJobOrderWarehouses: false,
            VersionNumberPrefix: null,
            VersionNumberSuffix: null,
            VersionNumberSeparator: null,
            RequireVersionRevision: false,
            ShowResourcesTable: false,
            SalesCicle: this.documentsSalesCicleMap["_" + tipoDocumento],
            mailRecipientsForDocumentsSendBcc: [],
            mailRecipientsForDocumentsSendCc: [],
            mailRecipientsForDocumentsSendTo: [],
        };
    }

    isChanged(): boolean {
        const vatRegistersToDelete = this.deletedElements.filter(
            (vatRegister: BaseVatRegisterViewModel) => !vatRegister.isNew()
        );
        const vatRegistersToUpdate = this.vatRegisters().filter((vatRegister: BaseVatRegisterViewModel) =>
            vatRegister.isChanged()
        );
        return vatRegistersToDelete.length != 0 || vatRegistersToUpdate.length != 0;
    }
}

export class BaseVatRegisterViewModel {
    hasFocus: ko.Computed<boolean>;
    withError: ko.Observable<boolean> = ko.observable(false);

    name: ko.Observable<string> = ko.observable();
    label: ko.Observable<string> = ko.observable();
    prefix: ko.Observable<string> = ko.observable();
    suffix: ko.Observable<string> = ko.observable();
    state: ko.Observable<number> = ko.observable();

    nameHasFocus: ko.Observable<boolean> = ko.observable();
    labelHasFocus: ko.Observable<boolean> = ko.observable();
    prefixHasFocus: ko.Observable<boolean> = ko.observable();
    suffixHasFocus: ko.Observable<boolean> = ko.observable();

    public CFRequired: ko.Observable<boolean> = ko.observable();
    public DateSequenceRequired: ko.Observable<boolean> = ko.observable();
    public DocumentType: number = null;
    position: number;

    public GroupLabel: ko.Observable<string> = ko.observable();
    public PositionInList: ko.Observable<number> = ko.observable();

    public VersionNumberPrefix: ko.Observable<string> = ko.observable();
    public VersionNumberSuffix: ko.Observable<string> = ko.observable();
    public VersionNumberSeparator: ko.Observable<string> = ko.observable();
    public RequireVersionRevision: ko.Observable<boolean> = ko.observable(false);
    public ShowResourcesTable: ko.Observable<boolean> = ko.observable(false);
    public CanHaveResourcesTable: ko.Observable<boolean> = ko.observable(false);

    public advancedViewModel: VatRegisterAdvancedSettings;
    public AdvancedConfigurationsAreShowed: ko.Observable<boolean> = ko.observable(false);

    scroller: ko.Observable<boolean> = ko.observable(false);

    public DefaultValuesSettings: ko.Observable<IProtocolDefaultValuesSettingsUi> = ko.observable(null);
    private initialDefaultData: string;

    defaultSearchProvider: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDocumentsService>())
    protected documentsService: IDocumentsService;
    @LazyImport(nameof<IValidationService>())
    protected validationService: IValidationService;
    @LazyImportSettingManager(nameof<IPrintTemplateSettingsManager>())
    private printTemplateSettingsManager: IPrintTemplateSettingsManager;

    public printTemplates: ko.ObservableArray<IPrintTemplate> = ko.observableArray();
    public preferredPrintTemplateId: ko.Observable<number> = ko.observable();
    public printTemplatesSelector: IView;

    protected validator: IValidator<BaseVatRegisterViewModel>;

    constructor(
        protected container: IVatRegisterEditingContainer,
        protected vatRegister: IVatRegister,
        protected registerPrintTemplates: number[],
        protected registerPreferredPrintTemplateId: number
    ) {
        this.DocumentType = this.vatRegister.TipoDocumento;

        this.hasFocus = ko.computed({
            read: this.hasFocusCallback.bind(this),
            deferEvaluation: true,
        });

        registerPrintTemplates = registerPrintTemplates || [];

        this.printTemplates(
            this.printTemplateSettingsManager
                .getPrintTemplates()
                .filter((pt) => registerPrintTemplates.indexOf(pt.id) != -1)
        );
        this.preferredPrintTemplateId(registerPreferredPrintTemplateId);

        this.printTemplatesSelector = {
            templateName: null,
            templateUrl: null,
            viewModel: null,
            component: null,
            render: () =>
                React.createElement(PrintTemplatesSelector, {
                    printTemplates: this.printTemplates,
                    preferredPrintTemplateId: this.preferredPrintTemplateId,
                }),
        };

        this.advancedViewModel = new VatRegisterAdvancedSettings(vatRegister, this.name);
        this.advancedViewModel.ShowJobOrderWarehousesFlag(
            this.vatRegister.TipoDocumento == ProlifeSdk.DdtTypeId ||
                this.vatRegister.TipoDocumento == ProlifeSdk.InvoiceOrDdtTypeId ||
                this.vatRegister.TipoDocumento == ProlifeSdk.CustomerOrderTypeId ||
                this.vatRegister.TipoDocumento == ProlifeSdk.WarehouseLoadTypeId
        );

        this.LoadDefaultSettings();
        this.ConfigureValidations();
    }

    scrollTo(): void {
        this.scroller.valueHasMutated();
    }

    validate(): IValidation[] {
        return this.validator.validate(this);
    }

    public setInitialPositionInList(pos: number): void {
        this.vatRegister.PositionInList = pos;
        this.PositionInList(pos);
    }

    private ConfigureValidations(): void {
        this.validator = this.validationService
            .createValidator<BaseVatRegisterViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(
                (v) => v.label(),
                TextResources.Invoices.DocumentLabelRequired,
                () => this.vatRegister.TipoDocumento != 0
            )
            .isTrue(
                (v) => !v.DefaultValuesSettings().validateMetadata(),
                () =>
                    String.format(
                        TextResources.ProlifeSdk.MetadataError,
                        this.name(),
                        TextResources.ProlifeSdk.MissingMetadataError
                    ),
                (v) => !!v.DefaultValuesSettings()
            );

        let lastPositionInList: number = this.PositionInList();
        let onValidation = false;
        this.PositionInList.subscribe((position: number) => {
            if (onValidation) return;

            onValidation = true;

            if (position < 1) {
                this.PositionInList(lastPositionInList);
                this.infoToastService.Warning(
                    String.format(ProlifeSdk.TextResources.Invoices.PositionIndexError, this.name())
                );
                onValidation = false;
                return;
            }

            const vatRegisters: IVatRegister[] = this.container
                .getVatRegistersByGroupLabel(this.GroupLabel())
                .filter((v: IVatRegister) => v.PositionInList == position && v.NomeRegistroIVA != this.name());
            const invalidPos: boolean = vatRegisters.length > 0;

            if (invalidPos && this.container.showPositionNumberConflictMessage)
                this.infoToastService.Warning(
                    String.format(
                        ProlifeSdk.TextResources.Invoices.PositionIndexAlreadyInUse,
                        this.name(),
                        vatRegisters[0].NomeRegistroIVA
                    )
                );

            lastPositionInList = position;
            onValidation = false;
        });
    }

    LoadDefaultSettings() {
        const matches: IDocumentsProvider[] = this.documentsService
            .getRegisteredDocumentProviders()
            .filter((p: IDocumentsProvider) => {
                return p.Id == this.vatRegister.IdRegistroIVA;
            });
        this.DefaultValuesSettings(matches.length == 0 ? null : matches[0].GetDefaultValuesSettingsUi());

        if (!this.DefaultValuesSettings()) return;

        this.DefaultValuesSettings().ShowEnableCheckbox(false);
        this.DefaultValuesSettings().ShowEnableMetadatasCheckbox(false);
        this.DefaultValuesSettings().LoadSettings(this.vatRegister.ProtocolsDefaultValues, true);
        this.DefaultValuesSettings().LoadMetadata(this.vatRegister.ProtocolsDefaultMetadatas, true);
        this.DefaultValuesSettings().loadMailRecipientTypes(
            this.vatRegister.mailRecipientsForDocumentsSendTo,
            this.vatRegister.mailRecipientsForDocumentsSendCc,
            this.vatRegister.mailRecipientsForDocumentsSendBcc
        );
        this.DefaultValuesSettings().SetEditMode(true);
        this.initialDefaultData = this.stringifySettings(this.DefaultValuesSettings());
    }

    private stringifySettings(settingsUI: IProtocolDefaultValuesSettingsUi): string {
        if (!settingsUI) return "";

        return JSON.stringify({
            settings: settingsUI.GetSettings(),
            metadata: settingsUI.GetMetadata(),
            toMailRecipients: settingsUI.getMailRecipientsForDocumentsSendTo(),
            ccMailRecipients: settingsUI.getMailRecipientsForDocumentsSendCc(),
            bccMailRecipients: settingsUI.getMailRecipientsForDocumentsSendBcc(),
        });
    }

    SwitchAdvancedVisibility() {
        this.AdvancedConfigurationsAreShowed(!this.AdvancedConfigurationsAreShowed());
    }

    erase() {
        this.container.remove(this);
    }

    getData(): IVatRegister {
        const vatRegister: IVatRegister = Object.assign({}, this.vatRegister);
        vatRegister.Posizione = this.container.getPositionInTypedArray(this);
        vatRegister.NomeRegistroIVA = this.name();
        vatRegister.NuovoLabel = this.label();
        vatRegister.Prefisso = this.prefix();
        vatRegister.Suffisso = this.suffix();
        vatRegister.Stato = this.state();
        vatRegister.ProtocolsDefaultValues = this.DefaultValuesSettings()
            ? this.DefaultValuesSettings().GetSettings()
            : [];
        vatRegister.ProtocolsDefaultMetadatas = this.DefaultValuesSettings()
            ? this.DefaultValuesSettings().GetMetadata()
            : [];
        vatRegister.mailRecipientsForDocumentsSendTo = this.DefaultValuesSettings()
            ? this.DefaultValuesSettings().getMailRecipientsForDocumentsSendTo()
            : [];
        vatRegister.mailRecipientsForDocumentsSendCc = this.DefaultValuesSettings()
            ? this.DefaultValuesSettings().getMailRecipientsForDocumentsSendCc()
            : [];
        vatRegister.mailRecipientsForDocumentsSendBcc = this.DefaultValuesSettings()
            ? this.DefaultValuesSettings().getMailRecipientsForDocumentsSendBcc()
            : [];
        vatRegister.DefaultSearchProvider = this.defaultSearchProvider();
        vatRegister.CFRequired = this.CFRequired();
        vatRegister.DateSequenceRequired = this.DateSequenceRequired();
        vatRegister.GroupLabel = this.GroupLabel();
        vatRegister.PositionInList = this.PositionInList();
        vatRegister.VersionNumberPrefix = this.VersionNumberPrefix();
        vatRegister.VersionNumberSuffix = this.VersionNumberSuffix();
        vatRegister.VersionNumberSeparator = this.VersionNumberSeparator();
        vatRegister.RequireVersionRevision = this.RequireVersionRevision();
        vatRegister.ShowResourcesTable = this.CanHaveResourcesTable() ? this.ShowResourcesTable() : false;
        vatRegister.VisualizzaImporti = 1;

        this.advancedViewModel.ApplyChangesOnRegister(vatRegister);

        return vatRegister;
    }

    getImagesInfo(): IVatRegisterImagesInfo {
        return this.advancedViewModel.GetImagesInfo();
    }

    load() {
        this.position = this.vatRegister.Posizione;
        this.name(this.vatRegister.NomeRegistroIVA);
        this.label(this.vatRegister.NuovoLabel);
        this.prefix(this.vatRegister.Prefisso);
        this.suffix(this.vatRegister.Suffisso);
        this.state(this.vatRegister.Stato);
        this.defaultSearchProvider(this.vatRegister.DefaultSearchProvider || undefined);
        this.CFRequired(this.vatRegister.CFRequired);
        this.DateSequenceRequired(this.vatRegister.DateSequenceRequired);
        this.GroupLabel(this.vatRegister.GroupLabel);
        this.PositionInList(this.vatRegister.PositionInList);
        this.VersionNumberPrefix(this.vatRegister.VersionNumberPrefix);
        this.VersionNumberSuffix(this.vatRegister.VersionNumberSuffix);
        this.VersionNumberSeparator(this.vatRegister.VersionNumberSeparator);
        this.RequireVersionRevision(this.vatRegister.RequireVersionRevision);
        this.ShowResourcesTable(this.vatRegister.ShowResourcesTable);

        if (!this.vatRegister.PositionInList) {
            const vatRegisters: IVatRegister[] = this.container.getVatRegistersByGroupLabel(this.GroupLabel());
            const lastPos: number = vatRegisters.length == 0 ? 0 : vatRegisters[vatRegisters.length - 1].PositionInList;
            this.PositionInList(lastPos + 1);
        }
    }

    hasFocusCallback(): boolean {
        return this.nameHasFocus() || this.labelHasFocus() || this.prefixHasFocus() || this.suffixHasFocus();
    }

    isChanged(): boolean {
        if (!this.vatRegister.IdRegistroIVA) return true;

        let isChanged = false;
        const currentData = this.getData();
        isChanged = isChanged || this.vatRegister.Posizione != currentData.Posizione;
        isChanged = isChanged || this.vatRegister.NomeRegistroIVA != currentData.NomeRegistroIVA;
        isChanged = isChanged || this.vatRegister.NuovoLabel != currentData.NuovoLabel;
        isChanged = isChanged || this.vatRegister.Prefisso != currentData.Prefisso;
        isChanged = isChanged || this.vatRegister.Suffisso != currentData.Suffisso;
        isChanged = isChanged || this.vatRegister.Stato != currentData.Stato;
        isChanged = isChanged || this.vatRegister.DefaultSearchProvider != currentData.DefaultSearchProvider;
        isChanged = isChanged || this.vatRegister.CFRequired != currentData.CFRequired;
        isChanged = isChanged || this.vatRegister.DateSequenceRequired != currentData.DateSequenceRequired;
        isChanged = isChanged || this.vatRegister.GroupLabel != currentData.GroupLabel;
        isChanged = isChanged || this.vatRegister.PositionInList != currentData.PositionInList;
        isChanged = isChanged || this.vatRegister.VersionNumberPrefix != currentData.VersionNumberPrefix;
        isChanged = isChanged || this.vatRegister.VersionNumberSuffix != currentData.VersionNumberSuffix;
        isChanged = isChanged || this.vatRegister.VersionNumberSeparator != currentData.VersionNumberSeparator;
        isChanged = isChanged || this.vatRegister.RequireVersionRevision != currentData.RequireVersionRevision;
        isChanged = isChanged || this.vatRegister.ShowResourcesTable != currentData.ShowResourcesTable;
        isChanged =
            isChanged ||
            JSON.stringify(this.registerPrintTemplates) !== JSON.stringify(this.printTemplates().map((pt) => pt.id));
        isChanged = isChanged || this.registerPreferredPrintTemplateId !== this.preferredPrintTemplateId();

        if (this.DefaultValuesSettings())
            isChanged = isChanged || this.initialDefaultData != this.stringifySettings(this.DefaultValuesSettings());

        return isChanged || this.advancedViewModel.SomeIsChanged();
    }

    isNew(): boolean {
        return !this.vatRegister.IdRegistroIVA;
    }
}

class InvoiceVatRegisterViewModel extends BaseVatRegisterViewModel {
    perFatture: ko.Observable<boolean> = ko.observable();
    perNoteDiCredito: ko.Observable<boolean> = ko.observable();
    perFattureAccompagnatorie: ko.Observable<boolean> = ko.observable();
    perFattureAcconto: ko.Observable<boolean> = ko.observable();

    defaultFatture: ko.Observable<boolean> = ko.observable();
    defaultNoteDiCredito: ko.Observable<boolean> = ko.observable();
    defaultFattureAccompagnatorie: ko.Observable<boolean> = ko.observable();
    defaultFattureAcconto: ko.Observable<boolean> = ko.observable();

    InvoiceElectronicInvoiceType: ko.Observable<string> = ko.observable();
    CreditNoteElectronicInvoiceType: ko.Observable<string> = ko.observable();
    DownPaymentElectronicInvoiceType: ko.Observable<string> = ko.observable();
    AccompanyingInvoiceElectronicInvoiceType: ko.Observable<string> = ko.observable();

    ElectronicInvoiceTypesDataSource = new ElectronicInvoiceTypesDataSource();

    descContributoImponibile: ko.Observable<string> = ko.observable();
    contributoImponibile: ko.Observable<number> = ko.observable();
    descContributoCompenso: ko.Observable<string> = ko.observable();
    contributoCompenso: ko.Observable<number> = ko.observable();
    DdtCanBeImportedToShippingInvoice: ko.Observable<boolean> = ko.observable(false);
    InvoiceCanBeImportedToInvoice: ko.Observable<boolean> = ko.observable(false);

    PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable(false);

    descContributoImponibileHasFocus: ko.Observable<boolean> = ko.observable();
    contributoImponibileHasFocus: ko.Observable<boolean> = ko.observable();
    descContributoCompensoHasFocus: ko.Observable<boolean> = ko.observable();
    contributoCompensoHasFocus: ko.Observable<boolean> = ko.observable();

    InvoiceLabel: ko.Observable<string> = ko.observable();
    CreditNoteLabel: ko.Observable<string> = ko.observable();
    AccompanyingInvoiceLabel: ko.Observable<string> = ko.observable();
    SalInvoiceLabel: ko.Observable<string> = ko.observable();

    ForPartialInvoices: ko.Observable<boolean> = ko.observable();
    LockPartialInvoicesFlag: ko.Observable<boolean> = ko.observable(true);

    StampDuty: ko.Observable<boolean> = ko.observable();
    StampDutyValue: ko.Observable<number> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    private loading = false;

    constructor(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister,
        registerPrintTemplates: number[],
        registerPreferredPrintTemplateId: number
    ) {
        super(container, vatRegister, registerPrintTemplates, registerPreferredPrintTemplateId);
        this.loading = true;

        this.documentsService
            .getDocumentsNumberForProtocol(this.vatRegister.IdRegistroIVA)
            .then((docsNumber: number) => {
                this.LockPartialInvoicesFlag(docsNumber > 0);
            });

        this.load();
        this.perFattureAccompagnatorie.subscribe((v) => {
            this.DdtCanBeImportedToShippingInvoice(v ? this.DdtCanBeImportedToShippingInvoice() : false);
        });

        let onPartialInvoicesFlagChanges = false;
        this.ForPartialInvoices.subscribe((val: boolean) => {
            if (this.loading || onPartialInvoicesFlagChanges || !this.LockPartialInvoicesFlag()) return;

            onPartialInvoicesFlagChanges = true;

            if (!this.authorizationsService.isAuthorized("Documents_ChangeVatRegisterForPartialInvoicesFlag")) {
                this.ForPartialInvoices(!val);
                this.dialogsService.Alert(
                    ProlifeSdk.TextResources.Invoices.OnVatRegisterForPartialInvoicesChangesBlockingMessage,
                    ProlifeSdk.TextResources.Invoices.OnVatRegisterForPartialInvoicesChangesBlockingMessageLabel,
                    () => {
                        onPartialInvoicesFlagChanges = false;
                    }
                );
                return;
            }

            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Invoices.OnVatRegisterForPartialInvoicesChangesWarningMessage,
                ProlifeSdk.TextResources.Invoices.OnVatRegisterForPartialInvoicesChangesBlockingMessageCancel,
                ProlifeSdk.TextResources.Invoices.OnVatRegisterForPartialInvoicesChangesBlockingMessageConfirm,
                (confirm: boolean) => {
                    if (!confirm) this.ForPartialInvoices(!val);

                    onPartialInvoicesFlagChanges = false;
                }
            );
        });

        this.perFattureAccompagnatorie.subscribe((val) => {
            this.advancedViewModel.ShowJobOrderWarehousesFlag(val);
        });

        this.advancedViewModel.ShowJobOrderWarehousesFlag(this.perFattureAccompagnatorie());

        this.configureValidations();
        this.loading = false;
    }

    private configureValidations(): void {
        this.validator = this.validationService
            .createValidator<InvoiceVatRegisterViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(
                (v) => v.InvoiceLabel(),
                TextResources.Invoices.InvoiceLabelRequired,
                () => this.perFatture()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (v) => v.CreditNoteLabel(),
                TextResources.Invoices.CreditNoteLabelRequired,
                () => this.perNoteDiCredito()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (v) => v.AccompanyingInvoiceLabel(),
                TextResources.Invoices.AccompanyingInvoiceLabelRequired,
                () => this.perFattureAccompagnatorie()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (v) => v.SalInvoiceLabel(),
                TextResources.Invoices.SalInvoiceLabelRequired,
                () => this.perFattureAcconto()
            )
            .isNotNullOrUndefined(
                (v) => v.StampDutyValue(),
                String.format(TextResources.Invoices.StampDutyRequired, this.name()),
                () => this.StampDuty() && this.StampDutyValue() <= 0
            )
            .isNotNullOrUndefined(
                (v) => v.InvoiceElectronicInvoiceType(),
                String.format(TextResources.Invoices.InvoiceElectronicInvoiceTypeRequired, this.name())
            )
            .isNotNullOrUndefined(
                (v) => v.AccompanyingInvoiceElectronicInvoiceType(),
                String.format(TextResources.Invoices.AccompanyingInvoiceElectronicInvoiceTypeRequired, this.name())
            )
            .isNotNullOrUndefined(
                (v) => v.DownPaymentElectronicInvoiceType(),
                String.format(TextResources.Invoices.DownPaymentElectronicInvoiceTypeRequired, this.name())
            )
            .isNotNullOrUndefined(
                (v) => v.CreditNoteElectronicInvoiceType(),
                String.format(TextResources.Invoices.CreditNoteElectronicInvoiceTypeRequired, this.name())
            );
    }

    hasFocusCallback(): boolean {
        return (
            super.hasFocusCallback() ||
            this.descContributoCompensoHasFocus() ||
            this.contributoCompensoHasFocus() ||
            this.descContributoImponibileHasFocus() ||
            this.contributoImponibileHasFocus()
        );
    }

    isChanged(): boolean {
        let isChanged = super.isChanged();
        const currentData = this.getData();
        isChanged = isChanged || this.vatRegister.TipiFatture != currentData.TipiFatture;
        isChanged = isChanged || this.vatRegister.DefaultDocument != currentData.DefaultDocument;
        isChanged = isChanged || this.vatRegister.DescContributoSuCompenso != currentData.DescContributoSuCompenso;
        isChanged = isChanged || this.vatRegister.ContributoSuCompenso != currentData.ContributoSuCompenso;
        isChanged =
            isChanged || this.vatRegister.DescContributoSuImponibileIVA != currentData.DescContributoSuImponibileIVA;
        isChanged = isChanged || this.vatRegister.ContributoSuImponibileIVA != currentData.ContributoSuImponibileIVA;
        isChanged =
            isChanged ||
            this.vatRegister.DdtCanBeImportedToShippingInvoice != currentData.DdtCanBeImportedToShippingInvoice;
        isChanged =
            isChanged || this.vatRegister.InvoiceCanBeImportedToInvoice != currentData.InvoiceCanBeImportedToInvoice;
        isChanged =
            isChanged || this.vatRegister.PaymentAndExpireModeRequired != currentData.PaymentAndExpireModeRequired;
        isChanged = isChanged || this.vatRegister.InvoiceLabel != currentData.InvoiceLabel;
        isChanged = isChanged || this.vatRegister.CreditNoteLabel != currentData.CreditNoteLabel;
        isChanged = isChanged || this.vatRegister.AccompanyingInvoiceLabel != currentData.AccompanyingInvoiceLabel;
        isChanged = isChanged || this.vatRegister.SalInvoiceLabel != currentData.SalInvoiceLabel;
        isChanged = isChanged || this.vatRegister.ForPartialInvoices != currentData.ForPartialInvoices;
        isChanged = isChanged || this.vatRegister.StampDutyValue != currentData.StampDutyValue;
        isChanged = isChanged || this.vatRegister.StampDuty != currentData.StampDuty;
        isChanged =
            isChanged ||
            (this.vatRegister.InvoiceElectronicInvoiceType || "") != (currentData.InvoiceElectronicInvoiceType || "");
        isChanged =
            isChanged ||
            (this.vatRegister.CreditNoteElectronicInvoiceType || "") !=
                (currentData.CreditNoteElectronicInvoiceType || "");
        isChanged =
            isChanged ||
            (this.vatRegister.DownPaymentElectronicInvoiceType || "") !=
                (currentData.DownPaymentElectronicInvoiceType || "");
        isChanged =
            isChanged ||
            (this.vatRegister.AccompanyingInvoiceElectronicInvoiceType || "") !=
                (currentData.AccompanyingInvoiceElectronicInvoiceType || "");
        return isChanged;
    }

    getData(): IVatRegister {
        const vatRegister = super.getData();
        vatRegister.TipiFatture =
            (this.perFatture() ? "1" : "0") +
            (this.perNoteDiCredito() ? "1" : "0") +
            (this.perFattureAccompagnatorie() ? "1" : "0") +
            (this.perFattureAcconto() ? "1" : "0");

        vatRegister.DefaultDocument = this.defaultFatture()
            ? 0
            : this.defaultNoteDiCredito()
            ? 1
            : this.defaultFattureAccompagnatorie()
            ? 2
            : this.defaultFattureAcconto()
            ? 3
            : 0;
        vatRegister.DescContributoSuCompenso = this.descContributoCompenso();
        vatRegister.ContributoSuCompenso = this.contributoCompenso() || 0;
        vatRegister.DescContributoSuImponibileIVA = this.descContributoImponibile();
        vatRegister.ContributoSuImponibileIVA = this.contributoImponibile() || 0;
        vatRegister.DdtCanBeImportedToShippingInvoice = this.DdtCanBeImportedToShippingInvoice();
        vatRegister.InvoiceCanBeImportedToInvoice = this.InvoiceCanBeImportedToInvoice();
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        vatRegister.InvoiceLabel = this.InvoiceLabel();
        vatRegister.CreditNoteLabel = this.CreditNoteLabel();
        vatRegister.AccompanyingInvoiceLabel = this.AccompanyingInvoiceLabel();
        vatRegister.SalInvoiceLabel = this.SalInvoiceLabel();
        vatRegister.ForPartialInvoices = this.ForPartialInvoices();
        vatRegister.StampDuty = this.StampDuty();
        vatRegister.StampDutyValue = this.StampDutyValue();
        vatRegister.InvoiceElectronicInvoiceType = this.InvoiceElectronicInvoiceType();
        vatRegister.CreditNoteElectronicInvoiceType = this.CreditNoteElectronicInvoiceType();
        vatRegister.DownPaymentElectronicInvoiceType = this.DownPaymentElectronicInvoiceType();
        vatRegister.AccompanyingInvoiceElectronicInvoiceType = this.AccompanyingInvoiceElectronicInvoiceType();

        return vatRegister;
    }

    load() {
        super.load();
        this.perFatture(this.vatRegister.TipiFatture.substring(0, 1) == "1");
        this.perNoteDiCredito(this.vatRegister.TipiFatture.substring(1, 2) == "1");
        this.perFattureAccompagnatorie(this.vatRegister.TipiFatture.substring(2, 3) == "1");
        this.perFattureAcconto(this.vatRegister.TipiFatture.substring(3, 4) == "1");

        this.InvoiceElectronicInvoiceType(this.vatRegister.InvoiceElectronicInvoiceType);
        this.CreditNoteElectronicInvoiceType(this.vatRegister.CreditNoteElectronicInvoiceType);
        this.DownPaymentElectronicInvoiceType(this.vatRegister.DownPaymentElectronicInvoiceType);
        this.AccompanyingInvoiceElectronicInvoiceType(this.vatRegister.AccompanyingInvoiceElectronicInvoiceType);

        this.defaultFatture(this.vatRegister.DefaultDocument == 0);
        this.defaultNoteDiCredito(this.vatRegister.DefaultDocument == 1);
        this.defaultFattureAccompagnatorie(this.vatRegister.DefaultDocument == 2);
        this.defaultFattureAcconto(this.vatRegister.DefaultDocument == 3);
        this.descContributoCompenso(this.vatRegister.DescContributoSuCompenso);
        this.contributoCompenso(this.vatRegister.ContributoSuCompenso || undefined);
        this.descContributoImponibile(this.vatRegister.DescContributoSuImponibileIVA);
        this.contributoImponibile(this.vatRegister.ContributoSuImponibileIVA || undefined);
        this.DdtCanBeImportedToShippingInvoice(this.vatRegister.DdtCanBeImportedToShippingInvoice);
        this.InvoiceCanBeImportedToInvoice(this.vatRegister.InvoiceCanBeImportedToInvoice);

        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);

        this.InvoiceLabel(this.vatRegister.InvoiceLabel);
        this.CreditNoteLabel(this.vatRegister.CreditNoteLabel);
        this.AccompanyingInvoiceLabel(this.vatRegister.AccompanyingInvoiceLabel);
        this.SalInvoiceLabel(this.vatRegister.SalInvoiceLabel);

        this.ForPartialInvoices(this.vatRegister.ForPartialInvoices);

        this.StampDuty(this.vatRegister.StampDuty);
        this.StampDutyValue(this.vatRegister.StampDutyValue);
    }

    public setInvoiceAsDefault() {
        if (!this.perFatture()) return;

        this.defaultFatture(true);
        this.defaultNoteDiCredito(false);
        this.defaultFattureAccompagnatorie(false);
        this.defaultFattureAcconto(false);
    }

    public setCreditNoteAsDefault() {
        if (!this.perNoteDiCredito()) return;

        this.defaultFatture(false);
        this.defaultNoteDiCredito(true);
        this.defaultFattureAccompagnatorie(false);
        this.defaultFattureAcconto(false);
    }

    public setAccompagnatoriaAsDefault() {
        if (!this.perFattureAccompagnatorie()) return;

        this.defaultFatture(false);
        this.defaultNoteDiCredito(false);
        this.defaultFattureAccompagnatorie(true);
        this.defaultFattureAcconto(false);
    }

    public setAccontoAsDefault() {
        if (!this.perFattureAcconto()) return;

        this.defaultFatture(false);
        this.defaultNoteDiCredito(false);
        this.defaultFattureAccompagnatorie(false);
        this.defaultFattureAcconto(true);
    }

    public activeFatture() {
        this.perFatture(!this.perFatture());
    }

    public activeNC() {
        this.perNoteDiCredito(!this.perNoteDiCredito());
    }

    public activeFattureAcc() {
        this.perFattureAccompagnatorie(!this.perFattureAccompagnatorie());
    }

    public activeFattureAcconto() {
        this.perFattureAcconto(!this.perFattureAcconto());
    }
}

class DDTVatRegisterViewModel extends BaseVatRegisterViewModel {
    fiscalDocument: ko.Observable<boolean> = ko.observable();
    showPrices: ko.Observable<boolean> = ko.observable();

    PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable(false);
    ExpireTypeRequired: ko.Observable<boolean> = ko.observable(false);

    constructor(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister,
        registerPrintTemplates: number[],
        registerPreferredPrintTemplateId: number
    ) {
        super(container, vatRegister, registerPrintTemplates, registerPreferredPrintTemplateId);
        this.load();
    }

    isChanged(): boolean {
        let isChanged = super.isChanged();
        const currentData = this.getData();
        isChanged = isChanged || this.vatRegister.DocumentoFiscale != currentData.DocumentoFiscale;
        isChanged = isChanged || this.vatRegister.VisualizzaImporti != currentData.VisualizzaImporti;
        isChanged =
            isChanged || this.vatRegister.PaymentAndExpireModeRequired != currentData.PaymentAndExpireModeRequired;
        isChanged =
            isChanged || this.vatRegister.InvoiceElectronicInvoiceType != currentData.InvoiceElectronicInvoiceType;
        return isChanged;
    }

    getData(): IVatRegister {
        const vatRegister = super.getData();

        vatRegister.DocumentoFiscale = this.fiscalDocument() ? 1 : 0;
        vatRegister.VisualizzaImporti = this.showPrices() ? 1 : 0;
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        return vatRegister;
    }

    load() {
        super.load();
        this.fiscalDocument(this.vatRegister.DocumentoFiscale == 1);
        this.showPrices(this.vatRegister.VisualizzaImporti == 1);
        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);
    }

    public toggleFiscalDocument() {
        this.fiscalDocument(!this.fiscalDocument());
    }

    public toggleShowPrices() {
        this.showPrices(!this.showPrices());
    }
}

class EstimateVatRegisterViewModel extends BaseVatRegisterViewModel {
    PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable(false);
    ValidityRequired: ko.Observable<boolean> = ko.observable(false);

    constructor(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister,
        registerPrintTemplates: number[],
        registerPreferredPrintTemplateId: number
    ) {
        super(container, vatRegister, registerPrintTemplates, registerPreferredPrintTemplateId);
        this.load();
    }

    load() {
        super.load();

        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);
        this.ValidityRequired(this.vatRegister.ValidityRequired);
    }

    isChanged(): boolean {
        let isChanged = super.isChanged();
        const currentData = this.getData();
        isChanged =
            isChanged || this.vatRegister.PaymentAndExpireModeRequired != currentData.PaymentAndExpireModeRequired;
        isChanged = isChanged || this.vatRegister.ValidityRequired != currentData.ValidityRequired;

        return isChanged;
    }

    getData(): IVatRegister {
        const vatRegister = super.getData();
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        vatRegister.ValidityRequired = this.ValidityRequired();
        return vatRegister;
    }
}

export class CustomProtocolViewModel extends BaseVatRegisterViewModel {
    private protocolType: IProtocolType;

    public HasCustomTemplate: ko.Observable<boolean> = ko.observable(false);
    public CustomTemplate: ko.Observable<IProtocolSettingsViewModel> = ko.observable();

    constructor(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister,
        registerPrintTemplates: number[],
        registerPreferredPrintTemplateId: number
    ) {
        super(container, vatRegister, registerPrintTemplates, registerPreferredPrintTemplateId);
        this.DocumentType = vatRegister.TipoDocumento;
        this.CanHaveResourcesTable(this.vatRegister.TipoDocumento === ProlifeSdk.CustomerOrderTypeId);
        this.load();
    }

    public setProtocolType(protocolType: IProtocolType) {
        if (protocolType == this.protocolType) return;

        this.protocolType = protocolType;
        this.HasCustomTemplate(protocolType.HasCustomTemplate);
        this.CustomTemplate(protocolType.createSettingsViewModel(this.container, this.vatRegister));
    }

    load() {
        super.load();

        if (this.HasCustomTemplate()) this.CustomTemplate().load();
    }

    getData(): IVatRegister {
        const vatRegister: IVatRegister = super.getData();

        if (this.HasCustomTemplate()) this.CustomTemplate().applyChangesToRegister(vatRegister);

        return vatRegister;
    }

    isChanged(): boolean {
        if (super.isChanged()) return true;

        if (this.HasCustomTemplate()) return this.CustomTemplate().isChanged();

        return false;
    }
}

class CustomTypeProtocolsViewModel {
    public Protocols: ko.ObservableArray<CustomProtocolViewModel> = ko.observableArray([]);
    public Label: string;
    public ShortLabel: string;

    public CanAddProtocols: ko.Observable<boolean> = ko.observable();
    public CanDeleteProtocols: ko.Observable<boolean> = ko.observable();
    public CanShowAdvancedSettings: ko.Observable<boolean> = ko.observable();
    public CanSetDocumentAutomations: ko.Observable<boolean> = ko.observable();
    public IsReadOnly: ko.Observable<boolean> = ko.observable();

    scroller: ko.Observable<boolean> = ko.observable(false);

    private NewCustomProtocolDescription: ko.Observable<string> = ko.observable();
    private NewProtocolHasFocus: ko.Observable<boolean> = ko.observable();

    constructor(private type: IProtocolType, private parent: VatRegistersEditingViewModel) {
        this.Label = type.DocumentTypeLabelForSettings;
        this.ShortLabel = type.DocumentTypeShortLabelForSettings;

        this.CanAddProtocols(this.type.canAddProtocols());
        this.CanDeleteProtocols(this.type.canAddProtocols());
        this.CanShowAdvancedSettings(true);
        this.CanSetDocumentAutomations(true);

        this.IsReadOnly(false);

        const protocolInterceptor = ko.computed(() => {
            const protocols = this.Protocols();
            protocols.forEach((p) => p.setProtocolType(type));
        });
    }

    public scrollTo(): void {
        this.scroller.valueHasMutated();
    }

    public CreateNewProtocol() {
        this.parent.createNewCustomProtocol(this.type.DcoumentTypeId, this.NewCustomProtocolDescription());
        this.NewCustomProtocolDescription("");
    }

    public HasProtocol(r: CustomProtocolViewModel): boolean {
        return this.Protocols.indexOf(r) > -1;
    }

    public IsProtocolValidFor(r: CustomProtocolViewModel): boolean {
        return r.DocumentType == this.type.DcoumentTypeId;
    }
}
