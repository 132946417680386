import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/06/2017
 * Time: 12:52
 * To change this template use File | Settings | File Templates.
 */

import { SectionForEdit } from "./SectionForEdit";
import { ISortable } from "../../../../interfaces/ISortable";

export class SectionQuestionsForEdit implements ISortable {
    public templateName: string = "section-questions-list";
    public templateUrl: string = "survey/templates/questionnaire";

    public Section: SectionForEdit;

    public Id: number;
    public Index: ko.Computed<number>;

    public OnQuestionMoved(beforeId : number, movedId : number, afterId : number) {
        this.Section.OnQuestionMoved(beforeId, movedId, afterId);
    }

    constructor(section: SectionForEdit) {
        this.Section = section;
        this.Id = section.Id;
        this.Index = ko.computed({
            read: () => {
                return this.Section.Index();
            },
            write: (index) => {
                this.Section.Index(index);
            }
        });
    }
}