import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ResourceManagerArea } from "./ResourceManagerArea";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { Deferred } from "../../../../Core/Deferred";

export class WorkTimeCategories extends ResourceManagerArea {

    public humanSection : WorkTimeCategoriesSection;
    public materialsSection : WorkTimeCategoriesSection;

    constructor(mainLayout : IResourcesManagerViewModel) {
        super(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoriesAreaTitle, "fa-clock-o", mainLayout,
            "ResourcesManager/Templates/Areas/WorkTimeCategories", "main-panel", ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_WorkTimeCategories);

        this.humanSection = new WorkTimeCategoriesSection(true);
        this.materialsSection = new WorkTimeCategoriesSection(false);
    }

    public show() {
        super.show();

        this.humanSection.init();
        this.materialsSection.init();
    }
}

export class WorkTimeCategoriesSection {

    public title : string;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesSettingsService : IWorkTimeCategoriesSettingsManager;

    private list : ko.ObservableArray<WorkTimeCategoryForList> = ko.observableArray([]);
    public filteredList : ko.Computed<WorkTimeCategoryForList[]>;
    public selected : ko.Observable<WorkTimeCategoryEditMask> = ko.observable(null);
    public textFilter : ko.Observable<string> = ko.observable("");
    public showDeleted : ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ServiceTypes.Authorization)
    private authorizationsService: IAuthorizationService;

    public canShowDeleted: ko.Observable<boolean> = ko.observable(false);

    constructor(private forHuman : boolean) {

        const showDeletedRight = this.authorizationsService.isAuthorized("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager");
        this.canShowDeleted(showDeletedRight);

        this.filteredList = ko.computed(() => {
            return this.list()
                .filter((c : WorkTimeCategoryForList) => { return c.category.Name.toUpperCase().indexOf(this.textFilter().toUpperCase()) > -1; })
                .filter((c : WorkTimeCategoryForList) => { return !c.category.Deleted || this.showDeleted(); })
                .sort((a, b) =>{
                    if(a.category.Name < b.category.Name)
                        return -1;
                    if(a.category.Name > b.category.Name)
                        return 1;
                    return 0;
                });
        });

        this.createNew();
    }

    public updateElementInLists(category : IWorkTimeCategory) {
        this.removeElementFromLists(category.Id);
        this.list.push(new WorkTimeCategoryForList(category, this));
    }

    public removeElementFromLists(id : number) {
        const updated = this.list().filter((c : WorkTimeCategoryForList) => { return c.category.Id == id; });

        if(updated.length > 0)
            this.list.remove(updated[0]);
    }

    public edit(category : IWorkTimeCategory){
        this.selected(new WorkTimeCategoryEditMask(category, this));
    }

    public createNew() {
        const emptyCategory : IWorkTimeCategory = {
            Id: -1,
            Name: "",
            Label: "",
            IsExtraordinary: false,
            DefaultHourSalary: 0,
            Deleted: false,
            IsBuiltIn: false,
            ResourceType: this.forHuman ? 0 : 1,
            IsAbsent: false
        };
        this.edit(emptyCategory);
    }

    public init() {
        this.workTimeCategoriesSettingsService.load().then(() => {
            if(this.forHuman)
                this.list(this.workTimeCategoriesSettingsService.GetAllForHumans(true).map((c : IWorkTimeCategory) => { return new WorkTimeCategoryForList(c, this); }));
            else
                this.list(this.workTimeCategoriesSettingsService.GetAllForMaterials(true).map((c : IWorkTimeCategory) => { return new WorkTimeCategoryForList(c, this); }));
        });
    }
}

export enum WorkTimeTypeEnum {
    Ordinary = 1,
    Extraordinary = 2,
    Absent = 3
}

export interface IWorkTimeType{
    Id : number;
    Label : string;
}

export class WorkTimeCategoryEditMask {
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesSettingsService : IWorkTimeCategoriesSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;


    public originalName : ko.Observable<string> = ko.observable("");
    public name : ko.Observable<string> = ko.observable("");
    public label: ko.Observable<string> = ko.observable("");
    public isExtraordinary : ko.Computed<boolean>;
    public defaultSalary : ko.Observable<number> = ko.observable(0);
    public isNew : ko.Observable<boolean> = ko.observable(false);
    public deleted : ko.Observable<boolean> = ko.observable(false);
    public isBuiltIn : ko.Observable<boolean> = ko.observable(false);
    public isAbsent : ko.Computed<boolean>;
    public availableWorkTimeType : ko.ObservableArray<IWorkTimeType> = ko.observableArray([
        {Id : WorkTimeTypeEnum.Extraordinary, Label: ProlifeSdk.TextResources.WorkedHours.Extraordinary},
        {Id : WorkTimeTypeEnum.Absent, Label: ProlifeSdk.TextResources.WorkedHours.Absent}
    ]);
    public selectedWorkTimeType : ko.Observable<IWorkTimeType> = ko.observable();

    constructor(public category : IWorkTimeCategory, private parent : WorkTimeCategoriesSection){
        this.name.subscribe((name: string) => {
            if (name && !this.label())
                this.label(name.substr(0, 1));
        });

        this.updateData(category);

        this.isExtraordinary = ko.computed(() => {
            if(this.selectedWorkTimeType() && this.selectedWorkTimeType().Id == WorkTimeTypeEnum.Extraordinary){
                return true;
            }

            return false;
        });

        this.isAbsent = ko.computed(() => {
            if(this.selectedWorkTimeType() && this.selectedWorkTimeType().Id == WorkTimeTypeEnum.Absent){
                return true;
            }

            return false;
        });
    }

    private updateData(category : IWorkTimeCategory) {
        this.category = category;
        this.name(category.Name);
        this.label(category.Label);
        this.selectedWorkTimeType(category.IsExtraordinary? this.availableWorkTimeType().firstOrDefault(x => x.Id == WorkTimeTypeEnum.Extraordinary) : 
            category.IsAbsent? this.availableWorkTimeType().firstOrDefault(x => x.Id == WorkTimeTypeEnum.Absent) : null);
        this.defaultSalary(category.DefaultHourSalary);
        this.isBuiltIn(category.IsBuiltIn);
        this.deleted(category.Deleted);
        this.isNew(category.Id <= 0);

        this.originalName(this.isNew() ? ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoriesNewCategory : category.Name);
    }

    public save(){
        if(this.name().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoriesInvalidName);
            return;
        }

        this.category.Name = this.name();
        this.category.Label = this.label();
        this.category.IsExtraordinary = this.isExtraordinary();
        this.category.DefaultHourSalary = this.defaultSalary();
        this.category.IsAbsent = this.isAbsent();
        this.workTimeCategoriesSettingsService.createOrUpdate(this.category)
            .then((c : IWorkTimeCategory) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryUpdated);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            }).catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryUpdateError);
            });
    }

    public hasChanges(): boolean {
        const newModel: IWorkTimeCategory = <IWorkTimeCategory> $.extend({}, this.category);

        newModel.Name = this.name();
        newModel.Label = this.label();
        newModel.IsExtraordinary = this.isExtraordinary();
        newModel.DefaultHourSalary = this.defaultSalary();
        newModel.IsAbsent = this.isAbsent();

        return !Object.identical(newModel, this.category);
    }

    public restoreItem(){
        this.category.Deleted = false;

        this.workTimeCategoriesSettingsService.createOrUpdate(this.category)
            .then((c : IWorkTimeCategory) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryRestored);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            }).catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryRestoredError);
            });
    }

    public deleteCategory(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryDeleteWarningConfirm, (confirm : boolean) => {
                if(!confirm)
                    return;

                this.workTimeCategoriesSettingsService.delete(this.category)
                    .then(() => {
                        this.category.Deleted = true;
                        this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryDeleted);
                        this.parent.updateElementInLists(this.category);
                        this.parent.createNew();
                    }).catch(() => {
                        this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.WorkTimeCategoryDeleteError);
                    });
            });
    }
}

export class WorkTimeCategoryForList {

    public isSelected : ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public category : IWorkTimeCategory, private parent : WorkTimeCategoriesSection){
        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().category.Id == this.category.Id;
        });
    }

    public showDetails(){
        if (!this.parent.selected()) {
            this.parent.edit(this.category);
            return;
        }

        if (this.parent.selected().hasChanges()) {
            this.ShowConfirmOnLostChanges()
                .then((confirm: boolean) => {
                    if (confirm)
                        this.parent.edit(this.category);
                });
            return;
        }

        this.parent.edit(this.category);
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}