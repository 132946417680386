import { If, IfNot } from "../../Components/IfIfNotWith";
import { TicketsMainPage } from "./TicketsMainPage";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { TicketsApplication } from "./TicketsApplication";
import { TicketComponent } from "./TicketComponent";

type TicketsRootProps = {
    application: TicketsApplication;
    forwardRef?: (e: _TicketsRoot) => void;
}

export function TicketsRoot(props: TicketsRootProps) {
    const C = require("./TicketsRoot")._TicketsRoot as typeof _TicketsRoot;
    return <C {...props} />;
}

export class _TicketsRoot {
    public ticketId: ko.Observable<number> = ko.observable();

    static defaultProps: Partial<TicketsRootProps> = {
    }

    constructor(private props : TicketsRootProps) {
        this.props.forwardRef && this.props.forwardRef(this);
    }

    onTicketSelected(id){
        this.ticketId(id);
    }
    
    render(){
        return <>
            <IfNot condition={this.ticketId}>
                {() => 
                <TicketsMainPage onTicketSelected={(id) => this.onTicketSelected(id)} application={this.props.application} />}
            </IfNot>
            <If condition={this.ticketId}>
                {() => 
                <TicketComponent onBack={() => this.onTicketSelected(null)} ticketId={this.ticketId()} application={this.props.application} />}
            </If>
        </>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(TicketsRoot);
}