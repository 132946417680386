import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IInvoicesService } from "../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IReasonForPayment } from "../ProlifeSdk/interfaces/invoice/IReasonForPayment";

export interface IReasonForPaymentDataSourceModel extends IDataSourceModel<string, IReasonForPayment> {
    
}

export class ReasonForPaymentDataSource extends BaseDataSource<IReasonForPaymentDataSourceModel> {
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService : IInvoicesService;

    private cache : IReasonForPayment[];

    getTitle(currentModel: IReasonForPaymentDataSourceModel): string {
        return TextResources.Invoices.ReasonsForPayment;
    }

    async getData(currentModel: IReasonForPaymentDataSourceModel, textFilter: string, skip: number, count: number): Promise<IReasonForPaymentDataSourceModel[]> {
        if(!this.cache) {
            this.cache = await this.invoicesService.getReasonsForPayment();
        }

        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);

        return this.cache
            .filter(d => splitFilters.filter(w => (d.Id + " - " + d.Description).toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: IReasonForPaymentDataSourceModel, ids: (string | number)[]): Promise<IReasonForPaymentDataSourceModel[]> {
        if(!this.cache) {
            this.cache = await this.invoicesService.getReasonsForPayment();
        }
        
        return this.cache
            .filter(d => ids.indexOf(d.Id) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IReasonForPayment) : IReasonForPaymentDataSourceModel {
        return {
            id: model.Id,
            title: model.Id + " - " + model.Description,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}