import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeSdk";

export function DocumentVatRows() {
    return  <ko-if data-bind="CanHaveTaxReport() && ShowAmounts()">
                <div class="invoice-taxes-summary bootstrap">
                    <table class="table table-condensed table-advance">
                        <thead>
                            <tr>
                                <th>{TextResources.Invoices.DocumentVatRowDescription}</th>
                                <th>{TextResources.Invoices.DocumentVatRowNature}</th>
                                <th>{TextResources.Invoices.DocumentVatRowChargeableVat}</th>
                                <th style="text-align: right">{TextResources.Invoices.DocumentVatRowTaxableTotal}</th>
                                <th style="text-align: center">{TextResources.Invoices.DocumentVatRowTaxPercentage}</th>
                                <th style="text-align: right">{TextResources.Invoices.DocumentVatRowTax}</th>
                                <th style="text-align: right">{TextResources.Invoices.DocumentVatRowTaxTotal}</th>
                            </tr>
                        </thead>
                        <tbody data-bind="foreach: TaxesReport">
                            <tr>
                                <td data-bind="text: VatDescription"></td>
                                <td data-bind="text: (!Nature() ? '' : Nature())"></td>
                                <td data-bind="text: (!ChargeableVatLabel() ? '' : ChargeableVatLabel())"></td>
                                <td style="text-align: right" data-bind="moneyTextEx: Taxable"></td>
                                <td style="text-align: center" data-bind="percentageText: VatPercentage"></td>
                                <td style="text-align: right" data-bind="moneyTextEx: Tax"></td>
                                <td style="text-align: right" data-bind="moneyTextEx: Total"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ko-if>
}