import { Validator } from "../../../ProlifeSdk/ValidationService";

function isCabOrAbi(val)
{
    return !val || val.length == 0 || (val.length == 5 && val.match(/^\d+$/));
}

function isIban(val)
{
	if(!val || val.length != 27)
            return false;

        var state = val.substring(0, 2);
        var euroCin = val.substring(2, 4);
        var cin = val.substring(4, 5);
        var abi = val.substring(5, 10);
        var cab = val.substring(10, 15);
        var account = val.substring(15);

        return state.match(/^[a-zA-Z]+$/)
            && euroCin.match(/^\d+$/)
            && isCabOrAbi(cab)
            && isCabOrAbi(abi);
}

Validator.prototype["mustBeIban"] = function<T,V>(this: Validator<T>, selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean) {
    this.rules.push({
        validate: (value: T) => {
            if(onlyIf && !onlyIf(value))
                return this.ok();

            let val = selector(value);
            if(!isIban(val)) {
                return this.error(errorMessage ?? "Il valore deve essere un IBAN valido: " + selector.toString());
            }
            return this.ok();
        }
    });
    return this;
};

declare module "../../../ProlifeSdk/ValidationService" {
    interface IValidator<T> {
        mustBeIban?(selector : (value : T) => string, errorMessage?: string, onlyIf?: () => boolean) : IValidator<T>;
    }
}