import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
    jobOrderType: {
        alignItems: "center",
        height: "26px", fontWeight: 300,

        "& .icon": {
            textAlign: "center",
            height: "18px",
            width: "18px",
            lineHeight: "18px"
        },

        "& .description": {
            height: "26px",
            lineHeight: "1em",
            alignItems: "center"
        }
    }
});
const { classes } = styleSheet.attach();

type JobOrderTypeProps = {
    name: string;
    icon: string;
    background: string;
    foreground: string;
}

export function JobOrderType(props: JobOrderTypeProps) {
    const C = require("./JobOrderType")._JobOrderType as typeof _JobOrderType;
    return <C {...props} />;
}

export class _JobOrderType {
    static defaultProps: Partial<JobOrderTypeProps> = {
    }

    constructor(private props : JobOrderTypeProps) {
        
    }
    
    render() {
        const classNames = ComponentUtils.classNames("flex-container", classes.jobOrderType);
        
        return  <div className={classNames} style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                    <div style={{ textAlign: "center", height: "18px", width: "18px", lineHeight: "18px", backgroundColor: this.props.background, color: this.props.foreground }}>
                        <i className={this.props.icon}></i>
                    </div>
                    <div className="flex-fill description flex-container">
                        <span style={{ display: "inline-block", lineHeight: "1em" }}>{this.props.name}</span>
                    </div>
                </div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderType);
}