import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { WorkflowStatusAfterSaveDialog } from "./WorkflowStatusAfterSaveDialog";
import { IWorkflowsAndTasksDataSourceModel } from "../../../DataSources/WorkflowsAndTasksDataSource";
import { IDataSource } from "../../../DataSources/IDataSource";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";

export class EventDetailsPage implements IDialog {
    public templateName = "event-details";
    public templateUrl = "blog/templates/events-details";
    public title: string;

    private logService: ILogService;
    private todoListService: ITodoListService;
    private dialogService: IDialogsService;
    private desktopService: IDesktopService;
    private console: IContextEventsObserver;

    public isSaving: ko.Observable<boolean> = ko.observable(false);

    public modal: {
        close: (result?: any) => void;
    };

    constructor(
        private serviceLocator: IServiceLocator,
        public itemInput: BlogEventBaseInput,
        console: IContextEventsObserver
    ) {
        this.console = console;
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.title = itemInput.title;
    }

    public dispose() {
        this.itemInput.dispose();
    }

    public onApplicationLaunch(): void {}

    onItemSelected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        this.itemInput.onItemSelected(sender, model);
    }

    onItemDeselected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        this.itemInput.onItemDeselected(sender, model);
    }

    public close(): void {
        if (this.itemInput.isChanged()) {
            this.dialogService.Confirm(
                ProlifeSdk.TextResources.Blog.UnsavedCloseWarning,
                ProlifeSdk.TextResources.Blog.UnsavedCloseCancel,
                ProlifeSdk.TextResources.Blog.UnsavedCloseConfirm,
                (result: boolean) => {
                    if (result) {
                        if (this.modal) this.modal.close(false);
                        else this.console.OnEventPageClose();
                    }
                }
            );
        } else {
            if (this.modal) this.modal.close(false);
            else this.console.OnEventPageClose();
        }
    }

    public setValues(itemEvent: any): void {
        this.itemInput.setValues(itemEvent);
    }

    public SetParent(parent: any) {
        this.itemInput.SetParent(parent);
    }

    public action(): void {
        const errorMessage: string[] = this.itemInput.getValidationMessage();

        if (errorMessage.length > 0) {
            this.dialogService.Alert(ProlifeSdk.TextResources.Blog.Errors + errorMessage.join(", "), "ok", () => {
                return;
            });
            return;
        }

        this.saveBlogEvent();
    }

    private async saveBlogEvent(): Promise<void> {
        try {
            const event: ILogEvent = this.itemInput.getBlogEvent();
            let result: { Confirm: boolean; TasksStatus: any[] } = { Confirm: true, TasksStatus: [] };
            if (event.Tasks.length > 0) {
                const workflowStatusChanges = new WorkflowStatusAfterSaveDialog({ tasksIds: event.Tasks });
                result = await workflowStatusChanges.show();
            }

            if (result.Confirm) await this.SaveAfterWorkflowStatusChanges(result.TasksStatus);
        } catch (e) {
            console.error(e);
        }
    }

    private async SaveAfterWorkflowStatusChanges(
        tasksStatus: { Id: number; Status: number; ProgressAvg: number }[]
    ): Promise<void> {
        if (this.isSaving()) return;

        try {
            this.dialogService.LockUI(ProlifeSdk.TextResources.Blog.Saving, true);
            this.isSaving(true);

            await this.itemInput.save();
            await this.todoListService.UpdateTasksStatus(tasksStatus);

            if (this.modal) this.modal.close(true);
            else this.console.OnEventPageSave();
        } catch (e) {
            console.error(e);
        } finally {
            this.isSaving(false);
            this.dialogService.UnlockUI();
        }
    }
}
