import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTemplatesTaskTagsProvider } from "./TodoListTemplatesTaskTagsProvider";
import { TodoListTemplateTask } from "./TodoListTemplateTask";
import { ITodoListUiProvider, ITodoListElementsProvider, ITodoList, ITodoListActivitiesTagsProvider, ITaskResource, ITodoListTaskTag, ITodoListActivitiesFiltersProvider, ITodoListTaskTagsAndResources, INewActivityDefaultData } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListService, ITodoListTemplateTaskInsertResponse } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTemplateTask } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { LazyImport } from "../../../../../Core/DependencyInjection";

export class TemplatesUiProvider implements ITodoListUiProvider
{
    constructor(private activitiesProvider : ITodoListElementsProvider)
    {
    }

    /* public GetTodoList(activitiesEditorDialog? : IActivityEditorDialog, options? : ITodoListOptions) : ITodoList
    {
        let todoList = new TodoList(this.activitiesProvider, new TemplatesTaskListNotificationsManagerAdapter(options), activitiesEditorDialog, options);
        todoList.CanEditWorkflow(false);
        todoList.IsTemplate(true);
        return  todoList;
    }

    public GetActivityEditorDialog() : IActivityEditorDialog
    {
        return new ActivityDetailsDialog({ activitiesProvider: this.activitiesProvider, dataSource: new TemplateTasksDataSource()});
    } */
}

export class TodoListTemplatesTasksProvider implements ITodoListElementsProvider
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    public ChooseContainerLabel : string = ProlifeSdk.TextResources.Todolist.SelectATemplate;
    TagsProvider : ITodoListActivitiesTagsProvider;
    UI : ITodoListUiProvider;

    constructor()
    {
        this.TagsProvider = new TodoListTemplatesTaskTagsProvider();
        this.UI = new TemplatesUiProvider(this);
    }

    public InitFromOtherActivity(activityToInitialize : ITodoListTemplateTask, otherActivity : ITodoListTemplateTask)
    {
        activityToInitialize.Priority = otherActivity.Priority;
        activityToInitialize.InQuality = otherActivity.InQuality;
        activityToInitialize.Duration = otherActivity.Duration;
        activityToInitialize.Resources = otherActivity.Resources.map((r : ITaskResource) => { return { ElementType : r.ElementType, ElementId : r.ElementId };});
        activityToInitialize.TemplateId = otherActivity.TemplateId;

        otherActivity.Tags.filter((t : ITodoListTaskTag) => { return t.Field.toUpperCase() != "FILE";}).forEach((t : ITodoListTaskTag) => {
            activityToInitialize.Tags.push({
                Type: t.Type,
                Value: t.Value,
                Field: t.Field
            });
        });
    }

    public GetActivities(filtersProvider : ITodoListActivitiesFiltersProvider, skip : number, count : number) : Promise<{ Id: number; }[]>
    {
        const templateId : number = filtersProvider.GetWorkflowsFilters().length > 0 ? filtersProvider.GetWorkflowsFilters()[0] : -1;
        return  this.todoListService.GetTemplatesTasks(filtersProvider.GetWordsFilters(),
                                            filtersProvider.GetFilterTags(),
                                            templateId,
                                            filtersProvider.GetFolderId(),
                                            filtersProvider.GetResourceGroupId(),
                                            skip, count) as any;
    }

    GetActivitiesTagsAndResources(activitiesIds: number[]): Promise<ITodoListTaskTagsAndResources[]> {
        return this.todoListService.GetActivitiesTagsAndResources(activitiesIds);
    }

    public GetSearchFilters(filtersProvider : ITodoListActivitiesFiltersProvider) : any
    {
        return {
            TextFilters : filtersProvider.GetWordsFilters(),
            TemplateId : filtersProvider.GetWorkflowsFilters().length > 0 ? filtersProvider.GetWorkflowsFilters()[0] : -1,
            StatusFolder : filtersProvider.GetFolderId(),
            Tags : filtersProvider.GetFilterTags(),
            ResourcesGroup : filtersProvider.GetResourceGroupId()
        }
    }

    public GetAdditionalDataForActivities(tasks : ITodoListTemplateTask[]) : Promise<void>
    {
        return Promise.resolve<void>(undefined);
    }

    CreateViewModelForActivity(activity : any, todolist : ITodoList, additionalData? : any) : any
    {
        return new TodoListTemplateTask(todolist, activity, this);
    }

    GetEmptyActivityModel(defaultData? : INewActivityDefaultData) : ITodoListTemplateTask
    {
        return {
            Id : -1,
            Status : 0,
            Duration : 0,
            Deleted : false,
            InQuality : defaultData ? defaultData.InQuality : false,
            TemplateId : defaultData && defaultData.ContainerId ? defaultData.ContainerId : -1,
            Tags : defaultData && defaultData.Tags ? defaultData.Tags : [],
            Resources : defaultData && defaultData.Resources ? defaultData.Resources || [] : [],
            ReportingType : defaultData && defaultData.ReportingType ? defaultData.ReportingType : 0,
            Optional: false,
            HideFromSuggestions: defaultData && defaultData.HideFromSuggestions ? defaultData.HideFromSuggestions : false,
            BudgetRequestCause: null,
            EstimatedBudgetRows: [],
            ActivitiesProgressAmountMode: defaultData && defaultData.ActivitiesProgressAmountMode ? defaultData.ActivitiesProgressAmountMode : 0,
            MultiplierUnitOfMeasure: defaultData && defaultData.MultiplierUoM ? defaultData.MultiplierUoM : '',
            Multiplier: defaultData && defaultData.Multiplier ? defaultData.Multiplier : 1,
            IsMilestone: false,
            DefaultWorkPlace: defaultData?.DefaultWorkPlace,
            DefaultTravelDistance: defaultData?.DefaultTravelDistance,
            DefaultCallRight: defaultData?.DefaultCallRight,
            WorkedHoursDefaultsStrictMode: defaultData?.WorkedHoursDefaultsStrictMode ?? false,
            HideAdministrativeFieldsOnWorkedHours: defaultData?.HideAdministrativeFieldsOnWorkedHours ?? false,
            WorkedHoursDefaultRoles: defaultData.DefaultRoles ?? [],
            WorkedHoursDefaultWorkTimeCategories: defaultData.DefaultWorkTimeCategories ?? []
        };
    }

    public MoveActivity(activityId : number, activityIsTask : boolean, destinationActivityId : number, destinationActivityIsTask : boolean, afterOrBefore : boolean) : Promise<void>
    {
        return this.todoListService.MoveTemplateTask(activityId, destinationActivityId, afterOrBefore);
    }

    GetActivityById(id : number) : Promise<ITodoListTemplateTask>
    {
        return this.todoListService.GetTemplateTaskById(id);
    }

    DeleteActivity(id : number) : Promise<void>
    {
        return this.todoListService.DeleteTemplateTask(id);
    }

    InsertOrUpdateActivity(activity : ITodoListTemplateTask) : Promise<ITodoListTemplateTaskInsertResponse>
    {
        return this.todoListService.InsertOrUpdateTemplateTask(activity);
    }

}