import * as ko from "knockout";
/**
 * Created by d.bucchi on 15/06/2017.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { TodoListTask } from "../providers/TodoListTask";
import { TaskBoard } from "../../task-board/TaskBoard";
import { TaskBoardColumn } from "../../task-board/TaskBoardColumn";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListElementsProvider } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";

export class ChangePlanDialog implements IDialog{
    private dialogsService: IDialogsService;
    private todoListService: ITodoListService;

    templateName: string = "activity-change-plan-dialog";
    templateUrl: string = "todolist/templates/activity";

    private someTasksSaved: boolean = false;
    observableTitle: ko.Observable<string> = ko.observable("");

    private activity: TodoListTask = null;

    private disposed: boolean = false;
    private selectedContainerId: number = null;

    TaskToChangePlan: ko.Observable<any> = ko.observable();

    title: string;

    modal: {
        close: (result?: any) => void;
    };

    constructor(serviceLocator: IServiceLocator, private activitiesProvider: ITodoListElementsProvider, public taskBoard: any) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
    }

    public ShowModal(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", {}, this.templateUrl, this.templateName);
    }

    public SetActivity(activity: TodoListTask) {
        this.activity = activity;
        this.PrepareSelectedActivity(this.activity);
    }

    private PrepareSelectedActivity(activity: TodoListTask) {
        this.TaskToChangePlan(activity);
        this.SetTitle();
    }

    private SetTitle() {
        var activityName = this.TaskToChangePlan().Title();

        if (this.selectedContainerId) {
            this.todoListService.GetWorkflow(this.selectedContainerId)
                .then((w: ITodoListWorkflow) => {
                    this.observableTitle(w.Title + " - " + activityName);
                });

            this.observableTitle();
        } else {
            this.observableTitle(activityName);
        }
    }

    close() {
        this.modal.close();
    }

    action() {
        this.todoListService.MoveTaskToWorkflow(this.TaskToChangePlan().task.Id, this.taskBoard.selectedWorkflow().Id);
        this.modal.close();
    }
}