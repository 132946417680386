import * as ko from "knockout";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { ICurrenciesSettingsManager } from "../Invoices/invoices/settings/CurrenciesSettingsManager";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { ICurrencyWithCountriesIds } from "../Invoices/interfaces/ICurrenciesService";

export class CurrenciesDataSource extends BaseDataSource<IDataSourceModel<number, ICurrencyWithCountriesIds>> {
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    private getDeletedCurrencies: boolean = false;

    public getTitle(currentModel: IDataSourceModel<number, ICurrencyWithCountriesIds>): string {
        return TextResources.Invoices.CurrenciesLabel;
    }
    
    public async getData(currentModel: IDataSourceModel<number, ICurrencyWithCountriesIds>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, ICurrencyWithCountriesIds>[]> {
        return new Promise((resolve) => {
            let currencies = this.currenciesSettingsManager.getCurrencies(textFilter, this.getDeletedCurrencies);
            resolve(this.createModels(currencies.slice(skip, skip + count)));
        });
    }
    
    public async getById(currentModel: IDataSourceModel<number, ICurrencyWithCountriesIds>, ids: number[]): Promise<IDataSourceModel<number, ICurrencyWithCountriesIds>[]> {
        return new Promise((resolve) => {
            resolve(this.createModels(this.currenciesSettingsManager.getCurrenciesByIds(ids)));
        });
    }

    public returnDeletedCurrencies(value: boolean): void {
        this.getDeletedCurrencies = value;
    }

    private createModels(currencies: ICurrencyWithCountriesIds[]): IDataSourceModel<number, ICurrencyWithCountriesIds>[] {
        return currencies.map(this.createModel, this);
    }

    private createModel(currency: ICurrencyWithCountriesIds): IDataSourceModel<number, ICurrencyWithCountriesIds> {
        return {
            id: currency.Id,
            isGroup: false,
            isLeaf: true,
            title: String.format(TextResources.Invoices.Currency, currency.CodeISO4217alpha3, currency.Symbol),
            subTitle: currency.Name,
            model: currency
        };
    }
}