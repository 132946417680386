import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { ILeafReferenceInfo, IDocumentBuilderDocumentOriginatingRows } from "../../interfaces/invoice/IDocumentsService";
import { IEntityRefInfo, IReferenceDetailsViewModel, ISimpleEntitiesViewModel, IEntityRefForDetails } from "../../interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../interfaces/invoice/IDocumentRow";
import { IDocumentReferencesMap } from "../../interfaces/invoice/IReferencesMap";
import { IVatRegisters } from "../../interfaces/invoice/settings/IVatRegisters";

export class DocumentRefDetailsViewModelFactory
{
    CreateViewModelsFor(references : IDocumentBuilderDocumentOriginatingRows[], details : IEntityRefInfo[], customerId : number, isReferencer : boolean) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        var docsIds : number[] = [];
        details.forEach((d : IEntityRefInfo) => {
            if(docsIds.indexOf(d.DocumentId) == -1)
                docsIds.push(d.DocumentId);
        });
        docsIds.forEach((id : number) => {
            var docDetails = details.filter((d : IEntityRefInfo) => { return d.DocumentId == id; });
            var docIds = docDetails.map((d1 : IEntityRefInfo) => { return d1.EntityKeyId; });
            var docRefs = references.filter((r : IRefDocumentRow) => { return docIds.indexOf(r.SourceEntityKeyId) > -1; });

            viewModels.push(new DocumentRefDetailsViewModel(docRefs, docDetails, docDetails[0].EntityType, docDetails[0].DocumentId, isReferencer));
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(referencedLeafsInfo : ILeafReferenceInfo[], warehouseId : number, customerId: number) : IReferenceDetailsViewModel[]
    {
        return [];
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(referenceMap : IDocumentReferencesMap) : ISimpleEntitiesViewModel
    {
        //Implementare se necessario
        return null;
    }
}

export class DocumentRefDetailsViewModel implements IReferenceDetailsViewModel
{
    public templateName : string;
    public templateUrl : string;
    private DocNumber : string;
    private DocDate : Date;
    private Rows : IEntityRefForDetails[];
    private RegisterName : string;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegistersService : IVatRegisters;

    constructor(rowsReferences : IDocumentBuilderDocumentOriginatingRows[], rowsDetails : IEntityRefInfo[], private documentType : string, private documentId: number, isReferencer : boolean)
    {
        this.RegisterName = rowsDetails.length > 0 && rowsDetails[0].RegisterId ?
            this.vatRegistersService.getVatRegisterById(rowsDetails[0].RegisterId).NomeRegistroIVA : ProlifeSdk.TextResources.ProlifeSdk.Document;

        this.templateName = "document"; //isReferencer ? "destination-document" : "document";
        this.templateUrl = "prolifesdk/templates/documents/referencemap/referenceDetails";
        this.DocNumber = rowsDetails[0].DocumentNumber;
        this.DocDate = rowsDetails[0].ReferenceDate;

        this.Rows = rowsDetails.map((d : IEntityRefInfo) => {
            var matches = rowsReferences.filter((r : IRefDocumentRow) => { return r.SourceEntityKeyId == d.EntityKeyId; });

            return {
                Details : d,
                Reference : matches[0],
                Amount : matches[0].Amount * (isReferencer && d.IsReferencesGroup ? d.RowAmount : 1),
                NetUnitPrice : isReferencer ? d.NetUnitPrice :  matches[0].NetUnitPrice,
                NetPrice : isReferencer ? d.NetPrice : matches[0].NetPrice
            }


        });
    }

    public ShowDetails()
    {
        this.documentsService.OpenDocumentOverlay(this.documentType, this.documentId);
    }
}
