import * as ko from "knockout";
import { TelephoneNumber } from "../Desktop/utilities/TelephoneNumber";

/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 07/02/13
 * Time: 17.01
 * To change this template use File | Settings | File Templates.
 */

export class TelephoneNumberValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                return underlyingObservable();
            },
            write: function(newValue) {
                var valueToWrite = TelephoneNumber.formatGSM(newValue);
                if(valueToWrite != underlyingObservable())
                    underlyingObservable(valueToWrite);
            }
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

export class TelephoneNumberText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();

        var interceptor = ko.computed(() => {
            var value = underlyingObservable();
            if(value == null || value == undefined || value == "")
                return "";

            return TelephoneNumber.formatGSM(value);
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["telephoneValue"] = new TelephoneNumberValue();
ko.bindingHandlers["telephoneText"] = new TelephoneNumberText();