import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxServiceNew } from "../../../Core/interfaces/IAjaxService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeSdk";
import { PrintTemplateEditor } from "./ui/PrintTemplateEditor";

export interface IPrintTemplateSettingsManager extends ISettingsManager {
    getPrintTemplates(): IPrintTemplate[];
    savePrintTemplates(printTemplates: IPrintTemplate[]): Promise<void>;
}

export interface IPrintTemplateWithPreferred extends IPrintTemplate {
    preferred: boolean;
}
export interface IPrintTemplate {
    id: number;
    title: string;
    template: string;
    inUse?: boolean;
}

export class PrintTemplateSettingsManager implements IPrintTemplateSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxService: IAjaxServiceNew;

    private printTemplates: IPrintTemplate[] = [];

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }

    async load(): Promise<any> {
        try {
            this.printTemplates = await this.ajaxService.Get<IPrintTemplate[]>(
                "r/api/DocumentPrintTemplate",
                "getAll",
                {}
            );
        } catch {
            console.warn("Impossibile raggiungere il servizio durante la chiamata a DocumentPrintTemplate/getAll");
        }
    }

    getName(): string {
        return nameof<IPrintTemplateSettingsManager>();
    }
    getLabel(): string {
        return TextResources.Invoices.PrintTemplatesSettingsManagerName;
    }
    hasEditingUI(): boolean {
        return true;
    }
    getEditingUI(): IView {
        return {
            templateName: null,
            templateUrl: null,
            viewModel: null,
            render: () => <PrintTemplateEditor />,
        };
    }

    getPrintTemplates(): IPrintTemplate[] {
        return this.printTemplates.slice();
    }

    async savePrintTemplates(printTemplates: IPrintTemplate[]) {
        this.printTemplates = await this.ajaxService.Post<IPrintTemplate[]>(
            "r/api/DocumentPrintTemplate",
            "createOrUpdate",
            {
                methodData: printTemplates,
            }
        );
    }
}
