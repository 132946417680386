import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IArticleAliasEx, IAliasesService } from "../Warehouse/AliasesService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export interface IArticleAliasesDataSourceModel extends IDataSourceModel<number, IArticleAliasEx> {

}

export class ArticleAliasesDataSource extends BaseDataSource<IArticleAliasesDataSourceModel> {
    @LazyImport(nameof<IAliasesService>())
    private aliasesService! : IAliasesService;

    private customerId : number = -1;
    
    public setCustomerId(customerId : number)
    {
        this.customerId = customerId;
    }

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.Articles;
    }    
    
    async getData(currentModel: IArticleAliasesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IArticleAliasesDataSourceModel[]> {
        let aliases = await this.aliasesService.GetArticleAliases(this.customerId, textFilter, skip, count);
        return aliases.map(this.createModelFor, this);
    }

    private createModelFor(articleAlias : IArticleAliasEx) : IArticleAliasesDataSourceModel {
        return {
            id: articleAlias.Id,
            title: articleAlias.AliasArticleCode,
            isLeaf: true,
            isGroup: false,
            subTitle: `${articleAlias.OriginalArticleCode} - ${articleAlias.Description}`,
            model: articleAlias
        }
    }
    
    async getById(currentModel: IArticleAliasesDataSourceModel, ids: number[]): Promise<IArticleAliasesDataSourceModel[]> {
        let articles = await this.aliasesService.GetArticleAliasesByIds(ids);
        return articles.map(this.createModelFor, this);
    }
}