import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/12/2018
 * Time: 14:13
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { INationsSettingsManager, INation } from "../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class NationsSettingsManager implements INationsSettingsManager {
    private nations: INation[] = [];
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }
    
    load(): Promise<INation[]> {
        return this.ajaxService.Get<INation[]>("Settings-api", "Nations", {})
            .then((nationsList: INation[]) => {
                this.nations = nationsList || [];
                return nationsList;
            });
    }

    getName(): string {
        return ProlifeSdk.NationsSettings;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Settings.NationsSettings;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return undefined;
    }

    getNationsList(): INation[] {
        return this.nations;
    }

    getNationById(id: number): INation {
        let matches = this.nations.filter((n) => n.Id == id);
        return matches[0] || null;
    }

    getNationByAplpha2Code(code: string): INation {
        let matches = this.nations.filter((n) => n.Alpha2Code == code);
        return matches[0] || null;
    }

    findCountry(searchQuery: string): INation {
        let text = (searchQuery || "").trim();
        return this.nations.firstOrDefault(p => p.Alpha2Code === text || p.Alpha3Code === text || p.Name === text);
    }
}