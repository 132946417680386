import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import { IUsersService, IUserForList } from "../Users/UsersService";
import { IDataSourceModel } from "./IDataSource";
import { ITicket, ITicketsService } from "../Tickets/TicketsService";

export interface ITicketsDataSourceModel extends IDataSourceModel<number, ITicket> {

}

export class TicketsDataSource extends BaseDataSource<ITicketsDataSourceModel> {
    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    private JobOrdersIds: number[] = [];
    private ResourcesIds: number[] = [];
    private CustomersIds: number[] = [];

    getTitle(currentModel: ITicketsDataSourceModel): string {
        return "Tickets";
    }

    setJobOrderId(id){
        this.JobOrdersIds = [ id ];
    }

    setResourcesId(id){
        this.ResourcesIds = [ id ];
    }

    setCustomerId(id){
        this.CustomersIds = [ id ];
    }
    
    async getData(currentModel: ITicketsDataSourceModel, textFilter: string, skip: number , count: number): Promise<ITicketsDataSourceModel[]> {
        let allTickets = await this.ticketsService.GetTickets({textFilter: textFilter, skip: skip, count: count, jobOrdersIds: this.JobOrdersIds, resourcesIds: this.ResourcesIds, customersIds: this.CustomersIds });
        return allTickets.map(this.createModelFor, this);
    }

    async getById(currentModel: ITicketsDataSourceModel, ids: number[]): Promise<ITicketsDataSourceModel[]> {
        let allTickets = await this.ticketsService.GetTicketsByIds(ids);
        return allTickets.map(this.createModelFor, this);
    }

    private createModelFor(ticket : ITicket) : ITicketsDataSourceModel {
        return {
            id: ticket.Id,
            title: ticket.Author + ' - ' + ticket.Date,
            isLeaf: true,
            isGroup: false,
            model: ticket
        }
    }
}