import * as React from "@abstraqt-dev/jsxknockout";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IWarehouseInspectionsDestinationProtocolsSettingsManager } from "../WarehouseInspectionsDestinationProtocolsSettingsManager";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../Components/Layouts";
import { IWarehouseInspectionDestinationProtocol } from "../../../WarehouseInspectionsService";
import TsxForEach from "../../../../Components/ForEach";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { WarehouseInspectionDestinationProtocol } from "./WarehouseInspectionDestinationProtocol";

export class WarehouseInspectionsDestinationProtocolsEditor {
    public title: string = "";

    public Protocols: ko.ObservableArray<IWarehouseInspectionDestinationProtocol> = ko.observableArray([]);

    @LazyImportSettingManager(nameof<IWarehouseInspectionsDestinationProtocolsSettingsManager>())
    private settingsManager: IWarehouseInspectionsDestinationProtocolsSettingsManager;

    constructor() {
        this.title = this.settingsManager.getLabel();
        this.Protocols(this.settingsManager.getDestinationProtocols());
    }

    public render() {
        let destinationProtocolsEditor: WarehouseInspectionsDestinationProtocolsEditor;

        return ComponentUtils.bindTo((
            <LayoutWithHeader>
                <LayoutHeader style={{ paddingTop: "30px" }}>
                    <h3 class="page-title">
                        <span data-bind={{ text: destinationProtocolsEditor.title }}></span>
                        <small class="alert alert-danger" style={{ position: "absolute", left: "60%", marginLeft: "-25%", textAlign: "center", top: "0px", width: "500px", color: "#a94442"}}>{TextResources.ProlifeSdk.SettingManagerReloadAlert}</small>
                    </h3>
                </LayoutHeader>
                <LayoutContent noOverflow={true}>
                    <div data-bind={{ slimScroll: "flex" }}>
                        <TsxForEach data={this.Protocols}>
                            {(protocol: IWarehouseInspectionDestinationProtocol) => <WarehouseInspectionDestinationProtocol protocol={protocol} onChanges={this.onDestinationProtocolChanges.bind(this)}/>}
                        </TsxForEach>
                    </div>
                </LayoutContent>
            </LayoutWithHeader>
        ), this, "destinationProtocolsEditor");
    }

    private onDestinationProtocolChanges(protocol: IWarehouseInspectionDestinationProtocol): void {
        this.settingsManager.createOrUpdateDestinationProtocols([protocol]);
    }
}

export class WarehouseInspectionsDestinationProtocolsEditorUI implements IView {
    templateName: string;
    templateUrl: string;
    viewModel: any;

    constructor() {

    }
    
    render() {
        let Editor = require("./WarehouseInspectionsDestinationProtocolsEditor").WarehouseInspectionsDestinationProtocolsEditor as typeof WarehouseInspectionsDestinationProtocolsEditor;
        return <Editor />;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WarehouseInspectionsDestinationProtocolsEditor);
}