import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { ServiceOrder, Resource } from "./Resource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { OperationalUnitsDataSource, IOperationalUnitsDataSourceModel } from "../../../../DataSources/OperationalUnitsDataSource";
import { TabNav, TabNavPage } from "../../../../Components/TabNavComponent";
import { IHumanResourceOrdersWorkingHours, IHumanResourceOrdersRoles, IHumanResourceOrdersSalaries } from "../../../../Users/HumanResourcesService";
import { IIdGeneratorService } from "../../../../ProlifeSdk/IdGeneratorService";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { WorkingDayEditor, WorkingHourEditor, classes } from "./WorkingDayEditor";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IOperationalUnit, IOperationalUnitsSettingsManager } from "../../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IOperationalUnitWorkingHours } from "../../../../ProlifeSdk/interfaces/users/IHumanResource";
import { IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { CheckBox } from "../../../../Components/Checkbox";
import TsxForEach from "../../../../Components/ForEach";
import { MoneyInput } from "../../../../Components/MoneyInput";
import { NumberInput } from "../../../../Components/NumberInput";
import { SOCostAnnualHoursBehaviuor } from "./SOCostAnnualHoursBehaviuor";

export interface IServiceOrderEditorParams {
    serviceOrder: ServiceOrder;
    resource: Resource;
}

export class ServiceOrderEditor extends DialogComponentBase implements IDataSourceListener {
    IsNew: ko.Observable<boolean> = ko.observable();

    Id : ko.Observable<number> = ko.observable();
    Title: ko.Observable<string> = ko.observable();
    FromDate : ko.Observable<Date> = ko.observable();
    ToDate : ko.Observable<Date> = ko.observable();
    EstimatedHoursToWork : ko.Observable<number> = ko.observable();
    MaxFlexiblePositive : ko.Observable<number> = ko.observable();
    MaxFlexibleNegative : ko.Observable<number> = ko.observable();
    FkOperationalUnitId: ko.Observable<number> = ko.observable();
    Notes : ko.Observable<string> = ko.observable();

    ShowWorkingHoursPanel: ko.Computed<boolean>;
    NoWorkingHoursDefined: ko.Computed<boolean>;
    NoRolesSelected: ko.Computed<boolean>;
    NoDefaultRoleSelected: ko.Computed<boolean>;
    NoCostSelected: ko.Computed<boolean>;
    NoDefaultCostSelected: ko.Computed<boolean>;

    HoursPerDay: ko.ObservableArray<ServiceOrderWorkingDayEditor> = ko.observableArray();
    Roles: ko.ObservableArray<ServiceOrderRoleEditor> = ko.observableArray();
    Costs: ko.ObservableArray<ServiceOrderCostEditor> = ko.observableArray();

    SelectAllRoles: ko.Computed<boolean>;
    SelectAllCosts: ko.Computed<boolean>;

    OperationalUnitCache: ko.Observable<IOperationalUnit> = ko.observable();
    OperationalUnitStandardWorkHoursCache: ko.ObservableArray<IOperationalUnitWorkingHours> = ko.observableArray();
    OperationalUnitsDataSource: OperationalUnitsDataSource = new OperationalUnitsDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacters : IUserCharactersSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.OperationalUnitsSettingsServiceType)
    private ouSettings: IOperationalUnitsSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategories : IWorkTimeCategoriesSettingsManager;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    private serviceOrder: ServiceOrder;
    private resource: Resource;
    private validator: IValidator<ServiceOrderEditor>;    

    constructor(params : IServiceOrderEditorParams) {
        super({
            className: "fullscreen"
        });

        this.serviceOrder = params.serviceOrder;
        this.resource = params.resource;
        
        this.title(this.serviceOrder?.Title() ?? TextResources.ResourcesManager.ServiceOrderNewTitle);

        this.ShowWorkingHoursPanel = ko.computed(() => {
            return this.FkOperationalUnitId() > 0;
        });

        this.NoWorkingHoursDefined = ko.computed(() => {
            return !this.HoursPerDay().any(h => h.HasWorkingHours());
        });

        this.NoRolesSelected = ko.computed(() => {
            return !this.Roles().any(r => r.IsSelected());
        });

        this.NoDefaultRoleSelected = ko.computed(() => {
            return !this.Roles().any(r => r.IsDefault() && r.IsSelected());
        });

        this.NoCostSelected = ko.computed(() => {
            return !this.Costs().any(c => c.IsSelected());
        });

        this.NoDefaultCostSelected = ko.computed(() => {
            return !this.Costs().any(c => c.IsDefault() && c.IsSelected());
        });

        this.SelectAllRoles = ko.computed({
            read: () => {
                let anyoneSelected = false;
                let allSelected = true;

                for(const role of this.Roles()) {
                    anyoneSelected = anyoneSelected || role.IsSelected();
                    allSelected = allSelected && role.IsSelected();
                }
                return allSelected ? true : (anyoneSelected ? null : false);
            },
            write: (value: boolean) => {
                for(const role of this.Roles()) {
                    role.IsSelected(value);
                }
            }
        });

        this.SelectAllCosts = ko.computed({
            read: () => {
                let anyoneSelected = false;
                let allSelected = true;

                for(const cost of this.Costs()) {
                    anyoneSelected = anyoneSelected || cost.IsSelected();
                    allSelected = allSelected && cost.IsSelected();
                }
                return allSelected ? true : (anyoneSelected ? null : false);
            },
            write: (value: boolean) => {
                for(const cost of this.Costs()) {
                    cost.IsSelected(value);
                }
            }
        });


        this.configureValidation();

        this.load();
    }

    private configureValidation() {
        this.validator = this.validationService.createValidator<ServiceOrderEditor>()
            .isNotNullUndefinedOrLessOrEqualZero(o => o.FkOperationalUnitId(), "Selezionare una unità operativa prima di salvare")
            .isNotNullOrUndefinedOrWhiteSpace(o => o.Title(), "Specificare un titolo per l'Ordine di Servizio")
            .isTrue(o => o.EstimatedHoursToWork() >= 0, "Il numero delle ore stimate deve essere positivo")
            .isNotNullOrUndefined(o => o.FromDate(), "Selezionare un data di inizio per l'Ordine di Servizio")
            .isTrue(o => !o.ToDate() || o.ToDate() > o.FromDate(), "La data di fine deve essere successiva alla data di inizio")
            .isFalse(o => this.resource.IntersectsWithOrders(o.Id(), o.FromDate(), o.ToDate(), o.FkOperationalUnitId()), "Le date di inizio e fine intersecano con quelle di almeno un altro ordine di servizio")
            .isFalse(o => o.NoWorkingHoursDefined(), "Definire l'orario di lavoro prima di salvare")
            .isFalse(o => o.NoRolesSelected(), "Selezionare almeno una mansione prima di salvare")
            .isFalse(o => o.NoDefaultRoleSelected(), "Selezionare una mansione predefinita prima di salvare")
            .isFalse(o => o.NoCostSelected(), "Selezionare almeno un costo prima di salvare")
            .isFalse(o => o.NoDefaultCostSelected(), "Selezionare un costo predefinito prima di salvare");
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(sender === this.OperationalUnitsDataSource) {
            const ouModel = model as IOperationalUnitsDataSourceModel;
            this.FkOperationalUnitId(ouModel?.id ?? -1);
            this.OperationalUnitCache(ouModel?.model);

            this.ouSettings.getOperationalUnitStandardWorkingHours(this.FkOperationalUnitId())
                .then(swh => {
                    this.OperationalUnitStandardWorkHoursCache(swh);
                });
        }
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
    }

    private async load() {
        this.IsNew(this.serviceOrder?.IsNew() ?? true);
        this.Id(this.serviceOrder?.Id() ?? this.idGeneratorService.getNextId());
        this.Title(this.serviceOrder?.Title())
        this.FromDate(this.serviceOrder?.FromDate());
        this.ToDate(this.serviceOrder?.ToDate());
        this.EstimatedHoursToWork(this.serviceOrder?.EstimatedHoursToWork());
        this.MaxFlexiblePositive(this.serviceOrder?.MaxFlexiblePositive());
        this.MaxFlexibleNegative(this.serviceOrder?.MaxFlexibleNegative());

        await this.OperationalUnitsDataSource.selectByIds(this.serviceOrder?.FkOperationalUnit());

        this.Notes(this.serviceOrder?.Notes());

        for(let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const day = new ServiceOrderWorkingDayEditor(dayOfWeek, this);
            this.HoursPerDay.push(day);

            if(this.serviceOrder) {
                for(const wh of this.serviceOrder.WorkingHours().filter(wh => wh.DayOfWeek() == dayOfWeek)) {
                    day.WorkingHours.push(new ServiceOrderWorkingHourEditor(wh.Id(), wh.DayOfWeek(), wh.Start(), wh.Duration(), this));
                }
                day.isChanged(0);
            }
        }

        const soRoles = this.serviceOrder?.Roles() ?? [];
        for(const role of this.userCharacters.getUserCharactersWithDeleted(this.serviceOrder.ResourceType())) {
            const foundRole = soRoles.firstOrDefault(r => r.Id() === role.IdUserCharacter);

            this.Roles.push(new ServiceOrderRoleEditor(role.IdUserCharacter, role.Description, foundRole?.IncludeInMeanCostCalculations() ?? false, foundRole?.IsDefault() ?? false, !!foundRole, !!role.Eliminato, this));
        }

        const soCosts = this.serviceOrder?.Costs() ?? [];
        for(const cost of this.workTimeCategories.getAll(true, this.serviceOrder.ResourceType())) {
            const foundCost = soCosts.firstOrDefault(c => c.Id() === cost.Id);

            this.Costs.push(new ServiceOrderCostEditor({
                Id: cost.Id,
                Description: cost.Name,
                Cost: foundCost?.HourSalary() ?? cost.DefaultHourSalary,
                IsDefault: foundCost?.IsDefault(),
                IsSelected: !!foundCost,
                IsDeleted: !!cost.Deleted,
                MaxAnnualHours: foundCost?.MaxAnnualHours(),
                OffsetMaxAnnualHours: foundCost?.OffsetMaxAnnualHours(),
                MaxAnnualHoursBehaviour: foundCost?.MaxAnnualHoursBehaviour(),
                MinAnnualHours: foundCost?.MinAnnualHours(),
                MinAnnualHoursBehaviour: foundCost?.MinAnnualHoursBehaviour()
            }, this));
        }
    }

    ShowModal() {
        return this.dialogsService.ShowModal<boolean>(this);
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if (!this.validator.validateAndShowInfoToast(this))
            return;

        this.serviceOrder.IsNew(this.IsNew());
        this.serviceOrder.IsChanged(true);

        this.serviceOrder.Title(this.Title());
        this.serviceOrder.FromDate(this.FromDate());
        this.serviceOrder.ToDate(this.ToDate());
        this.serviceOrder.FkOperationalUnit(this.FkOperationalUnitId());
        this.serviceOrder.OperationalUnitName(this.ouSettings.getById(this.FkOperationalUnitId()).Name);
        this.serviceOrder.EstimatedHoursToWork(this.EstimatedHoursToWork());
        this.serviceOrder.MaxFlexiblePositive(this.MaxFlexiblePositive());
        this.serviceOrder.MaxFlexibleNegative(this.MaxFlexibleNegative());
        this.serviceOrder.Notes(this.Notes());

        this.serviceOrder.HoursMonday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 1).TotalHours());
        this.serviceOrder.HoursTuesday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 2).TotalHours());
        this.serviceOrder.HoursWednesday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 3).TotalHours());
        this.serviceOrder.HoursThursday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 4).TotalHours());
        this.serviceOrder.HoursFriday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 5).TotalHours());
        this.serviceOrder.HoursSaturday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 6).TotalHours());
        this.serviceOrder.HoursSunday(this.HoursPerDay().firstOrDefault(hpd => hpd.dayOfWeek === 7).TotalHours());

        this.serviceOrder.LoadWorkingHours(this.HoursPerDay().selectMultiple(hpd => hpd.getData()));
        this.serviceOrder.LoadRoles(this.Roles().filter(r => r.IsSelected()).map(r => r.getData()));
        this.serviceOrder.LoadCosts(this.Costs().filter(c => c.IsSelected()).map(c => c.getData()));

        this.modal.close(true);
    }

    LoadStandardWorkingHoursForOU() {
        if (this.OperationalUnitStandardWorkHoursCache().length === 0) {
            this.infoToastService.Warning(TextResources.ResourcesManager.StandardWorkingHoursNotDefined);
            return;
        }

        for (const day of this.HoursPerDay()) {
            const slots = this.OperationalUnitStandardWorkHoursCache().filter(wh => wh.DayOfWeek === day.dayOfWeek).sort((a,b) => a.Start.localeCompare(b.Start));
            day.WorkingHours(
                slots.map(s => new ServiceOrderWorkingHourEditor(-1, day.dayOfWeek, moment(s.Start, "HH:mm:ss").toDate(), s.Duration, this))
            );
        }
    }

    ApplyWorkingHoursToAllDays(sourceDay: ServiceOrderWorkingDayEditor) {
        for (const day of this.HoursPerDay()) {
            if (day !== sourceDay) {
                day.CopyFrom(sourceDay);
            }
        }
    }

    async DeleteOrder() {
        if(!await this.dialogsService.ConfirmAsync(TextResources.ResourcesManager.DeleteServiceOrderWarning, TextResources.ResourcesManager.DeleteServiceOrderWarningCancel, TextResources.ResourcesManager.DeleteServiceOrderWarningConfirm))
            return;

        this.resource.DeleteOrder(this.serviceOrder);
        this.modal.close(false);
    }

    renderRoleSelection() {
        let vm : ServiceOrderEditor;
        let role : ServiceOrderRoleEditor;

        return  <>
                    <div>
                        {TextResources.ResourcesManager.ServiceOrderRolesSelectorTitle}
                    </div>
                    <div data-bind={{ slimScroll: 'auto' }}>
                        <table className="table table-condensed table-fixed-header">
                            <thead>
                                <tr>
                                    <th>
                                        <CheckBox checked={this.SelectAllRoles} />
                                        &nbsp;{TextResources.ResourcesManager.ServiceOrderRolesSelectorRole}
                                    </th>
                                    <th className="text-center">{TextResources.ResourcesManager.ServiceOrderRolesSelectorMeanCost}</th>
                                    <th className="text-center">{TextResources.ResourcesManager.ServiceOrderRolesSelectorDefaultRole}</th>
                                </tr>
                            </thead>
                            <tbody data-bind={{ foreach: { data: vm.Roles, as: 'role' } }}>
                            <tr>
                                <td>
                                    <input type="checkbox" data-bind={{ checked: role.IsSelected  }}/>&nbsp;
                                    <span data-bind={{ text: role.Description, style: { 'text-decoration': role.IsDeleted() ? 'line-through' : 'none' } }}></span>
                                </td>
                                <td className="text-center">
                                    <input type="checkbox" data-bind={{ checked: role.IncludeInMeanCostCalculations, enable: role.IsSelected }} />
                                </td>
                                <td className="text-center">
                                    <input type="radio" data-bind={{ radio: role.IsDefault, enable: role.IsSelected }} name="default"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </>;
    }

    renderCostEditor() {
        return  <>
                    <div>
                        {TextResources.ResourcesManager.ServiceOrderCostsSelectorTitle}
                    </div>      
                    <div data-bind={{ slimScroll: 'auto' }} className={classes.costsSelector}>
                        <table className="table table-condensed table-fixed-header">
                            <thead>
                                <tr>
                                    <th className="text-center default-column">{TextResources.ResourcesManager.ServiceOrderCostsSelectorDefaultCost}</th>
                                    <th>
                                        <CheckBox checked={this.SelectAllCosts} />
                                        &nbsp;{TextResources.ResourcesManager.ServiceOrderCostsSelectorWorktimeCategory}</th>
                                        
                                    <th className="cost-column">{TextResources.ResourcesManager.ServiceOrderCostsSelectorCost}</th>
                                    <th className="annual-hours-column text-right">{TextResources.ResourcesManager.ServiceOrderCostsSelectorAnnualHours}</th>
                                    <th className="annual-hours-column text-right">{TextResources.ResourcesManager.ServiceOrderCostMaxAnnualHoursOffset}</th>
                                    <th className="annual-hours-behaviours-column">{TextResources.ResourcesManager.ServiceOrderCostsSelectorAnnualHoursBehaviours}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <TsxForEach data={this.Costs} as="cost">
                                {(cost: ServiceOrderCostEditor) => (<tr>
                                            <td className="text-center v-align-middle">
                                                <input type="radio" data-bind={{ radio: cost.IsDefault, enable: cost.IsSelected }} name="default"></input>
                                            </td>
                                            <td className="v-align-middle">
                                                <CheckBox checked={cost.IsSelected} />&nbsp;
                                                <span data-bind={{ text: cost.Description, style: { 'text-decoration': cost.IsDeleted() ? 'line-through' : 'none' } }}></span>
                                            </td>
                                            <td className="cost-column v-align-middle">
                                                <MoneyInput
                                                    value={cost.Cost}
                                                    readonly={ko.computed(() => !cost.IsSelected())}
                                                    className="no-border-left no-border-right"
                                                    simple
                                                />
                                            </td>
                                            <td className="annual-hours-column">
                                                <div className="flex-container">
                                                    <div className="annual-hours-label">{TextResources.ResourcesManager.ServiceOrderCostMaxAnnualHours}</div>
                                                    <div className="flex-fill">
                                                        <NumberInput
                                                            value={cost.MaxAnnualHours}
                                                            placeholder={TextResources.ProlifeSdk.Unlimited}
                                                            simple
                                                            className="no-border-top no-border-bottom no-border-right"
                                                            readOnly={ko.computed(() => !cost.IsSelected())}
                                                            selectOnFocus
                                                            nullable
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-container">
                                                    <div className="annual-hours-label">{TextResources.ResourcesManager.ServiceOrderCostMinAnnualHours}</div>
                                                    <div className="flex-fill">
                                                        <NumberInput
                                                            value={cost.MinAnnualHours}
                                                            placeholder={TextResources.ProlifeSdk.Unlimited}
                                                            simple
                                                            className="no-border-bottom no-border-right"
                                                            readOnly={ko.computed(() => !cost.IsSelected())}
                                                            selectOnFocus
                                                            nullable
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="annual-hours-column">
                                                <div className="flex-container">
                                                    <div className="flex-fill">
                                                        <NumberInput
                                                            value={cost.OffsetMaxAnnualHours}
                                                            placeholder={TextResources.ProlifeSdk.NoValue}
                                                            simple
                                                            className="no-border-top no-border-right"
                                                            readOnly={ko.computed(() => !cost.IsSelected())}
                                                            selectOnFocus
                                                            nullable
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="annual-hours-behaviours-column">
                                                <div>
                                                    <select className="no-border-top no-border-right no-border-left form-control" data-bind={{ value: cost.MaxAnnualHoursBehaviour, options: cost.MaxAnnualHoursBehaviours, optionsValue: 'Id', optionsText: 'Label', enable: cost.IsSelected }}></select>
                                                </div>
                                                <div>
                                                    <select className="no-border form-control" data-bind={{ value: cost.MinAnnualHoursBehaviour, options: cost.MinAnnualHoursBehaviours, optionsValue: 'Id', optionsText: 'Label', enable: cost.IsSelected }}></select>
                                                </div>
                                            </td>
                                        </tr>
                                )}
                                </TsxForEach>
                            </tbody>

                        </table>
                    </div>
                </>
    }

    renderBody() {
        let vm : ServiceOrderEditor;
        let day: ServiceOrderWorkingDayEditor;
        let hour: ServiceOrderWorkingHourEditor;
        
        return  ComponentUtils.bindTo(
            <div className={"flex-container flex-full-height " + classes.serviceOrderEditor}>
                <div className="col-md-6 flex-container flex-vertical">
                    <div className="form-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderTitleLabel}</label>
                                    <input type="text" className="form-control" spellCheck={false} data-bind={{ value : vm.Title, selectOnFocus: {} }} placeholder={TextResources.ResourcesManager.ServiceOrderTitleLabel} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderFrom}</label>
                                    <div className='input-group date'>
                                        <input className="form-control" data-bind={{ datepicker : vm.FromDate, customDateTimeFormat : 'DD/MM/YYYY HH:mm' }} type="text" />
                                        <span className="input-group-addon">
                                            <span className="glyphicon glyphicon-calendar"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderTo}</label>
                                    <div className='input-group date'>
                                        <input className="form-control" data-bind={{ nullableDateTimePicker : vm.ToDate, customDateTimeFormat : 'DD/MM/YYYY HH:mm' }} type="text" />
                                        <span className="input-group-addon">
                                            <span className="glyphicon glyphicon-calendar"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <select2 dataSource={() => "vm.OperationalUnitsDataSource"} value={() => "vm.FkOperationalUnitId"} label={TextResources.ResourcesManager.OperationalUnit} placeholder={TextResources.ResourcesManager.OperationalUnitPlaceholder} listener={() => "vm"}></select2>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderTotalEstimatedWorkHours}</label>&nbsp;<i className="fa fa-info-circle" title={TextResources.ResourcesManager.ServiceOrderTotalEstimatedWorkHoursToltip}></i>
                                    <input className="form-control" data-bind={{ numberValue : vm.EstimatedHoursToWork, selectOnFocus: {} }} />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderMaxFlexiblePositive}</label>&nbsp;
                                    <input className="form-control" data-bind={{ nullableNumberValue : vm.MaxFlexiblePositive, selectOnFocus: {} }} />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderMaxFlexibleNegative}</label>&nbsp;
                                    <input className="form-control" data-bind={{ nullableNumberValue : vm.MaxFlexibleNegative, selectOnFocus: {} }} />
                                </div>
                            </div>
                        </div>

                        <div className="row" data-bind={{ visible: vm.ShowWorkingHoursPanel }}>
                            <div className="col-md-12">
                            <label className="control-label">{TextResources.ResourcesManager.ServiceOrderTimeSlots}</label>
                                <button type="button" className="btn btn-sm btn-primary" style="margin-left: 30px; margin-bottom: 8px;" data-bind={{ click: vm.LoadStandardWorkingHoursForOU }} title={TextResources.ResourcesManager.ServiceOrderDefaultWorkingTimeButtonTooltip}>{TextResources.ResourcesManager.ServiceOrderDefaultWorkingTimeButton}</button>
                                <table className="table table-bordered table-advance working-hours-editor" >
                                    <thead>
                                        <tr data-bind={{ foreach: { data: vm.HoursPerDay, as: 'day' }}}>
                                            <th className="text-center" style="background-color: #989898; color: white; width: 14%">
                                                <span className="text-uppercase" data-bind={{ text: day.DayName }}></span>
                                                <div className="pull-right">
                                                    <button type="button" className="btn btn-warning btn-xs" title="Applica a tutti i giorni" data-bind={{ click: vm.ApplyWorkingHoursToAllDays.bind(vm, day) }}>
                                                        <i className="icon-loop"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-primary btn-xs" title="Aggiungi fascia di lavoro" data-bind={{ click: day.AddWorkingHours }}>
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr data-bind={{ visible: vm.NoWorkingHoursDefined }}>
                                            <td colSpan={7}>
                                                {TextResources.ResourcesManager.ServiceOrderWorkingTimeNotDefined}
                                            </td>
                                        </tr>
                                        <tr data-bind={{ hidden: vm.NoWorkingHoursDefined, foreach: { data: vm.HoursPerDay, as: 'day' } }}>
                                            <td style="padding: 3px;">
                                                <div data-bind={{ hidden: day.HasWorkingHours }}>
                                                    <span>{TextResources.ResourcesManager.ServiceOrderWorkingTimeslotNotDefined}</span>
                                                </div>
                                                <ko-bind data-bind={{ foreach: { data: day.WorkingHours, as: 'hour' }}}>
                                                    <div className="working-hours-slot">
                                                        <div className="working-hours-start">
                                                            <div className="working-hours-label">{TextResources.ResourcesManager.ServiceOrderWorkingTimeslotStart}</div>
                                                            <input className="form-control" placeholder="Orario" data-bind={{ clockFace: hour.Start, selectOnFocus: {} }}/>
                                                            <button type="button" className="btn btn-danger btn-xs working-hours-delete-slot" data-bind={{ click: day.RemoveWorkingHours.bind(day, hour) }}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        </div>
                                                        <div className="working-hours-duration">
                                                            <div className="working-hours-label">{TextResources.ResourcesManager.ServiceOrderWorkingTimeslotDuration}</div>
                                                            <input className="form-control" placeholder="Numero di ore" data-bind={{ numberValue: hour.Duration, selectOnFocus: {} }} />
                                                        </div>
                                                    </div>
                                                </ko-bind>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr data-bind={{ foreach: { data: vm.HoursPerDay, as: 'day' } }}>
                                            <td className="text-center">
                                                <span data-bind={{ numberText : day.TotalHours }}></span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        
                    <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="control-label">{TextResources.ResourcesManager.ServiceOrderNotes}</label>
                                    <textarea className="form-control" data-bind={{ value : vm.Notes }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 flex-container flex-vertical">
                    <TabNav className="flex-1">
                        <TabNavPage title={TextResources.ResourcesManager.ResourcesRolesAreaTitle}>
                            {() => this.renderRoleSelection()}
                        </TabNavPage>
                        <TabNavPage title={TextResources.ResourcesManager.ResourceCostsDialogTitle}>
                            {() => this.renderCostEditor()}
                        </TabNavPage>
                    </TabNav>
                </div>
            </div>, this, "vm");
    }

    renderFooter() {
        let vm : ServiceOrderEditor;
        return  <div data-as={{vm}}>
                    <a href="#" className="btn default" data-bind={{ click: vm.close }}>Annulla modifiche e chiudi</a>
                    <a href="#" className="btn btn-primary" data-bind={{ click: vm.action }}>Applica le modifiche</a>
                    <ko-bind data-bind={{ ifnot: vm.IsNew }}>
                        <a href="#" className="btn btn-danger" data-bind={{ click: vm.DeleteOrder }}>Elimina</a>
                    </ko-bind>
                </div>;
    }
}

class ServiceOrderWorkingDayEditor extends WorkingDayEditor<ServiceOrderWorkingHourEditor, IHumanResourceOrdersWorkingHours> {
    constructor(dayOfWeek: number, private editor: ServiceOrderEditor) {
        super(dayOfWeek);
    }

    getDefaultStartingHour() : Date {
        const slot = this.editor.OperationalUnitStandardWorkHoursCache()
                .filter(w => w.DayOfWeek === this.dayOfWeek)
                .sort((a,b) => a.Start.localeCompare(b.Start))
                .firstOrDefault();

        return moment(slot?.Start || "08:00:00", "HH:mm:ss").toDate();
    }

    createNewWorkingHour(start: Date): ServiceOrderWorkingHourEditor {
        return this.createWorkingHour(-1, start, 0);
    }

    createWorkingHour(id: number, start: Date, duration: number): ServiceOrderWorkingHourEditor {
        return new ServiceOrderWorkingHourEditor(id, this.dayOfWeek, start, duration, this.editor);
    }
}

class ServiceOrderWorkingHourEditor extends WorkingHourEditor<IHumanResourceOrdersWorkingHours> {
    constructor(id: number, dayOfWeek: number, start: Date, duration: number, private editor : ServiceOrderEditor) {
        super(id, dayOfWeek, start, duration);
    }

    getData(): IHumanResourceOrdersWorkingHours {
        return {
            Id: this.Id(),
            DayOfWeek: this.DayOfWeek(),
            Start: moment(this.Start()).format("HH:mm:ss"),
            End: moment(this.Start()).add('hours', this.Duration()).format("HH:mm:ss"),
            Duration: this.Duration(),
            ServiceOrderId: this.editor.Id()
        };
    }
}

class ServiceOrderRoleEditor {
    Id : ko.Observable<number> = ko.observable();
    IsSelected: ko.Observable<boolean> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    IncludeInMeanCostCalculations: ko.Observable<boolean> = ko.observable();
    IsDefault: ko.Observable<boolean> = ko.observable();
    IsDeleted: ko.Observable<boolean> = ko.observable();
    
    constructor(id: number, description: string, includeInMeanCostCalculations: boolean, isDefault: boolean, isSelected: boolean, isDeleted: boolean, private editor : ServiceOrderEditor) {
        this.Id(id);
        this.IsSelected(isSelected);
        this.Description(description);
        this.IncludeInMeanCostCalculations(includeInMeanCostCalculations);
        this.IsDefault(isDefault);
        this.IsDeleted(isDeleted);
    }

    getData() : IHumanResourceOrdersRoles {
        return {
            FkCharacter: this.Id(),
            FkServiceOrder: this.editor.Id(),
            IncludeInMeanCostCalculations: this.IncludeInMeanCostCalculations(),
            IsDefault: this.IsDefault()
        };
    }
}

type ServiceOrderCost = {
    Id: number;
    Description: string;
    Cost: number;
    IsDefault: boolean;
    IsSelected: boolean;
    IsDeleted: boolean;
    MaxAnnualHours?: number;
    OffsetMaxAnnualHours?: number;
    MinAnnualHours?: number;
    MaxAnnualHoursBehaviour?: number;
    MinAnnualHoursBehaviour?: number;
}

type AnnualHoursBehaviour = {
    Id: number;
    Label: string;
}

class ServiceOrderCostEditor {
    Id : ko.Observable<number> = ko.observable();
    IsSelected: ko.Observable<boolean> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    Cost: ko.Observable<number> = ko.observable();
    MaxAnnualHours : ko.Observable<number> = ko.observable();
    OffsetMaxAnnualHours : ko.Observable<number> = ko.observable();
    MaxAnnualHoursBehaviour : ko.Observable<number> = ko.observable();
    MinAnnualHours : ko.Observable<number> = ko.observable();
    MinAnnualHoursBehaviour : ko.Observable<number> = ko.observable();
    IsDefault: ko.Observable<boolean> = ko.observable();
    IsDeleted: ko.Observable<boolean> = ko.observable();

    MaxAnnualHoursBehaviours: ko.ObservableArray<AnnualHoursBehaviour> = ko.observableArray([]);
    MinAnnualHoursBehaviours: ko.ObservableArray<AnnualHoursBehaviour> = ko.observableArray([]);

    constructor(cost: ServiceOrderCost, private editor : ServiceOrderEditor) {
        this.Id(cost.Id);
        this.IsSelected(cost.IsSelected);
        this.Description(cost.Description);
        this.Cost(cost.Cost);
        this.IsDefault(cost.IsDefault);
        this.IsDeleted(cost.IsDeleted);
        this.MaxAnnualHours(cost.MaxAnnualHours);
        this.OffsetMaxAnnualHours(cost.OffsetMaxAnnualHours);
        this.MaxAnnualHoursBehaviour(cost.MaxAnnualHoursBehaviour);
        this.MinAnnualHours(cost.MinAnnualHours);
        this.MinAnnualHoursBehaviour(cost.MinAnnualHoursBehaviour);

        const maxAnnualHoursBehaviours = [
            { Id: SOCostAnnualHoursBehaviuor.NoAction, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorNoAction },
            { Id: SOCostAnnualHoursBehaviuor.ShowResidual, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorShowResidual },
            { Id: SOCostAnnualHoursBehaviuor.ShowResidualAndAlert, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorShowResidualAndAlert },
            { Id: SOCostAnnualHoursBehaviuor.ShowResidualAndLock, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorShowResidualAndLock },
        ];
        const minAnnualHoursBehaviours = [
            { Id: SOCostAnnualHoursBehaviuor.NoAction, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorNoAction },
            { Id: SOCostAnnualHoursBehaviuor.ShowResidual, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorShowResidual },
            { Id: SOCostAnnualHoursBehaviuor.ShowResidualAndAlert, Label: TextResources.ResourcesManager.SOCostAnnualHoursBehaviuorShowResidualAndAlert }
        ];

        this.MaxAnnualHoursBehaviours(maxAnnualHoursBehaviours);
        this.MinAnnualHoursBehaviours(minAnnualHoursBehaviours);
    }

    getData() : IHumanResourceOrdersSalaries {
        return {
            FkServiceOrder: this.editor.Id(),
            FkWorkTimeCategoryId: this.Id(),
            HourSalary: this.Cost(),
            IsDefault: this.IsDefault(),
            MaxAnnualHoursAmount: this.MaxAnnualHours(),
            OffsetMaxAnnualHoursAmount: this.OffsetMaxAnnualHours(),
            MaxAnnualHoursAmountBehaviour: this.MaxAnnualHoursBehaviour(),
            MinAnnualHoursAmount: this.MinAnnualHours(),
            MinAnnualHoursAmountBehaviour: this.MinAnnualHoursBehaviour()
        }
    }
}