import * as Core from "../Core/Core";
import { Service } from "../Core/DependencyInjection";

export interface IIdGeneratorService {
    getNextId(): number;
}

@Service(nameof<IIdGeneratorService>())
class IdGeneratorService implements IIdGeneratorService {
    private nextId = -1;

    getNextId(): number {
        return this.nextId--;
    }
}

export default function Create() {
    return Core.serviceLocator.findService(nameof<IIdGeneratorService>());
}
