/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2016
 * Time: 10:56
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { IJobOrderService } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IJobOrderStateWithCounter } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import {
    INavigationFilters,
    INavigationMultipleFilters,
} from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class StatesWithCounterNavigationProvider extends NavigationProvider {
    public Counter: number;
    public Icon: string;
    public Background: string;
    public Foreground: string;

    private jobOrderService: IJobOrderService;

    constructor(serviceLocator: IServiceLocator, private state: IJobOrderStateWithCounter) {
        super();
        this.Name = state.Description;
        this.Icon = state.Icon;
        this.Background = state.Background;
        this.Foreground = state.Foreground;
        this.templateName = "job-orders-folder-with-counter";
        this.templateUrl = "jobOrder/templates/navigation";
        this.IsLeaf = true;
        this.Counter = state.JobOrdersCount;

        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public SetFilterInfoOnModel(f: INavigationFilters) {
        f.State = this.state.IdJobOrderStateId;
    }

    public SetMultipleFilterInfoOnModel(f: INavigationMultipleFilters) {
        f.States.push(this.state.IdJobOrderStateId);
    }

    public OnJobOrderDropped(event, ui) {
        event.preventDefault();
        event.stopPropagation();

        const jobOrderId: number = parseInt(ui.draggable.attr("jobOrderId"));
        ui.draggable.remove(); //Rimuovo il task dal DOM (sarà riaggiunto in seguito a notifica server)
        this.jobOrderService.changeState(jobOrderId, this.state.IdJobOrderStateId);
        return true;
    }

    public ValidateJobOrderDrop(status, el) {
        return true; //parseInt($(el).parent().parent().attr("status")) != status;
    }
}
