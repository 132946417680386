import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/06/2018
 * Time: 18:06
 * To change this template use File | Settings | File Templates.
 */

import {
    IProtocolSettingsViewModel,
    IVatRegisterEditingContainer,
} from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class SalProtocolSettings implements IProtocolSettingsViewModel {
    public templateName = "sal-protocol-settings";
    public templateUrl = "joborder/templates/settings";

    PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable(false);
    CFRequired: ko.Observable<boolean> = ko.observable();

    constructor(private container: IVatRegisterEditingContainer, private vatRegister: IVatRegister) {
        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);
        this.CFRequired(this.vatRegister.CFRequired);
    }

    public applyChangesToRegister(vatRegister: IVatRegister) {
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        vatRegister.CFRequired = this.CFRequired();
    }

    public async load() {}

    public isChanged(): boolean {
        return (
            this.vatRegister.PaymentAndExpireModeRequired != this.PaymentAndExpireModeRequired() ||
            this.vatRegister.CFRequired != this.CFRequired()
        );
    }
}
