import {
    IDiscountsCatalogsService,
    IResolvedDiscountCatalogArticle,
    DiscountCatalogRowMode,
} from "../Warehouse/DiscountsCatalogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IDiscountCatalogSolutionDataSourceModel
    extends IDataSourceModel<number, IResolvedDiscountCatalogArticle> {}

export interface IDiscountCatalogSolutionDataSource extends IDataSource {}

export class DiscountCatalogSolutionDataSource implements IDiscountCatalogSolutionDataSource {
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService!: IDiscountsCatalogsService;

    private catalogId: number;
    private view: IDataSourceView;
    private refreshRequested: boolean;

    setCatalogId(catalogId: number) {
        this.catalogId = catalogId;
    }

    getTitle(currentModel: IDiscountCatalogSolutionDataSourceModel): string {
        return "Test Scontistica";
    }

    isGroupedData(currentModel: IDiscountCatalogSolutionDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IDiscountCatalogSolutionDataSourceModel, b: IDiscountCatalogSolutionDataSourceModel): boolean {
        return !!a && !!b && a.id == b.id;
    }

    getData(
        currentModel: IDiscountCatalogSolutionDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDiscountCatalogSolutionDataSourceModel[]> {
        return this.discountsCatalogsService
            .ResolveDiscountCatalog(this.catalogId, textFilter, skip, count)
            .then((articles: IResolvedDiscountCatalogArticle[]) => {
                return articles.map(this.createModelFor, this);
            });
    }

    async getById(
        currentModel: IDiscountCatalogSolutionDataSourceModel,
        ids: (string | number)[]
    ): Promise<IDiscountCatalogSolutionDataSourceModel[]> {
        return [];
    }

    private createModelFor(article: IResolvedDiscountCatalogArticle): IDiscountCatalogSolutionDataSourceModel {
        Object.defineProperty(article, "ModeDescription", {
            value: DiscountCatalogRowMode.getDescription(article.Mode),
        });

        return {
            id: article.ArticleId,
            title: article.Code,
            subTitle: article.Description,
            isLeaf: true,
            isGroup: false,
            model: article,
        };
    }

    refresh() {
        if (!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if (this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
