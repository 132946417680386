import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderMovementsReport } from "./job-order-movements/JobOrderMovementsReport";
import { JobOrdersActivitiesReport } from "./job-orders-activities/JobOrderActivitiesReport";
import { JobOrderWithIncompleteQualityReport } from "./job-order-with-incomplete-quality/JobOrderWithIncompleteQualityReport";
import { JobOrdersAnalysis } from "./job-orders-analysis/JobOrdersAnalysis";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { JobOrdersCostsReport } from "./job-order-costs/JobOrdersCostsReport";
import { JobOrdersAdvancedExportReport } from "./job-order-advanced-export-report/JobOrdersAdvancedExportReport";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class JobOrderReportsProvider implements IReportsProvider
{
    authorizationService : IAuthorizationService;

    Id : number = 4;
    Name : string = ProlifeSdk.TextResources.JobOrder.Orders;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";
    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    private navigator : IReportsNavigator;
    serviceLocator : IServiceLocator;

    constructor(serviceLocator : IServiceLocator)
    {
        this.serviceLocator = serviceLocator;
        this.authorizationService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);
        this.initialize();
    }

    initialize()
    {
        this.Reports([]);

        if(this.authorizationService.isAuthorized("JobOrders_MovementsAndDDTsReport"))
            this.Reports.push(new JobOrderMovementsReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("JobOrders_IncompleteQualityReport"))
            this.Reports.push(new JobOrderWithIncompleteQualityReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("JobOrders_AnalysisReport"))
            this.Reports.push(new JobOrdersAnalysis(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("JobOrders_ActivitiesReport"))
            this.Reports.push(new JobOrdersActivitiesReport(this.serviceLocator, this.Id));
        if(this.authorizationService.isAuthorized("JobOrders_ViewJobOrderCostsReport"))
            this.Reports.push(new JobOrdersCostsReport(this.Id));
        if(this.authorizationService.isAuthorized("JobOrders_ViewJobOrderAdvancedExportReport"))
            this.Reports.push(new JobOrdersAdvancedExportReport(this.Id));

        this.setCurrentNavigator(this.navigator);
    }

    getReportById(reportId : number) : IReport
    {
        var report : IReport = null;

        this.Reports().forEach((r : IReport) => {
            report = r.Id == reportId ? r : report;
        })

        return report;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.navigator = navigator;
        this.Reports().forEach((provider) => provider.setNavigator(navigator));
    }

    Open()
    {
        this.navigator.navigateTo(this);
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}