import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type NumberTextPlaceholders = {
    value: number;
    text: string;
};

type NumericTextProps = {
    value: number | ko.Subscribable<number>;
    format?: string;
    numberTextPlaceholders?: NumberTextPlaceholders[];
    numberTextSuffix?: string;
    textIfNoValue?: string;
    className?: string;
};

export function NumericText(props: NumericTextProps) {
    const C = require("./NumericText")._NumericText as typeof _NumericText;
    return <C {...props} />;
}

export class _NumericText {
    static defaultProps: Partial<NumericTextProps> = {
        format: "0,0.00[0]",
        numberTextPlaceholders: [],
        numberTextSuffix: "",
        textIfNoValue: "",
    };

    constructor(private props: NumericTextProps) {}

    render() {
        const nt = this;
        return ComponentUtils.bindTo(
            <span
                data-bind={{
                    numberText: nt.props.value,
                    format: nt.props.format,
                    numberTextPlaceholders: nt.props.numberTextPlaceholders,
                    numberTextSuffix: nt.props.numberTextSuffix,
                    textIfNoValue: nt.props.textIfNoValue,
                    css: nt.props.className,
                }}></span>,
            this,
            "nt"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(NumericText);
}
