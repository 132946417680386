import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { JobOrderNotes } from "./JobOrderNotes/JobOrderNotes";
import { PageSwitcher } from "../../ProlifeSdk/prolifesdk/PageSwitcher";
import { BlogEventBaseInput } from "../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { EventDetailsPage } from "./EventsDetails/EventDetailsPage";
import { MailBlogEvent } from "./EventViewModels/MailBlogEvent";
import { WorkEvent } from "./EventViewModels/WorkEvent";
import { MaterialsEvent } from "./EventViewModels/MaterialsEvent";
import { CallBlogEvent } from "./EventViewModels/CallBlogEvent";
import { MeetingBlogEvent } from "./EventViewModels/MeetingBlogEvent";
import { MilestoneBlogEvent } from "./EventViewModels/MilestoneBlogEvent";
import { BlogJobOrderAdvancedFilter } from "./ui/BlogJobOrderAdvancedFilter";
import { MultipleSelectionDialog } from "./Dialog/MultipleSelectionDialog";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../DataSources/JobOrdersDataSource";
import {
    WorkflowsAndTasksDataSource,
    IWorkflowsAndTasksDataSourceModel,
} from "../../DataSources/WorkflowsAndTasksDataSource";
import { WorkflowEditDialog } from "../../Todolist/Todolist/ui/workflows/WorkflowEditDialog";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IWorkflowOutcomeSettingsManager } from "../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IWorkflowOutcome } from "../../Todolist/WorkflowOutcomesService";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../DataSources/IDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
    INavigationMenuComponentActionsGroup,
    INavigationMenuComponentMultipleSelectionConfig,
} from "../../Components/NavigationMenuComponent/INavigationMenuComponent";
import {
    IJobOrderForTaskBoard,
    ITaskForTaskBoard,
    ITodoListService,
} from "../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { ITodoListWorkflow } from "../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowEditorResult } from "../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IJobOrderNavigationMenuLeafProvider } from "../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenuLeafProvider";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IApplicationHost } from "../../Desktop/interfaces/IApplicationHost";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { INavigationMenuObserver } from "../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { IPageSwitcherObserver } from "../../ProlifeSdk/interfaces/prolife-sdk/IPageSwitcherObserver";
import { IPageConfigurationMap } from "../../ProlifeSdk/interfaces/IPageConfigurationMap";
import { INavigationMenuProvider } from "../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { ITag } from "../../ProlifeSdk/interfaces/ITag";
import { IBlogEventViewModel } from "../../ProlifeSdk/interfaces/blog/IBlogEventViewModel";
import { AlertsLegendUI } from "../../Components/AlertsLegend";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

export class BlogBooklet
    implements IContextEventsObserver, INavigationMenuObserver, IPageSwitcherObserver, IDataSourceListener
{
    //Elenco eventi
    public jobOrderNotes: JobOrderNotes;

    //Pagine del booklet
    public blogPages: ko.ObservableArray<any> = ko.observableArray([]);

    public currentPage: ko.Computed<any>;

    public templateName = "blog-booklet";
    public templateUrl = "blog/Templates";

    private workflowType = -1;
    private workflowState = -1;
    private jobOrderState = -1;
    /*Per versione mobile */
    public blogBookLetPageSwitcherHandler: PageSwitcher;
    public pageSwitcherMap: IPageConfigurationMap = {};

    @LazyImport(nameof<IApplicationHost>())
    private applicationHostService: IApplicationHost;

    public HasAdvancedFilters: ko.Observable<boolean> = ko.observable(false);

    //Nuovo menu commesse
    private JobOrdersDataSource: JobOrdersDataSource;
    public JobOrdersNavigatorMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private multipleSelectionEnabled: ko.Observable<boolean> = ko.observable();
    private selectedJobOrders: IJobOrderForTaskBoard[] = [];

    //Nuovo menu piani/task
    private TasksDataSource: WorkflowsAndTasksDataSource;
    public TasksNavigatorMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    public selectedTasks: ITaskForTaskBoard[] = [];

    //Servizi
    private serviceLocator: IServiceLocator;

    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    private outcomeService: IWorkflowOutcomeSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IUserInfo>())
    private userInfoService!: IUserInfo;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor(serviceLocator: IServiceLocator) {
        this.serviceLocator = serviceLocator;
        this.jobOrderNotes = new JobOrderNotes(serviceLocator, this);

        this.blogPages.push(this.jobOrderNotes);
        this.currentPage = ko.computed(() => {
            return this.blogPages()[this.blogPages().length - 1];
        });

        this.JobOrdersDataSource = new JobOrdersDataSource(false);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setUserId(this.userInfoService.getIdUser());
        this.JobOrdersNavigatorMenu.subscribe((navigationMenu) => {
            if (!navigationMenu) return;

            if (this.multipleSelectionRequest) {
                navigationMenu.setMultipleSelection(this.multipleSelectionRequest);
            }

            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            };

            navigationMenu.registerAction(showLegendAction);

            const action: INavigationMenuComponentActionsGroup = {
                isGroup: true,
                isSeparator: false,
                icon: () => (this.HasAdvancedFilters() ? "fa fa-filter" : "fa fa-angle-down"),
                actions: [
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "",
                        text: "Abilita selezione multipla",
                        visible: () => !this.multipleSelectionEnabled(),
                        canExecute: () => true,
                        action: () => {
                            this.enableMultipleSelection(true);
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "",
                        text: "Disabilita selezione multipla",
                        visible: () => this.multipleSelectionEnabled(),
                        canExecute: () => true,
                        action: () => {
                            this.enableMultipleSelection(false);
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: true,
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-filter",
                        text: () => "Filtro Avanzato" + (this.HasAdvancedFilters() ? " (ATTIVO)" : ""),
                        visible: () => true,
                        canExecute: () => true,
                        action: () => {
                            this.openFilters();
                        },
                    },
                ],
            };

            navigationMenu.registerAction(action);

            navigationMenu.navigateTo(this.JobOrdersDataSource.getOpenedLogicalState());
        });

        this.TasksDataSource = new WorkflowsAndTasksDataSource();
        this.TasksDataSource.setViewAll(this.authorizationsService.isAuthorized("TaskBoard_CanViewAll"));
        this.TasksDataSource.enableNotifications(true);

        this.TasksNavigatorMenu.subscribe((navigator) => {
            navigator.enableSelectAllChildren();
            const showingSecondaryActions = ko.observable(false);

            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            };

            navigator.registerAction(showLegendAction);

            const action = {
                icon: "fa fa-plus",
                isGroup: true,
                isSeparator: false,
                actions: [
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-plus",
                        text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                        visible: () => true,
                        canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                        action: () => {
                            this.CreateNewWorkflow();
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-copy",
                        text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                        visible: () => true,
                        canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                        action: () => {
                            this.CreateNewWorkflowFromWorkflow();
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-magic",
                        text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                        visible: () => true,
                        canExecute: () =>
                            this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                        action: () => {
                            this.CreateNewWorkflowFromTemplate();
                        },
                    },
                ],
            };

            if (
                this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow") ||
                this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow") ||
                this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
            )
                navigator.registerAction(action);

            if (
                this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow") ||
                this.authorizationsService.isAuthorized("TaskBoard_EditTask")
            ) {
                const startEditAction = {
                    icon: "fa fa-pencil",
                    isGroup: false,
                    isSeparator: false,
                    defaultClass: "btn-primary",
                    visible: () => !showingSecondaryActions(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.TasksNavigatorMenu().showSecondaryAction(true);
                    },
                };

                const endEditAction = {
                    icon: "fa fa-pencil",
                    isGroup: false,
                    isSeparator: false,
                    defaultClass: "btn-warning",
                    visible: () => showingSecondaryActions(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.TasksNavigatorMenu().showSecondaryAction(false);
                    },
                };

                navigator.registerAction(startEditAction);
                navigator.registerAction(endEditAction);
            }

            navigator.registerSearchAction({
                text: "Cerca tutto",
                action: () => this.TasksDataSource.setRecursiveSearch(true),
                icon: "",
                isGroup: false,
                isSeparator: false,
                isDefault: true,
            });

            navigator.registerSearchAction({
                text: "Cerca solo in questa lista",
                action: () => this.TasksDataSource.setRecursiveSearch(false),
                icon: "",
                isGroup: false,
                isSeparator: false,
            });

            navigator.setMultipleSelection({
                keepSelection: true,
                multipleSelection: true,
                selectLeafsOnly: true,
            });

            this.TasksDataSource.setRecursiveSearch(true);
        });

        /* per versione mobile*/
        this.blogBookLetPageSwitcherHandler = new PageSwitcher();
        this.blogBookLetPageSwitcherHandler.addPage(ProlifeSdk.Page_ProjectBlogBookLet, true, "", false);
        this.blogBookLetPageSwitcherHandler.addPage(
            ProlifeSdk.Page_BlogBookLet,
            false,
            ProlifeSdk.TextResources.Blog.BlogBookletPageTitle,
            false
        );
        this.blogBookLetPageSwitcherHandler.addPage(
            ProlifeSdk.Page_ModifyBlog,
            false,
            ProlifeSdk.TextResources.Blog.BlogBookletModifyTitle,
            false
        );
        this.blogBookLetPageSwitcherHandler.addPage(
            ProlifeSdk.Page_NewEvent,
            false,
            ProlifeSdk.TextResources.Blog.BlogBookletModifyTitle,
            false
        );

        this.pageSwitcherMap[ProlifeSdk.Page_ProjectBlogBookLet] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: false,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_BlogBookLet] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_ModifyBlog] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.pageSwitcherMap[ProlifeSdk.Page_NewEvent] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.applicationHostService.SetSideMenuEnabled(true, false);
    }

    public CreateNewWorkflow() {
        const notInitializedStates = this.outcomeService.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: this.selectedJobOrders.length == 1 ? this.selectedJobOrders[0].Id : null,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const vm: WorkflowEditDialog = new WorkflowEditDialog({ workflow: workflow });
        vm.ShowDialog().then((result: IWorkflowEditorResult) => {
            if (!result) return;

            this.TasksDataSource.refresh();
        });
    }

    CreateNewWorkflowFromWorkflow() {
        if (this.selectedJobOrders.length == 0) return;

        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrders[0].Id);
    }

    public CreateNewWorkflowFromTemplate() {
        const jobOrderId = this.selectedJobOrders.length == 1 ? this.selectedJobOrders[0].Id : null;

        this.todoListService.ShowCreateWorkflowFormTemplateDialog(jobOrderId).then((result) => {
            if (!result) return;

            this.TasksDataSource.refresh();
        });
    }

    private multipleSelectionRequest: INavigationMenuComponentMultipleSelectionConfig = null;
    private enableMultipleSelection(enabled: boolean) {
        const options: INavigationMenuComponentMultipleSelectionConfig = {
            selectLeafsOnly: true,
            multipleSelection: enabled,
            keepSelection: true,
        };

        if (!this.JobOrdersNavigatorMenu()) {
            this.multipleSelectionRequest = options;
            this.multipleSelectionEnabled(enabled);
            return;
        }

        this.JobOrdersNavigatorMenu().setMultipleSelection(options);
        this.multipleSelectionEnabled(enabled);
    }

    NotifyEventJobOrderSelected(jobOrderId: number) {
        this.TasksDataSource.setJobOrders(jobOrderId ? [jobOrderId] : []);
    }

    SelectTasks(tasksIds: number[]) {
        this.TasksDataSource.selectTasks(tasksIds);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (this.currentPage() != this.jobOrderNotes) {
            this.currentPage().onItemSelected(sender, model);
            return;
        }

        if (sender === this.JobOrdersDataSource) {
            const jobOrderModel = model as IJobOrderDataSourceModel;
            this.goToPage(ProlifeSdk.Page_BlogBookLet);

            if (!this.multipleSelectionEnabled()) {
                this.selectedJobOrders = [];
                this.TasksNavigatorMenu().navigateTo();
            }

            this.selectedJobOrders.push(jobOrderModel.model);

            this.jobOrderNotes.setJobOrders(this.selectedJobOrders);
            this.TasksDataSource.setJobOrders(this.selectedJobOrders.map((j) => j.Id));
        } else if (sender === this.TasksDataSource) {
            const taskModel = model as IWorkflowsAndTasksDataSourceModel;

            this.selectedTasks.push(taskModel.model as ITaskForTaskBoard);
            this.jobOrderNotes.setSelectedTasks(this.selectedTasks);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (this.currentPage() != this.jobOrderNotes) {
            this.currentPage().onItemDeselected(sender, model);
            return;
        }

        if (sender === this.JobOrdersDataSource) {
            const jobOrderModel = model as IJobOrderDataSourceModel;

            let index = -1;
            for (const jobOrder of this.selectedJobOrders) {
                index++;
                if (jobOrder.Id == jobOrderModel.id) {
                    this.selectedJobOrders.splice(index, 1);
                    break;
                }
            }

            this.jobOrderNotes.setJobOrders(this.selectedJobOrders);
            this.TasksDataSource.setJobOrders(this.selectedJobOrders.map((j) => j.Id));
        } else if (sender === this.TasksDataSource) {
            const taskModel = model as IWorkflowsAndTasksDataSourceModel;

            let index = -1;
            for (const task of this.selectedTasks) {
                index++;
                if (task.Id == taskModel.id) {
                    this.selectedTasks.splice(index, 1);
                    break;
                }
            }

            this.jobOrderNotes.setSelectedTasks(this.selectedTasks);
        }
    }

    canSelectItem?(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return new Promise((resolve, reject) => resolve(true));
    }

    public dispose() {
        this.blogPages().forEach((p) => {
            p.dispose();
        });
        this.TasksDataSource.enableNotifications(false);
    }

    public onBeforeUnload(): boolean {
        return this.currentPage() == this.jobOrderNotes || confirm(ProlifeSdk.TextResources.Blog.ConfirmUnload);
    }

    public selectJobOrdersAndTasks(jobOrdersIds: number[], tasksIds: number[]) {
        this.enableMultipleSelection(jobOrdersIds.length > 1);

        this.JobOrdersDataSource.selectJobOrders(...jobOrdersIds).then(() => {
            this.JobOrdersDataSource.navigateToJobOrder(jobOrdersIds[0]).then(() => {
                this.jobOrderNotes.setSelectedTasksById(tasksIds);
                setTimeout(() => this.TasksDataSource.selectTasks(tasksIds), 500);
            });
        });
    }

    public navigateToJobOrderId(jobOrderId: number) {
        this.JobOrdersDataSource.navigateToJobOrder(jobOrderId);
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {
        //Nulla da fare
    }

    onSelectionChanged(selection: INavigationMenuProvider[]) {
        this.goToPage(ProlifeSdk.Page_BlogBookLet);
        this.jobOrderNotes.setJobOrders(selection.map((p: IJobOrderNavigationMenuLeafProvider) => p.jobOrder));
    }

    RemoveJobOrderFromSelection(jobOrder: IJobOrderForTaskBoard): void {
        const remainingJobOrders = this.selectedJobOrders.slice(0);

        let index = -1;
        for (const j of remainingJobOrders) {
            index++;
            if (j.Id === jobOrder.Id) {
                remainingJobOrders.splice(index, 1);
                break;
            }
        }

        this.selectedJobOrders = [];
        this.JobOrdersDataSource.selectJobOrders(...remainingJobOrders.map((j) => j.Id));
    }

    public goHome(): void {
        location.href = "#/";
    }

    public RefreshEvents(): void {
        this.jobOrderNotes.RefreshEvents();
    }

    public GetTagForNewEvents(): ITag[] {
        return this.jobOrderNotes.GetTagForNewEvents();
    }

    public OnEventPageSave() {
        this.OnEventPageClose();
        this.jobOrderNotes.RefreshAfterEventSaved();
        this.TasksDataSource.refresh();
    }

    public OnEventPageClose() {
        this.currentPage().dispose();
        this.goBack();
        this.blogPages.pop();

        this.TasksDataSource.popState();
    }

    private OpenEvent(event: IBlogEventViewModel, createNew: boolean, pageName?: string) {
        this.TasksDataSource.pushState();

        if (this.blogPages().length > 1) this.blogPages.pop();

        if (createNew && this.jobOrderNotes.jobOrdersToShowEvents().length == 1)
            (<any>event).JobOrder(this.jobOrderNotes.jobOrdersToShowEvents()[0].Id);

        const eventEditMask = <BlogEventBaseInput>event.getEditViewModel();

        const page = new EventDetailsPage(this.serviceLocator, eventEditMask, this);
        this.blogPages.push(page);
        if (pageName !== null || pageName !== undefined) this.goToPage(pageName);
    }

    public EditEvent(event: IBlogEventViewModel) {
        this.OpenEvent(event, false, ProlifeSdk.Page_ModifyBlog);
    }

    public NewMail(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new MailBlogEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    public NewWork(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new WorkEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    public NewMaterial(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new MaterialsEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    public NewCall(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new CallBlogEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    public NewMeeting(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new MeetingBlogEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    public NewDecision(): void {
        if (this.jobOrderNotes.jobOrdersToShowEvents().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Blog.PleaseSelectAJobOrder);
            return;
        }
        this.OpenEvent(new MilestoneBlogEvent(this.serviceLocator, this), true, ProlifeSdk.Page_NewEvent);
        this.applicationHostService.closeMenu();
    }

    onGoingBack(newPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined) this.pageSwitcherMap[newPageName].onGoingBack();
    }

    onPageChanged(newPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined)
            this.applicationHostService.SetSideMenuEnabled(
                this.pageSwitcherMap[newPageName].LeftMenuEnabled,
                this.pageSwitcherMap[newPageName].RightMenuEnabled
            );
    }
    public openFilters() {
        const dialog = new BlogJobOrderAdvancedFilter(
            this.serviceLocator,
            this.workflowType,
            this.workflowState,
            this.jobOrderState
        );
        this.dialogsService.ShowModal<boolean>(dialog).then((result: boolean) => {
            if (!result) return;

            this.workflowType = dialog.Type() == undefined ? -1 : dialog.Type();
            this.workflowState = dialog.State() == undefined ? -1 : dialog.State();
            this.jobOrderState = dialog.ProjectState() == undefined ? -1 : dialog.ProjectState();

            this.HasAdvancedFilters(this.workflowType != -1 || this.workflowState != -1 || this.jobOrderState != -1);

            this.JobOrdersDataSource.setAdvancedFilters({
                workflowType: this.workflowType,
                workflowState: this.workflowState,
                jobOrderState: this.jobOrderState,
            });
            if (this.JobOrdersNavigatorMenu()) this.JobOrdersNavigatorMenu().refresh();
        });
    }

    goToPage(pageName: string) {
        this.onPageChanged(pageName);
        this.blogBookLetPageSwitcherHandler.goToPage(pageName);
    }

    goBack() {
        this.onPageChanged(this.blogBookLetPageSwitcherHandler.newPageBack());
        this.blogBookLetPageSwitcherHandler.goBack();
    }

    openDialogForSelection() {
        const dialog = new MultipleSelectionDialog(this.serviceLocator, this);
        dialog.ShowModal();
    }
}
