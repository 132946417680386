import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../../DataSources/JobOrdersDataSource";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ExpenseProjectPurchase } from "./ExpenseProjectPurchase";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { PurchaseTypeId, Total } from "./dialogs/ExpenseEditDialog";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IExpenseService, IFullExpenseExpensesProjects, IExpenseEventsAndPurchasesPurchases } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class ExpenseProject implements IDataSourceListener
{
    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public get Id(): number {
        return this.m_id;
    }

    public Percentage : ko.Observable<number> = ko.observable(0);
    public ProjectId : ko.Observable<number> = ko.observable(0);
    public ProjectNotes : ko.Observable<string> = ko.observable("");
    public ProjectName : ko.Observable<string> = ko.observable("");
    public ProjectType : ko.Observable<string> = ko.observable("");
    
    public HidePurchases : ko.Observable<boolean> = ko.observable(true);
    public ShowNotes : ko.Observable<boolean> = ko.observable(false);
    public NotesTrigger: ko.Observable<any> = ko.observable();

    public TotalCosts: ko.NotifiableComputed<number>;
    public TotalExpectedRevenues: ko.NotifiableComputed<number>;

    public PurchasesCosts: ko.ObservableArray<ExpenseProjectPurchase> = ko.observableArray([]);
    public PurchasesExpectedRevenues: ko.ObservableArray<ExpenseProjectPurchase> = ko.observableArray([]);

    public JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    
    public get CanCreateTask(): boolean {
        return this.m_canCreateTask;
    }

    public get CanCreateWorkflow(): boolean {
        return this.m_canCreateWorkflow;
    }
    
    private m_canCreateTask: boolean = false;
    private m_canCreateWorkflow: boolean = false;
    private m_canViewAll: boolean = false;

    private m_id: number;

    constructor(private expenseProject: IFullExpenseExpensesProjects = null, purchases: IExpenseEventsAndPurchasesPurchases[] = null)
    {
        this.m_canCreateTask = this.authorizationsService.isAuthorized("TaskBoard_InsertTask");
        this.m_canCreateWorkflow = this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow");
        this.m_canViewAll = this.authorizationsService.isAuthorized("TaskBoard_CanViewAll");

        this.m_id = this.expenseProject?.Id ?? this.expenseService.GetTemporaryId();

        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());

        this.Percentage(this.expenseProject?.Percentage);
        this.ProjectNotes(this.expenseProject?.Notes);
        this.NotesTrigger.valueHasMutated();
        this.ProjectId(this.expenseProject?.ProjectId);
        this.ProjectName(this.expenseProject?.ProjectName);
        this.ProjectType(this.expenseProject?.ProjectType);

        this.TotalCosts = ko.utils.notifyableComputed(() => {
            let costs = this.PurchasesCosts();
            return this.calculateTotal(costs);
        });

        this.TotalExpectedRevenues = ko.utils.notifyableComputed(() => {
            let costs = this.PurchasesExpectedRevenues();
            return this.calculateTotal(costs);
        });

        if (purchases !== null)
            this.loadPurchases(purchases);
    }

    private setPurchasesCosts(costs: ExpenseProjectPurchase[]) {
        this.TotalCosts.valueWillMutate();

        this.PurchasesCosts(costs);
        
        this.TotalCosts.valueHasMutated();
    }

    private setPurchasesExpectedRevenues(revenues: ExpenseProjectPurchase[]) {
        this.TotalExpectedRevenues.valueWillMutate();

        this.PurchasesExpectedRevenues(revenues);
        
        this.TotalExpectedRevenues.valueHasMutated();
    }

    public toggleNotesVisibility(): void {
        this.ShowNotes(!this.ShowNotes());
    }
    
    public async createNewTask(): Promise<void> {
        if (!this.canCreateTask())
            return;

        await this.todolistService.ShowCreateNewTaskDialog(this.ProjectId(), null);
    }
    
    public async createNewTaskFromTask(): Promise<void> {
        if (!this.canCreateTask())
            return;

        await this.todolistService.ShowCopyTaskDialog(null, this.ProjectId(), { initialViewAll: this.m_canViewAll });
    }
    
    public async createNewTaskFromTemplate(): Promise<void> {
        if (!this.CanCreateTask)
            return;

        await this.todolistService.ShowCreateTaskFromTemplateDialog(null, this.ProjectId(), { initialViewAll: this.m_canViewAll });
    }

    public async createNewWorkflow(): Promise<void> {
        if (!this.canCreateWorkflow())
            return;

        await this.todolistService.ShowCreateWorkflowDialog(this.ProjectId());
    }

    public async createNewWorkflowFromWorkflow(): Promise<void> {
        if (!this.canCreateWorkflow())
            return;

        await this.todolistService.ShowCreateWorkflowFromWorkflowDialog(this.ProjectId());
    }
    
    public async createNewWorkflowFromTemplate(): Promise<void> {
        if (!this.canCreateWorkflow())
            return;

        await this.todolistService.ShowCreateWorkflowFormTemplateDialog(this.ProjectId());
    }

    public addPurchase(purchase: IExpenseEventsAndPurchasesPurchases, actualPurchaseTypeTotalCost: number): ExpenseProjectPurchase {
        purchase.UnitMoney = actualPurchaseTypeTotalCost * (this.Percentage() / 100);
        purchase.NetMoney = purchase.UnitMoney;
        purchase.TotalMoney = purchase.UnitMoney;
        purchase.Description = TextResources.Expenses.Expenses + (!this.ProjectNotes() ? "" : ": " + this.ProjectNotes());
        
        return this.loadPurchase(purchase);
    }

    public copyPurchaseCostToExpectedRevenue(costModel: ExpenseProjectPurchase): void {
        let purchaseType = costModel.purchaseTypeId;

        let expectedRevenue = this.PurchasesExpectedRevenues().firstOrDefault(i => i.purchaseTypeId === purchaseType);
        if (!expectedRevenue) {
            let purchaseRow: IExpenseEventsAndPurchasesPurchases = {
                PurchaseId: null,
                Billed: false,
                PurchasePrice: null,
                SellPrice: null,
                EntityType: ProlifeSdk.PurchasesEntityTypeCode,
                EventId: null,
                JobOrderId: this.ProjectId(),
                PurchaseDate: null,
                Description: null,
                RowDescription: null,
                Amount: 1,
                UnitMoney: 0,
                Discount: undefined,
                NetMoney: 0,
                TotalMoney: 0,
                Billable: true,
                IsCost: false,
                PurchaseType: purchaseType
            };

            expectedRevenue = this.addPurchase(purchaseRow, 0);
        }
        
        let markup = costModel.Markup();
        let cost = (costModel.UnitCost() || 0);
        let calculatedCost = !markup ? cost : cost + (cost * (markup / 100));
        
        expectedRevenue.Description(costModel.Description());
        expectedRevenue.WorkflowId(costModel.WorkflowId());
        expectedRevenue.TaskId(costModel.TaskId());
        expectedRevenue.UnitCost(calculatedCost);
        expectedRevenue.Discount(undefined);
        expectedRevenue.NetCost(calculatedCost);
    }

    public removePurchasesByTypeMatching(purchases: IExpenseEventsAndPurchasesPurchases[]) {
        let purchaseTypes = purchases.map(p => p.PurchaseType).distinctValues();
        let costs = this.PurchasesCosts();
        let revenues = this.PurchasesExpectedRevenues();

        let costsToRemove = [];

        for (let cost of costs) {
            if (purchaseTypes.indexOf(cost.purchaseTypeId) < 0)
                costsToRemove.push(cost);
        }

        costsToRemove.forEach(c => costs.remove(c));

        let revenuesToRemove = [];

        for (let revenue of revenues) {
            if (purchaseTypes.indexOf(revenue.purchaseTypeId) < 0)
                revenuesToRemove.push(revenue);
        }

        revenuesToRemove.forEach(r => revenues.remove(r));

        this.setPurchasesCosts(costs);
        this.setPurchasesExpectedRevenues(revenues);
    }

    public removeExpectedRevenue(purchase: ExpenseProjectPurchase): void {
        let purchases = this.PurchasesExpectedRevenues();

        for (let i = 0; i < purchases.length; i++) {
            let p = purchases[i];

            if (p.Id === purchase.Id) {
                purchases.remove(p);
                break;
            }
        }

        this.setPurchasesExpectedRevenues(purchases);
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, IJobOrderDataSourceModel>): void {
        this.ProjectType("JOB");
        this.ProjectId(model?.id);
        this.ProjectName(model?.title);

        this.onJobOrderChanged(model?.id);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, IJobOrderDataSourceModel>): void {
        this.ProjectId(null);
        this.ProjectName(null);

        this.onJobOrderChanged(model?.id);
    }

    public recalculateCosts(totalCostsPerPurchaseType: Map<PurchaseTypeId, Total>) {
        let purchasesCosts = this.PurchasesCosts();
        for (let purchase of purchasesCosts) {
            let total = totalCostsPerPurchaseType.get(purchase.purchaseTypeId);
            let percentage = this.Percentage();

            purchase.UnitCost((total || 0) * (percentage / 100));
        }
    }
    
    public async selectActivities(): Promise<void> {
        // TODO selezione delle attività da legare all'evento di blog. Per il momento vengono prese dalle attività legate agli acquisti
    }

    public getData(): IFullExpenseExpensesProjects
    {
        this.expenseProject = this.expenseProject || ({} as IFullExpenseExpensesProjects);

        this.expenseProject.Percentage = this.Percentage();
        this.expenseProject.ProjectId = this.ProjectId();
        this.expenseProject.Notes = this.ProjectNotes();
        this.expenseProject.ProjectName = this.ProjectName();
        this.expenseProject.ProjectType = this.ProjectType();

        if (!this.expenseProject.Id)
            this.expenseProject.Id = this.m_id;

        return this.expenseProject;
    }

    public getPurchasesData(): IExpenseEventsAndPurchasesPurchases[] {
        let costs = this.PurchasesCosts().map(i => i.getData());
        let expectedRevenues = this.PurchasesExpectedRevenues().map(i => i.getData());
        let allPurchases = costs.concat(expectedRevenues);
        allPurchases.forEach((p) => p.JobOrderId = this.ProjectId());
        
        return allPurchases;
    }

    public createPurchaseModel(purchase: ExpenseProjectPurchase): IDataSourceModel<number, ExpenseProjectPurchase> {
        return {
            id: purchase.Id,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: purchase
        };
    }

    private canCreateTask(): boolean {
        if (!this.CanCreateTask)
            return false;

        if (!this.ProjectId()) {
            this.infoToastService.Warning(TextResources.Expenses.JobOrderSelectionRequired);
            return false;
        }

        return true;
    }

    private canCreateWorkflow(): boolean {
        if (!this.CanCreateWorkflow)
            return false;

        if (!this.ProjectId()) {
            this.infoToastService.Warning(TextResources.Expenses.JobOrderSelectionRequired);
            return false;
        }

        return true;
    }

    private loadPurchases(purchases: IExpenseEventsAndPurchasesPurchases[]): void {
        purchases.forEach(p => this.loadPurchase(p));
    }
    
    private loadPurchase(purchase: IExpenseEventsAndPurchasesPurchases): ExpenseProjectPurchase {
        if (purchase.IsCost)
            return this.addPurchaseCost(purchase);
        else
            return this.addPurchaseExpectedRevenue(purchase);
    }

    private addPurchaseExpectedRevenue(purchase: IExpenseEventsAndPurchasesPurchases): ExpenseProjectPurchase {
        let expectedRevenues = this.PurchasesExpectedRevenues();

        let existingExpectedRevenue = expectedRevenues.firstOrDefault(c => c.purchaseTypeId === purchase.PurchaseType);
        if (existingExpectedRevenue)
            return existingExpectedRevenue;

        let expectedRevenue: ExpenseProjectPurchase = new ExpenseProjectPurchase(purchase);

        expectedRevenues.push(expectedRevenue);
        this.setPurchasesExpectedRevenues(expectedRevenues);

        return expectedRevenue;
    }

    private addPurchaseCost(purchase: IExpenseEventsAndPurchasesPurchases): ExpenseProjectPurchase {
        let costs = this.PurchasesCosts();
        let existingCost = costs.firstOrDefault(c => c.purchaseTypeId === purchase.PurchaseType);
        if (existingCost)
            return existingCost;

        let cost: ExpenseProjectPurchase = new ExpenseProjectPurchase(purchase);

        costs.push(cost);

        this.setPurchasesCosts(costs);

        return cost;
    }

    private onJobOrderChanged(jobOrderId: number) {
        let purchasesCosts = this.PurchasesCosts();
        purchasesCosts.forEach(p => p.setJobOrder(jobOrderId));

        let purchasesExpectedRevenues = this.PurchasesExpectedRevenues();
        purchasesExpectedRevenues.forEach(p => p.setJobOrder(jobOrderId));
    }
    
    private calculateTotal(items: ExpenseProjectPurchase[]): number {
        return items.sum(c => c.NetCost());
    }

    private switchPurchaseVisibility(): void {
        this.HidePurchases(!this.HidePurchases());
    }
}