import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 03/12/2015
 * Time: 12:49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class TemplateActivitiesImportResultDialog implements IDialog {
    templateName: string = "template-activities-import-result-dialog";
    templateUrl: string = "todolist/templates/templates-designer";
    title : string = ProlifeSdk.TextResources.Todolist.TemplateImportSummary;

    modal: { close: (result?: any) => void; };

    constructor(private serviceLocator : IServiceLocator, public result : any)
    {

    }

    dispose()
    {
    }

    public close(): void
    {
        this.modal.close();
    }

    public action(): void
    {
        this.modal.close();
    }
}