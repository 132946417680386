import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { TasksUtils } from "../Todolist/Todolist/ui/utils/TasksUtils";
import { INavigationMenuComponentModel } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { ITaskForTaskBoard, ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IObjectChangesInfo,
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
} from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

export type ITasksDataSourceModel = INavigationMenuComponentModel<number, ITaskForTaskBoard>;

type NotificationAction = (changesInfo: IObjectChangesInfo, sentByMe: boolean) => void;
interface ITasksDataSourceNotificationsActions {
    [actionId: number]: NotificationAction;
}

export class TasksDataSource implements IDataSource, IChangesNotificationsServiceObserver {
    @LazyImport(nameof<ITodoListService>())
    private todoListService!: ITodoListService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService!: IChangesNotificationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo!: IUserInfo;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    private userId: number = null;
    private resourceId: number = null;
    private jobOrderId = 0;
    private workflowIds: number[] = [];
    private foldersIds: number[] = [];
    private showClosed = true;
    private viewAll = false;
    private getTasksIfNoFilter = false;
    private getWorkflowsInWorkflows = true;
    private searchOnWorkflowTitle = false;

    private view: IDataSourceView;

    private notificationsEnabled = false;
    private showWorkflowSubtitle = false;
    private showTaskState = true;
    private canViewAll = false;
    private bypassCanViewAllForJobOrderTasksPlanning = false;

    private notificationsActions: ITasksDataSourceNotificationsActions = {
        0: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*if(changesInfo.Object && changesInfo.Object.Task) {
                let model = this.createModelForTask(changesInfo.Object.Task);
                this.view.onItemCreated(model);
            }*/
            this.refresh();
        },
        1: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createModelForTask(changesInfo.Object.Task);
            this.view.onItemUpdated(model);*/
            this.refresh();
        },
        2: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createMinimalModelForTask(changesInfo.Object.Task);
            this.view.onItemDeleted(model);*/
            this.refresh();
        },
        8: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createMinimalModelForTask(changesInfo.Object.Task);
            this.view.onItemDeleted(model);*/
            this.refresh();
        },
        10: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createMinimalModelForTask(changesInfo.Object.Task);
            this.view.onItemDeleted(model);*/
            this.refresh();
        },
    };

    constructor() {
        this.canViewAll = this.authorizationsService.isAuthorized("TaskBoard_CanViewAll");
    }

    setViewAll(value: boolean): void {
        this.viewAll = value;
    }

    setBypassCanViewAllForJobOrderTasksPlanning(value: boolean): void {
        this.bypassCanViewAllForJobOrderTasksPlanning = value;
    }

    setView(view: IDataSourceView): void {
        this.view = view;
        this.refresh();
    }

    setGetTasksIfNoFilter(value: boolean): void {
        this.getTasksIfNoFilter = value;
    }

    setWorkflows(workflowIds: number[], refresh = true) {
        this.workflowIds = workflowIds.slice();

        if (this.view && refresh) this.view.refresh(true);
    }

    setFolders(foldersIds: number[], refresh = true) {
        this.foldersIds = foldersIds.slice();

        if (this.view && refresh) this.view.refresh(true);
    }

    setUserId(userId: number): void {
        this.userId = userId;
    }

    setResourceId(resourceId: number): void {
        this.resourceId = resourceId;
    }

    setJobOrderId(jobOrderId: number): void {
        this.jobOrderId = jobOrderId;
    }

    setShowClosed(value: boolean): void {
        this.showClosed = value;
    }

    setGetWorkflowsInWorkflows(value: boolean): void {
        this.getWorkflowsInWorkflows = value;
    }

    setShowTaskState(value: boolean): void {
        this.showTaskState = value;
    }

    setShowWorkflowSubtitle(value: boolean): void {
        this.showWorkflowSubtitle = value;
    }

    setSearchOnWorkflowTitle(value: boolean): void {
        this.searchOnWorkflowTitle = value;
    }

    enableNotifications(enabled: boolean) {
        if (this.notificationsEnabled == enabled) return;

        if (enabled) {
            this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);

            //d.collantoni: Notifica disabilitata in data 23/05/2023
            //this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.JobOrderTaskEntityTypeCode, this);
        } else this.changesNotificationsService.RemoveObserver(this);

        this.notificationsEnabled = enabled;
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sentByMe: boolean) {
        let workflowId: number = null;
        //let taskId: number = null;

        if (changesInfo.EntityCode == ProlifeSdk.TaskBoardTaskEntityTypeCode) {
            //taskId = changesInfo.Object.Task.Id;
            workflowId = changesInfo.Object.workflowId;
        }

        //d.collantoni: Notifica disabilitata in data 23/05/2023
        /*if (changesInfo.EntityCode == ProlifeSdk.JobOrderTaskEntityTypeCode) {
            //taskId = changesInfo.Object.Task.Id;
            workflowId = (changesInfo.Object.Task.RelatedWorkflow || changesInfo.Object.Task.RelatedWorkFlow);
        }*/

        if (this.workflowIds.indexOf(workflowId) == -1) return true;

        this.notificationsActions[changesInfo.Action](changesInfo, sentByMe);
        return false;
    }

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Todolist.Tasks;
    }

    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    refresh() {
        if (!this.view) return;

        this.view.refresh(true);
    }

    async getData(
        currentModel: ITasksDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<ITasksDataSourceModel[]> {
        console.log(
            "currentModel: ",
            currentModel,
            "textFilter: ",
            textFilter,
            "skip: ",
            skip,
            "count: ",
            count,
            "workflows:",
            this.workflowIds
        );

        if (!this.workflowIds) return [];

        console.log("Querying server for tasks in workflows ", this.workflowIds);
        const params = {
            textFilter: textFilter,
            userId: this.userId,
            resourceId: this.resourceId,
            workflows: this.workflowIds,
            statusIds: this.foldersIds,
            viewWorker: true,
            viewResponsible: true,
            skip: skip,
            count: count,
            viewAll: (this.canViewAll || this.bypassCanViewAllForJobOrderTasksPlanning) && this.viewAll,
            hideNoWork: false,
            destinationTaskBoardStatus: null,
            startDate: null,
            endDate: null,
            activityProgressMode: null,
            showClosed: this.showClosed,
            jobOrderId: this.jobOrderId,
            getTasksIfNoFilter: this.getTasksIfNoFilter,
            getWorkflowsInWorkflows: this.getWorkflowsInWorkflows,
            searchOnWorkflowTitle: this.searchOnWorkflowTitle,
        };

        const tasks = await this.todoListService.GetTasksForUser(params);
        console.log("Tasks: ", tasks);

        return this.createModelsForTasks(tasks);
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        const tasks = await this.todoListService.GetTasksForUserById(null, ids);
        return this.createModelsForTasks(tasks);
    }

    createModelsForTasks(tasks: ITaskForTaskBoard[]): ITasksDataSourceModel[] {
        return tasks.map(this.createModelForTask.bind(this));
    }

    private createModelForTask(t: ITaskForTaskBoard): ITasksDataSourceModel {
        const task: ITasksDataSourceModel = {
            id: t.Id,
            isGroup: false,
            isLeaf: true,
            title:
                t.Title +
                (this.showTaskState && this.showWorkflowSubtitle
                    ? " (" + TasksUtils.getTaskStatusName(t.TaskBoardStatus) + ")"
                    : ""),
            dragEnabled: true,
            icon: TasksUtils.getTaskStatusIcon(t.TaskBoardStatus),
            subTitle: this.showWorkflowSubtitle
                ? t.WorkflowTitle
                : this.showTaskState
                ? ProlifeSdk.TextResources.Todolist.Status + ": " + TasksUtils.getTaskStatusName(t.TaskBoardStatus)
                : "",
            badge: [
                {
                    text: t.BlogEventsCount.toString(),
                    cssClass: t.BlogEventsCount == 0 ? "badge-danger" : "badge-success",
                },
            ],
            alerts: {
                label: ProlifeSdk.TextResources.Todolist.Alerts,
                icons: TasksUtils.getAlertsForTask(t),
            },
            model: t,
        };

        if (t.IsAllocated) {
            task.iconsList = [
                {
                    icon: "fa fa-shopping-cart",
                    background: t.HasEstimatedWork ? "#45b6af" : "#f3565d",
                    foreground: "#ffffff",
                },
            ];
        }

        if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
            task.secondaryAction = {
                icon: {
                    icon: "fa fa-pencil",
                    background: "#ecbc29",
                    foreground: "white",
                },
                action: () => {
                    this.todoListService.ShowEditTaskDialog(t.Id).then((result) => {
                        if (!result) return;
                        this.view.refresh();
                    });
                },
            };
        }

        return task;
    }

    public getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-task", "application/prolife-tasks"];
    }

    public onItemBeginMove(model: ITasksDataSourceModel, dataTransfer: DataTransfer) {
        const task = model.model;

        const draggedTask: IDraggedTask = {
            IsTask: task.IsTask,
            TaskId: task.Id,
            TaskBoardStatus: task.TaskBoardStatus,
            WorkflowId: task.WorkflowId,
            JobOrderId: task.JobOrderId,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };

        dataTransfer.setData("text/plain", task.Title);
        dataTransfer.setData("application/prolife-task", JSON.stringify(draggedTask));
    }

    async onItemMoved(dataTransfer: DataTransfer, model: IDataSourceModel, before: boolean): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-tasks") >= 0 && model) {
            const droppedTasks: IDraggedTask[] = JSON.parse(dataTransfer.getData("application/prolife-tasks"));

            for (const droppedTask of droppedTasks) {
                const task: ITasksDataSourceModel = <ITasksDataSourceModel>model;
                this.moveTask(droppedTask, task, before);
            }
        } else if (dataTransfer.types.indexOf("application/prolife-task") >= 0 && model) {
            const droppedTask: IDraggedTask = JSON.parse(dataTransfer.getData("application/prolife-task"));
            const task: ITasksDataSourceModel = <ITasksDataSourceModel>model;
            this.moveTask(droppedTask, task, before);
        }
    }

    private moveTask(droppedTask: IDraggedTask, task: ITasksDataSourceModel, before: boolean) {
        if (this.userInfo.getCurrentCompanyGuid() != droppedTask.CompanyGuid) return;

        if (task.model.JobOrderId != droppedTask.JobOrderId) return;

        this.todoListService.MoveTask(droppedTask.TaskId, droppedTask.IsTask, task.model.Id, task.model.IsTask, before);
    }
}
