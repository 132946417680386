import { BaseDataSource } from "./BaseDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "./IDataSource";
import { IElectronicInvoiceType, IInvoicesService, IGetElectronicInvoiceTypesRequest } from "../ProlifeSdk/interfaces/invoice/IInvoicesService";

export type IElectronicInvoiceTypesDataSourceModel = IDataSourceModel<string, IElectronicInvoiceType>

export class ElectronicInvoiceTypesDataSource extends BaseDataSource<IElectronicInvoiceTypesDataSourceModel> {
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    private getForElectronicInvoicing = true;
    private getForSimplifiedElectronicInvoicing = false;
    private getForEsterometro = false;

    public getTitle(currentModel: IElectronicInvoiceTypesDataSourceModel): string {
        return TextResources.Invoices.ElectronicInvoiceTypes;
    }
    
    public async getData(currentModel: IElectronicInvoiceTypesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IElectronicInvoiceTypesDataSourceModel[]> {
        const request: IGetElectronicInvoiceTypesRequest = {
            textFilter: textFilter,
            electronicInvoicing: this.getForElectronicInvoicing,
            simplifiedElectronicInvoicing: this.getForSimplifiedElectronicInvoicing,
            esterometro: this.getForEsterometro,
            skip: skip,
            count: count
        };

        const types = await this.invoicesService.GetElectronicInvoiceTypes(request);
        return types.map(this.createModel, this);
    }
    
    public async getById(currentModel: IElectronicInvoiceTypesDataSourceModel, ids: string[]): Promise<IElectronicInvoiceTypesDataSourceModel[]> {
        const types = await this.invoicesService.GetElectronicInvoiceTypesByIds(ids);
        return types.map(this.createModel, this);
    }

    private createModel(type: IElectronicInvoiceType): IElectronicInvoiceTypesDataSourceModel {
        return {
            id: type.Code,
            title: String.format(TextResources.Invoices.ElectronicInvoiceTypeLabel, type.Code, type.Description),
            isGroup: false,
            isLeaf: true,
            model: type
        };
    }
}