import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDataSourceIcon } from "../../../../DataSources/IDataSource";
import {
    INavigationMenuComponentAlert,
    INavigationMenuComponentBadge,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTaskForAllocations } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class TasksUtils {
    public static getAlertsForTask(
        t: ITaskForTaskBoard | ITodoListTaskForAllocations
    ): INavigationMenuComponentAlert[] {
        const alerts: INavigationMenuComponentAlert[] = [];

        if (!t.IsReady)
            alerts.push({
                icon: {
                    icon: "fa fa-exclamation-circle",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskIsNotReadyAlert,
            });
        if (!t.HasWarehouse)
            alerts.push({
                icon: {
                    icon: "fa fa-shopping-cart",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsWarehouseArticles,
            });
        if (!t.HasPurchases)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsPurchases,
            });
        if (t.ReportingType == 1)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskIsReporting,
            });
        if (!t.HasResources)
            alerts.push({
                icon: {
                    icon: "fa fa-users",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsResources,
            });
        if (t.HoursToBeBilled > 0)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskHasToBeBilledHours,
            });
        if (t.HasNotApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskHasNotApprovedBudgetRequests,
            });
        if (t.HasApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskHasApprovedBudgetRequests,
            });
        if (!t.HasEstimatedWork)
            alerts.push({
                icon: {
                    icon: "fa fa-terminal",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.TaskHasNotEstimatedWork,
            });

        if (t.WorkflowMustBePlanned)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBePlanned,
            });

        if (t.ActivitiesMustBeReviewed)
            alerts.push({
                icon: {
                    icon: "fa fa-bookmark",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasActivitiesToBeReviewed,
            });

        return alerts;
    }

    public static getTaskStatusIcon(status: number): IDataSourceIcon {
        switch (status) {
            case -1:
                return {
                    icon: "fa fa-inbox",
                    background: "#c6c6c6",
                    foreground: "black",
                };
            case 0:
                return {
                    icon: "fa fa-tachometer",
                    background: "#428bca",
                    foreground: "white",
                };
            case 1:
                return {
                    icon: "fa fa-bolt",
                    background: "#ecbc29",
                    foreground: "black",
                };
            case 2:
                return {
                    icon: "fa fa-flag-checkered",
                    background: "#45b6af",
                    foreground: "white",
                };
            case 3:
                return {
                    icon: "fa fa-check",
                    background: "#45b6af",
                    foreground: "white",
                };
            case 4:
                return {
                    icon: "fa fa-clock-o",
                    background: "#89c4f4",
                    foreground: "black",
                };
            case 5:
                return {
                    icon: "fa fa-trash-o",
                    background: "#f3565d",
                    foreground: "white",
                };
            default:
                return null;
        }
    }

    public static getTaskStatusName(status: number) {
        switch (status) {
            case -1:
                return ProlifeSdk.TextResources.Todolist.Backlog;
            case 0:
                return ProlifeSdk.TextResources.Todolist.ToDo;
            case 1:
                return ProlifeSdk.TextResources.Todolist.InProgress;
            case 2:
                return ProlifeSdk.TextResources.Todolist.Completed;
            case 3:
                return ProlifeSdk.TextResources.Todolist.VerifiedSingular;
            case 4:
                return ProlifeSdk.TextResources.Todolist.SuspendedSingular;
            case 5:
                return ProlifeSdk.TextResources.Todolist.DeletedSingular;
            default:
                return null;
        }
    }

    public static getAllAlerts() {
        const alerts: INavigationMenuComponentAlert[] = [];

        alerts.push({
            icon: {
                icon: "fa fa-exclamation-circle",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskIsNotReadyAlert,
        });

        alerts.push({
            icon: {
                icon: "fa fa-shopping-cart",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsWarehouseArticles,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsPurchases,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskIsReporting,
        });

        alerts.push({
            icon: {
                icon: "fa fa-users",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskNeedsResources,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskHasToBeBilledHours,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskHasNotApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskHasApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "fa fa-terminal",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.TaskHasNotEstimatedWork,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBePlanned,
        });

        alerts.push({
            icon: {
                icon: "fa fa-bookmark",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasActivitiesToBeReviewed,
        });

        return alerts;
    }

    public static getAllBadges() {
        const badges: INavigationMenuComponentBadge[] = [];

        badges.push({
            text: "0",
            cssClass: "badge badge-danger",
            title: TextResources.Todolist.TaskBlogEventsBadgeLegendRedTitle,
        });

        badges.push({
            text: "2",
            cssClass: "badge badge-success",
            title: TextResources.Todolist.TaskBlogEventsBadgeLegendGreenTitle,
        });

        return badges;
    }
}
