import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ExercisesEditingViewModel } from "./ui/ExercisesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IExercises, IExercise, IExercisesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IExercises";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class ExercisesSettingsManager implements IExercises {
    private ajaxService : IAjaxService;
    private exercises : IExercise[] = [];
    private observers : IExercisesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer : IExercisesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IExercisesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IExercise[]> {
        var def = new Deferred<IExercise[]>();

        this.ajaxService.Get("Invoices-api", "Exercise?$orderby=Anno desc, Periodo desc", {})
            .then((exercises: IExercise[]) => {
                this.exercises = exercises;
                def.resolve(exercises);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.Exercises;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.Excercises;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "exercises",
            templateUrl: "invoices/templates/settings",
            viewModel: new ExercisesEditingViewModel(this.serviceLocator, this)
        };
    }

    getExercises() : IExercise[] {
        return this.exercises;
    }

    createOrUpdate(exercise : IExercise) : Promise<IExercise> {
        var def = new Deferred<IExercise>();

        if(exercise.Id > 0) {
            this.ajaxService.Put("Invoices-api", "Exercise", { methodData: exercise})
                .then((exercise: IExercise) => {
                    this.onExerciseChanged(exercise);
                    def.resolve(exercise);
                })
                .catch(() => def.reject());
        } else {
            this.ajaxService.Post("Invoices-api", "Exercise", { methodData: exercise})
            .then((exercise: IExercise) => {
                this.onExerciseAdded(exercise);
                def.resolve(exercise);
            })
            .catch(() => def.reject());
        }
        
        return def.promise();
    }

    remove(exerciseId : number) : Promise<void> {
        var def = new Deferred<void>();

        this.ajaxService.Delete("Invoices-api", "Exercise/" + exerciseId, {})
            .then(() => {
                this.onExerciseDeleted(exerciseId);
                def.resolve();
            })
            .catch(() => def.reject());

        return def.promise();
    }

    private onExerciseChanged(exercise : IExercise) {
        this.load();
        this.observers.forEach((obs) => obs.onExerciseChanged(exercise));
    }

    private onExerciseAdded(exercise : IExercise) {
        this.load();
        this.observers.forEach((obs) => obs.onExerciseAdded(exercise));
    }

    private onExerciseDeleted(exerciseId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onExerciseDeleted(exerciseId));
    }
}