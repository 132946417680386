import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

const styleSheet = jss.createStyleSheet({
    fileUploadButton: {
        position: 'relative',
        overflow: 'hidden',

        "& input[type='file']": {
            position: 'absolute',
            opacity: 0,
            inset: 0
        }
    }
}).attach();
const { classes } = styleSheet;

type FileUploadButtonProps = {
    className?: string;
    icon?: string;
    onChange: ((files: File[]) => void);
    multiple?: boolean;
    style?: CSSProperties;
    accept?: string;
}

export function FileUploadButton(props: FileUploadButtonProps) {
    const C = require("./FileUploadButton")._FileUploadButton as typeof _FileUploadButton;
    return <C {...props} />;
}

export class _FileUploadButton {
    static defaultProps: Partial<FileUploadButtonProps> = {
        className: "btn btn-icon-only btn-primary",
        multiple: false,
        accept: "*.*"
    }

    constructor(private props : FileUploadButtonProps) {
        
    }
    
    render() {
        const _fub = this;
        const fieldName = "file_" + new Date().valueOf();
        const classNames = ComponentUtils.classNames(this.props.className, classes.fileUploadButton);
        return  ComponentUtils.bindTo(
                <label style={this.props.style} htmlFor={fieldName} className={classNames}>
                    <input name={fieldName} type="file" multiple={this.props.multiple} data-bind={{ file: _fub.props.onChange }} accept={this.props.accept} />
                    <i data-bind={{ css: _fub.props.icon }}></i>
                </label>
        , this, "_fub");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(FileUploadButton);
}