import * as ko from "knockout";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IJobOrderRolesPrices } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";

export class JobOrderRolesPrices {

	public Salary : ko.Observable<number> = ko.observable(0);
    public enabled : ko.Observable<boolean> = ko.observable(false);

	constructor(private serviceLocator : IServiceLocator, private costo : IJobOrderRolesPrices, enabled : boolean){
		this.load(costo);
        this.enabled(enabled);
	}

	load(costo : IJobOrderRolesPrices) : void{
		this.Salary(costo.Salary);
	}

	getData() : IJobOrderRolesPrices{
		var costo : IJobOrderRolesPrices = <IJobOrderRolesPrices> $.extend({}, this.costo);

		costo.Salary = this.Salary();
		return costo;
	}
}