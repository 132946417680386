import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 18.30
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BanksEditingViewModel } from "./BanksEditingViewModel";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { NationsProvider } from "../../../../ProlifeSdk/prolifesdk/providers/NationsProvider";
import { ProvincesProvider } from "../../../../ProlifeSdk/prolifesdk/providers/ProvincesProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { INationsProvider } from "../../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { IProvincesProvider } from "../../../../ProlifeSdk/interfaces/settings/IProvincesSettingsManager";
import { ICompanySettingsManager } from "../../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { ICompany } from "../../../../ProlifeSdk/interfaces/settings/ICompany";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

interface ITaxSystem {
    Id: string;
    Label: string;
}

export class CompanySettingsEditingViewModel {
    public ragSociale : ko.Observable<string> = ko.observable();
    public piva : ko.Observable<string> = ko.observable();
    public cf : ko.Observable<string> = ko.observable();
    public capSociale : ko.Observable<number> = ko.observable();
    public regImprese : ko.Observable<string> = ko.observable();
    public REA : ko.Observable<string> = ko.observable();
    public indirizzo : ko.Observable<string> = ko.observable();
    public cap : ko.Observable<string> = ko.observable();
    public citta : ko.Observable<string> = ko.observable();
    public comune : ko.Observable<string> = ko.observable();
    public provincia : ko.Observable<string> = ko.observable();
    public stato : ko.Observable<string> = ko.observable();
    public telefono : ko.Observable<string> = ko.observable();
    public fax : ko.Observable<string> = ko.observable();
    public mail : ko.Observable<string> = ko.observable();
    public website : ko.Observable<string> = ko.observable();
    public ritenutaAcconto : ko.Observable<string> = ko.observable();
    public notaRitenutaAcconto : ko.Observable<string> = ko.observable();
    public CUCCode : ko.Observable<string> = ko.observable();

    public EORICode: ko.Observable<string> = ko.observable();
    public TaxSystem: ko.Observable<string> = ko.observable();
    public WindUp: ko.Observable<boolean> = ko.observable(); // stato liquidazione
    public SingleMember: ko.Observable<boolean> = ko.observable();
    public StampDutyThreshold: ko.Observable<number> = ko.observable();

    public NationsProvider: INationsProvider;
    public ProvincesProvider: IProvincesProvider;

    private logoToShow : ko.Observable<any> = ko.observable(null);
    private logoToSend : ko.Observable<any> = ko.observable(null);

    public banks : BanksEditingViewModel = new BanksEditingViewModel();
    public TaxSystems: ko.ObservableArray<ITaxSystem> = ko.observableArray([]);

    public isChanged : ko.Computed<boolean>;
    //private originalValue : ICompany;
    private originalValue: ko.Observable<any> = ko.observable();

    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    private validation : IValidator<CompanySettingsEditingViewModel>;

    constructor(private serviceLocator : IServiceLocator, private companySettingsManager : ICompanySettingsManager)
    {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.NationsProvider = new NationsProvider();
        this.ProvincesProvider = new ProvincesProvider();

        this.NationsProvider.SelectedNationCode.subscribe((code: string) => {
            if (this.ProvincesProvider.SelectedNationCode() == 'IT' || code == 'IT')
                this.ProvincesProvider.SelectedProvinceCode(null);

            this.ProvincesProvider.SelectedNationCode(code);
        });

        this.populateTaxSystemsList();
        this.reload();

        this.isChanged = ko.computed(() => {
            var isChanged = false;
            isChanged = isChanged || (this.ragSociale() != this.originalValue().RagioneSociale);
            isChanged = isChanged || (this.piva() != this.originalValue().PIVA);
            isChanged = isChanged || (this.cf() != this.originalValue().CF);
            isChanged = isChanged || (this.capSociale() != this.originalValue().CapSociale);
            isChanged = isChanged || (this.regImprese() != this.originalValue().RegImprese);
            isChanged = isChanged || (this.REA() != this.originalValue().REA);
            isChanged = isChanged || (this.indirizzo() != this.originalValue().Indirizzo);
            isChanged = isChanged || (this.cap() != this.originalValue().CAP);
            isChanged = isChanged || (this.citta() != this.originalValue().Citta);
            isChanged = isChanged || (this.comune() != this.originalValue().Comune);
            isChanged = isChanged || (this.ProvincesProvider.SelectedProvinceCode() != this.originalValue().Provincia);
            isChanged = isChanged || (this.NationsProvider.SelectedNationCode() != this.originalValue().Stato);
            isChanged = isChanged || (this.telefono() != this.originalValue().Telefono);
            isChanged = isChanged || (this.fax() != this.originalValue().Fax);
            isChanged = isChanged || (this.mail() != this.originalValue().Mail);
            isChanged = isChanged || (this.website() != this.originalValue().Website);
            isChanged = isChanged || (this.logoToSend() != null);
            isChanged = isChanged || (this.ritenutaAcconto() != this.originalValue().RitenutaAcconto);
            isChanged = isChanged || (this.notaRitenutaAcconto() != this.originalValue().NotaRitenutaAcconto);
            isChanged = isChanged || (this.EORICode() != this.originalValue().EORICode);
            isChanged = isChanged || (this.TaxSystem() != this.originalValue().TaxSystem);
            isChanged = isChanged || (this.WindUp() != this.originalValue().WindUp);
            isChanged = isChanged || (this.SingleMember() != this.originalValue().SingleMember);
            isChanged = isChanged || (this.StampDutyThreshold() != this.originalValue().StampDutyThreshold);
            isChanged = isChanged || this.banks.isChanged();
            isChanged = isChanged || (this.CUCCode() != this.originalValue().CUCCode);
            return isChanged;
        });

        this.validation = this.validationService.createValidator<CompanySettingsEditingViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(c => c.piva(), TextResources.Settings.InsertVATCode)
            .mustBePiva(c => c.piva());
    }

    public LoadLogo(file : File)
    {
        if(file.type.indexOf("image") == -1)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Settings.UnsupportedFormat);
            return;
        }

        if(file.size > 500000)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Settings.LogoTooBig);
            return;
        }

        var reader : FileReader = new FileReader();
        reader.onload = (event) => {
            var result = (<any>event.target).result;
            this.logoToShow(result);
            this.logoToSend(file);
        };

        reader.readAsDataURL(file);
    }

    public OnInvalidDropLogo()
    {
        alert("no files");
    }

    public update()
    {
        let validationResult = this.validation.validate(this);
        for(let bank of this.banks.banks())
            validationResult.push(...bank.validate());
        
        if(validationResult.length > 0)
        {
            this.infoToastService.Error(validationResult.map(r => r.message).join("<br/>"));
            return;
        }

        var company : ICompany = <ICompany> $.extend({}, this.companySettingsManager.get());

        company.RagioneSociale = this.ragSociale();
        company.PIVA = this.piva();
        company.CF = this.cf();
        company.CapSociale = this.capSociale();
        company.RegImprese = this.regImprese();
        company.REA = this.REA();
        company.Indirizzo = this.indirizzo();
        company.CAP = this.cap();
        company.Citta = this.citta();
        company.Comune = this.comune();
        company.Provincia = this.ProvincesProvider.SelectedProvinceCode();
        company.Stato = this.NationsProvider.SelectedNationCode();
        company.Telefono = this.telefono();
        company.Fax = this.fax();
        company.Mail = this.mail();
        company.Website = this.website();
        company.RitenutaAcconto = this.ritenutaAcconto();
        company.NotaRitenutaAcconto = this.notaRitenutaAcconto();
        company.EORICode = this.EORICode();
        company.TaxSystem = this.TaxSystem();
        company.SingleMember = this.SingleMember();
        company.WindUp = this.WindUp();
        company.StampDutyThreshold = this.StampDutyThreshold();
        company.CUCCode = this.CUCCode();

        this.banks.update(company.Banks);

        this.companySettingsManager.update(company)
            .then(() => {
                this.companySettingsManager.updateLogo(this.logoToSend())
                    .then(this.companyUpdated.bind(this))
            });
    }

    private companyUpdated()
    {
        this.companySettingsManager.load();
        this.reload();
        this.infoToastService.Success(ProlifeSdk.TextResources.Settings.CompanyInformationsUpdated);
    }

    public reload()
    {
        var company = this.companySettingsManager.get();
        this.originalValue(company);

        this.ragSociale(company.RagioneSociale);
        this.piva(company.PIVA);
        this.cf(company.CF);
        this.capSociale(company.CapSociale);
        this.regImprese(company.RegImprese);
        this.REA(company.REA);
        this.indirizzo(company.Indirizzo);
        this.cap(company.CAP);
        this.citta(company.Citta);
        this.comune(company.Comune);
        this.provincia(company.Provincia);
        this.stato(company.Stato);
        this.telefono(company.Telefono);
        this.fax(company.Fax);
        this.mail(company.Mail);
        this.website(company.Website);
        this.ritenutaAcconto(!company.RitenutaAcconto ? undefined : company.RitenutaAcconto);
        this.notaRitenutaAcconto(company.NotaRitenutaAcconto);
        this.EORICode(company.EORICode);
        this.TaxSystem(company.TaxSystem);
        this.SingleMember(company.SingleMember);
        this.WindUp(company.WindUp);
        this.StampDutyThreshold(company.StampDutyThreshold);
        this.CUCCode(company.CUCCode);
        this.NationsProvider.SelectedNationCode(company.Stato);
        this.ProvincesProvider.SelectedProvinceCode(company.Provincia);

        if(!this.logoToShow())
            this.logoToShow("data:image/jpg;base64," + company.Logo);

        this.banks.reload(company.Banks);
    }

    private populateTaxSystemsList(): void {
        var taxSystems: ITaxSystem[] = [];

        taxSystems.push({ Id: ProlifeSdk.RF01_Ordinary, Label: ProlifeSdk.RF01_Ordinary + ' - ' + ProlifeSdk.TextResources.Settings.RF01_Ordinary });
        taxSystems.push({ Id: ProlifeSdk.RF02_MinimumTaxPayer, Label: ProlifeSdk.RF02_MinimumTaxPayer + ' - ' + ProlifeSdk.TextResources.Settings.RF02_MinimumTaxPayer });
        taxSystems.push({ Id: ProlifeSdk.RF04_FarmingFishingAndRelatedActivities, Label: ProlifeSdk.RF04_FarmingFishingAndRelatedActivities + ' - ' + ProlifeSdk.TextResources.Settings.RF04_FarmingFishingAndRelatedActivities });
        taxSystems.push({ Id: ProlifeSdk.RF05_SaltAndTobacco, Label: ProlifeSdk.RF05_SaltAndTobacco + ' - ' + ProlifeSdk.TextResources.Settings.RF05_SaltAndTobacco });
        taxSystems.push({ Id: ProlifeSdk.RF06_MatchstickTrade, Label: ProlifeSdk.RF06_MatchstickTrade + ' - ' + ProlifeSdk.TextResources.Settings.RF06_MatchstickTrade });
        taxSystems.push({ Id: ProlifeSdk.RF07_PublishingIndustry, Label: ProlifeSdk.RF07_PublishingIndustry + ' - ' + ProlifeSdk.TextResources.Settings.RF07_PublishingIndustry });
        taxSystems.push({ Id: ProlifeSdk.RF08_PublicTelephonyServices, Label: ProlifeSdk.RF08_PublicTelephonyServices + ' - ' + ProlifeSdk.TextResources.Settings.RF08_PublicTelephonyServices });
        taxSystems.push({ Id: ProlifeSdk.RF09_PublicTransportDocumentsReselling, Label: ProlifeSdk.RF09_PublicTransportDocumentsReselling + ' - ' + ProlifeSdk.TextResources.Settings.RF09_PublicTransportDocumentsReselling });
        taxSystems.push({ Id: ProlifeSdk.RF10_EntertainmentGamesAndRelatedActivities, Label: ProlifeSdk.RF10_EntertainmentGamesAndRelatedActivities + ' - ' + ProlifeSdk.TextResources.Settings.RF10_EntertainmentGamesAndRelatedActivities });
        taxSystems.push({ Id: ProlifeSdk.RF11_TravelAgency, Label: ProlifeSdk.RF11_TravelAgency + ' - ' + ProlifeSdk.TextResources.Settings.RF11_TravelAgency });
        taxSystems.push({ Id: ProlifeSdk.RF12_Agritourism, Label: ProlifeSdk.RF12_Agritourism + ' - ' + ProlifeSdk.TextResources.Settings.RF12_Agritourism });
        taxSystems.push({ Id: ProlifeSdk.RF13_DoorToDoorSales, Label: ProlifeSdk.RF13_DoorToDoorSales + ' - ' + ProlifeSdk.TextResources.Settings.RF13_DoorToDoorSales });
        taxSystems.push({ Id: ProlifeSdk.RF14_AntiqueSecondHandGoodsAndWorksOfArtReselling, Label: ProlifeSdk.RF14_AntiqueSecondHandGoodsAndWorksOfArtReselling + ' - ' + ProlifeSdk.TextResources.Settings.RF14_AntiqueSecondHandGoodsAndWorksOfArtReselling });
        taxSystems.push({ Id: ProlifeSdk.RF15_AntiqueSecondHandGoodsAndWorksOfArtAuctioningReselling, Label: ProlifeSdk.RF15_AntiqueSecondHandGoodsAndWorksOfArtAuctioningReselling + ' - ' + ProlifeSdk.TextResources.Settings.RF15_AntiqueSecondHandGoodsAndWorksOfArtAuctioningReselling });
        taxSystems.push({ Id: ProlifeSdk.RF16_CashAccountingPA, Label: ProlifeSdk.RF16_CashAccountingPA + ' - ' + ProlifeSdk.TextResources.Settings.RF16_CashAccountingPA });
        taxSystems.push({ Id: ProlifeSdk.RF17_CashAccounting, Label: ProlifeSdk.RF17_CashAccounting + ' - ' + ProlifeSdk.TextResources.Settings.RF17_CashAccounting });
        taxSystems.push({ Id: ProlifeSdk.RF18_Other, Label: ProlifeSdk.RF18_Other + ' - ' + ProlifeSdk.TextResources.Settings.RF18_Other });
        taxSystems.push({ Id: ProlifeSdk.RF19_FlatRateScheme, Label: ProlifeSdk.RF19_FlatRateScheme + ' - ' + ProlifeSdk.TextResources.Settings.RF19_FlatRateScheme });

        this.TaxSystems(taxSystems);
    }
}