import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTaskChangeHistory } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";

export class TaskStateChangeEvent extends BlogEvent implements IView
{
    private humanResourcesService : IHumanResourcesSettingsManager;
    private todoListService : ITodoListService;

    public OldState : ko.Observable<string> = ko.observable();
    public NewState : ko.Observable<string> = ko.observable();
    public TaskName : ko.Observable<string> = ko.observable();

    public TaskChangeHistory : ko.ObservableArray<ITodoListTaskChangeHistory> = ko.observableArray([]);

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Updatable(false);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.iconClass = "fa-tasks";
        this.eventTypeName = ProlifeSdk.TextResources.Todolist.Task;
        this.templateName = "task-state-change-event";
        this.templateUrl = "todolist/templates/events";
        this.isDeleteLocked(true);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    public GetMailSubject() : string
    {
        return ProlifeSdk.TextResources.Todolist.TaskStateChangeMailSubject;
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {

            switch(item.TagName()) {
                case ProlifeSdk.Tag_TaskStateChange_OldState:
                    this.OldState(item.displayName());
                    break;

                case ProlifeSdk.Tag_TaskStateChange_NewState:
                    this.NewState(item.displayName());
                    break;

                case ProlifeSdk.Tag_TaskStateChange_TaskName:
                    this.TaskName(item.Value());
                    break;
            }
        });
    }

    public LoadDetails()
    {
        super.LoadDetails();

        var tasks = this.Tasks();
        if(tasks.length > 0) {
            this.todoListService.GetTaskChangeHistory(tasks[0])
                .then((hs : ITodoListTaskChangeHistory[]) => {
                    var lastProgress = 0;
                    var lastEstimate = 0;
                    var lastState = -1;

                    var firstChange = true;
                    hs.reverse().forEach(h => {
                        if(firstChange) {
                            lastProgress = h.NewProgressAvg || lastProgress;
                            lastEstimate = h.NewEstimatedWork || lastEstimate;
                            lastState = h.NewTaskBoardStatus || lastState;
                            firstChange = false;
                        } else {
                            if(h.NewEstimatedWork == null) { //Cambio di stima o progress
                                h.NewEstimatedWork = lastEstimate;
                                h.NewProgressAvg = lastProgress;
                                lastState = h.NewTaskBoardStatus;
                            } else { //Cambio di stato
                                h.NewTaskBoardStatus = lastState;
                                lastProgress = h.NewProgressAvg;
                                lastEstimate = h.NewEstimatedWork;
                            }
                        }
                    });
                    hs.reverse();
                    this.TaskChangeHistory(hs);
                });
        }
    }
}