import * as Core from "./Core";
import { IAuthorizationService } from "./interfaces/IAuthorizationService";

export function Right(rightName : string) : PropertyDecorator {
    return function _Right(target: any, propertyName: string) {
        let cachedValue : boolean = undefined;

        Object.defineProperty(
            target,
            propertyName,
            {
                get: () => {
                    if(cachedValue == undefined) {
                        let service = Core.serviceLocator.findService<IAuthorizationService>(nameof<IAuthorizationService>());
                        cachedValue = service.isAuthorized(rightName);
                    }
                    return cachedValue;
                },
                set: (newValue : any) => {
                    cachedValue = newValue;
                }
            }
        )
    }
}