import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/12/2018
 * Time: 11:17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { WizardStep } from "../../../../../ProlifeSdk/prolifesdk/WizardStep";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentForElectronicInvoicingViewModel } from "../../../../interfaces/IDocumentForElectronicInvoicing";

export class ElectronicInvoicingSummaryStep extends WizardStep {
    constructor(public SelectedDocuments: ko.ObservableArray<IDocumentForElectronicInvoicingViewModel>, initializationInfo: IWizardInitializationInfo) {
        super("electronic-invoicing-summary-step", "invoices/templates/wizard/steps", ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardInvoicingSecondStepTitle, initializationInfo);
    }
}