import * as ko from "knockout";

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { UserCharactersEditingViewModel } from "./ui/UserCharactersEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserCharactersSettingsManager, IUserCharacter, IIUserCharactersObserver } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class UserCharactersSettingsManager implements IUserCharactersSettingsManager {
	private ajaxService : IAjaxService;
	public userCharacters : IUserCharacter[] = [];
	private observers : IIUserCharactersObserver[] = [];
    private modulesService : IModulesService;

	constructor(private serviceLocator : IServiceLocator) {
		const settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
		settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Users.ResourcesRoles);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
		this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
	}

	load(): Promise<IUserCharacter[]> {
		return this.ajaxService.Post<IUserCharacter[]>("Users-api/UserCharacters", "DetailedSearch", {})
			.then((userCharacters) => this.userCharacters = userCharacters);
	}

	getName(): string {
		return ProlifeSdk.UserCharactersServiceType;
	}

	getLabel(): string {
		return ProlifeSdk.TextResources.Users.Roles;
	}

	hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) &&
            !this.modulesService.IsModuleEnabled(ProlifeSdk.ResourcesManagerApplicationCode);
	}

	getEditingUI(): IView {
		return {
			templateName: 'UserCharacters',
			templateUrl: 'Users/templates/settings',
			viewModel: new UserCharactersEditingViewModel(this.serviceLocator, this)
		};
	}

    getUserCharactersWithDeleted(resourceType? : number) : IUserCharacter[]
    {
        let characters = this.userCharacters;

        if (resourceType != undefined && resourceType != null)
            characters = this.userCharacters.filter((r : IUserCharacter) => {
                return r.ResourceType == resourceType;
            });

        return characters;
    }

	getUserCharacters(resourceType? : number) : IUserCharacter[]
    {
        const characters = this.getUserCharactersWithDeleted(resourceType);

        return characters.filter((r : IUserCharacter) => {
            return !r.Eliminato;
        });
	}

	getUserCharacterById(IdUserCharacter : number) : IUserCharacter {
		const userCharacter : IUserCharacter[] = this.userCharacters.filter((userCharacter : IUserCharacter) => userCharacter.IdUserCharacter == IdUserCharacter);
		return userCharacter[0];
	}

	getDefault() : IUserCharacter{
		let userCharacter : IUserCharacter[] = this.userCharacters.filter((userCharacter : IUserCharacter) => userCharacter.IsDefault == 1);

        if(userCharacter.length == 0 && this.userCharacters.length > 0)
            userCharacter = this.userCharacters;

		return userCharacter[0];
	}

	createOrUpdate(userCharacter : IUserCharacter) : Promise<IUserCharacter> {
		if(userCharacter.IdUserCharacter > 0) {
			return this.ajaxService.Put<IUserCharacter>("Users", "UserCharacters", { methodData: userCharacter})
				.then(this.onUserCharacterChanged.bind(this));
		}

		return this.ajaxService.Post<IUserCharacter>("Users-api/UserCharacters", "CreateNew", { methodData: userCharacter})
			.then(this.onUserCharacterAdded.bind(this));
	}

	remove(IdUserCharacter : number) : Promise<void> {
		return this.ajaxService.Delete<void>("Users", "UserCharacters/" + IdUserCharacter, {})
			.then(this.onUserCharacterDeleted.bind(this, IdUserCharacter));
	}

	private onUserCharacterChanged(userCharacter : IUserCharacter) {
		const index : number = this.getIndexOfUserCharacter(userCharacter.IdUserCharacter);
		this.userCharacters[index] = userCharacter;

		this.observers.forEach((obs) => obs.onUserCharacterChanged(userCharacter));

		return userCharacter;
	}

	private onUserCharacterAdded(userCharacter : IUserCharacter) {
		this.userCharacters.push(userCharacter);

		this.observers.forEach((obs) => obs.onUserCharacterAdded(userCharacter));

		return userCharacter;
	}

	private onUserCharacterDeleted(IdUserCharacter : number) {
		const index : number = this.getIndexOfUserCharacter(IdUserCharacter);
		this.userCharacters.splice(index, 1);

		this.observers.forEach((obs) => obs.onUserCharacterDeleted(IdUserCharacter));

		return IdUserCharacter;
	}

	private getIndexOfUserCharacter(IdUserCharacter : number) : number {
		const item = this.userCharacters.filter((userCharacter : IUserCharacter) => userCharacter.IdUserCharacter == IdUserCharacter)[0];
		return this.userCharacters.indexOf(item);
	}

}