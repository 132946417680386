import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowCategoryControlsEntityProvider } from "../../entity-providers/WorkflowCategoryControlsEntityProvider";
import { ResourcesAndGroupsManager } from "../tasks-list/ResourcesAndGroupsManager";
import { IActivityProgressOption } from "../tasks-list/TodoListActivity";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DetectChanges } from "../../../../Core/ChangeDetection";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ITodoListService, ReportingType } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IDefaultRole, IDefaultWorkTimeCategory, ITodoListTemplateBase } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import "./WorkflowSnapshotsEditor";
import { DefaultRole, DefaultWorkTimeCategory } from "../utils/WorkedHoursDefaultsEditor";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";

export abstract class WorkflowEditDialogBase implements IDetectChanges
{
    @LazyImport(nameof<ITodoListService>())
    protected todoListService : ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    protected dialogService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService: IInfoToastService;
    @LazyImport(nameof<IEntityProviderService>())
    protected entityProviderService : IEntityProviderService;

    @LazyImportSettingManager(ProlifeSdk.WorkflowCategoriesType)
    protected workflowCategories : IWorkflowCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    protected rolesSettings : IUserCharactersSettingsManager;
    @LazyImport(nameof<IWorkedHoursService>())
    protected workedHoursService : IWorkedHoursService;

    @DetectChanges
    public Title : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public Description : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public InQuality : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public Color : ko.Observable<string> = ko.observable();
    @DetectChanges
    public ResponsibleId : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public Category : ko.Observable<string> = ko.observable("");

    public Resources : ResourcesAndGroupsManager;

    @DetectChanges
    public ReportingType : ko.Observable<number> = ko.observable(0);
    public ReportingTypes : ReportingType[] = [];

    public Roles : ko.ObservableArray<IUserCharacter> = ko.observableArray([]);
    @DetectChanges
    public DefaultRoleId : ko.Observable<number> = ko.observable();

    @DetectChanges
    public ActivitiesProgressAmountMode: ko.Observable<number> = ko.observable(undefined);
    @DetectChanges
    public Multiplier: ko.Observable<number> = ko.observable(1);
    @DetectChanges
    public MultiplierUoM: ko.Observable<string> = ko.observable();

    public EnableActivitiesProgressModeSetting: ko.Observable<boolean> = ko.observable(false);
    public ActivitiesProgressAmountModeOptions: ko.ObservableArray<IActivityProgressOption> = ko.observableArray([]);

    @DetectChanges
    public ShowAlertOnUnestimatedTasks: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public WorkflowMustBePlanned: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public ActivitiesMustBeReviewed: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public WorkflowMustBeRelatedToCustomerOrders: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public OverrideCategoryShowAlertOnUnestimatedTasksSetting: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public OverrideCategoryWorkflowMustBePlannedSetting: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public OverrideCategoryActivitiesMustBeReviewedSetting: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public OverrideCategoryHideFromSuggestionsSetting: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public HideFromSuggestions : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public WorkedHoursDefaultPlace: ko.Observable<number> = ko.observable();
    @DetectChanges
    public WorkedHoursDefaultTravelDistance: ko.Observable<number> = ko.observable();
    @DetectChanges
    public DDC : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public WorkedHoursDefaultRoles: ko.ObservableArray<DefaultRole> = ko.observableArray([]);
    @DetectChanges
    public WorkedHoursDefaultWorkTimeCategories: ko.ObservableArray<DefaultWorkTimeCategory> = ko.observableArray([]);
    @DetectChanges
    public WorkedHoursDefaultsStrictMode : ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public HideAdministrativeDataOnWorkedHours : ko.Observable<boolean> = ko.observable(false);

    public IsNew: ko.Observable<boolean> = ko.observable(true);
    public isChanged: ko.Observable<number> = ko.observable(0);

    public ReportingTypeName : ko.Computed<string>;
    public DisableHideFromSuggestion: ko.Computed<boolean>;
    public WorkedHoursDefaultPlaceLabel: ko.Computed<string>;

    public resourcesSearchService : IControlsEntityProvider;
    public CategoriesProvider : WorkflowCategoryControlsEntityProvider;

    protected loading = false;
    private data: ITodoListTemplateBase;

    constructor() {
        this.resourcesSearchService = this.entityProviderService.getEntityProvider(ProlifeSdk.HumanResources).getControlsProvider();
        (this.resourcesSearchService as any).GetMaterialResources = false;
        this.CategoriesProvider = new WorkflowCategoryControlsEntityProvider();
        this.Resources = new ResourcesAndGroupsManager();

        this.ReportingTypes = this.todoListService.GetReportingTypes();

        this.ActivitiesProgressAmountModeOptions.push({ label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressWorkedHoursMode, value: 0 });
        this.ActivitiesProgressAmountModeOptions.push({ label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressAmountMode, value: 1 });

        this.Roles(this.rolesSettings.getUserCharacters());

        this.Category.subscribe((id: string) => {
            if (this.loading)
                return;
                
            if (!id)
                return;

            const n = parseInt(id);
            if (isNaN(n))
                return;

            if (!n)
                return;

            this.workflowCategories.getCategory(n).then((category) => {
                if (!this.OverrideCategoryShowAlertOnUnestimatedTasksSetting())
                    this.ShowAlertOnUnestimatedTasks(category.ShowAlertOnUnestimatedTasks);

                if (!this.OverrideCategoryWorkflowMustBePlannedSetting())
                    this.WorkflowMustBePlanned(category.WorkflowMustBePlanned);

                if (!this.OverrideCategoryActivitiesMustBeReviewedSetting())
                    this.ActivitiesMustBeReviewed(category.ActivitiesMustBeReviewed);

                if (!this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting())
                    this.WorkflowMustBeRelatedToCustomerOrders(category.WorkflowMustBeRelatedToCustomerOrders);

                    
                this.HideFromSuggestions(category.HideFromSuggestions);
            });
        });

        this.ReportingTypeName = ko.computed(() => {
            return this.ReportingTypes[this.ReportingType() || 0].Description;
        });

        this.WorkedHoursDefaultPlaceLabel = ko.computed(() => {
            const placeId = this.WorkedHoursDefaultPlace();
            const place = this.workedHoursService.getWorkPlaces().firstOrDefault(p => p.Id === placeId);
            return place?.Description;
        });

        this.DisableHideFromSuggestion = ko.computed(() => {
            return !this.OverrideCategoryHideFromSuggestionsSetting();
        });

        this.EnableActivitiesProgressModeSetting.subscribe((value: boolean) => {
            if (!value)
                this.ActivitiesProgressAmountMode(undefined);

            if (value && (this.ActivitiesProgressAmountMode() == null || this.ActivitiesProgressAmountMode() == undefined))
                this.ActivitiesProgressAmountMode(0)
        });
    }

    public SwitchActivitiesProgressSettingMode() {
        this.EnableActivitiesProgressModeSetting(!this.EnableActivitiesProgressModeSetting());
    }

    public SetActivitiesProgressAmountMode(value: number) {
        this.ActivitiesProgressAmountMode(value);
    }

    public HasChanges(): boolean {
        return this.isChanged() > 0 
            || this.Resources.IsChanged();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispose(): void {}

    action(): void
    {
        //implementare nelle derivate
    }

    public LoadFromModel(data : ITodoListTemplateBase)
    {
        this.loading = true;

        const defaultRole = this.rolesSettings.getDefault();
        const roleId = data.DefaultRoleId <= 0 ? defaultRole.IdUserCharacter : data.DefaultRoleId;

        this.data = data;

        this.Title(data.Title);
        this.Description(data.Description);
        this.InQuality(data.InQuality);
        this.Color(data.Color);
        this.ResponsibleId(data.Responsible ? data.Responsible.toString() : "");
        this.Category(data.Category ? data.Category.toString() : "");
        this.ReportingType(data.ReportingType ? data.ReportingType : 0);
        this.HideFromSuggestions(data.HideFromSuggestions);
        this.Resources.LoadResources(data.Resources);
        this.ActivitiesProgressAmountMode(data.ActivitiesProgressAmountMode == null ? undefined : data.ActivitiesProgressAmountMode);
        this.EnableActivitiesProgressModeSetting(data.ActivitiesProgressAmountMode != null && data.ActivitiesProgressAmountMode != undefined);
        this.Multiplier(data.Multiplier);
        this.MultiplierUoM(data.MultiplierUoM);
        this.ShowAlertOnUnestimatedTasks(data.ShowAlertOnUnestimatedTasks);
        this.WorkflowMustBePlanned(data.WorkflowMustBePlanned);
        this.ActivitiesMustBeReviewed(data.ActivitiesMustBeReviewed);
        this.WorkflowMustBeRelatedToCustomerOrders(data.WorkflowMustBeRelatedToCustomerOrders);
        this.DefaultRoleId(roleId);

        this.WorkedHoursDefaultPlace(data.DefaultWorkPlace);
        this.WorkedHoursDefaultTravelDistance(data.DefaultTravelDistance);
        this.DDC(data.DefaultCallRight);
        this.WorkedHoursDefaultsStrictMode(data.WorkedHoursDefaultsStrictMode);
        this.HideAdministrativeDataOnWorkedHours(data.HideAdministrativeFieldsOnWorkedHours);
        this.WorkedHoursDefaultRoles(this.createDefaultRoles(data.WorkedHoursDefaultRoles));
        this.WorkedHoursDefaultWorkTimeCategories(this.createDefaultWorkTimeCategories(data.WorkedHoursDefaultWorkTimeCategories));

        setTimeout(() => { // HACK per far funzionare correttamente quie cavolo di onOffSwitch, (ma non stanno funzionando comunque queste m***e)
            this.OverrideCategoryShowAlertOnUnestimatedTasksSetting(data.OverrideCategoryShowAlertOnUnestimatedTasksSetting ?? false);
            this.OverrideCategoryWorkflowMustBePlannedSetting(data.OverrideCategoryWorkflowMustBePlannedSetting ?? false);
            this.OverrideCategoryActivitiesMustBeReviewedSetting(data.OverrideCategoryActivitiesMustBeReviewedSetting ?? false);
            this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting(data.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting ?? false);
            this.OverrideCategoryHideFromSuggestionsSetting(data.OverrideCategoryHideFromSuggestionsSetting ?? false);

            this.loading = false;

            this.isChanged(0);
        }, 200);
    }

    protected createDefaultRoles(roles: IDefaultRole[]): DefaultRole[] {
        return roles?.map(r => ({ RoleId: ko.observable(r.RoleId), Order: ko.observable(r.Order) })) ?? [];
    }
    
    protected createDefaultWorkTimeCategories(workTimeCategories: IDefaultWorkTimeCategory[]): DefaultWorkTimeCategory[] {
        return workTimeCategories?.map(r => ({ WorkTimeCategoryId: ko.observable(r.WorkTimeCategoryId), Order: ko.observable(r.Order) })) ?? [];
    }

    public GetData(): ITodoListTemplateBase {
        const data = this.data || {
            Title : "",
            Description : "",
            InQuality: null,
            Color : "",
            Responsible: null,
            Category: null,
            ReportingType: 0,
            Resources : [],
            DefaultRoleId: -1,
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            OverrideCategoryShowAlertOnUnestimatedTasksSetting: false,
            OverrideCategoryWorkflowMustBePlannedSetting: false,
            OverrideCategoryActivitiesMustBeReviewedSetting: false,
            OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting: false,
            HideFromSuggestions: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
            DefaultWorkPlace: null,
            DefaultTravelDistance: null,
            DefaultCallRight: null,
            WorkedHoursDefaultRoles: [],
            WorkedHoursDefaultWorkTimeCategories: [],
        };

        const responsibleId : number = parseInt(this.ResponsibleId());
        const categoryId : number = parseInt(this.Category());

        data.Title = this.Title();
        data.Description = this.Description();
        data.InQuality = this.InQuality();
        data.Color = this.Color();
        data.Responsible = isNaN(responsibleId) ? null : responsibleId;
        data.Category = categoryId;
        data.ReportingType = this.ReportingType();
        data.Resources = this.Resources.GetResources();
        data.DefaultRoleId = this.DefaultRoleId();
        data.ActivitiesProgressAmountMode = this.ActivitiesProgressAmountMode();
        data.Multiplier = this.Multiplier();
        data.MultiplierUoM = this.MultiplierUoM();
        data.ShowAlertOnUnestimatedTasks = this.ShowAlertOnUnestimatedTasks();
        data.WorkflowMustBePlanned = this.WorkflowMustBePlanned();
        data.ActivitiesMustBeReviewed = this.ActivitiesMustBeReviewed();
        data.WorkflowMustBeRelatedToCustomerOrders = this.WorkflowMustBeRelatedToCustomerOrders();
        data.OverrideCategoryActivitiesMustBeReviewedSetting = this.OverrideCategoryActivitiesMustBeReviewedSetting();
        data.OverrideCategoryShowAlertOnUnestimatedTasksSetting = this.OverrideCategoryShowAlertOnUnestimatedTasksSetting();
        data.OverrideCategoryWorkflowMustBePlannedSetting = this.OverrideCategoryWorkflowMustBePlannedSetting();
        data.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting = this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting();
        data.HideFromSuggestions = this.HideFromSuggestions();
        data.WorkedHoursDefaultsStrictMode = this.WorkedHoursDefaultsStrictMode();
        data.HideAdministrativeFieldsOnWorkedHours = this.HideAdministrativeDataOnWorkedHours();
        data.DefaultWorkPlace = this.WorkedHoursDefaultPlace();
        data.DefaultTravelDistance = this.WorkedHoursDefaultTravelDistance();
        data.DefaultCallRight = this.DDC();

        return data;
    }
}