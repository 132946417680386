import * as ko from "knockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IInvoicesService } from "../../interfaces/invoice/IInvoicesService";
import { IDocumentsProvider } from "../../interfaces/invoice/IDocumentsProvider";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IProtocolDefaultValuesProvider, IProtocolSetting, IProtocolDefaultValuesSettingsUi } from "../../interfaces/invoice/IProtocolsSettingsManager";
import { AbortableDeferred } from "../../../Invoices/interfaces/IAbortableDeferred";

export interface IProtocolDefaultSettings {
    Provider: IProtocolDefaultValuesProvider;
    Settings: IProtocolSetting[];    
}

export interface IProtocolDocumentType { 
    DocumentType: string;
    NewLabel: string;
}

export abstract class DocumentsProvider implements IDocumentsProvider
{
    CanEdit: boolean = false;
    CanCreateNew: ko.Observable<boolean> = ko.observable(true);
    CanView: ko.Observable<boolean> = ko.observable(true);
    ProviderId: string;
    Name : ko.Observable<string> = ko.observable("");
    ReadOnly : ko.Observable<boolean> = ko.observable(true);
    CanCopy : ko.Observable<boolean> = ko.observable(false);
    Id: number;
    
    HasDefaultValuesSettingsUi : boolean = false;
    DefaultValuesProviders : IProtocolDefaultValuesProvider[] = [];
    CanBeAssociatedToJobOrder : boolean = true;

    HasVatRegister: boolean = true;
    VatRegisterLabel: ko.Observable<string> = ko.observable("");

    public DefaultSearchProvider : string = "";
    public DocumentEntityCode : string; //Stringa identificativa del tipo di documento (entity type)
    
    @LazyImport(nameof<IInvoicesService>())
    protected invoicesService : IInvoicesService;

    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;

    constructor(entityType : string)
    {
        this.DocumentEntityCode = entityType;
    }

    public onSearchDocumentsFailed()
    {
    }

    RegisterDefaultValuesProvider(p : IProtocolDefaultValuesProvider)
    {
        this.DefaultValuesProviders.push(p);
        this.DefaultValuesProviders.sort((a : IProtocolDefaultValuesProvider, b : IProtocolDefaultValuesProvider) => {
            return b.Priority - a.Priority;
        });
    }
    
    GetDefaultValuesSettingsUi() : IProtocolDefaultValuesSettingsUi
    {
        //Implementare nelle derivate
        return null;
    }

    GetDocumentGroupLabel(): string {
        //Implementare nelle derivate
        return undefined;
    }

    GetProviderPositionForMenu(): number {
        //Implementare nelle derivate
        return undefined;
    }

    abstract GetDocumentTypes() : IProtocolDocumentType[];
}
