import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/09/2017
 * Time: 11:31
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CartForList } from "./CartForList";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICartContentDetails } from "../../../ProlifeSdk/interfaces/allocations/ICart";

export class CartElement {
    public Id: number;
    public IsTask: ko.Observable<boolean> = ko.observable();
    public Percentage: ko.Observable<number> = ko.observable();
    public WorkflowTitle: ko.Observable<string> = ko.observable();
    public JobOrderTitle : ko.Observable<string> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();

    public EditingMode: ko.Computed<boolean>;
    public ElementJobOrder: ko.Computed<string>;
    public ElementWorkflow: ko.Computed<string>;

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;

    constructor(public element: ICartContentDetails, private cart: CartForList) {
        this.Id = element.ElementId;
        this.Percentage(100);
        this.IsTask(element.IsTask);
        this.WorkflowTitle(element.WorkflowTitle);
        this.JobOrderTitle(element.JobOrderTitle);
        this.Order(element.Order);

        this.EditingMode = ko.computed(() => {
            return this.cart.EnableContentEditing();
        });

        this.ElementJobOrder = ko.computed(() => {
            return ProlifeSdk.TextResources.JobOrder.Order + ": " + this.JobOrderTitle();
        });

        this.ElementWorkflow = ko.computed(() => {
            return this.WorkflowTitle() ? ProlifeSdk.TextResources.Todolist.Workflow + ": " + this.WorkflowTitle() : "";
        });
    }

    public DeleteFromCart(): void {
        this.cartsService.deleteElementFromCart(this.cart.Id, this.Id, this.IsTask())
            .then(() => {
                this.cart.OnElementDeleted(this);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.DeleteCartContentError);
            });
    }
}