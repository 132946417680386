import * as ko from "knockout";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";

export class EventsGroup
{
    numberDay : number;
    day : ko.Observable<Date> = ko.observable();
    events : ko.ObservableArray<BlogEvent> = ko.observableArray([]);

    constructor(day : Date, events : BlogEvent[])
    {
        this.day(day);
        this.events(events);
        this.numberDay = day.getFullYear() * 10000 + day.getMonth() * 100 + day.getDate();
    }

    public addIfMatch(event : any) : boolean
    {
        if(this.day().getDate() != event.ReferenceDate().getDate() ||
            this.day().getMonth() != event.ReferenceDate().getMonth() ||
            this.day().getFullYear() != event.ReferenceDate().getFullYear())
            return false;

        var events = this.events();
        events.push(event);
        events.sort((a : any, b : any) => {
            return b.ReferenceDate() - a.ReferenceDate();
        });
        this.events(events);
        return true;
    }
}
