import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/02/2018
 * Time: 15:21
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IEventCanceledMotivationEditor, ICanceledMotivationViewModel } from "../../../../interfaces/IEventCanceledMotivationsSettingsManager";
import { IEventCanceledMotivationsSettingsManager } from "../../../../../ProlifeSdk/interfaces/agenda/IEventCanceledMotivationsSettingsManager";
import { IEventCanceledMotivation } from "../../../../../ProlifeSdk/interfaces/agenda/IEventsService";

export class EventCanceledMotivationsEditingViewModel implements IEventCanceledMotivationEditor {
    public Title: string = ProlifeSdk.TextResources.Agenda.EventCanceledMotivationsEditorTitle;

    public Motivations: ko.ObservableArray<ICanceledMotivationViewModel> = ko.observableArray([]);

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: IEventCanceledMotivationsSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.LoadMotivations();
    }

    public CreateNewMotivation(): void {
        this.settingsManager.addOrUpdateMotivation(this.CreateMotivation())
            .then((motivation: IEventCanceledMotivation) => {
                this.Motivations.push(this.CreateMotivationViewModel(motivation));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.CreateMotivationError);
            });
    }

    public UpdateMotivation(motivation: ICanceledMotivationViewModel): void {
        this.settingsManager.addOrUpdateMotivation(motivation.GetData())
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.UpdateMotivationError);
            });
    }

    public RemoveMotivation(motivation: ICanceledMotivationViewModel): void {
        this.settingsManager.removeMotivation(motivation.Id())
            .then((motivations: IEventCanceledMotivation[]) => {
                this.Motivations(motivations.map((m: IEventCanceledMotivation) => { return this.CreateMotivationViewModel(m); }));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RemoveMotivationError);
            });
    }

    public OnMotivationMoved(beforeId: number, movedId: number, afterId: number): void {
        var moved = this.Motivations().filter((s) => { return s.Id() == movedId; });
        var before = this.Motivations().filter((s) => { return s.Id() == beforeId; });
        var after  = this.Motivations().filter((s) => { return s.Id() == afterId; });

        if(before.length == 0 && after.length == 0)
            return;

        var offset : number = before.length == 0 || before[0].GetData().Order > moved[0].GetData().Order ? 0 : 1;

        var newPosition = before.length > 0 ? before[0].GetData().Order + offset : 1;
        this.settingsManager.moveMotivation(movedId, newPosition)
            .then(() => {
                this.LoadMotivations();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MoveMotivationError);
            });
    }

    private LoadMotivations(): void {
        this.Motivations(this.settingsManager.getMotivations().map((m: IEventCanceledMotivation) => { return this.CreateMotivationViewModel(m); }));
    }

    private CreateMotivation(): IEventCanceledMotivation {
        return {
            Id: -1,
            Label: ProlifeSdk.TextResources.Agenda.MotivationDefaultLabel,
            Order: -1,
            ForWaitingList: true,
            ForAborting: true
        };
    }

    private CreateMotivationViewModel(motivation: IEventCanceledMotivation): ICanceledMotivationViewModel {
        return new CanceledMotivationViewModel(this.serviceLocator, motivation, this);
    }
}

class CanceledMotivationViewModel implements ICanceledMotivationViewModel {
    public Id: ko.Observable<number> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public Order: ko.Observable<number> = ko.observable();
    public ForWaitingList: ko.Observable<boolean> = ko.observable();
    public ForAborting: ko.Observable<boolean> = ko.observable();

    private lockUpdates: boolean = false;

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private motivation: IEventCanceledMotivation, private editor: IEventCanceledMotivationEditor) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Id(this.motivation.Id);
        this.Label(this.motivation.Label);
        this.Order(this.motivation.Order);
        this.ForWaitingList(this.motivation.ForWaitingList);
        this.ForAborting(this.motivation.ForAborting);

        this.Label.subscribe(this.OnDataChanged.bind(this));
        this.ForWaitingList.subscribe(this.OnForWaitingListChanged.bind(this));
        this.ForAborting.subscribe(this.OnForAbortingChanged.bind(this));
    }

    public Delete(): void {
        this.editor.RemoveMotivation(this);
    }

    public GetData(): IEventCanceledMotivation {
        return {
            Id: this.Id(),
            Label: this.Label(),
            Order: this.Order(),
            ForWaitingList: this.ForWaitingList(),
            ForAborting: this.ForAborting()
        };
    }

    private OnForWaitingListChanged(value: boolean) {
        if (this.lockUpdates)
            return;

        this.lockUpdates = true;

        if (!value && !this.ForAborting()) {
            this.ForWaitingList(!value);
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventCanceledMotivationTypeRequired);
            this.lockUpdates = false;
            return;
        }

        this.lockUpdates = false;

        this.OnDataChanged();
    }

    private OnForAbortingChanged(value: boolean) {
        if (this.lockUpdates)
            return;

        this.lockUpdates = true;

        if (!value && !this.ForWaitingList()) {
            this.ForAborting(!value);
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventCanceledMotivationTypeRequired);
            this.lockUpdates = false;
            return;
        }

        this.lockUpdates = false;

        this.OnDataChanged();
    }

    private OnDataChanged(): void {
        if (this.lockUpdates)
            return;

        this.editor.UpdateMotivation(this);
    }
}