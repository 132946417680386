import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SpeedFilter } from "./SpeedFilter";

export class AdvancedSearchFilter extends SpeedFilter
{
    templateName : string = "events-filter";
    templateUrl : string = "blog/templates/job-order-notes/filters";

    public inizio: ko.Observable<Date> = ko.observable();
    public fine: ko.Observable<Date> = ko.observable();
    public stato : ko.Observable<string> = ko.observable();

    public statusNdValue : string = ProlifeSdk.Tag_State_Undefined;
    public statusToDoValue : string = ProlifeSdk.Tag_State_ToDo;
    public statusInProgressValue : string = ProlifeSdk.Tag_State_InProgress;
    public statusDoneValue : string = ProlifeSdk.Tag_State_Done;

    public filterActive : ko.Observable<boolean> = ko.observable();

    constructor()
    {
        super();
        this.initialize();
    }

    private initialize() : void
    {
        this.filterActive(false);
        this.inizio(<Date>null);
        this.fine(<Date>null);
        this.stato("-1");
    }

    clear() : void
    {
        this.initialize();
    }

    applyFilter(): void
    {
        this.filterActive(!!this.inizio() || !!this.fine() || this.stato() != "-1");
    }
}
