import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { IContactUoForList } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class OrganizationalUnitDisplayName {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any) : void
    {
        OrganizationalUnitDisplayName.refreshContent(element, valueAccessor, allBindingsAccessor);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any): void
    {
        OrganizationalUnitDisplayName.refreshContent(element, valueAccessor, allBindingsAccessor);
    }

    private static refreshContent(element : any, valueAccessor: () => any, allBindingsAccessor: () => any)
    {
        var options = valueAccessor();
        var customerService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var uoId = ko.utils.unwrapObservable(options.id);
        var defaultText = options.defaultText || "";

        if (uoId == "" || uoId == undefined || uoId == null || uoId == undefined || uoId <= 0)
        {
            $(element).text(defaultText);
            return;
        }

        customerService.GetContactUoForList(<number>uoId).then(
            (uo : IContactUoForList) => {
                var risultato : string = defaultText;
                if (uo != null)
                    risultato = uo.Description;

                $(element).text(risultato);
            },
            () => {
                $(element).text(defaultText);
            }
        );
    }
}

ko.bindingHandlers["UoDisplayName"] = new OrganizationalUnitDisplayName();
ko.virtualElements.allowedBindings["UoDisplayName"] = true;
