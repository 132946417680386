import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { ReferencesDetailsBalloon } from "./ReferencesDetailsBalloon";
import { IDialog } from "../../../Core/interfaces/IDialogsService";

export class DocumentReferencesDialog implements IDialog
{
    public templateName: string = "document-references-dialog";
    public templateUrl: string = "prolifesdk/templates/documents/referencemap";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.RowContentAnalisys;
    modal: { close: (result?: any) => void; };

    constructor(private referencesInfo : ReferencesDetailsBalloon)
    {

    }

    close(): void
    {
        this.modal.close();
    }

    action(): void {}
}
