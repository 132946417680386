import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Param, ComponentParam, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import jss from "jss";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { ManufacturersDataSource } from "../../../../DataSources/ManufacturersDataSource";
import { IAliasesService, IManufacturerAlias, IManufacturerAliasEx } from "../../../AliasesService";
import {
    ManufacturerAliasesDataSource,
    IManufacturerAliasesDataSourceModel,
} from "../../../../DataSources/ManufacturerAliasesDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";

export interface IManufacturerAliasesEditor {
    HasChanges: ko.Computed<boolean>;
}

export interface IManufacturerAliasesEditorParams {
    CustomerId: Param<number>;
    Editable: Param<boolean>;
    InjectTo: Param<IManufacturerAliasesEditor>;
}

@DetectClassChanges
export class ManufacturerAlias implements IDataSourceListener, IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable(0);
    dispose(): void {}

    Id: ko.Observable<number> = ko.observable();
    @DetectChanges
    ManufacturerId: ko.Observable<number> = ko.observable();
    CustomerId: ko.Observable<number> = ko.observable();
    @DetectChanges
    NewManufacturerPrefix: ko.Observable<string> = ko.observable();

    isNew: ko.Computed<boolean>;

    ManufacturersDataSource: ManufacturersDataSource = new ManufacturersDataSource();

    @LazyImport(nameof<IAliasesService>())
    private aliasesService: IAliasesService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(
        private editor: ManufacturerAliasesEditor,
        private alias: IManufacturerAliasEx | null,
        customerId?: number | null
    ) {
        this.load(alias, customerId);

        this.isNew = ko.pureComputed(() => !this.Id() || this.Id() < 0);
    }

    private load(alias: IManufacturerAlias, customerId?: number | null) {
        if (alias) {
            this.Id(alias.Id);
            this.ManufacturerId(alias.ManufacturerId);
            this.CustomerId(customerId || alias.CustomerId);
            this.NewManufacturerPrefix(alias.NewManufacturerPrefix);
        } else {
            this.Id(undefined);
            this.ManufacturerId(undefined);
            this.CustomerId(customerId);
            this.NewManufacturerPrefix(undefined);
        }

        this.isChanged(0);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    public async save(): Promise<void> {
        try {
            let wasNew = this.isNew();

            let newAlias = await this.aliasesService.InsertOrUpdateManufacturerAlias([this.getData()]);
            if (newAlias && newAlias.length > 0) this.load(newAlias[0]);

            this.infoToastService.Success(TextResources.Warehouse.ArticleAliasSaveSuccess);
            this.editor.onAliasSaved(wasNew);
        } catch {
            this.infoToastService.Error(ProlifeSdk.TextResources.Warehouse.ErrorWhileSavingArticleAlias);
        }
    }

    /* public confirmChanges(): void {
        let isNew = this.isNew();
        let alias = this.getData();
        this.editor.confirmAliasChanges(alias, isNew);
    }

    public markAliasAsDeleted(): void {
        let alias = this.getData();
        this.editor.markAliasAsDeleted(alias);
    } */

    public async cancel(): Promise<void> {
        if (this.isChanged() > 0) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return;
        }

        this.load(this.alias, this.CustomerId());
    }

    public async remove(): Promise<void> {
        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ArticleAliasDeleteConfirm,
                TextResources.Warehouse.Cancel,
                TextResources.Warehouse.Delete
            ))
        )
            return;

        try {
            await this.aliasesService.RemoveManufacturerAlias(this.Id());
            this.infoToastService.Success(TextResources.Warehouse.ArticleAliasDeleteSuccess);

            this.editor.onAliasDeleted();
        } catch {
            this.infoToastService.Error(TextResources.Warehouse.ArticleAliasDeleteError);
        }
    }

    private getData(): IManufacturerAliasEx {
        let data = Object.assign({}, this.alias) as IManufacturerAliasEx;

        (data.Id = this.Id()),
            (data.ManufacturerId = this.ManufacturerId()),
            (data.CustomerId = this.CustomerId()),
            (data.NewManufacturerPrefix = this.NewManufacturerPrefix());

        return data;
    }
}

export class ManufacturerAliasesEditor implements IManufacturerAliasesEditor, IDataSourceListener {
    CustomerId: ComponentParam<number>;
    Editable: ComponentParam<boolean>;

    SelectedAlias: ko.Observable<ManufacturerAlias> = ko.observable();
    CodeSearch: ko.Observable<string> = ko.observable();
    Expanded: ko.Observable<boolean> = ko.observable();

    CodeSearchInput: ko.Observable<string> = ko.observable();

    ManufacturerAliasesDataSource: ManufacturerAliasesDataSource = new ManufacturerAliasesDataSource();

    HasChanges: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private newOrModifiedAliases: IManufacturerAlias[];
    private deletedAliases: IManufacturerAlias[];

    constructor(params: IManufacturerAliasesEditorParams) {
        this.CustomerId = ComponentUtils.parseParameter(params.CustomerId, -1);
        this.Editable = ComponentUtils.parseParameter(params.Editable, false);

        let customerIdInterceptor = ko.computed(() => {
            this.ManufacturerAliasesDataSource.setCustomerId(this.CustomerId());
        });

        this.newAlias();

        this.HasChanges = ko.computed(() => {
            return this.SelectedAlias() && this.SelectedAlias().isChanged() > 0;
        });

        let injectTo = ComponentUtils.parseParameter(params.InjectTo, undefined);
        if (injectTo) injectTo(this);
    }

    async createNewAlias(): Promise<boolean> {
        if (this.HasChanges()) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return false;
        }

        this.newAlias();
        return true;
    }

    /* public confirmAliasChanges(alias: IManufacturerAliasEx, isNew: boolean) {
        this.newOrModifiedAliases.push(alias);
        if (isNew)
            this.newAlias();
    }

    public markAliasAsDeleted(alias: IManufacturerAliasEx) {
        this.deletedAliases.push(alias);
        this.newAlias();
    } */

    private newAlias() {
        this.SelectedAlias(new ManufacturerAlias(this, null, this.CustomerId()));
        this.ManufacturerAliasesDataSource.select();
    }

    Expand() {
        this.Expanded(!this.Expanded());
    }

    onItemSelected(sender: IDataSource, model: IManufacturerAliasesDataSourceModel): void {
        if (sender == this.ManufacturerAliasesDataSource && model) {
            this.SelectedAlias(this.createViewModelFor(model));
        }
    }

    onItemDeselected(sender: IDataSource, model: IManufacturerAliasesDataSourceModel): void {}

    async canSelectItem?(sender: IDataSource, model: IManufacturerAliasesDataSourceModel): Promise<boolean> {
        if (this.SelectedAlias() && this.SelectedAlias().isChanged() > 0) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return false;
        }

        return true;
    }

    createViewModelFor(model: IManufacturerAliasesDataSourceModel): ManufacturerAlias {
        return new ManufacturerAlias(this, model.model, this.CustomerId());
    }

    onAliasSaved(wasNew) {
        this.ManufacturerAliasesDataSource.refresh();
        if (wasNew) this.newAlias();
    }

    onAliasDeleted() {
        this.ManufacturerAliasesDataSource.refresh();
        this.newAlias();
    }

    search() {
        this.CodeSearch(this.CodeSearchInput());
    }
}

const { classes } = jss
    .createStyleSheet({
        manufacturerAliasListItem: {
            "&.list-notification-item-details": {
                paddingLeft: "10px",
                paddingRight: "10px",
            },
        },
        portletCollapsed: {
            "&.portlet.light.bordered": {
                paddingBottom: "0px",

                "& > .portlet-title": {
                    borderBottom: "none",
                    marginBottom: "0px",
                },
            },
        },
    })
    .attach();

ko.components.register("manufacturer-aliases-editor", {
    viewModel: {
        createViewModel: (params: IManufacturerAliasesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            let vm = new ManufacturerAliasesEditor(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <div class="row" style="height: 100%">
                        <div class="col-md-6" style="height: 100%">
                            <div class="flex-container flex-vertical flex-full-height">
                                <div
                                    class="portlet light bordered"
                                    data-bind={`css: { '${classes.portletCollapsed}': !Expanded() }`}>
                                    <div class="portlet-title">
                                        <div class="caption">
                                            <i class="icon-list"></i>
                                            <span class="caption-subject bold uppercase">
                                                {TextResources.Warehouse.ManufacturerAliasesSearch}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <button
                                                class="btn btn-circle btn-default btn-icon-only"
                                                data-bind="click: Expand">
                                                <i class="fa fa-chevron-up" data-bind="visible: Expanded"></i>
                                                <i class="fa fa-chevron-down" data-bind="hidden: Expanded"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="portlet-body form" data-bind="visible: Expanded">
                                        <form data-bind="submit: search.bind($data)">
                                            <text-input
                                                value={() => "CodeSearchInput"}
                                                label={TextResources.Warehouse.SearchByCode}
                                                maxLength={50}
                                                upperCase={true}>
                                                <span class="input-group-btn">
                                                    <button class="btn btn-success" type="submit">
                                                        <i class="icon-magnifier"></i>
                                                    </button>
                                                </span>
                                            </text-input>
                                        </form>
                                    </div>
                                </div>
                                <list
                                    dataSource={() => "ManufacturerAliasesDataSource"}
                                    listener={() => "$data"}
                                    textFilter={() => "CodeSearch"}
                                    containerHeight={"flex"}
                                    class="flex-container flex-fill">
                                    <div
                                        class={"list-notification-item-details " + classes.manufacturerAliasListItem}
                                        data-bind="click: Select, with: Model">
                                        <div class="list-notification-item-title flex-container">
                                            <span data-bind="text: OriginalManufacturerCode"></span>
                                            <span
                                                class="flex-1 text-right"
                                                data-bind="text: NewManufacturerPrefix"></span>
                                        </div>
                                    </div>
                                </list>
                            </div>
                        </div>
                        <div class="col-md-6" style="height: 100%">
                            {/* <ko-if data-bind="Editable"> */}
                            <div class={"portlet light bordered " + classes.portletCollapsed}>
                                <div class="portlet-title">
                                    <div class="caption">
                                        <i class="icon-list"></i>
                                        <span class="caption-subject bold uppercase">
                                            {TextResources.Warehouse.ManufacturerAliasesManagement}
                                        </span>
                                    </div>
                                    <div class="actions">
                                        <button
                                            class="btn btn-circle btn-primary"
                                            data-bind="asyncClick: createNewAlias">
                                            <i class="fa fa-plus"></i>&nbsp;{TextResources.Warehouse.New}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* </ko-if> */}
                            <div class="portlet light bordered" data-bind="with: SelectedAlias">
                                <div class="portlet-title">
                                    <div class="caption">
                                        <i class="icon-list"></i>
                                        <span class="caption-subject bold uppercase" data-bind="hidden: isNew">
                                            {TextResources.Warehouse.EditManufacturerAlias}
                                        </span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: isNew">
                                            {TextResources.Warehouse.NewManufacturerAlias}
                                        </span>
                                        {/* <span class="caption-subject bold uppercase" data-bind="hidden: isNew() || !$parent.Editable()">{TextResources.Warehouse.EditManufacturerAlias}</span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: isNew() && $parent.Editable()">{TextResources.Warehouse.NewManufacturerAlias}</span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: !$parent.Editable()">{TextResources.Warehouse.ManufacturerAliasDetails}</span> */}
                                    </div>
                                    <div class="actions" /* data-bind="if: $parent.Editable" */>
                                        <button class="btn btn-circle btn-primary" data-bind="asyncClick: save">
                                            <i class="fa fa-floppy-o"></i>&nbsp;{TextResources.Warehouse.Save}
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-circle btn-warning"
                                            data-bind="asyncClick: cancel, enable: isChanged() > 0">
                                            <i class="fa fa-times-circle-o"></i>&nbsp;{TextResources.Warehouse.Cancel}
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-circle btn-danger"
                                            data-bind="hidden: isNew, asyncClick: remove">
                                            <i class="fa fa-trash-o"></i>&nbsp;{TextResources.Warehouse.Delete}
                                        </button>
                                    </div>
                                </div>
                                <div class="portlet-body form">
                                    <select2
                                        value={() => "ManufacturerId"}
                                        dataSource={() => "ManufacturersDataSource"}
                                        listener={() => "$data"}
                                        label={TextResources.Warehouse.Manufacturer}
                                        allowClear={true}
                                        placeholder={TextResources.Warehouse.SelectAManufacturer}
                                        /* readOnly={() => "!$parent.Editable()" }*/
                                    ></select2>

                                    <text-input
                                        value={() => "NewManufacturerPrefix"}
                                        label={TextResources.Warehouse.ManufacturerCodeAlias}
                                        placeholder={TextResources.Warehouse.ManufacturerCodeAliasPlaceholder}
                                        helpText={TextResources.Warehouse.ManufacturerCodeAliasHelpText}
                                        maxLength={4}
                                        upperCase={true}
                                        /* readOnly={() => "!$parent.Editable()"} */
                                    ></text-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
