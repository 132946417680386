import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 12:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { EventsUIProvider } from "./Agenda/ui/EventsUIProvider";
import { CategoriesSettingsManager } from "./Agenda/ui/settings/CategoriesSettingsManager";
import { AgendasEventsCustomersEntityProvider } from "./Agenda/ui/entityProviders/AgendasEventsCustomersEntityProvider";
import { AgendaEventCategoryEntityProvider } from "./Agenda/ui/entityProviders/AgendaEventCategoryEntityProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IEventsService, IEventsUIProvider, IAgendaEvent, IAgendaEventForEditingEx, ITimeslotForEvent, ICustomerEnvelope, IAgendaEventDescriptor, IEventOnDatesIntervalDetails, IAgendaEventResource } from "../ProlifeSdk/interfaces/agenda/IEventsService";
import { IEventAgendaAndStartEndInfo } from "../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ILogFilter } from "../ProlifeSdk/interfaces/ILogFilter";

class EventsService extends ProLifeService  implements IEventsService {
    public ui: IEventsUIProvider;

    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.EventsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IEventsService>(), this);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        this.ui = new EventsUIProvider(this.serviceLocator);
    }

    InitializeService() {
        super.InitializeService();
        
        new CategoriesSettingsManager(this.serviceLocator);
        new AgendasEventsCustomersEntityProvider(this.serviceLocator);
        new AgendaEventCategoryEntityProvider(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.EventsServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetEvents(start: Date, end: Date, agendaId: number): Promise<IAgendaEvent[]> {
        return this.ajaxService.Post<IAgendaEvent[]>("Agenda-api", "Events/GetEvents", {
            methodData: {
                StartDate: start,
                EndDate: end,
                AgendaId: agendaId
            }
        });
    }

    GetEvent(eventId: number): Promise<IAgendaEvent> {
        return this.ajaxService.Post<IAgendaEvent>("Agenda-api", "Events/GetEvent", {
            methodData: {
                EventId: eventId
            }
        });
    }

    GetEventForEditing(eventId: number, async: boolean = true): Promise<IAgendaEventForEditingEx> {
        return this.ajaxService.Post<IAgendaEventForEditingEx>("Agenda-api", "Events/GetEventForEditing", {
            methodData: {
                EventId: eventId
            },
            async: async
        });
    }

    CreateOrUpdate(event: IAgendaEventForEditingEx): Promise<number> {
        return this.ajaxService.Post<number>("Agenda-api", "Events", {
            methodData: event
        });
    }

    Delete(eventId: number): Promise<void> {
        return this.ajaxService.Delete<void>("Agenda-api", "Events?id=" + eventId, {});
    }

    UpdateEventAfterMove(id: number, start: Date, end: Date, allDay: boolean, categoryId: number): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/UpdateEventAfterMove", {
            methodData: {
                Id: id,
                StartDate: start,
                EndDate: end,
                AllDay: allDay,
                CategoryId: categoryId
            }
        });
    }

    GetAvailableTimeslotsForDate(date: Date, agendaId: number): Promise<ITimeslotForEvent[]> {
        return this.ajaxService.Post<ITimeslotForEvent[]>("Agenda-api", "Events/GetAvailableTimeslotsForDate", {
            methodData: {
                Date: date,
                AgendaId: agendaId
            }
        });
    }

    GetCustomersForEvent(textFilter: string): Promise<ICustomerEnvelope[]> {
        return this.ajaxService.Post<ICustomerEnvelope[]>("Agenda-api", "Events/GetCustomersForEvent", {
            methodData: {
                Filter: textFilter
            }
        });
    }

    GetCustomersForEventLimitedList(textFilter: string): Promise<ICustomerEnvelope[]> {
        return this.ajaxService.Post<ICustomerEnvelope[]>("Agenda-api", "Events/GetCustomersForEventLimitedList", {
            methodData: {
                Filter: textFilter
            }
        });
    }

    GetCustomerForEvent(customerId: number): Promise<ICustomerEnvelope> {
        return this.ajaxService.Post<ICustomerEnvelope>("Agenda-api", "Events/GetCustomerForEvent", {
            methodData: {
                CustomerId: customerId
            }
        });
    }

    HintSearch(filters: ILogFilter[], skip: number, count: number): Promise<IAgendaEventDescriptor[]> {
        return this.ajaxService.Post<IAgendaEventDescriptor[]>("Agenda-api", "Events/HintSearch", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count
            }
        });
    }

    Recovery(eventId: number): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/Recovery", {
            methodData: {
                EventId: eventId
            }
        });
    }

    OnEventDraggedFromWaitingList(eventId: number, targetAgendaId: number): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/OnEventDraggedFromWaitingList", {
            methodData: {
                EventId: eventId,
                TargetAgendaId: targetAgendaId
            }
        });
    }

    SetEventCategory(eventId: number, categoryId: number) : Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/SetEventCategory", {
            methodData: {
                EventId: eventId,
                CategoryId: categoryId
            }
        });
    }

    RemoveFromWaitingList(eventId: number, newCategory: number) : Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/RemoveFromWaitingList", {
            methodData: {
                EventId: eventId,
                CategoryId: newCategory
            }
        });
    }

    GetEventsOnDatesInterval(agendaId: number, startDate: Date, endDate: Date): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Events/GetEventsOnDatesInterval", {
            methodData: {
                AgendaId: agendaId,
                StartDate: startDate,
                EndDate: endDate
            }
        });
    }

    GetEventsOnDatesAndTimesIntervals(agendaId: number, startDate: Date, endDate: Date, startTime: Date, endTime: Date): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post<IEventOnDatesIntervalDetails[]>("Agenda-api", "Events/GetEventsOnDatesAndTimesIntervals", {
            methodData: {
                AgendaId: agendaId,
                StartDate: startDate,
                EndDate: endDate,
                StartTime: startTime,
                EndTime: endTime
            }
        });
    }

    GetResourcesLabels(eventId: number): Promise<string[]> {
        return this.ajaxService.Post<string[]>("Agenda-api", "Events/GetResourcesLabels", {
            methodData: {
                EventId: eventId
            }
        });
    }

    GetCustomersLabels(eventId: number): Promise<string[]> {
        return this.ajaxService.Post<string[]>("Agenda-api", "Events/GetCustomersLabels", {
            methodData: {
                EventId: eventId
            }
        });
    }

    GetCustomersEnvelopes(customersIds: number[]): Promise<ICustomerEnvelope[]> {
        return this.ajaxService.Post<ICustomerEnvelope[]>("Agenda-api", "Events/GetCustomersEnvelopes", {
            methodData: {
                CustomersIds: customersIds
            }
        });
    }

    ChangeEventAgenda(eventId: number, newAgendaId: number) : Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Events/ChangeEventAgenda", {
            methodData: {
                EventId: eventId,
                AgendaId: newAgendaId
            }
        });
    }

    SetEventsState(eventsIds: number[], stateId: number, canceledMotivationId: number = null): Promise<IEventAgendaAndStartEndInfo[]> {
        return this.ajaxService.Post<IEventAgendaAndStartEndInfo[]>("Agenda-api", "Events/SetEventsState", {
            methodData: {
                EventsIds: eventsIds,
                StateId: stateId,
                CanceledMotivationId: canceledMotivationId
            }
        });
    }

    ChangeEventsAgenda(eventsIds: number[], newAgendaId: number): Promise<IEventAgendaAndStartEndInfo[]> {
        return this.ajaxService.Post<IEventAgendaAndStartEndInfo[]>("Agenda-api", "Events/ChangeEventsAgenda", {
            methodData: {
                EventsIds: eventsIds,
                NewAgendaId: newAgendaId
            }
        });
    }

    VerifyTimeslotAvailability(eventId: number, agendaId: number, eventStartDateAndTime: Date, eventEndDateAndTime: Date): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Events/VerifyTimeslotAvailability", {
            methodData: {
                EventId: eventId,
                AgendaId: agendaId,
                EventStartDateAndTime: eventStartDateAndTime,
                EventEndDateAndTime: eventEndDateAndTime
            }
        });
    }

    VerifyEventsOverlap(eventId: number, agendaId: number, eventStartDateAndTime: Date, eventEndDateAndTime: Date): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Events/VerifyEventsOverlap", {
            methodData: {
                EventId: eventId,
                AgendaId: agendaId,
                EventStartDateAndTime: eventStartDateAndTime,
                EventEndDateAndTime: eventEndDateAndTime
            }
        });
    }

    GetEventCustomersIds(eventId: number): Promise<number[]> {
        return this.ajaxService.Post<number[]>("Agenda-api", "Events/GetEventCustomersIds", {
            methodData: {
                EventId: eventId
            }
        });
    }

    GetEventResources(eventId: number): Promise<IAgendaEventResource[]> {
        return this.ajaxService.Post<IAgendaEventResource[]>("Agenda-api", "Events/GetEventResources", {
            methodData: {
                EventId: eventId
            }
        });
    }

    IsEventRelatedToLoggedUser(eventId: number): Promise<boolean> {
        return this.ajaxService.Post<boolean>("Agenda-api", "Events/IsEventRelatedToLoggedUser", {
            methodData: {
                EventId: eventId
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator): IService {
    return new EventsService(serviceLocator);
}