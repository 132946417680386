import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import "./ui/WorkflowStatesSettingsViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWorkflowStatesSettingsManager, IWorkflowState, IWorkflowStatesObserver } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class WorkflowStatesSettingsManager implements IWorkflowStatesSettingsManager
{
    private ajaxService : IAjaxService;
    private modulesService : IModulesService;

    private states : IWorkflowState[] = [];
    private observers : IWorkflowStatesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.TasksSettingsGroup);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<IWorkflowState[]>
    {
        return this.ajaxService.Get<IWorkflowState[]>("Todolist-api", "WorkflowStates", {})
            .then((states) => this.states = states);
    }

    getName(): string {
        return ProlifeSdk.WorkflowStates;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Todolist.WorkflowStates;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) ||
            this.modulesService.IsModuleEnabled(ProlifeSdk.PersonalTodolistApplicationCode);
    }

    getEditingUI(): IView {
        return { //Non usiamo più il viewmodel, adesso usiamo il componente
            component: 'workflow-states-editor',
            templateName: "",
            templateUrl: '',
            viewModel: null//new WorkflowStatesSettingsViewModel(this.serviceLocator, this)
        };
    }

    getStates(getDeleted : boolean = false, skip: number = 0, count : number = 100000) : IWorkflowState[] {
        let states =  getDeleted ? this.states : this.states.filter((s) => { return !s.Deleted; });
        return states.slice(skip, skip + count);
    }

    getStateById(id : number) : IWorkflowState
    {
        var states = this.states.filter((s : IWorkflowState) => s.Id == id);
        if(states.length == 0) return null;
        return states[0];
    }

    getStateByIds(ids : number[]) : IWorkflowState[]
    {
        return this.states.filter((s : IWorkflowState) => ids.indexOf(s.Id) != -1);
    }

    moveState(stateId : number, newPosition : number) : Promise<IWorkflowState>
    {
        var d = new Deferred<IWorkflowState>();
        this.ajaxService.Post("Todolist-api", "WorkflowStates/UpdatePosition", { methodData: {
            StateId : stateId,
            NewPosition : newPosition
        }})
        .then((state : IWorkflowState) => {
            this.onStateChanged(state)
                .then(() => { d.resolve(state); })
                .catch(() => { d.reject([])});
        })
        .catch(() => { d.reject([])});
        return d.promise();
    }

    createOrUpdate(state : IWorkflowState) : Promise<IWorkflowState> {
        return this.ajaxService.Post<IWorkflowState>("Todolist-api", "WorkflowStates", { methodData: state})
            .then(this.onStateAdded.bind(this));
    }

    remove(id : number) : Promise<void>
    {
        if(!id) {
            return Promise.reject();
        }

        return this.ajaxService.Delete<void>("Todolist-api", "WorkflowStates/" + id, {})
            .then(this.onStateDeleted.bind(this, id));
    }

    addObserver(observer : IWorkflowStatesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IWorkflowStatesObserver) : void {
        let index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onStateChanged(state : IWorkflowState) : Promise<IWorkflowState[]> {
        return this.load().then((states) => {
            this.observers.forEach((obs : IWorkflowStatesObserver) => obs.onStateChanged(state));
            return states;
        });
    }

    private onStateAdded(state : IWorkflowState) : Promise<IWorkflowState[]> {
        return this.load().then((states) => {
            this.observers.forEach((obs : IWorkflowStatesObserver) => obs.onStateAdded(state));
            return states;
        });
    }

    private onStateDeleted(id : number) : Promise<IWorkflowState[]> {
        return this.load().then((states) => {
            this.observers.forEach((obs : IWorkflowStatesObserver) => obs.onStateDeleted(id));
            return states;
        });
    }
}