import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 23/11/2017
 * Time: 17:45
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { TeamsFirstRenderer } from "./TeamsFirstRenderer";
import { ResourcesFirstRenderer } from "./ResourcesFirstRenderer";

import * as moment from "moment";
import { ResourceViewModel, ITeamsManagerProvider, ITeamViewModel } from "./TeamsManager";
import { CartsAllocationsRenderer } from "./CartsAllocationsRenderer";
import { PlannerGanttItemInterval } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { IAllocationsService } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IGanttWrapper } from "../../interfaces/IGanttWrapper";
import { IAllocationSetting } from "../../interfaces/IAllocationSetting";

export class ResourceAllocationsGantt implements IGanttWrapper {
    public Gantt: PlannerGantt;
    TeamsFirstRenderer : TeamsFirstRenderer;
    ResourcesFirstRenderer : ResourcesFirstRenderer;

    public ResourcesDailyHoursLimit: ko.Observable<number> = ko.observable(8);

    public TeamFirstView: ko.Observable<boolean> = ko.observable(false);
    public ResourceFirstView: ko.Observable<boolean> = ko.observable(true);
    public CartsAllocationsView: ko.Observable<boolean> = ko.observable(false);

    private currentRenderer : any;

    private allocationsService: IAllocationsService;
    private refreshFunctions = [];

    constructor(private serviceLocator : IServiceLocator, private teamsManagerProvider: ITeamsManagerProvider, private resourceId: number, private opUnitId: number, private resourceName: string) {
        this.allocationsService = <IAllocationsService> this.serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);
        this.allocationsService.getSetting("ResourcesDailyHoursLimit")
            .then((setting: IAllocationSetting) => {
                var limit: number = !setting ? 8 : parseFloat(setting.Value);
                this.ResourcesDailyHoursLimit(limit);
            });

        this.refreshFunctions[ProlifeSdk.TeamFirstRenderer] = this.OnTeamsLoaded.bind(this);
        this.refreshFunctions[ProlifeSdk.ResourceFirstRenderer] = this.OnResourcesLoaded.bind(this);
        this.refreshFunctions[ProlifeSdk.CartsAllocationsRenderer] = this.OnTeamsLoaded.bind(this);

        this.Gantt = new PlannerGantt();
        this.Gantt.SearchInChildren(true);
        this.Gantt.AllowDragAndDrop(false);

        this.currentRenderer = new ResourcesFirstRenderer(this);
        this.Load();

        this.Gantt.SearchFilter(this.resourceName);
    }

    private Load() {
        this.Gantt.Items([]);
        this.refreshFunctions[this.GetCurrentRendererType()]();
    }

    private OnResourcesLoaded() {
        var resources = this.teamsManagerProvider.GetResources();
        var maxDate = moment();

        resources.forEach((r: ResourceViewModel) => {
            this.currentRenderer.render(r);
            if(maxDate < moment(r.EndDate()))
                maxDate = moment(r.EndDate());
        });

        maxDate = maxDate.add('months', 3);
        this.Gantt.EndDate(maxDate.toDate());
    }

    private OnTeamsLoaded() {
        var teams : ITeamViewModel[] = this.teamsManagerProvider.GetTeams();
        var maxDate = moment();

        teams.forEach(t => {
            this.currentRenderer.render(t);
            if(maxDate < moment(t.EndDate()))
                maxDate = moment(t.EndDate());
        });

        maxDate = maxDate.add('months', 3);
        this.Gantt.EndDate(maxDate.toDate());
    }

    public HighlightInterval(startDate: Date, endDate: Date): void {
        this.Gantt.HighlightInterval(startDate, endDate);
    }

    public TeamFirst() {
        this.TeamFirstView(true);
        this.ResourceFirstView(false);
        this.CartsAllocationsView(false);

        this.currentRenderer = new TeamsFirstRenderer(this);
        this.Load();

        this.Gantt.SearchFilter(this.resourceName);
        this.Gantt.SearchFilter.notifySubscribers(this.resourceName);
    }

    public ResourceFirst() {
        this.TeamFirstView(false);
        this.ResourceFirstView(true);
        this.CartsAllocationsView(false);

        this.currentRenderer = new ResourcesFirstRenderer(this);
        this.Load();

        this.Gantt.SearchFilter(this.resourceName);
        this.Gantt.SearchFilter.notifySubscribers(this.resourceName);
    }

    public CartsAllocations() {
        this.TeamFirstView(false);
        this.ResourceFirstView(false);
        this.CartsAllocationsView(true);

        this.currentRenderer = new CartsAllocationsRenderer(this.serviceLocator, this.Gantt, this.resourceId, this.opUnitId);
        this.Load();

        this.Gantt.SearchFilter("");
    }

    public GetCurrentRendererType(): number {
        if (this.currentRenderer instanceof TeamsFirstRenderer)
            return ProlifeSdk.TeamFirstRenderer;
        if (this.currentRenderer instanceof ResourcesFirstRenderer)
            return ProlifeSdk.ResourceFirstRenderer;
        if (this.currentRenderer instanceof CartsAllocationsRenderer)
            return ProlifeSdk.CartsAllocationsRenderer;
        return null;
    }

    public SetIntervalStyle(interval: PlannerGanttItemInterval, value: number, serviceOrderValue: number): void {
        if (value > this.ResourcesDailyHoursLimit()) {
            interval.Color("#FF0000");
            interval.BorderColor("rgba(255, 0, 0, 0.40)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(false);
            return;
        }

        if (value <= this.ResourcesDailyHoursLimit() && value > serviceOrderValue) {
            interval.Color("#FFBC00");
            interval.BorderColor("rgba(255, 188, 0, 0.40)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(false);
            return;
        }

        if (value <= serviceOrderValue && value >= 0) {
            interval.Color("rgb(132, 185, 115)");
            interval.BorderColor("rgb(0, 128, 0)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(true);
            interval.HistogramHeight(Math.min((value * 18 / serviceOrderValue), 18));
            return;
        }
    }

    public Refresh() {
        this.Load();
    }
}