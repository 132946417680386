import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes"; 
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { FunctionPointProjectViewModel } from "../ViewModels/FunctionPointProjectViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IEditingDialog } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IEditingDialog";
import { IFunctionPointProjectViewModel } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProjectViewModel";

export class FunctionPointProjectDialogManagerViewModel implements IEditingDialog {
    
    public templateName: string = "new-project-dialog";
    public templateUrl: string = "FunctionPointComputation/Templates/Dialogs";
    public title: string = ProlifeSdk.TextResources.FunctionPointComputation.ProjectDialogTitle;
    
    public Project: IFunctionPointProjectViewModel;

    modal?: { close: (result?: any) => void; };
   
    private dialogService: IDialogsService;

    constructor(serviceLocator: IServiceLocator, project?: IFunctionPointProjectViewModel) {
        if (project)
            this.Project = project;
        else
            this.Project = new FunctionPointProjectViewModel(serviceLocator);

        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
    }

    public show(): void {
        this.dialogService.ShowModal(this, "large", null, this.templateUrl, this.templateName);
    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        this.Project.Save()
        .then(() => {
            this.modal.close();
        });
    }    
}