import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 16/02/2018
 * Time: 14:37
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { CategoriesEditingViewModel } from "./ui/CategoriesEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { ICategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { IEventCategory } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IFestivityCategory } from "../../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IFolder } from "../../../../ProlifeSdk/interfaces/agenda/IFolderService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class CategoriesSettingsManager implements ICategoriesSettingsManager {
    private eventsCategories: IEventCategory[] = [];
    private festivitiesCategories: IFestivityCategory[] = [];
    private folders: IFolder[] = [];

    private ajaxService: IAjaxService;
    private modulesService: IModulesService;

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, TextResources.Agenda.AgendaSettingsGroup);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<void> {
        var def = new Deferred<void>();

        Promise.all([
            this.ajaxService.Post<IEventCategory[]>("Agenda-api", "Categories/GetEventsCategories", {}),
            this.ajaxService.Post<IFestivityCategory[]>("Agenda-api", "Categories/GetFestivitiesCategories", {}),
            this.ajaxService.Post<IFolder[]>("Agenda-api", "Folders/GetAllFolders", {})
        ]).then(([eventsCategoriesResponse, festivitiesCategoriesResponse, folders]) => {
                if (!eventsCategoriesResponse || !festivitiesCategoriesResponse)
                    return;
                this.eventsCategories = eventsCategoriesResponse;
                this.festivitiesCategories = festivitiesCategoriesResponse;
                this.folders = folders;
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    getCategories(): IEventCategory[] {
        return this.eventsCategories;
    }

    getCategoryById(id: number): IEventCategory {
        var categories = this.eventsCategories.filter((c: IEventCategory) => c.Id == id);
        if (categories.length == 0)
            return null;
        return categories[0];
    }

    getFestivitiesCategories(): IFestivityCategory[] {
        return this.festivitiesCategories;
    }

    getFolders(): IFolder[] {
        return this.folders;
    }

    addOrUpdateCategory(category: IEventCategory): Promise<IEventCategory> {
        var def = new Deferred<IEventCategory>();
        this.ajaxService.Post("Agenda-api", "Categories/AddOrUpdateEventCategory", { methodData: category })
            .then((responseObject: IEventCategory) => {
                if (category.Id <= 0) {
                    this.eventsCategories.push(responseObject);
                    def.resolve(responseObject);
                    return;
                }
                this.replaceCategory(responseObject, this.eventsCategories);
                def.resolve(responseObject);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    addOrUpdateFestivityCategory(category: IFestivityCategory): Promise<IFestivityCategory> {
        var def = new Deferred<IFestivityCategory>();
        this.ajaxService.Post("Agenda-api", "Categories/AddOrUpdateFestivityCategory", { methodData: category })
            .then((responseObject: IFestivityCategory) => {
                if (category.Id <= 0) {
                    this.festivitiesCategories.push(responseObject);
                    def.resolve(responseObject);
                    return;
                }
                this.replaceCategory(responseObject, this.festivitiesCategories);
                def.resolve(responseObject);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    addOrUpdateFolder(folder: IFolder): Promise<IFolder> {
        var def = new Deferred<IFolder>();

        this.ajaxService.Post("Agenda-api", "Folders", { methodData: folder })
            .then((responseObject: IFolder) => {
                if (folder.Id <= 0) {
                    this.folders.push(responseObject);
                    def.resolve(responseObject);
                    return;
                }
                this.replaceCategory(responseObject, this.folders);
                def.resolve(responseObject);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    removeFolder(id: number): Promise<IFolder[]> {
        var def = new Deferred<IFolder[]>();

        this.ajaxService.Delete("Agenda-api", "Folders?folderId=" + id, {})
            .then((folders: IFolder[]) => {
                this.folders = folders;
                def.resolve(folders);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    removeCategory(id: number): Promise<IEventCategory[]> {
        var def = new Deferred<IEventCategory[]>();
        this.ajaxService.Post("Agenda-api", "Categories/RemoveEventCategory", { methodData: { CategoryId: id }})
            .then((categories: IEventCategory[]) => {
                this.eventsCategories = categories;
                def.resolve(categories);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    removeFestivityCategory(id: number): Promise<IFestivityCategory[]> {
        var def = new Deferred<IFestivityCategory[]>();
        this.ajaxService.Post("Agenda-api", "Categories/RemoveFestivityCategory", { methodData: { CategoryId: id }})
            .then((categories: IFestivityCategory[]) => {
                this.festivitiesCategories = categories;
                def.resolve(categories);
            })
            .catch(() => {
                def.reject();
            });
        return def.promise();
    }

    moveEventCategory(movedId: number, newPosition: number): Promise<IEventCategory[]> {
        var def = new Deferred<IEventCategory[]>();

        this.ajaxService.Post("Agenda-api", "Categories/MoveEventCategory", { methodData: { CategoryId: movedId, NewPosition: newPosition }})
            .then((orderedCategories: IEventCategory[]) => {
                this.eventsCategories = orderedCategories;
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    moveFestivityCategory(movedId: number, newPosition: number): Promise<IFestivityCategory[]> {
        var def = new Deferred<IFestivityCategory[]>();

        this.ajaxService.Post("Agenda-api", "Categories/MoveFestivityCategory", { methodData: { CategoryId: movedId, NewPosition: newPosition }})
            .then((orderedCategories: IFestivityCategory[]) => {
                this.festivitiesCategories = orderedCategories;
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    moveFolder(movedId: number, newPosition: number): Promise<IFolder[]> {
        var def = new Deferred<IFolder[]>();

        this.ajaxService.Post("Agenda-api", "Folders/MoveFolder", { methodData: { FolderId: movedId, NewPosition: newPosition }})
            .then((orderedFolders: IFolder[]) => {
                this.folders = orderedFolders;
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.EventsCategoriesSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Agenda.CategoriesSettings;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.AgendaApplication);
    }

    getEditingUI(): IView {
        return {
            templateName: "categories-editor",
            templateUrl: "agenda/templates/settings",
            viewModel: new CategoriesEditingViewModel(this.serviceLocator, this)
        };
    }

    private replaceCategory(category: any, categories: any[]): void {
        for (var i = 0; i < categories.length; i++) {
            if (category.Id == categories[i].Id) {
                categories.splice(i, 1, category);
                break;
            }
        }
    }
}