import * as ko from "knockout";
export class WhenClickOut
{
    init(element: any, valueAccessor: () => any): void
    {
        var callback = ko.utils.unwrapObservable(valueAccessor())["callback"];
        var unbindWhen = ko.utils.unwrapObservable(valueAccessor())["unbindWhen"];
        var closeButtonSelector = ko.utils.unwrapObservable(valueAccessor())["closeButtonSelector"];
        var closeBtn = null;

        if(closeButtonSelector)
            closeBtn = $(element).find(closeButtonSelector)[0];

        var onEverywhereClick = (e) =>
        {
            if((!jQuery.contains(element, e.target) || closeBtn == e.target) && e.target != element)
            {
                callback();

                if(unbindWhen)
                {
                    $("body").unbind("click.whenClickOut");

                    if(closeBtn)
                        $(closeBtn).unbind("click.whenClickOnCloseBtn");
                }
            }

            e.stopPropagation();
        }

        if(closeBtn)
            $(closeBtn).click("click.whenClickOnCloseBtn", onEverywhereClick);

        $("body").bind("click.whenClickOut", onEverywhereClick);
    }


}

ko.bindingHandlers['whenClickOut'] = new WhenClickOut();
