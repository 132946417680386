import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { WarehouseApplication } from "./warehouse/WarehouseApplication";
import { WarehouseArticlesDataSource } from "./warehouse/documents/data-sources/WarehouseArticlesDataSource";
import { WarehouseInventoryAdjustmentDataSource } from "./warehouse/documents/data-sources/WarehouseInventoryAdjustmentDataSource";
import { WarehouseUnderStockArticlesDataSource } from "./warehouse/documents/data-sources/WarehouseUnderStockArticlesDataSource";
import { WarehouseStockDataSource } from "./warehouse/documents/data-sources/WarehouseStockDataSource";
import { WarehouseSupplierOrderDataSource } from "./warehouse/documents/data-sources/WarehouseSupplierOrderDataSource";
import { WarehouseCustomerOrderDataSource } from "./warehouse/documents/data-sources/WarehouseCustomerOrderDataSource";
import { ArticleRefDetailsViewModelFactory } from "./warehouse/providers/refs-details-factories/ArticleRefDetailsViewModelFactory";
import { RowToArticleRefConverterProvider } from "./warehouse/documents/converters/RowToArticleRefConverterProvider";
import { WarehouseReportsProvider } from "./warehouse/reports/WarehouseReportsProvider";
import { LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IService } from "../Core/interfaces/IService";
import {
    IArticlesService,
    IArticlesServiceObserver,
    IDocumentCost,
    IArticle,
    IArticleWithPosition,
    IArticleTransform,
    IArticleStockAndCost,
    IDeleteArticleResult,
    IDocumentReferencingArticle,
    IArticleDocumentMovement,
    IArticleById,
    IArticlePurchase,
    IArticlePurchaseWithStock,
} from "../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IReportsService } from "../ProlifeSdk/interfaces/report/IReportsService";
import { IModulesService } from "../ProlifeSdk/interfaces/desktop/IModulesService";
import { IAuthorizationServiceObserver } from "../Core/interfaces/IAuthorizationServiceObserver";
import {
    IAllBusinessInfoForArticle,
    IArticleBusinessInfoSimple,
} from "../ProlifeSdk/interfaces/warehouse/IArticleBusinessInfo";
import { RowToPurchaseArticleRefConverterProvider } from "./warehouse/documents/converters/RowToPurchaseArticleRefConverterProvider";
import { RowToNewPurchaseArticleRefConverterProvider } from "./warehouse/documents/converters/RowToNewPurchaseArticleRefConverterProvider";
import { PurchaseArticleRefDetailsViewModelFactory } from "./warehouse/providers/refs-details-factories/PurchaseArticleRefDetailsViewModelFactory";
import { WarehouseLoadDataSource } from "./warehouse/documents/data-sources/WarehouseLoadDataSource";
import { ResponseBase } from "../Core/response/ResponseBase";

export class ArticlesService implements IArticlesService, IAuthorizationServiceObserver, ISettingsServiceObserver {
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;
    @LazyImport(nameof<IReportsService>())
    private reportsService: IReportsService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    private warehouseApplication: WarehouseApplication;
    private observers: IArticlesServiceObserver[] = [];
    private dataSourcesConfigured = false;

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IArticlesService>(), this);
    }

    InitializeService() {
        this.authorizationService.addObserver(this);
        this.settingsService.addObserver(this);

        this.warehouseApplication = new WarehouseApplication(this.serviceLocator);

        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.WarehouseArticleEntityTypeCode,
            new ArticleRefDetailsViewModelFactory()
        );
        this.entitiesService.registerReferenceDetailsViewModelFactory(
            ProlifeSdk.WarehousePurchaseArticleEntityTypeCode,
            new PurchaseArticleRefDetailsViewModelFactory()
        );

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.WarehouseArticleEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Warehouse.Article,
            PluralEntityName: ProlifeSdk.TextResources.Warehouse.Articles,
        });
    }

    CalculateProtocolCostReport(documentType: string, from: Date, to: Date, protocolId = -1): Promise<IDocumentCost[]> {
        return this.ajaxService.Post("Warehouse-api", "ProtocolCostReport/CalculateReport", {
            methodData: {
                DocumentType: documentType,
                ProtocolId: protocolId,
                From: from,
                To: to,
            },
        });
    }

    onSettingsLoaded(): void {
        if (this.modulesService.IsModuleEnabled(ProlifeSdk.WarehouseApplicationCode)) {
            this.reportsService.registerReportsProvider(new WarehouseReportsProvider());
            this.documentsService.registerRowReferencesGeneratorFactory(new RowToArticleRefConverterProvider());

            if (this.authorizationService.isAuthorized("Warehouse_ViewNewPurchases")) {
                this.documentsService.registerRowReferencesGeneratorFactory(
                    new RowToPurchaseArticleRefConverterProvider()
                );
                this.documentsService.registerRowReferencesGeneratorFactory(
                    new RowToNewPurchaseArticleRefConverterProvider()
                );
            }
        }
    }

    onSettingsUpdated(updateType: string): void {}

    authorizationLoaded(rights: any): void {
        if (this.dataSourcesConfigured) return;

        this.documentsService.unregisterDocumentDataSource(
            (dds) =>
                dds instanceof WarehouseCustomerOrderDataSource ||
                dds instanceof WarehouseArticlesDataSource ||
                dds instanceof WarehouseUnderStockArticlesDataSource ||
                dds instanceof WarehouseSupplierOrderDataSource ||
                dds instanceof WarehouseLoadDataSource
        );

        new WarehouseCustomerOrderDataSource();
        new WarehouseSupplierOrderDataSource();
        new WarehouseArticlesDataSource();
        new WarehouseUnderStockArticlesDataSource();
        new WarehouseStockDataSource();
        new WarehouseInventoryAdjustmentDataSource();
        new WarehouseLoadDataSource();

        this.dataSourcesConfigured = true;
    }

    getServiceType(): string {
        return ProlifeSdk.ArticlesServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getArticles(searchFilter: string, catalogId?: number, skip = 0, count = 20): Promise<IArticle[]> {
        return this.ajaxService.Post<IArticle[]>("Warehouse-api/Articles", "Search", {
            methodData: {
                Filter: searchFilter,
                Skip: skip,
                Count: count,
                CatalogId: catalogId,
            },
            background: true,
        });
    }

    getArticlesCount(searchFilter: string, catalogId?: number): Promise<number> {
        return this.ajaxService.Post<number>("Warehouse-api/Articles", "Count", {
            methodData: {
                Filter: searchFilter,
                CatalogId: catalogId,
            },
            background: true,
        });
    }

    getArticleByCatalogRowId(articleId: number): Promise<IArticle> {
        if (!articleId) {
            return Promise.reject<IArticle>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "Articles/GetArticlesById/" + articleId, { background: true });
    }

    getArticlesByCatalogRowIds(articleIds: number[]): Promise<IArticle[]> {
        return this.ajaxService.Post("Warehouse-api", "Articles/GetArticlesByCatalogRowIds", {
            background: true,
            methodData: {
                Ids: articleIds,
            },
        });
    }

    getArticleByArticleId(articleId: number, async = true): Promise<IArticle> {
        if (!articleId) {
            return Promise.reject<IArticle>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "Articles/GetArticleByArticleId", {
            background: true,
            async: async,
            methodData: articleId,
        });
    }

    GetAllBusinessInfoForArticle(
        articleId: number,
        warehouseId: number,
        catalogId: number,
        customerId?: number
    ): Promise<IAllBusinessInfoForArticle> {
        return this.ajaxService.Post("Warehouse-api", "ArticleBusinessInfo/LoadAllBusinessInfoForArticle", {
            methodData: {
                ArticleId: articleId,
                CatalogId: catalogId,
                CustomerId: customerId,
                WarehouseId: warehouseId,
            },
            background: true,
            async: true,
        });
    }

    GetLoadPriceAvg(articleId: number, warehouseId: number): Promise<number> {
        return this.ajaxService.Post("Warehouse-api", "ArticleBusinessInfo/GetLoadPriceAvg", {
            methodData: {
                ArticleId: articleId,
                WarehouseId: warehouseId,
            },
        });
    }

    createOrUpdate(article: IArticleWithPosition): Promise<IArticleWithPosition> {
        var callback = article.Id ? this.onArticleChanged.bind(this) : this.onArticleAdded.bind(this);

        return this.ajaxService
            .Post<IArticleWithPosition>("Warehouse-api", "Articles", { methodData: article })
            .then(callback);
    }

    remove(articleId: number): Promise<void> {
        if (!articleId) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService
            .Delete<void>("Warehouse-api", "Articles/" + articleId, {})
            .then(this.onArticleDeleted.bind(this, articleId));
    }

    addObserver(observer: IArticlesServiceObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IArticlesServiceObserver): void {
        var index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    private onArticleAdded(article: IArticleWithPosition): IArticleWithPosition {
        this.observers.forEach((observer) => observer.onArticleAdded(article));
        return article;
    }

    private onArticleChanged(article: IArticleWithPosition): IArticleWithPosition {
        this.observers.forEach((observer) => observer.onArticleChanged(article));
        return article;
    }

    private onArticleDeleted(articleId: number): void {
        this.observers.forEach((observer) => observer.onArticleDeleted(articleId));
    }

    GetArticleBusinessInfoSimple(articleId: number, warehouseId: number): Promise<IArticleBusinessInfoSimple[]> {
        return this.ajaxService.Post("Warehouse-api", "ArticleBusinessInfo/GetArticleBusinessInfoSimple", {
            methodData: {
                ArticleId: articleId,
                WarehouseId: warehouseId,
            },
        });
    }

    GetArticlesTransforms(searchQuery: string): Promise<IArticleTransform[]> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/GetArticlesTransforms", {
            methodData: {
                searchQuery: searchQuery,
            },
        });
    }

    GetArticleTransforms(articleId: number): Promise<IArticleTransform> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/GetArticleTransforms", {
            methodData: {
                articleId: articleId,
            },
        });
    }

    InsertOrUpdateArticleTransform(articleId: number, transform: IArticleTransform): Promise<void> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/InsertOrUpdateArticleTransform", {
            methodData: {
                articleId: articleId,
                transform: transform,
            },
        });
    }

    DeleteTransform(articleId: number): Promise<void> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/DeleteArticleTransforms", {
            methodData: {
                articleId: articleId,
            },
        });
    }

    IsTransformChildRecursive(sourceArticleId: number, articleId: number): Promise<boolean> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/IsTransformChildRecursive", {
            methodData: {
                sourceArticleId: sourceArticleId,
                articleId: articleId,
            },
        });
    }

    IsTransformParentRecursive(sourceArticleId: number, articleId: number): Promise<boolean> {
        return this.ajaxService.Post("Warehouse-api", "Transforms/IsTransformParentRecursive", {
            methodData: {
                sourceArticleId: sourceArticleId,
                articleId: articleId,
            },
        });
    }

    getArticleStockAndCostAtDate(
        articleId: number,
        warehouseId: number,
        referenceDate: Date
    ): Promise<IArticleStockAndCost> {
        return this.ajaxService.Post("Warehouse-api", "Articles/GetArticleStockAndCostAtDate", {
            methodData: {
                articleId: articleId,
                warehouseId: warehouseId,
                referenceDate: referenceDate,
            },
        });
    }

    deleteArticle(ArticleId: number): Promise<IDeleteArticleResult> {
        return this.ajaxService.Post<IDeleteArticleResult>("Warehouse-api/Articles", "DeleteArticle", {
            background: true,
            methodData: {
                ArticleId: ArticleId,
            },
        });
    }

    GetDocumentsWhereArticleIsUsed(
        ArticleId: number | null,
        Skip: number | null,
        Count: number | null
    ): Promise<IDocumentReferencingArticle[]> {
        return this.ajaxService.Post<IDocumentReferencingArticle[]>(
            "Warehouse-api/Articles",
            "GetDocumentsWhereArticleIsUsed",
            {
                background: true,
                methodData: {
                    ArticleId: ArticleId,
                    Skip: Skip,
                    Count: Count,
                },
            }
        );
    }

    GetArticleDocumentsIntoPeriod(
        articleId: number | null,
        warehouseId: number | null,
        from: Date | null,
        to: Date | null
    ): Promise<IArticleDocumentMovement[]> {
        const result = this.ajaxService.Post<IArticleDocumentMovement[]>(
            "Warehouse-api/ArticleBusinessInfo",
            "GetArticleDocumentsIntoPeriod",
            {
                background: true,
                methodData: {
                    articleId: articleId,
                    warehouseId: warehouseId,
                    from: from,
                    to: to,
                },
            }
        );

        return result;
    }

    GetArticlesByIds(ids: number[] | null): Promise<IArticleById[]> {
        const result = this.ajaxService.Post<IArticleById[]>("Warehouse-api/Articles", "GetArticlesByIds", {
            background: true,
            methodData: {
                ids: ids,
            },
        });

        return result;
    }

    searchPurchases(
        query: string,
        warehouseId: number | null,
        onlyInStock: boolean,
        skip = 0,
        count = 50
    ): Promise<IArticlePurchase[]> {
        return this.ajaxServiceNew.Post<IArticlePurchase[]>("w/Purchases", "search", {
            methodData: {
                query,
                warehouseId,
                onlyInStock,
                skip,
                count,
            },
        });
    }

    searchPurchasesCount(query: string, warehouseId: number, onlyInStock: boolean): Promise<number> {
        return this.ajaxServiceNew.Post<number>("w/Purchases", "search/count", {
            methodData: {
                query,
                warehouseId,
                onlyInStock,
            },
        });
    }

    getPurchaseById(id: number, warehouseId: number): Promise<IArticlePurchase> {
        return this.ajaxServiceNew.Post<IArticlePurchase>("w/Purchases", "getPurchaseById", {
            methodData: {
                id,
                warehouseId,
            },
        });
    }

    searchPurchasesStock(
        query: string,
        warehouseId: number,
        skip = 0,
        count = 50,
        onlyInStock = true
    ): Promise<IArticlePurchaseWithStock[]> {
        return this.ajaxServiceNew.Post<IArticlePurchaseWithStock[]>("w/Purchases", "searchStock", {
            methodData: {
                query,
                warehouseId,
                skip,
                count,
                onlyInStock,
            },
        });
    }

    countPurchasesStock(query: string, warehouseId: number, onlyInStock = true): Promise<number> {
        return this.ajaxServiceNew.Post<number>("w/Purchases", "countStock", {
            methodData: {
                query,
                warehouseId,
                onlyInStock,
            },
        });
    }

    async updatePurchase(purchase: IArticlePurchase): Promise<void> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseBase>("w/Purchases", "update", {
                methodData: purchase,
            });

            if (!response.succeeded) {
                throw response.errors;
            }
        } catch (error) {
            console.log(error);

            throw error;
        }
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ArticlesService(serviceLocator);
}
