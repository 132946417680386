import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { NavigationProvider } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { TypesNavigationProvider } from "./providers/splash-page-menu-providers/TypesNavigationProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IStateAndTypeMenuObserver, INavigationFilters } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class RootNavigationProvider extends NavigationProvider
{
    constructor(serviceLocator : IServiceLocator)
    {
        super();
        this.Name = ProlifeSdk.TextResources.JobOrder.Types;
        this.AllowMultiLevelSearch = false;

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var typesManager = <IJobOrderTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderType);
        //var types : IJobOrderType[] = typesManager.getJobOrderTypes();
        var types : IJobOrderType[] = typesManager.getVisibleJobOrderTypes();
        types.forEach((t : IJobOrderType) => {
            var navigationProvider = new TypesNavigationProvider(serviceLocator, -1, t);
            navigationProvider.IsLeaf = true;

            this.Items.push(navigationProvider);
        });
    }
}

export class TypeSelectionMenu extends NavigationMenu
{
    public StopOnTypeLevel : boolean = true;
    public SelectedType : ko.Observable<number> = ko.observable(-1);

    private typeObservers : IStateAndTypeMenuObserver[] = [];

    constructor(serviceLocator : IServiceLocator)
    {
        super(serviceLocator, new RootNavigationProvider(serviceLocator));
        this.templateUrl = "jobOrder/Templates/navigation";
        this.templateName = "state-and-type-navigator";
        this.setDefaultMenuLocation();
    }

    private setDefaultMenuLocation()
    {
        this.MenuProvidersStack([this.RootMenuProvider]);
    }

    navigateTo(provider : INavigationMenuProvider)
    {
        super.navigateTo(provider);
        this.NotifyChangesOnFilters();
    }

    private NotifyChangesOnFilters()
    {
        this.typeObservers.forEach((o : IStateAndTypeMenuObserver) => {o.OnNavigationFilterIsChanged(this.GetFiltersFromActualPosition()); });
    }

    public GetFiltersFromActualPosition() : INavigationFilters
    {
        var filters : INavigationFilters = {
            LogicalState : -1,
            Type : -1,
            State : -1
        };

        this.MenuProvidersStack().forEach((p) => {
            if((<any>p).SetFilterInfoOnModel)
                (<any>p).SetFilterInfoOnModel(filters);
        } );

        this.selectedProviders.forEach((p) => {
            if((<any>p).SetFilterInfoOnModel)
                (<any>p).SetFilterInfoOnModel(filters);
        });

        return filters;
    }

    GoBack()
    {
        super.GoBack();
        this.clearSelection(false);
        this.NotifyChangesOnFilters();
    }

    selectProvider(provider : INavigationMenuProvider, notifyToObservers : boolean = true)
    {
        super.selectProvider(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    removeProviderFromSelection(provider: INavigationMenuProvider, notifyToObservers : boolean = true)
    {
        super.removeProviderFromSelection(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    RegisterStateAndTypeObserver(o : IStateAndTypeMenuObserver)
    {
        this.typeObservers.push(o);
    }
}