import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { Select } from "./Select";
import { MailTemplatesDataSource } from "../DataSources/MailTemplatesDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

type MailTemplateSelectorProps = {
    mailTemplateId: ko.Observable<number>;
    readonly: ko.Observable<boolean> | ko.Computed<boolean>;
};

export function MailTemplateSelector(props: MailTemplateSelectorProps) {
    const C = require("./MailTemplateSelector")._MailTemplateSelector as typeof _MailTemplateSelector;
    return <C {...props} />;
}

export class _MailTemplateSelector {
    static defaultProps: Partial<MailTemplateSelectorProps> = {};

    private templatesDataSource: MailTemplatesDataSource = new MailTemplatesDataSource();

    constructor(private props: MailTemplateSelectorProps) {}

    render() {
        return ComponentUtils.bindTo(
            <Select
                dataSource={this.templatesDataSource}
                value={this.props.mailTemplateId}
                placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                allowClear
                readOnly={this.props.readonly}
            />,
            this
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(MailTemplateSelector);
}
