import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { ITodoListTask } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import { INavigationMenu } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";

export class TaskProvider extends WorkflowsNavigatorProvider
{
    public IsVisible : ko.Computed<boolean>;

    private StatusDescription : ko.Observable<string> = ko.observable();
    public task : ITodoListTask;
    public NumberOfEvents : ko.Observable<number> = ko.observable(0);
    public ShowNumberOfEvents : boolean = false;
    public StatusIcon : ko.Observable<string> = ko.observable();
    public IsMilestone : ko.Observable<boolean> = ko.observable();

    public IsAllocated : ko.Observable<boolean> = ko.observable();
    public IsEstimated : ko.Observable<boolean> = ko.observable();

    private taskStates = [
        ProlifeSdk.TextResources.Todolist.Backlog,
        ProlifeSdk.TextResources.Todolist.ToDo,
        ProlifeSdk.TextResources.Todolist.InProgress,
        ProlifeSdk.TextResources.Todolist.Completed,
        ProlifeSdk.TextResources.Todolist.Verified,
        ProlifeSdk.TextResources.Todolist.Suspended,
        ProlifeSdk.TextResources.Todolist.DeletedColumn
    ];

    private taskIcons = [
        "fa fa-inbox",
        "fa fa-tachometer",
        "fa fa-bolt",
        "fa fa-flag-checkered",
        "fa fa-check",
        "fa fa-clock-o",
        "fa fa-trash-o"
    ];

    constructor(serviceLocator : IServiceLocator)
    {
        super(serviceLocator);
        this.templateName = "task-item";
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.IsLeaf = true;

        this.IsDraggable = ko.computed(() => {
            return this.navigator() ? this.navigator().draggableOptions.EnableTasksDragging() : false;
        });
    }

    public RefreshData(task : ITodoListTask, numberOfEvents : number = 0) : Promise<void>
    {
        this.Id = task.Id;
        this.task = task;
        this.NumberOfEvents(numberOfEvents);
        this.Name = task.Title;

        var status = this.taskStates[task.TaskBoardStatus + 1];
        if(task.TaskBoardStatus == 1) {
            status += " " + numeral(task.ProgressAvg).format("0,0") + "%";
        }

        this.StatusIcon(this.taskIcons[task.TaskBoardStatus + 1]);

        this.StatusDescription(status);
        this.IsMilestone(task.IsMilestone);
        this.IsAllocated(task.IsAllocated);
        this.IsEstimated(task.IsEstimated);

        return Promise.resolve<void>(undefined);
    }

    search(textFilter : string) : Promise<void>
    {
        return Promise.resolve<void>(undefined);
    }

    setCurrentNavigator(navigator : INavigationMenu)
    {
        super.setCurrentNavigator(navigator);
        this.ShowNumberOfEvents = (<any>navigator).IsNumberOfEventsVisible || false;
    }
}
