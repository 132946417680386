import * as ko from "knockout";
import * as numeral from "numeral";
import { SplashPage } from "./SplashPage";
import {
    TreeGrid,
    TreeGridStringColumn,
    TreeGridMoneyColumn,
    TreeGridDateTimeColumn,
    TreeGridMultilineStringColumn,
} from "../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IJobOrderForSplash } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { SplashPageDetailsDialog } from "./SplashPageDetailsComponent";
import { Right } from "../../../../Core/Authorizations";

export class SplashPageTableView {
    public templateName = "splashPageTableView";
    public templateUrl = "joborder/templates";

    public Table: TreeGrid<IJobOrderForSplash> = new TreeGrid<IJobOrderForSplash>();
    public VisibleNotes: ko.Observable<boolean> = ko.observable(false);
    public CompactView: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @Right("JobOrders_ViewJobOrderDetailsPopOver")
    private canShowDetailsPopover: boolean;

    constructor(serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.Table.AllowSort(false);

        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Customer,
                (a) => a.CustomerName,
                300
            ).Format((c) => (!c ? "" : c))
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Manager,
                (a) => a.ResponsibleName,
                200
            )
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Type,
                (a) => a.TypeDescription,
                200
            ).Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Status,
                (a) => a.StateDescription,
                250
            ).Align("center")
        );
        this.Table.AddColumn(
            new TreeGridMoneyColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Billed,
                (a) => a.Fatturato,
                150
            ).Align("center")
        );

        this.Table.AddColumn(
            new TreeGridMoneyColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.WorkCost,
                (a) => a.DoneWorkCost,
                150
            ).Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash, number>(
                ProlifeSdk.TextResources.JobOrder.Worked,
                (a) => a.DoneWork,
                150
            )
                .Format((w) => (w == null || w == undefined ? "" : numeral(w).format("0,00[0]") + " h/u"))
                .Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash, number>(
                ProlifeSdk.TextResources.JobOrder.WorkEstimation,
                (a) => a.EstimatedWork,
                150
            )
                .Format((w) => (w == null || w == undefined ? "" : numeral(w).format("0,00[0]") + " h/u"))
                .Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash, number>(
                ProlifeSdk.TextResources.JobOrder.WarehousesCount,
                (a) => a.WarehousesCount,
                150
            ).Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash, number>(
                ProlifeSdk.TextResources.JobOrder.WorkflowsCount,
                (a) => a.TotalWorkflows,
                150
            ).Align("center")
        );
        this.Table.AddColumn(
            new TreeGridStringColumn<IJobOrderForSplash, number>(
                ProlifeSdk.TextResources.JobOrder.ClosedWorkflows,
                (a) => a.ClosedWorkflows,
                150
            ).Align("center")
        );

        this.Table.AddColumn(
            new TreeGridDateTimeColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Start,
                (a) => a.StartDate,
                100
            )
                .Format((d) => (!d ? "" : moment(d).format("L")))
                .Align("center")
        );
        this.Table.AddColumn(
            new TreeGridDateTimeColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.Expiration,
                (a) => a.EndDate,
                100
            )
                .Format((d) => (!d ? "" : moment(d).format("L")))
                .Align("center")
        );
        this.Table.AddColumn(
            new TreeGridDateTimeColumn<IJobOrderForSplash>(
                ProlifeSdk.TextResources.JobOrder.RequestEndDate,
                (a) => a.RequestEndDate,
                100
            )
                .Format((d) => (!d ? "" : moment(d).format("L")))
                .Align("center")
        );

        this.VisibleNotes(
            this.authorizationsService.isAuthorized("JobOrders_ViewCommercialInfo") ||
                this.authorizationsService.isAuthorized("JobOrders_ViewTechnicalInfo") ||
                this.authorizationsService.isAuthorized("JobOrders_ViewAdministrationInfo")
        );

        if (this.authorizationsService.isAuthorized("JobOrders_ViewCommercialInfo")) {
            const column = this.Table.AddColumn(
                new TreeGridMultilineStringColumn<IJobOrderForSplash>(
                    ProlifeSdk.TextResources.JobOrder.CommercialNotes,
                    (a) => a.Description,
                    600
                )
                    .Format(
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter text-ellipsis" data-bind="treeGridColumnAction: $data"><a style="color: black" href="javascript:void(0);">' +
                                  $(v).wrapAll("<div></div>").parent().text() +
                                  "</a></div>",
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter" data-bind="slimScroll: \'99px\'">' +
                                  v +
                                  "</div>",
                        this.CompactView
                    )
                    .Align("left")
            ) as TreeGridMultilineStringColumn;
            column.RegisterAction((value: string, element: HTMLElement) => {
                const popOver = new JobOrderNotePopOver(ProlifeSdk.TextResources.JobOrder.CommercialNotes, value);
                popOver.show(element);
            });
        }

        if (this.authorizationsService.isAuthorized("JobOrders_ViewTechnicalInfo")) {
            const column = this.Table.AddColumn(
                new TreeGridMultilineStringColumn<IJobOrderForSplash>(
                    ProlifeSdk.TextResources.JobOrder.TechnicalNotes,
                    (a) => a.Description,
                    600
                )
                    .Format(
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter text-ellipsis" data-bind="treeGridColumnAction: $data"><a style="color: black" href="javascript:void(0);">' +
                                  $(v).wrapAll("<div></div>").parent().text() +
                                  "</a></div>",
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter" data-bind="slimScroll: \'99px\'">' +
                                  v +
                                  "</div>",
                        this.CompactView
                    )
                    .Align("left")
            ) as TreeGridMultilineStringColumn;
            column.RegisterAction((value: string, element: HTMLElement) => {
                const popOver = new JobOrderNotePopOver(ProlifeSdk.TextResources.JobOrder.TechnicalNotes, value);
                popOver.show(element);
            });
        }

        if (this.authorizationsService.isAuthorized("JobOrders_ViewAdministrationInfo")) {
            const column = this.Table.AddColumn(
                new TreeGridMultilineStringColumn<IJobOrderForSplash>(
                    ProlifeSdk.TextResources.JobOrder.AdministrationNotes,
                    (a) => a.Description,
                    600
                )
                    .Format(
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter text-ellipsis" data-bind="treeGridColumnAction: $data"><a style="color: black" href="javascript:void(0);">' +
                                  $(v).wrapAll("<div></div>").parent().text() +
                                  "</a></div>",
                        (v) =>
                            !v
                                ? ""
                                : '<div class="fixedTable-height-limiter" data-bind="slimScroll: \'99px\'">' +
                                  v +
                                  "</div>",
                        this.CompactView
                    )
                    .Align("left")
            ) as TreeGridMultilineStringColumn;
            column.RegisterAction((value: string, element: HTMLElement) => {
                const popOver = new JobOrderNotePopOver(ProlifeSdk.TextResources.JobOrder.AdministrationNotes, value);
                popOver.show(element);
            });
        }

        this.Refresh();
    }

    public dispose() {}

    async Refresh() {
        const count = await this.jobOrdersService.GetJobOrdersForSplashCount(this.parent.GetFilters());
        this.parent.JobOrdersCount(count);

        const jobOrders = await this.jobOrdersService.GetJobOrdersForSplash(this.parent.GetFilters(), 0, 1000, false);

        this.Table.ClearItems();
        for (const jobOrder of jobOrders) {
            const item = this.Table.AddItem(jobOrder, jobOrder.Name);
            item.CanHaveChildren(false);
            item.Actions.push({
                Classes: "btn-primary",
                Icon: "fa fa-search",
                Action: (item, action, event) =>
                    window.open(this.jobOrdersService.getJobOrderUrl(item.Value().JobOrderId), "_target"),
            });

            if (this.canShowDetailsPopover) {
                item.Actions.push({
                    Classes: "btn-primary",
                    Icon: "fa fa-info-circle",
                    Action: (item, action, event) => {
                        return this.dialogsService.ShowModal(
                            new SplashPageDetailsDialog({
                                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, item.Value().Name),
                                JobOrderId: item.Value().JobOrderId,
                            })
                        );
                    },
                });
            }
        }
    }
}

class JobOrderNotePopOver implements IDialog {
    public templateName = "joborder-note-popover";
    public templateUrl = "joborder/templates";
    public title: string;
    public modal?: { close: (result?: any) => void };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(title: string, public note: string) {
        this.title = title;
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        this.modal.close();
    }

    public show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopover(element, this, "top");
    }
}
