import { IExtendedBlogMailData, IMailsService } from "../Blog/MailsService";
import { LazyImport } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { Delay } from "../Decorators/Delay";
import { IDesktopService } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export interface IMailsMenuDataSourceModel extends IDataSourceModel<number, IExtendedBlogMailData> {
    safeMailBody: string;
}

export class MailsMenuDataSource extends BaseDataSource<IMailsMenuDataSourceModel> {
    @LazyImport(nameof<IMailsService>())
    private mailsService : IMailsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    private currentUserId: number;

    constructor() {
        super();

        this.currentUserId = this.userInfo.getIdUser(); // Modificare nel caso si debba poter gestire anche le mail di altri
    }

    getTitle(currentModel: IMailsMenuDataSourceModel): string {
        return TextResources.Blog.MailsMenuTitle;
    }

    async getData(currentModel: IMailsMenuDataSourceModel, textFilter: string, skip: number, count: number): Promise<IMailsMenuDataSourceModel[]> {
        return (await this.mailsService.GetMailsFromTemporaryStorage({ userId: this.currentUserId, textFilter: textFilter, skip: skip, count: count })).map(this.createMaildDataSourceModel, this);
    }
    
    async getById(currentModel: IMailsMenuDataSourceModel, ids: number[]): Promise<IMailsMenuDataSourceModel[]> {
        return (await this.mailsService.GetMailsFromTemporaryStorageByIds(ids)).map(this.createMaildDataSourceModel, this);
    }

    private createMaildDataSourceModel(mail: IExtendedBlogMailData): IMailsMenuDataSourceModel {
        let safeMailBody = "";

        try {
            safeMailBody = $((mail.Body ?? "<div></div>")).filter(function() { return this.nodeType !== Node.COMMENT_NODE && this.tagName !== "STYLE" }).text();
        } catch (e) {
            safeMailBody = mail.Body;
        }

        return {
            id: mail.Id,
            title: mail.Senders.join("; "),
            isGroup: false,
            isLeaf: true,
            model: mail,
            safeMailBody: safeMailBody
        };
    }

    public getSupportedDropMimeTypes(): string[] {
        return ['Files'];
    }

    public async onItemMoved(dataTransfer: DataTransfer): Promise<void> {
        if (dataTransfer.files.length > 0)
            await this.loadEmails(dataTransfer.files);
    }
    
    private async loadEmails(mails: FileList): Promise<void> {

        for (let i = 0; i < mails.length; i++) {
            const mail = mails[i];
            let data = { 
                mail: mail,
                user: this.currentUserId
            };

            let uploadDef = this.mailsService.MailUploadOnTemporaryStorage(data);
            this.desktopService.AddUploadNotification("E-Mail", uploadDef);

            uploadDef
                .then(this.onEmailLoaded.bind(this))
                .catch(this.onEmailUploadError.bind(this));
        }
    }

    @Delay(200)
    private onEmailLoaded(): void {
        this.refresh();
    }

    private onEmailUploadError(error) {
        // TODO
        console.log(error);
    }
}