import * as ko from "knockout";
/**
 * Created by d.bucchi on 12/06/2017.
 */
interface ISideMenuOptions {
    LeftMenuEnabled: ko.Observable<boolean>;
    RightMenuEnabled: ko.Observable<boolean>;
    registerObserver: (observer: any) => void;
}
interface ISelfSideMenu{
    panDirections: any;
    applyBinding: any;
    applyBinding2: any;
    startX: number;
    startY: number;
    startLeftPosition: number;
    startTime: Date;
    leftMenuOpened: boolean;
    rightMenuOpened: boolean;
    onXScrollEnabled: boolean;
}

export class SideMenuBindingHandler {

    public init(element: HTMLElement, valueAccessor: () => any, allBindings: () => any) {
        var self: ISelfSideMenu = {
            panDirections: ko.utils.unwrapObservable(valueAccessor()),
            applyBinding: allBindings()["applySidemenu"],
            applyBinding2: allBindings()["applyClickSidemenu"],
            startX: 0,
            startY: 0,
            startLeftPosition: 0,
            startTime: null,
            leftMenuOpened: false,
            rightMenuOpened: false,
            onXScrollEnabled: true
        };

        self.panDirections.registerObserver(() => {
            SideMenuBindingHandler.moveToCenter(element, self);
        });

        $(element).on("click", self.applyBinding2,
            (event: any) => {
                event.preventDefault();
                if (!self.leftMenuOpened && !self.rightMenuOpened && self.panDirections.LeftMenuEnabled()){
                    var transition = $(window).width()-50;
                    SideMenuBindingHandler.translate(element, transition);
                    self.leftMenuOpened = true;
                    self.onXScrollEnabled = true;
                    $(element).parent().find(".left-menu").removeClass("hidden");
                }else if(self.leftMenuOpened){
                    SideMenuBindingHandler.moveToCenter(element, self);
                    self.leftMenuOpened = false;
                    setTimeout(()=>{$(element).parent().find(".left-menu").addClass("hidden")},500);
                    self.onXScrollEnabled = true;
                }
            }
        );

        $(element).on("touchstart", self.applyBinding,
            (event: any) => {
                var ev = event.originalEvent;
                if (self.onXScrollEnabled) {
                    self.startX = event.originalEvent.changedTouches[0].clientX;
                    self.startY = event.originalEvent.changedTouches[0].clientY;
                    self.startLeftPosition = SideMenuBindingHandler.getLeftPosition(element);
                    self.startTime = new Date();
                }
                /*if (self.leftMenuOpened || self.rightMenuOpened) {
                    event.preventDefault();
                }*/
            });
        $(element).on("touchmove", self.applyBinding,
            (event: any) => {
                if (self.onXScrollEnabled) {
                    var dx = SideMenuBindingHandler.deltaX(self.startX, event.originalEvent.changedTouches[0].clientX);
                    var dy = SideMenuBindingHandler.deltaY(self.startY, event.originalEvent.changedTouches[0].clientY);
                    if (SideMenuBindingHandler.shouldScrollOnX(dx) &&
                        !SideMenuBindingHandler.shouldScrollOnY(dy) &&
                        !SideMenuBindingHandler.stopMovement(self.startX, event.originalEvent.changedTouches[0].clientX, self.leftMenuOpened, self.rightMenuOpened, element)) {
                        event.preventDefault();
                        if (SideMenuBindingHandler.toRight(self.startX, event.originalEvent.changedTouches[0].clientX) &&
                            (self.rightMenuOpened || self.panDirections.LeftMenuEnabled()))
                        {
                            $(element).css("transform", "translate(" + (self.startLeftPosition + (event.originalEvent.changedTouches[0].clientX - self.startX)) + "px, 0px)");
                        }
                        if (SideMenuBindingHandler.toLeft(self.startX, event.originalEvent.changedTouches[0].clientX) &&
                            (self.leftMenuOpened || self.panDirections.RightMenuEnabled()))
                        {
                            $(element).css("transform", "translate(" + (self.startLeftPosition + (event.originalEvent.changedTouches[0].clientX - self.startX)) + "px, 0px)");
                        }
                    }
                    if (SideMenuBindingHandler.leftMenuOpen(element)) {
                        $(element).parent().find(".left-menu").removeClass("hidden");
                        $(element).parent().find(".right-menu").addClass("hidden");
                    } else {
                        if (SideMenuBindingHandler.rightMenuOpen(element)) {
                            $(element).parent().find(".right-menu").removeClass("hidden");
                            $(element).parent().find(".left-menu").addClass("hidden");
                        } else {
                            $(element).parent().find(".left-menu").addClass("hidden");
                            $(element).parent().find(".right-menu").addClass("hidden");
                        }
                    }
                }
            });
        $(element).on("touchend", self.applyBinding,
            (event: any) => {
                if (self.onXScrollEnabled) {
                    self.onXScrollEnabled = false;
                    var dy = SideMenuBindingHandler.deltaY(self.startY, event.originalEvent.changedTouches[0].clientY);
                    if (SideMenuBindingHandler.toRight(self.startX, event.originalEvent.changedTouches[0].clientX)) {
                        if (!self.leftMenuOpened && !self.rightMenuOpened && self.panDirections.LeftMenuEnabled()) {
                            if (SideMenuBindingHandler.shouldCompleteToRight(SideMenuBindingHandler.getLeftPosition(element)) ||
                                SideMenuBindingHandler.shouldCompleteForInertia(self.startTime, self.startX, event.originalEvent.changedTouches[0].clientX) &&
                                !SideMenuBindingHandler.shouldScrollOnY(dy)) {
                                var transition = $(window).width()-50;
                                SideMenuBindingHandler.translate(element, transition);
                                self.leftMenuOpened = true;
                                self.onXScrollEnabled = true;
                            } else {
                                SideMenuBindingHandler.moveToCenter(element, self);
                                self.onXScrollEnabled = true;
                            }
                        } else if (self.rightMenuOpened) {
                            if ((SideMenuBindingHandler.shouldCompleteToRight(SideMenuBindingHandler.getRightPosition(element)) ||
                                (SideMenuBindingHandler.shouldCompleteForInertia(self.startTime, self.startX, event.originalEvent.changedTouches[0].clientX) && !SideMenuBindingHandler.shouldScrollOnY(dy)))) {
                                SideMenuBindingHandler.moveToCenter(element, self);
                                self.rightMenuOpened = false;
                                setTimeout(() => {
                                    $(element).parent().find(".right-menu").addClass("hidden");
                                }, 500);
                                self.onXScrollEnabled = true;
                            } else {
                                var transition = $(window).width() - 50;
                                SideMenuBindingHandler.translate(element, -transition);
                                self.onXScrollEnabled = true;
                            }
                        }
                    }
                    if (SideMenuBindingHandler.toLeft(self.startX, event.originalEvent.changedTouches[0].clientX)) {
                        if (!self.rightMenuOpened && !self.leftMenuOpened && self.panDirections.RightMenuEnabled()) {
                            if ((SideMenuBindingHandler.shouldCompleteToLeft(SideMenuBindingHandler.getLeftPosition(element)) ||
                                (SideMenuBindingHandler.shouldCompleteForInertia(self.startTime, self.startX, event.originalEvent.changedTouches[0].clientX) &&
                                    !SideMenuBindingHandler.shouldScrollOnY(dy)))) {
                                var transition = $(window).width() - 50;
                                SideMenuBindingHandler.translate(element, -transition);
                                self.rightMenuOpened = true;
                                self.onXScrollEnabled = true;
                            } else {
                                SideMenuBindingHandler.moveToCenter(element,self);
                                self.onXScrollEnabled = true;
                            }
                        } else if (self.leftMenuOpened) {
                            if ((SideMenuBindingHandler.shouldCompleteForInertia(self.startTime, self.startX, event.originalEvent.changedTouches[0].clientX) && !SideMenuBindingHandler.shouldScrollOnY(dy)) || !SideMenuBindingHandler.shouldCompleteToLeft(SideMenuBindingHandler.getLeftPosition(element))) {
                                SideMenuBindingHandler.moveToCenter(element, self);
                                self.leftMenuOpened = false;
                                setTimeout(()=> {
                                    $(element).parent().find(".left-menu").addClass("hidden")
                                }, 500);
                                self.onXScrollEnabled = true;
                            } else {
                                var transition = $(window).width() - 50;
                                SideMenuBindingHandler.translate(element, transition);
                                self.onXScrollEnabled = true;
                            }
                        }
                    }
                    self.onXScrollEnabled = true;
                }
            });
    }

    private static getLeftPosition(element: HTMLElement): number {
        if (!isNaN(parseInt($(element).css("transform").split(",")[4]))) {
            return parseInt($(element).css("transform").split(",")[4]);
        }
        return parseInt($(element).css("left"));
    }

    private static getRightPosition(element: HTMLElement): number {
        return -SideMenuBindingHandler.getLeftPosition(element);
    }

    private static shouldCompleteToRight(position: number): boolean {
        return position > ($(window).width() / 4);
    }

    private static shouldCompleteToLeft(position: number): boolean {
        return position < -($(window).width() / 4);
    }

    private static noMenuOpen(element: HTMLElement): boolean {
        return SideMenuBindingHandler.getLeftPosition(element) == 0;
    }

    private static leftMenuOpen(element: HTMLElement): boolean {
        return SideMenuBindingHandler.getLeftPosition(element) > 0;
    }

    private static rightMenuOpen(element: HTMLElement): boolean {
        return SideMenuBindingHandler.getLeftPosition(element) < 0;
    }

    private static toRight(prevX: number, nextX: number): boolean {
        return nextX > prevX;
    }

    private static toLeft(prevX: number, nextX: number): boolean {
        return nextX < prevX;
    }

    private static shouldCompleteForInertia(startTime: Date, startXTouch: number, endXTouch: number): boolean {
        var endTime = new Date();
        var deltaTime = endTime.getMilliseconds() - startTime.getMilliseconds();
        var deltaX = SideMenuBindingHandler.deltaX(startXTouch, endXTouch);
        return (deltaX / deltaTime) > 0.2;
    }

    private static deltaX(startXTouch: number, endXTouch: number): number {
        return Math.abs(endXTouch - startXTouch);
    }

    private static deltaY(startYTouch: number, endYTouch: number): number {
        return Math.abs(endYTouch - startYTouch);
    }

    private static shouldScrollOnX(dx: number): boolean {
        return dx > 1;
    }

    private static shouldScrollOnY(dy: number): boolean {
        return dy > 30;
    }

    private static stopMovement(prevX: number, nextX: number, leftMenuOpened: boolean, rightMenuOpened: boolean, element: HTMLElement): boolean {
        if (SideMenuBindingHandler.toRight(prevX, nextX) && (leftMenuOpened || SideMenuBindingHandler.getLeftPosition(element) >= $(window).width() - 50)) {
            return true;
        } else if (SideMenuBindingHandler.toLeft(prevX, nextX) && (rightMenuOpened || SideMenuBindingHandler.getRightPosition(element) >= $(window).width() - 50))
            return true;
        return false;
        //return false;
    }

    public static moveAutomaticToCenter() {
        $(".middle-menu").css("transition", "0.5s");
        $(".middle-menu").css("transform", "translate(0px,0px)");
        setTimeout(() => {
            $(".middle-menu").css("transition", "");
            $(".middle-menu").parent().find(".left-menu").addClass("hidden");
        }, 500);
    }

    public static moveToCenter(element: HTMLElement, self: any) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(0px,0px)");
        setTimeout(() => {
            $(element).css("transition", "");
            $(element).parent().find(".left-menu").addClass("hidden");
            self.leftMenuOpened = false;
            self.rightMenuOpened = false;
        }, 500);
    }

    private static translate(element: HTMLElement, x: number) {
        $(element).css("transition", "0.5s");
        $(element).css("transform", "translate(" + x + "px, 0px)");
        setTimeout(() => {
            $(element).css("transition", "");
        }, 500);
    }
}

ko.bindingHandlers["sideMenu"] = new SideMenuBindingHandler();