import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ILogFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";

export class CustomEntitiesProviderForControls implements IControlsCustomEntitiesProvider {
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(
        private providersWithDescriptions: IHintSearchGroupInfo[],
        private supportFreeText: boolean,
        private showType: boolean,
        private defaultTextLabel: string = ProlifeSdk.TextResources.Desktop.TextAndDate
    ) {}

    public findEntities(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            let searchFilter: string = query.term;
            const callback: any = query.callback;

            searchFilter = (searchFilter || "").trim();

            const data = { results: [] };

            const providersDef: Promise<any>[] = [];

            this.providersWithDescriptions.forEach((pws: IHintSearchGroupInfo) => {
                const def = pws.Provider.hintSearch(searchFilter).then((results: any[]) => {
                    if (results.length == 0) return;

                    const menuGroup = {
                        text: pws.Description,
                        children: [],
                    };

                    results.forEach((e) => {
                        menuGroup.children.push(this.FormatProviderItem(e, pws));
                    });

                    data.results.push(menuGroup);
                });
                providersDef.push(def);
            });

            Promise.all(providersDef).then(() => {
                //Voce Tutto (ricerca il contenuto in tutti i campi)
                if (this.supportFreeText) {
                    const getForAllFilter: ILogFilter = {
                        IsSearchForAllFilter: true,
                        HintSearch: searchFilter,
                    };

                    data.results.splice(0, 0, {
                        text: this.defaultTextLabel,
                        children: [
                            {
                                id: "$$HINTWORD$$§$$HINTWORD$$§" + searchFilter,
                                text: searchFilter,
                            },
                        ],
                    });
                }

                callback(data);
            });
        }, 500);
    }

    public findEntity(element, callback) {
        const values = <string>$(element).val();
        if (values !== "") {
            const result: any[] = [];
            const items: string[] = values.split("|");
            const defs: Promise<any>[] = [];
            const providersWithItems: any[] = [];
            items.forEach((stringItem: string) => {
                const parts = this.getItemParts(stringItem);

                if (parts.Type == "$$HINTWORD$$" && parts.GroupCode == "$$HINTWORD$$") {
                    result.push(this.FormatHintWordItem(parts.Id as string));
                    return;
                }

                const groupInfo: IHintSearchGroupInfo = this.providersWithDescriptions.filter(
                    (pwd: IHintSearchGroupInfo) => {
                        return pwd.GroupCode == parts.GroupCode;
                    }
                )[0];
                defs.push(
                    groupInfo.Provider.getEntity(parts.Id).then((item) => {
                        providersWithItems.push({ Item: item, GroupInfo: groupInfo });
                    })
                );
            });

            Promise.all(defs).then(() => {
                providersWithItems.forEach((p) => {
                    result.push(this.FormatProviderItem(p.Item, p.GroupInfo));
                });
                callback(result);
            });
        }
    }

    public formatItemsForControlValueField(itemsWithTypes: IItemIdWithTypeAndGroupCode[]): string {
        return itemsWithTypes
            .map((i: IItemIdWithTypeAndGroupCode) => {
                return i.Type + "§" + i.GroupCode + "§" + i.Id.toString();
            })
            .join("|");
    }

    public extractItemsAndTypesFromStringValue(stringValue: string): IItemIdWithTypeAndGroupCode[] {
        return stringValue
            .split("|")
            .filter((s: string) => {
                return s && s.trim().length > 0;
            })
            .map((s: string) => {
                const parts = this.getItemParts(s);
                return <IItemIdWithTypeAndGroupCode>{
                    GroupCode: parts.GroupCode,
                    Type: parts.Type,
                    Id: parts.Id,
                };
            });
    }

    private FormatHintWordItem(word: string) {
        return {
            id: "$$HINTWORD$$§$$HINTWORD$$§" + word,
            text: word,
        };
    }

    private FormatProviderItem(item: any, groupInfo: IHintSearchGroupInfo) {
        return {
            id: groupInfo.Provider.getType() + "§" + groupInfo.GroupCode + "§" + groupInfo.Provider.getPkValue(item),
            text: (this.showType ? groupInfo.Description + ":   " : "") + groupInfo.Provider.getDisplayName(item),
        };
    }

    private getItemParts(stringItem: string): EntitiesSearchProviderItemParts {
        const parts = stringItem.split("§");

        const type = parts[0];
        const groupCode = parts[1];
        const id: string | number = type == "$$HINTWORD$$" ? parts[2] : parseInt(parts[2]);

        return {
            Type: type,
            GroupCode: groupCode,
            Id: id,
        };
    }
}

interface EntitiesSearchProviderItemParts {
    Type: string;
    GroupCode: string;
    Id: string | number;
}
