import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IPlanningService, IAvailableWorkPerPeriod, IPlannedHours, ITheoreticalPlannedHours } from "../../../ProlifeSdk/interfaces/planning/IPlanningService";

interface IGraphInfo
{
    name? : string;
    data : any[];
    step?: string;
    type? : string;
    color? : string;
    pointStart?: number;
    pointInterval?: number;
    width?: number;
    style?: any;
    states?: any;
}

export class PlannerGraph {
    private planningService : IPlanningService;
    public graph : ko.Computed<Highcharts.Options>;
    public AvailableWorkPerPeriod : ko.ObservableArray<IAvailableWorkPerPeriod> = ko.observableArray();
    public PlannedWork : ko.ObservableArray<IPlannedHours> = ko.observableArray();
    public TheoreticalPlannedWork : ko.ObservableArray<ITheoreticalPlannedHours> = ko.observableArray();

    private viewingRangeInterceptor: ko.Computed<void>;
    private AllocationSerie: ko.ObservableArray<any> = ko.observableArray();
    public OrganizationalUnitId : ko.Observable<number> = ko.observable();
    public OrganizationalUnitName : ko.Observable<string> = ko.observable();
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    public MaxEndDate : ko.Observable<Date> = ko.observable();


    constructor(private serviceLocator : IServiceLocator, private StartDate : ko.Observable<Date>, private EndDate : ko.Observable<Date>, private ViewingStartDate : ko.Observable<Date>, private ViewingEndDate : ko.Observable<Date>) {
        this.planningService = <IPlanningService> this.serviceLocator.findService(ProlifeSdk.PlanningServiceType);
        let maxDate = 0;

        this.graph = ko.computed(() => {
            let series = {};
            let OUs = [];
            let plannedWorkSeries = {};
            let theoreticalWorkSeries = {};

            this.AvailableWorkPerPeriod().forEach((availableWork : IAvailableWorkPerPeriod) => {
                if(!series[availableWork.OperationalUnitId]) {
                    series[availableWork.OperationalUnitId] = [];
                    OUs.push({
                        id: availableWork.OperationalUnitId,
                        name: availableWork.OperationalUnitName
                    });
                }

                let date = moment(availableWork.StartDate).valueOf();
                maxDate = Math.max(maxDate, date);

                series[availableWork.OperationalUnitId].push([
                    <any>date,
                    <any>availableWork.MeanWorkADay
                ]);
            });

            let config = [];
            OUs.forEach(ou => {
                let date = moment(this.EndDate.peek()).valueOf();
                maxDate = Math.max(maxDate, date);

                series[ou.id].push([
                    <any>date,
                    <any>series[ou.id][series[ou.id].length - 1][1]
                ]);

                config.push({
                    name: String.format(ProlifeSdk.TextResources.Planning.AvailableWorkPerDay, ou.name),
                    data: series[ou.id],
                    step: 'left'
                });
            });

            if(this.PlannedWork().length > 0) {
                let lastDateWithValue = 0;

                this.PlannedWork().forEach((w : IPlannedHours) => {
                    if(!plannedWorkSeries[w.FkOperationalUnit]) {
                        plannedWorkSeries[w.FkOperationalUnit] = [];
                    }
                    let serie = plannedWorkSeries[w.FkOperationalUnit];

                    if(lastDateWithValue == 0)
                        lastDateWithValue = w.Date.valueOf();

                    if(w.Date.valueOf() - lastDateWithValue > 86400000) {
                        for(let tempDate = lastDateWithValue + 86400000; tempDate < w.Date.valueOf(); tempDate += 86400000) {
                            maxDate = Math.max(maxDate, tempDate);

                            serie.push([
                                <any>tempDate,
                                <any>0
                            ]);
                        }
                    }

                    lastDateWithValue = w.Date.valueOf();

                    maxDate = Math.max(maxDate, lastDateWithValue);

                    serie.push([
                        <any>lastDateWithValue,
                        <any>w.HoursAmount
                    ]);
                });
            }

            if(this.TheoreticalPlannedWork().length > 0) {
                this.TheoreticalPlannedWork().forEach((w : IPlannedHours) => {
                    if(!theoreticalWorkSeries[w.FkOperationalUnit]) {
                        theoreticalWorkSeries[w.FkOperationalUnit] = [];
                    }
                    let plannedSerie = plannedWorkSeries[w.FkOperationalUnit];
                    let theoreticalSerie = theoreticalWorkSeries[w.FkOperationalUnit];
                    let availableSerie = series[w.FkOperationalUnit];
                    if(availableSerie.length > 0) {
                        availableSerie.forEach(available => {
                            let currentDay = available[0];
                            let plannedForDay = (plannedSerie || []).filter(w => w[0] == currentDay);
                            let planned = plannedForDay.length > 0 ? plannedForDay[0][1] : 0;

                            let diff = Math.min(available[1] - planned, w.HoursAmount);
                            w.HoursAmount -= diff;

                            maxDate = Math.max(maxDate, currentDay);

                            theoreticalSerie.push([
                                <any>currentDay,
                                <any>diff
                            ]);

                        });
                    }
                });
            }

            OUs.forEach(ou => {
                if(!theoreticalWorkSeries[ou.id])
                    return;

                config.push({
                    name: "Allocazione Teorica",
                    data: theoreticalWorkSeries[ou.id],
                    step: 'left',
                    type: 'area',
                    color: '#F8B76E'
                });
            })

            OUs.forEach(ou => {
                if(!plannedWorkSeries[ou.id])
                    return;

                config.push({
                    name: "Allocazione",
                    data: plannedWorkSeries[ou.id],
                    step: 'left',
                    type: 'area',
                    color: '#A5F095'
                });
            });

            config.push({
                type: 'flags',
                data: [{
                    x: moment().valueOf(),
                    title: ProlifeSdk.TextResources.Planning.Today,
                    text: ProlifeSdk.TextResources.Planning.Today
                }],
                width: 32,
                style: { // text style
                    color: 'black'
                },
                states: {
                    hover: {
                        fillColor: '#95CEFF'
                    }
                }
            });

            this.MaxEndDate(new Date(maxDate));

            return this.createGraphConfiguration('ffffff', config);
        });

        /*var dateInterceptor = ko.computed(() => {
            this.planningService.getAvailableWorkPerPeriod(this.StartDate(), this.EndDate())
                .then((availableWork : IAvailableWorkPerPeriod[]) => {
                    this.AvailableWorkPerPeriod(availableWork);
                });
        });*/
    }

    public setAllocationSerie(allocationSerie : any[]) {
        this.AllocationSerie(allocationSerie);
    }

    public init(element : HTMLElement, highChart : Highcharts.ChartObject)
    {
        if(this.viewingRangeInterceptor) {
            this.viewingRangeInterceptor.dispose();
        }

        this.viewingRangeInterceptor = ko.computed(() => {
            if(highChart && highChart.xAxis) {
                if(!this.ViewingStartDate() || !this.ViewingEndDate())
                    return;
                highChart.xAxis[0].setExtremes(this.ViewingStartDate().valueOf(), this.ViewingEndDate().valueOf(), true, false);
            }
        });
    }

    private createGraphConfiguration(color : string, data: IGraphInfo[], yAxis? : Highcharts.AxisOptions[]): Highcharts.Options
    {
        let config : any = {
            credits:{
                enabled: false
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            legend : {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            tooltip: {
                animation: true,
                valueDecimals: 2,
                valueSuffix: ' h/g',
                xDateFormat: '%d/%m/%Y'
            },
            plotOptions: {
                line: {
                    getExtremesFromAll: true
                },
                area : {
                    stacking: 'normal',
                    lineWidth: 1
                }
            },
            series: data,
            xAxis: <any>{
                //visible: true,
                type: "linear",
                ordinal: false
                /*labels: {
                 formatter: function() {
                 return moment(<any>this.value).format("DD MMM")
                 }
                 }*/
            }/*,
             yAxis: {
             visible: true,
             title: {
             text: null
             },
             maxPadding: 0.1
             }*/
        };

        if(yAxis) {
            config.yAxis = yAxis;
        }

        return config;
    }
}