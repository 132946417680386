import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/03/2018
 * Time: 11:26
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CustomersProvider } from "../providers/CustomersProvider";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IEventsService, ICustomerEnvelope } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IEntityProvider } from "../../../../ProlifeSdk/interfaces/IEntityProvider";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../Core/Deferred";

export class AgendasEventsCustomersEntityProvider implements IEntityProvider<number, ICustomerEnvelope|ICustomer> {

    private customerService : ICustomersService;
    private eventsService: IEventsService;

    constructor(private serviceLocator : IServiceLocator ) {

        var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        entityProviderService.registerEntityProvider(this, this.getType());

        this.customerService = <ICustomersService> serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
    }

    getType() : string
    {
        return ProlifeSdk.AgendasEventsCustomersEntityType;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Customers.AddressBook;
    }

    getPkValue(item : ICustomerEnvelope) : number
    {
        return item.Id;
    }

    getDisplayName(item : ICustomerEnvelope) : string
    {
        return item.NameOrBusinessName + "&nbsp;-&nbsp;CF: " + (!item.CFOrPIVA ? "N/D" : item.CFOrPIVA) + "&nbsp;-&nbsp;Indirizzo: " + (!item.Address ? "N/D" : item.Address);
    }

    getDisplayNameForKeyValue(pkValue : number) : Promise<string>
    {
        var def = new Deferred<string>();
        this.customerService.getCustomer(pkValue)
            .then((customer : ICustomer) => {
                def.resolve(customer.FormattedContactName);
            })
            .catch(() => { def.reject(); });
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<ICustomerEnvelope[]>
    {
        return this.eventsService.GetCustomersForEvent(stringFilter);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new CustomersProvider();
    }

    getEntity(pkValue : number) : Promise<ICustomer>
    {
        return this.customerService.getCustomerById(pkValue);
    }
}