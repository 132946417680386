import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/08/2017
 * Time: 09:30
 * To change this template use File | Settings | File Templates.
 */

import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { PeopleDetailsDialog } from "./Survey/ui/peoples/dialogs/PeopleDetailsDialog";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IService } from "../Core/interfaces/IService";
import { IPeopleService, IPeople, IPeopleMetadata, IMetadataTypeType, IEditPeopleResult, IPeopleSample, IPeopleCallForHistory, IPeopleSearchFilter, IPeopleForList } from "../ProlifeSdk/interfaces/survey/IPeopleService";

class PeopleService extends ProLifeService implements IPeopleService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IPeopleService>(), this);

        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }
    
    getServiceType() : string
    {
        return ProlifeSdk.PeopleServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getPeopleDetails(peoleId: number): Promise<IPeople> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetPeopleDetails", {
            methodData: {
                PeopleId: peoleId
            }
        });
    }

    getPeopleMetadata(peoleId: number): Promise<IPeopleMetadata[]> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetPeopleMetadata", {
            methodData: {
                PeopleId: peoleId
            }
        });
    }

    getMetadataTypes(): Promise<IMetadataTypeType[]> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetMetadataTypes", {});
    }

    addPeople(peopleData: IPeople, peopleMetadata: IPeopleMetadata[]): Promise<IEditPeopleResult> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "AddPeople", {
            methodData: {
                PeopleData: peopleData,
                PeopleMetadata: peopleMetadata
            }
        });
    }

    updatePeople(peopleData: IPeople, peopleMetadata: IPeopleMetadata[]): Promise<IEditPeopleResult> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "UpdatePeople", {
            methodData: {
                PeopleData: peopleData,
                PeopleMetadata: peopleMetadata
            }
        });
    }

    getPeopleSamples(peopleId: number): Promise<IPeopleSample[]> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetPeopleSamples", {
            methodData: {
                PeopleId: peopleId
            }
        });
    }

    getPeopleHistory(peopleId: number): Promise<IPeopleCallForHistory[]> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetPeopleHistory", {
            methodData: {
                PeopleId: peopleId
            }
        });
    }

    getPeoplesForImport(filters: IPeopleSearchFilter, skip: number, count: number): Promise<IPeopleForList[]> {
        return this.ajaxService.Post("Survey-api/PeopleApi", "GetPeoplesForImport", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count
            }
        });
    }

    showPeopleDialog(peopleId: number): Promise<IEditPeopleResult> {
        let detailsDialog = new PeopleDetailsDialog(this.serviceLocator, peopleId);
        return this.dialogsService.ShowModal<IEditPeopleResult>(detailsDialog, "fullscreen", null, "survey/templates/people/dialogs", "people-details-dialog");
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return new PeopleService(serviceLocator);
}