import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/04/13
 * Time: 10.54
 * To change this template use File | Settings | File Templates.
 */

function zoomIn(element) {
    (<any>$(element))
        .filter(function() { return this instanceof HTMLElement })
        .transition({ scale: 0, opacity: 0 }, 0)
        .transition({ scale: 1, opacity: 1 }, 250, 'ease');
}

function zoomOut(element) {
    (<any>$(element))
        .filter(function() { return this instanceof HTMLElement })
        .transition({ scale: 0, opacity: 0 }, 250, 'ease')
        .transition({ width: 0 }, 250, 'ease', () => {
            ko.removeNode(element);
        });
}

export class ForEachWithZoom {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        ko.applyBindingsToNode(element, { foreach: { data: valueAccessor(), afterAdd: zoomIn, beforeRemove: zoomOut } }, viewModel);

        return {
            controlsDescendantBindings: true
        }
    }
}

ko.bindingHandlers["forEachWithZoom"] = new ForEachWithZoom();

interface IForEachAsyncLoadOptions {
    data: any[] | ko.ObservableArray<any>;
    as?: string;
    loadingTemplate: () => Node[];
}

interface IForEachAyncLoadItem {
    needsToBeRendered: boolean;
    nodes: Node[];
    value: any;
}

function cloneTemplate(template: Node[]) : Node[] {
    let newTemplate = [];
    for(let node of template) {
        newTemplate.push(node.cloneNode(true));
    }
    return newTemplate;
};

function insertNodesAtIndex(element : Element, nodes: Node[], index: number) {
    for(let node of nodes) {
        let actualChildren = ko.virtualElements.childNodes(element);
        let itemAtPosition = actualChildren[index];
        if(!itemAtPosition) {
            ko.virtualElements.insertAfter(element, node, actualChildren.length == 0 ? null : actualChildren[actualChildren.length-1]);
        }
        else {
            ko.virtualElements.insertAfter(element, node, itemAtPosition.previousSibling);
        }
        index++;
    }
}

function indexOfNode(element: Node, node : Node): number {
    let index = 0; 
    while(node.previousSibling != null && node.previousSibling != element) {
        node = node.previousSibling;
        index++;
    }
    return index;
}

function scheduleForEachUpdate(element: HTMLElement, items : IForEachAyncLoadItem[], bindingContext: ko.BindingContext, options: IForEachAsyncLoadOptions, template: Node[]) {
    let scheduledUpdate = $(element).data("ForEachAsyncLoad");
    if(scheduledUpdate)
        return; // Update già schedulato

    scheduledUpdate = setTimeout(() => {
        $(element).data("ForEachAsyncLoad", 0);

        let itemToRender = items.firstOrDefault(i => i.needsToBeRendered);
        if(!itemToRender)
            return;

        let t = cloneTemplate(template);
        let minIndex = Infinity;
        for(let existingNode of itemToRender.nodes) {
            let index = indexOfNode(element, existingNode);
            if(index < minIndex)
                minIndex = index;
        }
        $(itemToRender.nodes).remove();

        let newContext = bindingContext.createChildContext(itemToRender.value, options.as);
        let frag = document.createElement("div");
        for(let node of t) {
            frag.append(node);
        }
        ko.applyBindingsToDescendants(newContext, frag);

        insertNodesAtIndex(element, t, minIndex);

        itemToRender.needsToBeRendered = false;
        itemToRender.nodes = t;

        scheduleForEachUpdate(element, items, bindingContext, options, template);
    }, 0);

    $(element).data("ForEachAsyncLoad", scheduledUpdate);
}
export class ForEachAsyncLoad {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        let items : IForEachAyncLoadItem[] = [];
        let options = ko.unwrap(valueAccessor()) as IForEachAsyncLoadOptions;

        if(ko.isObservable(options.data)) {
            let sub = options.data.subscribe((changes) => {
               
                for(let change of changes) {
                    if(change.status === "added") {
                        if(change.moved !== undefined) {
                            let itemToMove = items[change.moved];
                            $(itemToMove.nodes).remove();
                            let newDestination = $(element).children()[change.index];
                            $(itemToMove.nodes).insertBefore(newDestination);
                        } else {
                            let newNodes = options.loadingTemplate();
                            insertNodesAtIndex(element, newNodes, change.index * newNodes.length);

                            items.splice(change.index, 0, {
                                needsToBeRendered: true,
                                nodes: newNodes,
                                value: change.value
                            });
                        }
                    } else if(change.status === "deleted") {
                        let itemToRemove = items[change.index];
                        for(let node of itemToRemove.nodes) {
                            ko.cleanNode(node);
                            $(node).remove();
                        }
                        items.splice(change.index, 1);
                    } else if(change.status === "retained") {
                    }

                    scheduleForEachUpdate(element, items, bindingContext, options, template);
                }

            }, {}, "arrayChange");

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                sub.dispose();
            });
        }

        let template = cloneTemplate(ko.virtualElements.childNodes(element));
        ko.virtualElements.emptyNode(element);

        for(let item of ko.unwrap(options.data)) {
            let newNode = options.loadingTemplate();

            for(let node of newNode) {
                let actualChildren = ko.virtualElements.childNodes(element);
                ko.virtualElements.insertAfter(element, node, actualChildren.length == 0 ? null : actualChildren[actualChildren.length-1]);
            }

            items.push({
                needsToBeRendered: true,
                nodes: newNode,
                value: item
            });
        }

        scheduleForEachUpdate(element, items, bindingContext, options, template);

        return {
            controlsDescendantBindings: true
        }
    }
}

ko.bindingHandlers["forEachEx"] = new ForEachAsyncLoad();
ko.virtualElements.allowedBindings["forEachEx"] = true;