import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

const { classes } = jss.createStyleSheet({
    "btn-back": {
        border: '2px solid rgb(102, 102, 102)',
        background: 'none',
        padding: '6px 9px',

        "&:hover": {
            borderColor: "black"
        },

        "& > i.fa": {
            fontSize: '16px'
        }
    }
}).attach();

type BackButtonProps = {
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
    icon?: string;
}

export class BackButton {
    static defaultProps = {
        icon: "fa fa-arrow-left",
        style: {}
    }

    constructor(private props : BackButtonProps) {
        
    }
    
    render() {
        const classNames = ComponentUtils.classNames("btn", "btn-circle", "btn-icon-only", classes["btn-back"], this.props.className);
        const style = {
            display: 'inline-block',
            ...this.props.style
        }

        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onClick();
        }

        return  <button className={classNames} onClick={handleClick} style={style}>
                    <i class={this.props.icon}></i>
                </button>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(BackButton);
}