import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/08/2017
 * Time: 16:46
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionnaireCategoriesControlsEntityProvider } from "./QuestionnaireCategoriesControlsEntityProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEntityProvider } from "../../../../../ProlifeSdk/interfaces/IEntityProvider";
import { IQuestionnaireCategorySettingManager } from "../../../../interfaces/IQuestionnaireCategorySettingManager";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ICategory } from "../../../../interfaces/ICategory";
import { Deferred } from "../../../../../Core/Deferred";

export class QuestionnaireCategoriesEntityProvider implements IEntityProvider<number, ICategory> {
    private categories: ICategory[];

    private categoryManager: IQuestionnaireCategorySettingManager;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.categoryManager = <IQuestionnaireCategorySettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireCategory);

        this.categories = this.categoryManager.getQuestionnaireCategories();
    }

    getType(): string {
        return ProlifeSdk.QuestionnaireCategory;
    }

    getEntityTypeName(): string {
        return ProlifeSdk.TextResources.Survey.QuestionnaireCategory;
    }

    getPkValue(item: ICategory): number {
        return item.Id;
    }

    getDisplayName(item: ICategory): string {
        return item.Description;
    }

    getDisplayNameForKeyValue(pkValue: number): Promise<string> {
        var def = new Deferred<string>();
        var categories: ICategory[] = this.categories.filter((s: ICategory) => { s.Id == pkValue });
        def.resolve(categories[0].Description);
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<ICategory[]> {
        var def = new Deferred<ICategory[]>();
        var categories: ICategory[] = this.categories.filter((s: ICategory) => {
            return s.Description.toUpperCase().indexOf(stringFilter.trim().toUpperCase()) > -1;
        });
        def.resolve(categories);
        return def.promise();
    }

    getEntity(pkValue: number): Promise<ICategory> {
        var def = new Deferred<ICategory>();
        var category: ICategory = this.categories.filter((s: ICategory) => {
            return s.Id == pkValue;
        })[0];
        def.resolve(category);
        return def.promise();
    }

    getControlsProvider(): IControlsEntityProvider {
        return new QuestionnaireCategoriesControlsEntityProvider(this.serviceLocator);
    }
}