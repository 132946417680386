import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 14:59
 * To change this template use File | Settings | File Templates.
 */


import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ISampleState, ISampleLogicalState } from "../../../../../../ProlifeSdk/interfaces/survey/ISampleState";
import { ISampleStateSettingManager } from "../../../../../interfaces/ISampleStateSettingManager";

export interface ISampleStateProvider {
    getData() : ISampleState;
    update(sampleState : ISampleState) : void;
    withError: ko.Observable<boolean>;
}

export class SampleStateEditingViewModel {
    title : string;
    elements : ko.ObservableArray<ISampleStateProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);

    public LogicalStates: ko.ObservableArray<ISampleLogicalState> = ko.observableArray([]);
    public NewState: ko.Observable<string> = ko.observable();

    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private sampleStateManager: ISampleStateSettingManager) {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.title = sampleStateManager.getLabel();

        this.LogicalStates(this.sampleStateManager.getSampleLogicalState());

        this.reloadElements();
    }

    private reloadElements() {
        this.elements([]);
        this.sampleStateManager.load();
        var sampleStates = this.sampleStateManager.getSampleStates();
        sampleStates.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(sampleState: ISampleState) {
        this.elements.push(new SampleStateViewModel(this, sampleState, this.sampleStateManager.getSampleLogicalState()));
    }

    public addNewSetting() {
        if (!this.NewState())
            return;
        var sampleStateViewModel = new SampleStateViewModel(this, {
            Id: null,
            Description: this.NewState(),
            LogicalStateId: this.sampleStateManager.getSampleStates()[0].Id,
            Locked: this.sampleStateManager.getSampleStates()[0].Locked
        }, this.sampleStateManager.getSampleLogicalState());
        this.elements.push(sampleStateViewModel);
        this.createOrUpdateEntry(sampleStateViewModel);

        this.NewState("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element: ISampleStateProvider) {
        var sampleState = element.getData();
        this.sampleStateManager.addOrUpdate(sampleState)
            .then((updatedSampleState) => element.update(updatedSampleState));
    }

    public deleteEntry(element: ISampleStateProvider) {
        var sampleState = element.getData();
        this.sampleStateManager.remove(sampleState.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.toastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            });
    }
}


class SampleStateViewModel implements ISampleStateProvider {
    Id: ko.Observable<number> = ko.observable();
    Label : ko.Observable<string> = ko.observable();
    LogicalState : ko.Observable<number> = ko.observable();
    Locked: ko.Observable<boolean> = ko.observable();
    SelectedLogicalState : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    public withError: ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    constructor(private container : SampleStateEditingViewModel, private sampleState : ISampleState, public sampleLogicalState : ISampleLogicalState[]) {
        this.Id(sampleState.Id);
        this.Label(sampleState.Description);
        this.LogicalState(sampleState.LogicalStateId);
        this.Locked(sampleState.Locked);

        this.setInitialState();

        this.Label.subscribe(this.onNameChanged.bind(this));
        this.LogicalState.subscribe(this.onDataChanged.bind(this));
    }

    private setInitialState(): void {
        this.SelectedLogicalState(this.sampleLogicalState.filter((s: ISampleLogicalState) => {
            return s.Id == this.LogicalState();
        })[0].Description);

    }

    public SetLogicalState(state: ISampleLogicalState): void {
        this.LogicalState(state.Id);
        this.SelectedLogicalState(state.Description);
    }

    private onNameChanged(newValue : string) {
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    getData() : ISampleState {
        var sampleState: ISampleState = <ISampleState> $.extend({}, this.sampleState);
        sampleState.Id = this.Id();
        sampleState.Description = this.Label();
        sampleState.LogicalStateId = this.LogicalState();
        return sampleState;
    }

    private onDataChanged(newValue : string) {
        if(this.updating) return;
        this.container.createOrUpdateEntry(this);
    }

    update(sampleState: ISampleState) : void {
        this.updating = true;
        this.sampleState = sampleState;
        this.Id(sampleState.Id);
        this.Label(sampleState.Description);
        this.LogicalState(sampleState.LogicalStateId);

        this.updating = false;
    }
}