import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../Core/DependencyInjection";
import {
    INavigationMenuComponentDataSource,
    INavigationMenuComponentModel,
    INavigationMenuComponent,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceModel, IDataSourceView } from "./IDataSource";
import {
    IAllocationsService,
    IManualAllocationsNumberPerDay,
} from "../ProlifeSdk/interfaces/allocations/IAllocationsService";

export interface IManualAllocationsDatesDataSource extends INavigationMenuComponentDataSource {
    setTeamId(teamId: number): void;
    setCartId(cartId: number): void;
}

export interface IMonthNavigationMenuComponentModel extends INavigationMenuComponentModel {
    isMonth: true;
    year: number;
    month: number;

    manualAllocationsNumber: number;
}

export interface IYearNavigationMenuComponentModel extends INavigationMenuComponentModel {
    isYear: true;
    year: number;

    manualAllocationsNumber: number;
}

export interface IDayNavigationMenuComponentModel extends INavigationMenuComponentModel {
    isDay: true;
    year: number;
    month: number;
    day: number;

    date: Date;
    dayNumber: string;
    isToday: boolean;

    manualAllocationsNumber: number;
}

export class ManualAllocationsDateDataSource implements IManualAllocationsDatesDataSource {
    public ManualAllocationsTotalNumber: ko.Observable<number> = ko.observable(0);

    private navigator: INavigationMenuComponent;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService!: IAllocationsService;

    private hasPendingSelect = false;
    private requestedMonth: Date;
    private requestedDay: Date;

    constructor(private teamId: number, private cartId: number) {}

    public setNavigator(navigator: INavigationMenuComponent): void {
        this.navigator = navigator;

        if (this.hasPendingSelect && this.requestedMonth != null) {
            this.selectMonth(this.requestedMonth);
        }

        if (this.hasPendingSelect && this.requestedDay != null) {
            this.selectDay(this.requestedDay);
        }
    }

    public setTeamId(teamId: number): void {
        this.teamId = teamId;
    }

    public setCartId(cartId: number): void {
        this.cartId = cartId;
    }

    public getTitle(currentModel: IDataSourceModel): string {
        if (currentModel === null) return ProlifeSdk.TextResources.WorkedHours.DateDataSourceTitle;

        const yearModel = currentModel as IYearNavigationMenuComponentModel;
        if (yearModel.isYear) return yearModel.year.toString();

        const monthModel = currentModel as IMonthNavigationMenuComponentModel;
        if (monthModel.isMonth) return monthModel.title;

        return "";
    }

    public isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    public setView(view: IDataSourceView): void {
        return;
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        if (!a || !b) return false;

        const aAsMonth = a as IMonthNavigationMenuComponentModel;
        const bAsMonth = b as IMonthNavigationMenuComponentModel;

        if (aAsMonth.isMonth && bAsMonth.isMonth) {
            return aAsMonth.month == bAsMonth.month && aAsMonth.year == bAsMonth.year;
        }

        const aAsYear = a as IYearNavigationMenuComponentModel;
        const bAsYear = b as IYearNavigationMenuComponentModel;

        if (aAsYear.isYear && bAsYear.isYear) {
            return aAsYear.year == bAsYear.year;
        }

        const aAsDay = a as IDayNavigationMenuComponentModel;
        const bAsDay = b as IDayNavigationMenuComponentModel;

        if (aAsDay.isDay && bAsDay.isDay) {
            return aAsDay.day == bAsDay.day && aAsDay.month == bAsDay.month && aAsDay.year == bAsDay.year;
        }

        return false;
    }

    public getData(
        currentModel: IDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel[]> {
        if (skip > 0) return new Promise((resolve) => resolve([]));

        return new Promise((resolve, reject) => {
            this.allocationsService
                .getManualAllocationsNumberPerDays(this.teamId, this.cartId)
                .then((allocationsNumbers: IManualAllocationsNumberPerDay[]) => {
                    this.ManualAllocationsTotalNumber(
                        allocationsNumbers
                            .map((a) => a.ManualAllocationsNumber)
                            .reduce((accumulator: number, currentValue) => accumulator + currentValue, 0)
                    );

                    if (currentModel == null) {
                        this.getYearsData(allocationsNumbers).then((years) => resolve(years));
                        return;
                    }

                    const yearModel = currentModel as IYearNavigationMenuComponentModel;
                    if (yearModel.isYear) {
                        this.getMonthsData(allocationsNumbers, yearModel.year).then((months) => resolve(months));
                        return;
                    }

                    const monthModel = currentModel as IMonthNavigationMenuComponentModel;
                    if (monthModel.isMonth) {
                        this.getDaysData(allocationsNumbers, monthModel.year, monthModel.month).then((days) =>
                            resolve(days)
                        );
                        return;
                    }

                    throw new Error("DateDataSource: Unsupported Model Type");
                })
                .catch(() => reject());
        });
    }

    public getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        return new Promise((resolve, reject) => {
            this.allocationsService
                .getManualAllocationsNumberPerDays(this.teamId, this.cartId)
                .then((allocationsNumbers: IManualAllocationsNumberPerDay[]) => {
                    this.ManualAllocationsTotalNumber(
                        allocationsNumbers
                            .map((a) => a.ManualAllocationsNumber)
                            .reduce((accumulator: number, currentValue) => accumulator + currentValue, 0)
                    );

                    if (currentModel == null) {
                        this.getYearsByIds(allocationsNumbers, ids).then((years) => resolve(years));
                        return;
                    }

                    const yearModel = currentModel as IYearNavigationMenuComponentModel;
                    if (yearModel.isYear) {
                        this.getMonthsByIds(allocationsNumbers, yearModel.year, ids).then((months) => resolve(months));
                        return;
                    }

                    const monthModel = currentModel as IMonthNavigationMenuComponentModel;
                    if (monthModel.isMonth) {
                        this.getDaysDataByIds(allocationsNumbers, monthModel.year, monthModel.month, ids).then((days) =>
                            resolve(days)
                        );
                        return;
                    }

                    throw new Error("DateDataSource: Unsupported Model Type");
                })
                .catch(() => reject());
        });
    }

    public selectFirstAvailableDay() {
        this.allocationsService
            .getManualAllocationsNumberPerDays(this.teamId, this.cartId)
            .then((allocations: IManualAllocationsNumberPerDay[]) => {
                if (!allocations || allocations.length === 0) return;

                this.selectDay(allocations[0].Date);
            });
    }

    public async selectMonth(startDate: Date): Promise<void> {
        if (!this.navigator) {
            this.requestedMonth = startDate;
            this.hasPendingSelect = true;
            return;
        }

        const year = startDate.getFullYear();
        const month = startDate.getMonth();

        const yearModel = this.createYear(year);
        const monthModel = this.createMonth(year, month);

        this.navigator.navigateTo(yearModel);
        await this.navigator.select(monthModel);

        this.hasPendingSelect = false;
    }

    public async selectDay(startDate: Date): Promise<void> {
        if (!this.navigator) {
            this.requestedDay = startDate;
            this.hasPendingSelect = true;
            return;
        }

        const year = startDate.getFullYear();
        const month = startDate.getMonth();
        const day = startDate.getDate();

        const yearModel = this.createYear(year);
        const monthModel = this.createMonth(year, month);

        const fakeAllocation: IManualAllocationsNumberPerDay = {
            ManualAllocationsNumber: 0,
            TeamId: null,
            Date: moment().date(day).year(year).month(month).toDate(),
        };

        const dayModel = this.createDay(fakeAllocation, month, year);

        this.navigator.navigateTo(yearModel, monthModel);
        await this.navigator.select(dayModel);

        this.hasPendingSelect = false;
    }

    private getDaysDataByIds(
        allocationsNumbers: IManualAllocationsNumberPerDay[],
        year: number,
        month: number,
        ids: number[]
    ): Promise<IDataSourceModel[]> {
        return new Promise((resolve, reject) => {
            this.getDaysData(allocationsNumbers, year, month).then((days: IDayNavigationMenuComponentModel[]) => {
                resolve(days.filter((d) => ids.indexOf(d.id as number) > -1));
            });
        });
    }

    private getMonthsByIds(
        allocationsNumbers: IManualAllocationsNumberPerDay[],
        year: number,
        ids: number[]
    ): Promise<IDataSourceModel[]> {
        return new Promise((resolve, reject) => {
            this.getMonthsData(allocationsNumbers, year).then((months: IMonthNavigationMenuComponentModel[]) => {
                resolve(months.filter((m) => ids.indexOf(m.id as number) > -1));
            });
        });
    }

    private getYearsByIds(
        allocationsNumbers: IManualAllocationsNumberPerDay[],
        ids: number[]
    ): Promise<IDataSourceModel[]> {
        return new Promise((resolve, reject) => {
            this.getYearsData(allocationsNumbers).then((years: IYearNavigationMenuComponentModel[]) => {
                resolve(years.filter((y) => ids.indexOf(y.id as number) > -1));
            });
        });
    }

    private getYearsData(
        allocationsNumbers: IManualAllocationsNumberPerDay[]
    ): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            const years: INavigationMenuComponentModel[] = [];
            const yearsMap: { [key: number]: IYearNavigationMenuComponentModel } = {};

            allocationsNumbers.forEach((a) => {
                const year = moment(a.Date).year();

                if (!yearsMap[year]) {
                    yearsMap[year] = this.createYear(year);
                    yearsMap[year].manualAllocationsNumber += 1;
                    yearsMap[year].badge[0].text = yearsMap[year].manualAllocationsNumber.toString();
                    years.push(yearsMap[year]);
                } else {
                    yearsMap[year].manualAllocationsNumber += 1;
                    yearsMap[year].badge[0].text = yearsMap[year].manualAllocationsNumber.toString();
                }
            });

            resolve(years);
        });
    }

    private getMonthsData(
        allocationsNumbers: IManualAllocationsNumberPerDay[],
        year: number
    ): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            const months: IMonthNavigationMenuComponentModel[] = [];
            const monthsMap: { [key: number]: IMonthNavigationMenuComponentModel } = {};

            allocationsNumbers.forEach((a) => {
                const month = moment(a.Date).month();

                if (!monthsMap[month]) {
                    monthsMap[month] = this.createMonth(year, month);
                    monthsMap[month].manualAllocationsNumber += 1;
                    monthsMap[month].badge[0].text = monthsMap[month].manualAllocationsNumber.toString();
                    months.push(monthsMap[month]);
                } else {
                    monthsMap[month].manualAllocationsNumber += 1;
                    monthsMap[month].badge[0].text = monthsMap[month].manualAllocationsNumber.toString();
                }
            });

            resolve(months);
        });
    }

    async getDaysData(
        allocationsNumbers: IManualAllocationsNumberPerDay[],
        year: number,
        month: number
    ): Promise<INavigationMenuComponentModel[]> {
        const daysModels: IDayNavigationMenuComponentModel[] = [];
        allocationsNumbers
            .filter((a) => a.Date.getFullYear() === year && a.Date.getMonth() === month)
            .forEach((a: IManualAllocationsNumberPerDay) => {
                daysModels.push(this.createDay(a, month, year));
            });

        return daysModels;
    }

    private createYear(year: number): IYearNavigationMenuComponentModel {
        return {
            id: year,
            isYear: true,
            year: year,
            isGroup: false,
            isLeaf: false,
            title: year.toString(),
            manualAllocationsNumber: 0,
            badge: [
                {
                    text: "",
                    cssClass: "badge-success",
                },
            ],
        };
    }

    private createMonth(year: number, month: number): IMonthNavigationMenuComponentModel {
        return {
            id: month,
            isMonth: true,
            year: year,
            month: month,
            isGroup: false,
            isLeaf: false,
            title: String.capitalize(moment().year(year).month(month).date(1).format("MMMM YYYY")),
            manualAllocationsNumber: 0,
            badge: [
                {
                    text: "",
                    cssClass: "badge-success",
                },
            ],
        };
    }

    private createDay(
        allocation: IManualAllocationsNumberPerDay,
        month: number,
        year: number
    ): IDayNavigationMenuComponentModel {
        const calendarDate = moment(allocation.Date);
        const today = moment();
        const day = calendarDate.date();

        const model: IDayNavigationMenuComponentModel = {
            id: day,
            isDay: true,
            isGroup: false,
            isLeaf: true,
            title: calendarDate.format("DD") + " " + calendarDate.format("dddd"),

            year: year,
            month: month,
            day: day,

            date: calendarDate.toDate(),
            dayNumber: moment(calendarDate).format("DD"),
            isToday: today.year() == year && today.month() == month && today.date() == day,

            badge: [
                {
                    text: allocation.ManualAllocationsNumber.toString(),
                    cssClass: "badge-success",
                },
            ],

            manualAllocationsNumber: allocation.ManualAllocationsNumber,
        };

        return model;
    }
}
