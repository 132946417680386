import * as ko from "knockout";
import { DocumentRefDetailsViewModelFactory } from "../../../../ProlifeSdk/prolifesdk/documents/DocumentRefDetailsViewModelFactory";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IReferenceDetailsViewModelFactory } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";

export class SupplierOrderRefDetailsViewModelFactory extends DocumentRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.Warehouse.SupplierOrders;
    }
}

