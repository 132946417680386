import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { ApplicationsViewModel } from "./ApplicationsViewModel";
import { LazyImport } from "../../Core/DependencyInjection";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { IService } from "../../Core/interfaces/IService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import {
    IApplicationsService,
    IApplicationsServiceObserver,
    IApplicationStarter,
} from "../interfaces/IApplicationsService";
import { IApplicationsStarterObserver, IApplicationBase } from "../interfaces/IApplication";
import { ISettingsServiceObserver, ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IApplicationHost } from "../interfaces/IApplicationHost";
import { IApplicationConfiguration } from "../interfaces/IApplicationConfiguration";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ProLifeApplication } from "./ProLifeApplication";

export class ApplicationsService
    implements IApplicationsService, IApplicationsStarterObserver, ISettingsServiceObserver
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IApplicationHost>())
    private applicationHost: IApplicationHost;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    public templateName = "applications";
    public templateUrl = "desktop/templates";
    public templateData: ApplicationsViewModel;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    private observers: IApplicationsServiceObserver[] = [];

    private AuthorizationsAreLoaded: ko.Observable<boolean> = ko.observable(false);
    private SettingsAreLoaded: ko.Observable<boolean> = ko.observable(false);
    private GlobalSettingsLoaded: ko.Observable<boolean> = ko.observable(false);
    private IsOpaServiceStarted = false;

    private installedApplications: IApplicationStarter[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IApplicationsService>(), this);
    }

    public async InitializeService() {
        this.templateData = new ApplicationsViewModel(this.serviceLocator);
        this.templateData.installedApplications(this.installedApplications);

        this.AuthorizationsAreLoaded.subscribe(this.startOPAService.bind(this));
        this.SettingsAreLoaded.subscribe(this.startOPAService.bind(this));
        this.GlobalSettingsLoaded.subscribe(this.startOPAService.bind(this));

        const settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.addObserver(this);

        await this.templateData.Initialize();
    }

    public getFirstGroupApplications(): IApplicationStarter[] {
        return <IApplicationStarter[]>this.templateData.firstGroupApplications();
    }

    public getSecondGroupApplications(): IApplicationStarter[] {
        return <IApplicationStarter[]>this.templateData.secondGroupApplications();
    }

    public getThirdGroupApplications(): IApplicationStarter[] {
        return <IApplicationStarter[]>this.templateData.thirdGroupApplications();
    }

    startOPAService() {
        if (
            this.IsOpaServiceStarted ||
            !this.AuthorizationsAreLoaded() ||
            !this.SettingsAreLoaded() ||
            !this.GlobalSettingsLoaded()
        )
            return;

        this.registerRoutes();

        const opaService = <IOPAService>this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Run("#/");

        this.IsOpaServiceStarted = true;
    }

    registerObserver(observer: IApplicationsServiceObserver) {
        this.observers.push(observer);
    }

    OnAuthorizationsLoaded() {
        const matchesWithoutAuths = this.templateData.installedApplications().filter((as: IApplicationStarter) => {
            return !as.areAuthorizationsLoaded;
        });

        //Avvio Sammy solo quando per tutte le app registrate sono state caricate le autorizzazioni
        if (matchesWithoutAuths.length == 0) {
            this.AuthorizationsAreLoaded(true);
            this.templateData.VerifyMobileAppEnabled();
        }
    }

    refreshApplicationsConfigurations() {
        this.ajaxService
            .Get("Desktop-api", "ApplicationsConfigurations/GetAllConfigurations", {})
            .then((configurations: IApplicationConfiguration[]) => {
                this.observers.forEach((o: IApplicationsServiceObserver) => {
                    o.onConfigurationsLoaded(configurations);
                });
                this.SettingsAreLoaded(true);
            });
    }

    getApplicationCode() {
        return "Desktop";
    }

    getName(): string {
        return ProlifeSdk.TextResources.Desktop.Desktop;
    }

    getIcon(): string {
        return "";
    }

    registerRoutes(): void {
        const opaService = <IOPAService>this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/", (context) => {
            const apps = this.templateData.installedApplications().filter((app: IApplicationStarter) => {
                return app.visible();
            });
            this.closeMenu();
            if (!this.desktopService.isMobileBrowser()) {
                //Mostro il desktop solo se esistono almeno 2 applicazioni altrimenti entro direttamente sull'applicazione
                if (apps.length > 1) {
                    this.startDesktop();
                } else {
                    apps[0].start();
                }
            } else {
                this.startDesktop();
            }
        });
    }

    private startDesktop() {
        this.startApplication(this);
        this.templateData.refresh();
    }

    getApplicationRoute(): string {
        return "#/";
    }

    getServiceType(): string {
        return ProlifeSdk.ApplicationsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    registerApplication(application: IApplicationBase): void {
        const app = new ProLifeApplication(this.serviceLocator, application);
        app.addObserver(this);

        if (this.templateData) this.templateData.installedApplications.push(app);
        else this.installedApplications.push(app);
    }

    startApplication(application: IApplicationBase): void {
        document.title = (application ? application.getName() + " - " : "") + ProlifeSdk.TextResources.Desktop.ProLife;
        this.applicationHost.startApplication(application);
    }

    getCurrentApplicationCode(): string {
        return this.applicationHost.getCurrentApplicationCode();
    }

    onGoingDown(): void {}

    onSettingsLoaded(): void {
        this.GlobalSettingsLoaded(true);
    }

    onSettingsUpdated(updateType: string): void {}

    SetSideMenuEnabled(leftMenuEnabled: boolean, rightMenuEnabled: boolean): void {
        this.applicationHost.SetSideMenuEnabled(leftMenuEnabled, rightMenuEnabled);
    }

    public closeMenu() {
        this.applicationHost.closeMenu();
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ApplicationsService(serviceLocator);
}
