import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { GroupedEntitiesProvider } from "../../../ProlifeSdk/prolifesdk/providers/GroupedEntitiesProvider";
import { AdvancedSearchFilter } from "./AdvancedEventsFilters";
import * as moment from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IEventsService, IAgendaEventDescriptor, IAgendasEventsSearchFilters, IEventCategory, IEventCanceledMotivation } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IEventAgendaAndStartEndInfo } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISearchResultsProvider } from "../../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { IAgendaEventDescriptorViewModel, IEventsSearchObserver, IEventAdvancedFilter } from "../../interfaces/IAgendaEvent";
import { ILogTagFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { ICategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { IEventCanceledMotivationsSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/IEventCanceledMotivationsSettingsManager";

export class EventsSearchViewModel implements ISearchResultsProvider {
    public SearchResultsProvider: GroupedEntitiesProvider;
    public SearchFilters: ko.Observable<string> = ko.observable("");
    public SearchResults: ko.ObservableArray<IAgendaEventDescriptorViewModel> = ko.observableArray([]);
    public SelectedEventsHistory: ko.ObservableArray<IAgendaEventDescriptorViewModel> = ko.observableArray([]);

    public IsSearching: ko.Observable<boolean> = ko.observable(false);

    public CanConfirmEvents: ko.Observable<boolean> = ko.observable(false);
    public CanAbortEvents: ko.Observable<boolean> = ko.observable(false);
    public CanPutEventsOnWaitingList: ko.Observable<boolean> = ko.observable(false);

    public ConfirmButtonEnabled: ko.Computed<boolean>;
    public AbortButtonEnabled: ko.Computed<boolean>;
    public PutOnWaitingListButtonEnabled: ko.Computed<boolean>;

    public AdvancedFilterActive: ko.Computed<boolean>;
    public CrossAgendasEnabled: ko.Observable<boolean> = ko.observable(false);
    public ShowAbortedEventsEnabled: ko.Observable<boolean> = ko.observable(false);
    public SelectedResults: ko.Computed<IAgendaEventDescriptorViewModel[]>;

    public LockFilters: boolean = false;

    private AdvancedSearchFilter: AdvancedSearchFilter;

    private eventsService: IEventsService;
    private infoToastService: IInfoToastService;
    private authorizationsService: IAuthorizationService;

    private isLoading: boolean = false;

    constructor(private serviceLocator: IServiceLocator, private searchObserver: IEventsSearchObserver) {
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.authorizationsService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);

        var entitiesCodesList = [ProlifeSdk.AgendasEventsCustomersEntityType, ProlifeSdk.AgendaEventCategoryEntityType, ProlifeSdk.AgendaEntityType];

        this.SearchResultsProvider = new GroupedEntitiesProvider(this, ProlifeSdk.TextResources.ProlifeSdk.TextsAndDates, entitiesCodesList);
        this.AdvancedSearchFilter = new AdvancedSearchFilter(this.serviceLocator);

        this.SearchFilters.subscribe((filtersString: string) => {
            this.search();
        });

        this.CrossAgendasEnabled.subscribe((value: boolean) => {
            if (this.IsSearching()) {
                this.search();
            }
        });

        this.ShowAbortedEventsEnabled.subscribe((value: boolean) => {
            if (this.IsSearching())
                this.search();
        });

        this.AdvancedFilterActive = ko.computed(() => {
            return this.AdvancedSearchFilter.IsActive();
        });

        this.SelectedResults = ko.computed(() => {
            return this.SearchResults().filter((r: IAgendaEventDescriptorViewModel) => { return r.Selected(); });
        });

        var CrossAgendasFilterInterceptor = ko.computed(() => {
            if (this.CrossAgendasEnabled() && this.IsSearching()) {
                this.searchObserver.UnselectAgendaForFilter();
            }
        });

        this.CanConfirmEvents(this.authorizationsService.isAuthorized("Agenda_ConfirmEvents"));
        this.CanAbortEvents(this.authorizationsService.isAuthorized("Agenda_AbortEvents"));
        this.CanPutEventsOnWaitingList(this.authorizationsService.isAuthorized("Agenda_PutEventsOnWaitingList"));

        this.ConfirmButtonEnabled = ko.computed(() => {
            return this.SelectedResults().length > 0;
        });

        this.AbortButtonEnabled = ko.computed(() => {
            return this.SelectedResults().length > 0;
        });

        this.PutOnWaitingListButtonEnabled = ko.computed(() => {
            return this.SelectedResults().length > 0;
        });
    }

    public SelectEventsBetweenLastTwoSelectionsInTheHistory(): void {
        if (this.SelectedEventsHistory().length <= 1)
            return;

        var lastSelectedEventId: number = this.SelectedEventsHistory()[this.SelectedEventsHistory().length - 1].Id;
        var secondLastSelectedEventId: number = this.SelectedEventsHistory()[this.SelectedEventsHistory().length - 2].Id;

        var lastSelectedEventIndex: number = 0;
        for (var i = 0; i < this.SearchResults().length; i++) {
            if (this.SearchResults()[i].Id == lastSelectedEventId) {
                lastSelectedEventIndex = i;
                break;
            }
        }

        var secondLastSelectedEventIndex: number = 0;
        for (var i = 0; i < this.SearchResults().length; i++) {
            if (this.SearchResults()[i].Id == secondLastSelectedEventId) {
                secondLastSelectedEventIndex = i;
                break;
            }
        }

        var startIndex: number = lastSelectedEventIndex > secondLastSelectedEventIndex ? secondLastSelectedEventIndex : lastSelectedEventIndex;
        var endIndex: number = lastSelectedEventIndex < secondLastSelectedEventIndex ? secondLastSelectedEventIndex : lastSelectedEventIndex;

        for (var i = startIndex; i <= endIndex; i++)
            this.SearchResults()[i].Selected(true);
    }

    public SelectAll(): void {
        this.SearchResults().forEach((r: IAgendaEventDescriptorViewModel) => r.Selected(true));
    }

    public DeselectAll(): void {
        this.SearchResults().forEach((r: IAgendaEventDescriptorViewModel) => r.Selected(false));
    }

    public ConfirmEvents(): void {
        var eventsIds: number[] = this.SelectedResults().map((r: IAgendaEventDescriptorViewModel) => { return r.Id; });

        var stateId: number = this.GetEventStateByLogicalState(ProlifeSdk.ConfirmedEventState);

        this.eventsService.SetEventsState(eventsIds, stateId, null)
            .then((rejectedEvents: IEventAgendaAndStartEndInfo[]) => {
                this.search();

                if (!!this.searchObserver.SelectedAgenda()) {
                    this.searchObserver.SelectedAgenda().RefreshCalendar();
                    this.searchObserver.SelectedAgenda().RefreshWaitingList();
                }

                if (rejectedEvents.length > 0) {
                    var message: string = "<br/><br/>";
                    rejectedEvents.forEach((e: IEventAgendaAndStartEndInfo) => {
                        message += e.EventTitle + "<br/>";
                    });
                    this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Agenda.EventsWithInvalidStartEndMessage, message));
                }
            })
            .catch(() => {

            });
    }

    public PutEventsOnWaitingList(): void {
        var eventsIds: number[] = this.SelectedResults().map((r: IAgendaEventDescriptorViewModel) => { return r.Id; });

        var stateId: number = this.GetEventStateByLogicalState(ProlifeSdk.WaitingListEventState);
        var motivationId: number = this.GetCanceledMotivationId();

        this.eventsService.SetEventsState(eventsIds, stateId, motivationId)
            .then(() => {
                this.search();

                if (!!this.searchObserver.SelectedAgenda()) {
                    this.searchObserver.SelectedAgenda().RefreshCalendar();
                    this.searchObserver.SelectedAgenda().RefreshWaitingList();
                }
            })
            .catch(() => {

            });
    }

    public AbortEvents(): void {
        var eventsIds: number[] = this.SelectedResults().map((r: IAgendaEventDescriptorViewModel) => { return r.Id; });

        var stateId: number = this.GetEventStateByLogicalState(ProlifeSdk.AbortedEventState);

        this.eventsService.SetEventsState(eventsIds, stateId)
            .then((rejectedEvents: IEventAgendaAndStartEndInfo[]) => {
                this.search();

                if (!!this.searchObserver.SelectedAgenda()) {
                    this.searchObserver.SelectedAgenda().RefreshCalendar();
                    this.searchObserver.SelectedAgenda().RefreshWaitingList();
                }
            })
            .catch(() => {

            });
    }

    public ShowAdvancedFiltersDialog(): void {
        this.AdvancedSearchFilter.ShowDialog()
            .then((filters: IEventAdvancedFilter) => {
                if (!filters)
                    return;
                this.search();
            });
    }

    public DoSearch(): void {
        this.search();
    }

    public findSearchMatches(query: any) {}

    public findSearchMatch(element, callback) {}

    public search(): void {
        if (this.isLoading)
            return;
        this.isLoading = true;
        this.IsSearching(true);

        var filters: any[] = [];
        this.pushSearchFieldFilters(filters);
        this.pushAdvancedFilters(filters);

        this.eventsService.HintSearch(filters, 0, 100)
            .then((events: IAgendaEventDescriptor[]) => {
                this.SearchResults(events.map((e: IAgendaEventDescriptor) => this.CreateEventDescriptorViewModel(e)));
                setTimeout(() => { this.isLoading = false; }, 200);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.EventsHintSearchError);
                this.isLoading = false;
            });
    }

    public LoadNextBlock(): void {
        if (this.isLoading)
            return;

        this.isLoading = true;

        var filters: any[] = [];
        this.pushSearchFieldFilters(filters);
        this.pushAdvancedFilters(filters);

        this.eventsService.HintSearch(filters, this.SearchResults().length, 100)
            .then((events: IAgendaEventDescriptor[]) => {
                this.SearchResults(this.SearchResults().concat(events.map((e: IAgendaEventDescriptor) => this.CreateEventDescriptorViewModel(e))));
                setTimeout(() => { this.isLoading = false; }, 200);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.EventsHintSearchError);
                this.isLoading = false;
            });
    }

    private pushSearchFieldFilters(filters: IAgendasEventsSearchFilters[]): void {
        var allFilters: any[] = JSON.parse("[" + (this.SearchFilters() || "").replace(/\|/g,",") + "]");
        allFilters.forEach((f: IAgendasEventsSearchFilters) => { filters.push(f); });
    }

    private pushAdvancedFilters(filters: IAgendasEventsSearchFilters[]): void {
        var Tags: ILogTagFilter[] = [];

        var advFilter = this.AdvancedSearchFilter.GetAdvancedFilters();

        if (!advFilter)
            return;

        filters.push({
            From: advFilter.From ? moment(advFilter.From).startOf("day").toDate() : null,
            To: advFilter.To ? moment(advFilter.To).endOf("day").toDate() : null,
            Tags: Tags,
            CrossAgendas: this.CrossAgendasEnabled(),
            ShowAbortedEvents: this.ShowAbortedEventsEnabled(),
            AgendaId: this.searchObserver.SelectedAgenda() ? <number> this.searchObserver.SelectedAgenda().AgendaId() : null
        });
    }

    private CreateEventDescriptorViewModel(event: IAgendaEventDescriptor): IAgendaEventDescriptorViewModel {
        return new EventDescriptorViewModel(this.serviceLocator, event, this, this.searchObserver);
    }

    private GetEventStateByLogicalState(logicalState: number): number {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var eventStatesManager: ICategoriesSettingsManager = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);

        return eventStatesManager.getCategories().filter((c: IEventCategory) => c.LogicalState == logicalState)[0].Id;
    }

    private GetCanceledMotivationId(): number {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var motivationsManager: IEventCanceledMotivationsSettingsManager = <IEventCanceledMotivationsSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventCanceledMotivationsSettingsManager);

        var motivations: IEventCanceledMotivation[] = motivationsManager.getMotivations();

        if (motivations.length == 0)
            return null;

        return motivations[0].Id;
    }
}

class EventDescriptorViewModel implements IAgendaEventDescriptorViewModel {
    public Id: number;
    public Selected: ko.Observable<boolean> = ko.observable();

    public EventJson: string = "";


    private lockSelection: boolean = false;
    private eventsService: IEventsService;

    constructor(private serviceLocator: IServiceLocator, public eventDescriptor: IAgendaEventDescriptor, private searchViewModel: EventsSearchViewModel, private searchObserver: IEventsSearchObserver) {
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.EventJson = JSON.stringify(this.eventDescriptor);

        this.Id = this.eventDescriptor.id;
        this.Selected(false);
    }

    public Select(descriptor: IAgendaEventDescriptorViewModel, mouseEvent: any): void {
        if (this.lockSelection)
            return;

        if (!mouseEvent.shiftKey) {
            this.Selected(!this.Selected());
            if (this.Selected())
                this.searchViewModel.SelectedEventsHistory.push(this);
            else {
                var index: number = this.searchViewModel.SelectedEventsHistory().indexOf(this);
                this.searchViewModel.SelectedEventsHistory.splice(index, 1);
            }
        }
        else {
            this.Selected(true);
            this.searchViewModel.SelectedEventsHistory.push(this);
            this.searchViewModel.SelectEventsBetweenLastTwoSelectionsInTheHistory();
        }
    }

    public EditEvent(): void {
        this.searchObserver.EditSearchedEvent(this.eventDescriptor);
    }

    public NavigateTo(): void {
        this.searchObserver.NavigateToEvent(this.eventDescriptor);
        this.searchViewModel.IsSearching(false);
    }

    public OnDragStart(): void {
        this.lockSelection = true;
        if (!this.searchObserver.SelectedAgenda())
            return;
        this.searchObserver.SelectedAgenda().ShowEventCanceledMotivationsList(true);
    }

    public OnDragStop(): void {
        this.lockSelection = false;
        if (!this.searchObserver.SelectedAgenda())
            return;
        this.searchObserver.SelectedAgenda().ShowEventCanceledMotivationsList(false);
    }
}