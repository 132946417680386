import * as ko from "knockout";
import { DocumentRowElement } from "./DocumentRowElement";
import { ReferencesMapViewer } from "./ReferencesMapViewer";
import { NodeSubElement } from "./NodeSubElement";
import { INodeDetails, INodeRow } from "../../../interfaces/invoice/IReferencesMap";

export class NodeDetails
{
    templateName : string =  "reference-map-document";
    templateUrl : string = "prolifesdk/templates/documents/referencemap";

    public Rows : ko.ObservableArray<NodeSubElement> = ko.observableArray([]);

    constructor(public node : INodeDetails, private map : ReferencesMapViewer)
    {
        this.Rows(node.Rows.map((r : INodeRow) => { return new DocumentRowElement(r, this);}));
    }

    UnselectAll()
    {
        this.Rows().forEach((r : NodeSubElement) => {r.IsSelected(false); });
    }

    OnSelectionChanged(selected : NodeSubElement)
    {
        this.Rows().forEach((r : NodeSubElement) => {
            r.IsSelected(r == selected);
        });

        this.map.OnSubSelectionChanged(this, selected.EntityId, selected.EntityType);
    }

    SelectRelatedRows(entityId : number, entityType : string)
    {
        this.Rows().forEach((r : NodeSubElement) => {
            r.IsSelected(r.IsSourceFor(entityId, entityType, this.map.References()) ||
                r.IsDestinationFor(entityId, entityType, this.map.References()));
        });
    }
}