/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 24/08/2016
 * Time: 15:15
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { EntityLockManager } from "../../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntityLockManager";
import { JobOrderViewModel } from "../JobOrderViewModel";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import {
    IJobOrderEditorPanelFactory,
    IJobOrderEditor,
    IJobOrderEditorPanel,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import {
    IJobOrderService,
    IJobOrderSectionsDefaultSettings,
} from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IEntitiesLockService } from "../../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { IJobOrderBlockMotivation } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderBlockMotivation";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { Deferred } from "../../../../../Core/Deferred";
import { Right } from "../../../../../Core/Authorizations";

export class JobOrderGeneralInfoFactory implements IJobOrderEditorPanelFactory {
    @Right("JobOrders_ViewGeneralInfo")
    private canView: boolean;

    constructor(public Color: string) {}

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderGeneralInfo(serviceLocator, editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return this.canView;
    }
}

class JobOrderGeneralInfo implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.GeneralInfo);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "general-info";

    IsEditing: ko.Observable<boolean> = ko.observable(false);

    public IsDeleted: ko.Computed<boolean>;
    public IsJobOrderLocked: ko.Computed<boolean>;
    public CanLockOrUnlockJobOrder: ko.Observable<boolean> = ko.observable(false);

    public CanStartWarehouse: ko.Observable<boolean> = ko.observable(false);
    public IsWarehouseModuleEnabled: ko.Observable<boolean> = ko.observable(false);

    public LockManager: EntityLockManager;

    public jobOrder: ko.Observable<JobOrderViewModel> = ko.observable();

    public ResponsiblesOpened: ko.Observable<boolean> = ko.observable(false);
    public WarehousesOpened: ko.Observable<boolean> = ko.observable(false);
    public UserCharactersOpened: ko.Observable<boolean> = ko.observable(false);
    public CallRightsPricesOpened: ko.Observable<boolean> = ko.observable(false);
    public VatRegistersOpened: ko.Observable<boolean> = ko.observable(false);
    public ActivitiesProgressOpened: ko.Observable<boolean> = ko.observable(false);

    public GeneralDataScroller: ko.Observable<boolean> = ko.observable(false);
    public ResponsiblesScroller: ko.Observable<boolean> = ko.observable(false);
    public WarehousesScroller: ko.Observable<boolean> = ko.observable(false);
    public UserCharactersScroller: ko.Observable<boolean> = ko.observable(false);
    public CallRightsPricesScroller: ko.Observable<boolean> = ko.observable(false);
    public VatRegistersScroller: ko.Observable<boolean> = ko.observable(false);
    public ActivitiesProgressScroller: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;
    @LazyImport(nameof<IEntitiesLockService>())
    private lockService: IEntitiesLockService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IModulesService>())
    private modulesService: IModulesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    constructor(private serviceLocator: IServiceLocator, private editor: IJobOrderEditor, public Color: string) {
        this.LockManager = new EntityLockManager(serviceLocator);

        this.IsDeleted = ko.computed(() => {
            return this.jobOrder() ? this.jobOrder().jobOrder.Deleted : false;
        });

        this.IsJobOrderLocked = ko.computed(() => {
            return this.jobOrder() ? true && !!this.jobOrder().jobOrder.FkBlockCause : false;
        });

        this.CanLockOrUnlockJobOrder(this.authorizationsService.isAuthorized("JobOrders_CanLockOrUnlockJobOrders"));

        this.IsWarehouseModuleEnabled(this.modulesService.IsModuleEnabled(ProlifeSdk.WarehouseApplicationCode));
        this.CanStartWarehouse(this.authorizationsService.isAuthorized("Warehouse_Start"));
    }

    public canShow(): boolean {
        return true;
    }

    public isDefaultOnNew() {
        return true;
    }

    public isDefault() {
        return true;
    }

    beforeChangePanel(skipPendingChangesCheck = false): Promise<boolean> {
        if (!this.IsEditing()) return Promise.resolve<boolean>(true);

        const deferred = new Deferred<boolean>();
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.JobOrder.ConfirmUnsavedChanges,
            ProlifeSdk.TextResources.JobOrder.ConfirmUnsavedChangesCancel,
            ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgConfirm,
            (result: boolean) => {
                if (result) {
                    this.cancelEditJobOrder(skipPendingChangesCheck);
                }

                deferred.resolve(result);
            }
        );

        return deferred.promise();
    }

    async beforeShowPanel(): Promise<void> {
        await this.load();
        this.loadSectionsSettings();

        if (this.jobOrder()) this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    public dispose() {
        if (this.jobOrder()) this.jobOrder().Dispose();

        this.LockManager.Dispose();
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    OnWorkflowSelectionChanged(selection: number[]) {
        this.load();
    }

    OnWorkflowDeleted(workflowId: number) {}

    OnWorkflowChanged(workflowId: number) {}

    OnWorkflowCreated(workflowId: number) {}

    OnWorkflowImportedFromTemplate(workflowId: number) {}

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    load(): Promise<boolean> {
        this.jobOrder(<JobOrderViewModel>this.editor.JobOrder());
        this.LockManager.SetEntity(ProlifeSdk.JobOrderEntityTypeCode, this.editor.JobOrderId);
        return Promise.resolve<boolean>(true);
    }

    public lockJobOrder() {
        //E' il blocco manuale della comessa da non connfondere con il blocco automatico dovuto alla presa in editing
        const dialog: IDialog = new LockMotivationSelectionDialog(
            this.serviceLocator,
            this.jobOrder().jobOrder.JobOrderId
        );
        this.dialogService
            .ShowModal<boolean>(dialog, null, null, dialog.templateUrl, dialog.templateName)
            .then((confirm: boolean) => {
                if (confirm) this.editor.getDetailsObserver().notifyChanges(this.jobOrder().jobOrder.JobOrderId);
            });
    }

    public unlockJobOrder() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.JobOrder.UnblockOrderMsg,
            ProlifeSdk.TextResources.JobOrder.UnblockOrderMsgCancel,
            ProlifeSdk.TextResources.JobOrder.UnblockOrderMsgconfirm,
            (confirm) => {
                if (!confirm) return;

                this.jobOrderService
                    .SetLockStatusForJobOrders([
                        {
                            JobOrderId: this.jobOrder().jobOrder.JobOrderId,
                            Locked: false,
                        },
                    ])
                    .then(() => {
                        this.editor.getDetailsObserver().notifyChanges(this.jobOrder().jobOrder.JobOrderId);
                    });
            }
        );
    }

    public editJobOrder() {
        if (!this.jobOrder().JobOrderId()) {
            this.IsEditing(true);
            this.jobOrder().SetEditMode(true);
            return;
        }

        //Nel caso di update devo lockare la commessa
        this.lockService.LockEntity(ProlifeSdk.JobOrderEntityTypeCode, this.jobOrder().JobOrderId()).then(() => {
            this.IsEditing(true);
            this.jobOrder().SetEditMode(true);
        });
    }

    public cancelEditJobOrder(skipPendingChangesCheck = false): void {
        if (this.jobOrder().isNew()) {
            this.editor.BackToSplash(true);
            return;
        }

        if (this.jobOrder().JobOrderId()) {
            this.lockService.UnlockEntity(ProlifeSdk.JobOrderEntityTypeCode, this.jobOrder().JobOrderId()).then(() => {
                this.IsEditing(false);
                this.jobOrder().SetEditMode(false);
                this.editor.reload();
            });
        } else this.createNew(skipPendingChangesCheck);
    }

    public createNew(skipPendingChangesCheck = false) {
        /*var jobOrderStateSettingsManager : IJobOrderStateSettingsManager = <IJobOrderStateSettingsManager> this.settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
        var jobOrderTypesSettingsManager : IJobOrderTypesSettingsManager = <IJobOrderTypesSettingsManager> this.settingsService.findSettingsManager(ProlifeSdk.JobOrderType);

        var responsible : IHumanResource = this.humanResourcesSettingsManager.getHumanResourceByUserId((<any>this.authenticationService.getUser()).IdUser);
        var responsibleId = responsible != null ? responsible.Id : 0;

        var jobOrder : IJobOrder = <IJobOrder>{
            CreationDate : new Date(),
            CreationUserId: this.userService.getIdUser(),
            ResponsableUserId : responsibleId,
            StartDate: new Date(),
            EndDate:new Date(),
            RequestEndDate: new Date(),
            TypeId : jobOrderTypesSettingsManager.getJobOrderTypes()[0].Id,
            StateId: jobOrderStateSettingsManager.getJobOrderStates()[0].IdJobOrderStateId,
            CustomerId: 0,
            RolesPrices:[],
            Projects : [],
            ProtocolsDefaultValues : [],
            ProtocolsSettings : []
        };
        this.jobOrder(new JobOrderViewModel.JobOrderViewModel(this.serviceLocator,this.editor.getDetailsObserver(), jobOrder));*/

        this.editor.createNew(skipPendingChangesCheck);
        this.load();
        this.editJobOrder();
    }

    public scrollToGeneralData(): void {
        this.GeneralDataScroller(!this.GeneralDataScroller());
    }

    public scrollToResponsibles(): void {
        this.ResponsiblesOpened(true);
        this.ResponsiblesScroller(!this.ResponsiblesScroller());
    }

    public scrollToWarehouses(): void {
        this.WarehousesOpened(true);
        this.WarehousesScroller(!this.WarehousesScroller());
    }

    public scrollToUserCharacters(): void {
        this.UserCharactersOpened(true);
        this.UserCharactersScroller(!this.UserCharactersScroller());
    }

    public scrollToCallRightsPrices(): void {
        this.CallRightsPricesOpened(true);
        this.CallRightsPricesScroller(!this.CallRightsPricesScroller());
    }

    public scrollToVatRegisters(): void {
        this.VatRegistersOpened(true);
        this.VatRegistersScroller(!this.VatRegistersScroller());
    }

    public scrollToActivitiesProgress(): void {
        this.ActivitiesProgressOpened(true);
        this.ActivitiesProgressScroller(!this.ActivitiesProgressScroller());
    }

    public switchResponsiblesOpenedState(): void {
        this.ResponsiblesOpened(!this.ResponsiblesOpened());
    }

    public switchWarehousesOpenedState(): void {
        this.WarehousesOpened(!this.WarehousesOpened());
    }

    public switchUserCharactersOpenedState(): void {
        this.UserCharactersOpened(!this.UserCharactersOpened());
    }

    public switchCallRightsPricesOpenedState(): void {
        this.CallRightsPricesOpened(!this.CallRightsPricesOpened());
    }

    public switchActivitiesProgressOpenedState(): void {
        this.ActivitiesProgressOpened(!this.ActivitiesProgressOpened());
    }

    public switchVatRegistersOpenedState(): void {
        this.VatRegistersOpened(!this.VatRegistersOpened());
    }

    public async saveSectionsConfig(): Promise<void> {
        const config: IJobOrderSectionsDefaultSettings = {
            WarehousesOpened: this.WarehousesOpened(),
            UserCharactersOpened: this.UserCharactersOpened(),
            CallRightsPricesOpened: this.CallRightsPricesOpened(),
            VatRegistersOpened: this.VatRegistersOpened(),
            ActivitiesProgressOpened: this.ActivitiesProgressOpened(),
        };

        await this.jobOrderService.saveJobOrderSectionsConfiguration(config);
        this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.CartInfoConfigSaved);
    }

    public async loadSectionsSettings(): Promise<void> {
        const config: IJobOrderSectionsDefaultSettings = await this.jobOrderService.getJobOrderSectionsConfiguration();

        this.WarehousesOpened(config.WarehousesOpened);
        this.CallRightsPricesOpened(config.CallRightsPricesOpened);
        this.UserCharactersOpened(config.UserCharactersOpened);
        this.VatRegistersOpened(config.VatRegistersOpened);
        this.ActivitiesProgressOpened(config.ActivitiesProgressOpened);
    }
}

export class LockMotivationSelectionDialog implements IDialog {
    private jobOrderService: IJobOrderService;

    templateName = "block-motivation-dialog";
    templateUrl = "joborder/templates/joborderdetail/general-info";
    title: string = ProlifeSdk.TextResources.JobOrder.LockOrder;
    modal: {
        close: (result?: any) => void;
    };

    public motivations: IJobOrderBlockMotivation[] = [];
    public selectedMotivation: ko.Observable<any> = ko.observable(null);

    constructor(serviceLocator: IServiceLocator, private jobOrderId: number) {
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        const settingsService: ISettingsService = <ISettingsService>(
            serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        const motivationsSettings: IJobOrderBlockMotivationsSettingsManager = <
            IJobOrderBlockMotivationsSettingsManager
        >settingsService.findSettingsManager(ProlifeSdk.JobOrderBlockMotivation);
        this.motivations = motivationsSettings.getMotivations();
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if (!this.selectedMotivation()) return;

        this.jobOrderService
            .SetLockStatusForJobOrders([
                {
                    JobOrderId: this.jobOrderId,
                    Locked: true,
                    BlockCause: this.selectedMotivation(),
                },
            ])
            .then(() => {
                this.modal.close(true);
            });
    }
}
