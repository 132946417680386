import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: n.angeli
 * Date: 19/02/13
 * Time: 15.02
 * To change this template use File | Settings | File Templates.
 */

import { LazyImport } from "../../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IScheduleState } from "../../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IScheduleStateSettingsManager } from "../../../../ProlifeSdk/interfaces/schedule/settings/IScheduleStateSettingsManager";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IScheduleLogicalState } from "../../../../ProlifeSdk/interfaces/schedule/settings/IScheduleLogicalState";

export interface IScheduleStateProvider {
	reload(): void;
	getData() : IScheduleState;
	update(scheduleState : IScheduleState) : void;
}

export class ScheduleStateEditingViewModel {
	title : string;
	elements : ko.ObservableArray<IScheduleStateProvider> = ko.observableArray();
	newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
	newSettingName : ko.Observable<string> = ko.observable();

	@LazyImport(nameof<IInfoToastService>())
	private infoToastService: IInfoToastService;

	constructor(private serviceLocator : IServiceLocator, private scheduleStateManager : IScheduleStateSettingsManager) {
		this.title = scheduleStateManager.getLabel();

		var scheduleState = scheduleStateManager.getScheduleStates();
		scheduleState.forEach(this.createViewModelFor.bind(this));
	}

	private createViewModelFor(scheduleState : IScheduleState) {
		this.elements.push(new ScheduleStateViewModel(this, scheduleState, this.scheduleStateManager.getScheduleLogicalState()));
	}

	public addNewSetting() {
		var scheduleStateViewModel = new ScheduleStateViewModel(this, {
			Descrizione: this.newSettingName(),
			StatoLogico: 0,
			IdScadenzaStato: 0
		}, this.scheduleStateManager.getScheduleLogicalState());
		this.elements.push(scheduleStateViewModel);
		this.createOrUpdateEntry(scheduleStateViewModel);

		this.newSettingName("");
	}

	public setIsSelectedNewSetting() {
		this.newSettingHasFocus(true);
	}

	public createOrUpdateEntry(element : IScheduleStateProvider) {
		var scheduleState = element.getData();
		this.scheduleStateManager.createOrUpdate(scheduleState)
			.then((updatedscheduleState) => element.update(updatedscheduleState))
			.catch(() => this.infoToastService.Error(ProlifeSdk.TextResources.Schedule.ScheduleStateSaveError));
	}

	public deleteEntry(element : IScheduleStateProvider) {
		var scheduleState = element.getData();
		
		this.scheduleStateManager
			.remove(scheduleState.IdScadenzaStato)
			.then(() => this.elements.remove(element))
			.catch(() => element.reload());
	}
}


class ScheduleStateViewModel implements IScheduleStateProvider {
	Descrizione : ko.Observable<string> = ko.observable();
	StatoLogico : ko.Observable<number> = ko.observable();
	StatoLogicoDescrizione : ko.Observable<string> = ko.observable();
	AccountingSoftwareCode: ko.Observable<string> = ko.observable();
	hasFocus : ko.Observable<boolean> = ko.observable(false);

	
	private updating : boolean = false;

	constructor(private container : ScheduleStateEditingViewModel, private scheduleState : IScheduleState, private scheduleLogicalState : IScheduleLogicalState[]) {
		this.Descrizione(scheduleState.Descrizione);
		this.StatoLogico(scheduleState.StatoLogico);
		this.AccountingSoftwareCode(scheduleState.AccountingSoftwareCode);

		this.Descrizione.subscribe(this.onNameChanged.bind(this));
		this.StatoLogico.subscribe(this.onDataChanged.bind(this));
		this.AccountingSoftwareCode.subscribe(this.onDataChanged.bind(this));
	}

	private onNameChanged(newValue : string) {
		if(!newValue) {
			this.container.deleteEntry(this);
		} else {
			this.container.createOrUpdateEntry(this);
		}
	}

	getData() : IScheduleState {
		var scheduleState : IScheduleState = <IScheduleState> $.extend({}, this.scheduleState);
		scheduleState.Descrizione = this.Descrizione();
		scheduleState.StatoLogico = this.StatoLogico();
		scheduleState.AccountingSoftwareCode = this.AccountingSoftwareCode();
		return scheduleState;

	}

	setLogicalState(state : IScheduleLogicalState) : void{
		this.StatoLogico(state.IdScheduleLogicalState);
	}


	private onDataChanged(newValue : string) {
		if(this.updating) return;
		this.container.createOrUpdateEntry(this);
	}

	update(scheduleState : IScheduleState) : void {
		this.updating = true;
		this.scheduleState = scheduleState;
		this.Descrizione(scheduleState.Descrizione);
		this.StatoLogico(scheduleState.StatoLogico);
		this.AccountingSoftwareCode(scheduleState.AccountingSoftwareCode);

		this.updating = false;
	}

	reload(): void {
		this.updating = true;
		
		this.Descrizione(this.scheduleState.Descrizione);
		this.StatoLogico(this.scheduleState.StatoLogico);
		this.AccountingSoftwareCode(this.scheduleState.AccountingSoftwareCode);

		this.updating = false;
	}
}