import * as Core from "../Core";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import jss from "jss";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import { IInfoToastService, InfoToastOptions } from "../interfaces/IInfoToastService";
import { IService } from "../interfaces/IService";
import * as toastr from "toastr";

let notificationSound = require("../../Sounds/notification.ogg");

const background = "#4746464d";
const black = "#000000";
const grey = "#999999";
const lightGrey = "#CCCCCC";
const white = "#FFFFFF";
const nearBlack = "#030303";
const green = "#51A351";
const red = "#BD362F";
const blue = "#2F96B4";
const orange = "#F89406";

const { classes } = jss
    .createStyleSheet({
        "@keyframes slide": {
            "0%": {
                position: "relative",
                right: "-300px",
            },
            "100%": {
                position: "relative",
                right: "0px",
            },
        },
        frostedToasts: {
            "& > div": {
                opacity: "1 !important",
            },

            "& .toast": {
                borderRadius: "15px !important",
                backdropFilter: "blur(8px)",
                backgroundColor: background,
                animation: "$slide 0.25s ease",
                textShadow: "-1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000",
                whiteSpace: "pre-wrap",

                "&:hover": {
                    backdropFilter: "blur(20px)",
                },

                "&.toast-success": {
                    borderLeft: "10px solid " + green,
                },

                "&.toast-error": {
                    borderLeft: "10px solid " + red,
                },

                "&.toast-info": {
                    borderLeft: "10px solid " + blue,
                },

                "&.toast-warning": {
                    borderLeft: "10px solid " + orange,
                },
            },
        },
    })
    .attach();

class InfoToastService implements IInfoToastService {
    private notificationAudio: HTMLAudioElement;
    private notificationAudioPlaying: boolean = false;

    constructor(serviceLocator: IServiceLocator, configuration: InfoToastOptions = Core.configuration.infoToastConfig) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IInfoToastService>(), this);

        this.notificationAudio = new Audio(notificationSound);
        this.notificationAudio.volume = 0.5;
        this.notificationAudio.onended = (ev) => {
            this.notificationAudioPlaying = false;
        };

        toastr.options.extendedTimeOut = 0;
        toastr.options.positionClass = classes.frostedToasts + " toast-top-right";
        toastr.options.progressBar = true;
    }

    getServiceType(): string {
        return ServiceTypes.InfoToast;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    private applyBaseStyles(overrides?: InfoToastOptions): InfoToastOptions {
        if (!overrides) return undefined;

        overrides.positionClass = classes.frostedToasts + " toast-top-right " + (overrides.positionClass || "");
        return overrides;
    }

    private playNotificationSound() {
        if (this.notificationAudioPlaying) return;

        this.notificationAudioPlaying = true;
        this.notificationAudio.play();
    }

    Clear(toast?: JQuery): void {
        toastr.clear(toast);
    }

    Error(message: string, title?: string, overrides?: InfoToastOptions): void {
        overrides = this.applyBaseStyles(overrides);

        toastr.error(message, title, <ToastrOptions>overrides);

        // this.playNotificationSound();
    }

    Info(message: string, title?: string, overrides?: InfoToastOptions): void {
        overrides = this.applyBaseStyles(overrides);

        toastr.info(message, title, <ToastrOptions>overrides);

        // this.playNotificationSound();
    }

    Success(message: string, title?: string, overrides?: InfoToastOptions): void {
        overrides = this.applyBaseStyles(overrides);

        toastr.success(message, title, <ToastrOptions>overrides);

        // this.playNotificationSound();
    }

    Warning(message: string, title?: string, overrides?: InfoToastOptions): void {
        overrides = this.applyBaseStyles(overrides);

        toastr.warning(message, title, <ToastrOptions>overrides);

        // this.playNotificationSound();
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new InfoToastService(serviceLocator);
}
