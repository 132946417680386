import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { DetectClassChanges, DetectChanges } from "../Core/ChangeDetection";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { ILetterOfAttempt, ICustomersService, IGetLettersOfAttemptsRequest, ILetterOfAttemptForEditor } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { IDetectChanges } from "../Core/interfaces/IDetectChanges";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface ILetterOfAttemptDataSourceModel extends IDataSourceModel<number, ILetterOfAttempt> {
    viewModel: LetterOfAttempt;
}

export class LettersOfAttemptsDataSource implements IDataSource {
    private view: IDataSourceView;
    private customerId: number = null;
    private showClosed = false;
    private referenceDate: Date = moment().startOf("day").toDate();
    private onlyValidAtReferenceDate = false;

    private modifiedLetters: LetterOfAttempt[] = [];

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;
    
    constructor() {

    }

    public getTitle(currentModel: ILetterOfAttemptDataSourceModel): string {
        return ProlifeSdk.TextResources.Customers.LettersOfAttempts;
    }
    
    public async getData(currentModel: ILetterOfAttemptDataSourceModel, textFilter: string, skip = 0, count = 100000): Promise<ILetterOfAttemptDataSourceModel[]> {
        const params: IGetLettersOfAttemptsRequest = {
            CustomerId: this.customerId,
            Skip: skip,
            Count: count,
            ShowClosed: this.showClosed,
            ReferenceDate: this.referenceDate,
            OnlyValidAtReferenceDate: this.onlyValidAtReferenceDate
        };

        const letters = await this.customersService.GetLettersOfAttempts(params);
        return this.createModels(letters, skip === 0);
    }
    
    public async getById(currentModel: ILetterOfAttemptDataSourceModel, ids: number[]): Promise<ILetterOfAttemptDataSourceModel[]> {
        const letters = await this.customersService.GetLettersOfAttemptsByIds(ids);
        return this.createModels(letters, false);
    }
    
    public getModifiedData(): LetterOfAttempt[] {
        return this.modifiedLetters;
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setCustomerId(customerId: number): void {
        this.customerId = customerId;
    }

    public setShowClosed(showClosed: boolean): void {
        this.showClosed = showClosed;
    }
    
    public setReferenceDate(date: Date): void {
        this.referenceDate = date;
    }

    public setShowOnlyValidAtReferenceDate(value: boolean): void {
        this.onlyValidAtReferenceDate = value;
    }

    public isGroupedData(currentModel: ILetterOfAttemptDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: ILetterOfAttemptDataSourceModel, b: ILetterOfAttemptDataSourceModel): boolean {
        return a === b || (!!a && !! b && a.id === b.id);
    }

    public createNew(customerId: number): void {
        const model: ILetterOfAttemptForEditor = {
            Id: null,
            CustomerId: customerId,
            StartDate: moment().toDate(),
            DueDate: null,
            Description: null,
            Amount: 0,
            ManuallyClosed: false,
            Note: null,
            UsedAmount: 0,
            Deleted: false
        };

        this.modifiedLetters.push(new LetterOfAttempt(model, this));
        this.view.refresh();
    }

    public addChangedLetterOfAttempt(letterOfAttempt: LetterOfAttempt) {
        if (this.modifiedLetters.indexOf(letterOfAttempt) < 0)
            this.modifiedLetters.push(letterOfAttempt);
    }

    public reset(): void {
        this.modifiedLetters.forEach((m) => m.dispose());
        this.modifiedLetters = [];
        if (this.view)
            this.view.refresh();
    }

    private createModels(letters: ILetterOfAttempt[], prependNewLetters: boolean): ILetterOfAttemptDataSourceModel[] {
        const models: ILetterOfAttemptDataSourceModel[] = letters.map((l) => {
            let viewModelsMatch = this.modifiedLetters.filter((v) => v.Id === l.Id);
            if (viewModelsMatch.length > 0) {
                let viewModel = viewModelsMatch[0];
                return this.createModel(l, viewModel.Deleted(), viewModel);
            }

            return this.createModel(l, false);
        });

        if (prependNewLetters) {
            this.modifiedLetters.forEach((l: LetterOfAttempt) => {
                if (!l.IsNew())
                    return;

                const newLetterModel = l.getData();
                models.unshift(this.createModel(newLetterModel, l.Deleted(), l)); 
            });
        }

        return models;
    }

    private createModel(letter: ILetterOfAttempt | ILetterOfAttemptForEditor, isDeleted: boolean, viewModel: LetterOfAttempt = null): ILetterOfAttemptDataSourceModel {
        if (!viewModel) {
            const modelForEdit: ILetterOfAttemptForEditor = letter as ILetterOfAttemptForEditor;
            modelForEdit.Deleted = isDeleted;
            viewModel = new LetterOfAttempt(modelForEdit, this);
        }

        const model: ILetterOfAttemptDataSourceModel = {
            id: letter.Id,
            isGroup: false,
            isLeaf: true,
            title: letter.Description,
            subTitle: String.format(ProlifeSdk.TextResources.Customers.LetterOfAttemptValidity, moment(letter.StartDate).format("L"), moment(letter.DueDate).format("L"), letter.RevenueAgencyReceiptDate ? moment(letter.RevenueAgencyReceiptDate).format("L") : TextResources.ProlifeSdk.NotAvailable),
            model: letter as ILetterOfAttempt,
            viewModel: viewModel
        };

        let isInvalidAtReferenceDate = false;
        if (this.referenceDate) {
            const today = moment().startOf("day");
            isInvalidAtReferenceDate = moment(letter.StartDate) > today || moment(letter.DueDate) < today;
        }

        if (letter.ManuallyClosed || (letter.Amount - letter.UsedAmount <= 0 && letter.Amount != 0) || isInvalidAtReferenceDate) {
            model.icon = {
                icon: "fa fa-lock"
            };
        }

        return model;
    }
}

@DetectClassChanges
export class LetterOfAttempt implements IDetectChanges {
    public readonly Id: number;

    @DetectChanges
    public Description: ko.Observable<string> = ko.observable();
    @DetectChanges
    public StartDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public DueDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public RevenueAgencyReceiptDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public Amount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Note: ko.Observable<string> = ko.observable();
    @DetectChanges
    public ManuallyClosed: ko.Observable<boolean> = ko.observable();
    @DetectChanges
    public Deleted: ko.Observable<boolean> = ko.observable();

    public UsedAmount: ko.Observable<number> = ko.observable();

    @DetectChanges
    public WithoutAmountLetter: ko.Observable<boolean> = ko.observable();
    public ShowNote: ko.Observable<boolean> = ko.observable(false);

    public isChanged: ko.Observable<number> = ko.observable(0);
    
    public IsExpired: ko.Computed<boolean>;
    public CanOpen: ko.Computed<boolean>;
    public CanClose: ko.Computed<boolean>;
    public IsNew: ko.Computed<boolean>;

    private LetterOfAttemptProtocolRegExp = /^[0-9]{17}[\-\/]{1}[0-9]{6}$/g;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    
    constructor(private letterOfAttempt: ILetterOfAttemptForEditor, private dataSource: LettersOfAttemptsDataSource) {
        this.Id = this.letterOfAttempt.Id;
        
        this.Description(this.letterOfAttempt.Description);
        this.StartDate(this.letterOfAttempt.StartDate);
        this.DueDate(this.letterOfAttempt.DueDate);
        this.RevenueAgencyReceiptDate(this.letterOfAttempt.RevenueAgencyReceiptDate);
        this.Amount(this.letterOfAttempt.Amount);
        this.Note(this.letterOfAttempt.Note);
        this.ManuallyClosed(this.letterOfAttempt.ManuallyClosed);
        this.Deleted(this.letterOfAttempt.Deleted);

        this.UsedAmount(this.letterOfAttempt.UsedAmount);

        this.WithoutAmountLetter(!this.letterOfAttempt.Amount && !!this.Id);

        this.IsNew = ko.computed(() => {
            return !this.letterOfAttempt.Id;
        });

        this.IsExpired = ko.computed(() => {
            return this.Amount() === 0 || moment((this.DueDate() || "2100-01-01T00:00:00+01:00")).startOf("day") < moment().startOf("day");
        });

        this.CanOpen = ko.computed(() => {
            return this.ManuallyClosed() && !this.IsExpired();
        });

        this.CanClose = ko.computed(() => {
            return !this.ManuallyClosed() && !this.IsExpired();
        })

        this.Description.subscribe((d: string) => {
            if (!d)
                return;

            let parts = d.split("-");
            let separator = "";
            if (parts.length != 2) {
                parts = d.split("/");

                if (parts.length != 2)
                    return;

                separator = "/";
            }
            else
                separator = "-";

            let serial = parts[1];
            while(serial.length < 6)
                serial = "0" + serial;

            const protocol = (parts[0] || "").trim() + separator + (serial || "").trim();

            this.Description(protocol);
        });

        this.isChanged(0);

        this.isChanged.subscribe((value: number) => {
            dataSource.addChangedLetterOfAttempt(this);
        });

        this.StartDate.subscribe((date: Date) => {
            const dueDate = this.DueDate();

            if (!dueDate || moment(dueDate) < moment(date))
                this.DueDate(date);
        });
    }

    public getData(): ILetterOfAttemptForEditor {
        const data: ILetterOfAttemptForEditor = $.extend(true, {}, this.letterOfAttempt);

        data.Description = this.Description();
        data.StartDate = this.StartDate();
        data.DueDate = this.DueDate();
        data.RevenueAgencyReceiptDate = this.RevenueAgencyReceiptDate();
        data.Amount = this.WithoutAmountLetter() ? 0 : this.Amount();
        data.Note = this.Note();
        data.ManuallyClosed = this.ManuallyClosed();
        data.Deleted = this.Deleted();

        return data;
    }

    public setDeleted(): void {
        this.Deleted(true);
    }

    public restore(): void {
        this.Deleted(false);
    }

    public setManuallyClosed(closed: boolean): void {
        this.ManuallyClosed(closed);
    }

    public getValidationWarnings(): string[] {
        let warnings = [];

        this.LetterOfAttemptProtocolRegExp.lastIndex = -1;
        
        if (!this.LetterOfAttemptProtocolRegExp.test(this.Description()))
            warnings.push(String.format(ProlifeSdk.TextResources.Customers.InvalidLetterOfAttemptProtocolNumber, this.Description()));
        
        if (!this.RevenueAgencyReceiptDate())
            warnings.push(ProlifeSdk.TextResources.Customers.MissingLetterOfAttemptDate);

        return warnings;
    }

    public validate(): boolean {
        if (!this.Description()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Customers.MissingLetterOfAttemptProtocol);
            return false;
        }

        if (this.Amount() === null || this.Amount() === undefined || this.Amount() < 0) {
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Customers.InvalidLetterOfAttemptAmount, this.Description()));
            return false;
        }
        
        if (!this.StartDate()) {
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Customers.InvalidLetterOfAttemptStartDate, this.Description()));
            return false;
        }

        if (!this.DueDate()) {
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Customers.InvalidLetterOfAttemptDueDate, this.Description()));
            return false;
        }

        if (moment(this.StartDate()) > moment(this.DueDate())) {
            this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Customers.InvalidLetterOfAttemptValidity, this.Description()));
            return false;
        }

        return true;
    }

    public dispose(): void {
        this.dataSource = null;
    }
}