import ko = require("knockout");
import * as React from "@abstraqt-dev/jsxknockout";
import { BindTo } from "../../../../Components/Bind";

type MaybeObservable<T> = ko.Observable<T> | T;

type ArticleInfoProps = {
    code: MaybeObservable<string>;
    description: MaybeObservable<string>;
}

export function ArticleInfo(props: ArticleInfoProps) {
    return  <BindTo viewModel={props} as="props">
                <div className="flex-container flex-vertical no-gutters" style={{ width: '100%' }}>
                    <div className="text-ellipsis" data-bind={{ text: props.code }}></div>
                    <small style={{ display: 'block', marginTop: '-5px' }} className="text-muted text-ellipsis" data-bind={{ text: props.description, visible: !!ko.unwrap(props.description) }}></small>
                </div>
            </BindTo>;
}

type PurchaseInfoProps = {
    code: MaybeObservable<string>;
    description: MaybeObservable<string>;
}

export function PurchaseInfo(props: PurchaseInfoProps) {
    return  <BindTo viewModel={props} as="props">
                <div className="flex-container flex-vertical no-gutters" style={{ width: '100%' }}>
                    <div className="text-ellipsis" data-bind={{ text: props.description }}></div>
                    <small style={{ display: 'block', marginTop: '-5px' }} className="text-muted text-ellipsis" data-bind={{ text: props.code, visible: !!ko.unwrap(props.code) }}></small>
                </div>
            </BindTo>;
}

type WorkInfoProps = {
    character: MaybeObservable<string>;
    description: MaybeObservable<string>;
}

export function WorkInfo(props : WorkInfoProps) {
    return  <BindTo viewModel={props} as="props">
                <div className="flex-container flex-vertical no-gutters" style={{ width: '100%' }}>
                    <div className="text-ellipsis" data-bind={{ text: props.description }}></div>
                    <small style={{ display: 'block', marginTop: '-5px' }} className="text-muted text-ellipsis" data-bind={{ text: props.character }}></small>
                </div>
            </BindTo>;
}

type EntityInfoProps = {
    type: MaybeObservable<string>;
    code: MaybeObservable<string>;
    rowDescription: MaybeObservable<string>;
    description: MaybeObservable<string>;
}

export function EntityInfo(props: EntityInfoProps) {
    let type = ko.unwrap(props.type);

    if(type === "WAR")
        return <ArticleInfo code={props.code} description={props.description} />
    else if(type === "EPC" || type === "WPC")
        return <PurchaseInfo code={props.code} description={props.description} />
    else if(type === "EWK")
        return <WorkInfo character={props.rowDescription} description={props.description} />;
    else 
        return null;
}