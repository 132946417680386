import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 01/09/2017
 * Time: 18:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";

export class InterviewCompletedDialog implements IDialog {
    public templateName: string = "interview-complete-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs/";
    public title: string = ProlifeSdk.TextResources.SurveyWizard.InterviewCompletedTitle;

    public modal: { close: (result?: any) => void; };

    constructor() {

    }

    close():void {
        this.modal.close(false);
    }

    action():void {
        this.modal.close(true);
    }
}