import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IJobOrderCallRightTypesPrice } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICallRightTypesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";

export class JobOrderCallRightTypesPrice
{
    public Price : ko.Observable<number> = ko.observable(0);
    public Name : string;

    constructor(private serviceLocator : IServiceLocator, private callRightPrice : IJobOrderCallRightTypesPrice)
    {
        this.load(callRightPrice);
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var callRightsTypesManager : ICallRightTypesSettingsManager = <ICallRightTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.CallRightTypesSettingsManagerType);
        this.Name = callRightsTypesManager.getById(callRightPrice.CallRightId).Name;
    }

    load(callRightPrice : IJobOrderCallRightTypesPrice) : void
    {
        this.Price(callRightPrice.Price);
    }

    getData() : IJobOrderCallRightTypesPrice
    {
        var callRightPrice : IJobOrderCallRightTypesPrice = <IJobOrderCallRightTypesPrice> $.extend({}, this.callRightPrice);
        callRightPrice.Price = this.Price();
        return callRightPrice;
    }
}
