import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { WorkflowSnapshotsDataSource, IWorkflowSnapshotsDataSourceModel } from "../../../../DataSources/WorkflowSnapshotsDataSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import moment = require("moment");
import { IPortletComponent } from "../../../../Components/PortletComponent";
import jss from "jss";
import { IWorkflowSnapshotTask, WorkflowSnapshotTasksDataSource } from "../../../../DataSources/WorkflowSnapshotTasksDataSource";
import { IListComponent } from "../../../../Components/ListComponent";
import { IDataSourceListener, IDataSource } from "../../../../DataSources/IDataSource";
import { ISnapshot, ITodoListService, IEstimatedCostsAndRevenuesAtSnapshotDate, ICreateOrUpdateSnapshotRequest } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";

const { classes } = jss.createStyleSheet({
    snapshotPortlet: {
        "& .portlet": {
            marginBottom: "0px",

            "& .portlet-body": {
                display: "flex !important",
                "-ms-flex-wrap": "nowrap",
                "flex-wrap": "nowrap",
                "flex-grow": 1,
                "flex-direction": "column",

                "& list": {
                    "height": "calc(100% - 65px)",

                    "& .list-notification-container": {
                        height: "100% !important"
                    },

                    "& .money": {
                        width: "12%"
                    }
                },

                "& .totals-table-wrapper": {
                    height: "60px",
                    "border-top": "1px solid lightgray",
                    color: "#74788d",
                    position: "relative",
                    top: "5px",

                    "& .totals-table": {
                        border: "1px solid #f2f2f2",

                        "& td": {
                            width: "16.65%"
                        }
                    }
                },

                "& .estimated-work": {
                    "border-left": "5px solid #ff893e"
                },
                
                "& .estimated-purchases": {
                    "border-left": "5px solid #56b7f7"
                },

                "& .estimated-articles": {
                    "border-left": "5px solid #43b545"
                }
            }
        }
    },

    timelinePortlet: {
        height: "100%",
        marginBottom: "0px",

        "& .portlet-body": {
            height: "100%"
        }
    },

    portletFormActions: {
        "& .form-actions": {
            padding: "15px 0px 0px 0px",

            "& button": {
                margin: "0px 3px"
            }
        }
    }
}).attach();

const attributes = {
    WorkflowId: "workflowId"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "workflow-snapshots-editor": {
               params?: {
                   WorkflowId: number
               };
               
               workflowId: number | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IWorkflowSnapshotsEditorProps {
    workflowId: number;
}

export interface ISnapshotFormObserver {
    onSnapshotCreationStarted(): void;
    onSnapshotSaved(snapshot: ISnapshot): void;
    onSnapshotDeleted(snapshot: ISnapshot): void;
}

export class WorkflowSnapshotsEditor implements IDataSourceListener, ISnapshotFormObserver {
    public SnapshotForm: SnapshotForm;
    public TimelinePortlet: ko.Observable<IPortletComponent> = ko.observable();
    public SnapshotContentList: ko.Observable<IListComponent<number, IWorkflowSnapshotTask>> = ko.observable();
    public SnapshotsDataSource: WorkflowSnapshotsDataSource;
    public SnapshotTasksDataSource: WorkflowSnapshotTasksDataSource;

    public TotalWorkCosts: ko.Observable<number> = ko.observable(0);
    public TotalPurchasesCosts: ko.Observable<number> = ko.observable(0);
    public TotalArticlesCosts: ko.Observable<number> = ko.observable(0);

    public TotalWorkRevenues: ko.Observable<number> = ko.observable(0);
    public TotalPurchasesRevenues: ko.Observable<number> = ko.observable(0);
    public TotalArticlesRevenues: ko.Observable<number> = ko.observable(0);

    public TotalCosts: ko.Computed<number>;
    public TotalRevenues: ko.Computed<number>;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;
    
    constructor(private props : IWorkflowSnapshotsEditorProps) {
        this.SnapshotForm = new SnapshotForm(this.props.workflowId);
        this.SnapshotForm.addObserver(this);
        this.SnapshotForm.setSnapshot();

        this.SnapshotsDataSource = new WorkflowSnapshotsDataSource();
        this.SnapshotsDataSource.setWorkflowId(this.props.workflowId);

        this.SnapshotTasksDataSource = new WorkflowSnapshotTasksDataSource();

        this.TimelinePortlet.subscribe((p) => {
            p.setViewModel(this);
        });

        this.TotalCosts = ko.computed(() => {
            return this.TotalWorkCosts() + this.TotalPurchasesCosts() + this.TotalArticlesCosts();
        });

        this.TotalRevenues = ko.computed(() => {
            return this.TotalWorkRevenues() + this.TotalPurchasesRevenues() + this.TotalArticlesRevenues();
        });
    }
    
    public onItemSelected(sender: IDataSource, model: IWorkflowSnapshotsDataSourceModel): void {
        this.SnapshotForm.setSnapshot(model.model);
        this.SnapshotTasksDataSource.setSnapshotId(model ? model.id: undefined);
        this.SnapshotTasksDataSource.refresh();

        if (model && model.id) {
            this.todolistService.GetSnapshotTasksEstimatedCostsAndRevenuesAtSnapshotDate(model.id)
                .then((totals: IEstimatedCostsAndRevenuesAtSnapshotDate) => {
                    this.TotalWorkCosts(totals.TotalWorkCosts);
                    this.TotalWorkRevenues(totals.TotalWorkRevenues);

                    this.TotalPurchasesCosts(totals.TotalPurchasesCosts);
                    this.TotalPurchasesRevenues(totals.TotalPurchasesRevenues);

                    this.TotalArticlesCosts(totals.TotalArticlesCosts);
                    this.TotalArticlesRevenues(totals.TotalArticlesRevenues);
                });
        } else {
            this.TotalWorkCosts(0);
            this.TotalWorkRevenues(0);

            this.TotalPurchasesCosts(0);
            this.TotalPurchasesRevenues(0);

            this.TotalArticlesCosts(0);
            this.TotalArticlesRevenues(0);
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IWorkflowSnapshotsDataSourceModel): void {
        this.SnapshotForm.setSnapshot();
        this.SnapshotTasksDataSource.setSnapshotId(undefined);
        this.SnapshotTasksDataSource.refresh();
    }
    
    public async canSelectItem(sender: IDataSource, model: IWorkflowSnapshotsDataSourceModel): Promise<boolean> {
        return this.SnapshotForm.canChangeSelectedSnapshot();
    }

    public onSnapshotCreationStarted(): void {
        this.SnapshotsDataSource.select();
        this.SnapshotTasksDataSource.setSnapshotId(undefined);
        this.SnapshotTasksDataSource.refresh();
    }

    public onSnapshotSaved(snapshot: ISnapshot): void {
        this.SnapshotsDataSource.refresh();
        this.SnapshotTasksDataSource.setSnapshotId(undefined);
        this.SnapshotTasksDataSource.refresh();
    }
    
    public onSnapshotDeleted(snapshot: ISnapshot): void {
        this.SnapshotsDataSource.refresh();
        this.SnapshotTasksDataSource.setSnapshotId(undefined);
        this.SnapshotTasksDataSource.refresh();
    }

    render() {
        return ComponentUtils.bindTo((
            <div className="flex-container flex-full-height">
                <portlet class={"flex-container flex-vertical " + classes.timelinePortlet} portlet-title={TextResources.Todolist.WorkflowSnapshotsTimelineTitle} collapsible={false} inject-to={() => "TimelinePortlet"}>
                    <timeline class="flex-fill" orientation="vertical" data-source={() => "SnapshotsDataSource"} listeners={() => "[$data]"}></timeline>
                </portlet>
                <div class="flex-container flex-vertical flex-fill">
                    <portlet class={classes.portletFormActions + " " + classes.snapshotPortlet} portlet-title={TextResources.Todolist.WorkflowSnapshotDataEditor} collapsible={false} view-model={() => "SnapshotForm"}>
                        <div class="form">
                            <div class="form-body" data-bind="with: SelectedSnapshot">
                                <text-input value={() => "Label"} label={TextResources.Todolist.SnapshotFormLabel} placeholder={TextResources.Todolist.SnapshotFormLabel}></text-input>
                            </div>
                            <div class="form-actions text-right">
                                <button type="button" class="btn btn-primary" data-bind="click: applySnapshot, enable: SelectedSnapshot() && !!SelectedSnapshot().Id">{TextResources.Todolist.ApplySnapshotButton}</button>
                                <button type="button" class="btn btn-success" data-bind="click: createNewSnapshot">{TextResources.Todolist.NewSnapshotButton}</button>
                                <button type="button" class="btn btn-danger" data-bind="click: deleteSnapshot, enable: SelectedSnapshot() && !!SelectedSnapshot().Id">{TextResources.Todolist.DeleteSnapshotButton}</button>
                                <button type="button" class="btn btn-primary" data-bind="click: saveSnapshot">{TextResources.Todolist.SaveSnapshotButton}</button>
                            </div>
                        </div>
                    </portlet>
                    <portlet class={classes.snapshotPortlet + " flex-container flex-vertical flex-fill"} portlet-title={TextResources.Todolist.SnapshotContentTitle} collapsible={false} view-model={() => "$data"}>
                        <list dataSource={() => "SnapshotTasksDataSource"} listener={() => "$data"} containerHeight="100%" injectTo={() => "SnapshotContentList"}>
                            <div class="list-notification-item-details" data-bind="with: Model">
                                <div class="list-notification-item-title flex-container">
                                    <span data-bind="text: Title"></span>
                                    <span class="flex-1 text-right" data-bind="text: "></span>
                                </div>
                                <div class="list-notification-item-time">
                                <table class="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>{TextResources.ProlifeSdk.Description}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.Amount}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.UnitCost}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.Cost}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.Markup}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.UnitPrice}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.Discount}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.NetUnitPrice}</th>
                                            <th class="text-right money">{TextResources.ProlifeSdk.Price}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ko-if data-bind="EstimatedBudgetRows.length === 0">
                                        <tr>
                                            <td class="text-center" colSpan={8}>{TextResources.Todolist.EmptyTaskSnapshotEstimatedBudgetRows}</td>
                                        </tr>
                                        </ko-if>
                                        <ko-foreach data-bind="EstimatedBudgetRows">
                                        <tr data-bind="css: { 'estimated-work': Type === 'EWK', 'estimated-articles': Type === 'WAR', 'estimated-purchases': Type === 'EPC' }">
                                            <td data-bind="">
                                                <ko-ifnot data-bind="Type === 'WAR'">
                                                    <span data-bind="text: EntityName"></span>
                                                </ko-ifnot>
                                                <ko-if data-bind="Type === 'WAR'">
                                                    <span data-bind="text: Description"></span>
                                                </ko-if>
                                            </td>
                                            <td data-bind="if: Type !== 'WAR'">
                                                <span data-bind="text: Description"></span>
                                            </td>
                                            <td data-bind="numberText: Amount" class="text-right"></td>
                                            <td data-bind="moneyText: UnitCost" class="text-right"></td>
                                            <td data-bind="moneyText: Cost" class="text-right"></td>
                                            <td data-bind="percentageText: Markup" class="text-right"></td>
                                            <td data-bind="moneyText: UnitPrice" class="text-right"></td>
                                            <td data-bind="discountText: DiscountValues" class="text-right"></td>
                                            <td data-bind="moneyText: NetUnitPrice" class="text-right"></td>
                                            <td data-bind="moneyText: Price" class="text-right"></td>
                                        </tr>
                                        </ko-foreach>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </list>
                        <div class="totals-table-wrapper" data-bind="visible: SnapshotForm.SelectedSnapshot() && !!SnapshotForm.SelectedSnapshot().Id">
                            <table class="table table-condensed totals-table">
                                <thead>
                                    <tr>
                                        <th colSpan={3}>{TextResources.Todolist.Costs} <span data-bind="moneyText: TotalCosts"></span></th>
                                        <th colSpan={3}>{TextResources.Todolist.Revenues} <span data-bind="moneyText: TotalRevenues"></span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="estimated-work">{TextResources.Todolist.Work} <span data-bind="moneyText: TotalWorkCosts"></span></td>
                                        <td class="estimated-purchases">{TextResources.Todolist.Purchases} <span data-bind="moneyText: TotalPurchasesCosts"></span></td>
                                        <td class="estimated-articles">{TextResources.Todolist.Warehouse} <span data-bind="moneyText: TotalArticlesCosts"></span></td>
                                        <td class="estimated-work">{TextResources.Todolist.Work} <span data-bind="moneyText: TotalWorkRevenues"></span></td>
                                        <td class="estimated-purchases">{TextResources.Todolist.Purchases} <span data-bind="moneyText: TotalPurchasesRevenues"></span></td>
                                        <td class="estimated-articles">{TextResources.Todolist.Warehouse} <span data-bind="moneyText: TotalArticlesRevenues"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </portlet>
                </div>
            </div>
        ), this, "snapshotsEditor");
    }
}

export class SnapshotForm {
    public SelectedSnapshot: ko.Observable<Snapshot> = ko.observable();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;
    
    private observers: ISnapshotFormObserver[] = [];

    constructor(private workflowId) {}

    public setSnapshot(model: ISnapshot = null) {
        model = model || this.createSnapshotModel();
        const snapshot = new Snapshot(model);
        this.SelectedSnapshot(snapshot);
    }

    public async canChangeSelectedSnapshot(): Promise<boolean> {
        if (!this.SelectedSnapshot() || !this.SelectedSnapshot().isChanged())
            return true;

        return this.showPendingSnapshotChangesConfirmPrompt();
    }

    public async applySnapshot(): Promise<void> {
        if (!this.testIfSelectedSnapshotIsSet())
            return;

        if (!this.SelectedSnapshot().Id)
            return;

        let confirm = true;

        confirm = await this.dialogsService.ConfirmAsync(TextResources.Todolist.ApplySnapshotMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);

        if (!confirm)
            return;

        await this.todolistService.ApplySnapshotToWorkflowTasks(this.SelectedSnapshot().Id, null);
        this.infoToastService.Success(TextResources.Todolist.SnapshotApplySuccess);
    }

    public async createNewSnapshot(): Promise<void> {
        let confirm = true;

        if (this.SelectedSnapshot() && this.SelectedSnapshot().isChanged() > 0)
            confirm = await this.showPendingSnapshotChangesConfirmPrompt();

        if (!confirm)
            return;

        const snapshotData = this.createSnapshotModel();
        this.SelectedSnapshot(new Snapshot(snapshotData));

        this.observers.forEach(o => o.onSnapshotCreationStarted());
    }
    
    public async saveSnapshot(): Promise<void> {
        if (!this.testIfSelectedSnapshotIsSet())
            return;

        const snapshot = this.SelectedSnapshot();
        if (!snapshot.Label()) {
            this.infoToastService.Warning(TextResources.Todolist.SnapshotLabelRequired);
            return;
        }

        const snapshotData = snapshot.GetData();
        const request: ICreateOrUpdateSnapshotRequest = {
            id: snapshotData.Id,
            label: snapshotData.Label,
            date: snapshotData.Date,
            workflowId: snapshotData.WorkflowId,
            createdByResourceId: snapshotData.CreatedByResourceId
        };

        const updatedSnapshot: ISnapshot = await this.todolistService.CreateOrUpdateSnapshot(request);
        
        const newSnapshot = this.createSnapshotModel();
        this.SelectedSnapshot(new Snapshot(newSnapshot));

        this.infoToastService.Success(TextResources.Todolist.SaveSnapshotSuccess);

        this.observers.forEach((o) => o.onSnapshotSaved(updatedSnapshot));
    }

    public async deleteSnapshot(): Promise<void> {
        if (!this.testIfSelectedSnapshotIsSet())
            return;

        const snapshot = this.SelectedSnapshot();
        
        if (!snapshot.Id)
            return;
        
        const confirm = await this.dialogsService.ConfirmAsync(TextResources.Todolist.SnapshotDeleteConfirmMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);
        if (!confirm)
            return;

        await this.todolistService.DeleteSnapshot(snapshot.Id);
        this.infoToastService.Success(TextResources.Todolist.DeleteSnapshotSuccess);

        this.observers.forEach((o) => o.onSnapshotDeleted(snapshot.GetData()));
    }

    public addObserver(observer: ISnapshotFormObserver): void {
        if (this.observers.indexOf(observer) > -1)
            return;

        this.observers.push(observer);
    }

    public removeObserver(observer: ISnapshotFormObserver): void {
        const index = this.observers.indexOf(observer);

        if (index >= 0)
            this.observers.splice(index, 1);
    }

    private async showPendingSnapshotChangesConfirmPrompt(): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(TextResources.Todolist.PendingSnapshotChangesConfirm, TextResources.ProlifeSdk.Abort, TextResources.ProlifeSdk.Confirm);
    }

    private testIfSelectedSnapshotIsSet(): boolean {
        if (!this.SelectedSnapshot()) {
            this.infoToastService.Warning(TextResources.Todolist.SnapshotSelectionRequired);
            return false;
        }

        return true;
    }

    private createSnapshotModel() {
        return { 
            Id: undefined, 
            Label: "",
            Date: moment().toDate(),
            WorkflowId: this.workflowId,
            CreatedByResourceId: null
        };
    }
}

@DetectClassChanges
export class Snapshot implements IDetectChanges {
    public get Id(): number {
        return this.snapshot?.Id;
    }

    @DetectChanges
    public Label: ko.Observable<string> = ko.observable();
    public isChanged: ko.Observable<number> = ko.observable();

    private snapshot: ISnapshot;

    constructor(snapshot: ISnapshot) {
        this.LoadFromModel(snapshot);
    }
    
    public LoadFromModel(snapshot: ISnapshot) {
        this.snapshot = snapshot;

        this.Label(this.snapshot?.Label);
    }

    public GetData(): ISnapshot {
        const data = $.extend(true, {}, this.snapshot) as ISnapshot;
        data.Label = this.Label();
        return data;
    }

    public dispose(): void {
        
    }
}

ko.components.register("workflow-snapshots-editor", {
    viewModel: {
        createViewModel: (params: IWorkflowSnapshotsEditorProps, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            const vm = new WorkflowSnapshotsEditor(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    
                </>
            ]);
            
            return vm;
        },
    },
    template: []
});