import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import jss from "jss";

const styleSheet = jss.createStyleSheet({
    textInput: {
        "&.input-icon.right > i:nth-child(2), & .input-icon.right > i:nth-child(2)": {
            right: "24px",
        },

        "table.table.compact.editable &": {
            borderLeft: "1px solid #ddd",
            marginLeft: "-1px !important",
        },

        "& .clear-icon": {
            marginTop: "6px",
            fontStyle: "normal",
            cursor: "pointer",

            "&:before": {
                content: "'\\d7'",
            },

            "&:hover": {
                color: "black",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type TextInputProps = {
    id?: string;
    label?: ko.MaybeObservable<string>;
    helpText?: ko.MaybeObservable<string>;
    addonPosition?: "left" | "right";
    uppercase?: boolean;
    maxLength?: number;
    placeholder?: ko.MaybeSubscribable<string>;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean> | (() => boolean);
    valueUpdate?: "input" | "keyup" | "keypress" | "afterkeydown" | "focusout";
    value?: ko.Observable<string> | ko.Computed<string>;
    children?: React.ReactNode;
    horizontal?: boolean;
    simple?: boolean;
    selectOnFocus?: boolean;
    multiline?: boolean;
    className?: string;
    rightIcon?: string;
    canClear?: boolean;
    spellCheck?: boolean;
    password?: boolean;
    name?: string;
    forwardRef?: (ti: _TextInput) => void;

    onFocus?: (element: HTMLInputElement | HTMLTextAreaElement) => void;
    onBlur?: (element: HTMLInputElement | HTMLTextAreaElement) => void;
    onEnterKeyDown?: () => void;
};

export function TextInput(props: TextInputProps) {
    const C = require("./TextInput")._TextInput as typeof _TextInput;
    return <C {...props} />;
}

export class _TextInput {
    private valueInterceptor: ko.Computed<string>;
    hasValue: ko.Computed<boolean>;
    focused: ko.Observable<boolean> = ko.observable().extend({ notify: "always" });

    private inputRef: HTMLInputElement | HTMLTextAreaElement = null;

    static defaultProps = {
        valueUpdate: "focusout",
        addonPosition: "right",
        multiline: false,
        spellCheck: false,
        password: false,
    };

    constructor(private props: TextInputProps) {
        props.value ??= ko.observable();

        this.valueInterceptor = ko.computed({
            read: () => this.props.value() ?? "",
            write: (value: string) => {
                if (value === null || value === undefined) this.props.value(value);
                else this.props.value(this.props.uppercase ? value.toUpperCase() : value);
            },
        });

        this.hasValue = ko.computed(() => {
            return this.valueInterceptor().length > 0;
        });

        if (
            !ko.isObservable(this.props.readonly) &&
            !ko.isComputed(this.props.readonly) &&
            typeof this.props.readonly === "function"
        )
            this.props.readonly = ko.computed(this.props.readonly);

        if (this.props.onFocus) this.focused.subscribe((focused) => focused && this.props.onFocus(this.inputRef));
        if (this.props.onBlur) this.focused.subscribe((focused) => !focused && this.props.onBlur(this.inputRef));

        if (props.forwardRef) props.forwardRef(this);
    }

    private clear() {
        this.valueInterceptor(undefined);
    }

    focus() {
        this.focused(true);
    }

    blur() {
        this.focused(false);
    }

    render() {
        let ti = this;
        if (!Array.isArray(this.props.children)) return null;

        if (this.props.simple) {
            return ComponentUtils.bindTo(this.renderInput(), this, "ti");
        }

        return ComponentUtils.bindTo(
            <div className={ComponentUtils.classNames("form-group", this.props.className, classes.textInput)}>
                {this.renderLabel()}
                {this.props.children.length > 0 && this.renderWithAddon()}
                {this.props.children.length === 0 && this.props.horizontal && (
                    <div className="col-md-9">{this.renderInput()}</div>
                )}
                {this.props.children.length === 0 && !this.props.horizontal && this.renderInput()}
                {this.renderHelp()}
            </div>,
            this,
            "ti"
        );
    }

    private renderHelp() {
        let ti = this;

        if (ko.isObservable(ti.props.helpText))
            return (
                <ko-bind data-bind={{ if: !!ti.props.helpText() }}>
                    <span class="help-block" data-bind={{ text: ti.props.helpText }}></span>
                </ko-bind>
            );

        return ti.props.helpText && <span className="help-block">{ti.props.helpText}</span>;
    }

    private renderInput() {
        let ti = this;

        let bindings =
            "disable: ti.props.readonly, value: ti.valueInterceptor, valueUpdate: ti.props.valueUpdate, hasFocus: ti.focused, attr: { placeholder: ti.props.placeholder }";

        if (this.props.selectOnFocus) bindings += ", selectOnFocus: {}";
        if (this.props.onEnterKeyDown)
            bindings += ", onKeyPress: { funcToInvoke: ti.props.onEnterKeyDown,  keyCode: 13 }";

        const classNames = ComponentUtils.classNames("form-control", this.props.simple ? this.props.className : "");

        if (this.props.multiline) {
            return (
                <textarea
                    id={ti.props.id}
                    style={{ resize: "vertical" }}
                    name={ti.props.name}
                    className={classNames}
                    maxLength={ti.props.maxLength}
                    data-bind={bindings}
                    spellCheck={ti.props.spellCheck}
                    ref={(ref) => (this.inputRef = ref)}
                />
            );
        }

        const input = (
            <input
                id={ti.props.id}
                className={classNames}
                name={ti.props.name}
                type={ti.props.password ? "password" : "text"}
                maxLength={ti.props.maxLength}
                data-bind={bindings}
                spellCheck={ti.props.spellCheck}
                ref={(ref) => (this.inputRef = ref)}
            />
        );

        if (this.props.rightIcon || this.props.canClear) {
            return (
                <div
                    className={ComponentUtils.classNames(
                        "input-icon right",
                        this.props.simple ? classes.textInput : ""
                    )}
                >
                    {this.props.rightIcon && <i className={this.props.rightIcon} />}
                    {this.props.canClear && (
                        <i className="clear-icon" data-bind={{ visible: ti.hasValue, click: ti.clear.bind(ti) }} />
                    )}
                    {input}
                </div>
            );
        }

        return input;
    }

    private renderWithAddon() {
        let ti = this;

        const inputClasses = ComponentUtils.classNames("input-group", {
            "col-md-9": ti.props.horizontal,
        });

        return (
            <div className={inputClasses}>
                {ti.props.addonPosition === "left" && this.props.children}
                {this.renderInput()}
                {ti.props.addonPosition === "right" && this.props.children}
            </div>
        );
    }

    private renderLabel() {
        let ti = this;
        const labelClasses = ComponentUtils.classNames("control-label", {
            "col-md-3": ti.props.horizontal,
        });

        if (ko.isObservable(ti.props.label))
            return (
                <ko-bind data-bind={{ if: !!ti.props.label() }}>
                    <label className={labelClasses} data-bind={{ text: ti.props.label }}></label>
                </ko-bind>
            );

        return this.props.label && <label className={labelClasses}>{ti.props.label}</label>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(TextInput);
}
