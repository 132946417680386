import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../interfaces/desktop/IChangesNotificationsService";
import { IEntitiesLockService } from "../../../interfaces/desktop/IEntitiesLockService";

export class EntityLockManager implements IChangesNotificationsServiceObserver {
    private lockService: IEntitiesLockService;
    private authorizationService: IAuthorizationService;
    private changesNotificationsService: IChangesNotificationsService;

    public CanForceUnlock: ko.Observable<boolean> = ko.observable(false);
    public IsLocked: ko.Observable<boolean> = ko.observable(false);

    private entityType: string = null;
    private entityKeyId: number = null;

    constructor(serviceLocator: IServiceLocator) {
        this.lockService = <IEntitiesLockService>serviceLocator.findService(ProlifeSdk.EntitiesLockServiceType);
        this.authorizationService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
        this.changesNotificationsService = <IChangesNotificationsService>serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);

        var canUnlock: boolean = this.authorizationService.isAuthorized(ProlifeSdk.DesktopApplicationCode + "_CanForceEntitiesUnlock");
        this.CanForceUnlock(canUnlock);
    }

    public SetEntity(entityType: string, entityKeyId: number) {
        this.changesNotificationsService.RemoveObserver(this);
        this.entityType = entityType;
        this.entityKeyId = entityKeyId;

        this.lockService.IsEntityLockedByOthers(entityType, entityKeyId).then((isLocked: boolean) => {
            this.IsLocked(isLocked);
            this.changesNotificationsService.ObserveNotificationsFor(this.entityType, this);
        });
    }

    public Dispose() {
        //E' importante invocarla altrimenti l'elemento rimane sempre in ascolto di notifiche
        this.changesNotificationsService.RemoveObserver(this);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (sendByMe || this.entityKeyId != changesInfo.EntityKeyId) return false;

        if (changesInfo.Action == 3) this.IsLocked(true);
        else if (changesInfo.Action == 4) this.IsLocked(false);

        return false;
    }

    public ForceUnlock() {
        this.lockService.ForceUnlockForEntity(this.entityType, this.entityKeyId).then(() => {
            this.IsLocked(false);
        });
    }
}
