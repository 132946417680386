import { BaseDataSource } from "./BaseDataSource";
import { IDataSource, IDataSourceModel, IDataSourceView } from "./IDataSource";

export class ProxyDataSource<T extends IDataSource, DM extends IDataSourceModel> extends BaseDataSource<DM> {
    constructor(private dataSource : T) {
        super();
    }

    getTitle(currentModel: DM): string {
        return this.dataSource.getTitle(currentModel);
    }

    isGroupedData(currentModel: DM, textFilter: string): boolean {
        return this.dataSource.isGroupedData(currentModel, textFilter);
    }

    areEqual(a: DM, b: DM): boolean {
        return this.dataSource.areEqual(a, b);
    }

    getData(currentModel: DM, textFilter: string, skip: number, count: number): Promise<DM[]> {
        return this.dataSource.getData(currentModel, textFilter, skip, count) as Promise<DM[]>;
    }

    getById(currentModel: DM, ids: (string | number)[]): Promise<DM[]> {
        return this.dataSource.getById(currentModel, ids) as Promise<DM[]>;
    }

    setView(view: IDataSourceView<string | number, any>): void {
        this.view = view;

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }

        if(this.selectRequested) {
            this.selectRequested = false;
            this.select(...this.selectRequest);
        }
    }

    getSupportedDropMimeTypes(): string[] {
        return this.dataSource.getSupportedDropMimeTypes();
    }

    onItemBeginMove?(model: DM, dataTransfer: DataTransfer) {
        return this.dataSource.onItemBeginMove(model, dataTransfer);
    }

    onItemMoved?(dataTransfer: DataTransfer, model: DM, before: boolean): Promise<void> {
        return this.dataSource.onItemMoved(dataTransfer, model, before);
    }

}