import * as ko from "knockout";
import { iframeResizer } from "iframe-resizer";
const iframeresizerScript = require("!!raw-loader!iframe-resizer/js/iframeResizer.contentWindow.min.js").default;
const iframestyle = require("!!raw-loader!./iFrame/iframestyle.css").default;

export class IFrameContentBindingHandler {
    init(element: HTMLIFrameElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings) {
        let allOptions = { checkOrigin: false };

        if (allBindingsAccessor.has("iframeContentOptions")) {
            allOptions = { ...allBindingsAccessor.get("iframeContentOptions"), ...allOptions };
        }

        const resizer = iframeResizer(allOptions, element);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            for (const r of resizer) r.iFrameResizer.close();
        });
    }

    update(
        element: HTMLIFrameElement,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const value = ko.utils.unwrapObservable(valueAccessor());

        if (element.contentWindow) {
            const doc = element.contentWindow.document;
            doc.write(
                "<!DOCTYPE html><html><head><script>" +
                    iframeresizerScript +
                    "</script><style>" +
                    iframestyle +
                    "</style></head><body>" +
                    (value ?? "") +
                    "</body></html>"
            );
            doc.close();
        } else {
            $(element).one("ready", () => {
                const doc = element.contentWindow.document;
                doc.write(
                    "<!DOCTYPE html><html><head><script>" +
                        iframeresizerScript +
                        "</script><style>" +
                        iframestyle +
                        "</style></head><body>" +
                        (value ?? "") +
                        "</body></html>"
                );
                doc.close();
            });
        }
    }
}

ko.bindingHandlers["iframeContent"] = new IFrameContentBindingHandler();
