import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IWorkedHoursRowForList, IWorkedHoursEditor } from "../../../interfaces/IWorkedHoursEditor";
import { IException, IWorkedHoursImportedInDocumentsValidationException } from "../../../../Core/interfaces/IException";
import { IWorkedHoursService, IWorkedHoursWithApprovalStateForListHoursApprovalRows, IWorkedHoursWithApprovalStateForListWorkedHours } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IBehindMenuOptions } from "../../../../ProlifeSdk/interfaces/bindings/IBehindMenuOptions";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { HoursApprovalStateUtilities } from "../approval/HoursApprovalStateUtilities";

export class WorkedHoursRowForList implements IWorkedHoursRowForList, IBehindMenuOptions {
    public HoursId: number;
    public ResourceId: number;

    public ResourceName: ko.Observable<string> = ko.observable();
    public IsFlexibility: ko.Observable<boolean> = ko.observable(false);
    public OrdinaryHours: ko.Observable<number> = ko.observable();
    public BreakHours: ko.Observable<number> = ko.observable();
    public RoleName: ko.Observable<string> = ko.observable();
    public WorkTimeCategoryName: ko.Observable<string> = ko.observable();
    public Billable: ko.Observable<boolean> = ko.observable();
    public CallRight: ko.Observable<boolean> = ko.observable();
    public TravelDistance: ko.Observable<number> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public WorkDate: ko.Observable<Date> = ko.observable();
    public EndTime: ko.Observable<Date> = ko.observable();
    public WorkDateTimeZone: ko.Observable<string> = ko.observable();
    public EndTimeTimeZone: ko.Observable<string> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public TaskName: ko.Observable<string> = ko.observable();
    public WorkflowName: ko.Observable<string> = ko.observable();
    public WorkPlaceId: ko.Observable<number> = ko.observable();
    public ApprovalState : ko.Observable<HoursApprovalState> = ko.observable();

    public ApprovalRows: ko.ObservableArray<IWorkedHoursWithApprovalStateForListHoursApprovalRows> = ko.observableArray([]);
    public ApprovalRowsWithOutcome : ko.Computed<IWorkedHoursWithApprovalStateForListHoursApprovalRows[]>;

    public WorkDateText: ko.Computed<string>;
    public EndTimeText: ko.Computed<string>;
    
    public ShowedTimezoneDifferentFromOriginalData: ko.Computed<boolean>;

    public CanEdit: ko.Observable<boolean> = ko.observable();

    public LeftMenuEnabled: ko.Observable<boolean> = ko.observable(false);
    public RightMenuEnabled: ko.Observable<boolean> = ko.observable(true);
    public LeftToEndMenuEnabled: ko.Observable<boolean> = ko.observable(false);
    public RightToEndMenuEnabled: ko.Observable<boolean> = ko.observable(false);
    public SlideRightEnabled: ko.Observable<boolean> = ko.observable(true);
    public SlideLeftEnabled: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService!: IWorkedHoursService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesManager: IHumanResourcesSettingsManager;

    constructor(private workedHours: IWorkedHoursWithApprovalStateForListWorkedHours, approvalRows: IWorkedHoursWithApprovalStateForListHoursApprovalRows[], private editor: IWorkedHoursEditor) {
        this.IsFlexibility(workedHours.EntityType === "FLX");
        this.WorkDateText = ko.computed(() => {
            if (!this.WorkDate() || !this.WorkDateTimeZone())
                return "";
            // eslint-disable-next-line import/namespace
            return moment.tz(this.WorkDate(), this.WorkDateTimeZone()).format("L LT z");
        });

        this.EndTimeText = ko.computed(() => {
            if (!this.EndTime() || !this.WorkDateTimeZone())
                return "";
            // eslint-disable-next-line import/namespace
            return moment.tz(this.EndTime(), this.EndTimeTimeZone()).format("L LT z");
        });

        this.ShowedTimezoneDifferentFromOriginalData = ko.computed(() => {
            // eslint-disable-next-line import/namespace
            return moment.tz(this.WorkDateTimeZone()).format("z") != moment.tz(this.workedHours.WorkDateTimezone).format("z") || moment.tz(this.EndTimeTimeZone()).format("z") != moment.tz(this.workedHours.EndTimeTimezone).format("z");
        });

        this.ApprovalRowsWithOutcome = ko.computed(() => {
            return this.ApprovalRows().filter(r => r.IsValid && HoursApprovalStateUtilities.isApprovedOrInApprovalPhase(r.ApprovalState));
        });

        this.RightMenuEnabled(true);
        this.RightToEndMenuEnabled(false);
        this.LeftToEndMenuEnabled(false);

        this.loadFromModel(workedHours, approvalRows);
    }

    public loadFromModel(workedHours: IWorkedHoursWithApprovalStateForListWorkedHours, approvalRows: IWorkedHoursWithApprovalStateForListHoursApprovalRows[]): void {
        this.workedHours = workedHours;
        this.ApprovalRows(approvalRows);

        const editAllResourcesRight = this.authorizationsService.isAuthorized("WorkedHours_CanEditOtherUsersWorkSheets");
        const resourceOnRow = this.editor.getSelectedResourceId();
        const actualResource = this.humanResourcesManager.getHumanResourceByUserId(this.userInfoService.getIdUser());

        this.CanEdit(!this.workedHours.BusinessManagement 
            && this.workedHours.ApprovalState !== HoursApprovalState.Approved
            && this.workedHours.ApprovalState !== HoursApprovalState.PartiallyApproved
            && (editAllResourcesRight || (actualResource && actualResource.Resource.Id === resourceOnRow)));
        
        this.HoursId = this.workedHours.HoursId;
        this.ResourceId = this.workedHours.ResourceId;

        this.ResourceName(this.workedHours.ResourceName);
        this.OrdinaryHours(this.workedHours.Hours);
        this.BreakHours(this.workedHours.BreakHours);
        this.RoleName(this.workedHours.RoleName);
        this.WorkTimeCategoryName(this.workedHours.WorkTimeCategoryName);
        this.Billable(this.workedHours.Billable);
        this.CallRight(this.workedHours.CallRight);
        this.TravelDistance(this.workedHours.TravelDistance);
        this.Description(this.workedHours.Description);
        this.WorkDate(this.workedHours.WorkDate);
        this.EndTime(this.workedHours.EndTime);
        this.WorkDateTimeZone(this.workedHours.WorkDateTimezone);
        this.EndTimeTimeZone(this.workedHours.EndTimeTimezone);
        this.JobOrderName(this.workedHours.JobOrderTitle);
        this.TaskName(this.workedHours.BusinessManagement ? (this.workedHours.Description || TextResources.ProlifeSdk.NotAvailable) : (this.workedHours.TaskTitle || TextResources.ProlifeSdk.NotAvailable));
        this.WorkflowName(this.workedHours.WorkflowTitle);
        this.WorkPlaceId(this.workedHours.WorkPlaceId);
        this.ApprovalState(this.workedHours.ApprovalState);
    }
    
    public resetTimezones(): void {
        this.WorkDateTimeZone(this.workedHours.WorkDateTimezone);
        this.EndTimeTimeZone(this.workedHours.EndTimeTimezone);
    }

    public edit(): void {
        if (!this.CanEdit())
            return;

        this.editor.editRow(this.workedHours.HoursId);
    }

    public async approve(): Promise<void> {
        if (this.editor.isHourInEditing(this.HoursId)) {
            await this.dialogsService.AlertAsync(ProlifeSdk.TextResources.WorkedHours.AlertBeforeApprovalMessage, ProlifeSdk.TextResources.WorkedHours.AlertBeforeApprovalTitle);
            return;
        }

        const approvalResult = await this.workedHoursService.ShowApprovalDialog(this.workedHours);

        if (!approvalResult)
            return;

        this.editor.onWorkedHoursApproval(this.HoursId);
    }

    public async Delete(): Promise<void> {
        if (!this.CanEdit())
            return;

        let deleted = false;
        const confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.WorkedHours.DeleteWorkedHoursRowConfirmMessage, ProlifeSdk.TextResources.WorkedHours.DeleteWorkedHoursRowCancelButton, ProlifeSdk.TextResources.WorkedHours.DeleteWorkedHoursRowConfirmButton);

        if (confirm) {
            try {
                await this.workedHoursService.DeleteHour(this.workedHours.HoursId);
                deleted = true;
            } catch(e) {
                const ex = e as IException;

                if (ex.ExceptionType == ProlifeSdk.ServerException_ProLifeValidationException && ex.ExceptionCode === 50100) {
                    this.infoToastService.Error(ex.ExceptionMessage);
                }

                if (ex.ExceptionType === ProlifeSdk.ServerException_WorkedHoursImportedInDocumentsValidationException) {
                    const whEx = ex as IWorkedHoursImportedInDocumentsValidationException;
                    const documents: string[] = whEx.ReferencingDocuments.map(r => String.format(ProlifeSdk.TextResources.WorkedHours.HoursReferencingDocumentForAlertMessage, r.DocumentLabel, r.DocumentNumber, moment(r.DocumentDate).format("L"), r.ProtocolName));
                    let message = whEx.ExceptionMessage + "<br/><br/>" + documents.join("<br/>");
                    
                    if (this.authorizationsService.isAuthorized("WorkedHours_EditOrDeleteImportedInDocumentsWorkedHours")) {
                        message += "<br/><br/>" + ProlifeSdk.TextResources.WorkedHours.ConfirmDeletingImportedInDocumentsHours;
                        const forceSavingImportedInDocumentsHours = await this.dialogsService.ConfirmAsync(message, ProlifeSdk.TextResources.ProlifeSdk.Abort, ProlifeSdk.TextResources.ProlifeSdk.Confirm);

                        if (forceSavingImportedInDocumentsHours) {
                            await this.workedHoursService.DeleteHour(this.workedHours.HoursId, forceSavingImportedInDocumentsHours);
                            deleted = true;
                        }

                    } else {
                        await this.dialogsService.AlertAsync(message, ProlifeSdk.TextResources.WorkedHours.WorkedHours);
                    }
                }
            }
            
            if (deleted)
                this.editor.onWorkedHoursDeleted(this.workedHours.HoursId);
        }
    }

    public getApprovalState(): number {
        return this.workedHours.ApprovalState;
    }
}