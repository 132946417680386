/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/08/2018
 * Time: 10:39
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../DocumentsService";
import { IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import {
    IMonthlyInvoicingLogRowViewModel,
    IMonthlyInvoicingEntityLogViewModel,
} from "../../../interfaces/IMonthlyInvoicingLogRowViewModel";
import {
    IMonthlyInvoicingLogRow,
    IMonthlyInvoicingEntityLog,
} from "../../../../ProlifeSdk/interfaces/invoice/wizard/IMonthlyInvoicingLogRow";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DocumentsLogStatus } from "./DocumentsLogStatus";

export class MonthlyInvoicingLogDialog implements IDialog {
    public templateName = "monthly-invoicing-log-dialog";
    public templateUrl = "invoices/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardLogTitle;
    public modal: { close: (result?: unknown) => void };

    public Logs: ko.ObservableArray<IMonthlyInvoicingLogRowViewModel> = ko.observableArray([]);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    constructor() {
        this.loadLog();
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        this.modal.close();
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", { noPrompt: true });
    }

    public loadLog(): Promise<IMonthlyInvoicingLogRow[]> {
        return this.invoicesService
            .GetMonthlyInvoicingLog(this.Logs().length, 100)
            .then((logs: IMonthlyInvoicingLogRow[]) => {
                this.Logs(this.Logs().concat(logs.map((l: IMonthlyInvoicingLogRow) => this.createLogViewModel(l))));
                return logs;
            });
    }

    private createLogViewModel(log: IMonthlyInvoicingLogRow): IMonthlyInvoicingLogRowViewModel {
        return new MonthlyInvoicingLogRow(log);
    }
}

class MonthlyInvoicingLogRow implements IMonthlyInvoicingLogRowViewModel {
    public ResourceName: ko.Observable<string> = ko.observable();
    public Date: ko.Observable<Date> = ko.observable();
    public WithPartialInvoicingStep: ko.Observable<boolean> = ko.observable();
    public AggregatesImportedDocumentsRows: ko.Observable<boolean> = ko.observable();
    public Expanded: ko.Observable<boolean> = ko.observable();
    public DocumentsTree: ko.ObservableArray<IMonthlyInvoicingEntityLogViewModel> = ko.observableArray([]);
    public State: ko.Observable<number> = ko.observable();
    public OperationType: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    private operationTypeLabels = [
        TextResources.Invoices.MonthlyInvoicingOperation,
        TextResources.Invoices.ElectronicInvoicingOperation,
        TextResources.Invoices.PassiveInvoiceImportOperation,
        TextResources.Invoices.MailSent,
    ];

    constructor(private log: IMonthlyInvoicingLogRow) {
        this.ResourceName(this.log.ResourceName);
        this.Date(this.log.Date);
        this.WithPartialInvoicingStep(this.log.WithPartialInvoicingStep);
        this.AggregatesImportedDocumentsRows(this.log.AggregatesImportedDocumentsRows);
        this.State(this.log.State);
        this.OperationType(this.operationTypeLabels[this.log.OperationType]);

        this.Expanded(false);

        this.Expanded.subscribe((value: boolean) => {
            if (value && this.DocumentsTree().length == 0) this.loadDocuments();
        });
    }

    private loadDocuments(): Promise<IMonthlyInvoicingEntityLog[]> {
        return this.invoicesService
            .GetMonthlyInvoicingEntitiesLog(this.log.DocumentsTreeRoots)
            .then((logs: IMonthlyInvoicingEntityLog[]) => {
                this.DocumentsTree(logs.map((l: IMonthlyInvoicingEntityLog) => this.createEntityLogViewModel(l, logs)));
                return logs;
            });
    }

    private createEntityLogViewModel(
        log: IMonthlyInvoicingEntityLog,
        logs: IMonthlyInvoicingEntityLog[]
    ): IMonthlyInvoicingEntityLogViewModel {
        return new MonthlyInvoicingEntityLog(log, logs, this.log.OperationType);
    }
}

class MonthlyInvoicingEntityLog implements IMonthlyInvoicingEntityLogViewModel {
    public EntityName: ko.Observable<string> = ko.observable();
    public EntityType: ko.Observable<string> = ko.observable();
    public DocumentLabel: ko.Observable<string> = ko.observable();
    public VatRegister: ko.Observable<string> = ko.observable();
    public IsPartialInvoice: ko.Observable<boolean> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();
    public PaymentType: ko.Observable<string> = ko.observable();
    public PaymentIBAN: ko.Observable<string> = ko.observable();
    public PaymentABI: ko.Observable<string> = ko.observable();
    public PaymentCAB: ko.Observable<string> = ko.observable();
    public State: ko.Observable<number> = ko.observable();
    public StateMessage: ko.Observable<string> = ko.observable();

    public OperationType: ko.Observable<number> = ko.observable();

    //public EntityLink: ko.Observable<string> = ko.observable();
    public CustomerLink: ko.Observable<string> = ko.observable();
    public JobOrderLink: ko.Observable<string> = ko.observable();

    public IsLastLevel: ko.Computed<boolean>;
    public Icon: ko.Computed<string>;
    public IconColor: ko.Computed<string>;

    public HasEntityLink: ko.Observable<boolean> = ko.observable(false);

    private generationStatesMap: { [key: string]: string } = {};
    private entitiesMap: { [key: string]: string } = {};

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor(
        private log: IMonthlyInvoicingEntityLog,
        private logs: IMonthlyInvoicingEntityLog[],
        operationType: number
    ) {
        this.initializeStatusMapping();
        this.initializeEntitiesMapping();

        this.EntityType(this.log.EntityType);
        this.EntityName(this.entitiesMap[this.log.EntityType]);
        this.DocumentLabel(this.log.DocumentLabel);
        this.VatRegister(this.log.VatRegisterName);
        this.IsPartialInvoice(this.log.IsPartialInvoice);
        this.CustomerName(this.log.CustomerName);
        this.JobOrderName(this.log.JobOrderName);
        this.ExpiryType(this.log.ExpiryType);
        this.PaymentType(this.log.PaymentType);
        this.PaymentIBAN(this.log.PaymentIBAN);
        this.PaymentABI(this.log.PaymentABI);
        this.PaymentCAB(this.log.PaymentCAB);
        this.State(this.log.State);
        this.StateMessage(
            this.log.StateMessage ? this.log.StateMessage : this.generationStatesMap["_" + this.log.State]
        );

        this.OperationType(operationType);

        this.HasEntityLink(
            [
                ProlifeSdk.InvoiceEntityTypeCode,
                ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
                ProlifeSdk.CreditNoteEntityTypeCode,
                ProlifeSdk.DdtEntityTypeCode,
                ProlifeSdk.PassiveInvoiceEntityTypeCode,
            ].indexOf(this.EntityType()) >= 0 && this.log.EntityId > 0
        );

        if (this.log.CustomerId)
            this.CustomerLink(
                "#/" + ProlifeSdk.TextResources.Customers.AddressBookURL + "/" + this.log.CustomerId.toString()
            );

        if (this.log.JobOrderId)
            this.JobOrderLink(
                "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + this.log.JobOrderId.toString()
            );

        this.IsLastLevel = ko.computed(() => {
            let maxLevel = 0;
            this.logs.forEach((l: IMonthlyInvoicingEntityLog) => {
                if (l.Level > maxLevel) maxLevel = l.Level;
            });

            return maxLevel == this.log.Level;
        });

        this.Icon = ko.computed(() => {
            return this.isSuccessState() ? "fa-check" : "fa-exclamation-circle";
        });

        this.IconColor = ko.computed(() => {
            return this.isSuccessState() ? "green" : "red";
        });
    }

    private isSuccessState(): boolean {
        return this.State() == DocumentsLogStatus.Generated || this.State() == DocumentsLogStatus.MailSendSuccess;
    }

    private initializeStatusMapping() {
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingGenerationQueuedState] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationQueuedState;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingGenerationGeneratedState] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationGeneratedState;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingGenerationNotGeneratedForInvalidTrustBalanceState] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationNotGeneratedForInvalidTrustBalanceState;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingGenerationNotGeneratedForUnexpectedError] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationNotGeneratedForUnexpectedError;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingLogMailAborted] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingLogMailAborted;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingLogMailSendSuccess] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingLogMailSendSuccess;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingLogMailSendPartial] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingLogMailSendPartial;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingLogMailSendError] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingLogMailSendError;
        this.generationStatesMap["_" + ProlifeSdk.MonthlyInvoicingLogMailSendRefused] =
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingLogMailSendRefused;
    }

    private initializeEntitiesMapping() {
        this.entitiesMap[ProlifeSdk.WorkedHoursEntityTypeCode] = ProlifeSdk.TextResources.JobOrder.WorkedHours;
        this.entitiesMap[ProlifeSdk.PurchasesEntityTypeCode] = ProlifeSdk.TextResources.Blog.Purchases;
        this.entitiesMap[ProlifeSdk.InvoiceEntityTypeCode] = ProlifeSdk.TextResources.Invoices.Invoice;
        this.entitiesMap[ProlifeSdk.AccompanyingInvoiceEntityTypeCode] =
            ProlifeSdk.TextResources.Invoices.AccompanyingInvoice;
        this.entitiesMap[ProlifeSdk.CreditNoteEntityTypeCode] = ProlifeSdk.TextResources.Invoices.CreditNote;
        this.entitiesMap[ProlifeSdk.DdtEntityTypeCode] = ProlifeSdk.TextResources.Invoices.Delivery;
        this.entitiesMap[ProlifeSdk.EstimateEntityTypeCode] = ProlifeSdk.TextResources.Invoices.Estimate;
        this.entitiesMap[ProlifeSdk.SalEntityTypeCode] = ProlifeSdk.TextResources.Invoices.Sal;
        this.entitiesMap[ProlifeSdk.CustomerOrderEntityTypeCode] = ProlifeSdk.TextResources.Warehouse.CustomerOrder;
        this.entitiesMap[ProlifeSdk.SupplierOrderEntityTypeCode] = ProlifeSdk.TextResources.Warehouse.SupplierOrder;
        this.entitiesMap[ProlifeSdk.WarehouseLoadEntityTypeCode] = ProlifeSdk.TextResources.Warehouse.WarehouseLoad;
        this.entitiesMap[ProlifeSdk.RequestForQuotationEntityTypeCode] =
            ProlifeSdk.TextResources.Provisioning.RequestsForQuotation;
        this.entitiesMap[ProlifeSdk.PurchaseRequestEntityTypeCode] =
            ProlifeSdk.TextResources.Provisioning.PurchaseRequest;
        this.entitiesMap[ProlifeSdk.PassiveInvoiceEntityTypeCode] = ProlifeSdk.TextResources.Invoices.PassiveInvoice;
    }

    public async OpenEntity(): Promise<void> {
        if (!this.HasEntityLink()) return;
        return this.documentsService.OpenDocumentOverlay(this.log.EntityType, this.log.EntityId);
    }
}
