import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { HoursRecordEvent } from "../EventViewModels/HoursRecordEvent";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../../../Users/HumanResourcesService";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWorkedHoursService, IWorkedHoursInADay } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IBlogTask } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class ResourceHoursGroup
{
    public humanResourcesSearchService : IControlsEntityProvider;
    private toastService : IInfoToastService;
    private humanResourcesService : IHumanResourcesSettingsManager;
    private workedHoursService : IWorkedHoursService;

    public JobOrderId: ko.Observable<number> = ko.observable(0);
    public WorkerId: ko.Observable<number> = ko.observable(0);
    public FromTime : ko.Observable<any> = ko.observable(moment("09:00", "HH:mm").toDate());
    public ToTime : ko.Observable<any> = ko.observable(moment("17:00", "HH:mm").toDate());
    public TravelDistance: ko.Observable<number> = ko.observable(0);
    public Breaks: ko.Observable<any> = ko.observable(0);
    public EffectiveTime : ko.Computed<number>;
    public EffectiveRecordTime : ko.Computed<number>;
    public IsEffectiveTimeNotCorrect : ko.Computed<boolean>;

    private CallRightSelectedRecord : ko.Observable<any> = ko.observable(null);

    public HoursRecords : ko.ObservableArray<HoursRecordEvent> = ko.observableArray([]);

    public WorkerDescription : ko.Observable<any> = ko.observable();

    private WorkedHoursInADayInOtherEvents : ko.ObservableArray<IWorkedHoursInADay> = ko.observableArray([]);

    private lastWorkerId : number;
    private lastReferenceDate : Date;

    constructor(private serviceLocator : IServiceLocator, records : HoursRecordEvent [], private contextEventsObserver : IContextEventsObserver,
        private manageCallRightOnChildren : boolean, private referenceDateField : ko.Observable<Date>, private workEventId : number, public selectedTasks : ko.ObservableArray<IBlogTask>,
        private jobOrderIdField : ko.Observable<number>)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.workedHoursService = <IWorkedHoursService> serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.humanResourcesSearchService = entityProviderService.getEntityProvider(ProlifeSdk.HumanResources).getControlsProvider();
        this.toastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);

        this.EffectiveTime = ko.computed(() => {
            if(!this.ToTime() || !this.FromTime())
                return 0;

            var effective : number = (moment(this.ToTime()).diff(moment(this.FromTime()), "minutes") /60) -
                this.Breaks();
            effective = effective > 0 ? effective : 0;
            return effective;
        });

        this.EffectiveRecordTime = ko.computed(() => {
            var total = 0;
            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                total += parseFloat(<any>r.Hours());
            })
            return total;
        });

        this.IsEffectiveTimeNotCorrect = ko.computed(() => {
            return this.EffectiveRecordTime() != this.EffectiveTime();
        });

        if(records.length == 0)
            records.push(this.prepareNewHourRecord());

        this.WorkerId.subscribe((workerId : number) => {
            this.WorkerDescription("");

            if(workerId)
            {
                var user : IHumanResource = this.humanResourcesService.getHumanResourceById(workerId);
                this.WorkerDescription(this.humanResourcesService.getFullName(user));
            }

            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                r.WorkerId(workerId);
            });

            this.RefreshWorkedHoursInOtherEvents();
        });

        this.FromTime.subscribe((from : Date) => {
            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                r.FromTime(from);
            });
        });

        this.ToTime.subscribe((to : Date) => {
            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                r.ToTime(to);
            });
        });

        this.TravelDistance.subscribe((value : number) => {
            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                r.TravelDistance(value);
            });
        });

        this.Breaks.subscribe((value : number) => {
            this.HoursRecords().forEach((r : HoursRecordEvent) => {
                r.Breaks(value);
            });
        });

        this.WorkerId(records[0].WorkerId());
        this.FromTime(records[0].FromTime());
        this.ToTime(records[0].ToTime());
        this.TravelDistance(records[0].TravelDistance());
        this.Breaks(records[0].Breaks());

        if(this.manageCallRightOnChildren)
            records.forEach((r : HoursRecordEvent) => {
                r.SetCallRightObserver(this.CallRightSelectedRecord);
            });

        records.forEach((r : HoursRecordEvent) => {
            var task = this.selectedTasks().filter((t : any) => r.Tasks().length > 0 && t.Id == r.Tasks()[0]);
            if(task.length > 0)
                r.setTask(task[0]);
        });

        this.HoursRecords(records);

        jobOrderIdField.subscribe(this.setJobOrder.bind(this));
        referenceDateField.subscribe(this.setDateOnHours.bind(this));
        referenceDateField.subscribe(this.RefreshWorkedHoursInOtherEvents.bind(this));

        this.setJobOrder();
        this.setDateOnHours();
    }

    private setJobOrder(){
        this.JobOrderId(this.jobOrderIdField());
        this.HoursRecords().forEach((r : HoursRecordEvent) => { r.JobOrder(this.jobOrderIdField()); })
    }

    private setDateOnHours(){
        this.HoursRecords().forEach((r : HoursRecordEvent) => { r.ReferenceDate(this.referenceDateField()); })
    }

    private RefreshWorkedHoursInOtherEvents()
    {
        if(this.lastWorkerId == this.WorkerId() && this.lastReferenceDate && this.referenceDateField() && this.lastReferenceDate.valueOf() == this.referenceDateField().valueOf())
            return;

        this.lastWorkerId = this.WorkerId();
        this.lastReferenceDate = this.referenceDateField();

        this.WorkedHoursInADayInOtherEvents([]);

        if(this.WorkerId() && this.referenceDateField())
            this.workedHoursService.GetWorkedHoursByResourcesInWorkDay(this.referenceDateField(), [this.WorkerId()], this.workEventId ? this.workEventId : -1).then((result) => {
                this.WorkedHoursInADayInOtherEvents(result);
            });
    }

    public RemoveHoursRecord(record)
    {
        this.HoursRecords.remove(record);
    }

    private prepareNewHourRecord(){
        var newEvent = new HoursRecordEvent(this.serviceLocator, this.contextEventsObserver);
        if(this.manageCallRightOnChildren)
            newEvent.SetCallRightObserver(this.CallRightSelectedRecord);
        newEvent.WorkerId(this.WorkerId());
        newEvent.FromTime(this.FromTime());
        newEvent.ToTime(this.ToTime());
        newEvent.TravelDistance(this.TravelDistance());
        newEvent.Breaks(this.Breaks());
        newEvent.AttachedTask(this.selectedTasks().length > 0 ? this.selectedTasks()[0] : null);
        newEvent.JobOrder(this.JobOrderId());
        newEvent.ReferenceDate(this.referenceDateField());

        if(this.HoursRecords().length > 0)
            newEvent.InitFromOther(this.HoursRecords()[0]);

        return newEvent;
    }
    public AddNewHoursRecord()
    {
        this.HoursRecords.push(this.prepareNewHourRecord());
    }

    public ValidateHours() : boolean
    {
        if(this.WorkerId() <= 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Blog.SelectResource);
            return false;
        }

        var isIntervalValid = this.FromTime() && this.ToTime() && this.FromTime() <= this.ToTime();

        if(!isIntervalValid)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Blog.InvalidInterval);
            return false;
        }

        var result : boolean = true;
        this.HoursRecords().forEach((r : HoursRecordEvent) => {
            if(!result)
                return;

            result = r.WorkTimeCategoryId() > 0;

            if(!result)
            {
                this.toastService.Warning(ProlifeSdk.TextResources.Blog.SelectType);
                return;
            }

            result = r.RoleId() > 0;

            if(!result)
            {
                this.toastService.Warning(ProlifeSdk.TextResources.Blog.SelectRole);
                return;
            }

            result = r.Hours() > 0;

            if(!result)
            {
                this.toastService.Warning(ProlifeSdk.TextResources.Blog.InsertHours);
                return;
            }
        });
        return result;
    }
}