import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss"
import { WorkflowCategoryControlsEntityProvider } from "../../entity-providers/WorkflowCategoryControlsEntityProvider";
import { ResourcesAndGroupsManager } from "../tasks-list/ResourcesAndGroupsManager";
import { ITodoListTemplate } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITemplateEditMaskObserver } from "../../../interfaces/ITemplateEditMaskObserver";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { WorkflowEditDialogBase } from "../workflows/WorkflowEditDialogBase";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { If } from "../../../../Components/IfIfNotWith";
import { Right } from "../../../../Core/Authorizations";
import { CheckBox } from "../../../../Components/Checkbox";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Portlet } from "../../../../Components/Portlet";
import { WorkedHoursDefaultsEditor } from "../utils/WorkedHoursDefaultsEditor";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type TemplateEditMaskDialogProps = {
    template : ITodoListTemplate;
    parent : ITemplateEditMaskObserver;
    originWorkflowId?: number;
    edit?: boolean;
}

type TemplateEditMaskProps = TemplateEditMaskDialogProps & {
    forwardRef?: (editor: _TemplateEditMask) => void;
}

export class TemplateEditMaskDialog extends DialogComponentBase {
    static defaultProps: Partial<TemplateEditMaskDialogProps> = {
        originWorkflowId: -1
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private editor: _TemplateEditMask;

    constructor(private props : TemplateEditMaskDialogProps) {
        super({ className: "medium"});
        this.title(this.props.template.Id > 0 ? TextResources.Todolist.EditWorkflowTemplate : TextResources.Todolist.NewWorkflowTemplate);
    }
    
    public Edit(): void {
        this.editor.Edit();
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    action() {
        this.editor
            .action()
            .then(() => this.modal.close());
    }
    
    renderBody() {
        return <TemplateEditMask {...this.props} forwardRef={(editor) => this.editor = editor} />;
    }
}

export function TemplateEditMask(props: TemplateEditMaskProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./TemplateEditMask")._TemplateEditMask as typeof _TemplateEditMask;
    return <C {...props} />;
}

export class _TemplateEditMask extends WorkflowEditDialogBase
{
    static defaultProps: Partial<TemplateEditMaskProps> = {
        originWorkflowId: -1,
        edit: false
    }

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacters : IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesSettingsManager : IWorkTimeCategoriesSettingsManager;

    public IsDefault : ko.Observable<boolean> = ko.observable(true);
    public EditMode : ko.Observable<number> = ko.observable(0);  //0=View, 1=Edit
    public DefaultRoleName : ko.Computed<string>;
    public RoleNames: ko.Computed<string[]>;
    public WorkTimeCategoryNames: ko.Computed<string[]>;
    public CategoryId : ko.Computed<number>;

    public template : ITodoListTemplate;

    @Right("!Allocations")
    private allocationsModuleEnabled: boolean;
    @Right("TaskBoard_ViewHoursReportingSection")
    private canViewHourReportingSection: boolean;

    constructor(private props: TemplateEditMaskProps)
    {
        super();

        this.template = this.props.template;
        if (this.props.edit)
            this.Edit();

        this.resourcesSearchService = this.entityProviderService.getEntityProvider(ProlifeSdk.HumanResources).getControlsProvider();
        (this.resourcesSearchService as any).GetMaterialResources = false;
        this.CategoriesProvider = new WorkflowCategoryControlsEntityProvider();
        this.Resources = new ResourcesAndGroupsManager();

        this.ActivitiesProgressAmountModeOptions.push({ label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressWorkedHoursMode, value: 0 });
        this.ActivitiesProgressAmountModeOptions.push({ label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressAmountMode, value: 1 });

        this.Roles(this.rolesSettings.getUserCharacters());

        this.Category.subscribe((id: string) => {
            if (this.loading)
                return;
                
            if (!id)
                return;

            const n = parseInt(id);
            if (isNaN(n))
                return;

            if (!n)
                return;

            this.workflowCategories.getCategory(n).then((category) => {
                if (!this.OverrideCategoryShowAlertOnUnestimatedTasksSetting())
                    this.ShowAlertOnUnestimatedTasks(category.ShowAlertOnUnestimatedTasks);

                if (!this.OverrideCategoryWorkflowMustBePlannedSetting())
                    this.WorkflowMustBePlanned(category.WorkflowMustBePlanned);

                if (!this.OverrideCategoryActivitiesMustBeReviewedSetting())
                    this.ActivitiesMustBeReviewed(category.ActivitiesMustBeReviewed);

                if (!this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting())
                    this.WorkflowMustBeRelatedToCustomerOrders(category.WorkflowMustBeRelatedToCustomerOrders);

                this.HideFromSuggestions(category.HideFromSuggestions);
            });
        });

        this.ReportingTypeName = ko.computed(() => {
            return this.ReportingTypes[this.ReportingType() || 0].Description;
        });

        this.RoleNames = ko.computed(() => {
            const roles = this.WorkedHoursDefaultRoles();
            const names = [];

            for (const defaultRole of roles) {
                if (defaultRole.RoleId() === -1) {
                    names.push(TextResources.Todolist.ResourceDefaults);
                    continue;
                }

                const role = this.userCharacters.getUserCharacterById(defaultRole.RoleId());
                if (!role)
                    continue;

                names.push(role.Description);
            }

            return names;
        });
        
        this.WorkTimeCategoryNames = ko.computed(() => {
            const categories = this.WorkedHoursDefaultWorkTimeCategories();
            const names = [];

            for (const defaultCategory of categories) {
                if (defaultCategory.WorkTimeCategoryId() === -1) {
                    names.push(TextResources.Todolist.ResourceDefaults);
                    continue;
                }

                const category = this.workTimeCategoriesSettingsManager.getById(defaultCategory.WorkTimeCategoryId());
                if (!category)
                    continue;

                names.push(category.Name);
            }

            return names;
        });

        this.EnableActivitiesProgressModeSetting.subscribe((value: boolean) => {
            if (!value)
                this.ActivitiesProgressAmountMode(null);

            if (value && this.ActivitiesProgressAmountMode() == null)
                this.ActivitiesProgressAmountMode(0)
        });

        this.DefaultRoleName = ko.computed(() => {
            const matchedRoles = this.Roles().filter((r : IUserCharacter) => r.IdUserCharacter == this.DefaultRoleId());
            if(matchedRoles.length > 0)
                return matchedRoles[0].Description;
            return "";
        });

        this.CategoryId = ko.computed(() => {
            return parseInt(this.Category());
        });

        this.LoadFromModel(this.template);

        this.props.forwardRef && this.props.forwardRef(this);
    }

    public SwitchActivitiesProgressSettingMode() {
        this.EnableActivitiesProgressModeSetting(!this.EnableActivitiesProgressModeSetting());
    }

    public SetActivitiesProgressAmountMode(value: number) {
        this.ActivitiesProgressAmountMode(value);
    }

    action(): Promise<ITodoListTemplate>
    {
        return this.Save();
    }

    public LoadFromModel(template : ITodoListTemplate)
    {
        super.LoadFromModel(template);

        this.IsNew(!template.Id || template.Id <= 0);
        this.IsDefault(template.IsDefault);

        this.isChanged(0);
    }

    private isValid(): boolean {
        const categoryId : number = parseInt(this.Category());

        if ((this.Title() || "").trim().length == 0)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InsertTemplateName);
            return false;
        }

        if (isNaN(categoryId) || !categoryId)
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectCategory);
            return false;
        }

        if (!this.Multiplier() || this.Multiplier() <= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InvalidWorkflowMultiplier);
            return false;
        }

        if (this.Roles().filter((r : IUserCharacter) => r.IdUserCharacter == this.DefaultRoleId()).length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectDefaultRole);
            return false;
        }

        return true;
    }

    public Edit()
    {
        this.EditMode(1);
    }

    public Cancel()
    {
        this.LoadFromModel(this.template);
        this.EditMode(0);
    }

    public async Delete()
    {
        const confirm = await this.dialogService.ConfirmAsync(ProlifeSdk.TextResources.Todolist.SureToDeleteTemplate, ProlifeSdk.TextResources.Todolist.DoNotDeleteTemplate, ProlifeSdk.TextResources.Todolist.DeleteTemplate);
        
        if (!confirm)
            return;

        await this.todoListService.DeleteTemplate(this.template.Id);
        if (this.props.parent)
            this.props.parent.OnTemplateDeleted();
    }

    public IsChanged() : boolean
    {
        return this.isChanged() != 0;
    }

    async Save(): Promise<ITodoListTemplate>
    {
        if (!this.isValid())
            return undefined;

        const model = super.GetData() as ITodoListTemplate;
        model.IsDefault = this.IsDefault();
        model.WorkedHoursDefaultRoles = this.WorkedHoursDefaultRoles().map(r => ({ TemplateId: this.template.Id, RoleId: r.RoleId(), Order: r.Order() }));
        model.WorkedHoursDefaultWorkTimeCategories = this.WorkedHoursDefaultWorkTimeCategories().map(r => ({ TemplateId: this.template.Id, WorkTimeCategoryId: r.WorkTimeCategoryId(), Order: r.Order() }));
        
        if (this.props.originWorkflowId > 0) {
            const template : ITodoListTemplate = await this.todoListService.CreateTemplateFromWorkflow(model, this.props.originWorkflowId)
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TemplateSaved);
            if (this.props.parent)
                this.props.parent.OnTemplateSaved(template);

            return template;
        } else {
            const template : ITodoListTemplate = await this.todoListService.InsertOrUpdateTemplate(model);
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TemplateSaved);
            if(this.props.parent)
                this.props.parent.OnTemplateSaved(template);
                
            return template;
        }
    }

    render() {
        return ComponentUtils.bindTo((
            <>
                <If condition={() => this.EditMode() === 1}>
                    {() => this.renderEditMode()}
                </If>
                <If condition={() => this.EditMode() === 0}>
                    {() => this.renderViewMode()}
                </If>
            </>
        ), this, "templateEditMask");
    }
    
    private renderEditMode() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const templateEditMask = this;
        
        return  <div className="horizontal-form">
                    <div className="form-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="control-label">Titolo</label>
                                    <input className="form-control" spellCheck={false} type="text" placeholder="Inserire un titolo..." data-bind="selectOnFocus : {}, value : Title, valueUpdate : 'afterkeydown'" />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row" style="margin-bottom: 30px">
                            <div className="col-md-12">
                                <textarea data-bind="htmlEditor : Description, html: Description" placeholder="Inserisci la descrizione dell'attivit&agrave;..."></textarea>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="control-label">Responsabile</label>
                                            <input className="form-control" type="hidden" data-bind="value: ResponsibleId, select2: { dropdownCssClass: 'prolife-styles dark', allowClear: true, query: resourcesSearchService.findEntities.bind(resourcesSearchService), placeholder: 'Seleziona...', initSelection: resourcesSearchService.findEntity.bind(resourcesSearchService) }"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="control-label">Categoria</label>
                                            <input className="form-control" type="hidden" data-bind="value: Category, select2: { dropdownCssClass: 'prolife-styles dark', allowClear: true, query: CategoriesProvider.findEntities.bind(CategoriesProvider), placeholder: 'Seleziona...', initSelection: CategoriesProvider.findEntity.bind(CategoriesProvider) }" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="control-label">Risorse</label>
                                            <ko-bind data-bind={{ with : templateEditMask.Resources}}>
                                                <input className="form-control default" type="hidden" data-bind="value: Resources, select2: { ajax : { quietMillis : 500 }, query : resourcesProvider.findEntities.bind(resourcesProvider), initSelection: resourcesProvider.findEntity.bind(resourcesProvider), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1 }"/>
                                            </ko-bind>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <label style="">Sovrascrivi le impostazioni di categoria</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label className="control-label" style="width: 75%">Predefinito</label>
                                            <input data-bind="onOffSwitch: IsDefault, switchSize: 'small'" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                        </div>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label className="control-label" style="width: 75%">Richiedi stima sui task</label>
                                            <input className="" data-bind="onOffSwitch: ShowAlertOnUnestimatedTasks, switchSize: 'small', enable: OverrideCategoryShowAlertOnUnestimatedTasksSetting" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CheckBox checked={this.OverrideCategoryShowAlertOnUnestimatedTasksSetting} />
                                    </div>
                                </div>
                
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label className="control-label" style="width: 75%">Richiedi associazione con ordini cliente</label>
                                            <input className="" data-bind="onOffSwitch: WorkflowMustBeRelatedToCustomerOrders, switchSize: 'small', enable: OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CheckBox checked={this.OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting} />
                                    </div>
                                </div>
                
                                {this.allocationsModuleEnabled && (
                                    <>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label className="control-label" style="width: 75%">Pianificazione richiesta</label>
                                                    <input className="" data-bind="onOffSwitch: WorkflowMustBePlanned, switchSize: 'small', enable: OverrideCategoryWorkflowMustBePlannedSetting" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <CheckBox checked={this.OverrideCategoryWorkflowMustBePlannedSetting} />
                                            </div>
                                        </div>
                    
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label className="control-label" style="width: 75%">Attività da rivedere</label>
                                                    <input className="" data-bind="onOffSwitch: ActivitiesMustBeReviewed, switchSize: 'small', enable: OverrideCategoryActivitiesMustBeReviewedSetting" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <CheckBox checked={this.OverrideCategoryActivitiesMustBeReviewedSetting} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <i className="fa fa-info"></i><span className="bold" style="color: #67b5ca">Attività</span>
                            </Portlet.Header>
                            <Portlet.Body>
                                {() => (
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="control-label">Avanzamento attività</label>
                                                            <select className="form-control" data-bind={{ options: templateEditMask.ActivitiesProgressAmountModeOptions, value: templateEditMask.ActivitiesProgressAmountMode, optionsText: 'label', optionsValue: 'value', optionsCaption: 'Usa le impostazioni predefinite' }}></select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="control-label">Moltiplicatore</label><br />
                                                            <input className="form-control" data-bind={{ numberValue: templateEditMask.Multiplier, selectOnFocus: {} }} type="text" placeholder="Inserire un numero..." />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="control-label">U.d.m.</label><br />
                                                            <input type="text" data-bind={{ value: templateEditMask.MultiplierUoM, selectOnFocus: {} }} className="form-control" placeholder="Unità di misura" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="control-label">Tipo Rendicontazione</label>
                                                            <select className="form-control" data-bind={{ options : templateEditMask.ReportingTypes, optionsText : 'Description', optionsValue : 'Id', value : templateEditMask.ReportingType }}></select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="control-label">Mansione Predefinita</label>
                                                            <select className="form-control" data-bind={{ options : templateEditMask.Roles, optionsText : 'Description', optionsValue : 'IdUserCharacter', value : templateEditMask.DefaultRoleId }}></select>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-9"></div>
                                                    <div className="col-md-3">
                                                        <label style="">Sovrascrivi le impostazioni di categoria</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label className="control-label" style="width: 75%">In qualit&agrave;</label>
                                                            <CheckBox checked={this.InQuality} switchLabels={{ onLabel: TextResources.ProlifeSdk.Yes, offLabel: TextResources.ProlifeSdk.No }} switch switchSize='small' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label className="control-label" style="width: 75%">Nascondi dai Suggerimenti</label>
                                                            <CheckBox checked={this.HideFromSuggestions} readOnly={this.DisableHideFromSuggestion} switchLabels={{ onLabel: TextResources.ProlifeSdk.Yes, offLabel: TextResources.ProlifeSdk.No }} switch switchSize='small' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <CheckBox checked={this.OverrideCategoryHideFromSuggestionsSetting} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.canViewHourReportingSection && (
                                            <WorkedHoursDefaultsEditor
                                                workPlace={this.WorkedHoursDefaultPlace}
                                                travelDistance={this.WorkedHoursDefaultTravelDistance}
                                                forceDataOnWorkedHours={this.WorkedHoursDefaultsStrictMode}
                                                ddc={this.DDC}
                                                hideAdministrativeDataOnWorkedHours={this.HideAdministrativeDataOnWorkedHours}

                                                defaultRoles={this.WorkedHoursDefaultRoles}
                                                defaultWorkTimeCategories={this.WorkedHoursDefaultWorkTimeCategories}
                                                />
                                        )}
                                    </>
                                )}
                            </Portlet.Body>
                        </Portlet>
                    </div>
                </div>;
    }
    
    private renderViewMode() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const templateEditMask = this;
        const $data: ResourcesAndGroupsManager = null;
        const name: string = null;

        return  <div className="horizontal-form" style="padding: 15px">
                    <div className="form-body">
                
                        <ko-bind data-bind={{ if : templateEditMask.Description() && templateEditMask.Description().length > 0 }}>
                        <div className="row">
                            <div className="col-md-12 note note-info">
                                <div data-bind="html : Description"></div>
                            </div>
                        </div>
                        </ko-bind>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Responsabile</label>
                                    <div>
                                        <strong data-bind="HumanResourceDisplayFullName : { id : ResponsibleId }"></strong>
                                    </div>
                                </div>
                            </div>
                
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Tipo Rendicontazione</label>
                                    <div>
                                        <strong data-bind="text : ReportingTypeName"></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Categoria</label>
                                    <div>
                                        <strong data-bind="workflowCategoryName : { id : CategoryId }"></strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Mansione Predefinita</label>
                                    <div>
                                        <strong data-bind="text : DefaultRoleName"></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Risorse</label>
                                    <div>
                                        <ko-bind data-bind={{ with : templateEditMask.Resources }}>
                                            <ko-bind data-bind={{ if : $data.ResourcesIdsAndTypes().length > 0 }}>
                                                <ul className="list-unstyled list-inline comma-separated-list" style="display : inline-block" data-bind="foreach : ResourcesIdsAndTypes">
                                                    <li>
                                                        <strong data-bind="resourceOrGroupText : {  id : ElementId, type : ElementType }"></strong>
                                                    </li>
                                                </ul>
                                            </ko-bind>
                                        </ko-bind>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Avanzamento attività</label>
                                    <div>
                                        <strong data-bind="text: (ActivitiesProgressAmountMode() == null ? 'Usa le impostazioni di sistema' : (ActivitiesProgressAmountMode() == 0 ? 'Calcola l\\\'avanzamento delle attività in base allore lavorate' : 'Calcola l\\\'avanzamento delle attività in base alla quantità'))"></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Predefinito</label>
                                    <div>
                                        <strong data-bind="text : IsDefault() ? 'Si' : 'No'"></strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="control-label">Moltiplicatore</label><br />
                                    <strong data-bind="numberText: Multiplier"></strong>
                                </div>
                            </div>
                
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="control-label">U.d.m.</label><br />
                                    <strong data-bind="text: MultiplierUoM"></strong>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Richiedi stima sui task</label>
                                    <div>
                                        <strong data-bind="text : ShowAlertOnUnestimatedTasks() ? 'Si' : 'No'"></strong>&nbsp;
                                        <strong data-bind="text : OverrideCategoryShowAlertOnUnestimatedTasksSetting() ? '(Sovrascrive le impostazioni di categoria)' : ''"></strong>
                                    </div>
                                </div>
                            </div>
                            {this.canViewHourReportingSection && (
                                <>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">Luogo</label><br />
                                            <strong data-bind={{ text: templateEditMask.WorkedHoursDefaultPlaceLabel }}></strong>
                                        </div>
                                    </div>
                        
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="control-label">Km viaggio</label><br />
                                            <strong data-bind={{ numberText: templateEditMask.WorkedHoursDefaultTravelDistance, format: '0,0.00' }}></strong>
                                        </div>
                                    </div>
                                </>)}
                        </div>
                
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Richiedi associazione con ordini cliente</label>
                                    <div>
                                        <strong data-bind="text : WorkflowMustBeRelatedToCustomerOrders() ? 'Si' : 'No'"></strong>&nbsp;
                                        <strong data-bind="text : OverrideCategoryWorkflowMustBeRelatedToCustomerOrdersSetting() ? '(Sovrascrive le impostazioni di categoria)' : ''"></strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">In qualit&agrave;</label>
                                    <div>
                                        <strong data-bind="text : InQuality() ? 'Si' : 'No'"></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            {this.allocationsModuleEnabled && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Pianificazione richiesta</label>
                                        <div>
                                            <strong data-bind="text : WorkflowMustBePlanned() ? 'Si' : 'No'"></strong>&nbsp;
                                            <strong data-bind="text : OverrideCategoryWorkflowMustBePlannedSetting() ? '(Sovrascrive le impostazioni di categoria)' : ''"></strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Nascondi dai suggerimenti</label>
                                    <div>
                                        <strong data-bind="text : HideFromSuggestions() ? 'Si' : 'No'"></strong>
                                        <strong data-bind="text : OverrideCategoryHideFromSuggestionsSetting() ? '(Sovrascrive le impostazioni di categoria)' : ''"></strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className="row">
                            {this.allocationsModuleEnabled && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Attività da rivedere</label>
                                        <div>
                                            <strong data-bind="text : ActivitiesMustBeReviewed() ? 'Si' : 'No'"></strong>&nbsp;
                                            <strong data-bind="text : OverrideCategoryActivitiesMustBeReviewedSetting() ? '(Sovrascrive le impostazioni di categoria)' : ''"></strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.canViewHourReportingSection && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Todolist.WorkedHoursForcedData}</label>
                                        <div>
                                            <strong data-bind={{ text : templateEditMask.WorkedHoursDefaultsStrictMode() ? 'Si' : 'No' }}></strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-6"></div>
                            {this.canViewHourReportingSection && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Todolist.DDC}</label>
                                        <div>
                                            <strong data-bind={{ text : templateEditMask.DDC() ? 'Si' : 'No' }}></strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-6"></div>
                            {this.canViewHourReportingSection && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Todolist.HideAdminstrativeDataFromWorkedHours}</label>
                                        <div>
                                            <strong data-bind={{ text : templateEditMask.HideAdministrativeDataOnWorkedHours() ? 'Si' : 'No' }}></strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-6"></div>
                            {this.canViewHourReportingSection && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Todolist.WorkedHoursDefaultRoles}</label>
                                        <br/>   
                                        <ul className="list-unstyled list-inline comma-separated-list" style="display : inline-block" data-bind={{ foreach : { data: templateEditMask.RoleNames, as: 'name' } }}>
                                            <li>
                                                <strong data-bind={{ text: name }}></strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-6"></div>
                            {this.canViewHourReportingSection && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Todolist.WorkedHoursDefaultWorkTimeCategories}</label>
                                        <br/>
                                        <ul className="list-unstyled list-inline comma-separated-list" style="display : inline-block" data-bind={{ foreach : { data: templateEditMask.WorkTimeCategoryNames, as: 'name' } }}>
                                            <li>
                                                <strong data-bind={{ text: name }}></strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(TemplateEditMaskDialog);
}