import * as ko from "knockout";
export class NestableList
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = valueAccessor() || {};

        var originalDropCallback = options.dropCallback;

        var cb = function(dropDetails)
        {
            var beforeEl = dropDetails.sourceEl.prev();
            var afterEl = dropDetails.sourceEl.next();

            var movedId = dropDetails.sourceId;
            var movedIsTask = dropDetails.sourceEl.data("istask");

            var beforeId = beforeEl ? beforeEl.data("id") : -1;
            var beforeIsTask = beforeEl ? beforeEl.data("istask") : true;
            var afterId = afterEl ? afterEl.data("id") : -1;
            var afterIsTask = afterEl ? afterEl.data("istask") : true;

            //Elimino l'elemento del DOM droppato se richiesto in configurazione
            if(options.removeDroppedItems)
                $(dropDetails.sourceEl).remove();

            originalDropCallback(beforeId, movedId, afterId, movedIsTask, beforeIsTask, afterIsTask);
        };

        options.dropCallback = cb;

        (<any>$(element)).nestable(options);

    }

}

ko.bindingHandlers["nestableList"] = new NestableList();