import * as ko from "knockout";
import { INavigationMenuComponentActionSeparatorItem, INavigationMenuComponentActionSeparator } from "./INavigationMenuComponent";

export class NavigationMenuComponentActionSeparator implements INavigationMenuComponentActionSeparatorItem {
    public isSeparator: true;
    public isGroup: false;
    
    constructor(public actionInfo: INavigationMenuComponentActionSeparator) {
        this.isSeparator = this.actionInfo.isSeparator;
        this.isGroup = this.actionInfo.isGroup;
    }
}