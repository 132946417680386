import * as ko from "knockout";
import { Moment } from "moment";
import * as moment from 'moment';

/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/04/2018
 * Time: 16:34
 * To change this template use File | Settings | File Templates.
 */

export class DateTimeUtilities {
    constructor() {}

    public GetTimeslotHourWithDaylightSavingTimeCheck(referimentDate: Moment, timeslotTime: Moment): string {
        // TODO: questa la funzione è sbagliata. Il check sull'ora legale viene fatto in base alla timezone corrente. Per farlo correttamente dovrei sapere anche la
        // timezone del momento in cui è stata salvata la fascia oraria dell'agenda (timeslotTime)

        var date: Moment = moment(referimentDate);
        var time: Moment = moment(timeslotTime);

        if (date.isDST() && !time.isDST())
            time.add(-1, "hours");

        if (!date.isDST() && time.isDST())
            time.add(1, "hours");

        return (<any>time).utcOffset((<any>date).utcOffset()).format("LT");
    }
}