import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import jss from "jss";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ReactNode } from "@abstraqt-dev/jsxknockout";

let attributes = {
    
};

const { classes } = jss.createStyleSheet({
    allocationsGantt: {
        height: '100%',

        '& .gantt-wrapper': {
            '--sidebar-width': '380px',
            '--space-between-header-buttons': '5px',
            '--day-width': '20px',
            '--item-height': '30px',
            '--top-bar-height': 'calc(var(--item-height) * 2)',
            '--table-border-color': 'lightgray',
            '--table-weekend-background': '#fff2f2',
            '--table-weekend-foreground': '#a94442',

            height: '100%',

            '& .fixedTable': {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',

                '& .top-area': {
                    display: 'flex',
                    height: 'var(--top-bar-height)',

                    '& .fixedTable-header': {
                        overflow: 'hidden',
                        
                        '& table tr th': {
                            height: 'var(--item-height)',
                            textAlign: 'center',
                            borderRight: '1px solid var(--table-border-color)',
                            borderTop: '1px solid var(--table-border-color)',
                            fontSize: '14px',
                            fontWeight: '600',
                        },

                        '& table tr.months-row th': {
                            maxWidth: 'calc(var(--day-width) * var(--days-in-month))'
                        },
    
                        '& table tr.dates-row th': {
                            minWidth: 'var(--day-width)',
    
                            '&[data-dayofweek="0"], &[data-dayofweek="6"]': {
                                backgroundColor: 'var(--table-weekend-background)',
                                color: 'var(--table-weekend-foreground)'
                            }
                        }
                    },
    
                    '& .fixedTable-menu': {
                        minWidth: 'var(--sidebar-width)',
                        height: 'var(--top-bar-height)',
                        display: 'flex',
                        flexDirection: 'column',
                        borderTop: '1px solid var(--table-border-color)',
                        borderLeft: '1px solid var(--table-border-color)',
                        borderRight: '1px solid var(--table-border-color)',
    
                        '& > .actions': {
                            flex: 1,
                            height: 'calc(var(--top-bar-height) / 2 - 1)',
                            "min-height": 'calc(var(--top-bar-height) / 2 - 1)',
                            display: 'flex',
                            alignItems: 'center',

                            '& > button': {
                                marginRight: 'var(--space-between-header-buttons)',
                            }
                        },
                        
                        '& > .search': {
                            height: 'calc(var(--top-bar-height) / 2)',
                            borderTop: '1px solid var(--table-border-color)',

                            '& > input.form-control': {
                                border: 'none'
                            },

                            '& .select2-container': {
                                height: "29px !important",

                                '& .select2-choice': {
                                    height: "29px",
                                    padding: "0px 0px 0px 12px",
                                    "font-size": "13px"
                                }
                            }
                        }
                    },
                },

                '& .bottom-area': {
                    display: 'flex',
                    flex: 1,
                    overflow: 'hidden',

                    '& .fixedTable-sidebar': {
                        width: 'var(--sidebar-width)',
                        border: '1px solid var(--table-border-color)',

                        '& .items': {
                            display: 'none',
                            width: 'var(--sidebar-width)',
                            borderBottom: '1px solid var(--table-border-color)',

                            '& .items': {
                                borderBottom: 'none',
                            },

                            '&.visible': {
                                display: 'block',
                            },

                            '& > .item': {
                                display: 'none',
                                height: 'var(--item-height)',
                                borderTop: '1px solid var(--table-border-color)',

                                '& .priority-marker': {
                                    position: "absolute",
                                    "background-color": "red",
                                    width: "15px",
                                    height: "var(--item-height)",
                                    display: "inline-block",

                                    "& i": {
                                        color: "white"
                                    }
                                },
                                
                                '& .links-marker': {
                                    position: "absolute",
                                    left: "35px",
                                    "background-color": "transparent",
                                    width: "15px",
                                    height: "var(--item-height)",
                                    display: "inline-block",
                                    cursor: "pointer",

                                    "& i": {
                                        "line-height": "var(--item-height)",
                                        color: "black"
                                    },

                                    "&.backround-red": {
                                        "background-color": "red",

                                        "& i": {
                                            color: "white"
                                        }
                                    }
                                },

                                '&.selected': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.125)',
                                },

                                '&.visible': {
                                    display: 'flex',
                                },

                                '&.disabled': {
                                    color: '#AAA',
                                },

                                '&.droppable-hover-before': {
                                    boxShadow: '0px 2px 0px #4b8df8 inset',
                                },

                                '&.droppable-hover-after': {
                                    boxShadow: '0px -2px 0px #4b8df8 inset',
                                },

                                '& > .item-text': {
                                    flex: 1,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 0, //Serve per far funzionare l'ellipsis con il flex
                                    paddingLeft: 'calc(25px + var(--level) * 30px)',

                                    '& > .item-text-expand-icon': {
                                        width: '25px',
                                        textAlign: 'center',
                                        lineHeight: 'var(--item-height)',
                                        visibility: 'hidden',

                                        '&.visible': {
                                            visibility: 'visible'
                                        }
                                    },

                                    '& > .item-text-icon': {
                                        visibility: 'hidden',

                                        '&.visible': {
                                            visibility: 'visible'
                                        }
                                    },

                                    '& > .item-text-label': {
                                        flex: 1,
                                    }
                                },

                                '& > .item-actions': {
                                    display: 'flex',
                                    borderLeft: '1px solid var(--table-border-color)'
                                }
                            },
                        },
                    },
    
                    '& .fixedTable-body': {
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                        width: '100%',
                        '-webkit-overflow-scrolling': 'touch',
                        overflow: 'hidden',
                        'overflow-x': 'auto',
                        'overflow-y': 'auto',
                        borderTop: '1px solid var(--table-border-color)',
                        borderBottom: '1px solid var(--table-border-color)',

                        '--visible-days': 0,
                        '--visible-items': 0,
                        '--offset-from-sunday': 0,
                        '--background-image': 0,

                        '& .background': {
                            width: 'calc(var(--visible-days) * var(--day-width))',
                            height: 'calc(var(--visible-items) * var(--item-height) + 1px)',
                            backgroundPosition: 'calc(-1 * var(--offset-from-sunday) * var(--day-width)) 0px',
                            backgroundImage: 'var(--background-image)',

                            '& .item-container': {
                                width: 'calc(var(--visible-days) * var(--day-width))',
                                height: 'var(--item-height)',
                                display: 'none',
                                position: 'relative',

                                '&.selected': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.125)',
                                },

                                '&.visible': {
                                    display: 'block'
                                },

                                '& .drop-area': {
                                    height: 'var(--item-height)',
                                },

                                '& .item': {
                                    /*'--color': 'red',
                                    '--border-color': 'red',
                                    '--text-color': 'white',
                                    '--duration': 0,
                                    '--days-from-start': 0,*/

                                    backgroundColor: 'var(--color)',
                                    borderColor: 'var(--border-color)',
                                    width: 'calc(var(--duration) * var(--day-width))',
                                    left: 'calc(var(--days-from-start) * var(--day-width))',
                                    position: 'absolute',
                                    height: 'calc((var(--item-height) * 2) / 3)',
                                    marginTop: 'calc((var(--item-height) * 1) / 6)',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',

                                    '& > .value': {
                                        color: 'var(--text-color)',
                                        fontSize: 'calc((var(--item-height) * 1) / 3)',
                                        lineHeight: 'calc((var(--item-height) * 2) / 3)',
                                        width: 'calc(var(--duration) * var(--day-width))',
                                    },

                                    '&.selected': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.125)',
                                    },

                                    '&.draggable': {
                                        cursor: 'move'
                                    },

                                    '&.no-estimate': {
                                        backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuM4zml1AAAADDSURBVEhLrY5BDsQgCEVdzhlm5RG8/8qb2Xk2tqIko0KT1wCB7wu/L46klL6llPCPnPNHuZ8GkUUtYISHlXs5MNqBHBjt4G0c7OBtHOzgLpzs4C6c7MDVDlztYl3SjkcW7WJd1AJGluxAOx5ZtauPagEjq3b1YS2gZ8eOfTWkZ8eOfTWksWsHU0jPrh1MIY0TOxAhPSd2IEIap3YgmsapHYgGLHYwDSx2IBqrHYjGagdP4WEHT+FhB/XnZQf152VXSgkXJ9ehIC5WRU0AAAAASUVORK5CYII=')",
                                    }
                                },

                                '& .marker': {
                                    '--text-color': 'white',
                                    '--days-from-start': 0,

                                    left: 'calc(var(--days-from-start) * var(--day-width))',
                                    color: 'var(--text-color)',
                                    position: 'absolute',

                                    '&.milestone': {
                                        color: 'currentColor',
                                        fontSize: '1.3em',
                                        lineHeight: 'var(--item-height)',
                                        marginLeft: 'calc(var(--day-width) - 6.5px)',
                                        width: '13px',
            
                                        '&:before': {
                                            content: '"\\2605"',
                                            color: 'currentColor',
                                        }
                                    },
            
                                    '&.diamond': {
                                        border: '1px solid black',
                                        background: 'currentColor',
                                        width: 'calc((var(--item-height) * 1) / 3)',
                                        height: 'calc((var(--item-height) * 1) / 3)',
                                        marginLeft: 'calc((var(--day-width) - (var(--item-height) * 1) / 6))',
                                        marginTop: 'calc((var(--item-height) * 1) / 3)',
                                        transform: 'rotate(45deg)',
                                        boxShadow: '0px 0px 3px white',
                                    },
            
                                    '&.square': {
                                        border: '1px solid black',
                                        background: 'currentColor',
                                        width: 'calc((var(--item-height) * 1) / 3)',
                                        height: 'calc((var(--item-height) * 1) / 3)',
                                        marginLeft: 'calc((var(--day-width) - (var(--item-height) * 1) / 6))',
                                        marginTop: 'calc((var(--item-height) * 1) / 3)',
                                        boxShadow: '0px 0px 3px white',
                                    },
            
                                    '&.square-with-right': {
                                        border: '1px solid black',
                                        background: 'currentColor',
                                        width: 'calc((var(--item-height) * 1) / 3)',
                                        height: 'calc((var(--item-height) * 1) / 3)',
                                        marginLeft: 'calc((var(--day-width) - (var(--item-height) * 1) / 6))',
                                        marginTop: 'calc((var(--item-height) * 1) / 3)',
                                        boxShadow: '0px 0px 3px white',
                                        display: 'flex',
            
                                        '&:after': {
                                            content: '"\\21d2"',
                                            color: 'black',
                                            alignSelf: 'center',
                                            fontSize: 'calc(var(--item-height) / 3)',
                                            marginLeft: 'calc((var(--item-height) / 3) - 2px)',
                                            lineHeight: 'calc(var(--item-height) / 3)',
                                            marginTop: '-2px',
                                        }
                                    },
            
                                    '&.square-with-left': {
                                        border: '1px solid black',
                                        background: 'currentColor',
                                        width: 'calc((var(--item-height) * 1) / 3)',
                                        height: 'calc((var(--item-height) * 1) / 3)',
                                        marginLeft: 'calc((var(--day-width) - (var(--item-height) * 1) / 6))',
                                        marginTop: 'calc((var(--item-height) * 1) / 3)',
                                        boxShadow: '0px 0px 3px white',
                                        display: 'flex',
            
                                        '&:after': {
                                            content: '"\\21d0"',
                                            color: 'black',
                                            alignSelf: 'center',
                                            fontSize: 'calc(var(--item-height) / 3)',
                                            marginLeft: 'calc((var(--item-height) / -3) + 2px)',
                                            lineHeight: 'calc(var(--item-height) / 3)',
                                            marginTop: '-2px',
                                        },
                                    },
            
                                    '&.ball': {
                                        border: '1px solid black',
                                        background: 'currentColor',
                                        width: '14px',
                                        height: '14px',
                                        marginTop: 'calc((var(--item-height) / 2) - 7px)',
                                        marginLeft: 'calc(var(--day-width) - 7px)',
                                        borderRadius: '6px !important',
            
                                        '&:after': {
                                            content: '"!"',
                                            width: '10px',
                                            height: '10px',
                                            display: 'block',
                                            color: 'black',
                                            lineHeight: '10px',
                                            marginLeft: '4px',
                                        }
                                    },
            
                                    '&.arrowUp': {
                                        color: 'black',
                                        width: '0',
                                        height: '0',
                                        borderStyle: 'solid',
                                        borderWidth: '0 5px 5px 5px',
                                        borderColor: 'transparent transparent currentColor transparent',
                                        bottom: '0',
                                        marginLeft: 'calc(var(--day-width) - 5px)',
                                    },
            
                                    '&.arrowDown': {
                                        color: 'black',
                                        width: '0',
                                        height: '0',
                                        borderStyle: 'solid',
                                        borderWidth: '5px 5px 0px 5px',
                                        borderColor: 'currentColor transparent transparent transparent',
                                        top: '0',
                                        marginLeft: 'calc(var(--day-width) - 5px)',
                                    },
            
                                    '&.arrowRight': {
                                        color: 'black',
                                        width: '0',
                                        height: '0',
                                        borderStyle: 'solid',
                                        borderWidth: '5px 0 5px 5px',
                                        borderColor: 'transparent transparent transparent currentColor',
                                        bottom: '0',
                                        marginLeft: '0',
                                    },
            
                                    '&.arrowLeft': {
                                        color: 'black',
                                        width: 'var(--day-width)',
                                        height: '0',
                                        borderStyle: 'solid',
                                        borderWidth: '5px 5px 5px 0',
                                        borderColor: 'transparent currentColor transparent transparent',
                                        bottom: '0',
                                        marginLeft: '0px',
                                    },
            
                                    '&.alertMarker': {
                                        color: 'black',
                                        width: '0',
                                        height: '0',
                                        borderStyle: 'solid',
                                        borderWidth: '0 7px 12px 7px',
                                        borderColor: 'transparent transparent currentColor transparent',
                                        bottom: '8px',
                                        marginLeft: 'calc(var(--day-width) - 6px)',
            
                                        '&:after': {
                                            content: '"!"',
                                            color: 'white',
                                            position: 'absolute',
                                            top: '-1px',
                                            left: '-1px',
                                            fontSize: '10px',
                                        }
                                    }
                                },
                            }
                        },
    
                        '& .today-marker': {
                            width: '2px',
                            backgroundColor: 'black',
                            position: 'absolute',
                            top: '0px',
                        }
                    },
                },
            },
        },

        
    }
}).attach();

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAllocationsGanttComponentParams {

}

export interface IAllocationsGanttComponentProperties {
    autoHeightParentSelector: string;
}

interface IGanttSearchOptions {
    children?: ReactNode;
}

interface IGanttSearchOptionProps {
    title: string;
    selected: (() => string);
    selectedStateSwitcher: (() => string);
    children: ReactNode;
}

interface IGanttSearchOption {
    render(): ReactNode;
    renderDropdownEntry(): ReactNode;
}

function GanttSearch(props: IGanttSearchOptions) {
    const renderOptionsDropdown = function() {
        let optionsDropdownEntries = [];
        ComponentUtils.Children.forEach(props.children, (o) => {
            let component = ComponentUtils.getComponent<IGanttSearchOption>(o);
            optionsDropdownEntries.push(component.renderDropdownEntry());
        });

        return (
            <ul class="dropdown-menu pull-right">
                {optionsDropdownEntries}
            </ul>
        );
    };

    return (
        <div class="search">
            <div class="input-group" style="width: calc(var(--sidebar-width) - 29px); table-layout: fixed;">
                {props.children}
                {ComponentUtils.Children.count(props.children) > 1 && 
                    <div class="input-group-btn" style="left: 1px; height: 29px">
                        <button type="button" class="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" style="height: 29px">
                            <i class="fa fa-angle-down"></i>
                        </button>
                        {renderOptionsDropdown()}
                    </div>
                }
            </div>
        </div>
    );
}

class GanttSearchOption {
    constructor(private props: IGanttSearchOptionProps) {}

    render(): ReactNode {
        return (
            <div data-bind={"visible: " + this.props["bind-selected"] + ", if: " + this.props["bind-selected"]}>
                {this.props.children}
            </div>
        );
    }

    renderDropdownEntry(): ReactNode {
        let visibilityBinding = "visibility: (" + this.props["bind-selected"] +  "() ? 'initial' : 'hidden')";
        return (
            <li data-bind={"click: " + this.props["bind-selectedStateSwitcher"]}>
                <a href="#">
                    <span style="margin-right: 10px">
                        <i class="fa fa-check" data-bind={"style: { " + visibilityBinding + "}"}></i>
                    </span>
                    {this.props.title}
                </a>
            </li>
        ); 
    }
}

export function Gantt(props : IAllocationsGanttComponentProperties) {

    const tableItemActions = () => 
        <>
            <button class="btn btn-xs btn-link" data-bind="click: ShowEditingDialog"><i class="fa fa-pencil black"></i></button>
            <button class="btn btn-xs btn-link" data-bind="click: gantt.ScrollToLeft.bind(gantt, $data)"><i class="fa fa-chevron-circle-left black"></i></button>
            <button class="btn btn-xs btn-link" data-bind="click: gantt.ScrollToRight.bind(gantt, $data)"><i class="fa fa-chevron-circle-right black"></i></button>
        </>;

    const tableItem = (draggable : boolean) => {
        let attr = draggable ? {
            'data-bind': "draggableEx: { CanDrag: true, OnDrag: gantt.OnBeginDrag.bind(gantt, $data) }, click: gantt.Select.bind(gantt)"
        } : {};

        return <>
            <span class="item-text" style="--level: 0" {...attr}>
                <i class="item-text-icon fa fa-users black" style="margin-right: 5px" data-bind="click: showTeamsPopOver, css: { 'visible': Metadata.AllocatedOnTeamsNumber > 1 }"></i>
                <i class="item-text-icon fa fa-list-alt" style="margin-right: 5px" data-bind="click: showCartContent, css: { 'visible': !!Title() }"></i>
                <i class="item-text-icon" style="margin-right: 5px" data-bind="css: { 'visible': Icon() && Title() }, class: Icon"></i>
                <span class="item-text-label text-ellipsis" data-bind="text: (!Title() ? 'Nessun carrello' : Title()), css: { 'not-draggable': !Title() }"></span>
            </span>
            <span class="item-actions">
                {tableItemActions()}
            </span>
        </>
    };
    

    const tableItemChildrenList = () =>
        <div class="items" data-bind="css: { 'visible': team.IsExpanded }, droppableListEx: { hoverClass: 'droppable-over', hoverBeforeClass: 'droppable-hover-before', hoverAfterClass: 'droppable-hover-after', itemSelector: '.sortable-elem', onDrop: gantt.OnAllocatedCartMoved.bind(gantt), allowedMimeTypes: gantt.AllowedMimeTypes }">
            <span class="item cart sortable-elem" data-bind="css: { visible: Children().length === 0, disabled: Disabled }">
                <span class="item-text" style="--level: 0">
                    <span class="item-text-label text-ellipsis not-draggable">
                        {TextResources.Allocations.EmptyTeamCartsAlllocation}
                    </span>
                </span>
            </span>
            <ko-foreach data-bind="{ data: Children().filter(c => c.Metadata.AllocationMode == 1 || !c.Title()), as: 'workflow' }">
                <span class="item cart sortable-elem" data-bind="css: { 'selected' : Selected, 'visible': Visible, disabled: Disabled }, attr: { 'data-id': Id(), 'title': Title }">
                    <span class="priority-marker text-center" data-bind="visible: Metadata.AllocationPriority == 1" title={TextResources.Allocations.CartWithPriority}>
                        <i class="fa fa-arrow-up"></i>
                    </span>
                    <span class="links-marker text-center" data-bind="visible: Metadata.HasLinks == 1, css: { 'backround-red': Metadata.HasUnfulfilledLinks == 1 }, asyncClick: gantt.ShowCartLinksPopOver.bind(gantt)" title={TextResources.Allocations.CartWithLinks}>
                        <i class="fa fa-link"></i>
                    </span>
                    {tableItem(true)}
                </span>
            </ko-foreach>
        </div>;

    const tableItemManualAllocationsChildrenList = () => 
        <span class="items" data-bind="css: { 'visible': team.IsExpanded }">
            <ko-foreach data-bind="{ data: Children().filter(c => c.Metadata.AllocationMode == 0), as: 'workflow' }">
                <span class="item cart" data-bind="css: { 'selected' : Selected, 'visible': Visible() }, attr: { 'title': Title }">
                    {tableItem(false)}
                </span>
            </ko-foreach>
        </span>;

    const tableItemTeam = () => 
        <>
            <div class="item team" data-bind="css: { 'selected' : Selected, 'visible': Visible, disabled: Disabled }, attr: { title: Title }, tooltip: { placement: 'right' }, droppableEx : { allowedMimeTypes: gantt.AllowedMimeTypes, hoverClass: 'droppable-hover', onDrop: gantt.OnAllocatedCartMovedOnTeam.bind(gantt, $data) }">
                <div class="item-text" data-bind="click: gantt.Select.bind(gantt)">
                    <span class="item-text-expand-icon fa expand" data-bind="css : { 'fa-caret-down' : IsExpanded, 'fa-caret-right' : !IsExpanded(), 'visible': CanHaveChildren }, toggle: IsExpanded, toggleActiveClass: 'expandedNode'"></span>
                    <span class="item-text-label bold text-ellipsis" data-bind="text: Title"></span>
                </div>
                <div class="item-actions">
                    {tableItemActions()}
                </div>
            </div>
            {tableItemChildrenList()}
            {tableItemManualAllocationsChildrenList()}
        </>
    
    return  <div class={classes.allocationsGantt} data-bind="with: $data, as: 'gantt'">
                <div class="gantt-wrapper" data-bind="style: { '--day-width': gantt.DaySizeInPixels() + 'px', '--item-height': gantt.ItemHeight() + 'px' }">
                    <div class="fixedTable" data-bind="template: { data: $data, afterRender: gantt.init.bind(gantt) }">
                        <div class="top-area">
                            <aside class="fixedTable-menu">
                                <div class="actions">
                                    <button class="btn btn-xs btn-primary" data-bind="click: ExpandAll" style="margin-left: 5px;"><i class="fa fa-angle-double-down"></i>&nbsp;Espandi</button>
                                    <button class="btn btn-xs btn-primary" data-bind="click: CollapseAll"><i class="fa fa-angle-double-up"></i>&nbsp;Collassa</button>
                                    <button class="btn btn-xs btn-success" data-bind="click: gantt.ShowLegend.bind(gantt)"><i class="fa fa-info-circle"></i>&nbsp;Legenda</button>
                                    <button class="btn btn-xs btn-success" data-bind="click: gantt.ShowTour.bind(gantt)"><i class="fa fa-book"></i>&nbsp;Tour</button>
                                    <button class="btn btn-xs " data-bind="visible: gantt.CanSeeButtonShowAllTeams, click: gantt.SwitchShowAllTeams.bind(gantt), css: {'btn-success': gantt.ShowAllTeams}"><i class="fa " data-bind="css: {'fa-eye': gantt.ShowAllTeams, 'fa-eye-slash': !gantt.ShowAllTeams()}"></i>&nbsp;Team</button>
                                    
                                </div>
                                <GanttSearch>
                                    <GanttSearchOption title={TextResources.Allocations.GenericGanttSearch} selected={() => "gantt.GenericSearchSelected"} selectedStateSwitcher={() => "gantt.SelectGenericSearchOption.bind(gantt)"}>
                                        <input type="text" style="height: 29px" class="form-control input-sm searchInput" placeholder={TextResources.Allocations.GenericGanttSearchPlaceholder} data-bind="value: SearchFilter, valueUpdate: 'afterkeydown'" />
                                    </GanttSearchOption>
                                    <GanttSearchOption title={TextResources.Allocations.JobOrderGanttSearch} selected={() => "gantt.JobOrderSearchSelected"} selectedStateSwitcher={() => "gantt.SelectJobOrderSearchOption.bind(gantt)"}>
                                        <select2 dataSource={() => "gantt.JobOrdersDataSource"} value={() => "gantt.SelectedJobOrder"} allowClear={true} placeholder={TextResources.Allocations.JobOrderGanttSearchPlaceholder} simple={true} listener={() => "gantt"}></select2>
                                    </GanttSearchOption>
                                </GanttSearch>
                            </aside>
                            <header class="fixedTable-header">
                                <table>
                                    <thead>
                                        <tr data-bind="foreach: Months" class="months-row">
                                            <th class="text-center text-ellipsis" data-bind="text: name, attr: { 'colspan' : days }, style: { '--days-in-month': days }"></th>
                                        </tr>
                                        <tr data-bind="foreach: Dates" class="dates-row">
                                            <th data-bind="text: $data.date(), attr: { 'data-dayofweek': $data.day() }"></th>
                                        </tr>
                                    </thead>
                                </table>
                            </header>
                        </div>
                        <div class="bottom-area">
                            <aside class="fixedTable-sidebar">
                                <div class="items visible" data-bind="foreach: { data: Items, as: 'team' }">
                                    {tableItemTeam()}
                                </div>
                            </aside>
                            <div class="fixedTable-body">
                                <div class="background" style="position: relative" data-bind="style: { '--visible-days' : Dates().length, '--visible-items': VisibleItems(), '--offset-from-sunday': StartDateOffsetFromSunday(), '--background-image': BackgroundImage }">
                                    <ko-foreach data-bind="{ data: Items, as: 'item' }">
                                        <div class="item-container" data-bind="css: { 'selected' : Selected, 'visible': Visible }, click: gantt.Select.bind(gantt)">
                                            <div class="drop-area" data-bind="droppableEx: { allowedMimeTypes: Tag().AllowedGanttMimeTypes, hoverClass: 'droppable-hover', onDrop: Tag().OnCartDropped.bind(Tag(), $data) }">
                                                <ko-foreach data-bind="{ data: Intervals, as: 'item' }">
                                                    <div class="item text-center" data-bind="style : { '--text-color': item.FontColor(), '--color' : item.Color(), '--border-color': item.BorderColor(), '--duration': item.Duration(), '--days-from-start': item.DaysFromStart() }, draggableEx: { CanDrag: gantt.AllowCartsDrag, OnDrag: gantt.OnAllocationDrag.bind(gantt, $data) }, css: { 'draggable': gantt.AllowCartsDrag() }, class: Style">
                                                        <div class="value text-ellipsis" data-bind="text: Value, attr: { title: Value }"></div>
                                                    </div>
                                                </ko-foreach>
                                                <ko-foreach data-bind="item.Markers">
                                                    <div class="marker" data-bind="style: { '--days-from-start': DaysFromStart(), '--text-color': Color }, css: Type, click: OnClickCallback, clickBubble: false, attr: { title: Title }"></div>
                                                </ko-foreach>
                                            </div>
                                        </div>
                                        <div class="item-container" data-bind="css: { visible: item.IsExpanded() && Children().length === 0 }"></div>
                                        <ko-foreach data-bind="{ data: Children, as: 'workflow' }">
                                            <div class="item-container" data-bind="css: { 'selected' : Selected, visible: item.IsExpanded() && Visible() }, click: gantt.Select.bind(gantt)">
                                                <ko-foreach data-bind="{ data: Intervals, as: 'item' }">
                                                    <div class="item text-center" data-bind="style : { '--text-color': item.FontColor(), '--color' : item.Color(), '--border-color': item.BorderColor(), '--duration': item.Duration(), '--days-from-start': item.DaysFromStart() }, css: Style()">
                                                        <span class="value" data-bind="numberText: Value"></span>
                                                    </div>
                                                </ko-foreach>
                                                <ko-foreach data-bind="DetailedAllocations">
                                                    <div class="allocation" data-bind="style: { 'width': (Duration() * gantt.DaySizeInPixels()) + 'px', backgroundColor: Color, left: (DaysFromStart() * gantt.DaySizeInPixels()) + 'px' }"></div>
                                                </ko-foreach>
                                                <ko-foreach data-bind="Markers">
                                                    <div class="marker" data-bind="style: { left: (DaysFromStart() * gantt.DaySizeInPixels()) + 'px', color: Color }, css: Type, click: OnClickCallback, clickBubble: false, attr: { title: Title }"></div>
                                                </ko-foreach>
                                            </div>
                                            <ko-if data-bind="item.IsExpanded() && $data.IsExpanded()">
                                                <ko-foreach data-bind="{ data: Children, as: 'task' }">
                                                    <div class="taskContainer" data-bind="style: { width: (gantt.DaySizeInPixels() * gantt.Dates().length) + 'px', height: gantt.ItemHeight() + 'px' }, visible: workflow.IsExpanded() && Visible(), css: { 'selected' : Selected }, click: gantt.Select.bind(gantt)">
                                                        <div class="task" data-bind="style : { 'color' : task.IsClosed() ? task.DisabledColor() : task.Color(), width: (task.Duration() * gantt.DaySizeInPixels()) + 'px', left: (task.DaysFromStart() * gantt.DaySizeInPixels()) + 'px' }, css: { 'no-estimate' : !HasEstimate() }, css: Style">
                                                            <div class="arrowLeft"></div>
                                                            <div class="thumb start"></div>
                                                            <div class="progress" data-bind="style : { 'width' : (task.Progress() > 100 ? 100 : task.Progress()) + '%', backgroundColor: task.ProgressColor() }"></div>
                                                            <div class="progress-text">
                                                                <span data-bind="percentageText: Math.round(task.Progress())"></span>
                                                                &nbsp;(<span data-bind="numberText: task.WorkDone, numberTextSuffix: 'h/u'"></span>&nbsp;/&nbsp;<span data-bind="numberText: task.EstimatedWork, numberTextSuffix: 'h/u'"></span>)
                                                            </div>
                                                            <div class="thumb end"></div>
                                                            <div class="arrowRight"></div>
                                                        </div>
                                                    </div>
                                                </ko-foreach>
                                            </ko-if>
                                        </ko-foreach>
                                    </ko-foreach>
                                    <div class="today-marker" data-bind="style: { height: (gantt.ItemHeight() * VisibleItems()) + 'px', left: (gantt.DaySizeInPixels() * (DaysFromStartDate() - 1) - 1) + 'px' }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export class GanttComponent {

    constructor(params : IAllocationsGanttComponentParams) {
        
    }
}

ko.components.register("allocations-gantt", {
    viewModel: {
        createViewModel: (params: IAllocationsGanttComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new GanttComponent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <ko-with data-bind="$parent">
                    <Gantt autoHeightParentSelector=".allocations-gantt"></Gantt>
                </ko-with>
            ]);
            
            return vm;
        },
    },
    template: []
});