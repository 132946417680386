import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTemplate } from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";

export class TemplateDisplayName
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options = valueAccessor();
        var todoListService = <ITodoListService> Core.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        var name : ko.Observable<string> = ko.observable(options.defaultDescription || "");
        todoListService.GetTemplate(ko.utils.unwrapObservable(options.id)).then((g : ITodoListTemplate) => {
            if(g)
                name(g.Title);
        });

        var interceptor = ko.computed(() => {
            return name();
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["templateDisplayName"] = new TemplateDisplayName();
ko.virtualElements.allowedBindings["templateDisplayName"] = true;

