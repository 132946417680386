import { ReportRenderer as IReportRenderer, ConstructableReportRenderer } from "./ReportRenderer";
import { ReportComponent, ReportPage } from "../Components";

type ReportRendererMap = {
    [componentType: string] : ConstructableReportRenderer;
}

class _ReportRendererProvider {
    private renderers : ReportRendererMap = {};

    public registerRenderer(componentType: string, rendererFactory : ConstructableReportRenderer) {
        if(this.renderers[componentType])
            console.warn(`A renderer for component "${componentType}" has already been registered`);

        this.renderers[componentType] = rendererFactory;
        console.log(`Registered renderer for component "${componentType}""`);
    }

    public createRenderer(component : ReportComponent) : ConstructableReportRenderer {
        if(!this.renderers[component.type])
            throw `No renderer is registered for component "${component.type}"`;

        return this.renderers[component.type];
    }
}

export var ReportRendererProvider = new _ReportRendererProvider();

export function RendererFor(componentType : string) : ClassDecorator {
    return (constructor : any) => {
        ReportRendererProvider.registerRenderer(componentType, constructor);
    }
}