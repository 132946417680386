import * as ko from "knockout";
export class TouchSpin
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = valueAccessor() || {};
        (<any>$(element)).TouchSpin(options);

        var disableBinding = allBindingsAccessor()["disable"];

        if (disableBinding != undefined) {
            var newBinding = {
                disable: disableBinding,
                css: { disabled: disableBinding }
            };

            var upButton = $(element).parent().find('.bootstrap-touchspin-up');
            if (upButton)
                ko.applyBindingsToNode(upButton[0], newBinding, undefined);

            newBinding = {
                disable: disableBinding,
                css: { disabled: disableBinding }
            };

            var downButton = $(element).parent().find('.bootstrap-touchspin-down');
            if (downButton)
                ko.applyBindingsToNode(downButton[0], newBinding, undefined);
        }
    }

}

ko.bindingHandlers["touchSpin"] = new TouchSpin();