import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/02/2018
 * Time: 14:54
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { FoldersUIProvider } from "./Agenda/ui/FoldersUIProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IFolderService, IFoldersUIProvider, IFolder, IFolderEnvelope } from "../ProlifeSdk/interfaces/agenda/IFolderService";
import { IEventOnDatesIntervalDetails } from "../ProlifeSdk/interfaces/agenda/IEventsService";

export class FolderService extends ProLifeService implements IFolderService {
    private ajaxService: IAjaxService;

    public ui: IFoldersUIProvider;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        
        this.serviceLocator.registerServiceInstance(this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);

        this.ui = new FoldersUIProvider(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.FolderServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return this.getServiceType() == serviceType;
    }

    GetFolders(showDeletedElements: boolean = false): Promise<IFolder[]> {
        return this.ajaxService.Get("Agenda-api", "Folders?showDeletedElements=" + showDeletedElements, {});
    }

    CreateOrUpdate(folder: IFolder): Promise<IFolder> {
        return this.ajaxService.Post("Agenda-api", "Folders", {
            methodData: folder
        })
    }

    Delete(folderId: number): Promise<void> {
        return this.ajaxService.Delete("Agenda-api", "Folders?folderId=" + folderId, {});
    }

    Recovery(folderId: number): Promise<void> {
        return this.ajaxService.Post("Agenda-api", "Folders/Recovery", {
            methodData: {
                FolderId: folderId
            }
        })
    }

    GetFoldersEnvelopesLimitedList(filter: string): Promise<IFolderEnvelope[]> {
        return this.ajaxService.Post("Agenda-api", "Folders/GetFoldersEnvelopesLimitedList", {
            methodData: {
                Filter: filter
            }
        })
    }

    GetFolderEnvelope(folderId: number): Promise<IFolderEnvelope> {
        return this.ajaxService.Post("Agenda-api", "Folders/GetFolderEnvelope", {
            methodData: {
                FolderId: folderId
            }
        })
    }

    GetFolder(folderId: number): Promise<IFolder> {
        return this.ajaxService.Post("Agenda-api", "Folders/GetFolder", {
            methodData: {
                FolderId: folderId
            }
        })
    }

    GetFolderFutureEvents(folderId: number): Promise<IEventOnDatesIntervalDetails[]> {
        return this.ajaxService.Post("Agenda-api", "Folders/GetFolderFutureEvents", {
            methodData: {
                FolderId: folderId
            }
        })
    }
}

export default function Create(serviceLocator : IServiceLocator): IService {
    return new FolderService(serviceLocator);
}