import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 09/05/2016
 * Time: 15:04
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";

export class DhtmlGantt {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var gantt = (<any>$(element)).dhx_gantt({
            /*scale_unit: 'day',
            step: 1,
            duration_unit: 'hour',
            duration_step: 1,
            subscales: [
                { unit: 'month', step: 1, date: "%M" },
                { unit: 'year', step: 1, date: "%Y" },
            ],*/
            start_date: moment().add('year', -1).toDate(),
             /*end_date: moment().add('month', 3).toDate(),*/
            scale_height: 60/*,
            date_scale: '%M %Y'*/
        });

        $(element).data("__gantt__", gantt);

        /*var tasks = ko.utils.unwrapObservable(valueAccessor());
        tasks.forEach(t => {
            if(t.isJobOrder) {
                gantt.addTask({
                    id: "J" + t.Id,
                    text: t.Title,
                    start_date: t.StartDate,
                    end_date: t.EndDate,
                    duration: t.Duration
                });
            } else {
                gantt.addTask({
                    id: "W" + t.Id,
                    text: t.Title,
                    start_date: t.StartDate,
                    end_date: t.EndDate,
                    duration: t.Duration
                }, "J" + t.parentId);
            }
        });*/
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var gantt = $(element).data("__gantt__");
        gantt.clearAll();

        var tasks = ko.utils.unwrapObservable(valueAccessor());
        tasks.forEach(t => {
            if(t.isJobOrder) {
                gantt.addTask({
                    id: "J" + t.Id,
                    text: t.Title,
                    start_date: t.StartDate,
                    end_date: t.EndDate,
                    duration: t.Duration,
                    progress: t.Progress,
                    unscheduled: !t.StartDate || !t.EndDate
                });
            } else {
                gantt.addTask({
                    id: "W" + t.Id,
                    text: t.Title,
                    start_date: t.StartDate,
                    end_date: t.EndDate,
                    duration: t.Duration,
                    progress: t.Progress,
                    unscheduled: !t.StartDate || !t.EndDate
                }, "J" + t.parentId);
            }
        });
    }
}

ko.bindingHandlers["dhtmlGantt"] = new DhtmlGantt();