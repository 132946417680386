import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/03/2018
 * Time: 15:09
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CategoriesProvider } from "../providers/CategoriesProvider";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEventCategory } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IEntityProvider } from "../../../../ProlifeSdk/interfaces/IEntityProvider";
import { ICategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../Core/Deferred";

export class AgendaEventCategoryEntityProvider implements IEntityProvider<number, IEventCategory> {
    private categoriesSettingsManager: ICategoriesSettingsManager;

    constructor(private serviceLocator: IServiceLocator) {
        var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        entityProviderService.registerEntityProvider(this, this.getType());

        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.categoriesSettingsManager = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);
    }

    getType():string {
        return ProlifeSdk.AgendaEventCategoryEntityType;
    }

    getEntityTypeName():string {
        return ProlifeSdk.TextResources.Agenda.CategoriesSettings;
    }

    getPkValue(item: IEventCategory): number {
        return item.Id;
    }

    getDisplayName(item: IEventCategory): string {
        return item.Label;
    }

    getDisplayNameForKeyValue(pkValue: number): Promise<string> {
        var def = new Deferred<string>();
        return def.resolve(this.categoriesSettingsManager.getCategoryById(pkValue).Label).promise();
    }

    hintSearch(stringFilter: string): Promise<IEventCategory[]> {
        var def = new Deferred<IEventCategory[]>();

        var categories = this.categoriesSettingsManager.getCategories();
        var filteredCategories = categories.filter((c: IEventCategory) => c.Label.toLowerCase().indexOf(stringFilter.toLowerCase()) > -1);

        def.resolve(filteredCategories);

        return def.promise();
    }

    getEntity(pkValue: number): Promise<IEventCategory> {
        var def = new Deferred<IEventCategory>();
        return def.resolve(this.categoriesSettingsManager.getCategoryById(pkValue)).promise();
    }

    getControlsProvider():IControlsEntityProvider {
        return new CategoriesProvider(this.serviceLocator);
    }
}