import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { Moment } from "moment";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { WorkingResourceAllocationDialog } from "./WorkingResourceAllocationDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService, IApprovedBudgetHistory, IWorkSpeedHistory } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowSelectorObserver, ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IAllocationsService, IJobOrderAllocationReport, IJobOrderWorkflowAllocationReport, IRemainingHoursForJobOrder, IAllocationsJobOrderReport } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IEstimatedWorkHistory } from "../../../../ProlifeSdk/interfaces/todolist/IEstimatedWorkHistory";
import { IDoneWorkHistory } from "../../../../ProlifeSdk/interfaces/todolist/IDoneWorkHistory";
import { IClosedWorkHistory } from "../../../../ProlifeSdk/interfaces/todolist/IClosedWorkHistory";
import { IRemainingWorkHistory } from "../../../../ProlifeSdk/interfaces/todolist/IRemainingWorkHistory";
import { IWorkingResource } from "../../../../ProlifeSdk/interfaces/todolist/IWorkingResource";
import { IPerformanceDashboardInfo } from "../../../../ProlifeSdk/interfaces/todolist/IPerformanceDashboardInfo";
import { IGraphInfo } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

declare var regression : (method: string, data: any[], degree?: number) => any;
//declare var Highcharts : any;

export class PerformanceDashboard implements IWorkflowSelectorObserver
{
    private todoListService : ITodoListService;
    private dialogsService : IDialogsService;
    private allocationsService : IAllocationsService;

    @LazyImport(ProlifeSdk.JobOrderServiceType)
    private jobOrderService! : IJobOrderService;

    public TotalEstimatedWork : ko.Observable<number> = ko.observable();
    public TotalEstimatedWorkInDays : ko.Observable<number> = ko.observable();
    public TotalDoneWork : ko.Observable<number> = ko.observable();
    public TotalDoneWorkInDays : ko.Observable<number> = ko.observable();
    public TotalClosedWork : ko.Observable<number> = ko.observable();
    public TotalClosedWorkInDays : ko.Observable<number> = ko.observable();
    public TotalComputedWork : ko.Observable<number> = ko.observable();
    public TotalComputedWorkInDays : ko.Observable<number> = ko.observable();
    public TotalRemainingWork : ko.Computed<number>;
    public TotalRemainingWorkInDays : ko.Computed<number>;

    public EstimatedDateOfEnd : ko.Observable<Date> = ko.observable();
    public EstimatedDaysRemaining : ko.Observable<number> = ko.observable();
    public NeededResources : ko.Observable<number> = ko.observable();
    public NeededResourcesPerWeek : ko.Observable<number> = ko.observable();

    public PlannedDateOfEnd : ko.Observable<Date> = ko.observable();
    public PlannedDaysRemaining : ko.Observable<number> = ko.observable();
    public PlannedNeededResources : ko.Observable<number> = ko.observable();
    public PlannedNeededResourcesPerWeek : ko.Observable<number> = ko.observable();

    public OldPlannedDateOfEnd : ko.Observable<Date> = ko.observable();
    public OldPlannedNeededResources : ko.Observable<number> = ko.observable();
    public OldPlannedNeededResourcesPerWeek : ko.Observable<number> = ko.observable();

    public Speed : ko.Observable<number> = ko.observable(1.0);
    public Progress : ko.Observable<number> = ko.observable(0);
    public Delay : ko.Observable<number> = ko.observable(0);

    public EstimatedWorkHistory : ko.ObservableArray<IEstimatedWorkHistory> = ko.observableArray([]);
    public ComputedEstimatedWorkHistory : ko.ObservableArray<IEstimatedWorkHistory> = ko.observableArray([]);
    public DoneWorkHistory : ko.ObservableArray<IDoneWorkHistory> = ko.observableArray([]);
    public AllDoneWorkHistory : ko.ObservableArray<IDoneWorkHistory> = ko.observableArray([]);
    public ClosedWorkHistory : ko.ObservableArray<IClosedWorkHistory> = ko.observableArray([]);
    public RemainingWorkHistory : ko.ObservableArray<IRemainingWorkHistory> = ko.observableArray([]);
    public WorkingResources : ko.ObservableArray<IWorkingResource> = ko.observableArray([]);
    public ApprovedBudgetHistory : ko.ObservableArray<IApprovedBudgetHistory> = ko.observableArray([]);
    public WorkSpeedHistory : ko.ObservableArray<IWorkSpeedHistory> = ko.observableArray([]);

    public LastUpdateDate : ko.Observable<Date> = ko.observable();

    public EstimatedWorkHistoryChart : ko.Computed<Highcharts.Options>;
    public DoneWorkHistoryChart : ko.Computed<Highcharts.Options>;
    public ForecastChart : ko.Computed<Highcharts.Options>;
    public SpeedChart : ko.Computed<Highcharts.Options>;
    public ProgressChart : ko.Computed<Highcharts.Options>;
    public DelayChart : ko.Computed<Highcharts.Options>;

    public AllocationsReport : ko.ObservableArray<IJobOrderAllocationReport> = ko.observableArray([]);
    public WorkflowAllocationsReport : ko.ObservableArray<IJobOrderWorkflowAllocationReport> = ko.observableArray([]);

    public RemainingAllocatedHours : ko.Observable<any> = ko.observable();

    private SelectedWorkflow : ko.Observable<number> = ko.observable();

    private disableRefresh : boolean = false;
    private jobOrderId : number = -1;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.allocationsService = <IAllocationsService> this.serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);

        this.EstimatedWorkHistoryChart = ko.computed(() => {
            return this.createGraphConfiguration('#92bbdd', [
                {
                    name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.InternalAuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#f7a35c')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#f7a35c')).setOpacity(0.5).get('rgba')
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.AuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#90ed7d')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#90ed7d')).setOpacity(0.5).get('rgba')
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.OtherAuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#7cb5ec')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#7cb5ec')).setOpacity(0.5).get('rgba')
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.EstimatedWork,
                    data: this.EstimatedWorkHistory().map((h : IEstimatedWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.EstimatedWork
                        ];
                    })
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.ClosedWork,
                    data: this.ClosedWorkHistory().map((h : IClosedWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.ClosedWork
                        ];
                    })
                }
            ]);
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.DoneWorkHistoryChart = ko.computed(() => {
            return this.createGraphConfiguration('#dba2a2', [
                {
                    name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.InternalAuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#f7a35c')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#f7a35c')).setOpacity(0.5).get('rgba'),
                    zIndex: 1
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.AuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#90ed7d')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#90ed7d')).setOpacity(0.5).get('rgba'),
                    zIndex: 2
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
                    data: this.ApprovedBudgetHistory().map((h : IApprovedBudgetHistory) => {
                        return [
                            <any>moment(h.ApprovedDate).valueOf(),
                            <any>h.OtherAuthorizedHours
                        ];
                    }),
                    step: 'left',
                    type: 'area',
                    stack: 'authorized',
                    stacking: 'normal',
                    color: (<Highcharts.Gradient>Highcharts.Color('#7cb5ec')).setOpacity(0.5).get('rgba'),
                    fillColor: (<Highcharts.Gradient>Highcharts.Color('#7cb5ec')).setOpacity(0.5).get('rgba'),
                    zIndex: 3
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.ComputedEstimatedWork,
                    data: this.ComputedEstimatedWorkHistory().map((h : IEstimatedWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.EstimatedWork
                        ];
                    }),
                    zIndex: 5
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.DoneWork,
                    data: this.DoneWorkHistory().map((h:IDoneWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.DoneWork
                        ]
                    }),
                    zIndex: 6
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.EstimatedWork,
                    dashStyle: 'LongDash',
                    data: this.EstimatedWorkHistory().map((h:IEstimatedWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.EstimatedWork
                        ]
                    }),
                    zIndex: 7
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.Speed,
                    yAxis: 1,
                    data: this.WorkSpeedHistory().map((h:IWorkSpeedHistory) => {
                        return [
                            <any>moment(h.Day).valueOf(),
                            <any>h.Speed
                        ]
                    }),
                    zIndex: 8
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.AllDoneWork,
                    data: this.AllDoneWorkHistory().map((h:IDoneWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.DoneWork
                        ]
                    }),
                    zIndex: 4
                }
            ], [
                {
                    title: {
                        text: ProlifeSdk.TextResources.JobOrder.Worked
                    }
                },
                {
                    title: {
                        text: ProlifeSdk.TextResources.JobOrder.Speed
                    },
                    opposite: false
                }
            ]);
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.ForecastChart = ko.computed(() => {
            var startDate = moment().startOf('day').add(-2, 'weeks');
            var endDate = moment().startOf('day').add(-1, 'day').endOf('day');

            var forecastSerie = [];
            var manualForecastSerie = [];
            var meanHoursADaySerie = [];
            var meanHoursADayForecastSerie = [];

            var doneWork = this.DoneWorkHistory().filter((h : IDoneWorkHistory) => moment(h.currentDate) <= endDate && moment(h.currentDate) >= startDate);
            var outOfRange = this.DoneWorkHistory().filter((h : IDoneWorkHistory) => moment(h.currentDate) < startDate);
            var lastKnownWork = 0;

            if(this.DoneWorkHistory().length != 0) {
                lastKnownWork = this.DoneWorkHistory()[this.DoneWorkHistory().length - 1].DoneWork;
            }

            var previousWork = 0;
            if(outOfRange.length > 0) {
                previousWork = outOfRange[outOfRange.length - 1].DoneWork;
            }

            if(doneWork.length == 0 && outOfRange.length > 0) {
                doneWork.push(outOfRange[outOfRange.length - 1]);
            }

            if(doneWork.length != 0) {
                var allDoneWork = this.DoneWorkHistory();
                var previousDoneWork = 0;

                var firstDay = moment(allDoneWork[0].currentDate).startOf('day');
                var lastDay = moment(allDoneWork[allDoneWork.length - 1].currentDate).startOf('day');
                var indexedAllDoneWork = {};

                allDoneWork.forEach(w => indexedAllDoneWork[moment(w.currentDate).startOf('day').valueOf()] = w.DoneWork);

                var canEndOnSaturday = false;
                var canEndOnSunday = false;

                for(var day = firstDay; firstDay.valueOf() <= lastDay.valueOf(); day = day.add('day', 1))
                {
                    var workInDay = indexedAllDoneWork[day.valueOf()];//allDoneWork.filter(w => moment(w.currentDate).startOf('day').valueOf() == day.valueOf());
                    var work = (workInDay ? workInDay - previousDoneWork : 0);

                    meanHoursADaySerie.push([
                        <any>day.valueOf(),
                        <any>work
                    ]);
                    if(workInDay) {
                        previousDoneWork = workInDay;
                    }

                    if(work > 0) {
                        if(day.day() == 6)
                            canEndOnSaturday = true;
                        else if(day.day() == 0)
                            canEndOnSunday = true;
                    }
                }

                /*for(var i = 0; i < allDoneWork.length; i++) {
                    meanHoursADaySerie.push([
                        <any>moment(allDoneWork[i].currentDate).valueOf(),
                        <any>(allDoneWork[i].DoneWork - previousDoneWork)
                    ]);
                    previousDoneWork = allDoneWork[i].DoneWork;
                }*/

                var lastWorkDay = doneWork[doneWork.length - 1];
                var doneWorkInRange = lastWorkDay.DoneWork - previousWork;

                if (previousWork == 0) {
                    startDate = moment(doneWork[0].currentDate).startOf('day');
                }

                var computedEstimate = this.ComputedEstimatedWorkHistory();
                if(computedEstimate.length != 0) {
                    var lastEstimate = computedEstimate[computedEstimate.length - 1].EstimatedWork;
                    var remainingWork = lastEstimate - lastKnownWork;

                    var dailyWork = doneWorkInRange / moment.duration(<any>endDate - <any>startDate).asDays();

                    var remainingDays = remainingWork / dailyWork;

                    if(dailyWork == 0)
                        remainingDays = moment.duration(3, 'month').asDays();

                    var startDate = moment(moment().format());

                    forecastSerie = this.computeForecastSerie(startDate, remainingDays, remainingWork, dailyWork, canEndOnSaturday, canEndOnSunday, false);
                    var maxAllocEndDate = null;
                    if (this.AllocationsReport().length > 0) {
                        this.AllocationsReport().forEach((r: IJobOrderAllocationReport) => {
                            var reportDate = r.RealLastAllocatedDate > r.TheoreticalLastAllocatedDate
                                ? r.RealLastAllocatedDate
                                : r.TheoreticalLastAllocatedDate;
                            maxAllocEndDate = !!maxAllocEndDate && moment(maxAllocEndDate) < moment(reportDate) ? reportDate : maxAllocEndDate;
                        });
                    }

                    var manualEndDay = forecastSerie[forecastSerie.length - 1][0];
                    if (maxAllocEndDate && maxAllocEndDate > manualEndDay)
                        manualEndDay = maxAllocEndDate;

                    if(this.WorkingResources().length != 0) {
                        var meanDailyWork = 0.0;
                        this.WorkingResources().forEach((wr : IWorkingResource) => {
                            meanDailyWork += wr.MeanHoursADay;
                        });

                        if(moment(this.OldPlannedDateOfEnd()).startOf('day').valueOf() != moment(this.PlannedDateOfEnd()).startOf('day').valueOf()) {
                            //L'utente ha modificato la data
                            var availableDays = moment.duration(<any>moment(this.PlannedDateOfEnd()) - <any>moment().startOf('day')).asDays();
                            if(availableDays > 0) {
                                this.PlannedNeededResources(((remainingWork / (availableDays * (5.0/7.0))) * 5.0) / 7.0);
                                this.PlannedNeededResourcesPerWeek(this.PlannedNeededResources() * 7.0);
                            }
                        } else if(this.OldPlannedNeededResourcesPerWeek() != this.PlannedNeededResourcesPerWeek()) {
                            //L'utente ha modificato le risorse
                        }

                        var plannedDailyWork = (this.PlannedNeededResourcesPerWeek() / 7.0) || ((meanDailyWork * 5.0) / 7.0);
                        var plannedRemainingDays = plannedDailyWork == 0 ? 0 : remainingWork / plannedDailyWork;
                        manualForecastSerie = this.computeForecastSerie(startDate, plannedRemainingDays, remainingWork, plannedDailyWork, canEndOnSaturday, canEndOnSunday, false);

                        var computedEndDay = manualForecastSerie[manualForecastSerie.length - 1][0];

                        meanHoursADayForecastSerie.push(meanHoursADaySerie[meanHoursADaySerie.length - 1]);

                        for(var i = 0; i < remainingDays; i++) {
                            meanHoursADayForecastSerie.push([
                                <any>moment().utc().startOf('day').add(i, 'days').valueOf(),
                                plannedDailyWork
                            ]);
                        }

                        meanHoursADayForecastSerie.push([
                            <any>moment().utc().startOf('day').add(Math.ceil(remainingDays), 'days').valueOf(),
                            plannedDailyWork
                        ]);
                    }

                    var plannedWorkSerie = [];
                    var theoreticalWorkSerie = [];
                    var realLastAllocDate = null;
                    var theoreticalLastAllocDate = null;
                    var unallocatedWork = 0;

                    if (this.RemainingAllocatedHours())
                        unallocatedWork = remainingWork - (<IRemainingHoursForJobOrder>this.RemainingAllocatedHours()).RealAllocatedHours;

                    this.AllocationsReport().forEach((rep : IJobOrderAllocationReport) => {
                        if(realLastAllocDate == null)
                            realLastAllocDate = rep.RealLastAllocatedDate;
                        else {
                            if (rep.RealLastAllocatedDate && moment(rep.RealLastAllocatedDate) > moment(realLastAllocDate)) {
                                realLastAllocDate = rep.RealLastAllocatedDate;
                            }
                        }
                    });

                    this.AllocationsReport().forEach((rep : IJobOrderAllocationReport) => {
                        if(theoreticalLastAllocDate == null)
                            theoreticalLastAllocDate = rep.TheoreticalLastAllocatedDate;
                        else {
                            if (rep.TheoreticalLastAllocatedDate && moment(rep.TheoreticalLastAllocatedDate) > moment(theoreticalLastAllocDate)) {
                                theoreticalLastAllocDate = rep.TheoreticalLastAllocatedDate;
                            }
                        }
                    });

                    if(realLastAllocDate) {
                        var days = moment.duration(<any>moment(realLastAllocDate) - <any>moment().utc().startOf('day').valueOf()).asDays();
                        var dailyWorkForAlloc = (<IRemainingHoursForJobOrder>this.RemainingAllocatedHours()).RealAllocatedHours / days;

                        plannedWorkSerie = this.computeForecastSerie(startDate, days, remainingWork, dailyWorkForAlloc, false, false, true);
                    }

                    if (!theoreticalLastAllocDate && unallocatedWork > 0) {
                        plannedWorkSerie.push([
                            <any>moment(manualEndDay).valueOf(),
                            unallocatedWork
                        ]);
                    }

                    var remainingWorkAfterTheoreticalAlloc = 0;
                    if (this.RemainingAllocatedHours())
                        remainingWorkAfterTheoreticalAlloc = unallocatedWork - (<IRemainingHoursForJobOrder>this.RemainingAllocatedHours()).TheoreticalAllocatedHours;

                    if(theoreticalLastAllocDate) {
                        var theoreticalStartDate = realLastAllocDate ? realLastAllocDate : startDate;
                        var days = moment.duration(<any>moment(theoreticalLastAllocDate) - <any>moment(theoreticalStartDate)).asDays();
                        var dailyWorkForAlloc = (<IRemainingHoursForJobOrder>this.RemainingAllocatedHours()).TheoreticalAllocatedHours / days;

                        theoreticalWorkSerie = this.computeForecastSerie(theoreticalStartDate, days, unallocatedWork, dailyWorkForAlloc, false, false, true);
                    }

                    var remainedWorkSerie = [];

                    if (remainingWorkAfterTheoreticalAlloc > 0) {
                        var days = moment.duration(<any>moment(manualEndDay) - <any>moment(theoreticalLastAllocDate)).asDays();
                        remainedWorkSerie = this.computeForecastSerie(theoreticalLastAllocDate, days, remainingWorkAfterTheoreticalAlloc, 0, false, false, true);
                        //theoreticalWorkSerie.concat(remainedWorkSerie);
                    }

                    if(dailyWork == 0) {
                        this.EstimatedDateOfEnd(null);
                        this.EstimatedDaysRemaining(null);
                        this.NeededResources(null);
                        this.NeededResourcesPerWeek(null);
                    } else {
                        this.EstimatedDateOfEnd(moment(manualEndDay).toDate());//moment().startOf('day').add(Math.ceil(remainingDays), 'days').toDate());
                        this.EstimatedDaysRemaining(remainingDays);
                        this.NeededResources(dailyWork);
                        this.NeededResourcesPerWeek(dailyWork * 7.0);
                    }

                    this.PlannedDateOfEnd(moment(computedEndDay).toDate());//moment().startOf('day').add(Math.ceil(plannedRemainingDays), 'days').toDate());
                    this.PlannedDaysRemaining(plannedRemainingDays);
                    this.PlannedNeededResources(plannedDailyWork);
                    this.PlannedNeededResourcesPerWeek(plannedDailyWork * 7.0);

                    this.OldPlannedDateOfEnd(this.PlannedDateOfEnd());
                    this.OldPlannedNeededResources(this.PlannedNeededResources());
                    this.OldPlannedNeededResourcesPerWeek(this.PlannedNeededResourcesPerWeek());
                }
            }
            else
            {
                //Non si fa la previsione perchè non c'è lavoro nel range
            }

            return this.createGraphConfiguration('#71e8de', [
                {
                    name: ProlifeSdk.TextResources.JobOrder.RemainingWork,
                    data: this.RemainingWorkHistory().map((h : IRemainingWorkHistory) => {
                        return [
                            <any>moment(h.currentDate).valueOf(),
                            <any>h.RemainingWork
                        ];
                    })
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.Forecast,
                    data: forecastSerie,
                    dashStyle: 'LongDash',
                    color: '#409d4c'
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.PlannedForecast,
                    data: manualForecastSerie,
                    dashStyle: 'LongDash',
                    color: '#e87e04'
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.MeanWorkADay,
                    data: meanHoursADaySerie,
                    color: '#000000',
                    lineWidth: 1,
                    yAxis: 1,
                    step: 'center'

                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.MeanWorkADayForecast,
                    data: meanHoursADayForecastSerie,
                    color: '#000000',
                    dashStyle: 'LongDash',
                    lineWidth: 1,
                    yAxis: 1,
                    step: 'center'
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.PlannedWorkForecast,
                    data: plannedWorkSerie,
                    dashStyle: 'LongDash',
                    color: '#3030FF'
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.TheoreticalWorkForecast,
                    data: theoreticalWorkSerie,
                    dashStyle: 'ShortDot',
                    color: '#69AA99'
                },
                {
                    name: ProlifeSdk.TextResources.JobOrder.RemainedUnallocatedWorkForecast,
                    data: remainedWorkSerie,
                    dashStyle: 'ShortDot',
                    color: '#FF0000'
                }
            ], [
                {
                    title: {
                        text: ProlifeSdk.TextResources.JobOrder.RemainingWork
                    }
                },
                {
                    title: {
                        text: ProlifeSdk.TextResources.JobOrder.MeanWorkADay
                    },
                    opposite: false
                }
            ]);
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.SpeedChart = ko.computed(() => {
            return this.createGaugeConfiguration(ProlifeSdk.TextResources.JobOrder.Speed, 0, 2, 'white', [{
                from: 0,
                to: 0.8,
                color: '#DF5353'
            }, {
                from: 0.8,
                to: 0.9,
                color: '#DDDF0D'
            }, {
                from: 0.9,
                to: 1.2,
                color: '#55BF3B'
            }, {
                from: 1.2,
                to: 1.5,
                color: '#DDDF0D'
            }, {
                from: 1.5,
                to: 2.0,
                color: '#DF5353'
            }], [parseFloat(this.Speed().toFixed(3))], '{y}');
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.ProgressChart = ko.computed(() => {
            return this.createGaugeConfiguration(ProlifeSdk.TextResources.JobOrder.Progress, 0, 100, 'white', [{
                from: 0,
                to: 100,
                color: '#9dccf2'
            }/*, {
                from: 60,
                to: 80,
                color: '#DDDF0D'
            }, {
                from: 80,
                to: 100,
                color: '#55BF3B'
            }*/], [parseFloat(this.Progress().toFixed(2))], '{y} %');
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.DelayChart = ko.computed(() => {
            return this.createGaugeConfiguration(ProlifeSdk.TextResources.JobOrder.Delay, -100, 100, 'white', [/*{
                from: -100,
                to: 0,
                color: '#55BF3B'
            }, {
                from: 0,
                to: 30,
                color: '#DDDF0D'
            }, {
                from: 30,
                to: 100,
                color: '#DF5353'
            }*/
                {
                    from: -100,
                    to: -33.3333333333333,
                    color: '#DF5353'
                }, {
                    from: -33.3333333333333,
                    to: -16,
                    color: '#DDDF0D'
                }, {
                    from: -16,
                    to: 11.11111111111111,
                    color: '#55BF3B'
                }, {
                    from: 11.11111111111111,
                    to: 25,
                    color: '#DDDF0D'
                }, {
                    from: 25,
                    to: 100,
                    color: '#DF5353'
                }
            ], [parseFloat(this.Delay().toFixed(2))], '{y} %');
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.TotalRemainingWork = ko.computed(() => {
            var computedEstimateHistory = this.ComputedEstimatedWorkHistory();
            var lastEstimate : IEstimatedWorkHistory = computedEstimateHistory[computedEstimateHistory.length - 1] || { currentDate: new Date(), EstimatedWork: 0 };
            var totalDoneWork = this.TotalDoneWork() || 0;

            return lastEstimate.EstimatedWork - totalDoneWork;
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.TotalRemainingWorkInDays = ko.computed(() => {
            return this.TotalRemainingWork() / 8.0;
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });
    }

    private computeForecastSerie(startDate: Moment, remainingDays : number, remainingWork : number, dailyWork : number, canEndOnSaturday : boolean, canEndOnSunday : boolean, realAlloc: boolean)
    {
        var forecastSerie = [];
        if (!startDate)
            return forecastSerie;

        var momentDate = !startDate ? moment() : moment(startDate);

        var regExp: RegExp = new RegExp("([+-][0-9]{2}:[0-9]{2})", "gi");
        var timezone = regExp.exec(startDate.format());
        var timezoneValue = 0;
        if (timezone) {
            var elem = <string>(timezone[0]).split(":")[0];
            timezoneValue = parseInt(elem);
            if (timezoneValue && timezoneValue > 0)
                momentDate.add(timezoneValue, 'hours');
            if (timezoneValue && timezoneValue < 0)
                momentDate.subtract('hours', timezoneValue);
        }

        for(var i = 0; i < remainingDays; i++) {
            forecastSerie.push([
                <any>moment(startDate).utc().startOf('day').add(i, 'days').valueOf(),
                <any>remainingWork - (dailyWork * i)
            ]);
        }

        if(dailyWork == 0) {
            forecastSerie.push([
                <any>moment(startDate).utc().startOf('day').add(Math.ceil(remainingDays), 'days').valueOf(),
                <any>remainingWork
            ]);
        } else {
            var endDay = moment(startDate).utc().startOf('day').add(Math.ceil(remainingDays), 'days');
            if (!realAlloc) {
                if (endDay.day() == 6 && !canEndOnSaturday)
                    endDay = endDay.add(1, 'days');
                if (endDay.day() == 0 && !canEndOnSunday)
                    endDay = endDay.add(1, 'days');

                forecastSerie.push([
                    <any>endDay.valueOf(),
                    <any>0
                ]);
            } else {
                forecastSerie.push([
                    <any>endDay.valueOf(),
                    <any>remainingWork - (dailyWork * remainingDays)
                ]);
            }
        }

        return forecastSerie;
    }

    public LoadData(jobOrderId : number)
    {
        this.jobOrderId = jobOrderId;

        this.EstimatedWorkHistory([]);
        this.ComputedEstimatedWorkHistory([]);
        this.DoneWorkHistory([]);
        this.RemainingWorkHistory([]);
        this.ClosedWorkHistory([]);
        this.WorkingResources([]);

        this.TotalComputedWork(0);
        this.TotalComputedWorkInDays(0);
        this.TotalEstimatedWork(0);
        this.TotalEstimatedWorkInDays(0);
        this.TotalDoneWork(0);
        this.TotalDoneWorkInDays(0);
        this.Speed(0);
        this.Progress(0);
        this.Delay(0);

        this.todoListService.GetEstimatedWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IEstimatedWorkHistory[]) => {
                this.EstimatedWorkHistory(result);
            });

        this.todoListService.GetComputedEstimatedWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IEstimatedWorkHistory[]) => {
                this.ComputedEstimatedWorkHistory(result);

                if(this.ComputedEstimatedWorkHistory().length > 0) {
                    this.TotalComputedWork(this.ComputedEstimatedWorkHistory()[this.ComputedEstimatedWorkHistory().length - 1].EstimatedWork);
                    this.TotalComputedWorkInDays(this.TotalComputedWork() / 8.0);
                } else {
                    this.TotalComputedWork(0);
                    this.TotalComputedWorkInDays(0);
                }
            });

        this.todoListService.GetDoneWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IDoneWorkHistory[]) => {
                this.DoneWorkHistory(result);
            });

        this.todoListService.GetAllDoneWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IDoneWorkHistory[]) => {
                this.AllDoneWorkHistory(result);
            });

        this.todoListService.GetRemainingWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IRemainingWorkHistory[]) => {
                this.RemainingWorkHistory(result);
            });

        this.todoListService.GetClosedWorkHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IClosedWorkHistory[]) => {
                this.ClosedWorkHistory(result);

                if(this.ClosedWorkHistory().length > 0) {
                    this.TotalClosedWork(this.ClosedWorkHistory()[this.ClosedWorkHistory().length - 1].ClosedWork);
                    this.TotalClosedWorkInDays(this.TotalClosedWork() / 8.0);
                } else {
                    this.TotalClosedWork(0);
                    this.TotalClosedWorkInDays(0);
                }
            });

        this.todoListService.GetPerformanceDashboardInfo(jobOrderId, this.SelectedWorkflow())
            .then((result : IPerformanceDashboardInfo) => {
                if(!result) {
                    this.TotalEstimatedWork(0);
                    this.TotalEstimatedWorkInDays(0);
                    this.TotalDoneWork(0);
                    this.TotalDoneWorkInDays(0);
                    this.Speed(0);
                    this.Progress(0);
                    this.Delay(0);
                } else {
                    this.TotalEstimatedWork(result.TotalEstimatedWork);
                    this.TotalEstimatedWorkInDays(result.TotalEstimatedWork / 8.0);
                    this.TotalDoneWork(result.TotalDoneWork);
                    this.TotalDoneWorkInDays(result.TotalDoneWork / 8.0);
                    this.Speed(result.Speed);
                    this.Progress(result.Progress);
                    this.Delay(result.Delay);
                }
            });

        this.todoListService.GetWorkingResources(jobOrderId, this.SelectedWorkflow())
            .then((result : IWorkingResource[]) => {
                this.WorkingResources(result);
            });

        this.todoListService.GetPerformanceUpdateState(jobOrderId)
            .then((lastUpdateDate : Date) => {
                this.LastUpdateDate(lastUpdateDate);
            });

        this.todoListService.GetApprovedBudgetHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IApprovedBudgetHistory[]) => {
                this.ApprovedBudgetHistory(result);
            });

        this.todoListService.GetWorkSpeedHistory(jobOrderId, this.SelectedWorkflow())
            .then((result : IWorkSpeedHistory[]) => {
                this.WorkSpeedHistory(result);
            });

        this.allocationsService.getJobOrderReport(jobOrderId)
            .then((result : IAllocationsJobOrderReport) => {
                this.AllocationsReport(result.AllocationsReport);
                this.WorkflowAllocationsReport(result.WorkflowAllocationsReport);
            });

        this.allocationsService.getRemainingHoursForJobOrder(jobOrderId)
            .then((remainingHours: IRemainingHoursForJobOrder) => {
                this.RemainingAllocatedHours(remainingHours);
            });
    }

    OnWorkflowSelectionChanged(selection: number[]) {
        this.SelectedWorkflow(selection && selection.length > 0 ? selection[0] : null);
        //this.LoadData(this.jobOrderId);
    }

    OnWorkflowDeleted(workflowId: number) {

    }

    OnWorkflowChanged(workflowId: number) {

    }

    OnWorkflowCreated(workflowId: number) {

    }

    OnWorkflowImportedFromTemplate(workflowId: number) {

    }

    OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    OpenWorkingResourceAllocationDialog(resource : IWorkingResource) {
        var dialog = new WorkingResourceAllocationDialog(this.serviceLocator, resource);
        this.dialogsService.ShowModal<void>(dialog, "large", { noPrompt: true });
    }

    private createGraphConfiguration(color : string, data: IGraphInfo[], yAxis? : Highcharts.AxisOptions[]): Highcharts.Options
    {
        var config : Highcharts.Options = {
            /*chart: {
                type: 'line',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                backgroundColor : null,
                borderColor : null
                //marginTop: 30,
                //spacing: [0,0,0,0],
                // width: 200
            },*/
            credits:{
                enabled: false
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            legend : {
                enabled: false
            },
            tooltip: {
                animation: true,
                valueDecimals: 2,
                valueSuffix: ' h/u',
                xDateFormat: '%d/%m/%Y'
            },
            /*plotOptions: {
                line: {
                    getExtremesFromAll: true
                }
            },*/
            series: data,
            xAxis: <any>{
                //visible: true,
                type: "linear",
                ordinal: false
                /*labels: {
                    formatter: function() {
                        return moment(<any>this.value).format("DD MMM")
                    }
                }*/
            }/*,
            yAxis: {
                visible: true,
                title: {
                    text: null
                },
                maxPadding: 0.1
            }*/
        };

        if(yAxis) {
            config.yAxis = yAxis;
        }

        return config;
    }

    private createGaugeConfiguration(serieName : string, min: number, max: number, labelsColor : string, ranges: any[], data: any[], labelFormat : string): Highcharts.Options
    {
        return {
            chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                backgroundColor : null,
                borderColor : null
                //spacing: [0,0,0,0],
                //width: 200
            },
            pane: {
                startAngle: -150,
                endAngle: 150,
                background: [
                {
                    backgroundColor: null,
                    borderWidth: 0
                },
                {
                    backgroundColor: null,
                    borderWidth: 0
                },
                {
                    backgroundColor: null,
                    borderWidth: 0
                },
                {
                    backgroundColor: null,
                    borderWidth: 0
                }]
            },
            credits:{
                enabled: false
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            legend : {
                enabled: false
            },
            tooltip: {
                animation: true,
                valueDecimals: 2,
                enabled: false
            },
            plotOptions: {
                gauge: <any>{
                    borderWidth: 0,
                    pointPadding: 0.1,
                    groupPadding: 0.01,
                    overshoot: 10,
                    dial: {
                        backgroundColor: labelsColor,
                        borderWidth: 0
                    },
                    dataLabels: {
                        color: labelsColor,
                        borderWidth: 0,
                        enabled: false,
                        style: <any>{
                            "color": labelsColor,
                            "fontSize": "14px",
                            "fontWeight": "normal",
                            "textShadow": "none"
                        },
                        format: labelFormat
                    },
                    pivot: {
                        backgroundColor: labelsColor
                    }
                },
                series: <any>{
                    pointPadding: 0.1,
                    groupPadding: 0.01
                }
            },
            series: [{
                name: serieName,
                data: data,
                type: 'gauge'
            }],
            xAxis: {
                visible: false
            },
            yAxis: {
                min: min,
                max: max,

                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 0,//5,
                minorTickPosition: 'outside',
                minorTickColor: labelsColor,

                tickPixelInterval: 30,
                tickWidth: 1,
                tickPosition: 'outside',
                tickLength: 5,
                tickColor: labelsColor,
                labels: {
                    enabled: true,
                    distance: 7,
                    step: 2,
                    rotation: <any>'auto',
                    style: {
                        color: labelsColor
                    }
                },
                title: {
                    text: ''
                },
                plotBands: ranges.map((r : any) => {
                    r.thickness = 5;
                    return r;
                })
            }
        };
    }

    public RefreshPerformance() {
        this.dialogsService.LockUI("Ricalcolo performance in corso...");
        this.jobOrderService.RefreshPerformance(this.jobOrderId)
            .then(() => {
                this.dialogsService.UnlockUI();
                this.LoadData(this.jobOrderId);
            })
            .catch(() => {
                this.dialogsService.UnlockUI();
            });
    }
}
