import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IService } from "../../Core/interfaces/IService";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IApplicationsService } from "../interfaces/IApplicationsService";
import { IUserInfoObserver } from "../../ProlifeSdk/interfaces/desktop/IUserInfoObserver";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICompanyForUser, IUserForClient } from "../../Core/interfaces/IPrincipal";

export class UserInfoService implements IUserInfo {
    public fullname: ko.Observable<string> = ko.observable("");
    public companies: ko.ObservableArray<ICompanyForUser> = ko.observableArray([]);

    private authenticationService: IAuthenticationService;
    private authorizationService: IAuthorizationService;
    private appsService: IApplicationsService;
    private user: IUserForClient;
    private currentCompany: string;

    private observers: IUserInfoObserver[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IUserInfo>(), this);
    }

    InitializeService() {
        this.authenticationService = this.serviceLocator.findService<IAuthenticationService>(
            ServiceTypes.Authentication
        );
        this.authorizationService = this.serviceLocator.findService<IAuthorizationService>(ServiceTypes.Authorization);
        this.appsService = this.serviceLocator.findService<IApplicationsService>(ProlifeSdk.ApplicationsServiceType);
    }

    getServiceType(): string {
        return ProlifeSdk.UserInfoServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    addObserver(observer: IUserInfoObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IUserInfoObserver): void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    logout() {
        this.authenticationService.logoutUser();
    }

    private issueSettingsReload() {
        const settings = this.serviceLocator.findService<ISettingsService>(ProlifeSdk.SettingsServiceType);
        if (settings) settings.reload();
    }

    public update(user: IUserForClient) {
        if (!user) {
            this.reset();
            return;
        }

        this.user = user;
        this.fullname(this.user.Name + " " + this.user.Surname);
        this.companies(this.user.Companies);
        this.currentCompany = this.user.CurrentCompanyGuid;

        this.authorizationService.refreshAuthorization();
        this.appsService.refreshApplicationsConfigurations();
        this.issueSettingsReload();
    }

    public getIdUser(): number {
        if (!this.user) return 0;
        return this.user.UserId;
    }

    public reset() {
        this.fullname("");
        this.companies([]);
        this.user = null;
    }

    public getCompanies(): ICompanyForUser[] {
        return this.companies();
    }

    public hasUser(): boolean {
        return !!this.user;
    }

    public getDefaultCompanyGuid(): string {
        return this.user.DefaultCompanyGuid;
    }

    public getDefaultCompany(): ICompanyForUser {
        return this.getCompanyByGuid(this.user.DefaultCompanyGuid);
    }

    public getCurrentCompanyGuid(): string {
        return this.currentCompany;
    }

    public getCurrentCompany(): ICompanyForUser {
        return this.getCompanyByGuid(this.currentCompany);
    }

    public getCompanyByGuid(guid: string): ICompanyForUser {
        return this.companies().find((company) => {
            return company.CompanyGuid == guid;
        });
    }

    public getUser() {
        return this.user;
    }

    public getUserName(): string {
        return this.user.Name + " " + this.user.Surname;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new UserInfoService(serviceLocator);
}
