/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 12/09/2017
 * Time: 12:38
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { TeamsFirstRenderer } from "./TeamsFirstRenderer";
import { ResourcesFirstRenderer } from "./ResourcesFirstRenderer";
import { TeamAllocationViewModel } from "./TeamAllocationViewModel";
import { TeamViewModel } from "./TeamViewModel";
import { ResourceViewModel, ITeamsManagerProvider, IResourceOperationalUnitViewModel, ITeamViewModel, ITeamAllocationViewModel } from "./TeamsManager";
import { PlannerGanttItemInterval } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { PlannerGantt } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGantt";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IAllocationsService } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IGanttWrapper } from "../../interfaces/IGanttWrapper";
import { IAllocationSetting } from "../../interfaces/IAllocationSetting";

export class TeamsGantt implements IGanttWrapper {
    Gantt : PlannerGantt;
    TeamsFirstRenderer : TeamsFirstRenderer;
    ResourcesFirstRenderer : ResourcesFirstRenderer;

    public TeamsFirstRendererEnabled : ko.Observable<boolean> = ko.observable(false);
    public ResourcesFirstRendererEnabled : ko.Observable<boolean> = ko.observable(false);

    public ResourcesDailyHoursLimit: ko.Observable<number> = ko.observable(8);

    private currentRenderer : TeamsFirstRenderer | ResourcesFirstRenderer;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    private allocationRangeSelectorInterceptor: ko.Computed<void>;

    constructor(private teamsManagerProvider : ITeamsManagerProvider, private selectedTeam : ko.Observable<TeamViewModel>) {
        this.allocationsService.getSetting("ResourcesDailyHoursLimit")
            .then((setting: IAllocationSetting) => {
                const limit: number = !setting ? 8 : parseFloat(setting.Value);
                this.ResourcesDailyHoursLimit(limit);
            });

        this.Gantt = new PlannerGantt();
        this.Gantt.SearchInChildren(true);
        this.Gantt.AllowDragAndDrop(false);

        this.ResourcesFirstRenderer = new ResourcesFirstRenderer(this);
        this.TeamsFirstRenderer = new TeamsFirstRenderer(this);

        this.currentRenderer = this.ResourcesFirstRenderer;
        this.ResourcesFirstRendererEnabled(true);

        /* this.teamsManagerProvider.OnTeamsLoaded.Add(this.OnTeamsLoaded.bind(this));
        this.teamsManagerProvider.OnTeamCreated.Add(this.OnTeamCreated.bind(this));
        this.teamsManagerProvider.OnTeamDeleted.Add(this.OnTeamDeleted.bind(this));
        this.teamsManagerProvider.OnAfterTeamsManagerChanged.Add(this.OnTeamsLoaded.bind(this)); */

        this.allocationRangeSelectorInterceptor = ko.computed(() => {
            if (!this.selectedTeam())
                return;
            const selectedAlloc: ITeamAllocationViewModel = this.selectedTeam().SelectedAllocation();
            if (!selectedAlloc)
                return;
            const selectedOpUnit: IResourceOperationalUnitViewModel = selectedAlloc.SelectedOperationalUnit();
            if (!selectedOpUnit)
                return;
            if (selectedOpUnit.SelectedRange())
                this.Gantt.HighlightInterval(selectedOpUnit.SelectedRange().StartDate(), selectedOpUnit.SelectedRange().EndDate());
        });

        //this.OnResourcesLoaded();
    }

    private OnResourcesLoaded() {
        const resources = this.teamsManagerProvider.GetResources();
        let maxDate = moment();

        this.Gantt.Items([]);

        resources.forEach((r: ResourceViewModel) => {
            const currentDay = moment().startOf("day");
            
            const validServiceOrders = r.ServiceOrders().filter((s) => {
                return moment(s.FromDate).startOf("day") <= currentDay && moment(s.ToDate || "2100-01-01").startOf("day") >= currentDay;
            });

            if (validServiceOrders.length == 0)
                return;

            (this.currentRenderer as ResourcesFirstRenderer).render(r);
            if(maxDate < moment(r.EndDate()))
                maxDate = moment(r.EndDate());
        });

        maxDate = maxDate.add('months', 3);
        this.Gantt.EndDate(maxDate.toDate());
    }

    private OnTeamsLoaded() {
        if (this.GetCurrentRendererType() != ProlifeSdk.TeamFirstRenderer) {
            this.OnResourcesLoaded();
            return;
        }
        this.Gantt.Items([]);

        const teams: ITeamViewModel[] = this.teamsManagerProvider.GetTeams();
        let maxDate = moment();

        teams.forEach(t => {
            (this.currentRenderer as TeamsFirstRenderer).render(t as TeamViewModel);

            if(maxDate < moment(t.EndDate()))
                maxDate = moment(t.EndDate());
        });

        maxDate = maxDate.add('months', 3);
        this.Gantt.EndDate(maxDate.toDate());
    }

    private OnTeamCreated(team : TeamViewModel) {
        if (this.GetCurrentRendererType() == ProlifeSdk.TeamFirstRenderer)
            (this.currentRenderer as TeamsFirstRenderer).render(team);
    }

    private OnTeamDeleted(team : TeamViewModel) {
        if (this.GetCurrentRendererType() == ProlifeSdk.TeamFirstRenderer)
            (this.currentRenderer as TeamsFirstRenderer).remove(team);
    }

    public Render(): void {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading);

        setTimeout(() => {
            if (this.currentRenderer instanceof TeamsFirstRenderer) {
                this.OnTeamsLoaded();
            }
            else {
                this.OnResourcesLoaded();
            }
    
            const actualFilter: string = this.Gantt.SearchFilter();
            this.Gantt.SearchFilter.notifySubscribers(actualFilter);

            this.dialogsService.UnlockUI();
        }, 600);
    }

    public SetTeamsFirstRenderer(): void {
        this.currentRenderer = this.TeamsFirstRenderer; // new TeamsFirstRenderer(this.serviceLocator, this);
        this.ResourcesFirstRendererEnabled(false);
        this.TeamsFirstRendererEnabled(true);
        
        this.Gantt.clearItemsOpeningStateCache();
        this.Render();
    }

    public SetResourcesFirstRenderer(): void {
        this.currentRenderer = this.ResourcesFirstRenderer; // new ResourcesFirstRenderer(this.serviceLocator, this);
        this.ResourcesFirstRendererEnabled(true);
        this.TeamsFirstRendererEnabled(false);
        
        this.Gantt.clearItemsOpeningStateCache();
        this.Render();
    }

    public SwitchRenderer() {
        if (this.currentRenderer instanceof TeamsFirstRenderer) {
            this.SetResourcesFirstRenderer();
        }
        else {
            this.SetTeamsFirstRenderer();
        }
    }

    public GetCurrentRendererType(): number {
        if (this.currentRenderer instanceof TeamsFirstRenderer)
            return ProlifeSdk.TeamFirstRenderer;
        if (this.currentRenderer instanceof ResourcesFirstRenderer)
            return ProlifeSdk.ResourceFirstRenderer;
        return null;
    }

    public SetIntervalStyle(interval: PlannerGanttItemInterval, value: number, serviceOrderValue: number): void {
        if (value > this.ResourcesDailyHoursLimit()) {
            interval.Color("#FF0000");
            interval.BorderColor("rgba(255, 0, 0, 0.40)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(false);
            return;
        }

        if (value <= this.ResourcesDailyHoursLimit() && value > serviceOrderValue) {
            interval.Color("#FFBC00");
            interval.BorderColor("rgba(255, 188, 0, 0.40)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(false);
            return;
        }

        if (value <= serviceOrderValue && value >= 0) {
            interval.Color("rgb(132, 185, 115)");
            interval.BorderColor("rgb(0, 128, 0)");
            interval.FontColor("#FFFFFF");
            interval.ShowHistogram(true);
            interval.HistogramHeight(Math.min((value * 18 / serviceOrderValue), 18));
            return;
        }
    }
}