import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 20/12/12
 * Time: 16.18
 * To change this template use File | Settings | File Templates.
 */

import { ISearchResult } from "../interfaces/ISearchResult";
import { IFilterWithMatches } from "../interfaces/IFilterWithMatches";
import { IWordFound } from "../interfaces/IWordFound";
import { ISearchContext } from "../interfaces/ISearchContext";

export class SearchResult implements ISearchResult {
    matchesByFilter : IFilterWithMatches[];
    wordsFound : IWordFound[];
    allMatches : any[];

    constructor(public context : ISearchContext) {
        this.matchesByFilter = [];
        this.wordsFound = [];
        this.allMatches = [];
    }

    addMatch(match : any) : void {
        if(this.allMatches.indexOf(match) == -1)
            this.allMatches.push(match);
    }

    addFilterMatch(filterMatch : IFilterWithMatches) : void {
        if(this.matchesByFilter.indexOf(filterMatch) == -1)
            this.matchesByFilter.push(filterMatch);
    }

    addMatchedWord(matchedWord : IWordFound) : void {
        this.wordsFound.push(matchedWord);
    }
}