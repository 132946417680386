import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 22/04/13
 * Time: 12.12
 * To change this template use File | Settings | File Templates.
 */

export class MultilineText {
    init(
        element: HTMLElement,
        valueAccessor: () => any,
        allBindingsAccessor: () => ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const value = valueAccessor();

        const interceptor = ko.computed(() => {
            const text = ko.isSubscribable(value) ? value() : value;
            let formattedText: string = text.replace(/\n/g, "<br>");

            while (formattedText.trim().endsWith("<br>"))
                formattedText = formattedText.substring(0, formattedText.length - 4);

            return formattedText;
        });

        ko.applyBindingsToNode(element, { html: interceptor }, undefined);
    }
}

ko.bindingHandlers["multilineText"] = new MultilineText();
