import * as ko from "knockout";
import { IDialog } from "../../Core/interfaces/IDialogsService";

export class FilterDialog implements IDialog
{
    public templateName : string;
    public templateUrl : string;
    public title: string;
    public filter : ko.Observable<any> = ko.observable();
    modal: { close: (result?: any) => void; };

    constructor(filter : any, templateName : string, templateUrl : string, title? : string)
    {
        this.filter(filter);
        this.templateName = templateName;
        this.templateUrl = templateUrl;
        this.title = title || "";

        if(this.filter().onLoad)
            this.filter().onLoad();
    }

    clear() : void
    {
        this.filter().clear();
        this.modal && this.modal.close(true);
    }

    close() : void
    {
        this.modal && this.modal.close();
    }

    action() : void
    {
        this.filter().applyFilter();
        this.modal && this.modal.close(true);
    }

}
