import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import moment = require("moment-timezone");
import jss from "jss";
import { ComponentUtils } from "../../Core/utils/ComponentUtils";
import {
    IFullCalendar,
    IFullCalendarEvent,
    IFullCalendarEventsDragObserver,
    IFullCalendarHeader,
    IFullCalendarSelectedInterval,
    IFullCalendarViewMap,
    IFullCalendarViewObject,
} from "../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IEventHandler } from "../../ProlifeSdk/interfaces/IEventHandler";
import { EventHandler } from "../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { OptionsInput } from "fullcalendar/src/types/input-types";
import { Moment } from "moment-timezone";
import { ITodoListService, IUserMilestone } from "../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { LazyImport } from "../../Core/DependencyInjection";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../Layouts";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import TsxForEach from "../ForEach";
import { TextFiltersUtilities } from "../../Core/utils/TextFiltersUtilities";
import { If } from "../IfIfNotWith";

const styleSheet = jss.createStyleSheet({
    milestonesCalendar: {
        height: "100%",

        "& .milestone": {
            borderColor: "#ddd !important",

            "&.expired": {
                borderLeftWidth: "5px !important",
                borderLeftColor: "red !important",
            },

            "& .fc-content": {
                marginBottom: "2px",
            },
        },

        "& .toolbar": {
            position: "absolute",
            left: "0px",
            top: "2px",
            zIndex: "1000",
            width: "100%",

            "& .milestones-list-button": {
                position: "absolute",
                right: "600px",
            },
        },

        "& .input-group": {
            "&.date": {
                position: "absolute",
                right: "305px",
                top: "2px",
                zIndex: "1000",

                "& .input-group-addon": {
                    display: "inline-block",
                    width: "160px",
                    color: "black",
                    padding: "0px",
                    background: "none",
                    border: "0",

                    "& .date-button": {
                        width: "160px",
                        border: "0",
                        position: "relative",
                        top: "-1px",
                    },
                },
            },
        },

        "& .fc": {
            height: "100%",

            "& .fc-toolbar": {
                position: "relative",
                zIndex: "999",

                "&.fc-header-toolbar": {
                    marginBottom: "-21px",

                    "& .fc-prev-button": {
                        marginRight: "185px",
                    },

                    "& .fc-button": {
                        display: "inline-block",
                        height: "1.9em",
                        lineHeight: "1.9em",
                        top: "0px",
                        color: "black",
                        boxShadow: "none",
                        textShadow: "none",
                        border: "0",
                        padding: "5px 8px 33px 8px",
                        marginLeft: "2px",
                        borderTopStyle: "none",
                        borderBottomStyle: "none",
                        borderRightStyle: "solid",
                        borderLeftStyle: "solid",
                        borderColor: "#ddd",
                        background: "transparent",

                        "&.fc-state-disabled": {
                            color: "#999",
                        },

                        "&.fc-state-active": {
                            color: "white",
                            backgroundColor: "#428bca",
                        },

                        "&.fc-state-hover": {
                            color: "white",
                            backgroundColor: "#428bca",
                        },

                        "&.fc-prev-button": {
                            paddingRight: "10px",
                            paddingLeft: "8px",

                            "& .fc-icon-left-single-arrow": {
                                fontWeight: "bold",
                            },
                        },

                        "&.fc-next-button": {
                            paddingRight: "8px",
                            paddingLeft: "10px",

                            "& .fc-icon-right-single-arrow": {
                                fontWeight: "bold",
                            },
                        },

                        "&:focus": {
                            outline: "none",
                        },

                        "& .fc-icon": {
                            position: "relative",
                            display: "inline-block",
                            padding: "0 .6em",
                            overflow: "hidden",
                            height: "1.9em",
                            lineHeight: "1.9em",
                            whiteSpace: "nowrap",
                            cursor: "pointer",

                            "&.fc-icon-left-single-arrow": {
                                fontFamily: "'Courier New', Courier, monospace",
                                margin: "0 .1em",
                            },
                        },
                    },
                },
            },

            "& .fc-view-container": {
                position: "relative",
                height: "calc(100% - 20px)",

                "& .fc-view": {
                    height: "100%",

                    "& table": {
                        height: "calc(100% - 20px)",

                        "& .fc-day-grid": {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        },

                        "& .fc-row": {
                            /* minHeight: "16.6%",
                            maxHeight: "16.6%", */

                            flexGrow: "1",

                            "& table": {
                                height: "100%",
                            },
                        },
                    },
                },

                "& .fc-list-view": {
                    "& .fc-scroller": {
                        width: "100%",
                    },
                },

                "& .fc-scroller.fc-day-grid-container": {
                    height: "100% !important",
                },
            },
        },

        "& .fc-body": {
            "& .fc-event-container": {
                "& .fc-event": {
                    "& .fc-content": {
                        "& .fc-time, & .fc-title": {
                            fontWeight: "300",
                            fontSize: "13px",
                        },
                    },
                },
            },
        },
    },

    milestonesList: {
        "& > div": {
            margin: 0,
        },
    },
});
const { classes } = styleSheet.attach();

export type MilestoneCalendarEvent = {
    taskId: number;
    isExpired: boolean;
    workflowTitle: string;
    workflowBackground: string;
    workflowForeground: string;
    jobOrderTitle: string;
    jobOrderIcon: string;
    jobOrderBackground: string;
    jobOrderForeground: string;
} & IFullCalendarEvent;

export type MilestonesCalendarProps = {
    milestones: ko.ObservableArray<IUserMilestone>;
};

export class MilestonesCalendar implements IFullCalendar {
    SelectedEvent: ko.Observable<MilestoneCalendarEvent> = ko.observable();
    SelectedInterval: ko.Observable<IFullCalendarSelectedInterval> = ko.observable();
    SelectedDate: ko.Observable<Date> = ko.observable();

    CalendarTitle: ko.Computed<string>;
    GetActualView: ko.Computed<IFullCalendarViewObject>;
    ExpiredMilestones: ko.Computed<IUserMilestone[]>;
    ExpiredMilestonesCount: ko.Computed<number>;

    OnEventAdded: IEventHandler;
    OnEventUpdated: IEventHandler;
    OnEventDeleted: IEventHandler;
    OnEventsRefresh: IEventHandler;
    OnSetHeaderRequest: IEventHandler;
    OnSetViewsOptionRequest: IEventHandler;
    OnSetBusinessHoursRequest: IEventHandler;
    OnSetSlotDurationRequest: IEventHandler;
    OnChengeViewRequest: IEventHandler;
    OnGoToDateRequest: IEventHandler;
    OnSelectRequest: IEventHandler;
    OnUnselectRequest: IEventHandler;

    private ActualView: ko.Observable<IFullCalendarViewObject> = ko.observable();
    private Options: OptionsInput;
    private static _self: MilestonesCalendar;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private milestonesSubscription: ko.Subscription;
    private selectedDateSubscription: ko.Subscription;

    constructor(private props: MilestonesCalendarProps) {
        MilestonesCalendar._self = this; // TODO cambiare questa oscenità

        this.OnEventAdded = new EventHandler();
        this.OnEventUpdated = new EventHandler();
        this.OnEventDeleted = new EventHandler();
        this.OnEventsRefresh = new EventHandler();
        this.OnChengeViewRequest = new EventHandler();
        this.OnSetHeaderRequest = new EventHandler();
        this.OnSetViewsOptionRequest = new EventHandler();
        this.OnGoToDateRequest = new EventHandler();
        this.OnSelectRequest = new EventHandler();
        this.OnUnselectRequest = new EventHandler();
        this.OnSetBusinessHoursRequest = new EventHandler();
        this.OnSetSlotDurationRequest = new EventHandler();

        this.GetActualView = ko.computed(() => {
            return this.ActualView();
        });

        this.CalendarTitle = ko.computed(() => {
            const currentView: IFullCalendarViewObject = this.ActualView();
            if (!currentView) return "";

            return currentView.title;
        });

        this.ExpiredMilestones = ko.computed(() => {
            const today = moment().startOf("day");
            return this.props.milestones().filter((m) => moment(m.TaskDueDate).startOf("day").isBefore(today));
        });

        this.ExpiredMilestonesCount = ko.computed(() => {
            return this.ExpiredMilestones().length;
        });

        this.setCalendarOptions();
    }

    componentDidMount() {
        this.milestonesSubscription = this.props.milestones.subscribe(() => {
            this.RefreshEvents();
        });

        this.selectedDateSubscription = this.SelectedDate.subscribe((date: Date) => {
            this.GoToDate(moment(date));
        });
    }

    componentWillUnmount() {
        this.milestonesSubscription.dispose();
        this.selectedDateSubscription.dispose();
    }

    private getMilestonesEvents(
        start: Moment,
        end: Moment,
        timezone: any,
        callback: (events: MilestoneCalendarEvent[]) => void
    ): void {
        callback(this.props.milestones().map(this.getCalendarEvent, this));
    }

    private getCalendarEvent(milestone: IUserMilestone): MilestoneCalendarEvent {
        return {
            id: "e_" + milestone.TaskId,
            editable: true,
            durationEditable: false,
            title: milestone.TaskTitle,
            allDay: true,
            start: moment(milestone.TaskDueDate).startOf("day").local(),
            end: moment(milestone.TaskDueDate).startOf("day").local(),
            color: "whitesmoke",
            textColor: "#000000",
            taskId: milestone.TaskId,
            isExpired: milestone.IsExpired,
            workflowTitle: milestone.WorkflowTitle,
            workflowBackground: milestone.WorkflowBackground,
            workflowForeground: milestone.WorkflowForeground,
            jobOrderTitle: milestone.JobOrderTitle,
            jobOrderIcon: milestone.JobOrderIcon,
            jobOrderBackground: milestone.JobOrderBackground,
            jobOrderForeground: milestone.JobOrderForeground,
        };
    }

    private setCalendarOptions(): void {
        const agendaHeader: IFullCalendarHeader = {
            left: "",
            center: "",
            right:
                ProlifeSdk.CalendarPrevButton +
                "," +
                ProlifeSdk.CalendarNextButton +
                "," +
                ProlifeSdk.CalendarTodayButton +
                "," +
                ProlifeSdk.CalendarMonthView +
                "," +
                ProlifeSdk.CalendarAgendaWeekView +
                "," +
                ProlifeSdk.CalendarAgendaDayView,
        };

        this.Options = {
            header: agendaHeader,
            defaultView: ProlifeSdk.CalendarMonthView,
            slotDuration: "00:15:00",
            snapDuration: "00:15:00",
            dragRevertDuration: 0,
            editable: true,
            droppable: false,
            selectable: true,
            locale: "it",
            height: "parent",
            customButtons: undefined,
            buttonText: undefined,
            unselectAuto: false,
            allDaySlot: true,
            eventOverlap: true,
            eventSources: [{ events: this.getMilestonesEvents.bind(this) }],
            viewRender: this.OnViewRenderCallback.bind(this),
            eventRender: this.OnEventRenderCallback.bind(this),
            eventDrop: this.OnEventDropCallback.bind(this),
            eventResize: this.OnEventResizeCallback.bind(this),
            eventClick: this.OnEventClickCallback, //.bind(this)
            dayRender: this.OnDayRenderCallback.bind(this),
            drop: this.OnDropCallback.bind(this),
            eventReceive: this.OnEventReceiveCallback.bind(this),
            select: this.OnSelectCallback.bind(this),
            unselect: this.OnUnselectCallback.bind(this),
            eventDragStop: this.OnDragStopCallback.bind(this),
            eventDragStart: this.OnDragStartCallback.bind(this),
        };
    }

    GetEvents(idOrFilter?: any): MilestoneCalendarEvent[] {
        return [];
    }

    SelectEvent(event: MilestoneCalendarEvent) {}

    RenderEvent(event: MilestoneCalendarEvent) {}

    UpdateEvent(event: MilestoneCalendarEvent) {}

    RemoveEvent(eventId: number) {}

    RefreshEvents() {
        this.OnEventsRefresh.Call();
    }

    async ModifyEvent(event: MilestoneCalendarEvent): Promise<MilestoneCalendarEvent> {
        return new Promise((success) => success(null));
    }

    SelectInterval(start: any, end: any): void {}

    ClearSelection(): void {}

    SetHeader(header: IFullCalendarHeader): void {}

    SetViewsOption(views: IFullCalendarViewMap) {}

    SetBusinessHours(businessHours: any): void {}

    SetSlotDuration(duration: string): void {}

    ChangeView(view: string): void {}

    GoToDate(date: Moment): void {
        this.OnGoToDateRequest.Call(date);
    }

    OnViewRenderCallback(view: IFullCalendarViewObject, element: HTMLElement): void {
        this.ActualView(view);
    }

    OnEventRenderCallback(event: MilestoneCalendarEvent, element: JQuery<HTMLElement>, view: IFullCalendarViewObject) {
        element.addClass("milestone");
        if (event.isExpired) {
            element.addClass("expired");
            element.attr("title", TextResources.Todolist.ExpiredMilestone);
        }

        const wf = $("<div></div>").addClass("fc-content").addClass("text-ellipsis");
        const wfTitle = $("<span></span>").addClass("fc-title").text(event.workflowTitle);
        wf.css("background-color", event.workflowBackground);
        wf.css("color", event.workflowForeground);
        wf.append(wfTitle);
        const currentContent = element.find(".fc-content").addClass("text-ellipsis");
        currentContent.before(wf);

        const jo = $("<div></div>").addClass("fc-content").addClass("text-ellipsis");
        const joTitle = $("<span></span>").addClass("fc-title").text(event.jobOrderTitle);
        const joIcon = $(
            '<span style="background-color: ' +
                event.jobOrderBackground +
                "; color: " +
                event.jobOrderForeground +
                '; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px; float: left;" class="' +
                event.jobOrderIcon +
                '"></span>'
        );
        jo.append(joIcon).append(joTitle);
        wf.before(jo);
    }

    OnEventDestroyCallback(event: MilestoneCalendarEvent, element: HTMLElement, view: IFullCalendarViewObject): void {}

    OnEventDropCallback(
        event: MilestoneCalendarEvent,
        delta: any,
        revertFunc: () => void,
        jsEvent: any,
        ui: JQuery<HTMLElement>,
        view: IFullCalendarViewObject
    ): void {}

    OnEventResizeCallback(
        event: MilestoneCalendarEvent,
        delta: any,
        revertFunc: () => void,
        jsEvent: any,
        ui: JQuery<HTMLElement>,
        view: IFullCalendarViewObject
    ): void {}

    OnEventClickCallback(event: MilestoneCalendarEvent, jsEvent: any, view: IFullCalendarViewObject): void {
        if (jsEvent.originalEvent.detail > 1) {
            MilestonesCalendar._self.todoListService.ShowEditTaskDialog(event.taskId);
        }
    }

    OnDayRenderCallback(date: any, cell: JQuery<HTMLElement>): void {}

    OnDropCallback(
        date: any,
        jsEvent: any,
        ui: JQuery<HTMLElement>,
        view: IFullCalendarViewObject,
        resourceId: any
    ): void {}

    OnEventReceiveCallback(event: MilestoneCalendarEvent): void {}

    OnSelectCallback(start: any, end: any, jsEvent: any, view: IFullCalendarViewObject): void {}

    OnUnselectCallback(jsEvent: any, view: IFullCalendarViewObject): void {}

    OnDragStartCallback(event: MilestoneCalendarEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {}

    OnDragStopCallback(event: MilestoneCalendarEvent, jsEvent: any, ui: any, view: IFullCalendarViewObject): void {}

    IsInViewRange(start: any, end: any): boolean {
        return (
            (this.ActualView().start <= start && this.ActualView().end > start) ||
            (this.ActualView().start <= end && this.ActualView().end > end)
        );
    }

    RegisterEventsDragObserver(observer: IFullCalendarEventsDragObserver): void {}

    NotifyEventDragStart(
        event: MilestoneCalendarEvent,
        jsEvent: any,
        view: IFullCalendarViewObject,
        sourceCalendar: IFullCalendar
    ): void {}

    NotifyEventDragStop(
        event: MilestoneCalendarEvent,
        jsEvent: any,
        view: IFullCalendarViewObject,
        sourceCalendar: IFullCalendar
    ): void {}

    private async showMilestonesListPopOver(viewModel: MilestonesCalendar, evt: MouseEvent): Promise<void> {
        const popOver = new MilestonesList({
            milestones: this.props.milestones,
            onMilestoneSelected: (m) => this.GoToDate(moment(m.TaskDueDate)),
        });
        return this.dialogsService.ShowPopoverComponent(
            evt.currentTarget as HTMLElement,
            popOver,
            "bottom",
            ".modal-body",
            null,
            ".modal-body"
        );
    }

    render() {
        const milestonesCalendar = this;

        return ComponentUtils.bindTo(
            <div className={classes.milestonesCalendar}>
                <div className="toolbar">
                    <div class="btn-group milestones-list-button">
                        <button
                            class="btn btn-primary"
                            type="button"
                            data-bind={{
                                asyncClick: milestonesCalendar.showMilestonesListPopOver.bind(milestonesCalendar),
                            }}>
                            {TextResources.Todolist.ExpiredMilestones}&nbsp;
                            <span
                                data-bind={{
                                    numberText: milestonesCalendar.ExpiredMilestonesCount,
                                    format: "0,0",
                                }}></span>
                            &nbsp;<i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                </div>
                <div class="input-group date" data-bind={{ datePicker: milestonesCalendar.SelectedDate }}>
                    <input type="hidden" class="form-control" />
                    <span class="input-group-addon">
                        <button
                            class="btn btn-default date-button"
                            type="button"
                            data-bind={{ text: milestonesCalendar.CalendarTitle }}></button>
                    </span>
                </div>
                <div
                    data-bind={{
                        fullCalendar: { calendar: milestonesCalendar, options: milestonesCalendar.Options },
                    }}></div>
            </div>,
            this,
            "milestonesCalendar"
        );
    }
}

export type MilestonesListProps = {
    milestones: ko.ObservableArray<IUserMilestone>;
    onMilestoneSelected: (milestone: IUserMilestone) => void;
};

class MilestonesList extends DialogComponentBase {
    ExpiredMilestones: ko.Computed<IUserMilestone[]>;
    ActualMilestones: ko.Computed<IUserMilestone[]>;
    FutureMilestones: ko.Computed<IUserMilestone[]>;

    Filter: ko.Observable<string> = ko.observable("").extend({ rateLimit: 500 });

    constructor(private props: MilestonesListProps) {
        super({ popover: true });

        this.title(TextResources.Todolist.MilestonesList);

        this.ExpiredMilestones = ko
            .computed(() => {
                const filter = this.Filter();
                return this.props
                    .milestones()
                    .filter(
                        (m) =>
                            m.IsExpired &&
                            (!filter ||
                                TextFiltersUtilities.contains(m.TaskTitle, filter) ||
                                TextFiltersUtilities.contains(m.WorkflowTitle, filter) ||
                                TextFiltersUtilities.contains(m.JobOrderTitle, filter))
                    );
            })
            .extend({ trackArrayChanges: true });

        this.ActualMilestones = ko
            .computed(() => {
                const today = moment().startOf("day");
                const filter = this.Filter();
                return this.props
                    .milestones()
                    .filter(
                        (m) =>
                            moment(m.TaskDueDate).startOf("day").isSame(today) &&
                            (!filter ||
                                TextFiltersUtilities.contains(m.TaskTitle, filter) ||
                                TextFiltersUtilities.contains(m.WorkflowTitle, filter) ||
                                TextFiltersUtilities.contains(m.JobOrderTitle, filter))
                    );
            })
            .extend({ trackArrayChanges: true });

        this.FutureMilestones = ko
            .computed(() => {
                const today = moment().startOf("day");
                const filter = this.Filter();
                return this.props
                    .milestones()
                    .filter(
                        (m) =>
                            moment(m.TaskDueDate).startOf("day").isAfter(today) &&
                            (!filter ||
                                TextFiltersUtilities.contains(m.TaskTitle, filter) ||
                                TextFiltersUtilities.contains(m.WorkflowTitle, filter) ||
                                TextFiltersUtilities.contains(m.JobOrderTitle, filter))
                    );
            })
            .extend({ trackArrayChanges: true });
    }

    renderBody() {
        const milestonesList = this;

        const renderMilestone = (milestone: IUserMilestone) => (
            <a
                class="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable milestone"
                style={{ backgroundColor: "whitesmoke", border: "1px solid #ddd", color: "#000000" }}
                data-bind={{ click: milestonesList.props.onMilestoneSelected.bind(milestonesList.props, milestone) }}>
                <div class="fc-content text-ellipsis">
                    <span
                        style={{
                            backgroundColor: milestone.JobOrderBackground,
                            color: milestone.JobOrderForeground,
                            textAlign: "center",
                            display: "inline-block",
                            height: "18px",
                            width: "18px",
                            lineHeight: "18px",
                            float: "left",
                        }}
                        className={milestone.JobOrderIcon}></span>
                    <span class="fc-title">{milestone.JobOrderTitle}</span>
                </div>
                <div
                    class="fc-content text-ellipsis"
                    style={{ backgroundColor: milestone.WorkflowBackground, color: milestone.WorkflowForeground }}>
                    <span class="fc-title">{milestone.WorkflowTitle}</span>
                </div>
                <div class="fc-content text-ellipsis">
                    <span class="fc-title">{milestone.TaskTitle}</span>
                </div>
            </a>
        );

        return ComponentUtils.bindTo(
            <div className="row" style={{ height: "400px", width: "350px", paddingBottom: "10px" }}>
                <div className="col-md-12 flex-container flex-vertical flex-full-height" style={{ overflow: "hidden" }}>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            data-bind={{ value: milestonesList.Filter, valueUpdate: "afterkeydown" }}
                            placeholder={TextResources.Todolist.MilestonesListSearch}
                        />
                    </div>
                    <Layout.ScrollContainer systemScrollable className={classes.milestonesList + " flex-fill"}>
                        <If condition={() => this.ExpiredMilestones().length > 0}>
                            {() => (
                                <div style={{ padding: "3px" }}>
                                    <TsxForEach data={this.ExpiredMilestones} as="milestone">
                                        {(m) => renderMilestone(m)}
                                    </TsxForEach>
                                </div>
                            )}
                        </If>
                        <If condition={() => this.ActualMilestones().length > 0}>
                            {() => (
                                <div style={{ padding: "3px", backgroundColor: "#7de4ff" }}>
                                    <TsxForEach data={this.ActualMilestones} as="milestone">
                                        {(m) => renderMilestone(m)}
                                    </TsxForEach>
                                </div>
                            )}
                        </If>
                        <If condition={() => this.FutureMilestones().length > 0}>
                            {() => (
                                <div style={{ padding: "3px" }}>
                                    <TsxForEach data={this.FutureMilestones} as="milestone">
                                        {(m) => renderMilestone(m)}
                                    </TsxForEach>
                                </div>
                            )}
                        </If>
                    </Layout.ScrollContainer>
                </div>
            </div>,
            this,
            "milestonesList"
        );
    }
}
