import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { UserInfoService } from "./desktop/UserInfoService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAuthenticationService } from "../Core/interfaces/IAuthenticationService";
import { IOPAService } from "../Core/interfaces/IOPAService";
import { IUserSession } from "./interfaces/IUserSession";
import { IAuthenticationServiceObserver } from "../Core/interfaces/IAuthenticationServiceObserver";
import { IUserInfoObserver } from "../ProlifeSdk/interfaces/desktop/IUserInfoObserver";
import { IUserForClient } from "../Core/interfaces/IPrincipal";

export class UserManager implements IAuthenticationServiceObserver, IUserInfoObserver {

	private authenticationService : IAuthenticationService;
	private userSession : IUserSession;
	public user : UserInfoService;

	constructor(private serviceLocator : IServiceLocator)
    {
		this.authenticationService = <IAuthenticationService> serviceLocator.findService(ServiceTypes.Authentication);
		this.authenticationService.addObserver(this);
	}

    async Initialize()
    {
		this.user = <UserInfoService> this.serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
		this.user.addObserver(this);

		this.userSession = <IUserSession> this.serviceLocator.findService(ProlifeSdk.UserSessionServiceType);
        /*var sessionToken = this.userSession.GetSessionToken();
        if(!sessionToken)
            return;*/

        //this.authenticationService.loginUserBySessionToken("");
		await this.authenticationService.loginByToken();
    }

    dispose() {
        this.user.removeObserver(this);
        this.authenticationService.removeObserver(this);
    }

	clearData() {
		this.user.reset();
		this.userSession.SetLoggedUser(null);
		this.userSession.SetSessionToken(null);
		this.userSession.SetCurrentCompany(null);
		location.href = "#/";
	}

	userLoggedIn(user: IUserForClient): void
    {
		const loggedUser = user;
		this.userSession.SetLoggedUser(JSON.stringify(user));
		this.userSession.SetSessionToken(loggedUser.SessionToken);
		this.userSession.SetCurrentCompany(loggedUser.CurrentCompanyGuid || loggedUser.DefaultCompanyGuid);
		this.user.update(user);
	}

	userLoggedOut(): void{
		this.clearData();
	}

	loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any): void
    {
		//TODO: controllare se questa roba serve
        /*const opaService : IOPAService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Run();
		this.clearData();*/
	}

	onCompanySwitched(company : any) : void
    {
		this.userSession.SetCurrentCompany(company.CompanyGUID);
	}
}