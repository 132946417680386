import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

export type LinkProps = {
    title: string;
    subTitle?: string;
    path: string;
    icon?: string;
    iconBackground?: string;
    iconForeground?: string;
    exact?: boolean;
}

export function Link(props: LinkProps) {
    const C = require("./Link")._Link as typeof _Link;
    return <C {...props} />;
}

export class _Link {
    static defaultProps: Partial<LinkProps> = {
    }

    constructor(public props : LinkProps) {
        
    }
    
    render() {
        return <div></div>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(Link);
}