/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 10:01
 * To change this template use File | Settings | File Templates.
 */

import { SplashPageList } from "./SplashPageList";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ICampaignFilters } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";

export class SplashPage {
    public templateName = "splash-page";
    public templateUrl = "surveywizard/templates/";

    public View: ko.Observable<any> = ko.observable();
    public ListElementsCount: ko.Observable<number> = ko.observable();

    public SearchFilters: ko.Observable<string> = ko.observable("");
    public HintSearchProvider: IControlsCustomEntitiesProvider;

    @LazyImport(nameof<ISurveyWizardService>())
    private surveyWizardService: ISurveyWizardService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService: IEntityProviderService;

    private hintSearchGroupsInfo: IHintSearchGroupInfo[] = [];

    constructor() {
        this.SearchFilters.subscribe(this.Refresh.bind(this));
        this.HintSearchProvider = this.entitiesProviderService.GetCustomEntitiesProviderForControls(
            this.hintSearchGroupsInfo,
            true,
            false
        );

        this.showListView();
    }

    public showListView() {
        this.switchView(new SplashPageList(this));
    }

    Initialize(): Promise<void> {
        return Promise.resolve<void>(undefined);
    }

    public goHome(): void {
        location.href = "#/";
    }

    Refresh() {
        if (this.View()) this.View().Refresh();
    }

    public dispose() {
        if (this.View()) this.View().dispose();
    }

    public GetFilters(): ICampaignFilters {
        const filters: IItemIdWithTypeAndGroupCode[] = this.HintSearchProvider.extractItemsAndTypesFromStringValue(
            this.SearchFilters()
        );
        const words: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "$$HINTWORD$$";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });
        const statesIds: number[] = []; //filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "S"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );

        return <ICampaignFilters>{
            TextFilters: words,
            StatesIds: statesIds,
        };
    }

    private switchView(newView) {
        if (this.View()) this.View().dispose();
        this.View(newView);
    }
}
