import * as React from "@abstraqt-dev/jsxknockout"
import ko = require("knockout");
import jss from "jss";
import { reloadNow, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { Application } from "../../Components/Application";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../Components/Layouts";
import { TicketComponent } from "./TicketComponent";
import { TicketsDataSource } from "../../DataSources/TicketsDataSource";
import { IJobOrderDataSourceModel, JobOrdersDataSource } from "../../DataSources/JobOrdersDataSource";
import { ResourcesDataSource } from "../../DataSources/ResourcesDataSource";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../DataSources/IDataSource";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ITableItem, Table } from "../../Components/TableComponent/TableComponent";
import { Column } from "../../Components/TableComponent/CustomColumn";
import { ITicket, ITicketsService } from "../TicketsService";
import { SecondaryRow } from "../../Components/TableComponent/SecondaryRow";
import { ITicketCategoriesSettingsManager } from "../settings/TicketCategoriesSettingsManager";
import { ITicketClosingTypesSettingsManager } from "../settings/TicketClosingTypesSettingsManager";
import { TicketsApplication } from "./TicketsApplication";
import { CustomersDataSource, ICustomersDataSourceModel } from "../../DataSources/CustomersDataSource";
import { Select2 } from "../../Components/Select2Component";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { ITicketOriginsSettingsManager } from "../settings/TicketOriginsSettingsManager";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";

const { classes } = jss.createStyleSheet({
    ticketsTable : {
        "& .actions-col": {
            width: "70px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

type TicketsMainPageProps = {
     ref?: (a: TicketsMainPage) => void;
     application: TicketsApplication;
     onTicketSelected: (id: number) => void;
    }

export class TicketsMainPage implements IDataSourceListener {
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImportSettingManager(nameof<ITicketCategoriesSettingsManager>())
    private ticketCategoriesSettingsManager: ITicketCategoriesSettingsManager;

    @LazyImportSettingManager(nameof<ITicketClosingTypesSettingsManager>())
    private ticketClosingTypesSettingsManager: ITicketClosingTypesSettingsManager;

    @LazyImportSettingManager(nameof<ITicketOriginsSettingsManager>())
    private ticketOriginsSettingsManager: ITicketOriginsSettingsManager;

    @LazyImport(nameof<ITicketsService>())
    private ticketsService : ITicketsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    public TicketId: ko.Observable<number> = ko.observable();
    childComponent: ko.Observable<TicketComponent> = ko.observable();

    public TableDataSource: TicketsDataSource = new TicketsDataSource();

    public TextFilter: ko.Observable<string> = ko.observable("");
    
    public SelectedJobOrder: ko.Observable<number> = ko.observable();
    public JobOrdersDataSource: JobOrdersDataSource;

    public SelectedResource: ko.Observable<number> = ko.observable();
    public ResourcesDataSource: ResourcesDataSource;

    public SelectedCustomer: ko.Observable<number> = ko.observable();
    public CustomersDataSource: CustomersDataSource;

    public DisplayedDescriptionId: ko.Observable<number> = ko.observable();

    private newAction: INavBarAction;
    
    constructor(private props: TicketsMainPageProps){
        this.ResourcesDataSource = new ResourcesDataSource();
        // this.ResourcesDataSource.setIcon({
        //     icon: "icon-user",
        //     foreground: "#FFFFFF",
        //     background: "#0000FF"
        // });

        this.CustomersDataSource = new CustomersDataSource();
        this.CustomersDataSource.setReturnGroupedData(false);

        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.SelectedJobOrder.subscribe((val) => {
            this.TableDataSource.setJobOrderId(val);
            this.TableDataSource.refresh();
        });

        this.SelectedResource.subscribe((val) => {
            this.TableDataSource.setResourcesId(val);
            this.TableDataSource.refresh();
        })

        this.SelectedCustomer.subscribe((val) => {
            this.TableDataSource.setCustomerId(val);
            this.TableDataSource.refresh();
        })

        if (props.ref){
            props.ref(this);
        }
    }

    componentDidMount(){
        this.props.application.canShowSidebar(true);

        this.newAction = this.props.application.addNavBarAction(TextResources.Tickets.AddNewTicket, ko.observable("fa fa-plus"), ko.observable(true), () => this.goNewTicket());
    }

    componentWillUnmount(){
        this.props.application.removeNavBarAction(this.newAction);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.JobOrdersDataSource) {
            let jobOrderModel = model as IJobOrderDataSourceModel;
            if (jobOrderModel) {
                this.SelectedJobOrder(jobOrderModel.model.Id);
            }
        } else if (sender === this.CustomersDataSource) {
            let customerModel = model as ICustomersDataSourceModel;
            if (customerModel) {
                this.SelectedCustomer(customerModel.model.Id);
            }
        }
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.CustomersDataSource) {
            this.SelectedCustomer(null);
        } else if (sender === this.JobOrdersDataSource) {
            this.SelectedJobOrder(null);
        }
    }

    resetSearchFields(){
        this.SelectedResource(null);
        this.ResourcesDataSource.selectByIds(...[]);
        this.SelectedJobOrder(null);
        this.JobOrdersDataSource.selectByIds(...[]);
        this.TextFilter(null);
        this.SelectedCustomer(null);
        this.CustomersDataSource.selectByIds(...[]);
    }

    getCategoryTitle(fkCategory: number): string {
        let cat = this.ticketCategoriesSettingsManager.getTicketCategoriesByIds([fkCategory]).firstOrDefault();
        return !cat ? "" : cat.Title;
    }

    getOriginTitle(fkOrigin: number): string {
        let or = this.ticketOriginsSettingsManager.getTicketOriginsByIds([fkOrigin]).firstOrDefault();
        return !or ? "" : or.Title;
    }

    getClosingTypeName(fkClosingType: number): string {
        let type = this.ticketClosingTypesSettingsManager.getTicketClosingTypesByIds([fkClosingType]).firstOrDefault();
        return !type ? "" : type.Name;
    }


    goNewTicket(){
        this.TicketId(null);
        this.props.onTicketSelected(-1);
    }

    openDetail(id: number) {
        this.TicketId(id);
        this.props.onTicketSelected(id);
    }

    async deleteTicket(id: number){
        let result = await this.dialogsService.ConfirmAsync(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem); 
        if (result) {
            try {
                await this.ticketsService.DeleteTicket(id, this.userInfo.getIdUser());
                this.infoToastService.Success(TextResources.Tickets.UpdateSuccess);
                this.TableDataSource.refresh();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    onBack(){
        this.props.onTicketSelected(null);
    }

    toggleDescription(id: number){
        if (this.DisplayedDescriptionId() === id)
            this.DisplayedDescriptionId(-1);
        else 
            this.DisplayedDescriptionId(id);
    }

    render(){
        let vm = this;
        let ticket: IDataSourceModel<number, ITicket>;
        let table: Table<ITicket>;


        return ComponentUtils.bindTo(
            <Application>
                <Application.LeftMenu>
                    <NavigationMenu allowNoSelection wrapperClassName="page-sidebar" dataSource={this.JobOrdersDataSource} listener={this} />
                </Application.LeftMenu>
                <Application.Content>
                    <LayoutWithHeader className="flex-1">
                        <LayoutHeader className="row no-gutters">
                            <div className="col-md-6">
                                <h1>{TextResources.Tickets.ApplicationName}</h1>
                            </div>
                        </LayoutHeader>
                        <LayoutContent noOverflow>
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <text-input value={() => "TextFilter"} label={TextResources.GDPR.Search} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <Select2 allowClear={true} label={TextResources.Tickets.RelatedResources}
                                                value={vm.SelectedResource} 
                                                dataSource={vm.ResourcesDataSource} 
                                                placeholder={TextResources.Tickets.SelectAResource}/>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <button className="btn btn-warning" style={{marginTop: "26px"}} data-bind={{click: vm.resetSearchFields.bind(vm)}}>
                                                <i className="fa fa-times-circle"></i> {TextResources.GDPR.ResetSearchFields}
                                            </button>
                                        </div>
                                    </div>
                                    <Table rowAs={"ticket"} title={TextResources.Tickets.TicketsSearchResults} showColumnSelector compact className={ComponentUtils.classNames("fixed-height", classes.ticketsTable)} dataSource={this.TableDataSource} verticalAlign="middle" fixedLayout={true} scrollable={true} textFilter={this.TextFilter}>
                                    <Column style={{ width: '30px' }}>
                                        <button className="btn btn-transparent btn-xs" title={TextResources.Tickets.ShowHideDetail} data-bind={{ click: vm.toggleDescription.bind(vm, ticket.model.Id) }}>
                                            <i className="fa" data-bind={{ css: { 'fa-caret-right': vm.DisplayedDescriptionId() !== ticket.model.Id, 'fa-caret-down': vm.DisplayedDescriptionId() === ticket.model.Id }}}></i>
                                        </button>
                                    </Column>
                                    <Column title={TextResources.Tickets.Author}>
                                        <div style={{width: "100%"}} className="text-ellipsis" data-bind={{text: ticket.model.Author, attr: {title: ticket.model.Author}}}></div>
                                    </Column>
                                    <Column title={TextResources.Tickets.Date} data-bind={{dateTimeText: ticket.model.Date}}/>
                                    <Column title={TextResources.Tickets.Description} style={{width: "300px"}}>
                                        <div style="width: 100%" className="text-ellipsis" data-bind={{text: $(ticket.model.Description).text()}}></div>
                                    </Column>
                                    <Column title={TextResources.Tickets.State} style={{width: "60px"}}>
                                        <i className="fa" data-bind={{css: {"fa-folder-open": ticket.model.FkClosingType === null, "fa-folder": ticket.model.FkClosingType !== null}}}/>
                                        <ko-bind data-bind={{if: ticket.model.FkClosingType === null}}>
                                            {TextResources.Tickets.Open}
                                        </ko-bind>
                                        <ko-bind data-bind={{ifnot: ticket.model.FkClosingType === null}}>
                                            <span>{TextResources.Tickets.Closed}:</span><span data-bind={{text: vm.getClosingTypeName(ticket.model.FkClosingType)}}></span>
                                        </ko-bind>
                                    </Column>
                                    <Column title={TextResources.Tickets.Category} style={{width: "150px"}} data-bind={{text: vm.getCategoryTitle(ticket.model.FkCategory)}}/>
                                    <Column title={TextResources.Tickets.Origin} style={{width: "150px"}} data-bind={{text: vm.getOriginTitle(ticket.model.FkOrigin)}}/>
                                    <Column title={TextResources.Tickets.CreationDate} visible={false} data-bind={{dateTimeText: ticket.model.CreationDate}}/>
                                    <Column title={TextResources.Tickets.CreatedBy} visible={false} data-bind={{text: ticket.model.CreationUserFullName}}/>
                                    <Column title={TextResources.Tickets.LastEditDate} data-bind={{dateTimeText: ticket.model.LastEditDate}}/>
                                    <Column title={TextResources.Tickets.EditedBy} data-bind={{text: ticket.model.LastEditUserFullName}}/>
                                    <Column title={TextResources.Tickets.Actions} headerCssClasses="actions-col" cssClasses="actions-col">
                                        {/* <ko-bind data-bind={{if: vm.canInsertOrUpdateTickets}}> */}
                                        <button className="btn btn-danger btn-xs" title={TextResources.Tickets.Delete} data-bind={{click: vm.deleteTicket.bind(vm, ticket.model.Id)}}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        {/*</ko-bind> */}
                                        <button className="btn btn-primary btn-xs" title={TextResources.Tickets.Detail + "..."} data-bind={{click: vm.openDetail.bind(vm, ticket.model.Id)}}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </Column>
                                    <SecondaryRow if={() => "vm.DisplayedDescriptionId() === ticket.model.Id"}>
                                        {(item: ITableItem<ITicket>) => 
                                        <>
                                        <td></td>
                                        <td data-bind={{attr: {colspan: (table.VisibleColumnsCount() - 1)}}}>
                                            <iframe data-bind={{iframeContent: ticket.model.Description}} style={{width: '100%', border: 'none'}} >

                                            </iframe>
                                        </td>
                                        </>}
                                    </SecondaryRow>
                                    </Table>
                        </LayoutContent>
                    </LayoutWithHeader>
                </Application.Content>
                <NavigationMenu allowNoSelection dataSource={this.CustomersDataSource} listener={this} />
            </Application>, this, "vm");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(TicketsMainPage);
}