import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IDocumentRowInlineReferenceProviderFactory,
    IDocumentInfoForInlineRefProvider,
    IDocumentRowInlineReferenceProvider,
} from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { IEntityForRef } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IArticlesService, IArticlePurchase } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { RowToPurchaseArticleRefConverter } from "./RowToPurchaseArticleRefConverter";

export class RowToPurchaseArticleRefConverterProvider implements IDocumentRowInlineReferenceProviderFactory {
    @LazyImport(nameof<IArticlesService>())
    private articleService: IArticlesService;

    public ActivationCode = "@04";
    public EntityType = ProlifeSdk.WarehousePurchaseArticleEntityTypeCode;
    public Icon = "fa fa-barcode";
    public Description = ProlifeSdk.TextResources.Blog.HandlePurchase;
    public DefaultActivation = true;
    public SupportedDocTypes: string[] = [
        ProlifeSdk.EstimateEntityTypeCode,
        ProlifeSdk.CustomerOrderEntityTypeCode,
        ProlifeSdk.DdtEntityTypeCode,
        ProlifeSdk.SalEntityTypeCode,
        ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
        ProlifeSdk.SupplierOrderEntityTypeCode,
        ProlifeSdk.RequestForQuotationEntityTypeCode,
        ProlifeSdk.PurchaseRequestEntityTypeCode,
        ProlifeSdk.WarehouseLoadEntityTypeCode,
    ];

    public async findEntities(
        query: string,
        skip: number,
        count: number,
        docInfo: IDocumentInfoForInlineRefProvider
    ): Promise<IEntityForRef[]> {
        if (!docInfo.SourceWarehouseId?.()) return [];

        const purchases = await this.articleService.searchPurchases(
            query,
            docInfo.SourceWarehouseId(),
            true,
            skip,
            count
        );
        return purchases.map((p) => this.createModelFor(p));
    }

    private createModelFor(a: IArticlePurchase): IEntityForRef {
        return {
            Id: a.id,
            EntityType: ProlifeSdk.WarehousePurchaseArticleEntityTypeCode,
            Description: a.description,
            SubTitle: a.purchaseType,
            Icon: {
                background: "white",
                foreground: "black",
                icon: "fa fa-shopping-cart",
            },
        };
    }

    public create(document: IDocumentInfoForInlineRefProvider): IDocumentRowInlineReferenceProvider {
        return new RowToPurchaseArticleRefConverter(this.ActivationCode, document);
    }
}
