import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { SupplierDiscountsManager } from "./warehouse/ui/Discounts/SupplierDiscountsManager";
import { CustomerDiscountsManager } from "./warehouse/ui/Discounts/CustomerDiscountsManager";
import { DiscountsUtilities } from "./warehouse/ui/Utilities/DiscountsUtilities";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IDiscountsService, IDiscountsServiceObserver, IDiscountsManager, IDiscount } from "../ProlifeSdk/interfaces/warehouse/IDiscountsService";

class DiscountsService implements IDiscountsService {
    private ajaxService : IAjaxService;
    private observers : IDiscountsServiceObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IDiscountsService>(), this);
        
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    calculateDiscount(discounts : string) : number
    {
        return DiscountsUtilities.calculateDiscount(discounts);
    }

    getServiceType() : string{
        return ProlifeSdk.DiscountsServiceType;
    }

    isOfType(serviceType: string) : boolean{
        return serviceType == this.getServiceType();
    }

    getDiscountsManagerByCustomerId(customerId : number, loadNow : boolean = true) : IDiscountsManager {
        return new CustomerDiscountsManager(this, customerId, loadNow);
    }

    getDiscountsManagerBySupplierId(supplierId : number, loadNow : boolean = true) : IDiscountsManager {
        return new SupplierDiscountsManager(this, supplierId, loadNow);
    }

    GetAllCustomerDiscounts() : Promise<IDiscount[]> {
        return this.ajaxService.Get("Warehouse-api", "Discounts/AllCustomerDiscounts", { });
    }

    GetAllSupplierDiscounts() : Promise<IDiscount[]> {
        return this.ajaxService.Get("Warehouse-api", "Discounts/AllSupplierDiscounts", { });
    }

    getGeneralDiscounts() : Promise<IDiscount[]> {
        return this.ajaxService.Get("Warehouse-api", "Discounts/GeneralCustomers", { background: true });
    }

    getDiscountsByCustomerId(customerId : number, async : boolean = true) : Promise<IDiscount[]> {
        if(!customerId) {
            return Promise.reject<IDiscount[]>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "Discounts/ByCustomer?customerId=" + customerId, { async: async, background: true });
    }

    getDiscountsBySupplierId(supplierId : number, async : boolean = false) : Promise<IDiscount[]> {
        if(!supplierId) {
            return Promise.reject<IDiscount[]>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "Discounts/BySupplier?supplierId=" + supplierId, { async: async });
    }

    createOrUpdate(discount : IDiscount) : Promise<IDiscount> {
        return this.ajaxService.Post<IDiscount>("Warehouse-api", "Discounts", { methodData: discount })
            .then((returnedDiscount: IDiscount) => {
                if (!returnedDiscount)
                    return null;
                if(discount.Id) this.onDiscountChanged(returnedDiscount);
                else this.onDiscountAdded(returnedDiscount);
                return returnedDiscount;
            });
    }

    remove(discountId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Warehouse-api", "Discounts/" + discountId, {  })
            .then(this.onDiscountDeleted.bind(this, discountId));
    }

    addObserver(observer : IDiscountsServiceObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IDiscountsServiceObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onDiscountAdded(discount : IDiscount) : void {
        this.observers.forEach((observer) => observer.onDiscountAdded(discount));
    }

    private onDiscountChanged(discount : IDiscount) : void {
        this.observers.forEach((observer) => observer.onDiscountChanged(discount));
    }

    private onDiscountDeleted(discountId : number) : void {
        this.observers.forEach((observer) => observer.onDiscountDeleted(discountId));
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new DiscountsService(serviceLocator);
}