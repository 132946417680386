import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IWarehouseLoadReasonsForShipmentSettingsManager } from "../WarehouseLoadReasonsForShipmentSettingsManager";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IOrdersService, IWarehouseLoadReasonForShipment } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

export class WarehouseLoadReasonsForShipmentSettingsEditor {
    public Title: ko.Observable<string> = ko.observable();
    public ReasonsForShipment: ko.ObservableArray<WarehouseLoadReasonForShipment> = ko.observableArray<WarehouseLoadReasonForShipment>([]);
    public NewReasonDescription: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(ProlifeSdk.OrdersServiceType)
    private ordersService: IOrdersService;

    constructor(private reasonsSettingsManager: IWarehouseLoadReasonsForShipmentSettingsManager) {
        this.Title(ProlifeSdk.TextResources.Warehouse.WarehouseLoadReasonsForShipmentSettingsManager);
        this.reload();
    }

    public addNew(): void {
        const descr = this.NewReasonDescription();

        if (!descr)
            return;

        const reason: IWarehouseLoadReasonForShipment = {
            Id: null,
            Description: descr,
            Deleted: false,
            UpdateArticlesMeanCosts: true
        };

        const vm = this.createViewModelFor(reason);
        vm.IsLocked(false);

        this.ReasonsForShipment.push(vm);
        this.NewReasonDescription(null);
    }

    public async save(): Promise<void> {
        const reasons = this.ReasonsForShipment();
        const errors = reasons.filter((r) => r.HasErrors());

        if (errors.length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Warehouse.WarehouseLoadReasonsForShipmentErrors);
            return;
        }

        const data = reasons.map((r) => r.getData());
        try {
            await this.reasonsSettingsManager.insertOrUpdateReasonsForShipment(data);
            this.infoToastService.Success(ProlifeSdk.TextResources.Warehouse.WarehouseLoadResonsForShipmentSaveSuccess)

            this.reload();
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Warehouse.WarehouseLoadResonsForShipmentSaveError)
        }
    }

    public async reload(): Promise<void> {
        const reasonsUsage = await this.ordersService.GetWarehouseLoadsReasonsForShipmentUsage();

        this.ReasonsForShipment(this.reasonsSettingsManager.getReasonsForShipment(false).map((r => {
            const vm = this.createViewModelFor(r);
            const usageMatch = reasonsUsage.filter((u) => u.ReasonForShipment === r.Id);
            
            // HACK per far settare correttamente il valore degli switch in interfaccia
            setTimeout(() => vm.IsLocked(usageMatch.length > 0 && usageMatch[0].WarehouseLoads > 0), 100);
            
            return vm;
        })));
    }

    private createViewModelFor(reason: IWarehouseLoadReasonForShipment): WarehouseLoadReasonForShipment {
        return new WarehouseLoadReasonForShipment(reason);
    }
}

class WarehouseLoadReasonForShipment {
    public get Id(): number {
        return this.reason.Id;
    }

    public Description: ko.Observable<string> = ko.observable();
    public UpdateArticlesMeanCosts: ko.Observable<boolean> = ko.observable();
    public Deleted: ko.Observable<boolean> = ko.observable();
    public IsLocked: ko.Observable<boolean> = ko.observable(false);
    public HasErrors: ko.Computed<boolean>;

    constructor(private reason: IWarehouseLoadReasonForShipment) {
        this.Description(this.reason.Description);
        this.UpdateArticlesMeanCosts(this.reason.UpdateArticlesMeanCosts);
        this.Deleted(this.reason.Deleted);

        this.HasErrors = ko.computed(() => {
            return !this.Description();
        });
    }

    public deleteReason(): void {
        this.Deleted(true);
    }

    public restoreReason(): void {
        this.Deleted(false);
    }

    public getData(): IWarehouseLoadReasonForShipment {
        const data = $.extend(true, {}, this.reason);

        data.Description = this.Description();
        data.UpdateArticlesMeanCosts = this.UpdateArticlesMeanCosts();
        data.Deleted = this.Deleted();

        return data;
    }
}