import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";

type WorkFlexibilityButtonProps = {
    resourceId: ko.Observable<number>;
    isEditFlexibility: ko.Observable<boolean>;
    onInsertFlexibilityEnabledChange?: (isFlexibility: boolean) => void;
    checkChanged: () => Promise<boolean>;
};

export function WorkFlexibilityButton(props: WorkFlexibilityButtonProps) {
    const C = require("./WorkFlexibilityButton")._WorkFlexibilityButton as typeof _WorkFlexibilityButton;
    return <C {...props} />;
}

export class _WorkFlexibilityButton {
    static defaultProps: Partial<WorkFlexibilityButtonProps> = {};

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourceSettings: IHumanResourcesSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettings: IJobOrderGeneralSettingsManager;

    public CanInsertFlexibility: ko.Observable<boolean> = ko.observable(false);

    private subscription: ko.Subscription;

    constructor(private props: WorkFlexibilityButtonProps) {}

    componentDidMount() {
        /*this.subscription = this.props.serviceOrder.subscribe((order: IValidRolesForServiceOrder) => {
            if(!order){
                this.CanInsertFlexibility(false);
                return;
            }
            const hasFlexibleWorkTimeCategories =  order.WorkTimeCategories.any(w => w.WorkTimeCategoryId === this.generalSettings.getDefaultFlexibilityWorkTimeCategory());
            this.CanInsertFlexibility(hasFlexibleWorkTimeCategories && this.generalSettings.getEnableFlexibility()); 
        });*/

        const updateCanInsertFlexibility = (res: number) => {
            //prendere service order di questa risorsa
            const loggedResource = this.resourceSettings.getHumanResourceById(res);
            const serviceOrderIds = loggedResource?.OrdersSalaries.filter(
                (a) => a.FkWorkTimeCategoryId === this.generalSettings.getDefaultFlexibilityWorkTimeCategory()
            ).map((a) => a.FkServiceOrder);
            const now = new Date();
            const future = new Date("2100-01-01T00:00:00+0100");
            const serviceOrder = loggedResource?.Orders.find(
                (a) => serviceOrderIds.includes(a.Id) && a.FromDate <= now && now < (a.ToDate ?? future)
            );

            this.CanInsertFlexibility(serviceOrder && this.generalSettings.getEnableFlexibility());
        };
        this.subscription = this.props.resourceId.subscribe(updateCanInsertFlexibility);
        updateCanInsertFlexibility(this.props.resourceId());
    }

    componentWillUnmount() {
        //this.subscription.dispose();
        //this.subscription = undefined;
    }

    private async switchFlexibilityInsert() {
        const res = await this.props.checkChanged();
        if (!res) return;
        this.props.onInsertFlexibilityEnabledChange &&
            this.props.onInsertFlexibilityEnabledChange(!this.props.isEditFlexibility());
    }

    render() {
        return (
            <If condition={this.CanInsertFlexibility}>
                {() => (
                    <button type="button" className="btn btn-success" onClick={this.switchFlexibilityInsert.bind(this)}>
                        <If condition={this.props.isEditFlexibility}>
                            {() => <>{TextResources.WorkedHours.InsertWorkedHours}</>}
                        </If>
                        <IfNot condition={this.props.isEditFlexibility}>
                            {() => <>{TextResources.WorkedHours.InsertFlexibility}</>}
                        </IfNot>
                    </button>
                )}
            </If>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WorkFlexibilityButton);
}
