import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { BudgetRequestDialog } from "./BudgetRequestDialog";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IBudgetRequest, IBudgetRequestForList, ITaskBudget } from "../../../../ProlifeSdk/interfaces/job-order/IEstimatedBudgetNavigator";

export class JobOrderBudget
{
    private dialogService : IDialogsService;
    private jobOrderService : IJobOrderService;
    private authService : IAuthorizationService;

    public BudgetRequests : ko.ObservableArray<BudgetRequest> = ko.observableArray([]);
    private JobOrderId : number = -1;

    public UserCanViewBudgetRequests : ko.Observable<boolean> = ko.observable(false);
    public UserCanEditBudgetRequests : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.authService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public AddNew()
    {
        var budgetDialog : BudgetRequestDialog = new BudgetRequestDialog(this.serviceLocator);
        budgetDialog.Initialize({
            JobOrderId: this.JobOrderId,
            CreationDate: new Date(),
            CreationUser: null,
            Title: "",
            Notes: "",
            Approved: false,
            ApprovedUser: null,
            ApprovedDate: null,
            TasksRequests: []
        }).then(() => {
            this.dialogService.ShowModal<IBudgetRequest>(budgetDialog, "fullscreen", {}, budgetDialog.templateUrl, budgetDialog.templateName)
                .then((result : IBudgetRequest) => {
                    if(!result)
                        return;

                    this.jobOrderService.GetBudgetRequestForList(result.Id).then((r : IBudgetRequestForList) => {
                        this.BudgetRequests.splice(0, 0, new BudgetRequest(this.serviceLocator, r, this));
                    });
                    budgetDialog.Dispose();
                });
        });
    }

    public Initialize(jobOrderId : number) : Promise<IBudgetRequestForList[]>
    {
        this.JobOrderId = jobOrderId;

        this.UserCanViewBudgetRequests(this.authService.isAuthorized(ProlifeSdk.JobOrderApplicationCode + "_BudgetRequest_View"));
        this.UserCanEditBudgetRequests(this.authService.isAuthorized(ProlifeSdk.JobOrderApplicationCode + "_BudgetRequest_Edit"));

        if(!this.UserCanViewBudgetRequests())
            return Promise.resolve<IBudgetRequestForList[]>([]);

        return this.RefreshList();
    }

    public RefreshList() : Promise<IBudgetRequestForList[]>
    {
        return this.jobOrderService.GetBudgetRequestsForList(this.JobOrderId).then((requests : IBudgetRequestForList[]) => {
            this.BudgetRequests(requests.map((r : IBudgetRequestForList) => { return new BudgetRequest(this.serviceLocator, r, this); }));
            return requests;
        });
    }

    public Dispose()
    {
        //TODO
    }
}

export class BudgetRequest
{
    private dialogService : IDialogsService;
    private jobOrderService : IJobOrderService;

    public Title : ko.Observable<string> = ko.observable();
    public CreationUser : ko.Observable<number> = ko.observable();
    public CreationDate : ko.Observable<Date> = ko.observable();
    public Total : ko.Observable<number> = ko.observable();
    public Approved : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator, private request : IBudgetRequestForList, private parentList : JobOrderBudget)
    {
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);

        this.LoadData(request);
    }

    private LoadData(r : IBudgetRequestForList)
    {
        this.Title(r.Title);
        this.CreationDate(r.CreationDate);
        this.CreationUser(r.CreationUser);
        this.Approved(r.Approved);
        this.Total(r.Total);
    }

    public LoadFromRequest(r : IBudgetRequest)
    {
        var total : number = 0;
        r.TasksRequests.forEach((t : ITaskBudget) => { total += (t.PurchasesBudget + t.WarehouseBudget + t.WorkBudget); });
        this.Title(r.Title);
        this.CreationDate(r.CreationDate);
        this.CreationUser(r.CreationUser);
        this.Approved(r.Approved);
        this.Total(total);
    }

    public Edit()
    {
        var budgetDialog : BudgetRequestDialog = new BudgetRequestDialog(this.serviceLocator);

        this.jobOrderService.GetBudgetRequest(this.request.Id).then((r : IBudgetRequest) => {
            budgetDialog.Initialize(r).then(() => {
                this.dialogService.ShowModal<IBudgetRequest>(budgetDialog, "fullscreen", {}, budgetDialog.templateUrl, budgetDialog.templateName)
                    .then((result : IBudgetRequest) => {
                        if(!result && this.parentList.BudgetRequests().indexOf(this) > -1)
                        {
                            this.parentList.BudgetRequests.remove(this);
                            return;
                        }

                        this.LoadFromRequest(result);
                        budgetDialog.Dispose();
                    });
            });
        });
    }
}
