import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TreeGrid, TreeGridStringColumn, TreeGridItem } from "../../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IEstimateBudgetRow } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IArticleTransformComponent, IArticlesService, IArticle, IArticleTransform } from "../../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { Deferred } from "../../../../../Core/Deferred";

export class TaskTransformsDialog implements IDialog {
    templateName:string = "task-transforms-dialog";
    templateUrl:string = "todolist/templates/tasks-list/dialogs";
    title:string = ProlifeSdk.TextResources.Todolist.TaskTransformsDialogTitle;
    modal: {
        close: (result?: any) => void;
    };

    public TransformsTree : TreeGrid = new TreeGrid();
    public SourceArticleId : ko.Observable<number> = ko.observable();
    public SourceArticleAmount : ko.Observable<number> = ko.observable();

    public SelectedArticles : ko.ObservableArray<IArticleTransformComponent> = ko.observableArray();

    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService : IArticlesService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    close():void {
        this.modal.close([]);
    }

    action():void {
        this.modal.close(this.SelectedArticles());
    }

    public ShowModal() : Promise<IArticleTransformComponent[]> {
        var def = new Deferred<IArticleTransformComponent[]>();
        
        this.dialogsService.ShowModal<IArticleTransformComponent[]>(this, "fullscreen", null)
            .then((components : IArticleTransformComponent[]) => {
                def.resolve(components);
            }).catch(() => {
                def.reject([]);
            });

        return def.promise();
    }

    constructor(private row : IEstimateBudgetRow) {
        this.TransformsTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.Warehouse.Description, value =>  value.ComponentDescription, 700).Align('left'));
        this.TransformsTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.Warehouse.Amount, value =>  value.Amount, 100).Format(this.numberFormatter.bind(this)).Align('right'));


        this.SourceArticleId(row.EntityKeyId);
        this.SourceArticleAmount(row.Amount);

        this.load();
    }

    private numberFormatter(value : number) : string {
        return numeral(value).format('0,0.00[0]');
    }

    private load() {
        this.articlesService.getArticleByCatalogRowId(this.row.EntityKeyId)
            .then((article : IArticle) => {
                this.articlesService.GetArticleTransforms(article.ArticleId)
                    .then((transform : IArticleTransform) => {
                        this.TransformsTree.ClearItems();

                        if(transform) {
                            transform.Construction.forEach((component : IArticleTransformComponent) => {
                                component.Amount = component.Amount * this.row.Amount;
                                var item = this.TransformsTree.AddItem(component, component.ComponentCode);
                                item.CanHaveChildren(false);
                                item.Actions.push({ Classes: "btn-primary", Icon: "fa fa-check", Action: () => this.SelectedArticles.push(component) });

                                this.articlesService.GetArticleTransforms(component.ComponentArticleId)
                                    .then((t : IArticleTransform) => {
                                        item.CanHaveChildren(!!t);
                                        if(item.CanHaveChildren()) {
                                            item.OnChildrenNeeded = this.loadChildren.bind(this, item);
                                        }
                                    });
                            });
                        }
                    });
            });
    }

    private loadChildren(item : TreeGridItem) {
        this.articlesService.GetArticleTransforms(item.Value().ComponentArticleId)
            .then((transform : IArticleTransform) => {
                transform.Construction.forEach((component : IArticleTransformComponent) => {
                    component.Amount = component.Amount * item.Value().Amount;
                    var child = item.AddChild(component, component.ComponentCode);
                    child.CanHaveChildren(false);
                    child.Actions.push({ Classes: "btn-primary", Icon: "fa fa-check", Action: () => this.SelectedArticles.push(component) });

                    this.articlesService.GetArticleTransforms(component.ComponentArticleId)
                        .then((t : IArticleTransform) => {
                            child.CanHaveChildren(!!t);
                            if(child.CanHaveChildren()) {
                                child.OnChildrenNeeded = this.loadChildren.bind(this, child);
                            }
                        });
                });
            });
    }

    public removeArticle(article : IArticleTransformComponent) {
        this.SelectedArticles.remove(article);
    }
}