import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { MovementArticle } from "../../ui/Movements/MovementArticle";
import { ArticlesDataSource, IArticleDataSourceModel } from "../../../../DataSources/ArticlesDataSource";
import { IDiscountsCatalogsService } from "../../../DiscountsCatalogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IDataSourceListener, IDataSource } from "../../../../DataSources/IDataSource";
import { IDocumentBuilderDocumentOriginatingRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWarehousesService } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IDiscountsService, IDiscountsManager } from "../../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

export class WarehouseArticlesDataSource
    extends DocumentDataWizardStep<MovementArticle>
    implements IDataSourceListener
{
    PublicRows: ko.ObservableArray<MovementArticle> = ko.observableArray([]);

    public NewArticleCode: ko.Observable<number> = ko.observable();
    public FinalTotal: ko.Computed<number>;
    public FinalTotalInDocumentCurrency: ko.Computed<number>;
    public ShowFinalTotalInDocumentCurrency: ko.Computed<boolean>;
    public isResetting = false;
    public editing: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;
    @LazyImport(nameof<IWarehousesService>())
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    public ArticlesDataSource: ArticlesDataSource = new ArticlesDataSource();
    public WorkflowName: ko.Observable<string> = ko.observable();
    public WorkflowId: ko.Observable<number> = ko.observable();
    previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Warehouse.Warehouse);

        this.FinalTotal = ko.computed(() => {
            return this.calculateTotal();
        });

        this.FinalTotalInDocumentCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();

            if (!documentCurrency) return this.FinalTotal();

            return CurrencyUtils.applyCurrencyReverseExchange(this.FinalTotal(), documentCurrency);
        });

        this.ShowFinalTotalInDocumentCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();
            if (!documentCurrency) return false;

            const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();

            return defaultCurrency.CodeISO4217alpha3 !== documentCurrency.Currency().CodeISO4217alpha3;
        });

        this.RegisterDataSource();
    }

    public RegisterDataSource() {
        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.CustomerOrderEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode,
            ProlifeSdk.EstimateEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.SalEntityTypeCode,
            ProlifeSdk.SupplierOrderEntityTypeCode,
            ProlifeSdk.PurchaseRequestEntityTypeCode
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: null,
                    Amount: r.Amount(),
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: (r.NetUnitPrice() ?? 0).ToCurrency(documentCurrency),
                    NetUnitPrice: r.NetUnitPrice(),
                    Order: 0,
                    TotalPriceInDocumentCurrency: (r.Price() ?? 0).ToCurrency(documentCurrency),
                    TotalPrice: r.Price(),
                    UnitPriceInDocumentCurrency: (r.UnitPrice() ?? 0).ToCurrency(documentCurrency),
                    UnitPrice: r.UnitPrice(),
                    Description: r.Description(),
                    Discounts: r.Discounts(),
                    UoM: r.UOM(),
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r: MovementArticle): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        refs.push({
            RefId: 0,
            DocumentId: 0,
            SourceEntityType: ProlifeSdk.WarehouseArticleEntityTypeCode,
            SourceEntityKeyId: r.getArticleId(),
            DestEntityType: this.initializationInfo.DocTypeCode,
            DestEntityKeyId: 0,
            CatalogId: r.CatalogId(),
            Amount: r.Amount(),
            UnitPrice: r.UnitPrice(),
            Discounts: r.Discounts(),
            NetUnitPrice: r.NetUnitPrice(),
            NetPrice: r.Price(),
            WarehouseId: this.initializationInfo.SourceWarehouseId,
        });
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return this.authorizationService.isAuthorized("Warehouse_Articles");
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);
        this.Rows([]);
        this.editing(true);
        this.isResetting = false;

        try {
            const warehouse = await this.warehousesService.getWarehouseById(initializationInfo.SourceWarehouseId);
            this.WorkflowId(warehouse?.WorkflowId);

            const workflow = await this.todoListService.GetWorkflowForList(warehouse?.WorkflowId);
            this.WorkflowName(workflow?.Title);
        } catch (e) {
            console.log(e);
        }
    }

    public async EditWorkflow(): Promise<void> {
        if (!this.WorkflowId()) return;

        await this.todoListService.ShowEditWorkflowDialog(this.WorkflowId());
    }

    public createDiscountManager(discountsService: IDiscountsService, customerId: number): IDiscountsManager {
        return customerId && customerId > 0 ? discountsService.getDiscountsManagerByCustomerId(customerId) : null;
    }

    public removeArticle(article: MovementArticle) {
        if (this.isResetting) return;

        this.Rows.remove(article);
    }

    public calculateTotal(): number {
        let totalPrice = 0;

        this.Rows().forEach((article: MovementArticle) => {
            totalPrice += numeral(numeral(article.Price()).format("0.00")).value();
        });

        return totalPrice;
    }

    public createViewModelFor(article: IArticle): MovementArticle {
        return new MovementArticle(article, false);
    }

    public onItemSelected(sender: IDataSource, model: IArticleDataSourceModel): void {
        if (sender == this.ArticlesDataSource) {
            const movementArticle = this.createViewModelFor(model.model);
            const isSupplier = this.initializationInfo.DocTypeCode == "SOR";

            this.discountsCatalogsService
                .ResolveCustomerDiscountCatalogsForArticle({
                    articleId: model.id,
                    customerId: this.initializationInfo.CustomerId,
                    isCustomer: !isSupplier,
                    overrideGroupId: null,
                    referenceDate: this.initializationInfo.DocumentDate,
                    warehouseId: this.initializationInfo.SourceWarehouseId,
                })
                .then((discount) => {
                    movementArticle.setDefaultValues(discount);
                    this.Rows.push(movementArticle);

                    setTimeout(() => this.NewArticleCode(null), 0);
                });
        }
    }

    public onItemDeselected(sender: IDataSource, model: IArticleDataSourceModel): void {}

    renderArticlesTable() {
        let step: WarehouseArticlesDataSource;
        let row: MovementArticle;

        return (
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                <th style="text-align: center;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardAmount}
                                </th>
                                <th style="text-align: center;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardUoM}
                                </th>
                                <th style="text-align: right;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardUnitPriceShort}
                                </th>
                                <th style="text-align: right;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardDiscount}
                                </th>
                                <th style="text-align: right;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardNetUnitPrice}
                                </th>
                                <th style="text-align: right;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardTotalPrice}
                                </th>
                                <th style="text-align: center;width: 120px;">
                                    {TextResources.Invoices.DocumentWizardRemove}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ko-bind data-bind={{ foreach: { data: step.Rows, as: "row" } }}>
                                <tr>
                                    <td data-bind={{ articleText: row.ArticleId }} style="vertical-align: middle"></td>
                                    <td>
                                        <input
                                            class="form-control input-sm"
                                            type="text"
                                            data-bind={{ numberValue: row.Amount, focusOnce: true, selectOnFocus: {} }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="form-control input-sm"
                                            type="text"
                                            data-bind={{ value: row.UOM, selectOnFocus: {} }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="form-control input-sm text-right"
                                            type="text"
                                            data-bind={{ moneyValueEx: row.UnitPrice, selectOnFocus: {} }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="form-control input-sm"
                                            type="text"
                                            data-bind={{ discountValue: row.Discounts, selectOnFocus: {} }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="form-control input-sm text-right"
                                            type="text"
                                            data-bind={{ moneyValueEx: row.NetUnitPrice, selectOnFocus: {} }}
                                        />
                                    </td>
                                    <td class="text-right">
                                        <span
                                            class="form-control input-sm text-right"
                                            data-bind={{ moneyTextEx: row.Price }}
                                        ></span>
                                    </td>
                                    <td class="text-center">
                                        <a
                                            href="#"
                                            class="btn btn-xs red"
                                            data-bind={{ click: step.removeArticle.bind(step, row) }}
                                        >
                                            <i class="fa fa-trash-o"></i>
                                            {TextResources.Invoices.DocumentWizardRemove}
                                        </a>
                                    </td>
                                </tr>
                            </ko-bind>

                            <tr>
                                <td>
                                    <select2
                                        value={() => "NewArticleCode"}
                                        simple={true}
                                        dataSource={() => "ArticlesDataSource"}
                                        listener={() => "$data"}
                                        placeholder={TextResources.Invoices.DocumentWizardSearchArticlePlaceholder}
                                        allowClear={true}
                                    ></select2>
                                </td>
                                <td colSpan={7}></td>
                            </tr>

                            <tr>
                                <td colSpan={5}>
                                    <strong>{TextResources.Invoices.DocumentWizardTotalPrice}</strong>
                                </td>
                                <td colSpan={2} class="text-right">
                                    <span data-bind={{ visible: step.ShowFinalTotalInDocumentCurrency }}>
                                        (
                                        <span
                                            data-bind={{
                                                moneyText: step.FinalTotalInDocumentCurrency,
                                                currencySymbol: step.DocumentCurrencySymbol,
                                                documentCurrency: step.DocumentCurrency,
                                            }}
                                        ></span>
                                        )
                                    </span>{" "}
                                    <span data-bind={{ moneyTextEx: step.FinalTotal }}></span>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        let step: WarehouseArticlesDataSource;
        const row: MovementArticle = null;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 20px;">
                <ko-bind data-bind={{ if: !!step.WorkflowId() }}>
                    <div class="row" style="margin-bottom: 20px">
                        <label class="col-md-1 control-label">
                            {TextResources.Invoices.DocumentWizardWarehouseWorkflow}
                        </label>
                        <div class="col-md-5">
                            <span class="form-control" data-bind={{ text: step.WorkflowName }}></span>
                        </div>
                        <div class="col-md-1">
                            <button
                                class="btn btn-primary"
                                data-bind={{ asyncClick: step.EditWorkflow }}
                                title={TextResources.Invoices.DocumentWizardEditWorkflow}
                            >
                                <i class="fa fa-pencil"></i>
                            </button>
                        </div>
                    </div>
                </ko-bind>

                {this.renderArticlesTable()}
            </div>,
            this,
            "step"
        );
    }
}
