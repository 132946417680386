import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { DropZone } from "../../../../../Components/DropZone";
import { Layout } from "../../../../../Components/Layouts";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { AllocationsJobOrdersMenu } from "../../AllocationsJobOrdersMenu";
import { CartContent } from "./CartContent";
import { CartForm, _CartForm } from "./CartForm";
import { ICart, ICartContentWithRolesInfo, ICartResource } from "../../../../../ProlifeSdk/interfaces/allocations/ICart";
import { ICartLink, ICartsService } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { CartWorkableHoursCalculationModes } from "../../../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IAllocationsService, IMissingRole, TeamIdentifier } from "../../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDraggedTask, IDraggedWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";

const styleSheet = jss.createStyleSheet({
    "cart-editor": {
        paddingBottom: "15px",
        borderBottom: "1px solid #ddd",
        borderRight: "1px solid #ddd",

        "& .row": {
            margin: "0px 0px 0px -15px !important"
        }
    },
    "drop-zone-message": {
        "& span": {
            display: "block",
            fontSize: "2em"
        },

        "& i": {
            fontSize: "2em"
        }
    },
    "menu": {
        position: "relative",

        "& .page-sidebar": {
            position: "absolute !important",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            width: "100%",

            "& .page-quick-sidebar-wrapper": {
                position: "absolute",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",

                "& .page-quick-sidebar": {
                    "& .page-quick-sidebar-chat": {
                        "& .page-quick-sidebar-chat-users": {
                            padding: "0px"
                        }
                    }
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type CartEditorProps = {
    cart?: ICart;
    resources: ICartResource[];
    links: ICartLink[];
    forceSaving?: boolean;
    forwardRef?: (editor: _CartEditor) => void;
    onCartNameChanged?: (cartName: string) => void;
    onCartWorkableHoursCalculationModeChange: (mode: CartWorkableHoursCalculationModes) => void;
}

export function CartEditor(props: CartEditorProps) {
    const C = require("./CartEditor")._CartEditor as typeof _CartEditor;
    return <C {...props} />;
}

export class _CartEditor {
    static defaultProps: Partial<CartEditorProps> = {
    }

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    public CartContent: ko.ObservableArray<ICartContentWithRolesInfo> = ko.observableArray([]);

    private menuRef: AllocationsJobOrdersMenu;
    private formRef: _CartForm;

    constructor(private props : CartEditorProps) {
        this.props.forwardRef && this.props.forwardRef(this);
    }

    componentDidMount() {
        this.getTeamsAllocatedOn();
        this.loadCartContent();
    }

    private async getTeamsAllocatedOn(): Promise<TeamIdentifier[]> {
        if (!this.props.cart || this.props.cart.Id <= 0)
            return [];

        try {
            return await this.allocationsService.getCartTeamsNames(this.props.cart?.Id);
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    public refreshMenu(): void {
        this.menuRef.refresh();
    }

    public getCartData(): ICart {
        return this.formRef.getCartData();
    }

    public async save(): Promise<ICart> {
        return await this.formRef.save();
    }

    private async loadCartContent(): Promise<void> {
        if (this.props.cart?.Id > 0) {
            const contents: ICartContentWithRolesInfo[] = await this.cartsService.getCartContentDetails(this.props.cart.Id, true);
            this.CartContent(contents ?? []);
        }
    }

    private async handleElementDrop(dataTransfer: DataTransfer): Promise<void> {
        const task: IDraggedTask = dataTransfer.types.indexOf("application/prolife-task") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-task")) : null;
        const workflow: IDraggedWorkflow = dataTransfer.types.indexOf("application/prolife-workflow") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-workflow")) : null;
        
        if (this.userInfo.getCurrentCompanyGuid() !== (task ? task.CompanyGuid : workflow.CompanyGuid)) {
            this.infoToastService.Error(TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        const elementId: number = task ? task.TaskId : workflow.WorkflowId;
        const isTask: boolean = task ? task.IsTask : workflow.IsTask;

        try {
            let canAdd = true;
            const hasUnestimated: boolean = await this.allocationsService.checkForUnestimatedTasks(elementId, isTask);
            if (hasUnestimated) {
                canAdd = await this.dialogsService.ConfirmAsync(
                    TextResources.Allocations.AddUnestimatedElementToCartMessage,
                    TextResources.Allocations.AddUnestimatedElementToCartCancel,
                    TextResources.Allocations.AddUnestimatedElementToCartConfirm
                )
            }

            if (!canAdd)
                return;

            const allocatedOn = await this.getTeamsAllocatedOn();
            const isAllocated = allocatedOn.length > 0;
            if (isAllocated) {
                // Nel momento in cui il carrello è allocato si deve controllare se i team possono svolgere il lavoro per le mansioni presenti sull'attività aggiunta.
                const promises: (Promise<IMissingRole[]>)[] = [];
                for (const team of allocatedOn)
                    promises.push(this.allocationsService.getMissingRolesByElement(elementId, isTask, team.Id));

                const results = await Promise.all(promises);
                if (!results.firstOrDefault(r => !r || r.length === 0)) {
                    // Non c'è nessun team su cui il carrello è allocato che non abbia tutti i ruoli necessari per l'elemento che si sta tentando di aggiungere. Chiedo conferma.
                    let index = 0;
                    let message = "<br/><br/>";
                    for (const team of allocatedOn) {
                        const missingRoles = results[index];
                        if (missingRoles && missingRoles.length > 0) {
                            const units: string = missingRoles.map((o: IMissingRole) => { return o.RoleName; }).join(",");
                            message += String.format(TextResources.Allocations.MissedRolesOnTeamAlertAlt, team.Name, units) + '<br/>';
                        }

                        index++;
                    }

                    canAdd = await this.dialogsService.ConfirmAsync(String.format(TextResources.Allocations.MissingRolesOnTeamsAlertMessage, message + "<br/>"), TextResources.Allocations.MissedRolesOnTeamCancel, TextResources.Allocations.MissedRolesOnTeamConfirm);

                    if (!canAdd)
                        return;
                }
            }

            await this.cartsService.manageCartWorkEndDate(this.props.cart.Id, elementId, isTask)
            let element: ICartContentWithRolesInfo = null;
            if (!isAllocated)
                element = await this.cartsService.addElementToCart(this.props.cart.Id, elementId, isTask, 100);
            else
                element = await this.cartsService.addElementOnAllocatedCart(elementId, isTask, this.props.cart.Id, -1); // Non è necessario passare il team id perché il sistema ricalcola comunque tutte le allocazioni, non lavora per team. Comunque non è una cosa da passare dal client, deve essere il server a verificare se il carrello è allocato e in quel caso richiamare il ricalcolo delle allocazini.
            
            if (!element) {
                this.infoToastService.Warning(TextResources.Allocations.CanNotAllocateElementAlreadyAllocated);
                return;
            }

            this.CartContent.push(element);
            this.menuRef.refresh();
        } catch(e) {
            this.infoToastService.Error(TextResources.Allocations.AddElementToCartError);
        }
    }

    private async handleRemoveContent(cartContentItem: ICartContentWithRolesInfo): Promise<void> {
        try {
            const allocatedOn = await this.getTeamsAllocatedOn();
            const isAllocated = allocatedOn.length > 0;

            if (!isAllocated)
                await this.cartsService.deleteElementFromCart(this.props.cart.Id, cartContentItem.ElementInfo.ElementId, cartContentItem.ElementInfo.IsTask);
            else
                await this.cartsService.removeElementFromAllocatedCart(cartContentItem.ElementInfo.ElementId, cartContentItem.ElementInfo.IsTask, this.props.cart.Id, -1);

            const itemToRemove = this.CartContent().find(c => c.ElementInfo.Id === cartContentItem.ElementInfo.Id);
            this.CartContent.remove(itemToRemove);
            this.CartContent().forEach((i, index) => i.ElementInfo.Order = index + 1);
            this.menuRef.refresh();
        } catch (e) {
            console.log(e);
            this.infoToastService.Error(TextResources.Allocations.DeleteCartContentError);
        }
    }

    render() {
        const isNew = !this.props.cart || !this.props.cart.Id || this.props.cart.Id <= 0;

        const rows = ["min-content"];
        const columns = ["1fr"];

        if (!isNew) {
            rows.push("1fr");
            columns.push("270px");
        }

        return  <Layout.Grid rows={["1fr"]} columns={columns} style={{ height: "100%" }}>
                    <Layout.Grid.Cell row={1} column={1}>
                        <DropZone
                            allowedMimeTypes={['application/prolife-task', 'application/prolife-workflow']}
                            onDrop={this.handleElementDrop.bind(this)}

                            dropMessage={   <div className={ComponentUtils.classNames("text-center", classes["drop-zone-message"])}>
                                                <i className="fa fa-plus"></i>
                                                <span>{TextResources.Allocations.EditCartDialogDropZoneMessage}</span>
                                            </div>}
                        >
                            <Layout.ScrollContainer systemScrollable style={{ height: "100%" }}>
                                <div className={classes["cart-editor"]}>
                                    <CartForm
                                        cart={this.props.cart}
                                        resources={this.props.resources}
                                        links={this.props.links}
                                        onCartWorkableHoursCalculationModeChange={(mode) => this.props.onCartWorkableHoursCalculationModeChange && this.props.onCartWorkableHoursCalculationModeChange(mode)}
                                        onCartNameChanged={(name) => this.props.onCartNameChanged && this.props.onCartNameChanged(name)}
                                        forwardRef={(ref) => this.formRef = ref}
                                    />
                                </div>
                                {!isNew && (
                                    <div style={{ paddingRight: "15px" }}>
                                        <CartContent
                                            cartId={this.props.cart.Id}
                                            cartContent={this.CartContent}
                                            onRemoveContent={this.handleRemoveContent.bind(this)}
                                            onReorderContent={() => this.loadCartContent()}
                                        />
                                    </div>
                                )}
                            </Layout.ScrollContainer>
                        </DropZone>
                    </Layout.Grid.Cell>
                    {!isNew && (
                        <Layout.Grid.Cell row={1} column={2} className={classes.menu}>
                            <AllocationsJobOrdersMenu
                                useWhiteTheme
                                forwardRef={(menu) => this.menuRef = menu}
                            />
                        </Layout.Grid.Cell>
                    )}
                </Layout.Grid>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CartEditor);
}