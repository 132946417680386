import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IOffsetsService, IDocumentOffsetLeaf } from "../Invoices/OffsetsService";
import { DocumentOffsetsTypes } from "../Invoices/invoices/settings/ui/OffsetEditor";
import { BaseDataSource } from "./BaseDataSource";
import { Task } from "../ProlifeSdk/prolifesdk/utils/Task";
import { IDataSourceModel } from "./IDataSource";
import { Deferred } from "../Core/Deferred";

export interface IOffsetsCodesDataSourceModel extends IDataSourceModel<number, IDocumentOffsetLeaf> {

}

export class OffsetsCodesDataSource extends BaseDataSource<IOffsetsCodesDataSourceModel> {
    
    @LazyImport(nameof<IOffsetsService>())
    private offsetsService: IOffsetsService;

    private shared: boolean = false;
    private static cache : IDocumentOffsetLeaf[];
    private static loadingDeferred : Deferred<void> = new Deferred<void>();

    public async setSharedMode(shared: boolean) : Promise<void> {
        if(this.shared === shared)
            return;

        this.shared = shared;
        if(this.shared && OffsetsCodesDataSource.cache === undefined) {
            OffsetsCodesDataSource.cache = []; //In questo modo blocco il caricamento da parte degli altri data source
            OffsetsCodesDataSource.cache = await this.offsetsService.GetOffsetsLeafs(null, 0, 100000);
            OffsetsCodesDataSource.loadingDeferred.resolve();
        }
    }

    public getTitle(currentModel: IOffsetsCodesDataSourceModel): string {
        return ProlifeSdk.TextResources.Invoices.Offset;
    }
    
    public async getData(currentModel: IOffsetsCodesDataSourceModel, textFilter: string, skip: number = 0, count: number = 10000): Promise<IOffsetsCodesDataSourceModel[]> {
        if(this.shared) {
            await OffsetsCodesDataSource.loadingDeferred;

            let splits = (textFilter || "").toLowerCase().split(" ");
            return OffsetsCodesDataSource.cache
                .filter(o => !splits.any(s => (o.Code + " " + o.Description).toLowerCase().indexOf(s) == -1))
                .slice(skip, skip + count)
                .map(this.createModel, this);
        } else {
            let offsetsLeafs = await this.offsetsService.GetOffsetsLeafs(textFilter, skip, count);
            return offsetsLeafs.map(this.createModel, this);
        }
    }

    public async getById(currentModel: IOffsetsCodesDataSourceModel, ids: number[]): Promise<IOffsetsCodesDataSourceModel[]> {
        if(this.shared) {
            await OffsetsCodesDataSource.loadingDeferred;
            
            return OffsetsCodesDataSource.cache
                .filter(o => ids.indexOf(o.Id) != -1)
                .map(this.createModel, this);
        }
        else {
            let offsetsLeafs = await this.offsetsService.GetOffsetsLeafByIds(ids);
            return offsetsLeafs.map(this.createModel, this);
        }
    }

    private createModel(offsetLeaf: IDocumentOffsetLeaf): IDataSourceModel<number, IDocumentOffsetLeaf> {
        let typeDesc = offsetLeaf.Type === DocumentOffsetsTypes.BalanceSheet ? ProlifeSdk.TextResources.Invoices.BalanceSheet :
            offsetLeaf.Type === DocumentOffsetsTypes.IncomeStatement ? ProlifeSdk.TextResources.Invoices.IncomeStatement :
            ProlifeSdk.TextResources.Invoices.Accounts;

        return {
            id: offsetLeaf.Id,
            title: (offsetLeaf.Code || "") + " (" + typeDesc + ")",
            subTitle: offsetLeaf.Description,
            isLeaf: true,
            isGroup: false,
            model: offsetLeaf
        };
    }
}