import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { UsersControlsEntityProvider } from "./UsersControlsEntityProvider";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IUsersService } from "../../UsersService";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IUserForHint, IUser } from "../../../ProlifeSdk/interfaces/users/IUser";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class UserEntityProvider implements IEntityProvider<number, IUserForHint>
{
    private usersService : IUsersService;
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator ) {

        var entityProviderService : IEntityProviderService= <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
		entityProviderService.registerEntityProvider(this, this.getType());

        this.usersService = <IUsersService> serviceLocator.findService(ProlifeSdk.UsersServiceType);
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }

    getType() : string
    {
        return ProlifeSdk.UsersEntityType;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Users.User;
    }

    getPkValue(item : IUserForHint) : number
    {
        return item.Id;
    }

    getDisplayName(item : IUserForHint) : string
    {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.usersService.getUserFromKeyValue(pkValue).then(
            (user : IUser) => {
                def.resolve(user ? user.Cognome + " " + user.Nome : "");
            })
            .catch(() => { def.reject(); });
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IUserForHint[]>
    {
        return this.ajaxService.Get("Users-api/Users", "HintSearch?filter=" + encodeURI(stringFilter), {
            background: true
        });
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new UsersControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : any) : Promise<any>
    {
        //Da implementare se serve
        return Promise.resolve<any>(undefined);
    }
}