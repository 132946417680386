import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let EXIF : any;

export class ResizeImageDialog implements IDialog {

    SmallSize : ko.Observable<string> = ko.observable();
    SmallWidth : ko.Observable<number> = ko.observable();
    SmallHeight : ko.Observable<number> = ko.observable();
    SmallBlob : ko.Observable<Blob> = ko.observable();

    MediumSize : ko.Observable<string> = ko.observable();
    MediumWidth : ko.Observable<number> = ko.observable();
    MediumHeight : ko.Observable<number> = ko.observable();
    MediumBlob : ko.Observable<Blob> = ko.observable();

    BigSize : ko.Observable<string> = ko.observable();
    BigWidth : ko.Observable<number> = ko.observable();
    BigHeight : ko.Observable<number> = ko.observable();
    BigBlob : ko.Observable<Blob> = ko.observable();

    RealSize : ko.Observable<string> = ko.observable();
    RealWidth : ko.Observable<number> = ko.observable();
    RealHeight : ko.Observable<number> = ko.observable();
    RealBlob : ko.Observable<Blob> = ko.observable();

    SmallVisible : ko.Observable<boolean> = ko.observable(true);
    MediumVisible : ko.Observable<boolean> = ko.observable(true);
    BigVisible : ko.Observable<boolean> = ko.observable(true);

    FileName : ko.Observable<string> = ko.observable();

    templateName = "file-repository-resize-dialog";
    templateUrl = "fileRepository/templates";
    title: string = ProlifeSdk.TextResources.FileRepository.ResizeImage;
    modal: {
        close: (result?: unknown) => void;
    };

    private infoToastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private file : File) {
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);

        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.src = <string>reader.result;
            img.onload = () => {
                EXIF.getData(this.file, () => {
                    this.doResizes.call(this, img);
                });
            }
        };
        reader.readAsDataURL(file);

        //800x600 Piccola
        //1280x1024 Media
        //1920x1536 Grande

        this.RealSize(numeral(file.size).format('0,0[.]0 b'));
        this.RealBlob(file);

        const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        this.FileName(fileName + moment().format("DDMMYYYYHHmmSS") + "." + extension);
    }

    private doResizes(img : HTMLImageElement) {
        const orientation = EXIF.getTag(this.file, "Orientation");

        if(img.width <= 800 && img.height <= 600)
            this.SmallVisible(false);
        if(img.width <= 1280 && img.height <= 960)
            this.MediumVisible(false);
        if(img.width <= 1920 && img.height <= 1440)
            this.BigVisible(false);

        if(this.SmallVisible())
            this.getResizedSize(img, 800, 600, orientation, (width, height, blob) => {
                this.SmallSize(numeral(blob.size).format('0,0[.]0 b'));
                this.SmallWidth(width);
                this.SmallHeight(height);
                this.SmallBlob(blob);
            });
        if(this.MediumVisible())
            this.getResizedSize(img, 1280, 960, orientation, (width, height, blob) => {
                this.MediumSize(numeral(blob.size).format('0,0[.]0 b'));
                this.MediumWidth(width);
                this.MediumHeight(height);
                this.MediumBlob(blob);
            });
        if(this.BigVisible())
            this.getResizedSize(img, 1920, 1440, orientation, (width, height, blob) => {
                this.BigSize(numeral(blob.size).format('0,0[.]0 b'));
                this.BigWidth(width);
                this.BigHeight(height);
                this.BigBlob(blob);
            });

        this.RealWidth(img.width);
        this.RealHeight(img.height);
    }

    private getResizedSize(img : HTMLImageElement, maxWidth : number, maxHeight : number, orientation : number, callback : (width : number, height : number, blob : Blob) => void) {
        const canvas = <HTMLCanvasElement>document.createElement('canvas');
        //var ctx = canvas.getContext("2d");

        let imageWidth = img.naturalWidth;
        let imageHeight = img.naturalHeight;
        let width = imageWidth;
        let height = imageHeight;
        const doSquash = this.file.type == 'image/jpeg';

        if (imageWidth > imageHeight) {
            if (imageWidth > maxWidth) {
                imageHeight *= maxWidth / imageWidth;
                imageWidth = maxWidth;
            }
        } else {
            if (imageHeight > maxHeight) {
                imageWidth *= maxHeight / imageHeight;
                imageHeight = maxHeight;
            }
        }
        canvas.width = imageWidth;
        canvas.height = imageHeight;

        if (maxWidth && width > maxWidth) {
            width = maxWidth;
            height = (imageHeight * width / imageWidth) << 0;
        }
        if (maxHeight && height > maxHeight) {
            height = maxHeight;
            width = (imageWidth * height / imageHeight) << 0;
        }

        this.renderImageToCanvas(img, canvas, width, height, orientation, doSquash);

        const finalFile = canvas.toDataURL("image/jpeg", 0.8);
        const blob = this.dataURItoBlob(finalFile);

        callback(Math.floor(imageWidth), Math.floor(imageHeight), blob);
    }

    private renderImageToCanvas(img : HTMLImageElement, canvas : HTMLCanvasElement, width : number, height  : number, orientation : number, doSquash : boolean) {
        let iw = img.naturalWidth, ih = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.save();
        this.transformCoordinate(canvas, width, height, orientation);
        const subsampled = this.detectSubsampling(img);
        if (subsampled) {
            iw /= 2;
            ih /= 2;
        }
        const d = 1024; // size of tiling canvas
        let tmpCanvas = <HTMLCanvasElement>document.createElement('canvas');
        tmpCanvas.width = tmpCanvas.height = d;
        let tmpCtx = tmpCanvas.getContext('2d');
        const vertSquashRatio = doSquash ? this.detectVerticalSquash(img, iw, ih) : 1;
        const dw = Math.ceil(d * width / iw);
        const dh = Math.ceil(d * height / ih / vertSquashRatio);
        let sy = 0;
        let dy = 0;
        while (sy < ih) {
            let sx = 0;
            let dx = 0;
            while (sx < iw) {
                tmpCtx.clearRect(0, 0, d, d);
                tmpCtx.drawImage(img, -sx, -sy);
                ctx.drawImage(tmpCanvas, 0, 0, d, d, dx, dy, dw, dh);
                sx += d;
                dx += dw;
            }
            sy += d;
            dy += dh;
        }
        ctx.restore();
        tmpCanvas = tmpCtx = null;
    }

    /**
     * Detect subsampling in loaded image.
     * In iOS, larger images than 2M pixels may be subsampled in rendering.
     */
    private detectSubsampling(img) : boolean {
        const iw = img.naturalWidth, ih = img.naturalHeight;
        if (iw * ih > 1024 * 1024) { // subsampling may happen over megapixel image
            const canvas = <HTMLCanvasElement>document.createElement('canvas');
            canvas.width = canvas.height = 1;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, -iw + 1, 0);
            // subsampled image becomes half smaller in rendering size.
            // check alpha channel value to confirm image is covering edge pixel or not.
            // if alpha value is 0 image is not covering, hence subsampled.
            return ctx.getImageData(0, 0, 1, 1).data[3] === 0;
        } else {
            return false;
        }
    }

    /**
     * Detecting vertical squash in loaded image.
     * Fixes a bug which squash image vertically while drawing into canvas for some images.
     */
    private detectVerticalSquash(img, iw, ih) : number {
        const canvas = <HTMLCanvasElement>document.createElement('canvas');
        canvas.width = 1;
        canvas.height = ih;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const data = ctx.getImageData(0, 0, 1, ih).data;
        // search image edge pixel position in case it is squashed vertically.
        let sy = 0;
        let ey = ih;
        let py = ih;
        while (py > sy) {
            const alpha = data[(py - 1) * 4 + 3];
            if (alpha === 0) {
                ey = py;
            } else {
                sy = py;
            }
            py = (ey + sy) >> 1;
        }
        const ratio = (py / ih);
        return (ratio===0)?1:ratio;
    }

    /**
     * Transform canvas coordination according to specified frame size and orientation
     * Orientation value is from EXIF tag
     */
    private transformCoordinate(canvas, width, height, orientation) {
        switch (orientation) {
            case 5:
            case 6:
            case 7:
            case 8:
                canvas.width = height;
                canvas.height = width;
                break;
            default:
                canvas.width = width;
                canvas.height = height;
        }
        const ctx = canvas.getContext('2d');
        switch (orientation) {
            case 2:
                // horizontal flip
                ctx.translate(width, 0);
                ctx.scale(-1, 1);
                break;
            case 3:
                // 180 rotate left
                ctx.translate(width, height);
                ctx.rotate(Math.PI);
                break;
            case 4:
                // vertical flip
                ctx.translate(0, height);
                ctx.scale(1, -1);
                break;
            case 5:
                // vertical flip + 90 rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.scale(1, -1);
                break;
            case 6:
                // 90 rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(0, -height);
                break;
            case 7:
                // horizontal flip + 90 rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(width, -height);
                ctx.scale(-1, 1);
                break;
            case 8:
                // 90 rotate left
                ctx.rotate(-0.5 * Math.PI);
                ctx.translate(-width, 0);
                break;
            default:
                break;
        }
    }

    resizeSmall() {
        if(this.FileName().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.PleaseProvideAFileName);
            return;
        }

        const result = {};
        result[this.FileName()] = this.SmallBlob();
        this.modal.close(result);
    }

    resizeMedium() {
        if(this.FileName().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.PleaseProvideAFileName);
            return;
        }

        const result = {};
        result[this.FileName()] = this.MediumBlob();
        this.modal.close(result);
    }

    resizeBig() {
        if(this.FileName().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.PleaseProvideAFileName);
            return;
        }

        const result = {};
        result[this.FileName()] = this.BigBlob();
        this.modal.close(result);
    }

    doNotResize() {
        if(this.FileName().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.PleaseProvideAFileName);
            return;
        }

        const result = {};
        result[this.FileName()] = this.file;
        this.modal.close(result);
    }

    close(): void {
        this.modal.close();
    }

    action(): void {

    }

    private dataURItoBlob(dataURI) : Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const dataView = new DataView(ab);
        return new Blob([dataView], { 'type': mimeString });
    }
}