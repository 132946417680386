import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInvalidHoursForResourceInfo } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";

export class InvalidHoursReportDialog implements IDialog {
    public templateName: string = "invalid-hours-report-dialog";
    public templateUrl: string = "resourcesmanager/templates/areas/resources";
    public title: string = ProlifeSdk.TextResources.ResourcesManager.HoursNotValidCheckReportTitle;
    modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private invalidHours : IInvalidHoursForResourceInfo[]){

    }

    public close(): void{
        this.modal.close();
    }

    public action(): void{

    }

    public showModal() : Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", null, this.templateUrl, this.templateName);
    }
}