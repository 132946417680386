import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/05/2018
 * Time: 12:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ITrustUpdateDelaysOnPaymentsEditingViewModel } from "../../../interfaces/ITrustUpdateDelaysOnPaymentsEditingViewModel";
import { ITrustUpdateDelayOnPaymentViewModel } from "../../../interfaces/ITrustUpdateDelayOnPaymentViewModel";
import { ITrustUpdateDelaysOnPaymentsSettingsManager, ITrustDelayOnPaymentWithLabel, ITrustDelayOnPayment } from "../../../../ProlifeSdk/interfaces/customer/ITrustUpdateDelaysOnPaymentsSettingsManager";

export class TrustUpdateDelaysOnPaymentsEditingViewModel implements ITrustUpdateDelaysOnPaymentsEditingViewModel {
    public title: string = ProlifeSdk.TextResources.Customers.TrustsDelaysEditingPanelTitle;
    public Delays: ko.ObservableArray<ITrustUpdateDelayOnPaymentViewModel> = ko.observableArray([]);

    private nextTemporaryId: number = 0;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private settingsManager: ITrustUpdateDelaysOnPaymentsSettingsManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Delays(this.settingsManager.GetDelays().map((d: ITrustDelayOnPaymentWithLabel) => this.CreateDelayViewModel(d)));
    }

    public CreateOrUpdateDelay(delay: ITrustUpdateDelayOnPaymentViewModel): Promise<ITrustDelayOnPayment> {
        return this.settingsManager.CreateOrUpdateIncrementDelay(delay.GetData())
            .then((newDelay: ITrustDelayOnPayment) => {
                delay.UpdateFromModel(newDelay);
                this.infoToastService.Success(ProlifeSdk.TextResources.Customers.TrustUpdateDelaySavingSuccess);
                return newDelay;
            });
    }

    private CreateDelayViewModel(delay: ITrustDelayOnPaymentWithLabel): ITrustUpdateDelayOnPaymentViewModel {
        if (delay.Id == null)
            delay.Id = this.nextTemporaryId--;

        return new TrustUpdateDelayOnPaymentViewModel(this.serviceLocator, delay, this);
    }
}

class TrustUpdateDelayOnPaymentViewModel implements ITrustUpdateDelayOnPaymentViewModel {
    public PaymentMode: ko.Observable<string> = ko.observable();
    public Delay: ko.Observable<number> = ko.observable();

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private delay: ITrustDelayOnPaymentWithLabel, private editor: ITrustUpdateDelaysOnPaymentsEditingViewModel) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Delay(this.delay.Delay);
        this.PaymentMode(this.delay.PaymentLabel);

        var lastDelayValue: number = this.Delay();
        var updating: boolean = false;
        this.Delay.subscribe((value: number) => {
            if (updating)
                return;

            updating = true;

            if (value < 0) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Customers.PositiveValueRequired);
                this.Delay(lastDelayValue);
                updating = false;
                return;
            }

            this.Save()
                .then(() => {
                    lastDelayValue = value;
                })
                .finally(() => {
                    updating = false;
                });
        });
    }

    public Save(): Promise<ITrustDelayOnPayment> {
        return this.editor.CreateOrUpdateDelay(this);
    }

    public UpdateFromModel(delay: ITrustDelayOnPayment): void {
        this.delay.Id = delay.Id;
        this.delay.PaymentTypeId = delay.PaymentTypeId;
        this.delay.Delay = delay.Delay;
        this.Delay(delay.Delay);
    }

    public GetData(): ITrustDelayOnPayment {
        return {
            Id: this.delay.Id,
            PaymentTypeId: this.delay.PaymentTypeId,
            Delay: this.Delay()
        };
    }
}