import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 04/05/2018
 * Time: 17:19
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { TrustsSettingsEditingViewModel } from "./ui/TrustsSettingsEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ITrustsSettingsManager, ITrustGeneralSettings } from "../../../ProlifeSdk/interfaces/customer/ITrustsSettingsManager";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class TrustsSettingsManager implements ITrustsSettingsManager {
    private settings: ITrustGeneralSettings;

    private ajaxService: IAjaxService;
    private modulesService: IModulesService;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Customers.CustomersSettingsGroup);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<ITrustGeneralSettings> {
        return this.ajaxService.Get<ITrustGeneralSettings>("Customers-api", "TrustsGeneralSettings", {})
            .then((settings: ITrustGeneralSettings) => {
                this.settings = settings;
                return settings;
            });
    }

    getName(): string {
        return ProlifeSdk.TrustsSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Customers.TrustsGeneralSettingsLabel;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.CustomersApplication);
    }

    getEditingUI(): IView {
        return {
            templateName: "trusts-settings",
            templateUrl: "customers/templates/settings",
            viewModel: new TrustsSettingsEditingViewModel(this.serviceLocator, this)
        };
    }

    GetSettings(): ITrustGeneralSettings {
        return this.settings;
    }

    UpdateSettings(trustsSettings: ITrustGeneralSettings): Promise<ITrustGeneralSettings> {
        return this.ajaxService.Post<ITrustGeneralSettings>("Customers-api", "TrustsGeneralSettings", { methodData: trustsSettings })
            .then((updatedSettings: ITrustGeneralSettings) => {
                this.settings = updatedSettings;
                return this.settings;
            });
    }

    GetTrustEnabledByDefault(): boolean {
        return this.settings.TrustEnabledByDefault;
    }

    GetTrustLimitOnPeriodWithoutConfiguredTrust(): number {
        return this.settings.TrustLimitOnPeriodWithoutConfiguredTrust;
    }

    GetConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrustSetting(): boolean {
        return this.settings.ConsiderIllimitatedTrustOnPeriodWithoutConfiguredTrust;
    }

    GetShowAlertOnSaveIfTrustIsNotConfigured(): boolean {
        return this.settings.ShowAlertOnSaveIfTrustIsNotConfigured;
    }

    GetStartDocumentsEmissionDateForTrustMovements(): Date {
        return this.settings.StartDocumentsEmissionDateForTrustMovements;
    }
}