import * as ko from "knockout";
import * as moment from "moment-timezone";
import { TimeZoneDataSource } from "../DataSources/TimeZoneDataSource";
import { IDataSourceListener } from "../DataSources/IDataSource";

export interface ITimeZonePickerComponent {
    
}

export interface ITimeZonePickerComponentParameters {
    ReadOnly? : boolean | ko.Observable<boolean>;
    Nullable? : boolean | ko.Observable<boolean>;
    Placeholder? : string;

    Label: string;
    TimeZone?: ko.Observable<string>;

    Listener?: IDataSourceListener | IDataSourceListener[];
    InjectTo?: ko.Observable<ITimeZonePickerComponent>;
}

export class TimeZonePickerComponet implements ITimeZonePickerComponent {
    ReadOnly : boolean | ko.Observable<boolean>;
    Nullable : boolean | ko.Observable<boolean>;
    Placeholder : string;
    Label : string;
    TimeZone : ko.Observable<string>;
    TimeZoneDataSource : TimeZoneDataSource;

    private listeners : IDataSourceListener[];

    constructor(params : ITimeZonePickerComponentParameters, private element : Element) {
        this.ReadOnly = params.ReadOnly || false;
        this.Nullable = params.Nullable || false;
        this.Placeholder = params.Placeholder || "";
        this.Label = params.Label || "";
        this.TimeZone = params.TimeZone || ko.observable(!ko.utils.unwrapObservable(this.Nullable) ? moment.tz.guess() : null);
        this.TimeZoneDataSource = new TimeZoneDataSource();

        if (!this.TimeZone() && !ko.utils.unwrapObservable(this.Nullable))
            this.TimeZone(moment.tz.guess());
        
        if(params.Listener && !Array.isArray(params.Listener))
            params.Listener = [params.Listener];
        this.listeners = <IDataSourceListener[]> params.Listener || [];
    }

    public detectTimeZone(): void {
        this.TimeZone(moment.tz.guess());
    }
}

ko.components.register('timezone-picker', {
    viewModel: {
        createViewModel: (params : ITimeZonePickerComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            return new TimeZonePickerComponet(params, componentInfo.element as Element);
        }
    },
    template: `<select2 params="Label: Label, Value: TimeZone, DataSource: TimeZoneDataSource, Placeholder: Placeholder, AllowClear: Nullable">
                    <button class="btn btn-primary" data-bind="click: detectTimeZone, tooltip: {}" type="button" title="Imposta il fuso orario del browser">
                        <i class="fa fa-map-marker"></i>
                    </button>
                </select2>`
});