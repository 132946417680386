import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ExpenseProject } from "../ExpenseProject";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { ResourcesDataSource } from "../../../../DataSources/ResourcesDataSource";
import { ArrayDataSource } from "../../../../DataSources/ArrayDataSource";
import { ExpenseCostRow, IExpenseCostRowListener } from "../ExpenseCostRow";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { ExpenseLogicalStatus } from "../../enums/ExpenseLogicalStatus";
import { IExpenseStatusesSettingsManager } from "../../settings/ExpenseStatusesSettingsManager";
import { KeyboardPanel } from "../../../../Components/KeyboardPanel/KeyboardPanelComponent";
import { Portlet } from "../../../../Components/KeyboardPanel/PortletComponent";
import { ExpenseEditorSection } from "../../enums/ExpenseEditorSection";
import { Right } from "../../../../Core/Authorizations";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IExpenseService, IExpenseStatus, IFullExpense, IExtendedFullExpense, IInsertOrUpdateFullExpensesRequest, IFullExpenseExpensesCostRows, IFullExpenseExpensesProjects, IExpenseEventsAndPurchasesPurchases } from "../../../../ProlifeSdk/interfaces/expense/IExpenseService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IAttachmentsManagerPathProvider } from "../../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { ExpenseProjectPurchase } from "../ExpenseProjectPurchase";

const { classes } = jss.createStyleSheet({
    "expense-editor": {
        "& .notes-wrapper": {
            "&.hide": {
                display: "none"
            }
        },
    
        "& .table-wrapper": {
            "--text-column-padding": "0px 5px",
            "padding-right": "15px",

            "& .btn-group": {
                "& .btn": {
                    margin: "0px"
                }
            },

            "& .secondary-table-wrapper": {
                margin: "0px",
                padding: "5px 10px",
                "background-color": "#dedede"
            },

            "& .has-attachments": {
                color: "black"
            },

            "& .no-attachments": {
                color: "#e5e5e5"
            },

            "& .jobOrder-col": {
                width: "60%"
            },

            "& .percentage-col": {
                width: "10%"
            },

            "& .notes-col": {
                width: "50%"
            },

            "& .actions-col": {
                width: "5%"
            },
            
            "& .job-order-actions-col": {
                width: "30%"
            },

            "& .cost-description-col": {
                width: "35%"
            },

            "& .purchase-type-col": {
                width: "20%"
            },

            "& .readonly-purchase-type-col": {
                width: "15%"
            },

            "& .readonly-col": {
                "vertical-align": "middle",
                padding: "var(--text-column-padding) !important"
            },

            "& .purchase-description-col": {
                width: "20%"
            },

            "& .purchase-footer-empty-col": {
                width: "90%"
            },

            "& .purchase-footer-total-col": {
                width: "10%"
            },

            "& .purchase-actions-col": {
                width: "8%",
                padding: "3px 5px !important"
            },

            "& .workflow-col": {
                width: "25%"
            },
            
            "& .task-col": {
                width: "25%"
            },

            "& .small-task-col": {
                width: "17%"
            },

            "& .billable-col": {
                width: "8%"
            },

            "& .amount-col": {
                width: "5%",

                "& .form-group": {
                    marginRight: "5px"
                }
            },

            "& .unit-cost-col": {
                width: "10%",

                "& .input-group-btn": {
                    position: "relative",
                    right: "-6px"
                }
            },
            
            "& .total-cost-col": {
                width: "10%"
            },

            "& .sustained-by-the-company-col": {
                width: "10%",
            },

            "& .centered-checkbox-col": {
                "& .flex-container": {
                    "justify-content": "center",
                    "justify-items": "center",
                    "margin-top": "4px"
                }
            },

            "& .attachments-column": {
                width: "5%"
            },

            "& .attachments-number": {
                "margin-right": "7px"
            },

            "& .footer-left-col": {
                width: "70%"
            },

            "& .footer-total-col": {
                width: "10%"
            },

            "& .footer-right-col": {
                width: "20%"
            },

            "& .purchases-visibility-switch": {
                "margin-right": "6px"
            },

            "& .projects-secondary-row": {

            },

            "& .total-percentage-warning": {
                color: "white"
            }
        },

        "& .workflows-and-tasks-menu-wrapper": {
            "& .page-quick-sidebar-wrapper": {
                position: "static",
                height: "100%"
            }
        },

        "& .create-activity-button": {
            "margin-right": "5px",

            "& i": {
                "margin-right": "5px"
            }
        }
    },

    "notes-button": {
        width: "20px",
        height: "20px",
        "text-align": "center",
        border: "1px solid black",
        "border-radius": "10px !important",
        padding: "0px",
        "margin-left": "6px",

        "& i": {
            margin: "0px",

            "&.fa-chevron-up": {
                position: "relative",
                top: "-2px"
            }
        },

        "&:focus": {
            outline: "none"
        }
    },

    "secondary-tables-wrapper": {
        "background-color": "#dedede"
    },

    "project-notes-wrapper": {
        "margin": "10px -5px"
    },

    "currency-input-popover": {
        width: "600px"
    }
}).attach();


export interface IExpenseEditDialogComponentParams {
    expenseId: number;
}

export type PurchaseTypeId = number;
export type Total = number;

@DetectClassChanges
export class ExpenseEditDialogComponent extends DialogComponentBase implements IDataSourceListener, IExpenseCostRowListener, IAttachmentsManagerPathProvider
{
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<IUserInfo>())
    private usersInfo: IUserInfo;
    @LazyImportSettingManager(nameof<IExpenseStatusesSettingsManager>())
    private expenseStatusesManager: IExpenseStatusesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesManager: IHumanResourcesSettingsManager;

    public Id: number;

    public ProtocolId: ko.Observable<number> = ko.observable(0);
    public ProtocolYear: ko.Observable<number> = ko.observable(0);
    @DetectChanges
    public ResourceId: ko.Observable<number> = ko.observable(0);
    public ResourceName: ko.Observable<string> = ko.observable("");
    @DetectChanges
    public From: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public To: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public Place: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Motive: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Notes: ko.Observable<string> = ko.observable('');
    public NotesTrigger: ko.Observable<any> = ko.observable();
    @DetectChanges
    public ExpenseStatus : ko.Observable<number> = ko.observable();
    
    public PercentageFormat: ko.Observable<string> = ko.observable("0,0.00[0000000] %");
    public ShowNotes: ko.Observable<boolean> = ko.observable(false);

    public CanEditCostRows: ko.Observable<boolean> = ko.observable(true);
    public ShowUnlockCostsButton: ko.Observable<boolean> = ko.observable(false);

    public IsNew: ko.Observable<boolean> = ko.observable();
    @DetectChanges
    public Total: ko.NotifiableComputed<number>;
    public Duration: ko.Computed<number>;
    public TotalProjectsPercentage: ko.NotifiableComputed<number>;
    public TotalProjectsPercentageFieldClasses: ko.Computed<string>;
    public TotalProjectsCosts: ko.NotifiableComputed<number>;
    public TotalProjectsExpectedRevenues: ko.NotifiableComputed<number>;
    public ShowDeleteButton: ko.Computed<boolean>;

    public HumanResourcesDataSource: ResourcesDataSource;
    public ExpenseStatusesDataSource: ArrayDataSource<IExpenseStatus>;

    public ExpenseProjects: ko.ObservableArray<ExpenseProject> = ko.observableArray([]);
    public ExpenseCostRows: ko.ObservableArray<ExpenseCostRow> = ko.observableArray([]);
    
    public KeyboardPanel: KeyboardPanel;

    public isChanged: ko.Observable<number> = ko.observable(0);

    @Right("Expense_CanViewExpectedRevenues")
    public CanViewExpectedRevenues: boolean;
    @Right("Expense_CanViewJobOrdersCostsDistribution")
    public CanViewJobOrdersCostsDistribution: boolean;
    @Right("Expense_ExpensesAdministrativeEdit")
    public ExpenseAdministrativeEdit: boolean;
    @Right("Expense_EditCompanyCosts")
    public CanEditCompanyCosts: boolean;
    @Right("Expense_CanApproveExpenses")
    public CanApproveExpenses: boolean;
    @Right("Expense_CanRefundExpenses")
    public CanRefundExpenses: boolean;

    private fullExpense: IFullExpense;
    private temporaryFileRepositoryPath: string;
    private initialFileRepositoryPath: string;
    private loading = false;
    
    constructor(params: IExpenseEditDialogComponentParams)
    {
        super({
            className: "fullscreen"
        });

        this.Id = params.expenseId;
        this.title(!this.Id ? ProlifeSdk.TextResources.Expenses.NewExpense : ProlifeSdk.TextResources.Expenses.ModifyExpense);

        this.CanViewJobOrdersCostsDistribution = this.CanViewExpectedRevenues || this.CanViewJobOrdersCostsDistribution;

        this.ExpenseStatusesDataSource = new ArrayDataSource();

        this.HumanResourcesDataSource = new ResourcesDataSource();
        this.HumanResourcesDataSource.setGetMaterialResources(false);
        this.HumanResourcesDataSource.setIncludeDeletedResources(false);
        this.HumanResourcesDataSource.setIncludeDisabledResources(false);
        this.HumanResourcesDataSource.setDateValidityForServiceOrders(null);

        this.loadExpensesStatusesOnDataSource();
        
        this.IsNew(!this.Id || this.Id < 1);

        this.ShowDeleteButton = ko.computed(() => {
            return !this.IsNew();
        });

        this.Duration = ko.computed(() => {
            if(!this.From() || !this.To())
                return 1;

            return moment(this.To()).diff(moment(this.From()), 'days') + 1;
        });

        this.TotalProjectsPercentage = ko.utils.notifyableComputed(() => {
            const projects = this.ExpenseProjects();
            const totalPercentage = projects.sum(p => p.Percentage() || 0);

            this.updatePurchasesCostsOnProjects();

            return totalPercentage;
        });

        this.TotalProjectsPercentageFieldClasses = ko.computed(() => {
            const baseClass = "text-right";
            return this.TotalProjectsPercentage() !== 100 ? baseClass + " total-percentage-warning bg-red" : baseClass;
        });

        this.TotalProjectsCosts = ko.utils.notifyableComputed(() => {
            const projects = this.ExpenseProjects();
            return projects.sum(p => p.TotalCosts() || 0);
        });
        
        this.TotalProjectsExpectedRevenues = ko.utils.notifyableComputed(() => {
            const projects = this.ExpenseProjects();
            return projects.sum(p => p.TotalExpectedRevenues() || 0);
        });

        this.Total = ko.utils.notifyableComputed(() => {
            const costRows = this.ExpenseCostRows();
            return costRows.sum(c => (c.TotalCost() || 0));    
        });

        this.Total.subscribe((newTotal: number) => {
            this.updatePurchasesCostsOnProjects();
        });

        this.From.subscribe((date: Date) => {
            if (this.loading)
                return;

            if (!date) {
                this.From(moment().toDate());
                return;
            }

            this.To(moment(date).toDate());
        });

        this.loadFullExpense();
    }

    private setExpenseProjectsRows(rows: ExpenseProject[]) {
        this.TotalProjectsPercentage.valueWillMutate();
        this.TotalProjectsCosts.valueWillMutate();
        this.TotalProjectsExpectedRevenues.valueWillMutate();

        this.ExpenseProjects(rows);
        
        this.TotalProjectsPercentage.valueHasMutated();
        this.TotalProjectsCosts.valueHasMutated();
        this.TotalProjectsExpectedRevenues.valueHasMutated();
    }

    private setExpenseCostRows(rows: ExpenseCostRow[]) {
        this.Total.valueWillMutate();

        this.ExpenseCostRows(rows);
        
        this.Total.valueHasMutated();
    }
    
    public onPurchaseTypeChanged(purchaseType: number): void {
        this.updatePurchasesOnProjects();
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.HumanResourcesDataSource) {
            this.ResourceName(model?.title);
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.HumanResourcesDataSource) {
            this.ResourceName(model?.title);
        }
    }
    
    public async close()
    {
        if (this.isChanged() > 0) {
            if(!await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.Expenses.CloseMsg, ProlifeSdk.TextResources.Expenses.CloseMsgCancel, ProlifeSdk.TextResources.Expenses.CloseMsgConfirm))
                return;
        }

        this.modal.close();
    }

    public async action()
    {
        const validationResult = this.validate();
        if (!validationResult)
            return;
        
        const warnings: string[] = this.getSaveWarnings();
        if (warnings.length > 0) {
            const lines: string = warnings.join("<br/>");
            const message: string = String.format(TextResources.Expenses.OnSaveWarnings, "<br/>" + lines);

            if(!await this.dialogsService.ConfirmAsync(message, TextResources.Expenses.OnSaveWarningsCancel, TextResources.Expenses.OnSaveWarningsConfirm))
                return;
        }
            
        try
        {
            const savedExpense = await this.doSave();
            if (!savedExpense)
                return;

            this.modal.close(savedExpense);
        }
        catch
        {
            this.infoToastService.Error(ProlifeSdk.TextResources.Expenses.SaveError, ProlifeSdk.TextResources.Expenses.SaveErrorTitle)
        }
    }
    
    public async validate(): Promise<boolean>
    {
        if (!this.ResourceId()) {
            this.infoToastService.Error(TextResources.Expenses.MissingResourceError);
            return false;
        }

        if (!this.From()) {
            this.infoToastService.Error(TextResources.Expenses.MissingFromDateError);
            return false;
        }

        if (!this.ExpenseStatus()) {
            this.infoToastService.Error(TextResources.Expenses.MissingExpenseStatusError);
            return false;
        }

        if (this.Duration() < 1)
        {
            this.infoToastService.Error(ProlifeSdk.TextResources.Expenses.InvalidInterval);
            return false;
        }

        const projects = await this.ExpenseProjects();
        if (projects.filter((p: ExpenseProject) => !p.ProjectId() || p.ProjectId() == 0).length > 0)
        {
            this.infoToastService.Error(ProlifeSdk.TextResources.Expenses.SelectProject);
            return false;
        }

        if (projects.distinctBy<number>((p: ExpenseProject) => p.ProjectId()).length !== projects.length) {
            this.infoToastService.Error(TextResources.Expenses.DuplicatedJobOrdersError)
            return false;
        }

        const costsRows = this.ExpenseCostRows();
        if (costsRows.filter(c => !c.PurchaseTypeId()).length > 0) {
            this.infoToastService.Error(TextResources.Expenses.MissingPurchaseType);
            return false;
        }

        if (costsRows.filter(c => !c.Description()).length > 0) {
            this.infoToastService.Error(TextResources.Expenses.MissingCostRowDescription);
            return false;
        }

        return true;
    }

    public unlockCostsTable(): void {
        this.CanEditCostRows(true);
        this.ShowUnlockCostsButton(false);
    }

    public async addNewProject(): Promise<void>
    {
        const emptyProject = new ExpenseProject();

        const initialPercentage = Math.max(100 - this.TotalProjectsPercentage(), 0);
        emptyProject.Percentage(initialPercentage);

        const projects = this.ExpenseProjects();
        projects.push(emptyProject);

        this.addPurchasesToProject(emptyProject);
        this.setExpenseProjectsRows(projects);
    }
    
    public async removeProject(project: ExpenseProject): Promise<void>
    {
        const projects = this.ExpenseProjects();

        for (let i = 0; i < projects.length; i++) {
            const p = projects[i];

            if (p.Id === project.Id ) {
                projects.splice(i, 1);
                break;
            }
        }

        this.setExpenseProjectsRows(projects);
    }

    public async addNewCostRow(): Promise<void>
    {
        const emptyRow = new ExpenseCostRow(this);

        const costRows = await this.ExpenseCostRows();

        if (costRows.length > 0) {
            const lastRow = costRows.lastOrDefault();
            emptyRow.CurrencyId(lastRow.CurrencyId());
            emptyRow.ExchangeValue(lastRow.ExchangeValue());
        }

        emptyRow.addListener(this);
        costRows.push(emptyRow);

        this.setExpenseCostRows(costRows);
    }
    
    public async removeCostRow(costRow: ExpenseCostRow): Promise<void>
    {
        const costsRows = await this.ExpenseCostRows();

        for (let i = 0; i < costsRows.length; i++) {
            const p = costsRows[i];

            if (p.Id === costRow.Id) {
                const removedItem = costsRows.splice(i, 1);
                removedItem?.firstOrDefault()?.removeListener(this);
                break;
            }
        }
        
        this.setExpenseCostRows(costsRows);
        this.updatePurchasesOnProjects();
    }

    public toggleNotesVisibility(): void {
        this.ShowNotes(!this.ShowNotes());
    }

    public async getData(): Promise<IFullExpense>
    {
        const expense = this.fullExpense.Expenses.firstOrDefault();

        expense.ResourceId = this.ResourceId();
        expense.Total = this.Total();
        expense.From = this.From();
        expense.To = this.To();
        expense.Notes = this.Notes();
        expense.ResourceName = this.ResourceName();
        expense.Place = this.Place();
        expense.Motive = this.Motive();
        expense.FkExpenseStatus = this.ExpenseStatus();

        this.fullExpense.Expenses = [expense];

        const jobOrders = await this.ExpenseProjects();
        this.fullExpense.ExpensesProjects = jobOrders.map(p => { const model = p.getData(); model.FkExpense = expense.Id; return model; });

        const costsRows = await this.ExpenseCostRows();
        this.fullExpense.ExpensesCostRows = costsRows.map(r => { const model = r.getData(); model.FkExpense = expense.Id; return model; });
        this.fullExpense.ExpensesCostRowsAttachments = costsRows.selectMultiple(r => r.getAttachmentsData());
        this.fullExpense.ExpensesPurchases = jobOrders.selectMultiple(j => j.getPurchasesData());
        return this.fullExpense;
    }

    public renderProjectsTableHeaderActions(): React.ReactNode {
        return (
            <a class="btn btn-primary btn-xs" data-bind="click: vm.addNewProject.bind(vm)">
                <i class="fa fa-plus"></i>
            </a>
        );
    }

    public renderProjectsTableTotalCostsAndExpectedRevenues(): React.ReactNode {
        return (
            <>
                <div class="bold">
                    {TextResources.Expenses.ProjectsTotalCosts} <span data-bind="moneyText: vm.TotalProjectsCosts"></span>
                </div>
                <div class="bold">
                    {TextResources.Expenses.ProjectsTotalExpectedRevenues} <span data-bind="moneyText: vm.TotalProjectsExpectedRevenues"></span>
                </div>
            </>
        );
    }
    
    public renderProjectsTableTotalPercentage(): React.ReactNode {
        return (
            <>
                <span class="bold">{TextResources.Expenses.ProjectsTotalPercentage} <span class="bold" data-bind="percentageText: vm.TotalProjectsPercentage, format: vm.PercentageFormat"></span></span>
            </>
        );
    }

    public renderCostRowsTableHeaderActions(): React.ReactNode {
        return (
            <>
                <button class="btn btn-primary btn-xs" data-bind="click: vm.addNewCostRow.bind(vm, $data), enable: vm.CanEditCostRows, visible: vm.CanEditCostRows">
                    <i class="fa fa-plus"></i>
                </button>
                <button class="btn btn-primary btn-xs" data-bind="click: vm.unlockCostsTable.bind(vm, $data), visible: vm.ShowUnlockCostsButton">
                    <i class="fa fa-unlock-alt"></i>
                </button>
            </>
        );
    }

    public renderEmptyColumn(): React.ReactNode {
        return (<></>);
    }

    public renderCostRowsTableTotalCostColumn(): React.ReactNode {
        let vm : ExpenseEditDialogComponent;

        return (
            <>
                <span class="bold">{TextResources.Expenses.TotalCost}</span>
                <span class="bold pull-right" data-bind={{ moneyText: vm.Total }}></span>
            </>
        );
    }

    public renderProjectSecondaryRow(item: ITableItem<ExpenseProject>) {
        let vm : ExpenseEditDialogComponent;

        const footerEmptyColumnNodesProvider = function() {
            return (
                <></>
            );
        };

        const footerTotalCostsColumnNodesProvider = function() {
            return (
                <>
                    <span class="bold">{TextResources.Expenses.TotalCost}</span>
                    <span class="bold pull-right total-cost" data-bind={{ moneyText: project.TotalCosts }}></span>
                </>
            );
        };

        const footerTotalExpectedRevenuesColumnNodesProvider = function() {
            return (
                <>
                    <span class="bold">{TextResources.Expenses.TotalCost}</span>
                    <span class="bold pull-right total-cost" data-bind={{ moneyText: project.TotalExpectedRevenues }}></span>
                </>
            );
        };

        let row : IDataSourceModel<number, ExpenseProject>;
        let project: ExpenseProject;
        let costsRow: IDataSourceModel<number, ExpenseProjectPurchase>;
        let revenueRow: IDataSourceModel<number, ExpenseProjectPurchase>;

        return (
            <td colSpan={3} class={classes["secondary-tables-wrapper"]} data-bind={{ with: row.model, as: 'project' }}>
                <div class={"row " + classes["project-notes-wrapper"]}>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{TextResources.Expenses.Notes}</label>
                            <button class={classes["notes-button"]} data-bind={{ click: project.toggleNotesVisibility.bind(project) }}>
                                <i class="fa" data-bind={{ css: { 'fa-chevron-down': !project.ShowNotes(), 'fa-chevron-up': project.ShowNotes } }}></i>
                            </button>
                            <div class="notes-wrapper" data-bind={{css: { hide: !project.ShowNotes() }}}>
                                <textarea data-bind={{ htmlEditor: project.ProjectNotes, html: project.ProjectNotes, htmlEditorTrigger: project.NotesTrigger, htmlEditorOptions: { language: 'it', resize_enabled: false, scayt_sLang: 'it_IT', height: 100 }}} placeholder={TextResources.Expenses.InsertNotes}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="secondary-table-wrapper">
                    <label class="bold">{TextResources.Expenses.ProjectsCosts}</label>
                    <Table compact={true} dataSource={{ array: item.Data.model.PurchasesCosts, factory: item.Data.model.createPurchaseModel.bind(item.Data.model) }} rowAs="costsRow" fixedLayout={true}>
                        <Column title={TextResources.Expenses.CostRowPurchaseType} cssClasses="readonly-purchase-type-col readonly-col" headerCssClasses="readonly-purchase-type-col readonly-col" footerNodesProvider={() => footerEmptyColumnNodesProvider()} footerColSpan={6} footerCssClasses="purchase-footer-empty-col">
                            <span data-bind={{ text: costsRow.model.PurchaseType }}></span>
                        </Column>
                        <Column title={TextResources.Expenses.CostRowDescription} cssClasses="purchase-description-col" headerCssClasses="purchase-description-col">
                            <text-input value={() => "costsRow.model.Description"} selectOnFocus={true} placeholder={TextResources.Expenses.CostRowDescription}></text-input>
                        </Column>
                        <Column title={TextResources.Expenses.Workflow} cssClasses="workflow-col" headerCssClasses="workflow-col">
                            <select2 value={() => "costsRow.model.WorkflowId"} dataSource={() => "costsRow.model.WorkflowsDataSource"} listener={() => "costsRow.model"} placeholder={TextResources.Expenses.WorkflowFieldPlaceholder} allowClear={true} simple={true}></select2>
                        </Column>
                        <Column title={TextResources.Expenses.Task} cssClasses="task-col" headerCssClasses="task-col">
                            <select2 value={() => "costsRow.model.TaskId"} dataSource={() => "costsRow.model.TasksDataSource"} listener={() => "costsRow.model"} placeholder={TextResources.Expenses.TaskFieldPlaceholder} allowClear={true} simple={true}></select2>
                        </Column>
                        <Column title={TextResources.Expenses.UnitPrice} cssClasses="unit-cost-col readonly-col text-right" headerCssClasses="unit-cost-col readonly-col text-right">
                            <span data-bind={{ moneyText: costsRow.model.UnitCost }}></span>
                        </Column>
                        <Column title={TextResources.Expenses.Discount} cssClasses="unit-cost-col readonly-col text-right" headerCssClasses="unit-cost-col readonly-col text-right">
                            <span data-bind={{ discountText: costsRow.model.Discount }}></span>
                        </Column>
                        <Column title={TextResources.Expenses.NetPrice} cssClasses="unit-cost-col readonly-col text-right" headerCssClasses="unit-cost-col readonly-col text-right" footerNodesProvider={() => footerTotalCostsColumnNodesProvider()} footerCssClasses="purchase-footer-total-col">
                            <span data-bind={{ moneyText: costsRow.model.NetCost }}></span>
                        </Column>
                        <Column title="" cssClasses="purchase-actions-col text-right" headerCssClasses="purchase-actions-col text-right" footerNodesProvider={() => <></>}>
                            <div class="btn-group" data-bind={{ if: vm.CanViewExpectedRevenues }}>
                                <button type="button" class="btn btn-xs btn-primary" data-bind={{ click: project.copyPurchaseCostToExpectedRevenue.bind(project, costsRow.model) }} title={TextResources.Expenses.GenerateExpectedRevenueRow}>
                                    <i class="fa fa-arrow-down"></i>
                                </button>
                                <button type="button" class="btn btn-xs btn-primary" data-bind={{ asyncClick: costsRow.model.showMarkupPopover.bind(costsRow.model) }} title={TextResources.Expenses.SetMarkupTitle}>
                                    <i class="fa fa-angle-left"></i>
                                </button>
                            </div>
                        </Column>
                    </Table>
                </div>
                <ko-bind data-bind={{ if: vm.CanViewExpectedRevenues }}>
                    <div class="secondary-table-wrapper">
                        <label class="bold">{TextResources.Expenses.ProjectsExpectedRevenues}</label>
                        <Table compact={true} dataSource={{ array: item.Data.model.PurchasesExpectedRevenues, factory: item.Data.model.createPurchaseModel.bind(item.Data.model) }} rowAs="revenueRow" fixedLayout={true}>
                            <Column title={TextResources.Expenses.CostRowPurchaseType} headerCssClasses="readonly-purchase-type-col" cssClasses="readonly-purchase-type-col readonly-col" footerNodesProvider={() => footerEmptyColumnNodesProvider()} footerColSpan={7} footerCssClasses="purchase-footer-empty-col">
                                <span data-bind={{ text: revenueRow.model.PurchaseType }}></span>
                            </Column>
                            <Column title={TextResources.Expenses.CostRowDescription} headerCssClasses="purchase-description-col" cssClasses="purchase-description-col">
                                <text-input value={() => "revenueRow.model.Description"} selectOnFocus={true}></text-input>
                            </Column>
                            <Column title={TextResources.Expenses.Workflow} headerCssClasses="workflow-col" cssClasses="workflow-col">
                                <select2 value={() => "revenueRow.model.WorkflowId"} dataSource={() => "revenueRow.model.WorkflowsDataSource"} listener={() => "revenueRow.model"} placeholder={TextResources.Expenses.WorkflowFieldPlaceholder} allowClear={true} simple={true}></select2>
                            </Column>
                            <Column title={TextResources.Expenses.Task} headerCssClasses="small-task-col" cssClasses="small-task-col">
                                <select2 value={() => "revenueRow.model.TaskId"} dataSource={() => "revenueRow.model.TasksDataSource"} listener={() => "revenueRow.model"} placeholder={TextResources.Expenses.TaskFieldPlaceholder} allowClear={true} simple={true}></select2>
                            </Column>
                            <Column title={TextResources.Expenses.Billable} headerCssClasses="billable-col centered-checkbox-col text-center" cssClasses="billable-col centered-checkbox-col text-center">
                                <checkbox checked={() => "revenueRow.model.Billable"}></checkbox>
                            </Column>
                            <Column title={TextResources.Expenses.UnitPrice} headerCssClasses="unit-cost-col readonly-col text-right" cssClasses="unit-cost-col readonly-col text-right">
                                <money-input value={() => "revenueRow.model.UnitCost"} currency={() => "revenueRow.model.CurrencyId"} exchange-value={() => "revenueRow.model.ExchangeValue"} button-size="small" editable-currency={false}></money-input>
                            </Column>
                            <Column title={TextResources.Expenses.Discount} headerCssClasses="unit-cost-col readonly-col text-right" cssClasses="unit-cost-col readonly-col text-right">
                                <input type="text" class="text-right form-control" data-bind={{ discountValue: revenueRow.model.Discount, selectOnFocus: {} }} />
                            </Column>
                            <Column title={TextResources.Expenses.NetPrice} headerCssClasses="unit-cost-col readonly-col text-right" cssClasses="unit-cost-col readonly-col text-right" footerNodesProvider={() => footerTotalExpectedRevenuesColumnNodesProvider()} footerCssClasses="purchase-footer-total-col">
                                <money-input value={() => "revenueRow.model.NetCost"} currency={() => "revenueRow.model.CurrencyId"} exchange-value={() => "revenueRow.model.ExchangeValue"} button-size="small" editable-currency={false}></money-input>
                            </Column>
                            <Column title="" cssClasses="purchase-actions-col text-right" headerCssClasses="purchase-actions-col text-right" footerNodesProvider={() => <></>}>
                                <a class="btn btn-xs btn-danger" data-bind={{ click: project.removeExpectedRevenue.bind(project, revenueRow.model) }}>
                                    <i class="fa fa-trash-o"></i>
                                </a>
                            </Column>
                        </Table>
                    </div>
                </ko-bind>
            </td>
        );
    }

    public dispose(): void {}

    public async getDestinationPathForAttachments(): Promise<string> {
        if (this.IsNew()) {
            return this.temporaryFileRepositoryPath;
        }

        return this.initialFileRepositoryPath;
    }
    
    private setupFileRepositoryPaths(): void {
        if (this.IsNew()) {
            if (!this.temporaryFileRepositoryPath) {
                const basePath = TextResources.Expenses.TempFileRepositoryPath;
                const expenseId = this.fullExpense.Expenses.firstOrDefault()?.Id;
                this.temporaryFileRepositoryPath = String.format(basePath, expenseId);
            }
            
            return;
        }

        if (!this.initialFileRepositoryPath) {
            const expensePath = TextResources.Expenses.ExpenseFileRepositoryPath;

            const expense = this.fullExpense.Expenses.firstOrDefault();    
            const momentDate = moment(expense.From);
            const year = momentDate.year();
            
            this.initialFileRepositoryPath = String.format(expensePath, expense.ResourceName, year, expense.Id);
        }
    }

    /*private async initializePortlets(): Promise<void> {
        this.KeyboardPanel.addPortletConfiguration(ExpenseEditorSection.GeneralData, { Opened: ko.observable(true), Scroller: ko.observable(true) });
        this.KeyboardPanel.addPortletConfiguration(ExpenseEditorSection.Costs, { Opened: ko.observable(true), Scroller: ko.observable(true) });
        this.KeyboardPanel.addPortletConfiguration(ExpenseEditorSection.JobOrders, { Opened: ko.observable(true), Scroller: ko.observable(true) });

        await this.KeyboardPanel.loadSectionsSettings();
    }*/

    private getSaveWarnings(): string[] {
        const warnings = [];

        if (!this.Motive())
            warnings.push(TextResources.Expenses.MotiveNotProvided);

        if (this.ExpenseCostRows().length === 0)
            warnings.push(TextResources.Expenses.CostRowsNotProvided);

        if (this.ExpenseProjects().length === 0)
            warnings.push(TextResources.Expenses.JobOrderRowsNotProvided);

        return warnings;
    }

    private async doSave(): Promise<IExtendedFullExpense> {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving);

        try {
            const expenseData: IFullExpense = await this.getData();

            const request: IInsertOrUpdateFullExpensesRequest = {
                expenses: expenseData.Expenses,
                expensesProjects: expenseData.ExpensesProjects,
                expensesCostRows: expenseData.ExpensesCostRows,
                expensesCostRowsAttachments: expenseData.ExpensesCostRowsAttachments,
                expensesEvents: expenseData.ExpensesEvents,
                expensesPurchasesRows: expenseData.ExpensesPurchases
            };

            const savedExpense: IFullExpense = await this.expenseService.InsertOrUpdateFullExpenses(request);
            const extendedSavedExpense = savedExpense as IExtendedFullExpense;
            extendedSavedExpense.Action = this.IsNew() ? "ADDED" : "MODIFIED";

            this.infoToastService.Success(ProlifeSdk.TextResources.Expenses.ExpenseSaved);

            // await this.loadFullExpense(savedExpense); Decommentare nel caso il dialog debba rimanere aperto dopo il salvataggio

            this.dialogsService.UnlockUI();
            return extendedSavedExpense;
        } catch (e) {
            this.dialogsService.UnlockUI();
        }

        return null;
    }
    
    private async loadFullExpense(fullExpense: IFullExpense = null): Promise<void> {
        this.loading = true;

        if (!fullExpense) {
            const date = moment().toDate();

            this.fullExpense = {
                Expenses: [{
                    Id: await this.expenseService.GetGlobalTemporaryId(),
                    ResourceId: null,
                    ProtocolId: null,
                    ProtocolYear: null,
                    From: date,
                    To: date,
                    Total: 0,
                    FkExpenseStatus: ExpenseLogicalStatus.Draft
                }],
                ExpensesCostRows: [],
                ExpensesProjects: [],
                ExpensesCostRowsAttachments: [],
                ExpensesEvents: [],
                ExpensesPurchases: []
            };

            const expenseId = this.Id;
            if (!!expenseId && expenseId > 0) {
                this.fullExpense = await this.expenseService.GetFullExpensesByIds([expenseId], null, null, null);
            }
        } else {
            this.fullExpense = fullExpense;
        }
        
        const expense = this.fullExpense.Expenses.firstOrDefault();

        this.Id = expense?.Id;
        this.IsNew(!(expense?.Id > 0));
        this.ProtocolId(expense?.ProtocolId);
        this.ProtocolYear(expense?.ProtocolYear);
        this.ResourceId(expense?.ResourceId);
        this.ResourceName(expense?.ResourceName);
        this.From(expense?.From);
        this.To(expense?.To);
        this.Place(expense?.Place);
        this.Motive(expense?.Motive);
        this.Notes(expense?.Notes);
        this.NotesTrigger.valueHasMutated();
        this.ExpenseStatus(expense?.FkExpenseStatus);

        if (expense?.FkExpenseStatus) {
            const expenseStatus = this.expenseStatusesManager.getStatusById(expense.FkExpenseStatus);
            if (expenseStatus.LogicalStatus !== ExpenseLogicalStatus.Draft) {
                this.CanEditCostRows(false);
                this.ShowUnlockCostsButton(this.ExpenseAdministrativeEdit);
            }
        }

        let resource: IHumanResource = null;
        let resourceId = this.ResourceId();

        if (!resourceId) {
            const userId = this.usersInfo.getIdUser();
            resource = this.humanResourcesManager.getHumanResourceByUserId(userId);
            resourceId = resource?.Resource.Id;
            this.ResourceId(resourceId);
            this.ResourceName(this.humanResourcesManager.getFullName(resource));
        }

        const costsRows = this.fullExpense.ExpensesCostRows.map((r: IFullExpenseExpensesCostRows) => {
            return new ExpenseCostRow(this, r, this.fullExpense.ExpensesCostRowsAttachments.filter(a => a.RowId === r.Id));
        });

        this.setExpenseCostRows(costsRows);

        const projects = this.fullExpense.ExpensesProjects.map((p: IFullExpenseExpensesProjects) => {
            const event = this.fullExpense.ExpensesEvents.firstOrDefault(e => e.FkJobOrder === p.ProjectId);
            let purchases: IExpenseEventsAndPurchasesPurchases[] = [];

            if (event) {
                purchases = this.fullExpense.ExpensesPurchases.filter(p => p.EventId === event.FkEvent);
            }

            return new ExpenseProject(p, purchases);
        });

        this.setExpenseProjectsRows(projects);
        this.setupFileRepositoryPaths();

        this.isChanged(0);
        this.loading = false;
    }

    private loadExpensesStatusesOnDataSource() {
        const expenseStatuses = this.expenseStatusesManager
            .getStatuses()
            .filter((s) => {
                return this.ExpenseAdministrativeEdit || (this.CanApproveExpenses && s.LogicalStatus === ExpenseLogicalStatus.Approved) || (this.CanRefundExpenses && s.LogicalStatus === ExpenseLogicalStatus.Refunded) || s.LogicalStatus === ExpenseLogicalStatus.Draft
            })
            .map(s => ({
                id: s.Id,
                title: s.Label,
                isGroup: false,
                isLeaf: true,
                model: s
            }));
        this.ExpenseStatusesDataSource.setData(...expenseStatuses);
    }

    private addPurchasesToProject(project: ExpenseProject, purchases: IExpenseEventsAndPurchasesPurchases[] = null, totalCostsPerPurchaseType: Map<PurchaseTypeId, Total> = null) {
        purchases = purchases || this.generatePurchasesRows();
        totalCostsPerPurchaseType = totalCostsPerPurchaseType || this.getCostsTotalsPerPurchaseType();

        purchases.forEach(p => project.addPurchase(p, totalCostsPerPurchaseType.get(p.PurchaseType)));
    }

    private updatePurchasesOnProjects(): void {
        const purchases = this.generatePurchasesRows();
        const totalCostsPerPurchaseType = this.getCostsTotalsPerPurchaseType();
        const projects = this.ExpenseProjects();

        for (const proj of projects) {
            proj.removePurchasesByTypeMatching(purchases);
            this.addPurchasesToProject(proj, purchases, totalCostsPerPurchaseType);
        }
    }

    private updatePurchasesCostsOnProjects(): void {
        const totalCostsPerPurchaseType = this.getCostsTotalsPerPurchaseType();

        const projects = this.ExpenseProjects();

        for (const project of projects)
            project.recalculateCosts(totalCostsPerPurchaseType);
    }

    private generatePurchasesRows(): IExpenseEventsAndPurchasesPurchases[] {
        const costsRows = this.ExpenseCostRows();
        const purchaseTypes = [];
        const purchasesRows: IExpenseEventsAndPurchasesPurchases[] = [];
        
        for (const row of costsRows) {
            const purchaseTypeId = row.PurchaseTypeId();

            if (!!purchaseTypeId && purchaseTypes.indexOf(purchaseTypeId) < 0)
                purchaseTypes.push(purchaseTypeId);
        }

        for (const purchaseType of purchaseTypes) {
            const purchaseCostRow: IExpenseEventsAndPurchasesPurchases = {
                PurchaseId: null,
                Billed: false,
                PurchasePrice: null,
                SellPrice: null,
                EntityType: ProlifeSdk.PurchasesEntityTypeCode,
                EventId: null,
                JobOrderId: null,
                PurchaseDate: null,
                Description: null,
                RowDescription: null,
                Amount: 1,
                UnitMoney: 0,
                Discount: undefined,
                NetMoney: 0,
                TotalMoney: 0,
                Billable: false,
                IsCost: true,
                PurchaseType: purchaseType
            };

            purchasesRows.push(purchaseCostRow);
        }
        
        return purchasesRows;
    }

    private getCostsTotalsPerPurchaseType(): Map<PurchaseTypeId, Total> {
        const totals = new Map();

        const costs = this.ExpenseCostRows();
        for (const cost of costs) {
            const purchaseTypeId = cost.PurchaseTypeId();

            let costTotal = totals.get(purchaseTypeId) || 0;
            costTotal += (cost.TotalCost() || 0);
            totals.set(purchaseTypeId, costTotal);
        }

        return totals;
    }

    public createExpenseCostRowsModel(expenseCostRow: ExpenseCostRow): IDataSourceModel<number, ExpenseCostRow> {
        return {
            id: expenseCostRow.Id,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: expenseCostRow
        };
    }

    public createExpenseProjectModel(project: ExpenseProject): IDataSourceModel<number, ExpenseProject> {
        return {
            id: project.Id,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: project
        };
    }

    renderBody() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm : ExpenseEditDialogComponent = this;
        let model: ExpenseCostRow;

        return ComponentUtils.bindTo(
            <div class={"flex-container flex-fill flex-full-height"}>
                <KeyboardPanel cssClasses={classes["expense-editor"]} ref={(r) => vm.KeyboardPanel = r}>
                    <Portlet name={ExpenseEditorSection.GeneralData} title={TextResources.Expenses.GeneralDataSectionTitle} isGeneralSection={true} portletCssClasses="general-data">
                        <div class="row">
 							<div class="col-md-1">
								<label>{TextResources.Expenses.ExpenseNumber}</label>
                                <span class="form-control" style={{ backgroundColor: "#f4f4f4", cursor: "not-allowed" }} data-bind={{ numberText: vm.ProtocolId, format: '0,0' }}></span>
                            </div>
                            <div class="col-md-3">
                                <select2 value={() => "$parent.ResourceId"} dataSource={() => "$parent.HumanResourcesDataSource"} listener={() => "$parent"} allowClear={true} placeholder={TextResources.Expenses.InsertResource} label={TextResources.Expenses.ResourceFieldLabel} readOnly={!this.ExpenseAdministrativeEdit}></select2>
                            </div>
                            <div class="col-md-2">
                                <label>{TextResources.Expenses.FromFieldLabel}</label>
                                <div class="input-group date" data-bind={{ nullableDatePicker: vm.From }}>
                                    <input class="form-control" type="text" placeholder={TextResources.Expenses.FromDatePlaceholder} data-bind={{ selectOnFocus: {} }} />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>{TextResources.Expenses.ToFieldLabel}</label>
                                <div class="input-group date" data-bind={{ nullableDatePicker: vm.To }}>
                                    <input class="form-control" type="text" placeholder={TextResources.Expenses.ToDatePlaceholder} data-bind={{ selectOnFocus: {} }} />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <text-input value={() => "$parent.Place"} label={TextResources.Expenses.Place} placeholder={TextResources.Expenses.Place} selectOnFocus={true}></text-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <text-input value={() => "$parent.Motive"} label={TextResources.Expenses.Motive} placeholder={TextResources.Expenses.Motive} selectOnFocus={true}></text-input>
                            </div>
                            <div class="col-md-4">
                                <select2 value={() => "$parent.ExpenseStatus"} dataSource={() => "$parent.ExpenseStatusesDataSource"} placeholder={TextResources.Expenses.ExpenseStatusPlaceholedr} label={TextResources.Expenses.ExpenseStatusFieldLabel} allowClear={true}></select2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>{TextResources.Expenses.Notes}</label>
                                    <button class={classes["notes-button"]} data-bind={{ click: vm.toggleNotesVisibility.bind(vm) }}>
                                        <i class="fa" data-bind={{ css: { 'fa-chevron-down': !vm.ShowNotes(), 'fa-chevron-up': vm.ShowNotes } }}></i>
                                    </button>
                                    <div class="notes-wrapper" data-bind={{css: { hide: !vm.ShowNotes() }}}>
                                        <textarea data-bind={{ htmlEditor: vm.Notes, html: vm.Notes, htmlEditorTrigger: vm.NotesTrigger, htmlEditorOptions: { language: 'it', resize_enabled: false, scayt_sLang: 'it_IT', height: 100 }}} placeholder={TextResources.Expenses.InsertNotes}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portlet>
                    <Portlet name={ExpenseEditorSection.Costs} title={TextResources.Expenses.CostsSectionTitle} portletCssClasses="costs-table" icon="fa-money">
                        <div class="table-wrapper">
                            <Table compact={true} bordered={true} dataSource={{ array: vm.ExpenseCostRows, factory: vm.createExpenseCostRowsModel.bind(vm) }} listener={this} fixedLayout={true}>
                                <Column title={TextResources.Expenses.CostRowPurchaseType} headerCssClasses="purchase-type-col text-right" cssClasses="purchase-type-col text-right" footerNodesProvider={() => vm.renderEmptyColumn()} footerColSpan={4} footerCssClasses={"footer-left-col"}>
                                    <select2 value={() => "model.PurchaseTypeId"} dataSource={() => "model.PurchaseTypesDataSource"} placeholder={TextResources.Expenses.PurchaseTypeFieldPlaceholder} listener={() => "model"} allowClear={true} simple={true} readOnly={() => "!model.CanEditRow || !vm.CanEditCostRows()"}></select2>
                                </Column>
                                <Column title={TextResources.Expenses.CostRowDescription} headerCssClasses="cost-description-col" cssClasses="cost-description-col">
                                    <text-input value={() => "model.Description"} placeholder={TextResources.Expenses.CostRowDescriptionPlaceholder} readOnly={() => "!model.CanEditRow || !vm.CanEditCostRows()"}></text-input>
                                </Column>
                                <Column title={TextResources.Expenses.Amount} headerCssClasses="amount-col text-right" cssClasses="amount-col text-right">
                                    <numeric-input value={() => "model.Amount"} placeholder={TextResources.Expenses.Amount} readOnly={() => "!model.CanEditRow || !vm.CanEditCostRows()"}></numeric-input>
                                </Column>
                                <Column title={TextResources.Expenses.UnitPrice} headerCssClasses="unit-cost-col text-right" cssClasses={() => "model.MoneyFieldClasses"} className="unit-cost-col">
                                    <money-input value={() => "model.UnitCost"} currency={() => "model.CurrencyId"} exchange-value={() => "model.ExchangeValue"} button-size="small" popover-container=".modal-body" popover-placement="left" popover-css-classes={classes["currency-input-popover"]} readOnly={() => "!model.CanEditRow || !vm.CanEditCostRows()"} can-edit-currency={() => "model.CurrencyEditingEnabled && vm.CanEditCostRows"}></money-input>
                                </Column>
                                <Column title={TextResources.Expenses.CostRowTotalCost} headerCssClasses="total-cost-col text-right" cssClasses="total-cost-col readonly-col text-right" footerNodesProvider={() => vm.renderCostRowsTableTotalCostColumn()} footerCssClasses={"footer-total-col"}>
                                    <span data-bind={{moneyText: model.TotalCost, currencySymbol: model.CurrencySymbol }}></span>
                                </Column>
                                <Column title={TextResources.Expenses.IsSustainedByResource} headerCssClasses="sustained-by-the-company-col centered-checkbox-col text-center" cssClasses="sustained-by-the-company-col centered-checkbox-col text-center" footerNodesProvider={() => vm.renderEmptyColumn()} footerColSpan={3} footerCssClasses={"footer-right-col"}>
                                    <checkbox checked={() => "model.IsSustainedByResource"} read-only={() => "!model.canEditCompanyCosts || !vm.CanEditCostRows()"}></checkbox>
                                </Column>
                                <Column title="" headerCssClasses="attachments-column text-center" cssClasses="attachments-column text-center readonly-col">
                                    <span data-bind="css: { 'has-attachments': model.HasAttachments, 'no-attachments': !model.HasAttachments() }">
                                        <span data-bind="text: model.AttachmentsNumber, visible: model.HasAttachments" class="attachments-number"></span>
                                        <i class="fa fa-paperclip"></i>
                                    </span>
                                </Column>
                                <Column title="" headerCssClasses="actions-col text-right" cssClasses="actions-col text-right" headerNodesProvider={() => vm.renderCostRowsTableHeaderActions()}>
                                    <button class="btn btn-primary btn-xs" data-bind="asyncClick: model.manageAttachments.bind(model)">
                                        <i class="fa fa-paperclip"></i>
                                    </button>
                                    <button class="btn btn-danger btn-xs" data-bind="click: vm.removeCostRow.bind(vm, model), enable: model.CanEditRow && vm.CanEditCostRows()">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </Column>
                            </Table>
                        </div>
                    </Portlet>
                    <Portlet name={ExpenseEditorSection.JobOrders} title={TextResources.Expenses.JobOrdersSectionTitle} portletCssClasses="job-orders-table" icon="fa-money" renderIf={vm.CanViewJobOrdersCostsDistribution}>
                        <div class="table-wrapper">
                            <Table compact={true} dataSource={{ array: vm.ExpenseProjects, factory: vm.createExpenseProjectModel.bind(vm)}} listener={this} rowAs="row" fixedLayout={true}>
                                <Column title={TextResources.Expenses.JobOrder} headerCssClasses="jobOrder-col" cssClasses="jobOrder-col" footerNodesProvider={() => vm.renderEmptyColumn()} footerCssClasses="">
                                    <select2 value={() => "row.model.ProjectId"} dataSource={() => "row.model.JobOrdersDataSource"} listener={() => "row.model"} placeholder={TextResources.Expenses.JobOrderFieldPlaceholder} allowClear={true} simple={true}></select2>
                                </Column>
                                <Column title={TextResources.Expenses.Percentage} headerCssClasses="percentage-col text-right" cssClasses="percentage-col text-right" footerNodesProvider={() => vm.renderProjectsTableTotalPercentage()} footerCssClasses={() => "vm.TotalProjectsPercentageFieldClasses"}>
                                    <percentage-input format={() => "vm.PercentageFormat"} value={() => "row.model.Percentage"} placeholder={TextResources.Expenses.Percentage}></percentage-input>
                                </Column>
                                <Column title="" headerCssClasses={"job-order-actions-col text-right"} cssClasses="job-order-actions-col readonly-col text-right" footerCssClasses="text-right" headerNodesProvider={() => vm.renderProjectsTableHeaderActions()} footerNodesProvider={() => vm.renderProjectsTableTotalCostsAndExpectedRevenues()}>
                                    {/* <a class="btn btn-primary btn-xs" data-bind="click: model.selectActivities.bind(model)" title={TextResources.Expenses.SelectActivities}>
                                        <i class="icon-list"></i>
                                    </a> */}
                                    <div class="btn-group create-activity-button" data-bind="if: row.model.CanCreateTask">
                                        <button class="btn btn-xs btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                                            {TextResources.Expenses.AddTaskButton} <i class="fa fa-angle-down"></i>
                                        </button>
                                        <ul class="dropdown-menu pull-right" role="menu">
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewTask.bind(row.model)">
                                                    <i class="fa fa-plus"></i>
                                                    {TextResources.Expenses.NewTask}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewTaskFromTask.bind(row.model)">
                                                    <i class="fa fa-copy"></i>
                                                    {TextResources.Expenses.NewTaskFromTask}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewTaskFromTemplate.bind(row.model)">
                                                    <i class="fa fa-magic"></i>
                                                    {TextResources.Expenses.NewTaskFromTemplate}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="btn-group create-activity-button" data-bind="if: row.model.CanCreateWorkflow">
                                        <button class="btn btn-xs btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                                            {TextResources.Expenses.AddWorkflowButton} <i class="fa fa-angle-down"></i>
                                        </button>
                                        <ul class="dropdown-menu pull-right" role="menu">
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewWorkflow.bind(row.model)">
                                                    <i class="fa fa-plus"></i>
                                                    {TextResources.Expenses.NewWorkflow}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewWorkflowFromWorkflow.bind(row.model)">
                                                    <i class="fa fa-copy"></i>
                                                    {TextResources.Expenses.NewWorkflowFromWorkflow}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" data-bind="click: row.model.createNewWorkflowFromTemplate.bind(row.model)">
                                                    <i class="fa fa-magic"></i>
                                                    {TextResources.Expenses.NewWorkflowFromTemplate}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <a class="btn btn-xs btn-primary purchases-visibility-switch" data-bind="click: row.model.switchPurchaseVisibility.bind(row.model)">
                                        <i class="fa" data-bind="css: { 'fa-chevron-down': row.model.HidePurchases, 'fa-chevron-up': !row.model.HidePurchases() }"></i>
                                    </a>
                                    <a class="btn btn-xs btn-danger" data-bind="click: vm.removeProject.bind(vm, row.model)">
                                        <i class="fa fa-trash-o"></i>
                                    </a>
                                </Column>
                                <SecondaryRow cssClasses="projects-secondary-row" visible={() => "!row.model.HidePurchases()"}>
                                    {(item) => this.renderProjectSecondaryRow(item)}
                                </SecondaryRow>
                            </Table>
                        </div>
                    </Portlet>
                </KeyboardPanel>
            </div>
        , this, 'vm')
    }
}
