import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BudgetRequestCausesSettingsViewModel } from "./ui/BudgetRequestCausesSettingsViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IBudgetRequestStatesSettingsManager, IBudgetRequestState, IBudgetRequestStatesObserver } from "../../../ProlifeSdk/interfaces/todolist/IBudgetRequestStatesSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class BudgetRequestCausesSettingsManager implements IBudgetRequestStatesSettingsManager
{
    private ajaxService : IAjaxService;
    private modulesService : IModulesService;

    private causes : IBudgetRequestState[] = [];
    private observers : IBudgetRequestStatesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.TasksSettingsGroup);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<IBudgetRequestState[]>
    {
        return this.ajaxService.Get<IBudgetRequestState[]>("Todolist-api", "BudgetRequestCause", {})
            .then((elements) => this.causes = elements);
    }

    getName(): string {
        return ProlifeSdk.BudgetRequestCauses;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Todolist.BudgetRequestCauses
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) ||
            this.modulesService.IsModuleEnabled(ProlifeSdk.PersonalTodolistApplicationCode);
    }

    getEditingUI(): IView {
        return {
            templateName: 'budget-request-cause',
            templateUrl: 'todolist/templates/settings',
            viewModel: new BudgetRequestCausesSettingsViewModel(this.serviceLocator, this)
        };
    }

    get(getDeleted : boolean = false) : IBudgetRequestState[] {
        return getDeleted ? this.causes : this.causes.filter((s) => { return !s.Deleted; });
    }

    betById(id : number) : IBudgetRequestState
    {
        var causes = this.causes.filter((s : IBudgetRequestState) => s.Id == id);
        if(causes.length == 0) return null;
        return causes[0];
    }

    move(stateId : number, newPosition : number) : Promise<IBudgetRequestState>
    {
        return this.ajaxService.Post<IBudgetRequestState>("Todolist-api", "BudgetRequestCause/UpdatePosition", { methodData: {
            CauseId : stateId,
            NewPosition : newPosition
        }}).then(this.onChanged.bind(this));
    }

    createOrUpdate(element : IBudgetRequestState) : Promise<IBudgetRequestState> {
        return this.ajaxService.Post<IBudgetRequestState>("Todolist-api", "BudgetRequestCause", { methodData: element})
            .then(this.onAdded.bind(this));
    }

    remove(id : number) : Promise<void>
    {
        if(!id) {
            return Promise.reject();
        }

        return this.ajaxService.Delete<void>("Todolist-api", "BudgetRequestCause/" + id, {})
            .then(this.onDeleted.bind(this, id));
    }

    addObserver(observer : IBudgetRequestStatesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IBudgetRequestStatesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onChanged(element : IBudgetRequestState) {
        return this.load().then((b) => {
            this.observers.forEach((obs : IBudgetRequestStatesObserver) => obs.onChanged(element));
            return b;
        });
    }

    private onAdded(element : IBudgetRequestState) {
        return this.load().then((b) => {
            this.observers.forEach((obs : IBudgetRequestStatesObserver) => obs.onAdded(element));
            return b;
        });
    }

    private onDeleted(id : number) : Promise<IBudgetRequestState[]> {
        return this.load().then((b) => {
            this.observers.forEach((obs : IBudgetRequestStatesObserver) => obs.onDeleted(id));
            return b;
        });
    }
}
