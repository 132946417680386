import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ManufacturersDataSource } from "../../../../DataSources/ManufacturersDataSource";
import { ManufacturerEditDialog } from "./ManufacturerEditDialog";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { ICatalogsService, ICatalog } from "../../../../ProlifeSdk/interfaces/warehouse/ICatalogsService";

export class CatalogEditDialog implements IDialog {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(ProlifeSdk.CatalogsServiceType)
    private catalogsService : ICatalogsService;

    templateName: string = "catalogEditDialog";
    templateUrl: string = "Warehouse/Templates/Catalogs";
    title: string = "Modifica Listino";

    modal: { close: (result?: any) => void; };

    ManufacturerId : ko.Observable<number> = ko.observable();
    Number : ko.Observable<string> = ko.observable();
    EffectiveDate : ko.Observable<Date> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    DealerEffectiveDate : ko.Observable<Date> = ko.observable();

    ManufacturersDataSource : ManufacturersDataSource = new ManufacturersDataSource();

    constructor(private catalog? : ICatalog) {
        if(!this.catalog) {
            this.title = "Nuovo Listino";

            this.catalog = {
                Id: 0,
                ManufacturerId: null,
                Number: "",
                EffectiveDate: new Date(),
                Description: "",
                DealerEffectiveDate: null,
                LastModification: null,
                Version: "020",
                IsoPiva: ""
            }
        }

        if(this.catalog) {
            this.ManufacturerId(this.catalog.ManufacturerId);
            this.Number(this.catalog.Number);
            this.EffectiveDate(this.catalog.EffectiveDate);
            this.Description(this.catalog.Description);
            this.DealerEffectiveDate(this.catalog.DealerEffectiveDate);
        }
    }

    public ShowModal() : Promise<void> {
        return this.dialogsService.ShowModal<void>(this);
    }

    close(): void {
        this.modal.close();
    }

    async action(): Promise<void> {
        if(!this.ManufacturerId()) {
            this.infoToastService.Warning("E' necessario selezionare un produttore prima di salvere il listino!")
            return;
        }

        if(!this.Number()) {
            this.infoToastService.Warning("E' necessario inserire il numero listino prima di salvere!")
            return;
        }

        await this.catalogsService.InsertOrUpdateCatalog([{
            Id: this.catalog.Id,
            ManufacturerId: this.ManufacturerId(),
            Number: this.Number(),
            Description: this.Description(),
            EffectiveDate: this.EffectiveDate(),
            DealerEffectiveDate: this.DealerEffectiveDate()
        }]);

        this.modal.close();
    }

    public NewManufacturer() : Promise<void> {
        return new ManufacturerEditDialog().ShowModal();
    }
}