import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 12:23
 * To change this template use File | Settings | File Templates.
 */

import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { FestivitiesRootNavigationProvider } from "./providers/FestivitiesRootNavigationProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";

export class FestivitiesAgendasMenu extends NavigationMenu {
    public ShowDeletedElements: ko.Observable<boolean> = ko.observable();
    public DeletedElementsButtonEnabled: ko.Observable<boolean> = ko.observable();

    constructor(private serviceLocator: IServiceLocator) {
        super(serviceLocator, new FestivitiesRootNavigationProvider(serviceLocator));

        this.templateUrl = "agenda/templates/navigation";
        this.templateName = "agendas-navigator";
        this.setDefaultMenuLocation();

        this.Title = ko.computed(() => {
            return (<any>this.CurrentView()).Title();
        });

        this.DeletedElementsButtonEnabled(false);
    }

    private setDefaultMenuLocation()
    {
        this.MenuProvidersStack([this.RootMenuProvider]);
    }
}