import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { PaymentAndExpireAccountingSoftwareMappingsDialog } from "./PaymentAndExpireAccountingSoftwareMappingsDialog";
import { PaymentTypeCodesDataSource } from "../../../../DataSources/PaymentTypeCodesDataSource";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IPaymentMode, IPaymentModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";

export interface IPaymentModeProvider {
    getData() : IPaymentMode;
    update(paymentMode : IPaymentMode) : void;
}

export class PaymentModesEditingViewModel {

    private dialogsService : IDialogsService;

    title : string;
    readOnlyElements : ko.ObservableArray<IPaymentModeProvider> = ko.observableArray();
    elements : ko.ObservableArray<IPaymentModeProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();
    ShowDeleted : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator, private paymentModesManager : IPaymentModes) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.title = paymentModesManager.getLabel();
        this.RefreshList();
        this.ShowDeleted.subscribe(this.RefreshList.bind(this));
    }

    public ShowAccountingSoftwareMappings()
    {
        var dialog : IDialog = new PaymentAndExpireAccountingSoftwareMappingsDialog(this.serviceLocator);
        this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
    }

    private RefreshList()
    {
        this.elements([]);
        this.readOnlyElements([]);
        var ivaModes = this.paymentModesManager.getPaymentModes(this.ShowDeleted());
        ivaModes.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(paymentMode : IPaymentMode) {
        var paymentModeViewModel = new PaymentModeViewModel(this, paymentMode);
        if(paymentMode.AssociaBanca == 0)
            this.elements.push(paymentModeViewModel);
        else
            this.readOnlyElements.push(paymentModeViewModel);
    }

    public addNewSetting() {
        var paymentModeViewModel = new PaymentModeViewModel(this, {
            Descrizione: this.newSettingName(),
            AssociaBanca: 0,
            Eliminato: 0,
            PaymentTypeCode: null
        });
        this.elements.push(paymentModeViewModel);
        this.createOrUpdateEntry(paymentModeViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IPaymentModeProvider) {
        var appearance = element.getData();
        this.paymentModesManager.createOrUpdate(appearance)
            .then((updatedAppearance) => element.update(updatedAppearance));
    }

    public deleteEntry(element : IPaymentModeProvider) {
        var paymentMode = element.getData();
        this.paymentModesManager.remove(paymentMode.IdTipoPagamento);

        if(this.ShowDeleted())
            return;

        this.elements.remove(element);
    }
}

class PaymentModeViewModel implements IPaymentModeProvider
{
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);
    descrizioneTipo : ko.Observable<string> = ko.observable();
    IsDeleted : ko.Observable<boolean> = ko.observable(false);

    PaymentTypeCode: ko.Observable<string> = ko.observable();
    PaymentTypeCodesDataSource: PaymentTypeCodesDataSource;

    private updating: boolean = false;
    private loading: boolean = false;

    constructor(private container : PaymentModesEditingViewModel, private paymentMode : IPaymentMode)
    {
        this.loading = true;

        this.PaymentTypeCodesDataSource = new PaymentTypeCodesDataSource();
        this.update(paymentMode);

        this.name.subscribe(this.onChanged.bind(this));
        this.PaymentTypeCode.subscribe(this.onChanged.bind(this));

        var descrizioneTipo : string = this.paymentMode.AssociaBanca == 1 ? ProlifeSdk.TextResources.Invoices.BankReceipt : "";
        descrizioneTipo = this.paymentMode.AssociaBanca == 2 ? ProlifeSdk.TextResources.Invoices.BankTransfer : descrizioneTipo;
        this.descrizioneTipo(descrizioneTipo);

        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    private onChanged()
    {
        if (!this.updating && !this.loading)
            this.container.createOrUpdateEntry(this);
    }

    public Delete()
    {
        this.IsDeleted(true);
        this.container.deleteEntry(this);
    }

    public Restore()
    {
        this.IsDeleted(false);
        this.container.createOrUpdateEntry(this);
    }

    getData() : IPaymentMode
    {
        var paymentMode : IPaymentMode = <IPaymentMode> $.extend({}, this.paymentMode);
        paymentMode.Descrizione = this.name();
        paymentMode.Eliminato = this.IsDeleted() ? 1 : 0;
        paymentMode.PaymentTypeCode = this.PaymentTypeCode();
        return paymentMode;
    }

    update(paymentMode : IPaymentMode) : void
    {
        this.updating = true;

        this.paymentMode = paymentMode;
        this.name(this.paymentMode.Descrizione);
        this.PaymentTypeCode(this.paymentMode.PaymentTypeCode);
        this.IsDeleted(this.paymentMode.Eliminato == 1);

        this.updating = false;
    }
}