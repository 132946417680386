import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../DocumentsService";
import { IDocumentsProvider } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import {
    IProtocolDefaultValuesProvider,
    IProtocolSetting,
} from "../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { IVatRegisters } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrder } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class ProtocolDefaultValuesProvider implements IProtocolDefaultValuesProvider {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private registersSettings: IVatRegisters;

    public Priority: number;

    constructor() {
        this.Priority = 0;
    }

    async GetSettings(
        protocolId: string,
        customerId: number,
        organizationalUnitId: number,
        jobOrderId: number
    ): Promise<IProtocolSetting[]> {
        return this.GetSettingsSync(protocolId, null, organizationalUnitId, null);
    }

    GetSettingsSync(
        protocolId: string,
        customer: ICustomer,
        organizationalUnitId: number,
        jobOrder: IJobOrder
    ): IProtocolSetting[] {
        const matches: IDocumentsProvider[] = this.documentsService
            .getRegisteredDocumentProviders()
            .filter((p: IDocumentsProvider) => {
                return p.ProviderId == protocolId;
            });
        const id: number = matches.length > 0 ? matches[0].Id : null;

        if (!id) return null;

        const provider = this.registersSettings.getVatRegisterById(id);
        return provider ? provider.ProtocolsDefaultValues : null;
    }
}
