import * as ko from "knockout";
import { WizardStep } from "./WizardStep";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentDataSource } from "../interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentToImportInfo } from "../interfaces/invoice/IDocumentImportDataWizardStep";

export class ImportDataWizardDataSourceStep extends WizardStep
{
    private onLoadCalled : boolean = false;

    constructor(protected dataSource : IDocumentDataSource, protected initializationInfo : IWizardInitializationInfo, protected destinationDocumentRowsReferences : IDocumentBuilderDocumentOriginatingRows[])
    {
        super(dataSource.templateName, dataSource.templateUrl, dataSource.title, initializationInfo, dataSource);
    }

    public GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        return this.dataSource.GetDocumentsToImportInfo();
    }

    public BeforeShow()
    {
        if(!this.onLoadCalled)
        {
            this.onLoadCalled = true;
            this.dataSource.Initialize(this.initializationInfo, this.destinationDocumentRowsReferences);
        }
    }

    public BeforeNext() : boolean
    {
        if(!this.dataSource.Validate())
            return false;

        //Da implementare nelle specializzazioni
        return true;
    }
}
