import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as moment from "moment-timezone";
import { IDataSourceListener } from "../DataSources/IDataSource";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { Param, ParamArray, ComponentParam, ComponentUtils } from "../Core/utils/ComponentUtils";

let attributes = {
    Placeholder: "placeholder",
    ReadOnly: "readOnly",
    Label: "label",
    LabelIcon: "labelIcon",
    Value: "value",
    TimeZone: "timeZone",
    CanChangeDate: "canChangeDate",
    Listener: "listener",
    InjectTo: "injectTo"
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "time-picker" : {
                params?: {
                    Placeholder?: string;
                    ReadOnly? : boolean | ko.Observable<boolean>;
                    Label?: string;
                    LabelIcon?: string;
                    Value: ko.Observable<Date>;
                    TimeZone?: ko.Observable<string>;
                    CanChangeDate?: boolean;
                    Listener?: IDataSourceListener | IDataSourceListener[];
                    InjectTo?: ko.Observable<ITimePickerComponent>;
                } | string;

                placeholder?: string | (() => string);
                readOnly? : boolean | (() => string);
                label?: string | (() => string);
                labelIcon?: string | (() => string);
                value: Date | (() => string);
                timeZone?: string | (() => string);
                canChangeDate?: boolean | (() => string);
                listener?: IDataSourceListener | IDataSourceListener[] | (() => string);
                injectTo?: ITimePickerComponent | (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

export interface ITimePickerComponent {
    setReferenceDate(date: Date): void;
}

export interface ITimePickerComponentParameters {
    Placeholder?: Param<string>;
    ReadOnly?: Param<boolean>;
    Label: Param<string>;
    LabelIcon?: Param<string>;
    Value: Param<Date>;
    TimeZone?: Param<string>;
    CanChangeDate?: Param<boolean>;
    Listener?: ParamArray<IDataSourceListener>;
    InjectTo?: (e: ITimePickerComponent) => void;
}

export class TimePickerComponent implements ITimePickerComponent {
    Placeholder : ComponentParam<string>;
    ReadOnly : ComponentParam<boolean>;
    Label : ComponentParam<string>;
    LabelIcon : ComponentParam<string>;
    TimeZone: ComponentParam<string>;
    Value : ComponentParam<Date>;
    CanChangeDate : ComponentParam<boolean>;

    TextValue : ko.Computed<string>;
    DateValue : ko.Computed<Date>;
    DateText : ko.Computed<string>;
    NotServerTimeZone : ko.Computed<boolean>;
    NextDayOverrunning: ko.Computed<boolean>;

    LastValueInvalid: ko.Observable<boolean> = ko.observable(false);
    AdvancedEditorVisible : ko.Observable<boolean> = ko.observable(false);
    
    AdvancedEditorTitle : string = ProlifeSdk.TextResources.ProlifeSdk.AdvancedTimeEditorTitle;
    TimeZoneTitle : string = ProlifeSdk.TextResources.ProlifeSdk.TimeZoneTitle;
    DateTitle : string = ProlifeSdk.TextResources.ProlifeSdk.DateTitle;

    private listeners : IDataSourceListener[];

    private m_referenceDate: ko.Observable<Date> = ko.observable();
    private m_referenceDateInterceptor: ko.Computed<void>;

    constructor(params : ITimePickerComponentParameters, private element : Element) {
        this.Placeholder = ComponentUtils.parseParameter(params.Placeholder, "");
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.Label = ComponentUtils.parseParameter(params.Label, "");
        this.LabelIcon = ComponentUtils.parseParameter(params.LabelIcon, "");
        this.Value = ComponentUtils.parseParameter(params.Value, null);
        this.TimeZone = ComponentUtils.parseParameter(params.TimeZone, null);
        this.CanChangeDate = ComponentUtils.parseParameter(params.CanChangeDate, false);
        
        this.listeners = ComponentUtils.parseParameterArray(params.Listener, [])();

        this.TextValue = ko.computed({
            read: () => {
                let date = moment.tz(this.Value(), this.TimeZone());
                if(date.seconds() == 0)
                    return date.format("LT");
                return date.format("LTS");
            },
            write: (value) => {
                let time = moment.tz(value, "LTS", this.TimeZone());
                let date = moment.tz(this.Value(), this.TimeZone());

                if(!time.isValid()) {
                    this.LastValueInvalid(true);
                    this.Value(date.toDate());
                    return;
                }

                this.LastValueInvalid(false);

                date = date
                    .hour(time.hour())
                    .minute(time.minute())
                    .second(time.second());

                this.Value(date.toDate());
            }
        }).extend({ notify: 'always' });

        this.DateValue = ko.computed({
            read: () => {
                return moment(this.Value()).startOf('day').toDate();
            },
            write: (value : Date) : void => {
                let originalDate = moment(this.Value(), this.TimeZone());

                let date = moment(value)
                    .hour(originalDate.hour())
                    .minute(originalDate.minute())
                    .second(originalDate.second());
                this.Value(date.toDate());
            }
        });

        this.DateText = ko.computed(() => moment(this.DateValue()).format("L"));

        this.NotServerTimeZone = ko.computed(() => {
            return this.TimeZone() != "Europe/Rome";
        });

        this.NextDayOverrunning = ko.computed(() => {
            this.DateValue();
            return !this.m_referenceDate() ? false : moment(this.m_referenceDate()).date() != moment(this.DateValue()).date();
        });

        this.m_referenceDateInterceptor = ko.computed(() => {
            let date = moment(this.DateValue()).toDate();
            if (!this.m_referenceDate())
                this.m_referenceDate(date);
        });

	    if (params.InjectTo)
            params.InjectTo(this);
    }
    
    public setReferenceDate(date: Date): void {
        this.m_referenceDate(date);
    }

    public showAdvancedEditor() {
        this.AdvancedEditorVisible(!this.AdvancedEditorVisible());
    }
}

ko.components.register('time-picker', {
    viewModel: {
        createViewModel: (params : ITimePickerComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new TimePickerComponent(params, componentInfo.element as Element);
            let $component: TimePickerComponent;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div className="form-group" data-bind={{ css: { 'has-error': $component.LastValueInvalid } }}>
                    <label className="control-label">
                        <ko-bind data-bind={{ if: !!$component.LabelIcon() }}>
                            <i data-bind={{ css: $component.LabelIcon }}></i>
                        </ko-bind>&nbsp;
                        <ko-bind data-bind={{ text: $component.Label }}></ko-bind>
                    </label>
                    <i className="fa fa-info-circle pull-right" data-bind={{ attr: { title: $component.DateText } }} style={{ marginTop: "5px" }}></i>
                    <div className="input-group">
                        <input className="form-control" type="text" data-bind={{ value: $component.TextValue, valueUpdate: 'focusout', selectOnFocus: {}, disable: $component.ReadOnly, attr: { placeholder: $component.Placeholder }, css: { 'bg-red-sunglo': $component.NextDayOverrunning } }} />
                        <span className="input-group-btn">
                            <button tabIndex={-1} className="btn btn-primary" type="button" data-bind={{ click: $component.showAdvancedEditor.bind($component) }}>
                                <i className="fa fa-globe"></i>
                            </button>
                        </span>
                    </div>
                    <div className="popover left inline-block" style="right: 64px; top: 26px; width: 400px; left: auto;" data-bind={{ visible: $component.AdvancedEditorVisible }}>
                        <div className="arrow" style="top: 16px"></div>
                        <h3 className="popover-title" data-bind={{ text: $component.AdvancedEditorTitle }}></h3>
                        <div className="popover-content">
                            <div className="row">
                                <div className="col-md-12">
                                    <timezone-picker params="Label: TimeZoneTitle, TimeZone: TimeZone"></timezone-picker>
                                </div>
                            </div>
                            <ko-bind data-bind={{ if: $component.CanChangeDate }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="control-label" data-bind={{ text: $component.DateTitle }}></label>
                                        <input className="form-control" type="text" data-bind={{ datePicker: $component.DateValue }} />
                                    </div>
                                </div>
                            </div>
                            </ko-bind>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <button className="btn btn-default btn-xs" data-bind={{ click: $component.showAdvancedEditor.bind($component) }}>&times; Chiudi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="help-block text-ellipsis" data-bind={{ css: { 'bg-red': $component.NotServerTimeZone } }}>
                        <ko-bind data-bind={{ if: $component.NotServerTimeZone }}>
                            &nbsp;<i className="fa fa-warning red"></i>
                        </ko-bind>
                        <ko-bind data-bind={{ text: $component.TimeZone }}></ko-bind>
                    </span>
                </div>
            ]);

            return vm;
        }
    },
    template:  []
});