import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 10.07
 * To change this template use File | Settings | File Templates.
 */

export class Validations {
    public static isPartitaIva(val)
    {
        var matches = val.match(/([A-Z]{2})([0-9]*)/i);
        if(matches && matches.length == 3) {
            val = matches[2];
        }
    
        var code = val.toString().substring(7,10);
        if (!(parseInt(code) > 0 && parseInt(code) <122))
            return false;
    
        var x = 0;
        var y = 0;
        var z = 0;
        var T = 0;
    
        for (var i = 0; i < val.length; i++) {
            if (((i + 1) % 2) == 0) {
                y = y + parseInt(val[i]);
                if (parseInt(val[i]) >= 5) {
                    z = z + 1;
                }
            } else {
                x = x + parseInt(val[i]);
            }
        }
        T = (x + (2 * y) + z) % 10;
        return T == 0;
    }

    public static isCodiceFiscale(cf) {
        var validi, i, s, set1, set2, setpari, setdisp;
        cf = cf.toUpperCase();
    
        validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for( i = 0; i < 16; i++ ){
            if( validi.indexOf( cf.charAt(i) ) == -1 )
                return false;
        }
        set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
        setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
        s = 0;
        for( i = 1; i <= 13; i += 2 )
            s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
        for( i = 0; i <= 14; i += 2 )
            s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
        if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
            return false;
    
        return true;
    }

    public static isCodiceFiscaleAziende(val) {
        return val.length == 11 && Number(val);
    }
}