import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IPurchasesDataProvider } from "../../../ProlifeSdk/interfaces/financial-data/IFinancialDataProvider";
import { IEntityProviderService, IEntityDescriptor } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IFinancialDataService } from "../../../ProlifeSdk/interfaces/financial-data/IFinancialDataService";
import { IPurchase, IPurchaseRow } from "../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ILogService } from "../../../ProlifeSdk/interfaces/desktop/ILogService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IPurchaseForMonthlyInvoicing } from "../../interfaces/IPurchasesService";
import { ISearchFilter } from "../../../ProlifeSdk/interfaces/ISearchFilter";

export class PurchasesFinancialDataProvider implements IPurchasesDataProvider {
    private logService: ILogService;
    private ajaxService: IAjaxService;
    private entitiesService: IEntityProviderService;
    private sdkService: IProLifeSdkService;

    constructor(serviceLocator: IServiceLocator) {
        var financialService = <IFinancialDataService>serviceLocator.findService(ProlifeSdk.FinancialDataServiceType);
        financialService.registerProvider(this);
        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.PurchasesEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.Blog.Purchase,
            PluralEntityName: ProlifeSdk.TextResources.Blog.Purchases,
        });
    }

    getType(): string {
        return ProlifeSdk.FinancialDataPurchasesProviderType;
    }

    getByEventId(eventId: number): Promise<IPurchase> {
        return this.ajaxService.Get<IPurchase>("Blog-api/Purchases", "ByEventId?id=" + eventId, {});
    }

    deleteByEventId(eventId: number): Promise<void> {
        return this.ajaxService.Delete("Blog-api", "Purchases/ByEventId/" + eventId, {});
    }

    getData(
        skip: number,
        count: number,
        jobOrderId: number,
        filters: ISearchFilter[] = [],
        includeNotBillable: boolean = null,
        getCosts: boolean = null,
        includeAlreadyImported = false
    ): Promise<IPurchaseRow[]> {
        filters.push({
            Tags: [
                {
                    ValueTypeId: ProlifeSdk.JobOrderEntityType,
                    Values: [jobOrderId.toString()],
                },
            ],
        });

        filters.forEach((f: ISearchFilter) => {
            (f.From = this.sdkService.Utilities.Dates.AddGmtTime(f.From)),
                (f.To = this.sdkService.Utilities.Dates.AddGmtTime(f.To));
        });

        return this.ajaxService.Post("Blog-api/Purchases", "Search", {
            methodData: {
                skip: skip,
                count: count,
                LogFilters: filters,
                includeNotBillable: includeNotBillable,
                getCosts: getCosts,
                includeAlreadyImported: includeAlreadyImported,
            },
        });
    }

    getDataForMonthlyInvoicing(
        jobOrderId: number,
        jobOrderType: number,
        customerId: number,
        billed: boolean,
        from: Date,
        to: Date
    ): Promise<IPurchaseForMonthlyInvoicing[]> {
        return this.ajaxService.Post("Blog-api/Purchases", "GetPurchasesForMonthlyInvoicing", {
            methodData: {
                JobOrderId: jobOrderId,
                JobOrderType: jobOrderType,
                CustomerId: customerId,
                Billed: billed,
                From: from,
                To: to,
            },
        });
    }

    getDataForMonthlyInvoicingCount(
        jobOrderId: number,
        jobOrderType: number,
        customerId: number,
        billed: boolean,
        from: Date,
        to: Date
    ): Promise<number> {
        return this.ajaxService.Post("Blog-api/Purchases", "GetPurchasesForMonthlyInvoicingCount", {
            methodData: {
                JobOrderId: jobOrderId,
                JobOrderType: jobOrderType,
                CustomerId: customerId,
                Billed: billed,
                From: from,
                To: to,
            },
        });
    }

    setSalId(ids: number[], salId: number): Promise<void> {
        return this.ajaxService.Post("Blog-api/Purchases", "SalId", {
            methodData: { purchasesIds: ids, salId: salId },
        });
    }

    save(purchase: IPurchase): Promise<IPurchase> {
        return this.ajaxService.Post("Blog-api", "Purchases", {
            methodData: purchase,
        });
    }

    GetPurchasesByIds(ids: number[]): Promise<IPurchaseRow[]> {
        return this.ajaxService.Post("Blog-api", "Purchases/GetPurchasesByIds", {
            methodData: ids,
        });
    }
}
