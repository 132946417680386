import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog//BlogEventBase";
import { WarehouseLoadEvent } from "./warehouse/ui/events/WarehouseLoadEvent";
import { WarehouseLoadRefDetailsViewModelFactory } from "./warehouse/providers/refs-details-factories/WarehouseLoadRefDetailsViewModelFactory";
import { IWarehouseLoad, IWarehouseLoadRow, IFullWarehouseLoad } from "../ProlifeSdk/interfaces/warehouse/IWarehouseLoad";
import { IMovementService, IMovementServiceObserver, IGetWarehouseLoadsForListRequest, IWarehouseLoadForList, IGetWarehouseTransfersForListRequest, IWarehouseTransferForList } from "../ProlifeSdk/interfaces/warehouse/IMovementService";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IEventUiProvider } from "../ProlifeSdk/interfaces/blog/IEventUiProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";

interface IBlogViewModels
{
    [type : string] : new (serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver) => BlogEventBase;
}

class MovementService implements IMovementService, IEventUiProvider
{
    private ajaxService : IAjaxService;
    private entitiesService : IEntityProviderService;
    private observers : IMovementServiceObserver[] = [];
    private eventsViewModels : IBlogViewModels = {};

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IMovementService>(), this);
    }

    InitializeService() {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        this.entitiesService = <IEntityProviderService> this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.eventsViewModels[ProlifeSdk.WarehouseEventType_WarehouseLoad] = WarehouseLoadEvent;

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode : ProlifeSdk.WarehouseLoadEntityTypeCode,
            EntityName : ProlifeSdk.TextResources.Warehouse.WarehouseLoadExt,
            PluralEntityName : ProlifeSdk.TextResources.Warehouse.WarehouseLoadsExt
        });

        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.WarehouseLoadEntityTypeCode, new WarehouseLoadRefDetailsViewModelFactory());
    }

    public getUIForEvent(item : ILogEvent, contextEventsObserver : IContextEventsObserver) : IView
    {
        var type = this.eventsViewModels[item.EventType] || BlogEventBase;
        var event : BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView> event;
    }

    addObserver(observer: IMovementServiceObserver)
    {
        this.observers.push(observer);
    }

    removeObserver(observer: IMovementServiceObserver)
    {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    getServiceType() : string
    {
        return ProlifeSdk.MovementServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getWarehouseLoadsByProtocol(protocolId: number, filter?: string): Promise<IWarehouseLoad[]>
    {
        if(!protocolId) {
            return Promise.reject<IWarehouseLoad[]>(undefined);
        }

        var filterQuery = "&filter=" + (filter || "");

        return this.ajaxService.Get("Warehouse-api", "WarehouseLoad/ByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    public getJobOrderWarehouseLoads(jobOrderId : number) : Promise<IWarehouseLoad[]>
    {
        if(!jobOrderId)
            return Promise.reject<IWarehouseLoad[]>(undefined);

        return this.ajaxService.Get("Warehouse-api", "WarehouseLoad/ByJobOrderId?jobOrderId=" + jobOrderId, { background: true });
    }


    getWarehouseLoadsPartByProtocol(protocolId : number, skip : number, count : number, filter? : string) : Promise<IWarehouseLoad[]>
    {
        if(!protocolId)
            return Promise.reject<IWarehouseLoad[]>(undefined);

        var filterQuery = "&skip=" + skip + "&count=" + count + "&filter=" + (filter || "");

        return this.ajaxService.Get("Documents-api", "WarehouseLoad/PartByProtocol?protocolId=" + protocolId + filterQuery, { background: true });
    }

    getWarehouseLoadById(documentId: number): Promise<IWarehouseLoad>
    {
        if(!documentId) {
            return Promise.reject<IWarehouseLoad>(undefined);
        }

        return this.ajaxService.Get("Warehouse-api", "WarehouseLoad/" + documentId, {});
    }

    getWarehouseLoadRowsById(documentId: number): Promise<IWarehouseLoadRow[]>
    {
        if(!documentId) {
            return Promise.reject<IWarehouseLoadRow[]>(undefined);
        }

        return this.ajaxService.Post("Warehouse-api", "WarehouseLoadRows/GetWarehouseLoadRows", {
            methodData: {
                DocumentId: documentId
            }
        });

        //return this.ajaxService.Get("Warehouse-api", "WarehouseLoadRows/" + documentId, {});
    }

    createOrUpdateWarehouseLoad(warehouseLoad: IFullWarehouseLoad): Promise<IWarehouseLoad>
    {
        var callback = (data : IWarehouseLoad) => {
            if(data)
                data.Numero = data.Numero ? data.Numero : "";
            this.observers.forEach((o : IMovementServiceObserver) => o.onWarehouseLoadAdded(data));
            return data;
        };

        if(warehouseLoad.WarehouseLoad.Id > 0)
            callback = (data : IWarehouseLoad) => {
                this.observers.forEach((o : IMovementServiceObserver) => o.onWarehouseLoadChanged(data));
                return data;
            }

        return this.ajaxService.Post<IWarehouseLoad>("Warehouse-api", "WarehouseLoad", {
            methodData: warehouseLoad
        }).then(callback);
    }

    deleteWarehouseLoad(documentId: number): Promise<void>
    {
        return this.ajaxService.Delete<void>("Warehouse-api", "WarehouseLoad/" + documentId, {})
            .then(() => {
                this.observers.forEach((observer : IMovementServiceObserver) => observer.onWarehouseLoadDeleted(documentId));
            });
    }

    setFreezeStatusByWarehouseLoadsIds(freeze: boolean, ids: number[]): Promise<void>
    {
        if(!ids || ids.length == 0) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService.Post<void>("Warehouse-api", "WarehouseLoad/SetFreezeStatus" , {
            methodData : { freezeStatus : freeze, ids: ids }
        }).then(() => {
                ids.forEach((id : number) => this.observers
                    .forEach((observer : IMovementServiceObserver) => observer.onWarehouseLoadLockStateChanged(id, freeze)));
            });
    }

    /*getLoadDetailUrl(documentId : number, registerId : number) : string
    {
        return String.format("#/" + ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, "Whl" + registerId, documentId);
    }

    getLoadNewDocumentUrl(registerId : number) : string
    {
        return "/#/" + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentURL, "Whl" + registerId);
    }

    getLoadForJobOrderNewDocumentUrl(registerId : number, jobOrderId : number) : string
    {
        return '/#/' + String.format(ProlifeSdk.TextResources.Invoices.NewDocumentForJobOrderURL, "Whl" + registerId, jobOrderId);
    }*/

    GetWarehouseLoadsForList(request: IGetWarehouseLoadsForListRequest): Promise<IWarehouseLoadForList[]> {
        return this.ajaxService.Post<IWarehouseLoadForList[]>("Documents-api/WarehouseLoad", "GetWarehouseLoadsForList", {
            background: true,
            methodData: request
        });
    }

    GetWarehouseTransfersForList(request: IGetWarehouseTransfersForListRequest): Promise<IWarehouseTransferForList[]> {
        return this.ajaxService.Post<IWarehouseTransferForList[]>("Documents-api/WarehouseTransfer", "GetWarehouseTransfersForList", {
            background: true,
            methodData: request
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new MovementService(serviceLocator);
}