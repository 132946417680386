import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Param, ComponentParam, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import {
    ArticleAliasesDataSource,
    IArticleAliasesDataSourceModel,
} from "../../../../DataSources/ArticleAliasesDataSource";
import { ArticlesDataSource } from "../../../../DataSources/ArticlesDataSource";
import { IArticleAliasEx, IAliasesService } from "../../../AliasesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import jss from "jss";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { ArticleAliasesImportDialog } from "./ArticleAliasesImportDialog";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";

export interface IArticleAliasesEditor {
    HasChanges: ko.Computed<boolean>;
}

export interface IArticleAliasesEditorParams {
    CustomerId: Param<number>;
    Editable: Param<boolean>;
    InjectTo: Param<IArticleAliasesEditor>;
}

@DetectClassChanges
export class ArticleAlias implements IDataSourceListener, IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable(0);
    dispose(): void {}

    Id: ko.Observable<number> = ko.observable();
    @DetectChanges
    ArticleId: ko.Observable<number> = ko.observable();
    CustomerId: ko.Observable<number> = ko.observable();
    @DetectChanges
    NewManufacturerPrefix: ko.Observable<string> = ko.observable();
    @DetectChanges
    NewCode: ko.Observable<string> = ko.observable();
    OriginalArticleCode: ko.Observable<string> = ko.observable();
    AliasArticleCode: ko.Computed<string>;
    Description: ko.Observable<string> = ko.observable();

    isNew: ko.Computed<boolean>;

    ArticlesDataSource: ArticlesDataSource = new ArticlesDataSource();

    ManufacturerCode: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IAliasesService>())
    private aliasesService: IAliasesService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(
        private editor: ArticleAliasesEditor,
        private alias: IArticleAliasEx | null,
        customerId?: number | null
    ) {
        this.load(alias, customerId);

        this.isNew = ko.pureComputed(() => !this.Id() || this.Id() < 0);
        this.AliasArticleCode = ko.pureComputed(() => {
            this.NewManufacturerPrefix();
            this.NewCode();

            if (!this.OriginalArticleCode()) return "";

            let manufacturerCode = this.OriginalArticleCode().substr(0, 3);
            let articleCode = this.OriginalArticleCode().substr(3);

            return (this.NewManufacturerPrefix() || manufacturerCode) + (this.NewCode() || articleCode);
        });

        this.ManufacturerCode();
    }

    private load(alias: IArticleAliasEx, customerId?: number | null) {
        if (alias) {
            this.Id(alias.Id);
            this.ArticleId(alias.ArticleId);
            this.CustomerId(customerId || alias.CustomerId);
            this.NewManufacturerPrefix(alias.NewManufacturerPrefix);
            this.NewCode(alias.NewCode);
            this.OriginalArticleCode(alias.OriginalArticleCode);
            this.Description(alias.Description);
        } else {
            this.Id(undefined);
            this.ArticleId(undefined);
            this.CustomerId(customerId);
            this.NewManufacturerPrefix(undefined);
            this.NewCode(undefined);
            this.OriginalArticleCode(undefined);
            this.Description(undefined);
        }

        this.isChanged(0);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender == this.ArticlesDataSource && model) {
            this.OriginalArticleCode(model.title);
            this.ManufacturerCode(model.title.substr(0, 3));
        }
        if (sender == this.ArticlesDataSource && model == null) {
            this.OriginalArticleCode(undefined);
            this.ManufacturerCode(undefined);
            this.NewCode(undefined);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender == this.ArticlesDataSource) {
            this.OriginalArticleCode(undefined);
            this.ManufacturerCode(undefined);
            this.NewCode(undefined);
        }
    }

    public async save(): Promise<void> {
        try {
            let wasNew = this.isNew();

            let newAlias = await this.aliasesService.InsertOrUpdateArticleAlias([
                {
                    Id: this.Id(),
                    ArticleId: this.ArticleId(),
                    CustomerId: this.CustomerId(),
                    NewCode: this.NewCode(),
                    NewManufacturerPrefix: this.NewManufacturerPrefix(),
                },
            ]);
            if (newAlias && newAlias.length > 0) this.load(newAlias[0]);

            this.infoToastService.Success(TextResources.Warehouse.ArticleAliasSaveSuccess);
            this.editor.onAliasSaved(wasNew);
        } catch {
            this.infoToastService.Error(ProlifeSdk.TextResources.Warehouse.ErrorWhileSavingArticleAlias);
        }
    }

    public async cancel(): Promise<void> {
        if (this.isChanged() > 0) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return;
        }

        this.load(this.alias, this.CustomerId());
    }

    public async remove(): Promise<void> {
        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ArticleAliasDeleteConfirm,
                TextResources.Warehouse.Cancel,
                TextResources.Warehouse.Delete
            ))
        )
            return;

        try {
            await this.aliasesService.RemoveArticleAlias(this.Id());
            this.infoToastService.Success(TextResources.Warehouse.ArticleAliasDeleteSuccess);

            this.editor.onAliasDeleted();
        } catch {
            this.infoToastService.Error(TextResources.Warehouse.ArticleAliasDeleteError);
        }
    }
}

export class ArticleAliasesEditor implements IArticleAliasesEditor, IDataSourceListener {
    CustomerId: ComponentParam<number>;
    Editable: ComponentParam<boolean>;

    HasChanges: ko.Computed<boolean>;

    SelectedAlias: ko.Observable<ArticleAlias> = ko.observable();
    CodeSearch: ko.Observable<string> = ko.observable();
    Expanded: ko.Observable<boolean> = ko.observable();

    CodeSearchInput: ko.Observable<string> = ko.observable();

    ArticleAliasesDataSource: ArticleAliasesDataSource = new ArticleAliasesDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(params: IArticleAliasesEditorParams) {
        this.CustomerId = ComponentUtils.parseParameter(params.CustomerId, -1);
        this.Editable = ComponentUtils.parseParameter(params.Editable, false);

        let customerIdInterceptor = ko.computed(() => {
            this.ArticleAliasesDataSource.setCustomerId(this.CustomerId());
        });

        this.newAlias();

        this.HasChanges = ko.computed(() => {
            return this.SelectedAlias() && this.SelectedAlias().isChanged() > 0;
        });

        let injectTo = ComponentUtils.parseParameter(params.InjectTo, undefined);
        if (injectTo) injectTo(this);
    }

    async createNewAlias(): Promise<boolean> {
        if (this.HasChanges()) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return false;
        }

        this.newAlias();
        return true;
    }

    private newAlias() {
        this.SelectedAlias(new ArticleAlias(this, null, this.CustomerId()));
        this.ArticleAliasesDataSource.select();
    }

    Expand() {
        this.Expanded(!this.Expanded());
    }

    onItemSelected(sender: IDataSource, model: IArticleAliasesDataSourceModel): void {
        if (sender == this.ArticleAliasesDataSource) {
            this.SelectedAlias(this.createViewModelFor(model));
        }
    }

    onItemDeselected(sender: IDataSource, model: IArticleAliasesDataSourceModel): void {}

    async canSelectItem?(sender: IDataSource, model: IArticleAliasesDataSourceModel): Promise<boolean> {
        if (this.SelectedAlias() && this.SelectedAlias().isChanged() > 0) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Warehouse.ArticleAliasLooseChangesMessage,
                    TextResources.Warehouse.Back,
                    TextResources.Warehouse.Continue
                ))
            )
                return false;
        }

        return true;
    }

    createViewModelFor(model: IArticleAliasesDataSourceModel): ArticleAlias {
        return new ArticleAlias(this, model.model, this.CustomerId());
    }

    onAliasSaved(wasNew: boolean) {
        this.ArticleAliasesDataSource.refresh();
        if (wasNew) this.newAlias();
    }

    onAliasDeleted() {
        this.ArticleAliasesDataSource.refresh();
        this.newAlias();
    }

    getExcelExportData(): any | null {
        return {
            customerId: this.CustomerId(),
        };
    }

    async importAliases(): Promise<void> {
        await new ArticleAliasesImportDialog(this.CustomerId()).showModal();
        this.ArticleAliasesDataSource.refresh();
    }

    search() {
        this.CodeSearch(this.CodeSearchInput());
    }
}

const { classes } = jss
    .createStyleSheet({
        articleAliasListItem: {
            "&.list-notification-item-details": {
                paddingLeft: "10px",
                paddingRight: "10px",
            },
        },
        portletCollapsed: {
            "&.portlet.light.bordered": {
                paddingBottom: "0px",

                "& > .portlet-title": {
                    borderBottom: "none",
                    marginBottom: "0px",
                },
            },
        },
    })
    .attach();

ko.components.register("article-aliases-editor", {
    viewModel: {
        createViewModel: (params: IArticleAliasesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            let vm = new ArticleAliasesEditor(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <div class="row" style="height: 100%">
                        <div class="col-md-6" style="height: 100%">
                            <div class="flex-container flex-vertical flex-full-height">
                                <div
                                    class="portlet light bordered"
                                    data-bind={`css: { '${classes.portletCollapsed}': !Expanded() }`}>
                                    <div class="portlet-title">
                                        <div class="caption">
                                            <i class="icon-list"></i>
                                            <span class="caption-subject bold uppercase">
                                                {TextResources.Warehouse.ArticleAliasesSearch}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <button
                                                class="btn btn-circle btn-default btn-icon-only"
                                                data-bind="click: Expand">
                                                <i class="fa fa-chevron-up" data-bind="visible: Expanded"></i>
                                                <i class="fa fa-chevron-down" data-bind="hidden: Expanded"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="portlet-body form" data-bind="visible: Expanded">
                                        <form data-bind="submit: search.bind($data)">
                                            <text-input
                                                value={() => "CodeSearchInput"}
                                                label={TextResources.Warehouse.SearchByCode}
                                                maxLength={50}
                                                upperCase={true}
                                                addonPosition="right">
                                                <span class="input-group-btn">
                                                    <button class="btn btn-success" type="submit">
                                                        <i class="icon-magnifier"></i>
                                                    </button>
                                                </span>
                                            </text-input>
                                        </form>
                                    </div>
                                </div>
                                <list
                                    dataSource={() => "ArticleAliasesDataSource"}
                                    listener={() => "$data"}
                                    textFilter={() => "CodeSearch"}
                                    containerHeight={"flex"}
                                    class="flex-container flex-fill">
                                    <div
                                        class={"list-notification-item-details " + classes.articleAliasListItem}
                                        data-bind="click: Select, with: Model">
                                        <div class="list-notification-item-title flex-container">
                                            <span data-bind="text: OriginalArticleCode"></span>
                                            <span class="flex-1 text-right" data-bind="text: AliasArticleCode"></span>
                                        </div>
                                        <div class="list-notification-item-time" data-bind="text: Description"></div>
                                    </div>
                                </list>
                            </div>
                        </div>
                        <div class="col-md-6" style="height: 100%">
                            <div class={"portlet light bordered " + classes.portletCollapsed}>
                                <div class="portlet-title flex-container">
                                    <div class="caption text-ellipsis">
                                        <i class="icon-list"></i>
                                        <span class="caption-subject bold uppercase">
                                            {TextResources.Warehouse.ArticleAliasesManagement}
                                        </span>
                                    </div>
                                    <div class="actions flex-1 flex-nowrap text-right">
                                        {/* <ko-if data-bind="Editable"> */}
                                        <button
                                            class="btn btn-circle btn-primary"
                                            data-bind="asyncClick: createNewAlias">
                                            <i class="fa fa-plus"></i>&nbsp;{TextResources.Warehouse.New}
                                        </button>
                                        &nbsp;
                                        {/* </ko-if> */}
                                        <excel-exporter
                                            cssClasses="btn btn-sm btn-circle btn-success"
                                            exporterId="Warehouse/ArticleAliasesExport"
                                            exporterMethod="GenerateExcel"
                                            dataProvider={() => "$data.getExcelExportData.bind($data)"}
                                            position="left">
                                            <i class="fa fa-download"></i>&nbsp;{TextResources.Warehouse.Export}
                                        </excel-exporter>
                                        &nbsp;
                                        <button
                                            class="btn btn-circle btn-warning"
                                            data-bind="asyncClick: importAliases">
                                            <i class="fa fa-upload"></i>&nbsp;{TextResources.Warehouse.Import}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="portlet light bordered" data-bind="with: SelectedAlias">
                                <div class="portlet-title">
                                    <div class="caption">
                                        <i class="icon-list"></i>
                                        <span class="caption-subject bold uppercase" data-bind="hidden: isNew">
                                            {TextResources.Warehouse.EditArticleAlias}
                                        </span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: isNew">
                                            {TextResources.Warehouse.NewArticleAlias}
                                        </span>
                                        {/* <span class="caption-subject bold uppercase" data-bind="hidden: isNew() || !$parent.Editable()">{TextResources.Warehouse.EditArticleAlias}</span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: isNew() && $parent.Editable()">{TextResources.Warehouse.NewArticleAlias}</span>
                                        <span class="caption-subject bold uppercase" data-bind="visible: !$parent.Editable()">{TextResources.Warehouse.ArticleAliasDetails}</span> */}
                                    </div>
                                    <div class="actions" /* data-bind="if: $parent.Editable()" */>
                                        <button class="btn btn-circle btn-primary" data-bind="asyncClick: save">
                                            <i class="fa fa-floppy-o"></i>&nbsp;{TextResources.Warehouse.Save}
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-circle btn-warning"
                                            data-bind="asyncClick: cancel, enable: isChanged() > 0">
                                            <i class="fa fa-times-circle-o"></i>&nbsp;{TextResources.Warehouse.Cancel}
                                        </button>
                                        &nbsp;
                                        <button
                                            class="btn btn-circle btn-danger"
                                            data-bind="hidden: isNew, asyncClick: remove">
                                            <i class="fa fa-trash-o"></i>&nbsp;{TextResources.Warehouse.Delete}
                                        </button>
                                    </div>
                                </div>
                                <div class="portlet-body form">
                                    <select2
                                        value={() => "ArticleId"}
                                        dataSource={() => "ArticlesDataSource"}
                                        listener={() => "$data"}
                                        label={TextResources.Warehouse.Article}
                                        allowClear={true}
                                        placeholder={TextResources.Warehouse.ChooseAnArticle}
                                        /* readOnly={() => "!$parent.Editable()"} */
                                    ></select2>

                                    {/*<text-input value={() => "NewManufacturerPrefix"} 
                                                label="Alias Prefisso Produttore" 
                                                placeholder="Codice produttore non modificato" 
                                                helpText="Utilizzare questo campo per alterare il codice produttore dell'articolo (primi 3 caratteri)"
                                                maxLength={3}
                                                upperCase={true}></text-input>*/}

                                    <text-input
                                        value={() => "NewCode"}
                                        label={TextResources.Warehouse.ArticleCodeAlias}
                                        placeholder={TextResources.Warehouse.ArticleCodeAliasPlaceholder}
                                        helpText={TextResources.Warehouse.ArticleCodeAliasHelpText}
                                        maxLength={47}
                                        upperCase={true}
                                        addonPosition="left"
                                        /* readOnly={() => "!$parent.Editable()"} */
                                    >
                                        <span class="input-group-addon">
                                            <label
                                                style="margin-bottom: 0px;"
                                                data-bind="text: $parent.ManufacturerCode"></label>
                                        </span>
                                    </text-input>

                                    <text-input
                                        value={() => "AliasArticleCode"}
                                        label={TextResources.Warehouse.NewArticleCodePreview}
                                        readOnly={true}></text-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
