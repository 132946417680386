import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/03/2016
 * Time: 14:29
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IWorkflowOutcomeSettingsManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";

export class WorkflowOutcomeBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();

        var interceptor = ko.computed(() => {
            var outcome = ko.utils.unwrapObservable(underlyingObservable);

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var workflowOutcomeSettings = <IWorkflowOutcomeSettingsManager> settingsService.findSettingsManager(ProlifeSdk.WorkflowOutcomes);

            var outcomeInstance = workflowOutcomeSettings.getOutcomeById(outcome);

            if(outcomeInstance)
                return outcomeInstance.Description;
            return "";
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["workflowOutcomeText"] = new WorkflowOutcomeBinding()
ko.virtualElements.allowedBindings["workflowOutcomeText"] = true;