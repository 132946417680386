import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/02/13
 * Time: 16.32
 * To change this template use File | Settings | File Templates.
 */

export class SubmitOnEnterOrTab {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        //TODO: Sarebbe meglio usare metodi statici piuttosto che usare l'istanza registrata nei bindingHandlers
        var self : SubmitOnEnterOrTab = <any> ko.bindingHandlers["submitOnEnterOrTab"];
        var options = ko.utils.unwrapObservable(valueAccessor());

        if(options.submitOnLostFocus) {
            element.addEventListener('blur', (event) => {
                setTimeout(() => {
                    if(self.unwrapOptionValue(options.submitOnLostFocus, viewModel))
                        self.doSubmit(self, event, element, options, 9, viewModel);
                }, (options.blurDelay || 0));

            });
        }

        element.addEventListener('keydown', (event) => {
            var keycode = event.keyCode || event.which;

            if(!event.shiftKey && (keycode == 13 || (keycode == 9 && !options.submitOnLostFocus))) {
                if (options.ignoreEnter && keycode == 13) return true;
                if (options.ignoreTab && keycode == 9) return true;

                self.doSubmit(self, event, element, options, keycode, viewModel);
            }

            return true;
        });
    }

    private doSubmit(self, event, element, options, keycode, viewModel) {
        event.preventDefault();
        event.stopPropagation();

        var form = $(element).closest("form");
        if(options.form) {
            form = $(options.form);
        }

        var useDefaultJump = true;
        useDefaultJump = useDefaultJump && !self.handleJumpToFirstFormField(element, keycode, form, options, viewModel);
        useDefaultJump = useDefaultJump && !self.handleJumpToLastFieldOfType(element, keycode, form, options, viewModel);

        if(useDefaultJump)
            self.handleDefaultJump(element, keycode, form, options, viewModel);

        form.submit();

        return false;
    }

    private handleJumpToLastFieldOfType(element, keycode, form, options, viewModel) : boolean {
        var randomNumber = (Math.random() * Number.MAX_VALUE).toString();

        var jumpToLastFieldOfType = this.unwrapOptionValue(options.jumpToLastFieldOfType, viewModel);

        if(jumpToLastFieldOfType) {
            form.on('submit.' + randomNumber, (e) => {
                setTimeout(() => { $(jumpToLastFieldOfType + ":last").focus();}, 200);

                if(options.selectOnFocus) {
                    setTimeout(() => {
                        $(jumpToLastFieldOfType + ":last").select();
                    }, 200);
                }

                form.off('submit.' + randomNumber);
            });
            return true;
        }

        return false;
    }

    private handleJumpToFirstFormField(element, keycode, form, options, viewModel) : boolean {
        var randomNumber = (Math.random() * Number.MAX_VALUE).toString();

        var jumpToFirstFormField = this.unwrapOptionValue(options.jumpToFirstFormField, viewModel);

        if(jumpToFirstFormField) {
            form.on('submit.' + randomNumber, (e) => {
                $(element).closest("form").find(":input:visible:enabled:first").focus();
                form.off('submit.' + randomNumber);
            });
            return true;
        }

        return false;
    }

    private handleDefaultJump(element, keycode, form, options, viewModel) : boolean {
        var randomNumber = (Math.random() * Number.MAX_VALUE).toString();
        form.on('submit.' + randomNumber, (e) => {
            if (keycode == 9) {
                var tabables = $("input[tabindex != '-1']:visible");
                var myindex = tabables.index($(element));
                tabables.eq(myindex+1).focus();
            }
            form.off('submit.' + randomNumber);
        });

        return true;
    }

    private unwrapOptionValue(option, viewModel) {
        return ko.isObservable(option) ? ko.utils.unwrapObservable(option) :
            typeof option === 'function' ? option.call(viewModel) : option;
    }
}

ko.bindingHandlers["submitOnEnterOrTab"] = new SubmitOnEnterOrTab();

interface ICallOnEnterOrTabParams {
    submitOnLostFocus?: boolean;
    selectOnFocus?: boolean;
    ignoreEnter?: boolean;
    ignoreTab?: boolean;
    jumpToLastFieldOfType?: string;
    functionToCall: () => Promise<void>;
}

export class CallOnEnterOrTab {
    init(element: any, valueAccessor: () => ICallOnEnterOrTabParams, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = ko.utils.unwrapObservable(valueAccessor());

        if(options.submitOnLostFocus) {
            $(element).on('blur.callOnEnterOrTab', (event) => {
                if($(element).val() === "")
                    return true;

                event.preventDefault();
                event.stopPropagation();

                setTimeout(() => {
                    CallOnEnterOrTab.doCall(element, options);
                }, 100);

                return false;
            });
        }

        $(element).on('keydown.callOnEnterOrTab', (event) => {
            var keycode = event.keyCode || event.which;

            if(!event.shiftKey && (keycode == 13 || (keycode == 9 && !options.submitOnLostFocus))) {
                if (options.ignoreEnter && keycode == 13) return true;
                if (options.ignoreTab && keycode == 9) return true;

                if($(element).val() === "")
                    return true;

                event.preventDefault();
                event.stopPropagation();

                setTimeout(() => {
                    CallOnEnterOrTab.doCall(element, options);
                }, 100);
            }

            return true;
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).off("blur.callOnEnterOrTab");
            $(element).off("keydown.callOnEnterOrTab");
        });
    }

    private static async doCall(element: HTMLElement, options: ICallOnEnterOrTabParams) {
        await options.functionToCall();

        if(options.jumpToLastFieldOfType) {
            let field = $(options.jumpToLastFieldOfType + ":last");

            field.focus();

            if(options.selectOnFocus) {
                field.select();
            }
        }
        return false;
    }
}

ko.bindingHandlers["callOnEnterOrTab"] = new CallOnEnterOrTab();