import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";
import { ISearchResultsProvider } from "../../interfaces/ISearchResultsProvider";
import { IEntitySearchResult } from "../../interfaces/IEntitySearchResult";
import { ISearchTagFilter } from "../../interfaces/ISearchTagFilter";
import { ILogTagFilter, ILogFilter } from "../../interfaces/ILogFilter";
import { Deferred } from "../../../Core/Deferred";

export class GroupedEntitiesProvider
{
    protected lastTimeout : ReturnType<typeof setTimeout>;
    
    @LazyImport(nameof<IEntityProviderService>())
    protected entitiesProviderService : IEntityProviderService;

	constructor(protected target : ISearchResultsProvider, protected defaultTextLabel : string = ProlifeSdk.TextResources.ProlifeSdk.TextsAndDates, protected entitiesCodes : string[] = [])
    {
        target.findSearchMatches = this.findSearchMatches.bind(this);
		target.findSearchMatch = this.findSearchMatch.bind(this);
	}

	protected findSearchMatches(query : any)
    {
		if(this.lastTimeout)
			clearTimeout(this.lastTimeout);

		this.lastTimeout = setTimeout(() =>
        {
			this.search(query.term, query.callback);
		}, 500);
	}

	protected findSearchMatch(element, callback)
    {
		var id = <string> $(element).val();
		if(id !== "") {
			var result = this.searchById(id);
			callback(result);
		}
	}

	protected search(searchFilter : string, callback : (data : any) => void)
    {
		var data = { results: [] };

        var providersDef = new Deferred();
        this.entitiesProviderService.search(searchFilter, this.entitiesCodes).then((results : IEntitySearchResult[]) => {

            var allTagsFilters : ISearchTagFilter[] = [];

            results.forEach((result : IEntitySearchResult) => {

                if(result.entities.length == 0)
                    return;

                var menuGroup = {
                    text : result.provider.getEntityTypeName(),
                    children : []
                };

                result.entities.forEach(e => {
                    var tags : ILogTagFilter[] = [{
                        Values : [result.provider.getPkValue(e)],
                        ValueTypeId : result.provider.getType()
                    }];

                    var entityFilter : ILogFilter = {
                        Tags : tags
                    };

                    entityFilter.Tags.forEach((t) => { allTagsFilters.push(t); });

                    menuGroup.children.push({
                        id : JSON.stringify(entityFilter),
                        text : result.provider.getEntityTypeName() + ':   ' + result.provider.getDisplayName(e)
                    });
                });

                data.results.push(menuGroup);

            });

            //Voce Tutto (ricerca il contenuto in tutti i campi)
            var getForAllFilter : ILogFilter = {
                IsSearchForAllFilter : true,
                HintSearch : searchFilter
                //Tags : allTagsFilters
            };

            data.results.splice(0, 0, {
                text : this.defaultTextLabel,
                children : [{
                    id : JSON.stringify(getForAllFilter),
                    text : searchFilter
                }]
            });
            /***/

            providersDef.resolve();
        }).catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
	}

	protected searchById(serializedItem : string) : any
    {
		return {
			id: JSON.parse(serializedItem),
			text: 'boh'
		}
	}
}