import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { OffsetEditor } from "./ui/OffsetEditor";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export type IOffsetSettingsManager = ISettingsManager

export class OffsetSettingsManager implements IOffsetSettingsManager {
    @LazyImport(ProlifeSdk.SettingsServiceType)
    private settingsService : ISettingsService;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }
    
    load(): Promise<any> {
        return Promise.resolve<any>(undefined);
    }    
    
    getName(): string {
        return ProlifeSdk.Offset;
    }
    
    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.Offset;
    }
    
    hasEditingUI(): boolean {
        return true;
    }
    
    getEditingUI(): IView {
        return {
            templateName: "offsetEditor",
            templateUrl: "Invoices/Templates/Settings",
            viewModel: new OffsetEditor()
        };
    }


}