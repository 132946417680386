import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { Moment } from "moment";
import { IDataSourceModel } from "./IDataSource";
import { ISnapshot, ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";

export interface IWorkflowSnapshotsGroupDataSourceModel extends IDataSourceModel<number, Date> {

}

export interface IWorkflowSnapshotsDataSourceModel extends IDataSourceModel<number, ISnapshot> {

}

export class WorkflowSnapshotsDataSource extends BaseDataSource<IDataSourceModel> {
    private workflowId: number;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;

    private snapshotsCache: Map<number, ISnapshot[]>;
    private lastTextFilter: string = undefined;
    
    constructor() {
        super();

        this.snapshotsCache = new Map();
    }

    public setWorkflowId(workflowId: number): void {
        this.workflowId = workflowId;
    }

    public getTitle(currentModel: IWorkflowSnapshotsDataSourceModel): string {
        return TextResources.Todolist.WorkflowSnapshotsDataSource;
    }
    
    public async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDataSourceModel[]> {
        if (skip > 0)
            return [];

        if (this.snapshotsCache.size === 0 || textFilter != this.lastTextFilter) {
            this.lastTextFilter = textFilter;
            this.refreshRequested = true;
        }

        while (this.refreshRequested) {
            this.refreshRequested = false;
            this.snapshotsCache.clear();
            await this.loadData(textFilter, skip, count);
        }

        if (this.isGroupedData(currentModel))
            return this.createNodes();

        return this.getSnapshots(currentModel as IWorkflowSnapshotsGroupDataSourceModel);
    }

    public async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IWorkflowSnapshotsDataSourceModel[]> {
        throw new Error("Method not implemented.");
    }

    public isGroupedData(currentModel: IDataSourceModel): boolean {
        return !currentModel;
    }

    public refresh(): void {
        this.snapshotsCache.clear();
        super.refresh();
    }

    private async loadData(textFilter: string, skip: number, count: number): Promise<void> {
        let snapshots = await this.todolistService.GetWorkflowSnapshots(this.workflowId, textFilter, skip, count);

        let lastKey: Moment = null;
        let snapshotsGroup = [];

        for (let snapshot of snapshots) {
            let momentDate = moment(snapshot.Date).startOf("day");
            
            if (lastKey != null && !lastKey.isSame(momentDate)) {
                this.snapshotsCache.set(lastKey.valueOf(), snapshotsGroup);

                snapshotsGroup = [];
                lastKey = momentDate;
            }

            lastKey = momentDate;
            snapshotsGroup.push(snapshot);
        }

        if (snapshotsGroup.length > 0)
            this.snapshotsCache.set(lastKey.valueOf(), snapshotsGroup);
    }

    private getSnapshots(currentModel: IWorkflowSnapshotsGroupDataSourceModel): IWorkflowSnapshotsDataSourceModel[] {
        if (!this.snapshotsCache.has(currentModel.model.valueOf()))
            return [];

        return this.snapshotsCache.get(currentModel.model.valueOf()).map(this.createSnapshotModel, this);
    }
    
    private createNodes(): IWorkflowSnapshotsGroupDataSourceModel[] {
        let datesIterator: IterableIterator<number> = this.snapshotsCache.keys();
        let key = datesIterator.next();

        let nodesModels: IWorkflowSnapshotsGroupDataSourceModel[] = [];

        while (!!key && !!key.value) {
            let dateValue = key.value;
            nodesModels.push(this.createNodeModel(dateValue))
            key = datesIterator.next();
        }

        return nodesModels;
    }
    
    private createNodeModel(date: number): IWorkflowSnapshotsGroupDataSourceModel {
        let momentDate = moment(date);

        return {
            id: date,
            isGroup: true,
            isLeaf: false,
            title: momentDate.format("L"),
            model: momentDate.toDate()
        };
    }

    private createSnapshotModel(snapshot: ISnapshot): IWorkflowSnapshotsDataSourceModel {
        return {
            id: snapshot.Id,
            isGroup: false,
            isLeaf: true,
            title: snapshot.Label,
            model: snapshot
        };
    }
}