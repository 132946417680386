import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IDocumentBuilderDocumentRelatedDocuments } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IRelatedDocumentViewModel, IRelatedDocumentType } from "../../../ProlifeSdk/interfaces/invoice/IRelatedDocumentViewModel";
import { IRelatedDocument } from "../../../ProlifeSdk/interfaces/invoice/IRelatedDocument";

export class RelatedDocumentInfo implements IRelatedDocumentViewModel {
    public Id: number;

    public DocumentNumber: ko.Observable<string> = ko.observable();
    public DocumentType: ko.Observable<string> = ko.observable();
    public DocumentDate: ko.Observable<Date> = ko.observable();
    public RowsIndices: ko.Observable<string> = ko.observable();
    public CIG: ko.Observable<string> = ko.observable();
    public CUP: ko.Observable<string> = ko.observable();
    public DocumentsTypesList: ko.ObservableArray<IRelatedDocumentType> = ko.observableArray([]);

    public ShowCIGAndCUP: ko.Computed<boolean>;
    public ValidForElectronicInvoice: ko.Computed<boolean>;
    public DocumentTypeLabel: ko.Computed<string>;

    private CreditNote: ko.Observable<boolean> = ko.observable();
    private AccompanyingInvoice: ko.Observable<boolean> = ko.observable();
    private SALInvoice: ko.Observable<boolean> = ko.observable();

    constructor(private document: IDocumentBuilderDocumentRelatedDocuments, private containerList: ko.ObservableArray<RelatedDocumentInfo>) {
        this.populateDocumentsTypesList();

        if (this.document) {
            this.Id = this.document.Id;
            this.DocumentNumber(this.document.RelatedDocumentNumber);
            this.DocumentDate(this.document.RelatedDocumentDate);
            this.DocumentType(this.createDocTypeCode());
            this.RowsIndices(this.document.RowsIndices);
            this.CIG(this.document.RelatedDocumentCIG);
            this.CUP(this.document.RelatedDocumentCUP);
            this.CreditNote(this.document.CreditNote);
            this.AccompanyingInvoice(this.document.AccompanyingInvoice);
            this.SALInvoice(this.document.SALInvoice);
        }

        this.DocumentTypeLabel = ko.computed(() => {
            this.DocumentType();
            var documentMatches = this.DocumentsTypesList().filter((d: any) => d.Type == this.DocumentType());
            return documentMatches.length > 0 ? documentMatches[0].Label : null;
        });

        this.ShowCIGAndCUP = ko.computed(() => {
            return this.DocumentType() != ProlifeSdk.DdtEntityTypeCode 
                && this.DocumentType() != ProlifeSdk.EstimateEntityTypeCode
                && this.DocumentType() != ProlifeSdk.SalEntityTypeCode;
        });

        this.ValidForElectronicInvoice = ko.computed(() => {
            return this.DocumentType() == ProlifeSdk.DdtEntityTypeCode
                || this.DocumentType() == ProlifeSdk.InvoiceEntityTypeCode
                || this.DocumentType() == ProlifeSdk.InvoiceEntityTypeCode + "_SI"
                || this.DocumentType() == ProlifeSdk.CustomerOrderEntityTypeCode
        });

        this.DocumentType.subscribe(this.setInvoiceTypeFlags.bind(this));
    }

    public openDocument(): void {
        /*var documentsNavigator: IDocumentsNavigator = this.parent.getDocumentsNavigator();
        var providerId: string = this.getProviderId();

        documentsNavigator.openDocumentByProviderId(providerId, this.document.RelatedDocumentId);*/
    }

    public remove(): void {
        var index: number = this.containerList.indexOf(this);
        var list: RelatedDocumentInfo[] = this.containerList();

        list.splice(index, 1);

        this.containerList(list);
    }

    public getData(): IRelatedDocument {
        var res: IRelatedDocument = <IRelatedDocument>$.extend(true, {}, this.document);

        res.RelatedDocumentNumber = this.DocumentNumber();
        res.RelatedDocumentDate = this.DocumentDate();
        res.RelatedDocumentType = this.getDocTypeFromCode();
        res.RowsIndices = this.RowsIndices();
        res.RelatedDocumentCIG = this.CIG();
        res.RelatedDocumentCUP = this.CUP();
        res.CreditNote = this.CreditNote();
        res.AccompanyingInvoice = this.AccompanyingInvoice();
        res.SALInvoice = this.SALInvoice();

        return res;
    }

    public isDocument(id: number, type: string): boolean {
        return this.document.RelatedDocumentId == id && this.document.RelatedDocumentType == type;
    }

    public updateFrom(document: IRelatedDocument): void {
        this.document = document;

        this.DocumentNumber(this.document.RelatedDocumentNumber);
        this.DocumentDate(this.document.RelatedDocumentDate);
        this.DocumentType(this.createDocTypeCode());
        this.RowsIndices(this.document.RowsIndices);
        this.CIG(this.document.RelatedDocumentCIG);
        this.CUP(this.document.RelatedDocumentCUP);
        this.CreditNote(this.document.CreditNote);
        this.AccompanyingInvoice(this.document.AccompanyingInvoice);
        this.SALInvoice(this.document.SALInvoice);
    }

    private populateDocumentsTypesList(): void {
        this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.Invoice, Type: ProlifeSdk.InvoiceEntityTypeCode });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.InvoiceMore, Type: ProlifeSdk.InvoiceEntytyTypeCode + '_AI' });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.CreditNote, Type: ProlifeSdk.InvoiceEntytyTypeCode + '_CN' });
        this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.SALInvoice, Type: ProlifeSdk.InvoiceEntityTypeCode + '_SI' });
        this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.Delivery, Type: ProlifeSdk.DdtEntityTypeCode });
        this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Warehouse.CustomerOrder, Type: ProlifeSdk.CustomerOrderEntityTypeCode });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Warehouse.SupplierOrder, Type: ProlifeSdk.SupplierOrderEntytyTypeCode });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.Sal, Type: ProlifeSdk.SalEntytyTypeCode });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Invoices.Quote, Type: ProlifeSdk.EstimateEntytyTypeCode });
        //this.DocumentsTypesList().push({ Label: ProlifeSdk.TextResources.Warehouse.WarehouseLoad, Type: ProlifeSdk.WarehouseLoadEntityTypeCode });
    }

    private createDocTypeCode(): string {
        return !this.document.CreditNote && !this.document.AccompanyingInvoice && !this.document.SALInvoice ? this.document.RelatedDocumentType :
               (this.document.CreditNote ? this.document.RelatedDocumentType + '_CN' :
                   (this.document.AccompanyingInvoice ? this.document.RelatedDocumentType + '_AI' :
                       this.document.RelatedDocumentType + '_SI'));
    }

    private getDocTypeFromCode(): string {
        return !this.DocumentType() ? "" : this.DocumentType().split("_")[0];
    }

    private setInvoiceTypeFlags(code: string): void {
        var codeComponents: string[] = code.split("_");

        if (codeComponents.length < 2) {
            this.CreditNote(false);
            this.AccompanyingInvoice(false);
            this.SALInvoice(false);
            return;
        }

        this.CreditNote(codeComponents[1] == 'CN');
        this.AccompanyingInvoice(codeComponents[1] == 'AI');
        this.SALInvoice(codeComponents[1] == 'SI');
    }

    private getProviderId(): string {
        switch(this.document.DocumentType) {
            case ProlifeSdk.InvoiceEntityTypeCode: {
                return "Vat" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.DdtEntityTypeCode: {
                return "Vat" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.CustomerOrderEntityTypeCode: {
                return "Cor" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.SupplierOrderEntityTypeCode: {
                return "Sor" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.SalEntityTypeCode: {
                return "Sal" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.EstimateEntityTypeCode: {
                return "Est" + this.document.RelatedDocumentProtocolId;
            }
            case ProlifeSdk.WarehouseLoadEntityTypeCode: {
                return "Whl" + this.document.RelatedDocumentProtocolId;
            }
            default:
                return "" + this.document.RelatedDocumentProtocolId;
        }
    }
}
