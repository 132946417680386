import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IWarehouseLoadReasonsForShipmentSettingsManager } from "../../../settings/WarehouseLoadReasonsForShipmentSettingsManager";
import { LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IWarehouseLoadReasonForShipment } from "../../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import { IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolSetting } from "../../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { DefaultValuesSettingsUi } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";
import { DefaultValueType } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValueType";

export class WarehouseLoadDefaultValuesSettingsUi extends DefaultValuesSettingsUi {
    @LazyImportSettingManager(ProlifeSdk.WarehouseLoadReasonsForShipmentSettingsManager)
    private resonsForShipmentManager: IWarehouseLoadReasonsForShipmentSettingsManager;

    public ReasonsForShipment: IWarehouseLoadReasonForShipment[] = [];
    public DefaultReasonForShipment: ko.Observable<IWarehouseLoadReasonForShipment> = ko.observable();

    constructor(protected protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);
        this.templateUrl = "warehouse/templates/defaultvalues";
        this.templateName = "warehouse-load-default-values";

        this.ReasonsForShipment = this.resonsForShipmentManager.getReasonsForShipment(false);
    }

    LoadSettings(settings: IProtocolSetting[], settingsEnabled: boolean) {
        super.LoadSettings(settings, settingsEnabled);

        settings.forEach((s: IProtocolSetting) => {
            if (s.ProtocolId != this.ProtocolId) return;

            if (s.SettingKey == ProlifeSdk.CauseSettingKey) {
                const reasonsMatches = this.ReasonsForShipment.filter((r) => r.Id == s.IntValue);
                this.DefaultReasonForShipment(reasonsMatches.length > 0 ? reasonsMatches[0] : null);
            }
        });
    }

    GetSettings(): IProtocolSetting[] {
        const settings: IProtocolSetting[] = [];

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.CauseSettingKey,
                this.DefaultReasonForShipment() ? this.DefaultReasonForShipment().Id : null,
                DefaultValueType.IntValue
            )
        );

        return settings;
    }
}
