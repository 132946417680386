import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/07/2018
 * Time: 16:27
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyWizardService } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { IChangesNotificationsService } from "../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IQuestionnaireEnvelope } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class IncomingCallDialog implements IDialog {
    public templateName: string = "incoming-call-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs/";
    public title: string = "Chiamata in arrivo";
    public modal: { close: (result?: any) => void; };

    public PhoneNumber : ko.Observable<string> = ko.observable();
    public CalledPhoneNumber : ko.Observable<string> = ko.observable();
    public IVRCode : ko.Observable<string> = ko.observable();
    public Questionnaires : ko.ObservableArray<Questionnaire> = ko.observableArray();
    public SelectedQuestionnaire : ko.Observable<any> = ko.observable();

    private surveyWizardService : ISurveyWizardService;
    private changesNotificationService : IChangesNotificationsService;
    private rejectedCallHandler : number;
    private acceptedCallHandler : number;

    constructor(private serviceLocator : IServiceLocator, private phoneNumber : string, private calledPhoneNumber, private ivrCode : string) {
        this.surveyWizardService = <ISurveyWizardService> serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType);

        this.changesNotificationService = <IChangesNotificationsService> serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);
        this.rejectedCallHandler = this.changesNotificationService.RegisterEventHandler("NotifyRejectedCall", this.OnRejectedCall.bind(this));
        this.acceptedCallHandler = this.changesNotificationService.RegisterEventHandler("NotifyAcceptedCall", this.OnAcceptedCall.bind(this));

        this.PhoneNumber(phoneNumber);
        this.CalledPhoneNumber(calledPhoneNumber);
        this.IVRCode(ivrCode);
    }

    private unregisterEventHandlers() {
        this.changesNotificationService.UnregisterEventHandler("NotifyRejectedCall", this.rejectedCallHandler);
        this.changesNotificationService.UnregisterEventHandler("NotifyAcceptedCall", this.acceptedCallHandler);
    }

    private OnRejectedCall(phoneNumber : string, calledPhoneNumber : string, IVRCode : string) {
        this.unregisterEventHandlers();
        this.modal.close(false);
    }

    private OnAcceptedCall(phoneNumber : string, calledPhoneNumber : string, IVRCode : string) {
        this.unregisterEventHandlers();
        this.modal.close(false);
    }

    close(): void {
        this.unregisterEventHandlers();
        this.surveyWizardService.notifyRejectedCall(this.phoneNumber, this.calledPhoneNumber, this.ivrCode)
            .then(() => {
                this.modal.close(false);
            })
    }

    action(): void {
        this.unregisterEventHandlers();
        this.surveyWizardService.notifyAcceptedCall(this.phoneNumber, this.calledPhoneNumber, this.ivrCode)
            .then((callId : number) => {
                this.modal.close(true);
                this.surveyWizardService.openSurveyWizard(callId, this.phoneNumber, this.calledPhoneNumber, this.ivrCode, this.SelectedQuestionnaire());
            });
    }

    public ShowModal() {
        var dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        var surveyService = <ISurveyService> this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        var infoToast = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        surveyService.getInboundQuestionnairesForCalledPhoneNumber(this.calledPhoneNumber)
            .then((questionnaires : IQuestionnaireEnvelope[]) => {
                this.Questionnaires(questionnaires.map(q => new Questionnaire(q)));
                if(this.Questionnaires().length > 0) {
                    this.SelectedQuestionnaire(this.Questionnaires()[0].Id());
                } else {
                    infoToast.Error(ProlifeSdk.TextResources.SurveyWizard.NoQuestionnaireConfigured, ProlifeSdk.TextResources.SurveyWizard.ConfigurationMissing);
                    return;
                }

                dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
            });
    }
}

class Questionnaire {
    public Id : ko.Observable<number> = ko.observable();
    public Title : ko.Observable<string> = ko.observable();

    constructor(questionnaire : IQuestionnaireEnvelope) {
        this.Id(questionnaire.Id);
        this.Title(questionnaire.Title + ' - Ver. ' + questionnaire.Version);
    }
}