import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { EntitiesDropDownList } from "./desktop/providers/ui/EntitiesDropDownList";
import { MultipleEntitiesProviderForControls } from "./desktop/providers/MultipleEntitiesProviderForControls";
import { CustomEntitiesProviderForControls } from "./desktop/providers/CustomEntitiesProviderForControls";
import { LazyImport } from "../Core/DependencyInjection";
import { IReferenceDetailsViewModelFactory } from "../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IEntityProvider } from "../ProlifeSdk/interfaces/IEntityProvider";
import { IDropDownList } from "../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { IEntitySearchResult } from "../ProlifeSdk/interfaces/IEntitySearchResult";
import { IEntityAttachment } from "../ProlifeSdk/interfaces/desktop/IEntityAttachment";
import {
    IControlsMultipleEntitiesProvider,
    IHintSearchGroupInfo,
    IControlsCustomEntitiesProvider,
} from "../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../Core/Deferred";

interface EntityProviderEntry<K = any, T = any> {
    [entityProviderName: string]: IEntityProvider<K, T>;
}

interface RefDetailsFactoryEntry {
    [entityType: string]: IReferenceDetailsViewModelFactory;
}

export class EntityProviderService implements IEntityProviderService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private entityProviders: EntityProviderEntry = {};
    private referenceDetailsFactories: RefDetailsFactoryEntry = {};
    private entitiesDescriptors: IEntityDescriptor[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IEntityProviderService>(), this);
    }

    getEntitiesDropDown(
        filter?: (e: IEntityDescriptor, index: number, array: IEntityDescriptor[]) => boolean
    ): IDropDownList {
        return new EntitiesDropDownList(this.serviceLocator, filter);
    }

    RegisterEntity(descriptor: IEntityDescriptor) {
        const matches = this.entitiesDescriptors.filter((d: IEntityDescriptor) => {
            return d.EntityCode == descriptor.EntityCode;
        });

        if (matches.length == 0) this.entitiesDescriptors.push(descriptor);
    }

    getRegisteredEntityProviders(): IEntityProvider[] {
        const providers = [];

        for (const providerType in this.entityProviders) {
            if (Object.prototype.hasOwnProperty.call(this.entityProviders, providerType)) {
                const provider = this.entityProviders[providerType];
                providers.push(provider);
            }
        }

        return providers;
    }

    getEntitiesDescriptors(): IEntityDescriptor[] {
        return this.entitiesDescriptors;
    }

    GetEntityDescriptor(entityCode: string): IEntityDescriptor {
        const matches = this.entitiesDescriptors.filter((d: IEntityDescriptor) => {
            return d.EntityCode == entityCode;
        });

        if (matches.length > 0) return matches[0];

        return null;
    }

    registerReferenceDetailsViewModelFactory(entityType: string, factory: IReferenceDetailsViewModelFactory) {
        this.referenceDetailsFactories[entityType] = factory;
    }

    getReferenceDetailsViewModelFactory(entityType: string): IReferenceDetailsViewModelFactory {
        return this.referenceDetailsFactories[entityType];
    }

    getEntityProvider(tagTypeId: string): IEntityProvider {
        return this.entityProviders[tagTypeId];
    }

    registerEntityProvider(entityProvider: IEntityProvider, entityProviderName: string): void {
        this.entityProviders[entityProviderName] = entityProvider;
    }

    getServiceType(): string {
        return ProlifeSdk.EntityProviderServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    search(filter: string, providersCodes: string[] = []): Promise<IEntitySearchResult[]> {
        const deferred = new Deferred<IEntitySearchResult[]>();
        const providersDefs: Promise<any[]>[] = [];
        const searchResult: IEntitySearchResult[] = [];

        for (const key in this.entityProviders) {
            if (providersCodes.length == 0 || providersCodes.indexOf(key) > -1) {
                const provider: IEntityProvider = this.entityProviders[key];
                providersDefs.push(
                    provider.hintSearch(filter).then(
                        function (provider, entities) {
                            searchResult.push({
                                provider: provider,
                                entities: entities,
                            });
                        }.bind(this, provider)
                    )
                );
            }
        }

        Promise.all(providersDefs)
            .then(() => {
                deferred.resolve(searchResult);
            })
            .catch(() => {
                deferred.reject([]);
            });
        return deferred.promise();
    }

    GetEntityAttachmentsIds(entityType: string, entityKey: number): Promise<string[]> {
        return this.ajaxService.Get(
            "Desktop-api",
            "Attachments/GetAttachmentsIds?entityType=" + entityType + "&entityKey=" + entityKey,
            { background: true }
        );
    }

    GetEntityAttachments(entityType: string, entityKey: number): Promise<IEntityAttachment[]> {
        return this.ajaxService.Post("Desktop-api", "Attachments/GetAttachments", {
            methodData: {
                EntityKey: entityKey,
                EntityType: entityType,
            },
        });
    }

    GetMultipleEntitiesProviderForControls(
        entitiesCodes: string[],
        supportFreeText: boolean,
        showType: boolean,
        defaultTextLabel?: string
    ): IControlsMultipleEntitiesProvider {
        return new MultipleEntitiesProviderForControls(
            this.serviceLocator,
            entitiesCodes,
            supportFreeText,
            showType,
            defaultTextLabel
        );
    }

    GetCustomEntitiesProviderForControls(
        providersWithDescriptions: IHintSearchGroupInfo[],
        supportFreeText: boolean,
        showType: boolean,
        defaultTextLabel?: string
    ): IControlsCustomEntitiesProvider {
        return new CustomEntitiesProviderForControls(
            providersWithDescriptions,
            supportFreeText,
            showType,
            defaultTextLabel
        );
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new EntityProviderService(serviceLocator);
}
