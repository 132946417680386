import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 20/10/2016
 * Time: 11:13
 * To change this template use File | Settings | File Templates.
 */

import { TreeGridItem } from "../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";

export class TreeGridColumnBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any {
        var column = ko.utils.unwrapObservable(valueAccessor());
        var item = ko.utils.unwrapObservable(allBindingsAccessor()["treeGridItem"]);

        var interceptor = ko.computed(() => {
            var value = column.Accessor(item.Value());
            $(element).html(column.GetHtml(value));

            ko.applyBindingsToDescendants(bindingContext, element);
        });

        return { controlsDescendantBindings: true };
    }
}

export class TreeGridColumnAction {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any {
        var column = ko.utils.unwrapObservable(valueAccessor());
        
        if (column.Action) {
            let item;    
            for (let parent of bindingContext.$parents) {
                if (parent instanceof TreeGridItem) {
                    item = parent;
                    break;
                }
            }
            
            var value = column.Accessor(item.Value());
            $(element).on("click", () => column.Action(value, element));
        }
    }
}

ko.bindingHandlers["treeGridColumn"] = new TreeGridColumnBinding();
ko.bindingHandlers["treeGridColumnAction"] = new TreeGridColumnAction();