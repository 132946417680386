import * as ko from "knockout";
import { Delay } from "../Decorators/Delay";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { Deferred } from "../Core/Deferred";

type Model<M> = IDataSourceModel<number, M, string | number, any>;
interface IPendingGetById<M> {
    currentModel : Model<M>;
    requestedIds : number[];
    deferred: Deferred<Model<M>[]>;
}

interface ICache<M> {
    [key: number]: Model<M>
}

function equalsIgnoreOrder<T>(a : T[], b : T[]) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

export class CachedDataSource<T extends IDataSource<number, M>, M = any> implements IDataSource<number, M> {
    private nullCache : ICache<M> = {};
    private modelIndex : Model<M>[] = [];
    private modelCache : WeakMap<Model<M>, ICache<M>> = new WeakMap();

    private pendingGetByIds: IPendingGetById<M>[] = [];

    constructor(private dataSource : T) {

    }

    private getPendingRequest(currentModel : Model<M>, ids: number[]) : IPendingGetById<M> {
        for(let pending of this.pendingGetByIds) {
            if(this.dataSource.areEqual(currentModel, pending.currentModel) && equalsIgnoreOrder(pending.requestedIds, ids))
                return pending;
        }
        return null;
    }

    private createOrGetModelCache(currentModel : Model<M>) : ICache<M> {
        if(currentModel === null)
            return this.nullCache;

        for(let cache of this.modelIndex) {
            if(this.dataSource.areEqual(currentModel, cache))
                return this.modelCache.get(cache);
        }

        let cache = {};
        this.modelIndex.push(currentModel);
        this.modelCache.set(currentModel, cache);
        return cache;
    }

    private getFromCache(currentModel : Model<M>, ids: number[]) : { found: Model<M>[], notFound: number[] } {
        let cache = this.createOrGetModelCache(currentModel);

        let foundInCache : Model<M>[] = [];
        let notFoundInCache : number[] = [];
        
        for(let id of ids) {
            let cacheValue = cache[id];
            if(cacheValue != undefined) {
                foundInCache.push(cacheValue);
            } else {
                notFoundInCache.push(id);
            }
        }

        return {
            found: foundInCache,
            notFound: notFoundInCache
        }
    }

    private addToCache(currentModel : Model<M>, models : Model<M>[]) {
        let cache = this.createOrGetModelCache(currentModel);

        for(let model of models) {
            cache[model.id] = model;
        }
    }

    getTitle(currentModel: Model<M>): string {
        return this.dataSource.getTitle(currentModel);
    }

    isGroupedData(currentModel: Model<M>, textFilter: string): boolean {
        return this.dataSource.isGroupedData(currentModel, textFilter);
    }

    areEqual(a: Model<M>, b: Model<M>): boolean {
        return this.dataSource.areEqual(a, b);
    }

    getData(currentModel: Model<M>, textFilter: string, skip: number, count: number): Promise<Model<M>[]> {
        return this.dataSource.getData(currentModel, textFilter, skip, count);
    }

    getById(currentModel: Model<M>, ids: number[]): Promise<Model<M>[]> {
        let cacheResult = this.getFromCache(currentModel, ids);
        if(cacheResult.notFound.length === 0) {
            return Promise.resolve(cacheResult.found);
        }

        let pending = this.getPendingRequest(currentModel, ids);
        if(!pending) {
            pending = {
                currentModel: currentModel,
                requestedIds: ids.slice(),
                deferred: new Deferred()
            };
            this.pendingGetByIds.push(pending);
        }

        this.doGetById();

        return pending.deferred.promise();
    }

    @Delay(100)
    private doGetById() {
        let pendingRequests = this.pendingGetByIds;
        this.pendingGetByIds = [];

        try
        {
            let nullIds : number[] = [];
            let index : Model<M>[] = [];
            let groups : WeakMap<Model<M>, number[]> = new WeakMap();

            for(let pendingRequest of pendingRequests) {
                let idsForRequest : number[] = null;

                for(let i of index) {
                    if(this.dataSource.areEqual(i, pendingRequest.currentModel)) {
                        idsForRequest = groups.get(i);
                        break;
                    }
                }

                if(!idsForRequest) {
                    idsForRequest = [];
                    if(pendingRequest.currentModel === null)
                        idsForRequest = nullIds;
                    else {
                        index.push(pendingRequest.currentModel);
                        groups.set(pendingRequest.currentModel, idsForRequest);
                    }
                }

                let uniqueIds = new Set(idsForRequest);
                for(let id of pendingRequest.requestedIds) {
                    if(!uniqueIds.has(id)) {
                        idsForRequest.push(id);
                        uniqueIds.add(id);
                    }
                }
            }

            if(nullIds.length > 0) {
                const { notFound } = this.getFromCache(null, nullIds);

                if(notFound.length === 0) {
                    for(let pendingRequest of pendingRequests.slice()) {
                        if(pendingRequest.currentModel === null) {
                            const { found } = this.getFromCache(null, pendingRequest.requestedIds);
                            pendingRequest.deferred.resolve(found);
                            pendingRequests.remove(pendingRequest);
                        }
                    }
                }

                this.dataSource.getById(null, notFound)
                    .then(fromDataSource => {
                        this.addToCache(null, fromDataSource);

                        for(let pendingRequest of pendingRequests.slice()) {
                            if(pendingRequest.currentModel === null) {
                                const { found } = this.getFromCache(null, pendingRequest.requestedIds);
                                pendingRequest.deferred.resolve(found);
                                pendingRequests.remove(pendingRequest);
                            }
                        }
                    });
            }

            for(let currentModel of index) {
                let ids = groups.get(currentModel);
                const { notFound } = this.getFromCache(currentModel, ids);
                
                if(notFound.length === 0) {
                    for(let pendingRequest of pendingRequests.slice()) {
                        if(this.dataSource.areEqual(pendingRequest.currentModel, currentModel)) {
                            const { found } = this.getFromCache(currentModel, pendingRequest.requestedIds);
                            pendingRequest.deferred.resolve(found);
                            pendingRequests.remove(pendingRequest);
                        }
                    }
                    continue;
                }

                this.dataSource.getById(currentModel, notFound)
                    .then(fromDataSource => {
                        this.addToCache(currentModel, fromDataSource);

                        for(let pendingRequest of pendingRequests.slice()) {
                            if(this.dataSource.areEqual(pendingRequest.currentModel, currentModel)) {
                                const { found } = this.getFromCache(currentModel, pendingRequest.requestedIds);
                                pendingRequest.deferred.resolve(found);
                                pendingRequests.remove(pendingRequest);
                            }
                        }
                    });
            }
        }
        catch
        {
            for(let pendingRequest of pendingRequests.slice()) {
                pendingRequest.deferred.reject([]);
            }
        }
    }

    setView(view: IDataSourceView<string | number, any>): void {
        this.dataSource.setView(view);
    }

    getSupportedDropMimeTypes(): string[] {
        return this.dataSource.getSupportedDropMimeTypes();
    }

    onItemBeginMove?(model: Model<M>, dataTransfer: DataTransfer) {
        this.dataSource.onItemBeginMove && this.dataSource.onItemBeginMove(model, dataTransfer);
    }

    onItemMoved?(dataTransfer: DataTransfer, model: Model<M>, before: boolean): Promise<void> {
        if(!this.dataSource.onItemMoved)
            return Promise.resolve();
        return this.dataSource.onItemMoved && this.dataSource.onItemMoved(dataTransfer, model, before);
    }

    setDataFilter?(callback: (model: Model<M>) => boolean): void {
        this.dataSource.setDataFilter && this.dataSource.setDataFilter(callback);
    }

}