import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/04/13
 * Time: 17.57
 * To change this template use File | Settings | File Templates.
 */

/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class CustomerValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();

        var customersService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var instance = {};

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            display : 'FullName',
            source : CustomerValue.findCustomers.bind(null, instance, customersService),
            limit: 50,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div>" + (item.FullName ? "<b>" + item.FullName + "</b>" : "");
                    element += (item.PIVA && item.PIVA.trim().length > 0) ? "<small>&nbsp;(" + item.PIVA + ")</small>" : "";
                    element += (item.Address && item.Address.trim().length > 0) ? "<br/><small>" + item.Address + "</small>" : "";
                    return element + "</div>";
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            valueAccessor()(item.id);
            otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(item.FullName);
            return item.FullName;
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var otherBindings = allBindingsAccessor();

        if(!newValue) {
            !otherBindings.value && $(element).val("");
            return;
        }
    }

    private static findCustomers(_this : any, customersService : ICustomersService, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) {
        if(_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            customersService.getAllCustomers(query, true)
                .then((customers : ICustomer[]) => {
                    return asyncProcess(CustomerValue.transformCustomers(customers));
                });
        }, 500);
    }

    private static transformCustomers(customers : ICustomer[]) : any[] {
        return customers.map(CustomerValue.transformCustomer);
    }

    private static transformCustomer(customer : ICustomer) : any {
        return {
            id: customer.IdCliente,
            FullName: customer.FormattedContactName || "",
            Address : customer.Indirizzo,
            PIVA: customer.PIVA || "",
            Provincia: customer.Provincia || "",
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["customersValue"] = new CustomerValue();