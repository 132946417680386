import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { RefConverterBase, IRefDocumentRowWithUOM, IDocumentInfoForInlineRefProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDiscountsCatalogsService, DiscountCatalogRowMode } from "../../../DiscountsCatalogsService";
import { IWarehousesService } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";

export class RowToArticleRefConverter extends RefConverterBase
{
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService : IDiscountsCatalogsService;
    @LazyImport(nameof<IWarehousesService>())
    private warehouseService : IWarehousesService;

    private CatalogId : ko.Observable<number> = ko.observable();

    constructor(activationCode : string, docInfo : IDocumentInfoForInlineRefProvider)
    {
        super(activationCode, ProlifeSdk.WarehouseArticleEntityTypeCode, docInfo);
    }

    public async getReference(rowId : number, entity : IEntityRefKey, amount : number) : Promise<IRefDocumentRowWithUOM>
    {
        const ref = this.GetEmptyReference(rowId, entity, amount);

        const forSupplier : boolean = [ProlifeSdk.SupplierOrderEntityTypeCode, ProlifeSdk.WarehouseLoadEntityTypeCode, ProlifeSdk.RequestForQuotationEntityTypeCode]
            .indexOf(this.DocumentInfo.DocTypeCode) > -1;

        const sourceWarehouseId = this.DocumentInfo.SourceWarehouseId ? this.DocumentInfo.SourceWarehouseId() : (await this.warehouseService.getDefaultWarehouse()).Id;

        const discount = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
            customerId: this.DocumentInfo.CustomerId(),
            isCustomer: !forSupplier,
            referenceDate: this.DocumentInfo.DocumentDate() || new Date(),
            warehouseId: sourceWarehouseId,
            articleId: entity.EntityKeyId,
            overrideGroupId: this.DocumentInfo.OverrideDiscountCatalog()
        });

        const hasExplicitPrice = discount.ExplicitPrice && discount.ExplicitPrice > 0;

        discount.UnitPrice = hasExplicitPrice ? discount.ExplicitPrice : (discount.UnitPrice ?? 0);
        const finalPrice = hasExplicitPrice ? discount.ExplicitPrice : discount.UnitPrice + ((discount.TotalDiscount / 100) * discount.UnitPrice);

        if (DiscountCatalogRowMode.getDiscountSign(discount.Mode) > 0) //E' un ricarico quindi non mostro il ricarico nel documento
        {
            ref.Discounts = undefined,
            ref.UnitPrice = finalPrice,
            ref.CatalogId = discount.CatalogId,
            ref.NetUnitPrice = finalPrice,
            ref.NetPrice = ref.Amount * finalPrice,
            ref.WarehouseId = sourceWarehouseId,
            ref.UnitOfMeasure = discount.UOM
        }
        else
        {
            const discountString = 
                (
                    (discount.Discount0 > 0 ? discount.Discount0 + "% " : "") +
                    (discount.Discount1 > 0 ? discount.Discount1 + "% " : "") +
                    (discount.Discount2 > 0 ? discount.Discount2 + "% " : "") +
                    (discount.Discount3 > 0 ? discount.Discount3 + "% " : "") +
                    (discount.Discount4 > 0 ? discount.Discount4 + "% " : "")
                ).trim();

            ref.Discounts = hasExplicitPrice ? undefined : discountString,
            ref.UnitPrice = discount.UnitPrice,
            ref.CatalogId = discount.CatalogId,
            ref.NetUnitPrice = finalPrice,
            ref.NetPrice = ref.Amount * finalPrice,
            ref.WarehouseId = sourceWarehouseId,
            ref.UnitOfMeasure = discount.UOM
        }

        return ref;
    }
}
