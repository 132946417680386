import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListActivitiesTagsProvider } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { LazyImport } from "../../../../../Core/DependencyInjection";

export class TodoListTemplatesTaskTagsProvider implements ITodoListActivitiesTagsProvider
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    constructor() {}

    public SearchLabels(filter : string) : Promise<string[]>
    {
        return this.todoListService.SearchTemplatesLabels(filter);
    }

}