import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IReminderService } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { Deferred } from "../../../../Core/Deferred";

export class BlogEventRefUiForRemind {
    private remindService : IReminderService;
    private jobOrderService : IJobOrderService;
    private blogService : IBlogService;

    public entityType: string = ProlifeSdk.BlogEventEntityTypeCode;
    public templateUrl : string = "reminder/templates/references";
    public templateName : string = "blog-event";

    public eventType : IDropDownList;
    public jobOrder : IDropDownList;
    public event : IDropDownList;

    constructor(private serviceLocator) {
        this.remindService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.blogService = <IBlogService>serviceLocator.findService(ProlifeSdk.BlogServiceType);
        this.jobOrder = this.jobOrderService.getJobOrdersDropDown();
        this.eventType = this.blogService.getBlogEventTypesDropDown();
        this.event = this.blogService.getBlogEventsDropDown(() => { return this.jobOrder.selectedId();}, () => { return this.eventType.selectedId();});
        this.remindService.registerObjReferenceUi(this);

        this.jobOrder.selectedId.subscribe(() => { this.event.selectedId(null); });
        this.eventType.selectedId.subscribe(() => { this.event.selectedId(null); });
    }

    public initialize(key: IEntityRefKey) : Promise<void> {
        var def = new Deferred<void>();

        this.jobOrder.selectedId(null);

        if(!key.EntityKeyId)
            return def.resolve().promise();

        this.blogService.getEventForList(key.EntityKeyId).then((e) => {
            this.jobOrder.selectedId(e.JobOrderId);
            this.eventType.selectedId(e.EventType);
            this.event.selectedId(e.Id);
            def.resolve();
        });

        return def.promise();
    }

    public getSelectedEntityKey(): IEntityRefKey{
        return {
            EntityKeyType : this.entityType,
            EntityKeyId : this.event.selectedId()
        }
    }

    public getSelectedEntityPathDescription(): Promise<string> {
        var def = new Deferred<string>();

        if(!this.event.selectedId())
            return def.resolve(null).promise();

        this.jobOrder.getSelected(null, (jobOrder) => {
            this.event.getSelected(null, (event) => {
                def.resolve((jobOrder.text || "N/A") + " > " + (event.text || "N/A"));
            });
        });

        return def.promise();
    }
}