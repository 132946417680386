import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/02/2018
 * Time: 18:46
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { NavigationProvider } from "../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IFullCalendarEventsReceiver } from "../../../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IEventsService, IAgendaEventDescriptor, IAgendaEvent, IAgendaEventForEditingEx } from "../../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IAgendaService, IAgenda, IAgendasDetailsForMenu, IEventAgendaAndStartEndInfo } from "../../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { INavigationMenu } from "../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { IAgendaEventDescriptorViewModel } from "../../../../interfaces/IAgendaEvent";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICategoriesSettingsManager } from "../../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

export class AgendasNavigationProvider extends NavigationProvider implements IFullCalendarEventsReceiver {
    public Title: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public Deleted: ko.Observable<number> = ko.observable();
    public FolderId: ko.Observable<number> = ko.observable();
    public WaitingListEventsNumber: ko.Observable<number> = ko.observable();
    public PlannedEvents: ko.Observable<number> = ko.observable();
    public PlannedEventsInAlertTimeWindow: ko.Observable<number> = ko.observable();
    public HasTimetableWithoutEndDate: ko.Observable<boolean> = ko.observable();
    public HasFreeTimeslots: ko.Observable<boolean> = ko.observable();
    public HasFreeTimeslotsOnlyOutOfAlertTimeWindow: ko.Observable<boolean> = ko.observable();
    public FreeTimeslotsBadge: ko.Observable<string> = ko.observable();
    public TimeslotModeEnabled: ko.Observable<boolean> = ko.observable();
    public DaysNumberForBadgeAlert: ko.Observable<number> = ko.observable();
    public DaysNumberForPlannedEventsAlert: ko.Observable<number> = ko.observable();
    public HasSyncErrors : ko.Observable<boolean> = ko.observable();

    public Droppable: ko.Observable<boolean> = ko.observable(true);

    public IsEventOverMe: (x: number, y: number) => boolean;

    protected eventsService: IEventsService;
    protected agendasService: IAgendaService;
    protected infoToastService: IInfoToastService;
    protected dialogsService: IDialogsService;
    protected authorizationsService: IAuthorizationService;

    constructor(protected serviceLocator: IServiceLocator, protected agenda: IAgenda) {
        super();

        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.authorizationsService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);

        this.IsLeaf = true;

        this.templateName = "agendas-navigation-provider";
        this.templateUrl = "agenda/templates/navigation";
        this.Id = this.agenda.Id;
        this.Name = this.agenda.Name;
        this.Icon(this.agenda.Icon);
        this.Background(this.agenda.Background);
        this.Foreground(this.agenda.Foreground);
        this.Deleted(this.agenda.Deleted);
        this.FolderId(this.agenda.FolderId);

        this.TimeslotModeEnabled(this.agenda.TimeslotsModeEnabled);
        this.DaysNumberForBadgeAlert(this.agenda.DaysNumberForBadgeAlert);
        this.DaysNumberForPlannedEventsAlert(this.agenda.DaysNumberForPlannedEventsAlert);
        this.HasSyncErrors(this.agenda.HasSyncErrors);

        this.Title(agenda.Name);

        this.Title.subscribe((title: string) => {
            this.Name = title;
        });

        if (this.TimeslotModeEnabled())
            this.LoadDetails();
    }

    setCurrentNavigator(navigator : INavigationMenu)
    {
        super.setCurrentNavigator(navigator);
    }

    UpdateFromModel(agenda: IAgenda): void {
        this.agenda = agenda;

        this.Id = this.agenda.Id;
        this.Title(this.agenda.Name);
        this.Icon(this.agenda.Icon);
        this.Background(this.agenda.Background);
        this.Foreground(this.agenda.Foreground);
        this.Deleted(this.agenda.Deleted);
        this.FolderId(this.agenda.FolderId);
    }

    OnElementDropped(event, ui): void{
        if (!this.authorizationsService.isAuthorized("Agenda_PlanEvents")) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MissingRightsToPlanEvents);
            return;
        }

        var droppedEvent: IAgendaEventDescriptor = ui.draggable.data("event");
        var fromWL: boolean = ui.draggable.data("fromwl");
        var fromHS: boolean = ui.draggable.data("fromhs");

        droppedEvent.id = this.ExtractEventId(droppedEvent.id);

        if (!!droppedEvent && fromWL) {
            this.OnEventDraggedFromWaitingList(droppedEvent);
            return;
        }

        if (!!droppedEvent && fromHS)
            this.OnEventDraggedFromSearchResult(droppedEvent);
    }

    LoadDetails(): Promise<IAgendasDetailsForMenu> {
        return this.agendasService.GetAgendasDetailsForMenu(this.agenda.Id)
            .then((details: IAgendasDetailsForMenu) => {
                if (!details)
                    return null;

                this.WaitingListEventsNumber(details.WaitingListEventsNumber);
                this.PlannedEvents(details.PlannedEventsNumber);
                this.PlannedEventsInAlertTimeWindow(details.PlannedEventsNumberOnAlertTimeWindow);
                this.HasTimetableWithoutEndDate(details.HasTimetableWithoutEndDate);
                this.HasFreeTimeslots(details.HasFreeTimeslots);
                this.HasFreeTimeslotsOnlyOutOfAlertTimeWindow(details.HasFreeTimeslotsOnlyOutOfAlertTimeWindow);
                this.FreeTimeslotsBadge(TextResources.Agenda.FreeTimeslotsBadge);
                return details;
            });
    }

    private OnEventDraggedFromWaitingList(event: IAgendaEvent): void {
        if (this.agenda.TimeslotsModeEnabled) {
            this.eventsService.OnEventDraggedFromWaitingList(event.id, this.Id)
                .then(() => {
                    this.navigator().NotifyEventDroppedOnAgenda(event, this.Id);
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.MoveEventToAgendaError);
                });

            return;
        }

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.ConfirmEventMovingFromWaitingListToAgendaWithoutWaitingList,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm) {
                    var originalAgenda: number = event.AgendaId;
                    var originalState: number = event.CategoryId;
                    event.AgendaId = this.Id;
                    event.CategoryId = null;

                    this.eventsService.ui.GetEventEditingDialog(event.id, event.AgendaId)
                        .then((modifiedEvent: IAgendaEventForEditingEx) => {
                            if (!modifiedEvent) {
                                event.AgendaId = originalAgenda;
                                event.CategoryId = originalState;
                                return;
                            }

                            this.navigator().NotifyEventDroppedOnAgenda(modifiedEvent, this.Id);
                        });
                }
            }
        );
    }

    private OnEventDraggedFromSearchResult(eventDescriptor: IAgendaEventDescriptor): void {
        if (!this.navigator().searchedEventsEditor)
            return;

        if (this.navigator().searchedEventsEditor.EventsSearchViewModel().SelectedResults().length > 0) {
            var selectedEvents: number[] = this.navigator().searchedEventsEditor.EventsSearchViewModel().SelectedResults().map((r: IAgendaEventDescriptorViewModel) => { return r.Id; });
            this.eventsService.ChangeEventsAgenda(selectedEvents, this.Id)
                .then((rejectedEvents: IEventAgendaAndStartEndInfo[]) => {
                    this.navigator().NotifyEventDroppedOnAgenda(event, this.Id);

                    if (rejectedEvents.length > 0) {
                        var message: string = "<br/><br/>";
                        rejectedEvents.forEach((e: IEventAgendaAndStartEndInfo) => message += e.EventTitle + "<br/><br/>");
                        this.infoToastService.Warning(String.format(ProlifeSdk.TextResources.Agenda.InvalidEventsMovementMessage, message));
                    }
                });

            return;
        }

        if (this.GetEventLogicalState(eventDescriptor) == ProlifeSdk.WaitingListEventState) {
            this.OnEventDraggedFromWaitingList(eventDescriptor);
            return;
        }

        eventDescriptor.AgendaId = this.Id;
        this.navigator().searchedEventsEditor.EditSearchedEvent(eventDescriptor);
    }

    private GetEventLogicalState(event: IAgendaEvent): number {
        if (!event.CategoryId)
            return ProlifeSdk.PlannedEventState;

        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var categoriesManager: ICategoriesSettingsManager = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);
        return categoriesManager.getCategoryById(event.CategoryId).LogicalState;
    }

    private ExtractEventId(eventId: any): number {
        if (typeof eventId == "number")
            return eventId;

        return eventId.split("-").length > 1 ? parseInt(eventId.split("-")[1]) : parseInt(eventId);
    }
}