import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import {
    IResourcesGroup,
    IResourcesGroupsSettingsManager,
} from "../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IHumanResource } from "../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../Users/Users/Settings/HumanResourcesSettingsManager";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export interface IResourcesAndGroupsDataSourceModel
    extends IDataSourceModel<string, string | IHumanResource | IResourcesGroup> {
    isResourcesGroup?: boolean;
    isResource?: boolean;
}

export class ResourcesAndGroupsDataSource extends BaseDataSource<IResourcesAndGroupsDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private resourcesGroupsSettingsManager: IResourcesGroupsSettingsManager;

    private includeDeletedResources = false;
    private includeDisabledResources = false;
    private includeMaterialResources = false;
    private includeGroups = false;

    public static HumanResourcesType = "HumanResource";
    public static ResourcesGroupsType = "ResourcesGroups";

    setIncludeDeletedResources(value: boolean): void {
        this.includeDeletedResources = value;
    }

    setIncludeDisabledResources(value: boolean): void {
        this.includeDisabledResources = value;
    }

    setIncludeMaterialResources(value: boolean): void {
        this.includeMaterialResources = value;
    }

    setIncludeGroups(value: boolean): void {
        this.includeGroups = value;
    }

    isGroupedData(currentModel: IResourcesAndGroupsDataSourceModel): boolean {
        return this.includeGroups && !currentModel;
    }

    getTitle(currentModel: IResourcesAndGroupsDataSourceModel): string {
        if (currentModel.isResource) return TextResources.Users.Resources;

        if (currentModel.isResourcesGroup) return TextResources.Users.ResourcesGroups;

        return "";
    }

    async getData(
        currentModel: IResourcesAndGroupsDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IResourcesAndGroupsDataSourceModel[]> {
        if (!this.includeGroups) return this.getHumanResoucesModels(textFilter, skip, count);

        if (!currentModel) return this.getGroups();

        if (currentModel.model === ResourcesAndGroupsDataSource.HumanResourcesType)
            return this.getHumanResoucesModels(textFilter, skip, count);

        return this.getResoucesGroupsModels(textFilter, skip, count);
    }

    private async getResoucesGroupsModels(
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IResourcesAndGroupsDataSourceModel[]> {
        const groups = this.resourcesGroupsSettingsManager
            .GetGroups()
            .filter((g) => {
                return (
                    (this.includeDeletedResources || !g.Deleted) && TextFiltersUtilities.contains(g.Name, textFilter)
                );
            })
            .slice(skip, skip + count);

        return groups.map(this.createResourcesGroupModel, this);
    }

    private async getHumanResoucesModels(
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IResourcesAndGroupsDataSourceModel[]> {
        const resources = this.humanResourcesSettingsManager
            .getHumanResources()
            .filter((r) => {
                return (
                    (this.includeDeletedResources || !r.Resource.Deleted) &&
                    (this.includeDisabledResources || !r.Resource.Disabled) &&
                    (this.includeMaterialResources || r.Resource.ResourceType === 0) &&
                    TextFiltersUtilities.contains(this.humanResourcesSettingsManager.getFullName(r), textFilter)
                );
            })
            .slice(skip, skip + count);

        return resources.map(this.createResourceModel, this);
    }

    private async getGroups(): Promise<IResourcesAndGroupsDataSourceModel[]> {
        const groups = [];
        groups.push(this.createGroup(ResourcesAndGroupsDataSource.HumanResourcesType, TextResources.Users.Resources));
        groups.push(
            this.createGroup(ResourcesAndGroupsDataSource.ResourcesGroupsType, TextResources.Users.ResourcesGroups)
        );
        return groups;
    }

    private createResourceModel(resource: IHumanResource): IResourcesAndGroupsDataSourceModel {
        return {
            id: resource.Resource.Id + "|" + ResourcesAndGroupsDataSource.HumanResourcesType,
            title: this.humanResourcesSettingsManager.getFullName(resource),
            isGroup: false,
            isLeaf: true,
            model: resource,
            isResource: true,
        };
    }

    private createResourcesGroupModel(group: IResourcesGroup): IResourcesAndGroupsDataSourceModel {
        return {
            id: group.Id + "|" + ResourcesAndGroupsDataSource.ResourcesGroupsType,
            title: group.Name,
            isGroup: false,
            isLeaf: true,
            model: group,
            isResourcesGroup: true,
        };
    }

    private createGroup(groupType: string, title: string): IResourcesAndGroupsDataSourceModel {
        return {
            id: null,
            title: title,
            isGroup: true,
            isLeaf: false,
            model: groupType,
        };
    }

    async getById(
        currentModel: IResourcesAndGroupsDataSourceModel,
        ids: string[]
    ): Promise<IResourcesAndGroupsDataSourceModel[]> {
        const models = [];
        for (const id of ids) {
            const [resourceId, resourceType] = id.toString().split("|");
            const numericId = parseInt(resourceId);
            if (resourceType === ResourcesAndGroupsDataSource.HumanResourcesType) {
                const resource = this.humanResourcesSettingsManager.getHumanResourceById(numericId);
                models.push(this.createResourceModel(resource));
            } else if (resourceType === ResourcesAndGroupsDataSource.ResourcesGroupsType) {
                const group = this.resourcesGroupsSettingsManager.GetGroupById(numericId);
                models.push(this.createResourcesGroupModel(group));
            }
        }

        return models;
    }
}
