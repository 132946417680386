import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";

type MoneyInputProps = {
    simple?: boolean;
    value: ko.Observable<number> | ko.Computed<number>;
    label?: string;
    placeholder?: string;
    selectOnFocus?: boolean;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean>;
    updateAfterKeyDown?: boolean;
    className?: string;
    onChange?: (newValue: number) => void;
}

export function MoneyInput(props: MoneyInputProps) {
    const C = require("./MoneyInput")._MoneyInput as typeof _MoneyInput;
    return <C {...props} />;
}

export class _MoneyInput {
    static defaultProps = {
        simple: false,
        label: " ",
        selectOnFocus: false,
        readonly: false,
        updateAfterKeyDown: false,
        className: ""
    }

    private valueChangesObserver: ko.Subscription;

    constructor(private props : MoneyInputProps) {}

    componentDidMount() {
        this.valueChangesObserver = this.props.value.subscribe((v) => {
            this.props.onChange && this.props.onChange(v);
        });
    }

    componentWillUnmount() {
        this.valueChangesObserver.dispose();
        this.valueChangesObserver = null;
    }
    
    render() {
        let bindings = "moneyValue: mi.props.value, disable: mi.props.readonly";

        if (this.props.selectOnFocus)
            bindings += ", selectOnFocus: {}";

        if (this.props.updateAfterKeyDown)
            bindings += ", onChange: mi.props.onChange"

        if (this.props.simple)
            return ComponentUtils.bindTo(<input className={"form-control text-right " + this.props.className} type="text" data-bind={bindings}></input>, this, 'mi');

        return ComponentUtils.bindTo(
                    <div className={"form-group " + this.props.className}>
                        <label className="control-label">{this.props.label}</label>
                        <input className="form-control text-right" type="text" data-bind={bindings}></input>
                    </div>
        , this, 'mi');
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(MoneyInput);
}