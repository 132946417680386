import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { PaymentModesEditingViewModel } from "./ui/PaymentModesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IPaymentModes, IPaymentMode, IPaymentModesObserver, IPaymentAndExpireForExport } from "../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class PaymentModeSettingsManager implements IPaymentModes {
    private ajaxService : IAjaxService;

    private paymentModes : IPaymentMode[] = [];
    private observers : IPaymentModesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<IPaymentMode[]> {
        return this.ajaxService.Get<IPaymentMode[]>("Invoices-api", "PaymentMode", {})
            .then((paymentModes: IPaymentMode[]) => { 
                this.paymentModes = paymentModes; 
                return paymentModes;
            });
    }

    getName(): string {
        return ProlifeSdk.PaymentMode;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.PaymentTypes;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: 'payments',
            templateUrl: 'invoices/templates/settings',
            viewModel: new PaymentModesEditingViewModel(this.serviceLocator, this)
        };
    }

    GetMappingForAccountingSwExport() : Promise<IPaymentAndExpireForExport[]>
    {
        return this.ajaxService.Get("Invoices-api", "PaymentAndExpireMappings", { });
    }

    UpdateMappingForAccountingSwExport(mappings : IPaymentAndExpireForExport[]) : Promise<void>
    {
        return this.ajaxService.Post("Invoices-api", "PaymentAndExpireMappings", {
            methodData : mappings
        });
    }

    getPaymentModes(getDeleted : boolean = false) : IPaymentMode[] {
        return this.paymentModes.filter((m : IPaymentMode) => { return getDeleted || m.Eliminato == 0; });
    }

    getPaymentModeIdByName(paymentModeName : string) : number {
        var payments = this.paymentModes.filter((payment : IPaymentMode) => payment.Descrizione == paymentModeName);
        if(payments.length == 0) return null;
        return payments[0].IdTipoPagamento;
    }

    getPaymentMode(id: number): IPaymentMode {
        return this.paymentModes.firstOrDefault(p => p.IdTipoPagamento === id);
    }

    createOrUpdate(paymentMode : IPaymentMode) : Promise<IPaymentMode> {
        var def = new Deferred<IPaymentMode>();

        if(paymentMode.IdTipoPagamento > 0) {
            this.ajaxService.Put("Invoices-api", "PaymentMode", { methodData: paymentMode})
                .then((paymentMode: IPaymentMode) => {
                    this.onPaymentModeChanged(paymentMode);
                    def.resolve(paymentMode);
                })
                .catch(() => {
                    def.reject();
                });
        } else {
            this.ajaxService.Post("Invoices-api", "PaymentMode", { methodData: paymentMode})
            .then((paymentMode: IPaymentMode) => {
                this.onPaymentModeAdded(paymentMode);
                def.resolve(paymentMode);
            })
            .catch(() => {
                def.reject();
            });
        }

        return def.promise();
    }

    remove(paymentModeId : number) : Promise<void> {
        var def = new Deferred<void>();

        this.ajaxService.Delete("Invoices-api", "PaymentMode/" + paymentModeId, {})
            .then(() => {
                this.onPaymentModeDeleted(paymentModeId);
                def.resolve();
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    addObserver(observer : IPaymentModesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IPaymentModesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onPaymentModeChanged(paymentMode : IPaymentMode) {
        this.load();
        this.observers.forEach((obs) => obs.onPaymentModeChanged(paymentMode));
    }

    private onPaymentModeAdded(paymentMode : IPaymentMode) {
        this.load();
        this.observers.forEach((obs) => obs.onPaymentModeAdded(paymentMode));
    }

    private onPaymentModeDeleted(paymentModeId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onPaymentModeDeleted(paymentModeId));
    }
}