import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 24/08/2017
 * Time: 15:53
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionnaireStatesControlsEntityProvider } from "./QuestionnaireStatesControlsEntityProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEntityProvider } from "../../../../../ProlifeSdk/interfaces/IEntityProvider";
import { IQuestionnaireState } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IQuestionnaireStateSettingManager } from "../../../../interfaces/IQuestionnaireStateSettingManager";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../../Core/Deferred";

export class QuestionnaireStatesEntityProvider implements IEntityProvider<number, IQuestionnaireState> {

    private states: IQuestionnaireState[];

    private statesManager: IQuestionnaireStateSettingManager;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <IQuestionnaireStateSettingManager>settingsService.findSettingsManager(ProlifeSdk.QuestionnaireState);

        this.states = this.statesManager.getQuestionnaireStates();
    }

    getType(): string {
        return ProlifeSdk.QuestionnaireState;
    }

    getEntityTypeName(): string {
        return ProlifeSdk.TextResources.Survey.QuestionnaireStatus;
    }

    getPkValue(item: IQuestionnaireState): number {
        return item.Id;
    }

    getDisplayName(item: IQuestionnaireState): string {
        return item.Description;
    }

    getDisplayNameForKeyValue(pkValue: number): Promise<string> {
        var def = new Deferred<string>();
        var state: IQuestionnaireState[] = this.states.filter((s: IQuestionnaireState) => { s.Id == pkValue });
        def.resolve(state[0].Description);
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IQuestionnaireState[]> {
        var def = new Deferred<IQuestionnaireState[]>();
        var states: IQuestionnaireState[] = this.states.filter((s: IQuestionnaireState) => {
            return s.Description.toUpperCase().indexOf(stringFilter.trim().toUpperCase()) > -1;
        });
        def.resolve(states);
        return def.promise();
    }

    getEntity(pkValue: number): Promise<IQuestionnaireState> {
        var def = new Deferred<IQuestionnaireState>();
        var state: IQuestionnaireState = this.states.filter((s: IQuestionnaireState) => {
            return s.Id == pkValue;
        })[0];
        def.resolve(state);
        return def.promise();
    }

    getControlsProvider(): IControlsEntityProvider {
        return new QuestionnaireStatesControlsEntityProvider(this.serviceLocator);
    }
}