import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Portlet } from "../../../Components/Portlet";
import { TextInput } from "../../../Components/TextInput";
import { DropdownList } from "../../../Components/DropdownList";
import { Template } from "../../../Bindings/ForceBinding";
import { QuotationCategoryListValuesEditor } from ".";
import { Layout } from "../../../Components/Layouts";
import { IQuotationCategory } from "../../QuotationService";
import type { _QuotationCategoryListValuesEditor } from "./QuotationCategoryListValuesEditor";

type QuotationCategoryEditorProps = {
    category: ko.Observable<IQuotationCategory>;
    onSave: (c: IQuotationCategory) => void;
    onDelete: (c: IQuotationCategory) => void;
}

type CategoryType = {
    id: number;
    name: string;
}

type CategoryEditorMap = {
    [id: number]: () => React.ReactElement;
}

export function QuotationCategoryEditor(props: QuotationCategoryEditorProps) {
    const C = require("./QuotationCategoryEditor")._QuotationCategoryEditor as typeof _QuotationCategoryEditor;
    return <C {...props} />;
}

export class _QuotationCategoryEditor extends React.Component<QuotationCategoryEditorProps> {
    static defaultProps: Partial<QuotationCategoryEditorProps> = {
    }

    name : ko.Observable<string> = ko.observable();
    type : ko.Observable<number> = ko.observable();
    editor : ko.Observable<() => React.ReactElement> = ko.observable();
    title: ko.Computed<string>;

    types : CategoryType[] = [
        { id: 1, name: "Testo" },
        { id: 2, name: "Numero" },
        { id: 3, name: "Si/No" },
        { id: 4, name: "Data" },
        { id: 5, name: "Lista" },
    ];

    editors: CategoryEditorMap = {};
    valuesEditor: ko.Observable<_QuotationCategoryListValuesEditor> = ko.observable();

    constructor(props : QuotationCategoryEditorProps) {
        super(props);
        
        this.editors[5] = () => <QuotationCategoryListValuesEditor ref={e => this.valuesEditor(e)} category={this.props.category} />;

        useEffect(() => {
            const cat = this.props.category();
            if(!cat) {
                this.name("");
                this.type(-1);
            } else {
                this.name(cat.Name);
                this.type(cat.Type);
            }
        }, [this.props.category])

        this.title = ko.computed(() => {
            const cat = this.props.category();
            if(!cat) return "";
            if(cat.Id <= 0) return "Nuova Categoria";
            return "Modifica Categoria";
        });

        useEffect(() => {
            this.editor(this.editors[this.type()]);
        }, [this.type]);
    }
    
    render() {
        const onSave = () => {
            this.props.onSave({
                ...this.props.category(),
                Name: this.name(),
                Type: this.type(),
                Values: this.valuesEditor()?.getData() ?? []
            })
        };

        const onCancel = () => {
            const cat = this.props.category();
            this.name(cat.Name);
            this.type(cat.Type);
        };

        const onDelete = () => {
            this.props.onDelete(this.props.category());
        };

        return  <Portlet collapsible={false} style={{ marginLeft: 20 }} noOverflow>
                    <Portlet.Header>
                        <Portlet.Header.Default title={this.title} className="font-red-sunglo bold uppercase" />
                    </Portlet.Header>
                    <Portlet.Actions>
                        <button className="btn btn-circle blue" onClick={onSave}><i className="fa fa-floppy-o"></i>&nbsp;Salva</button>&nbsp;
                        <button className="btn btn-circle yellow-gold" onClick={onCancel}><i className="fa fa-times"></i>&nbsp;Annulla</button>&nbsp;
                        <button className="btn btn-circle red" onClick={onDelete}><i className="fa fa-trash-o"></i>&nbsp;Elimina</button>&nbsp;
                    </Portlet.Actions>
                    <Portlet.Body>
                        {() => <>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextInput label="Nome" value={this.name} />
                                </div>
                                <div className="col-md-6">
                                    <DropdownList 
                                        label="Tipologia" 
                                        value={this.type} 
                                        values={this.types} 
                                        idGetter={v => v.id} 
                                        valueGetter={v => v.id.toString()} 
                                        textGetter={v => v.name} 
                                        //noSelectionPlaceholder="Seleziona una tipologia..."
                                        />
                                </div>
                            </div>
                            <Layout.ScrollContainer>
                                <Template component={this.editor} />
                            </Layout.ScrollContainer>
                        </>}
                    </Portlet.Body>
                </Portlet>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationCategoryEditor);
}