import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ScheduleStateEditingViewModel } from "./ui/ScheduleStateEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IScheduleState, IScheduleService } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IScheduleStateSettingsManager } from "../../../ProlifeSdk/interfaces/schedule/settings/IScheduleStateSettingsManager";
import { IScheduleStateObserver } from "../../../ProlifeSdk/interfaces/schedule/settings/IScheduleStateObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IScheduleLogicalState } from "../../../ProlifeSdk/interfaces/schedule/settings/IScheduleLogicalState";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class ScheduleStateSettingsManager implements IScheduleStateSettingsManager {
	private ajaxService : IAjaxService;
	private scheduleStates : IScheduleState[];
	private observers : IScheduleStateObserver[] = [];
	private statiLogiciScadenza : IScheduleLogicalState[] = [];

	@LazyImport(nameof<ISettingsService>())
	private settingsService: ISettingsService;

	@LazyImport(nameof<IScheduleService>())
	private schedulesService: IScheduleService;

	constructor(private serviceLocator : IServiceLocator) {
		this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Schedule.BillBookSettingsGroup);

		this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);

		this.statiLogiciScadenza.push({IdScheduleLogicalState : 0, Descrizione : ProlifeSdk.TextResources.Schedule.Unpaid});
		this.statiLogiciScadenza.push({IdScheduleLogicalState : 1, Descrizione : ProlifeSdk.TextResources.Schedule.Paid});
		this.statiLogiciScadenza.push({IdScheduleLogicalState : 2, Descrizione : ProlifeSdk.TextResources.Schedule.Canceled});
	}

	getScheduleLogicalState() :IScheduleLogicalState[]{
		return this.statiLogiciScadenza;
	}

	addObserver(observer : IScheduleStateObserver) : void {
		this.observers.push(observer);
	}

	removeObserver(observer : IScheduleStateObserver) : void {
		var index = this.observers.indexOf(observer);
		if(index < 0) return;
		this.observers.splice(index, 1);
	}

	load(): Promise<IScheduleState[]> {
		return this.schedulesService.GetSchedulesStates()
			.then((scheduleState: IScheduleState[]) => this.scheduleStates = scheduleState);
	}

	getName(): string {
		return ProlifeSdk.ScheduleState;
	}

	getLabel(): string {
		return ProlifeSdk.TextResources.Schedule.DeadlineStates;
	}

	hasEditingUI(): boolean {
		return true;
	}

	getEditingUI(): IView {
		return {
			templateName: "scheduleState",
			templateUrl: "schedule/templates/settings",
			viewModel: new ScheduleStateEditingViewModel(this.serviceLocator, this)
		};
	}

	getScheduleStates() : IScheduleState[] {
		return this.scheduleStates;
	}

	getScheduleStateById(stateId : number) : IScheduleState {
		var states : IScheduleState[] = this.scheduleStates.filter((state : IScheduleState) => state.IdScadenzaStato == stateId);
		return states[0];
	}

	createOrUpdate(scheduleState : IScheduleState) : Promise<IScheduleState> {
		let def: Deferred<IScheduleState> = new Deferred();
		
		this.schedulesService.InsertOrUpdateSchedulesStates([scheduleState])
			.then((savedSchedulesStates: IScheduleState[]) => {
				let schedule = savedSchedulesStates.firstOrDefault();

				if (!schedule) {
					def.reject();
					return;
				}

				if(scheduleState.IdScadenzaStato > 0) {
					this.onScheduleStateChanged(schedule);
				} else {
					this.onScheduleStateAdded(schedule);
				}

				def.resolve(schedule);
			});

		return def.promise();
	}

	remove(IdScadenzaStato : number) : Promise<void> {
		return this.schedulesService.DeleteScheduleState(IdScadenzaStato)
			.then(this.onScheduleStateDeleted.bind(this, IdScadenzaStato));
	}

	private onScheduleStateChanged(scheduleState : IScheduleState) {
        this.load();
		this.observers.forEach((obs) => obs.onScheduleStateChanged(scheduleState));
	}

	private onScheduleStateAdded(scheduleState : IScheduleState) {
        this.load();
		this.observers.forEach((obs) => obs.onScheduleStateAdded(scheduleState));
	}

	private onScheduleStateDeleted(IdScadenzaStato : number) {
        this.load();
		this.observers.forEach((obs) => obs.onScheduleStateDeleted(IdScadenzaStato));
	}
}