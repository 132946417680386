import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { CompanySettingsEditingViewModel } from "./ui/CompanySettingsEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICompanySettingsManager } from "../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { ICompany } from "../../../ProlifeSdk/interfaces/settings/ICompany";
import { ICompanySettingsObserver } from "../../../ProlifeSdk/interfaces/settings/ICompanySettingsObserver";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class CompanySettingsManager implements ICompanySettingsManager {

	private ajaxService : IAjaxService;
    private userInfo : IUserInfo;
    private company : ICompany;

    private observers : ICompanySettingsObserver[] = [];

	constructor(private serviceLocator : IServiceLocator) {
		this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
		this.userInfo = <IUserInfo> serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
	}

	getName() : string{
		return ProlifeSdk.CompanySettingsType;
	}

    getLabel() : string {
        return ProlifeSdk.TextResources.Settings.CompanyInformations;
    }

	load() : Promise<ICompany> {
		return this.ajaxService.Post<ICompany>("Settings-api/Company", "GetCompanyById", {
            methodData: {
                companyId : this.userInfo.getCurrentCompanyGuid()
            }
        }).then((company: ICompany) => this.company = company);
	}

    get() : ICompany {
        return this.company;
    }

    update(company : ICompany) : Promise<ICompany> {
        return this.ajaxService.Put<ICompany>("Settings-api", "Company", {
            methodData: company
        }).then(this.companyUpdated.bind(this));
    }

    updateLogo(file : File) : Promise<void>
    {
        if(!file)
            return Promise.resolve<void>(undefined);

        var data = { 'mail': file };
        return this.ajaxService.Upload("Settings-api", "Logo", data, false);
    }

    hasEditingUI() : boolean {
        return true;
    }

	getEditingUI() : IView{
        return {
            templateName: 'companyData',
            templateUrl: 'settings/templates',
            viewModel: new CompanySettingsEditingViewModel(this.serviceLocator, this)
        };
	}

    addObserver(observer : ICompanySettingsObserver) : void {
        this.observers.push(observer);
    }

    private companyUpdated(company : ICompany) {
        this.company = company;
        this.observers.forEach((o) => o.onCompanyChanged(company));
    }
}