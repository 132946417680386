import * as ko from "knockout";
export class GetPositionWhen
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var topField = valueAccessor()["topField"];
        var triggerField = valueAccessor()["triggerField"];

        var top : number = $(element).position().top;
        topField(top);

        triggerField.subscribe(() => {
            var top : number = $(element).position().top;
            topField(top);
        });
    }
}

ko.bindingHandlers["getPositionWhen"] = new GetPositionWhen();
