import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IPaymentMode, IPaymentModes } from "../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";

export interface IPaymentModeDataSourceModel extends IDataSourceModel<number, IPaymentMode> {

}

export class PaymentModesDataSource extends BaseDataSource<IPaymentModeDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.PaymentMode)
    public paymentModes: IPaymentModes;

    private viewDeleted: boolean;

    getTitle(currentModel: IPaymentModeDataSourceModel): string {
        return this.paymentModes.getLabel();
    }

    selectByIds(...ids: number[]) {
        return this.select(...ids.map(id => ({ id: id, title: "", isLeaf: true, isGroup: false })));
    }

    async getData(currentModel: IPaymentModeDataSourceModel, textFilter: string, skip: number, count: number): Promise<IPaymentModeDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let paymentModes = this.paymentModes.getPaymentModes(this.viewDeleted);
        
        return paymentModes
            .filter(d => splitFilters.filter(w => d.Descrizione.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    createModel(model : IPaymentMode): IPaymentModeDataSourceModel {
        return {
            id: model.IdTipoPagamento,
            title: model.Descrizione,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }

    async getById(currentModel: IPaymentModeDataSourceModel, ids: number[]): Promise<IPaymentModeDataSourceModel[]> {
        return this.paymentModes.getPaymentModes(true)
            .filter(p => ids.indexOf(p.IdTipoPagamento) >= 0)
            .map(this.createModel, this);
    }

}