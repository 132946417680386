import * as React from "@abstraqt-dev/jsxknockout";
import { Select, SelectProps } from "../../../../Components/Select";
import { IJobOrderForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";

export class JobOrderSelect {
    constructor(private props: SelectProps<number, IJobOrderForTaskBoard>) {}

    render() {
        return <Select {...this.props} />;
    }
}
