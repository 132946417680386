import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IEstimatedBudgetForTaskState, IEstimatedBudgetForTaskStatesService } from "../Todolist/interfaces/IEstimatedBudgetForTaskStatesService";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IEstimatedBudgetForTaskStatesSettingsManager } from "../Todolist/interfaces/IEstimatedBudgetForTaskStatesSettingsManager";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface IEstimatedBudgetForTaskStatesDataSourceModel extends IDataSourceModel<number, EsimatedBudgetForTaskState> {
    
}

interface IDraggetEstimatedBudgetForTaskState {
    Id: number,
    CompanyGuid: string;
}

export class EstimatedBudgetForTaskStatesDataSource extends BaseDataSource<IEstimatedBudgetForTaskStatesDataSourceModel> {
    @LazyImport(nameof<IEstimatedBudgetForTaskStatesService>())
    private estimatedBudgetForTaskStatesService: IEstimatedBudgetForTaskStatesService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    
    public getTitle(currentModel: IEstimatedBudgetForTaskStatesDataSourceModel): string {
        return "";
    }
    
    public async getData(currentModel: IEstimatedBudgetForTaskStatesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IEstimatedBudgetForTaskStatesDataSourceModel[]> {
        let items = await this.estimatedBudgetForTaskStatesService.GetEstimatedBudgetForTaskStates(textFilter, false, skip, count);
        return this.createStateModels(items);
    }
    
    public async getById(currentModel: IEstimatedBudgetForTaskStatesDataSourceModel, ids: number[]): Promise<IEstimatedBudgetForTaskStatesDataSourceModel[]> {
        let items = await this.estimatedBudgetForTaskStatesService.GetEstimatedBudgetForTaskStatesByIds(ids);
        return this.createStateModels(items);
    }

    public getSupportedDropMimeTypes(): string[] {
        return ['application/prolife-estimated-budget-for-task-state'];
    }
    
    public onItemBeginMove(model: IEstimatedBudgetForTaskStatesDataSourceModel, dataTransfer: DataTransfer): void {
        let draggedState : IDraggetEstimatedBudgetForTaskState = {
            Id: model.id,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData("application/prolife-estimated-budget-for-task-state", JSON.stringify(draggedState));
    }
    
    public async onItemMoved(dataTransfer: DataTransfer, model: IEstimatedBudgetForTaskStatesDataSourceModel, before: boolean) : Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-estimated-budget-for-task-state") >= 0 && model) {
            let droppedState = JSON.parse(dataTransfer.getData("application/prolife-estimated-budget-for-task-state")) as IDraggetEstimatedBudgetForTaskState;

            if (droppedState.CompanyGuid == this.userInfo.getCurrentCompanyGuid()) {
                await this.estimatedBudgetForTaskStatesService.MoveEstimatedBudgetForTaskState(droppedState.Id, before ? model.model.Order() : model.model.Order() + 1);
                this.refresh();
            }
        }
    }

    private createStateModels(states: IEstimatedBudgetForTaskState[]): IEstimatedBudgetForTaskStatesDataSourceModel[] {
        return states.map(s => ({
            id: s.Id,
            title: s.Label,
            isGroup: false,
            isLeaf: true,
            dragEnabled: true,
            icon: {
                icon: s.Icon,
                background: s.Background,
                foreground: s.Foreground
            },
            model: new EsimatedBudgetForTaskState(s, this)
        }));
    }
}

export class EsimatedBudgetForTaskState {
    public Label : ko.Observable<string> = ko.observable();
    public Icon : ko.Observable<string> = ko.observable();
    public Background : ko.Observable<string> = ko.observable();
    public Foreground : ko.Observable<string> = ko.observable();
    public Order : ko.Observable<number> = ko.observable();
    public Deleted : ko.Observable<boolean> = ko.observable(false);

    @LazyImportSettingManager(nameof<IEstimatedBudgetForTaskStatesSettingsManager>())
    private estimatedBudgetForTaskStatesManager: IEstimatedBudgetForTaskStatesSettingsManager;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private state: IEstimatedBudgetForTaskState, private dataSource: EstimatedBudgetForTaskStatesDataSource) {
        this.Label(this.state.Label);
        this.Icon(this.state.Icon);
        this.Background(this.state.Background);
        this.Foreground(this.state.Foreground);
        this.Order(this.state.Order);
        this.Deleted(this.state.Deleted);

        this.Label.subscribe(this.onDataChanges.bind(this));
        this.Icon.subscribe(this.onDataChanges.bind(this));
        this.Background.subscribe(this.onDataChanges.bind(this));
        this.Foreground.subscribe(this.onDataChanges.bind(this));
    }

    private async onDataChanges(): Promise<void> {
        let data = this.getData();

        try {
            await this.estimatedBudgetForTaskStatesManager.createOrUpdateStates([data]);
        } catch(e) {
            // TODO
        }
    }

    public async markAsDeleted(): Promise<void> {
        let confirm = await this.dialogsService.ConfirmAsync(TextResources.Todolist.EstimatedBudgetForTaskStateDeleteMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);

        if (!confirm)
            return;

        try {
            let data = this.getData();
            data.Deleted = true;

            await this.estimatedBudgetForTaskStatesManager.createOrUpdateStates([data]);
            this.dataSource.refresh();

        } catch (e) {
            // TODO errore
        }
    }


    public getData(): IEstimatedBudgetForTaskState {
        let data = Object.assign({}, this.state) as IEstimatedBudgetForTaskState;

        data.Label = this.Label();
        data.Icon = this.Icon();
        data.Background = this.Background();
        data.Foreground = this.Foreground();
        data.Deleted = this.Deleted();

        return data;
    }
}