import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 12/09/2018
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */

import { ImportDataWizardDataSourceStep } from "../../../../../ProlifeSdk/prolifesdk/ImportDataWizardDataSourceStep";
import { IDataSourceForMonthlyInvoicing, IMonthlyInvoicingDataSource, IMonthlyInvoicingDataSourceBaseFilters } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";

export class ImportMonthlyInvoicingDataWizardDataSourceStep extends ImportDataWizardDataSourceStep {
    constructor(dataSource: IDataSourceForMonthlyInvoicing, initializationInfo: IWizardInitializationInfo, destinationDocumentRowsReferences: IRefDocumentRow[], private wizard: IMonthlyInvoicingDataSource) {
        super(dataSource, initializationInfo, destinationDocumentRowsReferences);

        (this.dataSource as IDataSourceForMonthlyInvoicing).SetWizard(wizard);
    }

    public BeforeNext() : boolean
    {
        if(!this.dataSource.Validate())
            return false;

        (this.dataSource as IDataSourceForMonthlyInvoicing).SetDataSourceOptions(this.wizard.ImportData);

        return true;
    }

    public InitializeFilters(filters: IMonthlyInvoicingDataSourceBaseFilters): void {
        let dataSource = this.dataSource as IDataSourceForMonthlyInvoicing;
        
        if (dataSource.InitializeFilters)
            dataSource.InitializeFilters(filters);
    }

    public SetLoadRowsAfterInitialize(value: boolean): void {
        let dataSource = this.dataSource as IDataSourceForMonthlyInvoicing;
        dataSource.SetLoadRowsAfterInitialize(value);
    }

    public GetFilters(): IMonthlyInvoicingDataSourceBaseFilters {
        let dataSource = this.dataSource as IDataSourceForMonthlyInvoicing;
        
        if (dataSource.GetFilters)
            return dataSource.GetFilters();
        
        return null;
    }
}