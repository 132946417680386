import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTask, IEstimateBudgetRow } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkflowNavigator } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { INavigationMenuObserver } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { IBudgetRequestState, IBudgetRequestStatesSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IBudgetRequestStatesSettingsManager";
import { IBudgetRequest, ITaskBudget, ITaskInfoForBudgetRequest } from "../../../../ProlifeSdk/interfaces/job-order/IEstimatedBudgetNavigator";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { Deferred } from "../../../../Core/Deferred";

export class BudgetRequestDialog implements INavigationMenuObserver, IDialog
{
    private authService : IAuthorizationService;
    private dialogService : IDialogsService;
    private toastService : IInfoToastService;
    private todoListService : ITodoListService;
    private jobOrderService : IJobOrderService;

    public templateName: string = "budget-request-dialog";
    public templateUrl: string = "joborder/templates/joborderdetail/budget-assignment";
    title: string;
    modal : { close: (result?: any) => void; };

    private ActivitiesBar : IWorkflowNavigator;

    public Title : ko.Observable<string> = ko.observable("");
    public Notes : ko.Observable<string> = ko.observable("");
    public CreationDate : ko.Observable<Date> = ko.observable();
    public CreationUser : ko.Observable<number> = ko.observable();
    public Approved: ko.Observable<boolean> = ko.observable(false);
    public ApprovedUser : ko.Observable<number> = ko.observable();
    public ApprovedDate : ko.Observable<Date> = ko.observable();
    public Total : ko.Computed<number>;
    public TotalWorkHours : ko.Computed<number>;
    public TotalWork : ko.Computed<number>;
    public TotalPurchases : ko.Computed<number>;
    public TotalWarehouse : ko.Computed<number>;
    public TasksRequests : ko.ObservableArray<TaskBudgetRequest> = ko.observableArray([]);

    public BudgetRequestCauses : ko.ObservableArray<IBudgetRequestState> = ko.observableArray();
    public AllBudgetRequestCause : ko.Computed<TaskBudgetRequest>;

    private request : IBudgetRequest = null;

    public CanBeDeleted : ko.Computed<boolean>;
    public IsNew : ko.Observable<boolean> = ko.observable(false);

    public UserCanAuthorizeBudgetRequests : ko.Observable<boolean> = ko.observable(false);
    public UserCanEditBudgetRequests : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.authService = <IAuthorizationService>serviceLocator.findService(ServiceTypes.Authorization);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var budgetRequestSettingsManager = <IBudgetRequestStatesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.BudgetRequestCauses);

        this.BudgetRequestCauses(budgetRequestSettingsManager.get());

        this.ActivitiesBar = this.todoListService.GetWorkflowNavigatorUI({
            DisableWorkflowSelection : true,
            DisableWorkflowEditing : false,
            DisableTaskEditing : false
        });
        this.ActivitiesBar.setMultipleSelectionStatus(true);
        this.ActivitiesBar.setSingleSelectionStatus(false, true);
        this.ActivitiesBar.addMenuObserver(this);

        this.Total = ko.computed(() => {
            var total : number = 0 ;
            this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                total += t.Total();
            });
            return total;
        });

        this.TotalWorkHours = ko.computed(() => {
            var total : number = 0 ;
            this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                total += t.WorkHours();
            });
            return total;
        });

        this.TotalWork = ko.computed({
            read: () => {
                var total : number = 0 ;
                this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                    total += t.WorkBudget();
                });
                return total;
            },
            write: (value) => {
                var oldValue = this.TotalWork();
                if(oldValue == 0.0) {
                    var amount = value / this.TasksRequests().length;
                    this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                        t.WorkBudget(amount);
                    });
                } else {
                    var scale = value / oldValue;

                    this.TasksRequests().forEach((t:TaskBudgetRequest) => {
                        t.WorkBudget(t.WorkBudget() * scale);
                    });
                }
            }
        });

        this.TotalPurchases = ko.computed({
            read: () => {
                var total : number = 0 ;
                this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                    total += t.PurchasesBudget();
                });
                return total;
            },
            write: (value) => {
                var oldValue = this.TotalPurchases();
                if(oldValue == 0.0) {
                    var amount = value / this.TasksRequests().length;
                    this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                        t.PurchasesBudget(amount);
                    });
                } else {
                    var scale = value / oldValue;

                    this.TasksRequests().forEach((t:TaskBudgetRequest) => {
                        t.PurchasesBudget(t.PurchasesBudget() * scale);
                    });
                }
            }
        });

        this.TotalWarehouse = ko.computed({
            read: () => {
                var total : number = 0 ;
                this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                    total += t.WarehouseBudget();
                });
                return total;
            },
            write: (value) => {
                var oldValue = this.TotalWarehouse();
                if(oldValue == 0.0) {
                    var amount = value / this.TasksRequests().length;
                    this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                        t.WarehouseBudget(amount);
                    });
                } else {
                    var scale = value / oldValue;

                    this.TasksRequests().forEach((t:TaskBudgetRequest) => {
                        t.WarehouseBudget(t.WarehouseBudget() * scale);
                    });
                }
            }
        });

        this.CanBeDeleted = ko.computed(() => {
            return !this.IsNew() && !this.Approved();
        });

        this.UserCanAuthorizeBudgetRequests(this.authService.isAuthorized(ProlifeSdk.JobOrderApplicationCode + "_BudgetRequest_Authorize"));
        this.UserCanEditBudgetRequests(this.authService.isAuthorized(ProlifeSdk.JobOrderApplicationCode + "_BudgetRequest_Edit"));

        var requestCauseValue = ko.observable(null);

        this.AllBudgetRequestCause = ko.computed({
            read: requestCauseValue,
            write: (value) => {
                if(value == null)
                    return;

                this.TasksRequests().forEach((t : TaskBudgetRequest) => {
                    t.BudgetRequestCause(value);
                });
                requestCauseValue(value);
                requestCauseValue(null);
            }
        })
    }

    public SomeIsChanged() : boolean
    {
        var isChanged : boolean = false;
        isChanged = isChanged || this.Title() != this.request.Title;
        isChanged = isChanged || this.Notes() != this.request.Notes;

        isChanged = isChanged || this.TasksRequests().length != this.request.TasksRequests.length;

        this.TasksRequests().forEach((t : TaskBudgetRequest) => {
            isChanged = isChanged || t.SomeIsChanged();
            isChanged = isChanged || this.request.TasksRequests.map((t : ITaskBudget) => { return t.TaskId; }).indexOf(t.TaskId) == -1;
        });

        this.request.TasksRequests.forEach((t : ITaskBudget) => {
            isChanged = isChanged || this.TasksRequests().map((t : TaskBudgetRequest) => { return t.TaskId; }).indexOf(t.TaskId) == -1;
        });

        isChanged = isChanged || this.CreationDate() != this.request.CreationDate;
        isChanged = isChanged || this.ApprovedDate() != this.request.ApprovedDate;

        return isChanged;
    }

    public Initialize(r : IBudgetRequest) : Promise<void>
    {
        this.request = r;

        var def = new Deferred<void>();
        this.ActivitiesBar.Initialize([r.JobOrderId]).then(() => {

            this.ActivitiesBar.SetSelectedTasks(r.TasksRequests.map((t : ITaskBudget) => { return t.TaskId; }))
                .then(() => {

                    this.jobOrderService.GetTasksInfoForBudgetRequest(r.Id).then((info : ITaskInfoForBudgetRequest[]) => {
                        this.Title(r.Title);
                        this.CreationDate(r.CreationDate);
                        this.CreationUser(r.CreationUser);
                        this.Notes(r.Notes);
                        this.Approved(r.Approved);
                        this.ApprovedDate(r.ApprovedDate);
                        this.ApprovedUser(r.ApprovedUser);
                        this.TasksRequests(r.TasksRequests.map((t : ITaskBudget) => {
                            var matches = info.filter((i : ITaskInfoForBudgetRequest) => { return i.TaskId == t.TaskId; });
                            return new TaskBudgetRequest(this.serviceLocator, t, this.GetTaskTitle(t.TaskId), matches.length > 0 ? matches[0] : null, false);
                        }));
                        this.IsNew(!r.Id);

                        def.resolve();
                    }).catch(() => { def.reject([]); });
                })
                .catch(() => { def.reject([]); });

        }).catch(() => { def.reject([]); });
        return def.promise();
    }

    public CancelChanges()
    {
        this.Initialize(this.request);
    }

    public Delete()
    {
        this.dialogService.Confirm(ProlifeSdk.TextResources.JobOrder.RequestDeleteMsg, ProlifeSdk.TextResources.JobOrder.RequestDeleteMsgCancel, ProlifeSdk.TextResources.JobOrder.RequestDeleteMsgConfirm, (confirm : boolean) => {
            if(!confirm)
                return;

            this.jobOrderService.DeleteBudgetRequest(this.request.Id).then(() => {
                this.toastService.Success(ProlifeSdk.TextResources.JobOrder.RequestDeleted);
                this.request = null;
                this.modal.close(null);
            });
        });
    }

    private GetTaskTitle(taskId : number)
    {
        var tasksAndTitles : any[] = this.ActivitiesBar
            .getSelectedProviders().map((p) => { return { Id : (<any>p).task.Id, Title : (<any>p).task.Title }});
        var matches = tasksAndTitles.filter((t) => { return t.Id == taskId });
        return matches.length > 0 ? matches[0].Title : null;
    }

    public Authorize()
    {
        var authorize = (confirm : boolean) => {
            if(!confirm)
                return;

            var request : IBudgetRequest = this.request;
            request.Approved = true;
            request.ApprovedDate = this.ApprovedDate() || new Date();
            request.CreationDate = this.CreationDate();

            this.jobOrderService.InsertOrUpdateBudgetRequest(request)
                .then((r : IBudgetRequest) => {
                    this.toastService.Success(ProlifeSdk.TextResources.JobOrder.RequestAuthorized);
                    this.Initialize(r);
                });
        }

        if(this.SomeIsChanged())
            this.dialogService.Confirm(ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsg, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgCancel, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgConfirm, authorize.bind(this));
        else
            authorize(true);
    }

    public Revocation()
    {
        var revocation = (confirm : boolean) => {
            if(!confirm)
                return;

            var request : IBudgetRequest = this.request;
            request.Approved = false;
            request.CreationDate = this.CreationDate();
            request.ApprovedDate = null;

            this.jobOrderService.InsertOrUpdateBudgetRequest(request)
                .then((r : IBudgetRequest) => {
                    this.toastService.Success(ProlifeSdk.TextResources.JobOrder.RequestRevoked);
                    this.Initialize(r);
                });
        }

        if(this.SomeIsChanged())
            this.dialogService.Confirm(ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsg, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgCancel, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgConfirm, revocation.bind(this));
        else
            revocation(true);
    }

    public SaveChanges()
    {
        if(this.Title().trim().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.JobOrder.InsertRequestTitle);
            return;
        }

        if(this.TasksRequests().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.JobOrder.SelectAnActivity);
            return;
        }

        var request : IBudgetRequest = this.request;
        request.Title = this.Title();
        request.Notes = this.Notes();
        request.TasksRequests = this.TasksRequests().map((t : TaskBudgetRequest) => { return t.GetData(); });
        request.CreationDate = this.CreationDate();
        request.ApprovedDate = null;

        this.jobOrderService.InsertOrUpdateBudgetRequest(request)
            .then((r : IBudgetRequest) => {
                this.toastService.Success(ProlifeSdk.TextResources.JobOrder.RequestSaved);
                this.Initialize(r);
            });
    }

    public Dispose()
    {
        this.ActivitiesBar.Dispose();
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]){}

    onSelectionChanged(selection: INavigationMenuProvider[], navigator : INavigationMenu)
    {
        if(this.Approved() || !this.UserCanEditBudgetRequests())
            return;

        var tasksIds : number[] = selection.map((p) => { return (<any>p).task.Id; });
        var currentTasksIds : number[] = this.TasksRequests().map((t : TaskBudgetRequest) => { return t.TaskId; });

        //Rimuovo i task rimossi dalla selezione
        var tasksToRemove : TaskBudgetRequest[] = this.TasksRequests().filter((t : TaskBudgetRequest) => { return tasksIds.indexOf(t.TaskId) == -1; });
        tasksToRemove.forEach((t : TaskBudgetRequest) => { this.TasksRequests.remove(t); });

        //Aggiungo i nuovi task selezionati
        tasksIds.filter((id : number) => { return currentTasksIds.indexOf(id) == -1; }).forEach((id : number) => {

            this.jobOrderService.GetTaskInfoForBudgetRequest(this.request.Id, id).then((info : ITaskInfoForBudgetRequest) => {
                var task : TaskBudgetRequest = new TaskBudgetRequest(this.serviceLocator, {
                    RequestId: this.request.Id,
                    TaskId: id,
                    MeanWorkCost: 0,
                    WorkBudget: 0,
                    ComputedWorkBudget: 0,
                    PurchasesBudget: 0,
                    ComputedPurchasesBudget: 0,
                    WarehouseBudget: 0,
                    ComputedWarehouseBudget: 0,
                    BudgetRequestCause: null
                },
                this.GetTaskTitle(id), info, true);
                this.TasksRequests.push(task);
            });

        });


    }

    close(): void
    {
        var closeCallback = (confirm : boolean) => {
            if(!confirm)
                return;

            this.modal.close(this.request && this.request.Id ? this.request : null);
        }

        if(this.SomeIsChanged())
            this.dialogService.Confirm(ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsg, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgCancel, ProlifeSdk.TextResources.JobOrder.UnsavedChangesLostMsgConfirm, closeCallback.bind(this));
        else
            closeCallback(true);
    }

    action(): void {}
}

export class TaskBudgetRequest
{
    private todoListService : ITodoListService;
    private dialogService : IDialogsService;

    public TaskName : string;
    public TaskId : number;

    public BudgetRequestCause : ko.Observable<number> = ko.observable();
    public WorkBudget : ko.Observable<number> = ko.observable();
    public PurchasesBudget : ko.Observable<number> = ko.observable();
    public WarehouseBudget : ko.Observable<number> = ko.observable();

    public WorkHours : ko.Computed<number>;
    public MeanWorkCost : ko.Observable<number> = ko.observable();
    public ComputedWorkBudget : ko.Observable<number> = ko.observable();
    public ComputedPurchasesBudget : ko.Observable<number> = ko.observable();
    public ComputedWarehouseBudget : ko.Observable<number> = ko.observable();

    public EstimatedWorkBudget : ko.Observable<number> = ko.observable();
    public EstimatedPurchasesBudget : ko.Observable<number> = ko.observable();
    public EstimatedWarehouseBudget : ko.Observable<number> = ko.observable();

    public OtherRequestsWork : ko.Observable<number> = ko.observable();
    public OtherRequestsPurchases : ko.Observable<number> = ko.observable();
    public OtherRequestsWarehouse : ko.Observable<number> = ko.observable();
    public AuthorizedWork : ko.Observable<number> = ko.observable();
    public AuthorizedPurchases : ko.Observable<number> = ko.observable();
    public AuthorizedWarehouse : ko.Observable<number> = ko.observable();

    public Total : ko.Computed<number>;
    public EstimatedTotal : ko.Computed<number>;
    public OtherRequestsTotal : ko.Computed<number>;
    public AuthorizedTotal : ko.Computed<number>;

    constructor(private serviceLocator : IServiceLocator, private taskRequest : ITaskBudget, taskName : string, private taskInfo : ITaskInfoForBudgetRequest, initData : boolean)
    {
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);

        this.TaskId = taskRequest.TaskId;
        this.TaskName = taskName;

        this.BudgetRequestCause((taskInfo && !taskRequest.BudgetRequestCause) ? taskInfo.BudgetRequestCause : taskRequest.BudgetRequestCause);

        this.EstimatedWorkBudget(taskInfo.EstimatedWorkBudget);
        this.EstimatedPurchasesBudget(taskInfo.EstimatedPurchaseskBudget);
        this.EstimatedWarehouseBudget(taskInfo.EstimatedWarehouseBudget);

        this.OtherRequestsWork(taskInfo.OtherRequestsWork);
        this.OtherRequestsPurchases(taskInfo.OtherRequestsPurchases);
        this.OtherRequestsWarehouse(taskInfo.OtherRequestsWarehouse);

        this.AuthorizedWork(taskInfo.AuthorizedWork);
        this.AuthorizedPurchases(taskInfo.AuthorizedPurchases);
        this.AuthorizedWarehouse(taskInfo.AuthorizedWarehouse);

        this.Total = ko.computed(() => {
            return (this.WorkBudget() || 0) + (this.PurchasesBudget() || 0) + (this.WarehouseBudget() || 0);
        });

        this.EstimatedTotal = ko.computed(() => {
            return (this.EstimatedWorkBudget() || 0) + (this.EstimatedPurchasesBudget() || 0) + (this.EstimatedWarehouseBudget() || 0);
        });

        this.OtherRequestsTotal = ko.computed(() => {
            return (this.OtherRequestsWork() || 0) + (this.OtherRequestsPurchases() || 0) + (this.OtherRequestsWarehouse() || 0);
        });

        this.AuthorizedTotal = ko.computed(() => {
            return (this.AuthorizedWork() || 0) + (this.AuthorizedPurchases() || 0) + (this.AuthorizedWarehouse() || 0);
        });

        this.WorkHours = ko.computed(() => {
            let workBudget = this.WorkBudget();
            let meanWorkCost = this.MeanWorkCost();

            if(!meanWorkCost)
                return 0;
            return workBudget / meanWorkCost;
        });

        var remainingWorkBudget : number = taskInfo.EstimatedWorkBudget > taskInfo.OtherRequestsWork ? taskInfo.EstimatedWorkBudget - taskInfo.OtherRequestsWork : 0;
        var remainingPurchasesBudget : number = taskInfo.EstimatedPurchaseskBudget > taskInfo.OtherRequestsPurchases ? taskInfo.EstimatedPurchaseskBudget - taskInfo.OtherRequestsPurchases : 0;
        var remainingWarehouseBudget : number = taskInfo.EstimatedWarehouseBudget > taskInfo.OtherRequestsWarehouse ? taskInfo.EstimatedWarehouseBudget - taskInfo.OtherRequestsWarehouse : 0;

        this.WorkBudget(initData ? remainingWorkBudget : taskRequest.WorkBudget);
        this.PurchasesBudget(initData ? remainingPurchasesBudget : taskRequest.PurchasesBudget);
        this.WarehouseBudget(initData ? remainingWarehouseBudget : taskRequest.WarehouseBudget);

        this.MeanWorkCost(initData ? taskInfo.MeanWorkCost : taskRequest.MeanWorkCost);
        this.ComputedWorkBudget(initData ? remainingWorkBudget : taskRequest.ComputedWorkBudget);
        this.ComputedPurchasesBudget(initData ? remainingPurchasesBudget : taskRequest.ComputedPurchasesBudget);
        this.ComputedWarehouseBudget(initData ? remainingWarehouseBudget : taskRequest.ComputedWarehouseBudget);
    }

    public SomeIsChanged() : boolean
    {
        var isChanged : boolean = false;
        isChanged = isChanged || this.WorkBudget() != this.taskRequest.WorkBudget;
        isChanged = isChanged || this.PurchasesBudget() != this.taskRequest.PurchasesBudget;
        isChanged = isChanged || this.WarehouseBudget() != this.taskRequest.WarehouseBudget;
        isChanged = isChanged || this.BudgetRequestCause() != this.taskRequest.BudgetRequestCause;
        return isChanged;
    }

    public GetData() : ITaskBudget
    {
        var taskBudget : ITaskBudget = this.taskRequest;
        taskBudget.WorkBudget = this.WorkBudget();
        taskBudget.ComputedWorkBudget = this.ComputedWorkBudget();
        taskBudget.PurchasesBudget = this.PurchasesBudget();
        taskBudget.ComputedPurchasesBudget = this.ComputedPurchasesBudget();
        taskBudget.WarehouseBudget = this.WarehouseBudget();
        taskBudget.ComputedWarehouseBudget = this.ComputedWarehouseBudget();
        taskBudget.BudgetRequestCause = this.BudgetRequestCause();
        taskBudget.MeanWorkCost = this.MeanWorkCost();
        return taskBudget;
    }

    public ShowDetails()
    {
        this.todoListService.GetTaskById(this.taskRequest.TaskId).then((t : ITodoListTask) => {
            var dialog : IDialog = new TaskForBudgetDetails(this.serviceLocator, t);
            this.dialogService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
        });
    }
}

export class TaskForBudgetDetails implements IDialog
{
    templateName: string = "task-details";
    templateUrl: string = "joborder/templates/joborderdetail/budget-assignment";
    title: string;
    modal: { close: (result?: any) => void; };

    public EstimatedWorkRows : ko.ObservableArray<IEstimateBudgetRow> = ko.observableArray([]);
    public EstimatedPurchasesRows : ko.ObservableArray<IEstimateBudgetRow> = ko.observableArray([]);
    public EstimatedArticlesRows : ko.ObservableArray<IEstimateBudgetRow> = ko.observableArray([]);

    public TotalEstimatedArticlesAmount : number = 0;
    public TotalEstimatedWorkAmount : number = 0;
    public TotalEstimatedPurchasesPrice : number = 0;

    constructor(private serviceLocator : IServiceLocator, task : ITodoListTask)
    {
        this.title = task.Title;

        var estimateWorkRows = task.EstimatedBudgetRows
            .filter((r : IEstimateBudgetRow) => { return r.Type == ProlifeSdk.EstimatedWorkEntityTypeCode; });
        this.EstimatedWorkRows(estimateWorkRows);

        var estimatePurchasesRows = task.EstimatedBudgetRows
            .filter((r : IEstimateBudgetRow) => { return r.Type == ProlifeSdk.EstimatedPurchaseEntityTypeCode; });
        this.EstimatedPurchasesRows(estimatePurchasesRows);

        var estimateArticlesRows = task.EstimatedBudgetRows
            .filter((r : IEstimateBudgetRow) => { return r.Type == ProlifeSdk.WarehouseArticleEntityTypeCode; });
        this.EstimatedArticlesRows(estimateArticlesRows);

        this.EstimatedWorkRows().forEach((r) => { this.TotalEstimatedWorkAmount += r.Amount * r.Multiplier; });
        this.EstimatedPurchasesRows().forEach((r) => { this.TotalEstimatedPurchasesPrice += r.Price * r.Multiplier; });
        this.EstimatedArticlesRows().forEach((r) => { this.TotalEstimatedArticlesAmount += r.Amount * r.Multiplier; });
    }

    close(): void
    {
        this.modal.close();
    }

    action(): void { }
}