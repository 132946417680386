import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Portlet } from "../../../Components/Portlet";
import { List } from "../../../Components/ListComponent";
import { IQuotationCategory } from "../../QuotationService";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";

type QuotationCategoriesListProps = {
    selectedCategory?: ko.Observable<IQuotationCategory>;
    categories: ko.ObservableArray<IQuotationCategory>;
    onNew: () => void;
    onCategorySelected: (c: IQuotationCategory) => void;
};

export function QuotationCategoriesList(props: QuotationCategoriesListProps) {
    const C = require("./QuotationCategoriesList")._QuotationCategoriesList as typeof _QuotationCategoriesList;
    return <C {...props} />;
}

export class _QuotationCategoriesList
    extends React.Component<QuotationCategoriesListProps>
    implements IDataSourceListener
{
    static defaultProps: Partial<QuotationCategoriesListProps> = {};

    private list: ko.Observable<List<number, IQuotationCategory>> = ko.observable();
    private ignoreNextItemSelected = false;

    constructor(props: QuotationCategoriesListProps) {
        super(props);

        if (!props.selectedCategory) props.selectedCategory = ko.observable();

        useEffect(() => {
            if (!this.list()) return;

            this.ignoreNextItemSelected = true;
            const list = this.list();
            list.select(props.selectedCategory());
        }, [props.selectedCategory, this.list]);
    }

    onItemSelected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {
        if (this.ignoreNextItemSelected) {
            this.ignoreNextItemSelected = false;
            return;
        }
        this.props.onCategorySelected(model?.model);
    }

    onItemDeselected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {}

    render() {
        const factory = (c: IQuotationCategory) => ({
            id: c.Id,
            title: c.Name,
            isLeaf: true,
            isGroup: false,
            model: c,
        });

        return (
            <Portlet collapsible={false}>
                <Portlet.Header>
                    <Portlet.Header.Default title="Categorie" className="font-red-sunglo bold uppercase" />
                </Portlet.Header>
                <Portlet.Actions>
                    <button className="btn btn-circle btn-icon-only btn-default" onClick={this.props.onNew}>
                        <i className="fa fa-plus"></i>
                    </button>
                </Portlet.Actions>
                <Portlet.Body>
                    {() => (
                        <List
                            ref={(l) => this.list(l)}
                            dataSource={{ array: this.props.categories, factory: factory }}
                            listener={this}
                            containerHeight="flex"
                        />
                    )}
                </Portlet.Body>
            </Portlet>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(QuotationCategoriesList);
}
