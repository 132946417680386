import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/06/13
 * Time: 15.47
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { FileRepositoryApplication } from "./FileRepository/FileRepositoryApplication";
import { AttachmentsManager } from "./FileRepository/attachments/AttachmentsManager";
import { GenericFileConfigurationsUI } from "./FileRepository/settings/GenericFileConfigurationsUI";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew, UploadData } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    IFileRepositoryService,
    IRefAndDocPair,
    IFileUploadResult,
    IFileHistory,
    IAttachmentUploadStatus,
} from "../ProlifeSdk/interfaces/files/IFileRepositoryService";
import {
    IAttachmentsManagerPathProvider,
    IAttachmentsManager,
} from "../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { IFileOrFolder } from "../ProlifeSdk/interfaces/files/IFileOrFolder";
import { ITagHint } from "../ProlifeSdk/interfaces/files/ITagHint";
import { IPermaLink } from "../ProlifeSdk/interfaces/files/IPermaLink";
import { IGenericFileConfigurationsUI } from "../ProlifeSdk/interfaces/files/IGenericFileConfigurationsUI";
import { PromiseWithProgress } from "../Core/Deferred";
import { LazyImport } from "../Core/DependencyInjection";
import { IAuthenticationService } from "../Core/interfaces/IAuthenticationService";

class FileRepositoryService extends ProLifeService implements IFileRepositoryService {
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxService: IAjaxServiceNew;

    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;

    private application: FileRepositoryApplication;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.FileRepositoryApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IFileRepositoryService>(), this);
    }

    InitializeService() {
        super.InitializeService();

        this.application = new FileRepositoryApplication(this.serviceLocator);
    }

    createAttachmentsManager(pathProvider: IAttachmentsManagerPathProvider): IAttachmentsManager {
        return new AttachmentsManager(pathProvider);
    }

    getPreviewStyle(fileOrFolder: IFileOrFolder): string {
        if (!fileOrFolder) return "file not-found";

        const classes: string[] = [];
        classes.push(fileOrFolder.IsFolder ? "folder" : "file");

        if (fileOrFolder.HasPreview) classes.push("preview");
        if (fileOrFolder.Reserved) classes.push("reserved");
        if (!fileOrFolder.IsFolder)
            classes.push(
                fileOrFolder.IsFolder
                    ? ""
                    : fileOrFolder.Name.substr((~-fileOrFolder.Name.lastIndexOf(".") >>> 0) + 2).toLowerCase()
            );

        return classes.join(" ");
    }

    getServiceType(): string {
        return ProlifeSdk.FileRepositoryServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    browse(path: string): Promise<IFileOrFolder[]> {
        return this.ajaxService.Post("f/Browse", "browsePath", {
            methodData: {
                path,
            },
            background: true,
        });
    }

    ExistsDirectory(path: string): Promise<boolean> {
        return this.ajaxService.Post("f/Directory", "directoryExists", { methodData: path, background: true });
    }

    CreateDirectory(path: string): Promise<void> {
        return this.ajaxService.Post("f/Directory", "createDirectory", {
            methodData: {
                path,
            },
            background: true,
        });
    }

    createFilesReferencesToSendByMail(fileIds: string[]): Promise<IRefAndDocPair[]> {
        return this.ajaxService.Post("f/FileMail", "createReferences", { background: true, methodData: fileIds });
    }

    search(
        path: string,
        fromDate?: Date,
        toDate?: Date,
        name?: string,
        extension?: string,
        modifiedBy?: number,
        contentType?: string,
        contents?: string,
        tags?: number[],
        shared?: boolean
    ): Promise<IFileOrFolder[]> {
        return this.ajaxService.Post("f/Search", "", {
            background: true,
            methodData: {
                path,
                parameters: {
                    fromDate: fromDate,
                    toDate: toDate,
                    name: name,
                    extension: extension,
                    modifiedBy: modifiedBy,
                    contentType: contentType,
                    contents: contents,
                    tags: (tags || []).length == 0 ? undefined : JSON.stringify(tags),
                    shared: shared,
                },
            },
        });
    }

    deleteFile(path: string): Promise<void> {
        return this.ajaxService.Post("f/Files", "deleteFile", {
            methodData: {
                path,
            },
            background: true,
        });
    }

    deleteFolder(path: string): Promise<void> {
        return this.ajaxService.Post("f/Browse", "deleteFolder", {
            methodData: {
                path,
            },
            background: true,
        });
    }

    upload(path: string, file: Blob, fileName: string): PromiseWithProgress<IFileUploadResult> {
        const uploadData: UploadData = {};
        uploadData["path"] = path;
        uploadData["file"] = new File([file], fileName, {
            type: file.type,
        });

        return this.ajaxService.Upload("f/Files", "uploadFile", uploadData, true);
    }

    openAsDialog(currentPath: string): Promise<IFileOrFolder[]> {
        return this.application.OpenAsDialog(currentPath);
    }

    createFolder(path: string, newFolderName: string): Promise<void> {
        return this.ajaxService.Post("f/Browse", "createFolder", {
            methodData: {
                path: path + newFolderName,
            },
            background: true,
        });
    }

    renameFolder(currentPath: string, newName: string): Promise<void> {
        return this.ajaxService.Post("f/Browse", "renameFolder", {
            background: true,
            methodData: {
                path: currentPath,
                newName,
            },
        });
    }

    renameFile(currentPath: string, newName: string): Promise<void> {
        return this.ajaxService.Post("f/Files", "renameFile", {
            background: true,
            methodData: {
                path: currentPath,
                newName,
            },
        });
    }

    moveFolders(folderIds: string[], newPath: string): Promise<void> {
        return this.ajaxService.Post("f/Move", "moveFolders", {
            background: true,
            methodData: {
                path: newPath,
                folderIds: folderIds.map(parseInt),
            },
        });
    }

    moveFolder(folderName: string, oldPath: string, newPath: string): Promise<void> {
        return this.ajaxService.Post("f/Move", "moveByFolderName", {
            background: true,
            methodData: { newPath, oldPath, folderName },
        });
    }

    moveFiles(fileIds: string[], newPath: string): Promise<void> {
        return this.ajaxService.Post("f/Move", "moveFiles", {
            background: true,
            methodData: {
                path: newPath,
                fileIds,
            },
        });
    }

    copyFolders(folderIds: string[], newPath: string): Promise<void> {
        return this.ajaxService.Post("f/Copy", "copyFolders", {
            background: true,
            methodData: {
                path: newPath,
                folderIds: folderIds.map(parseInt),
            },
        });
    }

    copyFiles(fileIds: string[], newPath: string): Promise<void> {
        return this.ajaxService.Post("f/Copy", "copyFiles", {
            background: true,
            methodData: {
                path: newPath,
                fileIds,
            },
        });
    }

    getPreviewUrl(fileOrFolder: IFileOrFolder): string {
        return "/ServiceNew/f/Preview/" + fileOrFolder.Id + "?access_token=" + this.authenticationService.getToken();
    }

    getDownloadUrl(fileOrFolder: IFileOrFolder, open = false): string {
        return (
            "/ServiceNew/f/Files/getFile/" +
            fileOrFolder.Id +
            "?open=" +
            open +
            "&access_token=" +
            this.authenticationService.getToken()
        );
    }

    getFavouriteFolders(): Promise<IFileOrFolder[]> {
        return this.ajaxService.Get("f/Favourites", "", { background: true });
    }

    getFileDetails(fileId: string): Promise<IFileOrFolder> {
        return this.ajaxService.Get("f/Details", fileId, { background: true });
    }

    getFilesDetails(fileIds: string[]): Promise<IFileOrFolder[]> {
        return this.ajaxService.Post("f/Details", "getDetails", { background: true, methodData: { ids: fileIds } });
    }

    SearchTags(textFilter: string, skip: number, count: number): Promise<ITagHint[]> {
        return this.ajaxService.Post("f/TagsSearch", "search", {
            background: true,
            methodData: {
                textFilter,
                skip,
                count,
            },
        });
    }

    getTagHints(query: string): Promise<ITagHint[]> {
        return this.ajaxService.Post("f/Tags", "getTags", {
            background: true,
            methodData: {
                query,
            },
        });
    }

    createNewTag(name: string, color: number): Promise<ITagHint> {
        return this.ajaxService.Post("f/Tags", "createTag", {
            background: true,
            methodData: {
                name,
                color,
            },
        });
    }

    assignTagToFiles(labelId: number, fileIds: string[]): Promise<void> {
        return this.ajaxService.Post("f/Tags", "assignTag", {
            background: true,
            methodData: {
                labelId,
                fileIds,
            },
        });
    }

    removeTagFromFiles(labelId: number, fileIds: string[]): Promise<void> {
        return this.ajaxService.Delete("f/Tags", "", {
            background: true,
            methodData: {
                labelId,
                fileIds,
            },
        });
    }

    changeTagColor(labelId: number, color: number): Promise<void> {
        return this.ajaxService.Post("f/Color", "changeTagColor", {
            background: true,
            methodData: {
                id: labelId,
                color,
            },
        });
    }

    resolveTagNames(labelIds: number[]): Promise<ITagHint[]> {
        return this.ajaxService.Post("f/TagsResolve", "", {
            background: true,
            methodData: {
                labelIds,
            },
        });
    }

    deleteTag(labelId: number): Promise<void> {
        return this.ajaxService.Delete("TagsResolve", labelId.toString(), { background: true });
    }

    getAvailableSpace(): Promise<number> {
        return this.ajaxService.Get("f/Space", "", { background: true });
    }

    getUsedSpace(): Promise<number> {
        return this.ajaxService.Put("f/Space", "", { background: true });
    }

    getTotalSpace(): Promise<number> {
        return this.ajaxService.Post("f/Space", "", { background: true });
    }

    canCopy(folderIds: string[], fileIds: string[]): Promise<boolean> {
        return this.ajaxService.Post("f/Check", "", {
            background: true,
            methodData: {
                folderIds,
                fileIds,
            },
        });
    }

    setAdministration(labelId: number, administration: boolean): Promise<void> {
        return this.ajaxService.Post("f/TagSecurity", "administration/" + labelId, {
            background: true,
            methodData: administration,
        });
    }

    setCommercial(labelId: number, commercial: boolean): Promise<void> {
        return this.ajaxService.Post("f/TagSecurity", "commercial/" + labelId, {
            background: true,
            methodData: commercial,
        });
    }

    setTechnical(labelId: number, technical: boolean): Promise<void> {
        return this.ajaxService.Post("f/TagSecurity", "technical/" + labelId, {
            background: true,
            methodData: technical,
        });
    }

    getPermalinksForFile(fileId: string): Promise<IPermaLink[]> {
        return this.ajaxService.Get("f/Permalink", fileId, { background: true });
    }

    deletePermalink(permalinkId: string): Promise<void> {
        return this.ajaxService.Delete("f/Permalink", permalinkId, { background: true });
    }

    createPermalink(fileId: string): Promise<IPermaLink> {
        return this.ajaxService.Post("f/Permalink", fileId, { background: true });
    }

    GetGenericFileConfigurationsUI(): IGenericFileConfigurationsUI {
        return new GenericFileConfigurationsUI(this.serviceLocator);
    }

    GetTagLabelById(labelId: number): Promise<ITagHint> {
        return this.ajaxService.Get("f/Labels", "" + labelId, { background: true });
    }

    GetFileHistory(fileId: string): Promise<IFileHistory[]> {
        return this.ajaxService.Get("f/History", "" + fileId, { background: true });
    }

    GetVersionDownloadUrl(id: string): string {
        return "f/History/download/" + id + "?access_token=" + this.authenticationService.getToken();
    }

    GetLastVersion(fileId: string): Promise<IFileOrFolder> {
        return this.ajaxService.Get("f/History", "lastVersion/" + fileId, { background: true });
    }

    Publish(fileId: string): Promise<void> {
        return this.ajaxService.Post("f/History", "", {
            background: true,
            methodData: {
                id: fileId,
                publish: true,
            },
        });
    }

    Unpublish(fileId: string): Promise<void> {
        return this.ajaxService.Post("f/History", "", {
            background: true,
            methodData: {
                id: fileId,
                publish: false,
            },
        });
    }

    checkStatusForTaskAttachmentUpload(jobOrderId: number, fileName: string): Promise<IAttachmentUploadStatus> {
        return this.ajaxService.Post("f/JobOrderAttachments", "checkStatusFor", {
            background: true,
            methodData: {
                jobOrderId,
                fileName,
            },
        });
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new FileRepositoryService(serviceLocator);
}
