import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 02/05/13
 * Time: 9.58
 * To change this template use File | Settings | File Templates.
 */

function slideIn(valueAccessor: () => any, element, index, data) {
    (<any>$(element))
        .filter(function() { return this instanceof HTMLElement })
        .transition({ x: -4000 }, 0)
        .transition({ x: 20 * index, y: 20 * index, z: -20 * index }, 1000, 'ease', () => {
            ko.utils.domData.set(element, "__invoices_lastIndex", index);
        })
        .css("z-index", 30 - index);

    /*$(element).hammer().on('swipeleft', (event) => {
        //if(event.gesture.touches.length == 1) {
            var observableArray = valueAccessor();
            var array = observableArray();
            if(array.length > 1) {
                var clone = array.slice();

                var data = clone[0];
                clone.shift();
                clone.push(data);

                observableArray(clone);
            }
        //}
    });*/
}

function slideOut(valueAccessor: () => any, element, index, data) {
    (<any>$(element))
        .filter(function() { return this instanceof HTMLElement })
        .transition({ x: 4000, y: 20 * index, z: -20 * index }, 1000, 'ease', () => {
            ko.removeNode(element);
            ko.utils.domData.set(element, "__invoices_lastIndex", undefined);
        })
        .css("z-index", 30 - index);
}

function moveOutForChangePosition(valueAccessor: () => any, element, oldIndex, data) {

}

function moveInForChangePosition(valueAccessor: () => any, element, oldIndex, data) {
    var array = ko.utils.unwrapObservable(valueAccessor());
    var index = array.indexOf(data);

    oldIndex = ko.utils.domData.get(element, "__invoices_lastIndex");

    if(index >= 1 && index == (array.length - 1) && oldIndex == 0) {
        (<any>$(element))
            .filter(function() { return this instanceof HTMLElement })
            .transition({ x: -800, y: 20 * oldIndex, z: -20 * oldIndex }, 500, 'in')
            .transition({ x: -800, y: 20 * oldIndex, z: -20 * index }, 0, 'in')
            .transition({ x: 20 * index, y: 20 * index, z: -20 * index }, 500, 'out', () => {
                ko.utils.domData.set(element, "__invoices_lastIndex", index);
            })
            .css("z-index", 30 - index);
    } else if(index < oldIndex && index == 0 && (oldIndex > 1 && array.length > 1)) {
        (<any>$(element))
            .filter(function() { return this instanceof HTMLElement })
            .transition({ x: -800, y: 20 * oldIndex, z: -20 * oldIndex }, 500, 'in')
            .transition({ x: -800, y: 20 * oldIndex, z: -20 * index }, 0, 'in')
            .transition({ x: 20 * index, y: 20 * index, z: -20 * index }, 500, 'out', () => {
                ko.utils.domData.set(element, "__invoices_lastIndex", index);
            })
            .css("z-index", 30 - index);
    } else {
        (<any>$(element))
            .filter(function() { return this instanceof HTMLElement })
            .transition({ x: 20 * index, y: 20 * index, z: -20 * index }, 500, 'in-out', () => {
                ko.utils.domData.set(element, "__invoices_lastIndex", index);
            })
            .css("z-index", 30 - index);
    }
}

export class FirstOnlyWithSlideInOut {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        ko.applyBindingsToNode(element, {
            foreach: {
                data: valueAccessor(),
                afterAdd: slideIn.bind(null, valueAccessor),
                beforeRemove: slideOut.bind(null, valueAccessor),
                beforeMove: moveOutForChangePosition.bind(null, valueAccessor),
                afterMove: moveInForChangePosition.bind(null, valueAccessor)
            }
        }, bindingContext);

        return {
            controlsDescendantBindings: true
        };
    }
}

ko.bindingHandlers["firstOnlyWithSlideInOut"] = new FirstOnlyWithSlideInOut();