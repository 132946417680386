import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 25/08/2016
 * Time: 17:55
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { Delay } from "../../../../../Decorators/Delay";
import { TasksDataSource, ITasksDataSourceModel } from "../../../../../DataSources/TasksDataSource";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IListComponent } from "../../../../../Components/ListComponent";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListFolder } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListService, ITaskForTaskBoard, ITaskDialogOptions } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IBlogService } from "../../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IFileRepositoryService } from "../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";

export class JobOrderTasksFactory implements IJobOrderEditorPanelFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderTasks(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("Todolist_ManageJobOrdersActivities") && this.authorizationService.isAuthorized("JobOrders_ViewActivities");
    }

}

class JobOrderTasks implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Activities);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "activities";

    ExporterId  = "TodoList/WorkflowExport";

    //WorkflowEditor : ITodoList;
    TextFilter : ko.Observable<string> = ko.observable();
    TasksDataSource : TasksDataSource;
    TasksList : ko.Observable<IListComponent<number, ITasksDataSourceModel>> = ko.observable();
    WorkflowSelection : number[] = [];
    DetailedMode : ko.Observable<boolean> = ko.observable(false);
    Folders : ITodoListFolder[];

    public CanEditActivitiesMultipliers: ko.Observable<boolean> = ko.observable(false);

    public EnableEditActivitiesMultipliersButton: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService: ITodoListService;

    @LazyImport(ProlifeSdk.JobOrderServiceType)
    private jobOrderService: IJobOrderService;

    @LazyImport(ServiceTypes.Authorization)
    private authorizationService : IAuthorizationService;

    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private fileRepositoryService : IFileRepositoryService;

    @LazyImport(ServiceTypes.Dialogs)
    private dialogsService : IDialogsService;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {
        this.CanEditActivitiesMultipliers(this.authorizationService.isAuthorized("Todolist_TasksMultipliersMassiveEdit"));

        this.TasksDataSource = new TasksDataSource();
        this.TasksDataSource.setBypassCanViewAllForJobOrderTasksPlanning(true);
        this.TasksDataSource.setViewAll(this.authorizationService.isAuthorized("TaskBoard_CanViewAll"));
        this.TasksDataSource.setJobOrderId(this.editor.JobOrderId);
        this.TasksDataSource.enableNotifications(true);
        
        this.Folders = this.todoListService.GetTasksFolders();
        this.Folders.filter(f => !f.IsAggregateFolder && f.Id <= 3).forEach(f => f.IsSelected(true));
        this.load();

        this.TasksList.subscribe((list) => {
            this.TextFilter.subscribe((textFilter) => {
                list.TextFilter(textFilter);
            });
        });
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
        this.TasksDataSource.enableNotifications(false);
    }

    @Delay()
    load(): Promise<boolean> {
        this.TasksDataSource.setWorkflows(this.WorkflowSelection, false);
        this.TasksDataSource.setFolders(this.getSelectedFoldersIds(), true);
        return Promise.resolve<boolean>(true);
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        this.WorkflowSelection = [];
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        this.TasksDataSource.enableNotifications(true);
        this.EnableEditActivitiesMultipliersButton(this.CanEditActivitiesMultipliers() && this.WorkflowSelection.length > 0);
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(true);
    }

    showProductionActivitiesPrintDialog(): void {
        this.jobOrderService.ui.getProductionActivitiesPrintDialog(this.editor.JobOrderId).show();
    }

    showActivitiesMultipliersEditor(): void {
        this.todoListService.GetWorkflow(this.WorkflowSelection[0])
            .then((workflow: ITodoListWorkflow) => {
                const dialog = this.todoListService.GetWorkflowActivitiesMultipliersManagerUI(workflow);
                dialog.show();
            });
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.WorkflowSelection = this.WorkflowSelection || [];
        this.WorkflowSelection.push(model.id as number);
        this.EnableEditActivitiesMultipliersButton(this.CanEditActivitiesMultipliers() && this.WorkflowSelection.length > 0);

        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        this.WorkflowSelection = this.WorkflowSelection || [];
        const index = this.WorkflowSelection.indexOf(model.id as number);
        if(index != -1)
            this.WorkflowSelection.splice(index, 1);

        this.EnableEditActivitiesMultipliersButton(this.CanEditActivitiesMultipliers() && this.WorkflowSelection.length > 0);

        this.load();
    }

    OnWorkflowSelectionChanged(selection:number[]) {
        this.WorkflowSelection = selection;
        this.EnableEditActivitiesMultipliersButton(this.CanEditActivitiesMultipliers() && selection.length > 0);

        this.load();
    }

    OnWorkflowDeleted(workflowId:number) { this.load(); }

    OnWorkflowChanged(workflowId:number) { this.load(); }

    OnWorkflowCreated(workflowId:number) { this.load(); }

    OnWorkflowImportedFromTemplate(workflowId:number) { this.load(); }

    OnWorkflowUpdate(workflow: ITodoListWorkflow) {
    }

    public getExcelExportData() : any | null {
        const workflowIds = this.WorkflowSelection;
        if(workflowIds.length == 0) {
            this.infoToastService.Warning("Selezionare un piano prima di effettuare l'esportazione");
            return null;
        }

        const workflowId = workflowIds[0];

        return {
            workflowId: workflowId
        };
    }

    public SwitchCompactView()
    {
        this.DetailedMode(false);
    }

    public SwitchDetailedView()
    {
        this.DetailedMode(true);
    }

    public SelectFolder(folder : ITodoListFolder)
    {
        if(folder.IsAggregateFolder) {
            for(const f of this.Folders.filter(f => !f.IsAggregateFolder))
                f.IsSelected(!folder.IsSelected());
        } else {
            folder.IsSelected(!folder.IsSelected());
        }

        const allFolder = this.Folders.filter(f => f.IsAggregateFolder)[0];
        allFolder.IsSelected(this.getSelectedFoldersIds().length == this.Folders.length-1);

        this.load();
    }

    private getSelectedFoldersIds() : number[] {
        return this.Folders.filter(f => f.IsSelected() && !f.IsAggregateFolder).map(f => f.Id);
    }

    public ShowDetails(task : ITaskForTaskBoard) {
        const options: ITaskDialogOptions = this.getTaskDialogOptions();
        options.containerId = this.WorkflowSelection[0];
        this.todoListService.ShowEditTaskDialog(task.Id, options);
    }

    public OpenBlog(task : ITaskForTaskBoard) {
        const blogService = <IBlogService> this.serviceLocator.findService(ProlifeSdk.BlogServiceType);
        blogService.openBlogInNewWindow(this.editor.JobOrderId, task.Id);
    }

    public async AddNewTask() {
        const options: ITaskDialogOptions = this.getTaskDialogOptions();
        this.todoListService.ShowCreateNewTaskDialog(this.editor.JobOrderId, this.WorkflowSelection[0], options);
    }

    public async CopyTask(): Promise<void> {
        const options: ITaskDialogOptions = this.getTaskDialogOptions();
        this.todoListService.ShowCopyTaskDialog(this.WorkflowSelection[0], this.editor.JobOrderId, options);
    }
    
    public async CreateFromTaskTemplate(): Promise<void> {
        const options: ITaskDialogOptions = this.getTaskDialogOptions();
        this.todoListService.ShowCreateTaskFromTemplateDialog(this.WorkflowSelection[0], this.editor.JobOrderId, options);
    }

    public NewWorkflow() {
        this.todoListService.ShowCreateWorkflowDialog(this.editor.JobOrderId, this.editor.IsJobOrderClosed());
    }
    
    public NewWorkflowFromWorkflow() {
        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.editor.JobOrderId, this.editor.IsJobOrderClosed());
    }
    
    public NewWorkflowFromTemplate() {
        this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.editor.JobOrderId, this.editor.IsJobOrderClosed());
    }

    private getTaskDialogOptions(): ITaskDialogOptions {
        const options: ITaskDialogOptions = {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            showClosedJobOrders: this.editor.IsJobOrderClosed()
        };

        return options;
    }
}