import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { DocumentLayout } from "./DocumentLayout";

export class CompactDetailedLayout extends DocumentLayout
{
    constructor()
    {
        super();
        this.LayoutId = 3;
        this.Description = ProlifeSdk.TextResources.ProlifeSdk.CompactDetailDescription;
        this.Title = ProlifeSdk.TextResources.ProlifeSdk.CompactDetailTitle;
        this.ShortTitle = ProlifeSdk.TextResources.ProlifeSdk.CompactDetailShortTitle;
    }
}