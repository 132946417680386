import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { WorkSheetEvent } from "../WorkSheetEvent";
import { WorkedHoursEvent } from "../WorkedHoursEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class WorkedHoursInput extends BlogEventBaseInput
{
    public templateName : string  = "work-sheet-details";
    public title : string = "Statino";

    hours : ko.Observable<number> = ko.observable();

    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver,
                private event : WorkedHoursEvent)
    {
        super(serviceLocator,contextEventsObserver);
        this.templateUrl = "workedhours/templates/events/details";
        this.iconName = "f-icon-clock";
        this.setValues(event);
    }

    public setValues(item : WorkedHoursEvent) : void
    {
        super.setValues(item);
        this.hours(item.HoursId());
    }

    getBlogEvent(): ILogEvent
    {
        var localBlogEvent : ILogEvent = super.getBlogEvent();

        localBlogEvent.Tags.push( {TagName : ProlifeSdk.Tag_WorkSheet,
            Value : this.hours(),
            TagTypeId : ProlifeSdk.TagType_WorkSheet});
        localBlogEvent.EventType = ProlifeSdk.WorkedHoursEventType_WorkSheet;
        return localBlogEvent;
    }

    public getValidationMessage() : string[]
    {
        //Non implementato perchè non serve salvare visto che l'evento è in Read-Only
        return [];
    }
}
