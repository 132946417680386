import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { ReportDesigner, _ReportDesigner } from "./ReportDesigner";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { ReportSectionProps } from "./Components/ReportSection";
import { TextResources } from "../../ProlifeSdk/ProlifeSdk";

type ReportDesignerDialogProps = {
    configuration: { sections: ReportSectionProps[] };
}
export class ReportDesignerDialog extends DialogComponentBase {
    static defaultProps: Partial<ReportDesignerDialogProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private reportDesigner: _ReportDesigner;
    private originalConfig: string;

    constructor(private props : ReportDesignerDialogProps) {
        super({ className: "fullscreen" });
        this.title("Editor Modelli di Stampa")
        this.originalConfig = JSON.stringify(props.configuration);
    }

    show() {
        return this.dialogsService.ShowModal<{ sections: ReportSectionProps[] }>(this);
    }
    
    action() {
        const actualConfig = this.reportDesigner.getData();
        this.modal.close(actualConfig);
    }

    public close() {
        const actualConfig = this.reportDesigner.getData();
        const newData = JSON.stringify(actualConfig);
        if(newData !== this.originalConfig) {
            this.dialogsService.Confirm(TextResources.Invoices.PrintTemplateUnsavedChangesMessage, TextResources.Invoices.PrintTemplateUnsavedChangesMessageCancel, TextResources.Invoices.PrintTemplateUnsavedChangesMessageConfirm, (result) => {
                if(result) this.modal.close();
            })
        } else {
            this.modal.close();
        }
    }
    
    renderBody() {
        return <ReportDesigner 
                    forwardRef={(rd) => this.reportDesigner = rd}
                    inputDefinition={[{
                        Name: "EstimateId",
                        DataType: "Int32Key",
                        Description: "Id univoco del documento",
                        EntityType: "DOC"
                    }]} 
                    canImport
                    configuration={this.props.configuration} 
                    style={{ position: 'absolute', inset: 0 }} />;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportDesignerDialog);
}