import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/03/2018
 * Time: 12:51
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { AgendasProvider } from "../providers/AgendasProvider";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAgendaService, IAgendaEnvelope, IAgenda } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IEntityProvider } from "../../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../Core/Deferred";

export class AgendaEntityProvider implements IEntityProvider<number, IAgendaEnvelope> {
    private agendasService: IAgendaService;

    constructor(private serviceLocator: IServiceLocator) {
        var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        entityProviderService.registerEntityProvider(this, this.getType());

        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
    }

    getType(): string {

        return ProlifeSdk.AgendaEntityType;
    }

    getEntityTypeName(): string {
        return ProlifeSdk.TextResources.Agenda.Agenda;
    }

    getPkValue(item: IAgendaEnvelope): number {
        return item.Id;
    }

    getDisplayName(item: IAgendaEnvelope): string {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue: number): Promise<string> {
        var def = new Deferred<string>();
        this.agendasService.GetAgenda(pkValue)
            .then((agenda: IAgenda) => {
                def.resolve(agenda.Name);
            })
            .catch(() => { def.reject(); });

        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IAgendaEnvelope[]> {
        return this.agendasService.GetAgendasEnvelopes(stringFilter);
    }

    getEntity(pkValue: number): Promise<IAgenda> {
        return this.agendasService.GetAgenda(pkValue);
    }

    getControlsProvider(): IControlsEntityProvider {
        return new AgendasProvider(this.serviceLocator);
    }
}