import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Layout } from "../../../../Components/Layouts";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IException } from "../../../../Core/interfaces/IException";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { Select } from "../../../../Components/Select";
import { VatRegistersDataSource } from "../../../../DataSources/VatRegistersDataSource";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type ImportPassiveInvoicesDialogProps = {
}

export class ImportPassiveInvoicesDialogUI extends DialogComponentBase {
    static defaultProps: Partial<ImportPassiveInvoicesDialogProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor() {
        super({ className: 'fullscreen', noPrompt: true });
        this.title(TextResources.Invoices.ImportPassiveInvoices);
    }

    showDialog() {
        return this.dialogsService.ShowModal(this);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return <ImportPassiveInvoicesDialog />;
    }
}

function ImportPassiveInvoicesDialog(props: ImportPassiveInvoicesDialogProps) {
    const C = require("./ImportPassiveInvoicesDialog")._ImportPassiveInvoicesDialog as typeof _ImportPassiveInvoicesDialog;
    return <C {...props} />;
}

type IPassiveInvoiceImportResultReport = {

}

type PassiveInvoiceImportReport = {
    
}

type ImportStatus = {
    fileName: string;
    status: ko.Observable<string>;
    statusIcon: ko.Observable<string>;
    result: ko.Observable<string>;
    completed: ko.Observable<boolean>;
    error: ko.Observable<boolean>;
    expanded: ko.Observable<boolean>;
}

export class _ImportPassiveInvoicesDialog {
    static defaultProps: Partial<ImportPassiveInvoicesDialogProps> = {
    }

    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    selectedFiles : ko.ObservableArray<File> = ko.observableArray();
    files: ko.ObservableArray<ImportStatus> = ko.observableArray();
    loading : ko.Observable<boolean> = ko.observable(false);
    fkRegister : ko.Observable<number> = ko.observable();
    importing : ko.Observable<boolean> = ko.observable();

    private importCode : string;
    private eventsToDeregister: number[] = [];
    private subscriptionToDispose : ko.Subscription[] = [];
    private vatRegistersDataSource = new VatRegistersDataSource();

    constructor(private props : ImportPassiveInvoicesDialogProps) {
        this.subscriptionToDispose.push(this.selectedFiles.subscribe(() => {
            if(!this.selectedFiles()) {
                this.files([]);
                return;
            }

            this.files(this.selectedFiles().map(f => ({
                fileName: f.name,
                status: ko.observable(TextResources.Invoices.ImportPassiveInvoicesWaiting),
                statusIcon: ko.observable("fa-clock-o"),
                result: ko.observable(),
                completed: ko.observable(false),
                error: ko.observable(false),
                expanded: ko.observable(false)
            })));
        }));

        this.vatRegistersDataSource.setDocumentType(ProlifeSdk.PassiveInvoiceEntityTypeCode, ProlifeSdk.PassiveInvoiceTypeId);
    }

    public async uploadFile() : Promise<void>
    {
        this.desktopService.BlockPageUI(TextResources.Warehouse.ImportInProgress);
        this.loading(true);
        this.importing(true);
        
        const files = this.selectedFiles();
        const data = {};
        data["registerId"] = this.fkRegister();

        for(let i = 0; i < files.length; i++)
        {
            data['metel' + i] = files[i];
        }

        try
        {
            const result = await this.ajaxService.Upload<{ ImportCode: string }>("Invoices-api", "PassiveInvoiceImport", data);
            this.importCode = result.ImportCode;   
        }
        catch(e)
        {
            console.error(e);
        }
        finally
        {
            this.loading(false);
            this.importing(false);
            this.desktopService.UnblockPageUI();
        }
    }

    componentDidMount() {
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("PassiveInvoicesImportFileStart", (data: { ImportCode: string; FileName: string; })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === data.FileName);
                if(!file) {
                    console.warn(`File non trovato "${data.FileName}"`);
                    return;
                }
                file.status(TextResources.Invoices.ImportPassiveInvoicesInProgress);
                file.statusIcon("fa-circle-o-notch fa-spin");
            }
        }));
        this.eventsToDeregister.push(this.changesNotificationsService.RegisterEventHandler("PassiveInvoicesImportFileEnd", (data: { ImportCode: string; FileName: string; Result: boolean; ErrorMessage: string; })=> {
            if(data.ImportCode === this.importCode) {
                const file = this.files().firstOrDefault(f => f.fileName === data.FileName);
                if(data.Result) {
                    file.status(TextResources.Invoices.ImportPassiveInvoicesDone);
                    file.statusIcon("fa-check");
                    file.completed(true);
                    file.error(false);
                } else {
                    file.status(TextResources.Invoices.ImportPassiveInvoicesError);
                    file.statusIcon("fa-times");
                    file.completed(true);
                    file.error(true);
                    file.result(data.ErrorMessage);
                }
            }
        }));
    }

    componentWillUnmount() {
        this.eventsToDeregister.forEach(i => this.changesNotificationsService.UnregisterEventHandler(undefined, i));
        this.subscriptionToDispose.forEach(s => s.dispose());
    }
    
    render() {
        const $data = this;
        let file: IDataSourceModel<string, ImportStatus>;
        const dataSource = Table.defaultDataSource(this.files, (d) => ({ id: d.fileName, title: d.fileName, model: d }));

        return  ComponentUtils.bindTo(
            <div className="flex-container" style={{ position: 'absolute', inset: '15px' }}>
                <Layout.Grid columns={["1fr"]} rows={["auto", "1fr"]} rowGap={"15px"}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <div className="input-group fileinput-new" data-bind={{ fileInput : {} }}>
                            <div className="form-control uneditable-input" data-trigger="fileinput">
                                <i className="fa fa-file fileinput-exists"></i>&nbsp;
                                <span className="fileinput-filename"></span>
                            </div>
                            <span className="input-group-addon btn default btn-file">
                                <span className="fileinput-new">Seleziona files</span>
                                <span className="fileinput-exists">Cambia files</span>
                                <input type="file" accept=".xml" data-bind={{ file: $data.selectedFiles }} multiple />
                            </span>
                            <a href="#" className="input-group-addon btn red fileinput-exists" data-dismiss="fileinput">Rimuovi files</a>
                        </div>
                        <Select value={this.fkRegister} dataSource={this.vatRegistersDataSource} placeholder="Seleziona il protocollo su cui importare le fatture passive..." style={{ marginLeft: 'auto', flex: 0, minWidth: '500px' }} />
                        <button className="btn btn-primary" data-bind={{ click: $data.uploadFile, enable: !!$data.fkRegister() && $data.selectedFiles().length > 0 && !$data.importing() }}>Importa</button>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Table dataSource={dataSource} rowAs="file" systemScrollable showLoadingIndicator={this.loading}>
                            <Column title="File">
                                <span data-bind={{ text: file.model.fileName }}></span>
                            </Column>
                            <Column title="Stato" style={{ width: '200px'}} className="text-right">
                                <span data-bind={{ css: { 'text-success': file.model.completed, 'text-danger': file.model.error }}}>
                                    <span data-bind={{ text: file.model.status }}></span>&nbsp;
                                    <i className="fa" data-bind={{ css: file.model.statusIcon }} />&nbsp;
                                </span>
                                <a href="#" data-bind={{ click: () => file.model.expanded(!file.model.expanded()) }}>
                                    <i className="fa" data-bind={{ css: { 'fa-angle-down': !file.model.expanded(), 'fa-angle-up': file.model.expanded }}} />
                                </a>
                            </Column>
                            <SecondaryRow if={() => "file.model.expanded"}>
                                {(item: ITableItem<ImportStatus>) => 
                                    <div class="alert alert-danger" data-bind={{ text: file.model.result, visible: file.model.error }}>
                                        
                                    </div>
                                }
                            </SecondaryRow>
                        </Table>
                    </Layout.Grid.Cell>
                </Layout.Grid>
            </div>
        , this);
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ImportPassiveInvoicesDialog);
}