import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { SelectMultiple } from "../../../../../Components/SelectMultiple";
import { ComponentUtils } from "../../../../../Core/utils/ComponentUtils";
import { CartsDataSource, ICartsDataSourceModel } from "../../../../../DataSources/CartsDataSource";
import { ICartLink } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

export type CartLinksSelectorProps = {
    cartId: number;
    value: ko.ObservableArray<ICartLink>;

    onLinkAdded?: (linkId: number, delay: number) => void;
    onLinkRemoved?: (linkId: number) => void;
    onLinkDelayChange?: (value: number) => void;
}

export class CartLinksSelector {
    public CartsDataSource: CartsDataSource = new CartsDataSource();
    public LinksDelay: ko.Observable<number> = ko.observable(0);

    private Links: ko.ObservableArray<number> = ko.observableArray([]);

    private linksDelaySubscription: ko.Subscription;

    constructor(private props: CartLinksSelectorProps) {
        this.CartsDataSource.setShowAllocatedCarts(true);
        this.CartsDataSource.setShowClosedCarts(false);

        const links = this.props.value();
        const linksIds = links.map(l => l.LinkCartId);
        
        for (const link of links) {
            this.CartsDataSource.addCartToFilter(link.LinkCartId);   
        }

        if (this.props.cartId && this.props.cartId > 0)
            this.CartsDataSource.addCartToFilter(this.props.cartId);

        this.Links(linksIds);
        this.LinksDelay(links.firstOrDefault()?.DelayInDays ?? 0);
    }

    componentDidMount() {
        this.linksDelaySubscription = this.LinksDelay.subscribe((value) => {
            this.props.onLinkDelayChange && this.props.onLinkDelayChange(value);
        });
    }

    componentWillUnmount() {
        this.linksDelaySubscription.dispose();
    }

    private onCartSelected(cart: ICartsDataSourceModel): void {
        this.CartsDataSource.addCartToFilter(cart.id);
        this.props.onLinkAdded && this.props.onLinkAdded(cart.id, this.LinksDelay());
    }
    
    private onCartDeselected(cart: ICartsDataSourceModel): void {
        this.CartsDataSource.removeCartToFilter(cart.id);
        this.props.onLinkRemoved && this.props.onLinkRemoved(cart.id);
    }

    render() {
        const linksSelector = this;

        return  ComponentUtils.bindTo((
            <div className="row">
                <div className="col-md-8">
                    <SelectMultiple.WithLabel
                        dataSource={this.CartsDataSource}
                        value={this.Links}
                        label={TextResources.Allocations.CartLinks}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        onSelect={this.onCartSelected.bind(this)}
                        onDeselect={this.onCartDeselected.bind(this)}
                    />
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="control-label">{TextResources.Allocations.LinksDelayLabel}</label>&nbsp;<i className="fa fa-info-circle" title={TextResources.Allocations.LinksDelayTooltip}></i>
                        <input className="form-control" data-bind={{ numberValue: linksSelector.LinksDelay, format: "0,0" }} />
                    </div>
                </div>
            </div>), this, "linksSelector");
    }
}

export class CartLinksSelectorUI {
    constructor(private props: CartLinksSelectorProps) {}

    render() {
        return <CartLinksSelector {...this.props} />;
    }
}