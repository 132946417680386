import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { EntityCodeGeneratorEditingViewModel, TextPartEditorViewModel, DatePartEditorViewModel, SequencePartEditorViewModel, TypePartEditorViewModel, ParentPartEditorViewModel, IdPartEditorViewModel, BasePartEditorViewModel, SecondTypePartEditorViewModel, SuperParentSequencePartEditorViewModel, ParentSequencePartEditorViewModel, TypeSequencePartEditorViewModel } from "../../../../ProlifeSdk/prolifesdk/settings/EntityCodeGeneratorEditingViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEntityCodeGeneratorSettingsManager, IEntityCodeGeneratorConfigPart } from "../../../../ProlifeSdk/interfaces/job-order/IEntityCodeGeneratorSettingsManager";

export class WorkflowEntityCodeGeneratorEditingViewModel extends EntityCodeGeneratorEditingViewModel implements IView
{
    templateName:string = "jobOrderEntityCodeGenerator";
    templateUrl:string = "jobOrder/templates/settings";
    viewModel:any;

    title : string = ProlifeSdk.TextResources.Todolist.WorkflowTypeCodes;

    constructor(serviceLocator : IServiceLocator, settingsManager : IEntityCodeGeneratorSettingsManager) {
        super(serviceLocator, settingsManager);
        this.viewModel = this;
    }

    protected configurePartTypes() {
        this.PartTypes([
            { Name: "Testo", CreateNew: () => new TextPartEditorViewModel("Testo", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Data", CreateNew: () => new DatePartEditorViewModel("Data", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Id Sequenziale", CreateNew: () => new SequencePartEditorViewModel("Id Sequenziale", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Id per Progetto", CreateNew: () => new ParentSequencePartEditorViewModel("Id per Progetto", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Codice Tipologia", CreateNew: () => new TypePartEditorViewModel("Codice Tipologia", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Codice Rendicontazione", CreateNew: () => new SecondTypePartEditorViewModel("Codice Rendicontazione", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Codice Progetto", CreateNew: () => new ParentPartEditorViewModel("Codice Progetto", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Id Univoco", CreateNew: () => new IdPartEditorViewModel("Id Univoco", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
            { Name: "Id Sequenziale per Tipologia", CreateNew: () => new TypeSequencePartEditorViewModel("Id Sequenziale per Tipologia", { Type: 0, Text: "", DatePart: 0, Padding: 1, NullPlaceholder: "", SequenceStart : 1 }, this.settingsManager) },
        ]);
    }

    protected createViewModelForPart(part : IEntityCodeGeneratorConfigPart) : BasePartEditorViewModel {
        switch(part.Type)
        {
            case 0: //Text
                return new TextPartEditorViewModel("Testo", part, this.settingsManager)
            case 1: //DatePart
                return new DatePartEditorViewModel("Data", part, this.settingsManager);
            case 2: //Sequence
                return new SequencePartEditorViewModel("Id Sequenziale", part, this.settingsManager);
            case 3: //TypeCode
                return new TypePartEditorViewModel("Codice Tipologia", part, this.settingsManager);
            case 4: //ParentCode
                return new ParentPartEditorViewModel("Codice Progetto", part, this.settingsManager);
            case 6: //Id
                return new IdPartEditorViewModel("Id Univoco", part, this.settingsManager);
            case 8: //ParentSequence
                return new ParentSequencePartEditorViewModel("Id per Progetto", part, this.settingsManager);
            case 10: //SecondTypeCode
                return new SecondTypePartEditorViewModel("Codice Rendicontazione", part, this.settingsManager);
            case 13: //TypeSequence
                return new TypeSequencePartEditorViewModel("Id Sequenziale per Tipologia", part, this.settingsManager);
            default:
                return null;
        }
    }
}
