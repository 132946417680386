import * as ko from "knockout";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { SurveyPanelViewModel } from "./ui/SurveyPanelViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { Deferred } from "../../Core/Deferred";

const css = require("./ui/css/survey.scss");

export class SurveyApplication implements IApplication{
    public templateName : string = 'survey-panel';
    public templateUrl : string = 'survey/templates/';
    public templateData : any;
    public tileColor : string = 'tile-grey';
    private mainPanel : SurveyPanelViewModel;

    private applicationsService : IApplicationsService;

    public navBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Survey";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Survey.SurveyApplication;
    }

    getIcon(): string
    {
        return "fa fa-list-alt";
    }

    registerRoutes() : void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl, (context) => this.openSplashPage());
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/:id", (context) => this.openQuestionnaire(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "/:id/Edit", (context) => this.editQuestionnaire(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/:id", (context) => this.openSample(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/:id/Edit", (context) => this.editSample(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.CampaignUrl + "/:id", (context) => this.openCampaign(context));

        var regExp: RegExp = new RegExp("#\/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "\/" + ProlifeSdk.TextResources.Survey.QuestionnaireUrl + "\/([0-9]+)\/Edit");
        opaService.BeforeLeave(<any>regExp, () => {
            return this.onUrlChangingOnEditor();
        });

        regExp = new RegExp("#\/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "\/" + ProlifeSdk.TextResources.Survey.SampleUrl + "\/([0-9]+)\/Edit");
        opaService.BeforeLeave(<any>regExp, () => {
            return this.onUrlChangingOnEditor();
        });
    }

    onUrlChangingOnEditor(): boolean {
        if (!this.templateData.CurrentViewModel().ShowPrompt())
            return true;

        return confirm(ProlifeSdk.TextResources.Survey.UnsavedChanges);
    }

    getApplicationRoute() : string
    {
        return "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    onGoingDown() : void
    {
        if(this.mainPanel)
            this.mainPanel.dispose();

        this.mainPanel = null;
    }

    private initializePanel() : Promise<void>
    {
        var def = new Deferred<void>();

        if(this.mainPanel && !this.mainPanel.Reset)
        {
            def.resolve();
            return def.promise();
        }

        if (!this.mainPanel)
            this.mainPanel = new SurveyPanelViewModel(this.serviceLocator, this);
        
        this.mainPanel.initialize()
            .then(() => {
                this.templateData = this.mainPanel;

                this.applicationsService.startApplication(this);
                def.resolve();

            });

        return def.promise();
    }

    private openSplashPage()
    {
        this.initializePanel().then(() => {
            this.mainPanel.OpenSplashPage();
        });
    }

    private openSample(context : any) : void
    {
        var sampleId = parseInt(context.params["id"]);

        this.initializePanel()
            .then(() => {
                if (sampleId > 0) {
                    this.mainPanel.Reset = true;
                    this.mainPanel.OpenSample(sampleId);
                }
        })
    }

    private editSample(context : any) : void
    {
        var sampleId = parseInt(context.params["id"]);

        this.initializePanel()
            .then(() => {
                if (sampleId > 0)
                    this.mainPanel.EditSample(sampleId);
            })
    }

    private openQuestionnaire(context: any): void {
        var questionnaireId: number = parseInt(context.params["id"]);
        this.initializePanel()
            .then(() => {
                if (questionnaireId > 0) {
                    this.mainPanel.Reset = true;
                    this.mainPanel.OpenQuestionnaire(questionnaireId);
                }
            });
    }

    private editQuestionnaire(context: any): void {
        var questionnaireId: number = parseInt(context.params["id"]);
        this.initializePanel()
            .then(() => {
                if (questionnaireId > 0)
                    this.mainPanel.EditQuestionnaire(questionnaireId);
            });
    }

    private openCampaign(context: any): void {
        var campaignId: number = parseInt(context.params["id"]);
        this.initializePanel()
            .then(() => {
                if (campaignId > 0) {
                    this.mainPanel.Reset = true;
                    this.mainPanel.OpenCampaign(campaignId);
                }
            });
    }
}

