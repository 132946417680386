import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ReportsViewer } from "./ReportsViewer";
import { ReportsMenu } from "./ReportsMenu";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IReportsService } from "../../../ProlifeSdk/interfaces/report/IReportsService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";

export class ReportsViewModel
{
    templateUrl : string = "reports/templates";

    public menu : ReportsMenu;
    public reportSection : ko.Observable<any> = ko.observable();
    private reportsService : IReportsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.reportSection(new ReportsViewer());
        this.menu = new ReportsMenu(this.serviceLocator, this.reportSection());
        this.reportsService = <IReportsService> this.serviceLocator.findService(ProlifeSdk.ReportsServiceType);
    }

    openReport(providerId : number, reportId : number)
    {
        var reportLoaded : boolean = false;
        var loadAttempts : number = 0;
        this.LoadReport(providerId, reportId, 1);
    }

    private LoadReport(providerId : number, reportId : number, loadAttempt : number)
    {
        var reportToShow : IReport = null;
        var provider : IReportsProvider = null;

        this.reportsService.getRegisteredReportsProviders().forEach(p => {
            provider = p.Id == providerId ? p : provider;
        });

        if(!provider)
        {
            //TODO: E' stato introdotto per via dei problemi di inizializzazione di ProLife, da rimuovere appena ristrutturiamo il sistema
            if(loadAttempt < 10)
                setTimeout(() => {
                    this.LoadReport(providerId, reportId, loadAttempt+1);
                }, 500);
            return;
        }

        provider.initialize();
        reportToShow = provider.getReportById(reportId);
        reportToShow.initialize();

        this.menu.navigateTo(provider);
        this.reportSection().showReport(reportToShow);
    }

    goHome()
    {
        location.href = "#/";
    }

    dispose()
    {
        var section = this.reportSection();

        if(section && section.dispose)
            section.dispose();
    }
}
