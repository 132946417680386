import * as ko from "knockout";
import * as moment from "moment";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IExpenseStatusesSettingsManager } from "../settings/ExpenseStatusesSettingsManager";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IExpensesWithProjectsProjects, IExpensesWithProjectsExpenses } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";

export class ExpenseWithProjects
{
    public get Id(): number {
        return this.expense.Id;
    }

    public ProtocolId : ko.Observable<number> = ko.observable(0);
    public ProtocolYear : ko.Observable<number> = ko.observable(0);
    public Total : ko.Observable<number> = ko.observable(0);
    public From : ko.Observable<Date> = ko.observable();
    public To : ko.Observable<Date> = ko.observable();
    public Place : ko.Observable<string> = ko.observable();
    public Motive : ko.Observable<string> = ko.observable();
    public Notes : ko.Observable<string> = ko.observable('');
    public StatusLabel : ko.Observable<string> = ko.observable();
    public Projects : ko.ObservableArray<IExpensesWithProjectsProjects> = ko.observableArray([]);

    public Duration : ko.Computed<number>;
    public DurationLabel: ko.Computed<string>;
    public DateLabel: ko.Computed<string>;

    public WorkerName : ko.Observable<string> = ko.observable("");
    public TotalPercentage : ko.Computed<number>;

    @LazyImportSettingManager(nameof<IExpenseStatusesSettingsManager>())
    private expenseStatusesManager: IExpenseStatusesSettingsManager;

    constructor(private expense: IExpensesWithProjectsExpenses, private projects: IExpensesWithProjectsProjects[])
    {
        this.TotalPercentage = ko.computed(() => {
            var total = 0;
            this.Projects().forEach((p : IExpensesWithProjectsProjects) => total += p.Percentage);
            return total;
        });

        this.Duration = ko.computed(() => {
            if (!this.From() || !this.To())
                return 1;

            return moment(this.To()).diff(moment(this.From()), 'days') + 1;
        });

        this.DurationLabel = ko.computed(() => {
            return String.format(TextResources.Expenses.ExpenseDuration, this.Duration());
        });

        this.DateLabel = ko.computed(() => {
            return this.Duration() === 1 ? moment(this.From()).format("L") : String.format(TextResources.Expenses.ExpenseDateLabel, moment(this.From()).format("L"), moment(this.To()).format("L"));
        });

        this.ProtocolId(this.expense.ProtocolId);
        this.ProtocolYear(this.expense.ProtocolYear);
        this.Total(this.expense.Total);
        this.From(this.expense.From);
        this.To(this.expense.To);
        this.Notes(this.expense.Notes);
        this.Projects(this.projects);
        this.Place(this.expense.Place);
        this.Motive(this.expense.Motive);
        this.WorkerName(this.expense.ResourceName);

        let status = this.expenseStatusesManager.getStatusById(this.expense.FkExpenseStatus);
        this.StatusLabel(status?.Label);
    }
}