/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/06/2018
 * Time: 18:24
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ImportMonthlyInvoicingDataWizardInvoicingBaseStep } from "./ImportMonthlyInvoicingDataWizardInvoicingBaseStep";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { Invoice } from "./Invoice";
import { IMonthlyInvoicingDataSource } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IInvoiceForMonthlyInvoicingWrapper } from "../../../../interfaces/IInvoiceForMonthlyInvoicingWrapper";
import { IDocumentCurrencyViewModel } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IEntityToImportInfoForMonthlyInvoicing } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IInvoiceForMonthlyInvoicingWizard } from "../../../../interfaces/IInvoiceForMonthlyInvoicingWizard";
import { IEntityForMonthlyInvoicingTree } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IInvoiceForLabelsList } from "../../../../interfaces/IInvoiceForLabelsList";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";

export interface IPartialInvoiceCurrency {
    DocumentCurrency: IDocumentCurrencyViewModel;
    PartialInvoice: string;
}

export class ImportMonthlyInvoicingDataWizardInvoicingStep
    extends ImportMonthlyInvoicingDataWizardInvoicingBaseStep
    implements IInvoiceForMonthlyInvoicingWrapper
{
    public AggregatesRows: ko.Observable<boolean> = ko.observable(false);
    public InvoicesDate: ko.Observable<Date> = ko.observable();
    public IsLastStep: ko.Computed<boolean>;

    private ddts: IEntityToImportInfoForMonthlyInvoicing[] = [];

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(
        private destinationEntityTypeCode: string,
        dataSource: IMonthlyInvoicingDataSource,
        initializationInfo: IWizardInitializationInfo
    ) {
        super(
            "import-monthly-invoicing-data-wizard-invoicing-step",
            "invoices/templates/wizard/steps",
            ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardInvoicingFirstStepTitle,
            dataSource,
            initializationInfo
        );

        this.InvoicesDate(moment().toDate());

        this.PartialInvoicing.subscribe((value: boolean) => {
            if (!value) this.SelectedInvoices([]);

            if (value)
                this.EntitiesList().forEach((e: IEntityToImportInfoForMonthlyInvoicing) =>
                    e.GenerateLabel(this.LabelsMap, 1)
                );
        });

        this.IsLastStep = ko.computed(() => {
            return !this.PartialInvoicing();
        });
    }

    public async BeforeShow() {
        super.BeforeShow();

        const entities: IEntityToImportInfoForMonthlyInvoicing[][] = this.dataSource.GetEntitiesToImport(this);
        this.ddts = <IEntityToImportInfoForMonthlyInvoicing[]>entities[entities.length - 1];
        const invoices: IInvoiceForMonthlyInvoicingWizard[] = [];

        this.IncludePdfAsAttachmentOnElectronicInvoicingExport(
            this.dataSource.ImportData.IncludePdfAsAttachmentOnElectronicInvoicingExport
        );
        this.PartialInvoicing(this.dataSource.ImportData.WithPartialInvoicingStep);
        this.initialiPartialInvoicingSetting = this.PartialInvoicing();

        for (const d of this.ddts) {
            if (
                invoices.filter((i: IInvoiceForMonthlyInvoicingWizard) =>
                    i.MatchByGroupLabel(d.LabelForInvoiceGrouping())
                ).length != 0
            )
                continue;

            const invoice = this.createInvoiceViewModel(
                d.LabelForInvoiceGrouping(),
                this.ddts.filter((ddt: IEntityToImportInfoForMonthlyInvoicing) =>
                    d.HasLabel(ddt.LabelForInvoiceGrouping())
                )
            );
            await invoice.Initialize();
            invoice.storage(this);
            if (this.PartialInvoicing()) invoice.GenerateLabel(this.LabelsMap, 1);
            invoice.Selected(false);
            invoices.push(invoice);
        }

        this.EntitiesList(invoices);
        this.SelectedEntities([]);
    }

    public BeforeNext(): boolean {
        this.dataSource.ImportData.AggregatesImportedDocumentsRows = this.AggregatesRows();
        this.dataSource.ImportData.IncludePdfAsAttachmentOnElectronicInvoicingExport =
            this.IncludePdfAsAttachmentOnElectronicInvoicingExport();
        this.dataSource.ImportData.InvoicesDate = this.InvoicesDate();

        return this.isValid();
    }

    public RegenerateLabels(): void {
        this.LabelsMap = {};
        this.EntitiesList().forEach((e: IEntityToImportInfoForMonthlyInvoicing) => e.GenerateLabel(this.LabelsMap, 1));
    }

    public async GetDocumentsToImportInfo(): Promise<IInvoiceForMonthlyInvoicingWizard[]> {
        const entities = this.EntitiesList();
        const invoices: IInvoiceForMonthlyInvoicingWizard[] = [];

        if (this.PartialInvoicing()) {
            const finalInvoices = this.InvoicesToProduce();
            for (const finalInvoice of finalInvoices) {
                const finalInvoiceData = await finalInvoice.AsInvoiceForMonthlyInvoicingWizard(
                    entities.filter(
                        (pi) =>
                            (pi.LabelForInvoiceGrouping() || "").toUpperCase() ===
                            (finalInvoice.label || "").toUpperCase()
                    )
                );
                invoices.push(finalInvoiceData);
            }

            return invoices;
        }

        for (const d of entities) {
            const partialOrFinalInvoice = (await d.GetCopy()) as IInvoiceForMonthlyInvoicingWizard;
            invoices.push(partialOrFinalInvoice);
        }

        return invoices;
    }

    public async getDocumentsToGenerate(): Promise<IEntityForMonthlyInvoicingTree[]> {
        const invoices: IInvoiceForMonthlyInvoicingWizard[] = await this.GetDocumentsToImportInfo();
        invoices.sort((a: IInvoiceForMonthlyInvoicingWizard, b: IInvoiceForMonthlyInvoicingWizard) =>
            a.Name < b.Name ? -1 : a.Name == b.Name ? 0 : 1
        );

        const documentsTree: IEntityForMonthlyInvoicingTree[] = [];
        let entities: IEntityForMonthlyInvoicingTree[] = [];
        let lastInvoice: IInvoiceForMonthlyInvoicingWizard = invoices[0];

        invoices.forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
            if (i.Name != lastInvoice.Name) {
                documentsTree.push(lastInvoice.GetDocumentForMonthlyInvoicingTree(entities));
                entities = [];
                lastInvoice = i;
            }

            i.entities.forEach((d: IEntityToImportInfoForMonthlyInvoicing) => {
                entities.push(d.GetDocumentForMonthlyInvoicingTree([]));
            });
        });

        documentsTree.push(lastInvoice.GetDocumentForMonthlyInvoicingTree(entities));

        return documentsTree;
    }

    private createInvoiceViewModel(
        groupLabel: string,
        entities: IEntityToImportInfoForMonthlyInvoicing[]
    ): IInvoiceForMonthlyInvoicingWizard {
        return new Invoice(groupLabel, this.PartialInvoicing(), entities, this.dataSource);
    }

    private isValid(): boolean {
        return this.PartialInvoicing() ? this.validateForPartialInvoicing() : this.validateForFinalInvoicing();
    }

    private validateForFinalInvoicing(): boolean {
        let isValid = true;
        isValid = isValid && this.allDocumentsHasVatRegisterForFinalInvoicingValidation();
        isValid = isValid && this.allDocumentsHasExpire();
        isValid = isValid && this.allDocumentsHasPaymentMode();
        isValid = isValid && this.allDocumentsHasValidCurrencies();
        return isValid;
    }

    private allDocumentsHasValidCurrencies(): boolean {
        const errors: string[] = [];
        const partialInvoicing = this.PartialInvoicing();

        const entitiesList = this.EntitiesList();
        for (const entity of entitiesList) {
            const currencies = entity.DocumentCurrencies();

            for (const currency of currencies) {
                if (!currency.ExchangeValue() || (currency.IsDocumentCurrency() && !currency.ExchangeValueForVat())) {
                    const invoiceType = partialInvoicing
                        ? TextResources.Invoices.MonthlyInvoicingDocumentCurrenciesErrorsPartialInvoice
                        : TextResources.Invoices.MonthlyInvoicingDocumentCurrenciesErrorsInvoice;
                    const currencyCode = currency.Currency().CodeISO4217alpha3;
                    errors.push(
                        String.format(
                            TextResources.Invoices.MonthlyInvoicingDocumentCurrenciesErrors,
                            invoiceType,
                            entity.Name,
                            currencyCode
                        )
                    );
                }
            }
        }

        if (errors.length > 0) {
            const message = errors.join("<br/>");
            this.infoToastService.Warning(message);
        }

        return errors.length === 0;
    }

    private allInvoicesToProduceHasValidCurrencies(): boolean {
        const errors: string[] = [];

        const partialInvoicesList = this.EntitiesList();
        const invoicesList = this.InvoicesToProduce();
        for (const invoice of invoicesList) {
            const currencies = invoice.DocumentCurrencies();

            for (const currency of currencies) {
                if (!currency.ExchangeValue() || (currency.IsDocumentCurrency() && !currency.ExchangeValueForVat())) {
                    const currencyCode = currency.Currency().CodeISO4217alpha3;
                    errors.push(
                        String.format(
                            TextResources.Invoices.MonthlyInvoicingDocumentCurrenciesErrors,
                            TextResources.Invoices.MonthlyInvoicingDocumentCurrenciesErrorsInvoice,
                            invoice.label,
                            currencyCode
                        )
                    );
                }
            }

            const partialInvoicesCurrencies: IPartialInvoiceCurrency[] = partialInvoicesList
                .filter((i) => i.LabelForInvoiceGrouping() === invoice.label)
                .map((pi) => {
                    return {
                        DocumentCurrency: pi.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency()),
                        PartialInvoice: pi.Name,
                    };
                });

            const invoiceCurrenciesCodes = currencies.map((ic) => ic.Currency().CodeISO4217alpha3);

            for (const partialInvoiceCurrency of partialInvoicesCurrencies) {
                // Controllo che sulla fattura finale siano specificati i cambi per tutte le valute delle fatture parziali collegate
                const partialInvoiceCurrencyCode = partialInvoiceCurrency.DocumentCurrency.Currency().CodeISO4217alpha3;
                if (invoiceCurrenciesCodes.indexOf(partialInvoiceCurrencyCode) < 0) {
                    errors.push(
                        TextResources.Invoices.MonthlyInvoicingMissingCurrencyOnInvoice,
                        invoice.label,
                        partialInvoiceCurrencyCode,
                        partialInvoiceCurrency.PartialInvoice
                    );
                }
            }
        }

        if (errors.length > 0) {
            const message = errors.join("<br/>");
            this.infoToastService.Warning(message);
        }

        return errors.length === 0;
    }

    private allDocumentsHasPaymentMode(): boolean {
        const errors: string[] = [];

        this.EntitiesList().forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
            if (!i.HasValidPayment()) errors.push(i.Name);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.DocumentPaymentRequiredForMonthlyInvoicing, message)
            );
        }

        return errors.length == 0;
    }

    private allDocumentsHasExpire(): boolean {
        const errors: string[] = [];

        this.EntitiesList().forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
            if (!i.HasValidExpiry()) errors.push(i.Name);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.DocumentExpireRequiredForMonthlyInvoicing, message)
            );
        }

        return errors.length == 0;
    }

    private allDocumentsHasVatRegisterForFinalInvoicingValidation(): boolean {
        const errors: string[] = [];

        this.EntitiesList().forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
            if (!i.VatRegisterForFinalInvoicing()) errors.push(i.Name);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.VatRegisterRequiredForMonthlyInvoicing, message)
            );
        }

        return errors.length == 0;
    }

    private validateForPartialInvoicing(): boolean {
        let isValid = true;
        isValid = isValid && this.allDocumentsHasValidLabel();
        isValid = isValid && this.allDocumentsHasVatRegisterForPartialInvoicingValidation();
        isValid = isValid && this.allDocumentsGroupHasExpire();
        isValid = isValid && this.allDocumentsGroupHasPayment();
        isValid = isValid && this.allDocumentsHasValidCurrencies();
        isValid = isValid && this.allInvoicesToProduceHasValidCurrencies();
        isValid = isValid && !this.hasGroupsWithInconsistentData();
        return isValid;
    }

    private allDocumentsHasValidLabel(): boolean {
        if (this.HasEntitiesWithoutLabel()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.MisssingLabels);
            return false;
        }

        return true;
    }

    private allDocumentsHasVatRegisterForPartialInvoicingValidation(): boolean {
        const errors: string[] = [];

        this.EntitiesList().forEach((i: IInvoiceForMonthlyInvoicingWizard) => {
            if (!i.VatRegisterForPartialInvoicing()) errors.push(i.Name);
        });

        this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
            if (!i.VatRegister()) errors.push(i.label);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.VatRegisterRequiredForMonthlyInvoicing, message)
            );
        }

        return errors.length == 0;
    }

    private allDocumentsGroupHasExpire(): boolean {
        const errors: string[] = [];

        this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
            const partialInvoices: IInvoiceForMonthlyInvoicingWizard[] = <IInvoiceForMonthlyInvoicingWizard[]>(
                this.EntitiesList().filter(
                    (pi) =>
                        (!pi.LabelForInvoiceGrouping() ? "" : pi.LabelForInvoiceGrouping().toUpperCase()) ==
                        (!i.label ? "" : i.label.toUpperCase())
                )
            );
            if (partialInvoices.filter((pi) => !pi.HasValidExpiry()).length > 0) errors.push(i.label);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.DocumentExpireRequiredForPartialInvoices, message)
            );
        }

        return errors.length == 0;
    }

    private allDocumentsGroupHasPayment(): boolean {
        const errors: string[] = [];

        this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
            const partialInvoices: IInvoiceForMonthlyInvoicingWizard[] = <IInvoiceForMonthlyInvoicingWizard[]>(
                this.EntitiesList().filter(
                    (pi) =>
                        (!pi.LabelForInvoiceGrouping() ? "" : pi.LabelForInvoiceGrouping().toUpperCase()) ==
                        (!i.label ? "" : i.label.toUpperCase())
                )
            );
            if (partialInvoices.filter((pi) => !pi.HasValidPayment()).length > 0) errors.push(i.label);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.DocumentPaymentRequiredForPartialInvoices, message)
            );
        }

        return errors.length == 0;
    }

    private hasGroupsWithInconsistentData(): boolean {
        const errors: string[] = [];

        this.InvoicesToProduce().forEach((i: IInvoiceForLabelsList) => {
            if (i.HasInconsistentData()) errors.push(i.label);
        });

        if (errors.length > 0) {
            let message = "<br/><br/>";
            errors.forEach((e: string) => (message += e + "<br/>"));
            message += "<br/>";

            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.InvoicesGroupsWithInconsistentDataError, message)
            );
        }

        return errors.length > 0;
    }
}
