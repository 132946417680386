import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 17.59
 * To change this template use File | Settings | File Templates.
 */

import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ISettingsViewModel } from "../../interfaces/ISettingsViewModel";

export class SettingsManagerViewModel {
    public title : string;
    public Active : ko.Computed<boolean>;

    constructor(private settingsManager : ISettingsManager, private settings : ISettingsViewModel) {
        this.title = settingsManager.getLabel();

        this.Active = ko.computed(() => {
            return settings.selectedManager() == this.settingsManager;
        });
    }

    public edit() {
        this.settings.setEditingView(this.settingsManager);
    }
}