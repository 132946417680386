import { ReportComponent } from "./ReportComponent";

type ComponentConstructor<T extends ReportComponent = ReportComponent> = new (...args: any[]) => T;

type ComponentsMap = {
    [componentType: string]: ComponentConstructor;
}

class _ReportComponentProvider {
    private components : ComponentsMap = {}

    public registerComponent(componentType: string, factory: ComponentConstructor) {
        this.components[componentType] = factory;
    }

    public createComponent(componentData : { type: string }) : ReportComponent {
        return new this.components[componentData.type](componentData);
    }
}

export const ReportComponentProvider = new _ReportComponentProvider();

export function Component(componentType : string) : ClassDecorator {
    return (constructor : any) => {
        ReportComponentProvider.registerComponent(componentType, constructor);
    }
}