import * as ko from "knockout";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SplashPage } from "./SplashPage";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { EntitiesListNotificationsManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListNotificationsManager";
import { SplashPageNotificationsManagerAdapter } from "./SplashPageNotificationsManagerAdapter";
import "./SplashPageDetailsComponent";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderForSplash } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderState } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderRelatedElementsNumbers } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderRelatedElementsNumbers";
import { SplashPageDetailsDialog } from "./SplashPageDetailsComponent";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class SplashPageList {
    public templateName = "splashPageList";
    public templateUrl = "joborder/templates";

    public JobOrders: ko.ObservableArray<JobOrderForSplash> = ko.observableArray([]);

    private LockManager: EntitiesListLockManager;
    private NotificationsManager: EntitiesListNotificationsManager;

    private desktopService: IDesktopService;
    private jobOrdersService: IJobOrderService;
    private toastService: IInfoToastService;

    private loadingNextBlock = false;

    constructor(private serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.jobOrdersService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        this.LockManager = new EntitiesListLockManager(this.JobOrders, ProlifeSdk.JobOrderEntityTypeCode);

        this.NotificationsManager = new EntitiesListNotificationsManager(
            new SplashPageNotificationsManagerAdapter(
                serviceLocator,
                this.parent.GetFilters.bind(this.parent),
                this.JobOrders,
                this.CreateViewModelFor.bind(this)
            )
        );

        this.Refresh();
    }

    public dispose() {
        this.NotificationsManager.Dispose();
        this.LockManager.Dispose();
    }

    Refresh() {
        this.JobOrders([]);
        this.LoadNextBlock();
        this.LoadCount();
    }

    LoadNextBlock() {
        if (this.loadingNextBlock) return;

        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.JobOrder.Loading);
        this.loadingNextBlock = true;

        //Prima di caricare i dati aspetto che il lock manager sia pronto...
        this.LockManager.WhenIsReady().then(() => {
            //var filters : IItemIdWithTypeAndGroupCode[] = this.HintSearchProvider.extractItemsAndTypesFromStringValue(this.SearchFilters());
            //var words : string[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "$$HINTWORD$$"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
            //var responsiblesIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "R"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
            //var customersIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "C"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
            //var statesIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "S"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );

            this.jobOrdersService
                .GetJobOrdersForSplash(this.parent.GetFilters(), this.JobOrders().length, 30)
                .then((jobOrders: IJobOrderForSplash[]) => {
                    const newList = this.JobOrders();
                    jobOrders
                        .map((j: IJobOrderForSplash) => {
                            return this.CreateViewModelFor(j);
                        })
                        .forEach((j) => {
                            newList.push(j);
                        });
                    this.JobOrders(newList);
                })
                .finally(() => {
                    this.desktopService.UnblockPageUI();
                    this.loadingNextBlock = false;
                });
        });
    }

    LoadCount() {
        this.jobOrdersService.GetJobOrdersForSplashCount(this.parent.GetFilters()).then((count: number) => {
            this.parent.JobOrdersCount(count);
        });
    }

    private CreateViewModelFor(j: IJobOrderForSplash) {
        return new JobOrderForSplash(j, this);
    }

    SaveJobOrder(jobOrder: JobOrderForSplash) {
        this.jobOrdersService
            .UpdateFromSplash(jobOrder.jobOrder, this.parent.GetFilters())
            .then((matchWithFilters: boolean) => {
                this.toastService.Success(ProlifeSdk.TextResources.JobOrder.UpdateCompleted);

                //All'aggiornamento sulla vista ci pensa il notification manager...
            });
    }
}

export class JobOrderForSplash implements ILockableItem {
    private statesManager: IJobOrderStateSettingsManager;
    private typesManager: IJobOrderTypesSettingsManager;

    public State: ko.Observable<any> = ko.observable();
    public StateDescription: ko.Computed<string>;
    public States: IJobOrderState[];

    public Type: ko.Observable<any> = ko.observable();
    public TypeDescription: ko.Computed<string>;
    public Types: IJobOrderType[];

    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

    public ShowAlerts: ko.Observable<boolean> = ko.observable();

    public DetailsUrl: ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    private isChangingState = false;
    private lastState: IJobOrderState;

    constructor(public jobOrder: IJobOrderForSplash, private parent: SplashPageList) {
        this.ItemKey = jobOrder.JobOrderId;

        this.statesManager = <IJobOrderStateSettingsManager>(
            this.settingsService.findSettingsManager(ProlifeSdk.JobOrderState)
        );
        this.typesManager = <IJobOrderTypesSettingsManager>(
            this.settingsService.findSettingsManager(ProlifeSdk.JobOrderType)
        );

        this.States = this.statesManager.getJobOrderStates();
        this.State(this.statesManager.getJobOrderStateById(jobOrder.StateId));
        this.StateDescription = ko.computed(() => {
            return this.State() ? this.State().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });
        this.lastState = this.State();

        this.Types = this.typesManager.getVisibleJobOrderTypes();

        this.Type(this.typesManager.getJobOrderTypeById(jobOrder.TypeId));
        this.TypeDescription = ko.computed(() => {
            return this.Type() ? this.Type().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });

        this.DetailsUrl("#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + this.jobOrder.JobOrderId);

        this.ShowAlerts(
            !jobOrder.IsReady ||
                !jobOrder.HasWarehouse ||
                !jobOrder.HasPurchases ||
                !jobOrder.HasResources ||
                jobOrder.HasReportingTasks ||
                jobOrder.HoursToBeBilled > 0 ||
                !jobOrder.HasEstimatedWork ||
                jobOrder.WorkflowMustBePlanned ||
                jobOrder.ActivitiesMustBeReviewed ||
                jobOrder.WorkflowsMustBeRelatedToCustomerOrders
        );
    }

    public OpenJobOrder() {
        location.href = this.DetailsUrl();
    }

    public OpenDetailedInfo(viewModel: any, e: Event): Promise<void> {
        return this.dialogsService.ShowModal(
            new SplashPageDetailsDialog({
                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, this.jobOrder.Name),
                JobOrderId: this.jobOrder.JobOrderId,
            })
        );
    }

    public async SetState(state: IJobOrderState): Promise<void> {
        if (this.isChangingState) return;

        this.isChangingState = true;
        this.State(state);

        let confirm = true;

        if (state.LogicalState == ProlifeSdk.JobOrderClosedLogicalState)
            confirm = await this.jobOrderService.CheckOpenedTasksWorkedHoursAndDocuments(this.jobOrder.JobOrderId);

        if (confirm) this.OnStateChanged();
        else this.State(this.lastState);

        setTimeout(() => (this.isChangingState = false), 400);
    }

    public SetType(type: IJobOrderType) {
        this.Type(type);
        this.SaveChanges();
    }

    private OnStateChanged(): void {
        this.lastState = this.State();
        this.SaveChanges();
        setTimeout(() => (this.isChangingState = false), 400);
    }

    private SaveChanges() {
        this.jobOrder.StateId = this.State().IdJobOrderStateId;
        this.jobOrder.TypeId = this.Type().Id;
        this.parent.SaveJobOrder(this);
    }
}
