import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/09/2018
 * Time: 11:09
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITrustAuthorizationProcessService } from "../../../TrustAuthorizationProcessService";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IRequestStatesMap, ITrustAuthorizationRequest } from "../../../../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";

export class TrustAuthorizationAbortDialog implements IDialog {
    public templateName: string = "trust-authorization-abort-dialog";
    public templateUrl: string = "invoices/templates/customertrust";
    public title: string = ProlifeSdk.TextResources.Invoices.CustomerTrustAuthorizationTitle;

    public modal: { close: (result?: any) => void; };

    private requestStatesMap: IRequestStatesMap = {};

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    constructor(public authorizationRequest: ITrustAuthorizationRequest) {
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestIgnored] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestIgnoredLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestDenied] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestDeniedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestOnEvaluation] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestOnEvaluationLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestAccepted] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAcceptedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSendingInProgress] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSendingInProgressLabel;

        this.authorizationRequest.CustomerTrustBalanceAfterDocumentIssue = this.authorizationRequest.CustomerTrustBalanceAfterDocumentIssue * -1;
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.trustAuthorizationProcessService.AbortAuthorizationRequest(this.authorizationRequest.Id, [])
            .then(() => { this.modal.close(); });
    }

    show(): Promise<void> {
        return  this.dialogsService.ShowModal<void>(this, null, null, this.templateUrl, this.templateName);
    }
}