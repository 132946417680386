import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import moment = require("moment");
import { BaseDataSource } from "./BaseDataSource";
import { IGetPreOrdersRequest, IPreOrder, IProvisioningService } from "../Provisioning/ProvisioningService";
import { IDataSourceModel } from "./IDataSource";

export interface IPreOrdersDataSourceModel extends IDataSourceModel<number, IPreOrder> {
    NoteVisible: ko.Observable<boolean>;
}

export class PreOrdersDataSource extends BaseDataSource<IPreOrdersDataSourceModel> {
    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    private filter : Omit<IGetPreOrdersRequest, "TextFilters" | "Skip" | "Count"> = {};

    public setFilter(filter : Partial<Omit<IGetPreOrdersRequest, "TextFilters" | "Skip" | "Count">>) {
        this.filter = Object.assign({}, this.filter, filter);
    }

    public resetFilter() {
        this.filter = {};
    }

    public getTitle(currentModel: IPreOrdersDataSourceModel): string {
        return ProlifeSdk.TextResources.Provisioning.PreOrders;
    }
    
    public async getData(currentModel: IPreOrdersDataSourceModel, textFilter: string, skip: number = 0, count: number = 10000): Promise<IPreOrdersDataSourceModel[]> {
        let preOrders: IPreOrder[] = await this.provisioningService.GetPreOrders({
            ...this.filter,
            TextFilters: textFilter, 
            Skip: skip, 
            Count: count
        });
        return preOrders.map(this.createDataSourceModel, this);
    }
    
    public async getById(currentModel: IPreOrdersDataSourceModel, ids: number[]): Promise<IPreOrdersDataSourceModel[]> {
        let preOrders: IPreOrder[] = await this.provisioningService.GetPreOrdersByIds(ids);
        return preOrders.map(this.createDataSourceModel, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
    
    public onItemBeginMove(model: IPreOrdersDataSourceModel, dataTransfer: DataTransfer): void {
        
    }
    
    public async onItemMoved(dataTransfer: DataTransfer, model: IPreOrdersDataSourceModel, before: boolean): Promise<void> {
        
    }

    private createDataSourceModel(preOrder: IPreOrder): IPreOrdersDataSourceModel {
        return {
            id: preOrder.Id,
            title: String.format(ProlifeSdk.TextResources.Provisioning.PreOrderLabel, preOrder.Number, moment(preOrder.CreationDate).format("L")),
            isGroup: false,
            isLeaf: true,
            model: preOrder,
            NoteVisible: ko.observable(false)
        };
    }
}