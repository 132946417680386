import {
    IAccountingSoftwareRoute,
    IAccountingSoftwareRouteNew,
    IFileTagForProtocol,
    IFileTagForProtocolNew,
    IProtocolDefaultMetadatas,
    IProtocolDefaultMetadatasNew,
    IProtocolDefaultValueForProtocol,
    IProtocolDefaultValueForProtocolNew,
    ITagForProtocol,
    ITagForProtocolNew,
    IVatRegisterNew,
    IVatRegisterWithPrintTemplates,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class VatRegistersMapper {
    public static mapToOldVatRegister(vatRegister: IVatRegisterNew): IVatRegisterWithPrintTemplates {
        return {
            IdRegistroIVA: vatRegister.idRegistroIva,
            FKCompany: vatRegister.fkCompany,
            NomeRegistroIVA: vatRegister.nomeRegistroIVA,
            Formato: vatRegister.formato,
            TipoDocumento: vatRegister.tipoDocumento,
            NuovoLabel: vatRegister.nuovoLabel,
            Prefisso: vatRegister.prefisso,
            Suffisso: vatRegister.suffisso,
            Stato: vatRegister.stato,
            TipiFatture: vatRegister.tipiFatture,
            DefaultDocument: vatRegister.defaultDocument,
            DocumentoFiscale: vatRegister.documentoFiscale,
            Posizione: vatRegister.posizione,
            VisualizzaImporti: vatRegister.visualizzaImporti,
            DescContributoSuImponibileIVA: vatRegister.descContributoSuImponibileIVA,
            ContributoSuImponibileIVA: vatRegister.contributoSuImponibileIVA,
            DescContributoSuCompenso: vatRegister.descContributoSuCompenso,
            ContributoSuCompenso: vatRegister.contributoSuCompenso,
            HtmlFooter: vatRegister.htmlFooter,
            Logo: vatRegister.logo,
            HeaderImage: vatRegister.headerImage,
            DefaultLayoutId: vatRegister.defaultLayoutId,
            DefaultJobOrderFilteredByCustomer: vatRegister.defaultJobOrderFilteredByCustomer,
            DdtCanBeImportedToShippingInvoice: vatRegister.ddtCanBeImportedToShippingInvoice,
            InvoiceCanBeImportedToInvoice: vatRegister.invoiceCanBeImportedToInvoice,
            IsInventoryAdjustmentProtocol: vatRegister.isInventoryAdjustmentProtocol,
            ShowResourcesTable: vatRegister.showResourcesTable,
            CFRequired: vatRegister.cfRequired,
            DateSequenceRequired: vatRegister.dateSequenceRequired,
            PaymentAndExpireModeRequired: vatRegister.paymentAndExpireModeRequired,
            ValidityRequired: vatRegister.validityRequired,
            ImportCalculatedExpiriesIntoDocumentNotes: vatRegister.importCalculatedExpiriesIntoDocumentNotes,
            ExpiresIntoNotesPrefix: vatRegister.expiresIntoNotesPrefix,
            ExpiresIntoNotesSuffix: vatRegister.expiresIntoNotesSuffix,
            ForPartialInvoices: vatRegister.forPartialInvoices,
            StampDutyValue: vatRegister.stampDutyValue,
            StampDuty: vatRegister.stampDuty,
            InvoiceLabel: vatRegister.invoiceLabel,
            CreditNoteLabel: vatRegister.creditNoteLabel,
            AccompanyingInvoiceLabel: vatRegister.accompanyingInvoiceLabel,
            SalInvoiceLabel: vatRegister.salInvoiceLabel,
            GroupLabel: vatRegister.groupLabel,
            PositionInList: vatRegister.positionInList,
            AccountingSoftwareCode: vatRegister.accountingSoftwareCode,
            Notes: vatRegister.notes,
            InsertJobOrderIntoNotes: vatRegister.insertJobOrderIntoNotes,
            InsertOriginatingDocumentsIntoNotes: vatRegister.insertOriginatingDocumentsIntoNotes,
            JobOrderIntoNotesPrefix: vatRegister.jobOrderIntoNotesPrefix,
            DocumentIntoNotesPrefix: vatRegister.documentIntoNotesPrefix,
            DefaultSearchProvider: vatRegister.defaultSearchProvider,
            ShowAllCustomersAndSuppliers: vatRegister.showAllCustomersAndSuppliers,
            ShowOnlyJobOrderWarehouses: vatRegister.showOnlyJobOrderWarehouses,
            VersionNumberPrefix: vatRegister.versionNumberPrefix,
            VersionNumberSuffix: vatRegister.versionNumberSuffix,
            VersionNumberSeparator: vatRegister.versionNumberSeparator,
            RequireVersionRevision: vatRegister.requireVersionRevision,
            InvoiceElectronicInvoiceType: vatRegister.invoiceElectronicInvoiceType,
            CreditNoteElectronicInvoiceType: vatRegister.creditNoteElectronicInvoiceType,
            DownPaymentElectronicInvoiceType: vatRegister.downPaymentElectronicInvoiceType,
            AccompanyingInvoiceElectronicInvoiceType: vatRegister.accompanyingInvoiceElectronicInvoiceType,
            SalesCicle: vatRegister.salesCicle,
            FkCurrentLogo: vatRegister.fkCurrentLogo,
            EnableStampDuty: false,
            DefaultBlogTags: vatRegister.defaultBlogTags.map(VatRegistersMapper.mapToOldBlogTag),
            DefaultFileTags: vatRegister.defaultFileTags.map(VatRegistersMapper.mapToOldFileTag),
            AccountingSoftwareRoutes: vatRegister.accountingSoftwareRoutes.map(
                VatRegistersMapper.mapToOldAccountingSoftwareRoute
            ),
            ProtocolsDefaultValues: vatRegister.protocolsDefaultValues.map(
                VatRegistersMapper.mapToOldProtocolsDefaultValue
            ),
            ProtocolsDefaultMetadatas: vatRegister.protocolsDefaultMetadatas.map(
                VatRegistersMapper.mapToOldProtocolsDefaultMetadata
            ),

            preferredPrintTemplateId: null,
            printTemplates: [],

            mailRecipientsForDocumentsSendBcc: vatRegister.mailRecipientsForDocumentsSendBcc.slice(),
            mailRecipientsForDocumentsSendCc: vatRegister.mailRecipientsForDocumentsSendCc.slice(),
            mailRecipientsForDocumentsSendTo: vatRegister.mailRecipientsForDocumentsSendTo.slice(),
        };
    }

    static mapToOldBlogTag(tag: ITagForProtocolNew): ITagForProtocol {
        return {
            TagId: tag.tagId,
            ProtocolId: tag.protocolId,
            Name: tag.name,
            ValueTypeId: tag.valueTypeId,
            Value: tag.value,
            ValueNumber: tag.valueNumber,
            ValueDate: tag.valueDate,
            DisplayName: tag.displayName,
            EventType: tag.eventType,
        };
    }

    static mapToOldFileTag(tag: IFileTagForProtocolNew): IFileTagForProtocol {
        return {
            LabelId: tag.labelId,
            ProtocolId: tag.protocolId,
        };
    }

    static mapToOldAccountingSoftwareRoute(route: IAccountingSoftwareRouteNew): IAccountingSoftwareRoute {
        return {
            ProtocolId: route.protocolId,
            FkVatCode: route.fkVatCode,
            AccountRegisterCode: route.accountRegisterCode,
        };
    }

    static mapToOldProtocolsDefaultValue(value: IProtocolDefaultValueForProtocolNew): IProtocolDefaultValueForProtocol {
        return {
            ProtocolId: value.protocolId,
            SettingKey: value.settingKey,
            IntValue: value.intValue,
            DecimalValue: value.decimalValue,
            StringValue: value.stringValue,
            DateValue: value.dateValue,
            Source: value.source,
            FkProtocol: value.fkProtocol,
        };
    }

    static mapToOldProtocolsDefaultMetadata(metadata: IProtocolDefaultMetadatasNew): IProtocolDefaultMetadatas {
        return {
            Id: metadata.id,
            FKProtocol: metadata.fkProtocol,
            FKMetadata: metadata.fkMetadata,
            MetadataValueType: metadata.metadataValueType,
            ShowMarkerOnAllocationsGantt: metadata.showMarkerOnAllocationsGantt,
            PreloadOnDocument: metadata.preloadOnDocument,
            Order: metadata.order,
        };
    }
}
