import * as React from "@abstraqt-dev/jsxknockout"
import { Application } from "../../Components/Application"
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { IDataSourceListener, IDataSourceModel, IDataSource } from "../../DataSources/IDataSource";
import { Table, ITableItem } from "../../Components/TableComponent/TableComponent";
import { Column } from "../../Components/TableComponent/CustomColumn";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../Components/Layouts";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IGDPRService, ITreatment } from "../GDPRService";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../DataSources/JobOrdersDataSource";
import { ResourcesDataSource, IResourceDataSourceModel } from "../../DataSources/ResourcesDataSource";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TreatmentsDataSource, ITreatmentModel } from "../../DataSources/TreatmentsDataSource";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import ko = require("knockout");
import { GDPRTreatmentComponent } from "./GDPRTreatmentComponent";
import { If, IfNot } from "../../Components/IfIfNotWith";
import { Right } from "../../Core/Authorizations";
import jss from "jss";
import { SecondaryRow } from "../../Components/TableComponent/SecondaryRow";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { ITreatmentTypesSettingsManager } from "../settings/TreatmentTypesSettingsManager";

const { classes } = jss.createStyleSheet({
    gdprTable : {
        "& .actions-col": {
            width: "70px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

type GDPRMainPageProps = { ref?: (a: GDPRMainPage) => void; }

export class GDPRMainPage implements IDataSourceListener {
    // public MenuDataSource: ArrayDataSource<any> = new ArrayDataSource<any>();

    @LazyImport(nameof<IGDPRService>())
    private GDPRService: IGDPRService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @Right("GDPR_CanInsertOrUpdateTreatments")
    private canInsertOrUpdateTreatments: boolean;

    @LazyImportSettingManager(nameof<ITreatmentTypesSettingsManager>())
    private treatmentTypesSettingsManager: ITreatmentTypesSettingsManager;

    childComponent: GDPRTreatmentComponent;
    treatmentId: ko.Observable<number> = ko.observable();

    public TableDataSource: TreatmentsDataSource = new TreatmentsDataSource();

    public TextFilter: ko.Observable<string> = ko.observable("");
    
    public SelectedJobOrder: ko.Observable<number> = ko.observable();
    public JobOrdersDataSource: JobOrdersDataSource;

    public SelectedResource: ko.Observable<number> = ko.observable();
    public ResourcesDataSource: ResourcesDataSource;

    public Title: ko.Observable<string> = ko.observable(TextResources.GDPR.ApplicationName);
    public IsSearchMode: ko.Observable<boolean> = ko.observable(true);

    public DisplayedDescriptionId: ko.Observable<number> = ko.observable();

    constructor(private props: GDPRMainPageProps){
        // this.MenuDataSource.setData({
        //     id: 1, isGroup: false, isLeaf: true, title: TextResources.GDPR.ObjectsTable
        // });

        this.ResourcesDataSource = new ResourcesDataSource();
        this.ResourcesDataSource.setIcon({
            icon: "icon-user",
            foreground: "#FFFFFF",
            background: "#0000FF"
        });

        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.SelectedJobOrder.subscribe((val) => {
            this.TableDataSource.setJobOrderId(val);
            this.TableDataSource.refresh();
        });

        this.SelectedResource.subscribe((val) => {
            this.TableDataSource.setResourcesId(val);
            this.TableDataSource.refresh();
        })

        if (props.ref){
            props.ref(this);
        }
    }

    goNewTreatment() {
        this.treatmentId(null);
        this.IsSearchMode(false);
    }

    openDetail(id: number) {
        this.treatmentId(id);
        this.IsSearchMode(false);
    }

    getTreatmentTypeName(fkTreatmentType: number): string {
        let type = this.treatmentTypesSettingsManager.getTreatmentTypesByIds([fkTreatmentType]).firstOrDefault();
        return !type ? "" : type.Name;
    }

    /* VERSIONE CON MODALE
    async goNewTreatment(){
        let dialog = new GDPRTreatmentDialog();
        let result = await dialog.showModal();

        if (result) {
            this.infoToastService.Success(TextResources.GDPR.AddSuccess);
            this.TableDataSource.refresh();
        }
    }

    async openDetail(id: number){
        let dialog = new GDPRTreatmentDialog(id);
        let result = await dialog.showModal();

        if (result) {
            this.infoToastService.Success(TextResources.GDPR.EditSuccess);
            this.TableDataSource.refresh();
        }
    }*/

    async deleteTreatment(id: number){
        let result = await this.dialogsService.ConfirmAsync(TextResources.GDPR.DeleteItemMessage, TextResources.GDPR.CancelDeleteItem, TextResources.GDPR.ConfirmDeleteItem);
        if (result) {
            try {
                await this.GDPRService.DeleteTreatment(id, this.userInfo.getIdUser());
                this.TableDataSource.refresh();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    resetSearchFields(){
        this.SelectedResource(null);
        this.ResourcesDataSource.selectByIds(...[]);
        this.SelectedJobOrder(null);
        this.JobOrdersDataSource.selectByIds(...[]);
        this.TextFilter(null);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (this.IsSearchMode()) {
            if (sender === this.JobOrdersDataSource) {
                let jobOrderModel = model as IJobOrderDataSourceModel;
                if (this.SelectedJobOrder() === jobOrderModel.model.Id) {
                    this.SelectedJobOrder(null);
                    this.JobOrdersDataSource.selectByIds(...[]);
                } else {
                    this.SelectedJobOrder(jobOrderModel.model.Id);
                }
            } else if (sender === this.ResourcesDataSource) {
                let resourceModel = model as IResourceDataSourceModel;
                if (this.SelectedResource() === resourceModel.model.Resource.Id) {
                    this.SelectedResource(null);
                    this.ResourcesDataSource.selectByIds(...[]);
                } else {
                    this.SelectedResource(resourceModel.model.Resource.Id);
                }
            }
        }
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }

    async saveAll() {
        let result = await this.childComponent.saveAll();

        if (result) {
            this.infoToastService.Success(this.treatmentId ? TextResources.GDPR.EditSuccess : TextResources.GDPR.AddSuccess);
            this.IsSearchMode(true);
            this.TableDataSource.refresh();
        }
    }

    cancelEdit() {
        this.IsSearchMode(true);
    }

    toggleDescription(id: number){
        if (this.DisplayedDescriptionId() === id)
            this.DisplayedDescriptionId(-1);
        else 
            this.DisplayedDescriptionId(id);
    }

    render(){
        let treatment: IDataSourceModel<number, ITreatmentModel>;
        let vm = this;
        let table: Table<ITreatment>;

        return ComponentUtils.bindTo(
        <Application>
            <Application.LeftMenu>
                <NavigationMenu wrapperClassName="page-sidebar" dataSource={this.JobOrdersDataSource} listener={this} />
            </Application.LeftMenu>
            <Application.Content>
                <LayoutWithHeader className="flex-1">
                    <LayoutHeader className="row no-gutters">
                        <div className="col-md-10">
                            <h1 data-bind={{text: vm.Title}}></h1>
                        </div>
                        <div className="col-md-2 text-right" style="margin-top: 20px" data-bind={{ifnot: vm.IsSearchMode}}>
                            <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments}}>
                            <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}} style={{ marginRight: '5px' }}>
                                <i className="fa fa-save"></i> {TextResources.GDPR.Save}
                            </button>
                            </ko-bind>
                            <button className="btn btn-warning" data-bind={{click: vm.cancelEdit.bind(vm)}}>
                                <i className="fa fa-times"></i> {TextResources.GDPR.Cancel}
                            </button>
                        </div>
                    </LayoutHeader>
                    <LayoutContent noOverflow>
                        <If condition={vm.IsSearchMode}>
                            {() => (
                                <>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <text-input value={() => "TextFilter"} label={TextResources.GDPR.Search} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <button className="btn btn-warning" style={{marginTop: "26px"}} data-bind={{click: vm.resetSearchFields.bind(vm)}}>
                                            <i className="fa fa-times-circle"></i> {TextResources.GDPR.ResetSearchFields}
                                        </button>
                                    </div>
                                {/* <div className="col-md-4">
                                    <select2 value={() => "SelectedResource"} allowClear={true} dataSource={() => "ResourcesDataSource"} label={TextResources.GDPR.RelatedResources + ":"} />
                                    </div>
                                    <div className="col-md-4">
                                    <select2 value={() => "SelectedJobOrder"} allowClear={true} dataSource={() => "JobOrdersDataSource"} label={TextResources.GDPR.RelatedJobOrders + ":"} />
                                </div> */}
                                </div>
                                <Table id={64} rowAs={"treatment"} title={TextResources.GDPR.TreatmentsSearchResults} showColumnSelector compact className={ComponentUtils.classNames("fixed-height", classes.gdprTable)} dataSource={this.TableDataSource} verticalAlign="middle" fixedLayout={true} scrollable={true} textFilter={this.TextFilter}>
                                    <Column id={65} style={{ width: '30px' }}>
                                        <button className="btn btn-transparent btn-xs" title={TextResources.GDPR.ShowHideDetail} data-bind={{ click: vm.toggleDescription.bind(vm, treatment.model.Id) }}>
                                            <i className="fa" data-bind={{ css: { 'fa-caret-right': vm.DisplayedDescriptionId() !== treatment.model.Id, 'fa-caret-down': vm.DisplayedDescriptionId() === treatment.model.Id }}}></i>
                                        </button>
                                    </Column>
                                    <Column id={66} title={TextResources.GDPR.Title}>
                                        <div style={{width: "100%"}} className="text-ellipsis" data-bind={{text: treatment.model.Title, attr: {title: treatment.model.Title}}}></div>
                                    </Column>
                                    <Column id={67} title={TextResources.GDPR.Description} style={{width: "350px"}}>
                                        <div style="width: 100%" className="text-ellipsis" data-bind={{text: $(treatment.model.Description).text()}}></div>
                                    </Column>
                                    <Column id={68} title={TextResources.GDPR.State} style={{width: "60px"}}>
                                        <i className="fa" data-bind={{css: {"fa-folder-open": treatment.model.IsOpen, "fa-folder": !treatment.model.IsOpen}, attr: {title: treatment.model.StateLabel}}}/>
                                    </Column>
                                    <Column id={69} title={TextResources.GDPR.TreatmentType} style={{width: "150px"}} data-bind={{text: vm.getTreatmentTypeName(treatment.model.FkTreatmentType)}}/>
                                    <Column id={70} title={TextResources.GDPR.CreationDate} data-bind={{dateTimeText: treatment.model.CreationDate}}/>
                                    <Column id={71} title={TextResources.GDPR.CreatedBy} data-bind={{text: treatment.model.CreationUserFullName}}/>
                                    <Column id={72} title={TextResources.GDPR.LastEditDate} data-bind={{dateTimeText: treatment.model.LastEditDate}}/>
                                    <Column id={73} title={TextResources.GDPR.EditedBy} data-bind={{text: treatment.model.LastEditUserFullName}}/>
                                    <Column id={74} title={TextResources.GDPR.Actions} headerCssClasses="actions-col" cssClasses="actions-col">
                                        <button className="btn btn-primary btn-xs" title={TextResources.GDPR.Detail + "..."} data-bind={{click: vm.openDetail.bind(vm, treatment.model.Id)}}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <ko-bind data-bind={{if: vm.canInsertOrUpdateTreatments}}>
                                        <button className="btn btn-danger btn-xs" title={TextResources.GDPR.Delete} data-bind={{click: vm.deleteTreatment.bind(vm, treatment.model.Id)}}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        </ko-bind>
                                    </Column>
                                    <SecondaryRow if={() => "vm.DisplayedDescriptionId() === treatment.model.Id"}>
                                        {(item: ITableItem<ITreatment>) => 
                                        <>
                                        <td></td>
                                        <td data-bind={{attr: {colspan: (table.VisibleColumnsCount() - 1)}}}>
                                            <iframe data-bind={{iframeContent: treatment.model.Description}} style={{width: '100%', border: 'none'}} >

                                            </iframe>
                                        </td>
                                        </>}
                                    </SecondaryRow>
                                </Table>
                                </>)}
                        </If>
                        <IfNot condition={vm.IsSearchMode}>
                                {() => { 
                                    let GdprTreatmentComponent = require("./GDPRTreatmentComponent").GDPRTreatmentComponent as typeof GDPRTreatmentComponent; 
                                    return <GdprTreatmentComponent ref={(c) => this.childComponent = c} treatmentId={this.treatmentId()}/>
                                }}
                        </IfNot>
                    </LayoutContent>
                </LayoutWithHeader>
            </Application.Content>
            <NavigationMenu dataSource={this.ResourcesDataSource} listener={this} />
        </Application>, this, "vm");
    }

    dispose(){

    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(GDPRMainPage);
}