import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { HoursApprovalState } from "../../enums/HoursApprovalState";

export class HoursApprovalStateUtilities {
    private static labels: Map<HoursApprovalState, string> = new Map([
        [HoursApprovalState.Created, TextResources.WorkedHours.WorkedHoursRowCreated],
        [HoursApprovalState.PartiallyApproved, TextResources.WorkedHours.WorkedHoursRowPartiallyApproved],
        [HoursApprovalState.Approved, TextResources.WorkedHours.WorkedHoursRowApproved],
        [HoursApprovalState.Rejected, TextResources.WorkedHours.WorkedHoursRowRejected],
        [HoursApprovalState.Modified, TextResources.WorkedHours.WorkedHoursRowModified],
        [HoursApprovalState.Reset, TextResources.WorkedHours.WorkedHoursRowReset],
        [HoursApprovalState.Unmanaged, TextResources.WorkedHours.WorkedHoursRowUnmanaged],
        [HoursApprovalState.SignAdded, TextResources.WorkedHours.WorkedHoursRowSignAdded],
        [HoursApprovalState.SignRemoved, TextResources.WorkedHours.WorkedHoursRowSignRemoved]
    ]);
    
    private static tooltips: Map<HoursApprovalState, string> = new Map([
        [HoursApprovalState.Created, ""],
        [HoursApprovalState.PartiallyApproved, ""],
        [HoursApprovalState.Approved, TextResources.WorkedHours.HoursApprovalApprovedTooltip],
        [HoursApprovalState.Rejected, TextResources.WorkedHours.HoursApprovalRejectedTooltip],
        [HoursApprovalState.Modified, ""],
        [HoursApprovalState.Reset, ""],
        [HoursApprovalState.Unmanaged, TextResources.WorkedHours.HoursApprovalUnmanagedTooltip],
        [HoursApprovalState.SignAdded, ""],
        [HoursApprovalState.SignRemoved, ""]
    ]);
    
    private static icons: Map<HoursApprovalState, string> = new Map([
        [HoursApprovalState.Created, "fa-plus"],
        [HoursApprovalState.PartiallyApproved, ""],
        [HoursApprovalState.Approved, "fa-check"],
        [HoursApprovalState.Rejected, "fa-times"],
        [HoursApprovalState.Modified, "fa-edit"],
        [HoursApprovalState.Reset, ""],
        [HoursApprovalState.Unmanaged, "fa-exclamation-circle"],
        [HoursApprovalState.SignAdded, ""],
        [HoursApprovalState.SignRemoved, ""]
    ]);

    private constructor() {}

    public static getLabelFor(state: HoursApprovalState) {
        if (!HoursApprovalStateUtilities.labels.has(state))
            throw new Error(String.format("Invalid worked hours approval state {0}", state));

        return HoursApprovalStateUtilities.labels.get(state);
    }
    
    public static getTooltipFor(state: HoursApprovalState): string {
        if (!HoursApprovalStateUtilities.tooltips.has(state))
            throw new Error(String.format("Invalid worked hours approval state {0}", state));

        return HoursApprovalStateUtilities.tooltips.get(state);
    }
    
    public static getIconFor(state: HoursApprovalState) {
        if (!HoursApprovalStateUtilities.icons.has(state))
            throw new Error(String.format("Invalid worked hours approval state {0}", state));

        return HoursApprovalStateUtilities.icons.get(state);
    }

    public static isUnmanagedWorkedHoursRow(approvalState: HoursApprovalState): boolean {
        return approvalState !== HoursApprovalState.Approved
            && approvalState !== HoursApprovalState.Rejected 
            && approvalState !== HoursApprovalState.PartiallyApproved;
    }
    
    public static isApprovedOrInApprovalPhase(approvalState: HoursApprovalState): boolean {
        return approvalState === HoursApprovalState.Approved
            || approvalState === HoursApprovalState.Rejected
            || approvalState === HoursApprovalState.PartiallyApproved;
    }
}