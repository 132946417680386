import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type WorkPlaceProps = {
    workPlaceId: number;
}

export function WorkPlace(props: WorkPlaceProps) {
    const C = require("./WorkPlace")._WorkPlace as typeof _WorkPlace;
    return <C {...props} />;
}

export class _WorkPlace {
    static defaultProps: Partial<WorkPlaceProps> = {
        workPlaceId: 0
    }

    private labels: Map<number, string>;

    constructor(private props : WorkPlaceProps) {
        this.labels = new Map([
            [0, TextResources.WorkedHours.OnSite],
            [1, TextResources.WorkedHours.OffSite],
            [2, TextResources.WorkedHours.Away]
        ]);
    }
    
    render() {
        return <span>{this.labels.get(this.props.workPlaceId)}</span>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkPlace);
}