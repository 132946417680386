import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IBank } from "../ProlifeSdk/interfaces/settings/IBank";
import { ICompanySettingsManager } from "../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { TextFiltersUtilities } from "../Core/utils/TextFiltersUtilities";

export type CompanyABICABDataSourceModel = IDataSourceModel<string, IBank>;

export class CompanyABICABDataSource extends BaseDataSource<CompanyABICABDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    public companySettingsManager: ICompanySettingsManager;

    private _getABIs = false;
    private _getCABs = false;

    public getABIs(): void {
        this._getABIs = true;
        this._getCABs = false;
    }

    public getCABs(): void {
        this._getABIs = false;
        this._getCABs = true;
    }

    getTitle(currentModel: CompanyABICABDataSourceModel): string {
        return TextResources.Core.CompanyABIs;
    }

    async getData(
        currentModel: CompanyABICABDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<CompanyABICABDataSourceModel[]> {
        return this.getAllBanksWithABICAB()
            .filter((b) => TextFiltersUtilities.contains(this._getABIs ? b.ABI : b.CAB, textFilter))
            .slice(skip, skip + count)
            .map(this.createModel, this);
    }

    async getById(currentModel: CompanyABICABDataSourceModel, ids: string[]): Promise<CompanyABICABDataSourceModel[]> {
        return this.getAllBanksWithABICAB()
            .filter((b) => ids.indexOf(this._getABIs ? b.ABI : b.CAB) >= 0)
            .map(this.createModel, this);
    }

    private createModel(bank: IBank): CompanyABICABDataSourceModel {
        const value = this._getABIs ? bank.ABI : bank.CAB;

        return {
            id: value,
            title: value,
            subTitle: bank.Name,
            isGroup: false,
            isLeaf: true,
            model: bank,
        };
    }

    private getAllBanksWithABICAB(): IBank[] {
        const company = this.companySettingsManager.get();
        const banks = [];
        for (const bank of company.Banks) {
            const value = this._getABIs ? bank.ABI : bank.CAB;

            if (!(value || "").trim()) continue;

            banks.push(bank);
        }

        return banks;
    }
}
