import ko = require("knockout");
import { WarehouseInspectionAction } from "../../Enums/WarehouseInspectionAction";
import { IWarehouseInspectionWizardArticleInfo, IWarehouseInspectionWizardArticleInfoSource } from "../../../../../WarehouseInspectionsService";

export class SelectedArticleInfo {
    public DestinationWarehouseId: number;
    public DestinationWarehouse: string;
    public DestinationWarehouseStock: number;
    public CustomerId: number;
    public CustomerName: string;
    public JobOrderId: number;
    public JobOrderName: string;
    public LastInspectionDate: Date;

    public Operations: ko.ObservableArray<SelectedArticleOperationInfo> = ko.observableArray([]);
    public ShowOperations: ko.Observable<boolean> = ko.observable(false);

    public OperationsNumber: ko.Computed<number>;

    constructor(private operationsInfo: IWarehouseInspectionWizardArticleInfo[]) {
        let firstOp = this.operationsInfo.firstOrDefault();

        this.DestinationWarehouseId = firstOp?.DestinationWarehouseId;
        this.DestinationWarehouse = firstOp?.DestinationWarehouse;
        this.DestinationWarehouseStock = firstOp?.DestinationWarehouseStock;
        this.CustomerId = firstOp?.CustomerId;
        this.CustomerName = firstOp?.CustomerName;
        this.JobOrderId = firstOp?.JobOrderId;
        this.JobOrderName = firstOp?.JobOrderName;
        this.LastInspectionDate = firstOp?.LastInspectionDate;

        this.OperationsNumber = ko.computed(() => {
            return this.Operations().length;
        });

        this.loadOperations(this.operationsInfo);
    }

    public mergeOperationsInfo(operationsInfo: IWarehouseInspectionWizardArticleInfo[]) {
        let actualOperations = this.Operations();
        let newOperations = [];

        for (let op of operationsInfo) {
            let existingOp = actualOperations.firstOrDefault(ao => ao.SourceWarehouseId === op.SourceWarehouseId && ao.ArticleId === op.ArticleId && ao.OperationType === op.OperationType);
            if (!existingOp) {
                newOperations.push(op);
            } else {
                existingOp.merge(op);
            }
        }

        this.appendOperations(newOperations);
    }

    public getData(): IWarehouseInspectionWizardArticleInfo[] {
        return this.Operations().map(o => o.getData());
    }

    private loadOperations(operationsInfo: IWarehouseInspectionWizardArticleInfo[]): void {
        let operations = this.createOperations(operationsInfo);
        operations.sort((o1, o2) => this.compareOperations(o1, o2));
        this.Operations(operations);
    }

    private appendOperations(operationsInfo: IWarehouseInspectionWizardArticleInfo[]): void {
        let operations = this.createOperations(operationsInfo);
        let allOperations = this.Operations().concat(operations);
        allOperations.sort((o1, o2) => this.compareOperations(o1, o2));
        this.Operations(allOperations);
    }

    private createOperations(operationsInfo: IWarehouseInspectionWizardArticleInfo[]): SelectedArticleOperationInfo[] {
        let operations = [];
        for (let info of operationsInfo)
            operations.push(new SelectedArticleOperationInfo(info));
        return operations;
    }

    private compareOperations(o1: SelectedArticleOperationInfo, o2: SelectedArticleOperationInfo): number {
        if (o1.SourceWarehouse < o2.SourceWarehouse)
            return -1;
        else if (o1.SourceWarehouse > o2.SourceWarehouse)
            return 1;
        else if (o1.SourceWarehouseId < o2.SourceWarehouseId)
            return -1;
        else if (o1.SourceWarehouseId > o2.SourceWarehouseId)
            return 1;
        else if (o1.ArticleCode < o2.ArticleCode)
            return -1;
        else if (o1.ArticleCode > o2.ArticleCode)
            return 1;
        else if (o1.OperationType > o2.OperationType)
            return -1;
        else if (o1.OperationType < o2.OperationType)
            return 1;
        else return 0;
    }
}

export class SelectedArticleOperationInfo {
    public SourceWarehouseId: number;
    public SourceWarehouse: string;
    public SourceWarehouseStock: number;
    public ArticleId: number;
    public ArticleDescription: string;
    public ArticleCode: string;
    public EanCode: string;
    public MefCode: string;
    public Amount: number;
    public OperationType: WarehouseInspectionAction;

    private sources: IWarehouseInspectionWizardArticleInfoSource[] = [];

    constructor(private info: IWarehouseInspectionWizardArticleInfo) {
        this.SourceWarehouseId = this.info.SourceWarehouseId;
        this.SourceWarehouse = this.info.SourceWarehouse;
        this.SourceWarehouseStock = this.info.SourceWarehouseStock;
        this.ArticleId = this.info.ArticleId;
        this.ArticleDescription = this.info.ArticleDescription;
        this.ArticleCode = this.info.ArticleCode;
        this.EanCode = this.info.EanCode;
        this.MefCode = this.info.MefCode;
        this.Amount = this.info.Amount;
        this.OperationType = this.info.OperationType;

        this.sources = this.info.Sources.slice();
    }

    public merge(operation: IWarehouseInspectionWizardArticleInfo): void {
        for (let opSource of operation.Sources) {
            let ref = this.sources.firstOrDefault(s => s.RefId === opSource.RefId);
            if (!ref)
                this.sources.push({ 
                    WarehouseInspectionOperationId: this.info.Id,
                    DocumentId: opSource.DocumentId,
                    RefId: opSource.RefId,
                    RefAmount: 0,
                    DocumentDate: opSource.DocumentDate,
                    DocumentLabel: opSource.DocumentLabel,
                    DocumentNumber: opSource.DocumentNumber,
                    DocumentType: opSource.DocumentType,
                    DocumentRegisterId: opSource.DocumentRegisterId,
                    DocumentRegisterName: opSource.DocumentRegisterName
                });
            else
                ref.RefAmount += opSource.RefAmount;
        }
    }

    public getData(): IWarehouseInspectionWizardArticleInfo {
        let data = Object.assign({}, this.info);
        data.Sources = this.sources;
        return data;
    }

    public dispose(): void {}
}