import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import moment = require("moment");
import TsxForEach from "../../Components/ForEach";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IDocumentsService } from "../DocumentsService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../Components/Layouts";
import { DocumentResourceAssignmentsDetails, MonthHours } from "../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { ResponseError } from "../../Core/response/ResponseBase";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { DateTimeInput } from "../../Components/DateTimeInput";
import { SelectMultiple } from "../../Components/SelectMultiple";
import { If } from "../../Components/IfIfNotWith";
import { Loading } from "../../Components/Loading";
import { ResourceAssignments } from "./documents/ResourceAssignments";
import { IValidationService, IValidator } from "../../ProlifeSdk/ValidationService";
import { ResourcesAndGroupsDataSource } from "../../DataSources/ResourcesAndGroupsDataSource";
import { IResourcesGroupsSettingsManager } from "../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import {
    DocumentResourceDetailedAssignmentExtended,
    ResourceAssignmentsTable,
} from "./documents/ResourceAssignmentsTable";

const styleSheet = jss.createStyleSheet({
    "resources-assignments-report": {
        height: "100%",

        "& .header": {
            alignItems: "center",
            paddingBottom: "10px",
        },

        "& .totals-table": {
            marginTop: "20px",

            "& > h4": {
                padding: "10px 0px 10px 5px",
                fontWeight: "700",
                borderLeft: "1px solid lightgray",
                borderRight: "1px solid lightgray",
                borderTop: "1px solid lightgray",
                margin: 0,
                backgroundColor: "whitesmoke",
            },
        },
    },
});
const { classes } = styleSheet.attach();

export class ResourcesAssignmentsReport extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private resourcesGroupsSettingsManager: IResourcesGroupsSettingsManager;

    private from: ko.Observable<Date> = ko.observable();
    private to: ko.Observable<Date> = ko.observable();
    private selectedResources: ko.ObservableArray<string> = ko.observableArray([]);
    private resources: ResourcesAndGroupsDataSource = new ResourcesAndGroupsDataSource();
    private assignments: ko.ObservableArray<DocumentResourceAssignmentsDetails> = ko.observableArray([]);
    private loading: ko.Observable<boolean> = ko.observable(false);
    private searchClicked: ko.Observable<boolean> = ko.observable(false);

    private showHelpText: ko.Computed<boolean>;
    private showEmptyList: ko.Computed<boolean>;

    private validator: IValidator<ResourcesAssignmentsReport>;
    private readonly periodMaxLengthDays = 730;

    constructor() {
        super({ className: "large", noPrompt: true });
        this.title(TextResources.Invoices.ResourcesAssignmentsReportTitle);

        this.resources.setIncludeGroups(true);

        this.showHelpText = ko.computed(() => {
            return this.assignments().length === 0 && !this.searchClicked() && !this.loading();
        });

        this.showEmptyList = ko.computed(() => {
            return this.assignments().length === 0 && this.searchClicked() && !this.loading();
        });
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    componentDidMount() {
        this.from(moment().startOf("year").toDate());
        this.to(moment().endOf("month").toDate());

        this.validator = this.validationService
            .createValidator<ResourcesAssignmentsReport>()
            .isNotNullOrUndefined((r) => r.from(), TextResources.Invoices.FromRequired)
            .isNotNullOrUndefined((r) => r.to(), TextResources.Invoices.ToRequired)
            .isTrue((r) => r.from() <= r.to(), TextResources.Invoices.InvalidDateRange)
            .isFalse(
                (r) => moment(r.to()).diff(moment(r.from()), "days") > r.periodMaxLengthDays,
                TextResources.Invoices.DateDiffError
            );
    }

    private async loadAssignments(): Promise<void> {
        if (!this.validator.validateAndShowInfoToast(this)) return;

        const from = this.from();
        const to = this.to();
        const resources = this.selectedResources();
        const resourceIds = this.createResourceIdsList(resources);

        this.assignments([]);
        this.searchClicked(true);
        this.loading(true);

        try {
            const assignmentsResponse = await this.documentsService.GetResourceDocumentsAssignments(
                resourceIds,
                from,
                to
            );

            if (!assignmentsResponse.succeeded) {
                this.handleErrors(assignmentsResponse.errors);
                return;
            }

            this.assignments(assignmentsResponse.data);
        } catch (e) {
            console.error(e);
        } finally {
            this.loading(false);
        }
    }

    private createResourceIdsList(resources: string[]): number[] {
        const resourceIds = [];

        for (const res of resources) {
            const parts = res.split("|");
            const id = parseInt(parts[0]);
            if (parts[1] === ResourcesAndGroupsDataSource.HumanResourcesType) {
                resourceIds.push(id);
            } else {
                const group = this.resourcesGroupsSettingsManager.GetGroupById(id);
                if (group) {
                    resourceIds.push(...group.ResourcesIds);
                }
            }
        }

        return resourceIds;
    }

    private handleErrors(errors: ResponseError[]) {
        let errorMessage = "";
        for (const error of errors) {
            errorMessage += TextResources.Invoices[error.code] + "<br />";
        }

        this.infoToastService.Error(errorMessage);
    }

    action() {
        this.modal.close();
    }

    renderBody() {
        const ra = this;

        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["min-content", "1fr"]}
                columns={["1fr"]}
                className={classes["resources-assignments-report"]}>
                <Layout.Grid.Cell row={1} column={1} className="header">
                    <DateTimeInput
                        value={this.from}
                        allowClear
                        dateonly
                        placeholder={TextResources.Invoices.From}
                        parent=".modal-body"
                        simple
                    />
                    <DateTimeInput
                        value={this.to}
                        allowClear
                        dateonly
                        placeholder={TextResources.Invoices.To}
                        parent=".modal-body"
                        simple
                    />
                    <SelectMultiple
                        value={this.selectedResources}
                        dataSource={this.resources}
                        placeholder={TextResources.Invoices.Resources}
                        searchPlaceholder={TextResources.Invoices.Search}
                        allowClear
                        hideSelectedDataFromMenu
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-bind={{ asyncClick: ra.loadAssignments.bind(ra) }}>
                        <i className="fa fa-search"></i> {TextResources.Invoices.Search}
                    </button>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <Layout.ScrollContainer systemScrollable>
                        <If condition={this.showEmptyList}>
                            {() => (
                                <div className="text-center">
                                    <span>{TextResources.Invoices.EmptyAssignmentsList}</span>
                                </div>
                            )}
                        </If>
                        <If condition={this.showHelpText}>
                            {() => (
                                <div className="text-center">
                                    <span>{TextResources.Invoices.ListHelpText}</span>
                                </div>
                            )}
                        </If>
                        <If condition={this.loading}>
                            {() => (
                                <div className="text-center">
                                    <Loading show />
                                </div>
                            )}
                        </If>
                        <If condition={() => this.assignments().length > 0}>
                            {() => (
                                <ResourceAssignmentsTotals
                                    resourceAssignments={this.assignments}
                                    from={this.from}
                                    to={this.to}
                                    className="totals-table"
                                />
                            )}
                        </If>
                        <TsxForEach data={this.assignments}>
                            {(assignment: DocumentResourceAssignmentsDetails) => (
                                <ResourceAssignments
                                    resourceAssignments={assignment}
                                    from={this.from}
                                    to={this.to}
                                    showTitle
                                />
                            )}
                        </TsxForEach>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "ra"
        );
    }
}

type ResourceAssignmentsTotalsProps = {
    resourceAssignments: ko.MaybeObservableArray<DocumentResourceAssignmentsDetails>;
    from: ko.MaybeObservable<Date>;
    to: ko.MaybeObservable<Date>;
    className?: string;
};

class ResourceAssignmentsTotals {
    static defaultProps: Partial<ResourceAssignmentsTotalsProps> = {
        className: "",
    };

    private totals: ko.ObservableArray<DocumentResourceDetailedAssignmentExtended> = ko.observableArray([]);
    private subscriptions: ko.Subscription[] = [];

    constructor(private props: ResourceAssignmentsTotalsProps) {}

    componentDidMount() {
        if (ko.isSubscribable(this.props.resourceAssignments)) {
            this.subscriptions.push(this.props.resourceAssignments.subscribe(this.computeRows.bind(this)));
        }

        this.computeRows();
    }

    private computeRows() {
        this.totals([]);

        const data = [];
        const resourcesAssignments = ko.unwrap(this.props.resourceAssignments);

        data.push(this.createTotalsRow(resourcesAssignments, true, false, false));
        data.push(this.createTotalsRow(resourcesAssignments, false, true, false));
        data.push(this.createTotalsRow(resourcesAssignments, false, false, true));

        this.totals(data);
    }

    private createTotalsRow(
        hoursAmountPerMonth: DocumentResourceAssignmentsDetails[],
        isTotals: boolean,
        isMaxAvailableHoursRow: boolean,
        isResidual: boolean
    ): DocumentResourceDetailedAssignmentExtended {
        const hoursMonthsDistribution = isTotals
            ? this.createTotalsPerMonth(hoursAmountPerMonth.map((h) => h.hoursAssignmentsPerMonth))
            : isMaxAvailableHoursRow
            ? this.createTotalsPerMonth(hoursAmountPerMonth.map((h) => h.maxWorkingHoursPerMonth))
            : this.createTotalsPerMonth(hoursAmountPerMonth.map((h) => h.residualHoursPerMonth));

        return {
            id: 0,
            document: null,
            fkResource: null,
            hoursAmount: isTotals ? hoursAmountPerMonth.sum((h) => h.totalAssignedHours) : null,
            startDate: null,
            endDate: null,
            notes: "",
            rowNumber: null,

            hoursMonthsDistribution: hoursMonthsDistribution,

            detailsFromDate: ko.unwrap(this.props.from),
            detailsToDate: ko.unwrap(this.props.to),
            workingHoursInAssignmentPeriod: null,
            workingHoursInDetailsPeriod: null,
            isTotalsRow: isTotals,
            isMaxAvailableHoursRow: isMaxAvailableHoursRow,
            isResidualRow: isResidual,

            totalAssignedHoursInDetailsPeriod: isTotals
                ? hoursAmountPerMonth.sum((h) => h.totalAssignedHoursInDetailsPeriod)
                : isMaxAvailableHoursRow
                ? hoursAmountPerMonth.sum((h) => h.maxAvailableHoursInDetailsPeriod)
                : hoursAmountPerMonth.sum((h) => h.residualHoursInDetailsPeriod),

            label: isTotals
                ? TextResources.Invoices.DocumentResourceTotalHours
                : isResidual
                ? TextResources.Invoices.DocumentResourceResidualAvailableHours
                : TextResources.Invoices.DocumentResourceMaxAvailableHours,
        };
    }

    private createTotalsPerMonth(hoursAmountPerMonth: MonthHours[][]): MonthHours[] {
        const result = [];
        const monthsDictionary = {};

        for (const hoursAmounts of hoursAmountPerMonth) {
            for (const month of hoursAmounts) {
                const key = month.month + "-" + month.year;
                if (monthsDictionary[key]) {
                    monthsDictionary[key].hours += month.hours;
                } else {
                    monthsDictionary[key] = Object.assign({}, month);
                    result.push(monthsDictionary[key]);
                }
            }
        }

        return result.sort((a, b) => a.year - b.year || a.month - b.month);
    }

    render() {
        return ComponentUtils.bindTo(
            <ResourceAssignmentsTable
                resourceAssignments={this.totals}
                from={this.props.from}
                to={this.props.to}
                title={TextResources.Invoices.ResourcesAssignmentsReportTotalsTitle}
                className={this.props.className}
            />,
            this,
            "ratt"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ResourcesAssignmentsReport);
    reloadNow(ResourceAssignmentsTotals);
}
