import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 31/07/2017
 * Time: 17:52
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { People } from "../People";
import { PeopleMetadataForEditViewModel } from "../PeopleMetadataViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IPeopleService, IPeople, IPeopleMetadata, IEditPeopleResult } from "../../../../../ProlifeSdk/interfaces/survey/IPeopleService";

export class EditPeopleDialog implements IDialog {
    public templateName: string = "edit-people-dialog";
    public templateUrl: string = "survey/templates/people/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.AddPeopleDialogTitle;
    public modal: { close: (result?: any) => void; };

    public PeopleData: ko.Observable<any> = ko.observable();
    public PeopleMetadata: ko.ObservableArray<PeopleMetadataForEditViewModel> = ko.observableArray([]);
    public ActiveTab: ko.Observable<string> = ko.observable();

    public UpdateMode: ko.Observable<boolean> = ko.observable();
    public HasAddressBookAccess: ko.Observable<boolean> = ko.observable();

    public GeneralDataTab: ko.Computed<boolean>;
    public MetadataTab: ko.Computed<boolean>;
    public EmptyList: ko.Computed<boolean>;

    private peopleService: IPeopleService;
    private infoToastService: IInfoToastService;
    private authorizationsService: IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator, peopleId: number) {
        this.peopleService = <IPeopleService> this.serviceLocator.findService(ProlifeSdk.PeopleServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.authorizationsService = <IAuthorizationService> this.serviceLocator.findService(ServiceTypes.Authorization);

        this.HasAddressBookAccess(this.authorizationsService.isAuthorized("Customers_Start"));
        this.ActiveTab(ProlifeSdk.PeopleGeneralDataTab);

        if (!peopleId) {
            this.PeopleData(new People(this.newEmptyPeople()));
            this.UpdateMode(false);
        }

        if (peopleId) {
            this.title = ProlifeSdk.TextResources.Survey.EditPeopleDialogTitle;
            this.peopleService.getPeopleDetails(peopleId)
                .then((people: IPeople) => {
                    this.PeopleData(new People(people));
                    this.UpdateMode(true);
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleDetailsError);
                });
            this.peopleService.getPeopleMetadata(peopleId)
                .then((metadata: IPeopleMetadata[]) => {
                    this.PeopleMetadata(metadata.map((m: IPeopleMetadata) => {
                        return new PeopleMetadataForEditViewModel(m, this);
                    }));
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetPeopleMetadataError);
                });
        }

        this.GeneralDataTab = ko.computed(() => {
            return this.ActiveTab() == ProlifeSdk.PeopleGeneralDataTab;
        });

        this.MetadataTab = ko.computed(() => {
            return this.ActiveTab() == ProlifeSdk.PeopleMetadataTab;
        });

        this.EmptyList = ko.computed(() => {
            return this.PeopleMetadata().length == 0;
        });
    }

    action(): void {
        if (!this.validateForms()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.MissingPeopleData);
            return;
        }

        if (!this.UpdateMode()) {
            this.peopleService.addPeople(
                this.PeopleData().toJSON(),
                this.PeopleMetadata().map((m: PeopleMetadataForEditViewModel) => {
                    return m.toJSON();
                }))
                .then((result: IEditPeopleResult) => {
                    this.modal.close(result);
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Survey.AddPeopleError);
                });
            return;
        }

        this.peopleService.updatePeople(
            this.PeopleData().toJSON(),
            this.PeopleMetadata().map((m: PeopleMetadataForEditViewModel) => {
                return m.toJSON();
            }))
            .then((result: IEditPeopleResult) => {
                this.modal.close(result);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.UpdatePeopleError);
            });
        return;
    }

    close(): void {
        this.modal.close(null);
    }

    public SwitchTab(): void {
        this.ActiveTab(this.ActiveTab() == ProlifeSdk.PeopleGeneralDataTab ? ProlifeSdk.PeopleMetadataTab : ProlifeSdk.PeopleGeneralDataTab);
    }

    private newEmptyPeople(): IPeople {
        return <IPeople> {
            Id: null,
            BusinessName: null,
            Name: null,
            Surname: null,
            Address: null,
            CAP: null,
            City: null,
            Municipality: null,
            Province: null,
            Country: null,
            FiscalCode: null,
            PIVA: null,
            SourceDescription: null,
            ImportDate: null,
            DateOfBirth: null,
            Gender: null,
            PhoneNumber: null,
            Email: null,
            PEC: null,
            LastCall: null,
            CustomerId: null,
            IsCompany: false,
            CreationDate: null,
            CreatorId: null,
            ModifyDate: null,
            ModifierId: null,
            SourceId: null,
            ImporterId: null,
            RowIndexInSource: null
        };
    }

    private newEmptyMetadata(): IPeopleMetadata {
        return <IPeopleMetadata> {
            Id: null,
            PeopleId: null,
            MetaName: null,
            MetaType: null,
            IntegerValue: null,
            FloatValue: null,
            StringValue: null,
            BooleanValue: null,
            DateTimeOffsetValue: null
        };
    }

    public AddMetadata(): void {
        this.PeopleMetadata.push(new PeopleMetadataForEditViewModel(this.newEmptyMetadata(), this));
    }

    private validateForms(): boolean {
        var result: boolean = true;
        this.PeopleMetadata().forEach((m: PeopleMetadataForEditViewModel) => {
            if (m.MissingRequiredData())
                result = false;
        });
        return result;
    }
}