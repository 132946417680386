import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 28/05/13
 * Time: 15.04
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IEstimateState, IEstimateStates } from "../../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { Deferred } from "../../../../Core/Deferred";

export interface IEstimateStateProvider {
    update(estimateState : IEstimateState);
    getData() : IEstimateState;
}

export class EstimateStatesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IEstimateStateProvider> = ko.observableArray();
    NewEstimateStateDescription : ko.Observable<string> = ko.observable();
    NewEstimateStateDescriptionHasFocus : ko.Observable<boolean> = ko.observable(false);
    ShowDeleted : ko.Observable<boolean> = ko.observable(false);
    dialogsService : IDialogsService;
    toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private estimateStatesManager : IEstimateStates)
    {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.toastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.title = estimateStatesManager.getLabel();
        var estimateStates = estimateStatesManager.getEstimateStates();
        this.elements(estimateStates.map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(estimateState : IEstimateState) {
        return new EstimateState(this, estimateState);
    }

    public createOrUpdateEntry(state : IEstimateStateProvider, isLogicalStatusChanged : boolean = false) : Promise<IEstimateState>
    {
        if(!isLogicalStatusChanged)
            return this.createOrUpdateAfterConfirm(state);

        var d = new Deferred<IEstimateState>();

        this.dialogsService.Confirm(ProlifeSdk.TextResources.Invoices.LogicStateChangedMsg, ProlifeSdk.TextResources.Invoices.LogicStateChangedMsgCancel, ProlifeSdk.TextResources.Invoices.LogicStateChangedMsgConfirm, (confirm : boolean) => {
            if (!confirm)
            {
                d.resolve();
                return;
            }

            this.createOrUpdateAfterConfirm(state)
                .then((estimateState: IEstimateState) => { d.resolve(estimateState); })
                .catch(() => { d.reject([]); });
        });

        return d.promise();
    }

    public createOrUpdateAfterConfirm(estimateState : IEstimateStateProvider) : Promise<IEstimateState>
    {
        var data = estimateState.getData();
        return this.estimateStatesManager.createOrUpdate(data)
            .then((updatedEstimateState : IEstimateState) => {
                estimateState.update(updatedEstimateState)
                this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateSaved);
                return updatedEstimateState;
            });
    }

    public deleteEntry(state : IEstimateStateProvider)
    {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Invoices.DeleteStateMsg, ProlifeSdk.TextResources.Invoices.DeleteStateMsgCancel, ProlifeSdk.TextResources.Invoices.DeleteStateMsgConfirm, (confirm : boolean) => {
            if(!confirm)
                return;

            var data : IEstimateState = state.getData();
            this.estimateStatesManager.remove(data.IdStatoPreventivo)
                .then(() => {
                    this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateDeleted);
                    data.Eliminato = 1;
                    state.update(data);
                });
        });
    }

    public restore(state : IEstimateStateProvider)
    {
        var data : IEstimateState = state.getData();
        this.estimateStatesManager.restore(data.IdStatoPreventivo)
            .then((s : IEstimateState) => {
                this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateRestored);
                state.update(s);
            });
    }

    public addNewSetting() {
        if(!this.NewEstimateStateDescription()) return;

        var newEstimateState = this.createViewModelFor({
            Descrizione: this.NewEstimateStateDescription()
        });
        this.elements.push(newEstimateState);
        this.createOrUpdateEntry(newEstimateState);

        this.NewEstimateStateDescription("");
    }
}

class EstimateState implements IEstimateStateProvider {
    Description : ko.Observable<string> = ko.observable();
    State : ko.Observable<number> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    StateName : ko.Observable<string> = ko.observable();
    StateNames : ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.Invoices.Draft, ProlifeSdk.TextResources.Invoices.Accepted, ProlifeSdk.TextResources.Invoices.Rejected // N.B. L'ordine determina l'id salvato nel db perché viene preso l'indice del vettore
    ]);

    private updating : boolean = false;
    private IsVisible : ko.Computed<boolean>;
    public Deleted : ko.Observable<boolean> = ko.observable(false);
    public Editable : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : EstimateStatesEditingViewModel, private estimateState : IEstimateState) {
        this.update(estimateState);

        this.IsVisible = ko.computed(() => {
            return !this.Deleted() || container.ShowDeleted();
        });
    }

    public save()
    {
        if(this.updating || !this.Description())
            return;

        this.updating = true;

        var isLogicalStatusChanged : boolean = this.State() != this.estimateState.Stato;
        this.container.createOrUpdateEntry(this, isLogicalStatusChanged)
            .then(() => {
                this.cancelEdit();
            })
            .finally(() => {
                this.updating = false;
            });
    }

    public edit()
    {
        this.Editable(true);
    }

    public cancelEdit()
    {
        this.update(this.estimateState);
        this.Editable(false);
    }

    public update(estimateState : IEstimateState)
    {
        this.estimateState = estimateState;
        this.Description(estimateState.Descrizione);
        this.Deleted(estimateState.Eliminato == 1);
        this.setState(estimateState.Stato);
    }

    public getData() : IEstimateState {
        var newEstimateState = <IEstimateState> $.extend({}, this.estimateState);
        newEstimateState.Descrizione = this.Description();
        newEstimateState.Stato = this.State();
        return newEstimateState;
    }

    private restore()
    {
        this.container.restore(this);
    }

    private remove()
    {
        this.container.deleteEntry(this);
    }

    public setState(stateId : number) {
        this.State(stateId);
        this.StateName(this.StateNames()[stateId]);
    }
}