import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 13:31
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { CampaignTypeEditingViewModel } from "./ui/CampaignTypeEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICampaignTypeSettingManager } from "../../../../interfaces/ICampaignTypeSettingManager";
import { ICampaignType } from "../../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";

export class CampaignTypeSettingManager implements ICampaignTypeSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private campaignTypes: ICampaignType[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }

    getCampaignTypes(): ICampaignType[] {
        return this.campaignTypes;
    }

    getCampaignTypeById(id: number): ICampaignType {
        var types: ICampaignType[] = this.campaignTypes.filter((t: ICampaignType) => { return t.Id == id });
        return types[0];
    }

    load(): Promise<ICampaignType[]> {
        return this.ajaxService.Post<ICampaignType[]>("Survey-api/CampaignApi", "GetCampaignTypes", {})
            .then((types: ICampaignType[]) => { this.campaignTypes = types; return types; });
    }

    getName(): string {
        return ProlifeSdk.CampaignType;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.CampaignTypes;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "campaign-type",
            templateUrl: "survey/templates/campaign/settings",
            viewModel: new CampaignTypeEditingViewModel(this.serviceLocator, this)
        };
    }

    addOrUpdate(type: ICampaignType): Promise<ICampaignType> {
        return this.ajaxService.Post<ICampaignType>("Survey-api/CampaignApi", "AddOrUpdateCampaignType", {
            methodData: {
                Type: type
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/CampaignApi", "RemoveCampaignType", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: ICampaignType): void {
        var found: boolean = false;

        this.campaignTypes.forEach((s: ICampaignType) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Name = state.Name;
            s.Icon = state.Icon;
            s.Background = state.Background;
            s.Foreground = state.Foreground;

            found = true;
        });

        if (!found)
            this.campaignTypes.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.campaignTypes.length; i++) {
            if (this.campaignTypes[i].Id == id) {
                this.campaignTypes.splice(i, 1);
                break;
            }
        }
    }
}