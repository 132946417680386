import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ReportsViewer } from "./ReportsViewer";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IReportsServiceObserver, IReportsService } from "../../../ProlifeSdk/interfaces/report/IReportsService";
import { IReportsProvider } from "../../../ProlifeSdk/interfaces/report/IReportsProvider";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";

export class ReportsMenu implements IReportsNavigator, IReportsServiceObserver
{
    private reportsService : IReportsService;

    RootReportsProvider : IReportsProvider;
    ReportsProvidersStack : ko.ObservableArray<IReportsProvider> = ko.observableArray([]);
    CurrentView : ko.Computed<IReportsProvider>;
    CanGoBack : ko.Computed<boolean>;
    Title : ko.Computed<string>;

    constructor(serviceLocator : IServiceLocator, private viewer : ReportsViewer)
    {
        this.reportsService = <IReportsService> serviceLocator.findService(ProlifeSdk.ReportsServiceType);
        this.reportsService.RegisterObserver(this);
        this.RootReportsProvider = new ReportTypesProvider(serviceLocator);
        this.ReportsProvidersStack.push(this.RootReportsProvider);

        this.CurrentView = ko.computed(() => {
            if(this.ReportsProvidersStack().length == 0)
                return undefined;
            return this.ReportsProvidersStack()[this.ReportsProvidersStack().length - 1];
        });

        this.CanGoBack = ko.computed(() => {
            return this.ReportsProvidersStack().length > 1;
        });

        this.Title = ko.computed(() => {
            return ko.utils.unwrapObservable(this.CurrentView().Name);
        });

        this.RootReportsProvider.setCurrentNavigator(this);
    }

    OnReportsProviderRegistered(p : IReportsProvider)
    {
        this.RootReportsProvider.setCurrentNavigator(this);
    }

    navigateTo(report : IReportsProvider)
    {
        this.ReportsProvidersStack.push(report);
    }

    openReport(report : IReport)
    {
        if(this.ReportsProvidersStack().length > 1)
        {
            var provider : IReportsProvider = this.ReportsProvidersStack()[this.ReportsProvidersStack().length - 1];
            location.href = "#/Reports/" + provider.Id + "/" + report.Id;
            //this.viewer.showReport(report);
        }
    }

    GoBack()
    {
        this.ReportsProvidersStack.pop();
    }

    close()
    {
        location.href = "#/";
    }
}

class ReportTypesProvider implements IReportsProvider
{
    Id : number = -1;
    Name : string = ProlifeSdk.TextResources.Reports.ReportTypes;
    templateName : string = "report-folder-list-item";
    templateUrl : string = "reports/templates";

    Reports : ko.ObservableArray<IReportsProvider | IReport> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);

    private reportsService : IReportsService;

    constructor(serviceLocator : IServiceLocator)
    {
        this.reportsService = <IReportsService> serviceLocator.findService(ProlifeSdk.ReportsServiceType);
        this.Reports(this.reportsService.getRegisteredReportsProviders());
    }

    initialize()
    {
    }

    getReportById(reportId : number) : IReport
    {
        return null;
    }

    setCurrentNavigator(navigator : IReportsNavigator)
    {
        this.Reports().forEach((provider : IReportsProvider) => provider.setCurrentNavigator(navigator));
    }

    Open()
    {
        alert("Menu");
    }

    getViewModel() : IReportViewModel
    {
        return null;
    }
}