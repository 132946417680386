import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BlogEventBase } from "./BlogEventBase";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IUserInfo } from "../../interfaces/desktop/IUserInfo";
import { ILogService } from "../../interfaces/desktop/ILogService";
import { IDesktopService } from "../../interfaces/desktop/IDesktopService";
import { IEventBaseInput } from "../../interfaces/blog/IEventBaseInput";
import { IControlsEntityProvider } from "../../interfaces/IControlsEntityProvider";
import { ILogEvent } from "../../interfaces/ILogEvent";
import { ITag } from "../../interfaces/ITag";

export class EventBaseInput implements IEventBaseInput {
    //Services
    protected userService: IUserInfo;
    protected dialogService: IDialogsService;
    protected desktopService: IDesktopService;
    protected logService: ILogService;
    protected commessaSearchService: IControlsEntityProvider;

    //Event properties
    public pkEvent: ko.Observable<number> = ko.observable(0);
    public eventTime: ko.Observable<Date> = ko.observable();
    public eventDate: ko.Observable<Date> = ko.observable();

    //Decoration properties
    public title: string;
    public WorkSheets: ko.Observable<any> = ko.observable(); //L'oggetto che rappresenta la collezione di sheet
    public message: ko.Observable<string> = ko.observable("");
    public messageTrigger: ko.Observable<any> = ko.observable();

    public templateName: string;
    public templateUrl = "blog/Templates/events-details";
    public blogEvent: BlogEventBase;
    private originalValue: ILogEvent;
    private parent: ko.Observable<any> = ko.observable(null);
    public commessaId: ko.Observable<any> = ko.observable("");

    public iconName = "";

    constructor(protected serviceLocator: IServiceLocator, public contextEventsObserver: IContextEventsObserver) {
        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.commessaSearchService = entityProviderService
            .getEntityProvider(ProlifeSdk.JobOrderEntityTypeCode)
            .getControlsProvider();
        (<any>this.commessaSearchService).excludeClosed = true;

        this.userService = <IUserInfo>serviceLocator.findService(ProlifeSdk.UserInfoServiceType);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.logService = <ILogService>serviceLocator.findService(ProlifeSdk.LogServiceType);
    }

    public formatJobOrderItem(jobOrderItem) {
        if (!jobOrderItem.locked) return jobOrderItem.text;

        return (
            "<i class='fa fa-lock' title='" +
            String.format(
                ProlifeSdk.TextResources.ProlifeSdk.JobOrderLockedByWithMotivation,
                jobOrderItem.lockedByDescription,
                jobOrderItem.lockMotivationDescription
            ) +
            "'></i>" +
            jobOrderItem.text
        );
    }

    getChildEvents(): ILogEvent[] {
        //Per default nessun figlio
        return [];
    }

    //Fare override per customizzare il salvataggio
    public save(): Promise<ILogEvent | ILogEvent[]> {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.ProlifeSdk.Saving);

        const promise: Promise<ILogEvent | ILogEvent[]> =
            this.pkEvent() == 0
                ? this.logService.insertEvent(this.getBlogEvent())
                : this.logService.updateEvent([this.getBlogEvent()]);

        promise.finally(() => {
            this.desktopService.UnblockPageUI();
        });

        return promise;
    }

    public setValues(blogEvent: BlogEventBase) {
        this.blogEvent = blogEvent;
        this.pkEvent(blogEvent.IdEvent());
        this.message(blogEvent.Message());
        this.WorkSheets(blogEvent.WorkSheets());
        this.eventDate(blogEvent.ReferenceDate());
        this.commessaId(blogEvent.JobOrder() ? blogEvent.JobOrder().toString() : "");
        this.originalValue = blogEvent.originalValue;
    }

    getBaseBlogEvent(eventType: string, Tags: ITag[]): ILogEvent {
        Tags.push({
            TagName: ProlifeSdk.Tag_JobOrder,
            Value: this.commessaId(),
            TagTypeId: ProlifeSdk.JobOrderEntityType,
        });

        const referenceDate = moment(this.eventDate()).toDate();

        Tags.push({
            TagName: ProlifeSdk.Tag_ReferenceDate,
            TagTypeId: ProlifeSdk.TagType_DateTime,
            Value: referenceDate,
        });

        const blogEvent = <ILogEvent>{
            EventId: this.pkEvent() != 0 ? this.pkEvent() : undefined,
            EventType: eventType,
            Text: this.message(),
            WorkSheetsData: null,
            ModuleName: "Blog",
            TimeStamp: this.blogEvent == null ? new Date() : this.blogEvent.EventTime(),
            ReferenceDate: referenceDate,
            UserId: this.blogEvent == null ? 0 : this.blogEvent.EventUser(),
            Tags: Tags,
            EventData: {},
            CreatedBySystem: this.blogEvent == null ? false : this.blogEvent.CreatedBySystem(),
            Tasks: [],
            Locked: this.blogEvent == null ? false : this.blogEvent.Locked(),
            JobOrder: this.commessaId() && this.commessaId() != "" ? this.commessaId() : null,
        };

        if (this.blogEvent != null) blogEvent.FkParentId = this.blogEvent.FkParentId();
        else {
            if (this.parent() != null) {
                blogEvent.FkParentId = this.parent().IdEvent();
                blogEvent.UserId = this.userService.getIdUser();
            }
        }

        return blogEvent;
    }

    public isChanged(): boolean {
        const newItem: ILogEvent = this.getBlogEvent();
        const originalText = this.originalValue.Text ? this.originalValue.Text : "";
        const newItemText = newItem.Text ? newItem.Text : "";

        if (originalText != newItemText) return true;

        let globalChanged = false;
        newItem.Tags.forEach((tag: ITag) => {
            globalChanged =
                globalChanged || this.originalValue.Tags.filter((t: ITag) => this.areTagsEquals(tag, t)).length == 0;
        });

        return globalChanged;
    }

    private areTagsEquals(tag1: ITag, tag2: ITag): boolean {
        let tag1Value =
            tag1.TagTypeId == ProlifeSdk.TagType_DateTime ? moment(tag1.Value).toDate().valueOf() : tag1.Value;
        tag1Value = tag1Value ? tag1Value : "";

        let tag2Value =
            tag2.TagTypeId == ProlifeSdk.TagType_DateTime ? moment(tag2.Value).toDate().valueOf() : tag2.Value;
        tag2Value = tag2Value ? tag2Value : "";

        return tag1.TagName == tag2.TagName && tag1.TagTypeId == tag2.TagTypeId && tag1Value == tag2Value;
    }

    public getValidationMessage(): string[] {
        if (this.commessaId().length == 0) return [ProlifeSdk.TextResources.ProlifeSdk.SelectJobOrder];

        return [];
    }

    // fare override
    public getBlogEvent(): ILogEvent {
        return this.getBaseBlogEvent("Undefined", []);
    }

    public setBlogEvent(): void {
        if (this.blogEvent) this.blogEvent.LoadItemFromObject(this.getBlogEvent());
    }

    public SetParent(parent: any) {
        this.parent(parent);
        this.commessaId(parent.JobOrder() != null ? parent.JobOrder() : "");
    }

    public LocalClear() {}
}
