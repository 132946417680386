import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IMonthlyHour } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { HourApprovalViewModel } from "./HourApprovalViewModel";
import { _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";
import { HoursContainer } from "./HoursContainer";
import { JobOrderApprovalViewModel } from "./JobOrderApprovalViewModel";
import { ISelectableDate } from "./IDate";

export class TaskApprovalViewModel extends HoursContainer<never> {
    private resourceId: number;

    constructor(private hour: IMonthlyHour, private dates: ISelectableDate[], jobOrder: JobOrderApprovalViewModel, panel: _HoursApprovalPanelViewModel) {
        super(panel, jobOrder);

        this.id = hour.TaskId;
        this.type = ProlifeSdk.JobOrderTaskEntityTypeCode;
        this.name = hour.TaskName || ProlifeSdk.TextResources.WorkedHours.NoWorkLabel;
        this.info = this.hour.WorkflowName;
        this.resourceId = this.hour.ResourceId;

        for (const date of this.dates) {
            const approval = new HourApprovalViewModel({
                id: this.id,
                date: date.Date,
                isHoliday: date.IsHoliday,
                resourceId: this.resourceId
            }, date, this, this.panel);

            this.Hours.push(approval);
        }
    }

    public initialize(): void {
        super.initialize();

        const selectedValue = this.panel.getRowSelectionStateFromCache(this.getKeyForCache());
        this.Selected(selectedValue);

        this.Selected.subscribe((value: boolean) => {
            if (!this.id || this.panel.IsLoading())
                return;

            this.panel.cacheRowSelection(this.getKeyForCache(), value);
        });
    }
}
