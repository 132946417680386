import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IModulesService, IModulesServiceObserver } from "../ProlifeSdk/interfaces/desktop/IModulesService";

export class ModulesService implements IModulesService
{
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    private observers: IModulesServiceObserver[] = [];
    private enabledModules: { [code: string]: boolean };

    constructor(serviceLocator: IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IModulesService>(), this)
    }

    addObserver(observer : IModulesServiceObserver) : void
    {
        this.observers.push(observer);
    }

    removeObserver(observer : IModulesServiceObserver) : void
    {
        const index = this.observers.indexOf(observer);
        if(index == -1) return;
        this.observers = this.observers.splice(index, 1);
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getServiceType() : string
    {
        return ProlifeSdk.ModulesServiceType;
    }

    public IsModuleEnabled(moduleCode : string) : boolean
    {
        return this.enabledModules[moduleCode];
    }

    Refresh() : Promise<string[]>
    {
        return this.ajaxService.Post<string[]>("ModulesInfo", "", {})
            .then((enabledModules : string[]) => {
                this.enabledModules = {};

                for(let i = 0; i < enabledModules.length; i++)
                {
                    this.enabledModules[enabledModules[i]] = true;
                }

                for(let i = 0; i < this.observers.length; i++) {
                    this.observers[i].OnEnabledModulesLoaded(enabledModules);
                }
                return enabledModules;
            });
    }

    getModuleId(moduleCode: string): Promise<number> {
        return this.ajaxService.Post("ModulesInfo", "GetModuleId", {
            methodData: {
                moduleCode: moduleCode
            }
        });
    }
}

/*export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ModulesService(serviceLocator);
}*/