import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type AlertProps = {
    icon: string;
    foreground: string;
    tooltip: string;
};

export function Alert(props: AlertProps) {
    const C = require("./Alert")._Alert as typeof _Alert;
    return <C {...props} />;
}

export class _Alert {
    static defaultProps: Partial<AlertProps> = {};

    constructor(private props: AlertProps) {}

    render() {
        return ComponentUtils.bindTo(
            <div className="btn btn-default workflow-navigator-alert-icon">
                <i
                    className={this.props.icon}
                    style={{
                        color: this.props.foreground,
                        width: "1.25em",
                    }}
                    title={this.props.tooltip}
                ></i>
            </div>,
            this,
            "alert"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(Alert);
}
