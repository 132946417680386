import * as ko from "knockout";
import { Document } from "../../../../Invoices/invoices/documents/Document";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class DueDateAutomationDialog implements IDialog {
    templateName: string = "dueDateAutomation";
    templateUrl: string = "prolifesdk/templates/documents/automations";
    title: string = "Modifica Date di Consegna";
    modal: { close: (result?: any) => void; };

    public DueDate: ko.Observable<Date> = ko.observable();

    constructor(private document : Document) {

    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        
    }

    public setDueDates() {
        for(let row of this.document.Rows()) {
            row.DueDate(this.DueDate());
        }
        this.modal.close(true);
    }
}