import * as ko from "knockout";
import { BaseFilter } from "./BaseFilter";

export class ArrayFilter extends BaseFilter {

    constructor(propertyName, filterLabel,
                private filterProperty : string,
                private filterValue : string,
                private valueProperty : string) {
        super(propertyName, filterLabel);
    }

    GetValue(obj) : string[]
    {
        if(obj == null || !obj[this.propertyName] || Object.prototype.toString.call( obj[this.propertyName]) !== '[object Array]')
            return null;

        return obj[this.propertyName]
            .filter((item) => item[this.filterProperty] == this.filterValue)
            .map((item : any) => item[this.valueProperty]);
    }


}