import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { DocumentTaxRelief } from "../../../../Invoices/invoices/documents/DocumentTaxReliefEditor";
import { TaxReliefDataSource, TaxReliefDataSourceModel } from "../../../../DataSources/TaxReliefDataSource";
import { TextResources } from "../../../ProlifeTextResources";
import { Select } from "../../../../Components/Select";

const styleSheet = jss.createStyleSheet({
    taxReliefAutomation: {
        "& .row": {
            "width": "300px"
        }
    }
});
const { classes } = styleSheet.attach();

type TaxReliefAutomationDialogProps = {
    taxRelief: ko.ObservableArray<DocumentTaxRelief>;
}

export class TaxReliefAutomationDialog extends DialogComponentBase {
    static defaultProps: Partial<TaxReliefAutomationDialogProps> = {
    }

    private TaxReliefId: ko.Observable<number> = ko.observable();
    private SelectedTaxRelief: TaxReliefDataSourceModel;

    private taxReliefDataSource: TaxReliefDataSource;

    constructor(private props : TaxReliefAutomationDialogProps) {
        super({ popover: true });
        this.title(TextResources.Invoices.TaxReliefAutomationTitle);

        this.taxReliefDataSource = new TaxReliefDataSource();
    }
    
    action() {
        for (const taxReliefRow of this.props.taxRelief()) {
            if (this.SelectedTaxRelief) {
                taxReliefRow.TaxReliefId(this.SelectedTaxRelief.model.Id);
                taxReliefRow.onTaxReliefSelected(this.SelectedTaxRelief);
            }
            else {
                taxReliefRow.TaxReliefId(null);
                taxReliefRow.onTaxReliefDeselected(this.SelectedTaxRelief);
            }
        }

        this.modal.close();
    }

    private onTaxReliefSelect(model: TaxReliefDataSourceModel): void {
        this.SelectedTaxRelief = model;
    }
    
    private onTaxReliefDeselect(model: TaxReliefDataSourceModel): void {
        this.SelectedTaxRelief = undefined;
    }
    
    renderBody() {
        const taxReliefAutomation = this;

        return ComponentUtils.bindTo((
            <div className={classes.taxReliefAutomation}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Select
                                dataSource={this.taxReliefDataSource}
                                value={this.TaxReliefId}
                                placeholder={TextResources.Invoices.TaxReliefPlaceholder}
                                allowClear={true}

                                onSelect={this.onTaxReliefSelect.bind(this)}
                                onDeselect={this.onTaxReliefDeselect.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-primary btn-xs btn-circle" data-bind={{ click: taxReliefAutomation.action.bind(taxReliefAutomation) }}>
                            {TextResources.ProlifeSdk.Apply}
                        </button>
                    </div>
                </div>
            </div>
        ), this, "taxReliefAutomation");
    }
}