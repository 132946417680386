import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 04/05/2018
 * Time: 12:00
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ITrustsService, ITrustsValidationResult } from "../ProlifeSdk/interfaces/customer/ITrustsService";
import { ICustomerTrust, ITrustAttachment } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class TrustsService implements ITrustsService {
    private ajaxService : IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ITrustsService>(), this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType(): string {
        return ProlifeSdk.TrustsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetPagedTrusts(customerId: number, skip: number, count: number): Promise<ICustomerTrust[]> {
        return this.ajaxService.Post("Customers-api/Trusts", "GetPagedTrusts", {
            methodData: {
                CustomerId: customerId,
                Skip: skip,
                Count: count
            }
        });
    }

    GetBalance(customerId: number): Promise<number> {
        return this.ajaxService.Post("Customers-api/Trusts", "GetBalance", {
            methodData: {
                CustomerId: customerId
            }
        });
    }

    ValidateTrusts(trusts: ICustomerTrust[], customerId: number): Promise<ITrustsValidationResult> {
        return this.ajaxService.Post("Customers-api/Trusts", "ValidateTrusts", {
            methodData: {
                Trusts: trusts,
                CustomerId: customerId
            }
        });
    }

    GetTrustAttachments(trustId: number): Promise<ITrustAttachment[]> {
        return this.ajaxService.Post("Customers-api/Trusts", "GetTrustAttachments", {
            methodData: {
                TrustId: trustId
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new TrustsService(serviceLocator);
}