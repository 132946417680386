import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk"
import * as moment from "moment";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { WorkSheetInput } from "./details/WorkSheetInput";
import { WorkedHoursInput } from "./details/WorkedHoursInput";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IWorkSheetRow } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IWorkableElement } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkableElement";
import { Deferred } from "../../../../Core/Deferred";

export class WorkedHoursEvent extends BlogEvent implements IView
{
    private humanResourcesService : IHumanResourcesSettingsManager;
    private workedHoursService : IWorkedHoursService;

    public viewModel : any;

    public HoursId : ko.Observable<number> = ko.observable();
    public WorkerId: ko.Observable<number> = ko.observable(0);
    public WorkerName: ko.Observable<string> = ko.observable("");
    public Hyperlink : ko.Computed<string>;

    public HourRecord : ko.Observable<WorkSheetEventHourRecord> = ko.observable();
    public TotalHours : ko.Computed<number>;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Updatable(false);  //Per il momento non consento l'accesso al dettaglio

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.workedHoursService = <IWorkedHoursService> serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);

        this.iconClass = "fa-calendar";
        this.eventTypeName = "Ore lavorate";
        this.templateName = "worked-hours-event";
        this.templateUrl = "workedhours/templates/events";
        this.isDeleteLocked(true);

        this.WorkerId.subscribe((v) => {
            this.WorkerName("");

            if(!v || v <= 0)
                return;

            var user : IHumanResource = this.humanResourcesService.getHumanResourceById(v);
            this.WorkerName(this.humanResourcesService.getFullName(user));
        });

        this.HoursId.subscribe((v) => {
            this.HourRecord(undefined);

            if(!v)
                return;

            this.workedHoursService.GetWorkedHoursByIds([v]).then((rows: IWorkSheetRow[]) => {
                if(rows.length > 0)
                    this.HourRecord(rows.map((row) => { return new WorkSheetEventHourRecord(serviceLocator, row); })[0]);
            });
        });

        this.TotalHours = ko.computed(() => {
            return !this.HourRecord() ? 0 : this.HourRecord().Hours();
        });

        this.Hyperlink = ko.computed(() => {
            if(!this.ReferenceDate() || !this.WorkerId())
                return "#/WorkedHours";

            return "#/WorkedHours/" + moment(this.ReferenceDate()).format("DDMMYYYY") + "/" + this.WorkerId();
        });


        this.Clear();
    }

    Clear()
    {
        super.Clear();
        this.HoursId(0);
        this.WorkerId(0);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody()
            .then((b : string) => {

                var body = b;
                //Introdurre qui info aggiuntive sulle ore...
                def.resolve(body);
            })
            .catch(() => def.reject());
        
        return def.promise();
    }

    public GetMailSubject() : string
    {
        return String.format(ProlifeSdk.TextResources.WorkedHours.WorkedHoursEventMailSubject, moment(this.ReferenceDate()).format("L"));
    }

    getEditViewModel() : IEventBaseInput
    {
        return new WorkedHoursInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        this.Tags().forEach((item) => {

            this.HoursId(item.TagName() != ProlifeSdk.Tag_WorkedHours ? this.HoursId() : parseInt(item.Value()));
            this.WorkerId(item.TagName() != ProlifeSdk.TagWorkHours_Worker ? this.WorkerId() : parseInt(item.Value()));

        });
    }
}

export class WorkSheetEventHourRecord
{
    public Hours : ko.Observable<number> = ko.observable();
    public RoleDescription : ko.Observable<string> = ko.observable();
    public WorkPlaceDescr : ko.Observable<string> = ko.observable();
    public WorkTimeCategoryDescr : ko.Observable<string> = ko.observable();
    public ActivityContainerName : ko.Observable<string> = ko.observable(" - ");
    public ActivityName : ko.Observable<string> = ko.observable("");
    public Billable : ko.Observable<boolean> = ko.observable();
    public Billed : ko.Observable<boolean> = ko.observable();
    public Progress : ko.Observable<number> = ko.observable();
    public Notes : string;

    constructor(private serviceLocator : IServiceLocator, row : IWorkSheetRow)
    {
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var rolesManager : IUserCharactersSettingsManager = <IUserCharactersSettingsManager>settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
        var workTimeCategoriesManager : IWorkTimeCategoriesSettingsManager = <IWorkTimeCategoriesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType);
        var workedHoursService : IWorkedHoursService = <IWorkedHoursService> serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        this.Notes = (row.Description || "").trim();
        this.Hours(row.Hours);
        this.RoleDescription(rolesManager.getUserCharacterById(row.RoleId).Description);
        this.Billable(row.Billable);
        this.Billed(row.Billed);
        this.Progress(row.Progress);

        var workPlaces = [{ Id : 0, Description : ProlifeSdk.TextResources.WorkedHours.OnSite },
            { Id : 1, Description : ProlifeSdk.TextResources.WorkedHours.OffSite },
            { Id : 2, Description : ProlifeSdk.TextResources.WorkedHours.Away }];

        this.WorkPlaceDescr(workPlaces.filter((p) => {
            return p.Id == row.WorkPlaceId;
        })[0].Description);

        this.WorkTimeCategoryDescr(workTimeCategoriesManager.getById(row.WorkTimeCategoryId).Name);

        var containerId = row.ActivityContainerType == "JOR" ? row.JobOrderId : row.ProjectId;
        workedHoursService.GetWorkableElement(containerId, row.ActivityContainerType)
            .then((element : IWorkableElement) => {
                this.ActivityContainerName(element.Name);
            });

        if(row.ActivityType)
        {
            var activityId = row.ActivityType == "WIT" ? row.WorkItemId : row.TaskId;
            workedHoursService.GetWorkableElement(activityId, row.ActivityType)
                .then((element : IWorkableElement) => {
                    this.ActivityName(element.Name);
                });
        }
    }
}
