import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import { Table, ITableItem } from "../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../DataSources/IDataSource";
import { ComponentUtils, reloadNow, useEffect } from "../../Core/utils/ComponentUtils";
import { ICustomersDataSourceModel } from "../../DataSources/CustomersDataSource";
import { SecondaryRow } from "../../Components/TableComponent/SecondaryRow";
import { IQuotationForList, IQuotationService } from "../QuotationService";
import { LazyImport } from "../../Core/DependencyInjection";
import { ICustomerForList } from "../../ProlifeSdk/interfaces/customer/ICustomersService";
import { Layout } from "../../Components/Layouts";
import { Portlet } from "../../Components/Portlet";
import { BackButton } from "../../Components/BackButton";
import { QuotationForList } from "./QuotationEditor";

type QuotationsListProps = {
    customer: ko.Observable<ICustomerForList> | ko.Computed<ICustomerForList>;
    onQuotationSelected: (quotation: QuotationForList) => void;
    onClose: () => void;
}

type QuotationForListEx = QuotationForList & { State: string; StateColor: string; Collapsed: ko.Observable<boolean>; switchCollapsed: () => void; }
export class QuotationsList extends React.Component<QuotationsListProps> {
    quotations : ko.ObservableArray<QuotationForListEx> = ko.observableArray();
    title : ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IQuotationService>())
    private quotationService : IQuotationService;

    constructor(props : QuotationsListProps) {
        super(props);
    }

    private getStateColor(stateId: number) {
        switch(stateId) {
            case 0:
                return "#0089ff";
            case 1:
                return "rgb(255, 200, 0)";
            case 2:
                return "orange";
            case 3:
                return "rgb(100, 191, 0)";
            default:
                return "red";
        }
    }

    private getStateName(stateId: number) {
        switch(stateId) {
            case 0:
                return "Bozza";
            case 1:
                return "In Preparazione";
            case 2:
                return "Pronto";
            case 3:
                return "Generato";
            default:
                return "Sconosciuto";
        }
    }

    componentDidMount() {
        useEffect(() => {
            const customer = this.props.customer();
            if(!customer) return;

            this.title(customer.Name);

            const loadQuotations = async () => {
                const quotations = await this.quotationService.getQuotations(customer.Id);
                this.quotations(quotations.map(q => {
                    let collapsed = ko.observable(true);
                    return {
                        ...q,
                        FKJobOrder: ko.observable(q.FKJobOrder),
                        State: this.getStateName(q.FKState),
                        StateColor: this.getStateColor(q.FKState),
                        Collapsed: collapsed,
                        switchCollapsed: () => collapsed(!collapsed())
                    }
                }));
            }

            loadQuotations();
        }, [this.props.customer])
    }

    private createQuotationModel (quotation: QuotationForListEx) : IDataSourceModel<number, QuotationForListEx> {
        return {
            id: quotation.Id,
            title: quotation.Name,
            subTitle: quotation.Description,
            isLeaf: true,
            isGroup: false,
            model: quotation
        }
    }

    private renderQuotationsTable() {
        const { sortString } = ComponentUtils.useSorter<QuotationForListEx>();

        let quot : IDataSourceModel<number, QuotationForListEx>;
        return <Table id={1} showColumnSelector compact dataSource={{ array: this.quotations, factory: this.createQuotationModel }} rowAs="quot" scrollable>
                    <Column id={2} title="Nome" sorter={sortString(i => i.Name)}>
                        <div className="with-identifier">
                            <span className="identifier" data-bind={{ style: { 'background-color': (quot.model.StateColor) } }}></span>
                            <span style={{ marginLeft: 5 }} data-bind={{ text: quot.title }}></span>
                        </div>
                    </Column>
                    <Column id={3} title="Stato" style={{ width: 200 }} sorter={sortString(i => i.State)}>
                        <span data-bind={{ text: quot.model.State }}></span>
                    </Column>
                    <Column id={4} title="" style={{ width: 50, textAlign: 'right' }}>
                        <ColumnBody>
                            {(i : ITableItem<QuotationForListEx>) => (
                                <>
                                    <button className="btn btn-primary btn-xs" onClick={() => this.props.onQuotationSelected(i.Data.model)}>
                                        <i className="fa fa-search"></i>&nbsp;Dettaglio
                                    </button>
                                    <button className="btn btn-primary btn-xs" data-bind={{ click: quot.model.switchCollapsed.bind(quot.model) }}>
                                        <i className="fa" data-bind={{ css: { 'fa-angle-down': quot.model.Collapsed, 'fa-angle-up': !quot.model.Collapsed() }}}></i>
                                    </button>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                    <SecondaryRow if={() => "!quot.model.Collapsed()"}>
                        {(i) => (
                        <td colSpan={3} data-bind={{ style: { 'background-color': quot.model.StateColor }}}>
                            <div className="with-identifier" style={{ paddingRight: 0, marginRight: '-10px'}}>
                                <span className="identifier" data-bind={{ style: { 'background-color': (quot.model.StateColor) } }}></span>
                                <div style={{ marginLeft: '-5px', marginRight: '-5px', backgroundColor: 'white', width: '100%' }}>
                                    <iframe data-bind={{ iframeContent: quot.subTitle }} seamless style={{ border: 0, width: '100%' }} />
                                </div>
                            </div>
                            
                        </td>)}
                    </SecondaryRow>
                </Table>
    }

    render() {
        return  <Layout.Grid columns={["1fr"]} rows={["120px", "10fr"]} autoSizeMode={"min"}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                {/* <BackButton onClick={this.props.onClose} style={{ marginRight: '20px' }} /> */}
                                <Portlet.Header.Default title="Preventivi" subTitle={this.title} className="font-red-sunglo bold uppercase" />
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Layout.ScrollContainer>
                            {this.renderQuotationsTable()}
                        </Layout.ScrollContainer>
                    </Layout.Grid.Cell>
                </Layout.Grid>
    };
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationsList);
}