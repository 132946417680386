import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { ILogService, ILogEventType, ILogEventGroup } from "../ProlifeSdk/interfaces/desktop/ILogService";
import { IService } from "../Core/interfaces/IService";
import { ILogEvent, ILogEventEnvelope } from "../ProlifeSdk/interfaces/ILogEvent";
import { ILogFilter } from "../ProlifeSdk/interfaces/ILogFilter";
import { Deferred } from "../Core/Deferred";

export class LogService implements ILogService
{
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ILogService>(), this);
    }

    deleteEvent(eventId : number) : Promise<void>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "Delete", {
            methodData: { eventId: eventId }
        });
    }

    GetEventTypes() : Promise<ILogEventType[]>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        return this.ajaxService.Get("Blog-api", "EventTypes/GetProviders", { });
    }

    GetEventGroups() : Promise<ILogEventGroup[]>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        return this.ajaxService.Get("Blog-api", "EventTypes/GetGroups", { });
    }

    insertEvent(event : ILogEvent) : Promise<ILogEvent>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "Insert", {
            methodData: { value: event }
        });
    }

    changeLockStatus(eventsIds : number[], locked : boolean): Promise<void>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "ChangeLockStatus", {
            methodData: { 'eventsIds' : eventsIds, 'locked' : locked }
        });
    }

    updateEvent(event : ILogEvent[]): Promise<ILogEvent[]>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "Update", {
            methodData: { values: event }
        });
    }

    insertEventAndChildrenOfType(event : ILogEvent, childEvents : ILogEvent [], eventType : string) : Promise<ILogEvent>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "InsertWithChildrenOfType", {
            methodData: { eventToSave: event, childEvents : childEvents, eventType : eventType }
        });
    }

    updateEventAndChildrenOfType(event : ILogEvent, childEvents : ILogEvent [], eventType : string) : Promise<ILogEvent>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "UpdateWithChildrenOfType", {
            methodData: { eventToSave: event, childEvents : childEvents, eventType : eventType }
        });
    }

    hintSearch(value : string) : Promise<ILogEvent[]>
    {

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "HintSearch", {
            methodData: { value: value },
			background: true
        });
    }

    detailedSearch(logFilters : ILogFilter[], skip : number, count: number) : Promise<ILogEvent[]>
    {

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        return this.ajaxService.Post("Blog/Blog", "DetailedSearch", {
            methodData: { skip : skip, count : count, LogFilters: logFilters  },
			background: true
        });
    }

    detailedSearchOnJobOrders(logFilters : ILogFilter[], skip : number, count: number, jobOrdersIds : number[]) : Promise<ILogEvent[]>
    {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        var def = new Deferred<ILogEvent[]>();

        this.ajaxService.Post("Blog/Blog", "DetailedSearchOnJobOrders", {
            methodData: { skip : skip, count : count, jobOrdersIds : jobOrdersIds,LogFilters: logFilters  },
            background: true
        }).then((events : ILogEventEnvelope[]) => {
            var translatedEvents = events.map((event : ILogEventEnvelope) => {
                var translatedEvent : ILogEvent = {
                    EventId: event.EventId,
                    TimeStamp: event.EventTime,
                    ReferenceDate: event.ReferenceDate,
                    CreatedBySystem: event.CreateBySystem > 0,
                    Locked: event.Locked != 0,
                    EventType: event.EventTypeId,
                    ModuleName: event.ModuleName,
                    Text: "",
                    UserId: event.UserId,
                    EventData: null,
                    Tags : [
                        { TagName: ProlifeSdk.Tag_Subject, TagTypeId: ProlifeSdk.TagType_String, Value: event.Title },
                        { TagName: ProlifeSdk.Tag_State, TagTypeId: ProlifeSdk.TagType_String, Value: event.Status },
                        { TagName: ProlifeSdk.Tag_Priority, TagTypeId: ProlifeSdk.TagType_String, Value: event.Priority },
                        { TagName: ProlifeSdk.Tag_TracingQuality, TagTypeId: ProlifeSdk.TagType_String, Value: event.InQuality }
                    ],
                    FkParentId: null,
                    ChildrenEvents: [],
                    Tasks: [],
                    JobOrder: event.JobOrder,
                    RelatedTasks: event.RelatedTasks
                };

                if(event.HasAttachments) {
                    translatedEvent.Tags.push({
                        TagName: ProlifeSdk.Tag_File, TagTypeId: ProlifeSdk.TagType_DocumentReference, Value: ''
                    });
                }

                return translatedEvent;
            });

            def.resolve(translatedEvents);
        }).catch(() => {
            def.reject();
        });

        return def.promise();
    }

    getTagSum(tagName : string,ValueTypeId : string,logFilters : ILogFilter[]) : Promise<number>
    {

		this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

		return this.ajaxService.Post("Blog/Blog", "getTagSum", {
			methodData: { tagName: tagName, ValueTypeId : ValueTypeId, LogFilters: logFilters  },
			background: true
		});
	}

    getTagDistinct(tagName : string,ValueTypeId : string, logFilters : ILogFilter[]) : Promise<string[]>
    {

		this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

		return this.ajaxService.Post("Blog/Blog", "getTagDistinct", {
			methodData: { tagName: tagName, ValueTypeId : ValueTypeId, LogFilters: logFilters  },
			background: true
		});
	}

    get(eventsId : number[]) : Promise<ILogEvent[]>
    {
		this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

		return this.ajaxService.Post("Blog/Blog", "Get", {
			methodData: { eventsId: eventsId}});
	}

    getServiceType() : string
    {
        return ProlifeSdk.LogServiceType;
    }

    isOfType(serviceType: string) : boolean{
        return serviceType == this.getServiceType();
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new LogService(serviceLocator);
}