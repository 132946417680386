import * as ko from "knockout";
import jss from "jss";
import { ICurrenciesSettingsManager } from "../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { PurchaseTypesDataSource, IPurchaseTypesDataSourceModel } from "../../../DataSources/PurchaseTypesDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Right } from "../../../Core/Authorizations";
import { IDataSourceListener, IDataSource } from "../../../DataSources/IDataSource";
import { IDialogsService, IPopoverComponentInfo } from "../../../Core/interfaces/IDialogsService";
import { IAttachmentsManager, IAttachmentsManagerPathProvider } from "../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { IExpenseService, IFullExpenseExpensesCostRows, IFullExpenseExpensesCostRowsAttachments } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";

const { classes } = jss.createStyleSheet({
    "expenses-attachments-popover": {
        width: "500px",
        "max-height": "300px"
    }
}).attach();

export interface IExpenseCostRowListener {
    onPurchaseTypeChanged(purchaseType: number): void;
}

export class ExpenseCostRow implements IDataSourceListener {
    public get Id(): number {
        return this.m_id;
    }

    public Description: ko.Observable<string> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public UnitCost: ko.Observable<number> = ko.observable();
    public CurrencyId: ko.Observable<number> = ko.observable();
    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public ExchangeValue: ko.Observable<number> = ko.observable();
    public PurchaseTypeId: ko.Observable<number> = ko.observable();
    public AttachmentsIds : ko.ObservableArray<string> = ko.observableArray([]);
    public IsSustainedByResource : ko.Observable<boolean> = ko.observable();

    public TotalCost: ko.Computed<number>;
    public HasAttachments: ko.Computed<boolean>;
    public AttachmentsNumber: ko.Computed<number>;
    public MoneyFieldClasses: ko.Computed<string>;

    public PurchaseTypesDataSource: PurchaseTypesDataSource = new PurchaseTypesDataSource();

    public attachmentsManager: IAttachmentsManager;

    @Right("Expense_EditCompanyCosts")
    public canEditCompanyCosts: boolean;
    @Right("Expense_ExpensesAdministrativeEdit")
    public expenseAdministrativeEdit: boolean;
    @Right("Expense_EditCurrencyOnRows")
    public canEditCurrency: boolean;

    public CanEditRow: boolean = false;
    public CurrencyEditingEnabled: boolean = false;
    
    private listeners: IExpenseCostRowListener[] = [];

    private m_id: number;

    @LazyImport(nameof<IExpenseService>())
    private expenseService: IExpenseService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(private attachmentsManagerPathProvider: IAttachmentsManagerPathProvider, private row: IFullExpenseExpensesCostRows = null, private attachments: IFullExpenseExpensesCostRowsAttachments[] = null) {
        let currencyId = this.row?.FkCurrency;
        let currency = !currencyId ? this.currenciesSettingsManager.getDefaultCurrency() : this.currenciesSettingsManager.getCurrencyById(currencyId);

        this.m_id = this.row?.Id ?? this.expenseService.GetTemporaryId();

        this.Description(this.row?.Description);
        this.Amount(this.row?.Amount ?? 1);
        this.UnitCost(this.row?.UnitCost);
        this.CurrencyId(currency.Id);
        this.CurrencySymbol(currency.Symbol);
        this.ExchangeValue(this.row?.ExchangeValue ?? 1);
        this.PurchaseTypeId(this.row?.FkPurchaseType);
        this.AttachmentsIds(this.attachments?.map(a => a.AttachmentId) ?? []);
        this.IsSustainedByResource(this.row?.IsSustainedByResource ?? (this.canEditCompanyCosts ? false : true));

        this.AttachmentsNumber = ko.computed(() => {
            return (this.AttachmentsIds() || []).length;
        });

        this.HasAttachments = ko.computed(() => {
            return this.AttachmentsNumber() > 0;
        });

        this.MoneyFieldClasses = ko.computed(() => {
            let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            
            if ((defaultCurrency?.Id ?? this.CurrencyId()) === this.CurrencyId())
                return "unit-cost-col text-right";

            return "unit-cost-col text-right bg-red";
        });

        this.CurrencyId.subscribe((currencyId: number) => {
            let currency = this.currenciesSettingsManager.getCurrencyById(currencyId);
            this.CurrencySymbol(currency.Symbol);
        });

        this.TotalCost = ko.computed(() => {
            let unitCost = (this.UnitCost() || 0);
            let amount = (this.Amount() || 0);
            let exchangeValue = (this.ExchangeValue() || 1);

            return unitCost * amount * exchangeValue;
        });

        this.CanEditRow = this.expenseAdministrativeEdit || this.canEditCompanyCosts || this.IsSustainedByResource();
        this.CurrencyEditingEnabled = this.CanEditRow && this.canEditCurrency;
    }
    
    public onItemSelected(sender: IDataSource, model: IPurchaseTypesDataSourceModel): void {
        this.listeners.forEach(l => l.onPurchaseTypeChanged(model?.id));
    }
    
    public onItemDeselected(sender: IDataSource, model: IPurchaseTypesDataSourceModel): void {
        this.listeners.forEach(l => l.onPurchaseTypeChanged(model?.id));
    }
    
    public addListener(listener: IExpenseCostRowListener): void {
        if (this.listeners.indexOf(listener) >= 0)
            return;

        this.listeners.push(listener);
    }

    public removeListener(listener: IExpenseCostRowListener): void {
        this.listeners.remove(listener);
    }

    public async manageAttachments(item: any, event: Event): Promise<void> {
        let componentInfo: IPopoverComponentInfo = {
            componentName: "attachments-manager-popover",
            title: TextResources.Expenses.AttachmentsManagerTitle,
            model: {
                AttachmentsIds: this.AttachmentsIds,
                AttachmentsManagerPathProvider: this.attachmentsManagerPathProvider,
                InjectTo: ko.observable(),

                close: function() {
                    let popOver = this.InjectTo();

                    if (popOver)
                        popOver.onClose();

                    this.modal.close(null);
                }

            },
            params: "AttachmentsIds: AttachmentsIds, AttachmentsManagerPathProvider: AttachmentsManagerPathProvider, InjectTo: InjectTo"
        };

        return this.dialogsService.ShowPopoverComponent(event.currentTarget as HTMLElement, componentInfo, "left", ".modal-body", classes["expenses-attachments-popover"], ".modal-body");
    }

    public getData(): IFullExpenseExpensesCostRows {
        this.row = this.row || ({} as IFullExpenseExpensesCostRows);

        this.row.Description = this.Description();
        this.row.Amount = this.Amount();
        this.row.UnitCost  = this.UnitCost();
        this.row.FkCurrency = this.CurrencyId();
        this.row.ExchangeValue = this.ExchangeValue();
        this.row.FkPurchaseType = this.PurchaseTypeId();
        this.row.IsSustainedByResource = this.IsSustainedByResource();

        if (!this.row.Id)
            this.row.Id = this.m_id;

        return this.row;
    }

    public getAttachmentsData(): IFullExpenseExpensesCostRowsAttachments[] {
        if (!this.row.Id)
            this.row.Id = this.expenseService.GetTemporaryId();

        return this.AttachmentsIds().map(a => ({
            RowId: this.row.Id,
            AttachmentId: a
        }));
    }
}