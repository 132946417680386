import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 16/06/2017
 * Time: 19:16
 * To change this template use File | Settings | File Templates.
 */

import { NavigationProvider } from "../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { INavigationMenu } from "../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { Deferred } from "../../../../../Core/Deferred";

export class SurveyNavigationProvider extends NavigationProvider {

    public Icon: string = "";
    public Background: string = "";
    public Foreground: string = "";

    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, title: string, icon: string) {
        super();
        this.Name = title;
        this.Icon = icon;
        this.templateName = "app-menu-item";
        this.templateUrl = "survey/templates/navigation";
        this.IsLeaf = true;

        this.IsSelected.subscribe(this.notifySelection.bind(this));
    }

    setCurrentNavigator(navigator : INavigationMenu)
    {
        super.setCurrentNavigator(navigator);
    }

    refreshItems() : Promise<void>
    {
        var deferred = new Deferred<void>();
        deferred.resolve();
        return deferred.promise();
    }

    private notifySelection() {
        this.navigator().notifySelectionChange();
    }
}