import { DateTime, TempusDominus, Namespace, Options } from "@eonasdan/tempus-dominus";
import { ChangeEvent } from "@eonasdan/tempus-dominus/types/utilities/event-types";
import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.css";
import * as ko from "knockout";
import * as moment from "moment";

export class DateValue {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return "";
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format("L");
            },
            write: function (newValue) {
                if (ko.isObservable(underlyingObservable)) {
                    var newDate = moment(newValue, "L").toDate();
                    if (newDate.valueOf() != underlyingObservable().valueOf()) underlyingObservable(newDate);
                }
            },
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

export class DateText {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const noValue = allBindingsAccessor()["dateTextNoValue"] || "";
        const format = allBindingsAccessor()["customDateTimeFormat"] || "L";

        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return noValue;
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format(format);
            },
            write: function (newValue) {
                if (ko.isObservable(underlyingObservable)) underlyingObservable(moment(newValue, format).toDate());
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class ExtendedDateText {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return "";
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format("dddd LL");
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class DateTimeText {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const noValue = allBindingsAccessor()["dateTextNoValue"] || "";
        const format = allBindingsAccessor()["customDateTimeFormat"] || "L LT";
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return noValue;
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format(format);
            },
            write: function (newValue) {
                underlyingObservable(moment(newValue, format).toDate());
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class DateTimeSecondsText {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const noValue = allBindingsAccessor()["dateTextNoValue"] || "";
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return noValue;
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format("L LTS");
            },
            write: function (newValue) {
                underlyingObservable(moment(newValue, "L LTS").toDate());
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class CalendarDateTimeText {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return "";
                return moment(ko.utils.unwrapObservable(underlyingObservable)).calendar();
            },
            write: function (newValue) {
                underlyingObservable(moment(newValue, "L LT").toDate());
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class ExtendedDateTimeText {
    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const interceptor = ko.computed({
            read: function () {
                if (
                    ko.utils.unwrapObservable(underlyingObservable) == null ||
                    ko.utils.unwrapObservable(underlyingObservable) == "" ||
                    ko.utils.unwrapObservable(underlyingObservable) == undefined
                )
                    return "";
                return moment(ko.utils.unwrapObservable(underlyingObservable)).format("LLLL");
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class TimezonedDateTimeText {
    update(
        element: HTMLElement,
        valueAccessor: () => any,
        allBindingsAccessor: ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        let value = valueAccessor();
        let timezone = allBindingsAccessor.get("timezone");

        let interceptor = ko.computed(() => {
            if (
                ko.utils.unwrapObservable(value) == null ||
                ko.utils.unwrapObservable(value) == "" ||
                ko.utils.unwrapObservable(value) == undefined
            )
                return "";

            return moment.tz(ko.utils.unwrapObservable(value), timezone).format("L LTS Z");
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

type NewDateValueProps = {
    value: ko.Observable<Date> | ko.Computed<Date>;
    parent?: string;
    minDate?: Date;
    maxDate?: Date;
    allowClear?: boolean;
    initialDate?: Date | ko.Observable<Date> | ko.Computed<Date>;
    dateOnly?: boolean;
};

class NewDateValue {
    init(
        element: HTMLElement,
        valueAccessor: () => NewDateValueProps,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const value = valueAccessor();
        const initialDate = ko.unwrap(value.initialDate);
        const options: Options = {
            display: {
                viewMode: "calendar",
                theme: "light",
                components: {
                    decades: true,
                    year: true,
                    month: true,
                    date: true,
                    hours: !value.dateOnly,
                    minutes: !value.dateOnly,
                    seconds: !value.dateOnly,
                },
                buttons: {
                    clear: value.allowClear,
                    close: true,
                    today: true,
                },
                icons: {
                    next: "fa fa-chevron-right",
                    previous: "fa fa-chevron-left",
                    close: "fa fa-times",
                    today: "fa fa-calendar",
                    clear: "fa fa-trash-o",
                },
            },
            container: value.parent ? document.querySelector(value.parent) : undefined,
            restrictions: {
                minDate: value.minDate as DateTime,
                maxDate: value.maxDate as DateTime,
            },
            useCurrent: !value.allowClear,
        };
        if (initialDate) {
            options.defaultDate = initialDate as DateTime;
            options.viewDate = initialDate as DateTime;
        }

        const td = new TempusDominus(element, options);
        td.dates.formatInput = function (date) {
            if (!date) {
                if (value.allowClear) return "";
                date = undefined;
            }
            return moment(date).format(value.dateOnly ? "L" : "L LT");
        };
        td.dates.parseInput = (val: string | Date) => {
            return new DateTime(moment(val, value.dateOnly ? "L" : "L LT").toDate());
        };

        if (initialDate) {
            td.dates.setFromInput(initialDate);
        }

        const dispose = td.subscribe(Namespace.events.change, (event: ChangeEvent) => {
            if (event.isClear) value.value(undefined);
            else value.value(event.date);
        });

        const disposeSub = value.value.subscribe((val: Date) => {
            if (!val) td.dates.clear();
            else if (val.valueOf() !== td.dates.lastPicked?.valueOf()) td.dates.setFromInput(val);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if (Array.isArray(dispose)) dispose.forEach((d) => d.unsubscribe());
            else dispose.unsubscribe();

            disposeSub.dispose();
            td.dispose();
        });
    }
}

ko.bindingHandlers["dateValue"] = new DateValue();
ko.bindingHandlers["dateText"] = new DateText();
ko.bindingHandlers["extendedDateText"] = new ExtendedDateText();
ko.bindingHandlers["dateTimeText"] = new DateTimeText();
ko.bindingHandlers["dateTimeSecondsText"] = new DateTimeSecondsText();
ko.bindingHandlers["calendarDateTimeText"] = new CalendarDateTimeText();
ko.bindingHandlers["extendedDateTimeText"] = new ExtendedDateTimeText();
ko.bindingHandlers["timezonedDateTimeText"] = new TimezonedDateTimeText();
ko.bindingHandlers["newDateValue"] = new NewDateValue();

ko.virtualElements.allowedBindings.dateText = true;
ko.virtualElements.allowedBindings.dateTimeText = true;
ko.virtualElements.allowedBindings.dateTimeSecondsText = true;
ko.virtualElements.allowedBindings.calendarDateTimeText = true;
ko.virtualElements.allowedBindings.extendedDateTimeText = true;
ko.virtualElements.allowedBindings.timezonedDateTimeText = true;
