import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IManufacturersService, IManufacturer } from "../ProlifeSdk/interfaces/warehouse/IManufacturersService";

export class ManufacturerText
{
    init(element: any, valueAccessor: () => any): void
    {
        ManufacturerText.refreshContent(element, valueAccessor()());
    }

    update(element: any, valueAccessor: () => any): void
    {
        ManufacturerText.refreshContent(element, valueAccessor()());
    }

    private static refreshContent(element : any, id : number)
    {
        $(element).text("");

        if(id == null || id == undefined)
            return;

        var manufacturerService = <IManufacturersService> Core.serviceLocator.findService(ProlifeSdk.ManufacturersServiceType);
        manufacturerService.GetManufacturersByIds([id])
            .then((m : IManufacturer[]) => {
                let man = m.length > 0 ? m[0] : null;
                var description = man && man.Code ? man.Code : "";
                description += man && man.Piva ? " (" + man.Piva + ")" : "";
                $(element).text(description);
            });
    }
}

ko.bindingHandlers["manufacturerText"] = new ManufacturerText();