import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IWarehouseInspectionDestinationProtocol } from "../../../WarehouseInspectionsService";
import { VatRegistersDataSource } from "../../../../DataSources/VatRegistersDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";

export interface IWarehouseInspectionDestinationProtocolProps {
    protocol: IWarehouseInspectionDestinationProtocol;

    onChanges?: (protocol: IWarehouseInspectionDestinationProtocol) => void;
}

@DetectClassChanges
export class WarehouseInspectionDestinationProtocol {
    @DetectChanges
    public VatRegisterId : ko.Observable<number> = ko.observable();
    public VatRegisterLabel : ko.Observable<string> = ko.observable();

    public VatRegistersDataSource: VatRegistersDataSource = new VatRegistersDataSource();

    public isChanged: ko.Observable<number> = ko.observable(0);

    private onChangeSubscription: ko.Subscription = null;

    constructor(public props: IWarehouseInspectionDestinationProtocolProps) {
        this.VatRegistersDataSource.setDocumentType(this.props.protocol.DocumentType, this.props.protocol.FiscalDocumentType);
        this.VatRegistersDataSource.getOnlyInventoryAdjustment(this.props.protocol.IsInventoryAdjustment);

        this.VatRegisterId(this.props.protocol.FKRegister);
        this.VatRegisterLabel(this.getVatRegisterLabel(this.props.protocol));

        this.isChanged(0);

        this.onChangeSubscription = this.isChanged.subscribe(() => {
            if (this.props.onChanges)
                this.props.onChanges(this.getData());
        });
    }

    public componentWillUnmount() {

    }

    public getData(): IWarehouseInspectionDestinationProtocol {
        let data = Object.assign({}, this.props.protocol) as IWarehouseInspectionDestinationProtocol;
        data.FKRegister = this.VatRegisterId();
        return data;
    }

    public render() {
        return ComponentUtils.bindTo((
            <div className="flex-container">
                <div className="flex-1">
                    <select2 dataSource={() => "destinationProtocol.VatRegistersDataSource"} value={() => "destinationProtocol.VatRegisterId"} allowClear={false} label={() => "destinationProtocol.VatRegisterLabel"}></select2>
                </div>
            </div>
        ), this, "destinationProtocol");
    }

    public dispose() {}

    private getVatRegisterLabel(protocol: IWarehouseInspectionDestinationProtocol): string {
        if (protocol.DocumentType === ProlifeSdk.DdtEntityTypeCode)
            return TextResources.Warehouse.WarehouseTransferDestinationProtocol;

        if (protocol.DocumentType === ProlifeSdk.WarehouseLoadEntityTypeCode && protocol.IsInventoryAdjustment)
            return TextResources.Warehouse.InventoryAdjustmentDestinationProtocol;

        throw new Error("Unsupported document type");
    }
}