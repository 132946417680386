import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import jss from "jss";
import { SelectedArticleInfo, SelectedArticleOperationInfo } from "./SelectedArticleInfo";
import { IDataSourceModel } from "../../../../../../DataSources/IDataSource";
import { Table, ITableItem } from "../../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { SelectedArticlesInfoTable } from "./SelectedArticlesInfoTable";
import { ArticleInfo } from "../../../../../../Provisioning/Provisioning/ui/panels/ArticleInfo";
import { ComponentUtils } from "../../../../../../Core/utils/ComponentUtils";

const { classes } = jss.createStyleSheet({
    selectedArticlesInfo: {
        "& .operation-col": {
            width: "30%"
        },

        "& amount-col": {
            width: "15%"
        },

        "& .action-col": {
            width: "40px"
        }
    }
}).attach();

export interface ISelectedArticlesOperationsInfoProps {
    operations: ko.ObservableArray<SelectedArticleOperationInfo>;
    factory: (operation: SelectedArticleOperationInfo) => IDataSourceModel<number, SelectedArticleOperationInfo>;

    showAmountColumn?: boolean;
}

export function SelectedArticleOperationsInfo(props: ISelectedArticlesOperationsInfoProps) {

    let selectedArticlesInfoTable: SelectedArticlesInfoTable;
    let selectedArticleInfo: IDataSourceModel<number, SelectedArticleInfo>;
    let operationInfo: IDataSourceModel<number, SelectedArticleOperationInfo>;

    let { sortString, sortNumber } = ComponentUtils.useSorter<SelectedArticleOperationInfo>();

    return (
        <Table compact={true} fixedLayout={true} dataSource={{ array: props.operations, factory: props.factory }} rowAs="operationInfo" className={classes.selectedArticlesInfo}>
            <Column title={TextResources.Warehouse.Article} sorter={sortString(m => m.ArticleCode)}>
                <ColumnBody>
                    {(item: ITableItem<SelectedArticleOperationInfo>) => (<ArticleInfo code={item.Data.model.ArticleCode} description={item.Data.model.ArticleDescription}></ArticleInfo>)}
                </ColumnBody>
            </Column>
            <Column title={TextResources.Warehouse.InspectonOperation} className="operation-col" sorter={sortString(m => m.OperationType === 1 ? TextResources.Warehouse.StockCheck : TextResources.Warehouse.WarehouseTransfer)}>
                <ko-bind data-bind={{ if: operationInfo.model.OperationType === 1 }}>
                    <span>{TextResources.Warehouse.StockCheck}</span>
                </ko-bind>
                <ko-bind data-bind={{ if: operationInfo.model.OperationType === 0 }}>
                    <span>{TextResources.Warehouse.WarehouseTransfer}</span>
                </ko-bind>
            </Column>
            {props.showAmountColumn && <Column title={TextResources.Warehouse.Amount} className="amount-col" sorter={sortNumber(m => m.OperationType === 0 ? m.Amount : 0)}>
                <ko-bind data-bind={{ if: operationInfo.model.OperationType === 0 }}> {/* Mostro il campo quantità solo se è un trasferimento di magazzino */}
                    <span data-bind={{ numberText: operationInfo.model.Amount, format: '0,0' }}></span>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: operationInfo.model.OperationType === 0 }}>
                    N/A
                </ko-bind>
            </Column>}
            <Column className="action-col">
                <button type="button" className="btn btn-danger btn-xs" data-bind={{ click: selectedArticlesInfoTable.deleteOperation.bind(selectedArticlesInfoTable, operationInfo.model, selectedArticleInfo.model) }}>
                    <i className="fa fa-trash-o"></i>
                </button>
            </Column>
        </Table>
    );
}