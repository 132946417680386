import { Validator } from "../../../ProlifeSdk/ValidationService";

function isCodiceFiscale(cf){
	var validi, i, s, set1, set2, setpari, setdisp;
	cf = cf.toUpperCase();

	validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	for( i = 0; i < 16; i++ ){
		if( validi.indexOf( cf.charAt(i) ) == -1 )
			return false;
	}
	set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
	setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
	s = 0;
	for( i = 1; i <= 13; i += 2 )
		s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
	for( i = 0; i <= 14; i += 2 )
		s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
	if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
		return false;

	return true;
};

function isPartitaIva(val)
{
	var matches = val.match(/([A-Z]{2})([0-9]*)/i);
    if(matches && matches.length == 3) {
		if(matches[1].toUpperCase() != "IT")
			return true;
			
        val = matches[2];
    }

	var code = val.toString().substring(7, 10);
	if (!(parseInt(code) > 0 && parseInt(code) < 122))
		return false;

	var x = 0;
	var y = 0;
	var z = 0;
	var T = 0;

	for (let i = 0; i < val.length; i++) {
		if (((i + 1) % 2) == 0) {
			y = y + parseInt(val[i]);
			if (parseInt(val[i]) >= 5) {
				z = z + 1;
			}
		} else {
			x = x + parseInt(val[i]);
		}
	}
	T = (x + (2 * y) + z) % 10;
	return T == 0;
}

Validator.prototype["mustBeCfiscOrPiva"] = function<T,V>(this: Validator<T>, selector: (value: T) => V, errorMessage?: string, onlyIf?: (value: T) => boolean, canBeNull?: () => boolean) {
    this.rules.push({
        validate: (value: T) => {
            if(onlyIf && !onlyIf(value))
                return this.ok();

            let val = selector(value);
			if((val === null || val === undefined) && (!canBeNull || !canBeNull()))
                return this.error(errorMessage ?? "Il valore deve essere un Codice Fiscale o una partita IVA valida: " + selector.toString());
			else if (!val) {
				return this.ok();
			}

            if(!isCodiceFiscale(val) && !isPartitaIva(val)) {
                return this.error(errorMessage ?? "Il valore deve essere un Codice Fiscale o una partita IVA valida: " + selector.toString());
            }
            return this.ok();
        }
    });
    return this;
};

declare module "../../../ProlifeSdk/ValidationService" {
    interface IValidator<T> {
        mustBeCfiscOrPiva?(selector : (value : T) => string, errorMessage?: string, onlyIf?: () => boolean, canBeNull?: () => boolean) : IValidator<T>;
    }
}