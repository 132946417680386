import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { MailRecipientTypesDataSource } from "../DataSources/MailRecipientTypesDataSource";
import { SelectMultiple } from "./SelectMultiple";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { Select } from "./Select";
import { MetadataLogicalTypeSource } from "../ProlifeSdk/interfaces/customer/ICustomersService";

type MailRecipientTypeSelectorProps = {
    recipientTypes: ko.ObservableArray<string | number> | ko.Observable<string | number>;
    dataSources?: MetadataLogicalTypeSource[];
    allowFixedAddresses?: ko.Subscribable<boolean> | boolean;
    multiple?: boolean;
    readonly: ko.Observable<boolean> | ko.Computed<boolean>;
};

export class MailRecipientTypeSelectorUI {
    constructor(private props: MailRecipientTypeSelectorProps) {}

    render() {
        return <MailRecipientTypeSelector {...this.props} />;
    }
}

export function MailRecipientTypeSelector(props: MailRecipientTypeSelectorProps) {
    const C = require("./MailRecipientTypeSelector")._MailRecipientTypeSelector as typeof _MailRecipientTypeSelector;
    return <C {...props} />;
}

export class _MailRecipientTypeSelector {
    static defaultProps: Partial<MailRecipientTypeSelectorProps> = {
        dataSources: [MetadataLogicalTypeSource.All],
        allowFixedAddresses: false,
        multiple: false,
    };

    private recipientTypesDataSource: MailRecipientTypesDataSource = new MailRecipientTypesDataSource();

    private subscriptions: ko.Subscription[] = [];

    constructor(private props: MailRecipientTypeSelectorProps) {
        const allowFixedAddresses = ko.unwrap(this.props.allowFixedAddresses);
        this.recipientTypesDataSource.setAllowFixedAddress(allowFixedAddresses);

        this.initializeSubscriptions();
        this.setupDataSource();
    }

    private initializeSubscriptions() {
        if (ko.isSubscribable(this.props.allowFixedAddresses)) {
            this.subscriptions.push(
                this.props.allowFixedAddresses.subscribe((allowFixedAddresses) => {
                    this.recipientTypesDataSource.setAllowFixedAddress(allowFixedAddresses);
                })
            );
        }
    }

    private setupDataSource() {
        if (this.props.dataSources) {
            this.recipientTypesDataSource.clearDataSources();
            this.props.dataSources.forEach((ds) => this.recipientTypesDataSource.addDataSource(ds));
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((s) => s.dispose());
    }

    render() {
        return this.props.multiple
            ? ComponentUtils.bindTo(
                  <SelectMultiple
                      value={this.props.recipientTypes as ko.ObservableArray<string | number>}
                      dataSource={this.recipientTypesDataSource}
                      placeholder={TextResources.ProlifeSdk.MailRecipientTypePlaceholder}
                      allowClear
                      readOnly={this.props.readonly}
                  />,
                  this
              )
            : ComponentUtils.bindTo(
                  <Select
                      value={this.props.recipientTypes as ko.Observable<string | number>}
                      dataSource={this.recipientTypesDataSource}
                      placeholder={TextResources.ProlifeSdk.MailRecipientTypePlaceholder}
                      allowClear
                      readOnly={this.props.readonly}
                  />,
                  this
              );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(MailRecipientTypeSelector);
}
