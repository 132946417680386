import { IDataSourceModel } from "./IDataSource";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { IGDPRService, IGDPREventType } from "../GDPR/GDPRService";
import { GDPRLogicEventTypes, IGDPREventTypesSettingsManager } from "../GDPR/settings/GDPREventTypesSettingsManager";

export interface IGDPREventTypesDataSourceModel extends IDataSourceModel<number, IGDPREventType> {

}

export class GDPREventTypesDataSource extends BaseDataSource<IGDPREventTypesDataSourceModel> {
    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    @LazyImportSettingManager(nameof<IGDPREventTypesSettingsManager>())
    private GDPREventTypesSettingsManager: IGDPREventTypesSettingsManager;

    private allowedLogicTypesIds: number[];
    private showDeleted: boolean;

    setAllowedLogicTypesIds(ids: number[]){
        this.allowedLogicTypesIds = ids;
    }

    setShowDeleted(showDeleted: boolean){
        this.showDeleted = showDeleted;
    }

    getTitle(currentModel: IGDPREventTypesDataSourceModel): string {
        return "GDPR Event Types";
    }

    async getData(currentModel: IGDPREventTypesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IGDPREventTypesDataSourceModel[]> {
        let allEvents = await this.GDPREventTypesSettingsManager
                                    .getEventTypes(textFilter, this.showDeleted, skip, count)
                                    .filter(e => this.allowedLogicTypesIds.any(x => x == e.FkLogicEventType));
        return allEvents.map(this.createModelFor, this);
    }

    async getById(currentModel: IGDPREventTypesDataSourceModel, ids: number[]): Promise<IGDPREventTypesDataSourceModel[]> {
        let allEvents = await this.GDPREventTypesSettingsManager.getEventTypesByIds(ids);
        return allEvents.map(this.createModelFor, this);
    }

    private createModelFor(eventType: IGDPREventType): IGDPREventTypesDataSourceModel {
        return {
            id: eventType.Id,
            title: eventType.Description,
            isLeaf: true,
            isGroup: false,
            model: eventType
        };
    }
}