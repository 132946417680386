import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/06/2017
 * Time: 11:42
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SurveyNavigationMenu } from "../navigation/SurveyNavigationMenu";
import { SurveyNavigationProvider } from "../navigation/providers/SurveyNavigationProvider";
import { SurveyPanelViewModel } from "../SurveyPanelViewModel";
import { QuestionnaireSplashPageList } from "./QuestionnaireSplashPageList";
import { SampleSplashPageList } from "./SampleSplashPageList";
import { CampaignSplashPageList } from "./CampaignSplashPageList";
import { InboundConfigurationPage } from "../inbound/InboundConfigurationPage";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ISurveyService } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISurveyNavigationMenuObserver } from "../../../../ProlifeSdk/interfaces/survey/ISurveyNavigationMenuObserver";
import { IEntitiesLockService } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { IApplication } from "../../../../Desktop/interfaces/IApplication";
import { INavigationFilters } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";

export class SplashPage implements ISurveyNavigationMenuObserver {

    private desktopService : IDesktopService;
    private lockService : IEntitiesLockService;
    private surveyService : ISurveyService;
    private toastService : IInfoToastService;

    public templateUrl : string = "survey/templates";
    public templateName : string = "splash-page";
    public navigationMenu: SurveyNavigationMenu;

    public SearchFilters : ko.Observable<string> = ko.observable("");
    public HintSearchProvider: ko.Observable<any> = ko.observable();
    public Groups : ko.ObservableArray<ApplicationGroup> = ko.observableArray();

    public View : ko.Observable<any> = ko.observable();

    public ListElementsCount: ko.Observable<number> = ko.observable();

    constructor(private serviceLocator: IServiceLocator, private SurveyApplication: IApplication, public SurveyPanel: SurveyPanelViewModel) {
        this.desktopService = <IDesktopService> serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.lockService = <IEntitiesLockService> serviceLocator.findService(ProlifeSdk.EntitiesLockServiceType);
        this.surveyService = <ISurveyService> serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        this.navigationMenu = new SurveyNavigationMenu(this.serviceLocator);
        this.navigationMenu.setSingleSelectionStatus(true, true);
        this.navigationMenu.RegisterSurveyApplicationsObserver(this);
        this.navigationMenu.RootMenuProvider.Items().forEach((i: SurveyNavigationProvider) => {
            i.setCurrentNavigator(this.navigationMenu);
        });
        this.SearchFilters.subscribe(this.Refresh.bind(this));

        this.Groups.push(new ApplicationGroup("Generale")
            .addApplication(new Application("Questionari", "fa-pencil-square-o", this.ShowQuestionnaires.bind(this))));
        this.Groups.push(new ApplicationGroup("Outbound")
            .addApplication(new Application("Campioni", "fa-users", this.ShowSamples.bind(this)))
            .addApplication(new Application("Campagne", "fa-phone", this.ShowCampaigns.bind(this))));
        this.Groups.push(new ApplicationGroup("Inbound")
            .addApplication(new Application("Configurazioni", "fa-cog", this.ShowInboundConfigurations.bind(this))));
        /*this.Groups.push(new ApplicationGroup("Impostazioni")
            .addApplication(new Application("Mappatura Utenze", "", this.ShowUserMappings.bind(this))));*/
    }

    OnNavigationFilterIsChanged(filters : INavigationFilters)
    {
    }

    OnMenuSelectionChange() {

    }

    public goHome() : void
    {
        location.href = "#/";
    }

    Refresh()
    {
        if (this.View()) {
            this.View().Refresh();
            this.setNavBarActions(this.View().NavBarActions);
            return;
        }
        this.setNavBarActions([]);
    }

    public Initialize() : Promise<void>
    {
        return this.navigationMenu.refreshItems();
    }

    public dispose() {
        if (this.View())
            this.View().dispose();
    }

    public ShowQuestionnaires() {
        this.switchView(new QuestionnaireSplashPageList(this.serviceLocator, this));
    }

    public ShowSamples() {
        this.switchView(new SampleSplashPageList(this.serviceLocator, this));
    }

    public ShowCampaigns() {
        this.switchView(new CampaignSplashPageList(this.serviceLocator, this));
    }

    public ShowInboundConfigurations() {
        this.switchView(new InboundConfigurationPage(this.serviceLocator, this));
    }

    public ShowUserMappings() {
        //this.switchView(new CampaignSplashPageList(this.serviceLocator, this));
    }

    private switchView(newView) {
        this.SearchFilters("");
        this.HintSearchProvider(null);
        if(this.View())
            this.View().dispose();
        this.View(newView);

        if (this.View()) {
            this.setNavBarActions(this.View().NavBarActions);
            if(this.View().GetHintSearchProvider)
                this.HintSearchProvider(this.View().GetHintSearchProvider());
            if(this.View().Refresh)
                this.View().Refresh();
        }
    }

    public setNavBarActions(navBarActions: any[]) {
        this.SurveyApplication.navBarActions(navBarActions);
    }
}

class ApplicationGroup {
    public Title : ko.Observable<string> = ko.observable();
    public Applications : ko.ObservableArray<Application> = ko.observableArray();

    constructor(title : string) {
        this.Title(title);
    }

    public addApplication(app : Application) : ApplicationGroup {
        this.Applications.push(app);
        return this;
    }
}

class Application {
    public Title : ko.Observable<string> = ko.observable();
    public Icon : ko.Observable<string> = ko.observable();
    public Active : ko.Observable<boolean> = ko.observable();

    constructor(title : string, icon : string, private action : () => void) {
        this.Title(title);
        this.Icon(icon);
    }

    public Open() : void {
        this.action();
    }
}