import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ComponentParam, Param } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { IBaseInputParams, BaseInput } from "./BaseInputComponent";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import jss from "jss";

const { classes } = jss.createStyleSheet({
    numericInput: {
        "& .disabled-style": {
            cursor: "not-allowed",
            "background-color": "#eeeeee"
        }
    }
}).attach();


//Mapping tra property di Params e nome attributo su html
let attributes = {
    Value: "value",
    Label: "label",
    Placeholder: "placeholder",
    HelpText: "helpText",
    ReadOnly: "readOnly",
    MaxLength: "maxLength",
    UpperCase: "upperCase",
    AddonPosition: "addonPosition",
    ValueUpdate: "valueUpdate",
    ShowEditButton: "showEditButton",
    SelectOnFocus: "selectOnFocus",
    Format: "format",
    Icon: "icon",
    IconTitle: "icon-title",
    ShowIcon: "show-icon",
    CssClasses: "css-classes"
};

export interface INumericInputParams extends IBaseInputParams<number> {
    Format: Param<string>;
    Icon: Param<string>;
    IconTitle: Param<string>;
    ShowIcon: Param<boolean>;
    CssClasses: Param<string>;
}

export class NumericInput extends BaseInput<number> {
    public Format: ComponentParam<string>;
    public Icon: ComponentParam<string>;
    public IconTitle: ComponentParam<string>;
    public ShowIcon: ComponentParam<boolean>;
    public CssClasses: ComponentParam<string>;

    private defaultFormat: string = "0,0";

    constructor(params : INumericInputParams) {
        super(params);

        this.Format = ComponentUtils.parseParameter(params.Format, this.defaultFormat);
        this.Icon = ComponentUtils.parseParameter(params.Icon, "");
        this.IconTitle = ComponentUtils.parseParameter(params.IconTitle, "");
        this.ShowIcon = ComponentUtils.parseParameter(params.ShowIcon, false);
        this.CssClasses = ComponentUtils.parseParameter(params.CssClasses, "");

        this.initialize();
        this.ValueInterceptor.extend({ notify: 'always'});
    }

    protected readValue(): string {
        return !this.Value() ? "" : numeral(this.Value()).format(this.Format());
    }

    protected writeValue(value : number) {
        this.Value(value);
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "numeric-input" : {
                params?: {
                    Value: string;
                    Label?: string;
                    HelpText?: string;
                    Placeholder?: string;
                    ReadOnly?: string;
                    MaxLength?: string;
                    UpperCase?: string;
                    AddonPosition?: string;
                    ValueUpdate?: string;
                    ShowEditButton?: boolean;
                    Format?: string;
                    Icon?: string,
                    IconTitle?: string,
                    ShowIcon?: string,
                    CssClasses?: string,
                    SelectOnFocus?: string
                };

                value?: string | (() => string);
                /**
                 * Label del campo
                 */
                label?: string | (() => string);
                /**
                 * Testo visualizzato sotto l'input
                 */
                helpText?: string | (() => string);
                /**
                 * Placeholder text nell'input
                 */
                placeholder?: string | (() => string);

                readOnly?: boolean | (() => string);

                maxLength?: number | (() => string);

                upperCase?: boolean | (() => string);
                /**
                 * Posizione dell'elemento passato all'input
                 */
                addonPosition?: ("left" | "right") | (() => string);

                valueUpdate?: string | (() => string);
                
                showEditButton?: boolean | (() => string);

                format?: string | (() => string);

                icon?: string | (() => string);
                
                "icon-title"?: string | (() => string);

                "show-icon"?: boolean | (() => string);

                "css-classes"?: string | (() => string);
                
                selectOnFocus?: boolean | (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

ko.components.register("numeric-input", {
    viewModel: {
        createViewModel: (params: INumericInputParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new NumericInput(params);

            let selectOnFocus = vm.SelectOnFocus();
            let inputBindings = "numberValue: ValueInterceptor, attr: { placeholder: Placeholder, maxlength: MaxLength }, disable: ReadOnly, valueUpdate: $data.ValueUpdate(), css: CssClasses, format: Format";
            if (selectOnFocus)
                inputBindings += ", selectOnFocus: {}"

            let templateFragment = [<div class="form-group">
                                        <ko-if data-bind="Label">
                                            <label class="control-label" data-bind="text: Label"></label>
                                        </ko-if>
                                        <div data-bind="css: { 'input-icon': ShowIcon }">
                                            <ko-if data-bind="ShowIcon">
                                                <i class="fa" data-bind="css: Icon, attr: { title: IconTitle }"></i>
                                            </ko-if>
                                            <input class="form-control text-right" type="text" data-bind={inputBindings} />
                                        </div>
                                        <ko-if data-bind="HelpText">
                                            <span class="help-block" data-bind="text: HelpText">
                                            </span>
                                        </ko-if>
                                    </div>];

            if(componentInfo.templateNodes.length > 0) {
                templateFragment =  [<div class="form-group">
                                        <ko-if data-bind="Label">
                                            <label class="control-label" data-bind="text: Label"></label>
                                        </ko-if>
                                        <div class="input-group" data-bind="css: { 'input-icon': ShowIcon }">
                                            <ko-if data-bind="AddonPosition() == 'left'">
                                                <ko-template data-bind="{ nodes: $componentTemplateNodes }"></ko-template>
                                            </ko-if>
                                            <ko-if data-bind="ShowIcon">
                                                <i class="fa" data-bind="css: Icon, attr: { title: IconTitle }"></i>
                                            </ko-if>
                                            <input class="form-control text-right" type="text" data-bind={inputBindings} />
                                            <ko-if data-bind="AddonPosition() == 'right'">
                                                <ko-template data-bind="{ nodes: $componentTemplateNodes }"></ko-template>
                                            </ko-if>
                                        </div>
                                        <ko-if data-bind="HelpText">
                                            <span class="help-block" data-bind="text: HelpText">
                                            </span>
                                        </ko-if>
                                    </div>];
            }

            if (vm.ShowEditButton()) {
                templateFragment =  [
                    <div class={"form-group " + classes.numericInput}>
                        <ko-if data-bind="Label">
                            <label class="control-label" data-bind="text: Label"></label>
                        </ko-if>
                        <div class="input-group" data-bind="css: { 'input-icon': ShowIcon }">
                            <ko-if data-bind="ShowIcon">
                                <i class="fa" data-bind="css: Icon, attr: { title: IconTitle }"></i>
                            </ko-if>
                            <ko-if data-bind="EditValue">
                                <input class="form-control text-right" type="text" data-bind={inputBindings} />
                            </ko-if>
                            <ko-ifnot data-bind="EditValue">
                                <span class="form-control text-right disabled-style" data-bind="text: ValueInterceptor"/>
                            </ko-ifnot>
                            <span class="input-group-btn">
                                <button class="btn btn-primary active" type="button" data-bind="toggle: EditValue, disable: ReadOnly, visible: !EditValue()" title={TextResources.ProlifeSdk.EnableEditMode}>
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </span>
                        </div>
                        <ko-if data-bind="HelpText">
                            <span class="help-block" data-bind="text: HelpText">
                            </span>
                        </ko-if>
                    </div>
                ];
            }
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, templateFragment);
            
            return vm;
        },
    },
    template: []
});