import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IConveyance, IConveyances } from "../ProlifeSdk/interfaces/invoice/settings/IConveyances";

export interface ITransportsDataSourceModel extends IDataSourceModel<number, IConveyance> {

}

export class TransportsDataSource extends BaseDataSource<ITransportsDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.Conveyance)
    private conveyances : IConveyances;

    getTitle(currentModel: ITransportsDataSourceModel): string {
        return TextResources.Invoices.DeliveryLookAndFeel;
    }

    async getData(currentModel: ITransportsDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITransportsDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let appearances = this.conveyances.getConveyances();
        
        return appearances
            .filter(d => splitFilters.filter(w => d.Descrizione.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: ITransportsDataSourceModel, ids: (string | number)[]): Promise<ITransportsDataSourceModel[]> {
        return this.conveyances.getConveyances()
            .filter(p => ids.indexOf(p.Id) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IConveyance): ITransportsDataSourceModel {
        return {
            id: model.Id,
            title: model.Descrizione,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}