import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/06/2018
 * Time: 14:55
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { Moment } from "moment";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { Document } from "../../documents/Document";
import { ITrustAuthorizationProcessService } from "../../../TrustAuthorizationProcessService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { DocumentRow } from "../../documents/DocumentRows";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IResourceForTrustAuthorization, ITrustAuthorizationRequiredDialog, ITrustAuthorizationResult, ITrustAuthorizationResponse, ITrustAuthorizationRequest, ITrustAuthorizationRequestState } from "../../../../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";

interface ITimerObserver {
    OnCountdownEnded(): void;
}

interface IResourceForAuthorizationViewModel {
    ResourceId: number;
    ResourceName: ko.Observable<string>;
    State: ko.Observable<string>;

    getData(): IResourceForTrustAuthorization;
}

interface IRequestStatesMap {
    [state: string]: string;
}

export class TrustAuthorizationRequiredDialog implements IDialog, ITrustAuthorizationRequiredDialog, ITimerObserver {
    public templateName: string = "trust-authorization-required-dialog";
    public templateUrl: string = "invoices/templates/customertrust";
    public title: string = ProlifeSdk.TextResources.Invoices.CustomerTrustAuthorizationTitle;
    public modal: { close: (result?: any) => void; };

    public OnLineResources: ko.ObservableArray<IResourceForAuthorizationViewModel> = ko.observableArray([]);
    public RequestSent: ko.Observable<boolean> = ko.observable();

    public EmptyList: ko.Computed<boolean>;
    public documentTotalPrice: number;

    private timer: Timer;
    private requestStatesMap: IRequestStatesMap = {};
    private lastSentRequestId: string = null;
    private opened: boolean = false;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    constructor(private document: Document, public trustOverflow: number) {
        this.timer = new Timer();
        this.timer.Duration = 5;
        this.timer.RegisterObserver(this);

        this.documentTotalPrice = this.document.FinalTotal();

        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestIgnored] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestIgnoredLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestDenied] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestDeniedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestOnEvaluation] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestOnEvaluationLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestAccepted] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAcceptedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSendingInProgress] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSendingInProgressLabel;

        this.LoadOnLineUsersWithRightsForAuthorization();

        this.EmptyList = ko.computed(() => {
            return this.OnLineResources().length == 0;
        });
    }

    public close(): void {
        if (this.RequestSent()) {
            this.timer.Stop();
            this.trustAuthorizationProcessService.AbortAuthorizationRequest(this.lastSentRequestId, this.OnLineResources().map((r: IResourceForAuthorizationViewModel) => r.getData()));
        }

        var result: ITrustAuthorizationResult = {
            AuthorizationRequestId: this.lastSentRequestId,
            Authorized: false,
            AuthorizedByResourceId: null,
            AuthorizedByResourceName: null,
            TrustOverflow: this.trustOverflow
        };

        this.opened = false;
        this.modal.close(result);
    }

    public action(): void {
        this.RequestAuthorization();
    }

    public showModal(): Promise<ITrustAuthorizationResult> {
        this.opened = true;
        return this.dialogsService.ShowModal<ITrustAuthorizationResult>(this, null, null, "invoices/templates/customertrust", "trust-authorization-required-dialog");
    }

    public isOpened(): boolean {
        return this.opened;
    }

    public OnCountdownEnded(): void {
        this.RequestSent(false);
        this.trustAuthorizationProcessService.AbortAuthorizationRequest(this.lastSentRequestId, this.OnLineResources().map((r: IResourceForAuthorizationViewModel) => r.getData()));
    }

    public onTrustAuthorizationResponse(response: ITrustAuthorizationResponse): void {
        var resourcesMatch: IResourceForAuthorizationViewModel[] = this.OnLineResources().filter((r: IResourceForAuthorizationViewModel) => r.ResourceId == response.ResourceId);
        if (resourcesMatch.length > 0)
            this.OnLineResources().filter((r: IResourceForAuthorizationViewModel) => r.ResourceId == response.ResourceId)[0].State(this.requestStatesMap['_' + response.Answer]);
        else {
            var resource: IResourceForTrustAuthorization = {
                ResourceId: response.ResourceId,
                Name: response.ResourceName,
                Surname: ''
            };

            var resourceViewModel: IResourceForAuthorizationViewModel = this.CreateResourceViewModel(resource);
            resourceViewModel.State(this.requestStatesMap['_' + response.Answer]);
            this.OnLineResources.push(resourceViewModel);
        }

        var result: ITrustAuthorizationResult = {
            AuthorizationRequestId: this.lastSentRequestId,
            Authorized: true,
            AuthorizedByResourceId: response.ResourceId,
            AuthorizedByResourceName: response.ResourceName,
            TrustOverflow: this.trustOverflow
        };

        if (response.Answer == ProlifeSdk.TrustAuthorizationRequestIgnored)
            return;

        if (response.Answer == ProlifeSdk.TrustAuthorizationRequestOnEvaluation) {
            this.timer.Stop();
            return;
        }

        if (response.Answer == ProlifeSdk.TrustAuthorizationRequestDenied) {
            this.dialogsService.Alert(ProlifeSdk.TextResources.Invoices.TrustAuthorizationDenied, ProlifeSdk.TextResources.Invoices.CustomerTrustAuthorizationTitle, () => {});
            result.Authorized = false;
            this.modal.close(result);
            return;
        }

        this.modal.close(result);
    }

    public onTrustAuthorizationAbort() {
        this.timer.Stop();

        const result: ITrustAuthorizationResult = {
            AuthorizationRequestId: this.lastSentRequestId,
            Authorized: false,
            AuthorizedByResourceId: null,
            AuthorizedByResourceName: null,
            TrustOverflow: this.trustOverflow
        };

        this.modal.close(result);
    }

    private async LoadOnLineUsersWithRightsForAuthorization(): Promise<IResourceForTrustAuthorization[]> {
        let resources = await this.trustAuthorizationProcessService.GetOnLineUsersWithRightsForAuthorization()
        this.OnLineResources(resources.map((r: IResourceForTrustAuthorization) => this.CreateResourceViewModel(r)));
        return resources;
    }

    private CreateResourceViewModel(resource: IResourceForTrustAuthorization): IResourceForAuthorizationViewModel {
        return new ResourceForAuthorizationViewModel(resource);
    }

    private async RequestAuthorization(): Promise<void> {
        var documentRowsWithReferencesTotalPrice: number = 0;
        this.document.Rows().forEach((r: DocumentRow) => {
            if (r.OriginatingRows().filter(rr => rr.SourceEntityType == ProlifeSdk.InvoiceEntityTypeCode || rr.SourceEntityType == ProlifeSdk.CustomerOrderEntityTypeCode || rr.SourceEntityType == ProlifeSdk.DdtEntityTypeCode).length > 0)
                documentRowsWithReferencesTotalPrice += r.TotalPrice();
        });

        var request: ITrustAuthorizationRequest = {
            DocumentId: this.document.DocumentId(),
            DocumentEntityTypeCode: this.document.DocumentType(),
            VatRegisterId: this.document.FKRegister(),
            VatRegisterName: this.document.RegisterCache.NomeRegistroIVA,
            VatRegisterDocumentLabel: this.document.DocumentTypeLabel(),
            TotalPrice: this.documentTotalPrice,
            ImportedRowsTotalPrice: documentRowsWithReferencesTotalPrice,
            CustomerTrustBalanceAfterDocumentIssue: this.trustOverflow * -1,
            CustomerId: this.document.Recipient.Id(),
            CustomerName: this.document.Recipient.FormattedName(),
            JobOrderId: this.document.FKJobOrder(),
            JobOrderName: this.document.JobOrderName(),
            RequestTo: this.OnLineResources().map((r: IResourceForAuthorizationViewModel) => r.getData())
        };

        try
        {
            let requestState = await this.trustAuthorizationProcessService.RequestAuthorization(request)
            
            if (requestState.CustomerHasPendingRequest) {
                await this.trustAuthorizationProcessService.OnCustomerPendingRequests(requestState.PendingRequests[0])    
                this.RequestSent(false);
                this.lastSentRequestId = null;
                this.OnLineResources().forEach((r: IResourceForAuthorizationViewModel) => r.State(this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent]));
                return;
            }

            if (requestState.PendingRequests.length == 0) {
                this.RequestSent(false);
                this.lastSentRequestId = null;
                this.OnLineResources().forEach((r: IResourceForAuthorizationViewModel) => r.State(this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent]));
            }

            this.RequestSent(true);
            this.lastSentRequestId = requestState.PendingRequests[0].Id;
            this.timer.Start();

            this.OnLineResources().forEach((r: IResourceForAuthorizationViewModel) => {
                if (requestState.PendingRequests.filter((s: ITrustAuthorizationRequestState) => s.ResourceId == r.ResourceId).length == 0) {
                    r.State(ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel);
                    return;
                }

                r.State(this.requestStatesMap['_' + requestState.PendingRequests.filter((s: ITrustAuthorizationRequestState) => s.ResourceId == r.ResourceId)[0].State]);
            });
        }
        catch
        {
            this.RequestSent(false);
            this.lastSentRequestId = null;
            this.timer.Stop();

            this.LoadOnLineUsersWithRightsForAuthorization();
        }
    }
}

class Timer {
    public Time: ko.Observable<string> = ko.observable();
    public Format: string = "LTS";
    public Duration: number;

    private lastInterval: ReturnType<typeof setTimeout>;
    private startTime: Moment;

    private observers: ITimerObserver[] = [];

    constructor() {}

    public RegisterObserver(observer: ITimerObserver): void {
        this.observers.push(observer);
    }

    public Start(): void {
        if (!this.Duration)
            return;

        if (this.lastInterval)
            clearInterval(this.lastInterval);

        this.startTime = moment().hours(0).minutes(this.Duration).seconds(0).milliseconds(0);
        this.Time(this.startTime.format(this.Format));

        this.lastInterval = setInterval(() => {
            var time: Moment = this.startTime.subtract("seconds", 1);
            this.Time(time.format(this.Format));

            if (time.hours() == 0 && time.minutes() == 0 && time.seconds() == 0 && time.milliseconds() == 0) {
                this.Stop();
                this.NotifyCountdownEndToObservers();
            }

        }, 1000);
    }

    public Stop(): void {
        if (this.lastInterval)
            clearInterval(this.lastInterval);
    }

    public NotifyCountdownEndToObservers(): void {
        this.observers.forEach((o: ITimerObserver) => o.OnCountdownEnded());
    }
}

class ResourceForAuthorizationViewModel implements IResourceForAuthorizationViewModel {
    public ResourceId: number;
    public ResourceName: ko.Observable<string> = ko.observable();
    public State: ko.Observable<string> = ko.observable();

    constructor(private resource: IResourceForTrustAuthorization) {
        this.ResourceId = this.resource.ResourceId;
        this.ResourceName(this.resource.Name + ' ' + this.resource.Surname);
        this.State(ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel);
    }

    getData(): IResourceForTrustAuthorization {
        return this.resource;
    }
}