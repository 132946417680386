import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTemplateForList, ITodoListTemplate } from "../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class TemplatesControlsEntityProvider implements IControlsEntityProvider
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;
    
    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor() {}

    public findEntities(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.todoListService.GetTemplatesForList(query.term, 0, 50000)
                .then((data) => {
                    query.callback({
                        results: data.map((t : ITodoListTemplateForList) => {
                            return {
                                id: t.Id,
                                text: t.Title
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback)
    {
        var id = parseInt(<string>$(element).val());

        if(!isNaN(id) && id > 0) {
            this.todoListService.GetTemplate(id)
                .then((t : ITodoListTemplate) => callback({
                    id: t.Id,
                    text: t.Title
                }));
        }
    }
}