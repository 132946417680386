import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 09/03/2018
 * Time: 09:43
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { GroupedEntitiesProvider } from "../../../../ProlifeSdk/prolifesdk/providers/GroupedEntitiesProvider";
import { IAgendasEventsTagFilter } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { ISearchResultsProvider } from "../../../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { IEntitySearchResult } from "../../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { ISearchTagFilter } from "../../../../ProlifeSdk/interfaces/ISearchTagFilter";
import { ISearchFilter } from "../../../../ProlifeSdk/interfaces/ISearchFilter";
import { Deferred } from "../../../../Core/Deferred";

export class CustomGroupedEntitiesProvider extends GroupedEntitiesProvider {

    constructor(target: ISearchResultsProvider, defaultTextLabel: string = ProlifeSdk.TextResources.ProlifeSdk.TextsAndDates, entitiesCodes: string[] = []) {
        super(target, defaultTextLabel, entitiesCodes);

        target.findSearchMatches = this.findSearchMatches.bind(this);
        target.findSearchMatch = this.findSearchMatch.bind(this);
    }

    protected search(searchFilter : string, callback : (data : any) => void)
    {
        var data = { results: [] };

        var providersDef = new Deferred();
        this.entitiesProviderService.search(searchFilter, this.entitiesCodes).then((results : IEntitySearchResult[]) => {

            var allTagsFilters : ISearchTagFilter[] = [];

            results.forEach((result : IEntitySearchResult) => {

                if(result.entities.length == 0)
                    return;

                var menuGroup = {
                    text : result.provider.getEntityTypeName(),
                    children : []
                };

                result.entities.forEach(e => {
                    var tags : IAgendasEventsTagFilter[] = [{
                        Values : [result.provider.getPkValue(e)],
                        ValueTypeId : result.provider.getType(),
                        FieldLabel: result.provider.getEntityTypeName() + ':   ' + result.provider.getDisplayName(e)
                    }];

                    var entityFilter : ISearchFilter = {
                        Tags : tags
                    };

                    entityFilter.Tags.forEach((t) => { allTagsFilters.push(t); });

                    menuGroup.children.push({
                        id : JSON.stringify(entityFilter),
                        text : result.provider.getEntityTypeName() + ':   ' + result.provider.getDisplayName(e)
                    });
                });

                data.results.push(menuGroup);

            });

            //Voce Tutto (ricerca il contenuto in tutti i campi)
            var getForAllFilter : ISearchFilter = {
                IsSearchForAllFilter : true,
                HintSearch : searchFilter
                //Tags : allTagsFilters
            };

            data.results.splice(0, 0, {
                text : this.defaultTextLabel,
                children : [{
                    id : JSON.stringify(getForAllFilter),
                    text : searchFilter
                }]
            });
            /***/

            providersDef.resolve();
        }).catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
    }

    protected searchById(serializedItem : string) : any 
    {
        var itemsJSON: string[] = serializedItem.split("|");
        var items: ISearchFilter[] = itemsJSON.map((i: string) => { return JSON.parse(i); });
        var results: { id: string; text: string; }[] = [];
        items.forEach((i: ISearchFilter) => {
            if (i.IsSearchForAllFilter) {
                results.push({ id: JSON.stringify(i), text: i.HintSearch });
            } else {
                i.Tags.forEach((t: IAgendasEventsTagFilter) => {
                    results.push({ id: JSON.stringify(i), text: t.FieldLabel })
                });
            }
        });

        return results;
    }
}