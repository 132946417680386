import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ContractsApplication } from "./contracts/ContractsApplication";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IContractsService } from "../ProlifeSdk/interfaces/contracts/IContractsService";

class ContractsService implements IContractsService
{
    private application : ContractsApplication;
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.application = new ContractsApplication(this.serviceLocator);
    }

    getServiceType(): string
    {
        return ProlifeSdk.ContractsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ContractsService(serviceLocator);
}
