import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import {
    WorkflowAllocation,
    WorkflowBudgetRequest,
    WorkflowPurchaseEvent,
    RelatedDocument,
    WorkflowWorkedTask,
    RelatedDocumentRow,
    Cart,
    Activity,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import TsxForEach from "../../../../Components/ForEach";
import { If } from "../../../../Components/IfIfNotWith";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";

const styleSheet = jss.createStyleSheet({
    "document-link": {
        cursor: "pointer",
        textDecoration: "underline",
    },
});
const { classes } = styleSheet.attach();

type RelatedEntitiesDetailsProps = {
    relatedDocuments: RelatedDocument[];
    workedTasks: WorkflowWorkedTask[];
    purchases: WorkflowPurchaseEvent[];
    budgetRequests: WorkflowBudgetRequest[];
    allocations: WorkflowAllocation[];
};

export class RelatedEntitiesDetails extends DialogComponentBase {
    static defaultProps: Partial<RelatedEntitiesDetailsProps> = {};

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private RelatedDocuments: ko.ObservableArray<RelatedDocument> = ko.observableArray([]);
    private WorkedTasks: ko.ObservableArray<WorkflowWorkedTask> = ko.observableArray([]);
    private Purchases: ko.ObservableArray<WorkflowPurchaseEvent> = ko.observableArray([]);
    private BudgetRequests: ko.ObservableArray<WorkflowBudgetRequest> = ko.observableArray([]);
    private Allocations: ko.ObservableArray<WorkflowAllocation> = ko.observableArray([]);

    constructor(private props: RelatedEntitiesDetailsProps) {
        super({ noPrompt: true, className: "medium" });

        this.title(TextResources.Todolist.WorkflowRelatedEntitiesError);

        this.RelatedDocuments(this.props.relatedDocuments);
        this.WorkedTasks(this.props.workedTasks);
        this.Purchases(this.props.purchases);
        this.BudgetRequests(this.props.budgetRequests);
        this.Allocations(this.props.allocations);
    }

    action() {
        this.modal.close();
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return ComponentUtils.bindTo(
            <Layout.Grid rows={["min-content", "1ft"]} columns={["1ft"]}>
                <Layout.Grid.Cell row={1} column={1}>
                    <span>{TextResources.Todolist.WorkflowRelatedEntitiesErrorDescription}</span>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <Layout.ScrollContainer>
                        <Section
                            title={TextResources.Todolist.WorkflowRelatedDocuments}
                            items={this.RelatedDocuments}
                            renderer={(props) => <RelatedDocumentRenderer {...props} />}
                        />

                        <Section
                            title={TextResources.Todolist.WorkedTasks}
                            items={this.WorkedTasks}
                            renderer={(props) => <span>{props.description}</span>}
                        />

                        <Section
                            title={TextResources.Todolist.BlogPurchases}
                            items={this.Purchases}
                            renderer={(props) => <span>{props.description}</span>}
                        />

                        <Section
                            title={TextResources.Todolist.BudgetRequests}
                            items={this.BudgetRequests}
                            renderer={(props) => <span>{props.description}</span>}
                        />

                        <Section
                            title={TextResources.Todolist.Allocations}
                            items={this.Allocations}
                            renderer={(props) => <Allocation {...props} />}
                        />
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "wre"
        );
    }
}

class Section<T> {
    constructor(
        private props: { title: string; items: ko.ObservableArray<T>; renderer: (props: T) => React.ReactElement }
    ) {}

    render() {
        return ComponentUtils.bindTo(
            <>
                <If condition={ko.computed(() => this.props.items().length > 0)}>
                    {() => <h4 className="bold">{this.props.title}</h4>}
                </If>
                <ul>
                    <TsxForEach data={this.props.items}>{(item: T) => <li>{this.props.renderer(item)}</li>}</TsxForEach>
                </ul>
            </>,
            this,
            ""
        );
    }
}

class RelatedDocumentRenderer {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    private label = "";
    private canEditDocument = false;

    constructor(private props: RelatedDocument) {
        this.label = String.format(
            TextResources.Todolist.RelatedDocumentLabel,
            this.props.protocolName,
            this.props.number,
            moment(this.props.date).format("L")
        );

        const right = this.documentsService.getEditRightForDocument(this.props.type);
        this.canEditDocument = this.authorizationService.isAuthorized(right);
    }

    private openDocument() {
        if (this.canEditDocument) this.documentsService.OpenDocumentOverlayById(this.props.id);
    }

    render() {
        return ComponentUtils.bindTo(
            <>
                <span
                    className={this.canEditDocument ? classes["document-link"] : ""}
                    onClick={this.openDocument.bind(this)}
                >
                    {this.label}
                </span>
                <ul>
                    {this.props.rows.map((row) => (
                        <li>
                            <RelatedDocumentRowRenderer {...row} />
                        </li>
                    ))}
                </ul>
            </>,
            this,
            ""
        );
    }
}

class RelatedDocumentRowRenderer {
    constructor(private props: RelatedDocumentRow) {}

    render() {
        return (
            <span>
                {String.format(
                    TextResources.Todolist.RelatedDocumentRowLabel,
                    this.props.number,
                    this.props.description
                )}
            </span>
        );
    }
}

class Allocation {
    constructor(private props: WorkflowAllocation) {}

    render() {
        return ComponentUtils.bindTo(
            <>
                <span>{this.props.teamName}</span>
                <ul>
                    {this.props.carts.map((cart) => (
                        <li>
                            <CartRenderer {...cart} />
                        </li>
                    ))}
                </ul>
            </>,
            this,
            ""
        );
    }
}

class CartRenderer {
    constructor(private props: Cart) {}

    render() {
        return (
            <>
                <span>{this.props.name}</span>
                <ul>
                    {this.props.activities.map((activity) => (
                        <li>
                            <ActivityRenderer {...activity} />
                        </li>
                    ))}
                </ul>
            </>
        );
    }
}

class ActivityRenderer {
    constructor(private props: Activity) {}

    render() {
        return (
            <span>
                <ActivityIcon isTask={this.props.isTask} /> {this.props.name}
            </span>
        );
    }
}

class ActivityIcon {
    constructor(private props: { isTask: boolean }) {}

    render() {
        return <i className={"fa " + (this.props.isTask ? "fa-pencil-square-o" : "fa-sitemap")}></i>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RelatedEntitiesDetails);
}
