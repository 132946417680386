import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/09/2017
 * Time: 12:31
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ResourceAllocationRangeViewModel } from "./ResourceAllocationRangeViewModel";

import * as moment from "moment";
import { Moment } from "moment";
import { ResourceReallocationDialog } from "./dialogs/ResourceReallocationDialog";
import { IHumanResource, IHumanResourceOrders } from "../../../Users/HumanResourcesService";
import { IResourceOperationalUnitViewModel, IResourceAllocationRangeViewModel, ITeamsManagerProvider } from "./TeamsManager";
import { IAllocationsService, ITeamAllocation, IResourceAllocationRange } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IRangeSelectionObserver } from "../../interfaces/IRangeSelectionObserver";
import { IOperationalUnit } from "../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IAllocationPerDays } from "../../interfaces/IAllocationPerDays";

export class ResourceOperationalUnitViewModel implements IRangeSelectionObserver, IResourceOperationalUnitViewModel {
    Id: number;
    AllocationRanges : ko.ObservableArray<IResourceAllocationRangeViewModel> = ko.observableArray();

    Name : ko.Observable<string> = ko.observable();
    ResourceName : ko.Observable<string> = ko.observable();
    AllocatedHours : ko.Computed<number>;
    ServiceOrders : ko.ObservableArray<IHumanResourceOrders> = ko.observableArray();

    NewStartDate : ko.Observable<Date> = ko.observable();
    NewEndDate : ko.Observable<Date> = ko.observable();
    NewAmount : ko.Observable<number> = ko.observable(100);

    HoursMonday : ko.Observable<number> = ko.observable(0);
    HoursTuesday : ko.Observable<number> = ko.observable(0);
    HoursWednesday : ko.Observable<number> = ko.observable(0);
    HoursThursday : ko.Observable<number> = ko.observable(0);
    HoursFriday : ko.Observable<number> = ko.observable(0);
    HoursSaturday : ko.Observable<number> = ko.observable(0);
    HoursSunday : ko.Observable<number> = ko.observable(0);

    AllocationMode: ko.Observable<number> = ko.observable();
    ShowFinishedAllocations: ko.Observable<boolean> = ko.observable(false);

    PercentageAllocationEnabled: ko.Observable<boolean> = ko.observable(false);
    HoursAllocationEnabled: ko.Observable<boolean> = ko.observable(false);

    AbsoluteStartDate : ko.Computed<Moment>;
    AbsoluteEndDate : ko.Computed<Moment>;
    VisibleAllocationRanges: ko.Computed<IResourceAllocationRangeViewModel[]>;

    RangesLoaded: ko.Observable<boolean> = ko.observable(false);

    HasChanged : ko.Computed<boolean>;
    ValuesHaveChanged : ko.Observable<boolean> = ko.observable(false);
    NewStartDateOnFocus: ko.Observable<boolean> = ko.observable(false);

    SelectedRange: ko.Computed<IResourceAllocationRangeViewModel>;

    private infoToastService : IInfoToastService;
    private allocationsService : IAllocationsService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private allocation : ITeamAllocation, public OperationalUnit : IOperationalUnit, serviceOrders : IHumanResourceOrders[], private resource : IHumanResource, private teamsManagerProvider: ITeamsManagerProvider) {
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.allocationsService = <IAllocationsService> serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);

        this.Name(OperationalUnit.Name);
        this.ServiceOrders(serviceOrders.filter(so => moment(so.ToDate || '2100-01-01') >= moment()));
        this.ResourceName(resource.Resource.Name + " " + resource.Resource.Surname);

        this.Id = this.allocation.Id;

        this.AbsoluteStartDate = ko.computed(() => {
            let minDate = moment('2100-01-01');
            this.AllocationRanges().forEach((r : ResourceAllocationRangeViewModel) => {
                minDate = moment(r.StartDate()) < minDate ? moment(r.StartDate()) : minDate;
            });
            return minDate;
        });

        this.AbsoluteEndDate = ko.computed(() => {
            let maxDate = moment('1900-01-01');
            this.AllocationRanges().forEach((r : ResourceAllocationRangeViewModel) => {
                maxDate = moment(r.EndDate()) > maxDate ? moment(r.EndDate()) : maxDate;
            });
            return maxDate;
        });

        this.HasChanged = ko.computed(() => {
            return this.AllocationRanges().filter((a) => a.HasChanged()).length > 0 || this.ValuesHaveChanged();
        });

        this.NewStartDate.subscribe((date: Date) => {
            if (!date)
                return;
            if (!this.NewEndDate() || moment(date) > moment(this.NewEndDate()))
                this.NewEndDate(date);
        });

        this.NewStartDateOnFocus.subscribe((hasFocus: boolean) => {
            if (hasFocus && !this.NewStartDate()) {
                const startDate: Date = this.AllocationRanges().length == 0
                    ? moment().startOf("day").toDate()
                    : moment(this.AllocationRanges()[this.AllocationRanges().length - 1].EndDate()).startOf("day").add("days", 1).toDate();
                this.NewStartDate(startDate);
            }
        });

        this.AllocationMode(ProlifeSdk.ResourceAllocationByPercentage);
        this.SelectedRange = ko.computed(() => {
            const range = this.AllocationRanges().filter((r) => { return r.Selected(); });
            if (range.length == 0) return null;
            return range[0];
        });

        this.VisibleAllocationRanges = ko.computed(() => {
            let allocationRanges : IResourceAllocationRangeViewModel[] = this.AllocationRanges();
            if (!this.ShowFinishedAllocations())
                allocationRanges = allocationRanges.filter((r) => {
                    return moment(r.EndDate() || new Date()).startOf("day") >= moment().startOf("day");
                });
            allocationRanges.sort((a,b) => moment(b.StartDate()).toDate().valueOf() - moment(a.StartDate()).toDate().valueOf());
            return allocationRanges;
        });

        this.AllocatedHours = ko.computed(() => {
            const activeRanges = this.AllocationRanges().filter((r : ResourceAllocationRangeViewModel) => {
                return moment(r.EndDate()).startOf('day') >= moment().startOf('day');
            });

            let allocatedHours = 0;
            activeRanges.forEach((r : ResourceAllocationRangeViewModel) => {
                const startDate = moment(r.StartDate()).startOf("day") < moment().startOf("day") ? moment().startOf("day") : moment(r.StartDate());
                const endDate = moment(r.EndDate());
                for (let refDate = moment(startDate); refDate <= endDate; refDate = refDate.add("days", 1)) {
                    const serviceOrder = this.findServiceOrder(this.ServiceOrders(), refDate);
                    allocatedHours += this.getWorkedHoursForDay(serviceOrder, refDate, r);
                }
            });

            return allocatedHours;
        });
    }

    public RemoveAllocation(allocation : ResourceAllocationRangeViewModel) {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ConfirmAllocationDelete, ProlifeSdk.TextResources.Allocations.DoNotConfirmAllocationDelete, ProlifeSdk.TextResources.Allocations.DoConfirmAllocationDelete, (result : boolean) => {
            if(!result) return;

            this.AllocationRanges.remove(allocation);
            this.ValuesHaveChanged(true);

            this.teamsManagerProvider.SynchronizeResourceRanges(this.allocation.ResourceId, this.allocation.TeamId, this.OperationalUnit.Id);
        });
    }

    private VerifyRange() : boolean {
        if (!this.NewStartDate()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoStartDateSelected);
            return false;
        }

        /*if (moment(this.NewStartDate()).startOf("day") < moment().startOf("day")) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.StartDateTooSmall);
            return false;
        }*/

        if (!this.NewEndDate()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoEndDateSelected);
            return false;
        }

        if (moment(this.NewEndDate()).startOf("day") < moment(this.NewStartDate()).startOf("day")) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.InvalidRange);
            return false;
        }

        if (!this.NewAmount() && this.AllocationMode() == ProlifeSdk.PercentageEditigMode) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoAmountSelected);
            return false;
        }

        if (this.AllocationMode() == ProlifeSdk.HoursEditigMode && !this.VerifyHours()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.NoHoursSelected);
            return false;
        }

        const hasValidServiceOrders: boolean = this.VerifyServiceOrders(this.NewStartDate(), this.NewEndDate());

        if (!hasValidServiceOrders) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.MissingServiceOrderOnResourceAllocationRangeCreation);
            return false;
        }

        return true;
    }

    private VerifyHours(): boolean {
        return this.HoursMonday() > 0 || this.HoursTuesday() > 0 || this.HoursWednesday() > 0 || this.HoursThursday() > 0 || this.HoursFriday() > 0 || this.HoursSaturday() > 0 || this.HoursSunday() > 0;
    }

    public VerifyServiceOrders(startDate: Date, endDate: Date): boolean {
        const serviceOrdersForInterval: IHumanResourceOrders[] = [];

        this.ServiceOrders().forEach((s: IHumanResourceOrders) => {
            if (this.IsServiceOrderForInterval(s, startDate, endDate))
                serviceOrdersForInterval.push(s);
        });

        if (serviceOrdersForInterval.length == 0)
            return false;

        if (serviceOrdersForInterval.length == 1) {
            return moment(serviceOrdersForInterval[0].FromDate) <= moment(startDate) && (!serviceOrdersForInterval[0].ToDate || moment(serviceOrdersForInterval[0].ToDate) >= moment(endDate));
        }

        serviceOrdersForInterval.sort((a,b) => moment(a.FromDate).toDate().valueOf() - moment(b.FromDate).toDate().valueOf());

        for (let i = 0; i < serviceOrdersForInterval.length - 1; i++) {
            if (moment(serviceOrdersForInterval[i].ToDate).diff(moment(serviceOrdersForInterval[i + 1].ToDate), 'days') > 0)
                return false;
        }

        let startIsIncluded = false;
        const endIsIncluded = false;
        serviceOrdersForInterval.forEach((s: IHumanResourceOrders) => {
            if (moment(startDate) >= moment(s.FromDate) && (!s.ToDate || moment(startDate) <= moment(s.ToDate)))
                startIsIncluded = true;
            if (moment(endDate) >= moment(s.FromDate) && (!s.ToDate || moment(endDate) <= moment(s.ToDate)))
                startIsIncluded = true;
        });

        return startIsIncluded && endIsIncluded;
    }

    private IsServiceOrderForInterval(s: IHumanResourceOrders, startDate: Date, endDate: Date): boolean {
        return (moment(s.FromDate) <= moment(startDate) && (!s.ToDate || (moment(s.ToDate) >= moment(startDate) && moment(s.ToDate) <= moment(endDate)))) ||
            (moment(s.FromDate) >= moment(startDate) && moment(s.FromDate) <= moment(endDate) && (!s.ToDate || (moment(s.ToDate) >= moment(endDate)))) ||
            (moment(s.FromDate) >= moment(startDate) && moment(s.FromDate) <= moment(endDate) && (!s.ToDate || (moment(s.ToDate) <= moment(endDate)))) ||
            (moment(s.FromDate) <= moment(startDate) && (!s.ToDate || (moment(s.ToDate) >= moment(endDate))));
    }

    public AddRange() {
        if (!this.VerifyRange())
            return;

        const intersectingRanges = this.AllocationRanges().filter((a : ResourceAllocationRangeViewModel) => a.IsIntersecting(this.NewStartDate(), this.NewEndDate()));
        if (intersectingRanges.length > 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.RangeIntersecting);
            return;
        }

        const alloc = new ResourceAllocationRangeViewModel(this.serviceLocator, {
            Id: this.allocationsService.GenerateNextId(),
            TeamResourceAllocationId: this.allocation.Id,
            StartDate: this.NewStartDate(),
            EndDate: this.NewEndDate(),
            Amount: this.NewAmount(),
            Monday: this.HoursMonday(),
            Tuesday: this.HoursTuesday(),
            Wednesday: this.HoursWednesday(),
            Thursday: this.HoursThursday(),
            Friday: this.HoursFriday(),
            Saturday: this.HoursSaturday(),
            Sunday: this.HoursSunday(),
            OperationalUnitId: this.OperationalUnit.Id,
            AllocationType: this.AllocationMode()
        }, this.OperationalUnit.Id, this, this.resource.Resource.Id, this.ResourceName(), this.teamsManagerProvider);

        alloc.AllocationMode(this.PercentageAllocationEnabled() ? ProlifeSdk.PercentageEditigMode : ProlifeSdk.HoursEditigMode);
        alloc.RegisterSelectionObserver(this);

        this.AllocationRanges.unshift(alloc);

        this.ResetNewAllocationFields();

        this.teamsManagerProvider.SynchronizeResourceRanges(this.allocation.ResourceId, this.allocation.TeamId, this.OperationalUnit.Id);
    }

    public ResetNewAllocationFields() {
        this.NewStartDate(null);
        this.NewEndDate(null);
        this.NewAmount(100);
        this.HoursMonday(0);
        this.HoursTuesday(0);
        this.HoursWednesday(0);
        this.HoursThursday(0);
        this.HoursFriday(0);
        this.HoursSaturday(0);
        this.HoursSunday(0);
    }

    public Load() : Promise<IResourceAllocationRange[]> {
        return this.LoadRanges();
    }

    private LoadRanges() : Promise<IResourceAllocationRange[]> {
        return this.allocationsService.getAllocationRanges(this.allocation.Id, this.OperationalUnit.Id)
            .then((ranges : IResourceAllocationRange[]) => {
                this.AllocationRanges(ranges.map(this.createAllocationRangeViewModel.bind(this)));
                this.RangesLoaded(true);
                return ranges;
            })
    }

    private findServiceOrder(serviceOrders : IHumanResourceOrders[], refDay : Moment) : IHumanResourceOrders {
        const foundServiceOrders = serviceOrders.filter(o => refDay >= moment(o.FromDate) && refDay < moment(o.ToDate || moment("2100-01-01")));
        if(foundServiceOrders.length > 0)
            return foundServiceOrders[0];
        return null;
    }

    private getWorkedHoursForDay(serviceOrder : IHumanResourceOrders, refDay : Moment, range: ResourceAllocationRangeViewModel) : number {
        const dayOfWeek = refDay.day();

        switch(dayOfWeek) {
            case 0:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSunday * (range.Amount() / 100)) : range.HoursSunday();
            case 1:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursMonday * (range.Amount() / 100)) : range.HoursMonday();
            case 2:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursTuesday * (range.Amount() / 100)) : range.HoursTuesday();
            case 3:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursWednesday * (range.Amount() / 100)) : range.HoursWednesday();
            case 4:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursThursday * (range.Amount() / 100)) : range.HoursThursday();
            case 5:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursFriday * (range.Amount() / 100)) : range.HoursFriday();
            case 6:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSaturday * (range.Amount() / 100)) : range.HoursSaturday();
            default:
                return 0;
        }
    }

    private createAllocationRangeViewModel(range : IResourceAllocationRange) : ResourceAllocationRangeViewModel {
        const rangeVM = new ResourceAllocationRangeViewModel(this.serviceLocator, range, this.OperationalUnit.Id, this, this.allocation.ResourceId, this.ResourceName(), this.teamsManagerProvider);
        rangeVM.RegisterSelectionObserver(this);
        return rangeVM;
    }

    public getData(allocationId : number) : IResourceAllocationRange[] {
        return this.AllocationRanges().map(all => all.getData(allocationId));
    }

    public AddEmptyInterval() {
        if(!this.VerifyRange())
            return;

        const intersectingRanges = this.AllocationRanges().filter((a : ResourceAllocationRangeViewModel) => a.IsIntersecting(this.NewStartDate(), this.NewEndDate()));
        if(intersectingRanges.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.RangeNotIntersecting);
            return;
        }

        intersectingRanges.forEach((r : ResourceAllocationRangeViewModel) => {
            if(r.IsFullyInRange(this.NewStartDate(), this.NewEndDate()))
                this.AllocationRanges.remove(r);
            else if(r.IsStartInRange(this.NewStartDate(), this.NewEndDate()))
                r.StartDate(this.NewEndDate());
            else if(r.IsEndInRange(this.NewStartDate(), this.NewEndDate()))
                r.EndDate(this.NewStartDate());
            else {
                const oldEnd = moment(r.EndDate()).toDate();
                r.EndDate(moment(this.NewStartDate()).add('days', -1).toDate());

                const index = this.AllocationRanges.indexOf(r);
                const newAlloc = new ResourceAllocationRangeViewModel(
                    this.serviceLocator,
                    {
                        Id: this.allocationsService.GenerateNextId(),
                        TeamResourceAllocationId: this.allocation.Id,
                        StartDate: moment(this.NewEndDate()).add('days', 1).toDate(),
                        EndDate: oldEnd,
                        Amount: r.Amount(),
                        Monday: r.HoursMonday(),
                        Tuesday: r.HoursTuesday(),
                        Wednesday: r.HoursWednesday(),
                        Thursday: r.HoursThursday(),
                        Friday: r.HoursFriday(),
                        Saturday: r.HoursSaturday(),
                        Sunday: r.HoursSunday(),
                        OperationalUnitId: this.OperationalUnit.Id,
                        AllocationType: r.AllocationMode()
                    },
                    this.OperationalUnit.Id,
                    this,
                    this.allocation.ResourceId,
                    this.ResourceName(),
                    this.teamsManagerProvider
                );
                newAlloc.RegisterSelectionObserver(this);
                this.AllocationRanges.splice(index + 1, 0, newAlloc);
            }
        });

        this.ResetNewAllocationFields();

        this.teamsManagerProvider.SynchronizeResourceRanges(this.allocation.ResourceId, this.allocation.TeamId, this.OperationalUnit.Id);
    }

    public RelocateResource(): void {
        if (!this.VerifyRange())
            return;

        const allocationPerDays: IAllocationPerDays = this.AllocationMode() == ProlifeSdk.ResourceAllocationByPercentage ?
            null :
        {
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday()
        };

        const reallocationDialog = new ResourceReallocationDialog(
            this.serviceLocator,
            this.allocation.TeamId,
            this.allocation.ResourceId,
            this.OperationalUnit,
            this.NewStartDate(),
            this.NewEndDate(),
            this.NewAmount(),
            allocationPerDays,
            this.ResourceName(),
            this.teamsManagerProvider
        );
        this.dialogsService.ShowModal<ITeamsManagerProvider>(reallocationDialog, "fullscreen minheight800 minwidth1280", null, "allocations/templates/dialogs", "resource-reallocation-dialog")
            .then((teamsManager: ITeamsManagerProvider) => {
                if (!teamsManager)
                    return;
                this.UpdateAllocationsRanges(teamsManager);
            });
    }

    public UpdateAllocationsRanges(teamsManager: ITeamsManagerProvider): void {
        teamsManager.CloneToProvider(this.teamsManagerProvider);

        //this.teamsManagerProvider.StealTeamMangerFrom(teamsManager);
    }

    public ResetRangeSelection(): void {
        this.AllocationRanges().forEach((r: ResourceAllocationRangeViewModel) => {
            r.Selected(false);
        });
    }

    public SelectedAllocationByPercentage(): void {
        this.PercentageAllocationEnabled(!this.PercentageAllocationEnabled());
        this.HoursAllocationEnabled(false);
        this.AllocationMode(this.PercentageAllocationEnabled() ? ProlifeSdk.ResourceAllocationByPercentage : null);
    }

    public SelectedAllocationByHours(): void {
        this.PercentageAllocationEnabled(false);
        this.HoursAllocationEnabled(!this.HoursAllocationEnabled());
        this.AllocationMode(this.HoursAllocationEnabled() ? ProlifeSdk.ResourceAllocationByHours : null);
    }

    public Clone(teamsManagerProvider: ITeamsManagerProvider): IResourceOperationalUnitViewModel {
        const ou: ResourceOperationalUnitViewModel = new ResourceOperationalUnitViewModel(this.serviceLocator, this.allocation, this.OperationalUnit, this.ServiceOrders(), this.resource, teamsManagerProvider);
        ou.AllocationRanges(this.AllocationRanges().map((r: ResourceAllocationRangeViewModel) => { return r.Clone(ou, teamsManagerProvider); }));
        ou.RangesLoaded(true);
        return ou;
    }
}