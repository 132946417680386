/**
 * Created by m.buonaguidi on 15/06/2017.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SectionForWizard } from "./SectionForWizard";
import { QuestionForWizard } from "./QuestionForWizard";
import { QuestionnaireIdentifierViewModel } from "../questionnaires/QuestionnaireIdentifierViewModel";
import { ContactIdentifier } from "../queue/ContactIdentifier";
import { ReschedulesDialog } from "./dialogs/ReschedulesDialog";
import { FinishInterviewDialog } from "./dialogs/FinishInterviewDialog";
import { InterviewCompletedDialog } from "./dialogs/InterviewCompletedDialog";
import { DisableQuestionConfirmDialog } from "./dialogs/DisableQuestionConfirmDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import {
    ISurveyWizard,
    ISurveyWizardQuestion,
    ISurveyWizardService,
    ISurveyWizardSection,
    ISurveyWizardAnswer,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ISurveyService } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { IPeopleService, IEditPeopleResult } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ISelectionObserver } from "../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { ICampaignEnvelope } from "../../../../ProlifeSdk/interfaces/survey-wizard/ICampaignIdentifier";
import { IQuestionListObject } from "../../../../ProlifeSdk/interfaces/survey/IQuestionListObject";
import { ICallInQueueData, ICallResultData } from "../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";
import { IQuestionnaireIdentifier } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQuestionnaireIdentifier";
import { IContactForList } from "../../../../ProlifeSdk/interfaces/survey-wizard/IContactForList";
import { IQuestion } from "../../../../ProlifeSdk/interfaces/survey/IQuestion";
import { ISection } from "../../../../ProlifeSdk/interfaces/survey/ISection";
import { IAnswerForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerForWizard";
import { IQueueStateLabel } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueStateLabel";
import { IAnswerFromWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerFromWizard";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";

export class SurveyWizardViewModel implements ISelectionObserver, ISurveyWizard {
    public templateName = "wizard";
    public templateUrl = "surveywizard/templates/wizard";

    public Campaign: ko.Observable<ICampaignEnvelope> = ko.observable();
    public People: ko.Observable<ContactIdentifier> = ko.observable();
    public Questionnaire: ko.Observable<QuestionnaireIdentifierViewModel> = ko.observable();
    public Sections: ko.ObservableArray<SectionForWizard> = ko.observableArray([]);
    public Questions: ko.ObservableArray<QuestionForWizard> = ko.observableArray([]);
    public JobOrderId: ko.Observable<number> = ko.observable();
    public CanNavigateJobOrder: ko.Computed<boolean>;

    public QuestionsList: ko.Computed<IQuestionListObject[]>;
    public CanReturnToPrevious: ko.Computed<boolean>;

    public DoActionForAllQuestions: ko.Observable<boolean> = ko.observable(false);

    private questionHistory: ko.ObservableArray<ISurveyWizardQuestion> = ko.observableArray([]);

    public SelectedQuestion: ko.Observable<ISurveyWizardQuestion> = ko.observable();
    private SelectedQuestionIndex: ko.Observable<number> = ko.observable();
    public QuestionsNumber: ko.Computed<number>;
    public SectionsNumber: ko.Computed<number>;
    public TestMode: ko.Computed<boolean>;

    public Mode: ko.Observable<number> = ko.observable();

    public LastQuestion: ISurveyWizardQuestion = null;
    public JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    public NewJobOrderUrl = "";
    public EditJobOrderUrl: ko.Computed<string>;

    private Finish = false;

    @LazyImport(nameof<ISurveyWizardService>())
    private surveyWizardService: ISurveyWizardService;
    @LazyImport(nameof<ISurveyService>())
    private surveyService: ISurveyService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;
    @LazyImport(nameof<IPeopleService>())
    private peoplesService: IPeopleService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    private call: ICallInQueueData;
    public PreviousCallResult: ko.Observable<string> = ko.observable();

    public ShowPrompt: boolean;

    constructor(private serviceLocator, public queueId: number, public resume: string, mode: number) {
        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setShowClosed(true);

        this.Mode(mode);
        this.ShowPrompt = true;

        this.QuestionsList = ko.computed(() => {
            const questions: IQuestionListObject[] = [];
            this.Questions().forEach((q: QuestionForWizard) => {
                questions.push(<IQuestionListObject>{
                    Data: q,
                    IsSection: false,
                });
            });
            this.Sections().forEach((s: SectionForWizard) => {
                questions.push(<IQuestionListObject>{
                    Data: s,
                    IsSection: true,
                });
                s.Questions().forEach((sq: QuestionForWizard) => {
                    questions.push(<IQuestionListObject>{
                        Data: sq,
                        IsSection: false,
                    });
                });
            });

            return questions;
        });

        this.QuestionsNumber = ko.computed(() => {
            return this.QuestionsList().filter((o: IQuestionListObject) => {
                return !o.IsSection;
            }).length;
        });

        this.SectionsNumber = ko.computed(() => {
            return this.Sections().length;
        });

        this.CanReturnToPrevious = ko.computed(() => {
            return this.SelectedQuestionIndex() > 0;
        });

        this.TestMode = ko.computed(() => {
            return this.Mode() == ProlifeSdk.WizardTestMode;
        });

        this.CanNavigateJobOrder = ko.computed(() => {
            return this.JobOrderId() > 0;
        });

        this.EditJobOrderUrl = ko.computed(() => {
            if (!this.CanNavigateJobOrder()) return "";

            return this.jobOrderService.getJobOrderUrl(this.JobOrderId());
        });

        this.NewJobOrderUrl = this.jobOrderService.getJobOrderUrl(-1);

        this.outboundLoad();
    }

    private async outboundLoad(): Promise<void> {
        try {
            const call: ICallInQueueData = await this.surveyWizardService.getCallInQueueData(this.queueId);
            this.call = call;
            this.JobOrderId(call.LastJobOrderId);

            const loadDataResults = await Promise.all([
                this.loadPreviousCallResult(call),
                this.loadCampaignData(call),
                this.loadQuestionnaireData(call),
                this.loadPeopleData(call),
            ]);

            if (loadDataResults.some((r) => !r)) return;

            const loadQuestionnaireResult = await this.LoadQuestionnaire(call.QuestionnaireId);
            if (!loadQuestionnaireResult) return;

            const loadAnswersResult = await this.LoadAnswers();
            if (!loadAnswersResult) return;

            if (this.resume == ProlifeSdk.TextResources.SurveyWizard.ResumedWizardUrl || call.AttemptNumber > 1) {
                this.resumeWizardState();
                return;
            }
            if (this.QuestionsList().length > 0) {
                const index: number = this.selectableQuestionIndex(0);
                this.selectQuestion(<ISurveyWizardQuestion>this.QuestionsList()[index].Data);
            }
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetCallInQueueError);
        }
    }

    private async loadPreviousCallResult(call: ICallInQueueData): Promise<boolean> {
        if (call.AttemptNumber > 1) {
            try {
                const result: string = await this.surveyWizardService.getPreviousCallResult(
                    call.PeopleId,
                    call.CampaignId
                );
                this.PreviousCallResult(result);
            } catch (e) {
                console.error(e);
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetPreviousCallResultError);
                return false;
            }
        }

        return true;
    }

    private async loadCampaignData(call: ICallInQueueData): Promise<boolean> {
        try {
            const campaign: ICampaignEnvelope = await this.surveyWizardService.getCampaignDataForWizard(
                call.CampaignId
            );
            this.Campaign(campaign);
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetCampaignDataError);

            return false;
        }

        return true;
    }

    private async loadQuestionnaireData(call: ICallInQueueData): Promise<boolean> {
        try {
            const questionnaire: IQuestionnaireIdentifier =
                await this.surveyWizardService.getQuestionnaireDataForWizard(call.QuestionnaireId);
            if (!questionnaire) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
                return false;
            }

            this.Questionnaire(new QuestionnaireIdentifierViewModel(questionnaire));
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.QuestionnaireNotFound);
            return false;
        }

        return true;
    }

    private async loadPeopleData(call: ICallInQueueData): Promise<boolean> {
        try {
            const contact: IContactForList = await this.surveyWizardService.getPeopleDataForWizard(call.PeopleId);
            if (!contact) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.PeopleNotFound);
                return false;
            }

            this.People(new ContactIdentifier(contact));
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetPeopleDataError);
            return false;
        }

        return true;
    }

    private resumeWizardState(): void {
        let lastQuestionIndex = 0;
        for (let index = 0; index < this.QuestionsList().length; index++) {
            if (
                !this.QuestionsList()[index].IsSection &&
                (<ISurveyWizardQuestion>this.QuestionsList()[index].Data).Saved()
            ) {
                this.questionHistory.push(<ISurveyWizardQuestion>this.QuestionsList()[index].Data);
                const nextIndex = this.checkGoTo(<ISurveyWizardQuestion>this.QuestionsList()[index].Data);
                if (!nextIndex) {
                    lastQuestionIndex = index;
                    continue;
                }
                index = nextIndex - 1;
                lastQuestionIndex = index;
            }
        }
        lastQuestionIndex = this.selectableQuestionIndex(
            lastQuestionIndex == 0 ? lastQuestionIndex : lastQuestionIndex + 1
        );
        this.selectQuestion(<ISurveyWizardQuestion>this.QuestionsList()[lastQuestionIndex].Data);
    }

    public async LoadQuestionnaire(questionnaireId: number): Promise<boolean> {
        try {
            const loadResult = await Promise.all([
                await this.loadQuestionsWithoutSection(questionnaireId),
                await this.loadSectionsWithQuestions(questionnaireId),
            ]);

            if (loadResult.some((r) => !r)) return false;

            if (this.QuestionsList().filter((q) => !q.IsSection).length == 0)
                this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionsNotFound);

            return true;
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadSectionsAndQuestionsError);
            return false;
        }

        return true;
    }

    private async loadQuestionsWithoutSection(questionnaireId: number): Promise<boolean> {
        try {
            const questions: IQuestion[] = await this.surveyService.getQuestionsWithoutSection(questionnaireId);
            if (!questions || questions.length == 0) {
                return true;
            }

            this.Questions(
                questions.map((question: IQuestion) => {
                    const newQuestion = new QuestionForWizard(this.serviceLocator, question, this);
                    newQuestion.RegisterSelectionObserver(this);
                    return newQuestion;
                })
            );
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
            return false;
        }

        return true;
    }

    private async loadSectionsWithQuestions(questionnaireId: number): Promise<boolean> {
        try {
            const sections: ISection[] = await this.surveyService.getQuestionnaireSections(questionnaireId);
            if (sections.length == 0) return true;

            const sectionCalls: Promise<IQuestion[]>[] = [];

            this.Sections(
                sections.map((s: ISection) => {
                    const sectionViewModel: SectionForWizard = new SectionForWizard(this.serviceLocator, s, this);
                    sectionCalls.push(sectionViewModel.LoadQuestions(s.Id));
                    return sectionViewModel;
                })
            );

            await Promise.all(sectionCalls);
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadSectionsError);
            return false;
        }

        return true;
    }

    public async LoadAnswers(): Promise<boolean> {
        const calls: Promise<IAnswerForWizard[]>[] = [];

        try {
            for (const q of this.QuestionsList()) {
                if (!q.IsSection) calls.push((<ISurveyWizardQuestion>q.Data).LoadAnswers());
            }

            await Promise.all(calls);
            return true;
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadAnswersError);
            return false;
        }

        return true;
    }

    public DoScroll(): void {
        if (this.SelectedQuestionIndex() == 0) {
            this.SelectedQuestion().DoScroll(true);
            this.SelectedQuestion().DoScroll(false);
            return;
        }
        this.QuestionsList()[this.SelectedQuestionIndex() - 1].Data.DoScroll(true);
        this.QuestionsList()[this.SelectedQuestionIndex() - 1].Data.DoScroll(false);
    }

    public ReschedulesInterview(): void {
        const reschedulesDialog = new ReschedulesDialog(this.call, this.JobOrderId());
        this.dialogService
            .ShowModal<boolean>(
                reschedulesDialog,
                null,
                null,
                "surveywizard/templates/wizard/dialogs",
                "reschedules-dialog"
            )
            .then((result: boolean) => {
                if (result) {
                    this.ShowPrompt = false;
                    location.href =
                        "#/" +
                        ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
                        "/" +
                        ProlifeSdk.TextResources.SurveyWizard.CampaignUrl +
                        "/" +
                        this.Campaign().Id;
                }
            });
    }

    public FinishInterview(): void {
        const finishInterviewDialog = new FinishInterviewDialog(this.call, this.JobOrderId());
        this.dialogService
            .ShowModal<boolean>(
                finishInterviewDialog,
                null,
                null,
                "surveywizard/templates/wizard/dialogs",
                "finish-interview-dialog"
            )
            .then((result: boolean) => {
                if (result) {
                    this.ShowPrompt = false;
                    location.href =
                        "#/" +
                        ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
                        "/" +
                        ProlifeSdk.TextResources.SurveyWizard.CampaignUrl +
                        "/" +
                        this.Campaign().Id;
                }
            });
    }

    public NextStep(): void {
        this.SaveAnswer();
        this.DoScroll();
    }

    public PreviousStep(): void {
        this.selectPreviousQuestion();
        this.DoScroll();
    }

    public SaveAnswer(): void {
        if (this.Mode() == ProlifeSdk.WizardExecutionMode) {
            this.saveStep()
                .then(() => {
                    this.getNextQuestion();
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.SaveWizardStepError);
                });
            return;
        }
        this.getNextQuestion();
    }

    public async ShowPeopleDetailsDialog(): Promise<void> {
        const people = this.People();
        const editedPeople: IEditPeopleResult = await this.peoplesService.showPeopleDialog(people.Id);
        if (!editedPeople) return;

        people.Name(editedPeople.PeopleData.Name);
        people.Surname(editedPeople.PeopleData.Surname);
        people.BusinessName(editedPeople.PeopleData.BusinessName);
        people.PhoneNumber(editedPeople.PeopleData.PhoneNumber);
        people.Email(editedPeople.PeopleData.Email);
        people.PEC(editedPeople.PeopleData.PEC);
    }

    private getNextQuestion(): void {
        this.SelectedQuestion().Saved(true);
        this.questionHistory.push(this.SelectedQuestion());
        this.selectNextQuestion(this.SelectedQuestion());
        if (!this.Finish) {
            return;
        }
        this.finishInterview();
    }

    private finishInterview(): void {
        if (this.Mode() == ProlifeSdk.WizardExecutionMode) {
            this.surveyWizardService
                .getQueueStateLabel(ProlifeSdk.SuccesfullInterviewState)
                .then((label: IQueueStateLabel) => {
                    const resultCall: ICallResultData = {
                        Id: null,
                        CallsQueueId: this.call.Id,
                        CampaignId: this.call.CampaignId,
                        PeopleId: this.call.PeopleId,
                        AttemptNumber: this.call.AttemptNumber,
                        OperatorId: null,
                        CallDate: null,
                        ResultId: label.Id,
                        JobOrderId: this.JobOrderId(),
                    };

                    this.surveyWizardService
                        .saveCallState(null, true, resultCall)
                        .then(() => {
                            const completedInterviewDialog = new InterviewCompletedDialog();
                            this.dialogService
                                .ShowModal<boolean>(
                                    completedInterviewDialog,
                                    null,
                                    null,
                                    "surveywizard/templates/wizard/dialogs",
                                    "interview-completed-dialog"
                                )
                                .then((finish: boolean) => {
                                    if (finish) {
                                        this.ShowPrompt = false;
                                        location.href =
                                            "#/" +
                                            ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
                                            "/" +
                                            ProlifeSdk.TextResources.SurveyWizard.CampaignUrl +
                                            "/" +
                                            this.Campaign().Id;
                                    }
                                });
                        })
                        .catch(() => {
                            this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.FinishInterviewError);
                        });
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.FinishInterviewError);
                });
            return;
        }
        const completedInterviewDialog = new InterviewCompletedDialog();
        this.dialogService.ShowModal<void>(
            completedInterviewDialog,
            null,
            null,
            "surveywizard/templates/wizard/dialogs",
            "interview-completed-dialog"
        );
    }

    public selectQuestion(question: ISurveyWizardQuestion): void {
        this.Questions().forEach((q: ISurveyWizardQuestion) => {
            q.Selected(false);
        });
        this.Sections().forEach((s: ISurveyWizardSection) => {
            s.Questions().forEach((q: ISurveyWizardQuestion) => {
                q.Selected(false);
            });
        });
        question.Selected(true);
        question.EnableScroll = true;
        if (!this.isQuestionInHistory(question)) this.LastQuestion = question;
        this.SelectedQuestion(question);
        this.QuestionsList().forEach((q: IQuestionListObject, index: number) => {
            if (q.Data.Id == question.Id) this.SelectedQuestionIndex(index);
        });
    }

    private isQuestionInHistory(q: ISurveyWizardQuestion): boolean {
        let old = false;
        for (let i = 0; i < this.questionHistory().length; i++) {
            if (this.questionHistory()[i].Id == q.Id) {
                old = true;
                break;
            }
        }
        return old;
    }

    public selectPreviousQuestion(): void {
        if (this.questionHistory().length > 0) {
            for (let index = 0; index < this.questionHistory().length; index++) {
                if (this.questionHistory()[index].Id == this.SelectedQuestion().Id) {
                    if (index > 0) this.selectQuestion(this.questionHistory()[--index]);
                    return;
                }
            }
            this.selectQuestion(this.questionHistory()[this.questionHistory().length - 1]);
        }
    }

    private isSelectableQuestion(index: number): boolean {
        return !this.QuestionsList()[index].IsSection && this.QuestionsList()[index].Data.Enabled();
    }

    private skipSection(index: number): boolean {
        return this.QuestionsList()[index].IsSection && !this.QuestionsList()[index].Data.Enabled();
    }

    private selectableQuestionIndex(startIndex: number): number {
        let selectable = false;
        let skipSection = false;
        let questionIndex = startIndex;
        while (!selectable) {
            if (!this.QuestionsList()[questionIndex]) {
                this.Finish = true;
                return null;
            }

            if (this.QuestionsList()[questionIndex].IsSection) skipSection = this.skipSection(questionIndex);

            if (!skipSection) {
                selectable = this.isSelectableQuestion(questionIndex);
            }
            questionIndex += selectable ? 0 : 1;
        }
        return questionIndex;
    }

    public selectNextQuestion(question: ISurveyWizardQuestion): void {
        let questionIndex: number = null;
        for (let index = 0; index < this.QuestionsList().length; index++) {
            if (this.QuestionsList()[index].Data.Id == question.Id) {
                this.enableQuestionOrSection(this.SelectedQuestion());
                questionIndex = this.checkGoTo(this.SelectedQuestion());
                if (!questionIndex) questionIndex = this.selectableQuestionIndex(index + 1);
            }
        }
        if (!questionIndex) return;
        this.selectQuestion(<ISurveyWizardQuestion>this.QuestionsList()[questionIndex].Data);
    }

    private checkGoTo(question: ISurveyWizardQuestion): number {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers().filter((a: ISurveyWizardAnswer) => {
            return !!a.GoToLabel();
        });
        if (answers.length == 0) return null;
        const label = answers[0].GoToLabel();

        let questionIndex: number = null;
        this.QuestionsList().forEach((obj: IQuestionListObject, index: number) => {
            if (!obj.IsSection && obj.Data.Label() == label) questionIndex = index;
        });

        return questionIndex;
    }

    private enableQuestionOrSection(question: ISurveyWizardQuestion): void {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers().filter((a: ISurveyWizardAnswer) => {
            return !!a.EnableLabel();
        });
        answers.forEach((a: ISurveyWizardAnswer) => {
            this.QuestionsList().forEach((q: IQuestionListObject) => {
                if (q.Data.Label() == a.EnableLabel()) q.Data.Enabled(true);
            });
        });
    }

    public checkForDisablingQuestionWithAnswer(answer: ISurveyWizardAnswer): void {
        this.QuestionsList().forEach((obj: IQuestionListObject) => {
            if (obj.Data.Label() == answer.EnableLabel()) {
                this.checkIfHasAnswers(obj, answer);
            }
        });
    }

    private checkIfHasAnswers(object: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        if (!object.IsSection) {
            this.checkAnswersForSingleQuestion(object, answer);
            return;
        }
        this.checkAnswersForSectionQuestions(object, answer);
    }

    private checkAnswersForSectionQuestions(obj: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        const questionsWithAnswers: ISurveyWizardQuestion[] = (<ISurveyWizardSection>obj.Data)
            .Questions()
            .filter((q: ISurveyWizardQuestion) => {
                return q.SelectedAnswers().length > 0;
            });
        if (questionsWithAnswers.length == 0) {
            obj.Data.Enabled(false);
            return;
        }
        if (this.DoActionForAllQuestions()) {
            questionsWithAnswers.forEach((q: ISurveyWizardQuestion) => {
                this.resetQuestionAnswers(q);
            });
            obj.Data.Enabled(false);
            this.DoActionForAllQuestions(!this.DoActionForAllQuestions());
            return;
        }
        const confirmDialog = new DisableQuestionConfirmDialog(this, obj);
        this.dialogService
            .ShowModal<boolean>(
                confirmDialog,
                null,
                null,
                "surveywizard/templates/wizard/dialogs/",
                "disable-question-confirm-dialog"
            )
            .then((result: boolean) => {
                if (!result) {
                    this.abortAnswerChange(answer);
                } else {
                    this.resetQuestionAnswers(questionsWithAnswers[0]);
                    this.checkAnswersForSectionQuestions(obj, answer);
                }
            });
    }

    private checkAnswersForSingleQuestion(obj: IQuestionListObject, answer: ISurveyWizardAnswer): void {
        if ((<ISurveyWizardQuestion>obj.Data).SelectedAnswers().length == 0) {
            return;
        }
        if (!this.DoActionForAllQuestions()) {
            const confirmDialog = new DisableQuestionConfirmDialog(this, obj);
            this.dialogService
                .ShowModal<boolean>(
                    confirmDialog,
                    null,
                    null,
                    "surveywizard/templates/wizard/dialogs/",
                    "disable-question-confirm-dialog"
                )
                .then((result: boolean) => {
                    if (!result) {
                        this.abortAnswerChange(answer);
                    } else {
                        this.resetQuestionAnswers(<ISurveyWizardQuestion>obj.Data);
                    }
                });
            return;
        }
        this.resetQuestionAnswers(<ISurveyWizardQuestion>obj.Data);
        this.DoActionForAllQuestions(!this.DoActionForAllQuestions());
    }

    private abortAnswerChange(answer: ISurveyWizardAnswer): void {
        answer.Checked(!answer.Checked());
        if (this.SelectedQuestion().IsSingleAnswerQuestion()) this.SelectedQuestion().UncheckSingleAnswers(answer.Id);
    }

    private resetQuestionAnswers(question: ISurveyWizardQuestion): void {
        const answers: ISurveyWizardAnswer[] = question.SelectedAnswers();
        answers.forEach((a: ISurveyWizardAnswer) => {
            a.reset(this.Campaign().Id, this.People().Id);
        });
        question.ResetState();
    }

    selectSection(section: any): void {}

    private saveStep(): Promise<void> {
        const selectedAnswers: IAnswerFromWizard[] = this.SelectedQuestion()
            .SelectedAnswers()
            .map((a: ISurveyWizardAnswer) => {
                return a.ToJSON();
            });

        return this.surveyWizardService.saveWizardStep(
            this.Campaign().Id,
            this.People().Id,
            this.SelectedQuestion().Id,
            this.SelectedQuestion().AnswerTypeId(),
            selectedAnswers
        );
    }

    public dispose() {}
}
