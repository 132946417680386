import * as ko from "knockout";
import * as ProLifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { ProlifeSdkService } from "../services";
import moment = require("moment");
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IDocumentVersionNumberSuggestion, IGetDocumentVersionNumbersSuggestionsRequest } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export interface IDocumentVersionNumberDataSourceModel extends IDataSourceModel<number, IDocumentVersionNumberSuggestion> {

}

export class DocumentVersionNumbersDataSource implements IDataSource {
    private view: IDataSourceView;
    private documentType: string;
    private customerId: number;
    private jobOrderId: number;
    private protocolId: number;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    
    constructor() {}
    
    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setDocumentType(type: string): void {
        this.documentType = type;
    }

    public setCustomerId(customerId: number): void {
        this.customerId = customerId;
    }

    public setJobOrderId(jobOrderId: number): void {
        this.jobOrderId = jobOrderId;
    }

    public setProtocolId(protocolId: number) {
        this.protocolId = protocolId;
    }

    public getTitle(currentModel: IDocumentVersionNumberDataSourceModel): string {
        return ProLifeSdk.TextResources.Invoices.DocumentRevisionNumbersSeggestions;
    }
    
    public async getData(currentModel: IDocumentVersionNumberDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDocumentVersionNumberDataSourceModel[]> {
        let request: IGetDocumentVersionNumbersSuggestionsRequest = {
            documentType: this.documentType,
            protocolId: this.protocolId,
            customerId: this.customerId,
            jobOrderId: this.jobOrderId,
            textFilter: textFilter,
            skip: skip,
            count: count
        };

        let suggestions = await this.documentsService.GetDocumentVersionNumbersSuggestions(request);

        return suggestions.map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IDocumentVersionNumberDataSourceModel, ids: number[]): Promise<IDocumentVersionNumberDataSourceModel[]> {
        let  suggestions = await this.documentsService.GetDocumentVersionNumbersSuggestionsByDocumentIds(this.documentType, ids);
        return suggestions.map(this.createModelFor, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public isGroupedData(currentModel: IDocumentVersionNumberDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IDocumentVersionNumberDataSourceModel, b: IDocumentVersionNumberDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(suggestion: IDocumentVersionNumberSuggestion): IDocumentVersionNumberDataSourceModel {
        let documentLabel = String.format(ProLifeSdk.TextResources.Invoices.DocumentName, suggestion.ProtocolName, suggestion.DocumentNumber, moment(suggestion.Date).format("L"));

        return {
            id: suggestion.DocumentId,
            isGroup: false,
            isLeaf: true,
            title: suggestion.VersionNumber ? suggestion.VersionNumber.toString() : "1",
            subTitle: String.format(TextResources.Invoices.VersionNumberSuggestion, (suggestion.FullVersionRevisionNumber || ProLifeSdk.TextResources.Invoices.VersionNumberNotAvailable), documentLabel, (!this.jobOrderId && !!this.customerId ? "<br/>(" + suggestion.JobOdrerName + ")" : "")),
            model: suggestion
        };
    }
}