import { IService } from "../Core/interfaces/IService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { WarehouseInspectionAction as WarehouseInspectionOperationType } from "./warehouse/ui/WarehouseInspections/Enums/WarehouseInspectionAction";
import { WarehouseInspectionEditorDialog } from "./warehouse/ui/WarehouseInspections/Dialogs/WarehouseInspectionEditorDialog";
import { WarehouseInspectionsDestinationProtocolsSettingsManager } from "./warehouse/settings/WarehouseInspectionsDestinationProtocolsSettingsManager";

export interface IWarehouseInspectionOperation {
    InspectionOperationId : number;
    FKInspection : number;
    FKArticle : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    UOM?: string;
    FKSourceWarehouse : number;
    SourceWarehouse : string;
    FKDestinationWarehouse : number;
    DestinationWarehouse : string;
    WarehousePosition?: string;
    Amount : number;
    Done : boolean;
}

export interface ITakeOnWarehouseInspectionResponse {
}

export interface IWarehouseInspectionWizardArticleInfo {
    Id : number;
    WarehouseInspectionId : number;
    ArticleId : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    SourceWarehouseId : number;
    SourceWarehouse : string;
    SourceWarehouseStock?: number;
    DestinationWarehouseId : number;
    DestinationWarehouse : string;
    DestinationWarehouseStock?: number;
    CustomerId?: number;
    CustomerName?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    Amount : number;
    LastInspectionDate?: Date;
    OperationType : WarehouseInspectionOperationType;
    Done?: boolean;

    Sources : IWarehouseInspectionWizardArticleInfoSource[];
}

export interface IWarehouseInspectionWizardArticleInfoSource {
    WarehouseInspectionOperationId : number;
    DocumentId : number;
    DocumentType : string;
    DocumentLabel : string;
    DocumentDate : Date;
    DocumentNumber : string;
    DocumentRegisterId : number;
    DocumentRegisterName : string;
    RefId : number;
    RefAmount : number;
}

export interface IWarehouseInspectionWarehouse {
    WarehouseId : number;
    WarehouseName : string;
    JobOrderName?: string;
    CustomerName?: string;
    IsDestination?: boolean;
    IsDefaultWarehouse?: boolean;
}

export interface IArticleRequirementsInfo {
    ArticleId?: number;
    ArticleDescription?: string;
    ArticleCode : string;
    MefCode?: string;
    EanCode?: string;
    SourceWarehouseId : number;
    SourceWarehouse : string;
    DestinationWarehouseId?: number;
    DestinationWarehouse : string;
    RequestedAmount?: number;
    SourceWarehouseStock : number;
    DestinationWarehouseStock : number;
    JobOrderId?: number;
    JobOrder?: string;
    CustomerId?: number;
    Customer?: string;
}

export interface IArticleRequirementsInfo {
    ArticleId?: number;
    ArticleDescription?: string;
    ArticleCode : string;
    MefCode?: string;
    EanCode?: string;
    SourceWarehouseId : number;
    SourceWarehouse : string;
    DestinationWarehouseId?: number;
    DestinationWarehouse : string;
    RequestedAmount?: number;
    RefId?: number;
    SourceWarehouseStock : number;
    DestinationWarehouseStock : number;
    JobOrderId?: number;
    JobOrder?: string;
    CustomerId?: number;
    Customer?: string;
}

export interface IWarehouseInspectionsDestinationProtocols_Type {
    Id?: number;
    DocumentType?: string;
    FiscalDocumentType : number;
    IsInventoryAdjustment?: boolean;
    FKRegister : number;
}

export interface IWarehouseInspectionDestinationProtocol {
    Id : number;
    DocumentType : string;
    FiscalDocumentType : number;
    IsInventoryAdjustment : boolean;
    FKRegister : number;
}

export interface IFullWarehouseInspectionResult3 {
    FKInspection : number;
    DocumentsGenerated?: boolean;
}

export interface IFullWarehouseInspectionResult4 {
    FKInspection : number;
    LastDocumentsGenerationError : string;
}

export interface IGetRequirementsFromDocumentsForWarehouseInspectionRequest {
    startDateFilter?: Date;
    endDateFilter?: Date;
    fromWarehouseLoads?: boolean;
    skip?: number;
    count?: number;
}

export interface IArticleRequirementFromDocuments {
    ArticleId?: number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    RefId?: number;
    Amount?: number;
    DocumentId : number;
    DocumentType : string;
    DocumentLabel?: string;
    DocumentDate?: Date;
    DocumentNumber?: string;
    DocumentRegisterId : number;
    DocumentRegisterName?: string;
    CustomerId?: number;
    CustomerName?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    DestinationWarehouseId?: number;
    DestinationWarehouse?: string;
    DestinationWarehouseStock : number;
    SourceWarehouseId : number;
    SourceWarehouseName : string;
    SourceWarehouseStock : number;
}

export interface IGetWarehouseArticlesStockForWarehouseInspectionRequest {
    textFilter?: string;
    warehouseId?: number;
    lastWarehouseInspectionFilter?: Date;
    maxArticlesNumberPerWarehouse?: number;
    skip?: number;
    count?: number;
}

export interface IWarehouseArticleStock {
    ArticleId : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    WarehouseId : number;
    Warehouse : string;
    CustomerId?: number;
    CustomerName?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    Amount : number;
    LastInspectionDate?: Date;
}

export interface IWarehouseInspectionOperationForInspection {
    InspectionOperationId : number;
    FKInspection : number;
    FKArticle : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    UOM?: string;
    FKSourceWarehouse : number;
    SourceWarehouse : string;
    SourceWarehouseCode?: string;
    SourceWarehouseIsDefault : boolean;
    FKDestinationWarehouse : number;
    DestinationWarehouse : string;
    DestinationWarehouseCode?: string;
    DestinationWarehouseIsDefault : boolean;
    Hallway?: string;
    Shelf?: string;
    Ledge?: string;
    WarehousePosition?: string;
    RequestedAmount : number;
    Done?: boolean;
    ActualStockOnDestinationWarehouse : number;
}

export interface ISaveWarehouseInspectionResultsResponse {
    InspectionId : number;
    ActualInspectionStatus : number;
    RemainingOperations?: number;
}

export interface IWarehouseInspectionOperationForInspection {
    InspectionOperationId : number;
    FKInspection : number;
    FKArticle : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    UOM?: string;
    FKSourceWarehouse : number;
    SourceWarehouse : string;
    SourceWarehouseCode?: string;
    SourceWarehouseIsDefault : boolean;
    FKDestinationWarehouse : number;
    DestinationWarehouse : string;
    DestinationWarehouseCode?: string;
    DestinationWarehouseIsDefault : boolean;
    Hallway?: string;
    Shelf?: string;
    Ledge?: string;
    WarehousePosition?: string;
    RequestedAmount : number;
    Done?: boolean;
    ActualStockOnSourceWarehouse : number;
    ActualStockOnDestinationWarehouse : number;
}

export interface IAddWarehouseInspectionOperationResponse {
}

export interface IAddWarehouseInspectionOperationRequest {
    inspectionId?: number;
    articleId?: number;
    sourceWarehouseId?: number;
    destinationWarehouseId?: number;
    amount?: number;
    operationType?: number;
    defaultWarehouseStock?: number;
    verifiedArticleCode?: boolean;
    verifiedArticleCodeOnDestinationDefaultWarehouse?: boolean;
    verifiedSourceWarehouse?: boolean;
    verifiedDestinationWarehouse?: boolean;
    userId?: number;
}

export interface IWarehouseInspectionResult {
    InspectionOperationId : number;
    Amount?: number;
    Done?: boolean;
    VerifiedSourceWarehouse?: boolean;
    VerifiedDestinationWarehouse?: boolean;
}

export interface ISaveWarehouseInspectionResultsRequest {
    results?: IWarehouseInspectionResult[];
    defaultWarehouseStock?: number;
    verifiedArticleCode?: boolean;
    VerifiedArticleCodeOnDestinationDefaultWarehouse?: boolean;
    userId?: number;
}

export interface IActualInspectionsSatus {
    InspectionId : number;
    ActualInspectionStatus : number;
    RemainingOperations?: number;
    AutomaticDocumentGeneration : boolean;
}

export interface IWarehouseInspection_Type {
    Id : number;
    Title?: string;
    Status : number;
    CreationDate : Date;
    CreationUserId : number;
    LastModificationDate : Date;
    LastModificationUserId : number;
    WorkedBy?: number;
    WorkStartDate?: Date;
    AutomaticDocumentGeneration : boolean;
}

export interface IWarehouseInspectionOperation_Type {
    Id : number;
    FKInspection : number;
    FKArticle : number;
    ArticleDescription?: string;
    ArticleCode?: string;
    MefCode?: string;
    EanCode?: string;
    FKSourceWarehouse : number;
    SourceWarehouse?: string;
    FKDestinationWarehouse : number;
    DestinationWarehouse?: string;
    JobOrderId?: number;
    JobOrderName?: string;
    CustomerId?: number;
    CustomerName?: string;
    RequestedAmount : number;
    MovementAmount?: number;
    ActualAmount?: number;
    Done?: boolean;
    OperationType : number;
    VerifiedArticleCode?: boolean;
    VerifiedSourceWarehouse?: boolean;
    VerifiedDestinationWarehouse?: boolean;
    UserId?: number;
    User?: string;
    OperationDate?: Date;
}

export interface IWarehouseInspectionOperationSource_Type {
    FKInspectionOperation : number;
    FKDocument : number;
    FKRef : number;
    RefAmount : number;
}

export interface IFullWarehouseInspectionWarehouseInspection {
    Id : number;
    Title : string;
    Status : number;
    CreationDate : Date;
    CreationUserId : number;
    CreationUser?: string;
    LastModificationDate : Date;
    LastModificationUserId : number;
    LastModificationUser?: string;
    WorkedBy?: number;
    WorkedByName?: string;
    WorkStartDate?: Date;
    AutomaticDocumentGeneration : boolean;
}

export interface IFullWarehouseInspectionWarehouseInspectionOperations {
    Id : number;
    FKInspection : number;
    FKArticle : number;
    ArticleDescription?: string;
    ArticleCode : string;
    EanCode?: string;
    MefCode?: string;
    FKSourceWarehouse : number;
    SourceWarehouse : string;
    SourceWarehouseStock?: number;
    FKDestinationWarehouse : number;
    DestinationWarehouse : string;
    DestinationWarehouseStock?: number;
    JobOrderId?: number;
    JobOrderName?: string;
    CustomerId?: number;
    CustomerName?: string;
    RequestedAmount : number;
    MovementAmount?: number;
    ActualAmount?: number;
    Done?: boolean;
    OperationType : number;
    VerifiedArticleCode?: boolean;
    VerifiedSourceWarehouse?: boolean;
    VerifiedDestinationWarehouse?: boolean;
    VerifiedArticleCodeOnDestinationDefaultWarehouse?: boolean;
    UserId?: number;
    User?: string;
    OperationDate?: Date;
    CreationDate : Date;
    CreationUserId : number;
    CreationUser?: string;
    LastModificationDate : Date;
    LastModificationUserId : number;
    LastModificationUser?: string;
}

export interface IFullWarehouseInspectionWarehouseInspectionOperationsSources {
    FKDocument : number;
    FKInspectionOperation : number;
    FKRef : number;
    RefAmount : number;
    DocumentDate : Date;
    DocumentNumber?: string;
    DocumentLabel?: string;
    FKRegister : number;
    EntityType : string;
    RegisterName?: string;
}

export interface IFullWarehouseInspectionDocumentsGenerated {
    FKInspection : number;
    DocumentsGenerated?: boolean;
}

export interface IFullWarehouseInspectionLastDocumentsGenerationError {
    FKInspection : number;
    LastDocumentsGenerationError : string;
}

export interface IFullWarehouseInspection {
    WarehouseInspection : IFullWarehouseInspectionWarehouseInspection[];
    WarehouseInspectionOperations : IFullWarehouseInspectionWarehouseInspectionOperations[];
    WarehouseInspectionOperationsSources : IFullWarehouseInspectionWarehouseInspectionOperationsSources[];
    DocumentsGenerated : IFullWarehouseInspectionDocumentsGenerated;
    LastDocumentsGenerationError : IFullWarehouseInspectionLastDocumentsGenerationError;
}

export interface IGetWarehouseInspectionsRequest {
    textFilter?: string;
    getTakenOver?: boolean;
    getNotTakenOver?: boolean;
    statusFilter?: number[];
    closedInspectionsFrom?: Date;
    closedInspectionsTo?: Date;
    skip?: number;
    count?: number;
}

export interface IWarehouseInspection {
    Id : number;
    Title : string;
    Status : number;
    NumberOfArticles : number;
    TotalNumberOfArticles : number;
    SourceWarehouses?: string;
    NumberOfSourceWarehouses : number;
    DestinationWarehouses?: string;
    NumberOfDestinationWarehouses : number;
    CreationDate : Date;
    CreationUserId : number;
    CreationUser?: string;
    LastModificationDate : Date;
    LastModificationUserId : number;
    LastModificationUser?: string;
    WorkedBy?: number;
    WorkedByName?: string;
    WorkStartDate?: Date;
    LastDocumentsGenerationError?: string;
    DocumentsGenerated?: boolean;
    Order : number;
    AutomaticDocumentGeneration : boolean;
}

export interface IArticleStockAmount {
    WarehouseId?: number;
    ArticleId?: number;
    StockAmount : number;
}

export interface IWarehouseInspectionsService extends IService {
    GetNextFakeId() : number;
    CloseWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void>;
    StopWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void>;
    GetWarehouseArticlesStockForWarehouseInspectionByIds(ids : number[] | null) : Promise<IWarehouseArticleStock[]>;
    GenerateWarehouseInspectionFromDocument(documentId : number | null, userId : number | null) : Promise<void>;
    GetWarehouseInspectionsWarehouses(inspectionId : number | null) : Promise<IWarehouseInspectionWarehouse[]>;
    ShowWarehouseInspectionEditor(fullInspection : IFullWarehouseInspection) : Promise<void>;
    DeleteWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void>;
    WorkWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void>;
    GetArticlesRequirementsFromDocument(documentId : number | null) : Promise<IArticleRequirementsInfo[]>;
    GetWarehouseInspectionsDestinationProtocols() : Promise<IWarehouseInspectionDestinationProtocol[]>;
    CreateOrUpdateWarehouseInspectionsDestinationProtocols(protocols : IWarehouseInspectionsDestinationProtocols_Type[] | null) : Promise<IWarehouseInspectionDestinationProtocol[]>;
    GetWarehouseInspectionsByIds(ids : number[] | null) : Promise<IWarehouseInspection[]>;
    GenerateDocuments(inspectionId : number) : Promise<void>;
    DeleteWarehouseInspectionRows(ids : number[] | null, userId : number | null) : Promise<void>;
    GetRequirementsFromDocumentsForWarehouseInspection(request : IGetRequirementsFromDocumentsForWarehouseInspectionRequest) : Promise<IArticleRequirementFromDocuments[]>;
    GetWarehouseArticlesStockForWarehouseInspection(request : IGetWarehouseArticlesStockForWarehouseInspectionRequest) : Promise<IWarehouseArticleStock[]>;
    GetWarehouseInspectionOperationsForInspection(inspectionId : number | null, sourceWarehouseIds : number[] | null, destinationWarehouseIds : number[] | null, onlyToBeDone : boolean | null) : Promise<IWarehouseInspectionOperationForInspection[]>;
    UpdateWarehouseInspectionsOrder(movedItemId : number | null, neighbourId : number | null, before : boolean | null, movedItemNewOrder : number | null) : Promise<void>;
    AddWarehouseInspectionOperation(request : IAddWarehouseInspectionOperationRequest) : Promise<void>;
    SaveWarehouseInspectionResults(request : ISaveWarehouseInspectionResultsRequest) : Promise<IActualInspectionsSatus[]>;
    GetFullWarehouseInspection(inspectionId : number | null) : Promise<IFullWarehouseInspection>;
    CreateOrUpdateWarehouseInspections(inspections : IWarehouseInspection_Type[] | null, inspectionsOperations : IWarehouseInspectionOperation_Type[] | null, inspectionIOperationsSources : IWarehouseInspectionOperationSource_Type[] | null, userId : number | null) : Promise<IFullWarehouseInspection>;
    GetWarehouseInspections(request : IGetWarehouseInspectionsRequest) : Promise<IWarehouseInspection[]>;
    GetDefaultWarehousesStocks(articles : number[] | null) : Promise<IArticleStockAmount[]>;
}

@Service(nameof<IWarehouseInspectionsService>())
class WarehouseInspectionsService implements IWarehouseInspectionsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    private nextFakeId : number = -1;

    public InitializeService() : void {
        new WarehouseInspectionsDestinationProtocolsSettingsManager();
    }

    public GetNextFakeId() : number {
        return this.nextFakeId--;
    }

    CloseWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "CloseWarehouseInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
                userId: userId,
            }
        });



        return result;
    }

    StopWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "StopWarehouseInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
                userId: userId,
            }
        });



        return result;
    }

    GetWarehouseArticlesStockForWarehouseInspectionByIds(ids : number[] | null) : Promise<IWarehouseArticleStock[]> {
        let result = this.ajaxService.Post<IWarehouseArticleStock[]>("Warehouse-api/WarehouseInspections", "GetWarehouseArticlesStockForWarehouseInspectionByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetWarehouseInspectionsWarehouses(inspectionId : number | null) : Promise<IWarehouseInspectionWarehouse[]> {
        let result = this.ajaxService.Post<IWarehouseInspectionWarehouse[]>("Warehouse-api/WarehouseInspections", "GetWarehouseInspectionsWarehouses", {
            background: true,
            methodData: {
                inspectionId: inspectionId
            }
        });



        return result;
    }

    GenerateWarehouseInspectionFromDocument(documentId : number | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "GenerateWarehouseInspectionFromDocument", {
            methodData: {
                documentId: documentId,
                userId: userId
            }
        });



        return result;
    }

    async ShowWarehouseInspectionEditor(fullInspection : IFullWarehouseInspection) : Promise<void> {
        if (fullInspection.WarehouseInspection.length !== 1)
            throw new Error(String.format("Unsupported number of inspections. Can edit only one inspection at time, but {0} inspections were found.", fullInspection.WarehouseInspection.length));

        try
        {
            fullInspection = await this.CreateOrUpdateWarehouseInspections(fullInspection.WarehouseInspection, fullInspection.WarehouseInspectionOperations, fullInspection.WarehouseInspectionOperationsSources, null);
        } catch (e)
        {
            return;
        }

        let dialog = new WarehouseInspectionEditorDialog(fullInspection);
        return dialog.show();
    }

    DeleteWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "DeleteWarehouseInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
                userId: userId,
            }
        });



        return result;
    }

    WorkWarehouseInspection(inspectionId : number | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "WorkWarehouseInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
                userId: userId,
            }
        });



        return result;
    }

    GetArticlesRequirementsFromDocument(documentId : number | null) : Promise<IArticleRequirementsInfo[]> {
        let result = this.ajaxService.Post<IArticleRequirementsInfo[]>("Warehouse-api/WarehouseInspections", "GetArticlesRequirementsFromDocument", {
            background: true,
            methodData: {
                documentId: documentId,
            }
        });



        return result;
    }

    GetWarehouseInspectionsDestinationProtocols() : Promise<IWarehouseInspectionDestinationProtocol[]> {
        let result = this.ajaxService.Post<IWarehouseInspectionDestinationProtocol[]>("Warehouse-api/WarehouseInspections", "GetWarehouseInspectionsDestinationProtocols", {
            background: true,
            methodData: {
            }
        });



        return result;
    }

    CreateOrUpdateWarehouseInspectionsDestinationProtocols(protocols : IWarehouseInspectionsDestinationProtocols_Type[] | null) : Promise<IWarehouseInspectionDestinationProtocol[]> {
        let result = this.ajaxService.Post<IWarehouseInspectionDestinationProtocol[]>("Warehouse-api/WarehouseInspections", "CreateOrUpdateWarehouseInspectionsDestinationProtocols", {
            background: true,
            methodData: {
                protocols: protocols,
            }
        });



        return result;
    }

    GetWarehouseInspectionsByIds(ids : number[] | null) : Promise<IWarehouseInspection[]> {
        let result = this.ajaxService.Post<IWarehouseInspection[]>("Warehouse-api/WarehouseInspections", "GetWarehouseInspectionsByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GenerateDocuments(inspectionId : number) : Promise<void> {
        return this.ajaxService.Post("Warehouse-api/WarehouseInspections", "GenerateDocuments", {
            background: false,
            methodData: {
                inspectionId: inspectionId
            }
        });
    }

    DeleteWarehouseInspectionRows(ids : number[] | null, userId : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "DeleteWarehouseInspectionRows", {
            background: true,
            methodData: {
                ids: ids,
                userId: userId,
            }
        });



        return result;
    }

    GetRequirementsFromDocumentsForWarehouseInspection(request : IGetRequirementsFromDocumentsForWarehouseInspectionRequest) : Promise<IArticleRequirementFromDocuments[]> {
        let result = this.ajaxService.Post<IArticleRequirementFromDocuments[]>("Warehouse-api/WarehouseInspections", "GetRequirementsFromDocumentsForWarehouseInspection", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetWarehouseArticlesStockForWarehouseInspection(request : IGetWarehouseArticlesStockForWarehouseInspectionRequest) : Promise<IWarehouseArticleStock[]> {
        let result = this.ajaxService.Post<IWarehouseArticleStock[]>("Warehouse-api/WarehouseInspections", "GetWarehouseArticlesStockForWarehouseInspection", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetWarehouseInspectionOperationsForInspection(inspectionId : number | null, sourceWarehouseIds : number[] | null, destinationWarehouseIds : number[] | null, onlyToBeDone : boolean | null) : Promise<IWarehouseInspectionOperationForInspection[]> {
        let result = this.ajaxService.Post<IWarehouseInspectionOperationForInspection[]>("Warehouse-api/WarehouseInspections", "GetWarehouseInspectionOperationsForInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
                sourceWarehouseIds: sourceWarehouseIds,
                destinationWarehouseIds: destinationWarehouseIds,
                onlyToBeDone: onlyToBeDone,
            }
        });



        return result;
    }

    UpdateWarehouseInspectionsOrder(movedItemId : number | null, neighbourId : number | null, before : boolean | null, movedItemNewOrder : number | null) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "UpdateWarehouseInspectionsOrder", {
            background: true,
            methodData: {
                movedItemId: movedItemId,
                neighbourId: neighbourId,
                before: before,
                movedItemNewOrder: movedItemNewOrder,
            }
        });



        return result;
    }

    AddWarehouseInspectionOperation(request : IAddWarehouseInspectionOperationRequest) : Promise<void> {
        let result = this.ajaxService.Post<void>("Warehouse-api/WarehouseInspections", "AddWarehouseInspectionOperation", {
            background: true,
            methodData: request
        });



        return result;
    }

    SaveWarehouseInspectionResults(request : ISaveWarehouseInspectionResultsRequest) : Promise<IActualInspectionsSatus[]> {
        let result = this.ajaxService.Post<IActualInspectionsSatus[]>("Warehouse-api/WarehouseInspections", "SaveWarehouseInspectionResults", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetFullWarehouseInspection(inspectionId : number | null) : Promise<IFullWarehouseInspection> {
        let result = this.ajaxService.Post<IFullWarehouseInspection>("Warehouse-api/WarehouseInspections", "GetFullWarehouseInspection", {
            background: true,
            methodData: {
                inspectionId: inspectionId,
            }
        });



        return result;
    }

    CreateOrUpdateWarehouseInspections(inspections : IWarehouseInspection_Type[] | null, inspectionsOperations : IWarehouseInspectionOperation_Type[] | null, inspectionIOperationsSources : IWarehouseInspectionOperationSource_Type[] | null, userId : number | null) : Promise<IFullWarehouseInspection> {
        let result = this.ajaxService.Post<IFullWarehouseInspection>("Warehouse-api/WarehouseInspections", "CreateOrUpdateWarehouseInspections", {
            background: true,
            methodData: {
                inspections: inspections,
                inspectionsOperations: inspectionsOperations,
                inspectionIOperationsSources: inspectionIOperationsSources,
                userId: userId,
            }
        });



        return result;
    }

    GetWarehouseInspections(request : IGetWarehouseInspectionsRequest) : Promise<IWarehouseInspection[]> {
        let result = this.ajaxService.Post<IWarehouseInspection[]>("Warehouse-api/WarehouseInspections", "GetWarehouseInspections", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetDefaultWarehousesStocks(articles: number[] | null): Promise<IArticleStockAmount[]> {
        let result = this.ajaxService.Post<IArticleStockAmount[]>("Warehouse-api/WarehouseInspections", "GetDefaultWarehousesStocks", {
            background: true,
            methodData: {
        		articles: articles,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IWarehouseInspectionsService>());
}
