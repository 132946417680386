import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { AttachmentsManager } from "../../../FileRepository/FileRepository/attachments/AttachmentsManager";
import { Document } from "./Document";
import { Attachment } from "../../../ProlifeSdk/prolifesdk/blog/Attachment";

export function DocumentAttachmentsTemplateReadOnly() {
    let doc: Document;
    let att: AttachmentsManager;
    let attFile: Attachment;

    return  <ko-bind data-bind={{ with : doc.AttachmentsManager, as: 'att' }}>
                <div class="row margin-top-10 attachments-wrapper" data-bind={{ visible: att.attachments().length > 0 }}>
                    <div class="col-md-12">
                        <div class="portlet box blue">
                            <div class="portlet-title">
                                <div class="caption">{TextResources.Invoices.DocumentAttachments}</div>
                            </div>
                            <div class="portlet-body">
                
                                <div class="listview" data-bind={{ foreach: { data: att.attachments, as: 'attFile' }}}>
                                    <a href="#" class="list" data-bind={{ click : attFile.download }}>
                                        <div class="list-content">
                                            <div class="icon" data-bind={{ css: attFile.previewClasses, attr : { style : attFile.previewStyle } }}></div>
                                            <div class="data">
                                                <span class="list-title" data-bind={{ text : attFile.fileName }}></span>
                                                <span class="list-remark" data-bind={`visible: attFile.fileVersion, text: '${TextResources.Invoices.DocumentAttachmentsVersion} ' + attFile.fileVersion()`}></span>
                                            </div>
                                            <div class="include-in-electronic-invoice-flag" 
                                                    data-bind={{ visible: attFile.showIncludeInElectronicInvoiceFlag() && doc.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(), tooltip: { placement: 'left' } }} 
                                                    title={TextResources.Invoices.DocumentAttachmentsIncludeInElectronicInvoicing}>
                                                <span>
                                                    <i class="fa fa-check"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                
                            </div>
                        </div>
                    </div>
                </div>
            </ko-bind>;
}

export function DocumentAttachmentsTemplateNonReadOnly() {
    let doc: Document;
    let att: AttachmentsManager;
    let attFile: Attachment;

    return  <ko-bind data-bind={{ with: doc.AttachmentsManager, as: 'att' }}>
                <div class="row margin-top-10 attachments-wrapper">
                    <div class="col-md-12">
                        <div class="portlet box blue">
                            <div class="portlet-title">
                                <div class="caption">{TextResources.Invoices.DocumentAttachments}</div>
                                <div class="actions">
                                    <a href="#" class="btn default btn-sm" data-bind={{ click: att.attachFromRepository }}>
                                        <i class="fa fa-plus"></i>
                                        {TextResources.Invoices.DocumentAttachmentsAttach}
                                    </a>
                                    <a href="#" class="btn default btn-sm" data-bind={{ visible : att.selectedAttachments().length > 0, click : att.removeSelectedAttachments }}>
                                        <i class="fa fa-trash-o"></i>
                                        {TextResources.Invoices.DocumentAttachmentsDelete}
                                    </a>
                                    <a href="#" class="btn default btn-sm" data-bind={{ click : att.downloadSelectedAttachment, visible : att.selectedAttachments().length == 1 }}>
                                        <i class="fa fa-download"></i>
                                        {TextResources.Invoices.DocumentAttachmentsDownload}
                                    </a>
                                </div>
                            </div>
                            <div class="portlet-body" data-bind={{ fileDrop: att.uploadAndAttach }}>
                                <h4 class="text-center" data-bind={{ visible: att.attachments().length == 0 }}>{TextResources.Invoices.DocumentAttachmentsNoAttachment}</h4>
                                <div class="listview" data-bind={{ foreach: { data: att.attachments, as: 'attFile' }}}>
                
                                    <a href="#" class="list" data-bind={{ css : { selected : attFile.selected }, click : attFile.switchSelection }}>
                                        <div class="list-content">
                                            <div class="icon" data-bind={{ css: attFile.previewClasses, attr : { style : attFile.previewStyle } }}></div>
                                            <div class="data">
                                                <span class="list-title" data-bind={{ text : attFile.fileName }}></span>
                                                <span class="list-remark" data-bind={`visible: fileVersion, text: '${TextResources.Invoices.DocumentAttachmentsVersion} ' + fileVersion()`}></span>
                                            </div>
                                            <div 
                                                class="include-in-electronic-invoice-flag" 
                                                data-bind={{ visible: attFile.showIncludeInElectronicInvoiceFlag() && doc.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(), tooltip: { placement: 'left' } }} 
                                                title={TextResources.Invoices.DocumentAttachmentsIncludeInElectronicInvoicing}>
                                                <label>
                                                    <div class="checker">
                                                        <span data-bind={{ css: { checked: attFile.includeInElectronicInvoice } }}>
                                                            <input type="checkbox" data-bind={{ click: attFile.switchIncludeInElectronicInvoiceFlag, clickBubble: false }}></input>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ko-bind>;
}

export function DocumentAttachmentsReadOnly() {
    let $data: any;

    return  <ko-bind data-bind={{ let: { doc: $data }}}>
                <DocumentAttachmentsTemplateReadOnly></DocumentAttachmentsTemplateReadOnly>
            </ko-bind>;
}

export function DocumentAttachmentsNonReadOnly() {
    let $data: any;

    return  <ko-bind data-bind={{ let: { doc: $data }}}>
                <DocumentAttachmentsTemplateNonReadOnly></DocumentAttachmentsTemplateNonReadOnly>
            </ko-bind>;
}