import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 11/04/13
 * Time: 17.43
 * To change this template use File | Settings | File Templates.
 */

export class SlideUp {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : any {
        (<any>$(element)).hammer({ preventDefault: true })
            .on('swipeup', () => { valueAccessor()(true); })
            .on('swipedown', () => { valueAccessor()(false); });
    }
}

ko.bindingHandlers["slideUp"] = new SlideUp();