import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService, IEntityDescriptor } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";

export class EntitiesDropDownList implements IDropDownList {

    private entitiesService : IEntityProviderService;
    private entitiesDescriptors : IEntityDescriptor[];
    public selectedId : ko.Observable<string> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private filter? : (e : IEntityDescriptor, index: number, array: IEntityDescriptor[]) => boolean) {
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.entitiesDescriptors = this.entitiesService.getEntitiesDescriptors();
    }

    public getSelectedKey() : any {
        return this.selectedId();
    }

    public search(query : any)
    {
        var matches = this.entitiesDescriptors
            .filter((ed : IEntityDescriptor) => {
                return (ed.EntityName || "").toUpperCase().indexOf((query.term || "").trim().toUpperCase()) > -1;
            });

        if(this.filter)
            matches = matches.filter(this.filter);

        query.callback({
            results: matches
                .map((ed : IEntityDescriptor) => {
                    return {
                        id: ed.EntityCode,
                        text: ed.EntityName
                    };
                }).sort((a, b) => {
                    if(a.text < b.text)
                        return -1;
                    if(a.text > b.text)
                        return 1;
                    return 0;
                })
        });
    }

    public getSelected(element, callback)
    {
        var selected = this.entitiesDescriptors.filter((ed : IEntityDescriptor) => { return ed.EntityCode == this.selectedId(); });

        if(selected && selected.length > 0) {
            callback({
                id: selected[0].EntityCode,
                text: selected[0].EntityName
            });
        }
    }
}