import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ReferencesMapNode } from "./ReferencesMapNode";
import { ReferencesMapConnector } from "./ReferencesMapConnector";
import { IDocumentsService } from "../../DocumentsService";
import { INodeKey } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IReferenceForMap, INodeDetails } from "../../../ProlifeSdk/interfaces/invoice/IReferencesMap";

export class ReferencesMapChartModel
{
    private documentsService : IDocumentsService;

    Nodes : ReferencesMapNode[] = [];
    AnalyzedDocId : number;
    AnalyzedDocType : string;

    constructor(private serviceLocator : IServiceLocator,
                private system : any,
                private simpleEntitiesSourceClickHandler : (entityType : string) => {},
                private sourceClickHandler : (entityId : number, entityType : string) => {},
                private destinationClickHandler : (entityId : number, entityType : string) => {})
    {
        this.documentsService = <IDocumentsService>serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
    }

    public RefreshData(references : IReferenceForMap[], analyzedDocId : number, analyzedDocType : string)
    {
        this.AnalyzedDocId = analyzedDocId;
        this.AnalyzedDocType = analyzedDocType;
        this.Nodes = [];
        references.forEach((r : IReferenceForMap) => {
            var sourceMatches = this.Nodes.filter((n : ReferencesMapNode) => { return n.Identifier == ReferencesMapNode.GetIdentifierFromReferenceSource(r) });
            var sourceNode = sourceMatches.length > 0 ? sourceMatches[0] :
                new ReferencesMapNode(this.serviceLocator, r.SourceDocId, r.SourceEntityType, references,
                    (r.SourceDocId == this.AnalyzedDocId && r.SourceEntityType == this.AnalyzedDocType),
                    this.IsEntitySourceForAnalyzed(r.SourceId, r.SourceEntityType, references),
                    this.IsEntityDestForAnalyzed(r.SourceId, r.SourceEntityType, references),
                    this.simpleEntitiesSourceClickHandler,
                    this.sourceClickHandler,
                    this.destinationClickHandler);
            if(sourceMatches.length == 0)
                this.Nodes.push(sourceNode);

            var destMatches = this.Nodes.filter((n : ReferencesMapNode) => { return n.Identifier == ReferencesMapNode.GetIdentifierFromReferenceDest(r) });
            var destNode = destMatches.length > 0 ? destMatches[0] :
                new ReferencesMapNode(this.serviceLocator, r.DestDocId, r.DestEntityType, references,
                    (r.DestDocId == this.AnalyzedDocId && r.DestEntityType == this.AnalyzedDocType),
                    this.IsEntitySourceForAnalyzed(r.DestId, r.DestEntityType, references),
                    this.IsEntityDestForAnalyzed(r.DestId, r.DestEntityType, references),
                    this.simpleEntitiesSourceClickHandler,
                    this.sourceClickHandler,
                    this.destinationClickHandler);
            if(destMatches.length == 0)
                this.Nodes.push(destNode);
        });

        var nodesKeys : INodeKey[] = this.Nodes.filter((n : ReferencesMapNode) => { return !n.IsSimpleEntitiesSource; }).map((n : ReferencesMapNode) => { return { NodeId : n.EntityId, NodeType : n.EntityType } });
        this.documentsService.GetReferencesMapNodesDetails(nodesKeys).then((nodes : INodeDetails[]) => {
            this.Nodes.forEach((n : ReferencesMapNode) => {n.LoadDetails(nodes); });
            this.PlotOnRenderer();
        });
    }

    private IsEntitySourceForAnalyzed(entityId : number, entityType : string, allReferences : IReferenceForMap[])
    {
        var matches: IReferenceForMap[] = allReferences.filter((r : IReferenceForMap) => { return r.SourceId == entityId && r.SourceEntityType == entityType });

        var result : boolean = false;
        matches.forEach((r : IReferenceForMap) => {
            result = result || (r.DestDocId == this.AnalyzedDocId && r.DestEntityType == this.AnalyzedDocType) || this.IsEntitySourceForAnalyzed(r.DestId, r.DestEntityType, allReferences);
        });
        return result;
    }

    private IsEntityDestForAnalyzed(entityId : number, entityType : string, allReferences : IReferenceForMap[])
    {
        var matches: IReferenceForMap[] = allReferences.filter((r : IReferenceForMap) => { return r.DestId == entityId && r.DestEntityType == entityType });

        var result : boolean = false;
        matches.forEach((r : IReferenceForMap) => {
            result = result || (r.SourceDocId == this.AnalyzedDocId && r.SourceEntityType == this.AnalyzedDocType) || this.IsEntityDestForAnalyzed(r.SourceId, r.SourceEntityType, allReferences);
        });
        return result;
    }

    private PlotOnRenderer()
    {
        //Rimuovo nodi e connettori attuali
        this.system.eachEdge((e) => { this.system.pruneEdge(e); });
        this.system.eachNode((n) => { this.system.pruneNode(n); });

        //Aggiungo i nuovi elementi
        this.Nodes.forEach((n : ReferencesMapNode) => { this.system.addNode(n.Identifier, n); });
        this.Nodes.forEach((n : ReferencesMapNode) => {
            n.SourceConnectors.forEach((c : ReferencesMapConnector) => {
                this.system.addEdge(this.system.getNode(c.SourceNodeIdentifier), this.system.getNode(c.DestNodeIdentifier), c);
            });
        });
    }
}
