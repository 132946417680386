import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { SuppliersDataSource } from "../../../../DataSources/SuppliersDataSource";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { BaseDocumentForWizard } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/BaseDocumentForWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import {
    ISupplierOrderRowForDocuments,
    ISupplierOrderForWizard,
    ISupplierOrderRow,
    ISupplierOrderRowStatus,
} from "../../../../ProlifeSdk/interfaces/warehouse/ISupplierOrder";
import { IBaseDocumentRowForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IOrdersService } from "../../../../ProlifeSdk/interfaces/warehouse/IOrdersService";
import {
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRelatedWorkflows,
} from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DocumentDataSourceDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/data-sources/DocumentDataSourceDataWizardStep";

export class WarehouseSupplierOrderDataSource
    extends DocumentDataSourceDataWizardStep<ISupplierOrderRowForDocuments, SupplierOrderRow>
    implements IDataSourceListener
{
    public JobOrderName: ko.Observable<string> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public ForAllSuppliers: ko.Observable<boolean> = ko.observable(false);
    public SupplierOrders: ko.ObservableArray<SupplierOrder> = ko.observableArray();
    public Total: ko.Computed<number>;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    @LazyImport(nameof<IOrdersService>())
    private ordersService: IOrdersService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();

    public SelectAllRows: ko.Computed<boolean>;
    public WarningMessages: ko.Observable<string> = ko.observable();
    public ExpandedColumn: ko.Observable<number> = ko.observable(1);
    public SupplierId: ko.Observable<number> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();

    public SuppliersDataSource: SuppliersDataSource;
    public JobOrdersDataSource: JobOrdersDataSource;

    public CanFilter: ko.Observable<boolean> = ko.observable(false);

    private DisableDocumentsReload = false;
    private referenceDate: Date;
    previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Warehouse.SupplierOrders);

        this.installWatches();

        const actualResource = this.humanResourcesSettingsManager.getHumanResourceByUserId(
            this.userInfoService.getIdUser()
        );

        this.SuppliersDataSource = new SuppliersDataSource();

        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setResourceId(!actualResource ? null : actualResource.Resource.Id);
        this.JobOrdersDataSource.setUserId(this.userInfoService.getIdUser());
        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.JobOrdersDataSource.setViewFilters(
            true,
            true,
            this.authorizationService.isAuthorized("Documents_ViewAllJobOrders")
        );

        this.Total = ko.computed(() => {
            let result = 0;
            this.Rows().forEach((r: ISupplierOrderRowForDocuments) => {
                result += r.TotaleRiga || 0;
            });
            return result;
        });

        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.WarehouseLoadEntityTypeCode,
            ProlifeSdk.PassiveInvoiceEntityTypeCode
        );

        this.SelectAllRows = ko.computed({
            read: () => {
                const documents = this.SelectedDocuments();
                let rowsCount = 0;
                let selectedRowsCount = 0;

                documents.forEach((e: SupplierOrder) => {
                    const allRows = e.Rows();
                    const selectedRows = allRows.filter((r: SupplierOrderRow) => {
                        return r.Selected();
                    });

                    rowsCount += allRows.length;
                    selectedRowsCount += selectedRows.length;
                });

                return rowsCount === selectedRowsCount ? true : selectedRowsCount === 0 ? false : undefined;
            },
            write: (selected: boolean) => {
                this.SelectedDocuments().forEach((e: SupplierOrder) => {
                    e.Rows().forEach((r: SupplierOrderRow) => {
                        r.Selected(selected);
                    });
                });
            },
        });

        this.ForAllSuppliers.subscribe((val: boolean) => {
            this.CanFilter(val);

            if (!val) {
                this.SupplierId(this.initializationInfo.CustomerId);
                this.JobOrderId(this.initializationInfo.JobOrderId);
            }
        });
    }

    public CanShow(): boolean {
        return (
            this.authorizationService.isAuthorized("Warehouse_SupplierOrder") ||
            this.authorizationService.isAuthorized("Warehouse_ViewSupplierOrder")
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: null,
                    Amount: r.Quantita,
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: r.NetUnitPriceInDocumentCurrency,
                    NetUnitPrice: r.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency),
                    Order: 0,
                    TotalPriceInDocumentCurrency: r.TotalPriceInDocumentCurrency,
                    TotalPrice: r.TotalPriceInDocumentCurrency.ToEuro(documentCurrency),
                    UnitPriceInDocumentCurrency: r.PriceInDocumentCurrency,
                    UnitPrice: r.PriceInDocumentCurrency.ToEuro(documentCurrency),
                    Description: r.Descrizione,
                    Discounts: r.Discounts,
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: this.getRelatedWorkflows(r),
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r: ISupplierOrderRowForDocuments): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        for (const sourceRow of r.Rows.filter((r) => r.Quantita != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType: ProlifeSdk.SupplierOrderEntityTypeCode,
                SourceEntityKeyId: sourceRow.IdRigaOrdineFornitore,
                DestEntityType: this.initializationInfo.DocTypeCode,
                DestEntityKeyId: 0,
                Amount: sourceRow.Quantita,
                UnitPrice: sourceRow.Importo,
                Discounts: sourceRow.Discounts,
                NetUnitPrice: sourceRow.NetUnitPrice,
                NetPrice: sourceRow.TotaleRiga,
            });
        }
        return refs;
    }

    protected getRelatedWorkflows(source: ISupplierOrderRowForDocuments): IDocumentBuilderDocumentRelatedWorkflows[] {
        const result: IDocumentBuilderDocumentRelatedWorkflows[] = [];

        for (const row of source.Rows) {
            for (const rw of row.RelatedWorkflows || []) {
                result.push({
                    Id: null,
                    WorkflowId: rw.WorkflowId,
                    DocumentId: null,
                    DocumentType: this.initializationInfo.DocTypeCode,
                    DocumentNumber: null,
                    DocumentDate: null,
                    DocumentProtocolId: null,
                    RowId: 0,
                    RowDescription: null,
                    RowAmount: source.Quantita,
                    RowUoM: source.Udm,
                    RowPrice: source.NetUnitPrice,
                    WorkflowAmount: rw.WorkflowAmount,
                    ValidityStartDate: null,
                    WorkflowName: rw.WorkflowName,
                });
            }
        }

        return result;
    }

    protected override onImportSelectedRows(someDocumentsCannotBeImported: boolean) {}

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        this.DisableDocumentsReload = true;
        await super.Initialize(initializationInfo);
        this.SelectedDocuments([]);
        this.referenceDate = initializationInfo.DocumentDate || new Date();

        this.SupplierId(this.initializationInfo.CustomerId);
        this.JobOrderId(this.initializationInfo.JobOrderId);
        this.DateFrom(moment(this.referenceDate).startOf("year").toDate());
        this.DateTo(this.referenceDate);

        await this.loadCustomerName();
        this.DisableDocumentsReload = false;
        await this.loadSupplierOrders();
    }

    private installWatches() {
        this.DisableDocumentsReload = true;
        this.ForAllSuppliers.subscribe(this.loadSupplierOrders.bind(this));
        this.DateFrom.subscribe(this.loadSupplierOrders.bind(this));
        this.DateTo.subscribe(() => {
            if (this.DateTo() > this.referenceDate) {
                this.WarningMessages(
                    String.format(
                        ProlifeSdk.TextResources.Warehouse.WarningTheSelectedDateIsBiggerThanTheDocumentOne,
                        moment(this.referenceDate).format("L")
                    )
                );
            } else {
                this.WarningMessages(undefined);
            }
            this.loadSupplierOrders();
        });
        this.SupplierId.subscribe(this.loadSupplierOrders.bind(this));
        this.JobOrderId.subscribe(this.loadSupplierOrders.bind(this));
        this.DisableDocumentsReload = false;
    }

    private async loadCustomerName() {
        try {
            const customer = await this.customersService.getCustomerById(this.initializationInfo.CustomerId);
            this.CustomerName(customer.FormattedContactName);
        } catch {
            this.CustomerName(ProlifeSdk.TextResources.Warehouse.SupplierNotFound);
        }
    }

    private async loadSupplierOrders() {
        if (this.DisableDocumentsReload) return;

        const orders = await this.ordersService.GetSupplierOrdersForWizard(
            "",
            true,
            this.JobOrderId(),
            this.ForAllSuppliers(),
            this.SupplierId(),
            this.DateFrom(),
            this.DateTo(),
            0,
            1000000
        );
        this.supplierOrdersLoaded(orders);
    }

    private supplierOrdersLoaded(supplierOrders: ISupplierOrderForWizard[]) {
        this.SelectedDocuments([]);
        this.SupplierOrders(supplierOrders.map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(supplierOrder: ISupplierOrderForWizard): SupplierOrder {
        return new SupplierOrder(supplierOrder, this, this.initializationInfo.DocumentOriginatingRows);
    }

    public removeRow(row: ISupplierOrderRowForDocuments) {
        this.Rows.remove(row);
    }

    expandColumn(colId: number): void {
        this.ExpandedColumn(colId);
    }

    public async GetDocumentsToImportInfo(): Promise<IDocumentToImportInfo[]> {
        const docsToImport: SupplierOrder[] = [];
        this.Rows().forEach((r: ISupplierOrderRowForDocuments) => {
            r.Rows.forEach((dr: ISupplierOrderRow) => {
                const matches: SupplierOrder[] = this.SupplierOrders().filter((e: SupplierOrder) => {
                    return e.supplierOrder.Id == dr.FkOrdineFornitore;
                });

                if (matches.length == 0 || docsToImport.indexOf(matches[0]) > -1) return;

                docsToImport.push(matches[0]);
            });
        });

        return this.SelectedDocuments().map((d: SupplierOrder) => {
            return {
                Id: d.supplierOrder.Id,
                Name: String.format(
                    ProlifeSdk.TextResources.Warehouse.SupplierOrderNumberOf,
                    d.Number(),
                    moment(d.Date()).format("L")
                ),
                EntityType: ProlifeSdk.SupplierOrderEntityTypeCode,
            };
        });
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (!model) {
            this.JobOrdersDataSource.setCustomerIds(null);
            return;
        }

        this.JobOrdersDataSource.setCustomerIds(model.id as number);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        this.JobOrdersDataSource.setCustomerIds(null);
    }

    render() {
        let step: WarehouseSupplierOrderDataSource;
        let sor: SupplierOrder;
        let row: SupplierOrderRow;
        let outRow: ISupplierOrderRowForDocuments;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 0px 15px;">
                <div class="row wizard-header">
                    <div class="col-md-7 wizard-header-block">
                        <div class="row">
                            <label class="col-md-1 control-label">
                                {TextResources.Invoices.DocumentWizardSupplier}
                            </label>
                            <ko-bind data-bind={{ ifnot: step.CanFilter }}>
                                <div
                                    class="col-md-5 form-control-static text-ellipsis"
                                    data-bind={{
                                        text: step.CustomerName,
                                        attr: { title: step.CustomerName },
                                        tooltip: { placement: "right" },
                                    }}></div>
                            </ko-bind>
                            <ko-bind data-bind={{ if: step.CanFilter }}>
                                <div class="col-md-5 select2-wrapper">
                                    <select2
                                        allowClear={true}
                                        value={() => "SupplierId"}
                                        placeholder={TextResources.Invoices.DocumentWizardSelectPlaceholder}
                                        dataSource={() => "SuppliersDataSource"}
                                        listener={() => "$data"}></select2>
                                </div>
                                <label class="col-md-1 control-label">
                                    {TextResources.Invoices.DocumentWizardJobOrder}
                                </label>
                                <div class="col-md-5 select2-wrapper">
                                    <select2
                                        allowClear={true}
                                        value={() => "JobOrderId"}
                                        placeholder={TextResources.Invoices.DocumentWizardSelectPlaceholder}
                                        dataSource={() => "JobOrdersDataSource"}></select2>
                                </div>
                            </ko-bind>
                        </div>
                        <div class="row">
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                            <div class="col-md-3">
                                <div
                                    class="input-group date form_datetime"
                                    data-bind={{ nullableDatePicker: step.DateFrom }}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                            <div class="col-md-3">
                                <div
                                    class="input-group date form_datetime"
                                    data-bind={{ nullableDatePicker: step.DateTo }}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <ko-bind data-bind={{ if: step.DocumentCurrenciesEnabled }}>
                                <div
                                    class="col-md-4"
                                    data-bind={{ with: step.initializationInfo.DocumentCurrenciesInfo }}>
                                    <document-currencies-popover
                                        readonly-document-currency={() => "LockDocumentCurrencyChanges"}
                                        readonly-exchange-value={() => "LockExchangeValueChanges"}
                                        readonly-exchange-valueForVat={() => "LockExchangeValueForVatChanges"}
                                        document-currencies={() => "DocumentCurrencies"}
                                        button-css-classes="btn btn-sm blue"
                                        popover-css-classes="currencies-manager-for-modal"
                                        button-text={
                                            TextResources.Invoices.CurrenciesLabel
                                        }></document-currencies-popover>
                                </div>
                            </ko-bind>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-control-static">
                                <input type="checkbox" data-bind={{ checkbox: step.ForAllSuppliers }} />
                                {TextResources.Invoices.DocumentWizardShowOrdersFromAllSuppliers}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 wizard-header-block">
                        <div
                            class="alert alert-danger"
                            data-bind={{ visible: !!step.WarningMessages(), text: step.WarningMessages }}></div>
                    </div>
                </div>

                <div class="row wizard-body">
                    <div
                        class="wizard-body-column first-col"
                        data-bind={{
                            css: {
                                "expanded-column": step.ExpandedColumn() == 1,
                                "collapsed-column": step.ExpandedColumn() != 1,
                            },
                            click: step.expandColumn.bind(step, 1),
                        }}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-center closure-state-col">
                                            <i
                                                class="fa fa-lock"
                                                title={TextResources.Invoices.DocumentWizardClosureStatus}></i>
                                        </th>
                                        <th class="doc-number-col">{TextResources.Invoices.DocumentWizardNumber}</th>
                                        <th class="doc-date-col">{TextResources.Invoices.DocumentWizardDate}</th>
                                        <th class="customer-col">{TextResources.Invoices.DocumentWizardSupplier}</th>
                                        <th class="">{TextResources.Invoices.DocumentWizardJobOrder}</th>
                                        <th class="amount-col text-right">
                                            {TextResources.Invoices.DocumentWizardTotalPrice}
                                        </th>
                                        <th class="text-right">{TextResources.Invoices.CurrencyLabel}</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                        <div class="wizard-data-source-table-body">
                            <div data-bind={{ slimScroll: "auto" }}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                        <ko-bind data-bind={{ if: step.SupplierOrders().length == 0 }}>
                                            <tr>
                                                <td colSpan={6} style="text-align : center">
                                                    {TextResources.Invoices.DocumentWizardNoOrder}
                                                </td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{ foreach: { data: step.SupplierOrders, as: "sor" } }}>
                                            <tr
                                                data-bind={{
                                                    click: sor.SwitchSelection,
                                                    css: { active: sor.IsSelected },
                                                }}>
                                                <td
                                                    class="text-center closure-state-col"
                                                    data-bind={{
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}>
                                                    <ko-bind data-bind={{ if: sor.supplierOrder.Accepted }}>
                                                        <div
                                                            class="label label-sm"
                                                            data-bind={{
                                                                css: {
                                                                    "label-warning": sor.ClosureStatus() == 1,
                                                                    "label-danger": sor.ClosureStatus() == 0,
                                                                    "label-success": sor.ClosureStatus() == 2,
                                                                },
                                                                click: sor.openDocument.bind(sor),
                                                                style: { cursor: "pointer" },
                                                            }}>
                                                            <i
                                                                class="fa fa-file-text"
                                                                data-bind={`attr : { title :  sor.ClosureStatus() == 1 ? '${TextResources.Invoices.DocumentWizardPartiallyClosed}' : (sor.ClosureStatus() == 0 ? '${TextResources.Invoices.DocumentWizardClosed}' : '${TextResources.Invoices.DocumentWizardOpened}') }`}></i>
                                                        </div>
                                                    </ko-bind>
                                                    <ko-bind data-bind={{ ifnot: sor.supplierOrder.Accepted }}>
                                                        <i
                                                            class="fa fa-clock-o"
                                                            title={TextResources.Invoices.DocumentWizardNotAccepted}
                                                            data-bind={{
                                                                click: sor.openDocument.bind(sor),
                                                                style: { cursor: "pointer" },
                                                            }}></i>
                                                    </ko-bind>
                                                </td>
                                                <td
                                                    class="doc-number-col"
                                                    data-bind={{
                                                        text: Number,
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}></td>
                                                <td
                                                    class="doc-date-col"
                                                    data-bind={{
                                                        dateText: Date,
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}></td>
                                                <td
                                                    class="customer-col"
                                                    data-bind={{
                                                        text: sor.SupplierName,
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}></td>
                                                <td
                                                    class=""
                                                    data-bind={{
                                                        text: sor.OrderName,
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}></td>
                                                <td
                                                    class="amount-col text-right"
                                                    data-bind={{
                                                        moneyText: sor.Total,
                                                        currencySymbol: sor.CurrencySymbol,
                                                        documentCurrency: sor.DocumentCurrency,
                                                        style: {
                                                            background: !sor.supplierOrder.Accepted
                                                                ? "rgb(229, 229, 229)"
                                                                : "",
                                                        },
                                                    }}></td>
                                                <td class="text-right" data-bind={{ text: sor.CurrencyCode }}></td>
                                            </tr>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        class="wizard-body-column second-col"
                        data-bind={{
                            css: {
                                "expanded-column": step.ExpandedColumn() == 2,
                                "collapsed-column": step.ExpandedColumn() != 2,
                            },
                            style: { left: step.ExpandedColumn() == 3 || step.ExpandedColumn() == 2 ? "25%" : "50%" },
                            click: step.expandColumn.bind(step, 2),
                        }}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                    <tr>
                                        <th
                                            class="text-center selection-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            <input type="checkbox" data-bind={{ checkbox: step.SelectAllRows }} />
                                        </th>
                                        <th
                                            class="text-center closure-state-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            <i
                                                class="fa fa-thumb-tack"
                                                title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                        </th>
                                        <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                        <th class="text-center closed-amount-col">
                                            {TextResources.Invoices.DocumentWizardImportedAmount}
                                        </th>
                                        <th class="text-right amount-col">
                                            {TextResources.Invoices.DocumentWizardAmountToImport}
                                        </th>
                                        <th
                                            class="unit-of-measure-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            {TextResources.Invoices.DocumentWizardUoM}
                                        </th>
                                        <th
                                            class="text-right unit-price-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            {TextResources.Invoices.DocumentWizardUnitPriceShort}
                                        </th>
                                        <th
                                            class="discount-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            {TextResources.Invoices.DocumentWizardDiscount}
                                        </th>
                                        <th
                                            class="text-right unit-price-col"
                                            data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 2 } }}>
                                            {TextResources.Invoices.DocumentWizardNetUnitPrice}
                                        </th>
                                        <th class="text-right total-price-col">
                                            {TextResources.Invoices.DocumentWizardTotalPrice}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                        <div class="wizard-data-source-table-body">
                            <div data-bind={{ slimScroll: "auto" }}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                        <ko-bind data-bind={{ if: step.SelectedDocuments().length == 0 }}>
                                            <tr>
                                                <td colSpan={10} style="text-align : center">
                                                    {TextResources.Invoices.DocumentWizardNoRowAvailable}
                                                </td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{ foreach: { data: step.SelectedDocuments, as: "sor" } }}>
                                            <ko-bind data-bind={{ foreach: { data: sor.Rows, as: "row" } }}>
                                                <tr data-bind={{ css: { active: row.Selected } }}>
                                                    <td
                                                        class="text-center selection-col"
                                                        data-bind={{
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}>
                                                        <input type="checkbox" data-bind={{ checkbox: row.Selected }} />
                                                    </td>
                                                    <td
                                                        class="text-center closure-state-col"
                                                        data-bind={{
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}>
                                                        <ko-bind data-bind={{ if: row.AlreadyImportedInThisDocument }}>
                                                            <i
                                                                class="fa fa-thumb-tack"
                                                                title={
                                                                    TextResources.Invoices
                                                                        .DocumentWizardInDestinationDocument
                                                                }></i>
                                                        </ko-bind>
                                                    </td>
                                                    <td>
                                                        <span data-bind={{ text: row.Description }}></span>
                                                    </td>
                                                    <ko-bind data-bind={{ ifnot: row.ManuallyClosed }}>
                                                        <td
                                                            class="text-right closed-amount-cols"
                                                            data-bind={{ numberText: row.ClosedAmount }}></td>
                                                    </ko-bind>
                                                    <ko-bind data-bind={{ if: row.ManuallyClosed }}>
                                                        <td class="text-center closed-amount-cols">
                                                            <i
                                                                class="fa fa-lock"
                                                                title={
                                                                    TextResources.Invoices.DocumentWizardManuallyClosed
                                                                }></i>
                                                        </td>
                                                    </ko-bind>
                                                    <td
                                                        class="text-right amount-col"
                                                        data-bind={{ numberText: row.Amount }}></td>
                                                    <td
                                                        class="unit-of-measure-col"
                                                        data-bind={{
                                                            text: row.Udm,
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}></td>
                                                    <td
                                                        class="text-right unit-price-col"
                                                        data-bind={{
                                                            moneyText: row.UnitPrice,
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}></td>
                                                    <td
                                                        class="discount-col"
                                                        data-bind={{
                                                            discountText: row.Discounts,
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}></td>
                                                    <td
                                                        class="text-right unit-price-col"
                                                        data-bind={{
                                                            moneyText: row.NetUnitPrice,
                                                            css: { "hidden-col": step.ExpandedColumn() != 2 },
                                                        }}></td>
                                                    <td
                                                        class="text-right total-price-col"
                                                        data-bind={{ moneyText: row.Total }}></td>
                                                </tr>
                                            </ko-bind>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        class="wizard-body-column third-col"
                        data-bind={{
                            css: {
                                "expanded-column": step.ExpandedColumn() == 3,
                                "collapsed-column": step.ExpandedColumn() != 3,
                            },
                            click: step.expandColumn.bind(step, 3),
                        }}>
                        <div class="text-center" data-bind={{ visible: step.ExpandedColumn() == 3 }}>
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                data-bind={{ click: step.importSelectedRows }}>
                                {TextResources.Invoices.DocumentWizardImportRows}
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                data-bind={{ click: step.ClearImportedRows }}>
                                {TextResources.Invoices.DocumentWizardClear}
                            </button>
                        </div>

                        <div
                            class="wizard-body-column-with-actions"
                            data-bind={{
                                style: {
                                    top: step.ExpandedColumn() == 3 ? "37px" : "0px",
                                    bottom: step.ExpandedColumn() == 3 ? "0px" : "37px",
                                },
                            }}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-right amount-col">
                                                {TextResources.Invoices.DocumentWizardAmount}
                                            </th>
                                            <th
                                                class="unit-of-measure-col"
                                                data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 3 } }}>
                                                {TextResources.Invoices.DocumentWizardUoM}
                                            </th>
                                            <th
                                                class="text-right unit-price-col"
                                                data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 3 } }}>
                                                {TextResources.Invoices.DocumentWizardUnitPriceShort}
                                            </th>
                                            <th
                                                class="discount-col"
                                                data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 3 } }}>
                                                {TextResources.Invoices.DocumentWizardDiscount}
                                            </th>
                                            <th
                                                class="text-right unit-price-col"
                                                data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 3 } }}>
                                                {TextResources.Invoices.DocumentWizardNetUnitPrice}
                                            </th>
                                            <th class="text-right total-price-col">
                                                {TextResources.Invoices.DocumentWizardTotalPrice}
                                            </th>
                                            <th
                                                class="action-col"
                                                data-bind={{ css: { "hidden-col": step.ExpandedColumn() != 3 } }}>
                                                {TextResources.Invoices.DocumentWizardRemove}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>

                            <div class="wizard-data-source-table-body">
                                <div data-bind={{ slimScroll: "auto" }}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if: step.Rows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={8} class="text-center">
                                                        {TextResources.Invoices.DocumentWizardNoRowAvailable}
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: { data: step.Rows, as: "outRow" } }}>
                                                <tr>
                                                    <td data-bind={{ text: outRow.Descrizione }}></td>
                                                    <td
                                                        class="text-right amount-col"
                                                        data-bind={{ numberText: outRow.Quantita }}></td>
                                                    <td
                                                        class="unit-of-measure-col"
                                                        data-bind={{
                                                            text: outRow.Udm,
                                                            css: { "hidden-col": step.ExpandedColumn() != 3 },
                                                        }}></td>
                                                    <td
                                                        class="text-right unit-price-col"
                                                        data-bind={{
                                                            moneyText: outRow.PriceInDocumentCurrency,
                                                            currencySymbol: step.DocumentCurrencySymbol,
                                                            documentCurrency: step.DocumentCurrency,
                                                            css: { "hidden-col": step.ExpandedColumn() != 3 },
                                                        }}></td>
                                                    <td
                                                        class="discount-col"
                                                        data-bind={{
                                                            discountText: outRow.Discounts,
                                                            css: { "hidden-col": step.ExpandedColumn() != 3 },
                                                        }}></td>
                                                    <td
                                                        class="text-right unit-price-col"
                                                        data-bind={{
                                                            moneyText: outRow.NetUnitPriceInDocumentCurrency,
                                                            currencySymbol: step.DocumentCurrencySymbol,
                                                            documentCurrency: step.DocumentCurrency,
                                                            css: { "hidden-col": step.ExpandedColumn() != 3 },
                                                        }}></td>
                                                    <td
                                                        class="text-right total-price-col"
                                                        data-bind={{
                                                            moneyText: outRow.TotalPriceInDocumentCurrency,
                                                            currencySymbol: step.DocumentCurrencySymbol,
                                                            documentCurrency: step.DocumentCurrency,
                                                        }}></td>
                                                    <td
                                                        class="text-center action-col"
                                                        data-bind={{
                                                            css: { "hidden-col": step.ExpandedColumn() != 3 },
                                                        }}>
                                                        <a
                                                            href="#"
                                                            class="btn btn-xs red"
                                                            data-bind={{ click: step.removeRow.bind(step, outRow) }}>
                                                            <i class="fa fa-times"></i>
                                                            {TextResources.Invoices.DocumentWizardRemove}
                                                        </a>
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "step"
        );
    }
}

class SupplierOrder extends BaseDocumentForWizard<SupplierOrderRow> {
    SelectAll: ko.Computed<boolean>;
    SupplierName: ko.Observable<string> = ko.observable();
    OrderName: ko.Observable<string> = ko.observable();

    private rowsLoaded = false;

    @LazyImport(nameof<IOrdersService>())
    private ordersService: IOrdersService;

    constructor(
        public supplierOrder: ISupplierOrderForWizard,
        private rowsStorage: WarehouseSupplierOrderDataSource,
        private previouslyImportedRows: IDocumentBuilderDocumentOriginatingRows[]
    ) {
        super(supplierOrder, ProlifeSdk.SupplierOrderEntityTypeCode);

        this.SupplierName(supplierOrder.SupplierName);
        this.OrderName(supplierOrder.OrderName);

        this.SelectAll = ko.computed({
            read: () => {
                const rows = this.Rows();
                return rows.filter((r: SupplierOrderRow) => !r.Selected()).length == 0;
            },
            write: (selected: boolean) => {
                const rows = this.Rows();
                rows.forEach((r: SupplierOrderRow) => r.Selected(selected));
            },
        });
    }

    public SwitchSelection() {
        if (!this.supplierOrder.Accepted) return;

        this.IsSelected(!this.IsSelected());
        if (this.IsSelected()) {
            this.loadRows().then(() => {
                this.rowsStorage.SelectedDocuments.push(this);
            });
        } else this.rowsStorage.SelectedDocuments.remove(this);
    }

    public async loadRows(): Promise<void> {
        if (this.rowsLoaded) return;

        try {
            const rows = await this.ordersService.getSupplierOrderRowsById(this.supplierOrder.Id);
            this.onRowsLoaded(rows);
        } catch {
            this.rowsLoaded = false;
        }
    }

    private onRowsLoaded(rows: ISupplierOrderRow[]) {
        this.rowsLoaded = true;
        this.Rows(rows.map(this.createViewModelFor.bind(this)));
        this.ordersService
            .getSupplierOrderStatus(this.supplierOrder.Id)
            .then((status: ISupplierOrderRowStatus[]) =>
                this.Rows().forEach((r: SupplierOrderRow) => r.UpdateStatus(status))
            );
    }

    private createViewModelFor(row: ISupplierOrderRow): SupplierOrderRow {
        return new SupplierOrderRow(row, this.rowsStorage, this.previouslyImportedRows);
    }

    public doImportSelectedRows(result = true) {
        let someRowsAlreadyImported = false;

        if (!result) return someRowsAlreadyImported;

        const selectedRows = this.Rows().filter((r: SupplierOrderRow) => {
            return r.Selected();
        });
        const rowsToImport = selectedRows.filter(
            (r: SupplierOrderRow) => !r.wasPreviouslyImportedInThisDocument() && !r.wasImportedDuringThisWizard()
        );
        someRowsAlreadyImported = rowsToImport.length != selectedRows.length;

        rowsToImport.forEach((r: SupplierOrderRow) => {
            const row = r.createImportRow();
            const sourceDocumentCurrency = this.DocumentCurrency();

            if (this.rowsStorage.ApplyCurrenciesExchangeValues(row, sourceDocumentCurrency))
                this.rowsStorage.Rows.push(row);
        });

        return someRowsAlreadyImported;
    }
}

class SupplierOrderRow implements IBaseDocumentRowForWizard {
    Selected: ko.Observable<boolean> = ko.observable(true);
    RowNumber: ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable();
    UnitPrice: ko.Observable<number> = ko.observable();
    Total: ko.Observable<number> = ko.observable();
    NetUnitPrice: ko.Observable<number> = ko.observable();
    Discounts: ko.Observable<string> = ko.observable();
    Udm: ko.Observable<string> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();

    AlreadyImportedInThisDocument: ko.Observable<boolean> = ko.observable(false);
    RemainingInOrder: ko.Observable<number> = ko.observable(0);

    constructor(
        public sorRow: ISupplierOrderRow,
        private rowsStorage: WarehouseSupplierOrderDataSource,
        private destinationDocumentRowsReferences: IDocumentBuilderDocumentOriginatingRows[]
    ) {
        const amount = Math.max(sorRow.Quantita - sorRow.ClosedAmount, 0);

        this.RowNumber(sorRow.IndiceRiga);
        this.Description(sorRow.Descrizione);
        this.Amount(amount);
        this.UnitPrice(sorRow.PriceInDocumentCurrency); // this.UnitPrice(row.Importo);
        this.Total(amount * (sorRow.NetUnitPriceInDocumentCurrency || 0)); // this.Total((amount) * (row.NetUnitPrice || 0));
        this.Discounts(sorRow.Discounts);
        this.Udm(sorRow.Udm);
        this.NetUnitPrice(sorRow.NetUnitPriceInDocumentCurrency || 0); // this.NetUnitPrice(row.NetUnitPrice || 0);
        this.ManuallyClosed(sorRow.ManuallyClosed);
        this.ClosedAmount(sorRow.ClosedAmount);

        this.AlreadyImportedInThisDocument(this.wasPreviouslyImportedInThisDocument());
        this.CalculateRamainingAmount(sorRow.Quantita);
    }

    public setAmountAndUpdateTotal(amount: number): void {
        this.Amount(amount);
        this.Total(amount * (this.sorRow.NetUnitPriceInDocumentCurrency || 0));
    }

    private CalculateRamainingAmount(remainingFromServer) {
        let remaining = remainingFromServer;

        this.destinationDocumentRowsReferences
            .filter((r: IRefDocumentRow) => !r.RefId || r.RefId < 1)
            .forEach((r: IRefDocumentRow) => (remaining = remaining > r.Amount ? remaining - r.Amount : 0));

        //this.Amount(remaining);
        this.RemainingInOrder(remaining);
    }

    public UpdateStatus(status: ISupplierOrderRowStatus[]) {
        const matches: ISupplierOrderRowStatus[] = status.filter(
            (s: ISupplierOrderRowStatus) => s.RowId == this.sorRow.IdRigaOrdineFornitore
        );

        this.CalculateRamainingAmount(matches.length > 0 ? matches[0].AmountInOrder : 0);
    }

    public isAlreadyImported(): boolean {
        return (
            this.wasPreviouslyImportedInThisDocument() ||
            this.wasImportedDuringThisWizard() ||
            this.wasAlreadyImportedInADocument()
        );
    }

    public wasPreviouslyImportedInThisDocument(): boolean {
        return (
            this.destinationDocumentRowsReferences.filter(
                (ref: IRefDocumentRow) =>
                    ref.SourceEntityKeyId == this.sorRow.IdRigaOrdineFornitore &&
                    ref.SourceEntityType == ProlifeSdk.SupplierOrderEntityTypeCode
            ).length > 0
        );
    }

    public wasAlreadyImportedInADocument(): boolean {
        const sign: number = this.sorRow.Quantita < 0 ? -1 : 1;
        return this.sorRow.ReferencingRows.length > 0 && (this.sorRow.Quantita - this.sorRow.ClosedAmount) * sign <= 0;
    }

    public wasImportedDuringThisWizard(): boolean {
        return (
            this.rowsStorage
                .Rows()
                .filter(
                    (r: ISupplierOrderRowForDocuments) =>
                        r.Rows.filter(
                            (er: ISupplierOrderRow) =>
                                er.IdRigaOrdineFornitore == this.sorRow.IdRigaOrdineFornitore &&
                                er.EntityType == this.sorRow.EntityType
                        ).length > 0
                ).length > 0
        );
    }

    public createImportRow(): ISupplierOrderRowForDocuments {
        const referencedRow: ISupplierOrderRow = $.extend(true, {}, this.sorRow);

        referencedRow.Quantita = this.Amount();
        referencedRow.TotaleRiga = this.Total(); // Viene convertito in Euro dall'ordine fornitore
        referencedRow.NetUnitPrice = this.NetUnitPrice(); // Viene convertito in Euro dall'ordine fornitore
        referencedRow.TotalPriceInDocumentCurrency = this.Total();
        referencedRow.NetUnitPriceInDocumentCurrency = this.NetUnitPrice();

        return {
            Descrizione: this.Description(),
            Quantita: this.Amount(),
            Importo: this.UnitPrice(), // Viene convertito in Euro dall'ordine fornitore
            PriceInDocumentCurrency: this.UnitPrice(), // Viene convertito in valuta documento dall'ordine fornitore
            TotaleRiga: this.Total(), // Viene convertito in Euro dall'ordine fornitore
            TotalPriceInDocumentCurrency: this.Total(), // Viene convertito in valuta documento dall'ordine fornitore
            NetUnitPrice: this.NetUnitPrice(), // Viene convertito in Euro dall'ordine fornitore
            NetUnitPriceInDocumentCurrency: this.NetUnitPrice(), // Viene convertito in valuta documento dall'ordine fornitore
            Discounts: this.Discounts(),
            Udm: this.Udm(),
            OffsetId: this.sorRow.OffsetId,
            OffsetCode: this.sorRow.OffsetCode,

            Rows: [referencedRow],
        };
    }
}
