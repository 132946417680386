/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 25/08/2017
 * Time: 11:00
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ICampaignStateSettingManager } from "../../../../interfaces/ICampaignStateSettingManager";
import { ICampaignState } from "../../../../../ProlifeSdk/interfaces/survey/ICampaign";

export class CampaignStatesControlsEntityProvider implements IControlsEntityProvider {
    private statesManager: ICampaignStateSettingManager;
    private lastTimeout: number;

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService: ISettingsService = <ISettingsService>(
            serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        this.statesManager = <ICampaignStateSettingManager>(
            settingsService.findSettingsManager(ProlifeSdk.CampaignState)
        );
    }

    public findEntities(query: any) {
        const searchFilter: string = query.term || "";
        const states: ICampaignState[] = this.statesManager.getCampaignStates();
        const filteredStates: ICampaignState[] = states.filter((s: ICampaignState) => {
            return s.Label.toUpperCase().indexOf(searchFilter.trim().toUpperCase()) > -1;
        });

        query.callback({
            results: filteredStates.map((s: ICampaignState) => {
                return {
                    id: s.Id,
                    text: s.Label,
                };
            }),
        });
    }

    public findEntity(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id) && id > 0) {
            const s: ICampaignState = this.statesManager.getCampaignStateById(id);

            callback({
                id: s.Id,
                text: s.Label,
            });
        }
    }
}
