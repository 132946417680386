import * as ko from "knockout";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { ActivitiesNavigatorBase } from "./ActivitiesNavigatorBase";
import { RootNavigationProvider } from "./providers/workflows/RootNavigationProvider";
import { IWorkflowSelectorObserver, ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowNavigator, IWorkflowNavigatorOptions } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IWorkflowProvider } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowProvider";

export class WorkflowsNavigator extends ActivitiesNavigatorBase implements IWorkflowNavigator
{
    protected dialogService : IDialogsService;
    public IsNumberOfEventsVisible : boolean = false;
    private JobOrderIds : number[] = [];

    private observers : IWorkflowSelectorObserver[] = [];
    private changeSelectionTimeout: ReturnType<typeof setTimeout>;

    private lastSelectedIds : number[] = [];
    multipleSelectedJob: ko.ObservableArray<any> = ko.observableArray();

    constructor(serviceLocator : IServiceLocator, navigatorOptions? : IWorkflowNavigatorOptions)
    {
        super(serviceLocator, new RootNavigationProvider(serviceLocator, navigatorOptions || {}));
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs)
    }

    Initialize(jobOrderIds : number[]) : Promise<void>
    {
        this.JobOrderIds = jobOrderIds;
        (<RootNavigationProvider>this.RootMenuProvider).SetJobOrderIds(jobOrderIds);
        return super.InitializeBase();
    }

    ShowNumberOfEvents(show : boolean)
    {
        this.IsNumberOfEventsVisible = show;
    }

    public RegisterObserver(o : IWorkflowSelectorObserver)
    {
        this.observers.push(o);
    }

    public UnregisterObserver(o : IWorkflowSelectorObserver)
    {
        var index = this.observers.indexOf(o);
        if(index < 0)
            return;

        this.observers.splice(index, 1);
    }

    public RefreshWorkflow(id : number)
    {
        (<RootNavigationProvider>this.RootMenuProvider).RefreshWorkflow(id);
    }

    public SelectWorkflow(id: number) {
        if (!this.internalSelectWorkflow(id)) {
            (<RootNavigationProvider>this.RootMenuProvider).refreshItems()
                .then(() => {
                    this.internalSelectWorkflow(id);
                });
        }
    }

    private internalSelectWorkflow(id: number): boolean {
        var workflowsMatching: IWorkflowProvider[] = <IWorkflowProvider[]> (<RootNavigationProvider>this.RootMenuProvider).Items().filter((w: IWorkflowProvider) => w.WorkflowId == id);

        if (workflowsMatching.length > 0) {
            this.selectProvider(workflowsMatching[0], true);
            return true;
        }

        return false;
    }

    public OnWorkflowSelectionChanged(selectedIds : number[])
    {
        this.lastSelectedIds = selectedIds;

        clearTimeout(this.changeSelectionTimeout);

        this.changeSelectionTimeout = setTimeout(() => {
            this.observers.forEach((o : IWorkflowSelectorObserver) => {o.OnWorkflowSelectionChanged(selectedIds); });
        }, 300);
    }

    public OnWorkflowDeleted(workflowId : number)
    {
        this.observers.forEach((o : IWorkflowSelectorObserver) => {o.OnWorkflowDeleted(workflowId); });
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {
        this.observers.forEach((o: IWorkflowSelectorObserver) => o.OnWorkflowUpdate(workflow));
    }

    public NotifyCurrentSelection() {
        this.observers.forEach((o : IWorkflowSelectorObserver) => {o.OnWorkflowSelectionChanged(this.lastSelectedIds); });
    }
}