import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { EditorFor } from "./ReportEditorProvider";
import { ReportComponent } from "../Components/ReportComponent";
import { ReportEditorProps } from "./ReportEditor";
import { ReportComponentDataSource, ReportComponentWithDataSource } from "../Components/ReportComponentWithDataSource";
import TsxForEach from "../../ForEach";
import { DataSourceItem } from "./DataSourceEditor/DataSourceItem";
import { If } from "../../IfIfNotWith";
import { DataSourceEditingDialog } from "./DataSourceEditor/DataSourceEditingDialog";
import { Select } from "../../Select";
import { Table } from "../../TableComponent/TableComponent";
import { ReportComponentWithDataSourceId } from "../Components/ReportComponentWithDataSourceId";

const styleSheet = jss.createStyleSheet({
    dataSourceIdEditor: {
        "& .toolbar": {
            backgroundColor: "#eee",
            minHeight: '28px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },

        "& .list-group-item": {
            padding: '5px'
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDataSourceIdEditorProps = {
    component: ReportComponentWithDataSourceId & ReportComponent;
} & ReportEditorProps;

export function ReportDataSourceIdEditor(props: ReportDataSourceIdEditorProps) {
    const C = require("./ReportDataSourceIdEditor")._ReportDataSourceIdEditor as typeof _ReportDataSourceIdEditor;
    return <C {...props} />;
}

@EditorFor("DataSourceId")
export class _ReportDataSourceIdEditor {
    static defaultProps: Partial<ReportDataSourceIdEditorProps> = {
    }

    constructor(private props : ReportDataSourceIdEditorProps) {
        
    }

    render() {
        const dataSources = this.props.component.getSection().dataSources().map(ds => ds.getData());
        const dataSource = Table.defaultDataSource(dataSources, ds => ({ id: ds.id, title: ds.name, subTitle: ds.dataSourceId, model: ds }));

        return  <div class={ComponentUtils.classNames("editor-group", classes.dataSourceIdEditor)}>
                    <span class="editor-group-title">Fonte Dati</span>
                    <div className="editor-group-body" style={{ paddingTop: '5px'}}>
                        <Select.WithLabel label="Fonte Dati" dataSource={dataSource} value={this.props.component.dataSourceId} allowClear placeholder={"Seleziona una fonte dati..."} />
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDataSourceIdEditor);
}