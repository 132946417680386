import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 28/05/13
 * Time: 15.04
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDdtState, IDdtStates } from "../../../../ProlifeSdk/interfaces/invoice/settings/IDdtStates";
import { Deferred } from "../../../../Core/Deferred";

export interface IDdtStateProvider {
    update(state : IDdtState);
    getData() : IDdtState;
}

export class DdtStatesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IDdtStateProvider> = ko.observableArray();
    NewStateDescription : ko.Observable<string> = ko.observable();
    NewStateDescriptionHasFocus : ko.Observable<boolean> = ko.observable(false);
    ShowDeleted : ko.Observable<boolean> = ko.observable(false);
    dialogsService : IDialogsService;
    toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private statesManager : IDdtStates)
    {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.toastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.title = statesManager.getLabel();
        var states = statesManager.getStates(true);
        this.elements(states.map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(state : IDdtState)
    {
        return new DdtState(this, state);
    }

    public createOrUpdateEntry(state : IDdtStateProvider, isLogicalStatusChanged : boolean = false) : Promise<IDdtState>
    {
        if(!isLogicalStatusChanged)
            return this.createOrUpdateAfterConfirm(state);

        var d = new Deferred<IDdtState>();

        this.dialogsService.Confirm(ProlifeSdk.TextResources.Invoices.LogicStateChangedMsg, ProlifeSdk.TextResources.Invoices.LogicStateChangedMsgCancel, ProlifeSdk.TextResources.Invoices.LogicStateChangedMsgConfirm, (confirm : boolean) => {
            if (!confirm)
            {
                d.resolve();
                return;
            }

            this.createOrUpdateAfterConfirm(state)
                .then((ddtState: IDdtState) => { d.resolve(ddtState); })
                .catch(() => { d.reject([]); });
        });

        return d.promise();
    }

    public createOrUpdateAfterConfirm(state : IDdtStateProvider) : Promise<IDdtState>
    {
        var data = state.getData();
        return this.statesManager.createOrUpdate(data)
            .then((s : IDdtState) => {
                state.update(s);

                this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateSaved);
                return s;
            });
    }

    public deleteEntry(state : IDdtStateProvider)
    {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Invoices.DeleteStateMsg, ProlifeSdk.TextResources.Invoices.DeleteStateMsgCancel, ProlifeSdk.TextResources.Invoices.DeleteStateMsgConfirm, (confirm : boolean) => {
            if(!confirm)
                return;

            var data : IDdtState = state.getData();
            this.statesManager.remove(data.Id)
                .then(() => {
                    this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateDeleted);
                    var d : IDdtState = state.getData();
                    d.Deleted = 1;
                    state.update(d);
                });
        });
    }

    public restore(state : IDdtStateProvider)
    {
        var data : IDdtState = state.getData();
        this.statesManager.restore(data.Id)
            .then((s : IDdtState) => {
                this.toastService.Success(ProlifeSdk.TextResources.Invoices.StateRestored);
                state.update(s);
            });
    }

    public addNewSetting()
    {
        if(!this.NewStateDescription()) return;

        var newState = this.createViewModelFor(<IDdtState>{
            Description: this.NewStateDescription()
        });
        this.elements.push(newState);
        this.createOrUpdateEntry(newState);
        this.NewStateDescription("");
    }
}

class DdtState implements IDdtStateProvider
{
    Description : ko.Observable<string> = ko.observable();
    State : ko.Observable<number> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    StateName : ko.Observable<string> = ko.observable();
    StateNames : ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.Invoices.Draft, ProlifeSdk.TextResources.Invoices.Accepted, ProlifeSdk.TextResources.Invoices.Rejected
    ]);

    private updating : boolean = false;
    private IsVisible : ko.Computed<boolean>;
    public Deleted : ko.Observable<boolean> = ko.observable(false);
    public Editable : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : DdtStatesEditingViewModel, private state : IDdtState)
    {
        this.update(state);

        this.IsVisible = ko.computed(() => {
            return !this.Deleted() || container.ShowDeleted();
        });
    }

    public save()
    {
        if(this.updating || !this.Description())
            return;

        this.updating = true;

        var isLogicalStatusChanged : boolean = this.State() != this.state.LogicalStatus;
        this.container.createOrUpdateEntry(this, isLogicalStatusChanged)
            .then(() => {
                this.cancelEdit();
            })
            .finally(() => {
                this.updating = false;
            });
    }

    public edit()
    {
        this.Editable(true);
    }

    public cancelEdit()
    {
        this.update(this.state);
        this.Editable(false);
    }

    public update(state : IDdtState)
    {
        this.state = state;
        this.Description(state.Description);
        this.Deleted(state.Deleted == 1);
        this.setState(state.LogicalStatus);
    }

    public getData() : IDdtState
    {
        var newState = <IDdtState> $.extend({}, this.state);
        newState.Description = this.Description();
        newState.LogicalStatus = this.State();
        return newState;
    }

    private restore()
    {
        this.container.restore(this);
    }

    private remove()
    {
        this.container.deleteEntry(this);
    }

    public setState(stateId : number)
    {
        this.State(stateId);
        this.StateName(this.StateNames()[stateId]);
    }
}