import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IAliasesService, IManufacturerAliasEx } from "../Warehouse/AliasesService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export interface IManufacturerAliasesDataSourceModel extends IDataSourceModel<number, IManufacturerAliasEx> {

}

export class ManufacturerAliasesDataSource extends BaseDataSource<IManufacturerAliasesDataSourceModel> {
    @LazyImport(nameof<IAliasesService>())
    private aliasesService! : IAliasesService;

    private customerId : number = -1;

    public setCustomerId(customerId : number)
    {
        this.customerId = customerId;
    }

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.Articles;
    }    
    
    async getData(currentModel: IManufacturerAliasesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IManufacturerAliasesDataSourceModel[]> {
        let aliases = await this.aliasesService.GetManufacturerAliases(this.customerId, textFilter, skip, count);
        let models = aliases.map(this.createModelFor, this);
        return models;
    }

    private createModelFor(manufacturerAlias : IManufacturerAliasEx) : IManufacturerAliasesDataSourceModel {
        return {
            id: manufacturerAlias.Id,
            title: manufacturerAlias.NewManufacturerPrefix,
            isLeaf: true,
            isGroup: false,
            subTitle: manufacturerAlias.OriginalManufacturerCode,
            model: manufacturerAlias
        }
    }
    
    async getById(currentModel: IManufacturerAliasesDataSourceModel, ids: number[]): Promise<IManufacturerAliasesDataSourceModel[]> {
        let articles = await this.aliasesService.GetManufacturerAliasesByIds(ids);
        return articles.map(this.createModelFor, this);
    }
}