import * as numeral from "numeral";
import moment = require("moment");
import { INavigationMenuComponentModel, INavigationMenuComponent, INavigationMenuComponentDataSource } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { LazyImport } from "../Core/DependencyInjection";
import { IWorkedHoursService, IJobOrderDailyPlan } from "../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface IYearNavigationMenuComponentModel extends INavigationMenuComponentModel {
    jobOrderId: number;
    isYear: true;
    year: number;
}

export interface IMonthNavigationMenuComponentModel extends INavigationMenuComponentModel {
    jobOrderId: number;
    isMonth: true;
    year: number;
    month: number;
}

export interface IDayNavigationMenuComponentModel extends INavigationMenuComponentModel {
    jobOrderId: number;
    isDay: true;
    year: number;
    month: number;
    day: number;

    date: Date;
    dayNumber: string;
    isToday: boolean;

    hasExtraordinaryHours?: boolean;
    isHoliday?: boolean;
    thereIsAnAwayActivity?: boolean;
    thereIsAnOffsiteActivity?: boolean;
    totalHours?: string;
    numericTotalHours?: number;
    allApproved?: boolean;
    partiallyApproved?: boolean;
    someRejected?: boolean;
    isLocked?: boolean;
    hasFlexibility?: boolean;
}

export class WorkedHoursJobOrderDateDataSource implements INavigationMenuComponentDataSource {
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;

    protected view: IDataSourceView;
    protected jobOrderId: number;

    protected hasPendingSelect : boolean = false;
    protected requestedMonth : Date;
    protected requestedDay : Date;

    constructor() {}

    refresh() {
        if (this.view)
            this.view.refresh();
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if (this.hasPendingSelect && this.requestedMonth != null) {
            this.selectMonth(this.requestedMonth);
        }

        if (this.hasPendingSelect && this.requestedDay != null) {
            this.selectDay(this.requestedDay);
        }
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): void {
    }

    setNavigator(navigator: INavigationMenuComponent): void {
        
    }

    getTitle(currentModel  : IDataSourceModel | null) : string {
        if (currentModel == null)
            return TextResources.WorkedHours.DateDataSourceTitle;

        let yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if (yearModel.isYear)
            return yearModel.title;

        let monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if (monthModel.isMonth)
            return monthModel.title;

        return "";
    }

    isGroupedData(currentModel: INavigationMenuComponentModel, textFilter: string): boolean {
        return false;
    }    
    
    getData(currentModel: INavigationMenuComponentModel, textFilter: string, skip: number, count: number): Promise<INavigationMenuComponentModel[]> {
        if (skip > 0)
            return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => resolve([]));

        if( currentModel == null)
            return this.getYearsData();

        let yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if (yearModel.isYear)
            return this.getMonthsData(yearModel.year);

        let monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if (monthModel.isMonth)
            return this.getDaysData(monthModel.year, monthModel.month);

        throw new Error("DateDataSource: Unsupported Model Type");
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        if (currentModel == null) {
            return ids.map(id => this.createYear(id));
        }
        let yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if (yearModel.isYear) {
            return ids.map(id => this.createMonth(yearModel.year, id));
        }
        let monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if (monthModel.isMonth) {
            let monthPlan = await this.workedHoursService.GetMonthPlanForJobOrder(monthModel.year, monthModel.month + 1, this.jobOrderId);
            return monthPlan.filter(m => ids.indexOf(m.Id) != -1).map(m => this.createDay(m.Id, monthModel.month, monthModel.year, m));
        }

        return [];
    }
    
    getYearsData(): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            let years : INavigationMenuComponentModel[] = [];
            let nextYear = moment().year() + 1;
            let oldestYear = nextYear - 20;

            for (let year = nextYear; year >= oldestYear; year--) {
                let yearModel : IYearNavigationMenuComponentModel = this.createYear(year);

                years.push(yearModel);
            }

            resolve(years);
        });
    }

    getMonthsData(year : number): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            let months: IMonthNavigationMenuComponentModel[] = [];

            for (let month = 0; month < 12; month++) {
                let monthModel : IMonthNavigationMenuComponentModel = this.createMonth(year, month)

                months.push(monthModel);
            }

            resolve(months);
        });
    }

    async getDaysData(year: number, month: number): Promise<INavigationMenuComponentModel[]> {
        let monthPlan = await this.workedHoursService.GetMonthPlanForJobOrder(year, month + 1, this.jobOrderId);
        
        let daysModels: IDayNavigationMenuComponentModel[] = [];
        monthPlan.forEach((p) => {
            daysModels.push(this.createDay(p.Id, month, year, p));
        });
        
        return daysModels;
    }
    
    protected createDay(day: number, month: number, year: number, dailyPlan: IJobOrderDailyPlan = null): IDayNavigationMenuComponentModel {
        let calendarDate = moment(new Date()).year(year).month(month).date(day).toDate();
        let today = moment();

        let model: IDayNavigationMenuComponentModel = {
            id: day,
            jobOrderId: this.jobOrderId,
            isDay: true,
            isGroup: false,
            isLeaf: true,
            title: moment().year(year).month(month).date(day).format("dddd"),

            year: year,
            month: month,
            day: day,
            
            date: calendarDate,
            dayNumber: moment(calendarDate).format("DD"),
            isToday: today.year() == year && today.month() == month && today.date() == day
        };

        model.hasExtraordinaryHours = dailyPlan && dailyPlan.ExtraordinaryHours > 0;
        model.isHoliday = dailyPlan && dailyPlan.IsHoliday;
        model.thereIsAnAwayActivity = dailyPlan && dailyPlan.ThereIsAnAwayActivity;
        model.thereIsAnOffsiteActivity = dailyPlan && dailyPlan.ThereIsAnOffsiteActivity;
        model.totalHours = dailyPlan ? numeral(dailyPlan.OrdinaryHours + dailyPlan.ExtraordinaryHours).format("0.00") : numeral(0).format("0.00");
        model.numericTotalHours = dailyPlan ? dailyPlan.OrdinaryHours + dailyPlan.ExtraordinaryHours : 0;
        model.allApproved = dailyPlan && dailyPlan.AllApproved;
        model.partiallyApproved = dailyPlan && dailyPlan.PartiallyApproved;
        model.someRejected = dailyPlan && dailyPlan.SomeRejected;
        model.isLocked = dailyPlan && dailyPlan.IsLocked;
        model.hasFlexibility = dailyPlan && dailyPlan.HasFlexibility;

        return model;
    }

    protected createYear(year: number): IYearNavigationMenuComponentModel {
        return {
            id: year,
            jobOrderId: this.jobOrderId,
            isYear: true,
            year: year,
            isGroup: false,
            isLeaf: false,
            title: year.toString()
        };
    }

    protected createMonth(year: number, month: number): IMonthNavigationMenuComponentModel {
        return {
            id: month,
            jobOrderId: this.jobOrderId,
            isMonth: true,
            year: year,
            month: month,
            isGroup: false,
            isLeaf: false,
            title: String.capitalize(moment().year(year).month(month).date(1).format("MMMM YYYY"))
        };
    }

    public async selectMonth(startDate: Date) : Promise<void> {
        if (!this.view) {
            this.requestedMonth = startDate;
            this.hasPendingSelect = true;
            return;
        }

        let year = startDate.getFullYear();
        let month = startDate.getMonth();

        let yearModel = this.createYear(year);
        let monthModel = this.createMonth(year, month);

        this.view.navigateTo(yearModel);
        await this.view.select(monthModel);

        this.hasPendingSelect = false;
    }

    public async selectDay(startDate: Date) : Promise<void> {
        if (!this.view) {
            this.requestedDay = startDate;
            this.hasPendingSelect = true;
            return;
        }

        let year = startDate.getFullYear();
        let month = startDate.getMonth();
        let day = startDate.getDate();

        let yearModel = this.createYear(year);
        let monthModel = this.createMonth(year, month);
        let dayModel = this.createDay(day, month, year);

        this.view.navigateTo(yearModel, monthModel);
        await this.view.select(dayModel);

        this.hasPendingSelect = false;
    }

    public areEqual(a: INavigationMenuComponentModel, b: INavigationMenuComponentModel): boolean {
        if(a === b) return true;
        if(!a || !b) return false;
        
        let aAsMonth = a as IMonthNavigationMenuComponentModel;
        let bAsMonth = b as IMonthNavigationMenuComponentModel;

        if(aAsMonth.isMonth && bAsMonth.isMonth) {
            return aAsMonth.month == bAsMonth.month && aAsMonth.year == bAsMonth.year && aAsMonth.jobOrderId == bAsMonth.jobOrderId;
        }

        let aAsYear = a as IYearNavigationMenuComponentModel;
        let bAsYear = b as IYearNavigationMenuComponentModel;

        if(aAsYear.isYear && bAsYear.isYear) {
            return aAsYear.year == bAsYear.year && aAsYear.jobOrderId == bAsYear.jobOrderId;
        }

        let aAsDay = a as IDayNavigationMenuComponentModel;
        let bAsDay = b as IDayNavigationMenuComponentModel;

        if(aAsDay.isDay && bAsDay.isDay) {
            return aAsDay.day == bAsDay.day && aAsDay.month == bAsDay.month && aAsDay.year == bAsDay.year && aAsDay.jobOrderId == bAsDay.jobOrderId;
        }

        return false;
    }

    public setJobOrderId(id: number): void {
        this.jobOrderId = id;
    }

    public getSupportedDropMimeTypes() : string[] {
        return [];
    }
}