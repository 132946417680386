import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BasePagedList } from "../Utilities/BasePagedList";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IWarehousesService, IWarehouse } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { WarehousesDataSource } from "../../../../DataSources/WarehousesDataSource";
import { Select } from "../../../../Components/Select";
import { SegmentedInput } from "../../../../Components/SegmentedInput";
import { If } from "../../../../Components/IfIfNotWith";
import { ArticlesStockManagement } from "./ArticlesStockManagement";
import { PurchasesStockManagement } from "./PurchasesStockManagement";
import { Right } from "../../../../Core/Authorizations";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

type StockManagementProps = {
    selectDefaultWarehouse?: boolean;
    warehouseId?: number;
};

export function StockManagement(props: StockManagementProps) {
    const C = require("./StockManagement")._StockManagement as typeof _StockManagement;
    return <C {...props} />;
}

export class _StockManagement {
    static defaultProps = {
        selectDefaultWarehouse: true,
    };

    public SearchFilter: ko.Observable<string> = ko.observable("");
    public CustomerId: ko.Observable<number> = ko.observable();
    public WarehouseId: ko.Observable<number> = ko.observable();
    public ShowAllMovedArticles: ko.Observable<boolean> = ko.observable(false);
    public ArticlesSwitch: ko.Observable<"articles" | "purchases"> = ko.observable("articles");

    public SomeFilterIsChanged: ko.Observable<boolean> = ko.observable();
    private actualView: ko.Observable<BasePagedList> = ko.observable();

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    public SectionId = 1;

    private CustomersDataSource: CustomersDataSource = new CustomersDataSource();
    private WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();

    @Right("Warehouse_ViewNewPurchases")
    private canViewNewPurchases: boolean;

    constructor(public props: StockManagementProps) {
        this.CustomersDataSource.setReturnGroupedData(false);

        this.CustomerId.subscribe((customerId: number) => {
            this.WarehousesDataSource.setCustomerIds(customerId);
            this.WarehouseId(null);
        });

        if (props.selectDefaultWarehouse)
            this.warehousesService.getDefaultWarehouse().then((warehouse: IWarehouse) => {
                if (warehouse) this.WarehouseId(warehouse.Id);
            });

        if (props.warehouseId) this.WarehouseId(props.warehouseId);

        this.SearchFilter.subscribe(() => this.SomeFilterIsChanged(true));
    }

    public loadRows() {
        this.actualView()?.loadRows();
        this.SomeFilterIsChanged(false);
    }

    public ExportExcel() {
        const url =
            "Warehouse/StockReport/GenerateExcel?warehouseId=" +
            this.WarehouseId() +
            "&showOnlyStocked=" +
            this.ShowAllMovedArticles() +
            "&filter=" +
            encodeURIComponent(this.SearchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public Print() {
        const url =
            "Warehouse/StockReport/GeneratePdf?warehouseId=" +
            this.WarehouseId() +
            "&showOnlyStocked=" +
            this.ShowAllMovedArticles() +
            "&filter=" +
            encodeURIComponent(this.SearchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    render() {
        const sm = this;
        let page: number;
        return ComponentUtils.bindTo(
            <>
                <h3 className="page-title">{TextResources.Warehouse.Inventory}</h3>

                <div className="page-bar" style={{ padding: "5px" }}>
                    <form
                        style="margin-right: 10px"
                        className="caption search input-icon pull-left"
                        data-bind={{ submit: sm.loadRows }}
                    >
                        <i className="fa fa-search"></i>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Cerca articoli..."
                            data-bind={{ value: sm.SearchFilter, valueUpdate: "afterkeydown" }}
                        />
                    </form>

                    <button
                        className="btn pull-left"
                        data-bind={{
                            click: sm.loadRows,
                            css: { "btn-warning": sm.SomeFilterIsChanged, default: !sm.SomeFilterIsChanged() },
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        <i className="fa fa-search"></i>&nbsp;Cerca
                    </button>

                    <Select
                        style={{ width: "170px", marginRight: "10px" }}
                        className="pull-left"
                        dataSource={this.CustomersDataSource}
                        value={this.CustomerId}
                        allowClear
                        placeholder="Magazzini Interni"
                    />
                    <Select
                        style={{ width: "170px" }}
                        className="pull-left"
                        dataSource={this.WarehousesDataSource}
                        value={this.WarehouseId}
                        allowClear
                        placeholder="Seleziona un Magazzino..."
                    />

                    <div className="btn-group pull-right">
                        {/* <a className="btn grey-salt" href="../../../../Service/WarehouseLoaderLogin" target="_blank" style="margin-right: 10px">
                                <i className="fa fa-external-link"></i>
                                &nbsp;Applicazione mobile
                            </a> */}
                        <button
                            type="button"
                            className="btn grey-salt dropdown-toggle btn-fit-height"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                            data-close-others="true"
                            data-delay="1000"
                        >
                            Azioni&nbsp;
                            <i className="fa fa-angle-down"></i>
                        </button>
                        <ul className="dropdown-menu pull-right" role="menu">
                            <ko-bind data-bind={{ if: sm.WarehouseId() > 0 && sm.ArticlesSwitch() === "articles" }}>
                                <li>
                                    <a href="#" data-bind={{ click: sm.Print }}>
                                        Stampa
                                    </a>
                                </li>
                                <li>
                                    <a href="#" data-bind={{ click: sm.ExportExcel }}>
                                        Esporta Excel
                                    </a>
                                </li>
                            </ko-bind>
                        </ul>
                    </div>

                    <div className="pull-right" style="margin-right: 20px;margin-top: 7px;">
                        <div className="checker">
                            <span data-bind={{ css: { checked: sm.ShowAllMovedArticles } }}>
                                <input type="checkbox" data-bind={{ checked: sm.ShowAllMovedArticles }} />
                            </span>
                        </div>
                        Tutti i Movimentati
                    </div>

                    {this.canViewNewPurchases && (
                        <div className="pull-right" style="margin-right: 20px;margin-top: 7px;">
                            <SegmentedInput
                                simple
                                options={[
                                    { text: "Articoli", value: "articles" },
                                    { text: "Acquisti", value: "purchases" },
                                ]}
                                value={sm.ArticlesSwitch}
                            />
                        </div>
                    )}
                </div>

                <If condition={() => this.ArticlesSwitch() === "articles"}>
                    {() => (
                        <ArticlesStockManagement
                            searchFilter={this.SearchFilter}
                            customerId={this.CustomerId}
                            warehouseId={this.WarehouseId}
                            showAllMoved={this.ShowAllMovedArticles}
                            forwardRef={this.actualView}
                        />
                    )}
                </If>
                <If condition={() => this.ArticlesSwitch() === "purchases"}>
                    {() => (
                        <PurchasesStockManagement
                            searchFilter={this.SearchFilter}
                            customerId={this.CustomerId}
                            warehouseId={this.WarehouseId}
                            showAllMoved={this.ShowAllMovedArticles}
                            forwardRef={this.actualView}
                        />
                    )}
                </If>
            </>,
            this,
            "sm"
        );
    }
}

export class ArticleStockStatusLegend implements IDialog {
    public templateName = "article-stock-status-legend-dialog";
    public templateUrl = "warehouse/templates";
    title: string;
    modal: {
        close: (result?: any) => void;
    };

    constructor() {}

    close(): void {
        this.modal.close();
    }

    action(): void {}
}

if (module.hot) {
    module.hot.accept();
    reloadNow(StockManagement);
}
