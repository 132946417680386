import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { IUserMilestone } from "../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { MilestonesCalendar } from "./MilestonesCalendar";

const styleSheet = jss.createStyleSheet({
    userMilestonesModal: {
        position: "relative",
        height: "100%"
    }
});
const { classes } = styleSheet.attach();

export type UserMilestonesDialogProps = {
    milestones: ko.ObservableArray<IUserMilestone>;
}

export class UserMilestonesDialogUI extends DialogComponentBase {
    constructor(private props: UserMilestonesDialogProps) {
        super({ noPrompt: true, className: 'fullscreen' });

        this.title(TextResources.Todolist.UserMilestonesTooltip);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return <UserMilestones {...this.props} />;
    }
}

export function UserMilestones(props: UserMilestonesDialogProps) {
    const C = require("./UserMilestonesDialog")._UserMilestones as typeof _UserMilestones;
    return <C {...props} />;
}

export class _UserMilestones {
    
    constructor(private props: UserMilestonesDialogProps) {
        
    }

    render() {
        return ComponentUtils.bindTo((
            <div className={classes.userMilestonesModal}>
                <MilestonesCalendar milestones={this.props.milestones} />
            </div>
        ), this);
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(UserMilestones);
}