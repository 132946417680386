import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { ICustomersGroupsService, ICustomerGroup } from "../Customers/CustomersGroupsService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface ICustomerGroupDataModel extends IDataSourceModel<number, ICustomerGroup> {

}

export interface ICustomerGroupsDataSource extends IDataSource {

}

export class CustomerGroupsDataSource implements ICustomerGroupsDataSource {
    @LazyImport(nameof<ICustomersGroupsService>())
    private customersGroupsService : ICustomersGroupsService;

    private addNoSelectionItemRequired : boolean = false;
    private noSelectionItemTitle : string;
    private noSelectionItemIcon : string;
    private view: IDataSourceView;
    private selectRequested: boolean = false;
    private selectRequestedData: number[];

    getTitle(currentModel: ICustomerGroupDataModel): string {
        return ProlifeSdk.TextResources.Customers.CustomersGroups;
    }    
    
    isGroupedData(currentModel: ICustomerGroupDataModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: ICustomerGroupDataModel, b: ICustomerGroupDataModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }

    addNoSelectionItem(title: string, icon: string = 'icon-user') {
        this.addNoSelectionItemRequired = true;
        this.noSelectionItemTitle = title;
        this.noSelectionItemIcon = icon;
    }

    async getData(currentModel: ICustomerGroupDataModel, textFilter: string, skip: number, count: number): Promise<ICustomerGroupDataModel[]> {
        let allGroups = await this.customersGroupsService.GetCustomerGroups(null);

        let splitTextFilter = (textFilter || "").toLowerCase().split(' ').map(w => w.trim()).filter(w => w.length > 0);
        let models = allGroups
            .map(this.createModelFor, this);

        if(this.addNoSelectionItemRequired) {
            models = [<ICustomerGroupDataModel>{
                id: <number>null,
                title: this.noSelectionItemTitle,
                isLeaf: true,
                isGroup: false,
                icon: {
                    icon: this.noSelectionItemIcon
                },
                model: {
                    Id: null,
                    Name: this.noSelectionItemTitle,
                    IsDefault: false
                }
            }].concat(models);
        }

        return models
            .filter(g => splitTextFilter.filter(w => g.title.toLowerCase().indexOf(w) != -1).length == splitTextFilter.length);
    }

    async getById(currentModel: ICustomerGroupDataModel, ids: (string | number)[]): Promise<ICustomerGroupDataModel[]> {
        let allGroups = await this.customersGroupsService.GetCustomerGroups(null);

        let models = allGroups
            .map(this.createModelFor, this);

        if(this.addNoSelectionItemRequired) {
            models = [<ICustomerGroupDataModel>{
                id: <number>null,
                title: this.noSelectionItemTitle,
                isLeaf: true,
                isGroup: false,
                icon: {
                    icon: this.noSelectionItemIcon
                },
                model: {
                    Id: null,
                    Name: this.noSelectionItemTitle,
                    IsDefault: false
                }
            }].concat(models);
        }
        
        return models.filter(g => ids.indexOf(g.id) != -1)
    }

    async select(...selectedGroupIds: number[]) : Promise<void> {
        if(!this.view) {
            this.selectRequested = true;
            this.selectRequestedData = selectedGroupIds;
            return;
        }

        this.selectRequested = false;
        this.selectRequestedData = null;

        let models = await this.getById(null, selectedGroupIds);
        await this.view.select(...models);
    }

    createModelFor(model : ICustomerGroup) : ICustomerGroupDataModel {
        return {
            id: model.Id,
            title: model.Name,
            isLeaf: true,
            isGroup: false,
            icon: {
                icon: 'icon-users'
            },
            model: model
        };
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.selectRequested) {
            this.select(...this.selectRequestedData);
        }
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}