import * as React from "@abstraqt-dev/jsxknockout";
import { PropsWithChildren, ComponentUtils, BindingType } from "../Core/utils/ComponentUtils";

type ButtonProps = {
    ["data-bind"]?: BindingType;
    color?: "default" | "primary" | "success" | "warning" | "danger" | "info";
    size?: "sm" | "xs" | "lg";
    className?: string;

    onClick?: (e: MouseEvent) => void;
};

export function Button(props: PropsWithChildren<ButtonProps>) {
    const { className, ...rest } = props;
    const classes = ComponentUtils.classNames("btn", "btn-" + (props.color ?? "default"), className, {
        "btn-sm": props.size === "sm",
        "btn-xs": props.size === "xs",
        "btn-lg": props.size === "lg",
    });

    return <button type="button" className={classes} {...rest} />;
}

export function ButtonGroup(props: PropsWithChildren<{ circle?: boolean; size?: "sm" | "xs" | "lg" }>) {
    const sizeClass = props.size ? "btn-group-" + props.size : "";

    const classes = ComponentUtils.classNames("btn-group", sizeClass, {
        "btn-group-circle": props.circle,
    });

    return <div className={classes}>{props.children}</div>;
}
