import * as ko from "knockout";
/**
 * Created by g.adami on 19/06/2017.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SampleViewModel } from "./SampleViewModel";
import { SampleLogViewModel } from "./SampleLogViewModel";
import { SampleImportDialog } from "./dialogs/SampleImportDialog";
import { CleanSampleDialog } from "./dialogs/CleanSampleDialog";
import { SurveyPanelViewModel } from "../SurveyPanelViewModel";
import { SampleContentDialog } from "./dialogs/SampleContentDialog";
import { AlertDialog } from "../alert-dialog/AlertDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISampleService } from "../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { ISample, ISampleLog } from "../../../../ProlifeSdk/interfaces/survey/ISample";
import { IAssignedCampaigns } from "../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { Deferred } from "../../../../Core/Deferred";

export class SampleDetails
{
    public templateName : string = 'sample-details';
    public templateUrl : string = 'survey/templates/sample';

    public Sample : ko.Observable<SampleViewModel> = ko.observable();
    public Actions : ko.ObservableArray<SampleLogViewModel> = ko.observableArray([]);

    public EmptyActionsList: ko.Computed<boolean>;

    public CanModify: ko.Computed<boolean>;

    private sampleService : ISampleService;
    private infoToast : IInfoToastService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private sampleId: number, private parent: SurveyPanelViewModel)
    {
        this.sampleService = <ISampleService>serviceLocator.findService(ProlifeSdk.SampleServiceType);
        this.infoToast = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.loadData();

        this.CanModify = ko.computed(() => {
            if (!this.Sample())
                return false;
            return this.Sample().CanModify();
        });

        this.EmptyActionsList = ko.computed(() => {
            return this.Actions().length == 0;
        });
    }

    private loadData(): Promise<void> {
        var def = new Deferred<void>();

        Promise.all([
            this.sampleService.getSampleDetails(this.sampleId),
            this.sampleService.getSampleLogActions(this.sampleId)
        ]).then(([sampleResponse, logsResponse]) => {
                this.Sample(new SampleViewModel(this.serviceLocator, sampleResponse));
                this.Actions(logsResponse.map((l: ISampleLog) => { return new SampleLogViewModel(l)}));

                def.resolve();
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
                def.reject();
            });

        return def.promise();
    }

    public Edit(): void {
        this.sampleService.assignedToStartedCampaigns(this.sampleId)
            .then((assignedToCampaigns: IAssignedCampaigns[]) => {
                if (assignedToCampaigns.length > 0) {
                    var alertDialog = new AlertDialog(assignedToCampaigns, ProlifeSdk.TextResources.Survey.EditSampleWithAssociatedCampaignAlert);
                    this.dialogsService.ShowModal<boolean>(alertDialog, null, null, "survey/templates/dialogs", "alert-dialog")
                        .then((confirm: boolean) => {
                            if (confirm)
                                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.sampleId + "/Edit";
                        });
                    return;
                }
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.sampleId + "/Edit";
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.EditSampleError);
            });
    }

    public BackToSplash(): void
    {
        this.parent.Reset = false;
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    public ShowSampleContent() {
        var contentDialog = new SampleContentDialog(this.serviceLocator, this.sampleId);
        this.dialogsService.ShowModal<void>(contentDialog, "fullscreen", null, "survey/templates/sample/dialogs", "sample-content-dialog")
            .then(() => {
                this.loadData();
            });
    }

    dispose()
    {
        this.Actions([]);
    }
}