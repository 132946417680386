import * as ko from "knockout";
import { ReportComponent, ReportComponentProps } from "./ReportComponent";
import { Component } from "./ReportComponentProvider";
import { HasBackground, ReportComponentWithBackgroundProps } from "./ReportComponentWithBackground";
import { HasChildren, ReportComponentWithChildrenProps } from "./ReportComponentWithChildren";
import { HasPosition, ReportComponentWithPositionProps } from "./ReportComponentWithPosition";

export type ReportPageProps = {} & ReportComponentWithChildrenProps & ReportComponentWithBackgroundProps & ReportComponentWithPositionProps & ReportComponentProps;

@Component(nameof<ReportPage>())
export class ReportPage extends HasChildren(HasBackground(HasPosition(ReportComponent))) {
    get type() { return nameof<ReportPage>(); }

    constructor(args: ReportPageProps) {
        super(Object.assign({ backgroundColor: "#FFFFFF"}, args));
        this.features.remove("Position");

        this.x(0);
        this.y(0);
        this.width(21);
        this.height(29.7);
    }

    getData() {
        return super.getData();
    }
}