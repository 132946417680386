import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 15:38
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ContactForList } from "./ContactForList";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import {
    IContactForList,
    IContactForListFilters,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/IContactForList";
import { IQueueState } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueState";

export class QueueWithManualContactSelection {
    public templateName = "manual-contact-selection";
    public templateUrl = "surveywizard/templates/";

    public CallsQueue: ko.ObservableArray<ContactForList> = ko.observableArray([]);
    public ContactsNumber: ko.Computed<number>;
    public SelectedContact: ko.Observable<any> = ko.observable();

    private surveyWizardService: ISurveyWizardService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;
    private entitiesProviderService: IEntityProviderService;

    public SearchFilters: ko.Observable<string> = ko.observable("");

    public HintSearchProvider: IControlsCustomEntitiesProvider;
    private hintSearchGroupsInfo: IHintSearchGroupInfo[] = [];

    constructor(private serviceLocator: IServiceLocator, private campaignId: number) {
        this.surveyWizardService = <ISurveyWizardService>(
            this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType)
        );
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.entitiesProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );

        this.HintSearchProvider = this.entitiesProviderService.GetCustomEntitiesProviderForControls(
            this.hintSearchGroupsInfo,
            true,
            false
        );

        this.ContactsNumber = ko.computed(() => {
            return this.CallsQueue().length;
        });

        this.PopulateContactsList();

        this.SearchFilters.subscribe(this.PopulateContactsList.bind(this));
    }

    public PopulateContactsList(): void {
        this.CallsQueue([]);
        this.surveyWizardService
            .getCallsQueueForWizard(this.campaignId, this.GetFilters())
            .then((queue: IContactForList[]) => {
                const newList = this.CallsQueue();
                queue.forEach((c: IContactForList) => {
                    newList.push(new ContactForList(c, this));
                });
                this.CallsQueue(newList);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetCallsQueueError);
            });
    }

    public GetFilters(): IContactForListFilters {
        const filters: IItemIdWithTypeAndGroupCode[] = this.HintSearchProvider.extractItemsAndTypesFromStringValue(
            this.SearchFilters()
        );
        const words: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "$$HINTWORD$$";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });

        return <IContactForListFilters>{
            TextFilters: words,
        };
    }

    public Call(): void {
        if (!this.SelectedContact()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.ContactSelectionRequered);
            return;
        }

        this.surveyWizardService
            .getQueueStateLabelByLogicalState(ProlifeSdk.InProgressQueueState)
            .then((state: IQueueState) => {
                this.surveyWizardService
                    .updateQueueState(this.SelectedContact().QueueId, state.Id)
                    .then(() => {
                        location.href =
                            "#/" +
                            ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
                            "/" +
                            ProlifeSdk.TextResources.SurveyWizard.CampaignUrl +
                            "/" +
                            this.campaignId +
                            "/" +
                            ProlifeSdk.TextResources.SurveyWizard.WizardUrl +
                            "/" +
                            this.SelectedContact().QueueId;
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.StartNewCallError);
                    });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.StartNewCallError);
            });
    }

    public SelectContact(contact: ContactForList): void {
        if (this.SelectedContact()) this.SelectedContact().Selected(false);
        contact.Selected(true);
        this.SelectedContact(contact);
    }

    public dispose(): void {}
}
