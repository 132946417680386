import * as React from "@abstraqt-dev/jsxknockout";
import { With } from "../../../../Components/IfIfNotWith";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IWorkedHoursEditorParameters, IWorkedHoursRowForEditing } from "../../../interfaces/IWorkedHoursEditor";
import { _WorkedHoursEditor } from "./WorkedHoursEditor";
import { WorkedHoursRowForEditing } from "./WorkedHoursRowForEditing";

function renderRoleSelector() {
    const hourOnEdit: WorkedHoursRowForEditing = null;
    return  <>
                <ko-bind data-bind={{ if: !!hourOnEdit.RoleId() }}>
                    <label>{TextResources.WorkedHours.Role}</label>
                    <select class="form-control input-small pull-right" style="width: 100% !important" data-bind="value : RoleId, options : ValidRoles, optionsValue : 'RoleId', optionsText : 'RoleName', disable: IsReadOnly" tabIndex={4}></select>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: !!hourOnEdit.RoleId() }}>
                    <>
                        {renderFakeRoleField()}
                    </>
                </ko-bind>
            </>;
}

function renderFakeRoleField() {
    let hourOnEdit: WorkedHoursRowForEditing;
    return <div className="form-control text-ellipsis" data-bind={{ text: hourOnEdit.RoleSelectionStatusMessage, css: { 'disabled': !hourOnEdit.canViewOrEditRole }, attr: { title: hourOnEdit.RoleSelectionStatusMessage }, style: { color: (hourOnEdit.RoleId() ? "black" : "red" )} }} tabIndex={4}></div>;
}

function renderWorkTimeCategorySelector() {
    let hourOnEdit: WorkedHoursRowForEditing;
    return  <>
                <ko-bind data-bind={{ if: !!hourOnEdit.WorkTimeCategoryId() }}>
                    <label>{TextResources.WorkedHours.WorkTimeCategory}</label>
                    <select class="form-control input-small pull-right" style="width: 100% !important" data-bind="value : WorkTimeCategoryId, options : ValidWorkTimeCategories, optionsValue : 'WorkTimeCategoryId', optionsText : 'WorkTimeCategoryName', disable: IsReadOnly"></select>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: !!hourOnEdit.WorkTimeCategoryId() }}>
                    <>
                        {renderFakeWorkTimeCategoryField()}
                    </>
                </ko-bind>
            </>;
}

function renderFakeWorkTimeCategoryField() {
    let hourOnEdit: WorkedHoursRowForEditing;
    return <div className="form-control text-ellipsis" data-bind={{ text: hourOnEdit.WorkTimeCategorySelectionStatusMessage, css: { 'disabled': !hourOnEdit.canViewOrEditWorkTimeCategory }, attr: { title: hourOnEdit.WorkTimeCategorySelectionStatusMessage }, style: { color: (hourOnEdit.WorkTimeCategoryId() ? "black" : "red" )} }} tabIndex={6}></div>;
}

export function renderMobileEditor(hoursOnEditRow: ko.Observable<IWorkedHoursRowForEditing>) {
    const hourOnEdit: WorkedHoursRowForEditing = null;
    let editor: _WorkedHoursEditor;
    return  <div className="workedhours-panel mobile">
                <div className="upbar-mobile topBar">
                    <button className="btn btn-circle btn-transparent pull-left btnBlack" data-bind="click: GoBack">
                        <i className="fa fa-arrow-left" style={{ fontSize: "24px" }}></i>
                    </button>
                    <span className="taskboard-page-title" data-bind="text: $parent.WorkedHoursPageSwitcherHandler.actualPageTitle"></span>
                </div>
                <With data={hoursOnEditRow} as="hourOnEdit">
                    {() => (
                        <>
                            <div class="navigator-scrollable-container listActivity-mobile midActivity-menu" style="background-color: #fafafa">
                                <div class="col-md-12 projectActivityPadding borderBottom fullWidth BlockedDisplay joborder-selector" style="z-index: 101; padding-top: 15px; padding-bottom: 20px">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Data</strong></p>
                                    </div>
                                    <div class="activity-container-field pull-right" style="display: inline-block; width: 70%; text-align: left; padding-top: 7px;">
                                        <span data-bind="dateText: WorkDate"></span>
                                    </div>
                                </div>
                                <div class="col-md-12 projectActivityPadding borderBottom fullWidth BlockedDisplay joborder-selector" style="z-index: 101; padding-top: 15px; padding-bottom: 20px">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Progetto</strong></p>
                                    </div>
                                    <div class="activity-container-field pull-right" style="display: inline-block; width: 70%; text-align: right;">
                                        <select2 params="AllowClear: true, Placeholder: 'Digita per ricercare una commessa...', Value: JobOrderId, DataSource: JobOrderDataSource, Listener: $data"></select2>
                                    </div>
                                </div>
                                <div class="col-md-12 projectActivityPadding borderBottom fullWidth BlockedDisplay task-selector" style="padding-top: 15px; padding-bottom: 20px">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Attività</strong></p>
                                    </div>
                                    <div class="activity-container-field pull-right" style="display: inline-block; width: 70%; text-align: right;">
                                        <select2 params="AllowClear: true, Placeholder: 'Digita per ricercare una attività...', Value: TaskId, DataSource: TasksDataSource, Listener: $data"></select2>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay time-selector" style="padding-top: 0px; padding-bottom: 0px; position: relative">
                                    <div class="title" style="margin-top: 30px;">
                                        <p class="titleActivity"><strong>Inizio <i class="fa fa-flash"></i></strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <time-picker placeholder='Ora inizio' value={() => "WorkDate"} timeZone={() => "WorkDateTimezone"} readOnly={() => "IsReadOnly"}></time-picker>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay time-selector" style="padding-top: 0px; padding-bottom: 0px; position: relative">
                                    <div class="title" style="margin-top: 30px;">
                                        <p class="titleActivity"><strong>Fine <i class="fa fa-flag-checkered"></i></strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <time-picker placeholder='Ora fine' value={() => "EndTime"} timeZone={() => "EndTimeTimezone"} readOnly={() => "IsReadOnly"} canChangeDate={true}></time-picker>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Pausa <i class="fa fa-coffee"></i></strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <duration params="Placeholder: 'Pausa', Value: BreakHours, ReadOnly: IsReadOnly"></duration>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Ore <i class="fa fa-clock-o"></i></strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <duration params="Placeholder: 'Ore', Value: OrdinaryHours, ReadOnly: IsReadOnly"></duration>
                                    </div>
                                </div>
                                <ko-bind data-bind="if: TaskId">
                                <ko-bind data-bind="ifnot: WillSeeDetails">
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth text-center">
                                    <div class="title text-center" style="width: 100%" data-bind="click: changeSeeDetails">
                                        <p class="titleActivity"><b>Avanzamento attivit&agrave;</b></p>
                                    </div>
                                    <a href="#" class="btn btn-transparent saveButton-mobile btnBlack" data-bind="click: changeSeeDetails">
                                        <ko-bind data-bind="ifnot: WillSeeDetails"><i class="fa fa-chevron-down" style="margin-right: 5px; font-size: 24px"></i></ko-bind>
                                        <ko-bind data-bind="if: WillSeeDetails"><i class="fa fa-chevron-up" style="margin-right: 5px; font-size: 24px"></i></ko-bind>
                                    </a>
                                </div>
                                </ko-bind>
                                <ko-bind data-bind="if: WillSeeDetails">
                                <div class="projectActivityHeight paddingActivity fullWidth text-center">
                                    <div class="title text-center" style="width: 100%" data-bind="click: changeSeeDetails">
                                        <p class="titleActivity"><b>Avanzamento attivit&agrave;</b></p>
                                    </div>
                                    <a href="#" class="btn btn-transparent saveButton-mobile btnBlack" data-bind="click: changeSeeDetails">
                                        <ko-bind data-bind="ifnot: WillSeeDetails"><i class="fa fa-chevron-down" style="margin-right: 5px; font-size: 24px"></i></ko-bind>
                                        <ko-bind data-bind="if: WillSeeDetails"><i class="fa fa-chevron-up" style="margin-right: 5px; font-size: 24px"></i></ko-bind>
                                    </a>
                                </div>
                                </ko-bind>
                                </ko-bind>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth" style="" data-bind="visible: WillSeeDetails">
                                    <div style="width: 30%; display: inline-block">
                                        <div class="title" style="width: 100%">
                                            <p class="titleActivity">Gi&agrave; lavorate</p>
                                        </div>
                                        <div class="title" style="margin-top: 25px">
                                            <p class="titleActivity">Su ore stimate</p>
                                        </div>
                                    </div>
                                    <div class="pull-right" style="width: 60%; display: inline-block">
                                        <div class="title pull-right full-width text-right">
                                            <p class="pull-right"> <strong data-bind="timeTextFromNumber: WorkedHoursMinusOriginal"></strong> (+<span data-bind="timeTextFromNumber: OrdinaryHours"></span>)</p>
                                        </div>
                                        <div class="pull-right full-width text-right" style="height: 40%; margin-top: 7px; padding-top: 3px; text-align: center">
                                            <b data-bind="timeTextFromNumber: EstimatedWork"></b>
                                        </div>
                                    </div>
                                </div>
                                <ko-bind data-bind="if: WillSeeDetails">
                                <div class="centerText projectActivityHeight small-font borderBottom fullWidth BlockedDisplay projectActivityPadding">
                                    <div class="fullWidth pull-left" data-bind="visible: WillSeeDetails">
                                        <div class="FourtyPercWidth pull-left" style="display: inline-block">
                                            <div class="title fullWidth" style="margin-top: 20px;">
                                                <p class="titleActivity">Avanzamento(%)</p>
                                            </div>
                                            <div class="title fullWidth" style="margin-top: 20px">
                                                <p class="titleActivity">o T.residuo(h/u)</p>
                                            </div>
                                        </div>
                                        <ko-bind data-bind={{ if: editor.JobOrders_EditTaskProgress}}>
                                            <div class="sixtyPercWidth pull-right" style="display: inline-block">
                                                <div class="pull-right defaultDimensionActivity" style="margin-top: 10px; padding-top: 3px; text-align: center; width: 100%">
                                                    <input class="form-control pull-right text-right" type="text" data-bind="percentageValue: Progress, selectOnFocus: {}, enable: !IsReadOnly()" />
                                                </div>
                                                <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center; width: 100%">
                                                    <duration style="height: 34px" params="Value: ProgressInHours, ReadOnly: ProgressFieldDisabled"></duration>
                                                </div>
                                            </div>
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: editor.JobOrders_EditTaskProgress}}>
                                            <div class="sixtyPercWidth pull-right" style="display: inline-block">
                                                <div class="pull-right defaultDimensionActivity" style="margin-top: 10px; padding-top: 3px; text-align: center; width: 100%">
                                                    <b style="font-size: 1.2em" data-bind="percentageText: Progress"></b>
                                                </div>
                                                <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center; width: 100%">
                                                    <b style="font-size: 1.2em" data-bind="timeTextFromNumber: ProgressInHours"></b>
                                                </div>
                                            </div>
                                        </ko-bind>
                                    </div>
                                </div>
                                </ko-bind>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay" data-bind="visible: ShowServiceOrders">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Ordine di Servizio</strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <select class="form-control input-small pull-right" style="width: 100% !important" data-bind="value : ServiceOrderId, options : ServiceOrders, optionsValue : 'ServiceOrderId', optionsText : 'ServiceOrderName', disable: IsReadOnly" tabIndex={5}></select>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Mansione</strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <ko-bind data-bind={{ if: hourOnEdit.RenderRolesSelector }}>
                                            {renderRoleSelector()}
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: hourOnEdit.RenderRolesSelector }}>
                                            {renderFakeRoleField()}
                                        </ko-bind>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Tipo</strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <ko-bind data-bind={{ if: hourOnEdit.RenderWorktimeCategoriesSelector }}>
                                            {renderWorkTimeCategorySelector()}
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: hourOnEdit.RenderWorktimeCategoriesSelector }}>
                                            {renderFakeWorkTimeCategoryField()}
                                        </ko-bind>
                                    </div>
                                </div>
                                <ko-bind data-bind={{ if: !hourOnEdit.HideAdministrativeData() && hourOnEdit.canViewOrEditBillableAndCallRightFlag }}>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <label class="titleActivity" htmlFor="fatturabile">
                                            <strong>Fatturabile</strong>
                                        </label>
                                    </div>
                                    <div class="pull-right" data-bind="visible: !IsReadOnly()">
                                        <input id="fatturabile" class="checkboxActivity pull-right" data-bind="onOffSwitch : Billable" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                    </div>
                                    <div class="pull-right" data-bind="visible: IsReadOnly">
                                        <span data-bind="text: Billable() ? 'Si' : 'No'"></span>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title" style="width: 70%;">
                                        <label class="titleActivity" htmlFor="DDC">
                                            <strong>Diritto di chiamata</strong>
                                        </label>
                                    </div>
                                    <div class="pull-right" data-bind="visible: !IsReadOnly()">
                                        <input id="DDC" class="checkboxActivity pull-right" data-bind="onOffSwitch : CallRight" type="checkbox" checked={false} data-size="small" data-on-text="Si" data-off-text="No" />
                                    </div>
                                    <div class="pull-right" data-bind="visible: IsReadOnly">
                                        <span data-bind="text: CallRight() ? 'Si' : 'No'"></span>
                                    </div>
                                </div>
                                </ko-bind>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Luogo</strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <ko-bind data-bind={{ if: !hourOnEdit.HideAdministrativeData() && hourOnEdit.canViewOrEditWorkPlace }}>
                                            <select class="form-control input-small pull-right" style="width: 100% !important" data-bind="value : WorkPlaceId, options : WorkPlaces, optionsValue : 'Id', optionsText : 'Description', enable: !IsReadOnly()"></select>
                                        </ko-bind>
                                        <ko-bind data-bind={{ if: hourOnEdit.HideAdministrativeData() || !hourOnEdit.canViewOrEditWorkPlace }}>
                                            <div className="form-control" data-bind={{ css: { 'disabled': !hourOnEdit.canViewOrEditWorkPlace } }}>{TextResources.WorkedHours.Autoselection}</div>
                                        </ko-bind>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <div class="title">
                                        <p class="titleActivity"><strong>Km viaggio </strong></p>
                                    </div>
                                    <div class="pull-right defaultDimensionActivity" style="padding-top: 3px; text-align: center">
                                        <ko-bind data-bind={{ if: !hourOnEdit.HideAdministrativeData() && hourOnEdit.canViewOrEditTravelDistance }}>
                                            <input class="form-control pull-right text-right" type="text" data-bind="numberValue: TravelDistance, enable: !IsReadOnly()" />
                                        </ko-bind>
                                        <ko-bind data-bind={{ if: hourOnEdit.HideAdministrativeData() || !hourOnEdit.canViewOrEditTravelDistance }}>
                                            <div className="form-control" data-bind={{ css: { 'disabled': !hourOnEdit.canViewOrEditTravelDistance }, numberText: 0.00, format: '0,0.00' }}></div>
                                        </ko-bind>
                                    </div>
                                </div>
                                <div class="projectActivityHeight paddingActivity borderBottom fullWidth BlockedDisplay">
                                    <textarea class="noteArea" spellCheck="false" style="width: 100%" placeholder="Inserire le note..." data-bind="selectOnFocus: true, value: Notes, autoHeight: true, enable: !IsReadOnly()"></textarea>
                                </div>
                            </div>
                            <div class="bottomBarActivity-mobile">
                                <button class="btnWorkSheet btn btn-transparent btnBlue pull-right" data-bind="click: $parent.SaveActivity.bind($parent)" style="right: 10px">
                                    <i class="fa fa-check"></i>
                                </button>
                            </div>
                        </>
                    )}
                </With>
            </div>;
}