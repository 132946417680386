import * as ko from "knockout";
import * as numeral from "numeral";

export class DocumentsUtilities
{
    public static CalculateDiscount(discounts : string) : number
    {
        var totalDiscount = 1;
        var discounts = (discounts || "").replace(/[^0-9.,]/g, " ");

        discounts.split(" ")
            .filter((discountString : string) => discountString.length > 0)
            .map((discountString : string) => 1 - numeral(discountString + "%").value())
            .forEach((discount : number) => totalDiscount *= discount);

        return totalDiscount;
    }
}