import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowForList } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";

export class WorkflowsDropDownList implements IDropDownList
{
    private todoListService : ITodoListService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    public selectedId : ko.Observable<number> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private jobOrderIdGetter : () => number)
    {
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
    }

    public search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.todoListService.GetWorkflowsForList(this.jobOrderIdGetter(), query.term)
                .then((data) => {
                    query.callback({
                        results: data.map((g : ITodoListWorkflowForList) =>
                        {
                            return {
                                id: g.Id,
                                text: g.Title
                            };
                        })
                    });
                });
        }, 500);
    }

    public getSelected(element, callback)
    {
        if(this.selectedId()) {
            this.todoListService.GetWorkflowForList(this.selectedId())
                .then((g : ITodoListWorkflowForList) => callback({
                    id: g.Id,
                    text: g.Title
                }));
        }
    }

    public getSelectedKey() : any {
        return this.selectedId();
    }
}