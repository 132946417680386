import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

let attributes = {
    Markup: "markup"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "expense-markup-popover": {
               params?: {
                   Markup?: string
               };
               
               markup: number | (() => "string");
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IExpenseMarkupPopoverCompoenentParams {
    Markup: Param<number>;
}

export class ExpenseMarkupPopoverCompoenent {
    public Markup: ComponentParam<number>;

    constructor(params : IExpenseMarkupPopoverCompoenentParams) {
        this.Markup = ComponentUtils.parseParameter(params.Markup, 0);
    }
}

ko.components.register("expense-markup-popover", {
    viewModel: {
        createViewModel: (params: IExpenseMarkupPopoverCompoenentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new ExpenseMarkupPopoverCompoenent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <percentage-input format="0,0.00 %" value={() => "Markup"} placeholder={TextResources.Expenses.Percentage}></percentage-input>
            ]);
            
            return vm;
        },
    },
    template: []
});