import * as ko from "knockout";
import { ReportComponentConstructor, MergedReportComponentConstructor } from "./ReportComponent";

export type AutoSizeMode = "none" | "horizontal" | "vertical" | "both";
export interface ReportComponentWithPosition {
    x: ko.Observable<number>;
    y: ko.Observable<number>;
    width: ko.Observable<number>;
    height: ko.Observable<number>;

    autoSize: ko.Observable<AutoSizeMode>;
    minWidth: ko.Observable<number>;
    minHeight: ko.Observable<number>;

    maxWidth: ko.Observable<number>;
    maxHeight: ko.Observable<number>;

    realX: ko.Computed<number>;
    realY: ko.Computed<number>;
    realMinWidth: ko.Computed<number>;
    realMinHeight: ko.Computed<number>;
    realMaxWidth: ko.Computed<number>;
    realMaxHeight: ko.Computed<number>;
};

export type ReportComponentWithPositionProps = { 
    x: number, 
    y: number, 
    width: number, 
    height: number,

    autoSize?: AutoSizeMode,
    minWidth?: number,
    minHeight?: number

    maxWidth?: number,
    maxHeight?: number
};

export function HasPosition<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithPosition, ReportComponentWithPositionProps> {
    return class ReportComponentWithPosition extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "Position"];

            const { x, y, width, height, autoSize, minWidth, minHeight, maxWidth, maxHeight } = args[0] as ReportComponentWithPositionProps;
            this.x(x);
            this.y(y);
            this.width(width);
            this.height(height);

            this.autoSize(autoSize ?? "none");
            this.minWidth(minWidth ?? 0);
            this.minHeight(minHeight ?? 0);
            this.maxWidth(maxWidth ?? 0);
            this.maxHeight(maxHeight ?? 0);

            this.realX = ko.computed(() => ((this.parent() && this.parent().realX && this.parent().realX()) ?? 0) + this.x());
            this.realY = ko.computed(() => ((this.parent() && this.parent().realY && this.parent().realY()) ?? 0) + this.y());
            this.realMinWidth = ko.computed(() => {
                if(this.autoSize() === "vertical" || this.autoSize() === "none")
                    return this.width();
    
                return this.minWidth();
            });
    
            this.realMinHeight = ko.computed(() => {
                if(this.autoSize() === "horizontal" || this.autoSize() === "none")
                    return this.height();
    
                return this.minHeight();
            });

            this.realMaxWidth = ko.computed(() => {
                if(this.autoSize() === "vertical" || this.autoSize() === "none")
                    return this.width();
    
                return this.maxWidth();
            });
    
            this.realMaxHeight = ko.computed(() => {
                if(this.autoSize() === "horizontal" || this.autoSize() === "none")
                    return this.height();
    
                return this.maxHeight();
            });
        }

        getData() {
            const data = super.getData();

            return {
                ...data,
                x: this.x(),
                y: this.y(),
                width: this.width(),
                height: this.height(),
                autoSize: this.autoSize(),
                minHeight: this.minHeight(),
                minWidth: this.minWidth(),
                maxWidth: this.maxWidth() == 0 ? null : this.maxWidth(),
                maxHeight: this.maxHeight() == 0 ? null : this.maxHeight()
            }
        }

        x: ko.Observable<number> = ko.observable();
        y: ko.Observable<number> = ko.observable();
        width: ko.Observable<number> = ko.observable();
        height: ko.Observable<number> = ko.observable();

        autoSize: ko.Observable<AutoSizeMode> = ko.observable();
        minWidth: ko.Observable<number> = ko.observable();
        minHeight: ko.Observable<number> = ko.observable();

        maxWidth: ko.Observable<number> = ko.observable();
        maxHeight: ko.Observable<number> = ko.observable();

        realX: ko.Computed<number>;
        realY: ko.Computed<number>;
        realMinWidth: ko.Computed<number>;
        realMinHeight: ko.Computed<number>;
    }
};