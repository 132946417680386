import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IWarehouse } from "../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWarehouseSettingsManager } from "../../../ProlifeSdk/interfaces/warehouse/IWarehouseSettingsManager";
import { IValidityTypesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IValidityTypes";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WarehouseSettingsManager implements IWarehouseSettingsManager {
    private observers : IValidityTypesObserver[] = [];
    private defaultWarehouse : IWarehouse = null;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Warehouse.Warehouse);
    }

    load(): Promise<IWarehouse> {
        return this.ajaxService.Get<IWarehouse>("Warehouse-api", "DefaultWarehouse/FindDefault", {})
            .then((w : IWarehouse) => this.defaultWarehouse = w);
    }

    getDefaultWarehouse() : IWarehouse
    {
        return this.defaultWarehouse;
    }

    getName(): string {
        return ProlifeSdk.WarehouseSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Warehouse.Warehouse;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI(): IView {
        return null;
    }
}