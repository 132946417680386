import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/03/2019
 * Time: 12:53
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { WorkedAmountRow } from "./WorkedAmountRow";
import { WorkedAmountRowForListViewModel } from "./WorkedAmountRowForListViewModel";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowForLimitedList, ITodoListWorkflowForList } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { ITodoListTaskForLimitedList, ITodoListTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITaskStatusIconHelper } from "../../../ProlifeSdk/interfaces/todolist/ITaskStatusIconHelper";
import { IWorkedHoursService } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IWorkedAmountsPanelViewModel } from "../../interfaces/IWorkedAmountsPanelViewModel";
import { IWorkedAmountRowEditor, IWorkedAmountRowViewModel, IWorkedAmountRowForListViewModel } from "../../interfaces/IWorkedAmountRowViewModel";
import { IWorkedAmountExtendedRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedAmountRow";
import { IJobOrderForLimitedList, IJobOrderForList } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class WorkedAmountsPanelViewModel implements IWorkedAmountsPanelViewModel, IWorkedAmountRowEditor {
    public templateName: string = "worked-amounts-panel";
    public templateUrl: string = "workedhours/templates/panels";

    public RowOnEdit: ko.Observable<IWorkedAmountRowViewModel> = ko.observable();

    public ActivitiesList: ko.ObservableArray<IWorkedAmountRowForListViewModel> = ko.observableArray([]);

    public ShowClosed : ko.Observable<boolean> = ko.observable(true);
    public SelectedDate: ko.Observable<Date> = ko.observable();
    public SelectedJobOrders: ko.ObservableArray<number> = ko.observableArray([]);
    public SelectedJobOrder: ko.Observable<number> = ko.observable();
    public SelectedWorkflow: ko.Observable<number> = ko.observable();
    public SelectedTask: ko.Observable<number> = ko.observable();
    public SelectedResource: ko.Observable<number> = ko.observable();

    public DifferentialProgressInputMode: ko.Observable<boolean> = ko.observable(true);

    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public IsLoading: ko.Observable<boolean> = ko.observable(false);

    public TaskStatusIconHelper: ITaskStatusIconHelper;

    private dialogsService: IDialogsService;
    private jobOrdersService: IJobOrderService;
    private infoToastService: IInfoToastService;
    private workedHoursService: IWorkedHoursService;
    private todoListService: ITodoListService;

    private jobOrderSearchTimeout: ReturnType<typeof setTimeout>;
    private workflowSearchTimeout: ReturnType<typeof setTimeout>;
    private taskSearchTimeout: ReturnType<typeof setTimeout>;

    constructor(private serviceLocator: IServiceLocator, date: Date, resourceId: number) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.workedHoursService = <IWorkedHoursService> this.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        this.todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.jobOrdersService = <IJobOrderService> this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);

        this.TaskStatusIconHelper = this.todoListService.GetTaskStatusIconHelper();
        
        this.AddEmptyActivity();
        //this.LoadActivities();

        this.ShowClosed.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedDate.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedJobOrders.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedWorkflow.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedTask.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedResource.subscribe(() => {
            if (!this.IsLoading())
                this.LoadActivities();
        });

        this.SelectedJobOrder.subscribe((jobOrderId: number) => {
            if (this.IsLoading())
                return;

            if (!jobOrderId) {
                this.SelectedJobOrders([]);
                return;
            }

            this.SelectedJobOrders([jobOrderId]);
            this.LoadActivities();
        });
    }

    public AddEmptyActivity(checkRowOnEditChanges: boolean = true): void {
        if (this.RowOnEdit() && this.RowOnEdit().HasChanges() && checkRowOnEditChanges) {
            this.confirmPendingChangesLoss((confirm: boolean) => { if (confirm) this.internalAddEmptyActivity(); });
            return;
        }

        this.internalAddEmptyActivity();
    }

    public SetRowOnEdit(row: IWorkedAmountRowViewModel): void {
        if (this.RowOnEdit() && this.RowOnEdit().HasChanges()) {
            this.confirmPendingChangesLoss((confirm: boolean) => { if (confirm) this.internalSetRowOnEdit(row); });
            return;
        }

        this.internalSetRowOnEdit(row);
    }

    public SaveRowOnEdit(): void {
        if (!this.RowOnEdit())
            return;

        this.RowOnEdit().Save();
    }

    public OnRowSaved(): void {
        this.LoadActivities();
        this.AddEmptyActivity(false);
    }

    public OnRowDeleted(row: IWorkedAmountRowViewModel): void {
        this.LoadActivities();
        this.AddEmptyActivity(false);
    }

    public LoadActivities(): Promise<IWorkedAmountExtendedRow[]> {
        this.ShowLoadMore(true);
        this.ActivitiesList([]);
        return new Promise((resolve) => resolve([]));
        //return this.LoadNextPage();
    }

    public async LoadNextPage(): Promise<IWorkedAmountExtendedRow[]> {
        if (this.IsLoading())
            return [];

        this.IsLoading(true);

        let skip = this.ActivitiesList().length;
        let count = 50;
        let rows: IWorkedAmountExtendedRow[] = [];

        try {
            rows = await this.workedHoursService.LoadWorkedAmountsRows(this.SelectedJobOrders().map((j: number) => j <= 0 ? null : j), this.SelectedWorkflow(), this.SelectedTask(), this.SelectedDate(), this.ShowClosed(), this.SelectedResource(), skip, count);
            if (rows.length == 0 || rows.length <= 50)
                this.ShowLoadMore(false);

            let oldRows = this.ActivitiesList();
            this.ActivitiesList(oldRows.concat(rows.map((r) => this.createWorkedAmountRowForList(r))));
        } catch (e) {
            console.error(e);
        } finally {
            this.IsLoading(false);
        }

        return rows;
    }

    public findJobOrders(query : any) {
        if(this.jobOrderSearchTimeout)
            clearTimeout(this.jobOrderSearchTimeout);

        this.jobOrderSearchTimeout = setTimeout(() => {
            this.jobOrdersService.GetJobOrdersLimitedList(query.term, false, 0, 100)
                .then((jobOrders : IJobOrderForLimitedList[]) => {
                    query.callback({
                        results: jobOrders.map((j : IJobOrderForLimitedList) => {
                            return {
                                id: j.JobOrderId,
                                text: j.Name
                            };
                        })
                    });
                })
        }, 500);
    }

    public findJobOrder(element, callback) {
        var id = parseInt(<string>$(element).val());
        if(!id || id <= 0 || isNaN(id))
            return;

        this.jobOrdersService.GetJobOrderForList(id)
            .then((jobOrder : IJobOrderForList) => callback({
                id: jobOrder.JobOrderId,
                text: jobOrder.Name
            }));
    }

    public findWorkflows(query : any) {
        if(this.workflowSearchTimeout)
            clearTimeout(this.workflowSearchTimeout);

        this.workflowSearchTimeout = setTimeout(() => {
            this.todoListService.GetWorkflowsForLimitedList(this.SelectedJobOrder(), query.term, 0, 100)
                .then((workflows : ITodoListWorkflowForLimitedList[]) => {
                    query.callback({
                        results: workflows.map((w : ITodoListWorkflowForLimitedList) => {
                            return {
                                id: w.Id,
                                text: w.Title,
                                jobOrder: w.JobOrderName
                            };
                        })
                    });
                })
        }, 500);
    }

    public findWorkflow(element, callback) {
        var id = <number>$(element).val();
        if(!id)
            return;

        this.todoListService.GetWorkflowForList(id)
            .then((workflow : ITodoListWorkflowForList) => callback({
                id: workflow.Id,
                text: workflow.Title
            }));
    }

    public findTasks(query : any) {
        if(this.taskSearchTimeout)
            clearTimeout(this.taskSearchTimeout);

        this.taskSearchTimeout = setTimeout(() => {

            this.todoListService.GetTasksLimitedList(query.term, this.SelectedJobOrder(), this.SelectedWorkflow(), null, 0, 100)
                .then((tasks : ITodoListTaskForLimitedList[]) => {
                    query.callback({
                        results: tasks.map((t : ITodoListTaskForLimitedList) => {
                            return {
                                id: t.Id,
                                text: !t.Title ? 'N/A' : t.Title,
                                workflowTitle: t.WorkflowTitle,
                                jobOrderTitle: t.JobOrderTitle
                            };
                        })
                    });
                })
        }, 500);
    }

    public findTask(element, callback) {
        var id = <number>$(element).val();
        if(!id)
            return;

        this.todoListService.GetTaskById(id)
            .then((task : ITodoListTask) => callback({
                id: task.Id,
                text: task.Title
            }));
    }

    public GetFormattedTaskForList(data: any): string {
        return "<div style=\"display: inline-block;\"><span>" + data.text + "</span><br/>" +
            "<span style=\"font-size: 11px; color: #888;\">" + data.workflowTitle + "</span><br/>" +
            "<span style=\"font-size: 11px; color: #888;\">" + data.jobOrderTitle + "</span>" +
            "</div>";
    }

    public GetFormattedWorkflowForList(data: any): string {
        return "<div style=\"display: inline-block;\"><span>" + data.text + "</span><br/>" +
            "<span style=\"font-size: 11px; color: #888;\">" + data.jobOrder + "</span>" +
            "</div>";
    }

    private createWorkedAmountRowForList(rowData: IWorkedAmountExtendedRow): IWorkedAmountRowForListViewModel {
        var row = new WorkedAmountRowForListViewModel(this.serviceLocator);
        row.RegisterEditor(this);
        row.LoadFrom(rowData);
        return row;
    }

    private createWorkedAmountRow(rowData: IWorkedAmountExtendedRow = null): IWorkedAmountRowViewModel {
        var row = new WorkedAmountRow(this.serviceLocator);
        row.RegisterEditor(this);

        if (!rowData)
            rowData = {
                Id: this.workedHoursService.GetNextFakeId(),
                TaskId: null,
                TaskTitle: '',
                WorkableAmount: 0,
                WorkedAmount: 0,
                Progress: 0,
                UnitOfMeasure: '',
                JobOrderId: null,
                Date: moment().toDate(),
                ResourceId: null,
                TaskIsDeleted: false,
                OriginalProgress: 0,
                OriginalWorkableAmount: 0,
                TaskBoardStatus: -1,
                WorkflowId: null,
                WorkflowTitle: '',
                JobOrderTitle: ''
            };

        row.LoadFrom(rowData);

        return row;
    }

    private internalAddEmptyActivity(): void {
        this.RowOnEdit(this.createWorkedAmountRow());
    }

    private internalSetRowOnEdit(row: IWorkedAmountRowViewModel): void {
        var vm = this.createWorkedAmountRow(row.GetData());
        this.RowOnEdit(vm);
    }

    private confirmPendingChangesLoss(callback: (confirm: boolean) => void): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.WorkedHours.WorkedAmountRowChangesLossConfirmMessage,
            ProlifeSdk.TextResources.WorkedHours.WorkedAmountRowChangesLossCancelButton,
            ProlifeSdk.TextResources.WorkedHours.WorkedAmountRowChangesLossConfirmButton,
            callback
        );
    }
}