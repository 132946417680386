import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 25/08/2016
 * Time: 13:02
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { PerformanceDashboard } from "../PerformanceDashboard";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class JobOrderPerformanceFactory implements IJobOrderEditorPanelFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderPerformance(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewPerformancesDashboard");
    }

}

class JobOrderPerformance implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Performance);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "performance-dashboard";

    public performanceDashboard : PerformanceDashboard;
    private selectedWorkflowId: number = null;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {
        this.performanceDashboard = new PerformanceDashboard(serviceLocator);
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
        
    }

    @Delay()
    load(): Promise<boolean> {
        this.performanceDashboard.OnWorkflowSelectionChanged(this.selectedWorkflowId ? [this.selectedWorkflowId] : []);
        this.performanceDashboard.LoadData(this.editor.JobOrderId);
        return Promise.resolve<boolean>(true);
    }

    isDefaultOnNew(): boolean {
        return false;
    }

    isDefault(): boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(true);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.selectedWorkflowId = model.id as number;
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        this.selectedWorkflowId = null;
        this.load();
    }

    OnWorkflowSelectionChanged(selection: number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) {
    }

    OnWorkflowChanged(workflowId:number) {
    }

    OnWorkflowCreated(workflowId:number) {
    }

    OnWorkflowImportedFromTemplate(workflowId:number) {
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}
}