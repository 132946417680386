import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { RefConverterBase, IRefDocumentRowWithUOM, IDocumentInfoForInlineRefProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";

export class RowToNewPurchaseArticleRefConverter extends RefConverterBase
{
    constructor(activationCode : string, docInfo : IDocumentInfoForInlineRefProvider)
    {
        super(activationCode, ProlifeSdk.WarehousePurchaseArticleEntityTypeCode, docInfo);
    }

    public async getReference(rowId : number, entity : IEntityRefKey, amount : number) : Promise<IRefDocumentRowWithUOM>
    {
        const ref = this.GetEmptyReference(rowId, entity, amount);

        ref.Discounts = undefined;
        ref.UnitPrice = 0;
        ref.CatalogId = entity.Type;
        ref.NetUnitPrice = 0;
        ref.NetPrice = 0;
        ref.WarehouseId = this.DocumentInfo.SourceWarehouseId();
        ref.UnitOfMeasure = null;

        return ref;
    }
}
