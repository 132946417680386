import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { NavigationProvider } from "../../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { TaskProvider } from "./TaskProvider";
import { WorkflowEditDialog } from "../../../../workflows/WorkflowEditDialog";
import { WorkflowsNavigatorProvider } from "../WorkflowsNavigatorProvider";
import { ITodoListService } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowForList, ITodoListWorkflow } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowEditorResult } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IWorkflowProvider } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowProvider";

export class WorkflowProvider extends WorkflowsNavigatorProvider implements IChangesNotificationsServiceObserver, IWorkflowProvider {
    public WorkflowId: number;
    public NumberOfTasks: ko.Observable<number> = ko.observable(0);
    public NumberOfDoneTasks: ko.Observable<number> = ko.observable(0);
    public WorkflowProgress: ko.Observable<number> = ko.observable(0);
    public Title: ko.Observable<string> = ko.observable("");
    public InQuality: ko.Observable<boolean> = ko.observable();
    public HideFromSuggestions: ko.Observable<boolean> = ko.observable();
    public Icon: ko.Observable<string> = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();

    public OutcomeIcon: ko.Observable<string> = ko.observable();
    public OutcomeBackground: ko.Observable<string> = ko.observable();
    public OutcomeForeground: ko.Observable<string> = ko.observable();

    public IsLateStart: ko.Observable<boolean> = ko.observable();
    public IsLateEnd: ko.Observable<boolean> = ko.observable();
    public IsReady: ko.Observable<boolean> = ko.observable();
    public HasWarehouse: ko.Observable<boolean> = ko.observable();
    public HasPurchases: ko.Observable<boolean> = ko.observable();
    public HasResources: ko.Observable<boolean> = ko.observable();
    public HasWorkflowWarehouse: ko.Observable<boolean> = ko.observable();
    public WorkflowIsReady: ko.Observable<boolean> = ko.observable();
    public HasReportingTasks: ko.Observable<boolean> = ko.observable();
    public HoursToBeBilled: ko.Observable<number> = ko.observable();
    public HasEstimatedWork: ko.Observable<boolean> = ko.observable(true);

    private changesNotificationsService: IChangesNotificationsService;

    public TotalReestimatedHours: ko.Observable<number> = ko.observable();
    public AllocatedHours: ko.Observable<number> = ko.observable();
    public UnallocatedHours: ko.Observable<number> = ko.observable();

    public IsCompletelyAllocated: ko.Computed<boolean>;
    public IsPartiallyAllocated: ko.Computed<boolean>;
    public IsUnestimated: ko.Computed<boolean>;
    public HasAllocatedHours: ko.Computed<boolean>;
    public HasAllocatedElements: ko.Computed<boolean>;
    public HasNotEstimatedElements: ko.Observable<boolean> = ko.observable();
    public HasNotEstimatedElementsAllocated: ko.Observable<boolean> = ko.observable();

    public AllocationPercentage: ko.Computed<number>;

    // aggiunta per funzionare sul menù del modulo Allocations
    public HasGroups: ko.Observable<boolean> = ko.observable(false);
    public Groups: ko.ObservableArray<any> = null;

    constructor(protected serviceLocator: IServiceLocator, protected workflow: ITodoListWorkflowForList, private observer: any) {
        super(serviceLocator);
        this.changesNotificationsService = <IChangesNotificationsService>serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType);

        this.IsLeaf = true;
        this.templateName = "workflow-item";
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.SetWorkflowData(workflow);

        this.IsSelected.subscribe(() => {
            observer.OnWorkflowSelectionChanged();
        });

        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);

        this.TotalReestimatedHours(this.workflow.TotalReestimatedHours || 0);
        this.AllocatedHours(this.workflow.AllocatedHours || 0);
        this.UnallocatedHours(this.workflow.UnallocatedHours || 0);

        this.HasNotEstimatedElementsAllocated(this.workflow.HasNotEstimatedElementsAllocated);
        this.HasNotEstimatedElements(this.workflow.HasNotEstimatedElements);

        this.IsUnestimated = ko.computed(() => {
            return this.TotalReestimatedHours() == 0;
        });

        this.HasAllocatedHours = ko.computed(() => {
            return this.AllocatedHours() > 0;
        });

        this.HasAllocatedElements = ko.computed(() => {
            return this.HasAllocatedHours() || this.HasNotEstimatedElementsAllocated();
        });

        this.IsCompletelyAllocated = ko.computed(() => {
            return this.TotalReestimatedHours() == this.AllocatedHours() && !this.IsUnestimated() && !this.HasNotEstimatedElementsAllocated();
        });

        this.IsPartiallyAllocated = ko.computed(() => {
            return this.TotalReestimatedHours() - this.AllocatedHours() > 0 && this.HasAllocatedHours() && !this.HasNotEstimatedElementsAllocated();
        });

        this.AllocationPercentage = ko.computed(() => {
            if (this.TotalReestimatedHours() == 0) return null;
            return (this.AllocatedHours() / (this.AllocatedHours() + this.UnallocatedHours())) * 100;
        });
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        //Se il cambio di piano non impatta il piano selezionato scaro l'evento

        if (
            changesInfo.Action === 9 &&
            changesInfo.Object.workflowId != this.WorkflowId &&
            changesInfo.Changes.OldStatus.previousWorkflowId !== this.WorkflowId
        )
            return true;
        else if (changesInfo.Object.workflowId != this.WorkflowId) return true;

        this.RefreshFromServer();
        return false;
    }

    public OpenEditDialog() {
        this.todoListService.GetWorkflow(this.WorkflowId).then((w: ITodoListWorkflow) => {
            const vm: WorkflowEditDialog = new WorkflowEditDialog({ workflow: w });
            vm.ShowDialog().then((result: IWorkflowEditorResult) => {
                if (!result) return;

                if (result.Action == "REMOVE") {
                    this.observer.OnWorkflowDeleted(this);
                    this.Dispose();
                } else {
                    this.RefreshFromServer();
                    this.observer.OnWorkflowUpdate(result.Workflow);
                }
            });
        });
    }

    public RefreshFromServer() {
        this.todoListService.GetWorkflowForList(this.WorkflowId).then((w: ITodoListWorkflowForList) => {
            this.RefreshData(w);
        });
    }

    public RefreshData(workflow: ITodoListWorkflowForList): Promise<void> {
        this.SetWorkflowData(workflow);
        return Promise.resolve<void>(undefined);
    }

    public SetWorkflowData(workflow: ITodoListWorkflowForList) {
        this.WorkflowId = workflow.Id;
        this.Name = workflow.Title;
        this.Title(workflow.Title);
        this.InQuality(workflow.InQuality);
        this.HideFromSuggestions(workflow.HideFromSuggestions);
        this.NumberOfDoneTasks((workflow.TasksCount || 0) - (workflow.NotCompleteTasksCount || 0));
        this.NumberOfTasks(workflow.TasksCount);
        this.WorkflowProgress(((workflow.TasksCount - workflow.NotCompleteTasksCount) / workflow.TasksCount) * 100);
        this.Icon(workflow.Icon || "icon-star");
        this.Background(workflow.Background);
        this.Foreground(workflow.Foreground);
        this.StateId(workflow.StateId || workflow.Status);
        this.OutcomeIcon(workflow.OutcomeIcon);
        this.OutcomeBackground(workflow.OutcomeBackground);
        this.OutcomeForeground(workflow.OutcomeForeground);
        this.IsLateStart(workflow.IsLateStart);
        this.IsLateEnd(workflow.IsLateEnd);
        this.IsReady(workflow.IsReady);
        this.HasWarehouse(workflow.HasWarehouse);
        this.HasPurchases(workflow.HasPurchases);
        this.HasResources(workflow.HasResources);
        this.HasWorkflowWarehouse(workflow.HasWorkflowWarehouse);
        this.WorkflowIsReady(workflow.WorkflowIsReady);
        this.HasReportingTasks(workflow.HasReportingTasks);
        this.HoursToBeBilled(workflow.HoursToBeBilled);
    }

    search(textFilter: string): Promise<void> {
        return Promise.resolve<void>(undefined);
    }

    public Dispose() {
        this.changesNotificationsService.RemoveObserver(this);
    }

    public GetObserver(): any {
        return this.observer;
    }
}
