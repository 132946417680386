import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ProvincesProvider } from "../../../ProlifeSdk/prolifesdk/providers/ProvincesProvider";
import { NationsProvider } from "../../../ProlifeSdk/prolifesdk/providers/NationsProvider";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import jss from "jss";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { IInvoicesService } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IException } from "../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICustomersService, ILetterOfAttempt } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IWarehousesService } from "../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ICompanySettingsManager } from "../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IRecipientCode } from "../../../ProlifeSdk/interfaces/customer/IRecipientCode";
import { IValidationService, IValidator, IValidation } from "../../../ProlifeSdk/ValidationService";
import { IJobOrderBlockMotivationsSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderBlockMotivationsSettingsManager";
import { IDocumentBuilderDocument } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";

const attributes = {
    Customer: "customer",
    ReadOnly: "readonly",
    IsSourceForCopy: "isSourceForCopy",
    ForSuppliers: "forSuppliers",
    ForCustomers: "forCustomers",
    ShowOptions: "showOptions",
    FilerJobOrders: "filterJobOrders",
    ShowAllCustomers: "showAllCustomers",
    CollapsedWhenEmpty: "collapsedWhenEmpty",
    Label: "label",
    Placeholder: "placeholder",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "document-customer": {
                params?: {
                    Customer: string;
                    ReadOnly: string;
                    IsSourceForCopy: string;
                    ForSuppliers: string;
                    ForCustomers: string;
                    ShowOptions: string;
                    FilerJobOrders: string;
                    ShowAllCustomers: string;
                    CollapsedWhenEmpty: string;
                    Label: string;
                    Placeholder: string;
                };

                customer: DocumentRecipientInfo | (() => string);
                readonly: boolean | (() => string);
                isSourceForCopy: boolean | (() => string);
                forSuppliers: boolean | (() => string);
                forCustomers: boolean | (() => string);
                showOptions: boolean | (() => string);
                filterJobOrders?: () => string;
                showAllCustomers?: () => string;
                collapsedWhenEmpty?: boolean | (() => string);
                label: string | (() => string);
                placeholder?: string | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

export class DocumentRecipientInfo {
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<IWarehousesService>())
    private warehousesService: IWarehousesService;

    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    private company: ICompanySettingsManager;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderBlockMotivation)
    private motivationsSettings: IJobOrderBlockMotivationsSettingsManager;

    private validator: IValidator<DocumentRecipientInfo>;

    HasName: ko.Computed<boolean>;
    HasValue: ko.Computed<boolean>;
    MustValidate: ko.Computed<boolean>;

    // ------------------------------------ UTILITIES -----------------------------------
    IsLocked: ko.Observable<boolean> = ko.observable();
    LockMotivation: ko.Observable<string> = ko.observable();
    CustomerUrl: ko.Observable<string> = ko.observable();
    Selected: ko.Observable<boolean> = ko.observable();
    ShowAllCustomers: ko.Observable<boolean> = ko.observable();
    MustBeCompany: ko.Observable<boolean> = ko.observable();

    NationsProvider: NationsProvider = new NationsProvider();
    ProvincesProvider: ProvincesProvider = new ProvincesProvider();

    FormattedName: ko.Computed<string>;
    CustomerCache: ICustomer;
    LetterOfAttemptsCache: ILetterOfAttempt;
    LetterOfAttemptsCacheDate: Date;

    OnCustomerLoaded: EventHandler = new EventHandler();

    TAXCodeRequired: ko.Observable<boolean> = ko.observable(false);
    NationRequired: ko.Observable<boolean> = ko.observable(false);

    private preventLoad = false;

    // ------------------------------------ PROPERTIES ----------------------------------
    Id: ko.Observable<number> = ko.observable();
    LegalPerson: ko.Observable<boolean> = ko.observable(true);
    BusinessName: ko.Observable<string> = ko.observable();
    Name: ko.Observable<string> = ko.observable();
    Surname: ko.Observable<string> = ko.observable();
    Address: ko.Observable<string> = ko.observable();
    CAP: ko.Observable<string> = ko.observable();
    City: ko.Observable<string> = ko.observable();
    Municipality: ko.Observable<string> = ko.observable();
    Province: ko.Observable<string> = ko.observable();
    Nation: ko.Observable<string> = ko.observable();
    VATCode: ko.Observable<string> = ko.observable();
    TAXCode: ko.Observable<string> = ko.observable();

    EORICode: ko.Observable<string> = ko.observable();

    constructor(public isDestinationRecipient: boolean = false) {
        this.HasName = ko.computed(() => {
            return this.LegalPerson() ? !!this.BusinessName() : !!this.Name() || !!this.Surname();
        });

        this.HasValue = ko.computed(() => {
            return (
                !!this.Id() ||
                !!this.BusinessName() ||
                !!this.Name() ||
                !!this.Surname() ||
                !!this.Address() ||
                !!this.CAP() ||
                !!this.City() ||
                !!this.Municipality() ||
                !!this.Province() ||
                !!this.Nation() ||
                !!this.VATCode() ||
                !!this.TAXCode() ||
                !!this.EORICode()
            );
        });

        this.MustValidate = ko.computed(() => {
            return this.HasValue() || !this.isDestinationRecipient;
        });

        this.FormattedName = ko.computed(() => {
            if (this.LegalPerson()) return this.BusinessName();
            return ((this.Name() ?? "") + " " + (this.Surname() ?? "")).trim();
        });

        this.NationsProvider = new NationsProvider();
        this.ProvincesProvider = new ProvincesProvider();

        this.NationsProvider.SelectedNationCode.subscribe((code: string) => {
            if (this.ProvincesProvider.SelectedNationCode() == "IT" || code == "IT")
                this.ProvincesProvider.SelectedProvinceCode(undefined);

            this.ProvincesProvider.SelectedNationCode(code);

            this.Nation(code);
        });

        this.ProvincesProvider.SelectedProvinceCode.subscribe((code: string) => {
            this.Province(code);
        });

        this.BusinessName.subscribe((value: string) => {
            if (this.LegalPerson() && !(value ?? "").trim()) this.Id(null);
        });
        this.Name.subscribe((value: string) => {
            if (!this.LegalPerson() && !(value ?? "").trim()) this.Id(null);
        });

        this.Id.subscribe(async (id: number) => {
            if (this.preventLoad) return;
            await this.loadFromCustomerId(id);
        });

        this.configureValidation();
    }

    private configureValidation() {
        this.validator = this.validationService
            .createValidator<DocumentRecipientInfo>()
            .isNotNullOrUndefined((r) => r.LegalPerson())
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.BusinessName(),
                TextResources.ProlifeSdk.CustomerBusinessNameRequired,
                () => this.MustValidate() && this.LegalPerson()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.Name(),
                TextResources.ProlifeSdk.CustomerNameRequired,
                () => this.MustValidate() && !this.LegalPerson() && !this.Surname()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.Surname(),
                TextResources.ProlifeSdk.CustomerSurnameRequired,
                () => this.MustValidate() && !this.LegalPerson() && !this.Name()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.Nation(),
                TextResources.ProlifeSdk.CustomerNationRequired,
                () => this.MustValidate() && this.NationRequired()
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.VATCode(),
                TextResources.ProlifeSdk.CustomerVATCodeInvalid,
                () =>
                    this.MustValidate() &&
                    this.NationsProvider.SelectedNationCode() == "IT" &&
                    this.VATCode() != null &&
                    this.VATCode() != undefined &&
                    this.VATCode().trim() != ""
            )
            //.isTrue(r => this.companyPIvaMatch(r.VATCode()), TextResources.Invoices.InvalidVATNumber, () => this.MustValidate() && this.MustBeCompany())
            .isNotNullOrUndefinedOrWhiteSpace(
                (r) => r.TAXCode(),
                TextResources.ProlifeSdk.InsertCustomerTaxCode,
                () => this.MustValidate() && this.TAXCodeRequired() && this.NationsProvider.SelectedNationCode() == "IT"
            )
            .mustBeCfiscOrPiva(
                (r) => r.TAXCode(),
                TextResources.ProlifeSdk.CustomerTaxCodeInvalid,
                () => !!this.NationsProvider && this.NationsProvider.SelectedNationCode() == "IT",
                () => {
                    return (
                        (this.MustValidate() && !this.TAXCodeRequired()) ||
                        this.NationsProvider.SelectedNationCode() !== "IT"
                    );
                }
            );
    }

    validate(): IValidation[] {
        return this.validator.validate(this);
    }

    validateAndShowInfoToast(): boolean {
        return this.validator.validateAndShowInfoToast(this);
    }

    setLegalPerson(legalPerson: boolean) {
        this.LegalPerson(legalPerson);
    }

    getFirstRecipientCodeInfo(): IRecipientCode {
        return this.CustomerCache?.RecipientCodesList?.firstOrDefault();
    }

    getRecipientCodeById(recipientCodeId: number): IRecipientCode {
        return this.CustomerCache?.RecipientCodesList?.firstOrDefault((c) => c.Id === recipientCodeId);
    }

    async hasValidLetterOfAttemptAtDate(
        date: Date,
        includeClosed: boolean,
        documentId: number,
        documentType: string
    ): Promise<boolean> {
        if (this.LetterOfAttemptsCache == undefined || this.LetterOfAttemptsCacheDate != date) {
            this.LetterOfAttemptsCache = (
                await this.customersService.GetLettersOfAttempts({
                    CustomerId: this.Id(),
                    Count: 1,
                    Skip: 0,
                    OnlyValidAtReferenceDate: true,
                    ReferenceDate: date,
                    ShowClosed: includeClosed,
                    documentId: documentId,
                    documentType: documentType,
                })
            ).firstOrDefault();
        }

        return !!this.LetterOfAttemptsCache;
    }

    async loadCurrentCompany() {
        const company = this.company.get();
        const customer = await this.customersService.GetCustomerByVatNumber(company.PIVA);
        await this.loadFromCustomerId(customer?.IdCliente);
    }

    async copyFrom(other: DocumentRecipientInfo) {
        this.preventLoad = true;
        let customer;

        if (other.Id()) customer = await this.customersService.getCustomerById(other.Id());

        this.CustomerCache = customer;
        this.Id(other.Id());
        this.LegalPerson(other.LegalPerson());
        this.BusinessName(other.BusinessName());
        this.Name(other.Name());
        this.Surname(other.Surname());
        this.Address(other.Address());
        this.CAP(other.CAP());
        this.City(other.City());
        this.Municipality(other.Municipality());
        this.NationsProvider.SelectedNationCode(other.Nation());
        this.ProvincesProvider.SelectedNationCode(other.Nation());
        this.ProvincesProvider.SelectedProvinceCode(other.Province());
        this.VATCode(other.VATCode());
        this.TAXCode(other.TAXCode());
        this.EORICode(other.EORICode());
        this.IsLocked(other.IsLocked());
        this.LockMotivation(other.LockMotivation());

        this.CustomerUrl(this.customersService.GetCustomerUrl(other.Id()));

        this.preventLoad = false;
    }

    private companyPIvaMatch(piva: string): boolean {
        if (!piva) return false;

        let companyPiva = this.company.get().PIVA;
        if (!companyPiva) return false;

        const lenDiff = Math.abs(piva.length - companyPiva.length);
        if (piva.length > companyPiva.length) piva = piva.substr(lenDiff);
        else if (piva.length < companyPiva.length) companyPiva = companyPiva.substr(lenDiff);

        return piva === companyPiva;
    }

    private loadFromCustomer(customer: ICustomer) {
        this.CustomerCache = customer;
        this.Id(customer?.IdCliente);
        this.LegalPerson(customer?.IsCompany ?? false);
        this.BusinessName(customer?.RagioneSociale);
        this.Name(customer?.Nome);
        this.Surname(customer?.Cognome);
        this.Address(customer?.Indirizzo);
        this.CAP(customer?.CAP);
        this.City(customer?.Citta);
        this.Municipality(customer?.Comune);
        this.NationsProvider.SelectedNationCode(customer?.Stato);
        this.ProvincesProvider.SelectedNationCode(customer?.Stato);
        this.ProvincesProvider.SelectedProvinceCode(customer?.Provincia);
        this.VATCode(customer?.PIVA);
        this.TAXCode(customer?.CF);
        this.EORICode(customer?.EORICode);
        this.IsLocked(customer?.BlockedBy > 0);

        if (customer?.FkBlockCause > 0) {
            const motivation = this.motivationsSettings
                .getMotivations()
                .firstOrDefault((m) => m.Id === customer?.FkBlockCause);
            this.LockMotivation(motivation?.Description);
        } else {
            this.LockMotivation(undefined);
        }

        this.CustomerUrl(this.customersService.GetCustomerUrl(customer?.IdCliente));

        this.OnCustomerLoaded.Call();
    }

    async loadFromCustomerId(id: number) {
        let customer: ICustomer = null;
        if (id) customer = await this.customersService.getCustomerById(id);

        this.loadFromCustomer(customer);
    }

    async loadFromCustomerIdAndOUId(customerId: number, organizationalUnitId: number, addressId: number) {
        let customer: ICustomer = null;
        if (customerId) customer = await this.customersService.getCustomerById(customerId);

        if (!organizationalUnitId) {
            this.loadFromCustomer(customer);
            return;
        }

        const ou = customer.OrganizationalUnits.firstOrDefault((o) => o.Id === organizationalUnitId);
        if (ou) {
            const address = ou.Addresses.firstOrDefault((a) => !addressId || a.Id === addressId);
            if (address) {
                this.CustomerCache = customer;
                this.LegalPerson(true);
                this.BusinessName(customer.FormattedContactName);
                this.Name(undefined);
                this.Surname(undefined);
                this.Address(address.Address);
                this.CAP(address.CAP);
                this.City(address.City);
                this.Municipality(address.Municipality);
                this.NationsProvider.SelectedNationCode(undefined);
                this.ProvincesProvider.SelectedNationCode(undefined);
                this.ProvincesProvider.SelectedProvinceCode(undefined);
                this.VATCode(undefined);
                this.TAXCode(undefined);
                this.EORICode(undefined);
                this.IsLocked(false);
                this.LockMotivation(undefined);

                this.CustomerUrl(this.customersService.GetCustomerUrl(customer?.IdCliente));

                this.OnCustomerLoaded.Call();

                return;
            }
        }

        this.loadFromCustomer(null);
    }

    async loadFromDocument(document: IDocumentBuilderDocument, isDestinationRecipient: boolean) {
        this.preventLoad = true;

        if (!isDestinationRecipient) {
            let customer: ICustomer = null;
            if (document.Document.FKCustomer)
                customer = await this.customersService.getCustomerById(document.Document.FKCustomer);

            this.CustomerCache = customer;
            this.Id(document.Document.FKCustomer);
            this.LegalPerson(document.Details.CustomerLegalPerson);
            this.BusinessName(document.Details.CustomerBusinessName);
            this.Name(document.Details.CustomerName);
            this.Surname(document.Details.CustomerSurname);
            this.Address(document.Details.CustomerAddress);
            this.CAP(document.Details.CustomerPostalCode);
            this.City(document.Details.CustomerCity);
            this.Municipality(document.Details.CustomerMunicipality);
            this.NationsProvider.SelectedNationCode(document.Details.CustomerState);
            this.ProvincesProvider.SelectedNationCode(document.Details.CustomerState);
            this.ProvincesProvider.SelectedProvinceCode(document.Details.CustomerProvince);
            this.VATCode(document.Details.CustomerVATNumber);
            this.TAXCode(document.Details.CustomerTAXNumber);
            this.EORICode(document.Details.CustomerEORICode);

            this.IsLocked(customer?.BlockedBy > 0);
            if (customer?.FkBlockCause > 0) {
                const motivation = this.motivationsSettings
                    .getMotivations()
                    .firstOrDefault((m) => m.Id === customer?.FkBlockCause);
                this.LockMotivation(motivation?.Description);
            } else {
                this.LockMotivation(undefined);
            }

            this.CustomerUrl(this.customersService.GetCustomerUrl(document.Document.FKCustomer));
        } else {
            let customer: ICustomer = null;
            if (document.Document.FKRecipient)
                customer = await this.customersService.getCustomerById(document.Document.FKRecipient);

            this.CustomerCache = customer;
            this.Id(document.Document.FKRecipient);
            this.LegalPerson(document.Details.RecipientLegalPerson);
            this.BusinessName(document.Details.RecipientBusinessName);
            this.Name(document.Details.RecipientName);
            this.Surname(document.Details.RecipientSurname);
            this.Address(document.Details.RecipientAddress);
            this.CAP(document.Details.RecipientPostalCode);
            this.City(document.Details.RecipientCity);
            this.Municipality(document.Details.RecipientMunicipality);
            this.NationsProvider.SelectedNationCode(document.Details.RecipientState);
            this.ProvincesProvider.SelectedNationCode(document.Details.RecipientState);
            this.ProvincesProvider.SelectedProvinceCode(document.Details.RecipientProvince);
            this.VATCode(document.Details.RecipientVATNumber);
            this.TAXCode(document.Details.RecipientTAXNumber);
            this.EORICode(document.Details.RecipientEORICode);

            this.IsLocked(false);
            this.LockMotivation(undefined);

            this.CustomerUrl(this.customersService.GetCustomerUrl(document.Document.FKRecipient));
        }

        this.preventLoad = false;
    }

    async loadFromWarehouseId(value: number) {
        if (!value) return;

        const w = await this.warehousesService.getWarehouseById(value);
        if (w.IsDefault) {
            await this.loadFromCustomerId(null);
            return;
        }

        if (!w.CustomerId) {
            this.preventLoad = true;
            this.Id(null);
            this.preventLoad = false;
        }

        await this.loadFromCustomerId(w.CustomerId);

        if (!w.CustomerId) {
            this.LegalPerson(true);
            this.BusinessName(this.company.get().RagioneSociale);
        }

        this.BusinessName(this.BusinessName() + " - " + w.Name);
        this.Address(w.Address || "");
        this.CAP(w.CAP || "");
        this.City(w.City || "");
        this.Municipality(w.State || "");
    }
}

export interface IDocumentCustomerParams {
    Customer: Param<DocumentRecipientInfo>;
    ReadOnly: Param<boolean>;
    IsSourceForCopy: Param<boolean>;
    ForSuppliers: Param<boolean>;
    ForCustomers: Param<boolean>;
    ShowOptions: Param<boolean>;
    FilerJobOrders: Param<boolean>;
    ShowAllCustomers: Param<boolean>;
    CollapsedWhenEmpty: Param<boolean>;
    Label: Param<string>;
    Placeholder: Param<string>;
}

export class DocumentCustomer {
    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IInvoicesService>())
    private invoiceService: IInvoicesService;

    Customer: ComponentParam<DocumentRecipientInfo>;
    ReadOnly: ComponentParam<boolean>;
    IsSourceForCopy: ComponentParam<boolean>;
    ForSuppliers: ComponentParam<boolean>;
    ForCustomers: ComponentParam<boolean>;
    ShowOptions: ComponentParam<boolean>;
    FilterJobOrders: ComponentParam<boolean>;
    ShowAllCustomers: ComponentParam<boolean>;
    CollapsedWhenEmpty: ComponentParam<boolean>;
    Label: ComponentParam<string>;
    Placeholder: ComponentParam<string>;

    constructor(params: IDocumentCustomerParams) {
        this.Customer = ComponentUtils.parseParameter(params.Customer, null);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.IsSourceForCopy = ComponentUtils.parseParameter(params.IsSourceForCopy, false);
        this.ForSuppliers = ComponentUtils.parseParameter(params.ForSuppliers, false);
        this.ForCustomers = ComponentUtils.parseParameter(params.ForCustomers, false);
        this.ShowOptions = ComponentUtils.parseParameter(params.ShowOptions, false);
        this.FilterJobOrders = ComponentUtils.parseParameter(params.FilerJobOrders, false);
        this.ShowAllCustomers = ComponentUtils.parseParameter(params.ShowAllCustomers, false);
        this.CollapsedWhenEmpty = ComponentUtils.parseParameter(params.CollapsedWhenEmpty, false);
        this.Label = ComponentUtils.parseParameter(params.Label, "");
        this.Placeholder = ComponentUtils.parseParameter(
            params.Placeholder,
            TextResources.Invoices.DocumentSearchCustomerPlaceholder
        );

        if (!this.Customer()) throw "Impossibile creare un DocumentCustomer senza un Customer";
    }

    createNewCustomer() {
        this.customersService.ui.showNewCustomerDialog();
    }

    async verifyVat(): Promise<void> {
        const customer = this.Customer();
        let vatCode = customer.VATCode();
        if (!vatCode) {
            this.infoToastService.Warning(TextResources.Customers.NoVAT);
            return;
        }

        var countryCode = "IT";
        if (vatCode.charAt(0).match(/[a-z]/i) && vatCode.charAt(1).match(/[a-z]/i)) {
            countryCode = vatCode.substr(0, 2);
            vatCode = vatCode.substr(2);
        }

        try {
            const result = await this.invoiceService.VerifyVat(countryCode, vatCode);

            customer.BusinessName(result.Name);
            customer.Surname(result.Name); // TODO da rivedere (non so cosa mettere nel nome)
            customer.Address(result.Address);
        } catch (e) {
            const exception = e as IException;
            if (exception.ExceptionMessage == "SERVICE_UNAVAILABLE")
                this.infoToastService.Error(TextResources.Invoices.VatServiceUnavailable);
            else if (exception.ExceptionMessage == "javax.xml.rpc.soap.SOAPFaultException: IP_BLOCKED")
                this.infoToastService.Warning(TextResources.Invoices.VatServicePleaseRetry);
        }
    }
}

const { classes } = jss
    .createStyleSheet({
        documentCustomer: {
            display: "initial",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
            marginBottom: "10px",
            position: "relative",
            paddingBottom: "5px",
            minWidth: "10cm",

            "&.customer-is-locked": {
                backgroundColor: "#ffdddd",
            },

            // rimosso il 24/06/2021 perché lo specchietto del cliente rimane troppo grande in sola lettura del documento e occupa spazio inutilmente (richiesta di Andrea)
            // aggiunta un'altezza minima di 4cm al document-header per evitare che il body collassasse troppo sull'header

            /* "&.expanded": {
            minHeight: "4.7cm",
        }, */

            '& > input[type="checkbox"]': {
                position: "absolute",
                left: "-6px",
                top: "0px",
                width: "11px",
                height: "11px",
                display: "block",
                zIndex: 2,
                backgroundColor: "white",
                border: "1px solid red",
                appearance: "none",

                "&:checked:after": {
                    content: "'✔'",
                    width: "15px",
                    height: "15px",
                    color: "#000",
                    position: "absolute",
                    top: "-10px",
                    fontSize: "1.4em",
                },
            },

            "&::before": {
                position: "absolute",
                left: "-6px",
                backgroundColor: "red",
                width: "11px",
                height: "11px",
                display: "block",
                content: "''",
                marginTop: "0px",
            },

            "& .label": {
                fontWeight: "bold",
            },
        },
    })
    .attach();

function CustomerNonReadOnly() {
    let cust: DocumentCustomer;
    let c: DocumentRecipientInfo;
    let p: ProvincesProvider;

    return (
        <div
            class={classes.documentCustomer + " flex-container"}
            data-bind={{
                with: cust.Customer,
                as: "c",
                css: {
                    expanded: cust.Customer().HasValue() || !cust.CollapsedWhenEmpty(),
                    "customer-is-locked": cust.Customer().IsLocked,
                },
            }}
        >
            <div class="flex-container flex-vertical margin-left-5">
                <div class="label" style="color:black" data-bind={{ text: cust.Label }}></div>

                <div class="flex-container flex-vertical" style="margin-top: auto; width: 40px">
                    <ko-bind data-bind={{ if: c.HasValue() || !cust.CollapsedWhenEmpty() }}>
                        <ko-bind data-bind={{ if: c.IsLocked }}>
                            <span
                                className="btn btn-icon-only btn-circle grey-cascade"
                                data-bind={{ attr: { title: c.LockMotivation } }}
                                style={{ width: "34px" }}
                            >
                                <i className="fa fa-lock"></i>
                            </span>
                        </ko-bind>

                        <a
                            class="btn btn-primary new-customer-btn"
                            href="#"
                            data-bind={{ click: cust.createNewCustomer.bind(cust) }}
                        >
                            <i class="fa fa-plus"></i>
                        </a>

                        <ko-bind data-bind={{ if: !!c.Id() }}>
                            <a
                                class="btn btn-primary show-details-btn"
                                target="_blank"
                                data-bind={{ attr: { href: c.CustomerUrl } }}
                            >
                                <i class="fa fa-search-plus"></i>
                            </a>
                        </ko-bind>
                    </ko-bind>
                </div>
            </div>
            <div class="flex-container flex-vertical no-gutters flex-1">
                <div class="flex-container">
                    <ko-bind data-bind={{ if: c.isDestinationRecipient }}>
                        <ko-bind data-bind={{ if: c.LegalPerson }}>
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{
                                    recipientValue: c.Id,
                                    recipientValueSetter: c.loadFromCustomerIdAndOUId.bind(c),
                                    value: c.BusinessName,
                                    attr: { placeholder: cust.Placeholder },
                                }}
                            />
                        </ko-bind>
                        <ko-bind data-bind={{ ifnot: c.LegalPerson }}>
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{
                                    recipientValue: c.Id,
                                    recipientValueSetter: c.loadFromCustomerIdAndOUId.bind(c),
                                    value: c.Name,
                                    attr: { placeholder: cust.Placeholder },
                                }}
                            />
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{ value: c.Surname }}
                            />
                        </ko-bind>
                    </ko-bind>
                    <ko-bind data-bind={{ ifnot: c.isDestinationRecipient }}>
                        <ko-bind data-bind={{ if: c.LegalPerson }}>
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{
                                    contactsValue: c.Id,
                                    value: c.BusinessName,
                                    forSuppliers: cust.ForSuppliers,
                                    forCustomers: cust.ForCustomers,
                                    showAllCustomers: cust.ShowAllCustomers,
                                    attr: { placeholder: cust.Placeholder },
                                }}
                            />
                        </ko-bind>
                        <ko-bind data-bind={{ ifnot: c.LegalPerson }}>
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{
                                    contactsValue: c.Id,
                                    value: c.Name,
                                    forSuppliers: cust.ForSuppliers,
                                    forCustomers: cust.ForCustomers,
                                    showAllCustomers: cust.ShowAllCustomers,
                                    alternativeDisplayKey: "Nome",
                                    attr: { placeholder: cust.Placeholder },
                                }}
                            />
                            <input
                                autoComplete="off"
                                type="text"
                                class="document-control"
                                data-bind={{ value: c.Surname }}
                            />
                        </ko-bind>
                    </ko-bind>
                </div>
                <ko-bind data-bind={{ if: c.HasValue() || !cust.CollapsedWhenEmpty() }}>
                    <div class="flex-container">
                        <input
                            autoComplete="off"
                            type="text"
                            class="document-control"
                            data-bind={{ value: c.Address }}
                        />
                    </div>
                    <div class="flex-container flex-child-center">
                        <input
                            autoComplete="off"
                            maxLength={10}
                            type="text"
                            style="width: 45px"
                            class="document-control"
                            data-bind={{ value: c.CAP }}
                        />
                        <span>&nbsp;-&nbsp;</span>
                        <input autoComplete="off" type="text" class="document-control" data-bind={{ value: c.City }} />
                    </div>
                    <div class="flex-container">
                        <input
                            autoComplete="off"
                            type="text"
                            class="document-control"
                            data-bind={{ value: c.Municipality }}
                        />
                        <span style="width: 45px" data-bind={{ with: c.ProvincesProvider, as: "p" }}>
                            <ko-bind data-bind={{ if: p.SelectedNationCode() != "IT" }}>
                                <input
                                    autoComplete="off"
                                    style="width: 100%;"
                                    type="text"
                                    class="document-control"
                                    data-bind={{ value: p.SelectedProvinceCode }}
                                />
                            </ko-bind>
                            <ko-bind data-bind={{ if: p.SelectedNationCode() == "IT" }}>
                                <input
                                    type="hidden"
                                    class="document-control"
                                    data-bind={`select2: { value: p.SelectedProvinceCode, query: p.Search.bind(p), initSelection: p.Init.bind(p), allowClear: true, placeholder: '${TextResources.Invoices.DocumentSelectProvince}', multiple: false, minimumInputLength: 1, formatResult: p.GetFormattedNameForList.bind(p), formatSelection: p.GetFormattedCodeForSelection.bind(p), escapeMarkup: function (m) { return m; }, dropdownCss: { width: '200px' }, containerCssClass: 'select2-container-for-documents' }`}
                                />
                            </ko-bind>
                        </span>
                        <span style="width: 45px" data-bind={{ with: c.NationsProvider, as: "n" }}>
                            <input
                                type="hidden"
                                class="document-control"
                                data-bind={`select2: { value: n.SelectedNationCode, query: n.Search.bind(n), initSelection: n.Init.bind(n), allowClear: true, placeholder: '${TextResources.Invoices.DocumentSelectNation}', multiple: false, minimumInputLength: 1, formatResult: n.GetFormattedNameForList.bind(n), formatSelection: n.GetFormattedCodeForSelection.bind(n), escapeMarkup: function (m) { return m; }, dropdownCss: { width: '200px' }, containerCssClass: 'select2-container-for-documents' }`}
                            />
                        </span>
                    </div>
                    <div class="flex-container flex-child-center">
                        <b
                            data-bind={{ asyncClick: cust.verifyVat.bind(cust) }}
                            style="color: #4B8DF8; cursor: pointer"
                        >
                            {TextResources.Invoices.DocumentVATCode}
                        </b>
                        <input
                            autoComplete="off"
                            class="document-control"
                            type="text"
                            style="width: 40%;"
                            data-bind={{ upperCaseValue: c.VATCode, valueUpdate: "afterkeydown" }}
                        />
                        <b>{TextResources.Invoices.DocumentTAXCode}</b>
                        <input
                            autoComplete="off"
                            class="document-control"
                            type="text"
                            style="width: 40%"
                            data-bind={{ upperCaseValue: c.TAXCode, valueUpdate: "afterkeydown" }}
                        />
                    </div>
                </ko-bind>
                <div class="flex-container flex-child-center" style="height: 30px">
                    <div class="radio-list">
                        <label class="radio-inline" style="font-size: 12px">
                            <div class="radio">
                                <span data-bind={{ css: { checked: !c.LegalPerson() } }}>
                                    <input
                                        type="radio"
                                        name="legalPersonRadios"
                                        value="0"
                                        data-bind={{
                                            checked: !c.LegalPerson(),
                                            click: c.setLegalPerson.bind(c, false),
                                        }}
                                    ></input>
                                </span>
                            </div>{" "}
                            {TextResources.Invoices.DocumentPhysicalPerson}
                        </label>
                        <label class="radio-inline" style="font-size: 12px">
                            <div class="radio">
                                <span data-bind={{ css: { checked: !!c.LegalPerson() } }}>
                                    <input
                                        type="radio"
                                        name="legalPersonRadios"
                                        value="1"
                                        data-bind={{
                                            checked: !!c.LegalPerson(),
                                            click: c.setLegalPerson.bind(c, true),
                                        }}
                                    ></input>
                                </span>
                            </div>{" "}
                            {TextResources.Invoices.DocumentLegalPerson}
                        </label>
                    </div>
                </div>
                <ko-bind data-bind={{ if: cust.ShowOptions }}>
                    <div class="flex-container flex-child-center">
                        <div class="checker">
                            <span data-bind={{ css: { checked: cust.FilterJobOrders } }}>
                                <input type="checkbox" data-bind={{ checked: cust.FilterJobOrders }} />
                            </span>
                        </div>
                        {TextResources.Invoices.DocumentFilterJobOrders}
                    </div>
                    <div class="flex-container flex-child-center">
                        <div class="checker">
                            <span data-bind={{ css: { checked: cust.ShowAllCustomers } }}>
                                <input type="checkbox" data-bind={{ checked: cust.ShowAllCustomers }} />
                            </span>
                        </div>
                        {TextResources.Invoices.DocumentShowAllCustomers}
                    </div>
                </ko-bind>
            </div>
        </div>
    );
}

function CustomerReadOnly() {
    let cust: DocumentCustomer;
    let c: DocumentRecipientInfo;
    let p: ProvincesProvider;
    let n: NationsProvider;

    return (
        <div
            class={classes.documentCustomer + " flex-container"}
            data-bind={{
                with: cust.Customer,
                as: "c",
                css: {
                    expanded: cust.Customer().HasValue() || !cust.CollapsedWhenEmpty(),
                    "customer-is-locked": cust.Customer().IsLocked,
                },
            }}
        >
            {/* Selezione cliente per copia documento */}
            <input type="checkbox" data-bind={{ visible: cust.IsSourceForCopy, checked: c.Selected }} />

            <div class="flex-container flex-vertical margin-left-5">
                <div class="label" style="color:black" data-bind={{ text: cust.Label }}></div>

                <div class="flex-container flex-vertical" style="margin-top: auto; width: 40px">
                    <ko-bind data-bind={{ if: c.HasValue() || !cust.CollapsedWhenEmpty() }}>
                        <ko-bind data-bind={{ if: !!c.Id() }}>
                            <a
                                class="btn btn-primary show-details-btn"
                                target="_blank"
                                data-bind={{ attr: { href: c.CustomerUrl } }}
                            >
                                <i class="fa fa-search-plus"></i>
                            </a>
                        </ko-bind>
                    </ko-bind>
                </div>
            </div>
            <div class="flex-container flex-vertical no-gutters flex-1">
                <div class="flex-container">
                    <ko-bind data-bind={{ if: c.LegalPerson }}>
                        <span class="document-control" data-bind={{ text: c.BusinessName() || "IDEM" }}></span>
                    </ko-bind>
                    <ko-bind data-bind={{ ifnot: c.LegalPerson }}>
                        <span class="document-control" data-bind={{ text: c.Name }}></span>
                        <span class="document-control" data-bind={{ text: c.Surname }}></span>
                    </ko-bind>
                </div>
                <ko-bind data-bind={{ if: c.HasValue() || !cust.CollapsedWhenEmpty() }}>
                    <div class="flex-container">
                        <span class="document-control" data-bind={{ text: c.Address }}></span>
                    </div>
                    <div class="flex-container flex-child-center">
                        <span class="document-control" style="width: 45px" data-bind={{ text: c.CAP }}></span>
                        <span class="document-control" data-bind={{ text: c.City }}></span>
                    </div>
                    <div class="flex-container">
                        <span class="document-control" data-bind={{ text: c.Municipality }}></span>
                        <span style="width: 45px" data-bind={{ with: c.ProvincesProvider, as: "p" }}>
                            <span class="document-control" data-bind={{ text: p.SelectedProvinceCode }}></span>
                        </span>
                        <span style="width: 45px" data-bind={{ with: c.NationsProvider, as: "n" }}>
                            <span class="document-control" data-bind={{ text: n.SelectedNationCode }}></span>
                        </span>
                    </div>
                    <div class="flex-container flex-child-center">
                        <b>{TextResources.Invoices.DocumentVATCode}</b>
                        <span class="document-control" style="width: 40%" data-bind={{ text: c.VATCode }}></span>
                        <b>{TextResources.Invoices.DocumentTAXCode}</b>
                        <span class="document-control" style="width: 40%" data-bind={{ text: c.TAXCode }}></span>
                    </div>
                </ko-bind>
            </div>
        </div>
    );
}

ko.components.register("document-customer", {
    viewModel: {
        createViewModel: (params: IDocumentCustomerParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new DocumentCustomer(params);
            let $data: any;
            let cust: DocumentCustomer;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <ko-bind data-bind={{ with: $data, as: "cust" }}>
                    <ko-bind data-bind={{ if: cust.ReadOnly }}>
                        <CustomerReadOnly></CustomerReadOnly>
                    </ko-bind>
                    <ko-bind data-bind={{ ifnot: cust.ReadOnly }}>
                        <CustomerNonReadOnly></CustomerNonReadOnly>
                    </ko-bind>
                </ko-bind>,
            ]);

            return vm;
        },
    },
    template: [],
});
