import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DetectChanges, DetectClassChanges } from "../../../../Core/ChangeDetection";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../../../DataSources/JobOrdersDataSource";
import { TasksDataSource, ITasksDataSourceModel } from "../../../../DataSources/TasksDataSource";
import { ITimePickerComponent } from "../../../../Components/TimePickerComponent";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ResourceRoleAndCategorySelector } from "../ResourceRoleAndCategorySelector";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IWorkedHoursRowForEditing } from "../../../interfaces/IWorkedHoursEditor";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService, ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IWorkedHoursService,
    IValidRole,
    IValidRolesForServiceOrder,
    IValidWorkTimeCategory,
    IWorkedHoursForEditing,
    IWorkPlace,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { Right } from "../../../../Core/Authorizations";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";

export interface IWorkedHoursRowForEditingListener {
    onServiceOrderIdChanged(serviceOrderId: number);
}

@DetectClassChanges
export class WorkedHoursRowForEditing implements IWorkedHoursRowForEditing, IDataSourceListener {
    private listeners: IWorkedHoursRowForEditingListener[] = [];

    public isChanged: ko.Observable<number> = ko.observable(0);

    @LazyImport(nameof<IInfoToastService>())
    toastService: IInfoToastService;
    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IJobOrderService>())
    jobOrderService: IJobOrderService;
    @LazyImport(nameof<IBlogService>())
    blogService: IBlogService;
    @LazyImport(nameof<IAuthorizationService>())
    authorizationService: IAuthorizationService;
    @LazyImport(nameof<ISettingsService>())
    settingsService: ISettingsService;
    @LazyImport(nameof<ITodoListService>())
    todoListService: ITodoListService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesManager: IHumanResourcesSettingsManager;

    public WorkPlaces: ko.ObservableArray<IWorkPlace> = ko.observableArray([]);
    public WorkTimeCategories: ko.ObservableArray<IWorkTimeCategory> = ko.observableArray([]);

    public ValidRoles: ko.ObservableArray<IValidRole> = ko.observableArray();
    public ServiceOrders: ko.ObservableArray<IValidRolesForServiceOrder> = ko.observableArray();
    public ServiceOrderId: ko.Observable<number> = ko.observable();
    public ValidWorkTimeCategories: ko.ObservableArray<IValidWorkTimeCategory> = ko.observableArray();
    public ShowServiceOrders: ko.Computed<boolean>;

    //Campi
    public HoursId: number = null;
    public ResourceId: ko.Observable<number> = ko.observable();
    public ResourceName: ko.Observable<string> = ko.observable();

    @DetectChanges
    public WorkDate: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public WorkDateTimezone: ko.Observable<string> = ko.observable();
    @DetectChanges
    public JobOrderId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public TaskId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public WorkPlaceId: ko.Observable<number> = ko.observable(0);
    public WorkPlaceDescr: ko.Observable<string> = ko.observable();
    @DetectChanges
    public RoleId: ko.Observable<number> = ko.observable();
    public RoleDescr: ko.Observable<string> = ko.observable();
    @DetectChanges
    public WorkTimeCategoryId: ko.Observable<number> = ko.observable();
    public WorkTimeCategoryDescr: ko.Observable<string> = ko.observable();
    @DetectChanges
    public CallRight: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public BreakHours: ko.Observable<number> = ko.observable(0);
    @DetectChanges
    public TravelDistance: ko.Observable<number> = ko.observable(0);
    @DetectChanges
    public Billable: ko.Observable<boolean> = ko.observable(true);
    @DetectChanges
    public Progress: ko.Observable<number> = ko.observable(0);
    public ProgressInHours: ko.Observable<number> = ko.observable(0);
    public WorkedHours: ko.Observable<number> = ko.observable(0);
    @DetectChanges
    public EstimatedWork: ko.Observable<number> = ko.observable(0);
    public OrdinaryHours: ko.Observable<number> = ko.observable(0);
    public OriginalOrdinaryHours: ko.Observable<number> = ko.observable(0);
    public OriginalProgress: ko.Observable<number> = ko.observable(0);
    public OriginalEstimatedWork: ko.Observable<number> = ko.observable(0);
    public WorkedHoursMinusOriginal: ko.Computed<number>;
    @DetectChanges
    public Notes: ko.Observable<string> = ko.observable();
    public ActivityProgressMode: ko.Observable<number> = ko.observable(0);
    @DetectChanges
    public EndTime: ko.Observable<Date> = ko.observable();
    @DetectChanges
    public EndTimeTimezone: ko.Observable<string> = ko.observable();

    public HideAdministrativeData: ko.Observable<boolean> = ko.observable(false);
    public ActivityHasDefaultRolesSpecified: ko.Observable<boolean> = ko.observable(false);
    public ActivityHasDefaultWorkTimeCategoriesSpecified: ko.Observable<boolean> = ko.observable(false);

    public RenderRolesSelector: ko.Computed<boolean>;
    public RenderWorktimeCategoriesSelector: ko.Computed<boolean>;

    public HasChanges: ko.Computed<boolean>;
    public ProgressFieldDisabled: ko.Computed<boolean>;
    public EstimatedWorkFieldDisabled: ko.Computed<boolean>;
    //Flags
    public CanSetProgressInHours: ko.Computed<boolean>;
    public CanEditEstimate: ko.Observable<boolean> = ko.observable(false); //DEPRECATO
    public IsTaskClosed: ko.Observable<boolean> = ko.observable(false);

    public ApprovalState: ko.Observable<HoursApprovalState> = ko.observable();

    public JobOrderHasFocus: ko.Observable<boolean> = ko.observable(false);
    public HoursHasFocus: ko.Observable<boolean> = ko.observable(false);

    public IsReadOnly: ko.Computed<boolean>;

    private IsBusinessManagement: ko.Observable<boolean> = ko.observable(false);
    private isMonthLocked: ko.Observable<boolean> = ko.observable(false);
    private originalProgress: ko.Observable<number> = ko.observable();
    private resource: IHumanResource;
    private initializing = false;
    private isInitialized: ko.Observable<boolean> = ko.observable(false);

    public isInsertFlexibility: ko.Observable<boolean> = ko.observable(false);

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesService: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager: IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettings: IJobOrderGeneralSettingsManager;

    private External = false;

    private lastValidWorkDate: Date;
    private lastValidEndTime: Date;
    private changingDates = false;
    private changingHours = false;
    private changingProgress = false;
    private changingProgressInHours = false;

    //Components

    public StartTimeComponent: ko.Observable<ITimePickerComponent> = ko.observable();
    public EndTimeComponent: ko.Observable<ITimePickerComponent> = ko.observable();

    //Data Sources
    public JobOrderDataSource: JobOrdersDataSource;
    public TasksDataSource: TasksDataSource;

    private selectedJobOrder: ko.Observable<IJobOrderDataSourceModel> = ko.observable();
    private selectedTask: ko.Observable<ITasksDataSourceModel> = ko.observable();

    private selectedTaskModel: ko.Computed<ITaskForTaskBoard>;

    public SelectedJobOrderChanges: ko.Observable<number> = ko.observable(0);
    public SelectedTaskChanges: ko.Observable<number> = ko.observable(0);

    // Mobile
    public WillSeeDetails: ko.Observable<boolean> = ko.observable(false);

    public OverEstimate: ko.Computed<boolean>;

    private lastOrdinaryHours = 0;

    @Right("WorkedHours_viewEditBillableAndCallRightFlag")
    private _canViewOrEditBillableAndCallRightFlag: boolean;
    @Right("WorkedHours_viewEditRole")
    private _canViewOrEditRole: boolean;
    @Right("WorkedHours_viewEditWorkTimeCategory")
    private _canViewOrEditWorkTimeCategory: boolean;
    @Right("WorkedHours_viewEditWorkPlace")
    private _canViewOrEditWorkPlace: boolean;
    @Right("WorkedHours_viewEditTravelDistance")
    private _canViewOrEditTravelDistance: boolean;

    public get canViewOrEditBillableAndCallRightFlag(): boolean {
        return this._canViewOrEditBillableAndCallRightFlag;
    }

    public get canViewOrEditRole(): boolean {
        return this._canViewOrEditRole;
    }

    public get canViewOrEditWorkTimeCategory(): boolean {
        return this._canViewOrEditWorkTimeCategory;
    }

    public get canViewOrEditWorkPlace(): boolean {
        return this._canViewOrEditWorkPlace;
    }

    public get canViewOrEditTravelDistance(): boolean {
        return this._canViewOrEditTravelDistance;
    }

    setJobOrderAndBillableForFlexibility(): void {
        this.JobOrderId(this.generalSettings.getDefaultFlexibilityJobOrder());
        this.Billable(false);
    }

    setSuggestedFlexibleHours(): void {
        this.workedHoursService.GetSuggestedFlexibilityHours(this.WorkDate(), this.ServiceOrderId()).then((sug) => {
            this.OrdinaryHours(sug);
        });
    }

    public RoleSelectionStatusMessage: ko.Computed<string>;
    public WorkTimeCategorySelectionStatusMessage: ko.Computed<string>;

    constructor(private workedHours: IWorkedHoursForEditing, private insertPerJobOrder: boolean = false) {
        this.JobOrderDataSource = new JobOrdersDataSource();
        this.TasksDataSource = new TasksDataSource();
        this.TasksDataSource.setGetTasksIfNoFilter(true);
        this.TasksDataSource.setGetWorkflowsInWorkflows(false);
        this.TasksDataSource.setShowWorkflowSubtitle(true);
        this.TasksDataSource.setSearchOnWorkflowTitle(true);

        this.JobOrderDataSource.setWorkFilters(true);

        this.setResourceId(workedHours.ResourceId);

        this.selectedJobOrder.subscribe(() => {
            this.SelectedJobOrderChanges(this.SelectedJobOrderChanges() + 1);
        });

        this.ServiceOrderId.subscribe((newServiceOrder: number) => {
            this.listeners.forEach((listener) => {
                listener.onServiceOrderIdChanged(newServiceOrder);
            });
        });

        this.selectedTask.subscribe(() => {
            this.SelectedTaskChanges(this.SelectedTaskChanges() + 1);
        });

        this.selectedTaskModel = ko.computed(() => {
            const task = this.selectedTask();
            return task?.model;
        });

        this.RenderRolesSelector = ko.computed(() => {
            return (
                (!this.HideAdministrativeData() || !this.ActivityHasDefaultRolesSpecified()) && this.canViewOrEditRole
            );
        });

        this.RenderWorktimeCategoriesSelector = ko.computed(() => {
            return (
                (!this.HideAdministrativeData() || !this.ActivityHasDefaultWorkTimeCategoriesSpecified()) &&
                this.canViewOrEditWorkTimeCategory
            );
        });

        this.loadWorkPlaces();

        this.OrdinaryHours.subscribe((value: number) => {
            if (!this.WorkDate() || this.changingHours || this.lastOrdinaryHours === value) return;

            this.changingHours = true;

            let workedHours = value || 0;
            if (this.isInsertFlexibility()) {
                workedHours = Math.abs(workedHours);
            }
            this.lastOrdinaryHours = value;
            const breakHours = this.BreakHours() || 0;
            const workDate = this.WorkDate();
            const endTime = moment(workDate).add(workedHours, "hours").add(breakHours, "hours");
            this.EndTime(endTime.toDate());

            this.calculateProgressAndProgressInHoursOnWorkedHoursOrEstimatedWorkChanges();

            this.changingHours = false;
        });

        this.EstimatedWork.subscribe((value: number) => {
            this.calculateProgressAndProgressInHoursOnWorkedHoursOrEstimatedWorkChanges();
        });

        this.Progress.subscribe((value: number) => {
            if (this.changingProgress || this.changingProgressInHours || this.initializing) return;

            this.changingProgress = true;

            let progressInHours = this.EstimatedWork();
            if (value == 100) progressInHours = 0;
            else if (value > 0) {
                const totalWork = this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours();
                progressInHours = (totalWork * (100 - value)) / value;
            }

            this.ProgressInHours(progressInHours);

            this.changingProgress = false;
        });

        this.ProgressInHours.subscribe((value: number) => {
            if (this.changingProgressInHours || this.changingProgress || this.initializing) return;

            this.changingProgressInHours = true;

            const totalWork = this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours();
            const estimatedWork = totalWork + value;

            const progress = Math.min((totalWork / estimatedWork) * 100, 99);
            this.Progress(progress);

            this.changingProgressInHours = false;
        });

        this.BreakHours.subscribe((value: number) => {
            if (!this.WorkDate() || this.changingHours) return;

            this.changingHours = true;

            const startTime = moment(this.WorkDate()).milliseconds(0);
            const breakHours = value || 0;
            const endTime = !this.EndTime() ? moment(startTime) : moment(this.EndTime()).milliseconds(0);
            const workedHours = endTime.diff(startTime, "seconds") / 3600 - breakHours;

            if (workedHours < 0) this.EndTime(startTime.add(breakHours, "hours").toDate());
            else this.OrdinaryHours(workedHours);

            this.changingHours = false;
        });

        this.WorkDate.subscribe((newDate: Date) => {
            if (this.initializing || this.changingDates) {
                return;
            }

            this.changingDates = true;

            if (!newDate) {
                this.WorkDate(this.lastValidWorkDate);
                this.changingDates = false;
                return;
            }

            const startTime = moment(newDate).milliseconds(0);
            const breakHours = this.BreakHours() || 0;
            let workedHours = this.OrdinaryHours() || 0;
            if (this.isInsertFlexibility()) {
                workedHours = Math.abs(workedHours);
            }

            this.EndTime(startTime.add(breakHours + workedHours, "hours").toDate());
            this.lastValidWorkDate = newDate;

            if (this.StartTimeComponent()) this.StartTimeComponent().setReferenceDate(newDate);

            if (this.EndTimeComponent()) this.EndTimeComponent().setReferenceDate(newDate);

            this.changingDates = false;
        });

        this.EndTime.subscribe((newDate: Date) => {
            if (this.initializing || this.changingDates) {
                return;
            }

            this.changingDates = true;
            const startTime = moment(this.WorkDate()).milliseconds(0);
            const endTime = moment(newDate).milliseconds(0);

            if (endTime < startTime) {
                this.EndTime(this.lastValidEndTime);
                this.changingDates = false;
                return;
            }

            const breakHours = this.BreakHours() || 0;
            const calculatedWorkedHours = endTime.diff(startTime, "seconds") / 3600 - breakHours;
            if (calculatedWorkedHours >= 0) this.OrdinaryHours(calculatedWorkedHours);
            else {
                this.OrdinaryHours(0);
                this.BreakHours(calculatedWorkedHours);
            }

            this.lastValidEndTime = newDate;

            this.changingDates = false;
        });

        this.WorkTimeCategoryId.subscribe((id) => {
            if (this.initializing || !id) return;
            const c: IWorkTimeCategory = this.workTimeCategoriesManager.getById(id);
            this.WorkTimeCategoryDescr(!c ? null : c.Name);
        });

        this.WorkPlaceId.subscribe((id: number) => {
            const idMatch = this.WorkPlaces().filter((w) => w.Id == id);

            if (idMatch.length > 0) this.WorkPlaceDescr(idMatch[0].Description);
            else this.WorkPlaceDescr(null);
        });

        this.RoleId.subscribe((id) => {
            if (this.initializing || !id) return;

            this.setRoleDescription(id);
        });

        this.CanSetProgressInHours = ko.computed(() => {
            return this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours() == 0;
        });

        this.WorkedHoursMinusOriginal = ko.computed(() => {
            return this.WorkedHours() - this.OriginalOrdinaryHours();
        });

        this.IsReadOnly = ko.computed(() => {
            return (
                this.ApprovalState() === HoursApprovalState.Approved ||
                this.ApprovalState() === HoursApprovalState.PartiallyApproved ||
                this.isMonthLocked() ||
                this.IsBusinessManagement() ||
                !this.canEditHour()
            );
        });

        this.JobOrderId.subscribe((value) => {
            this.TasksDataSource.setJobOrderId(value);

            if (!value || value <= 0) {
                this.RoleId(null);
                this.RoleDescr(null);
                this.WorkTimeCategoryId(null);
                this.WorkTimeCategoryDescr(null);

                this.selectedJobOrder(null);
            }
        });

        this.TaskId.subscribe((value) => {
            if (!value || value <= 0) this.selectedTask(null);
        });

        this.HasChanges = ko.computed(() => {
            return this.isInitialized() && (this.isChanged() > 0 || this.OrdinaryHours() !== this.lastOrdinaryHours);
        });

        this.ProgressFieldDisabled = ko.computed(() => {
            return this.ActivityProgressMode() != 0 || this.IsReadOnly() || this.IsTaskClosed() || !this.TaskId();
        });

        this.EstimatedWorkFieldDisabled = ko.computed(() => {
            return (
                !this.CanEditEstimate() ||
                this.IsReadOnly() ||
                !this.TaskId() ||
                !this.authorizationService.isAuthorized("JobOrders_EditTaskEstimate")
            );
        });

        this.OverEstimate = ko.computed(() => {
            return (
                this.WorkedHoursMinusOriginal() + this.OrdinaryHours() > this.EstimatedWork() &&
                this.EstimatedWork() > 0
            );
        });

        this.ShowServiceOrders = ko.computed(() => {
            return (this.ServiceOrders() || []).length > 1;
        });

        this.RoleSelectionStatusMessage = ko.computed(() => {
            const roleId = this.RoleId();
            const jobOrderId = this.JobOrderId();
            const taskId = this.TaskId();

            if (!jobOrderId && !taskId) return TextResources.WorkedHours.SelectTaskOrJobOrder;

            return !roleId ? TextResources.WorkedHours.InvalidRole : TextResources.WorkedHours.Autoselection;
        });

        this.WorkTimeCategorySelectionStatusMessage = ko.computed(() => {
            const wtcId = this.WorkTimeCategoryId();
            const jobOrderId = this.JobOrderId();
            const taskId = this.TaskId();

            if (!jobOrderId && !taskId) return TextResources.WorkedHours.SelectTaskOrJobOrder;

            return !wtcId ? TextResources.WorkedHours.InvalidWorkTimeCategory : TextResources.WorkedHours.Autoselection;
        });

        this.loadFromModel(this.workedHours);
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender == this.JobOrderDataSource) {
            const jobOrder = model as IJobOrderDataSourceModel;

            if (!jobOrder || (!!jobOrder && !!this.selectedJobOrder() && jobOrder.id != this.selectedJobOrder().id))
                this.TaskId(null);

            this.selectedJobOrder(jobOrder);
        } else if (sender == this.TasksDataSource) {
            const task = model as ITasksDataSourceModel;
            this.selectedTask(task);
            const taskForTaskboardModel: ITaskForTaskBoard = !task
                ? null
                : !task.model
                ? null
                : (task.model as ITaskForTaskBoard);
            this.loadDataFromSelectedItem(taskForTaskboardModel);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return true;
    }

    public async loadFromModel(workedHours: IWorkedHoursForEditing): Promise<IWorkedHoursRowForEditing> {
        this.initializing = true;
        this.isInitialized(false);

        this.workedHours = workedHours;
        this.HoursId = workedHours.HoursId;

        this.WorkPlaceId(workedHours.WorkPlaceId);
        this.OrdinaryHours(workedHours.Hours || 0);

        this.lastOrdinaryHours = this.OrdinaryHours();

        this.OriginalOrdinaryHours(workedHours.Hours || 0);
        this.Notes(workedHours.Description);
        this.CallRight(workedHours.CallRight);
        this.BreakHours(workedHours.BreakHours);
        this.TravelDistance(workedHours.TravelDistance);
        this.Billable(workedHours.Billable);
        this.Progress(workedHours.Progress);
        this.ProgressInHours(workedHours.RemainingWork);
        this.OriginalProgress(workedHours.Progress);
        this.WorkedHours(workedHours.WorkedHours || 0);
        this.EstimatedWork(workedHours.EstimatedWork || 0);
        this.OriginalEstimatedWork(workedHours.EstimatedWork || 0);
        this.JobOrderId(workedHours.JobOrderId);
        this.TaskId(workedHours.TaskId);
        this.ApprovalState(workedHours.ApprovalState);
        this.ActivityProgressMode(workedHours.ActivityProgressMode || 0);
        this.WorkDate(workedHours.WorkDate);
        this.WorkDateTimezone(workedHours.WorkDateTimezone);
        this.EndTime(workedHours.EndTime);
        this.EndTimeTimezone(workedHours.EndTimeTimezone);
        this.CanEditEstimate(false);
        this.IsBusinessManagement(workedHours.BusinessManagement);
        this.HideAdministrativeData(workedHours.HideAdministrativeData);

        const promises = [];
        promises.push(this.JobOrderDataSource.getById(null, [workedHours.JobOrderId]));
        if (workedHours.TaskId > 0) promises.push(this.TasksDataSource.getById(null, [workedHours.TaskId]));

        const results = await Promise.all(promises);
        this.selectedJobOrder(results[0].firstOrDefault());
        const activity = results[1]?.firstOrDefault() as ITasksDataSourceModel;
        this.selectedTask(activity);

        const selector = new ResourceRoleAndCategorySelector({
            Categories: this.ValidWorkTimeCategories,
            Category: this.WorkTimeCategoryId,
            Date: this.WorkDate,
            JobOrder: this.JobOrderId,
            Resource: this.ResourceId,
            Role: this.RoleId,
            Roles: this.ValidRoles,
            ServiceOrder: this.ServiceOrderId,
            ServiceOrders: this.ServiceOrders,
            Task: this.selectedTaskModel,
            Initializing: () => this.initializing,
            IgnoreFirstRun: (!this.HoursId || this.HoursId < 0) && !this.TaskId() && !this.JobOrderId(), // N.B.: se sono in editing di una riga già salvata nel sistema oppure sono una nuova riga, ma ho già task o commessa impostato, non devo ignorare il primo avvio perché le liste delgi ordini di servizio, mansioni e tipologie orarie le devo caricare
            DelayComputeds: true,
            OnServiceOrdersLoaded: () => {
                if (!this.isInsertFlexibility()) return;

                this.RoleId(this.generalSettings.getDefaultFlexibilityRole());
                this.WorkTimeCategoryId(this.generalSettings.getDefaultFlexibilityWorkTimeCategory());
            },
        });

        if (activity?.model) {
            this.HideAdministrativeData(activity.model.HideAdministrativeFieldsOnWorkedHours);
            this.ActivityHasDefaultRolesSpecified(activity.model.HasDefaultRolesSpecified);
            this.ActivityHasDefaultWorkTimeCategoriesSpecified(activity.model.HasDefaultWorkTimeCategoriesSpecified);
        }

        await selector.load();

        // N.B.: Queste tre impostazioni devono stare dopo la load del selettore degli ordini di servizio, ruoli e tipologie orarie perché
        // il binding handler del select non imposta il valore selezionato se questo viene settato prima del caricamento delle opzioni
        // e vengono impostate solo se la riga è in modifica; se la riga è nuova i valori di default vengono già impostati dal selector in fase di loading
        if (this.HoursId > 0) {
            this.ServiceOrderId(workedHours.FkServiceOrder);
            this.RoleId(workedHours.RoleId);
            this.WorkTimeCategoryId(workedHours.WorkTimeCategoryId);
        }

        this.lastValidWorkDate = workedHours.WorkDate;
        this.lastValidEndTime = workedHours.EndTime;

        this.originalProgress(workedHours.Progress);

        this.isChanged(0);
        this.initializing = false;
        this.isInitialized(true);

        return this;
    }

    AddListener(listener: IWorkedHoursRowForEditingListener) {
        if (this.listeners.indexOf(listener) === -1) {
            this.listeners.push(listener);
        }
    }

    public getData(): IWorkedHoursForEditing {
        const jsonObj: IWorkedHoursForEditing = this.workedHours || {
            HoursId: this.HoursId,
            EntityType: ProlifeSdk.WorkedHoursEntityTypeCode,
            EventId: 0,
            WorkEventId: 0,
            JobOrderId: 0,
            WorkDate: moment(new Date()).startOf("day").toDate(),
            WorkDateTimezone: "",
            Description: null,
            ResourceId: null,
            ResourceName: "",
            RoleId: 0,
            From: null,
            To: null,
            BreakHours: 0,
            Billable: true,
            SalId: null,
            Billed: false,
            ProjectId: 0,
            WorkItemId: 0,
            TaskId: 0,
            ActivityContainerType: null,
            ActivityType: null,
            WorkPlaceId: 0,
            Hours: 0,
            WorkTimeCategoryId: 0,
            TravelDistance: 0,
            CallRight: false,
            BusinessManagement: false,
            IsPreviousMonthImbalance: false,
            Progress: 0,
            WorkedHours: 0,
            EstimatedWork: 0,
            RemainingWork: 0,
            OriginalProgress: 0,
            OriginalWorkedHours: 0,
            OriginalEstimatedWork: 0,
            EndTime: null,
            EndTimeTimezone: "",
            FkServiceOrder: -1,
            CanEditEstimate: false,
        };

        jsonObj.HoursId = this.HoursId;
        jsonObj.JobOrderId = this.JobOrderId();
        jsonObj.JobOrderTitle = !this.JobOrderId()
            ? null
            : this.selectedJobOrder()
            ? this.selectedJobOrder().title
            : this.workedHours.JobOrderTitle;
        jsonObj.WorkflowTitle = !this.TaskId()
            ? null
            : this.selectedTask()
            ? this.selectedTask().subTitle
            : this.workedHours.WorkflowTitle;
        jsonObj.WorkDate = moment.tz(this.WorkDate(), this.WorkDateTimezone()).toDate();
        jsonObj.WorkDateTimezone = this.WorkDateTimezone();
        jsonObj.Description = this.Notes();
        jsonObj.ResourceId = this.ResourceId();
        jsonObj.ResourceName = this.ResourceName();
        jsonObj.RoleId = this.RoleId();
        jsonObj.RoleName = this.RoleDescr();
        jsonObj.ProjectId = null;
        jsonObj.WorkItemId = null;
        jsonObj.TaskId = this.TaskId();
        jsonObj.TaskTitle = !this.TaskId()
            ? null
            : this.selectedTask()
            ? this.selectedTask().title
            : this.workedHours.TaskTitle;
        jsonObj.ActivityContainerType = "JOR";
        jsonObj.ActivityType = this.TaskId() ? ProlifeSdk.JobOrderTaskEntityTypeCode : null;
        jsonObj.WorkPlaceId = this.WorkPlaceId();
        jsonObj.Hours = this.OrdinaryHours();
        jsonObj.WorkTimeCategoryId = this.WorkTimeCategoryId();
        jsonObj.WorkTimeCategoryName = this.WorkTimeCategoryDescr();
        jsonObj.CallRight = this.CallRight();
        jsonObj.BreakHours = this.BreakHours();
        jsonObj.TravelDistance = this.TravelDistance() ?? 0;
        jsonObj.Billable = this.Billable();
        jsonObj.Progress = this.IsTaskClosed() ? 100 : this.Progress();
        jsonObj.WorkedHours = this.WorkedHours();
        jsonObj.EstimatedWork = this.EstimatedWork();
        jsonObj.RemainingWork = this.ProgressInHours();
        jsonObj.OriginalProgress = this.OriginalProgress();
        jsonObj.OriginalWorkedHours = this.OriginalOrdinaryHours();
        jsonObj.OriginalEstimatedWork = this.OriginalEstimatedWork();
        jsonObj.EndTime = moment.tz(this.EndTime(), this.EndTimeTimezone()).toDate();
        jsonObj.EndTimeTimezone = this.EndTimeTimezone();
        jsonObj.FkServiceOrder = this.ServiceOrderId();
        return jsonObj;
    }

    public CloseTask(): void {
        this.Progress(100);
    }

    public validate(): boolean {
        if (!this.JobOrderId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectProjectJobOrder);
            return false;
        }

        if (!this.OrdinaryHours() || Math.abs(this.OrdinaryHours()) > 24) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.InvalidWorkedHours);
            return false;
        }

        if (this.BreakHours() == null || this.BreakHours() == undefined || this.BreakHours() < 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.MissingBreakHours);
            return false;
        }

        if (!this.RoleId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectRole);
            return false;
        }

        if (!this.WorkTimeCategoryId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectWorktimeCategory);
            return false;
        }

        if (!this.WorkDate() || !this.EndTime()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectStartAndEndTime);
            return false;
        }

        return true;
    }

    public setWorkDate(date: Date): void {
        this.WorkDate(date);
    }

    public setEndTime(date: Date): void {
        this.EndTime(date);
    }

    public setWorkDateTimezone(timezone: string): void {
        this.WorkDateTimezone(timezone);
    }

    public setEndDateTimezone(timezone: string): void {
        this.EndTimeTimezone(timezone);
    }

    public setMonthLockingStatus(status: boolean): void {
        this.isMonthLocked(status);
    }

    public showClosedWorkableElements(showClosedJobOrders: boolean, showClosedActivities: boolean): void {
        this.TasksDataSource.setShowClosed(showClosedActivities);
        this.JobOrderDataSource.setShowClosed(showClosedJobOrders);
    }

    public viewAllElements(allJobOrders: boolean, allActivities: boolean): void {
        this.JobOrderDataSource.setViewFilters(true, true, allJobOrders);
        this.TasksDataSource.setViewAll(allActivities);
    }

    public getHoursId(): number {
        return this.HoursId;
    }

    public getJobOrderId(): number {
        return this.JobOrderId();
    }

    public getTaskId(): number {
        return this.TaskId();
    }

    public getRequestedTaskId(): number {
        return this.workedHours?.TaskId;
    }

    public setResourceId(id: number): void {
        this.resource = this.humanResourcesService.getHumanResourceById(id);

        const loggedUserResource = this.humanResourcesService.getLoggedResource();

        const userId = this.insertPerJobOrder ? loggedUserResource?.Resource?.FkUser : this.resource?.Resource?.FkUser;
        const resourceId = this.insertPerJobOrder ? loggedUserResource?.Resource?.Id : this.resource?.Resource?.Id;

        this.ResourceId(this.resource?.Resource?.Id);
        this.ResourceName(this.resource?.Resource?.Name + " " + this.resource?.Resource?.Surname);
        this.TasksDataSource.setUserId(userId);
        this.TasksDataSource.setResourceId(resourceId);
        this.JobOrderDataSource.setUserId(userId);
        this.JobOrderDataSource.setResourceId(resourceId);
    }

    public selectedJobOrderIsClosed(): boolean {
        if (!this.selectedJobOrder()) return false;

        const jobOrderStateSettingsManager: IJobOrderStateSettingsManager = this.settingsService.findSettingsManager(
            ProlifeSdk.JobOrderState
        ) as IJobOrderStateSettingsManager;
        const jobOrderState = jobOrderStateSettingsManager.getJobOrderStateById(this.selectedJobOrder().jobOrderState);

        return jobOrderState && jobOrderState.LogicalState == 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public dispose(): void {
        this.listeners = [];
    }

    /* PER MOBILE */
    public changeSeeDetails() {
        this.WillSeeDetails(!this.WillSeeDetails());
    }

    /* ---------------- */

    /* TODO Funzioni usate per selezione commessa e task. Da spostare su componente apposito */
    public GetIconForCurrentActivity() {
        return "fa-clipboard"; //this.GetActivityTypeIcon(this.WorkActivityType());
    }

    public GoToContainerDetail() {
        if (!this.JobOrderId()) return;

        //Vado a blog dalle righe degli statini di intervento
        if (this.External) this.blogService.openBlogInNewWindow(this.JobOrderId());
        else {
            const url = this.jobOrderService.getJobOrderUrl(this.JobOrderId());
            window.open(url, "_blank");
        }
    }

    public GoToActivityDetail() {
        if (!this.TaskId()) return;

        this.todoListService.ShowEditTaskDialog(this.TaskId());
    }

    public EditContainer() {
        this.EditActivity();
    }

    public EditActivity() {}

    /* ---------------- */

    public canEditHour(): boolean {
        const editAllResourcesRight = this.authorizationService.isAuthorized("WorkedHours_CanEditOtherUsersWorkSheets");
        const resourceOnRow = this.ResourceId();
        const actualResource = this.humanResourcesManager.getHumanResourceByUserId(this.userInfoService.getIdUser());

        return editAllResourcesRight || (actualResource && actualResource.Resource.Id === resourceOnRow);
    }

    private loadWorkPlaces() {
        this.WorkPlaces(this.workedHoursService.getWorkPlaces());
    }

    private loadDataFromSelectedItem(task: ITaskForTaskBoard): void {
        this.WorkedHours(!task ? 0 : task.WorkedHours);
        this.OriginalEstimatedWork(!task ? 0 : task.EstimatedDuration || 0);
        this.Progress(!task ? 0 : task.Progress || 0);
        this.OriginalProgress(!task ? 0 : task.Progress || 0);
        this.ProgressInHours(!task ? 0 : task.RemainingWork || 0);
        this.ActivityProgressMode(!task ? 0 : task.ActivityProgressMode || 0);
        this.Billable(!task ? !this.isInsertFlexibility() : task.ReportingType == 1);
        this.EstimatedWork(!task ? 0 : task.EstimatedDuration || 0);
        this.CanEditEstimate(false);

        this.WorkPlaceId(task?.DefaultWorkPlace ?? this.WorkPlaceId());
        this.TravelDistance(task?.DefaultTravelDistance ?? this.TravelDistance());
        this.CallRight(task?.DefaultCallRight ?? this.CallRight());
        this.HideAdministrativeData(task?.HideAdministrativeFieldsOnWorkedHours ?? false);
        this.ActivityHasDefaultRolesSpecified(task?.HasDefaultRolesSpecified || false);
        this.ActivityHasDefaultWorkTimeCategoriesSpecified(task?.HasDefaultWorkTimeCategoriesSpecified || false);

        this.originalProgress(!task ? 0 : task.Progress || 0);

        if (!this.JobOrderId() && !!task) this.JobOrderId(task.JobOrderId);
    }

    private setRoleDescription(roleId: number) {
        const matches = this.rolesManager.getUserCharactersWithDeleted().filter((r: IUserCharacter) => {
            return r.IdUserCharacter == roleId;
        });

        const c: IUserCharacter = matches.length > 0 ? matches[0] : null;
        this.RoleDescr(!c ? null : c.Description);
    }

    private calculateProgressAndProgressInHoursOnWorkedHoursOrEstimatedWorkChanges(): void {
        if (this.initializing || this.ActivityProgressMode() != 0) return;

        const totalWork = this.WorkedHours() - this.OriginalOrdinaryHours() + this.OrdinaryHours();
        const progressInHours = Math.max(0, this.EstimatedWork() - this.WorkedHours() - this.OrdinaryHours());
        this.ProgressInHours(progressInHours);

        let totalEstimate = 0;
        if (this.EstimatedWork() == 0) {
            //Non ho una stima del task, la stima totale sarà il lavoro stesso, in modo da mandare la percentuale direttamente al 100%
            totalEstimate = totalWork;
        } else {
            //E' presente una stima per il task
            totalEstimate = this.ProgressInHours() + totalWork;
        }

        if (totalEstimate == 0) return; //100

        const minProgress = this.originalProgress() == 100 ? 100 : 99;
        this.Progress(minProgress == 100 ? minProgress : Math.min((totalWork / totalEstimate) * 100.0, minProgress));
    }
}
