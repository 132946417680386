import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { IDocumentsService, SentMail } from "../../DocumentsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ActionSet, DocumentConfirmSendMailViewerDialog } from "./DocumentConfirmSendMailViewer";
import { SendMailAction } from "./SendMailAction";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IConfirmSendMailValidationExceptionData, IValidationException } from "../../../Core/interfaces/IException";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";

const styleSheet = jss.createStyleSheet({
    "send-mail-btn": {},
});
const { classes } = styleSheet.attach();

type MailSendButtonProps = {
    documentId: ko.Observable<number>;
    documentType: ko.Observable<string>;
    lastMailSentStatus: ko.Observable<SentMail>;
};

export function MailSendButton(props: MailSendButtonProps) {
    const C = require("./MailSendButton")._MailSendButton as typeof _MailSendButton;
    return <C {...props} />;
}

export class _MailSendButton {
    static defaultProps: Partial<MailSendButtonProps> = {};

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private errorHandlers = {
        50600: this.handleMailValidationException.bind(this),
        50601: this.handleMissingPdfValidationException.bind(this),
        50602: this.handleMissingDocumentValidationException.bind(this),
    };

    constructor(private props: MailSendButtonProps) {}

    private async handleMailValidationException(
        validationData: IConfirmSendMailValidationExceptionData
    ): Promise<void> {
        const modal = new DocumentConfirmSendMailViewerDialog({
            ...validationData,
            LastMailSentStatus: this.props.lastMailSentStatus(),
            ActionSet: ActionSet.NoSaveDocumentOption,
        });

        const result = await modal.show();
        if (result !== SendMailAction.Abort) {
            await this.sendMail(result);
        }
    }

    private async handleMissingPdfValidationException(): Promise<void> {
        await this.dialogsService.AlertAsync(
            TextResources.Invoices.DocumentMailSendMissingPdfError,
            TextResources.Invoices.ConfirmDocumentSendMailTitle
        );
    }

    private async handleMissingDocumentValidationException(): Promise<void> {
        await this.dialogsService.AlertAsync(
            TextResources.Invoices.DocumentMailSendMissingDocumentError,
            TextResources.Invoices.ConfirmDocumentSendMailTitle
        );
    }

    private async sendMail(action: SendMailAction) {
        try {
            await this.documentsService.sendDocumentMail(this.props.documentId(), this.props.documentType(), action);
            this.infoToastService.Success(TextResources.Invoices.DocumentMailSendRequested);
        } catch (e) {
            const validationException = e as IValidationException;

            if (validationException.ExceptionCode in this.errorHandlers) {
                await this.errorHandlers[validationException.ExceptionCode](validationException.ValidationData);
                return;
            }

            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }

    render() {
        const _msb = this;

        return ComponentUtils.bindTo(
            <button
                className={"btn btn-sm blue " + classes["send-mail-btn"]}
                data-bind={{ asyncClick: _msb.sendMail.bind(_msb, 0) }}
            >
                <i className="fa fa-envelope"></i>
            </button>,
            this,
            "_msb"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MailSendButton);
}
