import * as ko from "knockout";
import { WizardStep } from "./WizardStep";
import { ImportDataWizardSelectSourcesStep } from "./ImportDataWizardSelectSourcesStep";
import { ImportDataWizardDataSourceStep } from "./ImportDataWizardDataSourceStep";
import { LazyImport } from "../../Core/DependencyInjection";
import { DocumentRow } from "../../Invoices/invoices/documents/DocumentRows";
import { IDocumentDataSource, IDocumentBuilderDocumentRows, IDocumentBuilderDocumentOriginatingRows } from "../interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentImportDataWizardStep, IDocumentToImportInfo } from "../interfaces/invoice/IDocumentImportDataWizardStep";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";

export interface IStepInfoForFastForward {
    Step: IDocumentImportDataWizardStep;
    FastForwardButtonLabel: string;
}

export interface IDocumentBuilderDocumentRowsWithReferences {
    Row: IDocumentBuilderDocumentRows;
    OriginatingRows: IDocumentBuilderDocumentOriginatingRows[];
}

export interface IProcessedRow { 
    Row: DocumentRow;
    SourceRows: DocumentRow[];
    IsSelected: ko.Observable<boolean>;
}

export abstract class WizardDialog implements IDialog
{
    public ProcessedRows : ko.ObservableArray<IProcessedRow> = ko.observableArray([]);
    public dataSources : ko.ObservableArray<IDocumentDataSource> = ko.observableArray([]);

    public modal: { close: (result?: any) => void; };

    public CurrentStep : ko.Observable<IDocumentImportDataWizardStep> = ko.observable();
    public Steps : ko.ObservableArray<IDocumentImportDataWizardStep> = ko.observableArray([]);
    public IsLastStep : ko.Computed<boolean>;
    public CanGoBack : ko.Computed<boolean>;
    public ShowFastForwardButton : ko.Computed<boolean>;

    public dialogTemplateName : string = "import-data-wizard-dialog";
    public dialogTemplateUrl : string = "prolifesdk/templates/wizard";

    protected sourcesSelectionStep : WizardStep;
    protected documentsToImportInfo : IDocumentToImportInfo[][] = [];
    protected stepInfoForFastForward: ko.Observable<IStepInfoForFastForward> = ko.observable();
    
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    constructor(public templateName : string, public templateUrl : string,
                public title : string, private documentEntityTypeCode : string,
                protected destinationDocumentRowsReferences : IDocumentBuilderDocumentOriginatingRows[],
                protected initializationInfo : IWizardInitializationInfo)
    {
        this.dataSources.subscribe(this.OnDataSourcesSelected.bind(this));

        this.sourcesSelectionStep = new ImportDataWizardSelectSourcesStep(this.documentEntityTypeCode, this.dataSources, initializationInfo);
        this.Steps.push(this.sourcesSelectionStep);

        this.IsLastStep = ko.computed(() => {
            if(this.CurrentStep() == this.sourcesSelectionStep)
                return false;

            var steps = this.Steps();
            return this.CurrentStep() == steps[steps.length-1];
        });

        this.CanGoBack = ko.computed(() => {
            var steps = this.Steps();
            return this.CurrentStep() != steps[0];
        });

        this.ShowFastForwardButton = ko.computed(() => {
            let indexOfFirstValidStepForFatsForward = 0;

            for (let step of this.Steps()) {
                if (step == this.sourcesSelectionStep || step instanceof ImportDataWizardDataSourceStep)
                    continue;

                indexOfFirstValidStepForFatsForward = this.Steps.indexOf(step);
                break;
            }

            let currentStepIndex = this.Steps.indexOf(this.CurrentStep());

            return this.stepInfoForFastForward() 
                && this.CurrentStep() != this.sourcesSelectionStep
                && currentStepIndex < this.Steps.indexOf(this.stepInfoForFastForward().Step)
                && indexOfFirstValidStepForFatsForward <= currentStepIndex;
        });

        this.CurrentStep(this.Steps()[0]);
    }

    public showModal() : Promise<IDocumentBuilderDocumentRowsWithReferences[]> {
        return this.dialogService.ShowModal<IDocumentBuilderDocumentRowsWithReferences[]>(
            this,
            "fullscreen",
            undefined,
            this.dialogTemplateUrl,
            this.dialogTemplateName
        );
    }

    public GetImportedDocumentsInfo() : IDocumentToImportInfo[]
    {
        var info : IDocumentToImportInfo[] = [];

        this.documentsToImportInfo.forEach((stepDocs : IDocumentToImportInfo[]) => {
            stepDocs.forEach((d : IDocumentToImportInfo) => {
                var matches = info.filter((d1 : IDocumentToImportInfo) => { return d1.EntityType == d.EntityType && d1.Id == d.Id; });
                if(matches.length > 0)
                    return;
                info.push(d);
            });
        });

        return info;
    }

    public OnDataSourcesSelected()
    {
        var steps = [this.sourcesSelectionStep];
        this.dataSources().forEach((s : IDocumentDataSource) => {
            steps.push(new ImportDataWizardDataSourceStep(s, this.initializationInfo, this.destinationDocumentRowsReferences));
        });

        if(this.dataSources().length > 0)
            this.GetCustomSteps().forEach((s : WizardStep) => {
                steps.push(s);
            });

        this.Steps(steps);
        this.documentsToImportInfo = [];
    }

    abstract GetCustomSteps() : WizardStep[];

    close(): void
    {
        this.modal.close();
    }

    back() : void
    {
        if(!this.CurrentStep().BeforeBack())
            return;

        if(!this.CanGoBack()) return;
        var currentStepIndex = this.Steps.indexOf(this.CurrentStep());

        var step : IDocumentImportDataWizardStep = this.Steps()[currentStepIndex - 1];
        step.BeforeShow();
        this.CurrentStep(step);
    }

    next() : void
    {
        if(!this.CurrentStep().BeforeNext())
            return;

        if(this.IsLastStep())
            return;

        this.dialogService.LockUI();

        let currentStepIndex = this.Steps.indexOf(this.CurrentStep());
        this.CurrentStep().GetDocumentsToImportInfo()
            .then((documents) => {
                this.documentsToImportInfo[currentStepIndex] = documents;
                var step : IDocumentImportDataWizardStep = this.Steps()[currentStepIndex + 1];
                step.BeforeShow();
                this.CurrentStep(step);

                this.dialogService.UnlockUI();
            })
            .catch(() => {
                this.dialogService.UnlockUI();
            });
    }

    fastForward(): void {
        if (!this.stepInfoForFastForward())
            return;
        
        let currentStep = this.CurrentStep();

        if (!currentStep.BeforeNext())
            return;

        let currentStepIndex = this.Steps.indexOf(currentStep);
        let stepForFastForwardIndex = this.Steps.indexOf(this.stepInfoForFastForward().Step);

        if (currentStepIndex >= stepForFastForwardIndex)
            return;

        this.dialogService.LockUI();

        this.CurrentStep().GetDocumentsToImportInfo()
            .then((documents) => {
                this.documentsToImportInfo[currentStepIndex] = documents;

                for (let i = currentStepIndex + 1; i < stepForFastForwardIndex; i++) {
                    let nextStep : IDocumentImportDataWizardStep = this.Steps()[i];
                    nextStep.BeforeShow();
                    nextStep.BeforeNext();
                }
                
                let nextStep : IDocumentImportDataWizardStep = this.Steps()[stepForFastForwardIndex];

                nextStep.BeforeShow();
                this.CurrentStep(nextStep);

                this.dialogService.UnlockUI();
            })
            .catch(() => {
                this.dialogService.UnlockUI();
            });
    }

    async action(): Promise<void>
    {
        let rows = await this.getRowsToImport();
        this.modal.close(rows);
    }

    public abstract getRowsToImport() : Promise<IDocumentBuilderDocumentRowsWithReferences[]>;
}
