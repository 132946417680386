import * as ko from "knockout";
/**
 * Created by g.adami on 23/06/2017.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { EditPeopleDialog } from "../../peoples/dialogs/EditPeopleDialog";
import { PeopleSearchFilters } from "../../peoples/search/PeopleSearchFilters";
import { PeopleForListViewModel } from "../../peoples/PeopleForListViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISampleService } from "../../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { IPeopleSearchFiltersObserver, IPeopleService, IPeopleForList, IEditPeopleResult } from "../../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { ISampleDataToImport, ISampleImportDialogView, ISampleForImport, ISampleImportFilters } from "../../../../../ProlifeSdk/interfaces/survey/ISample";
import { Deferred } from "../../../../../Core/Deferred";

export class SampleImportDialog implements IDialog
{
    public templateName = "";
    public templateUrl = "survey/templates/sample/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.SampleImportDialogTitle;
    public modal: { close: (result?: any) => void; };

    public SourceViews: IViewForImportHash = {};
    private SourceViewsNames: string[] = [];
    public CurrentView: ko.Observable<any> = ko.observable();
    public SourceSelection : ko.Observable<string> = ko.observable();

    public ImportFromExcel: ko.Computed<boolean>;
    public ImportFromExistingSample: ko.Computed<boolean>;
    public ImportSingleContacts: ko.Computed<boolean>;

    public DefaultTemplateUrl: ko.Observable<string> = ko.observable();

    private dialogsService: IDialogsService;
    private infoToast: IInfoToastService;
    private sampleService : ISampleService;

    constructor(private serviceLocator : IServiceLocator, public sampleId : number, public DataToImport: ISampleDataToImport)
    {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToast = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.sampleService = <ISampleService> serviceLocator.findService(ProlifeSdk.SampleServiceType);

        this.SourceSelection(ProlifeSdk.ImportFromExcel);
        this.openView(this.SourceSelection());

        this.SourceSelection.subscribe((importMode: string) => {
            this.openView(importMode);
        });

        this.DefaultTemplateUrl("Service/Survey/SampleImportExport/DefaultTemplate/" + this.sampleId);

        this.ImportFromExcel = ko.computed(() => {
            return this.SourceSelection() == ProlifeSdk.ImportFromExcel;
        });

        this.ImportFromExistingSample = ko.computed(() => {
            return this.SourceSelection() == ProlifeSdk.ImportFromExistingSample;
        });

        this.ImportSingleContacts = ko.computed(() => {
            return this.SourceSelection() == ProlifeSdk.ImportSingleContacts;
        });
    }

    private openView(importMode: string): void {
        if (!this.SourceViews[importMode]) {
            this.SourceViews[importMode] = this.CreateViewModel(importMode);
            this.SourceViewsNames.push(importMode);
            this.CurrentView(this.SourceViews[importMode]);
            return;
        }
        this.CurrentView(this.SourceViews[importMode]);
    }

    private CreateViewModel(importMode: string): any {
        if (importMode == ProlifeSdk.ImportFromExcel)
            return new ExcelSampleImportView(this.serviceLocator, this);
        if (importMode == ProlifeSdk.ImportSingleContacts)
            return new ExistingContactsImportView(this.serviceLocator, this);
        return new ExistingSamplesImportView(this.serviceLocator, this);
    }

    public close() : void
    {
        this.modal.close(null);
    }

    public action() : void
    {
        this.SourceViewsNames.forEach((n: string) => {
            this.SourceViews[n].OnParentAction();
        });

        this.modal.close(null);
    }

    public SetExcelSource(): void {
        this.SourceSelection(ProlifeSdk.ImportFromExcel);
    }

    public SetExistingSampleSource(): void {
        this.SourceSelection(ProlifeSdk.ImportFromExistingSample);
    }

    public SetExistingContactsSource(): void {
        this.SourceSelection(ProlifeSdk.ImportSingleContacts);
    }
}

export class ExistingContactsImportView implements ISampleImportDialogView, IPeopleSearchFiltersObserver {
    public templateName  = "import-existing-contacts";
    public templateUrl  = "survey/templates/sample/dialogs";

    public Contacts: ko.ObservableArray<ContactForImport> = ko.observableArray([]);
    public SelectedContacts: ko.ObservableArray<ContactForImport> = ko.observableArray([]);
    public SelectAllContacts: ko.Observable<boolean> = ko.observable();
    public SelectAllSelectedContacts: ko.Observable<boolean> = ko.observable();
    public EmptyList: ko.Computed<boolean>;
    public EmptySelection: ko.Computed<boolean>;
    public ContactsList: ko.Computed<ContactForImport[]>;
    public SelectedContactsList: ko.Computed<ContactForImport[]>;

    public Filters: PeopleSearchFilters;

    private mustSelectAll = true;
    private mustSelectAllSelected = true;

    private isLoading = false;

    private peopleService: IPeopleService;
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private parent: SampleImportDialog) {
        this.peopleService = <IPeopleService> this.serviceLocator.findService(ProlifeSdk.PeopleServiceType);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.Filters = new PeopleSearchFilters(this);

        parent.DataToImport.SelectedContacts.forEach((c: IPeopleForList) => {
            this.SelectedContacts.push(new ContactForImport(c));
        });

        this.SelectAllContacts(false);
        this.SelectAllSelectedContacts(false);

        this.ContactsList = ko.computed(() => {
            return this.Contacts().filter((c: ContactForImport) => {
                return !this.IsContactSelected(c);
            });
        });

        this.SelectedContactsList = ko.computed(() => {
            return this.SelectedContacts();
        });

        this.EmptyList = ko.computed(() => {
            return this.ContactsList().length == 0;
        });

        this.EmptySelection = ko.computed(() => {
            return this.SelectedContactsList().length == 0;
        });

        this.SelectAllContacts.subscribe((action: boolean) => {
            if (this.mustSelectAll) {
                this.Contacts().forEach((s: ContactForImport) => {
                    s.IsSelected(action);
                });
            }
            this.mustSelectAll = true;
        });

        this.SelectAllSelectedContacts.subscribe((action: boolean) => {
            if (this.mustSelectAllSelected) {
                this.SelectedContacts().forEach((c: ContactForImport) => {
                    c.IsSelected(action);
                });
            }
            this.mustSelectAllSelected = true;
        });

        this.RefreshList(0);
    }

    public LoadNextBlock(): void {
        if (this.isLoading)
            return;

        this.isLoading = true;

        this.RefreshList(this.Contacts().length)
            .finally(() => {
                setTimeout(() => this.isLoading = false, 200);
            });
    }

    public OnFiltersChanges(): void {
        this.Contacts([]);
        this.RefreshList(0);
    }

    public OnParentAction(): void {
        this.parent.DataToImport.SelectedSamples = [];
        this.SelectedContacts().forEach((s: ContactForImport) => {
            this.parent.DataToImport.SelectedContacts.push(s.toJSON());
        });
    }

    public CheckIfAllSelected(): void {
        let allSelected = true;
        this.Contacts().forEach((s: ContactForImport) => {
            if (!s.IsSelected())
                allSelected = false;
        });
        this.mustSelectAll = false;
        this.SelectAllContacts(allSelected && (this.Contacts().length > 0));
        allSelected = true;
        this.SelectedContacts().forEach((s: ContactForImport) => {
            if (!s.IsSelected())
                allSelected = false;
        });
        this.mustSelectAllSelected = false;
        this.SelectAllSelectedContacts(allSelected && (this.SelectedContacts().length > 0));
    }

    public Select(): void {
        let contacts: ContactForImport[] = this.Contacts();
        for (let index = 0; index < contacts.length; index++) {
            if (contacts[index].IsSelected()) {
                this.SelectedContacts.push(contacts[index]);
                contacts[index].IsSelected(false);
                contacts.splice(index, 1);
                index -= 1;
            }
        }
        this.Contacts(contacts);
    }

    public Deselect(): void {
        let contacts: ContactForImport[] = this.SelectedContacts();
        for (let index = 0; index < contacts.length; index++) {
            if (contacts[index].IsSelected()) {
                if (!this.sampleAlreadyInList(contacts[index])) {
                    this.Contacts.push(contacts[index]);
                }
                contacts[index].IsSelected(false);
                contacts.splice(index, 1);
                index -= 1;
            }
        }
        this.SelectedContacts(contacts);
    }

    private sampleAlreadyInList(contact: ContactForImport): boolean {
        let exists = false;
        this.Contacts().forEach((c: ContactForImport) => {
            if (c.Id == contact.Id)
                exists = true;
        });
        return exists;
    }

    public AddNewContact(): void {
        let editPeopleDialog = new EditPeopleDialog(this.serviceLocator, null);
        this.dialogsService.ShowModal<IEditPeopleResult>(editPeopleDialog, "fullscreen", null, "survey/templates/people/dialogs", "edit-people-dialog")
            .then((result: IEditPeopleResult) => {
                if (!result)
                    return;
                this.SelectedContacts.unshift(new ContactForImport(<IPeopleForList>(result.PeopleData)));
            });
    }

    private RefreshList(skip: number): Promise<void> {
        let def = new Deferred<void>();

        this.peopleService.getPeoplesForImport(this.Filters.toJSON(), skip, 100)
            .then((contacts: IPeopleForList[]) => {
                this.Contacts(this.Contacts().concat(contacts.map((c: IPeopleForList) => new ContactForImport(c))));
                def.resolve()
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private IsContactSelected(contact: ContactForImport): boolean {
        let result = false;
        this.SelectedContacts().forEach((c: ContactForImport) => {
            if (contact.Id == c.Id)
                result = true;
        });
        return result;
    }
}

export class ExistingSamplesImportView implements ISampleImportDialogView {
    public templateName  = "import-from-existing-sample";
    public templateUrl  = "survey/templates/sample/dialogs";

    public Samples : ko.ObservableArray<SampleForImport> = ko.observableArray([]);
    public SelectAllSamples : ko.Observable<boolean> = ko.observable();
    public SamplesList : ko.Computed<SampleForImport[]>;

    public SelectedSamples : ko.ObservableArray<SampleForImport> = ko.observableArray([]);
    public SelectAllSelectedSamples : ko.Observable<boolean> = ko.observable();
    public SelectedSamplesList: ko.Computed<SampleForImport[]>;

    public SearchFilter: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 700, method: "notifyWhenChangesStop" } });
    public CreationFromFilter: ko.Observable<Date>  = ko.observable();
    public CreationToFilter: ko.Observable<Date>  = ko.observable();
    public ModifyFromFilter: ko.Observable<Date>  = ko.observable();
    public ModifyToFilter: ko.Observable<Date>  = ko.observable();

    public EmptyList: ko.Computed<boolean>;
    public EmptySelection: ko.Computed<boolean>;

    private mustSelectAll = true;
    private mustSelectAllSelected = true;
    private sampleService : ISampleService;

    constructor(private serviceLocator : IServiceLocator, private parent : SampleImportDialog)
    {
        this.sampleService = <ISampleService> serviceLocator.findService(ProlifeSdk.SampleServiceType);

        parent.DataToImport.SelectedSamples.forEach((s: ISampleForImport) => {
            this.SelectedSamples.push(this.CreateViewModelFor(s));
        });

        this.filter();

        this.SelectAllSamples(false);
        this.SelectAllSelectedSamples(false);

        this.SearchFilter.subscribe((title: string) => {
            this.filter();
        });
        this.CreationFromFilter.subscribe((date: Date) => {
            this.filter();
        });
        this.CreationToFilter.subscribe((date: Date) => {
            this.filter();
        });
        this.ModifyFromFilter.subscribe((date: Date) => {
            this.filter();
        });
        this.ModifyToFilter.subscribe((date: Date) => {
            this.filter();
        });

        this.SamplesList = ko.computed(() => {
            return this.Samples().filter((s: SampleForImport) => {
                return !this.IsSampleSelected(s);
            });
        });

        this.SelectedSamplesList = ko.computed(() => {
            return this.SelectedSamples();
        });

        this.EmptyList = ko.computed(() => {
            return this.SamplesList().length == 0;
        });

        this.EmptySelection = ko.computed(() => {
            return this.SelectedSamplesList().length == 0;
        });

        this.SelectAllSamples.subscribe((action: boolean) => {
            if (this.mustSelectAll) {
                this.Samples().forEach((s: SampleForImport) => {
                    s.IsSelected(action);
                });
            }
            this.mustSelectAll = true;
        });

        this.SelectAllSelectedSamples.subscribe((action: boolean) => {
            if (this.mustSelectAllSelected) {
                this.SelectedSamples().forEach((s: SampleForImport) => {
                    s.IsSelected(action);
                });
            }
            this.mustSelectAllSelected = true;
        });
    }

    public Select(): void {
        let samples: SampleForImport[] = this.Samples();
        for (let index = 0; index < samples.length; index++) {
            if (samples[index].IsSelected()) {
                this.SelectedSamples.push(samples[index]);
                samples[index].IsSelected(false);
                samples.splice(index, 1);
                index -= 1;
            }
        }
        this.Samples(samples);
    }

    public Deselect(): void {
        let samples: SampleForImport[] = this.SelectedSamples();
        for (let index = 0; index < samples.length; index++) {
            if (samples[index].IsSelected()) {
                if (!this.sampleAlreadyInList(samples[index])) {
                    this.Samples.push(samples[index]);
                }
                samples[index].IsSelected(false);
                samples.splice(index, 1);
                index -= 1;
            }
        }
        this.SelectedSamples(samples);
    }

    private sampleAlreadyInList(sample: SampleForImport): boolean {
        let exists = false;
        this.Samples().forEach((s: SampleForImport) => {
            if (s.Id() == sample.Id())
                exists = true;
        });
        return exists;
    }

    public OnParentAction(): void {
        this.parent.DataToImport.SelectedSamples = [];
        this.SelectedSamples().forEach((s: SampleForImport) => {
            this.parent.DataToImport.SelectedSamples.push(s.ToJSON());
        });
    }

    public CheckIfAllSelected(): void {
        let allSelected = true;
        this.Samples().forEach((s: SampleForImport) => {
            if (!s.IsSelected())
                allSelected = false;
        });
        this.mustSelectAll = false;
        this.SelectAllSamples(allSelected && (this.Samples().length > 0));
        allSelected = true;
        this.SelectedSamples().forEach((s: SampleForImport) => {
            if (!s.IsSelected())
                allSelected = false;
        });
        this.mustSelectAllSelected = false;
        this.SelectAllSelectedSamples(allSelected && (this.SelectedSamples().length > 0));
    }

    private IsSampleSelected(sample: SampleForImport): boolean {
        let result = false;
        this.SelectedSamples().forEach((s: SampleForImport) => {
            if (sample.Id() == s.Id())
                result = true;
        });
        return result;
    }

    private filter(){
        this.sampleService.getSamplesForImport(this.parent.sampleId, this.GetFilters())
            .then((samples: ISampleForImport[]) => {
                let newList = samples.map((s: ISampleForImport) => {
                    return this.CreateViewModelFor(s);
                });
                this.Samples(newList);
            });
    }

    private GetFilters(): ISampleImportFilters {
        return <ISampleImportFilters> {
            Title: this.SearchFilter(),
            CreationDateFrom: this.CreationFromFilter(),
            CreationDateTo: this.CreationToFilter(),
            ModifyDateFrom: this.ModifyFromFilter(),
            ModifyDateTo: this.ModifyToFilter()
        };
    }

    private CreateViewModelFor(s : ISampleForImport)
    {
        return new SampleForImport(this.serviceLocator, s, this);
    }
}

export class ExcelSampleImportView implements ISampleImportDialogView
{
    public templateName  = "import-from-excel";
    public templateUrl  = "survey/templates/sample/dialogs";

    public SelectedFiles: ko.ObservableArray<any> = ko.observableArray([]);

    public PreviousSelectedFiles: ko.ObservableArray<FileForImport> = ko.observableArray([]);
    public SelectAllFiles: ko.Observable<boolean> = ko.observable(false);

    public CanDeleteFiles: ko.Computed<boolean>;
    public EmptyList: ko.Computed<boolean>;

    private mustSelectAllFiles = true;

    private dialogService: IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private parent: SampleImportDialog)
    {
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.parent.DataToImport.SelectedFiles.forEach((f: File) => {
            this.PreviousSelectedFiles.push(new FileForImport(f, this));
        });

        this.SelectAllFiles.subscribe((select: boolean) => {
            if (this.mustSelectAllFiles) {
                this.PreviousSelectedFiles().forEach((f: FileForImport) => {
                    f.IsSelected(select);
                });
            }
            this.mustSelectAllFiles = true;
        });

        this.SelectedFiles.subscribe((files: File[]) => {
            for (let index = 0; index < files.length; index++) {
                this.PreviousSelectedFiles.push(new FileForImport(files[index], this));
            }
        });

        this.CanDeleteFiles = ko.computed(() => {
            let canDelete = false;
            this.PreviousSelectedFiles().forEach((f: FileForImport) => {
                if (f.IsSelected())
                    canDelete = true;
            });
            return canDelete;
        });

        this.EmptyList = ko.computed(() => {
            return this.PreviousSelectedFiles().length == 0;
        });
    }

    public DeleteFilesFromSelection(): void {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Survey.ConfirmFilesToImportDelete,
            ProlifeSdk.TextResources.Survey.FilesToImportDeleteCancelButton,
            ProlifeSdk.TextResources.Survey.FilesToImportDeleteConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.RemoveFilesFromSelection();
            }
        );
    }

    public CheckIfAllSelected(): void {
        let allSelected = true;
        this.PreviousSelectedFiles().forEach((f: FileForImport) => {
            if (!f.IsSelected())
                allSelected = false;
        });
        this.mustSelectAllFiles = false;
        this.SelectAllFiles(allSelected && (this.PreviousSelectedFiles().length > 0));
    }

    public OnParentAction(): void {
        this.parent.DataToImport.SelectedFiles = [];
        this.PreviousSelectedFiles().forEach((f: FileForImport) => {
            this.parent.DataToImport.SelectedFiles.push(f.ToJSON());
        });
        // Non più necessario: i file vengoni aggiunti in lista al momento della loro selezione
        /*var files: any = this.SelectedFiles();
        for (var index: number = 0; index < files.length; index++) {
            this.parent.DataToImport.SelectedFiles.push(files.item(index));
        }*/
    }

    private RemoveFilesFromSelection(): void {
        let files: FileForImport[] = this.PreviousSelectedFiles();
        for (let index = 0; index < files.length; index++) {
            if (files[index].IsSelected()) {
                files.splice(index, 1);
                index -= 1;
            }
        }
        this.PreviousSelectedFiles(files);
    }
}

export class ContactForImport extends PeopleForListViewModel {
    public IsSelected: ko.Observable<boolean> = ko.observable(false);

    constructor(people: IPeopleForList) {
        super(people);
    }
}

export class SampleForImport
{
    public IsSelected : ko.Observable<boolean> = ko.observable();
    public Id : ko.Observable<number> = ko.observable();
    public Title : ko.Observable<string> = ko.observable();
    public Size : ko.Observable<number> = ko.observable();
    public CreationDate : ko.Observable<Date> = ko.observable();
    public ModifyDate : ko.Observable<Date> = ko.observable();

    constructor(serviceLocator: IServiceLocator, public sample: ISampleForImport, private parent: ExistingSamplesImportView)
    {
        this.IsSelected(false);
        this.Id(sample.Id);
        this.Title(sample.Title);
        this.Size(sample.Size);
        this.CreationDate(sample.CreationDate);
        this.ModifyDate(sample.ModifyDate);

        this.IsSelected.subscribe((selected: boolean) => {
            this.parent.CheckIfAllSelected();
        });
    }

    public ToJSON(): ISampleForImport {
        return <ISampleForImport> {
            Id: this.Id(),
            Title: this.Title(),
            Size: this.Size(),
            CreationDate: this.CreationDate(),
            ModifyDate: this.ModifyDate()
        };
    }
}

export class FileForImport {
    public IsSelected: ko.Observable<boolean> = ko.observable();
    public File: File;

    constructor(file: File, private parent: ExcelSampleImportView) {
        this.IsSelected(false);
        this.File = file;

        this.IsSelected.subscribe((selected: boolean) => {
            this.parent.CheckIfAllSelected();
        });
    }

    public ToJSON(): File {
        return this.File;
    }
}

export interface IViewForImportHash {
    [importMode: string]: ISampleImportDialogView;
}
