import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import moment = require("moment");
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IDataSourceModel, IDataSource, IDataSourceView } from "../../../../DataSources/IDataSource";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IDocumentReferencingArticle, IArticlesService } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class DocumentsReferencingArticleDialog implements IDialog {
    public templateName: string = "documents-referencing-article-dialog";
    public templateUrl: string = "warehouse/templates";
    public title: string = ProlifeSdk.TextResources.Warehouse.DocumentsReferencingArticleDialogTitle;
    
    public modal: { close: (result?: any) => void; };

    public DocumentsDataSource: DocumentReferencingArticleDataSource;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private articleId: number) {
        this.DocumentsDataSource = new DocumentReferencingArticleDataSource();
        this.DocumentsDataSource.setArticleId(this.articleId);
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this, "large", { noPrompt: true });
    }

    public close(): void {
        this.modal.close();
    }
    
    public action(): void {
        this.modal.close();
    }
}

interface IDocumentReferencingArticleDataSourceModel extends IDataSourceModel<number, IDocumentReferencingArticle> {
    openDocument: () => Promise<void>;
}

class DocumentReferencingArticleDataSource implements IDataSource {
    private view: IDataSourceView;
    private articleId: number;

    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService: IArticlesService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    constructor() {
    }

    public getTitle(currentModel: IDocumentReferencingArticleDataSourceModel): string {
        return ProlifeSdk.TextResources.Warehouse.DocumentReferencingArticleDataSource;
    }

    public async getData(currentModel: IDocumentReferencingArticleDataSourceModel, textFilter: string, skip: number, count: number): Promise<IDocumentReferencingArticleDataSourceModel[]> {
        let documents = await this.articlesService.GetDocumentsWhereArticleIsUsed(this.articleId, skip, count);

        return documents.map(this.createObject, this);
    }
    
    public async getById(currentModel: IDocumentReferencingArticleDataSourceModel, ids: number[]): Promise<IDocumentReferencingArticleDataSourceModel[]> {
        throw new Error("Method not implemented.");
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setArticleId(articleId: number): void {
        this.articleId = articleId;
    }
    
    public isGroupedData(currentModel: IDocumentReferencingArticleDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IDocumentReferencingArticleDataSourceModel, b: IDocumentReferencingArticleDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }
    
    private createObject(document: IDocumentReferencingArticle): IDocumentReferencingArticleDataSourceModel {
        return {
            id: document.DocId,
            isGroup: false,
            isLeaf: true,
            title: String.format(ProlifeSdk.TextResources.Warehouse.DocumentReferencingArticleName, document.ProtocolName, document.Number, moment(document.DocDate).format("L")),
            model: document,
            openDocument: () => this.documentsService.OpenDocumentOverlay(document.DocType, document.DocId) //String.format("#/" + ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, protocolIdForUrl + document.ProtocolId, document.DocId)
        }
    }
}