import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { AjaxOptions } from "../../Core/interfaces/IAjaxService";
import { IAjaxFilter, IXHR } from "../../Core/interfaces/IAjaxFilterHostService";

interface ErrorHandlerMapping {
	[exceptionType : string] : (error) => void;
}

export class SessionExpiredHandler implements IAjaxFilter {
	private infoToastService : IInfoToastService;
	private authenticationService : IAuthenticationService;
	private toastService : IInfoToastService;
	private dialogService : IDialogsService;

	private mappings : ErrorHandlerMapping = {};

	constructor(private serviceLocator : IServiceLocator)
    {
		this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
		this.authenticationService = <IAuthenticationService> serviceLocator.findService(ServiceTypes.Authentication);
		this.toastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.mappings[ProlifeSdk.ServerException_SessionExpired] = (error) => this.doLogout(error);
		this.mappings[ProlifeSdk.ServerException_Authentication] = (error) => this.doLogout(error);
        this.mappings[ProlifeSdk.ServerException_MaintenanceMode] = (error) => this.doLogout(error);
        this.mappings[ProlifeSdk.ServerException_OldVersion] = (error) => this.doLogout(error);
		this.mappings[ProlifeSdk.ServerException_ProLife] =(error) => this.throwError(error);
		this.mappings[ProlifeSdk.ServerException_Authorization] = (error) => this.throwError(error);
        this.mappings["System.Exception"] = (error) => this.throwError(error);
	}

	onStarting(parameters: JQueryAjaxSettings): Promise<void> {
        if(parameters["background"]) return null;
		return null; //No override
	}
	onComplete(options: AjaxOptions, jqXHR: IXHR, textStatus: string): boolean {
        if(options.background) return false;
		return false; //No bubble
	}
	onSuccess(options: AjaxOptions, data: any, textStatus: string, jqXHR: IXHR): boolean {
        if(options.background) return false;
		return false; //No bubble
	}
	onError(options: AjaxOptions, jqXHR: IXHR, textStatus: string, errorThrown: any): boolean {
        try
        {
            if(jqXHR.status == 401) {
                location.href = "/Login";
                return false;
            }

            var responseString: string = "";

            try {
                responseString = jqXHR.responseText;
            } catch (e) {
                responseString = (<any>jqXHR).responseString;
            }

            if(!this.isJSON(responseString || "null")) {
                this.genericError("")
            } else {
                var deserializedError = JSON.parse(responseString || "null");
                var handler = this.mappings[deserializedError.ExceptionType] || this.genericError.bind(this);
                handler(deserializedError);
            }
        }
        catch(e)
        {
            this.toastService.Error(ProlifeSdk.TextResources.Desktop.ConnectionError);
            //this.doLogout("");
        }
		return false; //No bubble
	}

    private isJSON(data) {
        var isJson = false
        try {
            // this works with JSON string and JSON object, not sure about others
            var json = JSON.parse(data);
            isJson = typeof json === 'object' ;
        } catch (ex) {
            //console.error('data is not JSON');
        }
        return isJson;
    }

	private doLogout(error) {
        if(error.ExceptionType == ProlifeSdk.ServerException_MaintenanceMode) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.MaintenanceMode);
            location.href = "/Login";
        } else if(error.ExceptionType == ProlifeSdk.ServerException_OldVersion) {
            this.dialogService.LockUI(error.ExceptionMessage);
        } else if(error.ExceptionType == ProlifeSdk.ServerException_SessionExpired) {
            this.dialogService.LockUI(error.ExceptionMessage);

            setTimeout(() => {
                location.href = "/Login";
            }, 5000)
        } else {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.ExpiredSession);
            location.href = "/Login";
        }
		//this.authenticationService.logoutUser();
	}

	private throwError(error) {
		this.infoToastService.Error(error.ExceptionMessage || error.Message);
	}

	private genericError(error) {
		//this.infoToastService.Error("Errore generale durante la chiamata al servizio, contattare il servizio di assistenza");
	}
}