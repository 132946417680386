import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Document, IDocumentConfiguration } from "../Document";

export class InvoiceConfiguration implements IDocumentConfiguration {
    DocumentType: string = ProlifeSdk.InvoiceEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(true);

        document.DDTCausesDataSource.setShowOnlySalesCauses(true);

        document.CanHaveDestinationRecipient(false);
        document.CanBeAnElectronicInvoice(true);
        document.CanHaveStampDuty(true);
        document.CanBeDownPayment(true);
        document.CanHaveSecondaryRecipients(true);
        document.CanBeForPublicAdministration(true);
        document.CanHaveWithholdingTax(true);
        document.CanHideAmounts(false);
        document.CanSelectCustomers(true);
        document.CanSelectSuppliers(false);
        document.CanHaveRelatedDocuments(true);
        document.CanHaveSourceWarehouse(false);
        document.CanHaveDestinationWarehouse(false);
        document.CanHaveShippingInformation(false);
        document.CanHaveCause(false);
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(true);
        document.CanHaveTaxReport(true);
        document.CanReferenceArticles(false);
        document.CanHaveVAT(true);
        document.CanCloseRows(false);
        document.CanHaveLetterOfAttempts(true);
        document.CanViewOldLetterOfAttempts(false);
        document.CanChangeDiscountCatalog(false);
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(false);
        document.CanEditRevisionNumber(false);
        document.CanHaveSchedules(!document.RegisterCache.ForPartialInvoices);
        document.CanExportElectronicInvoices(true);
        document.CanHaveState(false);
        document.CanSelectClosedLettersOfAttempt(false);
        document.CanHaveCIGCUP(false);
        document.MustCheckTrust(true);
        document.IsCustomerDocument(true);
        document.CanHaveMetadatas(document.MetadatasEnabled);
        document.ShowVersionNumbersSuggestions(false);
        document.RecipientPosition("right");
        document.CanHaveTaxRelief(true);

        document.ExcelExporterId("Invoices/DocumentExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.InvoiceLabel);
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    print(documentId: number) {
        this.dialogsService.OpenUrlAsDialog("/Invoices/Print/Index/" + documentId, "Stampa Fattura");
        /*var popup = window.open("about:blank", "_blank");
        popup.location.href = "/Service/Invoices/Print/Index/" + documentId;*/
    }
}
