import jss from "jss";
import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { ITicketOrigin } from "../../TicketsService";
import { ITicketOriginsSettingsManager } from "../TicketOriginsSettingsManager";

const { classes } = jss.createStyleSheet({
    ticketsTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class TicketOrigin {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    public validator: IValidator<TicketOrigin>;

    Id: number;
    Title: ko.Observable<string> = ko.observable();
    Deleted: ko.Observable<boolean> = ko.observable();

    constructor(ticketOrigin: ITicketOrigin){
        this.Id = ticketOrigin.Id;
        this.Title(ticketOrigin.Title);
        this.Deleted(ticketOrigin.Deleted);

        this.validator = this.validationService.createValidator<TicketOrigin>()
        .isNotNullOrUndefinedOrWhiteSpace((d) => d.Title(), TextResources.Tickets.ErrorNoDescription)
    }
}

export interface ITicketOriginsSettingsComponentProps {
    TicketOriginsSettingsManager: ITicketOriginsSettingsManager;
}

export class TicketOriginsSettingsComponent {
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    TicketOrigins: ko.ObservableArray<TicketOrigin> = ko.observableArray();

    constructor(private props: ITicketOriginsSettingsComponentProps){
        this.loadData();
    }

    loadData(){
        this.TicketOrigins(this.props.TicketOriginsSettingsManager.getTicketOrigins(null, false).map((x) => new TicketOrigin(x)));
    }

    addNewTicketOrigin(){
        this.TicketOrigins.push(new TicketOrigin({
            Id: this.idGeneratorService.getNextId(),
            Deleted: false,
            Title: null
        }));
    }

    async deleteTicketOrigin(item: TicketOrigin){
        let result = await this.dialogsService.ConfirmAsync(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem);
        if (result) {
            if (item.Id < 0)
                this.TicketOrigins.remove(item);
            else
                item.Deleted(true);
        }
    }

    restoreTicketOrigin(item: TicketOrigin){
        item.Deleted(false);
    }

    async saveAll(){
        let isValid = true;
        for (let eventType of this.TicketOrigins()){
            if (!eventType.validator.validateAndShowInfoToast(eventType)){
                isValid = false;
                break;
            }
        }

        if (this.TicketOrigins().length < 1){
            this.infoToastService.Error(TextResources.Tickets.ErrorZeroOrigins);
            isValid = false;
        }

        if (isValid){
            try {
                await this.props.TicketOriginsSettingsManager.insertOrUpdateTicketOrigins(this.TicketOrigins().map(x => ({ 
                    Id: x.Id,
                    Title: x.Title(),
                    Deleted : x.Deleted()
                })));
            
                this.infoToastService.Success(TextResources.Tickets.AddExistingOriginSuccess);
                this.loadData();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    createTicketOriginModel(item : TicketOrigin) {
        return {
            id: item.Id,
            title: item.Title(),
            isLeaf: true,
            isGroup: false,
            model: item
        }
    }

    render() {
        let vm = this;
        let ticketOrigins: IDataSourceModel<number, TicketOrigin>;

        return ComponentUtils.bindTo(
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-10">
                        <h1>{TextResources.Tickets.Origins}</h1>
                    </div>
                    <div className="col-md-2 text-right" style="margin-top: 20px">
                        <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}}>
                            <i className="fa fa-save"></i> {TextResources.Tickets.Save}
                        </button>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <Table rowAs="ticketOrigins" dataSource={{array: vm.TicketOrigins, factory: vm.createTicketOriginModel }} compact={true} editable={true} scrollable={true} className={classes.ticketsTable}>
                        <Column title={TextResources.Tickets.Notes}>
                            {/* <span data-bind={{if: ticketOrigins.model.IsInUse}}>
                                <i className="fa fa-exclamation-triangle" title={TextResources.Tickets.TicketInUseAlert}></i>
                            </span> */}
                            <span data-bind={{if: ticketOrigins.model.Deleted}}>
                                <i className="fa fa-trash-o" title={TextResources.Tickets.OriginIsDeletedAlert}></i>
                            </span>
                        </Column>
                        <Column title={TextResources.Tickets.Title} cssClasses="noMargin">
                            <text-input value={() => "ticketOrigins.model.Title"} readOnly={() => "ticketOrigins.model.Deleted()"} placeholder={TextResources.Tickets.TitlePlaceholder}/>
                        </Column>
                        <Column title={TextResources.Tickets.Actions}>
                            <ColumnHeader>
                                <button className="btn btn-primary btn-xs" data-bind={{click: vm.addNewTicketOrigin.bind(vm)}}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <div data-bind={{ifnot: ticketOrigins.model.Deleted}}>
                                    <button className="btn btn-danger btn-xs" data-bind={{click: vm.deleteTicketOrigin.bind(vm, ticketOrigins.model)}}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                                <div data-bind={{if: ticketOrigins.model.Deleted}}>
                                    <button className="btn btn-success btn-xs" data-bind={{click: vm.restoreTicketOrigin.bind(vm, ticketOrigins.model)}}>
                                        <i className="fa fa-recycle"></i>
                                    </button>
                                </div>
                            </ColumnBody>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>, this, "vm");
    }
}   