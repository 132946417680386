import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 08/02/13
 * Time: 12.21
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import "./ui/dialogs/AllCustomersPaymentExtensionsDialog";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { CustomersViewModel } from "./ui/CustomersViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplication, IApplicationContextAction, IApplicationNavBarAction } from "../../Desktop/interfaces/IApplication";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../Core/DependencyInjection";

export class CustomersApplication implements IApplication {
    public templateName  = 'customers';
    public templateUrl  = 'customers/templates';
    public templateData : CustomersViewModel;
    public tileColor  = 'tile-orange';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);
    public contextActions: ko.ObservableArray<IApplicationContextAction> = ko.observableArray([]);
    public navBarActions: ko.ObservableArray<IApplicationNavBarAction> = ko.observableArray([]);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;

    constructor(private serviceLocator : IServiceLocator) {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Customers";
    }

    getName(): string {
        return ProlifeSdk.TextResources.Customers.AddressBook;
    }

    getIcon(): string {
        return "icon-users";
    }

    registerRoutes() : void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/" + ProlifeSdk.TextResources.Customers.AddressBookURL, (context) => this.start());
        opaService.Get("#/" + ProlifeSdk.TextResources.Customers.AddressBookURL + "/:customerId", (context) => {
            var customerId = parseInt(context.params["customerId"]);
            this.start(customerId);
        });
    }

    getApplicationRoute() : string {
        return "#/" + ProlifeSdk.TextResources.Customers.AddressBookURL;
    }

    onGoingDown() : void {
        this.templateData.dispose();
    }

    private start(customerId? : number) {
        this.templateData = new CustomersViewModel(this.serviceLocator, !customerId);

        if(customerId)
            this.templateData.OpenCustomer(customerId);

        this.applicationsService.startApplication(this);

        this.navBarActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Customers.NewContact),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.templateData.addNew.bind(this.templateData)
        },{
            Name: ko.observable(TextResources.Customers.AllCustomersPaymentExtensionsDialogTitle),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-calendar"),
            Run: this.templateData.showAllCustomersPaymentExtensions.bind(this.templateData)
        }]);
    }
}