import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { IWorkflowOutcomeSettingsManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IWorkflowOutcome } from "../Todolist/WorkflowOutcomesService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";

export interface IDraggedOutcome {
    Id: number;
    CompanyGuid: string;
}

export type IWorkflowOutcomesDataSourceModel = IDataSourceModel<number, WorkflowOutcome>;

export class WorkflowOutcomesDataSource extends BaseDataSource<IWorkflowOutcomesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    private workflowOutcomesSettingsManager: IWorkflowOutcomeSettingsManager;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    public getTitle(currentModel: IWorkflowOutcomesDataSourceModel): string {
        return ProlifeSdk.TextResources.Todolist.WorkflowOutcomes;
    }

    public async getData(
        currentModel: IWorkflowOutcomesDataSourceModel,
        textFilter: string,
        skip = 0,
        count = 1000000
    ): Promise<IWorkflowOutcomesDataSourceModel[]> {
        const outcomes = this.workflowOutcomesSettingsManager
            .getOutcomes(false)
            .filter((c) => this.textFilterMatch(c, textFilter))
            .slice(skip, count);

        return outcomes.map(this.createModel, this);
    }

    public async getById(
        currentModel: IWorkflowOutcomesDataSourceModel,
        ids: number[]
    ): Promise<IWorkflowOutcomesDataSourceModel[]> {
        const outcomes = this.workflowOutcomesSettingsManager.getOutcomeByIds(ids);
        return outcomes.map(this.createModel, this);
    }

    public getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-worflow-outcome"];
    }

    public onItemBeginMove(model: IDataSourceModel<number, WorkflowOutcome>, dataTransfer: DataTransfer): void {
        const draggedOutcome: IDraggedOutcome = {
            Id: model.id,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData("application/prolife-worflow-outcome", JSON.stringify(draggedOutcome));
    }

    public async onItemMoved(
        dataTransfer: DataTransfer,
        model: IDataSourceModel<number, WorkflowOutcome>,
        before: boolean
    ): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-worflow-outcome") >= 0 && model) {
            const droppedOutcome = JSON.parse(
                dataTransfer.getData("application/prolife-worflow-outcome")
            ) as IDraggedOutcome;

            if (droppedOutcome.CompanyGuid == this.userInfo.getCurrentCompanyGuid()) {
                await this.workflowOutcomesSettingsManager.moveOutcome(
                    droppedOutcome.Id,
                    before ? model.model.Position : model.model.Position + 1
                );
                this.refresh();
            }
        }
    }

    private createModel(outcome: IWorkflowOutcome): IDataSourceModel<number, WorkflowOutcome> {
        return {
            id: outcome.Id,
            title: outcome.Description,
            isGroup: false,
            isLeaf: true,
            dragEnabled: true,
            icon: {
                icon: outcome.Icon,
                background: outcome.Background,
                foreground: outcome.Foreground,
            },
            model: new WorkflowOutcome(outcome, this),
        };
    }

    private textFilterMatch(carriage: IWorkflowOutcome, textFilter: string): boolean {
        const words = (textFilter || "").toLowerCase().trim().split("");
        return words.filter((w) => (carriage.Description || "").toLowerCase().indexOf(w) >= 0).length === words.length;
    }
}

export class WorkflowOutcome {
    Id: number;
    Position: number;

    Description: ko.Observable<string> = ko.observable();
    Outcome: ko.Observable<number> = ko.observable();
    Icon: ko.Observable<string> = ko.observable();
    Background: ko.Observable<string> = ko.observable();
    Foreground: ko.Observable<string> = ko.observable();

    PreventArticlesUnload: ko.Observable<boolean> = ko.observable();
    PreventArticlesLoad: ko.Observable<boolean> = ko.observable();
    PreventTasksCreationOrChanges: ko.Observable<boolean> = ko.observable();
    PreventWorkedHoursCreationOrChange: ko.Observable<boolean> = ko.observable();
    PreventPurchasesCreationOrChange: ko.Observable<boolean> = ko.observable();
    MoveWorkflowToState: ko.Observable<number> = ko.observable();

    OutcomeNames: ko.ObservableArray<{ Id: number; Text: string }> = ko.observableArray([
        { Id: 0, Text: ProlifeSdk.TextResources.Todolist.WorkflowOutcomeNotSet },
        { Id: 1, Text: ProlifeSdk.TextResources.Todolist.WorkflowOutcomePositive },
        { Id: 2, Text: ProlifeSdk.TextResources.Todolist.WorkflowOutcomeNegative },
    ]);

    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    private workflowOutcomesSettingsManager: IWorkflowOutcomeSettingsManager;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private updating = false;

    public Saving: ko.Observable<boolean> = ko.observable();

    constructor(private outcome: IWorkflowOutcome, private dataSource: WorkflowOutcomesDataSource) {
        this.Description.subscribe(this.onDataChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
        this.Outcome.subscribe(this.onDataChanged.bind(this));
        this.PreventArticlesUnload.subscribe(this.onDataChanged.bind(this));
        this.PreventArticlesLoad.subscribe(this.onDataChanged.bind(this));
        this.PreventTasksCreationOrChanges.subscribe(this.onPreventTasksCreationOrChangesChanged.bind(this));
        this.PreventWorkedHoursCreationOrChange.subscribe(this.onDataChanged.bind(this));
        this.PreventPurchasesCreationOrChange.subscribe(this.onDataChanged.bind(this));
        this.MoveWorkflowToState.subscribe(this.onDataChanged.bind(this));

        this.update(outcome);
    }

    private onPreventTasksCreationOrChangesChanged() {
        const oldUpdatingStatus = this.updating;

        this.updating = true;

        this.PreventPurchasesCreationOrChange(true);
        this.PreventWorkedHoursCreationOrChange(true);

        this.updating = oldUpdatingStatus;

        this.onDataChanged();
    }

    private async onDataChanged() {
        if (this.updating) return;

        this.Saving(true);

        try {
            await this.workflowOutcomesSettingsManager.createOrUpdate(this.getData());
        } catch {
            this.infoToastService.Error("Si è verificato un errore durante il salvataggio dell'Esito");
        } finally {
            this.Saving(false);
        }
    }

    public update(outcome: IWorkflowOutcome) {
        this.updating = true;

        this.Id = outcome.Id;
        this.outcome = outcome;
        this.Description(outcome.Description);
        this.Icon(outcome.Icon);
        this.Background(outcome.Background);
        this.Foreground(outcome.Foreground);
        this.Outcome(outcome.LogicalStatus);
        this.PreventArticlesUnload(outcome.PreventArticlesUnload);
        this.PreventArticlesLoad(outcome.PreventArticlesLoad);
        this.PreventTasksCreationOrChanges(outcome.PreventTasksEditing);
        this.PreventWorkedHoursCreationOrChange(outcome.PreventWorkedHoursCreationOrChange);
        this.PreventPurchasesCreationOrChange(outcome.PreventPurchasesCreationOrChange);
        this.MoveWorkflowToState(outcome.MoveWorkflowToState);
        this.Position = outcome.Position;

        this.updating = false;
    }

    public getData(): IWorkflowOutcome {
        const newState = $.extend({}, this.outcome);
        newState.Description = this.Description();
        newState.LogicalStatus = this.Outcome();
        newState.Icon = this.Icon();
        newState.Background = this.Background();
        newState.Foreground = this.Foreground();
        newState.PreventArticlesUnload = this.PreventArticlesUnload();
        newState.PreventArticlesLoad = this.PreventArticlesLoad();
        newState.PreventTasksEditing = this.PreventTasksCreationOrChanges();
        newState.PreventWorkedHoursCreationOrChange = this.PreventWorkedHoursCreationOrChange();
        newState.PreventPurchasesCreationOrChange = this.PreventPurchasesCreationOrChange();
        newState.MoveWorkflowToState = this.MoveWorkflowToState();
        return newState;
    }

    public async markDeleted(): Promise<void> {
        const result = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Todolist.SureToDeleteOutcome,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteOutcome,
            ProlifeSdk.TextResources.Todolist.DeleteOutcome
        );
        if (!result) return;

        this.workflowOutcomesSettingsManager.remove(this.Id).then(() => this.dataSource.refresh());
    }
}
