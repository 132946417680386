import * as ko from "knockout";
import { FeatureRequirement } from "./FeatureRecap";

export class Feature {
    Id: number;
    featureId: string;
    featureName: string;
    description: string;
    featureType: ko.Observable<string> = ko.observable();
    requirements: ko.ObservableArray<FeatureRequirement> = ko.observableArray();

    constructor(id: number, featureId?: string, name?: string, description?: string, type?: string, requirements: FeatureRequirement[] = []) {
        this.Id = id;
        this.featureId = featureId;
        this.featureName = name;
        this.description = description;
        this.featureType(type);
        this.requirements(requirements.slice());
    }
}
