import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { Tag } from "../../../ProlifeSdk/prolifesdk/blog/Tag";
import { EmailInput } from "../EventsDetails/EmailInput";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../Core/Deferred";

export class MailBlogEvent extends BlogEvent implements IView
{
    public viewModel : any;

    public MailSubject : ko.Observable<string> = ko.observable("");
    public MailFrom : ko.ObservableArray<Tag> = ko.observableArray();
    public MailTo : ko.ObservableArray<Tag> = ko.observableArray();
    public MailCc : ko.ObservableArray<Tag> = ko.observableArray();
    public MailCcn : ko.ObservableArray<Tag> = ko.observableArray();

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconClass = "fa-envelope";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.MailEventName;
        this.templateName = "mailEvent";
        this.Clear();
    }

    Clear()
    {
        super.Clear();
        this.MailSubject("");
        this.MailFrom([]);
        this.MailTo([]);
        this.MailCc([]);
        this.MailCcn([]);
    }

    public GetMailSubject() : string
    {
        return String.format(ProlifeSdk.TextResources.Blog.MailSubject, moment(this.ReferenceDate()).format("L"));
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody().then((b : string) => {

            var body = b;
            body += String.format(ProlifeSdk.TextResources.Blog.MailSubjectInBody, this.MailSubject());

            body += this.CreateMailBodyPartForList(this.MailFrom(), ProlifeSdk.TextResources.Blog.Senders);
            body += this.CreateMailBodyPartForList(this.MailTo(), ProlifeSdk.TextResources.Blog.Recipients);
            body += this.CreateMailBodyPartForList(this.MailCc(), ProlifeSdk.TextResources.Blog.CCs);
            body += this.CreateMailBodyPartForList(this.MailCcn(), ProlifeSdk.TextResources.Blog.BCCs);
            def.resolve(body);
        })
            .catch(() => def.reject());
        return def.promise();
    }

    getEditViewModel() : IEventBaseInput
    {
        return new EmailInput(this.serviceLocator,
            this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();

        // Email
        this.MailFrom([]);
        this.MailTo([]);

        this.Tags().forEach((item) => {

            if(item.TagName() == ProlifeSdk.TagEmail_Subject)
                this.MailSubject(item.Value());

            if(item.TagName() == ProlifeSdk.TagEmail_From)
                this.MailFrom.push(item);

            if(item.TagName() == ProlifeSdk.TagEmail_To)
                this.MailTo.push(item);

            if(item.TagName() == ProlifeSdk.TagEmail_Cc)
                this.MailCc.push(item);

            if(item.TagName() == ProlifeSdk.TagEmail_Ccn)
                this.MailCcn.push(item);
        });
    }
}