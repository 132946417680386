import * as ko from "knockout";
import { IMonthlyHour } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IHoursContainer } from "./IHoursContainer";
import { ISelectableHour } from "./ISelectableHour";
import { HourApprovalViewModel } from "./HourApprovalViewModel";
import { _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";

export class HoursContainer<T extends IHoursContainer> implements IHoursContainer {
    id: number;
    type: string;
    name: string;
    info: string;
    level: number;

    Children: ko.ObservableArray<T> = ko.observableArray([]);
    Hours: ko.ObservableArray<ISelectableHour> = ko.observableArray([]);
    
    HasSelectedChildren: ko.Computed<boolean>;
    HasSelectedHours: ko.Computed<boolean>;

    Collapsed: ko.Observable<boolean> = ko.observable(true);
    CanExpand: ko.Computed<boolean>;
    IsVisible: ko.Computed<boolean>;
    IsSelectable: ko.Computed<boolean>;
    Selected: ko.Computed<boolean>;

    private _selected: ko.Observable<boolean> = ko.observable(false);

    constructor(protected panel: _HoursApprovalPanelViewModel, private parent: IHoursContainer) {
        this.level = !this.parent ? 0 : this.parent.level + 1;
    }

    public initialize(): void {
        this.Children().forEach(c => c.initialize());
        
        this.IsVisible = ko.computed(() => {
            return !this.parent ? true : (!this.parent.Collapsed() && this.parent.IsVisible());
        });

        this.CanExpand = ko.computed(() => {
            return this.Children().length > 0;
        });

        this.HasSelectedChildren = ko.computed(() => {
            return this.Children().filter((h) => h.Selected()).length > 0;
        });
        
        this.IsSelectable = ko.computed(() => {
            return this.Children().length > 0 || this.Hours().filter(h => h.Hours().length > 0).length > 0;
        });

        this.Selected = ko.computed({
            read: () => {
                const selectableChildren = this.Children().filter(c => c.IsSelectable());
                const selectedChildren = selectableChildren.filter(child => child.Selected() || child.Selected() === null);
                const selected = this._selected();

                if (selectableChildren.length === 0)
                    return selected;

                if (selectedChildren.length === selectableChildren.length && selectedChildren.length > 0)
                    return true;

                if (selectedChildren.length !== selectableChildren.length && selectedChildren.length > 0)
                    return null;

                return false;
            },
            write: (value: boolean) => {
                const children = this.Children();
                for (const child of children) {
                    child.Selected(value);
                }

                this._selected(value);
            }
        });

        this.Hours().forEach(h => h.initialize());

        this.HasSelectedHours = ko.computed(() => {
            return this.Hours().filter((h) => h.Selected()).length > 0;
        });
    }
    
    public toggleCollapsed() {
        const newCollapsed = !this.Collapsed();
        this.Collapsed(newCollapsed);
    }

    public addHour(hour: IMonthlyHour): void {
        const hours = this.Hours();

        const foundHour = hours.find((h: HourApprovalViewModel) => hour.StartOfDay >= h.StartOfDay && hour.StartOfDay <= h.EndOfDay) as HourApprovalViewModel;

        if (foundHour)
            foundHour.addHour(hour);
    }

    public getSelectedHours(): IMonthlyHour[] {
        if (this.Children().length === 0)
            return this.Hours().filter(h => h.Selected()).selectMultiple(h => h.Hours());

        return this.Children().selectMultiple(c => c.getSelectedHours());
    }

    public getKeyForCache(): string {
        return (this.parent ? this.parent.getKeyForCache() + "|" : "") + this.id + "_" + this.type;
    }
}
