import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ParamArray, Param, ComponentParamArray, ComponentParam } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Attachment } from "../../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { AttachmentsManager } from "../../../../FileRepository/FileRepository/attachments/AttachmentsManager";
import { TheoreticalAllocation } from "../../../../ProlifeSdk/ProlifeSdk";
import { IAttachmentsManagerPathProvider, IAttachmentsManager } from "../../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { IFileRepositoryService } from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";

let attributes = {
    AttachmentsIds: "attachments-ids",
    AttachmentsManagerPathProvider: "attachments-manager-path-provider",
    InjectTo: "inject-to"
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "attachments-manager-popover": {
                params?: {
                    AttachmentsIds?: string,
                    AttachmentsManagerPathProvider?: string,
                    InjectTo?: string
                };

                "attachments-ids": string[] | (() => string);
                "attachments-manager-path-provider": IAttachmentsManagerPathProvider | (() => string);
                "inject-to": AttachmentsManagerPopoverComponent | (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

export interface IAttachmentsManagerPopoverComponentParams {
    AttachmentsIds: ParamArray<string>;
    AttachmentsManagerPathProvider: Param<IAttachmentsManagerPathProvider>;
    InjectTo: Param<AttachmentsManagerPopoverComponent>;
}

export class AttachmentsManagerPopoverComponent {
    public AttachmentsIds: ComponentParamArray<string>;
    public AttachmentsManagerPathProvider: ComponentParam<IAttachmentsManagerPathProvider>;
    public InjectTo: ComponentParam<AttachmentsManagerPopoverComponent>;

    public AttachmentsManager: IAttachmentsManager;

    public ShowAttachmentsActions: ko.Observable<boolean> = ko.observable(false);

    private attachmentsInterceptor: ko.Subscription;

    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;

    constructor(params: IAttachmentsManagerPopoverComponentParams) {
        this.AttachmentsIds = ComponentUtils.parseParameterArray(params.AttachmentsIds, []);
        this.AttachmentsManagerPathProvider = ComponentUtils.parseParameter(params.AttachmentsManagerPathProvider, undefined);
        this.InjectTo = ComponentUtils.parseParameter(params.InjectTo, undefined);

        this.AttachmentsManager = this.fileRepositoryService.createAttachmentsManager(this.AttachmentsManagerPathProvider());

        let attachmentsIds = this.AttachmentsIds();
        if (attachmentsIds.length > 0)
            this.AttachmentsManager.loadAttachments(this.AttachmentsIds());

        this.attachmentsInterceptor = this.AttachmentsManager.attachments.subscribe(() => {
            this.AttachmentsIds(this.AttachmentsManager.attachments().map(a => a.id));
        });

        this.InjectTo(this);
    }

    public SwitchAttachmentsActionsState(): void {
        this.ShowAttachmentsActions(!this.ShowAttachmentsActions());
    }

    public onClose(): void {
        this.attachmentsInterceptor.dispose();
    }
}

ko.components.register("attachments-manager-popover", {
    viewModel: {
        createViewModel: (params: IAttachmentsManagerPopoverComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new AttachmentsManagerPopoverComponent(params);

            let $parent: AttachmentsManagerPopoverComponent;
            let $data: AttachmentsManager;
            let attachment: Attachment;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="flex-container flex-vertical flex-full-height" data-as={{ vm }}>
                    <ko-bind data-bind={{ with: vm.AttachmentsManager }}>
                        <div class="header">
                            <div class="btn-group pull-right">
                                <button type="button" class="btn btn-default dropdown-toggle btn-fit-height" data-toggle="dropdown" data-close-others="true" data-delay="1000" data-bind={{ click: $parent.SwitchAttachmentsActionsState.bind($parent), clickBubble: false }}>
                                    {TextResources.Expenses.AttachmentsActions}
                                    <i class="fa fa-angle-down"></i>
                                </button>

                                <ul class="dropdown-menu pull-right" role="menu" data-bind={{ style: { display: ($parent.ShowAttachmentsActions() ? 'block' : 'none') } }}>
                                    <li>
                                        <a href="#" data-bind={{ click: $data.attachFromRepository.bind($data), clickBubble: false }}>
                                            <i class="fa fa-paperclip"></i>&nbsp;{TextResources.Expenses.AttachFile}
                                        </a>
                                    </li>
                                    <ko-bind data-bind={{ if: $data.selectedAttachments().length > 0 }}>
                                        <li>
                                            <a href="#" data-bind={{ click: $data.removeSelectedAttachments.bind($data), clickBubble: false }}>
                                                <i class="fa fa-trash-o"></i>&nbsp;{TextResources.Expenses.DeleteSelectedAttachments}
                                            </a>
                                        </li>
                                    </ko-bind>
                                    <ko-bind data-bind={{ if: $data.selectedAttachments().length === 1 }}>
                                        <li>
                                            <a href="#" data-bind={{ click: $data.downloadSelectedAttachment.bind($data), clickBubble: false }}>
                                                <i class="fa fa-download"></i>&nbsp;{TextResources.Expenses.DownloadAttachment}
                                            </a>
                                        </li>
                                    </ko-bind>
                                </ul>
                            </div>

                            <h4>{TextResources.Expenses.Attachments}</h4>
                        </div>
                        <div class="flex-container flex-vertical flex-fill" data-bind={{ fileDrop: $data.uploadAndAttach.bind($data) }}>
                            <div class="listview" style="min-height: 80px;">
                                <ko-bind data-bind={{ if: $data.attachments().length === 0 }}>
                                    <h4 class="text-center" style="margin: 0; padding-top: 20px; pointer-events: none;">{TextResources.Expenses.EmptyAttachmentsList}</h4>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: $data.attachments, as: 'attachment' } }}>
                                    <a href="#" class="list multi-select" data-bind={{ click: attachment.switchSelection.bind(attachment), dblClickEx: attachment.download.bind(attachment), css: { selected: attachment.selected } }}>
                                        <div class="list-content">
                                            <div class="icon file" data-bind={{ css: attachment.previewClasses, attr: { style: attachment.previewStyle } }}></div>
                                            <div class="data"></div>
                                            <span class="list-title" data-bind={{ text: attachment.fileName }}></span>
                                            <span class="list-remark" data-bind={{ visible: !!attachment.fileVersion(), text: attachment.formattedFileVersion() }}></span>
                                        </div>
                                    </a>
                                </ko-bind>
                            </div>
                        </div>
                    </ko-bind>
                </div>
            ]);

            return vm;
        },
    },
    template: []
});