import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ISettingsManager } from "../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { LazyImport } from "../../Core/DependencyInjection";
import { ISettingsService } from "../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IGDPRService, ITreatmentType } from "../GDPRService";
import { TreatmentTypesSettingsComponent } from "./ui/TreatmentTypesSettingsComponent";
import { Right } from "../../Core/Authorizations";

export interface ITreatmentTypesSettingsManager extends ISettingsManager {
    getTreatmentTypes(textFilter: string, showDeleted?: boolean, skip?: number, count?: number);
    getTreatmentTypesByIds(ids: number[]);
    insertOrUpdateTreatmentTypes(types: ITreatmentType[]);
}

export class TreatmentTypesSettingsManager implements ITreatmentTypesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<IGDPRService>())
    private GDPRService : IGDPRService;

    private treatmentTypes: ITreatmentType[] = [];

    @Right("GDPR_CanWriteTreatmentTypes")
    private canWriteTreatmentTypes: boolean;

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.GDPR.DataManagement);
    }

    public getTreatmentTypes(textFilter: string, showDeleted?: boolean, skip: number = 0, count: number = 1000) {
        return this.treatmentTypes.filter(x => this.matchTextFilter(x, textFilter) && (showDeleted || x.Deleted === false)).slice(skip, skip + count);
    }

    public getTreatmentTypesByIds(ids: number[]) {
        return this.treatmentTypes.filter(x => ids.any(i => i == x.Id));
    }

    public async insertOrUpdateTreatmentTypes(items: ITreatmentType[]){
        await this.GDPRService.InsertOrUpdateTreatmentTypes(items);
        await this.load();
    }

    async load() {
        this.treatmentTypes = await this.GDPRService.GetTreatmentTypes(null, true, 0, 50);
    }

    getName() {
        return nameof<ITreatmentTypesSettingsManager>();
    }

    getLabel() {
        return TextResources.GDPR.TreatmentTypes;
    }

    hasEditingUI() {
        return this.canWriteTreatmentTypes;
    }

    getEditingUI() {
        return new TreatmentTypesSettingsComponent({TreatmentTypesSettingsManager: this});
    }

    private matchTextFilter(item: ITreatmentType, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (item.Name.toLowerCase().indexOf(word.toLowerCase()) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}