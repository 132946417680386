import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class WorkflowsSearchEntityProvider implements IEntityProvider<string, string> {
    constructor() {}

    getType(): string {
        return "$$HINTWORD$$";//ProlifeSdk.WorkflowEntityType;
    }

    getEntityTypeName(): string {
        return ProlifeSdk.TextResources.Todolist.Workflows;
    }
    
    getPkValue(item: string) {
        return item;
    }
    
    getDisplayName(item: string): string {
    
        return item;
    }
    
    getDisplayNameForKeyValue(pkValue: string): Promise<string> {
        return Promise.resolve(pkValue);
    }
    
    hintSearch(stringFilter: string): Promise<string[]> {
        return Promise.resolve([stringFilter]);
    }
    
    getEntity(pkValue: string): Promise<string> {
        return Promise.resolve(pkValue);
    }
    
    getControlsProvider(): IControlsEntityProvider {
        return undefined;
    }
}