import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Requirement } from "./Requirement";
import { Portlet, PortletActions, PortletBody, PortletHeader } from "../../../Components/Portlet";
import { Layout, LayoutRow } from "../../../Components/Layouts";
import { TextInput } from "../../../Components/TextInput";
import jss from "jss";
import { DateTimeInput } from "../../../Components/DateTimeInput";
import { RichTextInput } from "../../../Components/RichTextInput";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { FeatureEditorDialog } from "../Features/FeatureEditorDialog";
import { FeatureRequirement } from "../Features/FeatureRecap";
import { IInfrastructureRequirement } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";


const styleSheet = jss.createStyleSheet({
    requirementCells: {
        "& > div": {
            padding: "5px",
        }
    }

}).attach();

const { classes } = styleSheet;

type FunctionPointComputationRequirementEditorProps = {
    requirement: ko.Observable<Requirement>;
    onSave: (r: Requirement) => void;
    onDelete: (r: Requirement) => void;
}

export function FunctionPointComputationRequirementEditor(props: FunctionPointComputationRequirementEditorProps) {
    const C = require("./FunctionPointComputationRequirementEditor")._FunctionPointComputationRequirementEditor as typeof _FunctionPointComputationRequirementEditor;
    return <C {...props} />;
}

export class _FunctionPointComputationRequirementEditor extends React.Component<FunctionPointComputationRequirementEditorProps> {
    static defaultProps: Partial<FunctionPointComputationRequirementEditorProps> = {
    }

    id: ko.Observable<number> = ko.observable();
    requirementId: ko.Observable<string> = ko.observable();
    requirementDescription: ko.Observable<string> = ko.observable();
    requirementNote: ko.Observable<string> = ko.observable();
    requirementRealeseDate: ko.Observable<Date> = ko.observable();
    requirementDocPart: ko.Observable<string> = ko.observable();
    requirementRequest: ko.Observable<string> = ko.observable();
    requirementStatementId: ko.Observable<number> = ko.observable();
    requirementInfrastructureId: ko.Observable<number> = ko.observable();
    title: ko.Computed<string>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(props: FunctionPointComputationRequirementEditorProps) {
        super(props);

        useEffect(() => {
            const r = this.props.requirement();
            if (!r) {
                this.id(2);
                this.requirementId("");
                this.requirementDescription("");
                this.requirementNote("");
                this.requirementRealeseDate(new Date());
                this.requirementDocPart("");
                this.requirementRequest("");
                this.requirementStatementId();
            }
            else {
                this.id(r.Id);
                this.requirementId(r.RequirementId);
                this.requirementDescription(r.RequirementDescription);
                this.requirementNote(r.RequirementNote());
                this.requirementRealeseDate(r.RequirementRealeseDate());
                this.requirementDocPart(r.RequirementDocPart());
                this.requirementRequest(r.RequirementRequest());
                this.requirementStatementId(r.RequirementStatementId());
            }

        }, [this.props.requirement]);

        this.title = ko.computed(() => {
            const req = this.props.requirement();
            if (!req) return "Nuovo Requisito";
            if (req.Id <= 0) return "Nuovo Requisito";
            return "Modifica Requisito";
        });
    }
    async addFeature() {
        /*         let dialog = new FeatureEditorDialog({ requirementId: this.requirementId });
                dialog.title = ko.observable("Aggiungi Requisito");
                let result = await this.dialogsService.ShowModal<FeatureRequirement>(dialog);
                this.features.push(result); */
    }

    onSave () {
    //   this.props.onSave(r);
    }
    onCancel(){
/*         const oldRequirement = this.props.requirement();
        this.id(oldRequirement.Id);
        this.requirementInfrastructureId(oldRequirement.InfrastructureId);
        this.requirementId(oldRequirement.requirementId);
        this.requirementDescription(oldRequirement.requirementDescription);
        this.requirementNote(oldRequirement.requirementNote());
        this.requirementRealeseDate(oldRequirement.requirementRealeseDate());
        this.requirementDocPart(oldRequirement.requirementDocPart());
        this.requirementRequest(oldRequirement.requirementRequest()); */
    }
    onDelete(){
    //    this.props.onDelete(this.props.requirement());
    }
    render() {
        return <Portlet collapsible={false} >
            <PortletHeader>
                <PortletHeader.Default title={this.title}></PortletHeader.Default>
            </PortletHeader>
            <PortletActions>
                <div className={"flex-container"}>
                    <button className={"btn btn-success btn-circle btn-sm"} onClick={this.onSave}>
                        <i className={"fa fa-floppy-o"} /> &nbsp;Salva
                    </button>
                    <button className={"btn btn-danger btn-circle btn-sm"} onClick={this.onDelete}>
                        <i className={"fa fa-trash-o"} /> &nbsp;Elimina
                    </button>
                    <button className={"btn btn-warning btn-circle btn-sm"} onClick={this.onCancel}>
                        <i className={"fa fa-trash-o"} /> &nbsp;Annulla
                    </button>
                    <button className={"btn btn-primary btn-circle btn-sm"} onClick={this.addFeature.bind(this)}>
                        <i className={"fa fa-plus"} />&nbsp;Aggiungi Funzione
                    </button>
                </div>
            </PortletActions>
            <PortletBody >
                {() =>
                    <>
                        <Layout.Grid columns={["1fr", "1fr", "1fr", "1fr"]} rows={["auto", "auto", "1fr"]} className={classes.requirementCells}>
                            <Layout.Grid.Cell column={1} row={1}>
                                <TextInput label={"Codice"} value={this.requirementId} className={"flex-1"} placeholder="Inserire il codice funzione" />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={2} row={1} noOverflow={false}>
                                <DateTimeInput label={"Data Rilascio"} value={this.requirementRealeseDate} dateonly />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={3} row={1} >
                                <TextInput label={"Parte documento"} value={this.requirementDocPart} className={"flex-1"} />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={"1/5"} row={2} >
                                <TextInput label={"Richiesta"} value={this.requirementRequest} className={"flex-1"} />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={"1/3"} row={3} >
                                <RichTextInput label={"Descrizione"} value={this.requirementDescription} />
                            </Layout.Grid.Cell>
                            <Layout.Grid.Cell column={"3/5"} row={3} >
                                <RichTextInput label={"Note Aggiuntive"} value={this.requirementNote} />
                            </Layout.Grid.Cell>
                        </Layout.Grid>

                    </>}
            </PortletBody>
        </Portlet>
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(FunctionPointComputationRequirementEditor);
}