import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 08/06/2018
 * Time: 16:58
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { TrustAuthorizationRequiredDialog } from "./invoices/ui/customerTrust/TrustAuthorizationRequiredDialog";
import { TrustAuthorizationRequestDialog } from "./invoices/ui/customerTrust/TrustAuthorizationRequestDialog";
import { TrustAuthorizationAbortDialog } from "./invoices/ui/customerTrust/TrustAuthorizationAbortDialog";
import { Document } from "./invoices/documents/Document";
import { LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IService } from "../Core/interfaces/IService";
import { IDesktopService } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ITrustAuthorizationResult, ITrustAuthorizationRequest, IRequestAuthorizationResult, IResourceForTrustAuthorization, IReplyToAuthorizationRequest, ITrustAuthorizationResponse, IDocumentForTrustAuthorizationsHistory, IAuthorizationForHistory, IPendingTrustAuthorizationRequest, ITrustAuthorizationProcessObserver, ITrustAuthorizationRequestDialog, ITrustAuthorizationRequiredDialog } from "../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";
import { IChangesNotificationsService } from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

export interface ITrustAuthorizationProcessService extends IService {
    ShowTrustAuthorizationRequiredDialog(document: Document, trustOverflow: number): Promise<ITrustAuthorizationResult>;
    ShowTrustAuthorizationRequestDialog(request: ITrustAuthorizationRequest, pendingState?: boolean): Promise<void>;
    ShowTrustAuthorizationAbortDialog(request: ITrustAuthorizationRequest): Promise<void>;

    ExecuteAuthorizationProcess(document: Document, trustAuthorizationResult: ITrustAuthorizationResult): Promise<ITrustAuthorizationResult>;

    RequestAuthorization(request: ITrustAuthorizationRequest): Promise<IRequestAuthorizationResult>;
    AbortAuthorizationRequest(requestId: string, sendTo: IResourceForTrustAuthorization[]): Promise<void>;
    ReplyToAuthorizationRequest(response: IReplyToAuthorizationRequest): Promise<ITrustAuthorizationResponse>;
    GetPendingRequestsForCurrentUser(): Promise<ITrustAuthorizationRequest[]>;
    GetOnLineUsersWithRightsForAuthorization(): Promise<IResourceForTrustAuthorization[]>;
    GetPagedDocumentsForTrustAuthorizationsHistory(skip: number, count: number): Promise<IDocumentForTrustAuthorizationsHistory[]>;
    GetAuthorizationsHistoryForDocument(documentId: number, documentType: string): Promise<IAuthorizationForHistory[]>;
    GetPagedTrustAuthorizationsHistoryForCustomer(customerId: number, skip: number, count: number): Promise<IAuthorizationForHistory[]>;
    GetCustomerPendingAuthorizationRequests(customerId: number): Promise<IPendingTrustAuthorizationRequest>;
    OnCustomerPendingRequests(pendingRequest: IPendingTrustAuthorizationRequest): Promise<void>;

    //VerifyTrustOverflow(customerId: number, jobOrderId: number, documentId: number, documentType: string, documentTotalPrice: number, documentRowsWithReferencesTotalPrice: number): JQueryPromise;
}

export class TrustAuthorizationProcessService implements ITrustAuthorizationProcessService, ITrustAuthorizationProcessObserver {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;
    
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private trustAuthorizationRequestDialog: ITrustAuthorizationRequestDialog;
    private trustAuthorizationRequiredDialog: ITrustAuthorizationRequiredDialog;
    private authorizationsService: IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ITrustAuthorizationProcessService>(), this);
    }

    InitializeService() {
        this.changesNotificationsService.RegisterTrustAuthorizationProcessObserver(this);

        this.LoadPendingRequestsForCurrentUser();
    }

    getServiceType(): string {
        return ProlifeSdk.TrustAuthorizationProcessServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    ShowTrustAuthorizationRequiredDialog(document: Document, trustOverflow: number): Promise<ITrustAuthorizationResult> {
        this.trustAuthorizationRequiredDialog = new TrustAuthorizationRequiredDialog(document, trustOverflow);
        return this.trustAuthorizationRequiredDialog.showModal();
    }

    ShowTrustAuthorizationRequestDialog(request: ITrustAuthorizationRequest, pendingState: boolean = false): Promise<void> {
        this.trustAuthorizationRequestDialog = new TrustAuthorizationRequestDialog(request, pendingState);
        return this.trustAuthorizationRequestDialog.showModal();
    }

    ShowTrustAuthorizationAbortDialog(request: ITrustAuthorizationRequest): Promise<void> {
        var dialog = new TrustAuthorizationAbortDialog(request);
        return dialog.show();
    }

    GetPendingRequestsForCurrentUser(): Promise<ITrustAuthorizationRequest[]> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetPendingRequestsForCurrentUser", {});
    }

    GetOnLineUsersWithRightsForAuthorization(): Promise<IResourceForTrustAuthorization[]> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetOnLineUsersWithRightsForAuthorization", {});
    }

    GetPagedDocumentsForTrustAuthorizationsHistory(skip: number, count: number): Promise<IDocumentForTrustAuthorizationsHistory[]> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetPagedDocumentsForTrustAuthorizationsHistory", {
            methodData: {
                Skip: skip,
                Count: count
            }
        });
    }

    GetAuthorizationsHistoryForDocument(documentId: number, documentType: string): Promise<IAuthorizationForHistory[]> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetAuthorizationsHistoryForDocument", {
            methodData: {
                DocumentId: documentId,
                DocumentType: documentType
            }
        });
    }

    GetPagedTrustAuthorizationsHistoryForCustomer(customerId: number, skip: number, count: number): Promise<IAuthorizationForHistory[]> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetPagedTrustAuthorizationsHistoryForCustomer", {
            methodData: {
                CustomerId: customerId,
                Skip: skip,
                Count: count
            }
        });
    }

    GetCustomerPendingAuthorizationRequests(customerId: number): Promise<IPendingTrustAuthorizationRequest> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "GetCustomerPendingAuthorizationRequests", {
            methodData: {
                CustomerId: customerId
            }
        });
    }

    async ExecuteAuthorizationProcess(document: Document, trustAuthorizationResult: ITrustAuthorizationResult): Promise<ITrustAuthorizationResult> {
        if (!document.Recipient.Id()) {
            trustAuthorizationResult.Authorized = true;
            return trustAuthorizationResult;
        }

        var userWithExtraTrustRights: boolean = this.authorizationsService.isAuthorized("Customers_CanApproveExtraTrust");
        if (trustAuthorizationResult.TrustOverflow > 0) {
            let result = await this.GetCustomerPendingAuthorizationRequests(document.Recipient.Id())

            if (result) {
                await this.OnCustomerPendingRequests(result);
                trustAuthorizationResult.Authorized = false;
                return trustAuthorizationResult;
            }

            if (userWithExtraTrustRights) {
                var message: string = ProlifeSdk.TextResources.Invoices.ConfirmCustomerTrustOverflow.replace("##trust_value##", trustAuthorizationResult.TrustOverflow.toFixed(2));
                trustAuthorizationResult.Authorized = await this.ConfirmTrustOverflow(message);
                return trustAuthorizationResult;
            }

            return await this.ShowTrustAuthorizationRequiredDialog(document, trustAuthorizationResult.TrustOverflow)
        }

        trustAuthorizationResult.Authorized = true;
        return trustAuthorizationResult;
    }

    RequestAuthorization(request: ITrustAuthorizationRequest): Promise<IRequestAuthorizationResult> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "RequestAuthorization", {
            methodData: request
        });
    }

    AbortAuthorizationRequest(requestId: string, sendTo: IResourceForTrustAuthorization[]): Promise<void> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "AbortAuthorizationRequest", {
            methodData: {
                RequestId: requestId,
                SendTo: sendTo
            }
        });
    }

    ReplyToAuthorizationRequest(response: IReplyToAuthorizationRequest): Promise<ITrustAuthorizationResponse> {
        return this.ajaxService.Post("Invoices-api/TrustAuthorizationProcess", "ReplyToAuthorizationRequest", {
            methodData: response
        });
    }

    OnTrustAuthorizationRequest(request: ITrustAuthorizationRequest): void {
        this.ShowTrustAuthorizationRequestDialog(request);
    }

    OnTrustAuthorizationRequestAbort(requestId: string): void {
        if (this.trustAuthorizationRequestDialog)
            this.trustAuthorizationRequestDialog.onTrustAuthorizationAbort();

        if (this.trustAuthorizationRequiredDialog)
            this.trustAuthorizationRequiredDialog.onTrustAuthorizationAbort();

        if (this.desktopService.IsRequestOnEvaluation(requestId)) {
            this.desktopService.RemoveTrustAuthorizationPendingRequest(requestId);
            this.infoToastService.Info(ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAborted);
        }
    }

    OnTrustAuthorizationResponse(response: ITrustAuthorizationResponse): void {
        if (!this.authorizationsService.isAuthorized("Customers_CanApproveExtraTrust")) {
            this.ProcessResponseAsApplicantUser(response);
            return;
        }

        this.ProcessResponseAsAuthorizedUser(response);
    }

    OnCustomerPendingRequests(pendingRequest: IPendingTrustAuthorizationRequest): Promise<void> {
        var message = !pendingRequest.ResourceId ?
            String.format(ProlifeSdk.TextResources.Invoices.CustomerWithPendingAuthorizationRequestText, moment(pendingRequest.RequestDate).format("L LT"), pendingRequest.ApplicantResourceName) :
            String.format(ProlifeSdk.TextResources.Invoices.CustomerWithPendingAuthorizationRequestOnEvalText, moment(pendingRequest.RequestDate).format("L LT"), pendingRequest.ApplicantResourceName, pendingRequest.ResourceName);

        return this.dialogsService.AlertAsync(
            message,
            ProlifeSdk.TextResources.Invoices.CustomerWithPendingAuthorizationRequestLabel
        );
    }

    private ProcessResponseAsApplicantUser(response: ITrustAuthorizationResponse): void {
        if (!this.trustAuthorizationRequiredDialog)
            return;

        if (!this.trustAuthorizationRequiredDialog.isOpened())
            return;

        this.trustAuthorizationRequiredDialog.onTrustAuthorizationResponse(response);
    }

    private ProcessResponseAsAuthorizedUser(response: ITrustAuthorizationResponse): void {
        this.DoOperationsOnRequestDialog(response);
        this.DoOperationsOnNotificationCenter(response);
    }

    private async DoOperationsOnNotificationCenter(response: ITrustAuthorizationResponse): Promise<void> {
        if (response.SendByMe) {
            if (this.desktopService.IsRequestOnEvaluation(response.RequestId) && response.Answer != ProlifeSdk.TrustAuthorizationRequestOnEvaluation) {
                this.desktopService.RemoveTrustAuthorizationPendingRequest(response.RequestId);
                return;
            }

            if (response.Answer == ProlifeSdk.TrustAuthorizationRequestOnEvaluation) {
                let request = await this.GetAuthorizationRequest(response.RequestId);
                if (!request)
                    return;
                this.desktopService.AddTrustAuthorizationPendingRequests([request]);
            }
        }
    }

    private async GetAuthorizationRequest(requestId: string): Promise<ITrustAuthorizationRequest> {
        var request: ITrustAuthorizationRequest = null;

        if (this.trustAuthorizationRequestDialog)
            request = this.trustAuthorizationRequestDialog.getRequest();

        if (request)
            return request;

        let pendingRequests = await this.LoadPendingRequestsForCurrentUser();    
        let filteredRequests = pendingRequests.filter((r) => r.Id == requestId);
        return filteredRequests.length > 0 ? filteredRequests[0] : null;
    }

    private DoOperationsOnRequestDialog(response: ITrustAuthorizationResponse): void {
        if (!this.trustAuthorizationRequestDialog)
            return;

        if (!this.trustAuthorizationRequestDialog.isOpened())
            return;

        this.trustAuthorizationRequestDialog.onTrustAuthorizationResponse(response);
    }

    private async LoadPendingRequestsForCurrentUser(): Promise<ITrustAuthorizationRequest[]> {
        let pendingRequests = await this.GetPendingRequestsForCurrentUser();
        this.desktopService.AddTrustAuthorizationPendingRequests(pendingRequests);
        return pendingRequests;
    }

    private ConfirmTrustOverflow(message: string): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(
            message,
            ProlifeSdk.TextResources.Invoices.No,
            ProlifeSdk.TextResources.Invoices.Yes,
        );
    }
}

export default function Create(serviceLocator : IServiceLocator): IService {
    return new TrustAuthorizationProcessService(serviceLocator);
}