import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import { ICatalogsService, ICatalog } from "../ProlifeSdk/interfaces/warehouse/ICatalogsService";

export class CatalogsDataSource implements IDataSource {
    private manufacturerId: number = null;
    private view: IDataSourceView;

    @LazyImport(nameof<ICatalogsService>())
    private catalogsService: ICatalogsService;

    public setManufacturerId(manufacturer: number): void {
        this.manufacturerId = manufacturer;
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public getTitle(currentModel: IDataSourceModel<number, ICatalog>): string {
        return ProlifeSdk.TextResources.Warehouse.CatalogsDataSourceTitle;
    }

    public async getData(
        currentModel: IDataSourceModel<number, ICatalog>,
        textFilter: string,
        skip = 0,
        count = 1000000
    ): Promise<IDataSourceModel<number, ICatalog>[]> {
        const catalogs: ICatalog[] = await this.catalogsService.getCatalogs(
            textFilter,
            this.manufacturerId,
            skip,
            count
        );
        return catalogs.map(this.createModel, this);
    }

    public async getById(
        currentModel: IDataSourceModel<number, ICatalog>,
        ids: number[]
    ): Promise<IDataSourceModel<number, ICatalog>[]> {
        const catalog: ICatalog = await this.catalogsService.getCatalogById(ids.length > 0 ? ids[0] : null);
        return [this.createModel(catalog)];
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public isGroupedData(currentModel: IDataSourceModel<number, ICatalog>, textFilter: string): boolean {
        return false;
    }

    public areEqual(a: IDataSourceModel<number, ICatalog>, b: IDataSourceModel<number, ICatalog>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModel(catalog: ICatalog): IDataSourceModel<number, ICatalog> {
        return {
            id: catalog.Id,
            title: catalog.Description,
            isGroup: false,
            isLeaf: true,
            model: catalog,
        };
    }
}
