import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { WithodingTaxAccountingSoftwareMappings } from "./ui/WithodingTaxAccountingSoftwareMappings";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IWitholdingTaxSettingsManager, IWithholdingTaxMapping } from "../../../ProlifeSdk/interfaces/invoice/settings/IWitholdingTaxSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class WitholdingTaxSettingsManager implements IWitholdingTaxSettingsManager
{
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<void>
    {
        //Per ora non fa niente perchè gestiamo solo i mapping per l'export
        //verso i SW di contabilità
        return Promise.resolve<void>(undefined);
    }

    getName(): string {
        return ProlifeSdk.WitholdingTax;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.WitholdingTax;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "witholding-taxes",
            templateUrl: "invoices/templates/settings",
            viewModel: new WithodingTaxAccountingSoftwareMappings(this)
        };
    }

    GetAccountingSwMappings() : Promise<IWithholdingTaxMapping[]>
    {
        return this.ajaxService.Get("Invoices-api", "RatesCodesMappings", { });
    }

    UpdateAccountingSwMappings(mappings : IWithholdingTaxMapping[]) : Promise<void>
    {
        return this.ajaxService.Post("Invoices-api", "RatesCodesMappings", {
            methodData : mappings
        });
    }
}
