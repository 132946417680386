/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 19/09/2017
 * Time: 10:54
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { TeamViewModel } from "./TeamViewModel";
import { PlannerGanttItem, PlannerGanttItemInterval } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";

import * as moment from "moment";
import { Moment } from "moment";
import { TeamAllocationViewModel } from "./TeamAllocationViewModel";
import { ResourceOperationalUnitViewModel } from "./ResourceOperationalUnitViewModel";
import { ResourceAllocationRangeViewModel } from "./ResourceAllocationRangeViewModel";
import { IResourceOperationalUnitViewModel } from "./TeamsManager";
import { IHumanResourceOrders } from "../../../Users/HumanResourcesService";
import { IGanttWrapper } from "../../interfaces/IGanttWrapper";

export class TeamsFirstRenderer {
    constructor(private Gantt : IGanttWrapper) {

    }

    public render(team : TeamViewModel) {
        const newTeam = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
        newTeam.Id(team.Id());
        newTeam.Type("Team"); // TODO refactoring

        newTeam.Color("#b5cff9");
        newTeam.ProgressColor("#5997fb");
        newTeam.Progress(0);
        newTeam.Tag(team);
        newTeam.Title(team.Name());
        newTeam.StartDate(team.StartDate().toDate());
        newTeam.EndDate(team.EndDate().toDate());

        newTeam.loadOpeningState();

        this.renderAllocations(team, newTeam);
        this.renderTeam(newTeam);
        
        // team.Name.subscribe((n) => newTeam.Title(n));
        // team.StartDate.subscribe((d) => newTeam.StartDate(d.toDate()));
        // team.EndDate.subscribe((d) => newTeam.EndDate(d.toDate()));
        // team.Allocations.subscribe(this.renderAllocations.bind(this, team, newTeam));

        this.Gantt.Gantt.Items.push(newTeam);

        /* var teamRenderInterceptor = ko.computed(() => {
            this.renderTeam(newTeam);
        }); */
    }

    public remove(team : TeamViewModel) {
        const foundItem = this.Gantt.Gantt.Items().filter((i : PlannerGanttItem) => i.Tag() == team);
        foundItem.forEach(i => {
            this.Gantt.Gantt.Items.remove(i);
        });
    }

    private renderTeam(teamItem: PlannerGanttItem): void {
        let allIntervals = [];
        const intervals = [];

        teamItem.Children().forEach((i: PlannerGanttItem) => {
            allIntervals = allIntervals.concat(i.Intervals());
        });

        allIntervals.sort((a : PlannerGanttItemInterval, b : PlannerGanttItemInterval) => moment(a.StartDate()).diff(moment(b.StartDate()), 'days'));

        let firstDate : PlannerGanttItemInterval = null;
        let sum = 0;

        allIntervals.forEach((i: PlannerGanttItemInterval) => {
            if (!firstDate || moment(firstDate.StartDate()).valueOf() != moment(i.StartDate()).valueOf()) {
                if (firstDate) {
                    const interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
                    interval.Color("rgb(111, 166, 255)");
                    interval.BorderColor("rgb(34, 118, 253)");
                    interval.FontColor("#FFFFFF");
                    intervals.push(interval);
                }

                firstDate = i;
                sum = 0;
            }

            sum = sum + i.Value();
        });
        if (firstDate) {
            const interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
            interval.Color("rgb(111, 166, 255)");
            interval.BorderColor("rgb(34, 118, 253)");
            interval.FontColor("#FFFFFF");
            intervals.push(interval);
        }

        teamItem.Intervals(intervals);
    }

    private renderAllocations(team : TeamViewModel, parent : PlannerGanttItem) {
        const allocations = [];

        team.Allocations().forEach((alloc : TeamAllocationViewModel) => {
            const newAllocation = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
            newAllocation.Id(alloc.Id);
            newAllocation.Type("Allocation"); // TODO refactoring

            newAllocation.Color("#FF8080");
            newAllocation.ProgressColor("rgba(0, 0, 0, 0.40)");
            newAllocation.Progress(0);
            newAllocation.MultipleIntervals(true);
            newAllocation.Title(alloc.ResourceName());
            
            newAllocation.loadOpeningState();

            this.renderOperationalUnits(alloc, newAllocation);

            /* var renderInterceptor = ko.computed(() => {
                this.renderResourceAllocation(alloc, newAllocation);
            }); */
            this.renderResourceAllocation(alloc, newAllocation);

            // alloc.ResourceName.subscribe((n) => newAllocation.Title(n));
            // alloc.OperationalUnits.subscribe(this.renderOperationalUnits.bind(this, alloc, newAllocation));

            allocations.push(newAllocation);
        });

        parent.Children(allocations);
    }

    private renderResourceAllocation(alloc : TeamAllocationViewModel, newAllocation : PlannerGanttItem) {
        const intervals = [];
        let allIntervals = [];

        newAllocation.Children().forEach((c : PlannerGanttItem) => {
            allIntervals = allIntervals.concat(c.Intervals());
        });

        allIntervals.sort((a : PlannerGanttItem, b : PlannerGanttItem) => moment(a.StartDate()).diff(moment(b.StartDate()), 'days'));

        let firstDate : PlannerGanttItemInterval = null;
        let sum = 0;

        allIntervals.forEach((i: PlannerGanttItemInterval) => {
            if (!firstDate || moment(firstDate.StartDate()).valueOf() != moment(i.StartDate()).valueOf()) {
                if (firstDate) {
                    const interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
                    const refValue: number = this.GetServiceOrdersTotalDailyHours(alloc.OperationalUnits(), moment(firstDate.StartDate()));
                    this.Gantt.SetIntervalStyle(interval, sum, refValue);
                    intervals.push(interval);
                }

                firstDate = i;
                sum = 0;
            }

            sum = sum + i.Value();
        });
        if (firstDate) {
            const interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
            const refValue: number = this.GetServiceOrdersTotalDailyHours(alloc.OperationalUnits(), moment(firstDate.StartDate()));
            this.Gantt.SetIntervalStyle(interval, sum, refValue);
            intervals.push(interval);
        }

        newAllocation.Intervals(intervals);
    }

    private renderOperationalUnits(alloc : TeamAllocationViewModel, parent : PlannerGanttItem, subscribe  = true) {
        const operationalUnits = [];

        alloc.OperationalUnits().forEach((ou : ResourceOperationalUnitViewModel) => {
            /* if(subscribe)
                ou.AllocationRanges.subscribe(() => this.renderOperationalUnits(alloc, parent, false));
 */
            if(ou.AllocationRanges().length == 0)
                return;

            const newAllocation = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
            newAllocation.Id(ou.Id);
            newAllocation.Type("OperationalUnit"); // TODO refactoring
            newAllocation.Title(ou.Name());
            newAllocation.StartDate(ou.AbsoluteStartDate().toDate());
            newAllocation.EndDate(ou.AbsoluteEndDate().toDate());

            newAllocation.loadOpeningState();

            // ou.AbsoluteStartDate.subscribe((d) => newAllocation.StartDate(d.toDate()));
            // ou.AbsoluteEndDate.subscribe((d) => newAllocation.EndDate(d.toDate()));

            this.renderRanges(ou, newAllocation);

            operationalUnits.push(newAllocation);
        });

        parent.Children(operationalUnits);

        /* if(subscribe)
            alloc.OperationalUnits.subscribe(() => this.renderOperationalUnits(alloc, parent, false)); */
    }

    private renderRanges(ou : ResourceOperationalUnitViewModel, parent : PlannerGanttItem, subscribe  = true) {
        let ranges = [];

        ou.AllocationRanges().forEach((range : ResourceAllocationRangeViewModel) => {
            const intervals = this.splitRangeToDays(ou, range);

            /* if(subscribe) {
                range.StartDate.subscribe(d => this.renderRanges(ou, parent, false));
                range.EndDate.subscribe(d => this.renderRanges(ou, parent, false));
                range.Amount.subscribe(a => this.renderRanges(ou, parent, false));
            } */

            ranges = ranges.concat(intervals);
        });

        parent.Intervals(ranges);
    }

    private splitRangeToDays(ou : ResourceOperationalUnitViewModel, range : ResourceAllocationRangeViewModel) : PlannerGanttItemInterval[] {
        const ranges = [];
        const serviceOrders = ou.ServiceOrders();
        const startDate = moment(range.StartDate());
        const endDate = moment(range.EndDate());
        const amount = (range.Amount() / 100);

        for(let d = startDate; d <= endDate; d = moment(d.add('days', 1))) {
            const serviceOrder = this.findServiceOrder(serviceOrders, d);
            const allocatedHours = range.AllocationMode() == ProlifeSdk.PercentageEditigMode ?
                this.getWorkedHoursForDay(serviceOrder, d, range) * amount :
                this.getWorkedHoursForDay(serviceOrder, d, range);

            if(allocatedHours == 0)
                continue;

            const newRange = new PlannerGanttItemInterval(d.toDate(), d.toDate(), allocatedHours, this.Gantt.Gantt.StartDate);
            this.Gantt.SetIntervalStyle(newRange, allocatedHours, this.getServiceOrderHoursForDay(serviceOrder, d));

            ranges.push(newRange);
        }

        return ranges;
    }

    private findServiceOrder(serviceOrders : IHumanResourceOrders[], refDay : Moment) : IHumanResourceOrders {
        const foundServiceOrders = serviceOrders.filter(o => refDay >= moment(o.FromDate) && refDay < moment(o.ToDate || moment("2100-01-01")));
        if(foundServiceOrders.length > 0)
            return foundServiceOrders[0];
        return null;
    }

    private getWorkedHoursForDay(serviceOrder : IHumanResourceOrders, refDay : Moment, range: ResourceAllocationRangeViewModel) : number {
        const dayOfWeek = refDay.day();

        switch(dayOfWeek) {
            case 0:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSunday) : range.HoursSunday();
            case 1:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursMonday) : range.HoursMonday();
            case 2:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursTuesday) : range.HoursTuesday();
            case 3:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursWednesday) : range.HoursWednesday();
            case 4:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursThursday) : range.HoursThursday();
            case 5:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursFriday) : range.HoursFriday();
            case 6:
                return range.AllocationMode() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSaturday) : range.HoursSaturday();
            default:
                return 0;
        }
    }

    private getServiceOrderHoursForDay(serviceOrder : IHumanResourceOrders, refDay : Moment) : number {
        const dayOfWeek = refDay.day();

        switch(dayOfWeek) {
            case 0:
                return serviceOrder.HoursSunday;
            case 1:
                return serviceOrder.HoursMonday;
            case 2:
                return serviceOrder.HoursTuesday;
            case 3:
                return serviceOrder.HoursWednesday;
            case 4:
                return serviceOrder.HoursThursday;
            case 5:
                return serviceOrder.HoursFriday;
            case 6:
                return serviceOrder.HoursSaturday;
            default:
                return 0;
        }
    }

    private GetServiceOrdersTotalDailyHours(operationalUnits: IResourceOperationalUnitViewModel[], refDay: Moment): number {
        let sum = 0;
        operationalUnits.forEach((ou: ResourceOperationalUnitViewModel) => {
            const serviceOrders = ou.ServiceOrders();
            if (serviceOrders.length > 0) {
                const serviceOrder = this.findServiceOrder(serviceOrders, refDay);
                if (serviceOrder) {
                    sum = sum + this.getServiceOrderHoursForDay(serviceOrder, refDay);
                }
            }
        });
        return sum;
    }
}