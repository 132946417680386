import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
//import { JobOrdersNavigationMenu } from "./ui/navigation/JobOrdersNavigationMenu";
import { TypeSelectionMenu } from "./ui/navigation/TypeSelectionMenu";
import { StateSelectionMenu } from "./ui/navigation/StateSelectionMenu";
import { ProductionActivitiesPrintDialog } from "./ui/dialogs/ProductionActivitiesPrintDialog";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IJobOrderServiceUIProvider } from "../../ProlifeSdk/interfaces/job-order/IJobOrderServiceUIProvider";
import { IProductionActivitiesPrintDialog } from "../../ProlifeSdk/interfaces/job-order/IProductionActivitiesPrintDialog";
import { INavigationMenu } from "../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";

export class JobOrderServiceUIProvider implements  IJobOrderServiceUIProvider
{
	public toclickLoadCommessa : boolean;
	public jobOrdersList : any;
	constructor(private serviceLocator : IServiceLocator)
    {
	}

    //Deprecated
    /*getJobOrderList(jobOrderClickManager : IJobOrderMenuActionsManager) : IJobOrderNavigationMenu
    {
        return new JobOrdersNavigationMenu(this.serviceLocator, jobOrderClickManager, true);
    }*/

    getTypesNavigationMenu() : INavigationMenu
    {
        return new TypeSelectionMenu(this.serviceLocator);
    }

    getStatesNavigationMenu() : INavigationMenu
    {
        return new StateSelectionMenu(this.serviceLocator);
    }

    getProductionActivitiesPrintDialog(jobOrderId: number, selectedWorkflow: number = null): IProductionActivitiesPrintDialog {
        return new ProductionActivitiesPrintDialog(this.serviceLocator, jobOrderId, selectedWorkflow);
    }

	newCommessa()
    {
		location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/0";
	}

	loadCommesse()
    {
		location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL;
	}

	loadCommessa(commessaid : number)
    {
		location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + commessaid;
	}

    notifyChanges(jobOrderId : number)
    {
    }

}