import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ConveyancesEditingViewModel } from "./ui/ConveyancesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IConveyances, IConveyance, IConveyancesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IConveyances";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class ConveyancesSettingsManager implements IConveyances {
    private ajaxService : IAjaxService;
    private conveyances : IConveyance[] = [];
    private observers : IConveyancesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer : IConveyancesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IConveyancesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IConveyance[]> {
        return this.ajaxService.Get<IConveyance[]>("Invoices-api", "Conveyance", {})
            .then((conveyances) => this.conveyances = conveyances)
    }

    getName(): string {
        return ProlifeSdk.Conveyance;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.DeliveryType;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "setting",
            templateUrl: "settings/templates",
            viewModel: new ConveyancesEditingViewModel(this.serviceLocator, this)
        };
    }

    getConveyances() : IConveyance[] {
        return this.conveyances;
    }

    getConveyanceIdByName(conveyanceName : string) : number {
        var conveyancesWithName = this.conveyances.filter((conveyance : IConveyance) => conveyance.Descrizione == conveyanceName);
        if(conveyancesWithName.length == 0) return 0;
        return conveyancesWithName[0].Id;
    }

    createOrUpdate(conveyance : IConveyance) : Promise<IConveyance> {
        if(conveyance.Id > 0) {
            return this.ajaxService.Put<IConveyance>("Invoices-api", "Conveyance", { methodData: conveyance})
                .then(this.onConveyanceChanged.bind(this));
        }

        return this.ajaxService.Post<IConveyance>("Invoices-api", "Conveyance", { methodData: conveyance})
            .then(this.onConveyanceAdded.bind(this));
    }

    remove(conveyanceId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Invoices-api", "Conveyance/" + conveyanceId, {})
            .then(this.onConveyanceDeleted.bind(this, conveyanceId));
    }

    private onConveyanceChanged(conveyance : IConveyance) {
        this.load();
        this.observers.forEach((obs) => obs.onConveyanceChanged(conveyance));
    }

    private onConveyanceAdded(conveyance : IConveyance) {
        this.load();
        this.observers.forEach((obs) => obs.onConveyanceAdded(conveyance));
    }

    private onConveyanceDeleted(conveyanceId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onConveyanceDeleted(conveyanceId));
    }
}