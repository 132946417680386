import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ImportDocumentDataWizard, IDocumentDataWizardRow } from "./ImportDocumentDataWizard";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ImportDocumentDataWizardGroupingStep } from "./ImportDocumentDataWizardGroupingStep";
import { ImportDocumentDataWizardTransformStep } from "./ImportDocumentDataWizardTransformStep";
import { ImportDocumentDataWizardIvaSelectionStep } from "./ImportDocumentDataWizardIvaSelectionStep";
import { ImportDocumentDataWizardReportStep } from "./ImportDocumentDataWizardReportStep";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

export class SourcesSelectionDocumentDataWizardStep extends DocumentDataWizardStep {
    public DataSources: ko.ObservableArray<DocumentDataWizardStep> = ko.observableArray();

    private dataSources: DocumentDataWizardStep[] = [];

    constructor(private wizard: ImportDocumentDataWizard) {
        super();
        this.Title(TextResources.ProlifeSdk.SelectDataSources);
    }

    async Initialize(initializationInfo : IWizardInitializationInfo) : Promise<void> {
        super.Initialize(initializationInfo);

        this.dataSources = this.documentsService.getDataWizardStepsForDocumentType(initializationInfo.DocTypeCode).filter(ds => ds.CanShow(initializationInfo));
        this.dataSources.forEach(s => s.IsSelected(false));

        let steps = this.dataSources.slice();

        steps.push(new ImportDocumentDataWizardTransformStep());
        steps.push(new ImportDocumentDataWizardGroupingStep());
        steps.push(new ImportDocumentDataWizardIvaSelectionStep());
        steps.push(new ImportDocumentDataWizardReportStep());

        for (let step of steps) {
            await step.Initialize(initializationInfo);
        }

        this.DataSources(steps);
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    CanGoNext() : boolean {
        var selected = this.dataSources
            .filter(s => s.IsSelected());

        if (selected.length == 0)
        {
            this.infoToastService.Warning(TextResources.ProlifeSdk.SelectAtLeastOneDataSource);
            return false;
        }


        return true;
    }

    OnShow(previousRows: IDocumentDataWizardRow[]): void {

    }

    OnNext(): IDocumentDataWizardRow[] {
        var selected = this.DataSources()
            .filter(s => s.IsSelected());

        this.wizard.SetDataSources(selected);
        return [];
    }
    
    render() {
        let step : SourcesSelectionDocumentDataWizardStep;
        let source : DocumentDataWizardStep;

        return ComponentUtils.bindTo(
            <div style="padding: 20px">
                <div class="row">
                    <pre>
                        {TextResources.Invoices.DocumentWizardSourceSelectionInformation}
                    </pre>
                    <br />
                    <br />
                </div>
                <div class="row">
                    <ul class="list-group" data-bind={{foreach: { data: step.DataSources, as: 'source' }}}>
                        <li class="list-group-item" style="cursor : pointer" data-bind={{text : source.Title, toggle : source.IsSelected, toggleActiveClass : 'list-group-item-success', visible: source.IsVisible}}></li>
                    </ul>
                </div>
            </div>
        , this, "step");
    }
}