import * as ko from "knockout";
import { Export, LazyImport } from "../Core/DependencyInjection";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IInsertOrUpdateExcelExportConfigurationRequest {
    id?: number;
    loginId?: number;
    exporterId?: string;
    label?: string;
    fileId?: string;
}

export interface IExcelExportConfiguration {
    Id : number;
    LoginId?: number;
    Label?: string;
    FileId : string;
}

export interface IExcelExportService extends IService {
    RemoveExcelExportConfiguration(id : number | null) : Promise<void>;
    GetExcelExportConfigurations(loginId : number | null, exporterId : string | null) : Promise<IExcelExportConfiguration[]>;
    InsertOrUpdateExcelExportConfiguration(request : IInsertOrUpdateExcelExportConfigurationRequest) : Promise<IExcelExportConfiguration>;
    GetExcelExportConfigurationsByIds(ids : number[] | null) : Promise<IExcelExportConfiguration[]>;
}

@Export(nameof<IExcelExportService>())
class ExcelExportService implements IExcelExportService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    RemoveExcelExportConfiguration(id : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Core-api/ExcelExportConfigurations", "RemoveExcelExportConfiguration", {
            background: true,
            methodData: {
                id: id,
            }
        });
    }

    GetExcelExportConfigurations(loginId : number | null, exporterId : string | null) : Promise<IExcelExportConfiguration[]> {
        return this.ajaxService.Post<IExcelExportConfiguration[]>("Core-api/ExcelExportConfigurations", "GetExcelExportConfigurations", {
            background: true,
            methodData: {
                loginId: loginId,
                exporterId: exporterId,
            }
        });
    }

    InsertOrUpdateExcelExportConfiguration(request : IInsertOrUpdateExcelExportConfigurationRequest) : Promise<IExcelExportConfiguration> {
        return this.ajaxService.Post<IExcelExportConfiguration>("Core-api/ExcelExportConfigurations", "InsertOrUpdateExcelExportConfiguration", {
            background: true,
            methodData: request
        });
    }

    GetExcelExportConfigurationsByIds(ids: number[] | null): Promise<IExcelExportConfiguration[]> {
        return this.ajaxService.Post<IExcelExportConfiguration[]>("Core-api/ExcelExportConfigurations", "GetExcelExportConfigurationsByIds", {
            background: true,
            methodData: {
        		ids: ids,
        	}
        });
    }
}

export default function Create() {
    return new ExcelExportService();
}
