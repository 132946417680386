import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IUsersService } from "../../UsersService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IUser } from "../../../ProlifeSdk/interfaces/users/IUser";

export class UsersControlsEntityProvider implements IControlsEntityProvider
{
    private usersService : IUsersService;
    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor(serviceLocator : IServiceLocator)
    {
        this.usersService = <IUsersService> serviceLocator.findService(ProlifeSdk.UsersServiceType);
    }

    /***
     * Estrae gli id già impostati sull'elemento e li ritorna in formato lista
     * @param element
     * @param callback
     */
    public findMultipleEntities(element, callback)
    {
        var ids : string[] = (<string>$(element).val()).split("|");
        if(ids.length == 0)
            return;

        this.usersService.getCompanyUsers().then((data : IUser[]) => {
            var result : any[] = [];
            ids.map((s : string) => { return parseInt(s); })
                .forEach((id : number) => {
                    var matches = data.filter((u : IUser) => { return u.IdUser == id});

                    if(matches.length > 0)
                        result.push({
                            id: id,
                            text: this.usersService.GetFullName(matches[0])
                        });
                });

            callback(result);
        });
    }

    public findEntities(query : any)
    {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.usersService.getCompanyUsers(query.term)
                .then((data) => {
                    query.callback({
                        results: data.map((user : IUser) => {
                            return {
                                id: user.IdUser,
                                text: user.Nome + " " + user.Cognome
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback)
    {
        var id = $(element).val();
        if(id !== "" && id > 0) {
            this.usersService.getUserFromKeyValue(<number>id)
                .then((user : IUser) => callback({
                    id: user.IdUser,
                    text: user.Nome + " " + user.Cognome
                }));
        }
    }
}
