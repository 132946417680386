import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 20/06/2017
 * Time: 16:55
 * To change this template use File | Settings | File Templates.
 */

import { IQuestion } from "../../../../ProlifeSdk/interfaces/survey/IQuestion";

export class Question {
    public Id: number;
    public TypeId: ko.Observable<number> = ko.observable();
    public TypeName: ko.Observable<string> = ko.observable();
    public Text: ko.Observable<string> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Enabled: ko.Observable<boolean> = ko.observable();
    public Index: ko.Observable<number> = ko.observable();
    public ParentId: ko.Observable<number> = ko.observable();
    public ParentLabel: ko.Observable<string> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public QuestionnaireId: number;
    public AnswerTypeId: ko.Observable<number> = ko.observable();
    public AnswerTypeName: ko.Observable<string> = ko.observable();
    public PublicLabel: ko.Observable<string> = ko.observable();

    constructor(question: IQuestion) {
        this.Id = question.Id;
        this.TypeId(question.TypeId);
        this.TypeName(question.TypeName);
        this.Text(question.Text);
        this.Description(question.Description);
        this.Enabled(question.Enabled);
        this.Index(question.Index);
        this.ParentId(question.ParentId);
        this.ParentLabel(question.ParentLabel);
        this.CreationDate(question.CreationDate);
        this.ModifyDate(question.ModifyDate);
        this.CreatorId(question.CreatorId);
        this.ModifierId(question.ModifierId);
        this.Label(question.Label);
        this.QuestionnaireId = question.QuestionnaireId;
        this.AnswerTypeId(question.AnswerTypeId);
        this.AnswerTypeName(question.AnswerTypeName);
        this.PublicLabel(question.PublicLabel);
    }

    public ToJSON(): IQuestion {
        return <IQuestion> {
            Id: this.Id,
            TypeId: this.TypeId(),
            TypeName: this.TypeName(),
            Text: this.Text(),
            Description: this.Description(),
            Enabled: this.Enabled(),
            Index: this.Index(),
            ParentId: this.ParentId(),
            ParentLabel: this.ParentLabel(),
            CreationDate: this.CreationDate(),
            ModifyDate: this.ModifyDate(),
            CreatorId: this.CreatorId(),
            ModifierId: this.ModifierId(),
            Label: this.Label(),
            QuestionnaireId: this.QuestionnaireId,
            AnswerTypeId: this.AnswerTypeId(),
            AnswerTypeName: this.AnswerTypeName(),
            PublicLabel: this.PublicLabel()
        };
    }
}