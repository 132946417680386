import * as ko from "knockout";
/**
 * Created by d.bucchi on 12/06/2017.
 */

import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";

export class SubContextMenuDialog implements IDialog {
    private dialogsService: IDialogsService;

    public templateName: string;
    public templateUrl: string;
    public title: string;
    modal: { close: (result?: any) => void; };

    private showed: boolean = false;
    private modalDeferred: Promise<void> = null;

    constructor(private serviceLocator: IServiceLocator, public contextActions: any, templateName: string, templateUrl: string, title?: string) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.templateName = templateName;
        this.templateUrl = templateUrl;
        this.title = title || "";
    }

    public ShowModal(): Promise<void> {
        if (this.showed)
            return this.modalDeferred;

        this.showed = true;
        return this.modalDeferred = this.dialogsService.ShowModal<void>(this, "fullscreen", {}, this.templateUrl, this.templateName);
    }

    goRun(action) {
        this.modal.close();
        action.Run();
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.modal.close();
    }
}