import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { ProvisioningApplication } from "./Provisioning/ProvisioningApplication";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { RequestForQuotationDocumentProvider } from "./Provisioning/documents/RequestForQuotationDocumentProvider";
import { RequestForQuotationDocumentProtocolType } from "./Provisioning/documents/RequestForQuotationDocumentProtocolType";
import { RequestForQuotationDocumentRefDetailsViewModelFactory } from "./Provisioning/documents/RequestForQuotationDocumentRefDetailsViewModelFactory";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IDocumentCurrency } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IEntityProviderService } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IVatRegisters, IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { PurchaseRequestProtocolType } from "./Provisioning/documents/PurchaseRequestProtocolType";
import { PurchaseRequestDocumentProvider } from "./Provisioning/documents/PurchaseRequestDocumentProvider";
import { RequestForQuotationDataSource } from "./Provisioning/documents/data-sources/RequestForQuotationDataSource";

export interface IIntList {
    Id : number;
}

export interface IRequestForQuotationResponseData {
    ResponseAmount : ko.Subscribable<number>;
    ResponsePrice : ko.Observable<number>;
    ResponseDeliveryDate : ko.Subscribable<Date>;
    ResponseDate : ko.Observable<Date>;
}

export interface IPreOrderRowSuppliersForRequestsForQuotations {
    Id?: number;
    PreOrderRowId?: number;
    SupplierId?: number;
}

export interface IPreOrderRowSupplierForRequest {
    PreOrderRowId : number;
    SupplierForRqeuestRowId : number;
    SupplierForRequestId : number;
    SupplierForRequestName : string;
    RequestForQuotationResponseId : number;
    RequestForQuotationResponseAmount : number;
    RequestForQuotationResponseDate : Date;
    RequestForQuotationResponseDeliveryDate : Date;
    RequestForQuotationResponsePrice : number;
    RequestForQuotationId : number;
    RequestForQuotationNumber : string;
    RequestForQuotationDate : Date;
    RequestForQuotationStateId : number;
    RequestForQuotationSendingDate : Date;
    AssignedFromPreOrder : boolean;
}

export interface IGetPreOrderRowsForListRequest {
    TextFilter?: string;
    PreOrderId?: number;
    EntityId?: number;
    EntityType?: string;
    StateId?: number;
    Skip?: number;
    Count?: number;
}

export interface IPreOrderRowForList {
    Id : number;
    EntityId : number;
    EntityType : string;
    Amount : number;
    UoM : string;
    PreOrderId : number;
    EntityDescription : string;
    StateId : number;
    DeliveryDate? : Date;
    NeededAmount : number;
    SuppliersList : string;
    SuppliersCount : number;
    SuppliersWithAnswerCount : number;
    TotalCost : number;
    Discounts: string;
    UnitCost : number;
}

export interface IPreOrderRow {
    RowId?: number;
    EntityId : number;
    EntityType?: string;
    Amount : number;
    UoM?: string;
    PreOrderId : number;
    EntityDescription?: string;
    StateId?: number;
    DeliveryDate?: Date;
    RowSourceId?: number;
    CustomerOrderId : number;
    CustomerOrderRowId : number;
    CustomerOrderRowNumber : number;
    EntityRefId : number;
    RefAmount : number;
    RefUoM?: string;
    CustomerOrderRowDescription?: string;
    EntityRefDescription?: string;
}

export interface IPreOrderRowWithDetails {
    Id : number;
    PreOrderId : number;
    EntityId : number;
    EntityType?: string;
    Amount?: number;
    UoM?: string;
    EntityDescription?: string;
    StateId : number;
    DeliveryDate?: Date;
    SourceId : number;
    CustomerOrderId?: number;
    CustomerOrderRowId : number;
    CustomerOrderRowNumber : number;
    EntityRefId : number;
    RefAmount?: number;
    RefUoM?: string;
    CustomerOrderRowDescription?: string;
    EntityRefDescription?: string;
    CustomerOrderRowDeliveryDate?: Date;
    AmountOnRefRow?: number;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    NetScaledPrice?: number;
    ArticleCode?: string;
    StockAmount?: number;
    OthersWarehousesStock?: number;
    CustomerOrderNumber?: string;
    CustomerOrderDate?: Date;
    ProtocolId?: number;
    Protocol?: string;
    OrderAmountForArticle?: number;
    JobOrderId?: number;
    JobOrderName?: string;
    CustomerId?: number;
    CustomerName?: string;
    WarehouseId?: number;
    SourceWarehouse?: string;
    CustomerOrderHasBeenDeleted?: boolean;
    CustomerOrderRowHasBeenDeleted?: boolean;
    RefDocumentRowHasBeenDeleted?: boolean;
    PurchaseHasBeenChanged?: boolean;
    RequestForQuotationResponseId?: number;
    RequestForQuotationResponseAmount?: number;
    RequestForQuotationResponseDate?: Date;
    RequestForQuotationResponseDeliveryDate?: Date;
    RequestForQuotationResponsePrice?: number;
    RequestForQuotationId?: number;
    RequestForQuotationNumber?: string;
    RequestForQuotationDate?: Date;
    RequestForQuotationSendingDate?: Date;
    RequestForQuotationStateId?: number;
    SupplierForRequestId?: number;
    SupplierForRequestName?: string;
    SupplierForRequestRowId?: number;
    AssignedFromPreOrder?: boolean;
}

export interface IPreOrderRowSupplierWithDetails {
    SupplierForRequestRowId : number;
    SupplierForRequestId : number;
    SupplierForRequestName?: string;
    RequestForQuotationId : number;
    RequestForQuotationNumber?: string;
    RequestForQuotationDate?: Date;
    RequestForQuotationStateId : number;
    RequestForQuotationResponseAmount?: number;
    RequestForQuotationResponsePrice?: number;
    RequestForQuotationResponseDeliveryDate?: Date;
    RequestForQuotationResponseDate?: Date;
    RequestForQuotationSendingDate?: Date;
    AssignedFromPreOrder?: boolean;
}

export interface IGetRequestsForQuotationsRequest {
    SupplierId?: number;
    DateFrom?: Date;
    DateTo?: Date;
    StateId?: number;
    TextFilter?: string;
    Skip?: number;
    Count?: number;
}

export interface IRequestForQuotationCustomerJobOrder {
    Id?: number;
    CustomerId?: number;
    JobOrderId?: number;
    RequestForQuotationId?: number;
}

export interface IRequestForQuotationCustomerAndJobOrder {
    Id : number;
    CustomerId : number;
    JobOrderId?: number;
    RequestForQuotationId : number;
}

export interface IProvisioningServiceObserver {
    onRequestForQuotationAdded(requestForQuotation : IRequestForQuotationDocument) : void;
    onRequestForQuotationChanged(requestForQuotation : IRequestForQuotationDocument) : void;
    onRequestForQuotationDeleted(requestForQuotationId : number) : void;
    onRequestForQuotationLockStateChanged(requestForQuotationId : number, locked : boolean) : void;
}

export interface IVATRowType {
    Order?: number;
    VATType?: string;
    TaxableIncome?: number;
    VAT?: number;
    Tax?: number;
    Total?: number;
    FKVat?: number;
    Nature?: string;
    ChargeableVat?: string;
    VatRegulationsCode?: string;
}

export interface IRequestForQuotationDocumentResult0 {
    DocumentId?: number;
    DocumentNumber?: string;
}

export interface IRequestForQuotationDocumentResult1 {
    OldId?: number;
    NewId?: number;
}

export interface IRequestForQuotationDocumentType_Type {
    Id?: number;
    EntityType?: string;
    FKCompany?: number;
    FKRegistro : number;
    Numero?: string;
    Data?: Date;
    FKCliente?: number;
    FKTipoPagamento?: number;
    Totale?: number;
    ProgressivoRegIVA?: number;
    FkStato : number;
    FkTipoValidita?: number;
    Congelato : number;
    Eliminato : number;
    FkEsercizio : number;
    EstimateStateId : number;
    SalId?: number;
    OrderId?: number;
    Discount?: string;
    Rounding?: number;
    FinalTotal?: number;
    CompanyLogo?: string;
    HtmlFooter?: string;
    HeaderImage?: string;
    LayoutId : number;
    NumberPrefix?: string;
    NumberSuffix?: string;
    FkCommercialResponsible?: number;
    CommercialResponsibleName?: string;
    FkAdministrativeResponsible?: number;
    AdministrativeResponsibleName?: string;
    PagamentoIBAN?: string;
    PagamentoABI?: string;
    PagamentoCAB?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    ExternalReference?: string;
    LastGeneratedDocument?: string;
    SearchField?: string;
    FKTipoScadenza?: number;
    TipoScadenza?: string;
    LegalPerson : boolean;
    TotalPaid?: number;
    TotalToBePaid?: number;
    FkOutcome?: number;
}

export interface IRequestForQuotationDocument_Type {
    FkRequestForQuotationDocument : number;
    RagioneSocialeCompany?: string;
    PIVACompany?: string;
    CFCompany?: string;
    IndirizzoCompany?: string;
    CAPCompany?: string;
    CittaCompany?: string;
    ProvinciaCompany?: string;
    StatoCompany?: string;
    RagioneSocialeCliente?: string;
    PIVACliente?: string;
    CFCliente?: string;
    IndirizzoCliente?: string;
    CAPCliente?: string;
    CittaCliente?: string;
    ProvinciaCliente?: string;
    StatoCliente?: string;
    TipoPagamento?: string;
    Note?: string;
    NoteAmministrazione?: string;
    Descr1?: string;
    Descr2?: string;
    Descr3?: string;
    TemplatesCodeCompany?: string;
    ComuneCompany?: string;
    TelefonoCompany?: string;
    FaxCompany?: string;
    MailCompany?: string;
    WebsiteCompany?: string;
    RegImpreseCompany?: string;
    REACompany?: string;
    CapSocialeCompany?: number;
    TipoValidita?: string;
    LogoCodeCompany?: string;
    EstimateStateName?: string;
    OrderName?: string;
    NomeCliente?: string;
    CognomeCliente?: string;
    ComuneCliente?: string;
}

export interface IRecipientInfoType_Type {
    CustomerId?: number;
    OrganizationalUnitId?: number;
    CompanyName?: string;
    CustomerName?: string;
    CustomerSurname?: string;
    VATNumber?: string;
    TaxCode?: string;
    Address?: string;
    PostalCode?: string;
    City?: string;
    Municipality?: string;
    Province?: string;
    State?: string;
    PublicAdministration?: boolean;
    ElectronicInvoicing?: boolean;
    EORICode?: string;
    LegalPerson?: boolean;
}

export interface IRequestForQuotationDocumentRowType_Type {
    IdRequestForQuotationDocumentRow : number;
    FKRequestForQuotationDocument : number;
    Descrizione?: string;
    Quantita?: number;
    TipoQuantita?: string;
    Importo?: number;
    TotaleRiga?: number;
    IndiceRiga?: number;
    QuantitaScaricata?: number;
    Ordine : number;
    EntityType?: string;
    Udm?: string;
    Discounts?: string;
    NetUnitPrice?: number;
    ClosedAmount?: number;
    ManuallyClosed : boolean;
    DueDate?: Date;
    Currency?: string;
    OffsetId?: number;
    OffsetCode?: string;
}

export interface IReferenceType_Type {
    RefId?: number;
    DestinationId?: number;
    DestinationEntityType?: string;
    CatalogId?: number;
    SalId?: number;
    Id?: number;
    EntityType?: string;
    Amount?: number;
    AmountType?: string;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    TotalPrice?: number;
    WarehouseId?: number;
}

export interface IAttachmentType_Type {
    AttachmentId?: string;
    IncludeInElectronicInvoice?: boolean;
}

export interface IRelatedDocumentType_Type {
    Id?: number;
    DocumentId : number;
    DocumentType?: string;
    RelatedDocumentId?: number;
    RelatedDocumentType?: string;
    RelatedDocumentNumber?: string;
    RelatedDocumentDate : Date;
    RelatedDocumentProtocolId?: number;
    RelatedDocumentCIG?: string;
    RelatedDocumentCUP?: string;
    CreditNote : boolean;
    AccompanyingInvoice : boolean;
    SALInvoice : boolean;
    RowsIndices?: string;
}

export interface IRelatedWorkflow_Type {
    Id?: number;
    WorkflowId : number;
    DocumentId : number;
    DocumentType?: string;
    DocumentNumber?: string;
    DocumentDate : Date;
    DocumentProtocolId : number;
    RowId : number;
    RowDescription?: string;
    RowAmount : number;
    RowUoM?: string;
    RowPrice : number;
    WorkflowAmount : number;
    ValidityStartDate?: Date;
}

export interface IRequestForQuotationDocumentForList {
    Id : number;
    CustomerName?: string;
    Number?: string;
    Date?: Date;
    Locked : number;
    EstimateStateId : number;
    Total?: number;
    ClosureStatus?: number;
    IsApproved : number;
    FKRegistro : number;
}

export interface IRequestForQuotationDocumentResult2 {
    Id : number;
    EntityType : string;
    FKCompany?: number;
    FKRegistro : number;
    Numero?: string;
    Data?: Date;
    FKCliente?: number;
    FKTipoPagamento?: number;
    Totale?: number;
    ProgressivoRegIVA?: number;
    FkStato : number;
    FkTipoValidita?: number;
    Congelato : number;
    Eliminato : number;
    FkEsercizio : number;
    EstimateStateId : number;
    SalId?: number;
    OrderId?: number;
    Discount?: string;
    Rounding?: number;
    FinalTotal?: number;
    CompanyLogo?: string;
    HtmlFooter?: string;
    HeaderImage?: string;
    LayoutId : number;
    NumberPrefix?: string;
    NumberSuffix?: string;
    FkCommercialResponsible?: number;
    CommercialResponsibleName?: string;
    FkAdministrativeResponsible?: number;
    AdministrativeResponsibleName?: string;
    PagamentoIBAN?: string;
    PagamentoABI?: string;
    PagamentoCAB?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    ExternalReference?: string;
    LastGeneratedDocument?: string;
    SearchField?: string;
    FKTipoScadenza?: number;
    TipoScadenza?: string;
    LegalPerson : boolean;
    TotalPaid?: number;
    TotalToBePaid?: number;
    FkOutcome?: number;
}

export interface IRequestForQuotationDocumentResult3 {
    FkRequestForQuotationDocument : number;
    RagioneSocialeCompany?: string;
    PIVACompany?: string;
    CFCompany?: string;
    IndirizzoCompany?: string;
    CAPCompany?: string;
    CittaCompany?: string;
    ProvinciaCompany?: string;
    StatoCompany?: string;
    RagioneSocialeCliente?: string;
    PIVACliente?: string;
    CFCliente?: string;
    IndirizzoCliente?: string;
    CAPCliente?: string;
    CittaCliente?: string;
    ProvinciaCliente?: string;
    StatoCliente?: string;
    TipoPagamento?: string;
    Note?: string;
    NoteAmministrazione?: string;
    Descr1?: string;
    Descr2?: string;
    Descr3?: string;
    TemplatesCodeCompany?: string;
    ComuneCompany?: string;
    TelefonoCompany?: string;
    FaxCompany?: string;
    MailCompany?: string;
    WebsiteCompany?: string;
    RegImpreseCompany?: string;
    REACompany?: string;
    CapSocialeCompany?: number;
    TipoValidita?: string;
    LogoCodeCompany?: string;
    EstimateStateName?: string;
    OrderName?: string;
    NomeCliente?: string;
    CognomeCliente?: string;
    ComuneCliente?: string;
}

export interface IRequestForQuotationDocumentResult4 {
    IdRequestForQuotationDocumentRow : number;
    FKRequestForQuotationDocument : number;
    Descrizione?: string;
    Quantita?: number;
    Importo?: number;
    TotaleRiga?: number;
    IndiceRiga?: number;
    QuantitaScaricata?: number;
    Ordine : number;
    EntityType : string;
    Udm?: string;
    Discounts?: string;
    NetUnitPrice?: number;
    ClosedAmount?: number;
    ManuallyClosed : boolean;
    DueDate?: Date;
    Currency?: string;
    OffsetId?: number;
    OffsetCode?: string;
}

export interface IRelatedDocumentType {
    Id?: number;
    DocumentId : number;
    DocumentType?: string;
    RelatedDocumentId?: number;
    RelatedDocumentType?: string;
    RelatedDocumentNumber?: string;
    RelatedDocumentDate : Date;
    RelatedDocumentProtocolId?: number;
    RelatedDocumentCIG?: string;
    RelatedDocumentCUP?: string;
    CreditNote : boolean;
    AccompanyingInvoice : boolean;
    SALInvoice : boolean;
    RowsIndices?: string;
}

export interface IRequestForQuotationForList {
    Id : number;
    Number?: string;
    CreationDate : Date;
    LastModificationDate : Date;
    StateId : number;
    DateOfSending?: Date;
    SupplierId : number;
    CreationResourceId : number;
    LastModificationResourceId : number;
    SendingResourceId?: number;
    Description?: string;
    SupplierName?: string;
    CreationResourceName?: string;
    LastModificationResourceName?: string;
    SendingResourceName?: string;
}


export interface IRequestForQuotation_Type_Row_Shipments {
    id: number;
    shipmentDate: Date;
    amount: number;
    fkWarehouse: number;
    address: string;
    city: string;
    postalCode: string;
    province: string;
    state: string;
    isDefault: boolean;
}
export interface IRequestForQuotation_Type_Row {
    id: number;
    responsePrice: number;
    responseAmount: number;
    responseDeliveryDate: Date;
    responseDate: Date;
    dueDate: Date;
    verified: boolean;
    notes: string;
    shipments: IRequestForQuotation_Type_Row_Shipments[];
}

export interface IRequestForQuotation_Type {
    id: number;
    stateId: number;
    supplierId: number;
    description: string;
    number: string;
    rows: IRequestForQuotation_Type_Row[];
}

export interface IRequestForQuotation {
    Id : number;
    Number?: string;
    CreationDate : Date;
    LastModificationDate : Date;
    StateId : number;
    DateOfSending?: Date;
    SupplierId : number;
    CreationResourceId : number;
    LastModificationResourceId : number;
    SendingResourceId?: number;
    Description?: string;
}

export interface IRequestForQuotationDocumentResult9 {
    Id : number;
    DocumentId : number;
    DocumentType : string;
    CurrencyId : number;
    ExchangeValue : number;
    ExchangeValueForVat?: number;
    DocumentCurrency : boolean;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsDocument {
    Id : number;
    EntityType : string;
    FKCompany?: number;
    FKRegistro : number;
    Numero?: string;
    Data?: Date;
    FKCliente?: number;
    FKTipoPagamento?: number;
    Totale?: number;
    ProgressivoRegIVA?: number;
    FkStato : number;
    FkTipoValidita?: number;
    Congelato : number;
    Eliminato : number;
    FkEsercizio : number;
    EstimateStateId : number;
    SalId?: number;
    OrderId?: number;
    Discount?: string;
    Rounding?: number;
    FinalTotal?: number;
    CompanyLogo?: string;
    HtmlFooter?: string;
    HeaderImage?: string;
    LayoutId : number;
    NumberPrefix?: string;
    NumberSuffix?: string;
    FkCommercialResponsible?: number;
    CommercialResponsibleName?: string;
    FkAdministrativeResponsible?: number;
    AdministrativeResponsibleName?: string;
    PagamentoIBAN?: string;
    PagamentoABI?: string;
    PagamentoCAB?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    ExternalReference?: string;
    LastGeneratedDocument?: string;
    SearchField?: string;
    FKTipoScadenza?: number;
    TipoScadenza?: string;
    LegalPerson : boolean;
    TotalPaid?: number;
    TotalToBePaid?: number;
    FkOutcome?: number;
    DocumentTotalInDocumentCurrency : number;
    RoundingInDocumentCurrency : number;
    FinalTotalInDocumentCurrency : number;
    Currency : string;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsDetails {
    FkRequestForQuotationDocument : number;
    RagioneSocialeCompany?: string;
    PIVACompany?: string;
    CFCompany?: string;
    IndirizzoCompany?: string;
    CAPCompany?: string;
    CittaCompany?: string;
    ProvinciaCompany?: string;
    StatoCompany?: string;
    RagioneSocialeCliente?: string;
    PIVACliente?: string;
    CFCliente?: string;
    IndirizzoCliente?: string;
    CAPCliente?: string;
    CittaCliente?: string;
    ProvinciaCliente?: string;
    StatoCliente?: string;
    TipoPagamento?: string;
    Note?: string;
    NoteAmministrazione?: string;
    Descr1?: string;
    Descr2?: string;
    Descr3?: string;
    TemplatesCodeCompany?: string;
    ComuneCompany?: string;
    TelefonoCompany?: string;
    FaxCompany?: string;
    MailCompany?: string;
    WebsiteCompany?: string;
    RegImpreseCompany?: string;
    REACompany?: string;
    CapSocialeCompany?: number;
    TipoValidita?: string;
    LogoCodeCompany?: string;
    EstimateStateName?: string;
    OrderName?: string;
    NomeCliente?: string;
    CognomeCliente?: string;
    ComuneCliente?: string;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsRows {
    IdRequestForQuotationDocumentRow : number;
    FKRequestForQuotationDocument : number;
    Descrizione?: string;
    Quantita?: number;
    Importo?: number;
    TotaleRiga?: number;
    IndiceRiga?: number;
    QuantitaScaricata?: number;
    Ordine : number;
    EntityType : string;
    Udm?: string;
    Discounts?: string;
    NetUnitPrice?: number;
    ClosedAmount?: number;
    ManuallyClosed : boolean;
    DueDate?: Date;
    Currency?: string;
    OffsetId?: number;
    OffsetCode?: string;
    PriceInDocumentCurrency : number;
    TotalPriceInDocumentCurrency : number;
    NetUnitPriceInDocumentCurrency : number;
    CurrencyId?: number;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsReferencingRows {
    RefId : number;
    SourceEntityKeyId : number;
    SourceEntityType : string;
    DestEntityKeyId : number;
    DestEntityType : string;
    SalId?: number;
    CatalogId?: number;
    Amount?: number;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    NetPrice?: number;
    Action?: number;
    WarehouseId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    DocumentId : number;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsOriginatingRows {
    RefId : number;
    SourceEntityKeyId : number;
    SourceEntityType : string;
    DestEntityKeyId : number;
    DestEntityType : string;
    SalId?: number;
    CatalogId?: number;
    Amount?: number;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    NetPrice?: number;
    Action?: number;
    WarehouseId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    DocumentId : number;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsRelatedWorkflows {
    Id : number;
    WorkflowId : number;
    WorkflowName?: string;
    DocumentId : number;
    DocumentType : string;
    DocumentNumber : string;
    DocumentDate : Date;
    DocumentProtocolId : number;
    RowId : number;
    RowDescription?: string;
    RowAmount : number;
    RowUoM?: string;
    RowPrice : number;
    WorkflowAmount : number;
    ValidityStartDate?: Date;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsAttachments {
    AttachmentId : string;
    IncludeInElectronicInvoice?: boolean;
}

export interface IRequestForQuotationDocumentWithDetailsAndRowsDocumentCurrencies {
    Id : number;
    DocumentId : number;
    DocumentType : string;
    CurrencyId : number;
    ExchangeValue : number;
    ExchangeValueForVat?: number;
    DocumentCurrency : boolean;
}

export interface IRequestForQuotationDocumentWithDetailsAndRows {
    Document : IRequestForQuotationDocumentWithDetailsAndRowsDocument;
    Details : IRequestForQuotationDocumentWithDetailsAndRowsDetails;
    Rows : IRequestForQuotationDocumentWithDetailsAndRowsRows[];
    ReferencingRows : IRequestForQuotationDocumentWithDetailsAndRowsReferencingRows[];
    OriginatingRows : IRequestForQuotationDocumentWithDetailsAndRowsOriginatingRows[];
    RelatedWorkflows : IRequestForQuotationDocumentWithDetailsAndRowsRelatedWorkflows[];
    Attachments : IRequestForQuotationDocumentWithDetailsAndRowsAttachments[];
    DocumentCurrencies : IRequestForQuotationDocumentWithDetailsAndRowsDocumentCurrencies[];
}

export interface IRequestForQuotationDocumentType {
    Id?: number;
    EntityType?: string;
    FKCompany?: number;
    FKRegistro : number;
    Numero?: string;
    Data?: Date;
    FKCliente?: number;
    FKTipoPagamento?: number;
    Totale?: number;
    ProgressivoRegIVA?: number;
    FkStato : number;
    FkTipoValidita?: number;
    Congelato : number;
    Eliminato : number;
    FkEsercizio : number;
    EstimateStateId : number;
    SalId?: number;
    OrderId?: number;
    Discount?: string;
    Rounding?: number;
    FinalTotal?: number;
    CompanyLogo?: string;
    HtmlFooter?: string;
    HeaderImage?: string;
    LayoutId : number;
    NumberPrefix?: string;
    NumberSuffix?: string;
    FkCommercialResponsible?: number;
    CommercialResponsibleName?: string;
    FkAdministrativeResponsible?: number;
    AdministrativeResponsibleName?: string;
    PagamentoIBAN?: string;
    PagamentoABI?: string;
    PagamentoCAB?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    ExternalReference?: string;
    LastGeneratedDocument?: string;
    SearchField?: string;
    FKTipoScadenza?: number;
    TipoScadenza?: string;
    LegalPerson : boolean;
    TotalPaid?: number;
    TotalToBePaid?: number;
    FkOutcome?: number;
    DocumentTotalInDocumentCurrency?: number;
    RoundingInDocumentCurrency?: number;
    FinalTotalInDocumentCurrency?: number;
    Currency?: string;
}

export interface IRequestForQuotationDocument_Details {
    FkRequestForQuotationDocument : number;
    RagioneSocialeCompany?: string;
    PIVACompany?: string;
    CFCompany?: string;
    IndirizzoCompany?: string;
    CAPCompany?: string;
    CittaCompany?: string;
    ProvinciaCompany?: string;
    StatoCompany?: string;
    RagioneSocialeCliente?: string;
    PIVACliente?: string;
    CFCliente?: string;
    IndirizzoCliente?: string;
    CAPCliente?: string;
    CittaCliente?: string;
    ProvinciaCliente?: string;
    StatoCliente?: string;
    TipoPagamento?: string;
    Note?: string;
    NoteAmministrazione?: string;
    Descr1?: string;
    Descr2?: string;
    Descr3?: string;
    TemplatesCodeCompany?: string;
    ComuneCompany?: string;
    TelefonoCompany?: string;
    FaxCompany?: string;
    MailCompany?: string;
    WebsiteCompany?: string;
    RegImpreseCompany?: string;
    REACompany?: string;
    CapSocialeCompany?: number;
    TipoValidita?: string;
    LogoCodeCompany?: string;
    EstimateStateName?: string;
    OrderName?: string;
    NomeCliente?: string;
    CognomeCliente?: string;
    ComuneCliente?: string;
}

export interface IRecipientInfoType {
    CustomerId?: number;
    OrganizationalUnitId?: number;
    CompanyName?: string;
    CustomerName?: string;
    CustomerSurname?: string;
    VATNumber?: string;
    TaxCode?: string;
    Address?: string;
    PostalCode?: string;
    City?: string;
    Municipality?: string;
    Province?: string;
    State?: string;
    PublicAdministration?: boolean;
    ElectronicInvoicing?: boolean;
    EORICode?: string;
    LegalPerson?: boolean;
}

export interface IRequestForQuotationDocumentRowType {
    IdRequestForQuotationDocumentRow : number;
    FKRequestForQuotationDocument : number;
    Descrizione?: string;
    Quantita?: number;
    TipoQuantita?: string;
    Importo?: number;
    TotaleRiga?: number;
    IndiceRiga?: number;
    QuantitaScaricata?: number;
    Ordine : number;
    EntityType?: string;
    Udm?: string;
    Discounts?: string;
    NetUnitPrice?: number;
    ClosedAmount?: number;
    ManuallyClosed : boolean;
    DueDate?: Date;
    Currency?: string;
    OffsetId?: number;
    OffsetCode?: string;
    PriceInDocumentCurrency?: number;
    TotalPriceInDocumentCurrency?: number;
    NetUnitPriceInDocumentCurrency?: number;
    CurrencyId?: number;
}

export interface IReferenceType {
    RefId?: number;
    DestinationId?: number;
    DestinationEntityType?: string;
    CatalogId?: number;
    SalId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    DocumentId?: number;
    Id?: number;
    EntityType?: string;
    Amount?: number;
    AmountType?: string;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    TotalPrice?: number;
    WarehouseId?: number;
}

export interface IAttachmentType {
    AttachmentId?: string;
    IncludeInElectronicInvoice?: boolean;
}

export interface IRelatedWorkflow {
    Id?: number;
    WorkflowId : number;
    DocumentId : number;
    DocumentType?: string;
    DocumentNumber?: string;
    DocumentDate : Date;
    DocumentProtocolId : number;
    RowId : number;
    RowDescription?: string;
    RowAmount : number;
    RowUoM?: string;
    RowPrice : number;
    WorkflowAmount : number;
    ValidityStartDate?: Date;
}

export interface IDocumentCurrency_Type {
    Id?: number;
    DocumentId : number;
    DocumentType?: string;
    CurrencyId : number;
    ExchangeValue : number;
    ExchangeValueForVat?: number;
    DocumentCurrency : boolean;
}

export interface IInsertOrUpdateRequestForQuotationDocumentRequest {
    rfq?: IRequestForQuotationDocumentType[];
    details?: IRequestForQuotationDocument_Details[];
    customer?: IRecipientInfoType[];
    rows?: IRequestForQuotationDocumentRowType[];
    references?: IReferenceType[];
    attachments?: IAttachmentType[];
    relatedWorkflow?: IRelatedWorkflow[];
    currencies?: IDocumentCurrency_Type[];
    userId?: number;
    onWorkflowOutcomeChangeAction?: number;
}

export interface IRequestForQuotationDocumentDocumentIdAndNumber {
    DocumentId?: number;
    DocumentNumber?: string;
}

export interface IRequestForQuotationDocumentIdMapping {
    OldId?: number;
    NewId?: number;
}

export interface IRequestForQuotationDocumentDocument {
    Id : number;
    EntityType : string;
    FKCompany?: number;
    FKRegistro : number;
    Numero?: string;
    Data?: Date;
    FKCliente?: number;
    FKTipoPagamento?: number;
    Totale?: number;
    ProgressivoRegIVA?: number;
    FkStato : number;
    FkTipoValidita?: number;
    Congelato : number;
    Eliminato : number;
    FkEsercizio : number;
    EstimateStateId : number;
    SalId?: number;
    OrderId?: number;
    Discount?: string;
    Rounding?: number;
    FinalTotal?: number;
    CompanyLogo?: string;
    HtmlFooter?: string;
    HeaderImage?: string;
    LayoutId : number;
    NumberPrefix?: string;
    NumberSuffix?: string;
    FkCommercialResponsible?: number;
    CommercialResponsibleName?: string;
    FkAdministrativeResponsible?: number;
    AdministrativeResponsibleName?: string;
    PagamentoIBAN?: string;
    PagamentoABI?: string;
    PagamentoCAB?: string;
    ReferenceNumber?: string;
    ReferenceDate?: Date;
    ExternalReference?: string;
    LastGeneratedDocument?: string;
    SearchField?: string;
    FKTipoScadenza?: number;
    TipoScadenza?: string;
    LegalPerson : boolean;
    TotalPaid?: number;
    TotalToBePaid?: number;
    FkOutcome?: number;
    DocumentTotalInDocumentCurrency : number;
    RoundingInDocumentCurrency : number;
    FinalTotalInDocumentCurrency : number;
    Currency : string;
}

export interface IRequestForQuotationDocumentDetails {
    FkRequestForQuotationDocument : number;
    RagioneSocialeCompany?: string;
    PIVACompany?: string;
    CFCompany?: string;
    IndirizzoCompany?: string;
    CAPCompany?: string;
    CittaCompany?: string;
    ProvinciaCompany?: string;
    StatoCompany?: string;
    RagioneSocialeCliente?: string;
    PIVACliente?: string;
    CFCliente?: string;
    IndirizzoCliente?: string;
    CAPCliente?: string;
    CittaCliente?: string;
    ProvinciaCliente?: string;
    StatoCliente?: string;
    TipoPagamento?: string;
    Note?: string;
    NoteAmministrazione?: string;
    Descr1?: string;
    Descr2?: string;
    Descr3?: string;
    TemplatesCodeCompany?: string;
    ComuneCompany?: string;
    TelefonoCompany?: string;
    FaxCompany?: string;
    MailCompany?: string;
    WebsiteCompany?: string;
    RegImpreseCompany?: string;
    REACompany?: string;
    CapSocialeCompany?: number;
    TipoValidita?: string;
    LogoCodeCompany?: string;
    EstimateStateName?: string;
    OrderName?: string;
    NomeCliente?: string;
    CognomeCliente?: string;
    ComuneCliente?: string;
}

export interface IRequestForQuotationDocumentRows {
    IdRequestForQuotationDocumentRow : number;
    FKRequestForQuotationDocument : number;
    Descrizione?: string;
    Quantita?: number;
    Importo?: number;
    TotaleRiga?: number;
    IndiceRiga?: number;
    QuantitaScaricata?: number;
    Ordine : number;
    EntityType : string;
    Udm?: string;
    Discounts?: string;
    NetUnitPrice?: number;
    ClosedAmount?: number;
    ManuallyClosed : boolean;
    DueDate?: Date;
    Currency?: string;
    OffsetId?: number;
    OffsetCode?: string;
    PriceInDocumentCurrency : number;
    TotalPriceInDocumentCurrency : number;
    NetUnitPriceInDocumentCurrency : number;
    CurrencyId?: number;
}

export interface IRequestForQuotationDocumentReferencingRows {
    RefId : number;
    SourceEntityKeyId : number;
    SourceEntityType : string;
    DestEntityKeyId : number;
    DestEntityType : string;
    SalId?: number;
    CatalogId?: number;
    Amount?: number;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    NetPrice?: number;
    Action?: number;
    WarehouseId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    DocumentId : number;
}

export interface IRequestForQuotationDocumentOriginatingRows {
    RefId : number;
    SourceEntityKeyId : number;
    SourceEntityType : string;
    DestEntityKeyId : number;
    DestEntityType : string;
    SalId?: number;
    CatalogId?: number;
    Amount?: number;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    NetPrice?: number;
    Action?: number;
    WarehouseId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    DocumentId : number;
}

export interface IRequestForQuotationDocumentRelatedWorkflows {
    Id : number;
    WorkflowId : number;
    WorkflowName?: string;
    DocumentId : number;
    DocumentType : string;
    DocumentNumber : string;
    DocumentDate : Date;
    DocumentProtocolId : number;
    RowId : number;
    RowDescription?: string;
    RowAmount : number;
    RowUoM?: string;
    RowPrice : number;
    WorkflowAmount : number;
    ValidityStartDate?: Date;
}

export interface IRequestForQuotationDocumentAttachments {
    AttachmentId : string;
    IncludeInElectronicInvoice?: boolean;
}

export interface IRequestForQuotationDocumentDocumentCurrencies {
    Id : number;
    DocumentId : number;
    DocumentType : string;
    CurrencyId : number;
    ExchangeValue : number;
    ExchangeValueForVat?: number;
    DocumentCurrency : boolean;
}

export interface IRequestForQuotationDocument {
    DocumentIdAndNumber : IRequestForQuotationDocumentDocumentIdAndNumber;
    IdMapping : IRequestForQuotationDocumentIdMapping[];
    Document : IRequestForQuotationDocumentDocument;
    Details : IRequestForQuotationDocumentDetails;
    Rows : IRequestForQuotationDocumentRows[];
    ReferencingRows : IRequestForQuotationDocumentReferencingRows[];
    OriginatingRows : IRequestForQuotationDocumentOriginatingRows[];
    RelatedWorkflows : IRequestForQuotationDocumentRelatedWorkflows[];
    Attachments : IRequestForQuotationDocumentAttachments[];
    DocumentCurrencies : IRequestForQuotationDocumentDocumentCurrencies[];
}

export interface ICreateSupplierOrdersEntityRequest {
    SupplierId : number;
    EntityType : string;
    EntityId : number;
    EntityDescription : string;
    UnitPrice : number;
    NetUnitPrice : number;
    Amount : number;
    ResponseDeliveryDate : Date;
}

export interface ICreateSupplierOrdersRequest {
    Entities : ICreateSupplierOrdersEntityRequest[];
    RegisterId : number;
}

export interface IRequestForQuotationValidForSupplierOrder {
    RequestForQuotationId : number;
    RequestForQuotationRowId : number;
    EntityId?: number;
    EntityType : string;
    Code?: string;
    EntityDescription?: string;
    UoM?: string;
    SupplierId : number;
    SupplierForRequestName?: string;
    RequestForQuotationNumber?: string;
    RequestForQuotationDate : Date;
    RequestForQuotationStateId : number;
    RequestForQuotationSendingDate?: Date;
    RequestForQuotationResponseAmount?: number;
    RequestForQuotationResponsePrice?: number;
    RequestForQuotationResponseDeliveryDate?: Date;
    RequestForQuotationResponseDate?: Date;
    DueDate?: Date;
}

export interface IRequestForQuotationRow_Type {
    Id?: number;
    Number?: number;
    EntityId?: number;
    EntityType?: string;
    EntityDescription?: string;
    Amount?: number;
    UoM?: string;
    Note?: string;
    DeliveryDate?: Date;
    ResponseAmount?: number;
    ResponsePrice?: number;
    ResponseDeliveryDate?: Date;
    ResponseDate?: Date;
    DueDate?: Date;
    PreOrderId?: number;
    PreOrderRowId?: number;
    RequestForQuotationId?: number;
    SupplierOrderId?: number;
    RequestForQuotationDocumentId?: number;
    RequestForQuotationDocumentRowId?: number;
}

export interface IRequestForQuotationRow {
    Id : number;
    Number : number;
    EntityId?: number;
    EntityType : string;
    EntityCode?: string;
    EntityDescription?: string;
    Amount : number;
    UoM?: string;
    Note?: string;
    DeliveryDate?: Date;
    ResponseAmount?: number;
    ResponsePrice?: number;
    ResponseDeliveryDate?: Date;
    ResponseDate?: Date;
    DueDate?: Date;
    PreOrderId?: number;
    PreOrderRowId?: number;
    RequestForQuotationId : number;
    SupplierOrderId?: number;
    RequestForQuotationDocumentId?: number;
    RequestForQuotationDocumentRowId?: number;
    Verified?: boolean;

    Shipments: IRequestForQuotationRowShipment[];
}

export interface IRequestForQuotationRowShipment {
    Id: number;
    FkrequestForQuotationRow: number;
    ShipmentDate: Date;
    Amount: number;
    Fkwarehouse: number;
    Address: string;
    City: string;
    PostalCode: string;
    Province: string;
    State: string;
    IsDefault: boolean;
}

export interface IRequirement {
    RefId : number;
    EntityId : number;
    EntityType : string;
    EntityDescription?: string;
    ArticleCode?: string;
    StockAmount?: number;
    OthersWarehousesStock?: number;
    CustomerOrderRowId : number;
    CustomerOrderRowNumber?: number;
    CustomerOrderRowDescription?: string;
    CustomerOrderRowDeliveryDate?: Date;
    EntityRefId : number;
    Amount?: number;
    OldImportedAmountOnPreOrder?: number;
    UoM?: string;
    UnitPrice : number;
    Discounts?: string;
    NetUnitPrice : number;
    NetScaledPrice?: number;
    CustomerOrderId : number;
    CustomerOrderNumber?: string;
    CustomerOrderDate : Date;
    ProtocolId : number;
    Protocol?: string;
    OrderAmountForArticle?: number;
    JobOrderId?: number;
    JobOrderName?: string;
    CustomerId?: number;
    CustomerName?: string;
    WarehouseId?: number;
    SourceWarehouse?: string;
    EntityCode?: string;
    AmountUnitOfMeasure?: string;
}

export interface IGetPreOrdersRequest {
    TextFilters?: string;
    stateId?: number;
    fromDate?: Date;
    toDate?: Date;
    createdBy?: number;
    Skip?: number;
    Count?: number;
}

export interface IPreOrder {
    Id : number;
    Number?: string;
    StatusId : number;
    Note?: string;
    CreationDate?: Date;
    CreationResourceId : number;
    CreationResourceName?: string;
    LastModificationDate?: Date;
    LastModificationResourceId : number;
    LastModificationName?: string;
    Margin: number;
}

export interface IPreOrderRowSource {
    Id: number;
    Description: string;
    NeededAmount: number
    RowAmount: number;
    WarehouseStock: number;
    CustomerOrderRowDeliveryDate?: Date;
    CustomerOrderRowId: number;
    CustomerOrderRowNumber: number;
    JobOrderName: string;
    SourceWarehouse: string;
    CustomerName: string;
    Number: string;
    EntityType: string;
    DocumentId: number;
    DocumentDate: Date;
    DocumentName: string;
    DocumentRowId: number;
    EntityRefId: number;
    EntityRefDescription: string;
    UoM: string;
    SourceWarehouseId: number;
    SourceEntityId: number;
    SourceEntityType: string;
    SourceUnitPrice: number;
    SourceDiscounts: string;
    SourceNetUnitPrice: number;
    SourceEntityCode: string;
    OthersStockAmount: number;
    CustomerOrderRowUnitPrice: number;
    CustomerOrderRowDiscounts: string;
    CustomerOrderRowNetUnitPrice: number;
    CustomerOrderRowTotalPrice: number;
    EntityMeanCost: number;
    Margin: number;
}

export interface IPreOrderRowSupplier {
    SupplierId : number;
    SupplierName : string;
    RequestForQuotationId : number;
    RequestForQuotationRowId : number;
    RequestForQuotationCreationDate: Date;
    Number : string;
    StateId : number;
    DateOfSending? : Date;
    ResponseDate? : Date;
    ResponseAmount? : number;
    ResponsePrice? : number;
    ResponseDeliveryDate? : Date;
}

export interface IPreOrderDetails {
    Id: number;
    StateId: number;
    Number: string;
    Description: string;

    Rows: IPreOrderRowDetails[];
}

export interface IPreOrderRowDetails {
    Id : number;
    EntityId : number;
    EntityType : string;
    Amount : number;
    UoM : string;
    PreOrderId : number;
    EntityDescription : string;
    StateId : number;
    DeliveryDate? : Date;
    NeededAmount : number;
    SuppliersList : string;
    SuppliersCount : number;
    SuppliersWithAnswerCount : number;
    TotalCost : number;
    Discounts: string;
    UnitCost : number;
    EntityCode: string;

    Sources: PreOrderRowSourceDetails[];
    Suppliers: IPreOrderRowSupplierDetails[];
}

export type PreOrderRowSourceDetails = IPreOrderRowSource;

export interface IPreOrderRowSupplierDetails extends IPreOrderRowSupplier {
    Shipments: IRequestForQuotationRowShipment[];
}

export interface ISavePreOrderRequestRowSource {
    Id?: number;
    DocumentType: string;
    DocumentId: number;
    DocumentRowId: number;
    DocumentRowNumber: number;
    EntityRefId: number;
    Amount: number;
    UoM: string;
    DocumentRowDescription: string;
    EntityRefDescription: string;
    WarehouseId: number;
}

export interface ISavePreOrderRequestRowSupplierShipment {
    Id?: number;
    IsDefault: boolean;
    ShipmentDate: Date;
    Amount: number;
    WarehouseId: number;
    Address: string;
    PostalCode: string;
    City: string;
    Province: string;
    State: string;
}

export interface ISavePreOrderRequestRowSupplier {
    Id?: number;
    SupplierId: number;
    Shipments: ISavePreOrderRequestRowSupplierShipment[];
}

export interface ISavePreOrderRequestRow {
    Id?: number;
    EntityId: number;
    EntityType: string;
    Amount: number;
    DeliveryDate: Date;
    Sources: ISavePreOrderRequestRowSource[];
    Suppliers: ISavePreOrderRequestRowSupplier[];
}
export interface ISavePreOrderRequest {
    Notes: string;
    StatusId: number;
    Margin: number;
    Rows: ISavePreOrderRequestRow[];
}

export interface ISearchSourcesToImportRequest {
    warehouseId: number,
    customerId: number,
    jobOrderId: number,
    dateFrom: Date,
    dateTo: Date,
    protocol: string,
    types: string[],

    rows: ISavePreOrderRequestRow[]
}

export interface ISourceToImport {
    entityId: number,
    entityType: string,
    entityDescription: string,
    entityCode: string,
    neededAmount: number,
    sources: PreOrderRowSourceDetails[]
}

export interface IProvisioningService extends IService {
    addObserver(observer : IProvisioningServiceObserver) : void;
    removeObserver(observer : IProvisioningServiceObserver) : void;
    getDocumentDetailUrl(documentId : number, registerId : number) : string;

    SendRequestForQuotation(requestId : number, registerId : number, documentCurrencies?: IDocumentCurrency[]) : Promise<IRequestForQuotation>;
    GetRequestForQuotationCustomersAndJobOrders(RequestId : number | null) : Promise<IRequestForQuotationCustomerJobOrder[]>;
    DeleteRequestForQuotation(RequestId : number | null) : Promise<void>;
    GetPreOrderRowsForListByIds(Ids : number[] | null) : Promise<IPreOrderRowForList[]>;
    SavePreOrder(preOrderId: number, preOrder: ISavePreOrderRequest) : Promise<IPreOrder>;
    GetPreOrderRowsForList(request : IGetPreOrderRowsForListRequest) : Promise<IPreOrderRowForList[]>;
    GetPreOrderRows(PreOrderId : number | null) : Promise<IPreOrderRowForList[]>;
    GetRequestsForQuotations(request : IGetRequestsForQuotationsRequest) : Promise<IRequestForQuotationForList[]>;
    DeleteRequestForQuotationRow(rowId : number | null) : Promise<void>;
    AddCustomersAndJobOrdersToRequestForQuotation(requestForQuotationId : number | null, customersAndJobOrders : IRequestForQuotationCustomerJobOrder[] | null) : Promise<IRequestForQuotationCustomerAndJobOrder[]>;
    DeleteCustomersAndJobOrders(ids : number[] | null) : Promise<void>;
    GetRequestsForQuotationsByIds(Ids : number[] | null) : Promise<IRequestForQuotationForList[]>;
    SaveRequestForQuotation(Request : IRequestForQuotation_Type | null) : Promise<IRequestForQuotation>;
    DeletePreOrder(preOrderId : number) : Promise<void>;
    
    CreateSupplierOrders(request : ICreateSupplierOrdersRequest) : Promise<void>;
    GetRequestForQuotationRowsValidForSupplierOrders() : Promise<IRequestForQuotationValidForSupplierOrder[]>;
    GetRequestForQuotationRows(RequestId : number | null) : Promise<IRequestForQuotationRow[]>;
    GetRequestForQuotationRowsByIds(rowIds : number[] | null) : Promise<IRequestForQuotationRow[]>;
    GetPreOrdersByIds(Ids : number[] | null) : Promise<IPreOrder[]>;
    GetPreOrders(request : IGetPreOrdersRequest) : Promise<IPreOrder[]>;
    GetPreOrderRowSources(preOrderRowId: number) : Promise<IPreOrderRowSource[]>;
    GetPreOrderRowSuppliers(preOrderRowId: number) : Promise<IPreOrderRowSupplier[]>;
    GetCompletePreOrder(preOrderId: number) : Promise<IPreOrderDetails>;

    SearchSourcesToImport(preOrderId: number, request: ISearchSourcesToImportRequest) : Promise<ISourceToImport[]>;
}

@Service(nameof<IProvisioningService>())
class ProvisioningService extends ProLifeService implements IProvisioningService {
    private application : ProvisioningApplication;
    private hasRegisteredDocumentProviders : boolean;
    private observers : IProvisioningServiceObserver[] = [];
    private settingsLoaded = false;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew : IAjaxServiceNew;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService : IEntityProviderService;

    constructor () {
        super(ProlifeSdk.ProvisioningApplicationCode);
    }

    public InitializeService() : void {
        super.InitializeService();

        this.application = new ProvisioningApplication();
        this.settingsService.addObserver(this);

        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.RequestForQuotationEntityTypeCode, new RequestForQuotationDocumentRefDetailsViewModelFactory());
    }

    getDocumentDetailUrl(documentId : number, registerId : number) : string {
        return '/#/Documenti/Apri/Rfq' + registerId + '/' + documentId;
    }

    addObserver(observer : IProvisioningServiceObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IProvisioningServiceObserver) : void {
        const indexOf = this.observers.indexOf(observer);

        if (indexOf < 0)
            return;

        this.observers.splice(indexOf, 1);
    }

    onSettingsLoaded(): void {
        if (this.settingsLoaded)
            return;

        new RequestForQuotationDataSource();

        this.settingsLoaded = true;
    }

    onSettingsUpdated(updateType : string) : void {
        if (updateType != ProlifeSdk.SettingsUpdateType_DocumentsProtocols)
            return;

        //Rimuovo i registri di questo tipo precedentemente registrati
        this.documentsService.getRegisteredDocumentProviders().slice(0).forEach((p : IDocumentsProvider) => {
            if (p instanceof RequestForQuotationDocumentProvider || p instanceof PurchaseRequestDocumentProvider)
            {
                this.documentsService.unregisterDocumentsProvider(p);
            }
        });

        const vatRegisters = <IVatRegisters> this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        vatRegisters.unregisterCustomProtocolType(cpt => cpt instanceof RequestForQuotationDocumentProtocolType || cpt instanceof PurchaseRequestProtocolType);

        //Aggiungo i nuovi registri
        this.RegisterProtocols();
    }

    private RegisterProtocols() {
        const vatRegisters = <IVatRegisters> this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);

        vatRegisters.registerCustomProtocolType(new RequestForQuotationDocumentProtocolType());
        vatRegisters.registerCustomProtocolType(new PurchaseRequestProtocolType());

        vatRegisters.getVatRegisters().forEach((vatRegister : IVatRegister) => {
            if (vatRegister.Stato < ProlifeSdk.HiddenProtocolState && vatRegister.TipoDocumento == ProlifeSdk.RequestForQuotationTypeId)
                this.documentsService.registerDocumentsProvider(new RequestForQuotationDocumentProvider(vatRegister));
            else if (vatRegister.Stato < ProlifeSdk.HiddenProtocolState && vatRegister.TipoDocumento == ProlifeSdk.PurchaseRequestTypeId)
                this.documentsService.registerDocumentsProvider(new PurchaseRequestDocumentProvider(vatRegister));
        });
        this.hasRegisteredDocumentProviders = true;
    }

    public getServiceType() : string {
        return ProlifeSdk.ProvisioningServiceCode;
    }

    public isOfType(serviceType : string) : boolean {
        return this.getServiceType() === serviceType;
    }

    private onRequestForQuotationAdded(rfq : IRequestForQuotationDocument) {
        this.observers.forEach(o => o.onRequestForQuotationAdded(rfq));
    }

    private onRequestForQuotationChanged(rfq : IRequestForQuotationDocument) {
        this.observers.forEach(o => o.onRequestForQuotationChanged(rfq));
    }

    private onRequestForQuotationDeleted(documentId : number) {
        this.observers.forEach(o => o.onRequestForQuotationDeleted(documentId));
    }

    SendRequestForQuotation(requestId : number, registerId : number, documentCurrencies : IDocumentCurrency[] = []) : Promise<IRequestForQuotation> {
        return this.ajaxService.Post<IRequestForQuotation>("Provisioning-api/RequestsForQuotation", "SendRequestForQuotation", {
            methodData: {
                RequestId: requestId,
                RegisterId: registerId,
                DocumentCurrencies: documentCurrencies
            },
            background: true
        });
    }

    GetRequestForQuotationCustomersAndJobOrders(RequestId : number | null) : Promise<IRequestForQuotationCustomerJobOrder[]> {
        return this.ajaxServiceNew.Get<IRequestForQuotationCustomerJobOrder[]>(`p/rfq/${RequestId}`, "cnj", {
            background: true
        });
    }

    DeleteRequestForQuotation(RequestId : number | null) : Promise<void> {
        return this.ajaxServiceNew.Delete<void>("p/rfq", `${RequestId}`, {
            background: true
        });
    }

    GetPreOrderRowsForListByIds(Ids : number[] | null) : Promise<IPreOrderRowForList[]> {
        return this.ajaxService.Post<IPreOrderRowForList[]>("Provisioning-api/PreOrders", "GetPreOrderRowsForListByIds", {
            background: true,
            methodData: {
                Ids: Ids,
            }
        });
    }

    SavePreOrder(preOrderId: number, preOrder: ISavePreOrderRequest) : Promise<IPreOrder> {
        return this.ajaxServiceNew.Put<IPreOrder>(`p/pre/${preOrderId}`, "", {
            background: true,
            methodData: preOrder
        });
    }

    GetPreOrderRowsForList(request : IGetPreOrderRowsForListRequest) : Promise<IPreOrderRowForList[]> {
        return this.ajaxService.Post<IPreOrderRowForList[]>("Provisioning-api/PreOrders", "GetPreOrderRowsForList", {
            background: true,
            methodData: request
        });
    }

    GetPreOrderRows(PreOrderId : number | null) : Promise<IPreOrderRowForList[]> {
        return this.ajaxServiceNew.Get<IPreOrderRowForList[]>(`p/pre/${PreOrderId}/rows`, "", {
            background: true
        });
    }

    GetRequestsForQuotations(request : IGetRequestsForQuotationsRequest) : Promise<IRequestForQuotationForList[]> {
        return this.ajaxServiceNew.Post<IRequestForQuotationForList[]>("p/rfq", "search", {
            background: true,
            methodData: request
        });
    }

    DeleteRequestForQuotationRow(rowId : number | null) : Promise<void> {
        return this.ajaxServiceNew.Delete<void>("p/rfq", "rows", {
            background: true,
            methodData: {
                rowIds: [rowId],
            }
        });
    }

    AddCustomersAndJobOrdersToRequestForQuotation(requestForQuotationId : number | null, customersAndJobOrders : IRequestForQuotationCustomerJobOrder[] | null) : Promise<IRequestForQuotationCustomerAndJobOrder[]> {
        return this.ajaxServiceNew.Put<IRequestForQuotationCustomerAndJobOrder[]>(`rfq/${requestForQuotationId}`, "cnj", {
            background: true,
            methodData: {
                customersAndJobOrders: customersAndJobOrders
            }
        });
    }

    DeleteCustomersAndJobOrders(ids : number[] | null) : Promise<void> {
        return this.ajaxServiceNew.Delete<void>("p/rfq", "cnj", {
            background: true,
            methodData: {
                ids: ids,
            }
        });
    }

    GetRequestsForQuotationsByIds(Ids : number[] | null) : Promise<IRequestForQuotationForList[]> {
        const result = this.ajaxServiceNew.Post<IRequestForQuotationForList[]>("p/rfq", "byIds", {
            background: true,
            methodData: {
                ids: Ids,
            }
        });



        return result;
    }

    SaveRequestForQuotation(Request : IRequestForQuotation_Type | null) : Promise<IRequestForQuotation> {
        const result = this.ajaxServiceNew.Put<IRequestForQuotation>("p/rfq", "", {
            background: true,
            methodData: Request
        });



        return result;
    }

    DeletePreOrder(preOrderId : number) : Promise<void> {
        const result = this.ajaxServiceNew.Delete<void>(`p/pre/${preOrderId}`, "", {
            background: true
        });



        return result;
    }

    CreateSupplierOrders(request : ICreateSupplierOrdersRequest) : Promise<void> {
        const result = this.ajaxService.Post<void>("Warehouse-api/SupplierOrders", "CreateSupplierOrders", {
            background: true,
            methodData: request
        });

        return result;
    }

    GetRequestForQuotationRowsValidForSupplierOrders() : Promise<IRequestForQuotationValidForSupplierOrder[]> {
        const result = this.ajaxService.Post<IRequestForQuotationValidForSupplierOrder[]>("Provisioning-api/RequestsForQuotation", "GetRequestForQuotationRowsValidForSupplierOrders", {
            background: true,
            methodData: {
            }
        });



        return result;
    }

    GetRequestForQuotationRows(RequestId : number | null) : Promise<IRequestForQuotationRow[]> {
        const result = this.ajaxServiceNew.Get<IRequestForQuotationRow[]>(`p/rfq/${RequestId}`, "rows", {
            background: true
        });



        return result;
    }

    GetRequestForQuotationRowsByIds(rowIds : number[] | null) : Promise<IRequestForQuotationRow[]> {
        const result = this.ajaxServiceNew.Post<IRequestForQuotationRow[]>("p/rfq", "byIds", {
            background: true,
            methodData: {
                ids: rowIds,
            }
        });



        return result;
    }

    GetPreOrdersByIds(Ids : number[] | null) : Promise<IPreOrder[]> {
        const result = this.ajaxServiceNew.Post<IPreOrder[]>("p/pre/searchByIds", "", {
            background: true,
            methodData: {
                ids: Ids,
            }
        });



        return result;
    }

    GetPreOrders(request: IGetPreOrdersRequest): Promise<IPreOrder[]> {
        const result = this.ajaxServiceNew.Post<IPreOrder[]>("p/pre/search", "", {
            background: true,
            methodData: request
        });



        return result;
    }

    GetPreOrderRowSources(preOrderRowId: number) : Promise<IPreOrderRowSource[]> {
        const result = this.ajaxServiceNew.Get<IPreOrderRowSource[]>(`p/pre/rows/${preOrderRowId}/sources`, "", {
            background: true
        });



        return result;
    }

    GetPreOrderRowSuppliers(preOrderRowId: number) : Promise<IPreOrderRowSupplier[]> {
        const result = this.ajaxServiceNew.Get<IPreOrderRowSupplier[]>(`p/pre/rows/${preOrderRowId}/suppliers`, "", {
            background: true
        });



        return result;
    }

    GetCompletePreOrder(preOrderId: number) : Promise<IPreOrderDetails> {
        const result = this.ajaxServiceNew.Get<IPreOrderDetails>(`p/pre/${preOrderId}`, "", {
            background: true
        });



        return result;
    }

    SearchSourcesToImport(preOrderId: number, request: ISearchSourcesToImportRequest) : Promise<ISourceToImport[]> {
        const result = this.ajaxServiceNew.Post<ISourceToImport[]>(`p/pre/${preOrderId}/import`, "", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IProvisioningService>());
}
