import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 27/09/2017
 * Time: 18:28
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CartsList } from "./CartsList";
import { CartElement } from "./CartElement";
import * as moment from "moment";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { CartWorkableHoursCalculationModes } from "../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { AllocationStartType } from "../enums/AllocationStartType";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IAllocationsService, IDraggedCartForList } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IDraggedTask, IDraggedWorkflow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICartRoleInfo, ICartWithContent, ICartStatus, ICartContentDetails, ICartResource, ICart, ICartContentWithRolesInfo } from "../../../ProlifeSdk/interfaces/allocations/ICart";
import { ICartsStatesSettingManager } from "../../../ProlifeSdk/interfaces/allocations/ICartsStatesSettingManager";

export class CartForList {
    public Id: number;
    public Title: ko.Observable<string> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public Status: ko.Observable<number> = ko.observable();
    public CreationDate: Date;
    public CreatorId: number;
    public ModifyDate: Date;
    public ModifierId: number;
    public Items: ko.ObservableArray<CartElement> = ko.observableArray([]);
    public RolesInfo: ko.ObservableArray<ICartRoleInfo> = ko.observableArray([]);
    public IsExpanded: ko.Observable<boolean> = ko.observable(false);
    public EnableContentEditing: ko.Observable<boolean> = ko.observable(false);
    public IsEmpty: ko.Computed<boolean>;
    public IsClosed: ko.Computed<boolean>;
    public TotalEstimatedHours: ko.Observable<number> = ko.observable();
    public TotalReestimatedHours: ko.Observable<number> = ko.observable();
    public TotalWorkedHours: ko.Observable<number> = ko.observable();
    public LastPerformanceUpdate: ko.Observable<Date> = ko.observable();
    public IgnoreBacklog : ko.Observable<boolean> = ko.observable();
    public CartWorkableHoursCalculationMode : ko.Observable<CartWorkableHoursCalculationModes> = ko.observable();
    public AllocationStartType : ko.Observable<number> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public Priority: ko.Observable<boolean> = ko.observable(false);
    public WorkEndDate: ko.Observable<Date> = ko.observable();
    public Deallocated : ko.Observable<boolean> = ko.observable(false);

    public DropScope: ko.Observable<string> = ko.observable("default");
    public DragScope: ko.Observable<string> = ko.observable("default");

    public ShowPerformanceUpdatesInfo: ko.Computed<boolean>;

    public ReestimatedColumnTitle: ko.Computed<string>;
    public UsingReestimatedWork: ko.Computed<boolean>;
    public UsingTaskEstimateWork: ko.Computed<boolean>;

    public AllowedMimeTypes: string[] = [
        'application/prolife-task',
        'application/prolife-workflow'
    ];

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService!: IAllocationsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogService!: IDialogsService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.CartStatus)
    private statusManager: ICartsStatesSettingManager;

    constructor(private cart: ICartWithContent, private container: CartsList) {
        this.Id = this.cart.Cart.Id;
        this.Title(this.cart.Cart.Title);
        this.Color(this.cart.Cart.Color);
        this.Status(this.cart.Cart.Status);
        this.IgnoreBacklog(this.cart.Cart.IgnoreBacklog);
        this.AllocationStartType(this.cart.Cart.AllocationStartType === null || this.cart.Cart.AllocationStartType === undefined ? AllocationStartType.AsSoonAsPossible : this.cart.Cart.AllocationStartType);
        this.CartWorkableHoursCalculationMode(this.cart.Cart.CartWorkableHoursCalculationMode as CartWorkableHoursCalculationModes);
        this.StartDate(this.cart.Cart.StartDate);
        this.Priority(!!this.cart.Cart.Priority);
        this.WorkEndDate(this.cart.Cart.WorkEndDate);
        this.Deallocated(this.cart.Deallocated);
        
        this.DropScope(this.container.DropScope());
        this.DragScope(this.container.DragScope());

        this.CreationDate = this.cart.Cart.CreationDate;
        this.CreatorId = this.cart.Cart.CreatorId;
        this.ModifyDate = this.cart.Cart.ModifyDate;
        this.ModifierId = this.cart.Cart.ModifierId;

        this.LoadItems(this.cart.Content);
        this.LoadOpUnits(this.cart.RolesInfo);

        this.IsEmpty = ko.computed(() => {
            return this.Items().length == 0;
        });

        this.IsClosed = ko.computed(() => {
            let cartState: ICartStatus = this.statusManager.getCartsStatusById(this.Status());
            return cartState.LogicalStatus == ProlifeSdk.CartClosedStatus;
        });

        this.ShowPerformanceUpdatesInfo = ko.computed(() => {
            return this.Items().length > 0;
        });

        this.ReestimatedColumnTitle = ko.computed(() => {
            return this.CartWorkableHoursCalculationMode() === CartWorkableHoursCalculationModes.WorkflowSpeed ? TextResources.Allocations.WorkflowSpeedReestimatedWorkTitle : TextResources.Allocations.JobOrderSpeedReestimatedWorkTitle;
        });

        this.UsingReestimatedWork = ko.computed(() => {
            return this.CartWorkableHoursCalculationMode() !== CartWorkableHoursCalculationModes.TaskEstimatedWork;
        });

        this.UsingTaskEstimateWork = ko.computed(() => {
            return !this.UsingReestimatedWork();
        });
    }

    private LoadOpUnits(roles: ICartRoleInfo[]): void {
        if (!roles) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.RefreshCartInfoError);
            return;
        }
        this.RolesInfo(roles);
        this.CalculateTotals();
    }

    private LoadItems(items: ICartContentDetails[]): void {
        if (!items || items.length == 0)
            return;
        this.Items(items.map((i: ICartContentDetails) => { return new CartElement(i, this); }));
    }

    private CalculateTotals(): void {
        let estimated = 0;
        let reestimated = 0;
        let worked = 0;
        let maxDate = moment('1900-01-01');

        this.RolesInfo().forEach((op: ICartRoleInfo) => {
            estimated += op.EstimatedHours;
            reestimated += op.ReestimatedHours;
            worked += op.WorkedHours;
            if (!!op.LastPerformanceUpdate && moment(op.LastPerformanceUpdate) > maxDate)
                maxDate = moment(op.LastPerformanceUpdate);
        });
        this.TotalEstimatedHours(estimated);
        this.TotalReestimatedHours(reestimated);
        this.TotalWorkedHours(worked);
        this.LastPerformanceUpdate(maxDate.valueOf() == moment('1900-01-01').valueOf() ? null : maxDate.toDate());
    }

    public async EditCart(): Promise<void> {
        try {
            const cart = await this.cartsService.openCartEditorDialog(this.ToJson());
            
            if (!cart) {
                this.container.RefreshCarts();
                return;
            }

            const status: ICartStatus = this.statusManager.getCartsStatusById(cart.Status);
            if (status.LogicalStatus == ProlifeSdk.CartClosedStatus && !this.container.ShowClosedCarts()) {
                this.container.CartsList.remove(this);
                return;
            }

            this.Title(cart.Title);
            this.Color(cart.Color);
            this.Status(cart.Status);
            this.IgnoreBacklog(cart.IgnoreBacklog);
            this.CartWorkableHoursCalculationMode(cart.CartWorkableHoursCalculationMode as CartWorkableHoursCalculationModes);
            this.AllocationStartType(cart.AllocationStartType);
            this.StartDate(cart.StartDate);
            this.Priority(!!cart.Priority);
            this.WorkEndDate(cart.WorkEndDate);
            this.ModifyDate = cart.ModifyDate;
            this.ModifierId = cart.ModifierId;

            this.container.RefreshCarts();
        } catch {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.EditCartError);
        }
    }

    public OnElementDeleted(element: CartElement): void {
        this.Items.remove(element);
        this.container.Refresh();
        this.refreshOpUnitsInfo();
    }

    public SaveCartContent(): void {
        this.cartsService.saveCartContent(this.Id, this.Items().map((c: CartElement) =>  { return c.element; }))
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.SaveCartContentError);
            });
    }

    public async OnElementDropped(dataTransfer: DataTransfer): Promise<void> {
        let task: IDraggedTask = dataTransfer.types.indexOf("application/prolife-task") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-task")) : null;
        let workflow: IDraggedWorkflow = dataTransfer.types.indexOf("application/prolife-workflow") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-workflow")) : null;
        
        if (this.userInfo.getCurrentCompanyGuid() !== (task ? task.CompanyGuid : workflow.CompanyGuid)) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        let elementId: number = task ? task.TaskId : workflow.WorkflowId;
        let isTask: boolean = task ? task.IsTask : workflow.IsTask;

        if(!elementId)
			return;

        try {
            let result: boolean = await this.allocationsService.checkForUnestimatedTasks(elementId, isTask);
                
            
            if (!result) {
                this.addElementToCart(this.Id, elementId, isTask);
                return;
            }
            
            let confirm: boolean = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartMessage,
                ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartCancel,
                ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartConfirm);

            if (confirm)
                this.addElementToCart(this.Id, elementId, isTask);

        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AddElementToCartError);
        }
    }

    public OnDragStart(dataTransfer: DataTransfer): void {
        let cart: IDraggedCartForList = {
            CartId: this.Id,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };

        dataTransfer.setData("application/prolife-cart", JSON.stringify(cart));
    }

    private addElementToCart(cartId: number, elementId: number, isTask: boolean): void {
        this.cartsService.manageCartWorkEndDate(cartId, elementId, isTask)
            .then((newDate: Date) => {
                this.WorkEndDate(newDate);
                this.cartsService.addElementToCart(cartId, elementId, isTask, 100)
                    .then((element: ICartContentWithRolesInfo) => {
                        if (!element) {
                            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CanNotAllocateElementAlreadyAllocated);
                            return;
                        }
                        this.Items.push(new CartElement(element.ElementInfo, this));
                        this.container.Refresh();
                        this.refreshOpUnitsInfo();
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetCartElementDataError);
                    });
            });
    }

    private refreshOpUnitsInfo(): void {
        this.cartsService.getCartRolesInfo(this.Id)
            .then((roles: ICartRoleInfo[]) => {
                this.LoadOpUnits(roles);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetCartOpUnitsInfoError);
            });
    }

    public ToJson(): ICart {
        return <ICart> {
            Id: this.Id,
            Title: this.Title(),
            Color: this.Color(),
            Status: this.Status(),
            CreationDate: this.CreationDate,
            CreatorId: this.CreatorId,
            ModifyDate: this.ModifyDate,
            ModifierId: this.ModifierId,
            IgnoreBacklog: this.IgnoreBacklog(),
            CartWorkableHoursCalculationMode: this.CartWorkableHoursCalculationMode(),
            AllocationStartType: this.AllocationStartType(),
            StartDate: this.StartDate(),
            Priority: this.Priority() ? 1 : 0,
            WorkEndDate: this.WorkEndDate()
        };
    }
}