import { CustomTile } from "../../ProlifeSdk/interfaces/desktop/CustomTile";
import { ApplicationStarter } from "./ApplicationStarter";

export class CustomApplication extends ApplicationStarter {
    private textColor: string;
    private iconColor: string;
    private url: string;

    constructor(data: CustomTile) {
        super();

        this.applicationName = data.title;
        this.applicationIcon = data.icon;
        this.tileColor = data.backgroundColor;
        this.textColor = data.textColor;
        this.iconColor = data.iconColor;
        this.order = data.order;
        this.group = data.group;
        this.url = data.url;

        this.areAuthorizationsLoaded = true;

        this.visible(true);
    }

    public start(): void {
        const url = this.getApplicationRoute();
        window.open(url, "_blank");
    }

    public getApplicationRoute(): string {
        return this.url;
    }
}
