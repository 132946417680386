import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, PropsWithChildren, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { If } from "../../../../Components/IfIfNotWith";

const styleSheet = jss.createStyleSheet({
    accordionSecondaryRow: {
        "& > tbody > tr > td": {
            padding: 0,
            verticalAlign: 'middle'
        },

        "& > tbody > tr:first-child > td": {
            borderTop: 'none'
        },

        "& > tbody > tr > td .table.table-condensed > tbody:last-child > tr:last-child > td": {
            borderBottom: 'none'
        }
    }
});
const { classes } = styleSheet.attach();

type AccordionSecondaryRowProps = PropsWithChildren<{
    singleSection?: boolean;
}>;

export function AccordionSecondaryRow(props: AccordionSecondaryRowProps) {
    const C = require("./AccordionSecondaryRow")._AccordionSecondaryRow as typeof _AccordionSecondaryRow;
    return <C {...props} />;
}

export class _AccordionSecondaryRow {
    static defaultProps: Partial<AccordionSecondaryRowProps> = {
        singleSection: false
    }

    private sections: (AccordionSecondaryRow.SectionProps & {
        shown: ko.Observable<boolean>;
        toggle: () => void;
    })[] = [];

    constructor(private props : AccordionSecondaryRowProps) {
        ComponentUtils.Children.forEach(props.children, c => {
            if(ComponentUtils.isOfType(c, AccordionSecondaryRow.Section)) {
                const shown = ko.observable(false);
                const toggle = () => {
                    if(props.singleSection)
                        this.sections.forEach(s => s.shown(false));
                    
                    shown(!shown());
                };

                this.sections.push({
                    ...ComponentUtils.getComponent<AccordionSecondaryRow.Section>(c).props,
                    shown,
                    toggle 
                });
            }
        });
    }
    
    render() {
        return  <table className={classNames("table table-condensed", classes.accordionSecondaryRow)} style={{ width: '100%', margin: 0 }}>
                    <tbody>
                        {this.sections.map(_s => <>
                            {ComponentUtils.bindTo(
                                <tr>
                                    <td style={{ width: '19px', minHeight: '29px', height: '29px', borderRight: '1px solid #ddd', textAlign: 'center' }}>
                                        <span class="btn-link btn-xs" title={_s.title} data-bind={{ click: _s.toggle }}>
                                            <i class="fa" data-bind={{ css: { 'fa-angle-right': !_s.shown(), 'fa-angle-down': _s.shown }}}></i>
                                        </span>
                                    </td>
                                    <td style={{ cursor: 'pointer', padding: '0px 5px' }} data-bind={{ click: _s.toggle }}>{_s.label}</td>
                                </tr>
                            , _s, "_s")}
                            <If condition={_s.shown}>
                                {() => (
                                    <tr style={{ height: '1px' }}>
                                        <td style={{ borderRight: '1px solid #ddd', backgroundColor: '#f5f5f5', height: 'inherit' }}>
                                            <div style={{ height: '100%' }}></div>
                                        </td>
                                        <td>
                                            {_s.element()}
                                        </td>
                                    </tr>
                                )}
                            </If>
                        </>)}
                    </tbody>
                </table>
    }
}

export namespace AccordionSecondaryRow {
    export type SectionProps = {
        label: string;
        title?: string;
        element: () => React.ReactElement;
    }

    export class Section {
        constructor(public props: SectionProps) {

        }

        render() {
            return <></>;
        }
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AccordionSecondaryRow);
    reloadNow(AccordionSecondaryRow.Section);
}