import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import {
    IProtocolDefaultValuesSettingsUi,
    IPaymentAccount,
    IProtocolSetting,
} from "../../../interfaces/invoice/IProtocolsSettingsManager";
import {
    IProtocolDefaultMetadatas,
    IVatRegister,
    ProtocolMailRecipient,
} from "../../../interfaces/invoice/settings/IVatRegisters";
import { MetadataType } from "../../../../Invoices/invoices/enums/MetadataType";
import { DocumentMetadata, IDocumentMetadata } from "./DocumentMetadata";
import { DocumentMetadatasEditorUI } from "./DocumentMetadatasEditor";
import { TextResources } from "../../../ProlifeTextResources";
import { IMetadataService } from "../../../../Invoices/MetadataService";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { DocumentMetadatasDataSource } from "../../../../DataSources/DocumentMetadatasDataSource";
import { IPaymentTypeManagerUi } from "../../../interfaces/prolife-sdk/ui/IPaymentTypeManagerUi";
import { IPaymentMode, IPaymentModes } from "../../../interfaces/invoice/settings/IPaymentModes";
import { IProLifeSdkService } from "../../../interfaces/prolife-sdk/IProlifeSdkService";
import { IExpireMode, IExpireModes } from "../../../interfaces/invoice/settings/IExpireModes";
import { OffsetsCodesDataSource } from "../../../../DataSources/OffsetsCodesDataSource";
import { WorkflowOutcomesDataSource } from "../../../../DataSources/WorkflowOutcomesDataSource";
import { DocumentVersionNumberGenerationModes } from "../../../../Invoices/invoices/enums/DocumentVersionNumberGenerationModes";
import { DocumentNumberPrintChoices } from "../../../../Invoices/invoices/enums/DocumentNumberPrintChoices";
import {
    IDocumentNumberPrintChoice,
    NumberPrintChoicesProvider,
} from "../../../../Invoices/invoices/ui/documents/utils/NumberPrintChoicesProvider";
import {
    DocumentVersionNumberGenerationModesProvider,
    IVersionNumberGenerationMode,
} from "../../../../Invoices/invoices/ui/documents/utils/DocumentVersionNumberGenerationModesProvider";
import { CurrenciesDataSource } from "../../../../DataSources/CurrenciesDataSource";
import { DefaultValueType } from "./DefaultValueType";
import { DocumentMailEditorUI } from "./DocumentMailEditor";
import { ProtocolMailRecipientTypes } from "./ProtocolMailRecipientTypes";

export class DefaultValuesSettingsUi implements IProtocolDefaultValuesSettingsUi {
    public templateUrl: string;
    public templateName: string;

    public ProtocolName: string;
    public ProtocolId: string;
    public DocumentTypeId: ko.Observable<number | null> = ko.observable(null);
    public Enabled: ko.Observable<boolean> = ko.observable(false);
    public MetadatasEnabled: ko.Observable<boolean> = ko.observable(false);
    public Editable: ko.Observable<boolean> = ko.observable(true);
    public ShowEnableCheckbox: ko.Observable<boolean> = ko.observable(true);
    public ShowEnableMetadatasCheckbox: ko.Observable<boolean> = ko.observable(true);
    public StaticMetadatasTable: ko.Observable<boolean> = ko.observable(false);
    public EnableMetadatasSorting: ko.Observable<boolean> = ko.observable(true);
    public ShowAbiAndCabFields: ko.Observable<boolean> = ko.observable(true);
    public AllowFixedAddressesAsMailRecipients: ko.Observable<boolean> = ko.observable(true);
    public DefaultCurrency: ko.Observable<number | null> = ko.observable(null);
    public DefaultExpire: ko.Observable<IExpireMode | null> = ko.observable(null);
    public DefaultOffset: ko.Observable<number | null> = ko.observable(null);
    public DefaultOutcome: ko.Observable<number | null> = ko.observable(null);
    public DefaultVersionNumberGenerationMode: ko.Observable<DocumentVersionNumberGenerationModes | null> =
        ko.observable(null);
    public DefaultNumberPrintChoice: ko.Observable<DocumentNumberPrintChoices | null> = ko.observable(null);
    public DefaultSendDocumentMail: ko.Observable<boolean> = ko.observable(false);
    public DefaultIncludeDocumentAttachmentsWhenSendMail: ko.Observable<boolean> = ko.observable(false);
    public DefaultMailTemplateId: ko.Observable<number | null> = ko.observable(null);
    public DefaultMailToRecipeints: ko.ObservableArray<string | number> = ko.observableArray([]);
    public DefaultMailCcRecipeints: ko.ObservableArray<string | number> = ko.observableArray([]);
    public DefaultMailBccRecipeints: ko.ObservableArray<string | number> = ko.observableArray([]);
    public DefaultMetadatas: ko.ObservableArray<DocumentMetadata> = ko.observableArray([]);
    public CanHaveMetadatas: ko.Observable<boolean> = ko.observable(true);

    public DocumentMetadatasEditorUI: DocumentMetadatasEditorUI;
    public DocumentMailEditorUI: DocumentMailEditorUI;
    public PaymentTypeManagerUi: IPaymentTypeManagerUi;

    public vatRegisterId: number;
    protected NumberPrintChoices: IDocumentNumberPrintChoice[] = [];
    protected VersionNumberGenerationModes: IVersionNumberGenerationMode[] = [];
    protected PaymentModes: IPaymentMode[];
    protected Expires: IExpireMode[];
    protected OffsetsCodesDataSource: OffsetsCodesDataSource = new OffsetsCodesDataSource();
    protected OutcomesDataSource: WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();
    protected MetadatasDataSource: DocumentMetadatasDataSource = new DocumentMetadatasDataSource();
    protected CurrenciesDataSource: CurrenciesDataSource = new CurrenciesDataSource();

    private ReadOnly: ko.Observable<boolean> = ko.observable();
    private MetadataObserver: ko.Computed<void>;

    @LazyImport(nameof<IProLifeSdkService>())
    protected prolifeSdkService: IProLifeSdkService;
    @LazyImport(nameof<IMetadataService>())
    private metadatasService: IMetadataService;
    @LazyImportSettingManager(ProlifeSdk.PaymentMode)
    protected paymentModesManager: IPaymentModes;
    @LazyImportSettingManager(ProlifeSdk.ExpireModes)
    protected expiresManager: IExpireModes;

    constructor(protected protocol: IVatRegister, protocolId: string) {
        this.vatRegisterId = this.protocol.IdRegistroIVA;
        this.ProtocolId = protocolId;
        this.ProtocolName = protocol.NomeRegistroIVA;
        this.DocumentTypeId(this.protocol.TipoDocumento);

        this.PaymentTypeManagerUi = this.prolifeSdkService.Ui.GetPaymentTypeManagerUi(this.protocol.SalesCicle);
        this.PaymentTypeManagerUi.PaymentAccountId(-1);
        this.PaymentTypeManagerUi.RequireAbiAndCabSelection(false);
        this.PaymentTypeManagerUi.RequireIbanSelection(false);

        this.CurrenciesDataSource.returnDeletedCurrencies(false);
        this.MetadatasDataSource.setGetDeleted(false);
        this.PaymentModes = this.paymentModesManager.getPaymentModes();
        this.Expires = this.expiresManager.getExpireModes();

        this.NumberPrintChoices = NumberPrintChoicesProvider.getAvailableChoices();
        this.VersionNumberGenerationModes = DocumentVersionNumberGenerationModesProvider.getAvailableGenerationModes();

        this.ReadOnly(!this.Editable());

        this.Editable.subscribe((value: boolean) => {
            this.ReadOnly(!value);
        });

        this.MetadataObserver = ko.computed(() => {
            const selectedMetadataIds = this.DefaultMetadatas().map((m) => m.MetadataId());
            this.MetadatasDataSource.setMetadataToOmit(selectedMetadataIds);
        });

        this.DocumentMetadatasEditorUI = new DocumentMetadatasEditorUI({
            metadatas: this.DefaultMetadatas,
            commonMetadatasDataSource: this.MetadatasDataSource,
            readOnly: ko.computed(() => this.ReadOnly() || !this.MetadatasEnabled()),
            showConfigurationsColumn: true,
            showTypeColumn: true,
            titleOnTop: true,
            showPreloadOnDocumentCheckbox: true,
            staticTable: this.StaticMetadatasTable,
            enableSorting: this.EnableMetadatasSorting,
        });

        this.DocumentMailEditorUI = new DocumentMailEditorUI({
            automaticMailSend: this.DefaultSendDocumentMail,
            includeDocumentAttachmentsInMailSend: this.DefaultIncludeDocumentAttachmentsWhenSendMail,
            mailTemplateId: this.DefaultMailTemplateId,
            mailTo: this.DefaultMailToRecipeints,
            mailCc: this.DefaultMailCcRecipeints,
            mailBcc: this.DefaultMailBccRecipeints,
            allowFixedAddresses: this.AllowFixedAddressesAsMailRecipients,
            readonly: ko.computed(() => this.ReadOnly() || !this.Enabled()),
        });
    }

    Initialize(destinationAccounts: IPaymentAccount[]) {
        this.PaymentTypeManagerUi.ExternalAccounts(destinationAccounts);
    }

    SetEditMode(editable: boolean) {
        this.Editable(editable);
    }

    SetStaticMetadatasTable(value: boolean) {
        this.StaticMetadatasTable(value);
    }

    SetEnableMetadatasSorting(value: boolean) {
        this.EnableMetadatasSorting(value);
    }

    SwitchEnabled() {
        if (this.Editable()) this.Enabled(!this.Enabled());
    }

    LoadSettings(settings: IProtocolSetting[], settingsEnabled: boolean) {
        this.Enabled(settingsEnabled);

        settings.forEach((s: IProtocolSetting) => {
            if (s.ProtocolId != this.ProtocolId) return;

            if (s.SettingKey == ProlifeSdk.PaymentModeSettingKey) {
                const paymentMatches = this.PaymentModes.filter((im: IPaymentMode) => {
                    return im.IdTipoPagamento == s.IntValue;
                });

                this.PaymentTypeManagerUi.Payment(paymentMatches.length > 0 ? paymentMatches[0] : null);

                const accountMatches = settings.filter((s1: IProtocolSetting) => {
                    return s1.SettingKey == ProlifeSdk.PaymentAccountSettingKey;
                });

                if (accountMatches.length > 0) this.PaymentTypeManagerUi.PaymentAccountId(accountMatches[0].IntValue);

                const abiMatches = settings.filter((s1: IProtocolSetting) => {
                    return s1.SettingKey == ProlifeSdk.PaymentABISettingKey;
                });

                if (abiMatches.length > 0) this.PaymentTypeManagerUi.PaymentABI(abiMatches[0].StringValue);

                const cabMatches = settings.filter((s1: IProtocolSetting) => {
                    return s1.SettingKey == ProlifeSdk.PaymentCABSettingKey;
                });

                if (cabMatches.length > 0) this.PaymentTypeManagerUi.PaymentCAB(cabMatches[0].StringValue);
            }

            if (s.SettingKey == ProlifeSdk.ExpireModeSettingKey) {
                const expireMatches = this.Expires.filter((e: IExpireMode) => {
                    return e.IdTipoScadenza == s.IntValue;
                });

                this.DefaultExpire(expireMatches.length > 0 ? expireMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.PaymentOffsetSettingKey) {
                this.DefaultOffset(s.IntValue);
            }

            if (s.SettingKey == ProlifeSdk.WorkflowOutcomeSettingKey) {
                this.DefaultOutcome(s.IntValue);
            }

            if (s.SettingKey == ProlifeSdk.VersionNumberGenerationModeKey) {
                this.DefaultVersionNumberGenerationMode(s.IntValue);
            }

            if (s.SettingKey == ProlifeSdk.DocumentNumberPrintChoiceKey) {
                this.DefaultNumberPrintChoice(s.IntValue);
            }

            if (s.SettingKey == ProlifeSdk.CurrencySettingKey) {
                this.DefaultCurrency(s.IntValue);
            }

            if (s.SettingKey === ProlifeSdk.SendDocumentMailSettingKey) {
                this.DefaultSendDocumentMail(s.IntValue ? true : false);
            }

            if (s.SettingKey === ProlifeSdk.IncludeDocumentAttachmentsMailSettingKey) {
                this.DefaultIncludeDocumentAttachmentsWhenSendMail(s.IntValue ? true : false);
            }

            if (s.SettingKey === ProlifeSdk.MailTemplateSettingKey) {
                this.DefaultMailTemplateId(s.IntValue);
            }
        });
    }

    GetSettings(): IProtocolSetting[] {
        const settings: IProtocolSetting[] = [];

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.PaymentModeSettingKey,
                this.PaymentTypeManagerUi.Payment() ? this.PaymentTypeManagerUi.PaymentId() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.PaymentAccountSettingKey,
                this.PaymentTypeManagerUi.PaymentAccountId(),
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.PaymentABISettingKey,
                this.PaymentTypeManagerUi.PaymentABI(),
                DefaultValueType.StringValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.PaymentCABSettingKey,
                this.PaymentTypeManagerUi.PaymentCAB(),
                DefaultValueType.StringValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.ExpireModeSettingKey,
                this.DefaultExpire() ? this.DefaultExpire().IdTipoScadenza : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.PaymentOffsetSettingKey,
                this.DefaultOffset() ? this.DefaultOffset() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.WorkflowOutcomeSettingKey,
                this.DefaultOutcome() ? this.DefaultOutcome() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.VersionNumberGenerationModeKey,
                this.DefaultVersionNumberGenerationMode() ? this.DefaultVersionNumberGenerationMode() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.DocumentNumberPrintChoiceKey,
                this.DefaultNumberPrintChoice() ? this.DefaultNumberPrintChoice() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.CurrencySettingKey,
                this.DefaultCurrency() ? this.DefaultCurrency() : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.SendDocumentMailSettingKey,
                this.DefaultSendDocumentMail() ? 1 : 0,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.IncludeDocumentAttachmentsMailSettingKey,
                this.DefaultIncludeDocumentAttachmentsWhenSendMail() ? 1 : 0,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.MailTemplateSettingKey,
                this.DefaultMailTemplateId() ? this.DefaultMailTemplateId() : null,
                DefaultValueType.IntValue
            )
        );

        return settings;
    }

    async LoadMetadata(metadata: IProtocolDefaultMetadatas[], metadataEnabled: boolean): Promise<void> {
        const models: DocumentMetadata[] = [];
        for (const m of metadata.map(this.toDocumentMetadata, this)) {
            models.push(new DocumentMetadata(this.MetadatasDataSource, m));
        }

        if (this.StaticMetadatasTable()) {
            // carica tutti i metadati configurati sul protocollo e fai il merge con quelli passati in input
            const protocolMetadatas = await this.metadatasService.GetProtocolDefaultMetadata(this.vatRegisterId);
            for (const m of protocolMetadatas) {
                if (!metadata.firstOrDefault((mm) => mm.FKMetadata === m.FKMetadata)) {
                    models.push(new DocumentMetadata(this.MetadatasDataSource, this.toDocumentMetadata(m)));
                }
            }
        }

        this.DefaultMetadatas(models);
        this.MetadatasEnabled(metadataEnabled);
    }

    GetMetadata(): IProtocolDefaultMetadatas[] {
        const result: IProtocolDefaultMetadatas[] = [];

        for (const metadata of this.DefaultMetadatas()) {
            result.push({
                Id: metadata.Id,
                FKMetadata: metadata.MetadataId(),
                FKProtocol: this.vatRegisterId,
                MetadataValueType: metadata.MetadataValueType(),
                ShowMarkerOnAllocationsGantt: metadata.ShowMarkerOnAllocationsGantt(),
                PreloadOnDocument: metadata.PreloadOnDocument(),
                Order: metadata.Order(),
            });
        }

        return result;
    }

    loadMailRecipientTypes(
        to: ProtocolMailRecipient[],
        cc: ProtocolMailRecipient[],
        bcc: ProtocolMailRecipient[]
    ): void {
        this.DefaultMailToRecipeints(to.map(this.toMailRecipientValue, this));
        this.DefaultMailCcRecipeints(cc.map(this.toMailRecipientValue, this));
        this.DefaultMailBccRecipeints(bcc.map(this.toMailRecipientValue, this));
    }

    private toMailRecipientValue(recipient: ProtocolMailRecipient): string | number {
        return recipient.valueType === ProtocolMailRecipientTypes.FixedAddress
            ? recipient.value
            : parseInt(recipient.value);
    }

    getMailRecipientsForDocumentsSendTo(): ProtocolMailRecipient[] {
        const values = this.DefaultMailToRecipeints();
        return values.map(this.toProtocolMailRecipient, this);
    }

    private toProtocolMailRecipient(value: string | number): ProtocolMailRecipient {
        return {
            protocolId: this.vatRegisterId,
            valueType:
                typeof value === "string"
                    ? ProtocolMailRecipientTypes.FixedAddress
                    : ProtocolMailRecipientTypes.LogicType,
            value: value.toString(),
            valueDataType: typeof value === "string" ? "string" : "int",
        };
    }

    getMailRecipientsForDocumentsSendCc(): ProtocolMailRecipient[] {
        const values = this.DefaultMailCcRecipeints();
        return values.map(this.toProtocolMailRecipient, this);
    }

    getMailRecipientsForDocumentsSendBcc(): ProtocolMailRecipient[] {
        const values = this.DefaultMailBccRecipeints();
        return values.map(this.toProtocolMailRecipient, this);
    }

    validateMetadata(): string {
        if (this.DefaultMetadatas().firstOrDefault((m) => !m.MetadataId()))
            return TextResources.ProlifeSdk.MissingMetadataError;

        return "";
    }

    protected GetNewSetting(key: string, value: unknown, valueType: DefaultValueType): IProtocolSetting {
        return {
            ProtocolId: this.ProtocolId,
            SettingKey: key,
            IntValue: valueType == DefaultValueType.IntValue ? (value as number) : null,
            DecimalValue: valueType == DefaultValueType.DecimalValue ? (value as number) : null,
            StringValue: valueType == DefaultValueType.StringValue ? (value as string) : null,
            DateValue: valueType == DefaultValueType.DateValue ? (value as Date) : null,
        };
    }

    private toDocumentMetadata(metadata: IProtocolDefaultMetadatas): IDocumentMetadata {
        return {
            Id: metadata.Id,
            MetadataId: metadata.FKMetadata,
            MetadataValue: null,
            MetadataListValues: [],
            MetadataValueType: metadata.MetadataValueType as MetadataType,
            ShowMarkerOnAllocationsGantt: metadata.ShowMarkerOnAllocationsGantt,
            PreloadOnDocument: metadata.PreloadOnDocument,
            Order: metadata.Order,
        };
    }
}
