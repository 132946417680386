import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTaskTags } from "./TodoListTaskTags";
import { ITodoListTaskTag } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";

export class TodoListTaskLabelTags extends TodoListTaskTags
{
    public Labels : ko.ObservableArray<string> = ko.observableArray([]);

    constructor()
    {
        super(ProlifeSdk.TagType_String, "LABELS");
    }

    public LoadFromModel(tags : ITodoListTaskTag[])
    {
        super.LoadFromModel(tags);

        var labels : string[] = this.ExtractTagsByField(tags).map((t : ITodoListTaskTag) => { return t.Value; });
        this.Labels(labels);

        var value = this.ExtractTagsByField(tags).map((t : ITodoListTaskTag) => {return JSON.stringify(this.FormatAsTag(t.Value))}).join("|");
        this.TagsValue(value);
    }

    public SearchTags(query : any)
    {
        var textFilter = query.term;
        var wordMatchGroup = {
            text : "",
            children : [{
                id : JSON.stringify(<ITodoListTaskTag>{
                    Type : ProlifeSdk.TagType_String,
                    Field : "LABELS",
                    Value : textFilter
                }),
                text : textFilter
            }]
        }

        query.callback({ results : [wordMatchGroup] });
    }
}
