import * as ko from "knockout";
//import * as accounting from "accounting";

(<any>accounting).settings = {
	currency: {
		symbol : "€ ",   // default currency symbol is '$'
		format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
		decimal : ",",  // decimal point separator
		thousand: "'",  // thousands separator
		precision : 2   // decimal places
	},
	number: {
		precision : 2,  // default precision on numbers is 0
		thousand: "'",
		decimal : ","
	}
}

export function roundTo(value : number, decimal : number) : number{
	var i : number = value * Math.pow(10, decimal);
	i = Math.round(i);
	return i / Math.pow(10, decimal);
}

export function percentageUnformatter(value: string,decimal: number): number{
	var result : string[] = value.match(/([0-9'.,]*)[ ]*([%]*)/);
	var num : number = numberUnformatter(result[1], 0);

	if (num > 100)
		return 99.99;
	return num;
}

export function numberUnformatter(value: string, decimal: number): number
{
	if(value == undefined || value == null)
		return 0;

	value = value.replace(".",accounting.settings.number.decimal);
	value = value.replace(",",accounting.settings.number.decimal);
	return roundTo(accounting.unformat(value,","), decimal == null ? 2 : decimal);
}

export function digitUnformatter(value : string): number{
	return numberUnformatter(value,0);
}

export function moneyFormatter(value: number) : string
{
    var newvalue : string = (value == null || value == undefined) ? "0" : value.toString().replace(",",".");
	return accounting.formatMoney(newvalue);
}

export function numberFormatter(value: number): string
{
    var newvalue : string = (value == null || value == undefined) ? "0" : value.toString().replace(",",".");
	return accounting.formatNumber(value || 0);
}