import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver";
import { IAuthenticationServiceObserver } from "../../Core/interfaces/IAuthenticationServiceObserver";
import { IApplicationsServiceObserver, IApplicationsService } from "../interfaces/IApplicationsService";
import { IApplicationsStarterObserver, IApplicationBase } from "../interfaces/IApplication";
import { IApplicationConfiguration } from "../interfaces/IApplicationConfiguration";
import { IApplicationHost } from "../interfaces/IApplicationHost";
import { IUserForClient } from "../../Core/interfaces/IPrincipal";
import { LazyImport } from "../../Core/DependencyInjection";
import { ApplicationStarter } from "./ApplicationStarter";

export class ProLifeApplication
    extends ApplicationStarter
    implements IAuthorizationServiceObserver, IAuthenticationServiceObserver, IApplicationsServiceObserver
{
    public showedInDesktop = ko.observable(false);
    public showedInSideBar = ko.observable(false);
    private observers: IApplicationsStarterObserver[] = [];

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;
    @LazyImport(nameof<IApplicationsService>())
    private applicationsService: IApplicationsService;

    constructor(private serviceLocator: IServiceLocator, public application: IApplicationBase) {
        super();

        this.applicationName = application.getName();
        this.applicationIcon = application.getIcon();
        this.tileColor = application.tileColor || "bg-yellow-gold";

        this.authorizationService.addObserver(this);
        this.authenticationService.addObserver(this);
        this.applicationsService.registerObserver(this);
    }

    public addObserver(o: IApplicationsStarterObserver) {
        this.observers.push(o);
    }

    public onConfigurationsLoaded(configurations: IApplicationConfiguration[]) {
        const matches = configurations.filter((c: IApplicationConfiguration) => {
            return c.Code == this.application.getApplicationCode();
        });

        const newShowedInDesktopValue: boolean = matches.length > 0 && matches[0].DefaultLocation == 0;
        const newShowedInSidebarValue: boolean = matches.length > 0 && matches[0].DefaultLocation == 1;
        this.showedInDesktop(newShowedInDesktopValue);
        this.showedInSideBar(newShowedInSidebarValue);
    }

    public start() {
        location.href = this.application.getApplicationRoute();
        const applicationHost = <IApplicationHost>(
            this.serviceLocator.findService(ProlifeSdk.ApplicationHostServiceType)
        );
        applicationHost.closeMenu();
    }

    public getApplicationRoute() {
        return this.application.getApplicationRoute();
    }

    authorizationLoaded(rights: any): void {
        this.areAuthorizationsLoaded = true;
        this.registerRoutes();
        this.observers.forEach((o: IApplicationsStarterObserver) => {
            o.OnAuthorizationsLoaded();
        });
    }

    private registerRoutes() {
        if (!this.application.getApplicationCode) return;

        const applicationCode = this.application.getApplicationCode();
        if (this.authorizationService.isAuthorized(applicationCode + "_Start")) {
            this.application.registerRoutes();
            this.visible(true);
        } else this.visible(false);
    }

    userLoggedIn(user: IUserForClient): void {}

    userLoggedOut(): void {
        this.visible(false);
    }

    loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any): void {}
}
