import * as ko from "knockout";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { TemplatesDesigner } from "./ui/templates-designer/TemplatesDesigner";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IContextAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";

export class TemplatesDesignerApplication implements IApplication
{
    public templateName : string = 'templates-designer';
    public templateUrl : string = 'todolist/templates/templates-designer';
    public templateData : ko.Observable<any> = ko.observable();
    public tileColor : string = 'tile-grey';

    private applicationsService : IApplicationsService;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);
    public contextActions: ko.ObservableArray<IContextAction> = ko.observableArray([]);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return ProlifeSdk.TodolistApplicationCode;
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Todolist.TemplatesEditor;
    }

    getIcon(): string
    {
        return "icon-share";
    }

    registerRoutes() : void
    {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/WorkflowDesigner", (context) => this.start());
    }

    getApplicationRoute() : string
    {
        return "#/WorkflowDesigner";
    }

    onGoingDown(): void
    {
        this.templateData().dispose();
        this.templateData(undefined);
        //delete this.templateData();
    }

    private start()
    {
        if(this.templateData())
        {
            this.templateData().dispose && this.templateData().dispose();
            this.templateData(undefined);
            //delete this.templateData();
        }

        this.templateData(new TemplatesDesigner(this.serviceLocator));

        this.contextActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Todolist.NewTemplate),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.templateData().PrepareNewTemplate.bind(this.templateData())
        }]);

        this.applicationsService.startApplication(this);
    }
}