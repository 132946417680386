import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 09/04/13
 * Time: 9.55
 * To change this template use File | Settings | File Templates.
 */

export class FitText {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var value = ko.utils.unwrapObservable(valueAccessor());
        var options = allBindingsAccessor();

        if(!$(element).attr("fitTextFontSize"))
        {
            var compressor = ko.utils.unwrapObservable(options.fitCompressor) || 1,
                minFontSize = ko.utils.unwrapObservable(options.fitMinFontSize) || 10,
                maxFontSize = ko.utils.unwrapObservable(options.fitMaxFontSize) || 14;

            $(element).text(value);

            var width = $(element).width(),
                html = '<span style="white-space: nowrap"></span>',
                line = $(element).wrapInner(html).children()[0],
                n = maxFontSize;

            $(element).css('font-size', n);
            while($(line).width() > width) {
                if(n == minFontSize) {
                    $(element)
                        .css('white-space', 'nowrap')
                        .css('text-overflow', 'ellipsis')
                        .css('overflow', 'hidden');
                    break;
                }
                --n;
            }

            $(element).css('font-size', n);
            $(element).attr("fitTextFontSize", n);
        }


        $(element).text(value);
    }
}

ko.bindingHandlers["fitText"] = new FitText();
