import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/03/2018
 * Time: 16:45
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ExtendedAdvancedSearchFilter } from "./AdvancedEventsFilters";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IWaitingListEventViewModel } from "../../interfaces/IAgendaEvent";
import { INavigationMenuAction } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { IAgendaService } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IAgendaViewModel } from "../../interfaces/IAgenda";
import { IWaitingListEvent, IEventsService, IAgendaEvent } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { ISearchFilter } from "../../../ProlifeSdk/interfaces/ISearchFilter";
import { ILogTagFilter } from "../../../ProlifeSdk/interfaces/ILogFilter";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";

export class WaitingList {
    public Events: ko.ObservableArray<IWaitingListEventViewModel> = ko.observableArray([]);
    public EditingModeEnebled: ko.Observable<boolean> = ko.observable();
    public MenuActions : ko.ObservableArray<INavigationMenuAction> = ko.observableArray([]);
    public SearchFilters: ko.Observable<string> = ko.observable("");

    public AdvancedFilterActive: ko.Computed<boolean>;
    public IsEmpty: ko.Computed<boolean>;

    private AdvancedSearchFilter: ExtendedAdvancedSearchFilter;

    private agendasService: IAgendaService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private agenda: IAgendaViewModel) {
        this.agendasService = <IAgendaService> this.serviceLocator.findService(ProlifeSdk.AgendaServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.EditingModeEnebled(false);
        this.SetupMenuActions();

        this.AdvancedSearchFilter = new ExtendedAdvancedSearchFilter(this.serviceLocator);

        this.IsEmpty = ko.computed(() => {
            return this.Events().length == 0;
        });

        this.AdvancedFilterActive = ko.computed(() => {
            return this.AdvancedSearchFilter.IsActive();
        });

        this.SearchFilters.subscribe((filtersString: string) => {
            this.search();
        });
    }

    public LoadWaitingList(): Promise<IWaitingListEvent[]> {
        return this.search();
    }

    public ShowAdvancedFiltersDialog(): void {
        this.AdvancedSearchFilter.ShowDialog()
            .then(() => {
                this.search();
            });
    }

    public SelectEvent(eventId: number): void {
        this.Events().forEach((e: IWaitingListEventViewModel) => { if (e.EventId == eventId) e.Selected(true); else e.Selected(false); });
    }

    public RemoveEvent(eventId: number): void {
        var events: IWaitingListEventViewModel[] = this.Events();

        for (var i = 0; i < events.length; i++) {
            if (events[i].EventId == eventId) {
                events.splice(i, 1);
                break;
            }
        }

        this.Events(events);
        this.agenda.RefreshEditorMenu();
    }

    private CreateWaitingListEventViewModel(event: IWaitingListEvent): IWaitingListEventViewModel {
        return new WaitingListEvent(this.serviceLocator, event, this);
    }

    private SetupMenuActions(): void {
        var switchEditingMode: INavigationMenuAction = {
            IsSeparator: false,
            Text: ko.observable(this.EditingModeEnebled() ? ProlifeSdk.TextResources.Agenda.CloseEditingMode : ProlifeSdk.TextResources.Agenda.EnableEditingMode),
            Icon: ko.observable(this.EditingModeEnebled() ? "fa fa-undo" : "fa fa-pencil"),
            Action: () => { this.EditingModeEnebled(!this.EditingModeEnebled()); }
        };

        var editingModeSwitchInterceptor = ko.computed(() => {
            switchEditingMode.Text(this.EditingModeEnebled() ? ProlifeSdk.TextResources.Agenda.CloseEditingMode : ProlifeSdk.TextResources.Agenda.EnableEditingMode);
            switchEditingMode.Icon(this.EditingModeEnebled() ? "fa fa-undo" : "fa fa-pencil");
        });

        this.MenuActions.push(switchEditingMode);
    }

    private search(): Promise<IWaitingListEvent[]> {
        var filters: ISearchFilter[] = [];
        this.pushSearchFieldFilters(filters);
        this.pushAdvancedFilters(filters);

        return this.agendasService.GetWaitingList(this.agenda.AgendaId(), filters)
            .then((events: IWaitingListEvent[]) => {
                this.Events(events.map((e: IWaitingListEvent) => this.CreateWaitingListEventViewModel(e)));
                return events;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.EventsHintSearchError);
                return [];
            });
    }

    private pushSearchFieldFilters(filters: ISearchFilter[]): void {
        if (!!this.SearchFilters())
            filters.push({
                HintSearch: this.SearchFilters(),
                IsSearchForAllFilter: true
            });
    }

    private pushAdvancedFilters(filters: ISearchFilter[]): void {
        var advFilter = this.AdvancedSearchFilter.GetAdvancedFilters();

        if (!advFilter)
            return;

        filters.push({
            From: advFilter.From ? moment(advFilter.From).startOf("day").toDate() : null,
            To: advFilter.To ? moment(advFilter.To).endOf("day").toDate() : null,
            Tags: []
        });

        advFilter.Tags.forEach((t: ILogTagFilter) => {
            filters.push(<ISearchFilter> t);
        });
    }
}

class WaitingListEvent implements IWaitingListEventViewModel {
    public EventId: number;
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public EventJson: ko.Observable<string> = ko.observable("");

    public ShowEditingButton: ko.Computed<boolean>;

    private eventsService: IEventsService;

    constructor(private serviceLocator: IServiceLocator, public event: IWaitingListEvent, private WaitingList: WaitingList) {
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);

        this.EventId = event.id;
        this.EventJson(this.CreateEventJson());

        this.ShowEditingButton = ko.computed(() => {
            return this.WaitingList.EditingModeEnebled();
        });
    }

    public Edit(): void {
        this.eventsService.ui.GetEventEditingDialog(this.event.id, this.event.AgendaId)
            .then((modifiedEvent: IAgendaEvent) => {
                if (!modifiedEvent)
                    return;

                var settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
                var categoriesSettings = <ICategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.EventsCategoriesSettingsManager);

                var categoryLogicalState: number = categoriesSettings.getCategoryById(modifiedEvent.CategoryId).LogicalState;

                if (categoryLogicalState != ProlifeSdk.WaitingListEventState) {
                    this.WaitingList.RemoveEvent(this.EventId);
                }
            });
    }

    public Select():void {
        this.WaitingList.SelectEvent(this.EventId);
    }

    public OnDoubleClick(): void {
        this.Edit();
    }

    private CreateEventJson(): string {
        var duration = moment.duration(<any>moment(this.event.end) - <any>moment(this.event.start));

        var event: IAgendaEvent = {
            id: 'E-' + this.event.id,
            title: this.event.title,
            allDay: this.event.allDay,
            editable: true,
            color: this.event.color,
            AgendaId: this.event.AgendaId,
            CategoryId: this.event.CategoryId,
            Note: this.event.Note,
            Deleted: this.event.Deleted,
            EventPlace: this.event.EventPlace,
            Allocable: this.event.Allocable,
            start: moment(this.event.start).format("LT"),
            end: null,
            duration: (duration.hours() < 9 ? <any>('0' + duration.hours()) : <any>(duration.hours())) + ':' + duration.minutes(),
            stick: false,
            CreatedBy: this.event.CreatedBy,
            CreationDate: this.event.CreationDate,
            ModifiedBy: this.event.ModifiedBy,
            ModifyDate: this.event.ModifyDate,
            PreEventAllocatedHours: this.event.PreEventAllocatedHours,
            PostEventAllocatedHours: this.event.PostEventAllocatedHours,
            IsFromBookingPortal: false,
            EventAbortedMotivationId: this.event.EventAbortedMotivationId,
            EventSourceProvider: this.event.EventSourceProvider,
            EventCalendarOnProvider: this.event.EventCalendarOnProvider,
            EventIdOnProvider: this.event.EventIdOnProvider
        };

        return JSON.stringify(event);
    }
}