import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/06/2017
 * Time: 16:11
 * To change this template use File | Settings | File Templates.
 */

import { Section } from "../Section";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionForEdit } from "./QuestionForEdit";
import { QuestionnaireEditViewModel } from "./QuestionnaireEditViewModel";
import { SectionEditDialog } from "./dialogs/SectionEditDialog";
import { DescriptionPopOver, PopOverData } from "../../popover/DescriptionPopOver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyService } from "../../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISelectable } from "../../../../../ProlifeSdk/interfaces/survey/ISelectable";
import { ISortable } from "../../../../interfaces/ISortable";
import { ISelectionObserver } from "../../../../../ProlifeSdk/interfaces/survey/ISelectionObserver";
import { ISection, ISectionForEdit } from "../../../../../ProlifeSdk/interfaces/survey/ISection";
import { IQuestionForEdit, IQuestion } from "../../../../../ProlifeSdk/interfaces/survey/IQuestion";

export class SectionForEdit extends Section implements ISelectable, ISortable {
    public templateName: string = "sections-list-element";
    public templateUrl: string = "survey/templates/questionnaire";
    public Selected: ko.Observable<boolean> = ko.observable(false);

    public Questions: ko.ObservableArray<QuestionForEdit> = ko.observableArray([]);
    public IsNew: boolean;
    public Deleted: ko.Observable<boolean> = ko.observable(false);

    public PopOverData: PopOverData;
    public PopOverViewModel: DescriptionPopOver;

    public NewQuestionText: ko.Observable<string> = ko.observable();

    public QuestionsNumber: ko.Computed<number>;

    private surveyService: ISurveyService;
    private dialogService: IDialogsService;
    private infoToastService: IInfoToastService;

    private selectionObservers: ISelectionObserver[] = [];

    constructor(private serviceLocator: IServiceLocator, section: ISection, isNew: boolean, public parent: QuestionnaireEditViewModel) {
        super(section);
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.PopOverData = new PopOverData(this.Description);
        this.PopOverViewModel = new DescriptionPopOver(this.PopOverData);

        this.IsNew = isNew;

        this.QuestionsNumber = ko.computed(() => {
            return this.Questions().filter((q: QuestionForEdit) => {
                return !q.Deleted();
            }).length;
        });
    }

    public AddNewQuestion() {
        if (!this.NewQuestionText())
            return;
        var newQuestion = <IQuestionForEdit> {
            Id: this.getAvailableQuestionId(),
            ParentId: this.Id,
            ParentLabel: this.Label(),
            Text: this.NewQuestionText(),
            TypeId: 1,
            TypeName: null,
            Description: null,
            Label: this.parent.NewQuestionId.toString(),
            Index: this.QuestionsNumber() + 1,
            Enabled: false,
            CreationDate: null,
            CreatorId: null,
            ModifyDate: null,
            ModifierId: null,
            QuestionnaireId: this.parent.Questionnaire().Id,
            AnswerTypeId: this.parent.AnswerTypes()[0].Id,
            AnswerTypeName: this.parent.AnswerTypes()[0].Description,
            Answers: [],
            IsNew: true,
            Deleted: false,
            PublicLabel: null
        };
        this.parent.NewQuestionId += 1;
        var questionViewModel: QuestionForEdit = new QuestionForEdit(this.serviceLocator, newQuestion, true, this, this.parent);
        questionViewModel.RegisterSelectionObserver(this.parent);
        questionViewModel.RegisterQuestionMovementObserver(this.parent);
        this.Questions.push(questionViewModel);
        this.NewQuestionText(null);
    }

    public RegisterSelectionObserver(observer: ISelectionObserver) {
        this.selectionObservers.push(observer);
    }

    public NotifySelection() {
        this.selectionObservers.forEach((o: ISelectionObserver) => {
            o.selectSection(this);
        });
    }

    public loadQuestions() {
        if (!this.IsNew) {
            this.surveyService.getSectionQuestions(this.Id)
                .then((questions: IQuestion[]) => {
                    if (questions.length == 0) {
                        //this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.QuestionsNotFounded);
                        return;
                    }
                    this.Questions(questions.map((q: IQuestion) => {
                        var newQuestion: QuestionForEdit = new QuestionForEdit(this.serviceLocator, q, false, this, this.parent)
                        this.selectionObservers.forEach((o: any) => {
                            newQuestion.RegisterSelectionObserver(o);
                            newQuestion.RegisterQuestionMovementObserver(o);
                        })
                        return newQuestion;
                    }));
                    this.parent.NewQuestionId += this.Questions().length;
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
                });
        }
    }

    public ToJSON(): ISectionForEdit {
        var json = <ISectionForEdit> super.ToJSON();
        json.Questions = this.Questions().map((q: QuestionForEdit) => {
            return q.ToJSON();
        });
        json.IsNew = this.IsNew;
        json.Deleted = this.Deleted();
        return json;
    }

    public Delete() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Survey.DeleteSection,
            ProlifeSdk.TextResources.Survey.DeleteSectionCancel,
            ProlifeSdk.TextResources.Survey.DeleteSectionConfirm,
            (confirm) => {
                if (confirm)
                    this.deleteSection();
            }
        )
    }

    public Restore() {
        this.Deleted(false);
        this.propagateDeletedState(false);
    }

    public DeselectQuestions(): void {
        this.Questions().forEach((q: QuestionForEdit) => {
            q.Selected(false);
        });
    }

    public OnQuestionMoved(beforeId : number, movedId : number, afterId : number) {
        var actualPosition: number = this.getQuestionIndex(movedId, this.Questions());
        var previousQuestionPosition: number = this.getQuestionIndex(beforeId, this.Questions());
        var nextQuestionPosition: number = this.getQuestionIndex(afterId, this.Questions());
        this.updateIndexes(movedId, actualPosition, previousQuestionPosition, nextQuestionPosition, this.Questions());
    }

    private getQuestionIndex(id: number, elements: ISortable[]): number {
        var index: number = null;
        elements.forEach((el: ISortable) => {
            if (el.Id == id)
                index = el.Index();
        });
        return index;
    }

    private updateIndexes(elementId: number, actualIndex: number, previousObjectIndex: number, nextObjectIndex: number, elements: ISortable[]): void {
        if (!previousObjectIndex && !nextObjectIndex)
            return;
        previousObjectIndex = !previousObjectIndex ? 0 : previousObjectIndex;
        nextObjectIndex = !nextObjectIndex ? 99999 : nextObjectIndex;
        if (actualIndex < previousObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() >= actualIndex && e.Index() <= previousObjectIndex && e.Id != elementId)
                    e.Index(e.Index() - 1);
                if (e.Id == elementId)
                    e.Index(previousObjectIndex);
            })
            return;
        }
        if (actualIndex > nextObjectIndex) {
            elements.forEach((e: ISortable) => {
                if(e.Index() <= actualIndex && e.Index() >= nextObjectIndex && e.Id != elementId)
                    e.Index(e.Index() + 1);
                if (e.Id == elementId)
                    e.Index(nextObjectIndex);
            })
        }
    }

    private getAvailableQuestionId(): number {
        return this.QuestionsNumber() == 0 ? 1 : (Math.max.apply(Math, this.Questions().map((q: QuestionForEdit) => { return q.Id; }))) + 1;
    }

    private deleteSection() {
        this.Deleted(true);
        this.propagateDeletedState(true);
    }

    private propagateDeletedState(deleted: boolean): void {
        this.Questions().forEach((q: QuestionForEdit) => {
            deleted ? q.deleteQuestion() : q.Restore();
        });
    }

    public EditSection() {
        var editSectionDialog = new SectionEditDialog(this.serviceLocator, this, this.parent);
        this.dialogService.ShowModal<void>(editSectionDialog, null, null, "survey/templates/questionnaire/dialogs", "edit-section-dialog");
    }
}