import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IExpenseProject } from "../../../ProlifeSdk/interfaces/expense/IExpenseService";
import { ISearchResultsProvider } from "../../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { IEntitySearchResult } from "../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { Deferred } from "../../../Core/Deferred";

export class JobOrdersAndProjectsSearchProvider implements ISearchResultsProvider
{
    private entityProviderService : IEntityProviderService;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(serviceLocator : IServiceLocator)
    {
        this.entityProviderService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
    }

    public ResolveName(value : string) : Promise<string>
    {
        if(!value)
            return Promise.resolve<string>("");

        var def = new Deferred();
        var type : string = value.split("#")[1];
        var id : number = parseInt(value.split("#")[0]);
        var provider = this.entityProviderService.getEntityProvider(type);
        return provider ? provider.getDisplayNameForKeyValue(id) : Promise.resolve<string>("");
    }

    public GetProjectKey(expenseProject : IExpenseProject) : string
    {
        return expenseProject && expenseProject.Id > 0 ? expenseProject.ProjectId + "#" + expenseProject.ProjectType : null;
    }

    public ResolveId(value : string) : number
    {
        if(!value)
            return 0;

        var parts : string[] = value.split("#");
        var id : number = parseInt(parts[0]);
        return id;
    }

    public ResolveType(value : string) : string
    {
        if(!value)
            return "";

        var parts : string[] = value.split("#");
        var type : string = parts[1];
        return type;
    }

    public findSearchMatches(query : any)
    {
        if (this.lastTimeout)
            clearTimeout(this.lastTimeout);

        var data = { results: [] };
        var menuGroup;
        var providersDef = new Deferred();

        this.lastTimeout = setTimeout(() => {
            this.entityProviderService.search(query.term).then((results:IEntitySearchResult[]) => {

                results.forEach((result:IEntitySearchResult) => {

                    if (result.entities.length == 0 ||
                        (result.provider.getType() != ProlifeSdk.JobOrderEntityTypeCode &&
                            result.provider.getType() != ProlifeSdk.ProjectEntityTypeCode))
                        return;

                    menuGroup = {
                        text: result.provider.getEntityTypeName(),
                        children: []
                    }

                    result.entities.forEach(e => {

                        var id = result.provider.getPkValue(e);
                        var type:string = result.provider.getType();
                        var name:string = result.provider.getDisplayName(e);
                        menuGroup.children.push({
                            id: id + "#" + type,
                            text: name
                        });
                    });

                    data.results.push(menuGroup);
                })
                providersDef.resolve();
            }).catch(() => providersDef.reject());

            Promise.all([providersDef.promise()]).then(() => {
                query.callback(data);
            });
        }, 1000);
    }

    public findSearchMatch(element, callback)
    {
        var value = element.val();

        if(value.indexOf("#") < 0)
            return;

        var type : string = value.split("#")[1];
        var id : number = parseInt(value.split("#")[0]);
        this.ResolveName(value)
            .then((name : string) => {
                callback({
                    id : value,
                    text : name
                });
            });
    }
}