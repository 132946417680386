import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { IEstimatedBudgetForTaskStatesSettingsManager } from "../../../interfaces/IEstimatedBudgetForTaskStatesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IEstimatedBudgetForTaskState } from "../../../interfaces/IEstimatedBudgetForTaskStatesService";
import { IException } from "../../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { EstimatedBudgetForTaskStatesDataSource, EsimatedBudgetForTaskState } from "../../../../DataSources/EstimatedBudgetForTaskStatesDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import ko = require("knockout");
import { IListItem } from "../../../../Components/ListComponent";

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "estimated-budget-for-task-states-editor": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IEstimatedBudgetForTaskStatesEditorParams {

}

export class EstimatedBudgetForTaskStatesEditor {
    public title: ko.Observable<string> = ko.observable();
    public StatesDataSource: EstimatedBudgetForTaskStatesDataSource = new EstimatedBudgetForTaskStatesDataSource();

    @LazyImportSettingManager(nameof<IEstimatedBudgetForTaskStatesSettingsManager>())
    private estimatedBudgetForTaskStatesManager: IEstimatedBudgetForTaskStatesSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(params : IEstimatedBudgetForTaskStatesEditorParams) {
        this.title(this.estimatedBudgetForTaskStatesManager.getLabel());
    }

    public async createNew(): Promise<void>
    {
        let newState: IEstimatedBudgetForTaskState = {
            Id : null,
            Label : TextResources.Todolist.DefaultEstimatedBudgetForTaskStateLabel,
            Icon: "icon-info",
            Background: "#FFFFFF",
            Foreground: "#000000",
            Order : 1000,
            Deleted : false
        };

        try {
            await this.estimatedBudgetForTaskStatesManager.createOrUpdateStates([newState]);
            this.StatesDataSource.refresh();
        } catch (e) {
            let exception: IException = e as IException;
            this.infoToastService.Error(exception.ExceptionMessage);
        }
    }
}

ko.components.register("estimated-budget-for-task-states-editor", {
    viewModel: {
        createViewModel: (params: IEstimatedBudgetForTaskStatesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new EstimatedBudgetForTaskStatesEditor(params);
            let $data: IListItem<EsimatedBudgetForTaskState>;
            let estimatedBudgetState: EsimatedBudgetForTaskState;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="flex-container flex-vertical flex-full-height" data-as={{vm}}>
                    <div class="flex-container" style={{ alignItems: "center", height: "60px" }}>
                        <h3 class="page-title flex-fill" style={{ margin: 0 }}>
                            <span data-bind={{ text: vm.title }}></span>
                        </h3>
                        <button type="button" class="btn btn-primary" data-bind={{ click: vm.createNew.bind(vm) }}>
                            <i class="fa fa-plus"></i> {TextResources.Todolist.NewEstimatedBudgetForTaskStateLabel}
                        </button>
                    </div>
                    <div class="flex-container flex-vertical flex-fill">
                        <list sortable={true} allowNoSelection={true} dataSource={() => "vm.StatesDataSource"} containerHeight="flex">
                            <div class="row no-gutters" data-bind={{ with: $data.Model, as: "estimatedBudgetState" }}>
                                <div class="col-md-4">
                                    <label>{TextResources.Todolist.EstimatedBudgetForTaskStateLabel}</label>
                                    <input class="form-control" placeholder={TextResources.Todolist.EstimatedBudgetForTaskStatePlaceholder} type="text" data-bind={{ value: estimatedBudgetState.Label }} />
                                </div>

                                <div class="col-md-7">
                                    <div class="col-md-3">
                                        <label>{TextResources.Todolist.EstimatedBudgetForTaskStateIconLabel}</label>
                                        <div>
                                            <button class="btn btn-default" data-bind={{ iconPicker: estimatedBudgetState.Icon }} role="iconpicker" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label>{TextResources.Todolist.EstimatedBudgetForTaskStateBackgroundLabel}</label>
                                        <input type="text" class="form-control" data-bind={{ colorPicker: { colorField: estimatedBudgetState.Background, options: { showHexInput: false } } }} />
                                    </div>
                                    <div class="col-md-2">
                                        <label>{TextResources.Todolist.EstimatedBudgetForTaskStateForegroundLabel}</label>
                                        <input type="text" class="form-control" data-bind={{ colorPicker: { colorField: estimatedBudgetState.Foreground, options: { showHexInput: false } } }} />
                                    </div>
                                    <div class="col-md-5">
                                        <label>{TextResources.Todolist.EstimatedBudgetForTaskStatePreviewLabel}</label>
                                        <div>
                                            <div style={{ padding: "8px", marginTop: "-6px", float: "left" }}>
                                                <div class="btn btn-primary btn-lg" data-bind={{ style: { background: estimatedBudgetState.Background, color: estimatedBudgetState.Foreground }}} style={{ minHeight: "30px", minWidth: "35px", textAlign: "center", padding: 0, margin: "2px", lineHeight: "30px" }}>
                                                    <i data-bind={{ css: estimatedBudgetState.Icon }}></i>
                                                </div>
                                            </div>
                                            <div style={{ padding: "8px", marginTop: "-6px", float: "left", background: "#1F1F1F" }}>
                                                <div class="btn btn-primary btn-lg" data-bind={{ style: { background: estimatedBudgetState.Background, color: estimatedBudgetState.Foreground } }} style={{ minHeight: "30px", minWidth: "35px", textAlign: "center", padding: 0, margin: "2px", lineHeight: "30px" }}>
                                                    <i data-bind={{ css: estimatedBudgetState.Icon }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1">
                                    <label>&nbsp;</label>
                                    <button class="btn btn-danger" style="margin-left: 10px" data-bind={{ asyncClick : estimatedBudgetState.markAsDeleted.bind(estimatedBudgetState) }}>
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </div>
                        </list>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});