import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../Core/DependencyInjection";
import type { Provisioning } from "./ui/Provisioning";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { BaseComponentApplication } from "../../Desktop/BaseComponentApplication";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

export class ProvisioningApplication extends BaseComponentApplication {
    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;

    constructor() {
        super({
            tileColor: "tile-red"
        })
    }
    
    public getName(): string {
        return TextResources.Provisioning.ApplicationName;
    }
    
    public getIcon(): string {
        return "fa fa-truck";
    }

    public getApplicationRoute(): string {
        return ProlifeSdk.ProvisioningApplicationRoute;
    }
    
    public getApplicationCode(): string {
        return ProlifeSdk.ProvisioningApplicationCode;
    }
    
    public registerRoutes(): void {
        this.opaService.Get(/#\/Provisioning(\/.*)?/, () => this.startApplication());
    }
    
    private startApplication(): void {
        if(this.applicationsService.getCurrentApplicationCode() == this.getApplicationCode()) return;

        this.onGoingDown();
        this.applicationsService.startApplication(this);
    }

    getComponent(): React.ReactNode {
        const Prov = require("./ui/Provisioning").Provisioning as typeof Provisioning;
        return <Prov navBarActionManager={this} />;
    }
}