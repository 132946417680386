import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CustomersProvider } from "./providers/CustomersProvider";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ICustomersManager } from "../../../ProlifeSdk/interfaces/customer/ICustomersManager";
import { IEventCustomersProvider } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";

export class CustomersManager implements ICustomersManager {
    public Customers: ko.Observable<string> = ko.observable();
    public CustomersIds: ko.ObservableArray<number> = ko.observableArray([]);

    public CustomersProvider: IEventCustomersProvider;

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;

    constructor() {
        this.CustomersProvider = new CustomersProvider();
    }

    public LoadCustomers(customers: number[]) {
        this.CustomersIds(customers);
        const customersString = customers.join("|");
        this.Customers(customersString);
    }

    public GetCustomers(): number[] {
        if (!this.Customers())
            return [];
        return this.Customers().split('|').map((id: string) => { return parseInt(id); });
    }

    public ShowCustomersDialog(customerId: number = null, compactView = false): Promise<void> {
        if (!customerId)
            return this.customersService.ui.showNewCustomerDialog(null, null, null, compactView);
        return this.customersService.ui.showEditCustomerDialog(customerId, compactView);
    }
}