import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { HumanResourcesControlsEntityProvider } from "./HumanResourcesControlsEntityProvider";
import { IHumanResourcesService, IHumanResource } from "../../HumanResourcesService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class HumanResourceEntityProvider implements IEntityProvider<number, IHumanResource>
{
    @LazyImport(nameof<IHumanResourcesService>())
    private resourcesService : IHumanResourcesService;
    @LazyImport(nameof<IEntityProviderService>())
    private entityProviderService : IEntityProviderService;

    constructor()
    {
        this.entityProviderService.registerEntityProvider(this, this.getType());
    }

    getType() : string
    {
        return ProlifeSdk.HumanResources;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.Users.Resource;
    }

    getPkValue(item : IHumanResource) : number
    {
        return item.Resource.Id;
    }

    getDisplayName(user : IHumanResource) : string
    {
        //return item.Surname + " " + item.Name;
        return this.resourcesService.getFullName(user);
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        const def = new Deferred<string>();
        this.resourcesService.GetHumanResource(pkValue).then(
            (user : IHumanResource) => {
                def.resolve(this.resourcesService.getFullName(user));
            })
            .catch(() => { def.reject(); });
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IHumanResource[]>
    {
        const def: Deferred<IHumanResource[]> = new Deferred();

        this.resourcesService.GetHumanResources(stringFilter, false, false, true, null, 0, 10)
            .then((resources: IHumanResource[]) => {
                def.resolve(resources);
            })
            .catch((e) => {
                def.reject(e);
            });

        return def.promise();
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new HumanResourcesControlsEntityProvider();
    }

    getEntity(pkValue : any) : Promise<IHumanResource>
    {
        return this.resourcesService.GetHumanResource(parseInt(pkValue));
    }
}