import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/03/2018
 * Time: 16:13
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { CustomGroupedEntitiesProvider } from "./providers/CustomGroupedEntitiesProvider";
import * as moment from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../Core/interfaces/IDialogsService";
import { IEventsAdvancedFilterViewModel, IEventAdvancedFilter, IExtendedEventAdvancedFilter } from "../../interfaces/IAgendaEvent";
import { ISearchResultsProvider } from "../../../ProlifeSdk/interfaces/ISearchResultsProvider";
import { ISearchTagFilter } from "../../../ProlifeSdk/interfaces/ISearchTagFilter";

export class AdvancedSearchFilter implements IEventsAdvancedFilterViewModel {
    public IsActive: ko.Computed<boolean>;

    private filters: ko.Observable<any> = ko.observable();
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.filters({ From: moment().startOf("day"), To: null });

        this.IsActive = ko.computed(() => {
            return !!this.filters().From || !!this.filters().To;
        });
    }

    public ShowDialog(): Promise<IEventAdvancedFilter> {
        var dialog = new AdvancedFiltersDialog(this.filters());
        return this.dialogsService.ShowModal<IEventAdvancedFilter>(dialog, null, null, "agenda/templates/dialogs", "event-advanced-filter")
            .then((filters: IEventAdvancedFilter) => {
                if (!filters)
                    return filters;
                this.filters(filters);
                return filters;
            });
    }

    public GetAdvancedFilters(): IEventAdvancedFilter {
        return this.filters();
    }

    public Reset(): void {
        this.filters({
            From: moment().startOf("day"),
            To: null
        });
    }
}

export class ExtendedAdvancedSearchFilter implements IEventsAdvancedFilterViewModel {
    public IsActive: ko.Computed<boolean>;

    private filters: ko.Observable<any> = ko.observable();
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.filters({ From: null, To: null, Tags: [] });

        this.IsActive = ko.computed(() => {
            return !!this.filters().From || !!this.filters().To || this.filters().Tags.length > 0;
        });
    }

    ShowDialog(): Promise<IExtendedEventAdvancedFilter> {
        var dialog = new ExtendedAdvancedFiltersDialog(this.serviceLocator, this.filters());
        return this.dialogsService.ShowModal<IExtendedEventAdvancedFilter>(dialog, null, null, "agenda/templates/dialogs", "extended-event-advanced-filter")
            .then((filters: IExtendedEventAdvancedFilter) => {
                if (!filters)
                    return filters;
                this.filters(filters);
                return filters;
            });
    }

    GetAdvancedFilters(): IExtendedEventAdvancedFilter {
        return this.filters();
    }

    Reset(): void {}

}

class AdvancedFiltersDialog implements IDialog {
    public templateName: string = "event-advanced-filter";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Agenda.EventsAdvancedFilterDialogTitle;

    public From: ko.Observable<Date> = ko.observable();
    public To: ko.Observable<Date> = ko.observable();

    modal: { close: (result?: any) => void; };

    constructor(private filters: IEventAdvancedFilter) {
        this.From(filters.From);
        this.To(filters.To);
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        this.modal.close({ From: this.From(), To: this.To() });
    }

    clear(): void {
        this.From(null);
        this.To(null);
    }
}

class ExtendedAdvancedFiltersDialog extends AdvancedFiltersDialog implements ISearchResultsProvider {
    public templateName: string = "extended-event-advanced-filter";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Agenda.EventsAdvancedFilterDialogTitle;

    public SearchResultsProvider: CustomGroupedEntitiesProvider;
    public SearchFilters: ko.Observable<string> = ko.observable("");

    modal: { close: (result?: any) => void; };

    constructor(private serviceLocator: IServiceLocator, filters: IExtendedEventAdvancedFilter) {
        super(filters);

        var tagsJSON = JSON.stringify(filters.Tags).replace(/(\}\,)/g,"}|");
        this.SearchFilters(tagsJSON.substring(1,tagsJSON.length - 1));

        var entitiesCodesList = [ProlifeSdk.AgendasEventsCustomersEntityType, ProlifeSdk.AgendaEventCategoryEntityType];
        this.SearchResultsProvider = new CustomGroupedEntitiesProvider(this, ProlifeSdk.TextResources.ProlifeSdk.TextsAndDates, entitiesCodesList);
    }

    public findSearchMatches(query:any) {}

    public findSearchMatch(element, callback) {}

    private pushSearchFieldFilters(filters: ISearchTagFilter[]): void {
        var allFilters: any[] = JSON.parse("[" + (this.SearchFilters() || "").replace(/\|/g,",") + "]");
        allFilters.forEach((f: ISearchTagFilter) => { filters.push(f); });
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        var filter: IExtendedEventAdvancedFilter = { From: this.From(), To: this.To(), CrossAgendas: null, Tags: [] };
        this.pushSearchFieldFilters(filter.Tags);
        this.modal.close(filter);
    }

    clear(): void {
        this.From(null);
        this.To(null);
        this.SearchFilters("");
    }
}