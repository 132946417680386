import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 11/02/13
 * Time: 10.49
 * To change this template use File | Settings | File Templates.
 */

import { ICustomerBank } from "../../../ProlifeSdk/interfaces/customer/ICustomerBank";
import { IValidation, IValidator, IValidationService } from "../../../ProlifeSdk/ValidationService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { EventHandler } from "../../../ProlifeSdk/prolifesdk/utils/EventHandler";

export class CustomerBankViewModel
{
    public Name : ko.Observable<string> = ko.observable();
    public ABI : ko.Observable<string> = ko.observable();
    public CAB : ko.Observable<string> = ko.observable();
    public IBAN : ko.Observable<string> = ko.observable();

    public HasFocus : ko.Observable<boolean> = ko.observable(false);

    public OnBankChanged: EventHandler = new EventHandler();

    public HasChanges: ko.Computed<boolean>;
    public isValid: ko.Computed<boolean>;
    
    private validator: IValidator<CustomerBankViewModel>;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    constructor(private bank : ICustomerBank)
    {
        this.Name(bank.Name);
        this.ABI(bank.ABI);
        this.CAB(bank.CAB);
        this.IBAN(bank.IBAN);

        this.HasChanges = ko.computed(() => {
            let name = this.Name();
            let iban = this.IBAN();
            let abi = this.ABI();
            let cab = this.CAB();

            ko.ignoreDependencies(() => {
                this.OnBankChanged.Call();
            });

            return name != this.bank.Name
                || abi != this.bank.ABI
                || cab != this.bank.CAB
                || iban != this.bank.IBAN;
        });

        this.isValid = ko.computed(() => {
            return !!this.Name() && !!this.ABI() && !!this.CAB();
        });

        this.validator = this.validationService.createValidator<CustomerBankViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace((a => a.Name()), TextResources.Customers.MissingBankName)
            .isNotNullOrUndefinedOrWhiteSpace((a => a.ABI()), TextResources.Customers.MissingABI)
            .isNotNullOrUndefinedOrWhiteSpace((a => a.CAB()), TextResources.Customers.MissingCAB);
    }

    public getData() : ICustomerBank
    {
        var newBank = <ICustomerBank> Object.assign({}, this.bank);
        newBank.Name = this.Name();
        newBank.ABI = this.ABI();
        newBank.CAB = this.CAB();
        newBank.IBAN = this.IBAN();
        return newBank;
    }

    public GetValidation(): IValidation[] {
        return this.validator.validate(this);
    }
}