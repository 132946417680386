import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { LazyImport } from "../../../Core/DependencyInjection";
import { classNames, reloadNow } from "../../../Core/utils/ComponentUtils";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    "prolife-job-order-link": {
        "& .link-text": {
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type JobOrderLinkProps = {
    jobOrderId: number;
    jobOrderName?: string;
    className?: string;
    openNewTab?: boolean;
};

export function JobOrderLink(props: JobOrderLinkProps) {
    const C = require("./JobOrderLink")._JobOrderLink as typeof _JobOrderLink;
    return <C {...props} />;
}

export class _JobOrderLink {
    static defaultProps: Partial<JobOrderLinkProps> = {
        openNewTab: false,
        className: "",
        jobOrderName: "",
    };

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    constructor(private props: JobOrderLinkProps) {}

    render() {
        const { jobOrderId, jobOrderName, className, openNewTab } = this.props;

        return (
            <div className={classNames(classes["prolife-job-order-link"], className)}>
                <span
                    className="link-text"
                    onClick={() =>
                        window.open(this.jobOrderService.getJobOrderUrl(jobOrderId), openNewTab ? "_blank" : "_target")
                    }>
                    {jobOrderName ?? (!jobOrderId ? "" : TextResources.ProlifeSdk.NotAvailable)}
                </span>
            </div>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderLink);
}
