import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { IExpiredSchedules } from "../../../ProlifeSdk/interfaces/schedule/IExpiredSchedules";

export class ExpiredSchedulesViewModel {
	IdFattura : number;
    IdRegistro : number;
	NumeroFattura : string;
	Importo :  number;
	DataScadenza : Date;
	ImportoScaduto : number;
	GiorniRitardo :  number;

    constructor(expiredSchedule : IExpiredSchedules) {

		this.IdFattura = expiredSchedule.IdFattura;
        this.IdRegistro = expiredSchedule.IdRegistro;
		this.NumeroFattura = expiredSchedule.NumeroFattura;
		this.Importo = expiredSchedule.Importo;
		this.DataScadenza = moment(expiredSchedule.DataScadenza).toDate();
		this.ImportoScaduto = expiredSchedule.ImportoScaduto;
		this.GiorniRitardo = expiredSchedule.GiorniRitardo;
	}
}