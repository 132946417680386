import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as Core from "../../../Core/Core";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IEstimateStates } from "../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { IVatRegisters } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { DocumentLogicalStatus } from "../enums/DocumentLogicalStatus";
import { DocumentClosingStatus } from "../enums/DocumentClosingStatus";
import { InvoiceClosingStatus } from "../enums/InvoiceClosingStatus";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentCauseLogicType } from "../enums/DocumentCauseLogicType";

export type DocumentClosingStatusInfo = { value: number, color: string, label: string };

export class DocumentClosingStatusHelper {
    public static translateDocumentClosingStatus(documentType: string, protocolId: number, causeLogicType: number, documentState: number, documentClosingStatus: number): InvoiceClosingStatus {
        const settingsService: ISettingsService = Core.serviceLocator.findService(nameof<ISettingsService>());
        const estimatesStateManager: IEstimateStates = settingsService.findSettingsManager(ProlifeSdk.EstimateStates) as IEstimateStates;
        const protocolsManager: IVatRegisters = settingsService.findSettingsManager(ProlifeSdk.VatRegisters) as IVatRegisters;
        
        const docLogicalStatus = estimatesStateManager.getEstimateStateById(documentState)?.Stato;
        const protocol = protocolsManager.getVatRegisterById(protocolId);

        let closingStatus = documentClosingStatus;
        const invoicesTypes = [ProlifeSdk.InvoiceEntityTypeCode, ProlifeSdk.AccompanyingInvoiceEntityTypeCode, ProlifeSdk.CreditNoteEntityTypeCode];

        if (invoicesTypes.indexOf(documentType) < 0 || protocol.ForPartialInvoices) {
            if (DocumentClosingStatusHelper.isNotManagedDocument(documentType, docLogicalStatus, causeLogicType))
                closingStatus = InvoiceClosingStatus.NoSchedules;
            else 
                closingStatus = this.mapDocumentClosingStatusToInvoiceClosingStatus(documentClosingStatus);
        }

        return closingStatus;
    }
    
    private static isNotManagedDocument(documentType: string, docLogicalStatus: number, causeLogicType: number) {
        return (docLogicalStatus === DocumentLogicalStatus.Rejected && documentType === ProlifeSdk.EstimateEntityTypeCode)
            || (causeLogicType !== DocumentCauseLogicType.Sale && (documentType === ProlifeSdk.DdtEntityTypeCode || documentType === ProlifeSdk.AccompanyingInvoiceEntityTypeCode));
    }

    public static mapDocumentClosingStatusToInvoiceClosingStatus(documentClosingStatus: DocumentClosingStatus): InvoiceClosingStatus {
        if (documentClosingStatus == DocumentClosingStatus.Opened)
            return InvoiceClosingStatus.ExpiredSchedules;
        
        if (documentClosingStatus == DocumentClosingStatus.PartiallyClosed)
            return InvoiceClosingStatus.OpenedSchedules;
        
        if (documentClosingStatus == DocumentClosingStatus.Closed)
            return InvoiceClosingStatus.ClosedSchedules;
            
        return InvoiceClosingStatus.NoSchedules;
    }

    public static getInvoiceClosingStatusInfo(): DocumentClosingStatusInfo[] {
        const status = [];
        
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.NoSchedules, TextResources.Invoices.InvoiceWithoutSchedules, "transparent"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.ClosedSchedules, TextResources.Invoices.InvoicePaid, "#46c72f"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.ClosedAndOpenedSchedules, TextResources.Invoices.InvoicePartiallyPaid, "linear-gradient(to bottom, #46c72f 0, #ecbc29 100%) !important"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.ExpiredSchedules, TextResources.Invoices.InvoiceNotPaidAndExpired, "#f3565d"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.OpenedAndExpiredSchedules, TextResources.Invoices.InvoiceNotPaidAndSomeExpired, "linear-gradient(to bottom, #ecbc29 0, #f3565d 100%) !important"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.OpenedSchedules, TextResources.Invoices.InvoiceNotPaid, "#ecbc29"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.ClosedAndExpiredSchedules, TextResources.Invoices.InvoicePaidExpired, "linear-gradient(to bottom, #46c72f 0, #f3565d 100%) !important"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(InvoiceClosingStatus.OpenedClosedAndExpiredSchedules, TextResources.Invoices.InvoicePaidExpired, "linear-gradient(to bottom, #46c72f 0, #ecbc29 50%, #f3565d 100%) !important"));
        
        return status;
    }
    
    public static getDocumentClosingStatusInfo(): DocumentClosingStatusInfo[] {
        const status = [];
        
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(DocumentClosingStatus.Opened, TextResources.Invoices.NotManaged, "#f3565d"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(DocumentClosingStatus.PartiallyClosed, TextResources.Invoices.OnManaging, "#ecbc29"));
        status.push(DocumentClosingStatusHelper.createClosingStatusInfo(DocumentClosingStatus.Closed, TextResources.Invoices.Gestito, "#46c72f"));
        
        return status;
    }

    private static createClosingStatusInfo(status: number, label: string, color: string): DocumentClosingStatusInfo {
        return {
            value: status,
            label: label,
            color: color
        };
    }
}