import * as ko from "knockout";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";

export class SpeedFilter
{
    public isActive: ko.Observable<boolean> = ko.observable(false);
    public isTransparent: ko.Observable<boolean> = ko.observable(false);
	isVisible: ko.Observable<boolean> = ko.observable(false);

    isMatch(item: BlogEvent): boolean
    {
        return true;
    }
}
