import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IManufacturer, IManufacturersService } from "../ProlifeSdk/interfaces/warehouse/IManufacturersService";

export type IManufacturerDataSourceModel = IDataSourceModel<number, IManufacturer>;

export class ManufacturersDataSource extends BaseDataSource<IManufacturerDataSourceModel> {
    @LazyImport(ProlifeSdk.ManufacturersServiceType)
    private manufacturersService!: IManufacturersService;

    getTitle(currentModel: IManufacturerDataSourceModel): string {
        return ProlifeSdk.TextResources.Warehouse.Manufacturers;
    }

    isGroupedData(currentModel: IManufacturerDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IManufacturerDataSourceModel, b: IManufacturerDataSourceModel): boolean {
        return (!a && !b) || (!!a && !!b && a.id == b.id);
    }

    async getData(
        currentModel: IManufacturerDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IManufacturerDataSourceModel[]> {
        if (skip != 0) return [];

        return (await this.manufacturersService.SearchManufacturers(textFilter)).map(this.createModelFor, this);
    }

    private createModelFor(manufacturer: IManufacturer): IManufacturerDataSourceModel {
        return {
            id: manufacturer.Id,
            title: manufacturer.Code + " (" + manufacturer.Piva + ")",
            subTitle: manufacturer.Description,
            isLeaf: true,
            isGroup: false,
            model: manufacturer,
        };
    }

    async getById(currentModel: IManufacturerDataSourceModel, ids: number[]): Promise<IManufacturerDataSourceModel[]> {
        let manufacturers = await this.manufacturersService.GetManufacturersByIds(ids);
        return manufacturers.map(this.createModelFor, this);
    }
}
