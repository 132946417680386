import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { Task } from "../ProlifeSdk/prolifesdk/utils/Task";
import { JobOrdersUtils } from "../JobOrder/jobOrder/ui/utils/JobOrdersUtils";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel, IDataSourceView } from "./IDataSource";
import {
    INavigationMenuComponentAlerts,
    INavigationMenuComponentBadge,
    INavigationMenuComponentModel,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import {
    IJobOrderForTaskBoard,
    ITodoListService,
    IGetJobOrdersForUserRequest,
    IGetJobOrdersForUserByIdRequest,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { IJobOrderStateSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderState } from "../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { Deferred } from "../Core/Deferred";

export interface IJobOrderDataSourceModel extends IDataSourceModel<number, IJobOrderForTaskBoard> {
    isLogicalState?: boolean;
    isJobOrderState?: boolean;
    isType?: boolean;
    alerts?: INavigationMenuComponentAlerts;
    badge?: INavigationMenuComponentBadge[];
    jobOrderType?: number;
    jobOrderLogicalState?: number;
    jobOrderState?: number;
}

export class JobOrdersDataSource extends BaseDataSource<IJobOrderDataSourceModel> {
    @LazyImport(nameof<ITodoListService>())
    private todoListService!: ITodoListService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService!: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStateSettingsManager: IJobOrderStateSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesSettingsManager: IJobOrderTypesSettingsManager;

    private showClosed = false;
    private showNoWork = true;
    private filterContents = false;
    private contentTextFilter: string = null;
    private startDate: Date = null;
    private endDate: Date = null;
    private destinationState: number = null;
    private categoryFilter: number = null;

    private workflowTypeFilter = -1;
    private workflowStateFilter = -1;
    private jobOrderStateFilter = -1;
    private userId = -1;
    private resourceId: number = null;
    private customerIds: number[] = [];

    private jobOrderSelectionRequests: number[] = [];
    private pendingSelectJobOrders: Deferred<void> = null;
    private jobOrderNavigationRequest = -1;
    private pendingJobOrderNavigationRequest: Deferred<void> = null;

    private viewWorker = true;
    private viewResponsible = true;
    private viewAll = false;
    private canViewAll = false;

    private bypassCanViewAllForDocuments = false;

    private clearCache = true;
    private lastTextFilter: string = null;
    private jobOrdersCache: IJobOrderForTaskBoard[] = [];
    private cacheRefreshInProgress = false;

    constructor(private onlyJobOrders: boolean = true, private groupJobOrdersPerState: boolean = false) {
        super();
        this.canViewAll = this.authorizationService.isAuthorized("TaskBoard_CanViewAll");
    }

    refresh(keepSelection: boolean) {
        super.refresh(keepSelection);
        this.clearCache = true;
    }

    setCategoryFilter(categoryId: number) {
        this.categoryFilter = categoryId;
        this.clearCache = true;
    }

    setAdvancedFilters(options: { workflowType: number; workflowState: number; jobOrderState: number }) {
        this.workflowTypeFilter =
            options.workflowType === -1 || options.workflowType === null ? undefined : options.workflowType;
        this.workflowStateFilter =
            options.workflowState === -1 || options.workflowState === null ? undefined : options.workflowState;
        this.jobOrderStateFilter =
            options.jobOrderState === undefined || options.jobOrderState === null ? -1 : options.jobOrderState;
        this.clearCache = true;
    }

    setViewFilters(viewWorker: boolean, viewResponsible: boolean, viewAll: boolean) {
        this.viewWorker = viewWorker;
        this.viewResponsible = viewResponsible;
        this.viewAll = viewAll && (this.canViewAll || this.bypassCanViewAllForDocuments);
        this.clearCache = true;
    }

    setBypassCanViewAllForDocuments(value: boolean): void {
        this.bypassCanViewAllForDocuments = value;
    }

    setFilterContents(filterContents: boolean, contentTextFilter: string) {
        this.filterContents = filterContents;
        this.contentTextFilter = contentTextFilter;
        this.clearCache = true;
    }

    setUserId(userId: number): void {
        this.userId = userId;
        this.clearCache = true;
    }

    setResourceId(resourceId: number): void {
        this.resourceId = resourceId;
    }

    setCustomerIds(...customerIds: number[]): void {
        this.customerIds = customerIds ?? [];
    }

    addCustomerId(customerId: number): void {
        if (this.customerIds.indexOf(customerId) === -1) this.customerIds.push(customerId);
    }

    removeCustomerId(customerId: number): void {
        const index = this.customerIds.indexOf(customerId);
        if (index !== -1) this.customerIds.splice(index, 1);
    }

    setWorkFilters(showNoWork: boolean): void {
        this.showNoWork = showNoWork;
        this.clearCache = true;
    }

    setShowClosed(value: boolean) {
        this.showClosed = value;
        this.clearCache = true;
    }

    setDateFilters(startDate: Date, endDate: Date, destinationState: number) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.destinationState = destinationState;
        this.clearCache = true;
    }

    setView(view: IDataSourceView): void {
        super.setView(view);

        if (this.jobOrderSelectionRequests.length > 0) {
            const requests = this.jobOrderSelectionRequests;
            const deferred = this.pendingSelectJobOrders;

            this.jobOrderSelectionRequests = [];
            this.pendingSelectJobOrders = null;

            if (this.pendingJobOrderNavigationRequest) {
                this.pendingJobOrderNavigationRequest.promise().then(() => {
                    this.selectJobOrders(...requests).then(
                        () => {
                            if (deferred) deferred.resolve();
                        },
                        () => {
                            if (deferred) deferred.reject();
                        }
                    );
                });
            } else {
                this.selectJobOrders(...requests).then(
                    () => {
                        if (deferred) deferred.resolve();
                    },
                    () => {
                        if (deferred) deferred.reject();
                    }
                );
            }
        }

        if (this.jobOrderNavigationRequest >= 0) {
            const deferred = this.pendingJobOrderNavigationRequest;
            const request = this.jobOrderNavigationRequest;

            this.pendingJobOrderNavigationRequest = null;
            this.jobOrderNavigationRequest = -1;

            this.navigateToJobOrder(request).then(
                () => {
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                }
            );
        }
    }

    selectJobOrders(...ids: number[]): Promise<void> {
        if (!this.view) {
            this.jobOrderSelectionRequests = this.jobOrderSelectionRequests.concat(ids);
            this.pendingSelectJobOrders = this.pendingSelectJobOrders || new Deferred<void>();
            return this.pendingSelectJobOrders.promise();
        }

        return this.view.select(
            ...ids.map((id) => {
                return {
                    id: id,
                    isLeaf: true,
                    isGroup: false,
                    title: "",
                };
            })
        );
    }

    navigateToJobOrder(id: number): Promise<void> {
        if (!this.view) {
            this.jobOrderNavigationRequest = id;
            this.pendingJobOrderNavigationRequest = this.pendingJobOrderNavigationRequest || new Deferred<void>();
            return this.pendingJobOrderNavigationRequest.promise();
        }

        return this.getById(null, [id]).then((models: IJobOrderDataSourceModel[]) => {
            if (models.length == 0) return;

            const model = models[0];
            const logicalState = this.getLogicalStates().filter((s) => s.id === model.jobOrderLogicalState)[0];
            const type = this.getJobOrderTypes(model.jobOrderLogicalState).filter(
                (t) => t.id === model.jobOrderType
            )[0];

            this.view.navigateTo(logicalState, type);
            this.view.select(model);
        });
    }

    getTitle(currentModel: IJobOrderDataSourceModel): string {
        if (!this.onlyJobOrders) {
            if (currentModel === null) return ProlifeSdk.TextResources.JobOrder.Status;
            if (currentModel.isLogicalState) return ProlifeSdk.TextResources.JobOrder.Type;
            return currentModel.title;
        }

        return ProlifeSdk.TextResources.Desktop.Orders;
    }

    isGroupedData(currentModel: IJobOrderDataSourceModel, textFilter: string): boolean {
        if (this.onlyJobOrders) return this.groupJobOrdersPerState;

        if (currentModel && currentModel.isType) return false;

        return !!textFilter;
    }

    async getData(
        currentModel: IJobOrderDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IJobOrderDataSourceModel[]> {
        //console.log("In get data");
        let jobOrders: IJobOrderForTaskBoard[];
        let type = -1;
        let logicalState = -1;
        const needToGroup = this.isGroupedData(currentModel, textFilter);

        if (this.onlyJobOrders && this.groupJobOrdersPerState) {
            if (this.clearCache || this.lastTextFilter != textFilter) {
                /*  const splittedTextFilter = (textFilter || "")
                    .split(" ")
                    .filter((s) => typeof s === "string" && s.trim().length > 0); */

                while (this.cacheRefreshInProgress) {
                    //console.log("Waiting for cache refresh...");
                    await Task.Delay(100);
                }

                while (this.clearCache || this.lastTextFilter != textFilter) {
                    this.cacheRefreshInProgress = true;

                    /*let advancedFilters : ITaskBoardAdvancedFilter = {
                        StartDate: this.startDate,
                        EndDate: this.endDate,
                        DestinationStatus : this.destinationState
                    };
                    let splittedContentTextFilter = (this.contentTextFilter || "").split(' ').filter(s => typeof(s) === "string" && s.trim().length > 0);*/

                    this.jobOrdersCache = [];
                    this.clearCache = false;

                    //this.jobOrdersCache = await this.todoListService.GetJobOrdersForLoggedUser(this.userId, this.viewWorker, this.viewResponsible, this.categoryFilter, this.showClosed, !this.showNoWork, logicalState, this.viewAll, this.filterContents, splittedContentTextFilter, advancedFilters, splittedTextFilter, this.jobOrderStateFilter, this.workflowTypeFilter, this.workflowStateFilter, skip, count, true);

                    const param: IGetJobOrdersForUserRequest = {
                        userId: this.userId,
                        resourceId: this.resourceId,
                        customerIds: this.customerIds,
                        viewWorker: this.viewWorker,
                        viewResponsible: this.viewResponsible,
                        categoryIds: this.categoryFilter > 0 ? [this.categoryFilter] : [],
                        showClosed: this.showClosed,
                        hideNoWork: !this.showNoWork,
                        logicalState: logicalState,
                        viewAll: this.viewAll,
                        destinationTaskBoardStatus: this.destinationState,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        textFilter: textFilter,
                        contentTextFilter: this.contentTextFilter,
                        filterWithContent: this.filterContents,
                        stateIds: this.jobOrderStateFilter > 0 ? [this.jobOrderStateFilter] : [],
                        workflowTypes: this.workflowTypeFilter > 0 ? [this.workflowTypeFilter] : [],
                        workflowState: this.workflowStateFilter,
                        skip: skip,
                        count: count,
                    };

                    this.jobOrdersCache = await this.todoListService.GetJobOrdersForUser(param);

                    this.lastTextFilter = textFilter;
                }

                this.cacheRefreshInProgress = false;
            }

            if (currentModel === null) {
                const validStates = this.getJobOrderStates(null)
                    .filter((s) => this.jobOrderStateFilter === -1 || s.id == this.jobOrderStateFilter)
                    .filter((s) => this.jobOrdersCache.filter((j) => j.StateId == s.id).length > 0);

                //console.log("Out get data");
                return validStates.slice(skip, skip + count);
            } else if (currentModel.isJobOrderState) {
                jobOrders = this.jobOrdersCache.filter((j) => j.StateId == currentModel.id).slice(skip, skip + count);

                //console.log("Out get data");
                return this.getJobOrdersModels(jobOrders);
            }
        }

        if (needToGroup) {
            if (currentModel === null || (currentModel.isLogicalState && !currentModel.isGroup)) {
                if (skip != 0) return [];

                //Ritornare i gruppi
                const allGroups = [];

                const states = this.getLogicalStates().filter(
                    (s) =>
                        currentModel === null ||
                        (currentModel.isLogicalState && currentModel.jobOrderLogicalState == s.id)
                );
                for (const state of states) {
                    const types = this.getJobOrderTypes(state.jobOrderLogicalState);
                    for (const type of types) {
                        type.isGroup = true;
                        type.collapsed = false;
                        type.title = type.title + " - " + state.title;
                        allGroups.push(type);
                    }
                }

                return allGroups;
            }

            if (currentModel.isGroup) {
                type = currentModel.jobOrderType;
                logicalState = currentModel.jobOrderLogicalState;
            }
        } else {
            if (!this.onlyJobOrders) {
                if (currentModel === null) {
                    return this.getLogicalStates().slice(skip, skip + count);
                } else if (currentModel.isLogicalState) {
                    return this.getJobOrderTypes(currentModel.jobOrderLogicalState).slice(skip, skip + count);
                } else if (currentModel.isType) {
                    type = currentModel.jobOrderType;
                    logicalState = currentModel.jobOrderLogicalState;
                }
            }
        }

        /*let splittedTextFilter = (textFilter || "").split(' ').filter(s => typeof(s) === "string" && s.trim().length > 0);
        let splittedContentTextFilter = (this.contentTextFilter || "").split(' ').filter(s => typeof(s) === "string" && s.trim().length > 0);
        let advancedFilters : ITaskBoardAdvancedFilter = {
            StartDate: this.startDate,
            EndDate: this.endDate,
            DestinationStatus : this.destinationState
        };
        jobOrders = await this.todoListService.GetJobOrdersForLoggedUser(this.userId, this.viewWorker, this.viewResponsible, type, true, !this.showNoWork, logicalState, this.viewAll, this.filterContents, splittedContentTextFilter, advancedFilters, splittedTextFilter, this.jobOrderStateFilter, this.workflowTypeFilter, this.workflowStateFilter, skip, count, true);*/

        const param: IGetJobOrdersForUserRequest = {
            userId: this.userId,
            resourceId: this.resourceId,
            customerIds: this.customerIds,
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            categoryIds: type > 0 ? [type] : [],
            showClosed: true,
            hideNoWork: !this.showNoWork,
            logicalState: logicalState,
            viewAll: this.viewAll,
            destinationTaskBoardStatus: this.destinationState,
            startDate: this.startDate,
            endDate: this.endDate,
            textFilter: textFilter,
            contentTextFilter: this.contentTextFilter,
            filterWithContent: this.filterContents,
            stateIds: this.jobOrderStateFilter > 0 ? [this.jobOrderStateFilter] : [],
            workflowTypes: this.workflowTypeFilter > 0 ? [this.workflowTypeFilter] : [],
            workflowState: this.workflowStateFilter,
            skip: skip,
            count: count,
        };

        jobOrders = await this.todoListService.GetJobOrdersForUser(param);

        return this.getJobOrdersModels(jobOrders);
    }

    private getJobOrdersModels(jobOrders: IJobOrderForTaskBoard[]): IJobOrderDataSourceModel[] {
        return jobOrders.map<IJobOrderDataSourceModel>((j) => {
            const jobOrder: IJobOrderDataSourceModel = {
                id: j.Id,
                isGroup: false,
                isLeaf: true,
                title: j.Name,
                disabled: this.showClosed
                    ? false
                    : this.jobOrderStateSettingsManager.getJobOrderStateById(j.StateId).LogicalState == 1,
                icon: {
                    icon: j.Icon,
                    background: j.Background,
                    foreground: j.Foreground,
                },
                alerts: {
                    label: ProlifeSdk.TextResources.Todolist.Alerts,
                    icons: JobOrdersUtils.getAlertsForJobOrder(j),
                },
                subTitle: `(${j.StateDescription}) - ${j.CustomerName || "Nessun Cliente"}`,
                jobOrderState: j.StateId,
                jobOrderType: j.Type,
                jobOrderLogicalState: j.LogicalState,
                model: j,
                parent: this.getJobOrderTypeById(j.LogicalState, j.Type),
            };

            //14/07/2020: Si è deciso con Andrea di non visualizzare il badge finchè non capiamo bene cosa vogliamo visualizzare
            /*if (j.AllocationPercentage > 0 || j.HasNotEstimatedElementsAllocated) {
                jobOrder.badge = {
                    text: numeral(j.AllocationPercentage / 100).format("0[.]0[0]%"),
                    cssClass: JobOrdersUtils.getBadgeClassForWorkflow(j)
                };
            }*/

            return jobOrder;
        });
    }

    private getJobOrderType(logicalStateId: number, t: IJobOrderType) {
        return {
            id: t.Id,
            isGroup: false,
            isLeaf: false,
            title: t.Description,
            disabled: false,
            icon: {
                icon: t.Icon,
                background: t.Background,
                foreground: t.Foreground,
            },
            isType: true,
            jobOrderType: t.Id,
            jobOrderLogicalState: logicalStateId,
            model: null,
        };
    }

    private getJobOrderTypeById(logicalStateId: number, typeId: number) {
        const type = this.jobOrderTypesSettingsManager.getJobOrderTypeById(typeId);
        return this.getJobOrderType(logicalStateId, type);
    }

    private getJobOrderTypes(logicalStateId: number): IJobOrderDataSourceModel[] {
        const types = this.jobOrderTypesSettingsManager.getJobOrderTypes();
        return types.map<IJobOrderDataSourceModel>((t) => this.getJobOrderType(logicalStateId, t));
    }

    private getJobOrderState(state: IJobOrderState): IJobOrderDataSourceModel {
        return {
            id: state.IdJobOrderStateId,
            title: state.Description,
            isLeaf: false,
            isGroup: true,
            isJobOrderState: true,
            collapsed: false,
            model: null,
        };
    }

    private getJobOrderStates(logicalStateId: number | null): IJobOrderDataSourceModel[] {
        const states = this.jobOrderStateSettingsManager
            .getJobOrderStates()
            .filter((s) => logicalStateId === null || s.LogicalState == logicalStateId)
            .filter((s) => this.showClosed || s.LogicalState == 0);

        return states.map<IJobOrderDataSourceModel>(this.getJobOrderState, this);
    }

    private getLogicalStates(): IJobOrderDataSourceModel[] {
        return [
            {
                id: 0,
                isGroup: false,
                isLeaf: false,
                title: "Aperti",
                disabled: false,
                icon: {
                    icon: "fa fa-folder",
                    background: "#4b8df8",
                    foreground: "white",
                },
                isLogicalState: true,
                jobOrderLogicalState: 0,
                model: null,
            },
            {
                id: 1,
                isGroup: false,
                isLeaf: false,
                title: "Chiusi",
                disabled: false,
                icon: {
                    icon: "fa fa-folder",
                    background: "#4b8df8",
                    foreground: "white",
                },
                isLogicalState: true,
                jobOrderLogicalState: 1,
                model: null,
            },
        ];
    }

    public getOpenedLogicalState(): INavigationMenuComponentModel {
        return this.getLogicalStates().filter((s) => s.id == 0)[0];
    }

    async getById(currentModel: IJobOrderDataSourceModel, ids: number[]): Promise<IJobOrderDataSourceModel[]> {
        /*if(!this.onlyJobOrders) {
            if(currentModel === null) {
                return this.getLogicalStates().filter(s => ids.indexOf(s.id as number) != -1);
            } else if(currentModel.isLogicalState) {
                return this.getJobOrderTypes(currentModel.jobOrderLogicalState).filter(s => ids.indexOf(s.id as number) != -1);
            }
        }*/

        const request: IGetJobOrdersForUserByIdRequest = {
            ids: ids,
            userId: this.userId,
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            viewAll: this.viewAll,
        };

        const jobOrders: IJobOrderForTaskBoard[] = await this.todoListService.GetJobOrdersForUserById(request);

        return jobOrders.map<IJobOrderDataSourceModel>((j) => {
            return {
                id: j.Id,
                isGroup: false,
                isLeaf: true,
                title: j.Name,
                disabled: false,
                icon: {
                    icon: j.Icon,
                    background: j.Background,
                    foreground: j.Foreground,
                },
                subTitle: j.CustomerName || ProlifeSdk.TextResources.JobOrder.NoCustomer,
                jobOrderState: j.StateId,
                jobOrderType: j.Type,
                jobOrderLogicalState: j.LogicalState,
                model: j,
            };
        });
    }
}
