import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { ICurrenciesSettingsManager } from "../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { IScheduleState, IScheduleService, ICurrencyInfoForSchedule } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IScheduleStateSettingsManager } from "../../../ProlifeSdk/interfaces/schedule/settings/IScheduleStateSettingsManager";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ISchedule } from "../../../ProlifeSdk/interfaces/schedule/ISchedule";
import { IPaymentMode, IPaymentModes } from "../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { PaymentModesDataSource } from "../../../DataSources/PaymentModesDataSource";
import { CompanyIBANDataSource, ICompanyIBANDataSourceModel } from "../../../DataSources/CompanyIBANDataSource";
import { CustomerABIDataSource, CustomerABIDataSourceModel } from "../../../DataSources/CustomerABIDataSource";
import { CustomerCABDataSource, CustomerCABDataSourceModel } from "../../../DataSources/CustomerCABDataSource";
import { IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IValidationService, IValidator, IValidation } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

export class ScheduleViewModel {

	public IdScadenza : ko.Observable<number> = ko.observable();
	public DataScadenza : ko.Observable<Date> = ko.observable();
	public FKFattura : ko.Observable<number> = ko.observable();
	public Nota : ko.Observable<string> = ko.observable();
	public Importo : ko.Observable<number> = ko.observable();
	public PriceInDocumentCurrency : ko.Observable<number> = ko.observable();
	public Stato : ko.Observable<IScheduleState> = ko.observable();
	public Descrizione : ko.Observable<string> = ko.observable();
	public DataPagamento : ko.Observable<Date> = ko.observable();
	public CurrencyExchangeValue: ko.Observable<number>= ko.observable();
	public CurrencySymbol: string;

	public FKTipoPagamento : ko.Observable<number> = ko.observable();
	public TipoPagamento : ko.Observable<string> = ko.observable();
	public Payment: ko.Observable<IPaymentMode> = ko.observable();
	public PaymentBankName: ko.Observable<string> = ko.observable();
	public PaymentIBAN: ko.Observable<string> = ko.observable();
	public PaymentABI: ko.Observable<string> = ko.observable();
	public PaymentCAB: ko.Observable<string> = ko.observable();

	public PaymentModesDataSource: PaymentModesDataSource = new PaymentModesDataSource();
    public CompanyIBANDataSource: CompanyIBANDataSource = new CompanyIBANDataSource();
    public CustomerABIDataSource: CustomerABIDataSource = new CustomerABIDataSource();
    public CustomerCABDataSource: CustomerCABDataSource = new CustomerCABDataSource();

	public ShowCurrencyExchangeValueField: ko.Observable<boolean> = ko.observable();

	public scaduta :  ko.Computed<boolean>;
	public testoimporto : ko.Computed<string>;
	public noteExpandable : ko.Computed<boolean>;
	public viewDataPagamento : ko.Computed<boolean>;
	public expanded :  ko.Observable<boolean> = ko.observable(false);
	public noteExpanded :  ko.Observable<boolean> = ko.observable(false);
	public noteEditing :  ko.Observable<boolean> = ko.observable(false);

	public scheduleStates : IScheduleState[];

	@LazyImport(nameof<IInfoToastService>())
	private infoToast : IInfoToastService;
	@LazyImport(nameof<IScheduleService>())
	private scheduleService : IScheduleService;
	@LazyImport(nameof<IDialogsService>())
	private dialogService : IDialogsService;
	@LazyImport(nameof<IProLifeSdkService>())
	private sdkService: IProLifeSdkService;
	@LazyImport(nameof<IValidationService>())
	private validationsService: IValidationService;
	@LazyImportSettingManager(ProlifeSdk.ScheduleState)
	private scheduleStateSettingsManager : IScheduleStateSettingsManager;
	@LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
	private currenciesSettingsManager: ICurrenciesSettingsManager;
	@LazyImportSettingManager(ProlifeSdk.PaymentMode)
	private paymentModesManager: IPaymentModes;
	
	private updatingPrice = false;
	private updatingExchangeValue = false;
	private loading = false;

	private validator: IValidator<ScheduleViewModel>;

	constructor(private schedule : ISchedule, private currencyInfo: ICurrencyInfoForSchedule, private invoiceCustomer: ICustomer)
    {	
		this.validator = this.validationsService.createValidator<ScheduleViewModel>()
			.isNotNullOrUndefinedOrWhiteSpace((s) => s.PaymentIBAN(), TextResources.Schedule.PaymentIBANRequired, (s) => s.Payment() && s.Payment().AssociaBanca === 2)
			.isNotNullOrUndefinedOrWhiteSpace((s) => s.PaymentABI(), TextResources.Schedule.PaymentABIRequired, (s) => s.Payment() && s.Payment().AssociaBanca === 1)
			.isNotNullOrUndefinedOrWhiteSpace((s) => s.PaymentCAB(), TextResources.Schedule.PaymentCABRequired, (s) => s.Payment() && s.Payment().AssociaBanca === 1)
			.isNotNullUndefinedOrLessOrEqualZero((s) => s.FKTipoPagamento(), TextResources.Schedule.PaymentModeRequired)

		this.CustomerABIDataSource.setCustomer(this.invoiceCustomer);
		this.CustomerCABDataSource.setCustomer(this.invoiceCustomer);

		this.scheduleStates = this.scheduleStateSettingsManager.getScheduleStates();
		this.CurrencySymbol = this.currencyInfo.CurrencySymbol;

		const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
		this.ShowCurrencyExchangeValueField(defaultCurrency.Id !== this.currencyInfo.CurrencyId);

		this.Importo.subscribe((value: number) => {
			if (this.updatingPrice || this.loading)
				return;

			this.updatingPrice = true;

			const priceInCurrency = this.PriceInDocumentCurrency();
			const exchangeValue = !priceInCurrency ? this.currencyInfo.CurrencyExchangeValue : value / priceInCurrency;
			this.CurrencyExchangeValue(exchangeValue);
			
			this.updatingPrice = false;
		});

		this.CurrencyExchangeValue.subscribe((value: number) => {
			if (this.updatingExchangeValue || this.loading)
				return;

			this.updatingExchangeValue = true;

			const priceInCurrency = this.PriceInDocumentCurrency();
			const price = priceInCurrency * value;
			this.Importo(this.sdkService.Utilities.Numbers.Round(price, 2));
			
			this.updatingExchangeValue = false;
		});

		this.PriceInDocumentCurrency.subscribe((value: number) => {
			if (this.loading)
				return;
				
			this.Importo(this.sdkService.Utilities.Numbers.Round(value * this.CurrencyExchangeValue(), 2));
		});

		this.PaymentIBAN.subscribe((value: string) => {
			if (this.loading)
				return;

			if (!value)
				this.PaymentBankName(null);
		});
		
		this.PaymentABI.subscribe((value: string) => {
			if (this.loading)
				return;

			if (!value)
				this.PaymentBankName(null);
		});
		
		this.PaymentCAB.subscribe((value: string) => {
			if (this.loading)
				return;

			if (!value)
				this.PaymentBankName(null);
		});

		this.load(schedule);

		this.scaduta = ko.computed(() => { return this.Stato().StatoLogico == 0 && this.DataScadenza() && moment(new Date()).diff(moment(this.DataScadenza()), "days", true) > 0 });
		this.testoimporto = ko.computed(() => {

            let statePart = "";
			if (this.Stato().StatoLogico >= 1)
			{
				if (!this.DataPagamento())
					this.DataPagamento(new Date());
                statePart = String.format(ProlifeSdk.TextResources.Schedule.ScheduleDescriptionPaidDay, moment(this.DataPagamento()).format('L'));
			}
			else if(this.scaduta()) {
                statePart = ProlifeSdk.TextResources.Schedule.ScheduleDescriptionExpired;
            }

            return String.format(ProlifeSdk.TextResources.Schedule.ScheduleDescription, this.Stato().Descrizione, numeral(this.PriceInDocumentCurrency()).format("0,0.00[0]"), this.currencyInfo.CurrencySymbol, statePart);
		});
		this.noteExpandable =  ko.computed(() => {return (this.Nota() && (this.Nota().length > 60 || this.Nota().indexOf('\n') > 0) &&  !this.noteEditing());});
		this.viewDataPagamento = ko.computed(() => {return this.Stato().StatoLogico >= 1});
	}

    public ShotNotes()
    {
        this.dialogService.Alert(this.Nota(), ProlifeSdk.TextResources.Schedule.Notes, () => {});
    }

    public ResetData()
    {
        this.load(this.schedule);
    }

	public getValidation(): IValidation[] {
		return this.validator.validate(this);
	}

	load(schedule : ISchedule) : void
    {
		this.loading = true;

		this.IdScadenza(schedule.IdScadenza);
		this.DataScadenza(schedule.DataScadenza ? moment(schedule.DataScadenza).toDate() : null);
		this.FKFattura(schedule.FKFattura);
		this.Nota(schedule.Nota);
		this.PriceInDocumentCurrency(schedule.PriceInDocumentCurrency);
		this.Importo(schedule.Importo);
		this.Stato(schedule.Stato ? this.scheduleStateSettingsManager.getScheduleStateById(schedule.Stato) : this.scheduleStateSettingsManager.getScheduleStates()[0] );
		this.Descrizione(schedule.Descrizione);
		this.DataPagamento(schedule.DataPagamento ? moment(schedule.DataPagamento).toDate() : null);
		this.CurrencyExchangeValue(schedule.CurrencyExchangeValue);

		this.FKTipoPagamento(schedule.FKTipoPagamento || null);
		this.TipoPagamento(schedule.TipoPagamento);
		this.PaymentBankName(schedule.PaymentBankName);
		this.PaymentIBAN(schedule.PaymentIBAN);
		this.PaymentABI(schedule.PaymentABI);
		this.PaymentCAB(schedule.PaymentCAB);

		if (schedule.FKTipoPagamento) {
			this.Payment(this.paymentModesManager.getPaymentMode(schedule.FKTipoPagamento));
		}

		this.loading = false;
	}

    someIsChanged() : boolean
    {
        const oldDataScadenza = this.schedule.DataScadenza ? JSON.stringify(moment(this.schedule.DataScadenza).toDate()) : null;
        const newDataScadenza = this.DataScadenza() ? JSON.stringify(moment(this.DataScadenza()).toDate()) : null;

        const oldDataPagamento = this.schedule.DataPagamento ? JSON.stringify(moment(this.schedule.DataPagamento).toDate()) : null;
        const newDataPagamento = this.DataPagamento() ? JSON.stringify(moment(this.DataPagamento()).toDate()) : null;

        let isChanged = oldDataScadenza != newDataScadenza;
        isChanged = isChanged || (oldDataPagamento != newDataPagamento);
        isChanged = isChanged || ((this.Nota() || "") != (this.schedule.Nota || ""));
        isChanged = isChanged || this.Importo() != this.schedule.Importo;
		isChanged = isChanged || this.PriceInDocumentCurrency() != this.schedule.PriceInDocumentCurrency;
        isChanged = isChanged || ((this.Descrizione() || "") != (this.schedule.Descrizione || ""));
        isChanged = isChanged || this.Stato().IdScadenzaStato != this.schedule.Stato;
        isChanged = isChanged || this.CurrencyExchangeValue() != this.schedule.CurrencyExchangeValue;
		
		isChanged = isChanged || this.FKTipoPagamento() != this.schedule.FKTipoPagamento;
        isChanged = isChanged || (this.TipoPagamento() || "") != (this.schedule.TipoPagamento || "");
        isChanged = isChanged || (this.PaymentBankName() || "") != (this.schedule.PaymentBankName || "");
        isChanged = isChanged || (this.PaymentIBAN() || "") != (this.schedule.PaymentIBAN || "");
        isChanged = isChanged || (this.PaymentABI() || "") != (this.schedule.PaymentABI || "");
        isChanged = isChanged || (this.PaymentCAB() || "") != (this.schedule.PaymentCAB || "");

        return isChanged;
    }

	setValue(schedule : ScheduleViewModel)
	{
		this.schedule = schedule.getData();
	}

	setState(state : IScheduleState) : void
    {
		this.Stato(state);
	}

	changeResizefullState() : void
    {
		this.expanded(!this.expanded());
	}

	noteCollapses() : void
    {
		this.noteExpanded(!this.noteExpanded());
	}

	startEditNote() : void
    {
		//$('textarea').autosize();
		this.noteEditing(true);
	}

	reset() : void
    {
		this.noteEditing(false);
		this.load(this.schedule);
	}

	save() : void
    {
		

		this.scheduleService.SaveSchedule(this.getData())
			.then(this.onSaved.bind(this))
			.catch(this.onFailed.bind(this));
	}

	onFailed(jqXHR: JQueryXHR) : void
    {
		this.infoToast.Error(jqXHR.statusText);
	}

	onSaved()
    {
		this.schedule = this.getData();
		this.noteEditing(false);
	}

	clone() : ScheduleViewModel
    {
		return new ScheduleViewModel(<ISchedule> $.extend({}, this.AdjustDates(this.schedule)), this.currencyInfo, this.invoiceCustomer);
	}

    AdjustDates(schedule : ISchedule) : ISchedule
    {
        const result : ISchedule = <ISchedule> $.extend({}, schedule);
        result.DataScadenza = moment(result.DataScadenza) != null ? moment(result.DataScadenza).toDate() : null;
        result.DataPagamento =  moment(result.DataPagamento) != null ? moment(result.DataPagamento).toDate() : null;
		return result;
	}

	getData() : ISchedule
    {
		const schedule : ISchedule = <ISchedule> $.extend({}, this.schedule);

		const exchangeValue = this.CurrencyExchangeValue();

		schedule.DataScadenza = this.DataScadenza();
		schedule.PriceInDocumentCurrency = this.PriceInDocumentCurrency();
		schedule.Importo = this.Importo();
		schedule.Nota = this.Nota();
		schedule.FKFattura = this.FKFattura();
		schedule.Descrizione = this.Descrizione();
		schedule.Stato = this.Stato().IdScadenzaStato;
		schedule.DataPagamento = this.DataPagamento();
		schedule.CurrencyExchangeValue = exchangeValue;
		schedule.CurrencyId = this.currencyInfo.CurrencyId;

		schedule.FKTipoPagamento = this.FKTipoPagamento();
		schedule.TipoPagamento = this.TipoPagamento();
		schedule.PaymentBankName = this.PaymentBankName();
		schedule.PaymentIBAN = this.PaymentIBAN();
		schedule.PaymentABI = this.PaymentABI();
		schedule.PaymentCAB = this.PaymentCAB();

		return schedule;
	}

	onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
		if (sender == this.PaymentModesDataSource) {
			const previousPaymentMode = this.Payment();
			const selectedPaymentMode = (model?.model) as IPaymentMode;
			
			this.Payment(selectedPaymentMode);
			
			const previousPaymentModeAssociaBanca = previousPaymentMode?.AssociaBanca;
			const selectedPaymentModeAssociaBanca = selectedPaymentMode?.AssociaBanca;

			if (selectedPaymentModeAssociaBanca !== previousPaymentModeAssociaBanca) {
				this.PaymentABI(null);
				this.PaymentCAB(null);
				this.PaymentIBAN(null);
				this.PaymentBankName(null);
			}

			if (selectedPaymentMode)
				this.TipoPagamento(selectedPaymentMode.Descrizione);

        } else if (sender == this.CompanyIBANDataSource) {
            const ibanModel = (model as ICompanyIBANDataSourceModel);
            this.PaymentBankName(ibanModel?.bank.Name);
        } else if (sender == this.CustomerABIDataSource) {
            const abiModel = (model as CustomerABIDataSourceModel);
            this.PaymentBankName(abiModel?.model.Name);
        } else if (sender == this.CustomerCABDataSource) {
            const cabModel = (model as CustomerCABDataSourceModel);
            this.PaymentBankName(cabModel?.model.Name);
        }
	}

}