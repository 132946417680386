import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { Tag } from "../../../ProlifeSdk/prolifesdk/blog/Tag";
import { MeetingInput } from "../EventsDetails/MeetingInput";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";
import { Deferred } from "../../../Core/Deferred";

export class MeetingBlogEvent extends BlogEvent implements IView {
    public viewModel: any;

    public Participants: ko.ObservableArray<Tag> = ko.observableArray();
    public Duration: ko.Observable<number> = ko.observable();

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);
        this.iconClass = "fa-users";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.MeetingBlogTitle;
        this.templateName = "meetingEvent";
        this.Clear();
    }

    public GetMailSubject(): string {
        return String.format(
            ProlifeSdk.TextResources.Blog.MeetingBlogSubject,
            moment(this.ReferenceDate()).format("L")
        );
    }

    public GetMailBody(): Promise<string> {
        const def = new Deferred<string>();
        super
            .GetMailBody()
            .then((b: string) => {
                let body = b;
                body += String.format(
                    ProlifeSdk.TextResources.Blog.MeetingBlogBody,
                    numeral(this.Duration()).format("0.00")
                );
                body += this.CreateMailBodyPartForList(this.Participants(), ProlifeSdk.TextResources.Blog.Participants);
                def.resolve(body);
            })
            .catch(() => def.reject());
        return def.promise();
    }

    Clear() {
        super.Clear();
        this.Duration(0);
        this.Participants([]);
    }

    getEditViewModel(): IEventBaseInput {
        return new MeetingInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags() {
        super.loadTags();
        this.Participants([]);

        this.Tags().forEach((item) => {
            if (item.TagName() == ProlifeSdk.TagMeeting_Participant) {
                this.Participants.push(item);
            }
            if (item.TagName() == ProlifeSdk.TagMeeting_Duration) {
                this.Duration(parseFloat(item.Value()));
            }
        });
    }
}
