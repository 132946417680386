import * as ko from "knockout";
import { DocumentForReports } from "./DocumentForReports";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IInvoicesService } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";

export class DocumentsGroup
{
    documents : ko.ObservableArray<any> = ko.observableArray([]);
    editMode : ko.Observable<boolean> = ko.observable(false);
    selection : ko.Observable<boolean> = ko.observable(false);

    totalPrice : ko.Computed<number>;
    totalTax : ko.Computed<number>;
    totalInvoicePrice : ko.Computed<number>;

    @LazyImport(nameof<IInvoicesService>())
    invoicesService : IInvoicesService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    CanLockUnlock : ko.Observable<boolean> = ko.observable(false);

    constructor()
    {
        var self = this;

        this.totalPrice = ko.computed(() => {
            var total : number = 0;
            self.documents().forEach(d => {
                total += d.formatPrice(d.document.TotaleImponibile);
            });
            return total;
        });

        this.totalTax = ko.computed(() => {
            var total : number = 0;
            self.documents().forEach(d => {
                total += d.formatPrice(d.document.IVA);
            });
            return total;
        });

        this.totalInvoicePrice = ko.computed(() => {
            var total : number = 0;
            self.documents().forEach(d => {
                total += d.formatPrice(d.document.TotaleFattura);
            });
            return total;
        });

        this.CanLockUnlock(this.authorizationService.isAuthorized("Documents_LockUnlock"));
    }

    changeSelectionForAll()
    {
        var self = this;
        self.selection(!self.selection());
        this.documents().forEach(d => {
            d.selected(self.selection());
        });
    }

    toggleEditMode()
    {
        this.editMode(!this.editMode());
        this.selection(true);
        this.changeSelectionForAll();
    }

    changeDocSelection(document : DocumentForReports)
    {
        document.changeSelection();
        var allSelected = true;

        this.documents().forEach(d => {
            allSelected = allSelected && d.selected();
        });

        this.selection(allSelected && this.documents().length>0);
    }

    freezeSelected()
    {
        this.setFreezeStatusForSelected(true);
    }

    unfreezeSelected()
    {
        this.setFreezeStatusForSelected(false);
    }

    setFreezeStatusForSelected(freeze : boolean)
    {
        var ids = [];
        this.documents().forEach(d => {
            if(d.selected())
            {
                ids.push(d.document.IdFattura);
                d.freezed(freeze);
            }
        });

        this.invoicesService.setFreezeStatusByDocumentsIds(freeze, ids);
    }
}
