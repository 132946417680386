import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 18:58
 * To change this template use File | Settings | File Templates.
 */

import { IQuestionnaireIdentifier } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQuestionnaireIdentifier";

export class QuestionnaireIdentifierViewModel {
    public Id: number;
    public Title: ko.Observable<string> = ko.observable();
    public Version: ko.Observable<number> = ko.observable();
    public Description: ko.Observable<string> = ko.observable();
    public Category: ko.Observable<string> = ko.observable();

    constructor(questionnaire: IQuestionnaireIdentifier) {
        this.Id = questionnaire.Id;
        this.Title(questionnaire.Title);
        this.Version(questionnaire.Version);
        this.Description(questionnaire.Description);
        this.Category(questionnaire.Category);
    }
}