import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { DDTPortiEditingViewModel } from "./ui/DDTPortiEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDDTPortiSettingsManager, IDDTPorto, IDDTPortiSettingsManagerObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IDDTPortiSettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class DDTPortiSettingsManager  implements IDDTPortiSettingsManager
{
    private ajaxService : IAjaxService;
    private porti : IDDTPorto[];
    private observers : IDDTPortiSettingsManagerObserver[] = [];

    constructor(private serviceLocator : IServiceLocator)
    {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer : IDDTPortiSettingsManagerObserver) : void
    {
        this.observers.push(observer);
    }

    removeObserver(observer : IDDTPortiSettingsManagerObserver) : void
    {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IDDTPorto[]>
    {
        return this.ajaxService.Get<IDDTPorto[]>("Invoices-api", "DDTPorti", {})
            .then((porti: IDDTPorto[]) => this.porti = porti);
    }

    getName(): string {
        return ProlifeSdk.DDTPorti;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.PortTypes;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "setting",
            templateUrl: "settings/templates",
            viewModel: new DDTPortiEditingViewModel(this.serviceLocator, this)
        };
    }

    getDDTPorti() : IDDTPorto[] {
        return this.porti;
    }

    getDDTPortoIdByName(name : string) : number {
        var porti = this.porti.filter((p : IDDTPorto) => p.Description == name);
        if(porti.length == 0) return 0;
        return porti[0].Id;
    }

    createOrUpdate(porto : IDDTPorto) : Promise<IDDTPorto> {
        if(porto.Id > 0) {
            return this.ajaxService.Put<IDDTPorto>("Invoices-api", "DDTPorti", { methodData: porto})
                .then(this.onDDTPortoChanged.bind(this));
        }

        return this.ajaxService.Post<IDDTPorto>("Invoices-api", "DDTPorti", { methodData: porto})
            .then(this.onDDTPortoAdded.bind(this));
    }

    remove(portoId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Invoices-api", "DDTPorti/" + portoId, {})
            .then(this.onDDTPortoDeleted.bind(this, portoId));
    }

    private onDDTPortoChanged(porto : IDDTPorto) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTPortoChanged(porto));
    }

    private onDDTPortoAdded(porto : IDDTPorto) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTPortoAdded(porto));
    }

    private onDDTPortoDeleted(portoId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTPortoDeleted(portoId));
    }
}