import * as ko from "knockout";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IBarCodeScannerService extends IService {
    NotifyTaskStart(request: ITaskRequest) : Promise<boolean>;
    NotifyTaskEnd(request: ITaskRequest) : Promise<boolean>;
    NotifyBreakStart(request: ITaskRequest) : Promise<boolean>;
    NotifyBreakEnd(request: ITaskRequest) : Promise<boolean>;
    NotifyResourceRegistered(request: IScannerRequest) : Promise<boolean>;
    NotifyResourceUnregistered(request: IScannerRequest) : Promise<boolean>;
    GetWorkedActivities(request: IGetWorkedActivitiesRequest) : Promise<IBarCodeScannerRegistry[]>;
}

export interface IBarCodeScannerBaseRequest {
    AppGuid: string;
    ResourceId: number;
    ActionDateTime: Date;
}

export interface ITaskRequest extends IBarCodeScannerBaseRequest {
    TaskId: number;
}

export interface IScannerRequest extends IBarCodeScannerBaseRequest {
    BarCodeScannerId: number;
}

export interface IGetWorkedActivitiesRequest {
    AppGuid: string;
}

export interface IBarCodeScannerRegistry
{
    Id : number;
    AppGuid : string;
    ResourceId : number;
    TargetId: number;
    ActionType: string;
    ActionDateTime: Date;
    RowState: number;
}

@Service(nameof<IBarCodeScannerService>())
export class BarCodeScannerService implements IBarCodeScannerService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    NotifyTaskStart(request: ITaskRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyTaskStart", {
            methodData: request,
            background: true
        });
        return response;
    }

    NotifyTaskEnd(request: ITaskRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyTaskEnd", {
            methodData: request,
            background: true
        });
        return response;
    }

    NotifyBreakStart(request: ITaskRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyBreakStart", {
            methodData: request,
            background: true
        });
        return response;
    }

    NotifyBreakEnd(request: ITaskRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyBreakEnd", {
            methodData: request,
            background: true
        });
        return response;
    }

    NotifyResourceRegistered(request: IScannerRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyResourceRegistered", {
            methodData: request,
            background: true
        });
        return response;
    }

    NotifyResourceUnregistered(request: IScannerRequest): Promise<boolean> {
        let response = this.ajaxService.Post<boolean>("Workedhours-api/BarCodeScanner", "NotifyResourceUnregistered", {
            methodData: request,
            background: true
        });
        return response;
    }

    GetWorkedActivities(request: IGetWorkedActivitiesRequest): Promise<IBarCodeScannerRegistry[]> {
        let response = this.ajaxService.Post<IBarCodeScannerRegistry[]>("Workedhours-api/BarCodeScanner", "GetWorkedActivities", {
            methodData: request,
            background: true
        });
        return response;
    }

    InitializeService(): void {
        
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return serviceLocator.findService(nameof<IBarCodeScannerService>());
}