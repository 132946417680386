import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as numeral from "numeral";
import { LazyImport, LazyImportSettingManager } from "../Core/DependencyInjection";
import { JobOrdersUtils } from "../JobOrder/jobOrder/ui/utils/JobOrdersUtils";
import { IJobOrdersMenuAdvancedFilters } from "../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { TasksUtils } from "../Todolist/Todolist/ui/utils/TasksUtils";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel, IDataSourceView } from "./IDataSource";
import {
    IWorkflowState,
    IWorkflowStatesSettingsManager,
} from "../ProlifeSdk/interfaces/todolist/IWorkflowStatesSettingsManager";
import { WorkflowsMenuSearchModes } from "../Todolist/Todolist/enums/WorkflowsMenuSearchModes";
import {
    IGetJobOrdersForUserByIdRequest,
    IGetJobOrdersForUserRequest,
    IGetJobOrderWorkflowsForUserByIdsRequest,
    IGetJobOrderWorkflowsForUserRequest,
    IGetTasksForUserRequest,
    IJobOrderForTaskBoard,
    ITaskForTaskBoard,
    ITodoListService,
    IWorkflowForTaskBoard,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { INavigationMenuComponentModel } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { AdvancedFiltersManager } from "../Allocations/Allocations/ui/AdvancedFiltersManager";

type JobOrdersWorkflowsAndTasksDataSourceModel =
    | IJobOrderForTaskBoardDataSourceModel
    | IWorkflowsForTaskBoardGroupDataSourceModel
    | IWorkflowForTaskBoardDataSourceModel
    | ITasksForTaskBoardGroupsDataSourceModel
    | ITaskForTaskBoardDataSourceModel;

export interface IJobOrderForTaskBoardDataSourceModel
    extends INavigationMenuComponentModel<number, IJobOrderForTaskBoard> {
    isJobOrder: true;
}

export interface IWorkflowsForTaskBoardGroupDataSourceModel
    extends INavigationMenuComponentModel<number, IWorkflowState> {
    isGroup: true;
    isLeaf: false;
    isWorkflowGroup: true;
    workflowsCount: number;
}

export interface IWorkflowForTaskBoardDataSourceModel
    extends INavigationMenuComponentModel<number, IWorkflowForTaskBoard> {
    isWorkflow: true;
}

export interface ITasksForTaskBoardGroupsDataSourceModel extends INavigationMenuComponentModel<number, IWorkflowState> {
    isGroup: true;
    isLeaf: false;
    isTasksGroup: true;
}

export interface ITaskForTaskBoardDataSourceModel extends INavigationMenuComponentModel<number, ITaskForTaskBoard> {
    isTask: true;
}

export class JobOrdersWorkflowsAndTasksDataSource extends BaseDataSource<JobOrdersWorkflowsAndTasksDataSourceModel> {
    public AdvancedFilterIsActive: ko.Observable<boolean> = ko.observable(false);

    private workflowsCache: IWorkflowForTaskBoard[] = [];
    private tasksCache: ITaskForTaskBoard[] = [];
    private lastTextFilter: string = null;
    private lastJobOrder: IDataSourceModel = null;
    private workflowsCacheClearRequested: boolean;
    private tasksCacheClearRequested: boolean;

    private workflowsSearchMode: WorkflowsMenuSearchModes;
    private viewWorker = true;
    private viewResponsible = true;
    private viewAll = false;
    private viewAllActivities = false;
    private canViewAll = false;
    private showClosedJobOrders = false;
    private showClosedTasks = false;
    private showNoWork = true;
    private workflowStateFilter = -1;
    private userId: number;
    private resourceId: number;

    private advancedFiltersManager: AdvancedFiltersManager;
    private advancedFilters: IJobOrdersMenuAdvancedFilters;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.WorkflowStates)
    private workflowStatesManager: IWorkflowStatesSettingsManager;

    constructor() {
        super();
        this.canViewAll = this.authorizationsService.isAuthorized("TaskBoard_CanViewAll");
        this.viewAll = this.viewAll && this.canViewAll;
        this.workflowsSearchMode = WorkflowsMenuSearchModes.SearchWorkflows;
        this.requestAllCacheClearing();

        this.advancedFiltersManager = new AdvancedFiltersManager(
            ProlifeSdk.BlogApplicationCode,
            ProlifeSdk.AdvancedFiltersConfigAllocationType
        );
        this.advancedFiltersManager.setShowActivitiesMustBeAllocatedFlag(false);
    }

    public async initializeAdvancedFilters(): Promise<void> {
        await this.advancedFiltersManager.initialize();
        this.setAdvancedFiltersAndRefresh(this.advancedFiltersManager.getCurrentFilters());
    }

    private setAdvancedFiltersAndRefresh(filters: IJobOrdersMenuAdvancedFilters): void {
        this.advancedFilters = filters;
        this.AdvancedFilterIsActive(
            filters.SelectedJobOrdersStates.length > 0 ||
                filters.SelectedJobOrdersTypes.length > 0 ||
                filters.SelectedOpUnits.length > 0 ||
                filters.SelectedWorkflowTypes.length > 0 ||
                filters.OnlyActivitiesWithRequiredAllocation
        );
        this.requestAllCacheClearing();
        this.view.refresh();
    }

    private requestAllCacheClearing() {
        this.workflowsCacheClearRequested = true;
        this.tasksCacheClearRequested = true;
    }

    public setWorkflowsSearchMode(searchMode: WorkflowsMenuSearchModes): void {
        this.workflowsSearchMode = searchMode;
        this.requestAllCacheClearing();
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setViewFilters(
        viewWorker: boolean,
        viewResponsible: boolean,
        viewAll: boolean,
        overrideTasksViewAll = true
    ) {
        this.viewWorker = viewWorker;
        this.viewResponsible = viewResponsible;
        this.viewAll = viewAll && this.canViewAll;

        if (overrideTasksViewAll) this.viewAllActivities = this.viewAll;

        this.requestAllCacheClearing();
    }

    public setViewAllTasks(viewAllActivities: boolean) {
        this.viewAllActivities = viewAllActivities && this.canViewAll;

        this.requestAllCacheClearing();
    }

    public setJobOrdersShowClosed(value: boolean) {
        this.showClosedJobOrders = value;
        this.requestAllCacheClearing();
    }

    public setTasksShowClosed(value: boolean) {
        this.showClosedTasks = value;
        this.requestAllCacheClearing();
    }

    public setWorkFilters(showNoWork: boolean): void {
        this.showNoWork = showNoWork;
        this.requestAllCacheClearing();
    }

    public setUserId(userId: number): void {
        this.userId = userId;
        this.requestAllCacheClearing();
    }

    public setResourceId(resourceId: number) {
        this.resourceId = resourceId;
        this.requestAllCacheClearing();
    }

    public getTitle(currentModel: IDataSourceModel): string {
        if (!currentModel) return ProlifeSdk.TextResources.Desktop.Orders;

        const jobOrderModel = currentModel as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrderModel.isJobOrder) return jobOrderModel.title;

        return currentModel.title;
    }

    public async getData(
        currentModel: JobOrdersWorkflowsAndTasksDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<JobOrdersWorkflowsAndTasksDataSourceModel[]> {
        if (!currentModel) return this.getJobOrders(textFilter, skip, count);

        const jobOrderModel = currentModel as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrderModel.isJobOrder) {
            if (skip != 0) return [];

            if (this.lastTextFilter != textFilter || !this.areEqual(this.lastJobOrder, currentModel)) {
                this.workflowsCache = [];
                this.tasksCache = [];
                this.requestAllCacheClearing();

                this.lastTextFilter = textFilter;
                this.lastJobOrder = currentModel;
            }

            if (this.workflowsSearchMode === WorkflowsMenuSearchModes.SearchWorkflows || !textFilter)
                return this.getWorkflowsGroups(jobOrderModel.id, textFilter);
            else return this.getTasksGroups(jobOrderModel.id, textFilter);
        }

        const workflowsGroupModel = currentModel as IWorkflowsForTaskBoardGroupDataSourceModel;
        if (workflowsGroupModel.isWorkflowGroup) {
            if (skip != 0) return [];

            return this.getWorkflowsFromCache(workflowsGroupModel.id);
        }

        const tasksGroup = currentModel as ITasksForTaskBoardGroupsDataSourceModel;
        if (tasksGroup.isTasksGroup) {
            if (skip != 0) return [];

            return this.getTasksFromCache(tasksGroup.id);
        }

        const workflowModel = currentModel as IWorkflowForTaskBoardDataSourceModel;
        return this.getTasks(workflowModel, textFilter, skip, count);
    }

    public async getById(
        currentModel: JobOrdersWorkflowsAndTasksDataSourceModel,
        ids: number[]
    ): Promise<JobOrdersWorkflowsAndTasksDataSourceModel[]> {
        if (!currentModel) return this.getJobOrdersByIds(ids);

        const jobOrderModel = currentModel as IJobOrderForTaskBoardDataSourceModel;
        if (jobOrderModel.isJobOrder) return this.getWorkflowsByIds(ids);

        return this.getTasksByIds(ids);
    }

    public async editAdvancedFilters(): Promise<void> {
        const newFilters = await this.advancedFiltersManager.editFilters();
        this.setAdvancedFiltersAndRefresh(newFilters);
    }

    public isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        const jobOrderModel = currentModel as IJobOrderForTaskBoardDataSourceModel;
        return jobOrderModel && !!jobOrderModel.isJobOrder;
    }

    public areEqual(a: IDataSourceModel, b: IDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private async getJobOrders(
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IJobOrderForTaskBoardDataSourceModel[]> {
        const logicalState = -1;

        const request: IGetJobOrdersForUserRequest = {
            userId: !this.resourceId ? this.userId ?? this.userInfo.getIdUser() : null,
            resourceId: this.resourceId,
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            showClosed: this.showClosedJobOrders,
            hideNoWork: !this.showNoWork,
            logicalState: logicalState,
            viewAll: this.viewAll,
            textFilter: textFilter,
            categoryIds: this.advancedFilters?.SelectedJobOrdersTypes.map((f) => f.FilterObjectId) ?? [],
            stateIds: this.advancedFilters?.SelectedJobOrdersStates.map((f) => f.FilterObjectId) ?? [],
            workflowTypes: this.advancedFilters?.SelectedWorkflowTypes.map((f) => f.FilterObjectId) ?? [],
            workflowState: this.workflowStateFilter,
            filterWithContent: false,
            skip: skip,
            count: count,
        };

        const jobOrders = await this.todoListService.GetJobOrdersForUser(request);

        return jobOrders.map(this.createModelForJobOrder, this);
    }

    private async getJobOrdersByIds(ids: number[]): Promise<IJobOrderForTaskBoardDataSourceModel[]> {
        const request: IGetJobOrdersForUserByIdRequest = {
            ids: ids ?? [],
            userId: this.userId ?? this.userInfo.getIdUser(),
            viewWorker: true,
            viewResponsible: true,
            viewAll: true,
        };
        const jobOrders = await this.todoListService.GetJobOrdersForUserById(request);
        return jobOrders.map(this.createModelForJobOrder, this);
    }

    private createModelForJobOrder(jobOrder: IJobOrderForTaskBoard): IJobOrderForTaskBoardDataSourceModel {
        return {
            id: jobOrder.Id,
            isGroup: false,
            isLeaf: false,
            title: jobOrder.Name,
            icon: {
                icon: jobOrder.Icon,
                background: jobOrder.Background,
                foreground: jobOrder.Foreground,
            },
            alerts: {
                label: TextResources.Todolist.Alerts,
                icons: JobOrdersUtils.getAlertsForJobOrder(jobOrder),
            },
            subTitle: `(${jobOrder.StateDescription}) - ${jobOrder.CustomerName || "Nessun Cliente"}`,
            model: jobOrder,
            isJobOrder: true,
            canSelectEvenIfNotLeaf: true,
        };
    }

    private async getWorkflowsGroups(
        jobOrderId: number,
        textFilter: string
    ): Promise<IWorkflowsForTaskBoardGroupDataSourceModel[]> {
        const workflowStates = this.workflowStatesManager.getStates(false);

        while (this.workflowsCacheClearRequested) {
            this.workflowsCacheClearRequested = false;
            const request: IGetJobOrderWorkflowsForUserRequest = {
                userId: this.userId ?? this.userInfo.getIdUser(),
                jobOrderIds: [jobOrderId],
                viewWorker: this.viewWorker,
                viewResponsible: this.viewResponsible,
                categoryIds: this.advancedFilters?.SelectedWorkflowTypes.map((f) => f.FilterObjectId) ?? [],
                viewAll: this.viewAllActivities,
                hideNoWork: !this.showNoWork,
                destinationTaskBoardStatus: null,
                startDate: null,
                endDate: null,
                textFilter: textFilter,
                filterWithContent: false,
            };
            const workflows = await this.todoListService.GetJobOrderWorkflowsForUser(request);
            this.workflowsCache = workflows;

            if (this.workflowsCacheClearRequested) this.workflowsCache = [];
        }

        const groups = workflowStates
            .map((s) =>
                this.createModelForWorkflowState(s, this.workflowsCache.filter((w) => w.StateId === s.Id).length)
            )
            .filter((g) => g.workflowsCount > 0);
        const workStatusUsed: boolean =
            groups.filter((g) => g.model.LogicalStatus === 1 && g.workflowsCount > 0).length > 0;
        for (const group of groups)
            group.collapsed = workStatusUsed ? group.model.LogicalStatus != 1 : group.model.LogicalStatus != 0;

        return groups;
    }

    private async getTasksGroups(
        jobOrderId: number,
        textFilter: string
    ): Promise<ITasksForTaskBoardGroupsDataSourceModel[]> {
        while (this.tasksCacheClearRequested) {
            this.tasksCacheClearRequested = false;

            const request: IGetTasksForUserRequest = {
                textFilter: textFilter,
                userId: !this.resourceId ? this.userId ?? this.userInfo.getIdUser() : null,
                resourceId: this.resourceId,
                workflows: [],
                statusIds: [],
                jobOrderId: jobOrderId,
                viewWorker: this.viewWorker,
                viewResponsible: this.viewResponsible,
                skip: 0,
                count: 1000000,
                viewAll: this.viewAllActivities,
                hideNoWork: !this.showNoWork,
                destinationTaskBoardStatus: null,
                startDate: null,
                endDate: null,
                activityProgressMode: 0,
                showClosed: this.showClosedTasks,
                getTasksIfNoFilter: false,
                getWorkflowsInWorkflows: true,
                searchOnWorkflowTitle: false,
            };
            this.tasksCache = await this.todoListService.GetTasksForUser(request);

            if (this.tasksCacheClearRequested) this.tasksCache = [];
        }

        const groups: ITasksForTaskBoardGroupsDataSourceModel[] = [];
        const createdGroups: { [workflowId: number]: boolean } = {};

        for (const task of this.tasksCache) {
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!createdGroups[task.WorkflowId]) continue;

            groups.push({
                id: task.WorkflowId,
                isGroup: true,
                isLeaf: false,
                isTasksGroup: true,
                title: task.WorkflowTitle,
                collapsed: false,
            });

            createdGroups[task.WorkflowId] = true;
        }

        return groups;
    }

    private getWorkflowsFromCache(state: number): IWorkflowForTaskBoardDataSourceModel[] {
        return this.workflowsCache.filter((w) => w.StateId === state).map((w) => this.createModelForWorkflow(w));
    }

    private async getWorkflowsByIds(ids: number[]): Promise<IWorkflowForTaskBoardDataSourceModel[]> {
        const request: IGetJobOrderWorkflowsForUserByIdsRequest = {
            ids: ids,
            userId: this.userId ?? this.userInfo.getIdUser(),
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            viewAll: this.viewAllActivities,
            hideNoWork: !this.showNoWork,
        };
        const workflows = await this.todoListService.GetJobOrderWorkflowsForUserByIds(request);
        return workflows.map(this.createModelForWorkflow, this);
    }

    private createModelForWorkflow(workflow: IWorkflowForTaskBoard): IWorkflowForTaskBoardDataSourceModel {
        const item: IWorkflowForTaskBoardDataSourceModel = {
            id: workflow.Id,
            isGroup: false,
            isLeaf: false,
            title: workflow.Title,
            model: workflow,
            isWorkflow: true,
            showSelectAllChildren: true,
            icon: {
                icon: workflow.Icon,
                background: workflow.Background,
                foreground: workflow.Foreground,
            },
            progressBar: {
                progress: ((workflow.TasksCount - workflow.NotCompleteTasksCount) / workflow.TasksCount) * 100,
            },
            subTitle:
                TextResources.Todolist.ClosedTasks +
                ": " +
                (workflow.TasksCount - workflow.NotCompleteTasksCount) +
                "/" +
                workflow.TasksCount,
            secondaryAction: {
                icon: {
                    icon: "fa fa-pencil",
                    background: "#ecbc29",
                    foreground: "white",
                },
                action: () => {
                    this.todoListService.ShowEditWorkflowDialog(workflow.Id).then((result) => {
                        if (!result) return;
                        this.view.refresh();
                    });
                },
            },
            alerts: {
                label: TextResources.Todolist.Alerts,
                icons: WorkflowsUtils.getAlertsForWorkflow(workflow),
            },
            parent: this.lastJobOrder,
            parents: [this.lastJobOrder],
        };

        if ((workflow.AllocationPercentage || 0) > 0 || workflow.HasNotEstimatedElementsAllocated) {
            item.badge = [
                {
                    text: numeral(workflow.AllocationPercentage / 100).format("0[.]0[0]%"),
                    cssClass: WorkflowsUtils.getAllocationBadgeClass(workflow.HasNotEstimatedElementsAllocated),
                    action: WorkflowsUtils.getAllocationBadgeAction(
                        item.model.Id,
                        item.model.JobOrderId,
                        this.dialogsService
                    ),
                },
            ];
        }

        return item;
    }

    private createModelForWorkflowState(
        state: IWorkflowState,
        workflowsCount: number
    ): IWorkflowsForTaskBoardGroupDataSourceModel {
        return {
            id: state.Id,
            isGroup: true,
            isLeaf: false,
            isWorkflowGroup: true,
            title: String.format(TextResources.Todolist.WorkflowStateForMenu, state.Description, workflowsCount),
            model: state,
            collapsed: true,
            workflowsCount: workflowsCount,
        };
    }

    private async getTasks(
        workflow: IWorkflowForTaskBoardDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<ITaskForTaskBoardDataSourceModel[]> {
        const request: IGetTasksForUserRequest = {
            textFilter: textFilter,
            userId: !this.resourceId ? this.userId ?? this.userInfo.getIdUser() : null,
            resourceId: this.resourceId,
            statusIds: null,
            jobOrderId: workflow.model.JobOrderId,
            workflows: [workflow.id],
            viewWorker: this.viewWorker,
            viewResponsible: this.viewResponsible,
            skip: skip,
            count: count,
            viewAll: this.viewAllActivities,
            hideNoWork: !this.showNoWork,
            destinationTaskBoardStatus: null,
            startDate: null,
            endDate: null,
            activityProgressMode: 0,
            showClosed: true,
            getTasksIfNoFilter: false,
            getWorkflowsInWorkflows: true,
            searchOnWorkflowTitle: false,
        };
        const tasks = await this.todoListService.GetTasksForUser(request);
        return tasks.map((t) => this.createModelForTask(t, workflow));
    }

    private async getTasksByIds(ids: number[]): Promise<ITaskForTaskBoardDataSourceModel[]> {
        const tasks = await this.todoListService.GetTasksForUserById(this.userInfo.getIdUser(), ids);
        return tasks.map((t) =>
            this.createModelForTask(
                t,
                this.createModelForWorkflow(this.workflowsCache.firstOrDefault((w) => w.Id === t.WorkflowId))
            )
        );
    }

    private createModelForTask(task: ITaskForTaskBoard, workflow: IDataSourceModel): ITaskForTaskBoardDataSourceModel {
        const taskModel: ITaskForTaskBoardDataSourceModel = {
            id: task.Id,
            title: task.Title,
            isGroup: false,
            isLeaf: true,
            model: task,
            icon: TasksUtils.getTaskStatusIcon(task.TaskBoardStatus),
            subTitle: TextResources.Todolist.Status + ": " + TasksUtils.getTaskStatusName(task.TaskBoardStatus),
            badge: [
                {
                    text: task.BlogEventsCount.toString(),
                    cssClass: task.BlogEventsCount == 0 ? "badge-danger" : "badge-success",
                },
            ],
            alerts: {
                label: TextResources.Todolist.Alerts,
                icons: TasksUtils.getAlertsForTask(task),
            },
            secondaryAction: {
                icon: {
                    icon: "fa fa-pencil",
                    background: "#ecbc29",
                    foreground: "white",
                },
                action: () => {
                    this.todoListService.ShowEditTaskDialog(task.Id).then((result) => {
                        if (!result) return;
                        this.view.refresh();
                    });
                },
            },
            isTask: true,
            parent:
                workflow ||
                ({
                    id: task.WorkflowId,
                    title: task.WorkflowTitle,
                    isGroup: true,
                    isLeaf: false,
                    isWorkflow: true,
                    collapsed: false,
                } as IWorkflowForTaskBoardDataSourceModel),
        };

        taskModel.parents = [taskModel.parent].concat(taskModel.parent.parents ?? []);

        if (task.IsAllocated) {
            taskModel.iconsList = [
                {
                    icon: "fa fa-shopping-cart",
                    background: task.EstimatedDuration > 0 ? "#45b6af" : "#f3565d",
                    foreground: "#ffffff",
                },
            ];
        }

        return taskModel;
    }

    private getTasksFromCache(workflowId: number): ITaskForTaskBoardDataSourceModel[] {
        const workflow = this.workflowsCache.firstOrDefault((w) => w.Id === workflowId);
        return this.tasksCache
            .filter((t) => t.WorkflowId === workflowId)
            .map((t) => this.createModelForTask(t, this.createModelForWorkflow(workflow)));
    }

    refresh() {
        if (!this.view) return;

        this.workflowsCache = [];
        this.workflowsCacheClearRequested = true;

        this.view.refresh(true);
    }
}
