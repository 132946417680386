import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import * as ko from "knockout";
import { DropdownList } from "../../../Components/DropdownList";
import { Layout } from "../../../Components/Layouts";
import { Portlet } from "../../../Components/Portlet";
import { RichTextInput } from "../../../Components/RichTextInput";
import { TextInput } from "../../../Components/TextInput";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { FeatureData } from "./FeatureData";
import { Feature } from "./Feature";
import { _RequirementEditorDialog } from "../Requirements/RequirementEditorDialog";
import { FeatureRequirement } from "./FeatureRecap";

const styleSheet = jss.createStyleSheet({
    featureCells: {       
        "& > div":{                        
            padding: "5px",
        }
    }
   
}).attach();

const {classes} = styleSheet;

type FunctionPointComputationFeatureEditorProps = {
    feature: ko.Observable<Feature>,
    features: ko.ObservableArray<Feature>,
    onSave: (f: Feature) => void;
    onDelete: (f:Feature) => void;
}


export function FunctionPointComputationFeatureEditor(props: FunctionPointComputationFeatureEditorProps) {
    const C = require("./FunctionPointComputationFeatureEditor")._FunctionPointComputationFeatureEditor as typeof _FunctionPointComputationFeatureEditor;
    return <C {...props} />;
}

export class _FunctionPointComputationFeatureEditor extends React.Component<FunctionPointComputationFeatureEditorProps> {
    static defaultProps: Partial<FunctionPointComputationFeatureEditorProps> = {
    }

    featureName: ko.Observable<string> = ko.observable();
    featureId: ko.Observable<string> = ko.observable();
    featureDescription: ko.Observable<string> = ko.observable();
    featureType: ko.Observable<string> = ko.observable();
    /*typeValues: FeatureType[] = [{id: 1, descriptionType: "EI"},
                                {id:2, descriptionType: "EO"},
                                {id:3, descriptionType: "EQ"},
                                {id:4, descriptionType: "EIF"},
                                {id:5, descriptionType: "ILF"}];
    operation : ko.Observable<string> = ko.observable();
    operationTypes: FeatureType[] =  [{id:1, descriptionType:"ADD"},
                                    {id:2, descriptionType:"CFP"},
                                    {id:3, descriptionType:"CHG"},
                                    {id:4, descriptionType: "DEL"}];*/
    

    requirements: ko.ObservableArray<FeatureRequirement> = ko.observableArray();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    title: ko.Computed<"Nuova Funzionalità" | "Modifica Funzionalità">;

    constructor(props: FunctionPointComputationFeatureEditorProps) {
        super(props);
       
        useEffect(() => {
            const f = this.props.feature();
            if(!f){
                this.featureName("");
                this.featureId("");
                this.featureDescription("");
                this.featureType(undefined);
                this.requirements([]);
            }else{
                this.featureName(f.featureName);
                this.featureId(f.featureId);
                this.featureDescription(f.description);
                this.featureType(f.featureType());
                this.requirements(f.requirements().slice());
            }    
        }, [this.props.feature])
        
        this.title = ko.computed(() => {
            const feature = this.props.feature();
            if(!feature) return "Nuova Funzionalità";
            if(feature.Id <= 0) return "Nuova Funzionalità";
            return "Modifica Funzionalità";
        });
    }
    async addRequirement(){
/*         let dialog = new _RequirementEditorDialog({featureId: this.featureId});
        dialog.title= ko.observable("Aggiungi Requisito");
        let result = await this.dialogsService.ShowModal<FeatureRequirement>(dialog);
        this.requirements.push(result); */
    }
    render() {
        const onSave = () => {
            let dim = this.props.features().length + 1;
            let newid = this.props.feature().Id < 0 ? dim : this.props.feature().Id;
            this.props.onSave(
                new Feature(
                newid,
                this.featureId(),
                this.featureName(),
                this.featureDescription(),
                this.featureType(),
                this.requirements()
            ))
        };     
        const onCancel = () => {
            const oldFeature = this.props.feature();
            this.featureName(oldFeature.featureName);
            this.featureId(oldFeature.featureId);
            this.featureDescription(oldFeature.description);
            this.featureType(oldFeature.featureType());
            this.requirements(oldFeature.requirements().slice());
        };
        const onDelete = () => {                        
            this.props.onDelete(
                this.props.feature(this.props.feature())
            );            
        };

        let row: IDataSourceModel<number,Feature>;
        return <Portlet collapsible={false}>
                    <Portlet.Header>
                        <Portlet.Header.Default title={this.title} />
                    </Portlet.Header>
                    <Portlet.Actions >
                        <div className={"flex-container"}>
                            <button className={"btn btn-success btn-circle btn-sm"} onClick={onSave}>
                                <i className={"fa fa-floppy-o"} /> &nbsp;Salva
                                    </button>
                            <button className={"btn btn-danger btn-circle btn-sm"} onClick={onDelete}>
                                <i className={"fa fa-trash-o"} /> &nbsp;Elimina
                            </button>
                            <button className={"btn btn-warning btn-circle btn-sm"} onClick={onCancel}> 
                                <i className={"fa fa-trash-o"} /> &nbsp;Annulla
                            </button>
                            <button className={"btn btn-primary btn-circle btn-sm"} onClick={this.addRequirement.bind(this)}>
                                <i className={"fa fa-plus"} />&nbsp;Aggiungi Requisito
                            </button>
                        </div>
                    
                    </Portlet.Actions>
                    <Portlet.Body >
                        {() =><> <FeatureData feature={this.props.feature}/>
                              </>}
                    </Portlet.Body>        
                               
                </Portlet>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(()=> styleSheet.detach());
    reloadNow(FunctionPointComputationFeatureEditor);
}
