import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { BindTo } from "../../../../Components/Bind";
import { HorizontalLayout, Layout } from "../../../../Components/Layouts";
import { IPreOrdersDataSourceModel, PreOrdersDataSource } from "../../../../DataSources/PreOrdersDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ListItem } from "../../../../Components/ListComponent";
import { IPreOrder, IProvisioningService } from "../../../ProvisioningService";
import { RouteParams } from "../../../../Components/Routing";
import { IApplicationNavBarAction, INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { Portlet } from "../../../../Components/Portlet";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { DateRangeColumnFilter } from "../../../../Components/ColumnFilters/DateRangeColumnFilter";
import { SupplierColumnFilter } from "../../../../Components/ColumnFilters/SupplierColumnFilter";
import { OptionsColumnFilter } from "../../../../Components/ColumnFilters/OptionsColumnFilter";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ResourceColumnFilter } from "../../../../Components/ColumnFilters/ResourceColumnFilter";


export type PreOrdersListProps = RouteParams<{
    navBarActionManager: INavBarActionManager;
}>;

export class PreOrdersList {
    public PreOrdersDataSource: PreOrdersDataSource = new PreOrdersDataSource();
    private addAction: IApplicationNavBarAction;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService : IProvisioningService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();
    public StatusId: ko.Observable<number> = ko.observable();
    public CreatedBy: ko.Observable<number> = ko.observable();

    public StatesList: ko.ObservableArray<{ Id: number, Label: string }> = ko.observableArray([
        { Id: 2, Label: TextResources.Provisioning.OnWorkingPreOrder },
        { Id: 3, Label: TextResources.Provisioning.ClosedPreOrder },
        { Id: 4, Label: TextResources.Provisioning.PartiallyManagedPreOrder }
    ]);

    private refreshOnFilterChanged: ko.Computed<void>;

    constructor(private props: PreOrdersListProps) {
        // TODO registrarsi sul notificationsService per aggiornare in caso di creazione/modifica/cancellazione

        this.refreshOnFilterChanged = ko.computed(() => {
            this.PreOrdersDataSource.setFilter({
                createdBy: this.CreatedBy(),
                fromDate: this.DateFrom(),
                toDate: this.DateTo(),
                stateId: this.StatusId()
            });
            ko.ignoreDependencies(() => this.PreOrdersDataSource.refresh());
        }).extend({ deferred: true });
    }

    componentDidMount() {
        this.addAction = this.props.navBarActionManager.addNavBarAction(
            TextResources.Provisioning.NewPreOrder,
            "fa fa-plus",
            true,
            () => this.props.navigator.navigateTo("/Provisioning/PreOrders/New")
        );
    }

    componentWillUnmount() {
        this.props.navBarActionManager.removeNavBarAction(this.addAction);
        this.refreshOnFilterChanged.dispose();
    }

    private editPreOrder(poId: number) {
        this.props.navigator.navigateTo("/Provisioning/PreOrders/" + poId);
    }

    private async deletePreOrder(poId: number) {
        if(!await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.DeletePreOrderMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm
        )) return;

        await this.provisioningService.DeletePreOrder(poId);

        this.infoToastService.Success(TextResources.Provisioning.PreOrderDeleted);
        this.PreOrdersDataSource.refresh();
    }

    render() {
        const _pol = this;
        let pol: IPreOrdersDataSourceModel;

        const { sortDate, sortNumber, sortString } = ComponentUtils.useSorter<IPreOrder>();

        return  ComponentUtils.bindTo(
                <Layout.Grid columns={['1fr']} rows={["90px", "1fr"]}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <Portlet.Header.Default title="Preordini" className="font-red-sunglo bold uppercase" />
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Table compact dataSource={this.PreOrdersDataSource} rowAs="pol">
                            <Column title="Numero" sorter={sortNumber(r => parseInt(r.Number))}>
                                <span data-bind={{ text: pol.model.Number }} />
                            </Column>
                            <Column title="Creato da" sorter={sortString(r => r.CreationResourceName)} filterComponent={() => <ResourceColumnFilter initialValue={this.CreatedBy()} onResourceSelected={this.CreatedBy} />}>
                                <span data-bind={{ text: pol.model.CreationResourceName }} />
                            </Column>
                            <Column title="Creato il" sorter={sortDate(r => r.CreationDate)} filterComponent={() => <DateRangeColumnFilter initialDateFrom={this.DateFrom()} initialDateTo={this.DateTo()} onDateRangeChanged={(s,e) => { this.DateFrom(s); this.DateTo(e); }} />}>
                                <span data-bind={{ dateText: pol.model.CreationDate }} />
                            </Column>
                            <Column title="Stato" sorter={sortNumber(r => r.StatusId)} filterComponent={() => <OptionsColumnFilter initialValue={this.StatusId()} options={this.StatesList()} optionsValue="Id" optionsText="Label" onChanged={this.StatusId} placeholder="Tutti gli stati" />}>
                                <span data-bind={{ text: _pol.StatesList().firstOrDefault(s => s.Id === pol.model.StatusId).Label }} />
                            </Column>
                            <Column style={{ width: 200, textAlign: 'right' }}>
                                <button className="btn btn-xs btn-circle btn-primary" data-bind={{ visible: pol.model.Note != null && pol.model.Note != "" }}>
                                    <i className="fa" data-bind={{ css: { 'fa-angle-up': !pol.NoteVisible(), 'fa-angle-down': pol.NoteVisible }, toggleEx: pol.NoteVisible }} />
                                </button>
                                <button className="btn btn-xs btn-circle btn-primary" data-bind={{ click: _pol.editPreOrder.bind(_pol, pol.id)}}>
                                    <i className="fa fa-pencil"/>&nbsp;Modifica
                                </button>
                                <button className="btn btn-xs btn-circle btn-danger" data-bind={{ asyncClick: _pol.deletePreOrder.bind(_pol, pol.id)}}>
                                    <i className="fa fa-trash-o"/>&nbsp;Elimina
                                </button>
                            </Column>
                            <SecondaryRow if={() => "pol.NoteVisible" }>
                                {(item : ITableItem<IPreOrdersDataSourceModel>) => <td colSpan={item.VisibleColumnsCount()}>
                                        <div style={{ padding: 10, margin: -5, backgroundColor: '#f6f6f6' }} data-bind={{ text: pol.model.Note || "Nessuna nota" }}></div>
                                    </td>
                                }
                            </SecondaryRow>
                        </Table>
                    </Layout.Grid.Cell>
                </Layout.Grid>
        , this, "_pol");
    }
}