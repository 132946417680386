import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 16:01
 * To change this template use File | Settings | File Templates.
 */


import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ICampaignType } from "../../../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ICampaignTypeSettingManager } from "../../../../../interfaces/ICampaignTypeSettingManager";

export interface ICampaignTypesProvider
{
    getData() : ICampaignType;
    update(campaignType : ICampaignType) : void;
    withError: ko.Observable<boolean>;
}

export class CampaignTypeEditingViewModel {
    title : string;
    elements : ko.ObservableArray<ICampaignTypesProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    NewType : ko.Observable<string> = ko.observable();
    private toastService : IInfoToastService;

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private campaignTypesManager : ICampaignTypeSettingManager)
    {
        this.title = campaignTypesManager.getLabel();
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        campaignTypesManager.getCampaignTypes()
            .forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(campaignType : ICampaignType)
    {
        this.elements.push(new CampaignTypeViewModel(this.serviceLocator, this, campaignType));
    }

    public addNewSetting()
    {
        var vm = new CampaignTypeViewModel(this.serviceLocator, this, {
            Id: null,
            Name: this.NewType(),
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF"
        });
        this.elements.push(vm);
        this.createOrUpdateEntry(vm);
        this.NewType("");
    }

    public setIsSelectedNewSetting()
    {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : ICampaignTypesProvider)
    {
        var campaignType = element.getData();
        this.campaignTypesManager.addOrUpdate(campaignType)
            .then((updated) => element.update(updated));
    }

    public deleteEntry(element : ICampaignTypesProvider)
    {
        var campaignType = element.getData();
        this.campaignTypesManager.remove(campaignType.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            })
            .catch(() => { });
    }

    private reloadElements() {
        this.elements([]);
        this.campaignTypesManager.load()
            .then(() => {
                this.campaignTypesManager.getCampaignTypes()
                    .forEach(this.createViewModelFor.bind(this));
            });
    }
}


class CampaignTypeViewModel implements ICampaignTypesProvider
{
    private toastService : IInfoToastService;

    Id: ko.Observable<number> = ko.observable();
    Name : ko.Observable<string> = ko.observable();
    Icon: ko.Observable<string> = ko.observable();
    Background: ko.Observable<string> = ko.observable();
    Foreground: ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    public withError: ko.Observable<boolean> = ko.observable(false);

    private updating : boolean = false;

    private currentDescription : string;

    constructor(private serviceLocator, private container : CampaignTypeEditingViewModel, private campaignType : ICampaignType)
    {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        this.update(campaignType);

        this.Name.subscribe(this.onNameChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
    }

    private onNameChanged(newValue : string)
    {
        if(this.currentDescription == newValue)
            return;

        this.withError(false);
        if(!newValue)
        {
            this.container.deleteEntry(this);
        }
        else
            this.container.createOrUpdateEntry(this);
    }

    getData() : ICampaignType
    {
        var campaignType : ICampaignType = <ICampaignType> $.extend({}, this.campaignType);
        campaignType.Name = this.Name();
        campaignType.Icon = this.Icon();
        campaignType.Background = this.Background();
        campaignType.Foreground = this.Foreground();
        return campaignType;
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating) return;
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    update(campaignType : ICampaignType) : void
    {
        this.updating = true;

        this.campaignType = campaignType;
        this.Id(campaignType.Id);
        this.Name(campaignType.Name);
        this.Icon(campaignType.Icon);
        this.Background(campaignType.Background);
        this.Foreground(campaignType.Foreground);

        this.currentDescription = campaignType.Name;
        this.updating = false;
    }
}