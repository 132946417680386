import * as ko from "knockout";
import "reflect-metadata";
import { IDetectChanges, IDetectSnapshotChanges } from "./interfaces/IDetectChanges";

declare var JsonNetDecycle : any;

export function DetectChanges(target: any, propertyName: string) {
    let existingProperties = Reflect.getMetadata("detectChanges:properties", target) || []; 
    existingProperties.push(propertyName);

    Reflect.defineMetadata("detectChanges:properties", existingProperties, target);
}

export function DetectClassChanges<T extends { new(...args: any[]): IDetectChanges }>(constructor : T) {
    return class extends constructor {
        private subscriptions : ko.Subscription[] = [];

        constructor(...args : any[]) {
            super(...args);
            
            var properties : string[] = Reflect.getMetadata("detectChanges:properties", this) || [];

            for(let prop of properties) {
                let obs : ko.Observable<any> = this[prop];
                let subscription = obs.subscribe((newValue) => {
                    if (!Array.isArray(newValue))
                        console.log(prop + ' changed to ' + JSON.stringify(JsonNetDecycle.decycle(newValue)));
                    else
                        console.log(prop + ' array length changed to ' + newValue.length);
                    
                    ko.ignoreDependencies(() => this.isChanged(1));
                }, this)
                this.subscriptions.push(subscription);
            }
            
            this.isChanged.subscribe((newValue) => {
                console.log("isChanged now is " + newValue);
            });
        }

        dispose() : void {
            super.dispose();
            this.internalDispose();
        }

        private internalDispose() : void {
            for(let sub of this.subscriptions)
                sub.dispose();
        }
    };
}

export function DetectClassSnapshotChanges<T extends { new(...args: any[]): IDetectSnapshotChanges }>(constructor : T) {
    return class extends constructor {
        private subscriptions : ko.Subscription[] = [];

        constructor(...args : any[]) {
            super(...args);
        }

        dispose() : void {
            super.dispose();
            this.internalDispose();
        }

        private internalDispose() : void {
            for(let sub of this.subscriptions)
                sub.dispose();
        }

        takeSnapshot() : void {
            this.isChanged(0);

            var properties : string[] = Reflect.getMetadata("detectChanges:properties", this) || [];

            for(let prop of properties) {
                let obs : ko.Observable<any> = this[prop];
                let actualValue = obs();
                let subscription = obs.subscribe((newValue) => {
                    if(actualValue === newValue) {
                        this.isChanged(this.isChanged() - 1);
                        return;
                    }

                    this.isChanged(this.isChanged() + 1);
                }, this)
                this.subscriptions.push(subscription);
            }
        }
    };
}

