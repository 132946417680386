import { ReportComponent, ReportComponentProps } from "./ReportComponent";
import { Component } from "./ReportComponentProvider";
import { HasBackground, ReportComponentWithBackgroundProps } from "./ReportComponentWithBackground";
import { HasChildren, ReportComponentWithChildrenProps } from "./ReportComponentWithChildren";
import { HasPosition, ReportComponentWithPositionProps } from "./ReportComponentWithPosition";
import { HasVisibility, ReportComponentWithVisibilityProps } from "./ReportComponentWithVisibility";

export type ReportPanelProps = ReportComponentWithVisibilityProps & ReportComponentWithChildrenProps & ReportComponentWithBackgroundProps & ReportComponentWithPositionProps & ReportComponentProps;

@Component(nameof<ReportPanel>())
export class ReportPanel extends HasBackground(HasChildren(HasVisibility(HasPosition(ReportComponent)))) {
    get type() { return nameof<ReportPanel>(); }
}