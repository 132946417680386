import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { BasePagedList } from "../Utilities/BasePagedList";
import { MetelImport } from "../Metel/MetelImporter";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { CatalogEditDialog } from "./CatalogEditDialog";
import { ICatalogsService, ISearchedCatalog } from "../../../../ProlifeSdk/interfaces/warehouse/ICatalogsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class CatalogsList extends BasePagedList implements IView {
    @LazyImport(ProlifeSdk.CatalogsServiceType)
    private catalogsService: ICatalogsService;

    templateName: string = "catalogs-list";
    templateUrl: string = "warehouse/templates";
    viewModel: any;

    public title: string = ProlifeSdk.TextResources.Warehouse.Catalogs;
    private ShowOnlyActive: ko.Observable<boolean> = ko.observable(false);

    public SectionId: number = 5;

    constructor() {
        super();

        this.viewModel = this;
        this.loadRows();

        this.ShowOnlyActive.subscribe(this.loadRows.bind(this));
    }

    public newCatalog(): Promise<void> {
        let dialog = new CatalogEditDialog();
        return dialog.ShowModal().then(() => {
            this.loadRows();
        });
    }

    public importMetel() {
        new MetelImport().showDialog();
    }

    public fetchRows(): Promise<ISearchedCatalog[]> {
        return this.catalogsService.SearchCatalogs(
            this.searchFilter(),
            this.ShowOnlyActive(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage
        );
    }

    public fetchRowsCount(): Promise<number> {
        return this.catalogsService.SearchCatalogsCount(this.searchFilter(), this.ShowOnlyActive());
    }

    public createViewModelFor(catalog: ISearchedCatalog): CatalogViewModel {
        return new CatalogViewModel(this, catalog);
    }
}

export class CatalogViewModel {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    ManufacturerCode: string;
    Name: string;
    EffectiveDate: Date;
    IsActive: boolean;
    Number: string;

    constructor(private list: CatalogsList, private c: ISearchedCatalog) {
        this.ManufacturerCode = c ? c.ManufacturerCode : "";
        this.Name = c.Description;
        this.EffectiveDate = moment(c.EffectiveDate).toDate();
        this.IsActive = c.IsActive;
        this.Number = c.Number;
    }

    public Edit() {
        const dialog = new CatalogEditDialog(this.c);
        return dialog.ShowModal().then(() => {
            this.list.loadRows();
        });
    }

    public Download() {
        const url = "Warehouse-api/Catalogs/DownloadCatalog?catalogId=" + this.c.Id;
        this.ajaxService.DownloadFileFromUrl(url, { overrideMethod: "GET" });
    }
}
