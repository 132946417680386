import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderAllocationsDetails, IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export interface ISplashPageAllocationDetailsParams {
    jobOrderId: number;
}

export class SplashPageAllocationDetails {
    JobOrderId: number;

    Allocations : ko.ObservableArray<IJobOrderAllocationsDetails> = ko.observableArray([]);
    Loading: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    
    constructor(private props : ISplashPageAllocationDetailsParams) {
        this.JobOrderId = this.props.jobOrderId ?? -1;
    }

    componentDidMount() {
        console.log("Allocations ComponentDidMount");

        this.load();
    }

    render() {
        let allocation: IDataSourceModel<number, IJobOrderAllocationsDetails>;

        let { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<IJobOrderAllocationsDetails>();

        return ComponentUtils.bindTo((
            <Table compact={true} bordered={true} scrollable={true} showLoadingIndicator={this.Loading} dataSource={{ array: this.Allocations, factory: this.createAllocationModel.bind(this) }} rowAs="allocation">
                <Column title={TextResources.JobOrder.AllocationsDetailsResource} sorter={sortString(a => a.ResourceName)}>
                    <span data-bind={{ text: allocation.model.ResourceName }}></span>
                </Column>
                <Column title={TextResources.JobOrder.AllocationsDetailsCart} sorter={sortString(a => a.CartName)} data-bind={{ style: { 'border-left': '4px solid ' + allocation.model.Color } }}>
                    <div className="flex-container">
                        <span className="flex-fill" data-bind={{ text: allocation.model.CartName }}></span>
                        <i className="fa fa-calendar pull-right" title={TextResources.JobOrder.AllocationsDetailsManualCartTitle} data-bind={{ visible: allocation.model.AllocationMode == 0 }}></i>
                    </div>
                </Column>
                <Column title={TextResources.JobOrder.AllocationsDetailsStart} sorter={sortDate(a => a.StartDate)} className="text-right">
                    <span data-bind={{ dateText: allocation.model.StartDate }}></span>
                </Column>
                <Column title={TextResources.JobOrder.AllocationsDetailsEnd} sorter={sortDate(a => a.EndDate)} className="text-right">
                    <span data-bind={{ dateText: allocation.model.EndDate }}></span>
                </Column>
            </Table>
        ), this);
    }

    private async load() : Promise<void> {
        this.Loading(true);

        let allocations = await this.jobOrderService.GetJobOrderAllocationDetails(this.JobOrderId);
        this.Allocations(allocations);

        this.Loading(false);
    }

    private createAllocationModel(allocation: IJobOrderAllocationsDetails): IDataSourceModel<number, IJobOrderAllocationsDetails> {
        return {
            id: allocation.CartId,
            title: allocation.CartName,
            isGroup: false,
            isLeaf: true,
            model: allocation
        };
    }
}