import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { BaseDocumentForWizard } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/BaseDocumentForWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "../wizard/ImportDocumentDataWizard";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IInvoiceRowForDocuments, IInvoiceRow, IDdtForWizard, IBaseDocumentRowForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentBuilderDocumentRelatedWorkflows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DocumentDataSourceDataWizardStep } from "../wizard/data-sources/DocumentDataSourceDataWizardStep";

export class DdtDataSource extends DocumentDataSourceDataWizardStep<IInvoiceRowForDocuments, DdtRow>
{
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService : IInvoicesService;
    
    public JobOrderName : ko.Observable<string> = ko.observable();
    public CustomerName : ko.Observable<string> = ko.observable();
    public WithNoJobOrder : ko.Observable<boolean> = ko.observable(false);
    public Ddts : ko.ObservableArray<Ddt> = ko.observableArray();
    public Total : ko.Computed<number>;

    public IsDestinationShippingInvoice = ko.observable(false);

    public DateFrom : ko.Observable<Date> = ko.observable();
    public DateTo : ko.Observable<Date> = ko.observable();
    public SelectAllRows : ko.Computed<boolean>;

    public ShowAlreadyImported : ko.Observable<boolean> = ko.observable(true);

    private DisableDocumentsReload = false;
    private referenceDate : Date;

    public WarningMessages : ko.Observable<string> = ko.observable();
    public ExpandedColumn: ko.Observable<number> = ko.observable(1);
    previousStepRows: IDocumentDataWizardRow[];

    constructor()
    {
        super();
        this.Title(TextResources.Invoices.Delivery);
        
        this.installWatches();

        this.Total = ko.computed(() => {
            let result = 0;
            this.Rows().forEach(r => {
                result += (r.TotaleRiga || 0);
            });
            return result;
        });

        this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.InvoiceEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.CreditNoteEntityTypeCode
        );

        this.SelectAllRows = ko.computed({
            read: () => {
                const ddts = this.SelectedDocuments();
                let rowsCount = 0;
                let selectedRowsCount = 0;
                
                ddts.forEach((e: Ddt) => {
                    const ddtRows = e.Rows();
                    const selectedRows = ddtRows.filter((r : DdtRow) => { return r.Selected(); })

                    rowsCount += ddtRows.length;
                    selectedRowsCount += selectedRows.length;
                });

                return rowsCount === selectedRowsCount ? true : selectedRowsCount === 0 ? false : undefined;
            },
            write: (selected : boolean) => {
                this.SelectedDocuments().forEach((e : Ddt) => {
                    e.Rows().forEach((r : DdtRow) => {
                        r.Selected(selected);
                    });
                });
            }
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Quantita,
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: r.NetUnitPriceInDocumentCurrency,
                NetUnitPrice: r.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency),
                Order: 0,
                TotalPriceInDocumentCurrency: r.TotalPriceInDocumentCurrency,
                TotalPrice: r.TotalPriceInDocumentCurrency.ToEuro(documentCurrency),
                UnitPriceInDocumentCurrency: r.PriceInDocumentCurrency,
                UnitPrice: r.PriceInDocumentCurrency.ToEuro(documentCurrency),
                Description: r.Descrizione,
                Discounts: r.Discounts,

                FKVatCode: r.FKTipoIva,
                VatCode: r.IVA
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: this.getRelatedWorkflows(r),
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : IInvoiceRowForDocuments) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        for(const sourceRow of r.Rows.filter(r => r.Quantita != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType : ProlifeSdk.DdtEntityTypeCode,
                SourceEntityKeyId : sourceRow.IdRigaFattura,
                DestEntityType : this.initializationInfo.DocTypeCode,
                DestEntityKeyId : 0,
                Amount: sourceRow.Quantita,
                UnitPrice : sourceRow.Importo,
                Discounts : sourceRow.Discounts,
                NetUnitPrice: sourceRow.NetUnitPrice,
                NetPrice : sourceRow.TotaleRiga
            });
        }
        return refs;
    }

    protected getRelatedWorkflows(source : IInvoiceRowForDocuments) : IDocumentBuilderDocumentRelatedWorkflows[] {
        const result : IDocumentBuilderDocumentRelatedWorkflows[] = [];

        for(const row of source.Rows) {
            for(const rw of row.RelatedWorkflows || []) {

                result.push({
                    Id : null,
                    WorkflowId : rw.WorkflowId,
                    DocumentId : null,
                    DocumentType: this.initializationInfo.DocTypeCode,
                    DocumentNumber: null,
                    DocumentDate: null,
                    DocumentProtocolId: null,
                    RowId: 0,
                    RowDescription: null,
                    RowAmount: source.Quantita,
                    RowUoM: source.Udm,
                    RowPrice: source.NetUnitPrice,
                    WorkflowAmount: rw.WorkflowAmount,
                    ValidityStartDate: null,
                    WorkflowName: rw.WorkflowName
                });
            }
        }

        return result;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        //Consento l'import dei DDT su F.Acc. solo se oportunamente configurato nei settings del protocollo
        return initializationInfo.DocTypeCode !== ProlifeSdk.AccompanyingInvoiceEntityTypeCode ||
            (initializationInfo.DestinationDocumentProtocol && initializationInfo.DestinationDocumentProtocol.DdtCanBeImportedToShippingInvoice);
    }

    public HasAuthorization(): boolean {
        return this.authorizationService.isAuthorized("Documents_ViewDDTs") || this.authorizationService.isAuthorized("Documents_DDTs")
    }

    public async GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        const docsToImport : Ddt[] = [];
        this.Rows().forEach((r : IInvoiceRowForDocuments) => {
            r.Rows.forEach((dr : IInvoiceRow) => {
                const matches : Ddt[] = this.Ddts().filter((e : Ddt) => { return e.ddt.Id == dr.FKFattura; });

                if(matches.length == 0 || docsToImport.indexOf(matches[0]) > -1)
                    return;

                docsToImport.push(matches[0]);
            });
        });


        return this.SelectedDocuments().map((d : Ddt) => {
            return {
                Id : d.ddt.Id,
                Name : String.format(ProlifeSdk.TextResources.Invoices.DeliveryName, d.Number(), moment(d.Date()).format("L")),
                EntityType : ProlifeSdk.DdtEntityTypeCode
            }
        });
    }

    public CheckInitializationInfoSupport(initializationInfo : IWizardInitializationInfo)
    {
        //Consento l'import dei DDT su F.Acc. solo se oportunamente configurato nei settings del protocollo
        return initializationInfo.DocTypeCode !== ProlifeSdk.AccompanyingInvoiceEntityTypeCode ||
            (initializationInfo.DestinationDocumentProtocol && initializationInfo.DestinationDocumentProtocol.DdtCanBeImportedToShippingInvoice);
    }

    protected override onImportSelectedRows(someDocumentsCannotBeImported: boolean) {}

    public async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        this.DisableDocumentsReload = true;

        await super.Initialize(initializationInfo);
        this.SelectedDocuments([]);

        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf('month').toDate());
        this.DateTo(this.referenceDate);
        this.IsDestinationShippingInvoice(initializationInfo.DocTypeCode === ProlifeSdk.AccompanyingInvoiceEntityTypeCode);

        await this.loadJobOrderName();
        await this.loadCustomerName();

        this.DisableDocumentsReload = false;

        await this.loadDdts();
    }

    private installWatches()
    {
        this.DisableDocumentsReload = true;
        this.WithNoJobOrder.subscribe(this.loadDdts.bind(this));
        this.DateFrom.subscribe(this.loadDdts.bind(this));
        this.DateTo.subscribe(() => {
            if(this.DateTo() > this.referenceDate) {
                this.WarningMessages(String.format(ProlifeSdk.TextResources.Invoices.WatchWarning, moment(this.referenceDate).format("L")));
            } else {
                this.WarningMessages(undefined);
            }
            this.loadDdts();
        });
        this.DisableDocumentsReload = false;
    }

    private async loadJobOrderName()
    {
        try
        {
            const jobOrder = await this.jobOrderService.get(this.initializationInfo.JobOrderId)
            this.JobOrderName(jobOrder.Name);
        }
        catch
        {
            this.JobOrderName(TextResources.ProlifeSdk.JobOrderNotFound);
        }
    }

    private async loadCustomerName()
    {
        try
        {
            const customer = await this.customersService.getCustomerById(this.initializationInfo.CustomerId);
            this.CustomerName(customer.FormattedContactName);
        }
        catch
        {
            this.CustomerName(TextResources.ProlifeSdk.CustomerNotFound);
        }
    }

    private async loadDdts()
    {
        if(this.DisableDocumentsReload)
            return;

        const ddts = await this.invoicesService.GetDdtForWizard(
            "",
            this.WithNoJobOrder(), 
            this.initializationInfo.JobOrderId, 
            true, 
            this.initializationInfo.CustomerId, 
            this.DateFrom(), 
            this.DateTo(),  
            0, 
            1000000
        );
        this.ddtsLoaded(ddts);
    }

    private ddtsLoaded(ddts : IDdtForWizard[])
    {
        this.SelectedDocuments([]);
        this.Ddts(ddts.map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(ddt : IDdtForWizard) : Ddt
    {
        return new Ddt(ddt, this, this.initializationInfo.DocumentOriginatingRows, this.ShowAlreadyImported);
    }

    public removeRow(row : any)
    {
        this.Rows.remove(row);
    }

    public expandColumn(colId: number): void {
        this.ExpandedColumn(colId);
    }

    render() {
        let step: DdtDataSource;
        let ddtRow: Ddt;
        let rowItem: DdtRow;
        let outRow: IInvoiceRowForDocuments;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 0px 15px;">
                <div class="row wizard-header">
                    <div class="col-md-7 wizard-header-block">
                        <div class="row">

                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardJobOrder}</label>
                            <div class="col-md-3 form-control-static" data-bind={{text: step.JobOrderName, attr: { title: step.JobOrderName }, tooltip: { placement: 'right' }}}></div>
                            
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardCustomer}</label>
                            <div class="col-md-3 form-control-static" data-bind={{text: step.CustomerName, attr: { title: step.CustomerName }, tooltip: { placement: 'right' }}}></div>

                            <div class="col-md-4 form-control-static">
                                <input type="checkbox" data-bind={{checkbox : step.WithNoJobOrder}}/>
                                {TextResources.Invoices.DocumentWizardSearchDDTWithNoJobOrder}
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                            <div class="col-md-3">
                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateFrom}}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                            <div class="col-md-3">
                                <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateTo}}>
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <ko-bind data-bind={{ if: step.DocumentCurrenciesEnabled }}>
                                <div class="col-md-4" data-bind={{with: step.initializationInfo.DocumentCurrenciesInfo}}>
                                    <document-currencies-popover 
                                        readonly-document-currency={() => "LockDocumentCurrencyChanges"}
                                        readonly-exchange-value={() => "LockExchangeValueChanges"}
                                        readonly-exchange-valueForVat={() => "LockExchangeValueForVatChanges"}
                                        document-currencies={() => "DocumentCurrencies"}
                                        button-css-classes='btn btn-sm blue'
                                        popover-css-classes='currencies-manager-for-modal'
                                        button-text={TextResources.Invoices.CurrenciesLabel}></document-currencies-popover>
                                </div>
                            </ko-bind>
                        </div>
                    </div>
                    <div class="col-md-5 wizard-header-block">
                        <ko-bind data-bind={{ if : step.IsDestinationShippingInvoice }}>
                            <div class="row">
                                <div class="note note-warning">
                                    {TextResources.Invoices.DocumentWizardDDTToAccompanyingInvoiceWarning}
                                </div>
                            </div>
                        </ko-bind>
                        
                        <div class="alert alert-danger" data-bind={{visible: !!step.WarningMessages(), text: step.WarningMessages}}>

                        </div>
                    </div>
                </div>
                    
                <div class="row wizard-body">
                    <div class="wizard-body-column first-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 1, 'collapsed-column': step.ExpandedColumn() != 1 }, click: step.expandColumn.bind(step, 1)}}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                <tr>
                                    <th class="text-center closure-state-col">
                                        <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardClosureStatus}></i>
                                    </th>
                                    <th class="doc-number-col">{TextResources.Invoices.DocumentWizardNumber}</th>
                                    <th class="doc-date-col">{TextResources.Invoices.DocumentWizardDate}</th>
                                    <th class="text-right">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                    <th class="text-right">{TextResources.Invoices.CurrencyLabel}</th>
                                </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        <div class="wizard-data-source-table-body">
                            <div data-bind={{slimScroll: 'auto'}}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                    <ko-bind data-bind={{if : step.Ddts().length == 0}}>
                                        <tr>
                                            <td colSpan={4} class="text-center">{TextResources.Invoices.DocumentWizardNoDDTAvailable}</td>
                                        </tr>
                                    </ko-bind>
                                    <ko-bind data-bind={{ foreach: { data: step.Ddts, as: 'ddtRow' }}}>
                                        <tr data-bind={{click: ddtRow.SwitchSelection, css : { active : ddtRow.IsSelected }}}>
                                            <td class="text-center closure-state-col">
                                                <div class="label label-sm" data-bind={{css : { 'label-warning' : ddtRow.ClosureStatus() == 1, 'label-danger': ddtRow.ClosureStatus() == 0, 'label-success': ddtRow.ClosureStatus() == 2 }, click: ddtRow.openDocument.bind(ddtRow), style: { cursor: 'pointer' }}}>
                                                    <i class="fa fa-file-text" data-bind={`attr : { title :  ClosureStatus() == 1 ? '${TextResources.Invoices.DocumentWizardPartiallyClosed}' : (ClosureStatus() == 0 ? '${TextResources.Invoices.DocumentWizardClosed}' : '${TextResources.Invoices.DocumentWizardOpened}') }`}></i>
                                                </div>
                                            </td>
                                            <td class="doc-number-col" data-bind={{text: ddtRow.Number}}></td>
                                            <td class="doc-date-col" data-bind={{dateText: ddtRow.Date}}></td>
                                            <td class="text-right" data-bind={{moneyText: ddtRow.Total, currencySymbol: ddtRow.CurrencySymbol, documentCurrency: ddtRow.DocumentCurrency}}></td>
                                            <td class="text-right" data-bind={{text: ddtRow.CurrencyCode}}></td>
                                        </tr>
                                    </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="wizard-body-column second-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 2, 'collapsed-column': step.ExpandedColumn() != 2 }, style: { left: (step.ExpandedColumn() == 3 || step.ExpandedColumn() == 2 ? '25%' : '50%') }, click: step.expandColumn.bind(step, 2)}}>
                        <div class="wizard-data-source-table-header">
                            <table class="table table-striped table-bordered table-advance table-hover">
                                <thead>
                                <tr>
                                    <th class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                        <input type="checkbox" data-bind={{checkbox : step.SelectAllRows}} />
                                    </th>
                                    <th class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                        <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                    </th>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th class="text-center closed-amount-col">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmountToImport}</th>
                                    <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                    <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        <div class="wizard-data-source-table-body">
                            <div data-bind={{slimScroll: 'auto'}}>
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead></thead>
                                    <tbody>
                                        <ko-bind data-bind={{ if : step.SelectedDocuments().length == 0 }}>
                                            <tr>
                                                <td colSpan={7} class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{ foreach : { data: step.SelectedDocuments, as: 'ddtRow' }}}>
                                            <ko-bind data-bind={{ foreach : { data: ddtRow.Rows, as: 'rowItem' }}}>
                                                <tr data-bind={{css : { active : rowItem.Selected }}}>
                                                    <td class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                        <input type="checkbox" data-bind={{checkbox : rowItem.Selected}} />
                                                    </td>
                                                    <td class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                        <ko-bind data-bind={{ if : rowItem.AlreadyImportedInThisDocument }}>
                                                            <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                                        </ko-bind>
                                                    </td>
                                                    <td><span data-bind={{text: rowItem.Description}}></span></td>
                                                    <ko-bind data-bind={{ ifnot : rowItem.ManuallyClosed }}>
                                                    <td class="text-right closed-amount-col" data-bind={{numberText: rowItem.ClosedAmount}}></td>
                                                    </ko-bind>
                                                    <ko-bind data-bind={{ if : rowItem.ManuallyClosed }}>
                                                        <td class="text-center closed-amount">
                                                            <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardManuallyClosed}></i>
                                                        </td>
                                                    </ko-bind>
                                                    <td class="text-right amount-col" data-bind={{numberText: rowItem.Amount}}></td>
                                                    <td class="text-right unit-price-col" data-bind={{moneyText: rowItem.NetUnitPrice, currencySymbol: ddtRow.CurrencySymbol, documentCurrency: ddtRow.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                    <td class="text-right unit-price-col" data-bind={{moneyText: rowItem.Total, currencySymbol: ddtRow.CurrencySymbol, documentCurrency: ddtRow.DocumentCurrency}}></td>
                                                </tr>
                                            </ko-bind>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="wizard-body-column third-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 3, 'collapsed-column': step.ExpandedColumn() != 3 }, click: step.expandColumn.bind(step, 3)}}>
                        <div class="text-center" data-bind={{visible: step.ExpandedColumn() == 3}}>
                            <button type="button" class="btn btn-sm btn-primary" data-bind={{click: step.importSelectedRows}}>{TextResources.Invoices.DocumentWizardImportRows}</button>
                            <button type="button" class="btn btn-sm btn-danger" data-bind={{click: step.ClearImportedRows}}>{TextResources.Invoices.DocumentWizardClear}</button>
                        </div>
                        <div class="wizard-body-column-with-actions" data-bind={{style: { top: step.ExpandedColumn() == 3 ? '37px' : '0px', bottom: step.ExpandedColumn() == 3 ? '0px' : '37px' } }}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmount}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                            <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                            <th class="action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardRemove}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if : step.Rows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={5} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach : { data: step.Rows, as: 'outRow' }}}>
                                                <tr>
                                                    <td data-bind={{text: outRow.Descrizione}}></td>
                                                    <td class="text-right amount-col" data-bind={{numberText: outRow.Quantita}}></td>
                                                    <td class="text-right unit-price-col" data-bind={{moneyText: outRow.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                    <td class="text-right total-price-col"  data-bind={{moneyText: outRow.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency}}></td>
                                                    <td class="text-center action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>
                                                        <a href="#" class="btn btn-xs red" data-bind={{click: step.removeRow.bind(step, outRow)}}>
                                                            <i class="fa fa-times"></i>
                                                            {TextResources.Invoices.DocumentWizardRemove}
                                                        </a>
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        , this, "step");
    }
}

class Ddt extends BaseDocumentForWizard<DdtRow>
{
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService : IInvoicesService;

    private rowsLoaded  = false;

    constructor(public ddt : IDdtForWizard, private rowsStorage : DdtDataSource,
                private previouslyImportedRows : IDocumentBuilderDocumentOriginatingRows[], private showAlreadyImportedTrigger : ko.Observable<boolean>)
    {
        super(ddt, ProlifeSdk.DdtEntityTypeCode)
    }

    public SwitchSelection()
    {
        this.IsSelected(!this.IsSelected());

        if(this.IsSelected())
        {
            this.loadRows().then(() => {
                this.rowsStorage.SelectedDocuments.push(this);
            });
        }
        else
            this.rowsStorage.SelectedDocuments.remove(this);
    }

    public loadRows() : Promise<any>
    {
        if(this.rowsLoaded)
            return Promise.resolve([]);

        return this.invoicesService.getDocumentRowsById(this.ddt.Id)
            .then(this.onRowsLoaded.bind(this))
            .catch(() => this.rowsLoaded = false);
    }

    private onRowsLoaded(rows : IInvoiceRow[])
    {
        this.Rows(rows.map(this.createViewModelFor.bind(this)));
        this.rowsLoaded = true;
    }

    private createViewModelFor(row : IInvoiceRow) : DdtRow
    {
        return new DdtRow(row, this.rowsStorage, this.previouslyImportedRows);
    }

    public doImportSelectedRows(result  = true): boolean
    {
        let someRowsAlreadyImported = false;

        if (!result)
            return someRowsAlreadyImported;

        const selectedRows = this.Rows().filter((r : DdtRow) => { return r.Selected(); });
        const rowsToImport = selectedRows.filter((r : DdtRow) => !r.isAlreadyImportedInThisDoc());
        someRowsAlreadyImported = rowsToImport.length != selectedRows.length;

        if (rowsToImport.length > 0) {
            const refRow: IInvoiceRowForDocuments = this.getDocumentReferenceCommentRow();
            if (this.rowsStorage.Rows().filter((r) => r.Descrizione == refRow.Descrizione).length == 0)
                this.rowsStorage.Rows.push(refRow);
        }
        
        rowsToImport.forEach((r : DdtRow) => {
            const row = r.createImportRow();

            const sourceDocumentCurrency = this.DocumentCurrency();

            if (this.rowsStorage.ApplyCurrenciesExchangeValues(row, sourceDocumentCurrency))
                this.rowsStorage.Rows.push(row);
        });

        return someRowsAlreadyImported;
    }

    private getDocumentReferenceCommentRow() : IInvoiceRowForDocuments
    {
        return {
            Descrizione: String.format(ProlifeSdk.TextResources.Invoices.DeliveryReference, this.ddt.Number, moment(this.ddt.Date).format("L")),
            Quantita: 0,
            Importo: 0,
            TotaleRiga: 0,
            NetUnitPrice: 0,
            Rows: [],
            Udm : null,
            Discounts : null,
            NetUnitPriceInDocumentCurrency: 0,
            PriceInDocumentCurrency: 0,
            TotalPriceInDocumentCurrency: 0
        };
    }
}

class DdtRow implements IBaseDocumentRowForWizard
{
    Selected : ko.Observable<boolean> = ko.observable(true);
    RowNumber : ko.Observable<number> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Discounts : ko.Observable<string> = ko.observable();
    Udm : ko.Observable<string> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();

    AlreadyImportedInThisDocument : ko.Observable<boolean> = ko.observable(false);

    constructor(public row : IInvoiceRow, private rowsStorage : DdtDataSource, private destinationDocumentRowsReferences : IDocumentBuilderDocumentOriginatingRows[])
    {
        const amount = Math.max(row.Quantita - row.ClosedAmount, 0);

        this.RowNumber(row.IndiceRiga);
        this.Description(row.Descrizione);
        this.Amount(amount);
        this.UnitPrice(row.PriceInDocumentCurrency); //this.UnitPrice(row.Importo);
        this.Total((amount) * (row.NetUnitPriceInDocumentCurrency || 0)); // this.Total((amount) * (row.NetUnitPrice || 0));
        this.NetUnitPrice(row.NetUnitPriceInDocumentCurrency || 0); // this.NetUnitPrice(row.NetUnitPrice || 0);
        this.Discounts(row.Discounts);
        this.ManuallyClosed(row.ManuallyClosed);
        this.ClosedAmount(row.ClosedAmount);

        this.AlreadyImportedInThisDocument(this.isAlreadyImportedInThisDoc());
    }

    public setAmountAndUpdateTotal(amount: number): void {
        this.Amount(amount);
        this.Total((amount) * (this.row.NetUnitPriceInDocumentCurrency || 0));
    }

    public isAlreadyImportedInThisDoc() : boolean
    {
        return this.wasPreviouslyImportedInThisDocument() || this.wasImportedDuringThisWizard();
    }

    public wasPreviouslyImportedInThisDocument() : boolean {
        return this.destinationDocumentRowsReferences
            .filter((ref : IRefDocumentRow) => ref.SourceEntityKeyId == this.row.IdRigaFattura && ref.SourceEntityType == ProlifeSdk.DdtEntityTypeCode)
            .length > 0;
    }

    public wasAlreadyImportedInADocument() : boolean {
        const sign: number = this.row.Quantita < 0 ? -1 : 1;
        return this.row.ReferencingRows.length > 0 && ((this.row.Quantita - this.row.ClosedAmount) * sign <= 0);
    }

    public wasImportedDuringThisWizard() : boolean {
        return this.rowsStorage.Rows()
            .filter((r : IInvoiceRowForDocuments) => r.Rows.filter((er : IInvoiceRow) => er.IdRigaFattura == this.row.IdRigaFattura && er.EntityType == this.row.EntityType).length > 0)
            .length > 0;
    }

    public createImportRow() : IInvoiceRowForDocuments
    {
        const referencedRow: IInvoiceRow = $.extend(true, {}, this.row);

        referencedRow.Quantita = this.Amount();
        referencedRow.TotaleRiga = this.Total(); // Viene convertito in Euro dal Ddt
        referencedRow.NetUnitPrice = this.NetUnitPrice(); // Viene convertito in Euro dal Ddt
        referencedRow.TotalPriceInDocumentCurrency = this.Total();
        referencedRow.NetUnitPriceInDocumentCurrency = this.NetUnitPrice();

        return {
            Descrizione: this.Description(),
            Quantita: this.Amount(),
            Importo: this.UnitPrice(), // Viene convertito in Euro dal Ddt
            PriceInDocumentCurrency: this.UnitPrice(), // Viene convertito in valuta documento dal Ddt
            TotaleRiga: this.Total(), // Viene convertito in Euro dal Ddt
            TotalPriceInDocumentCurrency: this.Total(), // Viene convertito in valuta documento dal Ddt
            NetUnitPrice: this.NetUnitPrice(), // Viene convertito in Euro dal Ddt
            NetUnitPriceInDocumentCurrency: this.NetUnitPrice(), // Viene convertito in valuta documento dal Ddt
            Rows: [referencedRow],
            Udm : this.Udm(),
            Discounts : this.Discounts(),
            IVA : this.row.IVA,
            FKTipoIva : this.row.FKTipoIva,
            OffsetId: this.row.OffsetId,
            OffsetCode: this.row.OffsetCode
        };
    }
}