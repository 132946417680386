import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import {
    RefConverterBase,
    IRefDocumentRowWithUOM,
    IDocumentInfoForInlineRefProvider,
} from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IWarehousesService } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IArticlesService } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";

export class RowToPurchaseArticleRefConverter extends RefConverterBase {
    @LazyImport(nameof<IWarehousesService>())
    private warehouseService: IWarehousesService;
    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor(activationCode: string, docInfo: IDocumentInfoForInlineRefProvider) {
        super(activationCode, ProlifeSdk.WarehousePurchaseArticleEntityTypeCode, docInfo);
    }

    public async getReference(rowId: number, entity: IEntityRefKey, amount: number): Promise<IRefDocumentRowWithUOM> {
        const ref = this.GetEmptyReference(rowId, entity, amount);

        const sourceWarehouseId = this.DocumentInfo.SourceWarehouseId
            ? this.DocumentInfo.SourceWarehouseId()
            : (await this.warehouseService.getDefaultWarehouse()).Id;

        const article = await this.articlesService.getPurchaseById(entity.EntityKeyId, sourceWarehouseId);

        const useDealerPrice =
            this.documentsService.isPassiveCycleDocument(this.DocumentInfo.DocTypeCode) ||
            this.DocumentInfo.IsWarehouseTransfer();

        const finalPrice = useDealerPrice ? article.dealerPrice : article.customerPrice;

        ref.Discounts = undefined;
        ref.UnitPrice = finalPrice;
        ref.CatalogId = article.catalogId;
        ref.NetUnitPrice = finalPrice;
        ref.NetPrice = ref.Amount * finalPrice;
        ref.WarehouseId = sourceWarehouseId;
        ref.UnitOfMeasure = article.uom;

        return ref;
    }
}
