import { IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { DefaultValuesSettingsUi } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";

export class EstimateDefaultValuesSettingsUi extends DefaultValuesSettingsUi {
    constructor(protected protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);
        this.templateUrl = "invoices/templates/defaultvalues";
        this.templateName = "estimate-default-values";

        this.ShowAbiAndCabFields(false);
    }
}
