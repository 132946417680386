import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IWarehousesService, IWarehouse } from "../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export class WarehouseText
{
    init(element: any, valueAccessor: () => any): void
    {
        WarehouseText.refreshContent(element, ko.utils.unwrapObservable(valueAccessor()));
    }

    update(element: any, valueAccessor: () => any): void
    {
        WarehouseText.refreshContent(element, ko.utils.unwrapObservable(valueAccessor()));
    }

    private static refreshContent(element : any, id : number)
    {
        $(element).text("");

        if(id == null || id == undefined)
            return;

        if(typeof id === "string" && isNaN(parseInt(id))) {
            $(element).text(id);
            return;
        }

        var warehousesService = <IWarehousesService> Core.serviceLocator.findService(ProlifeSdk.WarehousesServiceType);
        warehousesService.getWarehouseById(id)
            .then((warehouse : IWarehouse) => {
                var item = WarehouseText.transformArticle(warehouse);
                $(element).text(item.FullName);
            });
    }

    public static transformArticle(warehouse : IWarehouse) : any {
        return {
            id: warehouse.Id,
            FullName: warehouse.Name,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["warehouseText"] = new WarehouseText();