import { HubConnection } from "@microsoft/signalr";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAuthenticationService } from "../Core/interfaces/IAuthenticationService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IService } from "../Core/interfaces/IService";
import { IUserSession } from "./interfaces/IUserSession";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDesktopService } from "../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    ICartsReallocationObserver,
    IObjectChangesInfo,
} from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import {
    ITrustAuthorizationProcessObserver,
    ITrustAuthorizationRequest,
    ITrustAuthorizationResponse,
} from "../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";

interface IChangesNotificationsServiceObserverWithCodes {
    Observer: IChangesNotificationsServiceObserver;
    EntitiesCodes: string[];
}

interface IAdditionalEventHandler {
    callback: () => void;
    id: number;
}

export class ChangesNotificationsService extends ProLifeService implements IChangesNotificationsService {
    private sessionService: IUserSession;
    private desktopService: IDesktopService;

    private observers: IChangesNotificationsServiceObserverWithCodes[] = [];
    private cartsReallocationObservers: ICartsReallocationObserver[] = [];
    private trustAuthorizationProcessObservers: ITrustAuthorizationProcessObserver[] = [];

    private additionalEventHandlers: { [eventName: string]: IAdditionalEventHandler[] } = {};
    private additionalEventHandlerNextId = 1;

    private notificationsConnection: HubConnection;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.DesktopApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IChangesNotificationsService>(), this);
    }

    public InitializeService(): void {
        super.InitializeService();

        this.sessionService = <IUserSession>this.serviceLocator.findService(ProlifeSdk.UserSessionServiceType);
        this.desktopService = <IDesktopService>this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        this.notificationsConnection = this.desktopService.SignalrConnection.getHubConnection();
        this.notificationsConnection.on("NotifyObjectChanges", this.OnChanges.bind(this));
        this.notificationsConnection.on("NotifyReallocation", this.OnReallocation.bind(this));
        this.notificationsConnection.on("NotifyReallocationError", this.OnReallocationError.bind(this));
        this.notificationsConnection.on("NotifyCartsReallocation", this.OnCartsReallocation.bind(this));
        this.notificationsConnection.on("NotifyTrustAuthorizationRequest", this.OnTrustAuthorizationRequest.bind(this));
        this.notificationsConnection.on(
            "NotifyTrustAuthorizationRequestAbort",
            this.OnTrustAuthorizationRequestAbort.bind(this)
        );
        this.notificationsConnection.on(
            "NotifyTrustAuthorizationResponse",
            this.OnTrustAuthorizationResponse.bind(this)
        );
        this.notificationsConnection.on("NotifyMessage", this.OnNotifyMessage.bind(this));
        this.notificationsConnection.on("disconnect", this.disconnect.bind(this));
    }

    private disconnect() {
        console.log("SignalR Server disconnect requested");
        this.desktopService.SignalrConnection.Disconnect();
    }

    public RegisterEventHandler(eventName: string, callback: (...args: unknown[]) => void): number {
        if (!this.additionalEventHandlers[eventName]) {
            this.additionalEventHandlers[eventName] = [];
            this.notificationsConnection.on(eventName, (...args: unknown[]) => {
                const eventHandlers = this.additionalEventHandlers[eventName];
                eventHandlers.forEach((e: IAdditionalEventHandler) => {
                    e.callback.apply(null, args);
                });
            });
        }

        const handler = {
            id: this.additionalEventHandlerNextId++,
            callback: callback,
        };

        this.additionalEventHandlers[eventName].push(handler);

        return handler.id;
    }

    public UnregisterEventHandler(eventName: string | undefined, eventId: number) {
        if (!eventName) {
            for (const en in this.additionalEventHandlers) {
                if (
                    this.additionalEventHandlers.hasOwnProperty(en) &&
                    Array.isArray(this.additionalEventHandlers[en])
                ) {
                    const existingEvent = this.additionalEventHandlers[en].firstOrDefault((e) => e.id === eventId);
                    if (existingEvent) eventName = en;
                }
            }

            if (!eventName) return;
        }

        if (!this.additionalEventHandlers[eventName]) return;

        const eventHandlers = this.additionalEventHandlers[eventName];
        const stillValidEvents = eventHandlers.filter((e: IAdditionalEventHandler) => e.id != eventId);
        this.additionalEventHandlers[eventName] = stillValidEvents;
    }

    private OnCartsReallocation(teamId: number) {
        this.cartsReallocationObservers.forEach((o: ICartsReallocationObserver) => o.OnCartsReallocation(teamId));
    }

    private OnReallocation() {
        this.cartsReallocationObservers.forEach((o: ICartsReallocationObserver) => o.OnReallocation());
    }

    private OnReallocationError(errorMessage: string) {
        this.cartsReallocationObservers.forEach((o: ICartsReallocationObserver) => o.OnReallocationError(errorMessage));
    }

    public RegisterCartsReallocationObserver(observer: ICartsReallocationObserver) {
        this.cartsReallocationObservers.push(observer);
    }

    public UnregisterCartsReallocationObserver(observer: ICartsReallocationObserver) {
        const index = this.cartsReallocationObservers.indexOf(observer);
        if (index < 0) return;
        this.cartsReallocationObservers.splice(index, 1);
    }

    public RegisterTrustAuthorizationProcessObserver(observer: ITrustAuthorizationProcessObserver) {
        this.trustAuthorizationProcessObservers.push(observer);
    }

    public UnregisterTrustAuthorizationProcessObserver(observer: ITrustAuthorizationProcessObserver) {
        const index = this.trustAuthorizationProcessObservers.indexOf(observer);
        if (index < 0) return;

        this.trustAuthorizationProcessObservers.splice(index, 1);
    }

    public OnTrustAuthorizationRequest(request: ITrustAuthorizationRequest): void {
        this.trustAuthorizationProcessObservers.forEach((o: ITrustAuthorizationProcessObserver) =>
            o.OnTrustAuthorizationRequest(request)
        );
    }

    public OnTrustAuthorizationRequestAbort(requestId: string): void {
        this.trustAuthorizationProcessObservers.forEach((o: ITrustAuthorizationProcessObserver) =>
            o.OnTrustAuthorizationRequestAbort(requestId)
        );
    }

    public OnTrustAuthorizationResponse(response: ITrustAuthorizationResponse): void {
        this.trustAuthorizationProcessObservers.forEach((o: ITrustAuthorizationProcessObserver) =>
            o.OnTrustAuthorizationResponse(response)
        );
    }

    public OnNotifyMessage(message: string, severity: number): void {
        const toastService: IInfoToastService = <IInfoToastService>(
            this.serviceLocator.findService(ServiceTypes.InfoToast)
        );

        switch (severity) {
            case 0:
                toastService.Info(message);
                break;
            case 1:
                toastService.Success(message);
                break;
            case 2:
                toastService.Warning(message);
                break;
            case 3:
                toastService.Error(message);
                break;
        }
    }

    private async OnChanges(serializedChanges: string) {
        const changes: IObjectChangesInfo = JSON.parseWithDate(serializedChanges);
        const desktopService: IDesktopService = <IDesktopService>(
            this.serviceLocator.findService(ProlifeSdk.DesktopServiceType)
        );

        //Skip notifiche utenti di altre compagnie (NB: Sarebbe meglio filtrarle lato server...).
        if ((this.sessionService.GetCurrentCompany() || "").toUpperCase() != (changes.CompanyGuid || "").toUpperCase())
            return;

        const sendByMe: boolean = changes.SenderConnectionId == desktopService.SignalrConnection.Id;
        const entityObservers = this.observers.filter(
            (o: IChangesNotificationsServiceObserverWithCodes) => o.EntitiesCodes.indexOf(changes.EntityCode) > -1
        );

        let hideNotification = false;
        for (const o of entityObservers) {
            const actionResult = await o.Observer.OnEntityHasBeenChanged(changes, sendByMe);
            hideNotification ||= actionResult;
        }

        if (entityObservers.length > 0 && !sendByMe && !hideNotification) this.ShowNotificationMessage(changes);
    }

    private ShowNotificationMessage(changes: IObjectChangesInfo) {
        const toastService: IInfoToastService = <IInfoToastService>(
            this.serviceLocator.findService(ServiceTypes.InfoToast)
        );
        const entitiesService: IEntityProviderService = <IEntityProviderService>(
            this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );

        const entityDescriptor: IEntityDescriptor = entitiesService.GetEntityDescriptor(changes.EntityCode);

        if (!entityDescriptor || [0, 1, 2].indexOf(changes.Action) == -1) return;

        let message = "";
        if (changes.Action == 0)
            message += String.format(
                ProlifeSdk.TextResources.Desktop.UserHasCreated,
                changes.UserFullName,
                entityDescriptor.EntityIsMale
                    ? ProlifeSdk.TextResources.Desktop.ArticleMale
                    : ProlifeSdk.TextResources.Desktop.ArticleFemale,
                entityDescriptor.EntityName
            );
        else if (changes.Action == 1)
            message += String.format(
                ProlifeSdk.TextResources.Desktop.UserHasModified,
                changes.UserFullName,
                entityDescriptor.EntityIsMale
                    ? ProlifeSdk.TextResources.Desktop.ArticleMale
                    : ProlifeSdk.TextResources.Desktop.ArticleFemale,
                entityDescriptor.EntityName
            );
        else if (changes.Action == 2)
            message += String.format(
                ProlifeSdk.TextResources.Desktop.UserHasDeleted,
                changes.UserFullName,
                entityDescriptor.EntityIsMale
                    ? ProlifeSdk.TextResources.Desktop.ArticleMale
                    : ProlifeSdk.TextResources.Desktop.ArticleFemale,
                entityDescriptor.EntityName
            );

        toastService.Info(message);
    }

    public ObserveNotificationsFor(entityTypeCode: string, o: IChangesNotificationsServiceObserver) {
        const matches = this.observers.filter((o1: IChangesNotificationsServiceObserverWithCodes) => {
            return o1.Observer == o;
        });
        const observerWithCodes: IChangesNotificationsServiceObserverWithCodes =
            matches.length > 0 ? matches[0] : { Observer: o, EntitiesCodes: [] };

        if (observerWithCodes.EntitiesCodes.indexOf(entityTypeCode) == -1)
            observerWithCodes.EntitiesCodes.push(entityTypeCode);

        if (this.observers.indexOf(observerWithCodes) == -1) this.observers.push(observerWithCodes);
    }

    public RemoveObserver(o: IChangesNotificationsServiceObserver) {
        const index: number = this.observers
            .map((o1) => {
                return o1.Observer;
            })
            .indexOf(o);
        if (index == -1) return;
        this.observers.splice(index, 1);
    }

    getServiceType(): string {
        return ProlifeSdk.ChangesNotificationsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ChangesNotificationsService(serviceLocator);
}
