import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { If } from "../../../Components/IfIfNotWith";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentClosingStatusIndicator } from "../../../Components/DocumentClosingStatusIndicator";
import { NumericText } from "../../../Components/NumericText";
import { DateTimeText } from "../../../Components/DateTimeText";
import { DocumentResourceAssignment } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";

const styleSheet = jss.createStyleSheet({
    "resource-assignments": {
        paddingBottom: "10px",

        "& .resource-col": {
            width: "200px",
            minWidth: "200px",
        },
    },
});
const { classes } = styleSheet.attach();

export type DocumentResourceDetailedAssignmentExtended = DocumentResourceAssignment & {
    isTotalsRow: boolean;
    isMaxAvailableHoursRow: boolean;
    isResidualRow: boolean;

    label: string;
};

type MonthColumn = {
    month: number;
    year: number;
    label: string;
};

type ResourceAssignmentsTableProps = {
    resourceAssignments: ko.ObservableArray<DocumentResourceDetailedAssignmentExtended>;
    from: ko.MaybeObservable<Date>;
    to: ko.MaybeObservable<Date>;
    title?: ko.MaybeObservable<string>;
    className?: string;
};

export function ResourceAssignmentsTable(props: ResourceAssignmentsTableProps) {
    const C = require("./ResourceAssignmentsTable")._ResourceAssignmentsTable as typeof _ResourceAssignmentsTable;
    return <C {...props} />;
}

export class _ResourceAssignmentsTable {
    static defaultProps: Partial<ResourceAssignmentsTableProps> = {
        title: "",
        className: "",
    };

    private canRender: ko.Observable<boolean> = ko.observable(false);
    private monthsColumns: MonthColumn[] = [];
    private subscriptions: ko.Subscription[] = [];

    constructor(private props: ResourceAssignmentsTableProps) {}

    componentDidMount() {
        this.subscriptions.push(this.props.resourceAssignments.subscribe(this.reDraw.bind(this)));
        if (ko.isSubscribable(this.props.from))
            this.subscriptions.push(this.props.from.subscribe(this.reDraw.bind(this)));
        if (ko.isSubscribable(this.props.to)) this.subscriptions.push(this.props.to.subscribe(this.reDraw.bind(this)));

        this.reDraw();
    }

    private reDraw(): void {
        this.canRender(false);
        this.loadMonthsColumns();
        this.canRender(true);
    }

    private loadMonthsColumns(): void {
        const from = moment(ko.unwrap(this.props.from)).startOf("month").toDate();
        const to = moment(ko.unwrap(this.props.to)).endOf("month").toDate();

        this.monthsColumns = [];
        let current = from;

        while (current <= to) {
            this.monthsColumns.push({
                month: current.getMonth() + 1,
                year: current.getFullYear(),
                label: moment(current).format("MMM"),
            });

            current = moment(current).add(1, "month").toDate();
        }
    }

    render() {
        const rat = this;
        return ComponentUtils.bindTo(
            <If condition={this.canRender}>
                {() => (
                    <div className={classes["resource-assignments"] + " " + this.props.className}>
                        <If
                            condition={() =>
                                ko.isSubscribable(this.props.title) ? this.props.title() : this.props.title
                            }>
                            {() => <h4 data-bind={{ text: rat.props.title }}></h4>}
                        </If>
                        <Table
                            id={ProlifeSdk.DocumentResourceAssignmentDetailsTable}
                            dataSource={Table.defaultDataSource(this.props.resourceAssignments, (res) => ({
                                id: res.id,
                                title: res.label,
                            }))}
                            compact
                            bordered
                            striped
                            fixedLayout
                            rowAs="ra">
                            <Column
                                id={1}
                                title={TextResources.Invoices.DetailedAssignmentsResourceColumn}
                                className="resource-col">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => (
                                        <span
                                            className={ComponentUtils.classNames({
                                                bold: ra.Data.model.isTotalsRow || ra.Data.model.isResidualRow,
                                            })}>
                                            {ra.Data.model.label}
                                        </span>
                                    )}
                                </ColumnBody>
                            </Column>
                            <Column
                                id={2}
                                title={TextResources.Invoices.DetailedAssignmentsDocumentColumn}
                                className="document-col">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => {
                                        return ra.Data.model.document ? (
                                            <DocumentClosingStatusIndicator
                                                documentId={ra.Data.model.document.id}
                                                documentType={ra.Data.model.document.entityType}
                                                protocolId={ra.Data.model.document.fkRegister}
                                                documentState={ra.Data.model.document.fkestimateState}
                                                documentClosingState={ra.Data.model.document.closureStatus}
                                                documentCauseLogicType={ra.Data.model.document.causeLogicType}
                                                className="btn-xs"
                                                content={<span>{ra.Data.model.document.number}</span>}
                                            />
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </ColumnBody>
                            </Column>
                            <Column
                                id={3}
                                title={TextResources.Invoices.DetailedAssignmentsRowNumberColumn}
                                className="row-col text-right">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => {
                                        return ra.Data.model.rowNumber ? (
                                            <NumericText value={ra.Data.model.rowNumber} format="0,0" />
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </ColumnBody>
                            </Column>
                            <Column
                                id={4}
                                title={TextResources.Invoices.DetailedAssignmentsStartDateColumn}
                                className="from-col">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => {
                                        return ra.Data.model.startDate ? (
                                            <DateTimeText dateTime={ra.Data.model.startDate} format="L" />
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </ColumnBody>
                            </Column>
                            <Column
                                id={5}
                                title={TextResources.Invoices.DetailedAssignmentsEndDateColumn}
                                className="to-col">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => {
                                        return ra.Data.model.endDate ? (
                                            <DateTimeText dateTime={ra.Data.model.endDate} format="L" />
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </ColumnBody>
                            </Column>
                            <Column
                                id={6}
                                title={TextResources.Invoices.DetailedAssignmentsHoursAmountColumn}
                                className="total-col text-right">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => {
                                        return !ra.Data.model.isResidualRow && !ra.Data.model.isMaxAvailableHoursRow ? (
                                            <NumericText
                                                value={ra.Data.model.hoursAmount}
                                                format="0,0[.][00]"
                                                className={ComponentUtils.classNames({
                                                    bold: ra.Data.model.isTotalsRow || ra.Data.model.isResidualRow,
                                                })}
                                            />
                                        ) : (
                                            <></>
                                        );
                                    }}
                                </ColumnBody>
                            </Column>
                            {this.monthsColumns.map((mc, i) => (
                                <Column id={-i} title={mc.label} className="month-col text-right">
                                    <ColumnBody>
                                        {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => (
                                            <NumericText
                                                value={
                                                    ra.Data.model.hoursMonthsDistribution.firstOrDefault(
                                                        (h) => h.month === mc.month && h.year === mc.year
                                                    )?.hours
                                                }
                                                format="0,0[.][00]"
                                                className={ComponentUtils.classNames({
                                                    bold: ra.Data.model.isTotalsRow || ra.Data.model.isResidualRow,
                                                })}
                                            />
                                        )}
                                    </ColumnBody>
                                </Column>
                            ))}
                            <Column
                                id={7}
                                title={TextResources.Invoices.DetailedAssignmentsHoursInDetailsPeriodAmountColumn}
                                className="period-total-col text-right">
                                <ColumnBody>
                                    {(ra: ITableItem<DocumentResourceDetailedAssignmentExtended>) => (
                                        <NumericText
                                            value={ra.Data.model.totalAssignedHoursInDetailsPeriod}
                                            format="0,0[.][00]"
                                            className={ComponentUtils.classNames({
                                                bold: ra.Data.model.isTotalsRow || ra.Data.model.isResidualRow,
                                            })}
                                        />
                                    )}
                                </ColumnBody>
                            </Column>
                        </Table>
                    </div>
                )}
            </If>,
            this,
            "rat"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ResourceAssignmentsTable);
}
