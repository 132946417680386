import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 13/09/2018
 * Time: 10:35
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IExpireModes, IExpiryExtension } from "../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";

export class ExpiriesExtensionsDialog implements IDialog {
    public templateName: string = "expiries-extensions-dialog";
    public templateUrl: string = "invoices/templates/settings";
    public title: string = ProlifeSdk.TextResources.Invoices.ExpiriesExtensionsDialogTitle;
    public modal: { close: (result?: any) => void; };

    public ExpiriesExtensions: ko.ObservableArray<ExpiryExtension> = ko.observableArray([]);
    public NewExtensionDate: ko.Observable<Date> = ko.observable();
    public NewExtensionValue: ko.Observable<number> = ko.observable();

    public Date: ko.Observable<Date> = ko.observable();

    public InvalidDate: ko.Computed<boolean>;
    public InvalidValue: ko.Computed<boolean>;

    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private expiriesManager: IExpireModes) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.ExpiriesExtensions(this.expiriesManager.getExpiriesExtensions().map((e: IExpiryExtension) => new ExpiryExtension(e, this)));

        this.InvalidDate = ko.computed(() => {
            return !this.NewExtensionDate();
        });

        this.InvalidValue = ko.computed(() => {
            return !this.NewExtensionValue() || this.NewExtensionValue() < 0;
        });
    }

    public close(): void {
        this.modal.close();
    }

    public action(): void {
        var isValid: boolean = true;

        this.ExpiriesExtensions().forEach((e: ExpiryExtension) => isValid = isValid && e.IsValid());
        if (!isValid) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ExpiriesExtensionsDialogError);
            return;
        }

        var extensions: IExpiryExtension[] = this.ExpiriesExtensions().map((e: ExpiryExtension) => e.GetData());
        this.expiriesManager.createOrUpdateExpiriesExtensions(extensions)
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ExpiriesExtensionSaved);
                this.modal.close();
            })
            .catch(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ExpiriesExtensionSavingError);
            });
    }

    public Show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this);
    }

    public AddExtension(): void {
        if (!this.NewExtensionValue() || !this.NewExtensionDate())
            return;

        var newExtension: IExpiryExtension = {
            Id: -1,
            ExtensionDate: this.NewExtensionDate(),
            ExtensionValue: this.NewExtensionValue()
        };

        this.ExpiriesExtensions.push(new ExpiryExtension(newExtension, this));
        this.NewExtensionDate(null);
        this.NewExtensionValue(null);
    }

    public RemoveExtension(extension: ExpiryExtension): void {
        this.ExpiriesExtensions.remove(extension);
    }
}

class ExpiryExtension {
    public ExtensionDate: ko.Observable<Date> = ko.observable();
    public ExtensionValue: ko.Observable<number> = ko.observable();
    public IsValid: ko.Computed<boolean>;

    public InvalidDate: ko.Computed<boolean>;
    public InvalidValue: ko.Computed<boolean>;

    constructor(private extension: IExpiryExtension, private editor: ExpiriesExtensionsDialog) {
        this.ExtensionDate(this.extension.ExtensionDate);
        this.ExtensionValue(this.extension.ExtensionValue);

        this.IsValid = ko.computed(() => {
            return this.ExtensionValue() > 0 && !!this.ExtensionDate();
        });

        this.InvalidDate = ko.computed(() => {
            return !this.ExtensionDate();
        });

        this.InvalidValue = ko.computed(() => {
            return !this.ExtensionValue() || this.ExtensionValue() <= 0;
        });
    }

    public Delete(): void {
        this.editor.RemoveExtension(this);
    }

    public GetData(): IExpiryExtension {
        return {
            Id: this.extension.Id,
            ExtensionDate: this.ExtensionDate(),
            ExtensionValue: this.ExtensionValue()
        };
    }
}