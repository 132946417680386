import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import { BasePagedList } from "../Utilities/BasePagedList";
import { ArticleWarehousesStockedInDialog } from "../Articles/ArticleWarehousesStockedInDialog";
import { ArticleDetailsDialog } from "../Articles/ArticleDetailsDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import {
    IArticlePurchaseWithStock,
    IArticlesService,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import jss from "jss";
import { Layout } from "../../../../Components/Layouts";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

const styleSheet = jss.createStyleSheet({
    purchasesStockManagement: {
        "& table.table > thead": {
            "& > tr": {
                position: "sticky",
                top: 0,

                "& > th": {
                    fontSize: "0.9em",
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type PurchasesStockManagementProps = {
    searchFilter: ko.Observable<string>;
    warehouseId: ko.Observable<number>;
    customerId: ko.Observable<number>;
    showAllMoved: ko.Observable<boolean>;
    forwardRef?: (list: BasePagedList) => void;
};

export function PurchasesStockManagement(props: PurchasesStockManagementProps) {
    const C = require("./PurchasesStockManagement")._PurchasesStockManagement as typeof _PurchasesStockManagement;
    return <C {...props} />;
}

export class _PurchasesStockManagement extends BasePagedList {
    static defaultProps = {};

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;

    constructor(public props: PurchasesStockManagementProps) {
        super(props.searchFilter);

        this.props.warehouseId.subscribe(() => this.loadRows());
        this.props.showAllMoved.subscribe(() => this.loadRows());

        if (this.props.forwardRef) this.props.forwardRef(this);
    }

    componentDidMount() {
        this.loadRows();
    }

    /*public ShowArticleInfo(article : Article)
    {
        const dialog = new CreateNewArticleDialog(true);
        dialog.SetWarehouse(this.props.warehouseId());
        dialog.loadAndShow(article.getData());
    }*/

    public ShowPurchaseDetails(article: IArticlePurchaseWithStock) {
        const dialog = new ArticleDetailsDialog(
            article.articleId,
            article.code,
            article.description,
            this.props.customerId(),
            this.props.warehouseId()
        );
        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    public ExportExcel() {
        const url =
            "Warehouse/StockReport/GenerateExcel?warehouseId=" +
            this.props.warehouseId() +
            "&showOnlyStocked=" +
            this.props.showAllMoved() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public Print() {
        const url =
            "Warehouse/StockReport/GeneratePdf?warehouseId=" +
            this.props.warehouseId() +
            "&showOnlyStocked=" +
            this.props.showAllMoved() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public ShowWhereIsStocked(article: IArticlePurchaseWithStock) {
        const whereIsStockedDialog = new ArticleWarehousesStockedInDialog(article.articleId);
        this.dialogService.ShowModal<void>(whereIsStockedDialog, "", { noPrompt: true });
    }

    public async fetchRows(): Promise<IArticlePurchaseWithStock[]> {
        if (!this.props.warehouseId()) return [];

        return await this.articlesService.searchPurchasesStock(
            this.searchFilter(),
            this.props.warehouseId(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage,
            this.props.showAllMoved()
        );
    }

    public async fetchRowsCount(): Promise<number> {
        if (!this.props.warehouseId()) return 0;

        return this.articlesService.countPurchasesStock(
            this.searchFilter(),
            this.props.warehouseId(),
            this.props.showAllMoved()
        );
    }

    public createViewModelFor(stockArticle: IArticlePurchaseWithStock): IArticlePurchaseWithStock {
        return stockArticle;
    }

    render() {
        const psm = this;
        let page: number;
        let row: IArticlePurchaseWithStock;

        return ComponentUtils.bindTo(
            <>
                <Layout.ScrollContainer
                    systemScrollable
                    verticalOnly
                    className={classes.purchasesStockManagement}
                    style={{
                        position: "absolute",
                        top: "120px",
                        bottom: "60px",
                        left: "20px",
                        right: "20px",
                        overflowY: "auto",
                    }}
                >
                    <table className="table table-advance">
                        <thead>
                            <tr>
                                <th>Tipologia</th>
                                <th>Descrizione</th>
                                <th className="text-right">Q.ta Libera</th>
                                <th className="text-right">Quantit&agrave;</th>
                                <th>UdM</th>
                                <th className="text-right">Totale</th>
                                <th className="text-right">Prezzo</th>
                                <th className="text-right">Ordinati</th>
                                <th className="text-right">Riservati</th>
                                <th className="text-right">Tot. Riservati</th>
                                {/* <th className="text-right">Min.Qt.</th> */}
                                {/* <th className="text-right">Posizione</th> */}
                                {/* <th></th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ko-bind data-bind={{ if: psm.Loading }}>
                                <tr>
                                    <td colSpan={11} className="text-center">
                                        <h3>Caricamento acquisti in corso...</h3>
                                    </td>
                                </tr>
                            </ko-bind>
                            <ko-bind data-bind={{ foreach: { data: psm.rows, as: "row" } }}>
                                <tr>
                                    <td data-bind={{ text: row.purchaseType }}></td>
                                    <td data-bind={{ text: row.description }}></td>
                                    <td
                                        className="text-right"
                                        data-bind={{ numberText: (row.stockAmount ?? 0) - (row.reservations ?? 0) }}
                                    ></td>
                                    <td className="text-right" data-bind={{ numberText: row.stockAmount }}></td>
                                    <td data-bind={{ text: row.uom }}></td>
                                    <td className="text-right" data-bind={{ numberText: row.totalStockAmount }}></td>
                                    <td className="text-right" data-bind={{ moneyText: row.customerPrice }}></td>
                                    <td className="text-right" data-bind={{ numberText: row.orders }}></td>
                                    <td className="text-right" data-bind={{ numberText: row.reservations }}></td>
                                    <td className="text-right" data-bind={{ numberText: row.totalReservations }}></td>
                                    {/* <td className="text-right" data-bind="numberText: MinimumAmount"></td> */}
                                    {/* <td className="text-right" data-bind="text: WarehousePosition"></td> */}
                                    {/* <td style="width : 30px; text-align : center">
                                            <i className="fa" data-bind="css : { 'fa-flag' : State() == 1, 'fa-warning' : State() == 3, 'fa-ban': State() == 9 }"></i>
                                        </td> */}
                                    <td style="text-align:right; white-space: nowrap">
                                        {/* <a href="#" className="btn btn-xs article-status-on-warehouse default" data-bind="attr : { 'status-for-warehouse' : StockStatus }, click : $parent.ShowStatusLegend.bind($parent)" title="Stato"><i className="fa fa-cubes"></i></a> */}
                                        <a
                                            href="#"
                                            className="btn btn-xs btn-primary"
                                            data-bind={{ click: psm.ShowWhereIsStocked.bind(psm, row) }}
                                            title="Magazzini su cui l'acquisto è stato movimentato"
                                        >
                                            <i className="fa fa-database"></i>
                                        </a>
                                        <a
                                            href="#"
                                            className="btn btn-xs btn-primary"
                                            data-bind={{ click: psm.ShowPurchaseDetails.bind(psm) }}
                                            title="Documenti che utilizzano l'acquisto"
                                        >
                                            <i className="fa fa-eye"></i>
                                        </a>
                                        {/* <a href="#" className="btn btn-xs default" data-bind="click : $parent.ShowArticleInfo.bind($parent, $data)" title="Dettagli articolo"><i className="fa fa-info-circle"></i></a> */}
                                    </td>
                                </tr>
                            </ko-bind>
                        </tbody>
                    </table>
                </Layout.ScrollContainer>

                <div style={{ position: "absolute", bottom: 0, left: "20px" }}>
                    <ul className="pagination">
                        <li data-bind={{ css: { disabled: psm.isOnFirstPage() || psm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: psm.goToPrevPage }}>
                                &larr; Prec
                            </a>
                        </li>

                        <ko-bind data-bind={{ foreach: { data: psm.pages, as: "page" } }}>
                            <li
                                data-bind={{
                                    css: { disabled: psm.SomeFilterIsChanged(), active: psm.selectedPage() == page },
                                }}
                            >
                                <a href="#" data-bind={{ text: page + 1, click: psm.goToPage.bind(psm, page) }}></a>
                            </li>
                        </ko-bind>

                        <li data-bind={{ css: { disabled: psm.isOnLastPage() || psm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: psm.goToNextPage }}>
                                Succ &rarr;
                            </a>
                        </li>
                    </ul>
                </div>
            </>,
            this,
            "psm"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PurchasesStockManagement);
}
