import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { INavigationMenuComponentDataSource, INavigationMenuComponentModel } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";
import { IAjaxService } from "../Core/interfaces/IAjaxService";

export interface IPurchasesDataSource extends INavigationMenuComponentDataSource {

}

export interface IPurchasesDataSourceModel extends INavigationMenuComponentModel {
    id: number;
    model: IPurchaseSuggestion;
}

export interface IPurchaseSuggestion {
    Id: number;
    Title: string;
    UnitPrice: number;
    Discount: string;
    NetUnitPrice: number;

    JobOrderId: number;
    JobOrderName: string;
    PurchaseDate: Date;
    PurchaseTypeId: number;
    PurchaseTypeName: string;
}

export class PurchasesDataSource implements IPurchasesDataSource {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    private getCosts: boolean = true;
    private getRevenues: boolean = true;

    constructor() {
    }

    setView(view: IDataSourceView): void {
        
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }   

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Desktop.Purchases;
    }

    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IPurchasesDataSourceModel, b: IPurchasesDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    setFilter(getCosts: boolean, getRevenues: boolean) {
        this.getCosts = getCosts;
        this.getRevenues = getRevenues;
    }

    async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<IPurchasesDataSourceModel[]> {
        let purchases = await this.ajaxService.Post<IPurchaseSuggestion[]>("Blog-api/Purchases", "SuggestByDescription", {
            methodData: {
                Filter: textFilter,
                Skip: skip,
                Count: count,
                GetCosts: this.getCosts,
                GetRevenues: this.getRevenues
            },
            background: true
        });

        return this.createModelsFor(purchases);
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IPurchasesDataSourceModel[]> {
        let purchases = await this.ajaxService.Post<IPurchaseSuggestion[]>("Blog-api/Purchases", "SuggestByIds", {
            methodData: {
                Ids: ids
            },
            background: true
        });

        return this.createModelsFor(purchases);
    }

    private createModelsFor(purchaseTypes : IPurchaseSuggestion[]) : IPurchasesDataSourceModel[] {
        return purchaseTypes.map<IPurchasesDataSourceModel>(pt => {
            return {
                id: pt.Id,
                isGroup: false,
                isLeaf: true,
                title: pt.Title,
                subTitle: `${pt.JobOrderName} - ${pt.PurchaseTypeName} - ${numeral(pt.UnitPrice).format("0,000.00 €")}`,
                model: pt
            };
        });
    }

    public getSupportedDropMimeTypes() : string[] {
        return [];
    }
}