import * as ko from "knockout";
import * as Core from "../../../Core/Core";
import * as React from "@abstraqt-dev/jsxknockout";
import { TrustAuthorizationRequestsManager } from "../../../Desktop/DesktopService";
import jss from "jss";
import moment = require("moment");
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";

const { classes } = jss.createStyleSheet({
    trustAuthorizationHeader: {
        display: 'inherit',
        listStyle: 'none',
        paddingLeft: 0,

        '& > li > a': {
            display: 'block',
            clear: 'both',
            fontWeight: 300,
            lineHeight: '20px',
            whiteSpace: 'normal',
            fontSize: '13px',
            padding: '10px',
            backgroundColor: '#ffffff',
            borderBottom: '1px solid #f4f4f4 !important',
            textShadow: 'none',
            color: '#666',
            cursor: 'default',

            '&:hover': {
                textDecoration: 'none'
            },

            '& > .task': {
                marginBottom: '5px',

                '& > .desc': {
                    fontSize: '13px',
                    fontWeight: '300',
                },

                '& > .percent': {
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: "'Open Sans', sans-serif",
                    float: 'right',
                    display: 'inline-block'
                }
            },

            '& > .progress': {
                display: 'block',
                height: '11px',
                margin: '0px',
            }
        }
    }
}).attach();

ko.components.register("trust-authorization-header", {
    viewModel: {
        createViewModel: (params: any, componentInfo: ko.components.ComponentInfo) => {
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div data-bind="slimScroll: '330px'">
                    <div style="background-color: #EEE; padding: 5px;" data-bind="visible: RequestsOnEvalNumber() == 0 && PendingRequestsNumber() == 0">
                        Nessuna richiesta di autorizzazione fido presente
                    </div>
                    <div style="background-color: #EEE; padding: 5px;" data-bind="visible: RequestsOnEvalNumber">
                        Hai <span data-bind="text: RequestsOnEvalNumber"></span> richieste di autorizzazione fido in attesa di risposta
                    </div>
                    <div data-bind="visible: RequestsOnEvalNumber() > 0">
                        <ul class={classes.trustAuthorizationHeader + " dropdown-menu-list"} style="height: 250px" data-bind="foreach: RequestsOnEval">
                            <li data-bind="click: Open">
                                <span style="border-top: 1px solid white; display: block; clear: both; font-weight: 300; line-height: 20px; white-space: normal; font-size: 13px; padding: 10px; background-color: #ffffff; border-bottom: 1px solid #f4f4f4 !important; text-shadow: none; color: #666;">
                                    <span class="task">
                                        <span class="desc" data-bind="text: RequestDate"></span>
                                        <br />
                                        <span class="desc">Richiesta da: 
                                            <strong>
                                                <span data-bind="text: ApplicantResource"></span>
                                            </strong>
                                        </span>
                                        <br />
                                        <span class="desc">Documento: 
                                            <span data-bind="text: Document"></span> di importo 
                                            <span data-bind="numberText: TotalPrice"></span> €
                                        </span>
                                        <br />
                                        <span class="desc">Cliente: 
                                            <a style="display: inline; background-color: transparent; border: 0 !important; padding: 0;" href="#" data-bind="attr: { href: CustomerLink, target: '_blank' }, click: function() { return true; }, clickBubble: false">
                                                <span data-bind="text: Customer"></span>
                                            </a>
                                        </span>
                                        <ko-if data-bind="!!JobOrder">
                                            <br />
                                            <span class="desc">Commessa: <a style="display: inline; background-color: transparent; border: 0 !important; padding: 0;" href="#" data-bind="attr: { href: JobOrderLink, target: '_blank' }, click: function() { return true; }, clickBubble: false"><span data-bind="text: JobOrder"></span></a></span>
                                        </ko-if>
                                        <br />
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div style="background-color: #EEE; padding: 5px;" data-bind="visible: PendingRequestsNumber">
                        <span data-bind="text: PendingRequestsSentByMe() ? 'Hai' : 'Ci sono'"></span>&nbsp;
                        <span data-bind="text: PendingRequestsNumber"></span>&nbsp;
                        richieste di autorizzazione fido da gestire
                    </div>
                    <div data-bind="visible: PendingRequestsNumber() > 0">
                        <ul class={classes.trustAuthorizationHeader + " dropdown-menu-list"} style="height: 250px" data-bind="foreach: PendingRequests">
                            <li data-bind="click: Open">
                                <span style="border-top: 1px solid white; display: block; clear: both; font-weight: 300; line-height: 20px; white-space: normal; font-size: 13px; padding: 10px; background-color: #ffffff; border-bottom: 1px solid #f4f4f4 !important; text-shadow: none; color: #666;">
                                    <span class="task">
                                        <span class="desc" data-bind="text: RequestDate"></span><br />
                                        <span class="desc">Richiesta da: <strong><span data-bind="text: ApplicantResource"></span></strong></span><br />
                                        <span class="desc">Documento: <span data-bind="text: Document"></span> di importo <span data-bind="numberText: TotalPrice"></span> €</span><br />
                                        <span class="desc">Cliente: <a style="display: inline; background-color: transparent; border: 0 !important; padding: 0;" href="#" data-bind="attr: { href: CustomerLink, target: '_blank' }, click: function() { return true; }, clickBubble: false"><span data-bind="text: Customer"></span></a></span>
                                        <ko-if data-bind="!!JobOrder">
                                            <br />
                                            <span class="desc">Commessa: <a style="display: inline; background-color: transparent; border: 0 !important; padding: 0;" href="#" data-bind="attr: { href: JobOrderLink, target: '_blank' }, click: function() { return true; }, clickBubble: false"><span data-bind="text: JobOrder"></span></a></span>
                                        </ko-if>
                                        <br />
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            ]);
            
            let desktopService : IDesktopService = Core.serviceLocator.findService(nameof<IDesktopService>());
            let vm : TrustAuthorizationRequestsManager = desktopService.GetTrustAuthorizationRequestsVM();

            //Aggiungere per debug
            /*vm.AddPendingRequests([{
                Id: "1",
                ApplicantResourceName: "ApplicantResource1",
                ResourceName: "Resource",
                VatRegisterDocumentLabel: "DocLabel",
                TotalPrice: 100,
                CustomerName: "Customer Name",
                JobOrderName: "JobOrder Name",
                RequestDate: new Date(),
                State: 1,
                CustomerId: 1,
                JobOrderId: 1034,
                DocumentEntityTypeCode: "DOC",
                DocumentId: 1,
                ImportedRowsTotalPrice: 100,
                VatRegisterId: 1,
                VatRegisterName: "Registro",
            }]);*/

            return vm;
        },
    },
    template: []
});