/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 09/10/2017
 * Time: 11:54
 * To change this template use File | Settings | File Templates.
 */
import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { AllocationsGanttForTeam } from "./AllocationsGanttForTeam";
import { ResourcesAndGroupsManager } from "../../../Agenda/Agenda/ui/ResourcesAndGroupsManager";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDayNavigationMenuComponentModel } from "../../../WorkedHours/workedhours/ui2019/navigation/DateDataSource";
import { ManualAllocationsEditor, IManualAllocationsEditor, IManualAllocationViewModel } from "./ManualAllocationsEditor";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { ManualAllocationsDateDataSource } from "../../../DataSources/ManualAllocationsDatesDataSource";
import { AllocationsMenuDataSource, IJobOrderForAllocationsDataSourceModel, IWorkflowForAllocationsDataSourceModel } from "../../../DataSources/AllocationsMenuDataSource";
import { CartWorkableHoursCalculationModes } from "../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { TextResources } from "../../../ProlifeSdk/ProlifeSdk";
import { AllocationPriority } from "../enums/AllocationPriority";
import { AllocationStartType } from "../enums/AllocationStartType";
import { AllocationMode } from "../enums/AllocationMode";
import { UiUtilities } from "../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { INavigationMenuComponent, INavigationMenuComponentActionsGroup, INavigationMenuComponentAction, INavigationMenuComponentActionSeparator, INavigationMenuComponentModel } from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IAllocationsService, ICartInfoDialogConfiguration, ITeamWorkProgressInfo, IAllocatedCartForList, IAllocatedHoursForRoles, IOpUnitWorkableHours, ILastAllocationDate, ICartWorkableHoursCalculationMode, IAllocatedCart, IMissingRole, ITeam, ICartForAllocation } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ICartLink, ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDraggedTask, IDraggedWorkflow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICartsReallocationObserver, IChangesNotificationsService } from "../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { ICartsStatesSettingManager } from "../../../ProlifeSdk/interfaces/allocations/ICartsStatesSettingManager";
import { ICartRoleInfo, ICartContentWithRolesInfo, ICartContentRoleInfo } from "../../../ProlifeSdk/interfaces/allocations/ICart";
import { IAllocationType } from "../../interfaces/IAllocationType";
import { AlertsLegendUI } from "../../../Components/AlertsLegend";
import { WorkflowsMenuSearchModes } from "../../../Todolist/Todolist/enums/WorkflowsMenuSearchModes";
import { CartLinksSelectorUI } from "./dialogs/editCartDialog/CartLinks";
import { IValidationException } from "../../../Core/interfaces/IException";
import { ActivityBacklogOption, ActivityBacklogOptions } from "./ActivityBacklogOption";

export class CartInfoDialog implements ICartsReallocationObserver, IDataSourceListener, IDialog {
    public templateName = "cart-info-dialog";
    public templateUrl = "allocations/templates/dialogs";
    public title: string;

    public modal: { close: (result?: any) => void; };
    public AllocationsGanttForTeam: AllocationsGanttForTeam;
    
    public Carts: ko.ObservableArray<AllocatedCartForList> = ko.observableArray([]);
    public SelectedCart: ko.Observable<CartAllocationsViewModel> = ko.observable();
    public TeamWorkProgressInfo: ko.Observable<any> = ko.observable();
    public NoCarts: ko.Computed<boolean>;

    public AllocationsMenuDataSource: AllocationsMenuDataSource;
    public JobOrdersMenu: ko.Observable<INavigationMenuComponent> = ko.observable();

    private ShowingWorkflows: ko.Observable<boolean> = ko.observable(false);
    private ShowingTasks: ko.Observable<boolean> = ko.observable(false);
    private selectedJobOrder: number = null;
    private selectedWorkflow: number = null;

    //---- Observables per gestione interfaccia grafica
    public SettingsLoaded: ko.Observable<boolean> = ko.observable(false);

    public CartsListCollapsed: ko.Observable<boolean> = ko.observable(false);
    public GanttCollapsed: ko.Observable<boolean> = ko.observable(false);

    public AllocationDataOpened: ko.Observable<boolean> = ko.observable(false);
    public ManualAllocationsOpened: ko.Observable<boolean> = ko.observable(false);
    public CartContentOpened: ko.Observable<boolean> = ko.observable(false);

    public CartDataScroller: ko.Observable<boolean> = ko.observable(false);
    public AllocationDataScroller: ko.Observable<boolean> = ko.observable(false);
    public ManualAllocationsScroller: ko.Observable<boolean> = ko.observable(false);
    public CartContentScroller: ko.Observable<boolean> = ko.observable(false);
    //----

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.CartStatus)
    private cartsStateManager: ICartsStatesSettingManager;

    constructor(private serviceLocator: IServiceLocator, private teamId: number, private teamName: string, selectedCart: number) {
        this.title = teamName;

        this.AllocationsMenuDataSource = new AllocationsMenuDataSource();
        this.JobOrdersMenu.subscribe((navigationMenu) => {
            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            }

            navigationMenu.registerAction(showLegendAction);

            const action: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-filter",
                text: "",
                activeClass: "red",
                defaultClass: "btn-transparent",
                active: () => this.AllocationsMenuDataSource.AdvancedFilterIsActive(),
                canExecute: () => true,
                action: () => {
                    this.AllocationsMenuDataSource.editAdvancedFilters();
                },
            }
            
            navigationMenu.registerAction(action);

            const workflowActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingWorkflows(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow")) {
                const insertWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflow();
                    }
                }

                workflowActions.actions.push(insertWorkflowAction);
            }
            
            if (this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow")) {
                const cloneWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromWorkflow();
                    }
                }

                workflowActions.actions.push(cloneWorkflowAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")) {
                const copyWorkflowAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => true,
                    action: () => {
                        this.CreateNewWorkflowFromTemplate();
                    }
                }

                workflowActions.actions.push(copyWorkflowAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow")) {
                const showingSecondaryActions = ko.observable(false)
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: ProlifeSdk.TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.JobOrdersMenu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: ProlifeSdk.TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingWorkflows(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.JobOrdersMenu().showSecondaryAction(false)
                    }
                };

                if (workflowActions.actions.length > 0) {
                    workflowActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                workflowActions.actions.push(editingAction);
                workflowActions.actions.push(endEditingAction);
            }

            if (workflowActions.actions.length > 0)
                navigationMenu.registerAction(workflowActions);

            const tasksActions : INavigationMenuComponentActionsGroup = {
                icon: "fa fa-pencil",
                isGroup: true,
                isSeparator: false,
                visible: () => this.ShowingTasks(),
                actions: []
            };

            if (this.authorizationsService.isAuthorized("TaskBoard_InsertTask")) {
                const insertTaskAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewTask,
                    visible: () => this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        this.CreateTask();
                    }
                };

                tasksActions.actions.push(insertTaskAction);
            }

            if (this.authorizationsService.isAuthorized("TaskBoard_EditTask")) {
                const showingSecondaryActions = ko.observable(false)
                const editingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-pencil",
                    text: ProlifeSdk.TextResources.Todolist.Edit,
                    visible: () => !showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.JobOrdersMenu().showSecondaryAction(true)
                    }
                };
                
                const endEditingAction: INavigationMenuComponentAction = {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-undo",
                    text: ProlifeSdk.TextResources.Todolist.EndEditing,
                    visible: () => showingSecondaryActions() && this.ShowingTasks(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.JobOrdersMenu().showSecondaryAction(false)
                    }
                };

                if (tasksActions.actions.length > 0) {
                    tasksActions.actions.push({ isSeparator: true } as INavigationMenuComponentActionSeparator)
                }

                tasksActions.actions.push(editingAction);
                tasksActions.actions.push(endEditingAction);
            }

            if (tasksActions.actions.length > 0)
                navigationMenu.registerAction(tasksActions);

            navigationMenu.registerSearchAction({
                text: "Cerca piano",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchWorkflows);
                    if (this.JobOrdersMenu().getTextFilter())
                        this.JobOrdersMenu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                isDefault: true,
                visible: () => this.ShowingWorkflows()
            });

            navigationMenu.registerSearchAction({
                text: "Cerca attività",
                action: () => {
                    this.AllocationsMenuDataSource.setWorkflowsSearchMode(WorkflowsMenuSearchModes.SearchActivitiesInWorkflows);
                    if (this.JobOrdersMenu().getTextFilter())
                        this.JobOrdersMenu().refresh();
                },
                icon: "",
                isGroup: false,
                isSeparator: false,
                visible: () => this.ShowingWorkflows()
            })
        });

        this.changesNotificationsService.RegisterCartsReallocationObserver(this);

        this.NoCarts = ko.computed(() => {
            return this.Carts().length == 0;
        });

        this.AllocationsGanttForTeam = new AllocationsGanttForTeam(this.serviceLocator, this.teamId, this.teamName);

        this.CartsListCollapsed.subscribe((value: boolean) => {
            if (value)
                return;

            this.LoadTeamWorkProgress();
        });

        this.CartContentOpened.subscribe((value: boolean) => {
            if (!value)
                return;

            this.RefreshNavigationMenu();
        });

        this.GanttCollapsed.subscribe((value: boolean) => {
            if (!value)
                this.AllocationsGanttForTeam.UpdateTeam(this.teamId);
        });

        this.LoadTeamWorkProgress();
        this.LoadCarts(selectedCart);
        this.loadInterfaceSettings();

        setTimeout(() => {
            this.allocationsService.triggerNextTutorialStep("team-details-opened");
        }, 0);
    }
    
    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this, "fullscreen minheight800", null, this.templateUrl, this.templateName);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }
    
    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }
    
    onNavigate(sender: IDataSource, ...history: INavigationMenuComponentModel<string | number, any>[]) {
        if (history.length === 0) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(false);

            this.selectedJobOrder = null;
            this.selectedWorkflow = null;

            return;
        }

        const model = history[history.length - 1];

        const jobOrderModel = model as IJobOrderForAllocationsDataSourceModel;
        if (jobOrderModel && jobOrderModel.isJobOrder) {
            this.ShowingWorkflows(true);
            this.ShowingTasks(false);

            this.selectedJobOrder = jobOrderModel.id;

            return;
        }

        const workflowModel = model as IWorkflowForAllocationsDataSourceModel;
        if (workflowModel && workflowModel.isWorkflow) {
            this.ShowingWorkflows(false);
            this.ShowingTasks(true);

            this.selectedWorkflow = workflowModel.id;
        }
    }

    public close(): void {
        if (this.SelectedCart() && this.SelectedCart().hasChanges()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Allocations.ChangesNotSaved,
                ProlifeSdk.TextResources.Allocations.ChangesNotSavedCancel,
                ProlifeSdk.TextResources.Allocations.ChangesNotSavedConfirm,
                (confirm) => {
                    if (!confirm)
                        return;
                    this.changesNotificationsService.UnregisterCartsReallocationObserver(this);
                    this.modal.close();
                }
            );
            
            return;
        }

        this.changesNotificationsService.UnregisterCartsReallocationObserver(this);
        this.modal.close();
    }

    public action(): void {
        if (!this.SelectedCart()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CartSelectionRequired);
            return;
        }
        
        this.SelectedCart()
            .save();
    }

    //---- Metodi per gestione interfaccia grafica
    public switchCartsListCollapsedState(): void {
        this.CartsListCollapsed(!this.CartsListCollapsed());
    }

    public switchGanttCollapsedState(): void {
        this.GanttCollapsed(!this.GanttCollapsed());
    }

    public scrollToCartData(): void {
        this.CartDataScroller(!this.CartDataScroller());
    }

    public scrollToAllocationData(): void {
        this.AllocationDataOpened(true);
        this.AllocationDataScroller(!this.AllocationDataScroller());
    }
    
    public scrollToManualAllocations(): void {
        this.ManualAllocationsOpened(true);
        this.ManualAllocationsScroller(!this.ManualAllocationsScroller());
    }

    public scrollToCartContent(): void {
        this.CartContentOpened(true);
        this.CartContentScroller(!this.CartContentScroller());
    }

    public switchAllocationDataOpenedState(): void {
        this.AllocationDataOpened(!this.AllocationDataOpened());
    }
    
    public switchManualAllocationsOpenedState(): void {
        this.ManualAllocationsOpened(!this.ManualAllocationsOpened());
    }

    public switchCartContentOpenedState(): void {
        this.CartContentOpened(!this.CartContentOpened());
        if (this.SelectedCart())
            this.SelectedCart().ShowCartContentListActions(false);
    }

    public async saveInterfaceSettings(): Promise<void> {
        const config: ICartInfoDialogConfiguration = {
            CartsListCollapsed: this.CartsListCollapsed(),
            GanttCollapsed: this.GanttCollapsed(),
            AllocationDataOpened: this.AllocationDataOpened(),
            ManualAllocationsOpened: this.ManualAllocationsOpened(),
            CartContentOpened:this.CartContentOpened()
        };

        await this.allocationsService.saveCartInfoDialogConfiguration(config);

        this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.CartInfoConfigSaved);
    }

    public async loadInterfaceSettings(): Promise<void> {
        const config = await this.allocationsService.getCartInfoDialogConfiguration();

        this.CartsListCollapsed(config.CartsListCollapsed);
        this.GanttCollapsed(config.GanttCollapsed);
        this.AllocationDataOpened(config.AllocationDataOpened);
        this.ManualAllocationsOpened(config.ManualAllocationsOpened);
        this.CartContentOpened(config.CartContentOpened);
    }

    //----

    public CloseCartContentListActions() {
        if (this.SelectedCart())
            this.SelectedCart().ShowCartContentListActions(false);
    }

    public LoadTeamWorkProgress(): void {
        this.allocationsService.getTeamWorkProgress(this.teamId)
            .then((progressInfo: ITeamWorkProgressInfo) => {
                this.TeamWorkProgressInfo(new TeamWorkProgress(progressInfo));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetTeamProgressError);
            });
    }

    public async CreateAndAllocateEmptyCart(): Promise<void> {
        try {
            const cart = await this.cartsService.openCartEditorDialog()
            if (!cart)
                return;

            this.allocateCartOnTeam(cart.Id, AllocationMode.AutomaticAllocation);
        } catch (e) {
            console.log(e);
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AddCartError);
        }
    }

    public AllocateExistingCart(): void {
        const dialog = new AllocatedCartSelectionDialog(this.teamId);
        dialog
            .show()
            .then((cartId: number) => {
                if (cartId === null)
                    return;
                
                this.allocateCartOnTeam(cartId, AllocationMode.AutomaticAllocation);        
            });
    }

    public DoManualAllocations(): void {
        const dialog = this.allocationsService.getManualAllocationDialog(this.teamId, moment().startOf("day").toDate(), null);
        dialog
            .show();
    }

    private allocateCartOnTeam(cartId, allocationMode): Promise<void> {
        return this.allocationsService.allocateCartOnTeam(this.teamId, cartId, allocationMode);
    }

    private LoadCarts(initSelection: number): void {
        this.allocationsService.GetAllocationsOnTeam(this.teamId)
            .then((carts: IAllocatedCartForList[]) => {
                const allocatedCarts: AllocatedCartForList[] = [];
                this.SelectedCart(null);
                
                carts.forEach((cart: IAllocatedCartForList) => {
                    const newCart = new AllocatedCartForList(cart, this);
                    allocatedCarts.push(newCart);
                    if (newCart.CartId == initSelection)
                        this.SelectCart(newCart);
                });

                this.Carts(allocatedCarts);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetAllocatedCartsError);
            });
    }

    public OnCartMoved(beforeId : number, movedId : number, afterId : number) {
        const actualPosition: number = this.getElementIndex(movedId, this.Carts());
        const previousCartPosition: number = this.getElementIndex(beforeId, this.Carts());
        const nextCartPosition: number = this.getElementIndex(afterId, this.Carts());
        const newIndex: number = this.updateIndexes(movedId, actualPosition, previousCartPosition, nextCartPosition, this.Carts());

        const neighbourId = beforeId ? beforeId : afterId;
        const before = beforeId ? false : true;

        this.allocationsService.reorderCartsAllocation(this.teamId, movedId, neighbourId, before);
    }

    public OnCartsReallocation(teamId: number) {
        this.AllocationsGanttForTeam.UpdateTeam(teamId);
        this.AllocationsMenuDataSource.refresh();
    }

    public OnReallocation() {
        this.LoadCarts(!this.SelectedCart() ? null : this.SelectedCart().getCartId());

        if (this.CartContentOpened())
            this.RefreshNavigationMenu();
        
        if (!this.CartsListCollapsed())
            this.LoadTeamWorkProgress();

        if (!this.GanttCollapsed())
            this.RefreshGantt();

        this.AllocationsMenuDataSource.refresh();
    }

    public OnReallocationError(errorMessage: string) {
        this.infoToastService.Error(errorMessage);
    }

    private getElementIndex(id: number, elements: any[]): number {
        let index: number = null;
        elements.forEach((el: any) => {
            if (el.CartId == id)
                index = el.CartOrder();
        });
        return index;
    }

    private updateIndexes(elementId: number, actualIndex: number, previousObjectIndex: number, nextObjectIndex: number, elements: any[]): number {
        let newIndex : number = actualIndex;
        if (!previousObjectIndex && !nextObjectIndex)
            return actualIndex;
        previousObjectIndex = !previousObjectIndex ? 0 : previousObjectIndex;
        nextObjectIndex = !nextObjectIndex ? 99999 : nextObjectIndex;
        if (actualIndex < previousObjectIndex) {
            elements.forEach((e: any) => {
                if(e.CartOrder() >= actualIndex && e.CartOrder() <= previousObjectIndex && e.Id != elementId)
                    e.CartOrder(e.CartOrder() - 1);
                if (e.Id == elementId) {
                    e.CartOrder(previousObjectIndex);
                    newIndex = e.CartOrder();
                }
            });
            return newIndex;
        }
        if (actualIndex > nextObjectIndex) {
            elements.forEach((e: any) => {
                if(e.CartOrder() <= actualIndex && e.CartOrder() >= nextObjectIndex && e.Id != elementId)
                    e.CartOrder(e.CartOrder() + 1);
                if (e.Id == elementId) {
                    e.CartOrder(nextObjectIndex);
                    newIndex = e.CartOrder();
                }
            });
        }
        return newIndex;
    }

    public SelectCart(cart: AllocatedCartForList): void {
        this.canSelectCart()
            .then((result: boolean) => {
                if (!result)
                    return;

                this.Carts().forEach((c: AllocatedCartForList) => {
                    c.Selected(false);
                });
        
                cart.Selected(true);
                this.SelectedCart(new CartAllocationsViewModel(cart, this));

                this.allocationsService.triggerNextTutorialStep("cart-detail-click");
            });
    }

    private canSelectCart(): Promise<boolean> {
        if (!this.SelectedCart() || (this.SelectedCart() && !this.SelectedCart().hasChanges()))
            return new Promise((resolve, reject) => resolve(true));

        return new Promise((resolve) => {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Allocations.DiscardCartChangesMessage,
                ProlifeSdk.TextResources.Allocations.DiscardCartChangesCancel,
                ProlifeSdk.TextResources.Allocations.DiscardCartChangesConfirm,
                (confirm: boolean) => {
                    resolve(confirm);
                }
            );
        });
    }

    public RefreshGantt(): void {
        if (this.GanttCollapsed())
            return;

        this.AllocationsGanttForTeam.UpdateTeam(this.teamId);
    }

    public RefreshNavigationMenu(): void {
        if (this.JobOrdersMenu())
            this.JobOrdersMenu().refresh();
    }

    public RefreshCartsList() {
        this.LoadCarts(this.SelectedCart().getCartId());
    }

    public RemoveAllocation(allocationId: number): void {
        let index = -1;
        this.Carts().forEach((c: AllocatedCartForList, i: number) => {
            if (c.AllocationId == allocationId)
                index = i;
        });
        if (index >= 0) {
            this.Carts.splice(index, 1);
            this.RefreshCartsOrder();
        }
    }

    private RefreshCartsOrder(): void {
        this.Carts().forEach((c: AllocatedCartForList, i: number) => {
            c.CartOrder(i + 1);
        });
    }

    public RefreshCartColor(cartId: number, color: string): void {
        this.Carts().forEach((c: AllocatedCartForList) => {
            if (c.CartId == cartId)
                c.Color(color);
        });
    }

    public GetCartStateLabel(stateId: number): string {
        return this.cartsStateManager.getCartsStatusById(stateId).Label;
    }

    private async CreateNewWorkflow(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowDialog(this.selectedJobOrder);
        this.JobOrdersMenu().refresh();
    }

    private async CreateNewWorkflowFromWorkflow(): Promise<void> {
        await this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.selectedJobOrder);
        this.JobOrdersMenu().refresh();
    }

    private async CreateNewWorkflowFromTemplate(): Promise<void>
    {
        await this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.selectedJobOrder);
        this.JobOrdersMenu().refresh();
    }

    private async CreateTask(): Promise<void> {
        await this.todoListService.ShowCreateNewTaskDialog(this.selectedJobOrder, this.selectedWorkflow, { initialViewAll: true });
        this.JobOrdersMenu().refresh();
    }
}

interface IRoleInfo {
    RoleName: string;
    RemainingAllocatedHours: number;
    UnallocatedHours: number;
}

export class TeamWorkProgress {
    public RemainingAllocatedHours: ko.ObservableArray<IAllocatedHoursForRoles> = ko.observableArray([]);
    public UnallocatedHours: ko.ObservableArray<IOpUnitWorkableHours> = ko.observableArray([]);
    public AllocationEndDate: ko.Observable<ILastAllocationDate> = ko.observable();

    public TotalRemainingHours: ko.Computed<number>;
    public TotalUnallocatedHours: ko.Computed<number>;

    public RolesInfo: ko.Computed<IRoleInfo[]>;

    constructor(progressInfo: ITeamWorkProgressInfo) {
        this.RemainingAllocatedHours(progressInfo.RemainingAllocatedHours);
        this.UnallocatedHours(progressInfo.UnallocatedHours);
        this.AllocationEndDate(progressInfo.AllocationEndDate);

        this.TotalRemainingHours = ko.computed(() => {
            let total = 0;
            this.RemainingAllocatedHours().forEach((r: IAllocatedHoursForRoles) => {
                total += r.AllocatedHours;
            });
            return total;
        });

        this.TotalUnallocatedHours = ko.computed(() => {
            let total = 0;
            this.UnallocatedHours().forEach((u: IOpUnitWorkableHours) => {
                total += u.TotalWorkableHours;
            });
            return total;
        });

        this.RolesInfo = ko.computed(() => {
            const info: IRoleInfo[] = [];
            this.UnallocatedHours();
            this.RemainingAllocatedHours().forEach((r: IAllocatedHoursForRoles) => {
                const unallocatedHours: IOpUnitWorkableHours = this.UnallocatedHours().filter((u: IOpUnitWorkableHours) => { return r.RoleId == u.FkOperationalUnit })[0];
                info.push({
                    RoleName: r.RoleName,
                    RemainingAllocatedHours: r.AllocatedHours,
                    UnallocatedHours: !unallocatedHours ? 0 : unallocatedHours.TotalWorkableHours
                });
            });
            return info;
        });
    }
}

export class AllocatedCartForList {
    public AllocationId: number;
    public TeamId: number;
    public CartId: number;
    public Title: ko.Observable<string> = ko.observable();
    public CartOrder : ko.Observable<number> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public Selected: ko.Observable<boolean> = ko.observable();
    public AutomaticAllocationsNumber: ko.Observable<number> = ko.observable();
    public ManualAllocationsNumber: ko.Observable<number> = ko.observable();
    public TeamsNames: ko.ObservableArray<string> = ko.observableArray([]);

    public ColorWithOpacity: ko.Computed<string>;
    public ForegroundColor: ko.Computed<string>;
    public HtmlFormattedTeamsNames: ko.Computed<string>;
    public CartAllocatedOnTeamsNumber: ko.Computed<number>;
    
    constructor(private cart: IAllocatedCartForList, private parent: CartInfoDialog) {
        this.TeamId = this.cart.TeamId;
        this.CartId = this.cart.CartId;
        this.Title(this.cart.Title);
        this.CartOrder(this.cart.CartOrder);
        this.Color(this.cart.Color);
        this.Selected(false);
        this.AutomaticAllocationsNumber(this.cart.AutomaticAllocationsNumber);
        this.ManualAllocationsNumber(this.cart.ManualAllocationsNumber);
        this.TeamsNames(this.cart.TeamsNames);

        this.ColorWithOpacity = ko.computed(() => {
            return UiUtilities.GetColorWithOpacity(this.Color(), 0.5)
        });

        this.ForegroundColor = ko.computed(() => {
            return UiUtilities.GetForegroundColor(this.Color());
        });

        this.CartAllocatedOnTeamsNumber = ko.computed(() => {
            return this.TeamsNames().length;
        });

        this.HtmlFormattedTeamsNames = ko.computed(() => {
            return this.TeamsNames().join('<br/>');
        });
    }

    public Select(): void {
        this.parent.SelectCart(this);
    }
}

export class ManualAllocationsViewModel implements IDataSourceListener {
    public datesDataSource: ManualAllocationsDateDataSource;
    public ManualAllocationsEditor: ko.Observable<IManualAllocationsEditor> = ko.observable();
    public Navigator: ko.Observable<INavigationMenuComponent> = ko.observable();

    public ManualAllocationsTotalNumber: ko.Computed<number>;

    constructor(private cart: AllocatedCartForList, private parent: CartInfoDialog) {
        this.datesDataSource = new ManualAllocationsDateDataSource(this.cart.TeamId, this.cart.CartId);
        this.datesDataSource.selectFirstAvailableDay();

        const manualAllocationsEditor = new ManualAllocationsEditor(this.cart.TeamId);
        manualAllocationsEditor.ShowTeamName(false);
        manualAllocationsEditor.ShowAllocationDate(false);
        manualAllocationsEditor.setCartIdFilter(this.cart.CartId);

        this.ManualAllocationsEditor(manualAllocationsEditor);

        this.Navigator.subscribe((navigator: INavigationMenuComponent) => {
            this.datesDataSource.setNavigator(navigator);
        });

        this.ManualAllocationsTotalNumber = ko.computed(() => {
            return this.datesDataSource.ManualAllocationsTotalNumber();
        });
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        const dayModel = model as IDayNavigationMenuComponentModel;
        this.ManualAllocationsEditor().setDate(dayModel.date, false);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        // Non devo fare niente
    }
    
    public canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return this.ManualAllocationsEditor().canChangeDate();
    }

    public Validate(): boolean {
        return this.ManualAllocationsEditor().validate();
    }    

    public Save(): Promise<void> {
        return this.ManualAllocationsEditor().save();
    }

    public HasChanges(): boolean {
        return this.ManualAllocationsEditor().hasChanges();
    }

    public async RemoveManualAllocation(allocation: IManualAllocationViewModel): Promise<void> {
        await this.ManualAllocationsEditor().removeAllocation(allocation, true);
    }
}

@DetectClassChanges
export class AutomaticAllocatedCartInfo {
    public AllocationId: number;
    public TeamId: number;
    public CartId: number;
    @DetectChanges
    public Title: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Status: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Color: ko.Observable<string> = ko.observable();
    @DetectChanges
    public AllocationStartType : ko.Observable<number> = ko.observable();
    @DetectChanges
    public ManualStartDate : ko.Observable<Date> = ko.observable();
    @DetectChanges
    public ManualEndDate : ko.Observable<Date> = ko.observable();
    public Content: ko.ObservableArray<CartContentDetails> = ko.observableArray([]);
    @DetectChanges
    public ActivitiesBacklogOption : ko.Observable<ActivityBacklogOptions> = ko.observable();
    @DetectChanges
    public CartWorkableHoursCalculationMode: ko.Observable<CartWorkableHoursCalculationModes> = ko.observable(CartWorkableHoursCalculationModes.JobOrderSpeed);
    @DetectChanges
    public Priority: ko.Observable<boolean> = ko.observable(false);
    
    public CartOrder: ko.Observable<number> = ko.observable();

    public RolesInfo: ko.ObservableArray<ICartRoleInfo> = ko.observableArray([]);
    public TotalEstimatedHours: ko.Observable<number> = ko.observable();
    public TotalReestimatedHours: ko.Observable<number> = ko.observable();
    public TotalWorkedHours: ko.Observable<number> = ko.observable();
    public LastPerformanceUpdate: ko.Observable<Date> = ko.observable();
    public EarlierAllocationStartDate: ko.Observable<Date> = ko.observable();
    public LatestAllocationEndDate: ko.Observable<Date> = ko.observable();
    public EarlierTheoreticalAllocationStartDate: ko.Observable<Date> = ko.observable();
    public LatestTheoreticalAllocationEndDate: ko.Observable<Date> = ko.observable();
    public WorkEndDate: ko.Observable<Date> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public DropScope: ko.Observable<string> = ko.observable("cart-info-dialog");

    @DetectChanges
    public Links: ko.ObservableArray<ICartLink> = ko.observableArray([]);
    public ActivitiesBacklogOptions: ko.ObservableArray<ActivityBacklogOption> = ko.observableArray([]);

    public CartWorkableHoursCalculationModesList: ko.ObservableArray<ICartWorkableHoursCalculationMode> = ko.observableArray([]);

    public ReestimatedColumnTitle: ko.Computed<string>;
    public RemainingWorkColumnTitle: ko.Computed<string>;
    public UsingReestimatedWork: ko.Computed<boolean>;
    public UsingTaskEstimateWork: ko.Computed<boolean>;

    public Resources: ResourcesAndGroupsManager;

    public IsManuallyAllocated: ko.Computed<boolean>;
    public IsEmpty: ko.Computed<boolean>;
    
    public StateLabel: ko.Observable<string> = ko.observable();
    public CollapsedView: ko.Observable<boolean> = ko.observable(true);

    public AllocationsTypes: ko.ObservableArray<IAllocationType> = ko.observableArray([]);

    public CartLinksSelector: CartLinksSelectorUI;

    public AllowedMimeTypes: string[] = [
        'application/prolife-task',
        'application/prolife-workflow'
    ];
    
    public isChanged: ko.Observable<number> = ko.observable(0);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService!: IDialogsService;

    @LazyImport(nameof<ICartsService>())
    protected cartsService!: ICartsService;

    @LazyImport(nameof<IAllocationsService>())
    protected allocationsService!: IAllocationsService;

    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService!: IInfoToastService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    constructor(private cart: IAllocatedCart, private parent: CartInfoDialog) {
        this.CartWorkableHoursCalculationModesList(this.allocationsService.getCartWorkableHoursCalculationModesList());
        this.ActivitiesBacklogOptions(this.allocationsService.getActivitiesBacklogOptions());

        this.AllocationId = this.cart.CartInfo.AllocationId;
        this.TeamId = this.cart.CartInfo.TeamId;
        this.CartId = this.cart.CartInfo.CartId;
        this.Title(this.cart.CartInfo.Title);
        this.Status(this.cart.CartInfo.Status);
        this.Color(this.cart.CartInfo.Color);
        this.AllocationStartType(this.cart.CartInfo.AllocationStartType);
        this.ManualStartDate(this.cart.CartInfo.ManualStartDate);
        this.ManualEndDate(this.cart.CartInfo.ManualEndDate);
        this.StateLabel(this.parent.GetCartStateLabel(this.cart.CartInfo.Status));
        this.ActivitiesBacklogOption(this.cart.CartInfo.IgnoreBacklog === true ? ActivityBacklogOptions.IgnoreBacklog : (this.cart.CartInfo.IgnoreBacklog === false ? ActivityBacklogOptions.UseBacklog : ActivityBacklogOptions.UseSystemDefault));
        this.CartWorkableHoursCalculationMode(this.cart.CartInfo.CartWorkableHoursCalculationMode as CartWorkableHoursCalculationModes);
        this.Priority(!!this.cart.CartInfo.Priority);
        this.CartOrder(this.cart.CartInfo.CartOrder);
        this.WorkEndDate(this.cart.CartInfo.WorkEndDate);
        this.Links(this.cart.Links);
        
        this.CartLinksSelector = new CartLinksSelectorUI({
            cartId: this.CartId,
            value: this.Links,
            onLinkDelayChange: (value) => { this.Links().forEach(l => l.DelayInDays = value); this.isChanged(1); },
            onLinkAdded: (linkId, delay) => { this.Links.push({ LinkCartId: linkId, DelayInDays: delay, CartId: this.CartId }); this.isChanged(1); },
            onLinkRemoved: (linkId) => { const link = this.Links().firstOrDefault(l => l.LinkCartId === linkId); link && this.Links.remove(link); this.isChanged(1); }
        });

        if (!this.cart.Resources)
            this.cart.Resources = [];

        this.Resources = new ResourcesAndGroupsManager();
        this.Resources.LoadResources(this.cart.Resources);

        this.IsManuallyAllocated = ko.computed(() => {
            return this.AllocationStartType() == AllocationStartType.FixedDate;
        });

        this.IsEmpty = ko.computed(() => {
            return this.Content().length == 0;
        });

        this.AllocationsTypes.push(<IAllocationType> { Id: AllocationStartType.FixedDate, Description: ProlifeSdk.TextResources.Allocations.ManualAllocationType });
        this.AllocationsTypes.push(<IAllocationType> { Id: AllocationStartType.AsSoonAsPossible, Description: ProlifeSdk.TextResources.Allocations.AutoAllocationType });

        this.StartDate(this.cart.CartInfo.StartDate);
        this.EndDate(this.cart.CartInfo.EndDate);

        this.LoadContent();
        this.LoadOpUnitsInfo();

        this.ReestimatedColumnTitle = ko.computed(() => {
            return this.CartWorkableHoursCalculationMode() === CartWorkableHoursCalculationModes.WorkflowSpeed ? TextResources.Allocations.WorkflowSpeedReestimatedWorkTitle : TextResources.Allocations.JobOrderSpeedReestimatedWorkTitle;
        });

        this.RemainingWorkColumnTitle = ko.computed(() => {
            return this.CartWorkableHoursCalculationMode() === CartWorkableHoursCalculationModes.WorkflowSpeed ? TextResources.Allocations.WorkflowSpeedRemainingWorkTitle : TextResources.Allocations.JobOrderSpeedRemainingWorkTitle;
        });

        this.UsingReestimatedWork = ko.computed(() => {
            return this.CartWorkableHoursCalculationMode() !== CartWorkableHoursCalculationModes.TaskEstimatedWork;
        });

        this.UsingTaskEstimateWork = ko.computed(() => {
            return !this.UsingReestimatedWork();
        });

        this.isChanged(0);
    }

    public LoadWorkEndDataFromAllocations(): void {
        if (!this.RolesInfo() || this.RolesInfo().length === 0)
            return;

        const date = this.RolesInfo()[0].AllocationEndDate;
        this.WorkEndDate(date);
    }

    public HasChanges(): boolean {
        return this.isChanged() > 0;
    }

    public dispose(): void {

    }

    public LoadContent(): void {
        this.cartsService.getCartContentDetails(this.cart.CartInfo.CartId, false)
            .then((contents: ICartContentWithRolesInfo[]) => {
                if (!contents) {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetCartContentError);
                    return;
                }
                this.Content(contents.map((c: ICartContentWithRolesInfo) => { return new CartContentDetails(c, this); }));
            });
    }

    public LoadOpUnitsInfo(): void {
        this.cartsService.getCartRolesInfo(this.CartId)
            .then((rolesInfo: ICartRoleInfo[]) => {
                this.RolesInfo(rolesInfo);
                let estimated = 0;
                let reestimated = 0;
                let worked = 0;
                let earlierStartDate = moment("2100-01-01");
                let latestEndDate = moment("2000-01-01");
                let earlierTheoreticalStartDate = moment("2100-01-01");
                let latestTheoreticalEndDate = moment("2000-01-01");
                let lastPerformanceUpdate = moment("2000-01-01");
                rolesInfo.forEach((op: ICartRoleInfo) => {
                    estimated += op.EstimatedHours;
                    reestimated += op.ReestimatedHours;
                    worked += op.WorkedHours;
                    earlierStartDate = op.AllocationStartDate && moment(op.AllocationStartDate) < earlierStartDate ? moment(op.AllocationStartDate) : earlierStartDate;
                    latestEndDate = op.AllocationEndDate && moment(op.AllocationEndDate) > latestEndDate ? moment(op.AllocationEndDate) : latestEndDate;
                    earlierTheoreticalStartDate = op.TheoreticalAllocationStartDate && moment(op.TheoreticalAllocationStartDate) < earlierTheoreticalStartDate ? moment(op.TheoreticalAllocationStartDate) : earlierTheoreticalStartDate;
                    latestTheoreticalEndDate = op.TheoreticalAllocationEndDate && moment(op.TheoreticalAllocationEndDate) > latestTheoreticalEndDate ? moment(op.TheoreticalAllocationEndDate) : latestTheoreticalEndDate;
                    lastPerformanceUpdate = op.LastPerformanceUpdate && moment(op.LastPerformanceUpdate) > lastPerformanceUpdate ? moment(op.LastPerformanceUpdate) : lastPerformanceUpdate;
                });
                this.TotalEstimatedHours(estimated);
                this.TotalReestimatedHours(reestimated);
                this.TotalWorkedHours(worked);
                this.EarlierAllocationStartDate((<any>earlierStartDate).isSame(moment("2100-01-01")) ? null : earlierStartDate.toDate());
                this.LatestAllocationEndDate((<any>latestEndDate).isSame(moment("2000-01-01")) ? null : latestEndDate.toDate());
                this.EarlierTheoreticalAllocationStartDate((<any>earlierTheoreticalStartDate).isSame(moment("2100-01-01")) ? null : earlierTheoreticalStartDate.toDate());
                this.LatestTheoreticalAllocationEndDate((<any>latestTheoreticalEndDate).isSame(moment("2000-01-01")) ? null : latestTheoreticalEndDate.toDate());
                this.LastPerformanceUpdate((<any>lastPerformanceUpdate).isSame(moment("2000-01-01")) ? null : lastPerformanceUpdate.toDate());
            });
    }

    public RemoveContent(element: CartContentDetails): void {
        this.cartsService.removeElementFromAllocatedCart(element.ElementId, element.IsTask, this.CartId, this.TeamId)
            .then(() => {
                this.Content.remove(element);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.RemoveElementFromCartError);
            });
    }

    public SwitchCollapsedView(): void {
        this.Content().forEach((c: CartContentDetails) => {
            c.Collapsed(true);
        });
        this.CollapsedView(true);

        this.parent.CloseCartContentListActions();
    }

    public SwitchExpandedView(): void {
        this.Content().forEach((c: CartContentDetails) => {
            c.Collapsed(false);
        });
        this.CollapsedView(false);

        this.parent.CloseCartContentListActions();
    }

    public Validate(): boolean {
        const startDate = this.StartDate();

        if (!this.Title() || !this.Color() || (this.AllocationStartType() == AllocationStartType.FixedDate && !startDate)) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.MissingRequiredCartData);
            return false;
        }

        if (this.AllocationStartType() == AllocationStartType.FixedDate && moment(startDate).startOf('day').diff(moment().startOf("day"), "days") > 365 * 2) {
            this.infoToastService.Error(TextResources.Allocations.InvalidCartStartDate);
            return false;
        }

        if (this.Links().filter(l => l.DelayInDays < 0).length > 0) {
            this.infoToastService.Error(TextResources.Allocations.InvalidLinksDelayValue);
            return false;
        }

        return true;
    }

    public Save(): Promise<void> {
        const data = this.getData();

        return this.cartsService.updateAllocatedCart(data)
            .then(() => {
                this.isChanged(0);
                this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.SaveCartSuccess);
            })
            .catch((e) => {
                const exception = e as IValidationException;
                if (exception.ExceptionCode == 34333) {
                    this.cartsService.showCircularDependenciesAlert(exception.ValidationData);
                    return;
                } else {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.UpdateCartError);
                }
            });
    }

    public DeallocateCart(): void {
        this.allocationsService.deallocateCart(this.TeamId, this.CartId);
    }

    public getData(): IAllocatedCart {
        return {
            CartInfo: {
                AllocationId : this.AllocationId,
                TeamId : this.TeamId,
                CartId : this.CartId,
                Title: this.Title(),
                Status: this.Status(),
                Color: this.Color(),
                AllocationStartType : this.AllocationStartType(),
                ManualStartDate : this.ManualStartDate(),
                ManualEndDate : this.ManualEndDate(),
                CartOrder : this.CartOrder(),
                IgnoreBacklog: this.ActivitiesBacklogOption() === ActivityBacklogOptions.UseSystemDefault ? null : (this.ActivitiesBacklogOption() === ActivityBacklogOptions.IgnoreBacklog ? true : false),
                CartWorkableHoursCalculationMode: this.CartWorkableHoursCalculationMode(),
                Priority: this.Priority() ? AllocationPriority.PriorityStandardAllocation : AllocationPriority.StandardAllocation,
                StartDate: this.StartDate(),
                EndDate: this.EndDate(),
                WorkEndDate: this.WorkEndDate()
            },
            Resources: this.Resources.GetResources(),
            Links: this.Links()
        };
    }

    public OnElementMoved(element: CartContentDetails, dataTransfer: DataTransfer) {
        if (element.IsTask) {
            const taskData: IDraggedTask = {
                TaskId: element.ElementId,
                IsTask: element.IsTask,
                JobOrderId: null,
                WorkflowId: null,
                TaskBoardStatus: null,
                CompanyGuid: this.userInfo.getCurrentCompanyGuid()
            };

            dataTransfer.setData("application/prolife-task", JSON.stringify(taskData));
            return;
        }

        const workflowData: IDraggedWorkflow = {
            WorkflowId: element.ElementId,
            IsTask: element.IsTask,
            JobOrderId: null,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid()
        };

        dataTransfer.setData("application/prolife-workflow", JSON.stringify(workflowData));
    }

    public async OnElementDropped(dataTransfer: DataTransfer, neighbourElement: CartContentDetails, before: boolean): Promise<void> {
        const task: IDraggedTask = dataTransfer.types.indexOf("application/prolife-task") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-task")) : null;
        const workflow: IDraggedWorkflow = dataTransfer.types.indexOf("application/prolife-workflow") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-workflow")) : null;
        
        if (this.userInfo.getCurrentCompanyGuid() !== (task ? task.CompanyGuid : workflow.CompanyGuid)) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        const elementId: number = task ? task.TaskId : workflow.WorkflowId;
        const isTask: boolean = task ? task.IsTask : workflow.IsTask;

        if (!elementId || (elementId === neighbourElement.ElementId && isTask === neighbourElement.IsTask))
            return;

        const content = this.Content();
        const draggedElementMatch = content.filter(e => e.ElementId === elementId && e.IsTask === isTask);
        const draggedElement = draggedElementMatch.length > 0 ? draggedElementMatch[0] : null;

        if (draggedElement) {
            const draggedElementIndex = draggedElement.Order;
            const neighbourElementIndex = neighbourElement.Order;

            if (before) {
                if (draggedElementIndex > neighbourElementIndex) {
                    for (const element of content) {
                        if (element.Order > draggedElementIndex)
                            break;

                        if (element.Order >= neighbourElementIndex)
                            element.Order++;
                    }

                    draggedElement.Order = neighbourElementIndex;
                } else {
                    for (const element of content) {
                        if (element.Order >= neighbourElementIndex)
                            element.Order++;
                    }

                    draggedElement.Order = neighbourElementIndex;
                }
            } else {
                if (draggedElementIndex > neighbourElementIndex) {
                    for (const element of content) {
                        if (element.Order >= neighbourElementIndex)
                            element.Order++;
                    }

                    draggedElement.Order = neighbourElementIndex + 1;
                } else {
                    for (const element of content) {
                        if (element.Order > draggedElementIndex && element.Order < neighbourElementIndex)
                            element.Order--;
                        
                        if (element.Order > neighbourElementIndex)
                            element.Order++;

                        draggedElement.Order = neighbourElementIndex + 1;
                    }
                }
            }

            content.sort((left, right) => left.Order - right.Order);

            this.Content(content);

        } else {
            try {
                const result: boolean = await this.allocationsService.checkForUnestimatedTasks(elementId, isTask);
                if (!result) {
                    this.getMissingOpUnits(elementId, isTask);
                    return;
                }

                const confirm: boolean = await this.dialogsService.ConfirmAsync(
                    ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartMessage,
                    ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartCancel,
                    ProlifeSdk.TextResources.Allocations.AddUnestimatedElementToCartConfirm
                )

                if (confirm)
                    this.getMissingOpUnits(elementId, isTask);
            
            } catch(e) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AddElementToCartError);
            }
        }
    }

    private getMissingOpUnits(elementId: number, isTask: boolean): void {
        this.allocationsService.getMissingRolesByElement(elementId, isTask, this.TeamId)
            .then((roles: IMissingRole[]) => {
                if (roles && roles.length > 0) {
                    const units: string = roles.map((o: IMissingRole) => { return o.RoleName; }).join(",");
                    const message: string = String.format(TextResources.Allocations.MissedRolesOnTeamAlert, units);
                    this.dialogsService.Confirm(
                        message,
                        TextResources.Allocations.MissedRolesOnTeamCancel,
                        TextResources.Allocations.MissedRolesOnTeamConfirm,
                        (confirm: boolean) => {
                            if (confirm)
                                this.addElementToCart(elementId, isTask);
                        }
                    );
                    return;
                }
                this.addElementToCart(elementId, isTask);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AllocationError);
            });
    }

    private addElementToCart(elementId: number, isTask: boolean): void {
        this.cartsService.manageCartWorkEndDate(this.CartId, elementId, isTask)
            .then(() => {
                this.cartsService.addElementOnAllocatedCart(elementId, isTask, this.CartId, this.TeamId)
                    .then((element: ICartContentWithRolesInfo) => {
                        if (!element) {
                            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CanNotAllocateElementAlreadyAllocated);
                            return;
                        }
                        const newElement: CartContentDetails = new CartContentDetails(element, this);
                        newElement.IsNew(true);
                        if (!this.CollapsedView())
                            newElement.Collapsed(false);
                        this.Content.push(newElement);
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AddElementToCartError);
                    });
            });
    }
}

export class CartContentDetails {
    public Id: number;
    public ElementId: number;
    public IsTask: boolean;
    public Order: number;
    public Priority: number;
    public Title: string;
    public WorkflowTitle: string;
    public JobOrderTitle: string;

    public RolesInfo: ko.ObservableArray<ICartContentRoleInfo> = ko.observableArray([]);

    public JobOrderLabel: string;
    public WorkflowLabel: string;

    public TotalEstimatedHours = 0;
    public TotalReestimatedHours = 0;
    public TotalWorkedHours = 0;

    public Collapsed: ko.Observable<boolean> = ko.observable(true);
    public IsNew: ko.Observable<boolean> = ko.observable(false);

    constructor(content: ICartContentWithRolesInfo, private parent: AutomaticAllocatedCartInfo) {
        this.Id = content.ElementInfo.Id;
        this.ElementId = content.ElementInfo.ElementId;
        this.IsTask = content.ElementInfo.IsTask;
        this.Order = content.ElementInfo.Order;
        this.Priority = content.ElementInfo.Priority;
        this.Title = content.ElementInfo.Title;
        this.WorkflowTitle = content.ElementInfo.WorkflowTitle;
        this.JobOrderTitle = content.ElementInfo.JobOrderTitle;

        this.RolesInfo(content.RolesInfo);
        this.CalculateTotals();

        this.JobOrderLabel = content.ElementInfo.JobOrderTitle ? ProlifeSdk.TextResources.JobOrder.Order + ": " + content.ElementInfo.JobOrderTitle : "";
        this.WorkflowLabel = content.ElementInfo.WorkflowTitle ? ProlifeSdk.TextResources.Todolist.Workflow + ": " + content.ElementInfo.WorkflowTitle : "";
    }

    public RemoveContent(): void {
        this.parent.RemoveContent(this);
    }

    private CalculateTotals(): void {
        this.RolesInfo().forEach((op: ICartRoleInfo) => {
            this.TotalEstimatedHours += op.EstimatedHours;
            this.TotalReestimatedHours += op.ReestimatedHours;
            this.TotalWorkedHours += op.WorkedHours;
        });
    }
}

class AllocatedCartSelectionDialog implements IDialog {
    public templateName = "allocated-cart-selection-dialog";
    public templateUrl = "allocations/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Allocations.AllocateExistingCartDiaog;

    public modal: { close: (result?: any) => void; };

    public Carts: ko.ObservableArray<AllocatedCartInfoForList> = ko.observableArray([]);

    @LazyImport(ProlifeSdk.AllocationsServiceCode)
    private allocationsService!: IAllocationsService;

    @LazyImport(ServiceTypes.InfoToast)
    private infoToastService!: IInfoToastService;

    @LazyImport(ServiceTypes.Dialogs)
    private dialogsService!: IDialogsService;

    constructor(teamId: number) {
        this.loadCarts(teamId);
    }

    public close(): void {
        this.modal.close(null);
    }
    
    public action(): void {
        const selectedCart = this.Carts().filter(c => c.Selected());
        
        if (selectedCart.length === 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CartSelectionRequired);
            return;
        }

        this.modal.close(selectedCart[0].Id);
    }

    public show(): Promise<number> {
        return this.dialogsService.ShowModal(this, null, null, this.templateUrl, this.templateName) as unknown as Promise<number>;
    }

    public selectCart(cart: AllocatedCartInfoForList): void {
        const cartSelectionStatus = cart.Selected();

        this.Carts().forEach((c) => c.Selected(false));
        cart.Selected(!cartSelectionStatus);
    }

    private async loadCarts(teamId: number): Promise<void> {
        const carts = await this.allocationsService.getAvailableCartsForAllocationOnTeam(teamId);

        this.Carts(carts.map(c => new AllocatedCartInfoForList(c, this)));
    }
}

class AllocatedCartInfoForList {
    public Id: number;

    public CartTitle: ko.Observable<string> = ko.observable();
    public Teams: ko.ObservableArray<ITeam> = ko.observableArray([]);    
    
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public Expanded: ko.Observable<boolean> = ko.observable(false);

    constructor(private cart: ICartForAllocation, private selector: AllocatedCartSelectionDialog) {
        this.Id = this.cart.Id;
        this.CartTitle(this.cart.Title);
        this.Teams(this.cart.AllocatedOnTeams || []);
    }

    public select(): void {
        this.selector.selectCart(this);
    }
}

export class CartAllocationsViewModel {
    public AutomaticAllocation: ko.Observable<AutomaticAllocatedCartInfo> = ko.observable();
    public ManualAllocations: ko.Observable<ManualAllocationsViewModel> = ko.observable();
    public ShowCartContentListActions: ko.Observable<boolean> = ko.observable(false);
    public HasAutomaticAllocation: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ProlifeSdk.AllocationsServiceCode)
    private allocationsService!: IAllocationsService;

    constructor(private cart: AllocatedCartForList, private parent: CartInfoDialog) {
        this.loadAutomaticAllocation();
        this.ManualAllocations(new ManualAllocationsViewModel(this.cart, this.parent));
    }

    public SwitchCartContentListActionsState(): void {
        this.ShowCartContentListActions(!this.ShowCartContentListActions());
    }

    public getCartId(): number {
        return this.cart.CartId;
    }

    public save(): Promise<boolean> {
        if (this.HasAutomaticAllocation() && !this.AutomaticAllocation().Validate())
            return new Promise((resolve) => resolve(false));

        if (!this.ManualAllocations().Validate())
            return new Promise((resolve) => resolve(false));

        const calls : Promise<any>[] = [];
        if (this.AutomaticAllocation())
            calls.push(this.AutomaticAllocation().Save());
        
        calls.push(this.ManualAllocations().Save());

        return new Promise((resolve, reject) => {
            Promise
                .all(calls)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public hasChanges(): boolean {
        return this.ManualAllocations().HasChanges() || (!!this.AutomaticAllocation() && this.AutomaticAllocation().HasChanges());
    }

    private loadAutomaticAllocation(): void {
        this.allocationsService.getAllocatedCartDetails(this.cart.TeamId, this.cart.CartId)
            .then((cartInfo: IAllocatedCart) => {
                if (!cartInfo.CartInfo)
                    return;

                this.AutomaticAllocation(new AutomaticAllocatedCartInfo(cartInfo, this.parent));
                this.HasAutomaticAllocation(!!cartInfo.CartInfo.AllocationId);
            });
    }
}