import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IEstimatedBudgetForTaskStatesSettingsManager } from "../../interfaces/IEstimatedBudgetForTaskStatesSettingsManager";
import { IEstimatedBudgetForTaskStatesService, IEstimatedBudgetForTaskState } from "../../interfaces/IEstimatedBudgetForTaskStatesService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IException } from "../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { Right } from "../../../Core/Authorizations";

export class EstimatedBudgetForTaskStatesSettingsManager implements IEstimatedBudgetForTaskStatesSettingsManager {
    @LazyImport(nameof<IEstimatedBudgetForTaskStatesService>())
    private estimatedBudgetForTaskStatesService: IEstimatedBudgetForTaskStatesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    
    private states: IEstimatedBudgetForTaskState[] = [];

    @Right("TaskBoard_ManageEstimatedBudgetForTaskStates")
    private canManageEstimatedBudgetStates: boolean;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Todolist.TasksSettingsGroup);
    }
    
    public async load(): Promise<void> {
        this.states = await this.estimatedBudgetForTaskStatesService.GetEstimatedBudgetForTaskStates(null, true, 0, null);
    }
    
    public getName(): string {
        return nameof<IEstimatedBudgetForTaskStatesSettingsManager>();
    }
    
    public getLabel(): string {
        return TextResources.Todolist.EstimatedBudgetForTaskStatesSettingsManager;
    }
    
    public hasEditingUI(): boolean {
        return this.canManageEstimatedBudgetStates;
    }
    
    public getEditingUI(): IView {
        return {
            component: 'estimated-budget-for-task-states-editor',
            templateName: "",
            templateUrl: '',
            viewModel: null
        };
    }

    public getStates(textFilter: string, showDeleted: boolean = false, skip: number = 0, count: number = null): IEstimatedBudgetForTaskState[] {
        return this.states.filter(s => this.matchTextFilter(s, textFilter) && (showDeleted || s.Deleted === false)).slice(skip, skip + count);
    }

    public getStateById(stateId: number): IEstimatedBudgetForTaskState {
        return this.states.firstOrDefault(s => s.Id === stateId);
    }

    public async createOrUpdateStates(states: IEstimatedBudgetForTaskState[]): Promise<void> {
        try {
            this.prepareStatesForInsert(states);
            let savedStates = await this.estimatedBudgetForTaskStatesService.CreateOrUpdateEstimatedBudgetForTaskStates(states);
            this.mergeStates(savedStates);
        } catch (e) {
            let exception = e as IException;
            let message = exception.ExceptionMessage;

            this.infoToastService.Error(message);
        }
    }
    
    private mergeStates(savedStates: IEstimatedBudgetForTaskState[]): void {
        for (let savedState of savedStates) {
            let found = false;
            for (let i = 0;  i < this.states.length; i++) {
                let oldState = this.states[i];

                if (oldState.Id === savedState.Id) {
                    found = true;
                    this.states[i] = savedState;
                    break;
                }
            }

            if (!found)
                this.states.push(savedState);
        }
    }
    
    private prepareStatesForInsert(states: IEstimatedBudgetForTaskState[]): void {
        let fakeId = -1;
        for (let state of states) {
            if (!state.Id || state.Id <= 0)
                state.Id = fakeId--;
        }
    }

    private matchTextFilter(state: IEstimatedBudgetForTaskState, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ").filter(w => w !== " ");

        if (words.length === 0)
            return true;

        let match = true;
        for (let word of words) {
            if (state.Label.indexOf(word) < 0) {
                match = false;
                break;
            }
        }

        return match;
    }
}