import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../ProlifeSdk";
import { Tag } from "./Tag";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../ProlifeTextResources";
import { IAttachment } from "../../interfaces/files/IAttachmentsManager";
import { IFileRepositoryService } from "../../interfaces/files/IFileRepositoryService";
import { IDesktopService } from "../../interfaces/desktop/IDesktopService";
import { IFileOrFolder } from "../../interfaces/files/IFileOrFolder";
import { IEntityAttachment } from "../../interfaces/desktop/IEntityAttachment";
import { IAjaxServiceNew } from "../../../Core/interfaces/IAjaxService";

export class Attachment implements IAttachment {
    public selected: ko.Observable<boolean> = ko.observable(false);
    public id: string;
    public fileName: string;
    public fileVersion: ko.Observable<number> = ko.observable();
    public fileSize: ko.Observable<number> = ko.observable(0);
    public formattedFileSize: ko.Observable<string> = ko.observable("");
    public formattedFileVersion: ko.Observable<string> = ko.observable("");
    public previewClasses: ko.Observable<string> = ko.observable(null);
    public previewStyle: ko.Observable<string> = ko.observable(null);
    public modifiedBy: ko.Observable<number> = ko.observable();

    public includeInElectronicInvoice: ko.Observable<boolean> = ko.observable();
    public entityAttachmentKey: ko.Observable<number> = ko.observable();
    public entityAttachmentType: ko.Observable<string> = ko.observable();
    public showIncludeInElectronicInvoiceFlag: ko.Observable<boolean> = ko.observable();

    @LazyImport(ProlifeSdk.FileRepositoryServiceType)
    private repositoryService: IFileRepositoryService;
    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    private file: IFileOrFolder;

    constructor() {}

    download() {
        this.DownloadFile(this.file);
    }

    downloadVersion() {
        this.repositoryService.getFileDetails(this.id).then((file: IFileOrFolder) => {
            this.DownloadFile(file);
        });
    }

    downloadLastVersion() {
        this.repositoryService.GetLastVersion(this.id).then((lastVersion: IFileOrFolder) => {
            this.DownloadFile(lastVersion);
        });
    }

    private DownloadFile(file: IFileOrFolder) {
        if (!file) return;

        const url = this.repositoryService.GetVersionDownloadUrl(file.Id);
        this.ajaxServiceNew.DownloadFileFromUrl(url, { overrideMethod: "GET" });
    }

    async refresh(): Promise<void> {
        const f: IFileOrFolder = await this.repositoryService.getFileDetails(this.id);
        this.loadFromFile(f);
    }

    loadFromTag(tag: Tag): Attachment {
        this.id = tag.Value();
        this.fileName = tag.displayName();
        this.fileVersion(-1);
        this.previewClasses(this.repositoryService.getPreviewStyle(null));
        this.previewStyle("");

        this.modifiedBy(0);

        this.fileSize(0);
        this.formattedFileSize("");
        this.formattedFileVersion("");

        this.refresh();

        return this;
    }

    loadFromFile(file: IFileOrFolder): Attachment {
        this.file = file ?? ({} as IFileOrFolder);
        this.id = file?.Id;
        this.fileName = file?.Name || "File not found.";
        this.fileVersion(file?.Version);
        if (file) this.previewClasses(this.repositoryService.getPreviewStyle(file));
        this.previewStyle(
            file?.HasPreview ? "background-image: url('" + this.repositoryService.getPreviewUrl(file) + "')" : ""
        );

        this.modifiedBy(file?.ModifiedBy);

        this.fileSize(file?.Size);
        this.formattedFileSize(numeral(file?.Size).format("0,0[.]0 b"));
        this.formattedFileVersion(String.format(TextResources.Expenses.Version, file?.Version ?? ""));

        return this;
    }

    loadEntityAttachmentInfo(attachment: IEntityAttachment): void {
        this.includeInElectronicInvoice(attachment.IncludeInElectronicInvoice);
        this.entityAttachmentKey(attachment.EntityKey);
        this.entityAttachmentType(attachment.EntityType);
    }

    toEntityAttachment(): IEntityAttachment {
        return <IEntityAttachment>{
            EntityType: this.entityAttachmentType(),
            EntityKey: this.entityAttachmentKey(),
            FileId: this.id,
            IncludeInElectronicInvoice: this.includeInElectronicInvoice(),
        };
    }

    switchIncludeInElectronicInvoiceFlag(): void {
        this.includeInElectronicInvoice(!this.includeInElectronicInvoice());
    }

    switchSelection() {
        this.selected(!this.selected());
    }
}
