import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import { If, IfNot } from "./IfIfNotWith";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

type YesNoProps = {
    value: ko.MaybeObservable<boolean> | (() => boolean);

    useIcons?: boolean;
    useIconsOptions?: YesNoUseIconsOptions;
}

type YesNoUseIconsOptions = {
    showOnlyYesIcon?: boolean;
    yesIcon?: string;
    noIcon?: string;
}

export function YesNo(props: YesNoProps) {
    const C = require("./YesNo")._YesNo as typeof _YesNo;
    return <C {...props} />;
}

export class _YesNo {
    static defaultProps: Partial<YesNoProps> = {
        useIcons: false,
        useIconsOptions: {
            showOnlyYesIcon: false,
            yesIcon: "fa-check",
            noIcon: "fa-times"
        }
    }

    constructor(private props : YesNoProps) {
        if (!this.props.useIconsOptions.yesIcon)
            this.props.useIconsOptions.yesIcon = _YesNo.defaultProps.useIconsOptions.yesIcon;
        
        if (!this.props.useIconsOptions.noIcon)
            this.props.useIconsOptions.noIcon = _YesNo.defaultProps.useIconsOptions.noIcon;
    }
    
    render() {
        return ComponentUtils.bindTo((
            <span>
                <If condition={() => ko.isSubscribable(this.props.value) || typeof this.props.value === "function" ? this.props.value() : this.props.value}>
                    {() => this.renderYesValue()}
                </If>
                <IfNot condition={() => ko.isSubscribable(this.props.value) || typeof this.props.value === "function" ? this.props.value() : this.props.value}>
                    {() => this.renderNoValue()}
                </IfNot>
            </span>
        ), this);
    }

    private renderYesValue() {
        if (!this.props.useIcons)
            return <>{TextResources.ProlifeSdk.Yes}</>;

        return (
            <span>
                <i className={"fa " + this.props.useIconsOptions.yesIcon}></i>
            </span>
        );
    }
    
    private renderNoValue() {
        if (!this.props.useIcons)
            return <>{TextResources.ProlifeSdk.No}</>;

        return (
            <>
                {!this.props.useIconsOptions.showOnlyYesIcon && 
                <span>
                    <i className={"fa " + this.props.useIconsOptions.noIcon}></i>
                </span>
                }
            </>
        );
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(YesNo);
}