import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 09/01/2019
 * Time: 10:41
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk";
import { NationsProvider } from "../providers/NationsProvider";
import { ProvincesProvider } from "../providers/ProvincesProvider";
import { LazyImport } from "../../../Core/DependencyInjection";
import { Document } from "../../../Invoices/invoices/documents/Document";
import { IInvoicesService } from "../../interfaces/invoice/IInvoicesService";
import { IDocumentBuilderDocumentSecondaryRecipients } from "../../interfaces/invoice/IDocumentsService";
import { IException } from "../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICustomersService } from "../../interfaces/customer/ICustomersService";
import { ISecondaryRecipientViewModel } from "../../interfaces/invoice/ISecondaryRecipientViewModel";
import { INationsProvider } from "../../interfaces/settings/INationsSettingsManager";
import { IProvincesProvider } from "../../interfaces/settings/IProvincesSettingsManager";
import { ISecondaryRecipient } from "../../interfaces/invoice/ISecondaryRecipient";
import { ICustomer } from "../../interfaces/customer/ICustomer";

export class SecondaryRecipient implements ISecondaryRecipientViewModel {
    public templateName: string = "secondary-recipient";
    public templateUrl: string = "prolifesdk/templates/documents";

    private Id: number;
    private DocumentId: number;
    private DocumentType: string;

    public CustomerId: ko.Observable<number> = ko.observable();
    public CustomerUrl: ko.Computed<string>;
    public CustomerBusinessName: ko.Observable<string> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public CustomerSurname: ko.Observable<string> = ko.observable();
    public CustomerAddress: ko.Observable<string> = ko.observable();
    public CustomerCAP: ko.Observable<string> = ko.observable();
    public CustomerCity: ko.Observable<string> = ko.observable();
    public CustomerMunicipality: ko.Observable<string> = ko.observable();
    public CustomerProvince: ko.Observable<string> = ko.observable();
    public CustomerState: ko.Observable<string> = ko.observable();
    public CustomerPIVA: ko.Observable<string> = ko.observable();
    public CustomerCF: ko.Observable<string> = ko.observable();
    public CustomerIsLocked: ko.Observable<boolean> = ko.observable(false);
    public LegalPerson: ko.Observable<boolean> = ko.observable();

    public ForSuppliers: ko.Observable<boolean> = ko.observable(false);
    public ForCustomers: ko.Observable<boolean> = ko.observable(true);

    public FormattedAddress: ko.Computed<string>;

    public NationsProvider: INationsProvider;
    public ProvincesProvider: IProvincesProvider;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IInvoicesService>())
    private invoiceService : IInvoicesService;
    
    private loading: boolean = false;

    constructor(private secondaryRecipient: IDocumentBuilderDocumentSecondaryRecipients, private parentDocument: Document) {
        this.loading = true;

        this.Id = this.customersService.generateFakeId();
        this.DocumentId = this.parentDocument.DocumentId();
        this.DocumentType = this.parentDocument.DocumentType();

        this.NationsProvider = new NationsProvider();
        this.ProvincesProvider = new ProvincesProvider();

        this.NationsProvider.SelectedNationCode.subscribe((code: string) => {
            if (this.ProvincesProvider.SelectedNationCode() == 'IT' || code == 'IT')
                this.ProvincesProvider.SelectedProvinceCode(undefined);

            this.ProvincesProvider.SelectedNationCode(code);
        });

        this.NationsProvider.SelectedNationCode("IT");

        if (!!secondaryRecipient) {
            this.Id = secondaryRecipient.Id;
            this.DocumentId = secondaryRecipient.DocumentId;
            this.DocumentType = secondaryRecipient.DocumentType;

            this.CustomerId(secondaryRecipient.CustomerId);
            this.CustomerBusinessName(secondaryRecipient.BusinessName);
            this.CustomerName(secondaryRecipient.Name);
            this.CustomerSurname(secondaryRecipient.Surname);
            this.CustomerAddress(secondaryRecipient.Address);
            this.CustomerCAP(secondaryRecipient.CAP);
            this.CustomerCity(secondaryRecipient.City);
            this.CustomerMunicipality(secondaryRecipient.Municipality);
            this.CustomerProvince(secondaryRecipient.Province);
            this.CustomerState(secondaryRecipient.State);
            this.CustomerPIVA(secondaryRecipient.VatNumber);
            this.CustomerCF(secondaryRecipient.TaxCode);
            this.LegalPerson(secondaryRecipient.LegalPerson);

            this.NationsProvider.SelectedNationCode(secondaryRecipient.State);

            this.ProvincesProvider.SelectedNationCode(secondaryRecipient.State);
            this.ProvincesProvider.SelectedProvinceCode(secondaryRecipient.Province);
        }

        this.CustomerBusinessName.subscribe((newValue: string) => {
            if(!newValue && this.LegalPerson())
            this.clearCustomerData();
        });

        this.CustomerSurname.subscribe((newValue: string) => {
            if (!newValue && !this.LegalPerson() && !this.loading)
                this.clearCustomerData();
        });

        this.CustomerId.subscribe(this.loadCustomerData.bind(this));

        this.LegalPerson.subscribe(async (value: boolean) => {
            if (value && !this.loading) {
                let confirm = await this.parentDocument.ShowConfirmSecondaryRecipientsOnInvoice();
                if (!confirm) 
                    this.LegalPerson(false);
            }
        });

        this.CustomerUrl = ko.computed(() => {
            return this.customersService.GetCustomerUrl(this.CustomerId());
        });

        this.FormattedAddress = ko.computed(() => {
            var capAndCity = (!this.CustomerCAP() ? "" : this.CustomerCAP()) + (!this.CustomerCity() ? "" : " " + this.CustomerCity());
            var municipalityProvinceAndState = (!this.CustomerMunicipality() ? "" : this.CustomerMunicipality()) + (!this.CustomerProvince() ? "" : " (" + this.CustomerProvince() + ")") + (!this.CustomerState() ? "" : " " + this.CustomerState());

            var firstPart = (this.CustomerAddress() || "") + (!this.CustomerAddress() ? "" : ", ") + capAndCity;

            return firstPart + (!firstPart ? "" : (!municipalityProvinceAndState ? "" : " - ")) + municipalityProvinceAndState;
        });

        this.loading = false;
    }

    public getData(): ISecondaryRecipient {
        var recipient: ISecondaryRecipient = {
            Id: this.Id,
            DocumentId: this.DocumentId,
            DocumentType: this.DocumentType,
            CustomerId: this.CustomerId(),
            Name: !this.LegalPerson() ? this.CustomerName() : null,
            Surname: !this.LegalPerson() ? this.CustomerSurname() : null,
            BusinessName: this.LegalPerson() ? this.CustomerBusinessName() : null,
            Address: this.CustomerAddress(),
            CAP: this.CustomerCAP(),
            City: this.CustomerCity(),
            Municipality: this.CustomerMunicipality(),
            Province: this.ProvincesProvider.SelectedProvinceCode(),
            State: this.NationsProvider.SelectedNationCode(),
            VatNumber: this.CustomerPIVA(),
            TaxCode: this.CustomerCF(),
            LegalPerson: this.LegalPerson()
        };

        return recipient;
    }

    public Delete(): void {
        this.parentDocument.RemoveSecondaryRecipient(this);
    }

    public createNewCustomer(): void {
        this.customersService.ui.showNewCustomerDialog();
    }

    public VerifyVat(): void {
        if(!this.CustomerPIVA()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Customers.NoVAT);
            return;
        }

        var piva = this.CustomerPIVA();
        var countryCode = "IT";
        if(piva.charAt(0).match(/[a-z]/i) && piva.charAt(1).match(/[a-z]/i)) {
            countryCode = piva.substr(0, 2);
            piva = piva.substr(2);
        }

        this.invoiceService.VerifyVat(countryCode, piva)
            .then((result) => {
                this.CustomerBusinessName(result.Name);
                this.CustomerSurname(result.Name); // TODO da rivedere (non so cosa mettere nel nonme)
                this.CustomerAddress(result.Address);
            })
            .catch((exception: IException) => {
                if(exception.ExceptionMessage == "SERVICE_UNAVAILABLE")
                    this.infoToastService.Error(ProlifeSdk.TextResources.Invoices.VatServiceUnavailable);
                else if(exception.ExceptionMessage == "javax.xml.rpc.soap.SOAPFaultException: IP_BLOCKED")
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.VatServicePleaseRetry);
            });
    }

    public setLegalPerson(value: boolean): void {
        this.LegalPerson(value);
    }

    private clearCustomerData(): void {
        this.CustomerId(undefined);
        this.CustomerName(undefined);
        this.CustomerSurname(undefined);
        this.CustomerBusinessName(undefined);
        this.CustomerAddress(undefined);
        this.CustomerCAP(undefined);
        this.CustomerCF(undefined);
        this.CustomerCity(undefined);
        this.CustomerMunicipality(undefined);
        this.CustomerProvince(undefined);
        this.CustomerPIVA(undefined);
        this.CustomerState(undefined);
        this.LegalPerson(undefined);

        this.ProvincesProvider.SelectedProvinceCode(undefined);
        this.NationsProvider.SelectedNationCode("IT");
    }

    private loadCustomerData(): void {
        if (this.loading)
            return;

        this.customersService.getCustomerById(this.CustomerId())
            .then(this.onCustomerDataLoaded.bind(this));
    }

    private async onCustomerDataLoaded(customer: ICustomer): Promise<void> {
        if (customer) {
            this.populateData(customer);

            if (customer.IsCompany) {
                let confirm = await this.parentDocument.ShowConfirmSecondaryRecipientsOnInvoice();
                if (!confirm) 
                    this.CustomerBusinessName(undefined);
            }
        }
    }

    private populateData(customer: ICustomer): void {
        this.loading = true;

        //this.CustomerBusinessName(((customer.BlockedBy || 0) > 0 ? "(▲) " : "") + customer.FormattedContactName);
        //this.CustomerSurname(((customer.BlockedBy || 0) > 0 ? "(▲) " : "") + customer.Cognome);

        this.CustomerBusinessName(customer.FormattedContactName);
        this.CustomerSurname(customer.Cognome);

        this.CustomerName(customer.Nome);
        this.CustomerAddress(customer.Indirizzo);
        this.CustomerCAP(customer.CAP);
        this.CustomerCity(customer.Citta);
        this.CustomerMunicipality(customer.Comune);
        this.CustomerProvince(customer.Provincia);
        this.LegalPerson(customer.IsCompany);

        this.CustomerState(customer.Stato);
        this.CustomerPIVA(customer.PIVA);
        this.CustomerCF(customer.CF);

        //this.CustomerIsLocked(customer.BlockedBy > 0);

        this.NationsProvider.SelectedNationCode(customer.Stato);

        this.ProvincesProvider.SelectedNationCode(customer.Stato);
        this.ProvincesProvider.SelectedProvinceCode(customer.Provincia);

        this.loading = false;
    }
}