import * as Core from "../../../../Core/Core";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDataSourceIcon } from "../../../../DataSources/IDataSource";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderMetadata } from "../JobOrderMetadataSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";

export class JobOrderClassificationUtils {
    public static getIconFor(data: IJobOrderMetadata): IDataSourceIcon {
        const settingsService: ISettingsService = Core.serviceLocator.findService(nameof<ISettingsService>());
        const jobOrderTypesSettingsManager: IJobOrderTypesSettingsManager = settingsService.findSettingsManager(
            ProlifeSdk.JobOrderType
        ) as IJobOrderTypesSettingsManager;

        let type: IJobOrderType;
        if (!data.ParentId && data.EntityKeyId)
            type = jobOrderTypesSettingsManager.getJobOrderTypeById(data.EntityKeyId);

        return {
            icon:
                type?.Icon ??
                (!data.HasChildren
                    ? "fa fa-tag icon-state-success icon-lg"
                    : "fa fa-folder icon-state-warning icon-lg"),
            background: type ? type.Background : undefined,
            foreground: type ? type.Foreground : undefined,
        };
    }
}
