import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/05/2018
 * Time: 11:57
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { TrustUpdateDelaysOnPaymentsEditingViewModel } from "./ui/TrustUpdateDelaysOnPaymentsEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ITrustUpdateDelaysOnPaymentsSettingsManager, ITrustDelayOnPaymentWithLabel, ITrustDelayOnPayment } from "../../../ProlifeSdk/interfaces/customer/ITrustUpdateDelaysOnPaymentsSettingsManager";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class TrustUpdateDelaysOnPaymentsSettingsManager implements ITrustUpdateDelaysOnPaymentsSettingsManager {
    private delays: ITrustDelayOnPaymentWithLabel[] = [];

    private ajaxService: IAjaxService;
    private modulesService: IModulesService;

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Customers.CustomersSettingsGroup);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
    }

    load(): Promise<ITrustDelayOnPaymentWithLabel[]> {
        return this.ajaxService.Get<ITrustDelayOnPaymentWithLabel[]>("Customers-api", "TrustUpdateDelaysOnPaymentsSettings", {})
            .then((delays: ITrustDelayOnPaymentWithLabel[]) => {
                this.delays = delays;
                return delays;
            });
    }

    getName(): string {
        return ProlifeSdk.TrustUpdateDelaysSettingsManager;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Customers.TrustUpdateDelaysSettingsLabel;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.CustomersApplication);
    }

    getEditingUI(): IView {
        return {
            templateName: "trust-update-delays-settings",
            templateUrl: "customers/templates/settings",
            viewModel: new TrustUpdateDelaysOnPaymentsEditingViewModel(this.serviceLocator, this)
        };
    }

    GetDelays(): ITrustDelayOnPaymentWithLabel[] {
        return this.delays;
    }

    CreateOrUpdateIncrementDelay(delay: ITrustDelayOnPayment): Promise<ITrustDelayOnPayment> {
        return this.ajaxService.Post<ITrustDelayOnPayment>("Customers-api", "TrustUpdateDelaysOnPaymentsSettings", { methodData: delay })
            .then((newDelay: ITrustDelayOnPayment) => {
                var oldDelay = this.delays.filter((d: ITrustDelayOnPaymentWithLabel) => { return d.Id == delay.Id; })[0];
                oldDelay.Id = newDelay.Id;
                oldDelay.PaymentTypeId = newDelay.PaymentTypeId;
                oldDelay.Delay = newDelay.Delay;
                return newDelay;
            });
    }
}