import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import moment = require("moment");
import { MaybeObservable } from "knockout";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { BindTo } from "../../../../Components/Bind";
import { findService } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";

type RequirementSourceInfoProps = {
    docId: MaybeObservable<number>;
    rowNumber: MaybeObservable<number>;
    docNumber: MaybeObservable<string>;
    docVersionNumber?: MaybeObservable<string>;
    docDate: MaybeObservable<Date>;
    protocolName: MaybeObservable<string>;

    showBottomRow?: boolean;
    showVersionNumber?: boolean;
    tooltip?: boolean;
};

export function RequirementSourceInfo(props : RequirementSourceInfoProps) {
    if (props.showBottomRow === null || props.showBottomRow === undefined)
        props.showBottomRow = true;

    let vm = {
        topRow: ko.pureComputed(() => !props.showVersionNumber ? String.format(TextResources.Provisioning.RequirementSourceDescriptionTopRow, ko.unwrap(props.protocolName), ko.unwrap(props.docNumber), moment(ko.unwrap(props.docDate)).format("L"))
                                                               : String.format(TextResources.Provisioning.RequirementSourceDescriptionTopRowWithVersionNumber, ko.unwrap(props.protocolName), ko.unwrap(props.docNumber), ko.unwrap(props.docVersionNumber), moment(ko.unwrap(props.docDate)).format("L"))),
        bottomRow: ko.pureComputed(() => String.format(TextResources.Provisioning.RequirementSourceDescriptionBottomRow, ko.unwrap(props.rowNumber))),
        showTooltip: !!props.tooltip,
        openDocument: () => {
            let docService = findService<IDocumentsService>(nameof<IDocumentsService>());
            docService.OpenDocumentOverlayById(ko.unwrap(props.docId));
        }
    }

    return <BindTo viewModel={vm} as="vm">
        <div style={{ width: '100%' }}>
            <a href="#" style={{ display: 'block' }} className="text-ellipsis" data-bind={{ text: vm.topRow, click: vm.openDocument, attr: { title: ( vm.showTooltip ? vm.topRow : '' ) } }}></a>
            {props.showBottomRow &&  <small style={{ display: 'block', marginTop: '-5px' }} className="text-muted text-ellipsis" data-bind={{ text: vm.bottomRow }}></small>}
        </div>
    </BindTo>
}