import * as Core from "../Core/Core";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import {
    IJobOrderMetadataSettingsManager,
    IJobOrderMetadata,
} from "../JobOrder/jobOrder/settings/JobOrderMetadataSettingsManager";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView, IDataSourceIcon } from "./IDataSource";
import { IJobOrderTypesSettingsManager } from "../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { JobOrderClassificationUtils } from "../JobOrder/jobOrder/settings/ui/JobOrderClassificationUtils";

export interface IJobOrderMetadataModel extends IDataSourceModel<number, IJobOrderMetadata> {
    parentId: number | null;
}

export class JobOrderMetadataDataSource implements IDataSource {
    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager!: IJobOrderMetadataSettingsManager;

    private view: IDataSourceView;
    private listMode = false;
    private typeFilter = -1;

    setView(view: IDataSourceView): void {
        this.view = view;
    }

    setListMode(enabled: boolean) {
        this.listMode = enabled;
    }

    setTypeFilter(value: number) {
        this.typeFilter = value;
    }

    refresh() {
        if (this.view) {
            this.view.refresh();
        }
    }

    select(model: IJobOrderMetadata) {
        if (this.view) {
            this.view.select(this.createModel(model));
        }
    }

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.JobOrder.MetadataSettings;
    }

    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IJobOrderMetadataModel, b: IJobOrderMetadataModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    getData(
        currentModel: IDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IJobOrderMetadataModel[]> {
        let models: IJobOrderMetadata[] = [];

        if (this.listMode) {
            let roots = this.metadataSettingsManager.getRoots();
            if (this.typeFilter > 0) {
                roots = roots.filter((r) => r.EntityKeyId == this.typeFilter);
            }

            return new Promise<IJobOrderMetadataModel[]>((resolve) => {
                const splits = (textFilter || "")
                    .toLowerCase()
                    .split(" ")
                    .filter((s) => s.length > 0);

                const leafs = this.getAllLeafs(null, roots).filter(
                    (l) => splits.filter((s) => l.title.toLowerCase().indexOf(s) >= 0).length == splits.length
                );

                resolve(leafs);
            });
        }

        if (!currentModel) {
            models = this.metadataSettingsManager.getRoots();
            if (this.typeFilter > 0) {
                models = models.filter((r) => r.EntityKeyId == this.typeFilter);
            }
        } else models = this.metadataSettingsManager.getChildren(currentModel.id as number);

        return new Promise<IJobOrderMetadataModel[]>((resolve) => {
            const splits = (textFilter || "")
                .toLowerCase()
                .split(" ")
                .filter((s) => s.length > 0);

            const filteredModels = models.filter(
                (l) => splits.filter((s) => l.Name.toLowerCase().indexOf(s) >= 0).length == splits.length
            );

            resolve(filteredModels.map(this.createModel.bind(this)));
        });
    }

    private getAllLeafs(parent: IJobOrderMetadata, list: IJobOrderMetadata[]): IJobOrderMetadataModel[] {
        let models: IJobOrderMetadataModel[] = [];

        for (const item of list) {
            if (!item.HasChildren) {
                const model = this.createModel(item);
                if (parent) {
                    model.title = parent.Name + " > " + item.Name;
                }
                models.push(model);
            }

            const children = this.metadataSettingsManager.getChildren(item.Id);
            models = models.concat(this.getAllLeafs(item, children));
        }

        return models;
    }

    private createModel(data: IJobOrderMetadata): IJobOrderMetadataModel {
        return {
            id: data.Id,
            parentId: data.ParentId,
            title: data.Name,
            subTitle: data.Code,
            isGroup: false,
            isLeaf: !data.HasChildren,
            icon: JobOrderClassificationUtils.getIconFor(data),
            model: data,
        };
    }

    getById(currentModel: IDataSourceModel, ids: number[]): Promise<IJobOrderMetadataModel[]> {
        return new Promise<IJobOrderMetadataModel[]>((resolve) => {
            const data = this.metadataSettingsManager.getByIds(ids);
            resolve(data.map(this.createModel.bind(this)));
        });
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
