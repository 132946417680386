import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export type DocumentType = "DOC" | "DOA" | "NDC" | "DDT" | "EST" | "SAL" | "COR" | "SOR" | "WHL" | "RFQ" | "RDA";

type DocumentTypeProps = {
    className?: string;
    documentType: DocumentType | ko.Subscribable<DocumentType>;
};

export function DocumentType(props: DocumentTypeProps) {
    const C = require("./DocumentType")._DocumentType as typeof _DocumentType;
    return <C {...props} />;
}

const typesDictionary: { [key in DocumentType]: string } = {
    DOC: TextResources.Invoices.Invoice,
    DOA: TextResources.Invoices.AccompanyingInvoice,
    NDC: TextResources.Invoices.CreditNote,
    DDT: TextResources.Invoices.DDT,
    EST: TextResources.Invoices.Estimate,
    SAL: TextResources.JobOrder.SAL,
    COR: TextResources.Warehouse.CustomerOrder,
    SOR: TextResources.Warehouse.SupplierOrder,
    WHL: TextResources.Warehouse.WarehouseLoad,
    RFQ: TextResources.Provisioning.RequestsForQuotation,
    RDA: TextResources.Provisioning.PurchaseRequest,
};

export function documentTypeTranslate(documentType: DocumentType) {
    return typesDictionary[documentType];
}

export class _DocumentType {
    static defaultProps: Partial<DocumentTypeProps> = {};

    private TranslatedValue: ko.Computed<string>;

    constructor(private props: DocumentTypeProps) {
        this.TranslatedValue = ko.computed(() => {
            const documentType = ko.unwrap(props.documentType);
            return typesDictionary[documentType];
        });
    }

    render() {
        const dt = this;

        return ComponentUtils.bindTo(
            <span data-bind={{ text: dt.TranslatedValue }} className={this.props.className}></span>,
            this,
            "dt"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(DocumentType);
}
