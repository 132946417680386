import * as ko from "knockout";
import { ManufacturersDataSource } from "../../../../../DataSources/ManufacturersDataSource";
import { DiscountFamiliesDataSource } from "../../../../../DataSources/DiscountFamiliesDataSource";
import { StatisticsFamiliesDataSource } from "../../../../../DataSources/StatisticsFamiliesDataSource";
import { ArticlesDataSource } from "../../../../../DataSources/ArticlesDataSource";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IPreferredSuppliersService, IPreferredSupplier } from "../../../../PreferredSuppliersService";
import { DetectClassChanges, DetectChanges } from "../../../../../Core/ChangeDetection";
import { SuppliersDataSource } from "../../../../../DataSources/SuppliersDataSource";
import { PreferredSuppliersDataSource, IPreferredSuppliersDataSourceModel, IPreferredSuppliersDataSourceObserver } from "../../../../../DataSources/PreferredSuppliersDataSource";
import { PreferredSuppliersSolutionDataSource } from "../../../../../DataSources/PreferredSuppliersSolutionDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";
import { IDetectChanges } from "../../../../../Core/interfaces/IDetectChanges";

export class PreferredSuppliers implements IView, IDataSourceListener, IPreferredSuppliersDataSourceObserver {
    templateName: string = "preferredSuppliers";
    templateUrl: string= "warehouse/templates/discounts";
    viewModel: any;

    SectionId: number = 11;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService! : IDialogsService;

    @LazyImport(nameof<IPreferredSuppliersService>())
    private preferredSuppliersService! : IPreferredSuppliersService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService! : IInfoToastService;

    public ManufacturerId : ko.Observable<number> = ko.observable();
    public ManufacturersDataSource : ManufacturersDataSource = new ManufacturersDataSource();

    public DiscountFamilyId : ko.Observable<number> = ko.observable();
    public DiscountFamiliesDataSource : DiscountFamiliesDataSource = new DiscountFamiliesDataSource();

    public StatisticFamilyId : ko.Observable<number> = ko.observable();
    public StatisticsFamiliesDataSource : StatisticsFamiliesDataSource = new StatisticsFamiliesDataSource();

    public ArticleFilter : ko.Observable<string> = ko.observable();
    public ArticlesDataSource : ArticlesDataSource = new ArticlesDataSource();

    public SupplierId : ko.Observable<number> = ko.observable();
    public SuppliersDataSource : SuppliersDataSource = new SuppliersDataSource()

    public FilterExpanded : ko.Observable<boolean> = ko.observable(false);
    public FilterEnabled : ko.Observable<boolean> = ko.observable(false);

    PreferredSuppliersDataSource : PreferredSuppliersDataSource = new  PreferredSuppliersDataSource();
    SelectedPreferredSupplier : ko.Observable<PreferredSupplier> = ko.observable();

    TestingPreferredSuppliersDataSource : PreferredSuppliersSolutionDataSource = new PreferredSuppliersSolutionDataSource();
    TestingPreferredSuppliersFilter : ko.Observable<string> = ko.observable();

    SomethingChanged : ko.Observable<boolean> = ko.observable(false);

    constructor() {
        this.viewModel = this;

        this.PreferredSuppliersDataSource.addObserver(this);

        this.SelectedPreferredSupplier(new PreferredSupplier(this));
    }

    onItemChanged(): void {
        this.SomethingChanged(true);
        this.TestingPreferredSuppliersDataSource.refresh();
    }

    onRowChanged(changedRow : PreferredSupplier) {
        if(changedRow)
            this.PreferredSuppliersDataSource.select(changedRow.getData());
        
        this.PreferredSuppliersDataSource.refresh();
        this.TestingPreferredSuppliersDataSource.refresh();

        this.SomethingChanged(true);
    }

    onItemSelected(sender: IDataSource, model: IPreferredSuppliersDataSourceModel): void {
        if(model)
            this.SelectedPreferredSupplier(new PreferredSupplier(this, model.model));
        else
            this.SelectedPreferredSupplier(null);
    }

    onItemDeselected(sender: IDataSource, model: IPreferredSuppliersDataSourceModel): void {
        
    }

    applyFilter() {
        this.FilterEnabled(true);
        this.doApplyFilter();
    }

    private doApplyFilter() {
        this.PreferredSuppliersDataSource.setFilter({
            ManufacturerId: this.ManufacturerId(),
            DiscountFamilyId: this.DiscountFamilyId(),
            StatisticFamilyId: this.StatisticFamilyId(),
            ArticleFilter: this.ArticleFilter(),
            SupplierId: this.SupplierId()
        });
        this.PreferredSuppliersDataSource.refresh();
    }

    resetFilter() {
        this.FilterEnabled(false);
        this.ManufacturerId(null);
        this.DiscountFamilyId(null);
        this.StatisticFamilyId(null);
        this.ArticleFilter(null);
        this.SupplierId(null);

        this.doApplyFilter();
    }

    public async newRule() : Promise<void> {
        let result : boolean = true;
        if(this.SelectedPreferredSupplier() && this.SelectedPreferredSupplier().isChanged() > 0) {
            result = await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse! Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");           
        }

        if(result) {
            this.SelectedPreferredSupplier(new PreferredSupplier(this));
            this.PreferredSuppliersDataSource.select();
        }
    }

    async canSelectItem(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): Promise<boolean> {
        if(this.SelectedPreferredSupplier() && this.SelectedPreferredSupplier().isChanged() > 0) {
            return await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse! Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");           
        }
        return true;
    }

    applyRules() : Promise<void> {
        return this.preferredSuppliersService.ApplyPreferredSuppliers()
            .then(() => {
                this.infoToastService.Success("I fornitori abituali sono stati impostati con successo!");
                this.SomethingChanged(false);
            });
    }
}

@DetectClassChanges
export class PreferredSupplier implements IDetectChanges {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService! : IDialogsService;

    @LazyImport(nameof<IPreferredSuppliersService>())
    private preferredSuppliersService! : IPreferredSuppliersService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService! : IInfoToastService;

    public IsNew : ko.Observable<boolean> = ko.observable();
    public Deleted : ko.Observable<boolean> = ko.observable();

    @DetectChanges
    public ManufacturerId : ko.Observable<number> = ko.observable();
    public ManufacturersDataSource : ManufacturersDataSource = new ManufacturersDataSource();

    @DetectChanges
    public DiscountFamilyId : ko.Observable<number> = ko.observable();
    public DiscountFamiliesDataSource : DiscountFamiliesDataSource = new DiscountFamiliesDataSource();

    @DetectChanges
    public StatisticFamilyId : ko.Observable<number> = ko.observable();
    public StatisticsFamiliesDataSource : StatisticsFamiliesDataSource = new StatisticsFamiliesDataSource();

    @DetectChanges
    public ArticleFilter : ko.Observable<string> = ko.observable();
    public ArticlesDataSource : ArticlesDataSource = new ArticlesDataSource();

    @DetectChanges
    public SupplierId : ko.Observable<number> = ko.observable();
    public SuppliersDataSource : SuppliersDataSource = new SuppliersDataSource()

    isChanged: ko.Observable<number> = ko.observable(0);

    dispose(): void {
        
    }

    constructor(private editor: PreferredSuppliers, protected preferredSupplier? : IPreferredSupplier) {
        this.IsNew(!preferredSupplier || !preferredSupplier.Id);

        if(preferredSupplier) {
            this.ManufacturerId(preferredSupplier.FkManufacturer);
            this.DiscountFamilyId(preferredSupplier.FkDiscountFamily);
            this.StatisticFamilyId(preferredSupplier.FkStatisticFamily);
            this.ArticleFilter(preferredSupplier.ArticleFilter);
            this.SupplierId(preferredSupplier.FkSupplier);
        }

        this.isChanged(0);
    }

    getData(): IPreferredSupplier {
        return {
            Id: this.preferredSupplier ? this.preferredSupplier.Id : 0,
            FkManufacturer: this.ManufacturerId(),
            FkDiscountFamily: this.DiscountFamilyId(),
            FkStatisticFamily: this.StatisticFamilyId(),
            ArticleFilter: this.ArticleFilter(),
            FkSupplier: this.SupplierId()
        };
    }

    public async Save() : Promise<void> {
        return this.preferredSuppliersService.CreateOrUpdatePreferredSupplier([this.getData()])
            .then((newRow) => {
                this.preferredSupplier = newRow;
                this.isChanged(0);
                this.IsNew(false);
                this.infoToastService.Success("Regola salvata con successo!");
                this.editor.onRowChanged(this);
            }, () => {
                this.infoToastService.Error("Impossibile inserire la regola perchè esiste già una regola con lo stesso filtro!");
            });
    }

    public async Remove() : Promise<void> {
        if(!await this.dialogsService.ConfirmAsync("Sei sicuro di voler eliminare questa regola?", "Annulla", "Elimina"))
            return;

        if(this.preferredSupplier) {
            await this.preferredSuppliersService.RemovePreferredSupplier(this.preferredSupplier.Id);
            this.infoToastService.Success("Regola eliminata con successo!");
            this.editor.onRowChanged(null);
        }
    }
}